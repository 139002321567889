/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from 'react'
import { setEditRuleDetails } from '../../../actions/governance/governanceAction'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

class ServiceAccessPort extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	/**
	 * Add new Allowedlist form
	 */
	addNewAllowedList = () => {
		this.props.setEditRuleDetails('condition', {
			...this.props.editRuleDetails.condition,
			allow: [...this.props.editRuleDetails.condition.allow, ''],
		})
	}
	/**
	 * Delete Allowedlist form
	 */
	deleteAllowedList = index => {
		let temp = this.props.editRuleDetails.condition.allow
		temp.splice(index, 1)
		this.props.setEditRuleDetails('condition', {
			...this.props.editRuleDetails.condition,
			allow: [...temp],
		})
	}
	/**
	 * Add new blocklist form
	 */
	addNewBlockList = () => {
		this.props.setEditRuleDetails('condition', {
			...this.props.editRuleDetails.condition,
			deny: [...this.props.editRuleDetails.condition.deny, ''],
		})
	}
	/**
	 * Delete blocklist form
	 */
	deleteBlockList = index => {
		let temp = this.props.editRuleDetails.condition.deny
		temp.splice(index, 1)
		this.props.setEditRuleDetails('condition', {
			...this.props.editRuleDetails.condition,
			deny: [...temp],
		})
	}

	render() {
		return (
			<div className='card border-0'>
				<div className='card-header p-0 border-0 bg-transparent' id='headingThree'>
					<h2 className='mb-0'>
						<button
							className='btn btn-outline-primary mb-1'
							onClick={() => {
								this.props.setEditRuleDetails('condition', {
									...this.props.editRuleDetails.condition,
									allow: this.props.editRuleDetails.condition.allow.length
										? this.props.editRuleDetails.condition.allow
										: [''],
								})
							}}
						>
							Allowed Ports
						</button>
					</h2>
				</div>
				<small className='text-muted mb-2'>Lorem ipsum dolor sit amet aedtr.</small>

				{this.props.editRuleDetails.condition.allow && this.props.editRuleDetails.condition.allow.length
					? this.props.editRuleDetails.condition.allow.map((ip, i) => {
							return (
								<div className='d-flex mb-1' key={i}>
									<div className='mr-3'>
										<input
											type='text'
											className='form-control'
											placeholder=''
											value={ip}
											onChange={e => {
												let temp = this.props.editRuleDetails.condition.allow
												temp[i] = e.target.value
												this.props.setEditRuleDetails('condition', {
													...this.props.editRuleDetails.condition,
													allow: temp,
												})
											}}
										/>
									</div>
									<div>
										<button className='btn btn-outline-success border-0' onClick={() => this.addNewAllowedList()}>
											<i className='far fa-plus-circle' />
										</button>
										<button className='btn btn-outline-danger border-0' onClick={() => this.deleteAllowedList(i)}>
											<i className='far fa-trash-alt' />
										</button>
									</div>
								</div>
							)
					  })
					: null}

				<div className='card-header p-0 border-0 bg-transparent' id='headingThree'>
					<h2 className='mb-0'>
						<button
							className='btn btn-outline-primary mb-1'
							onClick={() => {
								this.props.setEditRuleDetails('condition', {
									...this.props.editRuleDetails.condition,
									deny: this.props.editRuleDetails.condition.deny.length
										? this.props.editRuleDetails.condition.deny
										: [''],
								})
							}}
						>
							Blocked Ports
						</button>
					</h2>
				</div>
				<small className='text-muted mb-2'>Lorem ipsum dolor sit amet aedtr.</small>

				{this.props.editRuleDetails.condition.deny && this.props.editRuleDetails.condition.deny.length
					? this.props.editRuleDetails.condition.deny.map((ip, i) => {
							return (
								<div className='d-flex' key={i}>
									<div className='mr-3 mb-1'>
										<input
											type='text'
											className='form-control'
											placeholder=''
											value={ip}
											onChange={e => {
												let temp = this.props.editRuleDetails.condition.deny
												temp[i] = e.target.value
												this.props.setEditRuleDetails('condition', {
													...this.props.editRuleDetails.condition,
													deny: temp,
												})
											}}
										/>
									</div>
									<div>
										<button className='btn btn-outline-success border-0' onClick={() => this.addNewBlockList()}>
											<i className='far fa-plus-circle' />
										</button>
										<button className='btn btn-outline-danger border-0' onClick={() => this.deleteBlockList(i)}>
											<i className='far fa-trash-alt' />
										</button>
									</div>
								</div>
							)
					  })
					: null}
			</div>
		)
	}
}
/**
 * Type of the props used in the component
 */
ServiceAccessPort.propTypes = {
	logType: PropTypes.object,
	setEditRuleDetails: PropTypes.func,
	editRuleDetails: PropTypes.object,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		editRuleDetails: state.governance.editRuleDetails,
	}
}

export default connect(mapStateToProps, { setEditRuleDetails })(ServiceAccessPort)
