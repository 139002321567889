/*************************************************
 * Tvastar
 * @exports
 * @file ViewSidePanel.js
 * @author Prakash // on 26/09/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _, { filter } from 'lodash'
// import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { listAllAccounts, listAllRegions } from '../../../actions/commonAction'
import { setNewInitialRuleDetails, generateIamPolicy, getMasterGovernanceRules, getGovernanceServices } from '../../../actions/governance/newGovernanceAction'

import { getAccountNameFromId, capitalizeFirstLetter } from '../../../utils/utility'

import SearchWithHiddenInput from '../../common/SearchWithHiddenInput'

import RdsServiceEnginesTemplate from './pages/RDS/RdsServiceEnginesTemplate'
import ServiceLogs from './pages/RDS/ServiceLogs'
import ServiceCapacity from './pages/RDS/ServiceCapacity'
import ServiceBlockStore from './pages/RDS/ServiceBlockStore'

import DynamicServices from './pages/RDS/DynamicServices'

class ViewSidePanel extends Component {
	constructor(props) {
		super(props)
        this.toggleRef = React.createRef()
		this.state = {
            minMaxHeight: 700,
			selectedTab: "Poilcy",
			modeTypes: [
				{value: "reactive", label: "Reactive"},
				{value: "preventive", label: "Preventive"}
			],

			actionMethods: [
				{value: "immediately", label: "Immediately after the violation"},
				{value: "run_at", label: "At"},
				{value: "run_after", label: "After"},
			]
		}
	}

	componentDidMount = () => {
		document.addEventListener('keydown', this._handleKeyDown)
		this.getWindowSize()
		window.addEventListener('resize', () => {
            this.getWindowSize()
        })
		

		if(this.props.visitFrom === "List") {
			this.getGovernanceServices()
		} else {
			this.setState({ masterData: this.props.service_master_details }, () => this.structureServices())
		}
		if(this.state.selectedTab === "Json") {
			this.generateIamPolicy()
		}

		this.getAccounts()
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
        let headerHeihgt = 100
		let outerHeight = (window.outerHeight - browserBorder - headerHeihgt)

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }	
	
	masterData = (field, value, index) => { //not used
		let params = {}
		params.provider = this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider.toLowerCase() : ""
		params.resource_type = this.state[field] && this.state[field] !== "" ? this.state[field] : ""
		params.governance_type = this.state.governance_type

		let checkServicesExist = this.props.service_master_details && Object.keys(this.props.service_master_details).includes(value)
		if(!checkServicesExist && params.provider !== "" && params.resource_type !== "") {
			this.props.getMasterGovernanceRules(params, (promise, response) => {
				if(promise) {
					this.setState({ ["masterData_"+value]: response }, () => this.onClickServicesBlock(field, value, index))
					if(response && response.length) {
						let obj = this.props.service_master_details
						obj[value] = response
						
						this.setGovernancePropsDetails('service_master_details', obj)
					}
				} else {
					this.setState({ masterData: [] }, () => this.onClickServicesBlock(field, value, index))
				}
			})
		}
	}

	getGovernanceServices = () => {
		this.formPropsData()
		// let params = {}
		// params.governance_type = this.props.governanceType

		// if(params.governance_type) {
		// 	this.props.getGovernanceServices(params, (promise, response) => {
		// 		if(promise) {
		// 			this.setState({ services: response },
		// 				() => this.formPropsData()	
		// 			)
		// 		} else {
		// 			this.setState({ services: [] })
		// 		}
		// 	})
		// }
	}

	formPropsData = () => {
		this.setState({ selectedProvider: this.props.selectedProvider }, 
			() => {
				if(this.props.ruleDetails) {
					let policyInformation = this.props.ruleDetails.policies
					let policyBasicDetails = this.props.ruleDetails.policy_metadata
					console.log("this.props.ruleDetails", this.props.ruleDetails)
					// let policies = []
					// let serviceArray = []
					// policyInformation.forEach((item, index) => {
					// 	serviceArray.push(index)
					// 	item.categories.forEach(cat => {
					// 		cat.policies.forEach(catPol => {
					// 			catPol.resource_type = item.resource_type
					// 			policies.push(catPol)
					// 		})
					// 	})
					// 	this.setState({ ["resource_type_"+index]: item.resource_type, serviceArray }, 
					// 		() => {
					// 			this.masterData("resource_type_"+index, this.state["resource_type_"+index], index)
					// 		}
					// 	)
					// })
					// let resourceGroupPolicies = policyInformation ? _.chain(policyInformation).groupBy("resource_type").map((value1, key1) => ({ resource_type: key1, ruleResourceTypePolicies: value1, ruleCategory: _.chain(policyInformation).groupBy("rule_category").map((value, key) => ({ label: key, ruleCategoryGroupPolicies: value, ruleIdGroup: _.chain(value).groupBy('rule_id').map((value, key) => ({ label: key, policies: value })).value() })).value() })).value() : []

					// console.log("resourceGroupPolicies", resourceGroupPolicies)

					let groupedPolicies = {policies: policyInformation}
					let mergeObjects = {...groupedPolicies, ...policyBasicDetails}
					console.log("mergeObjects", mergeObjects)
					this.setState({ policyData: mergeObjects })

					this.props.setNewInitialRuleDetails(mergeObjects)

				}
			}
		)
	}

	onClickServicesBlock = (field, value, index) => {
		this.state.services && this.state.services.forEach(row => {
			if(row === value) {
				this.setState({ ["show_resource_type_"+value]: true })
			} else {
				this.setState({ ["show_resource_type_"+row]: false })
			}
		})
	}

	getAccounts = () => {
		let provider = this.props.editNewRuleDetails && this.props.editNewRuleDetails.provider ? this.props.editNewRuleDetails.provider : ""
		let params = {}
		params.provider = provider
		// if(this.props.accounts && this.props.accounts.length) {
		// 	let accounts = this.props.accounts
		// 	if(provider !== "") {
		// 		accounts = this.props.accounts.filter(account => account.provider === provider.toLowerCase())
		// 	}
		// 	let selectedAccount = accounts.length ? accounts[0].account_id : ""
		// 	this.setState({ accounts, filteredAccounts: accounts, selectedAccount })
		// } else {
		// 	this.props.listAllAccounts(params, (promise, response) => {
		// 		if (promise) {						
		// 			let filteredAccounts = response
		// 			if(provider !== "") {
		// 				filteredAccounts = response.filter(account => account.provider === provider.toLowerCase())
		// 			}
		// 			let selectedAccount = filteredAccounts.length ? filteredAccounts[0].account_id : ""
		// 			this.setState({ accounts: filteredAccounts, filteredAccounts, selectedAccount })
		// 		}
		// 	})
		// }
		this.props.listAllAccounts(params, (promise, response) => {})
	}

	_handleKeyDown = event => {
		switch (event.keyCode) {
			case 27:
				this.props.closeSidePanel()
				break
			default:
				break
		}
	}

	structureServices = () => {
		let ruleDetails = this.props.editNewRuleDetails

		let policies = []
		this.state.masterData && Object.entries(this.state.masterData).forEach(([key, row]) => {
			row.length && row.forEach(item => {
				policies = [...policies, ...item.policies]
			})
		})

		
		let data = []
		ruleDetails.policies && ruleDetails.policies.forEach(pol => {
			if(policies.filter(e => e.rule_id === pol.rule_id).length) {
				let dataRow = pol
				if(policies.filter(e => e.rule_id === pol.rule_id)[0].api_to_call) {
					dataRow.api_to_call = policies.filter(e => e.rule_id === pol.rule_id)[0].api_to_call
				}
				data.push(dataRow)
			}
		})
		let resourceGroupPolicies = data ? _.chain(data).groupBy("resource_type").map((value1, key1) => ({ resource_type: key1, ruleResourceTypePolicies: value1, ruleCategory: _.chain(data).groupBy("rule_category").map((value, key) => ({ label: key, ruleCategoryGroupPolicies: value, ruleIdGroup: _.chain(value).groupBy('rule_id').map((value, key) => ({ label: key, policies: value })).value() })).value() })).value() : []

		let groupPolicies = data ? _.chain(data)
		.groupBy('resource_type')
		.map((value, key) => ({ label: key, sub: value })).value() : data

		this.setState({ groupPolicies, resourceGroupPolicies })
	}

	generateIamPolicy = () => {
		let params = this.props.editNewRuleDetails
		this.props.generateIamPolicy(params, (promise, response) => {
			if(promise) {
				this.setState({ generatedIamPoilcy: response },
					() => this.structureGeneratedPolicy()	
				)
			} else {
				this.setState({ generatedIamPoilcy: {} })
			}
		})
	}
	
	structureGeneratedPolicy = () => {
		let generatedIamPoilcy = this.state.generatedIamPoilcy ? this.state.generatedIamPoilcy : []
		// console.log("generatedIamPoilcy", generatedIamPoilcy)
		
		let accounts = generatedIamPoilcy.map(e => e.account_id)

		let selectedAccount = accounts && accounts.length ? accounts[0] : ""
		let accPolicies = generatedIamPoilcy.map(e => e.account_id === selectedAccount ? e.policy : {})
		this.setState({ accounts, filteredAccounts: accounts, selectedAccount, accPolicies })
	}

    handleClickOutside(event) {
        if (this.state.selectedTab !== "Poilcy" || !this.toggleRef || (this.toggleRef && !this.toggleRef.current.contains(event.target))) {
            this.setState({ showModeOptions: false })
        } else {
            this.setState({ showModeOptions: true })
        }
    }
	
	render() {		
		return (
			<div className="advanced-search" onClick={(event) => this.handleClickOutside(event)}>
				<div className={`loaderOverlay ${this.state.queryLoading ? "" : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
				<div className="search-content bg-muted w-80">
					<div className="header-search bd-highlight d-flex justify-content-between">
						<div className="flex-fill bd-highlight">
							<h5>Details</h5>
							<div className="mr-2 d-flex">
								{/* <div className="mr-3">
									<p className="m-0 small">Last Scan: 29 Sep 2020 11:10</p>
								</div> */}
								{/* <div className="mr-3">
									<p className="m-0 pl-2 small">Account:  {this.state.selectedAccount ? this.state.selectedAccount: 'All'}</p>
								</div>
								<div className="mr-3">
									<p className="m-0 pl-2 small">Region:  {this.state.selectedRegion ? this.state.selectedRegion: 'All'}</p>
								</div>
								<div>
									<p className="m-0 pl-2 small">Duration: 
										{DURATION_FILTER_OPTIONS.map((item, index) => {
											return (
												this.state.selectedDuration === item.value ?
													item.option
												: null
											)
										})}
									</p>
								</div> */}
							</div>
						</div>

						<div className="text-right flex-fill bd-highlight align-self-center">
							<i className="far fa-times cursorPointer" onClick={() => this.props.closeSidePanel()}></i>
						</div>
					</div>
					<div className={`${!this.state.showLoading ? 'overflow-auto' : ''} p-2`}>
						<div className="d-flex justify-content-between">
							<ul className="nav nav-tabs border-bottom bd-highlight bg-muted shadow-none mb-0">
								<li className="nav-item">
									<span
										className={`nav-link text-white cursor-pointer f12 ${this.state.selectedTab === "Json" ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
										onClick={() => this.setState({ selectedTab: "Json" }, () => this.generateIamPolicy())}
									>
									Json
									</span>
								</li>
								<li className="nav-item">
									<span 
										className={`nav-link text-white cursor-pointer f12 ${this.state.selectedTab === "Poilcy" ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
										onClick={() => this.setState({ selectedTab: "Poilcy" })}
									>
										Policy
									</span>
								</li>
							</ul>
							{this.state.selectedTab === "Poilcy" ?
								<div className="select-sm-bg curvedDropdown minWidth120 mr-2">
									<div className="dropdown">
										<span className="dropdown-toggle d-flex justify-content-between px-2 text-white" ref={this.toggleRef}>
											{this.state.selectedModeType ? (this.state.modeTypes.filter(e => e.value === this.state.selectedModeType).length ? this.state.modeTypes.filter(e => e.value === this.state.selectedModeType)[0].label : this.state.selectedModeType) : <span className="placeholder">Select</span>}
										</span>
										<ul className={`dropdown-menu p-0 ${this.state.showModeOptions ? "show" : ''}`}>
											{this.state.modeTypes.map(item => {
												return (
													<li 
														onClick={() => 
															this.setState({ 
																showModeOptions: false,
																selectedModeType: item.value,
																groupPolicies: []
															}, () => this.structureServices())
														}
														className={`${this.state.selectedModeType === item.value ? "selected" :  ""}`}
													>
														{item.label}
													</li>
												)
											})}
										</ul>
									</div>
								</div>
							: null}
						</div>
						{this.state.selectedTab === "Poilcy" ?
							<div className="rounded bg-dark dlpDescriptionForm p-3 overflowYAuto" style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
								<div className="d-flex mt-3">
									<div className={`w-100`}>
										<div className="accordion" id="accordionExample">
											{this.state.policyData && this.state.policyData.policies && this.state.policyData.policies.length ?
												this.state.policyData.policies.map((row, rowIndex) => {
													return(
														<div className={`card bg-dark border-0 mb-2 overflow-unset`}>
															{/* <div className="d-flex justify-content-between text-white border-bottom">
																<div className="d-flex">
																	<p className="mb-0 f20 text-warning cursorPointer">{row.resource_type}</p>
																</div>
															</div> */}
															<h6 className='text-purple'>{row.resource_type === "opa_kubernetes" ? "Kubernetes(Gatekeeper) Policy" : row.resource_type}</h6>
															{row.categories && row.categories.length ? 
																row.categories.map(cat => {
																	return(
																		<React.Fragment>
																		<p className='mb-2 f20 text-warning cursorPointer'>
																			{cat.rule_category} Policy Rules
																		</p>
																		{cat.policies.map((pol, polIndex) => {
																			return(
																				!pol.template ?
																					<DynamicServices
																						masterData = {pol}
																						resourceType = {row.resource_type}
																						onClickFinish={this.state.onClickFinish}
																						// validateInput={(boo, noInputDetected) => this.setState({ onClickFinish: false, [row.rule_category+"_validation_"+polIndex]: noInputDetected ? (boo ? "failed" : "success") : "failed" }, () => this.validatePolicyInput(row.rule_category, boo, polIndex))}
																						viewMode={true}
																						selectedModeType={this.state.selectedModeType}
																						actionMethods={this.state.actionMethods}
																					/>
																				: pol.rule_category === "Engine" ?
																					<RdsServiceEnginesTemplate 
																						masterData = {pol}
																						onClickFinish={this.state.onClickFinish}
																						// validateInput={boo => this.setState({ onClickFinish: false, [pol.rule_category+"engine_validation_"+polIndex]: boo ? "failed" : "success" }, () => this.validatePolicyInput(pol.rule_category, boo, polIndex))}
																						viewMode={true}
																						selectedModeType={this.state.selectedModeType}
																						actionMethods={this.state.actionMethods}
																					/>
																				: pol.rule_category === "Log" ?
																					<ServiceLogs
																						masterData = {pol}
																						onClickFinish={this.state.onClickFinish}
																						// validateInput={boo => this.setState({ onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" }, () => this.validatePolicyInput(pol.rule_category, boo, polIndex))}
																						viewMode={true}
																						selectedModeType={this.state.selectedModeType}
																						actionMethods={this.state.actionMethods}
																					/>
																				: pol.rule_category === "BlockStore" ?
																					<ServiceBlockStore
																						masterData = {pol}
																						onClickFinish={this.state.onClickFinish}
																						// validateInput={boo => this.setState({ onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" }, () => this.validatePolicyInput(pol.rule_category, boo, polIndex))}
																						viewMode={true}
																						selectedModeType={this.state.selectedModeType}
																						actionMethods={this.state.actionMethods}
																					/>
																				: pol.rule_category === "Capacity" ?
																					<ServiceCapacity
																						masterData = {pol}
																						onClickFinish={this.state.onClickFinish}
																						// validateInput={boo => this.setState({ onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" }, () => this.validatePolicyInput(pol.rule_category, boo, polIndex))}
																						viewMode={true}
																						selectedModeType={this.state.selectedModeType}
																						actionMethods={this.state.actionMethods}
																					/>
																				: null
																			)
																		})}
																		</React.Fragment>
																	)
																})
															: null}
														</div>
													)
												})
											: this.state.resourceGroupPolicies && this.state.resourceGroupPolicies.length ?
												this.state.resourceGroupPolicies.map(row => {
													return(
														<React.Fragment>
														<h6 className='text-purple'>{row.resource_type === "opa_kubernetes" ? "Kubernetes(Gatekeeper) Policy" : row.resource_type}</h6>
														{row.ruleCategory.map((cat, polIndex) => {
															return(
																<React.Fragment>
																<p className='mb-2 f20 text-warning cursorPointer'>
																	{cat.label} Policy Rules
																</p>
																{cat.ruleCategoryGroupPolicies.map(pol => {
																	return(																			
																		!pol.template ?
																			<DynamicServices
																				masterData = {pol}
																				resourceType = {row.resource_type}
																				onClickFinish={this.state.onClickFinish}
																				validateInput={(boo, noInputDetected) => this.setState()}
																				viewMode={true}
																				selectedModeType={this.state.selectedModeType}
																				actionMethods={this.state.actionMethods}
																			/>
																		: pol.rule_category === "Engine" ?
																			<RdsServiceEnginesTemplate 
																				masterData = {pol}
																				onClickFinish={this.state.onClickFinish}
																				validateInput={boo => this.setState()}
																				viewMode={true}
																				selectedModeType={this.state.selectedModeType}
																				actionMethods={this.state.actionMethods}
																			/>
																		: pol.rule_category === "Log" ?
																			<ServiceLogs
																				masterData = {pol}
																				onClickFinish={this.state.onClickFinish}
																				validateInput={boo => this.setState()}
																				viewMode={true}
																				selectedModeType={this.state.selectedModeType}
																				actionMethods={this.state.actionMethods}
																			/>
																		: pol.rule_category === "BlockStore" ?
																			<ServiceBlockStore
																				masterData = {pol}
																				onClickFinish={this.state.onClickFinish}
																				validateInput={boo => this.setState()}
																				viewMode={true}
																				selectedModeType={this.state.selectedModeType}
																				actionMethods={this.state.actionMethods}
																			/>
																		: pol.rule_category === "Capacity" ?
																			<ServiceCapacity
																				masterData = {pol}
																				onClickFinish={this.state.onClickFinish}
																				validateInput={boo => this.setState()}
																				viewMode={true}
																				selectedModeType={this.state.selectedModeType}
																				actionMethods={this.state.actionMethods}
																			/>
																		: null
																	)
																})}
																</React.Fragment>
															)
														})}
														</React.Fragment>
													)
												})
											: this.state.groupPolicies && this.state.groupPolicies.length ?
												this.state.groupPolicies.map(row => {
													return(
														<React.Fragment>
														<h6 className='text-purple'>{row.label}</h6>
														{row.sub.map((pol, polIndex) => {
															return(
																!pol.template ?
																	<DynamicServices
																		masterData = {pol}
																		resourceType = {row.resource_type}
																		onClickFinish={this.state.onClickFinish}
																		validateInput={(boo, noInputDetected) => this.setState()}
																		viewMode={true}
																		selectedModeType={this.state.selectedModeType}
																		actionMethods={this.state.actionMethods}
																	/>
																: pol.rule_category === "Engine" ?
																	<RdsServiceEnginesTemplate 
																		masterData = {pol}
																		onClickFinish={this.state.onClickFinish}
																		validateInput={boo => this.setState()}
																		viewMode={true}
																		selectedModeType={this.state.selectedModeType}
																		actionMethods={this.state.actionMethods}
																	/>
																: pol.rule_category === "Log" ?
																	<ServiceLogs
																		masterData = {pol}
																		onClickFinish={this.state.onClickFinish}
																		validateInput={boo => this.setState()}
																		viewMode={true}
																		selectedModeType={this.state.selectedModeType}
																		actionMethods={this.state.actionMethods}
																	/>
																: pol.rule_category === "BlockStore" ?
																	<ServiceBlockStore
																		masterData = {pol}
																		onClickFinish={this.state.onClickFinish}
																		validateInput={boo => this.setState()}
																		viewMode={true}
																		selectedModeType={this.state.selectedModeType}
																		actionMethods={this.state.actionMethods}
																	/>
																: pol.rule_category === "Capacity" ?
																	<ServiceCapacity
																		masterData = {pol}
																		onClickFinish={this.state.onClickFinish}
																		validateInput={boo => this.setState()}
																		viewMode={true}
																		selectedModeType={this.state.selectedModeType}
																		actionMethods={this.state.actionMethods}
																	/>
																: null
															)
														})}
														</React.Fragment>
													)
												})
											: 
												<div className='d-flex justify-content-center m-4'>
													<p>There are no policies for the selected Rule. Please try another rule</p>
												</div>
											}
										</div>
									</div>
								</div>
							</div>
						:
							<div className="bg-dark rounded px-2">
								<div className="d-flex">
									<div className="col-sm-2 border-right p-0 pt-2">
										<div className="overflowYAuto"style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
											<div className="pt-3">
												<div className="">
													{this.state.accounts && this.state.accounts.length ?
														<SearchWithHiddenInput
															data={this.state.accounts}
															applyLiteDarkTags={true}
															searchClassName={'border-0'}
															searchIconColor={'text-gray3'}
															className="form-control-sm text-gray3 bg-muted border-0"
															hideInputSection={true}
															topClassName={'dark-search'}
															searchPlaceHolder={'Search....'}
															filteredData={(filteredAccounts) => {
																let selectedAccount = filteredAccounts.length ? filteredAccounts[0] : ""
																let accPolicies = filteredAccounts.map(e => e.account_id === selectedAccount ? e.policy : {})
																this.setState({ filteredAccounts, selectedAccount, accPolicies })
															}}
														/>
													: null}
												</div>
												<div className="d-flex justify-content-between my-3">
													<p className="mb-0 mt-1 small text-white">Showing {this.state.filteredAccounts && this.state.filteredAccounts.length} of total {this.state.accounts && this.state.accounts.length} Account(s)</p>
												</div>
												{this.state.filteredAccounts && this.state.filteredAccounts.length ? 
													this.state.filteredAccounts.map((row, i) => {
														return(
															<div className={`cursorPointer ${this.state.selectedAccount === row ? "bg-dark3" : ""}`} onClick={() => this.onClickAccFunction(row) }>
																<div className={`d-flex justify-content-between py-2 ${i !== 0 ? "border-top" : ""}`}>
																	<div className="w-50 align-self-center">
																		<h7 className="font-weight-bold text-white">{capitalizeFirstLetter(getAccountNameFromId(row, this.props.accounts))}</h7>
																		<p className="f12 m-0">{row}</p>
																	</div>
																</div>
															</div>
														)
													})
												:
													null
													// <div className='d-flex justify-content-center m-4'>
													// 	<p>There are no data on this criteria. Please try adjusting your search.</p>
													// </div>
												}
											</div>
										</div>
									</div>
									<div className="col-sm-10 pr-0 pt-2">
										<div className="overflowYAuto" style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
											{this.state.accPolicies && Object.entries(this.state.accPolicies).length ?
												<pre className="text-white">{JSON.stringify(this.state.accPolicies, null, 4)}</pre>												
											: null}
										</div>
									</div>
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ViewSidePanel.propTypes = {}

const mapStateToProps = state => {
	// console.log('observability query page',state)
	return {
		accounts: state.filters.accounts,
		regions: state.filters.regions,
		editNewRuleDetails: state.governance.editNewRuleDetails,
		service_master_details:
			state.governance.governancePropsDetails && state.governance.governancePropsDetails.service_master_details
				? state.governance.governancePropsDetails.service_master_details
				: {},
	}
}

export default connect(mapStateToProps, {
	listAllAccounts,
	listAllRegions,
	generateIamPolicy,
	getMasterGovernanceRules,
	getGovernanceServices,
	setNewInitialRuleDetails
})(withRouter(ViewSidePanel))