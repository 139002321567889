/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 1/06/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 * 
 *************************************************/
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { AppWrapper } from '../../common/AppWrapper'
import { URL_PATH } from '../../../config/urlPath'
// import PropTypes from 'prop-types'
import { setResourcePropsDetails } from '../../../actions/assets/assetsAction'

import { listAllProviders, listAllAccounts, listAllRegions, listAllServices } from '../../../actions/commonAction'
import { momentDateGivenFormat, subHours, twoDateDiffrence, subDays, getDayFromSelectedDuration, getAccountNameFromId } from '../../../utils/utility'
// import { UncontrolledTooltip } from 'reactstrap'
import _ from 'lodash'

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

import { RangePicker } from "react-minimal-datetime-range";
import "react-minimal-datetime-range/lib/react-minimal-datetime-range.min.css";

import Chart from 'react-apexcharts'

import LeftSideTab from './LeftSideTab'

import AutomationsLeftSection from './Automations/LeftSection'
import AutomationsRightSection from './Automations/RightSection'
import ServiceQuota from './ServiceQuota/LandingPage'
import Inventory from './Inventory'

import AlertsTab from './AlertsTab'
import AnomalyTab from './AnomalyTab'
import ChecksTab from './ChecksTab'
import ErrorsTab from './ErrorsTab'
import EventsTab from './EventsTab'
import ChangesTab from './ChangesTab'

import MetricsTab from './MetricsTab'
// import SreMetricsTab from './SreMetricsTab'

const durationOptions = [    
    { value: '+1h', option: 'Last 1 hour' },
    { value: '+3h', option: 'Last 3 hours' },
    { value: '+6h', option: 'Last 6 hours' },
    { value: '+12h', option: 'Last 12 hours' },
	{ value: '+24h', option: 'Last 24 hours' },
	{ value: '+3d', option: 'Last 3 days' },
	{ value: '+7d', option: 'Last 1 week' },
    { value: '+14d', option: 'Last 2 weeks' },
    { value: '+30d', option: 'Last 1 month' },
	{ value: 'custom', option: 'Custom Date' },
]
let timer = null;

const Categories = ["All", "compute", "Networking", "CDN", "Database", "Storage"]

class LandingPage extends Component {
    constructor(props) {
        super(props)
        this.accountRef = React.createRef()
        this.regionRef = React.createRef()
        this.daterRangeRef = React.createRef()
        this.categoryRef = React.createRef()
        
        this.state = {
            showLoading: true,

            //filter section start

            selectedProvider: "",

            selectedAccount: [],
            isAccountOpen: false,

            selectedRegion: [],
            isRegionOpen: false,

            selectedDuration: "+3h",

            selectedShowBy: "",

            typeofPages: [
                {label: "Alerts", value: "Alerts"},
                {label: "Anomalies", value: "Anomalies"},
                {label: "Automations", value: "Automations"},
                {label: "Changes", value: "ChangeEvents"},
                {label: "Checks", value: "Checks"},
                {label: "Compliance", value: "Compliance"},
                {label: "Errors", value: "Errors"},
                {label: "Inventory", value: "Inventory"},
                {label: "Issues", value: "IssueEvents"},
                {label: "LifeCycle", value: "LifeCycle"},
                {label: "Notifications", value: "Notifications"},
                {label: "Service Quota", value: "ServiceQuota"},
            ],
            selectedPage: "Inventory",
            
            //filter section end
            
            selectionRange: [{
                startDate: new Date(),
				endDate: new Date(),
				key: "selection"
			}],
            
            pageType: "",
            
            startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
            overAllTotalPages: 1,
			perPage: 15,
            endRecord: 15,
            
            assetCategory: [],
            selectedService: [],
            isCategoryOpen: false, 
            tagsArray: [0],

            showRightSide: true,

            allDurationOptions: durationOptions
        }
    }

	componentDidMount = () => {
        this.getFilterApis()

        this.filterDuarionOption()
    }

    filterDuarionOption = () => {
        let durationOptions = this.state.allDurationOptions
        let selectedDuration = this.state.selectedDuration
        if(this.state.selectedPage === "Alerts" || this.state.selectedPage === "Anomalies" || this.state.selectedPage === "Errors") {
            durationOptions = durationOptions.slice(0, 6)

            if(!durationOptions.filter(e => e.value === this.state.selectedDuration).length) {
                selectedDuration = "+3h"
            }
        }

        this.setState({ durationOptions, selectedDuration })
    }

    getFilterApis = () => {
        if(this.props.providers && this.props.providers.length) {
			this.setState({ providers: this.props.providers, selectedProvider: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : this.props.providers[0].provider_name },
				() => {
                    if (this.props.location && this.props.location.state && this.props.location.state) {
						this.loadFromLoctionAction()
					} else {
						this.getFilterData('provider')
					}
				}
			)
		} else {
			this.props.listAllProviders((promise, providers) => {
				if (promise) {
					this.setState({ providers, selectedProvider: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : providers[0].provider_name },
						() => {
							if (this.props.location && this.props.location.state && this.props.location.state) {
                                this.loadFromLoctionAction()
                            } else {
                                this.getFilterData('provider')
                            }
						}
					)
				} else {
					this.setState(
						{ providers: [],  }, 
						() => {
							this.getFilterData()
						}
					)
				}
			})
		}        
    }

    loadFromLoctionAction = () => {
        let selectedPage = this.props.location.state.selectedPage && this.props.location.state.selectedPage !== "" && this.state.typeofPages.filter(e => e.value === this.props.location.state.selectedPage).length ? this.props.location.state.selectedPage: this.state.selectedPage
        let selectedDuration = this.props.location.state.selectedDuration
        
        if(selectedPage === "Alerts" || selectedPage === "Anomalies" || selectedPage === "Errors") {
            if(!durationOptions.filter(e => e.value === this.state.selectedDuration).length) {
                selectedDuration = "+3h"
            }
        }

		this.setState({
			selectedProvider: this.props.location.state.selectedProvider.toLowerCase(),
            selectedAccount: [this.props.location.state.selectedAccount],
            // selectedDuration,
            selectedPage,
		},
            () => {
                this.getFilterData('provider')
            }
        )
	}
    
	getFilterData = from => {
		let params = {}

		if (this.state.selectedProvider !== "" && this.state.selectedProvider !== 'all') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}

		if (from === 'provider') {
			if(this.props.accounts && this.props.accounts.length) {
				let allAccounts = this.props.accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
				if(this.props.accounts.length) {
					let accRow = {}
					accRow.account_id = "All"
					accRow.account_name = "All"
					accRow.date = null
					accRow.disabled = false
					accRow.provider = "All"
					allAccounts.unshift(accRow);
				}
				this.setState({ accounts: allAccounts },
                    () => {
                        if(this.state.selectedAccount.length) {                            
                            let array = []
                            this.state.selectedAccount.forEach(accItem => {
                                let dataRow = {
                                    value: accItem,
                                    label: getAccountNameFromId(accItem, this.state.accounts)
                                }
                                array.push(dataRow)
                            })
                            this.handleMultiSelectChange("selectedAccount", array)
                        }
                    }
                )
			} else {
				this.props.listAllAccounts(params, (promise, allAccounts) => {
					if (promise) {						
						let filterdAccounts = allAccounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
						if(filterdAccounts.length) {
							let accRow = {}
							accRow.account_id = "All"
							accRow.account_name = "All"
							accRow.date = null
							accRow.disabled = false
							accRow.provider = "All"
							filterdAccounts.unshift(accRow);
						}						
						this.setState({ accounts: filterdAccounts },
                            () => {
                                if(this.state.selectedAccount.length) {
                                    let array = []
                                    this.state.selectedAccount.forEach(accItem => {
                                        let dataRow = {
                                            value: accItem,
                                            label: getAccountNameFromId(accItem, this.state.accounts)
                                        }
                                        array.push(dataRow)
                                    })
                                    this.handleMultiSelectChange("selectedAccount", array)
                                }
                            }    
                        )
					}
				})
			}

			if(this.props.regions && this.props.regions.length) {
				let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
				this.setState({ regions: providerRegions })
			} else {
				this.props.listAllRegions(params, (promise, regions) => {
					if (promise) {
						let providerRegions = regions && regions[0] && regions[0].provider_regions
						if(providerRegions) {
							let provider_regions_row = {}
							provider_regions_row.availability_zones = []
							provider_regions_row.city = "All"
							provider_regions_row.country = "All"
							provider_regions_row.geography = "All"
							provider_regions_row.name = "All"
							provider_regions_row.region = "All"
							provider_regions_row.region_id = "All"
							provider_regions_row.state = "All"
							providerRegions.unshift(provider_regions_row);
						}
						this.setState({ regions: providerRegions })
					} else {
						this.setState({ regions: [] })
					}
				})
			}

            this.props.listAllServices(params, (promise, services) => {})
		}

        this.durationFunction('onLoad')
	}

    onSearch = (type) => {
        if(this.state.changesInFilter) {
            this.props.setResourcePropsDetails("leftSectionSelection", {})
            this.props.setResourcePropsDetails("resourcesCoreMetricsList", {})
            let sliderStartDate = this.state.sliderStartDate
            let sliderEndDate = this.state.sliderEndDate
            if(this.state.selectedDuration === "custom") {
                sliderStartDate = this.state.datePickerStartDate && this.state.datePickerStartDate !== "" ? this.state.datePickerStartDate : sliderStartDate
                sliderEndDate = this.state.datePickerEndDate && this.state.datePickerEndDate !== "" ? this.state.datePickerEndDate : sliderEndDate
            }
            let rightPage = ""
            if(this.state.selectedPage === "Alerts") {
                rightPage = "Alerts"
            } else if(this.state.selectedPage === "Anomalies") {
                rightPage = "Anomalies"
            } else if(this.state.selectedPage === "Checks" || this.state.selectedPage === "Compliance") {
                rightPage = "Checks"
            } else if(this.state.selectedPage === "Errors") {
                rightPage = "Errors"
            } else if(this.state.selectedPage === "Notifications" || this.state.selectedPage === "IssueEvents" || this.state.selectedPage === "LifeCycle") {
                rightPage = "Events"
            } else if(this.state.selectedPage === "ChangeEvents") {
                rightPage = "change_events"
            }
 
            this.setState({
                brushStartTime: "",
                brushEndTime: "",
                changesInFilter: false,
                showLoading: true,
                resetEnabled: type === 'onLoad' ? false : true,
                sliderStartDate,
                sliderEndDate,
                nextErrorListToken: "",
                selectedAsset: "",
                showLoading: false,
                pageType: this.state.selectedPage,
                rightPage
            },
                () => {
                    if(this.state.pageType === "Automations" || this.state.pageType === "ServiceQuota" || this.state.pageType === "Inventory") {
                        this.setState({ showBrushGraph: false },
                            () => this.brushSelecteionPeriod(this.state.sliderStartDate, this.state.sliderEndDate, false)
                        )
                    } else {
                        this.brushOptions()
                        this.brushSelecteionPeriod(this.state.sliderStartDate, this.state.sliderEndDate, false)
                    }
                }
            )
        }
    }

    onReset = () => {
        this.setState({ 
            selectedProvider: "",
            selectedAccount: [],
            selectedDuration: '+3h',
            changesInFilter: this.state.resetEnabled,
            datePickerStartDate: "",
            datePickerEndDate: ""
        },
            () => {
                if(this.state.resetEnabled) {
                    this.durationFunction('onLoad')
                }
            }
        )
    }

    brushOptions = () => {
        let brushSeries = []
        let brushRow = {}
        brushRow.data = [
            {x: momentDateGivenFormat(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm'), y: 0},
            {x: momentDateGivenFormat(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm'), y: 0}
        ]
        brushSeries.push(brushRow)

        let endTIme = momentDateGivenFormat(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:ss')
        let time = endTIme
        let brushHours = 1
        let diff = twoDateDiffrence(this.state.sliderEndDate, this.state.sliderStartDate)
        if(diff === 1) {            
            brushHours = 3
        } else if(diff > 1 && diff <= 3) {
            brushHours = 6
        } else if(diff > 3 && diff <= 7) {
            brushHours = 24 //3
        } else if(diff > 7 && diff <= 14) {
            brushHours = 36 //6
        } else if(diff > 14 && diff <= 30) {
            brushHours = 48 //12
        } else if(diff > 30) {
            brushHours = 96 //24
        }

        let brushStartTime = subHours(time, brushHours)
        let brushEndTime = time
        
        let optionsBrush = {
            chart: {
                redrawOnWindowResize: false,
                redrawOnParentResize: false,
                background: '#fff',
                id: 'chart1',
                type: 'line',
                sparkline: {
                    enabled: true
                },
                zoom: {
                    enabled: false,
                },
                brush:{
                    // targets: ['bar1', 'bar2'],
                    target: "",
                    enabled: true,
                    autoScaleYaxis: true,
                },
                selection: {
                    enabled: true,
                    fill: {
                        color: "#039BE5",
                        opacity: 1,
                    },
                    xaxis: {
                        min: new Date(momentDateGivenFormat(brushStartTime, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                        max: new Date(momentDateGivenFormat(brushEndTime, 'YYYY-MM-DD HH:mm:ss')).getTime()
                    }
                },
                events: {
                    selection: (chartContext, { xaxis, yaxis }) => {
                        let startTime = momentDateGivenFormat(xaxis.min, 'YYYY-MM-DD HH:mm:ss')
                        let endTime = momentDateGivenFormat(xaxis.max, 'YYYY-MM-DD HH:mm:ss')
                        if(startTime !== this.state.sliderStartDate && endTime !== this.state.sliderEndDate) {
                            this.brushSelection(xaxis)
                        }
                    }
                }
            },
            markers: {
                size: 0
            },
            colors: ['#F44336'],
            fill: {
                type: 'solid',
                color: "#000",
                opacity: 1,
            },
            xaxis: {
                type: 'datetime',
                tooltip: {
                    enabled: false
                }
            },
            yaxis: {
                show: false,
                tickAmount: 2
            },
            annotations: {
                yaxis: [
                  {
                    y: 1,
                    borderColor: '#000',
                    label: {
                      borderColor: '#00E396',
                      style: {
                        color: '#fff',
                        background: '#00E396'
                      },
                      text: ""
                    }
                  }
                ]
            }
        }

        brushStartTime = momentDateGivenFormat(brushStartTime, 'YYYY-MM-DD HH:mm:ss')
        brushEndTime = momentDateGivenFormat(brushEndTime, 'YYYY-MM-DD HH:mm:ss')

        this.setState({ brushSeries, optionsBrush, showBrushGraph: true, brushStartTime, brushEndTime })
    }

    brushSelection = (xaxis, type) => {
        clearTimeout(timer)
        let brushStartTime = momentDateGivenFormat(xaxis.min, 'YYYY-MM-DD HH:mm:ss')
        let brushEndTime = momentDateGivenFormat(xaxis.max, 'YYYY-MM-DD HH:mm:ss')
        if(this.state.brushStartTime && this.state.brushStartTime !== "" && (this.state.sliderStartDate !== brushStartTime || this.state.sliderEndDate !== brushEndTime)) {
            timer = setTimeout(() => this.brushSelecteionPeriod(brushStartTime, brushEndTime, true), 1000)
        } else {
            this.brushSelecteionPeriod(this.state.sliderStartDate, this.state.sliderEndDate, false)
        }
        
        if(brushStartTime && brushEndTime) {
            this.setState({ brushStartTime, brushEndTime, alertListLoader: true })
        }
    }

    brushSelecteionPeriod = (brushStartTime, brushEndTime, brushTime) => {
        let obj = {}
        obj.start_time = this.state.sliderStartDate
        obj.end_time = this.state.sliderEndDate
        obj.brushStartTime = brushStartTime
        obj.brushEndTime = brushEndTime
        obj.selectedProvider = this.state.selectedProvider
        obj.selectedAccount = this.state.selectedAccount
        obj.selectedRegion = this.state.selectedRegion
        obj.selectedDuration = this.state.selectedDuration
        obj.pageType = this.state.pageType
        this.props.setResourcePropsDetails('dashboardDetails', obj)

        // this.props.setAiopsPropsDetails('obsAssetAlertsList', {})
        // this.props.setAiopsPropsDetails('obsAssetEventsList', {})
        // this.props.setAiopsPropsDetails('obsAssetAnomalyList', {})
        // this.props.setAiopsPropsDetails('obsAssetErrorsList', {})
        // this.props.setAiopsPropsDetails('observabilityCoreMetricsList', {})
        // this.props.setAiopsPropsDetails('observabilitySreMetricsList', {})
    }
    
    handleMultiSelectChange = (field, arrayValue) => {
		let value = arrayValue.map(item => item.value)

		let selectedValue = []
		if(field === 'selectedAccount') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.accounts.forEach(acc => {
						selectedValue.push(acc.account_id)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.accounts.length -1)) {
					selectedValue.push('All')
				}
			}	
            this.setState({ [field]: selectedValue, changesInFilter: true })		
		} else if(field === 'selectedRegion') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.regions.length -1)) {
					selectedValue.push('All')
				}
			}
            this.setState({ [field]: selectedValue, changesInFilter: true  })
		} else if(field === 'selectedService') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.regions.length -1)) {
					selectedValue.push('All')
				}
			}
            this.setState({ [field]: selectedValue })
		} else if(field === 'assetCategory') {
			let prevState = this.state[field] ? this.state[field] : []
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					Categories.forEach(item => {
						selectedValue.push(item)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (Categories.length -1)) {
					selectedValue.push('All')
				}
			}	
            this.setState({ [field]: selectedValue, changesInFilter: true },
                // () => this.filterResults()    
            )		
		}
    }
    
    getMultiSelectedCount = (type, array) => {
        return array.length && array.includes('All') ? 'All' : (array.length ? array.length +' Selected' : 'All')
    }

    handleSelectNew = (date) => {
        // let datePickerStartDate = momentDateGivenFormat(date[0], "YYYY-MM-DD 00:00:00")
		// let datePickerEndDate = momentDateGivenFormat(date[1], "YYYY-MM-DD 23:59:59")
        let datePickerStartDate = momentDateGivenFormat(date[0], "YYYY-MM-DD HH:mm:00")
		let datePickerEndDate = momentDateGivenFormat(date[1], "YYYY-MM-DD HH:mm:00")
        this.setState({ datePickerStartDate, datePickerEndDate, changesInFilter: true, showDateRangePicker: false })
	}

    durationFunction = (type) => {
        let duration = this.state.selectedDuration
        let period = duration.substring(duration.length, duration.length-1)
        let durationBtw = getDayFromSelectedDuration(duration)
        let sliderStartDate = ""
        if(period === "d") {
            sliderStartDate = momentDateGivenFormat(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
        } else {
            sliderStartDate = momentDateGivenFormat(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
        }
        
        let sliderEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')

        let selectionRange = [{
			startDate: new Date(sliderStartDate),
			endDate: new Date(sliderEndDate),
			key: 'selection',
		}]

        let dayDiffrence = twoDateDiffrence(this.state.sliderEndDate, this.state.sliderStartDate)

        this.setState({ sliderStartDate, sliderEndDate, selectionRange, changesInFilter: true, dayDiffrence },
            () => {
                if(type === "onLoad") {
                    this.onSearch('onLoad')
                }
            }
        )
    } 

    handleClickOutside = (event) => {
        if(!this.state.showQueryResults) {
            if(this.state.selectedPage !== "Automations" && this.state.selectedPage !== "Inventory") {
                if (this.daterRangeRef && !this.daterRangeRef.current.contains(event.target)) {
                    this.setState({ showDateRangePicker: false })
                }
            }

            if (this.accountRef && !this.accountRef.current.contains(event.target)) {
                this.setState({ isAccountOpen: false })
            } else {
                this.setState({ isAccountOpen: true })
            }

            if (this.regionRef && !this.regionRef.current.contains(event.target)) {
                this.setState({ isRegionOpen: false })
            } else {
                this.setState({ isRegionOpen: true })
            }
        }
    }

    reloadRightSide = () => {
        this.setState({ showRightSide: false },
            () => this.setState({ showRightSide: true })
        )
    }

    changeTab = () => {
        let rightPage = ""
        if(this.state.selectedPage === "Alerts") {
            rightPage = "Alerts"
        } else if(this.state.selectedPage === "Anomalies") {
            rightPage = "Anomalies"
        } else if(this.state.selectedPage === "Checks" || this.state.selectedPage === "Compliance") {
            rightPage = "Checks"
        } else if(this.state.selectedPage === "Errors") {
            rightPage = "Errors"
        } else if(this.state.selectedPage === "Notifications" || this.state.selectedPage === "IssueEvents" || this.state.selectedPage === "LifeCycle") {
            rightPage = "Events"
        } else if(this.state.selectedPage === "ChangeEvents") {
            rightPage = "change_events"
        }

        this.setState({ rightPage })
    }

	render() {
		return (
            <div className="container-fluid overflow-auto flex-grow-1 bg-muted" onClick={ (event) => { this.handleClickOutside(event) } }>
                <div className={`loaderOverlay ${this.state.showLoading ? "" : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
                <div className='row h-100'>
                    <div className='col-sm-12 p-0'>
                        <div className="title-section pb-3">
							<div className="d-flex">
                                <div className='col-4 align-self-center pl-2'>
                                    <div className='d-flex align-self-center'>
                                        <div className='mr-3'>
                                            <Link className='text-info far fa-arrow-left' to={URL_PATH.RESOURCES_DASHBOARD}>
                                                <span className="ml-2 fontFamily">Back</span>
                                            </Link>
                                        </div>
                                        <div className=''>
                                            <h6 className="text-white m-0">Resources</h6>
                                            <p className="text-white m-0">Count board view of resources on your Cloud</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={`col-8 align-self-center`}>
                                    <div className={`col-md-12 d-flex flex-wrap justify-content-end bd-highlight`}>
                                        <div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: '150px' }}>
                                            <label className="mb-1">Provider</label>
                                            <Select
                                                placeholder={'Select'}
                                                isSearchable={false}
                                                // menuIsOpen={true}
                                                className='f13 p-0 colorBlack reactSelectFilterDrop'
                                                value={({
                                                    value: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : 'Select',
                                                    label: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : <span className="placeholder">Select</span>
                                                })}
                                                options={this.state.providers && this.state.providers.map(item => ({
                                                    value: item.provider_name,
                                                    label: item.provider_name,	
                                                }))}
                                                onChange={event => this.setState({
                                                    selectedProvider: event.value,
                                                    changesInFilter: true,
                                                },
                                                    () => this.getFilterData('provider')
                                                )}
                                            />
                                        </div>
                                        <div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} ref={this.accountRef} style={{maxWidth: '150px' }}>
                                            <label className="mb-1">Account</label>
                                            <div className='dlpHeaderMultiSelection removeDropdownSearchBar'>
                                                <ReactMultiSelectCheckboxes						
                                                    placeholderButtonLabel="All"
                                                    menuIsOpen ={this.state.isAccountOpen}
                                                    getDropdownButtonLabel={() => this.getMultiSelectedCount('account', this.state.selectedAccount)}
                                                    onChange={arr => {
                                                    this.handleMultiSelectChange('selectedAccount', arr ? arr : []) }}
                                                    options={this.state.accounts && this.state.accounts.map(account => ({
                                                        value: account.account_id,
                                                        label: account.account_name,
                                                    }))}
                                                    value={this.state.selectedAccount.map(comp => ({
                                                        value: comp,
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                        <div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} ref={this.regionRef} style={{maxWidth: '150px' }}>
                                            <label className="mb-1">Region</label>
                                            <div className='dlpHeaderMultiSelection removeDropdownSearchBar'>
                                                <ReactMultiSelectCheckboxes
                                                    placeholderButtonLabel="Select"
                                                    menuIsOpen ={this.state.isRegionOpen}
                                                    getDropdownButtonLabel={() => this.getMultiSelectedCount('region', this.state.selectedRegion)}
                                                    onChange={arr => this.handleMultiSelectChange('selectedRegion', arr ? arr : []) }
                                                    options={this.state.regions && this.state.regions.map(reg => ({
                                                        value: reg.region,
                                                        label: reg.name,
                                                    }))}
                                                    value={this.state.selectedRegion.map(comp => ({
                                                        value: comp,
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`}style={{maxWidth: '150px' }}>
                                            <label className="mb-1">Show</label>
                                            <Select
                                                placeholder={'Select'}
                                                isSearchable={false}
                                                // menuIsOpen={true}
                                                className='f13 p-0 colorBlack reactSelectFilterDrop'
                                                value={({
                                                    value: this.state.selectedPage && this.state.selectedPage !== "" ? this.state.selectedPage : 'Select',
                                                    label: this.state.selectedPage && this.state.selectedPage !== "" ? this.state.typeofPages.filter(e => e.value === this.state.selectedPage)[0].label : <span className="placeholder">Select</span>
                                                })}
                                                options={this.state.typeofPages && this.state.typeofPages.map(item => ({
                                                    value: item.value,
                                                    label: item.label,	
                                                }))}
                                                onChange={event => this.setState({
                                                        selectedPage: event.value,                                                    
                                                        changesInFilter: true,
                                                    }, 
                                                        () => this.filterDuarionOption()
                                                    )
                                                }
                                            />
                                        </div>
                                        {this.state.selectedPage && this.state.selectedPage !== "Automations" && this.state.selectedPage !== "Inventory" ?
                                            <div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zindex1`} ref={this.daterRangeRef} style={{maxWidth: '150px'}}>
                                                <label className="mb-1">Duration</label>
                                                <Select
                                                    placeholder={'Select'}
                                                    isSearchable={false}
                                                    // menuIsOpen={true}
                                                    className='f13 p-0 colorBlack reactSelectFilterDrop'
                                                    value={({
                                                        value: this.state.selectedDuration && this.state.selectedDuration !== "" ? this.state.selectedDuration : 'Select',
                                                        label: this.state.selectedDuration && this.state.selectedDuration !== "" ? durationOptions.filter(e => e.value === this.state.selectedDuration)[0].option : <span className="placeholder">Select</span>
                                                    })}
                                                    options={this.state.durationOptions && this.state.durationOptions.map(item => ({
                                                        value: item.value,
                                                        label: item.option,	
                                                    }))}
                                                    onChange={event => {
                                                        if(event.value !== 'custom') {
                                                            this.setState(
                                                                {
                                                                    selectedDuration: event.value,
                                                                    showDateRangePicker: false,
                                                                },
                                                                () => {
                                                                    this.durationFunction("onChange")
                                                                }
                                                            )
                                                        } else {
                                                            this.setState({ showDateRangePicker: true, selectedDuration: event.value })
                                                        }
                                                    }}
                                                />
                                                {this.state.showDateRangePicker ?
                                                    <div className="obsDateTimePicker text-black5 pt-2 ml-n1" style={{"z-index": "999999999","position": "absolute","right":0}}>
                                                        <RangePicker
                                                            // locale={`en-us`} // default is en-us
                                                            show={true} // default is false
                                                            disabled={false}
                                                            placeholder={["Start Time", "End Time"]}
                                                            // defaultDates={[
                                                            //     new Date().getFullYear() + '-' + new Date().getMonth() + 1 + '-' + new Date().getDate(), 
                                                            //     new Date().getFullYear() + '-' + new Date().getMonth() + 1 + '-' + new Date().getDate()
                                                            // ]}
                                                            // defaultTimes={[new Date().getHours()-1+':00',new Date().getHours()+':00']}
                                                            // defaultDates={[
                                                            //     momentDateGivenFormat(new Date(), "YYYY-MM-DD"),
                                                            //     momentDateGivenFormat(new Date(), "YYYY-MM-DD"),
                                                            // ]}
                                                            // defaultDates={[
                                                            // year + "-" + month + "-" + date,
                                                            // year + "-" + month + "-" + date
                                                            // ]} // ['YYYY-MM-DD', 'YYYY-MM-DD']
                                                            defaultTimes={["00:00", "23:59"]} // ['hh:mm', 'hh:mm']
                                                            // initialDates={[
                                                            // year + "-" + month + "-" + date,
                                                            // year + "-" + month + "-" + date
                                                            // ]} // ['YYYY-MM-DD', 'YYYY-MM-DD']
                                                            // initialTimes={[new Date().getHours()-1 + ":00", new Date().getHours() + ":00"]} // ['hh:mm', 'hh:mm']
                                                            onConfirm={(res) => this.handleSelectNew(res)}
                                                            onClose={() => console.log("onClose")}
                                                            onClear={() => console.log("onClear")}
                                                            style={{ width: "610px", margin: "0 auto" }}
                                                            // markedDates={[
                                                            // `${year}-${month.padStart(2, "0")}-${date.padStart(2, "0") - 1}`,
                                                            // `${year}-${month.padStart(2, "0")}-${date.padStart(2, "0")}`
                                                            // ]} // OPTIONAL. ['YYYY-MM-DD']
                                                        />
                                                    </div>
                                                : null}
                                            </div>
                                        : null}
                                        
                                        <div className={`ml-3 align-self-center`}>
                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                <button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-right px-2 py-1`} onClick={() => this.setState({ showLoading: false }, () => this.durationFunction('onLoad'))}>Search</button>
                                                <button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-left px-2 py-1`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${this.state.pageType === "Inventory" ? "" : "px-3"}`}>
                            {this.state.showBrushGraph ? 
                                <div class="rounded bg-transparent w-100 mt-2">
                                    <div className="d-flex">
                                        {this.state.brushSeries && this.state.brushSeries.length && this.state.optionsBrush && Object.entries(this.state.optionsBrush).length ?
                                            <React.Fragment>
                                            <div className="w-5 d-flex justify-content-end pr-2">
                                                
                                            </div>
                                            <div className="removeSvgPoint w-80">
                                                <Chart options={this.state.optionsBrush} series={this.state.brushSeries} type="line" height={8} />
                                            </div>
                                            <div className="pl-2 w-15">
                                                <small className="mb-0 align-self-center text-info f10">{momentDateGivenFormat(this.state.brushStartTime, 'DD MMM HH:mm') +' - '+momentDateGivenFormat(this.state.brushEndTime, 'DD MMM HH:mm')}</small>
                                            </div>
                                            </React.Fragment>
                                        : null}
                                    </div>
                                </div>
                            : null}
                            {this.state.pageType === "ServiceQuota" ?
                                <ServiceQuota />
                            : this.state.pageType === "Inventory" ?
                                <Inventory />
                            : this.state.typeofPages && this.state.typeofPages.filter(e => e.value === this.state.pageType).length ?
                                <div className="row">
                                    <div className={`${this.state.pageType === "Automations" ? "col-sm-3" : "col-sm-7"} mt-2`}>
                                        {this.state.pageType === "Automations" ?
                                            <div class="bg-dark rounded w-100">
                                                <AutomationsLeftSection
                                                    reloadRightSide={() => this.reloadRightSide()}
                                                />
                                            </div>
                                        :
                                            <div class="bg-dark rounded w-100 obsSectionHeight">
                                                <LeftSideTab
                                                    pageType={this.state.pageType}
                                                    reloadRightSide={() => this.reloadRightSide()}
                                                    selectedShowBy={(option) => this.setState({ selectedShowBy: option }, 
                                                        () => this.changeTab()
                                                    )}
                                                />
                                            </div>
                                        }
                                    </div>
                                    {this.state.pageType === "Automations" ?
                                        <div className={`col-sm-9 pl-0 mt-2`}>
                                            <div className="rounded bg-dark">
                                                <AutomationsRightSection />
                                            </div>
                                        </div>
                                    : 
                                        <div className={`col-sm-5 pl-0 mt-2`}>
                                            <div className="rounded bg-dark p-3 h-100">
                                                <div className="w-100">
                                                    <ul className={`row nav nav-tabs border-bottom bg-dark shadow-none rounded justify-content-between`} id="myTab" role="tablist">
                                                        <span className={`d-flex`}>
                                                            {this.state.pageType === "Alerts" ?
                                                                <li className="nav-item">
                                                                    <span className={`nav-link text-white cursorPointer ${this.state.rightPage === 'Alerts' ? ' active' : ''}`} onClick={() => this.setState({ rightPage: 'Alerts', menuDisabled: true })}>
                                                                        Alerts 
                                                                        {/* {this.state.selectedAsset && this.state.selectedAsset.alerts ?
                                                                            <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">{this.state.selectedAsset.alerts}</span>
                                                                        : null}  */}
                                                                    </span>
                                                                </li>
                                                            : this.state.pageType === "Anomalies" ?
                                                                <li className="nav-item">
                                                                    <span className={`nav-link text-white cursorPointer ${this.state.rightPage === 'Anomalies' ? ' active' : ''}`} onClick={() => this.setState({ rightPage: 'Anomalies', menuDisabled: true })}>
                                                                    Anomalies 
                                                                        {/* {this.state.selectedAsset && this.state.selectedAsset.alerts ?
                                                                            <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">{this.state.selectedAsset.alerts}</span>
                                                                        : null}  */}
                                                                    </span>
                                                                </li>
                                                            : (this.state.pageType === "Checks" || this.state.pageType === "Compliance") ?
                                                                <li className="nav-item">
                                                                    <span className={`nav-link text-white cursorPointer ${this.state.rightPage === 'Checks' ? ' active' : ''}`} onClick={() => this.setState({ rightPage: 'Checks', menuDisabled: true })}>
                                                                    Checks 
                                                                        {/* {this.state.selectedAsset && this.state.selectedAsset.alerts ?
                                                                            <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">{this.state.selectedAsset.alerts}</span>
                                                                        : null}  */}
                                                                    </span>
                                                                </li>
                                                            : this.state.pageType === "Errors" ?
                                                                <li className="nav-item">
                                                                    <span className={`nav-link text-white cursorPointer ${this.state.rightPage === 'Errors' ? ' active' : ''}`} onClick={() => this.setState({ rightPage: 'Errors', menuDisabled: true })}>
                                                                    Errors
                                                                    </span>
                                                                </li>
                                                            : (this.state.pageType === "Notifications" || this.state.pageType === "IssueEvents" || this.state.pageType === "LifeCycle") ?
                                                                <li className="nav-item">
                                                                    <span className={`nav-link text-white cursorPointer ${this.state.rightPage === 'Events' ? ' active' : ''}`} onClick={() => this.setState({ rightPage: 'Events', menuDisabled: true })}>
                                                                    Events
                                                                    </span>
                                                                </li>
                                                            : this.state.pageType === "ChangeEvents" ?
                                                                <li className="nav-item">
                                                                    <span className={`nav-link text-white cursorPointer ${this.state.rightPage === 'change_events' ? ' active' : ''}`} onClick={() => this.setState({ rightPage: 'change_events', menuDisabled: true })}>
                                                                    Events
                                                                    </span>
                                                                </li>
                                                            : null}
                                                            {this.state.selectedShowBy === "Assets" ?
                                                                <li className="nav-item">
                                                                    <span className={`nav-link text-white cursorPointer ${this.state.rightPage === 'Metrics' ? ' active' : ''}`} onClick={() => this.setState({ rightPage: 'Metrics', menuDisabled: true })}>
                                                                        Metrics 
                                                                        {/* {this.state.selectedAsset && this.state.selectedAsset.alerts ?
                                                                            <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">{this.state.selectedAsset.alerts}</span>
                                                                        : null}  */}
                                                                    </span>
                                                                </li>
                                                            : null}
                                                            {/* <li className="nav-item">
                                                                <span className={`nav-link text-white cursorPointer ${this.state.rightPage === 'Events' ? ' active' : ''}`} onClick={() => this.setState({ rightPage: 'Events', menuDisabled: true })}>
                                                                    Alarms
                                                                    {this.state.selectedAsset && this.state.selectedAsset.events ?
                                                                        <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">{this.state.selectedAsset.events}</span>
                                                                    : null} 
                                                                </span>
                                                            </li> */}
                                                        </span>
                                                    </ul>
                                                    <div className={``}>
                                                        {this.state.showRightSide ? 
                                                            this.state.rightPage === 'Metrics' ?
                                                                this.state.selectedShowBy === "Assets" ?
                                                                    <MetricsTab
                                                                        height={115}
                                                                    />
                                                                : null
                                                            : this.state.rightPage === 'Alerts' ?
                                                                this.state.pageType === "Alerts" ?
                                                                    <AlertsTab 
                                                                        menuDisabled={() => this.setState({ menuDisabled: false })}
                                                                    />
                                                                : null
                                                            : this.state.rightPage === 'Anomalies' ?
                                                                this.state.pageType === "Anomalies" ?
                                                                    <AnomalyTab 
                                                                        menuDisabled={() => this.setState({ menuDisabled: false })}
                                                                    />
                                                                : null
                                                            : this.state.rightPage === 'Checks' ?
                                                                (this.state.pageType === "Checks" || this.state.pageType === 'Compliance') ?
                                                                    <ChecksTab 
                                                                        menuDisabled={() => this.setState({ menuDisabled: false })}
                                                                    />
                                                                : null
                                                            : this.state.rightPage === 'Errors' ?
                                                                this.state.pageType === "Errors" ?
                                                                    <ErrorsTab 
                                                                        menuDisabled={() => this.setState({ menuDisabled: false })}
                                                                    />
                                                                : null
                                                            :  this.state.rightPage === 'Events' ?
                                                                (this.state.pageType === "Notifications" || this.state.pageType === "IssueEvents" || this.state.pageType === "LifeCycle") ?
                                                                    <EventsTab 
                                                                        menuDisabled={() => this.setState({ menuDisabled: false })}
                                                                    />
                                                                : null
                                                            :  this.state.rightPage === 'change_events' ?
                                                                this.state.pageType === "ChangeEvents" ?
                                                                    <ChangesTab 
                                                                        menuDisabled={() => this.setState({ menuDisabled: false })}
                                                                    />
                                                                : null
                                                            : null
                                                        : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            : null}
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
LandingPage.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('resources landing page',state)
	return {
        accounts: state.filters.accounts,
		regions: state.filters.regions,
        services: state.filters.services,
    }
}

export default AppWrapper(LandingPage, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
    listAllServices,
    setResourcePropsDetails
})