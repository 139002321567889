/*************************************************
 * Tvastar
 * @exports
 * @file SidePanel.js
 * @author Prakash // on 09/09/2021
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { capitalizeFirstLetter, getAccountNameFromId } from '../../../utils/utility'
import {
    insertMsc,
    updateMsc,
    listMsc
} from '../../../actions/dlp/DlpJobsAction'
import Select from 'react-select'
import { listAllProviders, listAllAccounts } from '../../../actions/commonAction'
import { setDlpPropsDetails } from '../../../actions/dlp/dlpAction'
import { Spinner } from 'reactstrap'
import { store as CommonNotification } from 'react-notifications-component';

import ThreatJobs from './ThreatJobs'
import UebaJobs from './UebaJobs'
import DataEventsProcessor from './DataEventsProcessor'
import DataEventsConsolidator from './DataEventsConsolidator'
import AccessLogs from './AccessLogs'
import DataEventsAggregator from './DataEventsAggregator'
import InventoryProcessorDaily from './InventoryProcessorDaily'

class SidePanel extends Component {
    hierarichalBucketBarChartRef = React.createRef()
    constructor(props) {
        super(props)
        this.props = props;
        this.state = {
            jobTypes: [
                {label: 'Threat', value:'dlp_threats_job'},
                {label: 'Ueba', value:'dlp_ueba_job'},
                {label: 'Events Processor', value:'dlp_data_events_processor'},
                {label: 'Events Consolidator', value:'data_events_consolidator'},
                {label: 'Access Logs', value:'dlp_access_logs_processor'},
                {label: 'Aggregator Daily', value:'dlp_data_events_aggregator_daily'},
                {label: 'Inventory Daily', value:'dlp_inventory_processor_daily'}
            ]
        }
    }

    componentDidMount = () => {       
        document.addEventListener('keydown', this._handleKeyDown) 
        this.getProviders()
        if(this.props.pageType === 'edit' || this.props.pageType === 'view') {
            let selectedRecord = this.props.selectedRecord
            let name = selectedRecord.name
            let description = selectedRecord.description ? selectedRecord.description : ''            
			let selectedProvider = selectedRecord.provider
			let selectedAccount = selectedRecord.account_id
            let selectedJob = selectedRecord.job_type
            if(selectedRecord.job_type === 'dlp_data_events_consolidator_hourly' || selectedRecord.job_type ==='dlp_data_events_consolidator_daily') {
                selectedJob = 'data_events_consolidator'
            }

            this.setState({ name, description, selectedProvider, selectedAccount, selectedJob })
        }
    }

    _handleKeyDown = event => {
		switch (event.keyCode) {
			case 27:
				this.props.closeViewSidePanel()
				break
			default:
				break
		}
	}

    getProviders = () => {
        if(!this.props.providers || !this.props.providers.length) {
            this.props.listAllProviders((promise, response) => {
                if (promise) {
                    let selectedProvider = response.length ? response[0].provider_name : ''
                    this.setState({ selectedProvider },
                        () => {
                            this.getAccounts()
                        }
                    )
                }
            })
        } else {
            let selectedProvider = this.props.providers.length ? this.props.providers[0].provider_name : ''
            this.setState({ selectedProvider },
                () => {
                    this.getAccounts()
                }
            )
        }

    }

    getAccounts = () => {
        let accounts = []
        if(!this.props.accounts || !this.props.accounts.length) {
            let params = {}
            if(this.state.selectedProvider && this.state.selectedProvider !== '') {
                params.provider = this.state.selectedProvider.toLowerCase()
            }
            this.props.listAllAccounts(params, (promise, response) => {                
                if (promise) {
                    if(this.state.selectedProvider && this.state.selectedProvider !== '') {
                        accounts = response.filter(acc => acc.provider === this.state.selectedProvider.toLowerCase())
                    } else {
                        accounts = response
                    }   
                    this.setState({ accounts })
                }
            })
        } else {
            if(this.state.selectedProvider && this.state.selectedProvider !== '') {
                accounts = this.props.accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
            } else {
                accounts = this.props.accounts
            }
            this.setState({ accounts })
        }
        
    }    

	listMsc = () => {
        let params = {}
        params.job_type = ['dlp_threats_job', 'dlp_ueba_job', 'dlp_data_events_processor', 'dlp_data_events_consolidator_hourly', 'dlp_data_events_consolidator_daily', 'dlp_access_logs_processor', 'dlp_data_events_aggregator_daily', 'dlp_inventory_processor_daily']
        this.props.listMsc(params, (promise, response) => {})
    }
    
    actionPorcess = () => {
        let hasError = false
        let nameErrorMessage = ''
        if(!this.state.name || this.state.name === '') {
            hasError = true
            nameErrorMessage = 'Please enter configuration name'
        } else {
            //check watemark name already exist
            if(this.props.pageType === 'create') {
                let checkNameExist = this.props.dlpJobsList.filter(arr => arr.name === this.state.name)
                if(checkNameExist && checkNameExist.length) {
                    hasError = true
                    nameErrorMessage = 'Configuration name already exist'
                }
            } else if(this.props.pageType === 'edit') {
                let checkNameExist = this.props.dlpJobsList.filter(arr => arr.name === this.state.name && arr.msc_id !== this.props.selectedRecord.msc_id)
                if(checkNameExist && checkNameExist.length) {
                    hasError = true
                    nameErrorMessage = 'Configuration name already exist'
                }
            }
        }

        if(!this.state.selectedJob || this.state.selectedJob === '') {
            hasError = true
        }

        let erroMessage = ''
        if(!this.state.selectedProvider || this.state.selectedProvider === '') {
            // hasError = true
        }

        if(!this.state.selectedAccount || this.state.selectedAccount === '') {
            // hasError = true
        }

        if(!this.state.jobData) {
            hasError = true
        } else if(!hasError) {
            hasError = this.state.hasJobError
        }
        
        this.setState({ hasError, nameErrorMessage, erroMessage, showActionLoading: !hasError })

        this.props.setDlpPropsDetails('hasJobError', hasError)

        if(!hasError) {
            let params = {}
            params.name = this.state.name
            params.description = this.state.description
            params.provider = this.state.selectedProvider.toLowerCase()
            params.account_id = this.state.selectedAccount            
            
            let configuration_text = 'Configure '+ this.state.selectedJob +' jobs for provider '+this.state.selectedProvider+' account ID '+this.state.selectedAccount

            this.state.jobData && Object.entries(this.state.jobData).forEach(([key, value]) => {
				// this.setState({ [key]: value })
				params[key] = value
			})

            configuration_text += this.state.jobText

            params.configuration_text = configuration_text

            console.log('params', params)
            if(this.props.pageType === 'create') {
                params.mandatory = true
                this.props.insertMsc(params, (promise, response) => {
                    if(promise) {                        
                        let messageType = 'danger'		
                        let message = 'Check the data'
                        if(response.status && response.status.message && response.status.message === 'inserted') {
                            messageType = 'success'
                            message = 'Saved Successfully'
                        }
                        CommonNotification.addNotification({
                            message: message,
                            type: messageType,
                            insert: "top",
                            container: "top-center",
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
                        
                        if(response.status && response.status.message && response.status.message === 'inserted') {
                            this.setState({ showActionCompleted: true },
                                () => {
                                    setTimeout(() => { this.listMsc() }, 3000)
                                    setTimeout(() => { this.props.closeViewSidePanel() }, 4000)
                                }
                            )
                        } else {
                            this.setState({ showActionLoading: false })
                        }
                    } else {
                        this.setState({ showActionLoading: false })
                    }
                })
            } else if(this.props.pageType === 'edit') {
                params.msc_id = this.props.selectedRecord.msc_id
                this.props.updateMsc(params, (promise, response) => {
                    if(promise) {						
                        let messageType = 'danger'		
                        let message = 'Check the data'
                        if(response.status && response.status.message && response.status.message === 'updated') {
                            messageType = 'success'
                            message = 'Updated Successfully'
                        }
                        CommonNotification.addNotification({
                            message: message,
                            type: messageType,
                            insert: "top",
                            container: "top-center",
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
                        
                        if(response.status && response.status.message && response.status.message === 'updated') {
                            this.setState({ showActionCompleted: true },
                                () => {
                                    setTimeout(() => { this.listMsc() }, 3000)
                                    setTimeout(() => { this.props.closeViewSidePanel() }, 4000)
                                }
                            )
                        } else {
                            this.setState({ showActionLoading: false })
                        }
                    } else {
                        this.setState({ showActionLoading: false })
                    }
                })
            }
        }
    }

    jobData = (data, jobText, hasJobError) => {
        this.setState({ jobData: data, jobText, hasJobError })
    }

    getSelectedJobLabel = () => {
        let selectedJobLabel = ''
        this.state.jobTypes.forEach(item => {
            if(item.value === this.state.selectedJob) {
                selectedJobLabel = item.label
            }
        })
        return selectedJobLabel
    }

    render() {		
        return (
            <div className={`advanced-search`}>
				<div className={`search-content ${(this.state.showActionLoading) ? 'disabled' : ''}`}>
                    <div className="header-search flex-column bd-highlight">
                        <div class="w-100 d-flex">
                            <div class="flex-fill bd-highlight">
                                <h5 class="f20 font-weight-bold">{capitalizeFirstLetter(this.props.pageType)+' Jobs'}</h5>
                            </div>
                            <div class="text-right flex-fill bd-highlight">
                                <i className="far fa-times" onClick={() => this.props.closeViewSidePanel()}></i>
                            </div>
                        </div>
                    </div>
                    
                    <div className="container-fluid overflow-auto bg-muted h-100">
                        {!this.state.showLoading ?
                            <React.Fragment>
                            <div className={`bg-dark p-3 text-white mt-3 rounded`}>
                                <div className={`${this.props.pageType === 'view' ? 'disabled disableInputColor' : ''}`}>
                                    <div className={`form-group`}>
                                        <p className="m-0">Configuration Name</p>
                                        <input 
                                            type="text" 
                                            className="form-control col-sm-6"
                                            value={this.state.name ? this.state.name : ''}
                                            onChange={e => this.setState({ name: e.target.value })}
                                        />
                                        {this.state.hasError && this.state.nameErrorMessage !== '' ?
                                            <p className='m-0 text-danger align-self-end mb-1'>{this.state.nameErrorMessage}</p>
                                        : null}
                                    </div>
                                    
                                    <div className={`form-group`}>
                                        <p className="m-0">Description</p>
                                        <textarea
                                            className="form-control bg-gray3 border-black6"
                                            onChange={(event) => this.setState({ description: event.target.value })} 
                                            value={this.state.description}
                                            maxLength={200}
                                        />
                                    </div>
                                    <div className={`form-group dlpDescriptionForm`}>
                                        <div className="d-flex flex-wrap">
                                            <p className="mb-0 mr-3 align-self-end text-info mt-3">Configure</p>
                                            <Select
                                                placeholder={'Select'}
                                                isSearchable={false}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                className={`selectOption mr-2 mt-3 w-30 ${this.state.hasError && !this.state.selectedJob ? 'dottedDangerSelectLine' : ''}`}
                                                value={({
                                                    value: this.state.selectedJob,
                                                    label: this.state.selectedJob ? this.getSelectedJobLabel() : 'Select'
                                                })}
                                                options={this.state.jobTypes.length && this.state.jobTypes.map(item => ({
                                                    value: item.value,
                                                    label: item.label,	
                                                }))}
                                                onChange={event =>  this.setState({ selectedJob: event.value })}
                                            />
                                            <p className="mb-0 ml-3 align-self-end text-info mt-3">for</p>
                                        </div>
                                        {this.state.selectedJob ? 
                                            <div className={`form-group dlpDescriptionForm`}>
                                                <div className="d-flex flex-wrap">
                                                    <p className="mb-0 mr-2 align-self-end text-info mt-3">Provider</p>
                                                    <Select
                                                        placeholder={'Select'}
                                                        isSearchable={false}
                                                        components={{
                                                            IndicatorSeparator: () => null
                                                        }}
                                                        className={`selectOption mr-2 mt-3 ${this.state.hasError && !this.state.selectedProvider ? 'dottedDangerSelectLine' : ''}`}
                                                        value={({
                                                            value: this.state.selectedProvider,
                                                            label: this.state.selectedProvider ? this.state.selectedProvider.toUpperCase() : 'Select' 
                                                        })}
                                                        options={this.props.providers.length && this.props.providers.map(item => ({
                                                            value: item.provider_name,
                                                            label: item.provider_name,	
                                                        }))}
                                                        onChange={event =>  this.setState({ selectedProvider: event.value }, () => this.getAccounts())}
                                                    />
                                                    <p className="mb-0 mr-2 align-self-end text-info mt-3">Account</p>
                                                    <Select
                                                        placeholder={'Select'}
                                                        isSearchable={false}
                                                        components={{
                                                            IndicatorSeparator: () => null
                                                        }}
                                                        className={`selectOption mr-2 mt-3 ${this.state.hasError && !this.state.selectedAccount ? 'dottedDangerSelectLine' : ''}`}
                                                        value={({
                                                            value: this.state.selectedAccount,
                                                            label: this.state.selectedAccount ? getAccountNameFromId(this.state.selectedAccount, this.state.accounts): 'Select'
                                                        })}
                                                        options={this.state.accounts && this.state.accounts.length && this.state.accounts.map(item => ({
                                                            value: item.account_id,
                                                            label: item.account_name,	
                                                        }))}
                                                        onChange={event =>  this.setState({ selectedAccount: event.value })}
                                                    />
                                                </div>
                                            </div>    
                                        : null}
                                        {this.state.selectedJob === 'dlp_threats_job' ?
                                            <ThreatJobs 
                                                pageType={this.props.pageType}
                                                selectedRecord={this.props.selectedRecord}
                                                jobData={(data, jobText, hasJobError) => this.jobData(data, jobText, hasJobError)}
                                             />
                                        : this.state.selectedJob === 'dlp_ueba_job' ?
                                            <UebaJobs 
                                                pageType={this.props.pageType}
                                                selectedRecord={this.props.selectedRecord}
                                                jobData={(data, jobText, hasJobError) => this.jobData(data, jobText, hasJobError)}
                                            />
                                        : this.state.selectedJob === 'dlp_data_events_processor' ?
                                            <DataEventsProcessor 
                                                pageType={this.props.pageType}
                                                selectedRecord={this.props.selectedRecord}
                                                jobData={(data, jobText, hasJobError) => this.jobData(data, jobText, hasJobError)}
                                             />
                                        : this.state.selectedJob === 'data_events_consolidator' ?
                                            <DataEventsConsolidator 
                                                pageType={this.props.pageType}
                                                selectedRecord={this.props.selectedRecord}
                                                jobData={(data, jobText, hasJobError) => this.jobData(data, jobText, hasJobError)}
                                             />
                                        : this.state.selectedJob === 'dlp_access_logs_processor' ?
                                            <AccessLogs 
                                                pageType={this.props.pageType}
                                                selectedRecord={this.props.selectedRecord}
                                                jobData={(data, jobText, hasJobError) => this.jobData(data, jobText, hasJobError)}
                                             />
                                        : this.state.selectedJob === 'dlp_data_events_aggregator_daily' ?
                                            <DataEventsAggregator 
                                                pageType={this.props.pageType}
                                                selectedRecord={this.props.selectedRecord}
                                                jobData={(data, jobText, hasJobError) => this.jobData(data, jobText, hasJobError)}
                                            />
                                        : this.state.selectedJob === 'dlp_inventory_processor_daily' ?
                                            <InventoryProcessorDaily 
                                                pageType={this.props.pageType}
                                                selectedRecord={this.props.selectedRecord}
                                                jobData={(data, jobText, hasJobError) => this.jobData(data, jobText, hasJobError)}
                                            />
                                        : null}
                                    </div>
                                </div>
                                <div className={`border-top pt-2 ${this.state.hasError && this.state.errorMessage !== '' ? '' : 'mt-5'}`}>
                                    <button className='btn btn-light mr-1' onClick={() => this.props.closeViewSidePanel()}>Cancel</button>
                                    {this.props.pageType.toLowerCase() !== 'view' ?
                                        !this.state.showActionCompleted ?
                                            this.state.showActionLoading ?
                                                <button className='btn btn-primary' onClick={() => this.actionPorcess()}>
                                                    <Spinner size='sm' className='mx-2' color='white' /> {this.props.pageType.toLowerCase() === 'create' ? 'Creating ... ' : 'Updating ...' }
                                                </button>
                                            : 
                                                <button className='btn btn-primary' onClick={() => this.actionPorcess()}>{this.props.pageType.toLowerCase() === 'create' ? 'Create' : 'Update' }</button>
                                        :
                                            <button className='btn btn-primary disabled'>
                                                {this.props.pageType.toLowerCase() === 'create' ? 'Created' : 'Updated' }
                                            </button>
                                    : null}
                                </div>
                            </div>
                            </React.Fragment>
                        :
                            <div className='text-center mt-5' >
                                <Spinner color='white' size='lg' />
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}


/**
 * Type of the props used in the component
 */
 SidePanel.propTypes = {
    setDlpPropsDetails: PropTypes.func,
}

const mapStateToProps = state => {
    return {
		providers: state.filters.providers,
		accounts: state.filters.accounts,
		dlpJobsList: state.dlp.dlpJobsList
    }
}

export default connect(mapStateToProps, {
    listMsc,
    listAllProviders,
    listAllAccounts,
    insertMsc,
    updateMsc,
    setDlpPropsDetails,
})(withRouter(SidePanel))