/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file RcaLeftSection.js
 * @author Prakash // on 07/10/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component  } from 'react'
import { Link } from 'react-router-dom'
import { URL_PATH } from '../../../config/urlPath'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Search from '../../common/SearchComponent'
import SearchWithHiddenInput from '../../common/SearchWithHiddenInput'

import { momentConvertionUtcToLocalTime, capitalizeFirstLetter, getAccountNameFromId } from '../../../utils/utility'

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

// import { listAllAccounts, listAllRegions } from '../../../actions/commonAction'
import { setMicroServicePropsDetails } from '../../../actions/aiops/MicroServicesAction'


class RcaLeftSection extends Component {
	diagnosticsSlider = React.createRef()

    constructor(props) {
        super(props)

        this.state = {
            minMaxHeight: "700px",
            
            microServiceRca: this.props.microServiceRca,
			microservice_rca_block_0: true,
        }
    }
    
    componentDidMount = () => {
        if (this.props.location && this.props.location.state && Object.keys(this.props.location.state).length !== 0) {
            this.setState({ diagnostics: this.props.location.state.diagnostics })
        }

        this.getWindowSize()
        window.addEventListener("resize", () => {
            this.getWindowSize()
        })
        let rcaDetails = _.orderBy(this.props.microServicesRcaDetails, ['score'], ['desc'])
        this.setState({ 
            microServicesRcaDetails: rcaDetails,
            filteredMicroServicesRcaDetails: rcaDetails
        },
            () => this.onLoad(this.state.microServicesRcaDetails && this.state.microServicesRcaDetails.length ? this.state.microServicesRcaDetails[0] : {})
        )

    }
    componentDidUpdate = (prevProps) => {
        if(!_.isEqual(prevProps.microServicesRcaDetails, this.props.microServicesRcaDetails) && this.props.microServicesRcaDetails && this.props.microServicesRcaDetails.length) {
            let rcaDetails = _.orderBy(this.props.microServicesRcaDetails, ['score'], ['desc'])
            this.setState({ 
                microServicesRcaDetails: rcaDetails,
                filteredMicroServicesRcaDetails: rcaDetails 
            },
                () => this.onLoad(this.state.microServicesRcaDetails && this.state.microServicesRcaDetails.length ? this.state.microServicesRcaDetails[0] : {})
            )
        }
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 90  
		

        let groupSection = 30
        if(this.props.microServicesRcaDetails && this.props.microServicesRcaDetails.length) {
            let groupRowsHeight = Math.ceil(this.props.microServicesRcaDetails.length / 5)
            groupRowsHeight = 44 * groupRowsHeight

            groupSection = groupSection + groupRowsHeight
        }
        let outerHeight = (window.outerHeight - browserBorder - headerHeight - groupSection) +"px"

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }

    onLoad = (microServicesRcaDetails) => {
        let asset_details = []
        if(microServicesRcaDetails.asset_id && microServicesRcaDetails.asset_id !== "") {
            let assetRow = {}
            assetRow.asset_id = microServicesRcaDetails.asset_id ? microServicesRcaDetails.asset_id : ""
            assetRow.asset_name = microServicesRcaDetails.asset_name ? microServicesRcaDetails.asset_name : ""
            assetRow.asset_arn = microServicesRcaDetails.asset_arn ? microServicesRcaDetails.asset_arn : ""
            assetRow.resource_type = microServicesRcaDetails.resource_type ? microServicesRcaDetails.resource_type : ""
            assetRow.service_name = microServicesRcaDetails.service_name ? microServicesRcaDetails.service_name : ""
            assetRow.provider = microServicesRcaDetails.provider ? microServicesRcaDetails.provider : ""
            assetRow.account_id = microServicesRcaDetails.account_id ? microServicesRcaDetails.account_id : ""
            assetRow.region = microServicesRcaDetails.region ? microServicesRcaDetails.region : ""
            asset_details.push(assetRow)
        }

        let assetDetails = Array.isArray(asset_details) && asset_details.length ? asset_details[0] : {}

        let obj = {}
        obj.label = ""
        obj.eventGroup = ""
        obj.flag = ""
        obj.data = {}
        obj.microServicesRcaDetails = microServicesRcaDetails
        obj.asset_details = assetDetails
        obj.tableHeading = ""
        this.props.setMicroServicePropsDetails("selectedMicroServicesRcaEventDetail", obj)
    }    

    onExpandChecks = (item, index, row, rowIndex) => {
        this.setState({ ["rca_checks_"+rowIndex]: !this.state["rca_checks_"+rowIndex] },
            () => {
                if(row.data && row.data.length && this.state["rca_checks_"+rowIndex]) {
                    this.onChangeMicroServiceEvent(item, row, row.data[0], "selected_"+index+"_"+rowIndex+"_0")
                }
            }
        )
    }

    onChangeMicroServiceEvent = (microServicesRcaDetails, row, data, selectedRcaTrouble) => {
        let asset_details = []
        if(data.asset_id && data.asset_id !== "") {
            let assetRow = {}
            assetRow.asset_id = data.asset_id ? data.asset_id : ""
            assetRow.asset_name = data.asset_name ? data.asset_name : ""
            assetRow.asset_arn = data.asset_arn ? data.asset_arn : ""
            assetRow.resource_type = data.resource_type ? data.resource_type : ""
            assetRow.service_name = data.service_name ? data.service_name : ""
            assetRow.provider = data.provider ? data.provider : ""
            assetRow.account_id = data.account_id ? data.account_id : ""
            assetRow.region = data.region ? data.region : ""
            asset_details.push(assetRow)
        } else if(data.assets) {
            asset_details = data.assets
        }

        let assetDetails = Array.isArray(asset_details) && asset_details.length ? asset_details[0] : {}

        let obj = {}
        obj.label = row.label
        obj.eventGroup = row.tag
        obj.flag = row.flag
        obj.data = data
        obj.microServicesRcaDetails = microServicesRcaDetails
        obj.asset_details = assetDetails
        obj.tableHeading = ""
        this.props.setMicroServicePropsDetails("selectedMicroServicesRcaEventDetail", obj)

        this.setState({ selectedRcaTrouble })
    }    

    filterMicroservices = (microservice_id, selectedArray) => {
        let selectedMicroServiceGroup = ""
        
        if(microservice_id === this.state.selectedMicroServiceGroup) {
            selectedArray = this.state.microServicesRcaDetails
        } else {
            selectedArray = [selectedArray]
            selectedMicroServiceGroup = microservice_id
        }

        this.setState({ selectedMicroServiceGroup, filteredMicroServicesRcaDetails: selectedArray })
    }

	render() {
		return (
            <div className="">
                <div className={`loaderOverlay ${this.state.showLoading ? "" : "displayNone"}`}>
                    <div className="overlayEqualizerLoader">
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                    </div>
                </div> 
                <div className="col-md-12 p-3 mt-2">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-self-center">
                            <div className="">
                                <h6 className="mb-1 w-100">Root Cause Analysis</h6>
                                <p className="mb-0">Summary of analysis and observations of the chosen Cloud resources</p>
                            </div>
                        </div>
                        <div className="">
                            <p className="mb-1">
                                {this.props.microServicesRcaDetails && this.props.microServicesRcaDetails.length && this.props.microServicesRcaDetails[0].provider ? this.props.microServicesRcaDetails[0].provider.toUpperCase() : null}
                                {this.props.microServicesRcaDetails && this.props.microServicesRcaDetails.length && this.props.microServicesRcaDetails[0].account_id ? (" : ")+ getAccountNameFromId(this.props.microServicesRcaDetails[0].account_id, this.props.accounts) : null}
                                {this.props.microServicesRcaDetails && this.props.microServicesRcaDetails.length && this.props.microServicesRcaDetails[0].region ?  (" : ")+this.props.microServicesRcaDetails[0].region : null}
                            </p>
                            <small className="mb-0 align-self-center text-info d-flex justify-content-end">{this.props.microServicesRcaDetails && this.props.microServicesRcaDetails.length ? (this.props.microServicesRcaDetails[0].start_time && this.props.microServicesRcaDetails[0].end_time ? momentConvertionUtcToLocalTime(this.props.microServicesRcaDetails[0].start_time, "DD MMM HH:mm") +" - "+momentConvertionUtcToLocalTime(this.props.microServicesRcaDetails[0].end_time, "DD MMM HH:mm") : null) : null}</small>
                        </div>
                    </div>
                </div>
                {this.state.microServicesRcaDetails && this.state.microServicesRcaDetails.length ?
                    <div className="col-md-12 px-3">
                        <p className="my-1 text-primary-color">Showing the microservices observed in <span className="text-info">{this.state.microServicesRcaDetails && this.state.microServicesRcaDetails.microservice_name ? this.state.microServicesRcaDetails.microservice_name : ""}</span></p>
                        <div className={`row ml-1 mt-1 mb-2`}>
                            {this.state.microServicesRcaDetails.map((item, index) => {
                                return(
                                    <div className={`w-20 mb-2 cursorPointer pr-2`}>
                                        <div className={`p-2 bg-dark rounded ${this.state.selectedMicroServiceGroup === item.microservice_id ? "border-info" : "border-gray5"}`} onClick={() => this.filterMicroservices(item.microservice_id, item)}>
                                            <div className="d-flex">
                                                <span className={`small text-white`}>{item.microservice_name ? item.microservice_name : "Others"}<span className={`ml-2 small text-primary-color`}>({item.results && item.results.length})</span></span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                : null}
                <div className="col-md-12 px-3">
                    <div className={`bg-dark3 p-2 overflowYAuto`} style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
                        {this.state.filteredMicroServicesRcaDetails && this.state.filteredMicroServicesRcaDetails.length ?
                            this.state.filteredMicroServicesRcaDetails.map((rcaItm, rcaIndex) => {
                                return(
                                    <div className="rounded bg-dark2 px-3 py-2 my-2">
                                        <div className="d-flex justify-content-between">
                                            <div className='w-90' onClick={() => this.onLoad(rcaItm)}>
                                                <p className='mb-1'>{rcaItm.microservice_name}</p>
                                                <p className='mb-1 text-gray5'>{rcaItm.microservice_rca_description}</p>
                                            </div>
                                            <div className='w-10 d-flex justify-content-between'>
                                                <div className='w-50 d-flex justify-content-end'>
                                                    <CircularProgressbar
                                                        value={parseInt(rcaItm.score * 100)}
                                                        text={rcaItm.score}
                                                        counterClockwise={false}
                                                        styles={{trail: {stroke: "#353C4C",strokeWidth:"8"},path: {stroke: "#039BE5",strokeWidth:"6"},text: {fill: "#C1C1C1",fontSize: "25px"}}}
                                                    />
                                                </div>
                                                <span className={`ml-4 fa fa-angle-${this.state["microservice_rca_block_"+rcaIndex] ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.setState({ ["microservice_rca_block_"+rcaIndex]: !this.state["microservice_rca_block_"+rcaIndex] }, () => this.onLoad(rcaItm))}></span>
                                            </div>
                                        </div>
                                        <div className={`${this.state["microservice_rca_block_"+rcaIndex] ? '' : "displayNone"}`}>
                                            {rcaItm.results.map((item, index) => {
                                                return(
                                                <div className="rounded bg-dark3 border-info px-3 py-2 boxBoxHeadingDesign mt-3">
                                                    <div className='d-flex justify-content-between'>
                                                        <div className="header w-90 d-flex justify-content-between">
                                                            <div className='w-100' onClick={() => this.onLoad(this.state["rca_block_"+index] ? item : rcaItm)}>
                                                                <p className='mb-1'>{item.rca_type}</p>
                                                                <p className='mb-1 text-gray5'>{item.rca_description}</p>
                                                                <p className='mb-1'>Resources {item.asset_name +" : "+ item.service_name} probability {item.probability ? parseFloat(item.probability).toFixed(2) : 0} </p>
                                                            </div>
                                                            {/* <div className='w-10 d-flex justify-content-end displayNone'>
                                                                <CircularProgressbar
                                                                    value={70}
                                                                    text={"70%"}
                                                                    counterClockwise={false}
                                                                    styles={{trail: {stroke: "#353C4C",strokeWidth:"8"},path: {stroke: "#039BE5",strokeWidth:"6"},text: {fill: "#C1C1C1",fontSize: "25px"}}}
                                                                />
                                                            </div> */}
                                                        </div>
                                                        <div className='d-flex justify-content-end align-self-start'>
                                                            <span className={`ml-4 fa fa-angle-${this.state["rca_block_"+index] ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.setState({ ["rca_block_"+index]: !this.state["rca_block_"+index] }, () => this.onLoad(this.state["rca_block_"+index] ? item : rcaItm))}></span>
                                                        </div>
                                                    </div>
                                                    <div className={`mt-3 ${this.state["rca_block_"+index] ? '' : "displayNone"}`}>
                                                        <div className="accordion" id="accordionExample">
                                                            {item.checks && item.checks.length ?
                                                                _.orderBy(item.checks, ['flag'], ['desc']).map((row, rowIndex) => {
                                                                    return(
                                                                        <div className={`card bg-dark border-0 mb-2 overflow-unset`}>
                                                                            <div id={'heading'} onClick={() => this.onExpandChecks(item, index, row, rowIndex) }>
                                                                                <div className="px-3 py-2 mb-0 d-flex justify-content-between">
                                                                                    <p className="mb-0 cursorPointer text-white" onClick={() => this.setState({ show2: !this.stateshow2 })}><span className={`far ${this.state["rca_checks_"+rowIndex] ? "fa-minus-circle" : "fa-plus-circle"} mr-2`} ></span>{row.label}
                                                                                    </p>
                                                                                    <span className={`${row.flag ? "text-purplishRed" : "text-success"} cursorPointer`}>{row.flag ? "Possible root cause detected." : "No causal observations detected."} </span>
                                                                                </div>
                                                                            </div>
                                                                            <div id={'collapse'} className={`collapse ${this.state["rca_checks_"+rowIndex] ? 'show' : ""}`}>
                                                                                <div className="card-body">
                                                                                    {row.data && row.data.length ?
                                                                                        row.data.map((trb, trbIndex) => {
                                                                                            return(
                                                                                                <div className={`row mt-1 ${this.state.selectedRcaTrouble === "selected_"+index+"_"+rowIndex+"_"+trbIndex ? "bg-muted disabled" : "cursorPointer"}`} onClick={() => this.onChangeMicroServiceEvent(item, row, trb, "selected_"+index+"_"+rowIndex+"_"+trbIndex)}>
                                                                                                    {row.tag === "Traffic" ?
                                                                                                        <div className="col-12 py-2">{/* border-left */}
                                                                                                            <p className="mb-0">
                                                                                                                {trb.asset_name ?
                                                                                                                    <span className="mb-0 mr-2 f12 text-white font-weight-bold">{trb.asset_name}</span>
                                                                                                                : null}
                                                                                                                <span className="mb-0 small mr-1 text-primary-color">Resource</span>
                                                                                                                {trb.account_id ?
                                                                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold">{' '+trb.account_id}</span>
                                                                                                                : null}
                                                                                                                {trb.region ?
                                                                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+trb.region}</span>
                                                                                                                : null}
                                                                                                                {trb.resource_type ?
                                                                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+(Array.isArray(trb.resource_type) ? trb.resource_type.join(', ') : trb.resource_type)}</span>
                                                                                                                : null}
                                                                                                                {trb.service_name ?
                                                                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold mr-2">{' : '+trb.service_name}</span>
                                                                                                                : null}

                                                                                                                <span className="mr-1 text-white">Detected a traffic surge in the</span>
                                                                                                                <span className="mr-1 text-white">{(trb.metric_name ? trb.metric_name : "")} metric, </span> 
                                                                                                                {/* <span className="mr-1 text-white">{(trb.asset_name ? trb.asset_name : "")}</span> */}
                                                                                                                <span className="mr-1 text-white">following a Transient </span>
                                                                                                                <span className="mr-1 text-white">{(trb.pattern ? trb.pattern : "")} pattern and having a </span>
                                                                                                                <span className={`mr-1 badge ${trb.severity ? "risk-badge-"+(trb.severity ? trb.severity.toLowerCase() : "") : ""}`}>{(trb.severity ? trb.severity : "")}</span>
                                                                                                                <span className="mr-1 text-white">severity</span>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    : 
                                                                                                        <div className="col-12 py-2">{/* border-left */}
                                                                                                            <div className="d-flex">
                                                                                                                {trb.severity ?
                                                                                                                    <span className={`f10 mr-2 align-self-start mt-1 badge-sm risk-badge-${trb.severity ? trb.severity.toLowerCase() : 'critical'}`}>
                                                                                                                        {trb.severity ? 
                                                                                                                            (trb.severity.toLowerCase() === 'critical' ? 'C' : trb.severity.toLowerCase() === 'high' ? 'H' : trb.severity.toLowerCase() === 'medium' ? 'M' : 'L') 
                                                                                                                        : 'U'}
                                                                                                                    </span>
                                                                                                                : null}
                                                                                                                <p className="mb-0">
                                                                                                                    {trb.event_time ?
                                                                                                                        <span className="mb-0 mr-2 text-white f12 font-weight-bold text-primary-color">{trb.event_time ? momentConvertionUtcToLocalTime(trb.event_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                                                                    : null}
                                                                                                                    {trb.asset_name ?
                                                                                                                        <span className="mb-0 mr-2 f12 text-white font-weight-bold">{trb.asset_name}</span>
                                                                                                                    : null}
                                                                                                                    <span className="mb-0 small mr-1 text-primary-color">Resource</span>
                                                                                                                    {trb.account_id ?
                                                                                                                        <span className="mb-0 f12 text-primary-color font-weight-bold">{' '+trb.account_id}</span>
                                                                                                                    : null}
                                                                                                                    {trb.region ?
                                                                                                                        <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+trb.region}</span>
                                                                                                                    : null}
                                                                                                                    {trb.resource_type ?
                                                                                                                        <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+(Array.isArray(trb.resource_type) ? trb.resource_type.join(', ') : trb.resource_type)}</span>
                                                                                                                    : null}
                                                                                                                    {trb.service_name ?
                                                                                                                        <span className="mb-0 f12 text-primary-color font-weight-bold mr-2">{' : '+trb.service_name}</span>
                                                                                                                    : null}
                                                                                                                    {trb.description ?
                                                                                                                        <span className="mb-0 ml-1 f12 text-white font-weight-bold">{trb.description}</span>
                                                                                                                    : null}
                                                                                                                </p>
                                                                                                            </div>
                                                                                                            {/* <span className={`far fa-exclamation-triangle ${trb.flag !== "undefined" ? (trb.flag ? "text-purplishRed" : "text-success") : row.flag ? "text-purplishRed" : "text-success"} ml-2 align-self-center`}></span> */}
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })
                        : null}
                    </div>
                </div>
            </div>
		)
	}
}
/**
 * Type of the props used in the component
 */
RcaLeftSection.propTypes = {
    setMicroServicePropsDetails: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
	// console.log("state", state.observability)
	return {
        accounts: state.filters.accounts,
        regions: state.filters.regions && state.filters.regions[0] && state.filters.regions[0].provider_regions,
        microServicesRcaDetails: state.observability.observabilityMicroServicesRcaDetails ? state.observability.observabilityMicroServicesRcaDetails : {},
    }
}

export default connect(mapStateToProps, {
	// listAllAccounts,
	// listAllRegions,
    setMicroServicePropsDetails,
})(withRouter(RcaLeftSection))