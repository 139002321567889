/*************************************************
 * Tvastar
 * @exports
 * @file Register.js
 * @author Rajasekar // on 19/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'

import logo from '../../assets/images/logo.svg'
import { validateEmail, validatePassword } from '../../utils/utility'
import { signup } from '../../actions/registerAction'
import { URL_PATH } from '../../config/urlPath'

class Register extends Component {
	constructor(props) {
		super(props)
		this.state = {
			firstName:'',
			lastName:'',
			phone:'',
			company:'',
			email: '',
			password: '',
			hasError: false,
			onLoading: false,
			showRegisterErrorMessage: '',
			showRegisterSuccessScreen: false,
			
		}
	}

	/**
	 * Validates the user input and register user
	 */
	onRegister = () => {
		this.setState({ hasError: false })
		if (
			this.state.firstName === '' ||
			this.state.lastName === '' ||
			this.state.company === '' ||
			this.state.password === '' ||
			!validateEmail(this.state.email) ||
			//(this.state.phone !== '' && !validatePhone(this.state.phone)) ||
			!validatePassword(this.state.password)
		) {
			this.setState({ hasError: true })
		} else {
			this.setState({ onLoading: true })
			const params = {
				emailAddress: this.state.email,
				orgName:  this.state.company,
				firstName:  this.state.firstName,
				lastName:  this.state.lastName,
				password:  this.state.password,
				phoneNumber:  this.state.phone
			}
			console.log(params)
			this.props.signup(params, (promise, result) => {
				console.log(result)
				this.setState({ onLoading: false })
				if (promise) {
					this.setState({ showRegisterSuccessScreen: true, onLoading: false })
				} else {
					this.setState({ showRegisterErrorMessage: result, onLoading: false  })
				}
			})
		}
	}

	/**
	 * Renders register screen design
	 */
	render() {
		const {
			hasError,
			onLoading,
			firstName,
			lastName,
			phone,
			company,
			email,
			password,
			// confirmPassword,
			showRegisterSuccessScreen,
			// showRegisterErrorMessage,
		} = this.state
		return (
			<div className="d-flex position-fixed overflow-auto h-100 w-100">
				<div className="flex-grow-1 bg-dark text-white p-5 d-sm-flex d-none sign-bubbles">
					<div className="align-self-center">
						<Link to={URL_PATH.REGISTER} className='d-block text-center mb-2'>
							<img src={logo} alt='' />
						</Link>
						<h5>Welcome to the Tvaṣṭṛ</h5>
						<p>Magnam nihil quis aut corporis recusandae. Sint voluptate rem animi in porro. Molestias voluptatum est.</p>
						<table className="mt-4">
							<tr>
								<td className="align-top pr-3">
								<h5 className="m-0"><i className="far fa-network-wired"></i></h5>
								</td>
								<td>
								<h5>Joslin Rodgers</h5>
								<p>Quis aut corporis recusandae. Sint voluptate rem animi in porro. Molestias voluptatum est.</p>
								</td>
							</tr>
							<tr>
								<td className="align-top pr-3">
								<h5 className="m-0"><i className="far fa-wifi"></i></h5>
								</td>
								<td>
								<h5>Diego Morata</h5>
								<p>Quis aut corporis recusandae. Sint voluptate rem animi in porro. Molestias voluptatum est.</p>
								</td>
							</tr>
							<tr>
								<td className="align-top pr-3">
								<h5 className="m-0"><i className="far fa-chart-network"></i></h5>
								</td>
								<td>
								<h5>Ava Gregoraci</h5>
								<p>Quis aut corporis recusandae. Sint voluptate rem animi in porro. Molestias voluptatum est.</p>
								</td>
							</tr>
						</table>
					</div>
				</div>
				<div className="p-2 d-flex flex-column login">
					<div className="w-75 m-auto">
						{showRegisterSuccessScreen ? (
							<div className='text-center'>
								<h3 className='text-success'>
									<i className='fas fa-check-circle signup-success-tick-icon' />
								</h3>
								<p className='text-success mt-3'>Registaration Successful, please verify your email address before login to Tvaṣṭṛ</p>
								<p>
									Click here to <Link to={URL_PATH.LOGIN}>login</Link>
								</p>
							</div>
						) : (
							<React.Fragment>
							<h4 className="text-left textInfo">Create Account</h4>
							<p className="text-muted">Sint voluptate rem animi in porro. Molestias voluptatum est.</p>
							<div className="row">
								<div className="col-sm-6">
									<div className='form-group'>
										<label>Firstname*</label>
										<input
											type='text'
											className={`form-control ${hasError && firstName === '' ? 'is-invalid' : firstName === '' ? '' : 'is-valid' }`}
											placeholder='First Name'
											value={firstName}
											onChange={event => this.setState({ firstName: event.target.value })}
										/>
										<div className='invalid-feedback'>Please enter a valid first name.</div>
									</div>
								</div>
								<div className="col-sm-6">
									<div className='form-group'>
										<label>Lastname*</label>
										<input
											type='text'
											className={`form-control ${hasError && lastName === '' ? 'is-invalid' : lastName === '' ? '' : 'is-valid'}`}
											placeholder='Last Name'
											value={lastName}
											onChange={event => this.setState({ lastName: event.target.value })}
										/>
										<div className='invalid-feedback'>Please enter a valid last name.</div>
									</div>
								</div>
							</div>
							<div className='form-group'>
								<label>Business Email*</label>
								<input
									type='email'
									className={`form-control ${
										(hasError && email === '') || (hasError && !validateEmail(this.state.email)) ? 'is-invalid' : email === '' ? '' : !validateEmail(this.state.email) ? 'is-invalid' : 'is-valid'
									}`}
									placeholder='Email'
									value={email}
									onChange={event => this.setState({ email: event.target.value })}
								/>
								<div className='invalid-feedback'>Please enter a valid email.</div>
							</div>
							<div className='form-group'>
								<label>Phone</label>
								<input
									type='text'
									className={`form-control`}
									placeholder='Phone Number'
									value={phone}
									onChange={event => this.setState({ phone: event.target.value })}
								/>
								<div className='invalid-feedback'>Please enter a valid phone number.</div>
							</div>
							<div className='form-group'>
								<label>Company*</label>
								<input
									type='text'
									className={`form-control ${hasError && company === '' ? 'is-invalid' : company === '' ? '' : 'is-valid'}`}
									placeholder='Company Number'
									value={company}
									onChange={event => this.setState({ company: event.target.value })}
								/>
								<div className='invalid-feedback'>Please enter a valid company name.</div>
							</div>
							<div className='form-group'>
								<label>Password*</label>
								<input
									type='password'
									className={`form-control ${
										(hasError && password === '') || (hasError && !validatePassword(this.state.password))
											? 'is-invalid'
											: password === '' ? '' : password.length < 8 ? 'is-invalid' : 'is-valid'
									}`}
									placeholder='Password'
									value={password}
									onChange={event => this.setState({ password: event.target.value })}
								/>
								<div className='invalid-feedback'>Please enter a valid password.(Min 8 characters with atleast one number, one special character, one upper case letter and one lower case)</div>
							</div>
							<div className='text-danger mb-2'>{this.state.showRegisterErrorMessage}</div>
							<p className="mt-2 mb-2">By clicking Register, you agree to our Terms, Data Policy and Cookie Policy</p>
							<div className="d-flex mt-3">
								<button className='btn btn-primary mr-3' onClick={() => this.onRegister()} disabled={onLoading}>
									{onLoading ? 'Registering...' : 'Register'}
								</button>
								<p className="align-self-center m-0 d-flex">Already have an account?  <Link className='text-center d-block' to={URL_PATH.LOGIN}>&nbsp;Login</Link></p>
							</div>
							</React.Fragment>
						)}
					</div>
					<div className="text-center mt-auto">
						<p className="text-muted small mb-0">Terms and Conditions  |  Privacy Policy</p>
						<p className="text-muted small">© {moment().format('YYYY')}. All rights reserved.</p>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
Register.propTypes = {
	signup: PropTypes.func,
}

export default connect(null, { signup })(Register)
