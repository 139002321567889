/*************************************************
 * Tvastar
 * @exports
 * @file FilterSidePanel.js
 * @author Prakash // on 09/12/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _, { filter } from 'lodash'
// import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { store as CommonNotification } from 'react-notifications-component';

import { URL_PATH } from '../../../config/urlPath'

import { listAllAccounts, listAllRegions } from '../../../actions/commonAction'
import { generateIamPolicy, getMasterGovernanceRules, setNewInitialRuleDetails, editGovernancePolicy } from '../../../actions/governance/newGovernanceAction'

import { getAccountNameFromId, capitalizeFirstLetter } from '../../../utils/utility'

import SearchWithHiddenInput from '../../common/SearchWithHiddenInput'

import RdsServiceEnginesTemplate from './pages/RDS/RdsServiceEnginesTemplate'
import ServiceLogs from './pages/RDS/ServiceLogs'
import ServiceCapacity from './pages/RDS/ServiceCapacity'
import ServiceBlockStore from './pages/RDS/ServiceBlockStore'

import DynamicServices from './pages/RDS/DynamicServices'

class FilterSidePanel extends Component {
	constructor(props) {
		super(props)
		this.state = {
            minMaxHeight: 700,
			governanceTypes: [
				{label: "Infrastructure Policy", value: "infra"},
				{label: "Access Managment Policy", value: "access"},
			],

			actionMethods: [
				{value: "immediately", label: "Immediately after the violation"},
				{value: "run_at", label: "At"},
				{value: "run_after", label: "After"},
			]
		}
	}

	componentDidMount = () => {
		document.addEventListener('keydown', this._handleKeyDown)
		this.getWindowSize()
		window.addEventListener('resize', () => {
            this.getWindowSize()
        })

		this.structureServices()
		this.getAccounts()
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
        let headerHeihgt = 55	
		let outerHeight = (window.outerHeight - browserBorder - headerHeihgt)

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }

	getAccounts = () => {
		let provider = this.props.editNewRuleDetails && this.props.editNewRuleDetails.provider ? this.props.editNewRuleDetails.provider : ""
		let params = {}
		params.provider = provider
		// if(this.props.accounts && this.props.accounts.length) {
		// 	let accounts = this.props.accounts
		// 	if(provider !== "") {
		// 		accounts = this.props.accounts.filter(account => account.provider === provider.toLowerCase())
		// 	}
		// 	let selectedAccount = accounts.length ? accounts[0].account_id : ""
		// 	this.setState({ accounts, filteredAccounts: accounts, selectedAccount })
		// } else {
		// 	this.props.listAllAccounts(params, (promise, response) => {
		// 		if (promise) {						
		// 			let filteredAccounts = response
		// 			if(provider !== "") {
		// 				filteredAccounts = response.filter(account => account.provider === provider.toLowerCase())
		// 			}
		// 			let selectedAccount = filteredAccounts.length ? filteredAccounts[0].account_id : ""
		// 			this.setState({ accounts: filteredAccounts, filteredAccounts, selectedAccount })
		// 		}
		// 	})
		// }
		this.props.listAllAccounts(params, (promise, response) => {})
	}

	_handleKeyDown = event => {
		switch (event.keyCode) {
			case 27:
				this.props.closeSidePanel()
				break
			default:
				break
		}
	}

	structureServices = () => {
		let policy_metadata = this.props.masterData && this.props.masterData.policy_metadata ? this.props.masterData.policy_metadata : {}
		let policyData = this.props.masterData && this.props.masterData.policy ? this.props.masterData.policy : {}
		let policies = this.props.masterData && this.props.masterData.policy ? [this.props.masterData.policy] : []

		let masterRules = this.props.masterData && this.props.masterData.master_rule ? [this.props.masterData.master_rule] : []
		
		let data = []
		policies && policies.forEach(pol => {
			if(policies.filter(e => e.rule_id === pol.rule_id).length) {
				let dataRow = pol
				if(policies.filter(e => e.rule_id === pol.rule_id)[0].api_to_call) {
					dataRow.api_to_call = policies.filter(e => e.rule_id === pol.rule_id)[0].api_to_call
				}
				data.push(dataRow)
			}
		})

		let obj = policy_metadata
		obj.policies = data
		console.log("obj", obj)
		this.props.setNewInitialRuleDetails(obj)

		let groupPolicies = data ? _.chain(data).groupBy("rule_category").map((value, key) => ({ label: key, ruleCategoryGroupPolicies: value, ruleIdGroup: _.chain(value).groupBy('rule_id').map((value, key) => ({ label: key, policies: value })).value() })).value() : []

		let appliesToArray = [0]
		if(policy_metadata && policy_metadata.applies_to && policy_metadata.applies_to.length) {
			appliesToArray = []
			policy_metadata.applies_to.forEach((row, index) => {
				appliesToArray.push(index)
				this.setState({ 
					["selected_account_"+index]: row.account_id, 
					["selected_region_"+index]: (row.region ? row.region : []),
				})

				if(policyData.governance_type && policyData.governance_type === "infra") {
					this.setState({ ["selected_tags_"+index]: (row.tags ? row.tags : [])  })
				} else if(policyData.governance_type && policyData.governance_type === "infra") {
					this.setState({ ["selected_users_"+index]: (row.users ? row.users : []), ["selected_roles_"+index]: (row.roles ? row.roles : [])  })
				}
			})
		}
		
		let notifications = policy_metadata && policy_metadata.notifications ? policy_metadata.notifications : []
		let notificationArray = [0]
		if(notifications.length) {
			notificationArray = []
			notifications.forEach((row, index) => {
				notificationArray.push(index)
				this.setState({ 
					["selected_notfication_type_"+index]: row.notfication_type, 
					["shared_details_"+index]: (row.notification_id ? row.notification_id : [])
				})
			})
		}

		this.setState({ policy_metadata, policyData: policies, appliesToArray, notificationArray, groupPolicies, newGroup: data, masterRules })
	}

	generateIamPolicy = () => {
		let params = this.props.editNewRuleDetails
		this.props.generateIamPolicy(params, (promise, response) => {
			if(promise) {
				this.setState({ generatedIamPoilcy: response },
					() => this.structureGeneratedPolicy()	
				)
			} else {
				this.setState({ generatedIamPoilcy: {} })
			}
		})
	}

	structureGeneratedPolicy = () => {
		let generatedIamPoilcy = this.state.generatedIamPoilcy
		let accounts = generatedIamPoilcy.map(e => e.account_id)

		let selectedAccount = accounts && accounts.length ? accounts[0] : ""
		let accPolicies = generatedIamPoilcy.map(e => e.account_id === selectedAccount ? e.policy : {})
		this.setState({ accounts, filteredAccounts: accounts, selectedAccount, accPolicies })
	}

    handleClickOutside(event) {}

	validatePolicyInput = (category, boolean, polIndex) => {
		this.setState({ [category]: boolean })
	}

	onFinish = () => {
		let hasError = false
		let data = this.props.editNewRuleDetails

		this.setState({ hasError, onClickFinish: true },
			() => this.validateInput(hasError)	
		)
	}

	validateInput = (hasError) => {
		let hasPolicyError = false

		let policies = this.props.editNewRuleDetails && this.props.editNewRuleDetails.policies ? this.props.editNewRuleDetails.policies : []

		if(!hasPolicyError && !hasError) {
			policies.forEach(pol => {			
				if(pol.variables && pol.variables.length) {
					pol.variables.forEach(varb => {
						if(!hasPolicyError && (!varb.manditory || varb.manditory === "undefined")) {
							hasPolicyError = false
						} else if(!hasPolicyError) {
							if(varb.value && varb.value) {
								hasPolicyError = true
							} else if(varb.value_list && !varb.value_list.length) {
								hasPolicyError = true
							}
						}
					})
				}
			})
		
		}

		if(!hasPolicyError && !hasError) {
			this.setState({ queryLoading: true }, 
				() => this.updatePolicy()
			)
		}
	}

	updatePolicy = () => {
		let params = this.props.editNewRuleDetails
		console.log("updatePolicy",params)
		this.props.editGovernancePolicy(params, (promise, resposne) => {
			if(promise) {
				let messageType = 'success'		
				let message = 'User governance policy updated successfully'
				CommonNotification.addNotification({
					//title: "Wonderful!",
					message: message,
					type: messageType,
					insert: "top",
					container: "top-center",
					// animationIn: ["animate__animated", "animate__fadeIn"],
					// animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
					duration: 5000,
					onScreen: false,
					pauseOnHover: true,
					showIcon: true,			  
					}
				});
				this.setState({ queryLoading: false, finishBtn: 'Saved' },
					() => this.setTimeoutRedirect()
				)
			} else {
				this.setState({ disableFinish : false, finishBtn: 'Save' })
			}
		})
	}
	
	setTimeoutRedirect = (ruleId) => {
		setTimeout(() => {
			this.props.closeSidePanel()
		}, 5000)
	}
	
	render() {		
		return (
			<div className="advanced-search" onClick={(event) => this.handleClickOutside(event)}>
				<div className={`loaderOverlay ${this.state.queryLoading ? "" : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
				<div className="search-content bg-muted w-80">
					<div className="header-search bd-highlight d-flex justify-content-between">
						<div className="flex-fill bd-highlight">
							<h5>Details</h5>
							<div className="mr-2 d-flex">
								{/* <div className="mr-3">
									<p className="m-0 small">Last Scan: 29 Sep 2020 11:10</p>
								</div> */}
								{/* <div className="mr-3">
									<p className="m-0 pl-2 small">Account:  {this.state.selectedAccount ? this.state.selectedAccount: 'All'}</p>
								</div>
								<div className="mr-3">
									<p className="m-0 pl-2 small">Region:  {this.state.selectedRegion ? this.state.selectedRegion: 'All'}</p>
								</div>
								<div>
									<p className="m-0 pl-2 small">Duration: 
										{DURATION_FILTER_OPTIONS.map((item, index) => {
											return (
												this.state.selectedDuration === item.value ?
													item.option
												: null
											)
										})}
									</p>
								</div> */}
							</div>
						</div>

						<div className="text-right flex-fill bd-highlight align-self-center">
							<i className="far fa-times cursorPointer" onClick={() => this.props.closeSidePanel()}></i>
						</div>
					</div>
					<div className={`${!this.state.showLoading ? 'overflow-auto' : ''} p-2`}>
						<div className="rounded bg-dark dlpDescriptionForm dlpDescriptionFormPlain p-3 overflowYAuto" style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
							<div className="d-flex mt-3">
								<div className={`w-100`}>
									<div className="accordion" id="accordionExample">
										<div className={`card bg-dark3 border-0 mb-2`}>
											<div id={'heading_event'} onClick={() => this.setState({ expandPolicyInformation: !this.state.expandPolicyInformation })}>
												<div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
													<div className="text-white"><span className={`far ${this.state.expandPolicyInformation ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Policy Information</div>
													{/* <div className="text-info">Showing event details</div> */}
												</div>
											</div>
											<div id={'collapse_event'} className={`collapse ${this.state.expandPolicyInformation ? 'show' : ''}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
												<div className="card-body bg-dark3 border-0 mb-2 overflow-unset">
													<div className="d-flex mb-2">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Name:</p>
                                                            <p className="mb-0 text-white">{this.state.policy_metadata && this.state.policy_metadata.policy_name ? this.state.policy_metadata.policy_name : ''}</p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Type:</p>
                                                            <p className="mb-0 text-white">{this.state.policy_metadata && this.state.policy_metadata.governance_type && this.state.governanceTypes.filter(e => e.value === this.state.policy_metadata.governance_type).length ? this.state.governanceTypes.filter(e => e.value === this.state.policy_metadata.governance_type)[0].label : ""}</p>
                                                        </div>
                                                    </div>

													<div className="d-flex mb-2 border-top">
                                                        <div className="py-1 w-100">
                                                            <p className="b-block mb-0">Description:</p>
                                                            <p className="mb-0 text-white">{this.state.policy_metadata && this.state.policy_metadata.description ? this.state.policy_metadata.description : ''}</p>
                                                        </div>
                                                    </div>
												</div>
											</div>
										</div>

										<div className={`card bg-dark3 border-0 mb-2`}>
											<div id={'heading_event'} onClick={() => this.setState({ expandAppliesTo: !this.state.expandAppliesTo })}>
												<div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
													<div className="text-white"><span className={`far ${this.state.expandAppliesTo ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Apply Policies</div>
													{/* <div className="text-info">Showing event details</div> */}
												</div>
											</div>
											<div id={'collapse_event'} className={`collapse ${this.state.expandAppliesTo ? 'show' : ''}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
												<div className="card-body bg-dark3 border-0 mb-2 overflow-unset">
													{this.state.appliesToArray && this.state.appliesToArray.map(item => {
														return(
														<div className="p-3 rounded bg-dark2">
															<div className="d-flex mb-2">
																<div className="py-1 w-20">
																	<p className="b-block mb-0">Account:</p>
																	<p className="mb-0 text-white">{this.state["selected_region_"+item] ? this.state["selected_region_"+item] : ''}</p>
																</div>
																<div className="py-1 w-30 pl-3">
																	<p className="b-block mb-0">Region:</p>
																	<p className="mb-0 text-white">
																		{this.state.regions && this.state["selected_region_"+item] && this.state["selected_region_"+item].length ? 
																			this.state["selected_region_"+item].includes("All") || this.state["selected_region_"+item].length === this.state.regions && this.state.regions.length ?
																				<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">All</span>
																			: 
																				this.state["selected_region_"+item].map((row, index) => {
																					return(
																						<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{this.state.regions && this.state.regions.filter(e => e.value === row).length ? this.state.regions.filter(e => e.value === row)[0].label : row}</span>
																					)
																				})
																		: null}
																	</p>
																</div>
																{this.state.policyData && this.state.policyData.governance_type && this.state.policyData.governance_type === "infra" ?
																	<div className="py-1 w-50 pl-3">
																		<p className="b-block mb-0">Tags:</p>
																		<p className="mb-0 text-white">
																			{this.state["selected_tags_"+item] && this.state["selected_tags_"+item].length ? 
																				this.state["selected_tags_"+item].map(tag => {
																					return(
																						<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{tag.key +" : "+tag.value}</span>
																					)
																				})
																			:
																				<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">All</span>
																			}
																		</p>
																	</div>
																: this.state.policyData && this.state.policyData.governance_type && this.state.policyData.governance_type === "access" ?
																	<div className="py-1 w-50 pl-3 d-flex justify-content-between">
																		<div className="">
																			<p className="b-block mb-0">Users:</p>
																			<p className="mb-0 text-white">
																				{this.state["selected_users_"+item] && this.state["selected_users_"+item].length ? 
																					this.state["selected_users_"+item].map(user => {
																						return(
																							<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{user}</span>
																						)
																					})
																				:
																					<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">All</span>
																				}
																			</p>
																		</div>
																		<div className="">
																			<p className="b-block mb-0">Roles:</p>
																			<p className="mb-0 text-white">
																				{this.state["selected_roles_"+item] && this.state["selected_roles_"+item].length ? 
																					this.state["selected_roles_"+item].map(role => {
																						return(
																							<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{role}</span>
																						)
																					})
																				:
																					<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">All</span>
																				}	
																			</p>
																		</div>
																	</div>
																: null}
															</div>
														</div>
														)
													})}
												</div>
											</div>
										</div>

										<div className={`card bg-dark3 border-0 mb-2`}>
											<div id={'heading_event'} onClick={() => this.setState({ expandNotifications: !this.state.expandNotifications })}>
												<div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
													<div className="text-white"><span className={`far ${this.state.expandNotifications ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Notifications</div>
													{/* <div className="text-info">Showing event details</div> */}
												</div>
											</div>
											<div id={'collapse_event'} className={`collapse ${this.state.expandNotifications ? 'show' : ''}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
												{this.state.notificationArray && this.state.notificationArray.map(item => {
													return(
													<div className="p-3 rounded bg-dark2">
														<div className="d-flex mb-2">
															<div className="py-1 w-20">
																<p className="b-block mb-0">Notify to:</p>
																<p className="mb-0 text-white">{this.state["selected_notfication_type_"+item] ? this.state["selected_notfication_type_"+item] : ''}</p>
															</div>
															<div className="py-1 w-30 pl-3">
																<p className="b-block mb-0">Details:</p>
																<p className="mb-0 text-white">
																	{this.state["shared_details_"+item] && this.state["shared_details_"+item].length ? 
																		this.state["shared_details_"+item].map(share => {
																			return(
																				<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{share}</span>
																			)
																		})
																	: null}
																</p>
															</div>
														</div>
													</div>
													)
												})}
											</div>
										</div>

										{this.state.masterRules && this.state.masterRules.length ?
											this.state.masterRules.map((pol, polIndex) => {
												return(
													<div className={`card bg-dark border-0 mb-2 overflow-unset`}>
														<div className="d-flex justify-content-between text-white border-bottom">
															<div className="d-flex">
																<p className="mb-0 f20 text-warning cursorPointer">{pol.rule_category} policy rule</p>
															</div>
														</div>
														{!pol.template ?
															<DynamicServices
																masterData = {pol}
																resourceType = {pol.resource_type}
																rule_category = {pol.rule_category}
																onClickFinish={this.state.onClickFinish}
																validateInput={(boo, noInputDetected) => this.setState({ onClickFinish: false }, () => this.validatePolicyInput(pol.rule_category, boo, polIndex))}
																selectedModeType={this.state.selectedModeType}
																actionMethods={this.state.actionMethods}
															/>
														: pol.rule_category === "Engine" ?
															<RdsServiceEnginesTemplate 
																masterData = {pol}
																onClickFinish={this.state.onClickFinish}
																validateInput={boo => this.setState({ onClickFinish: false }, () => this.validatePolicyInput(pol.rule_category, boo, polIndex))}
																selectedModeType={this.state.selectedModeType}
																actionMethods={this.state.actionMethods}
															/>
														: pol.rule_category === "Log" ?
															<ServiceLogs
																masterData = {pol}
																onClickFinish={this.state.onClickFinish}
																validateInput={boo => this.setState({ onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" }, () => this.validatePolicyInput(pol.rule_category, boo, polIndex))}
																selectedModeType={this.state.selectedModeType}
																actionMethods={this.state.actionMethods}
															/>
														: pol.rule_category === "BlockStore" ?
															<ServiceBlockStore
																masterData = {pol}
																onClickFinish={this.state.onClickFinish}
																validateInput={boo => this.setState({ onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" }, () => this.validatePolicyInput(pol.rule_category, boo, polIndex))}
																selectedModeType={this.state.selectedModeType}
																actionMethods={this.state.actionMethods}
															/>
														: pol.rule_category === "Capacity" ?
															<ServiceCapacity
																masterData = {pol}
																onClickFinish={this.state.onClickFinish}
																validateInput={boo => this.setState({ onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" }, () => this.validatePolicyInput(pol.rule_category, boo, polIndex))}
																selectedModeType={this.state.selectedModeType}
																actionMethods={this.state.actionMethods}
															/>
														: null}
													</div>
												)
											})
										: null}

										<div className='mt-4'>
											<button className="btn btn-primary" onClick={() => this.onFinish()} >
												{this.state.finishBtn ? this.state.finishBtn : 'Save'} {this.props.disableFinish ? <Spinner size='sm' color='light' className='ml-2' /> : null}
											</button>
											<Link className='float-right btn btn-light text-muted text-decoration-none ml-1' to={URL_PATH.GOVERNANCE_MANAGE_RULES} >
												Cancel
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
FilterSidePanel.propTypes = {}

const mapStateToProps = state => {
	// console.log('observability query page',state)
	return {
		accounts: state.filters.accounts,
		regions: state.filters.regions,
		editNewRuleDetails: state.governance.editNewRuleDetails,
	}
}

export default connect(mapStateToProps, {
	listAllAccounts,
	listAllRegions,
	generateIamPolicy,
	getMasterGovernanceRules,
	setNewInitialRuleDetails,
	editGovernancePolicy
})(withRouter(FilterSidePanel))