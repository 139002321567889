/*************************************************
 * Tvastar
 * @exports
 * @file Table.js
 * @author Prakash // on 1/03/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTable, useRowSelect, useBlockLayout, useResizeColumns, useSortBy, usePagination } from 'react-table'
import { UncontrolledTooltip } from 'reactstrap'

const toolTipModifiers = {
	flip: { behavior: ['top'] },
	preventOverflow: { boundariesElement: 'viewport' },
}

function ResizeableTable({ columns, data, dashboard, perPage = 10, sortByColumn, sortByType=true, riskTooltip=false, onClickRow, rounded=false, tableHead=false, tableBody=false, tableSize=false, selectedColumn=false, columnFontSize=false }) {
	let sortBy = []
	// const defaultColumn = React.useMemo(
	// 	() => ({
	// 	  // When using the useFlexLayout:
	// 	  minWidth: 30, // minWidth is only used as a limit for resizing
	// 	  width: 150, // width is used for both the flex-basis and flex-grow
	// 	  maxWidth: 600, // maxWidth is only used as a limit for resizing
	// 	}),
	// 	[]
	//   )
	if(sortByColumn && sortByColumn !== '') {
		
		if(!sortByType) {
			sortBy = [
				{
					id: sortByColumn,
					asc: true
				}
			]
		} else {
			sortBy = [
				{
					id: sortByColumn,
					desc: true
				}
			]
		}
	}
	
	const [selectedRowIndex, setSelectedRowIndex] = useState(0)
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		// preGlobalFilteredRows,
		// setGlobalFilter,
		// state,
		// rows,
		// selectedFlatRows,
		// state: { pageIndex, pageSize, selectedRowIds },
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			// defaultColumn,
			data,
			initialState: { 
				pageIndex: 0, pageSize: perPage,
				sortBy: sortBy
			},
		},
		useBlockLayout,
		useResizeColumns,
		useSortBy,
		usePagination,
		useRowSelect
		
	)

	return (
		<React.Fragment>
			<div className={`${rounded} table-responsive`}>
				<table {...getTableProps()} className={`table ${tableSize ? tableSize : 'table-xs'} table-hover mb-0`}>
				<thead className={`${tableHead ? tableHead : 'bg-secondary-50'}`}>
						{headerGroups.map((headerGroup, i) => (
							<tr key={i} {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column, index) => (
									<th key={index} {...column.getHeaderProps()}>
										<span
											{...column.getSortByToggleProps({ title: 'Sort' })}
											style={{ marginRight: 10, cursor: 'pointer' }}
										>
											{column.isSorted ? (
												column.isSortedDesc ? (
													<React.Fragment>
														<i className='fas fa-sort-down' /> {column.render('Header')}
													</React.Fragment>
												) : (
													<React.Fragment>
														<i className='fas fa-sort-up' /> {column.render('Header')}
													</React.Fragment>
												)
											) : (
												column.render('Header')
												// <React.Fragment>
												// 	<i className='fas fa-sort' /> {column.render('Header')}
												// </React.Fragment>
											)}
										</span>
										{headerGroup.headers.length - 1 === index ? null : (
											<div {...column.getResizerProps()} className={`resizer`} />
										)}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody className={`${tableBody ? tableBody : 'bg-white'}`} {...getTableBodyProps()}>
						{page.map((row, i) => {
							prepareRow(row)
							return (
								<tr
									className={`w-100 ${selectedRowIndex === i ? (selectedColumn ? selectedColumn : 'bg-white3') : '' }`}
									key={i}
									{...row.getRowProps()}
									onClick={() => {
										onClickRow(row.original)
										setSelectedRowIndex(i)
									}}
								>
								
									{row.cells.map((cell, index) => {
										return (
											<React.Fragment key={index}>
													{riskTooltip && riskTooltip.includes(index) && (
														cell.row.values.severity ?
														<span className="uncontrolledTooltip">
															<UncontrolledTooltip  
																/* isOpen={true} */
																key={index}
																placement='top'
																modifiers={toolTipModifiers}
																target={`targetIdNotify${i}${index}`}
																//trigger="click"
																//className="uncontrolledTooltip"
																/* style={{backgroundColor:'red'}} */
															>
																{cell.row.values.severity}
															</UncontrolledTooltip>
														</span>
														: null
													)}
												<td
													key={index}
													className={`${columnFontSize} text-truncate`}
													{...cell.getCellProps()}
													id={`targetIdNotify${i}${index}`}
												>
													{/* {moment(cell.render('Cell').props.cell.value).isValid()
													? cell.render('Cell')
													: cell.render('Cell').props.cell.value && cell.render('Cell').props.cell.value.length > 15
													? cell.render('Cell').props.cell.value.substring(0, 15) + '...' */}
													{cell.render('Cell')}
												</td>
											</React.Fragment>
										)
									})}
								</tr>
							)
						})}

						{data.length ?
							null
						:
							<tr className='bg-white3 w-100 text-center'>
								<td colSpan={columns.length} className='text-truncate border-0'>
									There are no data on this criteria. Please try adjusting your filter.
								</td>
							</tr>
						}
					</tbody>
				</table>
			</div>
			{dashboard && data.length > perPage ? (
				<div className='pagination f12'>
					Rows per page:{' '}
					<select
						className="bg-white4"
						value={pageSize}
						onChange={e => {
							setPageSize(Number(e.target.value))
						}}
					>
					{[perPage * 1, perPage * 2, perPage * 3, perPage * 4, perPage * 5].map(pageSize => (
							<option key={pageSize} value={pageSize}>
								{pageSize}
							</option>
						))}
					</select>
					<span className='mx-3 pt-1'>
						Page{' '}{pageIndex + 1} of {pageOptions.length}{' '}
					</span>
					<button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
						<i className='fal fa-arrow-to-left f12 text-gray4' />
					</button>{' '}
					<button onClick={() => previousPage()} disabled={!canPreviousPage}>
						<i className='fal fa-angle-left f12 text-gray4' />
					</button>{' '}
					<button onClick={() => nextPage()} disabled={!canNextPage}>
						<i className='fal fa-angle-right f12 text-gray4' />
					</button>{' '}
					<button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
						<i className='fal fa-arrow-to-right f12 text-gray4' />
					</button>
				</div>
			) : null}
		</React.Fragment>
	)
}

/**
 * Type of the props used in the component
 */
ResizeableTable.propTypes = {
	columns: PropTypes.array,
	data: PropTypes.array,
	dashboard: PropTypes.bool,
	onClickRow: PropTypes.func,
	riskTooltip: PropTypes.array,
}

export default ResizeableTable
