
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file EventDetails.js
 * @author Prakash // on 02/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'

import _ from 'lodash'
import { momentConvertionUtcToLocalTime, capitalizeFirstLetter } from '../../../../utils/utility'

class EventDetails extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            expandCommonEventDetails: true,
        }
    }

    componentDidMount = () => {}

    componentDidUpdate = (prevProps, prevState) => {
        if(this.props.tabOptions && Object.entries(this.props.tabOptions).length) {
            if(!_.isEqual(prevProps.tabOptions, this.props.tabOptions)) {
                this.setState({ 
                    showSpinner: true,
                    expandCommonEventDetails: true,
                    expandUserIdentity: false, 
                    expandAccessDetails: false, 
                    expandTagDetails: false, 
                    expandPropsAssetDetails: false, 
                    expandEventAssetDetails: false, 
                    // details: this.props.tabOptions.details,
                    // merticsAssetNameInvolved: this.props.tabOptions.merticsAssetNameInvolved
                },
                    () => {
                        setTimeout(() => this.setState({ showSpinner: false }), 2000)
                    }
                )
            }
        }
    }    

    render() {
        return (
            <div className="mt-2 errorSectionScroll">
                {this.state.showSpinner ?
                    <div className='d-flex justify-content-center m-4'>
                        <Spinner className='text-center' color='white' size='lg' />
                    </div>
                :
                    <div className="rounded bg-dark3 p-3">
                        <div className="accordion" id="accordionExample">
                            {this.props.eventDetails && Object.entries(this.props.eventDetails).length && this.props.details && Object.entries(this.props.details).length ?
                                <React.Fragment>
                                {!Array.isArray(this.props.details.event_source) ?
                                    <div className={`card bg-dark border-0 mb-2`}>
                                        <div id={'heading_event'} onClick={() => this.setState({ expandCommonEventDetails: !this.state.expandCommonEventDetails })}>
                                            <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
                                                <div className="text-white"><span className={`far ${this.state.expandCommonEventDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Event Details</div>
                                                {/* <div className="text-info">Showing event details</div> */}
                                            </div>
                                        </div>
                                        <div id={'collapse_event'} className={`collapse ${this.state.expandCommonEventDetails ? 'show' : ''}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <div className="py-1 w-50">
                                                        <p className="b-block mb-0">Name:</p>
                                                        <p className="mb-0 text-white">{this.props.details.event_name ? this.props.details.event_name : ''}</p>
                                                    </div>
                                                    <div className="py-1 w-50 pl-3">
                                                        <p className="b-block mb-0">Id:</p>
                                                        <p className="mb-0 text-white">{this.props.details.event_id ?this.props.details.event_id : ''}</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex pt-2 border-top">
                                                    <div className="py-1 w-50">
                                                        <p className="b-block mb-0">Source:</p>
                                                        <p className="mb-0 text-white">{ this.props.details.event_source ? (!Array.isArray(this.props.details.event_source) ? this.props.details.event_source : this.props.details.event_source) : ""}</p>
                                                    </div>
                                                    <div className="py-1 w-50 pl-3">
                                                        <p className="b-block mb-0">Time:</p>
                                                        <p className="mb-0 text-white">{this.props.details.event_time ? momentConvertionUtcToLocalTime(this.props.details.event_time, 'DD MMM YYYY HH:mm') : ''}</p>
                                                    </div>
                                                </div>
                                                {!Array.isArray(this.props.eventDetails.event_source)  ?
                                                    this.props.eventDetails.event_source === "aws.rds" ?
                                                        this.props.eventDetails.additional_details ?
                                                            <div className="d-flex pt-2 border-top">
                                                                <div className="py-1 w-50">
                                                                    <p className="b-block mb-0">Event Code:</p>
                                                                    <p className="mb-0 text-white">{this.props.eventDetails.additional_details.event_id ? this.props.eventDetails.additional_details.event_id : ""}</p>
                                                                </div>
                                                                <div className="py-1 w-50 pl-3">
                                                                    <p className="b-block mb-0">Message:</p>
                                                                    <p className="mb-0 text-white">{this.props.eventDetails.additional_details.message ? this.props.eventDetails.additional_details.message : ''}</p>
                                                                </div>
                                                            </div>
                                                        : null
                                                    : this.props.eventDetails.event_source === "aws.ec2" ?
                                                        this.props.eventDetails.additional_details ?
                                                            <div className="col-sm-12">
                                                                <div className="row">
                                                                {Object.entries(this.props.eventDetails.additional_details).map(([key, value], addIdx) => {
                                                                    return(
                                                                        <React.Fragment>
                                                                            <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                            <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                                <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                                <p className="mb-0 text-white">{value}</p>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )
                                                                })}
                                                                </div>
                                                            </div>
                                                        : null
                                                    : this.props.eventDetails.event_source === "aws.codecommit" ?
                                                        //capitalizeTheFirstLetterOfEachWord(key.replaceAll("_", " "))
                                                        this.props.eventDetails.additional_details ?
                                                            <div className="col-sm-12">
                                                                <div className="row">
                                                                {Object.entries(this.props.eventDetails.additional_details).map(([key, value], addIdx) => {
                                                                    return(
                                                                        <React.Fragment>
                                                                            <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                            <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                                <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                                {key === "user" ?
                                                                                    <p className="mb-0 text-white">{value["name"] ? value["name"] : ""}</p>
                                                                                :
                                                                                    <p className="mb-0 text-white">{value}</p>
                                                                                }
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )
                                                                })}
                                                                </div>
                                                            </div>
                                                        : null
                                                    : this.props.eventDetails.event_source === "aws.codedeploy" ?
                                                        //capitalizeTheFirstLetterOfEachWord(key.replaceAll("_", " "))
                                                        this.props.eventDetails.additional_details ?
                                                            <div className="col-sm-12">
                                                                <div className="row">
                                                                {Object.entries(this.props.eventDetails.additional_details).map(([key, value], addIdx) => {
                                                                    return(
                                                                        <React.Fragment>
                                                                            <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                            <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                                <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                                {key === "artifacts" ?
                                                                                    <p className="mb-0 text-white">{value["location"] ? value["location"] : ""}</p>
                                                                                :
                                                                                    <p className="mb-0 text-white">{value}</p>
                                                                                }
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )
                                                                })}
                                                                </div>
                                                            </div>
                                                        : null
                                                    : this.props.eventDetails.event_source === "aws.codebuild" ?
                                                        //capitalizeTheFirstLetterOfEachWord(key.replaceAll("_", " "))
                                                        this.props.eventDetails.additional_details ?
                                                            <div className="col-sm-12">
                                                                <div className="row">
                                                                {Object.entries(this.props.eventDetails.additional_details).map(([key, value], addIdx) => {
                                                                    return(
                                                                        <React.Fragment>
                                                                            <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                            <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                                <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                                {key === "artifacts" ?
                                                                                    <p className="mb-0 text-white">{value["location"] ? value["location"] : ""}</p>
                                                                                : key === "commit_details" ?
                                                                                    <p className="mb-0 text-white">{value["commit_id"] ? value["commit_id"] : ""}</p>
                                                                                : key === "user" ?
                                                                                    <p className="mb-0 text-white">{value["name"] ? value["name"] : ""}</p>
                                                                                :
                                                                                    <p className="mb-0 text-white">{value}</p>
                                                                                }
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )
                                                                })}
                                                                </div>
                                                            </div>
                                                        : null
                                                    : this.props.eventDetails.event_source === "aws.codepipeline" ?
                                                        //capitalizeTheFirstLetterOfEachWord(key.replaceAll("_", " "))
                                                        this.props.eventDetails.additional_details ?
                                                            <div className="col-sm-12">
                                                                <div className="row">
                                                                {Object.entries(this.props.eventDetails.additional_details).map(([key, value], addIdx) => {
                                                                    return(
                                                                        <React.Fragment>
                                                                            <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                            <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                                <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                                {key === "deploy_details" ?
                                                                                    <p className="mb-0 text-white">{value["deployment_id"] ? value["deployment_id"] : ""}</p>
                                                                                :
                                                                                    <p className="mb-0 text-white">{value}</p>
                                                                                }
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )
                                                                })}
                                                                </div>
                                                            </div>
                                                        : null
                                                    : this.props.eventDetails.event_source === "rds_audit_log" ?
                                                        //capitalizeTheFirstLetterOfEachWord(key.replaceAll("_", " "))
                                                        this.props.eventDetails.additional_details ?
                                                            <div className="col-sm-12">
                                                                <div className="row">
                                                                {Object.entries(this.props.eventDetails.additional_details).map(([key, value], addIdx) => {
                                                                    return(
                                                                        <React.Fragment>
                                                                            <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                            <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                                <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                                {key === "user" ?
                                                                                    <p className="mb-0 text-white">{value["name"] ? value["name"] : ""}</p>
                                                                                :
                                                                                    <p className="mb-0 text-white">{value}</p>
                                                                                }
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )
                                                                })}
                                                                </div>
                                                            </div>
                                                        : null
                                                    : this.props.eventDetails.event_source === "rds_slow_log" ?
                                                        //capitalizeTheFirstLetterOfEachWord(key.replaceAll("_", " "))
                                                        this.props.eventDetails.additional_details ?
                                                            <div className="col-sm-12">
                                                                <div className="row">
                                                                {Object.entries(this.props.eventDetails.additional_details).map(([key, value], addIdx) => {
                                                                    return(
                                                                        <React.Fragment>
                                                                            <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                            <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                                <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                                {key === "user" ?
                                                                                    <p className="mb-0 text-white">{value["name"] ? value["name"] : ""}</p>
                                                                                :
                                                                                    <p className="mb-0 text-white">{value}</p>
                                                                                }
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )
                                                                })}
                                                                </div>
                                                            </div>
                                                        : null
                                                    : this.props.eventDetails.event_source === "config" ?
                                                        <React.Fragment>
                                                        {this.props.details.configuration_status ?
                                                            <div className="d-flex pt-2 border-top">
                                                                <div className="py-1 w-50">
                                                                    <p className="b-block mb-0">Configuration Status:</p>
                                                                    <p className="mb-0 text-white">{this.props.details.configuration_status}</p>
                                                                </div>
                                                            </div>
                                                        : null}
                                                        
                                                        {this.props.eventDetails.additional_details && Object.entries(this.props.eventDetails.additional_details).length && this.props.eventDetails.additional_details.changes ? 
                                                            <div className={`pt-2 border-top w-100`}>
                                                                <p className="b-block mb-2">Changes:</p>
                                                                {this.props.eventDetails.additional_details.changes.map(confCng => {
                                                                    return(
                                                                        <p className="mb-3 text-white"><span className="legend-circle mr-1 bg-info"></span>{confCng.description}:</p>
                                                                    )
                                                                })}
                                                            </div>
                                                        : null}
                                                        </React.Fragment>
                                                    : this.props.eventDetails.event_source === "checks" ?
                                                        <React.Fragment>
                                                        <div className="d-flex pt-2 border-top">
                                                            <div className="py-1 w-50">
                                                                <p className="b-block mb-0">Title:</p>
                                                                <p className="mb-0 text-white">{ this.props.details.title ? this.props.details.title : ""}</p>
                                                            </div>
                                                            <div className="py-1 w-50 pl-3">
                                                                <p className="b-block mb-0">Detail:</p>
                                                                <p className="mb-0 text-white">{ this.props.details.detail ? this.props.details.detail : ""}</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex pt-2 border-top">
                                                            <div className="py-1 w-50">
                                                                <p className="b-block mb-0">Severity:</p>
                                                                {this.props.eventDetails.risk ?
                                                                    <small className={`mr-1 badge risk-badge-${this.props.eventDetails.risk.toLowerCase()}`}>
                                                                    {this.props.eventDetails.risk}
                                                                    </small>
                                                                : null
                                                                }
                                                            </div>
                                                            <div className="py-1 w-50 pl-3">
                                                                <p className="b-block mb-0">Status:</p>
                                                                {this.props.eventDetails.status ?
                                                                    <small className={`mr-1 badge ${this.props.eventDetails.status === 'pass' ? 'badge-pass' : 'badge-fail'}`}>
                                                                    {this.props.eventDetails.status}
                                                                    </small>
                                                                : null
                                                                }
                                                            </div>
                                                        </div>
                                                        {this.props.eventDetails.resolution_status_info ?
                                                            <div className="col-sm-12">
                                                                <div className="row">
                                                                {Object.entries(this.props.eventDetails.resolution_status_info).map(([key, value], addIdx) => {
                                                                    return(
                                                                        <React.Fragment>
                                                                            <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                            <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                                <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                                {key === "timestamp" ?
                                                                                    <p className="mb-0 text-white">{value ? momentConvertionUtcToLocalTime(value, 'DD MMM YYYY HH:mm') : ''}</p>
                                                                                :
                                                                                    <p className="mb-0 text-white">{value}</p>
                                                                                }
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )
                                                                })}
                                                                </div>
                                                            </div>
                                                        : null}
                                                        </React.Fragment>
                                                    : null
                                                : null}
                                            </div>
                                        </div>
                                    </div>
                                : null}                                

                                {this.props.details.event_source && !Array.isArray(this.props.details.event_source) && this.props.details.event_source === "cloudtrail" ?
                                    <React.Fragment>
                                        {this.props.eventDetails.user_identity ?
                                            <div className={`card bg-dark border-0 mb-2`}>
                                                <div id={'heading_user'} onClick={() => this.setState({ expandUserIdentity: !this.state.expandUserIdentity })}>
                                                    <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_user'}aria-expanded="true" aria-controls={'collapse_user'}>
                                                        <div className="text-white"><span className={`far ${this.state.expandUserIdentity ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>User Details</div>
                                                        {/* <div className="text-info">Showing user details</div> */}
                                                    </div>
                                                </div>
                                                <div id={'collapse_user'} className={`collapse ${this.state.expandUserIdentity ? 'show' : ''}`} aria-labelledby={'heading_user'} data-parent="#accordionExample">
                                                    <div className="card-body">
                                                        <div className="d-flex mb-2">
                                                            <div className="py-1 w-50">
                                                                <p className="b-block mb-0">Name:</p>
                                                                <p className="mb-0 text-white">{this.props.eventDetails.user_identity.name ? this.props.eventDetails.user_identity.name  : ''}</p>
                                                            </div>
                                                            <div className="py-1 w-50 pl-3">
                                                                <p className="b-block mb-0">Type:</p>
                                                                <p className="mb-0 text-white">{this.props.eventDetails.user_identity.type ?this.props.eventDetails.user_identity.type : ''}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        : null}
                                        
                                        {this.props.eventDetails.accessed_from ?
                                            <div className={`card bg-dark border-0 mb-2`}>
                                                <div id={'heading_access'} onClick={() => this.setState({ expandAccessDetails: !this.state.expandAccessDetails })}>
                                                    <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_access'}aria-expanded="true" aria-controls={'collapse_access'}>
                                                        <div className="text-white"><span className={`far ${this.state.expandAccessDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Access Details</div>
                                                        {/* <div className="text-info">Showing access details</div> */}
                                                    </div>
                                                </div>
                                                <div id={'collapse_access'} className={`collapse ${this.state.expandAccessDetails ? 'show' : ''}`} aria-labelledby={'heading_access'} data-parent="#accordionExample">
                                                    <div className="card-body">
                                                        <div className="d-flex mb-2 border-bottom">
                                                            <div className="py-1 w-50">
                                                                <p className="b-block mb-0">Access Mode:</p>
                                                                <p className="mb-0 text-white">{this.props.eventDetails.accessed_from.access_mode ? this.props.eventDetails.accessed_from.access_mode : ''}</p>
                                                            </div>
                                                            <div className="py-1 w-50 pl-3">
                                                                <p className="b-block mb-0">ip:</p>
                                                                <p className="mb-0 text-white">{this.props.eventDetails.accessed_from.ip ?this.props.eventDetails.accessed_from.ip : ''}</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex mb-2">
                                                            <div className="py-1 w-100">
                                                                <p className="b-block mb-0">User Agent:</p>
                                                                <p className="mb-0 text-white">{this.props.eventDetails.accessed_from.user_agent ? this.props.eventDetails.accessed_from.user_agent : ''}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        : null}
                                    </React.Fragment>
                                : null}

                                {this.props.eventDetails.tags && this.props.eventDetails.tags.length ?
                                    <div className={`card bg-dark border-0 mb-2`}>
                                        <div id={'heading_config_tags'} onClick={() => this.setState({ expandTagDetails: !this.state.expandTagDetails })}>
                                            <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_tags'}aria-expanded="true" aria-controls={'collapse_config_tags'}>
                                                <div className="text-white"><span className={`far ${this.state.expandTagDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Tag Details</div>
                                                {/* <div className="text-info">Showing tag details</div> */}
                                            </div>
                                        </div>
                                        <div id={'collapse_config_tags'} className={`collapse ${this.state.expandTagDetails ? 'show' : ''}`} aria-labelledby={'heading_config_tags'} data-parent="#accordionExample">
                                            <div className="card-body">
                                                <ul className='tags my-1 px-3'>
                                                    {this.props.eventDetails.tags.map((tag, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <span className='tag f14'>{tag.key+': '+tag.value}</span>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                                {/* <div className={`d-flex flex-wrap`}>
                                                    {this.props.eventDetails.tags.map(item => {
                                                        return (
                                                            <small className={`mr-1 mb-1 badge badge-light`}>{item.key+' : '+item.value}</small> 
                                                        )
                                                    })}
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                : null}

                                {this.props.details.assets && this.props.details.assets.length ?
                                    <div className={`card bg-dark border-0 mb-2`}>
                                        <div id={'heading_config_assets'} onClick={() => this.setState({ expandPropsAssetDetails: !this.state.expandPropsAssetDetails })}>
                                            <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                                <div className="text-white"><span className={`far ${this.state.expandPropsAssetDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Asset Details</div>
                                                {/* <div className="text-info">Showing asset details</div> */}
                                            </div>
                                        </div>
                                        <div id={'collapse_config_assets'} className={`collapse ${this.state.expandPropsAssetDetails ? 'show' : ''}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                            {this.props.details.assets.map(item => {
                                                return(
                                                <div className="card-body m-2 pt-2 rounded counts_threads">
                                                    <div className="d-flex mb-2 border-bottom">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Name:</p>
                                                            <p className="mb-0 text-white">{item.asset_name ? item.asset_name : ''}</p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Id:</p>
                                                            <p className="mb-0 text-white">{item.asset_id ? item.asset_id : ""}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-2">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Resource:</p>
                                                            <p className="mb-0 text-white">
                                                                {this.props.details.provider ? this.props.details.provider.toUpperCase() : ""}
                                                                {this.props.details.account_id ? (" : ")+this.props.details.account_id : ""}
                                                                {this.props.details.region ? (" : ")+this.props.details.region : ""}
                                                            </p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Services:</p>
                                                            <p className="mb-0 text-white">
                                                                {item.resource_type ? item.resource_type : ''}
                                                                {item.service_name ? (" : ")+item.service_name : ''}
                                                            </p>
                                                        </div>
                                                    </div>                                                        
                                                    <div className="d-flex mb-2 border-bottom">
                                                        <div className="py-1 w-100">
                                                            <p className="b-block mb-0">ARN:</p>
                                                            <p className="mb-0 text-white">{item.asset_arn ?item.asset_arn : ''}</p>
                                                        </div>
                                                    </div>
                                                    {item.category ? 
                                                        <div className="d-flex mb-2 border-bottom">
                                                            <div className="py-1 w-100">
                                                                <p className="b-block mb-0">Category:</p>
                                                                <div className={`d-flex flex-wrap`}>
                                                                    {item.category.map(item => {
                                                                        return (
                                                                            <small className={`mr-1 mb-1 badge badge-light`}>{item}</small> 
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    : null}
                                                </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                : this.props.eventDetails.assets && this.props.eventDetails.assets.length ?
                                    <div className={`card bg-dark border-0 mb-2`}>
                                        <div id={'heading_config_assets'} onClick={() => this.setState({ expandEventAssetDetails: !this.state.expandEventAssetDetails })}>
                                            <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                                <div className="text-white"><span className={`far ${this.state.expandEventAssetDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Asset Details</div>
                                                {/* <div className="text-info">Showing asset details</div> */}
                                            </div>
                                        </div>                                        
                                        <div id={'collapse_config_assets'} className={`collapse ${this.state.expandEventAssetDetails ? 'show' : ''}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                            {this.props.eventDetails.assets.map(item => {
                                                return(
                                                <div className="card-body m-2 pt-2 rounded counts_threads">
                                                    <div className="d-flex mb-2 border-bottom">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Name:</p>
                                                            <p className="mb-0 text-white">{item.asset_name ? item.asset_name : ''}</p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Id:</p>
                                                            <p className="mb-0 text-white">{item.asset_id ? item.asset_id : ""}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-2">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Resource:</p>
                                                            <p className="mb-0 text-white">
                                                                {this.props.details.provider ? this.props.details.provider.toUpperCase() : ""}
                                                                {this.props.details.account_id ? (" : ")+this.props.details.account_id : ""}
                                                                {this.props.details.region ? (" : ")+this.props.details.region : ""}
                                                            </p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Services:</p>
                                                            <p className="mb-0 text-white">
                                                                {item.resource_type ? item.resource_type : ''}
                                                                {item.service_name ? (" : ")+item.service_name : ''}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-2 border-bottom">
                                                        <div className="py-1 w-100">
                                                            <p className="b-block mb-0">ARN:</p>
                                                            <p className="mb-0 text-white">{item.asset_arn ?item.asset_arn : ''}</p>
                                                        </div>
                                                    </div>
                                                    {item.category ? 
                                                        <div className="d-flex mb-2 border-bottom">
                                                            <div className="py-1 w-100">
                                                                <p className="b-block mb-0">Category:</p>
                                                                <div className={`d-flex flex-wrap`}>
                                                                    {item.category.map(item => {
                                                                        return (
                                                                            <small className={`mr-1 mb-1 badge badge-light`}>{item}</small> 
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    : null}
                                                </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                : null}
                                </React.Fragment>
                            : 
                                <div className='col-md-12 d-flex justify-content-center m-4'>
                                    <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }
}
/**
 * Type of the props used in the component
 */
EventDetails.propTypes = {}


/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log('errorDashboard',state)
    return {
        eventDetails: state.aiops.eventDetails,
        tabOptions: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.tabOptions ? state.aiops.aiopsPropsDetails.tabOptions : {},
    }
}


export default connect(mapStateToProps, {})(withRouter(EventDetails))        