/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file TagGroups.js
 * @author Rajasekar // on 31/03/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { AppWrapper } from '../../common/AppWrapper'
import PropTypes from 'prop-types'
import {
	getTagGroups,
	setSelectedTableRow,
	deleteTagGroup,
	setTagIdInStore,
} from '../../../actions/governance/taggingAction'
import { setInitialEditTagGroupDetails } from '../../../actions/governance/governanceAction'
import { showNotification } from '../../../actions/commonAction'
import Table from '../../designComponents/Table/Table'
import Search from '../../common/SearchComponent'
import { Spinner } from 'reactstrap'
import { URL_PATH } from '../../../config/urlPath'
import { SUCCESS, ERROR } from '../../../utils/constants'

class TagGroups extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isLoading: false,
			filteredArray: [],
			deleteLoading: false,
		}
	}

	componentDidMount = () => {
		this.setState({ isLoading: true })
		this.props.getTagGroups({}, () => {
			this.setState({ isLoading: false })
		})
	}

	onDeleteTagGroup = () => {
		this.setState({ deleteLoading: true })
		this.props.deleteTagGroup({ tag_group_id: this.props.selectedTableRow.tag_group_id }, (success, res) => {
			this.setState({ deleteLoading: false })
			if (success) {
				this.props.setSelectedTableRow(this.props.tagGroups[0])
				this.props.showNotification(SUCCESS, (res && res.message) || 'Tag group deleted successfully.')
			} else this.props.showNotification(ERROR, (res && res.message) || 'Tag group not deleted.')
		})
	}

	onEditTagGroup = () => {
		this.props.setTagIdInStore(this.props.selectedTableRow.tag_group_id)
		this.props.history.push({
			pathname: URL_PATH.GOVERNANCE_EDIT_TAG_GROUPS + '/' + this.props.selectedTableRow.tag_group_id,
			state: { tag_group_id: this.props.selectedTableRow.tag_group_id },
		})
	}

	render() {
		return (
			<div className='container-fluid overflow-auto flex-grow-1'>
				<div className='row h-100'>
					<div className='col-sm-9 p-0'>
						<div className='title-section p-3'>
							<div className='row'>
								<div className='col-6 align-self-center'>
									<h6 className='text-white m-0'>Tag Groups</h6>
								</div>
								<div className='col-6 d-flex justify-content-end'>
									<div className='mr-2'>
										<Search
											data={this.props.tagGroups && this.props.tagGroups}
											filteredData={filteredArray => {
												if (filteredArray.length) {
													this.props.setSelectedTableRow(filteredArray[0])
													this.setState({ filteredArray })
												}
											}}
										/>
									</div>
									<div>
										<button
											className='btn btn-primary'
											onClick={() => {
												this.props.setInitialEditTagGroupDetails()
												this.props.history.push(URL_PATH.GOVERNANCE_CREATE_TAG_GROUPS)
											}}
										>
											Create Tag Group
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className='box m-3'>
							{this.props.tagGroups && this.props.tagGroups.length && !this.state.isLoading ? (
								<Table
									columns={[
										{
											Header: 'Name',
											accessor: 'tag_group_name',
										},
										{
											Header: 'Created By',
											accessor: 'created_by',
										},
										{
											Header: 'Created At',
											accessor: 'created_at',
										},
										{
											Header: 'Last Modified',
											accessor: 'modified_at',
										},
									]}
									data={this.state.filteredArray.length ? this.state.filteredArray : this.props.tagGroups}
									onClickRow={tableRow => {
										this.props.setSelectedTableRow(tableRow)
									}}
									tooltipFor={[0]}
								/>
							) : (!this.state.isLoading && !this.props.tagGroups) ||
							  (this.props.tagGroups && !this.props.tagGroups.length && !this.state.isLoading) ? (
								<div className='d-flex justify-content-center m-4'>
									<p>No tag groups found</p>
								</div>
							) : null}
							{this.state.isLoading ? (
								<div className='d-flex justify-content-center m-4'>
									<Spinner className='text-center' color='dark' size='lg' />
								</div>
							) : null}
						</div>
					</div>
					<div className='col-sm-3 p-0'>
						<div className='detail'>
							<div className='detail-title'>
								<div className='d-flex justify-content-between align-content-center'>
									<div>
										{this.props.selectedTableRow.relations && (
											<span className='m-2 text-light'>
												Applies to{' '}
												{this.props.selectedTableRow.relations && this.props.selectedTableRow.relations.length} rules
											</span>
										)}
									</div>

									<div>
										<button className='btn btn-primary btn-sm mr-3' onClick={() => this.onEditTagGroup()}>
											Edit {this.state.isEditLoading ? <Spinner color='light' size='sm' /> : null}
										</button>
										<button
											className='btn btn-danger btn-sm'
											onClick={() => this.onDeleteTagGroup()}
											disabled={this.state.deleteLoading}
										>
											Delete {this.state.deleteLoading ? <Spinner color='light' size='sm' /> : null}
										</button>
									</div>
								</div>
								{/* <p className='mt-2 mb-0'>
									<small className='text-white mt-2'>
										{this.props.selectedTableRow && this.props.selectedTableRow.tag_group_name}
									</small>
								</p> */}
							</div>
							<div className='detail-content'>
								<h6 className='m-2'>Applied Tags</h6>
								{this.props.selectedTableRow &&
								this.props.selectedTableRow.tags &&
								this.props.selectedTableRow.tags.length
									? this.props.selectedTableRow.tags.map(item => {
											return (
												<>
													<p className='text-danger ml-2 mb-2'>{item.section_name}</p>
													<table className='w-100'>
														<tbody>
															<tr>
																<td className='p-2'>{item.key}</td>
																<td className='p-2 text-muted text-right'>{item.value}</td>
															</tr>
														</tbody>
													</table>
												</>
											)
									  })
									: null}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
TagGroups.propTypes = {
	getTagGroups: PropTypes.func,
	deleteTagGroup: PropTypes.func,
	setSelectedTableRow: PropTypes.func,
	getTagGroupsById: PropTypes.func,
	getTagGroupsConditionById: PropTypes.func,
	setTagIdInStore: PropTypes.func,
	tagGroups: PropTypes.array,
	selectedTableRow: PropTypes.object,
	history: PropTypes.array,
	setInitialEditTagGroupDetails: PropTypes.func,
	showNotification: PropTypes.func,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		tagGroups: state.governance.tagGroups,
		selectedTableRow: state.governance.selectedTagGroup,
	}
}

export default AppWrapper(TagGroups, mapStateToProps, {
	getTagGroups,
	setSelectedTableRow,
	setTagIdInStore,
	setInitialEditTagGroupDetails,
	deleteTagGroup,
	showNotification,
})
