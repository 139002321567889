    /*************************************************
 * Tvastar
 * @exports
 * @file PeakDipMetrics.js
 * @author Prakash // on 28/11/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _, { filter } from 'lodash'
// import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import Select from 'react-select'
import Chart from 'react-apexcharts'

import { momentDateGivenFormat, momentConvertionUtcToLocalTime, currentUTCtime, subDays, addMinutes,  addDays, twoDateDiffrence, capitalizeFirstLetter, currentLocaltime } from '../../../../utils/utility'
import { setResourcePropsDetails } from '../../../../actions/assets/assetsAction'
import { getMonitoringMetricsByTrend } from '../../../../actions/aiops/MetricsAction'
import { getPeakDipDetectionResults } from '../../../../actions/aiops/ObservabilityAction'
import ApexPositiveNegativeBar from '../../../common/charts/ApexPositiveNegativeBar'
import ResizeableDarkThemeTable from '../../../designComponents/Table/ResizeableDarkThemeTable'

class PeakDipMetrics extends Component {
	constructor(props) {
		super(props)
        this.toggleRef = React.createRef()
		this.state = {
            peakDipLoader: true,
            expandPeakAndDip: true,
            selectedDuration: "+1d",
            dataFrequency: ["Hourly", "Daily"],
            selectedFrequency: "Hourly",
		}
	}

	componentDidMount = () => {
		if(this.props.selectedAsset && Object.entries(this.props.selectedAsset).length) {
            this.setState({ 
                // start_time: this.props.caseAssetPageFilter.start_time,
                // end_time: this.props.caseAssetPageFilter.end_time,
                selectedAsset: this.props.selectedAsset ? this.props.selectedAsset : {},
                graphData: {},
            },
                () => {
                    this.getPeakDipDetectionResults()
                }
            )
        } else {
			this.setState({ peakDipLoader: false })
		}
    }

    getPeakDipDetectionResults = () => {
        
        let startTime = momentDateGivenFormat(subDays(currentUTCtime(), this.state.selectedDuration), 'YYYY-MM-DD HH:mm:ss')
        let endTime = momentDateGivenFormat(currentUTCtime(), 'YYYY-MM-DD HH:mm:ss')
        let params = {}
        // params.start_time = startTime
        // params.end_time = endTime
        params.duration = this.state.selectedDuration
        params.asset_id = "2177cc182e583c5af18c088c291ec604" //this.state.selectedAsset.asset_id ? this.state.selectedAsset.asset_id : "" //
        params.future_metrics = true
        params.past_metrics = true
        params.extrema_freq = this.state.selectedFrequency
        params.top = 3
        // if(this.state.selectedDuration !== "+1d" && this.state.selectedDuration !== "+30d") {
        //     params.extrema_freq = "Daily"
        // } else {
        //     params.extrema_freq = "Hourly"
        // }
        // params = {"start_time":"2022-11-12 06:00:00","end_time":"2022-11-16 06:00:00","asset_id":"19ceec7949058f64a0e8ce0e24f7b3cb","future_metrics":true, "past_metrics": true, }
        this.props.getPeakDipDetectionResults(params, (promise, response) => {
            if(promise) {
                let results = response && response.results ? response.results : []
                this.setState({ peakDipData: results, filteredPeakDipData: results, peakDipLoader: false },
                    () => this.filterPeakDip()    
                )
            } else {
                this.setState({ peakDipData: [], peakDipLoader: false })
            }
        })
    }

    filterPeakDip = () => {
        let hourlyData = this.state.peakDipData
        // if(this.state.selectedDuration !== "+1d" && this.state.selectedDuration !== "+30d") {
        //     hourlyData = this.state.peakDipData.filter(e => e.extrema_freq === "Daily")
        // } else {
        //     hourlyData = this.state.peakDipData.filter(e => e.extrema_freq === "Hourly")
        // }
        let peakData = hourlyData.filter(e => e.extrema_type === "peak")
        let dipData = hourlyData.filter(e => e.extrema_type === "dip")

        // if(peakData) {
        //     peakData = _.orderBy(peakData, [this.state.sortPeakBy], ["desc"])
        // }

        // if(dipData) {
        //     dipData = _.orderBy(dipData, [this.state.sortDipBy], ["desc"])
        // }

        let futureData = hourlyData.filter(e => e.future_metrics)
        let pastData = hourlyData.filter(e => e.past_metrics)

        this.setState({ peakData, dipData, futureData, pastData, filteredPastData: pastData, filteredFutureData: futureData },
            () => {
                if(this.state.selectedFrequency === "Hourly") {
                    this.formPeakData()
                } else {
                    this.createHeatMap()
                }
                // this.formDipData()
            }    
        )
    }

    filterTableResults = (key) => {
        
        if(this.state.selectedFrequency === "Hourly") {
            let data = this.state.peakDipData
            if(key === "type" && this.state.showPeakDipType && this.state.showPeakDipType !== "") {
                data = data.filter(e => e.extrema_type === this.state.showPeakDipType)
            }
            
            if(key === "metrics" && this.state.showFuturePastData && this.state.showFuturePastData !== "") {
                if(this.state.showFuturePastData === "past") {
                    data = data.filter(e => e.past_metrics)
                } else {
                    data = data.filter(e => e.future_metrics)
                }
            }

            this.setState({ filteredPeakDipData: data })
        } else {
            let filteredFutureData = this.state.futureData
            let filteredPastData = this.state.pastData

            if(key === "type" && this.state.showPeakDipType && this.state.showPeakDipType !== "") {
                filteredFutureData = filteredFutureData.filter(e => e.extrema_type === this.state.showPeakDipType)
                filteredPastData = filteredPastData.filter(e => e.extrema_type === this.state.showPeakDipType)
            }

            this.setState({ filteredFutureData, filteredPastData })
        }
    }

    formPeakData = () => {
        let peakData = this.state.peakData ? this.state.peakData : []
        let dates = []
        let data = []
        peakData.forEach(row => {
            if(this.state.selectedFrequency === "Daily") {
                // let date = row.date
                // dates.push(date)
                // data.push(100)
            } else {
                for(let i=0; i<=row.time_range_in_mins; i++) {
                    let date = addMinutes(row.min_time, i)
                    dates.push(date)
                    data.push(100)
                }
            }
        })

        let dipData = this.state.dipData ? this.state.dipData : []
        dipData.forEach(row => {
            if(this.state.selectedFrequency === "Daily") {
                // let date = row.date
                // dates.push(date)
                // data.push(-100)
            } else {
                for(let i=0; i<=row.time_range_in_mins; i++) {
                    let date = addMinutes(row.min_time, i)
                    dates.push(date)
                    data.push(-100)
                }
            }
        })

        let peakGraphData = {}
        peakGraphData.name = "Peak"
        peakGraphData.data = data
        peakGraphData.labels = dates

        console.log("peakGraphData", peakGraphData)

        this.setState({ peakGraphData })

    }

    createHeatMap = () => {
        let peakDipData = this.state.peakDipData ? this.state.peakDipData : []
        let seriesPast = []
        let seriesFuture = []
        
        let pastData = []
        let futureData = []
        let orderPeakDipData = _.orderBy(peakDipData, ['date'], ['asc'])
        let index = 0
        console.log("orderPeakDipData", orderPeakDipData)
        orderPeakDipData.forEach(row => {
            index++
            if(index > 7) {
                let seriesPastRow = {}
                seriesPastRow.name = ""
                seriesPastRow.data = pastData
                seriesPast.push(seriesPastRow)

                let daysRequired = 6
                if(!futureData.length) {
                    for (let i = daysRequired; i >= 0; i--) {
                        let localTime = momentDateGivenFormat(currentLocaltime(), "YYYY-MM-DD HH:mm:ss")
                        let day = momentDateGivenFormat(subDays(localTime, i), "dddd")
                        let date = momentDateGivenFormat(subDays(localTime, i), "YYYY-MM-DD")
                        let dataRow = {
                            w: day,
                            x: day.substr(0, 3),
                            y: 0,
                            z: date,
                        }
                        futureData.push(dataRow)
                    }
                }
                
                let seriesFutureRow = {}
                seriesFutureRow.name = ""
                seriesFutureRow.data = futureData
                seriesFuture.push(seriesFutureRow)
                
                index = 1
                pastData = []
                futureData = []
            }
            let value = row.extrema_strength
            if(row.extrema_type === "dip") {
                value = -row.extrema_strength
            }

            if(row.date <= momentDateGivenFormat(currentUTCtime(), "YYYY-MM-DD")) {
                let dataRow = {
                    w: row.day,
                    x: row.day.substr(0, 3),
                    y: value,
                    z: row.date,
                }
                pastData.push(dataRow)
            } else {
                let dataRow = {
                    w: row.day,
                    x: row.day.substr(0, 3),
                    y: value,
                    z: row.date,
                }
                futureData.push(dataRow)
            }
        })
        
        let heatOptions = {
            chart: {
                height: 240,
                type: "heatmap",
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
                sparkline: {
                    enabled: false
                },
                animations: {
                    enabled: false
                },
            },
            tooltip: {
                enabled: true,
                style: {
                    fontSize: '9px',
                },
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    let val  = w.globals.initialSeries[0].data[dataPointIndex].y
                    let labelName = ""
                    
                    if(val < 0) {
                        labelName = "Dip"
                        val = -(val)
                    } else if(val > 0) {
                        labelName = "Peak"
                    } else {
                        val = ""
                    }

                    let retrunData = '<div class="arrow_box"> <span>' +  w.globals.initialSeries[0].data[dataPointIndex].z + ' '+  w.globals.initialSeries[0].data[dataPointIndex].w+'</span> '
                    retrunData += '<span style="color:#039BE5"> ' + val + '</span>'                    
                    retrunData += '</div>'

                    return retrunData
                }
            },
            plotOptions: {
                heatmap: {
                    shadeIntensity: 0.5,
                    radius: 0,
                    useFillColorAsStroke: false,
                    colorScale: {
                        ranges: [
                            {
                                from: 1,
                                to: 49,
                                name: "low2",
                                color: "#ffc6c6" //f48071 //f8aaa0
                            },
                            {
                                from: 50,
                                to: 79,
                                name: "medium1",
                                color: "#ffe2e2" //ff7171 //f15b46
                            },
                            {
                                from: 80,
                                to: 100,
                                name: "extreme",
                                color: "#FF0000"
                            },
                            {
                                from: -100,
                                to: -51,
                                name: "high",
                                color: "#1d8c6e"
                            },
                            {
                                from: -50,
                                to: -34,
                                name: "medium",
                                color: "#26B68F"
                            },
                            {
                                from: -33,
                                to: -1,
                                name: "low",
                                color: "#57dbb8" //abeddb
                            },
                            {
                                from: 0,
                                to: 0,
                                name: "",
                                color: "#383E4D"
                            },
                        ]
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 1
            },
            
            xaxis: {
                show: true,
                tooltip: {
                    enabled: false
                },
                type: 'string',
                axisBorder: {
                    show: false,
                    color:'#434B5E'
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    datetimeUTC: false,
                    show: true,
                    style: {
                        colors: '#B8BBBE',
                        fontSize: '13px',
                        fontWeight: 'bolder',
                    },
                },
            },
            yaxis: {
                show: false,
                // this.props.tickAmount: this.props.yaxisTickAmont ? this.props.yaxisTickAmont : 4,
                axisBorder: {
                    show: false,
                    color: '#969AA7',
                },
                // tickAmount: parseInt(highestValue) <= 1 ? 1 : "",
                labels: {
                    show: false,
                }
            },
            legend: {
                show: false,
            },
        };

        console.log("seriesFuture", seriesFuture)
        console.log("seriesPast", seriesPast)

        

        this.setState({ heatPastSeries: seriesPast, heatOptions, heatFutureSeries: seriesFuture })
    }

    filterZoomData = (zoomStartTime, zoomEndTIme) => {
        let data = this.state.filteredPeakDipData

        data = data.filter(e => momentConvertionUtcToLocalTime(e.min_time, "YYYY-MM-DD HH:mm:ss") >= zoomStartTime && momentConvertionUtcToLocalTime(e.max_time, "YYYY-MM-DD HH:mm:ss") <= zoomEndTIme)

        this.setState({ filteredPeakDipData: data })
    }

    resetGraph = (type) => {
        let graphData = this.state[type]
        this.setState({ [type]: "" },
            () => {
                this.setState({ [type]: graphData, filteredPeakDipData: this.state.peakDipData })
            }
        )
    }

    expandPeakDibTab = () => {
        // if(this.state.correlationData && this.state.correlationData.length) {
        this.setState({ expandPeakAndDip: !this.state.expandPeakAndDip })
        // }
    }

    handleClickOutside(event) {}
	
	render() {		
		return (
            <React.Fragment>
                <div className={`d-flex justify-content-end ${this.state.peakDipLoader ? "disabled" : ""}`}>
                    <span className={`mr-2 f12 align-self-center`}>Last</span>
                    <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+30d' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ peakGraphData: {}, peakDipLoader: true, selectedDuration: '+30d' }, () => this.getPeakDipDetectionResults())}>1 Month</span>
                    {/* <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '14' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ peakGraphData: {}, peakDipLoader: true, selectedDuration: '14' }, () => this.getPeakDipDetectionResults())}>2 Week</span> */}
                    <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+7d' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ peakGraphData: {}, peakDipLoader: true, selectedDuration: '+7d' }, () => this.getPeakDipDetectionResults())}>1 Week</span>
                    <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+1d' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ peakGraphData: {}, peakDipLoader: true, selectedDuration: '+1d' }, () => this.getPeakDipDetectionResults())}>24 Hours</span>
                </div>
                {this.state.peakDipLoader ?
                    <div className='d-flex justify-content-center m-4'>
                        <Spinner className='text-center' color='white' size='lg' />
                    </div>
                :
                    <div className={`p-3`}>
                        <div className="accordion" id="accordionExample">
                            <div className={`card bg-dark3 border-0 mb-2`}>
                                {/* <div id={'heading_event'} onClick={() => this.expandPeakDibTab()}></div> */}
                                <div id={'heading_event'}> 
                                    <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
                                        <div className="text-white">
                                            {/* <span className={`far ${this.state.expandPeakAndDip ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span> */}
                                            Peak and Dip
                                        </div>
                                        {/* {this.state.peakDipLoader ?
                                            <Spinner className='text-center' color='white' size='sm' />
                                        :
                                            <div className="text-info">{!this.state.peakDipLoader || !this.state.peakDipLoader.length ? "No" : this.state.peakDipLoader.length} Peak(s)</div>
                                        } */}
                                    </div>
                                </div>
                                <div id={'collapse_event'} className={`collapse ${this.state.expandPeakAndDip ? 'show' : ''}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
                                    <div className="card-body">
                                        {this.state.peakDipLoader ?
                                            <div className='d-flex justify-content-center m-4'>
                                                <Spinner className='text-center' color='white' size='lg' />
                                            </div>
                                        :
                                            <div className="">
                                                <div className="rounded bg-muted px-3 pt-2 pb-3">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="d-flex">
                                                            <p className="small mb-0 mr-2 align-self-end">Showing peak and dip </p>
                                                            <div className="dlpDescriptionForm dlpDescriptionFormPlain">
                                                                <Select
                                                                    isSearchable={true}
                                                                    className={`selectOption mr-1`}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    value={({
                                                                        value: this.state.selectedFrequency && this.state.selectedFrequency !== "" ? this.state.selectedFrequency : "",
                                                                        label: this.state.selectedFrequency && this.state.selectedFrequency !== "" ? this.state.selectedFrequency : <span className="placeholder">Select</span>
                                                                    })}
                                                                    options={this.state.dataFrequency && this.state.dataFrequency.map(item => ({ 
                                                                        value: item,
                                                                        label: item,
                                                                    }))}
                                                                    onChange={event => this.setState({ selectedFrequency: event.value },
                                                                        () => this.getPeakDipDetectionResults()    
                                                                    )}
                                                                />
                                                            </div> 
                                                            <p className="small mb-0 ml-1 align-self-end">data.</p>
                                                        </div>
                                                        <i className="far fa-redo cursorPointer align-self-center" onClick={() => this.resetGraph("peakGraphData")}></i>
                                                    </div>
                                                    
                                                    {this.state.selectedFrequency === "Hourly" ?
                                                        this.state.peakGraphData && Object.entries(this.state.peakGraphData).length ?
                                                            <ApexPositiveNegativeBar
                                                                graphData={this.state.peakGraphData}
                                                                sparkline={false}
                                                                yaxis={false}
                                                                yaxisLabel={false}
                                                                xaxis={true}
                                                                xaxisFormat={this.state.selectedDuration !== "+1d" && this.state.selectedDuration !== "+30d" ? 'date' : 'dateWithtime'}
                                                                xaxisLabel={true}
                                                                axisLabelColor={'#B8BBBE'}
                                                                paddingLeft={20}
                                                                paddingTop={-50}
                                                                legend={false}
                                                                stacked={true}
                                                                stackType={true}
                                                                height={240}
                                                                // barHeight={'100%'}
                                                                barEndShape={'flat'}
                                                                columnWidth={this.state.selectedDuration !== "+1d" && this.state.selectedDuration !== "+30d" ? '40%' : '40%'}
                                                                backgroundBarShape={'flat'}
                                                                backgroundBarColors={['#333947']}
                                                                showBackgroundBarColors={false}
                                                                className={"transparentTooltip"}
                                                                zoomEnabled={true}
                                                                getZoomPeriod={true}
                                                                zoomPeriod={(zoomStartTime, zoomEndTIme) => this.filterZoomData(zoomStartTime, zoomEndTIme)}
                                                            />
                                                        : null
                                                    : 
                                                        <div className="d-flex justify-content-between transparentTooltip">
                                                            <div className="d-flex justify-content-center w-50 border-right-info">
                                                                <div className="w-60 pr-2">
                                                                    <React.Fragment>
                                                                    <span className="d-flex justify-content-center mt-3">Past</span>
                                                                    <div className="mt-n3">
                                                                    {this.state.heatOptions && this.state.heatPastSeries ?
                                                                        <Chart options={this.state.heatOptions} series={this.state.heatPastSeries} type="heatmap" height={180} />
                                                                    : null}
                                                                    </div>
                                                                    </React.Fragment>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-content-center w-50">
                                                                <div className="w-60 pr-2">
                                                                    <React.Fragment>
                                                                    <span className="d-flex justify-content-center mt-3">Future</span>
                                                                    <div className="mt-n3">
                                                                        {this.state.heatOptions && this.state.heatFutureSeries ?
                                                                            <Chart options={this.state.heatOptions} series={this.state.heatFutureSeries} type="heatmap" height={180} />
                                                                        : null}
                                                                    </div>
                                                                    </React.Fragment>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div> 
                                                <div className="rounded bg-muted p-3 mt-2">
                                                    <p className="small m-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et, sed do eiusmod tempor incididunt ut labore et.</p>
                                                    <div className="mt-2">
                                                        <div className={`d-flex justify-content-between mb-2`}>
                                                            <div className={`d-flex`}>
                                                                <p className="m-0 align-self-end small mr-2">Show </p>
                                                                <div className={`transparentSelectBorderBottom align-self-end`}>
                                                                    <select className="form-control"
                                                                        onChange={e => this.setState({ showPeakDipType: e.target.value},
                                                                            () =>this.filterTableResults("type")
                                                                        )}                         
                                                                            value={this.state.showPeakDipType}
                                                                        >
                                                                            <option value={""}>All</option>
                                                                            <option value={"peak"}>Peak</option>
                                                                            <option value={"dip"}>Dip</option>
                                                                    </select>
                                                                </div>
                                                                {this.state.selectedFrequency === "Hourly" ?
                                                                    <React.Fragment>
                                                                    <p className="m-0 align-self-end small mr-2"> data metrics type</p>
                                                                    <div className={`transparentSelectBorderBottom align-self-end`}>
                                                                        <select className="form-control"
                                                                            onChange={e => this.setState({ showFuturePastData: e.target.value},
                                                                                () =>this.filterTableResults("metrics")
                                                                            )}                         
                                                                                value={this.state.showFuturePastData}
                                                                            >
                                                                                <option value={""}>All</option>
                                                                                <option value={"future"}>Future</option>
                                                                                <option value={"past"}>Past</option>
                                                                        </select>
                                                                    </div>
                                                                    </React.Fragment>
                                                                : null}
                                                            </div>
                                                            {/* <div className={`d-flex`}>
                                                                <p className="m-0 align-self-end small mx-2">sort</p>
                                                                <p className="m-0 align-self-end small mr-2">Sort by</p>
                                                                <div className={`transparentSelectBorderBottom align-self-end`}>
                                                                    <select className="form-control"
                                                                        onChange={e => this.setState({ sortBy: e.target.value},
                                                                            () =>this.sortBy("peak")
                                                                        )}                         
                                                                            value={this.state.sortBy}
                                                                        >
                                                                            <option value={"date"}>Date</option>
                                                                            <option value={"time_range"}>Type</option>
                                                                            <option value={"extrema_freq"}>Frequency</option>
                                                                            <option value={"extrema_value"}>Metrics Type</option>
                                                                    </select>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                        {this.state.selectedFrequency === "Daily" ?
                                                            <div className="d-flex justify-content-center transparentTooltip">
                                                                <div className="w-50 border-right-info pr-3">
                                                                    <ResizeableDarkThemeTable
                                                                        columns={[
                                                                            {
                                                                                Header: "Date",
                                                                                accessor: "date",
                                                                                Cell: cellInfo => (<span>{cellInfo.row.original.date ? momentConvertionUtcToLocalTime(cellInfo.row.original.date, 'DD MMM YYYY')  : ''}</span>),
                                                                                width: 150
                                                                            },
                                                                            {
                                                                                Header: "Past",
                                                                                accessor: d => d.day +" : "+ d.extrema_type, //+" : "+ d.extrema_freq +" data"
                                                                                width: 250
                                                                            },
                                                                            // {
                                                                            //     Header: "Day",
                                                                            //     accessor: "day"
                                                                            // },
                                                                            // {
                                                                            //     Header: "Type",
                                                                            //     accessor: "extrema_type",
                                                                            //     Cell: cellInfo => (<span>{cellInfo.row.original.extrema_type ? capitalizeFirstLetter(cellInfo.row.original.extrema_type)  : ''}</span>),
                                                                            // },
                                                                            // {
                                                                            //     Header: "Frequency",
                                                                            //     accessor: "extrema_freq"
                                                                            // },
                                                                            // {
                                                                            //     Header: "Metrics Type",
                                                                            //     Cell: cellInfo => (<span>{cellInfo.row.original.past_metrics ? "Past" : cellInfo.row.original.past_metrics ? "Future" : ''}</span>)
                                                                            // }
                                                                        ]}
                                                                        data={this.state.filteredPastData ? this.state.filteredPastData : []}
                                                                        perPage={10}
                                                                        dashboard={true}
                                                                        sortByColumn={"date"}
                                                                        riskTooltip={[]}
                                                                        tableHead={'bg-dark'}
                                                                        deSelectBackground={true}
                                                                        tableType={"table-dark"}
                                                                        onClickRow={tableRow => {}}
                                                                        emptyMessage={"There is no data on this criteria"}
                                                                    />
                                                                </div>
                                                                <div className="w-50 pl-3">
                                                                    <ResizeableDarkThemeTable
                                                                        columns={[
                                                                            {
                                                                                Header: "Date",
                                                                                accessor: "date",
                                                                                Cell: cellInfo => (<span>{cellInfo.row.original.date ? momentConvertionUtcToLocalTime(cellInfo.row.original.date, 'DD MMM YYYY')  : ''}</span>),
                                                                                width: 150
                                                                            },
                                                                            {
                                                                                Header: "Future",
                                                                                accessor: d => d.day +" : "+ d.extrema_type, //+" : "+ d.extrema_freq +" data"
                                                                                width: 250
                                                                            },
                                                                            // {
                                                                            //     Header: "Metrics Type",
                                                                            //     Cell: cellInfo => (<span>{cellInfo.row.original.past_metrics ? "Past" : cellInfo.row.original.past_metrics ? "Future" : ''}</span>)
                                                                            // }
                                                                        ]}
                                                                        data={this.state.filteredFutureData ? this.state.filteredFutureData : []}
                                                                        perPage={10}
                                                                        dashboard={true}
                                                                        sortByColumn={"date"}
                                                                        riskTooltip={[]}
                                                                        tableHead={'bg-dark'}
                                                                        deSelectBackground={true}
                                                                        tableType={"table-dark"}
                                                                        onClickRow={tableRow => {}}
                                                                        emptyMessage={"There is no data on this criteria"}
                                                                    />
                                                                </div>
                                                            </div>
                                                        :
                                                            <ResizeableDarkThemeTable
                                                                columns={[
                                                                    {
                                                                        Header: "Date",
                                                                        accessor: "date",
                                                                        Cell: cellInfo => (<span>{cellInfo.row.original.date ? momentConvertionUtcToLocalTime(cellInfo.row.original.date, 'DD MMM YYYY HH:mm')  : ''}</span>),
                                                                        width: 200
                                                                    },
                                                                    {
                                                                        Header: "Day",
                                                                        accessor: "day"
                                                                    },
                                                                    {
                                                                        Header: "Type",
                                                                        accessor: "extrema_type",
                                                                        Cell: cellInfo => (<span>{cellInfo.row.original.extrema_type ? capitalizeFirstLetter(cellInfo.row.original.extrema_type)  : ''}</span>),
                                                                    },
                                                                    {
                                                                        Header: "Frequency",
                                                                        accessor: "extrema_freq"
                                                                    },
                                                                    {
                                                                        Header: "Metrics Type",
                                                                        Cell: cellInfo => (<span>{cellInfo.row.original.past_metrics ? "Past" : cellInfo.row.original.past_metrics ? "Future" : ''}</span>)
                                                                    }
                                                                ]}
                                                                data={this.state.filteredPeakDipData ? this.state.filteredPeakDipData : []}
                                                                perPage={10}
                                                                dashboard={true}
                                                                sortByColumn={"date"}
                                                                riskTooltip={[]}
                                                                tableHead={'bg-dark'}
                                                                deSelectBackground={true}
                                                                tableType={"table-dark"}
                                                                onClickRow={tableRow => {}}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
		)
	}
}

/**
 * Type of the props used in the component
 */
PeakDipMetrics.propTypes = {}

const mapStateToProps = state => {
	// console.log('observability query page',state)
	return {
		scheduleMonMetricProps: state.assets.resourcesPropsDetails && state.assets.resourcesPropsDetails.scheduleMonMetricProps ? state.assets.resourcesPropsDetails.scheduleMonMetricProps : [],
	}
}

export default connect(mapStateToProps, {
	setResourcePropsDetails,
	getMonitoringMetricsByTrend,
    getPeakDipDetectionResults
})(withRouter(PeakDipMetrics))