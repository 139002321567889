/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file CaseDetails.js
 * @author Prakash // on 10/02/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { URL_PATH } from '../../config/urlPath'
import { listAllRegions } from '../../actions/commonAction'
import { AppWrapper } from '../common/AppWrapper'
import { momentConvertionUtcToLocalTime, twoDateDiffrenceDayHoursMinutes, getRegionName, capitalizeFirstLetter } from '../../utils/utility'

import RemediationLeftSection from './remediation/RemediationLeftSection'
import RemediationRightSection from './remediation/RemediationRightSection'

import CloseSidePanel from '../aiops/CloseSidePanel'

// const html = `
//   <p id="main">
//     <span class="prettify">
//       keep me and make me pretty!
//     </span>
//   </p>
// `;

class ChecksDetail extends Component {
    constructor(props) {
        super(props)
        this.scrollRef = React.createRef()
        this.state = {
            caseRecord: {},
            showLoading: false,	
            regions: [],
            selectedRecord: [],
            selectedRecordId: '',
            startProcessorId: '',
            caseId: 'a22f200b-93a0-4813-8b44-0ca4ca0b5cfd',
            checkDetails: this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.identifier ? this.props.history.location.state.identifier : '',
            caseView: this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.caseView ? this.props.history.location.state.caseView : '',
            activeParentTab: 'Remediation', 
            activeTopTab: 'Events'
        }
    }

    componentDidMount = () => {
        console.log('this.props.history', this.props.history)
        // if(this.props.aiopsPropsDetails && this.props.aiopsPropsDetails.selectedCase) {
        //     this.setState({ caseId: this.props.aiopsPropsDetails.selectedCase.case_id })
        // }
    }

    componentDidUpdate = (prevProps, prevState) => {

        if(this.props.caseDetails !== prevProps.caseDetails || this.state.regions !== prevState.regions) {
            this.getRegion()
        }
    }

    getCaseDetails = (caseId) => {
        this.props.setAiopsPropsDetails('caseDetails', {})
        let caseDetailParams = {}
        caseDetailParams.case_id = caseId
        caseDetailParams.alerts = true
        caseDetailParams.assets = true
        this.props.getCaseDetails(caseDetailParams, (promise, result) => {})
    }

    getRegion = () => {
        if(!this.props.regions || !this.props.regions[0] || !this.props.regions[0].provider_regions) {
            let params = {
                provider: this.props.caseDetails ? (Array.isArray(this.props.caseDetails.provider) ? this.props.caseDetails.provider[0] : this.props.caseDetails.provider) : ''
            }

            if(params.provider && params.provider !== '') {
                this.props.listAllRegions(params, (promise, regions) => {
                    if(promise) {
                        this.setState({ regions: regions && regions[0] && regions[0].provider_regions })
                    }
                })
            }
        } else {
            this.setState({ regions: this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions })
        }
    }

	render() {
		return (
            this.state.caseId !== '' ?
                <div className={`container-fluid overflow-auto flex-grow-1`}>
                    {this.state.showCloseSection ? 
						<CloseSidePanel 
							closeSidePanel={() => this.setState({ showCloseSection: false })}
							caseId = {this.state.caseId}
							onClickCloseCase={caseId => this.getCaseDetails(caseId)}
						/>
					: null 
					}
                    <div className='row h-100'>
                        <div className={`${this.state.activeTopTab === 'Topology' 
                            ? (!this.state.fullScreen ? 'col-md-7' : 'col-md-12') 
                            : (this.state.activeParentTab === 'Remediation' ? 'col-md-7' : 'col-md-6')} 
                            p-0 bg-light-50`}>
                            <div className="title-section pb-3">
                                <div className='row d-flex justify-content-between'>
                                    <div className='d-flex align-self-center col-7'>
                                        <div className='mr-3'>
                                            <h6 className='m-0 text-white'>
                                                <Link
                                                    className='text-white far fa-arrow-left'
                                                    to={{
                                                        pathname: URL_PATH.AIOPS_DASHBOARD,
                                                        state:{ caseView: this.state.caseView }
                                                    }}
                                                >
                                                </Link>
                                            </h6>
                                        </div>
                                        <div>
                                            <h6 className="text-white m-0 f16 text-break">{this.state.checkDetails && this.state.checkDetails.title ? this.state.checkDetails.title : ''}</h6>
                                            <p className="text-white m-0">#{this.state.checkDetails && this.state.checkDetails.identifier_id}</p>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end col-5 text-white">
                                        <div className="w-75">
                                            <p className="b-block mb-0">Provider:</p>
                                            <p className="mb-0 f12"><b>{this.state.checkDetails && this.state.checkDetails.provider ? (Array.isArray(this.state.checkDetails.provider) ? this.state.checkDetails.provider[0].toUpperCase() : this.state.checkDetails.provider.toUpperCase()) : ''}</b></p>
                                        </div>
                                        <div className="w-100">
                                            <p className="b-block mb-0">Account:</p>
                                            <p className="mb-0 f12"><b>{this.state.checkDetails && this.state.checkDetails.account_id ? (Array.isArray(this.state.checkDetails.account_id) ? this.state.checkDetails.account_id[0] : this.state.checkDetails.account_id) : ''}</b></p>
                                        </div>
                                        <div className="w-100">
                                            <p className="b-block mb-0">Region:</p>
                                            <p className="mb-0 f12"><b>{this.state.checkDetails && this.state.checkDetails.region ? (Array.isArray(this.state.checkDetails.region) ? getRegionName(this.state.checkDetails.region[0], this.state.regions) : getRegionName(this.state.checkDetails.region, this.state.regions)) : ''}</b></p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-12 d-flex justify-content-between">
                                        <div className="d-flex">
                                            <p className="text-white mb-0 mr-3">Created Date: <b>{this.state.checkDetails && this.state.checkDetails.timestamp ? momentConvertionUtcToLocalTime(this.state.checkDetails.timestamp, 'DD MMM YYYY HH:mm:ss') : ''}</b></p>
                                            <p className="text-white mb-0 displayNone">Last Modified: <b>
                                                    {this.props.caseDetails && this.props.caseDetails.last_change && this.props.caseDetails.last_change !== '' ?
                                                        twoDateDiffrenceDayHoursMinutes(this.props.caseDetails.last_change, 'currentTime')+' ago'
                                                    : this.props.caseDetails && this.props.caseDetails.last_event && this.props.caseDetails.last_event !== '' ?
                                                        twoDateDiffrenceDayHoursMinutes(this.props.caseDetails.last_event, 'currentTime')+' ago'
                                                    : ''}
                                                </b>
                                            </p>
                                            <p className="text-white mb-0 mr-3">Asset: <b>{this.state.checkDetails && this.state.checkDetails.asset_id ? this.state.checkDetails.asset_id : ''}</b></p>
                                            <p className="text-white mb-0 mr-3">Resource: <b>{this.state.checkDetails && this.state.checkDetails.resource_type ? this.state.checkDetails.resource_type +' : ' +(this.state.checkDetails.service_name ? this.state.checkDetails.service_name : '') : ''}</b></p>
                                        </div>
                                        <div className="d-flex text-light">
                                            {this.state.checkDetails ?
                                            <div class="d-flex">
                                                <label class="mb-0">Severity</label>
                                                <span class={`badge badge-${this.state.checkDetails.risk ? (this.state.checkDetails.risk === 'Critical' ? 'danger' : this.state.checkDetails.risk === 'High' ? 'orange' : this.state.checkDetails.risk === 'Medium' ? 'purple' : this.state.checkDetails.risk === 'Low' ? 'success' : this.state.checkDetails.risk === 'Warning' ? 'warning ' : 'secondary') : 'secondary'} ml-1`}>{this.state.checkDetails.risk ? this.state.checkDetails.risk : 'Unknown'}</span>
                                            </div>
                                            : null}
                                            {this.state.checkDetails && this.state.checkDetails.status ?
                                            <div class="ml-3 d-flex">
                                                <label class="mb-0">Status</label>
                                                <span class="badge badge-danger mx-1">{capitalizeFirstLetter(this.state.checkDetails.status)}</span>
                                            </div>
                                            : null}
                                            
                                            <div className="ml-2 btn-group dropDown" onClick={() => this.setState({ showCaseDetailDropDown: !this.state.showCaseDetailDropDown })}>
                                                <i className="far fa-cog f15 align-self-center cursorPointer"></i>
                                                <div className={`dropdown-menu dropdown-menu-right bg-gray2 cursorPointer ${this.state.showCaseDetailDropDown ? 'show active' : ''}`}>
                                                    {this.props.caseDetails && this.props.caseDetails.status && this.props.caseDetails.status !== 'Closed' ?
                                                        <button className="dropdown-item" type="button" onClick={() => this.setState({ showCloseSection: true })}>
                                                            Close
                                                        </button>
                                                    : null}
                                                    <button className="dropdown-item" type="button">None</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                
                            <div className="box rounded-0 shadow-none bg-transparent border-right">
                                <ul className={`nav nav-tabs bd-highlight bg-white shadow-none ${this.state.showLoading ? 'disabled' : ''}`} id="myTab" role="tablist">
                                    <li className="nav-item displayNone">
                                        <span className={`nav-link cursorPointer ${this.state.activeParentTab === 'Analysis' ? ' active' : ''}`} onClick={() => this.setState({ activeParentTab: 'Analysis', activeTopTab: 'Alert' })}>Analysis</span>
                                    </li>
                                    <li className="nav-item">
                                        <span className={`nav-link cursorPointer ${this.state.activeParentTab === 'Remediation' ? ' active' : ''}`} onClick={() => this.setState({ activeParentTab: 'Remediation' })}>Remediation</span>
                                    </li>
                                </ul>
                                <div className={`tab-content p-0 ${this.state.activeParentTab === 'Analysis' ? '' : 'displayNone'}`}>
                                    <div className="tab-pane fade show active" id="home" role="tabpanel"
                                        aria-labelledby="home-tab">
                                        <ul className={`nav nav-tabs bd-highlight bg-white shadow-none ${this.state.showLoading ? 'disabled' : ''}`} id="myTab"
                                            role="tablist">
                                            <li className="nav-item">
                                                <span className={`nav-link cursorPointer ${this.state.activeTopTab === 'Alert' ? ' active' : ''}`} onClick={() => this.setState({ activeTopTab: 'Alert' })}>Alerts</span>
                                            </li>
                                            <li className="nav-item">
                                                <span className={`nav-link cursorPointer ${this.state.activeTopTab === 'Events' ? ' active' : ''}`} onClick={() => this.setState({ activeTopTab: 'Events' })}>Events</span>
                                            </li>
                                            <li className="nav-item">
                                                <span className={`nav-link cursorPointer ${this.state.activeTopTab === 'Assets' ? ' active' : ''}`} onClick={() => this.setState({ activeTopTab: 'Assets' })}>Assets</span>
                                            </li>
                                            <li className="nav-item">
                                                <span className={`nav-link cursorPointer ${this.state.activeTopTab === 'Topology' ? ' active' : ''}`} onClick={() => this.setState({ activeTopTab: 'Topology' })}>Topology</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={`vh-border-right`}>
                                {this.state.activeParentTab === 'Remediation' ?
                                    <RemediationLeftSection 
                                        caseId = {this.state.caseId}
                                    />                                
                                : null
                                }
                            </div> 
                        </div>
                        <div className={`${this.state.activeTopTab === 'Topology' 
                            ? (!this.state.fullScreen ? 'col-md-5' : 'col-md-12')
                            : (this.state.activeParentTab === 'Remediation' ? 'col-md-5' : 'col-md-6')}`}>
                            {this.state.activeParentTab === 'Remediation' ?
                                <RemediationRightSection
                                    caseId = {this.state.caseId}
                                />
                            : null
                            }
                        </div>
                    </div>
                </div>
            :
                <Redirect to={URL_PATH.AIOPS_DASHBOARD} />
		)
	}
}

/**
 * Type of the props used in the component
 */
 ChecksDetail.propTypes = {
    listAllRegions: PropTypes.func,
    getCaseDetails: PropTypes.func,
    setAiopsPropsDetails: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    console.log('mapStateToProps', state)
	return {
		detectors: state.detectors.detectors.results,
    }
}

export default AppWrapper(ChecksDetail, mapStateToProps, {
    listAllRegions
})