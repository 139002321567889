/*************************************************
 * Tvastar
 * @exports
 * @file Suppressor.js
 * @author Rajasekar // on 02/01/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Row, FormGroup, Label, Input, Spinner } from 'reactstrap'

import { AppWrapper } from '../common/AppWrapper'
import Table from '../designComponents/Table/Table'
import { listAllSuppressors, createSupression } from '../../actions/detectors/suppressorsAction'
import { listAllProviders, listAllServices, listAllRegions, listAllAccounts } from '../../actions/commonAction'
import Search from '../common/SearchComponent'
import ModalWrapper from '../common/ModalWrapper'
import _ from 'lodash'
import Select from 'react-select'

class Suppressor extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showLoading: false,
			showModal: false,
			tagsButtonClicked: false,
			selectedTableRow: {},
			filteredArray: [],
			providers: [],
			services: [],
			accounts: [],
			regions: [],
			selectedProvider: 'All',
			selectedService: '',
			selectedRegion: '',
			selectedAccount: '',

			newRuleTags: [{ key: '', value: '' }],
			ruleName: '',
			ruleDescription: '',
			ruleDetectorId: '',
			ruleDuration: '',
			ruleProvider: '',
			ruleOrganization: '',
			ruleAccount: '',
			ruleRegion: '',
			ruleService: '',
			ruleAsset: '',

			responseMessage: '',
			showMessage: false,
			creatingRule: false,
		}
	}

	componentDidMount = () => {
		
		this.props.listAllProviders((promise, providers) => {
			if (promise) {
				if (providers.length) {
					let dataRow = {}
					dataRow.provider_id = "All"
					dataRow.provider_name = "All"
					providers.unshift(dataRow);
				}
				this.setState({ providers })
			}
		})

		this.onSearch("onLoad")
	}

	onSearch = (type) => {
		if(this.state.changesInFilter || type === "onLoad") {
            this.setState({ 
                changesInFilter: false,
                showLoading: true,
                resetEnabled: type === 'onLoad' ? false : true
            },
                () => {
					this.filterIdentifiers()
                }
            )
        }
	}
	
	onReset = () => {
		this.setState({ 
            selectedProvider: "All",
			selectedService: "",
            selectedRegion: "",
            changesInFilter: this.state.resetEnabled,
        },
			() => this.onSearch()
        )
	}

	getFilterDetails = () => {
		let params = {
			"provider": this.state.selectedProvider.toLowerCase()
		}
		
		this.props.listAllRegions(params, (promise, response) => {
			if (promise) {
				this.setState({ regions: response && response.length ? response[0].provider_regions : []})
			}
		})
		this.props.listAllAccounts(params, (promise, response) => {
			if (promise) {
				this.setState({ accounts: response })
			}
		})

		this.props.listAllServices(params, (promise, response) => {
			if (promise) {
				this.setState({ services: response }, 
					() => {
						this.formSericeSelect()	
					}
				)
			}
		})
	}

	formSericeSelect = () => {
		let data = []
		if(this.state.services && this.state.services.length) {
			_.chain(this.state.services)
				.groupBy('resource_type')
				.map((value, key) => ({ label: key, sub: value }))
				.value()
				.forEach((service, i) => {
					let child = []
					service.sub.forEach((item, i) => {
						let childRow = {}
						childRow.value = service.service_name
						childRow.label = item.service_name
						child.push(childRow)
					})
					let dataRow = {}
					dataRow.label = service.label
					dataRow.options = child

					data.push(dataRow)
				})
		}

		this.setState({ servicesOptGrop: data })
	}

	filterIdentifiers = () => {
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'All') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if (this.state.selectedService !== '' && this.state.selectedService !== 'all') {
			params.service_name = this.state.selectedService
		}
		if (this.state.selectedRegion !== '' && this.state.selectedRegion !== 'all') {
			params.region = this.state.selectedRegion
		}

		this.props.listAllSuppressors(params, suppressorsArray => {
			if (suppressorsArray && suppressorsArray.length) {
				this.setState({ showLoading: false, selectedTableRow: this.props.suppressors[0], filteredArray: this.props.suppressors })
			}
			this.setState({ showLoading: false })
		})
	}
	/**
	 * Add new rule form
	 */
	addNewRuleTags = () => {
		const temp = this.state.newRuleTags
		temp.push({
			key: '',
			value: '',
		})
		this.setState({ newRuleTags: temp })
	}
	/**
	 * Delete new rule form
	 */
	deleteNewRuleTags = index => {
		if (this.state.newRuleTags.length === 1) {
			this.setState({ tagsButtonClicked: false })
			const temp = this.state.newRuleTags
			temp.splice(index, 1)
			temp.push({ key: '', value: '' })
			this.setState({ newRuleTags: temp })
		} else {
			const temp = this.state.newRuleTags
			temp.splice(index, 1)
			this.setState({ newRuleTags: temp })
		}
	}
	/**
	 * Handles on submit new rule form
	 */
	onSubmitNewRuleForm = () => {
		if (
			this.state.ruleName === '' ||
			this.state.ruleProvider === '' ||
			this.state.ruleDescription === '' ||
			this.state.ruleDetectorId === '' ||
			this.state.ruleDuration === '' ||
			this.state.ruleService === '' ||
			this.state.ruleOrganization === '' ||
			this.state.ruleAccount === '' ||
			this.state.ruleRegion === '' ||
			this.state.ruleAsset === '' ||
			this.state.newRuleTags[0].key === '' ||
			this.state.newRuleTags[0].value === ''
		) {
			this.setState({ hasErrorInRuleForm: true })
		} else {
			this.setState({ creatingRule: true })
			const formData = {
				name: this.state.ruleName,
				provider: this.state.ruleProvider,
				description: this.state.ruleDescription,
				module_id: this.state.ruleDetectorId,
				duration: this.state.ruleDuration,
				service_name: this.state.ruleService,
				organization: this.state.ruleOrganization,
				account_id: this.state.ruleAccount,
				region: this.state.ruleRegion,
				asset_name: this.state.ruleAsset,
				tags: this.state.newRuleTags,
				module_name: 'identifier',
			}
			this.props.createSupression(formData, res => {
				this.setState({
					newRuleTags: [{ key: '', value: '' }],
					ruleName: '',
					ruleDescription: '',
					ruleDetectorId: '',
					ruleDuration: '',
					ruleProvider: '',
					ruleOrganization: '',
					ruleAccount: '',
					ruleRegion: '',
					ruleService: '',
					ruleAsset: '',
					selectedProvider: '',
					selectedService: '',
					selectedRegion: '',
					selectedAccount: '',
					responseMessage: res.message,
					showMessage: true,
					creatingRule: false,
					hasErrorInRuleForm: false,
				})
			})
		}
	}

	/**
	 * Renders suppressor screen design
	 */
	render() {
		const { showLoading, regions, showModal } = this.state
		return (
			<div className='container-fluid overflow-auto flex-grow-1'>
				<ModalWrapper
					showModal={showModal}
					modalTitle='New Rule'
					buttonPrimaryLabel='Save'
					buttonSecondaryLabel='Cancel'
					onClickPrimaryButton={() => this.onSubmitNewRuleForm()}
					toggle={boolean => this.setState({ showModal: boolean })}
					showLoading={this.state.creatingRule}
					responseMessage={this.state.responseMessage}
					showMessage={this.state.showMessage}
				>
					<Row>
						<Col md={6}>
							<FormGroup>
								<Label>Name</Label>
								<Input
									type='text'
									placeholder='Name'
									value={this.state.ruleName}
									onChange={event => this.setState({ ruleName: event.target.value })}
								/>
								{this.state.hasErrorInRuleForm && this.state.ruleName === '' ? (
									<p className='text-danger'>Name is required.</p>
								) : (
									''
								)}
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup>
								<Label>Detector ID</Label>
								<Input
									type='text'
									placeholder='Detector ID'
									value={this.state.ruleDetectorId}
									onChange={event => this.setState({ ruleDetectorId: event.target.value })}
								/>
								{this.state.hasErrorInRuleForm && this.state.ruleDetectorId === '' ? (
									<p className='text-danger'>Detector Id is required.</p>
								) : (
									''
								)}
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col md={6}>
							<FormGroup>
								<Label>Description</Label>
								<Input
									type='text'
									placeholder='Description'
									value={this.state.ruleDescription}
									onChange={event => this.setState({ ruleDescription: event.target.value })}
								/>
								{this.state.hasErrorInRuleForm && this.state.ruleDescription === '' ? (
									<p className='text-danger'>Description is required.</p>
								) : (
									''
								)}
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup>
								<Label>Duration</Label>
								<Input
									type='text'
									placeholder='Duration'
									value={this.state.ruleDuration}
									onChange={event => this.setState({ ruleDuration: event.target.value })}
								/>
								{this.state.hasErrorInRuleForm && this.state.ruleDuration === '' ? (
									<p className='text-danger'>Duration is required.</p>
								) : (
									''
								)}
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col md={6}>
							<FormGroup>
								<Label>Provider</Label>
								<Input
									disabled={!this.state.providers.length}
									type='select'
									value={this.state.selectedProvider}
									onChange={event => {
										this.setState(
											{
												selectedProvider: event.target.value,
												ruleProvider: event.target.value,
											},
											() => {
												this.getFilterDetails()
												this.filterIdentifiers()
											}
										)
									}}
								>
									<option value=''>Select</option>
									{this.state.providers.map((provider, index) => {
										return (
											<option key={index} value={provider.provider_name}>
												{provider.provider_name}
											</option>
										)
									})}
								</Input>
								{this.state.hasErrorInRuleForm && this.state.ruleProvider === '' ? (
									<p className='text-danger'>Provider is required.</p>
								) : (
									''
								)}
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup>
								<Label>Organization</Label>
								<Input
									type='select'
									value={this.state.ruleOrganization}
									onChange={event => {
										this.setState({
											ruleOrganization: event.target.value,
										})
									}}
								>
									<option value=''>Select</option>
									<option>1</option>
									<option>2</option>
									<option>3</option>
									<option>4</option>
									<option>5</option>
								</Input>
								{this.state.hasErrorInRuleForm && this.state.ruleOrganization === '' ? (
									<p className='text-danger'>Organization is required.</p>
								) : (
									''
								)}
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col md={6}>
							<FormGroup>
								<Label>Account</Label>
								<Input
									disabled={!this.state.accounts.length}
									type='select'
									value={this.state.selectedAccount}
									onChange={event => {
										this.setState(
											{
												selectedAccount: event.target.value,
												ruleAccount: event.target.value,
											},
											() => this.filterIdentifiers('account')
										)
									}}
								>
									<option value=''>Select</option>
									{this.state.accounts.map((account, index) => {
										return (
											<option key={index} value={account.account_name}>
												{account.account_name}
											</option>
										)
									})}
								</Input>
								{this.state.hasErrorInRuleForm && this.state.ruleAccount === '' ? (
									<p className='text-danger'>Account is required.</p>
								) : (
									''
								)}
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup>
								<Label>Region</Label>
								<Input
									disabled={this.state.regions && this.state.regions.length ? true : false}
									type='select'
									value={this.state.selectedRegion}
									onChange={event => {
										this.setState(
											{
												selectedRegion: event.target.value,
												ruleRegion: event.target.value,
											},
											() => this.filterIdentifiers('region')
										)
									}}
								>
									<option value=''>Select</option>
									{regions && regions.length &&
										regions.map((region, index) => {
											return (
												<option key={index} value={region.region}>
													{region.name}
												</option>
											)
										})}
								</Input>
								{this.state.hasErrorInRuleForm && this.state.ruleRegion === '' ? (
									<p className='text-danger'>Region is required.</p>
								) : (
									''
								)}
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col md={6}>
							<FormGroup>
								<Label>Service</Label>
								<Input
									disabled={this.state.services && this.state.services.length ? true : false}
									type='select'
									value={this.state.selectedService}
									onChange={event => {
										this.setState(
											{
												selectedService: event.target.value,
												ruleService: event.target.value,
											},
											() => this.filterIdentifiers('')
										)
									}}
								>
									<option value=''>Select</option>
									{this.state.services &&
										this.state.services.length &&
										_.chain(this.state.services)
											.groupBy('resource_type')
											.map((value, key) => ({ label: key, sub: value }))
											.value()
											.map((service, i) => {
												return (
													<optgroup key={i} label={service.label}>
														{service.sub.map((item, i) => {
															return (
																<option key={i} value={service.service_name}>
																	{item.service_name}
																</option>
															)
														})}
													</optgroup>
												)
											})}
								</Input>
								{this.state.hasErrorInRuleForm && this.state.ruleService === '' ? (
									<p className='text-danger'>Service is required.</p>
								) : (
									''
								)}
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup>
								<Label>Asset</Label>
								<Input
									type='select'
									value={this.state.ruleAsset}
									onChange={event => {
										this.setState({
											ruleAsset: event.target.value,
										})
									}}
								>
									<option value=''>Select</option>
									<option>1</option>
									<option>2</option>
									<option>3</option>
									<option>4</option>
									<option>5</option>
								</Input>
								{this.state.hasErrorInRuleForm && this.state.ruleAsset === '' ? (
									<p className='text-danger'>Asset is required.</p>
								) : (
									''
								)}
							</FormGroup>
						</Col>
					</Row>
					{!this.state.tagsButtonClicked ? (
						<button onClick={() => this.setState({ tagsButtonClicked: true })} className='btn btn-sm btn-primary'>
							TAGS
						</button>
					) : (
						this.state.newRuleTags.map((rule, index) => {
							return (
								<>
									<div className='d-flex justify-content-start mt-3' key={index}>
										<div className='row'>
											<div className='col'>
												<input
													value={rule.key}
													onChange={event => {
														let tags = this.state.newRuleTags
														tags[index].key = event.target.value
														this.setState({ newRuleTags: tags })
													}}
													type='text'
													className='form-control form-control-sm'
													placeholder='Key'
												/>
											</div>
											:
											<div className='col'>
												<input
													value={rule.value}
													onChange={event => {
														let tags = this.state.newRuleTags
														tags[index].value = event.target.value
														this.setState({ newRuleTags: tags })
													}}
													type='text'
													className='form-control form-control-sm'
													placeholder='Value'
												/>
											</div>
										</div>
										<div>
											<i
												title='Add'
												onClick={() => this.addNewRuleTags()}
												className='fal fa-plus-circle p-1 fs-20 ml-3 text-success cursor-pointer'
											/>
											<i
												title='Delete'
												onClick={() => this.deleteNewRuleTags(index)}
												className='fal fa-trash-alt text-danger p-1 fs-20 cursor-pointer'
											/>
										</div>
									</div>
								</>
							)
						})
					)}
					{this.state.hasErrorInRuleForm &&
					(this.state.newRuleTags[0].key === '' || this.state.newRuleTags[0].value === '') ? (
						<p className='text-danger'>Tags is required.</p>
					) : (
						''
					)}
				</ModalWrapper>
				<div className='row h-100'>
					<div
						className={
							this.props.suppressors &&
							this.props.suppressors.length &&
							Object.entries(this.state.selectedTableRow).length !== 0 &&
							this.state.selectedTableRow.constructor === Object
								? 'col-sm-9 p-0'
								: 'col-sm-12 p-0'
						}
					>
						<div className='title-section'>							
							<div className={`row mt-3 ${showLoading ? 'disabled' : ''}`}>
								<div className="col-4 align-self-center">
									<h6 className="text-white m-0">Checks Suppressor</h6>
									<p className="text-white m-0">
										Manage user created Suppressor on your Cloud
									</p>
								</div>
								<div className="col-8 align-self-center">
									<div className={`col-md-12 d-flex flex-wrap justify-content-end ${this.state.showLoading ? "disabled" : ""}`}>
										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: "150px" }}>
											<label className="mb-1">Provider</label>
											<Select
												placeholder={"All"}
												isSearchable={false}
												// menuIsOpen={true}
												//onFocus={this.openProivderMenu}
												className="f13 p-0 colorBlack reactSelectFilterDrop"
												value={({
													value: this.state.selectedProvider,
													label: this.state.selectedProvider ? this.state.selectedProvider : <span className="placeholder">Select</span>
												})}
												options={this.state.providers && this.state.providers.map(item => ({
													value: item.provider_name,
													label: item.provider_name,	
												}))}
												onChange={event => this.setState({ selectedProvider: event.value, changesInFilter: true }, 
													() => {
														this.getFilterDetails()
													}
												)}
											/>
										</div>
										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: "150px" }}>
											<label className="mb-1">Region</label>
											<Select
												placeholder={"All"}
												isSearchable={false}
												// menuIsOpen={true}
												//onFocus={this.openProivderMenu}
												className="f13 p-0 colorBlack reactSelectFilterDrop"
												value={({
													value: this.state.selectedRegion,
													label: this.state.selectedRegion ? this.state.selectedRegion : <span className="placeholder">Select</span>
												})}
												options={regions && regions.map(item => ({
													value: item.region,
													label: item.name,	
												}))}
												onChange={event => this.setState({ selectedRegion: event.value, changesInFilter: true })}
											/>
										</div>
										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: "300px" }}>
											<label className="mb-1">Services</label>
											<Select
												placeholder={"Select"}
												// isSearchable={false}
												// menuIsOpen={true}
												//onFocus={this.openProivderMenu}
												className="f13 p-0 colorBlack reactSelectOptGroupFilterDrop"
												// label={({
												// 	label: this.state.selectedService,
												// 	// label: this.state.selectedService ? getRegionName(this.state.selectedService, regions) : <span className="placeholder">Select</span>
												// })}
												options={this.state.servicesOptGrop && this.state.servicesOptGrop}
												onChange={event => this.setState({ selectedService: event.label, changesInFilter: true })}
											/>
										</div>
										<div
											className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 displayNone ${
												this.state.services.length ? '' : 'disabled'
											}`}
										>
											<label>Service</label>
											<select
												className='form-control'
												onChange={event => {
													this.setState(
														{
															selectedService: event.target.value,
														},
														() => {
															this.setState({ showLoading: true }, () => this.filterIdentifiers('', true))
														}
													)
												}}
											>
												<option value='all'>All</option>
												{this.state.services &&
													this.state.services.length &&
													_.chain(this.state.services)
														.groupBy('resource_type')
														.map((value, key) => ({ label: key, sub: value }))
														.value()
														.map((service, i) => {
															return (
																<optgroup key={i} label={service.label}>
																	{service.sub.map((item, i) => {
																		return (
																			<option key={i} value={service.service_name}>
																				{item.service_name}
																			</option>
																		)
																	})}
																</optgroup>
															)
														})}
											</select>
										</div>
										<div className="mt-2 d-flex align-self-center">
											<div className="btn-group" role="group" aria-label="governance-dash">
												<button type="button" className={`${this.state.showLoading ? "disabled" : "cursorPointer"} btn btn-secondary border-right`} onClick={()=> this.onSearch()}>Search</button>
												<button type="button" className={`${this.state.showLoading ? "disabled" : "cursorPointer"} btn btn-secondary border-left`} onClick={()=> this.onReset()}><i className="far fa-redo"></i></button>
											</div>
										</div>
									</div>
									<div className='col-sm-12 d-flex justify-content-end mt-2 displayNone'>
										{/* <button className='btn btn-link p-0' onClick={() => this.setState({ showAdvancedSearchModal: true })}>
											Advanced Search
										</button>
										<button
											className='btn btn-link ml-3 p-0'
											onClick={() =>
												this.setState(
													{
														selectedProvider: '',
														selectedService: '',
														selectedRegion: '',
														selectedAccount: '',
													},
													() => this.filterIdentifiers()
												)
											}
										>
											Clear Filter
										</button> */}
									</div>
								</div>
							</div>
						</div>
						<div className="box custom-margin bg-dark box-shadow-none">
							<div className="col-12 d-flex justify-content-between py-2">
								<p className="mb-0 align-self-center">Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.props.suppressors && this.props.suppressors.length} suppressors</p>

								{this.props.suppressors && this.props.suppressors.length ?
									<div className={`mb-n2 ${this.state.showLoading ? "disabled" : ""}`}>
										<Search
											data={this.props.suppressors && this.props.suppressors}
											filteredData={filteredArray => this.setState({ filteredArray, selectedTableRow: filteredArray[0] })}
										/>
									</div>
								: null}
							</div>
							<div className='tab-content px-3' id='myTabContent'>
								<div className='tab-pane fade show active' id='home' role='tabpanel' aria-labelledby='home-tab'>
									{this.props.suppressors && this.props.suppressors.length && !showLoading ? (
										<Table
											columns={[
												{
													Header: 'Name',
													accessor: 'name',
												},
												{
													Header: 'Description',
													accessor: 'description',
													width: 200,
												},
												{
													Header: 'Detector Id',
													accessor: 'module_id',
												},
												{
													Header: 'Provider',
													accessor: 'provider',
												},
												{
													Header: 'Service',
													accessor: 'service_name',
												},
												{
													Header: 'Duration',
													accessor: 'duration',
												},
											]}
											data={this.state.filteredArray.length ? this.state.filteredArray : this.props.suppressors}
											onClickRow={tableRow => this.setState({ selectedTableRow: tableRow })}
										/>
									) : !showLoading && this.props.suppressors && !this.props.suppressors.length ? (
										<div className='d-flex justify-content-center m-4'>
											<p>There are no data on this criteria. Please try adjusting your filter.</p>
										</div>
									) : null}
									{showLoading ? (
										<div className='d-flex justify-content-center m-4'>
											<Spinner className='text-center' color='white' size='lg' />
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>
					<div className={this.props.suppressors && this.props.suppressors.length ? 'col-sm-3 p-0' : ''}>
						<div
							className={
								this.props.suppressors &&
								this.props.suppressors.length &&
								Object.entries(this.state.selectedTableRow).length !== 0 &&
								this.state.selectedTableRow.constructor === Object
									? 'detail'
									: 'd-none'
							}
						>
							<div className='detail-title'>
								<div className='d-flex'>
									<div className='align-self-center text-white'>
										<p className='m-0'>{this.state.selectedTableRow && this.state.selectedTableRow.module_id}</p>
									</div>
								</div>
								<span className='badge badge-pill badge-secondary p-2 mt-2 text-wrap'>
									{this.state.selectedTableRow && this.state.selectedTableRow.name}
								</span>
								<p className='mt-2 mb-0'>
									<small className='text-white mt-2'>
										{this.state.selectedTableRow && this.state.selectedTableRow.description}
									</small>
								</p>
							</div>
							<div className='detail-content'>
								<ul className='list-group'>
									<li className='list-group-item'>
										<span className='text-muted'>Provider ID :</span> <br />
										<span className='text-dark'>
											{this.state.selectedTableRow && this.state.selectedTableRow.provider}
										</span>
									</li>
									<li className='list-group-item'>
										<span className='text-muted'>Service :</span> <br />
										<span className='text-dark'>
											{this.state.selectedTableRow && this.state.selectedTableRow.service_name}
										</span>
									</li>
									<li className='list-group-item'>
										<span className='text-muted'>Asset :</span> <br />
										<span className='text-dark'>
											{this.state.selectedTableRow && this.state.selectedTableRow.asset_name}
										</span>
									</li>
									<li className='list-group-item'>
										<span className='text-muted'>Created at :</span> <br />
										<span className='text-dark'>
											{this.state.selectedTableRow && this.state.selectedTableRow.created_at}
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
Suppressor.propTypes = {
	listAllSuppressors: PropTypes.func,
	suppressors: PropTypes.array,
	listAllProviders: PropTypes.func,
	listAllRegions: PropTypes.func,
	listAllServices: PropTypes.func,
	createSupression: PropTypes.func,
	listAllAccounts: PropTypes.func,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		suppressors: state.suppressors.suppressors,
	}
}
export default AppWrapper(Suppressor, mapStateToProps, {
	listAllSuppressors,
	listAllProviders,
	listAllAccounts,
	listAllRegions,
	listAllServices,
	createSupression,
})
