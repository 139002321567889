/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormGroup, Label, Input, Col } from 'reactstrap'
import { setEditRuleDetails } from '../../../actions/governance/governanceAction'
import PropTypes from 'prop-types'

class ServiceLimits extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	render() {
		return (
			<div>
				{this.props.editRuleDetails.condition &&
				this.props.editRuleDetails.condition.limits &&
				this.props.editRuleDetails.condition.limits.length
					? this.props.editRuleDetails.condition.limits.map((parameter, parameter_index) => {
							return (
								<div className='d-flex ml-2' key={parameter_index}>
									<FormGroup>
										<Label>{parameter.label}</Label>
										<br />
										<small className='mb-3'>Lorem ipsum dolor sit amet, consectetur adipiscing.</small>
										{parameter.values &&
											parameter.values.map((item, i) => {
												return (
													<FormGroup row key={i}>
														<Col sm={6}>{item.parameter_name}</Col>
														<Col sm={6}>
															<Input
																type='number'
																name={parameter_index + '@' + i}
																id={parameter_index + '@' + i}
																value={item.parameter_value}
																onChange={event => {
																	let limits = this.props.editRuleDetails.condition.limits
																	let parameter_index = event.target.name.split('@')[0]
																	let index = event.target.name.split('@')[1]
																	let parameter_to_change = limits[parameter_index]
																	parameter_to_change.values.splice(index, 1, {
																		...parameter_to_change.values[index],
																		parameter_value: event.target.value,
																	})
																	limits.splice(parameter_index, 1, parameter_to_change)
																	this.props.setEditRuleDetails('condition', { limits: limits })
																}}
															/>
														</Col>
													</FormGroup>
												)
											})}
									</FormGroup>
								</div>
							)
					  })
					: null}
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ServiceLimits.propTypes = {
	logType: PropTypes.object,
	setEditRuleDetails: PropTypes.func,
	editRuleDetails: PropTypes.object,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		editRuleDetails: state.governance.editRuleDetails,
	}
}

export default connect(mapStateToProps, { setEditRuleDetails })(ServiceLimits)
