/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Metrics.js
 * @author Prakash // on 29/11/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { UncontrolledTooltip } from 'reactstrap'
import { getAlertsDailyTrend, getTotalAlertsCount } from '../../../actions/aiops/MetricsAction'
import { listAlertGroups, getAlertGroupDailyTrend } from '../../../actions/aiops/AlertAction'
import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'
import { listAlertsByAssets } from '../../../actions/aiops/DistributionAction'
import SearchWithHiddenInput from '../../common/SearchWithHiddenInput'

import { momentDateGivenFormat, momentConvertionUtcToLocalTime, capitalizeFirstLetter, twoDateTimeInterval, subMinutes, subHours, addMinutes, subDays, twoDateDiffrence } from '../../../utils/utility'
import _ from 'lodash'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'

import Chart from 'react-apexcharts'

class AlertLeftSection extends Component {
    
    constructor(props) {

        super(props)
        this.state = {
            showLoading: true,
            showMetricsLoading: false,
            alertDynamicColumns: [],
            totalAlertCount: [],
            showAlertType: 'timeline',
            selectedFilterType: 'H',
            selectedFilterPeriod: 1,
            groupedBy: 'severity',
            selectedCategory: 'Important / All'
        }
    }

	componentDidMount = () => {
        if(this.props.caseDetails  && Object.entries(this.props.caseDetails).length ) {
            this.changeGraphTimings()
        }
    }
        
    componentDidUpdate = (prevProps) => {

        if(prevProps.aiopsPropsDetails.alert_brush_changes)  {
            if(this.props.aiopsPropsDetails.alert_brush_changes.brushStartDate !== prevProps.aiopsPropsDetails.alert_brush_changes.brushStartDate || this.props.aiopsPropsDetails.alert_brush_changes.brushEndDate !== prevProps.aiopsPropsDetails.alert_brush_changes.brushEndDate) {
                // this.filterGroupData()
            }
        } 
        // else if(this.props.aiopsPropsDetails.alert_brush_changes && this.props.aiopsPropsDetails.alert_brush_changes.brushStartDate && this.props.aiopsPropsDetails.alert_brush_changes.brushEndDate) {
        //     this.filterGroupData()
        // }


        if(this.props.caseDetails && Object.entries(this.props.caseDetails).length && prevProps.caseDetails !== this.props.caseDetails) {
            this.changeGraphTimings()
        }
    }

    changeGraphTimings = () => {
        this.setState({ brushStartDate: '', brushEndDate: '' }, 
            () => {
                let caseStartTime = this.props.caseDetails.created_at
                let caseEndTime = addMinutes(this.props.caseDetails.last_event, 5)

                if(caseEndTime < caseStartTime) {
                    caseEndTime = caseStartTime
                }

                let selectedFilterType = this.state.selectedFilterType
                let selectedFilterPeriod = this.state.selectedFilterPeriod
    
                let sliderStartDate = caseStartTime
                let sliderEndDate = caseEndTime
                
                if(selectedFilterType === 'D') {
                    sliderStartDate = subDays(caseStartTime, selectedFilterPeriod)
                } else if(selectedFilterType === 'H') {
                    sliderStartDate = subHours(caseStartTime, selectedFilterPeriod)
                }

                let sliderObj = {}
                sliderObj.sliderStartDate = sliderStartDate
                sliderObj.sliderEndDate = sliderEndDate
                sliderObj.selectedFilterPeriod = selectedFilterPeriod
                sliderObj.selectedFilterType = selectedFilterType
                this.props.setAiopsPropsDetails('alert_slider_changes', sliderObj)
    
                this.setState({ caseStartTime, caseEndTime, sliderStartDate, sliderEndDate },
                    () => {
                        this.alertSection()
                        this.props.setAiopsPropsDetails('alert_page_details', {})
                        this.props.setAiopsPropsDetails('alertMonitoringList', {})
                        this.props.setAiopsPropsDetails('alertSreMetricsList', {})
                        this.props.setAiopsPropsDetails('alertMonitoringDetails', {})
                        this.props.setAiopsPropsDetails('alertMonitoringEvent', {})
                        this.props.setAiopsPropsDetails('alertSreMonitoringDetails', {})
                    }
                )   
            }
        )
    }

    alertSection = () => {
        let params = {}
        params.alerts_case_id = this.props.caseDetails.case_id
        params.start_time = this.state.sliderStartDate
        params.end_time = this.state.sliderEndDate

        this.props.getAlertsDailyTrend(params, (promise, response) => {
            if(promise) {
                this.setState({ showLoading: false, alertDailyTrend: response },
                    () => {
                        this.brushDuration()
                    }
                )
            } else {
                this.setState({ showLoading: false, alertDailyTrend: {} })
            }
        })

        params = {}
        params.alerts_case_id = this.props.caseDetails.case_id

        this.props.getAlertGroupDailyTrend(params, (promise, response) => {
            if(promise) {
                this.setState({ alerGrouptDailyTrend: response },
                    () => {
                        this.alertGroupBar()
                    }
                )
            } else {
                this.setState({ alerGrouptDailyTrend: {} })
            }
        })        

        let alertCountParams = {}
        alertCountParams.alerts_case_id = this.props.caseDetails.case_id
        alertCountParams.aggregate_by = ["severity"]
        alertCountParams.start_time = this.state.sliderStartDate
        alertCountParams.end_time = this.state.sliderEndDate
        this.props.getTotalAlertsCount(alertCountParams, (promise, response) => {})

        let detailParams = {}
        detailParams.alerts_case_id = this.props.caseDetails.case_id
        detailParams.start_time = this.state.sliderStartDate
        detailParams.end_time = this.state.sliderEndDate
        this.props.listAlertsByAssets(detailParams, (promise, response) => {
            if(promise) {
                this.setState({ alertsByAssets: response.results },
                    () => this.assetWiseAlertTimeline()
                )
            } else {
                this.setState({ alertsByAssets: {} })
            }
        })


        this.listAlertGroups()
    }

    listAlertGroups = () => {
        let params = {}
        params.alerts_case_id = this.props.caseDetails.case_id
        // params.start_time = this.state.sliderStartDate
        // params.end_time = this.state.sliderEndDate
        params.aggregate_by = this.state.groupedBy
        //params.important = this.state.groupType === 'All' ? false : true

        this.props.listAlertGroups(params, (promise, response) => {
            if(promise) {
                let data = response.results && response.results.details ? response.results.details : []
                this.setState({ groupAlertsResponse: data, showGroupLoading: false },
                    () => {
                        this.structureGroupAlerts()
                    }    
                )
            } else {
                this.setState({ groupAlerts: [] })
            }
        })
    }

    structureGroupAlerts = () => {
        let groupAllAlerts = []
        
        let totalCount = 0
        let groupAlertcategory = []
        if(this.state.groupAlertsResponse && this.state.groupAlertsResponse.length) {
            totalCount = this.state.groupAlertsResponse.reduce( function(a, b){ return a + b.count;}, 0);
            this.state.groupAlertsResponse.forEach((item, index) => {
                let dataRow = {}
                dataRow.count = item.count
                dataRow.category = item.label
                dataRow.orderBy = index+1
                let percentage = Math.ceil(((item.count / totalCount) * 100)/10)
                let array = []
                for(let i=1; i<=percentage; i++) {
                    array.push(i)
                }
                dataRow.totalBars = array
                
                groupAlertcategory.push(dataRow)
                item.alert_groups.forEach(alt => {
                    groupAllAlerts.push(alt)
                })
            })
        }

        let dataRow = {}
        dataRow.count = ''
        dataRow.category = 'Important / All'
        dataRow.orderBy = 0
        groupAlertcategory.push(dataRow)

        this.setState({ groupAllAlerts, groupAlerts: groupAllAlerts, groupAlertcategory: _.orderBy(groupAlertcategory, ['orderBy'], ['asc'])},
            () => this.getCategoryAlerts()
        )
    }

    getCategoryAlerts = (type) => {
        let filterdCategoryAlerts = this.state.groupAllAlerts
        if(this.state.selectedCategory !== 'Important / All') {
            filterdCategoryAlerts = this.state.groupAlertsResponse.filter(arr => arr.label === this.state.selectedCategory)
            if(filterdCategoryAlerts && filterdCategoryAlerts.length) {
                filterdCategoryAlerts = filterdCategoryAlerts[0].alert_groups ? filterdCategoryAlerts[0].alert_groups : []
            }
        }
        this.setState({ groupAlerts: filterdCategoryAlerts },
            () => this.filterData(type)
        )
    }

    filterData = (type) => {
        let data = this.state.groupAlerts ? this.state.groupAlerts : []

        if(type === "brush" && data.length && this.state.brushStartTime && this.state.brushStartTime !== "" && this.state.brushEndTime) {
            data = data.filter(d => {
                var time = this.getTimeLocal(momentConvertionUtcToLocalTime(d.created_at, 'YYYY-MM-DD HH:mm:ss'));
                return (time >= this.getTimeLocal(this.state.brushStartTime) && time <= this.getTimeLocal(this.state.brushEndTime));
            });
        }

        // let data = this.state.groupAlerts ? this.state.groupAlerts : []
        // if(data.length && this.props.aiopsPropsDetails.alert_brush_changes && this.props.aiopsPropsDetails.alert_brush_changes.brushStartDate && this.props.aiopsPropsDetails.alert_brush_changes.brushEndDate) {
        //     data = data.filter(d => {
        //         var time = this.getTimeLocal(momentConvertionUtcToLocalTime(d.created_at, 'YYYY-MM-DD HH:mm:ss'));
        //         return (time >= this.getTimeLocal(this.props.aiopsPropsDetails.alert_brush_changes.brushStartDate) && time <= this.getTimeLocal(this.props.aiopsPropsDetails.alert_brush_changes.brushEndDate));
        //     });
        // }

        this.setState({ totalFilteredArray: data, filteredArray: data },
            () => {
                if(this.state.filteredArray.length) {
                    let filteredArrayOrder = _.orderBy(this.state.filteredArray, ['event_time'], ['desc'])
                    this.getAlertGroupDetails(filteredArrayOrder[0])
                } else {
                    this.props.setAiopsPropsDetails('alert_page_details', {})
                }
            }
        )
    }

    brushDuration = () => {
        let sliderStartDate = this.state.sliderStartDate
        let sliderEndDate = this.state.sliderEndDate
        
        let difference = twoDateTimeInterval(this.state.sliderStartDate, this.state.sliderEndDate)

        let brushStartDate = sliderStartDate
        let brushEndDate = sliderEndDate

        if(difference && difference.length) {
            if(difference[0] > 0) {
                if(difference[0] === 1) {
                    brushStartDate = subHours(brushEndDate, 1)
                } else if(difference[0] < 2) {
                    brushStartDate = subHours(brushEndDate, 3)
                } else if(difference[0] < 3) {
                    brushStartDate = subHours(brushEndDate, 4)
                } else {
                    brushStartDate = subHours(brushEndDate, 8)
                }
            } else if(difference[1] > 0) {
                if(difference[1] <= 2) {
                    brushStartDate = subMinutes(brushEndDate, 20)
                } else if(difference[1] < 3) {
                    brushStartDate = subMinutes(brushEndDate, 30)
                } else if(difference[1] < 6) {
                    brushStartDate = subMinutes(brushEndDate, 50)
                } else if(difference[1] < 12) {
                    brushStartDate = subMinutes(brushEndDate, 1)
                } else if(difference[1] < 24) {
                    brushStartDate = subHours(brushEndDate, 1)
                }
            } else {
                if(difference[2] <= 2) {
                    brushStartDate = subMinutes(brushEndDate, .1)
                } else if(difference[2] <= 5) {
                    brushStartDate = subMinutes(brushEndDate, .5)
                } else if(difference[2] <= 10) {
                    brushStartDate = subMinutes(brushEndDate, 1)
                } else {
                    brushStartDate = subMinutes(brushEndDate, 5)
                }
            }
        }

        //convert brush time to local
        if(this.state.caseStartTime < brushEndDate) {
            brushStartDate = momentConvertionUtcToLocalTime(this.state.caseStartTime, 'YYYY-MM-DD HH:mm:ss')
        } else {
            brushStartDate = momentConvertionUtcToLocalTime(brushStartDate, 'YYYY-MM-DD HH:mm:ss')
        }
        brushEndDate = momentConvertionUtcToLocalTime(brushEndDate, 'YYYY-MM-DD HH:mm:ss')

        this.setState({ brushStartDate, brushEndDate, brushBarOptions: {}, }, 
            () => {
                this.alertBrushGraph()
            }
        )
    }

    alertBrushGraph = () => {
        let graphDetails = this.state.alertDailyTrend.results
        let array = []
        if(graphDetails && graphDetails.data && graphDetails.data.count) {
            let graphLabels = graphDetails.labels
            let alertTotalCount = graphDetails.data.count.reduce(function(a, b){
                return a + b;
            }, 0)
            graphDetails.data.count.forEach((val, index) => {
                let arrayValues = []
                if(!index) {
                    let time = momentConvertionUtcToLocalTime(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:00')
                    arrayValues.push(time)
                    arrayValues.push(0) 
                    array.push(arrayValues)
                }
                arrayValues = []
                let time = momentConvertionUtcToLocalTime(graphLabels[index], 'YYYY-MM-DD HH:mm:ss')
                arrayValues.push(time)
                arrayValues.push(val) 
                array.push(arrayValues)

                if(index === graphDetails.data.count.length-1) {
                    arrayValues = []
                    let time = momentConvertionUtcToLocalTime(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:00')
                    arrayValues.push(time)
                    arrayValues.push(0) 
                    array.push(arrayValues)
                }    
            })
            
            let brushBarSeries = [{
                data: array
            }]

            let xaxis = {
                tickAmount: 50,
                type: 'datetime',
                labels: {
                    datetimeUTC: false,
                    style: {
                        colors: '#60687C'
                    },
                },
                axisTicks: {
                    show: false
                },
                tooltip: {
                    enabled: false,
                },
                axisBorder: {
                    show: true,
                    color:'#434959',
                    widht: 2,
                    // offsetX: -15,
                    // offsetY: 0
                },
            }

            // let sliderObj = {}
            // sliderObj.brushStartDate = this.state.brushStartDate
            // sliderObj.brushEndDate = this.state.brushEndDate
            // this.props.setAiopsPropsDetails('alert_brush_changes', sliderObj)

            let columnWidth = '70%'
            if(brushBarSeries[0].data.length <= 10) {
                columnWidth = '10%'
            } else if(brushBarSeries[0].data.length <= 20) {
                columnWidth = '15%'
            } else if(brushBarSeries[0].data.length <= 30) {
                columnWidth = '20%'
            } else if(brushBarSeries[0].data.length <= 40) {
                columnWidth = '25%'
            } else if(brushBarSeries[0].data.length <= 50) {
                columnWidth = '30%'
            }
            
            let brushBarOptions = {
                chart: {
                    redrawOnParentResize: true,
                    id: 'chart1',
                    height: 130,
                    type: 'bar',
                    toolbar: {
                        show: false
                    },
                    brush:{
                        target: 'chartx',
                        enabled: true
                    },
                    selection: {
						enabled: true,
						fill: {
							color: "#c9ebf5",
							opacity: 0.1
						},  
						stroke: {
							show: false,
							curve: 'straight',
							lineCap: 'round',
							colors: '#039BE5',
							// colors: '#00A3CB',
							width: 0,
							dashArray: 0,      
						},
						xaxis: {
							min: this.getTimeLocal(this.state.brushStartDate),
                            max: this.getTimeLocal(this.state.brushEndDate)
						},
						style: {
							fontSize: '28px',
							color: '#000000'
						}
					},
                    events: {
                        selection: (chartContext, { xaxis, yaxis }) => {
                            this.brushSelection(xaxis)
                        }
                    },
                    // events:{
                    //     selection: (chartContext, { xaxis, yaxis }) => {
                    //         var minDate = new Date(xaxis.min);
                    //         var maxDate = new Date(xaxis.max);
                    //         let brushStartDate = momentDateGivenFormat(minDate, 'YYYY-MM-DD HH:mm:ss');
                    //         let brushEndDate = momentDateGivenFormat(maxDate, 'YYYY-MM-DD HH:mm:ss');
                    //         this.setState({ brushStartDate, brushEndDate },
                    //             () => {
                    //                 this.getAttribute()
                    //                 this.assetWiseAlertTimeline()
                    //             }
                    //         )
                    //     },
                    //     click: (event, chartContext, config) => {
                    //         this.setSliderPropDetails()
                    //     },
                    // }
                },
                stroke: {
                    width: 0
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        endingShape: 'rounded',
                        columnWidth: columnWidth,
                    }
                },

                colors: ['#19B2C0'],
                fill: {
                    colors: ['#039BE5'],
                    type: 'gradient', // gradient
                    gradient: {
                        type: 'vertical', // The gradient in the horizontal direction
                        gradientToColors: ['#5F5BA2'], // The color at the end of the gradient
                        opacityFrom: .9, // transparency
                        opacityTo: 1,
                        stops:[0, 120]
                    }
                },
                xaxis: xaxis,
                yaxis: {
                    show: false,
                    labels: {
                        show: false
                    }
                },            
                grid: {
                    position: 'back',
                    borderColor: '#2D323E',
                    show: true,
                    xaxis: {
                        lines: {
                            show: false
                        },
                    },
                    yaxis: {
                        lines: {
                            show: true
                        }
                    },
                    padding: {
                        top: 0,
                        right: 20,
                        bottom: 0,
                        left: 0
                    },
                },
                    
                tooltip: {
                    enabled: true,
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        show: false
                    },
                    marker: {
                        show: false
                    },
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]                    
                        let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
                        dateLable = dateLable.toString()
                        dateLable = momentDateGivenFormat(dateLable, 'DD MMM Y HH:mm:ss')
                        return '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+dateLable+'</div><div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[0] +'" ></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">Alerts</span><span class="font-weight-normal ml-2">'+val+'</span></div></div></div></div>'
                    },
                },
                annotations: {
                    position: 'back' ,
                    xaxis: [{
                        x:  new Date(momentConvertionUtcToLocalTime(this.state.caseStartTime, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                        strokeDashArray: 0,
                        borderColor: '#FF6F00',
                        label: {
                            borderColor: '#FF6F00',
                            style: {
                                // fontSize: '12px',
                                color: '#fff',
                                background: '#FF6F00'
                            },
                            orientation: 'horizontal',
                            text: 'CS',
                        }
                    }]
                }
            }

            this.setState({ brushBarSeries, brushBarOptions, alertTotalCount })
        } else {
            this.setState({ brushBarSeries: [], brushBarOptions: {} })
        }
    }

    brushSelection = (xaxis, type) => {
        let brushStartDate = momentDateGivenFormat(xaxis.min, 'YYYY-MM-DD HH:mm:ss')
        let brushEndDate = momentDateGivenFormat(xaxis.max, 'YYYY-MM-DD HH:mm:ss')

        if(this.state.brushStartTime && this.state.brushStartTime !== "" && this.state.brushStartTime !== this.state.sliderStartDate && this.state.brushEndTime !== this.state.sliderEndDate && this.state.brushStartTime !== brushStartDate && this.state.brushEndTime !== brushEndDate) {
            this.filterData("brush")
        }
        
        if(brushStartDate && brushEndDate) {
            this.setState({ brushStartDate, brushEndDate, brushStartTime: brushStartDate, brushEndTime: brushEndDate },
                () => {
                    this.getAttribute()
                    this.assetWiseAlertTimeline()
                }    
            )
        }
    }

    alertGroupBar = () => {
        let graphDetails = this.state.alerGrouptDailyTrend.results
        let array = []
        if(graphDetails && graphDetails.data && graphDetails.data.count) {
            let graphLabels = graphDetails.labels
            let alertGroupCount = graphDetails.data.count.reduce(function(a, b){
                return a + b;
            }, 0)
            graphDetails.data.count.forEach((val, index) => {
                let arrayValues = []
                if(!index) {
                    let time = momentConvertionUtcToLocalTime(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:00')
                    arrayValues.push(time)
                    arrayValues.push(0) 
                    array.push(arrayValues)
                }
                arrayValues = []
                let time = momentConvertionUtcToLocalTime(graphLabels[index], 'YYYY-MM-DD HH:mm:ss')
                arrayValues.push(time)
                arrayValues.push(val) 
                array.push(arrayValues)

                if(index === graphDetails.data.count.length-1) {
                    arrayValues = []
                    let time = momentConvertionUtcToLocalTime(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:00')
                    arrayValues.push(time)
                    arrayValues.push(0) 
                    array.push(arrayValues)
                }
                //form labels
                
            })
            
            let alertGroupSeries = [{
                data: array
            }]

            let xaxis = {
                tickAmount: 50,
                type: 'datetime',
                labels: {
                    datetimeUTC: false,
                    style: {
                        colors: '#60687C'
                    },
                },
                axisTicks: {
                    show: false
                },
                tooltip: {
                    enabled: false,
                },
                axisBorder: {
                    show: true,
                    color:'#434959',
                    widht: 2,
                    // offsetX: -15,
                    // offsetY: 0
                },
            }

            let columnWidth = '70%'
            if(alertGroupSeries[0].data.length <= 10) {
                columnWidth = '10%'
            } else if(alertGroupSeries[0].data.length <= 20) {
                columnWidth = '15%'
            } else if(alertGroupSeries[0].data.length <= 30) {
                columnWidth = '20%'
            } else if(alertGroupSeries[0].data.length <= 40) {
                columnWidth = '25%'
            } else if(alertGroupSeries[0].data.length <= 50) {
                columnWidth = '30%'
            }
            
            let alertGroupOptions = {
                chart: {
                    redrawOnParentResize: true,
                    id: 'groupChart',
                    height: 130,
                    type: 'bar',
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        endingShape: 'rounded',
                        columnWidth: columnWidth,
                    }
                },

                colors: ['#19B2C0'],
                fill: {
                    colors: ['#039BE5'],
                    type: 'gradient', // gradient
                    gradient: {
                        type: 'vertical', // The gradient in the horizontal direction
                        gradientToColors: ['#5F5BA2'], // The color at the end of the gradient
                        opacityFrom: .9, // transparency
                        opacityTo: 1,
                        stops:[0, 120]
                    }
                },
                xaxis: xaxis,
                yaxis: {
                    show: false,
                    labels: {
                        show: false
                    }
                },            
                grid: {
                    position: 'back',
                    borderColor: '#2D323E',
                    show: true,
                    xaxis: {
                        lines: {
                            show: false
                        },
                    },
                    yaxis: {
                        lines: {
                            show: true
                        }
                    },
                    padding: {
                        top: 0,
                        right: 20,
                        bottom: 0,
                        left: 0
                    },
                },
                    
                tooltip: {
                    enabled: true,
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        show: false
                    },
                    marker: {
                        show: false
                    },
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]                    
                        let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
                        dateLable = dateLable.toString()
                        dateLable = momentDateGivenFormat(dateLable, 'DD MMM Y HH:mm:ss')
                        return '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+dateLable+'</div><div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[0] +'" ></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">Grouped Alerts</span><span class="font-weight-normal ml-2">'+val+'</span></div></div></div></div>'
                    },
                },
                // annotations: {
                //     position: 'back' ,
                //     xaxis: [{
                //         x:  new Date(momentConvertionUtcToLocalTime(this.state.caseStartTime, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                //         strokeDashArray: 0,
                //         borderColor: '#FF6F00',
                //         label: {
                //             borderColor: '#FF6F00',
                //             style: {
                //                 // fontSize: '12px',
                //                 color: '#fff',
                //                 background: '#FF6F00'
                //             },
                //             orientation: 'horizontal',
                //             text: 'CS',
                //         }
                //     }]
                // }
            }

            this.setState({ alertGroupSeries, alertGroupOptions, alertGroupCount })
        } else {
            this.setState({ alertGroupSeries: [], alertGroupOptions: {} })
        }
    }
    
    getAttribute = () => {
        var attributes = document.querySelector('.alertCaseTimeAnnotation .apexcharts-selection-rect') && document.querySelector('.alertCaseTimeAnnotation .apexcharts-selection-rect').attributes
        if(attributes) {
            let width = attributes.width.value

            let x1 = parseInt(attributes.x.value)
            let x2 = parseInt(attributes.x.value) + parseInt(width)

            let leftLPosition = x1
            let rightLPosition = x2
            let diff = twoDateDiffrence(this.state.brushStartDate, this.state.brushEndDate)
            if(diff < 8) {
                leftLPosition = x1 - 20
                if(diff < 4) {
                    leftLPosition = x1 - 40
                }
                rightLPosition = x2
            }

            var d1 = document.getElementById('leftSliderSpan');
            d1.style.position = "absolute";
            d1.style.left = leftLPosition+'px';
            d1.style.top = '95px';
            d1.style.color = '#039BE5';
            
            var d2 = document.getElementById('rightSliderSpan');
            d2.style.position = "absolute";
            d2.style.left = rightLPosition+'px';
            d2.style.top = '95px';
            d2.style.color = '#039BE5';

            let caseTime = twoDateTimeInterval(this.state.sliderStartDate, this.state.sliderEndDate)
            let sliderBelowStartDate = ''
            let sliderBelowEndDate = ''
            // if(width > 40) {
            if(caseTime[0] > 0) {
                sliderBelowStartDate = momentDateGivenFormat(this.state.brushStartDate, 'DD MMM')
                sliderBelowEndDate = momentDateGivenFormat(this.state.brushEndDate, 'DD MMM')
            } else {
                sliderBelowStartDate = momentDateGivenFormat(this.state.brushStartDate, 'HH:mm')
                sliderBelowEndDate = momentDateGivenFormat(this.state.brushEndDate, 'HH:mm')
            }
            // }
            this.setState({ sliderBelowStartDate, sliderBelowEndDate })
        }
    }

    listAlertsTable = (groupAlert) => {
        let alertsTableColumns = []
        let dataRow = {}

        dataRow.Header = ' '
        dataRow.accessor = 'severity'
        dataRow.Cell = cellInfo => (
            <span className={`badge ${
                cellInfo.row.original.severity && cellInfo.row.original.severity !== '' ?
                    'risk-badge-'+cellInfo.row.original.severity.toLowerCase()
                : 'badge-secondary'
                }`}
            >
                {cellInfo.row.original.severity && cellInfo.row.original.severity !== '' ? (cellInfo.row.original.severity.toLowerCase() === 'low' ? 'L' : cellInfo.row.original.severity.toLowerCase() === 'medium' ? 'M' :  cellInfo.row.original.severity.toLowerCase() === 'high' ? 'H' : cellInfo.row.original.severity.toLowerCase() === 'critical' ? 'C' : '-') : '-'}
            </span>
        )
        dataRow.width = 40
        alertsTableColumns.push(dataRow)

        dataRow = {}
        dataRow.Header = 'Time'
        dataRow.accessor = 'event_time'
        dataRow.width = 130
        dataRow.Cell = cellInfo => (
            <span>{cellInfo.row.original.event_time ? momentConvertionUtcToLocalTime(cellInfo.row.original.event_time, 'DD MMM YYYY HH:mm:ss') : ''}</span>
        )
        alertsTableColumns.push(dataRow)

        dataRow = {}
        dataRow.Header = 'Alert Name'
        dataRow.accessor = 'event_name'	
        dataRow.width = 290				
        alertsTableColumns.push(dataRow)
        
        // dataRow = {}
        // dataRow.Header = 'Alert Metrics'
        // dataRow.accessor = 'event_type'
        // dataRow.width = 100
        // alertsTableColumns.push(dataRow)

        // dataRow = {}
        // dataRow.Header = 'Asset Name'
        // dataRow.accessor =  d => Array.isArray(d.asset_name) ? d.asset_name.map((item, index) => (d.asset_name.length - 1 === index ? item : item + ', ')) : (d.asset_name ? d.asset_name : '')
        // dataRow.width = 100
        // alertsTableColumns.push(dataRow)

        // dataRow = {}
        // dataRow.Header = 'Source'
        // dataRow.accessor = 'event_source'		
        // dataRow.width = 80			
        // alertsTableColumns.push(dataRow)   
        
        // dataRow = {}
        // dataRow.Header = 'Resource'
        // dataRow.accessor = d => (d.provider ? d.provider.toUpperCase() + ' : ' : '') + (d.account_id ? d.account_id + ' : ' : '') + (d.region ? d.region + ' : ' : '') + (d.provider ? d.resource_type : '')
        // dataRow.width = 180
        // alertsTableColumns.push(dataRow)     

        dataRow = {}
        dataRow.Header = ' '
        dataRow.accessor = ''	
        dataRow.width = 1			
        alertsTableColumns.push(dataRow)

        this.setState({ alerts: groupAlert.alerts, filteredTableArray: groupAlert.alerts, alertDynamicColumns: alertsTableColumns, showAlertTable: groupAlert.group_id })
    }

    setSliderPropDetails = () => {
        // let sliderObj = {}
        // sliderObj.brushStartDate = this.state.brushStartDate
        // sliderObj.brushEndDate = this.state.brushEndDate
        // this.props.setAiopsPropsDetails('alert_brush_changes',sliderObj)

        this.setState({ onChangeBrushStartDate: this.state.brushStartDate, onChangeBrushEndDate: this.state.brushEndDate },
            () => this.filterGroupData()    
        )
    }

    getTimeLocal = (time) => {
        time = new Date(time).getTime()
        return time
    }

    filterGroupData = () => {
        let data = this.state.groupAlerts ? this.state.groupAlerts : []
        if(data.length && this.state.onChangeBrushStartDate && this.state.onChangeBrushStartDate !== "") {
            data = data.filter(d => {
                var time = this.getTimeLocal(momentConvertionUtcToLocalTime(d.created_at, 'YYYY-MM-DD HH:mm:ss'));
                return (time >= this.getTimeLocal(this.state.onChangeBrushStartDate) && time <= this.getTimeLocal(this.state.onChangeBrushEndDate));
            });
        }

        this.setState({ totalFilteredArray: data, filteredArray: data },
            () => {
                if(this.state.filteredArray.length) {
                    let filteredArrayOrder = _.orderBy(this.state.filteredArray, ['event_time'], ['desc'])
                    this.getAlertGroupDetails(filteredArrayOrder[0])
                } else {
                    this.props.setAiopsPropsDetails('alert_page_details', {})
                }
            }
        )
    }

    filterAlertData = () => {
        let data = this.state.alerts && this.state.alerts.length ? this.state.alerts : []
        if(data.length) {
            data = data.filter(d => {
                var time = this.getTimeLocal(momentConvertionUtcToLocalTime(d.event_time, 'YYYY-MM-DD HH:mm:ss'));
                return (time >= this.getTimeLocal(this.props.aiopsPropsDetails.alert_brush_changes.brushStartDate) && time <= this.getTimeLocal(this.props.aiopsPropsDetails.alert_brush_changes.brushEndDate));
            });
        }

        this.setState({ filteredTableArray: data },
            () => {
                if(this.state.filteredTableArray.length) {
                    let filteredArrayOrder = _.orderBy(this.state.filteredTableArray, ['event_time'], ['desc'])
                    this.getAlertDetails(filteredArrayOrder[0])
                } else {
                    this.props.setAiopsPropsDetails('alert_page_details', {})
                }
            }
        )
    }

    getAssetAlertDetails = (alertInfo, dataPointIndex) => {
        // let selectedAlert = this.state.alertsListNew.filter(obj => obj.event_ids.includes(alertInfo.data[dataPointIndex][3]))
        // this.getAlertDetails(selectedAlert[0])
    }
    
    getAlertGroupDetails = (selectedAlert) => {
        let selectedAlertGroupId = selectedAlert.group_id ? selectedAlert.group_id : ''
        selectedAlert.type = 'group'
        this.setState({ selectedAlertGroupId },
            () => {
                this.listAlertsTable(selectedAlert)
                this.props.setAiopsPropsDetails('alert_page_details', selectedAlert)
            }
        )
    }

    getAlertDetails = (selectedAlert) => {
        selectedAlert.group_id = selectedAlert.event_id ? selectedAlert.event_id : ''
        selectedAlert.type = 'alert'
        this.props.setAiopsPropsDetails('alert_page_details', selectedAlert)
    }

    assetWiseAlertTimeline = () => {
        let graphDetails = this.state.alertsByAssets
        if(graphDetails) {
            let graphLabels = graphDetails.labels
            let graphData = graphDetails.alerts
            
            // var temp1 = graphLabels.map(d => Math.abs(new Date(momentConvertionUtcToLocalTime(d, 'YYYY-MM-DD HH:mm:ss')).getTime() - new Date(this.state.brushStartDate).getTime()));
            // var idx1 = temp1.indexOf(Math.min(...temp1));

            // var temp2 = graphLabels.map(d => Math.abs(new Date(momentConvertionUtcToLocalTime(d, 'YYYY-MM-DD HH:mm:ss')).getTime() - new Date(this.state.brushEndDate).getTime()));
            // var idx2 = temp2.indexOf(Math.min(...temp2));

            // let start =  idx1
            // let end  = idx2 + 1
            
            graphData.length && graphData.forEach((item, index) => {
                let series = []
                let arrayDates = []
                let data = []
                let severityArray = []
    
                
                let graphDataRow = {}
                graphDataRow.asset_name = item.asset_name
                graphDataRow.asset_id = item.asset_id
                let count = 0
                item.data.alert.forEach((row, j) => {
                    if(momentConvertionUtcToLocalTime(graphLabels[j], 'YYYY-MM-DD HH:mm:ss') >= this.state.brushStartDate && momentConvertionUtcToLocalTime(graphLabels[j], 'YYYY-MM-DD HH:mm:ss') <= this.state.brushEndDate) {
                        let arrayValuesRow = []
                        arrayValuesRow.push(momentConvertionUtcToLocalTime(graphLabels[j], 'YYYY-MM-DD HH:mm:ss'))
                        arrayValuesRow.push(item.data.alert[j])
                        if(item.data.alert[j]) {
                            count += 1
                        }
                        arrayValuesRow.push(item.data.severity[j])
                        arrayValuesRow.push(item.data.id[j])
                        data.push(arrayValuesRow)
                        arrayDates.push(momentConvertionUtcToLocalTime(graphLabels[j], 'YYYY-MM-DD HH:mm:ss'))
                        severityArray.push(item.data.severity[j])
                    }
    
                })
                graphDataRow.data = data
                series.push(graphDataRow)
                
                let columnWidth = '20%'
                if(count <= 3) {
                    columnWidth = '5%'
                } else if(count <= 5) {
                    columnWidth = '18%'
                } else if(count <= 8) {
                    columnWidth = '13%'
                } else if(count <= 12) {
                    columnWidth = '17%'
                }

                let timelineOptionsXaxis = {
                    plotOptions: {
                        bar: {
                            barHeight: '0%',
                            columnWidth: columnWidth
                        },          
                    },
                    chart: {
                        id: 'chartXaxis',
                        type: 'bar',
                        height: 230,
                        sparkline: {
                            enabled: false
                        },
                        toolbar: {
                            show: false,
                        },
                        redrawOnParentResize: true
                    },
                    // colors: ['#546E7A'],
                    colors: ['#ffffff'],
                    stroke: {
                        width: 0
                    },
                    dataLabels: {
                        enabled: false
                    },
                    markers: {
                        size: 0
                    },
                    xaxis: {
                        type: 'datetime',
                        labels: {
                            datetimeUTC: false,
                            style: {
                                colors: '#C1C1C1'
                            },
                            formatter: function (value) {
                                return momentDateGivenFormat(value, 'HH:mm:ss')
                            }
                        },
                        axisTicks: {
                            show: false,
                        },
                    },
                    yaxis: {
                        show: false,
                    },
                    tooltip: {
                        show: false,
                    },      
                    grid: {
                        show: false,
                        xaxis: {
                            lines: {
                                show: false
                            },
                        },
                        yaxis: {
                            lines: {
                                show: false
                            }
                        },
                        padding: {
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 20
                        }, 
                    }
                }
            
                let timelineOptions = {
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            barHeight: '100%',
                            // columnWidth: '100%',
                            // startingShape: 'flat',
                            // endingShape: 'flat',
                            columnWidth: columnWidth,
                            distributed: false,
                            // rangeBarOverlap: false
                        },          
                    },
                    chart: {
                        id: 'chart2',
                        //group: 'barBrushChart',
                        type: 'bar',
                        height: 230,
                        sparkline: {
                            enabled: true
                        },     
                        events: {
                            dataPointSelection: (event, chartContext, config, dataPointIndex) => { 
                                this.getAssetAlertDetails(config.w.globals.initialSeries[0], config.dataPointIndex)
                            }
                        },
                        redrawOnParentResize: true
                    },
                    // colors: ['#546E7A'],
                    colors: [function({ value, seriesIndex, dataPointIndex, w }) {
                        let color = ''
                        if(w.globals.initialSeries[seriesIndex].data.length) {
                            color = w.globals.initialSeries[seriesIndex].data[dataPointIndex][2]
                        }
                        if (color === 'Critical') {
                            return '#5F5BA2'
                        } else if (color === 'High') {
                            return '#9A77D1'
                        } else if (color === 'Medium') {
                            return '#3F73AD'
                        } else if (color === 'Low') {
                            return '#24A597'
                        } else {
                            return '#00B5D4'
                        }
                    }, function({ value, seriesIndex, dataPointIndex, w }) {
                        let color = ''
                        if(w.globals.initialSeries[seriesIndex].data.length) {
                            color = w.globals[seriesIndex].data[dataPointIndex][2]
                        }
                        if (color === 'Critical') {
                            return '#5F5BA2'
                        } else if (color === 'High') {
                            return '#9A77D1'
                        } else if (color === 'Medium') {
                            return '#3F73AD'
                        } else if (color === 'Low') {
                            return '#24A597'
                        } else {
                            return '#00B5D4'
                        }
                    }],
                    stroke: {
                        width: 0
                    },
                    dataLabels: {
                        enabled: false
                    },
                    markers: {
                        size: 0
                    },
                    xaxis: {
                        type: 'datetime',
                        labels: {
                            datetimeUTC: false
                        },
                        // min: this.getTimeLocal(graphLabels[start]),
                        // max: this.getTimeLocal(graphLabels[end-1]),
                    },
                    tooltip: {
                        fixed: {
                            enabled: false
                        },
                        x: {
                            show: false
                        },
                        y: {
                            title: {
                            formatter: (seriesName) => seriesName,
                            }
                        },
                        marker: {
                            show: false
                        },
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                            let dateLabel = momentDateGivenFormat(w.globals.initialSeries[0].data[dataPointIndex][0], 'MMM DD HH:mm:ss')
                            let severity = w.globals.initialSeries[seriesIndex].data[dataPointIndex][2]
                            let eventId = w.globals.initialSeries[seriesIndex].data[dataPointIndex][3]
                            // if (severity === 'Critical') {
                            //     fontColor = '#F75E3F'
                            // } else if (severity === 'High') {
                            //     fontColor = '#FF6F00'
                            // } else if (severity === 'Medium') {
                            //     fontColor = '#693EBC'
                            // } else if (severity === 'Low') {
                            //     fontColor = '#06CB9E'
                            // } else {
                            //     fontColor = '#00B5D4'
                            // }
                            return '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+dateLabel+'</div><div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">Severity: </span><span class="font-weight-normal ml-2">'+severity+'</span></div><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">Event Id:</span><span class="font-weight-normal ml-2">'+eventId+'</span></div></div></div></div>'
                            // return '<div class="arrow_box"> <span style="color:'+ fontColor +'">' + '  '+ capitalizeFirstLetter(severity) +'</span> ' + dateLabel + ' </div>'
                        }
                    }
                }
            
                this.setState({ timelineOptionsXaxis, ['timelineSeries_'+index]: series, ['timelineOptions_'+index]: timelineOptions })
            })
        }
    }
    
    handleChange = (event) => {
        this.setState({ groupType: event.target.value, showGroupLoading: true },
            () => {
                this.listAlertGroups()
            }    
        )
    }

    resetGraph = () => {
        this.setState({ onChangeBrushStartDate: "", onChangeBrushEndDate: "" },
            () => {
                this.brushDuration()
                this.filterGroupData()
            }
        )
    }

	render() {
		return (
            <div className='col-md-12'>
                <div className={`loaderOverlay ${this.state.showLoading ? '' : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
                <div className={`d-flex justify-content-between`}>
                    <div className="d-flex">
                        <p className="indicatoreBox bg-orange mb-0 mr-2"></p>
                        <p className="f12 mb-0 align-self-center">CS - Case Start</p>
                    </div>
                    <div className={`d-flex justify-content-end f10`}>
                        <span className={`mr-2 align-self-center ${this.state.selectedFilterType === 'H' && this.state.selectedFilterPeriod === 3 ? 'badge badge-info disabled' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'H', selectedFilterPeriod: 3, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>3H</span>
                        <span className={`mr-2 align-self-center ${this.state.selectedFilterType === 'H' && this.state.selectedFilterPeriod === 1 ? 'badge badge-info disabled' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'H', selectedFilterPeriod: 1, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>1H</span>
                        <span className={`mr-2 f12 align-self-center `}>before</span>
                        <span className={`mr-2 align-self-center ${this.state.selectedFilterType === 'caseTime' && this.state.selectedFilterPeriod === 0 ? 'badge badge-info disabled' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'caseTime', selectedFilterPeriod: 0, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>Case Time</span>
                    </div>
                </div>
                <div className="rounded bg-dark px-3 py-2 mt-2">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex">
                            <div className="d-flex">
                                <p className="f12 m-0 align-self-center pt-1">Showing {this.state.alertTotalCount && this.state.alertTotalCount} total alerts </p>
                                <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{this.state.sliderStartDate ? momentConvertionUtcToLocalTime(this.state.sliderStartDate, 'DD MMM YYYY HH:mm') +' - '+ momentConvertionUtcToLocalTime(this.state.sliderEndDate, 'DD MMM YYYY HH:mm') : ''}</p>                                
                                <span className="ml-2 align-self-center">(local time)</span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="align-self-center">
                                <div className="d-flex aiopsMetrics">
                                    <span onClick={this.resetGraph} className="fa fa-redo-alt border-right cursorPointer mr-2 align-self-center"></span>
                                    {this.props.totalAlertCount && this.props.totalAlertCount.length && this.props.totalAlertCount.filter(arr => arr.severity.toLowerCase() === 'critical').length ?
                                        <div className="d-flex mr-2">
                                            <div className={`f10 badge-square-sm risk-badge-critical`}>C</div>
                                            <p className="m-0 f11 text-white"><span className="mx-1">:</span>{this.props.totalAlertCount.find(arr => arr.severity.toLowerCase() === 'critical').total_count}</p>
                                        </div>
                                    : null}
                                    {this.props.totalAlertCount && this.props.totalAlertCount.length && this.props.totalAlertCount.filter(arr => arr.severity.toLowerCase() === 'high').length ?
                                        <div className="d-flex mr-2">
                                            <div className={`f10 badge-square-sm risk-badge-high`}>H</div>
                                            <p className="m-0 f11 text-white"><span className="mx-1">:</span> {this.props.totalAlertCount.find(arr => arr.severity.toLowerCase() === 'high').total_count}</p>
                                        </div>
                                    : null}
                                    {this.props.totalAlertCount && this.props.totalAlertCount.length && this.props.totalAlertCount.filter(arr => arr.severity.toLowerCase() === 'medium').length ?
                                        <div className="d-flex mr-2">
                                            <div className={`f10 badge-square-sm risk-badge-medium`}>M</div>
                                            <p className="m-0 f11 text-white"><span className="mx-1">:</span>{this.props.totalAlertCount.find(arr => arr.severity.toLowerCase() === 'medium').total_count}</p>
                                        </div>
                                    : null}
                                    {this.props.totalAlertCount && this.props.totalAlertCount.length && this.props.totalAlertCount.filter(arr => arr.severity.toLowerCase() === 'low').length ?
                                        <div className="d-flex">
                                            <div className={`f10 badge-square-sm risk-badge-low`}>L</div>
                                            <p className="m-0 f11 text-white"><span className="mx-1">:</span>{this.props.totalAlertCount.find(arr => arr.severity.toLowerCase() === 'low').total_count}</p>
                                        </div>
                                    : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="d-flex">
                        <p className="mb-0 align-self-center text-nowrap text-right pr-3 w-10">Alerts</p>
                        <div className="w-90 mt-n3 mb-n2 transparentTooltip alertCaseTimeAnnotation">
                            {this.state.brushBarOptions && Object.entries(this.state.brushBarOptions).length && this.state.brushBarSeries && this.state.brushBarSeries.length ?
                                <Chart options={this.state.brushBarOptions} series={this.state.brushBarSeries} type="bar" height={100} />
                            : null}
                            <div className="d-flex">
                                <div id="leftSliderSpan" className="f12">{this.state.sliderBelowStartDate ? this.state.sliderBelowStartDate : ''}</div>
                                <div id="rightSliderSpan" className="f12">{this.state.sliderBelowEndDate ? this.state.sliderBelowEndDate : ''}</div>
                            </div>
                        </div>
                    </div> 
                </div>
                
                {/* <div className="d-flex mt-2 ml-1">
                    <p className="m-0 align-self-end small mr-2">Show the important alerts groupd by</p>
                    <div class={`transparentSelectBorderBottom`}>
                        <select class="form-control" 
                            value={this.state.groupedBy}
                            onChange={event => {
                                this.setState({ groupedBy: event.target.value})
                            }}>
                            <option value='severity'>Severity</option>
                        </select>
                    </div>
                </div> */}
                <div className={`d-flex mt-2 ml-1 ${this.state.showGroupLoading ? 'disabled' : ''}`}>
                    <p className="m-0 align-self-end small mr-2">Show the </p>
                    <div class={`transparentSelectBorderBottom`}>
                        <select class="form-control" 
                        onChange={this.handleChange}
                        value={this.state.groupType}
                        >
                            <option key="All" value="All">ALL</option>
                            <option key="important" value="important">Important</option>
                        </select>
                    </div> 
                    <p className="m-0 align-self-end small mx-2">alerts groupd by</p>
                    <div class={`transparentSelectBorderBottom`}>
                        <select class="form-control" 
                            onChange={(event) => {
                                this.setState({ groupedBy: event.target.value, showGroupLoading: true },
                                    () => {
                                        this.listAlertGroups()
                                        this.getAlertGroupDailyTrend()
                                    }    
                                )
                            }}
                            value={this.state.groupedBy}                            
                            >
                            <option value='severity'>Severity</option>
                        </select>
                    </div>
                </div>
                <div className="rounded bg-dark px-3 py-2 mt-2">
                    <div className="d-flex">
                        <div className="d-flex">
                            <p className="f12 m-0 align-self-center pt-1">Showing {this.state.alertGroupCount && this.state.alertGroupCount} grouped alerts</p>                            
                            {/* <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{this.state.brushStartDate ? (momentDateGivenFormat(this.state.brushStartDate, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.brushEndDate, 'DD MMM YYYY HH:mm')) : ''}</p> */}
                            <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{this.state.sliderStartDate ? momentConvertionUtcToLocalTime(this.state.sliderStartDate, 'DD MMM YYYY HH:mm') +' - '+ momentConvertionUtcToLocalTime(this.state.sliderEndDate, 'DD MMM YYYY HH:mm') : ''}</p>
                            <span className="ml-2 align-self-center">(local time)</span>
                        </div>
                    </div>
                    <div className="d-flex">
                        <p className="mb-0 align-self-center text-nowrap text-right pr-3 w-10">Group</p>
                        <div className="w-90 mt-n3 mb-n3 transparentTooltip alertCaseTimeAnnotation">
                            {this.state.alertGroupOptions && Object.entries(this.state.alertGroupOptions).length && this.state.alertGroupSeries && this.state.alertGroupSeries.length ?
                                <Chart options={this.state.alertGroupOptions} series={this.state.alertGroupSeries} type="bar" height={100} />
                            : null}
                        </div>
                    </div>
                </div>

                <div className="rounded bg-dark p-3 mt-2">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex mb-1">
                            <div className="form-group mb-0 mr-3">
                                <select className="form-control form-control-sm select-dark" 
                                    onChange={event => this.setState({ showAlertType: event.target.value })}
                                    value={this.state.showAlertType}>
                                    <option value='timeline'>Timeline</option>
                                    <option value='distribution'>Distribution</option>
                                </select>
                            </div>
                            {this.state.showAlertType === 'timeline' ?
                                <small className="align-self-center">Showing {this.state.filteredArray && this.state.filteredArray.length} {this.state.filteredArray && this.state.filteredArray.length > 1 ? 'alerts' : 'alert'} </small>
                            : 
                                <small className="align-self-center">Showing asset wise alert distribution</small>
                            }
                        </div>
                        {this.state.totalFilteredArray && this.state.totalFilteredArray.length ?
                            <div className="d-flex">
                                <div className="form-group mb-0">
                                    <SearchWithHiddenInput
                                        ID={'alert_search'}
                                        data={this.state.totalFilteredArray ? this.state.totalFilteredArray : this.state.alertsList}
                                        applyLiteDarkTags={true}
                                        searchClassName={'border-0'}
                                        searchIconColor={'text-gray3'}
                                        className="form-control-sm text-gray3 bg-muted border-0"
                                        hideInputSection={true}
                                        topClassName={'dark-search'}
                                        filteredData={(filteredArray, searchText) => {
                                            this.setState({ filteredArray },
                                                () => {
                                                    if(filteredArray.length) {
                                                        let filteredArrayOrder = _.orderBy(filteredArray, ['event_time'], ['desc'])
                                                        this.getAlertGroupDetails(filteredArrayOrder[0])
                                                    }
                                                }
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                        : null}
                    </div>
                    {this.state.showAlertType === 'timeline' ? 
                        <div class="row">
                            {this.state.groupAlertcategory ? 
                                <div className="col-3 categoryBoxSection">
                                    <div class="bg-muted px-2 pt-2 select-bgg">
                                        {this.state.groupedBy === "severity" ?
                                            this.state.groupAlertcategory.map(item => {
                                                return(
                                                    item.category === "Important / All" ?
                                                        <div className={`d-flex p-2 cursorPointer ${this.state.selectedCategory === item.category ? 'text-info disabled' : ''}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryAlerts("brush"))}>
                                                            <p className="m-0 f-20">{item.category}</p>
                                                            <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p>
                                                        </div>
                                                    :
                                                        <div class={`d-flex justify-content-end mb-2 ${!item.count ? 'disabled opacity50' : 'cursorPointer'}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryAlerts("brush"))}>
                                                            <div class="d-flex">
                                                                {item.totalBars.map(bar => {
                                                                    return(
                                                                    <div className={`line-survey-1 risk-bg-${item.category.toLowerCase()}`}></div>
                                                                    )
                                                                })}
                                                                <div class={`font-weight-bold text-center risk-border-${item.category.toLowerCase()} text-white p-2`}>
                                                                    <p class="small mb-0" style={{minWidth: "75px"}}>{item.category+" "+item.count}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                )
                                            })                                            
                                        : 
                                            this.state.groupAlertcategory.map((item, index) => {
                                                return(
                                                    item.category === "Important / All" ?
                                                        <div className={`d-flex cursorPointer mb-3 ${this.state.selectedCategory === item.category ? 'text-info disabled' : ''}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryAlerts("brush"))}>
                                                            <p className="m-0 f-20">{item.category}</p>
                                                            <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p>
                                                        </div>
                                                    :
                                                    <div className={`d-flex cursorPointer mb-2 ${this.state.selectedCategory === item.category ? 'text-info disabled' : (!item.count ? 'text-gray4 disabled test' : 'text-white')}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryAlerts("brush"))}>
                                                        <div class="d-flex justify-content-between mb-2" style={{maxWidth: "160px"}}>
                                                            <p class="mb-0 align-self-center ellipseCategoryBoxSection w-60" id={'category_'+index}>{item.category}</p>
                                                            <UncontrolledTooltip target={'category_'+index}>{item.category}</UncontrolledTooltip>
                                                            <input class="form-control form-control-sm w-25 border-0 bg-grey text-white readOnly  disabled rounded-0 form-number text-right" style={{minWidth: "40px"}} type="text" placeholder="1" value={item.count !== '' ? item.count : ''} aria-label=".form-control-sm example" />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            : null}
                            <div className="col-9 pl-0">
                                <div className="timeline-style">
                                    {this.state.filteredArray && this.state.filteredArray.map(item => {
                                        return (
                                            <div className={`d-flex justify-content-between ${this.state.selectedAlertGroupId === item.group_id ? 'bg-muted': ''}`}>
                                                <React.Fragment>
                                                    <div className={`timelineAlertGroup d-flex ${this.state.selectedAlertGroupId === item.group_id ? '' : 'cursorPointer'} `} onClick={() => {
                                                            if(this.state.selectedAlertGroupId !== item.group_id) {
                                                                this.setState({ selectedAlertGroupId: item.group_id, ['alert_'+this.state.selectedAlertGroupId]: false, ['alert_'+item.group_id]: true }, 
                                                                    () => {
                                                                        this.getAlertGroupDetails(item)
                                                                    }
                                                                )
                                                            }
                                                        }}>
                                                        <div className="border-right p-2 pr-3">
                                                            <div className={`font-weight-bold text-center p-2 border-gray3 text-gray3`}>
                                                                <p className="small mb-0">{momentConvertionUtcToLocalTime(item.created_at, 'MMM DD')}</p>
                                                                <p className="m-0 f10">{momentConvertionUtcToLocalTime(item.created_at, 'HH:mm:ss')}</p>
                                                            </div>
                                                        </div>
                                                        <div className="timeline-space">
                                                            <span className="border bg-gray3"></span>
                                                            <div className="bg-gray3"></div>
                                                        </div>
                                                        <div className={`align-self-center ml-3 ${this.state['alert_'+item.group_id] ? 'mt-2' : ''}`}>
                                                            <div className="d-flex flex-wrap">
                                                                {item.severity ?
                                                                    <div className="d-flex">
                                                                        <span className={`align-self-center mr-1 fa fa-circle risk-${item.severity.toLowerCase()}`}></span>
                                                                        <p className={`mb-0 mr-2 risk-${item.severity.toLowerCase()}`}>
                                                                            {capitalizeFirstLetter(item.severity)}
                                                                        </p>
                                                                    </div>
                                                                : null}
                                                                <p className="mb-0 mr-2">{item.total_alerts+ (item.total_alerts>1 ?' alerts':' alert')}</p>
                                                                <p className="mb-0 mr-2">{item.event_type}</p>
                                                                <p className="mb-0 small mr-2">Resource</p>
                                                                {item.account_id ?
                                                                    <p className="mb-0 small mr-2">{' '+item.account_id}</p>
                                                                : null}
                                                                {item.region ?
                                                                    <p className="mb-0 small mr-2">{' : '+item.region}</p>
                                                                : null}
                                                                {item.resource_type ?
                                                                    <p className="mb-0 small mr-2">{' : '+(Array.isArray(item.resource_type) ? item.resource_type.join(', ') : item.resource_type)}</p>
                                                                : null}
                                                                {item.service_name ?
                                                                    <p className="mb-0 small mr-2">{' : '+item.service_name}</p>
                                                                : null}
                                                                {item.asset_name ?
                                                                    <p className="mb-0 small mr-2">{' : '+item.asset_name}</p>
                                                                : null}
                                                                {item.description ?
                                                                    <p className="mb-0 small mr-2">{' : '+item.description}</p>
                                                                : null}
                                                            </div>
                                                            {/* <div className={`small-divder my-1 bg-gray3`}></div> */}
                                                            {this.state.showAlertTable === item.group_id && this.state['alert_'+item.group_id] && this.state.filteredTableArray && this.state.filteredTableArray.length ?
                                                                <div className="my-2 mr-2">
                                                                    <ResizeableDarkThemeTable
                                                                        columns={this.state.alertDynamicColumns}
                                                                        data={this.state.filteredTableArray ? this.state.filteredTableArray : []}
                                                                        perPage={20}
                                                                        dashboard={this.state.filteredTableArray && this.state.filteredTableArray.length ? true : false}
                                                                        sortByColumn={'event_time'}
                                                                        riskTooltip={[0]}
                                                                        tooltipFor={[2]}
                                                                        tableHead={'bg-dark'}
                                                                        onClickRow={tableRow => {
                                                                            this.getAlertDetails(tableRow)
                                                                        }}
                                                                        tableSize={'table-xs'}
                                                                    />
                                                                </div>
                                                            : this.state.filteredTableArray && !this.state.filteredTableArray.length ?
                                                                <div className='d-flex justify-content-center m-4'>
                                                                    <p>There are no alerts in this group.</p>
                                                                </div>
                                                            : null}
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                                {this.state.selectedAlertGroupId === item.group_id ?
                                                    this.state['alert_'+item.group_id] ?
                                                        <i className="far fa-caret-down cusorPointer f20 p-2" onClick={() => this.setState({ ['alert_'+item.group_id]: false })}></i>
                                                    :
                                                        <i className="far fa-caret-left cusorPointer f20 p-2" onClick={() => this.setState({ ['alert_'+item.group_id]: true })}></i>
                                                : null}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    :  
                        <div className="rounded mt-2 text-white4">
                            {/* <div className="d-flex justify-content-between">
                                <div>
                                    <p className="mb-0"><b>Distribution</b></p>
                                    <p className="small mb-2 text-muted">Showing asset wise alert distribution</p>
                                </div>
                            </div> */}
                            <div className="distributionTable">
                                <table className="table table-sm table-dark-hover text-white f12">
                                    <thead className="bg-muted">
                                        <tr>
                                            <th></th>
                                            <th>Asset</th>
                                            <th>Description</th>
                                            <th>
                                                {this.state.alertsByAssets && this.state.alertsByAssets.alerts.slice(0,1).map((item, index) => {
                                                    return (
                                                        <div className="w-80 m-auto distributionLabelGraph disabled">
                                                            {this.state.timelineOptionsXaxis && this.state['timelineSeries_'+index] ?
                                                                <Chart options={this.state.timelineOptionsXaxis} series={this.state['timelineSeries_'+index]} type="bar" height={30}/>
                                                            :
                                                                null
                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-secondary">
                                        {this.state.alertsByAssets && this.state.alertsByAssets.alerts.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td className="d-flex">
                                                        <span className={`badge ${
                                                                item.severity ? 
                                                                    'risk-badge-'+item.severity.toLowerCase()
                                                                : 
                                                                    'badge-outline-secondary'
                                                            }`}
                                                        >
                                                            {item.severity === 'Low' ? 'L' : item.severity === 'Medium' ? 'M' :  item.severity === 'High' ? 'H' : item.severity === 'Critical' ? 'C' : '-'}
                                                        </span>
                                                    </td>
                                                    <td>{item.asset_name}</td>
                                                    <td>{item.description ? item.description : ''}</td>
                                                    <td className="w-100">
                                                        <div className="w-70 m-auto transparentTooltip">
                                                            {this.state['timelineOptions_'+index] && this.state['timelineSeries_'+index] ?
                                                                <Chart options={this.state['timelineOptions_'+index]} series={this.state['timelineSeries_'+index]} type="bar" height={15}/>
                                                            :
                                                            null
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            </div>
		)
	}
}
/**
 * Type of the props used in the component
 */
AlertLeftSection.propTypes = {
    getAlertsDailyTrend: PropTypes.func,
    getTotalAlertsCount: PropTypes.func,
    alertsGraphBrushInterval: PropTypes.func,
    setAiopsPropsDetails: PropTypes.func,
    listAlertsByAssets: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
	// console.log('state', state)
	return {
		accounts: state.filters.accounts,
        regions: state.filters.regions,
        caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : {},
        alertsList: state.aiops.caseDetails.results && state.aiops.caseDetails.results.alerts ? state.aiops.caseDetails.results.alerts : [],
        aiopsPropsDetails: state.aiops.aiopsPropsDetails ? state.aiops.aiopsPropsDetails : {},
        totalAlertCount: state.aiops.totalAlertCount,
    }
}

export default connect(mapStateToProps, {
    getAlertsDailyTrend,
    listAlertGroups,
    getAlertGroupDailyTrend,
    getTotalAlertsCount,
    setAiopsPropsDetails,
    listAlertsByAssets,
})(withRouter(AlertLeftSection))