/*************************************************
 * Tvastar
 * @exports
 * @file UserDetails.js
 * @author Rajasekar // on 20/02/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

class UserDetails extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	render() {
		return (
			<div className="box p-3 h-100 text-right bg-dark3">
				<h1 className="bx-watermark display-3 watermark-lc">
					<i className="fa fa-address-card" />
				</h1>
				{this.props.createdBy ? (
					<>
						<h6 className="text-white">{this.props.createdBy}</h6>
						<p>Created By</p>
					</>
				) : null}
				{this.props.createdAt ? (
					<>
						<h6 className="text-white">{moment(this.props.createdAt).format("DD MMM YYYY hh:mm")}</h6>
						<p className="mb-0">Created on</p>
					</>
				) : null}
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
UserDetails.propTypes = {
	createdBy: PropTypes.string,
	createdAt: PropTypes.string,
}

export default UserDetails
