/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Topology.js
 * @author Prakash // on 6/07/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { AppWrapper } from './common/AppWrapper'
import PropTypes from 'prop-types'
import Graph from "react-graph-vis"; //https://visjs.github.io/vis-network/examples/
import _ from 'lodash'
import { momentDateGivenFormat, momentConvertionLocalToUtcTime, getDayFromSelectedDuration, subDays, subHours, getAccountNameFromId, getRegionName } from '../utils/utility'
import { listAllProviders, listAllAccounts, listAllRegions, listAllServices } from '../actions/commonAction'
import { getTopology } from '../actions/aiops/TopologyAction'
import { setAiopsPropsDetails } from '../actions/aiops/AiopsAction'
import { getCoveredServices } from '../actions/detectors/detectorsAction'

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

import { RangePicker } from "react-minimal-datetime-range";
import "react-minimal-datetime-range/lib/react-minimal-datetime-range.min.css";

import compute from '../assets/images/compute.png'

import loadBalance from '../assets/images/aws_icons/load_balance.png'
import ec2 from '../assets/images/aws_icons/ec2.png'
import lambda from '../assets/images/aws_icons/lambda.png'
import rds from '../assets/images/aws_icons/rds.png'
import iam from '../assets/images/aws_icons/iam.png'
// import EBSDARK from '../assets/images/storage/Elastic-Block-Store-Dark.png'
import EBSLIGHT from '../assets/images/storage/Elastic-Block-Store-Light.png'
import ApexBarProgressChart from './common/charts/ApexBarProgressChart';

const durationOptions = [    
    { value: '+1h', option: 'Last 1 hour' },
    { value: '+3h', option: 'Last 3 hours' },
    { value: '+6h', option: 'Last 6 hours' },
    { value: '+12h', option: 'Last 12 hours' },
	{ value: '+24h', option: 'Last 24 hours' },
	{ value: '+3d', option: 'Last 3 days' },
	{ value: '+7d', option: 'Last 1 week' },
    { value: '+14d', option: 'Last 2 weeks' },
    { value: '+30d', option: 'Last 1 month' },
	{ value: 'custom', option: 'Custom Date' },
]

class Topology extends Component {
    
    constructor(props) {
        super(props)		
		this.accountRef = React.createRef()
		this.regionRef = React.createRef()
		this.daterRangeRef = React.createRef()

        this.nodeTypeRef = React.createRef()
        this.graphRef = React.createRef()
		

		this.state = {
            minMaxHeight: "700px", 
            minMaxHeightLeft: "700px",      

            //filter section start

            selectedProvider: "",

            selectedAccount: [],
            isAccountOpen: false,

            selectedRegion: [],
            isRegionOpen: false,

            selectedDuration: "+3h",

            
            //filter section end
            
            isnodeTypeOpen: false,
            // topologyType: 'node',
            allGraphData: {nodes:[], edges: []},
            filteredGraphData: {nodes:[], edges: []},

            directedGraph: false,
            showAssetDetail: false,
            
            removeNode: '',
            removeEdge: '',

            categoryList: [],
            selectedNodeTypes: [],
            nodeCategory: [],

            progressData: {
                data: [70],
                name: "Progress1",
                labels: ["Progress1"]
            }
        }
        
        // this.handleClick = this.handleClick.bind(this);
    }

	componentDidMount = () => {
        this.getFilterApis()
    	
		this.getWindowSize()
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 100  
        
        let graphHeaderFooter = 72
        let graphHeight = window.outerHeight - browserBorder - headerHeight - graphHeaderFooter
        
		let outerHeight = (window.outerHeight - browserBorder - headerHeight ) + "px"

        let minMaxHeightLeft = (window.outerHeight - browserBorder - headerHeight - graphHeaderFooter ) + "px"
		this.setState({ outerHeight, minMaxHeightLeft, minMaxHeight: outerHeight, graphHeight })
    }

    getFilterApis = () => {
        if(this.props.providers && this.props.providers.length) {
			this.setState({ providers: this.props.providers, selectedProvider: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : this.props.providers[0].provider_name },
				() => {
					this.getFilterData('provider')
				}
			)
		} else {
			this.props.listAllProviders((promise, providers) => {
				if (promise) {
					this.setState({ providers, selectedProvider: providers.length ? providers[0].provider_name : "" },
						() => {
							this.getFilterData('provider')
						}
					)
				} else {
					this.setState({ providers: []},
						() => {
							this.getFilterData()
						}
					)
				}
			})
		}
    }
    
	getFilterData = from => {
		let params = {}

		if (this.state.selectedProvider !== "" && this.state.selectedProvider !== 'all') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}

		if (from === 'provider') {
			if(this.props.accounts && this.props.accounts.length) {
				let allAccounts = this.props.accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
				if(this.props.accounts.length) {
					let accRow = {}
					accRow.account_id = "All"
					accRow.account_name = "All"
					accRow.date = null
					accRow.disabled = false
					accRow.provider = "All"
					allAccounts.unshift(accRow);
				}
				this.setState({ accounts: allAccounts })
			} else {
				this.props.listAllAccounts(params, (promise, allAccounts) => {
					if (promise) {						
						let filterdAccounts = allAccounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
						if(filterdAccounts.length) {
							let accRow = {}
							accRow.account_id = "All"
							accRow.account_name = "All"
							accRow.date = null
							accRow.disabled = false
							accRow.provider = "All"
							filterdAccounts.unshift(accRow);
						}						
						this.setState({ accounts: filterdAccounts })
					}
				})
			}

			if(this.props.regions && this.props.regions.length) {
				let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
				this.setState({ regions: providerRegions })
			} else {
				this.props.listAllRegions(params, (promise, regions) => {
					if (promise) {
						let providerRegions = regions && regions[0] && regions[0].provider_regions
						if(providerRegions) {
							let provider_regions_row = {}
							provider_regions_row.availability_zones = []
							provider_regions_row.city = "All"
							provider_regions_row.country = "All"
							provider_regions_row.geography = "All"
							provider_regions_row.name = "All"
							provider_regions_row.region = "All"
							provider_regions_row.region_id = "All"
							provider_regions_row.state = "All"
							providerRegions.unshift(provider_regions_row);
						}
						this.setState({ regions: providerRegions })
					} else {
						this.setState({ regions: [] })
					}
				})
			}

            // this.props.listAllServices(params, (promise, services) => {})
		}

        this.durationFunction('onLoad')
	}

    handleSelectNew = (date) => {
        
        // let sliderStartDate = date[0]
		// let sliderEndDate = date[1]
        
        let datePickerStartDate = momentDateGivenFormat(date[0], "YYYY-MM-DD HH:mm:00")
		let datePickerEndDate = momentDateGivenFormat(date[1], "YYYY-MM-DD HH:mm:00")

        this.setState({ datePickerStartDate, datePickerEndDate, changesInFilter: true, showDateRangePicker: false })
	}

    durationFunction = (type) => {
        let duration = this.state.selectedDuration
        let period = duration.substring(duration.length, duration.length-1)
        let durationBtw = getDayFromSelectedDuration(duration)
        let sliderStartDate = ""
        if(period === "d") {
            sliderStartDate = momentDateGivenFormat(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
        } else {
            sliderStartDate = momentDateGivenFormat(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
        }
        // let days = this.state.selectedDuration.replace('+', "").replace('d', "")
        // let reducedDays = days - 1
        // let sliderStartDate = momentDateGivenFormat(subDays(new Date(), reducedDays), 'YYYY-MM-DD 00:00:00')
        
        let sliderEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')

        this.setState({ sliderStartDate, sliderEndDate, changesInFilter: true },
            () => {
                if(type === "onLoad") {
                    this.onSearch('onLoad')
                }
            }
        )
    } 

    onSearch = (type) => {
        if(this.state.changesInFilter) {
            let sliderStartDate = this.state.sliderStartDate
            let sliderEndDate = this.state.sliderEndDate
            if(this.state.selectedDuration === "custom") {
                sliderStartDate = this.state.datePickerStartDate && this.state.datePickerStartDate !== "" ? this.state.datePickerStartDate : sliderStartDate
                sliderEndDate = this.state.datePickerEndDate && this.state.datePickerEndDate !== "" ? this.state.datePickerEndDate : sliderEndDate
            }
            this.setState({
                brushStartTime: "",
                brushEndTime: "",
                changesInFilter: false,
                showLoading: true,
                resetEnabled: type === 'onLoad' ? false : true,
                sliderStartDate,
                sliderEndDate,
                nextErrorListToken: "",
                selectedAsset: ""
            },
                () => {
                    this.getTopology()
                    this.getCoveredServices()
                }
            )
        }

        // setTimeout(() => { this.setState({ showLoading: false }) }, 3000)
    }

    onReset = () => {
        this.setState({ 
            selectedProvider: "",
            selectedAccount: [],
            selectedDuration: '+7d',
            changesInFilter: this.state.resetEnabled,
            datePickerStartDate: "",
            datePickerEndDate: ""
        },
            () => {
                if(this.state.resetEnabled) {
                    this.durationFunction('onLoad')
                }
            }
        )
    }

    handleClickOutside = (event) => {
        if (this.daterRangeRef && !this.daterRangeRef.current.contains(event.target)) {
            this.setState({ showDateRangePicker: false })
        }

        if (this.accountRef && !this.accountRef.current.contains(event.target)) {
            this.setState({ isAccountOpen: false })
        } else {
            this.setState({ isAccountOpen: true })
        }

        if (this.regionRef && !this.regionRef.current.contains(event.target)) {
            this.setState({ isRegionOpen: false })
        } else {
            this.setState({ isRegionOpen: true })
        }

        // if (this.nodeTypeRef && !this.nodeTypeRef.current.contains(event.target)) {
        //     this.setState({ isnodeTypeOpen: false })
        // } else {
        //     this.setState({ isnodeTypeOpen: true })
        // }
    }

    getCoveredServices = () => {
        let params = {}
        params.provider = this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider.toLowerCase() : ""
        if(this.state.selectedAccount && this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
            params.region = this.state.selectedRegion
        }
        params.aggregate_by = ["category"]
        
        if(params.provider !== "") {
            this.props.getCoveredServices(params, (promise, response) => {
                if(promise) {
                    let results = response.results && response.results.length ? response.results : []
                    this.setState({ 
                        categoryList: results,
                    })
                } else {
                    this.setState({ categoryList: [], showLoading: false })
                }
            })
        } else {
            this.setState({ categoryList: [], showLoading: false })
        }
    }
    
    getTopology = () => {
        let params = {}
        params.provider = this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider.toLowerCase() : ""
        if(this.state.selectedAccount) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
            params.region = this.state.selectedRegion
        }

        params.alerts = true
        params.start_time = momentConvertionLocalToUtcTime(this.state.sliderStartDate, "YYYY-MM-DD HH:mm:ss")
        params.end_time = momentConvertionLocalToUtcTime(this.state.sliderEndDate, "YYYY-MM-DD HH:mm:ss")

        this.props.getTopology(params, (promise, result) => {
            if(promise) {
                let nodeCategory = []
                let topologyGraphData = {}
                let nodes = []
                let edges = []
                let asset = []

                if(result.edge_details && result.edge_details.length) {
                    result.edge_details.forEach((vertex, i) => {
                        let edgeRow = {}
                        
                        edgeRow.from = result.vertex_details[vertex.start] && result.vertex_details[vertex.start].asset_id
                        edgeRow.to = result.vertex_details[vertex.end] && result.vertex_details[vertex.end].asset_id
                        edgeRow.label = vertex.label
                        edgeRow.length = 300
                        edgeRow.edge_details = {
                            vertex: vertex,
                            from: result.vertex_details[vertex.start] && result.vertex_details[vertex.start],
                            to: result.vertex_details[vertex.end] && result.vertex_details[vertex.end],
                        }
                        edgeRow.title = this.edgeToolTipDetails(vertex, result.vertex_details[vertex.start] && result.vertex_details[vertex.start], result.vertex_details[vertex.end] && result.vertex_details[vertex.end])
                        // edgeRow.description = 'Sed in semper ex. Nulla sed finibus orci, eget semper odio. Aenean viverra bibendum ultrices viverra bibendum ultrices..'
                        if(i === 0) {
                            edgeRow.dashes = true
                        } else {
                            edgeRow.dashes = false
                        }
                        edgeRow.font ={
                            color: '#FF6F00',
                            align: "top"
                        }
                        edgeRow.color = '#FF9900'
                        edges.push(edgeRow)
                        
                        let nodeRow = {}
                        let assetRow = {}
                        if(!nodes.filter(arr => arr.id === result.vertex_details[vertex.start].asset_id).length) {
                            let nodeDetails = result.vertex_details[vertex.start]
                            nodeRow = {}
                            nodeRow.id = nodeDetails.asset_id
                            nodeRow.name = nodeDetails.asset_name
                            nodeRow.title = this.nodeToolTipDetails(nodeDetails)
                            nodeRow.node_details = nodeDetails
                            nodeRow.custom_label = nodeRow.name
                            
                            // nodeRow.vectoreId = nodeDetails.asset_name
                            // nodeRow.name = nodeDetails.asset_name
                            // nodeRow.vpc_id = nodeDetails.vpc_id
                            // nodeRow.resource_type = nodeDetails.resource_type
                            // nodeRow.x = (i * 50)
                            // nodeRow.y = (i * 100)
                            
                            let resourceType = nodeDetails.resource_type
                            let serviceName = nodeDetails.service_name

                            nodeRow.node_category = nodeDetails.node_type ? nodeDetails.node_type : []
                            nodeRow.node_category.length && nodeRow.node_category.forEach(cat => {
                                if(nodeCategory.indexOf(cat) === -1) {
                                    nodeCategory.push(cat)
                                }
                                
                                nodeRow.size = 50
                                if(resourceType === "EC2") {
                                    nodeRow.shape = 'image'
                                    nodeRow.image = ec2
                                    nodeRow.borderWidth = 0
                                } else if(resourceType === "Lambda" && serviceName === "lambda") {
                                    nodeRow.shape = 'image'
                                    nodeRow.image = lambda
                                    nodeRow.borderWidth = 0
                                } else if(resourceType === "RDS" && serviceName !== "rds-clusters") {
                                    nodeRow.shape = 'image'
                                    nodeRow.image = rds
                                    nodeRow.borderWidth = 0
                                } else if(resourceType === "RDS" && serviceName === "rds-clusters") { 
                                    nodeRow.shape = "custom"
                                    nodeRow.borderWidth = 5
                                    nodeRow.ctxRenderer = ({ ctx, id, x, y, state: { selected, hover }, style }) => {
                                        let message = nodeRow.name
                                        let size = 60
                                        return this.drawDoubleCircle(ctx, id, x, y, style, message, size)
                                    }
                                } else if(resourceType === "IAM") {
                                    nodeRow.shape = 'image'
                                    nodeRow.image = iam
                                    nodeRow.borderWidth = 0
                                } else if(nodeRow.node_category.indexOf('Networking') >= 0) { 
                                    nodeRow.fontColor = '#693EBC'
                                } else if(nodeRow.node_category.indexOf('Analytics') >= 0) { 
                                    nodeRow.fontColor = '#693EBC' 
                                } else if(nodeRow.node_category.indexOf('Security') >= 0) { 
                                    nodeRow.fontColor = '#693EBC'
                                } else if(nodeRow.node_category.indexOf('CDN') >= 0) { 
                                    nodeRow.fontColor = '#693EBC'
                                } else if(nodeRow.node_category.indexOf('IAM') >= 0) { 
                                    nodeRow.fontColor = '#693EBC'
                                } else if(nodeRow.node_category.indexOf('Compute') >= 0) { 
                                    nodeRow.fontColor = '#007bff'
                                    nodeRow.shape = 'image'
                                    // nodeRow.shape = 'circularImage'
                                    nodeRow.image = compute
                                    nodeRow.borderWidth = 0
                                } else if(nodeRow.node_category.indexOf('Database') >= 0) { 
                                    nodeRow.fontColor = '#06CB9E'
                                } else if(nodeRow.node_category.indexOf('LoadBalancer') >= 0) {
                                    nodeRow.fontColor = '#007bff'                                     
                                    nodeRow.shape = 'image'
                                    // nodeRow.shape = 'circularImage'
                                    nodeRow.image = loadBalance
                                    nodeRow.borderWidth = 0
                                } else if(nodeRow.node_category.indexOf('Storage') >= 0) {      
                                    nodeRow.shape = 'image'
                                    // nodeRow.shape = 'circularImage'
                                    nodeRow.image = EBSLIGHT
                                    nodeRow.borderWidth = 0
                                }
                                
                                // if(nodeRow.node_category.indexOf('Compute') < 0 && nodeRow.node_category.indexOf('LoadBalancer') < 0 && nodeRow.node_category.indexOf('Storage') < 0) {
                                //     nodeRow.shape = "custom"
                                //     nodeRow.borderWidth = 5
                                //     nodeRow.ctxRenderer = ({ ctx, id, x, y, state: { selected, hover }, style }) => {
                                //         let message = nodeRow.name
                                //         let size = 50
                                //         return this.drawDoubleCircle(ctx, id, x, y, style, message, size)
                                //     }
                                // }
                            })
                            // nodeRow.tags = nodeDetails.tags ? nodeDetails.tags : []
                            let getAlertDetails = this.getVertexAlertDetails(nodeRow.id, result)
                            // let size = 200;
                            let count = 0;
                            let severity = ''
                            let color = '#d3d3d3'
                            if(getAlertDetails && Object.entries(getAlertDetails).length) {
                                if(getAlertDetails.count) {
                                    count = getAlertDetails.count
                                    // if(getAlertDetails.count > 10) { size = 800
                                    // } else if(getAlertDetails.count > 7) { size = 600
                                    // } else if(getAlertDetails.count > 4) { size = 500
                                    // } else { size = 300 }
                                }
                
                                if(getAlertDetails.severity) {
                                    severity = getAlertDetails.severity
                                    if(getAlertDetails.severity === 'Critical') { color = '#F75E3F'
                                    } else if(getAlertDetails.severity === 'High') { color = '#FFD200'
                                    } else if(getAlertDetails.severity === 'Medium') { color = '#1FCFE7'
                                    } else { color = '#06CB9E' }
                                }
                                
                                if(getAlertDetails.risk_wise) {
                                    nodeRow.risk_wise = getAlertDetails.risk_wise
                                }
                            }
                            // nodeRow.severity = severity                            
                            // nodeRow.color = color
                            // // nodeRow.size = size
                            // nodeRow.count = count
                            // nodeRow.fontSize = 50
                            // nodeRow.fontWeight = 600
                            // nodeRow.description = nodeRow.vectoreId+' Sed in semper ex. Nulla sed finibus orci, eget semper odio. Aenean viverra bibendum ultrices viverra bibendum ultrices..'

                            nodes.push(nodeRow)
                            assetRow = {}
                            assetRow.label = nodeDetails.asset_name
                            assetRow.id = nodeDetails.asset_name
                            asset.push(assetRow)                    
                        } 
                        
                        if(!nodes.filter(arr => arr.id === result.vertex_details[vertex.end].asset_id).length) {
                            let nodeDetails = result.vertex_details[vertex.end]
                            
                            nodeRow = {}                            
                            nodeRow.id = nodeDetails.asset_id
                            nodeRow.name = nodeDetails.asset_name
                            nodeRow.title = this.nodeToolTipDetails(nodeDetails)
                            nodeRow.node_details = nodeDetails
                            nodeRow.custom_label = nodeRow.name

                            let resourceType = nodeDetails.resource_type
                            let serviceName = nodeDetails.service_name
                            
                            nodeRow.node_category = nodeDetails.node_type ? nodeDetails.node_type : []
                            nodeRow.node_category.length && nodeRow.node_category.forEach(cat => {
                                if(nodeCategory.indexOf(cat) === -1) {
                                    nodeCategory.push(cat)
                                }

                                nodeRow.size = 50
                                if(resourceType === "EC2") {
                                    nodeRow.shape = 'image'
                                    nodeRow.image = ec2
                                    nodeRow.borderWidth = 0
                                } else if(resourceType === "Lambda" && serviceName === "lambda") {
                                    nodeRow.shape = 'image'
                                    nodeRow.image = lambda
                                    nodeRow.borderWidth = 0
                                } else if(resourceType === "RDS" && serviceName !== "rds-clusters") {
                                    nodeRow.shape = 'image'
                                    nodeRow.image = rds
                                    nodeRow.borderWidth = 0
                                } else if(resourceType === "RDS" && serviceName === "rds-clusters") { 
                                    nodeRow.shape = "custom"
                                    nodeRow.borderWidth = 5
                                    nodeRow.ctxRenderer = ({ ctx, id, x, y, state: { selected, hover }, style }) => {
                                        let message = nodeRow.name
                                        let size = 60
                                        return this.drawDoubleCircle(ctx, id, x, y, style, message, size)
                                    }
                                } else if(resourceType === "IAM") {
                                    nodeRow.shape = 'image'
                                    nodeRow.image = iam
                                    nodeRow.borderWidth = 0
                                } else if(nodeRow.node_category.indexOf('Networking') >= 0) {
                                    nodeRow.fontColor = '#693EBC'
                                } else if(nodeRow.node_category.indexOf('Analytics') >= 0) {
                                    nodeRow.fontColor = '#693EBC' 
                                } else if(nodeRow.node_category.indexOf('Security') >= 0) {
                                    nodeRow.fontColor = '#693EBC'
                                } else if(nodeRow.node_category.indexOf('CDN') >= 0) {
                                    nodeRow.fontColor = '#693EBC'
                                } else if(nodeRow.node_category.indexOf('IAM') >= 0) {
                                    nodeRow.fontColor = '#693EBC'
                                } else if(nodeRow.node_category.indexOf('Compute') >= 0) {
                                    nodeRow.fontColor = '#007bff'                                     
                                    nodeRow.shape = 'image'
                                    // nodeRow.shape = 'circularImage'
                                    nodeRow.image = compute
                                    nodeRow.borderWidth = 0
                                } else if(nodeRow.node_category.indexOf('Database') >= 0) {
                                     nodeRow.fontColor = '#06CB9E'
                                } else if(nodeRow.node_category.indexOf('LoadBalancer') >= 0) {
                                    nodeRow.fontColor = '#007bff'                                     
                                    nodeRow.shape = 'image'
                                    // nodeRow.shape = 'circularImage'
                                    nodeRow.image = loadBalance
                                    nodeRow.borderWidth = 0
                                } else if(nodeRow.node_category.indexOf('Storage') >= 0) {
                                    nodeRow.shape = 'image'
                                    // nodeRow.shape = 'circularImage'
                                    nodeRow.image = EBSLIGHT
                                    nodeRow.borderWidth = 0
                                }
                                
                                // if(nodeRow.node_category.indexOf('Compute') < 0 && nodeRow.node_category.indexOf('LoadBalancer') < 0 && nodeRow.node_category.indexOf('Storage') < 0) {
                                //     nodeRow.shape = "custom"
                                //     nodeRow.borderWidth = 5
                                //     nodeRow.ctxRenderer = ({ ctx, id, x, y, state: { selected, hover }, style }) => {
                                //         let message = nodeRow.name
                                //         let size = 50
                                //         return this.drawDoubleCircle(ctx, id, x, y, style, message, size)
                                //     }
                                // }
                            })
                            // nodeRow.tags = nodeDetails.tags ? nodeDetails.tags : []
                            let getAlertDetails = this.getVertexAlertDetails(nodeRow.id, result)
                            // let size = 200;
                            let count = 0;
                            let severity = ''
                            let color = '#d3d3d3'
                            if(getAlertDetails && Object.entries(getAlertDetails).length) {
                                if(getAlertDetails.count) {
                                    count = getAlertDetails.count
                                    // if(getAlertDetails.count > 10) { size = 800
                                    // } else if(getAlertDetails.count > 7) { size = 600
                                    // } else if(getAlertDetails.count > 4) { size = 500
                                    // } else { size = 300 }
                                }
                
                                if(getAlertDetails.severity) {
                                    severity = getAlertDetails.severity
                                    if(getAlertDetails.severity === 'Critical') { color = '#26B68F'
                                    } else if(getAlertDetails.severity === 'High') { color = '#2ED1BE'
                                    } else if(getAlertDetails.severity === 'Medium') { color = '#67E1D6'
                                    } else { color = '#00B5D4' }
                                }
                                
                                if(getAlertDetails.risk_wise) {
                                    nodeRow.risk_wise = getAlertDetails.risk_wise
                                }
                            }
                            // nodeRow.severity = severity
                            // nodeRow.color = color
                            // // nodeRow.size = size
                            // nodeRow.count = count
                            
                            // nodeRow.fontSize = 50
                            // nodeRow.fontWeight = 600
                            // nodeRow.description = nodeRow.vectoreId+' Sed in semper ex. Nulla sed finibus orci, eget semper odio. Aenean viverra bibendum ultrices viverra bibendum ultrices..'

                            nodes.push(nodeRow)

                            assetRow = {}
                            assetRow.label = nodeDetails.asset_name
                            assetRow.id = nodeDetails.asset_name
                            asset.push(assetRow)                    
                        }
                    })
                }

                console.log("nodes", nodes)
                console.log("edges", edges)

                topologyGraphData['nodes'] = nodes
                topologyGraphData['edges'] = edges

                asset = asset.filter((thing, index, self) =>
                    index === self.findIndex((t) => (
                        t.label === thing.label && t.id === thing.id
                    ))
                )

                // console.log('topologyGraphData', topologyGraphData)
                
                let selectedNodeTypes = []
                if(nodeCategory.includes('Compute')) {
                    selectedNodeTypes.push('Compute')
                }
                if(nodeCategory.includes('Database')) {
                    selectedNodeTypes.push('Database')
                }
                
                if(nodeCategory.includes('Storage')) {
                    selectedNodeTypes.push('Storage')
                }
                

                this.setState({ allGraphData: topologyGraphData, filterAsset: asset, nodeCategory, selectedNodeTypes, showLoading: false },
                    () => this.updateInput()
                )
            } else {
                this.setState({ allGraphData: {nodes:[], edges: []}, filteredGraphData: {nodes:[], edges: []}, nodeCategory: [], showLoading: false })
            }
        })
    }

    nodeToolTipDetails = (node_details) => {
        let html = '<p class="mb-1 f12 text-primary-color">Asset Name: <span class="text-white ml-1">'+node_details.asset_name+'</span></p><p class="mb-1 f12 text-primary-color">Services: <span class="text-white ml-1">'+(node_details.resource_type ? node_details.resource_type : "")+'</span><span class="text-white">'+(node_details.service_name ? " : "+node_details.service_name : "")+'</span></p><p class="mb-0 f12 text-primary-color">Resources: <span class="text-white ml-1">'+(node_details.provider ? node_details.provider.toUpperCase() : "")+'</span><span class="text-white">'+(node_details.account_id ? " : "+ getAccountNameFromId(node_details.account_id, this.state.accounts) : "")+'</span><span class="text-white">'+(node_details.region ? " : "+ getRegionName(node_details.region, this.state.regions) : "")+'</span></p> <div id="graphContainer"></div>'

        // <ApexAreaChart 
        //     graphData={"mockData"}
        //     name={"Threats"}
        //     xaxis={false}
        //     yaxis={true}
        //     yaxisLabe={false}
        //     markerSize={2}
        //     hideMarkerLength={45}
        //     gradient={true}
        //     gradientColor={["#9A77D1", "#fff"]}
        //     colors={["#9A77D1"]}
        //     sparkline={false}
        //     zoom={false}
        //     height={90}
        //     width={"100%"}
        //     grid={true}
        //     className={"transparentTooltip"}
        // />

        // let div = document.getElementById('graphContainer')

        // div.innerHTML += data

        // html += div

        let container = document.createElement("div");
        container.classList.add('p-2');
        container.innerHTML = html;
        return container;
    }

    edgeToolTipDetails = (vertex, from, to) => {
        let html = '<p class="mb-1 f12 text-info">From Node</p><p class="mb-1 f12 text-primary-color">Asset Name: <span class="text-white ml-1">'+from.asset_name+'</span></p><p class="mb-1 f12 text-primary-color">Services: <span class="text-white ml-1">'+(from.resource_type ? from.resource_type : "")+'</span><span class="text-white">'+(from.service_name ? " : "+from.service_name : "")+'</span></p><p class="mb-0 f12 text-primary-color">Resources: <span class="text-white ml-1">'+(from.provider ? from.provider.toUpperCase() : "")+'</span><span class="text-white">'+(from.account_id ? " : "+ getAccountNameFromId(from.account_id, this.state.accounts) : "")+'</span><span class="text-white">'+(from.region ? " : "+ getRegionName(from.region, this.state.regions) : "")+'</span></p>'

        html += '<p class="mt-2 mb-1 f12 text-info">Relationship</p><p class="mb-2 f12 text-white">'+vertex.label+'</p>'

        html += '<p class="mb-1 f12 text-info">To Node</p><p class="mb-1 f12 text-primary-color">Asset Name: <span class="text-white ml-1">'+to.asset_name+'</span></p><p class="mb-1 f12 text-primary-color">Services: <span class="text-white ml-1">'+(to.resource_type ? to.resource_type : "")+'</span><span class="text-white">'+(to.service_name ? " : "+to.service_name : "")+'</span></p><p class="mb-0 f12 text-primary-color">Resources: <span class="text-white ml-1">'+(to.provider ? to.provider.toUpperCase() : "")+'</span><span class="text-white">'+(to.account_id ? " : "+ getAccountNameFromId(to.account_id, this.state.accounts) : "")+'</span><span class="text-white">'+(to.region ? " : "+ getRegionName(to.region, this.state.regions) : "")+'</span></p>'


        let container = document.createElement("div");
        container.classList.add('p-2');
        container.innerHTML = html;


        return container;

    }
    
    getVertexAlertDetails = (asset_name, result) => {
        let alertDetails = result.alert_count[asset_name] ? result.alert_count[asset_name] : {}
        return alertDetails
    }
    
    changeGraphOption = (label) => {
        let myConfig = this.state.myConfig
        if(label === 'directed') {
            myConfig = Object.assign({}, myConfig, {                
                directed: this.state.directedGraph
            });
        }

        this.setState({ myConfig })
    }
    
    resetGraph = () => {
        let selectedNodeTypes = []
        if(this.state.nodeCategory.includes('Compute')) {
            selectedNodeTypes.push('Compute')
        }
        if(this.state.nodeCategory.includes('Database')) {
            selectedNodeTypes.push('Database')
        }        
        if(this.state.nodeCategory.includes('Storage')) {
            selectedNodeTypes.push('Storage')
        }
        this.setState({ filteredGraphData: {nodes:[], edges: []}, options: {}, events: {}, selectedNodeTypes }, 
            () => {
                this.updateInput()
            }
        )
    }
    
    addRemoveNodes = (type) => {
        let data = this.state.filteredGraphData
        let edges = data.edges
        let nodes = data.nodes
        if(type === 'remove') {
            if(nodes.length > 1) {
                let removeNode = nodes[nodes.length-1].id
                edges = edges.filter(arr => arr.source !== removeNode)
                edges = edges.filter(arr => arr.target !== removeNode)
                nodes = nodes.filter(arr => arr.id !== removeNode)
            }
        } else {
            let allData = this.state.allGraphData
            let allEdges = allData.edges
            let allNodes = allData.nodes
            if(nodes.length < allNodes.length) {
                let addNode = allNodes[nodes.length].id
                allNodes = allNodes.filter(arr => arr.id === addNode)
                nodes = [...nodes, ...allNodes];
                
                let formNewEdgesSource = allEdges.filter(arr => arr.source === addNode)
                let formNewEdgesTarget = allEdges.filter(arr => arr.target === addNode)                
                allEdges = [...formNewEdgesSource, ...formNewEdgesTarget];

                let formEdges = []
                allEdges.length && allEdges.forEach(item => {
                    if(nodes.filter(arr => arr.id === item.source).length) {
                        formEdges.push(item)
                    }
                })
                
                edges = [...edges, ...formEdges];

            }
        }
        data = Object.assign({}, data, {
            edges: edges,
            nodes: nodes
        });

        this.setState({ filteredGraphData: data }, 
            // () => this.updateInput('from_remove_edges')
        )
    }

    decorateGraphNodesWithInitialPositioning = nodes => (nodes.map(n =>
        Object.assign({}, n, {
          x: n.x || Math.floor(Math.random() * 500),
          y: n.y || Math.floor(Math.random() * 500)
        })
    ));

    updateInput = () => {
        // this.setState({ filteredGraphData: {nodes:[], edges: []} })
        let data = this.state.allGraphData

        let edges = data.edges
        let nodes = data.nodes
        let existNodes = []
        let arrayDiff = this.state.nodeCategory.filter(x => !this.state.selectedNodeTypes.includes(x));
        if(this.state.selectedNodeTypes && this.state.selectedNodeTypes.length) {
            this.state.selectedNodeTypes.forEach(cat => {
                let newNodes = nodes
                newNodes.filter((item) => {
                    if(item.node_category.indexOf(cat) >= 0) {
                        existNodes.push(item)
                    }
                })
            })

            arrayDiff.length && arrayDiff.forEach(cat => {
                nodes.filter((item) => {
                    if(item.node_category.indexOf(cat) >= 0 || !item.node_category.length) {
                        edges = edges.filter(arr => arr.from !== item.id)
                        edges = edges.filter(arr => arr.to !== item.id)
                    }
                });
            })
            nodes = existNodes 
        }

        nodes.length && nodes.forEach((item, i) => {
            // if(i === 0) {
            //     item.fixed = true
            //     item.x = -1000
            //     item.y = 0
            // }
        })

        let graphData = {
            edges: edges,
            nodes: nodes
            // nodes: nodes
        }

        // console.log(graphData)

        let options = {
            width: '100%',
            height: '1000px',
            autoResize: true,
            clickToUse: false,
            layout: {
                randomSeed: undefined,
                improvedLayout:true,
                clusterThreshold: 300,
                hierarchical: {
                  enabled:false,
                  levelSeparation: 1000,
                  nodeSpacing: 600,
                  treeSpacing: 1000,
                  blockShifting: true,
                  edgeMinimization: true,
                  parentCentralization: true,
                  direction: 'LR',        // UD, DU, LR, RL
                  sortMethod: 'hubsize',  // hubsize, directed
                  shakeTowards: 'roots'  // roots, leaves
                }
            },  
            interaction:{
                dragNodes:true,
                dragView: true,
                hideEdgesOnDrag: false,
                hideEdgesOnZoom: false,
                hideNodesOnDrag: false,
                hover: true,
                hoverConnectedEdges: true,
                keyboard: {
                  enabled: false,
                  speed: {x: 10, y: 10, zoom: 0.02},
                  bindToWindow: true,
                  autoFocus: true,
                },
                multiselect: false,
                navigationButtons: true,
                selectable: true,
                selectConnectedEdges: true,
                tooltipDelay: 300,
                zoomSpeed: 1,
                zoomView: true,                
            },
            manipulation: {
                enabled: true
            },
            configure: {
                enabled: false,
                showButton: true
            },
            physics:{
                enabled: true,
                barnesHut: {
                  theta: 0.5,
                  gravitationalConstant: -2000,
                  centralGravity: 0.3,
                  springLength: 250,
                  springConstant: 1,
                  damping: 0.09,
                  avoidOverlap: 1
                },
                forceAtlas2Based: {
                  theta: 1,
                  gravitationalConstant: -2000,
                  centralGravity: 0.1,
                  springConstant: 1,
                  springLength: 5000, //added in edge
                  damping: 1,
                  avoidOverlap: 1
                },
                repulsion: {
                  centralGravity: 0.01,
                  springLength: 500,
                  springConstant: 0.05,
                  nodeDistance: 200,
                  damping: 0.09
                },
                hierarchicalRepulsion: {
                  centralGravity: 0.0,
                  springLength: 100,
                  springConstant: 0.01,
                  nodeDistance: 120,
                  damping: 0.09,
                  avoidOverlap: 0
                },
                maxVelocity: 50,
                minVelocity: 0.1,
                solver: 'forceAtlas2Based',
                stabilization: {
                  enabled: true,
                  iterations: 1000,
                  updateInterval: 100,
                  onlyDynamicEdges: false,
                  fit: true
                },
                timestep: 0.5,
                adaptiveTimestep: true,
                wind: { x: 0, y: 0 },
                start: {
                    zoom: 100
                }
            },
            nodes:{
                borderWidth: 1,
                borderWidthSelected: 2,
                brokenImage:undefined,
                chosen: true,
                color: {
                    border: '#2B7CE9',
                    background: '#97C2FC',
                    highlight: {
                        border: '#2B7CE9',
                        background: '#D2E5FF'
                    },
                    hover: {
                        border: '#2B7CE9',
                        background: '#D2E5FF'
                    }
                },
                opacity: 1,
                fixed: {
                  x:false,
                  y:false
                },
                font: {
                  color: '#343434',
                  size: 14, // px
                  face: "'Muli', sans-serif",
                  background: 'none',
                  strokeWidth: 0, // px
                  strokeColor: '#ffffff',
                  align: 'center',
                  multi: false,
                  vadjust: 0,
                  bold: {
                    color: '#343434',
                    size: 14, // px
                    face: "'Muli', sans-serif",
                    vadjust: 0,
                    mod: 'bold'
                  },
                  ital: {
                    color: '#343434',
                    size: 14, // px
                    face: "'Muli', sans-serif",
                    vadjust: 0,
                    mod: 'italic',
                  },
                  boldital: {
                    color: '#343434',
                    size: 14, // px
                    face: "'Muli', sans-serif",
                    vadjust: 0,
                    mod: 'bold italic'
                  },
                  mono: {
                    color: '#343434',
                    size: 15, // px
                    face: 'courier new',
                    vadjust: 2,
                    mod: ''
                  }
                },
                group: undefined,
                heightConstraint: false,
                hidden: false,
                icon: {
                  face: 'FontAwesome',
                  code: undefined,
                  weight: undefined,
                  size: 50,  //50,
                  color:'#2B7CE9'
                },
                image: undefined,
                imagePadding: {
                  left: 0,
                  top: 0,
                  bottom: 0,
                  right: 0
                },
                label: undefined,
                labelHighlightBold: true,
                level: undefined,
                mass: 1,
                physics: true,
                scaling: {
                  min: 10,
                  max: 30,
                  label: {
                    enabled: false,
                    min: 14,
                    max: 30,
                    maxVisible: 30,
                    drawThreshold: 5
                  },
                  customScalingFunction: function (min,max,total,value) {
                    if (max === min) {
                      return 0.5;
                    }
                    else {
                      let scale = 1 / (max - min);
                      return Math.max(0,(value - min)*scale);
                    }
                  }
                },
                shadow:{
                  enabled: false,
                  color: 'rgba(0,0,0,0.5)',
                  size:10,
                  x:5,
                  y:5
                },
                shape: 'ellipse',
                shapeProperties: {
                  borderDashes: false, // only for borders
                  borderRadius: 6,     // only for box shape
                  interpolation: false,  // only for image and circularImage shapes
                  useImageSize: false,  // only for image and circularImage shapes
                  useBorderWithImage: false,  // only for image shape
                  coordinateOrigin: 'center'  // only for image and circularImage shapes
                },
                size: 25,
                title: undefined,
                value: undefined,
                widthConstraint: false,
                x: undefined,
                y: undefined
            },
            edges:{
                arrows: {
                  to: {
                    enabled: true,
                    imageHeight: undefined,
                    imageWidth: undefined,
                    scaleFactor: 1,
                    src: undefined,
                    type: "arrow"
                  },
                  middle: {
                    enabled: false,
                    imageHeight: 32,
                    imageWidth: 32,
                    scaleFactor: 1,
                    src: "https://visjs.org/images/visjs_logo.png",
                    type: "image"
                  },
                  from: {
                    enabled: false,
                    imageHeight: undefined,
                    imageWidth: undefined,
                    scaleFactor: 1,
                    src: undefined,
                    type: "arrow"
                  }
                },
                endPointOffset: {
                  from: 0,
                  to: 0
                },
                arrowStrikethrough: true,
                chosen: true,
                color: {
                  color:'#000000',
                  highlight:'#000000',
                  hover: '#000000',
                  inherit: 'from',
                  opacity:1.0
                },
                dashes: false,
                font: {
                  color: '#00000',
                  size: 20, // px
                  face: "'Muli', sans-serif",
                  background: 'none',
                  strokeWidth: 0, // px
                  strokeColor: '#ffffff',
                  align: 'horizontal',
                  multi: false,
                  vadjust: 0,
                  bold: {
                    color: '#00000',
                    size: 20, // px
                    face: "'Muli', sans-serif",
                    vadjust: 0,
                    mod: 'bold'
                  },
                  ital: {
                    color: '#00000',
                    size: 20, // px
                    face: "'Muli', sans-serif",
                    vadjust: 0,
                    mod: 'italic',
                  },
                  boldital: {
                    color: '#00000',
                    size: 20, // px
                    face: "'Muli', sans-serif",
                    vadjust: 0,
                    mod: 'bold italic'
                  },
                  mono: {
                    color: '#00000',
                    size: 20, // px
                    face: 'courier new',
                    vadjust: 2,
                    mod: ''
                  }
                },
                hidden: false,
                hoverWidth: 3,
                label: undefined,
                labelHighlightBold: true,
                length: undefined,
                physics: true,
                scaling:{
                  min: 1,
                  max: 15,
                  label: {
                    enabled: true,
                    min: 14,
                    max: 30,
                    maxVisible: 30,
                    drawThreshold: 5
                  },
                  customScalingFunction: function (min,max,total,value) {
                    if (max === min) {
                      return 0.5;
                    }
                    else {
                      var scale = 1 / (max - min);
                      return Math.max(0,(value - min)*scale);
                    }
                  }
                },
                selectionWidth: 1,
                selfReferenceSize: 20,
                selfReference:{
                    size: 20,
                    angle: Math.PI / 4,
                    renderBehindTheNode: true
                },
                shadow:{
                  enabled: false,
                  color: 'rgba(0,0,0,0.5)',
                  size:10,
                  x:5,
                  y:5
                },
                smooth: {
                  enabled: true,
                  type: "dynamic",
                  roundness: 0.5
                },
                title:undefined,
                value: undefined,
                width: 1,
                widthConstraint: false
            }
        }

        let events = {
            hoverNode: el => {
                const node = el.node;
                const currentGraph = this.graphRef.current;
                const network = currentGraph.Network;
                const tree = document.getElementById("data-tree-container");
                
                if (tree) {
                    const elements = document.getElementsByClassName("dataTree_" + node);
                    if (elements) {
                        [...elements].forEach(elVal => {
                        elVal.style.fontWeight = 600;
                        });
                    }
                    network.body.nodes[node].options.borderWidth = 6;
                    const optionsContainer = document.getElementById("graph-options-container");
                    if (optionsContainer) {
                        optionsContainer.style.visibility = "hidden";
                        optionsContainer.style.display = "none";
                    }
                    const contextMenuContainer = document.getElementById("graph-contextmenu-container");
                    const contextMenuEdgeContainer = document.getElementById("graph-contextmenu-edge-container");
                    if (contextMenuContainer) {
                        contextMenuContainer.style.visibility = "hidden";
                        contextMenuContainer.style.display = "none";
                    }
                    if (contextMenuEdgeContainer) {
                        contextMenuEdgeContainer.style.visibility = "hidden";
                        contextMenuEdgeContainer.style.display = "none";
                    }
                    network.unselectAll();
                }
            },
            blurNode: el => {
                const node = el.node;
                const currentGraph = this.graphRef.current;
                const network = currentGraph.Network;
                const tree = document.getElementById("data-tree-container");
                if (tree) {
                    const elements = document.getElementsByClassName("dataTree_" + node);
                    if (elements) {
                        [...elements].forEach(elValue => {
                        elValue.style.fontWeight = 100;
                        });
                    }
                    network.body.nodes[node].options.borderWidth = 3;
                }
            },
            hoverEdge: el => {
                const tree = document.getElementById("data-tree-container");
                if (tree) {
                    const currentGraph = this.graphRef.current;
                    const network = currentGraph.Network;
                    const nodeParent = network.body.edges[el.edge].fromId;
                    Object.values(network.body.edges).forEach(edge => {
                        if (edge.fromId === nodeParent && edge.options.label === network.body.edges[el.edge].options.label) {
                            const index = network.body.edgeIndices.findIndex(id => id === edge.id);
                            const obj = document.getElementById("dataTree_edge" + index);
                            if (obj) {
                                obj.style.fontWeight = 600;
                            }
                        }
                    });
                }
            },
            blurEdge: el => {
                const tree = document.getElementById("data-tree-container");
                if (tree) {
                    const currentGraph = this.graphRef.current;
                    const network = currentGraph.Network;
                    const nodeParent = network.body.edges[el.edge].fromId;
                    Object.values(network.body.edges).forEach(edge => {
                        if (edge.fromId === nodeParent && edge.options.label === network.body.edges[el.edge].options.label) {
                            const index = network.body.edgeIndices.findIndex(id => id === edge.id);
                            const obj = document.getElementById("dataTree_edge" + index);
                            if (obj) {
                                obj.style.fontWeight = 100;
                            }
                        }
                    });
                }
            },
            click: event => {
                const { nodes, edges } = event;
                const contextMenuContainer = document.getElementById("graph-contextmenu-container");
                const contextMenuEdgeContainer = document.getElementById("graph-contextmenu-edge-container");
                if (contextMenuContainer) {
                    contextMenuContainer.style.visibility = "hidden";
                    contextMenuContainer.style.display = "none";
                }
                if (contextMenuEdgeContainer) {
                    contextMenuEdgeContainer.style.visibility = "hidden";
                    contextMenuEdgeContainer.style.display = "none";
                }
                this.getSelectedNodeEdgeDetails(nodes, edges)
                return { nodes, edges };
            },
            // showPopup: node => { 
            //     document.getElementById('popup-event').innerHTML = 'Popover shown! Node ID:' + node;
            //     document.getElementById('popup-event').style = 'color: red';
            // },
            // afterDrawing: ctx => {
            //     var percent = 50
            //     const currentGraph = this.graphRef.current;
            //     const network = currentGraph.Network;
            //     var pos = network.getPositions([1, 2]);
            //     ctx.strokeStyle = ctx.filStyle = 'green';
            //     ctx.moveTo(pos[1].x, pos[1].y);
            //     ctx.lineTo(pos[1].x + (pos[2].x-pos[1].x)*percent/100, pos[1].y + (pos[2].y - pos[1].y)*percent/100);
            //     ctx.fill();
            //     ctx.stroke();   
            // },
            afterDrawing: ctx => ctx,
            // beforeDrawing: ctx => ctx,
            beforeDrawing: ctx => {
                const currentGraph = this.graphRef.current;
                const network = currentGraph.Network;
                let nodeIds = network.body.nodeIndices
                let nodeDetails = network.body.nodes 
                console.log("nodeDetails", nodeDetails)
                Object.entries(nodeDetails).forEach(([key, value]) => {
                    if(nodeIds.includes(key)) {
                        if(value.options && value.options.name) {
                            var nodeId = value.options.id;
                            var nodePosition = network.getPositions([nodeId]);
                            ctx.fillStyle = '#24A597';
                            ctx.font = "25px Muli";
                            if(value.options.shape === 'image') {
                                ctx.fillText(value.options.name, nodePosition[nodeId].x - 60, nodePosition[nodeId].y - 80);
                            } else if(value.options.shape === 'custom') {
                                ctx.fillText(value.options.name, nodePosition[nodeId].x - 50, nodePosition[nodeId].y - 90);
                            } else {
                                ctx.fillText(value.options.name, nodePosition[nodeId].x - 50, nodePosition[nodeId].y - 90);
                            }
                        }
                    }
                    // let nodeItem = nodeDetails.item
                })
                // body = network.body
                // var nodeId = 1;
                // var nodePosition = network.getPositions([nodeId]);

                // ctx.fillStyle = '#EDEDED';
                // ctx.font = "20px";
                
                // var scale = network.getScale();                
                // var visibleFontSize = 20;
                // if (visibleFontSize > 30) {
                //   ctx.font = 30/scale + "px"'Muli', sans-serif";  
                // }
                // else {
                //   ctx.font = 20 + "px"'Muli', sans-serif";
                // }
                
                
                // ctx.fillText('hello world', nodePosition[nodeId].x - 20, nodePosition[nodeId].y - 50);
                
            },
            selectNode: clickEvent => {
                const { nodes } = clickEvent;
                const nodeId = nodes[0];
                const currentGraph = this.graphRef.current;
                currentGraph.lastClickedNode = nodeId;
    
                if (currentGraph) {
                const network = currentGraph.Network;
                // const graphOptionsRef = this.graphOptionsRef.current;
                if (network && nodeId) {
                    if (network.body.nodes[nodeId]) {
                        network.body.nodes[nodeId].options.borderWidth = 6;
                    }
                }
                if (
                    nodeId &&
                    // nodeId.includes("cluster:") &&
                    network.isCluster(nodeId)
                ) {
                    network.openCluster(nodeId);
                    network.setOptions({ physics: true });
                    setTimeout(() => { network.setOptions({ physics: false }) }, 300);
                } else if (nodeId) {
                    // graphOptionsRef.setState({});
                    // network.focus(nodeId, {
                    //   scale: 1,
                    //   animation: {
                    //     duration: 120,scc
                    //     easingFunction: 'easeInOutQuad',
                    //   }
                    // });
                    // window.setTimeout(() => {
                    //   const optionsContainer = document.getElementById(
                    //     "graph-options-container"
                    //   );
                    //   optionsContainer.style.visibility = "visible";
                    //   optionsContainer.style.display = "block";
                    //   optionsContainer.style.right = "0px";
                    //   optionsContainer.style.top = "43px";
                    //   // neighbourhoodHighlight(clickEvent, graphOptionsRef.props.getNodes(), network);
                    // }, 220);
                }
                }
            },
            deselectNode: (/* clickEvent */) => {
                const optionsContainer = document.getElementById("graph-options-container");
                if (optionsContainer) {
                    optionsContainer.style.visibility = "hidden";
                    optionsContainer.style.display = "none";
                }
                const contextMenuContainer = document.getElementById("graph-contextmenu-container");
                const contextMenuEdgeContainer = document.getElementById("graph-contextmenu-edge-container");
                if (contextMenuContainer) {
                    contextMenuContainer.style.visibility = "hidden";
                    contextMenuContainer.style.display = "none";
                }
                if (contextMenuEdgeContainer) {
                    contextMenuEdgeContainer.style.visibility = "hidden";
                    contextMenuEdgeContainer.style.display = "none";
                }
                // neighbourhoodHighlight(clickEvent, graphOptionsRef.props.getNodes(), network);
                this.graphRef.current.lastClickedNode = null;
            },
            doubleClick: params => {
                params.event.preventDefault();
                const currentGraph = this.graphRef.current;
                const network = currentGraph.Network;
                const firstDegreeConnections = [
                    ...network.getConnectedNodes(params.nodes),
                    ...params.nodes
                ];
                const connectedNodes = [];
                firstDegreeConnections.forEach(node => {
                const nodeConnections = network.getConnectedNodes(node);
                connectedNodes.push(node);
                nodeConnections.forEach(scndNode => {
                    if (!connectedNodes.includes(scndNode)) {
                     connectedNodes.push(scndNode); // Will bring second degree node connections
                    }
                });
                });
                const contextMenuContainer = document.getElementById("graph-contextmenu-container");
                const contextMenuEdgeContainer = document.getElementById("graph-contextmenu-edge-container");
                if (contextMenuContainer) {
                    contextMenuContainer.style.visibility = "hidden";
                    contextMenuContainer.style.display = "none";
                }
                if (contextMenuEdgeContainer) {
                    contextMenuEdgeContainer.style.visibility = "hidden";
                    contextMenuEdgeContainer.style.display = "none";
                }
                network.selectNodes(connectedNodes);
            },
            stabilizationProgress: params => {
                const maxWidth = 496;
                const minWidth = 20;
                const widthFactor = params.iterations / params.total;
                const width = Math.max(minWidth, maxWidth * widthFactor);
                // document.getElementById('graphLoadingBar').style.visibility = 'auto';
                if(document.getElementById("graphLoadingBar")) {
                    document.getElementById("graphLoadingBar").style.display = "block";
                }
                if(document.getElementById("bar")) {
                    document.getElementById("bar").style.width = width + "px";
                }
                if(document.getElementById("text")) {
                    document.getElementById("text").innerText = Math.round(widthFactor * 100) + "%";
                }
            },
            stabilizationIterationsDone: () => {
                if(document.getElementById("graphLoadingBar")) {
                    document.getElementById("graphLoadingBar").style.display = "none";
                }
                const currentGraph = this.graphRef.current;
                if (currentGraph) {
                    const network = currentGraph.Network;
                    const nodePositions = network.getPositions();
                    const graph = _.cloneDeep(this.state.filteredGraphData);
                    graph.nodes = graph.nodes.map(node => ({
                        ...node,
                        ...nodePositions[node.id]
                    }));
                    // network.setData({ nodes: graph.nodes, edges: graph.edges });
                    // network.setOptions({ physics: false });
        
                    this.setState({
                        filteredGraphData: { nodes: graph.nodes, edges: graph.edges },
                        options: { ...options, physics: false }
                    });
        
                    const lastClickedNode = currentGraph.lastClickedNode;
                    if (lastClickedNode) {
                        setTimeout(() => {
                        network.selectNodes([lastClickedNode]);
                        currentGraph.props.events.selectNode({
                            nodes: [lastClickedNode]
                        });
                        }, 0);
                    }
                }
            }
        }

        this.setState({ filteredGraphData: graphData, options, events })
    }
    
    handleMultiSelectChange = (field, arrayValue) => {
		let value = arrayValue.map(item => item.value)
        let label = arrayValue.map(item => item.label)

		let selectedValue = []
		if(field === 'selectedAccount') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.accounts.forEach(acc => {
						selectedValue.push(acc.account_id)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.accounts.length -1)) {
					selectedValue.push('All')
				}
			}	
            this.setState({ [field]: selectedValue, changesInFilter: true })		
		} else if(field === 'selectedRegion') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.regions.length -1)) {
					selectedValue.push('All')
				}
			}
            this.setState({ [field]: selectedValue })
		} else if(field === 'selectedService') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.regions.length -1)) {
					selectedValue.push('All')
				}
			}
            this.setState({ [field]: selectedValue })
		} else if(field === 'selectedNodeTypes') {
            this.setState({ [field]: value, options: {}, events: {}, filteredGraphData: {nodes: [], edges: []} },
                () => this.updateInput()
            )
        }
    }
    
    getMultiSelectedCount = (type, array) => {
        return array.length && array.includes('All') ? 'All' : (array.length ? array.length +' Selected' : 'All')
    }

    drawDoubleCircle = (ctx, id, x, y, style, message, size) => {
        let innerCircle = size
        let outerCircle = size + 10
        const r = style.size;
        const drawNode = () => {

            ctx.beginPath();
            ctx.arc(x, y, outerCircle, 0, 2 * Math.PI);
            ctx.closePath();
            ctx.save();                    
            ctx.fillStyle = "#2D323E";
            ctx.fill();
            ctx.lineWidth = 5
            ctx.strokeStyle = "#24A597";
            ctx.stroke();

            // ctx.beginPath();
            // ctx.arc(50, 75, 50, 0, 2 * Math.PI);
            // ctx.closePath();
            // ctx.save();
            // ctx.lineWidth = 10
            // ctx.strokeStyle = "#5F5BA2";
            // ctx.stroke();
            // ctx.fillStyle = "#00D180";
            // ctx.fill()

            // ctx.beginPath();
            // ctx.moveTo(10, 80);
            // ctx.lineTo(90, 80);
            // ctx.lineWidth = 3
            // ctx.fillStyle = "#FFF";
            // ctx.stroke();

            
            ctx.beginPath();
            ctx.arc(x, y, innerCircle, 0, 2 * Math.PI);
            ctx.closePath();
            ctx.save();                    
            ctx.fillStyle = "#2D323E";
            ctx.fill();
            ctx.lineWidth = 8
            ctx.strokeStyle = "#FFD200";
            ctx.stroke();

            ctx.beginPath();
            ctx.font = "22px 'Muli', sans-serif";
            ctx.fillStyle = "#ffffff";
            ctx.strokeStyle = "#ffffff";
            ctx.fillText("1 Tier", x-(innerCircle/2), y-10);
            ctx.fill();

            ctx.beginPath();
            ctx.moveTo(x+(innerCircle - 10), y);
            ctx.lineTo(x-(innerCircle - 10), y);
            ctx.lineWidth = 5            
            ctx.strokeStyle = "#ffffff";
            ctx.stroke();

            ctx.beginPath();
            ctx.font = "22px 'Muli', sans-serif";
            ctx.fillStyle = "#ffffff";
            ctx.strokeStyle = "#ffffff";
            ctx.fillText("2 Nodes", x-(innerCircle/2)-10, y+28);
            ctx.fill();



            ctx.restore();

            // ctx.font = "normal 12px sans-serif";
            // ctx.fillStyle = "black";
            // ctx.font = "10px"
            // ctx.fillStyle = "#90CBF6"

            // var lines = message.split('\n');
        
            // for (var i = 0; i<lines.length; i++)
                // ctx.fillText(lines[i], x - 20, y,r - 20 );
                // ctx.fillText(lines[i], x - r + 10, y, 2 * r - 20 );
                // ctx.fillText(lines[i], x - 30, y - 45, r - 20 );
        };
        return {
            drawNode,
            nodeDimensions: { width: 2 * r, height: 2 * r },
        };
    }

    getSelectedNodeEdgeDetails = (nodes, edges) => {
        if(nodes.length  || edges.length) {
            let assetName = []
            if(this.state.allGraphData && this.state.allGraphData && Object.entries(this.state.allGraphData.nodes).length) {

                if(!nodes.length && edges.length) {
                    this.state.allGraphData.edges.forEach(edg => {
                        if(edges.includes(edg.id)) {
                            nodes.push(edg.from)
                            nodes.push(edg.to)
                        }
                    })
                }
                this.state.allGraphData.nodes.forEach(nod => {
                    if(nodes.includes(nod.id)) {
                        assetName.push(nod.name)
                    }
                })
            }

            let obj ={}
            obj.selected_node = assetName
            obj.selected_edge = edges
            this.props.setAiopsPropsDetails('topology_selected_node_edges', obj)
        }
    }
        
	render() {
		return (
            <div className="container-fluid overflow-auto flex-grow-1 bg-muted" onClick={ (event) => { this.handleClickOutside(event) } }>
                <div className={`loaderOverlay ${this.state.showLoading ? "" : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
                <div className='row h-100'>
                    <div className='col-sm-12 p-0'>
                        <div className="title-section p-2">
							<div className="d-flex">
                                <div className='col-4 pl-2 align-self-center'>
                                    <div className='d-flex'>
                                        <h6 className="text-white m-0">Topology</h6>
                                    </div>
                                    <p className="text-white m-0">Unified view of assets</p>
								</div>
                                <div className={`col-8 pl-0 align-self-center`}>
                                    <div className={`col-md-12 d-flex flex-wrap justify-content-end bd-highlight`}>
                                        <div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: '150px' }}>
                                            <label className="mb-1">Provider</label>
                                            <Select
                                                placeholder={'Select'}
                                                isSearchable={false}
                                                // menuIsOpen={true}
                                                className='f13 p-0 colorBlack reactSelectFilterDrop'
                                                value={({
                                                    value: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : 'Select',
                                                    label: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : <span className="placeholder">Select</span>
                                                })}
                                                options={this.state.providers && this.state.providers.map(item => ({
                                                    value: item.provider_name,
                                                    label: item.provider_name,	
                                                }))}
                                                onChange={event => this.setState({
                                                    selectedProvider: event.value,
                                                    changesInFilter: true,
                                                })}
                                            />
                                        </div>
                                        <div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} ref={this.accountRef} style={{maxWidth: '150px' }}>
                                            <label className="mb-1">Account</label>
                                            <div className='dlpHeaderMultiSelection removeDropdownSearchBar'>
                                                <ReactMultiSelectCheckboxes						
                                                    placeholderButtonLabel="All"
                                                    menuIsOpen ={this.state.isAccountOpen}
                                                    getDropdownButtonLabel={() => this.getMultiSelectedCount('account', this.state.selectedAccount)}
                                                    onChange={arr => {
                                                    this.handleMultiSelectChange('selectedAccount', arr ? arr : []) }}
                                                    options={this.state.accounts && this.state.accounts.map(account => ({
                                                        value: account.account_id,
                                                        label: account.account_name,
                                                    }))}
                                                    value={this.state.selectedAccount.map(comp => ({
                                                        value: comp,
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                        <div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} ref={this.regionRef} style={{maxWidth: '150px' }}>
                                            <label className="mb-1">Region</label>
                                            <div className='dlpHeaderMultiSelection removeDropdownSearchBar'>
                                                <ReactMultiSelectCheckboxes
                                                    placeholderButtonLabel="Select"
                                                    menuIsOpen ={this.state.isRegionOpen}
                                                    getDropdownButtonLabel={() => this.getMultiSelectedCount('region', this.state.selectedRegion)}
                                                    onChange={arr => this.handleMultiSelectChange('selectedRegion', arr ? arr : []) }
                                                    options={this.state.regions && this.state.regions.map(reg => ({
                                                        value: reg.region,
                                                        label: reg.name,
                                                    }))}
                                                    value={this.state.selectedRegion.map(comp => ({
                                                        value: comp,
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                        <div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zindex1`} ref={this.daterRangeRef} style={{maxWidth: '150px'}}>
                                            <label className="mb-1">Duration</label>
                                            <Select
                                                placeholder={'Select'}
                                                isSearchable={false}
                                                // menuIsOpen={true}
                                                className='f13 p-0 colorBlack reactSelectFilterDrop'
                                                value={({
                                                    value: this.state.selectedDuration && this.state.selectedDuration !== "" ? this.state.selectedDuration : 'Select',
                                                    label: this.state.selectedDuration && this.state.selectedDuration !== "" ? durationOptions.filter(e => e.value === this.state.selectedDuration)[0].option : <span className="placeholder">Select</span>
                                                })}
                                                options={durationOptions && durationOptions.map(item => ({
                                                    value: item.value,
                                                    label: item.option,	
                                                }))}
                                                onChange={event => {
                                                    if(event.value !== 'custom') {
                                                        this.setState(
                                                            {
                                                                selectedDuration: event.value,
                                                                showDateRangePicker: false,
                                                            },
                                                            () => {
                                                                this.durationFunction("onChange")
                                                            }
                                                        )
                                                    } else {
                                                        this.setState({ showDateRangePicker: true, selectedDuration: event.value })
                                                    }
                                                }}
                                            />
                                            {this.state.showDateRangePicker ?
                                                <div className="obsDateTimePicker text-black5 pt-2 ml-n1" style={{"z-index": "999999999","position": "absolute","right":0}}>
                                                    <RangePicker
                                                        // locale={`en-us`} // default is en-us
                                                        show={true} // default is false
                                                        disabled={false}
                                                        placeholder={["Start Time", "End Time"]}
                                                        // defaultDates={[
                                                        //     new Date().getFullYear() + '-' + new Date().getMonth() + 1 + '-' + new Date().getDate(), 
                                                        //     new Date().getFullYear() + '-' + new Date().getMonth() + 1 + '-' + new Date().getDate()
                                                        // ]}
                                                        // defaultDates={[
                                                        //     momentDateGivenFormat(new Date(), "YYYY-MM-DD"),
                                                        //     momentDateGivenFormat(new Date(), "YYYY-MM-DD"),
                                                        // ]}
                                                        // defaultDates={[
                                                        // year + "-" + month + "-" + date,
                                                        // year + "-" + month + "-" + date
                                                        // ]} // ['YYYY-MM-DD', 'YYYY-MM-DD']
                                                        // defaultTimes={[hour + ":" + minute, hour + ":" + minute]} // ['hh:mm', 'hh:mm']
                                                        // initialDates={[
                                                        // year + "-" + month + "-" + date,
                                                        // year + "-" + month + "-" + date
                                                        // ]} // ['YYYY-MM-DD', 'YYYY-MM-DD']
                                                        // initialTimes={[new Date().getHours()-1 + ":00", new Date().getHours() + ":00"]} // ['hh:mm', 'hh:mm']
                                                        defaultTimes={["00:00", "23:59"]} // ['hh:mm', 'hh:mm']
                                                        onConfirm={(res) => this.handleSelectNew(res)}
                                                        onClose={() => console.log("onClose")}
                                                        onClear={() => console.log("onClear")}
                                                        style={{ width: "610px", margin: "0 auto" }}
                                                        // markedDates={[
                                                        // `${year}-${month.padStart(2, "0")}-${date.padStart(2, "0") - 1}`,
                                                        // `${year}-${month.padStart(2, "0")}-${date.padStart(2, "0")}`
                                                        // ]} // OPTIONAL. ['YYYY-MM-DD']
                                                    />
                                                </div>
                                            : null}
                                        </div>
                                        <div className={`ml-3 align-self-center`}>
                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                <button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-right px-2 py-1`} onClick={() => this.setState({ showLoading: false }, () => this.onSearch())}>Search</button>
                                                <button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-left px-2 py-1`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='p-2'>
                            <div className="d-flex">
                                <div className="w-82">
                                    <div className="rounded bg-dark p-3">
                                        <div className="d-flex"> {/* justify-content-between */}
                                            <div className={`multiselectDarkTheme ${this.state.categoryList && this.state.categoryList.length > 10 ? '' : 'removeDropdownSearchBar'}`} style={{minWidth: "200px"}} ref={this.nodeTypeRef}>
                                                <ReactMultiSelectCheckboxes						
                                                    placeholderButtonLabel="All"
                                                    getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedNodeTypes', this.state.selectedNodeTypes)}
                                                    // menuIsOpen ={this.state.isnodeTypeOpen}
                                                    value={this.state.selectedNodeTypes.map(item => ({
                                                        value: item,
                                                        label: item
                                                    }))}
                                                    onChange={arr => {this.handleMultiSelectChange('selectedNodeTypes', arr ? arr : []) }}
                                                    options={this.state.categoryList.map(itm => ({
                                                        value: itm.category,
                                                        label: itm.category,
                                                    }))}
                                                />
                                            </div>
                                            <div className="form-check mr-3 align-self-center displayNone">
                                                <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                                <input 
                                                    type="checkbox" 
                                                    className="form-check-input" 
                                                    id="assetsInfoCheckbox" 
                                                    checked={this.state.showAssetDetail}
                                                    onChange={e => this.setState({ showAssetDetail: this.state.showAssetDetail ? false : true })}
                                                />
                                                <label className="form-check-label" for="assetsInfoCheckbox">Show Asset Info</label>
                                            </div>
                                            <div className="form-check mr-3 align-self-center displayNone">
                                                <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                                <input 
                                                    type="checkbox" 
                                                    className="form-check-input" 
                                                    id="directedCheckbox" 
                                                    checked={this.state.directedGraph}
                                                    onChange={e => this.setState({ directedGraph: this.state.directedGraph ? false : true },
                                                        () => this.changeGraphOption('directed')
                                                    )}
                                                />
                                                <label className="form-check-label" for="directedCheckbox">Directed</label>
                                            </div>
                                            <div className="ml-3 d-flex justify-content-end align-self-center">
                                                {/* <div className="rounded bg-secondary text-white py-1 px-2 mr-2">
                                                    Node
                                                    <span className="ml-2 fa fa-plus-square cursorPointer" onClick={() => this.addRemoveNodes('add')}></span>
                                                    <span className="ml-2 fa fa-minus-square cursorPointer" onClick={() => this.addRemoveNodes('remove')}></span>
                                                </div> */}
                                                <div className="rounded bg-secondary text-white px-2 py-1">
                                                    <span onClick={this.resetGraph} className="fa fa-redo-alt border-right cursorPointer"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="topologyFullpageGraph overflowYAuto" style={{minHeight: this.state.minMaxHeightLeft, maxHeight: this.state.minMaxHeightLeft}}>
                                            <div id="graphLoadingBar">
                                                <div>LOADING</div>
                                                <div id="loadingBar">
                                                    <div className="outerBorder">
                                                        <div id="text">0%</div>
                                                        <div id="border" />
                                                        <div id="bar" />
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.filteredGraphData && this.state.filteredGraphData && Object.entries(this.state.filteredGraphData.nodes).length ? 
                                                // <div id="grap overflowXAuto" style={{minWidth: "2500px"}}>
                                                <div id="graph">
                                                    <Graph
                                                        ref={this.graphRef}
                                                        graph={this.state.filteredGraphData}
                                                        options={this.state.options}
                                                        events={this.state.events}
                                                    />
                                                </div>
                                            : null }
                                        </div> 
                                        <div className="mt-2 d-flex justify-content-end displayNone">
                                            <div className="rounded bg-secondary text-white py-1 px-2 mr-2">
                                                Node
                                                <span className="ml-2 fa fa-plus-square cursorPointer" onClick={() => this.addRemoveNodes('add')}></span>
                                                <span className="ml-2 fa fa-minus-square cursorPointer" onClick={() => this.addRemoveNodes('remove')}></span>
                                            </div>
                                            <div className="rounded bg-secondary text-white px-2 py-1">
                                                <span onClick={this.resetGraph} className="fa fa-redo-alt border-right pr-2 cursorPointer"></span>
                                                <span onClick={this.onClickZoomIn}  className="fa fa-search-plus border-right px-2 cursorPointer"></span>
                                                <span onClick={this.onClickZoomOut}  className="fa fa-search-minus border-right px-2 cursorPointer"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-18 pl-2">
                                    <div className="rounded bg-dark p-3" style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
                                        <ApexBarProgressChart
                                            graphData={this.state.progressData}
                                            sparkline={true}
                                            horizontal={true}
                                            yaxis={false}
                                            yaxisLabel={false}
                                            xaxis={true}
                                            xaxisFormat={'string'}
                                            xaxisLabel={true}
                                            axisLabelColor={'#B8BBBE'}
                                            // paddingLeft={-25}
                                            legend={false}
                                            stacked={false}
                                            height={70}
                                            barHeight={'20%'}
                                            barEndShape={'rounded'}
                                            columnWidth={'25%'}
                                            gradient={true}
                                            backgroundBarColors={["#40475D"]}
                                            titleColor={"#B8BBBE"}
                                            className={"transparentTooltip"}
                                        />
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                   
            </div>
		)
	}
}
/**
 * Type of the props used in the component
 */
Topology.propTypes = {
    getTopology: PropTypes.func,
    setAiopsPropsDetails: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
	// console.log('state', state)
    return {
        providers: state.filters.providers,
        accounts: state.filters.accounts,
		regions: state.filters.regions,
        services: state.filters.services,
    }
}

export default AppWrapper(Topology, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
    listAllServices,
    getCoveredServices,
    getTopology,
    setAiopsPropsDetails,
})