/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file compilanceDasboard.js
 * @author Prakash // on 5/06/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { AppWrapper } from '../../common/AppWrapper'
import Chart from 'react-apexcharts'
import { currentLocaltime, previousDay, subDays, momentDateGivenFormat, momentConvertionLocalToUtcTime, getPastDaysNew } from '../../../utils/utility'
import { 
    listAllProviders, 
    listAllAccounts, 
    listAllRegions
} from '../../../actions/commonAction'

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";

import StaticSunburst from '../../common/charts/sunburst/StaticSunburst'
import ApexBarChart from '../../common/charts/ApexBarChart'
import HorizontalLimitLollipopChart from '../../common/charts/HorizontalLimitLollipopChart'

class ProfileDashboard extends Component {
    accountRef = React.createRef()
    regionRef = React.createRef()
    serviceRef = React.createRef()

    staticSunburstRef = React.createRef()
    horizontalLimitLollipopRef = React.createRef()

    constructor(props) {
        super(props)
        this.state = {
            showLoading: false,
            
            providers: [],
            selectedProvider: '',
            accounts: [],
            accountArray: [],
            selectedAccount: [],
            selectedAccountName: [],
            isAccountOpen: false,

            services: [],
            parentServices: [],
            selectedService: [],

            regions: [],
            selectedRegion: [],
            selectedRegionName: [],
            isRegionOpen: false,
            selectedFilterPeriod: 90,
            
            selectedCompliance: 'dc v1.0',
        }

        this.handleStartTimeChange  = this.handleStartTimeChange.bind(this)
        this.handleEndTimeChange    = this.handleEndTimeChange.bind(this)
    }
    
    componentDidMount = () => {	
        this.setState({ showLoading: true })
        this.props.listAllProviders((promise, providers) => {
            if (promise) {
                this.setState({ providers, selectedProvider: providers[0].provider_name.toLowerCase(), showLoading: true },
                    () => {
                        this.filterDlp('provider')
                        this.sliderPeriod()	
                    }
                )
            } else {
                this.setState(
                    { providers: [],  }, 
                    () => {
                        this.filterDlp()
                        this.sliderPeriod()	
                    }
                )
            }
        })
    }

    filterDlp = from => {
        this.setState({ showLoading: true })
        let params = {}

        if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'all') {
            params.provider = this.state.selectedProvider.toLowerCase()
        }

        if (from === 'provider') {
            this.props.listAllAccounts(params, (promise, accounts) => {
                if (promise) {
                    this.setState(
                        { accounts: accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase()) })
                } else {
                    this.setState({ accounts: [] })
                }
            })

            this.props.listAllRegions(params, (promise, regions) => {
                if (promise) {
                    let dataRow = {}
                    dataRow.region = 'All'
                    dataRow.name = 'All'
                    regions[0] && regions[0].provider_regions.unshift(dataRow);
                    this.setState({ regions:regions[0] && regions[0].provider_regions })
                } else {
                    this.setState({ regions: [] })
                }
            })
        }
    }

    onSearch = () => {
        let changesInFilter = this.state.changesInFilter;
        if(!this.state.previousSliderStartDate || (this.state.previousSliderStartDate !== this.state.sliderStartDate || this.state.previousSliderEndDate !== this.state.sliderEndDate)) {
            changesInFilter = true
            this.setState({ previousSliderStartDate: this.state.sliderStartDate, previousSliderEndDate: this.state.sliderEndDate })
        }
        if(changesInFilter) {
            
            this.setState({
                start_time: momentConvertionLocalToUtcTime(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:ss'),
                end_time: momentConvertionLocalToUtcTime(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:ss'),
				showLoading: true,
            })
        }

        this.setState({ changesInFilter: false })
    }	

    onReset = () => {
        this.setState({
            selectedProvider: this.state.providers && this.state.providers.length ? this.state.providers[0].provider_name : '',
            selectedRegion: [],
            selectedRegionName: [],
            selectedAccount: [],
            sliderStartDate: this.state.initialStartDate,
            sliderEndDate: this.state.initialEndDate,
            changesInFilter: true
        },
            () => this.onSearch()
        )
    }

	sliderPeriod = () => {
		let sliderEndDate = momentDateGivenFormat(currentLocaltime(), 'YYYY-MM-DD 23:59:59')
		let brushInterval = 0
		let fullBar = 0
		let smallBar = 0
		let filterMonth = 0
		if(this.state.selectedFilterPeriod === 7) {
			fullBar = 10
			brushInterval = 7
			filterMonth = 90
		} else if(this.state.selectedFilterPeriod === 30) {
			fullBar = 5
			brushInterval = 30
			filterMonth = 180
		} else if(this.state.selectedFilterPeriod === 90) {
			fullBar = 10
			brushInterval = 90
			filterMonth = 365
		}
		let sliderStartDate = subDays(sliderEndDate, (brushInterval-1))
		let dateArray = []
		// let month = ''

		let y = 1
		let z = 1
		for(let x=1;x<=filterMonth;x++) {
			
			let date = subDays(sliderEndDate, (filterMonth-x))

			let value = smallBar ? 0 : 5
			if(smallBar === z) {
				value = 5
				z = 1
			}
			z++
			if(y === fullBar || y === 1) {
				value = 10
				y = 1
			}
			y++;
			if(new Date(date).getTime() <= new Date(sliderEndDate).getTime()) {
				dateArray.push([date, value])
			}
		}
		sliderStartDate = momentDateGivenFormat(sliderStartDate, 'YYYY-MM-DD 00:00:00')
		sliderEndDate = momentDateGivenFormat(sliderEndDate, 'YYYY-MM-DD 23:59:59')

		this.setState({ dateArray, brushSliderSeries: [], sliderStartDate, sliderEndDate, initialStartDate: sliderStartDate, initialEndDate: sliderEndDate },
			() => {
				this.brushSliderChart(dateArray, sliderStartDate, sliderEndDate)
				this.onSearch()
			}
		)
	}
	
	brushSliderChart = (dateArray, sliderStartDate, sliderEndDate) => {
		if(dateArray.length) {
			// let labels = []
			// let dataOneLength = dateArray.length
			// let lastDate = dateArray[dataOneLength-1][0]
			let brushSliderSeries = [{
				data: dateArray
			}]
			let dataArray = []
			dateArray.forEach(item => {
				dataArray.push(item[0])
			})

			let columnWidth = '5%'
			let brushSliderOptions = {
				plotOptions: {
					bar: {
						barHeight: '100%',
						columnWidth: columnWidth,
					},          
				},
				chart: {
					redrawOnParentResize: true,
					id: 'chart1',
					height: 130,
					type: 'bar',
					brush:{
						target: 'none',
						enabled: true,
					},
					zoom: {
						enabled: false,
					},
					selection: {
						enabled: true,
						fill: {
							color: "#039BE5",
							opacity: 1,
							// opacity: 0.6
						},  
						stroke: {
							show: false,
							curve: 'straight',
							lineCap: 'round',
							// colors: '#00A3CB',
							colors: '#039BE5',
							width: 0,
							dashArray: 0,      
						},
						xaxis: {
							min: new Date(sliderStartDate).getTime(),
							max: new Date(sliderEndDate).getTime(),
						},
						style: {
							fontSize: '28px',
							color: '#000000'
						}
					},
					events: {
						selection: (chartContext, { xaxis, yaxis }) => {
							// var maxDate = new Date(xaxis.max);
							// var maxDate = addDays(new Date(xaxis.min), (this.state.selectedFilterPeriod - 1))

							var minDate = momentDateGivenFormat(new Date(xaxis.min), 'YYYY-MM-DD 00:00:00')
							var maxDate = subDays(momentDateGivenFormat(new Date(xaxis.max), 'YYYY-MM-DD 23:59:59'), 1)
							let startSlice = momentDateGivenFormat(minDate, 'YYYY-MM-DD 00:00:00');
							let endSlice = momentDateGivenFormat(maxDate, 'YYYY-MM-DD 23:59:59');

							this.setState({ sliderStartDate: startSlice, sliderEndDate: endSlice })
							this.getAttribute()
						},
						click: (event, chartContext, config) => {
							this.onSearch()
						}
					}
				},
				colors: ['#515151'],
				xaxis: {
					tickAmount: 8,
					type: 'datetime',
					tooltip: {
						enabled: false
					},
					position: 'top',
					labels: {
						show: true,
						datetimeUTC: false,
						style: {
							colors: '#E0E0E0'
						},
						formatter: function(val) {
							return momentDateGivenFormat(new Date(val), "DD MMM");
						}
					},
					
				},
				yaxis: {
					show: false,
					tickAmount: 2,
					labels: {
						show: false
					},
				},
				grid: {
					xaxis: {
						lines: {
							show: false
						},
					},
					yaxis: {
						lines: {
							show: false
						}
					},
				}
			}
		
			this.setState({ datePickerSliderSeries: brushSliderSeries, datePickerSliderOptions: brushSliderOptions })
		}
	}
	
	getAttribute = () => {
		var attributes = document.getElementsByClassName("apexcharts-selection-rect") && document.getElementsByClassName("apexcharts-selection-rect")[0] &&document.getElementsByClassName("apexcharts-selection-rect")[0].attributes;
		if(attributes) {
			let width = attributes.width.value
			let x1 = parseInt(attributes.x.value) + parseInt(20)
			let x2 = parseInt(attributes.x.value) + parseInt(width) + parseInt(10)
			var d1 = document.getElementById('leftSliderSpan');
			d1.style.position = "absolute";
			d1.style.left = x1+'px';
			d1.style.top = '125px';
			d1.style.color = '#039BE5';

			var d2 = document.getElementById('rightSliderSpan');
			d2.style.position = "absolute";
			d2.style.left = x2+'px';
			d2.style.top = '125px';
			d2.style.color = '#039BE5';
		}
	}

	handleStartTimeChange(date) {
		let sliderStartDate = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
		let sliderEndDate = this.state.sliderEndDate
		if(new Date(sliderStartDate).getTime() > new Date(sliderEndDate).getTime() ) {
			sliderEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')
		}
		// this.setState({ start_time: sliderStartDate, end_time: sliderEndDate, changesInFilter: true, changesInDatePicker: true })
		this.setState({ sliderStartDate, sliderEndDate, changesInFilter: true })
	}

	handleEndTimeChange(date) {
		let sliderStartDate = this.state.sliderStartDate
		let sliderEndDate = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
		if(new Date(sliderEndDate).getTime() > new Date(sliderStartDate).getTime() ) {
			this.setState({ sliderEndDate, changesInFilter: true })
		}
	}

    handleMultiSelectChange = (childKey, arrayValue) => {
        this.setState({ changesInFilter: true})
        let value = arrayValue.map(item => item.value)
        let label = arrayValue.map(item => item)		
        if(childKey === 'selectedAccount') {
            this.setState({ selectedAccount: value, selectedAccountName: label })
        } else if(childKey === 'selectedRegion') {
            if(value !== '' && (value.includes("All") || this.state.selectedRegion.includes("All"))) {
                value = ['All']
                label = {}
                label.value = 'All'
                label.label = 'All'
                this.setState({ selectedRegion: ['All'], selectedRegionName: [label] })
            } else {
                this.setState({ selectedRegion: value, selectedRegionName: label})
            }
        }
    }
        
    handleClickOutside(event) {
        
        if (this.accountRef && !this.accountRef.current.contains(event.target)) {
            this.setState({ isAccountOpen: false })
        } else {
            this.setState({ isAccountOpen: true })
        }

        if (this.regionRef && !this.regionRef.current.contains(event.target)) {
            this.setState({ isRegionOpen: false })
        } else {
            this.setState({ isRegionOpen: true })
        }
    }

    render() {
        return (
            <div className='container-fluid overflow-auto flex-grow-1' onClick={ (event) => { this.handleClickOutside(event) } }>  
                <div className='row h-100'>
                    <div className='col-sm-12 p-0'>
                        <div className='title-section displayNone' style={{padding: "1rem 1rem 1rem 1rem"}} >
							<div className='row'>
								<div className='col-lg-3 align-self-center'>
									<h6 className='text-white m-0'>DLP Trend</h6>
									<p className='text-white mb-2 '>Count board view of DLP trend on your Cloud</p>
								</div>
								<div className={`col-lg-9`}>
									<div className="row">
										<div className="col-sm-6">
											<div className='d-flex'>
												<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} ref={this.providerRef} style={{maxWidth: '150px' }}>
													<label className="mb-1">Provider</label>
													<Select
														placeholder={'All'}
														menuIsOpen={this.state.providerIsOpen}
														isSearchable={false}
														//onFocus={this.openProivderMenu}
														className='f13 p-0 colorBlack reactSelectFilterDrop'
														value={({
															value: this.state.selectedProvider,
															label: this.state.selectedProvider
														})}
														options={this.state.providers.map(item => ({
															value: item.provider_name,
															label: item.provider_name,	
														}))}
														onChange={event =>  
															this.setState(
																{
																	selectedProvider: event.value,
																	changesInFilter: true
																}
															)
														}
													/>
												</div>
												<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1 ${!this.state.providers.length}`} ref={this.accountRef} style={{maxWidth: '150px' }}>
													<label className="mb-1">Account</label>
													<div className='dlpHeaderMultiSelection removeDropdownSearchBar'>
														<ReactMultiSelectCheckboxes						
															placeholderButtonLabel="All"
															menuIsOpen ={this.state.isAccountOpen}
															value={this.state.selectedAccountName.map(user => ({
																value: user.value,
																label: user.label
															}))}
															onChange={arr => {
															this.handleMultiSelectChange('selectedAccount', arr ? arr : []) }}
															options={this.state.accounts.map(account => ({
																value: account.account_id,
																label: account.account_name,
															}))}
														/>
													</div>
												</div>
												<div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 ${!this.state.providers.length}`} ref={this.regionRef} style={{maxWidth: '150px'}}>
													<label className="mb-1">Region</label>
													<div className={`dlpHeaderMultiSelection ${this.state.regions && this.state.regions.length > 10 ? '' : 'removeDropdownSearchBar' } `}>
														<ReactMultiSelectCheckboxes						
															placeholderButtonLabel="Select"
															menuIsOpen ={this.state.isRegionOpen}
															value={this.state.selectedRegionName.map(user => ({
																value: user.value,
																label: user.label
															}))}
															onChange={arr => {
															this.handleMultiSelectChange('selectedRegion', arr ? arr : []) }}
															options={this.state.regions && this.state.regions.map(region => ({
																value: region.region,
																label: region.name,
															}))}
														/>
													</div>
												</div>
											</div>
										</div>										
										<div className="col-sm-6 align-self-end">
											<div className={`d-flex justify-content-end align-self-center`}>
												<div className={`d-flex justify-content-between align-self-end`}>
													{/* <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === 365 ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedFilterPeriod: 365 }, () => this.sliderPeriod())}>1 Year</span>
													<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === 180 ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedFilterPeriod: 180 }, () => this.sliderPeriod())}>6 Month</span> */}
												
													<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === 90 ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedFilterPeriod: 90 }, () => this.sliderPeriod())}>3 Month</span>
													<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === 30 ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedFilterPeriod: 30 }, () => this.sliderPeriod())}>1 Month</span>
													<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === 7 ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedFilterPeriod: 7 }, () => this.sliderPeriod())}>1 Week</span>
												</div>
												<div className={`bg-filterBlue text-white align-self-end p-1 f12 ml-2`}>
													{getPastDaysNew(this.state.sliderStartDate, this.state.sliderEndDate)}
												</div>
												<div className="align-self-center ml-3">
													<Datetime 
														value={momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM YYYY hh:mm A')}
														onChange={this.handleStartTimeChange}
														dateFormat={'DD MMM YYYY'}
														timeFormat={'hh:mm A'}
														inputProps={{ readOnly: true, disabled: this.state.showTrendLoading }}
														className="w-100 mr-1 dateSliderInputReszie"
														isValidDate={previousDay()}
                                                        closeOnSelect={true}
													/>
													<Datetime
														value={momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM YYYY hh:mm A')}
														onChange={this.handleEndTimeChange}
														dateFormat={'DD MMM YYYY'}
														timeFormat={'hh:mm A'}
														inputProps={{ readOnly: true, disabled: this.state.showTrendLoading }}
														className="w-100 dateSliderInputReszie"
														isValidDate={previousDay()}
                                                        closeOnSelect={true}
													/>
												</div>
												<div className={`ml-2 align-self-center`}>
													<div className="btn-group" role="group" aria-label="profile-dash">
														<button type="button" className={`cursorPointer btn btn-secondary border-right ${this.state.showTrendLoading ? 'disabled' : ''}`} onClick={()=> this.onSearch() }>Search</button>
														<button type="button" className={`cursorPointer btn btn-secondary border-left ${this.state.showTrendLoading ? 'disabled' : ''}`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
													</div>
													{/* <div className={`bg-filterBlue text-white align-self-center p-1 mt-2 f10 cursorPointer`}>
														{getPastDays(this.state.sliderStartDate, this.state.sliderEndDate)}
													</div> */}
												</div>	
											</div>
										</div>
									</div>
									{this.state.datePickerSliderOptions && this.state.datePickerSliderSeries ?
										<div className={`w-100 mb-n3 ${this.state.showTrendLoading ? 'disabled' : ''}`} >
											<Chart options={this.state.datePickerSliderOptions} series={this.state.datePickerSliderSeries} type="bar" height={80} />
										</div>
									: null}	
									<div className="d-flex">
										<div id="leftSliderSpan" className="f12">{momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM')}</div>
										<div id="rightSliderSpan" className="f12">{momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM')}</div>
									</div>						
								</div>
							</div>
						</div>
                        <div className='bashboard-bg'>
                            <div className="box shadow-none">
                                <div className="col-sm-12 row mt-2 border-bottom">
                                    <div className="row">
                                        <div className="col-sm-7 mt-4" ref={this.staticSunburstRef}>
                                            <StaticSunburst
                                                ID={'staticSunburst'}
                                                svgID={'svgStaticSunburst'}
                                                data={{}}
                                                width={800}
                                                colors={["#024d73", "#7c6561", "#da7847", "#6fb971", "#9e70cf", "#bbbbbb", "#7e24b3", "#9029cc", "#a22ee6", "#bc47ff"]}
                                            />
                                        </div>
                                        <div className={`col-sm-4`}>
                                                <p className="mb-1">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                <div className={`divTransition`}>
                                                    <table className={`table table-sm table-hover f12`}>
                                                        <tbody className="bg-white">
                                                            <tr>
                                                                <td>Data 1</td>
                                                                <td className="text-right"><span className="badge badge-primary ">300</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Data 2</td>
                                                                <td className="text-right"><span className="badge badge-primary">200</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Data 3</td>
                                                                <td className="text-right"><span className="badge badge-primary">1650</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Data 4</td>
                                                                <td className="text-right"><span className="badge badge-primary">70</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Data 5</td>
                                                                <td className="text-right"><span className="badge badge-primary">30</span></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="mt-n2"> 
                                                    <ApexBarChart
														graphData={'mockData'}
                                                        sparkline={false}
                                                        yaxis={false}
                                                        xaxis={true}
                                                        legend={false}
                                                        stacked={false}
														height={100}
														horizontal={false}
														barHeight={'40%'}
                                                    />
                                                </div>
                                                <HorizontalLimitLollipopChart
                                                    width={400}
                                                    height={200}
                                                    ID={'userLollipop'}
                                                    dummyData={true}
													showEndPointCircle={true}
													colors={['#A88CCC', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7']}
                                                />
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

/**
 * Type of the props used in the co mponent
 */
ProfileDashboard.propTypes = {
}


/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {}

export default AppWrapper(ProfileDashboard, mapStateToProps, {
    listAllProviders,
    listAllAccounts,
    listAllRegions,
})
            