/*************************************************
 * Tvastar
 * @exports
 * @file NetworkDetails.js
 * @author Rajasekar // on 20/02/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class NetworkDetails extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showInBound: true,
		}
	}
	render() {
		return (
			<div className="box p-3 h-100 bg-dark3">
				<p>
					<b className="text-white">Network Details</b>
				</p>
				<div className="d-flex">
					<div className="flex-grow-1">
						<p className="text-muted">
							VPC ID: <b className="text-white">{this.props.vpcId}</b>
						</p>
						<p className="text-muted">
							Security Group Details: <b className="text-white">{this.props.dbInstanceVpcSecurityGroups}</b>
						</p>
					</div>
					<div>
						<p className="text-muted">
							Subnet ID : <b className="text-white">{this.props.subnetIdentifier}</b>
						</p>
						<p className="text-muted">
							Network interface Id : <b className="text-white">{this.props.networkInterface}</b>
						</p>
					</div>
				</div>
				<ul className="nav nav-tabs border-bottom bd-highlight bg-dark3 cursorPointer shadow-none " id="myTab" role="tablist">
					<li className="nav-item" onClick={() => this.setState({ showInBound: true })}>
						<span className={`nav-link text-white ${this.state.showInBound ? "active" : ""}`}>Inbound</span>
					</li>
					<li className="nav-item" onClick={() => this.setState({ showInBound: false })}>
						<span className={`nav-link text-white ${!this.state.showInBound ? "active" : ""}`}>Outbound</span>
					</li>
				</ul>
				<div className="tab-content" id="myTabContent">
					<div className={`tab-pane fade ${this.state.showInBound ? "show active darkThemeTable" : ""}`}>
						<div className="table-responsive overflow-auto rounded">
							<table className="table table-striped table-sm">
								<thead className="thead">
									<tr className="bg-black text-white">
										{/* <th>Type</th> */}
										<th>Protocol</th>
										<th>Port Range</th>
										<th>Source</th>
										<th>Description</th>
									</tr>
								</thead>
								<tbody>
									{this.props.inboundRules && this.props.inboundRules.length ?
										this.props.inboundRules.map((item, i) => {
											return (
												<tr key={i}>
													{/* <td>{item.ipProtocol}</td> */}
													<td>{item.ipProtocol}</td>
													<td>
														{item.fromPort} - {item.toPort}
													</td>
													<td>
														{(item.ipv4Ranges && item.ipv4Ranges.length && item.ipv4Ranges[0].cidrIp) ||
															(item.userIdGroupPairs &&
																item.userIdGroupPairs.length &&
																item.userIdGroupPairs[0].groupId) ||
															(item.prefixListIds && item.prefixListIds.length && item.prefixListIds[0].prefixListId)}
													</td>
													<td>
														{(item.ipv4Ranges && item.ipv4Ranges.length && item.ipv4Ranges[0].description) ||
															(item.userIdGroupPairs &&
																item.userIdGroupPairs.length &&
																item.userIdGroupPairs[0].description) ||
															(item.prefixListIds && item.prefixListIds.length && item.prefixListIds[0].description)}
													</td>
												</tr>
											)
										})
									:
										<div className="d-flex justify-content-center m-4">
											<p>No Record Found.</p>
										</div>
									}
								</tbody>
							</table>
						</div>
					</div>
					<div className={`tab-pane fade ${!this.state.showInBound ? "show active darkThemeTable" : ""}`} id="profile">
						<table className="table table-striped">
							<thead className="thead">
								<tr className="bg-black text-white">
									{/* <th>Type</th> */}
									<th>Protocol</th>
									<th>Port Range</th>
									<th>Source</th>
									<th>Description</th>
								</tr>
							</thead>
							<tbody>
								{this.props.outboundRules &&this.props.outboundRules.length ?
									this.props.outboundRules.map((item, i) => {
										return (
											<tr key={i}>
												{/* <td>{item.ipProtocol}</td> */}
												<td>{item.ipv4Ranges[0].cidrIp}</td>
												<td>{item.ipProtocol}</td>
												<td>
													{(item.ipv4Ranges && item.ipv4Ranges.length && item.ipv4Ranges[0].cidrIp) ||
														(item.userIdGroupPairs &&
															item.userIdGroupPairs.length &&
															item.userIdGroupPairs[0].groupId) ||
														(item.prefixListIds && item.prefixListIds.length && item.prefixListIds[0].prefixListId)}
												</td>
												<td>
													{(item.ipv4Ranges && item.ipv4Ranges.length && item.ipv4Ranges[0].description) ||
														(item.userIdGroupPairs &&
															item.userIdGroupPairs.length &&
															item.userIdGroupPairs[0].description) ||
														(item.prefixListIds && item.prefixListIds.length && item.prefixListIds[0].description)}
												</td>
											</tr>
										)
									})
								:
									<div className="d-flex justify-content-center m-4">
										<p>No Record Found.</p>
									</div>
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		)
	}
}
/**
 * Type of the props used in the component
 */
NetworkDetails.propTypes = {
	vpcId: PropTypes.string,
	dbInstanceVpcSecurityGroups: PropTypes.string,
	subnetIdentifier: PropTypes.string,
	inboundRules: PropTypes.array,
	outboundRules: PropTypes.array,
	networkInterface: PropTypes.string,
}
export default NetworkDetails
