/*************************************************
 * Tvastar
 * @exports
 * @file ServiceDetails.js
 * @author Rajasekar // on 20/02/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class StateDetails extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	render() {
		return (
			<div className='box p-3 h-100 bg-dark3'>
				<p>
					<b className="text-white">State Details</b>
				</p>
				{this.props.status ? (
					<div className='d-flex mb-2 border-bottom'>
						<div className='py-2'>Instance State</div>
						<div className='flex-grow-1 text-right py-2 text-white'>{this.props.status}</div>
					</div>
				) : null}
				{this.props.instanceStatus ? (
					<div className='d-flex mb-2 border-bottom'>
						<div className='py-2'>Status Checks</div>
						<div className='flex-grow-1 text-right py-2 text-white'>{this.props.instanceStatus}</div>
					</div>
				) : null}
				{this.props.systemStatus ? (
					<div className='d-flex mb-2 border-bottom'>
						<div className='py-2'>System Status Checks</div>
						<div className='flex-grow-1 text-right py-2 text-white'>{this.props.systemStatus}</div>
					</div>
				) : null}
				{this.props.reasonMessage ? (
					<div className='d-flex mb-2 border-bottom'>
						<div className='py-2'>State Transition Reason</div>
						<div className='flex-grow-1 text-right py-2 text-white'>{this.props.reasonMessage}</div>
					</div>
				) : null}
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
StateDetails.propTypes = {
	reasonMessage: PropTypes.string,
	systemStatus: PropTypes.string,
	instanceStatus: PropTypes.string,
	status: PropTypes.string,
}
export default StateDetails
