/*************************************************
 * Tvastar
 * @exports
 * @file SidePanel.js
 * @author Prakash // on 28/06/2020
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { capitalizeFirstLetter } from '../../../utils/utility'
import Chart from 'react-apexcharts'


class ApexDonutChart extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    
    componentDidMount = () => {
        let overAll = 0
        let overAllLabel = 'Total'
        let graphData = this.props.graphData;
        let series = []
        let labels = []
		let graphHeading = ''
		let colors = this.props.colors
        if(graphData === 'mockData') {
            series = [30, 10, 4, 40]
            labels = ['Critical', 'High', 'Medium', 'Low']
        } else {
			graphData && Object.entries(graphData).length && Object.entries(graphData).forEach(([key, value]) => {
				if(key === 'label') {
					if(!colors || colors.length !== 2) {
						if(value.toLowerCase() === 'bucket') {
							colors = ['#24A597', '#393F4E']
						} else if(value.toLowerCase() === 'prefix') {
							colors = ['#775BA2', '#393F4E']
						} else if(value.toLowerCase() === 'files') {
							colors = ['#3F73AD', '#393F4E']
						} else if(value.toLowerCase() === 'occurrences') {
							colors = ['#88792E', '#393F4E']
						} else if(value.toLowerCase() === 'hits') {
							colors = ['#5F5BA2', '#393F4E']
						}
					}	
					graphHeading = capitalizeFirstLetter(value)
					overAllLabel = graphHeading
				} else if(key === 'total') {
					overAll = value
				} else if(key === 'items') {
					let totalCount = 0
					if(graphData.label.toLowerCase() === 'risks' || graphData.label.toLowerCase() === 'risk') {
						colors = ['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']
						let riskColors = []
						let result = value.filter(arr => arr.item_name.toLowerCase() === 'critical')
						if(result && result.length) {
							totalCount += result[0].item_count
							labels.push(capitalizeFirstLetter(result[0].item_name))
							series.push(result[0].item_count)
							riskColors.push(colors[0])
						}
						result = value.filter(arr => arr.item_name.toLowerCase() === 'high')
						if(result && result.length) {
							totalCount += result[0].item_count
							labels.push(capitalizeFirstLetter(result[0].item_name))
							series.push(result[0].item_count)
							riskColors.push(colors[1])
						}
						result = value.filter(arr => arr.item_name.toLowerCase() === 'medium')
						if(result && result.length) {
							totalCount += result[0].item_count
							labels.push(capitalizeFirstLetter(result[0].item_name))
							series.push(result[0].item_count)
							riskColors.push(colors[2])
						}
						result = value.filter(arr => arr.item_name.toLowerCase() === 'low')
						if(result && result.length) {
							totalCount += result[0].item_count
							labels.push(capitalizeFirstLetter(result[0].item_name))
							series.push(result[0].item_count)
							riskColors.push(colors[3])
						}
						colors = riskColors
					} else {
						if(value.length) {
							value.forEach(item => {
								totalCount += item.item_count
								series.push(item.item_count)
								labels.push(capitalizeFirstLetter(item.item_name))
							})
						} else {
							series = [100]
							colors = [this.props.emptyGraphColor ? this.props.emptyGraphColor : '#393F4E']
							labels = ['No Data']
						}
					}

					if(!graphData.total) {
						overAll = totalCount
					}
					
					if(!totalCount) {
						series = [100]
						colors = [this.props.emptyGraphColor ? this.props.emptyGraphColor : '#393F4E']
					}
				}
			})

			// if(series.length === 2 && colors.length > 2) {
			// 	colors = [colors[0], '#393F4E']
			// }
		}
		
		this.drawGraph(series, labels, colors, overAll, overAllLabel, graphHeading)
    }

    drawGraph = (series, labels, colors, overAll, overAllLabel, graphHeading) => {
		let total = {}
		total['show'] = this.props.showTotal
		total['label'] = labels[0] === 'No Data' ? overAllLabel : overAllLabel
		total['fontSize'] = '10px'
		total['color'] = this.props.totalColor ? this.props.totalColor : '#FFFFFF'
		total['formatter'] = function (w) {
		    return labels[0] === 'No Data' ? '' : overAll
		}

		let fillData = {}
		if(this.props.gradient) {
			fillData = {
				type: 'gradient',
			}
		}
	
		let options = {
			stroke: {
				width: 0
			},
			chart: {
				type: 'donut',
				sparkline: {
					enabled: true
				},
				zoom: {
					enabled: false,
				},
				// offsetX: -10
			},
			legend: {
				show: labels[0] === 'No Data' ? false : this.props.legend,
				fontSize: '12px',
				fontFamily: 'Muli, sans-serif',
				labels: {
					colors: '#A9A9A9',
					useSeriesColors: false
				},
				width: this.props.legendWidth ?  this.props.legendWidth : "",				
				floating: false,
				position: 'right',
				horizontalAlign: 'center',
				markers: {
					width: 10,
					height: 10,
					strokeWidth: 0,
					strokeColor: '#fff',
					fillColors: undefined,
					radius: 12,
					customHTML: undefined,
					onClick: undefined,
					offsetX: 0,
					offsetY: 0
				},
				formatter: function(seriesName, opts) {
					return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex]]
				},
				tooltipHoverFormatter: function(seriesName, opts) {
					console.log("seriesName", seriesName)
					console.log("opts.w.globals.series[opts.seriesIndex]", opts.w.globals.series[opts.seriesIndex])
					return seriesName + ' - <strong>' + opts.w.globals.series[opts.seriesIndex] + '</strong>'
				}
			},
			dataLabels: {
				enabled: false
			},
			fill: fillData,
			labels: labels,
			// colors: ['#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'],
			colors: colors,
			tooltip: {
				enabled: true,
				custom: function({ series, seriesIndex, w }) {
					let color = w.globals.colors[seriesIndex]
					if(color === '#393F4E' || color === '#2D323E') {
						color = '#EDEDED'
					}
					let val = ''
					if(labels[0] !== 'No Data') {
						val = series[seriesIndex]
					}
					return '<div class="arrow_box">' +
                      '<span class="mr-2">' +  w.globals.labels[seriesIndex] + '</span>' +
                      '<span style="color:'+ color +'">' + val  + '</span>' +
                      '</div>'
				},
				// fixed: {
				// 	enabled: true,
				// 	position: 'topRight',
				// 	offsetX: 0,
				// 	offsetY: 0,
				// }
			},
			plotOptions: {
				offsetX: 10,
    			offsetY: 0,
				pie: {
					donut: {
						size: this.props.size,
						labels: {
							show: this.props.showTotalLable,
							value: {
								fontSize: '10px',
								fontWeight: 700,
								color: '#fff',
								offsetY: 0
							},
							name: {
								fontSize: '10px',
								color: '#fff',
								offsetY: -2
							},	
							total: total
						}
					},      
				}
			}
		}

        this.setState({ series, options, graphHeading })
    }

    render() {
        return(
            this.state.series ?
				<div className={`${this.props.className} apexCommonDonutChart`}>
					<p className={`font-weight-bold mb-1 ${this.props.graphHeadingPosition ? this.props.graphHeadingPosition : 'text-center'} ${this.props.showGraphHeading ? '' : 'displayNone'}`}>{this.state.graphHeading ? this.state.graphHeading : ''}</p>
					<Chart options={this.state.options} series={this.state.series} type="donut" height={this.props.height} width={this.props.width} />
				</div>
            : null
        )
    }
}
 
/**
 * Type of the props used in the component
 */
ApexDonutChart.propTypes = {}

const mapStateToProps = state => {}

export default connect(mapStateToProps, {})(withRouter(ApexDonutChart))