/*************************************************
 * Tvastar
 * @exports
 * @file BucketTableAction.js
 * @author Prakash // on 15/10/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'

import {
	DLPPOLICYENDPOINT,
	DLP_CONFIGURATION_GET_SOURCE_DATA_CATALOG_BASE_PARAMS,
	DLP_CONFIGURATION_LIST_SOURCE_DATA_CATALOG,
	DLP_CONFIGURATION_INSERT_SOURCE_DATA_CATALOG,
	DLP_CONFIGURATION_UPDATE_SOURCE_DATA_CATALOG,
	DLP_CONFIGURATION_DELETE_SOURCE_DATA_CATALOG
} from '../../config'
import { ACTION_TYPES } from '../types'

/**
 * Action to get source data catalog base params
 * @param {Object} body
 * @param {Function} callback
*/

export const getSourceDataCatalogBaseParams = (body, callback) => {
    return async dispatch => {
        try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_CONFIGURATION_GET_SOURCE_DATA_CATALOG_BASE_PARAMS, { body })
            callback(true, response)
        } catch (error) {
            callback(false, {})
        }
    }
}

/**
 * Action to list source data catalog
 * @param {Object} body
 * @param {Function} callback
 */

export const listSourceDataCatalog = (body, callback) => {
    return async dispatch => {
		try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_CONFIGURATION_LIST_SOURCE_DATA_CATALOG, { body })
			dispatch({
				type: ACTION_TYPES.SET_DLP_CONFIGURATION_LIST_SOURCE_DATA,
				payload: response,
			})
			callback(true, response)
		} catch (error) {
			dispatch({
				type: ACTION_TYPES.SET_DLP_CONFIGURATION_LIST_SOURCE_DATA,
				payload: [],
			})
			callback(false, {})
		}
	}
}

/**
 * Action to insert source data catalog
 * @param {Object} body
 * @param {Function} callback
 */

export const insertSourceDataCatalog = (body, callback) => {
    return async dispatch => {
		try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_CONFIGURATION_INSERT_SOURCE_DATA_CATALOG, { body })
			callback(true, response)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to Update cusotmer account info
 * @param {Object} body
 * @param {Function} callback
 */

export const updateSourceDataCatalog = (body, callback) => {
    return async dispatch => {
		try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_CONFIGURATION_UPDATE_SOURCE_DATA_CATALOG, { body })
			callback(true, response)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to Delete source data catalog
 * @param {Object} body
 * @param {Function} callback
 */

export const deleteSourceDataCatalog = (body, callback) => {
    return async dispatch => {
		try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_CONFIGURATION_DELETE_SOURCE_DATA_CATALOG, { body })
			callback(true, response)
		} catch (error) {
			callback(false)
		}
	}
}
