/*************************************************
 * Tvastar
 * @exports
 * @file CustomerOperationsAction.js
 * @author prakash // on 28/07/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
// import cognitoService from '../../services/cognitoService'
// import { myLog } from '../../utils/utility'
import { API } from 'aws-amplify'

import {
	CUSTOMEROPERATIONSENDPOINT,
	GET_JOB_TOTAL_COUNT,
	GET_JOB_DETAILS,
	GET_JOB_DETAILS_BY_MODULE,
	GET_JOB_DAILY_COUNT,
	GET_JOB_HISTOGRAM,
	GET_COMPLETE_JOB_DETAILS

} from '../config'

/**
 * Action to get-job-total-count
 * @param {Object} body
 * @param {Function} callback
*/
export const getJobTotalCount = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(CUSTOMEROPERATIONSENDPOINT, GET_JOB_TOTAL_COUNT, { body })
			callback(true, response)
		} catch (error) {
			callback(false, {})
		}
	}
}

/**
 * Action to get-job-details
 * @param {Object} body
 * @param {Function} callback
*/
export const getJobDetails = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(CUSTOMEROPERATIONSENDPOINT, GET_JOB_DETAILS, { body })
			callback(true, response)
		} catch (error) {
			callback(false, {})
		}
	}
}

/**
 * Action to get-job-details-by-module
 * @param {Object} body
 * @param {Function} callback
*/
export const getJobDetailsByModule = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(CUSTOMEROPERATIONSENDPOINT, GET_JOB_DETAILS_BY_MODULE, { body })
			callback(true, response)
		} catch (error) {
			callback(false, {})
		}
	}
}

/**
 * Action to get-job-daily-count
 * @param {Object} body
 * @param {Function} callback
*/
export const getJobDailyCount = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(CUSTOMEROPERATIONSENDPOINT, GET_JOB_DAILY_COUNT, { body })
			callback(true, response)
		} catch (error) {
			callback(false, {})
		}
	}
}

/**
 * Action to get-job-histogram
 * @param {Object} body
 * @param {Function} callback
*/
export const getJobHistogram = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(CUSTOMEROPERATIONSENDPOINT, GET_JOB_HISTOGRAM, { body })
			callback(true, response)
		} catch (error) {
			callback(false, {})
		}
	}
}

/**
 * Action to get-complete-job-details
 * @param {Object} body
 * @param {Function} callback
*/
export const getCompleteJobDetails = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(CUSTOMEROPERATIONSENDPOINT, GET_COMPLETE_JOB_DETAILS, { body })
			callback(true, response)
		} catch (error) {
			callback(false, {})
		}
	}
}