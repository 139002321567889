/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file RecommendationsRightSection.js
 * @author Prakash // on 05/02/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import { UncontrolledTooltip } from 'reactstrap'

import { momentConvertionUtcToLocalTime, getRegionName, getAccountNameFromId, momentDateGivenFormat, currentLocaltime, subDays, currentUTCtime } from '../../../utils/utility'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'
import { listAllAccounts, listAllRegions } from '../../../actions/commonAction'
import { setCostPropsDetails } from  '../../../actions/cost/CostAction'

import Search from '../../common/SearchComponent'

import PrimarySecondaryMetricsSection from './PrimarySecondaryMetricsSection'
import IdleTimeSection from './IdleTimeSection'

import Chart from 'react-apexcharts'

class RecommendationsRightSection extends Component {
	sliderWrap = React.createRef()

    constructor(props) {
        super(props)
        this.annotationTypeRef = React.createRef()
        // this.scrolltoTop = React.createRef()
        this.state = {
            minMaxHeight: "700px",
            accounts: [],
            regions: [],

            startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
			overAllTotalPages: 1,
			perPage: 10,
            series: [],
            options: {},
            showRecomDetails: true,

            totalItemShowing: 0,
			showingRightIcon: true,
			showingOffStart: 1,
			showingOff: 0,

            showCurrentFeatures: true,
            showRecommendedFeatures: true,
            showRecommendedObjectFeatures: true,
        }
    }

    componentDidMount = () => {
        this.getWindowSize()

        window.addEventListener('resize', () => {
            this.getWindowSize()
        })
    }
    

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 15  
		let outerHeight = (window.outerHeight - browserBorder - headerHeight) +"px"

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }

    componentDidUpdate = (prevProps) => {
        if(!_.isEqual(prevProps.selectedRecommendedEvent, this.props.selectedRecommendedEvent) && this.props.selectedRecommendedEvent && Object.entries(this.props.selectedRecommendedEvent).length) {
            // let mertcisAnnotationTypes = []
            this.setState({ 
                selectedRecommendedEventLabel: this.props.selectedRecommendedEvent.label,
                selectedRecommendedEventTag: this.props.selectedRecommendedEvent.eventGroup,
                selectedRecommendedEvent: this.props.selectedRecommendedEvent.data,
                // mertcisAnnotationTypes,
                showMeticsChart: false,
                gandalfBarData: {},
                showIdealCharts: false,
                showRecommendedFeaturesSection: true
            },
                () => {
                    if(this.state.selectedRecommendedEventLabel !== "assets_list") {
                        this.setState({ showMeticsChart: true })
                    } 
                    if(this.state.selectedRecommendedEventTag === "Schedule") {
                        this.setState({ showIdealCharts: true })
                    }

                    if(this.state.selectedRecommendedEvent && this.state.selectedRecommendedEvent.additional_details && this.state.selectedRecommendedEvent.additional_details.statistics) {
                        this.multiAxisCharts()
                    }

                    if(!this.state.selectedRecommendedEvent.additional_details || !this.state.selectedRecommendedEvent.additional_details.recommended_features) {

                        this.setState({ showRecommendedFeaturesSection: false })
                    } else {
                        // this.sliderWrap.current.scrollLeft = 0
                        this.setState({ 
                            // totalItemShowing: 0,
                            // showingOffStart: 1,
                            // showingOff: 0,
                        },
                            this.slideLeft()
                        )
                    }
                }
            )
        } else if(prevProps.selectedRecommendedEvent !== this.props.selectedRecommendedEvent && this.props.selectedRecommendedEvent && !Object.entries(this.props.selectedRecommendedEvent).length) {
            this.setState({ 
                selectedRecommendedEventLabel: '', 
                selectedRecommendedEvent: {}
            })
        }
        
        if(!_.isEqual(prevProps.selectedResourceRecommendedEvent, this.props.selectedResourceRecommendedEvent) && this.props.selectedResourceRecommendedEvent && Object.entries(this.props.selectedResourceRecommendedEvent).length) {
            this.setState({ 
                selectedResourceRecommendedEventLabel: "",
                selectedResourceTableData: this.props.selectedResourceRecommendedEvent.data,
                selectedRecommendedEventLabel: "",
                selectedRecommendedEvent: {},
                showIdealCharts: false,
                showMeticsChart: false,
                showRecommendedFeaturesSection: false
            },
                () => {
                    this.setState({
                        selectedResourceRecommendedEventLabel: this.props.selectedResourceRecommendedEvent.showBy,
                        showIdealCharts: false,
                        showMeticsChart: false,
                    },
                        () => {
                            this.onChangeRecommendationEvent(this.state.selectedResourceTableData[this.state.selectedResourceTableData.length-1].label, this.state.selectedResourceTableData[this.state.selectedResourceTableData.length-1].tag, this.state.selectedResourceTableData[this.state.selectedResourceTableData.length-1], 0, "", this.state.selectedResourceTableData[this.state.selectedResourceTableData.length-1].asset_id)
                        }
                    )
                }
            )
        } else if(prevProps.selectedResourceRecommendedEvent !== this.props.selectedResourceRecommendedEvent && this.props.selectedResourceRecommendedEvent && !Object.entries(this.props.selectedResourceRecommendedEvent).length) {
            this.setState({ 
                selectedResourceTableData: []
            })
        }
    }

    redirect = (url) => {
        window.open(url, '_blank');
    }
	
	slideLeft() {
		let move = 0
		move = parseInt(this.state.totalItemShowing)
		this.setState({ totalItemShowing: this.state.totalItemShowing - 1 })
        let totalRecommandations = this.state.selectedRecommendedEvent && this.state.selectedRecommendedEvent.additional_details && this.state.selectedRecommendedEvent.additional_details.recommended_features ? this.state.selectedRecommendedEvent.additional_details.recommended_features.length : 0
        
        if(totalRecommandations) {
            let reqTabWidth = 0
            for (let i = 0; i < totalRecommandations; i++) {
                reqTabWidth += this.sliderWrap.current && this.sliderWrap.current.childNodes[i].getBoundingClientRect().width
            }
            const averageSliderItemWidth = reqTabWidth/totalRecommandations
            
            const sliderWrapWidth = this.sliderWrap.current && this.sliderWrap.current.getBoundingClientRect() && this.sliderWrap.current.getBoundingClientRect().width
            const totalItemShowing = sliderWrapWidth/averageSliderItemWidth;
            if(move > totalItemShowing) {
                if (this.sliderWrap.current.childNodes[move-1] && this.sliderWrap.current.childNodes[move-1].getBoundingClientRect().width) {
                    this.sliderWrap.current.scrollLeft -= (this.sliderWrap.current.childNodes[move-1].getBoundingClientRect().width + 25)	
                    this.setState({ showingRightIcon: true, showingOff: parseInt(this.state.showingOff - 1), showingOffStart: this.state.showingOffStart < 1 ? 1 : parseInt(this.state.showingOffStart-1)})
                } else {
                    this.setState({ showingLeftIcon: false, totalItemShowing, allowedItems: totalItemShowing })
                }
            } else {
                this.setState({ showingLeftIcon: false, totalItemShowing, allowedItems: totalItemShowing })
            }
			if(!this.state.showingOff || this.state.showingOff === 0) {
                let allRecommendations = this.state.selectedRecommendedEvent && this.state.selectedRecommendedEvent.additional_details && this.state.selectedRecommendedEvent.additional_details.recommended_features ? this.state.selectedRecommendedEvent.additional_details.recommended_features.length : 0
				this.setState({ showingOff: allRecommendations < parseInt(totalItemShowing) ? allRecommendations : parseInt(totalItemShowing), showingLeftIcon: true })
			}
        }
	}

	slideRight(item) {
        let totalRecommandations = this.state.selectedRecommendedEvent && this.state.selectedRecommendedEvent.additional_details && this.state.selectedRecommendedEvent.additional_details.recommended_features ? this.state.selectedRecommendedEvent.additional_details.recommended_features.length : 0

		let reqTabWidth = 0
		for (let i = 0; i < totalRecommandations; i++) {
			reqTabWidth += this.sliderWrap.current.childNodes[i].getBoundingClientRect().width
        }
		const averageSliderItemWidth = reqTabWidth/totalRecommandations
		const sliderWrapWidth = this.sliderWrap.current.getBoundingClientRect().width
        let totalItemShowing = sliderWrapWidth/averageSliderItemWidth;
        if(totalItemShowing > this.state.selectedRecommendedEvent.additional_details.recommended_features.length) {
            totalItemShowing = this.state.selectedRecommendedEvent.additional_details.recommended_features.length
        }

		let move = 0
		if(this.state.totalItemShowing === 0) {
			this.setState({ totalItemShowing: parseInt(totalItemShowing) })
			move = parseInt(totalItemShowing)
		} else {
			move = parseInt(this.state.totalItemShowing + 1)
			this.setState({ totalItemShowing: this.state.totalItemShowing + 1 })
        }
        
		if (this.sliderWrap.current.childNodes[move-1] && this.sliderWrap.current.childNodes[move-1].getBoundingClientRect().width) {

            if(item === '') {
                this.sliderWrap.current.scrollLeft += (this.sliderWrap.current.childNodes[move-1].getBoundingClientRect().width + 25)
            }
            
			if(!this.state.showingOff && this.state.showingOff < totalItemShowing) {
				this.setState({ showingOff: parseInt(totalItemShowing), showingLeftIcon: true })
			} else{
				this.setState({ showingOffStart: (this.state.showingOffStart + 1), showingOff: parseInt(this.state.showingOff + 1), showingLeftIcon: true })
			}
		} else {
			this.setState({ showingRightIcon: false, showingOff: totalRecommandations, totalItemShowing: totalRecommandations })
		}
    }

    multiAxisCharts = () => {
        let series = []
        let options = {}
        if(this.state.selectedRecommendedEvent && this.state.selectedRecommendedEvent.additional_details && this.state.selectedRecommendedEvent.additional_details.statistics && this.state.selectedRecommendedEvent.additional_details.statistics.length) {
            let durationData = []
            let priceData = []
            let labels = []
            let memoryUnit = ""
            this.state.selectedRecommendedEvent.additional_details.statistics.forEach(row => {
                // console.log(parseFloat(row.price).toFixed(2))
                labels.push(row.memory)
                priceData.push(row.price)
                durationData.push(row.duration)
                memoryUnit = row.memory_unit
            })

            series = [{
                name: 'Invocation Time',
                type: 'line',
                data: durationData
            }, {
                name: 'Invocation Cost',
                type: 'line',
                data: priceData
            }]

            options = {
                chart: {
                    height: 350,
                    type: 'line',
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                    sparkline: {
                        enabled: false
                    },
                    animations: {
                        enabled: false
                    },

                },
                stroke: {
                    curve: 'smooth'
                },
                // fill: {
                //     type:'solid',
                //     opacity: [0.35, 1],
                // },
                labels: labels,
                markers: {
                    size: 2
                },
                xaxis: {
                    show: true,
                    tooltip: {
                        enabled: false
                    },
                    axisBorder: {
                        show: true,
                        color:'#434B5E'
                    },
                    axisTicks: {
                        show: false
                    },
                    labels: {
                        show: true,
                        style: {
                            colors: "#50586A",
                            fontSize: '13px',
                            fontWeight: 'bolder',
                        },
                    }
                },
                yaxis: [
                    {
                        title: {
                            text: 'Invocation Time', 
                            style: {
                                color: "#50586A",
                                fontWeight: 600,
                            },
                        },
                        labels: {
                            show: true,
                            style: {
                                colors: "#50586A",
                                fontSize: '13px',
                                fontWeight: 'bolder',
                            },
                        }
                    },
                    {
                        opposite: true,
                        title: {
                            text: 'Invocation Cost', 
                            style: {
                                color: "#50586A",
                                fontWeight: 600,
                            },
                        },
                        labels: {
                            show: true,
                            style: {
                                colors: "#50586A",
                                fontSize: '13px',
                                fontWeight: 'bolder',
                            },
                        }
                    },
                ],
                grid: {
                    show: false,			        
                    xaxis: {
                        lines: {
                            show: false
                        },
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: this.props.paddingLeft ? this.props.paddingLeft : 10
                    },
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    // y: {
                    //     formatter: function (y) {
                    //         if(typeof y !== "undefined") {
                    //             return  y.toFixed(0);
                    //         }
                            
                    //         return y;
                    //     }
                    // },
                    
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        let retrunData = '<div class="arrow_box">'
                        retrunData += '<div class="small"> <span class="text-gray3">Memory</span> : '+w.globals.categoryLabels[dataPointIndex]+'</div>'
                        w.globals.initialSeries.forEach(row => {
                            retrunData += '<div class="small"> <span class="text-gray3">'+row.name+'</span> : '+row.data[dataPointIndex]+'</div>'
                        })
                        retrunData += '</div>'
                        return retrunData

                    }
                },
                legend: {
                    show: true,
                    labels: {
                        colors: "#50586A",
                        useSeriesColors: false
                    }
                }
            }
        }

        this.setState({ multiAxisSeries: series, multiAxisOptions: options })
    }

    onChangeRecommendationEvent = (label, tag, data, index, selectedEvent, assetId) => {
        let assetDetails = {}

        if(data.asset_id && assetDetails.asset_id !== "") {
            if(!data.resource_type || data.resource_type === "") {
                data.resource_type = data.resource_type ? data.resource_type : ""
            }
            if(!data.service_name || data.service_name === "") {
                data.service_name = data.service_name ? data.service_name : ""
            }
            if(!data.provider || data.provider === "") {
                data.provider = data.provider ? data.provider : ""
            }
            if(!data.account_id || data.account_id === "") {
                data.account_id = data.account_id ? data.account_id : ""
            }
            if(!data.region || data.region === "") {
                data.region = data.region ? data.region : ""
            }
        }
        let obj = {}
        obj.label = label
        obj.eventGroup = tag
        obj.data = data
        obj.asset_details = assetDetails
        obj.showBy = this.state.showBy
        
        this.setState({ 
            selectedRecommandation: selectedEvent

        },
            () => this.props.setCostPropsDetails("selectedRecommendedEvent", obj)
        )
    }

	render() {
		return (
            <div className=''> {/* ref={this.scrolltoTop} */}
                <div className={`m-0 overflowYAuto`} style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
                    {this.state.selectedResourceRecommendedEventLabel === "Service" ?
                        this.state.selectedResourceTableData && this.state.selectedResourceTableData.length ?
                            <div className={`card bg-dark border-0 p-3 mb-3`}>
                                <ResizeableDarkThemeTable
                                    columns={[
                                        {
                                            Header: "Service",
                                            accessor: d => d.resource_type+" : "+d.service_name,
                                            width: 200
                                        },
                                        {
                                            Header: "Category",
                                            accessor: "category",
                                            width: 150
                                        },
                                        {
                                            Header: "Savings",
                                            // accessor: d => d.additional_details && d savings+" ("+d.price_unit && d.price_unit+"%)"+"  ("+d.percentage_savings && d.percentage_savings+"%)",
                                            Cell: cellInfo => (
                                                <div className='d-flex'>
                                                    <p className="f12 m-0 text-success align-self-center mr-1">{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.current_features && cellInfo.row.original.additional_details.current_features.savings_billing_prev_month ? cellInfo.row.original.additional_details.current_features.savings_billing_prev_month : ''}</p>
                                                    <p className="mb-0 text-primary-color f11 mr-1">{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.current_features && cellInfo.row.original.additional_details.current_features.price_unit ? cellInfo.row.original.additional_details.current_features.price_unit : ''}</p>
                                                    <p className="mb-0 text-primary-color f11">{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.current_features && cellInfo.row.original.additional_details.current_features.percentage_savings_billing_prev_month ? "("+cellInfo.row.original.additional_details.current_features.percentage_savings_billing_prev_month+"%)" : ''}</p>
                                                </div>
                                            ),
                                            width: 150
                                        },
                                        {
                                            Header: "Asset",
                                            accessor: "asset_name",
                                            width: 200
                                        },
                                        {
                                            Header: "Resources",
                                            Cell: cellInfo => (
                                                (cellInfo.row.original.provider ? cellInfo.row.original.provider.toUpperCase() : '') + ': ' + getAccountNameFromId(cellInfo.row.original.account_id, this.state.accounts) + ': ' + (cellInfo.row.original.region ? (cellInfo.row.original.region === 'NA' ? 'Global' : getRegionName(cellInfo.row.original.region, this.state.regions)) : '')
                                            ),
                                            width: 200
                                        },
                                        {
                                            Header: "Description",
                                            accessor: "description",
                                            width: 600
                                        }
                                    ]}
                                    data={this.state.selectedResourceTableData}
                                    perPage={20}
                                    dashboard={true}
                                    sortByColumn={"Date"}
                                    riskTooltip={[0]}
                                    onClickRow={tableRow => {
                                        this.onChangeRecommendationEvent(tableRow.label, tableRow.tag, tableRow, 0, "", tableRow.asset_id)
                                    }}
                                    // defaultSelectedRecord={""}
                                    // tableSize={"table-sm"}
                                    // tableHead={"bg-dark"}
                                    tooltipFor={[1,2]}
                                />
                            </div>
                        : null
                    : null}
                    {this.state.selectedRecommendedEvent && Object.entries(this.state.selectedRecommendedEvent).length ? 
                        <React.Fragment>                            
                            <div className={`card bg-dark border-0 p-3 mb-3`}>
                                <div className={`${this.state.showRecomDetails ? "mb-2" : ""} d-flex justify-content-between`} onClick={() => this.setState({ showRecomDetails: !this.state.showRecomDetails })}>
                                    <div className="text-white cursorPointer"><span className={`far ${this.state.showRecomDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Details</div>
                                    {/* <div className="text-info">Showing event details</div> */}
                                </div>
                                <div className={`collapse ${this.state.showRecomDetails ? 'show' : ''}`}>
                                    <div className="d-flex mb-1 border-bottom">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Category</p>
                                            <p className="mb-0 text-white">
                                                {this.state.selectedRecommendedEvent.category ? this.state.selectedRecommendedEvent.category : ""}
                                            </p>
                                        </div>
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Event Time</p>
                                            <p className="mb-0 text-white">{this.state.selectedRecommendedEvent.timestamp ? momentConvertionUtcToLocalTime(this.state.selectedRecommendedEvent.timestamp, 'DD MMM YYYY HH:mm:ss') : <span>&nbsp;</span>}</p>
                                        </div>
                                        {/* <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">Utilization</p>
                                            <p className="mb-0 text-white">{this.state.selectedRecommendedEvent.utilization ? this.state.selectedRecommendedEvent.utilization : ""}</p>
                                        </div> */}
                                    </div>
                                    {/* <div className="d-flex border-bottom">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Asset</p>
                                            <p className="mb-0 text-white">{this.state.selectedRecommendedEvent.asset_name ? this.state.selectedRecommendedEvent.asset_name : <span>&nbsp;</span>}</p>
                                        </div>
                                    </div> */}
                                    <div className="d-flex mb-1 border-bottom">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Provider / Account / Region</p>
                                            <p className="mb-0 text-white">
                                                {this.state.selectedRecommendedEvent.provider ? this.state.selectedRecommendedEvent.provider.toUpperCase() : <span>&nbsp;</span>}
                                                {this.state.selectedRecommendedEvent.account_id ? (' : ')+getAccountNameFromId(this.state.selectedRecommendedEvent.account_id, this.props.accounts) : (this.state.selectedDiagnosticRcaEvent && this.state.selectedDiagnosticRcaEvent.account_id ? this.state.selectedDiagnosticRcaEvent.account_id : <span>&nbsp;</span>)}
                                                {this.state.selectedRecommendedEvent.region ?  (' : ')+getRegionName(this.state.selectedRecommendedEvent.region, this.state.regions) : <span>&nbsp;</span>}
                                            </p>
                                        </div>                                    
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">Service</p>
                                            <p className="mb-0 text-white">
                                                {this.state.selectedRecommendedEvent.resource_type ? this.state.selectedRecommendedEvent.resource_type : <span>&nbsp;</span>}
                                                {this.state.selectedRecommendedEvent.service_name ?  (' : ')+this.state.selectedRecommendedEvent.service_name : <span>&nbsp;</span>}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-1 border-bottom">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Asset</p>
                                            {this.state.selectedRecommendedEvent.id_name_map && this.state.selectedRecommendedEvent.id_name_map !== "" ?
                                                <p className="mb-0 text-white">
                                                    <span className={`ml-1`}>{(this.state.selectedRecommendedEvent.id_name_map.value ? this.state.selectedRecommendedEvent.id_name_map.value : "")}</span>
                                                    {this.state.selectedRecommendedEvent.id_name_map.key && this.state.selectedRecommendedEvent.id_name_map.key !== "" ?
                                                        <span className={`ml-1'} mb-0`}>
                                                            {" : "+this.state.selectedRecommendedEvent.id_name_map.key}
                                                        </span>
                                                    : null}
                                                </p>
                                            : 
                                                <p className="mb-0 text-white">{this.state.selectedRecommendedEvent.asset_name ? this.state.selectedRecommendedEvent.asset_name : <span>&nbsp;</span>}</p>
                                            }
                                        </div>
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">Asset Time</p>
                                            <p className="mb-0 text-white">{this.state.selectedRecommendedEvent.event_time ? momentConvertionUtcToLocalTime(this.state.selectedRecommendedEvent.event_time, 'DD MMM YYYY HH:mm:ss') : <span>&nbsp;</span>}</p>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-1">
                                        <div className="py-1 w-100">
                                            <p className="b-block mb-0">Description</p>
                                            <p className="mb-0 text-white">
                                                {this.state.selectedRecommendedEvent.description ? this.state.selectedRecommendedEvent.description : <span>&nbsp;</span>}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.state.selectedRecommendedEvent.additional_details && this.state.selectedRecommendedEvent.additional_details.current_features ?
                                <div className={`card bg-dark border-0 p-3 mb-3`}>
                                    <div className={`${this.state.showCurrentFeatures ? "mb-2" : ""} d-flex`}>
                                        <span className={`far ${this.state.showCurrentFeatures ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}  onClick={() => this.setState({ showCurrentFeatures: !this.state.showCurrentFeatures })}></span>
                                        <div className="text-white cursorPointer" onClick={() => this.setState({ showCurrentFeatures: !this.state.showCurrentFeatures })}>Observations</div>                                        
                                        {/* <div className="text-info">Showing event details</div> */}
                                    </div>
                                    {this.state.selectedRecommendedEvent.observation ?
                                        <p className="mb-1 f12 text-primary-color">
                                            {this.state.selectedRecommendedEvent.observation}
                                        </p>
                                    : null}
                                    {this.state.showCurrentFeatures ? 
                                        <React.Fragment>

                                        {this.state.selectedRecommendedEventLabel === "Lambda Right Sizing" ?
                                            <div className="d-flex">
                                                <div class="mb-3 col-10 px-0">
                                                    <div class="rounded bg-dark3">
                                                        <div className="p-3">
                                                            <div className="d-flex mb-2">
                                                                <div className="py-1 w-50">
                                                                    <p className="b-block mb-0">Duration {this.state.selectedRecommendedEvent.additional_details.current_features.duration_unit ? " ("+this.state.selectedRecommendedEvent.additional_details.current_features.duration_unit+")" : ''}
                                                                    </p>
                                                                    <p className="mb-0 text-white">{this.state.selectedRecommendedEvent.additional_details.current_features.duration ? this.state.selectedRecommendedEvent.additional_details.current_features.duration : ''}</p>
                                                                </div>
                                                                <div className="py-1 w-50 pl-3">
                                                                    <p className="b-block mb-0">Memory {this.state.selectedRecommendedEvent.additional_details.current_features.memory_unit ? " ("+this.state.selectedRecommendedEvent.additional_details.current_features.memory_unit+")" : ""}:</p>
                                                                    <p className="mb-0 text-white">{this.state.selectedRecommendedEvent.additional_details.current_features.memory ? this.state.selectedRecommendedEvent.additional_details.current_features.memory : ''}</p>
                                                                </div>
                                                                {/* <div className="py-1 w-33 pl-3">
                                                                    <p className="b-block mb-0">Price {this.state.selectedRecommendedEvent.additional_details.current_features.price_unit ? "("+this.state.selectedRecommendedEvent.additional_details.current_features.price_unit+")" : ""}:</p>
                                                                    <p className="mb-0 text-white">
                                                                        {this.state.selectedRecommendedEvent.additional_details.current_features.price_per_month ? this.state.selectedRecommendedEvent.additional_details.current_features.price_per_month : ''}
                                                                        {this.state.selectedRecommendedEvent.additional_details.current_features.price ? " / "+this.state.selectedRecommendedEvent.additional_details.current_features.price : ''}
                                                                    </p>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                            
                                                <div className="my-3 col-2 justify-content-end pr-0">
                                                    <p className="mb-0 f16 text-success">Savings {this.state.selectedRecommendedEvent.additional_details.current_features.price_unit ? "("+this.state.selectedRecommendedEvent.additional_details.current_features.price_unit+")" : ""}:</p>
                                                    <p className="mb-0 mr-3 text-success f20">
                                                        {parseFloat(this.state.selectedRecommendedEvent.additional_details.current_features.savings).toFixed(2)}
                                                        {this.state.selectedRecommendedEvent.additional_details.current_features.savings ? 
                                                            this.state.selectedRecommendedEvent.additional_details.current_features.percentage_savings ?
                                                                <span className="text-primary-color f12 ml-1">({this.state.selectedRecommendedEvent.additional_details.current_features.percentage_savings}%)</span>
                                                            : null
                                                        : ""}
                                                    </p>
                                                    {/* <div className="flex-row ml-1 align-self-center">
                                                        <p className="mb-0 text-nowrap small">2032, 2031</p>
                                                        <p className="mb-0 text-nowrap small">2032, 2031</p>
                                                    </div> */}
                                                </div>
                                            </div>
                                        :
                                            <React.Fragment>
                                            
                                            <div className="d-flex">
                                                {/* <div class="borderMiddleBlue-lg"> </div>*/}
                                                <div className="featureBox shadow-none mr-2">
                                                    <p className="mb-1 text-info">Current Features</p>
                                                    <div class="px-3 rounded bg-dark3 border-info">
                                                        <div className="row p-3 sectionBox">
                                                            <div className={`w-100`}>
                                                                <div className="py-1">
                                                                    <p className="b-block mb-0">Instance Type: <span className="ml-2 mb-0 text-white">{this.state.selectedRecommendedEvent.additional_details.current_features.instance_type ? this.state.selectedRecommendedEvent.additional_details.current_features.instance_type : ''}</span></p>
                                                                </div>
                                                                {this.state.selectedRecommendedEvent.additional_details.current_features.cpu ?
                                                                    <div className="py-1">
                                                                        <p className="b-block mb-0">CPU:
                                                                        <span className="ml-2 mb-0 text-white">{this.state.selectedRecommendedEvent.additional_details.current_features.cpu}</span></p>
                                                                    </div>
                                                                : this.state.selectedRecommendedEvent.additional_details.current_features.duration ? 
                                                                    <div className="py-1">
                                                                        <p className="b-block mb-0">Duration {this.state.selectedRecommendedEvent.additional_details.current_features.duration_unit ? " ("+this.state.selectedRecommendedEvent.additional_details.current_features.duration_unit+")" : ''}:
                                                                        <span className="ml-2 mb-0 text-white"> {this.state.selectedRecommendedEvent.additional_details.current_features.duration}</span></p>
                                                                    </div>
                                                                : null}
                                                                <div className="py-1">
                                                                    <p className="b-block mb-0">Memory {this.state.selectedRecommendedEvent.additional_details.current_features.memory_unit ? " ("+this.state.selectedRecommendedEvent.additional_details.current_features.memory_unit+")" : ""}:
                                                                    <span className="ml-2 mb-0 text-white">{this.state.selectedRecommendedEvent.additional_details.current_features.memory ? this.state.selectedRecommendedEvent.additional_details.current_features.memory : ''}</span></p>
                                                                </div>
                                                                <div className="py-1">
                                                                    <p className="b-block mb-0">Network (gbps):
                                                                    <span className="ml-2 mb-0 text-white">{this.state.selectedRecommendedEvent.additional_details.current_features.network_in_gbps ? this.state.selectedRecommendedEvent.additional_details.current_features.network_in_gbps : ''}</span></p>
                                                                </div>
                                                                <div className='bg-dark2 p-3 rounded'>
                                                                    <p className="mb-1">Monthly {this.state.selectedRecommendedEvent.additional_details.current_features.price_unit ? "("+this.state.selectedRecommendedEvent.additional_details.current_features.price_unit+")" : ""}</p>
                                                                    <div className='d-flex'>
                                                                        <div className='w-45 pr-2'>
                                                                            <p className="b-block mb-0">Price</p>
                                                                            <p className="mb-0 text-warning">
                                                                            {this.state.selectedRecommendedEvent.additional_details.current_features.price_per_month ? this.state.selectedRecommendedEvent.additional_details.current_features.price_per_month : ""}
                                                                            </p>
                                                                        </div>
                                                                        <div className='w-55'>
                                                                            <p className="b-block mb-0">Savings</p>
                                                                            <p className="mb-0 text-success">
                                                                                {this.state.selectedRecommendedEvent.additional_details.current_features.savings ? this.state.selectedRecommendedEvent.additional_details.current_features.savings : ""}
                                                                                <span className="text-primary-color f12 ml-1">{this.state.selectedRecommendedEvent.additional_details.current_features.percentage_savings ? "("+this.state.selectedRecommendedEvent.additional_details.current_features.percentage_savings+"%)" : ''}</span>
                                                                            </p>                     
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='bg-dark2 p-3 rounded mt-2'>
                                                                    <p className="mb-1">Usage {this.state.selectedRecommendedEvent.additional_details.current_features.price_unit ? "("+this.state.selectedRecommendedEvent.additional_details.current_features.price_unit+")" : ""}</p>
                                                                    <div className='d-flex'>
                                                                        <div className='w-45 pr-2'>
                                                                            <p className="b-block mb-0">Price</p>
                                                                            <p className="mb-0 text-warning">
                                                                            {this.state.selectedRecommendedEvent.additional_details.current_features.billing_prev_month ? this.state.selectedRecommendedEvent.additional_details.current_features.billing_prev_month : ""}
                                                                            </p>
                                                                        </div>
                                                                        <div className='w-55'>
                                                                            <p className="b-block mb-0">Savings</p>
                                                                            <p className="mb-0 text-success">
                                                                                {this.state.selectedRecommendedEvent.additional_details.current_features.savings_billing_prev_month ? this.state.selectedRecommendedEvent.additional_details.current_features.savings_billing_prev_month : ""}
                                                                                <span className="text-primary-color f12 ml-1">{this.state.selectedRecommendedEvent.additional_details.current_features.percentage_savings_billing_prev_month ? "("+this.state.selectedRecommendedEvent.additional_details.current_features.percentage_savings_billing_prev_month+"%)" : ''}</span>
                                                                            </p>                     
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='w-100'>
                                                    <div className="d-flex justify-content-between">
                                                        <p className="mb-1 ml-2">Recommendations</p>
                                                        {this.state.selectedRecommendedEvent.additional_details.recommended_features && Object.entries(this.state.selectedRecommendedEvent.additional_details.recommended_features).length ?
                                                            <div className="d-flex justify-content-end">
                                                                <small className="mr-3">
                                                                    Showing 
                                                                    {this.state.selectedRecommendedEvent.additional_details.recommended_features ?  
                                                                        " "+(this.state.showingOffStart) +" - "+ this.state.showingOff +" ("+this.state.selectedRecommendedEvent.additional_details.recommended_features.length+") "
                                                                    : " 0"
                                                                    } 
                                                                </small>
                                                                <span className="far fa-chevron-left mr-3 cursorPointer" onClick={() => this.slideLeft()}></span>
                                                                <span className={`far fa-chevron-right cursorPointer`}  onClick={() => this.slideRight("")}></span>
                                                            </div>
                                                        : null}
                                                    </div>
                                                    <div ref={this.sliderWrap} className="d-flex flex-nowrap overflow-hidden ml-2">
                                                        {this.state.showRecommendedFeatures ? 
                                                            this.state.selectedRecommendedEvent.additional_details.recommended_features && Object.entries(this.state.selectedRecommendedEvent.additional_details.recommended_features).map(([key, item]) => {
                                                                return(
                                                                    <div className="featureBox shadow-none mr-3">
                                                                        <div class="px-3 rounded bg-dark3">
                                                                            <div className="row p-3 sectionBox">
                                                                                <div className={`w-100`}>
                                                                                    <div className="py-1">
                                                                                        <p className="b-block mb-0">Instance Type: <span className="ml-2 mb-0 text-white">{item.instance_type ? item.instance_type : ''}</span></p>
                                                                                    </div>
                                                                                    {item.cpu ?
                                                                                        <div className="py-1">
                                                                                            <p className="b-block mb-0">CPU:
                                                                                            <span className="ml-2 mb-0 text-white">{item.cpu}</span></p>
                                                                                        </div>
                                                                                    : item.duration ? 
                                                                                        <div className="py-1">
                                                                                            <p className="b-block mb-0">Duration {item.duration_unit ? " ("+item.duration_unit+")" : ''}:
                                                                                            <span className="ml-2 mb-0 text-white"> {item.duration}</span></p>
                                                                                        </div>
                                                                                    : null}
                                                                                    <div className="py-1">
                                                                                        <p className="b-block mb-0">Memory {item.memory_unit ? " ("+item.memory_unit+")" : ""}:
                                                                                        <span className="ml-2 mb-0 text-white">{item.memory ? item.memory : ''}</span></p>
                                                                                    </div>
                                                                                    <div className="py-1">
                                                                                        <p className="b-block mb-0">Network (gbps):
                                                                                        <span className="ml-2 mb-0 text-white">{item.network_in_gbps ? item.network_in_gbps : ''}</span></p>
                                                                                    </div>
                                                                                    <div className='bg-dark2 p-3 rounded'>
                                                                                        <p className="mb-1">Monthly {item.price_unit ? "("+item.price_unit+")" : ''}</p>
                                                                                        <div className='d-flex'>
                                                                                            <div className='w-45 pr-2'>
                                                                                                <p className="b-block mb-0">Price</p>
                                                                                                <p className="mb-0 text-warning">
                                                                                                    {item.price_per_month ? item.price_per_month : ''}
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className='w-55'>
                                                                                                <p className="b-block mb-0">Savings</p>
                                                                                                <p className="mb-0 text-success">
                                                                                                    {item.savings ? item.savings : ''}
                                                                                                    <span className="text-primary-color f12 ml-1">{item.percentage_savings ? "("+item.percentage_savings+"%)" : ''}</span>
                                                                                                </p>                     
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='bg-dark2 p-3 rounded mt-2'>
                                                                                        <p className="mb-1">Usage {item.price_unit ? "("+item.price_unit+")" : ''}</p>
                                                                                        <div className='d-flex'>
                                                                                            <div className='w-45 pr-2 align-self-center'>
                                                                                                <p className="b-block mb-0">-</p>
                                                                                            </div>
                                                                                            <div className='w-55'>
                                                                                                <p className="b-block mb-0">Savings</p>
                                                                                                <p className="mb-0 text-success">
                                                                                                    {item.savings_billing_prev_month ? item.savings_billing_prev_month : ''}
                                                                                                    <span className="text-primary-color f12 ml-1">{item.percentage_savings_billing_prev_month ? "("+item.percentage_savings_billing_prev_month+"%)" : ''}</span>
                                                                                                </p>                     
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        : null}
                                                    </div>
                                                </div>
                                            </div>
                                            </React.Fragment>
                                            
                                        }
                            
                                        
                                        </React.Fragment>
                                    : null}
                                </div>
                            :  this.state.selectedRecommendedEvent.observation ?
                                <div className="border-bottom bg-dark p-3 mb-3">
                                    <p className="f16 mb-1 text-white">Observations: <span className="ml-1 f12 text-primary-color">
                                        {this.state.selectedRecommendedEvent.observation}</span>
                                    </p>
                                </div>
                            : null}

                            {this.state.showRecommendedFeaturesSection ?
                                this.state.selectedRecommendedEventLabel === "Lambda Right Sizing" ?
                                    <div className={`card bg-dark border-0 p-3 mb-3`}>
                                        <div className={`${this.state.showRecommendedObjectFeatures ? "mb-2" : ""} d-flex justify-content-between`}>
                                            <div className="text-white cursorPointer" onClick={() => this.setState({ showRecommendedObjectFeatures: !this.state.showRecommendedObjectFeatures })}><span className={`far ${this.state.showRecommendedObjectFeatures ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Recommendations</div>
                                        </div>
                                        {this.state.showRecommendedObjectFeatures ?
                                            this.state.selectedRecommendedEvent.additional_details.recommended_features ?
                                                <div className="featureBox shadow-none mr-3">
                                                    <div class="px-3 rounded">
                                                        <div className="row p-3 sectionBox bg-dark3">
                                                            <div className={`w-100`}>
                                                                <div className="py-1">
                                                                    <p className="b-block mb-0">Duration {this.state.selectedRecommendedEvent.additional_details.recommended_features.duration_unit ? " ("+this.state.selectedRecommendedEvent.additional_details.recommended_features.duration_unit+")" : ''}:
                                                                    <span className="ml-2 mb-0 text-white"> {this.state.selectedRecommendedEvent.additional_details.recommended_features.duration}</span></p>
                                                                </div>

                                                                <div className="py-1">
                                                                    <p className="b-block mb-0">Memory {this.state.selectedRecommendedEvent.additional_details.recommended_features.memory_unit ? " ("+this.state.selectedRecommendedEvent.additional_details.recommended_features.memory_unit+")" : ""}:
                                                                    <span className="ml-2 mb-0 text-white">{this.state.selectedRecommendedEvent.additional_details.recommended_features.memory ? this.state.selectedRecommendedEvent.additional_details.recommended_features.memory : ''}</span></p>
                                                                </div>
                                                                <div className="py-1">
                                                                    <p className="b-block mb-0 text-success f18">Savings {this.state.selectedRecommendedEvent.additional_details.recommended_features.price_unit ? "("+this.state.selectedRecommendedEvent.additional_details.recommended_features.price_unit+")" : ""}:</p>
                                                                    <p className="mb-0 text-success f20">
                                                                        {this.state.selectedRecommendedEvent.additional_details.recommended_features.savings ? Math.floor(this.state.selectedRecommendedEvent.additional_details.recommended_features.savings) === "number" ? this.state.selectedRecommendedEvent.additional_details.recommended_features.savings : parseFloat(Math.floor(this.state.selectedRecommendedEvent.additional_details.recommended_features.savings)).toFixed(2) : ""}
                                                                        {this.state.selectedRecommendedEvent.additional_details.recommended_features.savings ? 
                                                                            this.state.selectedRecommendedEvent.additional_details.recommended_features.percentage_savings ?
                                                                                <span className="text-primary-color f12 ml-1">({this.state.selectedRecommendedEvent.additional_details.recommended_features.percentage_savings}"%")</span>
                                                                            : null
                                                                        : ""}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            : null
                                        : null}
                                    </div>
                                : null
                            : null}
                            {this.state.selectedRecommendedEvent && this.state.selectedRecommendedEvent.additional_details && this.state.selectedRecommendedEvent.additional_details.statistics ?
                                <div className={`card bg-dark border-0 p-3 mb-3`}>
                                    <div className={`${this.state.showStatisticsSection ? "mb-2" : ""} d-flex justify-content-between`}>
                                        <div className="text-white cursorPointer" onClick={() => this.setState({ showStatisticsSection: !this.state.showStatisticsSection })}><span className={`far ${this.state.showStatisticsSection ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Statistics</div>
                                        {/* <div className="text-info">Showing event details</div> */}
                                    </div>
                                    {this.state.showStatisticsSection ?
                                        <div className={`rounded bg-dark3 border-0 p-3 mb-3`}>
                                            <div className="transparentTooltip">
                                                <Chart options={this.state.multiAxisOptions} series={this.state.multiAxisSeries} type="line" height={250} />
                                            </div>
                                        </div>
                                    : null}
                                </div>
                            : null}
                        </React.Fragment>
                    : null}

                    {this.state.showIdealCharts ?
                        <div className={`rounded bg-dark border-0 p-3 mb-3`}>
                            <IdleTimeSection
                                recommendedEvent={this.state.selectedRecommendedEvent}
                            />
                        </div>
                    : null}
                    
                    {this.state.showMeticsChart ?
                        <div className="my-3">
                            <PrimarySecondaryMetricsSection
                                page={"recommendation"}
                                startTime={this.state.selectedRecommendedEvent && this.state.selectedRecommendedEvent.start_time ? this.state.selectedRecommendedEvent.start_time : ""}
                                endTime={this.state.selectedRecommendedEvent && this.state.selectedRecommendedEvent.end_time ? this.state.selectedRecommendedEvent.end_time : ""}
                                metricsType={this.state.selectedRecommendedEventLabel}                                
                                selectedEvent = {this.state.selectedRecommendedEvent}
                                assetDetails={this.state.selectedRecommendedEvent}
                                brush={true}
                                sparkline={false}
                                yaxis={true}
                                yaxisLabel={true}
                                xaxis={true}
                                xaxisFormat={''}
                                xaxisLabel={true}
                                grid={false}
                                axisLabelColor={'#B8BBBE'}
                                legend={false}
                                stacked={false}
                                height={115}
                                horizontal={true}
                                barHeight={'40%'}
                                barEndShape={'rounded'}
                                columnWidth={'50%'}
                                gradient={true}
                                gradientColor={['#039BE5', '#5F5BA2']}
                                hideTooltipValue={true}
                                backgroundBarShape={'rounded'}
                                backgroundBarColors={['#333947']}
                                showBackgroundBarColors={false}
                                className={"transparentTooltip mt-n4 mb-n4"}
                                annotations={true}
                                // annotaionOptions={this.state.mertcisAnnotationTypes}
                            />
                        </div>
                    : null}
                    
                    {this.state.selectedRecommendedEvent && Object.entries(this.state.selectedRecommendedEvent).length ?
                        <div className={`card bg-dark border-0 p-3 mt-3 displayNone`}>
                            <div className={`${this.state.showAssetDetails ? "mb-2" : ""} d-flex justify-content-between`} onClick={() => this.setState({ showAssetDetails: !this.state.showAssetDetails })}>
                                <div className="text-white cursorPointer"><span className={`far ${this.state.showAssetDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Asset Details</div>
                                {/* <div className="text-info">Showing event details</div> */}
                            </div>
                            <div className={`collapse ${this.state.showAssetDetails ? 'show' : ''}`}>
                                <div className="d-flex mb-1 border-bottom">
                                    <div className="py-1 w-50">
                                        <p className="b-block mb-0">Provider / Account / Region</p>
                                        <p className="mb-0 text-white">
                                            {this.state.selectedRecommendedEvent.provider ? this.state.selectedRecommendedEvent.provider.toUpperCase() : <span>&nbsp;</span>}
                                            {this.state.selectedRecommendedEvent.account_id ? (' : ')+getAccountNameFromId(this.state.selectedRecommendedEvent.account_id, this.props.accounts) : (this.state.selectedDiagnosticRcaEvent && this.state.selectedDiagnosticRcaEvent.account_id ? this.state.selectedDiagnosticRcaEvent.account_id : <span>&nbsp;</span>)}
                                            {this.state.selectedRecommendedEvent.region ?  (' : ')+getRegionName(this.state.selectedRecommendedEvent.region, this.state.regions) : <span>&nbsp;</span>}
                                        </p>
                                    </div>                                    
                                    <div className="py-1 w-50 pl-3">
                                        <p className="b-block mb-0">Service</p>
                                        <p className="mb-0 text-white">
                                            {this.state.selectedRecommendedEvent.resource_type ? this.state.selectedRecommendedEvent.resource_type : <span>&nbsp;</span>}
                                            {this.state.selectedRecommendedEvent.service_name ?  (' : ')+this.state.selectedRecommendedEvent.service_name : <span>&nbsp;</span>}
                                        </p>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="py-1 w-50">
                                        <p className="b-block mb-0">Asset</p>
                                        {this.state.selectedRecommendedEvent.id_name_map && this.state.selectedRecommendedEvent.id_name_map !== "" ?
                                            <p className="mb-0 text-white">
                                                <span className={`ml-1`}>{(this.state.selectedRecommendedEvent.id_name_map.value ? this.state.selectedRecommendedEvent.id_name_map.value : "")}</span>
                                                {this.state.selectedRecommendedEvent.id_name_map.key && this.state.selectedRecommendedEvent.id_name_map.key !== "" ?
                                                    <span className={`ml-1'} mb-0`}>
                                                        {" : "+this.state.selectedRecommendedEvent.id_name_map.key}
                                                    </span>
                                                : null}
                                            </p>
                                        : 
                                            <p className="mb-0 text-white">{this.state.selectedRecommendedEvent.asset_name ? this.state.selectedRecommendedEvent.asset_name : <span>&nbsp;</span>}</p>
                                        }
                                    </div>
                                    <div className="py-1 w-50 pl-3">
                                        <p className="b-block mb-0">Asset Time</p>
                                        <p className="mb-0 text-white">{this.state.selectedRecommendedEvent.event_time ? momentConvertionUtcToLocalTime(this.state.selectedRecommendedEvent.event_time, 'DD MMM YYYY HH:mm:ss') : <span>&nbsp;</span>}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    : null}
                </div>
            </div>
		)
	}
}
/**
 * Type of the props used in the component
 */
RecommendationsRightSection.propTypes = {
    listAllAccounts: PropTypes.func,
	listAllRegions: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
    // console.log("state", state)
    return {
        accounts: state.filters.accounts,
        regions: state.filters.regions,
        selectedRecommendedEvent: state.cost.costPropsDetails && state.cost.costPropsDetails.selectedRecommendedEvent ? state.cost.costPropsDetails.selectedRecommendedEvent : {},
        selectedResourceRecommendedEvent: state.cost.costPropsDetails && state.cost.costPropsDetails.selectedResourceRecommendedEvent ? state.cost.costPropsDetails.selectedResourceRecommendedEvent : {},
    }
}

export default connect(mapStateToProps, {
    listAllAccounts,
	listAllRegions,
    setCostPropsDetails,
})(withRouter(RecommendationsRightSection))