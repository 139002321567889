/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file DetailsSection.js
 * @author Rajasekar // on 09/01/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Chart from 'chart.js'
import ApexChart from 'react-apexcharts'
import moment from 'moment'
import _ from 'lodash'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import ReactTooltip from 'react-tooltip';

import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner, UncontrolledTooltip } from 'reactstrap'
import { trimmedSplitUserName1, trimmedSplitUserName2, capitalizeFirstLetter, momentConvertionUtcToLocalTime, addHours, momentDateGivenFormat, convertBytes, convertSeconds, countFormater, convertBits } from '../../../utils/utility'
import { DURATION_FILTER_OPTIONS } from '../../../utils/constants'

import {
	getDailyAssetAvailability
} from '../../../actions/detectors/detectorsAction'

let statusOption = [
	{value: "", label: "All"},
	{value: "pending", label: "Pending"},
	{value: "reoccured", label: "Reoccured"},
	{value: "Resolved", label: "Resolved"},
]

let monitoringDurationOptions = [
	{value: "2h", label: "Last 2 Hrs"},
	{value: "6h", label: "Last 6 Hrs"},
	{value: "12h", label: "Last 12 Hrs"},
	{value: "24h", label: "Last 24 Hrs"},
	{value: "7d", label: "Last 7 Days"},
	{value: "14d", label: "Last 14 Days"},
	{value: "30d", label: "Last Month"},
	{value: "60d", label: "Last 2 Months"},
	{value: "90d", label: "Last 3 Months"}
]

class DetailsSection extends Component {
	constructor(props) {
		super(props)

		this.tabRef = React.createRef()

		this.state = {
			showRelatedIssues: false,
			showAssetDetails: true,
			showMonitoringDetails: false,
			showEvents: false,
			showBillingDetails: false,
			isEventsDropdownOpen: false,
			tabItemsToShow: 3,
			monitoringDuration: '2h',
			eventsDuration: '1h',
			relatedDurtaion: this.props.relatedDurtaion,
			series: [],
			options: [],
			showDetailsAvailSectionLoading: false,
			availabilityData: [],
			filterAvailabilityData: [],
			selectedAvailabilityMonth: 0,
			disablePreviousBtn: true,
			disableNextBtn: true,
			cpuMetricsSeries: [],
			cpuMetricsOptions: [],
			networkInMetricsSeries: [],
			networkInMetricsOptions: [],
			networkOutMetricsSeries: [],
			networkOutMetricsOptions: [],
		}
	}

	componentDidMount = () => {
		this.setState({
			tabItemsToShow: this.props.detailsTabNames.length,
			monitoringDuration: this.props.selectedMonitoringDuration,
		})
		this.setTabs()
		const _this = this
		window.addEventListener('resize', () => {
			_this.setTabs()
		})
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			this.setTabs()
			this.setState({ relatedDurtaion: this.props.relatedDurtaion })
			let showAssetDetails = false
			let showRelatedIssues = false
			let showEvents = false
			let showMonitoringDetails = false
			let showBillingDetails = false
			let showAvailabilityDetails = false
			
			if(this.props.selectedTab === 'asset_details') {
				showAssetDetails =  true				
			} else if(this.props.selectedTab === 'related_issues') {
				showRelatedIssues =  true
			} else if(this.props.selectedTab === 'events') {
				showEvents = true
			} else if(this.props.selectedTab === 'monitoring_details') {
				showMonitoringDetails = true
				this.metricsListGraph()
			} else if(this.props.selectedTab === 'billing') {
				showBillingDetails =  true				
			} else if(this.props.selectedTab === 'availability') {
				showAvailabilityDetails =  true				
			} 
			this.setState({ showAssetDetails, showRelatedIssues, showEvents, showMonitoringDetails, showBillingDetails, showAvailabilityDetails, monitoringDuration: this.props.selectedMonitoringDuration })
		}
		// if (
		// 	this.props.dayWiseCountGraphData.labels &&
		// 	this.state.showRelatedIssues &&
		// 	!this.props.showDetailsRelatedIssueLoading &&
		// 	this.props.selectedDetailsTab === 1
		// ) {
		// 	this.getRelatedDetails()
		// 	//this.showApexAssetActionGraph()
		// }
		// if (
		// 	this.props.eventGraphData !== prevProps.eventGraphData &&
		// 	this.state.showEvents &&
		// 	!this.props.showDetailsEventsSectionLoading
		// ) {
		// 	this.showEventsGraph()
		// }
		if (
			this.props.selectedRowBillingDetails !== prevProps.selectedRowBillingDetails &&
			this.state.showBillingDetails &&
			!this.props.showDetailsBillingSectionLoading
		) {
			// this.showBillingGraph()
		}
	}

	setTabs = () => {
		if (this.tabRef.current) {
			// myLog('current', this.tabRef.current)
			// myLog(this.tabRef.current.clientWidth)
			// myLog('childNodes - length', this.tabRef.current.childNodes.length)
			// myLog(this.tabRef.current.childNodes[0])
			// myLog(this.tabRef.current.childNodes[1])

			const maxTabWidth = this.tabRef.current.clientWidth - 40
			let reqTabWidth = 0
			let allowedTabLength = 0
			for (let i = 0; i < this.tabRef.current.childNodes.length; i++) {
				// myLog('reqqq', this.tabRef.current.childNodes[i].clientWidth)
				reqTabWidth += this.tabRef.current.childNodes[i].clientWidth
				if (reqTabWidth < maxTabWidth) {
					allowedTabLength++
				}
			}
			// myLog(maxTabWidth, reqTabWidth, allowedTabLength)
			this.setState({ tabItemsToShow: allowedTabLength })
		}
	}

	/**
	 * Method to show summary graph
	 */
	showFailTrendGraph = () => {
		if (this.props.dayWiseCountGraphData.labels && this.props.selectedDetailsTab === 1) {
			const relatedIssuesGraph = this.relatedIssuesGraph
			Chart.defaults.scale.gridLines.display = true
			Chart.defaults.scale.display = true
			const lastSevenDaysLabelData = this.props.dayWiseCountGraphData.labels.slice(-10)
			const lastSevenDaysgraphData = this.props.dayWiseCountGraphData.data.fail.slice(-10)
			const labelData = []
			const graphData = []
			lastSevenDaysLabelData.forEach(datetime => {
				labelData.push(moment(datetime).format('DD MMM'))
			})
			lastSevenDaysgraphData.forEach(count => {
				graphData.push(Math.round(count))
			})
			new Chart(relatedIssuesGraph, {
				type: 'line',
				data: {
					datasets: [
						{
							label: 'Count',
							data: graphData,
							borderColor: '#ccf3d6',
							backgroundColor: '#aadfb8',
							fill: true,
							borderWidth: 3,
							pointRadius: 1,
						},
					],
					labels: labelData,
				},
				options: {
					responsive: true,
					maintainAspectRatio: false,
					scales: {
						xAxes: [
							{
								gridLines: {
									display: false,
								},
							},
						],
						yAxes: [
							{
								ticks: {
									beginAtZero: true,
									steps: 10,
									stepValue: 5,
									max: 20,
								},
							},
						],
					},
					legend: {
						display: false,
					},
				},
			})
		}
	}

	/**
	* Method to display monitoring graph in
	*/
	monitoringApexMetrics = (type, data, label) => {		
		let graphLabel = []
		let createdGraphData = []
		let fontColors = []
		let startEndLabels = []
		if(label.length) {
			label.forEach((item, x) => {
				graphLabel.push(item)
				if(x === 0 || x === (label.length - 1)) {
					startEndLabels.push(item)
				}
			})
			let data1 = []	
			data.forEach((item, x) => {
				createdGraphData.push(item)
				let dataItems1 = {}
				dataItems1.x = label[x]
				dataItems1.y  = parseFloat(item,2)
				data1.push(dataItems1)

				fontColors.push('#000')
			})

			let series = []
			let dataRow = {}
			dataRow.data = data1
			dataRow.name = 'pass'
			dataRow.labels = graphLabel
			series.push(dataRow)
			let options = {
				chart: {
				height: 100,
				type: 'area',
				zoom: {
					enabled: false
				},
				sparkline: {
					enabled: false
				},
				toolbar: {
					show: false,
				}
				},
				legend: {
					show: false
				},
				dataLabels: {
				enabled: false
				},
				stroke: {
					show: true,
					curve: 'smooth',
					lineCap: 'butt',
					colors: undefined,
					width: 2,
					dashArray: 0,      
				},
				title: {
				text: ''
				},
				grid: {
					xaxis: {
						lines: {
							show: false
						},
					},
					yaxis: {
						lines: {
							show: false
						}
					},
				},			
				yaxis: {
					labels: {
						show: true,
						style: {
							colors: fontColors
						},
						formatter: function(value) {
							return parseInt(value)
						}
					},
					tickAmount: 1
				},
				labels: graphLabel,
				xaxis: {
					Categories: [graphLabel],
					show: true,
					labels: {
						rotate: 0,
						show: true,
						formatter: function(value) {
							if(value === startEndLabels[0] || value === startEndLabels[1]) {
								return value
							}
						},
						style: {
							colors: fontColors
						},
						axisTicks: {
							show: false
						}
					},
					tooltip: {
						enabled: false
					},
					// min: min,
					// max: max,
				},
				colors: ['#09D261', '#F44336'],
				// fill: {
				// 	colors: ["#CEFBFF"],
				// 	opacity: 0.7,
				// 	type: 'solid',
				// 	//opacity: 0.5,
				// },
				tooltip: {
					enabled: true,
					enabledOnSeries: false,
					shared: false,
					followCursor: false,
					intersect: false,
					inverseOrder: false,
					custom: function({series, seriesIndex, dataPointIndex, w}) {
						let val  = series[seriesIndex][dataPointIndex];
						let labelName = w.globals.initialSeries[0].labels[dataPointIndex]
						return '<div className="arrow_box"> <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val +
						'</div>'
					},
					fillSeriesColor: false,
					theme: false,
					style: {
					fontSize: '12px',				
					},
					onDatasetHover: {
						highlightDataSeries: false,
					},
					x: {
						show: false,
						format: 'dd MMM',
						formatter: undefined,
					},
					y: {
						show: false,
						formatter: undefined,
						title: {
							formatter: (seriesName) => seriesName,
						},
					},
					marker: {
						show: false,
					}
				}
			}
			
			if(type === 'cpuMetrics') {
				this.setState({ cpuMetricsSeries: series, cpuMetricsOptions: options })
			} else if(type === 'networkIn') {
				this.setState({ networkInMetricsSeries: series, networkInMetricsOptions: options })
			}  else if(type === 'networkOut') {
				this.setState({ networkOutMetricsSeries: series, networkOutMetricsOptions: options })
			} 
		}
	}
	/**
	 * Method to show billing graph
	 */
	showBillingGraph = () => {
		const currentBillingGraph = this.currentBillingGraph
		const lastBillingGraph = this.lastBillingGraph
		Chart.defaults.scale.gridLines.display = false
		Chart.defaults.scale.display = true
		new Chart(currentBillingGraph, {
			type: 'line',
			data: {
				datasets: [
					{
						label: 'Cost',
						data: [3.13844, 5.13807, 8, 2, 5],
						borderColor: '#ccf3d6',
						backgroundColor: '#aadfb8',
						fill: false,
						borderWidth: 3,
						pointRadius: 1,
					},
					{
						label: 'Cost',
						data: [5, 7, 2, 5, 7],
						borderColor: '#ccf3d6',
						backgroundColor: '#aadfb8',
						fill: false,
						borderWidth: 3,
						pointRadius: 1,
					},
					{
						label: 'Cost',
						data: [9, 2, 5, 3, 7],
						borderColor: '#ccf3d6',
						backgroundColor: '#aadfb8',
						fill: false,
						borderWidth: 3,
						pointRadius: 1,
					},
				],
				labels: ['ds', 'das', 'dser', 'dsf', 'qwe'],
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					xAxes: [
						{
							gridLines: {
								display: false,
							},
						},
					],
					yAxes: [
						{
							ticks: {
								stepSize: 1,
							},
						},
					],
				},
				legend: {
					display: false,
				},
			},
		})
		new Chart(lastBillingGraph, {
			type: 'line',
			data: {
				datasets: [
					{
						label: 'Cost',
						data: [3.13844, 5.13807, 8, 2, 5],
						borderColor: '#ccf3d6',
						backgroundColor: '#aadfb8',
						fill: false,
						borderWidth: 3,
						pointRadius: 1,
					},
					{
						label: 'Cost',
						data: [5, 7, 2, 5, 7],
						borderColor: '#ccf3d6',
						backgroundColor: '#aadfb8',
						fill: false,
						borderWidth: 3,
						pointRadius: 1,
					},
				],
				// labels: ['ds', 'das', 'dser', 'dsf', 'qwe'],
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					xAxes: [
						{
							gridLines: {
								display: false,
							},
						},
					],
					yAxes: [
						{
							ticks: {
								stepSize: 1,
							},
						},
					],
				},
				legend: {
					display: false,
				},
			},
		})
	}

	/**
	 * Returns fail count of the related issues
	 */
	getRelatedIssuesFailCount = () => {
		let failCount = 0
		if (this.props.relatedIssuesData && this.props.relatedIssuesData.length) {
			const failedArray = this.props.relatedIssuesData.filter(item => item.status === 'fail')
			failCount = failedArray.length
		}
		return failCount
	}

	/**
	 * Returns pass count of the related issues
	 */
	getRelatedIssuesPassCount = () => {
		let passCount = 0
		if (this.props.relatedIssuesData && this.props.relatedIssuesData.length) {
			const passedArray = this.props.relatedIssuesData.filter(item => item.status === 'pass')
			passCount = passedArray.length
		}
		return passCount
	}

	/**
	 * To find risk factor of a issue
	 */
	findRisk = from => {
		let riskCount = 0		
		if (this.props.relatedIssuesData && this.props.relatedIssuesData.length) {
			let filteredData = this.props.relatedIssuesData
			//filteredData = filteredData.filter(item => item.status !== 'NA')
			let relatedRiskCount = []
			filteredData.length &&
			_.chain(filteredData)
			.groupBy('risk')
			.map((value, key) => ({ label: key, sub: value }))
			.value()
			.forEach((risk, i) => {
				relatedRiskCount.push(risk)
				// risk.sub.forEach((item, i) => {
				// 	relatedRiskCount.push(item)
				// })
			})
			relatedRiskCount.forEach(item => {
				if(item.label === from) {
					riskCount = item.sub.length
				}
			})
			// const highArray = this.props.relatedIssuesData.filter(item => item.risk === from)
			// riskCount = highArray.length
		}
		return riskCount
	}
	/**
	 * Toggles events duration dropdown
	 */
	toggleEventsDropdown = () => {
		this.setState({ isEventsDropdownOpen: !this.state.isEventsDropdownOpen })
	}
	/**
	 * Get total count of billing for last month
	 */
	billingLastMonthCost = () => {
		let cost = 0
		this.props.selectedRowBillingDetails &&
			this.props.selectedRowBillingDetails.length &&
			this.props.selectedRowBillingDetails.forEach(item => {
				if (item.last_month) cost = item.last_month['total-unblended-cost']
			})
		return cost
	}
	/**
	 * Get total count of billing for last three months
	 */
	billingLastThreeMonthCost = () => {
		let cost = 0
		this.props.selectedRowBillingDetails &&
			this.props.selectedRowBillingDetails.length &&
			this.props.selectedRowBillingDetails.forEach(item => {
				if (item.duration) cost = item.duration['total-unblended-cost']
			})
		return cost
	}
	/**
	 * Get total count of billing for current month
	 */
	billingCurrentMonthCost = () => {
		let cost = 0
		this.props.selectedRowBillingDetails &&
			this.props.selectedRowBillingDetails.length &&
			this.props.selectedRowBillingDetails.forEach(item => {
				if (item.current_month) cost = item.current_month['total-unblended-cost']
			})
		return cost
	}

	selectedTableAction = index => {
		this.setState({ selectedTableAction: index})
	}

	handleClickGroup = (val) => {
		this.setState({ selectedTableDrop: this.state.selectedTableDrop === val ? '' : val })
	}
	
	getDailyAssetAvailability = () => {
		this.setState({ filterAvailabilityData: [] })
		let params = {}
		if(this.props.selectedDuration !== 'custom') {
			params.duration = this.props.selectedDuration
		} else {
			params.start_time = this.props.start_time
			params.end_time = this.props.end_time
		}
		this.props.getDailyAssetAvailability(params, (promise, dailyAssetAvailability) => {
			if (promise) {
				this.setState({ dailyAssetAvailability }, 
					() => this.dailyAssetAvailabilityFunction()
				)
			} else {
				this.setState({ dailyAssetAvailability: [] })
			}
		})

	}
	
	dailyAssetAvailabilityFunction = () => {
		let labels = this.state.dailyAssetAvailability.labels
		let values = this.state.dailyAssetAvailability.data
		let data = []
		for(let i=0; i<labels.length; i++) {
			let dataRow = {}
			const splitlabel = labels[i].split(' ');
			let date = splitlabel[0]
			dataRow.date = date
			dataRow.availability = values.availability[i]
			dataRow.cpu_average = values.cpu.avg[i]
			dataRow.cpu_min = values.cpu.min[i]
			dataRow.cpu_max = values.cpu.min[i]
			dataRow.cpu_average = values.cpu.avg[i]
			dataRow.cpu_min = values.cpu.min[i]
			dataRow.cpu_max = values.cpu.max[i]
			dataRow.memory_average = values.memory.avg[i]
			dataRow.memory_min = values.memory.min[i]
			dataRow.memory_max = values.memory.max[i]
			dataRow.network = values.network[i]
			dataRow.disk_usage = values.disk[i]
			data.push(dataRow)
		}

		let heatDuration = 1;
		if(this.props.selectedDuration === '7d' || this.props.selectedDuration === '+7d' || this.props.selectedDuration === '+14d' || this.props.selectedDuration === '+30d') {
			heatDuration = 1
			this.setState({ heatDuration })
		} else if(this.props.selectedDuration === '+60d' || this.props.selectedDuration === '+14d' || this.props.selectedDuration === '+30d') {
			heatDuration = 2
			this.setState({ heatDuration })
		} else {
			heatDuration = 3
			this.setState({ heatDuration })
		}

		let week = 0;
		let first = data[0].date;
		const splitDate = first.split('-');
		let year = splitDate[0]
		let month = splitDate[1]
		let date = moment([year, month - 1]);
		
		
		//let endDate = moment(startDate).endOf('month').toDate();
		let startDate = moment(date).subtract(1, 'days').toDate()
		let endDate = moment(startDate).add(heatDuration, 'month').toDate()
		endDate = moment(endDate).endOf('month').toDate();

		if(heatDuration === 1) {
			//to find the number of weeks in months start
			const dateFirst = moment(date).date(1)
			const dateLast = moment(date).date(date.daysInMonth())
			const startWeek = dateFirst.isoWeek()
			const endWeek = dateLast.isoWeek()
			if (endWeek < startWeek) {
				week =  dateFirst.weeksInYear() - startWeek + 1 + endWeek
			} else {
				week =  endWeek - startWeek + 1
			}
			//to find the number of weeks in months end
		}

		let last = data[data.length-1].date;
		const splitLastDate = last.split('-');
		let lastYear = splitLastDate[0]
		let lastMonth = splitLastDate[1]
		
		if(parseInt(lastMonth === month)) {
			this.setState({ disableNextBtn: true,  disablePreviousBtn: true })
		} else {
			this.setState({ disableNextBtn: false })
		}

		let monthName = moment(endDate).format('MMMM')
		
		this.setState({ showDetailsAvailSectionLoading: true, availabilityData: data, filterAvailabilityData: data, startDate, endDate, monthName, month, year, firstMonth: month, lastMonth, firstYear: year, lastYear, week})
	}
	
	filterAvailabilityDetails = (type) => {
		this.setState({ disableNextBtn: false, disablePreviousBtn: false })
		let startDate = ''
		let endDate = ''
		let month = ''
		let year = ''
		let date = ''
		let week = 0;
		if(type === 'next') {			
			startDate = this.state.endDate
			date = moment(startDate).subtract(-1, 'days')
			endDate = moment(startDate).add(this.state.heatDuration, 'month').toDate()
			endDate = moment(endDate).endOf('month').toDate();
			month = moment(endDate).format('MM')
			year = moment(endDate).format('YYYY')
			
			if(parseInt(this.state.lastMonth) <= parseInt(month)) {
				this.setState({ disableNextBtn: true, disablePreviousBtn: false })
			}
		} else {
			endDate = this.state.startDate
			startDate = moment(endDate).subtract(this.state.heatDuration, 'month').toDate()
			startDate = moment(startDate).endOf('month').toDate();
			date = moment(startDate).subtract(-1, 'days')
			month = moment(endDate).format('MM')
			year = moment(endDate).format('YYYY')
			if(this.state.heatDuration > 1) {
				if(parseInt(this.state.firstMonth) <= parseInt(month)) {
					this.setState({ disableNextBtn: false, disablePreviousBtn: true })
				}
			} else {
				if(parseInt(this.state.firstMonth) === parseInt(month)) {
					this.setState({ disableNextBtn: false, disablePreviousBtn: true })
				}
			}
		}

		if(this.state.heatDuration === 1) {
			//to find the number of weeks in months start
			const dateFirst = moment(date).date(1)
			const dateLast = moment(date).date(date.daysInMonth())
			const startWeek = dateFirst.isoWeek()
			const endWeek = dateLast.isoWeek()		
			if (endWeek < startWeek) {
			// cater to end of year (dec/jan)
				week =  dateFirst.weeksInYear() - startWeek + 1 + endWeek
			} else {
				week =  endWeek - startWeek + 1
			}
			//to find the number of weeks in months end
		}

		let monthName = moment(endDate).format('MMMM')

		this.setState({ filterAvailabilityData: this.state.availabilityData, startDate, endDate, monthName, month, year, week })
	}

	metricsListGraph = () => {
		let graphDetails = this.props.selectedRowMonitoringSnapshot && this.props.selectedRowMonitoringSnapshot.response ? this.props.selectedRowMonitoringSnapshot.response : []
		if(graphDetails && graphDetails.length) {
			graphDetails.forEach((graph, index) => {
				let graphData = graph.data
				Object.entries(graphData).forEach(([key, item], i) => {
					let array = []
					let series = []
					let options = {}
					if(item.values && item.values.length) {
						item.values.forEach((val, j) => {
							let arrayValues = []
							arrayValues.push(momentConvertionUtcToLocalTime(graph.dates[j], 'YYYY-MM-DD HH:mm:ss'))
							if(item.unit) {
								val = this.getGrapUnitValue(val, item.unit)
							}
							arrayValues.push(val)
							array.push(arrayValues)
						})

						if(array.length === 1) {
							let arrayValues = []
							arrayValues.push(addHours(momentConvertionUtcToLocalTime(graph.dates[0], 'YYYY-MM-DD HH:mm:ss'), 0.1))
							arrayValues.push(0)
							array.push(arrayValues)
						}

						series = [{
							data: array
						}]

						options = {
							chart: {
								// group: 'metrics_and_sre',
								// id:'metrics_'+i,
								type: 'area',
								width: '100%',
								toolbar: {
									show: false,
								},
								zoom: {
									enabled: false
								},
								sparkline: {
									enabled: false
								}
							},
							stroke: {
								show: true,
								curve: 'smooth',
								lineCap: 'butt',
								width: 2,
								dashArray: 0,      
							},
							colors: ['#775BA2'],
							fill: {
								type: "gradient",
								gradient: {
									// shadeIntensity: 1,
									// opacityFrom: 0.7,
									// opacityTo: 0.9,
									// stops: [0, 90, 100]
									gradientToColors: ['#775BA2'],
									shadeIntensity: 0,
									opacityFrom: .3,
									opacityTo: 1,
									stops: [30, 90]
								}
							},
							tooltip: {
								custom: function({series, seriesIndex, dataPointIndex, w}) {
									let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
									dateLable = dateLable.toString()
									dateLable = momentDateGivenFormat(dateLable, 'DD MMM HH:mm')
									let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]
									return '<div class="arrow_box">'+dateLable+' <span style="color:'+ w.globals.colors +'">' + parseFloat(val).toFixed(2) + '</span> </div>'
								},
								fixed: {
									enabled: false
								},
								x: {
									show: false,
								},
								y: {
									show: false
								},        
								marker: {
									show: false
								}
							},
							xaxis: {
								type: 'datetime',
								labels: {
									datetimeUTC: false,
									style: {
										colors: '#60687C'
									},
									// formatter: function (value) {
									//     return momentDateGivenFormat(value, 'HH:mm')
									// },
								},                            
								axisTicks: {
									show: false
								},
							},
							dataLabels: {
								enabled: false
							},
							yaxis: {
								show: true,
								axisTicks: {
									show: false,
								},
								axisBorder: {
									show: false,
									color: '#60687C'
								},
								tooltip: {
									enabled: false
								},
								// tickAmount: 2,
								min: 0,
								// max: yaxisMaxValue, 
								tickAmount: 1,
								labels: {
									offsetX: -10,
									// minWidth: 50,
									show: true,
									style: {
										fontSize: '9px',
										colors: '#60687C'
									},
									// formatter: (value) => { 
									//     if(Number(value) === value && value % 1 === 0) {
									//         return Math.ceil(value)
									//     } else {
									//         return Math.ceil(value)
									//     }
									// },
									// offsetX: 0,
									// offsetY: 0,
								}
							},
							grid: {
								show: true,
								borderColor: '#2D323E',
								strokeDashArray: 0,
								position: 'back',
								xaxis: {
									lines: {
										show: true
									},
									labels: {
										style: {
											colors: ['#FFF'],
										}
									},
									axisTicks: {
										show: false
									}
								},   
								yaxis: {
									lines: {
										show: false
									},
									labels: {
										style: {
											colors: ['#969AA7'],
										}
									}
								},                
								padding: {
									top: 0,
									right: 0,
									bottom: 0,
									left: 0
								},
							}
						}
					}

					this.setState({ [key+'_metrics_series_'+index+'_'+i]: series, [key+'_metrics_options_'+index+'_'+i]: options, loadingMonMetrics: false })
				})				
			})
		} else {
			this.setState({ loadingMonMetrics: false })
		}
	}

	getGrapUnitValue = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytes(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit)
            } else if(unit === 'Count') {
                // val = val > 1 ? parseInt(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

	toggleState = (state) => {
		this.setState(prevState => ({
			[state]: !prevState[state],
		}))
	}
	
	render() {
		return (
			<React.Fragment>
				<div className='px-3 d-flex align-content-center my-2'>
					<span className='m-0 font-weight-bold align-self-center text-white'>Product :</span>
					{Array.isArray(this.props.selectedTableRow.category) ? (
						this.props.selectedTableRow.category.map((item, index) => {
							return (
								<span key={index} className='badge badge-pill p-2 badge-secondary ml-1 text-white font-weight-bold'>
									{item}
								</span>
							)
						})
					) : (
						<span className='badge badge-pill p-2 badge-secondary ml-1 text-white font-weight-bold'>{this.props.selectedTableRow.category}</span>
					)}
				</div>

				{this.state.showAssetDetails && this.props.selectedTableRow && this.props.selectedTableRow.tags ? (
					<React.Fragment>
						<p className='px-3 m-0 font-weight-bold'>Tags :</p>
						<ul className='tags my-1 px-3'>
							{this.props.selectedTableRow.tags.map((tag, index) => {
								const keys = tag.key.split(':')
								const values = tag.value.split('-')
								delete values[values.length - 1]
								return (
									<li key={index}>
										<span className='tag'>
											{keys[keys.length - 1]}: {values.length > 1 ? values.join('-') : tag.value}
										</span>
									</li>
								)
							})}
						</ul>
					</React.Fragment>
				) : 
					<p className='px-3 m-0 mb-2 font-weight-bold'>Tags : <small className="m-0 p-0 f12">No Tags are associated for the Resource</small></p>
				}

				<ul
					ref={this.tabRef}
					className={`nav nav-tabs border-bottom bd-highlight bg-dark shadow-none ${
						this.props.showDetailsAssetSectionLoading ||
						this.props.showDetailsRelatedIssueLoading ||
						this.props.showDetailsMonitoringSectionLoading ||
						// this.props.showDetailsBillingSectionLoading ||
						this.props.showSuppressListLoading
							? 'disabled'
							: ''
					}`}
					id='myTab'
					role='tablist'
				>
					{this.props.detailsTabNames &&
						this.props.detailsTabNames.length &&
						this.props.detailsTabNames.map((item, index) => {
							if (index < this.state.tabItemsToShow)
								return (
									<li
										className='nav-item'
										key={index}
										onClick={() => {
											if (item.value === 'asset_details') {
												this.props.toggleDetailsSectionLoading('asset_details', true)
												this.props.getAssetDetails(this.props.selectedTableRow)
											} else if (item.value === 'related_issues') {
												this.props.toggleDetailsSectionLoading('related_issues', true)
												this.props.getRelatedIssues(this.props.selectedTableRow, this.state.relatedDurtaion)
											} else if (item.value === 'events') {
												this.props.toggleDetailsSectionLoading('events', true)
												this.props.getAssetEvents(this.props.selectedTableRow, this.state.eventsDuration)
											} else if (item.value === 'monitoring_details') {
												this.props.toggleDetailsSectionLoading('monitoring_details', true)
												this.props.getMonitoringSnap(this.props.selectedTableRow, this.state.monitoringDuration)
											} else if (item.value === 'billing') {
												this.props.toggleDetailsSectionLoading('billing', true)
												this.props.getBillingSnap(this.props.selectedTableRow)
											} else if (item.value === 'availability') {
												this.getDailyAssetAvailability(this.props.selectedTableRow)	
												this.props.toggleDetailsSectionLoading('availability', true)
											}
										}}
									>
										<span
											className={`nav-link text-white custom-link ${this.props.selectedDetailsTab === index ? 'active' : ''}`}
											id='assets-tab'
											data-toggle='tab'
											role='tab'
											onClick={() => this.props.setSelectedDetailsTabIndex(index) }
											//this.props.setSelectedDetailsTabIndex(index)}
											aria-controls='assets'
											aria-selected='true'
										>
											{item.name}
										</span>
									</li>
								)
							return null
						})}
					<li
						className={`nav-item ml-auto bd-highlight align-self-center ${
							this.state.tabItemsToShow <= this.props.detailsTabNames.length ? '' : 'd-none'
						}`}
					>
						<ButtonDropdown
							isOpen={this.props.showDetailsTabListDropdown}
							toggle={() => this.props.toggleDetailsTabListDropdown()}
						>
							<DropdownToggle
								tag='a'
								className={`nav-link custom-link ${!this.props.detailsTabNames.length ? 'd-none' : ''}`}
							>
								<i className='fal fa-angle-double-right cursor-pointer' />
							</DropdownToggle>
							<DropdownMenu
								right
								modifiers={{
									setMaxHeight: {
										enabled: true,
										order: 890,
										fn: data => {
											return {
												...data,
												styles: {
													...data.styles,
													overflow: 'auto',
													maxHeight: 300,
												},
											}
										},
									},
								}}
							>
								{this.props.detailsTabNames &&
									this.props.detailsTabNames.map((item, index) => {
										if (index >= this.state.tabItemsToShow)
											return (
												<DropdownItem
													key={index}
													onClick={() => {
														this.setTabs()
														if (item.value === 'asset_details') {
															this.props.toggleDetailsSectionLoading('asset_details', true)
															this.props.getAssetDetails(this.props.selectedTableRow)
														} else if (item.value === 'related_issues') {
															this.props.toggleDetailsSectionLoading('related_issues', true)
															this.props.getRelatedIssues(this.props.selectedTableRow, this.state.relatedDurtaion)
														} else if (item.value === 'events') {
															this.props.toggleDetailsSectionLoading('events', false)
															this.props.getAssetEvents(this.props.selectedTableRow, this.state.eventsDuration)
														} else if (item.value === 'monitoring_details') {
															this.props.getMonitoringSnap(this.props.selectedTableRow, this.state.monitoringDuration)
															this.props.toggleDetailsSectionLoading('monitoring_details', true)
														} else if (item.value === 'billing') {
															this.props.getBillingSnap(this.props.selectedTableRow)
															this.props.toggleDetailsSectionLoading('billing', true)
														}
														this.props.onClickDetailsTabDropDownItem(item, index)
													}}
												>
													{item.name}
												</DropdownItem>
											)
										return null
									})}
							</DropdownMenu>
						</ButtonDropdown>
					</li>
				</ul>
				<div className='tab-content' id='myTabContent'>
					{this.props.selectedRowAssetDetails.length &&
					!this.props.showDetailsAssetSectionLoading &&
					this.state.showAssetDetails ? (
						<div
							className={`tab-pane fade show active`}
							id='assets'
							role='tabpanel'
							aria-labelledby='assets-tab'>
							<div className='badge badge-light text-wrap text-left p-0 m-2 fs-13 d-flex rounded overflow-hidden flex-column border-0 bg-dark mt-3'>
								{this.props.selectedTableRow && (this.props.selectedTableRow.service_name || this.props.selectedTableRow.asset_name) ?
									<code className='p-2 text-white align-self-center f12'>
										{this.props.selectedTableRow && this.props.selectedTableRow.service_name ?
											<span className="badge-square bg-muted p-2 text-wrap mr-2 f13"> {this.props.selectedTableRow.service_name}</span>
										: null
										}
										{this.props.selectedTableRow && this.props.selectedTableRow.asset_name ?
											<span>:&nbsp;{this.props.selectedTableRow.asset_name}</span>
										: null
										}
									</code>
								: null 
								}
								<div className='bg-dark-theme-green p-2 f12 text-center text-white'>Active</div>
								{/* <div className='alert alert-success rounded-0 mb-0  align-self-center'>Active</div> */}
							</div>
							<div className='card m-2 border-0 p-2 bg-dark rounded'>
								<table className='table assets-side-table'>
									<thead>
										<tr>
											<th colSpan={2} className='text-white'>Network</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className='text-primary-color ml-3'>Provider ID</td>
											<td className='text-white'>{this.props.selectedRowAssetDetails[0].provider}</td>
										</tr>
										<tr>
											<td className='text-primary-color ml-3'>Service</td>
											<td className='text-white'>{this.props.selectedRowAssetDetails[0].service_name}</td>
										</tr>
										<tr>
											<td className='text-primary-color ml-3'>Resource Type</td>
											<td className='text-white'>{this.props.selectedRowAssetDetails[0].resource_type}</td>
										</tr>
										{/* <tr>
											<td className='text-primary-color ml-3'>Compliance ID</td>
											<td>
												{this.props.selectedRowAssetDetails[0].compliance ? (
													Array.isArray(this.props.selectedRowAssetDetails[0].compliance) ? (
														this.props.selectedRowAssetDetails[0].compliance.map((item, index) => {
															return (
																<td key={index} className='text-dark'>
																	{item.complianceId}
																</td>
															)
														})
													) : (
														<td className='text-dark'>
															{this.props.selectedRowAssetDetails[0].compliance &&
																this.props.selectedRowAssetDetails[0].compliance.complianceId}
														</td>
													)
												) : null}
											</td>
										</tr> */}
										<tr>
											<td className='text-primary-color ml-3'>Vpc ID</td>
											<td className='text-white'>{this.props.selectedRowAssetDetails[0].vpc_id}</td>
										</tr>
									</tbody>
								</table>
							</div>

							{this.props.selectedRowAssetDetails[0].action_by ? 
								<div className='user-details bg-dark mx-2'>
									{/* <span className='fa fa-id-card place-rb' /> */}
									<div className='d-flex position-relative flex-wrap'>
										<div className='text-left flex-grow-1 fs-13 mb-2'>
											{this.props.selectedRowAssetDetails[0].action_by ? (
												<p className='mb-1 text-white'>Created by 
													<span className='badge badge-outline-gray text-white mx-2'>{trimmedSplitUserName1(this.props.selectedRowAssetDetails[0].action_by)}</span> 
													<span className="text-info mx-2 font-weight-normal">{trimmedSplitUserName2(this.props.selectedRowAssetDetails[0].action_by)}</span> 
													on <span className="text-primary-color mx-2 font-weight-normal">{this.props.selectedRowAssetDetails[0].asset_time ? momentConvertionUtcToLocalTime(this.props.selectedRowAssetDetails[0].asset_time, 'DD MMM YYYY HH:mm') : ''}</span>
												</p>
											) : null}
										</div>
										<div className='text-left flex-grow-1 fs-13'>
											{this.props.selectedRowAssetDetails[0].action_by ? (
												<p className='mb-1 text-white'>Modified by 
													<span className='badge badge-outline-gray text-white mx-2'>{trimmedSplitUserName1(this.props.selectedRowAssetDetails[0].action_by)}</span> 
													<span className="text-info mx-2 font-weight-normal">{trimmedSplitUserName2(this.props.selectedRowAssetDetails[0].action_by)}</span> 
													on <span className="text-primary-color mx-2 font-weight-normal">{this.props.selectedRowAssetDetails[0].action_date ? momentConvertionUtcToLocalTime(this.props.selectedRowAssetDetails[0].action_date, 'DD MMM YYYY HH:mm') : ''}</span>
												</p>
											) : null}
										</div>
									</div>
								</div>
							: null
							}
						</div>
					) : this.props.showDetailsAssetSectionLoading &&
					  this.state.showAssetDetails ? (
						<div className='d-flex justify-content-center m-4'>
							<Spinner className='text-center' color='white' size='lg' />
						</div>
					) : !this.props.showDetailsAssetSectionLoading &&
					  !this.props.selectedRowAssetDetails.length &&
					  this.state.showAssetDetails ? (
						<div className='d-flex justify-content-center m-4'>
							<p>No asset details found</p>
						</div>
					) : null}

					{!this.props.showDetailsRelatedIssueLoading &&
					this.state.showRelatedIssues ? (
						<div
							className={`tab-pane related show active`}
							id='related'
							role='tabpanel'
							aria-labelledby='related-tab'
						>
							<div className='badge badge-light text-wrap text-left p-0 my-1 mx-2 fs-13 d-flex rounded overflow-hidden flex-column border-0 bg-dark mt-3'>
								{this.props.selectedTableRow && (this.props.selectedTableRow.service_name || this.props.selectedTableRow.asset_name) ?
									<code className='p-2 text-white align-self-center f12'>
										{this.props.selectedTableRow && this.props.selectedTableRow.service_name ?
											<span className="badge-square bg-muted p-2 text-wrap mr-2 f13"> {this.props.selectedTableRow.service_name}</span>
										: null
										}
										{this.props.selectedTableRow && this.props.selectedTableRow.asset_name ?
											<span>:&nbsp;{this.props.selectedTableRow.asset_name}</span>
										: null
										}
									</code>
								: null 
								}
								<div className='bg-dark-theme-green p-2 f12 text-center text-white'>Active</div>
							</div>
							<div className='d-flex justify-content-between p-2 mb-1'>
								<div className='w-65 float-left align-self-center pt-1'>
									<p className="mb-0 f12 text-white">Resource checks count grouped by status, risk
										{this.props.durationType !== '' ? 
											' Date : ' +this.props.durationType 
										: 
											DURATION_FILTER_OPTIONS.filter(e => e.value === this.props.relatedIssueDurtaion).length ? DURATION_FILTER_OPTIONS.filter(e => e.value === this.props.relatedIssueDurtaion)[0].option : this.props.relatedIssueDurtaion
										}
									</p>
								</div>
								<div className="select-sm-bg curvedDropdown minWidth120" toggle={() => this.toggleState("issueDurationFilter")}>
									<div className="dropdown">
										<span className="dropdown-toggle d-flex justify-content-between px-2 text-white" onClick={() => this.setState({ issueDurationFilter: !this.state.issueDurationFilter })}>
											{this.state.relatedDurtaion ? DURATION_FILTER_OPTIONS.filter(e => e.value === this.state.relatedDurtaion)[0].option : <span className="placeholder">Select</span>}
											<span className="caret"></span>
										</span>
										<ul className={`dropdown-menu p-0 ${this.state.issueDurationFilter ? "show" : ''}`}>
											{DURATION_FILTER_OPTIONS.map(item => {
												return (
													<li 
														onClick={() => this.setState({ 
															relatedDurtaion: item.value, 
															issueDurationFilter: false
														}, 
															() => this.props.getRelatedIssues(this.props.selectedTableRow, item.value) 
														)}
														className={`${this.state.relatedDurtaion === item.value ? "selected" :  ""}`}
													>
														{item.option}
													</li>
												)
											})}
										</ul>
									</div>
								</div>
								
								{/* <div className='w-35 float-right align-self-center'>
									<select
										className='customDropdown float-right'
										value={this.state.relatedDurtaion}
										onChange={event => {
											this.setState( {
												relatedDurtaion: event.target.value
											})
											this.props.getRelatedIssues(this.props.selectedTableRow, event.target.value)
										}}
									>
										{this.props.durationType !== '' ?
											<option key='select' value=''>Select</option>
										: null}

										{DURATION_FILTER_OPTIONS.map((item, index) => {
											return (
												<option key={index} value={item.value}>
													{item.option}
												</option>
											)
										})}
									</select>
								</div> */}
							</div>
							<div className="m-2">
								<div className='p-2 bg-dark rounded d-flex'>
									<div className='mr-2 mt-1 d-flex'>
										<div className='count border-success ml-3 mr-3'>
											<h5 className='text-white m-0'>{this.getRelatedIssuesPassCount()}</h5>
											<p className='text-white m-0'>
												<small>Pass</small>
											</p>
										</div>
										<div className='count border-danger'>
											<h5 className='text-white m-0'>{this.getRelatedIssuesFailCount()}</h5>
											<p className='text-white m-0'>
												<small>Fail</small>
											</p>
										</div>
									</div>
									<div className='d-flex bd-highlight ml-auto align-self-center pr-3'>
										<div className='mr-2'>
											<p className='mb-0'>
												<p className='m-2'>
													<small className='d-flex align-items-center'>
														<span className={`badge badge-pill risk-badge-low ${this.findRisk('Low') < 10 ? 'ml-2' : this.findRisk('Low') === 100 ? '' : 'ml-1'} mr-1`}>{this.findRisk('Low')}</span>
														Low
													</small>
												</p>
												<p className='m-2'>
													<small className='d-flex align-items-center'>
														<span className={`badge badge-pill  risk-badge-medium ${this.findRisk('Medium') < 10 ? 'ml-2' : this.findRisk('Medium') === 100 ? '' : 'ml-1'}  mr-1`}>{this.findRisk('Medium') }</span>
														Medium
													</small>
												</p>
											</p>
										</div>
										<div>
											<p className='mb-0'>
												<p className='m-2'>
													<small className='d-flex align-items-center'>
														<span className={`badge badge-pill  risk-badge-high ${this.findRisk('High') < 10 ? 'ml-2' : this.findRisk('High') === 100 ? '' : 'ml-1'} mr-1`}>{this.findRisk('High')}</span>
														High
													</small>
												</p>
												<p className='m-2'>
													<small className='d-flex align-items-center'>
														<span className={`badge badge-pill  risk-badge-critical ${this.findRisk('Critical') < 10 ? 'ml-2' : this.findRisk('Critical') === 100 ? '' : 'ml-1'} mr-1`}>{this.findRisk('Critical')}</span>
														Critical
													</small>
												</p>
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className='my-3 mx-2'>
								<div className='p-2 bg-dark rounded'>
									<p className='mb-0 text-white'>Trends</p>
									<p className='mb-2 f12'>Resource level checks pass / fail trend</p>
									<div className='p-2 bg-muted rounded'>
										{this.props.recentSeries && this.props.recentSeries.length ? (
											<div className='mt-n4 mb-n4 transparentTooltip'>
												<ApexChart type="area" series={this.props.recentSeries} options={this.props.recentOptions} height={120}/>
											</div>
										) : null}
									</div>
								</div>
							</div>
							
							{this.props.filteredRelatedIssuesData ? (
								<div className="p-2 bg-dark mx-2 rounded">
									<p className='mb-0 text-white'>Related Checks</p>
									{/* <p className='mb-2 f12'>Earum aut eos. Sed praesentium et hic voluptate ut.</p> */}
									<div className='w-100 d-flex mb-1 justify-content-between'>
										<div className='d-flex'>
											<div className="select-sm-bg curvedDropdown minWidth120" toggle={() => this.toggleState("issueStatusFilter")}>
												<div className="dropdown">
													<span className="dropdown-toggle d-flex justify-content-between px-2 text-white" onClick={() => this.setState({ issueStatusFilter: !this.state.issueStatusFilter })}>
														{this.state.selectedResourceStatus ? statusOption.filter(e => e.value === this.state.selectedResourceStatus)[0].label : <span className="placeholder">Status</span>}
														<span className="caret"></span>
													</span>
													<ul className={`dropdown-menu p-0 ${this.state.issueStatusFilter ? "show" : ''}`}>
														{statusOption.map(item => {
															return (
																<li 
																	onClick={() => this.setState({ 
																		selectedResourceStatus: item.value, 
																		issueStatusFilter: false
																	}, 
																		() => this.props.getRelatedTableData(this.state.selectedCategory, this.state.selectedResourceStatus, this.state.selectedStatus)
																	)}
																	className={`${this.state.selectedResourceStatus === item.value ? "selected" :  ""}`}
																>
																	{item.label}
																</li>
															)
														})}
													</ul>
												</div>
											</div>
											<div className="select-sm-bg curvedDropdown minWidth120 ml-2" toggle={() => this.toggleState("issueCategoryFilter")}>
												<div className="dropdown">
													<span className="dropdown-toggle d-flex justify-content-between px-2 text-white" onClick={() => this.setState({ issueCategoryFilter: !this.state.issueCategoryFilter })}>
														{this.state.selectedCategory ? this.state.selectedCategory : <span className="placeholder">Category</span>}
														<span className="caret"></span>
													</span>
													<ul className={`dropdown-menu p-0 ${this.state.issueCategoryFilter ? "show" : ''}`}>
														<li 
															onClick={() => this.setState({ 
																selectedResourceStatus: "", 
																issueCategoryFilter: false
															}, 
																() => this.props.getRelatedTableData(this.state.selectedCategory, this.state.selectedResourceStatus, this.state.selectedStatus)
															)}
															className={`${this.state.selectedResourceStatus === "" ? "selected" :  ""}`}
														>
															All
														</li>
														{this.props.categoryCounts &&
														this.props.categoryCounts.length &&
														this.props.categoryCounts.map(item => {
															return (
																<li 
																	onClick={() => this.setState({ 
																		selectedCategory: item.category, 
																		issueCategoryFilter: false
																	}, 
																		() => this.props.getRelatedTableData(this.state.selectedCategory, this.state.selectedResourceStatus, this.state.selectedStatus)
																	)}
																	className={`${this.state.selectedCategory === item.category ? "selected" :  ""}`}
																>
																	{item.category}
																</li>
															)
														})}
													</ul>
												</div>
											</div>
										</div>
										<div className='d-flex'>
											<div className="align-self-center">
												<span onClick={() => this.setState({ selectedStatus: this.state.selectedStatus === 'pass' ? '' : 'pass' },this.props.getRelatedTableData(this.state.selectedCategory, this.state.selectedResourceStatus, this.state.selectedStatus === 'pass' ? '' : 'pass' )) } className={`cursor-pointer badge mr-2 ${this.state.selectedStatus === 'pass' ? ' badge-outline-pass' : ' badge-outline-pass'} `}>Pass</span>
											</div>
											<div className="align-self-center">
												<span onClick={() => this.setState({ selectedStatus: this.state.selectedStatus === 'fail' ? '' : 'fail' }, this.props.getRelatedTableData(this.state.selectedCategory, this.state.selectedResourceStatus, this.state.selectedStatus === 'fail' ? '' : 'fail' )) } className={`cursor-pointer badge mr-2 ${this.state.selectedStatus === 'fail' ? ' badge-outline-fail' : ' badge-outline-fail'}`}>Fail</span>
											</div>
										</div>
									</div>									
									
									<div className={`mt-3`}> {/* checkRelatedIssue */}
										<ResizeableDarkThemeTable
											columns={[
												{
													Header: ' ',
													accessor: 'risk',
													Cell: cellInfo => (
														<div className={`risk-line-no-position risk-bg-${cellInfo.row.original.risk ? cellInfo.row.original.risk.toLowerCase() : ''}`} />
													),
													width: 10
												},
												{
													Header: 'Date',
													accessor: 'timestamp',
													Cell: cellInfo => momentConvertionUtcToLocalTime(cellInfo.row.original.asset_time, 'DD MMM YYYY HH:mm'),
													width: 120
												},
												{
													Header: 'Title',
													accessor: 'title',
													Cell: cellInfo => (
														<span className='pl-2'>{cellInfo.row.original.title}</span>
													),
												},
												/* {
													Header: 'Category',
													accessor: d =>
														Array.isArray(d.category)
															? d.category.map((item, index) => (d.category.length - 1 === index ? item : item + ', '))
															: d.category,
												}, */
												{
													Header: 'Status',
													//accessor: 'status',
													Cell: cellInfo => (
														<React.Fragment>
															<span className='pl-2'>{cellInfo.row.original.resolution_status_info ? capitalizeFirstLetter(cellInfo.row.original.resolution_status_info.status) : 'Resolved'}</span>
								
														</React.Fragment>
													),
													width: 80
												},
												{
													Header: 'Action',
													Cell: cellInfo => (
													<div className="align-self-center">
														<div className={`btn-group dropleft ${this.state.selectedTableDrop === cellInfo.row.id ? "position-absolute" : ""}`}>
															<div className={`rounded-circle px-2 py-1 cursorPointer ${this.state.selectedTableDrop === cellInfo.row.id ? 'bg-muted' : 'bg-black6'} `} onClick={ () => this.handleClickGroup(cellInfo.row.id) }>
																<i className="fa fa-ellipsis-v text-secondary"></i>
															</div>
															<div className={`dropdown-menu ${this.state.selectedTableDrop === cellInfo.row.id ? 'show active' : ''} `}>
																<i className="fa fa-caret-up"></i>
																{cellInfo.row.original.resolution_status_info && cellInfo.row.original.resolution_status_info.status === 'pending' ?
																	<button className="dropdown-item" type="button" 
																		onClick={ () => this.setState({selectedTableDrop: '' },
																			() => this.props.openCloseModal(cellInfo.row.original, this.props.relatedDurtaion) 
																		)}
																	>Close</button>
																:
																	<button className="dropdown-item" type="button">{cellInfo.row.original.resolution_status_info ? capitalizeFirstLetter(cellInfo.row.original.resolution_status_info.status) : capitalizeFirstLetter(cellInfo.row.original.status)}
																	</button>
																}
															</div>
														</div>
													</div>
													),
													width: 80												
												}
											]}
											data={this.props.filteredRelatedIssuesData}
											tooltipFor={[1]}
											riskTooltip={[0]}
											dashboard={this.props.filteredRelatedIssuesData.length ? true : false }
											perPage={10}
											// rounded={'rounded'}
											tableSize={'table-xs'}
											// tableHead={'bg-primary'}
											// selectedColumn={'bg-light'}
											columnFontSize={'f10'}
											onClickRow={tableRow => {}}
										/>
									</div>
								</div>
							) : null}
						</div>
					) : this.props.showDetailsRelatedIssueLoading &&
					  this.state.showRelatedIssues ? (
						<div className='d-flex justify-content-center m-4'>
							<Spinner className='text-center' color='white' size='lg' />
						</div>
					) : !this.props.showDetailsRelatedIssueLoading &&
					  this.state.showRelatedIssues ? (
						<div className='d-flex justify-content-center m-4'>
							<p>No related issues found</p>
						</div>
					) : null}

					{!this.props.showDetailsEventsSectionLoading &&
					this.state.showEvents ? (
						<div className={`tab-pane fade ${this.state.showEvents ? 'show active' : ''}`} id='monitoring'>
							<React.Fragment>
								<div className='badge badge-light text-wrap text-left p-0 m-2 fs-13 d-flex rounded overflow-hidden flex-column border-0 bg-dark mt-3'>
									{this.props.selectedTableRow && (this.props.selectedTableRow.service_name || this.props.selectedTableRow.asset_name) ?
										<code className='p-2 text-white align-self-center f12'>
											{this.props.selectedTableRow && this.props.selectedTableRow.service_name ?
												<span className="badge-square bg-muted p-2 text-wrap mr-2 f13"> {this.props.selectedTableRow.service_name}</span>
											: null
											}
											{this.props.selectedTableRow && this.props.selectedTableRow.asset_name ?
												<span>:&nbsp;{this.props.selectedTableRow.asset_name}</span>
											: null
											}
										</code>
									: null 
									}
									<div className='bg-dark-theme-green p-2 f12 text-center text-white'>Active</div>
								</div>
								{this.props.eventSeries && this.props.eventSeries.length ? (
									<div className='mt-3 mx-2'>
										<div className='p-2 bg-dark rounded'>
											<p className='mb-0 text-white'>Trends</p>
											<p className='mb-2 f12'>Resource level events trend</p>
											<div className='p-2 bg-muted rounded'>
													<div className='mt-n4 mb-n4 transparentTooltip'>
														<ApexChart type="area" series={this.props.eventSeries} options={this.props.eventOptions} height={120}/>
													</div>
											</div>
										</div>
									</div>
								) : null}
								{this.props.topEventsData && Object.keys(this.props.topEventsData).length ? (
									<div className='mt-3 mx-2'>
										<div className='p-2 bg-dark rounded'>
											<b className='mb-0 text-white'>Top Events</b>
											{/* <p className='mb-2 f12'><small>Earum aut eos. Sed praesentium et hic voluptate itaque ut.</small></p> */}
										
											<div className={`${this.props.topEventsData.results && this.props.topEventsData.results.length ? "row" : ""} lineHeight5`}>
												{this.props.topEventsData.results && this.props.topEventsData.results.length ? 
													this.props.topEventsData.results.map((item, i) => {
														let totalCount =  this.props.eventGraphData ? this.props.eventGraphData.total_events : 0
														let progressPercentage = ((item.event_count * 100) / totalCount) +'%'
														return (
															<React.Fragment key={i}>
																<div className='col-sm-5 text-right'>
																	<p className="f10 text-white">{item.last_event_name}</p>
																</div>
																<div className="col-sm-5 d-flex float-left ml15">
																	{totalCount > 0 ?
																		<div className='stock-progress'>
																			<div className='stock-1' style={{width:progressPercentage}} id={`eventProgress-${i}`}></div>
																			<UncontrolledTooltip placement='top' target={`eventProgress-${i}`}>{item.event_count}</UncontrolledTooltip>
																		</div>
																		: 
																		<div className='stock-progress'>
																			<div className='stock-5'></div>
																		</div>
																	}
																</div>
																<div className="col-sm-2 text-left"><small>{totalCount}</small></div>
															</React.Fragment>
														)
													})
												:
													<div className='d-flex justify-content-center m-4'>
														<p>No top events found</p>
													</div>
												}
											</div>
										</div>
									</div>
								) : null}

							<div className='mt-3 mx-2'>
								<div className='p-2 bg-dark rounded'>
									<p className='mb-0 text-white'>Total Events </p>
									{this.props.recentEventsData && this.props.recentEventsData.length ?
										<React.Fragment>
										<p className='mb-2 f12'>
											<small>Total of {this.props.eventGraphData && this.props.eventGraphData.total_events && this.props.eventGraphData.total_events} events have occured in this resource during this period..</small>
										</p>
										<div className={`mr-1`}> {/* className removed  assetDetailsSection */}
											<ResizeableDarkThemeTable
												columns={[
													{
														Header: 'Event',
														accessor: 'action',
														width: 120
													},
													{
														Header: 'Date',
														accessor: 'action_date',
														Cell: cellInfo => momentConvertionUtcToLocalTime(cellInfo.row.original.last_action_date ? cellInfo.row.original.last_action_date : cellInfo.row.original.action_date, 'DD MMM YYYY HH:mm'),
														width: 120
													},
													{
														Header: 'Action By',
														accessor: 'action_by',
														width: 180
													},
												]}
												
												data={this.props.recentEventsData}
												tooltipFor={[0, 1, 2]}
												riskTooltip={[]}
												dashboard={this.props.recentEventsData.length ? true : false }
												perPage={10}
												// rounded={'rounded'}
												tableSize={'table-xs'}
												// tableHead={'bg-primary'}
												// selectedColumn={'bg-light'}
												columnFontSize={'f10'}
												onClickRow={tableRow => {}}
											/>
										</div>
										</React.Fragment>
									: 
										<div className='d-flex justify-content-center m-4'>
											<p>No total events found</p>
										</div>
									}
								</div>
							</div>
							</React.Fragment>
						</div>
					) : this.props.showDetailsEventsSectionLoading &&
					  this.state.showEvents ? (
						<div className='d-flex justify-content-center m-4'>
							<Spinner className='text-center' color='white' size='lg' />
						</div>
					) : !this.props.showDetailsEventsSectionLoading &&
					  this.state.showEvents ? (
						<div className='d-flex justify-content-center m-4'>
							<p>No events found</p>
						</div>
					) : null}

					{/* Billing Section */}
					{!this.props.showDetailsBillingSectionLoading &&
					this.state.showBillingDetails &&
					!this.state.showEvents &&
					!this.state.showAssetDetails &&
					!this.state.showAvailabilityDetails &&
					!this.state.showMonitoringDetails &&
					!this.state.showRelatedIssues ? (
						<div
							className={`tab-pane fade show active`}
							id='billing'>
							<div className='badge badge-light text-wrap text-left p-0 m-2 fs-13 d-flex rounded overflow-hidden flex-column border-0 bg-dark mt-3'>
								{this.props.selectedTableRow && (this.props.selectedTableRow.service_name || this.props.selectedTableRow.asset_name) ?
									<code className='p-2 text-white align-self-center f12'>
										{this.props.selectedTableRow && this.props.selectedTableRow.service_name ?
											<span className="badge-square bg-muted p-2 text-wrap mr-2 f13"> {this.props.selectedTableRow.service_name}</span>
										: null
										}
										{this.props.selectedTableRow && this.props.selectedTableRow.asset_name ?
											<span>:&nbsp;{this.props.selectedTableRow.asset_name}</span>
										: null
										}
									</code>
								: null 
								}
								<div className='bg-dark-theme-green p-2 f12 text-center text-white'>Active</div>
							</div>
						
							{this.props.selectedRowBillingDetails &&
							this.props.selectedRowBillingDetails.length ? (
								<React.Fragment>
									<div className='card m-2 border-0'>
										<table className='table table-borderless billing-side-table my-2'>
											{/* <thead>
												<tr>
													<th />
													<th />
												</tr>
											</thead> */}
											<tbody>
												<tr className='bg-light'>
													<th className='text-muted align-middle'>Current Month</th>
													<td>
														<div className='d-flex justify-content-end text-success'>
															<span className='align-self-center fs-13 mr-2'>$</span>
															<h3 className='m-0'>{this.billingCurrentMonthCost()}</h3>
															<i className='far fa-angle-up align-self-center ml-2 fa-2x' />
															<span className='align-self-center ml-2'>5%</span>
														</div>
													</td>
												</tr>
												<tr>
													<th className='text-muted align-middle'>Last Month</th>
													<td>
														<div className='d-flex justify-content-end text-danger'>
															<span className='align-self-center fs-13 mr-2'>$</span>
															<h3 className='m-0'> {this.billingLastMonthCost()}</h3>
															<i className='far fa-angle-down align-self-center ml-2 fa-2x' />
															<span className='align-self-center ml-2'>5%</span>
														</div>
													</td>
												</tr>
												<tr>
													<th className='text-muted align-middle'>Last 3 Months</th>
													<td>
														<div className='d-flex justify-content-end text-danger'>
															<span className='align-self-center fs-13 mr-2'>$</span>
															<h3 className='m-0'>{this.billingLastThreeMonthCost()}</h3>
															<i className='far fa-angle-down align-self-center ml-2 fa-2x' />
															<span className='align-self-center ml-2'>5%</span>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</React.Fragment>
							) : 
								<div className='d-flex justify-content-center m-4'>
									<p>No billing data found</p>
								</div>
							}
						</div>
					) : !this.props.showDetailsBillingSectionLoading &&
						this.state.showBillingDetails &&
						!this.state.showAssetDetails &&
						!this.state.showMonitoringDetails &&
						!this.state.showRelatedIssues &&
						!this.state.showAvailabilityDetails &&
						!this.state.showEvents ? (
							<div className='d-flex justify-content-center m-4'>
								<Spinner className='text-center' color='white' size='lg' />
							</div>
					) : !this.props.showDetailsBillingSectionLoading &&
						!this.props.selectedRowBillingDetails.length &&
						!this.state.showAssetDetails &&
						!this.state.showMonitoringDetails &&
						!this.state.showRelatedIssues &&
						!this.state.showAvailabilityDetails &&
						!this.state.showEvents &&
						this.state.showBillingDetails ? (
							<div className='d-flex justify-content-center m-4'>
								<p>No billing data found</p>
							</div>
						) : null
					}

					<div className={`tab-pane fade ${this.state.showMonitoringDetails ? 'show active' : ''}`} id='monitoring' role='tabpanel' aria-labelledby='monitoring-tab'>
						<div className='badge badge-light text-wrap text-left p-0 m-2 fs-13 d-flex rounded overflow-hidden flex-column border-0 bg-dark mt-3'>
							{this.props.selectedTableRow && (this.props.selectedTableRow.service_name || this.props.selectedTableRow.asset_name) ?
								<code className='p-2 text-white align-self-center f12'>
									{this.props.selectedTableRow && this.props.selectedTableRow.service_name ?
										<span className="badge-square bg-muted p-2 text-wrap mr-2 f13"> {this.props.selectedTableRow.service_name}</span>
									: null
									}
									{this.props.selectedTableRow && this.props.selectedTableRow.asset_name ?
										<span>:&nbsp;{this.props.selectedTableRow.asset_name}</span>
									: null
									}
								</code>
							: null 
							}
							<div className='bg-dark-theme-green p-2 f12 text-center text-white'>Active</div>
						</div>
						
						<div className="p-2">
							{!this.props.showDetailsMonitoringSectionLoading && this.state.showMonitoringDetails && (
								<div className="d-flex justify-content-end">
									{this.state.showMonitoringDetails && this.props.selectedRowMonitoringSnapshot &&
										Object.entries(this.props.selectedRowMonitoringSnapshot).length ? (
											<div className='w-70 float-left align-self-center pt-1 text-info'>
												<small>
													From:{' '}
													<b className='mr-2'>{this.props.selectedRowMonitoringSnapshot.start_time ? momentConvertionUtcToLocalTime(this.props.selectedRowMonitoringSnapshot.start_time, 'DD MMM YYYY HH:mm') : ''}</b>{' '}
													To:{' '}
													<b>{this.props.selectedRowMonitoringSnapshot.end_time ? momentConvertionUtcToLocalTime(this.props.selectedRowMonitoringSnapshot.end_time, 'DD MMM YYYY HH:mm') : ''}</b>
												</small>
											</div>
										)
										: null
									}
									<div className="select-sm-bg curvedDropdown minWidth120" toggle={() => this.toggleState("monitDurationFilter")}>
										<div className="dropdown">
											<span className="dropdown-toggle d-flex justify-content-between px-2 text-white" onClick={() => this.setState({ monitDurationFilter: !this.state.monitDurationFilter })}>
												{this.state.monitoringDuration ? monitoringDurationOptions.filter(e => e.value === this.state.monitoringDuration)[0].label : <span className="placeholder">Duration</span>}
												<span className="caret"></span>
											</span>
											<ul className={`dropdown-menu p-0 ${this.state.monitDurationFilter ? "show" : ''}`}>
												{monitoringDurationOptions.map(item => {
													return (
														<li 
															onClick={() => this.setState({ 
																monitoringDuration: item.value, 
																monitDurationFilter: false
															}, 
																() => this.props.getMonitoringSnap(this.props.selectedTableRow, this.state.monitoringDuration)
															)}
															className={`${this.state.monitoringDuration === item.value ? "selected" :  ""}`}
														>
															{item.label}
														</li>
													)
												})}
											</ul>
										</div>
									</div>
								</div>
							)}
							{!this.props.showDetailsMonitoringSectionLoading &&
							this.state.showMonitoringDetails && this.props.selectedRowMonitoringSnapshot &&
							Object.entries(this.props.selectedRowMonitoringSnapshot).length ? 
								<div className="p-3 rounded bg-dark3 mt-3">
									{this.props.selectedRowMonitoringSnapshot.response && this.props.selectedRowMonitoringSnapshot.response.length ?
										this.props.selectedRowMonitoringSnapshot.response.map((item, index) => {
											return (
												<div className="row">
													{Object.keys(item.data).map((key, i) => {
														return (
															// <div className={`col-sm-6 mb-3`}>
															<div className={`col-sm-12 mb-3`}>
																<div class="p-3 border rounded">
																	<p class="text-white f16 p-1 mb-0">{key}</p>
																	{this.state[key+'_metrics_series_'+index+'_'+i] && this.state[key+'_metrics_series_'+index+'_'+i].length && this.state[key+'_metrics_options_'+index+'_'+i] ?
																		<div className="cursorPointer transparentTooltip mb-n4 mt-n4">
																			<ApexChart options={this.state[key+'_metrics_options_'+index+'_'+i]} series={this.state[key+'_metrics_series_'+index+'_'+i]} type="area" height={115}/>
																		</div>
																	: 
																		<div style={{minHeight:"85px"}} className="d-flex align-self-center justify-content-center">
																			<div className='align-self-center'>
																				No Data
																			</div>
																		</div>
																	}
																</div>
															</div>
														)
													})}
												</div>
											)
										})
									: 
										<div className='w-100 d-flex justify-content-center m-4'>
											<p>No monitoring details found</p>
										</div>
									}
								</div>
							: 
								this.props.showDetailsMonitoringSectionLoading &&
								!this.state.showAssetDetails &&
								!this.state.showBillingDetails &&
								!this.state.showRelatedIssues &&
								!this.state.showAvailabilityDetails &&
								!this.state.showEvents &&
								this.state.showMonitoringDetails ? (
									<div className='d-flex justify-content-center m-4'>
										<Spinner className='text-center' color='white' size='lg' />
									</div>
								) 
							: 
								!this.props.showDetailsMonitoringSectionLoading &&
								!Object.entries(this.props.selectedRowMonitoringSnapshot).length &&
								this.state.showMonitoringDetails &&
								!this.state.showAssetDetails &&
								!this.state.showBillingDetails &&
								!this.state.showRelatedIssues &&
								!this.state.showAvailabilityDetails &&
								!this.state.showEvents ? (
									<div className='w-100 d-flex justify-content-center m-4'>
										<p>No monitoring details found</p>
									</div>
								)
							: null }
						
						</div>
					</div>

					{!this.props.showDetailsAvailSectionLoading &&
					this.state.showAvailabilityDetails &&
					!this.state.showEvents &&
					!this.state.showAssetDetails &&
					!this.state.showBillingDetails &&
					!this.state.showRelatedIssues &&
					!this.state.showMonitoringDetails ? (
						<div
							className={`tab-pane fade show active}`}
							id='availability'
							role='tabpanel'
							aria-labelledby='availability-tab'>
								<div className='badge badge-light text-wrap text-left p-0 m-2 fs-13 d-flex rounded overflow-hidden flex-column border-0 bg-dark mt-3'>
									{this.props.selectedTableRow && (this.props.selectedTableRow.service_name || this.props.selectedTableRow.asset_name) ?
										<code className='p-2 text-white align-self-center f12'>
											{this.props.selectedTableRow && this.props.selectedTableRow.service_name ?
												<span className="badge-square bg-muted p-2 text-wrap mr-2 f13"> {this.props.selectedTableRow.service_name}</span>
											: null
											}
											{this.props.selectedTableRow && this.props.selectedTableRow.asset_name ?
												<span>:&nbsp;{this.props.selectedTableRow.asset_name}</span>
											: null
											}
										</code>
									: null 
									}
									<div className='bg-dark-theme-green p-2 f12 text-center text-white'>Active</div>
								</div>
								{this.state.filterAvailabilityData.length &&
								<React.Fragment>
									<div className='mt-3'>
										<b className='mb-1 ml-3 mt-3 text-black'>Heat Map</b>
									</div>
									<p className='mb-2 ml-3'>
										<small>Earum aut eos. Sed praesentium et hic voluptate itaque ut.</small>
									</p>
									<div className='mt-3 mb-4 pl-2 pr-2 assetHeatMap'>
										<div className="d-flex justify-content-between f12 mt-3 position-relative mb-2">
											<div className={`cursorPointer ${this.state.disablePreviousBtn ? 'disabled text-muted': 'text-info'}`}onClick={() => this.setState({ filterAvailabilityData: [], selectedAvailabilityMonth:(parseInt(this.state.month)-parseInt(1)) }, () => this.filterAvailabilityDetails('previous'))}><span className="far fa-angle-double-left"></span> Previous </div>
											{/* <div className="react-Calendar-month-name">{this.state.monthName && this.state.monthName}</div> */}
											<div className={`cursorPointer ${this.state.disableNextBtn ? 'disabled text-muted': 'text-info'}`} onClick={() => this.setState({ filterAvailabilityData:[], selectedAvailabilityMonth:(parseInt(this.state.month)+parseInt(1)) }, () => this.filterAvailabilityDetails('next'))}>Next <span className="far fa-angle-double-right"></span></div>
										</div>
										{this.state.filterAvailabilityData && this.state.filterAvailabilityData.length ?
											<React.Fragment>
											<div className={`m-auto ${this.state.heatDuration === 1 ? this.state.week === 6 ? 'w-30' : 'w-25' : this.state.heatDuration === 2 ? 'w-55' : 'w-70' } `}>
												<CalendarHeatmap
													startDate={this.state.startDate}
													endDate={this.state.endDate}
													values={this.state.filterAvailabilityData}
													classForValue={value => {
														if (!value) {
															return 'text-fill-empty';
														} else if(value.availability <= 20) {
															return `text-fill-color-1`;
														} else if(value.availability > 20 &&value.availability <= 30) {
															return `text-fill-color-2`;
														} else if(value.availability > 30 &&value.availability <= 40) {
															return `text-fill-color-3`;
														} else if(value.availability > 40) {
															return `text-fill-color-4`;
														}
													}}
													tooltipDataAttrs={value => {
														if(value.date != null) {
															return {
																'data-tip': `<p style="width:100%;margin-bottom:5px"><b>Day</b>:&nbsp; ${momentConvertionUtcToLocalTime(value.date, 'DD MMM YY')} </p>
																<p style="width:100%;margin-bottom:5px"><b>Availability</b>:&nbsp; ${value.availability}% </p>
																<p style="width:100%;margin-bottom:5px"><b>CPU</b>:&nbsp; Average - ${value.cpu_average}% &emsp; Min - ${value.cpu_min}% &emsp; Max - ${value.cpu_max}%  </p> 
																<p style="width:100%;margin-bottom:5px"><b>Memory</b>:&nbsp; Average - ${value.memory_average}% &emsp; Min - ${value.memory_min}% &emsp; Max - ${value.memory_max}% </p>
																<p style="width:100%;margin-bottom:5px"><b>Disk Usage</b>:&nbsp; ${value.disk_usage}% </p>`
															};
														} else {
															return {
																'data-tip': 'No data'
															};
														}
													}}
													//showOutOfRangeDays={true}
													horizontal={true}
													showMonthLabels={true}
													showWeekdayLabels={false}
													gutterSize={2}
												/>
												<ReactTooltip html={true}/>
												
											</div>
											<div className='assetHeatMapLegend'> 
												{/* <div className="assetHeatMapHeader">% Structural <br />Components in SGR</div> */}
												<ul>
													<li>
														<div className="assetHeatMapBox text-fill-empty"></div> No Data
													</li>
													<li>
														<div className="assetHeatMapBox text-fill-color-1"></div> &lt; 20
													</li>
													<li>
														<div className="assetHeatMapBox text-fill-color-2"></div> 21 - 30
													</li>
													<li>
														<div className="assetHeatMapBox text-fill-color-3"></div> 31 - 40
													</li>
													<li>
														<div className="assetHeatMapBox text-fill-color-4"></div> &gt; 40
													</li>
												</ul>
											</div>
											</React.Fragment>
										: !this.state.filterAvailabilityData.length &&
											<div className='d-flex justify-content-center m-4'>
												<Spinner className='text-center' color='white' size='lg' />
											</div> 
										}

									</div>
								</React.Fragment>
								}
						</div>
					) : !this.props.showDetailsAvailSectionLoading && this.state.showAvailabilityDetails && !this.state.filterAvailabilityData.length ? (
							<div className='d-flex justify-content-center m-4'>
								<Spinner className='text-center' color='white' size='lg' />
							</div>
					) :
						!this.state.showDetailsAvailSectionLoading && 
						this.state.showAvailabilityDetails &&
						!Object.entries(this.props.selectedRowMonitoringSnapshot).length &&
						!this.state.showAssetDetails &&
						!this.state.showBillingDetails &&
						!this.state.showEvents &&
						!this.state.showMonitoringDetails &&this.state.filterAvailabilityData ?
							<div className='d-flex justify-content-center m-4'>
								<p>No Availability details found</p>
							</div>
						: null
					}
				</div>
			</React.Fragment>
		)
	}
}
/**
 * Type of the props used in the component
 */
DetailsSection.propTypes = {
	selectedTableRow: PropTypes.object,
	detailsTabNames: PropTypes.array,
	getAssetDetails: PropTypes.func,
	getRelatedIssues: PropTypes.func,
	getBillingSnap: PropTypes.func,
	getMonitoringSnap: PropTypes.func,
	selectedDetailsTab: PropTypes.number,
	setSelectedDetailsTabIndex: PropTypes.func,
	showDetailsTabListDropdown: PropTypes.bool,
	toggleDetailsTabListDropdown: PropTypes.func,
	onClickDetailsTabDropDownItem: PropTypes.func,
	toggleDetailsSectionLoading: PropTypes.func,
	selectedRowAssetDetails: PropTypes.array,
	selectedRowBillingDetails: PropTypes.array,
	showDetailsMonitoringSectionLoading: PropTypes.bool,
	showDetailsAssetSectionLoading: PropTypes.bool,
	showDetailsRelatedIssueLoading: PropTypes.bool,
	showDetailsEventsSectionLoading: PropTypes.bool,
	showDetailsBillingSectionLoading: PropTypes.bool,
	showSuppressListLoading: PropTypes.bool,
	showRelatedIssues: PropTypes.bool,
	relatedIssuesData: PropTypes.array,
	selectedRowMonitoringSnapshot: PropTypes.object,
	dayWiseCountGraphData: PropTypes.object,
	recentEventsData: PropTypes.array,
	topEventsData: PropTypes.object,
	eventGraphData: PropTypes.object,
	getAssetEvents: PropTypes.func,
	selectedMonitoringDuration: PropTypes.string,
	getDailyAssetAvailability: PropTypes.func
}
//export default DetailsSection

export default connect(null, {
	getDailyAssetAvailability
})(withRouter(DetailsSection))