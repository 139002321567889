/*************************************************
 * Tvastar
 * @exports
 * @file trendAction.js
 * @author prakash
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import { API} from 'aws-amplify'
 
import {
    DLPENDPOINT,
    GET_DLP_FILTER_PROPERTIES,
    START_DATA_EVENTS_DAILY_TREND,
    GET_DATA_EVENTS_DAILY_TREND,
} from '../../config'
import { ACTION_TYPES } from '../types'

export const getDlpFilterProperties = (body, callback) => {
    return async dispatch => {
        try {
            const trendFilter = await API.post(DLPENDPOINT, GET_DLP_FILTER_PROPERTIES, { body })
            dispatch({
                type: ACTION_TYPES.SET_DLP_FILTER_PROPERTIES,
                payload: trendFilter,
            })
            callback(true, trendFilter)
        } catch (error) {
            dispatch({
                type: ACTION_TYPES.SET_DLP_FILTER_PROPERTIES,
                payload: [],
            })
            callback(false, [])
        }
    }
} 
export const startDataEventsDailyTrend = (body, callback) => {
    return async dispatch => {
        try {
            const startTrend = await API.post(DLPENDPOINT, START_DATA_EVENTS_DAILY_TREND, { body })
            dispatch({
                type: ACTION_TYPES.SET_START_DAILY_TREND,
                payload: startTrend,
            })
            callback(true)
        } catch (error) {
            dispatch({
                type: ACTION_TYPES.SET_START_DAILY_TREND,
                payload: {},
            })
            callback(false)
        }
    }
}

export const getDataEventsDailyTrend = (body, callback) => {
    return async dispatch => {
        try {
            const getTrend = await API.post(DLPENDPOINT, GET_DATA_EVENTS_DAILY_TREND, { body })
            if(Object.entries(getTrend).length) {
                if(getTrend.status === 'FAILED' || getTrend.status === 'CANCELLED' || getTrend.status === 'SUCCEEDED') {
                    dispatch({
                        type: ACTION_TYPES.SET_START_DAILY_TREND,
                        payload: {},
                    })
                }
            }
            dispatch({
                type: ACTION_TYPES.SET_DATA_DAILY_TREND,
                payload: getTrend,
            })
            callback(true, getTrend)
        } catch (error) {
            dispatch({
                type: ACTION_TYPES.SET_DATA_DAILY_TREND,
                payload: {},
            })
            callback(false, {})
        }
    }
}
