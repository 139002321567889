/*************************************************
 * Tvastar
 * @exports
 * @file commonReducer.js
 * @author Rajasekar // on 31/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from '../actions/types'

const INITIAL_STATE = {
	providers: [],
	accounts: [],
	accountsDetailsList: [],
	regions: [],
	services: [],
	searchMetaList: [],
	profileDetails: {},
	userMenu: [],
	automationPropsDetails: {},
	tagKeys: []
}

/**
 * To store filters list in redux store
 * @param {Object} state
 * @param {Object} action
 */
export const filtersReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ACTION_TYPES.SET_PROVIDERS_LIST:
			return {
				...state,
				providers: action.payload,
			}
		case ACTION_TYPES.SET_ACCOUNTS_LIST:
			return {
				...state,
				accounts: action.payload,
			}
		case ACTION_TYPES.SET_ACCOUNTS_DETAILS_LIST:
			return {
				...state,
				accountsDetailsList: action.payload,
			}
		case ACTION_TYPES.SET_REGIONS_LIST:
			return {
				...state,
				regions: action.payload,
			}
		case ACTION_TYPES.SET_SERVICES_LIST:
			return {
				...state,
				services: action.payload,
			}
		case ACTION_TYPES.SET_SEARCH_META_LIST:
			return {
				...state,
				searchMetaList: action.payload,
			}
		case ACTION_TYPES.SET_RESOURCE_SEARCH_META_LIST:
			return {
				...state,
				searchMetaList: action.payload,
			}
		case ACTION_TYPES.SET_LIST_OPERATING_SYSTEM:
			return {
				...state,
				operatingSystemList: action.payload,
			}
		case ACTION_TYPES.SET_PROFILE_DETAILS:
			return {
				...state,
				profileDetails: action.payload,
			}
		case ACTION_TYPES.SET_USER_MENU:
			return {
				...state,
				userMenu: action.payload,
			}			
		case ACTION_TYPES.SET_AUTOMATION_PROPS_DETAILS:
			return {
				...state,
				automationPropsDetails: action.payload
			}
		case ACTION_TYPES.SET_TAG_KEYS:
			return {
				...state,
				tagKeys: [ ...action.payload],
			}
		default:
			return state
	}
}
