/*************************************************
 * Tvastar
 * @exports
 * @file ViewSidePanel.js
 * @author Prakash // on 113/02/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _, { filter } from 'lodash'
// import PropTypes from 'prop-types'
import { Spinner, Input } from 'reactstrap'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

import { store as CommonNotification } from 'react-notifications-component';

import { getAccountNameFromId, capitalizeFirstLetter, onlyNumeric, momentConvertionUtcToLocalTime } from '../../../utils/utility'
import SearchWithHiddenInput from '../../common/SearchWithHiddenInput'
import { listAllAccounts, listAllRegions, getAllTagsKeys, getAllTagsValues } from '../../../actions/commonAction'
import { setCostPropsDetails, startPowerTuning } from '../../../actions/cost/CostAction'

class ViewSidePanel extends Component {
	constructor(props) {
		super(props)
        this.toggleRef = React.createRef()
		this.state = {
            minMaxHeight: 700,
			showDetails: true
		}
	}

	componentDidMount = () => {
		document.addEventListener('keydown', this._handleKeyDown)
		this.getWindowSize()
		window.addEventListener('resize', () => {
            this.getWindowSize()
        })

		this.getAllTagsKeys()
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
        let headerHeihgt = 100
		let outerHeight = (window.outerHeight - browserBorder - headerHeihgt)

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }

	_handleKeyDown = event => {
		if(this.state.onSaveFunction) {
			switch (event.keyCode) {
				case 27:
					this.props.closeSidePanel()
					break
				default:
					break
			}
		}
	}

    handleClickOutside(event) {}

	getAllTagsKeys = () => {
		
		if(!this.props.tagKeys || !this.props.tagKeys.length) {
			let params = {}
			// params.account_id = "265469181489"
			// params.region = "us-east-2"
			this.props.getAllTagsKeys(params, (promise, response) => {})
		}
	}

	getAllTagsValues = () => {
		let params = {}
		params.tags = [{ "key": this.state.tag_key }]
		this.props.getAllTagsValues(params, (promise, response) => {
			if(promise) {
				let result = response
				if(response.length) {
					result.unshift("All");
				}
				this.setState({ tagValues: response })
			}
		})
	}
	
	handleMultiSelectChange = (field, arrayValue, state) => {
		let value = arrayValue.map(item => item.value ? item.value : item)
		let selectedValue = []
		let prevState = this.state[field] ? this.state[field] : []
		if(prevState.length === 1 && prevState[0] === "All") {
			prevState = []
		}
		if(value.includes("All")) {
			if(!prevState.includes("All")) {
				if(state.filter(e => e.value).length) {
					selectedValue = state.map(e => e.value)
				} else {
					selectedValue = state
				}
			} else {
				const index = value.indexOf("All");
				if (index > -1) {
					value.splice(index, 1);
				}
				selectedValue = value
			}
		} else if(!prevState.includes("All")) {
			selectedValue = value
		}

		if(selectedValue.length && !selectedValue.includes("All")) {
			if(!selectedValue.includes("All") && selectedValue.length === (state.length -1)) {
				selectedValue.push("All")
			}
		}
		
		this.setState({ [field]: selectedValue })
	}
	
	getMultiSelectedCount = (type, array) => {
		return array.length && array.includes("All") ? "All" : (array.length ? array.length +" Selected" : <span className="placeholder w-100 align-self-center">Select</span>)
	}
	
	handleInputChange = (label, value) => {
		let obj = this.props.selectedItem
		// let payload = ""
		// if(value !== "") {
		// 	payload = JSON.parse(JSON.stringify(value))

		// 	console.log("payload", value)
		// 	console.log("payload", value)
		// }
		obj[label] = value

		this.setState({ [label]: value })

		this.props.setCostPropsDetails('selectedItem', obj)
	}

	onFinish = () => {
		let params = this.props.selectedItem

		this.props.startPowerTuning(params, (promise, response) => {
			let messageType = ''
			let message = ''
			let createErrorMessage = ""
			if(response) {
				if(response.status) {
					messageType = 'success'
					message = 'Power tuning stared successfully'
				} else {
					messageType = 'danger'
					message = 'Power tuning not started'
					createErrorMessage = response.error

				}
			} else {
				messageType = 'danger'
				message = 'Power tuning not started'
			}
			this.setState({ onSaveFunction: false })

			CommonNotification.addNotification({
				//title: "Wonderful!",
				message: message,
				type: messageType,
				insert: "top",
				container: "top-center",
				// animationIn: ["animate__animated", "animate__fadeIn"],
				// animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
				duration: 5000,
				onScreen: false,
				pauseOnHover: true,
				showIcon: true,
				}
			});
		})
	}

	addNewActions = () => {
		let alarm_actions = this.props.selectedItem && this.props.selectedItem.alarm_actions ? this.props.selectedItem.alarm_actions : []

		if(this.state.alarmActions && this.state.alarmActions !== "") {
			if(!alarm_actions.includes(this.state.alarmActions)) {
				alarm_actions.push(this.state.alarmActions)
			}
		}

		this.setState({ alarm_actions, alarmActions: "" },
			() => this.handleInputChange("alarm_actions", alarm_actions)
		)
	}

	removeActionsSection = (row) => {
		// filteredResult = this.state.selectedTags.filter(e => e.key !== tag.key && e.value !== tag.value)
		let filteredResult = this.props.selectedItem.alarm_actions.filter(e => e !== row)
		this.handleInputChange("alarm_actions", filteredResult)
	}
	
	render() {		
		return (
			<div className="advanced-search" onClick={(event) => this.handleClickOutside(event)}>
				<div className={`loaderOverlay ${this.state.queryLoading ? "" : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
				<div className="search-content bg-muted w-80">
					<div className="header-search bd-highlight d-flex justify-content-between">
						<div className="flex-fill bd-highlight">
							<h5>Power Tuning Details</h5>
							<div className="mr-2 d-flex">
							</div>
						</div>

						<div className="text-right flex-fill bd-highlight align-self-center">
							<i className={`far fa-times cursorPointer ${this.state.onSaveFunction ? "disbaled" : ""}`} onClick={() => this.props.closeSidePanel()}></i>
						</div>
					</div>
					<div className={`${!this.state.showLoading ? 'overflow-auto' : ''} p-2`}>
						<div className="dlpDescriptionForm overflowYAuto" style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
							{this.state.createErrorMessage && this.state.createErrorMessage !== "" ?
								<div className="p-3">
									<p  className="text-danger">{this.state.createErrorMessage}</p>
								</div>
							: null}
							<div className="p-3 rounded bg-dark">
								<div className="d-flex justify-content-between">
									<h5 className="mb-0 cursorPointer d-flex" onClick={() => this.setState({ showDetails: !this.state.showDetails })}>Details</h5>
									
									<span className={`ml-4 fa fa-angle-${this.state.showDetails ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.setState({ showDetails: !this.state.showDetails })}></span>
								</div>
								{this.state.showDetails && Object.entries(this.props.selectedItem).length ?
									<div className='mt-2'>
										<div className="d-flex mb-1 border-bottom">
											<div className="py-1 w-50">
												<p className="b-block mb-0">Provider / Account / Region:</p>
												<p className="mb-0 text-white">
													{this.props.selectedItem.provider ? this.props.selectedItem.provider : ''}

													{this.props.selectedItem.account_id ? ' : '+ getAccountNameFromId(this.props.selectedItem.account_id, this.props.accounts) : ''}

													{this.props.selectedItem.region ? ' : '+ getAccountNameFromId(this.props.selectedItem.region, this.props.regions) : ''}
												</p>
											</div>
											<div className="py-1 w-50 pl-3">
												<p className="b-block mb-0">Status:</p>
												<p className="mb-0 text-white">
													{this.props.selectedItem.status ? 
														<span className={`badge status-badge badge-outline-${this.props.selectedItem.status.toLowerCase()}`}>{this.props.selectedItem.status}</span>
													: 
														<span className={`badge status-badge badge-outline-secondary}`}>No Status</span>
													}
												</p>
											</div>											
										</div>
										<div className="d-flex mb-1 border-bottom">
											<div className="py-1 w-50">
												<p className="b-block mb-0">Asset Name</p>
												<p className="mb-0 text-white">
													{this.props.selectedItem.asset_name ? 
														<span>{this.props.selectedItem.asset_name+(this.props.selectedItem.id_name_map && this.props.selectedItem.id_name_map.key ? +" : "+this.props.selectedItem.id_name_map.key : "")}</span>
													: <span>&nbsp;</span>}
												</p>
											</div>
											<div className="py-1 w-50 pl-3">
												<p className="b-block mb-0">Asset Arn</p>
												<p className="mb-0 text-white">
													{this.props.selectedItem.asset_arn ? this.props.selectedItem.asset_arn : <span>&nbsp;</span>}
												</p>
											</div>
										</div>
										<div className="d-flex mb-1 border-bottom">
											<div className="py-1 w-50">
												<p className="b-block mb-0">Category</p>
												<p className="mb-0 text-white">
													{this.props.selectedItem.category ? 
														this.props.selectedItem.category.map(item => {
															return (
															<small className={`badge badge-secondary mr-1`}>{item}</small>
															)
														}) 
													: null}
												</p>
											</div>
											<div className="py-1 w-50 pl-3">
												<p className="b-block mb-0">Event Name</p>
												<p className="mb-0 text-white">
													{this.props.selectedItem.asset_time ? momentConvertionUtcToLocalTime(this.props.selectedItem.asset_time, "DD MMM YYYY HH:mm") : <span>&nbsp;</span>}
												</p>
											</div>
										</div>
										<div className="d-flex mb-1 border-bottom">
											<div className="py-1 w-50">
												<p className="b-block mb-0">Event Name</p>
												<p className="mb-0 text-white">
													{this.props.selectedItem.event_name ? this.props.selectedItem.event_name : <span>&nbsp;</span>}
												</p>
											</div>
											<div className="py-1 w-50 pl-3">
												<p className="b-block mb-0">IAM Arn</p>
												<p className="mb-0 text-white">
													{this.props.selectedItem.iam_arn ? this.props.selectedItem.iam_arn : <span>&nbsp;</span>}
												</p>
											</div>
										</div>
										<div className="d-flex mb-1 border-bottom">
											<div className="py-1 w-50">
												<p className="b-block mb-0">Resource Type</p>
												<p className="mb-0 text-white">
													{this.props.selectedItem.resource_type ? this.props.selectedItem.resource_type : <span>&nbsp;</span>}
												</p>
											</div>
											<div className="py-1 w-50 pl-3">
												<p className="b-block mb-0">Service Name</p>
												<p className="mb-0 text-white">
													{this.props.selectedItem.service_name ? this.props.selectedItem.service_name : <span>&nbsp;</span>}
												</p>
											</div>
										</div>
										<div className="d-flex mb-1 border-bottom">
											<div className="py-1 w-50">
												<p className="b-block mb-0">Tags</p>
												<p className="mb-0 text-white">
													{this.props.selectedItem.tags && this.props.selectedItem.tags.length ? 
														this.props.selectedItem.tags.map((tag, i) => {
															return (
																<small key={i} className="badge badge-outline-gray4 mr-1">{tag.key +' : '+tag.value}</small>
															)
														})
													:
														<small className={`badge badge-outline-gray4 mb-1 mr-1`}>No Tags</small>
													}
												</p>
											</div>
										</div>
										<div className="py-1 w-50 zapInputDark">
											<p className="b-block mb-0">Payload:</p>
											<p className="mb-0 text-white">
											<Input
												type="text"
												placeholder="Enter paylod"
												className={`inputTextbox`}
												// maxlength="128"
												value={this.state.payload ? this.state.payload : ""}
												onChange={e => this.handleInputChange("payload", e.target.value)}
											/>
											</p>
										</div>
									</div>
								: null}

								<div className='mt-4'>
									<button className={`btn btn-primary mr-2 ${this.state.onSaveFunction ? "disbaled" : ""}`} onClick={() => this.setState({ onSaveFunction: true }, () => this.onFinish())} >
										{this.state.onSaveFunction ? 
											<Spinner size='sm' color='light' className='mx-2' /> 
										: null}
										Save 
									</button>
									<button className={`btn btn-secondary ${this.state.onSaveFunction ? "disbaled" : ""}`} onClick={() => this.props.closeSidePanel()} >Cancel</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ViewSidePanel.propTypes = {}

const mapStateToProps = state => {
	// console.log('csot power tuning viewsidepanel',state)
	return {
		accounts: state.filters.accounts,
		regions: state.filters.regions,
		tagKeys: state.filters.tagKeys,
		selectedItem: state.cost.costPropsDetails && state.cost.costPropsDetails.selectedItem ? state.cost.costPropsDetails.selectedItem : {},
	}
}

export default connect(mapStateToProps, {
	listAllAccounts,
	listAllRegions, 
	getAllTagsKeys, 
	getAllTagsValues,
	setCostPropsDetails,
	startPowerTuning
})(withRouter(ViewSidePanel))