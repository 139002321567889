/*************************************************
 * Tvastar
 * @exports
 * @file dlpReducer.js
 * @author Rajasekar // on 26/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from '../../actions/types'

const INITIAL_STATE = {
	dlpPropsDetails: {},
	dlpProfileOverAll: [],
}

/**
 * To store assets list in redux store
 * @param {Object} state
 * @param {Object} action
 */
export const dlpReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ACTION_TYPES.SET_DLP_PROPS_DETAILS:
			return {
				...state,
				dlpPropsDetails: { ...action.payload },
			}
		case ACTION_TYPES.SET_SELECTED_DLP_FILTER:
			return {
				...state,
				selectedFilters: action.payload,
			}
		case ACTION_TYPES.SET_DLP_PROFILE_OVERALL_DISTRIBUTION:
			return {
				...state,
				dlpProfileOverAll: action.payload,
			}
		case ACTION_TYPES.SET_CROSS_ACCOUNT_DISTRIBUTION:
			return {
				...state,
				crossAccountDistribution: action.payload,
			}
		case ACTION_TYPES.SET_METADATA_TOTAL_COUNT:
			return {
				...state,
				metadataTotalCount: action.payload,
			}
		case ACTION_TYPES.SET_METADATA_RANGE_DISTRIBUTION:
			return {
				...state,
				metadataRangeDistribution: action.payload,
			}
		case ACTION_TYPES.SET_METADATA_OVERALL_DISTRIBUTION:
			return {
				...state,
				metadataOverallDistribution: action.payload,
			}
		case ACTION_TYPES.SET_DLP_FILTER_PROPERTIES:
			return {
				...state,
				trendFilters: action.payload,
			}
		case ACTION_TYPES.SET_START_DAILY_TREND:
			return {
				...state,
				startDailyTrend: action.payload
			}
		case ACTION_TYPES.SET_DATA_DAILY_TREND:
			return {
				...state,
				getDailyTrend: action.payload
			}
		case ACTION_TYPES.SET_DLP_POLICIES_PRIVACY_LIST:
			return {
				...state,
				dlpPrivacyPoliciesList: action.payload
			}
		case ACTION_TYPES.SET_DLP_POLICIES_SECURITY_LIST:
			return {
				...state,
				dlpSecurityPoliciesList: action.payload
			}
		case ACTION_TYPES.SET_DLP_CONFIGURATION_LIST_WATERMAEK:
			return {
				...state,
				dlpConfigWatermarklist: action.payload
			}
		case ACTION_TYPES.SET_DLP_CONFIGURATION_LIST_ENGINE_EVENTS:
			return {
				...state,
				dlpConfigEngineEventsList: action.payload
			}
		case ACTION_TYPES.SET_DLP_JOBS_LIST:
			return {
				...state,
				dlpJobsList: action.payload
			}
		case ACTION_TYPES.SET_DLP_CONFIGURATION_LIST_CUSTOMER_ACCOUNT:
			return {
				...state,
				dlpCustomerAccountInfoList: action.payload
			}
		case ACTION_TYPES.SET_DLP_CONFIGURATION_LIST_SOURCE_DATA:
			return {
				...state,
				dlpSourceDataList: action.payload
			}
		default:
			return state
	}
}
