/*************************************************
 * Tvastar
 * @exports
 * @file SreMetricsTab.js
 * @author Prakash // on 08/04/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Spinner } from 'reactstrap'
import { momentDateGivenFormat, momentConvertionUtcToLocalTime, convertBytes, convertBytesWithoutUnit, convertSeconds, convertSecondsWithoutUnit, countFormater, countFormaterWithoutUnit, convertBits, convertBitsWithoutUnit, momentConvertionLocalToUtcTime } from '../../../utils/utility'
import Chart from 'react-apexcharts'

import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'
import { getSreDetails } from '../../../actions/aiops/DiagnosticsAction'

class SreMetricsTab extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    
    componentDidMount = () => {
        if(this.props.caseAssetPageFilter && Object.entries(this.props.caseAssetPageFilter).length) {
            this.setState({ 
                start_time: this.props.caseAssetPageFilter.start_time,
                end_time: this.props.caseAssetPageFilter.end_time,
                brushStartTime: this.props.caseAssetPageFilter.brushStartTime ? this.props.caseAssetPageFilter.brushStartTime : this.props.caseAssetPageFilter.start_time,
                brushEndTime: this.props.caseAssetPageFilter.brushEndTime ? this.props.caseAssetPageFilter.brushEndTime : this.props.caseAssetPageFilter.end_time,
                selectedAsset: this.props.caseAssetPageFilter.selectedAsset ? this.props.caseAssetPageFilter.selectedAsset : {},
                sreMetricLoader: true,
                selectedMetrics: "",
                graphData: {}
            },
                () => {
                    this.getSreDetails()
                }
            )
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.caseAssetPageFilter && Object.entries(this.props.caseAssetPageFilter).length) {
            if(!_.isEqual(prevProps.caseAssetPageFilter, this.props.caseAssetPageFilter)) {
                this.setState({ 
                    start_time: this.props.caseAssetPageFilter.start_time,
                    end_time: this.props.caseAssetPageFilter.end_time,
                    brushStartTime: this.props.caseAssetPageFilter.brushStartTime ? this.props.caseAssetPageFilter.brushStartTime : this.props.caseAssetPageFilter.start_time,
                    brushEndTime: this.props.caseAssetPageFilter.brushEndTime ? this.props.caseAssetPageFilter.brushEndTime : this.props.caseAssetPageFilter.end_time,
                    selectedAsset: this.props.caseAssetPageFilter.selectedAsset ? this.props.caseAssetPageFilter.selectedAsset : {},
                    sreMetricLoader: true,
                    selectedMetrics: "",
                    graphData: {},
                },
                    () => {
                        this.getSreDetails()
                    }
                )
            }
        }   
    }

    getSreDetails = () => {
        let startTime = momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss')
        let endTime = momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss')
        let assetId = this.state.selectedAsset.asset_id ? this.state.selectedAsset.asset_id : ""
        let assetArn = this.state.selectedAsset.asset_arn ? this.state.selectedAsset.asset_arn : ""
        let assetName = this.state.selectedAsset.asset_name ? this.state.selectedAsset.asset_name : ""
        if(assetId !== "" || assetArn !== "" || assetName !== "") {
            let params = {}
            params.start_time = startTime
            params.end_time = endTime
            if(assetId !== "") {
                params.asset_id = assetId
            } else if(assetArn !== "") {
                params.asset_arn = assetArn
            } else {
                params.asset_name = assetName
                params.account_id = this.state.selectedAsset.account_id
                params.region = this.state.selectedAsset.region
                if(this.state.selectedAsset.service_name) {
                    params.service_name = this.state.selectedAsset.service_name
                } else if(this.state.selectedAsset.resource_type) {
                    params.resource_type = this.state.selectedAsset.resource_type
                }
            }
            if(!this.props.caseAssetSreMetricsList.hasOwnProperty(assetId)) {
                this.props.getSreDetails(params, (promise, response) => {
                    if(promise) {
                        this.setState({ 
                            sreDetails: response,
                            sreMetricLoader: false
                        },
                            () => {
                                this.formSreLatencyDetailGraph()
                                this.formSreRateDetailGraph()                                
                            }
                        )
                        if(response.length) {
                            let obj = this.props.caseAssetSreMetricsList
                            obj[assetId] = response
                            this.props.setAiopsPropsDetails('caseAssetSreMetricsList', obj)
                        }
                    } else {
                        this.setState({ sreDetails: [], sreMetricLoader: false })
                    }
                })
            } else {
                this.setState({ 
                    sreDetails: this.props.caseAssetSreMetricsList[assetId],
                    sreMetricLoader: true,
                },
                    () => {
                        this.formSreLatencyDetailGraph()
                        this.formSreRateDetailGraph()
                    }
                )
            }
        }
    }

    formSreLatencyDetailGraph = () => {
        let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']
        if(this.state.sreDetails && this.state.sreDetails.length) {
            // let obj = {}
            let filteredSreDetails = this.state.sreDetails
            filteredSreDetails.forEach((res, index) => {
                let graphData = res.data
                let graphLabels = res.dates

                let colorInc = 0
                graphData.latency && Object.entries(graphData.latency).forEach(([key, item], j) => {
                    console.log('item.values', item.values)
                    if(item.values && item.values.length) {

                        let chartType = 'line'
                        let animations = {}
                        let plotOptions = {}
                        let stroke = {
                            width: [3],
                            curve: 'smooth',
                        }
                        if(j % 2 === 0) {
                            chartType = 'bar'
                            animations = {
                                enabled: false
                            }
                            plotOptions = {
                                bar: {
                                    horizontal: false,
                                    barHeight: '100%',
                                    borderRadius: 'flat',
                                    startingShape: 'flat',
                                    endingShape: 'flat',
                                    columnWidth: '60%',
                                    distributed: false,
                                    rangeBarOverlap: false,
                                }
                            }
                            stroke = {
                                width: [2],
                            }
                        }

                        let latencySeries = []
                        let dataRow = {}
                        dataRow.name = key
                        dataRow.type = chartType
                        let plotValue = []
                        let brushValue = []
                        let labelsFrom = graphLabels.length - this.state.graphLabelsFrom

                        graphLabels.forEach((lab, i) => {
                            let dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            let val = item.values[i]
                            if(item.unit) {
                                val = this.getGrapUnitValue(val, item.unit)
                            }
                            dataArray.push(val)
                            plotValue.push(dataArray)

                            dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            dataArray.push(val)
                            brushValue.push(dataArray)
                        })
                        dataRow.data = plotValue
                        latencySeries.push(dataRow)

                        let latencyOptions = {
                            chart: {
                                id: 'chart_latency_details_'+j,
                                type: chartType,
                                toolbar: {
                                    show: false
                                },
                                zoom: {
                                    enabled: false
                                },
                                animations: animations
                            },
                            plotOptions: plotOptions,
                            dataLabels: {
                                enabled: false
                            },
                            stroke: stroke,
                            colors: [colors[colorInc]],
                            xaxis: {
                                type: 'datetime',
                                labels: {
                                    datetimeUTC: false,
                                    style: {
                                        colors: '#60687C'
                                    },
                                    // formatter: function (value) {
                                    //     return momentDateGivenFormat(value, 'HH:mm')
                                    // },
                                },                            
                                axisTicks: {
                                    show: false
                                },
                                tooltip: {
                                    enabled: false
                                },
                            },
                            yaxis: {
                                show: true,
                                type: 'numeric',
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                    color: '#60687C'
                                },
                                tooltip: {
                                    enabled: false
                                },
                                // tickAmount: 2,
                                min: 0,
                                // max: yaxisMaxValue, 
                                tickAmount: 1,
                                labels: {
                                    offsetX: -10,
                                    // minWidth: 50,
                                    show: true,
                                    style: {
                                        fontSize: '9px',
                                        colors: '#60687C'
                                    },
                                    // formatter: (value) => { 
                                    //     return value.toFixed(0)
                                    // },
                                    formatter: (value) => { 
                                        // if(Number(value) === value && value % 1 === 0) {
                                        //     return Math.ceil(value)
                                        // } else {
                                        //     return value.toFixed(1)
                                        // }
                                        if(Number(value) === value && value % 1 === 0) {
                                            return Math.ceil(value.toFixed(1))
                                        } else {
                                            return Math.ceil(value.toFixed(1))
                                        }
                                    },
                                }
                            },
                            tooltip: {
                                shared: true,
                                intersect: false,
                                y: {
                                    show: false,
                                },
                                
                                x: {
                                    show: false
                                },
                                marker: {
                                    show: false
                                },
                                custom: function({series, seriesIndex, dataPointIndex, w}) { 
                                    let returnData = ''
                                    
                                    if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                        Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                            if(!index) {
                                                returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                            }
                                            returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'
    
                                        })
                                    }
                                    returnData += '</div>'
                                    return returnData
                                }
                            },
                            grid: {
                                show: true,
                                borderColor: '#2D323E',
                                strokeDashArray: 0,
                                position: 'back',
                                xaxis: {
                                    lines: {
                                        show: true
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#FFF'],
                                        }
                                    },
                                    axisTicks: {
                                        show: false
                                    }
                                },   
                                yaxis: {
                                    lines: {
                                        show: false
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#969AA7'],
                                        }
                                    }
                                },                
                                padding: {
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0
                                },
                            },
                            legend: {
                                show: false
                            }
                        }

                        colorInc++;
                        console.log("series", latencySeries)
                        console.log("options", latencyOptions)
                        this.setState({ ['series_latency_'+j]: latencySeries, ['options_latency_'+j]: latencyOptions, sreMetricLoader: false })
                    }
                })
            })
        }
    }

    formSreRateDetailGraph = () => {
        let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']
        if(this.state.sreDetails && this.state.sreDetails.length) {

            let errorRateArray= []
            let filteredSreDetails = this.state.sreDetails.filter(arr => arr.asset_id === this.state.selectedAsset.asset_id)
            filteredSreDetails.forEach((res, index) => {
                let graphData = res.data
                let graphLabels = res.dates

                // let  strokeWidth = []
                let x = 0
                
                graphData.error_rate && Object.entries(graphData.error_rate).forEach(([key, item], j) => {                    
                    let chartType = 'line'
                    let animations = {}
                    let plotOptions = {}
                    let stroke = {
                        width: [3],
                        curve: 'smooth',
                        // dashArray: [0, 8, 5]
                    }
                    if(x % 2 === 0) {
                        chartType = 'bar'
                        animations = {
                            enabled: false
                        }
                        plotOptions = {
                            bar: {
                                horizontal: false,
                                barHeight: '100%',
                                borderRadius: 'flat',
                                startingShape: 'flat',
                                endingShape: 'flat',
                                columnWidth: '60%',
                                distributed: false,
                                rangeBarOverlap: false,
                            }
                        }
                        stroke = {
                            width: [2],
                        }
                    }

                    let error_series = []
                    let errorRow = {}
                    errorRow.name = key
                    errorRow.type = chartType

                    let plotValue = []
                    let labelsFrom = graphLabels.length - this.state.graphLabelsFrom
                    graphLabels.forEach((lab, i) => {
                        let dataArray = []
                        dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                        // dataArray.push(this.getGrapUnitValue(item.values[i], item.unit))
                        dataArray.push(item.values[i])
                        plotValue.push(dataArray)
                    })

                    errorRow.data = plotValue
                    error_series.push(errorRow)

                    errorRateArray.push({key: key, unit: item.unit})

                    let rate_options = {
                        chart: {
                            type: chartType,
                            id: 'chart_rate_details_'+x,
                            toolbar: {
                                show: false
                            },
                            zoom: {
                                enabled: false
                            },
                            animations: animations,
                        },
                        plotOptions: plotOptions,
                        stroke: stroke,
                        dataLabels: {
                            enabled: false
                        },
                        colors: [colors[x]],
                        xaxis: {
                            type: 'datetime',
                            labels: {
                                datetimeUTC: false,
                                style: {
                                    colors: '#60687C'
                                },
                                // formatter: function (value) {
                                //     return momentDateGivenFormat(value, 'HH:mm')
                                // },
                            },                            
                            axisTicks: {
                                show: false
                            },
                            tooltip: {
                                enabled: false
                            },
                        },
                        yaxis: {
                            show: true,
                            axisTicks: {
                                show: false,
                            },
                            axisBorder: {
                                show: false,
                                color: '#60687C'
                            },
                            tooltip: {
                                enabled: false
                            },
                            // tickAmount: 2,
                            min: 0,
                            // max: yaxisMaxValue, 
                            tickAmount: 2,
                            labels: {
                                offsetX: -10,
                                // minWidth: 50,
                                show: true,
                                style: {
                                    fontSize: '9px',
                                    colors: '#60687C'
                                },
                                formatter: (value) => { 
                                    // if(Number(value) === value && value % 1 === 0) {
                                    //     return Math.ceil(value)
                                    // } else {
                                    //     return value.toFixed(1)
                                    // }
                                    if(Number(value) === value && value % 1 === 0) {
                                        return Math.ceil(value.toFixed(1))
                                    } else {
                                        return Math.ceil(value.toFixed(1))
                                    }
                                },
                            }   
                        },
                        tooltip: {
                            shared: true,
                            intersect: false,
                            y: {
                                show: false,
                            },
                            
                            x: {
                                show: false
                            },
                            marker: {
                                show: false
                            },
                            custom: function({series, seriesIndex, dataPointIndex, w}) {
                                    // let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]
                                    // let labelName = w.globals.initialSeries[seriesIndex].name
                                    // // return '<div className="arrow_box"> '+ capitalizeFirstLetter(labelName) +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' + '  '+ val + '</span> </div>'         
                                    let returnData = ''
                                    
                                    if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                        Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                            if(!index) {
                                                returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                            }
                                            returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'
    
                                        })
                                    }
    
                                    returnData += '</div>'
                                    return returnData
                            }
                        },
                        grid: {
                            show: true,
                            borderColor: '#2D323E',
                            strokeDashArray: 0,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: true
                                },
                                labels: {
                                    style: {
                                        colors: ['#FFF'],
                                    }
                                },
                                axisTicks: {
                                    show: false
                                }
                            },   
                            yaxis: {
                                lines: {
                                    show: false
                                },
                                labels: {
                                    style: {
                                        colors: ['#969AA7'],
                                    }
                                }
                            },                
                            padding: {
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0
                            },
                        },
                        legend: {
                            show: false
                            // horizontalAlign: 'left',
                            // offsetX: 40
                        },
                    }

                    this.setState({ ['series_rate_detail_'+x]: error_series, ['options_rate_detail_'+x]: rate_options, sreMetricLoader: false })
                    x++;
                })
                
                graphData.request_rate && Object.entries(graphData.request_rate).forEach(([key, item], j) => {

                    let chartType = 'line'
                    let animations = {}
                    let plotOptions = {}
                    let stroke = {
                        width: [3],
                        curve: 'smooth',
                        // dashArray: [0, 8, 5]
                    }

                    if(x % 2 === 0) {
                        chartType = 'bar'
                        animations = {
                            enabled: false
                        }
                        plotOptions = {
                            bar: {
                                horizontal: false,
                                barHeight: '100%',
                                borderRadius: 'flat',
                                startingShape: 'flat',
                                endingShape: 'flat',
                                columnWidth: '60%',
                                distributed: false,
                                rangeBarOverlap: false,
                            }
                        }
                        stroke = {
                            width: [2],
                        }
                    }

                    let plotValue = []
                    let brushValue = []

                    let labelsFrom = graphLabels.length - this.state.graphLabelsFrom
                    graphLabels.forEach((lab, i) => {
                        let dataArray = []
                        dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                        dataArray.push(item.values[i])
                        // dataArray.push(this.getGrapUnitValue(item.values[i], item.unit))
                        plotValue.push(dataArray)

                        dataArray = []
                        dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                        dataArray.push(item.values[labelsFrom + i])
                        brushValue.push(dataArray)
                        // dataArray.push(this.getGrapUnitValue(bar1[i], 'Percent'))
                    })

                    let request_series = []
                    let requestRow = {}
                    requestRow.name = key
                    requestRow.type = 'bar'
                    requestRow.data = plotValue
                    request_series.push(requestRow)

                    errorRateArray.push({key: key, unit: item.unit})

                    let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC']

                    let request_options = {
                        chart: {
                            type: chartType,
                            id: 'chart_rate_details_'+x,
                            toolbar: {
                                show: false
                            },
                            zoom: {
                                enabled: false
                            },
                            animations: animations
                        },
                        plotOptions: plotOptions,
                        stroke: stroke,
                        dataLabels: {
                            enabled: false
                        },
                        colors: [colors[x]],
                        xaxis: {
                            type: 'datetime',
                            labels: {
                                datetimeUTC: false,
                                style: {
                                    colors: '#60687C'
                                },
                                // formatter: function (value) {
                                //     return momentDateGivenFormat(value, 'HH:mm')
                                // },
                            },                            
                            axisTicks: {
                                show: false
                            },
                            tooltip: {
                                enabled: false
                            },
                        },
                        yaxis: {
                            show: true,
                            axisTicks: {
                                show: false,
                            },
                            axisBorder: {
                                show: false,
                                color: '#60687C'
                            },
                            tooltip: {
                                enabled: false
                            },
                            // tickAmount: 2,
                            min: 0,
                            // max: yaxisMaxValue, 
                            tickAmount: 2,
                            labels: {
                                offsetX: -10,
                                // minWidth: 50,
                                show: true,
                                style: {
                                    fontSize: '9px',
                                    colors: '#60687C'
                                },
                                formatter: (value) => { 
                                    // if(Number(value) === value && value % 1 === 0) {
                                    //     return Math.ceil(value)
                                    // } else {
                                    //     return value.toFixed(1)
                                    // }
                                    if(Number(value) === value && value % 1 === 0) {
                                        return Math.ceil(value.toFixed(1))
                                    } else {
                                        return Math.ceil(value.toFixed(1))
                                    }
                                },
                            }
                        },
                        tooltip: {
                            shared: true,
                            intersect: false,
                            y: {
                                show: false,
                            },
                            
                            x: {
                                show: false
                            },
                            marker: {
                                show: false
                            },
                            custom: function({series, seriesIndex, dataPointIndex, w}) {
                                // let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]
                                // let labelName = w.globals.initialSeries[seriesIndex].name
                                // // return '<div className="arrow_box"> '+ capitalizeFirstLetter(labelName) +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' + '  '+ val + '</span> </div>'         
                                let returnData = ''
                                
                                if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                    Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                        if(!index) {
                                            returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                        }
                                        returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'

                                    })
                                }

                                returnData += '</div>'
                                return returnData
                            }
                        },
                        grid: {
                            show: true,
                            borderColor: '#2D323E',
                            strokeDashArray: 0,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: true
                                },
                                labels: {
                                    style: {
                                        colors: ['#FFF'],
                                    }
                                },
                                axisTicks: {
                                    show: false
                                }
                            },   
                            yaxis: {
                                lines: {
                                    show: false
                                },
                                labels: {
                                    style: {
                                        colors: ['#969AA7'],
                                    }
                                }
                            },                
                            padding: {
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0
                            },
                        },
                        legend: {
                            show: false
                            // horizontalAlign: 'left',
                            // offsetX: 40
                        },
                    }

                    this.setState({ ['series_rate_detail_'+x]: request_series, ['options_rate_detail_'+x]: request_options, sreMetricLoader: false })
                    x++;
                })
            })
            this.setState({ errorRateArray })
        }
    }

    getGrapUnitValue = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytes(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit)
            } else if(unit === 'Count') {
                // val = val > 1 ? parseInt(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    getGrapUnitValueWithoutUnit = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytesWithoutUnit(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSecondsWithoutUnit(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBitsWithoutUnit(val, unit)
            } else if(unit === 'Count') {
                // val = val > 1 ? parseInt(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val ? Math.ceil(val) : 0
                val = countFormaterWithoutUnit(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    render() {
        return(
            this.state.sreMetricLoader ?
                <div className='d-flex justify-content-center m-4'>
                    <Spinner className='text-center' color='white' size='lg' />
                </div>
            : this.state.sreDetails && !this.state.sreDetails.length ? 
                <div className='d-flex justify-content-center m-4'>
                    No SRE Metrics for the this asset.
                </div>
            : this.state.sreDetails && this.state.sreDetails.length ? 
                this.state.sreDetails.map((res, index) => {
                    return(
                        <React.Fragment>
                        {res.data && Object.entries(res.data.latency).map(([key, value], j) => {
                            return(
                                <div className={`'col-sm-12 mb-3`}>
                                    <div className={`border rounded p-2 ${this.state.selectedMetrics === key ? "bg-muted" : "bg-dark3"}`} 
                                        onClick={() => this.setState({ selectedMetrics: key }, () => this.props.selectedMetrics(key))}
                                        >
                                        <div className="d-flex">
                                            <p className="text-white f16 p-1 mb-0">{key}</p>
                                            <p className="text-primary-color f12 align-self-end p-1 mb-0">
                                                ({value.unit})
                                            </p>
                                        </div>
                                        <div className={`mb-n4 mt-n4 transparentTooltip`}>
                                            {this.state['options_latency_'+j] && this.state['series_latency_'+j] && this.state['series_latency_'+j].length ?
                                                <Chart options={this.state['options_latency_'+j]} series={this.state['series_latency_'+j]} type={`${j % 2 === 0 ? 'bar' : 'line'}`} height={this.props.height} width={"100%"} />
                                            :
                                                <div style={{minHeight:"165px"}} className="d-flex align-self-center justify-content-center">
                                                    <div className='align-self-center'>
                                                        No Data
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        
                        {this.state.errorRateArray && this.state.errorRateArray.length && this.state.errorRateArray.map((item, j) => {
                            return(
                                <div className={`col-sm-12 mb-3 p-0`}>
                                    <div className={`border rounded p-2 ${this.state.selectedMetrics === item.key ? "bg-muted" : "bg-dark3"}`} 
                                        onClick={() => this.setState({ selectedMetrics: item.key }, () => this.props.selectedMetrics(item.key))}
                                        >
                                        <div className="d-flex">
                                            <p className="text-white f16 p-1 mb-0">{item.key}</p>
                                            <p className="text-primary-color f12 align-self-end p-1 mb-0">
                                                ({item.unit})
                                            </p>
                                        </div>
                                        <div className={`mb-n4 mt-n4 transparentTooltip`}>
                                            <Chart options={this.state['options_rate_detail_'+j]} series={this.state['series_rate_detail_'+j]} type={`${j % 2 === 0 ? 'bar' : 'line'}`} height={this.props.height} width={"100%"} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        </React.Fragment>
                    )                    
                })
            : null
        )
    }
}

/**
 * Type of the props used in the component
 */
SreMetricsTab.propTypes = {
}   

const mapStateToProps = state => {
    return {
        caseAssetPageFilter: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.caseAssetPageFilter ? state.aiops.aiopsPropsDetails.caseAssetPageFilter : {},
        caseAssetSreMetricsList: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.caseAssetSreMetricsList ? state.aiops.aiopsPropsDetails.caseAssetSreMetricsList : [],
    }
}

export default connect(mapStateToProps, {
    getSreDetails,
    setAiopsPropsDetails
})(withRouter(SreMetricsTab))