/*************************************************
 * Tvastar
 * @exports
 * @file DataEventsAggregator.js
 * @author Prakash // on 09/09/2021
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { restrictCharacter } from '../../../../utils/utility'
import {
    getDataEventsAggregatorBaseParams,
    insertMsc,
    updateMsc,
} from '../../../../actions/dlp/DlpJobsAction'
import { Spinner } from 'reactstrap'

class DataEventsAggregator extends Component {
    hierarichalBucketBarChartRef = React.createRef()
    constructor(props) {
        super(props)
        this.props = props;
        this.state = {
            showLoading: true,
            bucketArray: [0],
        }
    }

    componentDidMount = () => {
        if(this.props.pageType === 'edit' || this.props.pageType === 'view') {
            this.editJobs()
        } else {
            this.getDataEventsAggregatorBaseParams()
        }
    }

    editJobs = () => {
        let selectedRecord = this.props.selectedRecord
        let mandatory = selectedRecord.mandatory
        let bucketArray = []
        if(selectedRecord.bucket) {
            selectedRecord.bucket.forEach((item, index) => {
                bucketArray.push(index)
                this.setState({ ['bucket_name_'+index]: item.bucket_name, ['prefix_name_'+index]: item.prefix })
            })
        } else {
            bucketArray.push(0)
        }

        let data_events_bucket_name = selectedRecord.dlp_data_events_aggregator_config.data_events_bucket_name

        this.setState({ bucketArray, data_events_bucket_name, mandatory },
            () => this.getDataEventsAggregatorBaseParams()    
        )
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.dlpPropsDetails && typeof this.props.dlpPropsDetails.hasJobError !== 'undefined' &&  prevProps.dlpPropsDetails.hasJobError !== this.props.dlpPropsDetails.hasJobError) {
            this.setState({ hasError: true })
        }
    }

    getDataEventsAggregatorBaseParams = () => {
        let params = {}
        this.props.getDataEventsAggregatorBaseParams(params, (promise, response) => {
            this.setState({ masterData: response, showLoading: false })
        })
    }

    onChangePropsEvent = () => {
        let hasError = false

        let checkBucketDataExist = 0
        this.state.bucketArray.forEach(item => {
            if(this.state['bucket_name_'+item] === '' && this.state['prefix_name_'+item] !== '') {
                hasError = true
            } else if(this.state['bucket_name_'+item] !== '') {
                checkBucketDataExist++
            }
        })
        
        if(!checkBucketDataExist) {
            hasError = true
        }
        
        if(!this.state.data_events_bucket_name || this.state.data_events_bucket_name === '') {
            hasError = true
        }
        
        let data = {}
        data.mandatory = this.props.pageType === 'create' ? true : this.state.mandatory
            
        let jobText = ' for buckets'
        let buckets = []
        this.state.bucketArray.forEach(item => {
            if(this.state['bucket_name_'+item] !== '') {
                let dataRow = {}
                dataRow.bucket_name = this.state['bucket_name_'+item]
                dataRow.prefix = this.state['prefix_name_'+item] ? this.state['prefix_name_'+item] : ''
                buckets.push(dataRow)
                jobText += ' bucket name '+this.state['bucket_name_'+item]+' prefix '+this.state['prefix_name_'+item]
            }
        })
        data.bucket = buckets
        
        let  config= {}
        if(this.state.masterData && this.state.masterData.static_fields) {
            Object.entries(this.state.masterData.static_fields).forEach(([key, value]) => {
                if(key !== 'dlp_data_events_aggregator_config') {
                    data[key] = value
                } else {
                    Object.entries(value).forEach(([eKey, eValue]) => {
                        config[eKey] = eValue
                    })
                }
            })
        }
        
        config.data_events_bucket_name = this.state.data_events_bucket_name
        jobText += ' store results in bucket '+this.state.data_events_bucket_name

        data.dlp_data_events_aggregator_config = config

        this.props.jobData(data, jobText, hasError)
    }

    addSection = (array) => {
        let rowList = this.state[array];
        if(this.state[array]) {
            let value = this.state[array][this.state[array].length - 1]
            value = value+1
            rowList.push(value);
        }
        
        this.setState({[array]: rowList })
    }

    removeBucketSection = (item, i) => {
        this.setState({ ['bucket_name_'+item]: '', ['prefix_name_'+item]: '' },
            () => {
                let rowList = this.state.bucketArray;
                rowList.splice(i, 1);
                this.setState({ bucketArray: rowList })
            }
        );
    }

    render() {		
        return (
            !this.state.showLoading ?
                <div className={`${this.props.pageType === 'view' ? 'disabled disableInputColor' : ''}`}>
                    <div className={`form-group dlpDescriptionFormWhite`}>
                        <p className="mb-0 mr-2 align-self-end text-purple mt-4">for the buckets</p>
                        {this.state.bucketArray.map((item, i) => {
                            return(
                                <div className="w-100">
                                    <div className="d-flex flex-wrap">
                                        <p className="mb-0 mr-2 align-self-end text-purple mt-3">Bucket</p>
                                        <input 
                                            type="text" 
                                            className={`inputField mt-3 w-30 ${this.state.hasError && (!this.state['bucket_name_'+item] || this.state['bucket_name_'+item] === '') ? 'dottedDangerTextboxLine' : ''}`}
                                            value={this.state['bucket_name_'+item] ? this.state['bucket_name_'+item] : ''}
                                            onChange={e => this.setState({ ['bucket_name_'+item]: restrictCharacter(e.target.value, '/') }, () => this.onChangePropsEvent())}
                                        />
                                        <p className="mb-0 mx-2 align-self-end text-purple mt-3">Prefix</p>
                                        <input 
                                            type="text" 
                                            className={`inputField mt-3 w-30`}
                                            value={this.state['prefix_name_'+item] ? this.state['prefix_name_'+item] : ''}
                                            onChange={e => this.setState({ ['prefix_name_'+item]: e.target.value }, () => this.onChangePropsEvent())}
                                        />
                                        {this.props.pageType.toLowerCase() !== 'view' && this.state.bucketArray.length > 1 ?
                                            <span className={`far fa-trash cursor-pointer f18 align-self-end ml-4`} onClick={() => this.removeBucketSection(item, i)}></span>
                                        : null}
                                        {this.props.pageType.toLowerCase() !== 'view' && (this.state.bucketArray.length - 1) ===  i ?
                                            <span className={`far fa-plus cursor-pointer f18 align-self-end ml-4`} onClick={() => this.addSection('bucketArray')}></span>
                                        : null}
                                    </div>
                                </div>
                            )
                        })}

                        <p className={`mb-0 mr-2 text-purple mt-3`}>Store results in</p>

                        <div className="w-100">                                                        
                            <div className="d-flex flex-wrap">
                                <p className="mb-0 mr-2 align-self-end text-purple mt-3">Bucket</p>
                                <input 
                                    type="text" 
                                    className={`inputField mt-3 w-30  ${this.state.hasError && (!this.state.data_events_bucket_name || this.state.data_events_bucket_name === '') ? 'dottedDangerTextboxLine' : ''}`}
                                    value={this.state.data_events_bucket_name ? this.state.data_events_bucket_name : ''}
                                    onChange={e => this.setState({ data_events_bucket_name: restrictCharacter(e.target.value, '/') }, () => this.onChangePropsEvent())}
                                />
                            </div>
                        </div>
                    </div>
                    {this.props.pageType !== 'create' ?
                        <div style={{ display: "inline" }} className="mr-2 align-self-end">
                            <div className="form-check">
                                <input type="checkbox"
                                    onChange={() => this.setState({ mandatory: !this.state.mandatory }, () => this.onChangePropsEvent())}
                                    className="form-check-input"
                                    checked={this.state.mandatory}
                                />
                                <p className="form-check-label mb-0 mr-2 text-purple">Enable</p>
                            </div>
                        </div>
                    : null}
                </div>
            :
                <div className='text-center mt-5' >
                    <Spinner color='white' size='lg' />
                </div>
        )
    }
}

const mapStateToProps = state => {
    return {
    dlpPropsDetails: state.dlp.dlpPropsDetails
    }
}

export default connect(mapStateToProps, {
    getDataEventsAggregatorBaseParams,
    insertMsc,
    updateMsc,
})(withRouter(DataEventsAggregator))