/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Input } from 'reactstrap'
import { setEditRuleDetails } from '../../../actions/governance/governanceAction'
import PropTypes from 'prop-types'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { currentUTCtime } from '../../../utils/utility'

const options = [
	{label: 'RDS', value: 'RDS'},
	{label: 'RDS Aurora MySQL Provisioned', value: 'RDS Aurora MySQL Provisioned'},
	{label: 'RDS Aurora Postgresql Provisioned', value: 'RDS Aurora Postgresql Provisioned'},
	{label: 'RDS Aurora MySQL Serverless', value: 'RDS Aurora MySQL Serverless'},
	{label: 'RDS Oracle', value: 'RDS Oracle'}
]

// const volumeTypes = [
// 	'General Purpose',
// 	'Provisioned IOPS io1',
// 	'Provisioned IOPS io2',
// 	'Magnetic',
// 	'Cold HDD',
// 	'Throughput Optimized'
// ]

class ServiceVersion extends Component {
	constructor(props) {
		super(props)
		this.optionTypeRef = React.createRef()
		this.scrollRef = React.createRef()
		this.state = {
			hasErrorInRuleForm: false,
			hasError: this.props.hasError,
			latestVersion: false,
			optionType: [],
			optionTypeArray: [],
			isOptionTypeOpen: false,
			editEnabled: false,
			allowedEngineDetails: this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedEngineDetails ? this.props.editRuleDetails.condition.allowedEngineDetails : [],
			filterSavedData: this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedEngineDetails ? this.props.editRuleDetails.condition.allowedEngineDetails : [],
			// filterSavedData: [{
			// 	'timestamp': '2020-12-12 23:45:35',
			// 	'options': 'RDS',
			// 	'latestVersion': true,
			// 	'optionType': ['mysql v5.7','oracle v.2','aurora v1.0.3','postgreysql v4']
			// }],

			options: [],
			optionValue: '',
			optionDataLabel: '',
			selectedOptionDetails: [],
			latestVersionArray: [],
			latestVersionDetail: '',
		}
	}

	componentDidMount = () => {
		// console.log(this.props)
		let selectedInputs = {}
		selectedInputs['options'] = ''
		selectedInputs['latestVersion'] = false
		selectedInputs['optionType'] = []
		selectedInputs['latestVersionDetail'] = ''
		this.props.setEditRuleDetails('selectedInputs', selectedInputs)

		if(this.props.condition) {
			let options = []
			let optionData = []
			let optionDataLabel = ''
			let latestVersionArray = []
			this.props.condition.length && this.props.condition.forEach(item => {
				Object.entries(item).forEach(([key, value]) => {
					if(key === 'name') {
						options.push(value)
					} else if(key === 'latest') {
						let dataRow = {}
						dataRow.label = item.name
						dataRow.value = value
						latestVersionArray.push(dataRow)
					} else {
						optionDataLabel = key
						let dataRow = {}
						dataRow.label = item.name
						dataRow.value = value
						optionData.push(dataRow)
					}
				})
			})
			this.setState({ options, optionDataLabel, optionData, latestVersionArray })
		}
	}
	
	componentDidUpdate = (prevProps) => {
		if(this.props.hasError !== this.state.hasError) {
			this.setState({ hasError: this.props.hasError })
		}
		
		if(prevProps.condition !== this.props.condition) {
			let options = []
			let optionData = []
			let optionDataLabel = ''
			this.props.condition.length && this.props.condition.forEach(item => {
				Object.entries(item).forEach(([key, value]) => {
					if(key === 'name') {
						options.push(value)
					} else {
						optionDataLabel = key
						let dataRow = {}
						dataRow.label = item.name
						dataRow.value = value
						optionData.push(dataRow)
					}
				})
			})

			this.setState({ options, optionDataLabel, optionData })
		}
	}

	handleMultiSelectChange = (field, arrayValue) => {
		let value = arrayValue.map(item => item.value)
		// let label = arrayValue.forEach(item => item)
		let selectedValue = []
		if(field === 'optionType') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.accounts.forEach(acc => {
						selectedValue.push(acc.account_id)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.optionTypeArray.length -1)) {
					selectedValue.push('All')
				}
			}

			this.setState({ optionType: selectedValue, optionTypeArray: selectedValue })
			let selectedInputs = this.props.editRuleDetails.selectedInputs ? this.props.editRuleDetails.selectedInputs : {}
			selectedInputs['optionType'] = selectedValue
			this.props.setEditRuleDetails('selectedInputs', selectedInputs)


		}			
	}

	getMultiSelectedCount = (type, array) => {
		if(array) {
			return array.length && array.includes('All') ? array.length - 1 +' Selected' :  array.length ? array.length +' Selected' : 'All'
		} else {
			return 'All'
		}
	}

	handleClickOutside(event) {
		if (this.optionTypeRef && this.optionTypeRef.current && !this.optionTypeRef.current.contains(event.target)) {
			this.setState({ isOptionTypeOpen: false })
		} else {
			this.setState({ isOptionTypeOpen: true })
		}

		
		if (this.searchRef && !this.searchRef.current.contains(event.target)) {
			this.setState({ showDropdown: false })
		}
	}
	
	addUpdateSavedData = (type) => {
		
		let selectedInputs = this.props.editRuleDetails.selectedInputs ? this.props.editRuleDetails.selectedInputs : {}
		let allowedEngineDetails = this.props.editRuleDetails.condition.allowedEngineDetails ? this.props.editRuleDetails.condition.allowedEngineDetails : []
		let hasErrorInRuleForm = false
		if(!this.props.editRuleDetails.selectedInputs.options || this.props.editRuleDetails.selectedInputs.options === '') {
			hasErrorInRuleForm = true
		}

		this.setState({ hasErrorInRuleForm })
		if(!hasErrorInRuleForm && Object.entries(selectedInputs).length) {

			let saveData = {}
			saveData['options'] = selectedInputs['options']
			saveData['optionType'] = selectedInputs['optionType']
			saveData['latestVersion'] = selectedInputs['latestVersion'] ? true : false
			if(selectedInputs['latestVersion']) {
				saveData['latestVersionDetail'] = selectedInputs['latestVersionDetail']
			}
			saveData['timestamp'] = currentUTCtime()

			if(type === 'Add') {
				saveData['rowIndex'] = allowedEngineDetails.length
				allowedEngineDetails.push(saveData)
			} else {				
				saveData['rowIndex'] = selectedInputs['rowIndex']
				allowedEngineDetails[this.state.rowIndex] = saveData
			}
			this.setState({
				allowedEngineDetails
			}, () => this.searchSavedData())			
						
			let newCondition = {}
			newCondition['options'] = ''
			newCondition['latestVersion'] = false
			newCondition['optionType'] = ''	
			newCondition['latestVersionDetail'] = ''
			this.props.setEditRuleDetails('selectedInputs', newCondition)

			let condition = {}
			condition['allowedEngineDetails'] = allowedEngineDetails
			this.props.setEditRuleDetails('condition', condition)
			
			this.setState({ 
				allowedEngineDetails,
				editEnabled: false,
				latestVersion: false,
				optionType: [],
				optionTypeArray: [],
				latestVersionDetail: '',
				searchText: ''
			})
		}
	}
	
	searchSavedData = () => {
		let filterSavedData = this.state.allowedEngineDetails
		if (this.state.searchText !== '') {
			filterSavedData =
				filterSavedData &&
				filterSavedData.filter(item => {
					let isPresent = []
					isPresent = this.recursiveSearch(item, this.state.searchText)
						.flat()
						.filter(bool => bool === true)
					if (isPresent[0]) {
						return true
					} else {
						return false
					}
				})
		}
		this.setState({ filterSavedData })
	}
	
	recursiveSearch = (item, searchValue) => {
		return Object.keys(item).map(key => {
			if (item[key] !== null && typeof item[key] == 'object') {
				return this.recursiveSearch(item[key], searchValue)
				// eslint-disable-next-line valid-typeof
			} else if (typeof item[key] === 'array') {
				return item.map((arrayElement, i) => this.recursiveSearch(arrayElement[i], searchValue))
			} else {
				// if(this.props.searchFilter.includes(key)) {
				return item[key] !== null
					? item[key]
							.toString()
							.toLowerCase()
							.indexOf(searchValue.toString().toLowerCase()) > -1
					: false
				// }else{
				// return false;
				// }
			}
		})
	}

	actionOnSavedData = (action, index) => {
		if(action === 'edit') {
			this.scrollRef.current.scrollIntoView({ behavior: 'smooth' })
			let editCondtion = {}
			editCondtion['options'] = this.props.editRuleDetails.condition.allowedEngineDetails[index].options ? this.props.editRuleDetails.condition.allowedEngineDetails[index].options : ''
			editCondtion['latestVersion'] = this.props.editRuleDetails.condition.allowedEngineDetails[index].latestVersion ? true : false
			editCondtion['optionType'] = this.props.editRuleDetails.condition.allowedEngineDetails[index].optionType && this.props.editRuleDetails.condition.allowedEngineDetails[index].optionType !== 'All' ? this.props.editRuleDetails.condition.allowedEngineDetails[index].optionType : []
			editCondtion['rowIndex'] = index
			editCondtion['latestVersionDetail'] = ''
			if(editCondtion['latestVersion']) {
				editCondtion['latestVersionDetail'] = this.props.editRuleDetails.condition.allowedEngineDetails[index].latestVersionDetail
			}
			this.props.setEditRuleDetails('selectedInputs', editCondtion)			

			let selectedOptionDetails = this.state.optionData.filter(arr => arr.label === editCondtion['options'])
			this.setState({ selectedOptionDetails: selectedOptionDetails[0].value,
					editEnabled: true,
					rowIndex: index,
					savedOptions: this.props.editRuleDetails.condition.allowedEngineDetails[index].options,
					latestVersion: this.props.editRuleDetails.condition.allowedEngineDetails[index].latestVersion,
					optionType: this.props.editRuleDetails.condition.allowedEngineDetails[index].optionType,
					optionTypeArray: editCondtion['optionType'],
					latestVersionDetail: editCondtion['latestVersionDetail']
				
			})
		} else {
			let allowedEngineDetails = this.state.allowedEngineDetails.filter((item) => item.rowIndex !== index);
			
			let condition = this.props.editRuleDetails.condition
			condition['allowedEngineDetails'] = allowedEngineDetails
			this.props.setEditRuleDetails('condition', condition)

			this.setState({ allowedEngineDetails, filterSavedData: allowedEngineDetails, searchText: '' })
		}
	}

	handleVersionInput = (input, value) => {
		let selectedInputs = this.props.editRuleDetails.selectedInputs ? this.props.editRuleDetails.selectedInputs : {}
		selectedInputs[input] = value
		if(input === 'latestVersion') {
			selectedInputs['optionType'] = []
			selectedInputs['latestVersionDetail'] = ''
			if(value && this.state.optionValue !== '') {
				let latestVersionArray = this.state.latestVersionArray.filter(arr => arr.label === this.state.optionValue)
				selectedInputs['latestVersionDetail'] = latestVersionArray && latestVersionArray.length && latestVersionArray[0].value
			}
			this.setState({ optionType: [], optionTypeArray: [], latestVersionDetail: selectedInputs['latestVersionDetail'] })
		}
		this.props.setEditRuleDetails('selectedInputs', selectedInputs)
	}

	clearForm = () => {
		// let selectedInputs = this.props.editRuleDetails.selectedInputs ? this.props.editRuleDetails.selectedInputs : {}

		let newCondition = {}
		newCondition['options'] = ''
		newCondition['latestVersion'] = false
		newCondition['optionType'] = ''
		newCondition['latestVersionDetail'] = ''
		this.props.setEditRuleDetails('selectedInputs', newCondition)
		this.setState({ 
			editEnabled: false,
			latestVersion: false,
			optionType: [],
			optionTypeArray: [],
			searchText: '',
			latestVersionDetail: ''
		})
	}

	getOptionData = (options) => {
		let selectedOptionDetails = this.state.optionData.filter(arr => arr.label === options)
		this.setState({ selectedOptionDetails: selectedOptionDetails[0].value },
			() => this.checkSavedData(options)
		)
	}	

	checkSavedData = (options) => {
		let allowedEngineDetails = this.props.editRuleDetails.condition.allowedEngineDetails ? this.props.editRuleDetails.condition.allowedEngineDetails : []
		let exist = allowedEngineDetails.filter((item) => item.options === options);
		if(exist.length) {
			this.actionOnSavedData('edit', exist[0].rowIndex)
		} else {
			let newCondition = {}
			newCondition['options'] = options
			newCondition['latestVersion'] = false
			newCondition['optionType'] = ''
			newCondition['latestVersionDetail'] = ''
			this.props.setEditRuleDetails('selectedInputs', newCondition)

			this.setState({
				editEnabled: false,
				latestVersion: false,
				optionType: [],
				optionTypeArray: [],
				searchText: '',
				latestVersionDetail: ''
			})
		}
	}

	removeSelectedOption = (index, option) => {
		let optionTypeArray = this.state.optionType.filter(e => e !== option)
		// console.log(details)
		// let optionTypeArray = []
		// if(details.length) {
		// 	details.map(item => {
		// 		let dataRow = {}
		// 		if (this.state.selectedOptionDetails.filter(e => e !== item).length) {
		// 			let array = options.filter(e => e === item)
		// 			optionTypeArray.push(array[0])
		// 		}
		// 	})
		// }
		let selectedInputs = this.props.editRuleDetails.selectedInputs ? this.props.editRuleDetails.selectedInputs : {}
		selectedInputs['optionType'] = optionTypeArray
		this.props.setEditRuleDetails('selectedInputs', selectedInputs)
		
		this.setState({ optionType: optionTypeArray, optionTypeArray })
	}

	render() {
		return (
			<div className="row zapInputDark" ref={this.scrollRef}  onClick={ (event) => { this.handleClickOutside(event) } }>
				<div className="col-lg-3 border-right">
					<h6 className="text-info">Engine Details</h6>
					<div className="row">
						<div className="col-md-12">
							<div className="form-group">
								<label>Options</label>
								<Select
									placeholder={'Select'}
									isSearchable={true}
									className='f13 p-0 w-100 z999'
									value={({
										value: this.props.editRuleDetails.selectedInputs && this.props.editRuleDetails.selectedInputs.options ? this.props.editRuleDetails.selectedInputs && this.props.editRuleDetails.selectedInputs.options !== '' && this.props.editRuleDetails.selectedInputs.options : 'Select',
										label: this.props.editRuleDetails.selectedInputs && this.props.editRuleDetails.selectedInputs.options ? this.props.editRuleDetails.selectedInputs && this.props.editRuleDetails.selectedInputs.options !== '' && this.props.editRuleDetails.selectedInputs.options : <span className="placeholder">Select</span>
									})}
									options={this.state.options && this.state.options.map(item => ({
										value: item,
										label: item,	
									}))}
									onChange={event => this.setState({ optionValue: event.value },
										() => this.getOptionData(event.value)
									)}
								/>
							</div>
						</div>
						{this.state.hasErrorInRuleForm && (!this.props.editRuleDetails.selectedInputs.option || this.props.editRuleDetails.selectedInputs.option === '') ? (
							<p className='text-danger m-0 p-0'>Options is Required.</p>
						) : null}
					</div>
				
					<div className="row mb-3">
						<div className="col-md-12">
							<div className="form-group form-check mb-1">
								<input 
									type="checkbox" 
									className="form-check-input" 
									id="latestVersion" 
									checked={this.props.editRuleDetails.selectedInputs && this.props.editRuleDetails.selectedInputs.latestVersion ? true : false}
									onChange={e => this.setState({ latestVersion: this.state.latestVersion ? false : true },
										() => {
											this.handleVersionInput('latestVersion', this.state.latestVersion) 
										}
									)}
								/>
								<label className="form-check-label" for="latestVersion">Allow Only Latest Version</label>
							</div>
						</div>
					</div>
					{this.state.selectedOptionDetails.length ?
						<div className="row mb-3">
							<div className="col-md-12">
								<label>DB Engine Version</label>
								<div className="col-sm-12 p-0">
									<div className={`multiselectDarkTheme mr-3 ${options.length > 10 ? '' : 'removeDropdownSearchBar'} ${this.state.latestVersion ? 'disabled disabledDarkBg' : ''}`} ref={this.optionTypeRef}>
										<ReactMultiSelectCheckboxes						
											placeholderButtonLabel="All"
											// menuIsOpen ={this.state.isOptionTypeOpen}
											getDropdownButtonLabel={() => this.getMultiSelectedCount('optionType', this.state.optionType)}
											value={this.state.optionTypeArray.map(item => ({
												value: item,
												label: item
											}))}
											onChange={arr => { this.handleMultiSelectChange('optionType', arr ? arr : []) }}
											options={this.state.selectedOptionDetails.map(item => ({
												value: item,
												label: item,
											}))}
										/>
									</div>
								</div>
								<div className="d-flex mt-1">
									<div className="flex-grow align-self-center">
										{this.state.optionTypeArray.length ? this.state.optionTypeArray.map((item, index) => {
											return(
												<span key={index} className="badge badge-light f14 mr-1 mb-1" >
													{item}
													<i onClick={ () => this.removeSelectedOption(index, item) } className='ml-1 fal fa-times cursorPointer'></i>
												</span>
											)
										})
										: null
										}
									</div>
								</div>
							</div>
						</div>
					: null
					}
					
					<button className='btn btn-sm btn-info align-self-center mr-3' onClick={() => this.addUpdateSavedData(this.state.editEnabled ? 'Update' : 'Add')}>
						{this.state.editEnabled ? 'Update' : 'Add'}
					</button>
					<button className='btn btn-sm btn-light align-self-center' onClick={() => this.clearForm()}>
						Reset
					</button>
				</div>
				
				<div className="col-lg-9">
					<div className="d-flex justify-content-between mb-2">
						<div className="d-flex align-self-end ">
							<h6 className="text-info m-0 p-0">Added Options</h6>
							{this.state.hasError && !this.state.allowedEngineDetails.length ? 
								<small className='ml-3 text-danger align-self-end'>Please add options.</small>
							: null}
						</div>
						<Input
							type='text'
							value={this.state.searchText}
							onChange={e => {
								this.setState({ searchText: e.target.value }, () => this.searchSavedData())
							}}
							className='inputTextbox w-20'
							placeholder='Search'
						/>
					</div>
					{this.state.filterSavedData && this.state.filterSavedData.length ?
						<React.Fragment>
						{this.state.filterSavedData.map(item => {
							return(
								<div className="p-3 bg-dark rounded mb-3">
									<div className="row">
										<div className="col-lg-2 col-sm-3 align-self-center">
											<label className="small d-block mb-0">Options</label>
											<label className={`badge badge-outline-light mr-1`}>{item.options}</label>
										</div>
										<div className="col-lg-2 col-sm-3 align-self-center">
											<label className="small d-block mb-0">Latest Image Version</label>
											<small className={`badge ${item.latestVersion ? 'badge-outline-success' : 'badge-outline-danger'}`}>{item.latestVersion ? 'Yes' : 'No'}</small>
										</div>
										<div className="col-lg-5 col-sm-3 align-self-center">
											<label className="small d-block mb-0">DB Engine Version</label>
											{item.optionType && item.optionType.length ? 
												item.optionType.map((type, i) => {
													return(
														<label className={`badge badge-outline-light mr-1`}>{type}</label>
													)
												})
											: 
												<label className={`badge badge-outline-light mr-1`}>{item.latestVersionDetail}</label>
											}
										</div>
										<div className="col-lg-3 col-sm-3 align-self-center">
											<button className="btn btn-outline-primary btn-sm border-0" onClick={() => this.actionOnSavedData('edit', item.rowIndex)}>Edit</button>
											<button className="btn btn-outline-danger btn-sm ml-3 border-0" onClick={() => this.actionOnSavedData('remove', item.rowIndex)}>Delete</button>
										</div>
									</div>
								</div>
							)
						})}
						</React.Fragment>
					: null
					}
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ServiceVersion.propTypes = {
	setEditRuleDetails: PropTypes.func,
	condition: PropTypes.object,
	editRuleDetails: PropTypes.object,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('stateVersion',state.governance)
	return {
		editRuleDetails: state.governance.editRuleDetails,
	}
}

export default connect(mapStateToProps, { setEditRuleDetails })(ServiceVersion)
