/*************************************************
 * Tvastar
 * @exports
 * @file PolicyDetails.js
 * @author Prakash // on 09/08/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { AppWrapper } from '../../common/AppWrapper'
// import PropTypes from 'prop-types'
import _, { truncate } from 'lodash'

import { Spinner, Input } from 'reactstrap'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import { store as CommonNotification } from 'react-notifications-component'

import { URL_PATH } from '../../../config/urlPath'

import {
	currentUTCtime,
	currentLocaltime,
	subDays,
	getAccountNameFromId,
	getRegionName,
	capitalizeFirstLetter,
} from '../../../utils/utility'
import {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
	getAllTagsKeys,
	getAllTagsValues,
} from '../../../actions/commonAction'
import {
	setNewInitialRuleDetails,
	setNewEditRuleDetails,
	setGovernancePropsDetails,
	getMasterGovernanceRules,
	createGovernancePolicy,
	editGovernancePolicy,
	getGovernanceServices,
} from '../../../actions/governance/newGovernanceAction'
import { listAssets } from '../../../actions/detectors/detectorsAction'

import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import RdsServiceEnginesTemplate from './pages/RDS/RdsServiceEnginesTemplate'
import ServiceLogs from './pages/RDS/ServiceLogs'
import ServiceCapacity from './pages/RDS/ServiceCapacity'
import ServiceBlockStore from './pages/RDS/ServiceBlockStore'

import DynamicServices from './pages/RDS/DynamicServices'
import ViewSidePanel from './ViewSidePanel'
import GlobalServiceTemplate from './pages/RDS/GlobalServiceTemplate'
import OPAServiceTemplate from './pages/RDS/OPAServiceTemplate'
import ManagmentPolicy from './pages/RDS/ManagmentPolicy'

class PolicyDetails extends Component {
	constructor(props) {
		super(props)
		this.accountRef = React.createRef()
		this.regionRef = React.createRef()
		this.daterRangeListRef = React.createRef()

		this.state = {
			listLoading: false,
			serviceArray: [0],

			finishBtn: 'Save',

			showRuleInfoSection: true,

			showAppliedSection: false,
			appliesToArray: [0],

			governanceTypes: [
				{ label: 'Access Managment Policy', value: 'access' },
				{ label: 'Global Policy', value: 'global' },
				{ label: 'Infrastructure Policy', value: 'infra' },
				{ label: 'OPA Policy', value: 'opa' },
				{ label: 'Kubernetes(Gatekeeper) Policy', value: 'opa_kubernetes' },
			],
			// governance_type: "infra",

			showActionSection: false,
			actionArray: [0],

			showNotificationSection: false,
			notificationArray: [0],
			notificationType: ['Slack', 'Email'],

			actionMethods: [
				{ value: 'immediately', label: 'Immediately after the violation' },
				{ value: 'run_at', label: 'At' },
				{ value: 'run_after', label: 'After' },
			],
		}
	}

	componentDidMount = () => {
		// if(this.props.providers && this.props.providers.length >= 1) {
		// 	let selectedProvider = this.props.providers[0].provider_name

		// 	this.setState({ selectedProvider, providers: this.props.providers },
		// 		() => {
		// 			this.filterData("provider")
		// 		}
		// 	)
		// } else {
		this.props.listAllProviders((promise, response) => {
			if (promise) {
				this.setState(
					{
						providers: response.length ? response : [],
						selectedProvider: response.length ? response[0].provider_name : '',
					},
					() => {
						if (this.state.selectedProvider && this.state.selectedProvider !== '') {
							this.handleInputChange('provider', this.state.selectedProvider.toLowerCase())
						}
						this.filterData('provider')
					}
				)
			} else {
				this.setState({ providers: [] }, () => {
					this.filterData()
				})
			}
		})

		this.getAllTagsKeys()

		if (
			this.props.history &&
			this.props.history.location &&
			this.props.history.location.state &&
			this.props.history.location.state &&
			this.props.history.location.state.ruleDetails
		) {
			let ruleDetails = this.props.history.location.state.ruleDetails
			let policyBasicDetails = ruleDetails.policy_metadata ? ruleDetails.policy_metadata : {}
			let policyInformation = ruleDetails.policies && ruleDetails.policies.length ? ruleDetails.policies : []
			this.setState(
				{
					ruleDetails,
					policyBasicDetails,
					policyInformation,
					selectedProvider: policyBasicDetails.provider ? policyBasicDetails.provider : this.state.selectedProvider,
					governance_type: policyBasicDetails.governance_type
						? policyBasicDetails.governance_type
						: this.state.governance_type,
				},
				() => {
					this.structureEditData()
					this.setPolicyBasicDetails()
					this.getGovernanceServices()
				}
			)
		} else {
			this.setState({ ruleDetails: {} })
		}
	}

	filterData = () => {
		let params = {}
		if (this.state.selectedProvider && this.state.selectedProvider !== '') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if (this.props.accounts && this.props.accounts.length) {
			let allAccounts = this.props.accounts
			if (this.state.selectedProvider && this.state.selectedProvider !== '') {
				allAccounts = this.props.accounts.filter(
					account => account.provider === this.state.selectedProvider.toLowerCase()
				)
			}
			if (this.props.accounts.length) {
				let accRow = {}
				accRow.account_id = 'All'
				accRow.account_name = 'All'
				accRow.date = null
				accRow.disabled = false
				accRow.provider = 'All'
				allAccounts.unshift(accRow)
			}
			this.setState({ accounts: allAccounts })
		} else {
			this.props.listAllAccounts(params, (promise, allAccounts) => {
				if (promise) {
					let filterdAccounts = allAccounts
					if (this.state.selectedProvider && this.state.selectedProvider !== '') {
						filterdAccounts = allAccounts.filter(
							account => account.provider === this.state.selectedProvider.toLowerCase()
						)
					}
					if (filterdAccounts.length) {
						let accRow = {}
						accRow.account_id = 'All'
						accRow.account_name = 'All'
						accRow.date = null
						accRow.disabled = false
						accRow.provider = 'All'
						filterdAccounts.unshift(accRow)
					}
					this.setState({ accounts: filterdAccounts })
				}
			})
		}

		if (this.props.regions && this.props.regions.length) {
			let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
			this.setState({ regions: providerRegions })
		} else {
			this.props.listAllRegions(params, (promise, regions) => {
				if (promise) {
					let providerRegions = regions && regions[0] && regions[0].provider_regions
					if (providerRegions) {
						let provider_regions_row = {}
						provider_regions_row.availability_zones = []
						provider_regions_row.city = 'All'
						provider_regions_row.country = 'All'
						provider_regions_row.geography = 'All'
						provider_regions_row.name = 'All'
						provider_regions_row.region = 'All'
						provider_regions_row.region_id = 'All'
						provider_regions_row.state = 'All'
						providerRegions.unshift(provider_regions_row)
					}
					this.setState({ regions: providerRegions })
				} else {
					this.setState({ regions: [] })
				}
			})
		}
	}

	listAssets = (type, item) => {
		let params = {}
		if (
			this.state['selected_account_' + item] &&
			this.state['selected_account_' + item].length &&
			this.state['selected_account_' + item][0] !== 'All'
		) {
			params.account_id = this.state['selected_account_' + item]
		}
		if (type === 'users') {
			params.service_name = 'iam-users'
		} else {
			params.service_name = 'iam-roles'
		}
		this.props.listAssets(params, (promise, response) => {
			if (promise) {
				this.setState({ ['applies_to_' + type + '_' + item]: response.results ? response.results : [] })
			} else {
				this.setState({ ['applies_to_' + type + '_' + item]: [] })
			}
		})
	}

	structureEditData = () => {
		let policyInformation = this.state.policyInformation //editData.policies
		let policyBasicDetails = this.state.policyBasicDetails //editData.policy_metadata
		let policies = []
		let serviceArray = []
		policyInformation.forEach((item, index) => {
			serviceArray.push(index)
			item.categories.forEach(cat => {
				cat.policies.forEach(catPol => {
					catPol.resource_type = item.resource_type
					policies.push(catPol)
				})
			})
			this.setState(
				{
					['resource_type_' + index]: item.resource_type,
					serviceArray,
					selectedProvider: policyBasicDetails && policyBasicDetails.provider ? policyBasicDetails.provider : 'aws',
				},
				() => {
					this.masterData('resource_type_' + index, this.state['resource_type_' + index], index)
				}
			)
		})
		let groupedPolicies = { policies: policies }
		let mergeObjects = { ...groupedPolicies, ...policyBasicDetails }
		this.props.setNewInitialRuleDetails(mergeObjects)
	}

	setPolicyBasicDetails = () => {
		let policyBasicDetails = this.state.policyBasicDetails

		let applies_to = policyBasicDetails.applies_to ? policyBasicDetails.applies_to : []
		let appliesToArray = [0]
		if (applies_to.length) {
			appliesToArray = []
			applies_to.forEach((row, index) => {
				appliesToArray.push(index)
				this.setState({
					['selected_account_' + index]: row.account_id,
					['selected_region_' + index]: row.region ? row.region : [],
					['selected_tags_' + index]: row.tags ? row.tags : [],
					['cluster_id_' + index]: row.cluster_id,
					['cluster_name_' + index]: row.cluster_name,
				})
			})
		}

		let notifications = policyBasicDetails.notifications ? policyBasicDetails.notifications : []
		let notificationArray = [0]
		if (notifications.length) {
			notificationArray = []
			notifications.forEach((row, index) => {
				notificationArray.push(index)
				this.setState({
					['selected_notfication_type_' + index]: row.notfication_type,
					['shared_details_' + index]: row.notification_id ? row.notification_id : [],
				})
			})
		}

		this.setState({ appliesToArray, applies_to, notificationArray, notifications })
	}

	getGovernanceServices = () => {
		let params = {}
		params.governance_type = this.state.governance_type

		if (params.governance_type) {
			this.props.getGovernanceServices(params, (promise, response) => {
				if (promise) {
					this.setState({ services: response })
				} else {
					this.setState({ services: [] })
				}
			})
		}
	}

	onClickConfigureSection = () => {
		if (this.state.governance_type) {
			this.setState({ showPolicySection: !this.state.showPolicySection })
		}
	}

	masterData = (field, value, index) => {
		let params = {}

		if (this.state.governance_type !== 'global' && this.state.governance_type !== 'opa' && this.state.governance_type !== 'opa_kubernetes') {
			params.resource_type = this.state[field] && this.state[field] !== '' ? this.state[field] : ''
			params.provider = this.state.selectedProvider && this.state.selectedProvider !== '' ? this.state.selectedProvider.toLowerCase() : ''
		}
		params.governance_type = this.state.governance_type

		let checkServicesExist = this.props.service_master_details && Object.keys(this.props.service_master_details).includes(value)
		if (!checkServicesExist && params.provider !== '' && params.resource_type !== '') {
			this.props.getMasterGovernanceRules(params, (promise, response) => {
				if (promise) {
					this.setState({ ['masterData_' + value]: response }, () => this.onClickServicesBlock(field, value, index))
					if (response && response.length) {
						let obj = this.props.service_master_details
						obj[value] = response

						this.props.setGovernancePropsDetails('service_master_details', obj)
					}
				} else {
					this.setState({ masterData: [] }, () => this.onClickServicesBlock(field, value, index))
				}
			})
		}
	}

	getGloableMasterData = type => {
		let field = type
		let value = type
		let index = 0
		let params = {}
		
		if (this.state.governance_type !== 'opa' && this.state.governance_type !== 'opa_kubernetes') {
			params.provider =
				this.state.selectedProvider && this.state.selectedProvider !== ''
					? this.state.selectedProvider.toLowerCase()
					: ''
		}
		params.governance_type = this.state.governance_type

		this.props.getMasterGovernanceRules(params, (promise, response) => {
			if (promise) {
				this.setState({ ['masterData_' + value]: response, serviceArray: [value] }, () =>
					this.onClickServicesBlock(field, value, index)
				)
				if (response && response.length) {
					let obj = this.props.service_master_details
					obj[value] = response

					this.props.setGovernancePropsDetails('service_master_details', obj)
				}
			} else {
				this.setState({ masterData: [] }, () => this.onClickServicesBlock(field, value, index))
			}
		})
	}

	onClickServicesBlock = (field, value, index) => {
		if (value === 'global' || value === 'opa' || value == 'opa_kubernetes') {
			this.setState({ ['show_resource_type_' + value]: true, ['resource_type_' + value]: value })
		} else {
			this.state.services &&
				this.state.services.forEach(row => {
					if (row === value) {
						this.setState({ ['show_resource_type_' + value]: true })
					} else {
						this.setState({ ['show_resource_type_' + row]: false })
					}
				})
		}
	}

	closeOtherResource = (field, value, index) => {
		this.state.services &&
			this.state.services.forEach(row => {
				if (row !== value) {
					this.setState({ ['show_resource_type_' + row]: false })
				}
			})
	}

	handleMultiSelectChange = (field, arrayValue, state) => {
		let value = arrayValue.map(item => (item.value ? item.value : item))
		let selectedValue = []
		if (field === 'selectedRegion' || field === 'selected_region') {
			if (field === 'selected_region') {
				field = field + '_' + state
			}

			let prevState = this.state[field] ? this.state[field] : []
			if (value.includes('All')) {
				if (!prevState.includes('All')) {
					this.state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf('All')
					if (index > -1) {
						value.splice(index, 1)
					}
					selectedValue = value
				}
			} else if (!prevState.includes('All')) {
				selectedValue = value
			}

			// if(selectedValue.length && !selectedValue.includes("All")) {
			// 	if(!selectedValue.includes("All") && selectedValue.length === (this.state.regions.length -1)) {
			// 		selectedValue.push("All")
			// 	}
			// }
			if (field === 'selected_region') {
				field = field + '_' + state
			}
			this.setState({ [field]: selectedValue }, () => this.onChangeApliesTo())
		} else {
			//for array
			if (state) {
				let prevState = this.state[field] ? this.state[field] : []
				if (prevState.length === 1 && prevState[0] === 'All') {
					prevState = []
				}
				if (value.includes('All')) {
					if (!prevState.includes('All')) {
						if (state.filter(e => e.value).length) {
							selectedValue = state.map(e => e.value)
						} else {
							selectedValue = state
						}
					} else {
						const index = value.indexOf('All')
						if (index > -1) {
							value.splice(index, 1)
						}
						selectedValue = value
					}
				} else if (!prevState.includes('All')) {
					selectedValue = value
				}

				if (selectedValue.length && !selectedValue.includes('All')) {
					if (!selectedValue.includes('All') && selectedValue.length === state.length - 1) {
						selectedValue.push('All')
					}
				}
			} else {
				selectedValue = value
			}
			this.setState({ [field]: selectedValue }, () => this.onChangeApliesTo())
		}
	}

	getMultiSelectedCount = (type, array) => {
		return array.length && array.includes('All') ? 'All' : array.length ? array.length + ' Selected' : 'All'
	}

	handleClickOutside = event => {}

	handleInputChange = (label, value) => {
		if (label === 'governance_type' && this.state.governance_type) {
			// if(window.confirm( 'Are you sure you want to delete this Card?') == true) {
			this.props.setNewEditRuleDetails('policies', [])
			this.props.setNewEditRuleDetails('applies_to', [])
			this.props.setNewEditRuleDetails(label, value)

			if (this.state.appliesToArray && this.state.appliesToArray.length) {
				this.state.appliesToArray.forEach(item => {
					this.setState({
						['selected_account_' + item]: [],
						['selected_region_' + item]: [],
						['selected_users_' + item]: [],
						['selected_roles_' + item]: [],
						['selected_tags_' + item]: [],
						['tag_key_' + item]: '',
						['tag_value_' + item]: [],
						['cluster_name_' + item]: '',
						['cluster_id_' + item]: '',
						['name_space_' + item]: [],
					})
				})
			}

			this.setState({ appliesToArray: [0] })
			// }
		} else {
			this.props.setNewEditRuleDetails(label, value)
		}
	}

	removeRegionSection = (item, index, reg) => {
		// filteredResult = this.state.selectedTags.filter(e => e.key !== tag.key && e.value !== tag.value)
		let filteredResult = this.state['selected_region_' + item].filter(e => e !== reg)
		this.setState({ ['selected_region_' + item]: filteredResult }, () =>
			this.handleMultiSelectChange('selected_region', filteredResult, item)
		)
	}

	removeUserRolsSection = (field, item, index, row) => {
		// filteredResult = this.state.selectedTags.filter(e => e.key !== tag.key && e.value !== tag.value)
		let filteredResult = this.state[field + '_' + index].filter(e => e !== row)
		this.setState({ [field + '_' + index]: filteredResult }, () =>
			this.handleMultiSelectChange(field + '_' + index, filteredResult, item)
		)
	}

	getAllTagsKeys = () => {
		if (!this.props.tagKeys || !this.props.tagKeys.length) {
			let params = {}
			// params.account_id = "265469181489"
			// params.region = "us-east-2"
			this.props.getAllTagsKeys(params, (promise, response) => {})
		}
	}

	getAllTagsValues = item => {
		let params = {}
		params.tags = [{ key: this.state['tag_key_' + item] }]
		this.props.getAllTagsValues(params, (promise, response) => {
			if (promise) {
				this.setState({ ['tagValues' + item]: response })
			}
		})
	}

	addNewTag = item => {
		let selectedTags = this.state['selected_tags_' + item] ? this.state['selected_tags_' + item] : []

		if (
			this.state['tag_key_' + item] &&
			this.state['tag_key_' + item] !== '' &&
			this.state['tag_value_' + item] &&
			this.state['tag_value_' + item] !== ''
		) {
			let dataRow = {
				key: this.state['tag_key_' + item],
				value: this.state['tag_value_' + item],
			}
			selectedTags.push(dataRow)
		}

		this.setState({
			['selected_tags_' + item]: selectedTags,
			['tag_key_' + item]: '',
			['tag_value_' + item]: '',
			tagValues: [],
		})
	}

	removeTagSection = (item, tag) => {
		// filteredResult = this.state.selectedTags.filter(e => e.key !== tag.key && e.value !== tag.value)
		let filteredResult = this.state['selected_tags_' + item].filter(e => e !== tag)
		this.setState({ ['selected_tags_' + item]: filteredResult }, () => this.onChangeApliesTo())
	}

	addNewNameSpace = item => {
		let selectedNameSpace = this.state['name_space_' + item] ? this.state['name_space_' + item] : []

		if (this.state['name_space_to_' + item] && this.state['name_space_to_' + item] !== '') {
			let data = this.state['name_space_to_' + item]

			selectedNameSpace.push(data)
		}

		this.setState(
			{
				['name_space_' + item]: selectedNameSpace,
				['name_space_to_' + item]: '',
			},
			() => this.onChangeApliesTo()
		)
	}

	removeNameSpace = (item, spaceTo) => {
		let filteredResult = this.state['name_space_' + item].filter(e => e !== spaceTo)
		this.setState({ ['name_space_' + item]: filteredResult }, () => this.onChangeApliesTo())
	}

	addSection = array => {
		let rowList = this.state[array]
		if (this.state[array]) {
			let value = this.state[array][this.state[array].length - 1]
			value = value + 1
			rowList.push(value)
		}

		this.setState({ [array]: rowList })
	}

	removeSection = (array, i) => {
		let rowList = this.state[array].filter(e => e !== i)
		this.setState({ [array]: rowList })
	}

	addNewSharedDetails = item => {
		let sharedDetails = this.state['shared_details_' + item] ? this.state['shared_details_' + item] : []
		if (this.state['shared_to_' + item] && this.state['shared_to_' + item] !== '') {
			sharedDetails.push(this.state['shared_to_' + item])
		}

		this.setState({ ['shared_details_' + item]: sharedDetails, ['shared_to_' + item]: '' })
	}

	removeSharedDetails = (item, sharedTo) => {
		let filteredResult = this.state['shared_details_' + item].filter(e => e !== sharedTo)
		this.setState({ ['shared_details_' + item]: filteredResult })
	}

	showPolicy = (array, showField, index) => {
		this.state[array].forEach(item => {
			if (index === item) {
				this.setState({ [showField + '_' + item]: !this.state[showField + '_' + item] })
			} else {
				this.setState({ [showField + '_' + item]: false })
			}
		})
	}

	addPolicies = (array, showField, index) => {
		let rowList = this.state[array]
		if (this.state[array]) {
			let value = this.state[array][this.state[array].length - 1]
			value = value + 1
			rowList.push(value)
		}
		rowList.forEach(item => {
			if (index === item) {
				this.setState({ [showField + '_' + item]: true })
			} else {
				this.setState({ [showField + '_' + item]: false })
			}
		})

		this.setState({ [array]: rowList })
	}

	onFinish = () => {
		let hasError = false
		let data = this.props.editNewRuleDetails

		if (this.props.editNewRuleDetails.governance_type !== 'opa' && (!data.provider || data.provider === '')) {
			hasError = true
		}

		if (!data.policy_name || data.policy_name === '') {
			hasError = true
		}

		let applies_to = []

		if (this.state.appliesToArray && this.state.appliesToArray.length) {
			let applyToDataExist = false

			if (this.state.appliesToArray) {
				this.state.appliesToArray.forEach(item => {
					//this.state["selected_region_"+item] && this.state["selected_region_"+item].length)
					if(this.props.editNewRuleDetails.governance_type !== 'opa_kubernetes') {
						if (this.state['selected_account_' + item] && this.state['selected_account_' + item] !== '') {
							applyToDataExist = true	
						}
					} else if((this.state['cluster_name_' + item] && this.state['cluster_name_' + item] !== '') && (this.state['cluster_id_' + item] && this.state['cluster_id_' + item] !== '')) {
						applyToDataExist = true
					}
				})
			}
			
			if (this.props.editNewRuleDetails.governance_type !== 'opa' && !applyToDataExist) {
				hasError = true
			}
		}

		let notifications = []

		if (this.state.notificationArray) {
			let notficationDataExist = false
			this.state.notificationArray.forEach(item => {
				if (
					this.state['selected_notfication_type_' + item] &&
					this.state['shared_details_' + item] &&
					this.state['shared_details_' + item].length
				) {
					notficationDataExist = true

					let notficationRow = {}
					notficationRow.notfication_type = this.state['selected_notfication_type_' + item]
					notficationRow.notification_id = this.state['shared_details_' + item]
					notifications.push(notficationRow)
				}
			})

			if (!notficationDataExist) {
				hasError = true
			}
		}
		this.handleInputChange('notifications', notifications)

		this.setState({ hasError, onClickFinish: true }, () => this.validateInput(hasError))

		// setTimeout(() => {
		// 	this.props.history.push({
		// 		pathname: URL_PATH.GOVERNANCE_MANAGE_RULES,
		// 		state: { showRules: true },
		// 	})
		// }, 100)
	}

	createPolicy = () => {
		let params = this.props.editNewRuleDetails

		if (this.props.editNewRuleDetails.governance_type === 'opa_kubernetes') {
			const { provider, ...rest } = this.props.editNewRuleDetails; 
			delete rest.provider; 
			params = rest
		}

		// let groupResourceType = _.chain(params.policies).groupBy("resource_type") .map((value, key) => ({ resource_type: key, data: value })).value();

		// let policies = []
		// groupResourceType.forEach(rt => {
		// 	let resRow= {}
		// 	resRow.resource_type = rt.resource_type
		// 	let groupRuleCategory = _.chain(rt.data).groupBy("rule_category") .map((value, key) => ({ rule_category: key, policy: value })).value()
		// 	let categories = []
		// 	groupRuleCategory.forEach(cat => {
		// 		let catRow = {}
		// 		catRow.rule_category = cat.rule_category
		// 		catRow.policies = cat.policy
		// 		categories.push(catRow)
		// 	})
		// 	resRow.categories = categories

		// 	policies.push(resRow)
		// })
		// params.policies = policies
		this.props.createGovernancePolicy(params, (promise, resposne) => {
			// this.setState({ disableFinish : false, finishBtn: 'Finish', queryLoading: false })

			if (promise) {
				let messageType = 'success'
				let message = 'User governance policy saved successfully'
				CommonNotification.addNotification({
					//title: "Wonderful!",
					message: message,
					type: messageType,
					insert: 'top',
					container: 'top-center',
					// animationIn: ["animate__animated", "animate__fadeIn"],
					// animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
						onScreen: false,
						pauseOnHover: true,
						showIcon: true,
					},
				})
				this.setState({ showLoading: false, finishBtn: 'Saved' }, () => this.setTimeoutRedirect())
			} else {
				this.setState({ disableFinish: false, finishBtn: 'Save', queryLoading: false })
			}
		})
	}

	updatePolicy = () => {
		let params = this.props.editNewRuleDetails
		// console.log("updatePolicy",params)
		this.props.editGovernancePolicy(params, (promise, response) => {
			if (promise) {
				let messageType = 'success'
				let message = 'User governance policy updated successfully'
				CommonNotification.addNotification({
					//title: "Wonderful!",
					message: message,
					type: messageType,
					insert: 'top',
					container: 'top-center',
					// animationIn: ["animate__animated", "animate__fadeIn"],
					// animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
						onScreen: false,
						pauseOnHover: true,
						showIcon: true,
					},
				})
				this.setState({ queryLoading: false, finishBtn: 'Saved' }, () => this.setTimeoutRedirect())
			} else {
				this.setState({ disableFinish: false, finishBtn: 'Save' })
			}
		})
	}

	setTimeoutRedirect = ruleId => {
		setTimeout(() => {
			this.props.history.push({
				pathname: URL_PATH.GOVERNANCE_MANAGE_RULES,
				state: { showRules: true, queryLoading: false },
			})
		}, 5000)
	}

	validatePolicyInput = (category, boolean, polIndex) => {
		this.setState({ [category]: boolean })
	}

	validateInput = hasError => {
		let hasPolicyError = false

		let policies =
			this.props.editNewRuleDetails && this.props.editNewRuleDetails.policies
				? this.props.editNewRuleDetails.policies
				: []

		if (!hasPolicyError && !hasError) {
			policies.forEach(pol => {
				if (pol.variables && pol.variables.length) {
					pol.variables.forEach(varb => {
						if (!hasPolicyError && (!varb.manditory || varb.manditory === 'undefined')) {
							hasPolicyError = false
						} else if (!hasPolicyError) {
							if (varb.value && varb.value) {
								hasPolicyError = true
							} else if (varb.value_list && !varb.value_list.length) {
								hasPolicyError = true
							}
						}
					})
				}
			})
		}

		if (!hasPolicyError && !hasError) {
			this.setState({ queryLoading: true }, () => {
				if (this.state.ruleDetails && Object.entries(this.state.ruleDetails).length) {
					this.updatePolicy()
				} else {
					this.createPolicy()
				}
			})
		}
	}

	onChangeApliesTo = () => {
		let applies_to = []

		if (this.state.appliesToArray && this.state.appliesToArray.length) {
			if (this.state.appliesToArray) {
				this.state.appliesToArray.forEach(item => {
					let appliesRow = {}
					if (this.props.editNewRuleDetails.governance_type !== 'opa_kubernetes') {
						if (this.state['selected_account_' + item] && this.state['selected_account_' + item] !== '') {
							appliesRow.account_id = this.state['selected_account_' + item]
							appliesRow.region = this.state['selected_region_' + item] ? this.state['selected_region_' + item] : []
							if (this.state.governance_type === 'infra') {
								appliesRow.tags =
									this.state['selected_tags_' + item] && this.state['selected_tags_' + item].length
										? this.state['selected_tags_' + item]
										: []
							} else {
								appliesRow.users =
									this.state['selected_users_' + item] && this.state['selected_users_' + item].length
										? this.state['selected_users_' + item]
										: []
								appliesRow.roles =
									this.state['selected_roles_' + item] && this.state['selected_roles_' + item].length
										? this.state['selected_roles_' + item]
										: []
							}
							applies_to.push(appliesRow)
						}
					} else if (this.state['cluster_name_' + item] && this.state['cluster_name_' + item] !== '') {
						appliesRow.cluster_name = this.state['cluster_name_' + item]
						appliesRow.cluster_id = this.state['cluster_id_' + item]
						appliesRow.namespaces =
							this.state['name_space_' + item] && this.state['name_space_' + item].length
								? this.state['name_space_' + item]
								: []
						applies_to.push(appliesRow)
					}
				})
			}
		}
		this.setState({ applies_to })
		this.handleInputChange('applies_to', applies_to)
	}

	render() {
		return (
			<div
				className='container-fluid overflow-auto flex-grow-1 bg-muted'
				onClick={event => {
					this.handleClickOutside(event)
				}}
			>
				<div className={`loaderOverlay ${this.state.queryLoading ? '' : 'displayNone'}`}>
					<div className='overlayEqualizerLoader'>
						<div className='spinner-item'></div>
						<div className='spinner-item'></div>
						<div className='spinner-item'></div>
						<div className='spinner-item'></div>
						<div className='spinner-item'></div>
					</div>
				</div>
				{this.state.showDetailsPanel ? (
					<ViewSidePanel
						masterData={this.state.masterData}
						closeSidePanel={() => this.setState({ showDetailsPanel: false })}
					/>
				) : null}
				<div className='row h-100'>
					<div className='col-sm-12 p-0'>
						<div className='title-section pb-3'>
							<div className='d-flex justify-content-between'>
								<div className='align-self-center'>
									<div className='d-flex'>
										<h6 className='text-white m-0'>Policies</h6>
									</div>
									<p className='text-white m-0'>Governance policies on your Cloud</p>
								</div>
								{this.state.ruleDetails && Object.entries(this.state.ruleDetails).length ? (
									<p
										className='text-info align-self-center cursorPointer'
										onClick={() => this.setState({ showDetailsPanel: true })}
									>
										Policy Details
									</p>
								) : null}
							</div>
						</div>

						<div className='p-2'>
							<div className='rounded bg-dark p-3 zapInputDark'>
								<div className='d-flex justify-content-between'>
									<p
										className='mb-2 text-white f20 cursorPointer'
										onClick={() => this.setState({ showRuleInfoSection: !this.state.showRuleInfoSection })}
									>
										Policy Information
									</p>
									<span
										className={`ml-4 fa fa-angle-${
											this.state.showRuleInfoSection ? 'up' : 'down'
										} f22 text-primary-color cursorPointer`}
										onClick={() => this.setState({ showRuleInfoSection: !this.state.showRuleInfoSection })}
									></span>
								</div>
								{this.state.showRuleInfoSection ? (
									<React.Fragment>
										<div className={`d-flex`}>
											<div className='col-sm-6 pl-0'>
												<div className='d-flex'>
													<div className='col-sm-4 pl-0'>
														<div className='d-flex justify-content-between'>
															<label className='align-self-center m-0'>Policy Type </label>
															{this.state.hasError &&
															(!this.props.editNewRuleDetails.governance_type ||
																this.props.editNewRuleDetails.governance_type === '') ? (
																<small className='text-issues'>required</small>
															) : null}
														</div>
														<Select
															isSearchable={true}
															className={`f13 p-0 ${
																this.props.ruleDetails && Object.entries(this.props.ruleDetails).length
																	? 'disabled disabledDarkBg'
																	: ''
															}`}
															value={{
																value:
																	this.props.editNewRuleDetails.governance_type &&
																	this.props.editNewRuleDetails.governance_type !== ''
																		? this.props.editNewRuleDetails.governance_type
																		: '',
																label:
																	this.props.editNewRuleDetails.governance_type &&
																	this.props.editNewRuleDetails.governance_type.toLowerCase() !== '' ? (
																		this.state.governanceTypes &&
																		this.state.governanceTypes.filter(
																			e => e.value === this.props.editNewRuleDetails.governance_type
																		)[0].label
																	) : (
																		<span className='placeholder'>Select</span>
																	),
															}}
															options={
																this.state.governanceTypes &&
																this.state.governanceTypes.map(item => ({
																	value: item.value,
																	label: item.label,
																}))
															}
															onChange={event =>
																this.setState({ governance_type: event.value }, () => {
																	if (this.props.editNewRuleDetails.governance_type !== event.value) {
																		this.handleInputChange('governance_type', event.value)
																		this.props.setGovernancePropsDetails('service_master_details', {})

																		if (event.value === 'global' || event.value === 'opa' || event.value === 'opa_kubernetes') {
																			this.getGloableMasterData(event.value)
																		} else {
																			this.getGovernanceServices()
																		}
																	}
																})
															}
														/>
													</div>
													<div className='col-sm-8 p-0'>
														<div className='d-flex justify-content-between'>
															<label className='align-self-center m-0'>Name of the policy</label>
															{this.state.hasError &&
															(!this.props.editNewRuleDetails.policy_name ||
																this.props.editNewRuleDetails.policy_name === '') ? (
																<small className='text-issues'>required</small>
															) : null}
														</div>
														<Input
															type='text'
															placeholder='Enter Policy Name'
															className='inputTextbox'
															maxlength='128'
															value={
																this.props.editNewRuleDetails.policy_name &&
																this.props.editNewRuleDetails.policy_name !== ''
																	? this.props.editNewRuleDetails.policy_name
																	: ''
															}
															onChange={e => this.handleInputChange('policy_name', e.target.value)}
														/>
													</div>
												</div>
												{this.props.editNewRuleDetails.governance_type !== 'opa' &&
												this.props.editNewRuleDetails.governance_type !== 'opa_kubernetes' ? (
													<div className='d-flex mt-3'>
														<div className='col-sm-4 pl-0 blackBgReactSelect'>
															<div className=''>
																<div className='d-flex justify-content-between'>
																	<label className='align-self-center m-0'>Provider </label>
																	{this.state.hasError &&
																	((this.state.editNewRuleDetails && !this.state.editNewRuleDetails.provider) ||
																		(this.state.editNewRuleDetails &&
																			this.props.editNewRuleDetails.provider === '')) ? (
																		<small className='text-issues'>required</small>
																	) : null}
																</div>
																<Select
																	isSearchable={false}
																	className={`f13 p-0 ${
																		this.props.ruleDetails && Object.entries(this.props.ruleDetails).length
																			? 'disabled disabledDarkBg'
																			: ''
																	}`}
																	value={{
																		value:
																			this.props.editNewRuleDetails.provider &&
																			this.props.editNewRuleDetails.provider !== ''
																				? this.props.editNewRuleDetails.provider
																				: '',
																		label:
																			this.props.editNewRuleDetails.provider &&
																			this.props.editNewRuleDetails.provider.toLowerCase() !== '' ? (
																				this.props.editNewRuleDetails.provider.toUpperCase()
																			) : (
																				<span className='placeholder'>Select</span>
																			),
																	}}
																	options={this.state.providers &&
																		this.state.providers.map(row => ({
																			value: row.provider_name,
																			label: row.provider_name,
																		}))
																	}
																	onChange={event =>
																		this.setState({ selectedProvider: event.value }, () => {
																			if (this.props.editNewRuleDetails.provider !== event.value) {
																				this.handleInputChange('provider', event.value.toLowerCase())
																			}
																		})
																	}
																/>
															</div>
														</div>
													</div>
												) : null}
											</div>
											<div className='col-sm-6 pr-0'>
												<label className='align-self-center m-0'>Description</label>
												<Input
													type='textarea'
													className='inputTextbox'
													placeholder='Enter Policy Description'
													rows='4'
													id='comment'
													maxlength='256'
													onChange={e => this.handleInputChange('description', e.target.value)}
													value={this.state.description}
												/>
											</div>
										</div>
									</React.Fragment>
								) : null}
							</div>

							{this.props.editNewRuleDetails.governance_type !== 'opa' ? (
								<div className='rounded bg-dark3 p-3 border-info mt-3 dlpDescriptionForm dlpDescriptionFormPlain'>
									<div className='d-flex justify-content-between'>
										<h5
											className='mb-0 text-purple3 cursorPointer d-flex'
											onClick={() => this.setState({ showAppliedSection: !this.state.showAppliedSection })}
										>
											<span className='mr-1 align-self-center'>Step 1: </span>Apply Policies
										</h5>
									{this.props.editNewRuleDetails.governance_type !== 'opa_kubernetes' ?(
										<div className='d-flex'>
											{this.state.hasError &&
											this.state.appliesToArray.length === 1 &&
											(!this.state.appliesToArray[0].account_id ||
												!this.state.notificationArray[0].account_id.length === '' ||

												!this.state.notificationArray[0].region ||
												!this.state.notificationArray[0].region.length) ? (
												<span className='align-self-center ml-3 text-danger'>Please fill the apply section</span>
											) : null }
											<span
												className={`ml-4 fa fa-angle-${
													this.state.showAppliedSection ? 'up' : 'down'
												} f22 text-primary-color cursorPointer`}
												onClick={() => this.setState({ showAppliedSection: !this.state.showAppliedSection })}
											></span>
										</div> ) : (this.props.editNewRuleDetails.governance_type === 'opa_kubernetes' ? (
										<div className='d-flex'>
											{this.state.hasError &&
											this.state.appliesToArray.length === 1 &&
											(!this.state.appliesToArray[0].cluster_id ||
												!this.state.appliesToArray[0].cluster_name ) ? (
												<span className='align-self-center ml-3 text-danger'>Please fill apply the section</span>
											) : null }
											<span
												className={`ml-4 fa fa-angle-${
													this.state.showAppliedSection ? 'up' : 'down'
												} f22 text-primary-color cursorPointer`}
												onClick={() => this.setState({ showAppliedSection: !this.state.showAppliedSection })}
											></span>
										</div> ) : null ) }

									</div>
									{this.state.showAppliedSection &&
									this.props.editNewRuleDetails.governance_type != 'opa_kubernetes' ? (
										<React.Fragment>
											{this.state.appliesToArray.map(item => {
												return (
													<div className='d-flex flex-wrap mb-2'>
														<p className={`mb-0 mr-2 mt-2 mb-1 align-self-end text-white`}>Restricted to the account</p>
														<Select
															isSearchable={true}
															components={{
																IndicatorSeparator: () => null,
															}}
															className={`selectOption selectBoxBottomColor mr-2 mt-2`}
															value={{
																value: this.state['selected_account_' + item],
																label:
																	this.state['selected_account_' + item] &&
																	this.state['selected_account_' + item] !== '' ? (
																		this.props.accounts.filter(
																			e => e.account_id === this.state['selected_account_' + item]
																		).length ? (
																			this.props.accounts.filter(
																				e => e.account_id === this.state['selected_account_' + item]
																			)[0].account_name
																		) : (
																			this.state['selected_account_' + item]
																		)
																	) : (
																		<span className='placeholder'>Select</span>
																	),
															}}
															options={
																this.props.accounts &&
																this.props.accounts.map(row => ({
																	value: row.account_id,
																	label: row.account_name,
																}))
															}
															onChange={event =>
																this.setState(
																	{
																		['selected_account_' + item]: event.value,
																		['selected_users_' + item]: [],
																		['selected_roles_' + item]: [],
																	},
																	() => {
																		this.onChangeApliesTo()
																		this.listAssets('users', item)
																		this.listAssets('roles', item)
																	}
																)
															}
														/>
														<p className={`mb-0 ml-2 mr-2 mt-2 mb-1 align-self-end text-white`}>and region</p>
														<div className={`multiSelectOption selectBoxBottomColor align-self-end mt-2`}>
															<ReactMultiSelectCheckboxes
																placeholderButtonLabel='All'
																getDropdownButtonLabel={() =>
																	this.getMultiSelectedCount(
																		'selected_region_' + item,
																		this.state['selected_region_' + item] ? this.state['selected_region_' + item] : []
																	)
																}
																options={
																	this.state.regions &&
																	this.state.regions.map(row => ({
																		value: row.region,
																		label: row.name,
																	}))
																}
																onChange={arr => {
																	this.handleMultiSelectChange('selected_region', arr ? arr : [], item)
																}}
																value={
																	this.state['selected_region_' + item] &&
																	this.state['selected_region_' + item].map(acc => ({
																		value: acc,
																	}))
																}
															/>
														</div>
														{this.state['selected_region_' + item] && this.state['selected_region_' + item].length ? (
															this.state['selected_region_' + item].includes('All') ||
															(this.state['selected_region_' + item].length === this.state.regions &&
																this.state.regions.length) ? (
																<span className='badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2'>
																	All
																	<i
																		className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3'
																		onClick={() => this.setState({ ['selected_region_' + item]: [] })}
																	></i>
																</span>
															) : (
																this.state['selected_region_' + item].map((row, index) => {
																	return (
																		<span className='badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2'>
																			{this.state.regions && this.state.regions.filter(e => e.value === row).length
																				? this.state.regions.filter(e => e.value === row)[0].label
																				: row}
																			<i
																				className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3'
																				onClick={() => this.removeRegionSection(item, index, row)}
																			></i>
																		</span>
																	)
																})
															)
														) : null}

														{this.state.governance_type === 'infra' ? (
															<React.Fragment>
																<p className={`mb-0 ml-2 mr-2 mt-2 mb-1 align-self-end text-white`}>tags</p>
																<Select
																	isSearchable={true}
																	components={{
																		IndicatorSeparator: () => null,
																	}}
																	className={`selectOption selectBoxBottomColor mr-2 mt-2`}
																	value={{
																		value: this.state['tag_key_' + item],
																		label:
																			this.state['tag_key_' + item] && this.state['tag_key_' + item] !== '' ? (
																				this.state['tag_key_' + item]
																			) : (
																				<span className='placeholder'>Key</span>
																			),
																	}}
																	options={
																		this.props.tagKeys &&
																		this.props.tagKeys.map(item => ({
																			value: item,
																			label: item,
																		}))
																	}
																	onChange={event =>
																		this.setState(
																			{
																				['tag_key_' + item]: event.value,
																				['tagValues' + item]: [],
																			},
																			() => this.getAllTagsValues(item)
																		)
																	}
																/>
																<Select
																	isSearchable={true}
																	components={{
																		IndicatorSeparator: () => null,
																	}}
																	className={`selectOption selectBoxBottomColor mr-2 mt-2`}
																	value={{
																		value:
																			this.state['tag_value_' + item] && this.state['tag_value_' + item] !== ''
																				? this.state['tag_value_' + item]
																				: 'Select',
																		label:
																			this.state['tag_value_' + item] && this.state['tag_value_' + item] !== '' ? (
																				this.state['tag_value_' + item]
																			) : (
																				<span className='placeholder'>Value</span>
																			),
																	}}
																	options={
																		this.state['tagValues' + item] &&
																		this.state['tagValues' + item].map(item => ({
																			value: item,
																			label: item,
																		}))
																	}
																	onChange={event =>
																		this.setState({ ['tag_value_' + item]: event.value }, () => this.onChangeApliesTo())
																	}
																/>
																<span
																	className={`far fa-plus cursor-pointer f18 mt-2 mb-1 align-self-end mr-4`}
																	onClick={() => this.addNewTag(item)}
																></span>
																{this.state['selected_tags_' + item] && this.state['selected_tags_' + item].length
																	? this.state['selected_tags_' + item].map((tag, index) => {
																			return (
																				<span className='badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2'>
																					{tag.key + ' : ' + tag.value}
																					<i
																						className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3'
																						onClick={() => this.removeTagSection(item, tag)}
																					></i>
																				</span>
																			)
																	  })
																	: null}
															</React.Fragment>
														) : this.state.governance_type === 'access' ? (
															<React.Fragment>
																<p className={`mb-0 ml-2 mr-2 mt-2 mb-1 align-self-end text-white`}>user</p>
																<div className={`multiSelectOption selectBoxBottomColor align-self-end mt-2`}>
																	<ReactMultiSelectCheckboxes
																		placeholderButtonLabel='All'
																		getDropdownButtonLabel={() =>
																			this.getMultiSelectedCount(
																				'selected_users_' + item,
																				this.state['selected_users_' + item] ? this.state['selected_users_' + item] : []
																			)
																		}
																		options={
																			this.state['applies_to_users_' + item] &&
																			this.state['applies_to_users_' + item].map(row => ({
																				value: row.asset_arn,
																				label: row.asset_arn,
																			}))
																		}
																		onChange={arr => {
																			this.handleMultiSelectChange('selected_users_' + item, arr ? arr : [], item)
																		}}
																		value={
																			this.state['selected_users_' + item] &&
																			this.state['selected_users_' + item].map(acc => ({
																				value: acc,
																			}))
																		}
																	/>
																</div>
																{this.state['selected_users_' + item] && this.state['selected_users_' + item].length ? (
																	this.state['selected_users_' + item].includes('All') ||
																	(this.state['selected_users_' + item].length ===
																		this.state['applies_to_users_' + item] &&
																		this.state['applies_to_users_' + item].length) ? (
																		<span className='badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2'>
																			All
																			<i
																				className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3'
																				onClick={() => this.setState({ ['selected_users_' + item]: [] })}
																			></i>
																		</span>
																	) : (
																		this.state['selected_users_' + item].map((row, index) => {
																			return (
																				<span className='badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2'>
																					{row}
																					<i
																						className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3'
																						onClick={() =>
																							this.removeUserRolsSection('selected_users', item, index, row)
																						}
																					></i>
																				</span>
																			)
																		})
																	)
																) : null}
																<p className={`mb-0 ml-2 mr-2 mt-2 mb-1 align-self-end text-white`}>role</p>
																<div className={`multiSelectOption selectBoxBottomColor align-self-end mt-2`}>
																	<ReactMultiSelectCheckboxes
																		placeholderButtonLabel='All'
																		getDropdownButtonLabel={() =>
																			this.getMultiSelectedCount(
																				'selected_roles_' + item,
																				this.state['selected_roles_' + item] ? this.state['selected_roles_' + item] : []
																			)
																		}
																		options={
																			this.state['applies_to_roles_' + item] &&
																			this.state['applies_to_roles_' + item].map(row => ({
																				value: row.asset_arn,
																				label: row.asset_arn,
																			}))
																		}
																		onChange={arr => {
																			this.handleMultiSelectChange('selected_roles_' + item, arr ? arr : [], item)
																		}}
																		value={
																			this.state['selected_roles_' + item] &&
																			this.state['selected_roles_' + item].map(acc => ({
																				value: acc,
																			}))
																		}
																	/>
																</div>
																{this.state['selected_roles_' + item] && this.state['selected_roles_' + item].length ? (
																	this.state['selected_roles_' + item].includes('All') ||
																	(this.state['selected_roles_' + item].length ===
																		this.state['applies_to_roles_' + item] &&
																		this.state['applies_to_roles_' + item].length) ? (
																		<span className='badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2'>
																			All
																			<i
																				className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3'
																				onClick={() => this.setState({ ['selected_roles_' + item]: [] })}
																			></i>
																		</span>
																	) : (
																		this.state['selected_roles_' + item].map((row, index) => {
																			return (
																				<span className='badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2'>
																					{row}
																					<i
																						className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3'
																						onClick={() =>
																							this.removeUserRolsSection('selected_roles', item, index, row)
																						}
																					></i>
																				</span>
																			)
																		})
																	)
																) : null}
															</React.Fragment>
														) : null}
													</div>
												)
											})}
											<span
												className='text-info mt-2 mb-5 cursorPointer'
												onClick={() => this.addSection('appliesToArray')}
											>
												+ Add Applied Section
											</span>
										</React.Fragment>
									) : this.state.showAppliedSection &&
									  this.props.editNewRuleDetails.governance_type === 'opa_kubernetes' ? (
										<React.Fragment>
											{this.state.appliesToArray.map(item => {
												return (
													<div className='d-flex flex-wrap mb-2'>
														<p className={`mb-0 mr-2 mt-2 mb-1 align-self-end`}>Cluster Name</p>
														<div className='selectBoxBottomColor w-15 text-white'>
															<input
																type='textarea'
																className={`inputField w-100`}
																style={{ minHeight: '38px' }}
																placeholder={'Enter'}
																value={this.state['cluster_name_' + item]}
																onChange={e =>
																	this.setState({ ['cluster_name_' + item]: e.target.value }, () => {
																		this.onChangeApliesTo()
																	})
																}
															/>
														</div>
														<p className={`mb-0 mr-2 mt-2 mb-1 ml-3 align-self-end text-white`}>Cluster ID</p>
														<div className='selectBoxBottomColor w-15'>
															<input
																type='text'
																className={`inputField w-100`}
																style={{ minHeight: '38px' }}
																placeholder={'Enter'}
																value={this.state['cluster_id_' + item]}
																onChange={e =>
																	this.setState({ ['cluster_id_' + item]: e.target.value }, () => {
																		this.onChangeApliesTo()
																	})
																}
															/>
														</div>
														<p className={`mb-0 mr-2 mt-2 mb-1 ml-3 align-self-end text-white`}>Name Space</p>
														<div className='selectBoxBottomColor w-15'>
															<input
																type='text'
																className={`inputField w-100`}
																style={{ minHeight: '38px' }}
																placeholder={'Enter'}
																value={this.state['name_space_to_' + item]}
																onChange={e =>
																	this.setState({ ['name_space_to_' + item]: e.target.value }, () =>
																		this.onChangeApliesTo()
																	)
																}
															/>
														</div>
														<span
															className={`far fa-plus cursor-pointer f18 mt-2 mb-1 align-self-end mr-4`}
															onClick={() => this.addNewNameSpace(item)}
														></span>
														{this.state['name_space_' + item] && this.state['name_space_' + item].length
															? this.state['name_space_' + item].map((space, index) => {
																	return (
																		<span className='badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2'>
																			{space}
																			<i
																				className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3'
																				onClick={() => this.removeNameSpace(item, space)}
																			></i>
																		</span>
																	)
															  })
															: null}
													</div>
												)
											})}
											<span
												className='text-info mt-2 mb-5 cursorPointer'
												onClick={() => this.addSection('appliesToArray')}
											>
												+ Add Applied Section
											</span>
										</React.Fragment>
									) : null}
								</div>
							) : null}

							<div className='rounded bg-dark p-3 mt-3 dlpDescriptionForm dlpDescriptionFormPlain'>
								{this.state.listLoading ? (
									<div className='d-flex justify-content-center m-4'>
										<Spinner className='text-center' color='white' size='lg' />
									</div>
								) : (
									<React.Fragment>
										<div className={`d-flex pb-2 justify-content-between`}>
											<div className='d-flex mr-2 w-40'>
												<h5
													className='mb-0 text-purple3 cursorPointer d-flex'
													onClick={() => this.onClickConfigureSection()}
												>
													<span className='mr-1 align-self-center'>
														Step {this.props.editNewRuleDetails.governance_type === 'opa' ? 1 : 2}:{' '}
													</span>{' '}
													Configure Policy Rule
												</h5>
											</div>
											<span
												className={`ml-4 fa fa-angle-${
													this.state.showPolicySection ? 'up' : 'down'
												} f22 text-primary-color cursorPointer`}
												onClick={() => this.onClickConfigureSection()}
											></span>
										</div>

										{/* <div className="d-flex errorSectionScroll h-95" > */}
										{this.state.showPolicySection && this.state.governance_type ? (
											<div className='accordion mt-3' id='accordionExample'>
												<div className='px-3'>
													{this.state.serviceArray &&
														this.state.serviceArray.map(ser => {
															return (
																<div className={`rounded bg-dark3 p-3 mb-2`}>
																	{this.state.governance_type !== 'global' && this.state.governance_type !== 'opa' && this.state.governance_type !== 'opa_kubernetes' ? (
																		<div className='d-flex justify-content-between'>
																			<div className='d-flex w-50 mb-2'>
																				{this.state.governance_type !== 'global' ? (
																					<React.Fragment>
																						<label className='align-self-center m-0'>Select a service </label>
																						<Select
																							isSearchable={true}
																							className={`selectOption mr-1`}
																							components={{
																								IndicatorSeparator: () => null,
																							}}
																							value={{
																								value:
																									this.state['resource_type_' + ser] &&
																									this.state['resource_type_' + ser] !== ''
																										? this.state['resource_type_' + ser]
																										: '',
																								label:
																									this.state['resource_type_' + ser] &&
																									this.state['resource_type_' + ser].toLowerCase() !== '' ? (
																										this.state['resource_type_' + ser]
																									) : (
																										<span className='placeholder'>Select</span>
																									),
																							}}
																							options={
																								this.state.services &&
																								this.state.services.map(item => ({
																									value: item,
																									label: item,
																								}))
																							}
																							onChange={event =>
																								this.setState({ ['resource_type_' + ser]: event.value }, () => {
																									this.masterData(
																										'resource_type_' + ser,
																										this.state['resource_type_' + ser],
																										ser
																									)
																								})
																							}
																						/>
																					</React.Fragment>
																				) : this.state.governance_type === 'global' ? (
																					<p className='mb-0 f20 text-primary-color'>Global</p>
																				) : this.state.governance_type === 'opa' ? (
																					<p className='mb-0 f20 text-primary-color'>OPA</p>
																				) : this.state.governance_type === 'opa_kubernetes' ? (
																					<p className='mb-0 f20 text-primary-color'>OPA Kubernetes</p>
																				) : null}
																			</div>
																			<span
																				className={`ml-4 fa fa-angle-${
																					this.state['show_resource_type_' + this.state['resource_type_' + ser]]
																						? 'up'
																						: 'down'
																				} f22 text-primary-color cursorPointer`}
																				onClick={() =>
																					this.setState(
																						{
																							['show_resource_type_' + this.state['resource_type_' + ser]]: !this.state[
																								'show_resource_type_' + this.state['resource_type_' + ser]
																							],
																						},
																						() =>
																							this.closeOtherResource(
																								'resource_type_' + ser,
																								this.state['resource_type_' + ser],
																								ser
																							)
																					)
																				}
																			></span>
																		</div>
																	) : null}
																	{this.state['show_resource_type_' + this.state['resource_type_' + ser]] ||
																	this.state['show_resource_type_global'] ||
																	this.state['show_resource_type_opa_kubernetes'] ||
																	this.state['show_resource_type_opa']
																		? this.state['masterData_' + this.state['resource_type_' + ser]] &&
																		  this.state['masterData_' + this.state['resource_type_' + ser]].length
																			? this.state['masterData_' + this.state['resource_type_' + ser]].map(
																					(row, rowIndex) => {
																						return row.policies && row.policies.length ? (
																							<div className={`card bg-dark3 border-0 p-2 overflow-unset`}>
																								<div className='d-flex justify-content-between text-white border-bottom'>
																									<div className='d-flex'>
																										<p
																											className='mb-0 f20 text-warning cursorPointer'
																											onClick={() =>
																												this.setState({
																													['show_' + row.rule_category + '_policies']: !this.state[
																														'show_' + row.rule_category + '_policies'
																													],
																												})
																											}
																										>
																											{row.rule_category} Policy Rules
																										</p>
																										<span className='align-self-center ml-3 text-danger'>
																											{this.state[row.rule_category]
																												? 'Please fill all the required fields'
																												: ''}
																										</span>
																									</div>

																									<div className='d-flex'>
																										{/* {this.props.editNewRuleDetails && this.props.editNewRuleDetails.policies && this.props.editNewRuleDetails.policies.filter(e => e.rule_category === row.rule_category && e.resource_type === ["resource_type_"+ser]).length ?
																							<React.Fragment> */}
																										{/* {this.props.editNewRuleDetails.policies.filter(e => e.rule_category === row.rule_category).filter(e => e.enabled).length ?
																								<span className="text-white mr-3">{this.props.editNewRuleDetails.policies.filter(e => e.rule_category === row.rule_category).filter(e => e.enabled).length} enabled</span>
																							: null}
																							{this.props.editNewRuleDetails.policies.filter(e => e.rule_category === row.rule_category).filter(e => !e.enabled).length ?
																								<span className="text-gray3 mr-3">{this.props.editNewRuleDetails.policies.filter(e => e.rule_category === row.rule_category).filter(e => !e.enabled).length} disabled</span>
																							: null} */}
																										{/* {this.props.editNewRuleDetails.policies.filter(e => e.rule_category === row.rule_category).filter(e => e.disabled).length ?
																								<span className="text-issues mr-3">{this.props.editNewRuleDetails.policies.filter(e => e.rule_category === row.rule_category).filter(e => e.disabled).length} disabled</span>
																							: null} */}
																										{/* </React.Fragment>
																						: 
																							// <span className="text-white mr-3">{row.policies.length} policie(s)</span>
																						} */}
																										<span className='text-white mr-3'>
																											{row.policies.length} policy rule(s)
																										</span>
																										<span
																											className={`ml-4 fa fa-angle-${
																												this.state['show_' + row.rule_category + '_policies']
																													? 'up'
																													: 'down'
																											} f22 text-primary-color cursorPointer`}
																											onClick={() =>
																												this.setState({
																													['show_' + row.rule_category + '_policies']: !this.state[
																														'show_' + row.rule_category + '_policies'
																													],
																												})
																											}
																										></span>
																									</div>
																								</div>
																								{this.state['show_' + row.rule_category + '_policies'] &&
																									row.policies.map((pol, polIndex) => {
																										return !pol.template ? (
																											pol.rule_id === 'AWS_GOV_GLOBAL_0001' ? (
																												<GlobalServiceTemplate
																													masterData={pol}
																													resource_type={this.state['resource_type_' + ser]}
																													rule_category={row.rule_category}
																													onClickFinish={this.state.onClickFinish}
																													validateInput={boo =>
																														this.setState(
																															{
																																onClickFinish: false,
																																[row.rule_category +
																																'global_service_validation_' +
																																polIndex]: boo ? 'failed' : 'success',
																															},
																															() =>
																																this.validatePolicyInput(
																																	row.rule_category,
																																	boo,
																																	polIndex
																																)
																														)
																													}
																													actionMethods={this.state.actionMethods}
																													governance_type={this.state.governance_type}
																													// hasError={this.state.hasPolicyError}
																												/>
																											) : pol.rule_id === 'AWS_GOV_GLOBAL_0001' ? (
																												<OPAServiceTemplate
																													masterData={pol}
																													resource_type={this.state['resource_type_' + ser]}
																													rule_category={row.rule_category}
																													onClickFinish={this.state.onClickFinish}
																													validateInput={boo =>
																														this.setState(
																															{
																																onClickFinish: false,
																																[row.rule_category +
																																'global_service_validation_' +
																																polIndex]: boo ? 'failed' : 'success',
																															},
																															() =>
																																this.validatePolicyInput(
																																	row.rule_category,
																																	boo,
																																	polIndex
																																)
																														)
																													}
																													actionMethods={this.state.actionMethods}
																													governance_type={this.state.governance_type}
																													// hasError={this.state.hasPolicyError}
																												/>
																											) : (
																												<DynamicServices
																													masterData={pol}
																													resource_type={this.state['resource_type_' + ser]}
																													rule_category={row.rule_category}
																													onClickFinish={this.state.onClickFinish}
																													validateInput={(boo, noInputDetected) =>
																														this.setState(
																															{
																																onClickFinish: false,
																																[row.rule_category +
																																'_validation_' +
																																polIndex]: noInputDetected
																																	? boo
																																		? 'failed'
																																		: 'success'
																																	: 'failed',
																															},
																															() =>
																																this.validatePolicyInput(
																																	row.rule_category,
																																	boo,
																																	polIndex
																																)
																														)
																													}
																													actionMethods={this.state.actionMethods}
																													governance_type={this.state.governance_type}
																													// hasError={this.state.hasPolicyError}
																												/>
																											)
																										) : pol.rule_id === "K8sRequiredAnnotations" ? (
																											<ManagmentPolicy
																												masterData = {pol}
																												resource_type = {this.state["resource_type_"+ser]}
																												rule_category = {row.rule_category}
																												onClickFinish={this.state.onClickFinish}
																												validateInput={(boo, noInputDetected) => this.setState({ onClickFinish: false, [row.rule_category+"_validation_"+polIndex]: noInputDetected ? (boo ? "failed" : "success") : "failed" }, () => this.validatePolicyInput(row.rule_category, boo, polIndex))}
																												actionMethods={this.state.actionMethods}
																												governance_type={this.state.governance_type}
																												// hasError={this.state.hasPolicyError}
																											/>
																										) : row.rule_category === 'Engine' ? (
																											<RdsServiceEnginesTemplate
																												masterData={pol}
																												resource_type={this.state['resource_type_' + ser]}
																												rule_category={row.rule_category}
																												onClickFinish={this.state.onClickFinish}
																												validateInput={boo =>
																													this.setState(
																														{
																															onClickFinish: false,
																															[row.rule_category + 'engine_validation_' + polIndex]: boo
																																? 'failed'
																																: 'success',
																														},
																														() =>
																															this.validatePolicyInput(row.rule_category, boo, polIndex)
																													)
																												}
																												actionMethods={this.state.actionMethods}
																												governance_type={this.state.governance_type}
																												// hasError={this.state.hasPolicyError}
																											/>
																										) : row.rule_category === 'Log' ? (
																											<ServiceLogs
																												masterData={pol}
																												resource_type={this.state['resource_type_' + ser]}
																												rule_category={row.rule_category}
																												onClickFinish={this.state.onClickFinish}
																												validateInput={boo =>
																													this.setState(
																														{
																															onClickFinish: false,
																															[row.rule_category + '_validation']: boo
																																? 'failed'
																																: 'success',
																														},
																														() =>
																															this.validatePolicyInput(row.rule_category, boo, polIndex)
																													)
																												}
																												actionMethods={this.state.actionMethods}
																												governance_type={this.state.governance_type}
																											/>
																										) : row.rule_category === 'BlockStore' ? (
																											<ServiceBlockStore
																												masterData={pol}
																												resource_type={this.state['resource_type_' + ser]}
																												rule_category={row.rule_category}
																												onClickFinish={this.state.onClickFinish}
																												validateInput={boo =>
																													this.setState(
																														{
																															onClickFinish: false,
																															[row.rule_category + '_validation']: boo
																																? 'failed'
																																: 'success',
																														},
																														() =>
																															this.validatePolicyInput(row.rule_category, boo, polIndex)
																													)
																												}
																												actionMethods={this.state.actionMethods}
																												governance_type={this.state.governance_type}
																											/>
																										) : row.rule_category === 'Capacity' ? (
																											<ServiceCapacity
																												masterData={pol}
																												resource_type={this.state['resource_type_' + ser]}
																												rule_category={row.rule_category}
																												onClickFinish={this.state.onClickFinish}
																												validateInput={boo =>
																													this.setState(
																														{
																															onClickFinish: false,
																															[row.rule_category + '_validation']: boo
																																? 'failed'
																																: 'success',
																														},
																														() =>
																															this.validatePolicyInput(row.rule_category, boo, polIndex)
																													)
																												}
																												actionMethods={this.state.actionMethods}
																												governance_type={this.state.governance_type}
																											/>
																										) : null
																									})}
																							</div>
																						) : null
																					}
																			  )
																			: null
																		: null}
																</div>
															)
														})}
													{this.state.governance_type !== 'global' && this.state.governance_type !== 'opa' && this.state.governance_type !== 'opa_kubernetes'? (
														<span
															className='text-info mt-3 mb-5 cursorPointer'
															onClick={() => this.addSection('serviceArray')}
														>
															+ Add New Service
														</span>
													) : null}
												</div>
											</div>
										) : null}
									</React.Fragment>
								)}
							</div>

							<div className='rounded bg-dark3 p-3 border-info mt-3 dlpDescriptionForm dlpDescriptionFormPlain'>
								<div className='d-flex justify-content-between'>
									<h5
										className='mb-0 text-purple3 cursorPointer d-flex'
										onClick={() => this.setState({ showActionSection: !this.state.showActionSection })}
									>
										<span className='mr-1 align-self-center'>
											Step {this.props.editNewRuleDetails.governance_type === 'opa' ? 2 : 3}:{' '}
										</span>{' '}
										Actions
									</h5>
									<span
										className={`ml-4 fa fa-angle-${
											this.state.showActionSection ? 'up' : 'down'
										} f22 text-primary-color cursorPointer`}
										onClick={() => this.setState({ showActionSection: !this.state.showActionSection })}
									></span>
								</div>
								{this.state.showActionSection ? (
									<React.Fragment>
										{this.state.actionArray.map(item => {
											return (
												<div className='d-flex flex-wrap mb-2'>
													<p className={`mb-0 mr-1 mt-2 align-self-center text-white`}>By Action</p>
													<Select
														placeholder={'Select'}
														isSearchable={true}
														components={{
															IndicatorSeparator: () => null,
														}}
														className={`selectOption selectBoxBottomColor mr-1`}
														value={{
															value: this.state['selected_action_' + item] && this.state['selected_action_' + item],
															label: this.state['selected_action_' + item] ? (
																this.state['selected_action_' + item]
															) : (
																<span className='placeholder'>Select</span>
															),
														}}
														options={
															this.state.notificationType &&
															this.state.notificationType.map(row => ({
																value: row,
																label: row,
															}))
														}
														onChange={event => this.setState({ ['selected_action_' + item]: event.value })}
													/>
												</div>
											)
										})}
										<span className='text-info mt-2 mb-5 cursorPointer' onClick={() => this.addSection('actionArray')}>
											+ Add Actions
										</span>
									</React.Fragment>
								) : null}
							</div>

							<div className='rounded bg-dark3 p-3 border-info mt-3 dlpDescriptionForm dlpDescriptionFormPlain'>
								<div className='d-flex justify-content-between'>
									<h5
										className='mb-0 text-purple3 cursorPointer d-flex'
										onClick={() => this.setState({ showNotificationSection: !this.state.showNotificationSection })}
									>
										<span className='mr-1 align-self-center'>
											Step {this.props.editNewRuleDetails.governance_type === 'opa' ? 3 : 4}:{' '}
										</span>
										Notifications
									</h5>
									<div className='d-flex'>
										{this.state.notificationArray.length === 1 &&
										(!this.state.notifications ||
											(this.state.notifications[0] && !this.state.notifications[0].notfication_type) ||
											(this.state.notifications[0] && this.state.notifications[0].notfication_type === '') ||
											(this.state.notifications[0] && !this.state.notifications[0].notification_id) ||
											(this.state.notifications[0] && this.state.notifications[0].notification_id === '')) ? (
											<span className='align-self-center ml-3 text-danger'>Please fill the notification section</span>
										) : null}
										<span
											className={`ml-4 fa fa-angle-${
												this.state.showNotificationSection ? 'up' : 'down'
											} f22 text-primary-color cursorPointer`}
											onClick={() => this.setState({ showNotificationSection: !this.state.showNotificationSection })}
										></span>
									</div>
								</div>
								{this.state.showNotificationSection ? (
									<React.Fragment>
										{this.state.notificationArray.map(item => {
											return (
												<div className='d-flex flex-wrap mb-2'>
													<p className={`mb-0 mr-1 mt-2 align-self-center text-white`}>Notify to</p>
													<Select
														placeholder={'Select'}
														isSearchable={true}
														components={{
															IndicatorSeparator: () => null,
														}}
														className={`selectOption selectBoxBottomColor mr-1`}
														value={{
															value:
																this.state['selected_notfication_type_' + item] &&
																this.state['selected_notfication_type_' + item],
															label: this.state['selected_notfication_type_' + item] ? (
																this.state['selected_notfication_type_' + item]
															) : (
																<span className='placeholder'>Select</span>
															),
														}}
														options={
															this.state.notificationType &&
															this.state.notificationType.map(row => ({
																value: row,
																label: row,
															}))
														}
														onChange={event => this.setState({ ['selected_notfication_type_' + item]: event.value })}
													/>
													<p className={`mb-0 mr-1 ml-2 mt-2 align-self-center text-white`}>and details</p>
													<div className='selectBoxBottomColor w-20'>
														<input
															type='text'
															className={`inputField w-100`}
															style={{ minHeight: '38px' }}
															placeholder={'Enter details'}
															value={this.state['shared_to_' + item]}
															onChange={e => this.setState({ ['shared_to_' + item]: e.target.value })}
														/>
													</div>

													<span
														className={`far fa-plus cursor-pointer f18 mt-2 mb-1 align-self-end mr-4`}
														onClick={() => this.addNewSharedDetails(item)}
													></span>
													{this.state['shared_details_' + item] && this.state['shared_details_' + item].length
														? this.state['shared_details_' + item].map((share, index) => {
																return (
																	<span className='badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2'>
																		{share}
																		<i
																			className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3'
																			onClick={() => this.removeSharedDetails(item, share)}
																		></i>
																	</span>
																)
														  })
														: null}
												</div>
											)
										})}
										<span
											className='text-info mt-2 mb-5 cursorPointer'
											onClick={() => this.addSection('notificationArray')}
										>
											+ Add Notfications
										</span>
									</React.Fragment>
								) : null}
							</div>

							<div className='mt-4'>
								<button className='btn btn-primary' onClick={() => this.onFinish()}>
									{this.state.finishBtn ? this.state.finishBtn : 'Save'}{' '}
									{this.props.disableFinish ? <Spinner size='sm' color='light' className='ml-2' /> : null}
								</button>
								<Link
									className='float-right btn btn-light text-muted text-decoration-none ml-1'
									to={URL_PATH.GOVERNANCE_MANAGE_RULES}
								>
									Cancel
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
PolicyDetails.propTypes = {}

const mapStateToProps = state => {
	// console.log('PolicyDetails', state)
	return {
		providers: state.filters.providers,
		accounts: state.filters.accounts,
		regions: state.filters.regions,
		tagKeys: state.filters.tagKeys,
		editNewRuleDetails: state.governance.editNewRuleDetails,
		service_master_details:
			state.governance.governancePropsDetails && state.governance.governancePropsDetails.service_master_details
				? state.governance.governancePropsDetails.service_master_details
				: {},
	}
}

export default AppWrapper(PolicyDetails, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
	getAllTagsKeys,
	getAllTagsValues,
	listAssets,

	setGovernancePropsDetails,
	setNewInitialRuleDetails,
	setNewEditRuleDetails,
	getMasterGovernanceRules,
	createGovernancePolicy,
	editGovernancePolicy,
	getGovernanceServices,
})
