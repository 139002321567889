/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Policy.js
 * @author Prakash // on 16/09/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Spinner } from 'reactstrap'
import { 
	listSecurityPolicies,
	deleteSecurityPolicies
} from '../../../../actions/dlp/PoliciesAction'

import { momentConvertionUtcToLocalTime } from '../../../../utils/utility'
import Search from '../../../common/SearchComponent'
import ResizeableTable from '../../../designComponents/Table/ResizeableTable'
import { store as CommonNotification } from 'react-notifications-component';


class Policy extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: false,
			showPolicyLoading: true,
        }
    }
    
    componentDidMount = () => {	
        this.setState({ showLoading: true })
        if(!this.props.securityPoliciesList || !this.props.securityPoliciesList.length) {
            this.listSecurityPolicies()
        } else {
            this.setState({ showPolicyLoading: false })
        }
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.securityPoliciesList !== this.props.securityPoliciesList) {
            this.setState({ showPolicyLoading: false })
        }
    }

    listSecurityPolicies = () => {
        let params = {}
        this.props.listSecurityPolicies(params)
    }

    deleteConfirmation = (policy_id, name) => {
        if (window.confirm('Are you sure, you want to delete the policy '+name+'?')) {
            this.setState({ showDeleteLoading: true },
                () => this.deleteSecurityPolicies(policy_id)
            )
        }
    }

    deleteSecurityPolicies = (policy_id) => {
        let params = {}
        params.policy_id = [policy_id]

        this.props.deleteSecurityPolicies(params, (promise, response) => {
            if(promise) {
                this.setState({ showDeleteLoading: false },
                    () => this.listSecurityPolicies()
                )

                let messageType = 'danger'		
                let message = 'Policy deleted'
                CommonNotification.addNotification({
                    message: message,
                    type: messageType,
                    insert: "top",
                    container: "top-center",
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                })
            }
        })
    }

    render() {
        return(
            !this.state.showPolicyLoading ?
            <React.Fragment>
                <div className="row"> 
                    <div className="col-sm-12">
                        <h6 className="font-weight-normal">Security Policy</h6>
                        <p>Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim.</p>
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <div className='d-flex'>
                        <select className="custom-select w-auto mr-3">
                            <option selected>Filter</option>
                            {/* <option value="1">InActive</option>
                            <option value="2">Active</option> */}
                        </select>
                        <small className="ml-2 align-self-center">Showing {this.state.filteredList ? this.state.filteredList.length : (this.props.securityPoliciesList && this.props.securityPoliciesList.length)} security {(this.state.filteredList ? this.state.filteredList.length : (this.props.securityPoliciesList && this.props.securityPoliciesList.length)) > 1 ? 'policies' : 'policy'} </small>
                    </div>
                    <div className="d-flex">
                        {this.props.securityPoliciesList && this.props.securityPoliciesList.length ?
                            <div className="form-group mb-0">
                                <Search
                                    data={this.props.securityPoliciesList}
                                    applyTags={false}
                                    applyLiteDarkTags={true}
                                    topClassName={'bg-white'}
                                    searchClassName={'textboxBorder'}
                                    searchIconColor={'text-dark'}
                                    className={"bg-transparent textboxBorder"}
                                    filteredData={(searchArray, searchText) => {
                                        this.setState({ filteredList: _.orderBy(searchArray, ['date'], ['desc']), emptySearchText: false })
                                    }}
                                    resetSearchBox={this.state.emptySearchText}
                                />
                            </div>
                        : null}
                        <span className='btn btn-primary cursorPointer text-center ml-2 mb-2' onClick={() => this.props.onClickAction({}, 'create')}>Add Policy</span>
                    </div>
                </div>
                
                {this.props.securityPoliciesList ?
                    <ResizeableTable
                        columns={[
                            {
                                Header: 'Date',
                                accessor: d => d.modified_at ? d.modified_at : d.created_at,
                                Cell: cellInfo => (<span>{cellInfo.row.original.modified_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.modified_at, 'DD MMM YYYY HH:mm') : (cellInfo.row.original.created_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, 'DD MMM YYYY HH:mm')  : '')}</span>)
                            },
                            {
                                Header: 'Title',
                                accessor: 'name',
                                width: 250	
                            },
                            {
                                Header: 'Source',
                                // accessor: 'data_association',
                                Cell: cellInfo => (<span>{cellInfo.row.original.data_association ? cellInfo.row.original.data_association.join(', ') : ''}</span>),
                                width: 250	
                            },
                            {
                                Header: 'Description',
                                accessor: 'policy_text',
                                width: 550
                            },
                            {
                                Header: 'Action',
                                Cell: cellInfo => (
                                    <div className="d-flex justify-content-between align-self-center">
                                        <p className="text-dark m-0 p-0 cursorPointer">
                                            <i className="fal fa-book-open" onClick={() => this.props.onClickAction(cellInfo.row.original, 'view')}></i>
                                        </p>
                                        <p className="text-dark m-0 p-0 cursorPointer">
                                            <i className="fal fa-user-edit" onClick={() => this.props.onClickAction(cellInfo.row.original, 'edit')}></i>
                                        </p>
                                        <p className="text-dark m-0 p-0 cursorPointer">
                                            {this.state.showDeleteLoading && cellInfo.row.original.policy_id === this.state.selectedPolicyId ? 
                                                <Spinner color='dark' size='sm' />
                                            :
                                                <i className="fal fa-trash" onClick={() => this.setState({ selectedPolicyId: cellInfo.row.original.policy_id }, () => this.deleteConfirmation(cellInfo.row.original.policy_id, cellInfo.row.original.name))}></i>
                                            }
                                        </p>
                                    </div>
                                ),
                                width:120
                            }
                        ]}
                        data={this.state.filteredList ? this.state.filteredList : this.props.securityPoliciesList}
                        perPage={10}
                        dashboard={this.state.filteredList ? this.state.filteredList.length : this.props.securityPoliciesList.length}
                        sortByColumn={'date'}
                        tooltipFor={[3]}
                        onClickRow={tableRow => {}}
                        tableSize={'table-striped'}
                    />
                :
                    <div className='d-flex justify-content-center m-4'>
                        <p>There are no data on this criteria. Please try adjusting your filter.</p>
                    </div>
                }
                </React.Fragment>
            :
                <div className='d-flex justify-content-center m-4'>
                    <Spinner color='dark' size='lg' />
                </div>
            
        )
    }
}


    

/**
 * Type of the props used in the component
 */
 Policy.propTypes = {
	listSecurityPolicies: PropTypes.func,
	deleteSecurityPolicies: PropTypes.func
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
 const mapStateToProps = state => {
    //  console.log('state', state)
    return {
		securityPoliciesList: state.dlp.dlpSecurityPoliciesList,
	}
}
export default connect(mapStateToProps, {
	listSecurityPolicies,
	deleteSecurityPolicies
})(withRouter(Policy))