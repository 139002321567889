/*************************************************
 * Tvastar
 * @exports
 * @file AddEdit.js
 * @author Prakash // on 08/10/2021
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { capitalizeFirstLetter, getAccountNameFromId,restrictCharacter } from '../../../../utils/utility'
import { 
    getDlpEngineEventsBaseParams,
    listMsc,
    insertMsc,
    updateMsc,
} from '../../../../actions/dlp/DlpJobsAction'
import Select from 'react-select'
import { listAllProviders, listAllAccounts } from '../../../../actions/commonAction'
import { Spinner } from 'reactstrap'
import { store as CommonNotification } from 'react-notifications-component';

class AddEdit extends Component {
    hierarichalBucketBarChartRef = React.createRef()
    constructor(props) {
        super(props)
        this.props = props;
        this.state = {
            showLoading: true,
			yesNoOptions: ['Yes', 'No'],
            bucketArray: [0],
            identifyArray: [0],
            deIdentifyArray: [0]
        }
    }

    componentDidMount = () => {
        this.getDlpEngineEventsBaseParams()
        this.getProviders()
        if(this.props.pageType === 'edit' || this.props.pageType === 'view') {
            let selectedRecord = this.props.selectedRecord
            let name = selectedRecord.name
            let description = selectedRecord.description ? selectedRecord.description : ''            
			let selectedProvider = selectedRecord.provider
			let selectedAccount = selectedRecord.account_id
            let selectedServiceName = selectedRecord.service_name

            let mandatory = selectedRecord.mandatory

            let bucketArray = []
            if(selectedRecord.buckets.length) {
                selectedRecord.buckets.forEach((item, index) => {
                    bucketArray.push(index)
                    this.setState({ ['bucket_name_'+index]: item.bucket_name, ['prefix_name_'+index]: item.prefix })
                })
            } else {
                bucketArray.push(0)
            }

            let dlp_engine_events_config = selectedRecord.dlp_engine_events_config

            let analyse_files = dlp_engine_events_config.analyse_files ? true : false
            let malware_check = dlp_engine_events_config.malware_check ? true : false
            let identify = false
            let identifyArray = []
            if(dlp_engine_events_config.identify && dlp_engine_events_config.identify.length) {
                dlp_engine_events_config.identify.forEach((item, index) => {
                    identifyArray.push(index)
                    this.setState({ ['identify_service_'+index]: item.standard, ['identify_rule_'+index]: item.rule_type })
                })
                identify = true
            } else {
                identifyArray.push(0)
            }
            let deidentify = dlp_engine_events_config.deidentify ? true : false

            let deIdentifyArray = []
            if(dlp_engine_events_config.output && dlp_engine_events_config.output.length) {
                dlp_engine_events_config.output.forEach((item, index) => {
                    deIdentifyArray.push(index)
                    let folderPrefix = item.config && item.config.folder && item.config.folder.folder_prefix ? item.config.folder.folder_prefix : ''
                    let bucketName = item.destinations && item.destinations[0] && item.destinations[0].bucket_name ? item.destinations[0].bucket_name : ''
                    this.setState({ ['bucket_folder_name_'+index]: bucketName, ['prefix_folder_name_'+index]: folderPrefix })
                })
            } else {
                deIdentifyArray.push(0)
            }

            this.setState({ name, description, selectedProvider, selectedAccount, selectedServiceName, bucketArray, analyse_files, malware_check, identify, deidentify, identifyArray, deIdentifyArray, mandatory })
        }
    }

    listMsc = () => {
        let params = {}
        params.job_type = 'dlp_engine_event'
        this.props.listMsc(params, (promise, response) => {
            this.setState({ showLoading: false })
        })
    }

    getProviders = () => {
        if(!this.props.providers || !this.props.providers.length) {
            this.props.listAllProviders((promise, response) => {
                if (promise) {
                    let selectedProvider = response.length ? response[0].provider_name : ''
                    this.setState({ selectedProvider },
                        () => {
                            this.getAccounts()
                        }
                    )
                }
            })
        } else {
            let selectedProvider = this.props.providers.length ? this.props.providers[0].provider_name : ''
            this.setState({ selectedProvider },
                () => {
                    this.getAccounts()
                }
            )
        }

    }

    getAccounts = () => {
        let accounts = []
        if(!this.props.accounts || !this.props.accounts.length) {
            let params = {}
            if(this.state.selectedProvider && this.state.selectedProvider !== '') {
                params.provider = this.state.selectedProvider.toLowerCase()
            }
            this.props.listAllAccounts(params, (promise, response) => {                
                if (promise) {
                    if(this.state.selectedProvider && this.state.selectedProvider !== '') {
                        accounts = response.filter(acc => acc.provider === this.state.selectedProvider.toLowerCase())
                    } else {
                        accounts = response
                    }   
                    this.setState({ accounts })
                }
            })
        } else {
            if(this.state.selectedProvider && this.state.selectedProvider !== '') {
                accounts = this.props.accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
            } else {
                accounts = this.props.accounts
            }
            this.setState({ accounts })
        }
        
    }

    getDlpEngineEventsBaseParams = () => {
        let params = {}
        this.props.getDlpEngineEventsBaseParams(params, (promise, response) => {
            this.setState({ masterData: response, showLoading: false },
                () => this.formMasterData()
            )
        })
    }

    formMasterData = () => {
		let data = this.state.masterData
		if(data && Object.entries(data).length) {
            if(data.service_rule_type_map && Object.entries(data.service_rule_type_map).length) {
                Object.entries(data.service_rule_type_map).forEach(([key,value]) => {
                    this.setState({ identityService: Object.keys(data.service_rule_type_map), ['identityRule_'+key]: value })
                })
            }
		}
	}

    actionPorcess = () => {
        let hasError = false

        let nameErrorMessage = ''
        if(!this.state.name || this.state.name === '') {
            hasError = true
            nameErrorMessage = 'Please enter configuration name'
        } else {
            //check watemark name already exist
            if(this.props.pageType === 'create') {
                let checkNameExist = this.props.dlpConfigEngineEventsList.filter(arr => arr.name === this.state.name)
                if(checkNameExist && checkNameExist.length) {
                    hasError = true
                    nameErrorMessage = 'Configuration name already exist'
                }
            } else if(this.props.pageType === 'edit') {
                let checkNameExist = this.props.dlpConfigEngineEventsList.filter(arr => arr.name === this.state.name && arr.msc_id !== this.props.selectedRecord.msc_id)
                console.log(checkNameExist)
                if(checkNameExist && checkNameExist.length) {
                    hasError = true
                    nameErrorMessage = 'Configuration name already exist'
                }
            }
        }

        let erroMessage = ''
        if(!this.state.selectedProvider || this.state.selectedProvider === '') {
            hasError = true
            erroMessage = 'Please select provider'
        }

        if(!this.state.selectedAccount || this.state.selectedAccount === '') {
            hasError = true
            erroMessage = 'Please select account'
        }

        if(!this.state.selectedServiceName || this.state.selectedServiceName === '') {
            hasError = true
            erroMessage = 'Please select service name'
        }        


        let checkBucketDataExist = 0
        this.state.bucketArray.forEach(item => {
            // if(this.state['bucket_name_'+item] !== '' && this.state['prefix_name_'+item] === '') {
            //     erroMessage = 'Please enter the bucket name'
            // } else 
            if(this.state['bucket_name_'+item] === '' && this.state['prefix_name_'+item] !== '') {
                hasError = true
                erroMessage = 'Please select prefix name'
            } else if(this.state['bucket_name_'+item] !== '') {
                checkBucketDataExist++
            }
        })

        if(!checkBucketDataExist) {
            hasError = true
            erroMessage = 'Please enter buket'
        }

        if(!this.state.analyse_files && !this.state.malware_check && !this.state.identify && !this.state.deidentify){
            hasError = true
            erroMessage = 'Please select any of the action to perform'
        }

        if(this.state.identify) {
            let checkIdentifyDataExist = 0
            this.state.identifyArray.forEach(item => {
                if(this.state['identify_service_'+item] !== '' && this.state['identify_rule_'+item] === '') {
                    erroMessage = 'Please select the standart'
                } else if(this.state['identify_service_'+item] === '' && this.state['identify_rule_'+item] !== '') {
                    erroMessage = 'Please select rule'
                } else {
                    checkIdentifyDataExist++
                }
            })
            if(!checkIdentifyDataExist) {
                hasError = true
                erroMessage = 'Please select identify entities'
            }
        }

        if(this.state.deidentify) {
            let checkDeidentifyDataExist = 0
            this.state.deIdentifyArray.forEach(item => {
                if(this.state['bucket_folder_name_'+item] !== '' && this.state['prefix_folder_name_'+item] === '') {
                    erroMessage = 'Please select the folder name'
                } else if(this.state['bucket_folder_name_'+item] === '' && this.state['prefix_folder_name_'+item] !== '') {
                    erroMessage = 'Please select bucket name'
                } else {
                    checkDeidentifyDataExist++
                }
            })
            if(!checkDeidentifyDataExist) {
                hasError = true
                erroMessage = 'Please enter deidentify'
            }
        }

        this.setState({ hasError, nameErrorMessage, erroMessage, showActionLoading: !hasError })

        if(!hasError) {
            let params = {}
            params.name = this.state.name
            params.description = this.state.description
            params.provider = this.state.selectedProvider.toLowerCase()
            params.account_id = this.state.selectedAccount
            params.service_name = this.state.selectedServiceName
            
            let configuration_text = 'Configure engine events for provider '+this.state.selectedProvider+' account ID '+this.state.selectedAccount+' service '+this.state.selectedServiceName
            

            configuration_text += ' for buckets'
            let buckets = []
            this.state.bucketArray.forEach(item => {
                if(this.state['bucket_name_'+item] !== '') {
                    let dataRow = {}
                    dataRow.bucket_name = this.state['bucket_name_'+item]
                    dataRow.prefix = this.state['prefix_name_'+item] ? this.state['prefix_name_'+item] : ''
                    buckets.push(dataRow)
                    configuration_text += ' bucket name '+this.state['bucket_name_'+item]+' prefix '+this.state['prefix_name_'+item]
                }
            })
            params.buckets = buckets

            let dlp_engine_events_config = {}
            if(this.state.masterData && this.state.masterData.static_fields) {
                Object.entries(this.state.masterData.static_fields).forEach(([key, value]) => {
                    if(key !== 'dlp_engine_events_config') {
                        params[key] = value
                    } else {
                        Object.entries(this.state.masterData.static_fields[key]).forEach(([key, value]) => {
                            dlp_engine_events_config[key] = value
                        })
                    }
                })
            }
            dlp_engine_events_config.analyse_files = this.state.analyse_files ? true : false
            dlp_engine_events_config.malware_check = this.state.malware_check ? true : false
            dlp_engine_events_config.deidentify = this.state.deidentify ? true : false            

            configuration_text += ' perform'
            if(this.state.analyse_files) {
                configuration_text += ' file analysis'
            }
            if(this.state.malware_check) {
                configuration_text += ' malware analysis'
            }            
            if(this.state.identify) {
                configuration_text += ' identify entities for'
            }

            let identify = []
            if(this.state.identify) {
                this.state.identifyArray.forEach(item => {
                    if(this.state['identify_service_'+item] !== '' && this.state['identify_rule_'+item] !== '') {
                        let dataRow = {}
                        dataRow.standard = this.state['identify_service_'+item]
                        dataRow.rule_type = this.state['identify_rule_'+item]
                        identify.push(dataRow)
                        configuration_text += ' standard '+this.state['identify_service_'+item]+' rule '+this.state['identify_rule_'+item]
                    }
                })
            }
            dlp_engine_events_config.identify = identify
            
                   
            if(this.state.deidentify) {
                configuration_text += ' store deidentify entities in'
            }
            
            if(this.state.deidentify) {                
                this.state.deIdentifyArray.forEach(item => {
                    if(this.state['bucket_folder_name_'+item] !== '' && this.state['prefix_folder_name_'+item] !== '') {
                        configuration_text += ' bucket '+this.state['bucket_folder_name_'+item]+' prefix '+this.state['prefix_folder_name_'+item]
                    }
                })
            }

            params.configuration_text = configuration_text

            let output = []

            let object = this.state.masterData.output_structure
            
            if(this.state.deIdentifyArray) {
                this.state.deIdentifyArray.forEach(bfRow => {
                    if(this.state['bucket_folder_name_'+bfRow] !== '' && this.state['prefix_folder_name_'+bfRow]) {
                        let newObject = {}
                        let config = Object.assign({}, object.config, { 
                            folder: Object.assign({}, object.config.folder, {
                                folder_prefix: this.state['prefix_folder_name_'+bfRow]
                            })
                        })
                        let destinations = []
                        let destinationsObject = {...object.destinations[0], ...{ bucket_name: this.state['bucket_folder_name_'+bfRow] }};
                        destinations.push(destinationsObject)
            
                        newObject.config = config
                        newObject.destinations = destinations
                        newObject.type = object.type
            
                        output.push(newObject)
                    }
                })
            }

            dlp_engine_events_config.output = output

            params.dlp_engine_events_config = dlp_engine_events_config
            
            

            console.log('params', params)

            if(this.props.pageType === 'create') {
                params.mandatory = true
                this.props.insertMsc(params, (promise, response) => {
                    if(promise) {                        
                        let messageType = 'danger'		
                        let message = 'Check the data'
                        if(response.status && response.status.message && response.status.message === 'inserted') {
                            messageType = 'success'
                            message = 'Saved Successfully'
                        }
                        CommonNotification.addNotification({
                            message: message,
                            type: messageType,
                            insert: "top",
                            container: "top-center",
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
                        
                        if(response.status && response.status.message && response.status.message === 'inserted') {
                            this.setState({ showActionCompleted: true },
                                () => {
                                    setTimeout(() => { this.listMsc() }, 3000)
                                    setTimeout(() => { this.props.showListPage() }, 4000)
                                }
                            )
                        } else {
                            this.setState({ showActionLoading: false })
                        }
                    } else {
                        this.setState({ showActionLoading: false })
                    }
                })
            } else if(this.props.pageType === 'edit') {
                params.mandatory = this.state.mandatory ? true : false
                params.msc_id = this.props.selectedRecord.msc_id
                this.props.updateMsc(params, (promise, response) => {
                    if(promise) {						
                        let messageType = 'danger'		
                        let message = 'Check the data'
                        if(response.status && response.status.message && response.status.message === 'updated') {
                            messageType = 'success'
                            message = 'Updated Successfully'
                        }
                        CommonNotification.addNotification({
                            message: message,
                            type: messageType,
                            insert: "top",
                            container: "top-center",
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
                        
                        if(response.status && response.status.message && response.status.message === 'updated') {
                            this.setState({ showActionCompleted: true },
                                () => {
                                    setTimeout(() => { this.listMsc() }, 3000)
                                    setTimeout(() => { this.props.showListPage() }, 4000)
                                }
                            )
                        } else {
                            this.setState({ showActionLoading: false })
                        }
                    } else {
                        this.setState({ showActionLoading: false })
                    }
                })
            }
        }
    }    

    onKeyDown = (e) => {
        let selectedTags = this.state.selectedTags ? this.state.selectedTags : []
		if (e.keyCode === 13 || e.keyCode === 9) {
            if(this.state.selected_tag !== '') {
                selectedTags.push(this.state.selected_tag.trim())
                if(e.keyCode === 9) {
                    e.preventDefault();
                }
                this.setState({ selectedTags, selected_tag: '' })
            }
        }
    }

    addSection = (array) => {
        let rowList = this.state[array];
        if(this.state[array]) {
            let value = this.state[array][this.state[array].length - 1]
            value = value+1
            rowList.push(value);
        }
        
        this.setState({[array]: rowList })
    }

    removeBucketSection = (item, i) => {
        this.setState({ ['bucket_name_'+item]: '', ['prefix_name_'+item]: '' },
            () => {
                let rowList = this.state.bucketArray;
                rowList.splice(i, 1);
                this.setState({ bucketArray: rowList })
            }
        );
    }

    removeIdentifySection = (item, i) => {
        this.setState({ ['identify_service_'+item]: '', ['identify_rule_'+item]: '' },
            () => {
                let rowList = this.state.identifyArray;
                rowList.splice(i, 1);
                this.setState({ identifyArray: rowList })
            }
        );
    }

    removeBucketFolderSection = (item, i) => {
        this.setState({ ['bucket_folder_name_'+item]: '', ['prefix_folder_name_'+item]: '' },
            () => {
                let rowList = this.state.deIdentifyArray;
                rowList.splice(i, 1);
                this.setState({ deIdentifyArray: rowList })
            }
        );
    }

    render() {		
        return (
            !this.state.showLoading ?
                <div className={`mt-3 rounded ${(this.state.showActionLoading) ? 'disabled' : ''}`}>
                    <div className="row"> 
                        <div className="col-sm-12">
                            <div className="d-flex justify-content-between">
                                <h6 className="font-weight-normal">{capitalizeFirstLetter(this.props.pageType)+' Engine events'}</h6>
                                <i className="fas fa-arrow-left cursorPointer" onClick={() => this.props.showListPage()}> back</i>
                                {/* <button className='btn btn-sm btn-light mr-1' onClick={() => this.props.showListPage()}>Cancel</button> */}
                            </div>
                            <p>Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim.</p>
                        </div>
                    </div>
                    <div className={`${this.props.pageType === 'view' ? 'disabled disableInputColor' : ''}`}>
                        <div className={`form-group`}>
                            <p className="m-0">Configuration Name</p>
                            <input 
                                type="text" 
                                className="form-control col-sm-6"
                                value={this.state.name ? this.state.name : ''}
                                onChange={e => this.setState({ name: e.target.value })}
                            />
                            {this.state.hasError && this.state.nameErrorMessage !== '' ?
                                <p className='m-0 text-danger align-self-end mb-1'>{this.state.nameErrorMessage}</p>
                            : null}
                        </div>
                        
                        <div className={`form-group`}>
                            <p className="m-0">Description</p>
                            <textarea
                                className="form-control border-black6"
                                onChange={(event) => this.setState({ description: event.target.value })} 
                                value={this.state.description}
                                maxLength={200}
                            />
                        </div>
                        <div className={`form-group dlpDescriptionFormWhite`}>
                            <p className="mb-0 mr-2 align-self-end text-purple mt-3">Configure engine events for</p>
                            <div className="d-flex flex-wrap">
                                <p className="mb-0 mr-2 align-self-end text-purple mt-3">Provider</p>
                                <Select
                                    placeholder={'Select'}
                                    isSearchable={false}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    className={`selectOption mr-2 mt-3 ${this.state.hasError && !this.state.selectedProvider ? 'dottedDangerSelectLine' : ''}`}
                                    value={({
                                        value: this.state.selectedProvider,
                                        label: this.state.selectedProvider
                                    })}
                                    options={this.props.providers.length && this.props.providers.map(item => ({
                                        value: item.provider_name,
                                        label: item.provider_name,	
                                    }))}
                                    onChange={event =>  this.setState({ selectedProvider: event.value }, () => this.getAccounts())}
                                />
                                <p className="mb-0 mr-2 align-self-end text-purple mt-3">Account</p>
                                <Select
                                    placeholder={'Select'}
                                    isSearchable={false}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    className={`selectOption mr-2 mt-3 ${this.state.hasError && !this.state.selectedAccount ? 'dottedDangerSelectLine' : ''}`}
                                    value={({
                                        value: this.state.selectedAccount,
                                        label: this.state.selectedAccount ? getAccountNameFromId(this.state.selectedAccount, this.state.accounts): 'Select'
                                    })}
                                    options={this.state.accounts && this.state.accounts.length && this.state.accounts.map(item => ({
                                        value: item.account_id,
                                        label: item.account_name,	
                                    }))}
                                    onChange={event =>  this.setState({ selectedAccount: event.value })}
                                />
                                <p className="mb-0 mr-2 align-self-end text-purple mt-3">Service name</p>
                                <Select
                                    placeholder={'Select'}
                                    isSearchable={false}
                                    // menuIsOpen ={true}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    className={`selectOption mr-2 mt-3 ${this.state.hasError && !this.state.selectedServiceName ? 'dottedDangerSelectLine' : ''}`}
                                    value={({
                                        value: this.state.selectedServiceName ? this.state.selectedServiceName : 'Select',
                                        label: this.state.selectedServiceName ? this.state.selectedServiceName : 'Select'
                                    })}
                                    options={this.state.masterData && this.state.masterData.service_name && this.state.masterData.service_name.map(item => ({
                                        value: item,
                                        label: item,	
                                    }))}
                                    onChange={event =>  this.setState({ selectedServiceName: event.value })}
                                />
                            </div>
                            <React.Fragment>
                                <p className="mb-0 mr-2 align-self-end text-purple mt-4">for the buckets</p>
                                {this.state.bucketArray.map((item, i) => {
                                    return(
                                        <div className="w-100">
                                            <div className="d-flex flex-wrap">
                                                <p className="mb-0 mr-2 align-self-end text-purple mt-3">Bucket</p>
                                                <input 
                                                    type="text" 
                                                    className={`inputField mt-3 w-30 ${this.state.hasError && (!this.state['bucket_name_'+item] || this.state['bucket_name_'+item] === '') ? 'dottedDangerTextboxLine' : ''}`}
                                                    value={this.state['bucket_name_'+item] ? this.state['bucket_name_'+item] : ''}
                                                    onChange={e => this.setState({ ['bucket_name_'+item]: restrictCharacter(e.target.value, '/') })}
                                                />
                                                <p className="mb-0 mx-2 align-self-end text-purple mt-3">Prefix</p>
                                                <input 
                                                    type="text" 
                                                    className={`inputField mt-3 w-30`}
                                                    value={this.state['prefix_name_'+item] ? this.state['prefix_name_'+item] : ''}
                                                    onChange={e => this.setState({ ['prefix_name_'+item]: e.target.value })}
                                                />
                                                {this.props.pageType.toLowerCase() !== 'view' && this.state.bucketArray.length > 1 ?
                                                    <span className={`far fa-trash cursor-pointer f18 align-self-end ml-4`} onClick={() => this.removeBucketSection(item, i)}></span>
                                                : null}
                                                {this.props.pageType.toLowerCase() !== 'view' && (this.state.bucketArray.length - 1) ===  i ?
                                                    <span className={`far fa-plus cursor-pointer f18 align-self-end ml-4`} onClick={() => this.addSection('bucketArray')}></span>
                                                : null}
                                            </div>
                                        </div>
                                    )
                                })}
                            </React.Fragment>
                            <div className={`d-flex mt-4`}>
                                <p className="mb-0 mr-2 align-self-end text-purple mt-n2">Perform</p>
                                {this.state.hasError && !this.state.analyse_files && !this.state.malware_check && !this.state.deidentify && !this.state.deidentify ?
                                    <p className="mb-0 mr-2 align-self-end text-danger mt-n2">Please select any of the action to perform</p>
                                : null}
                            </div>
                            <div className="d-flex flex-wrap mt-3">
                                <div style={{ display: "inline" }} className="mr-2 align-self-end">
                                    <div className="form-check">
                                        <input type="checkbox"
                                            onChange={() => this.setState({ analyse_files: !this.state.analyse_files },)}
                                            className="form-check-input"
                                            checked={this.state.analyse_files}
                                        />
                                        <p className="form-check-label mb-0 mr-2 text-purple">File Analysis</p>
                                    </div>
                                </div>

                                <div style={{ display: "inline" }} className="mx-2 align-self-end">
                                    <div className="form-check">
                                        <input type="checkbox"
                                            onChange={() => this.setState({ malware_check: !this.state.malware_check },)}
                                            className="form-check-input"
                                            checked={this.state.malware_check}
                                        />
                                        <p className="form-check-label mb-0 mr-2 text-purple">Malware Analysis</p>
                                    </div>
                                </div>

                                <div style={{ display: "inline" }} className="mx-2 align-self-end">
                                    <div className="form-check">
                                        <input type="checkbox"
                                            onChange={() => this.setState({ identify: !this.state.identify },)}
                                            className="form-check-input"
                                            checked={this.state.identify}
                                        />
                                        <p className="form-check-label mb-0 mr-2 text-purple">Identify Entities</p>
                                    </div>
                                </div>

                                <div style={{ display: "inline" }} className="mx-2 align-self-end">
                                    <div className="form-check">
                                        <input type="checkbox"
                                            onChange={() => this.setState({ deidentify: !this.state.deidentify },)}
                                            className="form-check-input"
                                            checked={this.state.deidentify}
                                        />
                                        <p className="form-check-label mb-0 mr-2 text-purple">Deidentify Entities</p>
                                    </div>
                                </div>

                            </div>

                            {this.state.identify ? 
                                <React.Fragment>
                                    <p className="mb-0 mr-2 text-purple mt-4">Identify entities for</p>
                                    {this.state.identifyArray.map((item, i) => {
                                        return(
                                            <div className="w-100">
                                                <div className="d-flex flex-wrap">
                                                    <p className="mb-0 mr-2 align-self-end text-purple mt-3">Standard</p>
                                                    <Select
                                                        // menuIsOpen={true}
                                                        placeholder={'Select'}
                                                        components={{
                                                            IndicatorSeparator: () => null
                                                        }}
                                                        isSearchable={false}
                                                        className={`selectOption mr-2 mt-3 w-30 ${this.state.hasError && !this.state['identify_service_'+i] ? 'dottedDangerSelectLine' : ''}`}
                                                        value={({
                                                            value: this.state['identify_service_'+i] ? this.state['identify_service_'+i] : 'Select',
                                                            label: this.state['identify_service_'+i] ? this.state['identify_service_'+i] : 'Select',
                                                        })}
                                                        options={this.state.identityService && this.state.identityService.map(item => ({
                                                            value: item,
                                                            label: item,
                                                        }))}
                                                        onChange={event =>  
                                                            this.setState({ ['identify_service_'+i]: event.value, ['identify_rule_'+i]: '' })
                                                        }
                                                    />
                                                    <p className="mb-0 mx-2 align-self-end text-purple mt-3">Rule</p>
                                                    <Select
                                                        // menuIsOpen={true}
                                                        placeholder={'Select'}
                                                        components={{
                                                            IndicatorSeparator: () => null
                                                        }}
                                                        isSearchable={false}
                                                        className={`selectOption mr-2 mt-3 w-30 ${this.state.hasError && !this.state['identify_rule_'+i] ? 'dottedDangerSelectLine' : ''}`}
                                                        value={({
                                                            value: this.state['identify_rule_'+i] ? this.state['identify_rule_'+i] : 'Select',
                                                            label: this.state['identify_rule_'+i] ? this.state['identify_rule_'+i] : 'Select',
                                                        })}
                                                        options={this.state['identityRule_'+this.state['identify_service_'+i]] && this.state['identityRule_'+this.state['identify_service_'+i]].map(item => ({
                                                            value: item,
                                                            label: item,
                                                        }))}
                                                        onChange={event =>  
                                                            this.setState({ ['identify_rule_'+i]: event.value })
                                                        }
                                                    />
                                                    
                                                    {this.props.pageType.toLowerCase() !== 'view' && this.state.identifyArray.length > 1 ?
                                                        <span className={`far fa-trash cursor-pointer f18 align-self-end ml-4`} onClick={() => this.removeIdentifySection(item, i)}></span>
                                                    : null}
                                                    {this.props.pageType.toLowerCase() !== 'view' && (this.state.identifyArray.length - 1) ===  i ?
                                                        <span className={`far fa-plus cursor-pointer f18 align-self-end ml-4`} onClick={() => this.addSection('identifyArray')}></span>
                                                    : null}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </React.Fragment>
                            : null}
                                
                            {this.state.deidentify ?
                                <React.Fragment>
                                    <p className={`mb-0 mr-2 text-purple ${(!this.state.identify || this.props.pageType.toLowerCase() === 'view')    ? 'mt-4' : ''}`}>Store deidentified entities in</p>
                                    {this.state.deIdentifyArray.map((item, i) => {
                                        return(
                                            <div className="w-100">                                                        
                                                <div className="d-flex flex-wrap">
                                                    <p className="mb-0 mr-2 align-self-end text-purple mt-3">Bucket</p>
                                                    <input 
                                                        type="text" 
                                                        className={`inputField mt-3 w-30  ${this.state.hasError && (!this.state['bucket_folder_name_'+item] || this.state['bucket_folder_name_'+item] === '') ? 'dottedDangerTextboxLine' : ''}`}
                                                        value={this.state['bucket_folder_name_'+item] ? this.state['bucket_folder_name_'+item] : ''}
                                                        onChange={e => this.setState({ ['bucket_folder_name_'+item]: restrictCharacter(e.target.value, '/') })}
                                                    />
                                                    <p className="mb-0 mx-2 align-self-end text-purple mt-3">Prefix</p>
                                                    <input 
                                                        className={`inputField mt-3 w-30 ${this.state.hasError && (!this.state['prefix_folder_name_'+item] || this.state['prefix_folder_name_'+item] === '') ? 'dottedDangerTextboxLine' : ''}`}
                                                        value={this.state['prefix_folder_name_'+item] ? this.state['prefix_folder_name_'+item] : ''}
                                                        onChange={e => this.setState({ ['prefix_folder_name_'+item]: e.target.value })}
                                                    />
                                                    {i ?
                                                        <span className={`far fa-trash cursor-pointer f18 align-self-end ml-4`} onClick={() => this.removeBucketFolderSection(item, i)}></span>
                                                    : null}
                                                </div>
                                            </div>
                                        )
                                    })}
                                    
                                    <div className={`d-flex col-sm-12 justify-content-end ${this.props.pageType.toLowerCase() === 'view' ? 'displayNone' : ''}`}>
                                        <span className='btn btn-secondary' onClick={() => this.addSection('deIdentifyArray')}>Add</span>
                                    </div>
                                </React.Fragment>
                            : null}
                        </div>
                        {this.props.pageType !== 'create' ?
                            <div style={{ display: "inline" }} className="mr-2 align-self-end">
                                <div className="form-check">
                                    <input type="checkbox"
                                        onChange={() => this.setState({ mandatory: !this.state.mandatory },)}
                                        className="form-check-input"
                                        checked={this.state.mandatory}
                                    />
                                    <p className="form-check-label mb-0 mr-2 text-purple">Enable</p>
                                </div>
                            </div>
                        : null}
                    </div>
                    <div className={`border-top pt-2 ${this.state.hasError && this.state.errorMessage !== '' ? '' : 'mt-5'}`}>
                        <button className='btn btn-light mr-1' onClick={() => this.props.showListPage()}>Cancel</button>
                        {this.props.pageType.toLowerCase() !== 'view' ?
                            !this.state.showActionCompleted ?
                                this.state.showActionLoading ?
                                    <button className='btn btn-primary' onClick={() => this.actionPorcess()}>
                                        <Spinner size='sm' className='mx-2' color='dark' /> {this.props.pageType.toLowerCase() === 'create' ? 'Creating ... ' : 'Updating ...' }
                                    </button>
                                : 
                                    <button className='btn btn-primary' onClick={() => this.actionPorcess()}>{this.props.pageType.toLowerCase() === 'create' ? 'Create' : 'Update' }</button>
                            :
                                <button className='btn btn-primary disabled'>
                                    {this.props.pageType.toLowerCase() === 'create' ? 'Created' : 'Updated' }
                                </button>
                        : null}
                    </div>
                </div>
            :
                <div className='text-center mt-5' >
                    <Spinner color='dark' size='lg' />
                </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
AddEdit.propTypes = {}

const mapStateToProps = state => {
    return {
		providers: state.filters.providers,
		accounts: state.filters.accounts,
		dlpConfigEngineEventsList: state.dlp.dlpConfigEngineEventsList
    }
}

export default connect(mapStateToProps, {
    getDlpEngineEventsBaseParams,
    listAllProviders,
    listAllAccounts,
    listMsc,
    insertMsc,
    updateMsc,
})(withRouter(AddEdit))