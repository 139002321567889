/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AlarmsTab.js
 * @author Prakash // on 08/06/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { listObservabilityAlertGroups, listObservabilityAlerts } from '../../../../actions/aiops/ObservabilityAction'
import { setResourcePropsDetails } from '../../../../actions/assets/assetsAction'

import _ from 'lodash'
import { CSVLink } from "react-csv";
import { momentDateGivenFormat, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord } from '../../../../utils/utility'

import Search from '../../../common/SearchComponent'

class AlarmsTab extends Component {
    constructor(props) {
        super(props)
        this.toggleRef = React.createRef()
        
        this.state = {
            alertListLoader: true,
            
            startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
            overAllTotalPages: 1,
			perPage: 100,
        }
    }

	componentDidMount = () => {
        if(this.props.leftSectionSelection && Object.entries(this.props.leftSectionSelection).length) {
            this.setState({
                selectedDetail: this.props.leftSectionSelection.selectedDetail,
                start_time: this.props.leftSectionSelection.start_time,
                end_time: this.props.leftSectionSelection.end_time,
                alertListLoader: true,
            },
                () => {
                    this.listObservabilityAlerts()
                    // this.listObservabilityAlertGroups()
                }
            )
        }
    }

    componentDidUpdate = (prevProps) => {
        
        if(this.props.leftSectionSelection && Object.entries(this.props.leftSectionSelection).length) {
            if(!_.isEqual(prevProps.leftSectionSelection, this.props.leftSectionSelection)) {
                this.setState({
                    selectedDetail: this.props.leftSectionSelection.selectedDetail,
                    start_time: this.props.leftSectionSelection.start_time,
                    end_time: this.props.leftSectionSelection.end_time,
                    alertListLoader: true,
                },
                    () => {
                        this.listObservabilityAlerts()
                        // this.listObservabilityAlertGroups()
                    }
                )
            }
        } 
    }

    navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = this.state.startRecord
            if(action === 'next' && currentPage !== this.state.totalPages) {
                startRecord = startRecord + this.state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - this.state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = this.state.totalPages
                startRecord = (this.state.totalPages - 1) * this.state.perPage
            }
            this.setState({ currentPage, startRecord })
        } 
	}

    gotoPage = (pageNumber) => {
        if(pageNumber > 0  && pageNumber <= this.state.totalPages) {
            let startRecord = (pageNumber - 1) * this.state.perPage
            this.setState({ currentPage: pageNumber, pageNumber, startRecord })
        } else  if(pageNumber > this.state.totalPages) {
            this.setState({ pageNumber: this.state.currentPage })  
        } else {
            this.setState({ pageNumber })
        }
    }

    listObservabilityAlerts = () => {
        // let params = {}
        // // params.alerts_case_id = '2408-1028-7602'
        // params.start_time = momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss')
        // params.end_time = momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss')
        // // params.duration = "2w"
        
        // params.asset_id = this.state.selectedDetail.asset_id ? this.state.selectedDetail.asset_id : ""
        // params.aggregate_by = "severity"        
        
        // if(this.state.selectedField !== "") {
        //     params.severity  = this.state.selectedField
        // } else {
        //     params.severity = this.state.allFields
        // }
        let paramsData = this.state.selectedDetail.request_metric_details ? this.state.selectedDetail.request_metric_details : {}
        // let params = _.omit(paramsData, ['metric_name','statistic']);
        let params = {}
        params.event_source = "cloudwatch_alarm"
        Object.entries(paramsData).forEach(([key, value]) => {
            params[key] = value
        })
        // params["event_source"] = "cloudwatch_alarm"
        let serviceQquotaId = this.state.selectedDetail.service_quota_id

        // console.log("params", params)

        if(paramsData && Object.entries(paramsData).length) {
            if(!this.props.alarmsTabDetails.hasOwnProperty(serviceQquotaId)) {
                this.props.listObservabilityAlerts(params, (promise, response) => {
                    if(promise) {
                        let results = response.results ? response.results : []
        
                        let totalPages = 1
                        if(results.length > this.state.perPage) {
                            totalPages = Math.ceil(results.length / this.state.perPage)
                        }

                        this.setState({
                            alertsList: results,
                            filteredArray: results,
                            alertListLoader: false,
                            totalPages,
                            startRecord: 0, 
                            currentPage: 1
                        },
                            () => {
                                this.props.menuDisabled()
                                this.formHeaders()
                            }
                        )                    

                        if(results.length) {
                            let obj = this.props.alarmsTabDetails ? this.props.alarmsTabDetails : {}
                            obj[serviceQquotaId] = results
                            this.props.setResourcePropsDetails('alarmsTabDetails', obj)
                        }

                    } else {
                        this.setState({ alertListLoader: false },
                            () => this.props.menuDisabled()
                        )            
                    } 
                })
            } else {
                let results = this.props.alarmsTabDetails[serviceQquotaId]
                let totalPages = 1
                if(results.length > this.state.perPage) {
                    totalPages = Math.ceil(results.length / this.state.perPage)
                }

                this.setState({
                    alertsList: results,
                    filteredArray: results,
                    alertListLoader: false,
                    totalPages,
                    startRecord: 0, 
                    currentPage: 1
                },
                    () => {
                        this.props.menuDisabled()
                        this.formHeaders()
                    }
                )
            }
        } else {
            this.setState({ alertsList: [], alertListLoader: false })
            this.props.menuDisabled()
        }
    }

	formHeaders = () => {
		let dataResult = this.state.filteredArray && this.state.filteredArray.length ? this.state.filteredArray : []
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === "string") {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})
			this.setState({ headers })
		}
	}

    getAlertDetails = (alertItemDetails) => {
        this.setState({ 
            selectedAlertId: alertItemDetails.event_id === this.state.selectedAlertId ? "" : alertItemDetails.event_id, 
            selectedAlert: alertItemDetails.event_id === this.state.selectedAlertId ? "" : alertItemDetails,
            expandAlertDetails: true,
            expandAssetDetails: false
        })
    }

    handleClickOutside(event) {
        // if (this.toggleRef && !this.toggleRef.current.contains(event.target)) {
        //     this.setState({ showPageTypeOptions: false })
        // } else {
        //     this.setState({ showPageTypeOptions: true })
        // }
    }

	render() {
		return (
            <div className="" onClick={(event) => this.handleClickOutside(event)}>
                <div className="d-flex mt-2">
                    <div className="rounded bg-dark p-0 col-sm-12">
                        {this.state.alertsList && this.state.alertsList.length ?
                            <div className="d-flex mb-2 justify-content-between">
                                <div className="d-flex mr-2">
                                    <p className="f12 m-0 align-self-center pt-1">
                                        <span>Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.state.alertsList && this.state.alertsList.length} alarm(s) from the {this.state.monitoringKey} {this.state.selectedDetail && this.state.selectedDetail.quota_name ? this.state.selectedDetail.quota_name : ""}</span>
                                        {/* <span className="mr-1">
                                            {this.state.selectedDetail && this.state.selectedDetail.applied_quota_value ?
                                                "Applied value"
                                            : this.state.selectedDetail && this.state.selectedDetail.default_quota_value ?
                                                "Default value "
                                            : null}
                                        </span> */}
                                        <span className="mx-1">
                                            {this.state.selectedDetail && this.state.selectedDetail.applied_quota_value ?
                                                this.state.selectedDetail.applied_quota_value
                                            : this.state.selectedDetail && this.state.selectedDetail.default_quota_value ?
                                                this.state.selectedDetail.default_quota_value
                                            : null}
                                        </span>
                                        <span className="f12 m-0 align-self-center pt-1 text-info ml-1">{this.state.brushStartTime ? momentDateGivenFormat(this.state.brushStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.brushEndTime, 'DD MMM YYYY HH:mm') : ""}</span>
                                    </p>
                                </div>
                                <div className="d-flex">
                                    <CSVLink 
                                        data={this.state.filteredArray ? this.state.filteredArray : []} 
                                        headers={this.state.headers ? this.state.headers : []}
                                        filename={"resource-alerts.csv"}
                                        className={"mt-2"}
                                        target="_blank"
                                    >
                                        <span className="d-flex text-primary-color mr-2">
                                            <i className="fas fa-download text-primary-color mr-2"></i>
                                            {/* csv */}
                                        </span>
                                    </CSVLink>
                                    <div className="justify-content-end w-100 mb-n2">
                                        <Search
                                            data={this.state.alertsList ? this.state.alertsList : []}
                                            applyTags={false}
                                            applyLiteDarkTags={true}
                                            topClassName={'bg-black5 align-self-center w-100 mr-1 border-gray5 rounded-5 f12'}
                                            searchClassName={'px-2 f12'}
                                            searchIconColor={'text-gray5 f12'}
                                            searchPlaceHolder={'Search alerts....'}
                                            className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                            filteredData={(filteredArray) => this.setState({ filteredArray },
                                                () => {
                                                    if(this.state.filteredArray.length) {
                                                        // this.getAlertDetails(this.state.filteredArray[0])
                                                    }
                                                }
                                            )}
                                        />
                                        {this.state.filteredArray && this.state.filteredArray.length > this.state.perPage ?
                                            <div className="pagination errorPagePagination f12 displayNone">
                                                <span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
                                                <button><i className={`fal fa-arrow-to-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('start', this.state.currentPage)}></i></button> 
                                                <button><i className={`fal fa-angle-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('previous', this.state.currentPage)}></i></button> 
                                                <span>
                                                    Go to
                                                    <input
                                                        type='number'
                                                        value={this.state.pageNumber || this.state.pageNumber === "" ? this.state.pageNumber : this.state.currentPage}
                                                        onChange={e => {
                                                            const page = e.target.value ? Number(e.target.value) : ""
                                                            this.gotoPage(page)
                                                        }}
                                                        className="ml-1 inputClass"
                                                    />
                                                </span>
                                                <button><i className={`fal fa-angle-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
                                                <button><i className={`fal fa-arrow-to-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
                                            </div>
                                        : null}
                                    </div>
                                </div>
                            </div>
                        : null}
                        
                        <div className="d-flex">
                            <div className={`w-100`}>
                                <div className={`bg-dark3 py-3 pl-2 obsMessageSectionXL`}>
                                    {this.state.alertListLoader ? 
                                        <div className='d-flex justify-content-center m-4'>
                                            <Spinner className='text-center' color='white' size='lg' />
                                        </div>
                                    : this.state.filteredArray && this.state.filteredArray.length ?
                                        // this.state.filteredArray.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map((item, i) => {
                                        this.state.filteredArray.map((item, i) => {
                                            return(
                                                <React.Fragment>
                                                <div className={`d-flex mr-2 px-2 mb-2 pb-1 text-white pt-1 border-bottom-black5`}>
                                                    <span className={`f10 mr-2 align-self-start mt-1 badge-sm risk-badge-${item.severity ? item.severity.toLowerCase() : 'critical'}`}>
                                                        {item.severity ? 
                                                            (item.severity.toLowerCase() === 'critical' ? 'C' : item.severity.toLowerCase() === 'high' ? 'H' : item.severity.toLowerCase() === 'medium' ? 'M' : 'L') 
                                                        : 'U'}
                                                    </span>
                                                    <p className="mb-0">
                                                        <span className="mb-0 mr-2 f12 font-weight-bold">{item.event_time ? momentConvertionUtcToLocalTime(item.event_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                        {item.asset_name ? 
                                                            <span className="f12 mb-0 mr-2 text-purple-2"> Asset {item.asset_name}</span> 
                                                        : null}
                                                        <span className="mb-0 mr-2 f12 font-weight-bold">{item.description ? item.description : ''}</span>
                                                    </p>
                                                </div>
                                                </React.Fragment>
                                            )
                                        })
                                    :
                                        <div className='d-flex justify-content-center m-4'>
                                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
AlarmsTab.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('errorDashboard',state)
	return {
        leftSectionSelection: state.assets.resourcesPropsDetails.leftSectionSelection ? state.assets.resourcesPropsDetails.leftSectionSelection : {},
        alarmsTabDetails: state.assets.resourcesPropsDetails && state.assets.resourcesPropsDetails.alarmsTabDetails ? state.assets.resourcesPropsDetails.alarmsTabDetails : {},
    }
}

export default connect(mapStateToProps, {
    listObservabilityAlertGroups,
    listObservabilityAlerts,
    setResourcePropsDetails,
})(withRouter(AlarmsTab))