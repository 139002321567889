/*************************************************
 * Tvastar
 * @exports
 * @file UebaJobs.js
 * @author Prakash // on 09/09/2021
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { capitalizeFirstLetter, restrictCharacter } from '../../../utils/utility'
import {
    getUebaJobBaseParams,
    insertMsc,
    updateMsc,
} from '../../../actions/dlp/DlpJobsAction'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { Spinner } from 'reactstrap'

class UebaJobs extends Component {
    hierarichalBucketBarChartRef = React.createRef()
    constructor(props) {
        super(props)
        this.props = props;
        this.state = {
            showLoading: true,
            bucketArray: [0],
            scheduleOptions: [
                {label: '1 Day', value: '1d'}, 
                {label: '1 Week', value: '7d'},
                {label: '2 Weeks', value: '14d'},
                {label: '1 Month', value: '30d'}
            ],
            services_to_run: '',
            granularityOptions: ['hourly', 'daily']
        }
    }

    componentDidMount = () => {       
        if(this.props.pageType === 'edit' || this.props.pageType === 'view') {
            let selectedRecord = this.props.selectedRecord
            let selectedSchedule = selectedRecord.schedule
            let selectedServices = selectedRecord.service_id
            let services_to_run = selectedRecord.services_to_run
            let window_size = selectedRecord.window_size
            window_size = window_size.substring(0, window_size.length - 1);
            let duration = selectedRecord.duration
            duration = duration.substring(0, duration.length - 1);
            let selectedGranularity = selectedRecord.granularity

            let mandatory = selectedRecord.mandatory
            let bucketArray = []
            if(selectedRecord.filters.bucket_name_with_prefix.length) {
                selectedRecord.filters.bucket_name_with_prefix.forEach((item, index) => {
                    bucketArray.push(index)
                    let bucketName = item.split('/')[0]
                    let prefix = item.split('/').slice(1).join('/')
                    this.setState({ ['bucket_name_'+index]: bucketName, ['prefix_name_'+index]: prefix })
                })
            } else {
                bucketArray.push(0)
            }

            let bucket_folder_name = ''
            let prefix_folder_name = ''
            if(selectedRecord.fileStorageProperties) {
                let item = selectedRecord.fileStorageProperties
                prefix_folder_name = item.config && item.config.folder && item.config.folder.folder_prefix ? item.config.folder.folder_prefix : ''
                bucket_folder_name = item.destination && item.destination.bucket_name ? item.destination.bucket_name : ''
            }

            this.setState({ selectedSchedule, bucketArray, mandatory, bucket_folder_name, prefix_folder_name, selectedServices, services_to_run, window_size, duration, selectedGranularity },
                () => this.getUebaJobBaseParams()    
            )
        } else {
            this.getUebaJobBaseParams()
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.dlpPropsDetails && typeof this.props.dlpPropsDetails.hasJobError !== 'undefined' &&  prevProps.dlpPropsDetails.hasJobError !== this.props.dlpPropsDetails.hasJobError) {
            this.setState({ hasError: true })
        }
    }

    getUebaJobBaseParams = () => {
        let params = {}
        this.props.getUebaJobBaseParams(params, (promise, response) => {
            let selectedServices = this.state.selectedServices ? this.state.selectedServices : []
            let servicesOption = response.services
            var newServices = Object.assign( { all: "All" }, servicesOption );
            if(this.state.services_to_run.toLowerCase() === 'all') {
                Object.keys(newServices).forEach(item => {
                    selectedServices.push(item)
                })
            }
            this.setState({ masterData: response, servicesOption: newServices, selectedServices, showLoading: false })
        })
    }

    removeSelectedOption = (field, value) => {
        let filteredReslt = this.state[field].filter(e => e !== value)
        this.setState({ [field]: filteredReslt })
    }

    onChangePropsEvent = () => {
        let hasError = false
        if(!this.state.selectedSchedule || this.state.selectedSchedule === '') {
            hasError = true
        }

        if(!this.state.selectedServices || !this.state.selectedServices.length) {
            hasError = true
        }

        if(!this.state.selectedGranularity || this.state.selectedGranularity === '') {
            hasError = true
        }

        if(!this.state.window_size || this.state.window_size === '') {
            hasError = true
        }

        if(!this.state.duration || this.state.duration === '') {
            hasError = true   
        }
        
        let checkBucketDataExist = 0
        this.state.bucketArray.forEach(item => {
            if(this.state['bucket_name_'+item] === '' && this.state['prefix_name_'+item] !== '') {
                hasError = true
            } else if(this.state['bucket_name_'+item] !== '') {
                checkBucketDataExist++
            }
        })
        
        if(!checkBucketDataExist) {
            hasError = true
        }
        
        if(this.state.bucket_folder_name !== '' && this.state.prefix_folder_name === '') {
            hasError = true
        } else if(this.state.bucket_folder_name === '' && this.state.prefix_folder_name !== '') {
            hasError = true
        } else if(this.state.bucket_folder_name === '' && this.state.prefix_folder_name === '') {
            hasError = true
        } else if(!this.state.bucket_folder_name || !this.state.prefix_folder_name) {
            hasError = true
        }

        let data = {}
        data.mandatory = this.props.pageType === 'create' ? true : this.state.mandatory
        data.schedule = this.state.selectedSchedule
        data.window_size = this.state.window_size+'d'
        data.duration = this.state.duration+'d'
        data.granularity = this.state.selectedGranularity
        
        let jobText = ' schedule '+this.state.selectedSchedule+' window size '+data.window_size+' duration '+data.duration+' granularity '+data.granularity
        
        data.service_id = this.state.selectedServices
        data.services_to_run = ''
        
        if(this.state.selectedServices.includes('all')) {
            data.service_id = []
            data.services_to_run = 'all'
            jobText += ' services all'
        } else {
            jobText += ' services '+this.state.selectedServices.join(', ')
        }
        
        jobText += ' for buckets'
        let buckets = []
        this.state.bucketArray.forEach(item => {
            if(this.state['bucket_name_'+item] !== '') {
                let bucketPrefix = this.state['bucket_name_'+item]
                bucketPrefix += this.state['prefix_name_'+item] ? ('/'+this.state['prefix_name_'+item]) : ''
                buckets.push(bucketPrefix)
                jobText += ' bucket name '+this.state['bucket_name_'+item]+' prefix '+this.state['prefix_name_'+item]
            }
        })
        let filters = {}
        filters.bucket_name_with_prefix = buckets

        data.filters = filters

        if(this.state.masterData && this.state.masterData.static_fields) {
            Object.entries(this.state.masterData.static_fields).forEach(([key, value]) => {
                data[key] = value
            })
        } 

        jobText += ' store results in bucket '+this.state.bucket_folder_name+' prefix '+this.state.prefix_folder_name

        let object = this.state.masterData.fileStorageProperties
        
        let newObject = {}
        if(this.state.bucket_folder_name !== '' && this.state.prefix_folder_name !== '') {
            let config = Object.assign({}, object.config, { 
                folder: Object.assign({}, object.config.folder, {
                    folder_prefix: this.state.prefix_folder_name
                })
            })
            let destinationsObject = Object.assign({}, object.destination, {
                bucket_name: this.state.bucket_folder_name 
            });

            newObject.config = config
            newObject.destination = destinationsObject
        }

        data.fileStorageProperties = newObject

        this.props.jobData(data, jobText, hasError)


    }    

    onKeyDown = (e) => {
        let selectedTags = this.state.selectedTags ? this.state.selectedTags : []
		if (e.keyCode === 13 || e.keyCode === 9) {
            if(this.state.selected_tag !== '') {
                selectedTags.push(this.state.selected_tag.trim())
                if(e.keyCode === 9) {
                    e.preventDefault();
                }
                this.setState({ selectedTags, selected_tag: '' })
            }
        }
    }

    addSection = (array) => {
        let rowList = this.state[array];
        if(this.state[array]) {
            let value = this.state[array][this.state[array].length - 1]
            value = value+1
            rowList.push(value);
        }
        
        this.setState({[array]: rowList })
    }

    removeBucketSection = (i) => {
        this.setState({ ['bucket_name_'+i]: '', ['prefix_name_'+i]: '' },
            () => {
                let rowList = this.state.bucketArray;
                rowList.splice(i, 1);
                this.setState({ bucketArray: rowList })
            }
        );
    }

    getSchedulLabel = () => {
        let scheduleLabel = ''
        this.state.scheduleOptions.forEach(item => {
            if(item.value === this.state.selectedSchedule) {
                scheduleLabel = item.label
            }
        })
        return scheduleLabel
    }

    handleMultiSelectChange = (field, choosen) => {
        let selectedValue = []
		if(field === 'selectedServices') {
			let value = choosen.map(item => item.value)
            let prevState = this.state.selectedServices
            if(value.includes('all')) {
                if(!prevState.includes('all')) {
                    Object.entries(this.state.servicesOption).forEach(([key, value]) => {
                        selectedValue.push(key)
                    })
                } else {
                    const index = value.indexOf('all');
                    if (index > -1) {
                        value.splice(index, 1);
                    }
                    selectedValue = value
                }
            } else if(!prevState.includes('all')) {
                selectedValue = value
            }

            if(selectedValue.length && !selectedValue.includes('all')) {
                if(selectedValue.length === Object.entries(this.state.masterData.services).length) {
                    selectedValue.push('all')
                }
            }
			this.setState({ [field]: selectedValue }, () => this.onChangePropsEvent())
		}
	}

    render() {		
        return (
            !this.state.showLoading ?
                <div className={`${this.props.pageType === 'view' ? 'disabled disableInputColor' : ''}`}>
                    <div className={`form-group dlpDescriptionForm`}>
                        <div className="d-flex flex-wrap">
                            <p className="mb-0 mr-2 align-self-end text-info mt-3">Schedule</p>
                            <Select
                                placeholder={'Select'}
                                isSearchable={false}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                className={`selectOption mr-2 mt-3 ${this.state.hasError && !this.state.selectedSchedule ? 'dottedDangerSelectLine' : ''}`}
                                value={({
                                    value: this.state.selectedSchedule ? this.state.selectedSchedule : 'Select',
                                    label: this.state.selectedSchedule ? this.getSchedulLabel() : 'Select'
                                })}
                                options={this.state.scheduleOptions && this.state.scheduleOptions.map(item => ({
                                    value: item.value,
                                    label: item.label,	
                                }))}
                                onChange={event =>  this.setState({ selectedSchedule: event.value }, () => this.onChangePropsEvent())}
                            />
                            <p className="mb-0 mx-2 align-self-end text-info mt-3">Services</p>
                            <div className={`multiSelectOption  align-self-end mt-3 ${this.state.hasError && (!this.state.selectedServices || !this.state.selectedServices.length)  ? 'dottedDangerMultiSelectLine' : ''}`}>
                                <ReactMultiSelectCheckboxes						
                                    placeholderButtonLabel="Select"
                                    isSearchable={true}
                                    options={this.state.servicesOption && Object.entries(this.state.servicesOption).map(([key, value]) => ({
                                        value: key,
                                        label: value,
                                    }))}
                                    onChange={event => {
                                        this.handleMultiSelectChange('selectedServices', event) }
                                    }
                                    value={this.state.selectedServices && this.state.selectedServices.map(item => ({
                                        value: item,
                                        label: item
                                    }))}
                                />
                            </div>
                            <p className="mb-0 mx-2 align-self-end text-info mt-3">window size</p>
                            <input 
                                type="text" 
                                className={`inputField mt-3 w-10 ${this.state.hasError && (!this.state.window_size || this.state.window_size === '') ? 'dottedDangerTextboxLine' : ''} `}
                                value={this.state.window_size ? this.state.window_size : ''}
                                onChange={e => this.setState({ window_size: e.target.value }, () => this.onChangePropsEvent())}
                            />
                            <p className="mb-0 ml-2 align-self-end text-info mt-3">days</p>
                        </div>
                        <div className="d-flex flex-wrap">
                            <p className="mb-0 mr-2 align-self-end text-info mt-3">Duration</p>
                            <input 
                                type="text" 
                                className={`inputField mt-3 w-10 ${this.state.hasError && (!this.state.duration || this.state.duration === '') ? 'dottedDangerTextboxLine' : ''} `}
                                value={this.state.duration ? this.state.duration : ''}
                                onChange={e => this.setState({ duration: e.target.value }, () => this.onChangePropsEvent())}
                            />
                            <p className="mb-0 mx-2 align-self-end text-info mt-3">days</p>
                            <p className="mb-0 mr-2 align-self-end text-info mt-3">granularity</p>
                            <Select
                                placeholder={'Select'}
                                isSearchable={false}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                className={`selectOption mr-2 mt-3 ${this.state.hasError && !this.state.selectedGranularity ? 'dottedDangerSelectLine' : ''}`}
                                value={({
                                    value: this.state.selectedGranularity ? this.state.selectedGranularity : 'Select',
                                    label: this.state.selectedGranularity ? capitalizeFirstLetter(this.state.selectedGranularity) : 'Select',
                                })}
                                options={this.state.granularityOptions && this.state.granularityOptions.map(item => ({
                                    value: item,
                                    label: capitalizeFirstLetter(item)
                                }))}
                                onChange={event =>  this.setState({ selectedGranularity: event.value }, () => this.onChangePropsEvent())}
                            />
                        </div>
                        
                        <p className="mb-0 mr-2 align-self-end text-info mt-4">for the buckets</p>
                        {this.state.bucketArray.map((item, i) => {
                            return(
                                <div className="w-100">
                                    <div className="d-flex flex-wrap">
                                        <p className="mb-0 mr-2 align-self-end text-info mt-3">Bucket</p>
                                        <input 
                                            type="text" 
                                            className={`inputField mt-3 w-30 ${this.state.hasError && (!this.state['bucket_name_'+item] || this.state['bucket_name_'+item] === '') ? 'dottedDangerTextboxLine' : ''}`}
                                            value={this.state['bucket_name_'+item] ? this.state['bucket_name_'+item] : ''}
                                            onChange={e => this.setState({ ['bucket_name_'+item]: restrictCharacter(e.target.value, '/') }, () => this.onChangePropsEvent())}
                                        />
                                        <p className="mb-0 mx-2 align-self-end text-info mt-3">Prefix</p>
                                        <input 
                                            type="text" 
                                            className={`inputField mt-3 w-30`}
                                            value={this.state['prefix_name_'+item] ? this.state['prefix_name_'+item] : ''}
                                            onChange={e => this.setState({ ['prefix_name_'+item]: e.target.value }, () => this.onChangePropsEvent())}
                                        />
                                        {i ?
                                            <span className={`far fa-trash cursor-pointer f18 align-self-end ml-4`} onClick={() => this.removeBucketSection(i)}></span>
                                        : null}
                                    </div>
                                </div>
                            )
                        })}
                        
                        <div className={`d-flex col-sm-12 justify-content-end ${this.props.pageType.toLowerCase() === 'view' ? 'displayNone' : ''}`}>
                            <span className='btn btn-secondary' onClick={() => this.addSection('bucketArray')}>Add</span>
                        </div>
                        <p className={`mb-0 mr-2 text-info ${this.props.pageType.toLowerCase() === 'view' ? 'mt-4' : ''}`}>Store results in</p>
                        <div className="w-100">                                                        
                            <div className="d-flex flex-wrap">
                                <p className="mb-0 mr-2 align-self-end text-info mt-3">Bucket</p>
                                <input 
                                    type="text" 
                                    className={`inputField mt-3 w-30  ${this.state.hasError && (!this.state.bucket_folder_name || this.state.bucket_folder_name === '') ? 'dottedDangerTextboxLine' : ''}`}
                                    value={this.state.bucket_folder_name ? this.state.bucket_folder_name : ''}
                                    onChange={e => this.setState({ bucket_folder_name: restrictCharacter(e.target.value, '/') }, () => this.onChangePropsEvent())}
                                />
                                <p className="mb-0 mx-2 align-self-end text-info mt-3">Prefix</p>
                                <input 
                                    className={`inputField mt-3 w-30 ${this.state.hasError && (!this.state.prefix_folder_name || this.state.prefix_folder_name === '') ? 'dottedDangerTextboxLine' : ''}`}
                                    value={this.state.prefix_folder_name ? this.state.prefix_folder_name : ''}
                                    onChange={e => this.setState({ prefix_folder_name: e.target.value }, () => this.onChangePropsEvent())}
                                />
                            </div>
                        </div>
                    </div>
                    {this.props.pageType !== 'create' ?
                        <div style={{ display: "inline" }} className="mr-2 align-self-end">
                            <div className="form-check">
                                <input type="checkbox"
                                    onChange={() => this.setState({ mandatory: !this.state.mandatory }, () => this.onChangePropsEvent())}
                                    className="form-check-input"
                                    checked={this.state.mandatory}
                                />
                                <p className="form-check-label mb-0 mr-2 text-info">Enable</p>
                            </div>
                        </div>
                    : null}
                </div>
            :
                <div className='text-center mt-5' >
                    <Spinner color='white' size='lg' />
                </div>
        )
    }
}

const mapStateToProps = state => {
    return {
		dlpPropsDetails: state.dlp.dlpPropsDetails
    }
}

export default connect(mapStateToProps, {
    getUebaJobBaseParams,
    insertMsc,
    updateMsc,
})(withRouter(UebaJobs))