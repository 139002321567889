/*************************************************
 * Tvastar
 * @exports
 * @file Constants.js
 * @author Rajasekar // on 19/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import { dayOfThisWeek } from './utility'
export const STORE = {
	NAME: 'userSession',
}
export const DURATION_FILTER_OPTIONS = [
	{ value: dayOfThisWeek(), option: 'This Week' },
	{ value: '+7d', option: 'Last 1 week' },
	{ value: '+14d', option: 'Last 2 weeks' },
	{ value: '+30d', option: 'Last 1 month' },
	{ value: '+60d', option: 'Last 2 months' },
	{ value: '+90d', option: 'Last 3 months' },
]
export const CUSTOM_DURATION_FILTER_OPTIONS = [
	{ value: dayOfThisWeek(), option: 'This Week' },
	{ value: '+7d', option: 'Last 1 week' },
	{ value: '+14d', option: 'Last 2 weeks' },
	{ value: '+30d', option: 'Last 1 month' },
	{ value: '+60d', option: 'Last 2 months' },
	{ value: '+90d', option: 'Last 3 months' },
	{ value: 'custom', option: 'Custom Date' },
]
export const CUSTOM_YESTERDAY_DURATION_FILTER_OPTIONS = [
	{ value: 'yesterday', option: 'Yesterday' },
	{ value: dayOfThisWeek(), option: 'This Week' },	
	{ value: '+7d', option: 'Last 1 week' },
	{ value: '+14d', option: 'Last 2 weeks' },
	{ value: '+30d', option: 'Last 1 month' },
	{ value: '+60d', option: 'Last 2 months' },
	{ value: '+90d', option: 'Last 3 months' },
	{ value: 'custom', option: 'Custom Date' },
]

export const HOURLY_DURATION = [
	{ value: '+1h', option: 'Last 1 hour' },
	{ value: '+3h', option: 'Last 3 hours' },
	{ value: '+6h', option: 'Last 6 hours' },
	{ value: '+1d', option: 'Last 1 day' },
	{ value: '+3d', option: 'Last 3 days' },
	{ value: '+7d', option: 'Last 1 week' },
	{ value: 'custom', option: 'Custom Date' },
]

export const HOURLY_DURATION_WITHOUT_CUSTOM_DATE = [
	{ value: '+1h', option: 'Last 1 hour' },
	{ value: '+3h', option: 'Last 3 hours' },
	{ value: '+6h', option: 'Last 6 hours' },
	{ value: '+1d', option: 'Last 1 day' },
	{ value: '+3d', option: 'Last 3 days' },
	{ value: '+7d', option: 'Last 1 week' },
]

export const HOURLY_DURATION_2 = [
	{ value: '1h', option: 'Last 1 hour' },
	{ value: '3h', option: 'Last 3 hours' },
	{ value: '6h', option: 'Last 6 hours' },
	{ value: '1d', option: 'Last 1 day' },
	{ value: '3d', option: 'Last 3 days' },
	{ value: '7d', option: 'Last 1 week' },
]

export const PRIORITY_FILTER_OPTIONS = [
	{ value: 'All', option: 'All' },
	{ value: 'Critical', option: 'Critical' },
	{ value: 'High', option: 'High' },
	{ value: 'Medium', option: 'Medium' },
	{ value: 'Low', option: 'Low' },
]
export const HTTP_CODE = {
	SUCCESS: 200,
	INSERT_SUCESS: 201,
	AUTHENTICATION_FAILURE: 401,
	REQUIRED_MISSING: 403,
	REQUEST_TIMED_OUT_FAILURE: 500,
	INPUT_VALIDATION_ERROR: 400,
	NO_DATA_FOUND: 404,
}

export const SUCCESS = 'success'
export const ERROR = 'error'

export const PER_PAGE = 10
