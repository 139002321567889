/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ListPreBuiltAutomations .js
 * @author Prakash // on 26/05/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import PropTypes from 'prop-types'
import { CSVLink } from "react-csv";

import { Spinner } from 'reactstrap'
import { listPrebuildAutomationPlans } from '../../actions/AutomationAction'
import ResizeableDarkThemeTable from '../designComponents/Table/ResizeableDarkThemeTable'
import Search from '../common/SearchComponent'
import { URL_PATH } from '../../config/urlPath'
import { capitalizeTheFirstLetterOfEachWord } from '../../utils/utility'

class ListPreBuiltAutomations extends Component {
	accountRef = React.createRef()
	regionRef = React.createRef()

	constructor(props) {
		super(props)

		this.state = {
			showLoading: true,
			
			automationList: [],
			filteredArray: [],

			selectedTableRow: {},

		}
	}

	componentDidMount = () => {
		this.listPrebuildAutomationPlans()
	}

	listPrebuildAutomationPlans = () => {
		let params = {}

		this.props.listPrebuildAutomationPlans(params, (promise, response) => {
			if(response) {
				this.setState({ automationList: response, filteredArray: response, showLoading: false },
					() => this.formHeader()
				)
			}
		})
	}

	formHeader = () => {
		let dataResult = this.state.filteredArray && this.state.filteredArray.length ? this.state.filteredArray : []
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === "string") {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})
			this.setState({ headers })
		}
	}
 
	onClickEditAutomation = (selectedAutomation) => {
		if(selectedAutomation.automation_type !== 'Remediation') {
			let actions = selectedAutomation.actions ? selectedAutomation.actions : []
	
			let newAction = []
			actions.forEach((act, i) => {
				act.section = i+4
				newAction.push(act)
			})		
			selectedAutomation.actions = newAction
		}

		
		this.props.history.push({
			pathname: URL_PATH.EDIT_AUTOMATION,
			state: { selectedRule: selectedAutomation }
		})
	}

	/**
	 * Renders governance Dashboard
	*/
	render() {
		return (
			<div className=''>
				<div className="d-flex justify-content-between pb-1">
					<p className="mb-0 align-self-center">Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.state.automationList && this.state.automationList.length} automations</p>
					
					{this.state.automationList && !this.state.automationList.length ?
						<div className="d-flex">
							<CSVLink 
								data={this.state.filteredArray ? this.state.filteredArray : []} 
								headers={this.state.headers ? this.state.headers : []}
								filename={"automations_pre_build.csv"}
								className={"mt-2"}
								target="_blank"
							>
								<span className="d-flex text-primary-color mr-2">
									<i className="fas fa-download text-primary-color mr-2"></i>
									{/* csv */}
								</span>
							</CSVLink>
							<div className={`mb-n2 ${this.state.showLoading ? 'disabled' : ''}`}>
								<Search
									data={this.state.automationList}
									filteredData={searchResult => {
										this.setState({ filteredArray: searchResult })
									}}
								/>
							</div>
						</div>
					: null}
				</div>
				{this.state.automationList && this.state.automationList.length && !this.state.showLoading ? (
					<ResizeableDarkThemeTable
						columns={[
							{
								Header: "Provider",
								Cell: cellInfo => (
									cellInfo.row.original.provider.toUpperCase()
								),

							},
							{
								Header: "Services",
								accessor: d => (d.resource_type + ': ' + d.service_name),
								Cell: cellInfo => ((cellInfo.row.original.resource_type ? cellInfo.row.original.resource_type : "")+""+
									(cellInfo.row.original.resource_type ? " : "+cellInfo.row.original.service_name : "")
								),
								width: 350

							},
							{
								Header: "Name",
								accessor: "name",
								Cell: cellInfo => (
									<div className="d-flex"> 
										<small className={`align-self-center badge risk-badge-${cellInfo.row.original.automation_type.toLowerCase() === "application" ? "critical" : "medium"}`}>{cellInfo.row.original.automation_type.toLowerCase() === 'application' ? "App" : "Rem"}</small>
										<span className="ml-3">{cellInfo.row.original.name}</span>
									</div>
								),
								width: 500
							},
							{
								Header: "Description",
								accessor: "description",
								width: 700
							},
							// {
							// 	Header: 'Status',
							// 	accessor: 'enabled',
							// 	Cell: cellInfo => (
							// 		<span
							// 			className={`badge status-badge badge-outline-${
							// 				cellInfo.row.original.enabled ?'success-black' : 'danger-black'
							// 				} badge-pill`}
							// 		>
							// 			{cellInfo.row.original.enabled ? 'Enabled' : 'Disabled'}
							// 		</span>
							// 	),
							// },
							{
								Header: 'Actions',
								Cell: cellInfo => (
									<div className="d-flex justify-content-between align-self-center">
										{/* <p className="text-white m-0 p-0 mr-1 cursorPointer"><i className="fal fa-book-open"></i></p> */}
										{/* <p className="text-white m-0 p-0 mr-1 cursorPointer"><i className="fas fa-pencil" onClick={() => this.onClickEditAutomation(cellInfo.row.original)}></i></p> */}
										<span className="badge badge-info mr-1 cursorPointer">View</span>
										{/* <p className="text-white m-0 p-0 mr-1 cursorPointer"><i className="fal fa-trash"></i></p>  */}
										<div className="toggleOnOffSwitchTable d-flex mr-1 align-self-center">
											<label className="switch mb-0" id={cellInfo.row.original.processor_rule_id} >
												<input type="checkbox" 
													className="inputdemo" 
													checked={this.state['enabled_'+cellInfo.row.original.processor_rule_id] ? (this.state['enabled_'+cellInfo.row.original.processor_rule_id] === 'Enabled' ? true : false) : cellInfo.row.original.enabled}
												/>
												<span className="slider round"></span>
											</label>
										</div>
										
										<span className="small">{this.state['enabled_'+cellInfo.row.original.processor_rule_id] ? this.state['enabled_'+cellInfo.row.original.processor_rule_id] : (cellInfo.row.original.enabled ? 'Enabled' : "Disabled")}</span>
										{/* <p className="text-white m-0 p-0 mr-1 cursorPointer">
											{this.state.showEnableSpinner && cellInfo.row.original.rule_id === this.state.showEnableSpinner ?
												<Spinner className='text-center' color='white' size='sm' />
											:
												cellInfo.row.original.enabled ? <i className="fal fa-thumbs-up"></i> : <i className="fal fa-thumbs-down"></i>
											}
										</p> */}
									</div>
								)
							}
						]}
						data={this.state.filteredArray && this.state.filteredArray}
						perPage={20}
						// tableHead={'bg-dark'}
						sortByColumn={'Date'}
						dashboard
						tooltipFor={[2, 3]}
						onClickRow={tableRow => {}}
						manualWidth={true}
					/>	
				) : (!this.state.showLoading && !this.props.rules) ||
					(this.props.rules && !this.props.rules.length && !this.state.showLoading) ? (
					<div className='d-flex justify-content-center m-4'>
						<p>There are no data on this criteria. Please try adjusting your filter.</p>
					</div>
				) : null}
				{this.state.showLoading ? (
					<div className='d-flex justify-content-center m-4'>
						<Spinner className='text-center' color='white' size='lg' />
					</div>
				) : null}
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ListPreBuiltAutomations.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('-',state)
	return {        
		providers: state.filters.providers,
        accounts: state.filters.accounts,
		regions: state.filters.regions,
	}
}

export default connect(mapStateToProps, {
	listPrebuildAutomationPlans,
})(withRouter(ListPreBuiltAutomations))