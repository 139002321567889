/*************************************************
 * Tvastar
 * @exports
 * @file BackupAndSnapshot.js
 * @author Rajasekar // on 20/02/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class BackupAndSnapshot extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	render() {
		return (
			<div className='box p-3 bg-dark3'>
				<div className='d-flex'>
					<p className='flex-grow-1 mb-2 text-white'>
						<b>Backup and Snapshots</b>
					</p>
					{/* <p className='mb-2'>
						<span className='badge badge-outline-success'>Enabled - 2d</span>
					</p> */}
				</div>
				<div>
					{this.props.restoreWindowlatestTime ? (
						<p className='text-muted mb-0'>
							Latest restore: <b className='text-white'>{this.props.restoreWindowlatestTime}</b>
						</p>
					) : null}
					{this.props.dbInstanceCopyTagsToSnapshot ? (
						<p className='text-muted mb-0'>
							Copy tags to snapshots: <b className='text-white'>{this.props.dbInstanceCopyTagsToSnapshot}</b>
						</p>
					) : null}
					{this.props.dbInstancePreferredBackupWindow ? (
						<p className='text-muted mb-2'>
							Backup window: <b className='text-white'>{this.props.dbInstancePreferredBackupWindow}</b>
						</p>
					) : null}
					{this.props.db_instance_copy_tags_to_snapshot ? (
						<p className='text-muted mb-2'>
							Copy tags to snapshots: <b className='text-white'>{this.props.db_instance_copy_tags_to_snapshot}</b>
						</p>
					) : null}
				</div>
				<div className='bordered-tile'>
					<div className='flex-grow-1'>
						<p className='mb-2'>{this.props.snapshotName}</p>
						<p className='mb-0'>{this.props.time}</p>
					</div>
					{/* <div className='align-self-center'>
						<p className='mb-0'>
							<span className='badge badge-outline-success'>Available</span>
						</p>
					</div> */}
				</div>
			</div>
		)
	}
}
/**
 * Type of the props used in the component
 */
BackupAndSnapshot.propTypes = {
	snapshotName: PropTypes.string,
	time: PropTypes.string,
	dbInstancePreferredBackupWindow: PropTypes.string,
	dbInstanceCopyTagsToSnapshot: PropTypes.string,
	restoreWindowlatestTime: PropTypes.string,
	db_instance_copy_tags_to_snapshot: PropTypes.string,
}
export default BackupAndSnapshot
