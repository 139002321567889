import React from 'react';
import _ from 'lodash'
import CommonDropdown from '../../../common/CommonDropdown'

const scoreOptions = [
    { value: 'Security', label: 'Security'},
    { value: 'Overall', label: 'Overall'}
];

const conditionOptions = [
    { value: '>', label: '>'},
    { value: '<', label: '<'},
    { value: '=', label: '='},
    { value: 'between', label: 'Between'},
];

const filterArray = [];

class ScoreFilterSection extends React.Component {
    constructor(props) {
        super(props)
        console.log('ScoreFilterSection', props)
        this.state = {
            rowList: [true]
        };
    };

    selectedOptionFunction = (index, label, value) => {
        let dataRow = {}
        dataRow.label = label
        dataRow.index = index
        if(label === 'Condition' && value === 'between') {
            this.setState({ [index]: true })
            //onChange = {(event) => this.setState({ [item.id]: event.target.value })}
        }
        if(label === 'text_1' || label === 'text_2') {
            dataRow.value = value.target.value
        } else {
            dataRow.value = value
        }
        filterArray.forEach((item , key) => {
            if(item.index === index && item.label === label) {
                filterArray.splice(key, 1);
            }
        })

        filterArray.push(dataRow)
    }

    add = () => {
        let rowList = [...this.state.rowList];
        rowList.push(true);
        this.setState({ rowList });
    }

    remove = (i) => {
        let rowList = [...this.state.rowList];
        rowList.splice(i, 1);
        this.setState({ rowList });
    }

    applyFilter = (event) => {
        //console.log(';;;;;;',filterArray)
        let array = filterArray
        let result = 
        _.chain(array)
        .groupBy('index').value()

        return this.props.data(result)
    }
    
    closeFilter = () => {
        return this.props.data(false);
    }

    render() {
        return (
            <div className="card cardDropSection">
                <div className="col-sm-12 m-0 p-0 d-flex mt-2">
                    <div className="col-sm-6 m-0 p-0">
                        <p className="text-white col-sm-12 f14 pb-0 mb-0">Lorem ipsum text industry since 1500s
                        </p>
                    </div>
                    <div className="col-sm-6 text-right">
                        <i 
                            className="fa fa-plus addRow cursorPointer" 
                            onClick={() => this.add()}>
                        </i>
                    </div>
                </div>
                <div className="card-body">
                    {this.state.rowList.map((x, i) => {
                        return (
                        <div className="d-flex mb-2" key={i}>
                            <div className={`mr-3`}>
                                <i class={`fa fa-minus removeRow cursorPointer ${i===0 ? 'disabled' : ''} `} onClick={() => this.remove(i)}></i>
                            </div>
                            <div className="mr-3">
                                <CommonDropdown 
                                    data = {this.selectedOptionFunction.bind(this, i, 'Score')}
                                    hideHeader = {false}
                                    headerName = {'Score'}
                                    dropOptions = {scoreOptions}
                                    displayMenu = {false}
                                    isMultiSelect = {false}
                                    isDefaultDropdown = {false}
                                    styleTop={'top30'}
                                    searchOption={false}
                                    label={''}
                                />
                            </div>
                            <div className="mr-3">
                                <CommonDropdown 
                                    data = {this.selectedOptionFunction.bind(this, i, 'Condition')}
                                    hideHeader = {false}
                                    headerName = {'Condition'}
                                    dropOptions = {conditionOptions}
                                    displayMenu = {false}
                                    isMultiSelect = {false}
                                    isDefaultDropdown = {false}
                                    styleTop={'top30'}
                                    value={this}
                                    searchOption={false}
                                    label={''}
                                />
                            </div>
                            <div>
                                <input className="customTextbox" type="text" id={'textValue_'+i} name={'text_'+i}
                                //onChange={e => {this.setState({ searchText: e.target.value }, () => this.filterOnchange())}}
                                    onChange={(event) => this.selectedOptionFunction(i, 'text_1', event)}
                                    placeholder='eg.1000'
                                />
                            </div>
                            <div className={`${this.state[i] && this.state[i] ? '' : 'displayNone'}`}>
                                <input className="customTextbox" type="text" id={'textValue_'+i} name={'text_'+i}
                                //onChange={e => {this.setState({ searchText: e.target.value }, () => this.filterOnchange())}}
                                    onChange={(event) => this.selectedOptionFunction(i, 'text_2', event)}
                                    placeholder='eg.1000'
                                />
                            </div>
                        </div>
                        )
                    })}
                </div>
                <div className="card-footer">
                    <div className="col-sm-6 m-0 p-0 float-left">
                        &nbsp;
                    </div>
                    <div className="col-sm-6 m-0 p-0 float-right text-right">
                        <span className="dropCancelBtn p-2 mr-2"
                            onClick={ () => this.closeFilter()}
                        >
                            Cancel
                        </span>
                        <span className="dropApplyBtn p-2" 
                            onClick={ () => this.applyFilter()}
                        >
                            Apply
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default ScoreFilterSection;
    