/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file MicroServicesLandingPage.js
 * @author Prakash // on 20/03/2023
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { AppWrapper } from '../../common/AppWrapper'

import MicroServicesLeftSection from './MicroServicesLeftSection'
import MicroServicesRightSection from './MicroServicesRightSection'

class MicroServicesLandingPage extends Component {
    
    constructor(props) {

        super(props)
        this.scrollRef = React.createRef()
        
        this.state = {
            disableMenu: true,
        }
    }

    componentDidMount = () => {
        
    }

    getCostRecommendations = () => {

    }

	render() {
		return (
            <div class={`main-content bg-muted`}>
                <div className={`container-fluid overflow-auto flex-grow-1`}>
                    <div className={`${this.state.fullScreen ? "" : "d-flex"} h-100`}>
                        <div className={`border-right ${this.state.fullScreen ? "w-100" : "w-55"} p-0 mr-2`}>
                            <MicroServicesLeftSection 
                                fullScreenOption = {(fullScreen) => 
                                    this.setState({ fullScreen })
                                }
                            />
                        </div>
                        <div className={` ${this.state.fullScreen ? "w-100" : "w-45"}`}>
                            <MicroServicesRightSection/>
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
MicroServicesLandingPage.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log("-",state)
	return {}
}

export default AppWrapper(MicroServicesLandingPage, mapStateToProps, {})