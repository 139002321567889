/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file BudgetLandingPage.js
 * @author Prakash // on 17/05/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 **************************************************/
import React, { Component, useRef } from 'react'
import PropTypes from 'prop-types'
import { Spinner, UncontrolledTooltip, UncontrolledPopover, PopoverBody } from 'reactstrap'
import _ from 'lodash'

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

import { AppWrapper } from '../../common/AppWrapper'
import { listAllProviders, listAllAccounts, listAllRegions, getAllTagsKeys, getAllTagsValues } from '../../../actions/commonAction'
import { getBudgetDetails } from '../../../actions/cost/CostAction'

import { getAccountNameFromId, getRegionName, momentDateGivenFormat } from '../../../utils/utility'

import ApexListLineChart from '../../common/charts/ApexListLineChart'
import ViewSidePanel from './ViewSidePanel'
import CreateBudgetPanel from './CreateBudgetPanel'

const budget =  {
	"dates": [
		{"start_time": "2023-01-01 00:00:00","end_time": "2023-03-31 00:00:00"},
		{"start_time": "2023-04-01 00:00:00","end_time": "2023-06-30 00:00:00"},
		{"start_time": "2023-07-01 00:00:00","end_time": "2023-09-30 00:00:00"},
		{"start_time": "2023-10-01 00:00:00","end_time": "2023-12-31 00:00:00"},
		{"start_time": "2023-01-01 00:00:00","end_time": "2023-03-31 00:00:00"},
	],
	"results":[{
		"provider": "aws",
		"account_id": "365682566275",
		"region": "us-east-1",
		"tags": [],
		"type": "percentage",
		"period": "Quarterly",
		"current_spend": 10,
		"budget": 100,
		"cost": [
			{amount: 20, percentage: "18%"},
			{amount: 30, percentage: "20%"},
			{amount: 40, percentage: "22%"},
			{amount: 50, percentage: "24%"},
			{amount: 30, percentage: "20%"},
		],
		"total": 100
	},
	{
		"provider": "aws",
		"account_id": "",
		"region": "us-east-2",
		"tags": [],
		"type": "percentage",
		"period": "Quarterly",
		"current_spend": 10,
		"budget": 100,
		"cost": [
			{amount: 20, percentage: "18%"},
			{amount: 30, percentage: "20%"},
			{amount: 40, percentage: "22%"},
			{amount: 50, percentage: "24%"},
			{amount: 30, percentage: "20%"},
		],
		"total": 100
	}]
}


class BudgetLandingPage extends Component {
	tagsRef = React.createRef()
	sliderHeaderRef = React.createRef()
	sliderBodyRef = React.createRef()
	getBlockWidthRef = React.createRef()
	
	constructor(props) {
		super(props)

		this.state = {
			showLoading: true,
			minMaxHeight: "770px",
			forecastOptions: [
				{label: "Monthly", value: "monthly"},
				{label: "Quarterly", value: "quarterly"},
				{label: "Half Yearly", value: "half-yearly"},
				{label: "Annual", value: "yearly"},
			],
			selectedPeriod: "quarterly",
			budgetResponse: budget,
			totalItemShowing: 5,
			itemMovedLeft: 0,
			itemMovedRight: 0
		}
	}

	componentDidMount = () => {
		
		this.getWindowSize()

		window.addEventListener('resize', () => {
			this.getWindowSize()
		})
		this.refLoop()

		if(this.props.providers && this.props.providers.length) {
			this.setState({ providers: this.props.providers, selectedProvider: this.props.providers && this.props.providers.length ? this.props.providers[0].provider_name : "" },
				() => this.getFilters()
			)
		} else {
			this.props.listAllProviders((promise, response) => {
				if (promise) {
					this.setState({ providers: response, selectedProvider: response && response.length ? response[0].provider_name : "" },
						() => this.getFilters()
					)
				} else {
					this.getFilters()
				}
			})
		}
	}

	refLoop = () => {
		// const refArr = useRef([])
		// refArr.current = this.state.budgetResponse.map((item, index) => {
		// 	return refArr.current[index] || React.createRef();
		// })
	}

	getWindowSize = () =>  {
		const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 180
		let tableHeader = 90
		let outerHeight = (window.outerHeight - browserBorder - headerHeight - tableHeader) + "px"

		this.setState({ outerHeight, minMaxHeight: outerHeight })
	}

	getFilters = () => {
		this.getAllTagsKeys()
		this.onSearch()

		let params = {}
		if(this.state.selectedProvider) {
			params.provider = this.state.selectedProvider.toLocaleLowerCase()
		}
		if(this.props.accounts && this.props.accounts.length) {
			this.setState({ accounts: this.props.accounts })
		} else {
			this.props.listAllAccounts(params, (promise, response) => {
				if (promise) {
					this.setState({ accounts: response })
				}
			})
		}

		if(this.props.regions && this.props.regions.length) {
			this.setState({ regions: this.props.regions })
		} else {
			this.props.listAllRegions(params, (promise, response) => {
				if (promise) {
					this.setState({ regions: response && response[0] && response[0].provider_regions ? response[0].provider_regions : [] })
				} else {
					this.setState({ regions: [] })
				}
			})
		}
	}
	
	getAllTagsKeys = () => {		
		if(!this.props.tagKeys || !this.props.tagKeys.length) {
			let params = {}
			this.props.getAllTagsKeys(params, (promise, response) => {
				this.setState({ showTagKeyLoading: !this.state.showTagKeyLoading })	
			})
		} else {
			this.setState({ showTagKeyLoading: !this.state.showTagKeyLoading })
		}
	}

	getAllTagsValues = () => {
		let params = {}
		params.tags = [{ "key": this.state.tag_key }]
		this.props.getAllTagsValues(params, (promise, response) => {
			if(promise) {
				this.setState({ tagValues: response },
					() => {
						if(this.state.tagsSelected && this.state.tagsSelected.filter(e => e.key === this.state.tag_key).length) {
							let value = this.state.tagsSelected.filter(e => e.key === this.state.tag_key).map(e => e.value)
							this.handleMultiSelectChange("tag_value", value, this.state.tagValues)
						}
					}	
				)
			}
		})
	}

	addNewTag = () => {
		let tagsSelected = this.state.tagsSelected ? this.state.tagsSelected : []

		if(this.state.tag_key && this.state.tag_key !== "") {
			let filteredTags = tagsSelected.filter(e => e.key !== this.state.tag_key)
			this.state.tag_value.forEach(row => {
				let dataRow = {
					key: this.state.tag_key,
					value: row,
				}
				filteredTags.push(dataRow)
			})

			this.setState({ tagsSelected: filteredTags, tag_key: "",  tag_value: "", tagValues: [], changesInFilter: true })
		}
	}

	removeTagSection = (tag) => {
		let filteredResult = this.state.tagsSelected.filter(e => e !== tag)
		this.setState({ tagsSelected: filteredResult })
	}
	
	onReset = () => {
		this.setState({
			selectedAccount: [],
			selectedRegion: [],
			selectedTags: [],
			tagsSelected: [],
			tag_key: "",
			tag_value: "",
			selectedPeriod: "Quarterly",
			changesInFilter: false		
		})
	}

	handleMultiSelectChange = (field, arrayValue, state) => {
		let value = arrayValue.map(item => item.value ? item.value : item)
		let selectedValue = []
		let prevState = this.state[field] ? this.state[field] : []
		if(value.includes("All")) {
			if(!prevState.includes("All")) {
				if(state.filter(e => e.value).length) {
					selectedValue = state.map(e => e.value)
				} else {
					selectedValue = state
				}
			} else {
				const index = value.indexOf("All");
				if (index > -1) {
					value.splice(index, 1);
				}
				selectedValue = value
			}
		} else if(!prevState.includes("All")) {
			selectedValue = value
		}

		if(selectedValue.length && !selectedValue.includes("All") && (state.includes("All") || state.filter(e => e.value === "All").length)) {
			if(selectedValue.length === state.length -1) {
				selectedValue.push("All")
			}
		}

		
		this.setState({ [field]: selectedValue, changesInFilter: true })
	}

	getMultiSelectedCount = (type, array) => {
		return array.length && array.includes("All") ? "All" :  array.length ? array.length +" Selected" : "All"
	}

	//on change filter items

	handleClickOutside(event) {

		if(this.state.showTagFilter) {
			if (this.tagsRef && !this.tagsRef.current.contains(event.target)) {
				this.setState({ showTagFilter: false })
			}
		}
	}

	onSearch = () => {
		let params = {}
		if(this.state.selectedProvider) {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		params.period = this.state.selectedPeriod
		if(this.state.selectedAccount && this.state.selectedAccount.length) {
			params.account_id = this.state.selectedAccount
		}
		if(this.state.selectedRegion && this.state.selectedRegion.length) {
			params.region = this.state.selectedRegion
		}
		if(this.state.selectedTags) {
			params.tags = this.state.selectedTags
		}

		this.props.getBudgetDetails(params, (promise, response) => {
			if(promise) {
				this.setState({ budgetDetails: response, showLoading: false },
					() => this.formBudgetListGraphDetails()	
				)
			} else {
				this.setState({ showLoading: false })
			}
		})
	}

	formBudgetListGraphDetails = () => {
		let costTrendDates = this.state.budgetDetails && this.state.budgetDetails.cost_trend_dates && this.state.budgetDetails.cost_trend_dates.length ? this.state.budgetDetails.cost_trend_dates : []
		let budgetDate = this.state.budgetDetails && this.state.budgetDetails.dates && this.state.budgetDetails.dates.length ? this.state.budgetDetails.dates : []
		let budgetResults = this.state.budgetDetails && this.state.budgetDetails.results && this.state.budgetDetails.results.length ? this.state.budgetDetails.results : []

		budgetResults.forEach((row, index) => {
			let graphData = {
				labels: costTrendDates,
				data: row.cost_trend
			}
			this.setState({ ["graphData_"+index]: {} },
				() => this.setState({ ["graphData_"+index]: graphData })
			)
		})
	}

	slideRight = () => {
		let width = this.getBlockWidthRef.current.childNodes[0] && this.getBlockWidthRef.current.childNodes[0].getBoundingClientRect().width
		if((this.state.totalItemShowing + this.state.itemMovedRight) <= (this.state.budgetDetails && this.state.budgetDetails.dates && this.state.budgetDetails.dates.length)) {
			this.sliderHeaderRef.current.scrollLeft += width
			// console.log("refArr", this.refArr[0])
			this.sliderBodyRef.current.scrollLeft += width
			this.setState({ itemMovedRight: this.state.itemMovedRight + 1 })
		}
	}

	slideLeft = () => {
		if(this.state.itemMovedRight >= 1) {
			let width = this.getBlockWidthRef.current.childNodes[0] && this.getBlockWidthRef.current.childNodes[0].getBoundingClientRect().width
			this.sliderHeaderRef.current.scrollLeft -= width
			this.sliderBodyRef.current.scrollLeft += width

			this.setState({ itemMovedRight: this.state.itemMovedRight - 1 })
		}
	}

	onClickDetails = (details) => {
		this.setState({ showViewSidePanel: true })
	}

	/**
	 * Renders cost comparison Landing page
	*/
	render() {
		return (
			<div className='container-fluid overflow-auto flex-grow-1 bg-muted' onClick={ (event) => { this.handleClickOutside(event) } }>
				<div className={`loaderOverlay ${this.state.showLoading ? "" : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
				{this.state.showViewSidePanel ?
					<ViewSidePanel
						selectedProvider={this.state.selectedProvider}
						selectedPeriod={this.state.selectedPeriod}
						selectedDetails={this.state.selectedDetails}
						closeSidePanel={() => this.setState({ showViewSidePanel: false })}
					/>
				: null}
				{this.state.showManageBudgetPanel ?
					<CreateBudgetPanel
					    selectedProvider={this.state.selectedProvider}
						selectedData={this.state.selectedData ? this.state.selectedData : {}}
						closeSidePanel={() => this.setState({ showManageBudgetPanel: false, selectedData: {} })}
					/>
				: null}
				<div className='row h-100'>
					<div className='col-sm-12 px-0'>
						<div className='title-section' style={{padding: "1rem 1rem 1rem 1rem"}} >
							<div className='row'>
								<div className='col-lg-3 d-flex align-self-center'>
									<div>
										<div className='d-flex'>
											<h6 className='text-white m-0'>Budget Forecast</h6>
										</div>
										<p className='text-white m-0'>
											Budget forecast for selected period
										</p>
									</div>
								</div>
								<div className={`col-lg-9`}>
									<div className="containerFilterSection">
										<div className="d-flex justify-content-end mt-2">
											<div className="mr-4">
												<div className="d-flex justify-content-between">
													<label className="align-self-center m-0">Provider </label>
												</div>
												<Select
													placeholder={'Select'}
													isSearchable={false}
													// menuIsOpen={true}
													className={`selectOptionsm f13 p-0`}
													components={{
														IndicatorSeparator: () => null
													}}
													value={({
														value: this.state.selectedProvider,
														label: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : <span className="placeholder">Select</span>
													})}
													options={this.state.providers && this.state.providers.map(row => ({
														value: row.provider_name,
														label: row.provider_name,
													}))}
													onChange={event => this.setState({ selectedProvider: event.value, changesInFilter: true }, 
														() => {
															this.getFilters("source")
														}
													)}
												/>
											</div>

											<div className="mr-4">
												<div className="d-flex justify-content-between">
													<label className="align-self-center pb-5p">Account </label>
													{this.state.hasError && (this.state.selectedAccount || this.stae.selectedAccount === "") ? (
														<small className="text-issues">required</small>
													) : null}
												</div>
												<div className={`multiSelectOptionSm align-self-end`}>
													<ReactMultiSelectCheckboxes
														placeholderButtonLabel="All"
														getDropdownButtonLabel={() => this.getMultiSelectedCount("selectedAccount", this.state.selectedAccount ? this.state.selectedAccount : [])}
														options={this.state.accounts && this.state.accounts.map(row => ({
															value: row.account_id,
															label: row.account_name,
														}))}
														onChange={arr => { this.handleMultiSelectChange("selectedAccount", arr ? arr : [], this.state.regions)}}
														value={this.state.selectedAccount && this.state.selectedAccount.map(row => ({
															value: row
														}))}
													/>
												</div>
											</div>

											<div className="mr-4">
												<div className="d-flex justify-content-between">
													<label className="align-self-center pb-5p">Region </label>
													{this.state.hasError && (this.state.selectedRegion || this.stae.selectedRegion === "") ? (
														<small className="text-issues">required</small>
													) : null}
												</div>
												<div className={`multiSelectOptionSm align-self-end`}>
													<ReactMultiSelectCheckboxes
														placeholderButtonLabel="All"
														getDropdownButtonLabel={() => this.getMultiSelectedCount("selectedRegion", this.state.selectedRegion ? this.state.selectedRegion : [])}
														options={this.state.regions && this.state.regions.map(row => ({
															value: row.region,
															label: row.name,
														}))}
														onChange={arr => { this.handleMultiSelectChange("selectedRegion", arr ? arr : [], this.state.regions)}}
														value={this.state.selectedRegion && this.state.selectedRegion.map(row => ({
															value: row
														}))}
													/>
												</div>
											</div>		

											<div className="mr-4" ref={this.tagsRef}>
												<div className="" onClick={() => this.setState({ showTagFilter: true })}>
													<label className="mb-0">Tags</label>
													<p className='mb-0 STDborderBottom mt-3' style={{ minWidth: "150px" }}>
														{!this.state.selectedTags || (this.state.selectedTags && !this.state.selectedTags.length) ?
															<span>All</span>
														: this.state.selectedTags && this.state.selectedTags.length ?
															<span>{this.state.selectedTags.length} selected</span>
														: null}
													</p>
												</div>
												{this.state.showTagFilter ?
													<div className="costPopupTagSection border mt-1" style={{ right: "20%" }}>
														<div className="p-3 bg-dark3">
															<div className={`w-100 mt-3`}>
																<p className="mb-2 w-100 text-white">Tags</p>
																<div className={`d-flex`}>
																	<label className="mb-1 w-40">Key</label>
																	<label className="mb-1 w-40 ml-3">Value</label>
																</div>
																<div className={`w-100`}>
																	<div className={`d-flex mt-3`}>
																		<Select
																			isSearchable={true}
																			components={{
																				IndicatorSeparator: () => null
																			}}
																			className={`selectOption mr-2 mt-2`}
																			value={({
																				value: this.state.tag_key,
																				label: this.state.tag_key && this.state.tag_key !== "" ? this.state.tag_key : <span className="placeholder">Key</span>
																			})}														
																			
																			options={this.props.tagKeys && this.props.tagKeys.map(item => ({
																				value: item,
																				label: item,	
																			}))}
																			onChange={event => this.setState({
																				tag_key: event.value,
																				tagValues: [],
																			},
																				() => this.getAllTagsValues()
																			)}
																		/>
																		{this.state.tag_key && this.state.tag_key !== "" && !this.state.tagValues.length ?
																			<div className='ml-3'>
																				<Spinner className='text-center align-self-center' color='white' size='sm' />
																			</div>
																		:
																			<React.Fragment>
																				<div className={`multiSelectOption align-self-center mr-2 z998`} style={{marginTop: "1.3rem"}}>
																					<ReactMultiSelectCheckboxes
																						placeholderButtonLabel="Select"
																						getDropdownButtonLabel={() => this.getMultiSelectedCount("tag_value", this.state.tag_value ? this.state.tag_value : [])}
																						options={this.state.tagValues && this.state.tagValues.map(row => ({
																							value: row,
																							label: row,
																						}))}
																						onChange={arr => { this.handleMultiSelectChange("tag_value", arr ? arr : [], this.state.tagValues) }}
																						value={this.state.tag_value && this.state.tag_value.map(acc => ({
																							value: acc
																						}))}
																					/>
																				</div>
																				<span className={`far fa-plus cursorPointer f18 align-self-center ml-4`} onClick={() => this.addNewTag("tags")}></span>
																			</React.Fragment>
																		}
																	</div>
																</div>
															</div>
															{this.state.tagsSelected && this.state.tagsSelected.length ? 
																this.state.tagsSelected.map((tag, index) => {
																	return(
																		<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">{tag.key +" : "+tag.value}
																			<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeTagSection(tag) } ></i>
																		</span>
																	)
																})
															: null}
															<div className="d-flex mt-3">
																<span className={`text-info mr-2 align-self-center cursorPointer`} onClick={() => this.setState({ tagsSelected: [], selectedTags: [] })}>Clear</span>
																<button className={`btn btn-sm btn-light cursorPointer`} onClick={() => this.setState({ showTagFilter: false, selectedTags: this.state.tagsSelected })}>Select</button>
															</div>
														</div>
													</div>
												: null}
											</div>
											
											<div className="mr-4">
												<div className="d-flex justify-content-between">
													<label className="align-self-center m-0">Period</label>
												</div>
												<Select
													placeholder={'Select'}
													isSearchable={false}
													// menuIsOpen={true}
													className={`selectOptionsm f13 p-0`}
													components={{
														IndicatorSeparator: () => null
													}}
													value={({
														value: this.state.selectedPeriod,
														label: this.state.selectedPeriod ? this.state.forecastOptions.filter(e => e.value === this.state.selectedPeriod)[0].label : <span className="placeholder">Select</span>
													})}
													options={this.state.forecastOptions && this.state.forecastOptions.map(row => ({
														value: row.value,
														label: row.label,
													}))}
													onChange={event => this.setState({ selectedPeriod: event.value })}
												/>
											</div>
											
											<div className={`align-self-center`}>
												<div className="btn-group" role="group" aria-label="Basic example">
													<button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-right px-2 py-1`} onClick={() => this.setState({ showLoading: true }, () => this.onSearch() )}>Search</button>
													<button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-left px-2 py-1`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="d-flex justify-content-between m-2">
							<div className=''>
								<p className='font-weight-bold m-0 f15'>Budget Forecast</p>
								<p className="mb-0 small text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
							</div>
							
							<div className="d-flex">								
								<div className="mr-4 d-flex">
									<button type="button"  className='mr-w btn btn-primary align-self-center cursorPointer' onClick={() => this.setState({ showManageBudgetPanel: true })}>
										Manage Budgets
									</button>
								</div>
								<div className="align-self-center">
									<div className="d-flex justify-content-end">
										{/* <small className="align-self-center">
											<span className='mr-2'>Showing</span>
											{this.state.totalAccArray.length > 4 ?  
												'  '+((this.state.totalItemShowing+this.state.itemMovedRight) > this.state.totalAccArray.length ? this.state.itemMovedRight - 1 : this.state.itemMovedRight) +' - '+ ((this.state.totalItemShowing+this.state.itemMovedRight) > this.state.totalAccArray.length ? this.state.totalAccArray.length : (this.state.totalItemShowing+this.state.itemMovedRight)) +' ('+this.state.totalAccArray.length+') '
											: 
												this.state.totalAccArray.length ? 
												' '+this.state.totalAccArray.length +' ('+this.state.totalAccArray.length+') '
												: ' 0'
											} 
										</small> */}
										<h5 className={`mb-0 mx-2 ${this.state.itemMovedRight > 0 ? 'text-white bg-info rounded-50' : 'bg-muted disabled rounded-50'} `}><span className="fad fa-arrow-circle-left cursorPointer" onClick={() => this.slideLeft()}></span></h5>
										<h5 className={`mb-0 ${this.state.budgetDetails && this.state.budgetDetails.dates && this.state.budgetDetails.dates.length >= (this.state.totalItemShowing + this.state.itemMovedRight) ? 'text-white bg-info rounded-50' : 'bg-muted disabled rounded-50'}`}><span className="fad fa-arrow-circle-right cursorPointer" onClick={() => this.slideRight()}></span></h5>
									</div>
								</div>
							</div>
						</div>
						<div className='mt-1 mx-2'>
							<div className='bg-dark3 p-3'>
								<div className='d-flex bg-muted'>
									<div className='w-15 py-2 justify-content-start pl-3 d-flex align-self-center text-white'>Source</div>
									<div className='w-8 py-2 justify-content-start d-flex align-self-center text-white'>Type</div>
									{/* <div className='w-8 py-2 justify-content-start d-flex align-self-center text-white'>Period</div> */}
									{/* <div className='w-8 py-2 justify-content-start d-flex align-self-center text-white'>Current Spend</div> */}
									<div className='w-16 py-2 align-self-center text-white'>
										<div class="mb-1 f18 d-flex justify-content-start">Spend</div>
										<div className='d-flex justify-content-start'>Total / Current</div>
									</div>
									<div className='w-8 py-2 justify-content-start d-flex align-self-center text-white'>Budget</div>
									<div className='w-10 py-2 justify-content-start d-flex align-self-center text-white'>Graph</div>
									<div className='w-53 overflow-x-atuo overflow-hidden' ref={this.sliderHeaderRef} >
										<div className='d-flex pt-1' ref={this.getBlockWidthRef}>
											{this.state.budgetDetails && this.state.budgetDetails.dates ?
												this.state.budgetDetails.dates.map(rowHeader => {
													return(
													<div className='py-3 justify-content-start d-flex align-self-center text-white' style={{minWidth:"20%"}}>
														<span>
															{this.state.budgetDetails && this.state.budgetDetails.results && this.state.budgetDetails.results.length && this.state.budgetDetails.results[0].period === "monthly" ? 
																momentDateGivenFormat(rowHeader.start_time, "DD MMM")
															:
																momentDateGivenFormat(rowHeader.start_time, "MMM")
															}
														</span>
														{rowHeader.end_time ?
															<span>- {momentDateGivenFormat(rowHeader.end_time, "MMM")}</span>
														:null}
													</div>
													)
												})
											: null}
											<div className='py-3 justify-content-start d-flex align-self-center text-white' style={{minWidth:"20%"}}>Total</div>
										</div>
									</div>
								</div>
								<div className='overflowYAuto' style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
									{this.state.budgetDetails && this.state.budgetDetails.results ?
										this.state.budgetDetails.results.map((row, index) => {
											return(
											<div className='d-flex bg-dark border-bottom-gray7'>
												<div className='w-15 py-3 pl-3 justify-content-start d-flex align-self-center border-right-gray7'>
													{/* <i className="fas fa-pencil text-primary-color cursorPointer mt-1 mr-3 f14" onClick={() => this.setState({ selectedData: row }, () => this.setState({ showManageBudgetPanel: true }))}></i> */}
													<p className="d-flex flex-wrap m-0 justify-content-start text-white cursorPointer" onClick={() => this.setState({ selectedDetails: row }, this.onClickDetails(row))} id={"headerName_"+index}>
														{row.account_id ? 
															<span>{getAccountNameFromId(row.account_id, this.state.accounts)}</span>
														: null}
														{row.region && row.region.length ?
															<span>&nbsp;:&nbsp;{getRegionName(row.region[0], this.state.regions)}</span>
														: null}
														{/* {row.tags && row.tags.length ?
															<span>{row.tags[0].key +" "+row.tags[0].value}</span> 
														: null} */}
														<UncontrolledPopover className="longData" trigger="hover" placement="top" target={`headerName_`+index}>
															{/* <PopoverHeader>
																<label className="mb-1 text-white font-weight-bold"> {item.case_title ? item.case_title : ''}</label>
															</PopoverHeader> */}
															<PopoverBody>
															<div className=''>
																{row.account_id ? 
																	<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																		<span className='text-primary-color'>Account:</span> {getAccountNameFromId(row.account_id, this.state.accounts)}
																	</span>
																: null}
																{row.region ?
																	<div className='d-flex flex-wrap'>
																		{row.region.map((reg, index) => {
																			return(
																			<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																				<span className='text-primary-color'>Region:</span> {getRegionName(reg, this.state.region)}
																			</span>
																			)
																		})}
																	</div>
																: null}
																{row.tags && row.tags.length ? 
																	<div className='d-flex flex-wrap'>
																		{row.tags.map((tag, index) => {
																			return(
																				<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2"><span className='text-primary-color'>Tag</span> {tag.key +" : "+tag.value}
																				</span>
																			)
																		})}
																	</div>
																: null}
															</div>
															</PopoverBody>
														</UncontrolledPopover>
														{/* <UncontrolledTooltip className="UncontrolledTooltipLongData" placement='top' target={"headerName_"+index}>
															<div className=''>
																{row.account_id ? 
																	<div>Account: {getAccountNameFromId(row.account_id, this.state.accounts)}</div>
																: null}
																{row.region ? 
																	<div>Region: {getRegionName(row.region, this.state.region)}</div>
																: null}
																{row.tags && row.tags.length ? 
																	<div className='d-flex flex-wrap'>
																		{row.tags.map((tag, index) => {
																			return(
																				<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">{tag.key +" : "+tag.value}
																				</span>
																			)
																		})}
																	</div>
																: null}
															</div>
														</UncontrolledTooltip>  */}
													</p>
												</div>
												<div className='w-8 py-3 justify-content-start d-flex align-self-center border-right-gray7 '>
													{row.type ? row.type : <span>&nbsp;</span>}
												</div>
												{/* <div className='w-8 py-3 justify-content-start d-flex align-self-center border-right-gray7 '>{row.period}</div> */}
												{/* <div className='w-8 py-3 justify-content-start d-flex align-self-center border-right-gray7 '>{row.current_spend}</div> */}
												<div className='w-16 py-3 justify-content-start d-flex align-self-center border-right-gray7 '>{row.total_spend+" / "+row.current_spend}</div>
												<div className='w-8 py-3 justify-content-start d-flex align-self-center border-right-gray7 '>{row.budget}</div>
												<div className='w-10 py-3 justify-content-start d-flex align-self-center border-right-gray7 '>
													{this.state["graphData_"+index] && Object.entries(this.state["graphData_"+index]).length ? 
														<ApexListLineChart
															graphData={this.state["graphData_"+index]}
															height={20}
															width={"90%"}
														/>
													: null}
												</div>
												<div className='w-53 overflow-x-atuo overflow-hidden' ref={this.sliderBodyRef} >
													<div className='d-flex'>
														{row.cost ?
															row.cost.map((rowCost, rCIndex) => {
																return(
																<div className='py-3 justify-content-start d-flex align-self-center border-right-gray7 ' style={{minWidth:"20%"}}>
																	<span>{rowCost}</span>
																	{row.percentage[rCIndex] ?
																		<span className={`ml-1 ${row.percentage[rCIndex] < 0 ? "text-success" : "text-danger"}`}>({row.percentage[rCIndex]}%)</span>
																	: null}
																</div>
																)
															})
														: null}
														<div className='py-3 justify-content-start d-flex align-self-center ' style={{minWidth:"20%"}}>{row.total}</div>
													</div>
												</div>
											</div>
											)
										})
									: null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
BudgetLandingPage.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log("state.cost", state.cost)
	return {
		providers: state.filters.providers,
		regions: state.filters.regions && state.filters.regions[0] && state.filters.regions[0].provider_regions ? state.filters.regions[0].provider_regions :[],
		tagKeys: state.filters.tagKeys,
	}
}

export default AppWrapper(BudgetLandingPage, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
	getAllTagsKeys,
	getAllTagsValues,
	getBudgetDetails,
})