/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ExpandedMetricsTab.js
 * @author Prakash // on 09/05/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'

import { Spinner } from 'reactstrap'
import _ from 'lodash'

import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'
import { getMonitoringMetricsByTrend } from '../../../actions/aiops/MetricsAction'
import { getSreDetails } from '../../../actions/aiops/DiagnosticsAction'

import { momentDateGivenFormat, momentConvertionUtcToLocalTime, convertBytes, convertSeconds, countFormater, convertBits, subHours, addHours, momentConvertionLocalToUtcTime, currentLocaltime } from '../../../utils/utility'

import Chart from 'react-apexcharts'

class ExpandedMetricsTab extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            showAnnotation: true,
            coreMetricsListLoader: true,
            sreMetricLoader: true,
        }
    }

	componentDidMount = () => {
        if(this.props.observabilityAssetPageFilter && Object.entries(this.props.observabilityAssetPageFilter).length) {
            this.setState({ 
                start_time: this.props.observabilityAssetPageFilter.start_time,
                end_time: this.props.observabilityAssetPageFilter.end_time,
                brushStartTime: this.props.observabilityAssetPageFilter.brushStartTime ? this.props.observabilityAssetPageFilter.brushStartTime : this.props.observabilityAssetPageFilter.start_time,
                brushEndTime: this.props.observabilityAssetPageFilter.brushEndTime ? this.props.observabilityAssetPageFilter.brushEndTime : this.props.observabilityAssetPageFilter.end_time,
                selectedAsset: this.props.observabilityAssetPageFilter.selectedAsset ? this.props.observabilityAssetPageFilter.selectedAsset : {},
                coreMetricsListLoader: true,
                sreMetricLoader: true,
                selectedMetricsType: "All Metrics",
                graphData: {}
            },
                () => {
                    this.getMetricDetails()
                    this.getSreDetails()
                }
            )
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.observabilityAssetPageFilter && Object.entries(this.props.observabilityAssetPageFilter).length) {
            if(!_.isEqual(prevProps.observabilityAssetPageFilter, this.props.observabilityAssetPageFilter)) {
                this.setState({ 
                    start_time: this.props.observabilityAssetPageFilter.start_time,
                    end_time: this.props.observabilityAssetPageFilter.end_time,
                    brushStartTime: this.props.observabilityAssetPageFilter.brushStartTime ? this.props.observabilityAssetPageFilter.brushStartTime : this.props.observabilityAssetPageFilter.start_time,
                    brushEndTime: this.props.observabilityAssetPageFilter.brushEndTime ? this.props.observabilityAssetPageFilter.brushEndTime : this.props.observabilityAssetPageFilter.end_time,
                    selectedAsset: this.props.observabilityAssetPageFilter.selectedAsset ? this.props.observabilityAssetPageFilter.selectedAsset : {},
                    selectedMetricsType: "All Metrics",
                    sreMetricLoader: true,
                    coreMetricsListLoader: true,
                    graphData: {},
                },
                    () => {
                        this.getMetricDetails()
                        this.getSreDetails()
                    }
                )
            }
        }
    }

    getMetricDetails = () => {
        let startTime = momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss')
        let endTime = momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss')
        let assetId = this.state.selectedAsset.asset_id ? this.state.selectedAsset.asset_id : ""
        let assetArn = this.state.selectedAsset.asset_arn ? this.state.selectedAsset.asset_arn : ""
        let assetName = this.state.selectedAsset.asset_name ? this.state.selectedAsset.asset_name : ""

        let assetDetails = []
        if(assetId !== "" || assetArn !== "" || assetName !== "") {
            let params = {}
            params.start_time = startTime
            params.end_time = endTime
            if(assetId !== "") {
                params.asset_id = assetId
            } else if(assetArn !== "") {
                params.asset_arn = assetArn
            } else {
                params.asset_name = assetName
                params.account_id = this.state.selectedAsset.account_id
                params.region = this.state.selectedAsset.region
                if(this.state.selectedAsset.service_name) {
                    params.service_name = this.state.selectedAsset.service_name
                } else if(this.state.selectedAsset.resource_type) {
                    params.resource_type = this.state.selectedAsset.resource_type
                }
            }
            if(this.state.selectedAsset.service_name) {
                params.service_name = this.state.selectedAsset.service_name
            }
                        
            // params.top = 10
            params.anomaly = false
            // params.sre_metric = false
                            
            if(!this.props.observabilityCoreMetricsList.hasOwnProperty(assetId)) {
                this.props.getMonitoringMetricsByTrend(params, (promise, response) => {
                    if(promise) {
                        let orderByResponse = response
                        if(response && response.length) {
                            orderByResponse = _.orderBy(response, ['order'], ['asc'])
                        }
                        this.setState({ 
                            metricsData: orderByResponse, 
                            coreMetricsListLoader: response.length ? true : false,
                            assetDetails
                        },
                            () => {
                                this.metricsListGraph()
                            }
                        )
                        if(response.length) {
                            let obj = this.props.observabilityCoreMetricsList
                            obj[assetId] = orderByResponse
                            this.props.setAiopsPropsDetails('observabilityCoreMetricsList', obj)
                        }
                    } else {
                        this.setState({ coreMetricsListLoader: false })
                    }
                })
            } else {
                this.setState({ 
                    metricsData: this.props.observabilityCoreMetricsList[assetId],
                    coreMetricsListLoader: true,
                },
                    () => {
                        this.metricsListGraph()
                    }
                )
            }
        } else {            
            this.setState({ coreMetricsListLoader: false })
        }

        this.setState({ startTime, endTime })
    }
    
    metricsListGraph = () => {
        let graphDetails = this.state.metricsData ? this.state.metricsData : []
        if(graphDetails && graphDetails.length) {
            graphDetails.forEach(graph => {
                let graphData = graph.data
                let graphLabels = graph.dates
                Object.entries(graphData).forEach(([key, item], i) => {
                    let colors = []
                    let series = []
                    let options = {}

                    let  strokeWidth = []

                    if(item.values && item.values.length) {
                        if(item.values && item.values.length) { //statistic
                            if(item.statistic !== "Maximum") {
                                colors.push('#775BA2')
                            } else {
                                colors.push('#3DAFE8')
                            }
                            strokeWidth.push(2)
                            let dataRow = {}
                            dataRow.name = item.statistic === "Maximum" ? "max" : item.statistic === "Average" ? "avg" : item.statistic === "Sum" ? "sum" : item.statistic
                            dataRow.type = item.statistic !== "Maximum" ? "area" : "line"
                            let plotValue = []
                            graphLabels.map((lab, i) => {
                                let dataArray = []
                                dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                                let val = item.values[i]
                                if(item.unit) {
                                    val = this.getGrapUnitValue(val, item.unit)
                                }
                                dataArray.push(val)
                                plotValue.push(dataArray)
                            })
                            dataRow.data = plotValue
                            series.push(dataRow)
                        }

                        if(item.additional_statistics && item.additional_statistics.length) {
                            if(item.values && item.values.length) {
                                if(item.additional_statistics[0] !== "Maximum") {
                                    colors.push('#775BA2')
                                } else {
                                    colors.push('#3DAFE8')
                                }
                                strokeWidth.push(2)
                                let dataRow = {}
                                dataRow.name = item.additional_statistics[0] === "Maximum" ? "max" : item.additional_statistics[0] === "Average" ? "avg" : item.additional_statistics[0] === "Sum" ? "sum" : item.additional_statistics[0]
                                dataRow.type = item.additional_statistics[0] !== "Maximum" ? "area" : "line"
                                let plotValue = []
                                graphLabels.map((lab, i) => {
                                    let dataArray = []
                                    dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                                    let val = item.values[i]
                                    if(item.unit) {
                                        val = this.getGrapUnitValue(val, item.unit)
                                    }
                                    dataArray.push(val)
                                    plotValue.push(dataArray)
                                })
                                dataRow.data = plotValue
                                series.push(dataRow)
                            }
                        }

                        console.log(key, series)
                        
                        let xaxisAnnotation = []
                        // if(this.state.showAnnotation) {
                        //     let alerts = [this.props.details.event_time]

                        //     if(alerts.length) {
                        //         alerts.forEach((item, z) => {
                        //             let alertRow = {}
                        //             // alertRow.x = new Date(momentConvertionUtcToLocalTime(addMinutes(item, (z*10)), 'YYYY-MM-DD HH:mm:ss')).getTime()
                        //             alertRow.x = new Date(momentConvertionUtcToLocalTime(item, 'YYYY-MM-DD HH:mm:ss')).getTime()
                        //             alertRow.strokeDashArray = 0
                        //             alertRow.borderColor = '#FF6F00'
                        //             alertRow.label = {
                        //                 borderColor: '#FF6F00',
                        //                 style: {
                        //                     // fontSize: '12px',
                        //                     color: '#fff',
                        //                     background: '#FF6F00'
                        //                 },
                        //                 orientation: 'horizontal',
                        //                 text: ''
                        //                 // text: momentConvertionUtcToLocalTime(item, 'DD MMM YY HH:mm'),
                        //             }

                        //             xaxisAnnotation.push(alertRow)
                        //         })
                        //     }
                        // }

                        let annotations = {
                            xaxis: xaxisAnnotation
                        }

                        options = {
                            chart: {
                                // group: 'metrics_sre',
                                // id: 'chart_rate_'+index,
                                // redrawOnParentResize: true,
                                stacked: true,
                                toolbar: {
                                    show: false,
                                },
                                zoom: {
                                    enabled: false
                                },
                                sparkline: {
                                    enabled: false
                                },
                                type: "line",
                                width: '100%',
                                animations: {
                                    enabled: false
                                }
                            },
                            colors: colors,
                            dataLabels: {
                                enabled: false,
                            },
                            stroke: {
                                width: strokeWidth,
                                curve: 'smooth',
                            },
                            legend: {
                                show: false
                            },
                            fill: {
                              type:'solid',
                              opacity: [0.25, 1],
                            },
                            // fill: {
                            //     type: "gradient",
                            //     gradient: {
                            //         // shadeIntensity: 1,
                            //         // opacityFrom: 0.7,
                            //         // opacityTo: 0.9,
                            //         // stops: [0, 90, 100]
                            //         gradientToColors: ['#775BA2'],
                            //         shadeIntensity: 0,
                            //         opacityFrom: .3,
                            //         opacityTo: 1,
                            //         stops: [30, 90]
                            //     }
                            // },
                            tooltip: {
                                // custom: function({series, seriesIndex, dataPointIndex, w}) {
                                //     let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
                                //     dateLable = dateLable.toString()
                                //     dateLable = momentDateGivenFormat(dateLable, 'DD MMM HH:mm')
                                //     let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]
                                //     return '<div class="arrow_box">'+dateLable+' <span style="color:'+ w.globals.colors +'">' + parseFloat(val).toFixed(2) + '</span> </div>'
                                // },
                                custom: function({series, seriesIndex, dataPointIndex, w}) {         
                                    let returnData = ''
                                    
                                    if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                        Object.entries(w.globals.initialSeries).map(([key, value], index) => {
                                            if(!index) {
                                                returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                            }
                                            returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'
    
                                        })
                                    }
    
                                    returnData += '</div>'
                                    return returnData
                                },
                                fixed: {
                                    enabled: false
                                },
                                x: {
                                    show: false,
                                },
                                y: {
                                    show: false
                                },        
                                marker: {
                                    show: false
                                }
                            },
                            xaxis: {
                                type: 'datetime',
                                labels: {
                                    datetimeUTC: false,
                                    style: {
                                        colors: '#60687C'
                                    },
                                    // formatter: function (value) {
                                    //     return momentDateGivenFormat(value, 'HH:mm')
                                    // },
                                },                            
                                axisTicks: {
                                    show: false
                                },
                                tooltip: {
                                    enabled: false
                                },
                            },
                            dataLabels: {
                                enabled: false
                            },
                            yaxis: [
                                {
                                    show: true,
                                    axisTicks: {
                                        show: false,
                                    },
                                    axisBorder: {
                                        show: false,
                                        color: '#60687C'
                                    },
                                    tooltip: {
                                        enabled: false
                                    },
                                    // tickAmount: 2,
                                    min: 0,
                                    // max: yaxisMaxValue, 
                                    tickAmount: 1,
                                    labels: {
                                        offsetX: -10,
                                        // minWidth: 50,
                                        show: true,
                                        style: {
                                            fontSize: '9px',
                                            colors: '#FFFFFF'
                                        },
                                        formatter: (value) => { 
                                            if(Number(value) === value && value % 1 === 0) {
                                                return Math.ceil(value.toFixed(1))
                                            } else {
                                                return Math.ceil(value.toFixed(1))
                                            }
                                            
                                            // if(!value) {
                                            //     return 0
                                            // } else {
                                            //     let val = value.toFixed(2)
                                            //     val = val.split(".")
                                            //     if(val[1] && val[1] > 0) {
                                            //         return value.toFixed(2)
                                            //     } else {
                                            //         return parseInt(val[0])
                                            //     }
                                            // }
                                        },
                                        // offsetX: 0,
                                        // offsetY: 0,
                                    }
                                },
                                {
                                    show: false,
                                }
                            ],
                            grid: {
                                show: false,
                                borderColor: '#2D323E',
                                strokeDashArray: 0,
                                position: 'back',
                                xaxis: {
                                    lines: {
                                        show: true
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#FFF'],
                                        }
                                    },
                                    axisTicks: {
                                        show: false
                                    }
                                },   
                                yaxis: {
                                    lines: {
                                        show: false
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#969AA7'],
                                        }
                                    }
                                },                
                                padding: {
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0
                                },
                            },
                            annotations: annotations,
                        }
                    }
                    this.setState({ [key+"_series_"+graph.asset_id]: series, [key+"_options_"+graph.asset_id]: options, coreMetricsListLoader: false },
                        () => {
                            // console.log([key+"_series_"+graph.asset_id], series)
                            // console.log([key+"_options_"+graph.asset_id], options)
                        }
                    )
                })
                
            })
        }
    }

    getGrapUnitValue = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : val
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytes(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit)
            } else if(unit === 'Count') {
                // val = val > 1 ? parseInt(val) : (!val ? val : parseFloat(val).toFixed(2))
                // val = val.split(".")
                // if(val[1] && val[1] > 0) {
                //     return val.toFixed(2)
                // } else {
                //     return parseInt(val[0])
                // }
                val = val ? Math.ceil(val) : 0
                if(val) {
                    val = countFormater(val)
                }
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {
            // val = val.split(".")
            // if(val[1] && val[1] > 0) {
            //     return val.toFixed(2)
            // } else {
            //     return parseInt(val[0])
            // }
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    getSreDetails = () => {
        let startTime = momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss')
        let endTime = momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss')
        let assetId = this.state.selectedAsset.asset_id ? this.state.selectedAsset.asset_id : ""
        let assetArn = this.state.selectedAsset.asset_arn ? this.state.selectedAsset.asset_arn : ""
        let assetName = this.state.selectedAsset.asset_name ? this.state.selectedAsset.asset_name : ""
        if(assetId !== "" || assetArn !== "" || assetName !== "") {
            let params = {}
            params.start_time = startTime
            params.end_time = endTime
            if(assetId !== "") {
                params.asset_id = assetId
            } else if(assetArn !== "") {
                params.asset_arn = assetArn
            } else {
                params.asset_name = assetName
                params.account_id = this.state.selectedAsset.account_id
                params.region = this.state.selectedAsset.region
                if(this.state.selectedAsset.service_name) {
                    params.service_name = this.state.selectedAsset.service_name
                } else if(this.state.selectedAsset.resource_type) {
                    params.resource_type = this.state.selectedAsset.resource_type
                }
            }
            if(!this.props.observabilitySreMetricsList.hasOwnProperty(assetId)) {
                this.props.getSreDetails(params, (promise, response) => {
                    if(promise) {
                        this.setState({ 
                            sreDetails: response,
                            sreMetricLoader: false
                        },
                            () => {
                                this.formSreLatencyDetailGraph()
                                this.formSreRateDetailGraph()                                
                            }
                        )
                        if(response.length) {
                            let obj = this.props.observabilitySreMetricsList
                            obj[assetId] = response
                            this.props.setAiopsPropsDetails('observabilitySreMetricsList', obj)
                        }
                    } else {
                        this.setState({ sreDetails: [], sreMetricLoader: false })
                    }
                })
            } else {
                this.setState({ 
                    sreDetails: this.props.observabilitySreMetricsList[assetId],
                    sreMetricLoader: true,
                },
                    () => {
                        this.formSreLatencyDetailGraph()
                        this.formSreRateDetailGraph()
                    }
                )
            }
        }
    }

    formSreLatencyDetailGraph = () => {
        let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']
        if(this.state.sreDetails && this.state.sreDetails.length) {
            // let obj = {}
            let filteredSreDetails = this.state.sreDetails
            filteredSreDetails.forEach((res, index) => {
                let graphData = res.data
                let graphLabels = res.dates

                let colorInc = 0
                graphData.latency && Object.entries(graphData.latency).forEach(([key, item], j) => {
                    console.log('item.values', item.values)
                    if(item.values && item.values.length) {

                        let chartType = 'line'
                        let animations = {}
                        let plotOptions = {}
                        let stroke = {
                            width: [3],
                            curve: 'smooth',
                        }
                        if(j % 2 === 0) {
                            chartType = 'bar'
                            animations = {
                                enabled: false
                            }
                            plotOptions = {
                                bar: {
                                    horizontal: false,
                                    barHeight: '100%',
                                    borderRadius: 'flat',
                                    startingShape: 'flat',
                                    endingShape: 'flat',
                                    columnWidth: '60%',
                                    distributed: false,
                                    rangeBarOverlap: false,
                                }
                            }
                            stroke = {
                                width: [2],
                            }
                        }

                        let latencySeries = []
                        let dataRow = {}
                        dataRow.name = key
                        dataRow.type = chartType
                        let plotValue = []
                        let brushValue = []
                        let labelsFrom = graphLabels.length - this.state.graphLabelsFrom

                        graphLabels.forEach((lab, i) => {
                            let dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            let val = item.values[i]
                            if(item.unit) {
                                val = this.getGrapUnitValue(val, item.unit)
                            }
                            dataArray.push(val)
                            plotValue.push(dataArray)

                            dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            dataArray.push(val)
                            brushValue.push(dataArray)
                        })
                        dataRow.data = plotValue
                        latencySeries.push(dataRow)

                        let latencyOptions = {
                            chart: {
                                id: 'chart_latency_details_'+j,
                                type: chartType,
                                toolbar: {
                                    show: false
                                },
                                zoom: {
                                    enabled: false
                                },
                                animations: animations
                            },
                            plotOptions: plotOptions,
                            dataLabels: {
                                enabled: false
                            },
                            stroke: stroke,
                            colors: [colors[colorInc]],
                            xaxis: {
                                type: 'datetime',
                                labels: {
                                    datetimeUTC: false,
                                    style: {
                                        colors: '#60687C'
                                    },
                                    // formatter: function (value) {
                                    //     return momentDateGivenFormat(value, 'HH:mm')
                                    // },
                                },                            
                                axisTicks: {
                                    show: false
                                },
                                tooltip: {
                                    enabled: false
                                },
                            },
                            yaxis: {
                                show: true,
                                type: 'numeric',
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                    color: '#60687C'
                                },
                                tooltip: {
                                    enabled: false
                                },
                                // tickAmount: 2,
                                min: 0,
                                // max: yaxisMaxValue, 
                                tickAmount: 1,
                                labels: {
                                    offsetX: -10,
                                    // minWidth: 50,
                                    show: true,
                                    style: {
                                        fontSize: '9px',
                                        colors: '#60687C'
                                    },
                                    // formatter: (value) => { 
                                    //     return value.toFixed(0)
                                    // },
                                    formatter: (value) => { 
                                        if(Number(value) === value && value % 1 === 0) {
                                            return Math.ceil(value.toFixed(1))
                                        } else {
                                            return Math.ceil(value.toFixed(1))
                                        }
                                    },
                                }
                            },
                            tooltip: {
                                shared: true,
                                intersect: false,
                                y: {
                                    show: false,
                                },
                                
                                x: {
                                    show: false
                                },
                                marker: {
                                    show: false
                                },
                                custom: function({series, seriesIndex, dataPointIndex, w}) { 
                                    let returnData = ''
                                    
                                    if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                        Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                            if(!index) {
                                                returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                            }
                                            returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'
    
                                        })
                                    }
                                    returnData += '</div>'
                                    return returnData
                                }
                            },
                            grid: {
                                show: true,
                                borderColor: '#2D323E',
                                strokeDashArray: 0,
                                position: 'back',
                                xaxis: {
                                    lines: {
                                        show: true
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#FFF'],
                                        }
                                    },
                                    axisTicks: {
                                        show: false
                                    }
                                },   
                                yaxis: {
                                    lines: {
                                        show: false
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#969AA7'],
                                        }
                                    }
                                },                
                                padding: {
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0
                                },
                            },
                            legend: {
                                show: false
                            }
                        }

                        colorInc++;
                        console.log("series", latencySeries)
                        console.log("options", latencyOptions)
                        this.setState({ ['series_latency_'+j]: latencySeries, ['options_latency_'+j]: latencyOptions, sreMetricLoader: false })
                    }
                })
            })
        }
    }

    formSreRateDetailGraph = () => {
        let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']
        if(this.state.sreDetails && this.state.sreDetails.length) {

            let errorRateArray= []
            let filteredSreDetails = this.state.sreDetails.filter(arr => arr.asset_id === this.state.selectedAsset.asset_id)
            filteredSreDetails.forEach((res, index) => {
                let graphData = res.data
                let graphLabels = res.dates

                // let  strokeWidth = []
                let x = 0
                
                graphData.error_rate && Object.entries(graphData.error_rate).forEach(([key, item], j) => {                    
                    let chartType = 'line'
                    let animations = {}
                    let plotOptions = {}
                    let stroke = {
                        width: [3],
                        curve: 'smooth',
                        // dashArray: [0, 8, 5]
                    }
                    if(x % 2 === 0) {
                        chartType = 'bar'
                        animations = {
                            enabled: false
                        }
                        plotOptions = {
                            bar: {
                                horizontal: false,
                                barHeight: '100%',
                                borderRadius: 'flat',
                                startingShape: 'flat',
                                endingShape: 'flat',
                                columnWidth: '60%',
                                distributed: false,
                                rangeBarOverlap: false,
                            }
                        }
                        stroke = {
                            width: [2],
                        }
                    }

                    let error_series = []
                    let errorRow = {}
                    errorRow.name = key
                    errorRow.type = chartType

                    let plotValue = []
                    let labelsFrom = graphLabels.length - this.state.graphLabelsFrom
                    graphLabels.forEach((lab, i) => {
                        let dataArray = []
                        dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                        // dataArray.push(this.getGrapUnitValue(item.values[i], item.unit))
                        dataArray.push(item.values[i])
                        plotValue.push(dataArray)
                    })

                    errorRow.data = plotValue
                    error_series.push(errorRow)

                    errorRateArray.push({key: key, unit: item.unit})

                    let rate_options = {
                        chart: {
                            type: chartType,
                            id: 'chart_rate_details_'+x,
                            toolbar: {
                                show: false
                            },
                            zoom: {
                                enabled: false
                            },
                            animations: animations,
                        },
                        plotOptions: plotOptions,
                        stroke: stroke,
                        dataLabels: {
                            enabled: false
                        },
                        colors: [colors[x]],
                        xaxis: {
                            type: 'datetime',
                            labels: {
                                datetimeUTC: false,
                                style: {
                                    colors: '#60687C'
                                },
                                // formatter: function (value) {
                                //     return momentDateGivenFormat(value, 'HH:mm')
                                // },
                            },                            
                            axisTicks: {
                                show: false
                            },
                            tooltip: {
                                enabled: false
                            },
                        },
                        yaxis: {
                            show: true,
                            axisTicks: {
                                show: false,
                            },
                            axisBorder: {
                                show: false,
                                color: '#60687C'
                            },
                            tooltip: {
                                enabled: false
                            },
                            // tickAmount: 2,
                            min: 0,
                            // max: yaxisMaxValue, 
                            tickAmount: 2,
                            labels: {
                                offsetX: -10,
                                // minWidth: 50,
                                show: true,
                                style: {
                                    fontSize: '9px',
                                    colors: '#60687C'
                                },
                                formatter: (value) => { 
                                    if(Number(value) === value && value % 1 === 0) {
                                        return Math.ceil(value.toFixed(1))
                                    } else {
                                        return Math.ceil(value.toFixed(1))
                                    }
                                },
                            }   
                        },
                        tooltip: {
                            shared: true,
                            intersect: false,
                            y: {
                                show: false,
                            },
                            
                            x: {
                                show: false
                            },
                            marker: {
                                show: false
                            },
                            custom: function({series, seriesIndex, dataPointIndex, w}) {
                                    // let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]
                                    // let labelName = w.globals.initialSeries[seriesIndex].name
                                    // // return '<div className="arrow_box"> '+ capitalizeFirstLetter(labelName) +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' + '  '+ val + '</span> </div>'         
                                    let returnData = ''
                                    
                                    if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                        Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                            if(!index) {
                                                returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                            }
                                            returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'
    
                                        })
                                    }
    
                                    returnData += '</div>'
                                    return returnData
                            }
                        },
                        grid: {
                            show: true,
                            borderColor: '#2D323E',
                            strokeDashArray: 0,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: true
                                },
                                labels: {
                                    style: {
                                        colors: ['#FFF'],
                                    }
                                },
                                axisTicks: {
                                    show: false
                                }
                            },   
                            yaxis: {
                                lines: {
                                    show: false
                                },
                                labels: {
                                    style: {
                                        colors: ['#969AA7'],
                                    }
                                }
                            },                
                            padding: {
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0
                            },
                        },
                        legend: {
                            show: false
                            // horizontalAlign: 'left',
                            // offsetX: 40
                        },
                    }

                    this.setState({ ['series_rate_detail_'+x]: error_series, ['options_rate_detail_'+x]: rate_options, sreMetricLoader: false })
                    x++;
                })
                
                graphData.request_rate && Object.entries(graphData.request_rate).forEach(([key, item], j) => {

                    let chartType = 'line'
                    let animations = {}
                    let plotOptions = {}
                    let stroke = {
                        width: [3],
                        curve: 'smooth',
                        // dashArray: [0, 8, 5]
                    }

                    if(x % 2 === 0) {
                        chartType = 'bar'
                        animations = {
                            enabled: false
                        }
                        plotOptions = {
                            bar: {
                                horizontal: false,
                                barHeight: '100%',
                                borderRadius: 'flat',
                                startingShape: 'flat',
                                endingShape: 'flat',
                                columnWidth: '60%',
                                distributed: false,
                                rangeBarOverlap: false,
                            }
                        }
                        stroke = {
                            width: [2],
                        }
                    }

                    let plotValue = []
                    let brushValue = []

                    let labelsFrom = graphLabels.length - this.state.graphLabelsFrom
                    graphLabels.forEach((lab, i) => {
                        let dataArray = []
                        dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                        dataArray.push(item.values[i])
                        // dataArray.push(this.getGrapUnitValue(item.values[i], item.unit))
                        plotValue.push(dataArray)

                        dataArray = []
                        dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                        dataArray.push(item.values[labelsFrom + i])
                        brushValue.push(dataArray)
                        // dataArray.push(this.getGrapUnitValue(bar1[i], 'Percent'))
                    })

                    let request_series = []
                    let requestRow = {}
                    requestRow.name = key
                    requestRow.type = 'bar'
                    requestRow.data = plotValue
                    request_series.push(requestRow)

                    errorRateArray.push({key: key, unit: item.unit})

                    let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC']

                    let request_options = {
                        chart: {
                            type: chartType,
                            id: 'chart_rate_details_'+x,
                            toolbar: {
                                show: false
                            },
                            zoom: {
                                enabled: false
                            },
                            animations: animations
                        },
                        plotOptions: plotOptions,
                        stroke: stroke,
                        dataLabels: {
                            enabled: false
                        },
                        colors: [colors[x]],
                        xaxis: {
                            type: 'datetime',
                            labels: {
                                datetimeUTC: false,
                                style: {
                                    colors: '#60687C'
                                },
                                // formatter: function (value) {
                                //     return momentDateGivenFormat(value, 'HH:mm')
                                // },
                            },                            
                            axisTicks: {
                                show: false
                            },
                            tooltip: {
                                enabled: false
                            },
                        },
                        yaxis: {
                            show: true,
                            axisTicks: {
                                show: false,
                            },
                            axisBorder: {
                                show: false,
                                color: '#60687C'
                            },
                            tooltip: {
                                enabled: false
                            },
                            // tickAmount: 2,
                            min: 0,
                            // max: yaxisMaxValue, 
                            tickAmount: 2,
                            labels: {
                                offsetX: -10,
                                // minWidth: 50,
                                show: true,
                                style: {
                                    fontSize: '9px',
                                    colors: '#60687C'
                                },
                                formatter: (value) => { 
                                    if(Number(value) === value && value % 1 === 0) {
                                        return Math.ceil(value.toFixed(1))
                                    } else {
                                        return Math.ceil(value.toFixed(1))
                                    }
                                },
                            }
                        },
                        tooltip: {
                            shared: true,
                            intersect: false,
                            y: {
                                show: false,
                            },
                            
                            x: {
                                show: false
                            },
                            marker: {
                                show: false
                            },
                            custom: function({series, seriesIndex, dataPointIndex, w}) {
                                // let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]
                                // let labelName = w.globals.initialSeries[seriesIndex].name
                                // // return '<div className="arrow_box"> '+ capitalizeFirstLetter(labelName) +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' + '  '+ val + '</span> </div>'         
                                let returnData = ''
                                
                                if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                    Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                        if(!index) {
                                            returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                        }
                                        returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'

                                    })
                                }

                                returnData += '</div>'
                                return returnData
                            }
                        },
                        grid: {
                            show: true,
                            borderColor: '#2D323E',
                            strokeDashArray: 0,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: true
                                },
                                labels: {
                                    style: {
                                        colors: ['#FFF'],
                                    }
                                },
                                axisTicks: {
                                    show: false
                                }
                            },   
                            yaxis: {
                                lines: {
                                    show: false
                                },
                                labels: {
                                    style: {
                                        colors: ['#969AA7'],
                                    }
                                }
                            },                
                            padding: {
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0
                            },
                        },
                        legend: {
                            show: false
                            // horizontalAlign: 'left',
                            // offsetX: 40
                        },
                    }

                    this.setState({ ['series_rate_detail_'+x]: request_series, ['options_rate_detail_'+x]: request_options, sreMetricLoader: false })
                    x++;
                })
            })
            this.setState({ errorRateArray })
        }
    }

    render() {
        return (
            <React.Fragment>
            {this.state.coreMetricsListLoader ?
                <div className='d-flex justify-content-center m-4'>
                    <span className="d-flex b-4"><Spinner className='text-center' color='white' size='lg' /> <span className="mt-2 ml-2 text-white">Loading Core Metrics ...</span></span>
                </div>
            :
                <div className="py-2">
                    <label className="mb-2 text-white">Core Metrics</label>
                    <div className="col-sm-12">
                        <div className="row">
                            {this.state.metricsData && this.state.metricsData.length ? 
                                this.state.metricsData.map(item => {
                                    return (
                                        Object.entries(item.data).map(([key, value], i) => {
                                            return (
                                                <div className={`col-sm-6 ${i % 2 ? "pr-0" : "p-0"}`}>
                                                    <div className="p-2 border rounded bg-dark3 mb-2">
                                                        <div className="d-flex">
                                                            <p className="text-white f16 p-1 mb-0">{key}</p>
                                                            <p className="text-primary-color f12 align-self-end p-1 mb-0">
                                                                ({value.statistic} {(value.additional_statistics && value.additional_statistics.length ?  " - "+value.additional_statistics[0] : "")})
                                                            </p>
                                                        </div>
                                                        {this.state[key+"_series_"+item.asset_id] && this.state[key+"_series_"+item.asset_id].length && this.state[key+"_options_"+item.asset_id] ?
                                                            <div className="cursorPointer transparentTooltip mb-n4 mt-n4">
                                                                <Chart options={this.state[key+"_options_"+item.asset_id]} series={this.state[key+"_series_"+item.asset_id]} type="line" height={150}/>
                                                            </div>
                                                        : 
                                                            <div style={{minHeight:"85px"}} className="d-flex align-self-center justify-content-center">
                                                                <div className="align-self-center">
                                                                    No Data
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    )
                                })
                            : 
                                <div className='d-flex justify-content-center m-4'>
                                    <p>There are no Core metrics for the selected Asset.</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }

            {this.state.sreMetricLoader ?                
                <div className='d-flex justify-content-center m-4'>
                    <span className="d-flex mt-4"><Spinner className='text-center' color='white' size='lg' /> <span className="mt-2 ml-2 text-white"> Loading SRE Metrics ...</span></span>
                </div>
            :        
                    <div className="col-sm-12">
                        <label className="my-2 text-white">SRE Metrics</label>
                        <div className="row">
                            {this.state.sreDetails && this.state.sreDetails.length ? 
                                this.state.sreDetails.map((res, index) => {
                                    return(
                                        <React.Fragment>
                                        {res.data && Object.entries(res.data.latency).map(([key, value], j) => {
                                            return(
                                                <div className={`col-sm-6 mb-3 ${j%2 ? "pr-0" : "p-0"}`}>
                                                    <div className={`border rounded bg-dark3 p-2`}>
                                                        <div className="d-flex">
                                                            <p className="text-white f16 p-1 mb-0">{key}</p>
                                                            <p className="text-primary-color f12 align-self-end p-1 mb-0">
                                                                ({value.unit})
                                                            </p>
                                                        </div>
                                                        <div className={`mb-n4 mt-n4 transparentTooltip`}>
                                                            {this.state['options_latency_'+j] && this.state['series_latency_'+j] && this.state['series_latency_'+j].length ?

                                                                <Chart options={this.state['options_latency_'+j]} series={this.state['series_latency_'+j]} type={`${j % 2 === 0 ? 'bar' : 'line'}`} height={150} width={"100%"} />
                                                            :
                                                                <div style={{minHeight:"165px"}} className="d-flex align-self-center justify-content-center">
                                                                    <div className='align-self-center'>
                                                                        No Data
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        
                                        {this.state.errorRateArray && this.state.errorRateArray.length && this.state.errorRateArray.map((item, j) => {
                                            return(
                                                <div className={`col-sm-6 mb-3 ${j%2 ? "pr-0" : "p-0"}`}>
                                                    <div className={`border rounded bg-dark3 p-2`}>
                                                        <div className="d-flex">
                                                            <p className="text-white f16 p-1 mb-0">{item.key}</p>
                                                            <p className="text-primary-color f12 align-self-end p-1 mb-0">
                                                                ({item.unit})
                                                            </p>
                                                        </div>
                                                        <div className={`mb-n4 mt-n4 transparentTooltip`}>
                                                            <Chart options={this.state['options_rate_detail_'+j]} series={this.state['series_rate_detail_'+j]} type={`${j % 2 === 0 ? 'bar' : 'line'}`} height={150} width={"100%"} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        </React.Fragment>
                                    )                    
                                })
                            :
                                <div className='d-flex justify-content-center m-4'>
                                    <p>There are no SRE metrics for the selected Asset.</p>
                                </div>
                            }
                        </div>
                    </div>
                
            }
            </React.Fragment>
        )
    }
}

/**
 * Type of the props used in the component
 */
ExpandedMetricsTab.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log('errorDashboard',state)
    return {
        observabilityAssetPageFilter: state.aiops.aiopsPropsDetails.observabilityAssetPageFilter ? state.aiops.aiopsPropsDetails.observabilityAssetPageFilter : {},
        observabilityCoreMetricsList: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.observabilityCoreMetricsList ? state.aiops.aiopsPropsDetails.observabilityCoreMetricsList : [],
        observabilitySreMetricsList: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.observabilitySreMetricsList ? state.aiops.aiopsPropsDetails.observabilitySreMetricsList : [],
    }
}

export default connect(mapStateToProps, {
    setAiopsPropsDetails,
    getMonitoringMetricsByTrend,
    getSreDetails,
})(withRouter(ExpandedMetricsTab))