/*************************************************
 * Tvastar
 * @exports
 * @file ApexListLineChart.js
 * @author Prakash // on 18/05/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { capitalizeTheFirstLetterOfEachWord, momentConvertionUtcToLocalTime, unitTimeConvertionDetail, countFormater } from '../../../utils/utility'
import Chart from 'react-apexcharts'


class ApexListLineChart extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    
    componentDidMount = () => {
        let graphData = this.props.graphData;
        if(graphData === 'mockData') {
            graphData = {}
            graphData.data = [45,67,34,71,41,93,64,77,36,34,44,10,1,3,91,45,32,3,37,21,65,45,38,59,16,3,66,85,30,62,49]
            graphData.labels = ["2021-05-12 00:00:00", "2021-05-13 00:00:00", "2021-05-14 00:00:00", "2021-05-15 00:00:00", "2021-05-16 00:00:00", "2021-05-17 00:00:00", "2021-05-18 00:00:00", "2021-05-19 00:00:00", "2021-05-20 00:00:00", "2021-05-21 00:00:00", "2021-05-22 00:00:00", "2021-05-23 00:00:00", "2021-05-24 00:00:00", "2021-05-25 00:00:00", "2021-05-26 00:00:00", "2021-05-27 00:00:00", "2021-05-28 00:00:00", "2021-05-29 00:00:00", "2021-05-30 00:00:00", "2021-05-31 00:00:00", "2021-06-01 00:00:00", "2021-06-02 00:00:00", "2021-06-03 00:00:00", "2021-06-04 00:00:00", "2021-06-05 00:00:00", "2021-06-06 00:00:00", "2021-06-07 00:00:00", "2021-06-08 00:00:00", "2021-06-09 00:00:00", "2021-06-10 00:00:00", "2021-06-11 00:00:00"]
        } else if(graphData === "top5MockData") {
            graphData = {}
            graphData.data = [45,67,34,71,41]
            graphData.labels = ["Data 1", "Data 2", "Data 3", "Data 4", "Data 5"]
        }
        
        if(graphData && Object.entries(graphData).length) {
            this.drawGraph(graphData)
        }
    }

    drawGraph = (graphData) => {
        let graphLables = graphData.labels
        let data = []
        let labels = []
        graphLables.forEach((lab, i) => {
            if(this.props.xaxisFormat === 'datetime') {
                labels.push(momentConvertionUtcToLocalTime(lab, 'DD MMM Y HH:mm'))
            } else if(this.props.xaxisFormat === 'date') {
                labels.push(momentConvertionUtcToLocalTime(lab, 'DD MMM Y'))
            } else {
                labels.push(lab)
            }
            
            data.push(graphData.data[i] ? graphData.data[i] : 0)
        })
        
        let series = [{
            name: '',
            data: data
        }]

        let options = {
            chart: {
                height: 160,
                type: 'line',
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
                sparkline: {
                    enabled: true
                },                
                stacked: false,
            },
            colors: ['#039BE5'],
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 2,
            },
            xaxis: {
                show: false,                
                categories: labels,
                labels: {
                    show: false,
                    style: {
                        fontSize: '9px'
                    },
                },
                axisBorder: {
                    show: true,
                    // color:'#434959',
                    color: '#343947',
                    height: 2,
                    width: '100%',
                    offsetX: 0,
                    offsetY: 0
                },
                axisTicks: {
                    show: false
                },
                tooltip: {
                    enabled: false
                },
            },            
            yaxis: {
                show: false,
                axisBorder: {
                    offsetX: -15,
                    // offsetY: 0
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: false
                },
            },
            tooltip: {
                enabled: false,
                custom: function({series, seriesIndex, dataPointIndex, w}) { 
                    let returnData = ''
                    returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+w.globals.categoryLabels[dataPointIndex]+'</div>'
                    if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                        Object.entries(w.globals.initialSeries).map(([key, value], index) => {
                            returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+value.name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+value.data[dataPointIndex]+'</span></div></div></div>'

                        })
                    }

                    returnData += '</div>'
                    return returnData

                    // let val  = series[seriesIndex][dataPointIndex];
                    // let labelName =  momentConvertionUtcToLocalTime(w.globals.categoryLabels[dataPointIndex], "HH:mm")
                    // let name = ""
                    // return '<div class="d-flex arrow_box"> '+ labelName +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  capitalizeFirstLetter(name) + '  '+ val + '</span> </div>'
                },
            },
            grid: {
                show: false
            }
        }

        this.setState({ series, options })
    }

    render() {
        return(
            this.state.series ?
                <div className={`${this.props.className}`}>
                    <Chart options={this.state.options} series={this.state.series} type="line" height={this.props.height} width={this.props.width ? this.props.width : "100%"}/>
                </div>
            : null
        )
    }
}
 
/**
 * Type of the props used in the component
 */
ApexListLineChart.propTypes = {}

const mapStateToProps = state => {}

export default connect(mapStateToProps, {})(withRouter(ApexListLineChart))