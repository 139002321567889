/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Dashboard.js
 * @author Rajasekar // on 02/03/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Chart from 'chart.js'
import { 
	Spinner,
	UncontrolledPopover,
	PopoverHeader,
	PopoverBody,
	ListGroup,
	ListGroupItem,
} from 'reactstrap'
import { listAllProviders, listAllAccounts, listAllRegions, listAllServices, getAllTags } from '../../actions/commonAction'
import {
	listUserGovernanceCateories, 
	listUserRules,
	getGovernanceUserRules,
	updateStatusRule,
	
	listAllRules,
	listAllClonedRules,
	setGovernanceFilter,
	deleteRule,
} from '../../actions/governance/governanceAction'
import { PRIORITY_FILTER_OPTIONS } from '../../utils/constants'
import { AppWrapper } from '../common/AppWrapper'
import ResizeableDarkThemeTable from '../designComponents/Table/ResizeableDarkThemeTable'
import Search from '../common/SearchComponent'
import { momentDateTime } from '../../utils/utility'
import _ from 'lodash'
import { URL_PATH } from '../../config/urlPath'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import RulesSidePanel from './RulesSidePanel'
import DeleteSidePanel from './DeleteSidePanel'

import { store as CommonNotification } from 'react-notifications-component';

// const governanceType = [
// 	{value: 'All', label: 'All'},
// 	{value: 'Data Governance', label: 'Data'},
// 	{value: 'Infrastructure Governance ', label: 'Infrastructure '}
// ]

// const category = [
// 	{value: 'All', label: 'All'},
// 	{value: 'Availability', label: 'Availability'},
// 	{value: 'Cost', label: 'Cost'},
// 	{value: 'Management', label: 'Management'},
// 	{value: 'Security', label: 'Security'},
// ]

let myProviderG = null

class GovernanceDashboard extends Component {
	constructor(props) {
		super(props)
		this.categoryRef = React.createRef()
		this.serviceRef = React.createRef()
		this.priorityRef = React.createRef()
		// this.tagRef = React.createRef()

		this.state = {
			showLoading: false,
			selectedProvider: "",
			selectedAccount: "",
			selectedRegion: "",
			selectedTags: [],
			totalselectedTag: "Select",

			isProviderOpen: false,
			isCategoryOpen: false,
			isServiceOpen: false,
			isPriorityOpen: false,

			// isAccountOpen: false,
			// isRegionOpen: false,
			// isTagOpen: false,
			// isGovernanceOpen: false,
			

			providers: [],
			accounts: [],
			regions: [],
			tags: [],

			categoryList: [],
			selectedCategory: [],
			serviceList: [],
			selectedService: [],
			selectedPriority: [],
			
			filteredArray: [],
			selectedTableRow: {},

			rulesJson: {},
			modalClose: false,
			deleteErroMessage: "",
		}
	}

	componentDidMount = () => {
		// if (this.props.location && this.props.location.state && this.props.location.state.showRules) {
		//	this.props.setGovernanceFilter("show", "rules")
		// 	this.filterGovernance()
		// }
		this.props.setGovernanceFilter("show", "rules")
		this.props.setGovernanceFilter("provider", "All")
		this.props.setGovernanceFilter("accountLabel", "All")
		this.props.setGovernanceFilter("regionLabel", "All")
		this.props.setGovernanceFilter("type", "All")
		this.props.setGovernanceFilter("typeLabel", "All")
		this.props.setGovernanceFilter("governanceType", "All")
		this.props.setGovernanceFilter("category", [{label:"All", value:"All"}])
		this.props.setGovernanceFilter("tags", "All")		

		this.setState({ showLoading: true })
		if(!this.props.providers || !this.props.providers.length) {
			this.props.listAllProviders(promise => {
				if (promise) {
					this.props.setGovernanceFilter("provider", this.props.providers[0].provider_name)
					let providers = this.props.providers
					this.setState({ providers, showLoading: true, selectedProvider: providers[0].provider_name },
						() => {
							// this.getAllTags()
							this.filterGovernance("provider")
						}
					)
				} else {
					this.filterGovernance()
				}
			})
		} else {
			let providers = this.props.providers.filter(arr => arr.provider_name !== "All")
			this.setState({ providers, showLoading: true, selectedProvider: providers[0].provider_name },
				() => {
					// this.getAllTags()
					this.props.setGovernanceFilter("provider", providers[0].provider_name)
					this.filterGovernance("provider")
				}
			)
		}		
	}

	/**
	 * Method to show providers graph
	 */
	showProvidersGraph = () => {
		if (myProviderG != null) {
			myProviderG.destroy()
		}
		const providersGraph = this.providersGraph
		Chart.defaults.scale.gridLines.display = false
		Chart.defaults.scale.display = false
		let providersGraphLabel = []
		let providersGraphData = []
		const colors = ["#f68d11", "#34a853", "#2075b8"]
		this.props.providerCount.forEach(item => providersGraphLabel.push(item.provider))
		this.props.providerCount.forEach(item => providersGraphData.push(item.count))
		myProviderG = new Chart(providersGraph, {
			type: "doughnut",
			options: {
				title: {
					display: true,
					fontsize: 14,
					fontColor: "white",
					text: "Provider",
				},
				legend: {
					onClick: e => e.stopPropagation(),
					display: true,
					position: "right",
					labels: {
						fontColor: "white",
						boxWidth: 10,
						generateLabels: function(chart) {
							var data = chart.data
							if (data.labels.length && data.datasets.length) {
								return data.labels.map(function(label, i) {
									var meta = chart.getDatasetMeta(0)
									var ds = data.datasets[0]
									var arc = meta.data[i]
									var custom = (arc && arc.custom) || {}
									var getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault
									var arcOpts = chart.options.elements.arc
									var fill = custom.backgroundColor
										? custom.backgroundColor
										: getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor)
									var stroke = custom.borderColor
										? custom.borderColor
										: getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor)
									var bw = custom.borderWidth
										? custom.borderWidth
										: getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth)
									// We get the value of the current label
									var value = chart.config.data.datasets[arc._datasetIndex].data[arc._index]
									return {
										// Instead of `text: label,`
										// We add the value to the string
										text: label + ": " + value,
										fillStyle: fill,
										strokeStyle: stroke,
										lineWidth: bw,
										hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
										index: i,
									}
								})
							} else {
								return []
							}
						},
					},
				},
			},
			data: {
				datasets: [
					{
						backgroundColor: colors,
						data: providersGraphData,
						borderColor: "#2D323E",
						borderWidth: 0,
					},
				],
				labels: providersGraphLabel,
			},
		})
	}
	/**
	 * Method to return TotalPriorityCounts
	 */
	getTotalPriorityCounts = () => {
		let count = 0
		if (this.props.priorityCount && this.props.priorityCount.length) {
			this.props.priorityCount.forEach(item => {
				count = count + item.count
			})
		}
		return count
	}
	/**
	 * Delete rule from list
	 */
	deleteRule = rule_id => {
		if (rule_id) {
			this.props.deleteRule({ rule_id })
		}
	}
	
	/**
	 * Method to calculate top 4 services
	 */
	renderServicesList = popover => {
		let myArray = this.props.resourceCount
		myArray.sort((a, b) => b.count - a.count)
		if (popover) {
			return myArray.map((item, index) => {
				if (index >= 4) {
					return (
						<ListGroupItem key={index} className="p-1 d-flex justify-content-between fs-12">
							<span className="mr-4">{item.resource_type}</span>
							<span>{item.count}</span>
						</ListGroupItem>
					)
				}
				return null
			})
		} else {
			return myArray.map((item, index) => {
				if (index < 4) {
					return (
						<p key={index} className={`mb-0 d-flex justify-content-between fs-12`}>
							{item.resource_type}
							<span className="align-self-center ml-2">{item.count}</span>
						</p>
					)
				}
				return null
			})
		}
	}
	/**
	 * Filter identifiers based on the filter selection
	 */
	filterGovernance = from => {
		this.setState({ showLoading: true, filteredArray: [] })
		let params = {}
		if (this.state.selectedProvider) {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		
		if (from === "provider") {
			this.props.listAllAccounts(params, (promise, accounts) => {
				if (promise) {
					if(accounts.length) {
						let accRow = {}
						accRow.account_id = "All"
						accRow.account_name = "All"
						accRow.date = null
						accRow.disabled = false
						accRow.provider = "All"
						accounts.unshift(accRow);
					}
					this.setState({ accounts })
					this.props.setGovernanceFilter("account", "")
					this.props.setGovernanceFilter("region", "")
					this.props.setGovernanceFilter("service", "")
				}
			})
			
			this.props.listAllRegions(params, (promise, regions) => {
				if (promise) {
					let providerRegions = regions && regions[0] && regions[0].provider_regions
					if(providerRegions) {
						let provider_regions_row = {}
						provider_regions_row.availability_zones = []
						provider_regions_row.city = "All"
						provider_regions_row.country = "All"
						provider_regions_row.geography = "All"
						provider_regions_row.name = "All"
						provider_regions_row.region = "All"
						provider_regions_row.region_id = "All"
						provider_regions_row.state = "All"
						providerRegions.unshift(provider_regions_row);
					}
					this.setState({ regions: providerRegions })
				} else {
					this.setState({ regions: [] })
				}
			})
			this.props.listAllServices(params, () => {})
		}

		if(!this.props.categoryList || !this.props.categoryList.length) {
			this.listUserGovernanceCateories()
		} else {
			let categoryList = []
			let catRow = {}
			catRow.category_name = "All"
			catRow.description = ""
			catRow.resources = "All"
			categoryList.push(catRow);
			this.props.categoryList.forEach(cat => {
				let catRow = {}
				catRow.category_name = cat.category_name
				catRow.description = cat.description ? cat.description : ""
				catRow.resources = cat.resources						
				categoryList.push(catRow)
			})

			this.setState({ categoryList })
		}

		if (this.state.selectedService.length && !this.state.selectedService.includes("All")) {
			params.resource_type = this.state.selectedService
		}

		if (this.state.selectedCategory.length && !this.state.selectedCategory.filter(obj => obj.value === "All").length) {
			let array = []
			this.state.selectedCategory.forEach(item => {
				array.push(item.value)
			})
			params.category = array
		}

		if (this.state.selectedPriority.length && !this.state.selectedPriority.filter(obj => obj.value === "All").length) {
			let array = []
			this.state.selectedPriority.forEach(item => {
				array.push(item.value)
			})
			params.priority = array
		}
		
		//params.enabled = true
		this.props.listUserRules(params, () => {
			this.setState({ showLoading: false })
			if (this.props.rules && this.props.rules.length) {
				this.setState({ showLoading: false, filteredArray: this.props.rules })
			}
		})
	}	

	listUserGovernanceCateories = () => {
		this.setState({ selectedCategory: [], selectedService: [] })
		let priorityParams = {}
		if (this.state.selectedPriority.length && !this.state.selectedPriority.filter(obj => obj.value === "All").length) {
			let array = []
			this.state.selectedPriority.forEach(item => {
				array.push(item.value)
			})
			priorityParams.priority = array
		}
		let categoryList = []
		this.props.listUserGovernanceCateories(priorityParams, (promise, response) => {
			if(promise) {
				let catRow = {}
				catRow.category_name = "All"
				catRow.description = ""
				catRow.resources = "All"
				categoryList.push(catRow);
				response.length && response.forEach(cat => {
					let catRow = {}
					catRow.category_name = cat.category_name
					catRow.description = cat.description ? cat.description : ""
					catRow.resources = cat.resources						
					categoryList.push(catRow)
				})

				this.setState({ categoryList })
			} else {
				this.setState({ categoryList })
			}
		})
	}
	/**
	 * Method to handle create rule
	 */
	onClickCreateRule = ruleObj => {
		this.props.history.push({
			pathname: URL_PATH.GOVERNANCE_CREATE_RULE,
			state: { ruleDetails: {} }
		})
	}

	onClickViewRule = (ruleId) => {
		let rulesJson = this.props.rules.filter(obj => obj.rule_id === ruleId)
		this.setState({ rulesSidePanel: true, rulesJson: rulesJson.length ? rulesJson[0] : {} })
	}

	onClickEditRule = (ruleId) => {
		let params = {}
		params.rule_id = ruleId
		this.props.getGovernanceUserRules(params, (promise, ) => {
			if(promise) {
				this.setState({ editGovernanceRule: true },
					this.props.history.push({
						pathname: URL_PATH.GOVERNANCE_EDIT_RULE,
						state: { ruleDetails: this.props.editRuleDetails }
					})
				)
			} else {
				this.setState({ editGovernanceRule: false })
				// alert("check the edit api")
			} 
		})
	}

	onClickDeleteRule = (ruleId) => {
		let filteredArray = this.props.rules
		if(this.state.filteredArray.length) {
			filteredArray = this.state.filteredArray
		}
		filteredArray = filteredArray.filter(obj => obj.rule_id !== ruleId)
		this.setState({ filteredArray })
	}
	
	onClickViewDeleteOption = (ruleId) => {
		let rulesJson = this.props.rules.filter(obj => obj.rule_id === ruleId)
		this.setState({ deleteSidePanel: true, rulesJson: rulesJson.length ? rulesJson[0] : {} })
		// if (this.state.deleteRuleId && this.state.deleteRuleId !== "") {
			// alert(123)
			// if(!this.state.enteredRuleId || this.state.enteredRuleId === "") {
			// 	this.setState({ deleteErroMessage: "Please enter the Rule Id to confirm you action"})
			// } else if(this.state.deleteRuleId !== this.state.enteredRuleId) {
			// 	this.setState({ deleteErroMessage: "Enterd rule id is invalid, Check the Rule Id is confirm you action"})
			// } else {
			// 	this.props.deleteUserRule(this.state.deleteRuleId)
			// }
		// }
	}

	onClickUpdateRule = (ruleId, status) => {
		let params = {}
		params.rule_id = ruleId
		params.enabled = status ? false : true
		// let message = "User governance rule status updated successfully"
		// let messageType = "success"
		// if(status) {
		// 	message = "User governance rule status updated successfully"
		// }
 		this.props.updateStatusRule(params, (promise, result) => {
			let filteredArray = this.props.rules
			if(this.state.filteredArray.length) {
				filteredArray = this.state.filteredArray
			}
			
			filteredArray.filter(obj => obj.rule_id === ruleId).forEach(obj => obj.enabled = params.enabled)
			this.setState({ filteredArray, showEnableSpinner: "" })
			CommonNotification.addNotification({
				//title: "Wonderful!",
				message: result.message,
				type: result.status ? "success" : "danger",
				insert: "top",
				container: "top-center",
				// animationIn: ["animate__animated", "animate__fadeIn"],
				// animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
				  duration: 300000,
				  onScreen: false,
				  pauseOnHover: true,
				  showIcon: true,			  
				}
			});
		})
	}
	

	handleClickOutside = (event) => {

		if (this.categoryRef && !this.categoryRef.current.contains(event.target)) {
			this.setState({ isCategoryOpen: false })
		} else {
			this.setState({ isCategoryOpen: true })
		}

		if (this.serviceRef && !this.serviceRef.current.contains(event.target)) {
			this.setState({ isServiceOpen: false })
		} else {
			this.setState({ isServiceOpen: true })
		}

		if (this.priorityRef && !this.priorityRef.current.contains(event.target)) {
			this.setState({ isPriorityOpen: false })
		} else {
			this.setState({ isPriorityOpen: true })
		}
	}   
    
    getAllTags = () => {
		this.setState({ tagKeysArray:[], tagSubArray: []})
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		if(this.state.selectedAccount && this.state.selectedAccount !== "") {
			params.region = this.state.selectedRegion
		}

		this.props.getAllTags(params, (promise, tags) => {
			let tagArray = []
			let tagKeyValueArray = []
			let tagSubArray = []
			let tagsKeyArray = []
			if (promise) {
				tags.forEach(tag => {
					tagArray.push(tag.tags)
					tagKeyValueArray.push(tag.tags.key +" "+tag.tags.value)
                })
				_.chain(tagArray)
				.groupBy("key")
				.map((value, key) => ({ label: key, sub: value }))
				.value()
				.forEach((service, i) => {
					tagsKeyArray.push(service)
					service.sub.forEach((item, i) => {
						tagSubArray.push(item)
					})
				})
				this.setState({ tagArray: _.orderBy(tagArray, ["key"], ["asc"]), tagKeyValueArray: _.orderBy(tagKeyValueArray, ["asc"]), tagKeysArray: _.orderBy(tagsKeyArray, ["label"], ["asc"]), tagSubArray })
			} else {
				this.setState({ tagArray: [], tagKeyValueArray: [], tagKeysArray:[], tagSubArray: [] })
			}
		})
	}

	handleMultiSelectChange = (field, arrayValue) => {
		let value = arrayValue.map(item => item.value)
		// let label = arrayValue.map(item => item)
		let selectedValue = []
		if(field === 'selectedCategory') {
			let prevState = this.state[field]
			if(arrayValue.filter(e => e.value === "All").length) {
				if(!prevState.filter(e => e.value === "All").length) {
					this.state.categoryList.forEach(item => {
						let dataRow = {}
						dataRow.value = item.category_name
						dataRow.label = item.category_name +" "+item.description
						selectedValue.push(dataRow)
					})
				} else {
					let data = arrayValue.filter(e => e.value !== "All")
					selectedValue = data
				}
			} else if(!prevState.filter(e => e.value === "All").length) {
				selectedValue = arrayValue
			}

			if(selectedValue.length && !selectedValue.filter(e => e.value === "All").length) {
				if(selectedValue.length === (this.state.categoryList.length -1)) {
					let dataRow = {}
					dataRow.value = "All"
					dataRow.label = "All"
					selectedValue.push(dataRow)
				}
			}			
		} else if(field === 'selectedService') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.serviceList.forEach(ser => {
						selectedValue.push(ser)
					})
				} else {
					const data = arrayValue.filter(e => e.value !== "All")
					selectedValue = data
				}
			} else if(!prevState.includes('All')) {
				selectedValue = arrayValue
			}

			if(selectedValue.length && !selectedValue.includes("All")) {
				if(selectedValue.length === (this.state.serviceList.length -1)) {
					selectedValue.push("All")
				}
			}			
		} else if(field === 'selectedPriority') {
			let prevState = this.state[field]
			if(arrayValue.filter(e => e.value === "All").length) {
				if(!prevState.filter(e => e.value === "All").length) {
					PRIORITY_FILTER_OPTIONS.forEach(item => {
						let dataRow = {}
						dataRow.value = item.value
						dataRow.label = item.option
						selectedValue.push(dataRow)
					})
				} else {
					let data = arrayValue.filter(e => e.value !== "All")
					selectedValue = data
				}
			} else if(!prevState.filter(e => e.value === "All").length) {
				selectedValue = arrayValue
			}

			if(selectedValue.length && !selectedValue.filter(e => e.value === "All").length) {
				if(selectedValue.length === (PRIORITY_FILTER_OPTIONS.length -1)) {
					let dataRow = {}
					dataRow.value = "All"
					dataRow.label = "All"
					selectedValue.push(dataRow)
				}
			}			
		} 

		this.setState({ [field]: selectedValue },
			() => {
				if(field === "selectedCategory") {
					this.getCategoryResources()
					this.props.setGovernanceFilter("category", selectedValue)
				} else if(field === "selectedService") {
					this.props.setGovernanceFilter("resource_type", selectedValue)
				} else if(field === "selectedPriority") {
					this.props.setGovernanceFilter("priority", selectedValue)
				}
			}	
		)
	}
	

	handleMultiSelectChange1 = (childKey, arrayValue) => {
		// let value = arrayValue.map(item => item.value)
		// let label = arrayValue.map(item => item)
		let currentState = arrayValue

		if(childKey === "selectedCategory") {
			let selectedCategory = arrayValue
			let prevState = this.state.selectedCategory

			// let value = arrayValue.map(item => item.value)
			// let label = arrayValue.map(item => item)

			prevState = prevState.filter(obj => obj.value === "All")
			currentState = arrayValue.filter(obj => obj.value === "All")
			if(prevState.length && currentState.length) {
				arrayValue = arrayValue.filter(obj => obj.value !== "All")
				selectedCategory = []
				arrayValue.forEach(item => {
					this.state.categoryList.forEach(cat => {
						if(cat.category_name === item.value) {
							let dataRow = {}
							dataRow.value = cat.category_name
							dataRow.label = cat.category_name+" "+cat.description
							selectedCategory.push(dataRow)
						}
					})
				})
			} else if(prevState.length && !currentState.length) {
				selectedCategory = []
			} else if(!prevState.length && !currentState.length) {
				selectedCategory = arrayValue
			} else if(!prevState.length && currentState.length) {
				selectedCategory = []
				this.state.categoryList.forEach(cat => {
					let dataRow = {}
					dataRow.value = cat.category_name
					dataRow.label = cat.category_name+" "+cat.description
					selectedCategory.push(dataRow)
				})
				
			}
			this.setState({ selectedCategory },
				() => {
					this.getCategoryResources()
					this.props.setGovernanceFilter("category", selectedCategory)
				}
			)

			// let prevState = this.state.
			// prevState = prevState.filter(obj => obj.value === "All")
			// currentState = arrayValue.filter(obj => obj.value === "All")
			// if(!arrayValue.length || (!prevState.length && currentState.length)) {
			// 	let selectedCategory = []
			// 	this.state.categoryList.map(cat => {
			// 		let dataRow = {}
			// 		dataRow.value = cat.category_name
			// 		dataRow.label = cat.category_name+" "+cat.description
			// 		selectedCategory.push(dataRow)
			// 	})
			// 	this.setState({ selectedCategory },
			// 		() => {
			// 			//this.filterGovernance("category")
			// 			this.getCategoryResources()
			// 			this.props.setGovernanceFilter("category", selectedCategory)
			// 		}
			// 	)
			// } else {
			// 	this.setState({ selectedCategory: arrayValue.filter(arr => arr.value !== "All") },
			// 		() => {
			// 			//this.filterGovernance("category")
			// 			this.getCategoryResources()
			// 			this.props.setGovernanceFilter("category", selectedCategory)
			// 		}
			// 	)
			// } 	
		} else if(childKey === "selectedService") {

			let selectedService = arrayValue
			let prevState = this.state.selectedService

			// let value = arrayValue.map(item => item.value)
			// let label = arrayValue.map(item => item)

			prevState = prevState.filter(obj => obj.value === "All")
			currentState = arrayValue.filter(obj => obj.value === "All")
			if(prevState.length && currentState.length) {
				arrayValue = arrayValue.filter(obj => obj.value !== "All")
				selectedService = []
				arrayValue.forEach(item => {
					this.state.serviceList.forEach(serv => {
						if(serv === item.value) {
							let dataRow = {}
							dataRow.value = serv
							dataRow.label = serv
							selectedService.push(dataRow)
						}
					})
				})
			} else if(prevState.length && !currentState.length) {
				selectedService = []
			} else if(!prevState.length && !currentState.length) {
				selectedService = arrayValue
			} else if(!prevState.length && currentState.length) {
				selectedService = []
				this.state.serviceList.forEach(serv => {
					let dataRow = {}
					dataRow.value = serv
					dataRow.label = serv
					selectedService.push(dataRow)
				})
				
			}
			this.setState({ selectedService },
				() => {
					this.props.setGovernanceFilter("resource_type", selectedService)
				}
			)

			// let prevState = this.state.selectedService
			// prevState = prevState.filter(obj => obj.value === "All")
			// currentState = arrayValue.filter(obj => obj.value === "All")
			// if(!arrayValue.length || (!prevState.length && currentState.length)) {
			// 	let selectedService = []
			// 	this.state.serviceList.map(serv => {
			// 		let dataRow = {}
			// 		dataRow.value = serv
			// 		dataRow.label = serv
			// 		selectedService.push(dataRow)
			// 	})
			// 	this.setState({ selectedService },
			// 		() => {
			// 			// this.filterGovernance("category")
			// 			this.props.setGovernanceFilter("category", selectedService)
			// 		}
			// 	)
			// } else {
			// 	let selectedServiceObject = arrayValue.filter(arr => arr.value !== "All")
			// 	let selectedService = []
			// 	selectedServiceObject.map(serv => {
			// 		let dataRow = {}
			// 		dataRow.value = serv.value
			// 		dataRow.label = serv.label
			// 		selectedService.push(dataRow)
			// 	})
			// 	this.setState({ selectedService },
			// 		() => {
			// 			// this.filterGovernance("category")
			// 			this.props.setGovernanceFilter("resource_type", selectedService)
			// 		}
			// 	)
			// } 	
		} else if(childKey === "selectedPriority") {
			let selectedPriority = arrayValue
			let prevState = this.state.selectedPriority

			// let value = arrayValue.map(item => item.value)
			let label = arrayValue.map(item => item)

			prevState = prevState.filter(obj => obj.value === "All")
			currentState = arrayValue.filter(obj => obj.value === "All")
			if(prevState.length && currentState.length) {
				arrayValue = arrayValue.filter(obj => obj.value !== "All")
				selectedPriority = []
				arrayValue.forEach(item => {
					PRIORITY_FILTER_OPTIONS.forEach(prio => {
						if(prio.value === item.value) {
							label = {}
							label.value = prio.value
							label.label = prio.option
							selectedPriority.push(label)
						}
					})
				})
			} else if(prevState.length && !currentState.length) {
				selectedPriority = []
			} else if(!prevState.length && !currentState.length) {
				selectedPriority = arrayValue
			} else if(!prevState.length && currentState.length) {
				selectedPriority = []
				PRIORITY_FILTER_OPTIONS.forEach(prio => {
					label = {}
					label.value = prio.value
					label.label = prio.option
					selectedPriority.push(label)
				})
				
			}
			this.setState({ selectedPriority },
				() => {
					this.props.setGovernanceFilter("priority", selectedPriority)
				}
			) 	
		}
	}

	getCategoryResources = () => {
		let slectedCategoryValue = this.state.selectedCategory.filter(arr => arr.value !== "All")
		slectedCategoryValue = slectedCategoryValue.map(item => item.value)
		let serviceList = []
		this.state.categoryList.forEach(cat => {
			slectedCategoryValue.filter(arr => arr.value === cat.category_name).length && cat.resources.forEach(item => {
				serviceList.push(item)
			})
		})
		if(serviceList.length && !serviceList.filter(obj => obj === "All").length) {
			serviceList.unshift("All");
		}
		this.setState({ serviceList: serviceList.filter((value, index) => serviceList.indexOf(value) === index) })
	}

	getMultiSelectedCount = (type, array) => {
		if(type === "service") {
			return array.length && array.includes("All") ? "All" :  array.length ? array.length +" Selected" : "All"
		} else {
			return array.length && array.filter(obj => obj.value === "All").length ? "All" :  array.length ? array.length +" Selected" : "All"
		}
	}

	onSearch = () => {
		// this.setState({ selectedProvider: [], selectedCategory: [], selectedService: [], selectedPriority: [], showLoading: true },
		// 	() => {
			//this.props.setGovernanceFilter("tags", "All")
		this.filterGovernance()
		// 	}
		// )
	}
	
	onReset = () => {
		this.setState({ 
			selectedProvider: this.state.providers[0].provider_name,
			selectedCategory: [],
			selectedService: [],
			selectedPriority: [],
		})
	}


	/**
	 * Renders governance Dashboard
	 */
	render() {
		return (
			<div className="container-fluid overflow-auto flex-grow-1 bg-muted" onClick={ (event) => { this.handleClickOutside(event) } }>
				<div className="row h-100">
					<div className="col-sm-12 p-0">
						<div className="title-section p-2">
							<div className="row">
								<div className="col-4 align-self-center">
									<h6 className="text-white m-0">Governance Rules</h6>
									<p className="text-white m-0">
										Manage user created governance rules on your Cloud
									</p>
								</div>								
								<div className="col-8 align-self-center">
									<div className={`col-md-12 d-flex flex-wrap justify-content-end bd-highlight ${this.state.showLoading ? "disabled" : ""}`}>
										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: "150px" }}>
											<label className="mb-1">Provider</label>
											<Select
												placeholder={"All"}
												isSearchable={false}
												//onFocus={this.openProivderMenu}
												className="f13 p-0 colorBlack reactSelectFilterDrop"
												value={({
													value: this.props.selectedFilters.provider,
													label: this.props.selectedFilters.provider ? this.props.selectedFilters.provider : <span className="placeholder">Select</span>
												})}
												options={this.state.providers && this.state.providers.map(item => ({
													value: item.provider_name,
													label: item.provider_name,	
												}))}
												onChange={event => this.setState({ selectedProvider: event.value }, 
													() => {
														this.props.setGovernanceFilter("provider", event.value)
														this.filterGovernance("provider")
													}
												)}
											/>
										</div>
										
										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} ref={this.categoryRef} style={{maxWidth: "150px"}}>
											<label>Category</label>
											<div className={`multiselectDarkThemeFilter ${this.state.categoryList && this.state.categoryList.length > 10 ? "" : "removeDropdownSearchBar"}`}>
												<ReactMultiSelectCheckboxes						
													placeholderButtonLabel="All"
													getDropdownButtonLabel={() => this.getMultiSelectedCount("category", this.state.selectedCategory)}
													menuIsOpen ={this.state.isCategoryOpen}
													value={this.state.selectedCategory && this.state.selectedCategory.map(cat => ({
														value: cat.value,
														label: cat.label ? cat.label : <span className="placeholder">Select</span>
													}))}
													onChange={arr => {
													this.handleMultiSelectChange("selectedCategory", arr ? arr : []) }}
													options={this.state.categoryList && this.state.categoryList.map(item => ({ 
														value: item.category_name,
														label: item.category_name +" "+item.description,
													}))}
												/>
											</div>
										</div>

										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} ref={this.serviceRef} style={{maxWidth: "150px"}}>
											<label>Service</label>
											<div className={`multiselectDarkThemeFilter ${this.state.serviceList && this.state.serviceList.length > 10 ? "" : "removeDropdownSearchBar"}`}>
												<ReactMultiSelectCheckboxes						
													placeholderButtonLabel="All"
													getDropdownButtonLabel={() => this.getMultiSelectedCount("service", this.state.selectedService)}
													menuIsOpen ={this.state.isServiceOpen}
													value={this.state.selectedService && this.state.selectedService.map(cat => ({
														value: cat,
														label: cat ? cat : <span className="placeholder">Select</span>
													}))}
													onChange={arr => {
													this.handleMultiSelectChange("selectedService", arr ? arr : []) }}
													options={this.state.serviceList && this.state.serviceList.map(item => ({ 
														value: item,
														label: item,
													}))}
												/>
											</div>
										</div>									

										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1 displayNone`} ref={this.tagRef} style={{maxWidth: "150px"}}>
											<label className="d-bock">Tag</label>
											<Select
												placeholder={"All"}
												menuIsOpen={this.state.isTagOpen}
												isSearchable={true}
												className="f12 p-0 colorBlack reactSelectFilterDrop"
												value={({
													value: this.props.selectedFilters.tags,
													label: this.props.selectedFilters.tags
												})}
												options={this.state.tagArray && this.state.tagArray.map(tag => ({ 
													value: tag.key+" "+tag.value,
													label: tag.key+" "+tag.value,
												}))}
												onChange={event => {
													if(this.props.selectedFilters.tags !==  event.value) {
														this.props.setGovernanceFilter("tags", event.value)
														//this.filterGovernance("tags")
													}
												}}
											/>
										</div>
										
										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} ref=
										{this.priorityRef} style={{maxWidth: "150px"}}>
											<label>Priority</label>
											<div className={`multiselectDarkThemeFilter removeDropdownSearchBar`}>
												<ReactMultiSelectCheckboxes						
													placeholderButtonLabel="All"
													getDropdownButtonLabel={() => this.getMultiSelectedCount("priority", this.state.selectedPriority)}
													menuIsOpen ={this.state.isPriorityOpen}
													value={this.state.selectedPriority && this.state.selectedPriority.map(cat => ({
														value: cat.value,
														label: cat.label ? cat.label : <span className="placeholder">Select</span>
													}))}
													onChange={arr => {
													this.handleMultiSelectChange("selectedPriority", arr ? arr : []) }}
													options={PRIORITY_FILTER_OPTIONS && PRIORITY_FILTER_OPTIONS.map(item => ({ 
														value: item.value,
														label: item.option,
													}))}
												/>
											</div>
										</div>

										<div className="mt-2 d-flex align-self-center">
											<div className="btn-group" role="group" aria-label="governance-dash">
												<button type="button" className={`${this.state.showLoading ? "disabled" : "cursorPointer"} btn btn-secondary border-right`} onClick={()=> this.onSearch()}>Search</button>
												<button type="button" className={`${this.state.showLoading ? "disabled" : "cursorPointer"} btn btn-secondary border-left`} onClick={()=> this.onReset()}><i className="far fa-redo"></i></button>
											</div>
											<button className="ml-3 btn btn-primary btn-sm" onClick={() => this.onClickCreateRule(this.state.selectedTableRow)}>
												Create Rule
											</button>
										</div>
									</div>
								</div>
							</div>
							{this.props.priorityCount && this.props.priorityCount.length ?
								<div className="row mt-2">
									<div className="col-12 col-xl-12 d-flex justify-content-around">
										<div className="d-flex flex-column text-white ml-2">
											<div className="text-white text-center font-weight-bold fs-12">Priority Count</div>
											<div className="d-flex row mt-3">
												{this.props.priorityCount.map((item, index) => {
													return (
														<div className="pr-2 mb-1 w-50" key={index}>
															<div className="bd-highlight d-flex">
																<small className="text-white bd-highlight">{item.priority}</small>
																<small className="text-white bd-highlight ml-auto">{item.count}</small>
															</div>
															<div className="progress">
																<div
																	className={`${
																		item.priority === "High"
																			? "bg-danger"
																			: item.priority === "Medium"
																			? "bg-warning"
																			: item.priority === "Low"
																			? "bg-low"
																			: ""
																	} progress-bar`}
																	role="progressbar"
																	style={{ width: `${(item.count / this.getTotalPriorityCounts()) * 100}%` }}
																	aria-valuenow={item.count}
																	aria-valuemin={item.count}
																	aria-valuemax={this.getTotalPriorityCounts()}
																/>
															</div>
														</div>
													)
												})}
											</div>
										</div>
										{this.props.providerCount && Object.keys(this.props.providerCount).length ? (
											<div
												style={{
													height: "100px",
													// width: "100px",
												}}
											>
												<canvas
													style={{
														height: "100px",
														width: "160px",
													}}
													ref={providersGraph => (this.providersGraph = providersGraph)}
												/>
											</div>
										) : null}

										{this.props.resourceCount && this.props.resourceCount.length ? (
											<div className="d-flex flex-column text-white ml-2">
												<div className="d-flex mb-2 justify-content-between">
													<div className="text-white text-center font-weight-bold fs-12 mr-3">Resources</div>
													<small className="mr-3 btn-link text-decoration-none cursor-pointer" id="moreRegionButton">
														More
													</small>
													<UncontrolledPopover
														placement="bottom"
														trigger="legacy"
														target="moreRegionButton"
														className="h-25"
													>
														<PopoverHeader>Resources</PopoverHeader>
														<PopoverBody>
															<ListGroup>{this.renderServicesList(true)}</ListGroup>
														</PopoverBody>
													</UncontrolledPopover>
												</div>
												<div className="d-flex fs-12">
													<div className="mr-3 text-nowrap">{this.renderServicesList()}</div>
												</div>
											</div>
										) : null}

										{/* {this.props.statusCounts && this.props.statusCounts.length ? (
											<div
												style={{
													height: "100px",
													// width: "150px",
												}}
											>
												<canvas
													style={{
														width: "170px",
														height: "100px",
													}}
													ref={statusGraph => (this.statusGraph = statusGraph)}
												/>
											</div>
										) : null} */}
										{/* {this.props.regionCounts && this.props.regionCounts.length ? (
											<div className="d-flex flex-column text-white ml-2">
												<div className="d-flex mb-2 justify-content-between">
													<div className="text-white text-center font-weight-bold fs-12">Regions</div>
													<small className="mr-3 btn-link text-decoration-none cursor-pointer" id="moreRegionButton">
														More
													</small>
													<UncontrolledPopover placement="bottom" target="moreRegionButton" className="h-25">
														<PopoverHeader>Regions</PopoverHeader>
														<PopoverBody>
															<ListGroup>{this.renderRegionList(true)}</ListGroup>
														</PopoverBody>
													</UncontrolledPopover>
												</div>
												<div className="d-flex fs-12">
													<div className="mr-3 text-nowrap">{this.renderRegionList()}</div>
												</div>
											</div>
										) : null} */}
									</div>
								</div>
							: null}
						</div>

						<div className="container-fluid bg-dark m-2 rounded h-100">
							<div className="d-flex justify-content-between py-2">
								<p className="mb-0 align-self-center">Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.props.rules && this.props.rules.length} Rules</p>
								<div className={`mb-n2 ${this.state.showLoading ? "disabled" : ""}`}>
									<Search
										data={this.props.rules ? this.props.rules : []}
										filteredData={filteredArray => {
											this.setState({ filteredArray })
										}}
									/>
								</div>
							</div>
							<div className='tab-content' id='myTabContent'>
								<div className='tab-pane fade show active governanceDashboardTable' id='home' role='tabpanel' aria-labelledby='home-tab'>
									{this.props.rules && this.props.rules.length && !this.state.showLoading ? (
										<ResizeableDarkThemeTable
											columns={[
												{
													Header: "Date",
													accessor:  d => d.last_modified ? d.last_modified : d.created_at,
													Cell: cellInfo => cellInfo.row.original.last_modified && cellInfo.row.original.last_modified !== "" ?  momentDateTime(cellInfo.row.original.last_modified) : momentDateTime(cellInfo.row.original.created_at)
												},
												/* {
													Header: "Rule ID",
													accessor: "rule_id"
												}, */
												{
													Header: "Rule Name",
													accessor: "rule_name",
												},
												/* {
													Header: "Description",
													accessor: "description"
												}, */
												{
													Header: "Category",
													accessor: "category",
												},
												{
													Header: "Service",
													accessor: "resource_type",
												},
												{
													Header: "Priority",
													accessor: "priority",
													Cell: cellInfo => (
														<span className={`text-white badge risk-badge-${
															cellInfo.row.original.priority === "Low"
																? "low"
																: cellInfo.row.original.priority === "Medium"
																	? "medium"
																	: cellInfo.row.original.priority === "High"
																		? "high"
																		: cellInfo.row.original.priority === "Critical"
																			? "critical"
																			: ""
															}`}
														>
															{cellInfo.row.original.priority}
														</span>
													),
												},
												{
													Header: "Type",
													accessor: d =>
														Array.isArray(d.governance_type)
															? d.governance_type.map((item, index) => (d.governance_type.length - 1 === index ? item : item + ", "))
															: d.governance_type,
													Cell: cellInfo => (
														<React.Fragment>
														{cellInfo.row.original.governance_type && cellInfo.row.original.governance_type.map(item => {
															return(
															<small className={`badge badge-outline-light mb-1 mr-1`}>{item}</small>
															)
														})}
														</React.Fragment>
													),
												},
												{
													Header: "Version",
													accessor: "version",
												},
												/*{
													Header: "Belongs to",
													accessor: "service_name",
												},
												{
													Header: "Attached To",
													Cell: cellInfo => (
														<span className="">wegot-prod US EAST (Ohio) Batch-CE-Spot</span>
													)
													/* accessor: d => (d.account_id ? d.account_id + " : " : "") */
													/* accessor: d => getAccountNameFromId(d.account_id, this.state.accounts) + " : " + d.region + " : " + d.tags,*/
													/* Cell: cellInfo => (
														<React.Fragment>
														<span className="">
															{cellInfo.row.original.account_id ? getAccountNameFromId(cellInfo.row.original.account_id, this.state.accounts) + " : " : ""}   
															{cellInfo.row.original.region ? cellInfo.row.original.region : ""} {cellInfo.row.original.tags ? " : "+cellInfo.row.original.tags : "" }
														</span>
														</React.Fragment>
													),
												}, */								
												/* {
													Header: "Events",
													accessor: " ",
												},													
												{
													Header: "P/F Count",
													accessor: " ",
												},*/
												{
													Header: "Status",
													accessor: "enabled",
													Cell: cellInfo => (
														<span
															className={`badge status-badge badge-outline-${
																cellInfo.row.original.enabled ?"success-black" : "danger-black"
																} badge-pill`}
														>
															{cellInfo.row.original.enabled ? "Enabled" : "Disabled"}
														</span>
													),
												},
												{
													Header: "Actions",
													Cell: cellInfo => (
														<div className="d-flex justify-content-between align-self-center">
															<p className="m-0 p-0 mr-1 cursorPointer" onClick={() => this.setState({ rulesJson : {} }, () => this.onClickViewRule(cellInfo.row.original.rule_id))}><i className="fal fa-book-open"></i></p>
															<p className="m-0 p-0 mr-1 cursorPointer" onClick={() => this.onClickEditRule(cellInfo.row.original.rule_id)}><i className="fal fa-user-edit"></i></p>
															<p className="m-0 p-0 mr-1 cursorPointer" onClick={() => this.setState({ rulesJson : {} }, () => this.onClickViewDeleteOption(cellInfo.row.original.rule_id))}><i className="fal fa-trash"></i></p> 
															{/* //onClick={() => this.setState({ enteredRuleId: "", modalClose: true, deleteRuleId: cellInfo.row.original.rule_id  })}>Delete</p> */}
															<p className="m-0 p-0 mr-1 cursorPointer" onClick={() => this.setState({ showEnableSpinner: cellInfo.row.original.rule_id }, () => this.onClickUpdateRule(cellInfo.row.original.rule_id, cellInfo.row.original.enabled))}>
																{this.state.showEnableSpinner && cellInfo.row.original.rule_id === this.state.showEnableSpinner ?
																	<Spinner className="text-center" color="dark" size="sm" />
																:
																	cellInfo.row.original.enabled ? <i className="fal fa-thumbs-up"></i> : <i className="fal fa-thumbs-down"></i>
																}
															</p>
														</div>
														)
												}
											]}
											data={this.state.filteredArray ? this.state.filteredArray : this.props.rules}
											perPage={20}
											dashboard={(this.props.filteredArray ? this.props.filteredArray.length : (this.props.rules && this.props.rules.length)) ? true : false}
											sortByColumn={"Date"}
											riskTooltip={[0]}
											onClickRow={tableRow => {}}
											// tableSize={"table-sm"}
											// tableHead={"bg-dark"}
											tooltipFor={[1,2,3]}
										/>
									) : (!this.state.showLoading && !this.props.rules) ||
										(this.props.rules && !this.props.rules.length && !this.state.showLoading) ? (
										<div className="d-flex justify-content-center m-4">
											<p>There are no data on this criteria. Please try adjusting your filter.</p>
										</div>
									) : null}
									{this.state.showLoading ? (
										<div className="d-flex justify-content-center m-4">
											<Spinner className="text-center" color="white" size="lg" />
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>
					{this.state.rulesSidePanel ? 
						<RulesSidePanel 
							closeViewRulesSidePanel={() => this.setState({ rulesSidePanel: false })}
							rulesJson = {this.state.rulesJson}
						/>
					: null 
					}

					{this.state.deleteSidePanel ? 
						<DeleteSidePanel 
							closeViewDeleteSidePanel={() => this.setState({ deleteSidePanel: false })}
							rulesJson = {this.state.rulesJson}
							onClickDeleteRule={ruleId => this.onClickDeleteRule(ruleId)}
						/>
					: null 
					}
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
GovernanceDashboard.propTypes = {
	listAllProviders: PropTypes.func,
	listAllAccounts: PropTypes.func,
	listAllRegions: PropTypes.func,
	listAllServices: PropTypes.func,

	listUserGovernanceCateories: PropTypes.func, 
	listUserRules: PropTypes.func,
	getGovernanceUserRules: PropTypes.func,
	updateStatusRule: PropTypes.func,


	listAllRules: PropTypes.func,
	getTotalCount: PropTypes.func,
	getDayWiseCount: PropTypes.func,
	getAssetDetails: PropTypes.func,
	getTotalAssetCount: PropTypes.func,
	getDailyAssetCount: PropTypes.func,
	history: PropTypes.object,
	rules: PropTypes.array,
	providers: PropTypes.array,
	accounts: PropTypes.array,
	services: PropTypes.array,
	regions: PropTypes.array,
	selectedTableRow: PropTypes.object,
	dailyAssetCount: PropTypes.object,
	setGovernanceFilter: PropTypes.func,
	selectedFilters: PropTypes.object,
	categoryList: PropTypes.array,
	listAllClonedRules: PropTypes.func,
	deleteRule: PropTypes.func,
	location: PropTypes.object,
	resourceCount: PropTypes.array,
	providerCount: PropTypes.object,
	priorityCount: PropTypes.object,
	getAllTags: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log("-",state)
	return {
		rules: state.governance.rules.results ? state.governance.rules.results.filter(arr => arr.status === "ACTIVE") : [],
		providers: state.filters.providers,
		services: state.filters.services,
		selectedTableRow: state.governance.selectedTableRow,
		selectedFilters: state.governance.selectedFilters,
		categoryList: state.governance.categoryList,
		resourceCount: state.governance.resourceCount,
		providerCount: state.governance.providerCount,
		priorityCount: state.governance.priorityCount,
		editRuleDetails: state.governance.editRuleDetails ? state.governance.editRuleDetails : {}
	}
}

export default AppWrapper(GovernanceDashboard, mapStateToProps, {
	listUserGovernanceCateories, 
	listUserRules,
	getGovernanceUserRules,
	updateStatusRule,

	listAllRules,
	listAllClonedRules,
	setGovernanceFilter,
	listAllProviders,
	listAllAccounts,
	listAllServices,
	listAllRegions,
	deleteRule,
	getAllTags,
})
