/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file EventsTab.js
 * @author Prakash // on 06/04/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { getCoveredListsSummary, getCoveredTrendsSummary } from '../../../actions/aiops/MicroServicesAction'
import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'
import { getEventDetails } from '../../../actions/aiops/EventAction'

import _ from 'lodash'
import { momentDateGivenFormat, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, capitalizeFirstLetter } from '../../../utils/utility'

import Search from '../../common/SearchComponent'
import ApexTrendBarChart from '../../common/charts/ApexTrendBarChart'

class EventsTab extends Component {
    constructor(props) {
        super(props)
        this.toggleRef = React.createRef()
        
        this.state = {
            showLoading: true,

            startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
            overAllTotalPages: 1,
			perPage: 100,

            pageType: "Events",
            groupType: 'All',
            groupedBy: 'impact_type',
            selectedCategory: 'Important / All',
            minimizeGraphSection: false,
        }
    }

	componentDidMount = () => {
        if(this.props.microSericesFilters && Object.entries(this.props.microSericesFilters).length) {
            this.setState({ 
                start_time: this.props.microSericesFilters.start_time,
                end_time: this.props.microSericesFilters.end_time,
                brushStartTime: this.props.microSericesFilters.brushStartDate ? this.props.microSericesFilters.brushStartDate : this.props.microSericesFilters.start_time,
                brushEndTime: this.props.microSericesFilters.brushEndDate ? this.props.microSericesFilters.brushEndDate : this.props.microSericesFilters.end_time,
                listLoader: true,
                graphData: {}
            },
                () => {
                    this.getCoveredTrendsSummary()
                    this.getCoveredListsSummary()
                }
            )
        }
    }

    componentDidUpdate = (prevProps) => {}

    getCoveredTrendsSummary = () => {
        let params = {
            "start_time": momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss'),
            "end_time":  momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss'),
            "provider": "aws",
            "account_id": this.props.selectedAccount,
            "microservice_id": [this.props.selectedMicroServies.microservice_id],
            "duration_aggregate_by": "hourly",
            "aggregate_by": "microservice",
            "flag": "events"
        }
        this.props.getCoveredTrendsSummary(params, (promise, response) => {
            if(promise) {
                this.setState({ trendData: response },
                    () => this.trendDetails()    
                )
            } else {
                this.setState({ trendData: {} })
            }
        })        
    }

    trendDetails = () => {
        let graphData = {
            start_time: momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss'), 
            end_time: momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss')
        }
        let trendData = this.state.trendData
        let graphDetails = trendData.results && trendData.results.length && trendData.results[0]

        let trendSeverityCount = graphDetails && graphDetails.total_count ? graphDetails.total_count : 0
                    
        if(graphDetails && graphDetails.trend && graphDetails.trend.events && graphDetails.trend.events.data) {
            graphData = {
                data: graphDetails.trend.events.data,
                labels: trendData.dates,
                start_time: momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'), 
                end_time: momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss')
            }
        }

        console.log("graphData", graphData)

        this.setState({ graphData, trendSeverityCount })
    }

    resetGraph = () => {
        let graphData = this.state.graphData
        this.setState({ graphData: {}, zoomedStartDate: "", zoomedEndDate: "" },
            () => {
                this.setState({ graphData, listData: this.state.totalListData, filteredListData: this.state.totalListData })
            }
        )
    }

    navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = this.state.startRecord
            if(action === 'next' && currentPage !== this.state.totalPages) {
                startRecord = startRecord + this.state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - this.state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = this.state.totalPages
                startRecord = (this.state.totalPages - 1) * this.state.perPage
            }
            this.setState({ currentPage, startRecord })
        } 
	}

    gotoPage = (pageNumber) => {
        if(pageNumber > 0  && pageNumber <= this.state.totalPages) {
            let startRecord = (pageNumber - 1) * this.state.perPage
            this.setState({ currentPage: pageNumber, pageNumber, startRecord })
        } else  if(pageNumber > this.state.totalPages) {
            this.setState({ pageNumber: this.state.currentPage })  
        } else {
            this.setState({ pageNumber })
        }
    }

    getCoveredListsSummary = () => {
        let params = {
            "start_time": momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss'),
            "end_time":  momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss'),
            "provider": "aws",
            "account_id": this.props.selectedAccount,
            "microservice_id": [this.props.selectedMicroServies.microservice_id],
            "duration_aggregate_by": "hourly",
            "aggregate_by": "microservice",
            "flag": "events",
            "list_aggregate_by": this.state.groupedBy
        }

        this.props.getCoveredListsSummary(params, (promise, response) => {
            if(promise) {
                let data = response.results && response.results.length ? response.results[0] : []
                this.setState({ listResponse: data, showGroupLoading: false },
                    () => {
                        this.structureGroupData()
                    }    
                )
            } else {
                this.setState({ listResponse: [], showGroupLoading: false, eventListLoader: false },
                    // () => this.props.menuDisabled()
                )
            }
        })
    }

    structureGroupData = () => {
        let groupAllData = []

        let totalCount = 0
        
        let groupCategory = []
        if(this.state.listResponse.list && this.state.listResponse.list.events && this.state.listResponse.list.events.length) {
            totalCount = this.state.listResponse.list.events.reduce( function(a, b){ return a + (b.count ? b.count : (b.events ? b.events.length : 0))}, 0);
            this.state.listResponse.list.events.forEach((item, index) => {
                let dataRow = {}
                dataRow.count = item.count ? item.count : (item.events ? item.events.length : 0)
                dataRow.category = item.label
                dataRow.orderBy = index+1
                let percentage = Math.ceil(((item.count / totalCount) * 100)/10)
                let array = []
                for(let i=1; i<=percentage; i++) {
                    array.push(i)
                }
                dataRow.totalBars = array
                groupCategory.push(dataRow)
                item.events.forEach(alt => {
                    if(groupAllData.length) {
                        let filter = groupAllData.filter(arr => arr.event_id === alt.event_id)
                        if(!filter.length) {
                            groupAllData.push(alt)
                        }
                    } else {
                        groupAllData.push(alt)
                    }
                })
            })
        }
        
        let dataRow = {}
        dataRow.count = ""
        dataRow.category = 'Important / All'
        dataRow.orderBy = 0
        groupCategory.push(dataRow)

        let orderedArray = _.orderBy(groupCategory, ['orderBy'], ['asc'])

        this.setState({ groupAllData, groupedData: groupAllData, groupCategory: orderedArray },
            () => {
                this.filterData()
            }
        )
    }

    getCategory = () => {
        let filterdCategory = this.state.groupAllData
        if(this.state.selectedCategory !== 'Important / All') {
            filterdCategory = this.state.listResponse.details.filter(arr => arr.label === this.state.selectedCategory)
            if(filterdCategory && filterdCategory.length) {
                filterdCategory = filterdCategory[0].events ? filterdCategory[0].events : []
            }
        }

        this.setState({ groupedData: filterdCategory, selectedEventId: "", startRecord: 0, currentPage: 1 },
            () => {
                this.filterData()
                // this.redirectToAnalysis()
                let scroll = document.getElementById('scrollTop');
                scroll.scrollIntoView();
            }
        )
    }

    filterData = () => {
        let data = this.state.groupedData ? this.state.groupedData : []
        data = _.orderBy(data, ['event_time'], ['desc'])

        let totalPages = 1
		if(data.length > this.state.perPage) {
			totalPages = Math.ceil(data.length / this.state.perPage)
		}

        this.setState({ totalFilteredArray: data, filteredArray: data, totalPages, eventListLoader: false },
            () => {
                if(this.state.filteredArray.length) {
                    // this.getEventDetails(this.state.filteredArray[0])
                }
            }    
        )
    }

    filterZoomData = (zoomedStartDate, zoomedEndDate) => {

        let data = this.state.totalFilteredArray ? this.state.totalFilteredArray : []

        data = data.filter(e => momentConvertionUtcToLocalTime(e.event_time, "YYYY-MM-DD HH:mm:ss") >= zoomedStartDate && momentConvertionUtcToLocalTime(e.event_time, "YYYY-MM-DD HH:mm:ss") <= zoomedEndDate)

        data = _.orderBy(data, ['event_time'], ['desc'])

        let totalPages = 1
		if(data.length > this.state.perPage) {
			totalPages = Math.ceil(data.length / this.state.perPage)
		}

        this.setState({ totalFilteredArray: data, filteredArray: data, totalPages, startRecord: 0, currentPage: 1, zoomedStartDate, zoomedEndDate },
            () => {
                if(this.state.filteredArray.length) {
                    // this.getEventDetails(this.state.filteredArray[0])
                }
            }    
        )

    }

    getEventDetails = (selectedEvent) => {
        let detailParams = {}
        detailParams.event_id = selectedEvent.event_id
        detailParams.event_source = selectedEvent.event_source

        let assetName = ""
        let assetID = ""
        let serviceName = ""
        let resourceType = ""
        let merticsAssetIdInvolved = []
        let merticsAssetNameInvolved = []
        selectedEvent.assets.forEach(item => {
            assetName = item.asset_name
            assetID = item.asset_id
            serviceName = item.service_name
            resourceType = item.resource_type
            if(item.asset_id) {
                merticsAssetIdInvolved.push(item.asset_id)
            }
            if(item.asset_name) {
                merticsAssetNameInvolved.push(item.asset_name)
            }
        })
        selectedEvent.assetName = assetName
        selectedEvent.assetID = assetID
        selectedEvent.serviceName = serviceName
        selectedEvent.resourceType = resourceType

        this.setState({ 
            selectedEventId: selectedEvent.event_id === this.state.selectedEventId ? "" : selectedEvent.event_id, 
            selectedEvent: selectedEvent.event_id === this.state.selectedEventId ? "" : selectedEvent,            
            expandCommonEventDetails: true,
            expandUserIdentity: false, 
            expandAccessDetails: false, 
            expandTagDetails: false, 
            expandPropsAssetDetails: false, 
            expandEventAssetDetails: false, 
        })

        this.props.getEventDetails(detailParams, (promise, result) => {
            this.setState({ showDetailsLoading: false })
        })
    }
    
    handleChange = (event) => {
        this.setState({ groupType: event.target.value, showGroupLoading: true, selectedEvent: "", selectedEventId: "", eventListLoader: true },
            () => {
                this.getCoveredListsSummary()
                // this.redirectToAnalysis()
            }    
        )
    }  

    handleClickOutside(event) {
        // if (this.toggleRef && !this.toggleRef.current.contains(event.target)) {
        //     this.setState({ showPageTypeOptions: false })
        // } else {
        //     this.setState({ showPageTypeOptions: true })
        // }
    }
    
	render() {
		return (
            <div className="" id="scrollTop" onClick={(event) => this.handleClickOutside(event)}>
                <div className="bg-dark3 mt-2 p-3 rounded">
                    <div className="d-flex justify-content-between">
                        
                        <div className="d-flex">
                            <label className="m-0 p-0">Total ({this.state.trendSeverityCount && this.state.trendSeverityCount.total ? this.state.trendSeverityCount.total : 0})</label>
                            <div className="d-flex ml-2 align-self-center">
                                <div className={`f10 badge-square-sm risk-badge-critical`}>C</div>
                                <p className="m-0 f11 text-white"><span className="mx-1">:</span> {this.state.trendSeverityCount && this.state.trendSeverityCount.severity && this.state.trendSeverityCount.severity.Critical ? this.state.trendSeverityCount.severity.Critical : 0}</p>
                            </div>
                            <div className="d-flex ml-2 align-self-center">
                                <div className={`f10 badge-square-sm risk-badge-high`}>H</div>
                                <p className="m-0 f11 text-white"><span className="mx-1">:</span> {this.state.trendSeverityCount && this.state.trendSeverityCount.severity && this.state.trendSeverityCount.severity.High ? this.state.trendSeverityCount.severity.High : 0}</p>
                            </div>
                            <div className="d-flex ml-2 align-self-center">
                                <div className={`f10 badge-square-sm risk-badge-medium`}>M</div>
                                <p className="m-0 f11 text-white"><span className="mx-1">:</span> {this.state.trendSeverityCount && this.state.trendSeverityCount.severity && this.state.trendSeverityCount.severity.Medium ? this.state.trendSeverityCount.severity.Medium : 0}</p>
                            </div>
                            <div className="d-flex ml-2 align-self-center">
                                <div className={`f10 badge-square-sm risk-badge-low`}>L</div>
                                <p className="m-0 f11 text-white"><span className="mx-1">:</span> {this.state.trendSeverityCount && this.state.trendSeverityCount.severity && this.state.trendSeverityCount.severity.Low ? this.state.trendSeverityCount.severity.Low : 0}</p>
                            </div>
                        </div>
                        <div className="d-flex">
                            {!this.state.minimizeGraphSection ?
                                <i className='far fa-redo cursorPointer' onClick={()=> this.resetGraph()}></i>
                            : null}
                            <i className={`fa fa-caret-${this.state.minimizeGraphSection ? "down" : "down"} cursorPointer ml-3 text-gray2`} onClick={() => this.setState({ minimizeGraphSection: !this.state.minimizeGraphSection })}></i>
                        </div>
                    </div>
                    {this.state.graphData && Object.keys(this.state.graphData).length && !this.state.minimizeGraphSection ?
                        <div className="mb-n2">
                            <ApexTrendBarChart
                                graphData={this.state.graphData}
                                graphRiskCountSection={false}
                                sparkline={false}
                                yaxis={false}
                                yaxisLabel={true}
                                yaxisTickAmount={2}
                                xaxis={true}
                                xaxisFormat={'string'}
                                xaxisLabel={true}
                                axisLabelColor={'#50586A'}
                                legend={false}
                                stacked={false}
                                height={100}
                                horizontal={false}
                                barHeight={'40%'}
                                barEndShape={'flat'}
                                columnWidth={'0%'}
                                backgroundBarShape={'rounded'}
                                backgroundBarColors={['#333947']}
                                showBackgroundBarColors={true}
                                className={"transparentTooltip"}
                                colors={['#3DAFE8']}
                                zoomEnabled={true}
                                getZoomPeriod={true}
                                zoomPeriod={(zoomedStartDate, zoomedEndDate) => {this.filterZoomData(zoomedStartDate, zoomedEndDate)}}
                            />
                        </div>
                    : null}
                </div>
                <div className="d-flex mt-2">
                    <div className="rounded bg-dark p-0 col-sm-12">
                        <div className={`d-flex mt-2 ml-1 mb-2 ${this.state.eventListLoader ? 'disabled' : ""}`}>
                            <div className="d-flex">
                                <p className="m-0 align-self-end small mr-2">Show the </p>
                                <div class={`transparentSelectBorderBottom align-self-end`}>
                                    <select class="form-control" 
                                    onChange={this.handleChange}
                                    value={this.state.groupType}
                                    >
                                        <option key="All" value="All">ALL</option>
                                        <option key="important" value="important">Important</option>
                                    </select>
                                </div> 
                                <p className="m-0 align-self-end small mx-2">events groupd by</p>
                                <div class={`transparentSelectBorderBottom align-self-end`}>
                                    <select class="form-control" 
                                        onChange={(event) => {
                                            this.setState({ groupedBy: event.target.value, showGroupLoading: true, selectedCategory: "Important / All", selectedEvent: "", selectedEventId: "", eventListLoader: true },
                                                () => {
                                                    // this.redirectToAnalysis()
                                                    this.getCoveredListsSummary()
                                                }
                                            )
                                        }}
                                        value={this.state.groupedBy}                            
                                        >
                                        {/* <option key="resource_type" value="resource_type">Resource</option> */}
                                        <option key="impact_type" value="impact_type">Impact</option>
                                        {/* <option key="asset_name" value="asset_name">Asset</option> */}
                                        <option key="lifecycle" value="lifecycle">LifeCycle</option>
                                        <option key="asset_category" value="asset_category">Product</option>
                                        <option key="event_source" value="event_source">Source</option>
                                        {/* {this.state.aggregateByArray && this.state.aggregateByArray.map(item => {
                                            return(
                                                <option value={item}>{capitalizeFirstLetter(item)}</option>
                                            )
                                        })} */}
                                    </select>
                                </div>
                            </div>
                            <div className="d-flex ml-2 w-30">
                                <p className="m-0 align-self-end small mx-2">Category</p>
                                {this.state.groupCategory ? 
                                    <div class={`transparentSelectBorderBottom align-self-end`}>
                                        <select className="form-control" 
                                            value={this.state.selectedCategory}
                                            onChange={(event) => {
                                                this.setState({ selectedCategory: event.target.value },
                                                    () => {
                                                        this.getCategory()
                                                    }    
                                                )
                                            }}>
                                            {this.state.groupCategory.map(item => {
                                                return(
                                                <option value={item.category}>{item.category+" "+item.count}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                : null}
                            </div>
                        </div>
                        <div className="d-flex mb-2 justify-content-between">
                            <div className="d-flex mr-2 w-70">
                                <p className="f12 m-0 align-self-center pt-1">Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.state.totalFilteredArray && this.state.totalFilteredArray.length}{this.state.totalFilteredArray && this.state.totalFilteredArray.length > 1 ? ' events' : ' events'}</p>
                                {/* {this.state.totalErrorList && this.state.totalErrorList.length}{this.state.totalErrorList && this.state.totalErrorList.length > 1 ? ' errors' : ' error'} from </p> */}
                                
                                {this.state.zoomedStartDate && this.state.zoomedStartDate !== "" ? 
                                    <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{momentDateGivenFormat(this.state.zoomedStartDate, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.zoomedEndDate, 'DD MMM YYYY HH:mm')}</p>
                                : 
                                    <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{this.state.brushStartTime ? momentDateGivenFormat(this.state.brushStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.brushEndTime, 'DD MMM YYYY HH:mm') : ""}</p>
                                }
                                {/*  */}
                            </div>
                            <div className="d-flex justify-content-end w-30 mb-n2">
                                {this.state.totalFilteredArray && this.state.totalFilteredArray.length ?
                                    <Search
                                        data={this.state.totalFilteredArray ? this.state.totalFilteredArray : []}
                                        applyTags={false}
                                        applyLiteDarkTags={true}
                                        topClassName={'bg-black5 align-self-center w-100 mr-1 border-gray5 rounded-5 f12'}
                                        searchClassName={'px-2 f12'}
                                        searchIconColor={'text-gray5 f12'}
                                        searchPlaceHolder={'Search events....'}
                                        className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                        filteredData={(filteredArray) => {
                                            this.setState({ filteredArray, errorDetails: {}, merticsAssetNameInvolved: [] },
                                                () => {
                                                    if(filteredArray.length) {
                                                        // this.getEventDetails(this.state.filteredArray[0])
                                                    }
                                                }    
                                            )
                                        }}
                                    />
                                : null}
                                {this.state.filteredArray && this.state.filteredArray.length > this.state.perPage ?
                                    <div className="pagination errorPagePagination f12 displayNone">
                                        <span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
                                        <button><i className={`fal fa-arrow-to-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('start', this.state.currentPage)}></i></button> 
                                        <button><i className={`fal fa-angle-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('previous', this.state.currentPage)}></i></button> 
                                        <span>
                                            Go to
                                            <input
                                                type='number'
                                                value={this.state.pageNumber || this.state.pageNumber === "" ? this.state.pageNumber : this.state.currentPage}
                                                onChange={e => {
                                                    const page = e.target.value ? Number(e.target.value) : ""
                                                    this.gotoPage(page)
                                                }}
                                                className="ml-1 inputClass"
                                            />
                                        </span>
                                        <button><i className={`fal fa-angle-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
                                        <button><i className={`fal fa-arrow-to-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
                                    </div>
                                : null}
                            </div>
                        </div>
                        
                        <div className="d-flex">
                            <div className={`w-100`}>
                                <div className={`bg-dark3 py-3 pl-2 ${!this.state.minimizeGraphSection ? "obsMessageSection" : "obsMessageSectionLg"}`}>
                                    {this.state.eventListLoader ? 
                                        <div className='d-flex justify-content-center m-4'>
                                            <Spinner className='text-center' color='white' size='lg' />
                                        </div>
                                    : this.state.filteredArray && this.state.filteredArray.length ?
                                        // this.state.filteredArray.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map((item, i) => {
                                        this.state.filteredArray.map((item, i) => {
                                            return(
                                                <div className={`px-2 mb-2 pb-1 border-bottom-black5 ${this.state.selectedEventId && this.state.selectedEventId === item.event_id ? "bg-muted" : ""}`}>
                                                    <div className="d-flex cursorPointer" onClick={() => this.setState({ showDetailsLoading: true }, () =>this.getEventDetails(item))}>
                                                        <p className="mb-0">
                                                            <span className="mb-0 mr-2 f12 text-primary-color font-weight-bold">{item.event_time ? momentConvertionUtcToLocalTime(item.event_time, 'DD MMM YYYY HH:mm') : ""} </span>
                                                            <span className="mb-0 mr-2 f12 font-weight-bold text-purple-2">Events</span>
                                                            {/* <span className={`mb-0 mr-2 f12 ${item.event_source === 'checks' ? 'text-purple' : item.event_source === 'config' ? 'text-secondary' : (item.event_source === 'cloudtrail' || item.event_source === 'cloudwatch_alarm') ? 'text-info' : 'text-gray3'} font-weight-bold`}>{capitalizeFirstLetter(item.event_source) + (item.event_name ? ' : ' + item.event_name : "") + (item.event_type ? ' : '+item.event_type : "")}</span> */}
                                                            <span className={`mb-0 mr-2 f12 text-purple-2`}>{(item.event_source ? capitalizeFirstLetter(item.event_source) : "") + (item.event_name ? ' : ' + item.event_name : "") + (item.event_type ? ' : '+item.event_type : "")}</span>
                                                            {item.assets && item.assets ? 
                                                                <span className="mb-0 mr-2 f12 text-white font-weight-bold"> 
                                                                    {item.assets.map((ast, astIndex) => (item.assets.length - 1 === astIndex ? (ast.asset_name) : ast.asset_name + ', '))}
                                                                </span>
                                                            : null}
                                                            <span className="mb-0 small mr-1 text-primary-color">Resource</span>
                                                            {item.account_id ?
                                                                <span className="mb-0 f12 text-primary-color font-weight-bold">{' '+item.account_id}</span>
                                                            : null}
                                                            {item.region ?
                                                                <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+item.region}</span>
                                                            : null}
                                                            {item.description ?
                                                                <span className="mb-0 ml-2 f12 text-white font-weight-bold">{item.description}</span>
                                                            : null}
                                                        </p>
                                                    </div>
                                                    {this.state.selectedEventId && this.state.selectedEventId === item.event_id && this.state.showDetailsLoading ?
                                                        <div className='d-flex justify-content-center m-4'>
                                                            <Spinner className='text-center' color='white' size='sm' />
                                                        </div>
                                                    : this.state.selectedEventId && this.state.selectedEventId === item.event_id ?
                                                        <div className="rounded bg-dark3 p-3">
                                                            <div className="accordion" id="accordionExample">
                                                                {this.props.eventDetails && Object.entries(this.props.eventDetails).length && this.state.selectedEvent && Object.entries(this.state.selectedEvent).length ?
                                                                    <React.Fragment>
                                                                    {!Array.isArray(this.state.selectedEvent.event_source) ?
                                                                        <div className={`card bg-dark border-0 mb-2`}>
                                                                            <div id={'heading_event'} onClick={() => this.setState({ expandCommonEventDetails: !this.state.expandCommonEventDetails })}>
                                                                                <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
                                                                                    <div className="text-white"><span className={`far ${this.state.expandCommonEventDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Event Details</div>
                                                                                    {/* <div className="text-info">Showing event details</div> */}
                                                                                </div>
                                                                            </div>
                                                                            <div id={'collapse_event'} className={`collapse ${this.state.expandCommonEventDetails ? 'show' : ""}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
                                                                                <div className="card-body">
                                                                                    <div className="d-flex">
                                                                                        <div className="py-1 w-50">
                                                                                            <p className="b-block mb-0">Name:</p>
                                                                                            <p className="mb-0 text-white">{this.state.selectedEvent.event_name ? this.state.selectedEvent.event_name : ""}</p>
                                                                                        </div>
                                                                                        <div className="py-1 w-50 pl-3">
                                                                                            <p className="b-block mb-0">Id:</p>
                                                                                            <p className="mb-0 text-white">{this.state.selectedEvent.event_id ?this.state.selectedEvent.event_id : ""}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex pt-2 border-top">
                                                                                        <div className="py-1 w-50">
                                                                                            <p className="b-block mb-0">Source:</p>
                                                                                            <p className="mb-0 text-white">{ this.state.selectedEvent.event_source ? (!Array.isArray(this.state.selectedEvent.event_source) ? this.state.selectedEvent.event_source : this.state.selectedEvent.event_source) : ""}</p>
                                                                                        </div>
                                                                                        <div className="py-1 w-50 pl-3">
                                                                                            <p className="b-block mb-0">Time:</p>
                                                                                            <p className="mb-0 text-white">{this.state.selectedEvent.event_time ? momentConvertionUtcToLocalTime(this.state.selectedEvent.event_time, 'DD MMM YYYY HH:mm') : ""}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    {!Array.isArray(this.props.eventDetails.event_source)  ?
                                                                                        this.props.eventDetails.event_source === "aws.rds" ?
                                                                                            this.props.eventDetails.additional_details ?
                                                                                                <div className="d-flex pt-2 border-top">
                                                                                                    <div className="py-1 w-50">
                                                                                                        <p className="b-block mb-0">Event Code:</p>
                                                                                                        <p className="mb-0 text-white">{this.props.eventDetails.additional_details.event_id ? this.props.eventDetails.additional_details.event_id : ""}</p>
                                                                                                    </div>
                                                                                                    <div className="py-1 w-50 pl-3">
                                                                                                        <p className="b-block mb-0">Message:</p>
                                                                                                        <p className="mb-0 text-white">{this.props.eventDetails.additional_details.message ? this.props.eventDetails.additional_details.message : ""}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            : null
                                                                                        : this.props.eventDetails.event_source === "aws.ec2" ?
                                                                                            this.props.eventDetails.additional_details ?
                                                                                                <div className="col-sm-12">
                                                                                                    <div className="row">
                                                                                                    {Object.entries(this.props.eventDetails.additional_details).map(([key, value], addIdx) => {
                                                                                                        return(
                                                                                                            <React.Fragment>
                                                                                                                <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                                                                <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                                                                    <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                                                                    <p className="mb-0 text-white">{value}</p>
                                                                                                                </div>
                                                                                                            </React.Fragment>
                                                                                                        )
                                                                                                    })}
                                                                                                    </div>
                                                                                                </div>
                                                                                            : null
                                                                                        : this.props.eventDetails.event_source === "aws.codecommit" ?
                                                                                            //capitalizeTheFirstLetterOfEachWord(key.replaceAll("_", " "))
                                                                                            this.props.eventDetails.additional_details ?
                                                                                                <div className="col-sm-12">
                                                                                                    <div className="row">
                                                                                                    {Object.entries(this.props.eventDetails.additional_details).map(([key, value], addIdx) => {
                                                                                                        return(
                                                                                                            <React.Fragment>
                                                                                                                <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                                                                <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                                                                    <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                                                                    {key === "user" ?
                                                                                                                        <p className="mb-0 text-white">{value["name"] ? value["name"] : ""}</p>
                                                                                                                    :
                                                                                                                        <p className="mb-0 text-white">{value}</p>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </React.Fragment>
                                                                                                        )
                                                                                                    })}
                                                                                                    </div>
                                                                                                </div>
                                                                                            : null
                                                                                        : this.props.eventDetails.event_source === "aws.codedeploy" ?
                                                                                            //capitalizeTheFirstLetterOfEachWord(key.replaceAll("_", " "))
                                                                                            this.props.eventDetails.additional_details ?
                                                                                                <div className="col-sm-12">
                                                                                                    <div className="row">
                                                                                                    {Object.entries(this.props.eventDetails.additional_details).map(([key, value], addIdx) => {
                                                                                                        return(
                                                                                                            <React.Fragment>
                                                                                                                <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                                                                <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                                                                    <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                                                                    {key === "artifacts" ?
                                                                                                                        <p className="mb-0 text-white">{value["location"] ? value["location"] : ""}</p>
                                                                                                                    :
                                                                                                                        <p className="mb-0 text-white">{value}</p>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </React.Fragment>
                                                                                                        )
                                                                                                    })}
                                                                                                    </div>
                                                                                                </div>
                                                                                            : null
                                                                                        : this.props.eventDetails.event_source === "aws.codebuild" ?
                                                                                            //capitalizeTheFirstLetterOfEachWord(key.replaceAll("_", " "))
                                                                                            this.props.eventDetails.additional_details ?
                                                                                                <div className="col-sm-12">
                                                                                                    <div className="row">
                                                                                                    {Object.entries(this.props.eventDetails.additional_details).map(([key, value], addIdx) => {
                                                                                                        return(
                                                                                                            <React.Fragment>
                                                                                                                <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                                                                <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                                                                    <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                                                                    {key === "artifacts" ?
                                                                                                                        <p className="mb-0 text-white">{value["location"] ? value["location"] : ""}</p>
                                                                                                                    : key === "commit_details" ?
                                                                                                                        <p className="mb-0 text-white">{value["commit_id"] ? value["commit_id"] : ""}</p>
                                                                                                                    : key === "user" ?
                                                                                                                        <p className="mb-0 text-white">{value["name"] ? value["name"] : ""}</p>
                                                                                                                    :
                                                                                                                        <p className="mb-0 text-white">{value}</p>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </React.Fragment>
                                                                                                        )
                                                                                                    })}
                                                                                                    </div>
                                                                                                </div>
                                                                                            : null
                                                                                        : this.props.eventDetails.event_source === "aws.codepipeline" ?
                                                                                            //capitalizeTheFirstLetterOfEachWord(key.replaceAll("_", " "))
                                                                                            this.props.eventDetails.additional_details ?
                                                                                                <div className="col-sm-12">
                                                                                                    <div className="row">
                                                                                                    {Object.entries(this.props.eventDetails.additional_details).map(([key, value], addIdx) => {
                                                                                                        return(
                                                                                                            <React.Fragment>
                                                                                                                <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                                                                <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                                                                    <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                                                                    {key === "deploy_details" ?
                                                                                                                        <p className="mb-0 text-white">{value["deployment_id"] ? value["deployment_id"] : ""}</p>
                                                                                                                    :
                                                                                                                        <p className="mb-0 text-white">{value}</p>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </React.Fragment>
                                                                                                        )
                                                                                                    })}
                                                                                                    </div>
                                                                                                </div>
                                                                                            : null
                                                                                        : this.props.eventDetails.event_source === "rds_audit_log" ?
                                                                                            //capitalizeTheFirstLetterOfEachWord(key.replaceAll("_", " "))
                                                                                            this.props.eventDetails.additional_details ?
                                                                                                <div className="col-sm-12">
                                                                                                    <div className="row">
                                                                                                    {Object.entries(this.props.eventDetails.additional_details).map(([key, value], addIdx) => {
                                                                                                        return(
                                                                                                            <React.Fragment>
                                                                                                                <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                                                                <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                                                                    <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                                                                    {key === "user" ?
                                                                                                                        <p className="mb-0 text-white">{value["name"] ? value["name"] : ""}</p>
                                                                                                                    :
                                                                                                                        <p className="mb-0 text-white">{value}</p>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </React.Fragment>
                                                                                                        )
                                                                                                    })}
                                                                                                    </div>
                                                                                                </div>
                                                                                            : null
                                                                                        : this.props.eventDetails.event_source === "rds_slow_log" ?
                                                                                            //capitalizeTheFirstLetterOfEachWord(key.replaceAll("_", " "))
                                                                                            this.props.eventDetails.additional_details ?
                                                                                                <div className="col-sm-12">
                                                                                                    <div className="row">
                                                                                                    {Object.entries(this.props.eventDetails.additional_details).map(([key, value], addIdx) => {
                                                                                                        return(
                                                                                                            <React.Fragment>
                                                                                                                <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                                                                <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                                                                    <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                                                                    {key === "user" ?
                                                                                                                        <p className="mb-0 text-white">{value["name"] ? value["name"] : ""}</p>
                                                                                                                    :
                                                                                                                        <p className="mb-0 text-white">{value}</p>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </React.Fragment>
                                                                                                        )
                                                                                                    })}
                                                                                                    </div>
                                                                                                </div>
                                                                                            : null
                                                                                        : this.props.eventDetails.event_source === "config" ?
                                                                                            <React.Fragment>
                                                                                            {this.state.selectedEvent.configuration_status ?
                                                                                                <div className="d-flex pt-2 border-top">
                                                                                                    <div className="py-1 w-50">
                                                                                                        <p className="b-block mb-0">Configuration Status:</p>
                                                                                                        <p className="mb-0 text-white">{this.state.selectedEvent.configuration_status}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            : null}
                                                                                            
                                                                                            {this.props.eventDetails.additional_details && Object.entries(this.props.eventDetails.additional_details).length && this.props.eventDetails.additional_details.changes ? 
                                                                                                <div className={`pt-2 border-top w-100`}>
                                                                                                    <p className="b-block mb-2">Changes:</p>
                                                                                                    {this.props.eventDetails.additional_details.changes.map(confCng => {
                                                                                                        return(
                                                                                                            <p className="mb-3 text-white"><span className="legend-circle mr-1 bg-info"></span>{confCng.description}:</p>
                                                                                                        )
                                                                                                    })}
                                                                                                </div>
                                                                                            : null}
                                                                                            </React.Fragment>
                                                                                        : this.props.eventDetails.event_source === "checks" ?
                                                                                            <React.Fragment>
                                                                                            <div className="d-flex pt-2 border-top">
                                                                                                <div className="py-1 w-50">
                                                                                                    <p className="b-block mb-0">Title:</p>
                                                                                                    <p className="mb-0 text-white">{ this.state.selectedEvent.title ? this.state.selectedEvent.title : ""}</p>
                                                                                                </div>
                                                                                                <div className="py-1 w-50 pl-3">
                                                                                                    <p className="b-block mb-0">Detail:</p>
                                                                                                    <p className="mb-0 text-white">{ this.state.selectedEvent.detail ? this.state.selectedEvent.detail : ""}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="d-flex pt-2 border-top">
                                                                                                <div className="py-1 w-50">
                                                                                                    <p className="b-block mb-0">Severity:</p>
                                                                                                    {this.props.eventDetails.risk ?
                                                                                                        <small className={`mr-1 badge risk-badge-${this.props.eventDetails.risk.toLowerCase()}`}>
                                                                                                        {this.props.eventDetails.risk}
                                                                                                        </small>
                                                                                                    : null
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="py-1 w-50 pl-3">
                                                                                                    <p className="b-block mb-0">Status:</p>
                                                                                                    {this.props.eventDetails.status ?
                                                                                                        <small className={`mr-1 badge ${this.props.eventDetails.status === 'pass' ? 'badge-pass' : 'badge-fail'}`}>
                                                                                                        {this.props.eventDetails.status}
                                                                                                        </small>
                                                                                                    : null
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            {this.props.eventDetails.resolution_status_info ?
                                                                                                <div className="col-sm-12">
                                                                                                    <div className="row">
                                                                                                    {Object.entries(this.props.eventDetails.resolution_status_info).map(([key, value], addIdx) => {
                                                                                                        return(
                                                                                                            <React.Fragment>
                                                                                                                <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                                                                <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                                                                    <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                                                                    {key === "timestamp" ?
                                                                                                                        <p className="mb-0 text-white">{value ? momentConvertionUtcToLocalTime(value, 'DD MMM YYYY HH:mm') : ""}</p>
                                                                                                                    :
                                                                                                                        <p className="mb-0 text-white">{value}</p>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </React.Fragment>
                                                                                                        )
                                                                                                    })}
                                                                                                    </div>
                                                                                                </div>
                                                                                            : null}
                                                                                            </React.Fragment>
                                                                                        : null
                                                                                    : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    : null}                                
                                    
                                                                    {this.state.selectedEvent.event_source && !Array.isArray(this.state.selectedEvent.event_source) && this.state.selectedEvent.event_source === "cloudtrail" ?
                                                                        <React.Fragment>
                                                                            {this.props.eventDetails.user_identity ?
                                                                                <div className={`card bg-dark border-0 mb-2`}>
                                                                                    <div id={'heading_user'} onClick={() => this.setState({ expandUserIdentity: !this.state.expandUserIdentity })}>
                                                                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_user'}aria-expanded="true" aria-controls={'collapse_user'}>
                                                                                            <div className="text-white"><span className={`far ${this.state.expandUserIdentity ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>User Details</div>
                                                                                            {/* <div className="text-info">Showing user details</div> */}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div id={'collapse_user'} className={`collapse ${this.state.expandUserIdentity ? 'show' : ""}`} aria-labelledby={'heading_user'} data-parent="#accordionExample">
                                                                                        <div className="card-body">
                                                                                            <div className="d-flex mb-2">
                                                                                                <div className="py-1 w-50">
                                                                                                    <p className="b-block mb-0">Name:</p>
                                                                                                    <p className="mb-0 text-white">{this.props.eventDetails.user_identity.name ? this.props.eventDetails.user_identity.name  : ""}</p>
                                                                                                </div>
                                                                                                <div className="py-1 w-50 pl-3">
                                                                                                    <p className="b-block mb-0">Type:</p>
                                                                                                    <p className="mb-0 text-white">{this.props.eventDetails.user_identity.type ?this.props.eventDetails.user_identity.type : ""}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            : null}
                                                                            
                                                                            {this.props.eventDetails.accessed_from ?
                                                                                <div className={`card bg-dark border-0 mb-2`}>
                                                                                    <div id={'heading_access'} onClick={() => this.setState({ expandAccessDetails: !this.state.expandAccessDetails })}>
                                                                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_access'}aria-expanded="true" aria-controls={'collapse_access'}>
                                                                                            <div className="text-white"><span className={`far ${this.state.expandAccessDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Access Details</div>
                                                                                            {/* <div className="text-info">Showing access details</div> */}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div id={'collapse_access'} className={`collapse ${this.state.expandAccessDetails ? 'show' : ""}`} aria-labelledby={'heading_access'} data-parent="#accordionExample">
                                                                                        <div className="card-body">
                                                                                            <div className="d-flex mb-2 border-bottom">
                                                                                                <div className="py-1 w-50">
                                                                                                    <p className="b-block mb-0">Access Mode:</p>
                                                                                                    <p className="mb-0 text-white">{this.props.eventDetails.accessed_from.access_mode ? this.props.eventDetails.accessed_from.access_mode : ""}</p>
                                                                                                </div>
                                                                                                <div className="py-1 w-50 pl-3">
                                                                                                    <p className="b-block mb-0">ip:</p>
                                                                                                    <p className="mb-0 text-white">{this.props.eventDetails.accessed_from.ip ?this.props.eventDetails.accessed_from.ip : ""}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="d-flex mb-2">
                                                                                                <div className="py-1 w-100">
                                                                                                    <p className="b-block mb-0">User Agent:</p>
                                                                                                    <p className="mb-0 text-white">{this.props.eventDetails.accessed_from.user_agent ? this.props.eventDetails.accessed_from.user_agent : ""}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            : null}
                                                                        </React.Fragment>
                                                                    : null}
                                    
                                                                    {this.props.eventDetails.tags && this.props.eventDetails.tags.length ?
                                                                        <div className={`card bg-dark border-0 mb-2`}>
                                                                            <div id={'heading_config_tags'} onClick={() => this.setState({ expandTagDetails: !this.state.expandTagDetails })}>
                                                                                <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_tags'}aria-expanded="true" aria-controls={'collapse_config_tags'}>
                                                                                    <div className="text-white"><span className={`far ${this.state.expandTagDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Tag Details</div>
                                                                                    {/* <div className="text-info">Showing tag details</div> */}
                                                                                </div>
                                                                            </div>
                                                                            <div id={'collapse_config_tags'} className={`collapse ${this.state.expandTagDetails ? 'show' : ""}`} aria-labelledby={'heading_config_tags'} data-parent="#accordionExample">
                                                                                <div className="card-body">
                                                                                    <ul className='tags my-1 px-3'>
                                                                                        {this.props.eventDetails.tags.map((tag, index) => {
                                                                                            return (
                                                                                                <li key={index}>
                                                                                                    <span className='tag f14'>{tag.key+': '+tag.value}</span>
                                                                                                </li>
                                                                                            )
                                                                                        })}
                                                                                    </ul>
                                                                                    {/* <div className={`d-flex flex-wrap`}>
                                                                                        {this.props.eventDetails.tags.map(item => {
                                                                                            return (
                                                                                                <small className={`mr-1 mb-1 badge badge-light`}>{item.key+' : '+item.value}</small> 
                                                                                            )
                                                                                        })}
                                                                                    </div> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    : null}
                                    
                                                                    {this.state.selectedEvent.assets && this.state.selectedEvent.assets.length ?
                                                                        <div className={`card bg-dark border-0 mb-2`}>
                                                                            <div id={'heading_config_assets'} onClick={() => this.setState({ expandPropsAssetDetails: !this.state.expandPropsAssetDetails })}>
                                                                                <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                                                                    <div className="text-white"><span className={`far ${this.state.expandPropsAssetDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Asset Details</div>
                                                                                    {/* <div className="text-info">Showing asset details</div> */}
                                                                                </div>
                                                                            </div>
                                                                            <div id={'collapse_config_assets'} className={`collapse ${this.state.expandPropsAssetDetails ? 'show' : ""}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                                                                {this.state.selectedEvent.assets.map(item => {
                                                                                    return(
                                                                                    <div className="card-body m-2 pt-2 rounded counts_threads">
                                                                                        <div className="d-flex mb-2">
                                                                                            <div className="py-1 w-50">
                                                                                                <p className="b-block mb-0">Name:</p>
                                                                                                <p className="mb-0 text-white">{item.asset_name ? item.asset_name : ""}</p>
                                                                                            </div>
                                                                                            <div className="py-1 w-50 pl-3">
                                                                                                <p className="b-block mb-0">Id:</p>
                                                                                                <p className="mb-0 text-white">{item.asset_id ? item.asset_id : ""}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="d-flex mb-2 border-top">
                                                                                            <div className="py-1 w-50">
                                                                                                <p className="b-block mb-0">Resource:</p>
                                                                                                <p className="mb-0 text-white">
                                                                                                    {this.state.selectedEvent.provider ? this.state.selectedEvent.provider.toUpperCase() : ""}
                                                                                                    {this.state.selectedEvent.account_id ? (" : ")+this.state.selectedEvent.account_id : ""}
                                                                                                    {this.state.selectedEvent.region ? (" : ")+this.state.selectedEvent.region : ""}
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className="py-1 w-50 pl-3">
                                                                                                <p className="b-block mb-0">Services:</p>
                                                                                                <p className="mb-0 text-white">
                                                                                                    {item.resource_type ? item.resource_type : ""}
                                                                                                    {item.service_name ? (" : ")+item.service_name : ""}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>                                                        
                                                                                        <div className="d-flex mb-2 border-top">
                                                                                            <div className="py-1 w-100">
                                                                                                <p className="b-block mb-0">ARN:</p>
                                                                                                <p className="mb-0 text-white">{item.asset_arn ?item.asset_arn : <span>&nbsp;</span>}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        {item.category ? 
                                                                                            <div className="d-flex mb-2 border-top">
                                                                                                <div className="py-1 w-100">
                                                                                                    <p className="b-block mb-0">Category:</p>
                                                                                                    <div className={`d-flex flex-wrap`}>
                                                                                                        {item.category.map(item => {
                                                                                                            return (
                                                                                                                <small className={`mr-1 mb-1 badge badge-light`}>{item}</small> 
                                                                                                            )
                                                                                                        })}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        : null}
                                                                                    </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    : this.props.eventDetails.assets && this.props.eventDetails.assets.length ?
                                                                        <div className={`card bg-dark border-0 mb-2`}>
                                                                            <div id={'heading_config_assets'} onClick={() => this.setState({ expandEventAssetDetails: !this.state.expandEventAssetDetails })}>
                                                                                <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                                                                    <div className="text-white"><span className={`far ${this.state.expandEventAssetDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Asset Details</div>
                                                                                    {/* <div className="text-info">Showing asset details</div> */}
                                                                                </div>
                                                                            </div>                                        
                                                                            <div id={'collapse_config_assets'} className={`collapse ${this.state.expandEventAssetDetails ? 'show' : ""}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                                                                {this.props.eventDetails.assets.map(item => {
                                                                                    return(
                                                                                    <div className="card-body m-2 pt-2 rounded counts_threads">
                                                                                        <div className="d-flex mb-2 border-bottom">
                                                                                            <div className="py-1 w-50">
                                                                                                <p className="b-block mb-0">Name:</p>
                                                                                                <p className="mb-0 text-white">{item.asset_name ? item.asset_name : ""}</p>
                                                                                            </div>
                                                                                            <div className="py-1 w-50 pl-3">
                                                                                                <p className="b-block mb-0">Id:</p>
                                                                                                <p className="mb-0 text-white">{item.asset_id ? item.asset_id : ""}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="d-flex mb-2">
                                                                                            <div className="py-1 w-50">
                                                                                                <p className="b-block mb-0">Resource:</p>
                                                                                                <p className="mb-0 text-white">
                                                                                                    {this.state.selectedEvent.provider ? this.state.selectedEvent.provider.toUpperCase() : ""}
                                                                                                    {this.state.selectedEvent.account_id ? (" : ")+this.state.selectedEvent.account_id : ""}
                                                                                                    {this.state.selectedEvent.region ? (" : ")+this.state.selectedEvent.region : ""}
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className="py-1 w-50 pl-3">
                                                                                                <p className="b-block mb-0">Services:</p>
                                                                                                <p className="mb-0 text-white">
                                                                                                    {item.resource_type ? item.resource_type : ""}
                                                                                                    {item.service_name ? (" : ")+item.service_name : ""}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="d-flex mb-2 border-bottom">
                                                                                            <div className="py-1 w-100">
                                                                                                <p className="b-block mb-0">ARN:</p>
                                                                                                <p className="mb-0 text-white">{item.asset_arn ?item.asset_arn : ""}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        {item.category ? 
                                                                                            <div className="d-flex mb-2 border-bottom">
                                                                                                <div className="py-1 w-100">
                                                                                                    <p className="b-block mb-0">Category:</p>
                                                                                                    <div className={`d-flex flex-wrap`}>
                                                                                                        {item.category.map(item => {
                                                                                                            return (
                                                                                                                <small className={`mr-1 mb-1 badge badge-light`}>{item}</small> 
                                                                                                            )
                                                                                                        })}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        : null}
                                                                                    </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    : null}
                                                                    </React.Fragment>
                                                                : 
                                                                    <div className='col-md-12 d-flex justify-content-center m-4'>
                                                                        <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    : null}
                                                </div>
                                            )
                                        })
                                    :
                                        <div className='d-flex justify-content-center m-4'>
                                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
EventsTab.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('errorDashboard',state)
	return {
        eventDetails: state.aiops.eventDetails,

        microSericesFilters: state.observability.microServiesPropsDetails.microSericesFilters ? state.observability.microServiesPropsDetails.microSericesFilters : {}
    }
}

export default connect(mapStateToProps, {
    getCoveredTrendsSummary,
    getCoveredListsSummary,
    setAiopsPropsDetails,
    getEventDetails
})(withRouter(EventsTab))