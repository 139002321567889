/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file CreatePolicy.js
 * @author Rajasekar // on 22/04/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AppWrapper } from '../../common/AppWrapper'
import {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
	listAllServices,
	showNotification,
} from '../../../actions/commonAction'
import {
	setCreatePolicyDetails,
	listIamAssets,
	checkPolicyName,
	generatePolicy,
	createPolicy,
} from '../../../actions/governance/IamAction'
import { Input, Spinner } from 'reactstrap'
import Select from 'react-select'
import Policy from './Policy'
import { ERROR, SUCCESS } from '../../../utils/constants'
import { URL_PATH } from '../../../config/urlPath'
import SidePanel from './SidePanel'
import _ from 'lodash'
import { getAccountNameFromId } from '../../../utils/utility'

class CreatePolicy extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showSidePanel: false,
			generatingPolicy: false,
			generated: {},
			creatingPolicy: false,
			keyupTimer: undefined,
			policyNameAvailable: false,
			isEditMode: false,
		}
	}

	componentDidMount = () => {
		let paths = this.props.location.pathname.split('/')
		if (Object.keys(this.props.createPolicyDetails).length === 0) {
			if (paths.includes('edit') && Object.keys(this.props.createPolicyDetails).length === 0) {
				this.props.history.push(URL_PATH.GOVERNANCE_LIST_POLICIES)
			}
		}
		if (paths.includes('edit')) {
			this.setState({ policyNameAvailable: true, isEditMode: true })
		}
		this.props.listAllProviders(promise => {
			if (promise) {
				if (this.props.providers.length === 1) {
					this.setState({ selectedProvider: this.props.providers[0].provider_name.toLowerCase() }, 
						() => {
							this.props.setCreatePolicyDetails('provider', this.state.selectedProvider)
							this.onSelectProvider()
						}
					)
				}
			}
		})
	}

	onSelectProvider = () => {
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'all') {
			params.provider = this.state.selectedProvider
		}
		this.props.listAllAccounts(params, (promise) => {
			if (promise) {
				if (this.props.accounts.length === 1) {
					this.setState({ selectedAccount: this.props.accounts[0].account_id.toLowerCase() }, 
						() => {
							this.props.setCreatePolicyDetails('account_id', this.state.selectedAccount)
							this.onSelectAccount(this.state.selectedAccount)
						}
					)
				}
			}
		})
		this.props.listAllServices(params, () => {})
	}

	onSelectAccount = account_id => {
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'all') {
			params.provider = this.state.selectedProvider && this.state.selectedProvider.toLowerCase()
		}
		params.account_id = account_id
		this.props.listIamAssets({ ...params, service_name: 'iam-roles' }, 'roles', () => {})
		this.props.listIamAssets({ ...params, service_name: 'iam-users' }, 'users', () => {})
		this.checkPolicyName(account_id)
	}

	checkPolicyName = account_id => {
		this.props.checkPolicyName(
			{
				account_id: account_id,
				policy_name: this.props.createPolicyDetails.policy_name,
			},
			(success, res) => {
				if (success) this.setState({ policyNameAvailable: true })
				if (!success) {
					this.props.showNotification(ERROR, res ? res.message : 'Invalid policy name.')
					this.setState({ policyNameAvailable: false })
				}
			}
		)
	}

	handleChange = (event, checkPolicyName) => {
		this.props.setCreatePolicyDetails(event.target.name, event.target.value)
		if (checkPolicyName) {
			if (this.state.keyupTimer) {
				clearTimeout(this.state.keyupTimer)
			}
			let keyupTimer = setTimeout(() => {
				this.setState({ isLoading: true })
				if (this.props.createPolicyDetails.account_id && this.props.createPolicyDetails.account_id !== '') {
					this.checkPolicyName(this.props.createPolicyDetails.account_id)
				}
			}, 1000)
			this.setState({
				keyupTimer,
			})
		}
	}

	handleMultiSelectChange = (key, childKey, arrayValue) => {
		let params = { ...this.props.createPolicyDetails.iam_identities, roles: [], users: [] }
		params[childKey] = arrayValue.map(item => item.value)
		this.props.setCreatePolicyDetails(key, params)
	}

	onClickAddPolicy = () => {
		if (
			!this.props.createPolicyDetails.policy_name ||
			!this.props.createPolicyDetails.provider ||
			!this.props.createPolicyDetails.description ||
			!this.props.createPolicyDetails.account_id
		) {
			this.props.showNotification(ERROR, 'Please fill out all fields.')
		} else {
			let data = this.props.createPolicyDetails.statements_to_generate
			if (data) {
				data.map(item => (item.isOpen = false))
				data.push({
					isOpen: true,
					service_name: '',
					categories: [{ category: '', usecases: [] }],
					condition_to_check: {
						global: [],
						specific: [],
						RequestTags: [],
						ResourceTags: [],
						TagKeys: [],
					},
				})
				this.props.setCreatePolicyDetails('statements_to_generate', data)
			} else {
				let temp = [
					{
						isOpen: true,
						service_name: '',
						categories: [],
						condition_to_check: {
							global: [],
							specific: [],
							RequestTags: [],
							ResourceTags: [],
							TagKeys: [],
						},
					},
				]
				this.props.setCreatePolicyDetails('statements_to_generate', temp)
			}
		}
	}

	onOpenPoilcy = index => {
		let data = this.props.createPolicyDetails.statements_to_generate
		if (data) {
			data.map((item, i) => (index === i ? (item.isOpen = !item.isOpen) : null))
			this.props.setCreatePolicyDetails('statements_to_generate', data)
		}
	}

	onClickGeneratePolicy = () => {
		let usecaseSelected = false
		this.props.createPolicyDetails && this.props.createPolicyDetails.statements_to_generate && this.props.createPolicyDetails.statements_to_generate.forEach(statement => {
			statement.categories.forEach(item => {
				if (item.usecases.length) {
					usecaseSelected = true
				}
			})
		})
		if (usecaseSelected) {
			this.setState({ generatingPolicy: true })
			let arr = this.props.createPolicyDetails.statements_to_generate
			arr.map(item => delete item['isOpen'])
			arr.forEach(statement => {
				_.remove(statement.categories, item => item.usecases.length === 0)
			})
			this.props.setCreatePolicyDetails('statements_to_generate', arr)
			this.props.generatePolicy(this.props.createPolicyDetails, (success, res) => {
				this.setState({ generatingPolicy: false })
				if (success) {
					this.setState({ showSidePanel: true, generated: res })
				} else {
					this.props.showNotification(ERROR, 'Unable to generate policy.')
				}
			})
		} else {
			this.props.showNotification(ERROR, 'Please select atleast one usecase in categories.')
		}
	}

	createPolicy = () => {
		this.setState({ creatingPolicy: true })
		let params = { ...this.props.createPolicyDetails, ...this.state.generated }
		this.props.createPolicy(params, success => {
			this.setState({ creatingPolicy: false })
			if (success) {
				this.props.showNotification(SUCCESS, 'Policy created Successfully.')
				this.props.history.push(URL_PATH.GOVERNANCE_LIST_POLICIES)
			} else {
				this.props.showNotification(ERROR, 'Error in creating policy.')
			}
		})
	}

	render() {
		return (
			<div className='container-fluid overflow-auto flex-grow-1 bg-muted'>
				{this.state.showSidePanel ? (
					<SidePanel
						generated={this.state.generated}
						creatingPolicy={this.state.creatingPolicy}
						onClickCreatePolicy={() => this.createPolicy()}
						closeSidePanel={() => this.setState({ showSidePanel: false })}
					/>
				) : null}
				<div className='row h-100'>
					<div className='col-sm-12 p-0'>
						<div className='title-section pb-2'>
							<div className='row'>
								<div className='col-12 d-flex align-self-center'>
									{this.state.isEditMode ? (
										<>
											<h6 className='text-white'>Name : {this.props.createPolicyDetails.policy_name}</h6>
											<h6 className='text-white ml-4'>ARN : {this.props.createPolicyDetails.policy_arn}</h6>
										</>
									) : (
										<h6 className='text-white'>Create Policy</h6>
									)}
								</div>
							</div>
						</div>
						<div className="container-fluid m-2 rounded h-100">
							<div className="bg-dark p-3 rounded">
								<div className='row'>
									<div className='col-12'>
										<div className='row'>
											<div className='col-sm-9'>
												<div className="bg-dark3 p-3 rounded">
													<h4 className="text-info">General Information</h4>
													<p className='text-muted small'>
														Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim proident et.
														Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim proident et.
													</p>
													<div className='row zapInputDark'>
														<div className='col-4'>
															<div className='form-group'>
																<label>Policy Name</label>
																<Input
																	type='text'
																	name='policy_name'
																	value={this.props.createPolicyDetails.policy_name}
																	onChange={e => {
																		this.handleChange(e, true)
																	}}
																	className='inputTextbox'
																	placeholder='Enter policy name'
																	disabled={this.state.isEditMode}
																/>
															</div>
														</div>
														<div className='col-4'>
															<div className='form-group'>
																<label>Provider</label>
																<Select
																	placeholder={"All"}
																	isSearchable={false}
																	// menuIsOpen={true}
																	//onFocus={this.openProivderMenu}
																	// className="f13 p-0"
																	value={({
																		value: this.props.createPolicyDetails.provider,
																		label: this.props.createPolicyDetails.provider ? this.props.createPolicyDetails.provider.toUpperCase() : <span className="placeholder">Select</span>
																	})}
																	options={this.props.providers && this.props.providers.map(item => ({
																		value: item.provider_name,
																		label: item.provider_name,	
																	}))}
																	disabled={this.state.isEditMode}
																	onChange={e => {
																		this.props.setCreatePolicyDetails("provider", e.value)
																		this.onSelectProvider()
																	}}
																/>
															</div>
														</div>
														<div className='col-4'>
															<div className='form-group'>
																<label>Account</label>
																<Select
																	placeholder={"All"}
																	isSearchable={false}
																	// menuIsOpen={true}
																	//onFocus={this.openProivderMenu}
																	// className="f13 p-0"
																	value={({
																		value: this.props.createPolicyDetails.account_id,
																		label: this.props.createPolicyDetails.account_id ? getAccountNameFromId(this.props.createPolicyDetails.account_id, this.props.accounts) : <span className="placeholder">Select</span>
																	})}
																	options={this.props.accounts && this.props.accounts.map(item => ({
																		value: item.account_id,
																		label: item.account_name,	
																	}))}
																	disabled={this.state.isEditMode}
																	onChange={e => this.setState({ selectedAccount: e.value },
																		() => {
																			this.props.setCreatePolicyDetails("account_id", e.value)
																			this.onSelectAccount(this.state.selectedAccount)
																		}
																	)}
																/>
															</div>
														</div>
														<div className='col-12'>
															<div className='form-group'>
																<label>Description</label>
																<Input
																	type='textarea'
																	rows={3}
																	name='description'
																	value={this.props.createPolicyDetails.description}
																	onChange={e => {
																		this.handleChange(e)
																	}}
																	className='inputTextbox'
																	placeholder='Enter policy description'
																/>
															</div>
														</div>
													</div>
												</div>
												<div className="bg-dark3 p-3 mt-3 rounded">
													<div className='row'>
														<div className='col-sm-8'>
															<h4 className="text-info">Policies</h4>
															<p className='text-muted small'>
																Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim proident et.
																Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim proident et.
															</p>
														</div>
														<div className='col-sm-4 text-right'>
															<button
																onClick={() => {
																	this.onClickAddPolicy()
																}}
																className='btn btn-link'
																// disabled={!this.state.policyNameAvailable}
															>
																ADD POLICY
															</button>
														</div>
													</div>
													<div className='row'>
														<div className='col-12'>
															{this.props.createPolicyDetails.statements_to_generate &&
																this.props.createPolicyDetails.statements_to_generate.map((policy, i) => {
																	return (
																		<Policy
																			index={i}
																			key={i}
																			policy={policy}
																			onOpen={index => this.onOpenPoilcy(index)}
																		/>
																	)
																})}
														</div>
													</div>
												</div>
											</div>
											<div className='col-sm-3 pl-0 zapInputDark'>
												<div className="bg-dark3 p-3 rounded">
													<h4 className="text-info">Resources</h4>
													<p className='text-muted small'>
														Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim proident et.
														Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim proident et.
													</p>
													<div className='form-group darkThemeSelectMulti'>
														<label>User</label>
														<div className='small text-muted'>
															Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim proident et.
														</div>
														<Select
															filterOption={null}
															onChange={arr => {
																this.handleMultiSelectChange('iam_identities', 'users', arr ? arr : [])
															}}
															options={this.props.iamUsers.map(asset => ({
																value: asset.asset_name,
																label: asset.asset_name,
															}))}
															value={
																this.props.createPolicyDetails.iam_identities &&
																this.props.createPolicyDetails.iam_identities.users &&
																this.props.createPolicyDetails.iam_identities.users.length &&
																this.props.createPolicyDetails.iam_identities.users.map(user => ({
																	value: user,
																	label: user,
																}))
															}
															isMulti
															classNamePrefix='Select Users'
															className='basic-multi-select text-dark mt-2'
															name='basic-multi-select1'
														/>
													</div>
													<div className='form-group darkThemeSelectMulti'>
														<label>Roles</label>
														<div className='small text-muted'>
															Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim proident et.
														</div>
														<Select
															filterOption={null}
															onChange={arr => {
																this.handleMultiSelectChange('iam_identities', 'roles', arr ? arr : [])
															}}
															options={this.props.iamRoles.map(asset => ({
																value: asset.asset_name,
																label: asset.asset_name,
															}))}
															value={
																this.props.createPolicyDetails.iam_identities &&
																this.props.createPolicyDetails.iam_identities.roles &&
																this.props.createPolicyDetails.iam_identities.roles.map(role => ({
																	value: role,
																	label: role,
																}))
															}
															isMulti
															classNamePrefix='Select Roles'
															className='basic-multi-select text-dark mt-2'
															name='basic-multi-select2'
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-12'>
											<hr />
											<button
												className='btn btn-link mr-3'
												onClick={() => this.props.history.push(URL_PATH.GOVERNANCE_LIST_POLICIES)}
											>
												Cancel
											</button>
											<button
												className='btn btn-primary'
												disabled={this.state.generatingPolicy}
												onClick={() => this.onClickGeneratePolicy()}
											>
												Generate Policy
												{this.state.generatingPolicy ? <Spinner size='sm' color='dark' className='ml-2' /> : null}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
CreatePolicy.propTypes = {
	listAllProviders: PropTypes.func,
	history: PropTypes.array,
	listAllAccounts: PropTypes.func,
	listAllRegions: PropTypes.func,
	listAllServices: PropTypes.func,
	listIamAssets: PropTypes.func,
	checkPolicyName: PropTypes.func,
	showNotification: PropTypes.func,
	setCreatePolicyDetails: PropTypes.func,
	generatePolicy: PropTypes.func,
	createPolicy: PropTypes.func,
	createPolicyDetails: PropTypes.object,
	location: PropTypes.object,
	providers: PropTypes.array,
	accounts: PropTypes.array,
	services: PropTypes.array,
	regions: PropTypes.array,
	iamRoles: PropTypes.array,
	iamUsers: PropTypes.array,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		providers: state.filters.providers,
		services: state.filters.services,
		accounts: state.filters.accounts,
		iamRoles: state.iam.iamRoles ? state.iam.iamRoles.results : [],
		iamUsers: state.iam.iamUsers ? state.iam.iamUsers.results : [],
		createPolicyDetails: state.iam.createPolicyDetails,
	}
}

export default AppWrapper(CreatePolicy, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllServices,
	listAllRegions,
	listIamAssets,
	setCreatePolicyDetails,
	showNotification,
	createPolicy,
	checkPolicyName,
	generatePolicy,
})
