import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Checkbox from '../../../common/formFields/Checkbox'
import TextField from '../../../common/formFields/TextField'
import { apiToDevCall } from '../../../../actions/commonAction'
import { setAiopsPropsDetails } from '../../../../actions/aiops/AiopsAction'
import { propTypes } from 'react-notification-system/dist/NotificationContainer'

class ModifyVolume extends Component {

  constructor(props) {
    super(props);
    this.state = props || {};
    this.state = {
      validationMessage: '',
      parentValue: this.props.value,
    }
    this.updateFormFieldValue = this.updateFormFieldValue.bind(this);
  }

  componentDidMount = () => {}

  updateFormFieldValue = (fieldName, fieldValue) => {
    let obj = this.props.aiopsRemediationParams
    obj[fieldName] = fieldValue
    this.props.setAiopsPropsDetails('remediationParams', obj)
    this.setState({ [fieldName]: fieldValue }, () => {
      this.props.formInputState()
    });
  };

  render() {
    return (
      this.props.selectedRecord && this.props.selectedRecord.parameters.map((item, index) => {
        return (
        <div className="row mt-2">
          <div className="col-md-12">
            <div className="form-group">
              <div className="d-flex justify-content-between">
                  {item.component !== 'checkbox' ? 
                      <label>{item.label}</label>
                  : null}
                  {item.component === 'multiple_textbox' ?
                      <small className="textInfo cursorPointer" onClick={() => this.addTextbox(item.name, item.label, index) }>Add New Row</small>
                  : null }
              </div>
              {item.component === 'textbox' ?
                <div style={{ display: "inline" }}>
                  <TextField
                    className="form-control bg-muted text-gray3 col-sm-3"
                    labelClassName={'text-gray3'}
                    recordSelection={this.updateFormFieldValue}
                    value={this.state[item.name+'_'+this.props.selectedRecord.processor_rule_id+'_'+index] ? this.state[item.name+'_'+this.props.selectedRecord.processor_rule_id+'_'+index] : ''}
                    name={[item.name+'_'+this.props.selectedRecord.processor_rule_id+'_'+index]}
                    label="First Field"
                    placeholder=""
                    required={item.mandatory ? true : false}
                    unitLabel = {item.unit ? item.unit : ''}
                    validation = {item.validation ? item.validation : {}}
                  />
                </div>
              : item.component === 'checkbox' ?
                <Checkbox 
                    className="form-check-input"
                    labelClassName={'text-white'}
                    recordSelection={this.updateFormFieldValue}
                    value={this.state[item.name+'_'+this.props.selectedRecord.processor_rule_id+'_'+index] ? true : false}
                    name={item.name+'_'+this.props.selectedRecord.processor_rule_id+'_'+index}
                    label={item.label ? item.label : ''}
                />
              : null }
            </div>
          </div>
        </div>
        )
      })
    )
  }
};

/**
 * Type of the props used in the component
 */
 ModifyVolume.propTypes = {
  apiToDevCall: PropTypes.func,
  setAiopsPropsDetails: PropTypes.func,
  formInputState: propTypes.func
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
 const mapStateToProps = state => {	
	// console.log('mapStateToProps', state)  
	return {
		accounts: state.filters.accounts,
        aiopsRemediationParams: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.remediationParams ? state.aiops.aiopsPropsDetails.remediationParams : {},
    }
}


export default connect(mapStateToProps, {
  apiToDevCall, 
  setAiopsPropsDetails
})(withRouter(ModifyVolume))
