/*************************************************
 * Tvastar
 * @exports
 * @file CostAction.js
 * @author parakash // on 3-02-2023
 * @copyright © 20214Tvastar. All rights reserved.
 *************************************************/
// import cognitoService from '../../services/cognitoService'
import { numberFormater, capitalizeFirstLetter } from '../../utils/utility'
import { API } from 'aws-amplify'

import {
	COST_SERVICE,
	GET_COST_QUERIES,
	GET_COST_QUERY_RESULTS
} from '../../config'

import { ACTION_TYPES } from '../types'

/**
 * Action to get-cost-queries
 * @param {Object} body
 * @param {Function} callback
 */
export const getCostQueries = (body, callback) => {
	return async () => {
		await API.post(COST_SERVICE, GET_COST_QUERIES, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to get-cost-query-results
 * @param {Object} body
 * @param {Function} callback
 */
export const getCostQueryResults = (body, callback) => {
	return async () => {
		await API.post(COST_SERVICE, GET_COST_QUERY_RESULTS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}