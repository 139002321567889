/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AnomalyTab.js
 * @author Prakash // on 06/04/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'

import { Spinner } from 'reactstrap'
import _ from 'lodash'
import { momentDateGivenFormat, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, capitalizeFirstLetter } from '../../../utils/utility'
import { getObservabilityAnomalyDailyTrend, listObservabilityAnomalyResults } from '../../../actions/aiops/ObservabilityAction'
import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'

import Search from '../../common/SearchComponent'
import ApexTrendBarChart from '../../common/charts/ApexTrendBarChart'

class AnomalyTab extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            anomalyListLoader: true,

            startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
			perPage: 30,

            groupType: 'All',
            groupedBy: 'severity',
            selectedCategory: 'Important / All',
            pageType: "Anomalies",
            minimizeGraphSection: false,
        }
        this.drawLine = 0
    }

	componentDidMount = () => {
        if(this.props.observabilityAssetPageFilter && Object.entries(this.props.observabilityAssetPageFilter).length) {
            this.setState({ 
                start_time: this.props.observabilityAssetPageFilter.start_time,
                end_time: this.props.observabilityAssetPageFilter.end_time,
                brushStartTime: this.props.observabilityAssetPageFilter.brushStartTime ? this.props.observabilityAssetPageFilter.brushStartTime : this.props.observabilityAssetPageFilter.start_time,
                brushEndTime: this.props.observabilityAssetPageFilter.brushEndTime ? this.props.observabilityAssetPageFilter.brushEndTime : this.props.observabilityAssetPageFilter.end_time,
                selectedAsset: this.props.observabilityAssetPageFilter.selectedAsset ? this.props.observabilityAssetPageFilter.selectedAsset : {},
                anomalyListLoader: true,
                graphData: {}
            },
                () => {
                    this.listObservabilityAnomalyResults()
                    this.getObservabilityAnomalyDailyTrend()
                }
            )
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.observabilityAssetPageFilter && Object.entries(this.props.observabilityAssetPageFilter).length) {
            if(!_.isEqual(prevProps.observabilityAssetPageFilter, this.props.observabilityAssetPageFilter)) {
                this.setState({ 
                    start_time: this.props.observabilityAssetPageFilter.start_time,
                    end_time: this.props.observabilityAssetPageFilter.end_time,
                    brushStartTime: this.props.observabilityAssetPageFilter.brushStartTime ? this.props.observabilityAssetPageFilter.brushStartTime : this.props.observabilityAssetPageFilter.start_time,
                    brushEndTime: this.props.observabilityAssetPageFilter.brushEndTime ? this.props.observabilityAssetPageFilter.brushEndTime : this.props.observabilityAssetPageFilter.end_time,
                    selectedAsset: this.props.observabilityAssetPageFilter.selectedAsset ? this.props.observabilityAssetPageFilter.selectedAsset : {},
                    anomalyListLoader: true,
                    graphData: {}
                },
                    () => {
                        this.listObservabilityAnomalyResults()
                        this.getObservabilityAnomalyDailyTrend()
                    }
                )
            }
        }
    }

    getObservabilityAnomalyDailyTrend = () => {
        let params = {}
        params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss')
        params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss')
        params.duration_aggregate_by = "seconds"
        let assetId = this.state.selectedAsset && this.state.selectedAsset.asset_id ? this.state.selectedAsset.asset_id : ""
        params.asset_id = assetId

        this.props.getObservabilityAnomalyDailyTrend(params, (promise, result) => {
            if(promise) {
                this.setState({ anomalyDailyTrendData: result },
                    () => this.anomalyTrend()    
                )
            } else {
                this.setState({ anomalyDailyTrendData: {} })
            }
        })        
    }

    anomalyTrend = () => {
        let graphData = {
            start_time: momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss'), 
            end_time: momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss')
        }
        
        let trendSeverityCount = this.state.anomalyDailyTrendData.total_count ? this.state.anomalyDailyTrendData.total_count : {}
        let graphDetails = this.state.anomalyDailyTrendData.results && this.state.anomalyDailyTrendData.results.length ? this.state.anomalyDailyTrendData.results[0] : {}
        if(graphDetails && graphDetails.data) {
            graphData = {
                data: graphDetails.data,
                labels: graphDetails.dates,
                start_time: momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'), 
                end_time: momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss')
            }
        }

        this.setState({ graphData, trendSeverityCount })
    }

    resetGraph = () => {
        let graphData = this.state.graphData
        this.setState({ graphData: {}, zoomedStartDate: "", zoomedEndDate: "" },
            () => {
                this.filterData()
                this.setState({ graphData })
            }
        )
    }

    navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = this.state.startRecord
            if(action === 'next' && currentPage !== this.state.totalPages) {
                startRecord = startRecord + this.state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - this.state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = this.state.totalPages
                startRecord = (this.state.totalPages - 1) * this.state.perPage
            }
            this.setState({ currentPage, startRecord })
        }
	}

    gotoPage = (pageNumber) => {
        if(pageNumber > 0  && pageNumber <= this.state.totalPages) {
            let startRecord = (pageNumber - 1) * this.state.perPage
            this.setState({ currentPage: pageNumber, pageNumber, startRecord })
        } else  if(pageNumber > this.state.totalPages) {
            this.setState({ pageNumber: this.state.currentPage })  
        } else {
            this.setState({ pageNumber })
        }
    }

    listObservabilityAnomalyResults = () => {
        let params = {}
        params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss')
        params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss')
        params.aggregate_by = this.state.groupedBy
        let assetId = this.state.selectedAsset && this.state.selectedAsset.asset_id ? this.state.selectedAsset.asset_id : ""
        params.asset_id = assetId
        if(this.state.groupType !== 'All') {
            params.anomaly_type = this.state.groupType
        }

        this.props.listObservabilityAnomalyResults(params, (promise, response) => {
            if(promise) {
                let data = response.results && response.results.details ? response.results.details : []
                this.setState({ anomalyResponse: data, showGroupLoading: false },
                    () => {
                        this.structureAnomalyData()
                    }    
                )
            } else {
                this.setState({ anomalyResponse: [], showGroupLoading: false, anomalyListLoader: false },
                    () => this.props.menuDisabled()
                )
            }
        })
    }

    structureAnomalyData = () => {
        let groupAllAnomaly = []

        let totalCount = 0
        
        let groupAnomalycategory = []
        if(this.state.anomalyResponse && this.state.anomalyResponse.length) {
            totalCount = this.state.anomalyResponse.reduce( function(a, b){ return a + (b.count ? b.count : (b.details ? b.details.length : 0))}, 0);
            this.state.anomalyResponse.forEach((item, index) => {
                let dataRow = {}
                dataRow.count = item.count ? item.count : (item.details ? item.details.length : 0)
                dataRow.category = item.label
                dataRow.orderBy = index+1
                let percentage = Math.ceil(((dataRow.count / totalCount) * 100)/10)
                let array = []
                for(let i=1; i<=percentage; i++) {
                    array.push(i)
                }
                dataRow.totalBars = array
                groupAnomalycategory.push(dataRow)
                item.events.forEach(alt => {
                    groupAllAnomaly.push(alt)
                })
            })
        }
        
        let groupAnomalyTotalCount = this.state.anomalyResponse.total_count ? this.state.anomalyResponse.total_count : 0
        let groupAnomalyfilteredCount = this.state.anomalyResponse.filtered_count ? this.state.anomalyResponse.filtered_count : 0
        
        let dataRow = {}
        dataRow.count = ""
        dataRow.category = 'Important / All'
        dataRow.orderBy = 0
        groupAnomalycategory.push(dataRow)

        let orderedArray = _.orderBy(groupAnomalycategory, ['orderBy'], ['asc'])

        this.setState({ groupAllAnomaly, groupAnomaly: groupAllAnomaly, groupAnomalycategory: orderedArray, groupAnomalyTotalCount, groupAnomalyfilteredCount, anomalyListLoader: false },
            () => {
                this.filterData()
                this.props.menuDisabled()
            }
        )
    }

    getCategoryEvents = () => {
        let filterdCategoryAnomaly = this.state.groupAllAnomaly
        if(this.state.selectedCategory !== 'Important / All') {
            filterdCategoryAnomaly = this.state.anomalyResponse.filter(arr => arr.label === this.state.selectedCategory)
            if(filterdCategoryAnomaly && filterdCategoryAnomaly.length) {
                filterdCategoryAnomaly = filterdCategoryAnomaly[0].events ? filterdCategoryAnomaly[0].events : []
            }
        }

        this.setState({ groupAnomaly: filterdCategoryAnomaly, selectedAnomaly: "", selectedAnomalyId: "" },
            () => {
                this.filterData()
                // this.redirectToAnalysis()
                let scroll = document.getElementById('scrollTop');
                scroll.scrollIntoView();
            }
        )
    }

    filterData = () => {
        let data = this.state.groupAnomaly ? this.state.groupAnomaly : []
        data = _.orderBy(data, ['anomaly_time'], ['desc'])

        let totalPages = 1
		if(data.length > this.state.perPage) {
			totalPages = Math.ceil(data.length / this.state.perPage)
		}

        this.setState({ totalFilteredArray: data, filteredArray: data, totalPages },
            () => {
                if(this.state.filteredArray.length) {
                    // this.getAnomlayDetails(this.state.filteredArray[0])
                }
            }    
        )
    }

    filterZoomData = (zoomedStartDate, zoomedEndDate) => {

        let data = this.state.totalFilteredArray ? this.state.totalFilteredArray : []

        data = data.filter(e => momentConvertionUtcToLocalTime(e.anomaly_time, "YYYY-MM-DD HH:mm:ss") >= zoomedStartDate && momentConvertionUtcToLocalTime(e.anomaly_time, "YYYY-MM-DD HH:mm:ss") <= zoomedEndDate)

        data = _.orderBy(data, ['anomaly_time'], ['desc'])

        let totalPages = 1
		if(data.length > this.state.perPage) {
			totalPages = Math.ceil(data.length / this.state.perPage)
		}

        this.setState({ totalFilteredArray: data, filteredArray: data, totalPages, startRecord: 0, currentPage: 1, zoomedStartDate, zoomedEndDate },
            () => {
                if(this.state.filteredArray.length) {
                    // this.getAnomlayDetails(this.state.filteredArray[0])
                }
            }    
        )

    }

    getAnomlayDetails = (selectedRecord) => {
        this.setState({ 
            selectedAnomalyId: selectedRecord.anomaly_id === this.state.selectedAnomalyId ? "" : selectedRecord.anomaly_id, 
            selectedAnomaly: selectedRecord.anomaly_id === this.state.selectedAnomalyId ? "" : selectedRecord,
            expandAnomalyDetails: true, 
            expandAnomalyPrecedingDetails: false,
            expandPropsAssetDetails: false,
            expandPropsLogDetails: false,
        })
    }
    
    handleChange = (event) => {

        this.setState({ groupType: event.target.value, showGroupLoading: true, selectedAnomaly: "", selectedAnomalyId: "", anomalyListLoader: true },
            () => {
                if(this.state.groupType !== "All" && this.state.groupedBy === "anomaly_type") {
                    this.setState({ groupedBy: "severity"},
                        () => this.listObservabilityAnomalyResults()
                    )
                } else {
                    this.listObservabilityAnomalyResults()
                }
                // this.redirectToAnalysis()
            }    
        )
    }   

    handleClickOutside(event) {
        // if (this.toggleRef && !this.toggleRef.current.contains(event.target)) {
        //     this.setState({ showPageTypeOptions: false })
        // } else {
        //     this.setState({ showPageTypeOptions: true })
        // }
    }

    render() {
        return (
            <div className="" id="scrollTop" onClick={(event) => this.handleClickOutside(event)}>
                <div className="bg-dark3 mt-2 p-3 rounded">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex">
                            <label className="m-0 p-0">Total ({this.state.trendSeverityCount && this.state.trendSeverityCount.total ? this.state.trendSeverityCount.total : 0})</label>
                            <div className="d-flex ml-2 align-self-center">
                                <div className={`f10 badge-square-sm risk-badge-critical`}>C</div>
                                <p className="m-0 f11 text-white"><span className="mx-1">:</span> {this.state.trendSeverityCount && this.state.trendSeverityCount.severity && this.state.trendSeverityCount.severity.Critical ? this.state.trendSeverityCount.severity.Critical : 0}</p>
                            </div>
                            <div className="d-flex ml-2 align-self-center">
                                <div className={`f10 badge-square-sm risk-badge-high`}>H</div>
                                <p className="m-0 f11 text-white"><span className="mx-1">:</span> {this.state.trendSeverityCount && this.state.trendSeverityCount.severity && this.state.trendSeverityCount.severity.High ? this.state.trendSeverityCount.severity.High : 0}</p>
                            </div>
                            <div className="d-flex ml-2 align-self-center">
                                <div className={`f10 badge-square-sm risk-badge-medium`}>M</div>
                                <p className="m-0 f11 text-white"><span className="mx-1">:</span> {this.state.trendSeverityCount && this.state.trendSeverityCount.severity && this.state.trendSeverityCount.severity.Medium ? this.state.trendSeverityCount.severity.Medium : 0}</p>
                            </div>
                            <div className="d-flex ml-2 align-self-center">
                                <div className={`f10 badge-square-sm risk-badge-low`}>L</div>
                                <p className="m-0 f11 text-white"><span className="mx-1">:</span> {this.state.trendSeverityCount && this.state.trendSeverityCount.severity && this.state.trendSeverityCount.severity.Low ? this.state.trendSeverityCount.severity.Low : 0}</p>
                            </div>
                        </div>
                        <div className="d-flex">
                            {!this.state.minimizeGraphSection ?
                                <i className='far fa-redo cursorPointer' onClick={()=> this.resetGraph()}></i>
                            : null}
                            <i className={`fa fa-caret-${this.state.minimizeGraphSection ? "down" : "down"} cursorPointer ml-3 text-gray2`} onClick={() => this.setState({ minimizeGraphSection: !this.state.minimizeGraphSection })}></i>
                        </div>
                    </div>
                    {this.state.graphData && Object.keys(this.state.graphData).length && !this.state.minimizeGraphSection ?
                        <div className="mt-n2"> 
                            <ApexTrendBarChart
                                graphData={this.state.graphData}
                                graphRiskCountSection={false}
                                sparkline={false}
                                yaxis={false}
                                yaxisLabel={true}
                                yaxisTickAmount={2}
                                xaxis={true}
                                xaxisFormat={'string'}
                                xaxisLabel={true}
                                axisLabelColor={'#50586A'}
                                legend={false}
                                stacked={false}
                                height={100}
                                horizontal={false}
                                barHeight={'40%'}
                                barEndShape={'flat'}
                                columnWidth={'0%'}
                                backgroundBarShape={'rounded'}
                                backgroundBarColors={['#333947']}
                                showBackgroundBarColors={true}
                                className={"transparentTooltip"}
                                colors={['#3DAFE8']}
                                zoomEnabled={true}
                                getZoomPeriod={true}
                                zoomPeriod={(zoomedStartDate, zoomedEndDate) => {this.filterZoomData(zoomedStartDate, zoomedEndDate)}}
                            />
                        </div>
                    : null}
                </div>
                <div className="d-flex mt-2">
                    <div className="rounded bg-dark p-0 col-sm-12">
                        <div className={`d-flex justify-content-between mt-2 ml-1 mb-2 ${this.state.anomalyListLoader ? 'disabled' : ""}`}>
                            <div className="d-flex">
                                <p className="m-0 align-self-end small mr-2">Show </p>
                                <div class={`transparentSelectBorderBottom align-self-end`}>
                                    <select class="form-control" 
                                    onChange={this.handleChange}
                                    value={this.state.groupType}
                                    >
                                        <option key="All" value="All">ALL</option>
                                        <option key="metric" value="metric">Metric</option>
                                        <option key="lifecycle" value="lifecycle">Lifecycle</option>
                                        <option key="apicalls" value="apicalls">API Calls</option>
                                        <option key="slow_query" value="slow_query">Slow Queries</option>
                                        <option key="logs" value="logs">Logs</option>
                                    </select>
                                </div> 
                                <p className="m-0 align-self-end small mx-2">anomalies groupd by</p>
                                {/* <p className="m-0 align-self-end small mx-2">Showing Anomalies groupd by</p> */}
                                <div class={`transparentSelectBorderBottom align-self-end`}>
                                    <select class="form-control" 
                                        onChange={(event) => {
                                            this.setState({ groupedBy: event.target.value, anomalyListLoader: true, selectedCategory: "Important / All", selectedAnomaly: "", selectedAnomalyId: "" },
                                                () => {
                                                    // this.redirectToAnalysis()
                                                    this.listObservabilityAnomalyResults()
                                                }
                                            )
                                        }}
                                        value={this.state.groupedBy}                            
                                        >
                                            <option key="severity" value="severity">Severity</option>
                                            {/* <option key="asset_name" value="asset_name">Asset</option> */}
                                            {/* <option key="service_name" value="service_name">Service</option> */}
                                            {(this.state.groupType === "All" || this.state.groupType === "logs") ? 
                                                <option key="anomaly_type" value="anomaly_type">Anomaly Type</option>
                                            : null}
                                    </select>
                                </div>
                            </div>
                            <div className="d-flex w-30">
                                <p className="m-0 align-self-end small mx-2">Category</p>
                                {this.state.groupAnomalycategory ? 
                                    <div class={`transparentSelectBorderBottom align-self-end`}>
                                        <select className="form-control" 
                                            value={this.state.selectedCategory}
                                            onChange={(event) => {
                                                this.setState({ selectedCategory: event.target.value },
                                                    () => {
                                                        this.getCategoryEvents()
                                                    }    
                                                )
                                            }}>
                                            {this.state.groupAnomalycategory.map(item => {
                                                return(
                                                <option value={item.category}>{item.category+" "+item.count}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                : null}
                            </div>
                        </div>
                        <div className="d-flex mb-2 justify-content-between">
                            <div className="d-flex mr-2 w-70">
                                <p className="f12 m-0 align-self-center pt-1">Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.state.totalFilteredArray && this.state.totalFilteredArray.length}{this.state.totalFilteredArray && this.state.totalFilteredArray.length > 1 ? ' anomalies' : ' anomalies'}</p>
                                
                                {this.state.zoomedStartDate && this.state.zoomedStartDate !== "" ? 
                                    <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{momentDateGivenFormat(this.state.zoomedStartDate, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.zoomedEndDate, 'DD MMM YYYY HH:mm')}</p>
                                :
                                    <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{this.state.brushStartTime ? momentDateGivenFormat(this.state.brushStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.brushEndTime, 'DD MMM YYYY HH:mm') : ""}</p>
                                }
                            </div>
                            <div className="d-flex justify-content-end w-30 mb-n2">
                                {this.state.totalFilteredArray && this.state.totalFilteredArray.length ?
                                    <Search
                                        data={this.state.totalFilteredArray ? this.state.totalFilteredArray : []}
                                        applyTags={false}
                                        applyLiteDarkTags={true}
                                        topClassName={'bg-black5 align-self-center w-100 mr-1 border-gray5 rounded-5 f12'}
                                        searchClassName={'px-2 f12'}
                                        searchIconColor={'text-gray5 f12'}
                                        searchPlaceHolder={'Search anomaly....'}
                                        className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                        filteredData={(filteredArray) => {
                                            this.setState({ filteredArray, errorDetails: {}, merticsAssetNameInvolved: [] },
                                                () => {
                                                    if(this.state.filteredArray.length) {
                                                        // this.getAnomlayDetails(this.state.filteredArray[0])
                                                    }
                                                }    
                                            )
                                        }}
                                    />
                                : null}
                            </div>
                        </div>
                        {this.state.filteredArray && this.state.filteredArray.length > this.state.perPage ?
                            <div className="pagination errorPagePagination f12 mt-n1 displayNone">
                                <span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
                                <button><i className={`fal fa-arrow-to-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('start', this.state.currentPage)}></i></button> 
                                <button><i className={`fal fa-angle-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('previous', this.state.currentPage)}></i></button> 
                                <button><i className={`fal fa-angle-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
                                <button><i className={`fal fa-arrow-to-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
                            </div>
                        : null}
                    </div>
                </div>
                
                <div className="d-flex">
                    <div className={`w-100`}>
                        <div className={`bg-dark3 py-3 pl-2 ${!this.state.minimizeGraphSection ? "obsMessageSection" : "obsMessageSectionLg"}`}>
                        {/* <div className={`rounded bg-dark3 p-3 tabWithSearchScroll`}> */}
                            {this.state.anomalyListLoader ? 
                                <div className='d-flex justify-content-center m-4'>
                                    <Spinner className='text-center' color='white' size='lg' />
                                </div>
                            : this.state.filteredArray && this.state.filteredArray.length ?
                                // this.state.filteredArray.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map((item, i) => {
                                this.state.filteredArray.map((item, i) => {
                                    return(
                                        <div className={`px-2 mb-2 pb-1 border-bottom-black5 ${this.state.selectedAnomalyId && this.state.selectedAnomalyId === item.event_id ? "bg-muted" : ""}`}>
                                            {this.state.groupType !== "logs" ?
                                                <div className="d-flex cursorPointer" onClick={() => this.getAnomlayDetails(item)}>
                                                    <span className={`f10 mr-2 align-self-start mt-1 badge-sm risk-badge-${item.severity ? item.severity.toLowerCase() : 'critical'}`}>
                                                        {item.severity ? 
                                                            (item.severity.toLowerCase() === 'critical' ? 'C' : item.severity.toLowerCase() === 'high' ? 'H' : item.severity.toLowerCase() === 'medium' ? 'M' : 'L') 
                                                        : 'U'}
                                                    </span>
                                                    <p className="mb-0">
                                                        <span className="mb-0 mr-2 f12 text-primary-color font-weight-bold">{item.anomaly_time ? momentConvertionUtcToLocalTime(item.anomaly_time, 'DD MMM YYYY HH:mm') : ""} </span>
                                                        <span className={`mb-0 mr-1 f12 text-purple-2`}>{item.anomaly_type ? item.anomaly_type : ""} </span>
                                                        <span className="mb-0 mr-2 f12 font-weight-bold text-purple-2">Anomaly</span>
                                                        {/* <span className={`mb-0 mr-2 f12 ${item.event_source === 'checks' ? 'text-purple' : item.event_source === 'config' ? 'text-secondary' : (item.event_source === 'cloudtrail' || item.event_source === 'cloudwatch_alarm') ? 'text-info' : 'text-gray3'} font-weight-bold`}>{capitalizeFirstLetter(item.event_source) + (item.event_name ? ' : ' + item.event_name : "") + (item.event_type ? ' : '+item.event_type : "")}</span> */}
                                                        {/* <span className={`mb-0 mr-2 f12 text-purple-2`}>{item.anomaly_name + '  '+ (item.anomaly_type ? ' : ' + item.anomaly_type : "") + (item.title ? ' : ' + item.title : "") + ' score '+ (item.anomaly_score ? ' : '+item.anomaly_score : 0)} </span> */}
                                                        {/* {item.asset_name && item.asset_name !== "" ? 
                                                            <span className="mb-0 mr-2 f12 text-white font-weight-bold">{item.asset_name} </span>
                                                        : null} */}
                                                        <span className="mb-0 small text-primary-color mr-1">Resource </span>
                                                        {item.account_id ?
                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">{' '+item.account_id} </span>
                                                        : null}
                                                        {item.region ?
                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+item.region} </span>
                                                        : null}
                                                        {item.anomaly_message ?
                                                            <span className="mb-0 ml-2 f12 text-white font-weight-bold">{item.anomaly_message} </span>
                                                        : null}
                                                    </p>
                                                </div>
                                            :
                                                <div className="d-flex cursorPointer" onClick={() => this.getAnomlayDetails(item)}>
                                                    <span className={`f10 mr-2 align-self-start mt-1 badge-sm risk-badge-${item.severity ? item.severity.toLowerCase() : 'critical'}`}>
                                                        {item.severity ? 
                                                            (item.severity.toLowerCase() === 'critical' ? 'C' : item.severity.toLowerCase() === 'high' ? 'H' : item.severity.toLowerCase() === 'medium' ? 'M' : 'L') 
                                                        : 'U'}
                                                    </span>
                                                    {item.type === "sequence" ?
                                                        <p className="mb-0">
                                                            <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.anomaly_time ? momentConvertionUtcToLocalTime(item.anomaly_time, 'DD MMM YYYY HH:mm') : ""} </span>
                                                            <span className={`mb-0 mr-1 f12 text-purple-2`}>Sequence/combination Anomaly </span>
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">Log lines matching the template </span>
                                                            {item.additional_details && item.additional_details.template ?
                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.additional_details.template} </span>
                                                            : null}
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">came in an abnormal sequence. The anomalous behavior was observed </span>
                                                            <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.count && item.count} times </span>
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold"> in </span>
                                                            {item.asset_name && item.asset_name !== "" ? 
                                                                <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.asset_name}  </span>
                                                            : null}
                                                            {item.service_name && item.service_name !== "" ? 
                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">(service: {item.service_name},  </span>
                                                            : null}
                                                            {item.asset_id && item.asset_id !== "" ? 
                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">ID: {item.asset_id}),  </span>
                                                            : null}
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">writing to log group </span>
                                                            {item.additional_details && item.additional_details.log_group ?
                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.additional_details.log_group} </span>
                                                            : null}
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">owned by account ID </span>
                                                            {item.account_id ?
                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.account_id} </span>
                                                            : null}
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">in </span>
                                                            {item.region ?
                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.region} </span>
                                                            : null}
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">region. This is a  </span>
                                                            <span className={`mb-0 f12 ${item.severity ? "risk-"+item.severity.toLowerCase() : "white"} font-weight-bold`}>{item.severity} </span>
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold"> severity anomaly predicted on the  </span>
                                                            <span className="mb-0 mr-1 f12 text-white font-weight-bold">last {item.period} </span>
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">data. </span>
                                                        </p>
                                                    : item.type === "numerical" ?
                                                        <p className="mb-0">
                                                            <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.anomaly_time ? momentConvertionUtcToLocalTime(item.anomaly_time, 'DD MMM YYYY HH:mm') : ""} </span>
                                                            <span className={`mb-0 mr-1 f12 text-purple-2`}>Numerical value Anomaly </span>
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">The numerical values for keys </span>
                                                            {item.keys && item.keys.length ?
                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.keys.join(", ")} </span>
                                                            : null}
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">are not normal occurrences. The anomalous behavior was observed </span>
                                                            <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.count && item.count} times </span>
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">in </span>
                                                            {item.asset_name && item.asset_name !== "" ? 
                                                                <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.asset_name}  </span>
                                                            : null}
                                                            {item.service_name && item.service_name !== "" ? 
                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">(service: {item.service_name},  </span>
                                                            : null}
                                                            {item.asset_id && item.asset_id !== "" ? 
                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">ID: {item.asset_id}),  </span>
                                                            : null}
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">writing to log group </span>
                                                            {item.additional_details && item.additional_details.log_group ?
                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.additional_details.log_group} </span>
                                                            : null}
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">owned by account ID </span>
                                                            {item.account_id ?
                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.account_id} </span>
                                                            : null}
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">in </span>
                                                            {item.region ?
                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.region} </span>
                                                            : null}
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">region. This is a  </span>
                                                            <span className={`mb-0 f12 ${item.severity ? "risk-"+item.severity.toLowerCase() : "white"} font-weight-bold`}>{item.severity} </span>
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold"> severity anomaly predicted on the </span>
                                                            <span className="mb-0 mr-1 f12 text-white font-weight-bold">last {item.period} </span>
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">data. </span>
                                                        </p>
                                                    : item.type === "categorical" ?
                                                        <p className="mb-0">
                                                            <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.anomaly_time ? momentConvertionUtcToLocalTime(item.anomaly_time, 'DD MMM YYYY HH:mm') : ""} </span>
                                                            <span className={`mb-0 mr-1 f12 text-purple-2`}>Categorical value Anomaly </span>
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">The categorical value </span>
                                                            {item.cat_value ?
                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.cat_value} </span>
                                                            : null}
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">is not a normal occurrence. The anomalous behavior was observed </span>
                                                            <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.count && item.count} times </span>
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">in </span>
                                                            {item.asset_name && item.asset_name !== "" ? 
                                                                <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.asset_name}  </span>
                                                            : null}
                                                            {item.service_name && item.service_name !== "" ? 
                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">(service: {item.service_name},  </span>
                                                            : null}
                                                            {item.asset_id && item.asset_id !== "" ? 
                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">ID: {item.asset_id}),  </span>
                                                            : null}
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">writing to log group </span>
                                                            {item.additional_details && item.additional_details.log_group ?
                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.additional_details.log_group} </span>
                                                            : null}
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">owned by account ID </span>
                                                            {item.account_id ?
                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.account_id} </span>
                                                            : null}
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">in </span>
                                                            {item.region ?
                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.region} </span>
                                                            : null}
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">region. This is a  </span>
                                                            <span className={`mb-0 f12 ${item.severity ? "risk-"+item.severity.toLowerCase() : "white"} font-weight-bold`}>{item.severity} </span>
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold"> severity anomaly predicted on the </span>
                                                            <span className="mb-0 mr-1 f12 text-white font-weight-bold">last {item.period} </span>
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">data. </span>
                                                        </p>
                                                    : item.type === "newtemplate" ?
                                                        <p className="mb-0">
                                                            <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.anomaly_time ? momentConvertionUtcToLocalTime(item.anomaly_time, 'DD MMM YYYY HH:mm') : ""} </span>
                                                            <span className={`mb-0 mr-1 f12 text-purple-2`}>New log line Anomaly </span>
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">Log lines matching the template </span>
                                                            {item.additional_details && item.additional_details.template ?
                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.additional_details.template} </span>
                                                            : null}
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">had not been observed previously. Such lines were observed </span>
                                                            <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.count && item.count} times </span>
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">in the </span>
                                                            <span className="mb-0 mr-1 f12 text-white font-weight-bold">last {item.period} </span>
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">data from </span>
                                                            {item.asset_name && item.asset_name !== "" ? 
                                                                <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.asset_name}  </span>
                                                            : null}
                                                            {item.service_name && item.service_name !== "" ? 
                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">(service: {item.service_name},  </span>
                                                            : null}
                                                            {item.asset_id && item.asset_id !== "" ? 
                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">ID: {item.asset_id}),  </span>
                                                            : null}
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">writing to log group </span>
                                                            {item.additional_details && item.additional_details.log_group ?
                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.additional_details.log_group} </span>
                                                            : null}
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">owned by account ID </span>
                                                            {item.account_id ?
                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.account_id} </span>
                                                            : null}
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">in </span>
                                                            {item.region ?
                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.region} </span>
                                                            : null}
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">region. This is a  </span>
                                                            <span className={`mb-0 f12 ${item.severity ? "risk-"+item.severity.toLowerCase() : "white"} font-weight-bold`}>{item.severity} </span>
                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold"> severity anomaly  </span>
                                                        </p>
                                                    : null}
                                                </div>
                                            }
                                            {this.state.selectedAnomalyId && this.state.selectedAnomalyId === item.anomaly_id ?
                                                <div className="rounded bg-dark3 p-3">
                                                    <div className="accordion" id="accordionExample">
                                                        <div className={`card bg-dark border-0 mb-2`}>
                                                            <div id={'heading_event'} onClick={() => this.setState({ expandAnomalyDetails: !this.state.expandAnomalyDetails })}>
                                                                <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
                                                                    <div className="text-white"><span className={`far ${this.state.expandAnomalyDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Anomaly Details</div>
                                                                    {/* <div className="text-info">Showing event details</div> */}
                                                                    <div className="toggleOnOffSwitchTable d-flex mr-1 align-self-center" >
                                                                        {/* onClick={() => this.automaiotnUpdateStatus(cellInfo.row.original)}> */}
                                                                        <label className="switch mb-0">
                                                                            <input type="checkbox" 
                                                                                className="inputdemo" 
                                                                                checked={this.state['enabled_'+item.anomaly_id] ? false : true}
                                                                            />
                                                                            <span className="slider round"></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div id={'collapse_event'} className={`collapse ${this.state.expandAnomalyDetails ? 'show' : <span>&nbsp;</span>}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
                                                                <div className="card-body">
                                                                    <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
                                                                        <div>
                                                                            <p className="mb-0 f16 text-white">{this.state.selectedAnomaly.anomaly_name ? this.state.selectedAnomaly.anomaly_name : <span>&nbsp;</span>}</p>
                                                                            <p className="mb-0 f12">{this.state.selectedAnomaly.anomaly_message ? this.state.selectedAnomaly.anomaly_message : <span>&nbsp;</span>}</p>
                                                                        </div>
                                                                        <div className="ml-3">
                                                                            <p className="b-block mb-0">Severity</p>
                                                                            <span className={`mr-2 badge ${this.state.selectedAnomaly.severity && this.state.selectedAnomaly.severity.toLowerCase() !== 'nan' ? 'risk-badge-'+this.state.selectedAnomaly.severity.toLowerCase() : 'badge-secondary' }`}>  
                                                                                {this.state.selectedAnomaly.severity ? capitalizeFirstLetter(this.state.selectedAnomaly.severity) : 'Unknown'}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="py-1 w-50">
                                                                            <p className="b-block mb-0">Time</p>
                                                                            <p className="mb-0 text-white">
                                                                                    {this.state.selectedAnomaly.anomaly_time ? momentConvertionUtcToLocalTime(this.state.selectedAnomaly.anomaly_time, 'DD MMM YYYY HH:mm') : <span>&nbsp;</span>}
                                                                            </p>
                                                                        </div>
                                                                        <div className="py-1 w-50 pl-3">
                                                                            <p className="b-block mb-0">Type</p>
                                                                            <p className="mb-0 text-white">
                                                                                {this.state.selectedAnomaly.anomaly_type ? this.state.selectedAnomaly.anomaly_type : <span>&nbsp;</span>}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex pt-2 border-top">
                                                                        <div className="py-1 w-50">
                                                                            <p className="b-block mb-0">Score</p>
                                                                            <p className="mb-0 text-white">
                                                                                    {this.state.selectedAnomaly.anomaly_score}
                                                                            </p>
                                                                        </div>
                                                                        <div className="py-1 w-50 pl-3">
                                                                            <p className="b-block mb-0">Value</p>
                                                                            <p className="mb-0 text-white">
                                                                                {this.state.selectedAnomaly.anomaly_value}
                                                                            </p>
                                                                        </div>
                                                                    </div>                    
                                                                    
                                                                    <div className="d-flex pt-2 border-top">
                                                                        <div className="py-1 w-50">
                                                                            <p className="b-block mb-0">Run Time</p>
                                                                            <p className="mb-0 text-white">
                                                                                {this.state.selectedAnomaly.run_time ? momentConvertionUtcToLocalTime(this.state.selectedAnomaly.run_time, 'DD MMM YYYY HH:mm') : <span>&nbsp;</span>}
                                                                            </p>
                                                                        </div>
                                                                        <div className="py-1 w-50 pl-3">
                                                                            <p className="b-block mb-0">Product</p>
                                                                            {this.state.selectedAnomaly.product ? 
                                                                                this.state.selectedAnomaly.product.map(item => {
                                                                                    return (
                                                                                    <small className={`badge badge-secondary mr-1`}>{item}</small>
                                                                                    )
                                                                                }) 
                                                                            : null
                                                                            }
                                                                        </div>
                                                                    </div>
                        
                                                                    {this.state.selectedAnomaly.additional_details && Object.entries(this.state.selectedAnomaly.additional_details).length ?
                                                                        <div className="col-sm-12">
                                                                            <div className="row">
                                                                            {Object.entries(this.state.selectedAnomaly.additional_details).map(([key, value], addIdx) => {
                                                                                return(
                                                                                    typeof value === "string" ? 
                                                                                        <React.Fragment>
                                                                                            <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                                            <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                                                <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                                                <p className="mb-0 text-white">{value}</p>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    : null
                                                                                )
                                                                            })}
                                                                            </div>
                                                                        </div>
                                                                    : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                        
                                                    {this.state.selectedAnomaly && this.state.selectedAnomaly.type === "sequence" && this.state.selectedAnomaly.preceding_sequence && this.state.selectedAnomaly.preceding_sequence.length ?
                                                        <div className={`card bg-dark border-0 mb-2`}>
                                                            <div id={'heading_config_assets'} onClick={() => this.setState({ expandAnomalyPrecedingDetails: !this.state.expandAnomalyPrecedingDetails })}>
                                                                <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                                                    <div className="text-white"><span className={`far ${this.state.expandAnomalyPrecedingDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Preceding Sequence</div>
                                                                </div>
                                                            </div>
                                                            <div id={'collapse_config_assets'} className={`collapse ${this.state.expandAnomalyPrecedingDetails ? 'show' : <span>&nbsp;</span>}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                                                <ul className="precedingUl">
                                                                    {this.state.selectedAnomaly.preceding_sequence.map((item, i) => {
                                                                            return(
                                                                                <li className="d-flex mt-2">
                                                                                    <span className="ulBullet mt-1 p-1"></span>
                                                                                    <span className="ml-2 align-self-end cursorPointer text-white" id={"asset_name_"+i}>{item}</span>
                                                                                    {/* <UncontrolledTooltip target={"asset_name_"+i}>{item.asset_name}</UncontrolledTooltip> */}
                                                                                
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    : null}
                        
                                                    {this.state.selectedAnomaly && (this.state.selectedAnomaly.asset_name || this.state.selectedAnomaly.asset_id) ?
                                                        <div className={`card bg-dark border-0 mb-2`}>
                                                            <div id={'heading_config_assets'} onClick={() => this.setState({ expandPropsAssetDetails: !this.state.expandPropsAssetDetails })}>
                                                                <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                                                    <div className="text-white"><span className={`far ${this.state.expandPropsAssetDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Asset Details</div>
                                                                    {/* <div className="text-info">Showing asset details</div> */}
                                                                </div>
                                                            </div>
                                                            <div id={'collapse_config_assets'} className={`collapse ${this.state.expandPropsAssetDetails ? 'show' : <span>&nbsp;</span>}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                                                <div className="card-body m-2 pt-2 rounded counts_threads">
                                                                    <div className="d-flex">
                                                                        <div className="py-1 w-50">
                                                                            <p className="b-block mb-0">Name:</p>
                                                                            <p className="mb-0 text-white">{this.state.selectedAnomaly.asset_name ? this.state.selectedAnomaly.asset_name : <span>&nbsp;</span>}</p>
                                                                        </div>
                                                                        <div className="py-1 w-50 pl-3">
                                                                            <p className="b-block mb-0">Id:</p>
                                                                            <p className="mb-0 text-white">{this.state.selectedAnomaly.asset_id ? this.state.selectedAnomaly.asset_id : <span>&nbsp;</span>}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex pt-2 border-top">
                                                                        <div className="py-1 w-50">
                                                                            <p className="b-block mb-0">Provider / Account / Region </p>
                                                                            <p className="mb-0 text-white">
                                                                                {this.state.selectedAnomaly.provider ? this.state.selectedAnomaly.provider.toUpperCase() : <span>&nbsp;</span>}
                                                                                {this.state.selectedAnomaly.account_id ? (' : ')+this.state.selectedAnomaly.account_id : <span>&nbsp;</span>}
                                                                                {this.state.selectedAnomaly.region ? (' : ')+this.state.selectedAnomaly.region : <span>&nbsp;</span>}
                                                                            </p>
                                                                        </div>
                                                                        <div className="py-1 w-50 pl-3">
                                                                            <p className="b-block mb-0">Service</p>
                                                                            <p className="mb-0 text-white">
                                                                                {this.state.selectedAnomaly.resource_type ? this.state.selectedAnomaly.resource_type : <span>&nbsp;</span>}
                                                                                {this.state.selectedAnomaly.service_name ? ('  ')+this.state.selectedAnomaly.service_name : <span>&nbsp;</span>}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    {this.props.asset_arn ? 
                                                                        <div className="d-flex pt-2 border-top">
                                                                            <div className="py-1 w-100">
                                                                                <p className="b-block mb-0">ARN:</p>
                                                                                <p className="mb-0 text-white">{this.props.asset_arn}</p>
                                                                            </div>
                                                                        </div>
                                                                    : null}
                                                                    {this.state.selectedAnomaly.category ? 
                                                                        <div className="d-flex pt-2 border-top">
                                                                            <div className="py-1 w-100">
                                                                                <p className="b-block mb-0">Category:</p>
                                                                                <div className={`d-flex flex-wrap`}>
                                                                                    {this.state.selectedAnomaly.category && Array.isArray(this.state.selectedAnomaly.category) ? 
                                                                                        this.state.selectedAnomaly.category.map(item => {
                                                                                            return (
                                                                                            <small className={`badge badge-secondary mr-1`}>{item}</small>
                                                                                            )
                                                                                        }) 
                                                                                    : this.state.selectedAnomaly.category ?
                                                                                        <small className={`badge badge-secondary mr-1`}>{this.state.selectedAnomaly.category}</small>
                                                                                    : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    : null}
                        
                                                    {this.state.selectedAnomaly && this.state.selectedAnomaly.logs && this.state.selectedAnomaly.logs.length ?
                                                        <div className={`card bg-dark border-0 mb-2`}>
                                                            <div id={'heading_config_assets'} onClick={() => this.setState({ expandPropsLogDetails: !this.state.expandPropsLogDetails })}>
                                                                <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                                                    <div className="text-white"><span className={`far ${this.state.expandPropsLogDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Log Details</div>
                                                                    {this.state.expandPropsLogDetails ?
                                                                        <div className="text-info">Showing {this.state.selectedAnomaly.count} observed anomalies</div>
                                                                        // <p className="small mb-0 ml-3">Showing {this.state.selectedAnomaly.count} observed anomalies</p>
                                                                    : null}
                                                                </div>
                                                            </div>                                    
                                                            <div id={'collapse_config_assets'} className={`collapse ${this.state.expandPropsLogDetails ? 'show' : <span>&nbsp;</span>}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                                                <div className={`rounded bg-dark3 mx-3 mb-3`} style={{maxHeight: "600px", overflowY: "auto"}}>
                                                                    {this.state.selectedAnomaly.logs.map((item, i) => {
                                                                            return(
                                                                                <div className={`d-flex flex-wrap mr-2 px-2 mb-2 pb-1 pt-1 ${i ? "border-top-black5" : ""}`}>
                                                                                    <p className="mb-0 text-break">
                                                                                        <span className="mb-0 mr-2 f12 text-white font-weight-bold">{item.timestamp ? momentConvertionUtcToLocalTime(item.timestamp, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                                        <span className={`mb-0 mr-2 f12 text-purple-2`}>{item.log_file ? item.log_file : ""}</span>
                                                                                        {item.log_event_id ?
                                                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">{item.log_event_id}</span>
                                                                                        : null}
                                                                                        {item.message ?
                                                                                            <span className="mb-0 ml-2 f12 text-white font-weight-bold">{item.message}</span>
                                                                                        : null}
                                                                                    </p>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    : null}
                                                </div>
                                            : null}
                                        </div>
                                    )
                                })
                            :
                                <div className='d-flex justify-content-center m-4'>
                                    <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
AnomalyTab.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log('errorDashboard',state)
    return {
        observabilityAssetPageFilter: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.observabilityAssetPageFilter ? state.aiops.aiopsPropsDetails.observabilityAssetPageFilter : {},
        obsAssetAnomalyList: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.obsAssetAnomalyList ? state.aiops.aiopsPropsDetails.obsAssetAnomalyList : {},
    }
}

export default connect(mapStateToProps, {
    getObservabilityAnomalyDailyTrend,
    listObservabilityAnomalyResults,
    setAiopsPropsDetails,
})(withRouter(AnomalyTab))        