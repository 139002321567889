/*************************************************
 * Tvastar
 * @exports
 * @file JobListPanel.js
 * @author Prakash // on 28/07/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { capitalizeFirstLetter, getAccountNameFromId, momentDateGivenFormat, momentConvertionUtcToLocalTime } from '../../utils/utility'
import { CUSTOM_DURATION_FILTER_OPTIONS } from '../../utils/constants'
import  { getJobTotalCount, getJobDetails, getJobDetailsByModule, getJobDailyCount, getCompleteJobDetails, getJobHistogram } from '../../actions/CustomerOperationsAction'
import { Spinner } from 'reactstrap'
import Search from '../common/SearchComponent'

class JobListPanel extends Component {
	proEnvWrap = React.createRef()
	constructor(props) {
		super(props)
		this.props = props;
		this.state = {
			activeTab: 0,
			minMaxHeight: "800px",
			showLoading: true,
			selectedHistoGramData: this.props.selectedHistoGramData,
			choosenAccount: this.props.choosenAccount,
			selectedDuration: this.props.selectedDuration,
			start_time: this.props.start_time,
			end_time: this.props.end_time,

			startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
			perPage: 100,
		}
	}

	componentDidMount = () => {
		this.getWindowSize()
		this.getCompleteJobDetails()
	}

	getWindowSize = () =>  {
		const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 120
		let outerHeight = (window.outerHeight - browserBorder - headerHeight) +"px"
		
		this.setState({ outerHeight, minMaxHeight: outerHeight })
	}

	getCompleteJobDetails = (type) => {
		
		let params = {}
		if(this.state.selectedDuration === "custom") {
			params.start_time = this.state.start_time
			params.end_time = this.state.end_time
		} else {
			params.duration = this.state.selectedDuration
		}
		if(this.state.choosenAccount && this.state.choosenAccount !== "") {
			params.account_id = this.state.choosenAccount
		}
		if(this.state.selectedHistoGramData && this.state.selectedHistoGramData.key) {
			params.execution_time_range = this.state.selectedHistoGramData.key
		}

		this.props.getCompleteJobDetails(params, (promise, response) => {
			if(promise) {
				let results = response.results ? response.results : []

				let totalPages = 1 
				if(results.length > this.state.perPage) {
					totalPages = Math.ceil(results.length / this.state.perPage)
				}

				this.setState({ completedJobs: results, filteredJobs: results, totalPages, showLoading: false })
			} else {
				this.setState({ completedJobs: [], filteredJobs: [], totalPages: 1, showLoading: false })
			}
		})
	}

    navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = this.state.startRecord
            if(action === "next" && currentPage !== this.state.totalPages) {
                startRecord = startRecord + this.state.perPage
                currentPage = currentPage + 1
            } else if(action === "previous" && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - this.state.perPage
            } else if(action === "start" && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === "end" && currentPage !== 0) {
                currentPage = this.state.totalPages
                startRecord = (this.state.totalPages - 1) * this.state.perPage
            }
            this.setState({ currentPage, startRecord })
        } 
	}

	render() {		
		return (
			<div className="advanced-search" style={{zIndex:99}}>
				<div className="search-content bg-muted w-60">
					<div className="header-search bd-highlight">
						<div className="flex-fill bd-highlight">
							<h5>{this.state.selectedHistoGramData ? (this.state.selectedHistoGramData.key+" : "+this.state.selectedHistoGramData.value) : ""}</h5>
							<div className="d-flex">
								<p className="m-0 small"><span className="text-pirmayr-color">Account: </span>
									{this.state.choosenAccount && this.state.choosenAccount !== "" ? 
										<span className="ml-1">{getAccountNameFromId(this.state.choosenAccount, this.props.accountsDetailsList)} <span className="text-primary-color mx-1">({this.state.choosenAccount})</span></span>
									: null}
								</p>
								<p className="m-0 small"><span className="text-pirmayr-color">Duration: </span>
									{this.state.selectedDuration !== "custom" ? 
										<span>{CUSTOM_DURATION_FILTER_OPTIONS.filter(e => e.value === this.state.selectedDuration)[0].option}</span>
									:
										<span>{this.state.start_time ? (momentDateGivenFormat(this.state.start_time, "DD MMM YYYY HH:mm") +" "+momentDateGivenFormat(this.state.end_time, "DD MMM YYYY HH:mm")) : ""}</span>
									}
								</p>
							</div>
						</div>
						<div className="text-right flex-fill bd-highlight ml-2 align-self-center">
							<i className="far fa-times cursorPointer" onClick={() => this.props.closeJobListPanel()}></i>
						</div>
					</div>
					<div className="overflow-auto">
						<ul className="nav nav-tabs bd-highlight displayNone" id="myTab" role="tablist">
							<li className="nav-item">
								<span
									className={`nav-link cursor-pointer ${this.state.activeTab === 0 ? "active" : ""} ${this.state.showLoading ? "disabled" : ""}`}
									style={{fontSize:"12px"}}
									onClick={() => this.setState(
										{ activeTab: 0, showLoading:true},
										() => {
											if(Object.entries(this.state.resourceChart).length === 0)  {
												this.setState({ donutData: {}, donutOptions: {} },
													() => this.getResourceChart()
												)
											} else {
												this.setState({ donutData: {}, donutOptions: {}, showLoading:false }, 
													() => this.getResourceDonutDetails()
												)
											}
										}
									)}
								>
								Resources
								</span>
							</li>
						</ul>
						
						{!this.state.showLoading?
							<div className="tab-content p-2" id="myTabContent">
								<div className="tab-pane fade show active" id="assets" role="tabpanel"
									aria-labelledby="ops-side-tab">
									{this.state.activeTab === 0 ?
										<React.Fragment>
										<div className="d-flex mb-2 justify-content-between">
											<div className="d-flex mr-2 w-50">
												<p className="f12 m-0 align-self-center pt-1">Showing {this.state.filteredJobs && this.state.filteredJobs.length} of total {this.state.completedJobs && this.state.completedJobs.length} data(s)</p>
											</div>
											<div className="d-flex justify-content-end w-50 mb-n2">
												{this.state.completedJobs && this.state.completedJobs.length ?
													<Search
														data={this.state.completedJobs ? this.state.completedJobs : []}
														applyTags={false}
														applyLiteDarkTags={true}
														topClassName={"bg-black5 align-self-center w-50 mr-1 border-gray5 rounded-5 f12"}
														searchClassName={"px-2 f12"}
														searchIconColor={"text-gray5 f12"}
														searchPlaceHolder={"Search...."}
														className={"bg-transparent text-white pl-0 form-control-sm f12"}
														filteredData={(filteredJobs) => {
															let totalPages = 1
															if(filteredJobs.length > this.state.perPage) {
																totalPages = Math.ceil(filteredJobs.length / this.state.perPage)
															}
															this.setState({ filteredJobs, startRecord: 0, currentPage: 1, totalPages })
														}}
													/>
												: null}
												{this.state.filteredJobs && this.state.filteredJobs.length > this.state.perPage ?
													<div className="pagination errorPagePagination f12">
														<span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
														<button><i className={`fal fa-arrow-to-left cursorPointer ${this.state.currentPage === 1 ? "disabled text-muted" : "text-info"}`} onClick={() => this.navigatePage("start", this.state.currentPage)}></i></button> 
														<button><i className={`fal fa-angle-left cursorPointer ${this.state.currentPage === 1 ? "disabled text-muted" : "text-info"}`} onClick={() => this.navigatePage("previous", this.state.currentPage)}></i></button> 
														{/* <span>
															Go to
															<input
																type="number"
																value={this.state.pageNumber || this.state.pageNumber === "" ? this.state.pageNumber : this.state.currentPage}
																onChange={e => {
																	const page = e.target.value ? Number(e.target.value) : ""
																	this.gotoPage(page)
																}}
																className="ml-1 inputClass"
															/>
														</span> */}
														<button><i className={`fal fa-angle-right cursorPointer ${this.state.currentPage === this.state.totalPages ? "disabled text-muted" : "text-info"}`} onClick={() => this.navigatePage("next", this.state.currentPage)}></i></button> 
														<button><i className={`fal fa-arrow-to-right cursorPointer ${this.state.currentPage === this.state.totalPages ? "disabled text-muted" : "text-info"}`} onClick={() => this.navigatePage("end", this.state.currentPage)}></i></button>
													</div>
												: null}
											</div>
										</div>
										<div className={`bg-dark3 py-3 pl-2 rounded overflowYAuto`} style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
											{this.state.filteredJobs && this.state.filteredJobs.length ?
												this.state.filteredJobs.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map((item, i) => {
													return(
														<React.Fragment>
														<div className={`px-2 mb-2 pb-1 ${i ? "border-top-black5" : ""}`}> 
															<div className="d-flex cursorPointer">
																<span className={`f10 mr-2 align-self-start mt-1 badge-sm ${item.status && item.status.toLowerCase() === "completed" ? "badge-pass" : (item.status && item.status.toLowerCase() === "failed" ? "badge-fail" : "")}`}>
																	{item.status ? 
																		(item.status.toLowerCase() === "completed" ? "P" : item.status.toLowerCase() === "failed" ? "F" : "") 
																	: " "}
																</span>
																<p className="mb-0">
																	<span className="mb-0 mr-2 text-white f12 font-weight-bold text-primary-color">{item.submitted_time ? momentConvertionUtcToLocalTime(item.submitted_time, "DD MMM YYYY HH:mm") : ""} </span>
																	<span className={`mb-0 mr-2 f12 text-purple-2`}>
																		{item.job_type ? item.job_type : ""}
																		{item.service_id ? " : "+ item.service_id : ""}
																	</span>																	
																	{item.submitted_by ?
																		<span className="mb-0 mr-2 f12 text-white-2 font-weight-bold"><span className="text-primary-color mr-1">Submitted By:</span> {item.submitted_by}</span>
																	: null}

																	{item.execution_time_in_secs ?
																		<span className="mb-0 mr-2 f12 text-white font-weight-bold"><span className="text-primary-color mr-1">Execution time in secs:</span> {item.execution_time_in_secs}</span>
																	: null}
																	{item.memory ?
																		<span className="mb-0 mr-2 f12 text-white font-weight-bold"><span className="text-primary-color mr-1">Memory:</span> {item.memory}</span>
																	: null}
																	<span className="mb-0 small mr-1 text-primary-color">Resource</span>
																	{item.account_id ?
																		<span className="mb-0 f12 text-primary-color font-weight-bold">{" "+item.account_id}</span>
																	: null}
																	{item.status_message ?
																		<span className="mb-0 ml-2 f12 text-white font-weight-bold">{item.status_message}</span>
																	: null}
																</p>
															</div>
														</div>
														</React.Fragment>
													)
												})
											:
												<div className="d-flex justify-content-center m-4">
													<p>There are no data on this criteria. Please try adjusting your filter.</p>
												</div>
											}
										</div>
									</React.Fragment>
									: null}

								</div>
							</div>
						:
							<div className="text-center mt-5" >
								<Spinner color="white" size="lg" />
							</div>
						}
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
JobListPanel.propTypes = {
	getResourceChart: PropTypes.func,  //Resource
	getTotalAssetCount: PropTypes.func,  //Resource
	getPurchaseDetails: PropTypes.func,  //Purchase
	getPurchaseByInstanceType: PropTypes.func,  //Purchase
	getTotalCount: PropTypes.func, //checks
	getDayWiseCount: PropTypes.func, //checks
}

const mapStateToProps = state => {
	return {
		accountsDetailsList: state.filters.accountsDetailsList
	}
}

export default connect(mapStateToProps, {
	getCompleteJobDetails
})(withRouter(JobListPanel))



