/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Dashboard.js
 * @author Prakash // on 05/06/2020
 * @copyright © 2020 Tvastar. All rights reserved.
 **************************************************/
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { UncontrolledTooltip, UncontrolledPopover, PopoverBody, Spinner } from 'reactstrap'
import _ from 'lodash'
import { AppWrapper } from '../common/AppWrapper'
import { listAllProviders, listAllAccounts, listAllServices } from '../../actions/commonAction'
import {
	getTotalCount,
	getDayWiseCount
} from '../../actions/detectors/detectorsAction'
import {
	getScore,
	getBillingCount,
	getDayWiseCountTags,
	getIssueStatusCount,
	getStatusCount,
	getCount,
	getCostTrend,
	getDailyScore
} from '../../actions/detectors/checksDashboardAction'
import { getTransitionTrend } from '../../actions/detectors/complianceDashboardAction'
import { DURATION_FILTER_OPTIONS } from '../../utils/constants'
import Chart from 'react-apexcharts'
import { URL_PATH } from '../../config/urlPath'
import moment from 'moment'
import { capitalizeFirstLetter, numberFormater, getAccountNameFromId } from '../../utils/utility'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Doughnut, Line } from 'react-chartjs-2';
import Select from 'react-select'

const environemntDropdown = [
	// {label:'All', value:'All'},
	{label:'Environment', value:'Environment'},
	{label:'Application', value:'Application'},
	{label:'Cost Centre', value:'Cost Centre'}
]

class GovernanceDashboard extends Component {
	p = 0;
	sliderWrap = React.createRef()
	sliderItemWrap = React.createRef()
	proEnvWrap = React.createRef()
	constructor(props) {
		super(props)		
		this.searchComponent = React.createRef()
		this.tabRef = React.createRef()		
		this.envRef = React.createRef()

		this.state = {
			left: 0,
			showLoading: false,
			providers: [],
			accounts: [],
			services: [],
			parentServices: [],
			selectedService: [],
			selectedProvider: '',
			selectedAccount: '',
			selectedCategory: [],
			selectedDuration: '+30d',
			filteredArray: [],
			categoryCounts: [],
			serviceNameCount:[],
			complianceIdCounts: [],	

			topEventsData: {},
			recentEventsData: [],
			eventCountData: {},
			score:[],
			dayWiseCountTags:[],
			categoryDayWiseCount: [],
			availabilitySum: 0, 
			availbilityPassSum: 0,
			governanceSum: 0, 
			governancePassSum: 0,
			taggingSum: 0, 
			taggingPassSum: 0,
			operationsSum: 0, 
			operationsPassSum: 0,
			securitySum: 0,
			securityPassSum:0,
			issueStatusCount:[],
			dayWiseRiskCounts: [],
			percentStatusCount: [],

			detailsTabNames: [
				{ name: 'Asset', value: 'asset_details' },
				{ name: 'Related Issues', value: 'related_issues' },
				{ name: 'Events', value: 'events' },
				{ name: 'Monitoring', value: 'monitoring_details' },
				{ name: 'Billing', value: 'billing' },
			],
			selectedTableTab: -1,
			showDetailsTabListDropdown: false,
			onClickSupressCreateButton: false,
			cost_pass_day_wise_count_data: [],
			cost_pass_day_wise_count_options: [],

			dayWiseCountGraphData: {},
			relatedIssuesData: [],
			assetConfigrationsList: [],
			summaryGraphData: {},

			tabItemsToShow: 0,

			detailsTabItemsToShow: 2,
			searchText: '',
			selectedMonitoringDuration: '',
			showAdvancedSearchModal: false,
			resetForm: 1,
			detectorKey:'',
			envDropIsOpen: false,
			selectedTag: 'Environment',
			bar_data:[], 
			bar_option:[],
			doughnut_data:[],
			doughnut_options:[],
			billingCount:[],
			processCount:[],

			pass_series: [],
			pass_options: [],
			fail_series: [],
			fail_options: [],
			totalSum: 0,
			passSum:0,
			failSum:0,
			
			riskTotalCounts:[],
			line_multi_data:[],
			line_multi_option:[],
			dayWiseRiskCountGraphData:[],
			
			

			accountIds: [],
			complianceCategoryScore: [],
			categoryScoreList: [],
			dailyCategoryScore: [],
			dailyScoreBarOptions: [],
			categoryList: [],
			compilanceDetailInput: '',
			costWiseTrendData: [],
			costSum: 0,
			//for next and previous week record
			showPrevNextRecord: 0,
			daysShownInGraph: 14,
			graphRecordsRemainigToShow: 0,
			disableNextWeekBtn: false,
			disablePreviousWeekBtn: true,
			hidePreviousNextBtn: false,

			transitionTrend: [],
            new_bar_transition_data:[],
            new_bar_transition_option:[],
            pending_bar_transition_data:[],
            pending_bar_transition_option:[],
            resolved_bar_transition_data:[],
            resolved_bar_transition_option:[],
            reoccured_bar_transition_data:[],
            reoccured_bar_transition_option:[],
            newTransitionPassSum: 0,
            newTransitionFailSum: 0,
            pendingTransitionFailSum: 0,
            resolvedTransitionFailSum: 0,
			reoccuredTransitionFailSum: 0,
			bar_security_data:[],
			bar_availability_data:[],
			bar_governance_data:[],
			bar_operations_data:[],
			bar_tagging_data:[],
			bar_category_option:[],

			multi_series: [],
			multi_options: [],

			accountWiseCount: [],
			regionWiseCount: [],

			totalItemShowing: 0,
			showingRightIcon: true,
			showingOffStart: 1,
			showingOff: 0,
			serviceWiseStart: 0,
			serviceWiseheight: 13
			
		}
		this.summaryGraph = "";
	}

	componentDidMount = () => {
		//document.addEventListener('keydown', this._handleKeyDown)
		//this.getAllCounts(true)
		this.setState({ showLoading: true })
		this.props.listAllProviders((promise, providers) => {
			if (promise) {
				this.setState({ providers })
				if (providers.length >= 1) {
					this.setState(
						{ selectedProvider: providers[0].provider_name },
					)
					this.filterIdentifiers('provider')
					this.setState({ showLoading: true })
				} else {
					this.filterIdentifiers()
				}
			} else {
				this.setState(
					{ providers: [] }, 
					() => this.filterIdentifiers()
				)
			}
		})
	}	

	// componentDidUpdate(prevProps, prevState) {
	// 	if (prevState.serviceWiseCount === this.state.serviceWiseCount) {
	// 		alert(12)
	// 	}
	// }
	
	// componentDidCatch(error, info) {
	// 	// log the error to our server with loglevel
	// 	// log.error({ error, info });
	// 	console.log('error',error)
	// 	console.log('info',info)
	// }
	
	/**
	 * Filter identifiers based on the filter selection
	 */
	filterIdentifiers = from => {
		if(from === 'envDrop') {
			this.setState({ envDropIsOpen: false })
		}
		if (this.props.location && this.props.location.state && this.props.location.state && this.state.selectedAccount === '' && this.state.selectedAccount !== 'all') {
			this.setState({
				selectedAccount: this.props.location.state.selectedAccount,
				selectedAccountName: this.props.location.state.selectedAccountName,
				selectedProvider: this.props.location.state.selectedProvider,
				selectedDuration: this.props.location.state.selectedDuration
			})
		}
		this.setState({ showLoading: false, filteredArray: [] })
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'all') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		
		if (from === 'provider') {
			this.props.listAllAccounts(params, (promise, accounts) => {
				if (promise) {
					this.setState({ accounts: accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())})
				} else {
					this.setState({ accounts: [] })
				}
			})

			this.props.listAllServices(params, (promise, services) => {
				let serviceArray = []
				let serviceResourceArray = []
				if (promise) {
					if(services) {
						services.length &&
						_.chain(services)
						.groupBy('resource_type')
						.map((value, key) => ({ label: key, sub: value }))
						.value()
						.forEach((service, i) => {
							serviceResourceArray.push(service)
							service.sub.forEach((item, i) => {
								serviceArray.push(item)
							})
						})
					}
					this.setState({ services, parentServices: serviceResourceArray, childServices: serviceArray})
				} else {
					this.setState({ services: [] })
				}
			})
		}
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount.toLowerCase()
		}
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.duration = this.state.selectedDuration
			params.account_id = this.state.selectedAccount
			params.aggregate_by = 'region'
			params.category = 'Governance'
			this.props.getTotalCount(params, (promise, regionWiseCount) => {
				if (promise) {
					this.setState({ accountWiseCount: regionWiseCount, regionWiseCount }, 
						() => {
							this.getAccountScore()
						}

					)
				} else {
					this.setState({ accountWiseCount: [], regionWiseCount: [], accScore: [] })
				}
			})
		} else {
			this.getAccountCount()		
			this.getAccountScore()	
		}

		//this.getCategoryDayWiseCount()
		this.getScore();
		this.getScore('account');
		this.getDailyScore();
		this.getBillingCount();
		this.getIssueStatusCount();
		this.getDayWiseCountTags();
		this.getStatusCount();
		this.getCount();
		this.getTotalCount();		
		this.getServiceCount()
		this.getDayWiseCount();				
		this.getDayWiseRiskCount();		
		//this.getAllCounts('resource_type')
		this.getTransitionTrend()

		
	}

	getAccountCount = () => {
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'all') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		params.duration = this.state.selectedDuration
		params.aggregate_by = 'account_id'
		params.category = 'Governance'
		this.props.getTotalCount(params, (promise, accountWiseCount) => {
			if (promise) {
				this.setState({ accountWiseCount, regionWiseCount: [] })
			} else {
				this.setState({ accountWiseCount: [], regionWiseCount: [] })
			}
		})
	}

	getAccountScore = () => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
			params.group_by = ["region"]
			params.score_category = ["ALL"]
		} else {
			params.group_by = ["account_id"]
			params.score_category = ["ALL"]
		}
		params.category = 'Governance'
		this.props.getScore(params, (promise, accScore) => {
			if (promise) {
				let obj = {}
				accScore.forEach(item => {
					if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
						obj[item.region] = item
					} else {
						obj[item.account_id] = item
					}
				})
				this.setState({ accScore: obj })
			} else {
				this.setState({ accScore: {} })
			}
			
		})
	}

	getServiceCount = () => {
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'all') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount.toLowerCase()
		}
		if(this.state.selectedService.length) {
			params.resource_type = this.state.selectedService
		}
		params.duration = this.state.selectedDuration
		params.aggregate_by = ["service_name", "risk"]
		params.category = 'Governance'
		this.props.getTotalCount(params, (promise, serviceWiseCount) => {
			if (promise) {
				let checkRiskArray = []
				
				if(serviceWiseCount.length) {
					_.chain(serviceWiseCount)
					.groupBy('service_name')
					.map((value, key) => ({ label: key, sub: value }))
					.value()
					.forEach(( cat, i) => {
						let dataRow = {}
						let totalFail = 0
						dataRow.name = cat.label
						cat.sub.forEach(item=> {
							totalFail = totalFail + item.fail_count 
							if(item.risk === 'Low') {
								dataRow.Low = item.fail_count 
							}
							if(item.risk === 'Medium') {
								dataRow.Medium = item.fail_count 
							}
							if(item.risk === 'High') {
								dataRow.High = item.fail_count 
							}
							if(item.risk === 'Critical') {
								dataRow.Critical = item.fail_count 
							}
						})

						dataRow.totalFail = totalFail
						if(dataRow.Low === undefined) {
							dataRow.Low = 0
						}
						if(dataRow.Medium === undefined) {
							dataRow.Medium = 0
						}
						if(dataRow.High === undefined) {
							dataRow.High = 0
						}
						if(dataRow.Critical === undefined) {
							dataRow.Critical = 0
						}
						checkRiskArray.push(dataRow)				
					})
				}
				
				checkRiskArray = checkRiskArray.filter(risk => risk.totalFail > 0)
				checkRiskArray = _.orderBy(checkRiskArray, ['totalFail'], ['desc'])

				let filterServiceCountLength = checkRiskArray.length;
				if(checkRiskArray.length > this.state.serviceWiseheight) {
					filterServiceCountLength = this.state.serviceWiseheight
				}
				this.setState({ serviceWiseStart: 0, filterServiceCountLength, serviceWiseCount: checkRiskArray, filterServiceCount : checkRiskArray, serviceLoading: false })
			} else {
				this.setState({ serviceWiseStart: 0, filterServiceCountLength: this.state.serviceWiseheight, serviceWiseCount: [], filterServiceCount: [], serviceLoading: false })
			}
		})
	}

	// componentWillUnmount() {
	// 	document.removeEventListener('keydown', this._handleKeyDown)
	// }
	
	// _handleKeyDown = event => {
	// 	switch (event.keyCode) {
	// 		case 27:
	// 			this.setState({ showAdvancedSearchModal: false })
	// 			break
	// 		default:
	// 			break
	// 	}
	// }

	// handleRightClick = () => {
	// 	this.ref.rightScroller ? (this.ref.rightScroller.scrollRight +=200) : null
	// }

	// handleLeftClick = () => {
	// 	this.ref.leftScroller ? (this.ref.leftScroller.scrollLeft -=200) : null
	// }

	
	handleClickOutside(event) {
		if (this.envRef && !this.envRef.current.contains(event.target)) {
			this.setState({ envDropIsOpen: false })
		} else {
			this.setState({ envDropIsOpen: true })
		}
	}

	
	getBillingCount = () =>{
		// let params = {}
		// params.provider = this.state.selectedProvider
		// params.duration = this.state.selectedDuration
		// if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
		// 	params.account_id = this.state.selectedAccount
		// }
		// this.props.getBillingCount(params, (promise, billingCount) => {
		// 	if (promise) {
		// 		this.setState({ billingCount }, 
		// 			//() => this.getDayWiseCountTags() 
		// 		)
		// 	} else {
		// 		this.setState({ billingCount: [] })
		// 	}
		// 	this.getDayWiseCountTags() 
		// })
	}

	getScore = account => {
		let params = {}
		
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		} 
		if(account === undefined || account === '') {
			params.score_category = ["overall"]
			params.group_by =["tags"]
			params.tags =[{"key":this.state.selectedTag}]
		} else {
			params.score_category = ["ALL"]
			//params.duration = '+10d'
		}
		params.category = 'Governance'
		this.props.getScore(params, (promise, score) => {			
			if (promise) {
				if(account === undefined || account === '') {
					if(score[0] !== undefined &&score[0].tags !== undefined) {
						this.setState({ score, detectorKey: score[0].tags[0].value })
						this.getAllCounts('resource_type');
						this.getCostTrend()
					} else {
						this.setState({score: [], detectorKey: [] })
						this.getCostTrend()
					}
				} else {
					let catArray = []
					let secCategoty = {}
					let availCategoty = {}
					let goverCategoty = {}
					let operCategoty = {}
					let tagCategoty = {}
					if(score[0] !== undefined && (score[0].identifier_category !== null || score[0].identifier_category !== undefined)) {
						Object.entries(score[0].identifier_category).forEach(([key, value]) => {
							if(key === 'Security') {
								secCategoty.category = key
								secCategoty.data = value.split("/")[0]
							}
							
							if(key === 'Governance') {
								goverCategoty.category = key
								goverCategoty.data = value.split("/")[0]
							}

							if(key === 'Availability') {
								availCategoty.category = key
								availCategoty.data = value.split("/")[0]
							}

							if(key === 'Operations') {
								operCategoty.category = key
								operCategoty.data = value.split("/")[0]
							}

							if(key === 'Tagging') {
								tagCategoty.category = key
								tagCategoty.data = value.split("/")[0]
							}
						});
					}

					if(Object.entries(secCategoty).length === 0) {
						secCategoty.category = 'Security'
						secCategoty.data = 0
					}
					if(Object.entries(goverCategoty).length === 0) {
						goverCategoty.category = 'Governance'
						goverCategoty.data = 0
					}
					if(Object.entries(availCategoty).length === 0) {
						availCategoty.category = 'Availability'
						availCategoty.data = 0
					}
					if(Object.entries(operCategoty).length === 0) {
						operCategoty.category = 'Operations'
						operCategoty.data = 0
					}
					if(Object.entries(tagCategoty).length === 0) {
						tagCategoty.category = 'Tagging'
						tagCategoty.data = 0
					}
					

					catArray.push(secCategoty)
					catArray.push(goverCategoty)
					catArray.push(availCategoty)
					catArray.push(operCategoty)
					catArray.push(tagCategoty)
					
					let compilanceCat = []
					if(score[0] !== undefined) {
						compilanceCat = score[0].compliance_category
					}
					this.setState({ complianceCategoryScore: compilanceCat, categoryScoreList: catArray},
					() => this.getAllCounts('compliance.complianceId'))
				}
			} else {
				this.setState({ score: [] })
			}

			//getting day wise count tags
			// if (this.timeout) clearTimeout(this.timeout);
			// this.timeout = setTimeout(() => {
			// 	this.getDayWiseCountTags();
			// }, 100);
			
		})
	}

	getDailyScore = () => {
		let params ={}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = '+7d'
		params.score_category = ["ALL"]
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		params.category = 'Governance'
		this.props.getDailyScore(params, (promise, dailyScore) => {			
			if (promise) {
				let catArray= []
				let secCategoty = {}
				let availCategoty = {}
				let goverCategoty = {}
				let operCategoty = {}
				let tagCategoty = {}
				Object.entries(dailyScore.data.identifier_category).forEach(([key, value]) => {
					if(key === 'Security') {
						secCategoty.category = key
						let data = []
						Object.entries(value).forEach(([catKey, catValue]) => {
							data.push(catValue.split("/")[0])
						})
						secCategoty.data = data
					}
					
					if(key === 'Governance') {
						goverCategoty.category = key
						let data = []
						Object.entries(value).forEach(([catKey, catValue]) => {
							data.push(catValue.split("/")[0])
						})
						goverCategoty.data = data
					}

					if(key === 'Availability') {
						availCategoty.category = key
						//availCategoty.data = value
						let data = []
						Object.entries(value).forEach(([catKey, catValue]) => {
							data.push(catValue.split("/")[0])
						})
						availCategoty.data = data
					}

					if(key === 'Operations') {
						operCategoty.category = key
						let data = []
						Object.entries(value).forEach(([catKey, catValue]) => {
							data.push(catValue.split("/")[0])
						})
						operCategoty.data = data
					}

					if(key === 'Tagging') {
						tagCategoty.category = key
						let data = []
						Object.entries(value).forEach(([catKey, catValue]) => {
							data.push(catValue.split("/")[0])
						})
						tagCategoty.data = data
					}
				});
				catArray.push(secCategoty)
				catArray.push(goverCategoty)
				catArray.push(availCategoty)
				catArray.push(operCategoty)
				catArray.push(tagCategoty)

				this.setState({ dailyCategoryScore: dailyScore, categoryList: catArray }, () => this.dayWiseCategoryFailGraph())
				
			} else {
				this.setState({ score: [] })
			}
			
		})
	}

	envSlideLeft() {
		this.proEnvWrap.current.scrollLeft -= 50
	}

	envSlideItemRight() {
		let totalCompliance = this.state.score.length
		if(totalCompliance > 5) {
			totalCompliance = 5
		}

		let reqTabWidth = 0
		for (let i = 0; i < totalCompliance; i++) {
			reqTabWidth += this.proEnvWrap.current.childNodes[i].getBoundingClientRect().width
		}
		const averageSliderItemWidth = reqTabWidth/totalCompliance
		const sliderWrapWidth = this.proEnvWrap.current.getBoundingClientRect().width
		const totalItemShowing = sliderWrapWidth/averageSliderItemWidth;

		//const index = this.state.complianceCategoryScore.indexOf(this.state.selectedSeries)
		//const index = 1
		
		if (totalItemShowing < totalCompliance) {
			this.proEnvWrap.current.scrollLeft += 50
			
		} else {
		 	this.proEnvWrap.current.scrollLeft = 0
		}
	}
	
	slideLeft() {
		let move = 0
		move = parseInt(this.state.totalItemShowing)
		this.setState({ totalItemShowing: this.state.totalItemShowing - 1 })
		
		const totalCompliance = this.state.complianceIdCounts.length;
		let reqTabWidth = 0
		for (let i = 0; i < totalCompliance; i++) {
			reqTabWidth += this.sliderWrap.current.childNodes[i].getBoundingClientRect().width
		}
		const averageSliderItemWidth = reqTabWidth/totalCompliance
		const sliderWrapWidth = this.sliderWrap.current.getBoundingClientRect().width
		const totalItemShowing = sliderWrapWidth/averageSliderItemWidth;
		
		if(move > totalItemShowing) {
			if (this.sliderWrap.current.childNodes[move-1] && this.sliderWrap.current.childNodes[move-1].getBoundingClientRect().width) {
				this.sliderWrap.current.scrollLeft -= this.sliderWrap.current.childNodes[move-1].getBoundingClientRect().width	
				this.setState({ showingRightIcon: true, showingOff: parseInt(this.state.showingOff - 1), showingOffStart: this.state.showingOffStart < 1 ? 1 : parseInt(this.state.showingOffStart-1)})
			} else {
				this.setState({ showingLeftIcon: false, totalItemShowing: totalItemShowing })
			}
		} else {
			this.setState({ showingLeftIcon: false, totalItemShowing: totalItemShowing })
		}
	}

	// slideRight() {
	// 	this.sliderWrap.current.scrollLeft += 110
	// }

	slideItemRight(item) {		
		const totalCompliance = this.state.complianceIdCounts.length;
		//const sliderItemWidth = this.sliderItemWrap.current.getBoundingClientRect().width + 
		//parseInt(12);
		let reqTabWidth = 0
		for (let i = 0; i < totalCompliance; i++) {
			reqTabWidth += this.sliderWrap.current.childNodes[i].getBoundingClientRect().width
		}
		const averageSliderItemWidth = reqTabWidth/totalCompliance
		const sliderWrapWidth = this.sliderWrap.current.getBoundingClientRect().width
		const totalItemShowing = sliderWrapWidth/averageSliderItemWidth;
		//const index = this.state.complianceCategoryScore.indexOf(this.state.selectedSeries)
		//const index = 1
		let move = 0
		if(this.state.totalItemShowing === 0) {
			this.setState({ totalItemShowing: totalItemShowing })
			move = parseInt(totalItemShowing)
		} else {
			move = parseInt(this.state.totalItemShowing + 1)
			this.setState({ totalItemShowing: this.state.totalItemShowing + 1 })
		}
		if (this.sliderWrap.current.childNodes[move-1] && this.sliderWrap.current.childNodes[move-1].getBoundingClientRect().width) {
			if(item === '') {
				this.sliderWrap.current.scrollLeft += this.sliderWrap.current.childNodes[move-1].getBoundingClientRect().width	
			}
			if(!this.state.showingOff && this.state.showingOff < totalItemShowing) {
				this.setState({ showingOff: parseInt(totalItemShowing), showingLeftIcon: true })
			} else{
				this.setState({ showingOffStart: (this.state.showingOffStart + 1), showingOff: parseInt(this.state.showingOff + 1), showingLeftIcon: true })
			}

			//const sliderCenter = sliderWrapWidth / 2 - 110
			//const left = index * 110 - sliderCenter
			//this.sliderWrap.current.scrollLeft = left		
			
		} else {
			this.setState({ showingRightIcon: false, totalItemShowing: this.state.complianceIdCounts.length })
		 	//this.sliderWrap.current.scrollLeft = 0
		}
	}

	getDayWiseCountTags = () =>{
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		params.aggregate_by = "tag"
		//params.risk = this.state.selectedRisk,
		params.tags = [{"key":this.state.selectedTag, "value": this.state.detectorKey}]
		params.category = 'Governance'
		this.props.getDayWiseCountTags(params, (promise, dayWiseCountTags) => {
			if (promise) {
				this.setState({dayWiseCountTags:dayWiseCountTags},()=>{
					this.showSummaryGraph();
				});
			} else {
				this.setState({ dayWiseCountTags: [] })
			}
		})
	}

	getStatusCount = () =>{
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		params.past_duration = "week"
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		params.category = 'Governance'
		this.props.getStatusCount(params, (promise, percentStatusCount) => {
			if (promise) {
				this.setState({percentStatusCount})
			} else {
				this.setState({ percentStatusCount: [] })
			}
		})
	}

	getIssueStatusCount = () =>{
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		//for week +7d, for month +30d
		//params.past_duration = "week"
		if(this.state.selectedDuration === '7d' || this.state.selectedDuration === '+7d' || this.state.selectedDuration === '+14d') {
			params.past_duration = "7d"
		} else {
			params.past_duration = "30d"
		}
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		params.category = 'Governance'
		this.props.getIssueStatusCount(params, (promise, issueStatusCount) => {
			// console.log('issueStatusCount',issueStatusCount)
			if (promise) {
				this.setState({issueStatusCount: issueStatusCount},()=>{
					// this.showSummaryGraph();
				});
			} else {
				this.setState({ issueStatusCount: [] })
			}
		})
	}

	getCount = () =>{
		let params = {}
		
		params.fields = ["asset_id","event_id","resource_type"]
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		params.category = 'Governance'
		this.props.getCount(params, (promise, processCount) => {
			if (promise) {
				this.setState({processCount:processCount});
			} else {
				this.setState({ processCount: [] })
			}
		})
	}

	getTotalCount = () =>{
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		params.aggregate_by = 'risk'
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		params.category = 'Governance'
		this.props.getTotalCount(params, (promise, riskTotalCounts) => {
			if (promise) {
				let riskCountArrays = []
				let criticalRow = {}
				let highRow = {}
				let mediumRow = {}
				let lowRow = {}
				if(riskTotalCounts.length > 0) {
					let i = 0;
					for (const [key] of Object.entries(riskTotalCounts)) {
						let value = riskTotalCounts[key]
						i++;
						if(value.risk === 'Critical') {
							criticalRow.NA_count= value.NA_count
							criticalRow.duration= value.duration
							criticalRow.fail_count= value.fail_count
							criticalRow.pass_count= value.pass_count
							criticalRow.pending_count= value.pending_count
							criticalRow.provider= value.provider
							criticalRow.reoccurred_count= value.reoccurred_count
							criticalRow.resolved_count= value.resolved_count
							criticalRow.risk= value.risk
						}

						if(value.risk === 'High') {
							highRow.NA_count= value.NA_count
							highRow.duration= value.duration
							highRow.fail_count= value.fail_count
							highRow.pass_count= value.pass_count
							highRow.pending_count= value.pending_count
							highRow.provider= value.provider
							highRow.reoccurred_count= value.reoccurred_count
							highRow.resolved_count= value.resolved_count
							highRow.risk= value.risk
						}

						if(value.risk === 'Medium') {
							mediumRow.NA_count= value.NA_count
							mediumRow.duration= value.duration
							mediumRow.fail_count= value.fail_count
							mediumRow.pass_count= value.pass_count
							mediumRow.pending_count= value.pending_count
							mediumRow.provider= value.provider
							mediumRow.reoccurred_count= value.reoccurred_count
							mediumRow.resolved_count= value.resolved_count
							mediumRow.risk= value.risk
						}

						if(value.risk === 'Low') {
							lowRow.NA_count= value.NA_count
							lowRow.duration= value.duration
							lowRow.fail_count= value.fail_count
							lowRow.pass_count= value.pass_count
							lowRow.pending_count= value.pending_count
							lowRow.provider= value.provider
							lowRow.reoccurred_count= value.reoccurred_count
							lowRow.resolved_count= value.resolved_count
							lowRow.risk= value.risk
						}
						if(i === riskTotalCounts.length) {
							if(Object.keys(lowRow).length === 0) {
								lowRow.NA_count= 0
								lowRow.duration= this.state.selectedDuration
								lowRow.fail_count= 0
								lowRow.pass_count= 0
								lowRow.pending_count= 0
								lowRow.provider= this.state.selectedProvider
								lowRow.reoccurred_count= 0
								lowRow.resolved_count= 0
								lowRow.risk= 0
							}
							if(Object.keys(mediumRow).length === 0) {
								mediumRow.NA_count= 0
								mediumRow.duration= this.state.selectedDuration
								mediumRow.fail_count= 0
								mediumRow.pass_count= 0
								mediumRow.pending_count= 0
								mediumRow.provider= this.state.selectedProvider
								mediumRow.reoccurred_count= 0
								mediumRow.resolved_count= 0
								mediumRow.risk= 0
							}
							if(Object.keys(highRow).length === 0) {
								highRow.NA_count= 0
								highRow.duration= this.state.selectedDuration
								highRow.fail_count= 0
								highRow.pass_count= 0
								highRow.pending_count= 0
								highRow.provider= this.state.selectedProvider
								highRow.reoccurred_count= 0
								highRow.resolved_count= 0
								highRow.risk= 0
							}
							if(Object.keys(criticalRow).length === 0) {
								criticalRow.NA_count= 0
								criticalRow.duration= this.state.selectedDuration
								criticalRow.fail_count= 0
								criticalRow.pass_count= 0
								criticalRow.pending_count= 0
								criticalRow.provider= this.state.selectedProvider
								criticalRow.reoccurred_count= 0
								criticalRow.resolved_count= 0
								criticalRow.risk= 'Critical'
							}
						}
					}
				}
				riskCountArrays.push(criticalRow)
				riskCountArrays.push(highRow)
				riskCountArrays.push(mediumRow)
				riskCountArrays.push(lowRow)
				
				this.setState({ riskTotalCounts: riskCountArrays })
			} else {
				this.setState({ riskTotalCounts: [] })
			}
		})
	}

	getCostTrend = () => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		//params.duration = '+10d'
		params.duration = this.state.selectedDuration
		params.category = 'Cost'
		params.aggregate_by = 'category'
		params.tags = [{"key":this.state.selectedTag, "value": this.state.detectorKey}]
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		params.category = 'Governance'
		this.props.getCostTrend(params, (promise, costWiseTrendData) => {
			if (promise) {
				this.setState({costWiseTrendData});
				this.dayWiseCostTrendGraph()
				
			} else {
				this.setState({ costWiseTrendData: [] })				
			}
		})
	}

	dayWiseCostTrendGraph = () => {
		let graphData = this.state.costWiseTrendData;
		let costData = [];
		let costSum = 0;
		let labels = [];
		if(graphData){
			labels = graphData.labels;
			costData =  graphData.data.cost
				
			costSum = costData.reduce(function(a, b){
				return a + b;
			}, 0);
		}

		let formated_labels = [];
		if(labels){
			for (const [key] of Object.entries(labels)) {
				formated_labels.push(moment(labels[key]).format('DD MMM Y'))
			}
		}

		let line_options = {
			// legend: {
            //     display: true,                
			// 	position: 'bottom',
			// 	labels: {
			// 		usePointStyle: true,
			// 		fill: true,
			// 		fontSize: 5
			// 	},
			// 	fontSize: 10,
			// },
			legend: {
				display: false,
			},
			scales:{
				xAxes: [{
					display: false, //this will remove all the x-axis grid lines
					gridLines: {
						display:false,	
					}
				}],
				yAxes: [{
					display: false, //this will remove all the y-axis grid lines
					gridLines: {
						display:false,	
					}					
				}]
			},
			maintainAspectRatio: true
		}

	
		let line_pass_cost_datasetsColumn = [
			{
				label: '',
				fill: true,
				backgroundColor: "#CEFBFF",
				borderColor: "#7BB9BD",
				borderCapStyle: 'butt',
				borderWidth: 1.5,
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: 'miter',
				pointBackgroundColor: "#CEFBFF",
				pointBorderColor: "#7BB9BD",
				pointBorderWidth: 1,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: "#CEFBFF",
				pointHoverBorderColor: "#7BB9BD",
				pointHoverBorderWidth: 2,
				pointRadius: 0,
				pointHitRadius: 2, //show tooltip inside bar if not '0'
				data: costData

			}
		];

		let line_pass_cost_datas = {labels:formated_labels, datasets: line_pass_cost_datasetsColumn}; 
		
		this.setState({cost_pass_day_wise_count_data:line_pass_cost_datas, cost_pass_day_wise_count_options:line_options, costSum: costSum});
	}

	getDayWiseCount = cost =>{
		let params = {}
		
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		params.category = 'Governance'
		this.props.getDayWiseCount(params, (promise, dayWiseCountGraphData) => {
			if (promise) {
				this.setState({dayWiseCountGraphData:dayWiseCountGraphData, multi_pass_fail_series: [], multi_pass_fail_options: [] });
				this.dayWiseCountGraphApex();
				this.multidayWiseCountGraphApex();
				this.getCategoryDayWiseCount() //catgory circular graph old
			} else {
				this.setState({ dayWiseCountGraphData: [] })
			}
		})		
	}

	getTagwiseChangeDetails = () => {
		this.setState({showPrevNextRecord: 0, daysShownInGraph: 14, graphRecordsRemainigToShow: 0, disableNextWeekBtn: false, disablePreviousWeekBtn: true })
		// this.getBillingCount();
		// this.getIssueStatusCount();
		// this.getStatusCount();
		// this.getCount();
		// this.getTotalCount();
		// this.getAllCounts('resource_type')
		this.getDayWiseCountTags();
		// this.getDayWiseCount();
		// this.getCostTrend()
		// this.getCategoryDayWiseCount()
		// this.getDayWiseRiskCount();
	}

	//to ge the sum of catefory fail count start
	getCategoryDayWiseCount = () =>{
		let params = {}
		params.aggregate_by = 'category'
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = '+7d'
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		params.category = 'Governance'
		this.props.getDayWiseCount(params, (promise, categoryDayWiseCount) => {
			let availabilitySum = 0
			let availbilityPassSum = 0;
			let operationsSum = 0
			let operationsPassSum = 0
			let securitySum = 0;
			let securityPassSum = 0;
			let taggingSum = 0;
			let taggingPassSum = 0;
			let governanceSum = 0;
			let governancePassSum = 0;
			if (promise) {
				if(categoryDayWiseCount.Availability && categoryDayWiseCount.Availability.data.fail.length > 0) {
					let availbilityData = categoryDayWiseCount.Availability.data.fail
					availabilitySum = availbilityData.reduce(function(a, b){
						return a + b;
					}, 0);
					availbilityPassSum = categoryDayWiseCount.Availability.data.pass.reduce(function(a, b){
						return a + b;
					}, 0);
				}
				if(categoryDayWiseCount.Operations && categoryDayWiseCount.Operations.data.fail.length > 0) {
					let operationsData = categoryDayWiseCount.Operations.data.fail
					operationsSum = operationsData.reduce(function(a, b){
						return a + b;
					}, 0);
					operationsPassSum = categoryDayWiseCount.Operations.data.pass.reduce(function(a, b){
						return a + b;
					}, 0);
				}
				if(categoryDayWiseCount.Security && categoryDayWiseCount.Security.data.fail.length > 0) {
					let securityData = categoryDayWiseCount.Security.data.fail
					securitySum = securityData.reduce(function(a, b){
						return a + b;
					}, 0);
					securityPassSum = categoryDayWiseCount.Security.data.pass.reduce(function(a, b){
						return a + b;
					}, 0);
				}
				if(categoryDayWiseCount.Tagging && categoryDayWiseCount.Tagging.data.fail.length > 0) {
					let taggingData = categoryDayWiseCount.Tagging.data.fail
					taggingSum = taggingData.reduce(function(a, b){
						return a + b;
					}, 0);
					taggingPassSum = categoryDayWiseCount.Tagging.data.pass.reduce(function(a, b){
						return a + b;
					}, 0);
				}
				if(categoryDayWiseCount.Governance && categoryDayWiseCount.Governance.data.fail.length > 0) {
					let governanceData = categoryDayWiseCount.Governance.data.fail
					governanceSum = governanceData.reduce(function(a, b){
						return a + b;
					}, 0);
					governancePassSum = categoryDayWiseCount.Governance.data.pass.reduce(function(a, b){
						return a + b;
					}, 0);
				}
				
				this.setState({categoryDayWiseCount, availabilitySum, availbilityPassSum, governanceSum, governancePassSum, taggingSum, taggingPassSum, operationsSum, operationsPassSum, securitySum, securityPassSum});				
				//this.dayWiseCategoryFailGraph();
			} else {
				this.setState({ categoryDayWiseCount: [] })
			}
		})
	}
	//to ge the sum of catefory fail count end

	dayWiseCategoryFailGraph = () => {
		let graphData = this.state.categoryList
		let graphLabel = this.state.dailyCategoryScore.labels

		let backgroundColor = '#7D57C5';
		let label = []
		for(let i=0;i<graphLabel.length;i++) {			
			label.push(moment(graphLabel[i]).format('DD MMM Y'))
		}
		for(let i=0;i<graphData.length;i++) {			
			let dailyScore = [];
			let barChartData = [];
			
			barChartData = {
				name: graphData[i].category,
				data: graphData[i].data,

			};
			dailyScore.push(barChartData)

			const barChartOptions = {
                tooltip: {
                    enabled: true,
                    //enabledOnSeries: undefined,
                    //shared: false,
                    //followCursor: false,
                    //intersect: false,
                    //inverseOrder: false,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
						let val  = series[seriesIndex][dataPointIndex];
						val = Math.abs(Math.round(val))
						//let labelName = w.globals.initialSeries[seriesIndex].name
						//let val = str.replace('-',series[seriesIndex][dataPointIndex]);
						//return '<div className="arrow_box">' + val + '</div>'
						return '<div className="arrow_box"> '+ w.globals.labels[dataPointIndex] + ' score ' + val + '</div>'
                    },
                    //fillSeriesColor: false,
                    //theme: true,
                    style: {
                    fontSize: '9px',
                    },
                    onDatasetHover: {
                        highlightDataSeries: true,
                    },
                    x: {
                        //show: true,
                        format: 'dd MMM Y',
                        formatter: undefined,
                    },
                    y: {
                        show: true,
                        format: 'dd',
                        //formatter: (value) => { return value },
                        formatter: undefined,
                        title: {
                            formatter: (seriesName) => seriesName,
                        },
                    },
                    z: {
                        formatter: undefined,
                        title: 'Size: 9px'
                    },
                    marker: {
                        show: true,
                    },
                    fixed: {
                        enabled: false,
                        position: 'topRight',
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
                legend: {
                    position: 'bottom',
                    fontSize: '11px',
                    markers: {
                        width: 9,
                        height: 9,
                        strokeWidth: 0,
                        strokeColor: '#fff',
                        fillColors: undefined,
                        radius: 9,
                        customHTML: undefined,
                        onClick: undefined,
                        offsetX: 0,
                        offsetY: 0
                    }
                },	
                chart: {
                    toolbar: {
                        show: false,
                    },
                    sparkline: {
                        enabled: true
                    },
                    type: 'bar',
                    height: 200,
                    stacked: false,
                },
                //colors: ['#1FCCE2'],<
                colors: [backgroundColor],
        
                plotOptions: {
                bar: {
                    horizontal: false,
                    barHeight: '50%',
                    startingShape: 'rounded',
                    endingShape: 'rounded',
                    columnWidth: '50%',
                    distributed: false,
                    rangeBarOverlap: false,
                    colors: {
                        backgroundBarColors: ['#BDBDBD'],
                        backgroundBarOpacity: 1,
                        backgroundBarRadius: 2,
                    }        
                },          
                },
                dataLabels: {
                enabled: false
                },
                stroke: {
                width: 0,
                colors: ["#fff"]
                },
                
                grid: {  				        
                xaxis: {
                    lines: {
                    show: false
                    },
                },
                yaxis: {
                    lines: {
                    show: false
                    }
                },
                },
                yaxis: {
                    labels: {
                        show:false,
                        style: {
                            fontSize: '9px'
                        }
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        fontSize: '9px',
                        show: false,
                        borderType: 'none',
                        color: '#78909C',
                        width: 6,
                        offsetX: 0,
                        offsetY: 0
                    },
                },
                xaxis: {				
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        tickAmount: 15,
                        fontSize: '9px',
                        show: false,
                        borderType: 'none',
                        color: '#78909C',
                        width: 6,
                        offsetX: 0,
                        offsetY: 0
                    },
                    categories: label,
                    labels: {
                        show:false,
                        style: {
                            fontSize: '9px'
                        }
                    }
                },
			}

			if(graphData[i].category === "Security") {
				this.setState({bar_security_data:dailyScore, bar_category_option:barChartOptions });
			} else if (graphData[i].category === "Availability") {
				this.setState({ bar_availability_data:dailyScore, bar_category_option:barChartOptions });
			} else if (graphData[i].category === "Governance") {
				this.setState({ bar_governance_data:dailyScore, bar_category_option:barChartOptions });
			} else if (graphData[i].category === "Operations") {
				this.setState({ bar_operations_data:dailyScore, bar_category_option:barChartOptions });
			} else if (graphData[i].category === "Tagging") {
				this.setState({ bar_tagging_data:dailyScore, bar_category_option:barChartOptions });
			}
		}

	}

	getDayWiseRiskCount = () =>{
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		params.aggregate_by = "risk"
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		params.category = 'Governance'
		this.props.getDayWiseCount(params, (promise, dayWiseRiskCountGraphData) => {
			if (promise) {
				this.setState({dayWiseRiskCountGraphData:dayWiseRiskCountGraphData, multi_options: [], multi_series: [] });
				//this.dayWiseRiskCountGraph();
				this.dayWiseRiskCountApexGraph()
			} else {
				this.setState({ dayWiseRiskCountGraphData: [] })
			}
		})
	}

	/**
	 * Method to get all category counts
	 */
	getAllCounts = aggregateBy => {
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'all') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if (!this.state.selectedDuration !== '' && this.state.selectedDuration !== 'all') {
			params.duration = this.state.selectedDuration
		}
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		
		if(aggregateBy === 'resource_type') {
			params.aggregate_by = 'resource_type'
			params.tags = [{"key":this.state.selectedTag, "value": this.state.detectorKey}]
		}
		params.aggregate_by = aggregateBy
		params.category = 'Governance'
		this.props.getTotalCount(params, (promise, getAllCounts) => {
			if (promise) {
				if(aggregateBy === 'category') {
					this.setState({ categoryCounts: getAllCounts, tabItemsToShow: getAllCounts.length })
				} else if(aggregateBy === 'compliance.complianceId') {
					let complianceCategoryArray = []
					if(getAllCounts !== null) {
						getAllCounts.forEach((item, i) => {
							item.value = '0';
							if(Object.entries(this.state.complianceCategoryScore).length){
								Object.entries(this.state.complianceCategoryScore).sort().forEach(([key, value]) => {
									if(item.complianceId === key) {
										item.value = (value.split("/"))[0]
									}
								})
							}
							complianceCategoryArray.push(item)
						})
					}
					this.setState({ complianceIdCounts: complianceCategoryArray }, 
						() => this.slideItemRight('initiate')//get number of item showing
					)
				} 
				// else if(aggregateBy === 'resource_type') {
				// 	this.setState({ serviceNameCount: _.orderBy(getAllCounts, ['fail_count'], ['desc'])})
				// 	this.showDoughnutGraph();	
				// } 
			} else {				
				if(aggregateBy === 'compliance.complianceId') {
					this.setState({ complianceIdCounts: [] })
				} else if(aggregateBy === 'category') {
					this.setState({ categoryCounts: [] })
				} else if(aggregateBy === 'resource_type') {
					this.setState({ serviceNameCount: [] })
				}
			}
			//console.log(';;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;',this.state)
		})
	}	
	
	/**
	 * Method to show summary graph
	 */
	
	dynamicColors = () => {
		let r = Math.floor(Math.random() * 255);
		let g = Math.floor(Math.random() * 255);
		let b = Math.floor(Math.random() * 255);
		return "rgb(" + r + "," + g + "," + b + ")";
	};

	dayWiseRiskCountGraph= () => {
		let graphData = this.state.dayWiseRiskCountGraphData;
		let line_multi_labelsColumn = [];
		let line_multi_datasetsColumn = [];
		let multiLabels = ''
		let criticalData = []
		let highData = []
		let mediumData = []
		let lowData = []
		if(graphData){
			if(graphData.Critical) {
				multiLabels = graphData.Critical.labels
				criticalData = graphData['Critical'].data.fail
			}
			if(graphData.High) {
				multiLabels = graphData.High.labels
				highData = graphData['High'].data.fail
			}
			if(graphData.Medium) {
				multiLabels = graphData.Medium.labels
				mediumData = graphData['Medium'].data.fail
			} 
			if(graphData.Low) {
				multiLabels = graphData.Low.labels
				lowData = graphData['Low'].data.fail
			}
			line_multi_labelsColumn = multiLabels;
			line_multi_datasetsColumn = [				
				{
					label: 'Critical',
					fill: true,
					//fillColor: gradient,
					//borderColor: "#37246E",
					backgroundColor: "#26B68F",
					borderColor: "#26B68F",
					borderCapStyle: 'butt',
					borderDash: [],
					borderDashOffset: 0.0,
					borderJoinStyle: 'miter',
					borderWidth: 1,
					lineTension: 0.5,
					pointBackgroundColor: "#26B68F",
					pointBorderColor: "#26B68F",
					pointBorderWidth: 1,
					pointHoverRadius: 5,
					pointHoverBackgroundColor: "#26B68F",
					pointHoverBorderColor: "#26B68F",
					pointHoverBorderWidth: 2,
					pointRadius: 0,
					pointHitRadius: 10,
					data: criticalData

				},
				{
					label: 'High',
					fill: true,
					backgroundColor: "#2ED1BE",
					borderColor: "#2ED1BE",
					//borderColor: "#EBE56F",
					borderCapStyle: 'butt',
					borderDash: [],
					borderDashOffset: 0.0,
					borderJoinStyle: 'miter',
					borderWidth: 1,
					lineTension: 0.5,
					pointBackgroundColor: "#2ED1BE",
					pointBorderColor: "#2ED1BE",
					pointBorderWidth: 1,
					pointHoverRadius: 5,
					pointHoverBackgroundColor: "#2ED1BE",
					pointHoverBorderColor: "#2ED1BE",
					pointHoverBorderWidth: 2,
					pointRadius: 0,
					pointHitRadius: 10,
					data: highData

				},
				{
					label: 'Medium',
					fill: true,
					backgroundColor: "#67E1D6",
					borderColor: "#67E1D6",
					//borderColor: "#08B089",
					borderCapStyle: 'butt',
					borderDash: [],
					borderDashOffset: 0.0,
					borderJoinStyle: 'miter',
					borderWidth: 1,
					lineTension: 0.5,
					pointBackgroundColor: "#67E1D6",
					pointBorderColor: "#67E1D6",
					pointBorderWidth: 1,
					pointHoverRadius: 5,
					pointHoverBackgroundColor: "#67E1D6",
					pointHoverBorderColor: "#67E1D6",
					pointHoverBorderWidth: 2,
					pointRadius: 0,
					pointHitRadius: 10,
					data: mediumData

				},
				{
					label: 'Low',
					fill: true,
					backgroundColor: "#00B5D4",
					borderColor: "#00B5D4",
					//borderColor: "#00D4F0",
					borderCapStyle: 'butt',
					borderDash: [],
					borderDashOffset: 0.0,
					borderJoinStyle: 'miter',
					borderWidth: 1,
					lineTension: 0.5,
					pointBackgroundColor: "#00B5D4",
					pointBorderColor: "#00B5D4",
					pointBorderWidth: 1,
					pointHoverRadius: 5,
					pointHoverBackgroundColor: "#00B5D4",
					pointHoverBorderColor: "#00B5D4",
					pointHoverBorderWidth: 2,
					pointRadius: 0,
					pointHitRadius: 10,
					data: lowData

				}
			];
		}

		let line_multi_labelsColumnData = [];
		if(line_multi_labelsColumn){
			for (const [key] of Object.entries(line_multi_labelsColumn)) {
				line_multi_labelsColumnData.push(moment(line_multi_labelsColumn[key]).format('DD MMM Y'))
			}
		}

		let line_multi_datas = {labels:line_multi_labelsColumnData, datasets: line_multi_datasetsColumn}; 

		let line_multi_options = {
			legend: {
                display: true,                
				position: 'bottom',
				labels: {
					usePointStyle: true,
					fill: true,
					fontSize: 10
				},
				fontSize: 15,
			},
			scales:{
				xAxes: [{
					stacked: true,
					display: true, //this will remove all the x-axis grid lines
					gridLines: {
						display:false
					},
					ticks: {fontSize: 9}
				}],
				yAxes: [{
					stacked: true,
					display: true, //this will remove all the y-axis grid lines
					gridLines: {
						display:false
					},
					ticks: {display: true, fontSize: 9}
				}]
			}
		}

		this.setState({line_multi_data:line_multi_datas, line_multi_option:line_multi_options});
	}

	dayWiseRiskCountApexGraph= () => {
		let graphData = this.state.dayWiseRiskCountGraphData;
		let criticalData = []
		let highData = []
		let mediumData = []
		let lowData = []
		if(graphData){
			
            let graphLables = []
            if(graphData.Critical) {
                graphLables = graphData.Critical.labels
            } else if(graphData.High) {
                graphLables = graphData.High.labels
            } else if(graphData.Medium) {
                graphLables = graphData.Medium.labels
            } else if(graphData.Low) {
                graphLables = graphData.Low.labels
			}
			
			for(let x=0;x<graphLables.length;x++) {
				let dataItems = {}
				dataItems.x = moment(graphLables[x]).format('DD MMM Y')
				dataItems.y  = graphData['Critical'] ? graphData['Critical'].data.fail[x] : 0
				criticalData.push(dataItems)

				dataItems = {}
				dataItems.x = moment(graphLables[x]).format('DD MMM Y')
				dataItems.y  = graphData['High'] ? graphData['High'].data.fail[x] : 0
				highData.push(dataItems)

				dataItems = {}
				dataItems.x = moment(graphLables[x]).format('DD MMM Y')
				dataItems.y  = graphData['Medium'] ? graphData['Medium'].data.fail[x] : 0
				mediumData.push(dataItems)

				dataItems = {}
				dataItems.x = moment(graphLables[x]).format('DD MMM Y')
				dataItems.y  = graphData['Low'] ? graphData['Low'].data.fail[x] : 0
				lowData.push(dataItems)

			}
			let multi_series = [
				{
				  name: 'Critical',
				  data: criticalData,
				 // labels: multiLabels
				},
				{
				  name: 'High',
				  data: highData,
				  //labels: multiLabels
				},
				{
				  name: 'Medium',
				  data: mediumData,
				  //labels: multiLabels
				},
				{
				  name: 'Low',
				  data: lowData,
				  //labels: multiLabels
				}
			]


			let xAxisRow = {}
			if(graphLables.length >= 7) {
				xAxisRow.type = 'datetime'
			}
			let aAxixRowTool = {}
			aAxixRowTool.enabled = false
			xAxisRow.tooltip = aAxixRowTool
			let aAxixRowTicks = {}
			aAxixRowTicks.show = false
			xAxisRow.axisTicks = aAxixRowTicks
			
			let multi_options = {
				chart: {
				  type: 'area',
				  height: 350,
				  stacked: true,
				  events: {
					selection: function (chart, e) {
					  //console.log(new Date(e.xaxis.min))
					}
				  },
				  toolbar: {
					show: false,
				  }
				},
				colors: ['#26B68F', '#2ED1BE', '#67E1D6', '#00B5D4'],
				dataLabels: {
				  enabled: false
				},
				stroke: {
					show: true,
					curve: 'smooth',
					lineCap: 'butt',
					colors: undefined,
					width: 2,
					dashArray: 0,      
				},
				fill: {
					colors: ['#26B68F', '#2ED1BE', '#67E1D6', '#00B5D4'],
					//opacity: 0.7,
					type: 'solid',
					//opacity: 0.5,
				},
				legend: {
				  position: 'bottom',
				  horizontalAlign: 'center'
				},
				grid: {
					xaxis: {
					  lines: {
						  show: false
					  },
				  },
				  yaxis: {
					  lines: {
						  show: false
					  }
				  },
			  	},
				xaxis: xAxisRow,
				yaxis: {
					marker: {
						show: false,
					},
					labels: {
					  style: {
						  fontSize: '10px',
						  fontWeight: 400,
						  cssClass: 'apexcharts-xaxis-label',
					  },
					}
				}
			}
			this.setState({ multi_series, multi_options });
		}
	}		

	getTransitionTrend = () => {
        let params = {}
        params.provider = this.state.selectedProvider.toLowerCase()
        params.duration = this.state.selectedDuration
        if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		params.category = 'Governance'
		this.props.getTransitionTrend(params, (promise, transitionTrend) => {
			if (promise) {
                this.setState({ transitionTrend });
                this.dayWiseTransitionGraph();
			} else {
                this.setState({ transitionTrend: [] })
            }
		})
	}
	
	dayWiseTransitionGraph= () => {
		// let summaryGraphLabelsData = [];
		let graphData = this.state.transitionTrend.data
        // let backgroundColor
		//let borderColor
		// let grayColor
		let graphLabels = this.state.transitionTrend.labels		
		// let dataItems = {}

		if(graphData) {
			for (const [key, value] of Object.entries(graphData)) {
				let labels = []
				let data = []
				for(let x=0;x<graphLabels.length;x++) {
					let dataItems = {}
					dataItems.x = moment(graphLabels[x]).format('DD MMM Y')
					dataItems.y  = value[x]
					labels.push(moment(graphLabels[x]).format('DD MMM Y'))
					data.push(dataItems)
				}
				// if(key === 'new') {
				//     graphData = value;
				//     backgroundColor = "#BA8FEB"
				// 	grayColor = '#BDBDBD'
				// } else if(key === 'pending') {
				//     graphData = value;
				//     backgroundColor = "#8F5ED1"
				// 	grayColor = '#D5D5D5'
				// } else if(key === 'resolved') {                    
				//     graphData = value;
				// 	backgroundColor = "#7B49CE"
				// 	grayColor = '#EDEDED'
				// } else if(key === 'reoccured') {
				//     graphData = value;
				//     backgroundColor = "#693EBC"
				// 	grayColor = '#EEEEEE'
				// }			
				
				let failSum = value.reduce(function(a, b){
					return a + b;
				}, 0);

				let newData = []
				let dataRow = {}
				dataRow.data = data
				dataRow.name = capitalizeFirstLetter(key)
				newData.push(dataRow)

				let trend_bar_options = {
					plotOptions: {
						bar: {
							barHeight: '100%',
							columnWidth: '50%',
							distributed: false,
							rangeBarOverlap: false,
							colors: {
								backgroundBarColors: ['#f7f7f7'],
								backgroundBarOpacity: 1,
								backgroundBarRadius: 2,
							}        
						},          
					},
					tooltip: {
						enabled: true,
						enabledOnSeries: false,
						shared: false,
						followCursor: false,
						intersect: false,
						inverseOrder: false,
						custom: function({series, seriesIndex, dataPointIndex, w}) {
							let val  = series[seriesIndex][dataPointIndex];
							let labelName = w.globals.initialSeries[0].data[dataPointIndex].x
							let name = w.globals.seriesNames[seriesIndex]
							return '<div class="arrow_box"> '+ labelName +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  capitalizeFirstLetter(name) + '  '+ val + '</span> </div>'
						},
						fillSeriesColor: false,
						theme: false,
						style: {
							fontSize: '12px',				
						},
						onDatasetHover: {
							highlightDataSeries: false,
						},
						x: {
							show: false,
							format: 'dd MMM Y',
							formatter: undefined,
						},
						y: {
							show: false,
							formatter: undefined,
							title: {
								formatter: (seriesName) => seriesName,
							},
						},
						marker: {
							show: false,
						}
					},
					chart: {
						zoom: {
							enabled: false,
						},
						height: 350,
						type: 'bar',
						toolbar: {
							show: false,
						},
						sparkline: {
							enabled: false
						},
					},
					colors: ['#bca1f0'],
					dataLabels: {
						enabled: false,
					},
					title: {
						text: ''
					},
					
					grid: {  	
						padding: {
							left: -10,
							right: 0,
							top: 0,
							bottom: 0,
						},			        
						xaxis: {
							lines: {
								show: false
							},
						},
						yaxis: {
							lines: {
								show: false
							}
						},
					},
					xaxis: {
						type: 'datetime',
						categories: [],
						position: 'bottom',
						axisBorder: {
							show: false
						},
						axisTicks: {
							show: false
						},
						tooltip: {
							enabled: false,
						},
						labels: {
							show: false,
							style: {
								fontSize: '9px'
							}
						}
					},
					yaxis: {
						tickAmount: 2,
						axisTicks: {
							show: false,
						},
						axisBorder: {
							show: false
						},			  
						labels: {
							show: false,
							formatter: function (val) {
							return val;
							},
							style: {
								fontSize: '9px'
							}
						}
					}
				}
				if(key === 'new') {
					this.setState({ new_bar_transition_data: newData , new_bar_transition_option: trend_bar_options, newTransitionFailSum: failSum });
				} else if(key === 'pending') {
					this.setState({ pending_bar_transition_data: newData, pending_bar_transition_option: trend_bar_options, pendingTransitionFailSum: failSum });
				} else if(key === 'resolved') {                    
					this.setState({ resolved_bar_transition_data: newData, resolved_bar_transition_option: trend_bar_options, resolvedTransitionFailSum: failSum });
				} else if(key === 'reoccured') {
					this.setState({ reoccured_bar_transition_data: newData, reoccured_bar_transition_option: trend_bar_options, reoccuredTransitionFailSum: failSum });
				}
			}
		}
	}
	
	dayWiseCountGraphApex = () => {
		let graphData = this.state.dayWiseCountGraphData;
		let passData = [];
		let failData = [];
		let labels = [];
		let passSum = 0;
		let failSum = 0;	
		let data1 = []
		let data2 = []
		let pass_options = {}
		let fail_options = {}
		if(graphData){
			for(let x=0;x<graphData.labels.length;x++) {
				let dataItems1 = {}
				dataItems1.x = moment(graphData.labels[x]).format('DD MMM Y')
				dataItems1.y  = graphData.data.pass[x]
				labels.push(moment(graphData.labels[x]).format('DD MMM Y'))
				data1.push(dataItems1)

				let dataItems2 = {}
				dataItems2.x = moment(graphData.labels[x]).format('DD MMM Y')
				dataItems2.y  = graphData.data.fail[x]
				labels.push(moment(graphData.labels[x]).format('DD MMM Y'))
				data2.push(dataItems2)
			}
			passData =  graphData.data.pass;
			failData =  graphData.data.fail;
			
			passSum = passData.reduce(function(a, b){
				return a + b;
			}, 0);

			failSum = failData.reduce(function(a, b){
				return a + b;
			}, 0);
		}
		
		let pass_series = []
		let dataRow = {}
		dataRow.data = data1
		dataRow.name = 'pass'
		pass_series.push(dataRow)

		let fail_series = []
		dataRow = {}
		dataRow.data = data2
		dataRow.name = 'fail'
		fail_series.push(dataRow)

		for(let x=0; x<2; x++) {
			let areaColor = ['#7BB9BD']
			let areaFillColor = ['#CEFBFF']
			if(x === 1) {
				areaColor = ["#EC8AA0"]
				areaFillColor= ["#FBE5EA"]
			}
			let options = {
				chart: {
				height: 100,
				type: 'area',
				sparkline: {
					enabled: true
				}
				},
				stroke: {
					show: true,
					curve: 'smooth',
					lineCap: 'butt',
					colors: undefined,
					width: 2,
				},
				grid: {
					xaxis: {
						lines: {
						show: false
						},
					},
					yaxis: {
						lines: {
						show: false
						}
					},
				},			
				yaxis: {
					labels: {
						show:false
					}
				},
				xaxis: {
					categories: '',
					labels: {
						show:false,
					}
				},
				colors: areaColor,
				fill: {
					colors: areaFillColor,
					opacity: 0.7,
					type: 'solid',
					//opacity: 0.5,
				},
				tooltip: {
					enabled: true,
					enabledOnSeries: undefined,
					shared: true,
					followCursor: false,
					intersect: false,
					inverseOrder: false,
					custom: function({series, seriesIndex, dataPointIndex, w}) {
						let val  = series[seriesIndex][dataPointIndex];
						//val = Math.abs(Math.round(val))
						let labelName = w.globals.categoryLabels[dataPointIndex]
						//let val = str.replace('-',series[seriesIndex][dataPointIndex]);
						return '<div class="arrow_box"> <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val +
						'</div>'
					},
					fillSeriesColor: false,
					theme: false,
					style: {
					fontSize: '12px',				
					},
					onDatasetHover: {
						highlightDataSeries: false,
					},
					x: {
						show: true,
						format: 'dd MMM Y',
						formatter: undefined,
					},
					y: {
						formatter: undefined,
						title: {
							formatter: (seriesName) => seriesName,
						},
					},
					marker: {
						show: false,
					}
				}
			}

			if(x === 0) {
				pass_options = options
			} else {
				fail_options = options
			}
		}

		let bar_options = {
			plotOptions: {
                bar: {
                    barHeight: '50%',
                    columnWidth: '50%',
                    distributed: false,
                    rangeBarOverlap: false,
                    colors: {
                        backgroundBarColors: ['#EEEEEE'],
                        backgroundBarOpacity: 1,
                        backgroundBarRadius: 2,
                    }        
                },          
			},
			tooltip: {
				enabled: true,
				enabledOnSeries: false,
				shared: false,
				followCursor: false,
				intersect: false,
				inverseOrder: false,
				custom: function({series, seriesIndex, dataPointIndex, w}) {
					let val  = series[seriesIndex][dataPointIndex];
					let labelName = w.globals.initialSeries[0].data[dataPointIndex].x
					let name = w.globals.seriesNames[seriesIndex]
					return '<div class="arrow_box"> '+ labelName +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  capitalizeFirstLetter(name) + '  '+ val + '</span> </div>'
				},
				fillSeriesColor: false,
				theme: false,
				style: {
				  fontSize: '12px',				
				},
				onDatasetHover: {
					highlightDataSeries: false,
				},
				x: {
					show: false,
					format: 'dd MMM Y',
					formatter: undefined,
				},
				y: {
					show: false,
					formatter: undefined,
					title: {
						formatter: (seriesName) => seriesName,
					},
				},
				marker: {
					show: false,
				}
			},
			chart: {
			  height: 350,
			  type: 'bar',
			  toolbar: {
				show: false,
			  },
			  sparkline: {
				  enabled: false
			  },
			},
			dataLabels: {
			  enabled: false,
			},
			title: {
			  text: ''
			},
			
			grid: {  				        
				xaxis: {
				  lines: {
					show: false
				  },
				},
				yaxis: {
				  lines: {
					show: false
				  }
				},
			},
			xaxis: {
			  type: 'datetime',
			  categories: [],
			  position: 'bottom',
			  axisBorder: {
				show: false
			  },
			  axisTicks: {
				show: false
			  },
			  tooltip: {
				enabled: false,
			  },
			  labels: {
				show: false,
				style: {
					fontSize: '9px'
				}
			  }
			},
			yaxis: {
			  tickAmount: 2,
			  axisTicks: {
				show: false,
			  },
			  axisBorder: {
				show: false
			  },			  
			  labels: {
				show: false,
				formatter: function (val) {
				  return val;
				},
				style: {
					fontSize: '9px'
				}
			  }
			
			}
		}
		

		this.setState({ pass_series, pass_options, fail_series, bar_options, fail_options, passSum, failSum });

	}

	multidayWiseCountGraphApex = () => {

		let graphData = this.state.dayWiseCountGraphData;
		let data1 = []
		let data2 = []
		let passData = [];
		let failData = [];
		let totalSum = 0;
		let passSum = 0;
		let failSum = 0;

		if(graphData){
			for(let x=0;x<graphData.labels.length;x++) {
				let dataItems1 = {}
				dataItems1.x = moment(graphData.labels[x]).format('DD MMM Y')
				dataItems1.y  = graphData.data.pass[x]				
				data1.push(dataItems1)

				let dataItems2 = {}
				dataItems2.x = moment(graphData.labels[x]).format('DD MMM Y')
				dataItems2.y  = graphData.data.fail[x]
				data2.push(dataItems2)
			}

			passData =  graphData.data.pass;
			failData =  graphData.data.fail;
			
			passSum = passData.reduce(function(a, b){
				return a + b;
			}, 0);

			failSum = failData.reduce(function(a, b){
				return a + b;
			}, 0);

			totalSum = failSum + passSum;

			let multi_series = [
				{
				  name: 'Pass',
				  data: data1,
				},
				{
				  name: 'Fail',
				  data: data2,
				}
			]		
			
			let xAxisRow = {}
			if(graphData.labels.length >= 7) {
				xAxisRow.type = 'datetime'
			}
			let aAxixRowTool = {}
			aAxixRowTool.enabled = false
			xAxisRow.tooltip = aAxixRowTool
			let aAxixRowTicks = {}
			aAxixRowTicks.show = false
			xAxisRow.labels = aAxixRowTicks
			
			let multi_options = {
				chart: {
				  type: 'area',
				  height: 100,
				  stacked: true,
				  toolbar: {
					show: false,
				  },
				  sparkline: {
					  enabled: true
				  },
				},
				colors: ['#7BB9BD', '#EC8AA0'],
				dataLabels: {
				  enabled: false
				},
				stroke: {
					show: true,
					curve: 'smooth',
					lineCap: 'butt',
					colors: undefined,
					width: 2,
					dashArray: 0,
				},
				fill: {
					colors: ['#CEFBFF', '#FBE5EA'],
					type: 'solid',
				},
				legend: {
				  show: false
				},
				grid: {
					xaxis: {
					  lines: {
						  show: false
					  },
				  },
				  yaxis: {
					  lines: {
						  show: false
					  }
				  },
			  	},
				xaxis: xAxisRow,
				yaxis: {
					labels: {
						show:false,					
					}
				}
			}
			this.setState({ multi_pass_fail_series: multi_series, multi_pass_fail_options: multi_options, totalSum, passSum, failSum });
		}
	}

	showSummaryGraph= () => {	
		this.setState({ bar_data: [], barChartOptions: {} })
		let graphData = this.state.dayWiseCountTags.data;
		let barChartData = [];
		//let summaryGraphLabels = [];
		let i =0;
		let tagAccount = this.state.detectorKey
		let summaryGraphLabelsData = [];

		let daysShownInGraph = this.state.daysShownInGraph
		let week = this.state.showPrevNextRecord
		if(week < 1) {
			this.setState({disablePreviousWeekBtn:true})
		}

		let graphLength = 0
		if(graphData && !Object.entries(graphData).legnth) {			
			for (const [key] of Object.entries(graphData)) {				
				for(const [detKey, detValue] of Object.entries(graphData[key])) {
					if(tagAccount === detKey) {
						let length = 0
						if(detValue.Low !== undefined) {
							length = detValue.Low.pass.length
						} else if(detValue.Medium !== undefined) {
							length = detValue.Medium.pass.length
						} else if(detValue.high !== undefined) {
							length = detValue.high.pass.length
						} else if(detValue.Critical !== undefined) {
							length = detValue.Critical.pass.length
						}						
						let passValueArray = []
						let lowExistArray = []
						let mediumExistArray = []
						let highExistArray = []
						let criticalExistArray = []
						let totalArray = []
						
						if(week > 0) {
							this.setState({disableNextWeekBtn:true, disablePreviousWeekBtn: false})
						}
						
						this.setState({disableNextWeekBtn:true})
						i = daysShownInGraph * week
						graphLength = daysShownInGraph * (week + 1)
						let graphRecordsRemainigToShow = length - graphLength;

						if(graphLength > length && graphRecordsRemainigToShow < daysShownInGraph){
							//graphLength = graphLength + graphRecordsRemainigToShow
							//graphLength = graphLength
						} else {
							this.setState({ disableNextWeekBtn: false })
						}

						summaryGraphLabelsData = []
						let x = i
						for (x;x<graphLength;x++) {
							if(x < graphLength) {
								if(this.state.dayWiseCountTags.labels[x] !== undefined) {
									summaryGraphLabelsData.push(moment(this.state.dayWiseCountTags.labels[x]).format('DD MMM Y'))
								} else {
									summaryGraphLabelsData.push('')
								}
							} else {
								break;
							}
						}
						
						//let i = thisweek;
						for(i;i<parseInt(graphLength);i++) {
							let passValue = 0;
							if(detValue.Low !== undefined) {
								passValue = passValue + detValue.Low.pass[i]
								lowExistArray.push(detValue.Low.fail[i])
							}
							
							if(detValue.Medium !== undefined) {
								passValue = passValue + detValue.Medium.pass[i]
								mediumExistArray.push(detValue.Medium.fail[i])
							}
							
							if(detValue.High !== undefined) {
								passValue = passValue + detValue.High.pass[i]
								highExistArray.push(detValue.High.fail[i])
							}
							
							if(detValue.Critical !== undefined) {
								passValue = passValue + detValue.Critical.pass[i]
								criticalExistArray.push(detValue.Critical.fail[i])
							}
							passValueArray.push(-passValue)							
						}	
						totalArray.push(passValueArray)
						if(lowExistArray.length>0) {
							totalArray.push(lowExistArray)
						} else {
							totalArray.push(0)
						}
						if(mediumExistArray.length>0) {
							totalArray.push(mediumExistArray)
						} else {
							totalArray.push(0)
						}
						if(highExistArray.length>0) {
							totalArray.push(highExistArray)
						} else {
							totalArray.push(0)
						}
						if(criticalExistArray.length>0) {
							totalArray.push(criticalExistArray)
						} else {
							totalArray.push(0)
						}
						
						//let graphColors = ['#5D38AA', '#24A89D', '#22B5B5', '#2DCBDC', '#1FCCE2']

						let dataRows = ''
						for(let m=0; m<totalArray.length; m++) {
							if(totalArray[m] === 0) {
								//dataRows = Array.apply(null, Array(length)).map(Number.prototype.valueOf,0);
								dataRows = Array.apply(null, Array(this.state.daysShownInGraph)).map(Number.prototype.valueOf,0);
							} else {
								dataRows = totalArray[m]
							}
							
							let summaryDataSet = {};
							summaryDataSet['data'] = dataRows
							if(m === 0) {
								summaryDataSet['name'] = 'Pass';
							} else if(m === 1) {
								summaryDataSet['name'] = 'Low';
							} else if(m === 2) {
								summaryDataSet['name'] = 'Medium';
							} else if(m === 3) {
								summaryDataSet['name'] = 'High';
							} else if(m === 4) {
								summaryDataSet['name'] = 'Critical';
							}
							barChartData.push(summaryDataSet);
						}
					} else {
						let summaryDataSet = {};
						summaryDataSet['name'] = 'No Data';
						for (let m=0;m<30;m++) {
							summaryGraphLabelsData.push('')
						}
						summaryDataSet['data'] = Array.apply(null, Array(30)).map(Number.prototype.valueOf,0)
						barChartData.push(summaryDataSet);
					}
				}
				break;
			}
		} else {
			let summaryDataSet = {};
			summaryDataSet['name'] = 'No Data';
			for (let m=0;m<30;m++) {
				summaryGraphLabelsData.push('')
			}
			summaryDataSet['data'] = Array.apply(null, Array(30)).map(Number.prototype.valueOf,0)
			barChartData.push(summaryDataSet);
		}
		
		// barChartData = {
		// 	labels:summaryGraphLabelsData,
		// 	datasets: barChartData
		// };
		
		const barChartOptions = {
			tooltip: {
				enabled: true,
				//enabledOnSeries: undefined,
				//shared: false,
				//followCursor: false,
				//intersect: false,
				//inverseOrder: false,
				custom: function({series, seriesIndex, dataPointIndex, w}) {
				  let val  = series[seriesIndex][dataPointIndex];
				  val = Math.abs(Math.round(val))
				  let labelName = w.globals.initialSeries[seriesIndex].name
				  //let val = str.replace('-',series[seriesIndex][dataPointIndex]);
				  return '<div class="arrow_box">' +
					'<span> '+ w.globals.labels[dataPointIndex] + 
					' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val + ' </span>' +
					'</div>'
				},
				//fillSeriesColor: false,
				//theme: true,
				style: {
				  fontSize: '9px',
				},
				onDatasetHover: {
					highlightDataSeries: true,
				},
				x: {
					//show: true,
					format: 'dd MMM Y',
					formatter: undefined,
				},
				y: {
					show: true,
					format: 'dd',
					//formatter: (value) => { return value },
					formatter: undefined,
					title: {
						formatter: (seriesName) => seriesName,
					},
				},
				z: {
					formatter: undefined,
					title: 'Size: 9px'
				},
				marker: {
					show: true,
				},
				fixed: {
					enabled: false,
					position: 'topRight',
					offsetX: 0,
					offsetY: 0,
				},
			},
			legend: {
				position: 'bottom',
				fontSize: '11px',
				markers: {
					width: 9,
					height: 9,
					strokeWidth: 0,
					strokeColor: '#fff',
					fillColors: undefined,
					radius: 9,
					customHTML: undefined,
					onClick: undefined,
					offsetX: 0,
					offsetY: 0
				}
			},	
			chart: {
				toolbar: {
					show: false,
				},
				//   sparkline: {
				// 	enabled: true
				//   },
				padding: {
					top:400
				},
			  type: 'bar',
			  height: 440,
			  stacked: true,
			  // animations: {
			  //     enabled: true,
			  //     easing: 'easeinout',
			  //     speed: 800,
			  //     animateGradually: {
			  //         enabled: true,
			  //         delay: 150
			  //     },
			  //     dynamicAnimation: {
			  //         enabled: true,
			  //         speed: 350
			  //     }
			  // }
			},
			colors: ['#5D38AA', '#00B5D4', '#67E1D6', '#2ED1BE', '#26B68F'],
	
			plotOptions: {
			  bar: {
				horizontal: true,
				barHeight: '40%',
				startingShape: 'flat',
				endingShape: 'flat',
				columnWidth: '80%',
				distributed: false,
				rangeBarOverlap: false,
				colors: {
				  ranges: [{
					  from: 1,
					  to: 0.9,
					  color: '#FF4560'
				  }],
				  backgroundBarColors: ['#BDBDBD'],
				  backgroundBarOpacity: 1,
				  backgroundBarRadius: 5,
				}        
			  },          
			},
			dataLabels: {
			  enabled: false
			},
			stroke: {
			  width: 0,
			  colors: ["#fff"]
			},
			
			grid: {  				        
			  xaxis: {
				lines: {
				  show: false
				},
			  },
			  yaxis: {
				lines: {
				  show: false
				}
			  },
			//   row: {
			// 	colors: undefined,
			// 	opacity: 0.5
			//   },  
			//   column: {
			// 	  colors: undefined,
			// 	  opacity: 0.5
			//   },  
			//   padding: {
			// 	  top: 0,
			// 	  right: 0,
			// 	  bottom: 0,
			// 	  left: 0
			//   }, 
			},
			yaxis: {
			  //min: -5,
			  //max: 5,
			  title: {
				// text: 'Age',
			  },
			  labels: {
				
				style: {
					fontSize: '9px'
				}
			  },
			  axisBorder: {
				show: false
			  },
			  axisTicks: {
				  fontSize: '9px',
				  show: false,
				  borderType: 'none',
				  color: '#78909C',
				  width: 6,
				  offsetX: 0,
				  offsetY: 0
			  },
			},
			title: {
			  text: ''
			},
			xaxis: {				
				axisBorder: {
					show: false
				},
				axisTicks: {
					tickAmount: 15,
					fontSize: '9px',
					show: false,
					borderType: 'none',
					color: '#78909C',
					width: 6,
					offsetX: 0,
					offsetY: 0
				},
				categories: summaryGraphLabelsData,
				title: {
					text: ''
				},
				labels: {
					formatter: function (val) {
						return Math.abs(Math.round(val))
					},
					style: {
						fontSize: '9px'
					},
					show: false
				}
			},
		}
		this.setState({bar_data: barChartData, bar_option: barChartOptions});
	}

	showDoughnutGraph= () => {
		let graphData = this.state.serviceNameCount;
		let doughnutChartData = [];
		let doughnuGraphLabels = [];
		let backgroundColor = [];
		//let hoverBackgroundColor = [];
		if(graphData){
			for (const [key, value] of Object.entries(graphData)) {
				if(key > 9) {
					break;
				}
				doughnuGraphLabels.push(value.resource_type+': '+value.fail_count);
				doughnutChartData.push(value.fail_count);
				backgroundColor.push(this.dynamicColors());
				//hoverBackgroundColor.push(this.dynamicColors());
			}
		}

		//doughnuGraphLabels = doughnuGraphLabels.slice(0, 8);

		let datasetsColumn = [{
			data: doughnutChartData,
			backgroundColor: [
				'#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'
				],
			hoveBackgroundColor: [
				'#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'
				],
			barThickness : 5,
		}];

		let doughnut_datas = {labels:doughnuGraphLabels, datasets: datasetsColumn}; 

		let doughnut_option = {
			maintainAspectRatio: false,
			responsive: false,
			animate   : true,
			animation: { duration: 1000 },
			legend: {				
				display:true,
				position: 'right',
				labels: {
					boxWidth: 15,
					fontSize: 9
				}
				// layout: {
				// 	padding: {
				// 		left: 0,
				// 		right: 50
				// 	},
				// }
			}
		}

		this.setState({doughnut_data:doughnut_datas, doughnut_options:doughnut_option});
	}
	/**
	 * Method to return total fail counts
	 */
	getTotalFailCounts = () => {
		let count = 0
		if (this.state.statusCounts && this.state.statusCounts.length > 1) {
			this.state.statusCounts.forEach(item => {
				if (item.status === 'fail') {
					count = item.fail_count
				}
			})
		}
		if (this.state.statusCounts && this.state.statusCounts.length === 1) {
			this.state.statusCounts.forEach(item => {
				count = item.fail_count
			})
		}
		return count
	}

	navigateServieWise = (slide) => {
		if(slide === 'left') {
			this.setState({
				serviceWiseStart: this.state.serviceWiseStart - this.state.serviceWiseheight,
				filterServiceCountLength: this.state.filterServiceCountLength - this.state.serviceWiseheight
			})
		} else {
			this.setState({ 
				filterServiceCountLength: this.state.filterServiceCount.length > this.state.filterServiceCountLength 
					? this.state.filterServiceCountLength + this.state.serviceWiseheight 
					: this.state.serviceWiseheight,
				serviceWiseStart: this.state.serviceWiseStart + this.state.serviceWiseheight 
			})
		}

	}

	tablefilterMultiSelectChange= (key, childKey, arrayValue) => {
		let value = arrayValue.map(item => item.value)
		if(childKey === 'selectedService') {
			this.setState({ selectedService: value, serviceLoading: true },
				() => this.getServiceCount()
			)
		}
	}


	// tagChanges = () => {
	// 	getScore
	// 	getDayWiseCountTags
	// 	getCostTrend
	// 	getAllCounts
	// }


	/**
	 * Renders Compliance Dashboard
	 */
	render() {
		return (
			<div className='container-fluid overflow-auto flex-grow-1' onClick={ (event) => { this.handleClickOutside(event) } }>
				<div className='row h-100'>
					<div className='col-sm-12 p-0'>
						<div className='title-section' style={{padding: "1rem 1rem 1rem 1rem"}} >
							<div className='row'>
								<div className='col-md-4 d-flex align-self-center'>
									<div>
										<div className='d-flex'>
											<h6 className='text-white m-0'>Governance Dashboard</h6>
										</div>
										<p className='text-white m-0'>
											Dashboard view of various types of Governance checks performed on your Cloud
										</p>
										</div>
								</div>
								<div className={`col-md-8 text-right`}>
								<div className='d-flex'>
									<div
										className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 ${!this.state.providers
											.length}`}
									>
										<label>Provider</label>
										<select
											className='form-control'
											value={this.state.selectedProvider}
											onChange={event => {
												this.setState(
													{
														selectedProvider: event.target.value,
														showLoading: true,
													},
													() => this.filterIdentifiers('provider')
												)
											}}
										>
											<option value='all'>All</option>
											{this.state.providers.length &&
												this.state.providers.map(provider => {
													return (
														<option key={provider.provider_id} value={provider.provider_name}>
															{provider.provider_name}
														</option>
													)
												})}
										</select>
									</div>
									<div
										className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 ${!this.state.accounts
											.length}`}
									>
										<label>Account</label>
										<select
											className='form-control'
											value={this.state.selectedAccount}
											disabled={!this.state.accounts.length}
											onChange={event => {
												this.setState(
													{
														selectedAccount: event.target.value,
														selectedAccountName: event.target.options[event.target.selectedIndex].text
													},
													() => this.filterIdentifiers('account')
												)
											}}
										>
											<option value='all'>All</option>
											{this.state.accounts.length &&
												this.state.accounts.map((account, i) => {
													return (
														<option key={i} value={account.account_id} name={account.account_name}>
															{account.account_name}
														</option>
													)
												})}
										</select>
									</div>
									<div className='form-group flex-fill bd-highlight mb-sm-0 styled-feild'>
										<label>Duration</label>
										<select
											className='form-control'
											value={this.state.selectedDuration}
											onChange={event => {
												this.setState(
													{
														selectedDuration: event.target.value,
													},
													() => this.filterIdentifiers('duration')
												)
											}}
										>
											{DURATION_FILTER_OPTIONS.map((item, index) => {
												return (
													<option key={index} value={item.value}>
														{item.option}
													</option>
												)
											})}
										</select>
									</div>
								</div>									
								</div>
							</div>
						</div>
						<div className='bashboard-bg'>
							<div className="box p-3 shadow-none">
								<div className="row">
									<div className="col-lg-5 col-xl-3 border-right">
										<div className="d-flex justify-content-between mb-3">
											<p className="mb-0 mt-1 small">{this.state.regionWiseCount.length ? 'Region Showing' : 'Account Showing'} {this.state.accountWiseCount.length > 3 ? '(3 of '+ this.state.accountWiseCount.length +')' : '('+this.state.accountWiseCount.length +' of '+ this.state.accountWiseCount.length +')' }</p>
											<p className={`text-info mb-0 small cursorPointer ${this.state.accountWiseCount.length > 3 ? '' : 'displayNone'} `} id={`allAccounts`}>See more...</p>											
											{this.state.accountWiseCount.length ?
												<UncontrolledPopover className="chDAccRegionPopOver" trigger="hover" placement="top" target={`allAccounts`}>
													<PopoverBody>
														{this.state.accountWiseCount.map((item, i) => {
															return(
																<React.Fragment>
																	{i===0 ? <p className={`font-weight-bold mb-2 mt-1`}>{this.state.regionWiseCount.length ? 'Region' : 'Account'} </p> : null }			
																	<div className={` ${this.state.accountWiseCount.length === 1 ? 'w-100' :'w-50'}  ${i%2 ? 'float-right' : this.state.accountWiseCount.length === 1 ? '' :'border-right float-left' } `}>
																		<div className="d-flex justify-content-between mb-2 ml-2">
																			<div className="w-50 align-self-center">
																				{this.state.regionWiseCount.length ?
																					<h8 className="font-weight-bold blackMuted">{item.region === 'NA' ? 'Global' : item.region}</h8>
																				:
																					<h8 className="font-weight-bold blackMuted">{capitalizeFirstLetter(getAccountNameFromId(item.account_id, this.state.accounts))}</h8>
																				}
																				<p className="m-0 f12 text-muted">{item.account_id}</p>
																			</div>
																			<div className="box bg-light d-flex p-2 shadow-none heihgtFitContent mr-2 align-self-center">
																				<div className="mr-2 width75">														
																					<p className="font-weight-bold m-0 f12">Pass&nbsp;:&nbsp;
																						<h7 className="font-weight-bold mb-0 text-success">{item.pass_count && item.pass_count}</h7>
																					</p>
																				</div>
																				<div className="mr-2 width75">														
																					<p className="font-weight-bold m-0 f12">Fail&nbsp;:&nbsp;<h7 className="font-weight-bold mb-0 text-danger">{item.fail_count && item.fail_count}</h7></p>
																				</div>
																			</div>
																			<div className={`mb-0 text-right w-10 align-self-center mr-2`}>
																				{!this.state.regionWiseCount.length ?
																					this.state.accScore && Object.entries(this.state.accScore).length && this.state.accScore.hasOwnProperty(item.account_id) ?
																						<CircularProgressbar
																							value={this.state.accScore[item.account_id].overall ? (this.state.accScore[item.account_id].overall.split("/"))[0] : 0}
																							text={this.state.accScore[item.account_id].overall ? (this.state.accScore[item.account_id].overall.split("/"))[0] : '-'}
																							counterClockwise={false}
																							styles={{trail: {strokeWidth:'3'},path: {stroke: '#19B2C0',strokeWidth:'3'},text: {fill: '#19B2C0',fontSize: '10px'}}}
																						/>
																					: 
																						<CircularProgressbar
																							value={0}
																							text={'-'}
																							counterClockwise={false}
																							styles={{trail: {strokeWidth:'3'},path: {stroke: '#19B2C0',strokeWidth:'3'},text: {fill: '#19B2C0',fontSize: '10px'}}}
																						/>
																					:
																						this.state.accScore && Object.entries(this.state.accScore).length && this.state.accScore.hasOwnProperty(item.region) ?
																						<CircularProgressbar
																							value={this.state.accScore[item.region].overall ? (this.state.accScore[item.region].overall.split("/"))[0] : 0}
																							text={this.state.accScore[item.region].overall ? (this.state.accScore[item.region].overall.split("/"))[0] : '-'}
																							counterClockwise={false}
																							styles={{trail: {strokeWidth:'3'},path: {stroke: '#19B2C0',strokeWidth:'3'},text: {fill: '#19B2C0',fontSize: '10px'}}}
																						/>
																					: 
																						<CircularProgressbar
																							value={0}
																							text={'-'}
																							counterClockwise={false}
																							styles={{trail: {strokeWidth:'3'},path: {stroke: '#19B2C0',strokeWidth:'3'},text: {fill: '#19B2C0',fontSize: '10px'}}}
																						/>
																				}
																			</div>
																		</div>
																	</div>
																</React.Fragment>
															)
														})}
													</PopoverBody>
												</UncontrolledPopover>
											: null }
										</div>
										<div className={`minheightAccCD border-bottom`}>
											{this.state.accountWiseCount.slice(0,3).map((item, i) => {
												return(
												<div className={`d-flex justify-content-between pb-2 ${i !== 0 ? 'border-top pt-2' : ''}`}>
													<div className="w-50 align-self-center">
														{this.state.regionWiseCount.length ?
															<h8 className="font-weight-bold blackMuted">{item.region === 'NA' ? 'Global' : item.region}</h8>
														:
															<h7 className="font-weight-bold blackMuted">{capitalizeFirstLetter(getAccountNameFromId(item.account_id, this.state.accounts))}</h7>
														}
														
														<p className="f12 text-muted m-0">{item.account_id}</p>
													</div>
													{/* <div className="align-self-center">
														<h7 className="mb-0 font-weight-bold blackMuted">{acc}</h7>
													</div> */}
													<div className="box bg-light d-flex p-2 shadow-none heihgtFitContent mr-2 align-self-center">
														<div className="mr-2 width75">														
															<p className="font-weight-bold m-0 f12">Pass&nbsp;:&nbsp;
																<h7 className="font-weight-bold mb-0 text-success">{item.pass_count && item.pass_count}</h7>
															</p>
														</div>
														<div className="mr-2 width75">														
															<p className="font-weight-bold m-0 f12">Fail&nbsp;:&nbsp;<h7 className="font-weight-bold mb-0 text-danger">{item.fail_count && item.fail_count}</h7></p>
														</div>
													</div>
													<div className="w-10 align-self-center" id={`scorePopover_`+i}>
														{!this.state.regionWiseCount.length ?
															this.state.accScore && Object.entries(this.state.accScore).length && this.state.accScore.hasOwnProperty(item.account_id) ?
																<CircularProgressbar
																	value={this.state.accScore[item.account_id].overall ? (this.state.accScore[item.account_id].overall.split("/"))[0] : 0}
																	text={this.state.accScore[item.account_id].overall ? (this.state.accScore[item.account_id].overall.split("/"))[0] : '-'}
																	counterClockwise={false}
																	styles={{trail: {strokeWidth:'3'},path: {stroke: '#19B2C0',strokeWidth:'3'},text: {fill: '#19B2C0',fontSize: '10px'}}}
																/>
															: 
																<CircularProgressbar
																	value={0}
																	text={'-'}
																	counterClockwise={false}
																	styles={{trail: {strokeWidth:'3'},path: {stroke: '#19B2C0',strokeWidth:'3'},text: {fill: '#19B2C0',fontSize: '10px'}}}
																/>
															:
																this.state.accScore && Object.entries(this.state.accScore).length && this.state.accScore.hasOwnProperty(item.region) ?
																<CircularProgressbar
																	value={this.state.accScore[item.region].overall ? (this.state.accScore[item.region].overall.split("/"))[0] : 0}
																	text={this.state.accScore[item.region].overall ? (this.state.accScore[item.region].overall.split("/"))[0] : '-'}
																	counterClockwise={false}
																	styles={{trail: {strokeWidth:'3'},path: {stroke: '#19B2C0',strokeWidth:'3'},text: {fill: '#19B2C0',fontSize: '10px'}}}
																/>
															: 
																<CircularProgressbar
																	value={0}
																	text={'-'}
																	counterClockwise={false}
																	styles={{trail: {strokeWidth:'3'},path: {stroke: '#19B2C0',strokeWidth:'3'},text: {fill: '#19B2C0',fontSize: '10px'}}}
																/>
														}
													</div>
													<UncontrolledPopover className="scorePopOver" trigger="hover" placement="top" target={`scorePopover_`+i}>
														<PopoverBody>
															{!this.state.regionWiseCount.length ? 
																this.state.accScore && Object.entries(this.state.accScore).length && this.state.accScore.hasOwnProperty(item.account_id) && Object.entries(this.state.accScore[item.account_id].identifier_category) ? Object.entries(this.state.accScore[item.account_id].identifier_category).map(([key, value], i) => {
																	return(
																		<React.Fragment>
																			{i===0 ? <p className={`font-weight-bold mb-2 mt-1`}>Scores</p> : null }			
																			<div className={` ${Object.entries(this.state.accScore[item.account_id].identifier_category).length === 1 ? 'w-100' :'w-50 pr-2'} ${i%2 ? 'float-right' : Object.entries(this.state.accScore[item.account_id].identifier_category).length === 1 ? '' :'border-right float-left' } `}>
																				<div className="d-flex justify-content-between mb-2 ml-2">
																					<div className="mb-0 align-self-center">
																						<h8 className="font-weight-bold blackMuted">{key}</h8>
																					</div>
																					<div className={`mb-0 text-right ${Object.entries(this.state.accScore[item.account_id].identifier_category).length === 1 ? 'w-10' :'w-25'} `}>
																						<CircularProgressbar
																							value={(value.split("/"))[0]}
																							text={(value.split("/"))[0]}
																							counterClockwise={false}
																							styles={{trail: {strokeWidth:'3'},path: {stroke: '#19B2C0',strokeWidth:'3'},text: {fill: '#19B2C0',fontSize: '10px'}}}
																						/>
																					</div>
																				</div>
																			</div>	
																		</React.Fragment>
																	)
																	})
																:
																	<React.Fragment>
																		<div className="mb-0 align-self-center">
																			<h8 className="font-weight-bold blackMuted">No Score</h8>
																		</div>
																		<div className={`mb-0 text-right w-10`}>
																			<CircularProgressbar
																				value={0}
																				text={'-'}
																				counterClockwise={false}
																				styles={{trail: {strokeWidth:'3'},path: {stroke: '#19B2C0',strokeWidth:'3'},text: {fill: '#19B2C0',fontSize: '10px'}}}
																			/>
																		</div>
																	</React.Fragment>
																:
																this.state.accScore && Object.entries(this.state.accScore).length && this.state.accScore.hasOwnProperty(item.region) && Object.entries(this.state.accScore[item.region].identifier_category) ? Object.entries(this.state.accScore[item.region].identifier_category).map(([key, value], i) => {
																	return(
																		<React.Fragment>
																			{i===0 ? <p className={`font-weight-bold mb-2 mt-1`}>Scores</p> : null }			
																			<div className={` ${Object.entries(this.state.accScore[item.region].identifier_category).length === 1 ? 'w-100' :'w-50 pr-2'} ${i%2 ? 'float-right' : Object.entries(this.state.accScore[item.region].identifier_category).length === 1 ? '' :'border-right float-left' } `}>
																				<div className="d-flex justify-content-between mb-2 ml-2">
																					<div className="mb-0 align-self-center">
																						<h8 className="font-weight-bold blackMuted">{key}</h8>
																					</div>
																					<div className={`mb-0 text-right ${Object.entries(this.state.accScore[item.region].identifier_category).length === 1 ? 'w-10' :'w-25'} `}>
																						<CircularProgressbar
																							value={(value.split("/"))[0]}
																							text={(value.split("/"))[0]}
																							counterClockwise={false}
																							styles={{trail: {strokeWidth:'3'},path: {stroke: '#19B2C0',strokeWidth:'3'},text: {fill: '#19B2C0',fontSize: '10px'}}}
																						/>
																					</div>
																				</div>
																			</div>	
																		</React.Fragment>
																	)})
																:
																	<React.Fragment>
																		<div className="mb-0 align-self-center">
																			<h8 className="font-weight-bold blackMuted">No Score</h8>
																		</div>
																		<div className={`mb-0 text-right w-10`}>
																			<CircularProgressbar
																				value={0}
																				text={'-'}
																				counterClockwise={false}
																				styles={{trail: {strokeWidth:'3'},path: {stroke: '#19B2C0',strokeWidth:'3'},text: {fill: '#19B2C0',fontSize: '10px'}}}
																			/>
																		</div>
																	</React.Fragment>
															}
														</PopoverBody>
													</UncontrolledPopover>
												</div>
												)
											})}
										</div>	
										
										<div className="mt-2 mb-2 d-flex justify-content-between minmaxheightServiceCD">
											<div>
												<p className="font-weight-bold text-left m-0">Service&nbsp;Wise</p>
												<p className="f12 fw400 m-0">Showing top services by priority count</p>
											</div>
											<div className='float-right align-self-center chDServiceWise'>
												<div className="d-flex">
													<Spinner className={`align-self-center mr-2 ${this.state.serviceLoading ? '' : 'displayNone'}`} size='sm' />
													<ReactMultiSelectCheckboxes
														value={this.state.selectedService.map(service => ({
																value: service,
																label: service
														}))}
														onChange={arr => {
														this.tablefilterMultiSelectChange('users', 'selectedService', arr ? arr : []) }}

														options={this.state.parentServices.map(service => ({
															value: service.label,
															label: service.label,
														}))}

													/>
												</div>
											</div>
										</div>
										<div className="border-bottom">
											{this.state.filterServiceCount && this.state.filterServiceCount.length > 0 ? 
											this.state.filterServiceCount.slice(this.state.serviceWiseStart, this.state.filterServiceCountLength).map((risk,i) => {
												let lowRiskBar = ((risk.Low * 100) / risk.totalFail) +'%'
												let mediumRiskBar = ((risk.Medium * 100) / risk.totalFail) +'%'
												let highRiskBar = ((risk.High * 100) / risk.totalFail) +'%'
												let criticalRiskBar = ((risk.Critical * 100) / risk.totalFail) +'%'
												return (
													<div className='d-flex justify-content-between mb-1' key={i}>
														<div className='w-30 text-left align-self-center'>
															<p className="font-weight-bold f10 m-0">{risk.name}</p>
														</div>
														<div className="w-50 align-self-center">
														{risk.totalFail > 0 ?
															<div className='stock-progress'>
																<div className='stock-1' style={{width:criticalRiskBar}} id={`assetCritical-${i}`}></div>
																<div className='stock-2' style={{width:highRiskBar}} id={`assetHigh-${i}`}></div>
																<div className='stock-3' style={{width:mediumRiskBar}} id={`assetMedium-${i}`}></div>
																<div className='stock-4' style={{width:lowRiskBar}} id={`assetLow-${i}`}></div>

																<UncontrolledTooltip placement='top' target={`assetCritical-${i}`}>Critical {risk.Critical}</UncontrolledTooltip>		
																<UncontrolledTooltip placement='top' target={`assetHigh-${i}`}>High {risk.High}</UncontrolledTooltip>
																<UncontrolledTooltip placement='top' target={`assetMedium-${i}`}>Medium {risk.Medium}</UncontrolledTooltip>
																<UncontrolledTooltip placement='top' target={`assetLow-${i}`}>low {risk.Low}</UncontrolledTooltip>
															</div>
														: 
															<div className='stock-progress'>
																<div className='stock-5'></div>
															</div>
														}
														</div>
														<div className="w-20 pl-2 text-left"><small>{risk.totalFail}</small></div>
													</div>
												)
											})
											
											:
												<div className='d-flex justify-content-between'>
													<div className='w-30 text-left align-self-center'>
														<p className="font-weight-bold f10 m-0">{this.state.selectedService !== '' ? this.state.selectedService : 'All'}</p>
													</div>
													<div className="w-50 align-self-center">
														<div className='stock-progress'>
															<div className='stock-5'></div>
														</div>
													</div>
													<div className="w-20 pl-2 text-left"><small>No&nbsp;Data</small></div>
												</div>
											}
											{/* <p className={`text-info text-right mb-0 pt-2 small cursorPointer 
												${this.state.filterServiceCount && this.state.filterServiceCount.length > 13 ? '' : 'displayNone'} `}
												onClick={()=>this.setState({ filterServiceCountLength: this.state.filterServiceCount.length > this.state.filterServiceCountLength ? this.state.filterServiceCount.length : 13 })}>
											 	{this.state.filterServiceCount && this.state.filterServiceCount.length > this.state.filterServiceCountLength ? 'See more services..' : this.state.filterServiceCount ? 'See less services' : ''}
											</p> */}
											<div className="d-flex justify-content-between py-1">
												{/* <small className="mr-3">
													Showing 
													{this.state.complianceIdCounts &&  this.state.complianceIdCounts.length ?  
														' '+(this.state.showingOffStart) +' - '+ this.state.showingOff +' ('+this.state.complianceIdCounts.length+') '
													: ' 0'
													} 
												</small> */}
												<div className="w-90">
													<span className={`text-left far fa-chevron-left mr-3 text-muted cursorPointer ${!this.state.serviceWiseStart  ? 'displayNone': ''}`} 
														onClick={() => this.navigateServieWise('left') }>
													</span>
												</div>
												<span className={`${(this.state.filterServiceCount && this.state.filterServiceCount.length > this.state.serviceWiseheight) && (this.state.filterServiceCount && this.state.filterServiceCount.length > this.state.filterServiceCountLength) ? '': 'displayNone'} float-right text-right far fa-chevron-right cursorPointer`} 
													onClick={() => this.navigateServieWise('right') }>
												</span>
											</div>
										</div>
										
										<div className='d-flex mt-2' ref={this.envRef}>
											<Select
												placeholder={'All'}
												//menuIsOpen={this.state.envDropIsOpen}
												isSearchable={false}
												//onFocus={this.openProivderMenu}
												className='f11 p-0 reactSelectSmallDrop w-100'
												value={({
													value: this.state.selectedTag,
													label: this.state.selectedTag
												})}
												options={environemntDropdown.map(item => ({
													value: item.value,
													label: item.label,	
												}))}
												onChange={event =>  
													this.setState(
														{
															selectedTag: event.value,
															showLoading: true,
														},
														() => {
															this.getScore()
															this.getDayWiseCountTags()
														}
													)
												}
											/>
											<small className='ml-3 align-self-center'>Health Scores by Popular Tags like Environment, Application, Cost Center, ..</small>
										</div>
										<div className="d-flex mt-3">
											{
												this.state.score && this.state.score.length > 0 ?
												this.state.score.slice(0,5).map((res,i)=>{
													let circleColor = 'blue'
													if(i === 0) {
														circleColor = '#DC2B55';
													} else if(i === 1) {
														circleColor = '#06CB9E';
													} else if(i === 2) {
														circleColor = '#693EBC';
													} else if(i === 3) {
														circleColor = '#1FCFE7';
													} else if(i === 4) {
														circleColor = '#2F2A40';
													}
													return (
														<div className={'text-center m0 p3 mr10 '+(i!==0 && (i+1) !== this.state.score.length?'col-sm-2':'col-sm-2')} key={i}>
															<CircularProgressbar value={(res.overall.split("/"))[0]} text={(res.overall.split("/"))[0]} counterClockwise={true} styles={{trail: {strokeWidth:'5'},path: {stroke: circleColor,strokeWidth:'6'}}}/>
															<p className="f10 p3 m0">{res.tags[0].value}</p>
														</div>
													)
												})
												: null
											}
										</div>
										<div className="pill-tabs">
											{this.state.score && this.state.score.length ?
												<React.Fragment>
													<span className="col-sm-1 far fa-chevron-left text-muted mr-2 cursorPointer float-left" style={{lineHeight:2}} onClick={() => this.envSlideLeft()}></span>											
													<ul className="col-sm-9 nav nav-tabs mb0 d-flex flex-nowrap overflow-hidden float-left" style={{paddingBottom:2}} id="myTab" ref={this.proEnvWrap} role="tablist">
														
														{this.state.score.slice(0,5).map((res,i)=>{
															return(
																<li key={i} className="nav-item mb5">
																	<span className={"nav-link text-nowrap cursorPointer "+(this.state.detectorKey === res.tags[0].value? 'active':'')} onClick={(e)=>{this.setState({detectorKey:res.tags[0].value},()=>{this.getTagwiseChangeDetails()})}}>{res.tags[0].value}</span>
																</li>
															)
														})}
														
													</ul>
													<span className='col-sm-1 far fa-chevron-right text-dark cursorPointer ml-2'  onClick={() => this.envSlideItemRight()}></span>
												</React.Fragment>
											: <p className="d-flex justify-content-center">No Score for the selected Tag</p>
											}
											
											<div className="tab-content mt-3" id="myTabContent">
												{this.state.bar_data && this.state.bar_data.length && this.state.bar_option ?
													<div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
														<p className="f12 mb-1">Status by priority level for the duration selected is shown.</p>
														<div className='d-flex justify-content-between f12 mt-3 position-relative z999 mb-n4'>
															<div className={`cursorPointer text-info ${this.state.disablePreviousWeekBtn ? 'disabled' : ''} ${this.state.hidePreviousNextBtn ? 'displayNone' : 'displayBlock'}`} onClick={()=> {this.setState({showPrevNextRecord:(this.state.showPrevNextRecord - 1)},() => {this.showSummaryGraph()})}}><span className='far fa-angle-double-left'></span> Previous </div>
															<div className={`text-info ${this.state.disableNextWeekBtn ? 'disabled cursorPointer' : ' cursorPointer'} ${this.state.hidePreviousNextBtn ? 'displayNone' : 'displayBlock'}`} onClick={()=> {this.setState({showPrevNextRecord:(this.state.showPrevNextRecord + 1)},() => {this.showSummaryGraph()})}}>Next <span className='far fa-angle-double-right'></span></div>
														</div>
													
														<div>
															<Chart type="bar" series={this.state.bar_data} width="100%" height={350} options={this.state.bar_option}/>
														</div>
													</div>
												: 
												null

												}
												<div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">...</div>
												<div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">...</div>
											</div>
										</div>
										<div className="d-flex mt-3 displayNone">
											<div className="mr-4">
												<h4 className='font-weight-normal'>
													{
														this.state.billingCount.total_bill &&
														"$"+this.state.billingCount.total_bill
													}
												</h4>
												<span className="badge badge-outline-primary d-table my-3 f12 mx-auto">Pellentesque </span>
												
												{this.state.cost_pass_day_wise_count_data && this.state.cost_pass_day_wise_count_data.length && this.state.cost_pass_day_wise_count_options ?
													<Line data={this.state.cost_pass_day_wise_count_data} options={this.state.cost_pass_day_wise_count_options} width={80} height={35}/>

												: null
												}
											</div>
											<div>
												<h4 className="text-info font-weight-normal">
													{
														this.state.billingCount.savings&&
														"$"+this.state.billingCount.savings
													}
												</h4>
												<p className="mb-1"><b>Lorem Ipsum</b></p>
												<p className="f12 fw400 mb-1">Sed in semper ex. Nulla sed finibus orci, eget semper odio. Aenean viverra bibendum ultrices viverra bibendum ultrices..</p>
											</div>
										</div>
										<div className="displayNone">
											<p className="mt-3 mb-0 font-weight-bold">
												Service wise
											</p>
											<div className="d-flex">
												<div>
												{
													this.state.doughnut_data && Object.entries(this.state.doughnut_data).length ?
													<Doughnut data={this.state.doughnut_data} options={this.state.doughnut_options} width={250} height={220} />
													: null
												}
												</div>
												<div>
													<p className="mb-2 f12">
														<span className="text-highlight mr-3">47%</span>
														<span className='text-success font-weight-bolder'>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span>
													</p>
													<p className="f12">
														<span className="text-highlight mr-3">47%</span>
														<span className='text-success font-weight-bolder'>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span>
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-7 col-xl-9">
										<div className="row border-bottom pb-3 ml-0">
											<div className='col-sm-8 pl-0'>
												<div className='d-flex'>
													{this.state.new_bar_transition_data && this.state.new_bar_transition_data.length ?
														<div className='w-25 borderMiddle pr-2'>
															<h6 className='mb-2 d-flex text-purple'>New</h6>
															<div className='d-flex text-muted'>
																<p className="m-0 font-weight-bold f30 align-self-end">{this.state.newTransitionFailSum}</p>
																<p className='font-weight-bold align-self-end mb-0 ml-2'><span className='fal fa-arrow-up text-success'></span>10% day</p>
															</div>
															<div className='mt-3'>
																<span id={'risk_trend_1'} className='mr-1 badge bg-1 cursorPointer'>
																	C:101
																	<UncontrolledTooltip placement='top' target={'risk_trend_1'}>{'Critical 125'}	</UncontrolledTooltip>
																</span>
																<span className='mr-1 badge bg-1 cursorPointer'>M:125</span>
																<span className='mr-1 badge bg-1 cursorPointer'>H:640</span>
																<span className='mr-1 badge bg-1 cursorPointer'>L:545</span>
															</div>
															<div className='w-100 mt-n1 mb-n3'>
																<Chart type="bar" series={this.state.new_bar_transition_data} options={this.state.new_bar_transition_option} height={100}/>
															</div>
														</div>
													: 
														null
													}
													{this.state.pending_bar_transition_data && this.state.pending_bar_transition_data.length ?
														<div className='w-25 borderMiddle px-2'>
															<h6 className='mb-2 d-flex text-purple'>Pending</h6>
															<div className='d-flex text-muted'>
																<p className="m-0 font-weight-bold f30 align-self-end">{this.state.pendingTransitionFailSum}</p>
																<p className='font-weight-bold align-self-end mb-0 ml-2'><span className='fal fa-arrow-up text-success'></span>10% day</p>
															</div>
															<div className='mt-3'>
																<span className='mr-1 badge bg-1 cursorPointer'>C:101</span>
																<span className='mr-1 badge bg-1 cursorPointer'>M:125</span>
																<span className='mr-1 badge bg-1 cursorPointer'>H:640</span>
																<span className='mr-1 badge bg-1 cursorPointer'>L:545</span>
															</div> 
															<div className='w-100 mt-n1 mb-n3'>
																<Chart type="bar" series={this.state.pending_bar_transition_data} options={this.state.pending_bar_transition_option} height={100}/>
															</div>
														</div>
													: null}
													{this.state.resolved_bar_transition_data && this.state.resolved_bar_transition_data.length ?
														<div className='w-25 borderMiddle px-2'>
															<h6 className='mb-2 d-flex text-purple'>Resolved</h6>
															<div className='d-flex text-muted'>
																<p className="m-0 font-weight-bold f30 align-self-end">{this.state.resolvedTransitionFailSum}</p>
																<p className='font-weight-bold align-self-end mb-0 ml-2'><span className='fal fa-arrow-up text-success'></span>10% day</p>
															</div>
															<div className='mt-3'>
																<span className='mr-1 badge bg-1 cursorPointer'>C:101</span>
																<span className='mr-1 badge bg-1 cursorPointer'>M:125</span>
																<span className='mr-1 badge bg-1 cursorPointer'>H:640</span>
																<span className='mr-1 badge bg-1 cursorPointer'>L:545</span>
															</div>
															<div className='w-100 mt-n1 mb-n3'>
																<Chart type="bar" series={this.state.resolved_bar_transition_data} options={this.state.resolved_bar_transition_option} height={100}/>
															</div>
														</div>
													: null}
													{this.state.reoccured_bar_transition_data && this.state.reoccured_bar_transition_data.length ?
														<div className='w-25 borderMiddle px-2'>
															<h6 className='mb-2 d-flex text-purple'>Reoccured</h6>
															<div className='d-flex text-muted'>
																<p className="m-0 font-weight-bold f30 align-self-end">{this.state.reoccuredTransitionFailSum}</p>
																<p className='font-weight-bold align-self-end mb-0 ml-2'><span className='fal fa-arrow-up text-success'></span>10% day</p>
															</div>
															<div className='mt-3'>
																<span className='mr-1 badge bg-1 cursorPointer'>C:101</span>
																<span className='mr-1 badge bg-1 cursorPointer'>M:125</span>
																<span className='mr-1 badge bg-1 cursorPointer'>H:640</span>
																<span className='mr-1 badge bg-1 cursorPointer'>L:545</span>
															</div>
															<div className='w-100 mt-n1 mb-n3'>
																<Chart type="bar" series={this.state.reoccured_bar_transition_data} options={this.state.reoccured_bar_transition_option} height={100}/>
															</div>
														</div>
													: null}
												</div>
											</div>
											<div className="col-xl-4 m-0">
												<div className="pl-2">
													<div className="d-flex justify-content-between bg-light rounded p-2 text-muted">
														<div className="d-flex ml-1">
															<h3 className="pr-2 mr-2 border-right mb-0 align-self-center">{numberFormater(this.state.passSum ? this.state.passSum : 0)}</h3>
															<p className="f12 mb-0 pr-3 align-self-center">Governance Checks passed
																 {/* is <b>{this.state.passSum ? this.state.passSum : ''}</b> */}
															</p>
														</div>
														<div className="d-flex">
															<h3 className="pr-2 mr-2 border-right mb-0 align-self-center">{numberFormater(this.state.failSum ? this.state.failSum : 0)}</h3>
															<p className="f12 mb-0 align-self-center">Governance Checks failed 
																{/* is <b>{this.state.failSum ? this.state.failSum : ''}</b> */}
															</p>
														</div>
													</div>
													<p className="m-0 f12 mb-0 pr-3 displayNone"> Daily trend is shown below</p>
													{this.state.multi_pass_fail_series && this.state.multi_pass_fail_series.length ? 
														<div className='mb-n2'>
															<Chart type="area" series={this.state.multi_pass_fail_series} options={this.state.multi_pass_fail_options} height={100}/>
														</div>
														 
													: null}
													
													{this.state.percentStatusCount && Object.entries(this.state.percentStatusCount).length ?
														<div className="d-flex justify-content-between mt-3">
															<p className="m-0 mr-3">
																<span className= {(this.state.percentStatusCount['current'].pass > this.state.percentStatusCount['past'].pass) ? "far fa-arrow-up text-success mr-2": "far fa-arrow-down text-success mr-2"} ></span>
																<b>{this.state.percentStatusCount['percent'].pass} %</b>
																<small className="ml-2">increase in pass</small>
															</p>
															<p className="m-0 float-right">
																<span className= { (this.state.percentStatusCount['current'].fail > this.state.percentStatusCount['past'].fail) ? "far fa-arrow-up text-danger mr-2": "far fa-arrow-down text-danger mr-2"} ></span>
																<b>{this.state.percentStatusCount['percent'].fail} %</b>
																<small className="ml-2">decrease in fail</small>
															</p>
														</div>
													: null
													}
												</div>
												<div className="d-flex flex-column displayNone">
													<div className="d-flex">
													<h2 className="mr-3 pr-3 border-right mb-0 align-self-center">{numberFormater(this.state.failSum ? this.state.failSum : '')}</h2>
													<p className="f12 mb-0">Number of checks failed is <b>{this.state.failSum ? this.state.failSum : ''}</b>. Daily trend is shown below</p>
													</div>
													{this.state.fail_series && this.state.fail_series.length ? 
														<div className='mt-n1 mb-n2'>
															<Chart type="area" series={this.state.fail_series} options={this.state.fail_options} height={100}/>
														</div>
															
													: null}
													<div className="d-flex mt-3">
														{this.state.percentStatusCount && Object.entries(this.state.percentStatusCount).length ?
															<p className="mr-3">
																<span className= { (this.state.percentStatusCount['current'].fail > this.state.percentStatusCount['past'].fail) ? "far fa-arrow-up text-danger mr-2": "far fa-arrow-down text-danger mr-2"} ></span>
																<b>{this.state.percentStatusCount['percent'].fail} %</b>
																<small className="ml-2">decrease in fail</small>
															</p>
														: null
														}
													</div>
												</div>
											</div>
										</div>
										<div className="row border-bottom py-3 ml-0">
											<div className="col-xl-7 m-0 p-0 px-0">
												<p className='font-weight-bold'>Priority Count</p>
												<div className="d-flex text-muted">	
													{this.state.riskTotalCounts && this.state.riskTotalCounts.length > 0 && this.state.riskTotalCounts.map((riskDetails, i) => {
													let riskValue = ((riskDetails.fail_count * 100) / this.state.failSum) +'%' ;
													let barFillColor = '';
													let margin = ''
													let text = ''
													if(riskDetails.risk === 'Critical') {
														text = 'Critical priority'
														barFillColor = 'criticalbgColor';
														margin = 'mx-3';
													} else if(riskDetails.risk === 'High') {
														text = 'High priority'
														barFillColor = 'highbgColor';
														margin = 'mx-3';
													} else if(riskDetails.risk === 'Medium') {
														text = 'Medium priority'
														barFillColor = 'mediumbgColor';
														margin = 'mx-3';
													} else if(riskDetails.risk === 'Low') {
														text = 'Low priority'
														barFillColor = 'lowbgColor';
													} 
													return (
														<div key={i} className={`d-flex riskcout  flex-column text-center ${margin}`}>
															<h5>{riskDetails.fail_count}</h5>
															<div className="progress">
																<div className={`progress-bar ${barFillColor}`}
																	role="progressbar" style={{width : riskValue}}
																	aria-valuenow="25" aria-valuemin="0" 
																	aria-valuemax="100">
																</div>
															</div>
															<small className="mt-2 f8">{text}</small>
														</div>
													)
													})}
												</div>
												<div className="mt-3 mt-n3 multiArea">
													{/* {this.state.line_multi_data.datasets && this.state.line_multi_data.datasets !== undefined &&this.state.line_multi_data.datasets.length &&
														<Line id="multiLine" data={this.state.line_multi_data} options={this.state.line_multi_option} width={100}  height={40}/>
													} */}

													{this.state.multi_series.length ?
													
														<Chart type="area" series={this.state.multi_series} options={this.state.multi_options} height={280} />
														:
														null
													}
												</div>
											</div>
											<div className="col-xl-5 p0">
												<p className='font-weight-bold'>Category wise scores & Daily trends</p>
												{this.state.categoryScoreList && this.state.categoryScoreList.map((categoryItem, y) => {
														return (
															<div key={y} className="row mb-2">
																<div id={categoryItem.category} className="w-8 align-self-center" >
																	<Link
																		to={{
																			pathname: URL_PATH.CHECKS_SEARCH,
																			state: { 
																				selectedProvider: this.state.selectedProvider, 
																				selectedDuration: this.state.selectedDuration, 
																				selectedAccount: this.state.selectedAccount, 
																				selectedAccountName: this.state.selectedAccountName ? this.state.selectedAccountName: '',
																				selectedCategory: categoryItem.category, 
																				from: this.props.location 
																			}
																		}}
																	>
																		<CircularProgressbar
																			value={categoryItem.data}
																			text={categoryItem.data?categoryItem.data:'0'}
																			counterClockwise={true}
																			styles={{trail: {strokeWidth:'6'},path: {stroke: '#19B2C0',strokeWidth:'6'},text: {fill: '#19B2C0',fontSize: '20px'}}}
																		/>
																		<UncontrolledTooltip placement='top' target={categoryItem.category}>{categoryItem.category + ' overall score is ' +categoryItem.data}</UncontrolledTooltip>
																	</Link>
																</div>
																<div className="w-80 d-flex border rounded-pill p-1 float-left ml-3 align-self-center text-muted">
																	<div className="col-sm-3 p-2 mt-1 align-self-center">
																		{categoryItem.category==='Security' ? 
																		this.state.bar_security_data && this.state.bar_security_data.length &&  this.state.bar_category_option ?
																			<Chart type="bar" series={this.state.bar_security_data} width="100%" height={30} options={this.state.bar_category_option}/> : ''
																		: categoryItem.category==='Availability' ?
																		this.state.bar_availability_data && this.state.bar_availability_data.length &&  this.state.bar_category_option ?
																			<Chart type="bar" series={this.state.bar_availability_data} width="100%" height={30} options={this.state.bar_category_option}/> : ''

																		: categoryItem.category==='Governance' ?
																		this.state.bar_governance_data && this.state.bar_governance_data.length &&  this.state.bar_category_option ?
																			<Chart type="bar" series={this.state.bar_governance_data} width="100%" height={30} options={this.state.bar_category_option}/> : ''

																		: categoryItem.category==='Operations' ?
																		this.state.bar_operations_data && this.state.bar_operations_data.length &&  this.state.bar_category_option ?
																			<Chart type="bar" series={this.state.bar_operations_data} width="100%" height={30} options={this.state.bar_category_option}/> : ''
																		:
																		this.state.bar_tagging_data && this.state.bar_tagging_data.length && this.state.bar_category_option ?
																			<Chart type="bar" series={this.state.bar_tagging_data} width="100%" height={30} options={this.state.bar_category_option}/> : ''
																		}
																	</div>

																	{categoryItem.category ==='Availability' ?
																	<div className="col-sm-9 p-0 m-0 align-self-center d-flex">
																		<div className="align-self-center w-40 p-0 m-0">
																			<small className="f14"><b>{categoryItem.category}</b></small>
																		</div>
																		<div className="w-60 pl-3 m-0">
																			<div className="d-flex">
																				<small className="w-67">checks passed</small> 
																				<small className="w-33 align-self-center">: <b className="text-primary">{this.state.availbilityPassSum}</b></small>
																			</div>
																			<div className="d-flex">
																				<small className="w-67">checks failed</small> 
																				<small className="w-33 align-self-center">: <b className="text-primary">{this.state.availabilitySum}</b></small>
																			</div>
																		</div>
																	</div>
																	: categoryItem.category ==='Governance'?
																	<div className="col-sm-9 p-0 m-0 align-self-center d-flex">
																		<div className="align-self-center w-40 p-0 m-0">
																			<small className="f14"><b>{categoryItem.category}</b></small>
																		</div>
																		<div className="w-60 pl-3 m-0">
																			<div className="d-flex">
																				<small className="w-67">checks passed</small> 
																				<small className="w-33 align-self-center">: <b className="text-primary">{this.state.governancePassSum}</b></small>
																			</div>
																			<div className="d-flex">
																				<small className="w-67">checks failed</small> 
																				<small className="w-33 align-self-center">: <b className="text-primary">{this.state.governanceSum}</b></small>
																			</div>
																		</div>
																	</div>
																	: categoryItem.category ==='Security'?
																	<div className="col-sm-9 p-0 m-0 align-self-center d-flex">
																		<div className="align-self-center w-40 p-0 m-0">
																			<small className="f14"><b>{categoryItem.category}</b></small>
																		</div>
																		<div className="w-60 pl-3 m-0">
																			<div className="d-flex">
																				<small className="w-67">checks passed</small> 
																				<small className="w-33 align-self-center">: <b className="text-primary">{this.state.securityPassSum}</b></small>
																			</div>
																			<div className="d-flex">
																				<small className="w-67">checks failed</small> 
																				<small className="w-33 align-self-center">: <b className="text-primary">{this.state.securitySum}</b></small>
																			</div>
																		</div>
																	</div>
																	: categoryItem.category ==='Operations'?
																	<div className="col-sm-9 p-0 m-0 align-self-center d-flex">
																		<div className="align-self-center w-40 p-0 m-0">
																			<small className="f14"><b>{categoryItem.category}</b></small>
																		</div>
																		<div className="w-60 pl-3 m-0">
																			<div className="d-flex">
																				<small className="w-67">checks passed</small> 
																				<small className="w-33 align-self-center">: <b className="text-primary">{this.state.operationsPassSum}</b></small>
																			</div>
																			<div className="d-flex">
																				<small className="w-67">checks failed</small> 
																				<small className="w-33 align-self-center">: <b className="text-primary">{this.state.operationsSum}</b></small>
																			</div>
																		</div>
																	</div>
																	:
																	<div className="col-sm-9 p-0 m-0 align-self-center d-flex">
																		<div className="align-self-center w-40 p-0 m-0">
																			<small className="f14"><b>{categoryItem.category}</b></small>
																		</div>
																		<div className="w-60 pl-3 m-0">
																			<div className="d-flex">
																				<small className="w-67">checks passed</small> 
																				<small className="w-33 align-self-center">: <b className="text-primary">{this.state.taggingPassSum}</b></small>
																			</div>
																			<div className="d-flex">
																				<small className="w-67">checks failed</small> 
																				<small className="w-33 align-self-center">: <b className="text-primary">{this.state.taggingSum}</b></small>
																			</div>
																		</div>
																	</div>
																	//<small className="col-sm-9 p-0 m-0 align-self-center">{categoryItem.category} Score and Last 7 days score trend. {categoryItem.category} checks failed is {this.state.taggingSum}.</small>
																	}
																	
																</div>
														</div>
														)
													}
												)}
											</div>
										</div>
										{/* <div className="row border-bottom py-3 ml-0">
											<div className='d-flex align-self-start'>
												<div className="col-sm-3 d-flex flex-column text-center align-self-end">
													<p className="mb-2 f12">
														<span className="text-highlight mr-3">{this.state.processCount.event_id}</span>
														<span className='text-success font-weight-bolder'>Total Events processed is </span>
													</p>
												</div>
												<div className="col-sm-3 d-flex flex-column text-center align-self-end mx-3">
													<h4 className="text-danger">{numberFormater(this.state.processCount.asset_id)}</h4>
													<small className="f8">Total Assets Scanned is {this.state.processCount.asset_id} Lorem ipsum dolor sit amet, consectetu.</small>
												</div>
												<div className="col-sm-3 d-flex flex-column text-center align-self-end mx-3">
													<h5>{this.state.processCount.resource_type}</h5>
													<small className="f8">Services</small>
												</div>
											</div>
										</div> */}
										<div className="border-bottom py-3 ml-0 displayNone">
											<p className='font-weight-bold'>Observations</p>
											<div className='row'>
												<div className="col-sm-6 col-xl-3 mb-3">
													<p className="mb-2 f12 d-flex">
														{/* <span className="d-block text-highlight mr-2">{(this.state.processCount.event_id)}</span> */}
														<span className='d-block text-muted font-weight-bolder'><b className='text-primary f18'>500</b> checks were conducted on <b className='text-primary f18'>324</b> important events occured in your cloud</span>
													</p>
												</div>
												<div className="col-sm-6 col-xl-3 mb-3">
													<p className="mb-2 f12 d-flex">
														<span className='d-block text-muted font-weight-bolder'>Events checked were 
														<span className="font-weight-bold mx-2 text-danger f18">
															{(this.state.processCount && this.state.processCount.event_id ? this.state.processCount.event_id : '')}
															<span className='far fa-arrow-up'></span>
														</span>
														than previuos month/week.</span>
													</p>
												</div>
												<div className="col-sm-6 col-xl-3 mb-3">
													<p className="mb-2 f12 d-flex">
														<span className='text-muted font-weight-bolder'><span className="f18 mr-2 text-success">25</span> events/types had contributed to 256 failed checks.</span>
													</p>
												</div>
												<div className="col-sm-6 col-xl-3 mb-3">
													<p className="mb-2 f12 d-flex">
														<span className='text-muted font-weight-bolder'><span className="f18 mr-2 text-primary">50%</span> of failed checks were generated in EC2, S3 service</span>
													</p>
												</div>
												<div className="col-sm-6 col-xl-3">
													<p className="mb-2 f12 d-flex">
														{/* <span className="d-block text-highlight mr-2">{(this.state.processCount.event_id)}</span> */}
														<span className='d-block text-muted font-weight-bolder'><b className='text-primary f18'>500</b> checks were conducted on <b className='text-primary f18'>324</b> important events occured in your cloud</span>
													</p>
												</div>
												<div className="col-sm-6 col-xl-3">
													<p className="mb-2 f12 d-flex">
														<span className='d-block text-muted font-weight-bolder'>Events checked were 
														<span className="font-weight-bold mx-2 text-danger f18">
															{(this.state.processCount && this.state.processCount.event_id ? this.state.processCount.event_id : '')}
															<span className='far fa-arrow-up'></span>
														</span>
														than previuos month/week.</span>
													</p>
												</div>
												<div className="col-sm-6 col-xl-3">
													<p className="mb-2 f12 d-flex">
														<span className='text-muted font-weight-bolder'><span className="f18 mr-2 text-success">25</span> events/types had contributed to 256 failed checks.</span>
													</p>
												</div>
												<div className="col-sm-6 col-xl-3">
													<p className="mb-2 f12 d-flex">
														<span className='text-muted font-weight-bolder'><span className="f18 mr-2 text-primary">50%</span> of failed checks were generated in EC2, S3 service</span>
													</p>
												</div>
												{/* <div className="col-sm-4">
													<h4 className="text-danger">{numberFormater(this.state.processCount.asset_id)}</h4>
													<small className="f8">Total Assets Scanned is {this.state.processCount.asset_id} Lorem ipsum dolor sit amet, consectetu.</small>
													<div className="progress mt-2">
														<div className="progress-bar bg-danger" role="progressbar" style={{width: "50%"}}
														aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
													</div>
												</div>
												<div className="col-sm-4">
													<h5>234</h5>
													<small className="f8">Lorem ipsum dolor sit amet, consectetu.</small>
													<div className="progress mt-2">
														<div className="progress-bar bg-success" role="progressbar" style={{width: "50%"}}
														aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
													</div>
												</div> */}
											</div>
										</div>
										<div className="row my-3 displayNone">
											<div className="col-12">
												<div className="d-flex justify-content-between">
													<p className='font-weight-bold m-0 p-0'>Compliance</p>													
													<div className="d-flex">
														<small className="mr-3">
															Showing 
															{this.state.complianceIdCounts &&  this.state.complianceIdCounts.length ?  
																' '+(this.state.showingOffStart) +' - '+ this.state.showingOff +' ('+this.state.complianceIdCounts.length+') '
															: ' 0'
															} 
														</small>
														<span className="far fa-chevron-left mr-3 text-muted cursorPointer" onClick={() => this.slideLeft()}></span>
														<span className={`far fa-chevron-right cursorPointer ${this.state.showingRightIcon ? '' : 'disabled'}`}  onClick={() => this.slideItemRight('')}></span>
													</div>
												</div>
												<p className="f12 fw400">Sed in semper ex. Nulla sed finibus orci, eget semper odio. Aenean viverra bibendum ultrices viverra bibendum ultrices..</p>
												<div ref={this.sliderWrap} className="d-flex flex-nowrap overflow-hidden">
													{this.state.complianceIdCounts.map((res, i) => {
														return (
															<Link key={i}
																className='box hippa-box bg-white3 shadow-none p-3 mr-3'
																to={{
																	pathname: URL_PATH.COMPLIANCE_DASHBOARD,
																	state: { 
																		selectedProvider: this.state.selectedProvider, 
																		selectedDuration: this.state.selectedDuration, 
																		selectedAccount: this.state.selectedAccount, 
																		selectedAccountName: this.state.selectedAccountName ? this.state.selectedAccountName: '',
																		selectedComplicance: res.complianceId, 
																		from: this.props.location 
																	}
																}}
																onClick={() => {
																	this.setState({ compilanceDetailInput: res.complianceId })
																}}
															>
																<div ref={this.sliderItemWrap}>
																	<h6>{res.complianceId.toUpperCase()}</h6>
																	<div className="d-flex">
																		<div className="mr-3 hippa-chart">
																		<CircularProgressbar
																			value={res.value}
																			text={res.value}
																			counterClockwise={true}
																			styles={{trail: {strokeWidth:'6'},path: {stroke: '#19B2C0',strokeWidth:'6'},text: {fill: '#19B2C0',fontSize: '20px'}}}/>
																		</div>
																		
																			
																		<React.Fragment>
																			<div className="d-flex flex-column text-center align-self-end mr-3">
																				<p className="mb-0 text-success">{numberFormater(res.pass_count)}</p>
																				<p className="mb-0">Pass</p>
																				<div className="progress mt-2">
																					<div className="progress-bar bg-success" role="progressbar" style={{width: "50%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
																				</div>
																			</div>
																			<div className="d-flex flex-column text-center align-self-end">
																				<p className="mb-0 text-danger">{numberFormater(res.fail_count)}</p>
																				<p className="mb-0">Fail</p>
																				<div className="progress mt-2">
																					<div className="progress-bar bg-danger" role="progressbar" style={{width: "50%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
																				</div>
																			</div>
																		</React.Fragment>
																	</div>
																</div>					
															</Link>
														)
													})}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
GovernanceDashboard.propTypes = {
	listAllProviders: PropTypes.func,
	listAllAccounts: PropTypes.func,
	listAllServices: PropTypes.func,
	getTotalCount: PropTypes.func,
	getDayWiseCount: PropTypes.func,	
	location: PropTypes.object,
	getScore:PropTypes.func,
	getDayWiseCountTags:PropTypes.func,
	getBillingCount:PropTypes.func,
	getIssueStatusCount:PropTypes.func,
	getStatusCount:PropTypes.func,
	getCount:PropTypes.func,
	getCostTrend:PropTypes.func,
	getDailyScore:PropTypes.func,
	getTransitionTrend:PropTypes.func
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		
	}
}

export default AppWrapper(GovernanceDashboard, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllServices,
	getTotalCount,
	getDayWiseCount,	
	getScore,	
	getBillingCount,
	getDayWiseCountTags,
	getIssueStatusCount,
	getStatusCount,
	getCount,
	getCostTrend,
	getDailyScore,
	getTransitionTrend 
})



