/*************************************************
 * Tvastar
 * @exports
 * @file Tags.js
 * @author Rajasekar // on 26/02/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Tags extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	render() {
		return (
			<div className="box p-3 h-100 bg-dark3">
				<h1 className="bx-watermark display-3 watermark-tr">
					<i className="fa fa-tags" />
				</h1>
				<div>
					<b className="text-white">Tags</b>
				</div>
				<ul className="tags mt-2">
					{this.props.tags.map((item, index) => {
						return (
							<li key={index}>
								<span className="tag-lg">
									{item.key}: {item.value}
								</span>
							</li>
						)
					})}
				</ul>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
Tags.propTypes = {
	tags: PropTypes.array,
}
export default Tags
