/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AssetDetailPage.js
 * @author Prakash // on 12/04/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 * 
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { listAllProviders, listAllAccounts, listAllRegions, listAllServices, getAllTagsKeys, getAllTagsValues } from '../../../actions/commonAction'
import { getCoveredServices, listCoveredAssets } from '../../../actions/detectors/detectorsAction'
import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'
import { getMonitoringMetricsByTrend } from '../../../actions/aiops/MetricsAction'
import { momentDateGivenFormat, subHours, twoDateDiffrence, subDays, getDayFromSelectedDuration, convertBytes, convertSeconds, countFormater, convertBits, momentConvertionLocalToUtcTime, getAccountNameFromId, getRegionName, addMinutes } from '../../../utils/utility'
import { UncontrolledTooltip, Spinner } from 'reactstrap'
import _ from 'lodash'

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

import Chart from 'react-apexcharts'
import MetricsTab from './MetricsTab'
import SreMetricsTab from './SreMetricsTab'

let timer = null;

class AssetDetailPage extends Component {
    constructor(props) {
        super(props)
        this.accountRef = React.createRef()
        this.regionRef = React.createRef()
        this.daterRangeRef = React.createRef()
        this.heightRef = React.createRef()
        this.categoryRef = React.createRef()
        
        this.state = {
            minMaxHeight: "700px",
            showLoading: true,

            //filter section start

            selectedProvider: "aws",

            selectedAccount: [],
            isAccountOpen: false,

            selectedRegion: [],
            isRegionOpen: false,

            selectedDuration: "+3h",

            // selectedAssetId: "i-0187412e6d8db13c2",

            
            //filter section end
            
            pageType: "Alerts",
            selectedMetricsType: "Core Metrics",
            
            showSearchBtn: true,
            disableSearchBtn: true,
            categoryList: [],
            assetCategory: [],
            selectedService: [],
            isCategoryOpen: false, 
            tagsArray: [0],

            nextErrorListToken: "",
            changesInFilter: true,
        }
    }

	componentDidMount = () => {
        this.getAllTagsKeys()

        this.onSearch('onLoad')
        
        this.getWindowSize()

        window.addEventListener('resize', () => {
            this.getWindowSize()
        })
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 200  
                
		let outerHeight = (window.outerHeight - browserBorder - headerHeight ) + "px"

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }

    componentDidUpdate = () => {
        if(this.props.assetBrushTimings === "reset") {
            this.props.setAiopsPropsDetails("assetBrushTimings", "")
            this.onChangePageType()
        }
    }

    onSearch = (type) => {
        if(this.state.changesInFilter) {
            let caseStartTime = this.props.caseDetails.created_at
            let caseEndTime = addMinutes(this.props.caseDetails.last_event, 1)

            if(caseEndTime < caseStartTime) {
                caseEndTime = caseStartTime
            }

            let sliderStartDate = caseStartTime
            let sliderEndDate = caseEndTime
            
            sliderStartDate = subDays(caseStartTime, 3)
            this.setState({
                brushStartTime: "",
                brushEndTime: "",
                changesInFilter: false,
                showLoading: true,
                resetEnabled: type === 'onLoad' ? false : true,
                sliderStartDate,
                sliderEndDate,
                nextErrorListToken: "",
                selectedAsset: ""
            },
                () => {
                    // let obj = {}
                    // obj.start_time = this.state.sliderStartDate
                    // obj.end_time = this.state.sliderEndDate
                    // this.props.setAiopsPropsDetails('caseAssetPageFilter', obj)
                    this.listCoveredAssets()
                    this.getCoveredServices()
                }
            )
        }

        // setTimeout(() => { this.setState({ showLoading: false }) }, 3000)
    }

    onReset = () => {
        this.setState({ 
            selectedProvider: "",
            selectedAccount: [],
            selectedDuration: '+7d',
            changesInFilter: this.state.resetEnabled,
            datePickerStartDate: "",
            datePickerEndDate: ""
        },
            () => {
                if(this.state.resetEnabled) {
                    this.onSearch('onLoad')
                }
            }
        )
    }

    listCoveredAssets = () => {
        let params = {}
        params.start_time = momentConvertionLocalToUtcTime(this.state.sliderStartDate, "YYYY-MM-DD HH:mm:00")
        params.end_time = momentConvertionLocalToUtcTime(this.state.sliderEndDate, "YYYY-MM-DD HH:mm:00")
        params.provider = "aws"
        if(this.state.selectedAccount && !this.state.selectedAccount.includes("All")) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && !this.state.selectedRegion.includes("All")) {
            params.region = this.state.selectedRegion
        }
        // params.resource_type = resource_type ? resource_type : this.props.alertPageDetails.resource_type

        if(this.state.nextErrorListToken !== "") {
            params.next_token = this.state.nextErrorListToken
        }
        params.size = 1000
        this.props.listCoveredAssets(params, (promise, response) => {
            if(promise) {
                let results = response.results ? response.results : []
                let selectedAsset = results[0] ? results[0] : ""

                let assetsList = [] 
                if(this.state.nextErrorListToken !== "") {
                    assetsList = this.state.assetsList ? this.state.assetsList : []
                }
                
                if(results && results.length) {
                    assetsList = assetsList.concat(results)
                }

                this.setState({
                    totalAssetsList: assetsList,
                    assetsList,
                    filteredAssetList: assetsList,
                    selectedAsset,
                    selectedAssetId: selectedAsset !== "" ? selectedAsset.asset_id : "",
                    showLoading: false,
                    nextErrorListToken: response.next_token ? response.next_token : ""
                },
                    ()  => {
                        if(this.state.assetsList.length) {
                            this.brushOptions()
                        }
                        if(this.state.nextErrorListToken !== "") {
                            this.setState({ showLoadMore: true })
                        } else {
                            this.setState({ showLoadMore: false })
                        }

                        // let scroll = document.getElementById('asset_name_'+this.state.selectedAssetId);
                        // scroll.scrollIntoView();
                    }
                )
            } else {
                this.setState({ assetsList: [], filteredAssetList: [], showLoading: false, showLoadMore: false })
            }
        })
    }

    onChangeSearchText = () => {
        if(!this.state.showFilterSection) {
            this.getSearchResults()
        } else {
            this.setState({ showSearchBtn: false })
        }
    }

    onClickFilterBtn = () => {
        this.setState({ showFilterSection: !this.state.showFilterSection, showSearchBtn: this.state.showFilterSection },
            () => {
                if(this.state.searchText && this.state.searchText !== "" && !this.state.showFilterSection) {
                    this.getSearchResults()
                }
            }
        )
    }

    getSearchResults = () => {
        if (this.state.searchText && this.state.searchText !== "" && this.state.searchText.length >= 3) {
            this.setState({ disableSearchBtn: false, showSearchBtn: true })
		} else if (this.state.searchText && this.state.searchText !== "" && this.state.searchText.length < 3) {
            this.setState({ disableSearchBtn: true, showSearchBtn: true  })
        } else if(!this.state.searchText || this.state.searchText === "") {
            let assetsList = []
            let selectedAsset = ""
            if(this.state.searchTotalResults && this.state.searchTotalResults.length) {
                assetsList = this.state.searchTotalResults
                selectedAsset = this.state.searchTotalResults[0]
            } else if(this.state.totalAssetsList && this.state.totalAssetsList.length) {
                assetsList = this.state.totalAssetsList
                selectedAsset = this.state.totalAssetsList[0]
            }
            this.setState({
                assetsList,
                filteredAssetList: assetsList,
                selectedAsset,
                selectedAssetId: selectedAsset !== "" ? selectedAsset.asset_id : "",
                showLoading: false
            },
                ()  => {
                    if(this.state.assetsList.length) {
                        this.brushOptions()
                    }
                    if(this.state.nextErrorListToken !== "") {
                        this.setState({ showLoadMore: true })
                    } else {
                        this.setState({ showLoadMore: false })
                    }
                }
            )
            this.setState({ showSearchBtn: this.state.showFilterSection ? false : true, disableSearchBtn: true })
        }
    }

    // function to search every object in array by recurseive
	recursiveSearch = (item, searchValue) => {
		return Object.keys(item).map(key => {
			if (item[key] !== null && typeof item[key] == 'object') {
				return this.recursiveSearch(item[key], searchValue)
				// eslint-disable-next-line valid-typeof
			} else if (typeof item[key] === 'array') {
				return item.map((arrayElement, i) => this.recursiveSearch(arrayElement[i], searchValue))
			} else {
				// if(this.props.searchFilter.includes(key)){
				return item[key] !== null
					? item[key]
							.toString()
							.toLowerCase()
							.indexOf(searchValue.toString().toLowerCase()) > -1
					: false
				// }else{
				// return false;
				// }
			}
		})
	}

    searchFilterListCoveredAssets = (type) => {
        if(type === "search" && this.state.nextErrorListToken === "") {
            let results = this.state.assetsList && this.state.assetsList.length &&
                this.state.assetsList.filter(item => {
                    let isPresent = []
                    isPresent = this.recursiveSearch(item, this.state.searchText)
                        .flat(5)
                        .filter(bool => bool === true)
                    if (isPresent[0]) {
                        return true
                    } else {
                        return false
                    }
                })

            this.setState({ filteredAssetList: results, showSearchLoading: false })
        } else {
            if(type === "search") {
                this.setState({ showLoading: true })
            }
            let params = {}
            params.start_time = momentConvertionLocalToUtcTime(this.state.sliderStartDate, "YYYY-MM-DD HH:mm:00")
            params.end_time = momentConvertionLocalToUtcTime(this.state.sliderEndDate, "YYYY-MM-DD HH:mm:00")
            params.provider = "aws"
            if(this.state.selectedAccount && !this.state.selectedAccount.includes("All")) {
                params.account_id = this.state.selectedAccount
            }
            if(this.state.selectedRegion && !this.state.selectedRegion.includes("All")) {
                params.region = this.state.selectedRegion
            }
            if(this.state.searchText && this.state.searchText !== "") {
                params.query_string = this.state.searchText
            }

            if(type === "filter" && this.state.assetCategory && this.state.assetCategory.length) {
                params.category = this.state.assetCategory
            }
            if(type === "filter" && this.state.selectedService && this.state.selectedService.length) {
                params.service_name = this.state.selectedService
            }        
            if(type === "filter" && this.state.tagsArray && this.state.tagsArray.length) {
                let tags = []
                this.state.tagsArray.forEach(item => {
                    if(this.state["tag_key_"+item] && this.state["tag_key_"+item] !== "" && this.state["tag_value_"+item] && this.state["tag_value_"+item] !== "") {
                        let tagRow = {
                            key: this.state["tag_key_"+item],
                            value: this.state["tag_value_"+item]
                        }

                        tags.push(tagRow)
                    }
                })

                if(tags.length) {
                    params.tags = tags
                }
            }

            this.props.listCoveredAssets(params, (promise, response) => {
                if(promise) {
                    let selectedAsset = ""
                    let results = response.results ? response.results : []
                    if(results && results.length) {
                        selectedAsset = results[0]
                    }
                    
                    this.setState({
                        searchTotalResults: results,
                        assetsList: results,
                        filteredAssetList: results,
                        selectedAsset,
                        selectedAssetId: selectedAsset !== "" ? selectedAsset.asset_id : "",
                        showSearchLoading: false,
                        showLoading: false,
                        showLoadMore: false,
                        showSearchBtn: true,
                        disableSearchBtn: this.state.searchText && this.state.searchText.length > 3 ? false  : true
                    },
                        ()  => {
                            if(this.state.assetsList.length) {
                                this.brushOptions()
                            }
                        }
                    )
                } else {
                    this.setState({ assetsList: [], filteredAssetList: [], selectedAsset: "", selectedAssetId: "", showSearchLoading: false,  showLoading: false, showLoadMore: false, showSearchBtn: true, disableSearchBtn: this.state.searchText && this.state.searchText.length > 3 ?  false : true })
                }
            })
        }
    }
    
    resetResults = () => {
        let selectedAsset = ""
        let assetsList = this.state.totalAssetsList ? this.state.totalAssetsList : []
        if(assetsList && assetsList.length) {
            selectedAsset = assetsList[0]
        }
        this.setState({
            assetsList,
            filteredAssetList: assetsList,
            selectedAsset,
            selectedAssetId: selectedAsset !== "" ? selectedAsset.asset_id : "",
            searchTotalResults: [],
        },
            ()  => {
                this.resetFilterSelection()
                if(this.state.assetsList && this.state.assetsList.length) {
                    this.brushOptions()
                }
            }
        )
    }

    addSection = (array) => {
        let rowList = this.state[array];
        if(this.state[array]) {
            let value = this.state[array][this.state[array].length - 1]
            value = value+1 
            rowList.push(value);
        }
        
        this.setState({[array]: rowList },
            // () => this.checkAllDataFilled()    
        )
    }

    removeSection = (item, i) => {
        this.setState({ ["tag_key_"+item]: "", ["tag_value_"+item]: "" },
            () => {
                let rowList = this.state.tagsArray;
                rowList.splice(i, 1);
                this.setState({ tagsArray: rowList })
            }
        )
    }

    resetFilterSelection = () => {
        this.state.tagsArray.forEach(item => {
            this.setState({ ["tag_key_"+item]: "", ["tag_value_"+item]: "" })
        })

        this.setState({ tagsArray: [0], selectedService: [], assetCategory: [] },
            () => this.getSearchResults()    
        )
    }

    removefilter = (index, value, field) => {
        let filteredResult = this.state[field]
        filteredResult = this.state[field].filter(e => e !== value)
        
        // filteredResult = this.state[field].filter(e => e.key !== option.key && e.value !== option.value)
        this.setState({ [field]: filteredResult })
    }

    getCoveredServices = () => {
        let params = {}
        params.provider = this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider.toLowerCase() : ""
        if(this.state.selectedAccount && this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
            params.region = this.state.selectedRegion
        }
        params.aggregate_by = ["category"]
        
        if(params.provider !== "") {
            this.props.getCoveredServices(params, (promise, response) => {
                if(promise) {
                    let results = response.results && response.results.length ? response.results : []
                    this.setState({ 
                        categoryList: results,
                    })
                } else {
                    this.setState({ categoryList: [], showLoading: false })
                }
            })
        } else {
            this.setState({ categoryList: [], showLoading: false })
        }
    }

    getAllTagsKeys = () => {
        let params = {}
        this.props.getAllTagsKeys(params, (promise, response) => {})
    }

    getAllTagsValues = (index) => {
        let params = {}
        params.tags = [{ "key": this.state["tag_key_"+index] }]
        this.props.getAllTagsValues(params, (promise, response) => {
            if(promise) {
                this.setState({ ["tagValues_"+index]: response })
            }
        })
    }
    
    handleMultiSelectChange = (field, arrayValue) => {
		let value = arrayValue.map(item => item.value)

		let selectedValue = []
		if(field === 'selectedAccount') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.accounts.forEach(acc => {
						selectedValue.push(acc.account_id)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.accounts.length -1)) {
					selectedValue.push('All')
				}
			}	
            this.setState({ [field]: selectedValue, changesInFilter: true })		
		} else if(field === 'selectedRegion') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.regions.length -1)) {
					selectedValue.push('All')
				}
			}
            this.setState({ [field]: selectedValue })
		} else if(field === 'selectedService') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.regions.length -1)) {
					selectedValue.push('All')
				}
			}
            this.setState({ [field]: selectedValue })
		} else if(field === 'assetCategory') {
			let prevState = this.state[field] ? this.state[field] : []
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
                    this.state.categoryList.forEach(item => {
						selectedValue.push(item.category)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.categoryList.length-1)) {
					selectedValue.push('All')
				}
			}	
            this.setState({ [field]: selectedValue, changesInFilter: true })		
		}
    }
    
    getMultiSelectedCount = (type, array) => {
        return array.length && array.includes('All') ? 'All' : (array.length ? array.length +' Selected' : 'All')
    }

    handleClickOutside = (event) => {
    }

    brushOptions = () => {
        let brushSeries = []
        let brushRow = {}
        brushRow.data = [
            {x: momentDateGivenFormat(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm'), y: 0},
            {x: momentDateGivenFormat(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm'), y: 0}
        ]
        brushSeries.push(brushRow)

        let endTIme = momentDateGivenFormat(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:ss')
        let time = endTIme
        let brushHours = 1
        let diff = twoDateDiffrence(this.state.sliderEndDate, this.state.sliderStartDate)
        
        if(diff > 1 && diff <= 3) {            
            brushHours = 6
        } else if(diff > 3 && diff <= 7) {
            brushHours = 24 //3
        } else if(diff > 7 && diff <= 14) {
            brushHours = 36 //6
        } else if(diff > 14 && diff <= 30) {
            brushHours = 48 //12
        } else if(diff > 30) {
            brushHours = 96 //24
        }

        let brushStartTime = subHours(time, brushHours)
        let brushEndTime = time
        
        let optionsBrush = {
            chart: {
                background: '#fff',
                id: 'chart1',
                type: 'line',
                sparkline: {
                    enabled: true
                },
                zoom: {
                    enabled: false,
                },
                brush:{
                    // targets: ['bar1', 'bar2'],
                    target: "",
                    enabled: true,
                    autoScaleYaxis: true,
                },
                selection: {
                    enabled: true,
                    fill: {
                        color: "#039BE5",
                        opacity: 1,
                    },
                    xaxis: {
                        min: new Date(momentDateGivenFormat(brushStartTime, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                        max: new Date(momentDateGivenFormat(brushEndTime, 'YYYY-MM-DD HH:mm:ss')).getTime()
                    }
                },
                events: {
                    selection: (chartContext, { xaxis, yaxis }) => {
                        this.brushSelection(xaxis)
                    }
                }
            },
            markers: {
                size: 0
            },
            colors: ['#F44336'],
            fill: {
                type: 'solid',
                color: "#000",
                opacity: 1,
            },
            xaxis: {
                type: 'datetime',
                tooltip: {
                    enabled: false
                }
            },
            yaxis: {
                show: false,
                tickAmount: 2
            },
            annotations: {
                yaxis: [
                  {
                    y: 1,
                    borderColor: '#000',
                    label: {
                      borderColor: '#00E396',
                      style: {
                        color: '#fff',
                        background: '#00E396'
                      },
                      text: ""
                    }
                  }
                ]
            }
        }

        this.setState({ brushSeries, optionsBrush },
            // () => this.props.loading()
        )
    }

    brushSelection = (xaxis, type) => {
        clearTimeout(timer)
        let brushStartTime = momentDateGivenFormat(xaxis.min, 'YYYY-MM-DD HH:mm:ss')
        let brushEndTime = momentDateGivenFormat(xaxis.max, 'YYYY-MM-DD HH:mm:ss')       

        if(this.state.brushStartTime && this.state.brushStartTime !== "" && this.state.brushStartTime !== this.state.sliderStartDate && this.state.brushEndTime !== this.state.sliderEndDate) {
            timer = setTimeout(() => this.caseAssetPageFilterProps(brushStartTime, brushEndTime, true), 1000)
        } else {
            this.caseAssetPageFilterProps(this.state.sliderStartDate, this.state.sliderEndDate, false)
        }
        
        if(brushStartTime && brushEndTime) {
            this.setState({ brushStartTime, brushEndTime, alertListLoader: true })
        }
    }

    caseAssetPageFilterProps = (brushStartTime, brushEndTime, brushTime) => {
        let obj = {}
        obj.start_time = this.state.sliderStartDate
        obj.end_time = this.state.sliderEndDate
        obj.brushStartTime = brushStartTime
        obj.brushEndTime = brushEndTime
        obj.selectedAsset = this.state.selectedAsset ? this.state.selectedAsset : {}
        this.props.setAiopsPropsDetails('caseAssetPageFilter', obj)

        this.setState({ brushTime })

        this.props.setAiopsPropsDetails('caseAssetAlertsList', {})
        this.props.setAiopsPropsDetails('caseAssetEventsList', {})
        this.props.setAiopsPropsDetails('caseAssetAnomalyList', {})
        this.props.setAiopsPropsDetails('caseAssetErrorsList', {})
        this.props.setAiopsPropsDetails('caseAssetCoreMetricsList', {})
        this.props.setAiopsPropsDetails('caseAssetSreMetricsList', {})
    }
    
    getAssetcaseMetrics = (item) => {
        this.props.setAiopsPropsDetails('caseAssetAlertsList', {})
        this.props.setAiopsPropsDetails('caseAssetEventsList', {})
        this.props.setAiopsPropsDetails('caseAssetAnomalyList', {})
        this.props.setAiopsPropsDetails('caseAssetErrorsList', {})
        
        let obj = {}
        obj.start_time = this.state.sliderStartDate
        obj.end_time = this.state.sliderEndDate
        obj.brushStartTime = this.state.brushTime ? this.state.brushStartTime : this.state.sliderStartDate
        obj.brushEndTime = this.state.brushTime ? this.state.brushEndTime : this.state.sliderEndDate
        obj.selectedAsset = item
        this.props.setAiopsPropsDetails('caseAssetPageFilter', obj)

        this.setState({ selectedAsset: item, selectedAssetId: item.asset_id, showRightSideDetail: false, selectedMetricsType: "Core Metrics", pageType: "Alerts", selectedMetrics: "" },
            () => {
                this.setState({ showRightSideDetail: true })
            }
        )
    }

    onChangePageType = () => {
        this.setState({ brushStartTime: "", brushEndTime: "" },
            () =>  this.brushOptions()   
        )
    }

    getMonitoringMetricsByTrend = (assetId) => {
        let params = {}
        params.start_time = momentConvertionLocalToUtcTime(this.state.sliderStartDate, "YYYY-MM-DD HH:mm:ss")
        params.end_time = momentConvertionLocalToUtcTime(this.state.sliderEndDate, "YYYY-MM-DD HH:mm:ss")
        params.asset_id = assetId
        params.top = 5
        params.anomaly = false
        // params.additional_metrics = [this.props.details.event_type]
        
        this.props.getMonitoringMetricsByTrend(params, (promise, response) => {
            if(promise) {
                this.setState({ 
                    metrics_data: response, 
                    loadingMetrics: false,
                    
                },
                    () => {
                        this.formMetricsGraph()
                    }
                )
            } else {
                this.setState({
                    loadingMetrics: false 
                })
            }
        })
    }

    formMetricsGraph = () => {
        let graphDetails = this.state.metrics_data ? this.state.metrics_data : []
        if(graphDetails && graphDetails.length) {
            graphDetails.forEach(graph => {
                let graphData = graph.data
                let graphLabels = graph.dates
                let cpuRow = {}
                if(graphData["CPUUtilization"]) {
                    let data = []
                    if(graphData["CPUUtilization"].values && graphData["CPUUtilization"].values.length) {
                        graphData["CPUUtilization"].values.forEach(val => {
                            if(graphData["CPUUtilization"].unit) {
                                val = this.getGrapUnitValue(val, graphData["CPUUtilization"].unit)
                            }
                            data.push(val)
                        })
                    }

                    cpuRow.data = data
                    cpuRow.labels = graphLabels
                }
                this.setState({  ['core_metrics_cpu_'+graph.asset_id]: cpuRow })
                
                let memoryRow = {}
                if(graphData["MemoryUtilization"]) {
                    let data = []
                    if(graphData["MemoryUtilization"].values && graphData["MemoryUtilization"].values.length) {
                        graphData["MemoryUtilization"].values.forEach(val => {
                            if(graphData["MemoryUtilization"].unit) {
                                val = this.getGrapUnitValue(val, graphData["MemoryUtilization"].unit)
                            }
                            data.push(val)
                        })
                    }

                    memoryRow.data = data
                    memoryRow.labels = graphLabels
                }
                this.setState({  ['core_metrics_memory_'+graph.asset_id]: memoryRow })

                let networRow = {}
                if(graphData["NetworkIn"]) {
                    let netRowIn = []
                    if(graphData["NetworkIn"].values && graphData["NetworkIn"].values.length) {
                        graphData["NetworkIn"].values.forEach(val => {
                            if(graphData["NetworkIn"].unit) {
                                val = this.getGrapUnitValue(val, graphData["NetworkIn"].unit)
                            }
                            netRowIn.push(val)
                        })
                    }

                    let netRowOut = []
                    if(graphData["NetworkOut"].values && graphData["NetworkOut"].values.length) {
                        graphData["NetworkOut"].values.forEach(val => {
                            if(graphData["NetworkOut"].unit) {
                                val = this.getGrapUnitValue(val, graphData["NetworkOut"].unit)
                            }
                            netRowOut.push(val)
                        })
                    }
                    let data = {
                        NetworkIn: netRowIn,
                        NetworkOut: netRowOut
                    }

                    networRow.data = data
                    networRow.labels = graphLabels
                }
                this.setState({  ['core_metrics_network_'+graph.asset_id]: networRow })
            })

            this.setState({ coreGraphs: ["cpu", "memory", "network"] })
        }
    }

    getGrapUnitValue = (val ,unit) => {
        if(val !== "" && unit !== "") {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytes(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit)
            } else if(unit === 'Count') {
                // val = val > 1 ? parseInt(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== "") {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    nextSetOfRecord = () => {
        if(this.state.nextErrorListToken !== "") {
            this.listCoveredAssets()
        } else {
            this.setState({ showLoadMore: false })
        }
    }

	render() {
		return (
            <div className="container-fluid overflow-auto flex-grow-1 bg-muted" onClick={ (event) => { this.handleClickOutside(event) } }>
                <div className={`loaderOverlay ${this.state.showLoading ? "" : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
                <div className='row h-100'>
                    <div className='col-sm-12 p-0'>
                        <div className='px-3'>
                            <div class="rounded bg-transparent w-100 mt-2">
                                <div className="d-flex">
                                    {this.state.brushSeries && this.state.brushSeries.length && this.state.optionsBrush && Object.entries(this.state.optionsBrush).length ?
                                        <React.Fragment>
                                        <div className="w-5 d-flex justify-content-end pr-2">
                                            
                                        </div>
                                        <div className="removeSvgPoint w-80">
                                            <Chart options={this.state.optionsBrush} series={this.state.brushSeries} type="line" height={8} />
                                        </div>
                                        <div className="pl-2 w-15">
                                            <small className="mb-0 align-self-center text-info f10">{momentDateGivenFormat(this.state.brushStartTime, 'DD MMM HH:mm') +' - '+momentDateGivenFormat(this.state.brushEndTime, 'DD MMM HH:mm')}</small>
                                        </div>
                                        </React.Fragment>
                                    : null}
                                </div>
                            </div>
                            <div className="row">
                                <div className={`col-sm-5 mt-2`}>
                                    <div class="bg-dark pt-2 w-100 overflowYAuto" style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
                                        <div className={`d-flex justify-content-between mx-1 mt-1 pr-2 pt-2 ${this.state.showFilterSection ? "bg-muted" : ""}`}>
                                            {this.state.totalAssetsList && this.state.totalAssetsList.length ?
                                                <div className="d-flex justify-content-between w-70">
                                                    <div className="m-2 w-80">
                                                        <div className={`input-group bg-black5 border-gray5 rounded-5 f12 align-self-center`}>
                                                            <div className='input-group-prepend'>
                                                                <div className="input-group-text bg-transparent px-2 f12">
                                                                    <i className="fal fa-search text-gray5 f12"></i>
                                                                </div>
                                                            </div>
                                                            <input
                                                                value={this.state.searchText}
                                                                onChange={e => this.setState({ searchText: e.target.value }, () => this.onChangeSearchText())}
                                                                type='text'
                                                                className={`form-control form-control-sm bg-transparent text-white pl-0 f12`}
                                                                placeholder='Search'
                                                            />
                                                        </div>
                                                    </div>
                                                    {this.state.showSearchBtn ?
                                                        <button className={`btn btn-sm d-flex ${this.state.disableSearchBtn ? "disabled bg-secondary" : "bg-info"} text-white py-1 align-self-center small`} onClick={ () => this.setState({ showSearchLoading: true }, () => this.searchFilterListCoveredAssets("search"))}> {this.state.showSearchLoading ? <Spinner className='text-center mr-1' color='white' size='sm' /> : null} Search</button>
                                                    : null}
                                                </div>
                                            : null}
                                            <div className="d-flex">
                                                {this.state.searchTotalResults && this.state.searchTotalResults.length ?
                                                    <i className="fas fa-redo align-self-center cursorPointer mr-3 f12" onClick={() => this.resetResults()}></i>
                                                : null}
                                                <i className="fas fa-filter align-self-center cursorPointer" onClick={() => this.onClickFilterBtn()}></i>
                                            </div>
                                        </div>
                                        {this.state.showFilterSection ?
                                            <div className="">
                                                <div className=" mx-1 mb-2 p-2 zapInputDark bg-muted">
                                                    <div className="w-100">
                                                        <div className="d-flex flex-wrap mb-1">
                                                            <label className="mb-0">Category</label>
                                                            {this.state.assetCategory && this.state.assetCategory.length && !this.state.assetCategory.includes("All") ?
                                                                this.state.assetCategory.map((cat, index) => {
                                                                    return(
                                                                        <span className="badge badge-secondary ml-2 mb-1">
                                                                            {cat}
                                                                            <i onClick={ () => this.removefilter(index, cat, 'assetCategory') } className='ml-1 fal fa-times cursorPointer'></i>
                                                                        </span>
                                                                    )
                                                                })
                                                            : this.state.assetCategory.includes("All") ?
                                                                <span className="badge badge-secondary ml-2 mb-1">All</span>
                                                            : null}
                                                        </div>                                                        
                                                        <div className={`multiselectDarkTheme align-self-center mr-2 z998`} ref={this.categoryRef}>
                                                            <ReactMultiSelectCheckboxes						
                                                                placeholderButtonLabel="All"
                                                                getDropdownButtonLabel={() => this.getMultiSelectedCount('assetCategory', this.state.assetCategory)}
                                                                // menuIsOpen ={this.state.isCategoryOpen}
                                                                value={this.state.assetCategory && this.state.assetCategory.map(cat => ({
                                                                    value: cat
                                                                }))}
                                                                onChange={arr => { this.handleMultiSelectChange('assetCategory', arr ? arr : []) }}
                                                                options={this.state.categoryList.map(item => ({
                                                                    value: item.category,
                                                                    label: item.category,
                                                                }))}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="w-100 mt-2">
                                                        <div className="d-flex flex-wrap mb-1">
                                                            <label className="mb-0">Services</label>
                                                            {this.state.selectedService && this.state.selectedService.length && !this.state.selectedService.includes("All") ?
                                                                this.state.selectedService.map((ser, index) => {
                                                                    return(
                                                                        <span className="badge badge-secondary ml-2 mb-1">
                                                                            {ser}
                                                                            <i onClick={ () => this.removefilter(index, ser, 'selectedService') } className='ml-1 fal fa-times cursorPointer'></i>
                                                                        </span>
                                                                    )
                                                                })
                                                            : this.state.selectedService.includes("All") ?
                                                                <span className="badge badge-secondary ml-2 mb-1">All</span>
                                                            : null}
                                                        </div>
                                                        <div className={`multiselectDarkTheme align-self-center mr-2 z999`}>
                                                            <ReactMultiSelectCheckboxes						
                                                                placeholderButtonLabel="All"
                                                                // menuIsOpen ={this.state.serviceDropdown}
                                                                getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedService', this.state.selectedService)}
                                                                value={this.state.selectedService.map(service => ({
                                                                    value: service,
                                                                    label: service
                                                                }))}
                                                                onChange={arr => { this.handleMultiSelectChange('selectedService', arr ? arr : []) }}
                                                                options={this.props.services.map(service => ({
                                                                    value: service.service_name,
                                                                    label: service.service_name,
                                                                }))}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={`w-100 mt-2`}>
                                                        <label className="mb-1">Tags</label>
                                                        {this.state.tagsArray.map((index, i) => {
                                                            return(
                                                                <div className={`w-100 ${i ? "mt-2 border-top-black4" : ""}`}>
                                                                    <div className={`d-flex ${!i ? "" : "mt-3"}`}>
                                                                        <div className="w-80">
                                                                            <Select
                                                                                placeholder={'Select'}
                                                                                isSearchable={true}
                                                                                // menuIsOpen={true}
                                                                                className='f13 p-0 dark-select-theme-with-dropdown-indicator'
                                                                                value={({
                                                                                    value: this.state["tag_key_"+index] && this.state["tag_key_"+index] !== "" ? this.state["tag_key_"+index] : 'Select',
                                                                                    label: this.state["tag_key_"+index] && this.state["tag_key_"+index] !== "" ? this.state["tag_key_"+index] : <span className="placeholder">Select</span>
                                                                                })}
                                                                                options={this.props.tagKeys && this.props.tagKeys.map(item => ({
                                                                                    value: item,
                                                                                    label: item,	
                                                                                }))}
                                                                                onChange={event => this.setState({
                                                                                    ["tag_key_"+index]: event.value,
                                                                                    ["tagValues_"+index]: [],
                                                                                },
                                                                                    () => this.getAllTagsValues(index)
                                                                                )}
                                                                            />
                                                                            <Select
                                                                                placeholder={'Select'}
                                                                                isSearchable={true}
                                                                                // menuIsOpen={true}
                                                                                className='f13 p-0 dark-select-theme-with-dropdown-indicator mt-2'
                                                                                value={({
                                                                                    value: this.state["tag_value_"+index] && this.state["tag_value_"+index] !== "" ? this.state["tag_value_"+index] : 'Select',
                                                                                    label: this.state["tag_value_"+index] && this.state["tag_value_"+index] !== "" ? this.state["tag_value_"+index] : <span className="placeholder">Select</span>
                                                                                })}
                                                                                options={this.state["tagValues_"+index] && this.state["tagValues_"+index].map(item => ({
                                                                                    value: item,
                                                                                    label: item,	
                                                                                }))}
                                                                                onChange={event => this.setState({
                                                                                    ["tag_value_"+index]: event.value
                                                                                })}
                                                                            />
                                                                        </div>
                                                                        {this.state.tagsArray.length > 1 ?
                                                                            <span className={`far fa-trash cursor-pointer f18 align-self-center ml-4`} onClick={() => this.removeSection(index, i)}></span>
                                                                        : null}
                                                                        {(this.state.tagsArray.length - 1) ===  i ?
                                                                            <span className={`far fa-plus cursor-pointer f18 align-self-center ml-4`} onClick={() => this.addSection("tagsArray")}></span>
                                                                        : null}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="d-flex justify-content-end mt-3">
                                                        <button className={`btn btn-sm bg-info text-white mr-2`} onClick={() => this.setState({ showFilterSection: false, showLoading: true }, () => this.searchFilterListCoveredAssets("filter"))}>Filter</button>
                                                        <button className={`btn btn-sm btn-light`} onClick={() => this.resetFilterSelection()}>Clear</button>
                                                    </div>
                                                </div>
                                            </div>
                                        : null}

                                        {this.state.filteredAssetList && this.state.filteredAssetList.length ?
                                            this.state.filteredAssetList.map((item, i) => {
                                                return (
                                                    <React.Fragment>
                                                    <div className={`px-2 cursorPointer ${i ? "border-top" : ""} ${this.state.selectedAsset && this.state.selectedAsset.asset_id === item.asset_id ? 'bg-dark3 text-info disabled' : ""}`} id={"asset_name_"+item.asset_id} onClick={() => this.getAssetcaseMetrics(item)}>
                                                        <div className="hiddenEllipses d-flex">
                                                            <p className={`m-0 f-20 pl pr-1 pt-2 pb-1 ${this.state.selectedAsset && this.state.selectedAsset.asset_id === item.asset_id ? 'text-info' : 'text-white'}`}>
                                                                {!item.id_name_map ?
                                                                    item.resource_type ?
                                                                        item.resource_type === "ApiGateway" ?
                                                                            item.rest_api_name && item.rest_api_name !== "" ?
                                                                                <p className={`ml-1 hiddenEllipses pt-2 ${this.state.selectedAsset && this.state.selectedAsset.asset_id === item.asset_id ? 'text-info' : 'text-primary-color'} mb-0`}>{item.asset_name+" : "+item.rest_api_name})</p>
                                                                            : null
                                                                        : item.resource_type === "EC2" ?
                                                                            item.tags && item.tags.length && item.tags.filter(e => e.key === "Name").length ?
                                                                                <p className={`ml-1 hiddenEllipses pt-2 ${this.state.selectedAsset && this.state.selectedAsset.asset_id === item.asset_id ? 'text-info' : 'text-primary-color'} mb-0`}>{item.asset_name+" : "+item.tags.filter(e => e.key === "Name")[0].value})</p>
                                                                            : null
                                                                        : null
                                                                    : null
                                                                :
                                                                    <span>
                                                                        {(item.id_name_map.value ? item.id_name_map.value : "")}
                                                                        <span className={`ml-1 hiddenEllipses ${this.state.selectedAsset && this.state.selectedAsset.asset_id === item.asset_id ? 'text-info' : 'text-primary-color'} mb-0`}>
                                                                            {item.id_name_map.key ? " : "+item.id_name_map.key : ""}
                                                                        </span>
                                                                    </span>
                                                                }
                                                            </p>
                                                        </div>
                                                        <div class="small hiddenEllipses px-2 pb-2 displayNone">
                                                            {item.provider ? item.provider.toUpperCase() : <span>&nbsp;</span>}
                                                            {item.account_id ? (' : ')+getAccountNameFromId(item.account_id, this.props.accounts) : <span>&nbsp;</span>}
                                                            {item.region ?  (' : ')+getRegionName(item.region, this.state.regions) : <span>&nbsp;</span>}
                                                            {item.resource_type ?  ('  ')+item.resource_type : <span>&nbsp;</span>}
                                                            {item.service_name ?  (' : ')+item.service_name : <span>&nbsp;</span>}
                                                        </div>
                                                        <div className="d-flex flex-wrap mb-1">
                                                            <p className="mr-1 mb-0">{item.service_name ? item.service_name : ""}</p>
                                                            {item.alerts ?
                                                                <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">Alerts {item.alerts}</span>
                                                            : null}
                                                            {item.anomalies ?
                                                                <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">Anomalies {item.anomalies}</span>
                                                            : null}
                                                            {item.errors ?
                                                                <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">Errors {item.errors}</span>
                                                            : null}
                                                            {item.events ?
                                                                <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">Events {item.events}</span>
                                                            : null}
                                                        </div>
                                                        
                                                        <UncontrolledTooltip placement='top' target={"asset_name_"+item.asset_id}>
                                                            <div className="uncontrolledTooltipLeftAlign">
                                                                <p className="mb-1 text-primary-color wordBreakAll"><span className="text-white mr-1">Asset: </span>
                                                                    {item.id_name_map && item.id_name_map !== "" ?
                                                                        <span className={`ml-1`}>{(item.id_name_map.value ? item.id_name_map.value : "")}</span>
                                                                    :
                                                                        <span className={`ml-1`}>{item.asset_name}</span>
                                                                    }
                                                                </p>
                                                                {item.id_name_map && item.id_name_map !== "" ?
                                                                    item.id_name_map.key ? 
                                                                        <p className={`mb-1 wordBreakAll`}>{item.id_name_map.key}</p>
                                                                    : null
                                                                :
                                                                    item.resource_type ?
                                                                        item.resource_type === "ApiGateway" ?
                                                                            item.rest_api_name && item.rest_api_name !== "" ?
                                                                                <p className={`mb-1 wordBreakAll`}>{item.rest_api_name})</p>
                                                                            : null
                                                                        : item.resource_type === "EC2" ?
                                                                            item.tags && item.tags.length && item.tags.filter(e => e.key === "Name").length ?
                                                                                <p className={`mb-1 wordBreakAll`}>{item.tags.filter(e => e.key === "Name")[0].value}</p>
                                                                            : null
                                                                        : null
                                                                    : null
                                                                }
                                                                <p className="mb-1 text-primary-color">
                                                                    <span className="text-white mr-1">Resources: </span>
                                                                    {item.provider ? item.provider.toUpperCase() : <span>&nbsp;</span>}
                                                                    {item.account_id ? (' : ')+item.account_id : <span>&nbsp;</span>}
                                                                    {item.region ?  (' : ')+getRegionName(item.region, this.state.regions) : <span>&nbsp;</span>}
                                                                </p>
                                                                <p className="mb-1 text-primary-color">
                                                                    <span className="text-white mr-1">Services: </span>
                                                                    {item.resource_type ?  ('  ')+item.resource_type : <span>&nbsp;</span>}
                                                                    {item.service_name ?  (' : ')+item.service_name : <span>&nbsp;</span>}
                                                                </p>
                                                                <div className="d-flex flex-wrap">
                                                                    {item.alerts ?
                                                                        <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">Alerts {item.alerts}</span>
                                                                    : null}
                                                                    {item.anomalies ?
                                                                        <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">Anomalies {item.anomalies}</span>
                                                                    : null}
                                                                    {item.errors ?
                                                                        <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">Errors {item.errors}</span>
                                                                    : null}
                                                                    {item.events ?
                                                                        <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">Events {item.events}</span>
                                                                    : null}
                                                                </div>
                                                                
                                                            </div>    
                                                        </UncontrolledTooltip>
                                                    </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        : 
                                            <div className='d-flex justify-content-center m-4'>
                                                <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                            </div>
                                        }
                                        {this.state.showLoadMore ?
                                            <div className="text-center text-info cursorPointer my-2" onClick={() => this.nextSetOfRecord()}> Load More<i className="fas fa-angle-double-down ml-1"></i></div>
                                        : null}
                                    </div>
                                </div>                                        
                                <div className={`col-sm-7 pl-0 mt-2`}>
                                    <div className="rounded bg-dark p-2 overflowYAuto" style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
                                        <div className="d-flex justify-content-between mb-1">
                                            <div className="d-flex">
                                                <p className="f12 m-0 align-self-center">Showing metrics for the period of</p>
                                                <p className="f12 mb-0 align-self-center text-info ml-2">
                                                    {this.state.brushTime ? 
                                                        momentDateGivenFormat(this.state.brushStartTime, 'DD MMM HH:mm') +' - '+ momentDateGivenFormat(this.state.brushEndTime, 'DD MMM YYYY HH:mm') 
                                                    :
                                                        momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM HH:mm') +' - '+ momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM YYYY HH:mm') 
                                                    }
                                                </p>
                                                <span className="ml-2">(local time)</span>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between mb-1">
                                            {/* <span className="badge badge-light text-dark mb-2">{this.state.selectedAsset.asset_name ? this.state.selectedAsset.asset_name : ""}</span> */}
                                            <div className={`rounded mr-2 bg-gray3 text-dark px-2 py-1 f12 hiddenEllipses align-self-center`} id={`metricsTitle`}>
                                                {this.state.selectedAsset && this.state.selectedAsset.asset_name ? this.state.selectedAsset.asset_name : ""}
                                            </div>
                                            <div className="d-flex w-35 justify-content-end">
                                                {/* <p className="font-weight-bold mb-0 mr-2 align-self-center">Asset</p> */}
                                                <div className={`form-group bd-highlight mb-0 styled-feild pt-0 pb-0 align-self-center`}>
                                                    <select className="form-control" 
                                                        value={this.state.selectedMetricsType}
                                                        onChange={event => {
                                                            this.setState({
                                                                selectedMetricsType: event.target.value,
                                                                selectedMetrics: "",
                                                                pageType: this.state.pageType === "Observation" ?  "Alerts" : this.state.pageType
                                                            })
                                                        }}>
                                                        <option value={"Core Metrics"}>Core Metrics</option>
                                                        <option value={"SRE Metrics"}>SRE Metrics</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="w-25 select-sm-bg curvedDropdown minWidth150 displayNone">
                                                <div className="dropdown">
                                                    <span className="dropdown-toggle d-flex justify-content-between px-2 text-white" onClick={() => this.setState({ showMetricsTypeOptions: !this.state.showMetricsTypeOptions })}>
                                                        {this.state.selectedMetricsType ? this.state.selectedMetricsType : <span className="placeholder">Select</span>}
                                                        <span className="caret"></span>
                                                        {/* <i className="fas fa-angle-down"></i> */}
                                                    </span>
                                                    <ul className={`dropdown-menu p-0 ${this.state.showMetricsTypeOptions ? "show" : ""}`}>
                                                        <li 
                                                            onClick={() => this.setState({ selectedMetricsType: "Core Metrics", showMetricsTypeOptions: false })} 
                                                            value={"Core Metrics"} 
                                                            className={`${this.state.selectedMetricsType === "Core Metrics" ? "selected" : ""}`}
                                                        >
                                                            Core Metrics
                                                        </li>
                                                        <li 
                                                            onClick={() => this.setState({ selectedMetricsType: "SRE Metrics", showMetricsTypeOptions: false })} 
                                                            value={"SRE Metrics"} 
                                                            className={`${this.state.selectedMetricsType === "SRE Metrics" ? "selected" : ""}`}
                                                        >
                                                            SRE Metrics
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.selectedMetricsType === "Core Metrics" ?
                                            <MetricsTab
                                                brushStartTime={this.state.brushStartTime}
                                                brushEndTime={this.state.brushEndTime}
                                                details= {this.state.selectedAsset}
                                                height={115}
                                                selectedMetrics={(selectedMetrics) => 
                                                    this.setState({ selectedMetrics: "" }, 
                                                        () => this.setState({ selectedMetrics })
                                                    )
                                                }
                                            />
                                        :
                                            <SreMetricsTab
                                                brushStartTime={this.state.brushStartTime}
                                                brushEndTime={this.state.brushEndTime}
                                                details= {this.state.selectedAsset}
                                                height={115}
                                                selectedMetrics={(selectedMetrics) => 
                                                    this.setState({ selectedMetrics: "" }, 
                                                        () => this.setState({ selectedMetrics })
                                                    )
                                                }
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
AssetDetailPage.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('case assets new',state)
	return {
        services: state.filters.services,
        tagKeys: state.filters.tagKeys,
        caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : {},
        caseAssetPageFilter: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.caseAssetPageFilter ? state.aiops.aiopsPropsDetails.caseAssetPageFilter : {},

        assetBrushTimings: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.assetBrushTimings ? state.aiops.aiopsPropsDetails.assetBrushTimings : {}
    }
}

export default connect(mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
    listAllServices,
    getCoveredServices,
    listCoveredAssets,
    setAiopsPropsDetails,
    getMonitoringMetricsByTrend,
    getAllTagsKeys,
    getAllTagsValues
})(withRouter(AssetDetailPage))