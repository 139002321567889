import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { setCreatePolicyDetails } from '../../../actions/governance/IamAction'
import _ from 'lodash'

class CategoryCard extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	updateUseCase = event => {
		let category_index = event.target.name.split('@')[0]
		let usecase_index = event.target.name.split('@')[1]
		let usecase = JSON.parse(event.target.name.split('@')[2])
		let storeArr = this.props.createPolicyDetails.statements_to_generate
		let obj = this.props.createPolicyDetails.statements_to_generate[this.props.policyIndex]
		let category_to_change = obj.categories[category_index]
		let tmp = obj.categories
		if (event.target.checked) {
			let new_usecase = {
				usecase: usecase.usecase,
				actions: usecase.api,
			}
			category_to_change.usecases.push(new_usecase)
		} else {
			category_to_change.usecases.splice(usecase_index)
		}
		tmp.splice(category_index, 1, category_to_change)
		this.props.setCreatePolicyDetails('statements_to_generate', storeArr)
	}

	onDeleteCategory = () => {
		let storeArr = this.props.createPolicyDetails.statements_to_generate
		let obj = this.props.createPolicyDetails.statements_to_generate[this.props.policyIndex]
		let tmp = obj.categories
		tmp.splice(this.props.index, 1)
		let newArr = storeArr
		this.props.deleteCategory(this.props.index)
		this.props.setCreatePolicyDetails('statements_to_generate', newArr)
	}

	isUsecaseAdded = (category_index, usecase) => {
		let filtered = 0
		let selectedPrevious =
			this.props.selectedStatements[this.props.policyIndex] &&
			this.props.selectedStatements[this.props.policyIndex].categories
		if (_.filter(selectedPrevious, category => category.category === this.props.categoryDetail[0]).length) {
			const some = _.filter(selectedPrevious, category => {
				return category.category === this.props.categoryDetail[0]
			})[0].usecases
			filtered = some.filter(useCase => {
				console.log(useCase.usecase + '====' + usecase)
				return useCase.usecase === usecase
			}).length
		}
		if (filtered === 0) {
			let usecases =
				this.props.createPolicyDetails.statements_to_generate[this.props.policyIndex] &&
				this.props.createPolicyDetails.statements_to_generate[this.props.policyIndex].categories[category_index] &&
				this.props.createPolicyDetails.statements_to_generate[this.props.policyIndex].categories[category_index]
					.usecases

			let index = _.findIndex(usecases, { usecase: usecase })
			if (index >= 0) {
				return true
			} else {
				return false
			}
		}
		return filtered
	}

	render() {
		return (
			<div className='col-sm-4 pr-0 mb-2'>
				<div className='box overflow-hidden bg-muted'>
					<div className='badge badge-secondary justify-content-between p-2 rounded-0 d-flex'>
						{this.props.categoryDetail[0]}{' '}
						<span className='fal fa-times cursor-pointer' onClick={() => this.onDeleteCategory()} />
					</div>
					<div className='p-2'>
						{this.props.categoryDetail[1].usecases.map((item, i) => {
							return (
								<div className='form-check form-check-inline w-100' key={item}>
									<input
										className='form-check-input'
										type='checkbox'
										name={this.props.index + '@' + i + '@' + JSON.stringify(item)}
										onChange={e => this.updateUseCase(e)}
										checked={this.isUsecaseAdded(this.props.index, item.usecase)}
									/>
									<label className='form-check-label'>{item.usecase}</label>
								</div>
							)
						})}
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
CategoryCard.propTypes = {
	index: PropTypes.number,
	categoryDetail: PropTypes.object,
	setCreatePolicyDetails: PropTypes.func,
	deleteCategory: PropTypes.func,
	createPolicyDetails: PropTypes.object,
	policyIndex: PropTypes.number,
	selectedStatements: PropTypes.array,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		createPolicyDetails: state.iam.createPolicyDetails,
		selectedStatements: state.iam.selectedStatements,
	}
}

export default connect(mapStateToProps, { setCreatePolicyDetails })(CategoryCard)
