/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file RcaRightSection.js
 * @author Prakash // on 07/10/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

import { momentConvertionUtcToLocalTime, getRegionName, getAccountNameFromId, capitalizeFirstLetter, addMinutes, subMinutes, twoDateDiffrenceDayHoursMinutes, momentConvertionLocalToUtcTime } from '../../../utils/utility'
import { listAllAccounts, listAllRegions } from '../../../actions/commonAction'
import { getChangeImpactAnalysisResults } from '../../../actions/aiops/AiopsAction'

// import Search from '../../common/SearchComponent'

// import RcaMetricsSection from './RcaMetricsSection'
import SreMetricDetails from '../../diagnostics/SreMetricDetails'
import TrafficMetrics from '../../diagnostics/TrafficMetrics'
import MonitoringTab from '../../diagnostics/MonitoringTab'
import InterventionMetrics from '../../diagnostics/InterventionMetrics'
import SelectedMonitoringMetrics from '../../diagnostics/SelectedMonitoringMetrics'
import SelectedSreMetrics from '../../diagnostics/SelectedSreMetrics'

import Reachability from '../../diagnostics/Reachability'


import DbSlowQueryMySQL from '../../diagnostics/DbSlowQueryMySQL'
import DbSlowQueryPostgreSQL from '../../diagnostics/DbSlowQueryPostgreSQL'

import ApexGandalfBarChart from '../../common/charts/ApexGandalfBarChart'
import ApexBarProgressChart from '../../common/charts/ApexBarProgressChart'
import ApexGandalfInterventionChart from '../../common/charts/ApexGandalfInterventionChart'


import PrimarySecondaryMetricsSection from './PrimarySecondaryMetricsSection'
import ElascticSlowQueryPostgreSQL from './ElascticSlowQueryPostgreSQL'
import MicroservicesMetricSection from './MicroservicesMetricSection'

class RcaRightSection extends Component {
    constructor(props) {
        super(props)
        this.annotationTypeRef = React.createRef()
        // this.scrolltoTop = React.createRef()
        this.state = {
            minMaxHeight: "700px",
            accounts: [],
            regions: [],

            startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
			overAllTotalPages: 1,
			perPage: 10,
            series: [],
            options: {}
        }
    }

    componentDidMount = () => {
        this.getWindowSize()

        window.addEventListener('resize', () => {
            this.getWindowSize()
        })
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 15  
		let outerHeight = (window.outerHeight - browserBorder - headerHeight) +"px"

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }

    componentDidUpdate = (prevProps) => {
        if(!_.isEqual(prevProps.selectedMicroServicesRcaEventDetail, this.props.selectedMicroServicesRcaEventDetail) && this.props.selectedMicroServicesRcaEventDetail && Object.entries(this.props.selectedMicroServicesRcaEventDetail).length) {
            let mertcisAnnotationTypes = []
            this.setState({ 
                selectedRcaEventLabel: this.props.selectedMicroServicesRcaEventDetail.label,
                selectedRcaEventTag: this.props.selectedMicroServicesRcaEventDetail.eventGroup,
                selectedRcaEventFlag: this.props.selectedMicroServicesRcaEventDetail.flag,
                selectedRcaEvent: this.props.selectedMicroServicesRcaEventDetail.data, 
                assetDetails: this.props.selectedMicroServicesRcaEventDetail.asset_details,
                troubleshootDetails: this.props.selectedMicroServicesRcaEventDetail.microServicesRcaDetails,

                mertcisAnnotationTypes,
                showMeticsChart: false,
                changeDetails: {},
                showSecondaryMetrics: false,
                showSelectedMonitoringMetrics: false,
                showSelecteSreMetrics: false,
                showMonitoringChart: false,
                showInterventionMetrics: false,
                assetsList: [],
                filteredAssetArray: [],
                gandalfBarData: {},
            },
                () => {
                    if(this.state.selectedRcaEventTag === "Changes") {
                        this.getChangeImpactAnalysisResults()
                    } else {
                        if(this.state.assetDetails && Object.entries(this.state.assetDetails).length) {
                            this.setState({ showMeticsChart: true })
                        }
                    }
                    let lastTwoString = this.state.selectedRcaEventLabel.split(" ").splice(-2).join(" ")
                    if(lastTwoString === 'Service Limits') {
                        this.setState({ showMonitoringChart: true })
                    } else if(this.state.selectedRcaEventTag === "Alerts" || this.state.selectedRcaEventTag === "Latency" || this.state.selectedRcaEventTag === "ErrorRate") {
                        if(this.state.troubleshootDetails && this.state.troubleshootDetails.asset_id) {
                            if(this.state.selectedRcaEventTag === "Alerts") {
                                this.setState({ showSelectedMonitoringMetrics: true })
                            } else if(this.state.selectedRcaEventTag === "Latency" || this.state.selectedRcaEventTag === "ErrorRate") {
                                this.setState({ showSelecteSreMetrics: true })
                            }
                        }
                    } else if(this.state.selectedRcaEventLabel === "assets_list") {
                        let assetsList = this.state.selectedMicroServicesRcaEventDetail && this.state.selectedMicroServicesRcaEventDetail.assets ? this.state.selectedMicroServicesRcaEventDetail.assets : []
                        let totalPages = 1
                        if(assetsList.length > this.state.perPage) {
                            totalPages = Math.ceil(assetsList.length / this.state.perPage)
                        }
                        this.setState({ assetsList, filteredAssetArray: assetsList, totalPages })
                    } else if(this.state.selectedRcaEventLabel === 'Application Changes' || this.state.selectedRcaEventLabel === 'Capacity Changes') {
                        this.setState({ showInterventionMetrics: true })
                    } else if(this.state.selectedRcaEventLabel === "Security Changes" || this.props.selectedMicroServicesRcaEventDetail.label === 'Performance Changes' || this.props.selectedMicroServicesRcaEventDetail.label === 'Network Changes' || this.props.selectedMicroServicesRcaEventDetail.label === 'Configuration Changes' || this.props.selectedMicroServicesRcaEventDetail.label === 'Tag Changes' || this.props.selectedMicroServicesRcaEventDetail.label === 'Policy Changes' || this.props.selectedMicroServicesRcaEventDetail.label === 'Disk Changes') {
                        this.formatChangesEvent()
                    }
                }
            )
        } else if(prevProps.selectedMicroServicesRcaEventDetail !== this.props.selectedMicroServicesRcaEventDetail && this.props.selectedMicroServicesRcaEventDetail && !Object.entries(this.props.selectedMicroServicesRcaEventDetail).length) {
            this.setState({ 
                selectedRcaEventLabel: '', 
                selectedRcaEvent: {}, 
                assetDetails: {}
            })
        }
    }
    
    getChangeImpactAnalysisResults = () => {
        if(this.state.selectedRcaEvent.event_id) {
            let params = {}
            params.event_id = this.state.selectedRcaEvent.event_id
            params.impacted_events = false

            this.props.getChangeImpactAnalysisResults(params, (promise, response) => {
                if(promise) {
                    this.setState({ impactAnalysis: response },
                        () => {
                            if(Object.entries(this.state.impactAnalysis).length) {
                                if(this.state.selectedRcaEventTag === "Changes") {
                                    this.structureImpactAnalysis()
                                } else {
                                    this.structureForPrimaryMetrics()
                                } 
                            }                  
                        }
                    )
                } else {
                    this.setState({ impactAnalysis: [] })
                }
            })
        }
    }

    structureImpactAnalysis = () => {
        let impactAnalysis = this.state.impactAnalysis

        let eventTime = impactAnalysis.change_event_time ? momentConvertionUtcToLocalTime(impactAnalysis.change_event_time, "YYYY-MM-DD HH:mm:ss") : ""        
        let startTime = impactAnalysis.counter_start_time ? momentConvertionUtcToLocalTime(impactAnalysis.counter_start_time, "YYYY-MM-DD HH:mm:ss") : ""
        let addMinuteToStartTime = addMinutes(startTime, 1)
        let endTime = impactAnalysis.counter_end_time ? momentConvertionUtcToLocalTime(impactAnalysis.counter_end_time, "YYYY-MM-DD HH:mm:ss") : ""    
        let subMinuteToEndTime = subMinutes(endTime, 1)
        
        let labels = [startTime, eventTime, endTime]
        let data = [0, 10, 0]

        let totalIssues = 0
        let totalErrors = 0
        let impactsObserved = []
        if(impactAnalysis.before_events && impactAnalysis.before_events.length) {
            impactAnalysis.before_events.forEach(item => {
                let date = momentConvertionUtcToLocalTime(item.event_time, "YYYY-MM-DD HH:mm:ss")
                labels.push(date)
                data.push(0)
                
                item.date = date
                item.value = Math.floor(Math.random() * (10 - 1) + 1)
                impactsObserved.push(item)

                if(item.event_type === "errors") {
                    totalErrors += 1
                } else if(item.event_type === "issues") {
                    totalIssues += 1
                }
            })
        }
        if(impactAnalysis.after_events && impactAnalysis.after_events.length) {
            impactAnalysis.after_events.forEach(item => {
                let date = momentConvertionUtcToLocalTime(item.event_time, "YYYY-MM-DD HH:mm:ss")
                labels.push(date)
                data.push(0)

                item.date = date
                item.value = Math.floor(Math.random() * (10 - 1) + 1)
                impactsObserved.push(item)

                if(item.event_type === "errors") {
                    totalErrors += 1
                } else if(item.event_type === "issues") {
                    totalIssues += 1
                }
            })
        }
        
        let eventTypeCount = {"alerts": 0, "anomalies": 0, "errors": 0, "issues": 0}
        if(impactsObserved.length) {
            let groupByEventType = _.groupBy(impactsObserved, 'event_type');
            Object.entries(groupByEventType).forEach(([key, value]) => {
                eventTypeCount[key] = value.length
            })
        } else {
            labels.push(addMinuteToStartTime)
            labels.push(subMinuteToEndTime)
            data.push(0)
            data.push(0)
        }

        let gandalfBarData = {}
        gandalfBarData.eventTime = eventTime
        gandalfBarData.labels = labels
        gandalfBarData.data = data
        gandalfBarData.impactsObserved = impactsObserved
        gandalfBarData.event_type_count = eventTypeCount

        let eventTypes = ["alerts", "anomalies", 'errors',  "issues"]
        
        let beforeCount = []
        eventTypes.forEach(item => { 
            let dataRow = {}
            if(this.state.impactAnalysis && this.state.impactAnalysis.after_count && this.state.impactAnalysis.before_count.hasOwnProperty(item)) {
                dataRow.type = item
                Object.entries(this.state.impactAnalysis.before_count[item]).forEach(([key, value]) => {
                    if(key === "severity") {
                        Object.entries(value).forEach(([sKey, sValue]) => {
                            dataRow[sKey] = sValue
                        })
                    } else {
                        dataRow[key] = value
                    }
                })
            } else {
                dataRow.type = item
                dataRow.count = 0
                dataRow.Critical = 0
                dataRow.High = 0
            }
            
            beforeCount.push(dataRow)
        })

        let afterCount = []
        eventTypes.forEach(item => { 
            let dataRow = {}
            if(this.state.impactAnalysis && this.state.impactAnalysis.after_count && this.state.impactAnalysis.after_count.hasOwnProperty(item)) {
                dataRow.type = item
                Object.entries(this.state.impactAnalysis.after_count[item]).forEach(([key, value]) => {
                    if(key === "severity") {
                        Object.entries(value).forEach(([sKey, sValue]) => {
                            dataRow[sKey] = sValue
                        })
                    } else {
                        dataRow[key] = value
                    }
                })
            } else {
                dataRow.type = item
                dataRow.count = 0
                dataRow.Critical = 0
                dataRow.High = 0
            }
            
            afterCount.push(dataRow)
        })

        let beforeIssueCount = beforeCount.filter(e => e.type === "issues")[0].count
        let afterIssueCount = afterCount.filter(e => e.type === "issues")[0].count

        let beforeIssuesProgressData = {
            data: [Math.ceil((beforeIssueCount/totalIssues) * 100)],
            name: [beforeIssueCount],
            labels: ["Issues"]
        }

        let afterIssuesProgressData = {
            data: [Math.ceil((afterIssueCount/totalIssues) * 100)],
            name: [afterIssueCount],
            labels: ["Issues"]
        }


        let beforeErrorsCount = beforeCount.filter(e => e.type === "errors")[0].count
        let afterErrorsCount = afterCount.filter(e => e.type === "errors")[0].count

        let beforeErrorsProgressData = {
            data: [Math.ceil((beforeErrorsCount/totalErrors) * 100)],
            name: [beforeErrorsCount],
            labels: ["Errors"]
        }

        let afterErrorsProgressData = {
            data: [Math.ceil((afterErrorsCount/totalErrors) * 100)],
            name: [afterErrorsCount],
            labels: ["Errors"]
        }

        let progressData = {
            data: [10],
            name: "",
            labels: ["Progress1"]
        }
        
        this.setState({ gandalfBarData, beforeCount, afterCount, progressData, impactsObserved, beforeIssuesProgressData, afterIssuesProgressData, beforeErrorsProgressData, afterErrorsProgressData })
    }

    structureForPrimaryMetrics = () => {
        let impactAnalysis = this.state.impactAnalysis
        //? momentConvertionUtcToLocalTime(impactAnalysis.change_event_time, "YYYY-MM-DD HH:mm:ss")
        
        let events = []
        if(impactAnalysis.before_events && impactAnalysis.before_events.length) {
            events = [...impactAnalysis.before_events, ...events]
        }
        
        if(impactAnalysis.after_events && impactAnalysis.after_events.length) {
            events = [...impactAnalysis.after_events, ...events]
        }

        let changeDetails = {}
        changeDetails.event_time = impactAnalysis.change_event_time ? impactAnalysis.change_event_time  : ""
        changeDetails.events = events

        this.setState({ changeDetails },
            () => {
                if(this.state.assetDetails && Object.entries(this.state.assetDetails).length) {
                    this.setState({ showMeticsChart: true })
                }
            }
        )

    }

    formatChangesEvent = () => {
        let selectedRcaEvent = this.state.selectedRcaEvent
        if(this.state.selectedRcaEvent && this.state.selectedRcaEvent.changes) {
            let data = []
            this.state.selectedRcaEvent.changes.map(cng => {
                if(cng.current_value) {
                    let currentValue = cng.current_value
                    try {
                        let replaceString = currentValue.replaceAll("'", "''''")
                        replaceString = replaceString.replaceAll("''''", '"')
                        currentValue = JSON.parse(replaceString)
                    } catch (error) {
                        currentValue = {}
                    }
                    if(Object.entries(currentValue).length) {                        
                        let arrayValue = []
                        Object.entries(currentValue).map(([key,item]) => {                            
                            let arrayOfStrings = false
                            if(Array.isArray(item)) {
                                arrayOfStrings = item.every(i => (typeof i !== "object"))
                            }
                            if(arrayOfStrings) {
                                let dataRow = {
                                    [key]: item
                                }
                                arrayValue.push(dataRow)
                            } else if(typeof item === 'string' || typeof item === 'number' || typeof item === 'boolean') {
                                let dataRow = {
                                    [key]: item
                                }
                                arrayValue.push(dataRow)
                            } else {
                                if(Array.isArray(item)) {
                                    item.forEach(obj => {
                                        let array = []
                                        let depthofObject = this.depthofObject(obj)
                                        array = this.validateValue(obj).flat(depthofObject)
                                        arrayValue.push(array)
                                    })                                    
                                } else {
                                    let array = []
                                    let depthofObject = this.depthofObject(item)
                                    array = this.validateValue(item).flat(depthofObject)
                                    arrayValue.push(array)
                                }
                            }
                        })
                        currentValue = arrayValue
                    } else {
                        currentValue = cng.current_value
                    }

                    cng.current_value_converted = currentValue
                    cng.current_value_type = typeof currentValue
                }
                if(cng.previous_value) {
                    let previousValue = cng.previous_value
                    try {
                        let replaceString = previousValue.replaceAll("'", "''''")
                        replaceString = replaceString.replaceAll("''''", '"')
                        previousValue = JSON.parse(replaceString)
                    } catch (error) {
                        previousValue = {}
                    }

                    if(Object.entries(previousValue).length) {
                        let arrayValue = []
                        Object.entries(previousValue).map(([key,item]) => {                            
                            let arrayOfStrings = false
                            if(Array.isArray(item)) {
                                arrayOfStrings = item.every(i => (typeof i !== "object"))
                            }
                            if(arrayOfStrings) {
                                let dataRow = {
                                    [key]: item
                                }
                                arrayValue.push(dataRow)
                            } else if(typeof item === 'string' || typeof item === 'number' || typeof item === 'boolean') {
                                let dataRow = {
                                    [key]: item
                                }
                                arrayValue.push(dataRow)
                            } else {
                                if(Array.isArray(item)) {
                                    item.forEach(obj => {
                                        let array = []
                                        let depthofObject = this.depthofObject(obj)
                                        array = this.validateValue(obj).flat(depthofObject)
                                        arrayValue.push(array)
                                    })                                    
                                } else {
                                    let array = []
                                    let depthofObject = this.depthofObject(item)
                                    array = this.validateValue(item).flat(depthofObject)
                                    arrayValue.push(array)
                                }
                            }
                            
                        })
                        previousValue = arrayValue
                    } else {
                        previousValue = cng.previous_value
                    }

                    cng.previous_value_converted = previousValue    
                    cng.previous_value_type = typeof previousValue 
                }

                data.push(cng)
            })
            selectedRcaEvent.changes = data
        }

        this.setState({ selectedRcaEvent })
    }

    depthofObject = (object) => {
        var level = 1;
        for(var key in object) {
            if (!object.hasOwnProperty(key)) continue;
    
            if(typeof object[key] == 'object'){
                var depth = this.depthofObject(object[key]) + 1;
                level = Math.max(depth, level);
            }
        }
        return level;
    }

    validateValue = (item, valKey) => {
        return Object.keys(item).map(key => {
            if(typeof item[key] === 'string' || typeof item[key] === 'number' || typeof item[key] === 'boolean') {
                let dataRow = {
                    [key]: item[key]
                }
                return dataRow
            } else if (item[key] !== null && typeof item[key] == 'object') {
                return this.validateValue(item[key], key)
            } else {
                return item[key] !== null
                    ? item[key].toString().toLowerCase()
                    : false
            }
        })
    }

	render() {
		return (
            <div className=''>
                <div className={`m-0 h-100 overflowYAuto`} style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
                    <div className="mt-3" id="scrollTop">
                        <div className="bg-dark p-3 rounded">
                            <div className="d-flex justify-content-between">
                                <div className="w-70">
                                    <label className="text-white mb-0">Root Cause Details</label>
                                </div>
                                {this.state.selectedRcaEvent && Object.entries(this.state.selectedRcaEvent).length ?
                                    <div className='w-30 d-flex justify-content-center'>
                                        <label className={`${this.state.selectedRcaEventFlag ? "text-purplishRed" : "text-success"} mb-0`}>{this.state.selectedRcaEventFlag ? "Possible root cause detected." : "No causal observations detected."}</label>                                   
                                    </div>
                                : null}
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className={`${this.state.selectedRcaEvent && Object.entries(this.state.selectedRcaEvent).length ? "w-70" : "w-100"}`}>
                                    {this.state.selectedRcaEventTag === 'Traffic' ?
                                        <div className="d-flex">
                                            {/* <img src={level_shift_down} className="img-fluid align-self-center mr-2" alt="level_shift_down" /> */}
                                            <p className="mb-0">Traffic Surge detected at {this.state.selectedRcaEvent.event_time ? momentConvertionUtcToLocalTime(this.state.selectedRcaEvent.event_time, "DD MMM YYYY HH:mm") : ""}. in the {(this.state.selectedRcaEvent.metric_name ? this.state.selectedRcaEvent.metric_name : "")} metric of the resource {(this.state.selectedRcaEvent.asset_name ? this.state.selectedRcaEvent.asset_name : "")}. It followed a Transient {this.state.selectedRcaEvent.pattern ? this.state.selectedRcaEvent.pattern : ""} pattern. The normal traffic ranges from values of {this.state.selectedRcaEvent.lower_value} to {this.state.selectedRcaEvent.upper_value} Count. A total of {this.state.selectedRcaEvent.anomaly_value && this.state.selectedRcaEvent.anomaly_value} anomalies have been detected in the duration of {this.state.selectedRcaEvent.start_time && this.state.selectedRcaEvent.end_time ? twoDateDiffrenceDayHoursMinutes(this.state.selectedRcaEvent.start_time, this.state.selectedRcaEvent.end_time) : ""}  </p>
                                        </div>
                                    :
                                        this.state.selectedRcaEvent && Object.entries(this.state.selectedRcaEvent).length && (this.state.selectedRcaEvent.description || this.state.selectedRcaEvent.resolution) ?
                                            <div className='d-flex flex-wrap'>
                                                <span>{this.state.selectedRcaEvent.description ? this.state.selectedRcaEvent.description : ""}  {this.state.selectedRcaEvent.resolution ? this.state.selectedRcaEvent.resolution : ""}</span>
                                            </div>
                                        : 
                                            <p className='mb-0'>{this.state.troubleshootDetails && this.state.troubleshootDetails.microservice_rca_description ? this.state.troubleshootDetails.microservice_rca_description : (this.state.troubleshootDetails && this.state.troubleshootDetails.rca_description ? this.state.troubleshootDetails.rca_description : (this.state.troubleshootDetails && this.state.troubleshootDetails.description ? this.state.troubleshootDetails.description : " -- "))}</p>
                                    }
                                </div>
                                {this.state.selectedRcaEvent && Object.entries(this.state.selectedRcaEvent).length ?
                                    <div className='w-30 d-flex justify-content-center mt-2' style={{ height: "50px" }}>
                                        <span className={`far fa-exclamation-triangle ${this.state.selectedRcaEventFlag ? "text-purplishRed" : "text-success"} f35`}></span>
                                    </div>
                                : null}
                            </div>
                            <div className="d-flex mb-1 border-top">
                                <div className="py-1 w-50">
                                    <p className="b-block mb-0">Name</p>
                                    <p className="mb-0 text-white">
                                        {this.state.troubleshootDetails && this.state.troubleshootDetails.rca_type ? this.state.troubleshootDetails.rca_type : (this.state.troubleshootDetails && this.state.troubleshootDetails.microservice_name ? this.state.troubleshootDetails.microservice_name : " -- ")}
                                    </p>
                                </div>
                                <div className="pl-3 py-1 w-50">
                                    <p className="b-block mb-0">ID</p>
                                    <p className="mb-0 text-white">
                                        {this.state.troubleshootDetails && this.state.troubleshootDetails.rca_id ? this.state.troubleshootDetails.rca_id : (this.state.troubleshootDetails && this.state.troubleshootDetails.microservice_id ? this.state.troubleshootDetails.microservice_id : " -- ")}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex mb-1 border-top">
                                <div className="py-1 w-50">
                                    <p className="b-block mb-0">Start Time</p>
                                    {this.state.troubleshootDetails && this.state.troubleshootDetails.start_time ? 
                                        <p className="mb-0 text-white">
                                            {momentConvertionUtcToLocalTime(this.state.troubleshootDetails.start_time, "DD MMM YYYY HH:mm:ss")}
                                            <span className="text-info ml-1">( local time )</span>
                                        </p>
                                    : null}
                                </div>           
                                <div className="py-1 w-50 pl-3">
                                    <p className="b-block mb-0">End Time</p>
                                    {this.state.troubleshootDetails && this.state.troubleshootDetails.end_time ? 
                                        <p className="mb-0 text-white">
                                            {momentConvertionUtcToLocalTime(this.state.troubleshootDetails.end_time, "DD MMM YYYY HH:mm:ss")}
                                            <span className="text-info ml-1">( local time )</span>
                                        </p>
                                    : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {this.state.troubleshootDetails && Object.entries(this.state.troubleshootDetails).length && this.state.troubleshootDetails.microservice_id ?
                        <div className="my-3">
                            <MicroservicesMetricSection
                                troubleshootDetails = {this.state.troubleshootDetails}
                            />
                        </div>
                    : null}
                    
                    {this.state.troubleshootDetails && this.state.troubleshootDetails.asset_name ?
                        <div className={`card bg-dark border-0 p-3 mt-3`}>
                            <div className={`${this.state.showAssetDetails ? "mb-2" : ""} d-flex justify-content-between`} onClick={() => this.setState({ showAssetDetails: !this.state.showAssetDetails })}>
                                <div className="text-white"><span className={`far ${this.state.showAssetDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Asset Details</div>
                                {/* <div className="text-info">Showing event details</div> */}
                            </div>
                            <div className={`collapse ${this.state.showAssetDetails ? 'show' : ''}`}>
                                <div className="d-flex mb-1 border-bottom">
                                    <div className="py-1 w-50">
                                        <p className="b-block mb-0">Provider / Account / Region</p>
                                        <p className="mb-0 text-white">
                                            {this.state.troubleshootDetails.provider ? this.state.troubleshootDetails.provider.toUpperCase() : <span>&nbsp;</span>}
                                            {this.state.troubleshootDetails.account_id ? (' : ')+getAccountNameFromId(this.state.troubleshootDetails.account_id, this.props.accounts) : (this.state.selectedRcaEvent && this.state.selectedRcaEvent.account_id ? this.state.selectedRcaEvent.account_id : <span>&nbsp;</span>)}
                                            {this.state.troubleshootDetails.region ?  (' : ')+getRegionName(this.state.troubleshootDetails.region, this.state.regions) : <span>&nbsp;</span>}
                                        </p>
                                    </div>                                    
                                    <div className="py-1 w-50 pl-3">
                                        <p className="b-block mb-0">Service</p>
                                        <p className="mb-0 text-white">
                                            {this.state.troubleshootDetails.resource_type ? this.state.troubleshootDetails.resource_type : <span>&nbsp;</span>}
                                            {this.state.troubleshootDetails.service_name ?  (' : ')+this.state.troubleshootDetails.service_name : <span>&nbsp;</span>}
                                        </p>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="py-1 w-50">
                                        <p className="b-block mb-0">Asset</p>
                                        {this.state.troubleshootDetails.id_name_map && this.state.troubleshootDetails.id_name_map !== "" ?
                                            <p className="mb-0 text-white">
                                                <span className={`ml-1`}>{(this.state.troubleshootDetails.id_name_map.value ? this.state.troubleshootDetails.id_name_map.value : "")}</span>
                                                {this.state.troubleshootDetails.id_name_map.key && this.state.troubleshootDetails.id_name_map.key !== "" ?
                                                    <span className={`ml-1'} mb-0`}>
                                                        {" : "+this.state.troubleshootDetails.id_name_map.key}
                                                    </span>
                                                : null}
                                            </p>
                                        : 
                                            <p className="mb-0 text-white">{this.state.troubleshootDetails.asset_name ? this.state.troubleshootDetails.asset_name : <span>&nbsp;</span>}</p>
                                        }
                                    </div>
                                    <div className="py-1 w-50 pl-3">
                                        <p className="b-block mb-0">Asset Time</p>
                                        <p className="mb-0 text-white">{this.state.troubleshootDetails.event_time ? momentConvertionUtcToLocalTime(this.state.troubleshootDetails.event_time, 'DD MMM YYYY HH:mm:ss') : <span>&nbsp;</span>}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    : null}

                    {this.state.selectedRcaEvent && Object.entries(this.state.selectedRcaEvent).length ? 
                        this.state.selectedRcaEventTag === 'Traffic' ?
                            <div className="mt-3">
                                <div className="mt-2">
                                    <TrafficMetrics
                                        page={"rca"}
                                        startTime={this.state.troubleshootDetails && this.state.troubleshootDetails.start_time ? this.state.troubleshootDetails.start_time : ""}
                                        endTime={this.state.troubleshootDetails && this.state.troubleshootDetails.end_time ? this.state.troubleshootDetails.end_time : ""}
                                        metricsType={this.state.selectedRcaEventLabel}
                                        selectedEvent = {this.state.selectedRcaEvent}
                                        assetDetails={this.state.troubleshootDetails}
                                        microServiceRca = {this.props.microServiceRca}
                                        brush={false}
                                        sparkline={false}
                                        yaxis={true}
                                        yaxisLabel={true}
                                        xaxis={true}
                                        xaxisFormat={''}
                                        xaxisLabel={true}
                                        grid={false}
                                        axisLabelColor={'#B8BBBE'}
                                        legend={false}
                                        stacked={false}
                                        height={115}
                                        horizontal={true}
                                        barHeight={'40%'}
                                        barEndShape={'rounded'}
                                        columnWidth={'50%'}
                                        gradient={true}
                                        gradientColor={['#039BE5', '#5F5BA2']}
                                        hideTooltipValue={true}
                                        backgroundBarShape={'rounded'}
                                        backgroundBarColors={['#333947']}
                                        showBackgroundBarColors={false}
                                        className={"transparentTooltip mt-n4 mb-n4"}
                                        annotations={true}
                                        annotaionOptions={this.state.mertcisAnnotationTypes}
                                    />
                                </div>
                                {/* <div className="mt-2">
                                    <div className="rounded bg-dark p-3">
                                        <h7 className="text-white">Detail</h7>
                                        <p className="mb-0">Traffic Surge detected at {this.state.selectedRcaEvent.event_time ? momentConvertionUtcToLocalTime(this.state.selectedRcaEvent.event_time, "DD MMM YYYY HH:mm") : ""}. It followed a {this.state.selectedRcaEvent.pattern ? this.state.selectedRcaEvent.pattern : ""} pattern. The normal traffic range is {this.state.selectedRcaEvent.lower_value ? this.state.selectedRcaEvent.lower_value : ""} to {this.state.selectedRcaEvent.upper_value ? this.state.selectedRcaEvent.upper_value : ""} {this.state.selectedRcaEvent.unit ? this.state.selectedRcaEvent.unit : ""} and a total of {this.state.selectedRcaEvent.total_anomalies && this.state.selectedRcaEvent.total_anomalies} anomalies had been detected in the duration of {this.state.selectedRcaEvent.start_time && this.state.selectedRcaEvent.end_time ? twoDateDiffrenceDayHoursMinutes(this.state.selectedRcaEvent.start_time, this.state.selectedRcaEvent.end_time) : ""}  </p>
                                    </div>
                                </div> */}
                            </div>
                        : this.state.selectedRcaEventLabel === 'SRE Observations' ?
                            <div className=" mt-3">
                                <SreMetricDetails
                                    page={"rca"}
                                    startTime={this.state.troubleshootDetails && this.state.troubleshootDetails.start_time ? this.state.troubleshootDetails.start_time : ""}
                                    endTime={this.state.troubleshootDetails && this.state.troubleshootDetails.end_time ? this.state.troubleshootDetails.end_time : ""}
                                    assetDetails={this.state.troubleshootDetails}
                                    microServiceRca = {this.props.microServiceRca}
                                    brush={true}
                                    sparkline={false}
                                    yaxis={true}
                                    yaxisLabel={true}
                                    xaxis={true}
                                    xaxisFormat={''}
                                    xaxisLabel={true}
                                    grid={false}
                                    axisLabelColor={'#B8BBBE'}
                                    legend={false}
                                    stacked={false}
                                    height={200}
                                    horizontal={true}
                                    barHeight={'40%'}
                                    barEndShape={'rounded'}
                                    columnWidth={'50%'}
                                    gradient={true}
                                    gradientColor={['#24A597', '#775BA2']}
                                    hideTooltipValue={true}
                                    backgroundBarShape={'rounded'}
                                    backgroundBarColors={['#333947']}
                                    showBackgroundBarColors={false}
                                    className={"transparentTooltip"}
                                    annotations={true}
                                />
                            </div>
                        : this.state.selectedRcaEventTag === 'Changes' ?
                            <React.Fragment>
                            <div className="mt-3">
                                <div className="bg-dark rounded p-3">
                                    <p className="mb-0 f16 text-white">Before Changes</p>
                                    {this.state.selectedRcaEvent.changes ?
                                        this.state.selectedRcaEvent.changes.map((cng,index) => {
                                            return(
                                                <React.Fragment>
                                                {!index && cng.field && cng.field !== "" ?
                                                    <p className="mb-1 f12">{cng.field}</p>
                                                : null}
                                                {cng.previous_value && cng.previous_value !== "" ?
                                                    <div className="col-sm-12 py-1">  
                                                        <div className="row">
                                                            {cng.previous_value_type === 'object' ? 
                                                                Object.entries(cng.previous_value_converted).map(([key, value]) => {
                                                                    return(
                                                                        Array.isArray(value) ?
                                                                            value.every(i => (typeof i !== "object")) ?
                                                                                value.map(arr => {
                                                                                    return(
                                                                                    (typeof arr === "string" || typeof arr === "number" || typeof arr === "boolean") ?
                                                                                        <div className={`py-1 col-sm-6 mb-2 pl-0`}>
                                                                                            <p className="b-block mb-0">{key}:</p>
                                                                                            {typeof arr === "number" ?
                                                                                                <p className="mb-0 text-white">{arr}</p>
                                                                                            :  typeof arr === "string" ?
                                                                                                <p className="mb-0 text-white">{arr.toLowerCase() === 'y' ? 'Yes' : arr.toLowerCase() === 'n' ? 'No' : arr}</p>
                                                                                            :
                                                                                                <p className="mb-0 text-white">{arr ? "True" : "False"}</p>
                                                                                            }
                                                                                        </div>
                                                                                    : null
                                                                                    )
                                                                                })
                                                                            :
                                                                                <div className="col-sm-12 bg-dark3 rounded mb-2">
                                                                                <div className="row p-2">
                                                                                {value.map(arr => {
                                                                                    return(
                                                                                        typeof arr === 'object' ?
                                                                                        Object.entries(arr).map(([arrKey, arrValue]) => {
                                                                                            return(
                                                                                            (typeof arrValue === "string" || typeof arrValue === "number"|| typeof arrValue === "boolean") ?
                                                                                                <div className={`py-1 col-sm-6 mb-2 pl-0`}>
                                                                                                    <p className="b-block mb-0">{arrKey}:</p>
                                                                                                    {typeof arrValue === "number" ?
                                                                                                        <p className="mb-0 text-white">{arrValue}</p>
                                                                                                    : typeof arrValue === "string" ?
                                                                                                        <p className="mb-0 text-white">{arrValue.toLowerCase() === 'y' ? 'Yes' : arrValue.toLowerCase() === 'n' ? 'No' : arrValue}</p>
                                                                                                    :
                                                                                                        <p className="mb-0 text-white">{arrValue ? "True" : "False"}</p>
                                                                                                    }
                                                                                                </div>
                                                                                            :null
                                                                                            )
                                                                                        })
                                                                                    : null
                                                                                    )
                                                                                })}
                                                                                </div>
                                                                                </div>

                                                                        :
                                                                            (typeof value === "string" || typeof value === "number"|| typeof value === "boolean") ?
                                                                                <div className={`py-1 col-sm-6 mb-2 pl-0`}>
                                                                                    <p className="b-block mb-0">{key}:</p>
                                                                                    {typeof value === "number" ?
                                                                                        <p className="mb-0 text-white">{value}</p>
                                                                                    : typeof value === "string" ?
                                                                                        <p className="mb-0 text-white">{value.toLowerCase() === 'y' ? 'Yes' : value.toLowerCase() === 'n' ? 'No' : value}</p>
                                                                                    :
                                                                                        <p className="mb-0 text-white">{value ? "True" : "False"}</p>
                                                                                    }
                                                                                </div>
                                                                            :
                                                                                Object.entries(value).map(([newKey, newValue]) => {
                                                                                    return(
                                                                                    Array.isArray(newValue) ?
                                                                                        newValue.every(i => (typeof i !== "object")) ? 
                                                                                        <div className={`py-1 col-sm-6 mb-2 pl-0`}>
                                                                                            <p className="b-block mb-0">{newKey}:</p>
                                                                                            {newValue.map(arr => {
                                                                                                return(
                                                                                                (typeof arr === "string" || typeof arr === "number" || typeof arr === "boolean") ?
                                                                                                    <small className="badge badge-secondary mr-2 mb-1">
                                                                                                    {typeof arr === "number" || typeof arr === "string" ?
                                                                                                        arr
                                                                                                    :
                                                                                                        arr ? "True" : "False"
                                                                                                    }
                                                                                                    </small>
                                                                                                : null
                                                                                                )
                                                                                            })}
                                                                                        </div>
                                                                                    : 
                                                                                        newValue.map(arr => {
                                                                                            return(
                                                                                            (typeof arr === "string" || typeof arr === "number" || typeof arr === "boolean") ?
                                                                                                <div className={`py-1 col-sm-6 mb-2 pl-0`}>
                                                                                                    <p className="b-block mb-0">{newKey}:</p>
                                                                                                    {typeof arr === "number" ?
                                                                                                        <p className="mb-0 text-white">{arr}</p>
                                                                                                    : typeof arr === "string" ?
                                                                                                        <p className="mb-0 text-white">{arr.toLowerCase() === 'y' ? 'Yes' : arr.toLowerCase() === 'n' ? 'No' : arr}</p>
                                                                                                    :
                                                                                                        <p className="mb-0 text-white">{arr ? "True" : "False"}</p>
                                                                                                    }
                                                                                                </div>
                                                                                            : null
                                                                                            )
                                                                                        })
                                                                                    : (typeof newValue === "string" || typeof newValue === "number" || typeof newValue === "boolean") ?
                                                                                        <div className={`py-1 col-sm-6 mb-2 pl-0`}>
                                                                                            <p className="b-block mb-0">{newKey}:</p>
                                                                                            {typeof newValue === "number" ?
                                                                                                <p className="mb-0 text-white">{newValue}</p>
                                                                                            : typeof newValue === "string" ?
                                                                                                <p className="mb-0 text-white">{newValue.toLowerCase() === 'y' ? 'Yes' : newValue.toLowerCase() === 'n' ? 'No' : newValue}</p>
                                                                                            :
                                                                                                <p className="mb-0 text-white">{newValue ? "True" : "False"}</p>
                                                                                            }
                                                                                        </div>
                                                                                    : null
                                                                                    )
                                                                                })
                                                                        
                                                                    )
                                                                })
                                                            :     
                                                                <div className="d-flex">
                                                                    <div className="py-1 w-50">
                                                                        {/* <p className="b-block mb-0">{cng.field ? cng.field : <span>&nbsp;</span>}</p> */}
                                                                        <p className="mb-0 text-white">{cng.previous_value}</p>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>                                                
                                                : 
                                                    <div className="col-sm-12 py-1 d-flex justify-content-center">
                                                        <span className={`fa fa-exclamation-circle text-warning mr-2 align-self-center f18`}></span>
                                                        <p className={`text-warning mr-2 mb-0 align-self-end f16`}>Previous value unknown.</p>
                                                    </div>
                                                }
                                                </React.Fragment>
                                            )
                                        })
                                    : null}
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="bg-dark rounded p-3">
                                    <p className="mb-0 f16 text-white">After Changes</p>
                                    {this.state.selectedRcaEvent.changes ?
                                        this.state.selectedRcaEvent.changes.map((cng,index) => {
                                            return(
                                                <React.Fragment>
                                                {!index && cng.field && cng.field !== "" ?
                                                    <p className="mb-1 f12">{cng.field}</p>
                                                : null}
                                                {/* {!index ?
                                                    <p className="mb-1 small">{(cng.description ? cng.description : '')}</p>
                                                : null} */}
                                                {cng.current_value && cng.current_value !== "" ?
                                                    <div className="col-sm-12 py-1">  
                                                        <div className="row">
                                                            {cng.current_value_type === 'object' ? 
                                                                Object.entries(cng.current_value_converted).map(([key, value]) => {
                                                                    return(
                                                                        Array.isArray(value) ?
                                                                            value.every(i => (typeof i !== "object")) ?
                                                                                value.map(arr => {
                                                                                    return(
                                                                                    (typeof arr === "string" || typeof arr === "number" || typeof arr === "boolean") ?
                                                                                        <div className={`py-1 col-sm-6 mb-2 pl-0`}>
                                                                                            <p className="b-block mb-0">{key}:</p>
                                                                                            {typeof arr === "number" ?
                                                                                                <p className="mb-0 text-white">{arr}</p>
                                                                                            :  typeof arr === "string" ?
                                                                                                <p className="mb-0 text-white">{arr.toLowerCase() === 'y' ? 'Yes' : arr.toLowerCase() === 'n' ? 'No' : arr}</p>
                                                                                            :
                                                                                                <p className="mb-0 text-white">{arr ? "True" : "False"}</p>
                                                                                            }
                                                                                        </div>
                                                                                    : null
                                                                                    )
                                                                                })
                                                                            :
                                                                                <div className="col-sm-12 bg-dark3 rounded mb-2">
                                                                                <div className="row p-2">
                                                                                {value.map(arr => {
                                                                                    return(
                                                                                        typeof arr === 'object' ?
                                                                                        Object.entries(arr).map(([arrKey, arrValue]) => {
                                                                                            return(
                                                                                            (typeof arrValue === "string" || typeof arrValue === "number"|| typeof arrValue === "boolean") ?
                                                                                                <div className={`py-1 col-sm-6 mb-2 pl-0`}>
                                                                                                    <p className="b-block mb-0">{arrKey}:</p>
                                                                                                    {typeof arrValue === "number" ?
                                                                                                        <p className="mb-0 text-white">{arrValue}</p>
                                                                                                    : typeof arrValue === "string" ?
                                                                                                        <p className="mb-0 text-white">{arrValue.toLowerCase() === 'y' ? 'Yes' : arrValue.toLowerCase() === 'n' ? 'No' : arrValue}</p>
                                                                                                    :
                                                                                                        <p className="mb-0 text-white">{arrValue ? "True" : "False"}</p>
                                                                                                    }
                                                                                                </div>
                                                                                            :null
                                                                                            )
                                                                                        })
                                                                                    : null
                                                                                    )
                                                                                })}
                                                                                </div>
                                                                                </div>

                                                                        :
                                                                            (typeof value === "string" || typeof value === "number"|| typeof value === "boolean") ?
                                                                                <div className={`py-1 col-sm-6 mb-2 pl-0`}>
                                                                                    <p className="b-block mb-0">{key}:</p>
                                                                                    {typeof value === "number" ?
                                                                                        <p className="mb-0 text-white">{value}</p>
                                                                                    : typeof value === "string" ?
                                                                                        <p className="mb-0 text-white">{value.toLowerCase() === 'y' ? 'Yes' : value.toLowerCase() === 'n' ? 'No' : value}</p>
                                                                                    :
                                                                                        <p className="mb-0 text-white">{value ? "True" : "False"}</p>
                                                                                    }
                                                                                </div>
                                                                            :
                                                                                Object.entries(value).map(([newKey, newValue]) => {
                                                                                    return(
                                                                                    Array.isArray(newValue) ?
                                                                                        newValue.every(i => (typeof i !== "object")) ? 
                                                                                        <div className={`py-1 col-sm-6 mb-2 pl-0`}>
                                                                                            <p className="b-block mb-0">{newKey}:</p>
                                                                                            {newValue.map(arr => {
                                                                                                return(
                                                                                                (typeof arr === "string" || typeof arr === "number" || typeof arr === "boolean") ?
                                                                                                    <small className="badge badge-secondary mr-2 mb-1">
                                                                                                    {typeof arr === "number" || typeof arr === "string" ?
                                                                                                        arr
                                                                                                    :
                                                                                                        arr ? "True" : "False"
                                                                                                    }
                                                                                                    </small>
                                                                                                : null
                                                                                                )
                                                                                            })}
                                                                                        </div>
                                                                                    : 
                                                                                        newValue.map(arr => {
                                                                                            return(
                                                                                            (typeof arr === "string" || typeof arr === "number" || typeof arr === "boolean") ?
                                                                                                <div className={`py-1 col-sm-6 mb-2 pl-0`}>
                                                                                                    <p className="b-block mb-0">{newKey}:</p>
                                                                                                    {typeof arr === "number" ?
                                                                                                        <p className="mb-0 text-white">{arr}</p>
                                                                                                    : typeof arr === "string" ?
                                                                                                        <p className="mb-0 text-white">{arr.toLowerCase() === 'y' ? 'Yes' : arr.toLowerCase() === 'n' ? 'No' : arr}</p>
                                                                                                    :
                                                                                                        <p className="mb-0 text-white">{arr ? "True" : "False"}</p>
                                                                                                    }
                                                                                                </div>
                                                                                            : null
                                                                                            )
                                                                                        })
                                                                                    : (typeof newValue === "string" || typeof newValue === "number" || typeof newValue === "boolean") ?
                                                                                        <div className={`py-1 col-sm-6 mb-2 pl-0`}>
                                                                                            <p className="b-block mb-0">{newKey}:</p>
                                                                                            {typeof newValue === "number" ?
                                                                                                <p className="mb-0 text-white">{newValue}</p>
                                                                                            : typeof newValue === "string" ?
                                                                                                <p className="mb-0 text-white">{newValue.toLowerCase() === 'y' ? 'Yes' : newValue.toLowerCase() === 'n' ? 'No' : newValue}</p>
                                                                                            :
                                                                                                <p className="mb-0 text-white">{newValue ? "True" : "False"}</p>
                                                                                            }
                                                                                        </div>
                                                                                    : null
                                                                                    )
                                                                                })
                                                                        
                                                                    )
                                                                })
                                                            :     
                                                                <div className="d-flex">
                                                                    <div className="py-1 w-50">
                                                                        {/* <p className="b-block mb-0">{cng.field ? cng.field : <span>&nbsp;</span>}</p> */}
                                                                        <p className="mb-0 text-white">{cng.current_value}</p>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>                                                
                                                : 
                                                     
                                                    <div className="col-sm-12 py-1 d-flex justify-content-center">
                                                        <span className={`fa fa-exclamation-circle text-warning mr-2 align-self-center f18`}></span>
                                                        <p className={`text-warning mr-2 mb-0 align-self-end f16`}>Current value unknown.</p>
                                                    </div>
                                                }
                                                    </React.Fragment>
                                            )
                                        })
                                    : null}
                                </div>
                            </div>
                            </React.Fragment>
                        : this.state.selectedRcaEventTag === 'Problem Connecting' ?
                            <Reachability
                                assetDetails= {this.state.troubleshootDetails}
                                microServiceRca = {this.props.microServiceRca}
                                selectedDiagnosticEvent = {this.state.selectedRcaEvent}
                            />
                        : this.state.selectedRcaEventTag === 'Slow Queries' || this.state.selectedRcaEventTag === 'Slow Query' ? 
                            this.state.selectedRcaEvent && this.state.selectedRcaEvent.event_group && this.state.selectedRcaEvent.event_group === "database_slowquery" ?
                                <DbSlowQueryMySQL
                                    page={"rca"}                                     
                                    assetDetails= {this.state.troubleshootDetails}
                                    selectedEvent={this.state.selectedRcaEvent}
                                    microServiceRca = {this.props.microServiceRca}
                                />
                            : this.state.selectedRcaEvent && this.state.selectedRcaEvent.event_group && this.state.selectedRcaEvent.event_group === "elasticache_slowquery" ?
                                <ElascticSlowQueryPostgreSQL
                                    page={"rca"}
                                    assetDetails= {this.state.troubleshootDetails}
                                    selectedEvent={this.state.selectedRcaEvent}
                                    microServiceRca = {this.props.microServiceRca}
                                />
                            : this.state.selectedRcaEvent && this.state.selectedRcaEvent.event_group && this.state.selectedRcaEvent.event_group === "elasticache_slowquery1" ?
                                <DbSlowQueryPostgreSQL
                                    page={"rca"}
                                    assetDetails= {this.state.troubleshootDetails}
                                    selectedEvent={this.state.selectedRcaEvent}
                                    microServiceRca = {this.props.microServiceRca}
                                />
                            : null
                        : null
                    : null}

                    {this.state.gandalfBarData && Object.entries(this.state.gandalfBarData).length ?
                        <div className="my-3 bg-dark p-3 rounded">                            
                            <ApexGandalfBarChart
                                page={"microServiceRca"}
                                graphData={this.state.gandalfBarData}
                                sparkline={false}
                                yaxis={false}
                                yaxisLabel={true}
                                xaxis={true}
                                xaxisFormat={'datetime'}
                                xaxisLabel={true}
                                axisLabelColor={'#50586a'}
                                axisLabelFontSize={'12px'}
                                paddingLeft={-25}
                                legend={false}
                                stacked={false}
                                height={180}
                                horizontal={false}
                                barHeight={'40%'}
                                className={"transparentTooltip bg-dark3 rounded"}
                                colors={['#3DAFE8', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7']}
                                showHeading={true}
                                impactAnalysis={this.state.impactAnalysis}
                                summary={this.state.troubleshootDetails && this.state.troubleshootDetails.summary ? this.state.troubleshootDetails.summary : ""}
                            />
                            <div className="d-flex bg-dark3 rounded p-2 mt-3">
                                <div className="w-50 border-right-info">
                                    <label className="f16 mb-2 text-info text-center d-flex justify-content-center">Before</label>
                                    {this.state.beforeCount && this.state.beforeCount.length ?
                                        this.state.beforeCount.map(item => {
                                            return(
                                            <div className="d-flex justify-content-between mb-1">
                                                <p className="mb-0 text-white">{capitalizeFirstLetter(item.type)} : <span className="text-primary-color">{item.count}</span></p>
                                                {item.type === "alerts" || item.type === "anomalies" ?
                                                    <ul className="stock-legend mb-0 d-flex justify-content-center f12">
                                                        <li calssName="d-flex"><span className="legend-circle risk-bg-critical mt-3p"></span> <span className="text-muted d-inline-block"> &nbsp;C: {item.Critical}</span></li>
                                                        <li calssName="d-flex"><span className="legend-circle risk-bg-high mt-3p"></span> <span className="text-muted d-inline-block"> &nbsp;H: {item.High}</span></li>
                                                    </ul>
                                                : item.type === "issues" && this.state.beforeIssuesProgressData ?
                                                    <div className="w-40 mr-2">
                                                        <ApexBarProgressChart
                                                            graphData={this.state.beforeIssuesProgressData}
                                                            sparkline={true}
                                                            horizontal={true}
                                                            yaxis={false}
                                                            yaxisLabel={false}
                                                            xaxis={true}
                                                            xaxisFormat={'string'}
                                                            xaxisLabel={true}
                                                            axisLabelColor={'#B8BBBE'}
                                                            // paddingLeft={-25}
                                                            legend={false}
                                                            stacked={false}
                                                            height={20}
                                                            barHeight={'20%'}
                                                            barEndShape={'rounded'}
                                                            columnWidth={'25%'}
                                                            gradient={true}                                                                
                                                            colors={["#FF4560"]}
                                                            backgroundBarColors={["#40475D"]}
                                                            titleColor={"#B8BBBE"}
                                                            className={"transparentTooltip"}
                                                        />
                                                    </div>
                                                : item.type === "errors" && this.state.beforeErrorsProgressData ?
                                                    <div className="w-40 mr-2">
                                                        <ApexBarProgressChart
                                                            graphData={this.state.beforeErrorsProgressData}
                                                            sparkline={true}
                                                            horizontal={true}
                                                            yaxis={false}
                                                            yaxisLabel={false}
                                                            xaxis={true}
                                                            xaxisFormat={'string'}
                                                            xaxisLabel={true}
                                                            axisLabelColor={'#B8BBBE'}
                                                            // paddingLeft={-25}
                                                            legend={false}
                                                            stacked={false}
                                                            height={20}
                                                            barHeight={'20%'}
                                                            barEndShape={'rounded'}
                                                            columnWidth={'25%'}
                                                            gradient={true}                                                                
                                                            colors={["#24A597"]}
                                                            backgroundBarColors={["#40475D"]}
                                                            titleColor={"#B8BBBE"}
                                                            className={"transparentTooltip"}
                                                        />
                                                    </div>
                                                : this.state.progressData ?
                                                    <div className="w-40 mr-2">
                                                        <ApexBarProgressChart
                                                            graphData={this.state.progressData}
                                                            sparkline={true}
                                                            horizontal={true}
                                                            yaxis={false}
                                                            yaxisLabel={false}
                                                            xaxis={true}
                                                            xaxisFormat={'string'}
                                                            xaxisLabel={true}
                                                            axisLabelColor={'#B8BBBE'}
                                                            // paddingLeft={-25}
                                                            legend={false}
                                                            stacked={false}
                                                            height={20}
                                                            barHeight={'20%'}
                                                            barEndShape={'rounded'}
                                                            columnWidth={'25%'}
                                                            gradient={true}
                                                            backgroundBarColors={["#40475D"]}
                                                            titleColor={"#B8BBBE"}
                                                            className={"transparentTooltip"}
                                                        />
                                                    </div>
                                                : null}
                                            </div>
                                            )
                                        })
                                    : null}
                                </div>

                                <div className="w-50 pl-2">
                                    <label className="f16 mb-2 text-info text-center d-flex justify-content-center">After</label>
                                    {this.state.afterCount && this.state.afterCount.length ?
                                        this.state.afterCount.map(item => {
                                            return(
                                            <div className="d-flex justify-content-between mb-1">
                                                <p className="mb-0 text-white">{capitalizeFirstLetter(item.type)} : <span className="text-primary-color">{item.count}</span></p>
                                                {item.type === "alerts" || item.type === "anomalies" ?
                                                    <ul className="stock-legend mb-0 d-flex justify-content-center f12">
                                                        <li calssName="d-flex"><span className="legend-circle risk-bg-critical mt-3p"></span> <span className="text-muted d-inline-block"> &nbsp;C: {item.Critical}</span></li>
                                                        <li calssName="d-flex"><span className="legend-circle risk-bg-high mt-3p"></span> <span className="text-muted d-inline-block"> &nbsp;H: {item.High}</span></li>
                                                    </ul>
                                                : item.type === "issues" && this.state.afterIssuesProgressData ?
                                                    <div className="w-40 mr-2">
                                                        <ApexBarProgressChart
                                                            graphData={this.state.afterIssuesProgressData}
                                                            sparkline={true}
                                                            horizontal={true}
                                                            yaxis={false}
                                                            yaxisLabel={false}
                                                            xaxis={true}
                                                            xaxisFormat={'string'}
                                                            xaxisLabel={true}
                                                            axisLabelColor={'#B8BBBE'}
                                                            // paddingLeft={-25}
                                                            legend={false}
                                                            stacked={false}
                                                            height={20}
                                                            barHeight={'20%'}
                                                            barEndShape={'rounded'}
                                                            columnWidth={'25%'}
                                                            gradient={true}
                                                            colors={["#FF4560"]}
                                                            backgroundBarColors={["#40475D"]}
                                                            titleColor={"#B8BBBE"}
                                                            className={"transparentTooltip"}
                                                        />
                                                    </div>
                                                : item.type === "errors" && this.state.afterErrorsProgressData ?
                                                    <div className="w-40 mr-2">
                                                        <ApexBarProgressChart
                                                            graphData={this.state.afterErrorsProgressData}
                                                            sparkline={true}
                                                            horizontal={true}
                                                            yaxis={false}
                                                            yaxisLabel={false}
                                                            xaxis={true}
                                                            xaxisFormat={'string'}
                                                            xaxisLabel={true}
                                                            axisLabelColor={'#B8BBBE'}
                                                            // paddingLeft={-25}
                                                            legend={false}
                                                            stacked={false}
                                                            height={20}
                                                            barHeight={'20%'}
                                                            barEndShape={'rounded'}
                                                            columnWidth={'25%'}
                                                            gradient={true}                                                                
                                                            colors={["#24A597"]}
                                                            backgroundBarColors={["#40475D"]}
                                                            titleColor={"#B8BBBE"}
                                                            className={"transparentTooltip"}
                                                        />
                                                    </div>
                                                : this.state.progressData ?
                                                    <div className="w-40 mr-2">
                                                        <ApexBarProgressChart
                                                            graphData={this.state.progressData}
                                                            sparkline={true}
                                                            horizontal={true}
                                                            yaxis={false}
                                                            yaxisLabel={false}
                                                            xaxis={true}
                                                            xaxisFormat={'string'}
                                                            xaxisLabel={true}
                                                            axisLabelColor={'#B8BBBE'}
                                                            // paddingLeft={-25}
                                                            legend={false}
                                                            stacked={false}
                                                            height={20}
                                                            barHeight={'20%'}
                                                            barEndShape={'rounded'}
                                                            columnWidth={'25%'}
                                                            gradient={true}
                                                            backgroundBarColors={["#40475D"]}
                                                            titleColor={"#B8BBBE"}
                                                            className={"transparentTooltip"}
                                                        />
                                                    </div>
                                                : null}
                                            </div>
                                            )
                                        })
                                    : null}
                                </div>
                            </div>
                            {this.state.impactAnalysis && this.state.impactAnalysis.intervention && this.state.impactAnalysis.intervention.length ?
                                <React.Fragment>
                                <p className="m-0 text-white mt-3">Interventions</p>
                                <p className="small mb-1">Event changed at 
                                    <span className="ml-1 text-info">{this.state.impactAnalysis && this.state.impactAnalysis.change_event_time ? momentConvertionUtcToLocalTime(this.state.impactAnalysis.change_event_time, "YYYY-MM-DD HH:mm:ss") : ""} </span>
                                    <span className="ml-1">is a {this.state.impactAnalysis && this.state.impactAnalysis.change_events && this.state.impactAnalysis && this.state.impactAnalysis.change_events.length ? "bad" : "good"} change, with</span>
                                    <span className={`ml-1 risk-${this.state.impactAnalysis && this.state.impactAnalysis.severity ? this.state.impactAnalysis.severity.toLowerCase() : ""}`}>{this.state.impactAnalysis && this.state.impactAnalysis.severity}</span>
                                    <span className="ml-1">severity</span>
                                </p>
                                <ApexGandalfInterventionChart
                                    graphData={""}
                                    impactAnalysis={this.state.impactAnalysis}
                                    sparkline={false}
                                    yaxis={false}
                                    yaxisLabel={true}
                                    xaxis={true}
                                    xaxisFormat={'datetime'}
                                    xaxisLabel={true}
                                    axisLabelColor={'#50586a'}
                                    axisLabelFontSize={'12px'}
                                    paddingLeft={-25}
                                    legend={false}
                                    stacked={false}
                                    height={180}
                                    showMarkers={false}
                                    className={"transparentTooltip"}
                                    colors={['#775DD0', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7']}
                                    showHeading={true}
                                    startTime={this.state.troubleshootDetails && this.state.troubleshootDetails.start_time ? this.state.troubleshootDetails.start_time : ""}
                                    endTime={this.state.troubleshootDetails && this.state.troubleshootDetails.end_time ? this.state.troubleshootDetails.end_time : ""}
                                    summary={this.state.troubleshootDetails && this.state.troubleshootDetails.summary ? this.state.troubleshootDetails.summary : ""}
                                />
                                </React.Fragment>
                            : null}
                        </div>
                    : null}

                    {this.state.showSelectedMonitoringMetrics ?
                        <div className="my-3">
                            <SelectedMonitoringMetrics
                                page={"rca"}
                                assetDetails={this.state.troubleshootDetails}
                                microServiceRca = {this.props.microServiceRca}
                                brush={true}
                                sparkline={false}
                                yaxis={true}
                                yaxisLabel={true}
                                xaxis={true}
                                xaxisFormat={''}
                                xaxisLabel={true}
                                grid={false}
                                axisLabelColor={'#B8BBBE'}
                                legend={false}
                                stacked={false}
                                height={150}
                                horizontal={true}
                                barHeight={'40%'}
                                barEndShape={'rounded'}
                                columnWidth={'50%'}
                                gradient={true}
                                gradientColor={['#039BE5', '#5F5BA2']}
                                hideTooltipValue={true}
                                backgroundBarShape={'rounded'}
                                backgroundBarColors={['#333947']}
                                showBackgroundBarColors={false}
                                className={"transparentTooltip mt-n4 mb-n4"}
                                annotations={true}
                                annotaionOptions={this.state.mertcisAnnotationTypes}
                            />
                        </div>
                    : this.state.showSelecteSreMetrics ?
                        <div className="my-3">
                            <SelectedSreMetrics
                                page={"rca"}
                                assetDetails={this.state.troubleshootDetails}
                                microServiceRca = {this.props.microServiceRca}
                                brush={true}
                                sparkline={false}
                                yaxis={true}
                                yaxisLabel={true}
                                xaxis={true}
                                xaxisFormat={''}
                                xaxisLabel={true}
                                grid={false}
                                axisLabelColor={'#B8BBBE'}
                                legend={false}
                                stacked={false}
                                height={150}
                                horizontal={true}
                                barHeight={'40%'}
                                barEndShape={'rounded'}
                                columnWidth={'50%'}
                                gradient={true}
                                gradientColor={['#039BE5', '#5F5BA2']}
                                hideTooltipValue={true}
                                backgroundBarShape={'rounded'}
                                backgroundBarColors={['#333947']}
                                showBackgroundBarColors={false}
                                className={"transparentTooltip mt-n4 mb-n4"}
                                annotations={true}
                                annotaionOptions={this.state.mertcisAnnotationTypes}
                            />
                        </div>
                    : null}
                    
                    <div className="my-3">
                        {this.state.showMeticsChart ?
                            <PrimarySecondaryMetricsSection
                                page={"rca"}
                                startTime={this.state.troubleshootDetails && this.state.troubleshootDetails.start_time ? this.state.troubleshootDetails.start_time : ""}
                                endTime={this.state.troubleshootDetails && this.state.troubleshootDetails.end_time ? this.state.troubleshootDetails.end_time : ""}
                                metricsType={this.state.selectedRcaEventLabel}                                
                                selectedEvent = {this.state.selectedRcaEvent}
                                assetDetails={this.state.troubleshootDetails}
                                microServiceRca = {this.props.microServiceRca}
                                changeDetails={this.state.changeDetails}
                                brush={true}
                                sparkline={false}
                                yaxis={true}
                                yaxisLabel={true}
                                xaxis={true}
                                xaxisFormat={''}
                                xaxisLabel={true}
                                grid={false}
                                axisLabelColor={'#B8BBBE'}
                                legend={false}
                                stacked={false}
                                height={115}
                                horizontal={true}
                                barHeight={'40%'}
                                barEndShape={'rounded'}
                                columnWidth={'50%'}
                                gradient={true}
                                gradientColor={['#039BE5', '#5F5BA2']}
                                hideTooltipValue={true}
                                backgroundBarShape={'rounded'}
                                backgroundBarColors={['#333947']}
                                showBackgroundBarColors={false}
                                className={"transparentTooltip mt-n4 mb-n4"}
                                annotations={true}
                                annotaionOptions={this.state.mertcisAnnotationTypes}
                            />
                        : null}
                        {this.state.showMonitoringChart ?
                            <MonitoringTab 
                                page={"rca"}
                                selectedDiagnosticEvent={this.state.selectedRcaEvent}
                            />
                        : this.state.showInterventionMetrics ?
                            <InterventionMetrics
                                page={"rca"}
                                metricsType={this.state.selectedDiagnosticEventLabel}
                                selectedDiagnosticEvent={this.state.selectedRcaEvent}
                                assetDetails={this.state.troubleshootDetails}
                                microServiceRca = {this.props.microServiceRca}
                                brush={false}
                                sparkline={false}
                                yaxis={true}
                                yaxisLabel={true}
                                xaxis={true}
                                xaxisFormat={''}
                                xaxisLabel={true}
                                grid={false}
                                axisLabelColor={'#B8BBBE'}
                                legend={false}
                                stacked={false}
                                height={115}
                                horizontal={true}
                                barHeight={'40%'}
                                barEndShape={'rounded'}
                                columnWidth={'50%'}
                                gradient={true}
                                gradientColor={['#039BE5', '#5F5BA2']}
                                hideTooltipValue={true}
                                backgroundBarShape={'rounded'}
                                backgroundBarColors={['#333947']}
                                showBackgroundBarColors={false}
                                className={"transparentTooltip mt-n4 mb-n4"}
                                annotations={true}
                                annotaionOptions={this.state.mertcisAnnotationTypes}
                            />
                        : null}
                    </div>
                </div>
            </div>
		)
	}
}
/**
 * Type of the props used in the component
 */
RcaRightSection.propTypes = {
    listAllAccounts: PropTypes.func,
	listAllRegions: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
    // console.log("right rca page", state)
    return {
        accounts: state.filters.accounts,
        regions: state.filters.regions,
        selectedMicroServicesRcaEventDetail: state.observability.microServiesPropsDetails && state.observability.microServiesPropsDetails.selectedMicroServicesRcaEventDetail ? state.observability.microServiesPropsDetails.selectedMicroServicesRcaEventDetail : {},

        microServicesRcaDetails: state.observability.observabilityMicroServicesRcaDetails ? state.observability.observabilityMicroServicesRcaDetails : {},
    }
}

export default connect(mapStateToProps, {
    listAllAccounts,
	listAllRegions,
    getChangeImpactAnalysisResults
})(withRouter(RcaRightSection))