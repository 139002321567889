/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from 'react'
import { setEditRuleDetails } from '../../../actions/governance/governanceAction'
import { listAllRegions, listAllAccounts } from '../../../actions/commonAction'
import { onlyNumeric, getRegionName, getAccountNameFromId } from '../../../utils/utility'
import { Spinner, Input } from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

class ServiceReplication extends Component {
	constructor(props) {
		super(props)
		this.regionRef = React.createRef()
		this.accountRef = React.createRef()
		
		this.state = {
			hasErrorInRuleForm: false,
			accounts: [],
			regions: [],

			isAccountOpen: false,
			isRegionOpen: false,
			isLoading: true,

			allowCrossAccountOption: [],
			allowCrossRegionOption: [],
		}
	}

	componentDidMount = () => {
		let params = {
			provider: this.props.editRuleDetails.provider && this.props.editRuleDetails.provider,
		}

		if(this.props.accounts && !this.props.accounts.length) {
			this.props.listAllAccounts(params, (promise, accounts) => {
				if (promise) {
					this.setState({ accounts, isLoading: false },
						() => this.waitForListAccRegion()
					)
				}
			})
		} else {
			this.setState({
				accounts: this.props.accounts,
				isLoading: false
			},
				() => this.waitForListAccRegion()
			)
		}

		if(this.props.regions && !this.props.regions.length) {
			this.props.listAllRegions(params, (promise, regions) => {
				if (promise) {	
					this.setState({ regions: regions[0].provider_regions, isLoading: false },
						() => this.waitForListAccRegion()
					)
				}
			})
		} else {
			this.setState({
				regions: this.props.regions,
				isLoading: false
			},
				() => this.waitForListAccRegion()
			)
		}		
	}

	waitForListAccRegion = () => {
		let allowReplicationState = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowReplication ? true : false
		let allowStandbyState = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowStandby ? true : false
		let maxReplicationPerClusterState = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.maxReplicationPerCluster ? this.props.editRuleDetails.condition.maxReplicationPerCluster : ''
		let allowCrossZoneReplicationState = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowCrossZoneReplication ? true : false
		let allowCrossRegionReplicationState = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowCrossRegionReplication ? true : false
		let allowCrossRegionOptionState =  this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowCrossRegionOption ? this.props.editRuleDetails.condition.allowCrossRegionOption : []
		// if(this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowCrossRegionReplication && this.props.editRuleDetails.condition.allowCrossRegionOption.length) {
		// 	let optionTypeArray = []
		// 	this.props.editRuleDetails.condition.allowCrossRegionOption.forEach(item => {
		// 		let dataRow = {}
		// 		if (this.state.regions.filter(e => e.region === item).length > 0) {
		// 			let array = this.state.regions.filter(e => e.region === item)
		// 			dataRow.label = array[0].name
		// 			dataRow.value = array[0].region
		// 			optionTypeArray.push(dataRow)
		// 		}
		// 	})
		// 	this.setState({ allowCrossRegionOptionArray: optionTypeArray })
		// }
		
		let allowCrossAccountReplicationState = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowCrossAccountReplication ? true : false
		let allowCrossAccountOptionState =  this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowCrossAccountOption ? this.props.editRuleDetails.condition.allowCrossAccountOption : []
		// if(this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowCrossAccountOption && this.props.editRuleDetails.condition.allowCrossAccountOption.length) {
		// 	let optionTypeArray = []
		// 	this.props.editRuleDetails.condition.allowCrossAccountOption.forEach(item => {
		// 		let dataRow = {}
		// 		if (this.state.accounts.filter(e => e.account_id === item).length > 0) {
		// 			let array = this.state.accounts.filter(e => e.account_id === item)
		// 			dataRow.label = array[0].account_name
		// 			dataRow.value = array[0].account_id
		// 			optionTypeArray.push(dataRow)
		// 		}
		// 	})
		// 	this.setState({ allowCrossAccountOptionArray: optionTypeArray })
		// }

		let encryptReplicasAtRestState = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.encryptReplicasAtRest ? true : false
		let encryptReplicasAtTransitState = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.encryptReplicasAtTransit ? true : false
		
		this.setState({ 
			allowReplication: allowReplicationState,
			allowStandby: allowStandbyState,
			maxReplicationPerCluster: maxReplicationPerClusterState,
			allowCrossZoneReplication: allowCrossZoneReplicationState,
			allowCrossRegionReplication: allowCrossRegionReplicationState,
			allowCrossRegionOption: allowCrossRegionOptionState,
			allowCrossAccountReplication: allowCrossAccountReplicationState,
			allowCrossAccountOption: allowCrossAccountOptionState,
			encryptReplicasAtRest: encryptReplicasAtRestState,
			encryptReplicasAtTransit: encryptReplicasAtTransitState
		})
		
		let condition = {}
		condition['allowReplication'] = allowReplicationState
		condition['allowStandby'] = allowStandbyState
		condition['maxReplicationPerCluster'] = maxReplicationPerClusterState
		condition['allowCrossZoneReplication'] = allowCrossZoneReplicationState
		condition['allowCrossRegionReplication'] = allowCrossRegionReplicationState
		condition['allowCrossRegionOption'] = allowCrossRegionOptionState
		condition['allowCrossAccountReplication'] = allowCrossAccountReplicationState
		condition['allowCrossAccountOption'] = allowCrossAccountOptionState
		condition['encryptReplicasAtRest'] = encryptReplicasAtRestState
		condition['encryptReplicasAtTransit'] = encryptReplicasAtTransitState

		this.props.setEditRuleDetails('condition', condition)
	}
	handleClickOutside = (event) => {

		if (this.regionRef && !this.regionRef.current.contains(event.target)) {
			this.setState({ isRegionOpen: false })
		} else {
			this.setState({ isRegionOpen: true })
		}

		if (this.accountRef && !this.accountRef.current.contains(event.target)) {
			this.setState({ isAccountOpen: false })
		} else {
			this.setState({ isAccountOpen: true })
		}
	}

	handleMultiSelectChange = (field, arrayValue) => {
		let value = arrayValue.map(item => item.value)
		// let label = arrayValue.map(item => item)

		let selectedValue = []
		if(field === 'allowCrossAccountOption') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.allAccounts.forEach(acc => {
						selectedValue.push(acc.account_id)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(selectedValue.length === (this.state.accounts.length -1)) {
					selectedValue.push('All')
				}
			}
			this.setState({ [field]: selectedValue },
				() => this.waitForStateUpdate(field, value)
			)
		} else if(field === 'allowCrossRegionOption') {			
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.regions.length -1)) {
					selectedValue.push('All')
				}
			}

			this.setState({ [field]: selectedValue },
				() => this.waitForStateUpdate(field, value)
			)
		}

	}

	waitForStateUpdate = (field, value) => {
		let condition = this.props.editRuleDetails.condition
		condition[field] = value
		this.props.setEditRuleDetails('condition', condition)
	}

	handleSelectedInput = (input, value) => {
		let condition = this.props.editRuleDetails.condition
		if(input === 'allowCrossAccountReplication' && value === false) {
			condition['allowCrossAccountReplication'] = []
			this.setState({ allowCrossAccountOption: [] })
		} else if(input === 'allowCrossRegionReplication' && value === false) {
			condition['allowCrossAccountReplication'] = []
			this.setState({ allowCrossRegionOption: [] })
		}
		condition[input] = value
		this.props.setEditRuleDetails('condition', condition)
		
	}

	removeSelectedOption = (field, index, value) => {
		let filteredResult = this.state[field].filter(e => e !== value)
		this.setState({ allowCrossAccountOption: filteredResult })

		let condition = this.props.editRuleDetails.condition
		condition[field] = filteredResult
		this.props.setEditRuleDetails('condition', condition)
	}

	getMultiSelectedCount = (type, array) => {
		if(array) {
			return array.length && array.includes('All') ? 'All' :  array.length ? array.length +' Selected' : 'All'
		} else {
			return 'All'
		}
	}
	
	render() {
		return (
			<div ref={this.scrollRef} onClick={ (event) => { this.handleClickOutside(event) } }> 
				
				{this.state.isLoading ?
					<div className='d-flex justify-content-center m-4'>
						<Spinner className='text-center' color='dark' size='lg' />
					</div>
				: 
					<div className="row zapInputDark">
						<div className="col-lg-12 col-md-12">
							<div className="row form-group">
								<div className="col-lg-3 col-md-3">
									<div className="form-check">
										<input 
											type="checkbox" 
											className="form-check-input" 
											id="allowReplication" 
											checked={this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowReplication ? true : false}
											onChange={e => this.setState({ allowReplication: this.state.allowReplication ? false : true },
												() => this.handleSelectedInput('allowReplication', this.state.allowReplication) 
											)}
										/>
										<label className="form-check-label mt-5p" for="allowReplication">Allow Replication</label>
									</div>
								</div>								
								<div className="col-lg-3 col-md-3">
									<div className="form-check">
										<div className='form-check form-check-inline align-self-center'>
											<input
												className='form-check-input'
												type='checkbox'
												id="allowStandby" 
												checked={this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowStandby ? true : false}
												onChange={e => this.setState({ allowStandby: this.state.allowStandby ? false : true },
													() => this.handleSelectedInput('allowStandby', this.state.allowStandby) 
												)}
											/>
											<label className="form-check-label mt-5p" for="allowStandby">Allow Standby</label>
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-md-6">&nbsp;</div>
							</div>
							
							<div className="row form-group">
								<div className="col-lg-3 col-md-3">
									<div className='d-flex mr-0'>
										<p className="m-0 p-0 align-self-center mr-3">Max. number of replicas allowed per Cluster</p>
										<Input
											type='text'
											value={this.state.maxReplicationPerCluster}
											onChange={e => {
												this.setState({ maxReplicationPerCluster: onlyNumeric(e.target.value) },
													() => this.handleSelectedInput('maxReplicationPerCluster', this.state.maxReplicationPerCluster) 
												)
											}}
											className='inputTextbox w-25'
											placeholder=''
										/>
									</div>
								</div>
								<div className="col-lg-3 col-md-3">
									<div className="form-check">
										<div className='form-check form-check-inline align-self-center'>
											<input
												className='form-check-input'
												type='checkbox'
												id='allowCrossZoneReplication'
												checked={this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowCrossZoneReplication ? true : false}
												onChange={e => this.setState({ allowCrossZoneReplication: this.state.allowCrossZoneReplication ? false : true },
													() => this.handleSelectedInput('allowCrossZoneReplication', this.state.allowCrossZoneReplication) 
												)}
											/>
											<label className="form-check-label mt-5p" for="allowCrossZoneReplication">Allow Cross Zone Replication</label>
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-md-6">&nbsp;</div>
							</div>

							<div className="row form-group">
								<div className="col-lg-3 col-md-3 align-self-center">
									<div className="form-check">
										<input
											className='form-check-input'
											type='checkbox'
											id='allowCrossRegionReplication'
											checked={this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowCrossRegionReplication ? true : false}
											onChange={e => this.setState({ allowCrossRegionReplication: this.state.allowCrossRegionReplication ? false : true },
												() => this.handleSelectedInput('allowCrossRegionReplication', this.state.allowCrossRegionReplication) 
											)}
										/>
										<label className="form-check-label mt-5p" for="allowCrossRegionReplication">Allow Cross Region Replication</label>
									</div>
								</div>
								<div className="col-lg-9 col-md-9">
									<div className="d-flex">
										<div className="form-check">
											<label className="mb-1">List of Regions Allowed</label>
											<div className={`multiselectDarkTheme minWidth300 z999 w-18 ${this.state.regions.length > 10 ? '' : 'removeDropdownSearchBar'} ${this.state.allowCrossRegionReplication ? '' : 'disabled disabledDarkBg'}`} ref={this.regionRef}>
												<ReactMultiSelectCheckboxes						
													placeholderButtonLabel="All"
													getDropdownButtonLabel={() => this.getMultiSelectedCount('allowCrossRegionOption', this.state.selectedAccount)}
													// menuIsOpen ={this.state.isRegionOpen}
													value={this.state.allowCrossRegionOption.map(acc => ({
														value: acc
													}))}
													onChange={arr => {
													this.handleMultiSelectChange('allowCrossRegionOption', arr ? arr : []) }}
													options={this.state.regions.map(item => ({
														value: item.region,
														label: item.name,
													}))}
												/>
											</div>
										</div>
										<div className="ml-3 flex-grow align-self-center mt-1">
											{this.state.allowCrossRegionOption.length ? this.state.allowCrossRegionOption.map((item, index) => {
												return(
													item !== 'All' ?
														<span key={index} className="badge badge-light f14 mr-1 mb-1" >
															{getRegionName(item, this.state.regions)}
															<i onClick={ () => this.removeSelectedOption('allowCrossRegionOption', index, item) } className='ml-1 fal fa-times cursorPointer'></i>
														</span>
													: null
												)
											})
											: null
											}
										</div>
									</div>
								</div>
							</div>
							
							<div className="row form-group">
								<div className="col-lg-3 col-md-3 align-self-center">
									<div className="form-check">
										<input
											className='form-check-input'
											type='checkbox'
											id='allowCrossAccountReplication'
											checked={this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowCrossAccountReplication ? true : false}
											onChange={e => this.setState({ allowCrossAccountReplication: this.state.allowCrossAccountReplication ? false : true },
												() => this.handleSelectedInput('allowCrossAccountReplication', this.state.allowCrossAccountReplication) 
											)}
										/>
										<label className="form-check-label mt-5p" for="allowCrossAccountReplication">Allow Cross Account</label>
									</div>
								</div>
								<div className="col-lg-9 col-md-9">
									<div className="d-flex">
										<div className="form-check">
											<label className="mb-1">List of Accounts Allowed</label>
											<div className={`multiselectDarkTheme minWidth300 z998 w-18 ${this.state.accounts.length > 10 ? '' : 'removeDropdownSearchBar'} ${this.state.allowCrossAccountReplication ? '' : 'disabled disabledDarkBg'}`} ref={this.accountRef}>
												<ReactMultiSelectCheckboxes						
													placeholderButtonLabel="All"
													getDropdownButtonLabel={() => this.getMultiSelectedCount('allowCrossAccountOption', this.state.selectedAccount)}
													// menuIsOpen ={this.state.isAccountOpen}
													value={this.state.allowCrossAccountOption.map(acc => ({
														value: acc
													}))}
													onChange={arr => {
													this.handleMultiSelectChange('allowCrossAccountOption', arr ? arr : []) }}
													options={this.state.accounts.map(item => ({
														value: item.account_id,
														label: item.account_name,
													}))}
												/>
											</div>
										</div>
										<div className="ml-3 flex-grow align-self-center mt-1">
											{this.state.allowCrossAccountOption.length ? this.state.allowCrossAccountOption.map((item, index) => {
												return(
													item !== 'All' ?
														<span key={index} className="badge badge-light f14 mr-1 mb-1" >
															{getAccountNameFromId(item, this.state.accounts)}
															<i onClick={ () => this.removeSelectedOption('allowCrossAccountOption', index, item) } className='ml-1 fal fa-times cursorPointer'></i>
														</span>
													: null
												)
											})
											: null
											}
										</div>
									</div>
								</div>
							</div>

							<div className="row form-group pt-2">
								<div className="col-lg-3 col-md-3">
									<div className="form-check">
										<input 
											type="checkbox" 
											className="form-check-input" 
											id="encryptReplicasAtRest" 
											checked={this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.encryptReplicasAtRest ? true : false}
											onChange={e => this.setState({ encryptReplicasAtRest: this.state.encryptReplicasAtRest ? false : true },
												() => this.handleSelectedInput('encryptReplicasAtRest', this.state.encryptReplicasAtRest) 
											)}
										/>
										<label className="form-check-label mt-5p" for="encryptReplicasAtRest">Encrypt Replicas at rest</label>
									</div>
								</div>
								<div className="col-lg-3 col-md-3">
									<div className="form-check">
										<div className='form-check form-check-inline align-self-center'>
											<input
												className='form-check-input'
												type='checkbox'
												id="encryptReplicasAtTransit" 
												checked={this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.encryptReplicasAtTransit ? true : false}
												onChange={e => this.setState({ encryptReplicasAtTransit: this.state.encryptReplicasAtTransit ? false : true },
													() => this.handleSelectedInput('encryptReplicasAtTransit', this.state.encryptReplicasAtTransit) 
												)}
											/>
											<label className="form-check-label mt-5p" for="encryptReplicasAtTransit">Encrypt Replicas at transit</label>
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-md-6">&nbsp;</div>
							</div>
						</div>
					</div>
				}
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ServiceReplication.propTypes = {
	logType: PropTypes.object,
	listAllAccounts: PropTypes.func,
	listAllRegions: PropTypes.func,
	setEditRuleDetails: PropTypes.func,
	editRuleDetails: PropTypes.object,
	parameters: PropTypes.object,
	provider: PropTypes.string,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		editRuleDetails: state.governance.editRuleDetails,	
		accounts: state.filters.accounts,
		regions: state.filters.regions && state.filters.regions[0] && state.filters.regions[0].provider_regions ? state.filters.regions[0].provider_regions : [],
	}
}
export default connect(mapStateToProps, {
	setEditRuleDetails,
	listAllAccounts,
	listAllRegions,
})(ServiceReplication)
