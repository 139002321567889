/*************************************************
 * Tvastar
 * @exports
 * @file ApexGandalfInterventionChart.js
 * @author Prakash // on 18/07/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Chart from 'react-apexcharts'
import _ from 'lodash'
import { getMonitoringMetricsByTrend } from '../../../actions/aiops/MetricsAction'

import { addHours, momentConvertionUtcToLocalTime, momentDateGivenFormat, getConvertedBytesUnit, convertBytes, convertBytesTo, convertBytesWithoutUnit, convertSeconds, convertSecondsWithoutUnit, countFormater, countFormaterWithoutUnit, convertBits, convertBitsWithoutUnit } from '../../../utils/utility'

class ApexGandalfInterventionChart extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    
    componentDidMount = () => {
        let graphData = this.props.graphData;
        if(graphData === 'mockData') {
            graphData = {
                data: [1,4,2,0,5,1,1,1,4,2,3,3,2,5,1,5,0,0,0,4,1,3,2,1,4,2,0,5,4,3,1],
                labels: ["14 July 2022 09:48", "14 July 2022 09:49", "14 July 2022 09:50", "14 July 2022 09:51", "14 July 2022 09:52", "14 July 2022 09:53", "14 July 2022 09:54", "14 July 2022 09:55", "14 July 2022 09:56", "14 July 2022 09:57", "14 July 2022 09:58", "14 July 2022 09:59", "14 July 2022 10:00", "14 July 2022 10:01", "14 July 2022 10:02", "14 July 2022 10:03", "14 July 2022 10:05", "14 July 2022 10:05", "14 July 2022 10:06", "14 July 2022 10:07", "14 July 2022 10:08", "14 July 2022 10:09", "14 July 2022 10:10", "14 July 2022 10:11", "14 July 2022 10:12", "14 July 2022 10:13", "14 July 2022 10:14", "14 July 2022 10:15", "14 July 2022 10:16", "14 July 2022 10:17", "14 July 2022 10:18"],
                alerts: [{date: "14 July 2022 09:52", y: 2, z: "alert"}, {date: "14 July 2022 10:04", y: 3, z: "anomalies"}, {date: "14 July 2022 10:06", y: 1, z: "error"}, {date: "14 July 2022 10:07", y: 2, z: "alert"}, {date: "14 July 2022 10:010", y: 3, z: "anomalies"},  {date: "14 July 2022 10:12", y: 2, z: "alert"}]
            }

            this.drawMockGraph()

        } else {
            this.getGandalfInterventionMertics()
        }
    }

    getGandalfInterventionMertics = () => {
        this.calldrawFunction = false
        let impactAnalysis = this.props.impactAnalysis
        if(impactAnalysis && impactAnalysis.intervention && impactAnalysis.intervention.length) {

            let interventionMetrics = []
            let params = {}
            impactAnalysis.intervention.forEach(item => {
                params.provider = item.provider
                params.anomaly = false
                if(item.asset_id) {
                    params.asset_id = item.asset_id
                } else {
                    params.asset_name = item.asset_name
                }
                params.service_name = item.service_name
                // params.metric_name = item.metric_name
                if(this.props.startTime && this.props.endTime) {
                    params.start_time = this.props.startTime
                    params.end_time = this.props.endTime
                } else {
                    params.start_time = item.start_time
                    params.end_time = item.end_time
                }

                interventionMetrics.push(item.metric_name)

                this.setState({ ["internvention_"+item.asset_id]: item })
            })

            params.metric_name = interventionMetrics
            
            this.props.getMonitoringMetricsByTrend(params, (promise, response) => {
                if(promise) {
                    this.setState({ interventionMetricsData: response, showLoading: false, interventionMetrics }, () => this.drawGraph() )
                } else {
                    this.setState({ showLoading: false, interventionMetrics })
                }
            })
        }
    }

    drawMockGraph = (graphData) => {
        let points = []
        graphData.alerts && graphData.alerts.forEach(item => {
            points.push({
                x: new Date(item.date).getTime(),
                y: item.y,
                // mouseEnter: function(e) {
                //     // do something on marker click
                //     alert(1)
                // },
                marker: {
                    size: 6,
                    fillColor: item.z === "alert" ? "#FF4560" : item.z === "anomalies" ? "#775BA2" : item.z === "error" ? "#858B99" : "#3DAFE8",
                    strokeColor: item.z === "alert" ? "#FF4560" : item.z === "anomalies" ? "#775BA2" : item.z === "error" ? "#858B99" : "#3DAFE8",
                    radius: 2,
                    OffsetX: 0,
                    OffsetY: 0,
                    cssClass: '',
                },
                label: {
                    // borderColor: "#FF4560",
                    // offsetY: 0,
                    // style: {
                    //     color: "#fff",
                    //     background: "#FF4560"
                    // },                
                    // text: ""
                }
            })
        })
        
        let graphLables = graphData.labels
        let series = [{
            data: graphData.data,
            labels: graphData.labels
        }]

        let options = {
            tooltip: {
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    let val  = series[seriesIndex][dataPointIndex]
                    let labelName = "change"
                    let unit = w.globals.initialSe
                    let retrunData = '<div class="arrow_box">'
                    retrunData += ' <span>' +  w.globals.initialSeries[0].labels[dataPointIndex] + '</span> '
                    if(labelName !== '') {
                        retrunData += '<span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> '+ val
                    } else {
                        retrunData += '<span style="color:#039BE5"> ' + val + '</span>'
                    }

                    retrunData += '</div>'

                    return retrunData
                },
                fixed: {
                    enabled: false
                },
                x: {
                    show: false,
                },
                y: {
                    show: false
                },        
                marker: {
                    show: false
                },
            },				
            chart: {
                redrawOnParentResize: true,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
                sparkline: {
                    enabled: this.props.sparkline
                },
                animations: {
                    enabled: true
                },
                type: 'line',
                height: 150,
                stacked: this.props.stacked,
            },
            colors: this.props.colors ? this.props.colors : ['#26B68F', '#2ED1BE', '#67E1D6', '#00B5D4'],
            stroke: {
                curve: 'straight',
                width: 1,
                lineCap: 'butt',
                // colors: ["#775DD0"]
            },
            dataLabels: {
                enabled: false
            },  
            grid: {
                show: false,
                borderColor: '#2D323E',
                strokeDashArray: 0,
                position: 'back',
                xaxis: {
                    lines: {
                        show: true
                    },
                    labels: {
                        style: {
                            colors: ['#FFF'],
                        }
                    },
                    axisTicks: {
                        show: false
                    }
                },   
                yaxis: {
                    lines: {
                        show: false
                    },
                    labels: {
                        style: {
                            colors: ['#969AA7'],
                        }
                    }
                },   
            },
            labels: graphLables,
            xaxis: {
                show: this.props.xaxis,
                tooltip: {
                    enabled: false
                },
                type: this.props.xaxisFormat !== 'string' ? 'datetime' : 'category',
                axisBorder: {
                    show: true,
                    color:'#434B5E'
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: this.props.xaxisLabel,
                    datetimeUTC: false,
                    style: {
                        colors: this.props.axisLabelColor,
                        fontSize: this.props.axisLabelFontSize ?  this.props.axisLabelFontSize : '13px',
                        fontWeight: 'bolder',
                    },
                },
                
                crosshairs: {
                    show: false
                },
            },
            yaxis: {
                show: this.props.yaxis,
                axisTicks: {
                    show: false,
                },
                // this.props.tickAmount: this.props.yaxisTickAmont ? this.props.yaxisTickAmont : 4,
                axisBorder: {
                    show: false,
                    color: '#969AA7',
                },
                tooltip: {
                    enabled: false
                },
                labels: {
                    show: this.props.yaxisLabel,
                    style: {
                        colors: this.props.axisLabelColor,
                        fontSize: '13px',
                        fontWeight: 'bolder',
                    },
                    formatter: function(value) {
                        return parseInt(value)
					}
                },
            },
            legend: {
                show: this.props.legend,
            }, 
            // markers: {
            //     // colors: "transparent", //to hide marker on hover in the apex line chart
            //     // strokeWidth: 0,
            //     // size: 0,
            //     // colors: this.props.colors,
            //     // strokeColors: this.props.colors,
            // },           
            annotations: {
                position: 'back',
                points: points,                
                xaxis: [{
                    x: new Date('14 July 2022 10:03').getTime(),
                    strokeDashArray: 0,
                    borderColor: '#F75E3F',
                    label: {
                      borderColor: '#F75E3F',
                      style: {
                        color: '#fff',
                        background: '#F75E3F',
                      },
                      text: 'ipsum',
                    }
                }]
            },
        }

        this.setState({ series, options })
    }

    drawGraph = () => {
        this.state.interventionMetricsData && this.state.interventionMetricsData.forEach((graph, gIndx) => {
            let graphData = graph.data
            let graphDates = graph.dates
            Object.entries(graphData).forEach(([key, item], i) => {
                let maxValue = item.statistics && item.statistics.max ? item.statistics.max : 1
                let avgValue = item.statistics && item.statistics.avg ? item.statistics.avg : 1
                let minValue = item.statistics && item.statistics.min ? item.statistics.min : 1
                
                let graphConvertTo = ""

                let maxRandomNumber = maxValue
                let minRandomNumber = minValue
                if(item.unit) {
                    graphConvertTo = this.getConvertedUnit(maxValue, item.unit)
                    maxRandomNumber = this.getGrapUnitValueWithoutUnit(maxValue, item.unit, "round")
                    minRandomNumber = this.getGrapUnitValueWithoutUnit(minValue, item.unit, "round")
                }

                let array = []
                let graphLabels = []
                item.values.forEach((val, j) => {
                    graphLabels.push(momentConvertionUtcToLocalTime(graphDates[j], 'YYYY-MM-DD HH:mm:ss'))
                    let arrayValues = []
                    arrayValues.push(momentConvertionUtcToLocalTime(graphDates[j], 'YYYY-MM-DD HH:mm:ss'))
                    if(item.unit) {
                        val = this.getGrapUnitValue(val, item.unit, "", graphConvertTo)
                    }
                    arrayValues.push(val)
                    array.push(arrayValues)
                })

                if(array.length === 1) {
                    let arrayValues = []
                    arrayValues.push(addHours(momentConvertionUtcToLocalTime(graphDates[0], 'YYYY-MM-DD HH:mm:ss'), 0.5))
                    arrayValues.push(0)
                    array.push(arrayValues)
                }

                let series = [{
                    data: array,
                    name: key,
                }]

                let impactsObserved = []
                if(maxRandomNumber) {
                    if(this.props.impactAnalysis.before_events && this.props.impactAnalysis.before_events.length) {
                        this.props.impactAnalysis.before_events.forEach(item => {
                            // if(item.event_time >= this.state["internvention_"+graph.asset_id].start_time && item.event_time <= this.state["internvention_"+graph.asset_id].end_time) {
                                let date = momentConvertionUtcToLocalTime(item.event_time, "YYYY-MM-DD HH:mm:ss")
                                item.date = date
                                let value = Math.floor(Math.random() * (maxRandomNumber - minRandomNumber))
                                item.value = parseInt(value) + parseInt(minRandomNumber)
                                impactsObserved.push(item)
                            // }
                        })
                    }

                    if(this.props.impactAnalysis.after_events && this.props.impactAnalysis.after_events.length) {
                        this.props.impactAnalysis.after_events.forEach(item => {
                            // if(item.event_time >= this.state["internvention_"+graph.asset_id].start_time && item.event_time <= this.state["internvention_"+graph.asset_id].end_time) {
                                let date = momentConvertionUtcToLocalTime(item.event_time, "YYYY-MM-DD HH:mm:ss")
                                item.date = date
                                let value = Math.floor(Math.random() * (maxRandomNumber - minRandomNumber))
                                item.value = parseInt(value) + parseInt(minRandomNumber)
                                impactsObserved.push(item)
                            // }
                        })
                    }
                }

                console.log("impactsObserved", impactsObserved)
                    
                let eventTypesCount = {"alerts": 0, "anomalies": 0, "errors": 0, "issues": 0}
                if(impactsObserved.length) {
                    let groupByEventType = _.groupBy(impactsObserved, 'event_type');
                    Object.entries(groupByEventType).forEach(([key, value]) => {
                        eventTypesCount[key] = value.length
                    })
                }

                let gandalfData = impactsObserved

                if(this.state["selectedMetricsLegend_"+key] === key && this.state["selectedLegendType_"+key] && this.state["selectedLegendType_"+key] !== "") {
                    gandalfData = gandalfData.filter(e => e.event_type === this.state["selectedLegendType_"+key]).length ? gandalfData.filter(e => e.event_type === this.state["selectedLegendType_"+key]) : []
                } else {
                    this.setState({ ["showElipMenu_"+key]: false })
                }

                let points = []                    
                gandalfData.forEach(item => {
                    points.push({
                        x: new Date(item.date).getTime(),
                        y: Math.floor(Math.random() * (maxValue - 1) + 1),
                        // y: item.value,
                        marker: {
                            size: 6,
                            fillColor: item.event_type === "alerts" ? "#3F73AD" : item.event_type === "anomalies" ? "#775BA2" : item.event_type === "errors" ? "#24A597" : item.event_type === "issues" ? "#FF4560" : "#858B99",
                                strokeColor: item.event_type === "alerts" ? "#3F73AD" : item.event_type === "anomalies" ? "#775BA2" : item.event_type === "errors" ? "#24A597" : item.event_type === "issues" ? "#FF4560" : "#858B99",
                            radius: 2,
                            OffsetX: 0,
                            OffsetY: 0,
                            cssClass: '',
                        },
                    })
                })

                let xaxisAnnotation = []
                let eventTime = this.state["internvention_"+graph.asset_id] && this.state["internvention_"+graph.asset_id].event_time ? momentConvertionUtcToLocalTime(this.state["internvention_"+graph.asset_id].event_time, "YYYY-MM-DD HH:mm:ss") : ""
                if(eventTime !== "") {
                    xaxisAnnotation = [{
                        x: new Date(eventTime).getTime(),
                        strokeDashArray: 0,
                        borderColor: '#F75E3F',
                        label: {
                            borderColor: '#F75E3F',
                            style: {
                                color: '#fff',
                                background: '#F75E3F',
                            },
                            text: "Intervention"
                        }
                    }]
                }

                let options = {
                    chart: {
                        redrawOnParentResize: true,
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false,
                        },
                        sparkline: {
                            enabled: this.props.sparkline
                        },
                        animations: {
                            enabled: true
                        },
                        type: 'line',
                        height: 150,
                        stacked: this.props.stacked,
                    },
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            show: false,
                        },
                        
                        x: {
                            show: false
                        },
                        marker: {
                            show: false
                        },
                        custom: function({series, seriesIndex, dataPointIndex, w}) {     
                                let returnData = ''
                                
                                if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                    Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                        if(!index) {
                                            returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                        }
                                        returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'

                                    })
                                }

                                returnData += '</div>'
                                return returnData
                        }
                    },     
                    colors: this.props.colors ? this.props.colors : ['#26B68F', '#2ED1BE', '#67E1D6', '#00B5D4'],
                    stroke: {
                        curve: 'straight',
                        width: 1,
                        lineCap: 'butt',
                        // colors: ["#775DD0"]
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        show: this.props.xaxis,
                        tooltip: {
                            enabled: false
                        },
                        type: this.props.xaxisFormat !== 'string' ? 'datetime' : 'category',
                        axisBorder: {
                            show: true,
                            color:'#434B5E'
                        },
                        axisTicks: {
                            show: false
                        },
                        labels: {
                            show: this.props.xaxisLabel,
                            datetimeUTC: false,
                            style: {
                                colors: this.props.axisLabelColor,
                                fontSize: this.props.axisLabelFontSize ?  this.props.axisLabelFontSize : '13px',
                                fontWeight: 'bolder',
                            },
                        },
                        
                        crosshairs: {
                            show: false
                        },
                    },
                    yaxis: {
                        show: this.props.yaxis,
                        axisTicks: {
                            show: false,
                        },
                        // this.props.tickAmount: this.props.yaxisTickAmont ? this.props.yaxisTickAmont : 4,
                        axisBorder: {
                            show: false,
                            color: '#969AA7',
                        },
                        tooltip: {
                            enabled: false
                        },
                        labels: {
                            show: this.props.yaxisLabel,
                            style: {
                                colors: this.props.axisLabelColor,
                                fontSize: '13px',
                                fontWeight: 'bolder',
                            },
                            formatter: function(value) {
                                return parseInt(value)
                            }
                        },
                    },
                    grid: {
                        show: true,
                        borderColor: '#2D323E',
                        strokeDashArray: 0,
                        position: 'back',
                        xaxis: {
                            lines: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: ['#FFF'],
                                }
                            },
                            axisTicks: {
                                show: false
                            }
                        },   
                        yaxis: {
                            lines: {
                                show: false
                            },
                            labels: {
                                style: {
                                    colors: ['#969AA7'],
                                }
                            }
                        },                
                        padding: {
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 20
                        },
                    },
                    annotations: {
                        position: 'back',
                        xaxis: xaxisAnnotation,
                        points: points
                    },
                    legend: {
                        show: this.props.legend,
                    }, 
                }

                this.setState({ ["series_"+key]: series, ["options_"+key]: options, eventTypesCount })
            })
        })
    }

    getConvertedUnit = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = getConvertedBytesUnit(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                // val = getConvertedSecondsUnit(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                // val = getConvertedBitsUnit(val, unit)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                // val = getConvertedCountFormater(val)
            } else {
                val = ""
            }
        } else if(val !== '') {           
            val = ""
        }
        return val
    }

    getGrapUnitValue = (val ,unit, valueType, convertTo) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseInt(val))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                if(convertTo) {
                    val = convertBytesTo(val, unit, valueType, convertTo)
                } else {
                    val = convertBytes(val, unit, valueType)
                }                         
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit, valueType, convertTo)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit, valueType, convertTo)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    getGrapUnitValueWithoutUnit = (val ,unit, valueType) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytesWithoutUnit(val, unit, valueType)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSecondsWithoutUnit(val, unit, valueType)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBitsWithoutUnit(val, unit, valueType)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                val = countFormaterWithoutUnit(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseInt(val))
        }
        return val
    }

    onClickPrimaryElipMenus = (key) => {
        this.state.interventionMetrics.forEach(item => {
            if(key !== item) {
                this.setState({ ["showElipMenu_"+item]: false })
            } else {
                this.setState({ ["showElipMenu_"+item]: !this.state["showElipMenu_"+item] })
            }
        })
    }

    onClickLegend = (type, key, i) => {
        this.setState({ ["selectedLegendType_"+key]: type, ["selectedMetricsLegend_"+key]: key, ["showElipMenu_"+key]: false, ["showElipMenu_"+key]: false },
            () => this.drawGraph()  
        )
    }

    render() {
        return(
            this.state.interventionMetricsData && this.state.interventionMetricsData.length ?
                this.state.interventionMetricsData.map((graph, gIndx) => {
                    return(
                        Object.entries(graph.data).map(([key, item], i) => {
                            return (
                                <div className={`bg-dark3 rounded p-2 ${gIndx ? "mt-2": ""} mb-2`}>
                                    <div className="d-flex justify-content-between">
                                        <div className=''>
                                            <p class="text-white f16 mb-0">
                                                {key} 
                                                {/* <span className="ml-2 text-purple-2 small">{item.id_name_map.value}</span>
                                                {item.id_name_map.key ?
                                                    <span className="mb-0 ml-2 text-purple-2 small">
                                                        {item.id_name_map.key}
                                                    </span>
                                                : null} */}
                                            </p>
                                            <p className="small mb-0">
                                                <span className="">Pattern: {this.state["internvention_"+graph.asset_id] && this.state["internvention_"+graph.asset_id].pattern}</span>
                                                <span className="ml-1">with the score {this.state["internvention_"+graph.asset_id] && this.state["internvention_"+graph.asset_id].score}</span>
                                                <span className="ml-1">of <span className={`risk-${this.state["internvention_"+graph.asset_id] && this.state["internvention_"+graph.asset_id].severity.toLowerCase()}`}>{this.state["internvention_"+graph.asset_id] && this.state["internvention_"+graph.asset_id].severity}</span> severity</span>
                                            </p>
                                        </div>
                                        <div className="btn-group ellipsisDropDownBlack dropleft">
                                            <div className={`cursorPointer`} onClick={() => this.onClickPrimaryElipMenus(key)}>
                                                <i className="fa fa-ellipsis-v text-secondary"></i>
                                            </div>
                                            <div className={`dropdown-menu ${this.state["showElipMenu_"+key] ? "show active" : ""}`}>
                                                <div>
                                                    <i className="fa fa-caret-up"></i>
                                                    <button className="dropdown-item" type="button"
                                                    onClick={() => this.onClickLegend("", key, i)}>All</button>
                                                </div>
                                                <div>
                                                    <i className="fa fa-caret-up"></i>
                                                    <button className="dropdown-item" type="button"
                                                    onClick={() => this.onClickLegend("alerts", key, i)}>Alerts</button>
                                                </div>
                                                <div>
                                                    <i className="fa fa-caret-up"></i>
                                                    <button className="dropdown-item" type="button"
                                                    onClick={() => this.onClickLegend("anomalies", key, i)}>Anomalies</button>
                                                </div>
                                                <div>
                                                    <i className="fa fa-caret-up"></i>
                                                    <button className="dropdown-item" type="button"
                                                    onClick={() => this.onClickLegend("errors", key, i)}>Errors</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state["options_"+key] && Object.keys(this.state["options_"+key]).length && this.state["series_"+key] && this.state["series_"+key].length ?
                                        <div className={`${this.props.className}`}>
                                            <Chart options={this.state["options_"+key]} series={this.state["series_"+key]} type="line" height={this.props.height} width={this.props.width ? this.props.width : "100%"}/>
                                            {this.state.eventTypesCount ?
                                                <ul className="stock-legend mt-n2 d-flex justify-content-center">
                                                    <li><span className="legend-circle bg-alerts"></span> <span className="text-muted d-inline-block"> &nbsp;Alerts: {this.state.eventTypesCount.alerts}</span></li> 
                                                    <li><span className="legend-circle bg-anomalies"></span> <span className="text-muted d-inline-block"> &nbsp; Anomalies: {this.state.eventTypesCount.anomalies}</span></li> 
                                                    <li><span className="legend-circle bg-errors"></span> <span className="text-muted d-inline-block"> &nbsp; Errors: {this.state.eventTypesCount.errors}</span></li>
                                                    <li><span className="legend-circle bg-issues"></span> <span className="text-muted d-inline-block"> &nbsp; Issues: {this.state.eventTypesCount.issues}</span></li>
                                                </ul>
                                            : null}
                                        </div>
                                    : null}
                                </div>
                            )
                        })
                    )
                })
            :
                <div className='d-flex justify-content-center m-4'>
                    No Metrics for the this criteria, Please adjust your filter to get the metric details.
                </div>
        )
    }
}
 
/**
 * Type of the props used in the component
 */
ApexGandalfInterventionChart.propTypes = {}

const mapStateToProps = state => {}

export default connect(mapStateToProps, {
    getMonitoringMetricsByTrend
})(withRouter(ApexGandalfInterventionChart))