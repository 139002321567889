/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file MetricsTab.js
 * @author Prakash // on 01/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'

import { Spinner } from 'reactstrap'
import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'
import { getMonitoringMetricsByTrend } from '../../../actions/aiops/MetricsAction'

import { momentDateGivenFormat, momentConvertionUtcToLocalTime, getConvertedBytesUnit, convertBytes, convertBytesTo, convertBytesWithoutUnit, convertSeconds, convertSecondsWithoutUnit, countFormater, countFormaterWithoutUnit, convertBits, convertBitsWithoutUnit, subHours, addHours, momentConvertionLocalToUtcTime, currentLocaltime } from '../../../utils/utility'

import Chart from 'react-apexcharts'

class MetricsTab extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            showAnnotation: true,
            loadingMetrics: true,
            details: this.props.details
        }
    }

    componentDidMount = () => {
        this.getMetricDetails()
    }

    getMetricDetails = () => {
        let eventTime = this.props.details.anomaly_time
        let merticsSaved = this.props.errorEventPageMonitoringList ? this.props.errorEventPageMonitoringList : {}
        let startTime = subHours(eventTime, 3)
        let endTime = addHours(eventTime, 3)
        if(endTime > momentConvertionLocalToUtcTime(currentLocaltime(), 'YYYY-MM-DD HH:mm:ss')) {
            endTime = momentConvertionLocalToUtcTime(currentLocaltime(), 'YYYY-MM-DD HH:mm:ss')
        }
        // let assetInvolved = this.props.merticsAssetIdInvolved && this.props.merticsAssetIdInvolved.length ? this.props.merticsAssetIdInvolved : this.props.merticsAssetNameInvolved
        // let assetIdExist = this.props.merticsAssetIdInvolved && this.props.merticsAssetIdInvolved.length ? true : false

        let merticsAssetNameInvolved = []
        let merticsAssetIdInvolved = []
        let merticsAssetArnInvolved = []
        let assetIdExist = true
        let assetArnExist = true
        let assetIds = []
        let assetNames = []
        let assetArns = []

        let assetDetails = []
        if(this.props.details && this.props.details.assets) {

            assetDetails = this.props.details.assets

            this.props.details.assets.forEach(item => {
                if(item.asset_id && assetIdExist) {
                    assetIds.push(item.asset_id)
                } else {
                    assetIdExist = false
                }

                if(item.asset_arn && assetArnExist) {
                    assetArns.push(item.asset_arn)
                    assetArnExist = true
                } else {
                    assetArnExist = false
                }

                if(item.asset_name) {
                    assetNames.push(item.asset_name)
                }
            })

            if(assetIdExist) {
                merticsAssetIdInvolved = assetIds
            } else if(assetArnExist) {
                merticsAssetArnInvolved = assetArns
            } else {
                merticsAssetNameInvolved = assetNames
            }
        } else {
            let dataRow = {}
            if(this.props.details.asset_id) {
                dataRow.asset_id = this.props.details.asset_id 
                merticsAssetIdInvolved = [this.props.details.asset_id]
            }
            if(this.props.details.asset_name) {
                dataRow.asset_name = this.props.details.asset_name
                merticsAssetNameInvolved = [this.props.details.asset_name]
            }
            if(this.props.details.service_name) {
                dataRow.service_name = this.props.details.service_name
            }                
            if(this.props.details.resource_type) {
                dataRow.resource_type = this.props.details.resource_type
            }
            if(this.props.details.asset_arn) {
                dataRow.asset_arn = this.props.details.asset_arn
                merticsAssetArnInvolved = [this.props.details.asset_arn]
            } else {
                assetArnExist = false
            }   
            if(dataRow && Object.entries(dataRow).length) {
                assetDetails.push(dataRow)
            }
        }

        if(assetDetails && assetDetails.length) {
            assetDetails.forEach(asst => {
                this.setState({ ['metrics_data_'+asst.asset_name] : [] }, 
                    () => {
                        if(!merticsSaved.hasOwnProperty(asst.asset_name)) {
                            let params = {}
                            params.start_time = startTime
                            params.end_time = endTime                            
                            if(assetIdExist) {
                                params.asset_id = merticsAssetIdInvolved
                            } else if(assetArnExist) {
                                params.asset_arn = merticsAssetArnInvolved
                            }  else {
                                params.asset_name = merticsAssetNameInvolved
                                params.account_id = this.props.details.account_id
                                params.region = this.props.details.region
                                if(asst.service_name) {
                                    params.service_name = asst.service_name
                                } else if(asst.resource_type) {
                                    params.resource_type = asst.resource_type
                                }
                            }
                            if(asst.service_name) {
                                params.service_name = asst.service_name
                            }
                            params.top = 5
                            params.anomaly = false
                            
                            this.props.getMonitoringMetricsByTrend(params, (promise, response) => {
                                if(promise) {
                                    this.setState({ 
                                        ['metrics_data_'+asst.asset_name]: response, 
                                        loadingMetrics: response.length ? true : false,
                                        assetDetails
                                    },
                                        () => {
                                            this.metricsListGraph()
                                        }
                                    )

                                    if(response.length) {
                                        // let obj = this.props.errorEventPageMonitoringList
                                        // obj[asst.asset_name] = response
                                        // this.props.setAiopsPropsDetails('errorEventPageMonitoringList', obj)
                                    }
                                } else {
                                    // this.props.menuDisabled()
                                    this.setState({
                                        loadingMetrics: false 
                                    })
                                }
                            })
                        } else {
                            this.setState({ ['metrics_data_'+asst.asset_name]: merticsSaved[asst.asset_name], assetDetails },
                                () => this.metricsListGraph()
                            )
                        }

                        this.setState({ startTime, endTime })
                    }
                )
            })
        } else {
            this.setState({ loadingMetrics: false, startTime, endTime, assetDetails })            
        }
    }
    
    metricsListGraph = () => {
        let selectedAsset = ''
        this.state.assetDetails && this.state.assetDetails.length && this.state.assetDetails.forEach(asst => {
            let graphDetails = this.state['metrics_data_'+asst.asset_name] ? this.state['metrics_data_'+asst.asset_name] : []
            if(graphDetails && graphDetails.length) {
                graphDetails.forEach(graph => {
                    let graphData = graph.data
                    Object.entries(graphData).forEach(([key, item], i) => {
                        let array = []
                        let series = []
                        let options = {}
                        if(item.values && item.values.length) {
                            if(selectedAsset === ''){
                                selectedAsset = asst.asset_name
                            }
                            let maxValue = item.statistics && item.statistics.max ? item.statistics.max : 1
                            let avgValue = item.statistics && item.statistics.avg ? item.statistics.avg : 1
                            let minValue = item.statistics && item.statistics.min ? item.statistics.min : 1
                            
                            
                            let graphConvertTo = ""

                            let maxRandomNumber = maxValue
                            let minRandomNumber = minValue
                            
                            if(item.unit) {
                                graphConvertTo = this.getConvertedUnit(maxValue, item.unit)
                                maxRandomNumber = this.getGrapUnitValueWithoutUnit(maxValue, item.unit, "round")
                                minRandomNumber = this.getGrapUnitValueWithoutUnit(minValue, item.unit, "round")
                            }

                            item.values.forEach((val, j) => {
                                let arrayValues = []
                                arrayValues.push(momentConvertionUtcToLocalTime(graph.dates[j], 'YYYY-MM-DD HH:mm:ss'))
                                if(item.unit) {
                                    val = this.getGrapUnitValue(val, item.unit, "", graphConvertTo)
                                }
                                arrayValues.push(val)
                                array.push(arrayValues)
                            })

                            series = [{
                                data: array
                            }]
                            
                            let xaxisAnnotation = []
                            if(this.state.showAnnotation) {
                                let alerts = [this.props.details.event_time]

                                if(alerts.length) {
                                    alerts.forEach((item, z) => {
                                        let alertRow = {}
                                        // alertRow.x = new Date(momentConvertionUtcToLocalTime(addMinutes(item, (z*10)), 'YYYY-MM-DD HH:mm:ss')).getTime()
                                        alertRow.x = new Date(momentConvertionUtcToLocalTime(item, 'YYYY-MM-DD HH:mm:ss')).getTime()
                                        alertRow.strokeDashArray = 0
                                        alertRow.borderColor = '#FF6F00'
                                        alertRow.label = {
                                            borderColor: '#FF6F00',
                                            style: {
                                                // fontSize: '12px',
                                                color: '#fff',
                                                background: '#FF6F00'
                                            },
                                            orientation: 'horizontal',
                                            text: ''
                                            // text: momentConvertionUtcToLocalTime(item, 'DD MMM YY HH:mm'),
                                        }

                                        xaxisAnnotation.push(alertRow)
                                    })
                                }
                            }

                            let annotations = {
                                xaxis: xaxisAnnotation
                            }

                            options = {
                                chart: {
                                    // group: 'metrics_and_sre',
                                    // id:'metrics_'+i,
                                    type: 'area',
                                    width: '100%',
                                    toolbar: {
                                        show: false,
                                    },
                                    zoom: {
                                        enabled: false
                                    },
                                    sparkline: {
                                        enabled: false
                                    }
                                },
                                stroke: {
                                    show: true,
                                    curve: 'smooth',
                                    lineCap: 'butt',
                                    width: 2,
                                    dashArray: 0,      
                                },
                                colors: ['#775BA2'],
                                fill: {
                                    type: "gradient",
                                    gradient: {
                                        // shadeIntensity: 1,
                                        // opacityFrom: 0.7,
                                        // opacityTo: 0.9,
                                        // stops: [0, 90, 100]
                                        gradientToColors: ['#775BA2'],
                                        shadeIntensity: 0,
                                        opacityFrom: .3,
                                        opacityTo: 1,
                                        stops: [30, 90]
                                    }
                                },
                                tooltip: {
                                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                                        let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
                                        dateLable = dateLable.toString()
                                        dateLable = momentDateGivenFormat(dateLable, 'DD MMM HH:mm')
                                        let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]
                                        return '<div class="arrow_box">'+dateLable+' <span style="color:'+ w.globals.colors +'">' + val + '</span> </div>'
                                    },
                                    fixed: {
                                        enabled: false
                                    },
                                    x: {
                                        show: false,
                                    },
                                    y: {
                                        show: false
                                    },        
                                    marker: {
                                        show: false
                                    }
                                },
                                xaxis: {
                                    type: 'datetime',
                                    labels: {
                                        datetimeUTC: false,
                                        style: {
                                            colors: '#60687C'
                                        },
                                        // formatter: function (value) {
                                        //     return momentDateGivenFormat(value, 'HH:mm')
                                        // },
                                    },                            
                                    axisTicks: {
                                        show: false
                                    },
                                    tooltip: {
                                        enabled: false
                                    },
                                },
                                dataLabels: {
                                    enabled: false
                                },
                                yaxis: {
                                    show: true,
                                    axisTicks: {
                                        show: false,
                                    },
                                    axisBorder: {
                                        show: false,
                                        color: '#60687C'
                                    },
                                    tooltip: {
                                        enabled: false
                                    },
                                    // tickAmount: 2,
                                    min: 0,
                                    // max: yaxisMaxValue, 
                                    tickAmount: 1,
                                    labels: {
                                        offsetX: -10,
                                        // minWidth: 50,
                                        show: true,
                                        style: {
                                            fontSize: '9px',
                                            colors: '#60687C'
                                        },
                                        formatter: (value) => { 
                                            if(Number(value) === value && value % 1 === 0) {
                                                return Math.ceil(value.toFixed(1))
                                            } else {
                                                return Math.ceil(value.toFixed(1))
                                            }
                                        },
                                        // offsetX: 0,
                                        // offsetY: 0,
                                    }
                                },
                                grid: {
                                    show: true,
                                    borderColor: '#2D323E',
                                    strokeDashArray: 0,
                                    position: 'back',
                                    xaxis: {
                                        lines: {
                                            show: true
                                        },
                                        labels: {
                                            style: {
                                                colors: ['#FFF'],
                                            }
                                        },
                                        axisTicks: {
                                            show: false
                                        }
                                    },   
                                    yaxis: {
                                        lines: {
                                            show: false
                                        },
                                        labels: {
                                            style: {
                                                colors: ['#969AA7'],
                                            }
                                        }
                                    },                
                                    padding: {
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        left: 0
                                    },
                                },
                                annotations: annotations,
                            }
                        }
                        this.setState({ [key+'_metrics_series_'+asst.asset_name]: series, [key+'_metrics_options_'+asst.asset_name]: options, loadingMetrics: false, selectedAsset })
                    })
                    
                })
            }
        })
        // this.props.menuDisabled()
    }

    getConvertedUnit = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = getConvertedBytesUnit(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                // val = getConvertedSecondsUnit(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                // val = getConvertedBitsUnit(val, unit)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                // val = getConvertedCountFormater(val)
            } else {
                val = ""
            }
        } else if(val !== '') {           
            val = ""
        }
        return val
    }

    getGrapUnitValue = (val ,unit, valueType, convertTo) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseInt(val))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                if(convertTo) {
                    val = convertBytesTo(val, unit, valueType, convertTo)
                } else {
                    val = convertBytes(val, unit, valueType)
                }                         
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit, valueType, convertTo)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit, valueType, convertTo)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    getGrapUnitValueWithoutUnit = (val ,unit, valueType) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytesWithoutUnit(val, unit, valueType)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSecondsWithoutUnit(val, unit, valueType)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBitsWithoutUnit(val, unit, valueType)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                val = countFormaterWithoutUnit(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseInt(val))
        }
        return val
    }

    render() {
        return (
            <div className="rounded bg-dark mt-2">
                <div className="d-flex mb-1">
                    <p className="f12 m-0 align-self-center">Showing metrics for the period of</p>
                    <p className="f12 mb-0 align-self-center text-info ml-2">{this.state.startTime ? momentConvertionUtcToLocalTime(this.state.startTime, 'DD MMM HH:mm') +' - '+ momentConvertionUtcToLocalTime(this.state.endTime, 'DD MMM YYYY HH:mm') : ''}</p>
                </div>
                {this.state.loadingMetrics ?
                    <div className='d-flex justify-content-center m-4'>
                        <Spinner className='text-center' color='white' size='lg' />
                    </div>
                : this.state.assetDetails && this.state.assetDetails.length ?
                    <React.Fragment>
                        <div className="d-flex mb-1">
                            {this.state.assetDetails.map((asst) => {
                                return(
                                    <div className={`rounded cursorPointer mr-2 ${this.state.selectedAsset === asst.asset_name ? 'bg-gray3 text-dark' : 'text-info bg-muted'} px-2 py-1 f12 hiddenEllipses`} id={`metricsTitle_`+asst.asset_name} onClick={() => this.setState({ selectedAsset: asst.asset_name })}>
                                        {this.props.details && this.props.details.asset_name ? this.props.details.asset_name : (this.props.details && this.props.details.assetName ? this.props.details.assetName : '')}
                                    </div>
                                )
                            })}
                        </div>
                        <div className="py-2"> {/*className metricsTabScroll */}
                            {this.state['metrics_data_'+this.state.selectedAsset] && this.state['metrics_data_'+this.state.selectedAsset].length ? 
                                this.state['metrics_data_'+this.state.selectedAsset].map(item => {
                                    return (
                                        Object.keys(item.data).map((key, i) => {
                                            return (
                                                <div className="p-3 border rounded bg-dark3 mb-2">
                                                    <p className="text-white f16 p-1 mb-0">{key}</p>
                                                {this.state[key+'_metrics_series_'+this.state.selectedAsset] && this.state[key+'_metrics_series_'+this.state.selectedAsset].length && this.state[key+'_metrics_options_'+this.state.selectedAsset] ?
                                                        <div className="cursorPointer transparentTooltip mb-n4 mt-n4">
                                                            <Chart options={this.state[key+'_metrics_options_'+this.state.selectedAsset]} series={this.state[key+'_metrics_series_'+this.state.selectedAsset]} type="area" height={115}/>
                                                        </div>
                                                    : 
                                                        <div style={{minHeight:"85px"}} className="d-flex align-self-center justify-content-center">
                                                            <div className='align-self-center'>
                                                                No Data
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })
                                    )
                                })
                            : 
                                <div className='d-flex justify-content-center m-4'>
                                    <p>There are no metrics for the selected Asset.</p>
                                </div>
                            }
                        </div>
                    </React.Fragment>
                : 
                    <div className='d-flex justify-content-center m-4'>
                        <p>There are no metrics for the selected Asset.</p>
                    </div>
                }
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
MetricsTab.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log('errorDashboard',state)
    return {
        errorEventPageMonitoringList: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.errorEventPageMonitoringList ? state.aiops.aiopsPropsDetails.errorEventPageMonitoringList : {},
        alertPageMonitoringList: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.alertPageMonitoringList ? state.aiops.aiopsPropsDetails.alertPageMonitoringList : {},
    }
}

export default connect(mapStateToProps, {
    setAiopsPropsDetails,
    getMonitoringMetricsByTrend,
})(withRouter(MetricsTab))