/*************************************************
 * Tvastar
 * @exports
 * @file DashboardSidePanel.js
 * @author Prakash // on 26/12/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _, { filter } from 'lodash'
// import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import Charts from 'react-apexcharts'

import { listAllAccounts, listAllRegions } from '../../actions/commonAction'
import { listGovernanceResults, governanceGetDayWiseCount } from '../../actions/governance/newGovernanceAction'

import { capitalizeFirstLetter, momentConvertionUtcToLocalTime } from '../../utils/utility'
import { DURATION_FILTER_OPTIONS } from '../../utils/constants'
import ResizeableDarkThemeTable from '../designComponents/Table/ResizeableDarkThemeTable'

const statusOption = [
	{value: "", label: "All"},
	{value: "pending", label: "Pending"},
	{value: "reoccured", label: "Reoccured"},
	{value: "Resolved", label: "Resolved"},
]

class DashboardSidePanel extends Component {
	constructor(props) {
		super(props)
        this.toggleRef = React.createRef()
		this.state = {
            minMaxHeight: 700,
			duration: "+30d"
		}
	}

	componentDidMount = () => {
		document.addEventListener('keydown', this._handleKeyDown)
		this.getWindowSize()
		window.addEventListener('resize', () => {
            this.getWindowSize()
        })

		this.onSearch()
    }

	onSearch = () => {
		this.listGovernanceResults()
		this.governanceGetDayWiseCount()
	}

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
        let headerHeihgt = 100
		let outerHeight = (window.outerHeight - browserBorder - headerHeihgt)

		this.setState({ outerHeight, minMaxHeight: outerHeight })
	}

	listGovernanceResults = () => {
		console.log("this.props.selectedViolation", this.props.selectedViolation)
		if(this.props.selectedViolation && this.props.selectedViolation.asset_arn) {
			let params = {
				"provider": this.props.selectedViolation && this.props.selectedViolation.provider ? this.props.selectedViolation.provider : "aws",
				"duration": this.state.duration,
				"asset_arn":  this.props.selectedViolation.asset_arn
			}
			this.props.listGovernanceResults(params, (promise, response) => {
				if (promise) {
					console.log("response", response)
					this.setState({ governanceResults: response, filterGovernanceResults: response })
				} else {
					this.setState({ governanceResults: [], filterGovernanceResults: [] })
				}
			})	
		}
	}

	getStatusCount = from  => {
		let passCount = 0
		if (this.state.governanceResults && this.state.governanceResults.length) {
			let relatedRiskCount = []
			this.state.governanceResults.length &&
			_.chain(this.state.governanceResults)
			.groupBy('status')
			.map((value, key) => ({ label: key, sub: value }))
			.value()
			.forEach((risk, i) => {
				relatedRiskCount.push(risk)
				// risk.sub.forEach((item, i) => {
				// 	relatedRiskCount.push(item)
				// })
			})
			relatedRiskCount.forEach(item => {
				if(item.label === from) {
					passCount = item.sub.length
				}
			})
		}
		return passCount
	}

	findRisk = from => {
		let riskCount = 0
		if (this.state.governanceResults && this.state.governanceResults.length) {
			let filteredData = this.state.governanceResults
			//filteredData = filteredData.filter(item => item.status !== 'NA')
			let relatedRiskCount = []
			filteredData.length &&
			_.chain(filteredData)
			.groupBy('risk')
			.map((value, key) => ({ label: key, sub: value }))
			.value()
			.forEach((risk, i) => {
				relatedRiskCount.push(risk)
				// risk.sub.forEach((item, i) => {
				// 	relatedRiskCount.push(item)
				// })
			})
			relatedRiskCount.forEach(item => {
				if(item.label === from) {
					riskCount = item.sub.length
				}
			})
			// const highArray = this.state.governanceResults.filter(item => item.risk === from)
			// riskCount = highArray.length
		}
		return riskCount
	}

	getRelatedTableData = (category, selectedResourceStatus, status) => {
		
		let filterGovernanceResults = this.state.governanceResults
		if(category !== '' && category !== undefined) {
			filterGovernanceResults = filterGovernanceResults.filter(item => item.category.includes(category))
		} 
		if(selectedResourceStatus !== '' && selectedResourceStatus !== undefined) {
			filterGovernanceResults = 
			filterGovernanceResults.filter(item => item.resolution_status_info ? 
				item.resolution_status_info.status === selectedResourceStatus ? 
				item.resolution_status_info.status === selectedResourceStatus :
					item.resolution_status_info.status === item.resolution_status_info.sub_status ? 
					item.resolution_status_info.status === selectedResourceStatus : 
					item.resolution_status_info.sub_status === selectedResourceStatus 
			: item.status === ''
				// ? selectedResourceStatus === 'reoccured' ? 
				// 	item.status === '' : 
				// 	selectedResourceStatus === 'resolved' ? 
				// : item.status === 'pass'
			)
		}
		if(status !== '' && status !== undefined) {
			filterGovernanceResults = filterGovernanceResults.filter(item => item.status === status)
		} 

		this.setState({ filterGovernanceResults })
	}

	governanceGetDayWiseCount = () =>{
		if(this.props.selectedViolation && this.props.selectedViolation.asset_arn) {
			let params = {
				"provider": this.props.selectedViolation && this.props.selectedViolation.provider ? this.props.selectedViolation.provider : "aws",
				"duration": this.state.duration,
				"asset_arn":  this.props.selectedViolation.asset_arn
			}
			this.props.governanceGetDayWiseCount(params, (promise, dayWiseCountGraphData) => {
				if (promise) {
					this.setState({ dayWiseCountGraphData },
						() => this.showApexAssetRecentGraph()
					)
				}
			})	
		}
	}

	showApexAssetRecentGraph = () => {
		let graphLabel = []
		let createdGraphData = []
		let modifiedGraphData = []
		let fontColors = []
		let startEndLabels = []
		this.state.dayWiseCountGraphData.labels.forEach((item, x) => {
			graphLabel.push(momentConvertionUtcToLocalTime(item, "DD MMM"))
			if(x === 0 || x === (this.state.dayWiseCountGraphData.labels.length - 1)) {
				startEndLabels.push(momentConvertionUtcToLocalTime(this.state.dayWiseCountGraphData.labels[x], "DD MMM"))
			}
		})
		let data1 = []	
		this.state.dayWiseCountGraphData.data && this.state.dayWiseCountGraphData.data.pass && this.state.dayWiseCountGraphData.data.pass.forEach((item, x) => {
			createdGraphData.push(item)
			let dataItems1 = {}
			dataItems1.x = momentConvertionUtcToLocalTime(this.state.dayWiseCountGraphData.labels[x], "DD MMM")
			dataItems1.y  = item
			data1.push(dataItems1)

			fontColors.push('#EDEDED')
		})

		let data2 = []
		this.state.dayWiseCountGraphData.data && this.state.dayWiseCountGraphData.data.fail && this.state.dayWiseCountGraphData.data.fail.forEach((item, x) => {
			modifiedGraphData.push(item)
			let dataItems2 = {}
			dataItems2.x = momentConvertionUtcToLocalTime(this.state.dayWiseCountGraphData.labels[x], "DD MMM")
			dataItems2.y  = item
			data2.push(dataItems2)
		})

		let series = []
		let dataRow = {}
		dataRow.data = data1
		dataRow.name = 'pass'
		dataRow.labels = graphLabel
		series.push(dataRow)

		dataRow = {}
		dataRow.data = data2
		dataRow.name = 'fail'
		dataRow.labels = graphLabel
		series.push(dataRow)
		
		let options = {
			chart: {
			height: 100,
			type: 'area',
			zoom: {
				enabled: false
			},
			sparkline: {
				enabled: false
			},
			toolbar: {
				show: false,
			}
			},
			legend: {
				show: false
			},
			dataLabels: {
			enabled: false
			},
			stroke: {
				show: true,
				curve: 'smooth',
				lineCap: 'butt',
				colors: undefined,
				width: 2,
				dashArray: 0,      
			},
			title: {
			text: ''
			},
			grid: {
				xaxis: {
					lines: {
						show: false
					},
				},
				yaxis: {
					lines: {
						show: false
					}
				},
			},			
			yaxis: {
				labels: {
					show: true,
					style: {
						colors: fontColors
					},
					formatter: function(value) {
						return parseInt(value)
					}
				},
				tickAmount: 1
			},
			labels: graphLabel,
			xaxis: {
				Categories: [graphLabel],
				show: true,
				labels: {
					rotate: 0,
					show: true,
					formatter: function(value) {
						if(value === startEndLabels[0] || value === startEndLabels[1]) {
							return value
						}
					},
					style: {
						colors: fontColors
					},
					axisTicks: {
						show: false
					}
				},
				tooltip: {
					enabled: false
				},
				// min: min,
				// max: max,
			},
			colors: ['#FFD200', '#B96422'],
			// colors: ['#09D261', '#F44336'],
			// fill: {
			// 	colors: ["#CEFBFF"],
			// 	opacity: 0.7,
			// 	type: 'solid',
			// 	//opacity: 0.5,
			// },
			tooltip: {
				enabled: true,
				enabledOnSeries: false,
				shared: false,
				followCursor: false,
				intersect: false,
				inverseOrder: false,
				custom: function({series, seriesIndex, dataPointIndex, w}) {
					// let val  = series[seriesIndex][dataPointIndex];
					// //let val  = series[seriesIndex][dataPointIndex];
					// let labelName = w.globals.initialSeries[0].labels[dataPointIndex]
					// //let label = w.globals.initialSeries[seriesIndex][dataPointIndex]
					// //val = Math.abs(Math.round(val))
					// //let labelName = w.globals.categoryLabels[dataPointIndex]
					// //let val = str.replace('-',series[seriesIndex][dataPointIndex]);
					// return '<div class="arrow_box"> <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val +
					// '</div>'

					let labelName = w.globals.initialSeries[0].labels[dataPointIndex]

					let returnData = '<div class="metricsDetailCurrentTooltip">'
					if(w.globals.initialSeries && w.globals.initialSeries.length) {
						w.globals.initialSeries.forEach((item, index) => {
							if(!index) {
								returnData += '<div class="apexcharts-tooltip-title f12 border-bottom-black5">'+labelName+'</div>'
							}
							returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+item.name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+item.data[dataPointIndex].y+'</span></div></div></div>'
						})
					}

					returnData += '</div>'
					return returnData
				},
				fillSeriesColor: false,
				theme: false,
				style: {
				fontSize: '12px',				
				},
				onDatasetHover: {
					highlightDataSeries: false,
				},
				x: {
					show: false,
					format: 'dd MMM',
					formatter: undefined,
				},
				y: {
					show: false,
					formatter: undefined,
					title: {
						formatter: (seriesName) => seriesName,
					},
				},
				marker: {
					show: false,
				}
			}

		}

		console.log("series", series)
		console.log("options", options)
		this.setState({ recentSeries: series, recentOptions: options });
	
	}

    handleClickOutside(event) {
    }
	
	render() {		
		return (
			<div className="advanced-search" style={{zIndex:9999999}} onClick={(event) => this.handleClickOutside(event)}>
				<div className={`loaderOverlay ${this.state.queryLoading ? "" : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
				<div className="search-content bg-muted w-60">
					<div className="header-search bd-highlight d-flex justify-content-between">
						<div className="flex-fill bd-highlight">
							<h5>Violation Details</h5>
							<div className="mr-2 d-flex">
							</div>
						</div>

						<div className="text-right flex-fill bd-highlight align-self-center">
							<i className="far fa-times cursorPointer" onClick={() => this.props.closeSidePanel()}></i>
						</div>
					</div>
					<div className={`${!this.state.showLoading ? 'overflow-auto' : ''} p-2`}>
						{/* <div className="d-flex justify-content-between">
							<ul className="nav nav-tabs border-bottom bd-highlight bg-muted shadow-none mb-0">
								<li className="nav-item">
									<span
										className={`nav-link text-white cursor-pointer f12 ${this.state.selectedTab === "Json" ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
										onClick={() => this.setState({ selectedTab: "Json" }, () => this.generateIamPolicy())}
									>
									Json
									</span>
								</li>
								<li className="nav-item">
									<span 
										className={`nav-link text-white cursor-pointer f12 ${this.state.selectedTab === "Poilcy" ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
										onClick={() => this.setState({ selectedTab: "Poilcy" })}
									>
										Policy
									</span>
								</li>
							</ul>
							<div className="select-sm-bg curvedDropdown minWidth120 mr-2">
								{this.state.selectedTab === "Poilcy" ?
									<div className="dropdown">
										<span className="dropdown-toggle d-flex justify-content-between px-2 text-white" ref={this.toggleRef}>
											{this.state.selectedModeType ? (this.state.modeTypes.filter(e => e.value === this.state.selectedModeType).length ? this.state.modeTypes.filter(e => e.value === this.state.selectedModeType)[0].label : this.state.selectedModeType) : <span className="placeholder">Select</span>}
										</span>
										<ul className={`dropdown-menu p-0 ${this.state.showModeOptions ? "show" : ''}`}>
											{this.state.modeTypes.map(item => {
												return (
													<li 
														onClick={() => 
															this.setState({ 
																showModeOptions: false,
																selectedModeType: item.value,
																groupPolicies: []
															}, () => this.structureServices())
														}
														className={`${this.state.selectedModeType === item.value ? "selected" :  ""}`}
													>
														{item.label}
													</li>
												)
											})}
										</ul>
									</div>
								: null}
							</div>
						</div> */}
						
						<div className="rounded dlpDescriptionForm overflowYAuto" style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
							{/* <div className="d-flex mt-3">
								<div className={`w-100`}> */}
									<div className="accordion" id="accordionExample">
										<div className={`card bg-dark border-0 mb-2 overflow-unset p-3`}>
											<div className={`${this.state.showViolationDetails ? "mb-2" : ""} d-flex justify-content-between`} onClick={() => this.setState({ showViolationDetails: !this.state.showViolationDetails })}>
												<div className="text-white"><span className={`far ${this.props.showViolationDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Details</div>
												{/* <div className="text-info">Showing event details</div> */}
											</div>
                            				<div className={`collapse ${this.state.showViolationDetails ? 'show' : ''}`}>
												<div className="d-flex justify-content-between">
                                                    <div>
                                                        <p className="mb-0 f16 text-white">{this.props.selectedViolation && this.props.selectedViolation.policy_name ? this.props.selectedViolation.policy_name : <span>&nbsp;</span>}</p>
                                                        <p className="mb-0 f12">{this.props.selectedViolation && this.props.selectedViolation.description ? this.props.selectedViolation.description : <span>&nbsp;</span>}</p>
                                                    </div>
                                                    <div className="ml-3">
                                                        <p className="b-block mb-0">Severity</p>
                                                        <span className={`mr-2 badge ${this.props.selectedViolation && this.props.selectedViolation.risk && this.props.selectedViolation.risk.toLowerCase() !== 'nan' ? 'risk-badge-'+this.props.selectedViolation.risk.toLowerCase() : 'badge-secondary' }`}>  
                                                            {this.props.selectedViolation && this.props.selectedViolation.risk ? capitalizeFirstLetter(this.props.selectedViolation.risk) : 'Unknown'}
                                                        </span>
                                                    </div>
                                                </div>
												<div className="d-flex mt-2 border-top">
													<div className="py-1 w-50">
														<p className="b-block mb-0">Time</p>
														<p className="mb-0 text-white">{this.props.selectedViolation && this.props.selectedViolation.timestamp ? momentConvertionUtcToLocalTime(this.props.selectedViolation.timestamp, 'DD MMM YYYY HH:mm:ss') : <span>&nbsp;</span>}</p>
													</div>
													<div className="py-1 w-50 pl-3">
														<p className="b-block mb-0">Status</p>
														{this.props.selectedViolation && this.props.selectedViolation.status ? 
															<small className={`mr-1 badge ${this.props.selectedViolation.status === 'pass' ? 'badge-pass' : 'badge-fail'}`}>{this.props.selectedViolation.status}</small>
														: null}
													</div>
												</div>
												<div className="d-flex mt-2 border-top">
													<div className="py-1 w-50">
														<p className="b-block mb-0">Provider / Account / Region</p>
														<p className="mb-0 text-white">
															{this.props.selectedViolation && this.props.selectedViolation.provider ? this.props.selectedViolation.provider.toUpperCase() : <span>&nbsp;</span>}
															{this.props.selectedViolation && this.props.selectedViolation.account_id ? (' : ') +this.props.selectedViolation.account_id : <span>&nbsp;</span>}
															{this.props.selectedViolation && this.props.selectedViolation.region ?  (' : ')+this.props.selectedViolation.region : <span>&nbsp;</span>}
														</p>
													</div>                                    
													<div className="py-1 w-50 pl-3">
														<p className="b-block mb-0">Service</p>
														<p className="mb-0 text-white">
															{this.props.selectedViolation && this.props.selectedViolation.resource_type ? this.props.selectedViolation.resource_type : <span>&nbsp;</span>}
															{this.props.selectedViolation && this.props.selectedViolation.service_name ?  (' : ')+this.props.selectedViolation.service_name : <span>&nbsp;</span>}
														</p>
													</div>
												</div>
												<div className="d-flex mt-2 border-top">
													<div className="py-1 w-50">
														<p className="b-block mb-0">Asset Arn</p>
														<p className="mb-0 text-white">{this.props.selectedViolation && this.props.selectedViolation.asset_arn ? this.props.selectedViolation.asset_arn : <span>&nbsp;</span>}</p>
													</div>
													<div className="py-1 w-50 pl-3">
														<p className="b-block mb-0">Category</p>
														{this.props.selectedViolation && this.props.selectedViolation.category ? 
															this.props.selectedViolation.category.map(item => {
																return (
																	<small className={`badge badge-secondary mr-1`}>{item}</small>
																)
															}) 
														: null}
													</div>
												</div>	
											</div>
										</div>
										
										<div className={`card bg-dark border-0 mb-2 overflow-unset p-3 mt-2`}>
											<div className='d-flex justify-content-between'>
												<div className='w-65 float-left align-self-center pt-1'>
													<p className="mb-0 f12 text-white">Resource checks count grouped by status, risk</p>
												</div>
												<div className="select-sm-bg curvedDropdown minWidth120">
													<div className="dropdown">
														<span className="dropdown-toggle d-flex justify-content-between px-2 text-white" onClick={() => this.setState({ durationFilter: !this.state.durationFilter })}>
															{this.state.duration ? DURATION_FILTER_OPTIONS.filter(e => e.value === this.state.duration)[0].option : <span className="placeholder">Select</span>}
															<span className="caret"></span>
														</span>
														<ul className={`dropdown-menu p-0 ${this.state.durationFilter ? "show" : ''}`}>
															{DURATION_FILTER_OPTIONS.map(item => {
																return (
																	<li onClick={() => this.setState({ 
																			duration: item.value, 
																			durationFilter: false
																		}, 
																			() => this.onSearch()
																		)}
																	>
																		{item.option}
																	</li>
																)
															})}
														</ul>
													</div>
												</div>
											</div>
											<div className="mt-3 mb-4">
												<div className='p-2 bg-dark3 rounded d-flex'>
													<div className='mr-2 mt-1 d-flex'>
														<div className='count border-warning ml-3 mr-3'>
															<h5 className='text-white m-0'>{this.getStatusCount('pass')}</h5>
															<p className='text-white m-0'>
																<small>Pass</small>
															</p>
														</div>
														<div className='count border-color-dark-theme-orange'>
															<h5 className='text-white m-0'>{this.getStatusCount('fail')}</h5>
															<p className='text-white m-0'>
																<small>Fail</small>
															</p>
														</div>
														{/* <div className='count border-warning'>
															<h5 className='text-dark m-0'>{this.getStatusCount('NA')}</h5>
															<p className='text-dark m-0'>
																<small>NA</small>
															</p>
														</div> */}
													</div>
													<div className='d-flex bd-highlight ml-auto align-self-center pr-3'>
														<div className='mr-2'>
															<span className='mb-0'>
																<p className='m-2'>
																	<small className='d-flex align-items-center'>
																		<span className={`badge risk-badge-low ${this.findRisk('Low') < 10 ? 'ml-2' : this.findRisk('Low') === 100 ? '' : 'ml-1'}  mr-1`}>{this.findRisk('Low') }</span>
																		Low
																	</small>
																</p>
																<p className='m-2'>
																	<small className='d-flex align-items-center'>
																		<span className={`badge risk-badge-medium ${this.findRisk('Medium') < 10 ? 'ml-2' : this.findRisk('Medium') === 100 ? '' : 'ml-1'}  mr-1`}>{this.findRisk('Medium') }</span>
																		Medium
																	</small>
																</p>
															</span>
														</div>
														<div>
															<span className='mb-0'>
																<p className='m-2'>
																	<small className='d-flex align-items-center'>
																		<span className={`badge risk-badge-high ${this.findRisk('High') < 10 ? 'ml-2' : this.findRisk('High') === 100 ? '' : 'ml-1'}  mr-1`}>{this.findRisk('High') }</span>
																		High
																	</small>
																</p>
																<p className='m-2'>
																	<small className='d-flex align-items-center'>
																		<span className={`badge risk-badge-critical ${this.findRisk('Critical') < 10 ? 'ml-2' : this.findRisk('Critical') === 100 ? '' : 'ml-1'}  mr-1`}>{this.findRisk('Critical') }</span>
																		Critical
																	</small>
																</p>
															</span>
														</div>
													</div>
												</div>
											</div>
											<p className='mb-0 text-white'>Trends</p>
											<p className='mb-2 f12'>Resource level checks pass / fail trend</p>
											<div className='p-2 bg-dark3 rounded'>
												{this.state.recentSeries && this.state.recentSeries.length ? (
													<div className='mt-n4 mb-n4 transparentTooltip'>
														<Charts type="area" series={this.state.recentSeries} options={this.state.recentOptions} height={240}/>
													</div>
												) : null}
											</div>
										</div>
										{console.log("this.state.filterGovernanceResults", this.state.filterGovernanceResults)}
										<div className={`card bg-dark border-0 mb-2 overflow-unset p-3 mt-2`}>
											<p className='mb-0 text-white'>Related Violations</p>
											<div className='w-100 d-flex mb-1 justify-content-between'>
												<div className='d-flex'>
													<div className="select-sm-bg curvedDropdown minWidth120">
														<div className="dropdown">
															<span className="dropdown-toggle d-flex justify-content-between px-2 text-white" onClick={() => this.setState({ issueStatusFilter: !this.state.issueStatusFilter })}>
																{this.state.selectedResourceStatus ? statusOption.filter(e => e.value === this.state.selectedResourceStatus)[0].label : <span className="placeholder">Status</span>}
																<span className="caret"></span>
															</span>
															<ul className={`dropdown-menu p-0 ${this.state.issueStatusFilter ? "show" : ''}`}>
																{statusOption.map(item => {
																	return (
																		<li 
																			onClick={() => this.setState({ 
																				selectedResourceStatus: item.value, 
																				issueStatusFilter: false
																			}, 
																				() => this.getRelatedTableData(this.state.selectedCategory, this.state.selectedResourceStatus, this.state.selectedStatus)
																			)}
																		>
																			{item.label}
																		</li>
																	)
																})}
															</ul>
														</div>
													</div>
													{/* <div className="select-sm-bg curvedDropdown minWidth120 ml-2">
														<div className="dropdown">
															<span className="dropdown-toggle d-flex justify-content-between px-2 text-white" onClick={() => this.setState({ issueCategoryFilter: !this.state.issueCategoryFilter })}>
																{this.state.selectedCategory ? this.state.selectedCategory : <span className="placeholder">Category</span>}
																<span className="caret"></span>
															</span>
															<ul className={`dropdown-menu p-0 ${this.state.issueCategoryFilter ? "show" : ''}`}>
																<li 
																	onClick={() => this.setState({ 
																		selectedResourceStatus: "", 
																		issueCategoryFilter: false
																	}, 
																		() => this.props.getRelatedTableData(this.state.selectedCategory, this.state.selectedResourceStatus, this.state.selectedStatus)
																	)}
																	className={`${this.state.selectedResourceStatus === "" ? "selected" :  ""}`}
																>
																	All
																</li>
																{this.props.categoryCounts &&
																this.props.categoryCounts.length &&
																this.props.categoryCounts.map((item, index) => {
																	return (
																		<li 
																			onClick={() => this.setState({ 
																				selectedCategory: item.category, 
																				issueCategoryFilter: false
																			}, 
																				() => this.props.getRelatedTableData(this.state.selectedCategory, this.state.selectedResourceStatus, this.state.selectedStatus)
																			)}
																			className={`${this.state.selectedCategory === item.category ? "selected" :  ""}`}
																		>
																			{item.category}
																		</li>
																	)
																})}
															</ul>
														</div>
													</div> */}
												</div>
												<div className='d-flex'>
													<div className="align-self-center">
														<span onClick={() => this.setState({ selectedStatus: this.state.selectedStatus === 'pass' ? '' : 'pass' },this.getRelatedTableData(this.state.selectedCategory, this.state.selectedResourceStatus, this.state.selectedStatus === 'pass' ? '' : 'pass' )) } className={`cursor-pointer badge mr-2 ${this.state.selectedStatus === 'pass' ? 'badge-pass' : 'badge-outline-pass'} `}>Pass</span>
													</div>
													<div className="align-self-center">
														<span onClick={() => this.setState({ selectedStatus: this.state.selectedStatus === 'fail' ? '' : 'fail' }, this.getRelatedTableData(this.state.selectedCategory, this.state.selectedResourceStatus, this.state.selectedStatus === 'fail' ? '' : 'fail' )) } className={`cursor-pointer badge mr-2 ${this.state.selectedStatus === 'fail' ? 'badge-fail' : 'badge-outline-fail'}`}>Fail</span>
													</div>
												</div>
											</div>
											{this.state.filterGovernanceResults && this.state.filterGovernanceResults.length && !this.state.showLoading ? (
												<ResizeableDarkThemeTable
													columns={[
														{
															Header: 'Risk',
															accessor: 'risk',
															Cell: cellInfo => (
																<span className={`badge risk-badge-${
																	cellInfo.row.original.risk ?
																		cellInfo.row.original.risk.toLowerCase()
																	: ''
																	} aiopsTableBadgeWidth`}
																>
																	{cellInfo.row.original.risk ?
																		(cellInfo.row.original.risk.toLowerCase() === 'critical' ? 
																			'C' 
																		: cellInfo.row.original.risk.toLowerCase() === 'high' ? 
																			'H'
																		: cellInfo.row.original.risk.toLowerCase() === 'medium' ? 
																			'M'
																		: cellInfo.row.original.risk.toLowerCase() === 'low' ? 
																			'L' 
																		: '') 
																	: 'U'}
																</span>
															),
															width: 80
														},
														// {
														// 	Header: ' ',
														// 	accessor: 'risk',
														// 	Cell: cellInfo => (
														// 		<div className={`risk-line risk-bg-${cellInfo.row.original.risk ? cellInfo.row.original.risk.toLowerCase() : ''}`} />
														// 	),
														// 	width: 10
														// },
														{
															Header: 'Date',
															// accessor: 'original.resolution_status_info.timestamp',
															//accessor: request => displayDate(request.requestTime),
															accessor: 'timestamp',
															Cell: cellInfo => (
																<span>
																	{cellInfo.row.original.status && cellInfo.row.original.status !== 'pass' ? cellInfo.row.original.resolution_status_info && cellInfo.row.original.resolution_status_info.timestamp && momentConvertionUtcToLocalTime(cellInfo.row.original.resolution_status_info.timestamp, "DD MMM YYYY HH:mm") : momentConvertionUtcToLocalTime(cellInfo.row.original.timestamp, "DD MMM YYYY HH:mm")}
																</span>
															),
															width: 180
														},
														{
															Header: 'Provider / Service',
															accessor: d =>
																d.provider + ' : ' + d.account_id + ' : ' + d.region,
															Cell: cellInfo => (
																<React.Fragment>
																<span className=''>
																	{cellInfo.row.original.provider + ' : ' +cellInfo.row.original.account_id + ' : ' + (cellInfo.row.original.region === 'NA' ? 'Global' : cellInfo.row.original.region) + ' / '+ cellInfo.row.original.service_name}
																</span>
																</React.Fragment>
															),
															width: 250
														},
														{
															Header: 'Status',
															accessor: 'status',
															Cell: cellInfo => (
																<span
																	className={`badge status-badge badge-outline-${
																		cellInfo.row.original.status === 'pass' ? 'pass' : 'fail'
																		} badge-pill`}
																>
																	{capitalizeFirstLetter(cellInfo.row.original.status)}
																</span>
															),
															width: 100,
														},
														{
															Header: 'Check',
															accessor: 'title',
															width: 270
														},
														{
															Header: 'Category',
															accessor: d =>
																Array.isArray(d.category) ? 
																	d.category.map((item, index) => (d.category.length - 1 === index ? item : item + ', '))
																: d.category,
															width: 200
														},
														{
															Header: ' ',
															accessor: ' ',
															width:1
														}
													]}
													data={this.state.filterGovernanceResults}
													onClickRow={tableRow => {}}
													selectedRecord={this.state.selectedTableRow}
													perPage={20}
													dashboard={this.state.filterGovernanceResults && this.state.filterGovernanceResults.length ? true : false}
													sortByColumn={"timestamp"}
													tooltipFor={[2,3,4]}
													riskTooltip={[0]}
													tableBody={"bg-dark3"}
													rounded={true}
													// selectedColumn={'bg-light'}
													// columnFontSize={'f12'}
												/>
											) : (!this.state.showLoading && this.state.filterGovernanceResults && this.state.filterGovernanceResults.length) ||
												(this.state.filterGovernanceResults && !this.state.filterGovernanceResults.length && !this.state.showLoading) ? (
													<div className='d-flex justify-content-center m-4'>
														<p>There are no data on this criteria. Please try adjusting your filter.</p>
													</div>
												) 
											: null}
										</div>
									</div>
								{/* </div>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
DashboardSidePanel.propTypes = {}

const mapStateToProps = state => {
	// console.log('observability query page',state)
	return {
		accounts: state.filters.accounts,
		regions: state.filters.regions,
	}
}

export default connect(mapStateToProps, {
	listAllAccounts,
	listAllRegions,
	listGovernanceResults,
	governanceGetDayWiseCount,
})(withRouter(DashboardSidePanel))