/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file EventsTab.js
 * @author Prakash // on 07/06/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { listObservabilityEvents } from '../../../actions/aiops/ObservabilityAction'
import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'

import _ from 'lodash'
import { CSVLink } from "react-csv";
import { momentDateGivenFormat, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord } from '../../../utils/utility'

import Search from '../../common/SearchComponent'
import ApexTrendBarChart from '../../common/charts/ApexTrendBarChart'

class EventsTab extends Component {
    constructor(props) {
        super(props)
        this.toggleRef = React.createRef()
        
        this.state = {
            eventListLoader: true,
            
            startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
			perPage: 100,
        }
    }

	componentDidMount = () => {
        if(this.props.leftSectionSelection && Object.entries(this.props.leftSectionSelection).length) {
            this.setState({ 
                start_time: this.props.leftSectionSelection.start_time,
                end_time: this.props.leftSectionSelection.end_time,
                brushStartTime: this.props.leftSectionSelection.brushStartTime ? this.props.leftSectionSelection.brushStartTime : this.props.leftSectionSelection.start_time,
                brushEndTime: this.props.leftSectionSelection.brushEndTime ? this.props.leftSectionSelection.brushEndTime : this.props.leftSectionSelection.end_time,
                selectedShowBy: this.props.leftSectionSelection.selectedShowBy ? this.props.leftSectionSelection.selectedShowBy : "",
                columnValue: this.props.leftSectionSelection.columnValue ? this.props.leftSectionSelection.columnValue : "",
                selectedField: this.props.leftSectionSelection.selectedField ? this.props.leftSectionSelection.selectedField : "",
                allFields: this.props.leftSectionSelection.allFields ? this.props.leftSectionSelection.allFields : "",
                selectedGroup: this.props.leftSectionSelection.selectedGroup ? this.props.leftSectionSelection.selectedGroup : "",
                pageType: this.props.leftSectionSelection.pageType ? this.props.leftSectionSelection.pageType : "",
                eventListLoader: true,
                graphData: {},
                eventsList: [],
                filteredArray: [],
            },
                () => {
                    this.listObservabilityEvents()
                }
            )
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.leftSectionSelection && Object.entries(this.props.leftSectionSelection).length) {
            if(!_.isEqual(prevProps.leftSectionSelection, this.props.leftSectionSelection)) {
                this.setState({ 
                    start_time: this.props.leftSectionSelection.start_time,
                    end_time: this.props.leftSectionSelection.end_time,
                    brushStartTime: this.props.leftSectionSelection.brushStartTime ? this.props.leftSectionSelection.brushStartTime : this.props.leftSectionSelection.start_time,
                    brushEndTime: this.props.leftSectionSelection.brushEndTime ? this.props.leftSectionSelection.brushEndTime : this.props.leftSectionSelection.end_time,
                    selectedShowBy: this.props.leftSectionSelection.selectedShowBy ? this.props.leftSectionSelection.selectedShowBy : "",
                    columnValue: this.props.leftSectionSelection.columnValue ? this.props.leftSectionSelection.columnValue : "",
                    selectedField: this.props.leftSectionSelection.selectedField ? this.props.leftSectionSelection.selectedField : "",
                    allFields: this.props.leftSectionSelection.allFields ? this.props.leftSectionSelection.allFields : "",
                    selectedGroup: this.props.leftSectionSelection.selectedGroup ? this.props.leftSectionSelection.selectedGroup : "",
                    pageType: this.props.leftSectionSelection.pageType ? this.props.leftSectionSelection.pageType : "",
                    eventListLoader: true,
                    eventsList: [],
                    filteredArray: [],
                },
                    () => {
                        this.listObservabilityEvents()
                    }
                )
            } else if(this.props.dashboardDetails && Object.entries(this.props.dashboardDetails).length) {
                if(!_.isEqual(prevProps.dashboardDetails, this.props.dashboardDetails)) {
                    this.setState({ 
                        start_time: this.props.dashboardDetails.start_time,
                        end_time: this.props.dashboardDetails.end_time,
                        brushStartTime: this.props.dashboardDetails.brushStartTime ? this.props.dashboardDetails.brushStartTime : this.props.dashboardDetails.start_time,
                        brushEndTime: this.props.dashboardDetails.brushEndTime ? this.props.dashboardDetails.brushEndTime : this.props.dashboardDetails.end_time,                        
                        eventListLoader: true,
                        eventsList: [],
                        filteredArray: [],
                    },
                        () => {
                            if(this.state.columnValue) {
                                this.listObservabilityEvents()
                            }
                        }
                    )
                }
            }
        }  else if(!_.isEqual(prevProps.leftSectionSelection, this.props.leftSectionSelection)) {
            this.setState({ 
                columnValue: "",
                eventListLoader: true
            },
                () => {
                    this.listObservabilityEvents()
                }
            )
        }
    }

    navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = this.state.startRecord
            if(action === 'next' && currentPage !== this.state.totalPages) {
                startRecord = startRecord + this.state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - this.state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = this.state.totalPages
                startRecord = (this.state.totalPages - 1) * this.state.perPage
            }
            this.setState({ currentPage, startRecord })
        } 
	}

    gotoPage = (pageNumber) => {
        if(pageNumber > 0  && pageNumber <= this.state.totalPages) {
            let startRecord = (pageNumber - 1) * this.state.perPage
            this.setState({ currentPage: pageNumber, pageNumber, startRecord })
        } else  if(pageNumber > this.state.totalPages) {
            this.setState({ pageNumber: this.state.currentPage })  
        } else {
            this.setState({ pageNumber })
        }
    }

    listObservabilityEvents = () => {
        let params = {}
        params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:00')
        params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:00')
        
        if(this.state.pageType == "LifeCycle") {
            if(this.state.selectedField && this.state.selectedField !== "") {
                params.asset_action = this.state.selectedField
            } else {
                params.asset_action = this.state.allFields
            }
            params.event_source = "cloudtrail"
            if(this.state.selectedShowBy === "Assets") {
                params.asset_id = this.state.columnValue
            } else {
                params.service_name = this.state.columnValue
            }

        } else {
            params.event_source = "aws.health"
            if(this.state.pageType !== "Notifications" && this.state.pageType !== "IssueEvents") {
                if(this.state.selectedField && this.state.selectedField !== "") {
                    params.impact_type = this.state.selectedField
                } else {
                    params.impact_type = this.state.allFields
                }
            }

            if(this.state.selectedShowBy === "Assets") {
                params.asset_id = this.state.columnValue
            } else {
                params.additional_details = {"service": this.state.columnValue}
            }

            if(this.state.pageType == "Notifications") {
                params.category = "accountNotification"                
            } else if(this.state.pageType == "IssueEvents") {
                params.category = "issue"
            }
        }

        if(this.state.columnValue && this.state.columnValue !== "") {
            this.props.listObservabilityEvents(params, (promise, response) => {
                if(promise) {
                    let results = response.results ? response.results : []
    
                    let totalPages = 1
                    if(results.length > this.state.perPage) {
                        totalPages = Math.ceil(results.length / this.state.perPage)
                    }
    
                    this.setState({
                        eventsList: results,
                        filteredArray: results,
                        eventListLoader: false,
                        totalPages,
                        startRecord: 0, 
			            currentPage: 1
                    },
                        () => {
                            this.props.menuDisabled()
                            this.formHeaders()
                        }
                    )
                    // if(results.length) {
                    //     let obj = this.props.eventTabDetails ? this.props.eventTabDetails : {}
                    //     obj[eventId] = results
                    //     this.props.setAiopsPropsDetails('eventTabDetails', obj)
                    // }
                } else {
                    this.setState({ eventsList: [], filteredArray: [], eventListLoader: false })
                }
            })
        } else {
            this.setState({ eventsList: [], filteredArray: [], eventListLoader: false })
        }
    }

	formHeaders = () => {
		let dataResult = this.state.filteredArray && this.state.filteredArray.length ? this.state.filteredArray : []
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === "string") {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})
			this.setState({ headers })
		}
	}

    getEventDetails = (item) => {
        this.setState({ selectedEventId: this.state.selectedEventId === item.event_id ? "" : item.event_id, selectedEvent: item, expandAssetDetails: !this.state.expandAssetDetails })
    }

	render() {
		return (
            <div className="">
                <div className="d-flex mt-2">
                    <div className="rounded bg-dark p-0 col-sm-12">
                        <div className="d-flex mb-2 justify-content-between">
                            <div className="d-flex mr-2">
                                <p className="f12 m-0 align-self-center pt-1">
                                    <span>Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.state.eventsList && this.state.eventsList.length} event(s) {this.state.selectedField && this.state.selectedField !== "" ? (<span> with   
                                    {this.state.selectedField ? 
                                        <span className="mx-1">
                                            {this.state.pageType === "Notifications" ?
                                                capitalizeTheFirstLetterOfEachWord(this.state.selectedField.toLowerCase().replace("_notification", ""))
                                            : this.state.pageType === "IssueEvents" ?
                                                capitalizeTheFirstLetterOfEachWord(this.state.selectedField.toLowerCase().replace("_issue", ""))
                                            :   this.state.selectedField
                                            }
                                        </span>
                                    : null}
                                    {this.state.selectedGroup && this.state.selectedGroup !== "" ? this.state.selectedGroup.replace("_", " ") : ""}
                                    </span>) : ""}
                                    between </span>
                                    <span className="f12 m-0 align-self-center pt-1 text-info ml-1">{this.state.brushStartTime ? momentDateGivenFormat(this.state.brushStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.brushEndTime, 'DD MMM YYYY HH:mm') : ""}</span>
                                </p>
                            </div>
                            <div className="d-flex">
                                <CSVLink 
                                    data={this.state.filteredArray ? this.state.filteredArray : []} 
                                    headers={this.state.headers ? this.state.headers : []}
                                    filename={"resource-alerts.csv"}
                                    className={"mt-2"}
                                    target="_blank"
                                >
                                    <span className="d-flex text-primary-color mr-2">
                                        <i className="fas fa-download text-primary-color mr-2"></i>
                                        {/* csv */}
                                    </span>
                                </CSVLink>
                                <div className="justify-content-end w-100 mb-n2">
                                    {this.state.eventsList && this.state.eventsList.length ?
                                        <Search
                                            data={this.state.eventsList ? this.state.eventsList : []}
                                            applyTags={false}
                                            applyLiteDarkTags={true}
                                            topClassName={'bg-black5 align-self-center w-100 mr-1 border-gray5 rounded-5 f12'}
                                            searchClassName={'px-2 f12'}
                                            searchIconColor={'text-gray5 f12'}
                                            searchPlaceHolder={'Search....'}
                                            className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                            filteredData={(filteredArray) => this.setState({ filteredArray })}
                                        />
                                    : null}
                                    {this.state.filteredArray && this.state.filteredArray.length > this.state.perPage ?
                                        <div className="pagination errorPagePagination f12">
                                            <span className="d-flex mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
                                            <button><i className={`fal fa-arrow-to-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('start', this.state.currentPage)}></i></button> 
                                            <button><i className={`fal fa-angle-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('previous', this.state.currentPage)}></i></button> 
                                            {/* <span>
                                                Go to
                                                <input
                                                    type='number'
                                                    value={this.state.pageNumber || this.state.pageNumber === "" ? this.state.pageNumber : this.state.currentPage}
                                                    onChange={e => {
                                                        const page = e.target.value ? Number(e.target.value) : ""
                                                        this.gotoPage(page)
                                                    }}
                                                    className="ml-1 inputClass"
                                                />
                                            </span> */}
                                            <button><i className={`fal fa-angle-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
                                            <button><i className={`fal fa-arrow-to-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
                                        </div>
                                    : null}
                                </div>
                            </div>
                        </div>
                        
                        <div className="d-flex">
                            <div className={`w-100`}>
                                <div className={`bg-dark3 py-3 pl-2 obsMessageSectionXL`}>
                                    {this.state.eventListLoader ? 
                                        <div className='d-flex justify-content-center m-4'>
                                            <Spinner className='text-center' color='white' size='lg' />
                                        </div>
                                    : this.state.filteredArray && this.state.filteredArray.length ?
                                        this.state.filteredArray.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map((item, i) => {
                                            return(
                                                <React.Fragment>
                                                <div className={`px-2 mb-2 pb-1 ${i ? "border-top-black5" : ""} ${this.state.selectedEventId && this.state.selectedEventId === item.event_id ? "bg-muted" : ""}`}> 
                                                    <div className="d-flex cursorPointer" onClick={() => this.getEventDetails(item)}>
                                                        <p className="mb-0">
                                                            <span className="mb-0 mr-2 text-white f12 font-weight-bold text-primary-color">{item.event_time ? momentConvertionUtcToLocalTime(item.event_time, 'DD MMM YYYY HH:mm') : ""} </span>
                                                            <span className={`mb-0 mr-2 f12 text-purple-2`}>
                                                                {item.event_source ? (Array.isArray(item.event_source) ? item.event_source.join(", ") : item.event_source) : ""}
                                                                {item.event_name ? ' : ' + item.event_name : ""}
                                                                {/* {item.event_type ? ' : '+ item.event_type : ""} */}
                                                            </span>
                                                            {item.assets && item.assets.length ?
                                                                item.assets.map(ast => {
                                                                    return(
                                                                        <span className="mb-0 mr-2 f12 text-white font-weight-bold">{ast.asset_name}</span>
                                                                    )
                                                                })
                                                            : item.asset_name ?
                                                                <span className="mb-0 mr-2 f12 text-white font-weight-bold">{item.asset_name}</span>
                                                            : null}
                                                            <span className="mb-0 small mr-1 text-primary-color">Resource</span>
                                                            {item.account_id ?
                                                                <span className="mb-0 f12 text-primary-color font-weight-bold">{' '+item.account_id}</span>
                                                            : null}
                                                            {item.region ?
                                                                <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+item.region}</span>
                                                            : null}
                                                            {this.state.pageType === "Notifications" || this.state.pageType === "IssueEvents"  ?
                                                                <React.Fragment>
                                                                {item.additional_details && item.additional_details.eventTypeCode ?
                                                                    <span className="mb-0 f12 text-white font-weight-bold ml-1">{item.additional_details.eventTypeCode}</span>
                                                                : null}
                                                                {item.event_analysis && item.event_analysis.length ?
                                                                    item.event_analysis.map(evtAny => {
                                                                        return(
                                                                        this.state.pageType === "Notifications" ?
                                                                            <span className="mb-0 f12 text-primary-color font-weight-bold mx-1">impact:  {capitalizeTheFirstLetterOfEachWord(evtAny.impact_type.toLowerCase().replace("_notification", ""))}</span>
                                                                        : this.state.pageType === "IssueEvents" ?
                                                                            <span className="mb-0 f12 text-primary-color font-weight-bold mx-1">impact:  {capitalizeTheFirstLetterOfEachWord(evtAny.impact_type.toLowerCase().replace("_issue", ""))}</span>
                                                                        : null
                                                                        )
                                                                    })
                                                                : null}
                                                                </React.Fragment>
                                                            : null}
                                                            {item.asset && item.asset.length ?
                                                                <React.Fragment>
                                                                {item.asset[0].resource_type ?
                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+item.asset[0].resource_type}</span>
                                                                : null}
                                                                {item.asset[0].service_name ?
                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+item.asset[0].service_name}</span>
                                                                : null}
                                                                </React.Fragment>
                                                            :null}
                                                            {(this.state.pageType == "Notifications" || this.state.pageType == "IssueEvents") ?
                                                                item.additional_details && item.additional_details.eventDescription && item.additional_details.eventDescription.length ?
                                                                    item.additional_details.eventDescription.slice(0,1).map(evtDes => {
                                                                        return(
                                                                            evtDes.latestDescription ?
                                                                                <span className="mb-0 f12 text-white font-weight-bold">
                                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold">Description:</span> {evtDes.latestDescription}
                                                                                </span>
                                                                            : null
                                                                        )
                                                                    })
                                                                : null
                                                            : 
                                                                <React.Fragment>
                                                                <span className="mb-0 f12 text-primary-color font-weight-bold mx-1">Done by </span>
                                                                {item.user_type ? 
                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold mx-1">{item.user_type}</span>
                                                                : null}
                                                                {item.user_name ? 
                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold mx-1">{item.user_name}</span>
                                                                : null}
                                                                </React.Fragment>
                                                            }
                                                            {item.description ?
                                                                <span className="mb-0 ml-2 f12 text-white font-weight-bold">{item.description}</span>
                                                            : null}
                                                        </p>
                                                    </div>
                                                    
                                                    {this.state.selectedEventId && this.state.selectedEventId === item.event_id ?
                                                        this.state.selectedEvent.assets && this.state.selectedEvent.assets.length ?
                                                            <div className="rounded bg-dark3 p-3 mt-1">
                                                                <div className="accordion" id="accordionExample">
                                                                    <div className={`card bg-dark border-0 mb-2`}>
                                                                        <div id={'heading_config_assets'} onClick={() => this.setState({ expandAssetDetails: !this.state.expandAssetDetails })}>
                                                                            <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                                                                <div className="text-white"><span className={`far ${this.state.expandAssetDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Asset Details</div>
                                                                                {/* <div className="text-info">Showing asset details</div> */}
                                                                            </div>
                                                                        </div>
                                                                        <div id={'collapse_config_assets'} className={`collapse ${this.state.expandAssetDetails ? 'show' : ""}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                                                            {this.state.selectedEvent.assets.map(item => {
                                                                                return(
                                                                                <div className="card-body m-2 pt-2 rounded counts_threads">
                                                                                    <div className="d-flex mb-2">
                                                                                        <div className="py-1 w-50">
                                                                                            <p className="b-block mb-0">Name</p>
                                                                                            <p className="mb-0 text-white">{item.asset_name ? item.asset_name : ""}</p>
                                                                                        </div>
                                                                                        <div className="py-1 w-50 pl-3">
                                                                                            <p className="b-block mb-0">Id</p>
                                                                                            <p className="mb-0 text-white">{item.asset_id ? item.asset_id : ""}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex mb-2 border-top">
                                                                                        <div className="py-1 w-50">
                                                                                            <p className="b-block mb-0">Resource</p>
                                                                                            <p className="mb-0 text-white">
                                                                                                {this.state.selectedEvent.provider ? this.state.selectedEvent.provider.toUpperCase() : ""}
                                                                                                {this.state.selectedEvent.account_id ? (" : ")+this.state.selectedEvent.account_id : ""}
                                                                                                {this.state.selectedEvent.region ? (" : ")+this.state.selectedEvent.region : ""}
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="py-1 w-50 pl-3">
                                                                                            <p className="b-block mb-0">Services</p>
                                                                                            <p className="mb-0 text-white">
                                                                                                {item.resource_type ? item.resource_type : ""}
                                                                                                {item.service_name ? (" : ")+item.service_name : ""}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>                                                        
                                                                                    <div className="d-flex mb-2 border-top">
                                                                                        <div className="py-1 w-100">
                                                                                            <p className="b-block mb-0">ARN</p>
                                                                                            <p className="mb-0 text-white">{item.asset_arn ?item.asset_arn : <span>&nbsp;</span>}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    {item.category ? 
                                                                                        <div className="d-flex mb-2 border-top">
                                                                                            <div className="py-1 w-100">
                                                                                                <p className="b-block mb-0">Category</p>
                                                                                                <div className={`d-flex flex-wrap`}>
                                                                                                    {item.category.map(item => {
                                                                                                        return (
                                                                                                            <small className={`mr-1 mb-1 badge badge-light`}>{item}</small> 
                                                                                                        )
                                                                                                    })}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    : null}
                                                                                </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        : null
                                                    : null}
                                                </div>
                                                </React.Fragment>
                                            )
                                        })
                                    :
                                        <div className='d-flex justify-content-center m-4'>
                                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
EventsTab.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('errorDashboard',state)
	return {
        leftSectionSelection: state.assets.resourcesPropsDetails.leftSectionSelection ? state.assets.resourcesPropsDetails.leftSectionSelection : {},
        dashboardDetails: state.assets.resourcesPropsDetails && state.assets.resourcesPropsDetails.dashboardDetails ? state.assets.resourcesPropsDetails.dashboardDetails : {},
    }
}

export default connect(mapStateToProps, {
    listObservabilityEvents,
    setAiopsPropsDetails,
})(withRouter(EventsTab))