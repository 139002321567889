/*************************************************
 * Tvastar
 * @exports
 * @file QueryAction.js
 * @author parakash // on 2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'

import {
	OBSERVABILITYQUERYENDPOINT,
	GET_QUERIES,
	START_QUERY_EXECUTION,
	GET_QUERY_RESULT,
	SET_OBSERVABILITY_ASSET_FAVOURITE_QUERY,
	UNSET_OBSERVABILITY_ASSET_FAVOURITE_QUERY

} from '../config'

import { ACTION_TYPES } from './types'

/**
 * Action to get-queries
 * @param {Object} body
 * @param {Function} callback
 */
export const getQueries = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(OBSERVABILITYQUERYENDPOINT, GET_QUERIES, { body })
			dispatch({
				type: ACTION_TYPES.SET_OBSERVABILITY_QUERY_LIST,
				payload: result,
			})
			callback(true, result)
		} catch(err) {
			callback(false, {})
		}
    }
}

/**
 * Action to start-query-execution
 * @param {Object} body
 * @param {Function} callback
 */
export const startQueryExection= (body, callback) => {
	return async () => {
		await API.post(OBSERVABILITYQUERYENDPOINT, START_QUERY_EXECUTION, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = err.response && err.response.data ? err.response.data : {message: "Something went wrong"}
			callback(false, message)
		})
    }
}

/**
 * Action to get-query-results
 * @param {Object} body
 * @param {Function} callback
 */
export const getQueryResult = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(OBSERVABILITYQUERYENDPOINT, GET_QUERY_RESULT, { body })
			if(result.status === "COMPLETED") {
				dispatch({
					type: ACTION_TYPES.SET_OBSERVABILITY_QUERY_RESULT,
					payload: result,
				})
			} 
			callback(true, result)
		} catch(err) {
			let message = err.response && err.response.data ? err.response.data : {message: "Something went wrong"}
			callback(false, message)
		}
    }
}

/**
 * Action to set-favourite-query
 * @param {Object} body
 * @param {Function} callback
 */
 export const setFavouriteQuery = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(OBSERVABILITYQUERYENDPOINT, SET_OBSERVABILITY_ASSET_FAVOURITE_QUERY, { body })
			callback(true, result)
		} catch(err) {
			let message = err.response && err.response.data ? err.response.data : {message: "Something went wrong"}
			callback(false, message)
		}
    }
}

/**
 * Action to unset-favourite-query
 * @param {Object} body
 * @param {Function} callback
 */
 export const unsetFavouriteQuery = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(OBSERVABILITYQUERYENDPOINT, UNSET_OBSERVABILITY_ASSET_FAVOURITE_QUERY, { body })
			callback(true, result)
		} catch(err) {
			let message = err.response && err.response.data ? err.response.data : {message: "Something went wrong"}
			callback(false, message)
		}
    }
}