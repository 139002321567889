/*************************************************
 * Tvastar
 * @exports
 * @file assetsAction.js
 * @author Rajasekar // on 05/02/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'

import {
	SYNCHRONIZER,
	LIST_ASSETS,
	GET_ADVANCED_SEARCH_LIST,
	GET_TOTAL_ASSET_COUNT,
	GET_DAILY_ASSET_COUNT,
	GET_ASSET_DETAIL_EXT,
	GET_ASSET_NETWORK_DETAILS,
	GET_ASSET_SG_DETAILS,
	GET_ASSET_STORAGE_DETAILS,
} from '../../config'
import { myLog } from '../../utils/utility'
import { ACTION_TYPES } from '../types'

/**
 * 
 
 * Action to get all assets list
 * @param {Object} body
 * @param {Function} callback
 */
export const listAllAssets = (body, callback) => {
	return async dispatch => {
		try {
			const assetsList = await API.post(SYNCHRONIZER, LIST_ASSETS, { body })
			dispatch({
				type: ACTION_TYPES.SET_ASSETS_LIST,
				payload: assetsList,
			})
			
			// dispatch({
			// 	type: ACTION_TYPES.SET_SELECTED_TABLE_ROW,
			// 	payload: assetsList.results && assetsList.results[0],
			// })
			callback(assetsList)
		} catch (error) {
			myLog('assetsList', error)

			dispatch({
				type: ACTION_TYPES.SET_SUPPRESSION_LIST,
				payload: {},
			})
			callback(false)
		}
	}
}

export const getAdvancedSearchResults = (body, callback) => {
	return async dispatch => {
		try {
			console.log(body)
			const advancedAssetsList = await API.post(SYNCHRONIZER, GET_ADVANCED_SEARCH_LIST, { body })
			console.log('advancedAssetsList', advancedAssetsList)
			dispatch({
				type: ACTION_TYPES.SET_ADVANCED_ASSETS_LIST,
				payload: advancedAssetsList,
			})
			dispatch({
				type: ACTION_TYPES.SET_ADVANCED_SELECTED_TABLE_ROW,
				payload: advancedAssetsList.data[0],
			})
			callback(advancedAssetsList)
		} catch (error) {
			myLog('advancedAssetsList', error)

			dispatch({
				type: ACTION_TYPES.SET_SUPPRESSION_LIST,
				payload: {},
			})
			callback(false)
		}
	}
}
/**
 * Action to get total Asset Count
 * @param {Object} body
 * @param {Function} callback
 */
export const getTotalAssetCount = (body, countFor, callback) => {
	return async dispatch => {
		try {
			const totalAssetCount = await API.post(SYNCHRONIZER, GET_TOTAL_ASSET_COUNT, { body })
			if (countFor === 'provider') {
				dispatch({
					type: ACTION_TYPES.SET_PROVIDER_COUNT,
					payload: totalAssetCount,
				})
			}
			if (countFor === 'status') {
				dispatch({
					type: ACTION_TYPES.SET_STATUS_COUNT,
					payload: totalAssetCount,
				})
			}
			if (countFor === 'region') {
				dispatch({
					type: ACTION_TYPES.SET_REGION_COUNT,
					payload: totalAssetCount,
				})
			}
			if (countFor === 'category') {
				dispatch({
					type: ACTION_TYPES.SET_CATEGORY_COUNT,
					payload: totalAssetCount,
				})
			}
			if (countFor === 'score') {
				dispatch({
					type: ACTION_TYPES.SET_SCORE_COUNT,
					payload: totalAssetCount,
				})
			}
			callback(true, totalAssetCount)
		} catch (error) {
			myLog('totalAssetCount', error)
			callback(false)
		}
	}
}
/**
 * Action to get daily Asset Count
 * @param {Object} body
 * @param {Function} callback
 */
export const getDailyAssetCount = (body, callback) => {
	return async dispatch => {
		try {
			const dailyAssetCount = await API.post(SYNCHRONIZER, GET_DAILY_ASSET_COUNT, { body })
			dispatch({
				type: ACTION_TYPES.SET_ASSET_COUNT,
				payload: dailyAssetCount,
			})
			callback(true)
		} catch (error) {
			myLog('dailyAssetCount', error)

			callback(false)
		}
	}
}
/**
 * Action to get Asset detail ext
 * @param {Object} body
 * @param {Function} callback
 */
export const getAssetDetailExt = (body, callback) => {
	return async dispatch => {
		try {
			const assetDetailExt = await API.post(SYNCHRONIZER, GET_ASSET_DETAIL_EXT, { body })
			dispatch({
				type: ACTION_TYPES.SET_ASSET_DETAILS,
				payload: assetDetailExt,
			})
			callback(true)
		} catch (error) {
			myLog('assetDetailExt', error)

			callback(false)
		}
	}
}

/**
 * Action to get Asset network detail
 * @param {Object} body
 * @param {Function} callback
 */
export const getAssetNetworkDetails = (body, callback) => {
	return async dispatch => {
		try {
			const assetNetworkDetail = await API.post(SYNCHRONIZER, GET_ASSET_NETWORK_DETAILS, { body })
			dispatch({
				type: ACTION_TYPES.SET_ASSET_NETWORK_DETAILS,
				payload: assetNetworkDetail,
			})
			callback(true)
		} catch (error) {
			myLog('assetNetworkDetail', error)
			callback(false)
		}
	}
}

/**
 * Action to get Asset storage detail
 * @param {Object} body
 * @param {Function} callback
 */
export const getAssetStorageDetails = (body, callback) => {
	return async dispatch => {
		try {
			const assetStorageDetail = await API.post(SYNCHRONIZER, GET_ASSET_STORAGE_DETAILS, { body })
			dispatch({
				type: ACTION_TYPES.SET_ASSET_STORAGE_DETAILS,
				payload: assetStorageDetail,
			})
			callback(true)
		} catch (error) {
			myLog('getAssetStorageDetails', error)

			callback(false)
		}
	}
}

/**
 * Action to get Asset sg detail
 * @param {Object} body
 * @param {Function} callback
 */
export const getAssetSGDetails = (body, callback) => {
	return async dispatch => {
		try {
			const assetSGDetail = await API.post(SYNCHRONIZER, GET_ASSET_SG_DETAILS, { body })
			dispatch({
				type: ACTION_TYPES.SET_ASSET_SG_DETAILS,
				payload: assetSGDetail,
			})
			callback(true)
		} catch (error) {
			myLog('getAssetSGDetails', error)

			callback(false)
		}
	}
}
/**
 * Action to set selected table row
 */
export const setSelectedTableRow = row => {
	return async dispatch => {
		dispatch({
			type: ACTION_TYPES.SET_SELECTED_TABLE_ROW,
			payload: row,
		})
	}
}
/**
 * Action to set selected filter
 */
export const setSelectedFilter = (filter, selectedValue) => {
	return async (dispatch, getState) => {
		let selectedFilters = getState().assets.selectedFilters
		selectedFilters[filter] = selectedValue
		dispatch({
			type: ACTION_TYPES.SET_SELECTED_FILTER,
			payload: selectedFilters,
		})
	}
}


/**
 * Action to set resource props details
 */
 export const setResourcePropsDetails = (key, value) => {
	return async (dispatch, getState) => {
		const resourcesPropsDetails = JSON.parse(JSON.stringify(getState().assets.resourcesPropsDetails))
		resourcesPropsDetails[key] = value
		
		dispatch({
			type: ACTION_TYPES.SET_RESOURCES_PROPS_DETAILS,
			payload: resourcesPropsDetails,
		})
	}
}