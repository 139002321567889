import React from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import _ from 'lodash'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

import { getDayFromSelectedDuration } from '../../../../utils/utility'
import { getAllTagsKeys, getAllTagsValues } from '../../../../actions/commonAction'

class TagsSection extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            inputComponent: this.props.inputComponent,
            selectedPage: this.props.selectedPage,
            selectedOption: this.props.selectedOption,
            selectedFilters: this.props.selectedFilters,
        }
    }

    componentDidMount = () => {
		this.getAllTagsKeys()
    }
    
    getAllTagsKeys = () => {
		
		if(!this.props.tagKeys || !this.props.tagKeys.length) {
			let params = {}
			// params.account_id = "265469181489"
			// params.region = "us-east-2"
			this.props.getAllTagsKeys(params, (promise, response) => {})
		}
	}

	getAllTagsValues = () => {
		let params = {}
		params.tags = [{ "key": this.state.tag_key }]
		this.props.getAllTagsValues(params, (promise, response) => {
			if(promise) {
				let result = response
				if(response.length) {
					result.unshift("All");
				}
				this.setState({ tagValues: response })
			}
		})
	}
	
	handleMultiSelectChange = (field, arrayValue, state) => {
		let value = arrayValue.map(item => item.value ? item.value : item)
		console.log("value", value)
		let selectedValue = []
		let prevState = this.state[field] ? this.state[field] : []
		if(prevState.length === 1 && prevState[0] === "All") {
			prevState = []
		}
		if(value.includes("All")) {
			if(!prevState.includes("All")) {
				if(state.filter(e => e.value).length) {
					selectedValue = state.map(e => e.value)
				} else {
					selectedValue = state
				}
			} else {
				const index = value.indexOf("All");
				if (index > -1) {
					value.splice(index, 1);
				}
				selectedValue = value
			}
		} else if(!prevState.includes("All")) {
			selectedValue = value
		}

		if(selectedValue.length && !selectedValue.includes("All")) {
			if(!selectedValue.includes("All") && selectedValue.length === (state.length -1)) {
				selectedValue.push("All")
			}
		}
		
		this.setState({ [field]: selectedValue })
	}
	
	getMultiSelectedCount = (type, array) => {
		return array.length && array.includes("All") ? "All" : (array.length ? array.length +" Selected" : <span className="placeholder w-100 align-self-center">Select</span>)
	}

	addNewTag = () => {
		let selectedTags = this.state.selectedFilters ? this.state.selectedFilters : []

		if(this.state.tag_key && this.state.tag_key !== "" && this.state.tag_value && this.state.tag_value.length) {
			this.state.tag_value.forEach(tag => {
				if(tag !== "All" && (!(selectedTags.filter(e => e.key === this.state.tag_key && e.value !== tag).length) || selectedTags.filter(e => e.key !== this.state.tag_key))) {
					let dataRow = {}
                    dataRow.label = "Tags"
					dataRow.key = this.state.tag_key
					dataRow.value = tag
                    dataRow.userView = this.state.selectedOption+" : "+this.state.tag_key + " : "+ tag
					selectedTags.push(dataRow)
				}
			})
		}

		this.setState({ selectedFilters: selectedTags, tag_key: "",  tag_value: [], tagValues: [] })
	}

	removeTagSection = (tag) => {
		// filteredResult = this.state.selectedTags.filter(e => e.key !== tag.key && e.value !== tag.value)
		let filteredResult = this.state.selectedFilters.filter(e => e !== tag)
		this.setState({selectedFilters: filteredResult })
	}

    applyFilter = () => {
        if(this.state.selectedFilters && this.state.selectedFilters.length) {
            return this.props.selectedInput(this.state.selectedOption, this.state.selectedFilters)
        } else {
            return this.props.selectedInput(false);
        }
    }

    closeFilter = () => {
        return this.props.selectedInput(false);
    }
    
    render() {
        return (
            
            <div className='container-fluid bg-dark3 mt-4 py-3'>
                <p className="text-white mb-2">{this.state.selectedOption}</p>
                <div className="d-flex flex-wrap mb-3 zapInputDark">
                    <Select
                        isSearchable={true}
                        className={`f13 p-0 mr-2 w-20 `}
                        value={({
                            value: this.state.tag_key,
                            label: this.state.tag_key && this.state.tag_key !== "" ? this.state.tag_key : <span className="placeholder">Key</span>
                        })}														
                        
                        options={this.props.tagKeys && this.props.tagKeys.map(item => ({
                            value: item,
                            label: item,	
                        }))}
                        onChange={event => this.setState({
                            tag_key: event.value,
                            tagValues: [],
                        },
                            () => this.getAllTagsValues()
                        )}
                    />       
                    <div className={`multiselectDarkTheme align-self-center mr-2 z999 w-30`}>
                        <ReactMultiSelectCheckboxes
                            placeholderButtonLabel="All"
                            getDropdownButtonLabel={() => this.getMultiSelectedCount("tag_value", this.state.tag_value ? this.state.tag_value : [])}
                            options={this.state.tagValues && this.state.tagValues.map(row => ({
                                value: row,
                                label: row,
                            }))}
                            onChange={arr => { this.handleMultiSelectChange("tag_value", arr ? arr : [], this.state.tagValues) }}
                            value={this.state.tag_value && this.state.tag_value.map(acc => ({
                                value: acc
                            }))}
                        />
                    </div>
                    <span className={`far fa-plus cursor-pointer f18 mt-2 mb-1 align-self-center ml-2`} onClick={() => this.addNewTag()}></span>
                </div>
                
                {this.state.selectedFilters && this.state.selectedFilters.length ? 
                    this.state.selectedFilters.map(tag => {
                        return(
                            <span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{tag.key +" : "+tag.value}
                                <i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeTagSection(tag) } ></i>
                            </span>
                        )
                    })
                : null}
                
                <div className='row mt-2 mb-3'>
                    <div className="col-sm-6"></div>
                    <div className="col-sm-6 justify-content-end d-flex">
                        <span className="dropCancelBtn d-block p-2 mr-2" onClick={ () => this.closeFilter()}>
                            Cancel
                        </span>
                        <span className="dropApplyBtn d-block p-2" onClick={ () => this.applyFilter()}>
                            Apply
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
	// console.log('observability query page',state)
	return {
		tagKeys: state.filters.tagKeys,
	}
}

export default connect(mapStateToProps, {
	getAllTagsKeys, 
	getAllTagsValues
})(withRouter(TagsSection))