/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file OperationalSummary.js
 * @author Prakash // on 05/06/2020
 * @copyright © 2020 Tvastar. All rights reserved.
 **************************************************/
import React, { Component } from 'react'
import { AppWrapper } from '../common/AppWrapper'
import PropTypes from 'prop-types'
////import Chart from 'chart.js'
import moment from 'moment'
import _ from 'lodash'
import { UncontrolledTooltip } from 'reactstrap'

import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

import { 
	listAllProviders,
	listOrganizations,
	listAllAccountsDetails,
} from '../../actions/commonAction'
import  { getJobTotalCount, getJobDetails, getJobDetailsByModule, getJobDailyCount, getJobHistogram } from '../../actions/operationalMetricsAction'
import { CUSTOM_DURATION_FILTER_OPTIONS } from '../../utils/constants'
import { datestartEndTime, getAccountNameFromId, capitalizeFirstLetter, countFormater } from '../../utils/utility' 

import Search from '../common/SearchComponent'
import SearchWithHiddenInput from '../common/SearchWithHiddenInput'

import ApexBarChart from '../common/charts/ApexBarChart';
import ApexStackedBarChart from '../common/charts/ApexStackedBarChart';
import StackedCircleBarChart from '../common/charts/StackedCircleBarChart'
import JobDetailPanel from './JobDetailPanel'
import JobListPanel from './JobListPanel'

class operationalSummary extends Component {
	constructor(props) {
		super(props)
		
		this.accountRef = React.createRef()
		this.toggleTrendTypeRef = React.createRef()
		this.toggleRef = React.createRef()
		this.toggleAccountRef = React.createRef()
		this.toggleListTypeTableRef = React.createRef()
        this.rightRadialStackedBarWidthRef = React.createRef()

		this.handleDayClick = this.handleDayClick.bind(this);
		this.handleResetClick = this.handleResetClick.bind(this);
		this.state = this.getInitialState();

		this.state = {
			changesInFilter: true,
			minMaxHeight: "800px",
			showDetailsPanel: false,
			left: 0,
			showLoading: true,
			
			providers: [],
			selectedProvider: "",
			accounts: [],
			accArray: [],
			accountArray: [],
			accountFilter: [],
			selectedAccount: [],
			selectedAccountArray: [],
			isAccountOpen: false,
			accountShowLength: 10,
			accStartRow: 0,
			accEndRow: 10,

			selectedUserOrgType: "",

			organizations: [],
			selectedOrganization: "",

			selectedDuration: "+7d",

			accSortOptions: [
				{label: "Success", value: "success"},
				{label: "Failed", value: "failed"},
				{label: "Count", value: "count"},
				{label: "CPU Hrs", value: "cpu_hours"},
				{label: "Memory Hrs", value: "memory_hours"},
				{label: "Total Time", value: "sum_hours"},
			],

			jobTypes: [
				{label: "Count", value: "count"},
				{label: "CPU Hrs", value: "cpu_hours"},
				{label: "Total Time", value: "execution_time_in_hours"},
				{label: "Failed", value: "failed"},
				{label: "Memory Hrs", value: "memory_hours"},
				{label: "Success/Failed", value: "success/failed"},
			],
            selectedJobType: "count",

			tableJobTypes: [
				{label: "Job Type", value: "job_type"},
				{label: "Total", value: "total"}
			],

			radialTableHeight: "600px",
			jobSortOptions: [
				{label: "Job Type", value: "job_type"},
				{label: "Frequency", value: "frequency"},
				{label: "Runs", value: "count"},
				{label: "Success", value: "success"},
				{label: "Failed", value: "failed"},
				{label: "CPU Hrs", value: "cpu_hours"},
				{label: "Memory Hrs", value: "memory_hours"},
				{label: "Total time", value: "sum_seconds"},
				// {label: "Min time", value: "min"},
				// {label: "Max time", value: "max"},
				{label: "Avg time", value: "avg"},
				// {label: "P95", value: "p95"},
				// {label: "P99", value: "p99"},
			],
			trendTypes: [
				{label: "All", value: "All"},
				{label: "Success", value: "Success"},
				{label: "Failed", value: "Failed"},
			],
			selectedTrendType: "All",
			
			trendGraphs: ["cpu_hours", "memory_hours", "vcpus", "memory"],
			trendGraphColors: ["#5F5BA2", "#9A77D1", "#3F73AD", "#24A597"],
		}
	}
	
	componentDidMount = () => {
		this.onSearch("onLoad")
		this.hideLoading()

		let params = {}
		this.props.listOrganizations(params, (promise, organizations) => {
			if (promise) {
				if(organizations.length) {
					let dataRow = {}
					dataRow.organization = "All"
					dataRow.org_name = "All"
					organizations.unshift(dataRow);
				}
				this.setState({ organizations })
			} else {
				this.setState({ organizations: [] })
			}
		})

		this.props.listAllAccountsDetails(params, (promise, accounts) => {
			if (promise) {
				let accountArray = []
				accounts.length && accounts.forEach((acc, i) => {
					if(i === 0) {
						let accRow = {}
						accRow.account_id = "All"
						accRow.account_name = "All"
						accRow.date = null
						accRow.disabled = false
						accRow.provider = "All"
						accountArray.push(accRow);
					}	
					accountArray.push(acc)			
				})
				this.setState({ accounts, accArray: accounts, accountArray, accountFilter: accountArray })
			} else {
				this.setState({ accounts: [], accountArray: [] })
			}
		})

		this.getWindowSize()
	}
	
    hideLoading = () => {
        setTimeout(() => { this.setState({ showLoading: false }) }, 10000)
    }

	getWindowSize = () =>  {
		const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 110
		let outerHeight = (window.outerHeight - browserBorder - headerHeight) +"px"

		this.setState({ outerHeight, minMaxHeight: outerHeight })
	}

	onSearch = (type) => {
        if(this.state.changesInFilter) {
            this.setState({
                changesInFilter: false,
                showLoading: true,
                resetEnabled: type === "onLoad" ? false : true,
            },
                () => {
					this.getAccountJobDetails()
                }
            )
        }
    }

	onReset = () => {
		this.setState({ 
			selectedOrganization: "", 
			selectedAccount: [],
			choosenAccount: "",
			selectedDuration: "+7d", 
            changesInFilter: this.state.resetEnabled,
		},
			() => this.onSearch()
		)
	}

	getJobTotalCount = () => {
		let params = {}
		params.aggregate_by = ["status"]
		params.radial = true
		if(this.state.selectedDuration === "custom") {
			params.start_time = this.state.start_time
			params.end_time = this.state.end_time
		} else {
			params.duration = this.state.selectedDuration
		}
		if(this.state.selectedOrganization.length && !this.state.selectedOrganization.includes("All")) {
			if(this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
				params.account_id = this.state.selectedAccount
			} else {
				let accountId = this.state.accArray.filter(e => e.organization === this.state.selectedOrganization)
				let accountIds = []
				accountId.forEach(item => {
					accountIds.push(item.account_id)
				})
				params.account_id = accountIds
			}
		} else if(this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
			params.account_id = this.state.selectedAccount
		}
		this.props.getJobTotalCount(params, (promise, response) => {
			if(promise) {
				let results = response && response.results && response.results.length && response.results[0] ? response.results[0].data : {}
				this.setState({ jobTotalCount: results })
			} else {
				this.setState({ jobTotalCount: {} })
			}
		})
	}

	getAccountJobDetails = () => {
		//, account_id: []
		let params = {}
		params.aggregate_by = ["account_id"]
		if(this.state.selectedDuration === "custom") {
			params.start_time = this.state.start_time
			params.end_time = this.state.end_time
		} else {
			params.duration = this.state.selectedDuration
		}
		if(this.state.selectedOrganization.length && !this.state.selectedOrganization.includes("All")) {
			if(this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
				params.account_id = this.state.selectedAccount
			} else {
				// console.log(this.state.accArray.filter(e => {e.organization === this.state.selectedOrganization[account_id]}))
				let accountId = this.state.accArray.filter(e => e.organization === this.state.selectedOrganization)
				let accountIds = []
				accountId.forEach(item => {
					accountIds.push(item.account_id)
				})
				params.account_id = accountIds
			}
		} else if(this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
			params.account_id = this.state.selectedAccount
		}
		this.props.getJobDetails(params, (promise, response) => {
			if(promise) {
				
				this.setState({ accResponse: response },
					() => this.structureAccountResults()
				)
			} else {
				this.setState({ accResponse: [] })
			}
		})
	}

	structureAccountResults = () => {
		let accountsJobList = []
		this.state.accResponse.forEach(item => {
			Object.entries(item.execution_time_in_hours).forEach(([key, value]) => {
				item[key+"_hours"] = value
			})
			Object.entries(item.execution_time_in_secs).forEach(([key, value]) => {
				item[key+"_seconds"] = value
			})
			accountsJobList.push(item)
		})

		let choosenAccount = accountsJobList.length ? accountsJobList[0].account_id : ""

		this.setState({ accountsJobList, filteredAccountsJobList: accountsJobList, choosenAccount },
			() => {
				this.onChangeAccFunction(this.state.choosenAccount)
			}
		)
	}

	onChangeAccFunction = (choosenAccount) => {
		if(choosenAccount !== "") {
			this.setState({ choosenAccount, showLoading: true, showRadialChart: false, jobStatusData: {}, histogramGraphData: {}, jobAccountDetails: [], filteredJobAccountDetails: [] },
				() => {
					this.getJobAccountDetails()
					this.getJobDetailsByModule()
					this.getJobDailyCount()
					this.getJobDailyCount("status")
					this.getCpuMemoryHourDetails()
					this.getJobHistogram()
					this.getJobTotalCount()
				}	
			)
		}
	}

	sortAccounts = (type, sortBy) => {
		let sortData = this.state.filteredAccountsJobList
		let sortType = this.state.accountSortType ? this.state.accountSortType : "desc"
		if(this.state.filteredAccountsJobList && this.state.filteredAccountsJobList.length) {			
			if(type === "onChange" && sortBy === this.state.selectedAccSortBy) {
				sortType = this.state.accountSortType === "desc" ? "asc" : "desc"
			} else if(type === "onChange" && sortBy !== this.state.selectedAccSortBy) {
				sortType = "desc"
			}
			sortData = _.orderBy(this.state.filteredAccountsJobList, [sortBy], [sortType])
		}

		this.setState({ filteredAccountsJobList: sortData, selectedAccSortBy: sortBy, accountSortType: sortType })
	}

	getJobAccountDetails = () => {
		//, account_id: []
		let params = {}
		if(this.state.selectedDuration === "custom") {
			params.start_time = this.state.start_time
			params.end_time = this.state.end_time
		} else {
			params.duration = this.state.selectedDuration
		}
		if(this.state.choosenAccount && this.state.choosenAccount !== "") {
			params.account_id = this.state.choosenAccount
		}
		this.props.getJobDetails(params, (promise, response) => {
			if(promise) {
				this.setState({ jobAccount: response },
					() => this.structureJobAccount()
				)
			} else {
				this.setState({ jobDetails: {} })
			}
		})
	}

	structureJobAccount = () => {
		let jobAccount = this.state.jobAccount
		let jobAccountDetails = []
		jobAccount.forEach(item => {
			Object.entries(item.execution_time_in_hours).forEach(([key, value]) => {
				item[key+"_hours"] = value
			})
			Object.entries(item.execution_time_in_secs).forEach(([key, value]) => {
				item[key+"_seconds"] = value
			})
			jobAccountDetails.push(item)
		})

		this.setState({ jobAccountDetails },
			() => this.sortJobAccDetails(this.state.jobAccountDetails, "job_type")
		)
	}

	sortJobAccDetails = (results, sortBy) => {
		let sortData = results
		let sortType = this.state.sortType ? this.state.sortType : "desc"
		if(results && results.length) {			
			if(sortBy === this.state.selectedJobSortBy) {
				sortType = this.state.sortType === "desc" ? "asc" : "desc"
			}
			
			sortData = _.orderBy(results, [sortBy], [sortType])

			// sortData = _.sortBy(results, [sortBy]);
		}

		this.setState({ filteredJobAccountDetails: sortData, selectedJobSortBy: sortBy, sortType })
	}

	getJobDetailsByModule = () => {
		let params = {}
		if(this.state.selectedDuration === "custom") {
			params.start_time = this.state.start_time
			params.end_time = this.state.end_time
		} else {
			params.duration = this.state.selectedDuration
		}
		if(this.state.choosenAccount && this.state.choosenAccount !== "") {
			params.account_id = this.state.choosenAccount
		}
		this.props.getJobDetailsByModule(params, (promise, response) => {
			if(promise) {
				this.setState({ jobDetails: response },
					() => {
						this.formRadialBarChart()
					}
				)
			} else {
				this.setState({ jobDetails: {}, showLoading: false })
			}
		})
	}

	formRadialBarChart = () => {
        let jobDetails = this.state.jobDetails

		let selectedJobDetails = jobDetails && jobDetails[this.state.selectedJobType] && jobDetails[this.state.selectedJobType].data ? jobDetails[this.state.selectedJobType].data : {}

		selectedJobDetails = Object.keys(selectedJobDetails).sort().reduce((obj, key) => (obj[key] = selectedJobDetails[key], obj), {})

        let graphColors = ["#FFD200", "#B96422"]
		if(this.state.selectedJobType === "failed") {
			graphColors = ["#B96422"]
		}

        let keys = ["Success", "Failed"]
		if(this.state.selectedJobType !== "success/failed") {
			keys = [this.state.selectedJobType]
		}
	
        let dataArray = []
		let highestData = 0
		let totalValue = 0
        Object.entries(selectedJobDetails).forEach(([key, value], index) => {
			let State = "files_"+index
			let dataRow = {}
			
			dataRow.State = State
			dataRow.name = key

			let valueCount = 0
			if(this.state.selectedJobType === "success/failed") {
				dataRow.Success = value["COMPLETED"] ? value["COMPLETED"] : 0
				dataRow.Failed = value["FAILED"] ? value["FAILED"] : 0
				valueCount = (dataRow.Success + dataRow.Failed)
			} else {
				dataRow[this.state.selectedJobType] = value				
				valueCount = value
			}

			let successFailTotal = valueCount
			totalValue = Object.entries(selectedJobDetails).length
			if(highestData < successFailTotal) {
				highestData = successFailTotal
			}
			
			dataArray.push(dataRow)
        })

		if(dataArray.length < 25) {			
			let length = dataArray.length
			for(let i=length; i<25; i++) {
				let dataRow = {}
				dataRow.State = "files_"+i
				dataRow.name = "empty_data"
				keys.forEach((key, j) => {
                    dataRow[key] = 0
				})
				dataArray.push(dataRow)
			}
		}

		let totalData = {}
		let data = {}
		data.name = "Job Type"
		keys.forEach((key, j) => {
			data[key] = this.count(dataArray, key)
		})

		totalData.data = data

		let radialTableHeight = (this.rightRadialStackedBarWidthRef.current.offsetWidth - 0) +"px"

        this.setState({ graphColors, dataArray, dataKeys: keys, highestData, totalData, totalValue, graphId: "jobTypeRadialBar", svgGraphId: "svgJobTypeRadialBar", showRadialChart: true, showLoading: false, radialTableHeight, selectedJobDetails },
			() => {
				this.sortJobTypeTableData("", "total")

				window.addEventListener("resize", () => {
					this.setState({ showRadialChart: false },
						() => {
							let radialTableHeight = (this.rightRadialStackedBarWidthRef.current.offsetWidth - 0) +"px"
							this.setState({ showRadialChart: true, radialTableHeight })    
						}
					)
				})
			}
		)
	}

	count = (array, key) => {
		return array.reduce(function (r, a) {
			return r + a[key];
		}, 0);
	}

	sortJobTypeTableData = (type, sortBy) => {
		if(sortBy === "total") {
			this.setState({ job_type_sort: "" })
		} else {
			this.setState({ total_sort: "" })
		}
        let selectedJobDetails = this.state.selectedJobDetails
		if(this.state.selectedJobType === "success/failed") {
			let newDetails = []
			Object.entries(selectedJobDetails).forEach(([key, value], index) => {
				let dataRow = {}
				dataRow.job_type = key
				dataRow.total = (value["COMPLETED"] ? value["COMPLETED"] : 0) + (value["FAILED"] ? value["FAILED"] : 0)
				newDetails.push(dataRow)
			})
			
			selectedJobDetails = newDetails
		} else {
			let newDetails = []
			Object.entries(selectedJobDetails).forEach(([key, value], index) => {
				let dataRow = {}
				dataRow.job_type = key
				dataRow.total = value
				newDetails.push(dataRow)
			})
			
			selectedJobDetails = newDetails
		}

		let sortData = selectedJobDetails
		let sortType = this.state[sortBy+"_sort"] ? this.state[sortBy+"_sort"] : "desc"
		if(selectedJobDetails && selectedJobDetails.length) {			
			if(type === "onChange" && sortBy === this.state.selectedTableJobType) {
				sortType = this.state[sortBy+"_sort"] === "desc" ? "asc" : "desc"
			} else if(type === "onChange" && sortBy !== this.state.selectedTableJobType) {
				sortType = "desc"
			}
			sortData = _.orderBy(selectedJobDetails, [sortBy], [sortType])
		}

		this.setState({ filteredJobTableData: sortData, selectedTableJobType: sortBy, [sortBy+"_sort"]: sortType })
	}

	getJobDailyCount = (type) => {
		let params = {}
		params.radial = true
		if(this.state.selectedDuration === "custom") {
			params.start_time = this.state.start_time
			params.end_time = this.state.end_time
		} else {
			params.duration = this.state.selectedDuration
		}
		if(this.state.choosenAccount && this.state.choosenAccount !== "") {
			params.account_id = this.state.choosenAccount
		}

		if(type === "job_type") {
			params.aggregate_by = [type]
		} else if(type === "status") {
			params.status = ["COMPLETED", "FAILED"]
			params.aggregate_by = ["status"]
			params.radial = true
		}

		this.props.getJobDailyCount(params, (promise, response) => {
			if(promise) {
				if(type === "job_type") {
					this.setState({ jobType: response },
						() => this.jobTypeTrend()
					)
				} else if(type === "status") {
					this.setState({ jobStatus: response },
						() => this.jobStatusTrend()
					)
				} else {
					this.setState({ jobDailyCount: response })
				}
			} else {
				this.setState({ jobDailyCount: {} })
			}
		})
	}

	jobTypeTrend = () => {

		let results = this.state.jobType && this.state.jobType.results && this.state.jobType.results.length ? this.state.jobType.results[0] : {}
		let graphData = {}
		if(results.data && Object.entries(results.data).length) {
			graphData.labels = results.dates
			Object.entries(results.data).forEach(([key, value]) => {
				graphData[key] = value
			})
		}		

		this.setState({ jobTypeTrendData: graphData })
	}

	getCpuMemoryHourDetails = () => {
		let params = {}
		if(this.state.selectedDuration === "custom") {
			params.start_time = this.state.start_time
			params.end_time = this.state.end_time
		} else {
			params.duration = this.state.selectedDuration
		}
		if(this.state.choosenAccount && this.state.choosenAccount !== "") {
			params.account_id = this.state.choosenAccount
		}
		params.sum_aggregate_by = this.state.trendGraphs
		params.duration_aggregate_by = "hourly"

		this.props.getJobDailyCount(params, (promise, response) => {
			if(promise) {
				let results = response && response.results && response.results.length ? response.results[0] : {}
				this.setState({ cpuMemoryData: results },
					() => this.cpuMemoryTrend()	
				)
			} else {
				this.setState({ cpuMemoryData: {} })
			}
		})
	}

	cpuMemoryTrend = () => {
		let results = this.state.cpuMemoryData
		if(results.data && Object.entries(results.data).length) {
			Object.entries(results.data).forEach(([key, value]) => {
				let graphData = {}
				graphData.labels = results.dates
				graphData[key] = value

				this.setState({ [key+"_graph_data"]: graphData })
			})
		}
	}

    resetGraph = (type) => {
        let graphData = this.state[type]
        this.setState({ [type]: "" },
            () => {
                this.setState({ [type]: graphData })
            }
        )
    }

	getJobHistogram = () => {
		let params = {}
		if(this.state.selectedDuration === "custom") {
			params.start_time = this.state.start_time
			params.end_time = this.state.end_time
		} else {
			params.duration = this.state.selectedDuration
		}
		if(this.state.choosenAccount && this.state.choosenAccount !== "") {
			params.account_id = this.state.choosenAccount
		}

		this.props.getJobHistogram(params, (promise, response) => {
			if(promise) {
				this.setState({ jobHistogram: response },
					() => this.histogramChart()	
				)
			} else {
				this.setState({ jobHistogram: {} })
			}
		})
	}	

    histogramChart = () => {
        let categories = []
        let data = []
        if(this.state.jobHistogram && this.state.jobHistogram.length) {
            categories = this.state.jobHistogram[0].data ? Object.keys(this.state.jobHistogram[0].data) : []
			data = this.state.jobHistogram[0].data ? Object.values(this.state.jobHistogram[0].data) : []
			// this.state.jobHistogram[0].data && Object.values(this.state.jobHistogram[0].data).forEach(item => {
			// 	data.push(countFormater(item, "round"))
			// })
			// console.log("data", data)
        } else {
            let histogramData = {
                "<1s": 0,
                "1s-5s": 5,
                "5s-10s": 0,
                "10s-30s": 0,
                "30s-60s": 12,
                ">=60s": 0
            }

            categories = Object.keys(histogramData)
            data = Object.values(histogramData)
        }

        let graphData = {}
        graphData.labels = categories
        graphData.data = data

        this.setState({ histogramGraphData: graphData })
    }

	jobStatusTrend = () => {
		let results = this.state.jobStatus && this.state.jobStatus.results && this.state.jobStatus.results.length ? this.state.jobStatus.results[0] : {}
		let graphData = {}
		if(results.data && Object.entries(results.data).length) {
			graphData.labels = results.dates
			Object.entries(results.data).forEach(([key, value]) => {
				if(this.state.selectedTrendType === "All" || this.state.selectedTrendType === key) {
					if(key === "COMPLETED") {
						key = "SUCCESS"
					}
					graphData[capitalizeFirstLetter(key)] = value
				}
			})
		}

		this.setState({ jobStatusData: graphData })
	}
	
	/**
	 * Filter identifiers based on the filter selection
	 */
	filterIdentifiers = from => {
		if (from === "organization") { //userOrg selectedUserOrgType
			let accArray = this.state.accounts
			if(this.state.selectedOrganization !== "" && this.state.selectedOrganization !== "All") {
				let formArray = accArray.filter(e => e.organization === this.state.selectedOrganization)

				this.setState({
					accArray: formArray,
					accountFilter: formArray,
					selectedAccount: [],
					choosenAccount: "",
				},
					() => this.formAccountFilter()
				)
				
			} else {
				this.setState({
					accArray,
					accountFilter: this.state.accountArray,
				})
			}
		}	
	}
	
	formAccountFilter = () => {
		let accountFilter = this.state.accountFilter
		if(accountFilter.length) {
			let accRow = {}
			accRow.account_id = "All"
			accRow.account_name = "All"
			accRow.date = null
			accRow.disabled = false
			accRow.provider = "All"
			accRow.organization = "All"
			accountFilter.unshift(accRow);
	
			this.setState({ accountFilter })
		}
	}

	handleMultiSelectChange = (field, arrayValue) => {
		let value = arrayValue.map(item => item.value)

		let selectedValue = []
		if(field === "selectedAccount") {
			let prevState = this.state[field]
			if(value.includes("All")) {
				if(!prevState.includes("All")) {
					this.state.accounts.forEach(acc => {
						selectedValue.push(acc.account_id)
					})
				} else {
					const index = value.indexOf("All");
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes("All")) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes("All")) {
				if(!selectedValue.includes("All") && selectedValue.length === (this.state.accounts.length -1)) {
					selectedValue.push("All")
				}
			}	
            this.setState({ [field]: selectedValue, changesInFilter: true })		
		}
	}
	
	handleClickOutside(event) {
		if(this.durationRef && this.durationRef.current && !this.durationRef.current.contains(event.target)) {
			if (this.dayPickerRef && !this.dayPickerRef.current.contains(event.target)) {
				this.setState({ showDatepicker: false })
				this.handleResetClick()
			}
		}
		
		if (this.accountRef && this.accountRef.current && !this.accountRef.current.contains(event.target)) {
			this.setState({ isAccountOpen: false })
		} else {
			this.setState({ isAccountOpen: true })
		}

		if (this.toggleTrendTypeRef && this.toggleTrendTypeRef.current && !this.toggleTrendTypeRef.current.contains(event.target)) {
			this.setState({ showTrendType: false })
		} else {
			this.setState({ showTrendType: !this.state.showTrendType })
		}

		if (this.toggleRef && this.toggleRef.current && !this.toggleRef.current.contains(event.target)) {
			this.setState({ showJobTypeRadialOptions: false })
		} else {
			this.setState({ showJobTypeRadialOptions: !this.state.showJobTypeRadialOptions })
		}

		if (this.toggleAccountRef && this.toggleAccountRef.current && !this.toggleAccountRef.current.contains(event.target)) {
			this.setState({ showAccountSortOptions: false })
		} else {
			this.setState({ showAccountSortOptions: !this.state.showAccountSortOptions })
		}

		if (this.toggleListTypeTableRef && this.toggleListTypeTableRef.current && !this.toggleListTypeTableRef.current.contains(event.target)) {
			this.setState({ showSortByOptions: false })
		} else {
			this.setState({ showSortByOptions: !this.state.showSortByOptions })
		}

	}
	
	getInitialState() {
		return {
			from: undefined,
			to: undefined,
		};	
	}

	handleResetClick = () => {
		this.setState(() => this.getInitialState());
	}

	handleDayClick(day) {
		if(this.state.from === undefined) {
			const range = DateUtils.addDayToRange(day, this.state);
			this.setState({ 
				range, 
				from: day, 
				durationTypeFrom: moment(range.from).format("DD MMM"), 
				start_time: datestartEndTime(day, "start"),
				changesInFilter: true,
			})
		} else {
			const newRange = {
				from: this.state.from,
				to: day
			}
			var Difference_In_Time = day.getTime() - this.state.from.getTime(); 			
			var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
			// if(Difference_In_Days < 6) {
			// 	alert("Please select 7 days for better view")
			// 	return;
			// }
			// let durtaion = "+"+(Difference_In_Days+1)+"d";
			if(Difference_In_Days > 0) {
				const range = DateUtils.addDayToRange(day, newRange);
				this.setState({ 
					range, 
					to: day,
					showDatepicker: false, 
					selectedDuration: "custom",
					durationType: this.state.durationTypeFrom + " - " + moment(range.from).format("DD MMM"),
					end_time: datestartEndTime(day, "end"),
					changesInFilter: true,
				},
					() => this.handleResetClick()
				)
			}
		}
	}

	onClickJobType = (selectedJob) => {
		this.setState({ showJobDetailsPanel: true, selectedJob })
	}

	onClickHistogramData = (key, value) => {
		let dataRow = {
			key: key,
			value: value
		}
		this.setState({ showJobListPanel: true, selectedHistoGramData: dataRow })
	}	

	/**
	 * Renders Asset Board
	 */
	render() {
		const { from, to } = this.state;
		const modifiers = { start: from, end: to };
		return (
			<div className="container-fluid overflow-auto flex-grow-1 bg-muted" onClick={ (event) => { this.handleClickOutside(event) } }>
				<div className={`loaderOverlay ${this.state.showLoading ? "" : "displayNone"}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
				{this.state.showJobDetailsPanel ?
					<JobDetailPanel
						closeJobDetailPanel= {() => this.setState({ showJobDetailsPanel: false })} 
						selectedJob= {this.state.selectedJob}
						selectedDuration= {this.state.selectedDuration}
						choosenAccount= {this.state.choosenAccount}
						start_time= {this.state.start_time}
						end_time= {this.state.end_time}
						trendGraphs= {this.state.trendGraphs}
						trendGraphColors= {this.state.trendGraphColors}
					/>
				: null}
				{this.state.showJobListPanel ?
					<JobListPanel
						closeJobListPanel= {() => this.setState({ showJobListPanel: false })} 
						selectedHistoGramData= {this.state.selectedHistoGramData}
						selectedDuration= {this.state.selectedDuration}
						choosenAccount= {this.state.choosenAccount}
						start_time= {this.state.start_time}
						end_time= {this.state.end_time}
					/>
				: null}
				<div className="row h-100">
					<div className="col-sm-12 p-0">
						<div className="title-section p-2">
							<div className="d-flex">
								<div className="col-lg-4 pl-2">
									<h6 className="text-white m-0">Operational Summary</h6>
									<p className="text-white mb-2">Vero sed expedita sint tenetur voluptatibus. Tenetur iure ipsum sint et.</p>
								</div>
								<div className={`col-lg-8 pl-0`}>
									<div className={`d-flex justify-content-end`}>
										<div className={`form-group my-2 flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} style={{maxWidth: "200px" }}>
											<label className="mb-1">Organizations</label>
                                            <Select
                                                placeholder={"Select"}
                                                isSearchable={true}
                                                // menuIsOpen={true}
                                                className="f13 p-0 colorBlack reactSelectFilterDrop"
                                                value={({
                                                    value: this.state.selectedOrganization && this.state.selectedOrganization !== "" ? this.state.selectedOrganization : "Select",
                                                    label: this.state.selectedOrganization && this.state.selectedOrganization !== "" ? this.state.selectedOrganization : <span className="placeholder">Select</span>
                                                })}
                                                options={this.state.organizations && this.state.organizations.map(item => ({
                                                    value: item.organization,
                                                    label: item.org_name,	
                                                }))}
                                                onChange={event => this.setState({
                                                    selectedOrganization: event.value,
                                                    changesInFilter: true,
                                                })}
                                            />
										</div>
										<div className={`form-group my-2 flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} ref={this.accountRef} style={{maxWidth: "200px" }}>
											<label className="mb-1">Account</label>
											<div className={`filterMultiSelectDropdown ${this.state.accounts && this.state.accounts.length > 10 ? "" : "removeDropdownSearchBar"}`}>
												<ReactMultiSelectCheckboxes						
													placeholderButtonLabel="All"
													menuIsOpen ={this.state.isAccountOpen}
                                                    value={this.state.selectedAccount.map(item => ({
                                                        value: item,
                                                    }))}
													onChange={arr => {
													this.handleMultiSelectChange("selectedAccount", arr ? arr : []) }}
													options={this.state.accountFilter && this.state.accountFilter.map(account => ({
														value: account.account_id,
														label: account.account_name,
													}))}
												/>
											</div>
										</div>								
										<div className="form-group my-2 flex-fill bd-highlight mb-sm-0 styled-feild mr-3" style={{maxWidth: "200px" }} ref={this.durationRef}>
											<label className="w-100">Duration
												{this.state.durationType !== "" ? <small className="pl-2">{this.state.durationType}</small> : ""}
											</label>
											<select
												className="form-control"
												value={this.state.selectedDuration}
												onChange={event => {
													if(event.target.value !== "custom") {
														this.setState({
															durationType: "",
															selectedDuration: event.target.value,
															showDatepicker: false,
															changesInFilter: true,
														},
															() => {
																this.filterIdentifiers("duration")															
															}
														)
													} else {
														this.setState({ showDatepicker: true })
													}
												}}
											>
												{CUSTOM_DURATION_FILTER_OPTIONS.map((item, index) => {
													return (
														<option key={index} value={item.value}>
															{item.option}
														</option>
													)
												})}
											</select>
										</div>

										<div className={`align-self-center text-center`}>
											<div className="btn-group" role="group" aria-label="ops-summary example">
												<button type="button" className={`${this.state.showLoading ? "disabled" : "cursorPointer"} btn btn-secondary border-right`} onClick={() => this.onSearch() }>Search</button>
												<button type="button" className={`${this.state.showLoading ? "disabled" : "cursorPointer"} btn btn-secondary border-left`} onClick={() => this.onReset()}><i className="far fa-redo"></i></button>
											</div>
										</div>
									</div>
									
									<div className={`RangeExample customDayPicker ${this.state.showDatepicker ? "" : "displayNone"}`} ref={this.dayPickerRef}>
										<DayPicker
											toMonth={new Date()}
											disabledDays={[
												{
													after: new Date(),
													before: (new Date(this.state.pervious90Days))
												},
											]}
											className="Selectable"
											numberOfMonths= {3}																			
											selectedDays={[from, { from, to }]}
											modifiers={modifiers}
											onDayClick={this.handleDayClick}
											tabIndex={0}
										/>
										<div className="col-sm-12">
											<button className="resetBtn f15 float-right mb-1" onClick={this.handleResetClick}>Reset</button>
										</div>
									</div>										
								</div>
							</div>
						</div>
						
						<div className="p-2">
							{/* <div className="col-12">
								<h5>Operational Summary</h5>
								<p className="text-muted">Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim.</p>
							</div> */}
							<div className="d-flex">
								<div className="col-sm-3 pl-0">
									<div className="bg-dark rounded overflowYAuto" style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
										<div className="d-flex m-2">
											<div className="box bg-dark3 d-flex p-2 shadow-none w-33 mr-2 justify-content-center cursporPointer" id="totalId">
												<div className="mr-2">
													<h7 className="font-weight-bold m-0">Total:&nbsp;&nbsp;<span className="text-white">{countFormater((this.state.jobTotalCount && this.state.jobTotalCount.COMPLETED ? this.state.jobTotalCount.COMPLETED : 0) + (this.state.jobTotalCount && this.state.jobTotalCount.FAILED ? this.state.jobTotalCount.FAILED : 0), "round")}</span></h7>
												</div>
											</div>
											<div className="box bg-dark3 d-flex p-2 shadow-none w-33 mr-2 justify-content-center cursporPointer" id="successId">
												<div className="mr-2">
													<h7 className="font-weight-bold m-0">Success:&nbsp;&nbsp;<span className="text-white">{this.state.jobTotalCount && this.state.jobTotalCount.COMPLETED ? countFormater(this.state.jobTotalCount.COMPLETED, "round") : "-"}</span></h7>
												</div>
											</div>
											<div className="box bg-dark3 d-flex p-2 shadow-none w-33 justify-content-center cursporPointer" id="failId">
												<div className="mr-2">
													<h7 className="font-weight-bold m-0">Failed:&nbsp;&nbsp;<span className="text-white">{this.state.jobTotalCount && this.state.jobTotalCount.FAILED ? countFormater(this.state.jobTotalCount.FAILED, "round") : "-"}</span></h7>
												</div>
											</div>										
										</div>                      
										<UncontrolledTooltip placement="top" target={"totalId"}>
											<div className="uncontrolledTooltipLeftAlign">
												<p className="mb-1 text-primary-color"><span className="text-white mr-1">Total: </span> {(this.state.jobTotalCount && this.state.jobTotalCount.COMPLETED ? this.state.jobTotalCount.COMPLETED : 0) + (this.state.jobTotalCount && this.state.jobTotalCount.FAILED ? this.state.jobTotalCount.FAILED : 0)}</p>
												<p className="mb-1 text-primary-color"><span className="text-white mr-1">Success: </span> {this.state.jobTotalCount && this.state.jobTotalCount.COMPLETED ? this.state.jobTotalCount.COMPLETED : 0}</p>
												<p className="mb-1 text-primary-color"><span className="text-white mr-1">Failed: </span> {this.state.jobTotalCount && this.state.jobTotalCount.FAILED ? this.state.jobTotalCount.FAILED : 0}</p>
											</div>
										</UncontrolledTooltip>
										<UncontrolledTooltip placement="top" target={"successId"}>
											<div className="uncontrolledTooltipLeftAlign">
												<p className="mb-1 text-primary-color"><span className="text-white mr-1">Success: </span> {this.state.jobTotalCount && this.state.jobTotalCount.COMPLETED ? this.state.jobTotalCount.COMPLETED : 0}</p>
											</div>
										</UncontrolledTooltip>
										<UncontrolledTooltip placement="top" target={"failId"}>
											<div className="uncontrolledTooltipLeftAlign">
												<p className="mb-1 text-primary-color"><span className="text-white mr-1">Failed: </span> {this.state.jobTotalCount && this.state.jobTotalCount.FAILED ? this.state.jobTotalCount.FAILED : 0}</p>
											</div>    
										</UncontrolledTooltip>
										
										<div className="pt-1">
											<p className="pl-1 mb-2 small text-white">Showing {this.state.filteredAccountsJobList && this.state.filteredAccountsJobList.length} of Total {this.state.accountsJobList && this.state.accountsJobList.length} Account(s)</p>
											<div className="d-flex justify-content-end mb-3 px-3">
												<p className="mb-0 align-self-center small mr-2">Sort&nbsp;By</p>
												<div className="select-sm-bg curvedDropdown minWidth120 mr-2" ref={this.toggleAccountRef}>
													<div className="dropdown">
														<span className="dropdown-toggle d-flex justify-content-between px-2 text-white">
															{this.state.selectedAccSortBy ? this.state.accSortOptions.filter(e => e.value === this.state.selectedAccSortBy)[0].label : <span className="placeholder">Select</span>}
														</span>
														<ul className={`dropdown-menu p-0 ${this.state.showAccountSortOptions ? "show" : ""}`}>
															{this.state.accSortOptions.map(item => {
																return (
																	<li 
																		onClick={() => this.sortAccounts("onChange", item.value)}
																		className={`${this.state.selectedAccSortBy === item.value ? "selected" :  ""}`}
																	>
																		{item.label}
																	</li>
																)
															})}
														</ul>
													</div>
												</div>
												<div className="">
													{this.state.accountsJobList && this.state.accountsJobList.length ?
														<SearchWithHiddenInput
															data={this.state.accountsJobList}
															applyLiteDarkTags={true}
															searchClassName={"border-0"}
															searchIconColor={"text-gray3"}
															className="form-control-sm text-gray3 bg-muted border-0"
															hideInputSection={true}
															topClassName={"dark-search"}
															searchPlaceHolder={"Search...."}
															filteredData={(filteredAccountsJobList) => this.setState({ filteredAccountsJobList })}
														/>
													: null}
												</div>
											</div>
											{this.state.filteredAccountsJobList && this.state.filteredAccountsJobList.length ? 
												this.state.filteredAccountsJobList.map((item, i) => {
													return(
														<React.Fragment>
															<div className={`cursorPointer ${this.state.choosenAccount === item.account_id ? "bg-dark3" : ""} px-3 ${i !== 0 ? "border-top" : ""} cursporPointer`} onClick={() => this.onChangeAccFunction(item.account_id) } id={`accountDetails_`+i}>
																<div className={`d-flex justify-content-between py-2`}>
																	<div className="w-30 align-self-center">
																		<h7 className="font-weight-bold text-white">{capitalizeFirstLetter(getAccountNameFromId(item.account_id, this.state.accounts))}</h7>
																		<p className="f12 m-0">{item.account_id}</p>
																	</div>
																	<div className="box bg-muted d-flex p-2 shadow-none w-70">
																		<div className="mr-2 w-20">
																			{/* <p className="font-weight-bold m-0 small text-center">Success</p> */}
																			<p className="font-weight-bold mb-0 small text-warning text-center">{item.success && item.success ? countFormater(item.success, "round") : "-"}</p>
																			<p className="font-weight-bold mb-0 small text-dark-theme-orange text-center">{item.failed && item.failed ? countFormater(item.failed, "round") : "-"}</p>
																		</div>
																		{/* <div className="mr-2 w-20">
																			<p className="font-weight-bold m-0 small text-center">Fail</p>
																			<p className="font-weight-bold mb-0 small text-dark-theme-orange text-center">{item.failed && item.failed ? item.failed : "-"}</p>
																		</div> */}
																		<div className="mr-2 w-20">
																			<p className="font-weight-bold m-0 small text-center">Count</p>
																			<p className="font-weight-bold mb-0 small text-center">{item.count && item.count ? countFormater(item.count, "round") : "-"}</p>
																		</div>
																		<div className="mr-2 w-20">
																			<p className="font-weight-bold m-0 small text-center">Time(hrs)</p>
																			<p className="font-weight-bold mb-0 small text-center">{item.sum_hours && item.sum_hours ? countFormater(item.sum_hours, "round") : "-"}</p>
																		</div>
																		<div className="mr-2 w-20">
																			<p className="font-weight-bold m-0 small text-center">CPU(hrs)</p>
																			<p className="font-weight-bold mb-0 small text-center">{item.cpu_hours && item.cpu_hours ? countFormater(item.cpu_hours, "round") : "-"}</p>
																		</div>
																		<div className="mr-2">
																			<p className="font-weight-bold m-0 small text-center">Memory(hrs)</p>
																			<p className="font-weight-bold mb-0 small text-center">{item.memory_hours && item.memory_hours ? countFormater(item.memory_hours, "round") : "-"}</p>
																		</div>
																	</div>
																</div>
															</div>
															
															<UncontrolledTooltip placement="top" target={`accountDetails_`+i}>
																<div className="uncontrolledTooltipLeftAlign">
																	<p className="mb-1 text-primary-color"><span className="text-white mr-1">Account Name: </span> {getAccountNameFromId(item.account_id, this.state.accounts)}</p>
																	<p className="mb-1 text-primary-color"><span className="text-white mr-1">Account Id: </span> {item.account_id}</p>
																	<p className="mb-1 text-warning"><span className="text-white mr-1">Success: </span> {item.success}</p>
																	<p className="mb-1 text-dark-theme-orange"><span className="text-white mr-1">Failed: </span> {item.failed}</p>
																	<p className="mb-1 text-primary-color"><span className="text-white mr-1">Count: </span> {item.count}</p>
																	<p className="mb-1 text-primary-color"><span className="text-white mr-1">Total Time Hours: </span> {item.execution_time_in_hours && item.execution_time_in_hours.sum && item.execution_time_in_hours.sum ? item.execution_time_in_hours.sum : "-"}</p>
																	<p className="mb-1 text-primary-color"><span className="text-white mr-1">Total Time Seconds: </span> {item.execution_time_in_secs && item.execution_time_in_secs.sum && item.execution_time_in_secs.sum ? item.execution_time_in_secs.sum : "-"}</p>
																	<p className="mb-1 text-primary-color"><span className="text-white mr-1">CPU Hours: </span> {item.cpu_hours}</p>
																	<p className="mb-1 text-primary-color"><span className="text-white mr-1">Memory Hours: </span> {item.memory_hours}</p>
																</div>    
															</UncontrolledTooltip>

														</React.Fragment>
													)
												})
											:
												<div className="d-flex justify-content-center m-4">
													<p>There are no data on this criteria. Please try adjusting your search.</p>
												</div>
											}
										</div>
									</div>
								</div>
								<div className="col-sm-9 p-0">
									<div className="bg-dark rounded p-2 overflowYAuto" style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
										<div className="d-flex">
											{this.state.jobStatusData && Object.entries(this.state.jobStatusData).length ?
												<div className="col-sm-5 pl-0">
													<div className="bg-dark3 p-2 rounded">
														<div className="d-flex justify-content-between">
															<div className="w-90">
																<p className="text-white mb-0">Success/Fail Trend</p>
																<p className="small mb-0">Showing success and fail type trend</p>
															</div>
															<div className="d-flex">
																<div className="select-sm-bg curvedDropdown minWidth120 mr-3" ref={this.toggleTrendTypeRef}>
																	<div className="dropdown">
																		<span className="dropdown-toggle d-flex justify-content-between px-2 text-white">
																			{this.state.selectedTrendType ? this.state.trendTypes.filter(e => e.value === this.state.selectedTrendType)[0].label : <span className="placeholder">Select</span>}
																		</span>
																		<ul className={`dropdown-menu p-0 ${this.state.showTrendType ? "show" : ""}`}>
																			{this.state.trendTypes.map(item => {
																				return (
																					<li 
																						onClick={() => {
																							this.setState({ 
																								selectedTrendType: item.value,
																								jobStatusData: {}
																							},
																								() => this.jobStatusTrend()
																							)
																						}}
																						className={`${this.state.selectedTrendType === item.value ? "selected" :  ""}`}
																					>
																						{item.label}
																					</li>
																				)
																			})}
																		</ul>
																	</div>
																</div>
																<i className="far fa-redo cursorPointer align-self-center" onClick={() => this.resetGraph("jobStatusData")}></i>
															</div>
														</div>
														<ApexStackedBarChart 
															graphData={this.state.jobStatusData}
															sparkline={false}
															zoom={true}
															yaxis={true}
															yaxisLabel={true}
															xaxis={true}
															xaxisFormat={"datetime"}
															xaxisLabel={true}
															axisLabelColor={"#50586a"}
															paddingLeft={0}
															legend={false}
															stacked={true}
															height={180}
															horizontal={false}
															barHeight={"40%"}
															barEndShape={"flat"}
															columnWidth={"25%"}
															gradient={true}
															gradientColor={["#FFD200", "#B96422"]}
															colors={["#FFD200", "#B96422"]}
															hideTooltipValue={true}
															backgroundBarShape={"flat"}
															backgroundBarColors={["#333947"]}
															showBackgroundBarColors={false}
															className={"transparentTooltip"}
														/>
													</div>
												</div>
											: null}
											{this.state.histogramGraphData && Object.entries(this.state.histogramGraphData).length ?
												<div className="col-sm-7 p-0">
													<div className="bg-dark3 p-2 rounded">
														<div className="d-flex">
															<div className="col-sm-8">
																<div className="d-flex justify-content-between">
																	<div className="w-90">
																		<p className="text-white mb-0">Job Histogram</p>
																		<p className="small mb-0">Showing job histogram trend</p>
																	</div>
																	{/* <i className="far fa-redo cursorPointer align-self-center" onClick={() => this.resetGraph("histogramGraphData")}></i> */}
																</div>
																<ApexBarChart
																	graphData={this.state.histogramGraphData}
																	sparkline={false}
																	zoom={false}
																	chartDistributed={true}
																	yaxis={true}
																	yaxisLabel={true}
																	// yaxisTickAmont={3}
																	xaxis={true}
																	xaxisFormat={"string"}
																	xaxisLabel={true}
																	axisLabelColor={"#50586a"}
																	axisLabelFontSize={"12px"}
																	paddingLeft={-25}
																	legend={false}
																	stacked={false}
																	height={180}
																	horizontal={false}
																	barHeight={"40%"}
																	barEndShape={"flat"}
																	columnWidth={"45%"}
																	gradient={false}
																	gradientColor={["#039BE5", "#5F5BA2"]}
																	hideTooltipValue={false}
																	backgroundBarShape={"flat"}
																	backgroundBarColors={["#333947"]}
																	showBackgroundBarColors={false}
																	className={"transparentTooltip"}
																	colors={["#A88CCC", "#D88ACF",	"#FE93B4",	"#FFAE90",	"#EDD482",	"#CFF69C",	"#97F9A3",	"#77ECC8",	"#7BCCE7"]}
																/>
															</div>
															<div className="col-sm-4 pr-0">
																<div className="bg-dark2 p-2 mt-2 rounded">
																	{this.state.jobHistogram && this.state.jobHistogram.length && this.state.jobHistogram[0].data && Object.keys(this.state.jobHistogram[0].data).length ?
																		Object.entries(this.state.jobHistogram[0].data).map(([key, value], j) => {
																			return (
																				<React.Fragment>
																				{!j ?
																					<div class="d-flex">
																						<p className="m-0 pb-2 w-50 border-right-info text-white text-center">Key</p>
																						<p className="m-0 w-50 text-white text-center">Value</p>
																					</div>
																				: null}
																				<div class="d-flex" onClick={() => this.onClickHistogramData(key, value)}>
																					<p className="m-0 pb-2 w-50 border-right-info">{key}</p>
																					<p className="m-0 pb-2 w-50 pl-2 text-center">{value}</p>
																				</div>

																				</React.Fragment>
																			)
																		})
																	: null}
																</div>
															</div>
														</div>
													</div>
												</div>
											: null}
											{this.state.hiddenDiv ?
												<div className="w-25 ml-2">
													<div className="d-flex justify-content-between mb-2">
														<div class="bg-dark3 px-2 py-1 rounded w-50">
															<p class="text-success-50 mb-1">10 sec</p>
															<div class="bg-success-3 small-divder"></div>
															<p class="text-success-50 mt-1 mb-0">Min</p>
														</div>
														<div class="bg-dark3 px-2 py-1 rounded w-50 ml-2">
															<p class="text-info-50 mb-1">20 sec</p>
															<div class="bg-info small-divder"></div>
															<p class="text-info-50 mt-1 mb-0">Avg</p>
														</div>
													</div>
													<div className="d-flex justify-content-between mb-2">
														<div class="bg-dark3 px-2 py-1 rounded w-50">
															<p class="text-success-50 mb-1">30 sec</p>
															<div class="bg-success-3 small-divder"></div>
															<p class="text-success-50 mt-1 mb-0">Max</p>
														</div>
														<div class="bg-dark3 px-2 py-1 rounded w-50 ml-2">
															<p class="text-info-50 mb-1">25 sec</p>
															<div class="bg-info small-divder"></div>
															<p class="text-info-50 mt-1 mb-0">STD</p>
														</div>
													</div>
													<div className="d-flex justify-content-between">
														<div class="bg-dark3 px-2 py-1 rounded w-50">
															<p class="text-success-50 mb-1">12 sec</p>
															<div class="bg-success-3 small-divder"></div>
															<p class="text-success-50 mt-1 mb-0">Med</p>
														</div>
														<div class="bg-dark3 px-2 py-1 rounded w-50 ml-2">
															<p class="text-info-50 mb-1">28 sec</p>
															<div class="bg-info small-divder"></div>
															<p class="text-info-50 mt-1 mb-0">95%</p>
														</div>
													</div>
												</div>
											: null}
										</div>

										<div className="col-sm-12">
											<div className="row">
												{this.state.trendGraphs ?
													this.state.trendGraphs.map((item ,index) => {
														return(
														this.state[item+"_graph_data"] && Object.entries(this.state[item+"_graph_data"]).length ?
															<div className={`col-sm-6 ${index%2 ? "p-0" : "pl-0"} mt-2`}>
																<div className="bg-dark3 p-2 rounded">
																	<div className="d-flex justify-content-between">
																		<div className="w-90">
																			<p className="text-white mb-0">{item === "cpu_hours" ? "CPU Hours" : item === "memory_hours" ? "Memory Hours" : item === "vcpus" ? "CPU Usage" : item === "memory" ? "Memory Usage" : ""} Trend</p>
																			<p className="small mb-0">Showing {item === "cpu_hours" ? "CPU hours" : item === "memory_hours" ? "Memory hours" : item === "vcpus" ? "CPU usage" : item === "memory" ? "Memory Usage" : ""} trend</p>
																		</div>
																		<i className="far fa-redo cursorPointer align-self-center mr-2" onClick={() => this.resetGraph(item+"_graph_data")}></i>
																	</div>
																	<div className="d-flex justify-content-between">
																		<div className="w-80">
																			<ApexStackedBarChart 
																				graphData={this.state[item+"_graph_data"]}
																				sparkline={false}
																				zoom={true}
																				yaxis={true}
																				yaxisLabel={true}
																				xaxis={true}
																				xaxisFormat={"datetime"}
																				xaxisLabel={true}
																				axisLabelColor={"#50586a"}
																				paddingLeft={0}
																				legend={false}
																				stacked={true}
																				height={180}
																				horizontal={false}
																				barHeight={"40%"}
																				barEndShape={"flat"}
																				columnWidth={"25%"}
																				gradient={true}
																				gradientColor={[this.state.trendGraphColors[index]]}
																				colors={[this.state.trendGraphColors[index]]}
																				hideTooltipValue={true}
																				backgroundBarShape={"flat"}
																				backgroundBarColors={["#333947"]}
																				showBackgroundBarColors={false}
																				className={"transparentTooltip"}
																			/>
																		</div>
																		<div className="w-18">
																			<div className="bg-dark2 p-2 mt-2 rounded" style={{height: "180px"}}>
																				<div class="d-flex pb-2">
																					<p className="m-0 small">Total:</p>
																					<p className="m-0 pl-2 small">{this.state.cpuMemoryData && this.state.cpuMemoryData.total && this.state.cpuMemoryData.total[item] ? this.state.cpuMemoryData.total[item] : "-"}</p>
																				</div>
																				<div class="d-flex pb-2">
																					<p className="m-0 small">Min:</p>
																					<p className="m-0 pl-2 small">{this.state.cpuMemoryData && this.state.cpuMemoryData.min && this.state.cpuMemoryData.min[item] ? this.state.cpuMemoryData.min[item] : "-"}</p>
																				</div>
																				<div class="d-flex pb-2">
																					<p className="m-0 small">Max:</p>
																					<p className="m-0 pl-2 small">{this.state.cpuMemoryData && this.state.cpuMemoryData.max && this.state.cpuMemoryData.max[item] ? this.state.cpuMemoryData.max[item] : "-"}</p>
																				</div>
																				<div class="d-flex pb-2">
																					<p className="m-0 small">Avg:</p>
																					<p className="m-0 pl-2 small">{this.state.cpuMemoryData && this.state.cpuMemoryData.avg && this.state.cpuMemoryData.avg[item] ? this.state.cpuMemoryData.avg[item] : "-"}</p>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														: null
														)
													})
												: null}
											</div>
										</div>

										<div className="mt-2 d-flex">
											<div className="col-sm-6 pl-0">
												<div className="bg-dark3 p-2 rounded" ref={this.rightRadialStackedBarWidthRef}>
													{/* <p className="mb-0">By Controls</p> */}
													<div className="d-flex justify-content-between">
														<div className="">
															<p className="mb-0">Job Type</p>
															<p className="small text-muted">Showing job types</p>
														</div>
														<div className="select-sm-bg curvedDropdown minWidth120" ref={this.toggleRef}>
															<div className="dropdown">
																<span className="dropdown-toggle d-flex justify-content-between px-2 text-white">
																	{this.state.selectedJobType ? this.state.jobTypes.filter(e => e.value === this.state.selectedJobType)[0].label : <span className="placeholder">Select</span>}
																</span>
																<ul className={`dropdown-menu p-0 ${this.state.showJobTypeRadialOptions ? "show" : ""}`}>
																	{this.state.jobTypes.map(item => {
																		return (
																			<li 
																				onClick={() => {
																					this.setState({ 
																						selectedJobType: item.value,
																						showRadialChart: false
																					},
																						() => this.formRadialBarChart()
																					)
																				}}
																				className={`${this.state.selectedJobType === item.value ? "selected" :  ""}`}
																			>
																				{item.label}
																			</li>
																		)
																	})}
																</ul>
															</div>
														</div>
													</div>
													{this.state.showRadialChart ? 
														<StackedCircleBarChart
															ID={this.state.graphId}
															svgID={this.state.svgGraphId}
															width={this.rightRadialStackedBarWidthRef.current.offsetWidth}
															data={this.state.dataArray}
															keys={this.state.dataKeys}
															userHighestValue={true}
															highestValue={this.state.highestData}
															hoverData={this.state.totalData}
															totalValue={this.state.totalValue}
															selectedState={""}
															tableData={(data, State) =>{}}
															legendText={"Job Types"}
															colors={this.state.graphColors}					
														/>
													: null}
												</div>
											</div>
											<div className="col-sm-6 p-0">
												<div className="bg-dark3 p-2 rounded h-100">
													<div className="d-flex justify-content-between mb-2">
														<div className="">
															<p className="mb-0">Job Type List</p>
															<p className="small text-muted mb-0">Showing job types lsit</p>
														</div>
													</div>
													<div className="stickyTableHeader overflowYAuto" style={{minHeight: this.state.radialTableHeight, maxHeight: this.state.radialTableHeight}}>
														<table className="table text-white mb-0 tableLayout">
															<thead className="">
																<tr class="w-100 bg-dark2">                                    
																	<th className={`text-center w-50`} onClick={() => this.sortJobTypeTableData("onChange", "job_type")}> 
																		<div className="d-flex pl-1">
																		<i className={`fas fa-sort-${this.state.job_type_sort === "desc" ? "down" : (this.state.job_type_sort === "asc" ? "up" : "")} pr-1 align-self-center`}></i>
																			<span>Job Type</span>
																		</div>
																	</th>
																	<th className={`w-50`} onClick={() => this.sortJobTypeTableData("onChange", "total")}>
																		<div className="d-flex justify-content-center">
																			<i className={`fas fa-sort-${this.state.total_sort === "desc" ? "down" : (this.state.total_sort === "asc" ? "up" : "")} pr-1 align-self-center`}></i>
																			<span>Total</span>
																		</div>
																	</th>
																</tr>
															</thead>
															<tbody>
																{this.state.filteredJobTableData && this.state.filteredJobTableData.length ?
																	this.state.filteredJobTableData.map((item, i) => {
																		return (
																			<tr className={`w-100 border`} >
																				<td className="small pr-0 pl-2 py-1"><span className="text-primary-color">{i+1+". "}</span>{item.job_type}</td>
																				<td className="text-center small px-0 py-1">{item.total}</td>
																			</tr>
																		)
																	})
																:
																	<tr>
																		<td colSpan="5" className="text-center bg-transparent"><p className="mb-0">There are no data on criteria. Please try adjusting your filter.</p></td>
																	</tr>
																}
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>

										<div className="mt-2">
											<div className="bg-dark3 p-2 rounded">
												<div className="d-flex justify-content-between mb-1">
													<div className="">
														<p className="mb-0">Jobs List</p>
														<p className="small text-muted mb-0">Showing job types list</p>
													</div>
													<div className="d-flex">
														<p className="mb-0 align-self-center small mr-2">Sort&nbsp;By</p>
														<div className="select-sm-bg curvedDropdown minWidth120" ref={this.toggleListTypeTableRef}>
															<div className="dropdown">
																<span className="dropdown-toggle d-flex justify-content-between px-2 text-white">
																	{this.state.selectedJobSortBy ? this.state.jobSortOptions.filter(e => e.value === this.state.selectedJobSortBy)[0].label : <span className="placeholder">Select</span>}
																</span>
																<ul className={`dropdown-menu p-0 ${this.state.showSortByOptions ? "show" : ""}`}>
																	{this.state.jobSortOptions.map(item => {
																		return (
																			<li 
																				onClick={() => {
																					this.sortJobAccDetails(this.state.filteredJobAccountDetails, item.value)
																				}}
																				className={`${this.state.selectedJobSortBy === item.value ? "selected" :  ""}`}
																			>
																				{item.label}
																			</li>
																		)
																	})}
																</ul>
															</div>
														</div>
													
														{this.state.jobAccountDetails && this.state.jobAccountDetails.length ?
															<Search
																data={this.state.jobAccountDetails ? this.state.jobAccountDetails : []}
																applyTags={false}
																applyLiteDarkTags={true}
																topClassName={"bg-black5 ml-3 mb-0 mr-sm-0 border-gray5 rounded-5 f12 align-self-start"}
																searchClassName={"px-2 f12"}
																searchIconColor={"text-gray5 f12"}
																searchPlaceHolder={"Search...."}
																className={"bg-transparent text-white pl-0 form-control-sm f12"}
																filteredData={(filteredJobAccountDetails) => {
																	this.setState({ filteredJobAccountDetails })
																}}
															/>
														: null}  
													</div>
												</div>
												<table className="table text-white mb-0 tableLayout border" >
													<thead className="">
														<tr class="w-100 bg-dark2">                                    
															<th className={`text-center`}>Job Type</th>
															<th className={`text-center`}>Frequency</th>
															<th className={`text-center`}>Runs</th>
															<th className={`text-center`}>Success/Failed</th>
															<th className={`text-center`} width="250px">Time (Hrs)</th>
															<th className={`text-center`}>CPU Hrs</th>
															<th className={`text-center`}>Memory Hrs</th>
															<th className={`text-center`}>Total Time</th>
														</tr>
													</thead>
													<tbody>
														{this.state.filteredJobAccountDetails && this.state.filteredJobAccountDetails.length ?
															this.state.filteredJobAccountDetails.map(item => {
																return (
																	<tr className={`w-100 border-top`} >
																		<td className="text-center" onClick={() => this.onClickJobType(item)}>{item.job_type}</td>
																		<td className="text-center">{item.frequency}</td>
																		<td className="text-center">{item.count}</td>
																		<td className="text-center">
																			<span className="text-warning">{(item.success ? item.success : 0)}</span>
																			<span className="mx-1">/</span>
																			<span className="text-dark-theme-orange">{(item.failed ? item.failed : 0)}</span>
																		</td>
																		<td className="text-center">
																			<React.Fragment>
																				<div className="d-flex">
																					<p className="mb-0 small"><span className="text-primary-color">Min: </span> {item.min_hours ? item.min_hours : 0 }</p>
																					<p className="mb-0 small ml-2"><span className="text-primary-color">Max: </span> {item.max_hours ? item.max_hours : 0 }</p>
																					<p className="mb-1 small ml-2"><span className="text-primary-color">Avg: </span> {item.avg_hours ? item.avg_hours : 0 }</p>
																				</div>
																				<div className="d-flex">
																					<p className="mb-0 small"><span className="text-primary-color ">P95: </span> {item.p95_hours ? item.p95_hours : 0 }</p>
																					<p className="mb-1 small ml-2"><span className="text-primary-color">P99: </span> {item.p99_hours ? item.p99_hours : 0 }</p>
																				</div>
																			</React.Fragment>
																		</td>
																		<td className="text-center">{item.cpu_hours}</td>
																		<td className="text-center">{item.memory_hours}</td>
																		<td className="text-center">
																			<p className="mb-0">{(item.sum_hours ? item.sum_hours : 0)} hrs / </p>
																			<p className="mb-0">{(item.sum_seconds ? item.sum_seconds : 0)}</p>
																		</td>
																	</tr>
																)
															})
														:
															<tr>
																<td colSpan="8" className="text-center bg-transparent"><p className="mb-0">There are no data on criteria. Please try adjusting your filter.</p></td>
															</tr>
														}
													</tbody>
												</table>
											</div>
										</div>

									</div>
								</div>									
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
operationalSummary.propTypes = { 
	listOrganizations: PropTypes.func,
	listAllAccountsDetails: PropTypes.func
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return { }
}

export default AppWrapper(operationalSummary, mapStateToProps, {
	listAllProviders,
	listOrganizations,
	listAllAccountsDetails,
	getJobTotalCount,
	getJobDetails,
	getJobDetailsByModule,
	getJobDailyCount,
	getJobHistogram,
})