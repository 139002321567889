/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file UserInsights.js
 * @author Prakash // on 05/06/2020
 * @copyright © 2020 Tvastar. All rights reserved.
 **************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
////import Chart from 'chart.js'
import { UncontrolledPopover, PopoverBody } from 'reactstrap'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import _ from 'lodash'
import { AppWrapper } from '../../common/AppWrapper'
import { listAllProviders, listAllAccounts, listAllServices } from '../../../actions/commonAction'
import {
	getIdentitiesList,
	lastAccessedDetails,
	getTotalCount,
	getTotalAssetsAccessed,
	getAccessTrend,
	listUsersAssumingRole,
	getAssetsAccessibleCount,
	getPermissionDetails,
	getActionsCategoryWise,
	getActionsServiceWise
} from '../../../actions/governance/userInsightsAction'
import Chart from 'react-apexcharts'
import Table2 from './Table2'
import moment from 'moment'
import { capitalizeFirstLetter } from '../../../utils/utility'
import InsightsSidePanel from './InsightsSidePanel'

import { Doughnut } from 'react-chartjs-2';

import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

const emptyOptions = []

const statusOptions = [
	{ label: 'Success', value: 'success'},
	{ label: 'Denied', value: 'denied'},
];

const accessMethodOptions = [
	{ label: 'API', value: 'API'},
	{ label: 'Console', value: 'Console'},
	{ label: 'CLI', value: 'CLI'},
	{ label: 'Service', value: 'Service'},
];

const actionOptions = [
	{ value: 'created', label: 'Created'},
	{ value: 'modified', label: 'Modified'},
	{ value: 'deleted', label: 'Deleted'},
];

class UserInsights extends Component {
	p = 0;
	wrapperRef = React.createRef()	
	serviceRef = React.createRef()
	userRef = React.createRef()
	accessRef = React.createRef()
	actionRef = React.createRef()
	statusRef = React.createRef()
	//serviceField = React.createRef()
	
	constructor(props) {
		super(props)		
		this.tabRef = React.createRef()		
		
		this.handleDayClick = this.handleDayClick.bind(this);
		this.handleResetClick = this.handleResetClick.bind(this);
		this.state = this.getInitialState();

		this.state = {
			isServiceOpen: false,
			isUserOpen: false,
			isActionOpen: false,
			isAccessOpen: false,
			isStatusOpen: false,

			showDetailsPanel: false,
			left: 0,
			showLoading: false,
			providers: [],
			accounts: [],
			services: [],
			serviceResourceType: [],
			definedService: [],
			selectedProvider: '',
			selectedAccount: '',
			selectedServiceType: 'iam-roles',
			selectedRole: '',
			selectedRoleType: '',
			selectedCreatedAt: '',
			selectedModifiedAt: '',
			selectedMangedPoliciesCount: '',
			selectedInlinePoliciesCount: '',
			selectedArn: '',
			selectedTags: '',
			selectedIdentities: [],
			identitiesList: [],
			totalAssetsAccessed: '',
			assetsAccessibleCount: '',
			selectedStatus: [],
			usersAssumingRole: [],
			selectedAssumedUser: [],
			selectedAccessMethod: [],
			selectedAction: [],
			lastAccessedDetails: [],
			getTotalCount: [],
			permissionOriginalData: [],
			permissionDetails: [],
			permissionFilterData: [],			
			accessTrend: [],
			trend_bar_data: [],
			trend_bar_option: [],
			selectedTrend:'daily',
			showGroupByLabel: 'Service',
			selectedGroupBy: 'service',
			selectedGroupByTags: '',
			selectedService: [],
			actionsCategoryWise: [],
			category_doughnut_data: [],
			actionsServiceWise: [],
			service_doughnut_data: [],
			durationSelector: 7,
			selectedDuration: '+7d',
			dayWiseCountGraphData: {},
			
			pervious90Days: moment().subtract('365', 'days'),
			showDatepickerBtn: false,
			selectedTableService: [],
			selectedTableStatus: [],
			assessTrendTable: [],
			columns: [],
			showServiceDropdown: false,
			showUserDropdown: false,
			showStatusDropdown: false,
			showActionDropdown: false,
			showAccessDropdown: false,

			applyFilterService: false,
			applyFilterUser: false,
			applyFilterAccess: false,
			applyFilterAction: false,
			applyFilterStatus: false,
			
			serviceIncludeType: '',
			userIncludeType: '',
			accessIncludeType: '',
			actionIncludeType: '',
			statusIncludeType: '',
		}
	}

	clearFilter = () => {
		this.setState({ selectedAssumedUser: [], selectedAccessMethod: [], selectedAction: [], from: undefined, to: undefined, selectedTrend:'daily', selectedGroupBy: 'service', showGroupByLabel: 'service', selectedGroupByTags: '', selectedService: [], selectedStatus: [], selectedDuration: '+7d', durationSelector: 7, showDatepickerBtn: false }, 
			() => this.trendChange()
		)
	}

	getSelectedGroupBy = (groupBy) => {
		let groupLabel = ''
		if(groupBy === 'access_method') {
			groupLabel = 'Access Method'
		} else if(groupBy === 'assumed_user') {
			groupLabel = 'Assumed User'
		} else if(groupBy === 'tag') {
			groupLabel = capitalizeFirstLetter(groupBy) +': '+ capitalizeFirstLetter(this.state.selectedGroupByTags)
		} else {
			groupLabel = capitalizeFirstLetter(groupBy)
		}
		
		this.setState({ selectedGroupBy: groupBy, showGroupByLabel: groupLabel},
			() => this.trendChange()
		)
		
	}

	getInitialState() {
		return {
		  from: undefined,
		  to: undefined,
		};
		
	}

	handleDayClick(day) {
		if(this.state.from === undefined) {
			const range = DateUtils.addDayToRange(day, this.state);
			this.setState({ range, from: day });
		} else {
			const newRange = {
				from: this.state.from,
				to: day
			}
			var Difference_In_Time = day.getTime() - this.state.from.getTime(); 			
			var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
			// if(Difference_In_Days < 6) {
			// 	alert('Please select 7 days for better view')
			// 	return;
			// }

			let durtaion = '+'+(Difference_In_Days+1)+'d';
			
			const range = DateUtils.addDayToRange(day, newRange);
			this.setState(
				{ range, to: day, showDatepicker: false, selectedDuration: durtaion },
				() => this.trendChange()
			);
		}
	}

	durationFinder = () => {
		let duration = this.state.durationSelector
		if(duration === 'custom') {
			this.setState({ from: undefined, to: undefined, showDatepickerBtn: true, selectedTrend: 'daily' })
		} else {
			let beginDate = moment().subtract(duration, 'days')
			let closeDate = moment()
			//console.log(closeDate._d)
			let selectedPeriod = '+'+this.state.durationSelector+'d';
			this.setState({ selectedDuration: selectedPeriod, from: beginDate._d, to: closeDate._d},
				() => this.trendChange()
			)
		}
	}

	handleClickOutside(event) {

        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
			this.setState({ showDatepicker: false })
			if(this.state.from === undefined || this.state.to === undefined) {
				this.setState({ from: undefined, to: undefined})
			}
		}

		if (this.serviceRef && !this.serviceRef.current.contains(event.target)) {
			this.setState({ isServiceOpen: false, showServiceDropdown: false })
		} else {
			this.setState({ isServiceOpen: true, showServiceDropdown: true })
		}
		
		if (this.userRef && !this.userRef.current.contains(event.target)) {
			this.setState({ isUserOpen: false, showUserDropdown: false })
		} else {
			this.setState({ isUserOpen: true, showUserDropdown: true })
		}

		if (this.actionRef && !this.actionRef.current.contains(event.target)) {
			this.setState({ isActionOpen: false, showActionDropdown: false })
		} else {
			this.setState({ isActionOpen: true, showActionDropdown: true })
		}

		if (this.accessRef && !this.accessRef.current.contains(event.target)) {
			this.setState({ isAccessOpen: false, showAccessDropdown: false })
		} else {
			this.setState({ isAccessOpen: true, showAccessDropdown: true })
		}

		if (this.statusRef && !this.statusRef.current.contains(event.target)) {
			this.setState({ isStatusOpen: false, showStatusDropdown: false })
		} else {
			this.setState({ isStatusOpen: true, showStatusDropdown: true })
		}
    }

	handleResetClick = () => {
		this.setState(() => this.getInitialState());
	}

	checkResetDatePicker = () => {
		if(this.state.from === undefined && this.state.to === undefined) {
		} else {
			if(this.state.from === undefined || this.state.to === undefined) {
				this.setState({ from: undefined, to: undefined, showDatepicker: false})
			}
		}
	}
	
	componentDidMount = () => {
		this.setState({ showLoading: true })
		this.props.listAllProviders((promise, providers) => {
			if (promise) {
				this.setState({ providers })
				if (providers.length > 0) {
					this.setState(
						{ selectedProvider: providers[0].provider_name },
					)
					this.filterIdentifiers('provider')
					this.setState({ showLoading: true })
				} else {
					this.filterIdentifiers()
				}
			} else {
				this.setState(
					{ providers: [] }, 
					() => this.filterIdentifiers()
				)
			}
		})
		
	}
	/**
	 * Filter identifiers based on the filter selection
	 */
	filterIdentifiers = from => {
		this.setState({ showLoading: false })
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'all') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if (from === 'provider') {
			this.props.listAllAccounts(params, (promise, accounts) => {
				if (promise) {
					this.setState({ accounts: accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase()), selectedAccount: accounts[0].account_id }, () => this.onLoadFunction() )
				} else {
					this.setState({ accounts: [] })
				}
			})
		}
	}

	onLoadFunction = () => {
		this.listAllServices()
		this.getIdentitiesList()		
	}

	componentWillUnmount() {
		//document.removeEventListener('mousedown', this.handleKeyDown, false)
	}

	handleKeyDown = (e) => {
		if(this.node.contain(e.target)) {
			return
		}
	}

	// handleRightClick = () => {
	// 	this.ref.rightScroller ? (this.ref.rightScroller.scrollRight +=200) : null
	// }

	// handleLeftClick = () => {
	// 	this.ref.leftScroller ? (this.ref.leftScroller.scrollLeft -=200) : null
	// }

	
	listAllServices = () => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		// params.account_id = this.state.selectedAccount
		this.props.listAllServices(params, (promise, services) => {
			if (promise) {
				let serviceArray = []
				let serviceResourceArray = []
				if(services) {
					services.length &&
					_.chain(services)
					.groupBy('resource_type')
					.map((value, key) => ({ label: key, sub: value }))
					.value()
					.forEach((service, i) => {
						serviceResourceArray.push(service)
						service.sub.forEach((item, i) => {
							serviceArray.push(item)
						})
					})
				}
				this.setState({ services, serviceResourceType: serviceResourceArray, definedService: serviceArray})
			} else {
				this.setState({ services: [] })
			}
		})
	}

	getIdentitiesList = () => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.account_id = this.state.selectedAccount
		params.service_name = this.state.selectedServiceType

		console.log('-------------getIdentitiesList-----------',params)
		this.props.getIdentitiesList(params, (promise, identitiesList) => {
			console.log('----------getIdentitiesList--------------',identitiesList)
			if (promise) {
				if(identitiesList.results.length) {
					if(this.state.selectedServiceType === 'iam-roles') {
						this.setState({ identitiesList: identitiesList.results, 	
							selectedRole: identitiesList.results[0].role_name, 
							selectedRoleType: identitiesList.results[0].role_type,
							selectedCreatedAt: identitiesList.results[0].created_at,
							selectedModifiedAt: identitiesList.results[0].modified_at,
							selectedMangedPoliciesCount: identitiesList.results[0].managed_policies_count,
							selectedInlinePoliciesCount: identitiesList.results[0].inline_policies_count,
							selectedArn: identitiesList.results[0].arn,
							selectedTags: identitiesList.results[0].tags },
							() => this.identitiesRoleFunction()
						)
					} else {
						this.setState({ identitiesList: identitiesList.results, 	
							selectedRole: identitiesList.results[0].user_name,
							selectedCreatedAt: identitiesList.results[0].created_at,
							selectedModifiedAt: identitiesList.results[0].modified_at,
							selectedMangedPoliciesCount: identitiesList.results[0].managed_policies_count,
							selectedInlinePoliciesCount: identitiesList.results[0].inline_policies_count,
							selectedArn: identitiesList.results[0].arn,
							selectedTags: identitiesList.results[0].tags },
							() => this.identitiesUserFunction()
						)
					}
				} else {
					this.setState({ identitiesList: [], totalAssetsAccessed: [], assetsAccessibleCount: [], usersAssumingRole: [], lastAccessedDetails: [], getTotalCount: [], permissionDetails: [], permissionFilterData: [], accessTrend: [],selectedRole: '', selectedRoleType: '', selectedCreatedAt: '', selectedModifiedAt: '', selectedMangedPoliciesCount: '', selectedInlinePoliciesCount: '', selectedArn: '', selectedTags: '', selectedAssumedUser: [], selectedAccessMethod: [], selectedAction: [], fromDate: this.state.from, toDate: this.state.to, selectedTrend:'daily', selectedGroupBy:'service', showGroupByLabel: 'service', selectedGroupByTags: '', selectedService: [], selectedStatus: [] })
				}
			} else {
				this.setState({ identitiesList: [], totalAssetsAccessed: [], assetsAccessibleCount: [], usersAssumingRole: [], lastAccessedDetails: [], getTotalCount: [], permissionDetails: [], permissionFilterData: [], accessTrend: [],selectedRole: '', selectedRoleType: '', selectedCreatedAt: '', selectedModifiedAt: '', selectedMangedPoliciesCount: '', selectedInlinePoliciesCount: '', selectedArn: '', selectedTags: '', selectedAssumedUser: [], selectedAccessMethod: [], selectedAction: [], fromDate: this.state.from, toDate: this.state.to, selectedTrend:'daily', selectedGroupBy:'service', showGroupByLabel: 'service', selectedGroupByTags: '', selectedService: [], selectedStatus: [] })
			}
		})
	}

	identitiesRoleFunction = () => {
		this.getTotalAssetsAccessed()
		this.getAssetsAccessibleCount()
		this.listUsersAssumingRole()
		this.lastAccessedDetails()
		this.getTotalCount()
		this.getAccessTrend()
		this.getPermissionDetails()
		this.getActionsCategoryWise()
		this.getActionsServiceWise()
	}

	identitiesUserFunction = () => {
		this.getTotalAssetsAccessed()
		this.getAssetsAccessibleCount()
		this.lastAccessedDetails()
		this.getTotalCount()
		this.getAccessTrend()
		this.getPermissionDetails()
		this.getActionsCategoryWise()
		this.getActionsServiceWise()
	}

	getTotalAssetsAccessed = () => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.account_id = this.state.selectedAccount
		params.identity_type  = this.state.selectedServiceType
		params.identity = this.state.selectedRole

		//console.log('-----------getTotalAssetsAccessed-------------',params)
		this.props.getTotalAssetsAccessed(params, (promise, totalAssetsAccessed) => {
			//console.log('------------getTotalAssetsAccessed------------',totalAssetsAccessed)	
			if (promise) {
				this.setState({ totalAssetsAccessed: totalAssetsAccessed.results.count })
			} else {
				this.setState({ totalAssetsAccessed: [] })
			}
		})
	}

	getAssetsAccessibleCount = () => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.account_id = this.state.selectedAccount
		params.identity_type  = this.state.selectedServiceType
		params.identity = this.state.selectedRole

		//console.log('----------getAssetsAccessibleCount--------------',params)
		this.props.getAssetsAccessibleCount(params, (promise, assetsAccessibleCount) => {
			//console.log('------------getAssetsAccessibleCount------------',assetsAccessibleCount)
			if (promise) {
				this.setState({ assetsAccessibleCount: assetsAccessibleCount.results.count })
			} else {
				this.setState({ assetsAccessibleCount: [] })
			}
		})
	}	
	
	listUsersAssumingRole = () => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.account_id = this.state.selectedAccount
		params.role_name = this.state.selectedRole
		params.duration = "+80d"
		params.arn = this.state.selectedArn
		params.role_type = this.state.selectedRoleType

		//"provider":"aws","account_id":"666665886686","role_name":"DatadogAWSIntegrationRole","arn": "arn:aws:iam::666665886686:role/DatadogAWSIntegrationRole","duration":"+80d","role_type":"Cross Account Role - 112367390792"

		console.log('----------listUsersAssumingRole--------------',params)
		this.props.listUsersAssumingRole(params, (promise, usersAssumingRole) => {
			console.log('-----------listUsersAssumingRole-------------',usersAssumingRole)
			if (promise) {
				//this.setState({ usersAssumingRole: usersAssumingRole.results, selectedAssumedUser: [usersAssumingRole.results[0].user] })
				this.setState({ usersAssumingRole: usersAssumingRole.results })
			} else {
				this.setState({ usersAssumingRole: [] })
			}
		})
	}

	lastAccessedDetails = () => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.identity_type  = this.state.selectedServiceType
		params.identity = this.state.selectedRole
		params.account_id = this.state.selectedAccount
		
		//console.log('----------lastAccessedDetails--------------',params)
		this.props.lastAccessedDetails(params, (promise, lastAccessedDetails) => {
			//console.log('-----------lastAccessedDetails-------------',lastAccessedDetails)
			if (promise) {
				this.setState({ lastAccessedDetails: lastAccessedDetails.results })
			} else {
				this.setState({ lastAccessedDetails: [] })
			}
		})
	}
	
	getTotalCount = () => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.account_id = this.state.selectedAccount
		params.identity_type  = this.state.selectedServiceType
		params.identity = this.state.selectedRole

		//console.log('----------getTotalCount--------------',params)
		this.props.getTotalCount(params, (promise, getTotalCount) => {
			//console.log('------------getTotalCount------------',getTotalCount)
			if (promise) {
				this.setState({ getTotalCount: getTotalCount.results })
			} else {
				this.setState({ getTotalCount: [] })
			}
		})
	}
	
	getPermissionDetails = () => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.account_id = this.state.selectedAccount
		params.identity_type = this.state.selectedServiceType
		params.identity = this.state.selectedRole

		//params.identity = this.state.selectedRole

		//console.log('--------------getPermissionDetails----------',params)
		this.props.getPermissionDetails(params, (promise, permissionDetails) => {
				//console.log('----------getPermissionDetails--------------',permissionDetails)
			if (promise) {
				let permissionArray = []
				if(permissionDetails.results) {
					permissionDetails.results.forEach(permis => {
						permis.categories.forEach(category => {
							let dataRow = {}
							dataRow.service_name = permis.service_name
							dataRow.category = category.category
							dataRow.usecases = category.usecases
							permissionArray.push(dataRow)
						})
					})
				}
				
				this.setState({ permissionDetails: permissionArray, permissionFilterData: permissionArray, permissionOriginalData: permissionDetails.results })
			} else {
				this.setState({ permissionDetails: [] })
			}
		})
	}

	trendChange = () => {
		this.getAccessTrend()
	}

	getAccessTrend = () => {
		this.setState({ accessTrend: [], assessTrendTable: [], columns: [] })
		let beginDate = moment().subtract(6, 'days').format("YYYY-MM-DD")
		let closeDate = moment().format("YYYY-MM-DD")
		if(this.state.from !== '' && this.state.from !== undefined) {
			beginDate = moment(this.state.from).format("YYYY-MM-DD")
		}
		if(this.state.to !== '' && this.state.to !== undefined) {
			closeDate = moment(this.state.to).format("YYYY-MM-DD")
		}
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.account_id = this.state.selectedAccount
		params.identity_type  = this.state.selectedServiceType
		params.identity = this.state.selectedRole
		params.group_by = this.state.selectedGroupBy
		if(this.state.selectedGroupByTags !== '') {
			params.group_by_tag = this.state.selectedGroupByTags
		}
		if(this.state.selectedGroupBy === 'assumed_user') {
			params.arn = this.state.selectedArn
		}
		params.from = beginDate
		params.to = closeDate
		params.trend = this.state.selectedTrend

		params.filters = {"service_name": this.state.applyFilterService ?this.state.selectedService : [], "action": this.state.applyFilterAction ? this.state.selectedAction : [], "status": this.state.applyFilterStatus ?this.state.selectedStatus : [], "access_method": this.state.applyFilterAccess ? this.state.selectedAccessMethod : [], "assumed_user": this.state.applyFilterUser ? this.state.selectedAssumedUser : []}
		// params.assumedUser = this.state.selectedAssumedUser
		// params.service = this.state.selectedService
		// params.status = this.state.selectedStatus
		// params.accessMethod = this.state.selectedAccessMethod
		// params.action = this.state.selectedAction

		//console.log('----------getAccessTrend--------------',params)
		this.props.getAccessTrend(params, (promise, accessTrend) => {			
			//console.log('------------getAccessTrend------------',accessTrend)
			if (promise) {
				if(accessTrend.results) {
					//let labels = accessTrend.results.trend.labels
					//let length = labels.length
					var assessTrendTable = []
					Object.entries(accessTrend.results.trend.data).forEach(([key,value]) => {
						let totalCount = value.reduce(function(a, b){
							return a + b;
						}, 0);
					  	var result = {
							"service": key,
							//"total": accessTrend.results.total,
							'total': totalCount
						}
					
					  	value.forEach((item,index) => {
							result[accessTrend.results.trend.labels[index]] = item
					  	})
					  	assessTrendTable.push(result);
					})

					if(assessTrendTable.length) {
						const columns = Object.entries(assessTrendTable[0]).map(([tKey, tValue]) =>{
							let header = ''
							let width = '100%'
							if(this.state.durationSelector > 7) {
								width = 75
							}
							if(tKey === 'service') {
								header = this.state.selectedGroupByTags === '' ? capitalizeFirstLetter(this.state.selectedGroupBy) : capitalizeFirstLetter(this.state.selectedGroupByTags)
								width = 200
							} else if(tKey === 'total') {
								header = 'Total'
								width = 75
							} else {
								if(this.state.selectedTrend === 'daily'){
									header = moment(tKey).format('MMM DD')
								} else {
									header = moment(tKey).format('MMM YY')
								}
							}
							return {
								Header: header,
								accessor: tKey,
								width: width
							}
						})
						this.setState({ accessTrend: accessTrend.results, assessTrendTable, columns: columns },
							() => this.trendGraph()
						)
					} else {
						const columns = []	
						this.setState({ accessTrend: accessTrend.results, assessTrendTable, columns: columns },
							() => this.trendGraph()
						)
					}
					
				} else {
					this.setState({ accessTrend: [], assessTrendTable: [], columns: [] },
						() => this.trendGraph()
					)
				}
			} else {
				this.setState({ accessTrend: [] })
			}
		})
	}

	trendGraph= () => {	
		let graphData = this.state.accessTrend.trend.data;
		let graphLabels = this.state.accessTrend.trend.labels;
		let barChartData = [];
		let summaryGraphLabelsData = [];
		let title ='';

		let length = graphLabels.length;
		let labelInterval = 1;
		let showLabel = 1
		for (let x = 0;x<length;x++) {
			if(graphLabels[x] !== undefined) {
				if(length >= 60) {
					if(labelInterval !== 1) {
						showLabel = 0;
						labelInterval--;
					} else {
						showLabel = 1;
						labelInterval = 10;
					}
				} else if(length >= 14) {
					if(labelInterval !== 1) {
						showLabel = 0;
						labelInterval--;
					} else {
						showLabel = 1;
						labelInterval = 5							
					}
				} 
				if(showLabel === 1) {
					summaryGraphLabelsData.push(moment(graphLabels[x]).format('DD MMM'))
				} else {
					summaryGraphLabelsData.push('')		
				}					
			} else {
				summaryGraphLabelsData.push('')
			}			
		}
		if(graphData && Object.entries(graphData).length) {
			for (const [key, value] of Object.entries(graphData)) {
				let summaryDataSet = {};
				summaryDataSet['data'] = value
				summaryDataSet['name'] = key;
				barChartData.push(summaryDataSet);
			}
		} else {
			let summaryDataSet = {};
			let length = graphLabels.length;
			let emptyArray = []
			for (let x = 0;x<length;x++) {
				emptyArray.push(0)
			}

			summaryDataSet['data'] = emptyArray
			summaryDataSet['name'] = 'No Record';
			barChartData.push(summaryDataSet);
		}
		
		const barChartOptions = {
			tooltip: {
				enabled: true,
				custom: function({series, seriesIndex, dataPointIndex, w}) {
				  let val  = series[seriesIndex][dataPointIndex];
				  val = Math.abs(Math.round(val))
				  let labelName = w.globals.initialSeries[seriesIndex].name
				  //let val = str.replace('-',series[seriesIndex][dataPointIndex]);
				  return '<div className="arrow_box">' +
					'<span> '+ w.globals.labels[dataPointIndex] + 
					' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val + ' </span>' +
					'</div>'
				},
				style: {
				  fontSize: '9px',
				},
			},				
			chart: {
				toolbar: {
					show: false,
				},
				type: 'bar',
				height: 440,
				stacked: true,
			},			
			colors: ['#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'],
			plotOptions: {
			  bar: {
				horizontal: false,
				barHeight: '40%',
				startingShape: 'flat',
				endingShape: 'flat',
				columnWidth: '60%',
				distributed: false,
				rangeBarOverlap: false,
				colors: {
				  ranges: [{
					  from: 1,
					  to: 0.9,
					  color: '#FF4560'
				  }],
				  backgroundBarColors: ['#EDEDED'],
				  backgroundBarOpacity: 1,
				  backgroundBarRadius: 0,
				}        
			  },          
			},
			dataLabels: {
			  enabled: false
			},
			stroke: {
			  width: 0,
			  colors: ["#fff"]
			},
			
			grid: {  				        
			  xaxis: {
				lines: {
				  show: false
				},
			  },
			  yaxis: {
				lines: {
				  show: false
				}
			  },
			  row: {
				colors: undefined,
				opacity: 0.5
			  },  
			  column: {
				  colors: undefined,
				  opacity: 0.5
			  }, 
			},
			title: {
			  text: title
			},
			xaxis: {
				categories: summaryGraphLabelsData,
				labels: {
					show: true,
					style: {
						fontSize: '9px'
					}
				}
			},
			legend: {
				show:true,
				position: 'bottom',
				fontSize: '11px',
			},
		}			
		
		this.setState({trend_bar_data:barChartData, trend_bar_option:barChartOptions});
	}

	getActionsCategoryWise = () => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.account_id = this.state.selectedAccount
		params.identity_type = this.state.selectedServiceType
		params.identity = this.state.selectedRole

		//console.log('----------getActionsCategoryWise--------------',params)
		this.props.getActionsCategoryWise(params, (promise, actionsCategoryWise) => {
			//console.log('------------actionsCategoryWise------------',actionsCategoryWise)
			if (promise) {
				this.setState({ actionsCategoryWise: _.orderBy(actionsCategoryWise.results, ['actions_allowed'], ['desc']) },
					() => this.categoryWiseDoughnutGraph()
				)
			} else {
				this.setState({ actionsCategoryWise: [] })
			}
		})
	}

	categoryWiseDoughnutGraph= () => {
		let graphData = this.state.actionsCategoryWise;
		let doughnutChartData = [];
		let doughnuGraphLabels = [];
		let backgroundColor = [];
		let hoverBackgroundColor = [];
		if(graphData && graphData.length){
			graphData.slice(0,10).forEach(categoryGraph => {
				// if(key > 9) {
				// 	break;
				// }
				//doughnuGraphLabels.push(categoryGraph.category+': '+categoryGraph.actions_allowed);
				doughnuGraphLabels.push(categoryGraph.category+': '+categoryGraph.actions_allowed);
				doughnutChartData.push(categoryGraph.actions_allowed);
				//backgroundColor.push(this.dynamicColors());
				//hoverBackgroundColor.push(this.dynamicColors());
			})
			
			backgroundColor = [
				'#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'
				];
			hoverBackgroundColor = [
				'#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'
				];
			
		} else {
			doughnuGraphLabels.push('No records');
			doughnutChartData.push(100);
			backgroundColor = ['#ececec'];
			hoverBackgroundColor = ['#ececec'];
		}			
			
		let datasetsColumn = [{
			data: doughnutChartData,
			backgroundColor: backgroundColor,
			hoverBackgroundColor: hoverBackgroundColor,
			barThickness : 5,
		}];

		let doughnut_datas = {labels:doughnuGraphLabels, datasets: datasetsColumn}; 

		let doughnut_option = {
			tooltips: {
				callbacks: {
					label: function(tooltipItem, doughnutChartData) {
						//console.log('doughnutChartData',doughnutChartData)
						//let value = doughnutChartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
						//let color = doughnutChartData.datasets[tooltipItem.datasetIndex].backgroundColor[tooltipItem.index]
						let label = doughnutChartData.labels[tooltipItem.index]
						//console.log('index',tooltipItem.index)
						//var dataset = doughnutChartData.datasets.data[tooltipItem.datasetIndex];
						//var index = tooltipItem.index;
						
						//return label + ": " + value;
						//here label and value are combined so we send only label prakash
						return label;
					}
				}
			},
			cutoutPercentage: 50,
			animate   : true,
			animation: { duration: 1000 },
			maintainAspectRatio: false,
			responsive: false,
			legend: {				
				display:true,
				position: 'right',
				labels: {
					boxWidth: 15,
					fontSize: 9
				}
				// layout: {
				// 	padding: {
				// 		left: 0,
				// 		right: 50
				// 	},
				// }
			}
		}
		this.setState({category_doughnut_data:doughnut_datas, category_doughnut_options:doughnut_option});
	}
	
	getActionsServiceWise = () => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.account_id = this.state.selectedAccount
		params.identity_type = this.state.selectedServiceType
		params.identity = this.state.selectedRole

		//console.log('----------getActionsgetActionsServiceWiseCategoryWise--------------',params)
		this.props.getActionsServiceWise(params, (promise, actionsServiceWise) => {
			//console.log('------------actionsServiceWise------------',actionsServiceWise)
			if (promise) {
				this.setState({ actionsServiceWise: _.orderBy(actionsServiceWise.results, ['actions_allowed'], ['desc']) },
				() => this.servicesWiseDoughnutGraph()
			)
			} else {
				this.setState({ actionsServiceWise: [] })
			}
		})
	}

	servicesWiseDoughnutGraph= () => {
		let graphData = this.state.actionsServiceWise;
		let doughnutChartData = [];
		let doughnuGraphLabels = [];
		let backgroundColor = [];
		let hoverBackgroundColor = [];
		if(graphData && graphData.length){
			graphData.slice(0,10).forEach(serviceGraph => {
				// if(key > 9) {
				// 	break;
				// }
				//doughnuGraphLabels.push(catGraph.category+': '+catGraph.actions_allowed);
				doughnuGraphLabels.push(serviceGraph.service+': '+serviceGraph.actions_allowed);
				doughnutChartData.push(serviceGraph.actions_allowed);
				//backgroundColor.push(this.dynamicColors());
				//hoverBackgroundColor.push(this.dynamicColors());
			})
			
			backgroundColor = [
				'#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'
				];
			hoverBackgroundColor = [
				'#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'
				];
			
		} else {
			doughnuGraphLabels.push('No records');
			doughnutChartData.push(100);
			backgroundColor = ['#ececec'];
			hoverBackgroundColor = ['#ececec'];
		}			
			
		let datasetsColumn = [{
			data: doughnutChartData,
			backgroundColor: backgroundColor,
			hoverBackgroundColor: hoverBackgroundColor,
			barThickness : 5,
		}];

		let doughnut_datas = {labels:doughnuGraphLabels, datasets: datasetsColumn}; 

		let doughnut_option = {
			tooltips: {
				callbacks: {
					label: function(tooltipItem, doughnutChartData) {
						//console.log('doughnutChartData',doughnutChartData)
						//let value = doughnutChartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
						//let color = doughnutChartData.datasets[tooltipItem.datasetIndex].backgroundColor[tooltipItem.index]
						let label = doughnutChartData.labels[tooltipItem.index]
						//console.log('index',tooltipItem.index)
						//var dataset = doughnutChartData.datasets.data[tooltipItem.datasetIndex];
						//var index = tooltipItem.index;
						
						//return label + ": " + value;
						//here label and value are combined so we send only label prakash
						return label;
					}
				}
			},
			cutoutPercentage: 50,
			animate   : true,
			animation: { duration: 1000 },
			maintainAspectRatio: false,
			responsive: false,
			legend: {				
				display:true,
				position: 'right',
				labels: {
					boxWidth: 15,
					fontSize: 9
				}
				// layout: {
				// 	padding: {
				// 		left: 0,
				// 		right: 50
				// 	},
				// }
			}
		}

		this.setState({service_doughnut_data:doughnut_datas, service_doughnut_options:doughnut_option});
	}

	fitlerBtnClick = (from, action) => {
		
		if(from === 'service' && this.state.selectedService.length) {
			if(action === 'cancel' && this.state.applyFilterService) {
				this.setState({ selectedService: [] }, 
					() => this.trendChange() 
				)
			} else if(action === 'cancel') {
				this.setState({ selectedService: [] })
			} else {
				this.setState({ applyFilterService: true },
					() => this.trendChange()
				)
			}
		} else if(from === 'user' && this.state.selectedAssumedUser.length) {
			if(action === 'cancel' && this.state.applyFilterUser) {
				this.setState({ selectedAssumedUser: [] }, 
					() => this.trendChange() 
				)
			} else if(action === 'cancel') {
				this.setState({ selectedAssumedUser: [] })
			} else {
				this.setState({ applyFilterUser: true },
					() => this.trendChange()
				)
			}
		} else if(from === 'access' && this.state.selectedAccessMethod.length) {
			if(action === 'cancel' && this.state.applyFilterAccess) {
				this.setState({ selectedAccessMethod: [] }, 
					() => this.trendChange() 
				)
			} else if(action === 'cancel') {
				this.setState({ selectedAccessMethod: [] })
			} else {
				this.setState({ applyFilterAccess: true }, 
					() => this.trendChange() 
				)
			}
		} else if(from === 'action' && this.state.selectedAction.length) {
			if(action === 'cancel' && this.state.applyFilterAction) {
				this.setState({ selectedAction: [] }, 
					() => this.trendChange() 
				)
			} else if(action === 'cancel') {
				this.setState({ selectedAction: [] })
			} else {
				this.setState({ applyFilterAction: true }, 
					() => this.trendChange() 
				)
			}
		} else if(from === 'status' && this.state.selectedStatus.length) {
			if(action === 'cancel' && this.state.applyFilterStatus) {
				this.setState({ selectedStatus: [] }, 
					() => this.trendChange() 
				)
			} else if(action === 'cancel') {
				this.setState({ selectedStatus: [] })
			} else {
				this.setState({ applyFilterStatus: true }, 
					() => this.trendChange() 
				)
			}
		}

		// this.setState({ selectedService: value },
		// 	//() => this.trendChange()
		// )
		// } else if(childKey === 'selectedStatus') {
		// 	this.setState({ selectedStatus: value },
		// 		//() => this.trendChange()
		// 	)
		// } else if(childKey === 'selectedAssumedUser') {
		// 	this.setState({ selectedAssumedUser: value },
		// 		//() => this.trendChange()
		// 	)
		// } else if(childKey === 'selectedAccessMethod') {
		// 	this.setState({ selectedAccessMethod: value },
		// 		//() => this.trendChange()
		// 	)
		// } else if(childKey === 'selectedAction') {
		// this.setState({ selectedAction: value },
		// 	//() => this.trendChange()
		// )
	}

	handleMultiSelectChange = (key, childKey, arrayValue) => {
		let value = arrayValue.map(item => item.value)
		//console.log(value)
		if(childKey === 'selectedService') {
			this.setState({ selectedService: value },
				//() => this.trendChange()
			)
		} else if(childKey === 'selectedStatus') {
			this.setState({ selectedStatus: value },
				//() => this.trendChange()
			)
		} else if(childKey === 'selectedAssumedUser') {
			this.setState({ selectedAssumedUser: value },
				//() => this.trendChange()
			)
		} else if(childKey === 'selectedAccessMethod') {
			this.setState({ selectedAccessMethod: value },
				//() => this.trendChange()
			)
		} else if(childKey === 'selectedAction') {
			this.setState({ selectedAction: value },
				//() => this.trendChange()
			)
		}
	}

	tablefilterMultiSelectChange= (key, childKey, arrayValue) => {
		
		let value = arrayValue.map(item => item.value)
		if(childKey === 'selectedTableService') {
			this.setState({ selectedTableService: value },
				() => this.filterPermissionTableValue()
			)
		}

		if(childKey === 'selectedTableStatus') {
			this.setState({ selectedTableStatus: value },
				() => this.filterPermissionTableValue()
			)
		}
		
	}

	filterPermissionTableValue = () => {
		let filterData = this.state.permissionDetails
		let formArray = []
		if(this.state.selectedTableService) {
			this.state.selectedTableService.length ?
				this.state.selectedTableService.forEach(val=> {
					let result = filterData.filter(filter => filter.service_name === val)
					result.forEach(res=> {
						formArray.push(res);
					})
				})
			: formArray = filterData
		}

		let statusArray = []
		if(this.state.selectedTableStatus) {
			this.state.selectedTableStatus.length &&
				this.state.selectedTableStatus.forEach(val=> {
				let result = formArray.filter(filter => filter.category === val)
				result.forEach(res=> {
					statusArray.push(res);
				})
				formArray = statusArray
			})
		}		
		this.setState({ permissionFilterData: formArray })
		
	}

	// handleClick = () => {
	// 	var selectField = this.serviceField;
	// 	//selectField.click()
	// }

	// serviceMenu = () => {
	// 	alert(this.state.menuIsOpen)
	// }

	/**
	 * Renders User Insights
	 */
	render() {
		const { from, to } = this.state;
		const modifiers = { start: from, end: to };
		return (
			<div className='container-fluid overflow-auto flex-grow-1' onClick={ (event) => { this.handleClickOutside(event) } }>
				{this.state.showDetailsPanel ? (            
					<InsightsSidePanel
                        closeViewSidePanel={() => this.setState({ showDetailsPanel: false })}
                        selectedProvider = {this.state.selectedProvider.toLowerCase()}
                        selectedAccount = {this.state.selectedAccount}
                        selectedDuration = {this.state.selectedDuration}
						
						selectedServiceType = {this.state.selectedServiceType}
                        selectedRole = {this.state.selectedRole}

						selectedCreatedAt = {this.state.selectedCreatedAt}
						selectedModifiedAt = {this.state.selectedModifiedAt}
						selectedMangedPoliciesCount = {this.state.selectedMangedPoliciesCount}

						//selectedProvider = 'aws'
                        //selectedAccount = '666665886686'
                        //selectedDuration = '+90d'
						
						//selectedServiceType = 'iam-roles'
                        //selectedRole = 'APIGatewayCloudwatchLogs'					
					/>
				) : null}
				<div className='row h-100'>
					<div className='col-sm-12 p-0'>
						<div className='title-section' style={{padding: "1rem 1rem 1rem 1rem"}} >
							<div className='row'>
								<div className='col-lg-4 d-flex align-self-center'>
									<div>
										<div className='d-flex'>
                                            <h6 className='text-white m-0'>IAM insights</h6>
                                            <span className={`ml-3 text-info align-self-center cursorPointer ${this.state.selectedRole !== '' ? '' : 'displayNone'} `} onClick={ () => this.setState({showDetailsPanel:true})}>Additional Details</span>
                                        </div>
										<p className='text-white m-0'>
											IAM insights of Identity and policies in your cloud
										</p>
									</div>
								</div>
								<div className={`col-lg-8 text-right`}>
								<div className='d-flex'>
									<div
										className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 ${!this.state.providers
											.length}`}
									>
										<label>Provider</label>
										<select
											className='form-control'
											value={this.state.selectedProvider}
											onChange={event => {
												this.setState(
													{
														selectedProvider: event.target.value,
														showLoading: true,
													},
													() => this.filterIdentifiers('provider')
												)
											}}
										>
											<option value='all'>All</option>
											{this.state.providers.length &&
												this.state.providers.map(provider => {
													return (
														<option key={provider.provider_id} value={provider.provider_name}>
															{provider.provider_name}
														</option>
													)
												})}
										</select>
									</div>
									<div
										className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 ${!this.state.accounts
											.length}`}
									>
										<label>Account</label>
										<select
											className='form-control'
											value={this.state.selectedAccount}
											disabled={!this.state.accounts.length}
											onChange={event => {
												this.setState(
													{
														selectedAccount: event.target.value,
													},
													() => this.getIdentitiesList()
												)
											}}
										>
											{this.state.accounts.length &&
												this.state.accounts.map((account, i) => {
													return (
														<option key={i} value={account.account_id}>
															{account.account_name}
														</option>
													)
												})}
										</select>
									</div>
									
									<div className='form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3'>
										<label>Type</label>
										<select
											className='form-control'
											value={this.state.selectedServiceType}
											onChange={event => {
												this.setState(
													{
														selectedServiceType: event.target.value,
													},
													() => this.getIdentitiesList()
												)
											}}
										>
											<option value='iam-roles'>Role</option>
											<option value='iam-users'>Users</option>
										</select>
									</div>

									<div style={{width:"20%", zIndex:999999}} className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 ${this.state.selectedServiceType === 'iam-roles' ? 'displayBlock' : 'displayNone' }`}>
										<label>Roles</label>
										<Select
											className='form-control p-0 colorBlack'
											value={({
												value: this.state.selectedRole,
												label: this.state.selectedRole,
											})}
											options={this.state.identitiesList.map(identities => ({
												value: identities,
												label: identities.role_name,
											}))}
											onChange={event => {
												this.setState(
													{
														selectedRole: event.value.role_name,
														selectedRoleType: event.value.role_type,
														selectedCreatedAt: event.value.created_at,
														selectedModifiedAt: event.value.modified_at,
														selectedMangedPoliciesCount: event.value.managed_policies_count,
														selectedInlinePoliciesCount: event.value.inline_policies_count,
														selectedArn: event.value.arn,
														selectedTags: event.value.tags,
													},
													() => this.identitiesRoleFunction()
												)
											}}
										/>
									</div>

									<div style={{width:"20%", zIndex:999999}} className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 ${this.state.selectedServiceType === 'iam-users' ? 'displayBlock' : 'displayNone' }`}>
										<label>Users</label>
										<Select
											className='form-control p-0 colorBlack'
											value={({
												value: this.state.selectedRole,
												label: this.state.selectedRole,
											})}
											options={this.state.identitiesList.map(identities => ({
												value: identities,
												label: identities.user_name,
											}))}
											onChange={event => {
												this.setState(
													{
														selectedRole: event.value.user_name,
														selectedRoleType: '',
														selectedCreatedAt: event.value.created_at,
														selectedModifiedAt: event.value.modified_at,
														selectedMangedPoliciesCount: event.value.managed_policies_count,
														selectedInlinePoliciesCount: event.value.inline_policies_count,
														selectedArn: event.value.arn,
														selectedTags: event.value.tags,
													},
													() => this.identitiesUserFunction()
												)
											}}
										/>
									</div>

									{/* <div className='form-group flex-fill bd-highlight mb-sm-0 styled-feild displayNone'>
										<label>Duration</label>
										<select
											className='form-control'
											value={this.state.selectedDuration}
											onChange={event => {
												this.setState(
													{
														selectedDuration: event.target.value,
													},
													() => this.filterIdentifiers('duration')
												)
											}}
										>
											<option value='7d'>This Week</option>
											<option value='+7d'>Last 1 week</option>
											<option value='+14d'>Last 2 weeks</option>
											<option value='+30d'>Last 1 month</option>
											<option value='+60d'>Last 2 months</option>
											<option value='+90d'>Last 3 months</option>
										</select>
									</div> */}
								</div>									
								</div>
							</div>
						</div>
						<div className='bashboard-bg'>
							<div className="box p-3 shadow-none">
								<div className="row">
									<div className="col-sm-3 border-right marginLeftMinus5">
										<div className="card cardBgColor1">
											<div className="card-body cardBodyManul">
												<div className="col-sm-12 p-0">
													<h6 className="card-title mb-0 font-weight-light w-75">{this.state.selectedArn ? this.state.selectedArn : null}</h6>
													<span className="faIcon">
															<i className="fad fa-info-circle"></i>
													</span>
												</div>
											</div>
											<div className="card-footer cardFooterManualCss">
												<div className="col-lg-6 m-0 p-0 float-lg-left">
													<p className="card-subtitle">Created Date</p>
													<p className="card-text f12">{this.state.selectedCreatedAt ? this.state.selectedCreatedAt : null}</p>
												</div>
												<div className="col-lg-6 m-0 p-0 float-lg-right">
													<p className="card-subtitle pl-xl-1">Last Update</p>
													<p className="card-text pl-xl-1 f12">{this.state.selectedModifiedAt ? this.state.selectedModifiedAt : null}</p>
												</div>
											</div>
										</div>
										<div className="card cardBgColor2 mt-3">
											<div className="card-body cardBodyManul">
												<div className="col-sm-12 m-0 p-0">
													<h6 className="card-title mb-0 font-weight-light">Policies</h6>
													<span className="faIcon">
														<i className="fad fa-file-certificate"></i>
													</span>
												</div>
											</div>
											<div className="card-footer cardFooterManualCss">
												<div className="col-lg-6 m-0 p-0 float-lg-left">
													<p className="card-subtitle f30">{this.state.selectedMangedPoliciesCount ? this.state.selectedMangedPoliciesCount : 0}</p>
													<p className="card-text f13">Number of manged</p>
												</div>
												<div className="col-lg-6 m-0 p-0 float-lg-right">
													<p className="card-subtitle pl-xl-1 f30">{this.state.selectedInlinePoliciesCount ? this.state.selectedInlinePoliciesCount : 0}</p>
													<p className="card-text pl-xl-1 f13">Number of Inlines</p>
												</div>
											</div>
										</div>										
										<div className="card cardBgColor4 mt-3">
											<div className="card-body cardBodyManul">
												<div className="col-sm-12 p-0">
													<h6 className="card-title mb-0 font-weight-light">{this.state.selectedServiceType === 'iam-roles' ? 'Role' : 'User' }</h6>
													<span className="faIcon">
														<i className="fad fa-user-crown"></i>
													</span>
													<h5 className={`card-title my-2 ${this.state.selectedServiceType === 'iam-roles' ? '' : 'displayNone'}`}>{this.state.selectedRoleType ? this.state.selectedRoleType : null}</h5>
												</div>
												<p className={`card-subtitle ${this.state.selectedServiceType === 'iam-roles' ? '' : 'displayNone'}`}>Last user who Assumed this Role</p>
												<p className={`card-text f13 font-weight-bold ${this.state.selectedServiceType === 'iam-roles' ? '' : 'displayNone'}`}>{this.state.lastAccessedDetails.last_accessed_user ? this.state.lastAccessedDetails.last_accessed_user : null}</p>
												<div className='row'>
													<div className={this.state.selectedServiceType === 'iam-roles' ? 'col-lg-6' : 'col-sm-12' }>
														<p className="card-subtitle mt-2">Last Access done using this {this.state.selectedServiceType === 'iam-roles' ? 'Role' : 'User' }</p>
														<p className={`card-title my-2`}>{this.state.lastAccessedDetails.last_accessed_time ? this.state.lastAccessedDetails.last_accessed_time : null}</p>
													</div>
													<div className={`col-lg-6  ${this.state.selectedServiceType === 'iam-roles' ? '' : 'displayNone'}`}>
														<p className="card-subtitle m-0 f30 text-lg-right">{this.state.usersAssumingRole ? this.state.usersAssumingRole.length : 0}</p>
														<p className="card-text pl-xl-1 f13 text-lg-right">Users Assumed (Last 3 months)</p>
													</div>
												</div>
											</div>
										</div>
										<div className="card cardBgColor5 mt-3">
											<div className="card-body cardBodyManul">
												<div className="col-sm-12 m-0 p-0">
													<h6 className="card-title mb-0 font-weight-light">Access/Action</h6>
													<span className="faIcon">
														<i className="fad fa-universal-access"></i>
													</span>
												</div>
												<div className="col-lg-6 mt-3 p-0 float-lg-left">
													<p className="card-subtitle">Last Access from</p>
													<p className="card-textf13 font-weight-light">{this.state.lastAccessedDetails.last_accessed_ip ?this.state.lastAccessedDetails.last_accessed_ip : null}</p>

													<p className="card-subtitle f30">{this.state.getTotalCount.allowed ? this.state.getTotalCount.allowed : 0}</p>
													<p className="card-textf13 font-weight-light">Total Action Allowed(3 months) </p>
												</div>
												<div className="col-lg-6 m-0 p-0 float-lg-left"> 
													<p className="card-subtitle pl-xl-1 f30">{this.state.getTotalCount.denied ? this.state.getTotalCount.denied : 0}</p>
													<p className="card-text pl-xl-1 f13">Total Action Denied</p>
												</div>
											</div>
										</div>
										<div className="card cardBgColor3 mt-3">
											<div className="card-body cardBodyManul pb-0">
												<div className="col-sm-12 m-0 p-0 float-left">
													<h6 className="card-title mb-0 font-weight-light">Assets/Tags</h6>
													<span className="faIcon">
														<i className="fad fa-tags"></i>
													</span>
													<div className="d-flex mt-3 flex-wrap">
														{this.state.selectedTags && this.state.selectedTags.length ? this.state.selectedTags.map(tag => {
															return (
																<span className="badge badge-outline-info d-table mr-2 mb-2 f12 bgInfo">{tag.value}</span>

															)
														})
														: null
														}
													</div>
												</div>
											</div>
											<div className="card-footer cardFooterManualCss">
												<div className="col-lg-6 m-0 p-0 float-lg-left">
													<p className="card-subtitle f30">{this.state.totalAssetsAccessed ? this.state.totalAssetsAccessed : 0}</p>
													<p className="card-text f13">No of Asssets Accessible</p>
												</div>
												<div className="col-lg-6 m-0 p-0 float-lg-right">
													<p className="card-subtitle pl-xl-1 f30">{this.state.assetsAccessibleCount ? this.state.assetsAccessibleCount : 0}</p>
													<p className="card-text pl-xl-1 f13">No of Assets Accessed(3 months)</p>
												</div>
											</div>
										</div>
									</div>
									<div className="col-sm-9 pl-0 marginLeftMinus5">
										<div className="row border-bottom pb-3 ml-0">
											<div className="col-sm-12">
												<div className="mb-1">
													<p className='font-weight-bold m-0 f15'>Trends</p>
													<p className="mb-0 small text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
												</div>
												<div className='row mt-2'>
													<div className='col-md-7 col-xl-10'>
														<div ref={this.wrapperRef}>
															<div className="d-flex">
																<div className='mr-2'>
																	<select className='customDropdown cursorPointer'
																		value={this.state.durationSelector}

																		onChange={event => {
																			this.setState({ durationSelector: event.target.value, showDatepickerBtn: false }, () => this.durationFinder() )
																		}}
																	>
																		<option value='7'>Last 1 week</option>
																		<option value='14'>Last 2 weeks</option>
																		<option value='30'>Last 1 month</option>
																		<option value='60'>Last 2 months</option>
																		<option value='90'>Last 3 months</option>
																		<option value='custom'>Custom Date</option>
																	</select>
																</div>
																<div className={`mr-2 ${this.state.showDatepickerBtn ? '' : 'displayNone'}`}>
																	<button type="button" className={`calendarSection blackMuted cursorPointer `} onClick={ () => this.setState({ showDatepicker: this.state.showDatepicker ? false : true }, () => this.checkResetDatePicker())}>{this.state.from === undefined ? moment().subtract(6, 'days').format("YYYY-MM-DD") : moment(this.state.from).format('YYYY-MM-DD') } to {this.state.to === undefined ? moment().format("YYYY-MM-DD") : moment(this.state.to).format('YYYY-MM-DD')}</button>
																	{this.state.showDatepickerBtn && (from || to) ?

																	<button className="resetBtn" onClick={this.handleResetClick}>Reset</button>			
																	: null
																	}
																</div>
																{/* <div className='select mr-2 calendarSection'>
																	<ul id="selectbox" onClick={ () => this.setState({ dropBtn: this.state.dropBtn ? '' : 'closed' })} className={`${this.state.dropBtn ? '' : 'closed'}`}>
																		<li values="1" className="option">Dropdown one</li>
																		<li values="2" className="option">Dropdown two</li>
																		<li values="3" className="option">Dropdown three</li>
																	</ul>
																</div> */}
																<div className='mr-3'>
																	<select className={`customDropdown cursorPointer ${this.state.durationSelector === 'custom' ? 'disabled': ''} `} value={this.state.selectedTrend}
																	onClick={ () => this.setState({ showDatepicker: false }, () => this.checkResetDatePicker()) }
																	onChange={event => {
																		this.setState(
																			{
																				selectedTrend: event.target.value
																			},
																			() => this.trendChange()
																		)
																	}}>
																		<option value="daily">Daily</option>
																		<option value="monthly">Monthly</option>
																	</select>
																</div>	
															</div>
															<div className={`RangeExample ${this.state.showDatepicker ? '' : 'displayNone'}`}>
																<DayPicker
																toMonth={new Date()}
																disabledDays={[
																	{
																		after: new Date(),
																		before: (new Date(this.state.pervious90Days))
																	},
																]}
																className="Selectable"
																numberOfMonths= {3}																			selectedDays={[from, { from, to }]}
																modifiers={modifiers}
																onDayClick={this.handleDayClick}
																tabIndex={0}
																/>
															</div>
														</div>
														{/* <div className="mr-3">

															<select className='form-control' value={this.state.selectedGroupBy}
															onChange={event => {
																this.setState(
																	{
																		selectedGroupBy: event.target.value
																	},
																	() => this.trendChange()
																)
															}}>
																<option defaultValue="Service">Service</option>
																<option value="Action"></option>
																<option value="Access Method">Access Method</option>
																<option value="Tag">Tag</option>
																<option value="Status">Status</option>
																<option value="Assumed User">Assumed User</option>
															</select>
														</div>
														<div className={`${this.state.selectedGroupBy === 'Tag' ? '' : 'displayNone'}`}>
															<select
																className='form-control'
																value={this.state.selectedGroupByTags}
																onChange={event => {
																	this.setState(
																		{
																			selectedGroupByTags: event.target.value,
																			showLoading: false,
																		},
																		() => this.trendChange()
																	)
																}}
															>
															<option key='0' value=''>Select</option>
																<option key='application' value='application'>Application</option>
																<option key='environment' value='environment'>Environment</option>
															</select>
														</div> */}	
														<div className='d-flex border flex-column mt-1 minHeight300'>
															<div className='bg-light border-bottom d-flex'>
																<div className='f12 border-right p-2'>
																	<label className='mb-0 font-weight-bold blackMuted f11'>Group by:
																		<span className='badge badge-primary custom-badge ml-2 text-white p-1'>
																			{this.state.showGroupByLabel} <i onClick={ this.state.selectedGroupBy === 'search' ? '' : () => this.getSelectedGroupBy('service') } className='ml-1 fal fa-times cursorPointer'></i>
																		</span>
																	</label>
																</div>
																<div className='d-flex p-2 overflow-auto'>
																	<span className={`mx-2 text-blue cursorPointer groupBySection ${this.state.selectedGroupBy === 'service' ? 'displayNone' : ''}`} onClick={ () => this.getSelectedGroupBy('service')}>Service</span>
																	<span className={`mx-2 text-blue cursorPointer groupBySection ${this.state.selectedGroupBy === 'action' ? 'displayNone' : ''}`} onClick={ () => this.getSelectedGroupBy('action')}>Action</span>
																	<span className='mx-2 text-blue cursorPointer groupBySection'>
																		<select
																			className='text-blue tagDropSection groupBySection'
																			value='Tag'
																			onChange={event => {
																				this.setState(
																					{
																						selectedGroupByTags: event.target.value,
																						showLoading: false,
																					},
																					() => this.getSelectedGroupBy('tag')
																				)
																			}}
																		>
																		<option key='0' value=''>Tag</option>
																		<option key='environment' value='environment'>Environment</option>
																		<option key='application' value='application'>Application</option>
																		<option key='cost_centre' value='cost_centre'>Cost Centre</option>				
																		</select>
																	</span>
																	<span className={`mx-2 text-blue cursorPointer groupBySection ${this.state.selectedGroupBy === 'status' ? 'displayNone' : ''}`} onClick={ () => this.getSelectedGroupBy('status')}>Status</span>
																	<span className={`ml-2 text-blue cursorPointer groupBySection ${this.state.usersAssumingRole && this.state.usersAssumingRole.length ? '' : 'displayNone'} ${this.state.selectedServiceType === 'iam-roles' ? '' : 'displayNone'} ${this.state.selectedGroupBy === 'assumed_user' ? 'displayNone' : ''}`} onClick={ () => this.getSelectedGroupBy('assumed_user')}>Assumed User</span>
																	<span className={`ml-2 text-blue cursorPointer groupBySection ${this.state.selectedGroupBy === 'access_method' ? 'displayNone' : ''}`} onClick={ () => this.getSelectedGroupBy('access_method')}>Access Method</span>
																	
																</div>
															</div>
															<div className='bgInfo border m-1' style={{backgroundColor:'rgba(188, 210, 250, 0.3)'}}>
																	<p className="p-2 m-0 small blackMuted text-center f10">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
															</div>
															{this.state.trend_bar_data && this.state.trend_bar_data.length && this.state.trend_bar_option ?
																<div className="mt-n2">																		
																	<Chart type="bar" series={this.state.trend_bar_data} width="100%" height={400} options={this.state.trend_bar_option}/>
																</div>
															: null
															}
														</div>
													</div>
													<div className='col-md-5 col-xl-2 p-0'>
														<div className="d-flex">
															<div className="col-sm-6 p-0">
																<p className="font-weight-bold mb-1 f14">Filter</p>
															</div>
															<div className="col-sm-6 p-0 text-right">
																<small className="text-blue font-weight-bold mb-1 f13 cursorPointer" onClick={ () => this.clearFilter()}>CLEAR ALL</small>
															</div>
														</div>
															{/* <label className='f12 mb-0 font-weight-bold text-muted'>Service</label> */}
															{/* <label className="f10 mb-0 font-weight-bold cursorPointer" style={{color: "#898989"}} id="serviceSelected" onClick={ () => this.setState({showServiceDropdown: this.state.showServiceDropdown ? false : true}, () => this.handleClick())}>Select... <span className="pl-2 mr-2 far fa-chevron-down selectDrop"></span></label> */}
															
														<div className={`border-top border-bottom pt-1`} ref={this.serviceRef}>
															<small className={`${this.state.selectedService.length ? '' : 'displayNone'} filterLabel`}>Service</small>
															<div className="col-sm-12 p-0 d-flex justify-content-between position-relative mb-1">
																<div className='custom-dropdown'>
																	<ReactMultiSelectCheckboxes 
																		
																		placeholderButtonLabel="Service"
																		menuIsOpen ={this.state.isServiceOpen}
																		value={this.state.selectedService.map(service => ({
																			value: service,
																			label: service
																		}))}
																		onChange={arr => {
																		this.handleMultiSelectChange('users', 'selectedService', arr ? arr : []) }}
																		options={this.state.serviceResourceType.map(service => ({
																		value: service.label,
																		label: service.label,
																		}))}
																		//onPress={this.serviceMenu()}
																	/> 	
																</div>
																<div>
																	<select className='cursorPointer position-absolute right-0 small border-0 text-muted includeDropDiv'
																	value={this.state.serviceIncludeType}
																		onChange={event => {
																			this.setState({ serviceIncludeType: event.target.value})
																		}}>
																		<option value='include'>Include</option>
																		<option value='exclude'>Exclude</option>
																	</select>
																</div>
															</div>
															<div className={`container-fluid mb-2 mt-2 ${this.state.showServiceDropdown ? '' : 'd-none'} `}>
																<div className='row'>
																	<div className="col-sm-12 p-0 displayNone">
																		<span className="text-center">Showing {this.state.serviceResourceType.length} of {this.state.serviceResourceType.length} results</span>
																	</div>
																</div>
																<div className='row'>
																	<div className="pl-0">
																		<span className="dropCancelBtn" onClick={ () => this.fitlerBtnClick('service', 'cancel') }>
																			Cancel
																		</span>	
																	</div>
																	<div className="flex-grow-1 text-right pr-0">
																		<span className="dropApplyBtn" onClick={ () => this.fitlerBtnClick('service', 'apply') }>
																			Apply Filters
																		</span>	
																	</div>
																</div>
															</div>
														</div>
														<div className={`border-bottom pt-1 ${this.state.usersAssumingRole && this.state.usersAssumingRole.length ? '' : 'displayNone'} ${this.state.selectedServiceType === 'iam-roles' ? '' : 'displayNone'}`} ref={this.userRef}>
															<small className={`${this.state.selectedAssumedUser.length ? '' : 'displayNone'} filterLabel`}>Assumed User</small>
															<div className="col-sm-12 p-0 d-flex justify-content-between position-relative mb-1">
																<div className='custom-dropdown removeDropdownSearchBar'>
																	<ReactMultiSelectCheckboxes
																		
																		placeholderButtonLabel="Assumed User"
																		menuIsOpen ={this.state.isUserOpen}
																		value={this.state.selectedAssumedUser.map(user => ({
																			value: user,
																			label: user
																		}))}
																		onChange={arr => {
																		this.handleMultiSelectChange('users', 'selectedAssumedUser', arr ? arr : []) }}
																		options={this.state.usersAssumingRole.map(assumRole => ({
																		value: assumRole.user,
																		label: assumRole.user,
																		}))}
																	/>
																</div>
																<div>
																	<select className='cursorPointer position-absolute right-0 small border-0 text-muted includeDropDiv'
																	value={this.state.userIncludeType}
																		onChange={event => {
																			this.setState({ userIncludeType: event.target.value})
																		}}>
																		<option value='include'>Include</option>
																		<option value='exclude'>Exclude</option>
																	</select>
																</div>
															</div>
															
															<div className={`container-fluid mb-2 mt-2 ${this.state.showUserDropdown ? '' : 'd-none'} `}>
																<div className='row'>
																	<div className="col-sm-12 p-0 displayNone">
																		<span className="text-center">Showing {this.state.usersAssumingRole.length} of {this.state.usersAssumingRole.length} results</span>
																	</div>
																</div>
																<div className='row'>
																	<div className="pl-0">
																		<span className="dropCancelBtn" onClick={ () => this.fitlerBtnClick('user', 'cancel') }>
																			Cancel
																		</span>	
																	</div>
																	<div className="flex-grow-1 text-right pr-0">
																		<span className="dropApplyBtn" onClick={ () => this.fitlerBtnClick('user', 'apply') }>
																			Apply Filters
																		</span>	
																	</div>
																</div>
															</div>
														</div>
														<div className={`border-bottom pt-1`} ref={this.accessRef}>
															<small className={`${this.state.selectedAccessMethod.length ? '' : 'displayNone'} filterLabel`}>Access Method</small>
															<div className="col-sm-12 p-0 d-flex justify-content-between position-relative mb-1">
																<div className='custom-dropdown removeDropdownSearchBar'>
																	<ReactMultiSelectCheckboxes
																		
																		placeholderButtonLabel="Access Method"
																		menuIsOpen ={this.state.isAccessOpen}
																		value={this.state.selectedAccessMethod.map(accMethod => ({
																			value: accMethod,
																			label: accMethod
																		}))}
																		onChange={arr => {
																		this.handleMultiSelectChange('users', 'selectedAccessMethod', arr ? arr : []) }}
																		options={accessMethodOptions}
																	/>
																</div>
																<div>
																	<select className='cursorPointer position-absolute right-0 small border-0 text-muted includeDropDiv'
																		value={this.state.accessIncludeType}
																		onChange={event => {
																			this.setState({ accessIncludeType: event.target.value})
																		}}>
																		<option value='include'>Include</option>
																		<option value='exclude'>Exclude</option>
																	</select>
																</div>
															</div>
															<div className={`container-fluid mb-2 mt-2 ${this.state.showAccessDropdown ? '' : 'd-none'} `}>
																<div className='row'>
																	<div className="col-sm-12 p-0 displayNone">
																		<span className="text-center">Showing {this.state.selectedAccessMethod.length} of {this.state.selectedAccessMethod.length} results</span>
																	</div>
																</div>
																<div className='row'>
																	<div className="pl-0">
																		<span className="dropCancelBtn" onClick={ () => this.fitlerBtnClick('access', 'cancel') }>
																			Cancel
																		</span>	
																	</div>
																	<div className="flex-grow-1 text-right pr-0">
																		<span className="dropApplyBtn" onClick={ () => this.fitlerBtnClick('access', 'apply') }>
																			Apply Filters
																		</span>	
																	</div>
																</div>
															</div>
														</div>
														<div className={`border-bottom pt-1`} ref={this.actionRef}>
															<small className={`${this.state.selectedAction.length ? '' : 'displayNone'} filterLabel`}>Action</small>
															<div className="col-sm-12 p-0 d-flex justify-content-between position-relative mb-1">
																<div className='custom-dropdown removeDropdownSearchBar'>
																	<ReactMultiSelectCheckboxes
																		
																		placeholderButtonLabel="Action"
																		menuIsOpen ={this.state.isActionOpen}
																		value={this.state.selectedAction.map(status => ({
																				value: status,
																				label: status
																		}))}
																		onChange={arr => {
																		this.handleMultiSelectChange('users', 'selectedAction', arr ? arr : []) }}
																		options={actionOptions}
																	/>
																</div>
																<div>
																	<select className='cursorPointer position-absolute right-0 small border-0 text-muted includeDropDiv'
																		value={this.state.actionIncludeType}

																		onChange={event => {
																			this.setState({ actionIncludeType: event.target.value})
																		}}
																	>
																		<option value='include'>Include</option>
																		<option value='exclude'>Exclude</option>
																	</select>
																</div>
															</div>
															<div className={`container-fluid mb-2 ${this.state.showActionDropdown ? '' : 'displayNone'}`}>
																<div className='row'>
																	<div className="col-sm-12 p-0 displayNone">
																		<span className="text-center">Showing {actionOptions.length} of {actionOptions.length} results</span>
																	</div>
																</div>
																<div className='row'>
																	<div className="pl-0">
																		<span className="dropCancelBtn" onClick={ () => this.fitlerBtnClick('action', 'cancel') }>
																			Cancel
																		</span>	
																	</div>
																	<div className="flex-grow-1 text-right pr-0">
																		<span className="dropApplyBtn" onClick={ () => this.fitlerBtnClick('action', 'apply') }>
																			Apply Filters
																		</span>	
																	</div>
																</div>
															</div>
														</div>
														<div className={`border-bottom pt-1`} ref={this.statusRef}>
															<small className={`${this.state.selectedStatus.length ? '' : 'displayNone'} filterLabel`}>Status</small>
															<div className="col-sm-12 p-0 d-flex justify-content-between position-relative mb-1">
																<div className='custom-dropdown removeDropdownSearchBar'>
																	<ReactMultiSelectCheckboxes
																		
																		placeholderButtonLabel="Status"
																		menuIsOpen ={this.state.isStatusOpen}
																		value={this.state.selectedStatus.map(status => ({
																				value: status,
																				label: status
																		}))}
																		onChange={arr => {
																		this.handleMultiSelectChange('users', 'selectedStatus', arr ? arr : []) }}
																		options={statusOptions}
																	/>
																</div>
																<div>
																	<select className='cursorPointer position-absolute right-0 small border-0 text-muted includeDropDiv'
																		value={this.state.statusIncludeType}

																		onChange={event => {
																			this.setState({ statusIncludeType: event.target.value})
																		}}
																	>
																		<option value='include'>Include</option>
																		<option value='exclude'>Exclude</option>
																	</select>
																</div>
															</div>
															<div className={`container-fluid mb-2 ${this.state.showStatusDropdown ? '' : 'displayNone'}`}>
																<div className='row'>
																	<div className="col-sm-12 p-0 displayNone">
																		<span className="text-center">Showing {statusOptions.length} of {statusOptions.length} results</span>
																	</div>
																</div>
																<div className='row'>
																	<div className="pl-0">
																		<span className="dropCancelBtn" onClick={ () => this.fitlerBtnClick('status', 'cancel') }>
																			Cancel
																		</span>	
																	</div>
																	<div className="flex-grow-1 text-right pr-0">
																		<span className="dropApplyBtn" onClick={ () => this.fitlerBtnClick('status', 'apply') }>
																			Apply Filters
																		</span>	
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-sm-12 mt-3">
												{/* <p className='font-weight-bold m-0'>Trends</p> */}		
												{this.state.assessTrendTable && this.state.assessTrendTable.length > 10 ?
												<p className="mb-1 small">Showing 10 results out of {this.state.assessTrendTable.length} in {this.state.selectedGroupByTags === '' ? capitalizeFirstLetter(this.state.selectedGroupBy) : capitalizeFirstLetter(this.state.selectedGroupByTags)}  Trends</p>

												: 
												<p className="mb-1 small">Showing {this.state.assessTrendTable.length} results out of {this.state.assessTrendTable.length} in {this.state.selectedGroupByTags === '' ? capitalizeFirstLetter(this.state.selectedGroupBy) : capitalizeFirstLetter(this.state.selectedGroupByTags)} Trends</p>
												}

												{this.state.assessTrendTable ?
													<Table2
														data={this.state.assessTrendTable.length ? this.state.assessTrendTable : this.state.assessTrendTable}
														columns = { this.state.columns }
														
														onClickRow={tableRow => {}}
														dashboard = {this.state.assessTrendTable.length ? true : false}
														tooltipFor={[]}
														marginRight={10}
													/>
													:
													/* <Table2
														columns = {[
															{
																Header: this.state.selectedGroupByTags === '' ? capitalizeFirstLetter(this.state.selectedGroupBy) : capitalizeFirstLetter(this.state.selectedGroupByTags),
																accessor:'123',
															},
															{
																Header: 'Total',
																accessor:'123',
															},
															{
																Header: '',
																accessor:'123',
															}
														]}
														data={this.state.assessTrendTable.length ? this.state.assessTrendTable : this.state.assessTrendTable}
														onClickRow={tableRow => {}}
														dashboard = {0}
														tooltipFor={[]}
													/> */

													null
												}
												
											</div>
										</div>
										<div className="row py-3 ml-0 ">
											<p className='col-sm-12 font-weight-bold m-0 f15'>Lorem Ipsum</p>
											<p className="col-sm-12 mb-1 small text-muted">is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
											<div className="col-sm-4 mt-3">
												<p className='font-weight-bold m-0'>By Category</p> 
												<p className="mb-1 small text-muted">Showing Top 10  categories Sed in semper ex. Nulla sed finibus orci, eget semper odio. Aenean viverra bibendum </p>
												{
													this.state.category_doughnut_data && this.state.category_doughnut_data.length ?
													<div>
													<Doughnut data={this.state.category_doughnut_data} options={this.state.category_doughnut_options} width={380} height={280} />
													</div>
													: null
												}
											</div>
											<div className="col-sm-4 mt-3">
												<p className='font-weight-bold m-0'>By Services</p> 
												<p className="mb-1 small text-muted">Showing Top 10  services Sed in semper ex. Nulla sed finibus orci, eget semper odio. Aenean viverra bibendum </p>
												{
													this.state.service_doughnut_data && this.state.service_doughnut_data.length ?
													<div>
													<Doughnut data={this.state.service_doughnut_data} options={this.state.service_doughnut_options} width={380} height={280} />
													</div>
													: null
												}
											</div>
											<div className="col-sm-4 pl-3 align-self-center">
												<p className='font-weight-bold m-0'>&nbsp;</p> 
												<p className="mb-1 small">&nbsp;</p>
												<ul className="list-unstyle m-0 p-0">
													<li className="mb-2 f12 d-flex"> 

														<span className="d-block text-muted font-weight-bolder">
															<b className="text-primary f16">5370</b> Actions Allowed in IAM Policy but NOT performed in Cloud 
														</span>
													</li>
													<li className="mb-2 f12 d-flex"> 

														<span className="d-block text-muted font-weight-bolder">
															<b className="text-primary f16">5370</b> Actions Allowed in IAM Policy but NOT performed in Cloud 
														</span>
													</li>
													<li className="mb-2 f12 d-flex"> 

														<span className="d-block text-muted font-weight-bolder">
															<b className="text-primary f16">5370</b> Actions Allowed in IAM Policy but NOT performed in Cloud 
														</span>
													</li>
												</ul>
											</div>
										</div>
										<div className="col-sm-12 my-3 minHeight300">
											<div className="col-sm-12">
												<div className="row d-flex">
													<div className="col-lg-3">
														<div className="d-flex">
															<div className="mr-3">
																{/* <label>Services</label> */}
																<ReactMultiSelectCheckboxes
																	value={this.state.selectedTableService.map(service => ({
																			value: service,
																			label: service
																	}))}
																	onChange={arr => {
																	this.tablefilterMultiSelectChange('users', 'selectedTableService', arr ? arr : []) }}

																	options={this.state.definedService.map(service => ({
																	value: service.service_name,
																	label: service.service_name,
																	}))}

																/>
															</div>
															<div className="mr-3">
																{/* <label>Function</label> */}
																<ReactMultiSelectCheckboxes
																	value={this.state.selectedTableStatus.map(status => ({
																		value: status,
																		label: status
																	}))}
																	onChange={arr => {
																	this.tablefilterMultiSelectChange('users', 'selectedTableStatus', arr ? arr : []) }}
																	
																	options={this.state.permissionFilterData && this.state.permissionFilterData.length ?
																	_.chain(this.state.permissionFilterData)
																	.groupBy('category')
																	.map((value, key) => ({ label: key, sub: value }))
																	.value()
																	.map(( cat, i) => ({
																		value: cat.label,
																		label: cat.label
																	}))
																	: 
																	emptyOptions
																	}
																/>
															</div>
														</div>
													</div>
													<div className="col-lg-9  align-self-center">
														<p className="mb-1 small text-muted">is simply dummy text of the printing and typesetting. Lorem Ipsum has been the standard dummy since 1500s.</p>
													</div>
													
												</div>
												<div className='table-responsive'>
													<table className='table table-sm borderCollapseSpace'>
														<tbody>
															{this.state.permissionFilterData && this.state.permissionFilterData.length ? this.state.permissionFilterData.map((permission,p) => {
																	return(
																	<tr key={p} className="mb-1">
																		<td className='borderLeft7'>
																			<span className="f16">{permission.service_name}</span> <br />
																			<span className="blackMuted">{permission.category}</span>
																		</td>
																		<td>
																			<span className="blackMuted">Usecases</span> <br />
																			<div className="p-0 d-flex flex-wrap">
																			{permission.usecases.map((useCase, x) => {
																				return (
																					<div key={x}  className="pr-2">
																						<p className="f12 mb-0 text-nowrap cursorPointer" id={`us_`+p+`_`+x}>{useCase.usecase} {x < (permission.usecases.length-1) ? ', ' : ' ' }</p>							
																						<UncontrolledPopover trigger="hover" placement="top" target={`us_`+p+`_`+x}>
																							<PopoverBody>
																								<p className="font-weight-bold mb-1">Service Name: <small className="f15">{permission.service_name} </small></p>
																								<p className="font-weight-bold mb-1">Usecase: <small className="f15">{useCase.usecase}</small></p>
																								<p className="font-weight-bold mb-1 f13"> Actions:
																								{useCase.actions.map((action, z) => {                                        				
																								return (
																									<small className="f14"> {action} <br/></small>
																								)
																								})}
									

																								</p>
																								<div className="d-flex">
																								</div>
																							</PopoverBody>
																						</UncontrolledPopover>
																						</div>
																				)												
																			})}
																			</div>
																		</td>
																	</tr>
																	)
																})
																: 
																<tr className="mb-1 text-center">
																	<td className='borderLeft7'>
																		No Data Found
																	</td>
																</tr>
															}
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
UserInsights.propTypes = {
	getIdentitiesList:PropTypes.func,
	lastAccessedDetails:PropTypes.func,
	getTotalCount:PropTypes.func,
	getTotalAssetsAccessed:PropTypes.func,
	getAccessTrend:PropTypes.func,
	listUsersAssumingRole:PropTypes.func,
	getAssetsAccessibleCount:PropTypes.func,
	getPermissionDetails:PropTypes.func,
	getActionsCategoryWise:PropTypes.func,
	getActionsServiceWise:PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		
	}
}

export default AppWrapper(UserInsights, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllServices, 

	getIdentitiesList,
	lastAccessedDetails,
	getTotalCount,
	getTotalAssetsAccessed,
	getAccessTrend,
	listUsersAssumingRole,
	getAssetsAccessibleCount,
	getPermissionDetails,
	getActionsCategoryWise,
	getActionsServiceWise
})



