/*************************************************
 * Tvastar
 * @exports
 * @file resultsAction.js
 * @author Rajasekar // on 27/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'

import {
	DETECTORS,
	GET_COMPLIANCE_BY_EVENT,
	GET_COMPLIANCE_BY_ASSET,
	GET_COMPLIANCE_BY_SERVICE_COUNT,
	GET_COMPLIANCE_BY_ROLE,
	GET_COMPLIANCE_CONTROL_DETAILS,
	GET_COMPLIANCE_SUB_CONTROL_DETAILS,
	GET_COMPLIANCE_MINOR_CONTROL_DETAILS,
	GET_RISK_WISE_COMPLEXITY,
	GET_PASS_FAIL_DETAILS,
	GET_OBSERVATIONS,
	GET_RISK_BY_ROLE,
	GET_COMPLIANCE_BY_TAG,
	GET_TRANSITION_TREND,
	GET_DAY_WISE_ASSET_COUNT
} from '../../config'

/**
 * Action to get compliance by event
 * @param {Object} body
 * @param {Function} callback
 */
export const getPassFailDetails = (body, callback) => {
	return async () => {
		try {
			const getComEvent = await API.post(DETECTORS, GET_PASS_FAIL_DETAILS, { body })
			callback(true, getComEvent)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to get compliance by event
 * @param {Object} body
 * @param {Function} callback
 */
export const getComplianceByEvent = (body, callback) => {
	return async () => {
		try {
			const getComEvent = await API.post(DETECTORS, GET_COMPLIANCE_BY_EVENT, { body })
			callback(true, getComEvent)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to get compliance by asset
 * @param {Object} body
 * @param {Function} callback
 */
export const getComplianceByAsset = (body, callback) => {
	return async () => {
		try {
			const getComEvent = await API.post(DETECTORS, GET_COMPLIANCE_BY_ASSET, { body })
			callback(true, getComEvent)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to get compliance by score count
 * @param {Object} body
 * @param {Function} callback
 */
export const getComplianceByScoreCount = (body, callback) => {
	return async () => {
		try {
			const getComEvent = await API.post(DETECTORS, GET_COMPLIANCE_BY_SERVICE_COUNT, { body })
			callback(true, getComEvent)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to get compliance by role
 * @param {Object} body
 * @param {Function} callback
 */
export const getComplianceByRole = (body, callback) => {
	return async () => {
		try {
			const getComEvent = await API.post(DETECTORS, GET_COMPLIANCE_BY_ROLE, { body })
			callback(true, getComEvent)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to get compliance Control details
 * @param {Object} body
 * @param {Function} callback
 */
export const getComplianceControlDetails = (body, callback) => {
	return async () => {
		try {
			const getComEvent = await API.post(DETECTORS, GET_COMPLIANCE_CONTROL_DETAILS, { body })

			let result = null
			if(getComEvent.length > 0){
				 result = getComEvent[0].controls;
			}
			callback(true, result)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to get compliance sub control details
 * @param {Object} body
 * @param {Function} callback
 */
export const getComplianceSubControlDetails = (body, callback) => {
	return async () => {
		try {
			const results = await API.post(DETECTORS, GET_COMPLIANCE_SUB_CONTROL_DETAILS, { body })			
			let response = null
			var subControl ={};
			results[0].controls.forEach((item)=>{
			  if(item.total_sub_controls >0){
				subControl[item.control] = item;
			  }
			})
			
			if(Object.entries(subControl).length > 0){
			  response = subControl;
			}
			callback(true, response)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to get compliance minor control details
 * @param {Object} body
 * @param {Function} callback
 */
export const getComplianceMinorControlDetails = (body, callback) => {
	return async () => {
		try {
			const results = await API.post(DETECTORS, GET_COMPLIANCE_MINOR_CONTROL_DETAILS, { body })
			var minorControl ={};
			results[0].controls.forEach((item)=>{
				minorControl[item.control] = item;
			})
			callback(true, minorControl)
		} catch (error) {
			callback(false)
		}
	}
}

/** Action to get compliance minor control details
* @param {Object} body
* @param {Function} callback
*/
export const getRiskWiseComplexity = (body, callback) => {
   return async () => {
	   try {
		   const results = await API.post(DETECTORS, GET_RISK_WISE_COMPLEXITY, { body })
		   callback(true, results)
	   } catch (error) {
		   callback(false)
	   }
   }
}

/** Action to get Observations
* @param {Object} body
* @param {Function} callback
*/
export const getObservations = (body, callback) => {
	return async () => {
		try {
			const results = await API.post(DETECTORS, GET_OBSERVATIONS, { body })
			callback(true, results)
		} catch (error) {
			callback(false)
		}
	}
}

/** Action to get Risk By Role
* @param {Object} body
* @param {Function} callback
*/
export const getRiskByRole = (body, callback) => {
	return async () => {
		try {
			const results = await API.post(DETECTORS, GET_RISK_BY_ROLE, { body })
			callback(true, results)
		} catch (error) {
			callback(false)
		}
	}
}

/** Action to get Compliance By Tag
* @param {Object} body
* @param {Function} callback
*/
export const getComplianceByTag = (body, callback) => {
	return async () => {
		try {
			const results = await API.post(DETECTORS, GET_COMPLIANCE_BY_TAG, { body })
			callback(true, results)
		} catch (error) {
			callback(false)
		}
	}
}

/** Action to get Transition Trend
* @param {Object} body
* @param {Function} callback
*/
export const getTransitionTrend = (body, callback) => {
	return async () => {
		try {
			const results = await API.post(DETECTORS, GET_TRANSITION_TREND, { body })
			callback(true, results)
		} catch (error) {
			callback(false)
		}
	}
}

/** Action to get Transition Trend
* @param {Object} body
* @param {Function} callback
*/
export const getDayWiseAssetCount = (body, callback) => {
	return async () => {
		try {
			const results = await API.post(DETECTORS, GET_DAY_WISE_ASSET_COUNT, { body })
			callback(true, results)
		} catch (error) {
			callback(false)
		}
	}
}