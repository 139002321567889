/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 01/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 * 
 *************************************************/
import React, { Component } from 'react'
import { AppWrapper } from '../../common/AppWrapper'
import { URL_PATH } from '../../../config/urlPath'
// import PropTypes from 'prop-types'
import { listAllProviders, listAllAccounts, listAllRegions } from '../../../actions/commonAction'
import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'
import { getCoveredServices, listCoveredAssets } from '../../../actions/detectors/detectorsAction'
import {getChangeEvents } from '../../../actions/aiops/ObservabilityAction'
import { getMonitoringMetricsByTrend } from '../../../actions/aiops/MetricsAction'

import { UncontrolledTooltip, Spinner } from 'reactstrap'
import { momentDateGivenFormat, subHours, twoDateDiffrence, subDays, getDayFromSelectedDuration, getAccountNameFromId, getRegionName, convertBytes, convertSeconds, countFormater, convertBits, capitalizeFirstLetter, momentConvertionUtcToLocalTime, momentConvertionLocalToUtcTime } from '../../../utils/utility'

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import _ from 'lodash'

import Search from '../../common/SearchComponent'
import SearchWithHiddenInput from '../../common/SearchWithHiddenInput'

import ApexListLineChart from '../../common/charts/listCharts/ApexListLineChart'
import ApexListBarChart from '../../common/charts/listCharts/ApexListBarChart'
import AssetDetailPage from './AssetDetailPage'

const durationOptions = [    
    { value: '+1h', option: 'Last 1 hour' },
    { value: '+6h', option: 'Last 6 hours' },
    { value: '+12h', option: 'Last 12 hours' },
    { value: '+3h', option: 'Last 3 hours' },
	{ value: '+24h', option: 'Last 24 hours' }
]
let timer = null;

const alertsCount = [
    {alerts: 10, errors: 10, checks: 10, compliance: 10, anomalies: 10, issues: 10, notficaitons: 10},
    {alerts: 3, errors: 3, checks: 3, compliance: 3, anomalies: 3, issues: 3, notficaitons: 3},
    {alerts: 100, errors: 100, checks: 100, compliance: 100, anomalies: 100, issues: 100, notficaitons: 100},
]

class LandingPage extends Component {
    constructor(props) {
        super(props)
        this.accountRef = React.createRef()
        this.regionRef = React.createRef()
        
        this.state = {
			minMaxHeight: "770px",
            showLoading: true,
            providers1:[
                {provider_name: "AWS"}
            ],

            //filter section start

            selectedProvider: "",

            selectedAccount: [],
            isAccountOpen: false,

            selectedRegion: [],
            isRegionOpen: false,

            selectedDuration: "+3h",

            //filter section end
            coreGraphs: ["cpu", "memory", "disk", "network", "connections"],

            startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
			perPage: 11,
            endRecord: 11,
            alertsCount: alertsCount
        }
    }

	componentDidMount = () => {
        if(this.props.providers && this.props.providers.length) {
			this.setState({ providers: this.props.providers, selectedProvider: this.props.providers[0].provider_name },
				() => {
					this.getFilterData('provider')
				}
			)
		} else {
			this.props.listAllProviders((promise, providers) => {
				if (promise) {
					this.setState({ providers, selectedProvider: providers[0].provider_name },
						() => {
							this.getFilterData('provider')
						}
					)
				} else {
					this.setState(
						{ providers: [],  }, 
						() => {
							this.getFilterData()
						}
					)
				}
			})
		}
    }
    
	getFilterData = from => {
		let params = {}

		if (this.state.selectedProvider !== "" && this.state.selectedProvider !== 'all') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}

		if (from === 'provider') {
			if(this.props.accounts && this.props.accounts.length) {
				let allAccounts = this.props.accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
				if(this.props.accounts.length) {
					let accRow = {}
					accRow.account_id = "All"
					accRow.account_name = "All"
					accRow.date = null
					accRow.disabled = false
					accRow.provider = "All"
					allAccounts.unshift(accRow);
				}
				this.setState({ accounts: allAccounts })
			} else {
				this.props.listAllAccounts(params, (promise, allAccounts) => {
					if (promise) {						
						let filterdAccounts = allAccounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
						if(filterdAccounts.length) {
							let accRow = {}
							accRow.account_id = "All"
							accRow.account_name = "All"
							accRow.date = null
							accRow.disabled = false
							accRow.provider = "All"
							filterdAccounts.unshift(accRow);
						}						
						this.setState({ accounts: filterdAccounts })
					}
				})
			}

			if(this.props.regions && this.props.regions.length) {
				let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
				this.setState({ regions: providerRegions })
			} else {
				this.props.listAllRegions(params, (promise, regions) => {
					if (promise) {
						let providerRegions = regions && regions[0] && regions[0].provider_regions
						if(providerRegions) {
							let provider_regions_row = {}
							provider_regions_row.availability_zones = []
							provider_regions_row.city = "All"
							provider_regions_row.country = "All"
							provider_regions_row.geography = "All"
							provider_regions_row.name = "All"
							provider_regions_row.region = "All"
							provider_regions_row.region_id = "All"
							provider_regions_row.state = "All"
							providerRegions.unshift(provider_regions_row);
						}
						this.setState({ regions: providerRegions })
					} else {
						this.setState({ regions: [] })
					}
				})
			}
		}

        this.durationFunction('onLoad')
	
    	
		this.getWindowSize()
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 105  
		let outerHeight = (window.outerHeight - browserBorder - headerHeight) +"px"

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }

    onSearch = (type) => {
        if(this.state.changesInFilter) {
            let sliderStartDate = this.state.sliderStartDate
            let sliderEndDate = this.state.sliderEndDate
            if(this.state.selectedDuration === "custom") {
                sliderStartDate = this.state.datePickerStartDate && this.state.datePickerStartDate !== "" ? this.state.datePickerStartDate : sliderStartDate
                sliderEndDate = this.state.datePickerEndDate && this.state.datePickerEndDate !== "" ? this.state.datePickerEndDate : sliderEndDate
            }
            this.setState({
                brushStartTime: "",
                brushEndTime: "",
                changesInFilter: false,
                showLoading: true,
                resetEnabled: type === 'onLoad' ? false : true,
                sliderStartDate,
                sliderEndDate,
                nextErrorListToken: ""
            },
                () => {
                    // let obj = {}
                    // obj.start_time = this.state.sliderStartDate
                    // obj.end_time = this.state.sliderEndDate
                    // this.props.setAiopsPropsDetails('observabilityAssetPageFilter', obj)
                    this.getCoveredServices()
                    let obj = []
                    this.props.setAiopsPropsDetails("obs_asset_core_metrics", obj)
                }
            )
        }

        // setTimeout(() => { this.setState({ showLoading: false }) }, 3000)
    }

    onReset = () => {
        this.setState({ 
            selectedProvider: "",
            selectedAccount: [],
            selectedDuration: '+3h',
            changesInFilter: this.state.resetEnabled,
            datePickerStartDate: "",
            datePickerEndDate: ""
        },
            () => {
                if(this.state.resetEnabled) {
                    this.durationFunction('onLoad')
                }
            }
        )
    }

    getCoveredServices = () => {
        let params = {}
        params.provider = this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider.toLowerCase() : ""
        if(this.state.selectedAccount && this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
            params.region = this.state.selectedRegion
        }
        params.monitoring_enabled_services = true
        params.assets_present = true
        params.include_request = false
        params.aggregate_by = ["resource_type", "service_name"]
        params.radial = true
        
        if(params.provider !== "") {
            this.props.getCoveredServices(params, (promise, response) => {
                if(promise) {
                    let results = response.results && response.results.length ? response.results : []
                    let selectedResources = results.length ? results[0].resource_type : ""
                    let selectedServiceNames = results.length && results[0].data ? Object.keys(results[0].data) : []
                    if(this.state.selectedResources && results.length) {
                        if(results.filter(e => e.resource_type === this.state.selectedResources).length) {
                            selectedResources = results.filter(e => e.resource_type === this.state.selectedResources)[0].resource_type
                            selectedServiceNames = selectedResources.length && selectedResources[0].data ? Object.keys(selectedResources[0].data) : []
                        }
                    }
                    this.setState({ 
                        resourcesList: results,
                        selectedResources,
                        selectedServiceNames,
                        showLoading: results.length ? true : false,
                    },
                        () => {
                            if(this.state.resourcesList.length) {
                                this.listCoveredAssets()
                                this.getChangeEvents()
                            }
                        }
                    )
                } else {
                    this.setState({ resourcesList: [], showLoading: false })
                }
            })
        } else {
            this.setState({ resourcesList: [], showLoading: false })
        }
    }

    listCoveredAssets = () => {
        let params = {}
        params.start_time = momentConvertionLocalToUtcTime(this.state.sliderStartDate, "YYYY-MM-DD HH:mm:00")
        params.end_time = momentConvertionLocalToUtcTime(this.state.sliderEndDate, "YYYY-MM-DD HH:mm:00")
        params.provider = this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider.toLowerCase() : ""
        if(this.state.selectedAccount && this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
            params.region = this.state.selectedRegion
        }
        // if(this.state.nextErrorListToken && this.state.nextErrorListToken !== '') {
        //     params.next_token = this.state.nextErrorListToken
        // }
        // params.resource_type = this.state.selectedResources
        params.service_name = this.state.selectedServiceNames
        // params.size = 1000
        if(params.provider !== "") {
            this.props.listCoveredAssets(params, (promise, response) => {
                if(promise) {
    
                    let assetsList = [] 
                    // if(this.state.nextErrorListToken && this.state.nextErrorListToken !== '') {
                    //     assetsList = this.state.assetsList ? this.state.assetsList : []
                    // }
                    
                    if(response.results && response.results.length) {
                        assetsList = response.results
                        // assetsList = assetsList.concat(response.results)
                    }
    
                    // let results = response.results
                    let totalPages = 1
                    if(assetsList && assetsList.length > this.state.perPage) {
                        totalPages = Math.ceil(assetsList.length / this.state.perPage)
                    }
    
                    this.setState({ 
                        assetsList, 
                        filteredAssetList: assetsList, 
                        showLoading: false,
                        totalPages,
                        endRecord: 11,
                        startRecord: 0, 
			            currentPage: 1,

                        nextErrorListToken: response.next_token ? response.next_token : '',  },
                        ()  => {
                            if(this.state.assetsList.length) {
                                this.brushOptions()
                                this.getAssetIdForMetrics()
                                // this.getAssetObservabiltyMetrics(this.state.assetsList[0])
                            }
                            if(this.state.filteredAssetList.length > this.state.endRecord) {
                                this.setState({ showLodeMore: true })
                            } else {
                                this.setState({ showLodeMore: false })
                            }                            
                        }
                    )
                } else {
                    this.setState({ assetsList: [], filteredAssetList: [], showLoading: false })
                }
            })
        } else {
            this.setState({ assetsList: [], filteredAssetList: [], showLoading: false })
        }
    }

    getChangeEvents = () => {   
        let params = {}
        params.provider = this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider.toLowerCase() : ""
        // params.duraiton = this.state.selectedDuration
        params.start_time = momentConvertionLocalToUtcTime(this.state.sliderStartDate, "YYYY-MM-DD HH:mm:ss")
        params.end_time = momentConvertionLocalToUtcTime(this.state.sliderEndDate, "YYYY-MM-DD HH:mm:ss")
        params.aggregate_by = "asset"
        params.service_name = this.state.selectedServiceNames
        params.include_name = true
        
        this.props.getChangeEvents(params, (promise, response) => {
            if(promise) {
                this.setState({ changesResponse: response && response.length ? response : [] })
            } else {
                this.setState({ changesResponse: [], showLoading: false })
            }
        })
    }

    getResourcesAlerts = (item) => {
        // let filterData = this.state.resourcesList.filter(e => e.label === item.label)
        let selectedServiceNames = item.data ? Object.keys(item.data) : []
        this.setState({ selectedResources: item.resource_type, selectedServiceNames, assetsList: [], filteredAssetList: [], showLoading: true },
            () => {
                this.listCoveredAssets()
                this.getChangeEvents()
                let scroll = document.getElementById('scrollTop');
                scroll.scrollIntoView();
            }    
        )
    }
    
    handleMultiSelectChange = (field, arrayValue) => {
		let value = arrayValue.map(item => item.value)

		let selectedValue = []
		if(field === 'selectedAccount') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.accounts.forEach(acc => {
						selectedValue.push(acc.account_id)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.accounts.length -1)) {
					selectedValue.push('All')
				}
			}	
            this.setState({ [field]: selectedValue, changesInFilter: true })		
		} else if(field === 'selectedRegion') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.regions.length -1)) {
					selectedValue.push('All')
				}
			}
            this.setState({ [field]: selectedValue })
		} 
    }
    
    getMultiSelectedCount = (type, array) => {
        return array.length && array.includes('All') ? 'All' : (array.length ? array.length +' Selected' : 'All')
    }

    durationFunction = (type) => {
        let duration = this.state.selectedDuration
        let period = duration.substring(duration.length, duration.length-1)
        let durationBtw = getDayFromSelectedDuration(duration)
        let sliderStartDate = ""
        if(period === "d") {
            sliderStartDate = momentDateGivenFormat(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
        } else {
            sliderStartDate = momentDateGivenFormat(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
        }
        // let days = this.state.selectedDuration.replace('+', "").replace('d', "")
        // let reducedDays = days - 1
        // let sliderStartDate = momentDateGivenFormat(subDays(new Date(), reducedDays), 'YYYY-MM-DD 00:00:00')
        
        let sliderEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')

        let selectionRange = [{
			startDate: new Date(sliderStartDate),
			endDate: new Date(sliderEndDate),
			key: 'selection',
		}]

        let dayDiffrence = twoDateDiffrence(this.state.sliderEndDate, this.state.sliderStartDate)

        this.setState({ sliderStartDate, sliderEndDate, selectionRange, changesInFilter: true, dayDiffrence },
            () => {
                if(type === "onLoad") {
                    this.onSearch('onLoad')
                }
            }
        )
    }

    handleClickOutside = (event) => {
        if(!this.state.showQueryResults) {

            if (this.accountRef && !this.accountRef.current.contains(event.target)) {
                this.setState({ isAccountOpen: false })
            } else {
                this.setState({ isAccountOpen: true })
            }

            if (this.regionRef && !this.regionRef.current.contains(event.target)) {
                this.setState({ isRegionOpen: false })
            } else {
                this.setState({ isRegionOpen: true })
            }            
        }
    }

    brushOptions = () => {
        let brushSeries = []
        let brushRow = {}
        brushRow.data = [
            {x: momentDateGivenFormat(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm'), y: 0},
            {x: momentDateGivenFormat(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm'), y: 0}
        ]
        brushSeries.push(brushRow)

        let endTIme = momentDateGivenFormat(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:ss')
        let time = endTIme
        let brushHours = 2
        let diff = twoDateDiffrence(this.state.sliderEndDate, this.state.sliderStartDate)
        
        if(diff > 1 && diff <= 3) {            
            brushHours = 6
        } else if(diff > 3 && diff <= 7) {
            brushHours = 24 //3
        } else if(diff > 7 && diff <= 14) {
            brushHours = 36 //6
        } else if(diff > 14 && diff <= 30) {
            brushHours = 48 //12
        } else if(diff > 30) {
            brushHours = 96 //24
        }

        let brushStartTime = subHours(time, brushHours)
        let brushEndTime = time
        
        let optionsBrush = {
            chart: {
                background: '#fff',
                id: 'chart1',
                type: 'line',
                sparkline: {
                    enabled: true
                },
                zoom: {
                    enabled: false,
                },
                brush:{
                    // targets: ['bar1', 'bar2'],
                    target: "",
                    enabled: true,
                    autoScaleYaxis: true,
                },
                selection: {
                    enabled: true,
                    fill: {
                        color: "#039BE5",
                        opacity: 1,
                    },
                    xaxis: {
                        min: new Date(momentDateGivenFormat(brushStartTime, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                        max: new Date(momentDateGivenFormat(brushEndTime, 'YYYY-MM-DD HH:mm:ss')).getTime()
                    }
                },
                events: {
                    selection: (chartContext, { xaxis, yaxis }) => {
                        this.brushSelection(xaxis)
                    }
                }
            },
            markers: {
                size: 0
            },
            colors: ['#F44336'],
            fill: {
                type: 'solid',
                color: "#000",
                opacity: 1,
            },
            xaxis: {
                type: 'datetime',
                tooltip: {
                    enabled: false
                }
            },
            yaxis: {
                show: false,
                tickAmount: 2
            },
            annotations: {
                yaxis: [
                  {
                    y: 1,
                    borderColor: '#000',
                    label: {
                      borderColor: '#00E396',
                      style: {
                        color: '#fff',
                        background: '#00E396'
                      },
                      text: ""
                    }
                  }
                ]
            }
        }

        this.setState({ brushSeries, optionsBrush },
            // () => this.props.loading()
        )
    }

    brushSelection = (xaxis, type) => {
        clearTimeout(timer)
        let brushStartTime = momentDateGivenFormat(xaxis.min, 'YYYY-MM-DD HH:mm:ss')
        let brushEndTime = momentDateGivenFormat(xaxis.max, 'YYYY-MM-DD HH:mm:ss')       

        if(this.state.brushStartTime && this.state.brushStartTime !== "" && this.state.brushStartTime !== this.state.sliderStartDate && this.state.brushEndTime !== this.state.sliderEndDate) {
            timer = setTimeout(() => this.observabilityAssetPageFilterProps(brushStartTime, brushEndTime, true), 1000)
        } else {
            this.observabilityAssetPageFilterProps(this.state.sliderStartDate, this.state.sliderEndDate, false)
        }
        
        if(brushStartTime && brushEndTime) {
            this.setState({ brushStartTime, brushEndTime, alertListLoader: true })
        }
    }    

    observabilityAssetPageFilterProps = (brushStartTime, brushEndTime, brushTime) => {
        let obj = {}
        obj.start_time = this.state.sliderStartDate
        obj.end_time = this.state.sliderEndDate
        obj.brushStartTime = brushStartTime
        obj.brushEndTime = brushEndTime
        obj.selectedAsset = this.state.selectedAsset ? this.state.selectedAsset : {}
        this.props.setAiopsPropsDetails('observabilityAssetPageFilter', obj)

        this.setState({ brushTime })

        this.props.setAiopsPropsDetails('obsAssetAlertsList', {})
        this.props.setAiopsPropsDetails('obsAssetEventsList', {})
        this.props.setAiopsPropsDetails('obsAssetAnomalyList', {})
        this.props.setAiopsPropsDetails('obsAssetErrorsList', {})
        this.props.setAiopsPropsDetails('observabilityCoreMetricsList', {})
        this.props.setAiopsPropsDetails('observabilitySreMetricsList', {})
    }
    
    getAssetObservabiltyMetrics = (item) => {
        this.props.setAiopsPropsDetails('obsAssetAlertsList', {})
        this.props.setAiopsPropsDetails('obsAssetEventsList', {})
        this.props.setAiopsPropsDetails('obsAssetAnomalyList', {})
        this.props.setAiopsPropsDetails('obsAssetErrorsList', {})
        
        let obj = {}
        obj.start_time = this.state.sliderStartDate
        obj.end_time = this.state.sliderEndDate
        obj.brushStartTime = this.state.brushTime ? this.state.brushStartTime : this.state.sliderStartDate
        obj.brushEndTime = this.state.brushTime ? this.state.brushEndTime : this.state.sliderEndDate
        obj.selectedAsset = item
        this.props.setAiopsPropsDetails('observabilityAssetPageFilter', obj)

        this.setState({ selectedAsset: item, selectedAssetId: item.asset_id, showRightSideDetail: false },
            () => {
                this.setState({ showRightSideDetail: true })
            }
        )
    }    

    navigatePage = (action, currentPage) => {
        if(currentPage === this.state.totalPages && action === 'next') {
            this.setState({ showLoading: true, currentPage: currentPage + 1 },
                () => this.listCoveredAssets()                
            )
        } else if(currentPage > 0) {
            let startRecord = this.state.startRecord
            if(action === 'next' && currentPage !== this.state.totalPages) {
                startRecord = startRecord + this.state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - this.state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = this.state.totalPages
                startRecord = (this.state.totalPages - 1) * this.state.perPage
            }

            this.setState({ currentPage, startRecord },
                () => this.getAssetIdForMetrics()    
            )
        } 
	}

    gotoPage = (pageNumber) => {
        if(pageNumber > 0  && pageNumber <= this.state.totalPages) {
            let startRecord = (pageNumber - 1) * this.state.perPage
            this.setState({ currentPage: pageNumber, pageNumber, startRecord })
        } else  if(pageNumber > this.state.totalPages) {
            this.setState({ pageNumber: this.state.currentPage })  
        } else {
            this.setState({ pageNumber })
        }
    }

    onChangePageType = () => {
        this.setState({ brushStartTime: "", brushEndTime: "" },
            () =>  this.brushOptions()   
        )
    }

    getAssetIdForMetrics = () => {
        let metricsAssetId = []
        if(this.state.filteredAssetList && this.state.filteredAssetList.length) {
            this.state.filteredAssetList.slice((this.state.endRecord - this.state.perPage), this.state.endRecord).forEach(item => {
            // this.state.filteredAssetList.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).forEach(item => {
                // console.log("this.props.obs_asset_core_metrics", this.props.obs_asset_core_metrics)
                if(this.props.obs_asset_core_metrics && this.props.obs_asset_core_metrics.length) {
                    if(!this.props.obs_asset_core_metrics.filter(e => e.asset_id === item.asset_id).length) {
                        metricsAssetId.push(item.asset_id)
                    }
                } else {
                    metricsAssetId.push(item.asset_id)
                }
            })
        }
        this.setState({ loadingMetrics: false },
            () => {
                if(metricsAssetId.length) {
                    this.getMonitoringMetricsByTrend(metricsAssetId)
                } else if(this.props.obs_asset_core_metrics && this.props.obs_asset_core_metrics.length) {
                    this.setState({ metrics_data: this.props.obs_asset_core_metrics },
                        () => this.formMetricsGraph()    
                    )
                }
            }   
        )
    }

    getMonitoringMetricsByTrend = (assetId) => {
        let params = {}
        params.start_time = momentConvertionLocalToUtcTime(this.state.sliderStartDate, "YYYY-MM-DD HH:mm:ss")
        params.end_time = momentConvertionLocalToUtcTime(this.state.sliderEndDate, "YYYY-MM-DD HH:mm:ss")
        params.asset_id = assetId
        // params.top = 5
        params.anomaly = false
        params.category = ["CPU", "Memory", "Disk", "Network", "Connections"]
        // params.additional_metrics = [this.props.details.event_type]
        
        this.props.getMonitoringMetricsByTrend(params, (promise, response) => {
            if(promise) {
                this.setState({ 
                    metrics_data: response && response.length ? response :this.props.obs_asset_core_metrics, 
                    loadingMetrics: false,
                },
                    () => {
                        this.formMetricsGraph()
                    }
                )
                let obj = []
                let data = this.props.obs_asset_core_metrics ? this.props.obs_asset_core_metrics : []
                obj = data.concat(response);
                this.props.setAiopsPropsDetails("obs_asset_core_metrics", obj)
            } else {
                this.setState({
                    loadingMetrics: false 
                })
            }
        })
    }

    formMetricsGraph = () => {
        let graphDetails = this.state.metrics_data ? this.state.metrics_data : []
        if(graphDetails && graphDetails.length) {
            graphDetails.forEach(graph => {
                let graphData = graph.data
                let graphLabels = graph.dates                

                let cpuRow = {}
                let cpuSum = 0

                let memoryRow = {}
                let memorySum = 0
                
                let diskRow = {}
                let diskSum = 0

                let networkRow = {}
                let networkSum = 0

                let connectionsRow = {}
                let connectionsSum = 0

                let networkAvg = []

                Object.entries(graphData).forEach(([key, item]) => {                    
                    let avgData = {}
                    if(item.statistics) {
                        if(item.statistics.avg) {
                            if(item.unit) {
                                avgData.avg = this.getGrapUnitValue(item.statistics.avg, item.unit, "round")
                            } else {
                                avgData.avg = item.statistics.avg
                            }
                        }
                        if(item.statistics.max) {
                            if(item.unit) {
                                avgData.max = this.getGrapUnitValue(item.statistics.max, item.unit, "round")
                            } else {
                                avgData.max = item.statistics.max
                            }
                        }
                    }
                    if(item.category === "CPU") {
                        let data = []                        
                        if(item.values && item.values.length) {
                            item.values.forEach(val => {
                                cpuSum += val
                                if(item.unit) {
                                    val = this.getGrapUnitValue(val, item.unit)
                                }
                                data.push(val)
                            })
                        }    
                        cpuRow.data = data
                        cpuRow.labels = graphLabels
                        
                        this.setState({  ['statistics_cpu_'+graph.asset_id]: avgData })
                    } else if(item.category === "Memory") {
                        let data = []
                        if(item.values && item.values.length) {
                            item.values.forEach(val => {
                                memorySum += val
                                if(item.unit) {
                                    val = this.getGrapUnitValue(val, item.unit)
                                }
                                data.push(val)
                            })
                        }
                        
                        memoryRow.data = data
                        memoryRow.labels = graphLabels
                        this.setState({  ['statistics_memory_'+graph.asset_id]: avgData })
                    } else if(item.category === "Disk") {
                        let data = []
                        if(item.values && item.values.length) {
                            item.values.forEach(val => {
                                diskSum += val
                                if(item.unit) {
                                    val = this.getGrapUnitValue(val, item.unit)
                                }
                                data.push(val)
                            })
                        }    
                        diskRow.data = data
                        diskRow.labels = graphLabels

                        this.setState({  ['statistics_disk_'+graph.asset_id]: avgData })
                    } else if(item.category === "Network") {
                        let data = []
                        if(item.values && item.values.length) {
                            item.values.forEach(val => {
                                if(item.unit) {
                                    val = this.getGrapUnitValue(val, item.unit)
                                }
                                data.push(val)
                            })
                        }
                        
                        let newData = networkRow && networkRow.data ? networkRow.data : {}
                        newData[key] = data
                        
                        networkRow.data = newData
                        networkRow.labels = graphLabels

                        if(item.statistics) {
                            if(item.statistics.avg) {
                                let avg = 0
                                if(item.unit) {
                                    avg = this.getGrapUnitValue(item.statistics.avg, item.unit, "round")
                                } else {
                                    avg = item.statistics.avg
                                }
                                networkAvg.push(avg)
                            }
                        }

                        this.setState({  ['statistics_network_'+graph.asset_id]: networkAvg })

                    } else if(item.category === "Connections") {
                        let data = []
                        if(item.values && item.values.length) {
                            item.values.forEach(val => {
                                connectionsSum += val
                                if(item.unit) {
                                    val = this.getGrapUnitValue(val, item.unit, )
                                }
                                data.push(val)
                            })
                        }
                        connectionsRow.data = data
                        connectionsRow.labels = graphLabels

                        this.setState({  ['statistics_connections_'+graph.asset_id]: avgData })
                    }
                })
                if(cpuSum > 0 && cpuRow && Object.entries(cpuRow).length) {
                    this.setState({  ['core_metrics_cpu_'+graph.asset_id]: cpuRow })
                }
                if(memorySum > 0 && memoryRow && Object.entries(memoryRow).length) {
                    this.   setState({  ['core_metrics_memory_'+graph.asset_id]: memoryRow })
                } 
                if(diskSum > 0 && diskRow && Object.entries(diskRow).length) {
                    this.setState({  ['core_metrics_disk_'+graph.asset_id]: diskRow })
                }
                if(networkRow && Object.entries(networkRow).length) {
                    this.setState({  ['core_metrics_network_'+graph.asset_id]: networkRow })
                }
                if(connectionsSum > 0 && connectionsRow && Object.entries(connectionsRow).length) {
                    this.setState({  ['core_metrics_connections_'+graph.asset_id]: connectionsRow })
                }
            })

            this.setState({ coreGraphs: [] },
                () => this.setState({ coreGraphs: ["cpu", "memory", "disk", "network", "connections"] })    
            )
        }   
    }

    getGrapUnitValue = (val ,unit, valueType) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                if(valueType === "round") {
                    val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(0))
                } else {
                    val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
                }
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytes(val, unit, valueType)
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit, valueType)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit, valueType)
            } else if(unit === 'Count') {
                // val = val > 1 ? parseInt(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {          
            if(valueType === "round") { 
                val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(0))
            } else {
                val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
            }
        }
        return val
    }

    redirectToDetailPage = (selectedAsset) => {
        this.props.history.push({
            pathname: URL_PATH.OBSERVABILITY_ASSET_DETAILS,
            state: { 
                selectedProvider: this.state.selectedProvider ? this.state.selectedProvider : "",
                selectedAccount: this.state.selectedAccount ? this.state.selectedAccount : [],
                selectedRegion: this.state.selectedRegion ? this.state.selectedRegion : [],
                selectedDuration: this.state.selectedDuration ? this.state.selectedDuration : "",
                selectedAsset
            }
        })
    }

    nextSetOfRecord = () => {
        let lastEndRecord = this.state.endRecord
        let endRecord = this.state.endRecord + this.state.perPage
        this.setState({ endRecord },
            () => {
                if(lastEndRecord > this.state.filteredAssetList.length && this.state.nextErrorListToken !== "") {
                    this.listCoveredAssets()
                } else if(lastEndRecord > this.state.filteredAssetList.length) {
                    this.setState({ endRecord, showLodeMore: false })
                } else {
                    if(endRecord > this.state.filteredAssetList.length) {
                        this.setState({ showLodeMore: false })
                    } 
                    this.getAssetIdForMetrics()
                }
            }
        )
    }

	render() {
		return (
            <div className="container-fluid overflow-auto flex-grow-1 bg-muted" onClick={ (event) => { this.handleClickOutside(event) } }>
                <div className={`loaderOverlay ${this.state.showLoading ? "" : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
                <div className='row h-100'>
                    <div className='col-sm-12 p-0'>
                        <div className="title-section p-2">
							<div className="d-flex">
                                <div className='col-4 align-self-center pl-2'>
                                    <div className='d-flex'>
                                        <h6 className="text-white m-0">Observability</h6>
                                    </div>
                                    <p className="text-white m-0">Unified view of Alerts, Events and Errors in your Cloud</p>
								</div>
                                <div className={`col-8 align-self-center pl-0`}>
                                    <div className={`col-md-12 d-flex flex-wrap justify-content-end bd-highlight`}>
                                        <div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: '150px' }}>
                                            <label className="mb-1">Provider</label>
                                            <Select
                                                placeholder={'Select'}
                                                isSearchable={false}
                                                // menuIsOpen={true}
                                                className='f13 p-0 colorBlack reactSelectFilterDrop'
                                                value={({
                                                    value: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : 'Select',
                                                    label: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : <span className="placeholder">Select</span>
                                                })}
                                                options={this.state.providers && this.state.providers.map(item => ({
                                                    value: item.provider_name,
                                                    label: item.provider_name,	
                                                }))}
                                                onChange={event => this.setState({
                                                    selectedProvider: event.value,
                                                    changesInFilter: true,
                                                })}
                                            />
                                        </div>
                                        <div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} ref={this.accountRef} style={{maxWidth: '150px' }}>
                                            <label className="mb-1">Account</label>
                                            <div className='dlpHeaderMultiSelection removeDropdownSearchBar'>
                                                <ReactMultiSelectCheckboxes						
                                                    placeholderButtonLabel="All"
                                                    menuIsOpen ={this.state.isAccountOpen}
                                                    getDropdownButtonLabel={() => this.getMultiSelectedCount('account', this.state.selectedAccount)}
                                                    onChange={arr => {
                                                    this.handleMultiSelectChange('selectedAccount', arr ? arr : []) }}
                                                    options={this.state.accounts && this.state.accounts.map(account => ({
                                                        value: account.account_id,
                                                        label: account.account_name,
                                                    }))}
                                                    value={this.state.selectedAccount.map(comp => ({
                                                        value: comp,
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                        <div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} ref={this.regionRef} style={{maxWidth: '150px' }}>
                                            <label className="mb-1">Region</label>
                                            <div className='dlpHeaderMultiSelection removeDropdownSearchBar'>
                                                <ReactMultiSelectCheckboxes
                                                    placeholderButtonLabel="Select"
                                                    menuIsOpen ={this.state.isRegionOpen}
                                                    getDropdownButtonLabel={() => this.getMultiSelectedCount('region', this.state.selectedRegion)}
                                                    onChange={arr => this.handleMultiSelectChange('selectedRegion', arr ? arr : []) }
                                                    options={this.state.regions && this.state.regions.map(reg => ({
                                                        value: reg.region,
                                                        label: reg.name,
                                                    }))}
                                                    value={this.state.selectedRegion.map(comp => ({
                                                        value: comp,
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                        <div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zindex1`} style={{maxWidth: '150px'}}>
                                            <label className="mb-1">Duration</label>
                                            <Select
                                                placeholder={'Select'}
                                                isSearchable={false}
                                                // menuIsOpen={true}
                                                className='f13 p-0 colorBlack reactSelectFilterDrop'
                                                value={({
                                                    value: this.state.selectedDuration && this.state.selectedDuration !== "" ? this.state.selectedDuration : 'Select',
                                                    label: this.state.selectedDuration && this.state.selectedDuration !== "" ? durationOptions.filter(e => e.value === this.state.selectedDuration)[0].option : <span className="placeholder">Select</span>
                                                })}
                                                options={durationOptions && durationOptions.map(item => ({
                                                    value: item.value,
                                                    label: item.option,	
                                                }))}
                                                onChange={event => 
                                                    this.setState({
                                                        selectedDuration: event.value,
                                                        changesInFilter: true
                                                    },
                                                        () => {
                                                            this.durationFunction()
                                                        }
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className={`ml-3 align-self-center`}>
                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                <button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-right px-2 py-1`} onClick={() => this.setState({ showLoading: false }, () => this.onSearch() )}>Search</button>
                                                <button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-left px-2 py-1`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className='p-2'>
                            <div class="bg-dark">
                                <div className="d-flex">
                                    <div className="col-sm-1 p-0 border-right-info overflowYAuto" style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
                                        <div class="bg-dark3 w-100" ref={this.heightRef}>
                                            {this.state.resourcesList && this.state.resourcesList.length ?
                                                this.state.resourcesList.map((reslist, i) => {
                                                    return (
                                                    <div className={`px-2 cursorPointer ${i ? "border-top" : ""} ${this.state.selectedResources === reslist.resource_type ? 'text-info disabled' : ''}`} onClick={() => this.getResourcesAlerts(reslist)}>
                                                        <div className="hiddenEllipses d-flex" id={"resource_type_"+i}>
                                                            <p className={`m-0 f-20 pl pr-1 pt-2 pb-2 ${this.state.selectedResources === reslist.resource_type ? 'text-info' : 'text-white'}`}>{reslist.resource_type + (reslist.total ? " ("+reslist.total+") " : "")}</p>
                                                        </div>
                                                        <UncontrolledTooltip placement='top' target={"resource_type_"+i}>
                                                            <div className="text-left">  
                                                                <p className="mb-1 text-primary-color"><span className="text-white mr-1">Asset: </span>{reslist.resource_type + (reslist.total ? " ("+reslist.total+") " : "")}</p>
                                                                {reslist.data && Object.entries(reslist.data).length ? 
                                                                    Object.entries(reslist.data).map(([key, value]) => {
                                                                        return(
                                                                            <p className="mb-1 text-primary-color">{key+" : "+value}</p>
                                                                        )
                                                                    })
                                                                : null}
                                                            </div>    
                                                        </UncontrolledTooltip>
                                                    </div>
                                                    )
                                                })
                                            : null}
                                        </div>
                                    </div>
                                    <div className="col-sm-11 pl-0 overflowYAuto" style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}> 
                                        <div class="bg-dark w-100">
                                            <div className="row" id="scrollTop">
                                                <p className="mt-2 mb-1 col-sm-12"><span className="ml-2">Showing {this.state.assetsList && this.state.assetsList.length > this.state.endRecord ? this.state.endRecord : this.state.assetsList && this.state.assetsList.length} of total {this.state.assetsList && this.state.assetsList.length} asset(s) in <span className="text-info">{this.state.selectedResources}</span></span></p>
                                                <div className="col-sm-8">
                                                    <div className="bg-dark3">
                                                        <table className="table mb-0 tableLayout">
                                                            <thead className="text-white">
                                                                <tr style={{height: "55px"}}>
                                                                    <th className="bg-dark3 px-0" style={{width:"16%"}}>
                                                                        <SearchWithHiddenInput
                                                                            ID={'alert_search'}
                                                                            data={this.state.assetsList ? this.state.assetsList : this.state.alertsList}
                                                                            applyLiteDarkTags={true}
                                                                            searchClassName={'border-0'}
                                                                            searchIconColor={'text-gray3'}
                                                                            className="form-control-sm text-gray3 bg-muted border-0"
                                                                            hideInputSection={true}
                                                                            topClassName={'dark-search flexWrap-unset'}
                                                                            filteredData={(filteredAssetList, searchText) => {
                                                                                
                                                                                let totalPages = 1
                                                                                if(filteredAssetList && filteredAssetList.length > this.state.perPage) {
                                                                                    totalPages = Math.ceil(filteredAssetList.length / this.state.perPage)
                                                                                }
                                                                                
                                                                                this.setState({ filteredAssetList, startRecord: 0, endRecord: filteredAssetList && filteredAssetList.length },
                                                                                    () => {
                                                                                        this.getAssetIdForMetrics()
                                                                                    }    
                                                                                )
                                                                            }}
                                                                        />
                                                                        {/* <Search
                                                                            data={this.state.assetsList ? this.state.assetsList : []}
                                                                            applyTags={false}
                                                                            applyLiteDarkTags={true}
                                                                            topClassName={'bg-black5 mr-1 border-gray5 rounded-5 f12 align-self-center'}
                                                                            searchClassName={'px-2 f12'}
                                                                            searchIconColor={'text-gray5 f12'}
                                                                            searchPlaceHolder={'Search assets....'}
                                                                            className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                                                            filteredData={(filteredAssetList) => {
                                                                                
                                                                                let totalPages = 1
                                                                                if(filteredAssetList && filteredAssetList.length > this.state.perPage) {
                                                                                    totalPages = Math.ceil(filteredAssetList.length / this.state.perPage)
                                                                                }

                                                                                // this.setState({ filteredAssetList, totalPages, currentPage: 1, startRecord: 0 },
                                                                                this.setState({ filteredAssetList, startRecord: 0, endRecord: filteredAssetList && filteredAssetList.length },
                                                                                    () => {
                                                                                        this.getAssetIdForMetrics()
                                                                                    }    
                                                                                )
                                                                            }}
                                                                        /> */}
                                                                    </th>
                                                                    {this.state.coreGraphs && this.state.coreGraphs.length ?
                                                                        this.state.coreGraphs.map(item => {
                                                                            return(
                                                                                <th className="text-center">{item === "cpu" ? "CPU" : capitalizeFirstLetter(item)}</th>
                                                                            )
                                                                        })
                                                                    : null}
                                                                </tr>
                                                            </thead>
                                                            <tbody className="text-primary-color">
                                                                {this.state.loadingMetrics ?
                                                                    <tr className='d-flex justify-content-center m-4'>
                                                                        <Spinner className='text-center' color='white' size='lg' />
                                                                    </tr>
                                                                :
                                                                    this.state.filteredAssetList && this.state.filteredAssetList.length ? 
                                                                        this.state.filteredAssetList.slice(this.state.startRecord, this.state.endRecord).map((ast, index) => {
                                                                        // this.state.filteredAssetList.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map((ast, index) => {
                                                                            return(
                                                                                <tr style={{height: "64.5px"}} key={index} className={`${!index ? "" : "border-top"}`}>
                                                                                    <td className="align-middle bg-dark3 cursorPointer" id={"asset_name_"+index} onClick={() => this.redirectToDetailPage(ast)}>
                                                                                        <div class="small hiddenEllipses text-white" style={{width:"150px"}}>{ast.asset_name}</div>
                                                                                        <div class="small hiddenEllipses" style={{width:"150px"}}>
                                                                                        {/* <tr className="small"> */}
                                                                                            {ast.provider ? ast.provider.toUpperCase() : <span>&nbsp;</span>}
                                                                                            {ast.account_id ? (' : ')+getAccountNameFromId(ast.account_id, this.props.accounts) : <span>&nbsp;</span>}
                                                                                            {ast.region ?  (' : ')+getRegionName(ast.region, this.state.regions) : <span>&nbsp;</span>}
                                                                                            {ast.resource_type ?  ('  ')+ast.resource_type : <span>&nbsp;</span>}
                                                                                            {ast.service_name ?  (' : ')+ast.service_name : <span>&nbsp;</span>}
                                                                                        </div>
                                                                                        <UncontrolledTooltip placement='top' target={"asset_name_"+index}>
                                                                                            <div className="">
                                                                                                <p className="mb-1">{ast.asset_name}</p>
                                                                                                <p className="mb-1">
                                                                                                    {ast.provider ? ast.provider.toUpperCase() : <span>&nbsp;</span>}
                                                                                                    {ast.account_id ? (' : ')+getAccountNameFromId(ast.account_id, this.props.accounts) : <span>&nbsp;</span>}
                                                                                                    {ast.region ?  (' : ')+getRegionName(ast.region, this.state.regions) : <span>&nbsp;</span>}
                                                                                                </p>
                                                                                                <p className="mb-1">
                                                                                                    {ast.resource_type ?  ('  ')+ast.resource_type : <span>&nbsp;</span>}
                                                                                                    {ast.service_name ?  (' : ')+ast.service_name : <span>&nbsp;</span>}
                                                                                                </p>
                                                                                                
                                                                                            </div>    
                                                                                        </UncontrolledTooltip>
                                                                                    </td>
                                                                                    {this.state.coreGraphs && this.state.coreGraphs.length ?
                                                                                        this.state.coreGraphs.map(item => {
                                                                                            return(
                                                                                                <td className="">
                                                                                                {item !== "network" ?
                                                                                                    <div className="d-flex justify-content-between">
                                                                                                        <div className="w-75">
                                                                                                            <ApexListBarChart 
                                                                                                                graphData={this.state["core_metrics_"+item+"_"+ast.asset_id]}
                                                                                                                name={'Line'}
                                                                                                                xaxis={false}
                                                                                                                xaxisFormat={'datetime'}
                                                                                                                yaxis={false}
                                                                                                                yaxisLabel={false}
                                                                                                                markerSize={0}
                                                                                                                hideMarkerLength={30}
                                                                                                                gradient={false}
                                                                                                                gradientColor={['#039BE5', '#039BE5']}
                                                                                                                colors={['#039BE5']}
                                                                                                                sparkline={true}
                                                                                                                zoom={false}
                                                                                                                height={34}
                                                                                                                width={'100%'}
                                                                                                                grid={false}
                                                                                                                className={"transparentTooltip mb-n2"}
                                                                                                                tooltip={true}
                                                                                                                stacked={false}
                                                                                                                animations={false}
                                                                                                            />
                                                                                                        </div>
                                                                                                        {this.state["core_metrics_"+item+"_"+ast.asset_id] && Object.entries(this.state["core_metrics_"+item+"_"+ast.asset_id]).length ?
                                                                                                            <div className="w-25 pl-1 pt-1 mb-0 align-self-center f11">
                                                                                                                <p className="d-flex mb-2 text-primary-color f8">Avg <span className="pl-1 text-primary-color">{this.state["statistics_"+item+"_"+ast.asset_id] && this.state["statistics_"+item+"_"+ast.asset_id].avg && this.state["statistics_"+item+"_"+ast.asset_id].avg}</span></p>
                                                                                                                <p className="d-flex mb-0 text-primary-color f8">Max <span className="pl-1 text-primary-color">{this.state["statistics_"+item+"_"+ast.asset_id] && this.state["statistics_"+item+"_"+ast.asset_id].max && this.state["statistics_"+item+"_"+ast.asset_id].max}</span></p>
                                                                                                            </div>
                                                                                                        : null}
                                                                                                    </div>
                                                                                                : 
                                                                                                    <div className="d-flex justify-content-between">
                                                                                                        <div className="w-75">
                                                                                                            <ApexListLineChart 
                                                                                                                graphData={this.state["core_metrics_"+item+"_"+ast.asset_id]}
                                                                                                                name={'Line'}
                                                                                                                xaxis={false}
                                                                                                                xaxisFormat={'datetime'}
                                                                                                                yaxis={false}
                                                                                                                yaxisLabel={false}
                                                                                                                markerSize={0}
                                                                                                                hideMarkerLength={30}
                                                                                                                gradient={false}
                                                                                                                gradientColor={['#039BE5', '#039BE5']}
                                                                                                                colors={['#775BA2', '#039BE5']}
                                                                                                                sparkline={true}
                                                                                                                zoom={false}
                                                                                                                height={34}
                                                                                                                width={'100%'}
                                                                                                                grid={false}
                                                                                                                className={"transparentTooltip metricsDetailCurrentTooltipAlignment"}
                                                                                                                tooltip={true}
                                                                                                                stacked={true}
                                                                                                            />
                                                                                                        </div>
                                                                                                        {this.state["statistics_"+item+"_"+ast.asset_id] && this.state["statistics_"+item+"_"+ast.asset_id].length ?
                                                                                                            <div className="w-25 pl-1 pt-1 mb-0 align-self-center f11">
                                                                                                                {this.state["statistics_"+item+"_"+ast.asset_id].map((sat, k) => {
                                                                                                                    return(
                                                                                                                        <p className={`d-flex ${!k ? "mb-2" : "mb-0"} text-color-primary f8`}>Avg <span className="ml-1 text-primary-color">{sat}</span></p>
                                                                                                                    )
                                                                                                                })}
                                                                                                            </div>
                                                                                                        : null}
                                                                                                    </div>
                                                                                                }
                                                                                            </td>
                                                                                            )
                                                                                        })
                                                                                    : null}
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    : 
                                                                        <tr key={-1}>
                                                                            <td colSpan={6} className="text-center">No Records</td>
                                                                        </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 pl-0">
                                                    <div className="bg-dark3">
                                                        <div className="table-responsive">
                                                            <table className="table mb-0">
                                                                <thead className="text-white">
                                                                    <tr style={{height: "55px"}}>
                                                                        <th>Changes</th>
                                                                        <th>Alerts</th>
                                                                        <th>Anomalies</th>
                                                                        <th>Errors</th>
                                                                        <th>Checks</th>
                                                                        <th>Issues</th>
                                                                        <th>Notifications</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="text-primary-color">
                                                                    {this.state.loadingMetrics ?
                                                                        <tr className='d-flex justify-content-center m-4'>
                                                                            <Spinner className='text-center' color='white' size='lg' />
                                                                        </tr>
                                                                    :
                                                                        this.state.filteredAssetList && this.state.filteredAssetList.length ?
                                                                            this.state.filteredAssetList.slice(this.state.startRecord, this.state.endRecord).map((dat ,j) => {
                                                                                return(
                                                                                    <tr style={{height: "64.5px"}} key={j} className={`${!j ? "" : "border-top"}`}>
                                                                                        <td className="align-middle text-center bg-dark3 cursorPointer">
                                                                                            {this.state.changesResponse && this.state.changesResponse.length && this.state.changesResponse.filter(e => e.service_name === dat.service_name).length && this.state.changesResponse.filter(e => e.service_name === dat.service_name)[0].total_count ?
                                                                                                this.state.changesResponse.filter(e => e.service_name === dat.service_name)[0].total_count
                                                                                            : 
                                                                                            <span className="text-black4">-</span>
                                                                                            }
                                                                                        </td>
                                                                                        <td className="align-middle text-center bg-dark3 cursorPointer">{dat.alerts ? dat.alerts : <span className="text-black4">-</span>}</td>
                                                                                        <td className="align-middle text-center bg-dark3 cursorPointer">{dat.anomalies ? dat.anomalies : <span className="text-black4">-</span>}</td>
                                                                                        <td className="align-middle text-center bg-dark3 cursorPointer">{dat.errors ? dat.errors : <span className="text-black4">-</span>}</td>
                                                                                        <td className="align-middle text-center bg-dark3 cursorPointer">{dat.checks ? dat.checks : <span className="text-black4">-</span>}</td>
                                                                                        <td className="align-middle text-center bg-dark3 cursorPointer">{dat.issues ? dat.issues : <span className="text-black4">-</span>}</td>
                                                                                        <td className="align-middle text-center bg-dark3 cursorPointer">{dat.notifications ? dat.notifications : <span className="text-black4">-</span>}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        : 
                                                                            <tr key={-1}>
                                                                                <td colSpan={7} className="text-center">No Records</td>
                                                                            </tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div> 
                                                {this.state.showLodeMore ?
                                                    <div className="col-sm-12 text-center cursorPointer my-1 text-info" onClick={() => this.nextSetOfRecord()}> Load More<i className="fas fa-angle-double-down ml-1"></i></div>
                                                : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
LandingPage.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('observability landing page',state)
	return {
        accounts: state.filters.accounts,
		regions: state.filters.regions,
        observabilityAssetPageFilter: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.observabilityAssetPageFilter ? state.aiops.aiopsPropsDetails.observabilityAssetPageFilter : {},
        obs_asset_core_metrics: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.obs_asset_core_metrics ? state.aiops.aiopsPropsDetails.obs_asset_core_metrics : [],
    }
}

export default AppWrapper(LandingPage, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
    setAiopsPropsDetails,
    getCoveredServices,
    listCoveredAssets,
    getChangeEvents,
    getMonitoringMetricsByTrend
})