import React, { Component } from 'react'
import { Spinner } from 'reactstrap'
import _ from 'lodash'
import Search from '../../common/SearchComponent'
import PropTypes from 'prop-types'
import { AppWrapper } from '../../common/AppWrapper'
import {
	listAllConfigurations,
	insertConfiguration,
	deleteConfiguration,
	updateConfiguration,
} from '../../../actions/governance/governanceAction'
import { listAllProviders, listAllServices, listAllRegions, listAllAccounts } from '../../../actions/commonAction'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'
import Select from 'react-select'
import { getRegionName, momentConvertionUtcToLocalTime } from '../../../utils/utility'

import CreateEditConfiguration from './CreateEditConfiguration'

class GovernanceConfiguration extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showLoading: false,
			selectedTableRow: {},
			filteredArray: [],
			providers: [],
			services: [],
			regions: [],
			accounts: [],
			selectedProvider: 'All',
			selectedRegion: '',
			selectedService: '',
		}
	}

	componentDidMount = () => {
		this.props.listAllProviders((promise, providers) => {
			if (promise) {
				if (providers.length) {
					let dataRow = {}
					dataRow.provider_id = "All"
					dataRow.provider_name = "All"
					providers.unshift(dataRow);
				}
				this.setState({ providers })
			}
		})

		this.onSearch("onLoad")
	}

	getFilterDetails = () => {
		let params = {
			"provider": this.state.selectedProvider.toLowerCase()
		}

		this.props.listAllRegions(params, (promise, regions) => {
			if (promise) {
				this.setState({ regions: regions[0].provider_regions })
			}
		})

		this.props.listAllServices(params, (promise, services) => {
			if (promise) {
				this.setState({ services }, 
					() => this.formSericeSelect()	
				)
			}
		})
	}

	onSearch = (type) => {
		if(this.state.changesInFilter || type === "onLoad") {
            this.setState({ 
                changesInFilter: false,
                showLoading: true,
                resetEnabled: type === 'onLoad' ? false : true
            },
                () => {
					this.listAllConfigurations()
                }
            )
        }
	}
	
	onReset = () => {
		this.setState({ 
            selectedProvider: "All",
            selectedRegion: "",
			selectedService: "",
            changesInFilter: this.state.resetEnabled,
        },
			() => this.onSearch()
        )
	}

	listAllConfigurations = () => {
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'All') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if (this.state.selectedService !== '' && this.state.selectedService !== 'all') {
			params.service_name = this.state.selectedService
		}
		
		this.props.listAllConfigurations(params, (promise, response) => {
			if (promise) {
				this.setState({ showLoading: false, selectedTableRow: this.props.configurations[0], filteredArray: this.props.configurations })
			} else {
				this.setState({ showLoading: false })
			}
		})
	}
	
	/**
	 * Filter identifiers based on the filter selection
	 */
	filterIdentifiers = (from, boolean) => {
		this.setState({ filteredArray: [] })
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'All') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if (this.state.selectedService !== '' && this.state.selectedService !== 'all') {
			params.service_name = this.state.selectedService
		}
		if (this.state.selectedRegion !== '' && this.state.selectedRegion !== 'all') {
			params.service_name = this.state.selectedRegion
		}
		if (from === 'provider') {
			this.props.listAllServices(params, (promise, services) => {
				if (promise) {
					this.setState({ services }, 
						() => this.formSericeSelect()	
					)
				}
			})
			this.props.listAllRegions(params, (promise, regions) => {
				if (promise) {
					this.setState({ regions: regions[0].provider_regions })
				}
			})
		}

		if (!boolean) {
			this.setState({ showLoading: true })
			this.props.listAllConfigurations(params, identifiersArray => {
				if (identifiersArray) {
					this.setState({ showLoading: false, selectedTableRow: this.props.configurations[0] })
				}
				this.setState({ showLoading: false })
			})
		}
	}
	/**
	 * Add new rule form
	 */
	addNewRuleTags = () => {
		const temp = this.state.newRuleTags
		temp.push({
			key: '',
			value: '',
		})
		this.setState({ newRuleTags: temp })
	}
	/**
	 * Delete new rule form
	 */
	deleteNewRuleTags = index => {
		if (this.state.newRuleTags.length === 1) {
			this.setState({ tagsButtonClicked: false })
			const temp = this.state.newRuleTags
			temp.splice(index, 1)
			temp.push({ key: '', value: '' })
			this.setState({ newRuleTags: temp })
		} else {
			const temp = this.state.newRuleTags
			temp.splice(index, 1)
			this.setState({ newRuleTags: temp })
		}
	}

	onClickDelete = item => {
		this.props.deleteConfiguration({ configuration_id: item.configuration_id }, () => {})
	}

	formSericeSelect = () => {
		let data = []
		if(this.state.services && this.state.services.length) {
			_.chain(this.state.services)
				.groupBy('resource_type')
				.map((value, key) => ({ label: key, sub: value }))
				.value()
				.forEach((service, i) => {
					let child = []
					service.sub.forEach((item, i) => {
						let childRow = {}
						childRow.value = service.service_name
						childRow.label = item.service_name
						child.push(childRow)
					})
					let dataRow = {}
					dataRow.label = service.label
					dataRow.options = child

					data.push(dataRow)
				})
		}

		this.setState({ servicesOptGrop: data })
	}

	render() {
		const { showLoading, regions } = this.state
		return (
			<div className="container-fluid overflow-auto flex-grow-1 bg-muted">
				{this.state.showCreateEditConfiguration ?
					<CreateEditConfiguration 
						closePanel={() => this.setState({ showCreateEditConfiguration: false })}
						callListApi={() => this.setState({ showCreateEditConfiguration: false }, () => this.listAllConfigurations())}
						actionType={this.state.actionType}
						selectedConfiguration = {this.state.selectedConfiguration}
					/>
				: null}
				<div className="row h-100">
					<div
						className={
							this.props.configurations &&
							this.props.configurations.length &&
							Object.entries(this.state.selectedTableRow).length !== 0
								? 'col-sm-9 p-0'
								: 'col-sm-12 p-0'
						}
					>
						<div className='title-section p-3'>
							<div className='row'>
								<div className="col-4 align-self-center">
									<h6 className="text-white m-0">Governance Configuration</h6>
									<p className="text-white m-0">
										Manage user created configurations on your Cloud
									</p>
								</div>
								<div className="col-8 align-self-center">
									<div className={`col-md-12 d-flex flex-wrap justify-content-end ${this.state.showLoading ? "disabled" : ""}`}>
										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: "150px" }}>
											<label className="mb-1">Provider</label>
											<Select
												placeholder={"All"}
												isSearchable={false}
												// menuIsOpen={true}
												//onFocus={this.openProivderMenu}
												className="f13 p-0 colorBlack reactSelectFilterDrop"
												value={({
													value: this.state.selectedProvider,
													label: this.state.selectedProvider ? this.state.selectedProvider : <span className="placeholder">Select</span>
												})}
												options={this.state.providers && this.state.providers.map(item => ({
													value: item.provider_name,
													label: item.provider_name,	
												}))}
												onChange={event => this.setState({ selectedProvider: event.value, changesInFilter: true }, 
													() => {
														this.getFilterDetails()
													}
												)}
											/>
										</div>
										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: "150px" }}>
											<label className="mb-1">Region</label>
											<Select
												placeholder={"All"}
												isSearchable={false}
												// menuIsOpen={true}
												//onFocus={this.openProivderMenu}
												className="f13 p-0 colorBlack reactSelectFilterDrop"
												value={({
													value: this.state.selectedRegion,
													label: this.state.selectedRegion ? getRegionName(this.state.selectedRegion, regions) : <span className="placeholder">Select</span>
												})}
												options={regions && regions.map(item => ({
													value: item.region,
													label: item.name,	
												}))}
												onChange={event => this.setState({ selectedRegion: event.value, changesInFilter: true })}
											/>
										</div>
										
										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: "300px" }}>
											<label className="mb-1">Services</label>
											<Select
												placeholder={"Select"}
												// isSearchable={false}
												// menuIsOpen={true}
												//onFocus={this.openProivderMenu}
												className="f13 p-0 colorBlack reactSelectOptGroupFilterDrop"
												// label={({
												// 	label: this.state.selectedService,
												// 	// label: this.state.selectedService ? getRegionName(this.state.selectedService, regions) : <span className="placeholder">Select</span>
												// })}
												options={this.state.servicesOptGrop && this.state.servicesOptGrop}
												onChange={event => this.setState({ selectedService: event.label, changesInFilter: true })}
											/>
										</div>
										<div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 ${this.state.services.length ? '' : 'disabled disabledDarkBg'} displayNone`} style={{maxWidth: "150px" }}>
											<label>Service</label>
											<select
												className='form-control'
												onChange={event => {
													this.setState({ selectedService: event.target.value, changesInFilter: true })
												}}
											>
												<option value='all'>All</option>
												{this.state.services && this.state.services.length ?
													_.chain(this.state.services)
														.groupBy('resource_type')
														.map((value, key) => ({ label: key, sub: value }))
														.value()
														.map((service, i) => {
															return (
																<optgroup key={i} label={service.label}>
																	{service.sub.map((item, i) => {
																		return (
																			<option key={i} value={service.service_name}>
																				{item.service_name}
																			</option>
																		)
																	})}
																</optgroup>
															)
														})
												: null}
											</select>
										</div>
										<div className="mt-2 d-flex align-self-center">
											<div className="btn-group" role="group" aria-label="governance-dash">
												<button type="button" className={`${this.state.showLoading ? "disabled" : "cursorPointer"} btn btn-secondary border-right`} onClick={()=> this.onSearch()}>Search</button>
												<button type="button" className={`${this.state.showLoading ? "disabled" : "cursorPointer"} btn btn-secondary border-left`} onClick={()=> this.onReset()}><i className="far fa-redo"></i></button>
											</div>
											<button className='ml-3 btn btn-primary btn-sm' onClick={() => this.setState({ actionType: "New", showCreateEditConfiguration: true })}>
												Create Rule
											</button>
										</div>
									</div>
								</div>
							</div>
									
								{/* </div>
								<div className='col-sm-4 align-self-center'>
									<Search
										data={this.props.configurations && this.props.configurations}
										filteredData={filteredArray => this.setState({ filteredArray, selectedTableRow: filteredArray[0] })}
									/>
								</div>
							</div> */}
						</div>
						<div className="container-fluid bg-dark m-2 rounded h-100">
							<div className="d-flex justify-content-between py-2">
								<p className="mb-0 align-self-center">Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.props.configurations && this.props.configurations.length} Rules</p>
							
								<div className={`mb-n2 ${this.state.showLoading ? "disabled" : ""}`}>
									<Search
										data={this.props.configurations ? this.props.configurations : []}
										filteredData={filteredArray => this.setState({ filteredArray, selectedTableRow: filteredArray.length ? filteredArray[0] : {} })}
									/>
								</div>
							</div>
							<div className='tab-content' id='myTabContent'>
								<div className='tab-pane fade show active' id='home' role='tabpanel' aria-labelledby='policies-tab'>
									{this.props.configurations && this.props.configurations.length && !showLoading ? (
										<ResizeableDarkThemeTable
											columns={[
												{
													Header: 'Module Name',
													accessor: 'module_name',
													width: 300
												},
												{
													Header: 'Detector Id',
													accessor: 'module_id',
													width: 300
												},
												{
													Header: 'Name',
													accessor: 'key_name',
													width: 300
												},
												{
													Header: 'Value',
													accessor: 'key_value',
													width: 300
												},
												{
													Header: 'Action',
													Cell: cellInfo => (
														<div className="d-flex align-self-center">
															<i className="fal fa-user-edit cursorPointer mr-2" onClick={() => this.setState({ showCreateEditConfiguration: true, selectedConfiguration: cellInfo.row.original, actionType: "Edit" })} />
															<i className="fal fa-trash cursorPointer" onClick={() => this.onClickDelete(cellInfo.row.original)}></i>
														</div>
													),
													width: 150
												},
											]}
											
											data={this.state.filteredArray.length ? this.state.filteredArray : []}
											perPage={20}
											dashboard={(this.props.filteredArray ? this.props.filteredArray.length : this.props.configurations.length) ? true : false}
											sortByColumn={"module_name"}
											riskTooltip={[]}
											onClickRow={tableRow => this.setState({ selectedTableRow: tableRow })}
											// tableSize={"table-sm"}
											// tableHead={"bg-dark"}
											tooltipFor={[3]}
											manualWidth={true}
											
										/>
									) : !showLoading && this.props.configurations && !this.props.configurations.length ? (
										<div className='d-flex justify-content-center m-4 '>
											<p>No data found</p>
										</div>
									) : null}
									{showLoading ? (
										<div className='d-flex justify-content-center m-4'>
											<Spinner className='text-center' color='white' size='lg' />
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>
					<div className={this.props.configurations && this.props.configurations.length ? 'col-sm-3 p-0' : ''}>
						<div
							className={`bg-dark3
								${this.props.configurations &&
								this.props.configurations.length &&
								Object.entries(this.state.selectedTableRow).length !== 0 ? 'detail' : 'd-none'}
							`}
						>
							<div className="bg-dark d-flex">
								<h6 className='pl-3 py-2 m-0 text-white'>{this.state.selectedTableRow && this.state.selectedTableRow.module_id}</h6>
								<h6 className='pl-2 py-2 m-0 text-white'>{this.state.selectedTableRow && this.state.selectedTableRow.module_name}</h6>
							</div>
							<div className="bg-dark m-3 p-3 rounded">
								<div className="pb-2 mb-2 border-bottom">
									<p className="b-block mb-2">Value :</p>
									<p className="mb-0 text-white">{this.state.selectedTableRow && this.state.selectedTableRow.key_value}</p>
								</div>
								<div className="pb-2 mb-2">
									<p className="b-block mb-2">Created at :</p>
									<p className="mb-0 text-white">
										{this.state.selectedTableRow && this.state.selectedTableRow.created_at ? momentConvertionUtcToLocalTime(this.state.selectedTableRow.created_at, "DD MMM YYYY HH:mm") : ""}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
GovernanceConfiguration.propTypes = {
	listAllConfigurations: PropTypes.func,
	listAllProviders: PropTypes.func,
	listAllServices: PropTypes.func,
	listAllRegions: PropTypes.func,
	listAllAccounts: PropTypes.func,
	deleteConfiguration: PropTypes.func,
	configurations: PropTypes.array,
	insertConfiguration: PropTypes.func,
	updateConfiguration: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		configurations: state.governance.configurations,
	}
}

export default AppWrapper(GovernanceConfiguration, mapStateToProps, {
	listAllConfigurations,
	listAllProviders,
	listAllServices,
	listAllRegions,
	listAllAccounts,
	updateConfiguration,
	insertConfiguration,
	deleteConfiguration,
})
