/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Metrics.js
 * @author Prakash // on 22/02/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'  
// import { Spinner } from 'reactstrap'
import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'
import { listAssets } from '../../../actions/detectors/detectorsAction'
import { getMonitoringMetricsByTrend } from '../../../actions/aiops/MetricsAction'
import { getSreDetails } from '../../../actions/aiops/DiagnosticsAction'
import { capitalizeFirstLetter, momentConvertionUtcToLocalTime } from '../../../utils/utility'

import AlertMetricList from './AlertMetricList'
import AlertMetricDetails from './AlertMetricDetails'

class AlertRightSection extends Component {
    
    constructor(props) {
        super(props)
        this.scrolltoTop = React.createRef()

        this.state = {
            showMetricsDetails: this.props.showMetricsDetails ? true : false,
            showFilerSection: true,
            alertDetails: {},

            caseId: this.props.caseId,
            sliderStartDate: '',
            sliderEndDate: '',
            showMetrics: false,

        }
    }

    componentDidMount = (prevProps) => {
        this.props.setAiopsPropsDetails('showMetricsList', true)
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.aiopsPropsDetails && Object.entries(this.props.aiopsPropsDetails).length) {
            if(prevProps.alertPageDetails)  {
                if(this.props.alertPageDetails.group_id && this.props.alertPageDetails.group_id !== prevProps.alertPageDetails.group_id) {
                    this.setState({ showMetricsDetails: false, alertDetails: this.props.alertPageDetails },
                        () => {
                            // this.scrolltoTop.current.scrollIntoView({ block: "start", behavior: "smooth" })
                            this.scrolltoTop.current.scrollIntoView()
                            if(!this.props.alertAssetDetails.hasOwnProperty(this.props.alertPageDetails.asset_name)) {
                                this.listAssets()    
                            } else {
                                this.setState({ assetDetails: this.props.alertAssetDetails.hasOwnProperty(this.props.alertPageDetails.asset_name) })
                            }
                            if(!this.props.alertMonitoringList.hasOwnProperty(this.props.alertPageDetails.asset_name)) {
                                this.setState({ showMetrics: false }, () => this.getMonitoringMetricsByTrend() )
                            } else {
                                if(this.props.alertPageDetails.event_type) {                                    
                                    let metrics = this.props.alertMonitoringList[this.props.alertPageDetails.asset_name]
                                    let checkAdditionMetricsAvailable = metrics[0].data.hasOwnProperty(this.props.alertPageDetails.event_type)
                                    if(!checkAdditionMetricsAvailable) {
                                        this.setState({ showMetrics: false, alertMetricListState: this.props.alertMonitoringList }, 
                                            () => {
                                                this.props.setAiopsPropsDetails('alertMonitoringList', {})
                                                this.getMonitoringMetricsByTrend() 
                                            }
                                        )
                                    }
                                }
                            }
                            // if(this.props.alertPageDetails.resource_type !== 'EC2')  {
                            //     if(!this.props.alertSreMetricsList.hasOwnProperty(this.props.alertPageDetails.asset_name)) {
                            //         this.getSreDetails()
                            //     }
                            // }
                        }
                    )
                } else if(this.props.alertPageDetails && !this.props.alertPageDetails.group_id && !this.state.visited) {
                    this.setState({ assetDetails: {}, visited: true })
                }
            }
        }
    }

    getMonitoringMetricsByTrend = () => {
        let params = {}
        params.provider = this.props.caseDetails && this.props.caseDetails.provider ? (Array.isArray(this.props.caseDetails.provider) ? this.props.caseDetails.provider[0] : this.props.caseDetails.provider) : 'aws'
        params.start_time = this.props.aiopsPropsDetails.alert_slider_changes && this.props.aiopsPropsDetails.alert_slider_changes.sliderStartDate
        params.end_time = this.props.aiopsPropsDetails.alert_slider_changes && this.props.aiopsPropsDetails.alert_slider_changes.sliderEndDate
        params.anomaly = true
        params.case_id = this.state.caseId
        if(this.props.alertPageDetails.event_type) {
            params.additional_metrics = [this.props.alertPageDetails.event_type]
        }
        let assetName = ''
        if(Array.isArray(this.props.alertPageDetails.asset_name)) {
            assetName = this.props.alertPageDetails.asset_name
            params.asset_name = this.props.alertPageDetails.asset_name
        } else {
            params.asset_name = [this.props.alertPageDetails.asset_name]
            assetName = this.props.alertPageDetails.asset_name
        }
         
        let topItems = 3
        if(this.props.alertPageDetails.resource_type === 'EC2') {
            topItems = 5
        }
        params.top = topItems
        params.sre_metric = false
        if(assetName && assetName !== null) {
            let fileredAsset = this.props.assetsList.filter(obj => obj.asset_name === params.asset_name[0])
            if(fileredAsset && fileredAsset.length) {
                if(Array.isArray(fileredAsset[0].service_name)) {
                    params.service_name = fileredAsset[0].service_name[0]
                } else {
                    params.service_name = fileredAsset[0].service_name
                }
            }
            this.props.getMonitoringMetricsByTrend(params, (promise, response) => {
                if(promise) {                    
                    if(this.state.alertMetricListState && this.state.alertMetricListState.hasOwnProperty(assetName) && this.props.alertPageDetails.event_type && this.props.alertPageDetails.event_type !== "") {
                        let obj = this.state.alertMetricListState
                        let assetData = obj[assetName][0]

                        //updating metrics for smae asset
                        let uppendData = assetData.data
                        uppendData[params.additional_metrics] = response[0].data[params.additional_metrics]
                        assetData.data = uppendData
                        //updating metrics for smae asset

                        //adding additional metrics to restrict based on the alert
                        let addMetrics = assetData.additional_metrics
                        assetData.additional_metrics = [...addMetrics, this.props.alertPageDetails.event_type]
                        //adding additional metrics to restrict based on the alert
                        
                        obj[assetName] = [assetData]
                        this.props.setAiopsPropsDetails('alertMonitoringList', obj)
                        this.setState({ showMetrics: true})
                    } else {
                        let obj = this.props.alertMonitoringList
                        if(this.props.alertPageDetails.event_type && this.props.alertPageDetails.event_type !== "") {
                            response[0].additional_metrics = [this.props.alertPageDetails.event_type]
                        } else {
                            response[0].additional_metrics = []
                        }
                        obj[assetName] = response
                        
                        this.props.setAiopsPropsDetails('alertMonitoringList', obj)
                        this.setState({ showMetrics: true})
                    }

                    // this.setState({ showMetrics: true})
                    // let obj = this.props.alertMonitoringList
                    // obj[assetName] = response
                    // this.props.setAiopsPropsDetails('alertMonitoringList', obj)
                }
            })
        }
    }

    getSreDetails = () => {
        let assetId = ''
        if(Array.isArray(this.props.alertPageDetails.asset_id)) {
            assetId = this.props.alertPageDetails.asset_id[0]
        } else {
            assetId = this.props.alertPageDetails.asset_id
        }

        let assetName = ''
        if(Array.isArray(this.props.alertPageDetails.asset_name)) {
            assetName = this.props.alertPageDetails.asset_name[0]
        } else {
            assetName = this.props.alertPageDetails.asset_name
        }

        let params = {}
        params.case_id = this.props.caseDetails.case_id
        params.start_time = this.props.aiopsPropsDetails.alert_slider_changes && this.props.aiopsPropsDetails.alert_slider_changes.sliderStartDate
        params.end_time = this.props.aiopsPropsDetails.alert_slider_changes && this.props.aiopsPropsDetails.alert_slider_changes.sliderEndDate
        params.asset_id = assetId

        if(assetId && assetId !== null && assetId !== '') {
            this.props.getSreDetails(params, (promise, response) => {
                if(promise) {
                    let obj = this.props.alertSreMetricsList
                    obj[assetName] = response
                    this.props.setAiopsPropsDetails('alertSreMetricsList', obj)
                }
            })
            
        }
    }

    getAlertDetails = () => {
        this.setState({ alertDetails: this.props.alertPageDetails })
    }

    listAssets = () => {        
        let assetDetail = this.props.assetsList.filter(obj => obj.asset_name === this.props.alertPageDetails.asset_name)     

        let asset_name = assetDetail && assetDetail.length && assetDetail[0].asset_name ? assetDetail[0].asset_name : this.props.alertPageDetails.asset_name
        let resource_type = assetDetail &&  assetDetail.length && assetDetail[0].resource_type ? assetDetail[0].resource_type: ''
        let region = assetDetail &&  assetDetail.length && assetDetail[0].region ? assetDetail[0].region : ''
        
        let params = {}
        params.asset_name = asset_name ? asset_name : this.props.alertPageDetails.asset_name
        params.resource_type = resource_type ? resource_type : this.props.alertPageDetails.resource_type
        params.region = region ? region : this.props.alertPageDetails.region
        
        if(params.resource_type !== '' && params.region !== '') {
            this.props.listAssets(params, (promise, response) => {
                if (promise) {
                    if(response.results && response.results.length) {
                        let obj = this.props.alertAssetDetails
                        obj[asset_name] = response.results
                        this.props.setAiopsPropsDetails('alertAssetDetails', obj)
                    }
                    this.setState({ assetDetails: response.results ? response.results : [] })
                } else {
                    this.setState({ assetDetails: [] })
                }
            })
        }
    }

    getMetricsDeatils = (assetId, assetName) => {
        if(Array.isArray(assetName)) {
            assetName = assetName[0]
        }

        if(Array.isArray(assetId)) {
            assetId = assetId[0]
        }
        this.setState({ selectedMetricAssetName: assetName, selectedMetricAssetId: assetId, showMetricsDetails: true },
            () => {
                this.scrolltoTop.current.scrollIntoView()
                this.props.setAiopsPropsDetails('showMetricsList', false)
            }   
        )
    }

    render() {
        return (
            <div className=""  ref={this.scrolltoTop}>
            {!this.state.showMetricsDetails ?
                <div className='row'>
                    <div className="col-sm-12 p-3">
                        {this.props.alertPageDetails && Object.entries(this.props.alertPageDetails).length ?
                            <div className="rounded bg-dark p-3">
                                {/* <p className="mb-0 f16 text-white"><b>Details</b></p> */}
                                <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
                                    {this.props.alertPageDetails.type === 'alert' ?
                                        <div>
                                            <p className="mb-0 f16 text-white">{this.props.alertPageDetails.event_name ? this.props.alertPageDetails.event_name : ''}</p>
                                            <p className="mb-0 f12">{this.props.alertPageDetails.description ? this.props.alertPageDetails.description : ''}</p>
                                        </div>
                                    : 
                                        <div>
                                            <div className="d-flex flex-wrap">
                                                <p className="mb-0 f16 text-white">{this.props.alertPageDetails.resource_type ? this.props.alertPageDetails.resource_type : ''}</p>
                                                <p className="mb-0 f16 text-white">{this.props.alertPageDetails.event_type ? '_'+this.props.alertPageDetails.event_type : ''}</p>
                                                <p className="mb-0 f16 text-white">{this.props.alertPageDetails.asset_name ? '_'+this.props.alertPageDetails.asset_name : ''}</p>
                                            </div>
                                            <p className="mb-0 f12">{this.props.alertPageDetails.event_type ? this.props.alertPageDetails.event_type : ''}</p>
                                        </div>
                                    }
                                    <div>
                                        <p className="b-block mb-0">Severity:</p>
                                        <span className={`mr-2 badge ${this.props.alertPageDetails.severity ? 'risk-badge-'+this.props.alertPageDetails.severity.toLowerCase() : 'badge-secondary' }`}>  
                                            {this.props.alertPageDetails.severity ? capitalizeFirstLetter(this.props.alertPageDetails.severity) : 'Unknown'}
                                        </span>
                                    </div>
                                </div>
                                {/* <div className='dropdown-divider-dark'></div> */}
                                
                                {this.props.alertPageDetails.type === 'group' ?
                                    <React.Fragment>
                                    <div className="d-flex mb-1 border-bottom">
                                        <div className="py-1 w-50">
                                            <div className="d-flex flex-wrap"> 
                                                <p className="b-block mb-0 mr-2 text-white">Severity</p>
                                                {/* <span className={`align-self-center mr-1 fa fa-circle risk-${item.severity.toLowerCase()}`}></span> */}
                                                <p className={`align-self-center mb-0 mr-2 badge ${this.props.alertPageDetails.first_event_severity ? 'risk-badge-'+this.props.alertPageDetails.first_event_severity.toLowerCase() : 'badge-secondary' }`}>  
                                                    {this.props.alertPageDetails.first_event_severity ? capitalizeFirstLetter(this.props.alertPageDetails.first_event_severity) : 'Unknown'}
                                                </p>
                                                <p className="b-block mb-0 mr-2 text-white">for the first alert observed on</p>
                                                <p className="mb-0 text-white">{this.props.alertPageDetails.first_event ? momentConvertionUtcToLocalTime(this.props.alertPageDetails.first_event, 'DD MMM YYYY HH:mm') : ''}</p>
                                            </div>
                                        </div>
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">Threshold / Statistic / Period:</p>
                                            <p className="mb-0 text-warning">
                                                {this.props.alertPageDetails.first_event_threshold && this.props.alertPageDetails.first_event_threshold}
                                                {this.props.alertPageDetails.unit ? (this.props.alertPageDetails.unit ? (this.props.alertPageDetails.unit.toLowerCase() === 'percent' ? ' %' : " "+this.props.alertPageDetails.unit) : '') : ''}
                                                {this.props.alertPageDetails.first_event_statistic ? (' / ')+this.props.alertPageDetails.first_event_statistic : ''}
                                                {this.props.alertPageDetails.first_event_period ? (' / ')+this.props.alertPageDetails.first_event_period : ''}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-1 border-bottom">
                                        <div className="py-1 w-50">
                                            <div className="d-flex flex-wrap"> 
                                                <p className="b-block mb-0 mr-2 text-white">Severity</p>
                                                {/* <span className={`align-self-center mr-1 fa fa-circle risk-${item.severity.toLowerCase()}`}></span> */}
                                                <p className={`align-self-center mb-0 mr-2 badge ${this.props.alertPageDetails.last_event_severity ? 'risk-badge-'+this.props.alertPageDetails.last_event_severity.toLowerCase() : 'badge-secondary' }`}>  
                                                    {this.props.alertPageDetails.last_event_severity ? capitalizeFirstLetter(this.props.alertPageDetails.last_event_severity) : 'Unknown'}
                                                </p>
                                                <p className="b-block mb-0 mr-2 text-white">for the last alert observed on</p>
                                                <p className="mb-0 text-white">{this.props.alertPageDetails.last_event ? momentConvertionUtcToLocalTime(this.props.alertPageDetails.last_event, 'DD MMM YYYY HH:mm') : ''}</p>
                                            </div>
                                        </div>
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">Threshold / Statistic / Period:</p>
                                            <p className="mb-0 text-warning">
                                                {this.props.alertPageDetails.last_event_threshold && this.props.alertPageDetails.last_event_threshold}
                                                {this.props.alertPageDetails.unit ? (this.props.alertPageDetails.unit ? (this.props.alertPageDetails.unit.toLowerCase() === 'percent' ? ' %' : " "+this.props.alertPageDetails.unit) : '') : ''}
                                                {this.props.alertPageDetails.last_event_statistic ? (' / ')+this.props.alertPageDetails.last_event_statistic : ''}
                                                {this.props.alertPageDetails.last_event_period ? (' / ')+this.props.alertPageDetails.last_event_period : ''}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-1 border-bottom">
                                        <div className="py-1 w-50">
                                            <div className="d-flex flex-wrap"> 
                                                <p className="b-block mb-0 mr-2 text-white">Severity</p>
                                                {/* <span className={`align-self-center mr-1 fa fa-circle risk-${item.severity.toLowerCase()}`}></span> */}
                                                <p className={`align-self-center mb-0 mr-2 badge ${this.props.alertPageDetails.highest_event_severity ? 'risk-badge-'+this.props.alertPageDetails.highest_event_severity.toLowerCase() : 'badge-secondary' }`}>  
                                                    {this.props.alertPageDetails.highest_event_severity ? capitalizeFirstLetter(this.props.alertPageDetails.highest_event_severity) : 'Unknown'}
                                                </p>
                                                <p className="b-block mb-0 mr-2 text-white">for the highest alert observed on</p>
                                                <p className="mb-0 text-white">{this.props.alertPageDetails.highest_event ? momentConvertionUtcToLocalTime(this.props.alertPageDetails.highest_event, 'DD MMM YYYY HH:mm') : ''}</p>
                                            </div>
                                        </div>
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">Threshold / Statistic / Period:</p>
                                            <p className="mb-0 text-warning">
                                                {this.props.alertPageDetails.highest_event_threshold && this.props.alertPageDetails.highest_event_threshold}
                                                {this.props.alertPageDetails.unit ? (this.props.alertPageDetails.unit ? (this.props.alertPageDetails.unit.toLowerCase() === 'percent' ? ' %' : " "+this.props.alertPageDetails.unit) : '') : ''}
                                                {this.props.alertPageDetails.highest_event_statistic ? (' / ')+this.props.alertPageDetails.highest_event_statistic : ''}
                                                {this.props.alertPageDetails.highest_event_period ? (' / ')+this.props.alertPageDetails.highest_event_period : ''}
                                            </p>
                                        </div>
                                    </div>
                                    </React.Fragment>

                                : null}
                                {this.props.alertPageDetails.type === 'alert' ?
                                    <div className="d-flex mb-1 border-bottom">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Alert Time:</p>
                                            <p className="mb-0 text-white">
                                                {this.props.alertPageDetails.type === 'alert' ?
                                                    this.props.alertPageDetails.event_time ? momentConvertionUtcToLocalTime(this.props.alertPageDetails.event_time, 'DD MMM YYYY HH:mm') : ''
                                                :
                                                    this.props.alertPageDetails.created_at ? momentConvertionUtcToLocalTime(this.props.alertPageDetails.created_at, 'DD MMM YYYY HH:mm') : ''
                                                }
                                            </p>
                                        </div>
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">Alert Metrics / Statistic / Period:</p>
                                            {this.props.alertPageDetails.type === 'alert' ?
                                                <p className="mb-0 text-white">
                                                    {this.props.alertPageDetails.event_source ? this.props.alertPageDetails.event_source : ''}
                                                    {this.props.alertPageDetails.event_type ? (' : ')+this.props.alertPageDetails.event_type : ''}
                                                    {this.props.alertPageDetails.statistic ? (' / ')+this.props.alertPageDetails.statistic : ''}
                                                    {this.props.alertPageDetails.period ? (' / ')+this.props.alertPageDetails.period : ''}
                                                </p>
                                            :        
                                                // <p className="mb-0 text-white">
                                                //     {this.props.alertPageDetails.event_type ? this.props.alertPageDetails.event_type : ''}
                                                // </p>
                                            null}
                                        </div>
                                    </div>
                                : null}
                                {this.props.alertPageDetails.type === 'alert' ?
                                    <div className="d-flex mb-1 border-bottom">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Threshold / Breached Value:</p>
                                            <p className="mb-0 text-warning">
                                                {this.props.alertPageDetails.threshold && this.props.alertPageDetails.threshold}{this.props.alertPageDetails.unit_symbol ? (this.props.alertPageDetails.unit_symbol ? (this.props.alertPageDetails.unit_symbol.toLowerCase() === 'percent' ? ' %' : " "+this.props.alertPageDetails.unit_symbol) : '') : ''}
                                                {this.props.alertPageDetails.value ? (' / ')+this.props.alertPageDetails.value : ''}
                                            </p>
                                        </div>
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">Provider / Account / Region :</p>
                                            <p className="mb-0 text-white">
                                                {this.props.alertPageDetails.provider ? this.props.alertPageDetails.provider.toUpperCase() : ''}
                                                {this.props.alertPageDetails.account_id ? (' / ')+this.props.alertPageDetails.account_id : ''}
                                                {this.props.alertPageDetails.region ? (' / ')+this.props.alertPageDetails.region : ''}
                                            </p>
                                        </div>
                                    </div>
                                : null}
                                {this.state.assetDetails && this.state.assetDetails.length ?
                                    <React.Fragment>
                                    <div className="d-flex mb-1 border-bottom">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Resource Id / Name:</p>
                                            <p className="mb-0 text-white">{this.state.assetDetails[0].asset_name ? this.state.assetDetails[0].asset_name : ''}</p>
                                        </div>
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">Service</p>
                                            <p className="mb-0 text-white">
                                                {this.state.assetDetails[0].resource_type ? this.state.assetDetails[0].resource_type : ''}
                                                {this.state.assetDetails[0].service_name ? (' : ')+this.state.assetDetails[0].service_name : ''}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Last Action:</p>
                                            <p className="mb-0 text-white">
                                                {this.state.assetDetails[0].action ?this.state.assetDetails[0].action : ''}
                                                {this.state.assetDetails[0].action_date ? 
                                                    (' / ')+ momentConvertionUtcToLocalTime(this.state.assetDetails[0].action_date, 'DD MMM YYYY HH:mm') 
                                                : this.state.assetDetails[0].asset_time ? 
                                                    (' / ')+ momentConvertionUtcToLocalTime(this.state.assetDetails[0].asset_time, 'DD MMM YYYY HH:mm') 
                                                : ''}
                                                {this.state.assetDetails[0].action_by ? (' / ')+ this.state.assetDetails[0].action_by : ''}
                                            </p>
                                        </div>
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">Category:</p>
                                            {this.state.assetDetails[0].category ? 
                                                this.state.assetDetails[0].category.map(item => {
                                                    return (
                                                    <small className={`badge badge-secondary mr-1`}>{item}</small>
                                                    )
                                                }) 
                                            : null
                                            }
                                        </div>
                                    </div>
                                    </React.Fragment>
                                :
                                    <div className="d-flex mb-1">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Resource Id / Name:</p>
                                            <p className="mb-0 text-white">{this.props.alertPageDetails.asset_name ? this.props.alertPageDetails.asset_name : ''}</p>
                                        </div>
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">Service:</p>
                                            <p className="mb-0 text-white">
                                                {this.props.alertPageDetails.resource_type ? this.props.alertPageDetails.resource_type : ''}
                                                {this.props.alertPageDetails.service_name ?  (' : ')+this.props.alertPageDetails.service_name : ''}
                                            </p>
                                        </div>
                                    </div>
                                }
                                {this.props.alertPageDetails.type !== 'alert' ?
                                    <div className="d-flex mb-1 border-top">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Provider / Account / Region :</p>
                                            <p className="mb-0 text-white">
                                                {this.props.alertPageDetails.provider ? this.props.alertPageDetails.provider.toUpperCase() : ''}
                                                {this.props.alertPageDetails.account_id ? (' : ')+this.props.alertPageDetails.account_id : ''}
                                                {this.props.alertPageDetails.region ? (' : ')+this.props.alertPageDetails.region : ''}
                                            </p>
                                        </div>
                                    </div>
                                : null}
                            </div>
                        : this.props.alertDetails && !Object.entries(this.props.alertDetails).length ? 
                            <div className='col-md-12 d-flex justify-content-center m-4'>
                                <p>Please select the alert to get the alert event details.</p>
                            </div>
                        : null}
                        <div className="mt-3">
                            {this.props.alertPageDetails && this.props.alertMonitoringList[this.props.alertPageDetails.asset_name] ?
                                <AlertMetricList
                                    from={'alertPage'}
                                    eventType={this.props.alertPageDetails ? this.props.alertPageDetails.event_type : ''}
                                    caseId={this.props.caseId}
                                    getMetricsDeatils={(assetId, assetName) => this.getMetricsDeatils(assetId, assetName)}
                                    textColor={'text-white'}
                                    backGroudColor={'bg-dark'}
                                    heading={'bg-muted text-info'}
                                />
                            : null}
                            {/* !this.state.showMetricsList ?
                                <div className='d-flex justify-content-center m-4'>
                                    <Spinner className='text-center' color='white' size='lg' />
                                </div>
                            : null} */}
                        </div>
                    </div>                             
                </div>
            :
                <AlertMetricDetails 
                    caseId={this.props.caseId}
                    selectedMetricAssetName = {this.state.selectedMetricAssetName ? this.state.selectedMetricAssetName : ''}
                    selectedMetricAssetId = {this.state.selectedMetricAssetId ? this.state.selectedMetricAssetId : ''}
                    showMetricsDetails={(bool) => 
                        this.setState({ showMetricsDetails: bool },
                            () => {
                                this.props.setAiopsPropsDetails('showMetricsList', true)
                            }
                        )
                    }
                    backGroudColor={'bg-muted'}
                    heading={'bg-black text-info'}
                    textColor={'text-white'}
                />
            }
            </div>
        )
    }
}
/**
 * Type of the props used in the component
 */
AlertRightSection.propTypes = {
    listAssets: PropTypes.func,
    getMonitoringMetricsByTrend: PropTypes.func,
    getSreDetails: PropTypes.func,
    setAiopsPropsDetails: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
    // console.log('state', state)
    return {
        aiopsPropsDetails: state.aiops.aiopsPropsDetails ? state.aiops.aiopsPropsDetails : {},
        alertPageDetails: state.aiops.aiopsPropsDetails.alert_page_details ? state.aiops.aiopsPropsDetails.alert_page_details : {},
        alertEventDetails: state.aiops.alertEventDetails,
        caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : {},
        assetsList: state.aiops.caseDetails.results && state.aiops.caseDetails.results.assets ? state.aiops.caseDetails.results.assets : [],
        alertsList: state.aiops.caseDetails.results && state.aiops.caseDetails.results.alerts ? state.aiops.caseDetails.results.alerts : [],
        
        alertAssetDetails: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.alertAssetDetails ? state.aiops.aiopsPropsDetails.alertAssetDetails : {},
        alertMonitoringList: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.alertMonitoringList ? state.aiops.aiopsPropsDetails.alertMonitoringList : {},
        alertSreMetricsList: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.alertSreMetricsList ? state.aiops.aiopsPropsDetails.alertSreMetricsList : {},
    }
}

export default connect(mapStateToProps, {
    listAssets,
    getMonitoringMetricsByTrend,
    getSreDetails,
    setAiopsPropsDetails,
})(withRouter(AlertRightSection))