/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file QueryEditor.js
 * @author Prakash // on 26/10/2020
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { AppWrapper } from '../common/AppWrapper'
import PropTypes from 'prop-types'
import { listAllAccounts } from '../../actions/commonAction'
import { listAthenaResources } from '../../actions/dlp/PoliciesAction'
import { invokeQuery, getQueryResult, listQueryLog, downlaodQueryResult } from '../../actions/dlp/QueryAction'

import Select from 'react-select';
import _ from 'lodash'
import { capitalizeFirstLetter, getAccountNameFromId, restrictCharacter, momentConvertionUtcToLocalTime } from '../../utils/utility'
import { Spinner } from 'reactstrap'

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-mysql";
import "ace-builds/src-noconflict/theme-tomorrow";

import ResizeableFullWidthTable from '../designComponents/Table/ResizeableFullWidthTable'
import Search from '../common/SearchComponent'

let timer = null;

class QueryEditor extends Component {
    constructor(props) {
        super(props)
        this.aceEditor = React.createRef()
        this.tabSlider = React.createRef()

        this.state = {            
            activeParentTab: 'editor',
            tabsArray: [1],
            activeTab: 1,
            disableLeftSlider: true,
            newTabAllowed: 10,            
            scheduleOptions: [
                {label: '1 Day', value: '1d'}, 
                {label: '1 Week', value: '7d'},
                {label: '2 Weeks', value: '14d'},
                {label: '1 Month', value: '30d'}
            ],
            // recentQueryData: recentQueryData,
            // filteredRecentList: recentQueryData,
            // quertyResults: [
            //     {column1: 'one', column2: 'tow'},
            //     {column1: 'three', column2: 'four'},
            //     {column1: 'five', column2: 'six'},
            //     {column1: 'seven', column2: 'eight'},
            // ],
        }
    }

    componentDidMount = () => {
        this.getAllFilters()
        this.getDataSource()
        this.slideTabNext()
        if(localStorage.getItem('DlpQueryTab') && JSON.parse(localStorage.getItem('DlpQueryTab')).length) {
            let recentExectionIds = []
            let tabsArray = []
            let activeTab = 1
            let data =JSON.parse(localStorage.getItem('DlpQueryTab'))
            data.forEach((item, index) => {
                if(!index) {
                    activeTab = item.tab
                    this.setState({ 
                        ['query_'+item.tab]: item.query, 
                        selectedAccount: item.account_id,
                        catalog_name: item.catalog_name,
                        database_name: item.database_name,
                        ['invokeSpinner_'+item.tab]: item.execution_id !== '' ? true : false
                    },
                        () => {
                            this.getDataSource()
                            this.getDatabaseList()
                        }
                    )
                }
                tabsArray.push(item.tab)
                if(item.execution_id && item.execution_id !== '') {
                    recentExectionIds.push(item.execution_id)
                }
                this.setState({ 
                    ['query_'+item.tab]: item.query,
                    ['execution_id_'+item.tab]: item.execution_id ? item.execution_id : '',
                    ['job_id_'+item.tab]: item.job_id ? item.job_id : ''
                },
                    () => {
                        if(this.state['execution_id_'+item.tab] !== '' && !index) {
                            this.getQueryResult(item.tab)
                        }
                    }
                )
            })

            this.setState({ tabsArray, activeTab, recentExectionIds  })
        }
    }
    
    componentDidUpdate = () => {}

    componentWillUnmount () {
        // localStorage.setItem('rootState', JSON.stringify(this.state);
        this.setLocalStorage()
    }

    setLocalStorage = () => {
        let array = []
        this.state.tabsArray.forEach(item => {
            let dataRow = {}
            dataRow.tab = item
            dataRow.query = this.state['query_'+item] ? this.state['query_'+item] : ''
            dataRow.account_id = this.state.selectedAccount
            dataRow.catalog_name = this.state.catalog_name
            dataRow.database_name = this.state.database_name
            dataRow.execution_id = this.state['execution_id_'+item]
            dataRow.job_id = this.state['job_id_'+item]

            if(dataRow.query !== '') {
                array.push(dataRow)
            }
        })

        localStorage.setItem('DlpQueryTab', JSON.stringify(array));
    }

    getDataSource = () => {
        let params = {}
        this.props.listAthenaResources(params, (promise, response) => {
            if(promise) {
                this.setState({ catalogs: response, loadingDataSource: false, disablePage: false })
            }
        })
	}

    getDatabaseList = () => {
		let params = {}
		params.catalog_name = this.state.catalog_name
		this.props.listAthenaResources(params, (promise, response) => {
			if(promise) {
				this.setState({ database: response, loadingDatabase: false, disablePage: false },
                    () => {   
                        if(this.state.database_name !== '' && this.state.selectedDataSource === 'Database Table') {
                            this.getTables()
                        }
                    }
                )
			}
		})
	}

	getTables = () => {
		let params = {}
		params.catalog_name = this.state.catalog_name
		params.database_name = this.state.database_name
		this.props.listAthenaResources(params, (promise, response) => {
			this.setState({ tables: response, loadingTables: false, disablePage: false })
		})
	}

    // getTableData = () => {
    //     let columns = []
    //     this.state.quertyResults && this.state.quertyResults.length && Object.keys(this.state.quertyResults[0]).forEach(item => {
    //         let dataRow = {}
    //         dataRow.Header = capitalizeFirstLetter(item)
    //         dataRow.accessor = item		
    //         columns.push(dataRow)
    //     })

    //     this.setState({ columns, filteredList: this.state.quertyResults })
    // }
    /**
     * Filter identifiers based on the filter selection
     */
    getAllFilters = () => {
        if(!this.props.accounts || !this.props.accounts.length) {
            let params = {}
            this.props.listAllAccounts(params, (promise, allAccounts) => {})
        }
    }

    onChangeQuery = (value) => {
        // let value = this.aceEditor && this.aceEditor.current ? this.aceEditor.current.editor.getValue() : ''
        // console.log(event, index)
        this.setState({ ['query_'+this.state.activeTab]: value },
            () => this.setLocalStorage()
        )
    }

    invokeQuery = () => {
        let activeTab = this.state.activeTab
        let hasError = false
        let query = this.state['query_'+activeTab] ? this.state['query_'+activeTab] : ''
        if(query === '' || !this.state.selectedAccount || !this.state.catalog_name || !this.state.database_name) {
            hasError = true
        }

        if(!hasError) {
            let params = {}
            params.account_id = this.state.selectedAccount
            params.catalog_name = this.state.catalog_name
            params.database_name = this.state.database_name
            // params.table_name = "users"
            params.query = query

            params.user_info = {
                "organization": "org1",
                "group": "group1",
                "user_id": "user1"
            }
            params.service_name = "athena"                     
            params.analyse_files = false
            params.identify = []
            params.deidentify = false
            this.props.invokeQuery(params, (promise, response) => {
                if(promise) {
                    this.setState({ ['execution_id_'+activeTab]: response.execution_id, hasError, ['invokeSpinner_'+activeTab]: false },
                        () => {
                            this.getQueryResult(activeTab, 'invoke')
                            // this.checkQueryResult()
                        }
                    )
                }
            })
        } else {
            this.setState({ hasError, ['invokeSpinner_'+activeTab]: false })
        }
    }

    getQueryResult = (activeTab, from) => {
        let params = {}
        params.service_name = "athena"
        if(this.state['job_id_'+activeTab] && this.state['job_id_'+activeTab] !== '' && from !== 'invoke') {
            params.job_id = this.state['job_id_'+activeTab]
        } else {
            params.execution_id = this.state['execution_id_'+activeTab]
        }
        params.response_size = "first.100"
        params.response_format = "json"
        if(this.state['execution_id_'+activeTab] && this.state['execution_id_'+activeTab] !== '') {
            this.props.getQueryResult(params, (promise, response) => {
                if(promise) {
                    if(response.status === 'NOT_COMPLETED') {
                        timer = setTimeout(() => this.getQueryResult(activeTab), 5000)
                    } else {
                        clearTimeout(timer)
                        this.formTableColumn(activeTab, response)
                    }
                } else {
                    clearTimeout(timer)
                    this.setState({ ['invokeSpinner_'+activeTab]: false })
                    
                }
            })
        }
    }

    formTableColumn = (activeTab, response) => {
        let columns = []
        let data = response.data ? response.data : []
        Object.keys(data[0]).forEach(item => {
            let dataRow = {}
            dataRow.Header = capitalizeFirstLetter(item)
            dataRow.accessor = item
            columns.push(dataRow)
        })

        this.setState({ columns, ['query_result_'+activeTab]: data, ['filteredList_'+activeTab]: data, ['invokeSpinner_'+activeTab]: false, ['job_id_'+activeTab]: response.job_id ? response.job_id: '' },
            () => this.setLocalStorage()
        )
    }

    onResetQuerySection = () => {
        // if(this.aceEditor) {
        //     this.aceEditor.current.editor.setValue('')
        // }
        this.setState({ ['query_'+this.state.activeTab]: '' },
            () => this.setLocalStorage()
        )
        // this.setState({ ['query_result_'+this.state.activeTab]: [], ['filteredList_'+this.state.activeTab]: [] })
    }

    addTab = (array) => {
        let rowList = this.state[array];
        let value = this.state[array][this.state[array].length - 1]
        value = value+1
        if(this.state[array]) {
            rowList.push(value)
        }
        
        this.setState({ [array]: rowList, activeTab: value },
            () => {
                this.slideTabNext(value)
                this.setLocalStorage()
            }
        )
    }

    removeTab = (tabId) => {
        let rowList = this.state.tabsArray.filter(e => e !== tabId)

        let activeTab = 0
        let nextItems = this.state.tabsArray.filter(e => e > tabId)
        if(nextItems.length) {
            activeTab = nextItems[0]
        } else {
            let previousItems = this.state.tabsArray.filter(e => e < tabId)
            activeTab = previousItems[previousItems.length - 1]
        }
        let recentExectionIds = this.state.recentExectionIds
        if(recentExectionIds.includes(this.state['execution_id_'+tabId])) {
            recentExectionIds = recentExectionIds.filter(e => e !== this.state['execution_id_'+tabId])
        }

        this.setState({ 
            tabsArray: rowList ,
            ['query_'+tabId]: '',
            activeTab,
            recentExectionIds,
            ['invokeSpinner_'+tabId]: false
        },
            () => this.setLocalStorage()
        )
    }

    slideTabNext = (item) => {
        // let totalTabs = this.state.tabsArray.length;
        
        // let averageSliderItemWidth = 0
		// let reqTabWidth = 0
		// for (let i = 0; i < totalTabs; i++) {
        //     reqTabWidth += this.tabSlider.current.childNodes[i].getBoundingClientRect().width
		// }
		// averageSliderItemWidth = reqTabWidth/totalTabs //remove or comment if all tab are same with
		// let sliderWrapWidth = this.tabSlider.current.getBoundingClientRect().width
		// let totalItemsPerPage = sliderWrapWidth/averageSliderItemWidth;        
        // let totalItemShowing = parseInt(totalItemsPerPage)
        
        // if(totalItemShowing >= totalTabs) {
        //     this.setState({ disableRightSlider: true, disableLeftSlider: true })
        // } else {
        //     this.setState({ disableRightSlider: false })
        // }
        
		// let move = this.state.showingTabNumber ? this.state.showingTabNumber : totalItemShowing
        // console.log(move, 'right', move-1, '-', this.tabSlider.current.childNodes[move-1] && this.tabSlider.current.childNodes[move-1].getBoundingClientRect().width)
		// if (this.tabSlider.current.childNodes[move-1] && this.tabSlider.current.childNodes[move-1].getBoundingClientRect().width) {
        //     if(item === '' || item) {
        //         this.tabSlider.current.scrollLeft += 154
        //         // if(move >= totalTabs || move <= totalItemShowing) {
        //         //     this.setState({ disableRightSlider: true })
        //         // }
        //         if(item === '') {
        //             // disableLeftSlider: false,
        //             this.setState({ showingTabNumber: move + 1, activeTab: this.state.tabsArray[move - 1] })
        //         } else if(totalItemShowing < item) {
        //             //disableLeftSlider: false,
        //             this.setState({ showingTabNumber: this.state.tabsArray.length + 1 })
        //         }
        //     } else {
        //         this.setState({ showingTabNumber: move })
        //     }
        // } else {
        //     this.setState({ showingTabNumber: move })
		// }
        this.tabSlider.current.scrollLeft += 154
        // let tabExist = this.state.activeTab + 1
        // while(this.state.tabsArray.includes(tabExist)) {
        //     tabExist++
        // }
        // this.setState({ activeTab: tabExist })
    }

    slideTabPrevious = () => {
        // let move = 0
		// move = this.state.tabsArray.indexOf(this.state.activeTab)
        
		// let totalTabs = this.state.tabsArray.length;
		// let reqTabWidth = 0
		// for (let i = 0; i < totalTabs; i++) {
		// 	reqTabWidth += this.tabSlider.current.childNodes[i].getBoundingClientRect().width
		// }

		// let averageSliderItemWidth = reqTabWidth/totalTabs
		// let sliderWrapWidth = this.tabSlider.current.getBoundingClientRect().width        
		// let totalItemShowing = sliderWrapWidth/averageSliderItemWidth;
        // totalItemShowing = parseInt(totalItemShowing)
        // console.log(move, 'left', move-1, '-', totalItemShowing)
        
		// if(move >= totalItemShowing) {
		// 	if (this.tabSlider.current.childNodes[move-1] && this.tabSlider.current.childNodes[move-1].getBoundingClientRect().width) {
		// 		this.tabSlider.current.scrollLeft -= 154
        //         // console.log(move-1, 'move-1', totalItemShowing)
        //         // if((move-1) <= totalItemShowing) {
        //         //     this.setState({ disableLeftSlider: true })
        //         // }
        //         // disableRightSlider: false,
        //         this.setState({ showingTabNumber: move + 1, activeTab: this.state.tabsArray[move-1] })
		// 	}
		// } else {
        //     this.setState({ showingTabNumber: move + 1 })
		// }

        this.tabSlider.current.scrollLeft -= 154

        // let tabExist = this.state.activeTab - 1
        // while(this.state.tabsArray.includes(tabExist)) {
        //     tabExist--
        // }
        // this.setState({ activeTab: tabExist })

    }

    onChangeTab = (item) => {
        clearTimeout(timer)
        this.setState({ 
            activeTab: item, 
            // ['query_'+item]: this.state['query_'+item] ? this.state['query_'+item] : '',
            emptySearchText: true
        },
            () => {
                this.setLocalStorage()
                if(this.state['execution_id_'+this.state.activeTab] && this.state['execution_id_'+this.state.activeTab] !== '') {
                    this.getQueryResult(this.state.activeTab)
                }
            }
        )
    }

    getSchedulLabel = () => {
        let scheduleLabel = ''
        this.state.scheduleOptions.forEach(item => {
            if(item.value === this.state.selectedSchedule) {
                scheduleLabel = item.label
            }
        })
        return scheduleLabel
    }

    scheduleQuery = () => {
        let hasScheduleError = false
        if(!this.state.bucket_name || this.state.bucket_name === '' || !this.state.selectedSchedule) {
            hasScheduleError = true
        }

        if(!hasScheduleError) {
            let params = {}
            params.bucket_name = this.state.bucket_name
            params.prefix_name = this.state.prefix_name ? this.state.prefix_name : ''
            params.schedule = this.state.selectedSchedule
            // this.props.scheduleQueryReport(params, (promise, response) => {
            //     if(promise) {
            //         this.setState({ ['execution_id_'+activeTab]: response.execution_id, hasScheduleError })
            //     }
            // })
        } else {
            this.setState({ hasScheduleError })
        }

        this.setState({ hasScheduleError })
    }

    onClickAction = (data, type) => {
        let recentExectionIds = this.state.recentExectionIds ? this.state.recentExectionIds : []
        if(!recentExectionIds.includes(data.execution_id) && this.state.tabsArray.length < this.state.newTabAllowed) {
            recentExectionIds.push(data.execution_id)
            this.setState({ recentExectionIds, },
                () => {
                    let rowList = this.state.tabsArray;
                    let value = this.state.tabsArray[this.state.tabsArray.length - 1]
                    value = value+1
                    if(this.state.tabsArray) {
                        rowList.push(value)
                    }
                    
                    this.setState({ 
                        tabsArray: rowList, activeTab: value, 
                        ['query_'+value]: data.query, 
                        ['execution_id_'+value]: data.execution_id, 
                        ['job_id_'+value]: data.job_id ? data.job_id : '', 
                        activeParentTab: 'editor',
                        catalog_name: data.catalog_name,
                        database_name: data.database_name,
                        selectedAccount: data.account_id
                    },
                        () => {
                            // this.slideTabNext(value)
                            // this.setLocalStorage()
                            this.getQueryResult(value)
                            this.setLocalStorage()
                            this.getDataSource()
                            this.getDatabaseList()
                        }
                    )
                }    
            )
        }
    }

    listQueryLog = () => {
        let params = {}
        params.user_info ={
            group: "group1",
            organization: "org1",
            user_id: "user1"
        }
        this.props.listQueryLog(params, (promise, response) => {
            if(promise) {
                this.setState({ recentLoading: false, recentQueryData: response, filteredRecentList: response })
            }
        })
    }

    donwloadQuery = () => {
        let params = {}
        params.service_name = "athena"
        params.job_id = this.state['job_id_'+this.state.activeTab]

        this.props.downlaodQueryResult(params, (promise, response) => {
            if(promise) {
                if(response.status === 'success') {
                    window.open(response.download_url, "_blank")
                }
            }
            this.setState({ ['downlaod_spinner_'+this.state.activeTab]: false })
        })
    }

    render() {
        return (
            <div className="container-fluid overflow-auto flex-grow-1">
				<div className='row h-100'>
					<div className='col-sm-12 p-0'>
						<div className="title-section p-3">
							<h6 className="text-white">Query Editor</h6>
							<p className="text-white">Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim.</p>
						</div>
						<div className="box m-3 p-3 h-100">
							<div className="row">
								<div className='col-12'>
									<h6>Query</h6>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur.
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <ul className={`nav nav-tabs border-bottom-gray3 shadow-none bg-white1 ${this.state.showLoading ? 'disabled' : ''}`} id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <span className={`nav-link text-purple cursorPointer ${this.state.activeParentTab === 'editor' ? ' active' : ''}`} onClick={() => this.setState({ activeParentTab: 'editor' })}>Editor</span>
                                        </li>
                                        <li className="nav-item">
                                            <span className={`nav-link text-purple cursorPointer ${this.state.activeParentTab === 'recentQueries' ? ' active' : ''}`} onClick={() => this.setState({ recentLoading: true, activeParentTab: 'recentQueries' }, () => this.listQueryLog())}>Recent Queries</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row h-100">
                                {this.state.activeParentTab === 'editor' ?
                                    <React.Fragment>
                                    <div className="col-lg-2">
                                        <div className='form-group'>
                                            <label className="align-self-center">Account </label>
                                            <div className="d-flex">
                                                <div className={`align-self-center mr-2 w-100`}>
                                                    <Select
                                                        placeholder={'Select'}
                                                        // menuIsOpen={this.state.providerIsOpen}
                                                        isSearchable={false}
                                                        className={`f13 p-0  z999`}
                                                        value={({
                                                            value: this.state.selectedAccount ? this.state.selectedAccount : 'Select',
                                                            label: this.state.selectedAccount ? getAccountNameFromId(this.state.selectedAccount, this.props.accounts) : 'Select'
                                                        })}
                                                        options={this.props.accounts && this.props.accounts.map(acc => ({
                                                            value: acc.account_id,
                                                            label: acc.account_name,
                                                        }))}
                                                        onChange={event => { this.setState({ selectedAccount: event.value }) }}
                                                    />
                                                </div>
                                            </div>
                                            {this.state.hasError && (!this.state.selectedAccount || this.state.selectedAccount === '') ?
                                                <span className='ml-2 text-danger align-self-end m-0 p-0'>Account is required</span>
                                            : null }
                                        </div>
                                        <div className='form-group'>
                                            <label className="align-self-center">{this.state.loadingDataSource ? <Spinner size='sm' className='mx-2' color='dark' /> : ''} Data Source </label>
                                            <div className="d-flex">
                                                <div className={`align-self-center mr-2 w-100`}>
                                                    <Select
                                                        // menuIsOpen={true}
                                                        placeholder={'Select'}
                                                        components={{
                                                            IndicatorSeparator: () => null
                                                        }}
                                                        isSearchable={false}
                                                        className={`f13 p-0  z998`}
                                                        value={({
                                                            value: this.state.catalog_name ? this.state.catalog_name : 'Select',
                                                            label: this.state.catalog_name ? this.state.catalog_name : 'Select'
                                                        })}
                                                        options={this.state.catalogs && this.state.catalogs.map(cat => ({
                                                            value: cat,
                                                            label: cat,
                                                        }))}
                                                        onChange={event =>  
                                                            this.setState({ catalog_name: event.value, loadingDatabase: true }, 
                                                                () => this.getDatabaseList() 
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {this.state.hasError && (!this.state.catalog_name || this.state.catalog_name === '') ?
                                                <span className='ml-2 text-danger align-self-end m-0 p-0'>Data Source is required</span>
                                            : null }
                                        </div>
                                        
                                        <div className='form-group'>
                                            <label className="align-self-center">{this.state.loadingDatabase ? <Spinner size='sm' className='mx-2' color='dark' /> : ''} Database </label>
                                            <div className="d-flex">
                                                <div className={`align-self-center mr-2 w-100`}>
                                                    <Select
                                                        // menuIsOpen={true}
                                                        placeholder={'Select'}
                                                        components={{
                                                            IndicatorSeparator: () => null
                                                        }}
                                                        isSearchable={true}
                                                        className={`f13 p-0  z997`}
                                                        value={({
                                                            value: this.state.database_name ? this.state.database_name : 'Select',
                                                            label: this.state.database_name ? this.state.database_name : 'Select'
                                                        })}
                                                        options={this.state.database && this.state.database.map(dbs => ({
                                                            value: dbs,
                                                            label: dbs,
                                                        }))}
                                                        onChange={event =>  
                                                            this.setState({ database_name: event.value, loadingTables: true, disablePage: true }, 
                                                                // () => this.getTables() 
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {this.state.hasError && (!this.state.database_name || this.state.database_name === '') ?
                                                <span className='ml-2 text-danger align-self-end m-0 p-0'>Database is required</span>
                                            : null }
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-10 border-left-gray3">
                                        {/* <div className="d-flex justify-content-between mb-2">
                                            <div className="d-flex align-self-end ">
                                                <h6 className="text-purple m-0 p-0">Editor</h6>
                                            </div>
                                        </div> */}
                                        <div className="border-gray3 dlpQueryTab">
                                            <div className="d-flex justify-content-between">
                                                <div className="border-bottom-gray3 stepperTab" ref={this.tabSlider}>
                                                    {this.state.tabsArray.map(item => {
                                                        return(
                                                            <div style={{"minWidth": '154px'}} class={`border-right-gray3 ${item === this.state.activeTab ? 'f16 font-weight-bold bg-white1 text-purple' : 'bg-white1 text-dark'}`}>
                                                                <div className="d-flex">
                                                                    <label class="mb-0 py-2 pl-3 pr-2 cursorPointer" onClick={() => this.onChangeTab(item)}>New Query {item}</label>
                                                                    <span class={`align-self-center text-dark f16 fal fa-times cursorPointer pr-2 ${this.state.tabsArray.length === 1 ? 'displayNone': ''}`} onClick={() => this.removeTab(item)}></span>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                <div style={{"minWidth": "27px"}} class={`border-left-gray3 border-bottom-gray3  py-2 px-2 bg-white1 cursor-pointer ${this.state.disableLeftSlider ? '' : 'cursorPointer'}`} onClick={() => this.slideTabPrevious('')}>
                                                    <span class={`text-dark f16 fas fa-chevron-left`}></span>
                                                </div>
                                                <div style={{"minWidth": "27px"}} class={`border-left-gray3 border-bottom-gray3  py-2 px-2 bg-white1 cursor-pointer ${this.state.disableRightSlider ? '' : 'cursorPointer'}`} onClick={() => this.slideTabNext('')}>
                                                    <span class={`text-dark f16 fas fa-chevron-right`}></span>
                                                </div>
                                                <div class={`border-left-gray3 border-bottom-gray3  py-2 px-2 bg-white1 cursor-pointer ${this.state.tabsArray.length === this.state.newTabAllowed ? 'disabled': ''}`} onClick={() => this.addTab('tabsArray')}>
                                                    <span class={`text-dark f16 far fa-plus`}></span>
                                                </div>
                                            </div>
                                            <AceEditor
                                                placeholder="Type your query to get your result"
                                                mode="mysql"
                                                theme="tomorrow"
                                                name="blah2"
                                                width="100%"
                                                height="400px"
                                                wrapEnabled={true}
                                                focus={true}
                                                onChange={this.onChangeQuery}
                                                fontSize={16}
                                                showPrintMargin={true}
                                                showGutter={true}
                                                highlightActiveLine={true}
                                                value={this.state['query_'+this.state.activeTab] ? this.state['query_'+this.state.activeTab] : ''}
                                                setOptions={{
                                                    enableBasicAutocompletion: false,
                                                    enableLiveAutocompletion: false,
                                                    enableSnippets: false,
                                                    showLineNumbers: true,
                                                    tabSize: 2,
                                                }}
                                                // style={{}}
                                                ref={this.aceEditor}
                                            />
                                        </div>

                                        <div className="d-flex justify-content-between border-top-gray3 pt-2 mt-3">
                                            <div className="d-flex">
                                                <button className={`btn  
                                                    ${this.state['query_'+this.state.activeTab] && this.state['query_'+this.state.activeTab] !== '' && !this.state['invokeSpinner_'+this.state.activeTab] ? 'btn-primary' : 'btn-light disabled'}`} 
                                                    onClick={() => this.setState({ ['invokeSpinner_'+this.state.activeTab]: true, ['schedule_details_'+this.state.activeTab]: false }, () => this.invokeQuery())}
                                                >
                                                    {this.state['invokeSpinner_'+this.state.activeTab] ? <Spinner size='sm' className='mx-2' color='dark' /> : ''} 
                                                    {!this.state['invokeSpinner_'+this.state.activeTab] && this.state['execution_id_'+this.state.activeTab] && this.state['execution_id_'+this.state.activeTab] !== '' ? 'Run Again' : 'Run'}
                                                </button>

                                                <button className='btn btn-light ml-2' onClick={() => this.onResetQuerySection()}>Clear</button>
                                            </div>
                                            {this.state['query_result_'+this.state.activeTab] && this.state['query_result_'+this.state.activeTab].length ?
                                                <div className="d-flex">
                                                    <button className={`btn btn-outline-info ${this.state['downlaod_spinner_'+this.state.activeTab] ? 'disabled' : ''}`} onClick={() => this.setState({ ['downlaod_spinner_'+this.state.activeTab]: true }, () => this.donwloadQuery())}>
                                                        {this.state['downlaod_spinner_'+this.state.activeTab] ? <Spinner size='sm' className='mx-2' color='dark' /> : null}Download
                                                    </button>
                                                    {/* <div className="btn-group dropDown" onClick={() => this.setState({ showOptions: !this.state.showOptions })}>
                                                        <i className="fas fa-arrow-circle-down f15 align-self-center cursorPointer"></i>
                                                        <div className={`dropdown-menu dropdown-menu-right dropdownSettingDark bg-dark cursorPointer ${this.state.showOptions ? 'show active' : ''}`}>
                                                            <button className="dropdown-item text-white" type="button" onClick={() => this.setState({ caseCloseSection: true })}>
                                                                Csv
                                                            </button>
                                                            <button className="dropdown-item text-white" type="button" onClick={() => this.setState({ caseCloseSection: true })}>
                                                                Json
                                                            </button>
                                                        </div>
                                                    </div> */}
                                                    {/* <button className={`btn btn-success ml-2 cursorPointer ${this.state.scheduleOption ? 'disabaled' : ''}`} onClick={() => this.setState({ scheduleOption: true })}>Schedule</button> */}
                                                    <label className={`ml-2 cursorPointer text-info align-self-end ${this.state['schedule_details_'+this.state.activeTab] ? 'disabled' : ''}`} onClick={() => this.setState({ ['schedule_details_'+this.state.activeTab]: true })}>Schedule</label>
                                                </div>
                                            : null}
                                        </div>
                                        {this.state['query_result_'+this.state.activeTab] && this.state['query_result_'+this.state.activeTab].length ?
                                            <React.Fragment>
                                            {this.state['schedule_details_'+this.state.activeTab] ? 
                                                <div className="mt-3">
                                                    <p className="mb-0 mr-2 align-self-end text-purple mt-4">Schedule Details</p>
                                                    <div className="d-flex flex-wrap">
                                                        <div className="w-30">
                                                            <label className="align-self-center m-0">Bucket </label>
                                                            <input 
                                                                type="text" 
                                                                className={`border-gray3 form-control`}
                                                                value={this.state.bucket_name ? this.state.bucket_name : ''}
                                                                onChange={e => this.setState({ bucket_name: restrictCharacter(e.target.value, '/') })}
                                                            />
                                                            {this.state.hasScheduleError && (!this.state.bucket_name || this.state.bucket_name === '') ?
                                                                <span className='ml-2 text-danger align-self-end m-0 p-0'>Bucket is required</span>
                                                            : null}
                                                        </div>
                                                        <div className="w-30 mx-2">
                                                            <label className="align-self-center m-0">Prefix </label>
                                                            <input 
                                                                type="text" 
                                                                className={`border-gray3 form-control`}
                                                                value={this.state.prefix_name ? this.state.prefix_name : ''}
                                                                onChange={e => this.setState({ prefix_name: e.target.value })}
                                                            />
                                                        </div>
                                                        <div className="w-10 mx-2">
                                                            <label className="align-self-center m-0">Schedule Period </label>
                                                            <Select
                                                                placeholder={'Select'}
                                                                isSearchable={false}
                                                                // menuIsOpen ={true}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                                className={`mr-2`}
                                                                value={({
                                                                    value: this.state.selectedSchedule ? this.state.selectedSchedule : 'Select',
                                                                    label: this.state.selectedSchedule ? this.getSchedulLabel() : 'Select'
                                                                })}
                                                                options={this.state.scheduleOptions && this.state.scheduleOptions.map(item => ({
                                                                    value: item.value,
                                                                    label: item.label,	
                                                                }))}
                                                                onChange={event =>  this.setState({ selectedSchedule: event.value })}
                                                            />
                                                            {this.state.hasScheduleError && !this.state.selectedSchedule ?
                                                                <span className='ml-2 text-danger align-self-end m-0 p-0'>Duration is required</span>
                                                            : null }
                                                        </div>
                                                        <div>
                                                            <label className="align-self-center m-0 w-100">&nbsp;</label>
                                                            <button className='btn btn-outline-info' onClick={() => this.scheduleQuery()}>Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            : null}

                                            <div className="d-flex justify-content-between mt-3">
                                                <div className='d-flex'>
                                                    <small className="ml-2 align-self-center">Showing {this.state['filteredList_'+this.state.activeTab].length} query results</small>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="form-group mb-0">
                                                        <Search
                                                            data={this.state['query_result_'+this.state.activeTab]}
                                                            applyTags={false}
                                                            applyLiteDarkTags={true}
                                                            topClassName={'bg-white'}
                                                            searchClassName={'textboxBorder'}
                                                            searchIconColor={'text-dark'}
                                                            className={"bg-transparent textboxBorder"}
                                                            filteredData={(searchArray, searchText) => {
                                                                this.setState({ ['filteredList_'+this.state.activeTab]: _.orderBy(searchArray, ['date'], ['desc']), emptySearchText: false })
                                                            }}
                                                            resetSearchBox={this.state.emptySearchText}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <ResizeableFullWidthTable
                                                columns={this.state.columns ? this.state.columns : []}
                                                data={this.state['filteredList_'+this.state.activeTab] ? this.state['filteredList_'+this.state.activeTab] : []}
                                                perPage={10}
                                                dashboard={this.state['filteredList_'+this.state.activeTab] ? this.state['filteredList_'+this.state.activeTab].length : 0}
                                                sortByColumn={'Date'}
                                                tooltipFor={[3]}
                                                onClickRow={tableRow => {}}
                                                tableSize={'table-striped'}
                                            />
                                            </React.Fragment>
                                        : null}
                                    </div>
                                    </React.Fragment>
                                : this.state.activeParentTab === 'recentQueries' ?
                                    !this.state.recentLoading && this.state.recentQueryData && this.state.recentQueryData.length ?
                                        <div className="col-lg-12">
                                            <div className="d-flex justify-content-between mt-3">
                                                <div className=''>
                                                    <p className="m-0 text-purple">Recent Queries</p>
                                                    <small className="align-self-center">Showing {this.state.filteredRecentList.length} query results</small>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="form-group mb-0">
                                                        <Search
                                                            data={this.state.recentQueryData}
                                                            applyTags={false}
                                                            applyLiteDarkTags={true}
                                                            topClassName={'bg-white'}
                                                            searchClassName={'textboxBorder'}
                                                            searchIconColor={'text-dark'}
                                                            className={"bg-transparent textboxBorder"}
                                                            filteredData={(searchArray, searchText) => {
                                                                this.setState({ filteredRecentList: searchArray })
                                                            }}
                                                            resetSearchBox={this.state.emptySearchText}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <ResizeableFullWidthTable
                                                columns={[
                                                    {
                                                        Header: 'Date',
                                                        accessor: d => d.modified_at ? d.modified_at : d.created_at,
                                                        Cell: cellInfo => (<span>{cellInfo.row.original.modified_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.modified_at, 'DD MMM YYYY HH:mm') : (cellInfo.row.original.created_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, 'DD MMM YYYY HH:mm')  : '')}</span>)
                                                    },
                                                    {
                                                        Header: 'Account',
                                                        accessor: 'account_id',
                                                        // accessor: d=>d.account_id ? getAccountNameFromId(account_id, this.state.accounts)
                                                        width: 200
                                                    },                                                    
                                                    {
                                                        Header: 'Database',
                                                        accessor: 'database_name',
                                                        width: 200
                                                    },
                                                    {
                                                        Header: 'Query',
                                                        accessor: 'query',
                                                        width: 600                                                    },
                                                    {
                                                        Header: 'Status',
                                                        accessor: 'current_status',
                                                        Cell: cellInfo => (<span className={`${cellInfo.row.original.current_status && cellInfo.row.original.current_status.toLowerCase() === 'completed' ? 'text-success' : cellInfo.row.original.current_status.toLowerCase() ==='failed' ? 'text-danger' : 'text-warning' }`}>{cellInfo.row.original.current_status ? capitalizeFirstLetter(cellInfo.row.original.current_status) : ''}</span>)
                                                    },
                                                    {
                                                        Header: ' ',
                                                        // Header: () => (<div style={{textAlign:"right"}}>&nbsp;</div>),
                                                        
                                                        headerClassName: 'text-right',
                                                        Cell: cellInfo => (
                                                            <div className="d-flex justify-content-between align-self-center">
                                                                <p className={`m-0 p-0 ${this.state.recentExectionIds && this.state.recentExectionIds.includes(cellInfo.row.original.execution_id) ? 'disabled text-gray3' : 'text-dark cursorPointer'}`}>
                                                                    <i className="fal fa-book-open" onClick={() => this.onClickAction(cellInfo.row.original, 'view')}></i>
                                                                </p>
                                                                {/* <p className="text-dark m-0 p-0 cursorPointer">
                                                                    <i className="fal fa-user-edit" onClick={() => this.props.onClickAction(cellInfo.row.original, 'edit') }></i>
                                                                </p>
                                                                <p className="text-dark m-0 p-0 cursorPointer">
                                                                    {this.state.showDeleteLoading && cellInfo.row.original.doc_id === this.state.selectedWatermarkId ? 
                                                                        <Spinner color='dark' size='sm' />
                                                                    :
                                                                        <i className="fal fa-trash" onClick={() => this.setState({ selectedWatermarkId: cellInfo.row.original.doc_id }, () => this.deleteConfirmation(cellInfo.row.original.doc_id))}></i>
                                                                    }
                                                                </p> */}
                                                            </div>
                                                        ),
                                                        width:120
                                                    }
                                                ]}
                                                data={this.state.filteredRecentList ? this.state.filteredRecentList : []}
                                                perPage={10}
                                                dashboard={this.state.filteredRecentList ? this.state.filteredRecentList.length : 0}
                                                sortByColumn={'Date'}
                                                tooltipFor={[2]}
                                                onClickRow={tableRow => {}}
                                                tableSize={'table-striped'}
                                            />
                                        </div>
                                    : !this.state.recentLoading && this.state.recentQueryData && !this.state.recentQueryData.length ?
                                        <div className='col-lg-12 d-flex justify-content-center m-4'>
                                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                        </div>
                                    : this.state.recentLoading ?
                                        <div className='col-lg-12 d-flex justify-content-center m-4'>
                                            <Spinner color='dark' size='lg' />
                                        </div>
                                    : null
                                : null}    
                            </div>
                        </div>
                    </div>
                </div>
            </div>              
        )
    }
}

/**
 * Type of the props used in the component
 */
QueryEditor.propTypes = {
    listAllAccounts: PropTypes.func,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log('resource', state)
    return {
        accounts: state.filters.accounts,
    }
}

export default AppWrapper(QueryEditor, mapStateToProps, {
    listAllAccounts,
    listAthenaResources,
    invokeQuery,
    getQueryResult,
    listQueryLog,
    downlaodQueryResult
})
