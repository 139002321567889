/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Metrics.js
 * @author Prakash // on 22/02/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { momentDateGivenFormat, momentConvertionUtcToLocalTime } from '../../../utils/utility'
import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'
import { listRcaDetails, getRcaDetails } from '../../../actions/aiops/RootCauseAction'

import ApexSimpleProgressChart from '../../common/charts/ApexSimpleProgressChart'
// import { Index } from 'pondjs'

class RootCauseLeftSection extends Component {
	rootCauseSlider = React.createRef()
    
    constructor(props) {

        super(props)
        this.scrollRef = React.createRef()

        this.state = {
            selectedRca: 0,
            showLoading: true
        }
    }

	componentDidMount = () => {
        if(!this.props.aiopsRcaList || !this.props.aiopsRcaList.length) {
            let params = {}
            params.case_id = this.props.caseDetails.case_id
            this.props.listRcaDetails(params, (promise, response) => {
                if(promise) {
                    let orderedResponse = []
                    if(response.results && response.results.length) {
                        console.log('response.results', response.results)
                        orderedResponse = _.orderBy(response.results, ['rca_end_time'], ['desc'])
                    }
                    this.setState({ rootCauseList: orderedResponse, rca_id: orderedResponse.length ? orderedResponse[0].rca_id : '', showTimeline: true },
                        () => {
                            this.getRcaDetails()
                            this.slideRight('initialize')
                            window.addEventListener('resize', () => {
                                this.setState({ showTimeline : false },
                                    () => this.setState({ showTimeline: true, totalItemShowing: 0 }, () => this.slideRight('initialize'))    
                                )
                            })
                        }    
                    )
                } else {
                    this.setState({ rootCauseList: {}, showLoading: false })
                }
            })
        } else {
            let orderedResponse = this.props.aiopsRcaList
            if(this.props.aiopsRcaList && this.props.aiopsRcaList.length) {
                orderedResponse = _.orderBy(this.props.aiopsRcaList, ['rca_end_time'], ['desc'])
            }
            this.setState({ rootCauseList: orderedResponse, rca_id: orderedResponse.length ? orderedResponse[0].rca_id : '', showTimeline: true },
                () => {
                    this.getRcaDetails('')
                    this.slideRight('initialize')
                    window.addEventListener('resize', () => {
                        this.setState({ showTimeline : false },
                            () => this.setState({ showTimeline: true, totalItemShowing: 0 }, () => this.slideRight('initialize'))    
                        )
                    })
                }    
            )
        }

        this.barChart()
        this.anomalyChart()
        this.progressBar()

        setTimeout(() => { this.setState({ showLoading: false }) }, 5000)
    }

    getRcaDetails = (rca_id) => {
        // if(rca_id || (!this.props.aiopsRcaDetails || !Object.entries(this.props.aiopsRcaDetails).length)) {
            let params = {}
            params.rca_id = this.state.rca_id
            params.case_id = this.props.caseDetails.case_id
            this.props.getRcaDetails(params, (promise, response) => {
                if(promise) {
                    this.setState({ rootCauseDetails: response.result, showLoading: false },
                        () => {
                            this.props.setAiopsPropsDetails('selectedRootCauseEvent', {})
                            this.filterRcaDetils()
                        }
                    )
                } else {
                    this.setState({ rootCauseDetails: {}, showLoading: false })
                }
            })
        // } else {
        //     this.setState({ rootCauseDetails: this.props.aiopsRcaDetails, showLoading: false },
        //         () => {
        //             this.props.setAiopsPropsDetails('selectedRootCauseEvent', {})
        //             this.filterRcaDetils()
        //         }
        //     )
        // }
    }
    
    filterRcaDetils = () => {
        let probableRootCauses = []
        let prediction = []
        if(this.state.rootCauseDetails && this.state.rootCauseDetails.length) {
            let assets = this.state.rootCauseDetails[0].assets ? this.state.rootCauseDetails[0].assets : this.props.assetsList
            
            this.state.rootCauseDetails[0].rca && this.state.rootCauseDetails[0].rca.forEach(item => {
                item.data.forEach(detail => {
                    if(item.label === 'Database Changes') {
                        Object.entries(detail).forEach(([key, value]) => {
                            if(key === 'index_changes' || key === 'other_changes' || key === 'table_changes' || key === 'view_changes') {
                                let dataRow = {}
                                dataRow.label = item.label
                                dataRow.data = detail
                                dataRow.key = key
                                dataRow.count = value.statistics.reduce(function(a, b){ return a + b['count']; }, 0);
                                dataRow.action = dataRow.count > 1 ? 'Changes' : 'Change'
                                dataRow.description = value.description
                                dataRow.details = value.details
                                dataRow.rca_score = detail.rca_score ? detail.rca_score : 0
                                dataRow.tableHeading = (key === 'index_changes' ? 'Index Changes' : key === 'table_changes' ? 'Table Changes' : key === 'view_changes' ? 'View Changes' : key === 'other_changes' ? 'Other Changes' : '')

                                let asset_id = detail.asset_id ? detail.asset_id : detail.sre_asset_id ? detail.sre_asset_id : ''
                                let asset = assets.filter(e => e.asset_id === asset_id)
                                dataRow.asset_name = asset.length ? asset[0].asset_name : ''

                                probableRootCauses.push(dataRow)
                            }
                        })
                    } else if(item.label === 'Metric Observations') {
                        let dataRow = {}
                        dataRow.label = item.label
                        dataRow.data = detail
                        dataRow.description = ''
                        dataRow.rca_score = detail.rca_score ? detail.rca_score : 0

                        let asset_id = detail.asset_id ? detail.asset_id : detail.sre_asset_id ? detail.sre_asset_id : ''
                        let asset = assets.filter(e => e.asset_id === asset_id)
                        dataRow.asset_name = asset.length ? asset[0].asset_name : ''
                        // probableRootCauses.push(dataRow)
                    } else {
                        let dataRow = {}
                        dataRow.label = item.label
                        dataRow.data = detail
                        dataRow.description = ''
                        dataRow.rca_score = detail.rca_score ? detail.rca_score : 0

                        let asset_id = detail.asset_id ? detail.asset_id : detail.sre_asset_id ? detail.sre_asset_id : ''
                        let asset = assets.filter(e => e.asset_id === asset_id)
                        dataRow.asset_name = asset.length ? asset[0].asset_name : ''
                        probableRootCauses.push(dataRow)
                    }
                })
            })
            // this.state.rootCauseDetails[0].prediction && this.state.rootCauseDetails[0].prediction.forEach(item => {
            //     item.data.forEach(dat => {                    
            //         dataRow.label = item.label
            //         dataRow.data = dat
            //         let asset_id = dat.asset_id ? dat.asset_id : dat.sre_asset_id ? dat.sre_asset_id : ''
            //         let asset = this.props.assetsList.filter(e => e.asset_id === asset_id)
            //         prediction.push(dat)
            //     })
            // })
        }
        console.log('probableRootCauses', probableRootCauses)
        this.setState({ probableRootCauses: _.orderBy(probableRootCauses, ['rca_score'], ['desc']), prediction },
            () => {
                if(this.state.probableRootCauses.length) {
                    this.onChangeRcaEvent(this.state.probableRootCauses[0])
                }
            }
        )
    }

    onChangeRcaEvent = (selectedEvent) => {
        console.log('selectedEvent', selectedEvent)
        let asset_id = selectedEvent.data && selectedEvent.data.asset_id ? selectedEvent.data.asset_id : (selectedEvent.data.sre_asset_id ? selectedEvent.data.sre_asset_id : '')

        let asset_details = this.props.assetsList.filter(e => e.asset_id === asset_id)

        let progressValue = selectedEvent.rca_score ? selectedEvent.data.rca_score : 0

        let obj = {}
        obj.label = selectedEvent.label
        obj.asset_details = asset_details.length ? asset_details[0] : {}
        obj.tableHeading = selectedEvent.tableHeading ? selectedEvent.tableHeading : ''
        obj.progressValue = progressValue.toString()

        let selectedRootCause = ''
        // let tableHeading = ''
        if(selectedEvent.label === 'Database Changes') {
            obj.data = selectedEvent
            selectedRootCause = selectedEvent
            
        } else if(selectedEvent.label === 'Application Changes' || selectedEvent.label === 'Capacity Changes' || selectedEvent.label === 'Health Events' || selectedEvent.label === 'Maintenance and Scheduled Events' || selectedEvent.label === 'Infra Changes' || selectedEvent.label === 'SRE Observations' || selectedEvent.label === 'DB SlowQuery Events' || selectedEvent.label === 'Connectivity Checks' ) {
            selectedRootCause = selectedEvent
            obj.data = selectedEvent.data
        }
        this.setState({ 
            selectedRootCause,
            selectedRootCauseEvent: selectedEvent.data 
        },
            () => this.props.setAiopsPropsDetails('selectedRootCauseEvent', obj)
        )
    }


    barChart = () => {
        let array = [['2021-5-01 00:00:00', 5], ['2021-5-02 00:00:00', 0], ['2021-5-03 00:00:00', 0], ['2021-5-04 00:00:00', 5], ['2021-5-05 00:00:00', 5], ['2021-5-06 00:00:00', 0], ['2021-5-07 00:00:00', 0], ['2021-5-08 00:00:00', 5], ['2021-5-09 00:00:00', 0], ['2021-5-10 00:00:00', 5]]
                    
        let barSeries = [{
            name: 'Bar',
            data: array,
        }]

        let barOptions = {
            chart: {
                type: 'bar',
                height: 100,
                sparkline: {
                    enabled: true
                },
                toolbar: {
                    show: false
                },
                redrawOnParentResize: true
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    barHeight: '100%',
                    columnWidth: '20%',
                    distributed: false,
                    colors: {
                        backgroundBarColors: ['#E0E0E0'],
                        backgroundBarOpacity: 1,
                        backgroundBarRadius: 0,
                    }
                }
            },
            colors: ['#19B2C0'],
            stroke: {
                width: 0
            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 0
            },
            xaxis: {
                show: true,
                type: 'datetime',
                labels: {
                    datetimeUTC: false
                },
            },
            yaxis: {
                show: false,
            },
            grid: {
                xaxis: {
                    lines: {
                        show: false
                    },
                },
                yaxis: {
                    lines: {
                        show: false
                    }
                },
            },
            tooltip: {
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    title: {
                    formatter: (seriesName) => seriesName,
                    }
                },
                marker: {
                    show: false
                },
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    let val = w.globals.initialSeries[0].data[dataPointIndex][1]
                    let dateLabel = momentDateGivenFormat(w.globals.initialSeries[0].data[dataPointIndex][0], 'MMM DD')
                    // let labelName = w.globals.initialSeries[0].event_id
                    // let fontColor = '#693EBC'

                    return '<div class="arrow_box">'+ dateLabel +'<span style="color:#693EBC"> '+ val +'</span></div>'
                }
            }
        }
        this.setState({ barSeries, barOptions})
    }

    anomalyChart = () => {
        
        let array = [['2021-5-01 00:00:00', 5], ['2021-5-02 00:00:00', 0], ['2021-5-03 00:00:00', 0], ['2021-5-04 00:00:00', 5], ['2021-5-05 00:00:00', 5], ['2021-5-06 00:00:00', 0], ['2021-5-07 00:00:00', 0], ['2021-5-08 00:00:00', 5], ['2021-5-09 00:00:00', 0], ['2021-5-10 00:00:00', 5]]
                    
        let anomalySeries = [{
            name: 'Anomaly',
            data: array,
        }]

        let anomalyOptions = {
            chart: {
                type: 'bar',
                height: 100,
                sparkline: {
                    enabled: true
                },
                toolbar: {
                    show: false
                },
                redrawOnParentResize: true
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    barHeight: '100%',
                    columnWidth: '20%',
                    distributed: false,
                }
            },
            colors: ['#F75E3F'],
            stroke: {
                width: 0
            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 0
            },
            xaxis: {
                show: true,
                type: 'datetime',
                labels: {
                    datetimeUTC: false
                },
            },
            yaxis: {
                show: false,
            },
            tooltip: {
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    title: {
                    formatter: (seriesName) => seriesName,
                    }
                },
                marker: {
                    show: false
                },
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    // let val = w.globals.initialSeries[0].data[dataPointIndex][1]
                    let dateLabel = momentDateGivenFormat(w.globals.initialSeries[0].data[dataPointIndex][0], 'MMM DD')
                    // let labelName = w.globals.initialSeries[0].event_id
                    // let fontColor = '#F75E3F'

                    // return '<div class="arrow_box">'+ dateLabel +'<span style="color:#F75E3F">' + '  '+ val +'</span></div>'
                    return '<div class="arrow_box">Anomaly<span style="color:#F75E3F"> '+dateLabel +'</span></div>'
                }
            }
        }
        
        this.setState({ anomalySeries, anomalyOptions})
    }
    
    slideLeft() {
		let move = 0
		move = parseInt(this.state.totalItemShowing)
		this.setState({ totalItemShowing: this.state.totalItemShowing - 1 })
		
		let totalRootCauses = this.state.rootCauseList && this.state.rootCauseList.length;
		let reqTabWidth = 0
		for (let i = 0; i < totalRootCauses; i++) {
			reqTabWidth += this.rootCauseSlider.current.childNodes[i].getBoundingClientRect().width + 30
		}
		let averageSliderItemWidth = reqTabWidth/totalRootCauses
		let sliderWrapWidth = this.rootCauseSlider.current.getBoundingClientRect().width        
		let totalItemShowing = sliderWrapWidth/averageSliderItemWidth;
        totalItemShowing = parseInt(totalItemShowing)
		if(move > totalItemShowing) {
			if (this.rootCauseSlider.current.childNodes[move-1] && this.rootCauseSlider.current.childNodes[move-1].getBoundingClientRect().width) {
				this.rootCauseSlider.current.scrollLeft -= this.rootCauseSlider.current.childNodes[move-1].getBoundingClientRect().width + 30
                this.setState({ disableRightSlider: true, disableLeftSlider: false })
                // this.setState({ showingRightIcon: true, showingOff: parseInt(this.state.showingOff - 1), showingOffStart: this.state.showingOffStart < 1 ? 1 : parseInt(this.state.showingOffStart-1)})
                // this.setState({ showingRightIcon: true, showingOff: parseInt(this.state.showingOff - 1), showingOffStart: this.state.showingOffStart < 1 ? 1 : parseInt(this.state.showingOffStart-1)})
                // this.rootCauseSlider.current.scrollLeft += this.rootCauseSlider.current.childNodes[move-1].getBoundingClientRect().width
			} else {
				// this.setState({ showingLeftIcon: false, totalItemShowing: totalItemShowing })
                this.setState({ totalItemShowing })
			}
		} else {
			// this.setState({ showingLeftIcon: false, totalItemShowing: totalItemShowing })
            this.setState({ totalItemShowing })
		}
	}

	slideRight(item) {
		let totalRootCauses = this.state.rootCauseList && this.state.rootCauseList.length;
        
        let averageSliderItemWidth = 0
		let reqTabWidth = 0
		for (let i = 0; i < totalRootCauses; i++) {
            reqTabWidth += this.rootCauseSlider.current.childNodes[i].getBoundingClientRect().width + 30
            // averageSliderItemWidth = reqTabWidth //enable if all tab has same width
		}
		averageSliderItemWidth = reqTabWidth/totalRootCauses //remove or comment if all tab are same with
		let sliderWrapWidth = this.rootCauseSlider.current.getBoundingClientRect().width
		let totalItemsPerPage = sliderWrapWidth/averageSliderItemWidth;
        let totalItemShowing = parseInt(totalItemsPerPage)
        if(totalItemShowing > totalRootCauses) {
            this.setState({ disableRightSlider: true })
        } else {
            this.setState({ disableRightSlider: false, disableLeftSlider: true })
        }
		let move = 0
		if(this.state.totalItemShowing === 0) {
			this.setState({ totalItemShowing })
			move = totalItemShowing
		} else {
			move = parseInt(this.state.totalItemShowing + 1)
			this.setState({ totalItemShowing: this.state.totalItemShowing + 1, disableLeftSlider: true })
		}

		if (this.rootCauseSlider.current.childNodes[move-1] && this.rootCauseSlider.current.childNodes[move-1].getBoundingClientRect().width) {
            if(item === '') {
                this.rootCauseSlider.current.scrollLeft += this.rootCauseSlider.current.childNodes[move-1].getBoundingClientRect().width + 30
                if(move > totalItemsPerPage) {
                    this.setState({ disableRightSlider: true })
                }
            }
			// if(!this.state.showingOff && this.state.showingOff < totalItemShowing) {
			// 	this.setState({ showingOff: parseInt(totalItemShowing), showingLeftIcon: true })
			// } else{
			// 	this.setState({ showingOffStart: (this.state.showingOffStart + 1), showingOff: parseInt(this.state.showingOff + 1), showingLeftIcon: true })
			// }

            // 	//const sliderCenter = sliderWrapWidth / 2 - 110
            // 	//const left = index * 110 - sliderCenter
            // 	//this.rootCauseSlider.current.scrollLeft = left		
                
        } else {
            this.setState({ totalItemShowing: this.state.rootCauseList.length })
		}
	}

    progressBar = () => {
        let progressSeries = [75]
        let progressOptions = {
            chart: {
                height: 75,
                width: 75,
                type: 'radialBar',
                sparkline: {
                    enabled: true
                },
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                radialBar: {
                    // startAngle: -135,
                    // endAngle: 225,
                    hollow: {
                        margin: 0,
                        size: '70%',
                        background: '#fff',
                        image: undefined,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        position: 'front',
                        dropShadow: {
                            enabled: true,
                            top: 3,
                            left: 0,
                            blur: 4,
                            opacity: 0.24
                        }
                    },
                    track: {
                        background: '#fff',
                        strokeWidth: '67%',
                        margin: 0, // margin is in pixels
                        dropShadow: {
                            enabled: true,
                            top: 0,
                            left: 0,
                            blur: 4,
                            opacity: 0.35
                        }
                    },
                
                    dataLabels: {
                        show: true,
                        name: {
                            offsetY: 0,
                            show: false,
                            color: '#888',
                            fontSize: '14px'
                        },
                        value: {
                            offsetY: 5,
                            formatter: function(val) {
                                return parseInt(val);
                            },
                            color: '#111',
                            fontSize: '14px',
                            show: true,
                        }
                    }
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    type: 'horizontal',
                    shadeIntensity: 0.5,
                    gradientToColors: ['#ABE5A1'],
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100]
                }
            },
            stroke: {
                lineCap: 'round'
            },
            labels: ['']
        }
        this.setState({ progressSeries, progressOptions })
    }

	render() {
		return (
            <div className='col-md-12 p-3'>
				<div className={`loaderOverlay ${this.state.showLoading ? '' : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
                <div className={`d-flex ${this.state.showTimeline ? '' : 'displayNone'}`}>
                    <div className="align-self-center">
                        <span className={`fas fa-chevron-left ${this.state.disableLeftSlider ? 'disabled' : 'cursorPointer'}`} onClick={() => this.slideLeft()}></span>
                    </div>
                    <div className="stepper" ref={this.rootCauseSlider}>
                        {this.state.rootCauseList && this.state.rootCauseList.length ?
                            this.state.rootCauseList.map(item => {
                                return(
                                    <div className={`${this.state.rca_id === item.rca_id ? 'bg-info text-white' : 'bg-secondary cursorPointer'} px-2  pt-2p rounded`} onClick={() => this.setState({ rca_id: item.rca_id, rootCauseDetails: {}, showLoading: true }, () => this.getRcaDetails(item.rca_id))}>{item.rca_end_time ? momentConvertionUtcToLocalTime(item.rca_end_time, 'DD MMM HH:mm') : ''}</div>
                                )
                            })
                        : null}
                    </div>
                    <div className="align-self-center">
                        <span className={`fas fa-chevron-right ${this.state.disableRightSlider ? 'disabled' : 'cursorPointer'}`} onClick={() => this.slideRight('')}></span>
                    </div>
                </div>
                <div className=" mt-2">
                    <p className="mb-0 f16 text-white">Probable Root Cause Analysis</p>
                    <p className="mb-1 f12">adipiscing elit, sed do eiusmod tempor incididunt ut)</p>
                    <div className="rounded bg-dark p-3">
                        <div class="d-flex">
                            <p class="mb-0 f16 text-white align-self-end">Root Causes</p>
                            <p class="mb-0 ml-3 f12 align-self-end">Showing the RVC result</p>
                        </div>
                        
                        <div class="row mt-2">
                            {this.state.probableRootCauses && this.state.probableRootCauses.length ? 
                            this.state.probableRootCauses.map((item, index) => {
                                return(
                                    <div class={`col-lg-6 mb-2 cursorPointer ${index%2 !== 0 ? '' : ''}`} onClick={() => this.setState({ selectedRca: index }, () => this.onChangeRcaEvent(item))}>
                                        <div class={`p-2 ${this.state.selectedRca === index ? 'bg-muted' : 'bg-dark3'} rounded`}>
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <p class="my-0 align-self-center text-white">{item.label}</p>
                                                    {item.label === 'Database Changes' ?
                                                        <p className="small">
                                                            {item.description ? item.description : ''}
                                                        </p>
                                                    : item.label === 'Health Events' ?
                                                        <p className="f12 m-0">
                                                            {/* <p className="f16 m-0">{dig.health_event_service +' : ' +dig.health_event_category +' '}</p> */}
                                                            {item.data.health_event_description ? item.data.health_event_description : ''}
                                                            {item.data.health_event_time ? (' on '+momentConvertionUtcToLocalTime(item.data.health_event_time, 'MMM DD HH:mm:ss')) : ''}
                                                        </p>
                                                    : item.label === 'Maintenance and Scheduled Events' ?
                                                        <p className="f12 m-0">
                                                            {/* <p className="f16 m-0">{dig.maintsch_event_service +' : ' +dig.maintsch_event_category +' '}</p> */}
                                                            {item.data.maintsch_event_description ? item.data.maintsch_event_description : ''}
                                                            {item.data.maintsch_event_service ? (' on '+momentConvertionUtcToLocalTime(item.data.maintsch_event_service, 'MMM DD HH:mm:ss')) : ''}
                                                        </p>
                                                    : item.label === 'SRE Observations' ?
                                                        <p className="small">
                                                            {/* <p className="text-warning 20 m-0">
                                                                {item.data.sre_events_count && item.data.sre_events_count}
                                                                {dig.sre_events_count ? (dig.sre_events_count > 1 ? 'events' : 'event') : 'event'}
                                                            </p> */}
                                                            {item.data.sre_resource_type ? item.data.sre_resource_type : ''}
                                                            {item.data.sre_event_description ? ' '+item.data.sre_event_description : ''}
                                                        </p>
                                                    :
                                                        <p className="small">
                                                            {item.description ? item.description : ''}
                                                            {item.asset_name ? (' in '+item.asset_name) : ''}
                                                            {(item.data.event_time || item.data.sre_event_time) ? (' on '+momentConvertionUtcToLocalTime((item.data.event_time ? item.data.event_time : item.data.sre_event_time), 'DD MMM YYYY HH:mm')) : ''}
                                                        </p>
                                                    }
                                                </div>
                                                <ApexSimpleProgressChart
                                                    value={item.rca_score ? item.rca_score : 0}
                                                    valueoffsetY={-12}
                                                    height={65}
                                                    width={65}
                                                    size={'60%'}
                                                    strokeWidth={'100%'}
                                                    label={''}
                                                    labeloffsetY={0}
                                                    colors={['#24A597']}
                                                    strokeColor={'#353C4C'}
                                                    trackMargin={0}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            : null}
                        </div>
                    </div>
                    <div class="borderBottomMiddleBlue m-2 displayNone"></div>
                    <div className="rounded bg-dark p-3 mt-2 displayNone">
                        <div class="d-flex">
                            <p class="mb-0 f16 text-white align-self-end">Prediction</p>
                            <p class="mb-0 ml-3 f12 align-self-end">Showing the prediction result</p>
                        </div>
                        {/* <div class="row mt-2">
                            {this.state.prediction && this.state.prediction.length ? 
                            this.state.prediction.forEach((item, index) => {
                                if(item.label !== 'Connectivity Checks' && item.label !== 'Health Events' && item.label !== 'DB SlowQuery Events') {
                                    let val = (index +1) * 15;
                                    return(
                                        this.state['filtered_diagnostic_data_'+index].slice(0,1).map((dig, i) => {
                                            return(
                                            item.label === 'Application Changes' ?
                                                <div class={`col-lg-6 mb-2 cursorPointer ${index%2 !== 0 ? 'pl-0' : ''}`} onClick={() => this.onChangeRca(item.label, dig, index, 'app_changes_'+index+'_'+i, dig.asset_id, '')}>
                                                    <div class="p-2 bg-dark3 rounded">
                                                        <div class="d-flex justify-content-between">
                                                            <div>
                                                                <p class="my-0 align-self-center text-white">{item.label}</p>      
                                                                <p className="small">
                                                                    {dig.description ? dig.description : ''}
                                                                    {dig.asset_name ? (' in '+dig.asset_name) : ''}
                                                                    {dig.event_time ? (' on '+momentConvertionUtcToLocalTime(dig.event_time, 'DD MMM YYYY HH:mm')) : ''}
                                                                </p>
                                                            </div>
                                                            <ApexSimpleProgressChart
                                                                value={val}
                                                                valueoffsetY={-12}
                                                                height={65}
                                                                width={65}
                                                                size={'60%'}
                                                                strokeWidth={'100%'}
                                                                label={''}
                                                                labeloffsetY={0}
                                                                colors={['#775BA2']}
                                                                strokeColor={'#353C4C'}
                                                                trackMargin={0}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            : item.label === 'Database Changes' ?
                                                <div class={`col-lg-6 mb-2 cursorPointer ${index%2 !== 0 ? 'pl-0' : ''}`} onClick={() => this.onChangeRca(item.label, dig, index, 'db_'+index+'_'+i, dig.asset_id, dig.key)}>
                                                    <div class="p-2 bg-dark3 rounded">
                                                        <div class="d-flex justify-content-between">
                                                            <div>
                                                            <p class="my-0 align-self-center text-white">{item.label}</p>      
                                                            <p className="small">
                                                                {dig.description ? dig.description : ''}
                                                            </p>
                                                            </div>
                                                            <ApexSimpleProgressChart
                                                                value={val}
                                                                valueoffsetY={-12}
                                                                height={65}
                                                                width={65}
                                                                size={'60%'}
                                                                strokeWidth={'100%'}
                                                                label={''}
                                                                labeloffsetY={0}
                                                                colors={['#775BA2']}
                                                                strokeColor={'#353C4C'}
                                                                trackMargin={0}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            : item.label === 'Maintenance and Scheduled Events' ?
                                                <div class={`col-lg-6 mb-2 cursorPointer ${index%2 !== 0 ? 'pl-0' : ''}`} onClick={() => this.onChangeRca(item.label, dig, index, 'maintsch_event_'+index+'_'+i, '', '')}>
                                                    <div class="p-2 bg-dark3 rounded">
                                                        <div class="d-flex justify-content-between">
                                                            <div>
                                                                <p class="my-0 align-self-center text-white">{item.label}</p>      
                                                                <p className="small">
                                                                {dig.maintsch_event_description ? dig.maintsch_event_description : ''}
                                                                {dig.maintsch_event_time ? (' on '+momentConvertionUtcToLocalTime(dig.maintsch_event_time, 'DD MMM YYYY HH:mm')) : ''}
                                                            </p>
                                                            </div>
                                                            <ApexSimpleProgressChart
                                                                value={val}
                                                                valueoffsetY={-12}
                                                                height={65}
                                                                width={65}
                                                                size={'60%'}
                                                                strokeWidth={'100%'}
                                                                label={''}
                                                                labeloffsetY={0}
                                                                colors={['#775BA2']}
                                                                strokeColor={'#353C4C'}
                                                                trackMargin={0}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            : item.label === 'Capacity Changes' ?
                                                <div class={`col-lg-6 mb-2 cursorPointer ${index%2 !== 0 ? 'pl-0' : ''}`} onClick={() => this.onChangeRca(item.label, dig, index, 'capacity_changes_'+index+'_'+i, dig.asset_id, '')}>
                                                    <div class="p-2 bg-dark3 rounded">
                                                        <div class="d-flex justify-content-between">
                                                            <div>
                                                                <p class="my-0 align-self-center text-white">{item.label}</p>      
                                                                <p className="small">
                                                                    {dig.event_description ? dig.event_description : ''}
                                                                    {dig.event_time ? (' on '+momentConvertionUtcToLocalTime(dig.event_time, 'DD MMM YYYY HH:mm')) : ''}
                                                                </p>
                                                            </div>
                                                            <ApexSimpleProgressChart
                                                                value={val}
                                                                valueoffsetY={-12}
                                                                height={65}
                                                                width={65}
                                                                size={'60%'}
                                                                strokeWidth={'100%'}
                                                                label={''}
                                                                labeloffsetY={0}
                                                                colors={['#775BA2']}
                                                                strokeColor={'#353C4C'}
                                                                trackMargin={0}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            : item.label === 'Infra Changes' ?
                                                <div class={`col-lg-6 mb-2 cursorPointer ${index%2 !== 0 ? 'pl-0' : ''}`} onClick={() => this.onChangeRca(item.label, dig, index, 'infra_changes_'+index+'_'+i, dig.asset_id, '')}>
                                                    <div class="p-2 bg-dark3 rounded">
                                                        <div class="d-flex justify-content-between">
                                                            <div>
                                                                <p class="my-0 align-self-center text-white">{item.label}</p>      
                                                                <p className="small">
                                                                {dig.event_description ? dig.event_description : ''}
                                                                {dig.event_time ? (' on '+momentConvertionUtcToLocalTime(dig.event_time, 'DD MMM YYYY HH:mm')) : ''}</p>
                                                            </div>
                                                            <ApexSimpleProgressChart
                                                                value={val}
                                                                valueoffsetY={-12}
                                                                height={65}
                                                                width={65}
                                                                size={'60%'}
                                                                strokeWidth={'100%'}
                                                                label={''}
                                                                labeloffsetY={0}
                                                                colors={['#775BA2']}
                                                                strokeColor={'#353C4C'}
                                                                trackMargin={0}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            : item.label === 'SRE Observations' ?
                                                <div class={`col-lg-6 mb-2 cursorPointer ${index%2 !== 0 ? 'pl-0' : ''}`} onClick={() => this.onChangeRca(item.label, dig, index, 'sre_events_'+index+'_'+i, dig.sre_asset_id, '')}>
                                                    <div class="p-2 bg-dark3 rounded">
                                                        <div class="d-flex justify-content-between">
                                                            <div>
                                                                <p class="my-0 align-self-center text-white">{item.label}</p>      
                                                                <p className="small">
                                                                {dig.sre_event_description ? dig.sre_event_description : ''}
                                                            </p>
                                                            </div>
                                                            <ApexSimpleProgressChart
                                                                value={val}
                                                                valueoffsetY={-12}
                                                                height={65}
                                                                width={65}
                                                                size={'60%'}
                                                                strokeWidth={'100%'}
                                                                label={''}
                                                                labeloffsetY={0}
                                                                colors={['#775BA2']}
                                                                strokeColor={'#353C4C'}
                                                                trackMargin={0}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            : null
                                            )
                                        })
                                    )
                                }
                            })
                            : null}
                        </div> */}
                    </div>
                </div>
            </div>
		)
	}
}
/**
 * Type of the props used in the component
 */
RootCauseLeftSection.propTypes = {
    listRcaDetails: PropTypes.func,
    getRcaDetails: PropTypes.func,
    setAiopsPropsDetails: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
	// console.log('state', state)
	return {
        caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : {},
        aiopsPropsDetails: state.aiops.aiopsPropsDetails ? state.aiops.aiopsPropsDetails : {},
        aiopsRcaList: state.aiops.aiopsRcaList && state.aiops.aiopsRcaList.results ? state.aiops.aiopsRcaList.results : [],
        aiopsRcaDetails: state.aiops.aiopsRcaDetails && state.aiops.aiopsRcaDetails.result ? state.aiops.aiopsRcaDetails.result : [],
        assetsList: state.aiops.caseDetails.results && state.aiops.caseDetails.results.assets ? state.aiops.caseDetails.results.assets : [],
    }
}

export default connect(mapStateToProps, {
    listRcaDetails,
    getRcaDetails,
    setAiopsPropsDetails,
})(withRouter(RootCauseLeftSection))