/*************************************************
 * Tvastar
 * @exports
 * @file CloseForm.js
 * @author Rajasekar // on 09/01/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Col, Row, FormGroup, Label, Input, Spinner } from 'reactstrap'
import { resolveIssue } from '../../../actions/detectors/detectorsAction'

class CloseForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			closeStatus: 'Resolved',
			closeStatusType: '',
			closeAttachment: '',
			closeAttachmentKey: false,
			
			selectedProvider: this.props.selectedTableRow.provider.toLowerCase(),
			selectedAccount: this.props.selectedTableRow.account_id,
			selectedRegion: this.props.selectedTableRow.region,
			selectedDuration: this.props.selectedDuration,
			assetId: this.props.selectedTableRow.asset_id,
			
			responseMessage: '',
			showMessage: false,
			showLoading: false,
			hasErrorInRuleForm: false,
		}
	}

	componentDidMount = () => {
	console.log(this.props.selectedTableRow)
	}
	
	/**
	 * Handles on submit new rule form
	 */
	onSubmit = () => {
		this.setState({ showLoading: true, responseMessage: '' })

		let params = {}
		params.status = this.state.closeStatus
		params.sub_status =  this.state.closeStatusType
		params.identifiers = [{"checksum": this.props.selectedTableRow.checksum,
		"identifier_id": this.props.selectedTableRow.identifier_id}]
		params.resolve_mode = 'mannual'
		params.reason = 'solved manually'
		
		const data = new FormData() 
		
		if(this.state.closeAttachment) {
			data.append('proof', this.state.closeAttachment);
		}
		data.append('request', JSON.stringify(params));

		console.log('formData',data);

		if(!this.state.hasErrorInRuleForm) {
			this.props.resolveIssue(data, (promise, response) => {
				console.log(response)
				this.setState({
					closeStatusType: '',
					closeReason: '',
					closeAttachment: '',
					responseMessage: response.data ? response.data.message : '',
					showMessage: true,
					showLoading: false,
					hasErrorInRuleForm: false,
					responseStatus: response.data ? response.data.status : false,
					//selectedTableRow: response.data && response.data.status ? this.state.selectedTableRow : this.state.selectedTableRow
				}, 
					() => this.hideMessage()
				)
			})
		}
	}

	hideMessage = () => {
		setTimeout(() => {
			this.setState({
				showMessage: false
			});
		}, 5000)
	}

	fileChangedHandler = (event) => {
		this.setState({ hasErrorInRuleForm: false })
		let file_size = event.target.files[0].size;
	
		//or if you like to have name and ty	pe
		// let file_name = event.target.files[0].name;
		let file_type = event.target.files[0].type;
		let file_extension = file_type.split('.')
		if(file_extension.length === 1) {
			file_extension = file_type.split('/')
		}
		file_extension = file_extension[file_extension.length - 1].toLowerCase();
		// let files = event.target.files[0]
		//console.log(files)
		let upload = 1
		let allowedType = ['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png', 'pdf', 'msword', 'txt']
		if(!allowedType.includes(file_extension)) {
			upload = 0
			this.setState({ hasErrorInRuleForm: true, closeAttachment: '', attachmentMessage: 'Make sure your proof type is image / pdf / doc / txt'})
			return false;
		}

		if(file_size > 0) {
			let fileSizeMb = (file_size / 1024) /1024

			if(fileSizeMb > 1) {
				upload = 0
				this.setState({ hasErrorInRuleForm: true, closeAttachment: '', attachmentMessage: 'Proof size should be less than 1mb'})
				return false;
			}
		}
		if(upload === 1) {
			//const data = new FormData() 
			//data.append('file', document);
			//data.append('name', file_name);
			//data.append('file', event.target.files[0])
			this.setState({ closeAttachment: event.target.files[0]  })
		}
	   //do whatever operation you want to do here
	};

	/**
	 * Renders close form
	 */
	render() {
		return (
			<div className='pop-slider'>
				<div className='container-fluid bg-dark'>
					<div className='row pt-2 pb-2'>
						<div className='col-8 pr-0 align-self-center'>
							<h6 className='m-0 text-white'>
								<i
									className='far fa-arrow-left mr-2 cursor-pointer'
									onClick={() => this.props.onClickCloseBackButton(false)}
								/>{' '}
								Close
							</h6>
						</div>
					</div>
				</div>
				<div className='container-fluid overflow-auto'>
					<div className='row'>
						<div className='col-12 mt-3'>
							<Row>
								<Col md={6}>
									<FormGroup>
										<Label>Status</Label>
										<Input
											disabled={true}
											type='select'
											className="form-control-small"
											value={this.state.closeStatus}
											onChange={event => {
												this.setState(
													{
														closeStatus: event.target.value
													}
												)
											}}
										>
											<option value='resolved'>Resolved</option>
										</Input>
									</FormGroup>
								</Col>
								<Col md={6}>
									<FormGroup>
										<Label>Status Type</Label>
										<Input
											type='select'
											className="form-control-small"
											value={this.state.closeStatusType}
											onChange={event => {
												this.setState(
													{
														closeStatusType: event.target.value
													}
												)
											}}
										>
											<option value=''>Select</option>
											<option value='resolved_verified'>Resolved & Verified</option>
											<option value='not_issue'>Not an Issue</option>
											<option value='duplicate'>Duplicate</option>
										</Input>
										{this.state.hasErrorInRuleForm && this.state.selectedStatusType === '' ? (
											<p className='text-danger'>Status Type is required.</p>
										) : null}
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col md={12}>
									<FormGroup>
										<Label>Reason</Label>
										<textarea className="w-100 borderGray" value={this.state.closeReason} onChange={event => this.setState({ closeReason: event.target.value })} rows="2" placeholder='Reason for closing'>
											
										</textarea>
										{this.state.hasErrorInRuleForm && this.state.closeReason === '' ? (
											<p className='text-danger'>Reason is required.</p>
										) : null}
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col md={12}>
									<FormGroup>
										<Label>Proof <span className={`f11 resetBtn ${this.state.closeAttachment !== '' ? 'cursorPointer' : 'displayNone' }`} onClick={()=> this.setState({ closeAttachmentKey: true, closeAttachment: '', hasErrorInRuleForm: false })}>Clear </span></Label>
										<Input
											type='file'
											//accept="image/jpg, image/png"
											className="form-control-sm"
											id="closeAttachmentId"
											key={this.state.closeAttachmentKey &&	this.state.closeAttachment}
											onChange={event => { this.fileChangedHandler(event) }}
										/>
										{this.state.hasErrorInRuleForm && this.state.closeAttachment === '' ? (
											<p className='text-danger f11'>{this.state.attachmentMessage}</p>
										) : (
											''
										)}
									</FormGroup>
								</Col>
								{/* <Col md={2}>
										{console.log(this.state.closeAttachment)}
									<button className={`f11 rfes 'info cursorPointer' : 'displayNone'} `} onClick={()=> this.setState({ closeAttachmentKey: true, closeAttachment: '' })}>Clear </button>
								</Col> */}
							</Row>
					
							{this.state.showMessage ?
								<p className={` ${this.state.responseStatus ? 'text-success' : 'text-danger' }`}>{this.state.responseMessage}</p> 
							: ''}

							<hr />
							<div className='text-right mb-3'>
								<button className='btn btn-sm btn-light mr-3' onClick={() => this.props.onClickCloseBackButton(false)}>
									Back
								</button>
								<button
									className={`btn btn-sm btn-primary`}
									onClick={() => this.onSubmit()}
									disabled={this.state.showLoading}
								>
									{this.state.showLoading && !this.state.hasErrorInRuleForm ? (
										<React.Fragment>
											{'submitting'}
											<Spinner className='text-center ml-2' color='light' size='sm' />{' '}
										</React.Fragment>
									) : 'Submit'
									}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
/**
 * Type of the props used in the component
 */
CloseForm.propTypes = {
	showEditSupress: PropTypes.bool,
	isCreateClicked: PropTypes.bool,
	onClickBackButton: PropTypes.func,
	providersData: PropTypes.array,
	//createSupression: PropTypes.func,
	resolveIssue: PropTypes.func,
}
export default connect(null, {
	resolveIssue,
})(CloseForm)
