/*************************************************
 * Tvastar
 * @exports
 * @file SidePanel.js
 * @author Prakash // on 28/06/2020
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
 import React, { Component } from 'react'
 import { connect } from 'react-redux'
 import { withRouter } from 'react-router-dom'
 import * as d3 from 'd3'
 
 class HorizontalLimitLollipopChart extends Component {
    constructor(props) {
        super(props)
        this.props = props;
        this.state = {}
    }

    componentDidMount = () => {
        let lollipopHorizData = []
        let graphData = this.props.graphData
        let graphDataCount =  this.props.graphData ? this.props.graphData.count : 10

        if(graphData && graphData.data) {
            graphData.data.forEach(item => {
                let dataRow = {}
                dataRow.label = item.label
                dataRow.name = item.item_name
                dataRow.item_count = item.item_count
                dataRow.total_count = graphData.count
                lollipopHorizData.push(dataRow)
            })
        } else if(this.props.dummyData) {
            lollipopHorizData = [
                {label: 'getNodeByDegree', name: 'getNodeByDegree', item_count: 12, total_count: 30},
                {label: 'Expected', name: 'Expected', item_count: 23, total_count: 30},
                {label: 'assigned', name: 'assigned', item_count: 4, total_count: 30},
                {label: 'return', name: 'return', item_count: 8, total_count: 30}
            ]
        }

        // if(lollipopHorizData.length < 4) {
        //     let length = 4 - lollipopHorizData.length
        //     for(let i=1; i <= length; i++) {
        //         let dataRow = {}
        //         if(i==1) {
        //             dataRow.label = ' '
        //         } else if(i==2) {
        //             dataRow.label = '  '
        //         } else if(i==3) {
        //             dataRow.label = '   '
        //         } else if(i==4) {
        //             dataRow.label = '    '
        //         }    
        //         dataRow.label = 'index'+i           
        //         dataRow.name = i.toString()
        //         dataRow.item_count = i
        //         dataRow.total_count = graphData.count ? graphData.count : 10
        //         lollipopHorizData.push(dataRow)
        //     }
        // }

        // console.log('lollipopHorizData', lollipopHorizData)


        if(lollipopHorizData.length) {
            this.setState({ lollipopHorizData, graphDataCount },
                () => this.drawChart()    
            )
        }
    }

    drawChart = () => {
        var margin = {top: 10, right: 30, bottom: 10, left: 10},
        width = this.props.width
        var height = this.props.height - margin.top - margin.bottom;
    
        let circleStroke = 3
        let circleInnerStroke = 4

        let ID = '#'+this.props.ID
        
        // append the svg object to the body of the page
        var svg = d3.select(ID)
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(0," + margin.top + ")");

        let tooltip = d3.select(ID).append("text").attr("id", "tooltip")
    
        var color = d3.scaleOrdinal()
            .range(this.props.colors)
            // .range(["#eb6383", "#fa9191", "#6B486B", "#b4f2e1"]);
        
        // var secodaryColor = d3.scaleOrdinal().range(["#eb6383", "#fa9191", "#6B486B", "#b4f2e1"]);
        
        var x = d3.scaleLinear()
        .domain([0, this.state.graphDataCount])
        .range([ 0, width]);
    
        svg.append("g")
            .attr("transform", "translate(0," + height + ")")
            // .call(d3.axisBottom(x))
            .selectAll("text")
            .attr("transform", "translate(-10,0)rotate(-45)")
            .style("text-anchor", "top");
    
        // Y axis
        var y = d3.scaleBand()
            .range([ 0, height ])
            .domain(this.state.lollipopHorizData.map(function(d) { return d.label; }))
            .padding(1);
        // svg.append("g")
        //   .call(d3.axisLeft(y))
    
    
        
    
        // Circles
        svg.selectAll("mycircle")
            .data(this.state.lollipopHorizData)
            .enter()
            .append("circle")
                .attr('class', 'firstCircle')
                .attr("cx", function(d) { return x(0) })
                .attr("cy", function(d) { return y(d.label); })
                .attr("r", "6")
                .style("fill", "none")
                // .attr("stroke", "black")
                .attr("stroke-width", circleStroke)
                .attr('stroke', function(d, i) {
                    return color(i);
                })
                .on("mouseover", (event, d) => {     
                    tooltip.style("display", "block")
                })    
                .on("mouseout", d => {     
                    tooltip.style("display", "none")
                })
                .on("mousemove", (event, d) => {
                    let tooltipContent = d.label+' '+d.item_count
                    tooltip.text(tooltipContent).style('transform', `translate(${event.offsetX - 90}px, ${event.offsetY - (height + (this.props.dashboardTooltipHeight ? this.props.dashboardTooltipHeight : 70))}px)`);
                })
            
        svg.selectAll("mycircle")
            .data(this.state.lollipopHorizData)
            .enter()
            .append("circle")
                .attr('class', 'firstCircle')
                .attr("cx", function(d) { return x(0); })
                .attr("cy", function(d) { return y(d.label); })
                .attr("r", "1")
                .style('fill', function(d, i) {
                    return color(i);
                })
                // .attr("stroke", "black")
                .attr("stroke-width", circleInnerStroke)
                .attr('stroke', function(d, i) {
                    return color(i);
                })              
                .on("mouseover", (event, d) => {     
                    tooltip.style("display", "block")
                })    
                .on("mouseout", d => {     
                    tooltip.style("display", "none")
                })
                .on("mousemove", (event, d) => {
                    let tooltipContent = d.label+' '+d.item_count
                    tooltip.text(tooltipContent).style('transform', `translate(${event.offsetX - 90}px, ${event.offsetY - (height + (this.props.dashboardTooltipHeight ? this.props.dashboardTooltipHeight : 70))}px)`);
                })
            
            // .on("mousemove", function(event, d) {
            //     let hoverContent = capitalizeFirstLetter(d.data.name)+' '+d.value
            //     tooltip.text(hoverContent).style('transform', `translate(${event.offsetX - 50}px, ${event.offsetY - (height + 100)}px)`);
            // });
    
        svg.selectAll("myline")
            .data(this.state.lollipopHorizData)
            .enter()
            .append("line")
                .attr('class', 'secondLine')
                .attr("x1", function(d) { return x(0); })
                .attr("x2", function(d) { return x(d.total_count) - circleStroke })
                .attr("y1", function(d) { return y(d.label); })
                .attr("y2", function(d) { return y(d.label); })
                .attr("stroke", "lightgrey")

        if(this.props.showEndPointCircle) {
            // End Point cirlce of variable 2
            svg.selectAll("mycircle")
                .data(this.state.lollipopHorizData)
                .enter()
                .append("circle")
                    .attr('class', 'secondCircle')
                    .attr("cx", function(d) { return x(d.total_count)})
                    .attr("cy", function(d) { return y(d.label); })
                    .attr("r", "6")
                    .style("fill", "none")
                    .attr("stroke-width", circleStroke)
                    .attr("stroke", "lightgrey")
                    // .attr('stroke', function(d, i) {
                    //     return secodaryColor(i);
                    // })
                    .on("mouseover", (event, d) => {     
                        tooltip.style("display", "block")
                    })    
                    .on("mouseout", d => {     
                        tooltip.style("display", "none")
                    })
                    .on("mousemove", (event, d) => {
                        let tooltipContent = 'Total Count '+d.total_count
                        tooltip.text(tooltipContent).style('transform', `translate(${event.offsetX - 90}px, ${event.offsetY - (height + 70)}px)`);
                    })
        }
    
        svg.selectAll("myline")
            .data(this.state.lollipopHorizData)
            .enter()
            .append("text")
                .attr("x", x(0) )
                .attr("y", function(d) { return y(d.label) - 10; })
                .attr("font-size", "10px")
                .style("text-anchor", "sart")
                .attr('stroke', '#fff')
                .attr('stroke-width', '.5')
                .text(function(d) { return d.label; });

        // Lines
        svg.selectAll("myline")
            .data(this.state.lollipopHorizData)
            .enter()
            .append("line")
            .attr('class', 'firstLine')
            .attr("x1", function(d) { return x(0); })
            .attr("x2", x(0))
            .attr("y1", function(d) { return y(d.label); })
            .attr("y2", function(d) { return y(d.label); })
            .attr("stroke-width", "2.5")
            .attr('stroke', function(d, i) {
                return color(i);
            })

        // Change the X coordinates of line and circle
        svg.selectAll(".firstCircle")
            .transition()
            .duration(2000)
            .attr("cx", function(d) { return x(d.item_count) - (x(d.item_count) < (circleStroke * 2) ? 0 : (circleStroke * 2)); })

        svg.selectAll(".firstLine")
            .transition()
            .duration(2000)
            .attr("x1", function(d) { return x(d.item_count); })

        // svg.selectAll(".secondLine")
        //     .transition()
        //     .duration(4000)
        //     .attr("x1", function(d) { return x(d.total_count); })

        // svg.selectAll(".secondCircle")
        //     .transition()
        //     .duration(4000)
        //     .attr("cx", function(d) { return x(d.total_count) + circleStroke; })
    }
 
    render() {		
        return (
            <div id={this.props.ID} className={this.props.className ? this.props.className : ''} />
        )
    }
}
 
 /**
  * Type of the props used in the component
  */
 HorizontalLimitLollipopChart.propTypes = {}
 
 const mapStateToProps = state => {}
 
 export default connect(mapStateToProps, {})(withRouter(HorizontalLimitLollipopChart))
 
 
 
 