/*************************************************
 * Tvastar
 * @exports
 * @file ApexBarProgressChart.js
 * @author Prakash // on 13/07/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { capitalizeTheFirstLetterOfEachWord, momentConvertionUtcToLocalTime, unitTimeConvertionDetail } from '../../../utils/utility'
import Chart from 'react-apexcharts'


class ApexBarProgressChart extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    
    componentDidMount = () => {
        let graphData = this.props.graphData;
        if(graphData === 'mockData') {
            graphData = {}
            graphData.data = ["50"]
            graphData.labels = ["mock data"]
            graphData.name = "Mock Data"
        }
        if(graphData && Object.entries(graphData).length) {
            this.drawGraph(graphData)
        }
    }

    drawGraph = (graphData) => {
        let categories = graphData.labels
        
        let backgroundColors = {}
        if(this.props.showBackgroundBarColors) {
            backgroundColors = {
                ranges: [{
                    from: 1,
                    to: 0.9,
                    color: 'red'
                }],
                backgroundBarColors: this.props.backgroundBarColors ? this.props.backgroundBarColors : ['#EDEDED'],
                backgroundBarOpacity: 1,
                backgroundBarRadius: this.props.backgroundBarShape && this.props.backgroundBarShape === 'rounded' ? (this.props.barRadius ? this.props.barRadius : 10) : 0,
            }
        }
        
        let series = [
            {
                name: graphData.name,
                data: graphData.data
            }
        ]

        let options = {
            chart: {
                height: this.props.height,
                type: "bar",
                stacked: this.props.stacked,
                sparkline: {
                    enabled: this.props.sparkline
                }
            },
            plotOptions: {
                bar: {
                    horizontal: this.props.horizontal,
                    barHeight: "20%",
                    colors: {
                        backgroundBarColors: this.props.backgroundBarColors
                    }
                }
            },
            colors: this.props.colors ? this.props.colors : ['#26B68F', '#2ED1BE', '#67E1D6', '#00B5D4'],
            stroke: {
                width: 0
            },
            // title: {
            //     floating: true,
            //     offsetX: -10,
            //     offsetY: 5,
            //     text: categories && categories.length ? categories[0] : "",
            //     style: {
            //         fontSize: '14px',
            //         fontWeight: 'bold',
            //         fontFamily: undefined,
            //         color: this.props.titleColor
            //       },
            // },
            // subtitle: {
            //     floating: true,
            //     align: "right",
            //     offsetY: 0,
            //     text: graphData.data && graphData.data.length ? graphData.data[0] +"%" : "",
            //     style: {
            //         fontSize: "14px",
            //         color: this.props.titleColor
            //     }
            // },
            
            tooltip: {
                y: {
                    show: false,
                },                
                x: {
                    show: false
                },
                marker: {
                    show: false
                },
                custom: function({series, seriesIndex, dataPointIndex, w}) {     
                    console.log(w.globals)
                    console.log(series)
                    let val  = series[seriesIndex][dataPointIndex];
                    let labelName = w.globals.labels[seriesIndex]
                    let name = w.globals.seriesNames[seriesIndex]

                    return '<div class="d-flex arrow_box"> '+ labelName +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' + name + '</span> </div>'
                }
            },  
            xaxis: {
                categories: categories
            },
            yaxis: {
                max: 100
            },
            fill: {
                opacity: 1
            }
        };

        this.setState({ series, options })
    }

    render() {
        return(
            this.state.series ?
                <div className={`${this.props.className}`}>
                    <Chart options={this.state.options} series={this.state.series} type="bar" height={this.props.height} width={this.props.width ? this.props.width : "100%"}/>
                </div>
            : null
        )
    }
}
 
/**
 * Type of the props used in the component
 */
ApexBarProgressChart.propTypes = {}

const mapStateToProps = state => {}

export default connect(mapStateToProps, {})(withRouter(ApexBarProgressChart))