/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Dashboard.js
 * @author CreateEditAutomation // on 08/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { URL_PATH } from '../../config/urlPath'
// import PropTypes from 'prop-types'
import { Spinner, Input } from 'reactstrap'
import { listAllProviders, listAllAccounts, listAllRegions, listEventSources } from '../../actions/commonAction'
import { listAutomationActions, createAutomation, editAutomation, automationListEvents, viewAutomation } from '../../actions/AutomationAction'

import { AppWrapper } from '../common/AppWrapper'

import Select from 'react-select';
import { setAutomationPropsDetails } from '../../actions/commonAction'
import { listAssets } from '../../actions/detectors/detectorsAction'
import { capitalizeTheFirstLetterOfEachWord, getAccountNameFromId, getRegionName } from '../../utils/utility'
import ActionSection from './ActionSection'

import { store as CommonNotification } from 'react-notifications-component';

import Checkbox from '../common/formFields/Checkbox'
import SelectField from '../common/formFields/SelectField'

import Help from './AutomationRIghtSection/Help'
import Database from './AutomationRIghtSection/Database'
import PatternCheck from './AutomationRIghtSection/PatternCheck'
import CreateDiagnostics from './AutomationRIghtSection/CreateDiagnostics'
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip'

const alertTriggerTypeOptions = [
    { label: 'Cloudwatch Alarm', name: 'cloudwatch-alarm'},
    { label: 'Cloudwatch Loggroup', name: 'cloudwatch-loggroups'}
];
const triggerSource = [
    { value: "Alert", label: "Alert"},
    { value: "Event", label: "Event" }
];

class CreateEditAutomation extends Component {
    constructor(props) {
        super(props)
        this.detailDropActionRef = React.createRef()
        this.scrolltoDiv = React.createRef()
        this.dropResultRef = React.createRef()
        this.state = {
            tabs:[],
            latestTab: 1,
            activeSection: 1,
            showDetailsButton: true,

            accounts: [],
            regions: [],
            triggerNameOptions: [],
            pageType: "create",
            automationType: ["Application"],
            showHelperSection: "",
            helperModule: "",

            dropResults: ["component_id", "age", "resourc_type", "services"],
        }
    }

    componentDidMount = () => {
        let selectedRule = this.props.location.state && this.props.location.state.selectedRule ? this.props.location.state.selectedRule : {}

        console.log("selectedRule", selectedRule)

        if(window.location.pathname === "/automation/edit" && !Object.entries(selectedRule).length) {
            this.props.history.push({
                pathname: URL_PATH.LIST_AUTOMATION,
            })
        }

        if (Object.entries(selectedRule).length) {
            this.setState({ loadingAutomation: true })
            let params = {}
            params.processor_rule_id = selectedRule.processor_rule_id

            this.props.viewAutomation(params, (promise, response) => {
                if(promise) {
                    let results = response && response.length ? response[0] : {}
                    this.setState({ ruleDetails: results },
                        () => this.editRule()
                    )
                } else {
                    this.setState({ ruleDetails: {}, loadingAutomation: false })
                }
            })
            
        } else {
            if(this.state.automationType && this.state.automationType.length === 1) {
                this.setState({ automation_type: this.state.automationType[0] }, 
                    () => {
                        this.listAutomationActions()
                        this.formInputProps('details', 'automation_type', this.state.automation_type)
                    }
                )
            }
            if(triggerSource && triggerSource.length === 1) {
                this.setState({ selectedTriggerSource: triggerSource[0].value }, 
                    () => {
                        this.formInputProps("trigger", "trigger_source", this.state.selectedTriggerSource)
                    }
                )
            }
            
            this.props.listAllProviders((promise, response) => {
                if(promise) {
                    if(response.length && response.length === 1) {
                        this.setState({ selectedProvider: response[0].provider_name.toLowerCase() },
                            () => {
                                this.getCommonFilters()
                                this.formInputProps("apply_to", "provider", this.state.selectedProvider)
                            }
                        )
                    }
                }
            })
        }
    }

    editRule = () => {
        
        let ruleDetails = this.state.ruleDetails
        console.log("ruleDetails 11", ruleDetails)
        let newelement = "Remediation"
        this.setState({
            automationType: [...this.state.automationType, newelement],
            pageType: "edit",
            selectedProvider: ruleDetails.provider ? ruleDetails.provider.toUpperCase() : "",
            selectedAccount: ruleDetails.account_id ? ruleDetails.account_id : "",
            selectedRegion: ruleDetails.region ? ruleDetails.region : "",
            automation_name: ruleDetails.name ? ruleDetails.name : "",
            automation_type: ruleDetails.automation_type ? ruleDetails.automation_type : "",
            description: ruleDetails.description ? ruleDetails.description : "",
            selectedTriggerName: ruleDetails.trigger_name ? ruleDetails.trigger_name : "",
            selectedTriggerType: ruleDetails.trigger_type ? ruleDetails.trigger_type : "",
            selectedTriggerSource: ruleDetails.trigger_source ? ruleDetails.trigger_source : "",
            trigger_pattern: ruleDetails.trigger_pattern ? ruleDetails.trigger_pattern : "",
            showApplyToSection: true,
            showTriggerSection: true,
            automatonStatus: ruleDetails.enabled ? ruleDetails.enabled : false,
        },
            () => {
                this.listAutomationActions()
                this.onChangeTriggerSource()
                if(this.state.selectedTriggerSource === "Event") {
                    this.onChangeTriggerType()
                }
            }
        )

        if(ruleDetails.automation_type === "Remediation") {
            this.setState({ 
                disableFields: true,
                showChecksSection: true,
                showRemediationActionSection: true,
                enablePlusAction: false,
                checksData: ruleDetails.checks_description ? ruleDetails.checks_description : [],
                activeSection: 5,
                applicable_actions: ruleDetails.applicable_actions ? ruleDetails.applicable_actions : [],
                action_parameters: ruleDetails.action_parameters ? ruleDetails.action_parameters : [],
                preffered_action: ruleDetails.preffered_action,
                loadingAutomation: false
            },
                () => this.getCommonFilters()
            )
        } else {
            let actions = ruleDetails.actions ? ruleDetails.actions : []
            
            let newAction = []
            let tabs = []
            actions.forEach((act, i) => {
                act.section = i+4
                tabs.push(act.section)
                newAction.push(act)
            })

            ruleDetails.actions = newAction
            
            this.setState({ 
                latestTab: ruleDetails.actions.length + 3,
                activeSection: ruleDetails.actions.length + 3,
                ["showAction_"+(ruleDetails.actions.length + 3)]: true,
                tabs,
                enablePlusAction: true,
                loadingAutomation: false
            },
                () => {
                    this.getCommonFilters()
                }
            )
        }
        console.log("ruleDetails 12", ruleDetails)
        this.props.setAutomationPropsDetails(ruleDetails)
    }

    getCommonFilters = () => {
        let params = {}
        if(this.props.accounts && this.props.accounts.length) {
            let accounts = this.props.accounts
            if(this.state.selectedProvider) {
                accounts = this.props.accounts.filter(e => e.provider === this.state.selectedProvider.toLowerCase())
                this.setState({ accounts },
                    () => {
                        if(this.state.accounts.length && this.state.accounts.length === 1) {
                            this.setState({ selectedAccount: this.state.accounts[0].account_id })
                            this.formInputProps("apply_to", "account_id", this.state.accounts[0].account_id)
                        }
                    }
                )
            }
        } else {
            this.props.listAllAccounts(params, (promise, response) => {
                if(promise) {
                    let filterdAccounts = response.filter(e => e.provider === this.state.selectedProvider.toLowerCase())
                    this.setState({ accounts: filterdAccounts },
                        () => {
                            if(this.state.accounts.length && this.state.accounts.length === 1) {
                                this.setState({ selectedAccount: this.state.accounts[0].account_id })
                                this.formInputProps("apply_to", "account_id", this.state.accounts[0].account_id)
                            }
                        }
                    )
                }
            })
        }

        if(this.state.selectedProvider) {
            params.provider = this.state.selectedProvider.toLowerCase()

            if(this.props.regions && this.props.regions.length) {
                let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
                providerRegions = providerRegions.filter(e => e.name !== "All")
                this.setState({ regions: providerRegions },
                    () => {
                        if(this.state.regions.length && this.state.regions.length === 1) {
                            this.setState({ selectedRegion: this.state.regions[0].region })
                            this.formInputProps("apply_to", "region", this.state.regions[0].region)
                        }
                    }
                )
            } else {
                this.props.listAllRegions(params, (promise, response) => {
                    if (promise) {
                        let providerRegions = response && response[0] && response[0].provider_regions ? response[0].provider_regions : []
                        providerRegions = providerRegions.filter(e => e.name !== "All")
                        this.setState({ regions: providerRegions },
                            () => {
                                if(this.state.regions.length && this.state.regions.length === 1) {
                                    this.setState({ selectedRegion: this.state.regions[0].region })
                                    this.formInputProps("apply_to", "region", this.state.regions[0].region)
                                }
                            }
                        )
                    }
                })
            }
        }

    }

    listAutomationActions = () => {
        let params = {
            automation_type: this.state.automation_type
        }

        this.props.listAutomationActions(params, (promsie, response) => {})
    }

    formInputProps = (section, field, value) => {
        let obj = this.props.automationPropsDetails
        obj[field] = value
        this.props.setAutomationPropsDetails(obj)

        if(this.state.automation_name && this.state.automation_name !== "" && this.state.description && this.state.description !== "" && this.state.automation_type) {
            this.setState({ detailsInput: true })
        } else {
            this.setState({ detailsInput: false })
        }

        if(this.state.selectedProvider && this.state.selectedRegion && this.state.selectedAccount) {
            this.setState({ applytoInput: true })
        } else {
            this.setState({ applytoInput: false })
        }

        if(this.state.selectedTriggerType && this.state.selectedTriggerSource && this.state.selectedTriggerName) {
            let triggerInput = true
            if(this.state.selectedTriggerType === "cloudwatch_loggroup" && this.state.trigger_pattern === "") {
                triggerInput = false
            } 
            this.setState({ triggerInput })
        } else {
            this.setState({ triggerInput: false })
        }        

        if(this.state.automation_type === "Remediation") {
            if(this.state.preffered_action && this.state.preffered_action !== "") {
                this.setState({ updateRemedition: true })
            } else {
                this.setState({ updateRemedition: false })
            }
        }
    }

    detailsSubmit = () => {
        this.setState({
            activeSection: 2,
            showApplyToSection: true,
        })
    }

    applytoSubmit = () => {
        this.setState({
            activeSection: 3,
            showTriggerSection: true,
            // triggerNameLoader: true
        },
            // () => this.getTriggerName()
        )
    }

    listEventSources = () => {
        this.props.listEventSources((promise, response) => {
            if(promise) {                
                this.setState({ triggerTypeOptions: response },
                    () => {
                        if(this.state.triggerTypeOptions.length && this.state.triggerTypeOptions.length === 1) {
                            this.setState({ selectedTriggerType: this.state.triggerTypeOptions[0].name },
                                () => this.formInputProps("trigger", "trigger_type", this.state.selectedTriggerType)    
                            )
                        }
                    }
                )
            } else {
                this.setState({ triggerTypeOptions: [] })
            }
        })
    }

    onChangeTriggerSource = () => { 
        if(this.state.selectedTriggerSource === "Event") {
            this.listEventSources()
        } else {            
            this.setState({ triggerTypeOptions: alertTriggerTypeOptions, triggerNameLoader: true },
                () => {
                    if(alertTriggerTypeOptions && alertTriggerTypeOptions.length && alertTriggerTypeOptions.length === 1) {
                        this.setState({ selectedTriggerType: alertTriggerTypeOptions[0].name },
                            () => this.formInputProps("trigger", "trigger_type", this.state.selectedTriggerType)
                        )                        
                    }
                    this.getTriggerName()
                }
            )
        }
    }

    onChangeTriggerType = () => {
        this.setState({ triggerNameLoader: true },
            () => this.automationListEvents()
        )
    }

    getTriggerName = () => {
        
        let params = {
            "provider": this.state.selectedProvider.toLowerCase(),
            "account_id": this.state.selectedAccount,
            "region": this.state.selectedRegion,
            "service_name": this.state.selectedTriggerType
        }
        if(this.state.selectedTriggerType && this.state.selectedTriggerType !== "") {
            this.props.listAssets(params, (promise, response) => {
                if(promise) {
                    this.setState({ 
                        triggerNameOptions: response && response.results ? response.results : [], 
                        triggerNameLoader: false,
                    },
                        () => {
                            if(this.state.triggerNameOptions.length && this.state.triggerNameOptions.length === 1) {
                                this.setState({ selectedTriggerName: this.state.triggerNameOptions[0].asset_name })
                                this.formInputProps("trigger", "trigger_name", this.state.triggerNameOptions[0].asset_name)
                            }
                        }
                    )
                } else {
                    this.setState({ triggerNameLoader: false })
                }
            })
        }
    }

    automationListEvents = () => {
        let params = {
            "provider": this.state.selectedProvider.toLowerCase(),
            // "account_id": this.state.selectedAccount,
            // "region": this.state.selectedRegion,
            "event_source": this.state.selectedTriggerType
        }

        this.props.automationListEvents(params, (promise, response) => {
            if(promise) {
                this.setState({ 
                    eventTriggerNameOptions: response, 
                    triggerNameLoader: false,
                },
                    () => {
                        if(this.state.eventTriggerNameOptions.length && this.state.eventTriggerNameOptions.length === 1) {
                            this.setState({ selectedTriggerName: this.state.eventTriggerNameOptions[0] })
                            this.formInputProps("trigger", "trigger_name", this.state.eventTriggerNameOptions[0])
                        }
                    }
                )
            } else {
                this.setState({ triggerNameLoader: false })
            }
        })
    }

    addAction = (activeSection) => {
        let tabs = this.state.tabs
        if(!this.state.tabs.includes(activeSection)) {
            tabs.push(activeSection)
            this.setState({ latestTab: activeSection })
            // setTimeout(() => { this.scrollToSection(activeSection) }, 1000)
        }
        tabs.forEach(item => {
            this.setState({ ["showAction_"+item]: false })
        })
        this.setState({ 
            tabs: [],
            activeSection,
            ["showAction_"+activeSection]: true,
            enablePlusAction: false
        },
            () => this.setState({ tabs })
        )
    }

    scrollToSection = (activeSection) => {
        this.scrolltoDiv.current.scrollIntoView()
    };

    handleClickOutside = (event) => {
        if(this.detailDropActionRef && this.detailDropActionRef.current !== null && !this.detailDropActionRef.current.contains(event.target)) {
            this.setState({ showDetailDropAction: false })
        }

        if(this.dropResultRef && this.dropResultRef.current !== null && !this.dropResultRef.current.contains(event.target)) {
            this.setState({ show_drop_option: false })
        }
    }

    deleteStep = (section) => {
        let obj = this.props.automationPropsDetails
        let actions = obj.actions
        actions = actions.filter(e => e.section !== section);
        obj.actions = actions
        this.props.setAutomationPropsDetails(obj)

        let tabs = this.state.tabs
        let activeTab = section
        if(section === this.state.latestTab) {
            activeTab = tabs.length > 1 ? tabs[tabs.length - 2] : 0
        } else {
            let indexOf = tabs.indexOf(section)
            activeTab = tabs[indexOf+1]
        }
        tabs = tabs.filter(e => e !== section)
        this.setState({ tabs: [], latestTab: tabs[tabs.length - 1], activeSection: tabs.length ? 0 : 3, ["showAction_"+activeTab]: true },
            () => this.setState({ tabs })
        )
    }

    createAutomation = () => {

        let params = this.props.automationPropsDetails
        if(this.props.automationPropsDetails.hasOwnProperty("processor_rule_id") && window.location.pathname === "/automations/edit") {
            this.props.editAutomation(params, (promise, response) => {
                let messageType = ""
        
                if(promise) {
                    messageType = "success"
                } else {
                    messageType = "danger"	
                }
                let message = response && response.message ? response.message : "Error in inserting data"

                CommonNotification.addNotification({
                    message: message,
                    type: messageType,
                    insert: "top",
                    container: "top-center",
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                });
                this.setState({ showLoading: false, actionLoader: false },
                    () => {
                        if(messageType === "success") {
                            this.props.history.push({
                                pathname: URL_PATH.LIST_AUTOMATION,
                            })
                            this.props.setAutomationPropsDetails({})
                        }                        
                    }    
                )
            })
        } else {
            this.props.createAutomation(params, (promise, response) => {
                let messageType = ""
                let tabs = this.state.tabs

                if(promise) {
                    messageType = "success"
                } else {
                    messageType = "danger"	
                    this.setState({ tabs: [] },
                        () => { 
                            this.setState({ tabs })
                        }    
                    )
                }
                let message = response && response.message ? response.message : "Error in inserting data"
                
                CommonNotification.addNotification({
                    message: message,
                    type: messageType,
                    insert: "top",
                    container: "top-center",
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                });

                this.setState({ showLoading: false },
                    () => {
                        if(messageType === "success") {
                            this.props.history.push({
                                pathname: URL_PATH.LIST_AUTOMATION,
                            })
                            this.props.setAutomationPropsDetails({})
                        }
                    }    
                )
            })
        }

    }

    onClickActiveTab = (activeSection) => {
        let tabs = this.state.tabs
        
        tabs.forEach(item => {
            this.setState({ ["showAction_"+item]: false })
        })

        this.setState({ activeSection, tabs: [] },
            () => this.setState({ tabs })    
        )
    }

    enableAddFunction = (name) => {
        this.setState({ enablePlusAction: !this.state.enablePlusAction },
            () => this.setState({ enablePlusAction: name === "" ? false : true })
        )
    }

    onContinueTrigger = () => {
        if(this.state.automation_type === "Remediation") {
            this.setState({ activeSection: 4 })
        } else {
            this.addAction(4)
        }
    }

    updateFormFieldValue = (fieldName, fieldValue) => {
        let split = fieldName[0].split("_")
        let obj = this.props.automationPropsDetails
        let array = []
        obj.action_parameters.forEach(item => {
            if(item.action === split[1]) {
                item.parameters.forEach(par => {
                    if(par.name === split[0]) {
                        par.value = fieldValue
                    }
                })
            }
            array.push(item)
        })
        obj.action_parameters = array
        this.props.setAutomationPropsDetails(obj)
        
        this.setState({ [fieldName]: fieldValue });

    }

    validateInput = () => {
        let hasError = false

        //validation part
        
        let obj = this.props.automationPropsDetails
        let activeSection = 0

        if(!obj.name || obj.name === "" || !obj.description || obj.description === "" || !obj.automation_type) {
            hasError = true
            activeSection = 1
            let scroll = document.getElementById("top");
            scroll.scrollIntoView();
        } else if(!obj.provider || !obj.region || !obj.account_id) {
            hasError = true
            activeSection = 2
            let scroll = document.getElementById("scrollToApply");
            scroll.scrollIntoView();
        } else if(!obj.trigger_name || !obj.trigger_source || !obj.trigger_type) {   
            hasError = true
            activeSection = 3
            let scroll = document.getElementById("scrollToTrigger");
            scroll.scrollIntoView();
        } else if(this.state.automation_type === "Remediation" && (!this.state.preffered_action || this.state.preffered_action === "")) {
            hasError = true
            activeSection = 5
        }

        this.setState({ hasError },
            () => {
                if(activeSection) {
                    this.onClickActiveTab(activeSection)
                }
            }
        )
        
        if(!hasError) {
            this.createAutomation()
        }
    }


    render() {
        return (
            <div className="container-fluid overflow-auto flex-grow-1 bg-muted" onClick={(event) => this.handleClickOutside(event) }>
                <div className="row">
                    <div className="col-sm-12 p-0">
                        <div className="w-100 position-fixed zIndex1">
                            <div className="title-section pb-2">
                                <div className="row">
                                    <div className="col-12 mt-0">
                                        <div className="d-flex justify-content-between mr-5">
                                            <h6 className="text-white m-0">{this.state.pageType === "edit" ? "Edit " : "Create "}Automation</h6>
                                            <Link className="btn-sm btn-light text-decoration-none mr-3 text-dark" to={URL_PATH.LIST_AUTOMATION}>Back</Link>
                                        </div>
                                        {/* <button className="btn btn-light mr-1" onClick={() => this.props.onClickBack()}>Back</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid" style={{marginTop: "53px"}} id="top">
                            {this.state.loadingAutomation ? 
                                <div className='d-flex justify-content-center m-4'>
                                    <Spinner className='text-center' color='white' size='lg' />
                                </div>
                            :
                                <div className="row mt-2">
                                    <div className={`col-sm-12 pl-0 position-fixed zIndex1`}>
                                        <div className="d-flex bg-dark3 border-muted w-100 mb-2" style={{height: "45px"}}>
                                            <div className="d-flex justify-content-between col-sm-12">
                                                {!this.state.editAutomationName ?
                                                    <div className="d-flex ml-2 mb-2">
                                                        <i className="fas fa-pencil mr-1 align-self-end cursorPointer mb-1" onClick={() => this.setState({ editAutomationName: true })}></i>
                                                        <p className="mb-0 pt-2 pl-2 align-self-end">{this.state.automation_name && this.state.automation_name !== "" ? this.state.automation_name : <span className="placeholder">Name your automation</span> }</p>
                                                    </div>
                                                :
                                                    <Input
                                                        type="text"
                                                        placeholder="Name your automation"
                                                        className="zapLabelInput w-25"
                                                        readOnly={!this.state.editAutomationName ? true : false}
                                                        value={this.state.automation_name}
                                                        onChange={event => 
                                                            this.setState({ automation_name: event.target.value },
                                                                () => this.formInputProps("details", "name", this.state.automation_name)    
                                                            )
                                                        }
                                                        onBlur={() => this.setState({ editAutomationName: false }) }
                                                    />
                                                }
                                                <div className={` ${this.state.pageType === "edit" ? "d-flex mr-5" : "displayNone"}`}>
                                                    <div className={`toggleOnOffSwitch d-flex align-self-center`}>
                                                        <label className="switch mb-0">
                                                            <input 
                                                                type="checkbox" 
                                                                checked={this.state.automatonStatus} 
                                                                className="inputdemo" 
                                                                onClick={() => 
                                                                    this.setState({ automatonStatus: !this.state.automatonStatus }, 
                                                                        () => this.formInputProps("details", "enabled", this.state.automatonStatus)
                                                                    )
                                                                } />
                                                            <span className="slider round">
                                                                {/* {!this.state.automatonStatus ?
                                                                    <span className="text">Off</span>
                                                                : null} */}
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <span className="small ml-2 align-self-center">{this.state.automatonStatus ? "Enabled" : "Disabled"}</span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${this.state.showHelperSection !== "" ? "d-flex justify-content-center" : "w-100"}`}>
                                        <div className={`${this.state.showHelperSection !== "" ? "w-70" : "w-100"}`}>
                                            <div className={`p-0 pl-1 zapDesign zapInputDark`} style={{marginTop: "55px"}}>
                                                <div className={`section ${this.state.activeSection === 1 ? "active" : ""}`}>
                                                    <div className={`sectionHeader d-flex ${this.state.activeSection === 1 ? "" : "rounded"}`} onClick={() => this.onClickActiveTab(1)}>
                                                        <div className="d-flex">
                                                            <div className="">
                                                                {/* <i className="fas fa-exclamation-circle icon-stack-2x"></i> */}
                                                                <i className={`far fa-file-alt f30 p-2 border-gray3 align-self-center rounded ${this.state.activeSection === 1 ? "text-white" : ""}`}></i>
                                                            </div>
                                                            <div className="columnHeading ml-3 align-self-center">
                                                                <div className="d-flex">
                                                                    <span className="title">1. Details</span>
                                                                    {this.state.activeSection !== 1 ?
                                                                        <div clasName="d-flex text-white">
                                                                            <span className="ml-2 text-info">Name: <span className="text-white">{this.state.automation_name}</span></span>
                                                                            <span className="ml-2 text-info">Type: <span className="text-white">{this.state.automation_type}</span></span>
                                                                        </div>
                                                                    : null}
                                                                </div>
                                                                <span className="description">Description about the automation job</span>
                                                            </div>
                                                        </div>
                                                        <div className={`d-flex align-self-center text-black5 mt-1 ${this.state.activeSection === 1 ? "" : "displayNone"}`}>
                                                            <i className="fas fa-question-circle" onClick={() => this.setState({ showHelperSection: this.state.helperModule === "details" ? "" : "help", helperModule: "details" })}></i>
                                                        </div>
                                                    </div>
                                                    <div className={`sectionBody ${this.state.activeSection === 1 ? "" : "displayNone"}`}>
                                                        <div className="d-flex">
                                                            <div className="w-50">
                                                                <div className="d-flex justify-content-between">
                                                                    <label className="mb-1">Name </label>
                                                                    <small className={`${this.state.hasError && (!this.state.automation_name || this.state.automation_name === "") ? "text-danger" : ""} mr-2`}>(required)</small>
                                                                </div>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="Name your automation"
                                                                    className="inputTextbox"
                                                                    readOnly={this.state.disableFields}
                                                                    value={this.state.automation_name}
                                                                    onChange={event => 
                                                                        this.setState({ automation_name: event.target.value },
                                                                            () => this.formInputProps("details", "name", this.state.automation_name)
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="pl-3 w-50">
                                                                <div className="d-flex justify-content-between">
                                                                    <label className="mb-1">Type </label>
                                                                    <small className={`${this.state.hasError && !this.state.automationType ? "text-danger" : ""} mr-2`}>(required)</small>
                                                                </div>
                                                                <Select 
                                                                    options={this.state.automationType && this.state.automationType.map(item => ({                                                            
                                                                        value: item,
                                                                        label: item,
                                                                    }))}
                                                                    value={({
                                                                        value: this.state.automation_type,
                                                                        label: this.state.automation_type ? this.state.automation_type : <span className="placeholder">Select automation type</span>
                                                                    })}
                                                                    placeholder={<div></div>}
                                                                    isSearchable={!this.state.disableFields}
                                                                    menuIsOpen={this.state.disableFields ? false : undefined}
                                                                    onChange={event => 
                                                                        this.setState({ automation_type: event.value },
                                                                            () => {
                                                                                this.formInputProps("details", "automation_type", this.state.automation_type)
                                                                                this.listAutomationActions()
                                                                            }
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mt-3">
                                                            <div className="d-flex">
                                                                <div className="w-100">
                                                                    <div className="d-flex justify-content-between">
                                                                        <label className="mb-1">Description </label>
                                                                        <small className={`${this.state.hasError && (!this.state.description || this.state.description === "") ? "text-danger" : ""} mr-2`}>(required)</small>
                                                                    </div>
                                                                    <Input 
                                                                        type="textarea" 
                                                                        name="text" 
                                                                        readOnly={this.state.disableFields}
                                                                        className="inputTextbox"
                                                                        value={this.state.description}
                                                                        rows={3}
                                                                        onChange={event => 
                                                                            this.setState({ description: event.target.value },
                                                                                () => {
                                                                                    this.formInputProps("details", "description", this.state.description)
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                {/* <div className="pl-3 col-sm-6"> */}
                                                                    {/* <label className="mb-1">Test</label>
                                                                    <div className="inputBatch">
                                                                        <div className="textbox">

                                                                        </div>
                                                                    </div> */}
                                                                        {/* <input type="text" list="cars" />
                                                                        <datalist id="cars">
                                                                            <option>Volvo</option>
                                                                            <option>Saab</option>
                                                                            <option>Mercedes</option>
                                                                            <option>Audi</option>
                                                                        </datalist> */}
                                                                        
                                                                {/* </div> */}

                                                            </div>
                                                        </div>
                                                        {/* <div className="col-sm-12 my-3 row">
                                                            <div contenteditable="true" className="form-control" >
                                                                <span className="text">&nbsp;</span>
                                                            </div>
                                                            <div className={`dropdownOptions mt-n3`}>
                                                                <button className="d-flex flex-column mb-3">
                                                                    <p className="mb-0">Action</p>
                                                                    <p className="mb-0 small description d-flex flex-wrap text-left">Description text common</p>
                                                                </button>
                                                            </div>

                                                        </div> */}
                                                        <div className={`continueButton mt-4 text-center`} id="scrollToApply">
                                                            <button className={`btn bg-${this.state.detailsInput ? "info" : "gray3 disabled"} p-2`} onClick={() => this.detailsSubmit() }>
                                                                {this.state.detailsInput ?
                                                                    "Continue"
                                                                : 
                                                                    "To continue, fill the details"
                                                                }
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.showApplyToSection ?
                                                    <React.Fragment>
                                                    <div className="lineDown"></div>
                                                    <div className="lineDown"></div>
                                                    <div className="downArrow"><i className="fas fa-caret-down"></i></div>
                                                    <div className={`section ${this.state.activeSection === 2 ? "active" : ""}`}>
                                                        <div className={`sectionHeader ${this.state.activeSection === 2 ? "" : "rounded"}`} onClick={() => this.onClickActiveTab(2)}>
                                                            <div className="d-flex">
                                                                <i className={`far fa-file-alt f30 p-2 border-gray3 align-self-center rounded ${this.state.activeSection === 2 ? "text-white" : ""}`}></i>
                                                                <div className="columnHeading ml-3 align-self-center">
                                                                    <div className="d-flex">
                                                                        <span className="title">2. Apply To</span>
                                                                        {this.state.activeSection !== 2 ?
                                                                            <div clasName="d-flex text-white">
                                                                                {this.state.selectedProvider ?
                                                                                    <span className="ml-2 text-info">Provider: <span className="text-white">{this.state.selectedProvider.toUpperCase()}</span></span>
                                                                                : null}
                                                                                {this.state.selectedAccount ? 
                                                                                    <span className="ml-2 text-info">Account: <span className="text-white">{getAccountNameFromId(this.state.selectedAccount, this.state.accounts)}</span></span>
                                                                                : null}
                                                                                {this.state.selectedRegion ?
                                                                                    <span className="ml-2 text-info">Region: <span className="text-white">{getRegionName(this.state.selectedRegion, this.state.regions)}</span></span>
                                                                                : null}
                                                                            </div>
                                                                        : null}
                                                                    </div>
                                                                    <span className="description">An automation apply to the accounts and region tag</span>
                                                                </div>
                                                            </div>
                                                            <div className={`d-flex align-self-center text-black5 mt-1 ${this.state.activeSection === 2 ? "" : "displayNone"}`}>
                                                                <i className="fas fa-question-circle" onClick={() => this.setState({ showHelperSection: this.state.helperModule === "applyto" ? "" : "help", helperModule: "applyto" })}></i>
                                                            </div>
                                                        </div>
                                                        <div className={`sectionBody ${this.state.activeSection === 2 ? "" : "displayNone"}`}>
                                                            <div className="d-flex">
                                                                <div className="w-50">
                                                                    <div className="d-flex justify-content-between">
                                                                        <label className="mb-1">Providers </label>
                                                                        <small className={`${this.state.hasError && (!this.state.selectedProvider || this.state.selectedProvider === "") ? "text-danger" : ""} mr-2`}>(required)</small>
                                                                    </div>
                                                                    <Select 
                                                                        options={this.props.providers && this.props.providers.length && this.props.providers.map(item => ({
                                                                            value: item.provider_name,
                                                                            label: item.provider_name,
                                                                        }))}
                                                                        value={({
                                                                            value: this.state.selectedProvider && this.state.selectedProvider.toUpperCase(),
                                                                            label: this.state.selectedProvider ? this.state.selectedProvider.toUpperCase() : <span className="placeholder">Select provider</span>
                                                                        })}
                                                                        isSearchable={!this.state.disableFields}
                                                                        menuIsOpen={this.state.disableFields ? false : undefined}
                                                                        onChange={event => 
                                                                            this.setState({ selectedProvider: event.value.toLowerCase() },
                                                                                () => {
                                                                                    this.getCommonFilters()
                                                                                    this.formInputProps("apply_to", "provider", this.state.selectedProvider)
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="pl-3 w-50">
                                                                    <div className="d-flex justify-content-between">
                                                                        <label className="mb-1">Accounts </label>
                                                                        <small className={`${this.state.hasError && (!this.state.selectedAccount || this.state.selectedAccount === "") ? "text-danger" : ""} mr-2`}>(required)</small>
                                                                    </div>
                                                                    <Select 
                                                                        options={this.state.accounts && this.state.accounts.length &&this.state.accounts.map(item => ({
                                                                            value: item.account_id,
                                                                            label: item.account_name,
                                                                        }))}
                                                                        value={({
                                                                            value: this.state.selectedAccount,
                                                                            label: this.state.selectedAccount && this.state.selectedAccount !== "" ? getAccountNameFromId(this.state.selectedAccount, this.state.accounts) : <span className="placeholder">Select account</span>
                                                                        })}
                                                                        isSearchable={!this.state.disableFields}
                                                                        menuIsOpen={this.state.disableFields ? false : undefined}
                                                                        onChange={event => 
                                                                            this.setState({ selectedAccount: event.value },
                                                                                () => this.formInputProps("apply_to", "account_id", this.state.selectedAccount)
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="d-flex mt-3">
                                                                <div className="w-50">
                                                                    <div className="d-flex justify-content-between">
                                                                        <label className="mb-1">Region </label>
                                                                        <small className={`${this.state.hasError && (!this.state.selectedRegion || this.state.selectedRegion === "") ? "text-danger" : ""} mr-2`}>(required)</small>
                                                                    </div>
                                                                    <Select 
                                                                        options={this.state.regions && this.state.regions.map(item => ({
                                                                            value: item.region,
                                                                            label: item.name,
                                                                        }))}
                                                                        value={({
                                                                            value: this.state.selectedRegion,
                                                                            label: this.state.selectedRegion && this.state.selectedRegion !== ""  ? getRegionName(this.state.selectedRegion, this.state.regions) : <span className="placeholder">Select region</span>
                                                                        })}
                                                                        // menuIsOpen={true}
                                                                        onChange={event => 
                                                                            this.setState({ selectedRegion: event.value },
                                                                                () => this.formInputProps("apply_to", "region", this.state.selectedRegion)
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className={`continueButton mt-4 text-center`} id="scrollToTrigger">
                                                                <button className={`btn bg-${this.state.applytoInput ? "info" : "gray3 disabled"} p-2`} onClick={() => this.applytoSubmit() }>
                                                                    {this.state.applytoInput ?
                                                                        "Continue"
                                                                    : 
                                                                        "To continue, fill apply to"
                                                                    }
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </React.Fragment>
                                                : null}
                                                {this.state.showTriggerSection ?
                                                    <React.Fragment>
                                                    <div className="lineDown"></div>
                                                    <div className="lineDown"></div>
                                                    <div className="downArrow"><i className="fas fa-caret-down"></i></div>
                                                    <div className={`section ${this.state.activeSection === 3 ? "active" : ""}`}>
                                                        <div className={`sectionHeader ${this.state.activeSection === 3 ? "" : "rounded"}`} onClick={() => this.onClickActiveTab(3)}>
                                                            <div className="d-flex">
                                                                <i className={`far fa-file-alt f30 p-2 border-gray3 align-self-center rounded ${this.state.activeSection === 3 ? "text-white" : ""}`}></i>
                                                                <div className="columnHeading ml-3 align-self-center">
                                                                    <div className="d-flex">
                                                                        <span className="title">3. Trigger</span>
                                                                        {this.state.activeSection !== 3 ?
                                                                            <div clasName="d-flex text-white">
                                                                                {this.state.selectedTriggerSource ?
                                                                                    <span className="ml-2 text-info">Type: <span className="text-white">{this.state.selectedTriggerSource}</span></span>
                                                                                : null}
                                                                                {this.state.selectedTriggerType ?
                                                                                    <span className="ml-2 text-info">Source: <span className="text-white">{this.state.triggerTypeOptions && this.state.triggerTypeOptions.filter(e => e.name === this.state.selectedTriggerType).length ? this.state.triggerTypeOptions.filter(e => e.name === this.state.selectedTriggerType)[0].label : ""}</span></span>
                                                                                : null}
                                                                                {this.state.selectedTriggerName ?
                                                                                    <span className="ml-2 text-info">Name: <span className="text-white">{this.state.selectedTriggerName}</span></span>
                                                                                : null}
                                                                                {this.state.selectedTriggerType && this.state.selectedTriggerType === "cloudwatch_loggroup" && this.state.trigger_pattern && this.state.trigger_pattern !== "" ?
                                                                                    <span className="ml-2 text-info">Pattern: <span className="text-white">{this.state.trigger_pattern}</span></span>
                                                                                : null}
                                                                            </div>
                                                                        : null}
                                                                    </div>
                                                                    <span className="description">An trigger to alarm the situation</span>
                                                                </div>
                                                            </div>
                                                            <div className={`d-flex align-self-center text-black5 mt-1 ${this.state.activeSection === 3 ? "" : "displayNone"}`}>
                                                                <i className="fas fa-question-circle mr-4" onClick={() => this.setState({ showHelperSection: this.state.helperModule === "trigger" ? "" : "help", helperModule: "trigger" })}></i>
                                                                {/* <div className="position-relative d-inline-block" ref={this.detailDropActionRef}>
                                                                    <i className="fas fa-ellipsis-h" onClick={() => this.setState({ showDetailDropAction: !this.state.showDetailDropAction })}></i>
                                                                    <div className={`moreOptions ${this.state.showDetailDropAction ? "" : "displayNone"}`}>
                                                                        <button>Rename step…</button>
                                                                        <button>Delete step…</button>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        <div className={`sectionBody ${this.state.activeSection === 3 ? "" : "displayNone"}`}>
                                                            <div className="d-flex">
                                                                <div className="w-50">
                                                                    <div className="d-flex justify-content-between">
                                                                        <label className="mb-1">Type </label>
                                                                        <small className={`${this.state.hasError && (!this.state.selectedTriggerSource || this.state.selectedTriggerSource === "") ? "text-danger" : ""} mr-2`}>(required)</small>
                                                                    </div>
                                                                    <Select 
                                                                        options={triggerSource && triggerSource.map(item => ({
                                                                            value: item.value,
                                                                            label: item.value.replaceAll("_", " "),
                                                                        }))}
                                                                        value={({
                                                                            value: this.state.selectedTriggerSource && this.state.selectedTriggerSource,
                                                                            label: this.state.selectedTriggerSource ?  capitalizeTheFirstLetterOfEachWord(this.state.selectedTriggerSource.replaceAll("_", " ")) : <span className="placeholder">Select trigger type</span> 
                                                                        })}
                                                                        // isSearchable={!this.state.disableFields}
                                                                        // menuIsOpen={this.state.disableFields ? false : undefined}
                                                                        onChange={event => 
                                                                            this.setState({ selectedTriggerSource: event.value, selectedTriggerName: "", selectedTriggerType: "", selectedTriggerType: "", triggerTypeOptions: [] },
                                                                                () => {
                                                                                    this.onChangeTriggerSource()
                                                                                    this.formInputProps("trigger", "trigger_source", this.state.selectedTriggerSource)
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="pl-3 w-50">
                                                                    <div className="d-flex justify-content-between">
                                                                        <label className="mb-1">Source </label>
                                                                        <small className={`${this.state.hasError && (!this.state.selectedTriggerType || this.state.selectedTriggerType === "") ? "text-danger" : ""} mr-2`}>(required)</small>
                                                                    </div>
                                                                    <Select 
                                                                        options={this.state.triggerTypeOptions && this.state.triggerTypeOptions.map(item => ({
                                                                            value: item.name,
                                                                            label: item.label
                                                                        }))}
                                                                        value={({
                                                                            value: this.state.selectedTriggerType && this.state.selectedTriggerType,
                                                                            label: this.state.selectedTriggerType && this.state.selectedTriggerType !== "" && this.state.triggerTypeOptions && this.state.triggerTypeOptions.filter(e => e.name === this.state.selectedTriggerType).length ? this.state.triggerTypeOptions && this.state.triggerTypeOptions.filter(e => e.name === this.state.selectedTriggerType)[0].label : <span className="placeholder">Select trigger source</span> 
                                                                        })}
                                                                        isSearchable={!this.state.disableFields}
                                                                        menuIsOpen={this.state.disableFields ? false : undefined}
                                                                        onChange={event => this.setState({ selectedTriggerType: event.value, selectedTriggerName: "" },
                                                                                () => {
                                                                                    if(this.state.selectedTriggerSource === "Event") {
                                                                                        this.onChangeTriggerType()
                                                                                    } else {
                                                                                        this.getTriggerName()
                                                                                    }
                                                                                    this.formInputProps("trigger", "trigger_type", this.state.selectedTriggerType)
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="d-flex mt-3">
                                                                <div className="w-50">                                                        
                                                                    <div className="d-flex justify-content-between">
                                                                        <label className="mb-1">
                                                                            {this.state.triggerNameLoader ? <Spinner color="whtie" size="sm" className="mx-1" /> :null}
                                                                            Name
                                                                        </label>
                                                                        <small className={`${this.state.hasError && (!this.state.selectedTriggerName || this.state.selectedTriggerName === "") ? "text-danger" : ""} mr-2`}>(required)</small>
                                                                    </div>
                                                                    {this.state.selectedTriggerSource === "Event" ?
                                                                        <Select
                                                                            options={this.state.eventTriggerNameOptions && this.state.eventTriggerNameOptions.map(item => ({
                                                                                value: item.event_name,
                                                                                label: item.event_name,
                                                                            }))}
                                                                            value={({
                                                                                value: this.state.selectedTriggerName && this.state.selectedTriggerName,
                                                                                label: this.state.selectedTriggerName && this.state.selectedTriggerName !== "" ? this.state.selectedTriggerName : <span className="placeholder">Select trigger name</span> 
                                                                            })}
                                                                            isSearchable={!this.state.disableFields}
                                                                            menuIsOpen={this.state.disableFields ? false : undefined}
                                                                            onChange={event => 
                                                                                this.setState({ selectedTriggerName: event.value },
                                                                                    () => {
                                                                                        this.formInputProps("trigger", "trigger_name", this.state.selectedTriggerName)
                                                                                    }
                                                                                )
                                                                            }
                                                                        />
                                                                    : 
                                                                        <Select
                                                                            options={this.state.triggerNameOptions && this.state.triggerNameOptions.map(item => ({
                                                                                value: item.asset_name,
                                                                                label: item.asset_name,
                                                                            }))}
                                                                            value={({
                                                                                value: this.state.selectedTriggerName && this.state.selectedTriggerName,
                                                                                label: this.state.selectedTriggerName ? this.state.selectedTriggerName : <span className="placeholder">Select trigger name</span> 
                                                                            })}
                                                                            isSearchable={!this.state.disableFields}
                                                                            menuIsOpen={this.state.disableFields ? false : undefined}
                                                                            onChange={event => 
                                                                                this.setState({ selectedTriggerName: event.value },
                                                                                    () => {
                                                                                        this.formInputProps("trigger", "trigger_name", this.state.selectedTriggerName)
                                                                                    }
                                                                                )
                                                                            }
                                                                        />
                                                                    }
                                                                </div>
                                                                {this.state.selectedTriggerType === "cloudwatch_loggroup" ? 
                                                                    <div className="pl-3 w-50">
                                                                        <div className="d-flex justify-content-between">
                                                                            <label className="mb-1">Pattern </label>
                                                                            <small className={`${this.state.hasError && (!this.state.trigger_pattern || this.state.trigger_pattern === "") ? "text-danger" : ""} mr-2`}>(required)</small>
                                                                        </div>
                                                                        <Input
                                                                            type="text"
                                                                            placeholder="Name your automation"
                                                                            className="inputTextbox"
                                                                            readOnly={this.state.disableFields}
                                                                            value={this.state.trigger_pattern}
                                                                            onChange={event => 
                                                                                this.setState({ trigger_pattern: event.target.value },
                                                                                    () => this.formInputProps("trigger", "trigger_pattern", this.state.trigger_pattern)
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                : null}
                                                            </div>
                                                            <div className={`continueButton mt-4 text-center`}>
                                                                <button className={`btn bg-${this.state.triggerInput ? "info" : "gray3 disabled"} p-2`} onClick={() => this.onContinueTrigger() }>
                                                                    {this.state.triggerInput ?
                                                                        "Continue"
                                                                    : 
                                                                        "To continue, fill trigger"
                                                                    }
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </React.Fragment>
                                                : null}
                                                {this.state.showChecksSection && this.state.checksData && this.state.checksData.length ?
                                                    <React.Fragment>
                                                    <div className="lineDown"></div>
                                                    <div className="lineDown"></div>
                                                    <div className="downArrow"><i className="fas fa-caret-down"></i></div>
                                                    <div className={`section ${this.state.activeSection === 4 ? "active" : ""}`}>
                                                        <div className={`sectionHeader ${this.state.activeSection === 4 ? "" : "rounded"}`} onClick={() => this.onClickActiveTab(4)}>
                                                            <div className="d-flex">
                                                                <i className={`far fa-file-alt f30 p-2 border-gray3 align-self-center rounded ${this.state.activeSection === 3 ? "text-white" : ""}`}></i>
                                                                <div className="columnHeading ml-3 align-self-center">
                                                                    <div className="d-flex">
                                                                        <span className="title">4. Troubleshooting Checks</span>
                                                                    </div>
                                                                    <span className="description">Details about Troubleshooting checks to be performed</span>
                                                                </div>
                                                            </div>
                                                            <div className={`d-flex align-self-center text-black5 mt-1 ${this.state.activeSection === 4 ? "" : "displayNone"}`}>
                                                                <i className="fas fa-question-circle mr-4" onClick={() => this.setState({ showHelperSection: this.state.helperModule === "details" ? "" : "troubleShooting", helperModule: "troubleShooting" })}></i>
                                                            </div>
                                                        </div>
                                                        <div className={`sectionBody ${this.state.activeSection === 4 ? "" : "displayNone"}`}>
                                                            {this.state.checksData.map((item, i) => {
                                                                return(
                                                                <div className="d-flex">
                                                                    <h6>{i+1}.</h6>
                                                                    <h6 className="ml-2">{item}</h6>
                                                                </div>
                                                                )
                                                            })}
                                                            <div className={`continueButton mt-4 text-center`}>
                                                                <button className={`btn bg-info p-2`} onClick={() => this.setState({ activeSection: 5 })}>Continue</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </React.Fragment>
                                                : null}

                                                {this.state.showRemediationActionSection ?
                                                    <React.Fragment>
                                                    <div className="lineDown"></div>
                                                    <div className="lineDown"></div>
                                                    <div className="downArrow"><i className="fas fa-caret-down"></i></div>
                                                    <div className={`section ${this.state.activeSection === 5 ? "active" : ""}`}>
                                                        <div className={`sectionHeader ${this.state.activeSection === 5 ? "" : "rounded"}`} onClick={() => this.onClickActiveTab(5)}>
                                                            <div className="d-flex">
                                                                <i className={`far fa-file-alt f30 p-2 border-gray3 align-self-center rounded ${this.state.activeSection === 3 ? "text-white" : ""}`}></i>
                                                                <div className="columnHeading ml-3 align-self-center">
                                                                    <div className="d-flex">
                                                                        <span className="title">{this.state.showChecksSection ? 5 : 4}. Actions</span>
                                                                    </div>
                                                                    <span className="description">Details about actions to be performed</span>
                                                                </div>
                                                            </div>
                                                            <div className={`d-flex align-self-center text-black5 mt-1 ${this.state.activeSection === 5 ? "" : "displayNone"}`}>
                                                                <i className="fas fa-question-circle mr-4" onClick={() => this.setState({ showHelperSection: this.state.helperModule === "remediationAction" ? "" : "remediationAction", helperModule: "remediationAction" })}></i>
                                                            </div>
                                                        </div>
                                                        <div className={`sectionBody ${this.state.activeSection === 5 ? "" : "displayNone"}`}>
                                                            <div className="d-flex mb-3">
                                                                <div className="w-50">
                                                                    <div className="d-flex justify-content-between">
                                                                        <label className="mb-1">Preferred Action </label>
                                                                        <small className={`${this.state.hasError && (!this.state.preffered_action || this.state.preffered_action === "") ? "text-danger" : ""} mr-2`}>(required)</small>
                                                                    </div>
                                                                    <Select 
                                                                        options={this.state.applicable_actions && this.state.applicable_actions.map(item => ({                                                            
                                                                            value: item,
                                                                            label: item,
                                                                        }))}
                                                                        value={({
                                                                            value: this.state.preffered_action,
                                                                            label: this.state.preffered_action ? this.state.preffered_action : <span className="placeholder">Select Preferred Action</span>
                                                                        })}
                                                                        onChange={event => 
                                                                            this.setState({ preffered_action: event.value },
                                                                                () => {
                                                                                    this.formInputProps("actions", "preffered_action", this.state.preffered_action)
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            {this.state.applicable_actions && this.state.applicable_actions.map(appAction => {
                                                                return(
                                                                <div className="p-3 bg-dark3 mt-2 rounded">
                                                                    <label className="text-white">{appAction}</label>
                                                                    {this.state.action_parameters.filter(e => e.action === appAction).length ?
                                                                        this.state.action_parameters.filter(e => e.action === appAction).map(act => {
                                                                            return(
                                                                            act.parameters && act.parameters.map((item, index) => {
                                                                                    return(
                                                                                        <div className="row mt-2">
                                                                                            <div className="col-md-12">
                                                                                                <div className="form-group">
                                                                                                    <div className="d-flex justify-content-between">
                                                                                                        {item.component !== "checkbox" ? 
                                                                                                            <label>{item.label}</label>
                                                                                                        : null}
                                                                                                    </div>
                                                                                                    {item.component === "select" ?
                                                                                                        <React.Fragment>
                                                                                                            <SelectField 
                                                                                                                className="w-50"
                                                                                                                recordSelection={this.updateFormFieldValue}
                                                                                                                value={this.state[item.name+"_"+appAction+"_"+index] ? this.state[item.name+"_"+appAction+"_"+index] : item.value}
                                                                                                                name={[item.name+"_"+appAction+"_"+index]}
                                                                                                                label="Select"
                                                                                                                placeholder="All"
                                                                                                                required={item.mandatory ? true : false}
                                                                                                                unitLabel = {item.unit ? item.unit : ""}
                                                                                                                apiToCall={item.apiToCall? item.apiToCall : {}}
                                                                                                            />
                                                                                                            {this.state.validationError && this.state+[item.name+"_"+appAction+"_"+index+"_validationErrorMessage"] !=="" ? (
                                                                                                                <small className="text-danger">{this.state[item.name+"_"+appAction+"_"+index+"_validationErrorMessage"]}</small>
                                                                                                            ) : null}
                                                                                                        </React.Fragment>
                                                                                                    : (item.component === "checkbox" || item.type === "bool") ? 
                                                                                                        <React.Fragment>
                                                                                                            <Checkbox 
                                                                                                                className="form-check-input"
                                                                                                                recordSelection={this.updateFormFieldValue}
                                                                                                                value={this.state[item.name+"_"+appAction+"_"+index] && this.state[item.name+"_"+appAction+"_"+index] !== "" ? this.state[item.name+"_"+appAction+"_"+index] : item.value}
                                                                                                                name={[item.name+"_"+appAction+"_"+index]}
                                                                                                                label={item.label ? item.label : item.name}
                                                                                                            />
                                                                                                            {this.state.validationError && this.state[item.name+"_"+appAction+"_"+index+"_validationErrorMessage"] !=="" ? (
                                                                                                                <small className="text-danger">{this.state[item.name+"_"+appAction+"_"+index+"_validationErrorMessage"]}</small>
                                                                                                            ) : null}
                                                                                                        </React.Fragment>
                                                                                                    : null
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            )
                                                                        })
                                                                    :
                                                                        <div className="d-flex justify-content-center m-4">
                                                                            <p className="">No Input Requrired</p>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                )
                                                            })}
                                            
                                                            <div className={`mt-4`}>
                                                                <div className={`continueButton mt-4 text-center`}>
                                                                    <button className={`btn bg-${this.state.updateRemedition ? "info" : "gray3 disabled"} p-2 text-white`} onClick={() => this.setState({ actionLoader: true }, () => this.validateInput())}>
                                                                        {this.state.actionLoader ? <Spinner color="white" size="sm" className="mx-1" /> :null}
                                                                        Update Automation
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </React.Fragment>
                                                : null}
                                                {this.state.tabs && this.state.tabs.length ? 
                                                    this.state.tabs.map((item, index) => {
                                                        return(
                                                            <React.Fragment>
                                                                <ActionSection
                                                                    activeSection={item}
                                                                    tabCount={index+4}
                                                                    showAction={this.state["showAction_"+item]}
                                                                    latestTab={this.state.latestTab}
                                                                    selectAction={(section) => this.addAction(section)}
                                                                    showHelper={() => this.setState({ showHelperSection: this.state.helperModule === "action" ? "" : "help", helperModule: "action" })}
                                                                    scrollToSection={(section) => this.scrollToSection(section)}
                                                                    deleteStep={(section) => this.deleteStep(section)}
                                                                    createAutomation={() => this.validateInput()}
                                                                    actionName={(name) => this.enableAddFunction(name)}
                                                                    pageType={this.state.pageType}
                                                                    showHelperSection={(val, page, details) => {
                                                                        this.setState({ 
                                                                            showHelperSection: val,
                                                                            patternCheck: false,
                                                                            diagnosticDetails: "", 
                                                                            databaseDetails: ""
                                                                        },
                                                                            () => {
                                                                                if(page === "showDatabaseSection" && details !== "") {
                                                                                    this.setState({ showHelperSection: ""},
                                                                                        () => this.setState({ databaseDetails: details, showHelperSection: val })
                                                                                    )
                                                                                } else if(page === "patternCheck" && details !== "") { 
                                                                                    this.setState({ databaseDetails: "", patternCheck: true })
                                                                                } else if(page === "showDiagnosticsSection" && details !== "") { 
                                                                                    this.setState({ showHelperSection: ""},
                                                                                        () => this.setState({ diagnosticDetails: details, showHelperSection: val })
                                                                                    )
                                                                                }
                                                                            }
                                                                            // () => this.scrollToTop()
                                                                        )
                                                                    }}
                                                                />
                                                                {this.state.latestTab === item && this.state.enablePlusAction ?
                                                                    <React.Fragment>
                                                                        <div className="lineDown"></div>
                                                                        <div className="position-relative">
                                                                            <div className="circleDiv">
                                                                                <div className="lineOnCircle"></div>
                                                                                <div className="circle" onClick={() => this.addAction(item+1)}><i className="fas fa-plus plusIcon"></i></div>
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                : 
                                                                    null
                                                                }

                                                            </React.Fragment>
                                                        )
                                                    })
                                                : null}
                                            </div>
                                        </div>   
                                        <div className={`${this.state.showHelperSection !== "" ? "right h-100" : "displayNone"}`} style={{marginTop: "45px"}}>
                                            <div className="d-flex col-sm-12 p-0 h-100" id="scrollTop">
                                                <div className="bg-dark">
                                                    <div className={`col-sm-12 py-2 ${this.state.showHelperSection === "help" ? "bg-info text-white" : "cursorPointer"}`} onClick={() => this.setState({ showHelperSection: "help" })}>
                                                        <i className={`fas fa-question f20`} id="help"></i>
                                                        <UncontrolledTooltip target="help">Help</UncontrolledTooltip>
                                                    </div>
                                                    {this.state.databaseDetails && this.state.databaseDetails !== "" ?
                                                        <div className={`col-sm-12 py-2 ${this.state.showHelperSection === "database" ? "bg-info text-white" : "cursorPointer"}`} onClick={() => this.setState({ showHelperSection: "database" })}>
                                                            <i className={`fas fa-database f20`} id="database"></i>
                                                            <UncontrolledTooltip target="database">Database</UncontrolledTooltip>
                                                        </div>
                                                    : null}
                                                    <div className={`col-sm-12 py-2 ${this.state.showHelperSection === "patternCheck" ? "bg-info text-white" : "cursorPointer"}`} onClick={() => this.setState({ showHelperSection: "patternCheck" })}>
                                                        <i className={`fas fa-adjust f20`} id="grokPattern"></i>
                                                        <UncontrolledTooltip target="grokPattern">Validate Grok Pattern</UncontrolledTooltip>
                                                    </div>
                                                    <div className={`col-sm-12 py-2 ${this.state.showHelperSection === "diagnostics" ? "bg-info text-white" : "cursorPointer"}`} onClick={() => this.setState({ showHelperSection: "diagnostics" })}>
                                                        <i className={`fas fa-magic f20`} id="createDiagnostics"></i>
                                                        <UncontrolledTooltip target="createDiagnostics">Create Diagnostics</UncontrolledTooltip>
                                                    </div>
                                                </div>
                                                <div className="w-100 bg-dark3 overflow-auto">
                                                    {this.state.showHelperSection === "help" ?
                                                        <Help 
                                                            activeSection={this.state.activeSection}
                                                            showHelperSection={() => this.setState({ showHelperSection: "", helperModule: "" })}
                                                        />
                                                    : this.state.showHelperSection === "database" ?
                                                        <Database 
                                                            activeSection={this.state.activeSection}
                                                            showHelperSection={() => this.setState({ showHelperSection: "", helperModule: "" })}
                                                            databaseDetails={this.state.databaseDetails}
                                                        />
                                                    : this.state.showHelperSection === "patternCheck" ?
                                                        <PatternCheck 
                                                            activeSection={this.state.activeSection}
                                                            showHelperSection={() => this.setState({ showHelperSection: "", helperModule: "" })}
                                                            databaseDetails={this.state.databaseDetails}
                                                        />
                                                    : this.state.showHelperSection === "diagnostics" ?
                                                        <CreateDiagnostics 
                                                            activeSection={this.state.activeSection}
                                                            showHelperSection={() => this.setState({ showHelperSection: "", helperModule: "" })}
                                                            diagnosticDetails={this.state.diagnosticDetails}
                                                        />
                                                    : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className={`mt-5 pt-5`} ref={this.scrolltoDiv}></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


/**
 * Type of the props used in the component
 */
CreateEditAutomation.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log("-",state)
    return {
        providers: state.filters.providers,
        accounts: state.filters.accounts,
        regions: state.filters.regions,
        automationPropsDetails: state.filters.automationPropsDetails,
        listAutomationActionsProps: state.aiops.listAutomationActionsProps
    }
}

export default AppWrapper(CreateEditAutomation, mapStateToProps, {
    listAllProviders,
    listAllAccounts,
    listAllRegions,
    listEventSources,
    automationListEvents,
    setAutomationPropsDetails,
    listAutomationActions, 
    listAssets,
    createAutomation,
    editAutomation,
    viewAutomation
})