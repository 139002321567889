/*************************************************
 * Tvastar
 * @exports
 * @file ClusterDetails.js
 * @author Rajasekar // on 20/02/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ClusterDetails extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	render() {
		return (
			<div className='box p-3 bg-dark3'>
				<div className='d-flex'>
					<p className='flex-grow-1 text-white'>
						<b>Cluster Details</b>
					</p>
					{!this.props.esClusterStatus ? <p>Multi-AZ: {this.props.dbInstanceMultiAz}</p> : null}
				</div>
				<div className='d-flex justify-content-between'>
					<div>
						{this.props.esClusterStatus ? (
							<p className='text-muted mb-0'>
								Cluster Status: <b className='text-white'>{this.props.esClusterStatus}</b>
							</p>
						) : null}
						{this.props.esDomainEndpointOptions ? (
							<p className='text-muted mb-0'>
								Domain Status: <b className='text-white'>{this.props.esDomainEndpointOptions}</b>
							</p>
						) : null}
						<p className='text-muted mb-2'>
							Documents: <b className='text-white'>-</b>
						</p>
						<p className='text-muted mb-2'>
							Pending Modifications: <b className='text-white'>-</b>
						</p>
					</div>
					<div>
						{this.props.ultraWarmEnabled ? (
							<p className='text-muted mb-0'>
								Ultra Warm: <b className='text-white'>{this.props.ultraWarmEnabled}</b>
							</p>
						) : null}
						{this.props.esAvailabilityZones ? (
							<p className='text-muted mb-0'>
								Availability Zone: <b className='text-white'>{this.props.esAvailabilityZones}</b>
							</p>
						) : null}
						<p className='text-muted mb-2'>
							Free Space: <b className='text-white'>-</b>
						</p>
					</div>
				</div>

				{this.props.esClusterInstanceType ? (
					<div className='table-responsive rounded '>
						<table className='table table-striped table-sm'>
							<thead className='thead'>
								<tr className='bg-light'>
									<th>Node Type</th>
									<th>Node Instance Type</th>
									<th>Nodes</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>-</td>
									<td>{this.props.esClusterInstanceType}</td>
									<td>{this.props.esClusterInstanceCount}</td>
								</tr>
							</tbody>
						</table>
					</div>
				) : null}

				{this.props.dbInstanceMonitoringRoleArn ? (
					<div className='table-responsive rounded '>
						<table className='table table-striped table-sm'>
							<thead className='thead'>
								<tr className='bg-light'>
									<th>DB Instance</th>
									<th>Zone</th>
									<th>Replication source</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>{this.props.dbInstanceMonitoringRoleArn}</td>
									<td>{this.props.dbInstanceAvailabilityZone}</td>
									<td>{this.props.replicationSource}</td>
								</tr>
							</tbody>
						</table>
					</div>
				) : null}
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ClusterDetails.propTypes = {
	dbInstanceMonitoringRoleArn: PropTypes.string,
	dbInstanceAvailabilityZone: PropTypes.string,
	replicationSource: PropTypes.string,
	dbInstanceMultiAz: PropTypes.string,
	esClusterInstanceCount: PropTypes.string,
	esClusterInstanceType: PropTypes.string,
	ultraWarmEnabled: PropTypes.string,
	esAvailabilityZones: PropTypes.string,
	esClusterStatus: PropTypes.string,
	esDomainEndpointOptions: PropTypes.string,
}

export default ClusterDetails
