/*************************************************
 * Tvastar
 * @exports
 * @file ApesMutliyAxis  .js
 * @author Prakash // on 16/07/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { momentDateGivenFormat, addHours, momentConvertionUtcToLocalTime, getConvertedBytesUnit, convertBytes, convertBytesTo, convertBytesWithoutUnit, convertSeconds, convertSecondsWithoutUnit, countFormater, countFormaterWithoutUnit, convertBits, convertBitsWithoutUnit, subDays, getDayFromSelectedDuration, subHours } from '../../utils/utility'
import Chart from 'react-apexcharts'
import { getSreDetails } from '../../actions/aiops/DiagnosticsAction'

import { getMonitoringMetricsByTrend } from '../../actions/aiops/MetricsAction'
import { setDiagnosticsPropsDetails } from '../../actions/aiops/DiagnosticsAction'
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

class MetricsSection extends Component {
    constructor(props) {
        super(props)
        this.annotationTypeRef = React.createRef()
        this.state = {
            showLoading: true,
            sreMetricsDetails: [],
            show_latency: true,
            show_error_rate: true,
            selectedAnnotationTypes: [],
            showAnnotation: true,
        }
    }
    
    componentDidMount = () => {
        let startTime = this.props.diagnostics.start_time ? this.props.diagnostics.start_time : this.props.diagnostics.diagnostic_start_time
        let endTime = this.props.diagnostics.end_time ? this.props.diagnostics.end_time : this.props.diagnostics.diagnostic_start_time
        if(this.props.page && this.props.page === "rca") {
            startTime = this.props.startTime
            endTime = this.props.endTime
        } else {
            endTime = this.props.diagnostics.end_time ? this.props.diagnostics.end_time : this.props.diagnostics.diagnostic_start_time

            let duration = this.props.diagnosticsDetails.duration
            if(duration && duration !== "custom") {
                let period = duration.substring(duration.length, duration.length-1)
                let durationBtw = getDayFromSelectedDuration(duration)
                if(period === "d") {
                    startTime = momentDateGivenFormat(subDays(startTime, durationBtw), "YYYY-MM-DD HH:mm:ss")
                } else {
                    startTime = momentDateGivenFormat(subHours(startTime, durationBtw), "YYYY-MM-DD HH:mm:ss")
                }

            } 
        }
               

        this.setState({ metricsStartTime: startTime, metricsEndTime: endTime },
            () => this.onLoad()    
        )
        
    }

    onLoad = () => {
        if(this.props.metricsType !== 'SRE Observations') {
            if(!this.props.assetDetails || !this.props.assetDetails.resource_type || this.props.assetDetails.resource_type !== 'EC2') {

                let savedSreMetrics = {}
                if(this.props.page && this.props.page === "rca") {
                    savedSreMetrics = this.props.diagnosticRcaSreMertrics
                } else {
                    savedSreMetrics = this.props.diagnosticSreMertrics
                }
                console.log("savedSreMetrics", savedSreMetrics)
                if(Object.entries(savedSreMetrics).length) {
                    if(savedSreMetrics.hasOwnProperty(this.props.assetDetails.asset_id)) {
                        this.setState({ 
                            sreMetricsDetails: savedSreMetrics[this.props.assetDetails.asset_id],
                            showLoading: false
                        },
                            () => {
                                this.formSreMetricsLatencyGraph()
                                this.formSreMetricsRateGraph()
                            }
                        )
                    } else {
                        this.getSreDetails()
                    }
                } else {
                    this.getSreDetails()
                }
            }
        }
        
        let savedMonMetrics = {}
        if(this.props.page && this.props.page === "rca") {
            savedMonMetrics = this.props.diagnosticRcaMonitMertrics
        } else {
            savedMonMetrics = this.props.diagnosticMonitMertrics
        }
        if(Object.entries(savedMonMetrics).length) {
            if(savedMonMetrics.hasOwnProperty(this.props.assetDetails.asset_id)) {
                this.setState({ metricsByTrend: savedMonMetrics[this.props.assetDetails.asset_id], showLoading: false },
                    () => {
                        this.metricsListGraph()
                    }
                )
            } else {
                this.getMonitoringMetricsByTrend()
            }
        } else {
            this.getMonitoringMetricsByTrend()
        }
    }

    getSreDetails = () => {
        if(this.props.assetDetails.asset_id) {
            let params = {}
            params.asset_id = this.props.assetDetails.asset_id
            
            // let endTime = this.props.diagnostics.end_time ?  this.props.diagnostics.end_time :  this.props.diagnostics.diagnostic_start_time
            // params.start_time = momentDateGivenFormat(subDays(endTime, 3), "YYYY-MM-DD HH:mm:ss")
            // params.end_time = endTime
            params.start_time = this.state.metricsStartTime
            params.end_time = this.state.metricsEndTime


            this.props.getSreDetails(params, (promise, response) => {
                if(promise) {
                    this.setState({ 
                        sreMetricsDetails: response,
                        showLoading: false
                    },
                        () => {
                            this.formSreMetricsLatencyGraph()
                            this.formSreMetricsRateGraph()
                            if(this.props.page && this.props.page === "rca") {
                                let obj = {}
                                obj = this.props.diagnosticRcaSreMertrics
                                obj[this.props.assetDetails.asset_id] = response
                                this.props.setDiagnosticsPropsDetails('diagnosticRcaSreMertrics', obj)
                            } else {
                                let obj = {}
                                obj = this.props.diagnosticSreMertrics
                                obj[this.props.assetDetails.asset_id] = response
                                this.props.setDiagnosticsPropsDetails('diagnosticSreMertrics', obj)
                            }
                        }
                    )
                } else {
                    this.setState({ sreMetricsDetails: [], showLoading: false })                    
                }
            })
        } else {
            this.setState({ showLoading: false })
        }
    }

    formSreMetricsLatencyGraph = () => {
        let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']
        if(this.state.sreMetricsDetails && this.state.sreMetricsDetails.length) {
            let filteredSreDetails = this.state.sreMetricsDetails
            filteredSreDetails.forEach((res, index) => {
                let graphData = res.data
                let graphLabels = res.dates
                
                let yDetails = []
                let series =[]
                let colorInc = 0
                graphData.latency &&  Object.entries(graphData.latency).forEach(([key, item], j) => {
                    let yRow = {}
                    let maxValue = item.statistics && item.statistics.max ? item.statistics.max : 1
                    let avgValue = item.statistics && item.statistics.avg ? item.statistics.avg : 1
                    let minValue = item.statistics && item.statistics.min ? item.statistics.min : 1
                    
                    
                    let graphConvertTo = ""

                    let maxRandomNumber = maxValue
                    let minRandomNumber = minValue
                    if(item.unit) {
                        graphConvertTo = this.getConvertedUnit(maxValue, item.unit)
                        maxRandomNumber = this.getGrapUnitValueWithoutUnit(maxValue, item.unit, "round")
                        minRandomNumber = this.getGrapUnitValueWithoutUnit(minValue, item.unit, "round")
                    }
                    if(item.values && item.values.length) {
                        if(!colorInc) {
                            yRow = {
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                    color: '#60687C'
                                },
                                tooltip: {
                                    enabled: false
                                },
                                tickAmount: 2,
                                min: 0,
                                labels: {
                                    offsetX: -10,
                                    show: true,
                                    style: {
                                        fontSize: '9px',
                                        colors: '#60687C'
                                    },
                                    formatter: (value) => { 
                                        return value.toFixed(2)
                                    },
                                }
                            }
                        } else {
                            yRow = {
                                show: false
                            }
                        }
                        yDetails.push(yRow)
                        
                        let dataRow = {}
                        dataRow.name = key
                        dataRow.type = 'line'
                        let plotValue = []
                        graphLabels.forEach((lab, i) => {
                            let dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            let val = item.values[i]
                            if(item.unit && item.unit !== '') {
                                val = this.getGrapUnitValue(val, item.unit, "", graphConvertTo)
                            }
                            dataArray.push(val)
                            plotValue.push(dataArray)
                        })
                        dataRow.data = plotValue
                        series.push(dataRow)

                        colorInc++;
                    }
                })

                let xaxisAnnotation = []
                if(this.state.showAnnotation) {
                    // xaxisAnnotation = [{
                    //     x: new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                    //     strokeDashArray: 0,
                    //     borderColor: '#FF6F00',
                    //     label: {
                    //         borderColor: '#FF6F00',
                    //         style: {
                    //             // fontSize: '12px',
                    //             color: '#fff',
                    //             background: '#FF6F00'
                    //         },
                    //         orientation: 'horizontal',
                    //         text: '',
                    //     }
                    // }
                    // ]
                }
                    
                if(this.state.selectedAnnotationTypes && this.state.selectedAnnotationTypes.length) {
                    let borderColor = ''
                    let background = ''
                    let color = ''
                    let text = ''
                    let location = ''
                    this.state.selectedAnnotationTypes.forEach(item => {
                        text = item.value
                        if(item.value === 'C' || item.value === 'E') {
                            borderColor = '#24A597'
                            background = '#24A597'
                            color = '#fff'
                            location = new Date(momentConvertionUtcToLocalTime(graphLabels[180], 'YYYY-MM-DD HH:mm:ss')).getTime()

                            let dataRow = {
                                x: location,
                                strokeDashArray: 0,
                                borderColor: borderColor,
                                opacity: 1,
                                label: {
                                    borderColor: borderColor,
                                    orientation: 'horizontal',
                                    text: text,
                                    style: {
                                        color: color,
                                        background: background,
                                        fontSize: '10px',
                                        // width: '10px',
                                    },
                                }
                            }
                            xaxisAnnotation.push(dataRow)
                        }
                    })
                }
                
                let annotations = {
                    xaxis: xaxisAnnotation
                }

                let options = {
                    chart: {
                        // group: 'metrics_sre',
                        type: 'line',
                        // id: 'chart_latency_'+index,
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false
                        },                        
                        sparkline: {
                            enabled: false
                        },
                    },
                    colors: colors,
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        width: [2, 3, 4],
                        curve: 'smooth',
                        // dashArray: [0, 8, 5]
                    },
                    xaxis: {
                        // categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
                        type: 'datetime',
                        // categories: labels,
                        labels: {
                            datetimeUTC: false,
                            show: this.props.xaxis,
                            style: {
                                fontSize: '9px',
                                colors: this.props.axisLabelColor
                            },
                        }
                    },
                    yaxis: yDetails,
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            show: false,
                        },
                        
                        x: {
                            show: false
                        },
                        marker: {
                            show: false
                        },
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                                // let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]
                                // let labelName = w.globals.initialSeries[seriesIndex].name
                                // // return '<div className="arrow_box"> '+ capitalizeFirstLetter(labelName) +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' + '  '+ val + '</span> </div>'         
                                let returnData = ''
                                
                                if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                    Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                        if(!index) {
                                            returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                        }
                                        returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'

                                    })
                                }

                                returnData += '</div>'
                                return returnData
                        }
                    },                    
                    grid: {
                        show: true,
                        borderColor: '#2D323E',
                        strokeDashArray: 0,
                        position: 'back',
                        xaxis: {
                            lines: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: ['#FFF'],
                                }
                            },
                            axisTicks: {
                                show: false
                            }
                        },   
                        yaxis: {
                            lines: {
                                show: false
                            },
                            labels: {
                                style: {
                                    colors: ['#969AA7'],
                                }
                            }
                        },                
                        padding: {
                            top: 0,
                            right: 20,
                            bottom: 0,
                            left: 0
                        },
                    },
                    legend: {
                        show: false
                        // horizontalAlign: 'left',
                        // offsetX: 40
                    },
                    annotations: annotations
                }

                this.setState({ ['metrics_series_'+index]: series, ['metrics_options_'+index]: options })
            })
        }
    }

    formSreMetricsRateGraph = () => {
        let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']
        if(this.state.sreMetricsDetails && this.state.sreMetricsDetails.length) {
            let filteredSreDetails = this.state.sreMetricsDetails
            filteredSreDetails.forEach((res, index) => {
                let graphData = res.data
                let graphLabels = res.dates

                let series = []
                
                let  strokeWidth = []
                let x = 0
                
                let yDetails = []
                let yRow = {}
                Object.entries(graphData.error_rate).forEach(([key, item], j) => {
                    strokeWidth.push(x+2)
                    let maxValue = item.statistics && item.statistics.max ? item.statistics.max : 1
                    let avgValue = item.statistics && item.statistics.avg ? item.statistics.avg : 1
                    let minValue = item.statistics && item.statistics.min ? item.statistics.min : 1
                    
                    
                    let graphConvertTo = ""

                    let maxRandomNumber = maxValue
                    let minRandomNumber = minValue
                    if(item.unit) {
                        graphConvertTo = this.getConvertedUnit(maxValue, item.unit)
                        maxRandomNumber = this.getGrapUnitValueWithoutUnit(maxValue, item.unit, "round")
                        minRandomNumber = this.getGrapUnitValueWithoutUnit(minValue, item.unit, "round")
                    }
                    if(item.values && item.values.length) {
                        if(!x) {
                            yRow = {
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                    color: '#60687C'
                                },
                                tooltip: {
                                    enabled: false
                                },
                                tickAmount: 2,
                                min: 0,
                                labels: {
                                    offsetX: -10,
                                    show: true,
                                    style: {
                                        fontSize: '9px',
                                        colors: '#60687C'
                                    },
                                },
                                formatter: (value) => { 
                                    // console.log(value)
                                    if(Number(value) === value && value % 1 === 0) {
                                        return Math.ceil(value)
                                    } else {
                                        return value.toFixed(2)
                                    }
                                },
                            }
                            
                        } else {
                            yRow = {
                                show: false
                            }
                        }
                        let dataRow = {}
                        dataRow.name = key
                        dataRow.type = 'column'
                        
                        let plotValue = []
                        graphLabels.forEach((lab, i) => {
                            let dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            let val = item.values[i]
                            if(item.unit) {
                                val = this.getGrapUnitValue(val, item.unit, "", graphConvertTo)
                            }
                            dataArray.push(val)
                            // dataArray.push(item.values[i])
                            plotValue.push(dataArray)
                        })
                        dataRow.data = plotValue
                        series.push(dataRow)
                    }
                    x++;
                })
                
                Object.entries(graphData.request_rate).forEach(([key, item], j) => {
                    strokeWidth.push(x+2)
                    let maxValue = item.statistics && item.statistics.max ? item.statistics.max : 1
                    let avgValue = item.statistics && item.statistics.avg ? item.statistics.avg : 1
                    let minValue = item.statistics && item.statistics.min ? item.statistics.min : 1
                    
                    
                    let graphConvertTo = ""

                    let maxRandomNumber = maxValue
                    let minRandomNumber = minValue
                    if(item.unit) {
                        graphConvertTo = this.getConvertedUnit(maxValue, item.unit)
                        maxRandomNumber = this.getGrapUnitValueWithoutUnit(maxValue, item.unit, "round")
                        minRandomNumber = this.getGrapUnitValueWithoutUnit(minValue, item.unit, "round")
                    }
                    if(item.values && item.values.length) {
                        yRow = {
                            axisTicks: {
                                show: false,
                            },
                            axisBorder: {
                                show: false,
                                color: '#60687C'
                            },
                            tooltip: {
                                enabled: false
                            },
                            tickAmount: 2,
                            min: 0,
                            labels: {
                                offsetX: -10,
                                show: true,
                                style: {
                                    fontSize: '9px',
                                    colors: '#60687C'
                                },
                            }
                        }
                        yDetails.push(yRow)
                        
                        let dataRow = {}
                        dataRow.name = key
                        dataRow.type = 'line'
                        let plotValue = []

                        graphLabels.forEach((lab, i) => {
                            let dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            let val = item.values[i]
                            if(item.unit) {
                                val = this.getGrapUnitValue(val, item.unit, "", graphConvertTo)
                            }
                            dataArray.push(val)
                            // dataArray.push(item.values[i])
                            plotValue.push(dataArray)
                        })
                        dataRow.data = plotValue
                        series.push(dataRow)
                    }
                    x++;
                })

                let xaxisAnnotation = []
                if(this.state.showAnnotation) {
                    // xaxisAnnotation = [{
                    //     x: new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                    //     strokeDashArray: 0,
                    //     borderColor: '#FF6F00',
                    //     label: {
                    //         borderColor: '#FF6F00',
                    //         style: {
                    //             // fontSize: '12px',
                    //             color: '#fff',
                    //             background: '#FF6F00'
                    //         },
                    //         orientation: 'horizontal',
                    //         text: '',
                    //     }
                    // }
                    // ]
                }
                    
                if(this.state.selectedAnnotationTypes && this.state.selectedAnnotationTypes.length) {
                    let borderColor = ''
                    let background = ''
                    let color = ''
                    let text = ''
                    let location = ''
                    this.state.selectedAnnotationTypes.forEach(item => {
                        text = item.value
                        if(item.value === 'C' || item.value === 'E') {
                            borderColor = '#24A597'
                            background = '#24A597'
                            color = '#fff'
                            location = new Date(momentConvertionUtcToLocalTime(graphLabels[180], 'YYYY-MM-DD HH:mm:ss')).getTime()                        

                            let dataRow = {
                                x: location,
                                strokeDashArray: 0,
                                borderColor: borderColor,
                                opacity: 1,
                                label: {
                                    borderColor: borderColor,
                                    orientation: 'horizontal',
                                    text: text,
                                    style: {
                                        color: color,
                                        background: background,
                                        fontSize: '10px',
                                        // width: '10px',
                                    },
                                }
                            }
                            xaxisAnnotation.push(dataRow)
                        }
                    })
                }
                
                let annotations = {
                    xaxis: xaxisAnnotation
                }

                let options = {
                    chart: {
                        // group: 'metrics_sre',
                        // id: 'chart_rate_'+index,
                        // redrawOnParentResize: true,
                        stacked: true,
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false
                        },
                        sparkline: {
                            enabled: false
                        },
                        type: "line",
                        animations: {
                            enabled: false
                        }
                    },
                    colors: colors,
                    dataLabels: {
                        enabled: false,
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            barHeight: '100%',
                            // borderRadius: 8,
                            startingShape: 'flat',
                            endingShape: 'flat',
                            columnWidth: '60%',
                            distributed: false,
                            rangeBarOverlap: false,
                        }
                    },
                    stroke: {
                        width: strokeWidth,
                        curve: 'smooth',
                    },
                    xaxis: {
                        show: this.props.xaxis,
                        type: 'datetime',
                        // categories: labels,
                        labels: {
                            datetimeUTC: false,
                            show: this.props.xaxis,
                            style: {
                                fontSize: '9px',
                                colors: this.props.axisLabelColor
                            },
                        },
                    },
                    yaxis: yDetails,
                    grid: {
                        show: true,
                        borderColor: '#2D323E',
                        strokeDashArray: 0,
                        position: 'back',
                        xaxis: {
                            lines: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: ['#FFF'],
                                }
                            },
                            axisTicks: {
                                show: false
                            }
                        },   
                        yaxis: {
                            lines: {
                                show: false
                            },
                            labels: {
                                style: {
                                    colors: ['#969AA7'],
                                }
                            }
                        },                
                        padding: {
                            top: 0,
                            right: 20,
                            bottom: 0,
                            left: 0
                        },
                    },
                    legend: {
                        show: false
                    },
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            show: false,
                        },                        
                        x: {
                            show: false
                        },
                        marker: {
                            show: false
                        },
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                                // let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]
                                // let labelName = w.globals.initialSeries[seriesIndex].name
                                // // return '<div className="arrow_box"> '+ capitalizeFirstLetter(labelName) +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' + '  '+ val + '</span> </div>'         
                                let returnData = ''
                                
                                if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                    Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                        if(!index) {
                                            returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                        }
                                        returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'

                                    })
                                }

                                returnData += '</div>'
                                return returnData
                        }
                    },
                    annotations: annotations
                }

                this.setState({ ['metrics_rate_series_'+index]: series, ['metrics_rate_options_'+index]: options })
            })

        }
    }  

    getMonitoringMetricsByTrend = () => {
        if(this.props.assetDetails.asset_id) {
            let params = {}
            params.provider = this.props.selectedEvent && this.props.selectedEvent.provider ? this.props.selectedEvent.provider : 'aws'
            params.anomaly = true
            if(this.props.assetDetails.asset_id) {
                params.asset_id = this.props.assetDetails.asset_id
            } else {
                params.asset_name = [this.props.assetDetails.asset_name]
                params.service_name = this.props.assetDetails.service_name
            }

            let topMertrics = 3
            if(this.props.metricsType === 'SRE Observations' || (this.props.assetDetails && this.props.assetDetails.resource_type && this.props.assetDetails.resource_type === 'EC2')) {
                topMertrics = 6
            }
            params.top = topMertrics

            params.start_time = this.state.metricsStartTime
            params.end_time = this.state.metricsEndTime
            
            this.props.getMonitoringMetricsByTrend(params, (promise, result) => {
                if(promise) {
                    this.setState({ metricsByTrend : [] },
                        () => {
                            this.setState({ metricsByTrend: result, showLoading: false },
                                () => {
                                    this.metricsListGraph()
                                    if(this.props.page && this.props.page === "rca") {
                                        let obj = {}
                                        obj = this.props.diagnosticRcaMonitMertrics
                                        obj[this.props.assetDetails.asset_id] = result
                                        this.props.setDiagnosticsPropsDetails('diagnosticRcaMonitMertrics', obj)
                                    } else {
                                        let obj = {}
                                        obj = this.props.diagnosticMonitMertrics
                                        obj[this.props.assetDetails.asset_id] = result
                                        this.props.setDiagnosticsPropsDetails('diagnosticMonitMertrics', obj)
                                    }
                                }
                            )
                        }
                    )
                } else {
                    this.setState({ showLoading: false })
                }
            })
        } else {
            this.setState({ showLoading: false })
        }
    }

    metricsListGraph = () => {
        let graphDetails = this.state.metricsByTrend
        if(graphDetails) {
            graphDetails.forEach((graph, index) => {
                let graphData = graph.data
                let graphDates = graph.dates
                            
                
                Object.entries(graphData).forEach(([key, item]) => {
                    let array = []
                    let graphLabels = []

                    let maxValue = item.statistics && item.statistics.max ? item.statistics.max : 1
                    let avgValue = item.statistics && item.statistics.avg ? item.statistics.avg : 1
                    let minValue = item.statistics && item.statistics.min ? item.statistics.min : 1
                    
                    
                    let graphConvertTo = ""

                    let maxRandomNumber = maxValue
                    let minRandomNumber = minValue
                    if(item.unit) {
                        graphConvertTo = this.getConvertedUnit(maxValue, item.unit)
                        maxRandomNumber = this.getGrapUnitValueWithoutUnit(maxValue, item.unit, "round")
                        minRandomNumber = this.getGrapUnitValueWithoutUnit(minValue, item.unit, "round")
                    }

                    item.values.forEach((val, j) => {
                        graphLabels.push(momentConvertionUtcToLocalTime(graphDates[j], 'YYYY-MM-DD HH:mm:ss'))
                        let arrayValues = []
                        arrayValues.push(momentConvertionUtcToLocalTime(graphDates[j], 'YYYY-MM-DD HH:mm:ss'))
                        if(item.unit) {
                            val = this.getGrapUnitValue(val, item.unit, "", graphConvertTo)
                        }
                        arrayValues.push(val)
                        array.push(arrayValues)
                    })

                    if(array.length === 1) {
                        let arrayValues = []
                        arrayValues.push(addHours(momentConvertionUtcToLocalTime(graphDates[0], 'YYYY-MM-DD HH:mm:ss'), 0.5))
                        arrayValues.push(0)
                        array.push(arrayValues)
                    }

                    let series = [{
                        data: array,
                        name: key,
                    }]
                    let xaxisAnnotation = []
                    if(this.state.showAnnotation) {
                        // xaxisAnnotation = [{
                        //     x: new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                        //     strokeDashArray: 0,
                        //     borderColor: '#FF6F00',
                        //     label: {
                        //         borderColor: '#FF6F00',
                        //         style: {
                        //             // fontSize: '12px',
                        //             color: '#fff',
                        //             background: '#FF6F00'
                        //         },
                        //         orientation: 'horizontal',
                        //         text: '',
                        //     }
                        // }
                        // ]
                    }
                        
                    if(this.state.selectedAnnotationTypes && this.state.selectedAnnotationTypes.length) {
                        let borderColor = ''
                        let background = ''
                        let color = ''
                        let text = ''
                        let location = ''
                        this.state.selectedAnnotationTypes.forEach(item => {
                            text = item.value
                            if(item.value === 'C' || item.value === 'E') {
                                borderColor = '#24A597'
                                background = '#24A597'
                                color = '#fff'
                                location = new Date(momentConvertionUtcToLocalTime(graphLabels[180], 'YYYY-MM-DD HH:mm:ss')).getTime()                        

                                let dataRow = {
                                    x: location,
                                    strokeDashArray: 0,
                                    borderColor: borderColor,
                                    opacity: 1,
                                    label: {
                                        borderColor: borderColor,
                                        orientation: 'horizontal',
                                        text: text,
                                        style: {
                                            color: color,
                                            background: background,
                                            fontSize: '10px',
                                            // width: '10px',
                                        },
                                    }
                                }
                                xaxisAnnotation.push(dataRow)
                            }
                        })
                    }
                    
                    let annotations = {
                        xaxis: xaxisAnnotation
                    }

                    let options = {
                        chart: {
                            // group: 'metrics_and_sre',
                            // id:'metrics_'+i,
                            type: 'area',
                            width: '100%',
                            toolbar: {
                                show: false,
                            },
                            zoom: {
                                enabled: false
                            },
                            sparkline: {
                                enabled: false
                            }
                        },
                        stroke: {
                            show: true,
                            curve: 'smooth',
                            lineCap: 'butt',
                            width: 2,
                            dashArray: 0,      
                        },
                        colors: ['#775BA2'],
                        fill: {
                            type: "gradient",
                            gradient: {
                                // shadeIntensity: 1,
                                // opacityFrom: 0.7,
                                // opacityTo: 0.9,
                                // stops: [0, 90, 100]
                                gradientToColors: ['#775BA2'],
                                shadeIntensity: 0,
                                opacityFrom: .3,
                                opacityTo: 1,
                                stops: [30, 90]
                            }
                        },
                        tooltip: {
                            custom: function({series, seriesIndex, dataPointIndex, w}) {
                                let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
                                dateLable = dateLable.toString()
                                dateLable = momentDateGivenFormat(dateLable, 'DD MMM HH:mm')
                                let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]
                                return '<div class="arrow_box">'+dateLable+' <span style="color:'+ w.globals.colors +'">' + parseFloat(val).toFixed(2) + '</span> </div>'
                            },
                            fixed: {
                                enabled: false
                            },
                            x: {
                                show: false,
                            },
                            y: {
                                show: false
                            },        
                            marker: {
                                show: false
                            }
                        },
                        xaxis: {
                            type: 'datetime',
                            labels: {
                                datetimeUTC: false,
                                style: {
                                    colors: '#60687C'
                                },
                                // formatter: function (value) {
                                //     return momentDateGivenFormat(value, 'HH:mm')
                                // },
                            },                            
                            axisTicks: {
                                show: false
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        yaxis: {
                            show: true,
                            axisTicks: {
                                show: false,
                            },
                            axisBorder: {
                                show: false,
                                color: '#60687C'
                            },
                            tooltip: {
                                enabled: false
                            },
                            // tickAmount: 2,
                            min: 0,
                            // max: yaxisMaxValue, 
                            tickAmount: 1,
                            labels: {
                                offsetX: -10,
                                // minWidth: 50,
                                show: true,
                                style: {
                                    fontSize: '9px',
                                    colors: '#60687C'
                                },
                                // formatter: (value) => { 
                                //     if(Number(value) === value && value % 1 === 0) {
                                //         return Math.ceil(value)
                                //     } else {
                                //         return Math.ceil(value)
                                //     }
                                // },
                                // offsetX: 0,
                                // offsetY: 0,
                            }
                        },
                        grid: {
                            show: true,
                            borderColor: '#2D323E',
                            strokeDashArray: 0,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: true
                                },
                                labels: {
                                    style: {
                                        colors: ['#FFF'],
                                    }
                                },
                                axisTicks: {
                                    show: false
                                }
                            },   
                            yaxis: {
                                lines: {
                                    show: false
                                },
                                labels: {
                                    style: {
                                        colors: ['#969AA7'],
                                    }
                                }
                            },                
                            padding: {
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0
                            },
                        },
                        annotations: annotations,
                    }
                    this.setState({ [key+'_'+graph.asset_id]: series, [key+'_options_'+graph.asset_id]: options })
                })
            })
        }
    }
    
    getMultiSelectedCount = (type, array) => {
        if(array) {
            return array.length && array.includes('All') ? 'All' :  array.length ? array.length +' Selected' : 'All'
        } else {
            return 'All'
        }
    }

    handleMultiSelectChange = (childKey, arrayValue) => {
        // let value = arrayValue.map(item => item.value)
        // let label = arrayValue.map(item => item.label)

        if(childKey === 'annotationType') {
            this.setState({ selectedAnnotationTypes: arrayValue, showMeticsChart: false },
                () => {
                    this.setState({ showMeticsChart: true },
                        () => { 
                            this.metricsListGraph()
                            this.formSreMetricsLatencyGraph()
                            this.formSreMetricsRateGraph()
                        }
                    )
                }    
            )
        }
    }
	
	handleClickOutside(event) {
        // if (this.annotationTypeRef && !this.annotationTypeRef.current.contains(event.target)) {
        //     this.setState({ isannotationTypeOpen: false })
        // } else {
        //     this.setState({ isannotationTypeOpen: true })
        // }
    }

    getConvertedUnit = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = getConvertedBytesUnit(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                // val = getConvertedSecondsUnit(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                // val = getConvertedBitsUnit(val, unit)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                // val = getConvertedCountFormater(val)
            } else {
                val = ""
            }
        } else if(val !== '') {           
            val = ""
        }
        return val
    }

    getGrapUnitValue = (val ,unit, valueType, convertTo) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseInt(val))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                if(convertTo) {
                    val = convertBytesTo(val, unit, valueType, convertTo)
                } else {
                    val = convertBytes(val, unit, valueType)
                }                         
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit, valueType, convertTo)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit, valueType, convertTo)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    getGrapUnitValueWithoutUnit = (val ,unit, valueType) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytesWithoutUnit(val, unit, valueType)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSecondsWithoutUnit(val, unit, valueType)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBitsWithoutUnit(val, unit, valueType)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                val = countFormaterWithoutUnit(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseInt(val))
        }
        return val
    }

    render() {
        return (
            !this.state.showLoading ?
                <div class={`bg-dark rounded p-3`} onClick={ (event) => { this.handleClickOutside(event) } }>
                    <div class="d-flex justify-content-between">
                        <div>
                            <p class="f16 text-white mb-1">Metrics</p>
                            {this.state.metricsByTrend && this.state.metricsByTrend.length ?
                                <div className="d-flex">
                                    <p class="f12 text-white mb-0">Key Metrics for {this.props.assetDetails.resource_type ? this.props.assetDetails.resource_type : ''}</p>
                                    <p class="f12 text-white mb-0 ml-2">{this.props.assetDetails.asset_name ? this.props.assetDetails.asset_name : ''}</p>
                                </div>
                            : null}
                        </div>
                        <div className="d-flex">
                            <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{"("+momentConvertionUtcToLocalTime(this.state.metricsStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentConvertionUtcToLocalTime(this.state.metricsEndTime, 'DD MMM YYYY HH:mm')+")"}</p>
                            {/* <span className={`mr-2 align-self-center f12 badge badge-info mr-3`}>3D</span>                         */}
                            {/* <div className={`multiSelection-fullWidth z999 removeDropdownSearchBar align-self-center position-relative`} ref={this.annotationTypeRef}>
                                <ReactMultiSelectCheckboxes						
                                    placeholderButtonLabel="All"
                                    getDropdownButtonLabel={() => this.getMultiSelectedCount('nodeTypes', this.state.selectedAnnotationTypes)}
                                    menuIsOpen ={this.state.isannotationTypeOpen}
                                    value={this.state.selectedAnnotationTypes.map(item => ({
                                        value: item.value,
                                        // label: <div className="d-flex"><span className="badge badge-secondary">{item.prefix}</span> <span className="badge badge-secondary">{item.label}</span></div>
                                    }))}
                                    onChange={arr => {
                                    this.handleMultiSelectChange('annotationType', arr ? arr : []) }}
                                    options={this.props.annotaionOptions && this.props.annotaionOptions.map(itm => ({
                                        value: itm.value,
                                        label: <span><span className={`badge-square font-weight-bold f11 bg-dark-theme-${(itm.prefix === 'C' || itm.prefix === 'E') ? 'green' : itm.prefix === 'CE' ? 'orange' : itm.prefix === 'CS' ? 'orange' : 'blue'}`}>{itm.prefix}</span>{itm.label}</span>,
                                    }))}
                                />
                            </div> */}
                        </div>
                    </div>
                    <div class="container-fluid mt-2">
                    {this.props.metricsType === 'SRE Observations' || (this.props.assetDetails && this.props.assetDetails.resource_type && this.props.assetDetails.resource_type === 'EC2') ?
                        <div className="">
                            <div class="row">
                                {this.state.metricsByTrend && this.state.metricsByTrend.length ?
                                    this.state.metricsByTrend.map((item, index) => {
                                        return(
                                        Object.keys(item.data).map((key, j) => {
                                            return (
                                                <div class={`mb-2 col-sm-6 ${j % 2 === 0 ? 'px-0' : ''}`}>
                                                    <div class="p-3 border rounded">
                                                        <p class="text-white f16 p-1 mb-0">{key}</p>
                                                        {/* <div class="rounded text-info bg-muted px-2 py-1 f16 d-table">{key}</div> */}
                                                        {/* <p class="f12 mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing.</p> */}
                                                        
                                                        {this.state[key+'_options_'+item.asset_id] && Object.keys(this.state[key+'_options_'+item.asset_id]).length && this.state[key+'_'+item.asset_id] && this.state[key+'_'+item.asset_id].length ?
                                                            <div className="cursorPointer transparentTooltip mb-n4 mt-n4">
                                                                <Chart options={this.state[key+'_options_'+item.asset_id]} series={this.state[key+'_'+item.asset_id]} type="area" height={115} width={'100%'}/>
                                                            </div>
                                                        : null}
                                                    </div>
                                                </div>
                                            )
                                        })
                                        )
                                    })
                                : 
                                    <div className='d-flex justify-content-center m-4'>
                                        No Metrics for the this criteria, Please adjust your filter to get the metric details.
                                    </div>
                                }
                            </div>
                        </div>
                    : this.props.metricsType !== 'SRE Observations' ?
                        (this.state.metricsByTrend && this.state.metricsByTrend.length) || (this.state.sreMetricsDetails && this.state.sreMetricsDetails.length) ?
                            <div class="row">
                                <div class="mb-2 col-sm-6 px-0">
                                    {this.state.metricsByTrend && this.state.metricsByTrend.length ?
                                        this.state.metricsByTrend.map((item, index) => {
                                            return(
                                            Object.keys(item.data).map(key => {
                                                return (
                                                <div class="p-3 border rounded mb-3">
                                                    <p class="text-white f16 p-1 mb-0">{key}</p>
                                                    {/* <div class="rounded text-info bg-muted px-2 py-1 f16 d-table">{key}</div> */}
                                                    {/* <p class="f12 mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing.</p> */}
                                                    
                                                    {this.state[key+'_options_'+item.asset_id] && Object.keys(this.state[key+'_options_'+item.asset_id]).length && this.state[key+'_'+item.asset_id] && this.state[key+'_'+item.asset_id].length ?
                                                        <div className="cursorPointer transparentTooltip mb-n4 mt-n4">
                                                            <Chart options={this.state[key+'_options_'+item.asset_id]} series={this.state[key+'_'+item.asset_id]} type="area" height={115} width={'100%'}/>
                                                        </div>
                                                    : null}
                                                </div>
                                                )
                                            })
                                            )
                                        })
                                    : null}
                                </div>
                                <div class="mb-2 col-sm-6">
                                    {this.state.sreMetricsDetails && this.state.sreMetricsDetails.length ? 
                                        this.state.sreMetricsDetails.map((res, index) => {
                                            return(
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <div class="mb-3 col-12 px-0">
                                                        <div class="p-3 border rounded">
                                                            <p class="f16 text-white m-0 p-1">Latency</p>
                                                            {this.state['metrics_series_'+index] ?
                                                                <div className={`${this.props.className}`}>
                                                                    <Chart options={this.state['metrics_options_'+index]} series={this.state['metrics_series_'+index]} type="line" height={this.props.height} />
                                                                </div>
                                                            : null}    
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="mb-3 col-12 px-0">
                                                        <div class="p-3 border rounded">
                                                            <p class="f16 text-white m-0 p-1">API Error/Request Rate</p>
                                                            {this.state['metrics_rate_series_'+index] ?
                                                                <div className={`${this.props.className}`}>
                                                                    <Chart options={this.state['metrics_rate_options_'+index]} series={this.state['metrics_rate_series_'+index]} type="line" height={this.props.height} />
                                                                </div>
                                                            : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                        })
                                    : null}
                                </div>
                            </div>
                        : 
                            <div className='d-flex justify-content-center m-4'>
                                No Metrics for the this criteria, Please adjust your filter to get the metric details.
                            </div>
                    : null}
                </div>
                </div>
            : 
                <div className={`bg-dark rounded p-3`}>
                    <p className={`mb-2 text-white`}><b>Metrics</b></p>
                    <div className='d-flex justify-content-center m-4'>
                        <Spinner className='text-center' color='white' size='lg' />
                    </div>
                </div>
        )
    }
}

/**
* Type of the props used in the component
*/
MetricsSection.propTypes = {
    getSreDetails: PropTypes.func,
    getMonitoringMetricsByTrend: PropTypes.func,
    setDiagnosticsPropsDetails: PropTypes.func
}   

const mapStateToProps = state => {
    return {
        diagnosticMonitMertrics: state.aiops.diagnosticsPropsDetails && state.aiops.diagnosticsPropsDetails.diagnosticMonitMertrics ? state.aiops.diagnosticsPropsDetails.diagnosticMonitMertrics : {},

        diagnosticSreMertrics: state.aiops.diagnosticsPropsDetails && state.aiops.diagnosticsPropsDetails.diagnosticSreMertrics ? state.aiops.diagnosticsPropsDetails.diagnosticSreMertrics : {},

        diagnosticRcaMonitMertrics: state.aiops.diagnosticsPropsDetails && state.aiops.diagnosticsPropsDetails.diagnosticRcaMonitMertrics ? state.aiops.diagnosticsPropsDetails.diagnosticRcaMonitMertrics : {},

        diagnosticRcaSreMertrics: state.aiops.diagnosticsPropsDetails && state.aiops.diagnosticsPropsDetails.diagnosticRcaSreMertrics ? state.aiops.diagnosticsPropsDetails.diagnosticRcaSreMertrics : {},

        diagnosticsDetails: state.aiops.aiopsDiagnosticsDetails && state.aiops.aiopsDiagnosticsDetails.result ? state.aiops.aiopsDiagnosticsDetails.result : [],
    }
}

export default connect(mapStateToProps, {
    getSreDetails,
    getMonitoringMetricsByTrend,
    setDiagnosticsPropsDetails
})(withRouter(MetricsSection))