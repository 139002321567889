/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from 'react'
import { listAllServices } from '../../../actions/commonAction'
import { setEditRuleDetails } from '../../../actions/governance/governanceAction'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Label, CustomInput } from 'reactstrap'

class ServiceToUse extends Component {
	constructor(props) {
		super(props)
		this.state = {
			decision: 'allow',
			selectedTypes: [],
			selectedState: {},
			services: [],
		}
	}

	componentDidMount = () => {
		this.props.listAllServices({ provider: this.props.parameters.provider }, (success, services) => {
			if (success) {
				this.setState({ services })
			}
		})
		if (this.props.editRuleDetails.condition.allow && this.props.editRuleDetails.condition.allow.length > 0) {
			this.setState({ decision: 'allow', selectedTypes: this.props.editRuleDetails.condition.allow })
			let tmp = {}
			this.props.editRuleDetails.condition.allow.forEach(item => {
				tmp[item] = true
			})
			this.setState({ selectedState: tmp })
		} else {
			const { parameters } = this.props
			if (parameters) {
				if (parameters.allow && parameters.allow.length > 0) {
					this.setState({ decision: 'allow', selectedTypes: parameters.allow })
					let tmp = {}
					parameters.allow.forEach(item => {
						tmp[item] = true
					})
					this.setState({ selectedState: tmp })
				}
			}
		}
		if (this.props.editRuleDetails.condition.deny && this.props.editRuleDetails.condition.deny.length > 0) {
			this.setState({ decision: 'deny', selectedTypes: this.props.editRuleDetails.condition.deny })
			let tmp = {}
			this.props.editRuleDetails.condition.deny.forEach(item => {
				tmp[item] = true
			})
			this.setState({ selectedState: tmp })
		} else {
			const { parameters } = this.props
			if (parameters) {
				if (parameters.deny && parameters.deny.length > 0) {
					this.setState({ decision: 'allow', selectedTypes: parameters.deny })
					let tmp = {}
					parameters.deny.forEach(item => {
						tmp[item] = true
					})
					this.setState({ selectedState: tmp })
				}
			}
		}
	}

	handleChange = event => {
		let condition = {}
		if (event.target.name === 'decision') {
			this.setState({ decision: event.target.value })
			condition[event.target.value] = this.state.selectedTypes
		}
		this.props.setEditRuleDetails('condition', condition)
		if (event.target.type === 'checkbox') {
			let name = event.target.name
			this.setState({ selectedState: { ...this.state.selectedState, [name]: event.target.checked } })

			if (event.target.checked) {
				let tmp = this.state.selectedTypes
				tmp.push(event.target.name)
				this.setState({ selectedTypes: tmp })
			} else {
				let tmp = this.state.selectedTypes
				_.pull(tmp, event.target.name)
				this.setState({ selectedTypes: tmp })
			}
			condition[this.state.decision] = this.state.selectedTypes
		}
		this.props.setEditRuleDetails('condition', condition)
	}

	// dividedServiceTypes = service_types => {
	// 	let dividedServiceTypes = []
	// 	for (let index = 0; index < service_types.length; index += 4) {
	// 		if (index < service_types.length) {
	// 			dividedServiceTypes.push(service_types.slice(index, index + 4))
	// 		}
	// 	}
	// 	return dividedServiceTypes
	// }

	render() {
		// const serviceTypes = this.dividedServiceTypes(this.props.condition.services)
		return (
			<div>
				<div className='w-50'>
					<Label for='exampleCustomSelect'>Decision</Label>
					<small className='text-muted'>Lorem ipsum dolor sit amet aedtr.</small>

					<CustomInput
						type='select'
						name='decision'
						id='decision-native-simple'
						onChange={e => this.handleChange(e)}
						value={this.state.decision}
					>
						<option value='allow'>Allow</option>
						<option value='deny'>Deny</option>
					</CustomInput>
				</div>
				<div className='mt-3 font-weight-bold'> Select services to use:</div>

				<div className='row mt-3 col-12'>
					{this.state.services &&
						this.state.services.map(service_type => {
							return (
								<div key={service_type.instanceType} className='col-2'>
									<div className='custom-control custom-checkbox'>
										<CustomInput
											type='checkbox'
											name={service_type.service_name}
											id={service_type.service_name}
											label={service_type.service_name}
											onChange={e => this.handleChange(e)}
											checked={this.state.selectedState[service_type.service_name] === true ? true : false}
										/>
									</div>
								</div>
							)
						})}
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ServiceToUse.propTypes = {
	parameters: PropTypes.object,
	setEditRuleDetails: PropTypes.func,
	listAllServices: PropTypes.func,
	editRuleDetails: PropTypes.object,
	condition: PropTypes.object,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		editRuleDetails: state.governance.editRuleDetails,
	}
}

export default connect(mapStateToProps, { setEditRuleDetails, listAllServices })(ServiceToUse)
