/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AlertAnalysis.js
 * @author Prakash // on 08/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
*************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import _ from 'lodash'
import { momentConvertionLocalToUtcTime } from '../../../../utils/utility'

import { setAiopsPropsDetails } from '../../../../actions/aiops/AiopsAction'
import { getObservabilityAlertsDailyTrend, getObservabilityTopAlerts, getObservabilityAlertsTotalCount } from '../../../../actions/aiops/ObservabilityAction'

import ApexDonutChart from '../../../common/charts/ApexDonutChart'
import ApexBarChart from '../../../common/charts/ApexBarChart'
import ApexTrendBarChart from '../../../common/charts/ApexTrendBarChart'

class AlertAnalysis extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            selectedTab: 'Analysis',
            selectedTopErrorBy: 'service_asset',
            start_time: this.props.aiopsCommonPageFilter.start_time ? this.props.aiopsCommonPageFilter.start_time : this.props.start_time,
            end_time: this.props.aiopsCommonPageFilter.end_time ? this.props.aiopsCommonPageFilter.end_time : this.props.end_time,
        }
    }

    componentDidMount = () => {
        if(this.props.start_time && this.props.start_time !== "") {
            this.onSearch()
        } else if(this.props.aiopsCommonPageFilter && this.props.aiopsCommonPageFilter.start_time && this.props.aiopsCommonPageFilter.start_time !== "") {
            this.onSearch()
        }

        if(this.props.alertsTrend) {
            // this.alertsTrendGraph()
            let graphData = {
                start_time: momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'), 
                end_time: momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss')
            }
            let graphDetails = this.props.alertsTrend.results            
            if(graphDetails && graphDetails.data && graphDetails.data.count) {
                graphData = {
                    data: graphDetails.data.count,
                    labels: graphDetails.labels,
                    start_time: momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'), 
                    end_time: momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss')
                }
            }
            this.setState({ graphData })
        }
    }

    onSearch = () => {
        this.setState({
            showDonut: false,
            leftSideTopAlerts: {}, rightSideTopAlerts: {}
        }, () => {
            this.getObservabilityAlertsDailyTrend()
            this.getObservabilitySeverityServiceTotalCount()
            this.getObservabilityAlertsTotalCount()

            this.getObservabilityTopAssets()
            this.getObservabilityTopAlerts()
        })
    }

    getObservabilityAlertsDailyTrend = () => {
        let params = {
            "start_time": momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'),
            "end_time": momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss'),
            "alert_type_distribution":true
        }

        this.props.getObservabilityAlertsDailyTrend(params, (promise, response) => {
            if(promise) {
                this.setState({ alertsWiseTrendData: response && response.results ? response.results : {} },
                    () => this.alertsWiseTrendGraph()
                )
            }
        })
    }

    alertsWiseTrendGraph = () => {
        let startTime = momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss')
        let endTime = momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss')
        

        if(this.state.alertsWiseTrendData) {
            
            //Application changes starts
            let data = this.state.alertsWiseTrendData["Application"].data && this.state.alertsWiseTrendData["Application"].data.count && this.state.alertsWiseTrendData["Application"].data.count.length ? this.state.alertsWiseTrendData["Application"].data.count : [0, 0]
            
            let labels = this.state.alertsWiseTrendData["Application"].labels && this.state.alertsWiseTrendData["Application"].labels.length ? this.state.alertsWiseTrendData["Application"].labels : [startTime, endTime]
            
            let graphData = {
                data,
                labels,
                start_time: startTime,
                end_time: endTime
            }

            this.setState({ Application_alert_trend: graphData })
            //Application changes end


            //Infra changes starts
            data = this.state.alertsWiseTrendData["Infra"].data && this.state.alertsWiseTrendData["Infra"].data.count && this.state.alertsWiseTrendData["Infra"].data.count.length ? this.state.alertsWiseTrendData["Infra"].data.count : [0, 0]
            labels = this.state.alertsWiseTrendData["Infra"].labels && this.state.alertsWiseTrendData["Infra"].labels.length ? this.state.alertsWiseTrendData["Infra"].labels : [startTime, endTime]
            graphData = {
                data,
                labels,
                start_time: startTime,
                end_time: endTime
            }

            this.setState({ Infra_alert_trend: graphData })
            //Infra changes end
        }
    }

    getObservabilityTopAssets = () => {
        let params = {
            "start_time": momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'),
            "end_time": momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss'),
            'top': 5,
            'aggregate_by': [["asset_name"]]
        }

        this.props.getObservabilityTopAlerts(params, (promise, response) => {
            if(promise) {
                this.setState({ topAssets: response.length && response[0].data ? response[0].data : {} },
                    () => this.topAssetsSectionGraph()
                )
            }
        })
    }

    topAssetsSectionGraph = () => {
        if(this.state.topAssets['asset_name']) {
            let list = this.state.topAssets['asset_name']
            let labels = Object.keys(list).sort(function(a,b){return list[b] - list[a]})

            let topStatusData = {}
            let data = []
            labels.forEach(item => {
                data.push(list[item])
            })
            
            for(let i=labels.length; i<5; i++) {
                data.push(0)
                labels.push('empty')
            }

            topStatusData.data = data
            topStatusData.labels = labels
            this.setState({ topAssets: topStatusData, rightSideTopAlerts: topStatusData })
        }        
    }

    getObservabilityTopAlerts = () => {
        let params = {
            "start_time": momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'),
            "end_time": momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss'),
            'top': 5,
            'aggregate_by': ["alert_type",["event_name"]]
        }

        this.props.getObservabilityTopAlerts(params, (promise, response) => {
            if(promise) {
                this.setState({ topAlerts: response },
                    () => this.topAlertsSectionGraph()
                )
            }
        }) 
    }

    topAlertsSectionGraph = () => {
        if(this.state.topAlerts && this.state.topAlerts.length) {
            this.state.topAlerts.forEach(item => {
                let list = item.data && item.data.event_name ? item.data.event_name : {}
                let labels = Object.keys(list).sort(function(a,b){return list[b] - list[a]})

                let topStatusData = {}
                let data = []
                labels.forEach(item => {
                    data.push(list[item])
                })
                
                for(let i=labels.length; i<5; i++) {
                    data.push(0)
                    labels.push('empty')
                }

                topStatusData.data = data
                topStatusData.labels = labels

                this.setState({ [item.alert_type+"_alerts"]: topStatusData })

            })
        } else {
            let topStatusData = {}
            let data = []
            let labels = []
            for(let i=labels.length; i<5; i++) {
                data.push(0)
                labels.push('empty')
            }

            topStatusData.data = data
            topStatusData.labels = labels

            this.setState({ Application_alerts: topStatusData, Infra_alerts: topStatusData })
        }       
    }

    onChangeTopErrosType = () => {
        if(this.state.selectedTopErrorBy === 'service_asset') {
            this.setState({ leftSideTopAlerts: this.state.topServices, rightSideTopAlerts: this.state.topAssets })   
        } else {
            this.setState({ leftSideTopAlerts: this.state.topCause, rightSideTopAlerts: this.state.topImpact })   
        }
    }

    getObservabilitySeverityServiceTotalCount = () => {
        let params = {
            "start_time": momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'),
            "end_time": momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss'),
            'radial': true,
            'aggregate_by': [["severity", "resource_type"]]
        }

        this.props.getObservabilityAlertsTotalCount(params, (promise, response) => {
            if(promise) {
                this.setState({ severityServiceData: response.results && response.results.length && response.results[0].data ? response.results[0].data : {} },
                    () => this.severityServiceDonutGraph()    
                )
            }
        }) 
    }

    severityServiceDonutGraph = () => { 
        //services Donut start
        let services = []
        let services_total_count = 0
        this.state.severityServiceData.resource_type && Object.entries(this.state.severityServiceData.resource_type).forEach(([key, value]) => {
            let donutRow = {}
            donutRow.item_count = value
            donutRow.item_name = key
            services.push(donutRow)
            services_total_count += value 
        })

        services = _.orderBy(services, ['item_count'], ['desc'])
        services = services.slice(0,10)

        let resourceDonutData = {
            "items": services,
            "label": "Services",
            "total_count": services_total_count
        }

        //services Donut start

        //severity Donut start
        let severity = []
        let severity_total_count = 0
        this.state.severityServiceData.severity && Object.entries(this.state.severityServiceData.severity).forEach(([key, value]) => {

            let donutRow = {}
            donutRow.item_name = key
            donutRow.item_count = value
            donutRow.orderBy = key.toLowerCase() === 'critical' ? 1 :key.toLowerCase() === 'high' ? 2 : key.toLowerCase() === 'medium' ? 3 : 4
            severity.push(donutRow)

            severity_total_count += value 
        })

        severity = _.orderBy(severity, ['orderBy'], ['asc'])

        let severityDonutData = {
            "items": severity,
            "label": "Severity",    
            "total_count": severity_total_count
        }

        //severity Donut start

        this.setState({ resourceDonutData, severityDonutData, showDonut: true })
    }

    getObservabilityAlertsTotalCount = () => {
        let params = {
            "start_time": momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'),
            "end_time": momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss'),
            'radial': true,
            "alert_type": "Application",
            'aggregate_by': [["event_name"]]
        }

        this.props.getObservabilityAlertsTotalCount(params, (promise, response) => {
            if(promise) {
                this.setState({ alertDonutData: response.results && response.results.length && response.results[0].data ? response.results[0].data : {} },
                    () => this.alertsDonutGraph()
                )
            }
        }) 
    }

    alertsDonutGraph = () => { 
        //alerts Donut start
        let alerts = []
        let alerts_total_count = 0
        this.state.alertDonutData.event_name && Object.entries(this.state.alertDonutData.event_name).forEach(([key, value]) => {
            let donutRow = {}
            donutRow.item_count = value
            donutRow.item_name = key
            alerts.push(donutRow)
            alerts_total_count += value 
        })

        alerts = _.orderBy(alerts, ['item_count'], ['desc'])
        alerts = alerts.slice(0,10)

        let alertsDonutData = {
            "items": alerts,
            "label": "alerts",
            "total_count": alerts_total_count
        }

        //alerts Donut start

        this.setState({ alertsDonutData, showDonut: true })
    }

    resetAlertGraph = (type) => {
        if(type) {
            let graphData = this.state[type+"_alert_trend"]
            this.setState({ [type+"_alert_trend"]: {} },
                () => {
                    this.setState({ [type+"_alert_trend"]: graphData })
                }
            )
        } else {
            let graphData = this.state.graphData
            this.setState({ graphData: {} },
                () => {
                    this.setState({ graphData })
                }
            )
        }
    }

    render() {
        return (
            <div className={`${this.props.start_time ? "p-3 bg-muted" : ""}`} style={{maxHeight: "100%"}}>{/* errorSectionScroll */}
                <div className="col-sm-12 mt-2">
                    <div className="row">
                        <div className="col-sm-4">
                            {this.state.showDonut && this.state.severityDonutData ?
                                <ApexDonutChart
                                    graphData={this.state.severityDonutData}
                                    legend={true}
                                    showTotal={true}
                                    showTotalLable={true}
                                    showGraphHeading={false}
                                    height={150}
                                    legendWidth={200}
                                    size={'70%'}
                                    gradient={false}
                                    colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#039BE5']}
                                    gradientColor={['#A88CCC', '#D88ACF']}
                                    className={"transparentTooltip leftSide"}
                                    emptyGraphColor={"#2D323E"}
                                />
                            : null}
                        </div>
                        <div className="col-sm-4">
                            {this.state.showDonut && this.state.resourceDonutData ?
                                <ApexDonutChart
                                    graphData={this.state.resourceDonutData}
                                    legend={true}
                                    showTotal={true}
                                    showTotalLable={true}
                                    showGraphHeading={false}
                                    height={150}
                                    legendWidth={200}
                                    size={'70%'}
                                    gradient={false}
                                    colors={['#A88CCC', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7']}
                                    gradientColor={['#A88CCC', '#D88ACF']}
                                    className={"transparentTooltip leftSide"}
                                    emptyGraphColor={"#2D323E"}
                                />
                            : null}
                        </div>
                        <div className="col-sm-4">
                            {this.state.showDonut && this.state.alertsDonutData ?
                                <ApexDonutChart
                                    graphData={this.state.alertsDonutData}
                                    legend={true}
                                    showTotal={true}
                                    showTotalLable={true}
                                    showGraphHeading={false}
                                    height={150}
                                    legendWidth={200}
                                    size={'70%'}
                                    gradient={false}
                                    colors={['#A88CCC', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7']}
                                    gradientColor={['#A88CCC', '#D88ACF']}
                                    className={"transparentTooltip leftSide"}
                                    emptyGraphColor={"#2D323E"}
                                />
                            : null}
                        </div>
                    </div>
                </div>

                <div className="rounded counts_threads p-3 mt-2">
                    {/* <div className="d-flex mb-3">
                        <p className="font-weight-bold mb-0 mr-2 align-self-center">Show Errors by</p>
                        <div className={`form-group bd-highlight mb-sm-1 styled-feild `}>
                            <select className="form-control-sm" 
                                value={this.state.selectedTopErrorBy}
                                onChange={event => {
                                    this.setState(
                                        {
                                            selectedTopErrorBy: event.target.value,
                                            leftSideTopAlerts: {},
                                            rightSideTopAlerts: {}
                                        },
                                            () => this.onChangeTopErrosType()
                                    )
                                }}>
                                <option value={'service_asset'}>Service & Asset</option>
                                <option value={'cause_impact'}>Cause & Impact</option>
                                
                            </select>
                        </div>
                    </div> */}
                    
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="bg-dark p-3 rounded">
                                <label>Top 5 Infra Alerts</label>
                                {this.state.Infra_alerts && Object.entries(this.state.Infra_alerts).length ?
                                    <div className="mt-n4"> 
                                        <ApexBarChart
                                            graphData={this.state.Infra_alerts}
                                            sparkline={false}
                                            yaxis={false}
                                            yaxisLabel={false}
                                            xaxis={true}
                                            xaxisFormat={'string'}
                                            xaxisLabel={true}
                                            axisLabelColor={'#50586A'}
                                            paddingLeft={-10}
                                            legend={false}
                                            stacked={false}
                                            height={240}
                                            horizontal={true}
                                            barHeight={'40%'}
                                            barEndShape={'rounded'}
                                            columnWidth={'25%'}
                                            gradient={true}
                                            gradientColor={['#039BE5', '#5F5BA2']}
                                            hideTooltipValue={true}
                                            backgroundBarShape={'rounded'}
                                            backgroundBarColors={['#333947']}
                                            showBackgroundBarColors={false}
                                            className={"transparentTooltip"}
                                        />
                                    </div>
                                : null}
                            </div>
                        </div>
                        <div className="col-lg-6 pl-0">
                            <div className="bg-dark p-3 rounded">
                                <label>Top 5 Application Alerts</label>
                                {this.state.Application_alerts && Object.entries(this.state.Application_alerts).length ?
                                    <div className="mt-n4"> 
                                        <ApexBarChart
                                            graphData={this.state.Application_alerts}
                                            sparkline={false}
                                            yaxis={false}
                                            yaxisLabel={false}
                                            xaxis={true}
                                            xaxisFormat={'string'}
                                            xaxisLabel={true}
                                            axisLabelColor={'#50586A'}
                                            paddingLeft={-10}
                                            legend={false}
                                            stacked={false}
                                            height={240}
                                            horizontal={true}
                                            barHeight={'40%'}
                                            barEndShape={'rounded'}
                                            columnWidth={'25%'}
                                            gradient={true}
                                            gradientColor={['#039BE5', '#5F5BA2']}
                                            hideTooltipValue={true}
                                            backgroundBarShape={'rounded'}
                                            backgroundBarColors={['#333947']}
                                            showBackgroundBarColors={false}
                                            className={"transparentTooltip"}
                                        />
                                    </div>
                                : null}
                            </div>
                        </div>
                        <div className="col-lg-6 mt-2">
                            <div className="bg-dark p-3 rounded">
                                <label>Top 5 Assets</label>
                                {this.state.rightSideTopAlerts && Object.entries(this.state.rightSideTopAlerts).length ?
                                    <div className="mt-n4"> 
                                        <ApexBarChart
                                            graphData={this.state.rightSideTopAlerts}
                                            sparkline={false}
                                            yaxis={false}
                                            yaxisLabel={false}
                                            xaxis={true}
                                            xaxisFormat={'string'}
                                            xaxisLabel={true}
                                            axisLabelColor={'#50586A'}
                                            paddingLeft={-10}
                                            legend={false}
                                            stacked={false}
                                            height={240}
                                            horizontal={true}
                                            barHeight={'40%'}
                                            barEndShape={'rounded'}
                                            columnWidth={'25%'}
                                            gradient={true}
                                            gradientColor={['#039BE5', '#5F5BA2']}
                                            hideTooltipValue={true}
                                            backgroundBarShape={'rounded'}
                                            backgroundBarColors={['#333947']}
                                            showBackgroundBarColors={false}
                                            className={"transparentTooltip"}
                                        />
                                    </div>
                                : null}
                            </div>
                        </div>
                    </div>
                    
                    <div className="row mt-3">
                        <div className="col-lg-12">
                            <div className="bg-dark p-3 rounded">
                                <div className="d-flex justify-content-between">
                                    <label>Total Alerts Trend </label>
                                    <i className='far fa-redo cursorPointer' onClick={()=> this.resetAlertGraph()}></i>
                                </div>
                                {this.state.graphData && Object.keys(this.state.graphData).length ?
                                    <div className="mt-n2"> 
                                        <ApexTrendBarChart
                                            graphData={this.state.graphData}
                                            graphRiskCountSection={false}
                                            sparkline={false}
                                            yaxis={true}
                                            yaxisLabel={true}
                                            yaxisTickAmount={2}
                                            xaxis={true}
                                            xaxisFormat={'string'}
                                            xaxisLabel={true}
                                            axisLabelColor={'#50586A'}
                                            legend={false}
                                            stacked={false}
                                            height={220}
                                            horizontal={false}
                                            barHeight={'40%'}
                                            barEndShape={'flat'}
                                            columnWidth={'0%'}
                                            backgroundBarShape={'rounded'}
                                            backgroundBarColors={['#333947']}
                                            showBackgroundBarColors={true}
                                            className={"transparentTooltip"}
                                            colors={['#3DAFE8']}
                                        />
                                    </div>
                                : null}
                                {/* <div className="transparentTooltip mt-n2">
                                    {this.state.anomalySeries && this.state.anomalySeries.length ?
                                        <Chart options={this.state.anomalyOptions} series={this.state.anomalySeries} type="bar" height={200} />
                                    : null}
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-lg-12">
                            <div className="bg-dark p-3 rounded mt-2 ">
                                <div className="d-flex justify-content-between">
                                    <label>Infra Alerts Trend </label>
                                    <i className='far fa-redo cursorPointer' onClick={()=> this.resetAlertGraph("Infra")}></i>
                                </div>
                                {this.state.Infra_alert_trend && Object.keys(this.state.Infra_alert_trend).length ?
                                    <div className="mt-n2"> 
                                        <ApexTrendBarChart
                                            graphData={this.state.Infra_alert_trend}
                                            graphRiskCountSection={false}
                                            sparkline={false}
                                            yaxis={true}
                                            yaxisLabel={true}
                                            yaxisTickAmount={2}
                                            xaxis={true}
                                            xaxisFormat={'string'}
                                            xaxisLabel={true}
                                            axisLabelColor={'#50586A'}
                                            legend={false}
                                            stacked={false}
                                            height={220}
                                            horizontal={false}
                                            barHeight={'40%'}
                                            barEndShape={'flat'}
                                            columnWidth={'0%'}
                                            backgroundBarShape={'rounded'}
                                            backgroundBarColors={['#333947']}
                                            showBackgroundBarColors={true}
                                            className={"transparentTooltip"}
                                            colors={['#3DAFE8']}
                                        />
                                    </div>
                                : null}
                            </div>

                            <div className="bg-dark p-3 rounded mt-2 ">
                                <div className="d-flex justify-content-between">
                                    <label className="">Application Alerts Trend </label>
                                    <i className='far fa-redo cursorPointer' onClick={()=> this.resetAlertGraph("Application")}></i>
                                </div>
                                {this.state.Application_alert_trend && Object.keys(this.state.Application_alert_trend).length ?
                                    <div className="mt-n2"> 
                                        <ApexTrendBarChart
                                            graphData={this.state.Application_alert_trend}
                                            graphRiskCountSection={false}
                                            sparkline={false}
                                            yaxis={true}
                                            yaxisLabel={true}
                                            yaxisTickAmount={2}
                                            xaxis={true}
                                            xaxisFormat={'string'}
                                            xaxisLabel={true}
                                            axisLabelColor={'#50586A'}
                                            legend={false}
                                            stacked={false}
                                            height={220}
                                            horizontal={false}
                                            barHeight={'40%'}
                                            barEndShape={'flat'}
                                            columnWidth={'0%'}
                                            backgroundBarShape={'rounded'}
                                            backgroundBarColors={['#333947']}
                                            showBackgroundBarColors={true}
                                            className={"transparentTooltip"}
                                            colors={['#3DAFE8']}
                                        />
                                    </div>
                                : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
AlertAnalysis.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log('errorDashboard',state)
    return {
        aiopsCommonPageFilter: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.aiopsCommonPageFilter ? state.aiops.aiopsPropsDetails.aiopsCommonPageFilter : {},
        alertsTrend: state.observability.observabilityAlertTrend
    }
}

export default connect(mapStateToProps, {
    setAiopsPropsDetails,
    getObservabilityAlertsDailyTrend,
    getObservabilityTopAlerts,
    getObservabilityAlertsTotalCount,
})(withRouter(AlertAnalysis))