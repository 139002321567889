/*************************************************
 * Tvastar
 * @exports
 * @file EditDescription.js
 * @author Rajasekar // on 14/03/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { URL_PATH } from '../../config/urlPath'
import PropTypes from 'prop-types'
import { Spinner, Input } from 'reactstrap'
import { listAllProviders } from '../../actions/commonAction'
import { setEditRuleDetails, listGovernanceCategories, getGernanceMasterRule } from '../../actions/governance/governanceAction'
import Select from 'react-select'
import { splitString, momentDateTime } from '../../utils/utility'


class EditDescription extends Component {
	constructor(props) {		
		super(props)
		this.state = {
			showLoading: false,
			hasError: this.props.hasError,
			hasNextError: false,
			providers: [],

			showProviderLoading: false,
			resourcesArray: [],
			categoryListArray: [],
			selectedProvider: '',
			complianceId: this.props.editRuleDetails.complianceId ? this.props.editRuleDetails.complianceId : [],
		}
	}
	
	componentDidMount = () => {			
		if(!this.props.ruleDetails || !Object.entries(this.props.ruleDetails).length) {
			if(this.props.categoryListNew && this.props.categoryListNew.length) {
				let categoryListArray = []
				this.props.categoryListNew.forEach(cat => {
					let catRow = {}
					catRow.category_name = cat.category_name
					catRow.description = cat.description ? cat.description : ''
					catRow.resources = cat.resources
					categoryListArray.push(catRow)
				})
				this.setState({ categoryListArray },
					() => this.getCategoryResources(this.props.editRuleDetails.category, '')	
				)
			}
			
			// if(!this.props.editRuleDetails.provider || this.props.editRuleDetails.provider === 'Select') {
			// 	this.props.setEditRuleDetails('provider', 'Select')
			// }
			if(!this.props.editRuleDetails.category || this.props.editRuleDetails.category === 'Select') {
				this.props.setEditRuleDetails('category', 'Select')
				this.props.setEditRuleDetails('categoryLabel', 'Select')
				this.props.setEditRuleDetails('resource_type', 'Select')
			}
			if(this.props.providers && this.props.providers.length) {	
				if(this.props.providers.length === 1) {				
					this.setState({ providers: this.props.providers, selectedProvider: this.props.providers[0].provider_name },
						() => {
							this.handleDescription('provider', this.props.providers[0].provider_name)
						}
					)
				} else {
					this.setState({ providers: this.props.providers, selectedProvider: 'Select' })
				}
			} else {
				this.setState({ showLoading: true })
				this.props.listAllProviders((promise, providers) => {		
					if (promise) {	
						if(providers.length === 1) {
							this.setState({ providers, showLoading: false, selectedProvider: providers[0].provider_name },
								() => {
									this.handleDescription('provider', providers[0].provider_name)
								}
							)
						} else {
							this.setState({ providers })
						}
					} else {
						this.setState({ providers: [], showLoading: false, selectedProvider: 'Select' })
					}
				})
			}
		} else {
			//this.getGovernanceRules()
			if(!this.props.editRuleDetails.governance_category || !this.props.editRuleDetails.governance_type) {
				let params = {}
				params.category = this.props.editRuleDetails.category
				params.resource_type = this.props.editRuleDetails.resource_type
				this.props.getGernanceMasterRule(params, (promise, governanceRules) => {
					if(promise) {
						if(governanceRules.results && governanceRules.results.length) {
							governanceRules.results.forEach(item => {
								this.props.setEditRuleDetails('governance_category', item.governance_category)
								this.props.setEditRuleDetails('governance_type', item.governance_type)
							})
						}
					}
				})
			}
		}
	}

	componentDidUpdate = () => {
		if(this.props.hasError !== this.state.hasError) {
			this.setState({ hasError: this.props.hasError })
		}
	}

	handleDescription = (label, value) => {
		if(label === 'provider') {
			value = value.toLowerCase()
		}
		this.props.setEditRuleDetails(label, value)
		
		if(label === 'provider' && value !== '' && value !== 'select' && value !== 'Select') {
			this.setState({ showProviderLoading: true })
			let params = {}
			params.provider = value
			this.props.listGovernanceCategories(params, (promise, categoryList) => {
				if(promise) {
					let categoryListArray = []
					categoryList.forEach(cat => {
						let catRow = {}
						catRow.category_name = cat.category_name
						catRow.description = cat.description ? cat.description : ''
						catRow.resources = cat.resources						
						categoryListArray.push(catRow)
					})
					this.setState({ categoryListArray, showProviderLoading: false })
				} else {
					this.setState({ categoryListArray: [], showProviderLoading: false })
				}
			})
		}
	}

	validateDescription = () => {
		// if(this.props.editRuleDetails.provider === 'Select' || this.props.editRuleDetails.description === '' || !this.props.editRuleDetails.category || this.props.editRuleDetails.category === '' || !this.props.editRuleDetails.rule_name || this.props.editRuleDetails.rule_name === '') {
		// 	this.setState({ hasNextError: true })
		// } else {
		//	this.props.onClickNext()
		// }
			
		this.props.onClickNext()
	}

	getCategoryResources = (categoryName, resetCondition) => {
		if(resetCondition !== '') {
			this.props.setEditRuleDetails('condition', {})
		}
		let category = this.state.categoryListArray.filter(obj => obj.category_name === categoryName)
		this.setState({ resourcesArray: category.length ? category[0].resources : [] },
			//() => this.storage_clear_condition()
		)
	}

	storage_clear_condition = () => {
		// if(this.props.editRuleDetails.category === 'Service-BlockStore' || this.props.editRuleDetails.category === 'Service-Logs') {
			let condition = {}
			this.props.setEditRuleDetails('condition', condition)
		// } else if
	}

	getGovernanceRules = (value) => {
		if(this.props.editRuleDetails.category === 'Service-BlockStore') {
			let condition = {}
			this.props.setEditRuleDetails('condition', condition)
		}
		let params = {}
		params.category = this.props.editRuleDetails.category
		params.resource_type = value
		this.props.getGernanceMasterRule(params, (promise, governanceRules) => {
			this.props.setEditRuleDetails('base_rule_id', '')
			this.props.setEditRuleDetails('base_rule_name', '')
			this.props.setEditRuleDetails('description', '')
			this.props.setEditRuleDetails('priority', '')
			this.props.setEditRuleDetails('compliance', [])
			this.props.setEditRuleDetails('complianceId', [])
			this.props.setEditRuleDetails('service_name', '')
			this.props.setEditRuleDetails('enabled', '')
			this.props.setEditRuleDetails('plan', '')
			this.props.setEditRuleDetails('weight', '')
			this.props.setEditRuleDetails('classification', '')
			this.props.setEditRuleDetails('governance_category', '')
			this.props.setEditRuleDetails('governance_type', '')

			
			let governanceRuleArray = []
			if(promise) {
				let complianceId = []
				if(governanceRules.results && governanceRules.results.length) {

					governanceRules.results.forEach(item => {
						let dataRow = {}
						dataRow.compliance = []
						item.compliance && item.compliance.forEach(comp => {
							let split = splitString(comp.complianceId, ', ')
							dataRow.compliance = split
							complianceId = split
						})
						dataRow.base_rule_id = item.rule_id
						dataRow.base_rule_name = item.rule_name
						dataRow.description = item.description
						dataRow.priority = item.priority
						dataRow.service_name = item.service_name
						dataRow.enabled = item.enabled
						dataRow.plan = item.plan
						dataRow.weight = item.weight
						dataRow.classification = item.classification
						dataRow.governance_category = item.governance_category
						dataRow.governance_type = item.governance_type
						

						this.props.setEditRuleDetails('base_rule_id', item.rule_id)
						this.props.setEditRuleDetails('base_rule_name', item.rule_name)
						this.props.setEditRuleDetails('description', item.description)
						this.props.setEditRuleDetails('priority', item.priority)
						this.props.setEditRuleDetails('compliance', item.compliance)
						this.props.setEditRuleDetails('complianceId', complianceId)
						this.props.setEditRuleDetails('service_name', item.service_name)
						this.props.setEditRuleDetails('enabled', item.enabled)
						this.props.setEditRuleDetails('plan', item.plan)
						this.props.setEditRuleDetails('weight', item.weight)
						this.props.setEditRuleDetails('classification', item.classification)
						this.props.setEditRuleDetails('governance_category', item.governance_category)
						this.props.setEditRuleDetails('governance_type', item.governance_type)

						governanceRuleArray.push(dataRow)
					})
				}
				this.setState({ governanceRules: governanceRules.results && governanceRules.results.length ? governanceRules.results[0] : [], governanceRuleArray, complianceId },
					() => this.storage_clear_condition()
				)
			} else {
				this.setState({ governanceRules: [], governanceRuleArray },
					() => this.storage_clear_condition()
				)
			}
		})
	}

	render() {
		return (
			this.state.showLoading ?
				<div className='d-flex justify-content-center m-4'>
					<Spinner className='text-center' color='white' size='lg' />
				</div>	
			:
				<React.Fragment>
				<h5 className="text-white">Description</h5>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
					magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
					consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
					pariatur.
				</p>
				<div className="rounded bg-dark px-3 py-2 mt-2">
					<div className={`row`}>
						<div className="col-lg-6 zapInputDark">
							<h6 className="text-info">Provider Details</h6>
							<div className={`row`}>
								<div className="col-md-5 form-group">
									<div className='d-flex'>
										<label className="align-self-center m-0 mr-3">Provider </label>
										<Select
											// placeholder='Select'
											/* menuIsOpen={true} */
											isSearchable={true}
											//onFocus={this.openProivderMenu}
											className={`f13 p-0 w-40 ${this.props.ruleDetails && Object.entries(this.props.ruleDetails).length ? 'disabled disabledDarkBg' : ''}`}
											value={({
												value: this.props.editRuleDetails.provider && this.props.editRuleDetails.provider.toLowerCase() !== 'select' ? this.props.editRuleDetails.provider.toUpperCase() : 'Select',
												label: this.props.editRuleDetails.provider && this.props.editRuleDetails.provider.toLowerCase() !== 'select' ? this.props.editRuleDetails.provider.toUpperCase() : <span className="placeholder">Select</span>
											})}
											options={this.state.providers && this.state.providers.map(item => ({
												value: item.provider_name,
												label: item.provider_name,	
											}))}
											onChange={event => this.setState({ selectedProvider : event.value },
												() => {
													if(this.props.editRuleDetails.provider !== event.value.toLowerCase()) {
														this.handleDescription('provider', event.value)
													}
												}
											)}
										/>
										{this.state.showProviderLoading ? <Spinner className='ml-3 align-self-center' color='dark' size='sm' /> : null }
									</div>
									{(this.state.hasError || this.state.hasNextError) && (!this.props.editRuleDetails.provider || this.props.editRuleDetails.provider === 'Select') ? (
										<small className='text-danger'>Provider is required.</small>
									) : null}
								</div>
							</div>

							<div className={`row mt-2`}>
								<div className="col-md-6">
									<div className="form-group">
										<label>Category</label>
										<Select
											// placeholder={'Select'}
											/* menuIsOpen={true} */
											isSearchable={true}
											//onFocus={this.openProivderMenu}
											className={`f13 p-0 ${this.props.ruleDetails && Object.entries(this.props.ruleDetails).length ? 'disabled disabledDarkBg' : ''}`}
											value={({
												value: this.props.editRuleDetails.category && this.props.editRuleDetails.category !== 'Select' ? this.props.editRuleDetails.category : 'Select',
												label: this.props.editRuleDetails.categoryLabel && this.props.editRuleDetails.categoryLabel !== 'Select' ? this.props.editRuleDetails.categoryLabel : <span className="placeholder">Select</span>
											})}
											options={this.state.categoryListArray && this.state.categoryListArray.map(cat => ({
												value: cat.category_name,
												label: cat.category_name +' ('+cat.description+')',	
											}))}
											onChange={event => {
												this.handleDescription('category', event.value)
												this.handleDescription('categoryLabel', event.label)
												this.handleDescription('resource_type', 'Select')
												this.getCategoryResources(event.value, 'resetCondition')
											}}
										/>
										{(this.state.hasError || this.state.hasNextError) && (!this.props.editRuleDetails.category || this.props.editRuleDetails.category === '' || this.props.editRuleDetails.category === 'Select') ? (
											<small className='text-danger'>Category is required.</small>
										) : null}
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<label>Service</label>
										<Select
											placeholder={'Select'}
											isSearchable={true}
											className={`f13 p-0 ${this.props.ruleDetails && Object.entries(this.props.ruleDetails).length ? 'disabled disabledDarkBg' : ''}`}
											value={({
												value: this.props.editRuleDetails.resource_type && this.props.editRuleDetails.resource_type !== 'Select' ? this.props.editRuleDetails.resource_type : 'Select',
												label: this.props.editRuleDetails.resource_type && this.props.editRuleDetails.resource_type !== 'Select' ? this.props.editRuleDetails.resource_type : <span className="placeholder">Select</span>
											})}
											options={this.state.resourcesArray && this.state.resourcesArray.map(resources => ({
												value: resources,
												label: resources,	
											}))}
											onChange={event => {
												this.handleDescription('resource_type', event.value)
												this.getGovernanceRules(event.value)
											}}
										/>
									
										{(this.state.hasError || this.state.hasNextError) && (!this.props.editRuleDetails.resource_type || this.props.editRuleDetails.resource_type === '' || this.props.editRuleDetails.resource_type === 'Select') ? (
											<small className='text-danger'>Service is required.</small>
										) : null}
									</div>
								</div>
							</div>
							{this.props.editRuleDetails.resource_type && this.props.editRuleDetails.resource_type !== 'Select' && this.props.editRuleDetails.category && this.props.editRuleDetails.category !== 'Select' ?
								<React.Fragment>
									{/* <div className="form-group">
										<div className="d-flex">
											<div className="form-check">
												<input type="checkbox" className="form-check-input disabled" id="exampleCheck1" 
												checked={this.props.editRuleDetails.classification && this.props.editRuleDetails.classification === 'Data' ? true : false}
												/>
												<label className="form-check-label" for="exampleCheck1">Data Governance</label>
											</div>
											<div className="form-check ml-3">
												<input type="checkbox" className="form-check-input disabled" id="exampleCheck2" 
												checked={this.props.editRuleDetails.classification && this.props.editRuleDetails.classification === 'Infrastructure' ? true : false}
												/>
												<label className="form-check-label" for="exampleCheck1">Infrastructure Governance</label>
											</div>
										</div>
									</div> */}
									<div className='d-flex'>
										<h6 className="text-info mr-2">Rule Details</h6> 
										{this.props.editRuleDetails.base_rule_id && this.props.editRuleDetails.base_rule_id !== '' ? null : <Spinner className='ml-3 align-self-center' color='white' size='sm' /> }
									</div>
									<div className={`bg-dark3 p-3 rounded mb-3 ${this.props.editRuleDetails.base_rule_id && this.props.editRuleDetails.base_rule_id !== '' ? '' : 'displayNone' }`}>
										<div className="row">
											<div className="col-md-6">
												<p className="mb-1">Base Rule ID</p>
												<h6 className="text-white">{this.props.editRuleDetails.base_rule_id ? this.props.editRuleDetails.base_rule_id : ''}</h6>
											</div>
											<div className="col-md-6">
												<p className="mb-1">Priority</p>
												{this.props.editRuleDetails.priority ?
													<small className={`badge ${this.props.editRuleDetails.priority === 'Critical' ? 'badge-critical' : this.props.editRuleDetails.priority === 'High' ? 'badge-high' : this.props.editRuleDetails.priority === 'Medium' ? 'badge-medium' : 'badge-low'}`}>
													{this.props.editRuleDetails.priority}
													</small>
												:
												null
												}
											</div>
										</div>
										<div className="row">
											<div className="col-md-6">
												<p className="mb-1">Base Rule Name</p>
												<h6 className="text-white">{this.props.editRuleDetails.base_rule_name ? this.props.editRuleDetails.base_rule_name : ''}</h6>
											</div>
											<div className="col-md-6">
												<p className="mb-1">Compliance</p>
												{this.props.editRuleDetails && this.props.editRuleDetails.complianceId ?
													
													this.props.editRuleDetails.complianceId.map(comp => {
														return (
														<small className="badge badge-outline-light mr-2">{comp}</small>
														)
													})
												: null
												}
												{/* <small className="badge badge-outline-light ml-2">PCI DSS 2.0</small> */}
											</div>
										</div>
										<div className="row">
											<div className="col-md-6">
												<p className="mb-1">Classification</p>
												<h6 className="text-white">{this.props.editRuleDetails.classification ? this.props.editRuleDetails.classification : ''}</h6>
											</div>
											<div className="col-md-6">
												<p className="mb-1">Type</p>
												{this.props.editRuleDetails && this.props.editRuleDetails.governance_type ?
													this.props.editRuleDetails.governance_type.map(type => {
														return (
															<small className="badge badge-outline-light mr-2">{type}</small>
														)
													})
												: null
												}
											</div>
										</div>
										<div className="row">
											<div className="col-md-6">
												<p className="mb-1">Category</p>
												<h6 className="text-white">{this.props.editRuleDetails.governance_category ? this.props.editRuleDetails.governance_category : ''}</h6>
											</div>					
											{this.props.ruleDetails && Object.entries(this.props.ruleDetails).length ? 
												<div className="col-md-6">
													<p className="mb-1">Number of Versions</p>
													<h6 className="text-white">{this.props.editRuleDetails.version ? this.props.editRuleDetails.version : ''}</h6>
												</div>
											: null
											}
										</div>
										{this.props.editRuleDetails.created_at && this.props.editRuleDetails.created_by ? 
											<React.Fragment>
												<div className="row">
													<div className="col-md-6">
														<p className="mb-1">Created Date</p>
														<h6 className="text-white">{momentDateTime(this.props.editRuleDetails.created_at)}</h6>
													</div>
													<div className="col-md-6">
														<p className="mb-1">Created By</p>
														<h6 className="text-white">{this.props.editRuleDetails.created_by}</h6>
													</div>
												</div>
												{this.props.editRuleDetails.last_modified && this.props.editRuleDetails.last_modified !== '' ?
													<div className="row">
														<div className="col-md-6">
															<p className="mb-1">Modified Date</p>
															<h6 className="text-white">{momentDateTime(this.props.editRuleDetails.last_modified)}</h6>
														</div>
														<div className="col-md-6">
															<p className="mb-1">Modified By</p>
															<h6 className="text-white">{this.props.editRuleDetails.modified_by}</h6>
														</div>
													</div>
												: null
												}
											</React.Fragment>
										: null
										}
									</div>
								</React.Fragment>
							: null
							}

							<div className="row mt-3">							
								<div className="col-md-6">
									<label>Rule Name</label>
									<Input
										type="text"
										placeholder="Enter Rule Name"
										className="inputTextbox"
										maxlength="128"
										value={this.props.editRuleDetails.rule_name}
										onChange={e => this.handleDescription('rule_name', e.target.value)}
									/>
									{(this.state.hasError || this.state.hasNextError) && (!this.props.editRuleDetails.rule_name || this.props.editRuleDetails.rule_name === '') ? (
										<small className='text-danger'>Rule Name is required.</small>
									) : null}
								</div>
								<div className="col-md-12 mt-3">
									<label>Description</label>
									<Input
										type="textarea"
										className="inputTextbox" 
										placeholder="Enter Rule Description"
										rows="3"
										id="comment"
										maxlength="256"
										onChange={e => this.handleDescription('description', e.target.value)}
										value={this.props.editRuleDetails.description}
									/>
									{(this.state.hasError || this.state.hasNextError) && (!this.props.editRuleDetails.description || this.props.editRuleDetails.description === '') ? (
										<small className='text-danger'>Description is required.</small>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="border-top pt-2 mt-3">
					<Link className='btn btn-light text-muted text-decoration-none mr-1' to={URL_PATH.GOVERNANCE_RULES} >
						Back
					</Link>
					<button className="btn btn-primary" onClick={() => this.validateDescription()}>Next</button>
				</div>
				
				</React.Fragment>
		)
	}
}

/**
 * Type of the props used in the component
 */
EditDescription.propTypes = {
	onClickNext: PropTypes.func,
	setEditRuleDetails: PropTypes.func,
	ruleDetails: PropTypes.object,
	editRuleDetails: PropTypes.object,
	listAllProviders: PropTypes.func,
	listGovernanceCategories: PropTypes.func,
	getGernanceMasterRule: PropTypes.func
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('description',state)
	return {
		providers: state.filters.providers,
		editRuleDetails: state.governance.editRuleDetails,
		categoryListNew: state.governance.categoryListNew ? state.governance.categoryListNew : []
	}
}

export default connect(mapStateToProps, { 
	setEditRuleDetails, 
	listGovernanceCategories, 
	listAllProviders,
	getGernanceMasterRule 
})(EditDescription)
