/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 12/04/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 * 
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { listAllServices, getAllTagsKeys, getAllTagsValues } from '../../../../actions/commonAction'
import { getServiceQuotaDetails } from '../../../../actions/detectors/detectorsAction'
import { setResourcePropsDetails } from '../../../../actions/assets/assetsAction'
import { momentConvertionLocalToUtcTime } from '../../../../utils/utility'
import { UncontrolledTooltip } from 'reactstrap'
import _ from 'lodash'

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

// import MetricsTab from './MetricsTab'

// import AlertsTab from './AlertsTab'

import SearchWithHiddenInput from '../../../common/SearchWithHiddenInput'
import MonitoringTab from './MonitoringTab'
import AlarmsTab from './AlarmsTab'

const Categories = ["All", "compute", "Networking", "CDN", "Database", "Storage"]

class LandingPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            qutoaPageType: "Alarms",

            assetCategory: [],
            selectedService: [],
            tagsArray: [0],
        }
    }

	componentDidMount = () => {}
    
    componentDidUpdate = (prevProps) => {
        if(this.props.dashboardDetails && Object.entries(this.props.dashboardDetails).length) {
            if(!_.isEqual(prevProps.dashboardDetails, this.props.dashboardDetails)) {
                this.setState({ 
                    start_time: this.props.dashboardDetails.start_time,
                    end_time: this.props.dashboardDetails.end_time,
                    brushStartTime: this.props.dashboardDetails.brushStartTime ? this.props.dashboardDetails.brushStartTime : this.props.dashboardDetails.start_time,
                    brushEndTime: this.props.dashboardDetails.brushEndTime ? this.props.dashboardDetails.brushEndTime : this.props.dashboardDetails.end_time,
                    selectedProvider: this.props.dashboardDetails.selectedProvider,
                    selectedAccount: this.props.dashboardDetails.selectedAccount,
                    selectedRegion: this.props.dashboardDetails.selectedRegion,
                    selectedDuration: this.props.dashboardDetails.selectedDuration,
                    showLoading: true,
                    selectedGroup: "",
                    groupOptions: [],
                },
                    () => {
                        this.getServiceQuotaDetails()
                        this.listAllServices()
                    }
                )
            }
        }
    }
    

    listAllServices = () => {
        let params = {}

		if (this.state.selectedProvider !== "" && this.state.selectedProvider !== 'all') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
        if(!this.props.services || !this.props.services.length) {
            this.props.listAllServices(params, (promise, services) => {})
        }
    }

    getServiceQuotaDetails = () => {
        let params = {}
            // "start_time": "2022-04-21 13:00:00", "end_time":"2022-04-21 20:30:00"
            // 'start_time': '2022-04-22 05:45:00', 'end_time':'2022-04-22 15:05:00'
        
        params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartTime, "YYYY-MM-DD HH:mm:00")
        params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndTime, "YYYY-MM-DD HH:mm:00")
        if(this.state.selectedAccount && this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length && this.state.selectedRegion !== "" && !this.state.selectedRegion.includes("All")) {
            params.region = this.state.selectedRegion
        }

        this.props.getServiceQuotaDetails(params, (promise, response) => {
            if(promise) {
                let results = response.results && response.results.length ? response.results : []
                this.setState({ listResponse: results, filteredResults: results, showLoading: false, selectedRecord: results.length ? results[0] : {} },
                    () => {
                        this.getServiceDetails(this.state.selectedRecord)
                    }
                )
            } else {
                this.setState({ showLoading: false })
            }
        })
    }    

    getServiceDetails = (item) => {
        this.props.setResourcePropsDetails('alarmsTabDetails', {})
        let serviceDetails = item.details ? item.details : []
        
        this.setState({ selectedRecord: item, serviceDetails },
            () => {
                if(serviceDetails.length) {
                    this.getSelectedAsset(this.state.serviceDetails[0])
                }
            }
        )
    }

    getSelectedAsset = (item) => {
        this.setState({ selectedDetail: item })
        
        let obj = {}
        obj.selectedDetail = item
        obj.start_time = this.state.start_time
        obj.end_time = this.state.end_time
        this.props.setResourcePropsDetails('leftSectionSelection', obj)
    }

    getAllTagsKeys = () => {
        let params = {}
        this.props.getAllTagsKeys(params, (promise, response) => {})
    }

    getAllTagsValues = (index) => {
        let params = {}
        params.tags = [{ "key": this.state["tag_key_"+index] }]
        this.props.getAllTagsValues(params, (promise, response) => {
            if(promise) {
                this.setState({ ["tagValues_"+index]: response })
            }
        })
    }
    
    handleMultiSelectChange = (field, arrayValue) => {
		let value = arrayValue.map(item => item.value)

		let selectedValue = []
		if(field === 'selectedService') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.regions.length -1)) {
					selectedValue.push('All')
				}
			}
            this.setState({ [field]: selectedValue })
		} else if(field === 'assetCategory') {
			let prevState = this.state[field] ? this.state[field] : []
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					Categories.forEach(item => {
						selectedValue.push(item)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (Categories.length -1)) {
					selectedValue.push('All')
				}
			}	
            this.setState({ [field]: selectedValue, changesInFilter: true },
                // () => this.filterResults()    
            )		
		}
    }
    
    getMultiSelectedCount = (type, array) => {
        return array.length && array.includes('All') ? 'All' : (array.length ? array.length +' Selected' : 'All')
    } 
    
	render() {
		return (
            <div className="container-fluid overflow-auto flex-grow-1 bg-muted">
                <div className={`loaderOverlay ${this.state.showLoading ? "" : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
                <div className='row h-100'>
                    <div className={`col-sm-3 pl-0 mt-2`}>
                        <div class="bg-dark pt-2 w-100 overflowHidden-y-scroll rounded obsSectionHeight mb-1" ref={this.heightRef}>
                            {this.state.listResponse && this.state.listResponse.length ?
                                <div className={`d-flex justify-content-end mx-1 mt-1 pr-2 pt-2 ${this.state.showFilterSection ? "bg-muted" : ""}`}>
                                    <SearchWithHiddenInput
                                        data={this.state.listData}
                                        applyLiteDarkTags={true}
                                        searchClassName={'border-0'}
                                        searchIconColor={'text-gray3'}
                                        className="form-control-sm text-gray3 bg-dark3 border-0"
                                        hideInputSection={true}
                                        topClassName={'dark-search'}
                                        searchPlaceHolder={'Search....'}                                            
                                        filteredData={(filteredList) => this.setState({ filteredList, emptySearchText: false })}
                                        resetSearchBox={this.state.emptySearchText}
                                    />
                                    <i className="fas fa-filter align-self-center cursorPointer" onClick={() => this.setState({ showFilterSection: !this.state.showFilterSection }, () => this.getAllTagsKeys())}></i>
                                </div>
                            :null}
                            {this.state.showFilterSection ?
                                <div className=" mx-1 mb-2 p-2 zapInputDark bg-muted">
                                    <div className="w-100">
                                        <div className="d-flex flex-wrap mb-1">
                                            <label className="mb-0">Category</label>
                                            {this.state.assetCategory && this.state.assetCategory.length && !this.state.assetCategory.includes("All") ?
                                                this.state.assetCategory.map((cat, index) => {
                                                    return(
                                                        <span className="badge badge-secondary ml-2 mb-1">
                                                            {cat}
                                                            <i onClick={ () => this.removefilter(index, cat, 'assetCategory') } className='ml-1 fal fa-times cursorPointer'></i>
                                                        </span>
                                                    )
                                                })
                                            : this.state.assetCategory.includes("All") ?
                                                <span className="badge badge-secondary ml-2 mb-1">All</span>
                                            : null}
                                        </div>                                                        
                                        <div className={`multiselectDarkTheme align-self-center mr-2 z998`} ref={this.categoryRef}>
                                            <ReactMultiSelectCheckboxes						
                                                placeholderButtonLabel="All"
                                                getDropdownButtonLabel={() => this.getMultiSelectedCount('assetCategory', this.state.assetCategory)}
                                                // menuIsOpen ={this.state.isCategoryOpen}
                                                value={this.state.assetCategory && this.state.assetCategory.map(cat => ({
                                                    value: cat
                                                }))}
                                                onChange={arr => { this.handleMultiSelectChange('assetCategory', arr ? arr : []) }}
                                                options={Categories.map(item => ({
                                                    value: item,
                                                    label: item,
                                                }))}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-100 mt-2">
                                        <div className="d-flex flex-wrap mb-1">
                                            <label className="mb-0">Services</label>
                                            {this.state.selectedService && this.state.selectedService.length && !this.state.selectedService.includes("All") ?
                                                this.state.selectedService.map((ser, index) => {
                                                    return(
                                                        <span className="badge badge-secondary ml-2 mb-1">
                                                            {ser}
                                                            <i onClick={ () => this.removefilter(index, ser, 'selectedService') } className='ml-1 fal fa-times cursorPointer'></i>
                                                        </span>
                                                    )
                                                })
                                            : this.state.selectedService.includes("All") ?
                                                <span className="badge badge-secondary ml-2 mb-1">All</span>
                                            : null}
                                        </div>
                                        <div className={`multiselectDarkTheme align-self-center mr-2 z999`}>
                                            <ReactMultiSelectCheckboxes						
                                                placeholderButtonLabel="All"
                                                // menuIsOpen ={this.state.serviceDropdown}
                                                getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedService', this.state.selectedService)}
                                                value={this.state.selectedService.map(service => ({
                                                    value: service,
                                                    label: service
                                                }))}
                                                onChange={arr => { this.handleMultiSelectChange('selectedService', arr ? arr : []) }}
                                                options={this.props.services.map(service => ({
                                                    value: service.service_name,
                                                    label: service.service_name,
                                                }))}
                                            />
                                        </div>
                                    </div>
                                    <div className={`w-100 mt-2`}>
                                        <label className="mb-1">Tags</label>
                                        {this.state.tagsArray && this.state.tagsArray.map((index, i) => {
                                            return(
                                                <div className={`w-100 ${i ? "mt-2 border-top-black4" : ""}`}>
                                                    <div className={`d-flex ${!i ? "" : "mt-3"}`}>
                                                        <div className="w-80">
                                                            <Select
                                                                placeholder={'Select'}
                                                                isSearchable={true}
                                                                // menuIsOpen={true}
                                                                className='f13 p-0 dark-select-theme-with-dropdown-indicator'
                                                                value={({
                                                                    value: this.state["tag_key_"+index] && this.state["tag_key_"+index] !== "" ? this.state["tag_key_"+index] : 'Select',
                                                                    label: this.state["tag_key_"+index] && this.state["tag_key_"+index] !== "" ? this.state["tag_key_"+index] : <span className="placeholder">Select</span>
                                                                })}
                                                                options={this.props.tagKeys && this.props.tagKeys.map(item => ({
                                                                    value: item,
                                                                    label: item,	
                                                                }))}
                                                                onChange={event => this.setState({
                                                                    ["tag_key_"+index]: event.value,
                                                                    ["tagValues_"+index]: [],
                                                                },
                                                                    () => this.getAllTagsValues(index)
                                                                )}
                                                            />
                                                            {/* <Input
                                                                type="text"
                                                                placeholder="key"
                                                                className="inputTextbox-sm w-100 f12"
                                                                value={this.state["tag_key_"+index]}
                                                                id={"tagsKey"}
                                                                onChange={event =>
                                                                    this.setState({ ["tag_key_"+index]: event.target.value })
                                                                }
                                                            /> */}
                                                            {/* <Input
                                                                type="text"
                                                                placeholder="value"
                                                                className="inputTextbox-sm mt-2 w-100 f12"
                                                                value={this.state["tag_value_"+index]}
                                                                id={"tagsValue"}
                                                                onChange={event =>
                                                                    this.setState({ ["tag_value_"+index]: event.target.value })
                                                                }
                                                            /> */}
                                                            <Select
                                                                placeholder={'Select'}
                                                                isSearchable={true}
                                                                // menuIsOpen={true}
                                                                className='f13 p-0 dark-select-theme-with-dropdown-indicator mt-2'
                                                                value={({
                                                                    value: this.state["tag_value_"+index] && this.state["tag_value_"+index] !== "" ? this.state["tag_value_"+index] : 'Select',
                                                                    label: this.state["tag_value_"+index] && this.state["tag_value_"+index] !== "" ? this.state["tag_value_"+index] : <span className="placeholder">Select</span>
                                                                })}
                                                                options={this.state["tagValues_"+index] && this.state["tagValues_"+index].map(item => ({
                                                                    value: item,
                                                                    label: item,	
                                                                }))}
                                                                onChange={event => this.setState({
                                                                    ["tag_value_"+index]: event.value
                                                                })}
                                                            />
                                                        </div>
                                                        {this.state.tagsArray.length > 1 ?
                                                            <span className={`far fa-trash cursor-pointer f18 align-self-center ml-4`} onClick={() => this.removeSection(index, i)}></span>
                                                        : null}
                                                        {(this.state.tagsArray.length - 1) ===  i ?
                                                            <span className={`far fa-plus cursor-pointer f18 align-self-center ml-4`} onClick={() => this.addSection("tagsArray")}></span>
                                                        : null}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="d-flex justify-content-end mt-3">
                                        <button className={`btn btn-sm bg-info text-white mr-2`} onClick={() => this.setState({ showFilterSection: false }, () => this.filterResults())}>Filter</button>
                                        <button className={`btn btn-sm btn-light`} onClick={() => this.resetFilterSelection()}>Clear</button>
                                    </div>
                                </div>
                            : null}
                            

                            {this.state.filteredResults && this.state.filteredResults.length ? 
                                this.state.filteredResults.map((item, i) => {
                                    return (
                                        <React.Fragment>
                                        <div className={`p-2 cursorPointer ${i ? "border-top" : ""} ${this.state.selectedRecord && this.state.selectedRecord.label === item.label ? 'bg-dark3 text-info disabled' : ''}`} id={"asset_name_"+i} onClick={() => this.getServiceDetails(item)}>
                                            <div className="d-flex">
                                                <p className={`m-0 f-20 pl pr-1 pt-2 pb-1 hiddenEllipses ${this.state.selectedRecord && this.state.selectedRecord.label === item.label ? 'text-info' : 'text-white'}`}>{item.label}</p>
                                            </div>
                                            <UncontrolledTooltip placement='top' target={"asset_name_"+i}>
                                                <div className="uncontrolledTooltipLeftAlign">
                                                    <p className="mb-1 text-primary-color"><span className="text-white mr-1">Name: </span> {item.label}</p>
                                                </div>    
                                            </UncontrolledTooltip>
                                        </div>
                                        </React.Fragment>
                                    )
                                })
                            : 
                                <div className='d-flex justify-content-center m-4'>
                                    <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={`col-sm-4 pl-0 mt-2`}>
                        <div className="rounded bg-dark p-2">
                            <div className="table-responsive obsSectionHeight overflowYAuto">
                                <table className="table text-white mb-0">
                                    <thead className="" >
                                        <tr class="w-100">
                                            <th style={{width: "250px"}}>Name</th>
                                            <th className="text-center">Applied</th>
                                            <th className="text-center">Default</th>
                                            <th className="text-center">Actual</th>
                                            <th className="text-center">Utilization</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.serviceDetails && this.state.serviceDetails.length ?
                                            this.state.serviceDetails.map((item, index) => {
                                                return (
                                                    <tr className={`w-100 border-top ${this.state.selectedDetail && this.state.selectedDetail.service_quota_id && this.state.selectedDetail.service_quota_id === item.service_quota_id ? "text-info" : "text-white cursorPointer"}`}  onClick={() => this.getSelectedAsset(item)}>
                                                        <td className="align-middle py-1" style={{maxWidth: "250px"}} id={`quota_name_`+index}>
                                                            <p className={`mb-0 hiddenEllipses`}>
                                                                {item.quota_name ? item.quota_name : ""}
                                                            </p>
                                                            {item.asset_level && item.asset_name ? 
                                                                <p className={`mb-0 hiddenEllipses mt-1`}>
                                                                    {item.asset_name}
                                                                </p>
                                                            : null}
                                                        </td>
                                                        <UncontrolledTooltip placement='top' target={`quota_name_`+index}>
                                                            <div className="uncontrolledTooltipLeftAlign">
                                                                <p className="mb-1 text-primary-color"><span className="text-white mr-1">Name: </span> {item.quota_name}</p>
                                                                {item.asset_level && item.asset_name ? 
                                                                    <p className="mb-1 text-primary-color"><span className="text-white mr-1">Asset: </span> {item.asset_name}</p>
                                                                : null}
                                                                <p className="mb-1 text-primary-color"><span className="text-white mr-1">Applied: </span> {item.applied_quota_value ? item.applied_quota_value : "-"}</p>
                                                                <p className="mb-1 text-primary-color"><span className="text-white mr-1">Default: </span> {item.default_quota_value ? item.default_quota_value : "-"}</p>
                                                                <p className="mb-1 text-primary-color"><span className="text-white mr-1">Actual: </span> {item.actual_value ? item.actual_value : "-"}</p>
                                                                <p className="mb-1 text-primary-color"><span className="text-white mr-1">Utilization: </span> {item.utilization ? item.utilization+"%" : "-"}</p>
                                                            </div>    
                                                        </UncontrolledTooltip>
                                                        <td className="text-center">{item.applied_quota_value ? item.applied_quota_value : "-"}</td>
                                                        <td className="text-center">{item.default_quota_value ? item.default_quota_value : "-"}</td>
                                                        <td className="text-center">{item.actual_value ? item.actual_value : "-"}</td>
                                                        <td className="text-center">{item.utilization ? item.utilization+"%" : "-"}</td>
                                                    </tr>
                                                )
                                            })
                                        :
                                            <tr>
                                                <td colSpan="5" className="text-center bg-transparent"><p className="mb-0">There are no data on LeftSideTab. Please try adjusting your filter.</p></td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className={`col-sm-5 pl-0 mt-2`}>
                        <div className="rounded bg-dark p-3 h-100">
                            <div className="w-100">
                                <ul className={`row nav nav-tabs border-bottom bg-dark shadow-none justify-content-between`} id="myTab" role="tablist">
                                    <span className={`d-flex ${this.state.menuDisabled ? 'disabled' : '' }`}>
                                        <li className="nav-item">
                                            <span className={`nav-link text-white cursorPointer ${this.state.qutoaPageType === 'Alarms' ? ' active' : ''}`} onClick={() => this.setState({ qutoaPageType: 'Alarms', menuDisabled: true })}>
                                                Alarms
                                            </span>
                                        </li>
                                        <li className="nav-item">
                                            <span className={`nav-link text-white cursorPointer ${this.state.qutoaPageType === 'Monitoring' ? ' active' : ''}`} onClick={() => this.setState({ qutoaPageType: 'Monitoring', menuDisabled: true })}>
                                                Monitoring 
                                                {/* {this.state.selectedRecord && this.state.selectedRecord.alerts ?
                                                    <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">{this.state.selectedRecord.alerts}</span>
                                                : null}  */}
                                            </span>
                                        </li>
                                    </span>
                                </ul>
                                <div className={`obsSectionBodyHeight overflowYAuto`}>
                                    {this.state.qutoaPageType === 'Alarms' ?
                                        <AlarmsTab 
                                            menuDisabled={() => this.setState({ menuDisabled: false })}
                                        />
                                    : this.state.qutoaPageType === 'Monitoring' ?
                                        <MonitoringTab 
                                            menuDisabled={() => this.setState({ menuDisabled: false })}
                                        />
                                    : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
LandingPage.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('observability landing page',state)
	return {
        services: state.filters.services,
        dashboardDetails: state.assets.resourcesPropsDetails && state.assets.resourcesPropsDetails.dashboardDetails ? state.assets.resourcesPropsDetails.dashboardDetails : {},
        tagKeys: state.filters.tagKeys,
    }
}

export default connect(mapStateToProps, {
    listAllServices,
    getAllTagsKeys,
    getAllTagsValues,
	getServiceQuotaDetails,
    setResourcePropsDetails
})(withRouter(LandingPage))