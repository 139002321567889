/*************************************************
 * Tvastar
 * @exports
 * @file TopologyAction.js
 * @author prakash
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'

import {
    TOPOLOGY,
	GET_NODE_BY_DEGREE,
	GET_TOPOLOGY,
} from '../../config'
import { myLog } from '../../utils/utility'


export const getNodeByDegree = (body, callback) => {
    return async dispatch => {
		try {
            const nodeByDegree = await API.post(TOPOLOGY, GET_NODE_BY_DEGREE, { body })
			callback(true, nodeByDegree)
		} catch (error) {
            myLog('nodeByDegree', error)
			callback(false, [])
		}
	}
}

export const getTopology = (body, callback) => {
	return async () => {
		try {
			const topology = await API.post(TOPOLOGY, GET_TOPOLOGY, { body })
			callback(true, topology)
		} catch (error) {
			myLog('topology', error)
			callback(false)
		}
	}
}