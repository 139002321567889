/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Dashboard.js
 * @author Prakash // on 28/06/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 **************************************************/
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { URL_PATH } from '../../../config/urlPath'
import { UncontrolledPopover, PopoverBody, Spinner } from 'reactstrap'
import _ from 'lodash'
import { AppWrapper } from '../../common/AppWrapper'

import { capitalizeFirstLetter, getAccountNameFromId, momentDateGivenFormat, getDayFromSelectedDuration, subDays, subHours, momentConvertionUtcToLocalTime, momentConvertionLocalToUtcTime } from '../../../utils/utility'

import { listAllProviders, listAllAccounts, listAllRegions, listAllServices, getVariousCountsByAccount } from '../../../actions/commonAction'
import {getChangeEvents } from '../../../actions/aiops/ObservabilityAction'
import { getScore } from '../../../actions/detectors/checksDashboardAction'

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

class Dashboard extends Component {
	
	constructor(props) {
		super(props)
		this.accRegRef = React.createRef()
		this.accountRef = React.createRef()
		this.state = {
			minMaxHeight: "770px",
			showLoading: true,
			providers: [],
			selectedProvider: '',

			accounts: [],
			selectedAccount: [],
			isAccountOpen: false,

			// services: [],
			// parentServices: [],
			// selectedService: [],
						
			selectedDuration: '+24h',

			accountsNew: [],
			tagsArray: [0],
		}
	}

	componentDidMount = () => {
		if(this.props.providers && this.props.providers.length) {
			this.setState({ providers: this.props.providers.filter(e => e.provider_id !== "All"), selectedProvider: this.props.providers.filter(e => e.provider_id !== "All")[0].provider_name },
				() => {
					this.onChangeProvider('provider')
				}
			)
		} else {
			this.props.listAllProviders((promise, providers) => {
				if (promise) {
					this.setState({ providers, selectedProvider: providers.length ? providers[0].provider_name : '' },
						() => {
							this.onChangeProvider('provider')
						}
					)
				} else {
					this.setState(
						{ providers: [],  }, 
						() => {
							this.onChangeProvider('')
						}
					)
				}
			})
		}
		
		this.getWindowSize()
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 120
		let outerHeight = (window.outerHeight - browserBorder - headerHeight) +"px"

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }

	onChangeProvider = () => {
		if (this.state.selectedProvider && this.state.selectedProvider !== '') {
			let params = {}
			params.provider = this.state.selectedProvider.toLowerCase()

			if(this.props.accounts && this.props.accounts.length) {
				let allAccounts = this.props.accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
				if(this.props.accounts.length) {
					let accRow = {}
					accRow.account_id = "All"
					accRow.account_name = "All"
					accRow.date = null
					accRow.disabled = false
					accRow.provider = "All"
					allAccounts.unshift(accRow);
				}
				this.setState({ accounts: allAccounts })
			} else {
				this.props.listAllAccounts(params, (promise, allAccounts) => {
					if (promise) {						
						let filterdAccounts = allAccounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
						if(filterdAccounts.length) {
							let accRow = {}
							accRow.account_id = "All"
							accRow.account_name = "All"
							accRow.date = null
							accRow.disabled = false
							accRow.provider = "All"
							filterdAccounts.unshift(accRow);
						}						
						this.setState({ accounts: filterdAccounts })
					}
				})
			}

			if(this.props.regions && this.props.regions.length) {
				let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
				this.setState({ regions: providerRegions })
			} else {
				this.props.listAllRegions(params, (promise, regions) => {
					if (promise) {
						let providerRegions = regions && regions[0] && regions[0].provider_regions
						if(providerRegions) {
							let provider_regions_row = {}
							provider_regions_row.availability_zones = []
							provider_regions_row.city = "All"
							provider_regions_row.country = "All"
							provider_regions_row.geography = "All"
							provider_regions_row.name = "All"
							provider_regions_row.region = "All"
							provider_regions_row.region_id = "All"
							provider_regions_row.state = "All"
							providerRegions.unshift(provider_regions_row);
						}
						this.setState({ regions: providerRegions })
					} else {
						this.setState({ regions: [] })
					}
				})
			}

			this.durationFunction("onLoad")

			// this.props.listAllServices(params, (promise, services) => {
			// 	let serviceArray = []
			// 	let serviceResourceArray = []
			// 	if (promise) {
			// 		if(services) {
			// 			services.length &&
			// 			_.chain(services)
			// 			.groupBy('resource_type')
			// 			.map((value, key) => ({ label: key, sub: value }))
			// 			.value()
			// 			.forEach((service, i) => {
			// 				serviceResourceArray.push(service)
			// 				service.sub.forEach((item, i) => {
			// 					serviceArray.push(item)
			// 				})
			// 			})
			// 		}
			// 		this.setState({ services, parentServices: serviceResourceArray, childServices: serviceArray})
			// 	} else {
			// 		this.setState({ services: [] })
			// 	}
			// })
		}
	}
    
    handleMultiSelectChange = (field, arrayValue) => {
		let value = arrayValue.map(item => item.value)

		let selectedValue = []
		if(field === 'selectedAccount') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.accounts.forEach(acc => {
						selectedValue.push(acc.account_id)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.accounts.length -1)) {
					selectedValue.push('All')
				}
			}	
            this.setState({ [field]: selectedValue, changesInFilter: true })		
		}
    }
    
    getMultiSelectedCount = (type, array) => {
        return array.length && array.includes('All') ? 'All' : (array.length ? array.length +' Selected' : 'All')
    }

	durationFunction = (type) => {
        let duration = this.state.selectedDuration
        let period = duration.substring(duration.length, duration.length-1)
        let durationBtw = getDayFromSelectedDuration(duration)
        let start_time = ""
        if(period === "d") {
            start_time = momentConvertionUtcToLocalTime(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
        } else {
            start_time = momentConvertionUtcToLocalTime(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
        }
        
        let end_time = momentConvertionUtcToLocalTime(new Date(), 'YYYY-MM-DD HH:mm:ss')

        this.setState({ start_time, end_time, changesInFilter: true },
			() => {
                if(type === "onLoad") {
                    this.onSearch('onLoad')
                }
            }
		)
    }

	onSearch = (type) => {
        if(this.state.changesInFilter) {
			this.setState({ 
				resetEnabled: type !== 'onLoad' ? true : false 
			},
				() => {
					this.getVariousCountsByAccount()
					this.getChangeEvents()
					this.getAccountScore()
				}
			)

			setTimeout(() => { this.setState({ showLoading: false }) }, 5000)
		}
	}	

	onReset = () => {
		this.setState({
			selectedProvider: this.state.providers && this.state.providers.length ? this.state.providers[0].provider_name : '',
			selectedAccount: [],
			selectedDuration: '+24h',
			showLoading: true,
			changesInFilter: this.state.resetEnabled,
		},
			() => {
                if(this.state.resetEnabled) {
                    this.durationFunction('onLoad')
                }
            }
		)
	}

	getVariousCountsByAccount = () => {
		let params = {}
		if(this.state.selectedProvider && this.state.selectedProvider !== "") {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if(this.state.selectedAccount && this.state.selectedAccount.length && this.state.selectedAccount.includes("All")) {
			params.account_id = this.state.selectedAccount
		}
		// params.start_time = this.state.start_time
		// params.end_time = this.state.end_time
		params.start_time = momentConvertionLocalToUtcTime(this.state.start_time, "YYYY-MM-DD HH:mm:ss")
		params.end_time = momentConvertionLocalToUtcTime(this.state.end_time, "YYYY-MM-DD HH:mm:ss")
		this.props.getVariousCountsByAccount(params, (promise, response) => {
			if (promise) {
				this.setState({ accountsNew: response })
			} else {
				this.setState({ accountsNew: [] })
			}
		})

	}

    getChangeEvents = () => {   
        let params = {}
        params.provider = this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider.toLowerCase() : ""
        // params.duraiton = this.state.selectedDuration
		params.start_time = momentConvertionLocalToUtcTime(this.state.start_time, "YYYY-MM-DD HH:mm:ss")
		params.end_time = momentConvertionLocalToUtcTime(this.state.end_time, "YYYY-MM-DD HH:mm:ss")
		params.aggregate_by = "account_id"
		params.include_name = true

        
        this.props.getChangeEvents(params, (promise, response) => {
            if(promise) {
                this.setState({ changesResponse: response && response.length ? response : [] })
            } else {
                this.setState({ changesResponse: [], showLoading: false })
            }
        })
    }

	getAccountScore = () => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		params.group_by = ["account_id"]
		params.score_category = ["ALL"]

		this.props.getScore(params, (promise, accScore) => {
			if (promise) {
				let obj = {}
				accScore.forEach(item => {
					obj[item.account_id] = item
				})
				this.setState({ accScore: obj })
			} else {
				this.setState({ accScore: {} })
			}
			
		})
	}
	
	handleClickOutside = (event) => {		
		if(this.state.showFilterSection) {
			if (this.accRegRef && !this.accRegRef.current.contains(event.target)) {
				this.setState({ showFilterSection: false })
			}
		}
		if (this.accountRef && !this.accountRef.current.contains(event.target)) {
			this.setState({ isAccountOpen: false })
		} else {
			this.setState({ isAccountOpen: true })
		}
    }

	redirectLandingPage = (accountId, selectedPage) => {
		this.props.history.push({
            pathname: URL_PATH.RESOURCES_LANDING_PAGE,
            state: { 
                selectedProvider: this.state.selectedProvider,
                selectedAccount: accountId,
				selectedDuration: this.state.selectedDuration,
				selectedPage
            }
        })
	}

	/**
	 * Renders Compliance Dashboard
	 */
	render() {
		return (
			<div className='container-fluid overflow-auto flex-grow-1 bg-muted' onClick={ (event) => { this.handleClickOutside(event) } } >
				<div className={`loaderOverlay ${this.state.showLoading ? '' : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
				<div className='row h-100'>
					<div className='col-sm-12 p-0'>
						<div className='title-section p-2'>
							<div className="d-flex">
								<div className="col-5 pl-2">
									<h6 className="text-white m-0">Resources Dashboard</h6>
									<p className="text-white m-0">Count board view of resources on your Cloud</p>
								</div>
								<div className={`col-7 pl-0 text-right ${this.state.showLoading ? 'disabled' : ''}`}>
									<div className={`d-md-flex justify-content-end`}>
										<div className={`form-group cursorPointer flex-fill mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: '150px' }}>
											<label className="mb-1">Provider</label>
											<Select
												placeholder={'All'}
												isSearchable={false}
												className='f13 p-0 colorBlack reactSelectFilterDrop'
												value={({
													value: this.state.selectedProvider,
													label: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : <span className="placeholder">Select</span>
												})}
												options={this.state.providers && this.state.providers.map(item => ({
													value: item.provider_name,
													label: item.provider_name,	
												}))}
												onChange={event =>  
													this.setState({
														selectedProvider: event.value,
														changesInFilter: true
													},
														() => this.onChangeProvider()
													)
												}
											/>
										</div>										
                                        <div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} ref={this.accountRef} style={{maxWidth: '150px' }}>
                                            <label className="mb-1">Account</label>
                                            <div className={`dlpHeaderMultiSelection ${this.state.accounts && this.state.accounts.length < 10 ? "removeDropdownSearchBar" : ""}`}>
                                                <ReactMultiSelectCheckboxes						
                                                    placeholderButtonLabel="All"
                                                    menuIsOpen ={this.state.isAccountOpen}
                                                    getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedAccount', this.state.selectedAccount)}
                                                    value={this.state.selectedAccount && this.state.selectedAccount.map(comp => ({
                                                        value: comp,
                                                    }))}
                                                    onChange={arr => { this.handleMultiSelectChange('selectedAccount', arr ? arr : []) }}
                                                    options={this.state.accounts && this.state.accounts.map(account => ({
                                                        value: account.account_id,
                                                        label: account.account_name,
                                                    }))}
                                                />
                                            </div>
										</div>
										<div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} style={{maxWidth: '150px' }} ref={this.accRegRef}>
											<div className="" onClick={() => this.setState({ showFilterSection: true })}>
												<label>Region/Tags</label>
												<p className='pt-1 mb-0'>
													<React.Fragment>
													{this.state.selectedRegion && this.state.selectedRegion.length && this.state.selectedRegion.includes("All") ?
														<span>All</span>
													: this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All") ?
														<span>{this.state.selectedRegion.length} selected</span>
													: this.state.accountSelected && this.state.accountSelected.length ?
														<span>All</span>
													:
														<span className="placeholder">&nbsp;</span>
													}
													</React.Fragment>
												</p>
											</div>
											{this.state.showFilterSection ?
												<div className="resourcePopupFilterSection border mt-1">
													<div className="p-3 zapInputDark bg-muted">
														<React.Fragment>
														<div className="w-100">
															<div className="d-flex flex-wrap mb-1">
																<label className="mb-0">Region</label>
																{this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All") ?
																	this.state.selectedRegion.map((cat, index) => {
																		return(
																			<span className="badge badge-secondary ml-2 mb-1">
																				{cat}
																				<i onClick={ () => this.removefilter(index, cat, 'selectedRegion') } className='ml-1 fal fa-times cursorPointer'></i>
																			</span>
																		)
																	})
																: this.state.selectedRegion && this.state.selectedRegion.includes("All") ?
																	<span className="badge badge-secondary ml-2 mb-1">All</span>
																: null}
															</div>
															<div className={`multiselectDarkTheme align-self-center z998`}>
																<ReactMultiSelectCheckboxes						
																	placeholderButtonLabel="All"
																	getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedRegion', this.state.selectedRegion ? this.state.selectedRegion : [])}
																	value={this.state.selectedRegion && this.state.selectedRegion.map(reg => ({
																		value: reg
																	}))}
																	onChange={arr => { this.handleMultiSelectChange('selectedRegion', arr ? arr : []) }}
																	options={this.state.regions && this.state.regions.map(reg => ({
																		value: reg.region,
																		label: reg.name,
																	}))}
																/>
															</div>
														</div>
														<div className={`w-100 mt-3`}>
															<p className="mb-2 w-100">Tags</p>
															<div className={`d-flex`}>
																<label className="mb-1 w-40">Key</label>
																<label className="mb-1 w-40 ml-3">Value</label>
															</div>
															{this.state.tagsArray.map((index, i) => {
																return(
																	<div className={`w-100 ${i ? "mt-2 border-top-black4" : ""}`}>
																		<div className={`d-flex ${!i ? "" : "mt-3"}`}>
																			<Select
																				placeholder={'Select'}
																				isSearchable={true}
																				// menuIsOpen={true}
																				className='f13 p-0 dark-select-theme-with-dropdown-indicator w-40'
																				value={({
																					value: this.state["tag_key_"+index] && this.state["tag_key_"+index] !== "" ? this.state["tag_key_"+index] : 'Select',
																					label: this.state["tag_key_"+index] && this.state["tag_key_"+index] !== "" ? this.state["tag_key_"+index] : <span className="placeholder">Select</span>
																				})}
																				options={this.props.tagKeys && this.props.tagKeys.map(item => ({
																					value: item,
																					label: item,	
																				}))}
																				onChange={event => this.setState({
																					["tag_key_"+index]: event.value,
																					["tagValues_"+index]: [],
																				},
																					() => this.getAllTagsValues(index)
																				)}
																			/>
																			{this.state["tag_key_"+index] && this.state["tag_key_"+index] !== "" && !this.state["tagValues_"+index].length ?
																				<div className='ml-3'>
																					<Spinner className='text-center align-self-center' color='white' size='sm' />
																				</div>
																			:
																				<React.Fragment>
																					<Select
																						placeholder={'Select'}
																						isSearchable={true}
																						// menuIsOpen={true}
																						className='f13 p-0 dark-select-theme-with-dropdown-indicator w-40 ml-3'
																						value={({
																							value: this.state["tag_value_"+index] && this.state["tag_value_"+index] !== "" ? this.state["tag_value_"+index] : 'Select',
																							label: this.state["tag_value_"+index] && this.state["tag_value_"+index] !== "" ? this.state["tag_value_"+index] : <span className="placeholder">Select</span>
																						})}
																						options={this.state["tagValues_"+index] && this.state["tagValues_"+index].map(item => ({
																							value: item,
																							label: item,	
																						}))}
																						onChange={event => this.setState({
																							["tag_value_"+index]: event.value
																						})}
																					/>
																					{this.state.tagsArray.length > 1 ?
																						<span className={`far fa-trash cursor-pointer f18 align-self-center ml-4`} onClick={() => this.removeTagSection(index, i)}></span>
																					: null}
																					{(this.state.tagsArray.length - 1) ===  i ?
																						<span className={`far fa-plus cursor-pointer f18 align-self-center ml-4`} onClick={() => this.addSection("tagsArray")}></span>
																					: null}
																				</React.Fragment>
																			}
																		</div>
																	</div>
																)
															})}
														</div>
														</React.Fragment>
														<div className="d-flex justify-content-end mt-3">
															<span className={`text-info mr-2 align-self-center`} onClick={() => this.resetFilterSelection()}>Clear</span>
															{/* <button className={`btn btn-sm bg-info text-white mr-2`} onClick={() => this.setState({ showFilterSection: false }, () => this.filteredResults())}>Filter</button> */}
															<button className={`btn btn-sm btn-light`} onClick={() => this.setState({ showFilterSection: false })}>Select</button>
														</div>
													</div>
												</div>
											: null}
										</div>
										<div className={`d-flex`}>
                                            <span className={`mr-2 f12 align-self-center`}>Last</span>
                                            {/* <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+90d' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '+90d' }, () => this.durationFunction())}>3 Months</span> */}
                                            {/* <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+60d' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '+60d' }, () => this.durationFunction())}>2 Month</span> */}
                                            <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+30d' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '+30d' }, () => this.durationFunction())}>1 Month</span>
                                            {/* <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+14d' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '+14d' }, () => this.durationFunction())}>2 Week</span> */}
                                            <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+7d' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '+7d' }, () => this.durationFunction())}>1 Week</span>
											<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+3d' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '+3d' }, () => this.durationFunction())}>3 Days</span>
											<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+24h' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '+24h' }, () => this.durationFunction())}>24 Hours</span>
											{/* <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+12h' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '+12h' }, () => this.durationFunction())}>12 Hours</span> */}
											<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+6h' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '+6h' }, () => this.durationFunction())}>6 Hours</span>
											<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+1h' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '+1h' }, () => this.durationFunction())}>1 Hour</span>
                                        </div>
										<div className={`ml-2 align-self-center`}>
											<div className="btn-group" role="group" aria-label="checks-dash">
												<button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-right`} onClick={() => this.setState({ showLoading: true }, this.onSearch()) }> 
													{this.state.showLoading ?
														<Spinner className={`align-self-center mr-2`} size='sm' />
													: null}
													Search
												</button>
												<button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-left`} onClick={() => this.onReset()}><i className='far fa-redo'></i></button>
											</div>
										</div>	
									</div>
								</div>
							</div>
						</div>
						<div className={`box rounded-0 shadow-none bg-transparent`}> {/* p-3 */}
							<div className="row">
								<div className="col-sm-12">
									<div className={`p-2 bg-muted`}> {/* rounded */}
										<div className="row">
											<div className="col-sm-12">
												<div className="bg-dark">
													<div className="table-responsive overflowYAuto" style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
														<table className="table text-white mb-0 tableLayout darkTableHover" >{/* obsSectionHeight */}
															<thead className="" >
																<tr class="w-100">    
																	<th className="bg-dark3" style={{width: "400px"}}>{/* borderRight: "5px solid #383E4D" */}
																		<p className="mb-0 mt-1 small text-white">Showing {this.state.accountsNew && this.state.accountsNew.length} Account(s)</p>
																	</th>
																	<th className={`text-center`}>Changes</th>
																	{this.state.accountsNew && this.state.accountsNew.length && this.state.accountsNew[0].data ?
																		Object.keys(this.state.accountsNew[0].data).map(key => {
																			return(
																				<th className={`text-center`}>{capitalizeFirstLetter(key)}</th>
																			)
																		})
																	: null}
																</tr>
															</thead>
															<tbody>
																{this.state.accountsNew && this.state.accountsNew.length ?
																	this.state.accountsNew.map((item, i) => {
																		return (
																			<tr className={`w-100 border-top cursorPointer`}>
																				<td className="align-middle py-1 bg-dark3" style={{width: "400px"}} onClick={() => this.redirectLandingPage(item.account_id, "")}>{/* borderRight: "5px solid #383E4D" */}
																					<div className={`d-flex justify-content-between pb-2 ${i !== 0 ? 'border-top pt-2' : ''}`}>
																						<div className="w-50 align-self-center">
																							<h7 className="font-weight-bold text-white">{capitalizeFirstLetter(getAccountNameFromId(item.account_id, this.state.accounts))}</h7>
																							<p className="f12 m-0">{item.account_id}</p>
																						</div>
																						<div className="box bg-muted d-flex p-2 shadow-none heihgtFitContent mr-2 align-self-center">
																							<div className="mr-2 width75">														
																								<p className="font-weight-bold m-0 f12">Pass&nbsp;:&nbsp;
																									<h7 className="font-weight-bold mb-0 text-warning">{item.pass_count && item.pass_count}</h7>
																								</p>
																							</div>
																							<div className="mr-2 width75">														
																								<p className="font-weight-bold m-0 f12">Fail&nbsp;:&nbsp;<h7 className="font-weight-bold mb-0 text-dark-theme-orange">{item.fail_count && item.fail_count}</h7></p>
																							</div>
																						</div>
																						<div className="w-10 align-self-center cursorPointer" id={`scorePopover_`+i}>
																							{this.state.accScore && Object.entries(this.state.accScore).length && this.state.accScore.hasOwnProperty(item.account_id) ?
																								<CircularProgressbar
																									value={this.state.accScore[item.account_id].overall ? (this.state.accScore[item.account_id].overall.split("/"))[0] : 0}
																									text={this.state.accScore[item.account_id].overall ? (this.state.accScore[item.account_id].overall.split("/"))[0] : '-'}
																									counterClockwise={false}
																									styles={{trail: {stroke: '#353C4C',strokeWidth:'8'},path: {stroke: '#039BE5',strokeWidth:'6'},text: {fill: '#039BE5',fontSize: '10px'}}}
																								/>
																							: 
																								<CircularProgressbar
																									value={0}
																									text={'-'}
																									counterClockwise={false}
																									styles={{trail: {stroke: '#353C4C',strokeWidth:'8'},path: {stroke: '#039BE5',strokeWidth:'6'},text: {fill: '#039BE5',fontSize: '10px'}}}
																								/>
																							}
																						</div>
																						
																						<UncontrolledPopover className="scorePopOver" trigger="hover" placement="top" target={`scorePopover_`+i}>
																							<PopoverBody>
																								{this.state.accScore && Object.entries(this.state.accScore).length && this.state.accScore.hasOwnProperty(item.account_id) && Object.entries(this.state.accScore[item.account_id].identifier_category) ? 
																									Object.entries(this.state.accScore[item.account_id].identifier_category).map(([key, value], i) => {
																										return(
																										<React.Fragment>
																											{!i ? 
																												<p className={`font-weight-bold mb-2 mt-1 text-white`}>Scores</p> 
																											: null }
																											<div className={` ${Object.entries(this.state.accScore[item.account_id].identifier_category).length === 1 ? 'w-100' :'w-50 pr-2'} ${i%2 ? 'float-right' : Object.entries(this.state.accScore[item.account_id].identifier_category).length === 1 ? '' :'border-right float-left' } `}>
																												<div className="d-flex justify-content-between mb-2 ml-2">
																													<div className="mb-0 align-self-center">
																														<h8 className="font-weight-bold text-white">{key}</h8>
																													</div>
																													<div className={`mb-0 text-right ${Object.entries(this.state.accScore[item.account_id].identifier_category).length === 1 ? 'w-10' :'w-25'} `}>
																														<CircularProgressbar
																															value={(value.split("/"))[0]}
																															text={(value.split("/"))[0]}
																															counterClockwise={false}
																															styles={{trail: {stroke: '#353C4C',strokeWidth:'5'},path: {stroke: '#039BE5',strokeWidth:'3'},text: {fill: '#039BE5',fontSize: '10px'}}}
																														/>
																													</div>
																												</div>
																											</div>	
																										</React.Fragment>
																										)
																									})
																								:
																									<React.Fragment>
																									<div className="mb-0 align-self-center">
																										<h8 className="font-weight-bold blackMuted">No Score</h8>
																									</div>
																									<div className={`mb-0 text-right w-10`}>
																										<CircularProgressbar
																											value={0}
																											text={'-'}
																											counterClockwise={false}
																											styles={{trail: {stroke: '#353C4C',strokeWidth:'5'},path: {stroke: '#039BE5',strokeWidth:'3'},text: {fill: '#039BE5',fontSize: '10px'}}}
																										/>
																									</div>
																									</React.Fragment>
																								}
																							</PopoverBody>
																						</UncontrolledPopover>
																					</div>
																				</td>
																				<td className={`align-middle text-center f18`} onClick={() => this.redirectLandingPage(item.account_id, "ChangeEvents")}>
																					{this.state.changesResponse && this.state.changesResponse.length && this.state.changesResponse.filter(e => e.account_id === item.account_id).length && this.state.changesResponse.filter(e => e.account_id === item.account_id)[0].total_count ?
																						this.state.changesResponse.filter(e => e.account_id === item.account_id)[0].total_count
																					: 
																						"-"
																					}
																				</td>
																				{item.data ?
																					<React.Fragment>
																					{Object.entries(item.data).map(([key, val]) => {
																						return(
																							<td className={`align-middle text-center f18`} onClick={() => this.redirectLandingPage(item.account_id, capitalizeFirstLetter(key))}>{val ? val : "-"}</td>
																						)
																					})}
																					</React.Fragment>
																				: null}
																			</tr>
																		)
																	})
																:
																	<tr>
																		<td colSpan="5" className="text-center bg-transparent"><p className="mb-0">There are no data on criteria. Please try adjusting your filter.</p></td>
																	</tr>
																}
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
Dashboard.propTypes = {
	listAllProviders: PropTypes.func,
	listAllAccounts: PropTypes.func,
	listAllServices: PropTypes.func,	
	location: PropTypes.object,
	getScore:PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		providers: state.filters.providers,
		accounts: state.filters.accounts,
		regions: state.filters.regions,
	}
}

export default AppWrapper(Dashboard, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
	listAllServices,
	getVariousCountsByAccount,
	getChangeEvents,
	getScore,
})



