/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Metrics.js
 * @author Prakash // on 22/02/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import _ from 'lodash'
import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'
import { getMonitoringMetricsByTrend } from '../../../actions/aiops/MetricsAction'
import { getSreDetails } from '../../../actions/aiops/DiagnosticsAction'
import { momentDateGivenFormat, momentConvertionUtcToLocalTime, addHourGetDate, getTimeLocal, convertBytes, convertBytesWithoutUnit, convertSeconds, convertSecondsWithoutUnit, countFormater, countFormaterWithoutUnit, convertBits, convertBitsWithoutUnit } from '../../../utils/utility'

import Chart from 'react-apexcharts'


class AlertMetricList extends Component {
    
    constructor(props) {

        super(props)
        this.timer = null;
        
        this.state = {
            loadingSreMetrics: true,
            // loadingMonMetrics: true,
            sreDetails: [],
            showAnnotation: true,
        }
    }

    componentDidMount = () => {
        if(this.props.details.resource_type !== 'EC2')  {
            if(!this.props.anomalySreMetricsList.hasOwnProperty(this.props.details.asset_id)) {
                this.getSreDetails()
            } else {
                this.setState({ sreDetails: this.props.anomalySreMetricsList[this.props.details.asset_id]}, 
                    () => {
                        this.formSreLatencyGraph()
                        this.formSreRateGraph() 
                    }
                )
            }
        }
        if(this.props.anomalyMonitoringList && Object.entries(this.props.anomalyMonitoringList).length) {
            console.log(this.props.details.asset_id, this.props.anomalyMonitoringList)
            if(this.props.anomalyMonitoringList.hasOwnProperty(this.props.details.asset_id)) {
                this.setState({ metricsByTrend: [] },
                    () => {
                        this.setState({ 
                            metricsByTrend: this.props.anomalyMonitoringList[this.props.details.asset_id],
                            loadingMonMetrics: true,
                        },
                            () => {
                                this.metricsListGraph()
                            }
                        )
                    }
                )
            } else {
                this.getMonitoringMetricsByTrend()
            }
        } else {
            this.getMonitoringMetricsByTrend()
        }

        // if(this.props.anomalySreMetricsList && Object.entries(this.props.anomalySreMetricsList).length) {
        //     if(this.props.anomalySreMetricsList.hasOwnProperty(this.props.details.asset_id)) {
        //         this.setState({ 
        //             sreDetails: this.props.anomalySreMetricsList[this.props.details.asset_id],
        //             loadingSreMetrics: false,
        //         },
        //             () => {
        //                 this.formSreLatencyGraph()
        //                 this.formSreRateGraph()
        //             }
        //         )
        //     } else{
        //         this.setState({ loadingSreMetrics: false })
        //     }
        // }
    }

    // componentDidUpdate = (prevProps) => {
    //     if(!_.isEqual(prevProps.details, this.props.details)) {
    //         if(this.props.anomalyMonitoringList.hasOwnProperty(this.props.details.asset_id)) {
    //             this.setState({ metricsByTrend: [] },
    //                 () => {
    //                     this.setState({ 
    //                         metricsByTrend: this.props.anomalyMonitoringList[this.props.details.asset_id],
    //                     },
    //                         () => this.metricsListGraph()
    //                     )
    //                 }
    //             )
    //         }
    //     }

    //     if(prevProps.anomalySreMetricsList) {
    //         if(prevProps.anomalySreMetricsList !== this.props.anomalySreMetricsList) {
    //             if(this.props.anomalySreMetricsList.hasOwnProperty(this.props.details.asset_id)) {
    //                 this.setState({ anomalySreMetricsList: [] },
    //                     () => {
    //                         this.setState({ 
    //                             sreDetails: this.props.anomalySreMetricsList[this.props.details.asset_id],
    //                             loadingSreMetrics: false,
    //                         },
    //                             () => {
    //                                 this.formSreLatencyGraph()
    //                                 this.formSreRateGraph()
    //                             }
    //                         )
    //                     }
    //                 )
    //             } else{
    //                 this.setState({ loadingSreMetrics: false })
    //             }
    //         }
    //     }
    // }

    getSreDetails = () => {
        let assetId = ''
        if(Array.isArray(this.props.details.asset_id)) {
            assetId = this.props.details.asset_id[0]
        } else {
            assetId = this.props.details.asset_id
        }

        let assetName = ''
        if(Array.isArray(this.props.details.asset_name)) {
            assetName = this.props.details.asset_name[0]
        } else {
            assetName = this.props.details.asset_name
        }

        let params = {}
        params.case_id = this.props.caseDetails.case_id
        params.start_time = this.props.aiopsPropsDetails.alert_slider_changes && this.props.aiopsPropsDetails.alert_slider_changes.sliderStartDate
        params.end_time = this.props.aiopsPropsDetails.alert_slider_changes && this.props.aiopsPropsDetails.alert_slider_changes.sliderEndDate
        params.asset_id = assetId

        if(assetId && assetId !== null && assetId !== '') {
            this.props.getSreDetails(params, (promise, response) => {
                if(promise) {
                    if(response && response.legnth) {
                        let obj = this.props.anomalySreMetricsList
                        obj[assetId] = response
                        this.props.setAiopsPropsDetails('anomalySreMetricsList', obj)
                    }
                    this.setState({ sreDetails: response }, 
                        () => {
                            this.formSreLatencyGraph()
                            this.formSreRateGraph() 
                        }
                    )
                }
            })
            
        }
    }

    getMonitoringMetricsByTrend = () => {
        let params = {}
        params.provider = this.props.caseDetails && this.props.caseDetails.provider ? (Array.isArray(this.props.caseDetails.provider) ? this.props.caseDetails.provider[0] : this.props.caseDetails.provider) : 'aws'
        params.start_time = this.props.aiopsPropsDetails.alert_slider_changes && this.props.aiopsPropsDetails.alert_slider_changes.sliderStartDate
        params.end_time = this.props.aiopsPropsDetails.alert_slider_changes && this.props.aiopsPropsDetails.alert_slider_changes.sliderEndDate

        params.anomaly = true
        params.case_id = this.props.caseId
        if(this.props.details.event_type && this.props.anomalyPage !== "anomalyPage") {
            params.additional_metrics = [this.props.details.event_type]
        }
        let assetId = this.props.details.asset_id
        let assetName = ''
        if(Array.isArray(this.props.details.asset_name)) {
            assetName = this.props.details.asset_name
            params.asset_name = this.props.details.asset_name
        } else {
            params.asset_name = [this.props.details.asset_name]
            assetName = this.props.details.asset_name
        }  
        params.asset_id = assetId      
         
        let topItems = 3
        if(this.props.details.resource_type === 'EC2') {
            topItems = 5
        }
        params.top = topItems
        if(this.props.details.service_name) {
            params.service_name = this.props.details.service_name
        } else {            
            let fileredAsset = this.props.assetsList.filter(obj => obj.asset_id === params.asset_id)
            if(fileredAsset && fileredAsset.length) {
                if(Array.isArray(fileredAsset[0].service_name)) {
                    params.service_name = fileredAsset[0].service_name[0]
                } else {
                    params.service_name = fileredAsset[0].service_name
                }
            }
        }
        params.sre_metric = false

        if(assetId && assetId !== null) {
            this.props.getMonitoringMetricsByTrend(params, (promise, response) => {
                if(promise) {
                    if(response && response.length) {
                        let obj = this.props.anomalyMonitoringList
                        obj[assetId] = response
                        this.props.setAiopsPropsDetails('anomalyMonitoringList', obj)
                    }

                    this.setState({ metricsByTrend: response },
                        () => this.metricsListGraph()
                    )
                }
            })
        }
    }
    
    metricsListGraph = () => {
        let graphDetails = this.state.metricsByTrend        
        if(graphDetails && graphDetails.length) {
            // let graphDates = []
            graphDetails.forEach((graph, index) => {
                let graphData = graph.data
                // graphDates = graph.dates
                Object.entries(graphData).forEach(([key, item], i) => {
                    let array = []
                    let series = []
                    let options = {}
                    if(item.values && item.values.length) {
                        item.values.forEach((val, j) => {
                            let arrayValues = []
                            arrayValues.push(momentConvertionUtcToLocalTime(graph.dates[j], 'YYYY-MM-DD HH:mm:ss'))
                            if(item.unit) {
                                val = this.getGrapUnitValue(val, item.unit)
                            }
                            arrayValues.push(val)
                            array.push(arrayValues)
                        })

                        if(array.length === 1) {
                            let arrayValues = []
                            arrayValues.push(addHourGetDate(momentConvertionUtcToLocalTime(graph.dates[0], 'YYYY-MM-DD HH:mm:ss'), 0.1))
                            arrayValues.push(0)
                            array.push(arrayValues)
                        }

                        series = [{
                            data: array
                        }]
                        
                        let xaxisAnnotation = []
                        if(this.state.showAnnotation) {
                            let caseTimeAnnotation = {
                                x: new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                                strokeDashArray: 0,
                                borderColor: '#FF6F00',
                                label: {
                                    borderColor: '#FF6F00',
                                    style: {
                                        // fontSize: '12px',
                                        color: '#fff',
                                        background: '#FF6F00'
                                    },
                                    orientation: 'horizontal',
                                    text: '',
                                }
                            }

                            xaxisAnnotation.push(caseTimeAnnotation)

                            let alerts = []
                            if(this.props.details && this.props.details.alerts && this.props.details.alerts.length) {
                                this.props.details.alerts.forEach(et => {
                                    alerts.push(et.event_time)
                                })
                            } else {
                                alerts.push(this.props.details.event_time)
                            }

                            if(alerts.length) {
                                alerts.forEach((item, z) => {
                                    let alertRow = {}
                                    // alertRow.x = new Date(momentConvertionUtcToLocalTime(addMinutes(item, (z*10)), 'YYYY-MM-DD HH:mm:ss')).getTime()
                                    alertRow.x = new Date(momentConvertionUtcToLocalTime(item, 'YYYY-MM-DD HH:mm:ss')).getTime()
                                    alertRow.strokeDashArray = 0
                                    alertRow.borderColor = '#039BE5'
                                    alertRow.label = {
                                        borderColor: '#039BE5',
                                        style: {
                                            // fontSize: '12px',
                                            color: '#fff',
                                            background: '#039BE5'
                                        },
                                        orientation: 'horizontal',
                                        text: '',
                                    }

                                    xaxisAnnotation.push(alertRow)
                                })
                            }
                        }

                        // console.log('xaxisAnnotation', xaxisAnnotation)

                        let annotations = {
                            xaxis: xaxisAnnotation
                        }

                        options = {
                            chart: {
                                // group: 'metrics_and_sre',
                                // id:'metrics_'+i,
                                type: 'area',
                                width: '100%',
                                toolbar: {
                                    show: false,
                                },
                                zoom: {
                                    enabled: false
                                },
                                sparkline: {
                                    enabled: false
                                }
                            },
                            stroke: {
                                show: true,
                                curve: 'smooth',
                                lineCap: 'butt',
                                width: 2,
                                dashArray: 0,      
                            },
                            colors: ['#775BA2'],
                            fill: {
                                type: "gradient",
                                gradient: {
                                    // shadeIntensity: 1,
                                    // opacityFrom: 0.7,
                                    // opacityTo: 0.9,
                                    // stops: [0, 90, 100]
                                    gradientToColors: ['#775BA2'],
                                    shadeIntensity: 0,
                                    opacityFrom: .3,
                                    opacityTo: 1,
                                    stops: [30, 90]
                                }
                            },
                            tooltip: {
                                custom: function({series, seriesIndex, dataPointIndex, w}) {
                                    let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
                                    dateLable = dateLable.toString()
                                    dateLable = momentDateGivenFormat(dateLable, 'DD MMM HH:mm')
                                    let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]
                                    return '<div class="arrow_box">'+dateLable+' <span style="color:'+ w.globals.colors +'">' + parseFloat(val).toFixed(2) + '</span> </div>'
                                },
                                fixed: {
                                    enabled: false
                                },
                                x: {
                                    show: false,
                                },
                                y: {
                                    show: false
                                },        
                                marker: {
                                    show: false
                                }
                            },
                            xaxis: {
                                type: 'datetime',
                                labels: {
                                    datetimeUTC: false,
                                    style: {
                                        colors: '#60687C'
                                    },
                                    // formatter: function (value) {
                                    //     return momentDateGivenFormat(value, 'HH:mm')
                                    // },
                                },                            
                                axisTicks: {
                                    show: false
                                },
                            },
                            dataLabels: {
                                enabled: false
                            },
                            yaxis: {
                                show: true,
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                    color: '#60687C'
                                },
                                tooltip: {
                                    enabled: false
                                },
                                // tickAmount: 2,
                                min: 0,
                                // max: yaxisMaxValue, 
                                tickAmount: 1,
                                labels: {
                                    offsetX: -10,
                                    // minWidth: 50,
                                    show: true,
                                    style: {
                                        fontSize: '9px',
                                        colors: '#60687C'
                                    },
                                    formatter: (value) => { 
                                        if(Number(value) === value && value % 1 === 0) {
                                            return Math.ceil(value.toFixed(1))
                                        } else {
                                            return Math.ceil(value.toFixed(1))
                                        }
                                    },
                                    // offsetX: 0,
                                    // offsetY: 0,
                                }
                            },
                            grid: {
                                show: true,
                                borderColor: '#2D323E',
                                strokeDashArray: 0,
                                position: 'back',
                                xaxis: {
                                    lines: {
                                        show: true
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#FFF'],
                                        }
                                    },
                                    axisTicks: {
                                        show: false
                                    }
                                },   
                                yaxis: {
                                    lines: {
                                        show: false
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#969AA7'],
                                        }
                                    }
                                },                
                                padding: {
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0
                                },
                            },
                            annotations: annotations,
                        }
                    }

                    this.setState({ [key+'_metrics_series_'+index+'_'+i]: series, [key+'_metrics_options_'+index+'_'+i]: options, loadingMonMetrics: false })
                })

                if(graph.total_anomaly_count) {
                    let array = []                    
                    let original_data = []
                    graph.total_anomaly_count.forEach((val, k) => {
                        let arrayValues = []
                        arrayValues.push(getTimeLocal(momentConvertionUtcToLocalTime(graph.dates[k], 'YYYY-MM-DD HH:mm:ss')))
                        arrayValues.push(val > 0 ? 5 : 0)
                        original_data.push(val)
                        array.push(arrayValues)
                    })
                    
                    let series = [{
                        name: 'Anomaly',
                        data: array,
                        original_data
                    }]
                    
                    let anomalyBarOptions = {
                        chart: {
                            type: 'bar',
                            sparkline: {
                                enabled: true
                            },
                            animations: {
                                enabled: false,
                            }
                        },
                        plotOptions: {
                            bar: {
                                barHeight: '100%',
                                columnWidth: '5%',        
                            },          
                        },
                        // stroke: {
                        //     // show: true,
                        //     // lineCap: 'butt', 
                        //     width: 1,
                        // },
                        xaxis: {
                            type: 'datetime',
                            // min: getTimeLocal(graphDates[start]),
                            // max: getTimeLocal(graphDates[end]),
                            labels: {
                                datetimeUTC: false,
                            }
                        },
                        colors: ['#F75E3F'],
                        tooltip: {
                            fixed: {
                                enabled: false
                            },
                            x: {
                                show: false
                            },
                            y: {
                                show: false
                            },
                            marker: {
                                show: false
                            },
                            custom: function({series, seriesIndex, dataPointIndex, w}) {
                                let val = w.globals.initialSeries[0].data[dataPointIndex][1]
                                let dateLabel = momentConvertionUtcToLocalTime(w.globals.initialSeries[0].data[dataPointIndex][0], 'MMM DD HH:mm')
                                // let labelName = w.globals.initialSeries[0].event_id
    
                                return '<div class="arrow_box">'+ dateLabel +'<span style="color:'+ w.globals.colors +'">  '+ val +'</span></div>'
                            }
                        }
                    }
    
                    this.setState({ [graph.asset_id+'_anomaly_'+index]: series, ['anomaly_'+graph.asset_id+'_'+index]: anomalyBarOptions })
                }
                
            })
        } else {
            this.setState({ loadingMonMetrics: false })
        }
    }

    formSreLatencyGraph = () => {
        let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']
        if(this.state.sreDetails && this.state.sreDetails.length) {
            let filteredSreDetails = this.state.sreDetails
            filteredSreDetails.forEach((res, index) => {
                let graphData = res.data
                let graphLabels = res.dates
                let yDetails = []
                let series =[]
                let colorInc = 0
                graphData.latency && Object.entries(graphData.latency).forEach(([key, item]) => {
                    let yRow = {}
                    if(item.values && item.values.length) {
                        if(!colorInc) {
                            yRow = {
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                    color: '#60687C'
                                },
                                tooltip: {
                                    enabled: false
                                },
                                tickAmount: 2,
                                min: 0,
                                labels: {
                                    offsetX: -10,
                                    show: true,
                                    style: {
                                        fontSize: '9px',
                                        colors: '#60687C'
                                    },
                                    // formatter: (value) => { 
                                    //     return value.toFixed(2)
                                    // },
                                    formatter: (value) => { 
                                        if(Number(value) === value && value % 1 === 0) {
                                            return Math.ceil(value.toFixed(1))
                                        } else {
                                            return Math.ceil(value.toFixed(1))
                                        }
                                    },
                                }
                            }
                        } else {
                            yRow = {
                                show: false
                            }
                        }
                        yDetails.push(yRow)
                        let dataRow = {}
                        dataRow.name = key
                        dataRow.type = 'line'
                        let plotValue = []
                        graphLabels.forEach((lab, i) => {
                            let dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            let val = item.values[i]
                            if(item.unit && item.unit !== '') {
                                val = this.getGrapUnitValue(val, item.unit)
                            }
                            dataArray.push(val)
                            plotValue.push(dataArray)
                        })
                        dataRow.data = plotValue
                        series.push(dataRow)

                        colorInc++;
                    }

                })

                let xaxisAnnotation = []
                if(this.state.showAnnotation) {
                    let caseTimeAnnotation = {
                        x: new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                        strokeDashArray: 0,
                        borderColor: '#FF6F00',
                        label: {
                            borderColor: '#FF6F00',
                            style: {
                                // fontSize: '12px',
                                color: '#fff',
                                background: '#FF6F00'
                            },
                            orientation: 'horizontal',
                            text: '',
                        }
                    }

                    xaxisAnnotation.push(caseTimeAnnotation)

                    let alerts = []
                    if(this.props.details && this.props.details.alerts && this.props.details.alerts.length) {
                        this.props.details.alerts.forEach(et => {
                            alerts.push(et.event_time)
                        })
                    } else {
                        alerts.push(this.props.details.event_time)
                    }

                    if(alerts.length) {
                        alerts.forEach((item, z) => {
                            let alertRow = {}
                            // alertRow.x = new Date(momentConvertionUtcToLocalTime(addMinutes(item, (z*10)), 'YYYY-MM-DD HH:mm:ss')).getTime()
                            alertRow.x = new Date(momentConvertionUtcToLocalTime(item, 'YYYY-MM-DD HH:mm:ss')).getTime()
                            alertRow.strokeDashArray = 0
                            alertRow.borderColor = '#039BE5'
                            alertRow.label = {
                                borderColor: '#039BE5',
                                style: {
                                    // fontSize: '12px',
                                    color: '#fff',
                                    background: '#039BE5'
                                },
                                orientation: 'horizontal',
                                text: '',
                            }

                            xaxisAnnotation.push(alertRow)
                        })
                    }
                }

                let annotations = {
                    xaxis: xaxisAnnotation
                }

                let options = {
                    chart: {
                        // group: 'metrics_sre',
                        type: 'line',
                        // id: 'chart_latency_'+index,
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false
                        },                        
                        sparkline: {
                            enabled: false
                        },
                    },
                    colors: colors,
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        width: [2, 3, 4],
                        curve: 'smooth',
                        // dashArray: [0, 8, 5]
                    },
                    xaxis: {
                        // categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
                        type: 'datetime',
                        // categories: labels,
                        labels: {
                            datetimeUTC: false,
                            show: true,
                            style: {
                                fontSize: '9px',
                                colors: '#60687C'
                            },
                        }
                    },
                    
                    yaxis: yDetails,
                    // yaxis: {
                    //     show: false,
                    //     tickAmount: 2,
                    //     min: 0,
                    //     // max: 1,
                    //     labels: {
                    //         show: true,
                    //         style: {
                    //             fontSize: '9px',
                    //             colors: '#60687C'
                    //         },
                    //         formatter: (value) => { 
                    //             if(Number(value) === value && value % 1 === 0) {
                    //                 return Math.ceil(value)
                    //             } else {
                    //                 return value.toFixed(1)
                    //             }
                    //         },
                    //     }
                    // },
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            show: false,
                        },
                        x: {
                            show: false
                        },
                        marker: {
                            show: false
                        },
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                                // let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]
                                // let labelName = w.globals.initialSeries[seriesIndex].name
                                // // return '<div className="arrow_box"> '+ capitalizeFirstLetter(labelName) +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' + '  '+ val + '</span> </div>'         
                                let returnData = ''
                                
                                if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                    Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                        if(!index) {
                                            returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                        }
                                        returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'

                                    })
                                }

                                returnData += '</div>'
                                return returnData
                        }
                    },
                    grid: {
                        show: true,
                        borderColor: '#2D323E',
                        strokeDashArray: 0,
                        position: 'back',
                        xaxis: {
                            lines: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: ['#2D323E'],
                                }
                            },  
                        },   
                        yaxis: {
                            lines: {
                                show: false
                            },
                            labels: {
                                style: {
                                    colors: ['#2D323E'],
                                }
                            }
                        },
                        padding: {
                            // top: 0,
                            right: 0,
                            // bottom: 0,
                            left: 0
                        }, 
                    },
                    legend: {
                        show: false
                        // horizontalAlign: 'left',
                        // offsetX: 40
                    },
                    annotations: annotations
                }

                this.setState({ [this.props.details.asset_id+'_series_'+index]: series, [this.props.details.asset_id+'_options_'+index]: options, loadingSreMetrics: false })
            })
        } else {
            this.setState({ showLoading: false })
        }
    }

    formSreRateGraph = () => {
        let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']
        if(this.state.sreDetails && this.state.sreDetails.length) {
            let filteredSreDetails = this.state.sreDetails
            filteredSreDetails.forEach((res, index) => {
                let graphData = res.data
                let graphLabels = res.dates

                let series = []
                
                let  strokeWidth = []
                let x = 0
                let yDetails = []
                let yRow = {}
                graphData.error_rate && Object.entries(graphData.error_rate).forEach(([key, item], j) => {
                    strokeWidth.push(x+2)
                    if(item.values && item.values.length) {
                        if(!x) {
                            yRow = {
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                    color: '#60687C'
                                },
                                tooltip: {
                                    enabled: false
                                },
                                tickAmount: 2,
                                min: 0,
                                labels: {
                                    offsetX: -10,
                                    show: true,
                                    style: {
                                        fontSize: '9px',
                                        colors: '#60687C'
                                    },
                                },
                                formatter: (value) => { 
                                    // console.log(value)
                                    if(Number(value) === value && value % 1 === 0) {
                                        return Math.ceil(value.toFixed(1))
                                    } else {
                                        return Math.ceil(value.toFixed(1))
                                    }
                                },
                            }
                            
                        } else {
                            yRow = {
                                show: false
                            }
                        }
                        // yDetails.push(yRow)

                        let dataRow = {}
                        dataRow.name = key
                        dataRow.type = 'line'
                        
                        let plotValue = []
                        graphLabels.forEach((lab, i) => {
                            let dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))   
                            dataArray.push(item.values[i])                         
                            // let val = item.values[i]
                            // if(item.unit && item.unit !== '') {
                            //     val = this.getGrapUnitValue(val, item.unit)
                            // }
                            plotValue.push(dataArray)
                        })
                        dataRow.data = plotValue
                        series.push(dataRow)
                    }
                    x++;
                })

                let xaxisAnnotation = []
                if(this.state.showAnnotation) {
                    let caseTimeAnnotation = {
                        x: new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                        strokeDashArray: 0,
                        borderColor: '#FF6F00',
                        label: {
                            borderColor: '#FF6F00',
                            style: {
                                // fontSize: '12px',
                                color: '#fff',
                                background: '#FF6F00'
                            },
                            orientation: 'horizontal',
                            text: '',
                        }
                    }

                    xaxisAnnotation.push(caseTimeAnnotation)

                    let alerts = []
                    if(this.props.details && this.props.details.alerts && this.props.details.alerts.length) {
                        this.props.details.alerts.forEach(et => {
                            alerts.push(et.event_time)
                        })
                    } else {
                        alerts.push(this.props.details.event_time)
                    }

                    if(alerts.length) {
                        alerts.forEach((item, z) => {
                            let alertRow = {}
                            // alertRow.x = new Date(momentConvertionUtcToLocalTime(addMinutes(item, (z*10)), 'YYYY-MM-DD HH:mm:ss')).getTime()
                            alertRow.x = new Date(momentConvertionUtcToLocalTime(item, 'YYYY-MM-DD HH:mm:ss')).getTime()
                            alertRow.strokeDashArray = 0
                            alertRow.borderColor = '#039BE5'
                            alertRow.label = {
                                borderColor: '#039BE5',
                                style: {
                                    // fontSize: '12px',
                                    color: '#fff',
                                    background: '#039BE5'
                                },
                                orientation: 'horizontal',
                                text: '',
                            }

                            xaxisAnnotation.push(alertRow)
                        })
                    }
                }

                let annotations = {
                    xaxis: xaxisAnnotation
                }

                let options = {
                    chart: {
                        // group: 'metrics_sre',
                        // id: 'chart_rate_'+index,
                        redrawOnParentResize: true,
                        stacked: true,
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false
                        },
                        sparkline: {
                            enabled: false
                        },
                        type: "line",
                        animations: {
                            enabled: false
                        }
                    },
                    colors: colors,
                    dataLabels: {
                        enabled: false,
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            barHeight: '100%',
                            // borderRadius: 8,
                            startingShape: 'flat',
                            endingShape: 'flat',
                            columnWidth: '60%',
                            distributed: false,
                            rangeBarOverlap: false,
                        }
                    },
                    stroke: {
                        width: strokeWidth,
                        curve: 'smooth',
                    },
                    xaxis: {
                        show: true,
                        type: 'datetime',
                        // categories: labels,
                        labels: {
                            datetimeUTC: false,
                            show: true,
                            style: {
                                fontSize: '9px',
                                colors: '#60687C'
                            },
                        },
                    },
                    yaxis: yDetails,
                    grid: {
                        show: false,
                        borderColor: '#2D323E',
                        strokeDashArray: 0,
                        position: 'back',
                        xaxis: {
                            lines: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: ['#969AA7'],
                                }
                            },  
                        },   
                        yaxis: {
                            lines: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: ['#969AA7'],
                                }
                            },
                        },
                        padding: {
                            // top: 0,
                            right: 0,
                            // bottom: 0,
                            left: 0
                        },
                    },
                    legend: {
                        show: false
                    },
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            show: false,
                        },                        
                        x: {
                            show: false
                        },
                        marker: {
                            show: false
                        },
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                                // let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]
                                // let labelName = w.globals.initialSeries[seriesIndex].name
                                // // return '<div className="arrow_box"> '+ capitalizeFirstLetter(labelName) +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' + '  '+ val + '</span> </div>'         
                                let returnData = ''
                                
                                if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                    Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                        if(!index) {
                                            returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                        }
                                        returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'

                                    })
                                }

                                returnData += '</div>'
                                return returnData
                        }
                    },
                    annotations: annotations
                }

                this.setState({ [this.props.details.asset_id+'_error_rate_series_'+index]: series, [this.props.details.asset_id+'_error_rate_options_'+index]: options, loadingSreMetrics: false })                

                series = []
                graphData.request_rate && Object.entries(graphData.request_rate).forEach(([key, item]) => {
                    if(item.values && item.values.length) {
                        yRow = {
                            axisTicks: {
                                show: false,
                            },
                            axisBorder: {
                                show: false,
                                color: '#60687C'
                            },
                            tooltip: {
                                enabled: false
                            },
                            tickAmount: 2,
                            min: 0,
                            labels: {
                                offsetX: -10,
                                show: true,
                                style: {
                                    fontSize: '9px',
                                    colors: '#60687C'
                                },
                            }
                        }
                        yDetails.push(yRow)
                        let dataRow = {}
                        dataRow.name = key
                        dataRow.type = 'line'
                        let plotValue = []
    
                        graphLabels.forEach((lab, i) => {
                            let dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))   
                            dataArray.push(item.values[i])                         
                            plotValue.push(dataArray)
                        })
                        dataRow.data = plotValue
                        series.push(dataRow)
                    }
                })

                colors = ['#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']

                options = {
                    chart: {
                        // group: 'metrics_sre',
                        // id: 'chart_rate_'+index,
                        redrawOnParentResize: true,
                        stacked: true,
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false
                        },
                        sparkline: {
                            enabled: false
                        },
                        type: "line",
                        animations: {
                            enabled: false
                        }
                    },
                    colors: colors,
                    dataLabels: {
                        enabled: false,
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            barHeight: '100%',
                            // borderRadius: 8,
                            startingShape: 'flat',
                            endingShape: 'flat',
                            columnWidth: '60%',
                            distributed: false,
                            rangeBarOverlap: false,
                        }
                    },
                    stroke: {
                        width: strokeWidth,
                        curve: 'smooth',
                    },
                    xaxis: {
                        show: true,
                        type: 'datetime',
                        // categories: labels,
                        labels: {
                            datetimeUTC: false,
                            show: true,
                            style: {
                                fontSize: '9px',
                                colors: '#60687C'
                            },
                        },
                    },
                    yaxis: yDetails,
                    grid: {
                        show: false,
                        borderColor: '#2D323E',
                        strokeDashArray: 0,
                        position: 'back',
                        xaxis: {
                            lines: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: ['#969AA7'],
                                }
                            },  
                        },   
                        yaxis: {
                            lines: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: ['#969AA7'],
                                }
                            },
                        },
                        padding: {
                            // top: 0,
                            right: 0,
                            // bottom: 0,
                            left: 0
                        },
                    },
                    legend: {
                        show: false
                    },
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            show: false,
                        },                        
                        x: {
                            show: false
                        },
                        marker: {
                            show: false
                        },
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                                // let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]
                                // let labelName = w.globals.initialSeries[seriesIndex].name
                                // // return '<div className="arrow_box"> '+ capitalizeFirstLetter(labelName) +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' + '  '+ val + '</span> </div>'         
                                let returnData = ''
                                
                                if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                    Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                        if(!index) {
                                            returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                        }
                                        returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'

                                    })
                                }

                                returnData += '</div>'
                                return returnData
                        }
                    },
                    annotations: annotations
                }

                this.setState({ [this.props.details.asset_id+'_request_rate_series_'+index]: series, [this.props.details.asset_id+'_request_rate_options_'+index]: options, loadingSreMetrics: false })
            })

        } else {
            this.setState({ loadingSreMetrics: false })
        }
    }

    getGrapUnitValue = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytes(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit)
            } else if(unit === 'Count') {
                // val = val > 1 ? parseInt(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    getGrapUnitValueWithoutUnit = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = !val ? val : parseFloat(val).toFixed(1)
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytesWithoutUnit(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSecondsWithoutUnit(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBitsWithoutUnit(val, unit)
            } else if(unit === 'Count') {
                // val = val > 1 ? parseInt(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val ? Math.ceil(val) : 0
                val = countFormaterWithoutUnit(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }
    
    render() {
        return (
                <div className={`bg-dark rounded p-3 mt-3`}>
                    <div className="d-flex justify-content-between mb-2">
                        <p className={`mb-0 w-75 ${this.props.textColor}`}>
                            <b>Key Metrics for 
                            {this.props.details && this.props.details.resource_type ? ' '+this.props.details.resource_type : ''}
                            {this.props.details && this.props.details.asset_name ? ' '+this.props.details.asset_name : ''}
                            </b>
                        </p> 

                        <div className="d-flex">
                            <div className="d-flex mr-2 align-self-center">
                                <p className="indicatoreBox bg-orange mb-0 mr-2"></p>
                                <p className="f12 mb-0">Case Time</p>
                            </div>
                            <div className="d-flex mr-2 align-self-center">
                                <p className="indicatoreBox bg-info mb-0 mr-2"></p>
                                <p className="f12 mb-0">Alert</p>
                            </div>
                            <span className={`fa fa-expand-wide cursorPointer ${this.props.textColor} align-self-center`} 
                                onClick={() => {
                                    this.props.getMetricsDeatils(this.props.details && this.props.details.asset_name, this.props.details && this.props.details.asset_id) 
                                    this.props.setAiopsPropsDetails('showMetricsList', false)
                                }
                            }></span>
                        </div>
                    </div>
                    <div className={`${this.props.details.event_type && this.props.details.event_type !== '' ? '' : 'displayNone'}`}>
                        {this.state.metricsByTrend && this.state.metricsByTrend.length ? 
                            this.state.metricsByTrend.map((item, index) => {
                                return (
                                    Object.keys(item.data).map((key, i) => {
                                        return (
                                        key === this.props.details.event_type ?
                                            <div className="mb-3">
                                                <div class="p-3 rounded bg-dark3">
                                                    <p class="text-white f16 p-1 mb-0">{key}</p>
                                                    {this.state[key+'_metrics_series_'+index+'_'+i] && this.state[key+'_metrics_series_'+index+'_'+i].length && this.state[key+'_metrics_options_'+index+'_'+i] ?
                                                        <div className="cursorPointer transparentTooltip mb-n4 mt-n4">
                                                            <Chart options={this.state[key+'_metrics_options_'+index+'_'+i]} series={this.state[key+'_metrics_series_'+index+'_'+i]} type="area" height={120}/>
                                                        </div>
                                                    :
                                                        <div style={{minHeight:"85px"}} className="d-flex align-self-center justify-content-center">
                                                            <div className='align-self-center'>
                                                                No Data
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        : null
                                        )
                                    })
                                )
                            })
                        : null}
                    </div>
                    
                    {this.props.details && this.props.details && this.props.details.resource_type !== 'EC2' ?
                        <div className="p-3 rounded bg-dark3">
                            <div className="row">
                                <div className={`col-sm-6`}>
                                    {!this.state.loadingMonMetrics && this.state.metricsByTrend && this.state.metricsByTrend.length ? 
                                        this.state.metricsByTrend.map((item, index) => {
                                            return (
                                            <div className="mb-3">
                                                {Object.keys(item.data).map((key, i) => {
                                                    return (
                                                        this.props.anomalyPage === "anomalyPage" || !item.additional_metrics || item.additional_metrics && !item.additional_metrics.includes(key) ?
                                                            <div className={`${this.props.details && this.props.details && this.props.details.resource_type === 'EC2' ? 'col-sm-6' : ''} mb-3`}>
                                                                <div class="p-3 border rounded">
                                                                    <p class="text-white f16 p-1 mb-0">{key}</p>
                                                                    {this.state[key+'_metrics_series_'+index+'_'+i] && this.state[key+'_metrics_series_'+index+'_'+i].length && this.state[key+'_metrics_options_'+index+'_'+i] ?
                                                                        <div className="cursorPointer transparentTooltip mb-n4 mt-n4">
                                                                            <Chart options={this.state[key+'_metrics_options_'+index+'_'+i]} series={this.state[key+'_metrics_series_'+index+'_'+i]} type="area" height={115}/>
                                                                        </div>
                                                                    : 
                                                                        <div style={{minHeight:"85px"}} className="d-flex align-self-center justify-content-center">
                                                                            <div className='align-self-center'>
                                                                                No Data
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        : null
                                                    )
                                                })}
                                            </div>
                                            )
                                        })
                                    : this.state.loadingMonMetrics ?
                                        <div className='d-flex justify-content-center m-4'>
                                            <Spinner className='text-center' color='white' size='lg' />
                                        </div>
                                    :  !this.state.loadingMonMetrics &&( !this.state.metricsByTrend || !this.state.metricsByTrend.length) ?
                                        <div className='d-flex justify-content-center m-4'>
                                            No Metrics for the this asset.
                                        </div>
                                    : null}
                                </div>
                                <div className="col-sm-6 pl-0">
                                    {!this.state.loadingSreMetrics && this.state.sreDetails && this.state.sreDetails.length ?
                                        this.state.sreDetails.map((res, index) => {
                                            return(
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <div class="mb-3 col-12 px-0">
                                                        <div class="p-3 border rounded">
                                                            <p class="f16 text-white m-0 p-1">Latency</p>
                                                            {this.state[this.props.details.asset_id+'_series_'+index] && this.state[this.props.details.asset_id+'_series_'+index].length ?
                                                                <div className={`cursorPointer transparentTooltip mt-n4 mb-n4`}>
                                                                    <Chart options={this.state[this.props.details.asset_id+'_options_'+index]} series={this.state[this.props.details.asset_id+'_series_'+index]} type="line" height={115} />
                                                                </div>
                                                            : null}    
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="row">
                                                    <div class="mb-3 col-12 px-0">
                                                        <div class="p-3 border rounded">
                                                            <p class="f16 text-white m-0 p-1">Request Rate</p>
                                                            {this.state[this.props.details.asset_id+'_request_rate_series_'+index] && this.state[this.props.details.asset_id+'_request_rate_series_'+index].length ?
                                                                <div className={`cursorPointer transparentTooltip mt-n4 mb-n4`}>
                                                                    <Chart options={this.state[this.props.details.asset_id+'_request_rate_options_'+index]} series={this.state[this.props.details.asset_id+'_request_rate_series_'+index]} type="line" height={115} />
                                                                </div>
                                                            :
                                                                <div style={{minHeight:"85px"}} className="d-flex align-self-center justify-content-center">
                                                                    <div className='align-self-center'>
                                                                        No Data
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="mb-3 col-12 px-0">
                                                        <div class="p-3 border rounded">
                                                            <p class="f16 text-white m-0 p-1">Error Rate</p>
                                                            {this.state[this.props.details.asset_id+'_error_rate_series_'+index] && this.state[this.props.details.asset_id+'_error_rate_series_'+index].length ?
                                                                <div className={`cursorPointer transparentTooltip mt-n4 mb-n4`}>
                                                                    <Chart options={this.state[this.props.details.asset_id+'_error_rate_options_'+index]} series={this.state[this.props.details.asset_id+'_error_rate_series_'+index]} type="line" height={115} />
                                                                </div>
                                                            :
                                                                <div style={{minHeight:"85px"}} className="d-flex align-self-center justify-content-center">
                                                                    <div className='align-self-center'>
                                                                        No Data
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                        })
                                    : this.state.loadingSreMetrics ?
                                        <div className='d-flex justify-content-center m-4'>
                                            <Spinner className='text-center' color='white' size='lg' />
                                        </div>
                                    : this.state.sreDetails && !this.state.sreDetails.length ?
                                        <div className='d-flex justify-content-center m-4'>
                                            No SRE Metrics for the this asset1.
                                        </div>
                                    : null}
                                </div>
                            </div>
                        </div>
                    : 
                        <div className="p-3 rounded bg-dark3">
                            {this.state.metricsByTrend && this.state.metricsByTrend.map((item, index) => {
                                return (
                                    <div className="row">
                                        {Object.keys(item.data).map((key, i) => {
                                            return (
                                            key !== this.props.details.event_type ?
                                                <div className={`col-sm-6 mb-3`}>
                                                    <div class="p-3 border rounded">
                                                        <p class="text-white f16 p-1 mb-0">{key}</p>
                                                        {this.state[key+'_metrics_series_'+index+'_'+i] && this.state[key+'_metrics_series_'+index+'_'+i].length && this.state[key+'_metrics_options_'+index+'_'+i] ?
                                                            <div className="cursorPointer transparentTooltip mb-n4 mt-n4">
                                                                <Chart options={this.state[key+'_metrics_options_'+index+'_'+i]} series={this.state[key+'_metrics_series_'+index+'_'+i]} type="area" height={115}/>
                                                            </div>
                                                        : 
                                                            <div style={{minHeight:"85px"}} className="d-flex align-self-center justify-content-center">
                                                                <div className='align-self-center'>
                                                                    No Data
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            : null
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div>
                // <div className={`bg-dark rounded p-3 mt-3`}>
                //     <p className={`mb-2 ${this.props.textColor}`}><b>Key Metrics for {this.props.details ? this.props.details.resource_type : ''}
                //          {this.props.details ? ' '+this.props.details.asset_name : ''}</b></p>
                //     <div className='d-flex justify-content-center m-4'>
                //         No Metrics for the this criteria, Please adjust your filter to get the metric details.
                //     </div>
                // </div>
        )
    }
}
/**
 * Type of the props used in the component
 */
AlertMetricList.propTypes = {
    setAiopsPropsDetails: PropTypes.func,
    getMonitoringMetricsByTrend: PropTypes.func,
    getMetricsDeatils: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
    // console.log('state', state)
    return {
        aiopsPropsDetails: state.aiops.aiopsPropsDetails ? state.aiops.aiopsPropsDetails : {},
        anomalyDetails: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.anomalyDetails ? state.aiops.aiopsPropsDetails.anomalyDetails : {},
        caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : {},
        assetsList: state.aiops.caseDetails.results && state.aiops.caseDetails.results.assets ? state.aiops.caseDetails.results.assets : [],
        
        anomalyMonitoringList: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.anomalyMonitoringList ? state.aiops.aiopsPropsDetails.anomalyMonitoringList : {},
        anomalySreMetricsList: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.anomalySreMetricsList ? state.aiops.aiopsPropsDetails.anomalySreMetricsList : {},
    }
    
}

export default connect(mapStateToProps, {
    getMonitoringMetricsByTrend,
    getSreDetails,
    setAiopsPropsDetails,
})(withRouter(AlertMetricList))