/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file compilanceDetail.js
 * @author Prakash // on 15/06/2020
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { URL_PATH } from '../../../config/urlPath'

import { UncontrolledPopover, PopoverHeader, PopoverBody, Col, Row, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap'

import { numberFormater, splitterFirstPart, splitterSecondPart, capitalizeFirstLetter, getAccountNameFromId, momentDateGivenFormat } from '../../../utils/utility'

import { AppWrapper } from '../../common/AppWrapper'
import { listAllProviders, listAllAccounts } from '../../../actions/commonAction'
import { getTotalCount, getDayWiseCount, resolveIssue } from '../../../actions/detectors/detectorsAction'

import { 
    getComplianceByEvent,
    getComplianceByRole,
    getComplianceByTag,
    getComplianceControlDetails,
    getComplianceSubControlDetails,
    getComplianceMinorControlDetails,
    getPassFailDetails,
    getTransitionTrend } from '../../../actions/detectors/complianceDashboardAction'
import { getScore, getStatusCount } from '../../../actions/detectors/checksDashboardAction'


import ViewSidePanel from './ViewSidePanel'
import SearchControls from './SearchControls'
import _ from 'lodash'

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

import Chart from 'react-apexcharts'
import ApexBarChart from '../../common/charts/ApexBarChart'
import ApexSimpleProgressChart from '../../common/charts/ApexSimpleProgressChart'
import ApexDonutChart from '../../common/charts/ApexDonutChart'
import ApexRiskStackedBarChart from '../../common/charts/ApexRiskStackedBarChart'
import ApexAreaChart from '../../common/charts/ApexAreaChart'

import HorizontalLimitLollipopChart from '../../common/charts/HorizontalLimitLollipopChart'
import StackedCircleBarChart from '../../common/charts/StackedCircleBarChart'

let score = 0;


class ComplianceDashboard extends Component {
    controlRef = React.createRef()
	constructor(props) {
        super(props)
        this.rightSideTableRef = React.createRef()
        this.rightRadialStackedBarWidthRef = React.createRef()
        this.rightHorizontalLimitLollipopRef = React.createRef()
		this.tabRef = React.createRef()
        this.accordionContent = [];
        this.accordionChildContent = [];
		this.state = {
			selectedFilterPeriod: 7,
            popoverOpen: false,
            setPopoverOpen: false,
            showLoading: true,
            selectedComplicance: '',
            complianceScore: 0,
            selectedProvider: '',
            selectedDuration: '',            
            //selectedTag:this.props.selectedTag,
            //detectorKey:this.props.detectorKey,
            selectedTag:'Environment',
            detectorKey:'Production',
            providers: [],
            selectedAccount: '',
            accounts: [],
            processCount:[],
            passFailDetailsCount: [],
            passlDetailsCount: [],
            failDetailsCount: [],

            failSum: 0,
            dayWiseCountGraphData: [],
            percentStatusCount: [],

            majorControlCounts: [],
            subControlCounts: [],
            minorControlCounts: [],
            complianceCounts: [],
            complianceCategoryScore: [],
            dayWiseCounts: [],
            complianceControlDetails: [],
            complianceSubControlDetails: [],
            complianceMinorControlDetails: [],
            controlsTotalArray: [],
            filteredArray: [],
            showDetailsPanel: false,
            transitionTrend: [],
            
            modalHeader: '',
            modalSignup: false,
            modalAlert: false,
            controlsList: [],
            isControlOpen: false,
            selectedControls: [],            

            closeLoading: false,
            closeStatus: 'resolved',
            closeStatusType: '',
            closeAttachment: '',
            closeAttachmentKey: false,
            
            responseMessage: '',
            showMessage: false,
            hasErrorInRuleForm: false,
            
            // topThreatsGraphData: topThreatsGraphData,

            selectedTopFailure: 'Resources'
        }

        this.toggleCloseModal = this.toggleCloseModal.bind(this)
    }

	componentDidMount = () => {
        if (this.props.location && this.props.location.state && Object.keys(this.props.location.state).length !== 0) {
            this.setState({
                    selectedAccount: this.props.location.state.selectedAccount,
                    selectedProvider: this.props.location.state.selectedProvider,
                    selectedDuration: this.props.location.state.selectedDuration,
                    selectedComplicance: this.props.location.state.selectedComplicance,
                    complianceCoverage: this.props.location.state.complianceCoverage
                    //showDetailsPanel: true
                }
            )    
            if(this.props.location.state.from) {
                this.setState({ fromUrl: this.props.location.state.from.pathname })
            }
        }

        if(!this.props.providers || !this.props.providers.length) {
            this.props.listAllProviders((promise, providers) => {
                if (promise) {
                    this.setState({ providers, selectedProvider: this.state.selectedProvider === '' ? (providers.length ? providers[0].provider_name : '') : this.state.selectedProvider },
                        () => {
                            this.onChangeProvider()
                            this.onSearch('onLoad')
                        }
                    )
                } else {
                    this.setState({ providers: [] },
                        () => {
                            this.filterIdentifiers()
                            this.onSearch('onLoad')
                        }
                    )
                }
            })
        } else {
            this.setState({ providers: this.props.providers, selectedProvider: this.state.selectedProvider === '' ? (this.props.providers.length ? this.props.providers[0].provider_name : '') : this.state.selectedProvider },
                () => {
                    this.onChangeProvider()
                    this.onSearch('onLoad')
                }
            )
        }
        
        this.getInitializePaginationRight()
    }

    onChangeProvider = () => {
        if(!this.props.accounts || !this.props.accounts.length) {
            let params = {}
            params.provider = this.state.selectedProvider.toLowerCase()
            this.props.listAllAccounts(params, (promise, accounts) => {
                if (promise) {
                    this.setState({ accounts: accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())})
                } else {
                    this.setState({ accounts: [] })
                }
            })
        } else {
            this.setState({ accounts: this.props.accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())})
        }
    }

	onSearch = (type) => {

        this.setState({ selectedComplianceScore: '', riskData: {}, dayWiseCounts: {}, newTrendData: {}, pendingTrendData: {}, resolvedTrendData: {}, reoccuredTrendData: {}, resetEnabled: type !== 'onLoad' ? true : false, showRightSideRadialChart: false, rightSideFilteredTableData: [], showRightSideLollipopChart: false, showLoading: true },
            () => {
                //asset and event leading to failure count
                this.getPassFailDetails();
        
                this.getScore()        
                this.getDayWiseCount();
                this.getDayWiseCount('risk');
                this.getStatusCount()
                //this.getDayWiseRiskCount();
                this.getComplianceControlDetails(); //major   
                        
                
                this.getTotalCount('compliance.authoritySubControl');
                this.getTotalCount('compliance.authorityMinorControl');
                this.getTotalCount('compliance.complianceId');
                this.getTransitionTrend()

                this.onChangeTopFailures()

            }
        )

        setTimeout(() => { this.setState({ showLoading: false }) }, 5000)

	}	

	onReset = () => {
		this.setState({
			selectedProvider: this.state.providers && this.state.providers.length ? this.state.providers[0].provider_name : '',
			selectedAccount: '',
			selectedDuration: '+30d'
		},
			() => {
                if(this.state.resetEnabled) {
                    this.onSearch('onLoad')
                }
            }
		)
	}

    toggleCloseModal = (event) => {
        if(event.keyCode !== 27)  {
            this.setState({
                modalSignup: !this.state.modalSignup
            })
        }
    }

    filterChangedHandler = (event) => {
		this.setState({ hasErrorInRuleForm: false, disableOnSubmit: true })
		let file_size = event.target.files[0].size;
	
		//or if you like to have name and ty	pe
		// let file_name = event.target.files[0].name;
		let file_type = event.target.files[0].type;
		let file_extension = file_type.split('.')
		if(file_extension.length === 1) {
			file_extension = file_type.split('/')
		}
		file_extension = file_extension[file_extension.length - 1].toLowerCase();
		// let files = event.target.files[0]
		let upload = 1
		let allowedType = ['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png', 'pdf', 'msword', 'txt']
		if(!allowedType.includes(file_extension)) {
			upload = 0
			this.setState({ hasErrorInRuleForm: true, closeAttachment: '', attachmentMessage: 'Make sure your proof type is image / pdf / doc / txt'})
			return false;
		}

		if(file_size > 0) {
			let fileSizeMb = (file_size / 1024) /1024

			if(fileSizeMb > 1) {
				upload = 0
				this.setState({ hasErrorInRuleForm: true, closeAttachment: '', attachmentMessage: 'Proof size should be less than 1mb', disableOnSubmit: false })
				return false;
			}
		}
		if(upload === 1) {
			this.setState({ closeAttachment: event.target.files[0], disableOnSubmit: false  })
		}
	   //do whatever operation you want to do here
	}
    
    onModalSubmit = () => {
        this.setState({ closeLoading: true, responseMessage: '', disableOnSubmit: true })
        
        let filters = {}

        filters.provider = this.state.selectedProvider.toLowerCase()
        filters.duration = this.state.selectedDuration
        if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
            filters.account_id = this.state.selectedAccount
        }

        //filters.compliance = this.state.selectedDuration
        if((this.state.selectedControls.length === 1 && this.state.selectedControls[0] === this.state.closeMajorControl) || this.state.selectedControls.length === 0 ) {
            filters.compliance = [{"complianceId": this.state.selectedComplicance, 'authorityMajorControl': this.state.closeMajorControl}]
        } else if(this.state.selectedControls.length > 0) {
            let complianceArray = []
            this.state.selectedControls.forEach(item => {
                let dataRow = {}
                dataRow.complianceId = this.state.selectedComplicance
                dataRow.authorityMajorControl = this.state.closeMajorControl
                dataRow[this.state.closeControlType] = item
                complianceArray.push(dataRow)
            })
            filters.compliance = complianceArray
        }

		let params = {}
		params.status = this.state.closeStatus
		params.sub_status =  this.state.closeStatusType
        params.filters = filters
		params.resolve_mode = 'mannual'
		params.reason = 'solved manually'
		const data = new FormData() 
		if(this.state.closeAttachment) {
			data.append('proof', this.state.closeAttachment);
		}
		data.append('request', JSON.stringify(params));

		if(!this.state.hasErrorInRuleForm) {
			this.props.resolveIssue(data, (promise, response) => {
                if(promise) {
                    this.setState({
                        closeLoading: false,
                        closeStatusType: '',
                        closeReason: '',
                        closeAttachment: '',
                        responseMessage: response.data.message,
                        showMessage: true,
                        showLoading: false,
                        hasErrorInRuleForm: false,
                        responseStatus: response.data.status,
                    }, 
                        () => this.hideMessage()
                    )
                } else {
                    this.setState({ 
                        disableOnSubmit: false, 
                        closeLoading: false,
                        showMessage: true,
                        showLoading: false,
                        responseMessage: response.message,
                        responseStatus: response.status,
                    })
                }
			})
		}
	}
    
    hideMessage = () => {
        setTimeout(() => {
            this.setState({
                modalSignup: !this.state.modalSignup,
                showMessage: false,
            }, 
                this.getTransitionTrend()
            );
        }, 5000)
    }
    
    envSlideLeft = key => {
        this.accordionContent[key].scrollLeft -= 50
	}

	envSlideItemRight = (key, controls) => {
        //console.log(this.accordionContent[key].getBoundingClientRect())
        let totalControls = 0;
        this.state.complianceSubControlDetails && this.state.complianceSubControlDetails[controls] ?
            totalControls = this.state.complianceSubControlDetails[controls].sub_controls.length
        :
            totalControls = this.state.complianceMinorControlDetails[controls].minor_controls.length
            //console.log(totalControls)
            //console.log(this.accordionContent[key])
        let reqTabWidth = 0
		for (let i = 0; i < totalControls; i++) {
            //console.log(this.accordionChildContent[i])
            reqTabWidth += this.accordionChildContent[i].getBoundingClientRect().width
			//reqTabWidth += this.accordionContent[key].current.childNodes[i].getBoundingClientRect().width
        }    
        //console.log(reqTabWidth)

		const averageSliderItemWidth = reqTabWidth/totalControls
		const sliderWrapWidth = this.accordionContent[key].getBoundingClientRect().width
		const totalItemShowing = sliderWrapWidth/averageSliderItemWidth;
        //const index = this.state.complianceCategoryScore.indexOf(this.state.selectedSeries)
        
		// const index = 1
		if (totalItemShowing < totalControls) {
			this.accordionContent[key].scrollLeft += 50
		} else {
		 	this.accordionContent[key].scrollLeft = 0
		}
    }
    
    getPassFailDetails = () =>{
		let params = {}
        // params.compliance = [{"complianceId":this.state.selectedComplicance}]
        // params.duration = this.state.selectedDuration

        params.compliance = [{"complianceId":this.state.selectedComplicance}]
        if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
        }
        params.provider = this.state.selectedProvider.toLowerCase()
        params.duration = this.state.selectedDuration

		this.props.getPassFailDetails(params, (promise, passFailDetailsCount) => {
			if (promise) {
                this.setState({ passFailDetailsCount, passDetailsCount: passFailDetailsCount.pass_details, failDetailsCount: passFailDetailsCount.fail_details});
			} else {
				this.setState({ passFailDetailsCount: [] })
			}
		})
	}

    getScore = account => {
		let params = {}		
        
        //params.duration = this.state.selectedDuration
        params.score_category = ["compliance_category"]
        
        //params.compliance = [{"complianceId":this.state.selectedComplicance}]
        if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
        }
        params.provider = this.state.selectedProvider.toLowerCase()
        params.duration = this.state.selectedDuration
		
		this.props.getScore(params, (promise, score) => {			
            let selectedComplianceScore = 0
			if (promise) {
                // let donutArray = []
                let total_count = 0
                let dataRow = {}
                if(score[0] && score[0].compliance_category) {
                    dataRow.label = 'Score'
                    let dataItems = []
                    Object.entries(score[0].compliance_category).forEach(([key, value]) => {
                        if(key === this.state.selectedComplicance) {
                            selectedComplianceScore = parseInt(value.split("/")[0])
                            total_count = parseInt(value.split("/")[0])
                            let itemRow = {}
                            itemRow.item_count = parseInt(value.split("/")[0])
                            itemRow.item_name = 'Score'
                            dataItems.push(itemRow)

                            itemRow = {}
                            itemRow.item_count = parseInt(value.split("/")[1]) - parseInt(value.split("/")[0])
                            itemRow.item_name = 'Remaining'
                            dataItems.push(itemRow)
                        }
                    })
                    dataRow.items = dataItems
                    dataRow.total_count = total_count
                    // donutArray.push(dataRow)
                }
                this.setState({ complianceCategoryScore: score[0] && score[0].compliance_category ? score[0].compliance_category : [], complianceDonutScore: dataRow, selectedComplianceScore })
			} else {
				this.setState({ complianceCategoryScore: [] })
			}
		})
    }

    getTransitionTrend = () => {
        let params = {}                
        params.compliance = [{"complianceId":this.state.selectedComplicance}]
        params.provider = this.state.selectedProvider.toLowerCase()
        params.duration = this.state.selectedDuration
        if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
        }
        		
		this.props.getTransitionTrend(params, (promise, transitionTrend) => {
			if (promise) {
                this.setState({ transitionTrend });
                this.dayWiseTransitionGraph();
			} else {
                this.setState({ transitionTrend: [] })
            }
		})
    }
    
    getDayWiseCount = aggregateBy =>{
		let params = {}        
        params.duration = this.state.selectedDuration
        params.compliance = [{"complianceId":this.state.selectedComplicance}]
        if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
        }
        params.provider = this.state.selectedProvider.toLowerCase()

        if(aggregateBy === 'risk') {
            params.aggregate_by = aggregateBy
        } else if(aggregateBy === 'reoccurred' || aggregateBy === 'pending' || aggregateBy === 'resolved') {
            params.sub_status = aggregateBy
        }
		
		this.props.getDayWiseCount(params, (promise, dayWiseCounts) => {
			if (promise) {
                if(aggregateBy === 'risk') {
                    this.setState({ dayWiseCounts },
                        () => this.dayWiseRiskCountApexGraph()
                    );
                } else {
                    this.setState({dayWiseCountGraphData: dayWiseCounts, multi_pass_fail_series: [], multi_pass_fail_options: []},
                        () => this.multidayWiseCountGraphApex()                        
                    );
                    // this.getTotalCount('risk')   
                }
			} else {
                if(aggregateBy === 'risk') {
                    this.setState({ dayWiseCounts: [] })
                } else {
                    this.setState({ dayWiseCountGraphData: [] })
                }
			}
		})		
    }

	multidayWiseCountGraphApex = () => {
        let graphData = this.state.dayWiseCountGraphData;
        let data1 = []
        let data2 = []
        let passData = [];
        let failData = [];
        let totalSum = 0;
        let passSum = 0;
        let failSum = 0;

        if(graphData){
            for(let x=0;x<graphData.labels.length;x++) {
                let dataItems1 = {}
                dataItems1.x = momentDateGivenFormat(graphData.labels[x], 'DD MMM Y')
                dataItems1.y  = graphData.data.pass[x]				
                data1.push(dataItems1)

                let dataItems2 = {}
                dataItems2.x = momentDateGivenFormat(graphData.labels[x], 'DD MMM Y')
                dataItems2.y  = graphData.data.fail[x]
                data2.push(dataItems2)
            }

            passData =  graphData.data.pass;
            failData =  graphData.data.fail;
            
            passSum = passData.reduce(function(a, b){
                return a + b;
            }, 0);

            failSum = failData.reduce(function(a, b){
                return a + b;
            }, 0);

            totalSum = failSum + passSum;

            let multi_series = [
                {
                    name: 'Pass',
                    data: data1,
                },
                {
                    name: 'Fail',
                    data: data2,
                }
            ]

            let options = {
                tooltip: {
                    enabled: true,
                    style: {
                        fontSize: '9px',
                    },
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        let val  = series[seriesIndex][dataPointIndex];
                        let labelName = w.globals.initialSeries[seriesIndex].name
                        return '<div class="arrow_box">' +
                          '<span>' +  w.globals.initialSeries[0].data[dataPointIndex].x + '</span>' +
                          '<span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val + ' </span>' +
                          '</div>'
                      }
                },				
                chart: {
                    redrawOnParentResize: true,
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                    sparkline: {
                        enabled: false
                    },
                    type: 'bar',
                    height: 150,
                    stacked: true,
                },
                colors: ['#FFD200', '#B96422'],
				plotOptions: {
					bar: {
						horizontal: false,
						barHeight: '100%',
						// borderRadius: 'rounded',
						startingShape: 'flat',
						endingShape: 'flat',
						columnWidth: '60%',
						distributed: false,
						rangeBarOverlap: false,
						colors: {
							ranges: [{
								from: 1,
								to: 0.9,
								color: 'red'
							}],
							backgroundBarColors: ['#333947'],
							backgroundBarOpacity: 1,
							backgroundBarRadius: 0,
						}
					},       
				},
                dataLabels: {
                    enabled: false
                },
                grid: {
                    show: false,			        
                    xaxis: {
                        lines: {
                            show: false
                        },
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: -5
                    },
                },
                xaxis: {
                    show: true,
                    tooltip: {
                        enabled: false
                    },
                    type: 'datetime',
                    axisBorder: {
                        show: true,
                        color:'#434B5E'
                    },
                    axisTicks: {
                        show: false
                    },
                    labels: {
                        show: true,
                        style: {
                            colors: '#B8BBBE',
                            fontSize: '13px',
                            fontWeight: 'bolder',
                        }
                    }
                },
                yaxis: {
                    show: false,
                    axisBorder: {
                        show: true,
                        color: '#969AA7',
                    },
                    labels: {
                        show: false,
                        style: {
                            colors: '#B8BBBE',
                            fontSize: '13px',
                            fontWeight: 'bolder',
                        }
                    }
                },
                legend: {
                    show: false,
                },
            }
            this.setState({ multi_pass_fail_series: multi_series, multi_pass_fail_options: options, totalSum, passSum, failSum });
        }
    }

	getStatusCount = () =>{
		let params = {}
        // params.provider = this.state.selectedProvider.toLowerCase()
        // params.duration = this.state.selectedDuration
        params.past_duration = "week"	
        
        params.compliance = [{"complianceId":this.state.selectedComplicance}]
        if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
        }
        params.provider = this.state.selectedProvider.toLowerCase()
        params.duration = this.state.selectedDuration
		
		this.props.getStatusCount(params, (promise, percentStatusCount) => {
			if (promise) {
				this.setState({percentStatusCount})
			} else {
				this.setState({ percentStatusCount: [] })
			}
		})
	}
    
    getComplianceControlDetails = () =>{
        let params = {}
        //params.compliance = [{"complianceId":this.state.selectedComplicance}]
        params.compliance = [{"complianceId":this.state.selectedComplicance}]
        // if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
		// 	params.account_id = this.state.selectedAccount
        // }
        params.provider = this.state.selectedProvider.toLowerCase()
        params.duration = this.state.selectedDuration
		
		this.props.getComplianceControlDetails(params, (promise, complianceControlDetails) => {
			if (promise) {
                this.setState({ complianceControlDetails },
                   () =>  {
                       this.getComplianceSubControlDetails()
                       this.getTotalCount('compliance.authorityMajorControl');
                   }
                );
			} else {
                this.setState({ complianceControlDetails: [] })
			}
		})
    }

    getComplianceSubControlDetails = () =>{
        let params = {}
        //params.compliance = [{"complianceId":this.state.selectedComplicance}]
        
        params.compliance = [{"complianceId":this.state.selectedComplicance}]
        // if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
		// 	params.account_id = this.state.selectedAccount
        // }
        params.provider = this.state.selectedProvider.toLowerCase()
        params.duration = this.state.selectedDuration
		
		this.props.getComplianceSubControlDetails(params, (promise, complianceSubControlDetails) => {
			if (promise) {
                if(complianceSubControlDetails === null) {
                    this.setState({ complianceSubControlDetails });
                    this.getComplianceMinorControlDetails()
                } else {
                    let controlsTotalArray = []
                    this.state.complianceControlDetails.forEach((item,i) =>{
                        item.minor_control = []
                        let subArray = []
                        complianceSubControlDetails && complianceSubControlDetails[item.control] &&
                        complianceSubControlDetails[item.control].sub_controls.forEach(subCtrl => {
                            let sub_control = {}
                            sub_control.count = subCtrl.count
                            sub_control.description = subCtrl.description
                            sub_control.detectors = subCtrl.detectors
                            sub_control.control = subCtrl.sub_control
                            
                            const subCtrlColor = this.subCtrlColor(item.control, subCtrl.sub_control)
                            let passCount = 0
                            let failCount = 0
                            let naCount = 0
                            let bgColor = '';
                            let orderBy = 0
                            if(subCtrlColor) {
                                if(subCtrlColor.fail_count > 0) {
                                    orderBy = 2
                                } else if(subCtrlColor.pass_count > 0) {
                                    orderBy = 1
                                }
                                passCount = subCtrlColor.pass_count
                                failCount = subCtrlColor.fail_count
                                naCount = subCtrlColor.NA_count
                                bgColor = subCtrlColor.bgColor
                            }
                            sub_control.passCount = passCount
                            sub_control.failCount = failCount
                            sub_control.naCount = naCount
                            sub_control.bgColor = bgColor
                            sub_control.orderBy = orderBy
                            subArray.push(sub_control)
                        })
                        item.sub_control = subArray
                        controlsTotalArray.push(item)
                    })
                    this.setState({ complianceSubControlDetails, controlsTotalArray, filteredArray: controlsTotalArray, radialBarData: controlsTotalArray, showLoading: false },
                        () => this.formRadialBarChart()    
                    );
                }
			} else {
                this.setState({ complianceSubControlDetails: [] })
			}
		})
    }    

    subCtrlColor = (control, subControl) => {
        if(this.state.subControlCounts){
            for(let i=0;i<this.state.subControlCounts.length;i++){
                let item = this.state.subControlCounts[i]
                let subCtrlData = {}
                if(control === item.authorityMajorControl && subControl === item.authoritySubControl) {
                    subCtrlData.NA_count = item.NA_count; 
                    subCtrlData.pass_count = item.pass_count; 
                    subCtrlData.fail_count = item.fail_count;                  
                    let bgColor = "border-color-info";
                    if (item.fail_count > 0) {
                        bgColor = "border-color-dark-theme-orange";
                    } else if(item.fail_count === 0) {
                        bgColor = "border-warning";
                    }
                    subCtrlData.bgColor = bgColor

                    return subCtrlData;
                    //break;
                }
            }
        }
    }

    getComplianceMinorControlDetails = () => {
        let params = {}
        //params.compliance = [{"complianceId":this.state.selectedComplicance}]
        
        params.compliance = [{"complianceId":this.state.selectedComplicance}]
        // if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
		// 	params.account_id = this.state.selectedAccount
        // }
        params.provider = this.state.selectedProvider.toLowerCase()
        params.duration = this.state.selectedDuration
		
		this.props.getComplianceMinorControlDetails(params, (promise, complianceMinorControlDetails) => {
			if (promise) {
                let controlsTotalArray = []
                this.state.complianceControlDetails.forEach((item,i) =>{
                    item.sub_control = []
                    let minorArray = []
                    complianceMinorControlDetails && complianceMinorControlDetails[item.control] &&
                    complianceMinorControlDetails[item.control].minor_controls.forEach(minCtrl => {
                        let minor_control = {}
                        minor_control.count = minCtrl.count
                        minor_control.description = minCtrl.description
                        minor_control.detectors = minCtrl.detectors
                        minor_control.control = minCtrl.minor_control
                                                
                        const minCtrlColor = this.minCtrlColor(item.control, minCtrl.minor_control)
                        let passCount = 0
                        let failCount = 0
                        let naCount = 0
                        let bgColor = '';
                        let orderBy = 0
                        if(minCtrlColor) {
                            if(minCtrlColor.fail_count > 0) {
                                orderBy = 2
                            } else if(minCtrlColor.pass_count > 0) {
                                orderBy = 1
                            }
                            passCount = minCtrlColor.pass_count
                            failCount = minCtrlColor.fail_count
                            naCount = minCtrlColor.NA_count
                            bgColor = minCtrlColor.bgColor
                        }
                        minor_control.passCount = passCount
                        minor_control.failCount = failCount
                        minor_control.naCount = naCount
                        minor_control.bgColor = bgColor
                        minor_control.orderBy = orderBy
                        minorArray.push(minor_control);
                    })

                    item.minor_control = _.orderBy(minorArray, ['orderBy'], ['desc'])
                    controlsTotalArray.push(item)
                })

                this.setState({ complianceMinorControlDetails, controlsTotalArray, filteredArray: controlsTotalArray, radialBarData: controlsTotalArray, showLoading: false },
                    () => {
                        this.formRadialBarChart()
                    }
                );
			} else {
                this.setState({ complianceMinorControlDetails: [] })
			}
		})
    }

    minCtrlColor = (control, minorControl) => {
        if(this.state.minorControlCounts){
            for(let i=0;i<this.state.minorControlCounts.length;i++){
                let item = this.state.minorControlCounts[i]
                let minCtrlData = {}
                if(control === item.authorityMajorControl && minorControl === item.authorityMinorControl) {  
                    minCtrlData.NA_count = item.NA_count; 
                    minCtrlData.pass_count = item.pass_count; 
                    minCtrlData.fail_count = item.fail_count;                  
                    let bgColor = "border-color-info";
                    if (item.fail_count > 0) {
                        bgColor = "border-color-dark-theme-orange";
                    } else if(item.fail_count === 0) {
                        bgColor = "border-warning";
                    }
                    minCtrlData.bgColor = bgColor

                    return minCtrlData;
                    //break;
                }
            }
        }
    }

	formRadialBarChart = () => {
        let graphData = this.state.radialBarData
		// let rightSideGraphColors = ["#9029cc", "#a22ee6", "#bc47ff", "#c35cff", "#cb70ff", "#d285ff", "#da99ff", "#e15ad2", "#fb74eb", "#fb83ed", "#fc93f0", "#fca2f2", "#fdb2f4", "#fdc1f6"]
		// let rightSideGraphColors = ['#24a597', '#775ba2']
        let rightSideGraphColors = ['#FFD200', '#B96422']
        let keys = ['Pass', 'Fail']
	
        let rightSideArray = []
		let rightSideHighest = 0
		let rightSideTotal = 0
        let rightSideTotalTableData = {}
        graphData.forEach((item, index) => {
            if(item.sub_control && item.sub_control.length) {
                let failCount = 0
                if(this.state.majorControlCounts && this.state.majorControlCounts.length) {
                    let filter = this.state.majorControlCounts.filter(arr => arr.authorityMajorControl === item.control)
                    if(filter.length) {
                        // passCount = filter[0].pass_count ? filter[0].pass_count : 0
                        failCount = filter[0].fail_count ? filter[0].fail_count : 0
                    }
                }
                let State = 'files_'+index
                let dataRow = {}
                dataRow.State = State
                dataRow.name = item.control
                // dataRow.Pass = passCount
                dataRow.Pass = 0
                dataRow.Fail = failCount
                // dataRow.rightSideHighest = passCount + failCount
                let passFailTotal = failCount
                rightSideTotal = graphData.length
                if(rightSideHighest < passFailTotal) {
                    rightSideHighest = passFailTotal
                }
                if(failCount > 0) {
                    rightSideArray.push(dataRow)
                }

                let filterCtrl = _.orderBy(item.sub_control, ['failCount'], ['desc'])
                rightSideTotalTableData[State] = filterCtrl

            } else if(item.minor_control && item.minor_control.length) {
                let failCount = 0
                if(this.state.majorControlCounts && this.state.majorControlCounts.length) {
                    let filter = this.state.majorControlCounts.filter(arr => arr.authorityMajorControl === item.control)
                    if(filter.length) {
                        // passCount = filter[0].pass_count ? filter[0].pass_count : 0
                        failCount = filter[0].fail_count ? filter[0].fail_count : 0
                    }
                }
                let State = 'files_'+index
                let dataRow = {}
                dataRow.State = State
                dataRow.name = item.control
                // dataRow.Pass = passCount
                dataRow.Pass = 0
                dataRow.Fail = failCount
                // dataRow.rightSideHighest = passCount + failCount
                let passFailTotal = failCount
                rightSideTotal = graphData.length
                if(rightSideHighest < passFailTotal) {
                    rightSideHighest = passFailTotal
                }
                if(failCount > 0) {
                    rightSideArray.push(dataRow)
                }

                let filterCtrl = _.orderBy(item.minor_control, ['failCount'], ['desc'])
                rightSideTotalTableData[State] = filterCtrl
            }
        })
		
		if(rightSideArray.length < 25) {			
			let length = rightSideArray.length
			for(let i=length; i<=25; i++) {
				let dataRow = {}
				dataRow.State = 'files_'+i
				dataRow.name = 'empty_data'
				// dataRow.rightSideHighest = 0
				keys.forEach((key, j) => {
					// if(j===0) {
                    //     let count = 1
                    //     if(rightSideHighest <= 5) {
                    //         count = 1/5
                    //     }
					// 	dataRow[key] = count
                    //     // dataRow[key] = rightSideHighest
					// } else {
					// 	dataRow[key] = 0
					// }
                    dataRow[key] = 0
				})
				rightSideArray.push(dataRow)
			}
		}

		let rightSideTotalData = {}
		let data = {}
		data.name = 'Control Data'
		keys.forEach((key, j) => {
			data[key] = this.count(rightSideArray, key)
		})
		rightSideTotalData.data = data

        this.setState({ rightSideGraphColors,  rightSideArray, rightSideKeys: keys, rightSideHighest, rightSideTotalData, rightSideTotal, rightSideGraphId: 'totalThreats', rightSideSvgGraphId: 'svgTotalThreats', showRightSideRadialChart: true, showRightSideLollipopChart: true, rightSideTotalTableData },
            () => this.rightSideDataFormat()
        )
	}

	count = (array, key) => {
		return array.reduce(function (r, a) {
			return r + a[key];
		}, 0);
	}
    
    getTotalCount = aggregateBy => {
        let params = {}
        params.aggregate_by = aggregateBy
        params.compliance = [{"complianceId":this.state.selectedComplicance}]
        if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
        }
        params.duration = this.state.selectedDuration

        this.props.getTotalCount(params, (promise, totalCount) => {
            if (promise) {
                if(aggregateBy === 'compliance.authorityMajorControl') {
                    let majorControlCounts = totalCount
                    this.setState({ majorControlCounts },
                        () => this.formTopthreats()    
                    )
                } else if(aggregateBy === 'compliance.authoritySubControl') {
                    let subControlCounts = totalCount
                    this.setState({ subControlCounts })
                } else if(aggregateBy === 'compliance.authorityMinorControl') {
                    let minorControlCounts = totalCount
                    this.setState({ minorControlCounts }) 
                } else if(aggregateBy === 'compliance.complianceId') {
                    let complianceCounts = totalCount
                    this.setState({ complianceCounts })
                }
            } else {
                if(aggregateBy === 'compliance.authorityMajorControl') {
                    let majorControlCounts
                    this.setState({ majorControlCounts })
                } else if(aggregateBy === 'compliance.authoritySubControl') {
                    let subControlCounts
                    this.setState({ subControlCounts })
                } else if(aggregateBy === 'compliance.authorityMinorControl') {
                    let minorControlCounts
                    this.setState({ minorControlCounts })
                } else if(aggregateBy === 'compliance.complianceId') {
                    let complianceCounts
                    this.setState({ complianceCounts })
                }
            }
        })        
    }

    formTopthreats = () => {
        let data = []
        let labels = []
        if(this.state.majorControlCounts) {
            let orderMajorCtrl = _.orderBy(this.state.majorControlCounts, ['fail_count'], ['desc']);
            orderMajorCtrl.slice(0,5).forEach(item => {
                data.push(item.fail_count)
                let label = item.authorityMajorControl
                if(this.state.complianceControlDetails) {
                    let filter = this.state.majorControlCounts.filter(arr => arr.authorityMajorControl === item.control)
                    if(filter.length) {
                        label += filter[0].description ? ' : '+filter[0].description : ''
                    }
                }
                labels.push(label)
           })
        }

        if(data.length < 5) {
            for(let i=data.length;i<5;i++){
                data.push(0)
                labels.push('')
            }
        }

        let topThreatsGraphData = {
            data,
            labels
        }

        this.setState({ topThreatsGraphData })

        // let topControls = []
        // this.state.complianceControlDetails && this.state.complianceControlDetails.forEach(item => {
        //     console.log(item)
        //     let dataRow = {}
        //     dataRow.label = item.control+''+ (item.description && item.description !== '' ? ' : '+item.description : '')
        //     let count = 0
        //     if(this.state.majorControlCounts) {
        //         let filter = this.state.majorControlCounts.filter(arr => arr.authorityMajorControl === item.control)
        //         if(filter.length && filter[0].fail_count) {
        //             count = filter[0].fail_count
        //         }
        //     }
        //     dataRow.count = count
        //     topControls.push(dataRow)
        // })
        // topControls = _.orderBy(topControls, ['count'], ['desc']);
        // if(topControls < 5) {
        //     for(let i=data.length;i<5;i++){
        //         let dataRow = {}
        //         dataRow.count = 0
        //         dataRow.label = ''
        //         topControls.push(dataRow)
        //     }
        // }
        // console.log('topControls', topControls)
    }

    dayWiseTransitionGraph = () => {
		let graphDetails = this.state.transitionTrend.details
		let graphLabels = this.state.transitionTrend.labels
		
		if(graphDetails) {
			for (const [key, value] of Object.entries(graphDetails)) {
				let data = []
				let graphData = value.daywise_trend
				for(let x=0;x<graphLabels.length;x++) {
					let dataItems = {}
					dataItems.x = momentDateGivenFormat(graphLabels[x], 'DD MMM Y')
					dataItems.y  = graphData[x]
					data.push(dataItems)
				}

				let trendGraphData = {}
				trendGraphData.labels = graphLabels
				trendGraphData.data = data

				this.setState({ [key+'_trend_data']: trendGraphData });
			}
		}
	}
    
	dayWiseRiskCountApexGraph= () => {
        let graphData = this.state.dayWiseCounts;
		if(graphData){
            //for risk donut chart start
            let riskData =  {}

            let items = []
            let totalCount = 0
            let criticalCount = 0
            if(graphData.Critical) {
                criticalCount = graphData.Critical.data.fail.reduce(function(a, b){
                    return a + b;
                }, 0);
                totalCount += criticalCount
            }
            let dataRow = {}
            dataRow.item_count = criticalCount
            dataRow.item_name = 'Critical'
            items.push(dataRow)

            let hightCount = 0
            if(graphData.High) {
                hightCount = graphData.High.data.fail.reduce(function(a, b){
                    return a + b;
                }, 0);
                totalCount += hightCount
            }
            dataRow = {}
            dataRow.item_count = hightCount
            dataRow.item_name = 'High'
            items.push(dataRow)

            let mediumCount = 0
            if(graphData.Medium) {
                mediumCount = graphData.Medium.data.fail.reduce(function(a, b){
                    return a + b;
                }, 0);
                totalCount += mediumCount
            }
            dataRow = {}
            dataRow.item_count = mediumCount
            dataRow.item_name = 'Medium'
            items.push(dataRow)

            let lowCount = 0
            if(graphData.Low) {
                lowCount = graphData.Low.data.fail.reduce(function(a, b){
                    return a + b;
                }, 0);
                totalCount += lowCount
            }
            dataRow = {}
            dataRow.item_count = lowCount
            dataRow.item_name = 'Low'
            items.push(dataRow)

            riskData.items = items
            riskData.label = 'Risks'
            riskData.total_count = totalCount
            //for risk donut chart end


			
			this.setState({ riskData, totalFailCount: totalCount });
		}
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this._handleKeyDown)
    }
        
	_handleKeyDown = event => {
		switch (event.keyCode) {
			case 27:
				this.setState({ showAdvancedSearchModal: false })
				break
			default:
				break
		}
	}

    closeIssue = (closeMajorControl, description, closeControls, closeControlType) => {
        this.setState({ 
            controlsList: [], 
            modalHeader: '', 
            closeControls,
            selectedControls: [],

            disableOnSubmit: false,
            closeLoading: false,
            closeStatus: 'resolved',
            closeStatusType: '',
            closeAttachment: '',
            closeAttachmentKey: false,            
            responseMessage: '',
            showMessage: false,
            hasErrorInRuleForm: false, 
        })

        let closeControlsNew = []
        let dataRow1 = {};
        dataRow1.control = closeMajorControl
        closeControlsNew.push(dataRow1)
        
        closeControls.forEach(item => {
            if(item.failCount > 0) {
                let dataRow = {}
                dataRow.control = item.control
                closeControlsNew.push(dataRow)
            }
        })

        this.setState({ modalSignup: !this.state.modalSignup, closeMajorControl, closeControlType, controlsList: closeControlsNew, modalHeader: closeMajorControl+' '+description })
    }

     handleMultiSelectChange = (childKey, arrayValue) => {
        let value = arrayValue.map(item => item.value)
		if(childKey === 'selectedControls') {
			if(value !== '' && (value.includes(this.state.closeMajorControl))) {
				value = [this.state.closeMajorControl]
				this.setState({ selectedControls: value, isControlOpen: false})
			} else {
				this.setState({ selectedControls: value })
			}
        }
    }
    
    handleClickOutside(event) {
		if(this.state.controlsList.length) {
			if (this.controlRef && !this.controlRef.current.contains(event.target)) {
				this.setState({ isControlOpen: false })
			} else {
				this.setState({ isControlOpen: true })
			}
		}
	}	

	getInitializePaginationRight = () => {
		this.setState({
			rightSidePageCount: 1,
			currentPage: 1,
			rightSideStart: 0, 
			rightSidePerPage: 5,
			disableLeftAction: true,
			disableRightAction: false,
		})
	}

    rightSideDataFormat = (data, State) => {   
        let result = []
        let rightSidePageCount = 1
		let rightSidePageCountArray = []
		let ritPageStart = 0
		let ritPageEnd = 1

        // let splitName = data && data.name ? data.name.split(":") : []
        let controlName = data && data.name ? data.name : ''
		
        if(this.state.rightSideTotalTableData.hasOwnProperty(State)) {
            result = this.state.rightSideTotalTableData[State]
        }

        let rightSideDataLength = Object.entries(result).length
        if(rightSideDataLength > this.state.rightSidePerPage) {
			rightSidePageCount = Math.ceil(rightSideDataLength / this.state.rightSidePerPage)
			ritPageEnd = rightSidePageCount > 5 ? 5 : rightSidePageCount
			for(let i=0;i<rightSidePageCount;i++) {
				rightSidePageCountArray.push(i+1)
			}
		}

        let array = []
        if(!State) {
            this.state.controlsTotalArray.forEach(item => {
                let dataRow = {}
                dataRow.control = splitterFirstPart(item.control, ':')
                dataRow.description = item.description  
                this.state.majorControlCounts && this.state.majorControlCounts.forEach(controlCounts => {
                    if(controlCounts && controlCounts.authorityMajorControl === item.control) {
                        dataRow.failCount = controlCounts.fail_count
                    }
                })
                if(typeof dataRow.failCount === "undefined") {
                    dataRow.failCount = 0
                }
                array.push(dataRow)
            })
            result = array
        }
        
        this.setState({ 
            ritPageStart,
			ritPageEnd,
			rightSidePageCount,
			rightSidePageCountArray,
            rightSideFilteredTableData: _.orderBy(result, ['failCount'], ['desc']),
            controlName
        })
    }

    navigatePage = (action, currentPage) => {
		let ritPageStart = 0
		let ritPageEnd = this.state.rightSidePageCount > 5 ? 5 : this.state.rightSidePageCount

		let startRecord = this.state.rightSideStart
		if(action === 'next') {
			startRecord = startRecord + this.state.rightSidePerPage
			currentPage = currentPage + 1
		} else if(action === 'previous' && currentPage !== 1) {
			currentPage = currentPage - 1
			startRecord = startRecord - this.state.rightSidePerPage
		} else if(action === 'start' && currentPage !== 1) {
			currentPage = 1
			startRecord = 0
		} else if(action === 'end') {
			currentPage = this.state.rightSidePageCount
			ritPageStart = this.state.ritPageStart + (currentPage - 5)
			ritPageEnd = this.state.rightSidePageCount
			startRecord = (this.state.rightSidePageCount - 1) * this.state.rightSidePerPage
		} else if(action === 'page') {
			startRecord = (currentPage - 1) * this.state.rightSidePerPage
		}	
		
		if(this.state.rightSidePageCount > 5) {
			if(currentPage > 2 && (action === 'next' || action === 'page')) {
				ritPageStart = this.state.ritPageStart + 1
				ritPageEnd = this.state.ritPageEnd + 1
			}

			if(currentPage < 3 && (action === 'previous' || action === 'page') && this.state.ritPageStart > 1) {
				ritPageStart = this.state.ritPageStart - 1
				ritPageEnd = this.state.ritPageEnd - 1
			}

			if(ritPageEnd > this.state.rightSidePageCount) {
				ritPageStart = this.state.ritPageStart
				ritPageEnd = this.state.rightSidePageCount
			}
		}

		let disableLeftAction = true
		if(currentPage > 1) {
			disableLeftAction = false
		}

		let disableRightAction = false
		if(currentPage === this.state.rightSidePageCount) {
			disableRightAction = true
		}

		this.setState({ currentPage, ritPageStart, ritPageEnd, rightSideStart: startRecord, disableLeftAction, disableRightAction },
            () => this.rightSideTableRef.current.scrollIntoView()    
        )
	}

    onChangeTopFailures = () => {
        if(this.state.selectedTopFailure === 'Resources') {
            this.getTopFailure('service_group')
        } else if(this.state.selectedTopFailure === 'Roles') {
            this.getComplianceByRole()
        } else if(this.state.selectedTopFailure === 'Services') {
            this.getTopFailure('resource_type')
        } else if(this.state.selectedTopFailure === 'Environment' || this.state.selectedTopFailure === 'Application' || this.state.selectedTopFailure === 'Cost Centre') {
            this.getComplianceByTag()
        } else if(this.state.selectedTopFailure === 'Events') {
            this.getComplianceByEvent()
        }
    }

    getTopFailure = aggregateBy => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
        params.duration = this.state.selectedDuration
		params.compliance = [{"complianceId":this.state.selectedComplicance}]
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		params.aggregate_by = aggregateBy
		this.props.getTotalCount(params, (promise, response) => {
            let dataArray = []
            let totalCount = 0
			if (promise) {
                if(response.length) {
                    response = _.orderBy(response, ['fail_count'], ['desc']); 
                    totalCount = response.map(item => item.fail_count).reduce((prev, next) => prev + next);
                    response.slice(0,4).forEach(item=> {
                        let dataRow = {}
                        dataRow.item_count = item.fail_count
                        if(aggregateBy === 'service_group') {
                            dataRow.item_name = item.service_group
                            dataRow.label = item.service_group
                        } else if(aggregateBy === 'resource_type') {
                            dataRow.item_name = item.resource_type
                            dataRow.label = item.resource_type
                        }
                        dataArray.push(dataRow)
                    })
                }
            }
            
            let horizontalGraphData = {}
            horizontalGraphData.count = totalCount
            horizontalGraphData.data = dataArray
            this.setState({ horizontalGraphData, showEqualizerGraph: true })
        })
    }
    
    getComplianceByTag = () => {
        let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
            params.account_id = this.state.selectedAccount
		}
        params.compliance = [{"complianceId":this.state.selectedComplicance}]
        params.tags = [{"key":this.state.selectedTopFailure}]

        this.props.getComplianceByTag(params, (promise, response) => {
            let dataArray = []
            let totalCount = 0
            if(promise) {
                if(Object.entries(response).length) {
                    response[this.state.selectedTopFailure] && response[this.state.selectedTopFailure].slice(0,4).forEach(item => {
                        let dataRow = {}
                        totalCount += item.fail
                        dataRow.item_count = item.fail
                        dataRow.item_name = item.value
                        dataRow.label = item.value
                        dataArray.push(dataRow)
                    })
                }
            }
            let horizontalGraphData = {}
            horizontalGraphData.count = totalCount
            horizontalGraphData.data = dataArray
            this.setState({ horizontalGraphData, showEqualizerGraph: true })
        })
    }

    getComplianceByRole = () => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		params.compliance = [{"complianceId":this.state.selectedComplicance}]

		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		
		this.props.getComplianceByRole(params, (promise, response) => {    
            let dataArray = []
            let totalCount = 0
			if (promise) {
                if(response.length) {
                    response = _.orderBy(response, ['fail'], ['desc']);
                    totalCount = response.map(item => item.fail).reduce((prev, next) => prev + next);

                    response.slice(0,4).forEach(item=> {
                        let dataRow = {}
                        dataRow.item_count = item.fail
                        dataRow.item_name = item.role_name
                        dataRow.label = item.role_name
                        dataArray.push(dataRow)
                    })
                }
			}
            
            let horizontalGraphData = {}
            horizontalGraphData.count = totalCount
            horizontalGraphData.data = dataArray
            this.setState({ horizontalGraphData, showEqualizerGraph: true })
		})
	}

    getComplianceByEvent = () => {
        let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		params.compliance = [{"complianceId":this.state.selectedComplicance}]

		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		
		this.props.getComplianceByEvent(params, (promise, response) => {
            let dataArray = []
            let totalCount = 0
			if(promise) {
                if(response.length) {
                    response.forEach(item => {
                        let dataRow = {}
                        dataRow.item_name = item.event_name
                        dataRow.label = item.event_name
                        let count = 0
                        if(item.risk_details && item.risk_details.length) {
                            count = item.risk_details.map(item => item.fail).reduce((prev, next) => prev + next);
                        }
                        totalCount += count
                        dataRow.item_count = count
                        dataArray.push(dataRow)
                    })
                    dataArray = _.orderBy(dataArray, ['count'], ['desc']);
                    dataArray = dataArray.slice(0,4)
                }
            }

            let horizontalGraphData = {}
            horizontalGraphData.count = totalCount
            horizontalGraphData.data = dataArray
            this.setState({ horizontalGraphData, showEqualizerGraph: true })
		})
    }
    
	/**
	 * Renders detectors Dashboard
	*/
	render() {
		return (
            <div className='container-fluid overflow-auto flex-grow-1 bg-muted'>
                {this.state.showDetailsPanel ? (            
					<ViewSidePanel
                        closeViewSidePanel={() => this.setState({ showDetailsPanel: false })}
                        selectedComplicance = {this.state.selectedComplicance}
                        complianceScore = {score}
                        selectedProvider = {this.state.selectedProvider}
                        selectedAccount = {this.state.selectedAccount}
                        accounts={this.state.accounts}
                        selectedDuration = {this.state.selectedDuration}
                        totalFailSum = {this.state.failSum}
                        totalPassSum = {this.state.passSum}
                        failDetailsCount = {this.state.failDetailsCount}
					/>
				) : null}
                <div className={`loaderOverlay ${this.state.showLoading ? '' : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
                <div className='row h-100'>
					<div className='col-sm-12 p-0'>
						<div className='title-section p-2'>
							<div className="row">
								<div className="col-5 mt-0">
                                    <div className='d-flex align-self-center'>
                                        <div className='mr-3'>
                                            {this.state.fromUrl !== undefined ?
                                                <Link
                                                    className='text-white far fa-arrow-left'
                                                    to={this.state.fromUrl}
                                                >
                                                </Link>
                                            : 
                                                <Link
                                                    className='text-white far fa-arrow-left'
                                                    to={URL_PATH.COMPLIANCE_DASHBOARD}
                                                >
                                                </Link>
                                            }
                                        </div>
                                        <div className='d-flex'>
                                            <h6 className="text-white m-0">Policy - {this.state.selectedComplicance.toUpperCase()}</h6>
                                            <span className='ml-3 text-info align-self-center cursorPointer' onClick={ () => this.setState({showDetailsPanel:true})}>Additional Details</span>
                                        </div>
                                    </div>
                                    <p className="text-white m-0">Detailed results of the checks performed for this compliance policy</p>
                                </div>
                                <div className={`col-7 text-right ${this.state.showLoading ? 'disabled' : ''}`}>
									<div className={`d-md-flex justify-content-end`}>
                                        <div className={`form-group cursorPointer flex-fill mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: '150px' }}>
                                            <label className="mb-1">Provider</label>
                                            <Select
                                                placeholder={'All'}
                                                isSearchable={false}
                                                className='f13 p-0 colorBlack reactSelectFilterDrop'
                                                value={({
                                                    value: this.state.selectedProvider,
                                                    label: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : <span className="placeholder">Select</span>
                                                })}
                                                options={this.state.providers && this.state.providers.map(item => ({
                                                    value: item.provider_name,
                                                    label: item.provider_name,	
                                                }))}
                                                onChange={event =>  
                                                    this.setState({
                                                        selectedProvider: event.value,
                                                        changesInFilter: true,
                                                        complianceScore: 0,
                                                        showLoading: true,
                                                    },
                                                        () => this.onChangeProvider()
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className={`form-group cursorPointer flex-fill mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: '150px' }}>
                                            <label className="mb-1">Account</label>
                                            <Select
                                                placeholder={'All'}
                                                isSearchable={false}
                                                className='f13 p-0 colorBlack reactSelectFilterDrop'
                                                value={({
                                                    value: this.state.selectedAccount && this.state.selectedAccount !== '' ? this.state.selectedAccount : 'All',
													label: this.state.selectedAccount && this.state.selectedAccount !== '' ? getAccountNameFromId(this.state.selectedAccount, this.state.accounts) : 'All'
                                                })}
                                                options={this.state.accounts && this.state.accounts.map(item => ({
                                                    value: item.account_id,
                                                    label: item.account_name,	
                                                }))}
                                                onChange={event =>  
                                                    this.setState({
                                                        selectedAccount: event.value,
                                                        changesInFilter: true
                                                    })
                                                }
                                            />
                                        </div>
                                    
                                        <div className={`d-flex`}>
                                            <span className={`mr-2 f12 align-self-center`}>Last</span>
                                            <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+90d' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '+90d' })}>3 Months</span>
                                            <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+60d' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '+60d' })}>2 Month</span>
                                            <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+30d' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '+30d' })}>1 Month</span>
                                            <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+14d' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '+14d' })}>2 Week</span>
                                            <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+7d' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '+7d' })}>1 Week</span>
                                        </div>
                                        
                                        <div className={`ml-2 align-self-center`}>
                                            <div className="btn-group" role="group" aria-label="checks-dash">
                                                <button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-right`} onClick={() => this.onSearch() }>Search</button>
                                                <button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-left`} onClick={() => this.onReset()}><i className='far fa-redo'></i></button>
                                            </div>
                                        </div>	
                                    </div>
                                </div>
							</div>
                        </div>

                        <div className={`box rounded-0 shadow-none bg-transparent`}>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className={`mb-3 p-3 bg-muted`}>
                                        <div className="row pb-2">
                                            <div className="col-sm-6">
                                                <div className="d-flex align-self-center">
                                                    <div className="col-sm-12 m-0 p-0">
                                                        <div className="bg-dark p-3 rounded counts_threads">
                                                            <div className="row mb-2">
                                                                <div className="col-sm-6 d-flex h-100">
                                                                    <div className="d-flex justify-content-between">
                                                                        {this.state.selectedComplianceScore && this.state.selectedComplianceScore !== '' ?
                                                                            <ApexSimpleProgressChart
                                                                                value={this.state.selectedComplianceScore}
                                                                                valueoffsetY={0}
                                                                                height={100}
                                                                                width={100}
                                                                                size={'45%'}
                                                                                colors={['#24A597']}
                                                                                label={'Score'}
                                                                                labeloffsetY={0}
                                                                                strokeWidth={'100%'}
                                                                                strokeColor={'#353C4C'}
                                                                                gridPadding={{left:-20,right:-20,top:-20,bottom:-20}}
                                                                            />
                                                                        : null}
                                                                        {this.state.riskData && Object.entries(this.state.riskData).length ?
                                                                            <div className="ml-2">
                                                                                <ApexDonutChart
                                                                                    graphData={this.state.riskData}
                                                                                    legend={false}
                                                                                    showTotal={true}
                                                                                    showTotalLable={true}
                                                                                    showGraphHeading={false}
                                                                                    height={100}
                                                                                    width={100}
                                                                                    size={'70%'}
                                                                                    gradient={false}
                                                                                    gradientColor={['#A88CCC', '#D88ACF']}
                                                                                    colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']}
                                                                                    className={"transparentTooltip"}
                                                                                />
                                                                            </div>
                                                                        : null}
                                                                        {(this.state.complianceCoverage || this.state.complianceCoverage === 0) ?
                                                                            <div className="ml-2">
                                                                                <ApexSimpleProgressChart
                                                                                    value={Math.ceil(this.state.complianceCoverage)}
                                                                                    valueoffsetY={0}
                                                                                    height={100}
                                                                                    width={100}
                                                                                    size={'45%'}
                                                                                    colors={['#A88CCC']}
                                                                                    label={'Coverage'}
                                                                                    labeloffsetY={0}
                                                                                    strokeWidth={'100%'}
                                                                                    strokeColor={'#353C4C'}
                                                                                    gridPadding={{left:-20,right:-20,top:-20,bottom:-20}}
                                                                                />
                                                                            </div>
                                                                        : null}
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 align-self-center">
                                                                    <h6>Risk Count</h6>
                                                                    <p className="small text-muted">&nbsp;</p>
                                                                    <div className="d-flex flex-column mb-3">
                                                                        <div className="d-flex">
                                                                            <h2 className="mr-3 border-right pr-3 mb-0 align-self-center text-white">
                                                                            {this.state.totalFailCount ? this.state.totalFailCount : ''}</h2>
                                                                            <div>
                                                                                <p className="mb-0 text-primary font-weight-bold">Open</p>
                                                                                <p className="small align-self-end mb-0">Risk spread across your resources</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1 mt-3">
                                                                <div className="col-sm-6">
                                                                    {this.state.dayWiseCounts && Object.keys(this.state.dayWiseCounts).length ?
                                                                        <div className="mt-n2"> 
                                                                            <ApexRiskStackedBarChart
                                                                                graphData={this.state.dayWiseCounts}
                                                                                graphRiskCountSection={true}
                                                                                sparkline={false}
                                                                                yaxis={true}
                                                                                yaxisLabel={true}
                                                                                xaxis={true}
                                                                                xaxisFormat={'string'}
                                                                                xaxisLabel={true}
                                                                                axisLabelColor={'#50586A'}
                                                                                legend={false}
                                                                                stacked={true}
                                                                                height={220}
                                                                                horizontal={false}
                                                                                barHeight={'40%'}
                                                                                barEndShape={'flat'}
                                                                                columnWidth={'25%'}
                                                                                backgroundBarShape={'rounded'}
                                                                                backgroundBarColors={['#333947']}
                                                                                showBackgroundBarColors={true}
                                                                                className={"transparentTooltip"}
                                                                                colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']}
                                                                            />
                                                                        </div>
                                                                    : null}
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <p><b>Top Risks</b></p>
                                                                    {this.state.topThreatsGraphData && Object.entries(this.state.topThreatsGraphData).length ?
                                                                        <div className="mt-n4"> 
                                                                            <ApexBarChart
                                                                                graphData={this.state.topThreatsGraphData ? this.state.topThreatsGraphData : {}}
                                                                                sparkline={false}
                                                                                yaxis={false}
                                                                                yaxisLabel={false}
                                                                                xaxis={true}
                                                                                xaxisFormat={'string'}
                                                                                xaxisLabel={true}
                                                                                axisLabelColor={'#B8BBBE'}
                                                                                paddingLeft={-25}
                                                                                legend={false}
                                                                                stacked={false}
                                                                                height={280}
                                                                                horizontal={true}
                                                                                barHeight={'40%'}
                                                                                barEndShape={'rounded'}
                                                                                columnWidth={'25%'}
                                                                                gradient={true}
                                                                                gradientColor={['#039BE5', '#5F5BA2']}
                                                                                hideTooltipValue={true}
                                                                                backgroundBarShape={'rounded'}
                                                                                backgroundBarColors={['#333947']}
                                                                                showBackgroundBarColors={false}
                                                                                className={"transparentTooltip"}
                                                                            />
                                                                        </div>
                                                                    : null}
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3">
                                                                <div className="col-md-12">
                                                                    <div className='d-flex p-2 bg-dark rounded'>
                                                                        {this.state.transitionTrend && this.state.transitionTrend.details && Object.entries(this.state.transitionTrend.details).length ?
                                                                            Object.entries(this.state.transitionTrend.details).map(([key, value]) => {
                                                                                return(
                                                                                    key !== 'reoccurred' ?
                                                                                        <div className='w-33 pr-2'>
                                                                                            <div className="d-flex">
                                                                                                <h5 className="text-white">
                                                                                                    <small className="mr-2">{capitalizeFirstLetter(key)}</small>
                                                                                                    <b>{value.count ? value.count : 0}</b>
                                                                                                    <span className={`far fa-arrow-${(value.trend === null || value.trend === 'increase') ? 'up  text-success' : 'down text-danger'} mr-2`}></span>
                                                                                                    <small>{value.percent ? value.percent : 0}%</small>
                                                                                                </h5>
                                                                                            </div>
                                                                                            <div className="d-flex">
                                                                                                <div className={`mr-3 risk-critical`}>
                                                                                                    <p className="mb-0 font-weight-bold">{value.severity_wise && value.severity_wise.Critical ? value.severity_wise.Critical : 0}</p>
                                                                                                    <p className="small font-weight-bold align-self-end">Critical
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className="mr-3 risk-high">
                                                                                                    <p className="mb-0 font-weight-bold">{value.severity_wise && value.severity_wise.High ? value.severity_wise.High : 0}</p>
                                                                                                    <p className="small font-weight-bold align-self-end">High</p>
                                                                                                </div>
                                                                                                <div className="mr-3 risk-medium">
                                                                                                    <p className="mb-0 font-weight-bold">{value.severity_wise && value.severity_wise.Medium ? value.severity_wise.Medium : 0}</p>
                                                                                                    <p className="small font-weight-bold align-self-end">Medium
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className="risk-low">
                                                                                                    <p className="mb-0 font-weight-bold">{value.severity_wise && value.severity_wise.Low ? value.severity_wise.Low : 0}</p>
                                                                                                    <p className="small font-weight-bold align-self-end">Low</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            {this.state[key+'_trend_data'] && Object.keys(this.state[key+'_trend_data']).length ?
                                                                                                <div className='w-100 ml-n2 mt-n4 mb-n4'>
                                                                                                    <ApexAreaChart 
                                                                                                        graphData={this.state[key+'_trend_data']}
                                                                                                        name={'New'}
                                                                                                        xaxis={false}
                                                                                                        yaxis={true}
                                                                                                        yaxisLabe={false}
                                                                                                        markerSize={2}
                                                                                                        hideMarkerLength={30}
                                                                                                        gradient={true}
                                                                                                        gradientColor={['#775BA2', '#775BA2']}
                                                                                                        colors={['#775BA2']}
                                                                                                        sparkline={false}
                                                                                                        zoom={false}
                                                                                                        height={90}
                                                                                                        width={'100%'}
                                                                                                        grid={true}
                                                                                                        className={"transparentTooltip"}
                                                                                                    />
                                                                                            </div>
                                                                                            : null}
                                                                                        </div>		
                                                                                    : null
                                                                                )
                                                                            })
                                                                        : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-sm-12 row d-flex"> */}
                                            </div>
                                            <div className={`col-md-6 mb-n4`} ref={this.rightSideTableRef}>
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-sm-6 p-0" ref={this.rightRadialStackedBarWidthRef}>
                                                            {/* <p className="mb-0">By Controls</p> */}
                                                            <p className="mb-0">Distribution</p>
                                                            <p className="small text-muted">Showing distribution of Failed Checks</p>
                                                            {this.state.showRightSideRadialChart ? 
                                                                <StackedCircleBarChart
                                                                    ID={this.state.rightSideGraphId}
                                                                    svgID={this.state.rightSideSvgGraphId}
                                                                    width={this.rightRadialStackedBarWidthRef.current.offsetWidth}
                                                                    data={this.state.rightSideArray}
                                                                    keys={this.state.rightSideKeys}
                                                                    userHighestValue={true}
                                                                    highestValue={this.state.rightSideHighest}
                                                                    hoverData={this.state.rightSideTotalData}
                                                                    totalValue={this.state.rightSideTotal}
                                                                    selectedState={this.state.selectedChartControl ? this.state.selectedChartControl : ''}
                                                                    tableData={(data, State) => 
                                                                        {
                                                                            if(State && this.state.selectedChartControl !== State) {
                                                                                this.setState({ selectedChartControl: State, showRightSideRadialChart: false },
                                                                                    () => {
                                                                                        this.setState({
                                                                                            showRightSideRadialChart: true
                                                                                        },   
                                                                                            () => this.rightSideDataFormat(data, State)    
                                                                                        )
                                                                                    }
                                                                                )
                                                                            }
                                                                        }
                                                                    }
                                                                    legendText={'Controls'}
                                                                    colors={this.state.rightSideGraphColors}					
                                                                />
                                                            : null}
                                                            
                                                            {this.state.multi_pass_fail_series && this.state.multi_pass_fail_series.length ? 
                                                                <div className="">
                                                                    <p className="mb-1">Pass / Fail Trend</p>
                                                                    <div className="mt-n2 transparentTooltip">
                                                                        <Chart type="bar" series={this.state.multi_pass_fail_series} options={this.state.multi_pass_fail_options} height={150}/>
                                                                    </div>
                                                                </div>
                                                            : null}
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <p className="small mb-0 mt-2">
                                                                Status by Controls 
                                                                <p className="text-muted small">
                                                                    {this.state.controlName && this.state.controlName !== "" ? 
                                                                        (this.state.controlName) 
                                                                    : 
                                                                        <span>&nbsp;</span>
                                                                    }
                                                                </p>
                                                            </p>
                                                            <div className="d-flex text-warning">
                                                                <h4 className="mb-0 mr-3">0325</h4>
                                                                <h3 className="mb-0 mr-2"><i className="fad fa-map-marker-alt"></i></h3>
                                                                <h3 className="mb-0 mr-2"><i className="fad fa-map-marker-alt"></i></h3>
                                                                <h3 className="mb-0 mr-2"><i className="fad fa-map-marker-alt"></i></h3>
                                                                <div className="flex-row ml-1">
                                                                    <p className="mb-0 text-nowrap small">2032, 2031</p>
                                                                    <p className="mb-0 text-nowrap small">2032, 2031</p>
                                                                </div>
                                                            </div>
                                                            <div className="mt-3 radialTableSection" style={{minHeight: "300px"}}>
                                                                {this.state.rightSideFilteredTableData && this.state.rightSideFilteredTableData.length ?
                                                                    <div className="" style={{minHeight: "260px"}}>
                                                                        {this.state.rightSideFilteredTableData.slice(this.state.rightSideStart, (this.state.rightSideStart + this.state.rightSidePerPage)).map((item, i) => {
                                                                            return(
                                                                                <div className="mt-2 col-12 p-0 bg-dark">
                                                                                    <div className="count border-info d-flex p-2">
                                                                                        <h5 className="col-sm-2 p-0 m-0">{item.failCount ? item.failCount : 0}</h5>
                                                                                        <div className="col-sm-10 d-flex" id={'description_'+i}>
                                                                                            {item.control && item.control !== '' ?
                                                                                                <p className="text-nowrap mb-0 align-self-end ml-2 small cursorPointer">{item.control+(' : ')}</p>
                                                                                            : null}
                                                                                            <p className="m-0 align-self-end ml-1 small hiddenEllipses cursorPointer">{item.description ? item.description : '' }</p>
                                                                                        </div>
                                                                                        {item.description && item.description !== '' ?
                                                                                            <UncontrolledPopover className="dark-theme-popover" trigger="hover" placement="top" target={`description_`+i}>
                                                                                                <PopoverHeader>
                                                                                                    <label className="mb-1 text-white font-weight-bold"> {item.control}</label>
                                                                                                </PopoverHeader>
                                                                                                <PopoverBody>
                                                                                                    {item.description ? 
                                                                                                        <div className="mt-1">
                                                                                                            <p className="mb-1 f14 text-white font-weight-bold">Description:<span
                                                                                                            className="f12 ml-2">{item.description}</span></p>
                                                                                                        </div>
                                                                                                    : null}
                                                                                                </PopoverBody>
                                                                                            </UncontrolledPopover>
                                                                                        : null}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                : null}
                                                                {this.state.rightSidePageCount > 1 ?
                                                                    <div className="pagination mt-3 justify-content-center d-flex">
                                                                        <span className={`${this.state.disableLeftAction ? 'disabled text-muted' : ''}`} onClick={(e) => {e.preventDefault(); this.navigatePage('start', this.state.currentPage)}}><i className="fas fa-chevron-double-left"></i></span>
                                                                        <span className={`${this.state.disableLeftAction ? 'disabled text-muted' : ''}`} onClick={(e) => {e.preventDefault(); this.navigatePage('previous', this.state.currentPage)}}><i className="fas fa-chevron-left"></i></span>

                                                                        {this.state.rightSidePageCountArray.slice(this.state.ritPageStart, this.state.ritPageEnd).map((pag, index) => {
                                                                            return(
                                                                                <span  className={`${this.state.currentPage === pag ? 'active' : ''}`} onClick={(e) => {e.preventDefault(); this.navigatePage('page', (index+1))}}>{pag}</span>
                                                                            )
                                                                        })}
                                                                        <span className={`${this.state.disableRightAction ? 'disabled text-muted' : ''}`} onClick={(e) => {e.preventDefault(); this.navigatePage('next', this.state.currentPage)}}><i className="fas fa-chevron-right"></i></span>
                                                                        <span className={`${this.state.disableRightAction ? 'disabled text-muted' : ''}`} onClick={(e) => {e.preventDefault(); this.navigatePage('end', this.state.currentPage)}}><i className="fas fa-chevron-double-right"></i></span>
                                                                    </div>
                                                                : null}
                                                            </div>
                                                            <div className="pt-5" style={{minHeight: "240px"}} ref={this.rightHorizontalLimitLollipopRef}>
                                                                {this.state.showRightSideLollipopChart ? 
                                                                    <React.Fragment>
                                                                    <div className={`d-flex mt-2`}>
                                                                        <p className="small m-0 align-self-end small mr-2">Top</p>
                                                                        <div className={`transparentSelectBorderBottom`}>
                                                                            <select className="f12 form-control" 
                                                                                onChange={(event) => {
                                                                                    this.setState({ selectedTopFailure: event.target.value, showEqualizerGraph: false },
                                                                                        () => this.onChangeTopFailures()    
                                                                                    )
                                                                                }}
                                                                                value={this.state.selectedTopFailure}                            
                                                                                >
                                                                                <option value='Resources'>Resources</option>
                                                                                <option value='Roles'>Roles</option>
                                                                                <option value='Services'>Services</option>
                                                                                <option value='Environment'>Environment</option>
                                                                                <option value='Application'>Application</option>
                                                                                <option value='Cost Centre'>Cost Centre</option>
                                                                                <option value='Events'>Events</option>
                                                                            </select>
                                                                        </div>
                                                                        <p className="m-0 align-self-end small ml-2">which caused most failures</p>
                                                                    </div>
                                                                    {this.state.showEqualizerGraph && this.state.horizontalGraphData.data.length ? 
                                                                        <HorizontalLimitLollipopChart
                                                                            width={this.rightHorizontalLimitLollipopRef.current.offsetWidth - 20}
                                                                            height={170}
                                                                            ID={'leftSideLollipop'}
                                                                            graphData={this.state.horizontalGraphData}
                                                                            showEndPointCircle={true}
                                                                            colors={['#039BE5', '#039BE5', '#FFD200', '#FFD200']}
                                                                        />
                                                                    : this.state.showEqualizerGraph && !this.state.horizontalGraphData.data.length ?
                                                                        <div className='d-flex justify-content-center m-4'>
                                                                            <p  className="m-0 text-white">No Failure in {this.state.selectedTopFailure}</p>
                                                                        </div>
                                                                    :
                                                                        <div className='d-flex justify-content-center m-4'>
                                                                            <Spinner color='white' size='lg' />
                                                                        </div>
                                                                    }
                                                                    </React.Fragment>
                                                                : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>     
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.complianceControlDetails ?
                                        <div className="mt-3">
                                            <div className="col-sm-12">
                                                <div className="bg-dark3 p-2 rounded">
                                                    <div className="col-sm-12 ">
                                                        <div className="row">
                                                            <div className="col-sm-8">
                                                                <p className="font-weight-bold text-white mb-0">Status by controls
                                                                    <Link
                                                                        className='ml-2 small text-decoration-none'
                                                                        to={{
                                                                            pathname: URL_PATH.CHECKS_SEARCH,
                                                                            state:{ 
                                                                                selectedProvider: this.state.selectedProvider, 
                                                                                selectedDuration: this.state.selectedDuration, 
                                                                                selectedAccount: this.state.selectedAccount, 
                                                                                selectedAccountName: this.state.selectedAccount ? getAccountNameFromId(this.state.selectedAccount, this.state.accounts) : '', 
                                                                                selectedComplicance: this.state.selectedComplicance, 
                                                                                from: this.props.location
                                                                            }
                                                                        }}
                                                                    >
                                                                        View All Issues
                                                                    </Link>
                                                                </p>
                                                            <p className="small f12 mb-2">Find the category wise listing of identified vulnerabilities on <b>{this.state.selectedAccount ? getAccountNameFromId(this.state.selectedAccount, this.state.accounts) : ''}</b> account for the audit policy <b>{this.state.selectedComplicance}</b></p>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <SearchControls
                                                                    data={this.state.controlsTotalArray && this.state.controlsTotalArray}
                                                                    // filteredData={filteredArray => { this.setState({ filteredArray }) }}
                                                                    filteredData={filteredArray => {
                                                                        if (filteredArray.length) {
                                                                            this.setState({ filteredArray, selectedTableRow: filteredArray[0] })
                                                                        } else {
                                                                            this.setState({ filteredArray: this.state.controlsTotalArray })
                                                                        }
                                                                    }}
                                                                    className={'dlpSerachControlsPlaceholder bg-muted'}
                                                                    inputClassName={'text-dark bg-transparent'}
                                                                    // searchIconColor={'text-white4'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <table className="table">
                                                        <tbody>
                                                            {this.state.filteredArray && this.state.filteredArray.map((item,i) =>{
                                                                let found = 0
                                                                return(
                                                                    <tr key={i}>
                                                                        <td className='align-middle'>
                                                                            <p className="mb-2 fw500 text-white"><span className="badge-square bg-muted p-2 text-wrap text-white mr-1 f13">{splitterFirstPart(item.control, ':')}</span> {splitterSecondPart(item.control, ':') +' '+item.description}</p>
                                                                            <div className='align-self-center float-left'>
                                                                                <span className="far fa-chevron-left text-gray3 mr-2" onClick={() => this.envSlideLeft(i)}></span>
                                                                            </div>
                                                                            <div className="col-sm-11 mr-2 d-flex flex-nowrap overflow-hidden float-left maxWidth700" ref={ref => this.accordionContent[i] = ref}>
                                                                                {item.sub_control && item.sub_control.length ?
                                                                                    item.sub_control.map((subCtrl, x)=>{
                                                                                        return (
                                                                                        <div ref={ref => this.accordionChildContent[x] = ref} key={x}  className={subCtrl.bgColor +" count pl-2 pr-3"}>
                                                                                            <p className="text-white f12 mb-0 text-nowrap cursorPointer" id={`sc_`+i+`_`+x}>{subCtrl.control}</p>
                                                                                            <UncontrolledPopover className="dark-theme-popover" trigger="hover" placement="top" target={`sc_`+i+`_`+x}>
                                                                                                <PopoverHeader>
                                                                                                    <label className="mb-1 text-white font-weight-bold"> {subCtrl.control}</label>
                                                                                                </PopoverHeader>
                                                                                                <PopoverBody>
                                                                                                    <div className="mt-1">
                                                                                                        <p className="mb-1 f12 text-white">{subCtrl.description}</p>
                                                                                                        <div className="d-flex">
                                                                                                            <div className="d-flex w-33">
                                                                                                                <p className="mb-0 text-left text-white">Pass: <span className="font-weight-bold mb-0 text-warning text-left">{subCtrl.passCount}</span></p>
                                                                                                            </div>
                                                                                                            <div className="d-flex w-33">
                                                                                                                <p className="mb-0 text-left text-white">Fail: <span className="font-weight-bold mb-0 text-dark-theme-orange text-left">{subCtrl.failCount}</span></p>
                                                                                                            </div>
                                                                                                            <div className="d-flex w-33">
                                                                                                                <p className="mb-0 text-left text-white">NA: <span className="font-weight-bold mb-0 text-info text-left">{subCtrl.naCount}</span></p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </PopoverBody>
                                                                                            </UncontrolledPopover>
                                                                                        </div>
                                                                                        )
                                                                                    })
                                                                                
                                                                                :
                                                                                    item.minor_control && item.minor_control.length && item.minor_control.map((minCtrl, x)=>{
                                                                                        return (
                                                                                        <div ref={ref => this.accordionChildContent[x] = ref} key={x} className={minCtrl.bgColor +" count pl-2 pr-3"}>                       
                                                                                            <p className="text-white f12 mb-0 text-nowrap cursorPointer" id={`mc_`+i+`_`+x}>{minCtrl.control}</p>
                                                                                            <UncontrolledPopover className="dark-theme-popover" trigger="hover" placement="top" target={`mc_`+i+`_`+x}>
                                                                                                <PopoverHeader>
                                                                                                    <label className="mb-1 text-white font-weight-bold"> {minCtrl.control}</label>
                                                                                                </PopoverHeader>
                                                                                                <PopoverBody>
                                                                                                    <div className="mt-1">
                                                                                                        <p className="mb-1 f12 text-white">{minCtrl.description}</p>
                                                                                                        <div className="d-flex">
                                                                                                            <div className="d-flex w-33">
                                                                                                                <p className="mb-0 text-left text-white">Pass: <span className="font-weight-bold mb-0 text-warning text-left">{minCtrl.passCount}</span></p>
                                                                                                            </div>
                                                                                                            <div className="d-flex w-33">
                                                                                                                <p className="mb-0 text-left text-white">Fail: <span className="font-weight-bold mb-0 text-dark-theme-orange text-left">{minCtrl.failCount}</span></p>
                                                                                                            </div>
                                                                                                            <div className="d-flex w-33">
                                                                                                                <p className="mb-0 text-left text-white">NA: <span className="font-weight-bold mb-0 text-info text-left">{minCtrl.naCount}</span></p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </PopoverBody>
                                                                                            </UncontrolledPopover>  
                                                                                        </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                            <div className='align-self-center'>
                                                                                <span className='far fa-chevron-right text-gray3 cursorPointer ml-1'  onClick={() => this.envSlideItemRight(i, item.control)}></span>                                                                        
                                                                            </div>
                                                                        </td>
                                                                        <td className='align-middle'>
                                                                            <h5 className="font-weight-bold mb-0 text-white">{item.count}</h5>
                                                                            <p className="font-weight-bold f12 mb-0 text-white">Checks</p>
                                                                        </td>
                                                                        
                                                                        <td className='align-middle'>
                                                                            <div className="box bg-muted justify-content-between d-flex p-2 shadow-none">
                                                                            
                                                                            {this.state.majorControlCounts && this.state.majorControlCounts.map((controlCounts,z) => {
                                                                                if(controlCounts && controlCounts.authorityMajorControl === item.control) {
                                                                                    found = 1
                                                                                    return (
                                                                                    <React.Fragment>
                                                                                        <div className="mr-2">
                                                                                            <h5 className="font-weight-bold mb-0 text-warning text-center">{numberFormater(controlCounts.pass_count)}</h5>
                                                                                            <p className="font-weight-bold mb-0 text-center f12 text-white">Pass</p>
                                                                                        </div>
                                                                                        <div className="mr-2">
                                                                                            <h5 className="font-weight-bold mb-0 text-dark-theme-orange text-center">{numberFormater(controlCounts.fail_count)}</h5>
                                                                                            <p className="font-weight-bold mb-0 text-center f12 text-white">Fail</p>
                                                                                        </div>

                                                                                        <div>
                                                                                        <h5 className="font-weight-bold mb-0 text-info text-center">{numberFormater(controlCounts.NA_count)}</h5>
                                                                                        <p className="font-weight-bold mb-0 text-center f12 text-white">NA</p>
                                                                                        </div>
                                                                                        
                                                                                    </React.Fragment>
                                                                                    )
                                                                                }
                                                                                if(z === (this.state.majorControlCounts.length - 1) && found === 0) {
                                                                                    return (
                                                                                    <React.Fragment>
                                                                                        <div className="mr-2">
                                                                                            <h5 className="font-weight-bold mb-0 text-warning text-center">0</h5>
                                                                                            <p className="font-weight-bold mb-0 text-center f12 text-white">Pass</p>
                                                                                        </div>
                                                                                        <div className="mr-2">
                                                                                            <h5 className="font-weight-bold mb-0 text-dark-theme-orange text-center">0</h5>
                                                                                            <p className="font-weight-bold mb-0 text-center f12 text-white">Fail</p>
                                                                                        </div>

                                                                                        <div>
                                                                                        <h5 className="font-weight-bold mb-0 text-info text-center">0</h5>
                                                                                        <p className="font-weight-bold mb-0 text-center f12 text-white">NA</p>
                                                                                        </div>
                                                                                        
                                                                                    </React.Fragment>
                                                                                    )
                                                                                    
                                                                                }
                                                                            })}
                                                                                
                                                                            {/* <div>
                                                                                    {this.state.complianceSubControlDetails ? 
                                                                                        <h5 className="font-weight-bold mb-0 text-info text-center">{this.state.complianceSubControlDetails[item.control]?this.state.complianceSubControlDetails[item.control].not_applicable:0}</h5>
                                                                                    :   
                                                                                        <h5 className="font-weight-bold mb-0 text-info text-center">{this.state.complianceMinorControlDetails[item.control]?this.state.complianceMinorControlDetails[item.control].not_applicable:0}</h5>
                                                                                        
                                                                                    }
                                                                                    <p className="font-weight-bold mb-0 text-center f12">NA</p>
                                                                                </div> */}
                                                                            </div>
                                                                        </td>
                                                                        <td className='align-middle text-right'>
                                                                            <div className="dflex">
                                                                                <Link
                                                                                    className='text-decoration-none'
                                                                                    to={{
                                                                                        pathname: URL_PATH.CHECKS_SEARCH,
                                                                                        state:{ 
                                                                                            selectedProvider: this.state.selectedProvider, 
                                                                                            selectedDuration: this.state.selectedDuration, 
                                                                                            selectedAccount: this.state.selectedAccount,
                                                                                            selectedAccountName: this.state.selectedAccount ? getAccountNameFromId(this.state.selectedAccount, this.state.accounts) : '',
                                                                                            authorityMajorControl: item.control, 
                                                                                            selectedComplicance: this.state.selectedComplicance, 
                                                                                            from: this.props.location 
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    View
                                                                                </Link>
                                                                                <span className="link cursorPointer" onClick={() => this.closeIssue(item.control, item.description, item.sub_control.length ? item.sub_control : item.minor_control, item.sub_control.length ? 'authoritySubControl' : 'authorityMinorControl')}> <span className="text-white"> / </span> Close</span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })                                                
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    :''}
                                </div>
                            </div>
                        </div>
                        <React.Fragment>
                            <Modal className="closeIssue" backdrop="static" isOpen={this.state.modalSignup} toggle={this.toggleCloseModal}  onClick={ (event) => { this.handleClickOutside(event) } }>
                                <ModalHeader toggle={this.toggleCloseModal}>  
                                    {this.state.modalHeader} 
                                </ModalHeader>
                                <ModalBody>
                                {this.state.controlsList.length ?
                                    <Form>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <div className={`innerMultiDrop modalPopup ${this.state.controlsList.length > 10 ? '' : 'removeDropdownSearchBar' } `} ref={this.controlRef}>
                                                        <ReactMultiSelectCheckboxes
                                                            placeholderButtonLabel={this.state.closeMajorControl}
                                                            menuIsOpen = {this.state.isControlOpen}
                                                            value={this.state.selectedControls.map(value => ({
                                                                value: value,
                                                                label: value
                                                            }))}
                                                            onChange={arr => {
                                                            this.handleMultiSelectChange('selectedControls', arr ? arr : []) }}
                                                            options={this.state.controlsList.map(ctrl => ({
                                                                value: ctrl.control,
                                                                label: ctrl.control
                                                            }))}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>  
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label>Status</Label>
                                                    <Input
                                                        disabled={true}
                                                        type='select'
                                                        className="form-control-small"
                                                        value={this.state.closeStatus}
                                                        onChange={event => {
                                                            this.setState(
                                                                {
                                                                    closeStatus: event.target.value
                                                                }
                                                            )
                                                        }}
                                                    >
                                                        <option value='resolved'>Resolved</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label>Status Type</Label>
                                                    <Input
                                                        type='select'
                                                        className="form-control-small"
                                                        value={this.state.closeStatusType}
                                                        onChange={event => {
                                                            this.setState(
                                                                {
                                                                    closeStatusType: event.target.value
                                                                }
                                                            )
                                                        }}
                                                    >
                                                        <option value=''>Select</option>
                                                        <option value='resolved_verified'>Resolved & Verified</option>
                                                        <option value='not_issue'>Not an Issue</option>
                                                        <option value='duplicate'>Duplicate</option>
                                                    </Input>
                                                    {this.state.hasErrorInRuleForm && this.state.selectedStatusType === '' ? (
                                                        <p className='text-danger'>Status Type is required.</p>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label>Reason</Label>
                                                    <textarea className="w-100 borderGray" value={this.state.closeReason} onChange={event => this.setState({ closeReason: event.target.value })} rows="2" placeholder='Reason for closing'>
                                                        
                                                    </textarea>
                                                    {this.state.hasErrorInRuleForm && this.state.closeReason === '' ? (
                                                        <p className='text-danger'>Reason is required.</p>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label>Proof <span className={`f11 resetBtn ${this.state.closeAttachment !== '' ? 'cursorPointer' : 'displayNone' }`} onClick={()=> this.setState({ closeAttachmentKey: true, closeAttachment: '', hasErrorInRuleForm: false })}>Clear </span></Label>
                                                    <Input
                                                        type='file'
                                                        //accept="image/jpg, image/png"
                                                        className="form-control-sm"
                                                        id="closeAttachmentId"
                                                        key={this.state.closeAttachmentKey &&	this.state.closeAttachment}
                                                        onChange={event => { this.filterChangedHandler(event) }}
                                                    />
                                                    {this.state.hasErrorInRuleForm && this.state.closeAttachment === '' ? (
                                                        <p className='text-danger f11'>{this.state.attachmentMessage}</p>
                                                    ) : (
                                                        ''
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            {/* <Col md={2}>
                                                    {console.log(this.state.closeAttachment)}
                                                <button className={`f11 rfes 'info cursorPointer' : 'displayNone'} `} onClick={()=> this.setState({ closeAttachmentKey: true, closeAttachment: '' })}>Clear </button>
                                            </Col> */}
                                        </Row>
                                    </Form>
                                :
                                    <p className={`m-0 p-0 align-self-center `}>No Failed assets in {this.state.modalHeader}</p> 
                                }
                                </ModalBody>
                                <ModalFooter className="col-sm-12 m-0 p-0">
                                    <div className="col-sm-5 m-0 p-0">
                                        {this.state.showMessage ?
                                            <p className={`m-0 p-0 align-self-center ${this.state.responseStatus ? 'text-success' : 'text-danger' }`}>{this.state.responseMessage}</p> 
                                        : ''}
                                    </div>
                                    <div className={`col-sm-6 float-right text-right ${this.state.disableOnSubmit ? 'disabled' : ''}`}>
                                        <button className='btn btn-sm btn-light mr-3' onClick={this.toggleCloseModal}>
                                            Back
                                        </button>
                                        <button
                                            className={`btn btn-sm btn-primary ${this.state.controlsList.length ? '' : 'displayNone'} ${this.state.closeLoading ? 'disabled' : ''}`}
                                            onClick={() => this.onModalSubmit()}
                                            >
                                            {this.state.closeLoading ? (
                                                <React.Fragment>
                                                    {'submitting'}
                                                    <Spinner className='text-center ml-2' color='light' size='sm' />{' '}
                                                </React.Fragment>
                                            ) : 'Submit'
                                            }
                                        </button>
                                    </div>
                                </ModalFooter>
                            </Modal>
                            
                        </React.Fragment>
                    </div>
                </div>
            </div>        
		)
	}
}

/**
 * Type of the props used in the co mponent
 */
ComplianceDashboard.propTypes = {
	listAllProviders: PropTypes.func,
	listAllAccounts: PropTypes.func,   
    getScore: PropTypes.func,
    getPassFailDetails: PropTypes.func,
    getDayWiseCount: PropTypes.func,    
    getComplianceControlDetails: PropTypes.func,
    getComplianceSubControlDetails: PropTypes.func,
    getComplianceMinorControlDetails: PropTypes.func,
    getTotalCount: PropTypes.func,
    getStatusCount:PropTypes.func,
    getTransitionTrend: PropTypes.func
    
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		providers: state.filters.providers,
		accounts: state.filters.accounts,
    }
    
}

export default AppWrapper(ComplianceDashboard, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
    getComplianceByEvent,
    getComplianceByRole,
    getComplianceByTag,    
    getScore,
    getPassFailDetails,
    getDayWiseCount,    
    getComplianceControlDetails,
    getComplianceSubControlDetails,
    getComplianceMinorControlDetails,
    getTotalCount,
    getStatusCount,
    getTransitionTrend,
    resolveIssue
})