/*************************************************
 * Tvastar
 * @exports
 * @file governanceAction.js
 * @author Rajasekar // on 27/02/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import { API, Auth } from 'aws-amplify'
import axios from 'axios'

import {
    DLPTHREATENDPOINT,
	DLP_THREAT_END_POINT,
	DLP_UEBA_GET_DAY_WISE_COUNT,
	DLP_UEBA_GET_COMPLIANCE_CONTROL_DETAILS,
	DLP_UEBA_GET_UEBA_DETAILS,
	DLP_UEBA_GET_UEBA_COVERAGE_AND_TOP_STATS,
	DLP_UEBA_DOWNLOAD_CHECKS_RESULTS,
	DLP_UEBA_GET_TRANSITION_TREND,
} from '../../config'
import { myLog } from '../../utils/utility'

/**
 * Action to get day wise count
 * @param {Object} body
 * @param {Function} callback
*/
export const getDayWiseCount = (body, callback) => {
	return async () => {
		try {
			const totalCount = await API.post(DLPTHREATENDPOINT, DLP_UEBA_GET_DAY_WISE_COUNT, { body })
			callback(true, totalCount)
		} catch (error) {
			callback(false)
			myLog('daywiseCount', error)
		}
	}
}

/**
 * Action to get compliance Control details
 * @param {Object} body
 * @param {Function} callback
*/
export const getComplianceControlDetails = (body, callback) => {
	return async () => {
		try {
			const getComEvent = await API.post(DLPTHREATENDPOINT, DLP_UEBA_GET_COMPLIANCE_CONTROL_DETAILS, { body })

			let result = null
			if(getComEvent.length > 0){
				 result = getComEvent[0].controls;
			}
			callback(true, result)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to get threat details
 * @param {Object} body
 * @param {Function} callback
*/
export const getThreatDetails = (body, callback) => {
	return async () => {
		try {
			const results = await API.post(DLPTHREATENDPOINT, DLP_UEBA_GET_UEBA_DETAILS, { body })		
			callback(true, results)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to get threat coverage
 * @param {Object} body
 * @param {Function} callback
*/
export const getThreatCoverageAndTopStats = (body, callback) => {
	return async () => {
		try {
			const results = await API.post(DLPTHREATENDPOINT, DLP_UEBA_GET_UEBA_COVERAGE_AND_TOP_STATS, { body })		
			callback(true, results)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to download chedks results
 * @param {Object} body
 * @param {Function} callback
*/
export const downloadChecksResults = (body, callback) => {
	return async dispatch => {
		try {
			const apiUrl = DLP_THREAT_END_POINT + DLP_UEBA_DOWNLOAD_CHECKS_RESULTS;

			let contentType = 'application/octet-stream'
			if(body.response_format === 'json') {
				contentType = 'application/json'
			} 
			
			const download = await axios.post(apiUrl, body, {
				headers: {
					'Content-Type': contentType,
					Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}`
				}
			})

			let data = download.data;
			if(body.response_format === 'json') {
				data = JSON.stringify(download.data);
			}
			const url = window.URL.createObjectURL(new Blob([data])); 
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'report.'+body.response_format); //or any other extension
			document.body.appendChild(link);
			link.click();
			callback(true, download)
		} catch (error) {
			let respone = {}
			respone.message = error.response.data.message
			respone.status = error.response.data.status
			callback(false, respone)
		}
	}
}

/** Action to get Transition Trend
* @param {Object} body
* @param {Function} callback
*/
export const getTransitionTrend = (body, callback) => {
	return async () => {
		try {
			const results = await API.post(DLPTHREATENDPOINT, DLP_UEBA_GET_TRANSITION_TREND, { body })
			callback(true, results)
		} catch (error) {
			callback(false)
		}
	}
}