/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file SummaryLeftSection.js
 * @author Prakash // on 08/03/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

class SummaryLeftSection extends Component {
    
    constructor(props) {

        super(props)
        
        this.state = {
            showLoading: false,
        }
    }

    // handleDateTimeChange  = this.handleDateTimeChange.bind(this)

	componentDidMount = () => {}

	render() {
		return (
            <div className='col-md-12'>
                <div className='tab-content p-0' id='myTabContent'>                                        
                    <div className='tab-pane fade show active'>
                        <p className="mb-0 f16 text-white">Summary</p>
                        <p className="mb-0 f12">Showing the sumamry of the case</p>
                        <div className="d-flex justify-content-between">
                            {/* <p className="mb-0 f12">Showing the remediation options</p> */}
                        </div>
                        <div className="container-fluid mt-3">
                            <div className={`row ${!this.state.showLoading ? '' : 'disabled'}`}>
                                <div className="col-12 d-flex flex-nowrap overflow-hidden rounded bg-dark ">
                                    <div class="p-3">
                                        {this.props.caseDetails && this.props.caseDetails.summary ?
                                            <div className="row mb-2">
                                                {this.props.caseDetails.summary.split("\n").map((str, index) => {
                                                    return (
                                                        // index === 0 ?
                                                            <p class="mb-1 f16 text-white d-flex w-100">{str}</p>
                                                        // : 
                                                        //     <p>{str}</p>
                                                    )
                                                })}                                                            
                                            </div>
                                        : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                
                </div>
            </div>
		)
	}
}
 
/**
 * Type of the props used in the component
 */
SummaryLeftSection.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('----------------------',state)
	return {
        caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : [],
    }
}

export default connect(mapStateToProps, {})(withRouter(SummaryLeftSection))