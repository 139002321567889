
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ActionSection.js
 * @author Prakash // on 08/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
// import PropTypes from 'prop-types'

import { Spinner, UncontrolledTooltip } from 'reactstrap'
import { setAutomationPropsDetails } from '../../../actions/commonAction'
import RunCloudwatchInsightsQuery from '../RunCloudwatchInsightsQuery'
import ApplyGrokAndGroupBy from '../ApplyGrokAndGroupBy'
import SlowQueryAnalysis from '../SlowQueryAnalysis'
import Notify from '../Notify'
import CallAgent from '../CallAgent'
import CallAgentRdsMysql from '../CallAgentRdsMysql'
import JoinOutput from '../JoinOutput'
import RunDiagnostics from '../RunDiagnostics'

import DynamicSection from './DynamicSection'
import ReplaceIPAdress from './ReplaceIPAdress'
import RevokeSecurityGroupIngress from './RevokeSecurityGroupIngress'


class ActionSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeSection: this.props.activeSection,
            showAction: this.props.showAction,
            latestTab: this.props.latestTab,
            selectedActionTabs: this.props.selectedActionTabs
        }
    }

    componentDidMount = () => {
        if(this.state.showAction) {
            this.props.scrollToSection(this.state.activeSection)
        }
        if(this.props.automationPropsDetails) {
            let obj = this.props.automationPropsDetails
            let actionsExist = 0
            if(obj.actions && obj.actions.length) {
                let exist = obj.actions.filter(e => e.section === this.state.activeSection)
                if(exist.length) {
                    actionsExist = 1
                    this.setState({ 
                        selectedAction: exist[0].action ? exist[0].action : "",
                        action_name: exist[0].action_name,
                        action_description: exist[0].description,
                        name: exist[0].name
                    })
                }
            }
            if(!actionsExist) {
                this.formInputProps(this.state.activeSection, "section", this.state.activeSection)
            }
        }

    }

    onActionSelect = (item) => {
        this.setState({ 
            selectedAction: item.action ? item.action : "", 
            action_name: item.action_name,
            action_description: item.description,
            showActionResults: false 
        },
            () => {
                this.formInputProps(this.state.activeSection, "action", this.state.selectedAction)
            }
        )

    }

    formInputProps = (section, field, value) => {
        let hasError = false
        let errorMessage = ""
        let obj = this.props.automationPropsDetails
        let actions = obj.actions ? obj.actions : []
        if((!actions.length || !actions.filter((e => e.section === section)).length) && field === "section") {
            let newObject = {}
            newObject[field] = value
            actions.push(newObject)
        } else if(field === "save_automation") {
            obj[field] = value
        } else if(field !== "section") {
            let objIndex = actions.findIndex((e => e.section === section))
            let nameArray = actions.filter(e => e.section !== section).map(o => o.name)

            if(nameArray.length && nameArray.includes(this.state.action_name)) {
                hasError = true
                errorMessage = "Name already exist"
            }
            if(field === "action") {
                actions[objIndex]["action"] = this.state.selectedAction
                actions[objIndex]["action_name"] = this.state.action_name
                actions[objIndex]["description"] = this.state.action_description
            } else {
                actions[objIndex][field] = value
            }
        }
        obj.actions = actions
        this.props.setAutomationPropsDetails(obj)
        this.setState({ hasError, errorMessage },
            () => {
                this.checkActionStatus()
            }    
        )        
    }

    checkActionStatus = () => {
        if(!this.state.hasError && this.state.childData && this.state.selectedAction && this.state.name && this.state.name !== "") {
            this.setState({ actionDataCompleted: true })
        } else {
            this.setState({ actionDataCompleted: false })
        }
    }

    enableSection = () => {
        // this.props.actionName(this.state.name ? this.state.name : "")
        // if(!this.state.showAction){
        //     this.props.selectAction(this.state.activeSection)
        // }
        let selectedActionTabs = this.state.selectedActionTabs ? this.state.selectedActionTabs : []
        if(selectedActionTabs.filter(e => e === this.props.activeSection).length) {
            selectedActionTabs = selectedActionTabs.filter(e => e !== this.props.activeSection)
        } else {
            selectedActionTabs.push(this.props.activeSection)
        }

        this.setState({ selectedActionTabs })
        this.props.selectAction(selectedActionTabs)

    }

    filterActions = (type) => {
        let filterActionResult = this.props.listAutomationActionsProps
        if(filterActionResult.length) {
            filterActionResult = filterActionResult.filter(e => e.action.toLowerCase() === type)
        }
        this.setState({ filterActionResult })
    }

    render() {
        return (
            <div className={"class_"+this.state.activeSection}>
                <div className={`section ${this.state.selectedActionTabs.includes(this.state.activeSection) ? "active" : ""} ${this.props.identifierDetail && this.props.identifierDetail.resolution_status_info && this.props.identifierDetail.resolution_status_info.status && this.props.identifierDetail.resolution_status_info.status === "resolved" ? "cursorAuto" : ""}`}>
                    <div className={`sectionHeader ${this.state.selectedActionTabs.includes(this.state.activeSection) ? "" : "rounded"}`} onClick={() => this.enableSection()}>
                        <div className="d-flex">
                            <div className="">
                                {!this.state.selectedActionTabs.includes(this.state.activeSection) && !this.state.actionDataCompleted ?
                                    <i className="fas fa-exclamation-circle icon-stack-2x"></i>
                                : null}
                                <i className={`fas fa-radiation f30 p-2 border-gray3 align-self-center rounded ${this.state.selectedActionTabs.includes(this.state.activeSection) ? "text-white" : ""}`}></i>
                            </div>
                            <div className="columnHeading ml-3 align-self-center">
                                <div className="d-flex">
                                    <span className="title">{this.props.tabCount}. Action</span>
                                    {!this.state.selectedActionTabs.includes(this.state.activeSection) ?
                                        <div clasName="d-flex text-white">
                                            {this.state.action_name ?
                                                <span className="ml-2 text-info">Type: <span className="text-white">{this.state.action_name}</span></span>
                                            : null}
                                            {this.state.name ?
                                                <span className="ml-2 text-info">Name: <span className="text-white">{this.state.name}</span></span>
                                            : null}
                                        </div>
                                    : null}
                                </div>
                                <span className="description">Details about action to be perfomed</span>
                            </div>
                        </div>
                    </div>
                    <div className={`sectionBody ${this.state.selectedActionTabs.includes(this.state.activeSection) ? "" : "displayNone"}`}>
                        <div className="mt-3">
                            {this.state.selectedAction && this.state.selectedAction !== "" ?
                                <div className="d-flex">
                                    <div className="w-50">
                                        <label className="mb-2 w-100">Action Type</label>
                                        <label className="text-white">{this.state.action_name}</label>
                                    </div>
                                    <div className="pl-3 w-50">
                                        <label className="mb-2 w-100">Action Name</label>
                                        <label className="text-white">{this.state.name}</label>
                                    </div>
                                </div>
                            : null}
                            {this.state.selectedAction === "RunCloudwatchInsightsQuery" ?
                                <RunCloudwatchInsightsQuery 
                                    activeSection={this.state.activeSection}
                                    actionCompleted={(bool) => this.setState({ childData: bool }, () => this.checkActionStatus())}
                                />
                            : this.state.selectedAction === "ApplyGrokAndGroupBy" ?
                                <ApplyGrokAndGroupBy 
                                    activeSection={this.state.activeSection}
                                    actionCompleted={(bool) => this.setState({ childData: bool }, () => this.checkActionStatus())}
                                />
                            : this.state.selectedAction === "SlowQueryAnalysis" ?
                                <SlowQueryAnalysis 
                                    activeSection={this.state.activeSection}
                                    actionCompleted={(bool) => this.setState({ childData: bool }, () => this.checkActionStatus())}
                                />
                            : this.state.selectedAction === "Notify" ?
                                <Notify
                                    activeSection={this.state.activeSection}
                                    actionCompleted={(bool) => this.setState({ childData: bool }, () => this.checkActionStatus())}
                                />
                            : (this.state.action_name === "Run Commands in Redis Cluster" || this.state.action_name === "Run commands in Redis") ?
                                <CallAgent
                                    activeSection={this.state.activeSection}
                                    actionCompleted={(bool) => this.setState({ childData: bool }, () => this.checkActionStatus())}
                                />
                            : this.state.action_name === "Run RDS MySQL Queries" ?
                                <CallAgentRdsMysql
                                    activeSection={this.state.activeSection}
                                    actionCompleted={(bool) => this.setState({ childData: bool }, () => this.checkActionStatus())}
                                />
                            : this.state.selectedAction === "Join" ?
                                <JoinOutput
                                    activeSection={this.state.activeSection}
                                    actionCompleted={(bool) => this.setState({ childData: bool }, () => this.checkActionStatus())}
                                />
                            : this.state.selectedAction === "RunDiagnostics" ?
                                <RunDiagnostics
                                    activeSection={this.state.activeSection}
                                    actionCompleted={(bool) => this.setState({ childData: bool }, () => this.checkActionStatus())}
                                />
                            : this.state.selectedAction === "ReplaceIPAdress" ?
                                <ReplaceIPAdress
                                    activeSection={this.state.activeSection}
                                    actionCompleted={(bool) => this.setState({ childData: bool }, () => this.checkActionStatus())}
                                    identifierDetail={this.props.identifierDetail}
                                />
                            : this.state.selectedAction === "RevokeSecurityGroupIngress" ?
                                <RevokeSecurityGroupIngress
                                    activeSection={this.state.activeSection}
                                    actionCompleted={(bool) => this.setState({ childData: bool }, () => this.checkActionStatus())}
                                    identifierDetail={this.props.identifierDetail}
                                />
                            :
                                <DynamicSection
                                    activeSection={this.state.activeSection}
                                    actionCompleted={(bool) => this.setState({ childData: bool }, () => this.checkActionStatus())}
                                    identifierDetail={this.props.identifierDetail}
                                />
                            }

                            <div className="p-3 bg-dark3 mt-2 rounded displayNone">
                                <div className="d-felx pl-3">
                                    <input type="checkbox" className="form-check-input" checked={this.state.save_automation} onClick={() => this.setState({ save_automation: !this.state.save_automation }, () => this.formInputProps(this.state.activeSection, "save_automation", this.state.save_automation))} />
                                    <label className="form-check-label mb-0 pt-2p">Automate this remediation</label>
                                    <i id={"automationInfo"} className="fas fa-info-circle ml-2 cursorPointer"></i>
                                    <UncontrolledTooltip target={"automationInfo"}>
                                        <div className="uncontrolledTooltipLeftAlign">
                                            Lore ipsum data foor Automate this remediation
                                            {/* {this.state.selectedQuery.display_output[qry.field].validation.max ?
                                                <p className="mb-1 text-primary-color">
                                                    <span className="text-white mr-1">Max length:</span>
                                                    {this.state.selectedQuery.display_output[qry.field].validation.max}
                                                </p>
                                            : null} */}
                                        </div>
                                    </UncontrolledTooltip>
                                </div>
                            </div>
                            
                            {this.state.latestTab === this.state.activeSection ?
                                <React.Fragment>
                                {this.props.identifierDetail && this.props.identifierDetail.resolution_status_info && this.props.identifierDetail.resolution_status_info.status && this.props.identifierDetail.resolution_status_info.status !== "resolved" ?
                                    <div className={`d-felx  mt-4 text-center ${this.props.identifierDetail && this.props.identifierDetail.resolution_status_info && this.props.identifierDetail.resolution_status_info.status && this.props.identifierDetail.resolution_status_info.status === "resolved" ? "disabled" : ""}`}>
                                        <input type="checkbox" className="form-check-input" checked={this.state.acknowledgment} onClick={() => this.setState({ acknowledgment: !this.state.acknowledgment, showApplyBtn: !this.state.showApplyBtn })}/>
                                        <label className="form-check-label mb-0 pt-2p">I here by confirm to apply the above choosen remediation</label>
                                    </div>
                                : null}
                                <div className={`mt-4 text-center`}>
                                    <button className={`btn bg-info p-2 text-white 
                                        ${this.state.showApplyBtn && this.state.actionDataCompleted ? "continueButton" : "disabled"}
                                        ${this.props.identifierDetail && this.props.identifierDetail.resolution_status_info && this.props.identifierDetail.resolution_status_info.status && this.props.identifierDetail.resolution_status_info.status === "resolved" ? "displayNone" : ""}`}
                                        onClick={() => this.setState({ actionLoader: true }, () => this.props.createAutomation())}
                                    >
                                        {/* {this.state.actionLoader ? <Spinner color='white' size='sm' className="mx-1" /> :null} */}
                                        Execute
                                    </button>
                                    <button className={`btn bg-secondary p-2 text-white ml-2`} onClick={() => this.props.goBack()}>Cancel</button>
                                </div>
                                </React.Fragment>
                            : null}
                        </div>
                    </div>
                </div>
                {this.props.totalActions !== this.props.tabCount ?
                    <React.Fragment>
                    <div className="lineDown"></div>
                    <div className="lineDown"></div>
                    <div className="downArrow"><i className="fas fa-caret-down"></i></div>
                    </React.Fragment>
                : null}
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
ActionSection.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log("zapAction New",state)
    return {
        automationPropsDetails: state.filters.automationPropsDetails,
        listAutomationActionsProps: state.aiops.listAutomationActionsProps,
    }
}

export default connect(mapStateToProps, {
    setAutomationPropsDetails,
})(withRouter(ActionSection))        