/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Configuration.js
 * @author Rajasekar // on 02/01/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Chart from 'chart.js'
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner } from 'reactstrap'
import _ from 'lodash'
import { AppWrapper } from '../common/AppWrapper'
import ResizeableDarkThemeTable from '../designComponents/Table/ResizeableDarkThemeTable'
import { listAllIdentifiers, getIdentifiersTotalCount } from '../../actions/detectors/configurationsAction'
import { listAllProviders, listAllServices } from '../../actions/commonAction'
import Search from '../common/SearchComponent'
import { Link } from 'react-router-dom'
import Select from 'react-select'

import { PRIORITY_FILTER_OPTIONS } from '../../utils/constants'

const catergotyList = ["All", "Availability", "Compliance", "Cost", "Governance", "Performance", "Security", "Tagging", "Operations"]


class Configuration extends Component {
	constructor(props) {
		super(props)

		this.tabRef = React.createRef()

		this.state = {
			showLoading: false,
			selectedTableRow: {},
			filteredArray: [],
			providers: [],
			services: [],
			selectedProvider: 'All',
			selectedService: '',
			selectedRisk: '',
			selectedCategory: "",

			riskGraphData: [],
			identifiersTotalCount: [],

			categoryCounts: [],
			showTabListDropdown: false,
			tabItemsToShow: 0,
			selectedTableTab: -1,
		}
	}

	componentDidMount = () => {
		this.getTotalCounts()
		const _this = this
		window.addEventListener('resize', () => {
			_this.setTabs()
		})		

		this.props.listAllProviders((promise, providers) => {
			if (promise) {
				if (providers.length) {
					let dataRow = {}
					dataRow.provider_id = "All"
					dataRow.provider_name = "All"
					providers.unshift(dataRow);
				}
				this.setState({ providers })
			}
		})

		this.onSearch("onLoad")
	}

	onSearch = (type) => {
		if(this.state.changesInFilter || type === "onLoad") {
            this.setState({ 
                changesInFilter: false,
                showLoading: true,
                resetEnabled: type === 'onLoad' ? false : true
            },
                () => {
					this.filterIdentifiers()
                }
            )
        }
	}
	
	onReset = () => {
		this.setState({ 
            selectedProvider: "All",
			selectedService: "",
            selectedRisk: "",
			selectedCategory: "All",
            changesInFilter: this.state.resetEnabled,
        },
			() => this.onSearch()
        )
	}

	getAllCounts = () => {
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'All') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if (this.state.selectedService !== '' && this.state.selectedService !== 'all') {
			params.service_name = this.state.selectedService
		}
		if (this.state.selectedRegion !== '' && this.state.selectedRegion !== 'all') {
			params.region = this.state.selectedRegion
		}
		if (this.state.selectedStatus !== '' && this.state.selectedStatus !== 'all') {
			params.status = this.state.selectedStatus
		}
		if (this.state.selectedRisk !== '' && this.state.selectedRisk !== 'all') {
			params.risk = this.state.selectedRisk
		}
		if (this.state.selectedCategory && this.state.selectedCategory !== 'All') {
			params.category = this.state.selectedCategory
		}
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		if (!this.state.selectedDuration !== '' && this.state.selectedDuration !== 'all') {
			params.duration = this.state.selectedDuration
		}
		params.aggregate_by = 'category'
		this.props.getIdentifiersTotalCount(params, (promise, categoryCounts) => {
			if (promise) {
				this.setState({ categoryCounts, tabItemsToShow: categoryCounts.length })
			}
			this.setTabs()
		})
	}

	setTabs = () => {
		if (this.tabRef.current) {
			// myLog(this.tabRef.current)
			// myLog(this.tabRef.current.clientWidth)
			// myLog(this.tabRef.current.childNodes.length)
			// myLog(this.tabRef.current.childNodes)
			// myLog(this.tabRef.current.childNodes[0].clientWidth)
			// myLog(this.tabRef.current.childNodes[1].clientWidth)
			const maxTabWidth = this.tabRef.current.clientWidth - 128
			let reqTabWidth = 0
			let allowedTabLength = 0
			for (let i = 0; i < this.tabRef.current.childNodes.length; i++) {
				reqTabWidth += this.tabRef.current.childNodes[i].clientWidth
				if (reqTabWidth < maxTabWidth) {
					allowedTabLength++
				}
			}
			// myLog(maxTabWidth, reqTabWidth, allowedTabLength)
			this.setState({ tabItemsToShow: allowedTabLength })
		}
	}

	showConfigurationGraph = () => {
		const configGraph = this.configGraph
		Chart.defaults.scale.gridLines.display = false
		Chart.defaults.scale.display = false
		let graphLabel = []
		let graphData = []
		const colors = ['#f68d11', '#34a853', '#2075b8']
		this.state.identifiersTotalCount.forEach(item => {
			graphLabel.push(item.provider)
			graphData.push(item.count)
		})
		new Chart(configGraph, {
			type: 'doughnut',
			options: {
				legend: {
					display: false,
				},
			},
			data: {
				datasets: [
					{
						backgroundColor: colors,
						data: graphData,
						borderColor: '#2D323E',
						borderWidth: 0,
					},
				],
				labels: graphLabel,
			},
		})
	}

	showConfigurationRiskGraph = () => {
		const configRiskGraph = this.configRiskGraph
		Chart.defaults.scale.gridLines.display = false
		Chart.defaults.scale.display = false
		let graphLabel = []
		let graphData = []
		let colors = []
		this.state.riskGraphData.forEach(() => {
			colors.push('#' + (((1 << 24) * Math.random()) | 0).toString(16))
		})
		this.state.riskGraphData.forEach(item => {
			graphLabel.push(item.risk)
			graphData.push(item.count)
		})
		new Chart(configRiskGraph, {
			type: 'line',
			data: {
				datasets: [
					{
						label: '#',
						data: graphData,
						borderColor: '#09D261',
						fill: true,
						borderWidth: 2,
					},
				],
				labels: graphLabel,
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				legend: {
					display: false,
				},
			},
		})
	}
	/**
	 * toggles tab list dropdown
	 */
	toggleShowTabListDropdown = () => {
		this.setState(prevState => ({
			showTabListDropdown: !prevState.showTabListDropdown,
		}))
	}

	/**
	 * Get total counts
	 */
	getTotalCounts = () => {
		const params = {
			aggregate_by: 'provider',
		}
		this.props.getIdentifiersTotalCount(params, (promise, identifiersTotalCount) => {
			if (promise) {
				this.setState({ identifiersTotalCount })
				this.showConfigurationGraph()
			}
			this.setState({ showGraphLoading: false })
		})
		const risksParams = {
			aggregate_by: 'risk',
		}
		this.props.getIdentifiersTotalCount(risksParams, (promise, identifiersTotalCount) => {
			if (promise) {
				this.setState({ riskGraphData: identifiersTotalCount })
				this.showConfigurationRiskGraph()
			}
		})
	}
	/**
	 * Method to return total fail counts
	 */
	getTotalFailCounts = () => {
		let count = 0
		if (this.state.categoryCounts && this.state.categoryCounts.length) {
			this.state.categoryCounts.forEach(item => {
				count += item.count
			})
		}
		return count
	}

	/**
	 * Method to return total fail counts
	 */
	getTotalRiskFailCounts = () => {
		let count = 0
		if (this.state.riskGraphData && this.state.riskGraphData.length) {
			this.state.riskGraphData.forEach(item => {
				count += item.count
			})
		}
		return count
	}

	/**
	 * Filter identifiers based on the filter selection
	 */
	filterIdentifiers = () => {
		this.setState({ showLoading: true })
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'All') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if (this.state.selectedService !== '' && this.state.selectedService !== 'all') {
			params.service_name = this.state.selectedService
		}
		if (this.state.selectedRisk !== '' && this.state.selectedRisk !== 'all') {
			params.risk = this.state.selectedRisk
		}
		if (this.state.selectedCategory !== '' && this.state.selectedCategory !== 'All') {
			params.category = this.state.selectedCategory
		}
		this.props.listAllIdentifiers(params, identifiersArray => {
			if (identifiersArray && identifiersArray.results) {
				this.setState({ showLoading: false, selectedTableRow: this.props.identifiers[0] },
					() => {
						this.setTabs()
					}	
				)
			} else {
				this.setState({ showLoading: false })
			}
		})

		this.getAllCounts(true)
	}

	getFilterDetails = () => {
		let params = {
			"provider": this.state.selectedProvider.toLowerCase()
		}

		this.props.listAllServices(params, (promise, services) => {
			if (promise) {
				this.setState({ services }, 
					() => {
						this.formSericeSelect()	
					}
				)
			}
		})
	}

	formSericeSelect = () => {
		let data = []
		if(this.state.services && this.state.services.length) {
			_.chain(this.state.services)
				.groupBy('resource_type')
				.map((value, key) => ({ label: key, sub: value }))
				.value()
				.forEach((service, i) => {
					let child = []
					service.sub.forEach((item, i) => {
						let childRow = {}
						childRow.value = service.service_name
						childRow.label = item.service_name
						child.push(childRow)
					})
					let dataRow = {}
					dataRow.label = service.label
					dataRow.options = child

					data.push(dataRow)
				})
		}

		this.setState({ servicesOptGrop: data })
	}

	/**
	 * Handles on select category
	 */
	onSelectCategory = event => {
		this.setState({ selectedCategory: event.target.value, },
			() => this.filterIdentifiers()
		)
	}

	/**
	 * Handles on click tab dropdown
	 */
	onClickDropDownItem = (item, index) => {
		let tempArray = this.state.categoryCounts
		tempArray.splice(index, 1)
		tempArray.splice(this.state.tabItemsToShow - 2, 0, item)
		this.setState({
			categoryCounts: tempArray,
			selectedTableTab: this.state.tabItemsToShow - 2,
			selectedCategory: item.category,
		})
		this.getAllDetectorsOnTabChange(item.category)
	}
	/**
	 * Get all identifiers on change the tab
	 */
	getAllDetectorsOnTabChange = category => {
		this.setState({ selectedCategory: category }, () => this.filterIdentifiers())
	}

	render() {
		const { selectedTableRow, showLoading } = this.state
		return (
			<div className="container-fluid overflow-auto flex-grow-1 bg-muted">
				<div className='row h-100'>
					<div className={selectedTableRow && Object.entries(selectedTableRow).length ? 'col-sm-9 p-0' : 'col-sm-12 p-0'}>
						<div className='title-section-new'>
							<div className={`row mt-3 ${showLoading ? 'disabled' : ''}`}>
								<div className="col-4 align-self-center">
									<h6 className="text-white m-0">Checks Configuration</h6>
									<p className="text-white m-0">
										Manage user created configurations on your Cloud
									</p>
								</div>
								<div className="col-8 align-self-center">
									<div className={`col-md-12 d-flex flex-wrap justify-content-end ${this.state.showLoading ? "disabled" : ""}`}>
										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: "150px" }}>
											<label className="mb-1">Provider</label>
											<Select
												placeholder={"All"}
												isSearchable={false}
												// menuIsOpen={true}
												//onFocus={this.openProivderMenu}
												className="f13 p-0 colorBlack reactSelectFilterDrop"
												value={({
													value: this.state.selectedProvider,
													label: this.state.selectedProvider ? this.state.selectedProvider : <span className="placeholder">Select</span>
												})}
												options={this.state.providers && this.state.providers.map(item => ({
													value: item.provider_name,
													label: item.provider_name,	
												}))}
												onChange={event => this.setState({ selectedProvider: event.value, changesInFilter: true }, 
													() => {
														this.getFilterDetails()
													}
												)}
											/>
										</div>
									
										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: "300px" }}>
											<label className="mb-1">Services</label>
											<Select
												placeholder={"Select"}
												// isSearchable={false}
												// menuIsOpen={true}
												//onFocus={this.openProivderMenu}
												className="f13 p-0 colorBlack reactSelectOptGroupFilterDrop"
												// label={({
												// 	label: this.state.selectedService,
												// 	// label: this.state.selectedService ? getRegionName(this.state.selectedService, regions) : <span className="placeholder">Select</span>
												// })}
												options={this.state.servicesOptGrop && this.state.servicesOptGrop}
												onChange={event => this.setState({ selectedService: event.label, changesInFilter: true })}
											/>
										</div>
										<div className='form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 displayNone'>
											<label>Service</label>
											<select
												className='form-control'
												disabled={this.state.selectedProvider === ''}
												onChange={event => {
													this.setState({ selectedService: event.target.value, },
														() => this.filterIdentifiers()
													)
												}}
											>
												<option value='all'>All</option>
												{this.state.services &&
													this.state.services.length &&
													_.chain(this.state.services)
														.groupBy('resource_type')
														.map((value, key) => ({ label: key, sub: value }))
														.value()
														.map((service, i) => {
															return (
																<optgroup key={i} label={service.label}>
																	{service.sub.map((item, i) => {
																		return (
																			<option key={i} value={service.service_name}>
																				{item.service_name}
																			</option>
																		)
																	})}
																</optgroup>
															)
														})}
											</select>
										</div>
										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: "150px" }}>
											<label className="mb-1">Risk</label>
											<Select
												placeholder={"All"}
												isSearchable={false}
												// menuIsOpen={true}
												//onFocus={this.openProivderMenu}
												className="f13 p-0 colorBlack reactSelectFilterDrop"
												value={({
													value: this.state.selectedRisk,
													label: this.state.selectedRisk ? this.state.selectedRisk : <span className="placeholder">Select</span>
												})}
												options={PRIORITY_FILTER_OPTIONS && PRIORITY_FILTER_OPTIONS.map(item => ({
													value: item.value,
													label: item.option,	
												}))}
												onChange={event => this.setState({ selectedRisk: event.value, changesInFilter: true })}
											/>
										</div>
										<div className='form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 displayNone'>
											<label>Risk</label>
											<select
												className='form-control'
												onChange={event => {
													this.setState({ selectedRisk: event.target.value },
														() => this.filterIdentifiers()
													)
												}}
											>
												<option value='all'>All</option>
												<option>High</option>
												<option>Low</option>
												<option>Medium</option>
											</select>
										</div>
										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: "150px" }}>
											<label className="mb-1">Category</label>
											<Select
												placeholder={"All"}
												isSearchable={false}
												// menuIsOpen={true}
												//onFocus={this.openProivderMenu}
												className="f13 p-0 colorBlack reactSelectFilterDrop"
												value={({
													value: this.state.selectedCategory,
													label: this.state.selectedCategory && this.state.selectedCategory !== "" ? this.state.selectedCategory : <span className="placeholder">Select</span>
												})}
												options={catergotyList && catergotyList.map(item => ({
													value: item,
													label: item,	
												}))}
												onChange={event => this.setState({ selectedCategory: event.value, changesInFilter: true })}
											/>
										</div>
										<div className='form-group flex-fill bd-highlight mb-sm-0 styled-feild displayNone'>
											<label>Category</label>
											<select className='form-control' onChange={event => this.onSelectCategory(event)}>
												<option value='all'>All</option>
												<option>Availability</option>
												<option>Compliance</option>
												<option>Cost</option>
												<option>Governance</option>
												<option>Performance</option>
												<option>Security</option>
												<option>Tagging</option>
												<option>Operations</option>
											</select>
										</div>
										<div className="mt-2 d-flex align-self-center">
											<div className="btn-group" role="group" aria-label="governance-dash">
												<button type="button" className={`${this.state.showLoading ? "disabled" : "cursorPointer"} btn btn-secondary border-right`} onClick={()=> this.onSearch()}>Search</button>
												<button type="button" className={`${this.state.showLoading ? "disabled" : "cursorPointer"} btn btn-secondary border-left`} onClick={()=> this.onReset()}><i className="far fa-redo"></i></button>
											</div>
										</div>
									</div>
								</div>
								<div className='col-sm-4 displayNone'>
									<Search
										data={this.props.identifiers && this.props.identifiers}
										filteredData={filteredArray => this.setState({ filteredArray, selectedTableRow: filteredArray[0] })}
									/>
									{/* <button className='btn btn-link p-0' onClick={() => this.setState({ showAdvancedSearchModal: true })}>
										Advanced Search
									</button>
									<button
										className='btn btn-link ml-3 p-0'
										onClick={() =>
											this.setState(
												{
													selectedProvider: '',
													selectedService: '',
													selectedRisk: '',
													selectedCategory: '',
												},
												() => this.filterIdentifiers()
											)
										}
									>
										Clear Filter
									</button> */}
								</div>
							</div>
							<div className='row mt-2 d-flex'>
								<div className='col-12 col-xl-12 d-flex justify-content-end'>
									<div className='d-flex justify-content-start align-self-center'>
										<div className='mx-3'>
											<canvas
												style={{
													// width: '100px',
													height: '60px',
												}}
												ref={configGraph => (this.configGraph = configGraph)}
											/>
										</div>
										<div className='d-flex flex-wrap mx-3'>
											{this.state.riskGraphData.length
												? this.state.riskGraphData.map((item, index) => {
														if (item.count !== 0) {
															return (
																<div className='pr-2 mb-1 w-50' key={index}>
																	<div className='bd-highlight d-flex'>
																		<small className='text-white bd-highlight'>{item.risk}</small>
																		<small className='text-white bd-highlight ml-auto'>{item.count}</small>
																	</div>
																	<div className='progress'>
																		<div
																			className={`risk-bg-${item.risk.toLowerCase()} progress-bar`}
																			role='progressbar'
																			style={{ width: `${(item.count / this.getTotalRiskFailCounts()) * 100}%` }}
																			aria-valuenow={item.count}
																			aria-valuemin={item.count}
																			aria-valuemax={this.getTotalRiskFailCounts()}
																		/>
																	</div>
																</div>
															)
														}
														return null
												  })
												: null}
										</div>
									</div>
								</div>
								{/* <div className='col-4 col-xl-4 align-self-end'>
									<Search
										data={this.props.identifiers && this.props.identifiers}
										filteredData={filteredArray => this.setState({ filteredArray, selectedTableRow: filteredArray[0] })}
									/>
								</div> */}
							</div>
						</div>						

						<div className="box custom-margin bg-dark3 box-shadow-none">
							{this.state.categoryCounts && this.state.categoryCounts.length ? (
								<div>
									<ul
										ref={this.tabRef}
										className={`nav nav-tabs border-bottom bg-dark3 ${this.state.showLoading ? 'disabled' : ''}`}
										id='myTab'
										role='tablist'
									>
										<li className='nav-item'>
											<Link
												className={`nav-link custom-link text-white ${this.state.selectedTableTab === -1 ? 'active' : ''}`}
												id='home-tab'
												data-toggle='tab'
												to=''
												onClick={() => {
													// const params = {
													// 	duration: this.state.selectedDuration,
													// }
													this.setState({ selectedCategory: "All" })
													this.setState({ showLoading: true })
													this.filterIdentifiers()
												}}
												role='tab'
												aria-selected='true'
											>
												All
												<span className='badge badge-outline-light d-table m-auto'>{this.getTotalFailCounts()}</span>
											</Link>
										</li>
										{this.state.categoryCounts &&
											this.state.categoryCounts.length &&
											this.state.categoryCounts.map((item, index) => {
												if (index < this.state.tabItemsToShow)
													return (
														<li
															className='nav-item'
															key={index}
															onClick={() => this.getAllDetectorsOnTabChange(item.category)}
														>
															<div
																className={`nav-link custom-link cursor-pointer text-white ${
																	this.state.selectedTableTab === index ? 'active' : ''
																}`}
																id='profile-tab'
																data-toggle='tab'
																onClick={() => this.setState({ selectedTableTab: index })}
																role='tab'
																aria-selected='false'
															>
																{item.category}
																<span className='badge badge-outline-light d-table m-auto'>{item.count}</span>
															</div>
														</li>
													)
												return null
											})}
										<li
											className={`nav-item ml-auto bd-highlight align-self-center text-white ${
												this.state.categoryCounts.length >= this.state.tabItemsToShow ? '' : 'd-none'
											}`}
										>
											<ButtonDropdown
												isOpen={this.state.showTabListDropdown}
												toggle={() => this.toggleShowTabListDropdown()}
											>
												<DropdownToggle
													tag='a'
													className={`nav-link custom-link text-white ${!this.state.categoryCounts.length ? 'd-none' : ''}`}
												>
													<i className='fal fa-angle-double-right cursor-pointer' />
												</DropdownToggle>
												<DropdownMenu
													right
													className="bg-dark3"
													modifiers={{
														setMaxHeight: {
															enabled: true,
															order: 890,
															fn: data => {
																return {
																	...data,
																	styles: {
																		...data.styles,
																		overflow: 'auto',
																		maxHeight: 300,
																	},
																}
															},
														},
													}}
												>
													{this.state.categoryCounts &&
														this.state.categoryCounts.map((item, index) => {
															if (index >= this.state.tabItemsToShow)
																return (
																	<DropdownItem key={index} className="text-white" onClick={() => this.onClickDropDownItem(item, index)}>
																		{item.category}
																	</DropdownItem>
																)
															return null
														})}
												</DropdownMenu>
											</ButtonDropdown>
										</li>
									</ul>
								</div>
							) : null}							
							<div className="col-12 d-flex justify-content-between py-2">
								<p className="mb-0 align-self-center">Showing {this.state.filteredArray && this.state.filteredArray.length ? this.state.filteredArray.length : (this.props.identifiers && this.props.identifiers.length)} of total {this.props.identifiers && this.props.identifiers.length} Rules</p>
								<div className={`mb-n2 ${this.state.showLoading ? "disabled" : ""}`}>
									<Search
										data={this.props.identifiers && this.props.identifiers}
										filteredData={filteredArray => this.setState({ filteredArray, selectedTableRow: filteredArray[0] })}
									/>
								</div>
							</div>
							<div className='tab-content px-3' id='myTabContent'>
								<div className='tab-pane fade show active' id='home' role='tabpanel' aria-labelledby='home-tab'>
									{this.props.identifiers && this.props.identifiers.length && !showLoading ? (
										<ResizeableDarkThemeTable
											columns={[
												{
													Header: 'Risk',
													accessor: 'risk',
													Cell: cellInfo => (
														<span className={`badge risk-badge-${
															cellInfo.row.original.risk ?
																cellInfo.row.original.risk.toLowerCase()
															: ''
															} aiopsTableBadgeWidth`}
														>
															{cellInfo.row.original.risk ?
																(cellInfo.row.original.risk.toLowerCase() === 'critical' ? 
																	'C' 
																: cellInfo.row.original.risk.toLowerCase() === 'high' ? 
																	'H'
																: cellInfo.row.original.risk.toLowerCase() === 'medium' ? 
																	'M'
																: cellInfo.row.original.risk.toLowerCase() === 'low' ? 
																	'L' 
																: '') 
															: 'U'}
														</span>
													),
													width: 80
												},
												{
													Header: 'Service Id',
													accessor: 'service_id',
												},
												{
													Header: 'Name',
													accessor: 'title',
													width: 220,
												},
												{
													Header: 'Group Name',
													accessor: 'group_name',
													width: 220,
												},
												{
													Header: 'Provider',
													accessor: 'provider',
												},
												{
													Header: 'Service',
													accessor: 'service_name',
													width: 220,
												},
												{
													Header: 'Category',
													accessor: d =>
														Array.isArray(d.category)
															? d.category.map((item, index) => (d.category.length - 1 === index ? item : item + ', '))
															: d.category,
													width: 250,
												},
												{
													Header: ' ',
													// accessor: 'risk',
													width: 1
												},
											]}
											
											
											data={this.state.filteredArray.length ? this.state.filteredArray : this.props.identifiers}
											perPage={20}
											dashboard={(this.props.filteredArray ? this.props.filteredArray.length : this.props.identifiers.length) ? true : false}
											sortByColumn={" "}
											riskTooltip={[]}
											onClickRow={tableRow => this.setState({ selectedTableRow: tableRow })}
											// tableSize={"table-sm"}
											// tableHead={"bg-dark"}
											tableBody={"bg-dark"}
											tooltipFor={[6]}
											rounded={true}
											// manualWidth={true}
										/>
									) : (!showLoading && !this.props.identifiers) ||
									  (this.props.identifiers && !this.props.identifiers.length) ? (
										<div className='d-flex justify-content-center m-4'>
											<p>No data found</p>
										</div>
									) : null}
									{showLoading ? (
										<div className='d-flex justify-content-center m-4'>
											<Spinner className='text-center' color='white' size='lg' />
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>
					{selectedTableRow && Object.entries(selectedTableRow).length ?
						<div className='col-sm-3 p-0'>
							<div
								className={`bg-dark3
									${this.props.identifiers &&
										this.props.identifiers.length &&
										this.state.selectedTableRow && 
										Object.entries(this.state.selectedTableRow).length &&
										this.state.selectedTableRow.constructor === Object
											? 'detail'
											: 'd-none'
									}
								`}
							>
								<div className='detail-title bg-dark3 border-bottom'>
									<div className='d-lg-flex'>
										{selectedTableRow && selectedTableRow.risk ?
											<div
												className={`risk-bg-${selectedTableRow.risk.toLowerCase()} risk-status mr-3`}
											>
												{selectedTableRow.risk ? selectedTableRow.risk : ""}
											</div>
										: null}
										<div className='align-self-center text-white'>
											<p className='m-0'>{selectedTableRow.service_id}</p>
										</div>
									</div>
									<span className='badge badge-pill badge-secondary p-2 mt-2 text-wrap'>{selectedTableRow.title}</span>
									<p className='mt-2 mb-0'>
										<small className='text-white mt-2'>{selectedTableRow.description}</small>
									</p>
								</div>
								
								<div className="bg-dark m-3 p-3 rounded">
									<div className="pb-2 mb-2 border-bottom">
										<p className="b-block mb-2">Category :</p>
										{selectedTableRow.category && selectedTableRow.category.map((item, index) => {
											return (
												<span key={index} className='badge badge-pill badge-light ml-1'>
													{item}
												</span>
											)
										})}
									</div>
									<div className="pb-2 mb-2 border-bottom">
										<p className="b-block mb-2">Provider ID :</p>
										<p className="mb-0 text-white">{selectedTableRow.provider}</p>
									</div>
									<div className="pb-2 mb-2 border-bottom">
										<p className="b-block mb-2">Service :</p>
										<p className="mb-0 text-white">{selectedTableRow.service_name}</p>
									</div>
									<div className="pb-2 mb-2 border-bottom">
										<p className="b-block mb-2">Resource Type :</p>
										<p className="mb-0 text-white">{selectedTableRow.resource_type}</p>
									</div>								
									<div className="pb-2 mb-2 border-bottom">
										<p className="b-block mb-2">Mandatory :</p>
										<p className="mb-0 text-white">{selectedTableRow.mandatory ? 'Yes' : 'No'}</p>
									</div>
									<div className="pb-2 mb-2">
										<p className="b-block mb-2">Compliance ID :</p>
										<span className='text-muted'>Compliance ID :</span> <br />
											{selectedTableRow.compliance && Array.isArray(selectedTableRow.compliance) ? (
												selectedTableRow.compliance.map((item, index) => {
													return (
														<span key={index} className='text-white'>
															{item.complianceId}
														</span>
													)
												})
											) : (
												<span className='text-white'>
													{selectedTableRow.compliance && selectedTableRow.compliance.complianceId}
												</span>
											)}
									</div>
								</div>
							</div>
						</div>
					: null}
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
Configuration.propTypes = {
	listAllIdentifiers: PropTypes.func,
	listAllProviders: PropTypes.func,
	listAllServices: PropTypes.func,
	getIdentifiersTotalCount: PropTypes.func,
	identifiers: PropTypes.array,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		identifiers: state.identifiers.identifiers.results,
	}
}

export default AppWrapper(Configuration, mapStateToProps, {
	listAllIdentifiers,
	listAllProviders,
	listAllServices,
	getIdentifiersTotalCount,
})
