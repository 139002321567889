
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file JoinOutput.js
 * @author Prakash // on 31/01/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Select from 'react-select';
import { Input } from 'reactstrap'
import { setAutomationPropsDetails } from '../../actions/commonAction'

// const channelType = ["slack", "email"]
const functionOptions = ["JoinByKey"]

class JoinOutput extends Component {
    constructor(props) {
        super(props)

        this.toggleJoinRef = React.createRef();
        this.toggleAndRef = React.createRef();
        
        this.state = {
            activeSection: this.props.activeSection,
            andArray: [0],
            channelArray: [0]

        }
    }

    componentDidMount = () => {
        if(this.props.automationPropsDetails) {
            let obj = this.props.automationPropsDetails
            console.log("obj", obj)
            let exist = obj.actions.filter(e => e.section === this.state.activeSection)
            if(exist.length) {
                let input = exist[0].input ? exist[0].input : []
                input.forEach(item => {
                    if(item.key === "action") {
                        this.setState({ join_action: item.value },
                            () => {
                                this.checkAllDataFilled()
                            }
                        )
                    } else {
                        this.setState({ [item.key]: item.value_list ? item.value_list : item.value },
                            () => {
                                this.checkAllDataFilled()
                            }
                        )
                    }
                })                
            }

            let actions = this.props.automationPropsDetails.actions ? this.props.automationPropsDetails.actions : []
            let previousAutomations = actions.filter(e => e.section < this.state.activeSection)

            this.setState({ previousAutomations })
        }
    }

    componentDidUpdate = (prevProps) => {}

    onActionSelect = (action) => {
        this.setState({ 
            selectedAction: action, 
            showActionResults: false 
        },
            // () => this.formInputProps(["action_"+this.state.activeSection], "action", this.state.selectedAction)
        )
    }

    formInputProps = (section, field, value) => {
        let obj = this.props.automationPropsDetails
        let actions = obj.actions
        let objIndex = actions.findIndex((e => e.section === section))
        let dataRow = {}
        dataRow.key = field
        if(field === "and") {
            dataRow.value_list = value
            dataRow.dynamic = true
        } else {
            dataRow.value = value
        }
        if(field === "action") {
            dataRow.dynamic = true
        }

        let input = actions[objIndex].input ? actions[objIndex].input : []
        let inputIndex = input.findIndex((e => e.key === field))
        if(inputIndex > -1) {
            input[inputIndex] = dataRow
        } else {
            input.push(dataRow)
        }
        actions[objIndex].input = input
        obj.actions = actions
        
        this.props.setAutomationPropsDetails(obj)

        this.checkAllDataFilled()        
    }

    checkAllDataFilled = () => {

        let hasError = false
        // let errorMessage = ""

        if(!this.state.join_action || this.state.join_action === "" || !this.state.and || !this.state.and.length || !this.state.function || !this.state.lookup_key || this.state.lookup_key === "") {
            hasError = true
            // errorMessage = "Please fill minimum channel details"
        }

        this.setState({ hasError })
        // && this.state.attachments && this.state.attachments !== ""
        if(!hasError) {
            this.props.actionCompleted(true)
        } else {
            this.props.actionCompleted(false)
        }
    }

    addSection = (array) => {
        let rowList = this.state[array];
        if(this.state[array]) {
            let value = this.state[array][this.state[array].length - 1]
            value = value+1 
            rowList.push(value);
        }
        
        this.setState({[array]: rowList })
    }

    removeMessage = (item, i) => {
        this.setState({ ["message_key"+item]: "", ["message_value_"+item]: "" },
            () => {
                let rowList = this.state.messageArray;
                rowList.splice(i, 1);
                this.setState({ messageArray: rowList })
            }
        );
    }   

    removeSelectedBadge = (field, value, index) => {
        // let filteredReslt = this.state[field].filter(e => e !== value)
        // this.setState({ [field]: filteredReslt },
        //     () => this.formInputProps(this.state.activeSection, field, this.state[field])    
        // )
        let filteredReslt = this.state[field]
        filteredReslt.splice(index, 1);
        this.setState({ [field]: filteredReslt },
            () => this.formInputProps(this.state.activeSection, field, this.state[field])    
        )
    }

    handleClickOutside(event) {
        if (this.toggleJoinRef && !this.toggleJoinRef.current.contains(event.target)) {
            this.setState({ show_action_options: false })
        }
        if (this.toggleAndRef && !this.toggleAndRef.current.contains(event.target)) {
            this.setState({ show_and_options: false })
        }
    }

    onSelectAndAction = (field, obj) => {
        let selectedOption = this.state[field] ? this.state[field] : []
        let name = "${"+obj.name+"}"
        if(selectedOption.includes(name)) {
            selectedOption = selectedOption.filter(e => e !== name) //for ui purpose            
        } else {
            let name = "${"+obj.name+"}"
            selectedOption.push(name)
        }

        this.setState({ [field]: selectedOption },
            () => this.formInputProps(this.state.activeSection, field, this.state[field])
        )
    }

    checkEnteredCharacter = (field, textboxState, index) => {
        let fieldState = textboxState
        if(index !== "") {
            fieldState = textboxState+"_"+index
        }
        let position = this.state[fieldState].indexOf("$$");
        if(position >= 0) {
            this.setState({ ["show_"+textboxState+"_options"]: true})
        }
        // () => this.formInputProps(this.state.activeSection, field, this.state[fieldState])
    }

    onKeyDown = (e, field, stateField) => {
        let array = this.state[stateField] && Array.isArray(this.state[stateField]) ? this.state[stateField] : []
        console.log(this.state[stateField])
		if (e.keyCode === 13 || e.keyCode === 9) {
            if(this.state[field] && this.state[field] !== "") {
                array.push(this.state[field].trim())
                if(e.keyCode === 9) {
                    e.preventDefault();
                }
                this.setState({ [stateField]: array, [field]: "" },
                    () => this.formInputProps(this.state.activeSection, stateField, this.state[stateField])
                )
            }
        }
    }

    onBlur = (e, field, stateField) => {
        let array = this.state[stateField] ? this.state[stateField] : []
        if(this.state[field] && this.state[field] !== "") {
            array.push(this.state[field].trim())
            this.setState({ [stateField]: array, [field]: "" },
                () => this.formInputProps(this.state.activeSection, stateField, this.state[stateField])
            )
        }
    }  

    selectedpreviousAutomation = (field, index, actionDetails, childIndex, childDetails) => {
        // this.state.previousAutomations.forEach((item, i) => {
        //     this.setState({ ["showChild_"+i]: false })
        // })

        //if there is child it will be confiigured when real data is available for child section
        // this.setState({ ["showChild_"+childIndex]: !this.state["showChild_"+childIndex] })

        //if there is no child
        // this.selectedField(field, index, actionDetails, childIndex, childDetails)
        let stringToSave = ""
        if(actionDetails !== "") {
            let res = "${"+actionDetails.name+".}"
            if(childDetails !== "") {
                res = "${"+actionDetails.name+"."+childDetails.name+"}"
            }
            stringToSave = res
        }

        this.setState({ [field]: stringToSave, ["show_"+field+"_options"]: false },
            () => {
                document.getElementById(field).focus();
                if(field === "join_action") {
                    this.formInputProps(this.state.activeSection, "action", this.state.join_action)
                }
            }
        )
    }
    

    render() {
        return (
            <div className="" onClick={(event) => this.handleClickOutside(event)}>
                <div className="d-flex mt-3">
                    <div className="w-50">
                        <div className="position-relative d-inline-block w-100" ref={this.toggleJoinRef}>
                            <h6 className="mb-1">Join</h6>
                            <label className="mb-1">Action 1</label>    
                            <Input
                                type="text"
                                placeholder="join"
                                className="inputTextbox"
                                id={"join_action"}
                                value={this.state.join_action}
                                autocomplete="off"
                                // readOnly={true}
                                onChange={e => this.setState({ join_action: e.target.value }, 
                                    () => {
                                        this.checkEnteredCharacter("action", "join_action", "")
                                        this.formInputProps(this.state.activeSection, "action", this.state.join_action)
                                    }
                                )}
                                // onClick={() => this.setState({ show_action_options: !this.state.show_action_options })}
                            />
                            
                            {this.state.show_join_action_options && this.state.previousAutomations && this.state.previousAutomations.length ? 
                                <div className={`dropdownOptions topAuto mt-2 bg-white `}>
                                    {this.state.previousAutomations.map((prevAct, j) => {
                                        return (
                                            <React.Fragment>
                                                <button className="d-flex flex-column mb-3" onClick={() => this.selectedpreviousAutomation("join_action", "", prevAct, j, "")}>
                                                {/* onClick={() => this.setState({ show_action_options: false, join_action: "${"+prevAct.name+"}" }, () => this.formInputProps(this.state.activeSection, "action", this.state.join_action))}> */}
                                                    <div className="d-flex justify-content-between w-100">
                                                        <div>
                                                            <p className="mb-0 text-left">{prevAct.name}</p>
                                                            <p className="mb-0 small description">{prevAct.action_name}</p>
                                                        </div>
                                                        {/* if there is child it will be confiigured when real data is available for child section */}
                                                        {/* {this.state["showChild_"+j] ? 
                                                            <i className="fas fa-angle-up mr-2 f18"></i>
                                                        :
                                                            <i className="fas fa-angle-down mr-2 f18"></i>
                                                        } */}
                                                        
                                                    </div>
                                                </button>
                                                {/* {this.state["showChild_"+j] ? 
                                                    this.state.previousAutomations.map(child => {
                                                        return(
                                                        <button className={`d-flex flex-column ml-2 ${j === this.state.previousAutomations.length - 1 ? "mb-3" : "mb-2 mt-n2"} `}  onClick={() => this.selectedField("multiply_with", index, prevAct, j, child)}>
                                                            <p className="mb-0 f12 label">{child.action_name}</p>
                                                        </button>
                                                        )
                                                    })
                                                : null} */}
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                            : null}
                        </div>
                    </div>
                    <div className="pl-3 w-50">
                        <div className="position-relative d-inline-block w-100" ref={this.toggleAndRef}>
                            <h6 className="mb-1">And</h6>
                            <label className="mb-1">Action 2</label>
                            <Input
                                type="text"
                                placeholder="and"
                                className="inputTextbox"
                                id={"and_input"}
                                value={this.state.and_input}
                                autocomplete="off"
                                // readOnly={true}
                                // onClick={() => this.setState({ show_and_options: !this.state.show_and_options })}
                                onChange={e => this.setState({ and_input: e.target.value }, 
                                    () => {
                                        this.checkEnteredCharacter("and", "and_input", "")
                                        // this.formInputProps(this.state.activeSection, "and", this.state.and_input)
                                    }
                                )}
                                onKeyDown={e => this.onKeyDown(e, "and_input", "and")}
                                // onBlur={e => this.onBlur(e, "and_input", "and")}
                            />
                            {this.state.show_and_input_options && this.state.previousAutomations && this.state.previousAutomations.length ? 
                                <div className={`dropdownOptions topAuto mt-2`}>
                                    {this.state.previousAutomations.map((prevAct, j) => {
                                        return (
                                            <React.Fragment>
                                                <button className="d-flex flex-column mb-3" onClick={() => this.selectedpreviousAutomation("and_input", "", prevAct, j, "")}>
                                                    <div className="d-flex justify-content-between w-100">
                                                        {/* <div className="d-flex" onClick={() => this.onSelectAndAction("and", prevAct)}> */}
                                                            {/* <input type="checkbox" className={`mt-1 mr-1`} checked={this.state.and && this.state.and.includes("${"+prevAct.name+"}") ? true : false} /> */}
                                                        <div className="d-flex">
                                                            <div>
                                                                <p className="mb-0 text-left">{prevAct.name}</p>
                                                                <p className="mb-0 small description">{prevAct.action_name}</p>
                                                            </div>
                                                        </div>
                                                        {/* if there is child it will be confiigured when real data is available for child section */}
                                                        {/* {this.state["showChild_"+j] ? 
                                                            <i className="fas fa-angle-up mr-2 f18"></i>
                                                        :
                                                            <i className="fas fa-angle-down mr-2 f18"></i>
                                                        } */}
                                                        
                                                    </div>
                                                </button>
                                                {/* {this.state["showChild_"+j] ? 
                                                    this.state.previousAutomations.map(child => {
                                                        return(
                                                        <button className={`d-flex flex-column ml-2 ${j === this.state.previousAutomations.length - 1 ? "mb-3" : "mb-2 mt-n2"} `}  onClick={() => this.selectedField("multiply_with", index, prevAct, j, child)}>
                                                            <p className="mb-0 f12 label">{child.action_name}</p>
                                                        </button>
                                                        )
                                                    })
                                                : null} */}
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                            : null}
                        </div>
                    </div>
                </div>
                <div className="mt-2 d-flex flex-wrap">
                    {this.state.join_action && this.state.join_action !== "" ?
                        <p className="mb-0 mr-2">
                            Join Action 1 
                            <span className="badge-square badge-secondary f13 align-self-center p-1 mx-1 mb-1" >{this.state.join_action}</span>
                        </p>
                    : null}
                    {this.state.and && Array.isArray(this.state.and) && this.state.and.map((tab, addIndex) => {
                        return(
                            <React.Fragment>
                            {!addIndex ? 
                                <p className="mb-0 mr-2">With Action 2</p>
                            : null}
                            <span key={addIndex} className="badge-square badge-secondary f13 align-self-center p-1 mx-1 mb-1" >
                            {tab}
                                <i onClick={ () => this.removeSelectedBadge("and", tab, addIndex) } className="ml-1 fal fa-times cursorPointer"></i>
                            </span>
                            </React.Fragment>
                        )
                    })}
                </div>
                <div className="d-flex mt-3">
                    <div className="w-50">
                        <label className="mb-1">Using Method</label>
                        <Select 
                            options={functionOptions.map(item => ({
                                value: item,
                                label: item,
                            }))}
                            value={({
                                value: this.state.function && this.state.function,
                                label: this.state.function ? this.state.function : <span className="placeholder">Select Method</span>
                            })}
                            onChange={event => 
                                this.setState({ function: event.value },
                                    () => {
                                        this.formInputProps(this.state.activeSection, "function", this.state.function)
                                    }
                                )
                            }
                        />
                    </div>
                    <div className="pl-3 w-50">
                        <label className="mb-1">With LookUpKey</label>
                        <div className="col-sm-12 p-0">
                            <Input
                                type="text"
                                placeholder="Loopup key"
                                className="inputTextbox"
                                value={this.state.lookup_key}
                                id={"lookup_key"}                                
                                autocomplete="off"
                                onChange={event =>
                                    this.setState({ lookup_key: event.target.value },
                                        () => {
                                            this.formInputProps(this.state.activeSection, "lookup_key", this.state.lookup_key)
                                        }
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
JoinOutput.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log("JoinOutput",state)
    return {
        automationPropsDetails: state.filters.automationPropsDetails
    }
}

export default connect(mapStateToProps, {
    setAutomationPropsDetails,
})(withRouter(JoinOutput))        