/*************************************************
 * Tvastar
 * @exports
 * @file SidePanel.js
 * @author Rajasekar // on 22/04/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { URL_PATH } from '../../../config/urlPath'
import {
	setEditPolicyDetailsObjectInStore,
	setCreatePolicyDetails,
	setPolicyCategoriesSelectedForEdit,
} from '../../../actions/governance/IamAction'
import { Spinner } from 'reactstrap'

class ViewSidePanel extends Component {
	constructor(props) {
		super(props)
		this.state = {
			activeTab: 0,
		}
	}

	getAttachmentCount = () => {
		let count = 0
		if (
			this.props.selectedPolicyTableRow.attached_to_identities &&
			this.props.selectedPolicyTableRow.attached_to_identities.users
		) {
			count = count + this.props.selectedPolicyTableRow.attached_to_identities.users.length
		}
		if (
			this.props.selectedPolicyTableRow.attached_to_identities &&
			this.props.selectedPolicyTableRow.attached_to_identities.roles
		) {
			count = count + this.props.selectedPolicyTableRow.attached_to_identities.roles.length
		}
		return count
	}
	setValuesInStoreForEdit = selected => {
		let iam_identities = {}
		iam_identities.roles = selected.attached_to_identities.roles
		iam_identities.users = selected.attached_to_identities.users
		this.props.setCreatePolicyDetails('iam_identities', iam_identities)
		this.props.setPolicyCategoriesSelectedForEdit(JSON.parse(selected.policy_generation_request).statements_to_generate)
		this.props.setCreatePolicyDetails('policy_arn', this.props.selectedPolicyTableRow.policy_details.Arn)
		this.props.setEditPolicyDetailsObjectInStore(JSON.parse(selected.policy_generation_request))
	}

	render() {
		const policy_generation_request = JSON.parse(this.props.selectedPolicyTableRow.policy_generation_request)
		return (
			<div className='advanced-search'>
				<div className='search-content'>
					<div className='header-search flex-column bd-highlight'>
						<div className='w-100 d-flex'>
							<div className='flex-fill bd-highlight'>
								<h5>Policy ARN: {this.props.selectedPolicyTableRow.policy_details.Arn}</h5>
							</div>
							<div className='text-right flex-fill bd-highlight'>
								<i className='far fa-times cursor-pointer' onClick={() => this.props.closeViewSidePanel()} />
							</div>
						</div>
						<div className='w-100 small d-flex'>
							<div className='mr-3'>
								<p className='m-0'>
									Default VersionId: {this.props.selectedPolicyTableRow.policy_details.DefaultVersionId}
								</p>
								<p className='m-0'>No of entities using the policy: {this.getAttachmentCount()}</p>
							</div>
							<div>
								<p className='m-0'>Policy is attached to the following resources:</p>
								<p className='m-0'>
									Users:{' '}
									{this.props.selectedPolicyTableRow.attached_to_identities.users.map(user => {
										return (
											<span key={user} className='mr-2'>
												{user}
											</span>
										)
									})}
								</p>
							</div>
						</div>
					</div>
					<div className='container-fluid p-0 overflow-auto'>
						<ul className='nav nav-tabs bd-highlight' id='myTab' role='tablist'>
							<li className='nav-item'>
								<a
									className={`nav-link ${this.state.activeTab === 0 ? 'active' : ''}`}
									onClick={() => this.setState({ activeTab: 0 })}
									href={() => false}
								>
									Policy Document
								</a>
							</li>
							<li className='nav-item'>
								<a
									className={`nav-link ${this.state.activeTab === 1 ? 'active' : ''}`}
									onClick={() => this.setState({ activeTab: 1 })}
									href={() => false}
								>
									Policy Details
								</a>
							</li>
						</ul>
						<div className='d-flex justify-content-end mt-2'>
							<button
								className='btn btn-sm btn-primary mr-2'
								onClick={() => {
									this.props.history.push(URL_PATH.GOVERNANCE_EDIT_POLICIES)
									this.setValuesInStoreForEdit(this.props.selectedPolicyTableRow)
								}}
							>
								Edit
							</button>
							<button
								disabled={this.props.showDeleteLoading}
								className='btn btn-sm btn-danger mr-2'
								onClick={() => this.props.onClickDelete(this.props.selectedPolicyTableRow.policy_details.Arn)}
							>
								Delete
								{this.props.showDeleteLoading ? <Spinner size='sm' className='ml-2' color='dark' /> : null}
							</button>
						</div>
						<div className='tab-content p-2' id='myTabContent'>
							{this.state.activeTab === 0 && (
								<div className='tab-pane fade show active p-3'>
									<pre>
										{JSON.stringify(JSON.parse(this.props.selectedPolicyTableRow.policy_document), undefined, 2)}
									</pre>
								</div>
							)}
							{this.state.activeTab === 1 && (
								<div className='tab-pane fade show active p-3 table-responsive'>
									{policy_generation_request &&
										policy_generation_request.statements_to_generate.map((statement, i) => {
											return (
												<div key={i}>
													<div className='flex-fill bd-highlight'>
														<h6>Service: {statement.service_name}</h6>
													</div>
													<p className='text-blue'>Functionalities:</p>
													{statement.categories &&
														statement.categories.map((category, i) => {
															return (
																<div key={i}>
																	<div className='flex-fill bd-highlight'>
																		<h6>{category.category}</h6>
																	</div>
																	<p className='text-blue'>Usecases Enabled:</p>
																	{category.usecases &&
																		category.usecases.map((usecase, i) => {
																			return <p key={i}>- {usecase.usecase}</p>
																		})}
																</div>
															)
														})}
													{statement.condition_to_check.global.length + statement.condition_to_check.specific.length >
														0 && <p className='text-blue'>Conditions Enabled:</p>}
													{statement.condition_to_check.specific &&
														statement.condition_to_check.specific.map((condition, i) => {
															return <p key={i}> - {condition.name.replace('Enable ', '').replace(' Condition', '')}</p>
														})}
													{statement.condition_to_check.global &&
														statement.condition_to_check.global.map((condition, i) => {
															return <p key={i}>- {condition.name.replace('Enable ', '').replace(' Condition', '')}</p>
														})}

													{statement.condition_to_check.ResourceTags.length > 0 && (
														<p className='text-12 px-8 text-blue'>Resource Tags to which policy applies:</p>
													)}
													{statement.condition_to_check.ResourceTags &&
														statement.condition_to_check.ResourceTags.map((tag, i) => {
															return (
																<div className='' key={i}>
																	<p className='text-10'>{typeof tag === 'string' ? tag : tag.key + ':' + tag.value}</p>
																</div>
															)
														})}
													{statement.condition_to_check.RequestTags.length > 0 && (
														<p className='text-12 px-8 text-blue'>Tags that can be created for new resources:</p>
													)}
													{statement.condition_to_check.RequestTags &&
														statement.condition_to_check.RequestTags.map((tag, i) => {
															return (
																<div key={i}>
																	<p className='text-10'>{tag.key + ':' + tag.value} </p>
																</div>
															)
														})}
													{statement.condition_to_check.TagKeys.length > 0 && (
														<p className='text-12 px-8 text-blue'>Tags that can be used:</p>
													)}
													{statement.condition_to_check.TagKeys &&
														statement.condition_to_check.TagKeys.map(tag => {
															return (
																<div className='px-32' key={i}>
																	<p className='text-10'>{tag}</p>
																</div>
															)
														})}
												</div>
											)
										})}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ViewSidePanel.propTypes = {
	closeViewSidePanel: PropTypes.func,
	selectedPolicyTableRow: PropTypes.object,
	setEditPolicyDetailsObjectInStore: PropTypes.func,
	setCreatePolicyDetails: PropTypes.func,
	onClickDelete: PropTypes.func,
	setPolicyCategoriesSelectedForEdit: PropTypes.func,
	showDeleteLoading: PropTypes.bool,
	history: PropTypes.array,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		createPolicyDetails: state.iam.createPolicyDetails,
		selectedPolicyTableRow: state.governance.selectedPolicyTableRow,
	}
}

export default connect(mapStateToProps, {
	setEditPolicyDetailsObjectInStore,
	setPolicyCategoriesSelectedForEdit,
	setCreatePolicyDetails,
})(withRouter(ViewSidePanel))
