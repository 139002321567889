/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ChangesTab.js
 * @author Prakash // on 10/06/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { listChangeEvents } from '../../../actions/aiops/ObservabilityAction'

import _ from 'lodash'
import { CSVLink } from "react-csv";
import { momentDateGivenFormat, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord } from '../../../utils/utility'

import Search from '../../common/SearchComponent'

class ChangesTab extends Component {
    constructor(props) {
        super(props)
        this.toggleRef = React.createRef()
        
        this.state = {
            changesListLoader: true,
            
            startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
			perPage: 100,
        }
    }

	componentDidMount = () => {
        if(this.props.leftSectionSelection && Object.entries(this.props.leftSectionSelection).length) {
            this.setState({ 
                start_time: this.props.leftSectionSelection.start_time,
                end_time: this.props.leftSectionSelection.end_time,
                brushStartTime: this.props.leftSectionSelection.brushStartTime ? this.props.leftSectionSelection.brushStartTime : this.props.leftSectionSelection.start_time,
                brushEndTime: this.props.leftSectionSelection.brushEndTime ? this.props.leftSectionSelection.brushEndTime : this.props.leftSectionSelection.end_time,
                selectedShowBy: this.props.leftSectionSelection.selectedShowBy ? this.props.leftSectionSelection.selectedShowBy : "",
                columnLabel: this.props.leftSectionSelection.columnLabel ? this.props.leftSectionSelection.columnLabel : "",
                columnValue: this.props.leftSectionSelection.columnValue ? this.props.leftSectionSelection.columnValue : "",
                selectedField: this.props.leftSectionSelection.selectedField ? this.props.leftSectionSelection.selectedField : "",
                allFields: this.props.leftSectionSelection.allFields ? this.props.leftSectionSelection.allFields : "",
                pageType: this.props.leftSectionSelection.pageType ? this.props.leftSectionSelection.pageType : "",
                changesListLoader: true,
                graphData: {}
            },
                () => {
                    this.listChangeEvents()
                    this.hideLoading()
                }
            )
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.leftSectionSelection && Object.entries(this.props.leftSectionSelection).length) {
            if(!_.isEqual(prevProps.leftSectionSelection, this.props.leftSectionSelection)) {
                this.setState({ 
                    start_time: this.props.leftSectionSelection.start_time,
                    end_time: this.props.leftSectionSelection.end_time,
                    brushStartTime: this.props.leftSectionSelection.brushStartTime ? this.props.leftSectionSelection.brushStartTime : this.props.leftSectionSelection.start_time,
                    brushEndTime: this.props.leftSectionSelection.brushEndTime ? this.props.leftSectionSelection.brushEndTime : this.props.leftSectionSelection.end_time,
                    selectedShowBy: this.props.leftSectionSelection.selectedShowBy ? this.props.leftSectionSelection.selectedShowBy : "",
                    columnLabel: this.props.leftSectionSelection.columnLabel ? this.props.leftSectionSelection.columnLabel : "",
                    columnValue: this.props.leftSectionSelection.columnValue ? this.props.leftSectionSelection.columnValue : "",
                    selectedField: this.props.leftSectionSelection.selectedField ? this.props.leftSectionSelection.selectedField : "",
                    allFields: this.props.leftSectionSelection.allFields ? this.props.leftSectionSelection.allFields : "",
                    changesListLoader: true,
                    graphData: {},
                },
                    () => {
                        this.listChangeEvents()
                        this.hideLoading()
                    }
                )
            } else if(this.props.dashboardDetails && Object.entries(this.props.dashboardDetails).length) {
                if(!_.isEqual(prevProps.dashboardDetails, this.props.dashboardDetails)) {
                    this.setState({ 
                        start_time: this.props.dashboardDetails.start_time,
                        end_time: this.props.dashboardDetails.end_time,
                        brushStartTime: this.props.dashboardDetails.brushStartTime ? this.props.dashboardDetails.brushStartTime : this.props.dashboardDetails.start_time,
                        brushEndTime: this.props.dashboardDetails.brushEndTime ? this.props.dashboardDetails.brushEndTime : this.props.dashboardDetails.end_time,                        
                        changesListLoader: true,
                    },
                        () => {
                            if(this.state.columnValue) {
                                this.listChangeEvents()
                                this.hideLoading()
                            }
                        }
                    )
                }
            }
        } else if(!_.isEqual(prevProps.leftSectionSelection, this.props.leftSectionSelection)) {
            this.setState({ 
                columnValue: "",
                changesListLoader: true
            },
                () => {
                    this.listChangeEvents()
                }
            )
        }
    }

    navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = this.state.startRecord
            if(action === 'next' && currentPage !== this.state.totalPages) {
                startRecord = startRecord + this.state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - this.state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = this.state.totalPages
                startRecord = (this.state.totalPages - 1) * this.state.perPage
            }
            this.setState({ currentPage, startRecord })
        } 
	}

    gotoPage = (pageNumber) => {
        if(pageNumber > 0  && pageNumber <= this.state.totalPages) {
            let startRecord = (pageNumber - 1) * this.state.perPage
            this.setState({ currentPage: pageNumber, pageNumber, startRecord })
        } else  if(pageNumber > this.state.totalPages) {
            this.setState({ pageNumber: this.state.currentPage })  
        } else {
            this.setState({ pageNumber })
        }
    }

    listChangeEvents = () => {
        
        let params = {}
        params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:00')
        params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:00')
        if(this.state.selectedShowBy === "Assets") {
            params.asset_arn = this.state.columnValue
        } else {
            params.resource_type = this.state.columnValue
        }

        if(this.state.selectedField !== "") {
            params.change_nature = this.state.selectedField
        } else {
            // params.change_nature = this.state.allFields
        }
        
        if(this.state.columnValue && this.state.columnValue !== "") {
            this.props.listChangeEvents(params, (promise, response) => {
                if (promise) {
                    let results = response && response.length ? response : []
                    
                    let totalPages = 1
                    if(results.length > this.state.perPage) {
                        totalPages = Math.ceil(results.length / this.state.perPage)
                    }
    
                    this.setState({ changeResults: results, totalFilteredArray: results, filteredArray: results, changesListLoader: false, totalPages, startRecord: 0, currentPage: 1 },
                        () => {                            
                            this.formHeaders()
                        }    
                    )
                } else {
                    this.setState({ changeResults: [], totalFilteredArray: [], filteredArray: [], changesListLoader: false })
                }
            })
        } else {
            this.setState({ changeResults: [], totalFilteredArray: [], filteredArray: [], changesListLoader: false })
        }
    }

	formHeaders = () => {
		let dataResult = this.state.filteredArray && this.state.filteredArray.length ? this.state.filteredArray : []
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === "string") {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})
			this.setState({ headers })
		}
	}

    hideLoading = () => {
        setTimeout(() => { this.setState({ changesListLoader: false }) }, 5000)
    }

	render() {
		return (
            <div className="">
                <div className="d-flex mt-2">
                    <div className="rounded bg-dark p-0 col-sm-12">
                        {this.state.totalFilteredArray && this.state.totalFilteredArray.length ?
                            <div className="d-flex mb-2 justify-content-between">
                                <div className="d-flex mr-2">
                                    <p className="f12 m-0 align-self-center pt-1">
                                        <span>Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.state.totalFilteredArray && this.state.totalFilteredArray.length} event(s) {this.state.selectedField && this.state.selectedField !== "" ? (<span> with {this.state.selectedField} </span>) : ""} between {this.state.columnLabel ? this.state.columnLabel : ""} </span>
                                        <span className="f12 m-0 align-self-center pt-1 text-info ml-1">{this.state.brushStartTime ? momentDateGivenFormat(this.state.brushStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.brushEndTime, 'DD MMM YYYY HH:mm') : ""}</span>
                                    </p>
                                </div>
                                <div className="d-flex">
                                    <CSVLink 
                                        data={this.state.filteredArray ? this.state.filteredArray : []} 
                                        headers={this.state.headers ? this.state.headers : []}
                                        filename={"resource-alerts.csv"}
                                        className={"mt-2"}
                                        target="_blank"
                                    >
                                        <span className="d-flex text-primary-color mr-2">
                                            <i className="fas fa-download text-primary-color mr-2"></i>
                                            {/* csv */}
                                        </span>
                                    </CSVLink>
                                    <div className="justify-content-end w-100 mb-n2">
                                        <Search
                                            data={this.state.totalFilteredArray ? this.state.totalFilteredArray : []}
                                            applyTags={false}
                                            applyLiteDarkTags={true}
                                            topClassName={'bg-black5 align-self-center w-100 mr-1 border-gray5 rounded-5 f12'}
                                            searchClassName={'px-2 f12'}
                                            searchIconColor={'text-gray5 f12'}
                                            searchPlaceHolder={'Search....'}
                                            className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                            filteredData={(filteredArray) => this.setState({ filteredArray },
                                                () => {
                                                    if(this.state.filteredArray.length) {
                                                        // this.getAlertDetails(this.state.filteredArray[0])
                                                    }
                                                }
                                            )}
                                        />
                                        {this.state.filteredArray && this.state.filteredArray.length > this.state.perPage ?
                                            <div className="pagination errorPagePagination f12">
                                                <span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
                                                <button><i className={`fal fa-arrow-to-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('start', this.state.currentPage)}></i></button> 
                                                <button><i className={`fal fa-angle-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('previous', this.state.currentPage)}></i></button> 
                                                {/* <span>
                                                    Go to
                                                    <input
                                                        type='number'
                                                        value={this.state.pageNumber || this.state.pageNumber === "" ? this.state.pageNumber : this.state.currentPage}
                                                        onChange={e => {
                                                            const page = e.target.value ? Number(e.target.value) : ""
                                                            this.gotoPage(page)
                                                        }}
                                                        className="ml-1 inputClass"
                                                    />
                                                </span> */}
                                                <button><i className={`fal fa-angle-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
                                                <button><i className={`fal fa-arrow-to-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
                                            </div>
                                        : null}
                                    </div>
                                </div>
                            </div>
                        : null}
                        
                        <div className="d-flex">
                            <div className={`w-100`}>
                                <div className={`bg-dark3 py-3 pl-2 obsMessageSectionXL`}>
                                    {this.state.changesListLoader ? 
                                        <div className='d-flex justify-content-center m-4'>
                                            <Spinner className='text-center' color='white' size='lg' />
                                        </div>
                                    : this.state.filteredArray && this.state.filteredArray.length ?
                                        this.state.filteredArray.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map((item, i) => {
                                            return(
                                                <React.Fragment>
                                                <div className={`px-2 mb-2 pb-1 border-bottom-black5 ${this.state.selectedEvent && this.state.selectedEvent.event_id === item.event_id ? "bg-muted" : ""}`}> 
                                                    <div className="d-flex cursorPointer" onClick={() => this.setState({ selectedEvent: item })}>
                                                        <p className="mb-0">
                                                            <span className="mb-0 mr-2 text-white f12 font-weight-bold text-primary-color">{item.event_time ? momentConvertionUtcToLocalTime(item.event_time, 'DD MMM YYYY HH:mm') : ""} </span>
                                                            {/* <span className="f12 mb-0 mr-2 text-purple-2"> {item.total_alerts+ (item.total_alerts>1 ?' alerts':' alert')}</span>  */}
                                                            <span className={`mb-0 mr-2 f12 text-purple-2`}>
                                                                {item.event_source ? (Array.isArray(item.event_source) ? item.event_source.join(", ") : item.event_source) : ""}
                                                                {item.event_name ? item.event_name : ""}
                                                                {/* {item.event_type ? ' : '+ item.event_type : ""} */}
                                                            </span>
                                                            {item.assets && item.assets.length ? 
                                                                <React.Fragment>
                                                                {item.assets[0].asset_name ?
                                                                    <span className="mb-0 mr-2 f12 text-white font-weight-bold">{item.assets[0].asset_name}</span>
                                                                : null}
                                                                <span className="mb-0 small mr-1 text-primary-color">Resource</span>
                                                                {item.assets[0].region ?
                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold">{'  '+item.assets[0].region}</span>
                                                                : item.region ?
                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold">{'  '+item.region}</span>
                                                                : null}
                                                                {item.assets[0].account_id ?
                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+item.assets[0].account_id}</span>
                                                                : null}
                                                                {item.assets[0].resource_type ?
                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+item.assets[0].resource_type}</span>
                                                                : null}
                                                                {item.assets[0].service_name ?
                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold mr-2">{' : '+item.assets[0].service_name}</span>
                                                                : null}
                                                                </React.Fragment>
                                                            : 
                                                                item.region ?
                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold">{item.region}</span>
                                                                : null
                                                            }
                                                            {/* {item.additional_details && item.additional_details.message ?
                                                                <span className="mb-0 f12 text-white font-weight-bold mr-2">{item.additional_details.message}</span>
                                                            : null}  */}
                                                            {item.description ?
                                                                <span className="mb-0 f12 text-white font-weight-bold mr-2">{item.description}</span> 
                                                            : null}
                                                        </p>
                                                    </div>
                                                </div>
                                                </React.Fragment>
                                            )
                                        })
                                    :
                                        <div className='d-flex justify-content-center m-4'>
                                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ChangesTab.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('errorDashboard',state)
	return {
        leftSectionSelection: state.assets.resourcesPropsDetails.leftSectionSelection ? state.assets.resourcesPropsDetails.leftSectionSelection : {},
        dashboardDetails: state.assets.resourcesPropsDetails && state.assets.resourcesPropsDetails.dashboardDetails ? state.assets.resourcesPropsDetails.dashboardDetails : {},
    }
}

export default connect(mapStateToProps, {
    listChangeEvents,
})(withRouter(ChangesTab))