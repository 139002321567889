/*************************************************
 * Tvastar
 * @exports
 * @file Sidebar.js
 * @author Rajasekar // on 02/01/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import store from 'store'
import { Link } from 'react-router-dom'

import { URL_PATH } from '../../config/urlPath'
import { STORE } from '../../utils/constants'
import { getUserMenu } from '../../actions/commonAction'

// import profilePicture from '../../assets/images/profilePicture.jpg'

let icons = [
	{label: "Automations", value: "fas fa-magic"},
	{label: "Observability", value: "fas fa-binoculars"}, 
	{label: "Cases", value: "fab fa-ethereum"}, 
	{label: "Resources", value: "fal fa-landmark"}, 
	{label: "Checks", value: "fal fa-album-collection"}, 
	{label: "Diagnostics", value: "fal fa-border-none"},
	{label: "Policy", value: "fal fa-columns"},
	{label: "Cost", value: "fas fa-sack-dollar"}
	// , "fal fa-columns", "fal fa-border-none"]
]

const userName = typeof store.get(STORE.NAME) === 'object' ? store.get(STORE.NAME).username : ''
const firstLetter =
	typeof store.get(STORE.NAME) === 'object' ? store.get(STORE.NAME).username && store.get(STORE.NAME).username[0] : ''

class Sidebar extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showSidebar: false
		}
	}

	componentDidMount = () => {

		if(!Object.entries(this.props.userMenu).length) {
			this.props.getUserMenu((promise, response) => {
				if (promise) {
					this.setState({ menuList: response }	)
				} else {
					this.setState({ menuList: [] })
				}
			})
		} else {
			this.setState({ menuList: this.props.userMenu })
		}

		// this.availbaleLinks()
	}

	availbaleLinks = () => {
		let governancePages = [
			URL_PATH.GOVERNANCE_RULES,
			URL_PATH.GOVERNANCE_DASHBOARD,
			URL_PATH.GOVERNANCE_TAGGING,
			URL_PATH.GOVERNANCE_TAG_GROUPS,
			URL_PATH.GOVERNANCE_EDIT_TAG_GROUPS,
			URL_PATH.GOVERNANCE_CREATE_TAG_GROUPS,
			URL_PATH.GOVERNANCE_APPLIED_ASSETS,
			URL_PATH.GOVERNANCE_LIST_POLICIES,
			URL_PATH.GOVERNANCE_CREATE_POLICIES,
			URL_PATH.GOVERNANCE_EDIT_POLICIES,
			URL_PATH.GOVERNANCE_CONFIGURATION,
			URL_PATH.GOVERNANCE_CREATE_RULE,
			URL_PATH.GOVERNANCE_EDIT_RULE,
		]

		let checksPages = [
			URL_PATH.CHECKS_DASHBOARD,
			URL_PATH.COMPLIANCE_DASHBOARD,
			URL_PATH.CHECKS_DETAILS,
			URL_PATH.CHECKS_SEARCH,
			URL_PATH.CHECKS_SUPPRESSOR,
			URL_PATH.CHECKS_CONFIGURATION,
		]

		let assetsPages = [
			URL_PATH.RESOURCES_DASHBOARD_OLD,
			URL_PATH.RESOURCES_SEARCH,
			URL_PATH.RESOURCE_COMPARISON,
			URL_PATH.RESOURCES_DETAILS,
			URL_PATH.RESOURCES_DASHBOARD,
			URL_PATH.RESOURCES_LANDING_PAGE,
		]
		
		let insightsPages = [
			URL_PATH.USER_INSIGHTS
		]		

		let dlpPages = [
			URL_PATH.DLP_DASHBOARD,
			URL_PATH.DLP_THREATS,
			URL_PATH.DLP_UEBA,
			URL_PATH.DLP_COMPLIANCE,
			URL_PATH.DLP_TREND,
			URL_PATH.DLP_PROFILE,
			URL_PATH.DLP_REPORTS,
			URL_PATH.DLP_MALWARE,
			URL_PATH.DLP_CONFIGURATION,
			URL_PATH.DLP_POLICY,
			URL_PATH.DLP_JOBS,
			URL_PATH.DLP_QUERY,
		]

		let automationPages = [
			URL_PATH.AUTOMATIONS_DASHBOARD,
			URL_PATH.LIST_AUTOMATION,
			URL_PATH.CREATE_AUTOMATION,
			URL_PATH.EDIT_AUTOMATION,
			URL_PATH.AUTOMATION_DETAILS,
		]

		let aiopsPages = [
			URL_PATH.AIOPS_DASHBOARD,
			URL_PATH.AIOPS_CASE_DETAILS
		]

		let observabilityPages = [
			URL_PATH.OBSERVABILITY_ASSETS,
			URL_PATH.OBSERVABILITY_EVENTS,
		]

		let diagnosticPages = [
			URL_PATH.LIST_DIAGNOSTICS,
			URL_PATH.DIAGNOSTICS_DETAILS,
		]

		this.setState({ dlpPages, automationPages, insightsPages, assetsPages, checksPages, governancePages, aiopsPages, observabilityPages, diagnosticPages })
	}


	handleClick = () => {
		document.addEventListener('mouseOver', () => this.onClick());
	}

	onClick = () => {
		this.setState({ close: false })
	}
	
	/**
	 * Renders sidebar of the screen
	 */
	render() {
		return (
			<div id='navigation' className={`navigation ${this.state.showSidebar ? '' : 'nav-collapsed'}`} onMouseOver={this.handleClick}>
				<div className='nav-content'>
					<div className='nav-header'>
						<div className='collapse-nav' onClick={() => this.setState({ showSidebar: !this.state.showSidebar })}>
							<div className='open-nav'>
								<i className='fal fa-align-left' />
							</div>
						</div>
						<div className='logo'>
							<Link to=''>
								<img src='' alt='' />
							</Link>
						</div>
						<div className='user-caption'>{firstLetter && firstLetter.toUpperCase()}</div>
						<p className='text-white text-center mt-2 mb-0'>{userName}</p>
					</div>
					
					<div className='main-nav'>
						<nav className='nav flex-column'>
							
							{this.props.userMenu && this.props.userMenu.menu && this.props.userMenu.menu.map((item, index) => {
								return (
									<Link
										key={"parent_index_"+index}
										className='nav-link dropdown-toggle'
										to={(!item.submenulist || (item.submenulist && !item.submenulist.length)) ? item.link : ""}
										onClick={event => {
											if(item.submenulist && item.submenulist.length) {
												event.preventDefault()
												this.setState({ ['show_menu_'+index]: !this.state['show_menu_'+index] })
											} else {
												this.setState({ activeMenu: item.menu })
											}
										}}
									>
										<i className={`${icons.filter(e => e.label === item.menu).length ? icons.filter(e => e.label === item.menu)[0].value : "fal fa-search"} ${(window.location.pathname === item.link || item.submenulist && item.submenulist.filter(e => e.link === window.location.pathname).length) ? ' text-info' : ' text-primary-color'} fs-15 mr-2`} />

										<span className={`nav-text ${window.location.pathname === item.link && (!item.submenulist || (item.submenulist && !item.submenulist.length)) ? 'text-info' : ''}`}>{item.menu}</span>
										<div className={`dropdown-menu ${this.state['show_menu_'+index] ? 'show' : ''}`}>
											{item.submenulist && item.submenulist.length ? 
												item.submenulist.map((sub, i) => {
													return(
														<Link
															key={"child_index_"+i}
															className={`dropdown-item ${(sub.link === window.location.pathname) ? 'text-info' : ''}`}
															to={sub.link}
															// onClick={() => this.setState({ activeMenu: item.menu }) }
														>
															<span className='nav-text'>{sub.submenu}</span>
														</Link>
													)
												})
											: null}
										</div>
									</Link>
								)
							})}
						</nav>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('sidebar state', state)
	return {
		userMenu: state.filters.userMenu,
	}
}

export default connect(mapStateToProps, {
	getUserMenu
})(withRouter(Sidebar))
