/*************************************************
 * Tvastar
 * @exports
 * @file CreateDiagnostics.js
 * @author Prakash // on 18/01/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getRegionName, getAccountNameFromId, momentDateGivenFormat, currentLocaltime, momentConvertionLocalToUtcTime, twoDateDiffrence } from '../../../utils/utility'
import { HOURLY_DURATION } from '../../../utils/constants'

import { listAllProviders, listAllAccounts, listAllRegions } from '../../../actions/commonAction'
import { startDiagnostics, listDiagnosticChecks, saveDiagnosticTemplate, listDiagnosticTemplate } from '../../../actions/aiops/DiagnosticsAction'

import { Spinner, Input, UncontrolledTooltip } from 'reactstrap'

import Select from 'react-select'

import { store as CommonNotification } from 'react-notifications-component';

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";

// import moment from 'moment'
// const today = moment();
// const disableFutureDt = current => {
//   return current.isBefore(today)
// }
const disableFutureDt = current => {
	return current.isBefore(currentLocaltime())
}

const startInputProps = {
    placeholder: 'Start Datetime',
    className: 'form-control bg-black5 text-white border-0',
    readOnly: true,
};

const endInputProps = {
    placeholder: 'End Datetime',
    className: 'form-control bg-black5 text-white border-0',
    readOnly: true,
};

class CreateDiagnostics extends Component {
	proEnvWrap = React.createRef()
	constructor(props) {
		super(props)
		this.props = props;
		
		this.createAccountRef = React.createRef()
		this.createRegionRef = React.createRef()

		this.state = {
			showLoading: true,

            selectedProvider: "",
            selectedAccount: "",
            selectedRegion: "",

			tagsArray: [0],
			selectedTags: [],
			allChecks: true,
		}

		this.handleStartTimeChange  = this.handleStartTimeChange.bind(this)
        this.handleEndTimeChange    = this.handleEndTimeChange.bind(this)
	}

	componentDidMount = () => {
		this.listDiagnosticChecks()
        if(this.props.providers && this.props.providers.length) {
			this.setState({ providers: this.props.providers, selectedProvider: this.props.providers[0].provider_name },
				() => {
					this.getFilterData("provider")
				}
			)
		} else {
			this.props.listAllProviders((promise, providers) => {
				if (promise) {
					this.setState({ providers, selectedProvider: providers[0].provider_name },
						() => {
							this.getFilterData("provider")
						}
					)
				} else {
					this.setState(
						{ providers: [],  }, 
						() => {
							this.getFilterData()
						}
					)
				}
			})
		}	
	}
	/**
	 * Filter identifiers based on the filter selection
	 */
	getFilterData = () => {
		let params = {}
		if (this.state.selectedProvider) {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		
        if(this.props.accounts && this.props.accounts.length) {
			let allAccounts = this.props.accounts.filter(e => e.provider === this.state.selectedProvider.toLowerCase())
			let selectedAccount = allAccounts && allAccounts.length === 1 ? allAccounts[0].account_id : ""
			this.setState({ accounts: allAccounts, selectedAccount })
		} else {
			this.props.listAllAccounts(params, (promise, allAccounts) => {
				if (promise) {						
					let filterdAccounts = allAccounts.filter(e => e.provider === this.state.selectedProvider.toLowerCase())
					let selectedAccount = filterdAccounts && filterdAccounts.length === 1 ? filterdAccounts[0].account_id : ""
					this.setState({ accounts: filterdAccounts, selectedAccount })
				}
			})
		}

		if(this.props.regions && this.props.regions.length) {
			let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
			let selectedRegion = providerRegions && providerRegions.length === 1 ? providerRegions[0].region: ""
			this.setState({ regions: providerRegions, selectedRegion })
		} else {
			this.props.listAllRegions(params, (promise, regions) => {
				if (promise) {
					let providerRegions = regions && regions[0] && regions[0].provider_regions
					let selectedRegion = providerRegions && providerRegions.length === 1 ? providerRegions[0].region: ""
					this.setState({ regions: providerRegions, selectedRegion })
				} else {
					this.setState({ regions: [], selectedRegion: [] })
				}
			})
		}
	}	

	listDiagnosticChecks = () => {
		this.props.listDiagnosticChecks((promise, response) => {
			if (promise) {
				this.setState({ diagnostiChecks: response })
			} else {
				this.setState({ diagnostiChecks: [] })
			}
		})
	}

	handleClickOutside = (event) => {

		// if (this.createRegionRef && !this.createRegionRef.current.contains(event.target)) {
		// 	this.setState({ isRegionOpen: false })
		// } else {
		// 	this.setState({ isRegionOpen: true })
		// }
	}

	addSection = () => {
        let rowList = this.state.tagsArray;
        if(this.state.tagsArray) {
            let value = this.state.tagsArray[this.state.tagsArray.length - 1]
            value = value+1
            rowList.push(value);
        }
        
        this.setState({tagsArray: rowList })
    }

    removeSection = (item, i) => {
        this.setState({ ["tag_key_"+item]: "", ["tag_value_"+item]: "" },
            () => {
                let rowList = this.state.tagsArray;
                rowList.splice(i, 1);
                this.setState({ tagsArray: rowList })
            }
        );
    }

	onChangeEvent = (index, type) => {
		let dataRow = {}
		dataRow.key = this.state["tag_key_"+index] ? this.state["tag_key_"+index] : ""
		dataRow.value = this.state["tag_value_"+index] ? this.state["tag_value_"+index] : ""
		
		let tags = this.state.selectedTags ? this.state.selectedTags : []
		// tags.push(dataRow)

		if(tags.length && tags.length-1 >= index) {
			tags[index] = dataRow
		} else {
            tags.push(dataRow)
        }

		this.setState({ selectedTags: tags })
	}

	startDiagnostics = () => {
		let params = {}
		params.provider = this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider.toLowerCase() : ""
		params.account_id = this.state.selectedAccount ? this.state.selectedAccount : ""
		params.region = this.state.selectedRegion ? this.state.selectedRegion : ""
		params.tags = this.state.selectedTags ? this.state.selectedTags : []
		params.description = this.state.description ? this.state.description : ""
		params.name = this.state.name ? this.state.name : ""
		if(this.state.selectedDuration === "custom") {
			params.start_time = this.state.start_time ?  momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:00') : ""
			params.end_time = this.state.end_time ? momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:00') : ""
		} else {
			params.duration = this.state.selectedDuration ? this.state.selectedDuration : ""
		}

		let checks = []
		if(this.state.selectedDiagnoticChecks && this.state.selectedDiagnoticChecks.length) {
			checks = this.state.selectedDiagnoticChecks
		}

		params.checks = checks

		let hasError = false
		if(params.name === "" || params.provider === "" || params.account_id === "" || params.region === "" || !params.tags.length || !this.state.selectedDuration) {
			hasError = true
		}

		if(!hasError) {
			this.state.selectedTags.forEach(tag => {
				if(tag.key === "" || tag.value === "") {
					hasError = false
				}
			})
		}

		if(this.state.selectedDuration === "custom") {
			if(params.start_time === "" || params.end_time === "") {
				hasError = true
			} else if(this.state.hasDateError) {
				hasError = this.state.hasDateError
			}
		}
		if(!hasError) {
			this.props.startDiagnostics(params, (promise, response) => {
				let messageType = ""
				if(promise) {
					messageType = "success"
					if(!response.status) {
						messageType = "danger"
					}
					this.setState({ runDiagnosticsLoading: false })
				} else {
					messageType = "danger"	
					this.setState({ runDiagnosticsLoading: false })
				}

                let message = response && response.message ? response.message : ""
				let diagnosticId = response.result && response.result.diagnostic_id ? response.result.diagnostic_id : ""
				if(messageType === "success") {
					message = 'Diagnostics submitted successfully. Diagnostics Id: '+diagnosticId
				}
                CommonNotification.addNotification({
                    message: message,
                    type: messageType,
                    insert: "top",
                    container: "top-center",
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                });

				if(messageType === "success") {
					this.props.callListApi()
				}
			})
		} else {
			this.setState({ hasError, runDiagnosticsLoading: false })
		}
	}

	saveDiagnosticTemplate = () => {
		let params = {}
		params.provider = this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider.toLowerCase() : ""
		params.account_id = this.state.selectedAccount ? this.state.selectedAccount : ""
		params.region = this.state.selectedRegion ? this.state.selectedRegion : ""
		params.tags = this.state.selectedTags ? this.state.selectedTags : []
		params.description = this.state.description ? this.state.description : ""
		params.name = this.state.name ? this.state.name : ""
		if(this.state.selectedDuration === "custom") {
			params.start_time = this.state.start_time ?  momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:00') : ""
			params.end_time = this.state.end_time ? momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:00') : ""
		} else {
			params.duration = this.state.selectedDuration ? this.state.selectedDuration : ""
		}

		let checks = []
		if(this.state.selectedDiagnoticChecks && this.state.selectedDiagnoticChecks.length) {
			checks = this.state.selectedDiagnoticChecks
		}

		params.checks = checks

		let hasError = false
		if(params.name === "" || params.provider === "" || params.account_id === "" || params.region === "" || !params.tags.length || !this.state.selectedDuration) {
			hasError = true
		}

		if(!hasError) {
			this.state.selectedTags.forEach(tag => {
				if(tag.key === "" || tag.value === "") {
					hasError = false
				}
			})
		}

		if(this.state.selectedDuration === "custom") {
			if(params.start_time === "" || params.end_time === "") {
				hasError = true
			} else if(this.state.hasDateError) {
				hasError = this.state.hasDateError
			}
		}
		
		if(!hasError) {
			this.props.saveDiagnosticTemplate(params, (promise, response) => {
				let messageType = ""
				if(promise) {
					messageType = "success"
					if(!response.status) {
						messageType = "danger"
					}
					this.setState({ saveDiagnosticsLoading: false })
				} else {
					messageType = "danger"	
					this.setState({ saveDiagnosticsLoading: false })
				}

                let message = response && response.message ? response.message : ""
				// let templateId = response.result && response.result.template_id ? response.result.template_id : ""
				if(messageType === "success") {
					message = 'Diagnostics submitted successfully.'
				}
                CommonNotification.addNotification({
                    message: message,
                    type: messageType,
                    insert: "top",
                    container: "top-center",
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                });

				if(messageType === "success") {
					let params = {}
					params.provider =this.props.automationPropsDetails.provider
					params.account_id =this.props.automationPropsDetails.account_id
					params.region =this.props.automationPropsDetails.region
					this.props.listDiagnosticTemplate(params, (promise, response) => {})
					this.props.showHelperSection()
				}
			})
		} else {
			this.setState({ hasError, saveDiagnosticsLoading: false })
		}
	}

	durationFunction = (type) => {
		if(this.state.selectedDuration !== "custom") {
			// let days = this.state.selectedDuration.replace('+', "").replace('d', "")
			// let reducedDays = days - 1
        
			// let start_time = momentDateGivenFormat(subDays(new Date(), reducedDays), 'YYYY-MM-DD 00:00:00')
			// let end_time = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:00')

			// this.setState({ start_time, end_time, showDateTimePicker: false })
			this.setState({ showDateTimePicker: false })
		} else {
			this.setState({ showDateTimePicker: true })
		}
    }
  
    handleStartTimeChange(date) {
        let start_time = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
        let end_time = this.state.end_time
		let endBoolen = false
		let message = ""
		if(this.state.end_time) {
			if(new Date(start_time).getTime() > new Date(end_time).getTime()) {
				endBoolen = true
				message = "please select valid date"
			} else if(twoDateDiffrence(end_time, start_time) >= 7) {
				endBoolen = true
				message = "please select date interval of 7 days"
			}
		}
		this.setState({ start_time, hasDateError: endBoolen, showDateisNotValid: message })
    }

    handleEndTimeChange(date) {
		let start_time = this.state.start_time
        let end_time = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
		let startBoolen = false
		let message = ""
		if(this.state.start_time) {
			if(new Date(end_time).getTime() < new Date(start_time).getTime() ) {
				startBoolen = true
				message = "please select valid date"
			} else if(twoDateDiffrence(end_time, start_time) >= 7) {
				startBoolen = true
				message = "please select date interval of 7 days"
			} 
		}
		this.setState({ end_time, hasDateError: startBoolen, showDateisNotValid: message  },
			
		)
    }

	onChangeDiagnotics = (check) => {
		let selectedDiagnoticChecks = this.state.selectedDiagnoticChecks ? this.state.selectedDiagnoticChecks : []
		if(this.state.allChecks) {
			selectedDiagnoticChecks = []
		}

		if(selectedDiagnoticChecks.length || check) {
			let idExist = selectedDiagnoticChecks.filter(e => e === check)
			if(idExist.length) {
				selectedDiagnoticChecks = selectedDiagnoticChecks.filter(e => e !== check)
			} else {
				selectedDiagnoticChecks.push(check)
			}
		}
		this.setState({ selectedDiagnoticChecks })
	}

	startDateFuntion = (state) => {
		this.setState({ [state]: false })
	}
	
	render() {		
		return (
            <div className="zapInputDark">
                <div className="d-flex justify-content-between p-3 bg-gray5 border-left">
                    <p className="mb-0 font-weight-bold text-white">{this.props.diagnosticDetails && this.props.diagnosticDetails.name ? "Edit" : "Add"} Diagnostics</p>
                    <i className="far fa-times mr-3 text-white" onClick={() => this.props.showHelperSection()}></i>
                </div>
                <div className="p-3">
                    <p className="mb-0 small">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"s standard dummy text ever since the 1500s,</p>
					<div className="mt-2">
						<div className="d-flex justify-content-between">
							<p className="mb-0 text-white">Name</p>										
							{this.state.hasError && (!this.state.name || this.state.name === "") ?
								<p className="mb-0 text-danger">required</p>
							: null}
						</div>
						<Input 
							type="text" 
							placeholder="Enter name"
							maxLength={80}
							className={`bg-muted text-white border-0`}
							value={this.state.name ? this.state.name : ""}
							onChange={e => this.setState({ name: e.target.value })}
						/>
					</div>
					<div className="mt-2 ">
							<div className="w-60">
								<div className="d-flex justify-content-between">
									<p className="b-block mb-0 text-white">Provider</p>
									{this.state.hasError && this.state.selectedProvider === "" ?
										<p className="mb-0 text-danger">required</p>
									: null}
								</div>
								<Select
									placeholder={"Select"}
									isSearchable={true}
									// menuIsOpen={true}
									className="f13 p-0 selectDarkTheme"
									value={({
										value: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : "Select",
										label: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : <span className="placeholder">Select</span>
									})}
									options={this.state.providers && this.state.providers.map(item => ({
										value: item.provider_name,
										label: item.provider_name,	
									}))}
									onChange={event =>  
										this.setState({
											selectedProvider: event.value
										},
											() => this.getFilterData()
										)
									}
								/>
							</div>
						</div>
					
					<div className="d-flex mt-2">
						<div className="py-1 w-50">
							<div className="d-flex justify-content-between">
								<p className="b-block mb-0 text-white">Account</p>
								{this.state.hasError && (!this.state.selectedAccount || this.state.selectedAccount === "") ?
									<p className="mb-0 text-danger">required</p>
								: null}
							</div>
							<Select
								placeholder={"Select"}
								isSearchable={true}
								className="f13 p-0 selectDarkTheme"
								options={this.state.accounts && this.state.accounts.map(account => ({
									value: account.account_id,
									label: account.account_name,
								}))}
								value={({
									value: this.state.selectedAccount && this.state.selectedAccount !== "" ? this.state.selectedAccount : "Select",
									label: this.state.selectedAccount && this.state.selectedAccount !== "" ? getAccountNameFromId(this.state.selectedAccount, this.state.accounts) : <span className="placeholder">Select</span>
								})}
								onChange={event =>  
									this.setState({
										selectedAccount: event.value
									})
								}
							/>
						</div>
						<div className="pl-3 py-1 w-50">
							<div className="d-flex justify-content-between">
								<p className="b-block mb-0 text-white">Region</p>
								{this.state.hasError && (!this.state.selectedRegion || this.state.selectedRegion === "") ?
									<p className="mb-0 text-danger">required</p>
								: null}
							</div>
							<Select
								placeholder={"Select"}
								isSearchable={true}
								// menuIsOpen={true}
								className="f13 p-0 selectDarkTheme"
								options={this.state.regions && this.state.regions.map(reg => ({
									value: reg.region,
									label: reg.name,
								}))}
								value={({
									value: this.state.selectedRegion && this.state.selectedRegion !== "" ? this.state.selectedRegion : "Select",
									label: this.state.selectedRegion && this.state.selectedRegion !== "" ? getRegionName(this.state.selectedRegion, this.state.regions) : <span className="placeholder">Select</span>
								})}
								onChange={event =>  
									this.setState({
										selectedRegion: event.value
									})
								}
							/>
						</div>
					</div>
					<div className="d-flex mt-2">
						<div className="py-1 w-50">
							<div className="d-flex justify-content-between">
								<p className="b-block mb-0 text-white">Duration</p>
								{this.state.hasError && (!this.state.selectedDuration || this.state.selectedDuration === "") ?
									<p className="mb-0 text-danger">required</p>
								: null}
							</div>
							<Select
								placeholder={"Select"}
								isSearchable={true}
								// menuIsOpen={true}
								className="f13 p-0 selectDarkTheme"
								options={HOURLY_DURATION.map(dur => ({
									value: dur.value,
									label: dur.option,
								}))}
								value={({
									value: this.state.selectedDuration && this.state.selectedDuration !== "" ? this.state.selectedDuration : "Select",
									label: this.state.selectedDuration && this.state.selectedDuration !== "" ? HOURLY_DURATION.filter(e => e.value === this.state.selectedDuration)[0].option : <span className="placeholder">Select</span>
								})}
								onChange={event =>  
									this.setState({
										selectedDuration: event.value
									},
										() => this.durationFunction()
									)
								}
							/>
						</div>						
					</div>

					{this.state.showDateTimePicker ?
						<div className="mt-2">
							<div className="d-flex justify-content-between">
								<p className="b-block mb-0 text-white">&nbsp;</p>
								{this.state.hasError && (!this.state.start_time || !this.state.end_time) ?
									<p className="mb-0 text-danger">required</p>
								: null}
							</div>
							
							<div className="d-flex">
								<Datetime 
									// value={momentDateGivenFormat(this.state.start_time, 'DD MMM YYYY hh:mm A')}
									// open={this.state.open_start_date}
									// onClick={() => this.setState({ open_start_date: true })}
									onChange={this.handleStartTimeChange}
									dateFormat={'DD MMM YYYY'}
									timeFormat={'hh:mm A'}
									inputProps={ startInputProps }
									className="w-100 mr-3"
									isValidDate={disableFutureDt}
								/>
								<Datetime
									// value={momentDateGivenFormat(this.state.end_time, 'DD MMM YYYY hh:mm A')}
									// open={this.state.open_end_date}
									// onClick={() => this.setState({ open_end_date: true })}
									onChange={this.handleEndTimeChange}
									dateFormat={'DD MMM YYYY'}
									timeFormat={'hh:mm A'}
									inputProps={ endInputProps }
									className="w-100"
									isValidDate={disableFutureDt}
								/>
							</div>
							{this.state.hasDateError ?
								<p className="mb-0 text-danger">{this.state.showDateisNotValid}</p>
							: null}
							{this.state.hasError && (!this.state.start_time || this.state.start_time === "" || !this.state.end_time || this.state.end_time === "") ?
								<p className="mb-0 text-danger">Please select date</p>
							: null}
						</div>
					: null}
					
					<div className="mt-2">
						<div className="w-82 d-flex justify-content-between">
							<p className="b-block mb-0 text-white">Tags</p>
							{this.state.hasError && (!this.state.selectedTags || !this.state.selectedTags.length) ?
								<p className="mb-0 text-danger">required</p>
							: null}
						</div>
						{this.state.tagsArray.map((item, i) => {
							return(
								<div className={`w-100 ${i ? "mt-3" : ""}`}>
									<div className="d-flex">
										{/* <p className="mb-0 mr-2 align-self-end text-info">Bucket</p> */}
										<Input
											type="text" 
											placeholder="key (environment)"
											className={`w-40 bg-black5 text-white border-0 ${this.state.hasError && (this.state["tag_value_"+item] && this.state["tag_value_"+item] !== "" && (!this.state["tag_key_"+item] || this.state["tag_key_"+item] === "")) ? "danger-border" : ""}`}
											value={this.state["tag_key_"+item] ? this.state["tag_key_"+item] : ""}
											onChange={e => this.setState({ ["tag_key_"+item]: e.target.value }, () => this.onChangeEvent(i, "key"))}
										/>
										{/* <p className="mb-0 mx-2 align-self-end text-info">Prefix</p> */}
										<Input 
											type="text" 
											placeholder="value (prod)"
											className={`w-40 bg-black5 text-white border-0 ml-3 ${this.state.hasError && (this.state["tag_key_"+item] && this.state["tag_key_"+item] !== "" && (!this.state["tag_value_"+item] || this.state["tag_value_"+item] === "")) ? "danger-border-3" : ""}`}
											value={this.state["tag_value_"+item] ? this.state["tag_value_"+item] : ""}
											onChange={e => this.setState({ ["tag_value_"+item]: e.target.value }, () => this.onChangeEvent(i, "value"))}
										/>
										{this.state.tagsArray.length > 1 ?
											<span className={`far fa-trash cursor-pointer f18 align-self-center ml-4`} onClick={() => this.removeSection(item, i)}></span>
										: null}
										{(this.state.tagsArray.length - 1) ===  i ?
											<span className={`far fa-plus cursor-pointer f18 align-self-center ml-4`} onClick={() => this.addSection()}></span>
										: null}
									</div>
								</div>
							)
						})}
					</div>
					<div className="form-group form-check mt-3 mb-0">
						<input 
							type="checkbox" 
							className="form-check-input"
							checked={this.state.allChecks}
							onChange={e => this.setState({ allChecks: !this.state.allChecks }, () => this.onChangeDiagnotics())}
						/>
						<label className="form-check-label text-white f14">
							Run all Diagnostic checks
						</label>
					</div>
					{!this.state.allChecks ?
						<div className="mt-3">
							<div className="table-responsive rounded border">
								<table className="table table-sm mb-0 text-primary-color">
									<thead className="bg-dark3">
										<tr>
											<th colspan="2" className="w-40">Diagnostic Checks</th>
											{/* <th className="text-nowrap w-20">Check</th> */}
											<th className="w-60">Description</th>
										</tr>
									</thead>
									<tbody className="bg-dark text-white">
										{ this.state.diagnostiChecks && this.state.diagnostiChecks.map((item, index) => {
											return(
												<tr className={`${index === this.state.diagnostiChecks.length-1 ? "" : "border-bottom-dark"}`}>
													<td>
														<input
															type='checkbox'
															min='1'
															className="form-check-input m-0 mt-n2 align-self-center"
															onChange={e => this.onChangeDiagnotics(item.check)}
															checked={this.state.selectedDiagnoticChecks && this.state.selectedDiagnoticChecks.includes(item.check) ? true : false}
														/>
													</td>
													<td>
														{item.name}
													</td>
													<td>
														<p className="mb-0" id={`description_`+index}>{item.description}</p>
														<UncontrolledTooltip placement='top' target={`description_`+index}>{item.description}</UncontrolledTooltip>		
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						</div>
					: null}
					<div className="mt-3">
						<p className="mb-0 text-white">Description</p>
						<Input 
							type="textarea" 
							rows={5}
							maxLength={512}
							placeholder="enter description"
							className={`bg-muted text-white border-0`}
							value={this.state.description ? this.state.description : ""}
							onChange={e => this.setState({ description: e.target.value })}
						/>
					</div>
					<div className="d-flex mt-3 mb-2">
						<button className={`btn bg-secondary p-2 text-white mr-3`} onClick={() => this.props.showHelperSection()}>Cancel</button>
						<button className={`btn bg-info mr-3`} onClick={() => this.setState({ saveDiagnosticsLoading: true }, () => this.saveDiagnosticTemplate())}>
							<Spinner className={`mr-2 ${this.state.saveDiagnosticsLoading ? "" : "displayNone"}`} color="white" size="sm" />Save as Template
						</button>
					</div>
					<div className="mt-3">
						<span>&nbsp;</span>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
CreateDiagnostics.propTypes = {}

const mapStateToProps = state => {
	return {
		providers: state.filters.providers,
        accounts: state.filters.accounts,
		regions: state.filters.regions,
        automationPropsDetails: state.filters.automationPropsDetails,
	}
}

export default connect(mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
	startDiagnostics,
	listDiagnosticChecks,
	saveDiagnosticTemplate,
	listDiagnosticTemplate
})(withRouter(CreateDiagnostics))



