/*************************************************
 * Tvastar
 * @exports
 * @file aiopsAction.js
 * @author parakash // on 2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
// import cognitoService from '../../services/cognitoService'
import { numberFormater, capitalizeFirstLetter } from '../../utils/utility'
import { API } from 'aws-amplify'

import {
	AIOPS_SERVICE,
	CLOSE_CASE,
	LIST_CASES,
	LIST_CASES_WITH_DETAILS,
	GET_AIOPS_COVERED_RESOURCES,
	LIST_AIOPS_ALERTS,
	LIST_AIOPS_ASSETS,
	GET_CASE_DETAILS,
	GET_CASES_TOTAL_COUNT,
	GET_AIOPS_DAILY_TREND,
	AIOPS_CASE_UPDATE_CASE_DETAILS,
	AIOPS_GET_ALERT_TREND_BY_ASSET,
	GET_EVENTS_BY_IMPACT,
	GET_AGGREGATED_EVENTS,
	GET_CHANGE_IMPACT_ANALYSIS_RESULTS,
	LIST_ALARMS,
	CREATE_ALARM,

	REMEDIATION,
	GET_AIOPS_REMEDIATION_ESTIMATE,

} from '../../config'

import { ACTION_TYPES } from '../types'

/**
 * Action to list cases
 * @param {Object} body
 * @param {Function} callback
 */
export const listCases = (body, callback) => {
	return async () => {
		await API.post(AIOPS_SERVICE, LIST_CASES, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to close cases
 * @param {Object} body
 * @param {Function} callback
 */
export const closeCase = (body, callback) => {
	return async () => {
		await API.post(AIOPS_SERVICE, CLOSE_CASE, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to list cases with details
 * @param {Object} body
 * @param {Function} callback
 */
export const listCasesWithDetails = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(AIOPS_SERVICE, LIST_CASES_WITH_DETAILS, { body })
			if(result.status) {
				dispatch({
					type: ACTION_TYPES.SET_LIST_CASE_DETAILS,
					payload: result,
				})
			} else {
				dispatch({
					type: ACTION_TYPES.SET_LIST_CASE_DETAILS,
					payload: {},
				})
			}
			callback(true, result)
		} catch(err) {
			// let message = ''
			dispatch({
				type: ACTION_TYPES.SET_LIST_CASE_DETAILS,
				payload: {},
			})
			callback(false, {})
		}
    }
}

/**
 * Action to get aiops coverd reqeust
 * @param {Object} body
 * @param {Function} callback
 */
export const getAiopsCoveredResources = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(AIOPS_SERVICE, GET_AIOPS_COVERED_RESOURCES, { body })
			if(result && result.results && Object.entries(result.results).length) {
				result.results['graphData'] = aiopsCoveredResourcesGraph(result.results)
			}
			dispatch({
				type: ACTION_TYPES.SET_AIOPS_COVERED_RESOURCES,
				payload: result,
			})
			callback(true, result)
		} catch(err) {
			// let message = ''
			dispatch({
				type: ACTION_TYPES.SET_AIOPS_COVERED_RESOURCES,
				payload: {},
			})
			callback(false, {})
		}
    }
}

/**
 * Action to GET AIOPS REMEDIATION ESTIMATE
 * @param {Object} body
 * @param {Function} callback
 */
export const getAiopsRemediationEstimate = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(REMEDIATION, GET_AIOPS_REMEDIATION_ESTIMATE, { body })
			dispatch({
				type: ACTION_TYPES.SET_AIOPS_REMEDIATION_ESTIMATE,
				payload: result,
			})
			callback(true, result)
		} catch(err) {
			// let message = ''
			dispatch({
				type: ACTION_TYPES.SET_AIOPS_REMEDIATION_ESTIMATE,
				payload: {},
			})
			callback(false, {})
		}
	}
}

/**
 * Action to Get cases details
 * @param {Object} body
 * @param {Function} callback
 */
export const getCaseDetails = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(AIOPS_SERVICE, GET_CASE_DETAILS, { body })
			dispatch({
				type: ACTION_TYPES.SET_GET_CASE_DETAILS,
				payload: result,
			})
			callback(true, result)
		} catch(err) {
			callback(false, {})
		}
    }
}

/**
 * Action to Get cases Total Count
 * @param {Object} body
 * @param {Function} callback
 */
export const getCasesTotalCount = (body, callback) => {
	return async dispatch => {
		try {
			if(body.aggregate_by[0] === 'resource_type' && body.searchInputs === 'filter') {
				delete body.searchInputs;
				const casesTotalCount = await API.post(AIOPS_SERVICE, GET_CASES_TOTAL_COUNT, { body })
				dispatch({
					type: ACTION_TYPES.SET_AIOPS_RESOURCE_CASES_TOTAL_COUNT,
					payload: casesTotalCount,
				}) 
				callback(true, casesTotalCount)
			} else {
				const casesTotalCount = await API.post(AIOPS_SERVICE, GET_CASES_TOTAL_COUNT, { body })
				let result = {}
				if(body.aggregate_by[0] === 'resource_type') {
					result = serviceGraph(casesTotalCount)
					dispatch({
						type: ACTION_TYPES.SET_AIOPS_SERVICE_CASES_TOTAL_COUNT,
						payload: result,
					})
				} else if(body.aggregate_by[0] === 'account_id') {
					dispatch({
						type: ACTION_TYPES.SET_AIOPS_ACCOUNT_CASES_TOTAL_COUNT,
						payload: casesTotalCount,
					})
				} else if(body.aggregate_by[0] === 'region') {
					dispatch({
						type: ACTION_TYPES.SET_AIOPS_REGION_CASES_TOTAL_COUNT,
						payload: casesTotalCount,
					}) 
				} else if(body.aggregate_by[0] === 'severity') {
					dispatch({
						type: ACTION_TYPES.SET_AIOPS_SEVERITY_CASES_TOTAL_COUNT,
						payload: casesTotalCount,
					})
				} else if(body.aggregate_by[0] === 'status') {
					dispatch({
						type: ACTION_TYPES.SET_AIOPS_STATUS_CASES_TOTAL_COUNT,
						payload: casesTotalCount,
					})
				}
				callback(true, casesTotalCount)
			}
			
		} catch(err) {
			callback(false, {})
		}
	}
}

const serviceGraph = (casesTotalCount) => {
	let donutSeries = []
	let labels = []
	// let overAll = 0
	let overAllCount = 0
	// let overAllLable = 'OverAll'
	let colors = []
	let legend = {
		show: true,
		width: 120,
		fontSize: '10px',
		fontFamily: 'Muli, sans-serif',
		labels: {
			colors: ['#EDEDED'],
			useSeriesColors: false
		},
	}

	if(casesTotalCount.length) {
		casesTotalCount.forEach(item => {
			donutSeries.push(item.total_count)
			labels.push(item.resource_type)
			// overAll += item.total_count
			overAllCount += item.total_count
		})
		colors = ['#A88CCC', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7']
		if(donutSeries.length === 2) {
			colors = ['#24A597', '#383E4D']
		}
	} else {
		labels.push('No Cases')
		donutSeries.push(0.1)
		colors = ['#C1C1C1']
		overAllCount = 'NoData'
		// overAllLable = 'Cases'
		legend = {
			show: false,
		}
	}

	let total = {} 
	total['show'] = true
	total['fontSize'] = '10px'
	total['color'] = '#EDEDED'
	if(overAllCount === 'NoData') {
		total['label'] = 'No Cases'
		total['formatter'] = function (w) {
			return 0
		}
	} else {
		total['label'] = 'Services'
		total['formatter'] = function (w) {
			return overAllCount
		}
	}


	let donutOptions = {
		stroke: {
			width: 0
		},
		chart: {
			  type: 'donut',
			  redrawOnParentResize: true,
		},		
		
		dataLabels: {
			  enabled: false
		},
		fill: {
			type: '#ececec',
		},
		labels: labels,
		colors: colors,
		tooltip: {
			enabled: false,
		},
		// tooltip: {
		// 	enabled: true,
		// 	custom: function({ series, seriesIndex, w }) {
		// 		let color = w.globals.colors[seriesIndex]
		// 		if(color === '#353C4C' || color === '#2D323E') {
		// 			color = '#EDEDED'
		// 		}
		// 		return '<div class="arrow_box">' +
		// 		  '<span class="mr-2">' +  w.globals.labels[seriesIndex] + '</span>' +
		// 		  '<span style="color:'+ color +'">' +  series[seriesIndex] + '</span>' +
		// 		  '</div>'
		// 	},
		// },
		legend: legend,
		plotOptions: {
			pie: {
				donut: {
					size: '70%',
					labels: {
						show: true,
						value: {
							fontSize: '10px',
							fontWeight: 700,
							offsetY: 0,
							color: '#EDEDED',
							formatter: function (val) {
								return numberFormater(val)
							}
						},
						name: {
							fontSize: '10px',
							offsetY: -2,
							color: '#EDEDED',
							formatter: function (val) {
								return capitalizeFirstLetter(val)
							}
						},	
						total:  total
					}
				},      
			}
		},				
		responsive: [{
			breakpoint: 1550,
			options: {
				legend: {
					show: false,
					width: undefined,
					fontSize: '10px',
					fontFamily: 'Muli, sans-serif',
					position: 'bottom',
					labels: {
						colors: 'EDEDED',
						useSeriesColors: false
					},
				}
			}
		}]	
	}
	
	let graph = {}
	graph.series = donutSeries
	graph.options = donutOptions
	return graph

}

const aiopsCoveredResourcesGraph = (result) => {
	let donutSeries = [result.total_covered_assets ? result.total_covered_assets : 0, result.total_assets_in_organization ? result.total_assets_in_organization : 0]
	let labels = ['Assets Covered', 'Assets Managed']

	let overAll = result.covered_percentage+'%'


	let total = {}
	
	total['show'] = true
	total['label'] = 'AIOps Coverage'
	total['fontSize'] = '10px'
	total['color'] = '#EDEDED'
	total['formatter'] = function (w) {
		return overAll
	}
	let colors = ['#A88CCC', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7']
	if(donutSeries.length === 2) {
		colors = ['#24A597', '#383E4D']
	}

	let donutOptions = {
		stroke: {
			width: 0
		},
		chart: {
			  type: 'donut',
		},
		legend: {
			show: true,
			width: 130,
			fontSize: '10px',
			fontFamily: 'Muli, sans-serif',
			labels: {
				colors: 'EDEDED',
				useSeriesColors: false
			},
		},
		dataLabels: {
			enabled: false
		},
		labels: labels,
		colors: colors,
		tooltip: {
			enabled: false,
		},
		plotOptions: {
			pie: {
				donut: {
					size: '70%',
					labels: {
						show: true,
						value: {
							fontSize: '10px',
							fontWeight: 700,
							offsetY: 0,
							color: '#EDEDED',
							formatter: function (val) {
								return numberFormater(val)
							}
						},
						name: {
							fontSize: '10px',
							offsetY: -2,
							color: '#EDEDED',
							formatter: function (val) {
								return capitalizeFirstLetter(val)
							}
						},	
						total:  total
					}
				},      
			}
		},		
		responsive: [{
			breakpoint: 1550,
			options: {
			  chart: {
				width: 220,
				height: 220,
			  },
			  legend: {
				  
				show: true,
				width: undefined,
				fontSize: '10px',
				fontFamily: 'Muli, sans-serif',
				position: 'bottom',
				labels: {
					colors: 'EDEDED',
					useSeriesColors: false
				},
			  }
			}
		}]
	}
	
	let graph = {}
	graph.series = donutSeries
	graph.options = donutOptions

	return graph

}

// export const getAiopsDailyTrend = (body, callback) => {
// 	return async () => {
// 		await API.post(AIOPS_SERVICE, GET_AIOPS_DAILY_TREND, { body })
// 		.then((response) => {
// 			callback(true, response)
// 		}).catch((err) => {
// 			let message = ''
// 			callback(false, message)
// 		})
//     }
// }

/**
 * Action to Get Aiops Daily Trend
 * @param {Object} body
 * @param {Function} callback
 */
export const getAiopsDailyTrend = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(AIOPS_SERVICE, GET_AIOPS_DAILY_TREND, { body })
			if(body.all) {
				dispatch({
					type: ACTION_TYPES.SET_AIOPS_DAILY_TREND,
					payload: result,
				})
			}
			callback(true, result)
		} catch(err) {
			if(body.all) {
				dispatch({
					type: ACTION_TYPES.SET_AIOPS_DAILY_TREND,
					payload: {},
				})
			}
			callback(false, {})
		}
	}
}


/**
 * Action to get AIOPS Alert
 * @param {Object} body
 * @param {Function} callback
 */
export const listAlerts = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(AIOPS_SERVICE, LIST_AIOPS_ALERTS, { body })
			console.log(response)
			// if(body.type === 'alertEventDetails') {
				if(response.status) {
					dispatch({
						type: ACTION_TYPES.SET_ALERT_EVENT_DETAILS,
						payload: response && response.results ? response.results : [],
					})
				} else {
					dispatch({
						type: ACTION_TYPES.SET_ALERT_EVENT_DETAILS,
						payload: [],
					})
				}
			// }
			callback(true, response)
		} catch(err) {
			// if(body.type === 'alertEventDetails') {
				dispatch({
					type: ACTION_TYPES.SET_ALERT_EVENT_DETAILS,
					payload: {},
				})
			// }
			callback(false, {})
		}
	}
}

/**
 * Action to get AIOPS Assets
 * @param {Object} body
 * @param {Function} callback
 */
export const listAssets = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(AIOPS_SERVICE, LIST_AIOPS_ASSETS, { body })
			// dispatch({
			// 	type: ACTION_TYPES.SET_AIOPS_ASSETS_LSIT,
			// 	payload: result,
			// })
			callback(true, result)
		} catch(err) {
			// let message = ''
			// dispatch({
			// 	type: ACTION_TYPES.SET_AIOPS_ASSETS_LSIT,
			// 	payload: [],
			// })
			callback(false, {})
		}
    }
}

/**
 * Action to set aiops props details
 */
export const setAiopsPropsDetails = (key, value) => {
	return async (dispatch, getState) => {
		let aiopsPropsDetails = JSON.parse(JSON.stringify(getState().aiops.aiopsPropsDetails))
		aiopsPropsDetails[key] = value
		if(key !== 'remediationStatus') {
			dispatch({
				type: ACTION_TYPES.SET_AIOPS_PROPS_DETAILS,
				payload: aiopsPropsDetails,
			})
		} else {
			dispatch({
				type: ACTION_TYPES.SET_AIOPS_PROPS_DETAILS,
				payload: {},
			})
		}
	}
}

/**
 * Action to get UPDATE CASE DETAILS
 * @param {Object} body
 * @param {Function} callback
 * @author Prakash // on 18/08/2021
 */
 export const updateCaseDetails = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(AIOPS_SERVICE, AIOPS_CASE_UPDATE_CASE_DETAILS, { body })
			// dispatch({
			// 	type: ACTION_TYPES.SET_AIOPS_ASSETS_LSIT,
			// 	payload: result,
			// })
			callback(true, result)
		} catch(err) {
			// let message = ''
			// dispatch({
			// 	type: ACTION_TYPES.SET_AIOPS_ASSETS_LSIT,
			// 	payload: [],
			// })
			callback(false, {})
		}
    }
}

export const getAlertTrendByAsset = (body, callback) => {
	return async () => {
		await API.post(AIOPS_SERVICE, AIOPS_GET_ALERT_TREND_BY_ASSET, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to get-events-by-impact
 * @param {Object} body
 * @param {Function} callback
 */
 export const getEventsByImpact = (body, callback) => {
	return async dispatch => {
		try {
			const resonse = await API.post(AIOPS_SERVICE, GET_EVENTS_BY_IMPACT, { body })
			callback(true, resonse)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to get-aggregated-events
 * @param {Object} body
 * @param {Function} callback
 */
 export const getAggregatedEvents = (body, callback) => {
	return async dispatch => {
		try {
			const resonse = await API.post(AIOPS_SERVICE, GET_AGGREGATED_EVENTS, { body })
			callback(true, resonse)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * get-change-impact-analysis-results
 * @param {Object} body
 * @param {Function} callback
 */
 export const getChangeImpactAnalysisResults = (body, callback) => {
	return async dispatch => {
		try {
			const resonse = await API.post(AIOPS_SERVICE, GET_CHANGE_IMPACT_ANALYSIS_RESULTS, { body })
			callback(true, resonse)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * list-alarms
 * @param {Object} body
 * @param {Function} callback
 */
 export const listAlarms = (body, callback) => {
	return async dispatch => {
		try {
			const resonse = await API.post(AIOPS_SERVICE, LIST_ALARMS, { body })
			callback(true, resonse)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * create-alarm
 * @param {Object} body
 * @param {Function} callback
 */
 export const createAlarm = (body, callback) => {
	return async dispatch => {
		try {
			const resonse = await API.post(AIOPS_SERVICE, CREATE_ALARM, { body })
			callback(true, resonse)
		} catch (error) {
			callback(false)
		}
	}
}
