/*************************************************
 * Tvastar
 * @exports
 * @file ApesMutliyAxis  .js
 * @author Prakash // on 16/07/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { momentDateGivenFormat, momentConvertionUtcToLocalTime, convertBytes, convertBytesWithoutUnit, convertSeconds, convertSecondsWithoutUnit, countFormater, countFormaterWithoutUnit, convertBits, convertBitsWithoutUnit, twoDateTimeInterval, subDays, subHours, subMinutes, calculateMinutes } from '../../utils/utility'
import Chart from 'react-apexcharts'
import { setDiagnosticsPropsDetails, getSreDetails } from '../../actions/aiops/DiagnosticsAction'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

class SreMetricDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            sreDetails: [],
            show_latency: true,
            show_error_rate: true,
            selectedFilterType: 'H',
            selectedFilterPeriod: 6,
            showAnnotation: true,

        }
    }
    
    componentDidMount = () => {
        let savedMetrics = {}
        if(this.props.page && this.props.page === "rca") {
            savedMetrics = this.props.diagnosticRcaSreMertricDetails
        } else {
            savedMetrics = this.props.diagnosticSreMertricDetails
        }

        if(Object.entries(this.props.savedMetrics).length) {
            if(this.props.savedMetrics.hasOwnProperty(this.props.assetDetails.asset_name)) {
                this.setState({ 
                    sreDetails: this.props.savedMetrics[this.props.assetDetails.asset_name],
                    showLoading: false
                },
                    () => {
                        this.changeGraphTimings()
                    }
                )
            } else {
                this.getSreDetails()
            }
        } else {
            this.getSreDetails()
        }
    }

    componentDidUpdate = (prevProps) => {
        // if(this.props.assetDetails && this.props.assetDetails !== prevProps.assetDetails) {
        // this.getSreDetails()
        // }
    }

    getSreDetails = () => {
        let params = {}
        if(this.props.page && this.props.page === "rca") {
            params.start_time = this.props.startTime
            params.end_time = this.props.endTime
        } else {
            let endTime = this.props.diagnostics.diagnostic_end_time ?  this.props.diagnostics.diagnostic_end_time :  this.props.diagnostics.diagnostic_start_time
            params.start_time = momentDateGivenFormat(subDays(endTime, 3), "YYYY-MM-DD HH:mm:ss")
            params.end_time = endTime
        }

        params.asset_id = this.props.assetDetails.asset_id

        if(params.asset_id) {
            this.props.getSreDetails(params, (promise, response) => {
                if(promise) {
                    this.setState({ 
                        sreDetails: response,
                        showLoading: false
                    },
                        () => {
                            this.changeGraphTimings()
                            if(this.props.page && this.props.page === "rca") {
                                let obj = {}
                                obj = this.props.diagnosticRcaSreMertricDetails
                                obj[this.props.assetDetails.asset_name] = response
                                this.props.setDiagnosticsPropsDetails('diagnosticRcaSreMertricDetails', obj)
                            } else {
                                let obj = {}
                                obj = this.props.diagnosticSreMertricDetails
                                obj[this.props.assetDetails.asset_name] = response
                                this.props.setDiagnosticsPropsDetails('diagnosticSreMertricDetails', obj)
                            }

                            
                        }
                    )
                } else {
                    this.setState({ sreDetails: [], showLoading: false })
                }
            })
        }
    }

    changeGraphTimings = () => {
        let endTime = this.props.diagnostics.diagnostic_end_time ?  this.props.diagnostics.diagnostic_end_time :  this.props.diagnostics.diagnostic_start_time
        let seletedStartTime = momentDateGivenFormat(subDays(endTime, 3), "YYYY-MM-DD HH:mm:ss")
        let seletedEndTime = endTime        
        
        if(this.state.selectedFilterType === 'D') {
            seletedStartTime = subDays(seletedEndTime, this.state.selectedFilterPeriod)
        } else {
            seletedStartTime = subHours(seletedEndTime, this.state.selectedFilterPeriod)
        }

        let graphLabelsFrom = calculateMinutes(seletedStartTime, seletedEndTime)
        
        let difference = twoDateTimeInterval(seletedStartTime, seletedEndTime)
        
        let brushStartDate = seletedStartTime
        let brushEndDate = seletedEndTime
        if(difference && difference.length) {
            if(difference[0] > 0) {
                if(difference[0] === 1) {
                    brushStartDate = subHours(brushEndDate, 1)
                } else if(difference[0] < 2) {
                    brushStartDate = subHours(brushEndDate, 3)
                } else if(difference[0] < 3) {
                    brushStartDate = subHours(brushEndDate, 4)
                } else {
                    brushStartDate = subHours(brushEndDate, 8)
                }
            } else if(difference[1] > 0) {
                if(difference[1] <= 1) {
                    brushStartDate = subMinutes(brushEndDate, 10)
                } else if(difference[1] < 3) {
                    brushStartDate = subMinutes(brushEndDate, 15)
                } else if(difference[1] < 6) {
                    brushStartDate = subMinutes(brushEndDate, 30)
                } else if(difference[1] < 12) {
                    brushStartDate = subMinutes(brushEndDate, 45)
                } else if(difference[1] < 24) {
                brushStartDate = subHours(brushEndDate, 1)
            }
            } else {
                brushStartDate = subMinutes(brushEndDate, 10)
            }
        }

        brushStartDate = momentConvertionUtcToLocalTime(brushStartDate, 'YYYY-MM-DD HH:mm:ss')
        brushEndDate = momentConvertionUtcToLocalTime(brushEndDate, 'YYYY-MM-DD HH:mm:ss')        
        let sliderStartDate = momentConvertionUtcToLocalTime(seletedStartTime, 'YYYY-MM-DD HH:mm:ss')
        let sliderEndDate = momentConvertionUtcToLocalTime(seletedEndTime, 'YYYY-MM-DD HH:mm:ss')

        this.setState({
            graphLabelsFrom,
            brushStartDate, 
            brushEndDate,
            latencyBrushStartDate: brushStartDate,
            latencyBrushEndDate: brushEndDate,
            errorRateBrushStartDate: brushStartDate,
            errorRateBrushEndDate: brushEndDate,
            sliderStartDate,
            sliderEndDate,
        },
            () => {
                this.formSreLatencyGraph()
                this.formSreLatencyDetailGraph()
                this.formSreRateGraph()
                this.formSreRateDetailGraph()
            }
        )
    }

    formSreLatencyGraph = () => {
        let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']
        if(this.state.sreDetails && this.state.sreDetails.length) {
            let filteredSreDetails = this.state.sreDetails.filter(arr => arr.asset_id === this.props.assetDetails.asset_id)
            filteredSreDetails.forEach((res, index) => {
                let graphData = res.data
                let graphLabels = res.dates

                let series = []
                let brushData = []
                let colorInc = 0
                let yDetails = []

                graphData.latency && Object.entries(graphData.latency).forEach(([key, item]) => {
                    let yRow = {}
                    if(item.values && item.values.length) {
                        if(!colorInc) {
                            yRow = {
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                    color: '#60687C'
                                },
                                tooltip: {
                                    enabled: false
                                },
                                tickAmount: 2,
                                min: 0,
                                labels: {
                                    offsetX: -10,
                                    show: true,
                                    style: {
                                        fontSize: '9px',
                                        colors: '#60687C'
                                    },
                                    formatter: (value) => { 
                                        return value.toFixed(2)
                                    },
                                }
                            }
                        } else {
                            yRow = {
                                show: false
                            }
                        }
                        yDetails.push(yRow)

                        let dataRow = {}
                        dataRow.name = key
                        dataRow.type = 'line'
                        let plotValue = []
                        let brushValue = []

                        let labelsFrom = graphLabels.length - this.state.graphLabelsFrom
                        graphLabels.slice(labelsFrom, graphLabels.length).forEach((lab, i) => {
                            let dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            // dataArray.push(this.getGrapUnitValue(item.values[i], item.unit))
                            dataArray.push(item.values[labelsFrom + i])
                            plotValue.push(dataArray)
                            // dataArray.push(this.getGrapUnitValue(bar1[i], 'Percent'))

                            dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            dataArray.push(item.values[labelsFrom + i])
                            brushValue.push(dataArray)
                        })
                        dataRow.data = plotValue
                        series.push(dataRow)

                        if(!colorInc) {
                            let brushRow = {}
                            brushRow.metrics = key
                            brushRow.name = key
                            brushRow.type = 'line'
                            brushRow.data = brushValue
                            brushData.push(brushRow)
                        }

                        colorInc++;
                    }
                })

                let xaxisAnnotation = []

                let annotations = {
                    xaxis: xaxisAnnotation
                }

                let options = {
                    chart: {
                        type: 'line',
                        id: 'chart_latency_'+index,
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false
                        }
                    },
                    colors: colors,
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        width: [2, 3, 4],
                        curve: 'smooth',
                        // dashArray: [0, 8, 5]
                    },
                    xaxis: {
                        type: 'datetime',
                        labels: {
                            datetimeUTC: false,
                            show: true,
                            style: {
                                fontSize: '9px',
                                colors: '#60687C'
                            },
                        }
                    },
                    yaxis: yDetails,
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            show: false,
                        },
                        
                        x: {
                            show: false
                        },
                        marker: {
                            show: false
                        },
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                                // let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]
                                // let labelName = w.globals.initialSeries[seriesIndex].name
                                // // return '<div className="arrow_box"> '+ capitalizeFirstLetter(labelName) +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' + '  '+ val + '</span> </div>'         
                                let returnData = ''
                                
                                if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                    Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                        if(!index) {
                                            returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                        }
                                        returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'

                                    })
                                }

                                returnData += '</div>'
                                return returnData
                        }
                    },
                    grid: {
                        show: true,
                        borderColor: '#2D323E',
                        strokeDashArray: 0,
                        position: 'back',
                        xaxis: {
                            lines: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: ['#2D323E'],
                                }
                            },  
                        },   
                        yaxis: {
                            lines: {
                                show: false
                            },
                            labels: {
                                style: {
                                    colors: ['#2D323E'],
                                }
                            }
                        },
                        padding: {
                            // top: 0,
                            right: 0,
                            // bottom: 0,
                            left: 0
                        }, 
                    },
                    legend: {
                        show: false
                        // horizontalAlign: 'left',
                        // offsetX: 40
                    },
                    annotations: annotations
                }

                let brushOptions = {
                    chart: {
                        id: 'chart_latency_brush'+index,
                        height: 130,
                        type: 'area',
                        sparkline: {
                            enabled: true
                        },
                        brush:{
                            target: 'chart_latency_'+index,
                            enabled: true
                        },
                        // events: {
                        //     brushScrolled: function(chartContext, { xaxis, yaxis }) {
                        //     console.log(xaxis);
                        //     },
                        //     scrolled: function(chartContext, { xaxis }) {
                        //         console.log('------------',xaxis);
                        //     }
                            
                        // },
                        selection: {
                            enabled: true,
                            xaxis: {
                                min: new Date(this.state.brushStartDate).getTime(),
                                max: new Date(this.state.brushEndDate).getTime()
                            },
                            stroke: {
                                show: false,
                                curve: 'straight',
                                lineCap: 'round',
                                colors: '#3F73AD',
                                // colors: '#00A3CB',
                                width: 0,
                                dashArray: 0,      
                            },
                            fill: {
                                color: "#EAF1FB",
                                opacity: .2
                            },  
                        },
                        // events: {
                        //     selection: function(chartContext, { xaxis, yaxis }) {
                        //         console.log(xaxis)
                        //         xaxis = {
                        //             min: new Date(xaxis.min).getTime(),
                        //             max: new Date(xaxis.max).getTime()
                        //         }
                        //     }
                        // }
                    },                    
                    labels: graphLabels,
                    colors: ['#039BE5'],
                    fill: {
                        type: 'gradient',
                        gradient: {
                            // shadeIntensity: 1,
                            // opacityFrom: 0.7,
                            // opacityTo: 0.9,
                            // stops: [0, 90, 100]
                            gradientToColors: ['#039BE5'],
                            shadeIntensity: 0,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [50, 90]
                        }
                    },
                    xaxis: {
                        show: false,
                        type: 'datetime',
                        // categories: labels,
                        labels: {
                            datetimeUTC: false,
                            show: false,
                            style: {
                                fontSize: '9px',
                                colors: this.props.axisLabelColor
                            },
                        },
                        axisTicks: {
                            show: false,
                        },
                        axisBorder: {
                            show: false,
                        }
                    },
                    yaxis: {
                        show: false,
                        tickAmount: 2,
                        labels: {
                            show: false,
                            style: {
                                fontSize: '9px',
                                colors: this.props.axisLabelColor
                            },
                        },
                    },
                    grid: {
                        show: false,
                        borderColor: '#35363A',
                        strokeDashArray: 0,
                        position: 'back',
                        xaxis: {
                            lines: {
                                show: false
                            },
                            labels: {
                                style: {
                                    colors: ['#969AA7'],
                                }
                            },  
                        },   
                        yaxis: {
                            lines: {
                                show: false
                            },
                            labels: {
                                style: {
                                    colors: ['#969AA7'],
                                }
                            }
                        },
                    },
                }

                this.setState({ ['series_'+index]: series, ['options_'+index]: options, ['brushSeries_'+index]: brushData, ['brushOptions_'+index]: brushOptions, graphLabels })
            })
        }
    }

    formSreLatencyDetailGraph = () => {
        let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']
        if(this.state.sreDetails && this.state.sreDetails.length) {
            // let obj = {}
            let filteredSreDetails = this.state.sreDetails
            filteredSreDetails.forEach((res, index) => {
                let graphData = res.data
                let graphLabels = res.dates

                let commonTotalGraphValue = 0
                let commonLatencyDetailBrush = []
                let latenceyDetailTargets = []
                let colorInc = 0
                graphData.latency && Object.entries(graphData.latency).forEach(([key, item], j) => {
                    if(item.values && item.values.length) {
                        let thresholdArray = []
                        if(item.threshold && Object.entries(item.threshold).length) {
                            Object.entries(item.threshold).forEach(([key, value]) => {
                                let thresholdRow = {}
                                thresholdRow.prefix = ''
                                thresholdRow.label = key
                                let val = this.getGrapUnitValue(value, 'Seconds')
                                thresholdRow.value = val
                                thresholdArray.push(thresholdRow)
                            })
                        }                        
                        latenceyDetailTargets.push('chart_latency_details_'+j)

                        let chartType = 'line'
                        let animations = {}
                        let plotOptions = {}
                        let stroke = {
                            width: [3],
                            curve: 'smooth',
                        }
                        if(j % 2 === 0) {
                            chartType = 'bar'
                            animations = {
                                enabled: false
                            }
                            plotOptions = {
                                bar: {
                                    horizontal: false,
                                    barHeight: '100%',
                                    borderRadius: 'flat',
                                    startingShape: 'flat',
                                    endingShape: 'flat',
                                    columnWidth: '60%',
                                    distributed: false,
                                    rangeBarOverlap: false,
                                }
                            }
                            stroke = {
                                width: [2],
                            }
                        }

                        let latencySeries = []
                        let dataRow = {}
                        dataRow.name = key
                        dataRow.type = chartType
                        let plotValue = []
                        let brushValue = []
                        let labelsFrom = graphLabels.length - this.state.graphLabelsFrom
                        graphLabels.slice(labelsFrom, graphLabels.length).forEach((lab, i) => {
                            let dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            let val = item.values[labelsFrom + i]
                            if(item.unit) {
                                val = this.getGrapUnitValue(val, item.unit)
                            }
                            dataArray.push(val)
                            plotValue.push(dataArray)

                            dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            dataArray.push(val)
                            brushValue.push(dataArray)
                        })
                        dataRow.data = plotValue
                        latencySeries.push(dataRow)

                        if(commonTotalGraphValue < item.values.reduce((a, b) => a + b, 0)) {
                            commonLatencyDetailBrush = []
                            commonTotalGraphValue = item.values.reduce((a, b) => a + b, 0)
                            let brushRow = {}
                            brushRow.metrics = key
                            brushRow.name = key
                            brushRow.type = 'line'
                            brushRow.data = brushValue
                            commonLatencyDetailBrush.push(brushRow)
                        }

                        let xaxisAnnotation = []

                        let yaxisAnnotaions = []
                        if(this.state.selected_latency && this.state.selected_latency.length && this.state['threshold_latency_'+index+'_'+j] && this.state['threshold_latency_'+index+'_'+j].length) {
                            let borderColor = ''
                            let background = ''
                            let color = ''
                            let text = ''
                            let location = ''
                            let annotationLineColors = ['#FFD200', '#F75E3F', '#039BE5', '#06CB9E']
                            this.state.selected_latency.forEach((item, i) => {
                                text = item.text
                                let filter = this.state['threshold_latency_'+index+'_'+j].filter(arr => arr.label === item.text)
                                location = filter[0] && filter[0].value ? filter[0].value : 0
                                
                                this.state['threshold_latency_'+index+'_'+j].forEach((thres, k) => {
                                    if(text.toLowerCase() === thres.label.toLowerCase()) {
                                        borderColor = '#fff'
                                        background = annotationLineColors[k]
                                    }
                                })
                                color = '#fff' 

                                let dataRow = {
                                    y: location,
                                    strokeDashArray: 0,
                                    borderColor: borderColor,
                                    opacity: 1,
                                    label: {
                                        borderColor: 'none',
                                        text: text,
                                        style: {
                                            color: color,
                                            background: background,
                                            fontSize: '12px'
                                        },
                                        textAnchor: 'middle',
                                    }
                                }
                                yaxisAnnotaions.push(dataRow)
                            })    
                        }

                        let annotations = {
                            xaxis: xaxisAnnotation,
                            yaxis: yaxisAnnotaions
                        }

                        let latency_options = {
                            chart: {
                                id: 'chart_latency_details_'+j,
                                type: chartType,
                                toolbar: {
                                    show: false
                                },
                                zoom: {
                                    enabled: false
                                },
                                animations: animations
                            },
                            plotOptions: plotOptions,
                            dataLabels: {
                                enabled: false
                            },
                            stroke: stroke,
                            colors: [colors[colorInc]],
                            xaxis: {
                                type: 'datetime',
                                labels: {
                                    datetimeUTC: false,
                                    show: this.props.xaxis,
                                    style: {
                                        fontSize: '9px',
                                        colors: this.props.axisLabelColor
                                    },
                                }
                            },
                            yaxis: {
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                    color: '#60687C'
                                },
                                tooltip: {
                                    enabled: false
                                },
                                tickAmount: 2,
                                min: 0,
                                labels: {
                                    offsetX: -10,
                                    show: true,
                                    style: {
                                        fontSize: '9px',
                                        colors: '#60687C'
                                    },
                                    formatter: (value) => { 
                                        return value.toFixed(2)
                                    },
                                }
                            },
                            tooltip: {
                                shared: true,
                                intersect: false,
                                y: {
                                    show: false,
                                },
                                
                                x: {
                                    show: false
                                },
                                marker: {
                                    show: false
                                },
                                custom: function({series, seriesIndex, dataPointIndex, w}) { 
                                    let returnData = ''
                                    
                                    if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                        Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                            if(!index) {
                                                returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                            }
                                            returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'
    
                                        })
                                    }
                                    returnData += '</div>'
                                    return returnData
                                }
                            },                            
                            grid: {
                                show: this.props.grid,
                                borderColor: '#35363A',
                                strokeDashArray: 0,
                                position: 'back',
                                xaxis: {
                                    lines: {
                                        show: true
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#969AA7'],
                                        }
                                    },  
                                },   
                                yaxis: {
                                    lines: {
                                        show: false
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#969AA7'],
                                        }
                                    }
                                },
                                padding: {
                                    // top: 0,
                                    right: 0,
                                    // bottom: 0,
                                    left: 0
                                }, 
                            },
                            legend: {
                                show: false
                            },
                            annotations: annotations
                        }
                    
                        if(j === (Object.entries(graphData.latency).length-1)) {
                            let latencyDetailBrushOptions = {
                                chart: {
                                    id: 'chart_latency_detail_brush_'+index,
                                    height: 130,
                                    type: 'area',
                                    sparkline: {
                                        enabled: true
                                    },
                                    brush:{
                                        targets: latenceyDetailTargets, 
                                        // target: 'chart_latency_details_'+index,
                                        enabled: true
                                    },
                                    selection: {
                                        enabled: true,
                                        xaxis: {
                                            min: new Date(this.state.latencyBrushStartDate).getTime(),
                                            max: new Date(this.state.latencyBrushEndDate).getTime()
                                        },
                                        stroke: {
                                            show: false,
                                            curve: 'straight',
                                            lineCap: 'round',
                                            colors: '#3F73AD',
                                            // colors: '#00A3CB',
                                            width: 0,
                                            dashArray: 0,      
                                        },
                                        fill: {
                                            color: "#EAF1FB",
                                            opacity: .2
                                        },  
                                    },
                                },                    
                                labels: graphLabels,
                                colors: ['#039BE5'],
                                fill: {
                                    type: 'gradient',
                                    gradient: {
                                        // shadeIntensity: 1,
                                        // opacityFrom: 0.7,
                                        // opacityTo: 0.9,
                                        // stops: [0, 90, 100]
                                        gradientToColors: ['#039BE5'],
                                        shadeIntensity: 0,
                                        opacityFrom: 1,
                                        opacityTo: 1,
                                        stops: [50, 90]
                                    }
                                },
                                xaxis: {
                                    show: false,
                                    type: 'datetime',
                                    // categories: labels,
                                    labels: {
                                        datetimeUTC: false,
                                        show: false,
                                        style: {
                                            fontSize: '9px',
                                            colors: this.props.axisLabelColor
                                        },
                                    },
                                    axisTicks: {
                                        show: false,
                                    },
                                    axisBorder: {
                                        show: false,
                                    }
                                },
                                yaxis: {
                                    show: false,
                                    tickAmount: 2,
                                    labels: {
                                        show: false,
                                        style: {
                                            fontSize: '9px',
                                            colors: this.props.axisLabelColor
                                        },
                                    },
                                },
                                grid: {
                                    show: false,
                                    borderColor: '#35363A',
                                    strokeDashArray: 0,
                                    position: 'back',
                                    xaxis: {
                                        lines: {
                                            show: false
                                        },
                                        labels: {
                                            style: {
                                                colors: ['#969AA7'],
                                            }
                                        },  
                                    },   
                                    yaxis: {
                                        lines: {
                                            show: false
                                        },
                                        labels: {
                                            style: {
                                                colors: ['#969AA7'],
                                            }
                                        }
                                    },
                                },
                            }

                            this.setState({ ['series_latency_brush_detail_'+index]: commonLatencyDetailBrush, ['options_latency_brush_detail_'+index]: latencyDetailBrushOptions })
                        }

                        colorInc++;

                        this.setState({ ['series_latency_'+j]: latencySeries, ['options_latency_'+j]: latency_options, ['threshold_latency_'+index+'_'+j]: thresholdArray, threshold_latency: thresholdArray, latenceyDetailTargets })
                    }
                })
            })
        }
    }

    formSreRateGraph = () => {
        let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']
                
        if(this.state.sreDetails && this.state.sreDetails.length) {
            let filteredSreDetails = this.state.sreDetails.filter(arr => arr.asset_id === this.props.assetDetails.asset_id)
            filteredSreDetails.forEach((res, index) => {
                let graphData = res.data
                let graphLabels = res.dates

                let series = []
                
                let  strokeWidth = []
                let x = 0
                let yDetails = []
                let yRow = {}
                graphData.error_rate && Object.entries(graphData.error_rate).forEach(([key, item], j) => {
                    strokeWidth.push(x+2)
                    if(item.values && item.values.length) {
                        if(!x) {
                            yRow = {
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                    color: '#60687C'
                                },
                                tooltip: {
                                    enabled: false
                                },
                                tickAmount: 2,
                                min: 0,
                                labels: {
                                    offsetX: -10,
                                    show: true,
                                    style: {
                                        fontSize: '9px',
                                        colors: '#60687C'
                                    },
                                },
                                formatter: (value) => { 
                                    // console.log(value)
                                    if(Number(value) === value && value % 1 === 0) {
                                        return Math.ceil(value)
                                    } else {
                                        return value.toFixed(2)
                                    }
                                },
                            }
                            
                        } else {
                            yRow = {
                                show: false
                            }
                        }
                        let dataRow = {}
                        dataRow.name = key
                        dataRow.type = 'column'
                        let plotValue = []
                        
                        let labelsFrom = graphLabels.length - this.state.graphLabelsFrom
                        graphLabels.slice(labelsFrom, graphLabels.length).forEach((lab, i) => {
                            let dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            // dataArray.push(this.getGrapUnitValue(item.values[i], item.unit))
                            let val = item.values[labelsFrom + i]
                            if(item.unit && item.unit !== '') {
                                val = this.getGrapUnitValue(val, item.unit)
                            }
                            dataArray.push(val)
                            plotValue.push(dataArray)
                        })
                        dataRow.data = plotValue
                        series.push(dataRow)
                        x++;
                    }
                })
                
                let brushData = []
                graphData.request_rate && Object.entries(graphData.request_rate).forEach(([key, item], j) => {
                    strokeWidth.push(x+2)
                    if(item.values && item.values.length) {
                        yRow = {
                            axisTicks: {
                                show: false,
                            },
                            axisBorder: {
                                show: false,
                                color: '#60687C'
                            },
                            tooltip: {
                                enabled: false
                            },
                            tickAmount: 2,
                            min: 0,
                            labels: {
                                offsetX: -10,
                                show: true,
                                style: {
                                    fontSize: '9px',
                                    colors: '#60687C'
                                },
                            }
                        }
                        yDetails.push(yRow)

                        let dataRow = {}
                        dataRow.name = key
                        dataRow.type = 'line'
                        let plotValue = []
                        let brushValue = []

                        let labelsFrom = graphLabels.length - this.state.graphLabelsFrom
                        graphLabels.slice(labelsFrom, graphLabels.length).forEach((lab, i) => {
                            let dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            let val = item.values[labelsFrom + i]
                            if(item.unit && item.unit !== '') {
                                val = this.getGrapUnitValue(val, item.unit)
                            }
                            dataArray.push(val)
                            plotValue.push(dataArray)

                            dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            dataArray.push(val)
                            brushValue.push(dataArray)
                            // dataArray.push(this.getGrapUnitValue(bar1[i], 'Percent'))
                        })
                        dataRow.data = plotValue
                        series.push(dataRow)

                        if(!j) {
                            let brushRow = {}
                            brushRow.name = key
                            brushRow.type = 'line'
                            brushRow.data = brushValue
                            brushData.push(brushRow)
                        }
                    }
                    x++;
                })                

                let xaxisAnnotation = []

                let annotations = {
                    xaxis: xaxisAnnotation
                }

                let options = {
                    chart: {
                        id: 'chart_rate_'+index,
                        redrawOnParentResize: true,
                        stacked: true,
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false
                        },
                        type: "line"
                    },
                    colors: colors,
                    dataLabels: {
                        enabled: false,
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            barHeight: '100%',
                            // borderRadius: 8,
                            startingShape: 'flat',
                            endingShape: 'flat',
                            columnWidth: '60%',
                            distributed: false,
                            rangeBarOverlap: false,
                        }
                    },
                    stroke: {
                        width: strokeWidth,
                        curve: 'smooth',
                    },
                    xaxis: {
                        show: true,
                        type: 'datetime',
                        // categories: labels,
                        labels: {
                            datetimeUTC: false,
                            show: true,
                            style: {
                                fontSize: '9px',
                                colors: '#60687C'
                            },
                        },
                    },
                    yaxis: yDetails,
                    grid: {
                        show: false,
                        borderColor: '#2D323E',
                        strokeDashArray: 0,
                        position: 'back',
                        xaxis: {
                            lines: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: ['#969AA7'],
                                }
                            },  
                        },   
                        yaxis: {
                            lines: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: ['#969AA7'],
                                }
                            },
                        },
                        padding: {
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0
                        },
                    },
                    legend: {
                        show: false
                    },
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            show: false,
                        },
                        
                        x: {
                            show: false
                        },
                        marker: {
                            show: false
                        },
                        custom: function({series, seriesIndex, dataPointIndex, w}) {     
                            let returnData = ''
                            if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                    if(!index) {
                                        returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                    }
                                    returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'

                                })
                            }

                            returnData += '</div>'
                            return returnData
                        }
                    },                    
                    annotations: annotations
                }

                let brushOptions = {
                    chart: {
                        id: 'chart_rate_brush'+index,
                        height: 130,
                        type: 'area',
                        sparkline: {
                            enabled: true
                        },
                        brush:{
                            target: 'chart_rate_'+index,
                            enabled: true
                        },
                        selection: {
                            enabled: true,
                            xaxis: {
                                min: new Date(this.state.brushStartDate).getTime(),
                                max: new Date(this.state.brushEndDate).getTime()
                            },
                            stroke: {
                                show: false,
                                curve: 'straight',
                                lineCap: 'round',
                                colors: '#3F73AD',
                                // colors: '#00A3CB',
                                width: 0,
                                dashArray: 0,      
                            },
                            fill: {
                                color: "#EAF1FB",
                                opacity: .2
                            },  
                        },
                    },                    
                    labels: graphLabels,
                    colors: ['#039BE5'],
                    fill: {
                        type: 'gradient',
                        gradient: {
                            // shadeIntensity: 1,
                            // opacityFrom: 0.7,
                            // opacityTo: 0.9,
                            // stops: [0, 90, 100]
                            gradientToColors: ['#039BE5'],
                            shadeIntensity: 0,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [50, 90]
                        }
                    },
                    xaxis: {
                        show: false,
                        type: 'datetime',
                        // categories: labels,
                        labels: {
                            datetimeUTC: false,
                            show: false,
                            style: {
                                fontSize: '9px',
                                colors: this.props.axisLabelColor
                            },
                        },
                        axisTicks: {
                            show: false,
                        },
                        axisBorder: {
                            show: false,
                        }
                    },
                    yaxis: {
                        show: false,
                        tickAmount: 2,
                        min: 0,
                        labels: {
                            show: false,
                            style: {
                                fontSize: '9px',
                                colors: this.props.axisLabelColor
                            },
                        },
                    },
                    grid: {
                        show: false,
                        borderColor: '#35363A',
                        strokeDashArray: 0,
                        position: 'back',
                        xaxis: {
                            lines: {
                                show: false
                            },
                            labels: {
                                style: {
                                    colors: ['#969AA7'],
                                }
                            },  
                        },   
                        yaxis: {
                            lines: {
                                show: false
                            },
                            labels: {
                                style: {
                                    colors: ['#969AA7'],
                                }
                            }
                        },
                    },
                }

                this.setState({ ['rate_series_'+index]: series, ['rate_options_'+index]: options, ['rate_brushSeries_'+index]: brushData, ['rate_brushOptions_'+index]: brushOptions })
            })

        }
    }

    formSreRateDetailGraph = () => {
        let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']
        if(this.state.sreDetails && this.state.sreDetails.length) {

            let xaxisAnnotation = []
            let annotations = {
                xaxis: xaxisAnnotation
            }

            let errorRateArray= []
            let filteredSreDetails = this.state.sreDetails.filter(arr => arr.asset_id === this.props.assetDetails.asset_id)
            filteredSreDetails.forEach((res, index) => {
                let graphData = res.data
                let graphLabels = res.dates

                // let  strokeWidth = []
                let x = 0
                
                let commonTotalGraphValue = 0
                let commonRateDetailBrush = []
                let errorRateDetailTargets = []

                graphData.error_rate && Object.entries(graphData.error_rate).forEach(([key, item], j) => {
                    errorRateDetailTargets.push('chart_rate_details_'+x)
                    
                    let thresholdArray = []
                    if(item.threshold && Object.entries(item.threshold).length) {
                        Object.entries(item.threshold).forEach(([key, value]) => {
                            let thresholdRow = {}
                            thresholdRow.prefix = ''
                            thresholdRow.label = key
                            thresholdRow.value = value
                            thresholdArray.push(thresholdRow)
                        })
                    }
                    
                    let chartType = 'line'
                    let animations = {}
                    let plotOptions = {}
                    let stroke = {
                        width: [3],
                        curve: 'smooth',
                        // dashArray: [0, 8, 5]
                    }
                    if(x % 2 === 0) {
                        chartType = 'bar'
                        animations = {
                            enabled: false
                        }
                        plotOptions = {
                            bar: {
                                horizontal: false,
                                barHeight: '100%',
                                borderRadius: 'flat',
                                startingShape: 'flat',
                                endingShape: 'flat',
                                columnWidth: '60%',
                                distributed: false,
                                rangeBarOverlap: false,
                            }
                        }
                        stroke = {
                            width: [2],
                        }
                    }

                    let error_series = []
                    let errorRow = {}
                    errorRow.name = key
                    errorRow.type = chartType

                    let plotValue = []
                    let labelsFrom = graphLabels.length - this.state.graphLabelsFrom
                    graphLabels.slice(labelsFrom, graphLabels.length).forEach((lab, i) => {
                        let dataArray = []
                        dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                        // dataArray.push(this.getGrapUnitValue(item.values[i], item.unit))
                        dataArray.push(item.values[labelsFrom + i])
                        plotValue.push(dataArray)
                    })

                    errorRow.data = plotValue
                    error_series.push(errorRow)

                    errorRateArray.push({key: key, unit: item.unit})

                    let rate_options = {
                        chart: {
                            type: chartType,
                            id: 'chart_rate_details_'+x,
                            toolbar: {
                                show: false
                            },
                            zoom: {
                                enabled: false
                            },
                            animations: animations,
                        },
                        plotOptions: plotOptions,
                        stroke: stroke,
                        dataLabels: {
                            enabled: false
                        },
                        colors: [colors[x]],
                        xaxis: {
                            // categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
                            type: 'datetime',
                            // categories: labels,
                            labels: {
                                datetimeUTC: false,
                                show: this.props.xaxis,
                                style: {
                                    fontSize: '9px',
                                    colors: this.props.axisLabelColor
                                },
                            }
                        },
                        yaxis: {
                            show: this.props.yaxis,
                            tickAmount: 2,
                            min: 0,
                            // max: 1,
                            labels: {
                                show: this.props.yaxisLabel,
                                style: {
                                    fontSize: '9px',
                                    colors: this.props.axisLabelColor
                                },
                                formatter: (value) => { 
                                    if(Number(value) === value && value % 1 === 0) {
                                        return Math.ceil(value)
                                    } else {
                                        return value.toFixed(1)
                                    }
                                },
                            }
                        },
                        tooltip: {
                            shared: true,
                            intersect: false,
                            y: {
                                show: false,
                            },
                            
                            x: {
                                show: false
                            },
                            marker: {
                                show: false
                            },
                            custom: function({series, seriesIndex, dataPointIndex, w}) {
                                    // let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]
                                    // let labelName = w.globals.initialSeries[seriesIndex].name
                                    // // return '<div className="arrow_box"> '+ capitalizeFirstLetter(labelName) +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' + '  '+ val + '</span> </div>'         
                                    let returnData = ''
                                    
                                    if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                        Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                            if(!index) {
                                                returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                            }
                                            returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'
    
                                        })
                                    }
    
                                    returnData += '</div>'
                                    return returnData
                            }
                        },
                        grid: {
                            show: this.props.grid,
                            borderColor: '#35363A',
                            strokeDashArray: 0,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: true
                                },
                                labels: {
                                    style: {
                                        colors: ['#969AA7'],
                                    }
                                },  
                            },   
                            yaxis: {
                                lines: {
                                    show: false
                                },
                                labels: {
                                    style: {
                                        colors: ['#969AA7'],
                                    }
                                }
                            },
                            padding: {
                                // top: 0,
                                right: 0,
                                // bottom: 0,
                                left: 0
                            }, 
                        },
                        legend: {
                            show: false
                            // horizontalAlign: 'left',
                            // offsetX: 40
                        },
                        annotations: annotations
                    }

                    this.setState({ ['series_rate_detail_'+x]: error_series, ['options_rate_detail_'+x]: rate_options, ['threshold_rate_'+index+'_'+x]: thresholdArray })
                    x++;
                })
                
                graphData.request_rate && Object.entries(graphData.request_rate).forEach(([key, item], j) => {
                    errorRateDetailTargets.push('chart_rate_details_'+x)

                    let thresholdArray = []
                    if(item.threshold && Object.entries(item.threshold).length) {
                        Object.entries(item.threshold).forEach(([key, value]) => {
                            let thresholdRow = {}
                            thresholdRow.prefix = ''
                            thresholdRow.label = key
                            thresholdRow.value = value
                            thresholdArray.push(thresholdRow)
                        })
                    }

                    let chartType = 'line'
                    let animations = {}
                    let plotOptions = {}
                    let stroke = {
                        width: [3],
                        curve: 'smooth',
                        // dashArray: [0, 8, 5]
                    }

                    if(x % 2 === 0) {
                        chartType = 'bar'
                        animations = {
                            enabled: false
                        }
                        plotOptions = {
                            bar: {
                                horizontal: false,
                                barHeight: '100%',
                                borderRadius: 'flat',
                                startingShape: 'flat',
                                endingShape: 'flat',
                                columnWidth: '60%',
                                distributed: false,
                                rangeBarOverlap: false,
                            }
                        }
                        stroke = {
                            width: [2],
                        }
                    }

                    let plotValue = []
                    let brushValue = []

                    let labelsFrom = graphLabels.length - this.state.graphLabelsFrom
                    graphLabels.slice(labelsFrom, graphLabels.length).forEach((lab, i) => {
                        let dataArray = []
                        dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                        dataArray.push(item.values[labelsFrom + i])
                        // dataArray.push(this.getGrapUnitValue(item.values[i], item.unit))
                        dataArray.push(item.values[labelsFrom + i])
                        // dataArray.push(item.values[labelsFrom + i])
                        plotValue.push(dataArray)

                        dataArray = []
                        dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                        dataArray.push(item.values[labelsFrom + i])
                        brushValue.push(dataArray)
                        // dataArray.push(this.getGrapUnitValue(bar1[i], 'Percent'))
                    })

                    let request_series = []
                    let requestRow = {}
                    requestRow.name = key
                    requestRow.type = 'bar'
                    requestRow.data = plotValue
                    request_series.push(requestRow)
                    
                    if(commonTotalGraphValue < item.values.reduce((a, b) => a + b, 0)) {
                        commonRateDetailBrush = []
                        commonTotalGraphValue = item.values.reduce((a, b) => a + b, 0)
                        let brushRow = {}
                        brushRow.metrics = key
                        brushRow.name = key
                        brushRow.type = 'line'
                        brushRow.data = brushValue
                        commonRateDetailBrush.push(brushRow)
                    }

                    errorRateArray.push({key: key, unit: item.unit})

                    let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC']

                    let request_options = {
                        chart: {
                            type: chartType,
                            id: 'chart_rate_details_'+x,
                            toolbar: {
                                show: false
                            },
                            zoom: {
                                enabled: false
                            },
                            animations: animations
                        },
                        plotOptions: plotOptions,
                        stroke: stroke,
                        dataLabels: {
                            enabled: false
                        },
                        colors: [colors[x]],
                        xaxis: {
                            // categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
                            type: 'datetime',
                            // categories: labels,
                            labels: {
                                datetimeUTC: false,
                                show: this.props.xaxis,
                                style: {
                                    fontSize: '9px',
                                    colors: this.props.axisLabelColor
                                },
                            }
                        },
                        yaxis: {
                            show: this.props.yaxis,
                            tickAmount: 2,
                            min: 0,
                            // max: 1,
                            labels: {
                                show: this.props.yaxisLabel,
                                style: {
                                    fontSize: '9px',
                                    colors: this.props.axisLabelColor
                                },
                                formatter: (value) => { 
                                    if(Number(value) === value && value % 1 === 0) {
                                        return Math.ceil(value)
                                    } else {
                                        return value.toFixed(1)
                                    }
                                },
                            }
                        },
                        tooltip: {
                            shared: true,
                            intersect: false,
                            y: {
                                show: false,
                            },
                            
                            x: {
                                show: false
                            },
                            marker: {
                                show: false
                            },
                            custom: function({series, seriesIndex, dataPointIndex, w}) {
                                // let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]
                                // let labelName = w.globals.initialSeries[seriesIndex].name
                                // // return '<div className="arrow_box"> '+ capitalizeFirstLetter(labelName) +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' + '  '+ val + '</span> </div>'         
                                let returnData = ''
                                
                                if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                    Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                        if(!index) {
                                            returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                        }
                                        returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'

                                    })
                                }

                                returnData += '</div>'
                                return returnData
                            }
                        },
                        grid: {
                            show: this.props.grid,
                            borderColor: '#35363A',
                            strokeDashArray: 0,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: true
                                },
                                labels: {
                                    style: {
                                        colors: ['#969AA7'],
                                    }
                                },  
                            },   
                            yaxis: {
                                lines: {
                                    show: false
                                },
                                labels: {
                                    style: {
                                        colors: ['#969AA7'],
                                    }
                                }
                            },
                            padding: {
                                // top: 0,
                                right: 0,
                                // bottom: 0,
                                left: 0
                            }, 
                        },
                        legend: {
                            show: false
                            // horizontalAlign: 'left',
                            // offsetX: 40
                        },
                        annotations: annotations
                    }

                    if(j === (Object.entries(graphData.request_rate).length-1)) {
                        let rateDetailBrushOptions = {
                            chart: {
                                id: 'chart_rate_detail_brush_'+index,
                                height: 130,
                                type: 'line',
                                sparkline: {
                                    enabled: true
                                },
                                brush:{
                                    targets: errorRateDetailTargets, 
                                    // target: 'chart_latency_details_'+index,
                                    enabled: true
                                },
                                selection: {
                                    enabled: true,
                                    xaxis: {
                                        min: new Date(this.state.errorRateBrushStartDate).getTime(),
                                        max: new Date(this.state.errorRateBrushEndDate).getTime()
                                    },
                                    stroke: {
                                        show: false,
                                        curve: 'straight',
                                        lineCap: 'round',
                                        colors: '#3F73AD',
                                        // colors: '#00A3CB',
                                        width: 0,
                                        dashArray: 0,      
                                    },
                                    fill: {
                                        color: "#EAF1FB",
                                        opacity: .2
                                    },  
                                },
                            },                    
                            labels: graphLabels,
                            colors: ['#039BE5'],
                            fill: {
                                type: 'gradient',
                                gradient: {
                                    // shadeIntensity: 1,
                                    // opacityFrom: 0.7,
                                    // opacityTo: 0.9,
                                    // stops: [0, 90, 100]
                                    gradientToColors: ['#039BE5'],
                                    shadeIntensity: 0,
                                    opacityFrom: 1,
                                    opacityTo: 1,
                                    stops: [50, 90]
                                }
                            },
                            xaxis: {
                                show: false,
                                type: 'datetime',
                                // categories: labels,
                                labels: {
                                    datetimeUTC: false,
                                    show: false,
                                    style: {
                                        fontSize: '9px',
                                        colors: this.props.axisLabelColor
                                    },
                                },
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                }
                            },
                            yaxis: {
                                show: false,
                                tickAmount: 2,
                                min: 0,
                                labels: {
                                    show: false,
                                    style: {
                                        fontSize: '9px',
                                        colors: this.props.axisLabelColor
                                    },
                                },
                            },
                            grid: {
                                show: false,
                                borderColor: '#35363A',
                                strokeDashArray: 0,
                                position: 'back',
                                xaxis: {
                                    lines: {
                                        show: false
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#969AA7'],
                                        }
                                    },  
                                },   
                                yaxis: {
                                    lines: {
                                        show: false
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#969AA7'],
                                        }
                                    }
                                },
                            },
                        }

                        this.setState({ ['series_rate_brush_detail_'+index]: commonRateDetailBrush, ['options_rate_brush_detail_'+index]: rateDetailBrushOptions, errorRateDetailTargets })

                    }

                    this.setState({ ['series_rate_detail_'+x]: request_series, ['options_rate_detail_'+x]: request_options, ['threshold_rate_'+index+'_'+x]: thresholdArray })
                    x++;
                })
            })
            this.setState({ errorRateArray })
        }
    }

    updateErrorRateOptions = (index, childIndex) => {
        let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']
        let xaxisAnnotation = []
        let yaxisAnnotaions = []
        if(this.state['selected_rate_'+index+'_'+childIndex] && this.state['selected_rate_'+index+'_'+childIndex].length) {
            let borderColor = ''
            let background = ''
            let color = ''
            let text = ''
            let location = ''
            let annotationLineColors = ['#FFD200', '#F75E3F', '#039BE5', '#06CB9E']
            this.state['selected_rate_'+index+'_'+childIndex].forEach((item, i) => {
                text = item.text
                // location = this.getGrapUnitValueWithoutUnit(item.value, unit)
                location = item.value
                this.state['threshold_rate_'+index+'_'+childIndex].forEach((thres, j) => {
                    if(text.toLowerCase() === thres.label.toLowerCase()) {
                        borderColor = '#fff'
                        background = annotationLineColors[j]
                    }
                })
                color = '#fff' 

                let dataRow = {
                    y: location,
                    strokeDashArray: 0,
                    borderColor: borderColor,
                    opacity: 1,
                    label: {
                        borderColor: 'none',
                        // orientation: 'horizontal',
                        text: text,
                        style: {
                            color: color,
                            background: background,
                            fontSize: '12px',
                            // width: '15px',
                        },
                        textAnchor: 'middle',
                    }
                }
                yaxisAnnotaions.push(dataRow)
            })
        }
        
        let annotations = {
            xaxis: xaxisAnnotation,
            yaxis: yaxisAnnotaions
        }
        

        let rate_options = {
            chart: {
                type: 'line',
                id: 'chart_rate_details_'+childIndex,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [2],
                curve: 'smooth',
                // dashArray: [0, 8, 5]
            },
            colors: [colors[childIndex]],
            xaxis: {
                // categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
                type: 'datetime',
                // categories: labels,
                labels: {
                    datetimeUTC: false,
                    show: this.props.xaxis,
                    style: {
                        fontSize: '9px',
                        colors: this.props.axisLabelColor
                    },
                }
            },
            yaxis: {
                show: this.props.yaxis,
                tickAmount: 2,
                min: 0,
                // max: 1,
                labels: {
                    show: this.props.yaxisLabel,
                    style: {
                        fontSize: '9px',
                        colors: this.props.axisLabelColor
                    },
                    formatter: (value) => { 
                        if(Number(value) === value && value % 1 === 0) {
                            return Math.ceil(value)
                        } else {
                            return value.toFixed(1)
                        }
                    },
                }
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    show: false,
                },
                
                x: {
                    show: false
                },
                marker: {
                    show: false
                },
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                        // let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]
                        // let labelName = w.globals.initialSeries[seriesIndex].name
                        // // return '<div className="arrow_box"> '+ capitalizeFirstLetter(labelName) +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' + '  '+ val + '</span> </div>'         
                        let returnData = ''
                        
                        if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                            Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                if(!index) {
                                    returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                }
                                returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'

                            })
                        }

                        returnData += '</div>'
                        return returnData
                }
            },
            grid: {
                show: this.props.grid,
                borderColor: '#35363A',
                strokeDashArray: 0,
                position: 'back',
                xaxis: {
                    lines: {
                        show: true
                    },
                    labels: {
                        style: {
                            colors: ['#969AA7'],
                        }
                    },  
                },   
                yaxis: {
                    lines: {
                        show: false
                    },
                    labels: {
                        style: {
                            colors: ['#969AA7'],
                        }
                    }
                },
                padding: {
                    // top: 0,
                    right: 0,
                    // bottom: 0,
                    left: 0
                }, 
            },
            legend: {
                show: false
                // horizontalAlign: 'left',
                // offsetX: 40
            },
            annotations: annotations
        }

        let rateDetailBrushOptions = {
            chart: {
                id: 'chart_rate_detail_brush_'+index,
                height: 130,
                type: 'line',
                sparkline: {
                    enabled: true
                },
                brush:{
                    targets: this.state.errorRateDetailTargets, 
                    // target: 'chart_latency_details_'+index,
                    enabled: true
                },
                selection: {
                    enabled: true,
                    xaxis: {
                        min: new Date(this.state.errorRateBrushStartDate).getTime(),
                        max: new Date(this.state.errorRateBrushEndDate).getTime()
                    },
                    stroke: {
                        show: false,
                        curve: 'straight',
                        lineCap: 'round',
                        colors: '#3F73AD',
                        // colors: '#00A3CB',
                        width: 0,
                        dashArray: 0,      
                    },
                    fill: {
                        color: "#EAF1FB",
                        opacity: .2
                    },
                },
            },                    
            labels: this.state.graphLabels,
            colors: ['#039BE5'],
            fill: {
                type: 'gradient',
                gradient: {
                    // shadeIntensity: 1,
                    // opacityFrom: 0.7,
                    // opacityTo: 0.9,
                    // stops: [0, 90, 100]
                    gradientToColors: ['#039BE5'],
                    shadeIntensity: 0,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [50, 90]
                }
            },
            xaxis: {
                show: false,
                type: 'datetime',
                // categories: labels,
                labels: {
                    datetimeUTC: false,
                    show: false,
                    style: {
                        fontSize: '9px',
                        colors: this.props.axisLabelColor
                    },
                },
                axisTicks: {
                    show: false,
                },
                axisBorder: {
                    show: false,
                }
            },
            yaxis: {
                show: false,
                tickAmount: 2,
                min: 0,
                labels: {
                    show: false,
                    style: {
                        fontSize: '9px',
                        colors: this.props.axisLabelColor
                    },
                },
            },
            grid: {
                show: false,
                borderColor: '#35363A',
                strokeDashArray: 0,
                position: 'back',
                xaxis: {
                    lines: {
                        show: false
                    },
                    labels: {
                        style: {
                            colors: ['#969AA7'],
                        }
                    },  
                },   
                yaxis: {
                    lines: {
                        show: false
                    },
                    labels: {
                        style: {
                            colors: ['#969AA7'],
                        }
                    }
                },
            },
        }

        this.setState({ ['options_rate_brush_detail_'+index]: {}, ['options_rate_detail_'+childIndex]: {} },
            () => {
                this.setState({ ['options_rate_brush_detail_'+index]: rateDetailBrushOptions, ['options_rate_detail_'+childIndex]: rate_options })
            }
        )
    }

    getGrapUnitValue = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytes(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit)
            } else if(unit === 'Count') {
                // val = val > 1 ? parseInt(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    getGrapUnitValueWithoutUnit = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytesWithoutUnit(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSecondsWithoutUnit(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBitsWithoutUnit(val, unit)
            } else if(unit === 'Count') {
                // val = val > 1 ? parseInt(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val ? Math.ceil(val) : 0
                val = countFormaterWithoutUnit(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    handleMultiSelectChange = (type, index, childIndex, unit, arrayValue) => {
        // let value = arrayValue.map(item => item.value)
        // let label = arrayValue.map(item => item)

        this.setState({ [type+'_'+index+'_'+childIndex]: arrayValue },
            () => {
                if(type === 'selected_latency') {
                    this.updateLatencyOptions(index, childIndex, unit)
                } else if(type === 'selected_rate') {
                    this.updateErrorRateOptions(index, childIndex, unit)
                }
            }    
        )
    }

    onChangeMultiSelectChange = (type, arrayValue) => {
        // let value = arrayValue.map(item => item.value)
        // let label = arrayValue.map(item => item)

        this.setState({ [type]: arrayValue },
            () => {
                if(type === 'selected_latency') {
                    this.formSreLatencyDetailGraph()
                }
            }    
        )
    }

    getMultiSelectedCount = (type, array) => {
        if(array) {
            return array.length && array.includes('All') ? 'All' :  array.length ? array.length +' Selected' : 'All'
        } else {
            return 'All'
        }
    }
        
    // handleClickOutside(event) {		
    // 	if (this.accountRef && !this.accountRef.current.contains(event.target)) {
    // 		this.setState({ isAccountOpen: false })
    // 	} else {
    // 		this.setState({ isAccountOpen: true })
    // 	}

    // 	if (this.regionRef && !this.regionRef.current.contains(event.target)) {
    // 		this.setState({ isRegionOpen: false })
    // 	} else {
    // 		this.setState({ isRegionOpen: true })
    // 	}
    // }

    render() {
        return(
            !this.state.showLoading && this.state.sreDetails && this.state.sreDetails.length ? 
                this.state.sreDetails.map((res, index) => {
                    return(
                    <div className="">
                        <div className="d-flex justify-content-between">
                            <div className={`d-flex justify-content-end`}>
                                <span className={`mr-2 f12 align-self-center `}>Last</span>
                                <span className={`mr-2 align-self-center f12 ${this.state.selectedFilterType === 'D' && this.state.selectedFilterPeriod === 3 ? 'badge badge-info' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'D', selectedFilterPeriod: 3, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>3D</span>
                                <span className={`mr-2 align-self-center f12 ${this.state.selectedFilterType === 'D' && this.state.selectedFilterPeriod === 1 ? 'badge badge-info' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'D', selectedFilterPeriod: 1, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>1D</span>
                                <span className={`mr-2 align-self-center f12 ${this.state.selectedFilterType === 'H' && this.state.selectedFilterPeriod === 12 ? 'badge badge-info' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'H', selectedFilterPeriod: 12, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>12H</span>
                                <span className={`mr-2 align-self-center f12 ${this.state.selectedFilterType === 'H' && this.state.selectedFilterPeriod === 6 ? 'badge badge-info' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'H', selectedFilterPeriod: 6, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>6H</span>
                                {/* <span className={`mr-2 align-self-center f12 ${this.state.selectedFilterType === 'H' && this.state.selectedFilterPeriod === 3 ? 'badge badge-info' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'H', selectedFilterPeriod: 3, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>3H</span> */}
                                <span className={`mr-2 align-self-center f12 ${this.state.selectedFilterType === 'H' && this.state.selectedFilterPeriod === 1 ? 'badge badge-info' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'H', selectedFilterPeriod: 1, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>1H</span>
                            </div>
                        </div>                        
                        <div class="bg-dark rounded my-3 px-3 pt-3">
                            <div class="d-flex justify-content-between">
                                <div className="w-98">
                                    <div className="d-flex justify-content-between">
                                        <div className="align-self-center">
                                            <p class="f16 text-white mb-1">Latency</p>
                                            <p class="f12 text-white">{this.state.show_latency ? 'Showing overall Latency' : 'Showing the latency by metrics'} for {res.asset_name ? (res.asset_name +' asset'): ''}</p>
                                        </div>
                                        <div className="align-self-center">
                                            {this.state.show_detail_latency ?
                                                <div className="d-flex justify-content-end align-self-center mt-2">
                                                    <div className="mr-3 w-fit-content">
                                                        <div className={`multiSelection-minWidth removeMargin z999 removeDropdownSearchBar position-relative bg-muted`}>
                                                            <ReactMultiSelectCheckboxes						
                                                                placeholderButtonLabel="All"
                                                                getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedlatency', this.state.selected_latency)}
                                                                // menuIsOpen ={this.state.isannotationTypeOpen}
                                                                value={this.state.selected_latency && this.state.selected_latency.map(itm => ({
                                                                    value: itm.value,
                                                                    label: itm.label,
                                                                    text: itm.text,
                                                                }))}
                                                                onChange={arr => {
                                                                this.onChangeMultiSelectChange('selected_latency', arr ? arr : []) }}
                                                                options={this.state.threshold_latency && this.state.threshold_latency.map((itm, i) => ({
                                                                    value: itm.value,
                                                                    label: <span><span className={`mr-1 multiSelectSquareBox bg-${i === 0 ? 'warning' : i === 1 ? 'danger' : i === 2 ? 'info' : 'success'}`}></span>{itm.label}</span>,
                                                                    text: itm.label
                                                                }))}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-2 d-flex justify-content-end">
                                    {this.state.show_latency ?
                                        <button class="bg-transparent border-0 align-self-center text-white p-0 z999" >
                                            <i class="far fa-expand-wide cursorPointer" onClick={() => this.setState({ show_latency: false, show_detail_latency: true })}></i>
                                        </button>
                                    :
                                        <button class="bg-transparent border-0 align-self-center text-white p-0 z999" >
                                            <i class="far fa-compress cursorPointer" onClick={() => this.setState({ show_latency: true, show_detail_latency: false })}></i>
                                        </button>
                                    }
                                </div>
                            </div>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="mb-3 col-12 px-0">
                                        <div class="p-3 rounded bg-dark3">
                                            {this.state.show_latency && this.state['series_'+index] ?
                                                <div className={`mt-n2 ml-n3 ${this.props.className}`}>
                                                    <Chart options={this.state['options_'+index]} series={this.state['series_'+index]} type="line" height={this.props.height} />
                                                
                                                    <div class="container-fluid">
                                                        <div class="row mt-3">
                                                            <div class="col-md-7 align-self-center">
                                                                <div class="brush-timeline bg-dark">
                                                                    <div class="border-timeline rounded">
                                                                        <div className={`aiopsSre`}>
                                                                            <Chart options={this.state['brushOptions_'+index]} series={this.state['brushSeries_'+index]} type="line" height={50} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-5 align-self-center">
                                                                <div class="d-flex justify-content-between">
                                                                    <div>
                                                                        <small>From</small>
                                                                        <p class="text-warning font-weight-bold f16">{this.state.sliderStartDate && this.state.sliderStartDate !== '' ? momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM YYYY HH:mm') : ''}</p>
                                                                    </div>
                                                                    <div>
                                                                        <small>To</small>
                                                                        <p class="text-warning font-weight-bold f16">{this.state.sliderEndDate && this.state.sliderEndDate !== '' ? momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM YYYY HH:mm') : ''}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            : this.state.show_detail_latency ?
                                                <div className="mt-1">
                                                    <div className="row">
                                                        {res.data && Object.entries(res.data.latency).map(([key, item], j) => {
                                                            return(
                                                                <div className={`${j % 2 === 0 && j === (Object.entries(res.data.latency).length-1) ? 'col-sm-12' : (j % 2 !== 0 ? 'col-sm-6' : 'col-sm-6')} mb-3`}>
                                                                <div className={`border rounded p-3`}>
                                                                    <div className="d-flex">
                                                                        <p class="f16 text-white mb-1 mr-2">{key}</p>
                                                                        <div className="d-flex align-self-center displayNone">
                                                                            <div className="mr-3 w-fit-content">
                                                                                <div className={`multiSelection-minWidth removeMargin z999 removeDropdownSearchBar position-relative bg-muted`}>
                                                                                    <ReactMultiSelectCheckboxes						
                                                                                        placeholderButtonLabel="All"
                                                                                        getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedCount', this.state['selected_latency_'+index+'_'+j])}
                                                                                        // menuIsOpen ={this.state.isannotationTypeOpen}
                                                                                        value={this.state['selected_latency_'+index+'_'+j] && this.state['selected_latency_'+index+'_'+j].map(itm => ({
                                                                                            value: itm.value,
                                                                                            label: itm.label,
                                                                                            text: itm.text,
                                                                                        }))}
                                                                                        onChange={arr => {
                                                                                        this.handleMultiSelectChange('selected_latency', index, j, item.unit, arr ? arr : []) }}
                                                                                        options={this.state['threshold_latency_'+index+'_'+j] && this.state['threshold_latency_'+index+'_'+j].map((itm, i) => ({
                                                                                            value: itm.value,
                                                                                            label: <span><span className={`mr-1 multiSelectSquareBox bg-${i === 0 ? 'warning' : i === 1 ? 'danger' : i === 2 ? 'info' : 'success'}`}></span>{itm.label}</span>,
                                                                                            text: itm.label
                                                                                        }))}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={`mt-n3 ml-n3 ${this.props.className}`}>
                                                                        {this.state['options_latency_'+j] && this.state['series_latency_'+j] && this.state['series_latency_'+j].length ?
                                                                        <Chart options={this.state['options_latency_'+j]} series={this.state['series_latency_'+j]} type={`${j % 2 === 0 ? 'bar' : 'line'}`} height={150} width={"100%"} />
                                                                        :
                                                                            <div style={{minHeight:"165px"}} className="d-flex align-self-center justify-content-center">
                                                                                <div className='align-self-center'>
                                                                                    No Data
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            )
                                                        })}
                                                        <div class="container-fluid">
                                                            <div class="row">
                                                                <div class="col-md-7 align-self-center">
                                                                    <div class="brush-timeline bg-dark">
                                                                        <div class="border-timeline rounded">
                                                                            <div className={`aiopsSre`}>
                                                                                {this.state['series_latency_brush_detail_'+index] ?
                                                                                    <Chart options={this.state['options_latency_brush_detail_'+index]} series={this.state['series_latency_brush_detail_'+index]} type="line" height={50} />
                                                                                : null}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-5 align-self-center">
                                                                    <div class="d-flex justify-content-between">
                                                                        <div>
                                                                            <small>From</small>
                                                                            <p class="text-warning font-weight-bold f16">{this.state.sliderStartDate && this.state.sliderStartDate !== ''? momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM YYYY HH:mm') : ''}</p>
                                                                        </div>
                                                                        <div>
                                                                            <small>To</small>
                                                                            <p class="text-warning font-weight-bold f16">{this.state.sliderEndDate && this.state.sliderEndDate !== ''? momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM YYYY HH:mm') : ''}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            : null}    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="bg-dark rounded my-3 px-3 pt-3">
                            <div class="d-flex justify-content-between">
                                <div className="">
                                    <p class="f16 text-white mb-1">API Error/Request Rate</p>
                                    <p class="f12 text-white">{this.state.show_error_rate ? 'Showing overall API Error/Request Rate' : 'Showing the API Error/Request Rate by metrics'} for {res.asset_name ? (res.asset_name+' asset') : ''}</p>
                                </div>
                                {this.state.show_error_rate ?
                                    <button class="bg-transparent border-0 align-self-center text-white p-0 z999" >
                                        <i class="far fa-expand-wide" onClick={() => this.setState({ show_error_rate: false, show_detail_error_rate: true })}></i>
                                    </button>
                                :
                                    <button class="bg-transparent border-0 align-self-center text-white p-0 z999" >
                                        <i className="far fa-compress cursorPointer" onClick={() => this.setState({ show_error_rate: true, show_detail_error_rate: false })}></i>
                                    </button>
                                }
                            </div>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="mb-3 col-12 px-0">
                                        <div class="p-3 rounded bg-dark3">
                                            {this.state.show_error_rate && this.state['rate_series_'+index] ?
                                                <div className={`mt-n2 ml-n3 ${this.props.className}`}>
                                                    <Chart options={this.state['rate_options_'+index]} series={this.state['rate_series_'+index]} type="line" height={this.props.height} />
                                                    <div class="container-fluid">
                                                        <div class="row mt-3">
                                                            <div class="col-md-7 align-self-center">
                                                                <div class="brush-timeline bg-dark">
                                                                    <div class="border-timeline rounded">
                                                                        <div className={`aiopsSre`}>
                                                                            <Chart options={this.state['rate_brushOptions_'+index]} series={this.state['rate_brushSeries_'+index]} type="line" height={50} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-5 align-self-center">
                                                                <div class="d-flex justify-content-between">
                                                                    <div>
                                                                        <small>From</small>
                                                                        <p class="text-warning font-weight-bold f16">{this.state.sliderStartDate && this.state.sliderStartDate !== ''? momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM YYYY HH:mm') : ''}</p>
                                                                    </div>
                                                                    <div>
                                                                        <small>To</small>
                                                                        <p class="text-warning font-weight-bold f16">{this.state.sliderEndDate && this.state.sliderEndDate !== ''? momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM YYYY HH:mm') : ''}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            : this.state.show_detail_error_rate ?
                                                <div className="mt-1">
                                                    <div className="row">
                                                        {this.state.errorRateArray && this.state.errorRateArray.length && this.state.errorRateArray.map((item, j) => {
                                                            return(
                                                                <div className={`${j % 2 === 0 && j === (this.state.errorRateArray.length-1) ? 'col-sm-12' : (j % 2 !== 0 ? 'col-sm-6' : 'col-sm-6')} mb-3`}>
                                                                <div className={`border rounded p-3`}>
                                                                    <div className="d-flex">
                                                                    <p class="f16 text-white mb-1 mr-2">{item.key}</p>
                                                                        <div className="d-flex align-self-center">
                                                                            <div className="mr-3 w-fit-content">
                                                                                <div className={`multiSelection-minWidth removeMargin z999 removeDropdownSearchBar position-relative bg-muted`}>
                                                                                    <ReactMultiSelectCheckboxes						
                                                                                        placeholderButtonLabel="All"
                                                                                        getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedCount', this.state['selected_rate_'+index+'_'+j])}
                                                                                        // menuIsOpen ={this.state.isannotationTypeOpen}
                                                                                        value={this.state['selected_rate_'+index+'_'+j] && this.state['selected_rate_'+index+'_'+j].map(itm => ({
                                                                                            value: itm.value,
                                                                                            label: itm.label,
                                                                                            text: itm.text,
                                                                                        }))}
                                                                                        onChange={arr => {
                                                                                        this.handleMultiSelectChange('selected_rate', index, j, item.unit, arr ? arr : []) }}
                                                                                        options={this.state['threshold_rate_'+index+'_'+j] && this.state['threshold_rate_'+index+'_'+j].map((itm, i) => ({
                                                                                            value: itm.value,
                                                                                            label: <span><span className={`mr-1 multiSelectSquareBox bg-${i === 0 ? 'warning' : i === 1 ? 'danger' : i === 2 ? 'info' : 'success'}`}></span>{itm.label}</span>,
                                                                                            text: itm.label
                                                                                        }))}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={`mt-n3 ml-n3 ${this.props.className}`}>
                                                                        <Chart options={this.state['options_rate_detail_'+j]} series={this.state['series_rate_detail_'+j]} type={`${j % 2 === 0 ? 'bar' : 'line'}`} height={150} width={"100%"} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            )
                                                        })}
                                                        <div class="container-fluid">
                                                            <div class="row">
                                                                <div class="col-md-7 align-self-center">
                                                                    <div class="brush-timeline bg-dark">
                                                                        <div class="border-timeline rounded">
                                                                            <div className={`aiopsSre`}>
                                                                                {this.state['series_rate_brush_detail_'+index] ?
                                                                                    <Chart options={this.state['options_rate_brush_detail_'+index]} series={this.state['series_rate_brush_detail_'+index]} type="line" height={50} />
                                                                                : null}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-5 align-self-center">
                                                                    <div class="d-flex justify-content-between">
                                                                        <div>
                                                                            <small>From</small>
                                                                            <p class="text-warning font-weight-bold f16">{this.state.sliderStartDate && this.state.sliderStartDate !== ''? momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM YYYY HH:mm') : ''}</p>
                                                                        </div>
                                                                        <div>
                                                                            <small>To</small>
                                                                            <p class="text-warning font-weight-bold f16">{this.state.sliderEndDate && this.state.sliderEndDate !== ''? momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM YYYY HH:mm') : ''}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    )
                }) 
                : this.state.showLoading ?
                    <div class="bg-dark rounded p-3 my-3">
                        <p class="f16 text-white mb-2">Latency / Request / Error Rate</p>
                        <div className='d-flex justify-content-center m-4'>
                            <Spinner className='text-center' color='white' size='lg' />
                        </div>
                    </div>
                : this.state.showLoading && this.state.sreDetails && !this.state.sreDetails.length ? 
                    <div class="bg-dark rounded p-3 my-3">
                        <p class="f16 text-white mb-2">Latency / Request / Error Rate</p>
                        <div className='d-flex justify-content-center m-4'>
                            There is no SRE Metrics for {this.props.assetDetails && this.props.assetDetails.asset_name ? this.props.assetDetails.asset_name : ''}
                        </div>
                    </div>
                : null
        )
    }
}

/**
 * Type of the props used in the component
 */
SreMetricDetails.propTypes = {
    getSreDetails: PropTypes.func,
    setDiagnosticsPropsDetails: PropTypes.func,
}   

const mapStateToProps = state => {
    return {
        diagnosticSreMertricDetails: state.aiops.diagnosticsPropsDetails && state.aiops.diagnosticsPropsDetails.diagnosticSreMertricDetails ? state.aiops.diagnosticsPropsDetails.diagnosticSreMertricDetails : {},

        diagnosticRcaSreMertricDetails: state.aiops.diagnosticsPropsDetails && state.aiops.diagnosticsPropsDetails.diagnosticRcaSreMertricDetails ? state.aiops.diagnosticsPropsDetails.diagnosticRcaSreMertricDetails : {},
    }
}

export default connect(mapStateToProps, {
    getSreDetails,
    setDiagnosticsPropsDetails
})(withRouter(SreMetricDetails))