/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ManageAutomations.js
 * @author Prakash // on 16/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { listAllProviders, listAllAccounts, listAllRegions, setAutomationPropsDetails } from '../../actions/commonAction'
import { listAutomationPlans, editAutomation, enableDisableAutomation } from '../../actions/AutomationAction'
import { AppWrapper } from '../common/AppWrapper'
import { capitalizeTheFirstLetterOfEachWord } from '../../utils/utility'
import { URL_PATH } from '../../config/urlPath'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

import ListAutomationsNew from './ListAutomationsNew'
import ListPreBuiltAutomations from './ListPreBuiltAutomations'

const automationTypeOptions = ["All", "Application", "Remediation"]
const tirggerTypeOptions = ["All", "cloudwatch_alarm"]

class ManageAutomations extends Component {
	accountRef = React.createRef()
	regionRef = React.createRef()

	constructor(props) {
		super(props)

		this.state = {
			showLoading: true,

			providers: [],
			selectedProvider: '',

			accounts:[],
			selectedAccount: [],
			isAccountOpen: false,

			region: [],
			selectedRegion: [],
			isRegionOpen: false,

			automationType: "All",
			triggerType: "All",

			activeTab: "Automations"

		}
	}

	componentDidMount = () => {
		if(this.props.providers && this.props.providers.length) {
			let providers = this.props.providers.filter(arr => arr.provider_name !== 'All')
			this.setState({ providers, selectedProvider: providers[0].provider_name },
				() => this.getCommonFilters()
			)
		} else {
			this.props.listAllProviders((promise, response) => {
				if(promise) {
					if(response.length && response.length === 1) {
						this.setState({ selectedProvider: response[0].provider_name },
							() => this.getCommonFilters()
						)
					} else {
						this.getCommonFilters()
					}
				} else {
					this.getCommonFilters()
				}
			})
		}		
	}
	

	/**
	 * Filter identifiers based on the filter selection
	 */
	getCommonFilters = from => {
		// this.setState({ showLoading: true })
		let params = {}
		if (this.state.selectedProvider) {
			params.provider = this.state.selectedProvider.toLowerCase()
		}

		
        if(this.props.accounts && this.props.accounts.length) {
			let allAccounts = this.props.accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
			if(this.props.accounts.length) {
				let accRow = {}
				accRow.account_id = "All"
				accRow.account_name = "All"
				accRow.date = null
				accRow.disabled = false
				accRow.provider = "All"
				allAccounts.unshift(accRow);
			}
			this.setState({ accounts: allAccounts })
		} else {
			this.props.listAllAccounts(params, (promise, allAccounts) => {
				if (promise) {						
					let filterdAccounts = allAccounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
					if(filterdAccounts.length) {
						let accRow = {}
						accRow.account_id = "All"
						accRow.account_name = "All"
						accRow.date = null
						accRow.disabled = false
						accRow.provider = "All"
						filterdAccounts.unshift(accRow);
					}						
					this.setState({ accounts: filterdAccounts })
				}
			})
		}

		if(this.props.regions && this.props.regions.length) {
			let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
			this.setState({ regions: providerRegions })
		} else {
			this.props.listAllRegions(params, (promise, regions) => {
				if (promise) {
					let providerRegions = regions && regions[0] && regions[0].provider_regions
					if(providerRegions) {
						let provider_regions_row = {}
						provider_regions_row.availability_zones = []
						provider_regions_row.city = "All"
						provider_regions_row.country = "All"
						provider_regions_row.geography = "All"
						provider_regions_row.name = "All"
						provider_regions_row.region = "All"
						provider_regions_row.region_id = "All"
						provider_regions_row.state = "All"
						providerRegions.unshift(provider_regions_row);
					}
					this.setState({ regions: providerRegions })
				} else {
					this.setState({ regions: [] })
				}
			})
		}

		this.onSearch("onLoad")
	}

	onSearch = (type) => {
        if(this.state.changesInFilter || type === "onLoad") {
            this.setState({ 
                changesInFilter: false,
                showLoading: true,
                resetEnabled: type === 'onLoad' ? false : true
            },
                () => {
					this.setState({ showLoading: false })
					// this.listAutomationPlans()
                }
            )
        }
		
	}
	
	onReset = () => {
		this.setState({ 
            selectedProvider: this.state.providers && this.state.providers.length === 1 ? this.state.providers[0].provider_name : "",
            selectedAccount: [],
            selectedRegion: [],
			automationType: "All",
            changesInFilter: this.state.resetEnabled,
        },
			() => this.onSearch()
        )
	}
	
	/**
	 * Method to handle create rule
	 */
	onClickCreateAutomaiton = () => {
		this.props.setAutomationPropsDetails({})
		this.props.history.push({
			pathname: URL_PATH.CREATE_AUTOMATION,
			state: { selectedRule: {} }
		})
	}

	handleClickOutside = (event) => {
		if (this.accountRef && !this.accountRef.current.contains(event.target)) {
			this.setState({ isAccountOpen: false })
		} else {
			this.setState({ isAccountOpen: true })
		}

		if (this.regionRef && !this.regionRef.current.contains(event.target)) {
			this.setState({ isRegionOpen: false })
		} else {
			this.setState({ isRegionOpen: true })
		}
	}

	handleMultiSelectChange = (field, arrayValue) => {
		let value = arrayValue.map(item => item.value)
		// let label = arrayValue.map(item => item)
		let selectedValue = []
		if(field === 'selectedAccount') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.accounts.forEach(acc => {
						selectedValue.push(acc.account_id)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.accounts.length -1)) {
					selectedValue.push('All')
				}
			}			
		} else if(field === 'selectedRegion') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.regions.length -1)) {
					selectedValue.push('All')
				}
			}
		}

		this.setState({ [field]: selectedValue, changesInFilter: true })
	}

	getMultiSelectedCount = (type, array) => {
		return array.length && array.filter(e => e.toLowerCase() === 'all').length ? 'All' :  array.length ? array.length +' Selected' : 'All'
	}

	/**
	 * Renders governance Dashboard
	*/
	render() {
		return (
			<div className='container-fluid overflow-auto flex-grow-1 bg-muted' onClick={ (event) => { this.handleClickOutside(event) } }>
				<div className='row h-100'>
					<div className='col-sm-12 p-0'>
						<div className='title-section py-2'>
							<div className='row'>
								<div className='col-4 align-self-center'>
									<h6 className='text-white m-0'>Automations</h6>
									<p className='text-white m-0'>
										Manage automations on your Cloud
									</p>
								</div>
								<div className='col-8 align-self-center'>
									<div className={`col-md-12 d-flex flex-wrap justify-content-end bd-highlight ${this.state.showLoading ? 'disabled' : ''}`}>
										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: '150px' }}>
											<label className="mb-1">Provider</label>
											<Select
												placeholder={'All'}
												isSearchable={false}
												//onFocus={this.openProivderMenu}
												className='f13 p-0 colorBlack reactSelectFilterDrop'
												value={({
													value: this.state.selectedProvider,
													label: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : <span className="placeholder">Select</span>
												})}
												options={this.state.providers.map(item => ({
													value: item.provider_name,
													label: item.provider_name,	
												}))}
												onChange={event =>  
													this.setState(
														{
															selectedProvider: event.value,
															changesInFilter: true
														}
													)
												}
											/>
										</div>
										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1 ${!this.state.providers.length}`} ref={this.accountRef} style={{maxWidth: '150px' }}>
											<label className="mb-1">Account</label>
											<div className='dlpHeaderMultiSelection removeDropdownSearchBar'>
												<ReactMultiSelectCheckboxes						
													placeholderButtonLabel="All"
													menuIsOpen ={this.state.isAccountOpen}
													getDropdownButtonLabel={() => this.getMultiSelectedCount('account', this.state.selectedAccount)}
													onChange={arr => {
													this.handleMultiSelectChange('selectedAccount', arr ? arr : []) }}
													options={this.state.accounts.map(account => ({
														value: account.account_id,
														label: account.account_name,
													}))}
													value={this.state.selectedAccount.map(comp => ({
														value: comp,
													}))}
												/>
											</div>
										</div>
										<div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 ${!this.state.providers.length}`} ref={this.regionRef} style={{maxWidth: '150px'}}>
											<label className="mb-1">Region</label>
											<div className={`dlpHeaderMultiSelection ${this.state.regions && this.state.regions.length > 10 ? '' : 'removeDropdownSearchBar' } `}>
												<ReactMultiSelectCheckboxes						
													placeholderButtonLabel="Select"
													menuIsOpen ={this.state.isRegionOpen}
													getDropdownButtonLabel={() => this.getMultiSelectedCount('region', this.state.selectedRegion)}
													onChange={arr => {
													this.handleMultiSelectChange('selectedRegion', arr ? arr : []) }}
													options={this.state.regions && this.state.regions.map(region => ({
														value: region.region,
														label: region.name,
													}))}
													value={this.state.selectedRegion.map(reg => ({
														value: reg,
													}))}
												/>
											</div>
										</div>
										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: '150px' }}>
											<label className="mb-1">Type</label>
											<Select
												placeholder={'All'}
												isSearchable={false}
												//onFocus={this.openProivderMenu}
												className='f13 p-0 colorBlack reactSelectFilterDrop'
												value={({
													value: this.state.automationType,
													label: this.state.automationType ? this.state.automationType : <span className="placeholder">Select</span>
												})}
												options={automationTypeOptions.map(item => ({
													value: item,
													label: item,	
												}))}
												onChange={event =>  
													this.setState({
														automationType: event.value,
														changesInFilter: true
													})
												}
											/>
										</div>
										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: '150px' }}>
											<label className="mb-1">Trigger</label>
											<Select
												placeholder={'All'}
												isSearchable={false}
												//onFocus={this.openProivderMenu}
												className='f13 p-0 colorBlack reactSelectFilterDrop'
												value={({
													value: this.state.triggerType,
													label: this.state.triggerType ? capitalizeTheFirstLetterOfEachWord(this.state.triggerType.replace("_", " ")) : <span className="placeholder">Select</span>
												})}
												options={tirggerTypeOptions.map(item => ({
													value: item,
													label: capitalizeTheFirstLetterOfEachWord(item.replace("_", " ")),
												}))}
												onChange={event =>  
													this.setState({
														triggerType: event.value,
														changesInFilter: true
													})
												}
											/>
										</div>
													
										<div className="mt-2 d-flex align-self-center">
											<div className="btn-group" role="group" aria-label="governance-dash">
												<button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-right`} onClick={()=> this.onSearch()}>Filter</button>
												<button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-left`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
											</div>
										</div>
										<div className="mt-2 ml-2 d-flex">
											<button type="button"  className='mr-w btn btn-primary align-self-center' onClick={() => this.onClickCreateAutomaiton()}>
												Create Automation
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						<div className="m-2 bg-dark h-90 rounded">
							<div className='container-fluid'>
								<div className="d-flex justify-content-between py-2">
									<ul className={`nav nav-tabs border-0 bd-highlight bg-transparent mt-n2 shadow-none mb-0 ${this.state.showLoading ? 'disabled' : ''}`} id="myTab" role="tablist">
										<li className="nav-item">
											<span className={`nav-link text-white cursorPointer ${this.state.activeTab === 'Automations' ? ' active' : ''}`} onClick={() => this.setState({ activeTab: 'Automations' })}>Automations</span>
										</li>
										<li className="nav-item">
											<span className={`nav-link text-white cursorPointer ${this.state.activeTab === 'Pre Built' ? ' active' : ''}`} onClick={() => this.setState({ activeTab: 'Pre Built' })}>Pre Built</span>
										</li>
									</ul>
								</div>
								{!this.state.showLoading ?
									this.state.activeTab === 'Automations' ? 
										<ListAutomationsNew 
											selectedProvider={this.state.selectedProvider}
											selectedAccount={this.state.selectedAccount}
											selectedRegion={this.state.selectedRegion}
											triggerType={this.state.triggerType}
										/>
									: 
										<ListPreBuiltAutomations 
											selectedProvider={this.state.selectedProvider}
											selectedAccount={this.state.selectedAccount}
											selectedRegion={this.state.selectedRegion}
											triggerType={this.state.triggerType}
										/>
								: null}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ManageAutomations.propTypes = {
	listAllProviders: PropTypes.func,
	listAllAccounts: PropTypes.func,
	listAllRegions: PropTypes.func,	
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('-',state)
	return {
		providers: state.filters.providers,
        accounts: state.filters.accounts,
		regions: state.filters.regions,
	}
}

export default AppWrapper(ManageAutomations, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
	setAutomationPropsDetails,
	listAutomationPlans,
	editAutomation,
	enableDisableAutomation
})