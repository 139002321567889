/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AutomationsLeftSection.js
 * @author Prakash // on 02/06/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { UncontrolledTooltip } from 'reactstrap'
import { listAllServices, getAllTagsKeys, getAllTagsValues } from '../../../../actions/commonAction'
import { setResourcePropsDetails } from '../../../../actions/assets/assetsAction'
import { listCoveredAssets } from '../../../../actions/detectors/detectorsAction'

import _ from 'lodash'
import { momentConvertionLocalToUtcTime, getAccountNameFromId, getRegionName } from '../../../../utils/utility'

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

import Search from '../../../common/SearchComponent'
import SearchWithHiddenInput from '../../../common/SearchWithHiddenInput'

const Categories = ["All", "compute", "Networking", "CDN", "Database", "Storage"]

class AutomationsLeftSection extends Component {
    constructor(props) {
        super(props)
        this.toggleRef = React.createRef()
        
        this.state = {
            minMaxHeight: 700,
            showLoading: true,

            startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
            overAllTotalPages: 1,
			perPage: 10,

            pageType: "Events",
            groupType: 'All',
            groupedBy: 'Impact',
            selectedCategory: 'Important / All',
            minimizeGraphSection: false,

            assetCategory: [],
            selectedService: [],
            tagsArray: [0],
        }
    }

	componentDidMount = () => {
		this.getWindowSize()
		window.addEventListener('resize', () => {
            this.getWindowSize()
        })

        if(this.props.dashboardDetails && Object.entries(this.props.dashboardDetails).length) {
            this.setState({ 
                start_time: this.props.dashboardDetails.start_time,
                end_time: this.props.dashboardDetails.end_time,
                brushStartTime: this.props.dashboardDetails.brushStartTime ? this.props.dashboardDetails.brushStartTime : this.props.dashboardDetails.start_time,
                brushEndTime: this.props.dashboardDetails.brushEndTime ? this.props.dashboardDetails.brushEndTime : this.props.dashboardDetails.end_time,
                selectedProvider: this.props.dashboardDetails.selectedProvider,
                selectedAccount: this.props.dashboardDetails.selectedAccount,
                selectedRegion: this.props.dashboardDetails.selectedRegion,
                selectedDuration: this.props.dashboardDetails.selectedDuration,
                selectedShowBy: this.props.dashboardDetails.selectedShowBy,
                showLoader: true,
            },
                () => {
                    this.listCoveredAssets()
                    this.listAllServices()
                }
            )
        }
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
        let headerHeihgt = 100
        let otherHeight = 20
		let outerHeight = (window.outerHeight - browserBorder - headerHeihgt - otherHeight)

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.dashboardDetails && Object.entries(this.props.dashboardDetails).length) {
            if(!_.isEqual(prevProps.dashboardDetails, this.props.dashboardDetails)) {
                this.setState({ 
                    start_time: this.props.dashboardDetails.start_time,
                    end_time: this.props.dashboardDetails.end_time,
                    brushStartTime: this.props.dashboardDetails.brushStartTime ? this.props.dashboardDetails.brushStartTime : this.props.dashboardDetails.start_time,
                    brushEndTime: this.props.dashboardDetails.brushEndTime ? this.props.dashboardDetails.brushEndTime : this.props.dashboardDetails.end_time,
                    selectedProvider: this.props.dashboardDetails.selectedProvider,
                    selectedAccount: this.props.dashboardDetails.selectedAccount,
                    selectedRegion: this.props.dashboardDetails.selectedRegion,
                    selectedDuration: this.props.dashboardDetails.selectedDuration,
                    selectedShowBy: this.props.dashboardDetails.selectedShowBy,
                    showLoader: true,
                },
                    () => {
                        this.listCoveredAssets()
                    }
                )
            }
        }
    }

    listAllServices = () => {
        if(!this.props.services || !this.props.services.length) {
            let params = {}
            if (this.state.selectedProvider !== "" && this.state.selectedProvider !== 'all') {
                params.provider = this.state.selectedProvider.toLowerCase()
            }
            this.props.listAllServices(params, (promise, services) => {})
        }
    }

    listCoveredAssets = () => {
        let params = {}
        params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartTime, "YYYY-MM-DD HH:mm:00")
        params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndTime, "YYYY-MM-DD HH:mm:00")
        params.provider = "aws"
        if(this.state.selectedAccount && !this.state.selectedAccount.includes("All")) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && !this.state.selectedRegion.includes("All")) {
            params.region = this.state.selectedRegion
        }
        // params.resource_type = resource_type ? resource_type : this.props.alertPageDetails.resource_type

        if(this.state.nextErrorListToken && this.state.nextErrorListToken !== '') {
            params.next_token = this.state.nextErrorListToken
        }
        params.size = 1000
        this.props.listCoveredAssets(params, (promise, response) => {
            if(promise) {
                let selectedAsset = ""
                if(response.results && response.results.length && (!this.state.selectedAsset || this.state.selectedAsset === "" )) {
                    selectedAsset = response.results[0]
                }

                let assetsList = [] 
                if(this.state.nextErrorListToken && this.state.nextErrorListToken !== '') {
                    assetsList = this.state.assetsList ? this.state.assetsList : []
                }
                
                if(response.results && response.results.length) {
                    assetsList = assetsList.concat(response.results)
                }

                // let results = response.results
                let totalPages = 1
                if(assetsList && assetsList.length > this.state.perPage) {
                    totalPages = Math.ceil(assetsList.length / this.state.perPage)
                }

                this.setState({ 
                    selectedAsset,
                    assetsList: assetsList, 
                    filteredAssetList: assetsList, 
                    filteredResults: assetsList,
                    showLoading: false,
                    totalPages, 
                    nextErrorListToken: response.next_token ? response.next_token : '',  },
                    ()  => {
                        if(this.state.filteredAssetList.length > this.state.endRecord) {
                            this.setState({ showLoadMore: true })
                        } else {
                            this.setState({ showLoadMore: false })
                        }

                        if(this.state.filteredAssetList.length) {
                            this.getAssetAutomations(this.state.filteredAssetList[0])
                        }
                    }
                )
            } else {
                this.setState({ assetsList: [], filteredAssetList: [], showLoading: false })
            }
        })
    }    

    nextSetOfRecord = () => {
        let lastEndRecord = this.state.endRecord
        let endRecord = this.state.endRecord + this.state.perPage
        this.setState({ endRecord },
            () => {
                if(lastEndRecord > this.state.filteredAssetList.length && this.state.nextErrorListToken !== "") {
                    this.listCoveredAssets()
                } else if(lastEndRecord > this.state.filteredAssetList.length) {
                    this.setState({ endRecord, showLoadMore: false })
                } else {
                    if(endRecord > this.state.filteredAssetList.length) {
                        this.setState({ showLoadMore: false })
                    }
                }
            }
        )
    }

    filterResults = () => {
        let results = this.state.assetsList
        if(this.state.assetCategory && this.state.assetCategory.length) {
            let data = []
            this.state.assetCategory.forEach(cat => {
                if(results.filter(e=> e.category.includes(cat)).length) {
                    let categoryResults = results.filter(e=> e.category.includes(cat))
                    data = data.concat(categoryResults)
                    data = data.filter((item,index) => { return (data.indexOf(item) === index) })
                }

            })
            results = data
        }
        if(this.state.selectedService && this.state.selectedService.length) {
            results = results.filter(e => this.state.selectedService.includes(e.service_name))
        }
        
        if(results && results.length && this.state.tagsArray && this.state.tagsArray.length) {
            let tagMatchedData = []
            let recordExist = false
            this.state.tagsArray.forEach(item => {
                if(this.state["tag_key_"+item] && this.state["tag_key_"+item] !== "" && this.state["tag_value_"+item] && this.state["tag_value_"+item] !== "") {
                    recordExist = true
                    if(tagMatchedData && tagMatchedData.length) {
                        let newTagsMatched = []
                        tagMatchedData.forEach(itm => {
                            if(itm.tags && itm.tags.length && itm.tags.filter(e => e.key === this.state["tag_key_"+item] && e.value === this.state["tag_value_"+item]).length) {
                                newTagsMatched.push(itm)
                            }
                        })
                        tagMatchedData = newTagsMatched
                    } else {
                        results.forEach(itm => {
                            if(itm.tags && itm.tags.length && itm.tags.filter(e => e.key === this.state["tag_key_"+item] && e.value === this.state["tag_value_"+item]).length) {
                                tagMatchedData.push(itm)
                            }
                        })                        
                    }
                }
            })
            if(recordExist) {
                results = tagMatchedData
            }
        }

        if (this.state.searchText && this.state.searchText !== '') {
			results =
				results &&
				results.filter(item => {
					let isPresent = []
					isPresent = this.recursiveSearch(item, this.state.searchText)
						.flat()
						.filter(bool => bool === true)
					if (isPresent[0]) {
						return true
					} else {
						return false
					}
				})
		}

        let totalPages = 1
        if(results && results.length > this.state.perPage) {
            totalPages = Math.ceil(results.length / this.state.perPage)
        }
        let showLoadMore = false
        if(results.length > this.state.endRecord) {
            showLoadMore = true
        } 

        console.log("results", results)

        this.setState({ filteredAssetList: results, totalPages, startRecord: 0, currentPage: 1, showLoadMore },
            () => {
                if(this.state.filteredAssetList.length)
                this.getAssetAutomations(this.state.filteredAssetList[0])
            }
        )
    }

	// function to search every object in array by recurseive
	recursiveSearch = (item, searchValue) => {
		return Object.keys(item).map(key => {
			if (item[key] !== null && typeof item[key] == 'object') {
				return this.recursiveSearch(item[key], searchValue)
				// eslint-disable-next-line valid-typeof
			} else if (typeof item[key] === 'array') {
				return item.map((arrayElement, i) => this.recursiveSearch(arrayElement[i], searchValue))
			} else {
				// if(this.props.searchFilter.includes(key)){
				return item[key] !== null
					? item[key]
							.toString()
							.toLowerCase()
							.indexOf(searchValue.toString().toLowerCase()) > -1
					: false
				// }else{
				// return false;
				// }
			}
		})
	}
    
    searchData = (filteredAssetList) => {
        let totalPages = 1
        if(filteredAssetList && filteredAssetList.length > this.state.perPage) {
            totalPages = Math.ceil(filteredAssetList.length / this.state.perPage)
        }

        this.setState({ filteredAssetList, totalPages, currentPage: 1, startRecord: 0 },
            () => {
                if(filteredAssetList.length) {
                    this.getAssetAutomations(filteredAssetList[0])
                }
            }
        )
    }
    
    getAssetAutomations = (item) => {
        let obj = {}
        obj.selectedAsset = item
        this.props.setResourcePropsDetails('automationDetails', obj)

        this.setState({ selectedAsset: item, selectedAssetId: item.asset_id, showRightSideDetail: false },
            () => {
                this.setState({ showRightSideDetail: true })
            }
        )
    }

    addSection = (array) => {
        let rowList = this.state[array];
        if(this.state[array]) {
            let value = this.state[array][this.state[array].length - 1]
            value = value+1 
            rowList.push(value);
        }
        
        this.setState({[array]: rowList },
            // () => this.checkAllDataFilled()    
        )
    }

    removeSection = (item, i) => {
        this.setState({ ["tag_key_"+item]: "", ["tag_value_"+item]: "" },
            () => {
                let rowList = this.state.tagsArray;
                rowList.splice(i, 1);
                this.setState({ tagsArray: rowList })
            }
        )
    }

    resetFilterSelection = () => {
        this.state.tagsArray.forEach(item => {
            this.setState({ ["tag_key_"+item]: "", ["tag_value_"+item]: "" })
        })

        this.setState({ showFilterSection: !this.state.showFilterSection, tagsArray: [0], selectedService: [], assetCategory: [] },
            () => this.filterResults()    
        )
    }

    removefilter = (index, value, field) => {
        let filteredResult = this.state[field]
        filteredResult = this.state[field].filter(e => e !== value)
        
        // filteredResult = this.state[field].filter(e => e.key !== option.key && e.value !== option.value)
        this.setState({ [field]: filteredResult })
    }

    getAllTagsKeys = () => {
        let params = {}
        this.props.getAllTagsKeys(params, (promise, response) => {})
    }

    getAllTagsValues = (index) => {
        let params = {}
        params.tags = [{ "key": this.state["tag_key_"+index] }]
        this.props.getAllTagsValues(params, (promise, response) => {
            if(promise) {
                this.setState({ ["tagValues_"+index]: response })
            }
        })
    }
    
    handleMultiSelectChange = (field, arrayValue) => {
		let value = arrayValue.map(item => item.value)

		let selectedValue = []
		if(field === 'selectedService') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.regions.length -1)) {
					selectedValue.push('All')
				}
			}
            this.setState({ [field]: selectedValue })
		} else if(field === 'assetCategory') {
			let prevState = this.state[field] ? this.state[field] : []
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					Categories.forEach(item => {
						selectedValue.push(item)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (Categories.length -1)) {
					selectedValue.push('All')
				}
			}	
            this.setState({ [field]: selectedValue, changesInFilter: true },
                // () => this.filterResults()    
            )		
		}
    }
    
    getMultiSelectedCount = (type, array) => {
        return array.length && array.includes('All') ? 'All' : (array.length ? array.length +' Selected' : 'All')
    }  
    
	render() {
		return (
            <div className="overflowYAuto" id="scrollTop" style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
                <div className={`d-flex justify-content-between mx-1 mt-1 pr-2 pt-2 ${this.state.showFilterSection ? "bg-muted" : ""}`}>
                    {this.state.assetsList && this.state.assetsList.length ?
                        <div className="m-2 w-60">
                            <SearchWithHiddenInput
                                data={this.state.assetsList}
                                applyLiteDarkTags={true}
                                searchClassName={'border-0'}
                                searchIconColor={'text-gray3'}
                                className="form-control-sm text-gray3 bg-dark3 border-0"
                                hideInputSection={true}
                                topClassName={'dark-search'}
                                searchPlaceHolder={'Search....'}                                            
                                filteredData={(filteredList) => this.setState({ filteredAssetList: filteredList, emptySearchText: false })}
                                resetSearchBox={this.state.emptySearchText}
                            />
                            {/* <Search
                                data={this.state.assetsList}
                                applyTags={false}
                                applyLiteDarkTags={true}
                                topClassName={'bg-black5 border-gray5 rounded-5 f12'}
                                searchClassName={'px-2 f12'}
                                searchIconColor={'text-gray5 f12'}
                                searchPlaceHolder={'Search....'}
                                className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                filteredData={(filteredList) => this.setState({ filteredAssetList: filteredList })}
                            /> */}
                            {/* <div className={`input-group mb-2 mr-sm-2 bg-black5 mr-1 border-gray5 rounded-5 f12 align-self-center`}>
                                <div className='input-group-prepend'>
                                    <div className="input-group-text bg-transparent px-2 f12">
                                        <i className="fal fa-search text-gray5 f12"></i>
                                    </div>
                                </div>
                                <input
                                    value={this.state.searchText}
                                    onChange={e => {
                                        this.setState({ searchText: e.target.value }, () => this.filterResults())
                                    }}
                                    type='text'
                                    className={`form-control form-control-sm bg-transparent text-white pl-0 f12`}
                                    placeholder='Search'
                                />
                            </div> */}
                        </div>
                    : null}
                    <i className="fas fa-filter align-self-center cursorPointer" onClick={() => this.setState({ showFilterSection: !this.state.showFilterSection }, () => this.getAllTagsKeys())}></i>
                </div>
                {this.state.showFilterSection ?
                    <div className="">
                        <div className=" mx-1 mb-2 p-2 zapInputDark bg-muted">
                            <div className="w-100">
                                <div className="d-flex flex-wrap mb-1">
                                    <label className="mb-0">Category</label>
                                    {this.state.assetCategory && this.state.assetCategory.length && !this.state.assetCategory.includes("All") ?
                                        this.state.assetCategory.map((cat, index) => {
                                            return(
                                                <span className="badge badge-secondary ml-2 mb-1">
                                                    {cat}
                                                    <i onClick={ () => this.removefilter(index, cat, 'assetCategory') } className='ml-1 fal fa-times cursorPointer'></i>
                                                </span>
                                            )
                                        })
                                    : this.state.assetCategory.includes("All") ?
                                        <span className="badge badge-secondary ml-2 mb-1">All</span>
                                    : null}
                                </div>                                                        
                                <div className={`multiselectDarkTheme align-self-center mr-2 z998`} ref={this.categoryRef}>
                                    <ReactMultiSelectCheckboxes						
                                        placeholderButtonLabel="All"
                                        getDropdownButtonLabel={() => this.getMultiSelectedCount('assetCategory', this.state.assetCategory)}
                                        // menuIsOpen ={this.state.isCategoryOpen}
                                        value={this.state.assetCategory && this.state.assetCategory.map(cat => ({
                                            value: cat
                                        }))}
                                        onChange={arr => { this.handleMultiSelectChange('assetCategory', arr ? arr : []) }}
                                        options={Categories.map(item => ({
                                            value: item,
                                            label: item,
                                        }))}
                                    />
                                </div>
                            </div>
                            <div className="w-100 mt-2">
                                <div className="d-flex flex-wrap mb-1">
                                    <label className="mb-0">Services</label>
                                    {this.state.selectedService && this.state.selectedService.length && !this.state.selectedService.includes("All") ?
                                        this.state.selectedService.map((ser, index) => {
                                            return(
                                                <span className="badge badge-secondary ml-2 mb-1">
                                                    {ser}
                                                    <i onClick={ () => this.removefilter(index, ser, 'selectedService') } className='ml-1 fal fa-times cursorPointer'></i>
                                                </span>
                                            )
                                        })
                                    : this.state.selectedService.includes("All") ?
                                        <span className="badge badge-secondary ml-2 mb-1">All</span>
                                    : null}
                                </div>
                                <div className={`multiselectDarkTheme align-self-center mr-2 z999`}>
                                    <ReactMultiSelectCheckboxes						
                                        placeholderButtonLabel="All"
                                        // menuIsOpen ={this.state.serviceDropdown}
                                        getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedService', this.state.selectedService)}
                                        value={this.state.selectedService.map(service => ({
                                            value: service,
                                            label: service
                                        }))}
                                        onChange={arr => { this.handleMultiSelectChange('selectedService', arr ? arr : []) }}
                                        options={this.props.services.map(service => ({
                                            value: service.service_name,
                                            label: service.service_name,
                                        }))}
                                    />
                                </div>
                            </div>
                            <div className={`w-100 mt-2`}>
                                <label className="mb-1">Tags</label>
                                {this.state.tagsArray.map((index, i) => {
                                    return(
                                        <div className={`w-100 ${i ? "mt-2 border-top-black4" : ""}`}>
                                            <div className={`d-flex ${!i ? "" : "mt-3"}`}>
                                                <div className="w-80">
                                                    <Select
                                                        placeholder={'Select'}
                                                        isSearchable={true}
                                                        // menuIsOpen={true}
                                                        className='f13 p-0 dark-select-theme-with-dropdown-indicator'
                                                        value={({
                                                            value: this.state["tag_key_"+index] && this.state["tag_key_"+index] !== "" ? this.state["tag_key_"+index] : 'Select',
                                                            label: this.state["tag_key_"+index] && this.state["tag_key_"+index] !== "" ? this.state["tag_key_"+index] : <span className="placeholder">Select</span>
                                                        })}
                                                        options={this.props.tagKeys && this.props.tagKeys.map(item => ({
                                                            value: item,
                                                            label: item,	
                                                        }))}
                                                        onChange={event => this.setState({
                                                            ["tag_key_"+index]: event.value,
                                                            ["tagValues_"+index]: [],
                                                        },
                                                            () => this.getAllTagsValues(index)
                                                        )}
                                                    />
                                                    <Select
                                                        placeholder={'Select'}
                                                        isSearchable={true}
                                                        // menuIsOpen={true}
                                                        className='f13 p-0 dark-select-theme-with-dropdown-indicator mt-2'
                                                        value={({
                                                            value: this.state["tag_value_"+index] && this.state["tag_value_"+index] !== "" ? this.state["tag_value_"+index] : 'Select',
                                                            label: this.state["tag_value_"+index] && this.state["tag_value_"+index] !== "" ? this.state["tag_value_"+index] : <span className="placeholder">Select</span>
                                                        })}
                                                        options={this.state["tagValues_"+index] && this.state["tagValues_"+index].map(item => ({
                                                            value: item,
                                                            label: item,	
                                                        }))}
                                                        onChange={event => this.setState({
                                                            ["tag_value_"+index]: event.value
                                                        })}
                                                    />
                                                </div>
                                                {this.state.tagsArray.length > 1 ?
                                                    <span className={`far fa-trash cursor-pointer f18 align-self-center ml-4`} onClick={() => this.removeSection(index, i)}></span>
                                                : null}
                                                {(this.state.tagsArray.length - 1) ===  i ?
                                                    <span className={`far fa-plus cursor-pointer f18 align-self-center ml-4`} onClick={() => this.addSection("tagsArray")}></span>
                                                : null}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="d-flex justify-content-end mt-3">
                                <button className={`btn btn-sm bg-info text-white mr-2`} onClick={() => this.setState({ showFilterSection: false }, () => this.filterResults())}>Filter</button>
                                <button className={`btn btn-sm btn-light`} onClick={() => this.resetFilterSelection()}>Clear</button>
                            </div>
                        </div>
                    </div>
                : null}

                {this.state.filteredAssetList && this.state.filteredAssetList.length ?
                    this.state.filteredAssetList.slice(this.state.startRecord, this.state.endRecord).map((item, i) => {
                        return (
                            <React.Fragment>
                            <div className={`px-2 cursorPointer ${i ? "border-top" : ""} ${this.state.selectedAsset && this.state.selectedAsset.asset_id === item.asset_id ? 'bg-dark3 text-info disabled' : ''}`} id={"asset_name_"+i} onClick={() => this.getAssetAutomations(item)}>
                                <div className="d-flex">
                                    <p className={`m-0 f-20 pl pr-1 pt-2 pb-1 hiddenEllipses ${this.state.selectedAsset && this.state.selectedAsset.asset_id === item.asset_id ? 'text-info' : 'text-white'}`}>
                                        {item.id_name_map && item.id_name_map !== "" ?
                                            <React.Fragment>
                                                <span className={`ml-1 hiddenEllipses`}>{(item.id_name_map.value ? item.id_name_map.value : "")}</span>
                                                <span className={`ml-1 hiddenEllipses ${this.state.selectedAsset && this.state.selectedAsset.asset_id === item.asset_id ? 'text-info' : 'text-primary-color'} mb-0`}>
                                                    {item.id_name_map.key ? " : "+item.id_name_map.key : ""}
                                                </span>
                                            </React.Fragment>
                                        :
                                            <React.Fragment>
                                            <span className={`ml-1 hiddenEllipses`}>{item.asset_name}</span>
                                            {item.resource_type ?
                                                item.resource_type === "ApiGateway" ?
                                                    item.rest_api_name && item.rest_api_name !== "" ?
                                                        <p className={`ml-1 hiddenEllipses ${this.state.selectedAsset && this.state.selectedAsset.asset_id === item.asset_id ? 'text-info' : 'text-primary-color'} mb-0`}>({item.rest_api_name})</p>
                                                    : null
                                                : item.resource_type === "EC2" ?
                                                    item.tags && item.tags.length && item.tags.filter(e => e.key === "Name").length ?
                                                        <p className={`ml-1 hiddenEllipses ${this.state.selectedAsset && this.state.selectedAsset.asset_id === item.asset_id ? 'text-info' : 'text-primary-color'} mb-0`}>({item.tags.filter(e => e.key === "Name")[0].value})</p>
                                                    : null
                                                : null
                                            : null}
                                            </React.Fragment>
                                        }
                                    </p>
                                </div>
                                <div class="small hiddenEllipses pb-2">
                                    {item.resource_type ?  ('  ')+item.resource_type : <span>&nbsp;</span>}
                                    {item.service_name ?  (' : ')+item.service_name : <span>&nbsp;</span>}
                                </div>
                                <div class="small hiddenEllipses px-2 pb-2 displayNone">
                                    {item.provider ? item.provider.toUpperCase() : <span>&nbsp;</span>}
                                    {item.account_id ? (' : ')+getAccountNameFromId(item.account_id, this.props.accounts) : <span>&nbsp;</span>}
                                    {item.region ?  (' : ')+getRegionName(item.region, this.state.regions) : <span>&nbsp;</span>}
                                    {item.resource_type ?  ('  ')+item.resource_type : <span>&nbsp;</span>}
                                    {item.service_name ?  (' : ')+item.service_name : <span>&nbsp;</span>}
                                </div>
                                {/* <div className="d-flex flex-wrap mb-1">
                                    <p className="mr-1 mb-0">{item.service_name ? item.service_name : ""}</p>
                                    {item.alerts ?
                                        <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">Alerts {item.alerts}</span>
                                    : null}
                                    {item.anomalies ?
                                        <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">Anomalies {item.anomalies}</span>
                                    : null}
                                    {item.errors ?
                                        <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">Errors {item.errors}</span>
                                    : null}
                                    {item.events ?
                                        <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">Events {item.events}</span>
                                    : null}
                                </div> */}
                                
                                <UncontrolledTooltip placement='top' target={"asset_name_"+i}>
                                    <div className="uncontrolledTooltipLeftAlign">
                                        <p className="mb-1 text-primary-color wordBreakAll"><span className="text-white mr-1">Asset: </span> 
                                            {item.id_name_map && item.id_name_map !== "" ?
                                                <span className={`ml-1`}>{(item.id_name_map.value ? item.id_name_map.value : "")}</span>
                                            :
                                                <span className={`ml-1`}>{item.asset_name}</span>
                                            }
                                        </p>
                                        {item.id_name_map && item.id_name_map !== "" ?
                                            item.id_name_map.key ? 
                                                <p className={`mb-1 wordBreakAll`}>{item.id_name_map.key}</p>
                                            : null
                                        :
                                            item.resource_type ?
                                                item.resource_type === "ApiGateway" ?
                                                    item.rest_api_name && item.rest_api_name !== "" ?
                                                        <p className={`mb-1 wordBreakAll`}>{item.rest_api_name})</p>
                                                    : null
                                                : item.resource_type === "EC2" ?
                                                    item.tags && item.tags.length && item.tags.filter(e => e.key === "Name").length ?
                                                        <p className={`mb-1 wordBreakAll`}>{item.tags.filter(e => e.key === "Name")[0].value}</p>
                                                    : null
                                                : null
                                            : null
                                        }
                                        <p className="mb-1 text-primary-color">
                                            <span className="text-white mr-1">Resources: </span>
                                            {item.provider ? item.provider.toUpperCase() : <span>&nbsp;</span>}
                                            {item.account_id ? (' : ')+item.account_id : <span>&nbsp;</span>}
                                            {item.region ?  (' : ')+getRegionName(item.region, this.state.regions) : <span>&nbsp;</span>}
                                        </p>
                                        <p className="mb-1 text-primary-color">
                                            <span className="text-white mr-1">Services: </span>
                                            {item.resource_type ?  ('  ')+item.resource_type : <span>&nbsp;</span>}
                                            {item.service_name ?  (' : ')+item.service_name : <span>&nbsp;</span>}
                                        </p>
                                        {/* <div className="d-flex flex-wrap">
                                            {item.alerts ?
                                                <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">Alerts {item.alerts}</span>
                                            : null}
                                            {item.anomalies ?
                                                <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">Anomalies {item.anomalies}</span>
                                            : null}
                                            {item.errors ?
                                                <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">Errors {item.errors}</span>
                                            : null}
                                            {item.events ?
                                                <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">Events {item.events}</span>
                                            : null}
                                        </div> */}                                        
                                    </div>    
                                </UncontrolledTooltip>
                            </div>
                            </React.Fragment>
                        )
                    })
                : 
                    <div className='d-flex justify-content-center m-4'>
                        <p>There are no data on this criteria. Please try adjusting your filter.</p>
                    </div>
                }
                {this.state.showLoadMore ?
                    <div className="text-center text-info cursorPointer my-2" onClick={() => this.nextSetOfRecord()}> Load More<i className="fas fa-angle-double-down ml-1"></i></div>
                : null}
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
AutomationsLeftSection.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('AutomationsLeftSection',state)
	return {
        accounts: state.filters.accounts,
		regions: state.filters.regions,
        services: state.filters.services,
        tagKeys: state.filters.tagKeys,
        dashboardDetails: state.assets.resourcesPropsDetails && state.assets.resourcesPropsDetails.dashboardDetails ? state.assets.resourcesPropsDetails.dashboardDetails : {},
    }
}

export default connect(mapStateToProps, {
    listAllServices,
    getAllTagsKeys,
    getAllTagsValues,
    listCoveredAssets,
    setResourcePropsDetails,
})(withRouter(AutomationsLeftSection))