/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file diagnosticsLeftSection.js
 * @author Prakash // on 13/01/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component  } from 'react'
import { Link } from 'react-router-dom'
import { URL_PATH } from '../../config/urlPath'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Search from '../common/SearchComponent'
import SearchWithHiddenInput from '../common/SearchWithHiddenInput'

import { momentConvertionUtcToLocalTime, capitalizeFirstLetter, getAccountNameFromId } from '../../utils/utility'

import { listAllAccounts, listAllRegions } from '../../actions/commonAction'
import { setDiagnosticsPropsDetails, getDiagnosticDetails } from '../../actions/aiops/DiagnosticsAction'


class DiagnosticsLeftSection extends Component {
	diagnosticsSlider = React.createRef()

    constructor(props) {
        super(props)

        this.state = {
            minMaxHeight: "700px",
            expandMenuId: "",
            activeCollapseMenu: "",
            showLoading: true,
            diagnosticType: "All",

            startRecord: 0,
            perPage: 1,
            currentPage: 1,
        }
    }
    
    componentDidMount = () => {
        this.getDiagnosticDetails()

        // window.ononline = (event) => {
        //     this.setState({ noInternet: false })
        // };

        let params = {}
		if (this.state.selectedProvider) {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		
        if(!this.props.accounts || !this.props.accounts.length) {
			this.props.listAllAccounts(params, (promise, response) => {})
		}

        if(!this.props.regions || !this.props.regions.length) {
            this.props.listAllRegions(params, (promise, regions) => {})
        }

        window.addEventListener("resize", () => {
            this.getWindowSize()
        })
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 150  
		

        let groupSection = 30
        if(this.state.groupedDiagnostics && this.state.groupedDiagnostics.length) {
            let groupRowsHeight = Math.ceil(this.state.groupedDiagnostics.length / 5)
            groupRowsHeight = 44 * groupRowsHeight

            groupSection = groupSection + groupRowsHeight
        }
        let outerHeight = (window.outerHeight - browserBorder - headerHeight - groupSection) +"px"

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }

    getDiagnosticDetails = () => {
        let params = {}
        params.diagnostic_id = this.props.diagnostics.diagnostic_id
        this.props.getDiagnosticDetails(params, (promise, response) => {
            if(promise) {
                this.setState({ diagnosticDetails: response.result, showLoading: false, expandMenuId: "", activeCollapseMenu: "", currentDiagnosticsId: this.state.diagnostic_id },
                    () => {
                        let obj = {}
                        obj.label = "assets_list"
                        obj.tag = ""
                        obj.data = this.state.diagnosticDetails ? this.state.diagnosticDetails : []
                        this.props.setDiagnosticsPropsDetails("selectedDiagnosticEvent", obj)

                        this.structureDiagnosticDetails()
                    }
                )
            } else {
                this.setState({ diagnosticDetails: {}, showLoading: false, expandMenuId: "", activeCollapseMenu: "" })
            }
        })
    }

    structureDiagnosticDetails = () => {
        let groupedDiagnostics = []
        let totalDiagnosticsData = []
        if(this.state.diagnosticDetails && Object.entries(this.state.diagnosticDetails).length) {
            let filteredData = this.state.diagnosticDetails.diagnostics
            // let filteredData = this.state.diagnosticDetails.diagnostics.filter(e => e.data.length)  //remove the diagnotstics which has now data
            if(filteredData && filteredData.length) {
                groupedDiagnostics = _.chain(filteredData).groupBy("tag")
                .map((value, key) => ({ 
                    label: key ? key : "Not Grouped", 
                    sub: _.orderBy(value, ["flag"], ["desc"]),
                    observedFlag: value.filter(e => e.flag).length 
                })).value()


                groupedDiagnostics = _.orderBy(groupedDiagnostics, ["observedFlag"], ["desc"])    
            }
            
            if(groupedDiagnostics && groupedDiagnostics.length) {
                totalDiagnosticsData = _.orderBy(filteredData, ["flag"], ["desc"])
            }
        }

        this.setState({ totalDiagnosticsData, groupedDiagnostics },
            () => {
                this.filterDiagnostics("All", this.state.totalDiagnosticsData)    
                this.getWindowSize()
            }
        )
    }

    filterDiagnostics = (type, selectedArray) => {

        let selectedDiagnosticsGroup = ""
        if(type !== "All") {
            if(type === this.state.selectedDiagnosticsGroup) {
                selectedArray = this.state.totalDiagnosticsData
            } else {
                selectedDiagnosticsGroup = type
            }
        }
        
        let observedDiagnostics = []
        selectedArray.forEach((item, index) => {
            if(item.label !== "DB SlowQuery Events") {
                if(item.label === "MySQL DB Changes" || item.label === "DB Audit PostgreSQL") {
                    let array = []
                    let totalCount = 0
                    let totalDatabases = item.data.length
                    item.data.forEach(changes => {
                        Object.entries(changes).forEach(([key, value], j) => {
                            if(key === "index_changes" || key === "table_changes" || key === "view_changes" || key === "other_changes") {
                                let dataRow = {}
                                dataRow.asset_id = changes.asset_id
                                dataRow.asset_name = changes.asset_name
                                dataRow.asset_arn = changes.asset_arn
                                dataRow.resource_type = changes.resource_type
                                dataRow.service_name = changes.service_name ? changes.service_name : ""
                                dataRow.provider = changes.provider ? changes.provider : ""
                                dataRow.region = changes.region ? changes.region : ""
                                dataRow.account = changes.account ? changes.account : ""

                                dataRow.key = key
                                dataRow.count = value.statistics.reduce(function(a, b){ return a + b["count"]; }, 0);
                                dataRow.action = dataRow.count > 1 ? "Changes" : "Change"
                                totalCount +=dataRow.count
                                dataRow.description = value.description
                                dataRow.details = value.details
                                array.push(dataRow)
                            }
                        })
                    })

                    this.setState({ ["diagnostic_label_"+index]: item.label, ["diagnostic_db_count_"+index]: totalDatabases, ["diagnostic_count_"+index]: totalCount, ["diagnostic_data_"+index]: array, ["filtered_diagnostic_data_"+index]: array })
                } else {
                    this.setState({ ["diagnostic_label_"+index]: item.label, ["diagnostic_data_"+index]: item.data, ["filtered_diagnostic_data_"+index]: item.data })
                }
                let detailView = false
                if(item.data && item.data.length) {
                    detailView = true
                }
                item.detailView = detailView
                observedDiagnostics.push(item)
            }
        })

        // observedDiagnostics = _.orderBy(observedDiagnostics, ["orderBy"], ["asc"])

        observedDiagnostics = [
            ...observedDiagnostics.filter(e => e.flag),
            ...observedDiagnostics.filter(e => !e.flag && e.detailView),
            ...observedDiagnostics.filter(e => !e.flag && !e.detailView),
        ];

        this.setState({ selectedDiagnosticsGroup, observedDiagnostics, searchData: observedDiagnostics, filteredObservedDiagnostics: observedDiagnostics })
    }

    filterResults = (event) => {
        let diagnosticType = this.state.diagnosticType
        if(event) {
            diagnosticType = event.target.value
        }
        let observedDiagnostics = this.state.observedDiagnostics

        if(diagnosticType !== "All") {
            if(diagnosticType === "observed") {
                observedDiagnostics = observedDiagnostics.filter(e => e.flag)
            } else {
                observedDiagnostics = observedDiagnostics.filter(e => !e.flag)
            }
        }

        if(this.state.searchText && this.state.searchText !== "") {
			observedDiagnostics =
				observedDiagnostics &&
				observedDiagnostics.filter(item => {
					let isPresent = []
					isPresent = this.recursiveSearch(item, this.state.searchText).flat(5).filter(bool => bool === true)
					if (isPresent[0]) {
						return true
					} else {
						return false
					}
				})
        }

        this.setState({ diagnosticType, filteredObservedDiagnostics: observedDiagnostics })
    }

	// function to search every object in array by recurseive
	recursiveSearch = (item, searchValue) => {
		return Object.keys(item).map(key => {
			if (item[key] !== null && typeof item[key] == "object") {
				return this.recursiveSearch(item[key], searchValue)
				// eslint-disable-next-line valid-typeof
			} else if (typeof item[key] === "array") {
				return item.map((arrayElement, i) => this.recursiveSearch(arrayElement[i], searchValue))
			} else {
				// if(this.props.searchFilter.includes(key)){
				return item[key] !== null
					? item[key]
							.toString()
							.toLowerCase()
							.indexOf(searchValue.toString().toLowerCase()) > -1
					: false
				// }else{
				// return false;
				// }
			}
		})
	}

    expandCollapse = (expandMenuId, label, tag) => {
        if(this.state.activeCollapseMenu !== expandMenuId) {
            this.onClickExpandMenu(expandMenuId, label, tag)
        } else {
            let obj = {}
            obj.label = "assets_list"
            obj.tag = ""
            obj.data = this.state.diagnosticDetails ? this.state.diagnosticDetails : []
            this.props.setDiagnosticsPropsDetails("selectedDiagnosticEvent", obj)
        }
        this.setState({ 
            expandMenuId: expandMenuId === this.state.expandMenuId ? "" : expandMenuId, 
            activeCollapseMenu: expandMenuId === this.state.expandMenuId ? "" : expandMenuId,
            startRecord: 0,
            currentPage: 1,
        })
    }

    onClickExpandMenu = (expandMenuId, label, tag) => {
        if(this.state.observedDiagnostics && Object.entries(this.state.observedDiagnostics).length) {
            this.state.observedDiagnostics && this.state.observedDiagnostics.forEach((item, index) => {
                if(item.label === "Application Changes" && label === "Application Changes") {
                    this.setState({ ["diagnostic_label_"+index]: item.label, ["diagnostic_data_"+index]: item.data, ["filtered_diagnostic_data_"+index]: item.data },
                        () => {
                            this.onChangeDiagnosticEvent(item.label, item.tag, item.data[0], index, "app_changes_"+index+"_0", item.data[0].asset_id, "")
                        }
                    )
                } else if((item.label === "MySQL DB Changes" && label === "MySQL DB Changes") || (item.label === "DB Audit PostgreSQL" && label === "DB Audit PostgreSQL")) {
                    let array = []
                    let totalCount = 0
                    let totalDatabases = item.data.length
                    item.data.forEach(changes => {
                        Object.entries(changes).forEach(([key, value], j) => {
                            if(key === "index_changes" || key === "table_changes" || key === "view_changes" || key === "other_changes") {
                                let dataRow = {}                                
                                dataRow.asset_id = changes.asset_id
                                dataRow.asset_name = changes.asset_name
                                dataRow.asset_arn = changes.asset_arn
                                dataRow.resource_type = changes.resource_type
                                dataRow.service_name = changes.service_name ? changes.service_name : ""
                                dataRow.provider = changes.provider ? changes.provider : ""
                                dataRow.region = changes.region ? changes.region : ""
                                dataRow.account = changes.account ? changes.account : ""

                                dataRow.key = key
                                dataRow.count = value.statistics.reduce(function(a, b){ return a + b["count"]; }, 0);
                                dataRow.action = dataRow.count > 1 ? "Changes" : "Change"
                                totalCount +=dataRow.count
                                dataRow.description = value.description
                                dataRow.details = value.details
                                array.push(dataRow)
                            }
                        })
                    })

                    this.setState({ ["diagnostic_label_"+index]: item.label, ["diagnostic_db_count_"+index]: totalDatabases, ["diagnostic_count_"+index]: totalCount, ["diagnostic_data_"+index]: array, ["filtered_diagnostic_data_"+index]: array },
                        () => {
                            this.onChangeDiagnosticEvent(item.label, item.tag, this.state["filtered_diagnostic_data_"+index][0], index, "db_"+index+"_0", this.state["filtered_diagnostic_data_"+index][0].asset_id, this.state["filtered_diagnostic_data_"+index][0].key)
                        }
                    )
                } else if(item.label === "Health Events" && label === "Health Events") {
                    this.setState({ ["diagnostic_label_"+index]: item.label, ["diagnostic_data_"+index]: item.data, ["filtered_diagnostic_data_"+index]: item.data },
                        () => {
                            this.onChangeDiagnosticEvent(item.label, item.tag, item.data[0], index, "health_event_"+index+"_0", "", "")
                        }
                    )
                } else if(item.label === "Maintenance and Scheduled Events" && label === "Maintenance and Scheduled Events") {
                    this.setState({ ["diagnostic_label_"+index]: item.label, ["diagnostic_data_"+index]: item.data, ["filtered_diagnostic_data_"+index]: item.data },
                        () => {
                            this.onChangeDiagnosticEvent(item.label, item.tag, item.data[0], index, "maintsch_event_"+index+"_0", "", "")
                        }
                    )
                } else if(item.label === "Capacity Changes" && label === "Capacity Changes") {
                    this.setState({ ["diagnostic_label_"+index]: item.label, ["diagnostic_data_"+index]: item.data, ["filtered_diagnostic_data_"+index]: item.data },
                        () => {
                            this.onChangeDiagnosticEvent(item.label, item.tag, item.data[0], index, "capacity_changes_"+index+"_0", item.data[0].asset_id, "")
                        }
                    )
                } else if(item.label === "General Changes" && label === "General Changes") {
                    this.setState({ ["diagnostic_label_"+index]: item.label, ["diagnostic_data_"+index]: item.data, ["filtered_diagnostic_data_"+index]: item.data },
                        () => {
                            if(item.data && item.data.length) {
                                this.onChangeDiagnosticEvent(item.label, item.tag, item.data[0], index, "infra_changes_"+index+"_0", item.data[0].asset_id, "")
                            } else if(item.data || (item.data && !item.data.length)) {
                                this.props.setDiagnosticsPropsDetails("selectedDiagnosticEvent", {})
                            }
                        }
                    )
                } else if((item.label === "Security Changes" && label === "Security Changes") || (item.label === "Performance Changes" && label === "Performance Changes") || (item.label === "Network Changes" && label === "Network Changes") || (item.label === "Configuration Changes" && label === "Configuration Changes") || (item.label === "Tag Changes" && label === "Tag Changes") || (item.label === "Disk Changes" && label === "Disk Changes") || (item.label === "Policy Changes" && label === "Policy Changes")) {
                    this.setState({ ["diagnostic_label_"+index]: item.label, ["diagnostic_data_"+index]: item.data, ["filtered_diagnostic_data_"+index]: item.data },
                        () => {
                            if(item.data && item.data.length) {
                                this.onChangeDiagnosticEvent(item.label, item.tag, item.data[0], index, "changes_"+index+"_0", item.data[0].asset_id, "")
                            } else if(item.data || (item.data && !item.data.length)) {
                                this.props.setDiagnosticsPropsDetails("selectedDiagnosticEvent", {})
                            }
                        }
                    )
                } else if(item.label === "SRE Observations" && label === "SRE Observations") {
                    this.setState({ ["diagnostic_label_"+index]: item.label, ["diagnostic_data_"+index]: item.data, ["filtered_diagnostic_data_"+index]: item.data },
                        () => {
                            if(item.data && item.data.length) {
                                this.onChangeDiagnosticEvent(item.label, item.tag, item.data[0], index, "sre_events_"+index+"_0", item.data[0].sre_asset_id, "")
                            } else if(item.data || (item.data && !item.data.length)) {
                                this.props.setDiagnosticsPropsDetails("selectedDiagnosticEvent", {})
                            }
                        }
                    )
                } else if(item.label === "DB SlowQuery Events" && label === "DB SlowQuery Events") {
                    this.setState({ ["diagnostic_label_"+index]: item.label, ["diagnostic_data_"+index]: item.data, ["filtered_diagnostic_data_"+index]: item.data },
                        () => {
                            this.onChangeDiagnosticEvent(item.label, item.tag, item.data[0], index, "db_slow_query_event_"+index+"_0", item.data[0].dbslowq_asset_id, "")
                        }
                    )
                } else if((item.label === "DB SlowQuery MySQL" && label === "DB SlowQuery MySQL") || (item.label === "DB SlowQuery PostgreSQL" && label === "DB SlowQuery PostgreSQL")) {
                    this.setState({ ["diagnostic_label_"+index]: item.label, ["diagnostic_data_"+index]: item.data, ["filtered_diagnostic_data_"+index]: item.data },
                        () => {
                            this.onChangeDiagnosticEvent(item.label, item.tag, item.data[0], index, "db_slow_query_mysql_"+index+"_0", "", "")
                        }
                    )
                } else if(item.label === "Athena Slow Query Analysis" && label === "Athena Slow Query Analysis") {
                    this.setState({ ["diagnostic_label_"+index]: item.label, ["diagnostic_data_"+index]: item.data, ["filtered_diagnostic_data_"+index]: item.data },
                        () => {
                            this.onChangeDiagnosticEvent(item.label, item.tag, item.data[0], index, "athena_analysis_"+index+"_0", "", "")
                        }
                    )
                } else if(item.label === "Reachability Analysis" && label === "Reachability Analysis") {
                    this.setState({ ["diagnostic_label_"+index]: item.label, ["diagnostic_data_"+index]: item.data, ["filtered_diagnostic_data_"+index]: item.data },
                        () => {
                            this.onChangeDiagnosticEvent(item.label, item.tag, item.data[0], index, "reachability_analysis_"+index+"_0", "", "")
                        }
                    )
                } else if(item.label === "Connectivity Checks" && label === "Connectivity Checks") {
                    this.setState({ ["diagnostic_label_"+index]: item.label, ["diagnostic_data_"+index]: item.data, ["filtered_diagnostic_data_"+index]: item.data },
                        () => {
                            this.onChangeDiagnosticEvent(item.label, item.tag, item.data[0], index, "connectivity_"+index+"_0", "", "")
                        }
                    )
                } else if(item.label === "Traffic Surge" && label === "Traffic Surge") {
                    this.setState({ ["diagnostic_label_"+index]: item.label, ["diagnostic_data_"+index]: item.data, ["filtered_diagnostic_data_"+index]: item.data },
                        () => {
                            this.onChangeDiagnosticEvent(item.label, item.tag, item.data[0], index, "traffic_surge_"+index+"_0", item.data[0].asset_id, "")
                        }
                    )                    
                } else if((label === "EC2 Service Limits" && item.label === "EC2 Service Limits") || (label === "RDS Service Limits" && item.label === "RDS Service Limits") || (label === "ElastiCache Service Limits" && item.label === "ElastiCache Service Limits") || (label === "KMS Service Limits" && item.label === "KMS Service Limits") || (label === "DynamoDB Service Limits" && item.label === "DynamoDB Service Limits") || (label === "SecretsManager Service Limits" && item.label === "SecretsManager Service Limits") || (label === "ApiGateway Service Limits" && item.label === "ApiGateway Service Limits") || (label === "SQS Service Limits" && item.label === "SQS Service Limits") || (label === "Lambda Service Limits" && item.label === "Lambda Service Limits") || (label === "SNS Service Limits" && item.label === "SNS Service Limits") || (label === "Database deadlocks" && item.label === "Database deadlocks")) {
                    this.setState({ ["diagnostic_label_"+index]: item.label, ["diagnostic_data_"+index]: item.data, ["filtered_diagnostic_data_"+index]: item.data },
                        () => {
                            if(item.data && item.data.length) {
                                this.onChangeDiagnosticEvent(item.label, item.tag, item.data[0], index, "limits_"+index+"_0", item.data[0].asset_id, "")
                            } else if(item.data || (item.data && !item.data.length)) {
                                this.props.setDiagnosticsPropsDetails("selectedDiagnosticEvent", {})
                            }
                        }
                    )
                } else if((label === "Operational Health Events" && item.label === "Operational Health Events") || (label === "Maintenance Scheduled Health Events" && item.label === "Maintenance Scheduled Health Events") || (label === "Availability Health Events" && item.label === "Availability Health Events") || (label === "Network Health Events" && item.label === "Network Health Events") || (label === "Security Health Events" && item.label === "Security Health Events") || (label === "Console Health Events" && item.label === "Console Health Events") || (label === "Connectivity Health Events" && item.label === "Connectivity Health Events") || (label === "API Health Events" && item.label === "API Health Events") || (label === "Error/Failure Health Events" && item.label === "Error/Failure Health Events") || (label === "Performance Health Events" && item.label === "Performance Health Events") || (label === "Security Health" && item.label === "Security Health ") || (label === "Limit Health Events" && item.label === "Limit Health Events") || (label === "Service Health Events" && item.label === "Service Health Events") || (item.label === "Update/Upgrade/Patch Changes" && label === "Update/Upgrade/Patch Changes") || (label === "Maintenance Events" && item.label === "Maintenance Events")) {
                    this.setState({ ["diagnostic_label_"+index]: item.label, ["diagnostic_data_"+index]: item.data, ["filtered_diagnostic_data_"+index]: item.data },
                        () => {
                            if(item.data && item.data.length) {
                                this.onChangeDiagnosticEvent(item.label, item.tag, item.data[0], index, "health_events_"+index+"_0", item.data[0].asset_id, "")
                            } else if(item.data || (item.data && !item.data.length)) {
                                this.props.setDiagnosticsPropsDetails("selectedDiagnosticEvent", {})
                            }
                        }
                    )
                } else if((tag === "Alerts" && item.tag === "Alerts" && expandMenuId === index) || (tag === "Latency" && item.tag === "Latency" && expandMenuId === index) || (tag === "ErrorRate" && item.tag === "ErrorRate" && expandMenuId === index) || (tag === "Anomalies" && item.tag === "Anomalies" && expandMenuId === index)) {
                    this.setState({ ["diagnostic_label_"+index]: item.label, ["diagnostic_data_"+index]: item.data, ["filtered_diagnostic_data_"+index]: item.data },
                        () => {
                            if(item.data && item.data.length) {
                                this.onChangeDiagnosticEvent(item.label, item.tag, item.data[0], index, "alerts_"+index+"_0", item.data[0].asset_id, "")
                            } else if(item.data || (item.data && !item.data.length)) {
                                this.props.setDiagnosticsPropsDetails("selectedDiagnosticEvent", {})
                            }
                        }
                    )
                } else if((tag === "Backup" && item.tag === "Backup" && expandMenuId === index) || (tag === "Failover" && item.tag === "Failover" && expandMenuId === index) || (tag === "Failure" && item.tag === "Failure" && expandMenuId === index) || (tag === "Replication" && item.tag === "Replication" && expandMenuId === index) || (tag === "Restoration" && item.tag === "Restoration" && expandMenuId === index) || (tag === "Recovery" && item.tag === "Recovery" && expandMenuId === index) ) {
                    this.setState({ ["diagnostic_label_"+index]: item.label, ["diagnostic_data_"+index]: item.data, ["filtered_diagnostic_data_"+index]: item.data },
                        () => {
                            if(item.data && item.data.length) {
                                this.onChangeDiagnosticEvent(item.label, item.tag, item.data[0], index, "events_"+index+"_0", item.data[0].asset_id, "")
                            } else if(item.data || (item.data && !item.data.length)) {
                                this.props.setDiagnosticsPropsDetails("selectedDiagnosticEvent", {})
                            }
                        }
                    )
                } else if((tag === "Errors" && item.tag === "Errors" && expandMenuId === index)) {
                    this.setState({ ["diagnostic_label_"+index]: item.label, ["diagnostic_data_"+index]: item.data, ["filtered_diagnostic_data_"+index]: item.data },
                        () => {
                            if(item.data && item.data.length) {
                                this.onChangeDiagnosticEvent(item.label, item.tag, item.data[0], index, "errors_"+index+"_0", item.data[0].asset_id, "")
                            } else if(item.data || (item.data && !item.data.length)) {
                                this.props.setDiagnosticsPropsDetails("selectedDiagnosticEvent", {})
                            }
                        }
                    )
                }
            })
        }
    }

    onChangeDiagnosticEvent = (label, tag, data, index, selectedEvent, asset_id, tableHeading) => {

        let asset_details = []
        if(this.state.diagnosticDetails) {
            if(label === "DB SlowQuery Events" || label === "Athena Slow Query Analysis" || label === "DB SlowQuery MySQL" || label === "Reachability Analysis") {
                let assetRow = {}
                assetRow.asset_id = data.dbslowq_asset_id ? data.dbslowq_asset_id : data.asset_id ? data.asset_id : ""
                assetRow.asset_name = data.dbslowq_asset_name ? data.dbslowq_asset_name : data.asset_name ? data.asset_name : ""
                assetRow.asset_arn = data.dbslowq_asset_arn ? data.dbslowq_asset_ar : data.asset_ar ? data.asset_ar : ""
                assetRow.resource_type = data.dbslowq_resource_type ? data.dbslowq_resource_type : data.resource_type ? data.resource_type : ""
                assetRow.service_name = data.dbslowq_service_name ? data.dbslowq_service_name : data.service_name ? data.service_name : ""
                assetRow.provider = data.dbslowq_provider ? data.dbslowq_provider : data.provider ? data.provider : ""
                assetRow.region = data.dbslowq_region ? data.dbslowq_region : data.region ? data.region : ""
                asset_details.push(assetRow)
            } else if(label === "Connectivity Checks") {
                let assetRow = {}
                assetRow.asset_id = data.conntchks_asset_id
                assetRow.asset_name = data.conntchks_asset_name
                assetRow.asset_arn = data.conntchks_asset_arn
                assetRow.resource_type = data.conntchks_resource_type
                assetRow.service_name = data.conntchks_service_name ? data.conntchks_service_name : ""
                assetRow.provider = data.conntchks_provider ? data.conntchks_provider : ""
                assetRow.region = data.conntchks_region ? data.conntchks_region : ""
                assetRow.account = data.conntchks_account ? data.conntchks_account : ""
                asset_details.push(assetRow)
            } else if(data.asset_id && data.asset_id !== "") {
                if(this.state.diagnosticDetails.assets) {
                    asset_details = this.state.diagnosticDetails.assets.filter(arr => arr.asset_id === data.asset_id)  
                    // if(!asset_details.length) {
                    //     asset_details = this.state.diagnosticDetails.assets.filter(arr => arr.asset_name === asset_id)
                    // }
                }
                if(!asset_details.length) {
                    let assetRow = {}
                    assetRow.asset_id = data.asset_id ? data.asset_id : ""
                    assetRow.asset_name = data.asset_name ? data.asset_name : ""
                    assetRow.asset_arn = data.asset_arn ? data.asset_arn : ""
                    assetRow.resource_type = data.resource_type ? data.resource_type : ""
                    assetRow.service_name = data.service_name ? data.service_name : ""
                    assetRow.provider = data.provider ? data.provider : ""
                    assetRow.account_id = data.account_id ? data.account_id : ""
                    assetRow.region = data.region ? data.region : ""
                    asset_details.push(assetRow)
                }
            } else if(data.assets) {
                asset_details = data.assets
            }
        }

        let assetDetails = asset_details.length ? asset_details[0] : {}

        if(assetDetails && assetDetails.asset_id && assetDetails.asset_id !== "") {
            if(!assetDetails.resource_type || assetDetails.resource_type === "") {
                assetDetails.resource_type = data.resource_type ? data.resource_type : ""
            }
            if(!assetDetails.service_name || assetDetails.service_name === "") {
                assetDetails.service_name = data.service_name ? data.service_name : ""
            }
            if(!assetDetails.provider || assetDetails.provider === "") {
                assetDetails.provider = data.provider ? data.provider : ""
            }
            if(!assetDetails.account_id || assetDetails.account_id === "") {
                assetDetails.account_id = data.account_id ? data.account_id : ""
            }
            if(!assetDetails.region || assetDetails.region === "") {
                assetDetails.region = data.region ? data.region : ""
            }
        }
        let obj = {}
        obj.label = label
        obj.eventGroup = tag
        obj.data = data
        obj.asset_details = assetDetails

        if(label === "MySQL DB Changes" || label === "DB Audit PostgreSQL") {
            let heading = tableHeading === "index_changes" ? "Index Changes" : tableHeading === "table_changes" ? "Table Changes" : tableHeading === "view_changes" ? "View Changes" : tableHeading === "other_changes" ? "Other Changes" : ""
            obj.tableHeading = heading          
        }

        this.setState({ 
            selectedDiagnostic: selectedEvent,
            selectedDiagnosticEvent: data 

        },
            () => this.props.setDiagnosticsPropsDetails("selectedDiagnosticEvent", obj)
        )
    }

    navigatePage = (action, currentPage, totalPages) => {
		let startRecord = this.state.startRecord
		if(action === "next" && currentPage !== totalPages) {
			startRecord = startRecord + this.state.perPage
			currentPage = currentPage + 1
		} else if(action === "previous" && currentPage !== 0) {
			currentPage = currentPage - 1
			startRecord = startRecord - this.state.perPage
		} else if(action === "start" && currentPage !== 0) {
			currentPage = 1
			startRecord = 0
		} else if(action === "end" && currentPage !== 0) {
			currentPage = totalPages
			startRecord = (totalPages - 1) * this.state.perPage
		}
		this.setState({ currentPage, startRecord })
    }

	render() {
		return (
            <div className="">
                <div className={`loaderOverlay ${this.state.showLoading ? "" : "displayNone"}`}>
                    <div className="overlayEqualizerLoader">
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                    </div>
                </div> 
                <div className="col-md-12 p-3 mt-2">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-self-center">
                            <div className="mr-3">
                                <Link className="text-info far fa-arrow-left" to={URL_PATH.LIST_DIAGNOSTICS}>
                                    <span className="ml-2 fontFamily">Back</span>
                                </Link>
                            </div>
                            <div className="">
                                <h6 className="mb-1 w-100">Observed Diagnostics</h6>
                                <p className="mb-0">Summary of analysis and observations of the chosen Cloud resources</p>
                            </div>
                        </div>
                        <div className="">
                            <p className="mb-1">
                                {this.state.diagnosticDetails && this.state.diagnosticDetails.provider ? this.state.diagnosticDetails.provider.toUpperCase() : null}
                                {this.state.diagnosticDetails && this.state.diagnosticDetails.account_id ? (" : ")+ getAccountNameFromId(this.state.diagnosticDetails.account_id, this.props.accounts) : null}
                                {this.state.diagnosticDetails && this.state.diagnosticDetails.region ?  (" : ")+this.state.diagnosticDetails.region : null}
                            </p>
                            <small className="mb-0 align-self-center text-info d-flex justify-content-end">{this.state.diagnosticDetails ? (this.state.diagnosticDetails.start_time && this.state.diagnosticDetails.end_time ? momentConvertionUtcToLocalTime(this.state.diagnosticDetails.start_time, "DD MMM HH:mm") +" - "+momentConvertionUtcToLocalTime(this.state.diagnosticDetails.end_time, "DD MMM HH:mm") : null) : null}</small>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 px-3">
                    <div className="mt-2 text-white">
                        <div className="d-flex mb-2 justify-content-between">
                            <div className={`d-flex ml-1 ${this.state.anomalyListLoader ? "disabled" : ""}`}>    
                                <p className="m-0 align-self-end small mr-2">Show </p>
                                <div class={`transparentSelectBorderBottom align-self-end mr-2`}>
                                    <select class="form-control" 
                                    onChange={this.filterResults}
                                    value={this.state.diagnosticType}
                                    >
                                        <option value="All">ALL</option>
                                        <option value="observed">Observed</option>
                                        <option value="not_observed">Not Observed</option>
                                    </select>
                                </div>
                                <p className="m-0 align-self-end small mr-2">{this.state.filteredObservedDiagnostics && this.state.filteredObservedDiagnostics.length} of total {this.state.totalDiagnosticsData && this.state.totalDiagnosticsData.length} diagnostics(s)</p>
                            </div>
                            <div className="d-flex justify-content-end w-40 mb-n2">                                
                                <Link 
                                    className="text-info mt-1"
                                    to= {{
                                        pathname: URL_PATH.DIAGNOSTICS_RCA,
                                        state: { 
                                            diagnostics: this.props.diagnostics
                                        }
                                    }}
                                    
                                >
                                    <span className="ml-2 fontFamily">Troubleshoot&nbsp;Details</span>
                                </Link>
                                {this.state.totalDiagnosticsData && this.state.totalDiagnosticsData.length ?
                                    <div className={`input-group ml-2 mb-2 mr-sm-2 bg-black5 mr-1 border-gray5 rounded-5 f12 align-self-center`}>
                                        <div className="input-group-prepend">
                                            <div className="input-group-text bg-transparent px-2 f12">
                                                <i className="fal fa-search text-gray5 f12"></i>
                                            </div>
                                        </div>
                                        <input
                                            value={this.state.searchText}
                                            onChange={e => {
                                                this.setState({ searchText: e.target.value }, () => this.filterResults())
                                            }}
                                            type="text"
                                            className={`form-control form-control-sm bg-transparent text-white pl-0 f12`}
                                            placeholder="Search"
                                        />
                                    </div>
                                : null}
                            </div>
                        </div>
                        {this.state.groupedDiagnostics && this.state.groupedDiagnostics.length ?
                            <React.Fragment>
                            <p className="my-1 text-primary-color">Showing the diagnostics observed in <span className="text-info">{this.state.diagnosticDetails && this.state.diagnosticDetails.name ? this.state.diagnosticDetails.name : ""}</span></p>
                            <div className={`row ml-1 mt-1 mb-2`}>
                                {this.state.groupedDiagnostics.map((item, index) => {
                                    return(
                                        <div className={`w-20 mb-2 cursorPointer pr-2`}>
                                            <div className={`p-2 bg-dark rounded ${this.state.selectedDiagnosticsGroup === item.label ? "border-info" : item.observedFlag ? "border-purplishRed" : "success-border"}`} onClick={() => this.filterDiagnostics(item.label, item.sub)}>
                                                <div className="d-flex">
                                                    <i className={`mr-2 align-self-center fa fa-circle ${item.observedFlag ? "text-purplishRed" : "text-success"}`}></i>
                                                    <span className={`small ${item.observedFlag ? "text-purplishRed" : "text-success"}`}>{item.label && item.label !== "undefined" ? item.label : "Others"} <span className={`small text-primary-color`}>({item.sub && item.sub.length})</span></span>
                                                </div>
                                                {/* <div className="" style={{paddingLeft:"3px"}}>
                                                    <i className={`fas fa-arrows-alt-v ${item.status === "Reachable" ? "text-info" : "text-purplishRed"} `}></i>
                                                </div> */}
                                                {/* <div className={`d-flex`}>
                                                    <span className={`small`}>{item.sub && item.sub.filter(e => e.flag).length} <span class={`ml-1 ${item.observedFlag ? "text-purplishRed" : "text-success"}`}>diagnostics observed</span></span>
                                                </div> */}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            </React.Fragment>
                        : null}
                        <div className="d-flex">
                            <div className={`w-100`}>
                                <div className={`bg-dark3 p-2 overflowYAuto`} style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
                                    {this.state.alertListLoader ? 
                                        <div className="d-flex justify-content-center m-4">
                                            {/* <Spinner className="text-center" color="white" size="lg" /> */}
                                        </div>
                                    : this.state.filteredObservedDiagnostics && this.state.filteredObservedDiagnostics.length ?
                                        this.state.filteredObservedDiagnostics.map((item, index) => {
                                            return(
                                                <React.Fragment>
                                                <div className={`p-2 ${index ? "border-top-black5" : ""} ${this.state.expandMenuId === index ? "bg-muted" : ""}`}> 
                                                    <div className={`mb-0 d-flex justify-content-between ${item.detailView ? "cursorPointer" : "disabled"}`} onClick={() => this.expandCollapse(index, item.label, item.tag)} data-toggle="collapse" data-target={"#collapse_"+index}aria-expanded="true" aria-controls={"collapse_"+index}>
                                                        <div><span className={`far ${this.state.expandMenuId === index ? "fa-minus-circle" : "fa-plus-circle"} mr-2`} ></span>{item.label}</div>
                                                        <div className="">
                                                            {(item.label === "MySQL DB Changes" || item.label === "DB Audit PostgreSQL") &&  this.state.expandMenuId !== index ?
                                                                <span className={` ${item.flag ? "text-purplishRed" : "text-success"}`}>Showing <span className="text-primary-color">{this.state["diagnostic_count_"+index] && this.state["diagnostic_count_"+index]}</span> changes in {item.label === "MySQL DB Changes" ? "MySQL database" : "PostgreSQL database"} </span>
                                                            : this.state["diagnostic_label_"+index] === "DB SlowQuery MySQL" && this.state.expandMenuId !== index ?
                                                                <span className={` ${item.flag ? "text-purplishRed" : "text-success"}`}>Showing <span className="text-primary-color">{this.state["filtered_diagnostic_data_"+index] && this.state["filtered_diagnostic_data_"+index].length}</span> MySQL Slow Query Analysis </span>
                                                            : this.state["diagnostic_label_"+index] === "DB SlowQuery PostgreSQL" && this.state.expandMenuId !== index ?
                                                                <span className={` ${item.flag ? "text-purplishRed" : "text-success"}`}>Showing <span className="text-primary-color">{this.state["filtered_diagnostic_data_"+index] && this.state["filtered_diagnostic_data_"+index].length}</span> PostgreSQL Slow Query Analysis </span>
                                                            : this.state.expandMenuId !== index ?
                                                                <span className={` ${item.flag ? "text-purplishRed" : "text-success"}`}>Showing <span className="text-primary-color">{item.data && item.data.length}</span> {item.label} </span>
                                                            : null}
                                                            <span className={`far fa-exclamation-triangle ${item.flag ? "text-purplishRed" : "text-success"} ml-3`}></span>
                                                        </div>
                                                    </div>

                                                    {this.state.expandMenuId === index ?
                                                        <div className="mt-1">
                                                            <div className="accordion" id="accordionExample">
                                                                <div className={`card bg-dark border-0 ${index !== this.state.observedDiagnostics.length-1 ? "" : ""}`}>
                                                                    <div id={"collapse_"+index} className={`collapse ${this.state.expandMenuId === index ? "show" : ""}`} aria-labelledby={"heading_"+index} data-parent="#accordionExample">
                                                                        <div className="card-body">
                                                                            <div className="d-flex justify-content-between mt-1">
                                                                                <div className="d-flex">
                                                                                    {this.state["diagnostic_label_"+index] === "MySQL DB Changes" ?
                                                                                        <p className="small mb-0 mr-2 mt-1">Showing {this.state["diagnostic_count_"+index]} changes in {this.state["diagnostic_db_count_"+index]} MySQL database </p>
                                                                                    : this.state["diagnostic_label_"+index] === "DB Audit PostgreSQL" ?
                                                                                        <p className="small mb-0 mr-2 mt-1">Showing {this.state["diagnostic_count_"+index]} changes in {this.state["diagnostic_db_count_"+index]} PostgreSQL database </p>
                                                                                    : this.state.expandMenuId !== index && (this.state["diagnostic_label_"+index] === "DB SlowQuery Events" || this.state["diagnostic_label_"+index] === "Athena Slow Query Analysis" || this.state["diagnostic_label_"+index] === "Reachability Analysis" || this.state["diagnostic_label_"+index] === "Connectivity Checks" || this.state["diagnostic_label_"+index] === "Traffic Surge") ? 
                                                                                        <span className="text-info">Showing {this.state["filtered_diagnostic_data_"+index] && this.state["filtered_diagnostic_data_"+index].length} {item.label} </span>
                                                                                    : this.state["diagnostic_label_"+index] === "DB SlowQuery MySQL" && this.state.expandMenuId !== index ?
                                                                                        <span className="text-info">Showing {this.state["filtered_diagnostic_data_"+index] && this.state["filtered_diagnostic_data_"+index].length} MySQL Slow Query Analysis </span>
                                                                                    : this.state["diagnostic_label_"+index] === "DB SlowQuery PostgreSQL" && this.state.expandMenuId !== index ?
                                                                                        <span className="text-info">Showing {this.state["filtered_diagnostic_data_"+index] && this.state["filtered_diagnostic_data_"+index].length} PostgreSQL Slow Query Analysis </span>
                                                                                    : this.state.expandMenuId !== index && (this.state["diagnostic_label_"+index] === "Capacity Changes" || this.state["diagnostic_label_"+index] === "Health Events" || this.state["diagnostic_label_"+index] === "Maintenance and Scheduled Events" ||  this.state["diagnostic_label_"+index] === "General Changes" || this.state["diagnostic_label_"+index] === "Security Changes" || this.state["diagnostic_label_"+index] === "Performance Changes" || this.state["diagnostic_label_"+index] === "Network Changes" || this.state["diagnostic_label_"+index] === "Configuration Changes" || this.state["diagnostic_label_"+index] === "Tag Changes"  || this.state["diagnostic_label_"+index] === "Policy Changes" || this.state["diagnostic_label_"+index] === "Disk Changes" || this.state["diagnostic_label_"+index] === "SRE Observations" || this.state["diagnostic_label_"+index] === "EC2 Service Limits" || this.state["diagnostic_label_"+index] === "RDS Service Limits" || this.state["diagnostic_label_"+index] === "ElastiCache Service Limits" || this.state["diagnostic_label_"+index] === "KMS Service Limits" || this.state["diagnostic_label_"+index] === "DynamoDB Service Limits" || this.state["diagnostic_label_"+index] === "SecretsManager Service Limits" || this.state["diagnostic_label_"+index] === "ApiGateway Service Limits" || this.state["diagnostic_label_"+index] === "SQS Service Limits" || this.state["diagnostic_label_"+index] === "Lambda Service Limits" || this.state["diagnostic_label_"+index] === "SNS Service Limits" || this.state["diagnostic_label_"+index] === "Database deadlocks") ?
                                                                                        <p className="small mb-0 ml-2 align-self-center pt-1 text-info">Showing {this.state["filtered_diagnostic_data_"+index] && this.state["filtered_diagnostic_data_"+index].length} {item.label} </p>
                                                                                    :  
                                                                                        <p className="small mb-0 mr-2 mt-1">Showing {this.state["filtered_diagnostic_data_"+index] && this.state["filtered_diagnostic_data_"+index].length} {item.label} </p>
                                                                                    }
                                                                                </div>
                                                                                <div className="form-group mb-0">
                                                                                    <SearchWithHiddenInput
                                                                                        ID={"search_id_"+index}
                                                                                        data={this.state["diagnostic_data_"+index]}
                                                                                        applyLiteDarkTags={true}
                                                                                        className="form-control-sm text-white4 bg-muted border-0"
                                                                                        hideInputSection={true}
                                                                                        searchClassName={"border-0"}
                                                                                        searchIconColor={"text-white4"}
                                                                                        topClassName={"mb-2"}
                                                                                        filteredData={(filteredArray) => {
                                                                                            this.setState({ 
                                                                                                ["filtered_diagnostic_data_"+index]: filteredArray,
                                                                                                ["diagnostic_count_"+index]: filteredArray.length   
                                                                                            },
                                                                                                () => {
                                                                                                    if(this.state["diagnostic_label_"+index] === "MySQL DB Changes" || this.state["diagnostic_label_"+index] === "DB Audit PostgreSQL") {
                                                                                                        let dbLength = 0
                                                                                                        if(filteredArray.length) {
                                                                                                            dbLength = _.chain(filteredArray).groupBy("asset_id").map((value, key) => ({ key})).value().length
                                                                                                        } //to get number of database involved in the changes
                                                                                                        this.setState({
                                                                                                            ["diagnostic_db_count_"+index]: dbLength
                                                                                                        })
                                                                                                    }
                                                                                                }
                                                                                            )
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            {/* <div className="input-group mb-3 w-auto">
                                                                                <div className="input-group-prepend">
                                                                                    <span className="input-group-text" id="basic-addon1">
                                                                                    <i className="far fa-search"></i>
                                                                                    </span>
                                                                                </div>
                                                                                <input type="text" className="form-control" placeholder="Search..." aria-label="Username" aria-describedonby="basic-addon1" />
                                                                            </div> */}
                                                                            <div className="container-fluid">
                                                                                {item.label === "Application Changes" ?
                                                                                    this.state["filtered_diagnostic_data_"+index] && this.state["filtered_diagnostic_data_"+index].length ?
                                                                                        <React.Fragment>
                                                                                        {this.state["filtered_diagnostic_data_"+index].slice(this.state.startRecord, this.state.startRecord+this.state.perPage).map((dig, i) => {
                                                                                            return (
                                                                                                <div className={`row mt-4 ${this.state.selectedDiagnostic === "app_changes_"+index+"_"+(this.state.startRecord + i) ? "bg-muted disabled" : "cursorPointer"}`} onClick={() => this.onChangeDiagnosticEvent(item.label, item.tag, dig, index, "app_changes_"+index+"_"+(this.state.startRecord + i), dig.asset_id, "")}>
                                                                                                    <div className="col-2 border-right align-self-center">
                                                                                                        <p className="text-warning 20 m-0">{momentConvertionUtcToLocalTime(dig.event_time, "MMM DD")}</p>
                                                                                                        <p className="text-warning 20 m-0">{momentConvertionUtcToLocalTime(dig.event_time, "HH:mm:ss")}</p>
                                                                                                    </div>
                                                                                                    <div className="col-10 border-left py-3">
                                                                                                        <p className="f16 m-0">{(dig.provider).toUpperCase() +" : " +dig.resource_type +" "}</p>
                                                                                                        <div className="timeline-divider"></div>
                                                                                                        <p className="f12 m-0">Resource 
                                                                                                            {dig.account_id ? (" : "+dig.account_id) : ""}
                                                                                                            {dig.region ? (" : "+dig.region) : ""}
                                                                                                            {dig.asset_name ? (" : "+dig.asset_name) : ""}
                                                                                                            {dig.description ? (" : "+dig.description) : ""}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })}
                                                                                        {this.state["filtered_diagnostic_data_"+index].length > this.state.perPage ?
                                                                                            <div className="pagination">
                                                                                                <span className="mx-3">Page <strong>{this.state.currentPage} of {Math.ceil(this.state["filtered_diagnostic_data_"+index].length / 1)}</strong> </span>
                                                                                                <button className={`${this.state.currentPage <= 1 ? "disabled text-muted" : "text-gray3"}`} onClick={() => this.navigatePage("start", this.state.currentPage, Math.ceil(this.state["filtered_diagnostic_data_"+index].length / 1))}><i className="fal fa-arrow-to-left"></i></button> 
                                                                                                <button className={`${this.state.currentPage <= 1 ? "disabled text-muted" : "text-gray3"}`} onClick={() => this.navigatePage("previous", this.state.currentPage, Math.ceil(this.state["filtered_diagnostic_data_"+index].length / 1))}><i className="fal fa-angle-left"></i></button> 
                                                                                                <button className={`${this.state.currentPage !== Math.ceil(this.state["filtered_diagnostic_data_"+index].length / 1) ? "text-gray3" : "disabled text-muted"}`}><i className="fal fa-angle-right" onClick={() => this.navigatePage("next", this.state.currentPage, Math.ceil(this.state["filtered_diagnostic_data_"+index].length / 1))}></i></button> 
                                                                                                <button className={`${this.state.currentPage !== Math.ceil(this.state["filtered_diagnostic_data_"+index].length / 1) ? "text-gray3" : "disabled text-muted"}`}><i className="fal fa-arrow-to-right" onClick={() => this.navigatePage("end", this.state.currentPage, Math.ceil(this.state["filtered_diagnostic_data_"+index].length / 1))}></i></button>
                                                                                            </div>
                                                                                        : null}
                                                                                        </React.Fragment>
                                                                                    : null
                                                                                : item.label === "Capacity Changes" ?
                                                                                    this.state["filtered_diagnostic_data_"+index] && this.state["filtered_diagnostic_data_"+index].length ?
                                                                                        <React.Fragment>
                                                                                        {this.state["filtered_diagnostic_data_"+index].slice(this.state.startRecord, this.state.startRecord+this.state.perPage).map((dig, i) => {
                                                                                            return (
                                                                                                <div className={`row mt-4 ${this.state.selectedDiagnostic === "capacity_changes_"+index+"_"+(this.state.startRecord + i) ? "bg-muted disabled" : "cursorPointer"}`} onClick={() => this.onChangeDiagnosticEvent(item.label, item.tag, dig, index, "capacity_changes_"+index+"_"+(this.state.startRecord + i), dig.asset_id, "")}>
                                                                                                    <div className="col-2 border-right align-self-center">
                                                                                                        <p className="text-warning 20 m-0">{momentConvertionUtcToLocalTime(dig.event_time, "MMM DD")}</p>
                                                                                                        <p className="text-warning 20 m-0">{momentConvertionUtcToLocalTime(dig.event_time, "HH:mm:ss")}</p>
                                                                                                    </div>
                                                                                                    <div className="col-10 border-left py-3">
                                                                                                        <p className="f16 m-0">{dig.event_source +" : " +dig.event_name +" "}</p>
                                                                                                        <div className="timeline-divider"></div>
                                                                                                        {dig.changes && dig.changes.map(des => {
                                                                                                            return(
                                                                                                                <p className="f12 m-0">{des.description ? des.description : ""}</p>
                                                                                                            )
                                                                                                        })}
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })}
                                                                                        {this.state["filtered_diagnostic_data_"+index].length > this.state.perPage ?
                                                                                            <div className="pagination">
                                                                                                <span className="mx-3">Page <strong>{this.state.currentPage} of {Math.ceil(this.state["filtered_diagnostic_data_"+index].length / 1)}</strong> </span>
                                                                                                <button className={`${this.state.currentPage <= 1 ? "disabled text-muted" : "text-gray3"}`} onClick={() => this.navigatePage("start", this.state.currentPage, Math.ceil(this.state["filtered_diagnostic_data_"+index].length / 1))}><i className="fal fa-arrow-to-left"></i></button> 
                                                                                                <button className={`${this.state.currentPage <= 1 ? "disabled text-muted" : "text-gray3"}`} onClick={() => this.navigatePage("previous", this.state.currentPage, Math.ceil(this.state["filtered_diagnostic_data_"+index].length / 1))}><i className="fal fa-angle-left"></i></button> 
                                                                                                <button className={`${this.state.currentPage !== Math.ceil(this.state["filtered_diagnostic_data_"+index].length / 1) ? "text-gray3" : "disabled text-muted"}`}><i className="fal fa-angle-right" onClick={() => this.navigatePage("next", this.state.currentPage, Math.ceil(this.state["filtered_diagnostic_data_"+index].length / 1))}></i></button> 
                                                                                                <button className={`${this.state.currentPage !== Math.ceil(this.state["filtered_diagnostic_data_"+index].length / 1) ? "text-gray3" : "disabled text-muted"}`}><i className="fal fa-arrow-to-right" onClick={() => this.navigatePage("end", this.state.currentPage, Math.ceil(this.state["filtered_diagnostic_data_"+index].length / 1))}></i></button>
                                                                                            </div>
                                                                                        : null}
                                                                                        </React.Fragment>
                                                                                    : null
                                                                                : this.state["filtered_diagnostic_data_"+index] && this.state["filtered_diagnostic_data_"+index].length ?
                                                                                    this.state["filtered_diagnostic_data_"+index].map((dig, i) => {
                                                                                        return (
                                                                                        item.label === "MySQL DB Changes" ?
                                                                                            <div className={`row mt-4 ${this.state.selectedDiagnostic === "db_"+index+"_"+i ? "bg-muted disabled" : "cursorPointer"}`} onClick={() => this.onChangeDiagnosticEvent(item.label, item.tag, dig, index, "db_"+index+"_"+i, dig.asset_id, dig.key)}>
                                                                                                <div className="col-2 border-right align-self-center">
                                                                                                    <h6 className="text-warning 20 m-0">{dig.count}</h6>
                                                                                                    <p className="text-warning 20 m-0">{dig.action}</p>
                                                                                                </div>
                                                                                                <div className="col-10 border-left py-3">
                                                                                                    <p className="f16 m-0">{(dig.key === "index_changes" ? "Index" : dig.key === "table_changes" ? "Table" : dig.key === "view_changes" ? "View" : dig.key === "other_changes" ? "Other" : "")}</p>
                                                                                                    <div className="timeline-divider"></div>
                                                                                                    <p className="f12 m-0">{dig.description ? dig.description : ""}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        : item.label === "DB Audit PostgreSQL" ?
                                                                                            <div className={`row mt-4 ${this.state.selectedDiagnostic === "db_"+index+"_"+i ? "bg-muted disabled" : "cursorPointer"}`} onClick={() => this.onChangeDiagnosticEvent(item.label, item.tag, dig, index, "db_"+index+"_"+i, dig.asset_id, dig.key)}>
                                                                                                <div className="col-2 border-right align-self-center">
                                                                                                    <h6 className="text-warning 20 m-0">{dig.count}</h6>
                                                                                                    <p className="text-warning 20 m-0">{dig.action}</p>
                                                                                                </div>
                                                                                                <div className="col-10 border-left py-3">
                                                                                                    <p className="f16 m-0">{(dig.key === "index_changes" ? "Index" : dig.key === "table_changes" ? "Table" : dig.key === "view_changes" ? "View" : dig.key === "other_changes" ? "Other" : "")}</p>
                                                                                                    <div className="timeline-divider"></div>
                                                                                                    <p className="f12 m-0">{dig.description ? dig.description : ""}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        : item.label === "Health Events" ?
                                                                                            <div className={`row mt-4 ${this.state.selectedDiagnostic === "health_event_"+index+"_"+i ? "bg-muted disabled" : "cursorPointer"}`} onClick={() => this.onChangeDiagnosticEvent(item.label, item.tag, dig, index, "health_event_"+index+"_"+i, "", "")}>
                                                                                                <div className="col-2 border-right align-self-center">
                                                                                                    <p className="text-warning 20 m-0">{momentConvertionUtcToLocalTime(dig.health_event_time, "MMM DD")}</p>
                                                                                                    <p className="text-warning 20 m-0">{momentConvertionUtcToLocalTime(dig.health_event_time, "HH:mm:ss")}</p>
                                                                                                </div>
                                                                                                <div className="col-10 border-left py-3">
                                                                                                    <p className="f16 m-0">{dig.health_event_service +" : " +dig.health_event_category +" "}</p>
                                                                                                    <div className="timeline-divider"></div>
                                                                                                    <p className="f12 m-0">{dig.health_event_description ? dig.health_event_description : ""}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        : item.label === "Maintenance and Scheduled Events" ?
                                                                                            <div className={`row mt-4 ${this.state.selectedDiagnostic === "maintsch_event_"+index+"_"+i ? "bg-muted disabled" : "cursorPointer"}`} onClick={() => this.onChangeDiagnosticEvent(item.label, item.tag, dig, index, "maintsch_event_"+index+"_"+i, "", "")}>
                                                                                                <div className="col-2 border-right align-self-center">
                                                                                                    <p className="text-warning 20 m-0">{momentConvertionUtcToLocalTime(dig.maintsch_event_time, "MMM DD")}</p>
                                                                                                    <p className="text-warning 20 m-0">{momentConvertionUtcToLocalTime(dig.maintsch_event_time, "HH:mm:ss")}</p>
                                                                                                </div>
                                                                                                <div className="col-10 border-left py-3">
                                                                                                    <p className="f16 m-0">{dig.maintsch_event_service +" : " +dig.maintsch_event_category +" "}</p>
                                                                                                    <div className="timeline-divider"></div>
                                                                                                    <p className="f12 m-0">{dig.maintsch_event_description ? dig.maintsch_event_description : ""}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        : item.label === "General Changes" ?
                                                                                            <div className={`row mt-4 ${this.state.selectedDiagnostic === "infra_changes_"+index+"_"+i ? "bg-muted disabled" : "cursorPointer"}`} onClick={() => this.onChangeDiagnosticEvent(item.label, item.tag, dig, index, "infra_changes_"+index+"_"+i, dig.asset_id, "")}>
                                                                                                <div className="col-2 border-right align-self-center">
                                                                                                    <p className="text-warning 20 m-0">{momentConvertionUtcToLocalTime(dig.event_time, "MMM DD")}</p>
                                                                                                    <p className="text-warning 20 m-0">{momentConvertionUtcToLocalTime(dig.event_time, "HH:mm:ss")}</p>
                                                                                                </div>
                                                                                                <div className="col-10 border-left py-3">
                                                                                                    <p className="f16 m-0">{dig.event_source +" : " +dig.event_name +" "}</p>
                                                                                                    <div className="timeline-divider"></div>
                                                                                                    {dig.changes && dig.changes.map(des => {
                                                                                                        return(
                                                                                                            <p className="f12 m-0">{des.description ? des.description : ""}</p>
                                                                                                        )
                                                                                                    })}
                                                                                                </div>
                                                                                            </div>
                                                                                        : (item.label === "Security Changes" || item.label === "Performance Changes" || item.label === "Network Changes" || item.label === "Configuration Changes" || item.label === "Tag Changes" || item.label === "Policy Changes" || item.label === "Disk Changes" ) ?
                                                                                            <div className={`row mt-4 ${this.state.selectedDiagnostic === "changes_"+index+"_"+i ? "bg-muted disabled" : "cursorPointer"}`} onClick={() => this.onChangeDiagnosticEvent(item.label, item.tag, dig, index, "changes_"+index+"_"+i, dig.asset_id, "")}>
                                                                                                <div className="col-2 border-right align-self-center">
                                                                                                    <p className="text-warning 20 m-0">{momentConvertionUtcToLocalTime(dig.event_time, "MMM DD")}</p>
                                                                                                    <p className="text-warning 20 m-0">{momentConvertionUtcToLocalTime(dig.event_time, "HH:mm:ss")}</p>
                                                                                                </div>
                                                                                                <div className="col-10 border-left py-3">
                                                                                                    <p className="f16 m-0">{dig.event_source +" : " +dig.event_name +" "}</p>
                                                                                                    <div className="timeline-divider"></div>
                                                                                                    {dig.changes && dig.changes.map(des => {
                                                                                                        return(
                                                                                                            <p className="f12 m-0">{des.description ? des.description : ""}</p>
                                                                                                        )
                                                                                                    })}
                                                                                                </div>
                                                                                            </div>
                                                                                        : item.label === "SRE Observations" ?
                                                                                            <div className={`row mt-4 ${this.state.selectedDiagnostic === "sre_events_"+index+"_"+i ? "bg-muted disabled" : "cursorPointer"}`} onClick={() => this.onChangeDiagnosticEvent(item.label, item.tag, dig, index, "sre_events_"+index+"_"+i, dig.sre_asset_id, "")}>
                                                                                                <div className="col-2 border-right align-self-center">
                                                                                                    <p className="text-warning 20 m-0">
                                                                                                        {dig.sre_events_count && dig.sre_events_count}
                                                                                                    </p>
                                                                                                    <p className="text-warning 20 m-0">{dig.sre_events_count ? (dig.sre_events_count > 1 ? "events" : "event") : "event"}</p>
                                                                                                </div>
                                                                                                <div className="col-10 border-left py-3">
                                                                                                    <p className="f16 m-0">{dig.sre_resource_type ? dig.sre_resource_type : ""}</p>
                                                                                                    <div className="timeline-divider"></div>
                                                                                                    <p className="f12 m-0">{dig.sre_event_description ? dig.sre_event_description : ""}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        : item.label === "DB SlowQuery Events" ?
                                                                                            <div className={`row mt-4 ${this.state.selectedDiagnostic === "db_slow_query_event_"+index+"_"+i ? "bg-muted disabled" : "cursorPointer"}`} onClick={() => this.onChangeDiagnosticEvent(item.label, item.tag, dig, index, "db_slow_query_event_"+index+"_"+i, "", "")}>
                                                                                                <div className="col-2 border-right align-self-center">
                                                                                                    <p className="text-warning 20 m-0">{dig.dbslowq_count ? dig.dbslowq_count : ""}</p>
                                                                                                    <p className="text-warning 20 m-0">{dig.dbslowq_count && dig.dbslowq_count > 1 ? "Slow Queries" : "Slow Query"}</p>
                                                                                                </div>
                                                                                                <div className="col-10 border-left py-3">
                                                                                                    <p className="f16 m-0">{dig.dbslowq_resource_type +" " +dig.dbslowq_engine +" : "+ dig.dbslowq_asset_id}</p>
                                                                                                    <div className="timeline-divider"></div>
                                                                                                    <p className="f12 m-0">{dig.dbslowq_summary ? dig.dbslowq_summary : ""}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        : item.label === "DB SlowQuery MySQL" ?
                                                                                            <div className={`row mt-4 ${this.state.selectedDiagnostic === "db_slow_query_mysql_"+index+"_"+i ? "bg-muted disabled" : "cursorPointer"}`} onClick={() => this.onChangeDiagnosticEvent(item.label, item.tag, dig, index, "db_slow_query_mysql_"+index+"_"+i, "", "")}>
                                                                                                <div className="col-2 border-right align-self-center text-center">
                                                                                                    <p className="text-warning 20 m-0">{dig.total}</p>
                                                                                                    <p className="text-warning 20 m-0">Slow</p>
                                                                                                    <p className="text-warning 20 m-0">Queries</p>
                                                                                                </div>
                                                                                                <div className="col-10 border-left py-3">
                                                                                                    <p className="small  m-0">Unique Slow Queries {dig.unique}</p>
                                                                                                    <div className="d-flex">
                                                                                                        <small className="mr-2">Min: {dig.count && dig.count.min ? dig.count.min : 0}</small>
                                                                                                        <small className="mr-2">Avg: {dig.count && dig.count.avg ? dig.count.avg : 0}</small>
                                                                                                        <small className="mr-2">Max: {dig.count && dig.count.max ? dig.count.max : 0}</small>
                                                                                                        <small className="mr-2">STD: {dig.count && dig.count.stddev ? dig.count.stddev : 0}</small>
                                                                                                        <small className="mr-2">Med: {dig.count && dig.count.median ? dig.count.median : 0}</small>
                                                                                                        <small className="mr-2">95th Per: {dig.count && dig.count["95%"] ? dig.count["95%"] : 0}</small>
                                                                                                    </div>
                                                                                                    <p className="small m-0">Query with highest time: {dig.query_time && dig.query_time.max ? dig.query_time.max : 0} seconds</p>
                                                                                                    <p className="small m-0">Query with lowest time: {dig.query_time && dig.query_time.min ? dig.query_time.min : 0} seconds</p>
                                                                                                    <p className="small m-0">Total time taken by slow queries: {dig.query_time && dig.query_time.sum ? dig.query_time.sum : 0} seconds</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        : item.label === "DB SlowQuery PostgreSQL" ?
                                                                                            <div className={`row mt-4 ${this.state.selectedDiagnostic === "db_slow_query_mysql_"+index+"_"+i ? "bg-muted disabled" : "cursorPointer"}`} onClick={() => this.onChangeDiagnosticEvent(item.label, item.tag, dig, index, "db_slow_query_mysql_"+index+"_"+i, "", "")}>
                                                                                                <div className="col-2 border-right align-self-center text-center">
                                                                                                    <p className="text-warning 20 m-0">{dig.total}</p>
                                                                                                    <p className="text-warning 20 m-0">Slow</p>
                                                                                                    <p className="text-warning 20 m-0">Queries</p>
                                                                                                </div>
                                                                                                <div className="col-10 border-left py-3">
                                                                                                    <p className="small  m-0">Unique Slow Queries {dig.unique}</p>
                                                                                                    <div className="d-flex">
                                                                                                        <small className="mr-2">Min: {dig.count && dig.count.min ? dig.count.min : 0}</small>
                                                                                                        <small className="mr-2">Avg: {dig.count && dig.count.avg ? dig.count.avg : 0}</small>
                                                                                                        <small className="mr-2">Max: {dig.count && dig.count.max ? dig.count.max : 0}</small>
                                                                                                        <small className="mr-2">STD: {dig.count && dig.count.stddev ? dig.count.stddev : 0}</small>
                                                                                                        <small className="mr-2">Med: {dig.count && dig.count.median ? dig.count.median : 0}</small>
                                                                                                        <small className="mr-2">95th Per: {dig.count && dig.count["95%"] ? dig.count["95%"] : 0}</small>
                                                                                                    </div>
                                                                                                    <p className="small m-0">Query with highest time: {dig.query_time && dig.query_time.max ? dig.query_time.max : 0} seconds</p>
                                                                                                    <p className="small m-0">Query with lowest time: {dig.query_time && dig.query_time.min ? dig.query_time.min : 0} seconds</p>
                                                                                                    <p className="small m-0">Total time taken by slow queries: {dig.query_time && dig.query_time.sum ? dig.query_time.sum : 0} seconds</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        : item.label === "Athena Slow Query Analysis" ?
                                                                                            <div className={`row mt-4 ${this.state.selectedDiagnostic === "athena_analysis_"+index+"_"+i ? "bg-muted disabled" : "cursorPointer"}`} onClick={() => this.onChangeDiagnosticEvent(item.label, item.tag, dig, index, "athena_analysis_"+index+"_"+i, "", "")}>
                                                                                                <div className="col-2 border-right align-self-center text-center">
                                                                                                    <p className="text-warning 20 m-0">{dig.total}</p>
                                                                                                    <p className="text-warning 20 m-0">Slow</p>
                                                                                                    <p className="text-warning 20 m-0">Queries</p>
                                                                                                </div>
                                                                                                <div className="col-10 border-left py-3">
                                                                                                    <p className="small  m-0">Unique Slow Queries {dig.unique}</p>
                                                                                                    <div className="d-flex">
                                                                                                        <small className="mr-2">Min: {dig.count && dig.count.min ? dig.count.min : 0}</small>
                                                                                                        <small className="mr-2">Avg: {dig.count && dig.count.avg ? dig.count.avg : 0}</small>
                                                                                                        <small className="mr-2">Max: {dig.count && dig.count.max ? dig.count.max : 0}</small>
                                                                                                        <small className="mr-2">STD: {dig.count && dig.count.stddev ? dig.count.stddev : 0}</small>
                                                                                                        <small className="mr-2">Med: {dig.count && dig.count.median ? dig.count.median : 0}</small>
                                                                                                        <small className="mr-2">95th Per: {dig.count && dig.count["95%"] ? dig.count["95%"] : 0}</small>
                                                                                                    </div>
                                                                                                    <p className="small m-0">Query with highest time: {dig.query_time && dig.query_time.max ? dig.query_time.max : 0} seconds</p>
                                                                                                    <p className="small m-0">Query with lowest time: {dig.query_time && dig.query_time.min ? dig.query_time.min : 0} seconds</p>
                                                                                                    <p className="small m-0">Total time taken by slow queries: {dig.query_time && dig.query_time.sum ? dig.query_time.sum : 0} seconds</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        : item.label === "Reachability Analysis" ?
                                                                                            <div className={`row mt-4 ${this.state.selectedDiagnostic === "reachability_analysis_"+index+"_"+i ? "bg-muted disabled" : "cursorPointer"}`} onClick={() => this.onChangeDiagnosticEvent(item.label, item.tag, dig, index, "reachability_analysis_"+index+"_"+i, "", "")}>
                                                                                                <div className="col-2 border-right align-self-center text-center">
                                                                                                    <h6 className="text-warning 20 m-0">{dig.total_impacted_assets && dig.total_impacted_assets.length}</h6>
                                                                                                    <p className="text-warning 20 m-0">Assets Impacted</p>
                                                                                                    {/* <p className="text-warning 20 m-0">Impacted</p> */}
                                                                                                </div>
                                                                                                <div className="col-10 border-left py-3">
                                                                                                    <p className="f16 m-0">{(dig.provider).toUpperCase() +" : " +dig.resource_type +" "}</p>
                                                                                                    <div className="timeline-divider"></div>
                                                                                                        {dig.description ? 
                                                                                                            dig.description.split("\n").map(item => {
                                                                                                                return(
                                                                                                                    <p className="f12 mb-1">{item}</p>
                                                                                                                )
                                                                                                            })
                                                                                                        : 
                                                                                                            <p className="f12 m-0">Resource 
                                                                                                                {dig.account_id ? (" : "+dig.account_id) : ""}
                                                                                                                {dig.region ? (" : "+dig.region) : ""}
                                                                                                                {dig.asset_name ? (" : "+dig.asset_name) : ""}
                                                                                                                {/* {dig.description ? (" : "+dig.description) : ""} */}
                                                                                                            </p>
                                                                                                        }
                                                                                                </div>
                                                                                            </div>
                                                                                        : item.label === "Connectivity Checks" ?
                                                                                            <div className={`row mt-4 ${this.state.selectedDiagnostic === "connectivity_"+index+"_"+i ? "bg-muted disabled" : "cursorPointer"}`} onClick={() => this.onChangeDiagnosticEvent(item.label, item.tag, dig, index, "connectivity_"+index+"_"+i, "", "")}>
                                                                                                <div className="col-2 border-right align-self-center">
                                                                                                    <p className="text-warning 20 m-0">{dig.conntchks_count ? dig.conntchks_count : ""}</p>
                                                                                                    <p className="text-warning 20 m-0">{dig.conntchks_count && dig.conntchks_count > 1 ? "Checks" : "Checks"}</p>
                                                                                                </div>
                                                                                                <div className="col-10 border-left py-3">
                                                                                                    <p className="f16 m-0">
                                                                                                        {
                                                                                                        (dig.conntchks_resource_type ? dig.conntchks_resource_type : "") 
                                                                                                        +" "+
                                                                                                        (dig.conntchks_engine ? dig.conntchks_engine : "") 
                                                                                                        +" "+
                                                                                                        (dig.conntchks_asset_id ? dig.conntchks_asset_id : "")}
                                                                                                    </p>
                                                                                                    <div className="timeline-divider"></div>
                                                                                                    <p className="f12 m-0">{dig.conntchks_summary ? dig.conntchks_summary : ""}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        : item.label === "Traffic Surge" ?
                                                                                            <div className={`row mt-4 ${this.state.selectedDiagnostic === "traffic_surge_"+index+"_"+i ? "bg-muted disabled" : "cursorPointer"}`} onClick={() => this.onChangeDiagnosticEvent(item.label, item.tag, dig, index, "traffic_surge_"+index+"_"+i, "", "")}>
                                                                                                {/* <div className="col-2 border-right align-self-center">
                                                                                                    <p className="text-warning 20 m-0">{dig.conntchks_count ? dig.conntchks_count : ""}</p>
                                                                                                    <p className="text-warning 20 m-0">{dig.conntchks_count && dig.conntchks_count > 1 ? "Checks" : "Checks"}</p>
                                                                                                </div> */}
                                                                                                
                                                                                                <div className="col-12 py-3">{/* border-left */}
                                                                                                    <p className="f16 m-0">
                                                                                                        <span className="mr-2">Detected </span>
                                                                                                        <span className="mr-2">{(dig.pattern ? dig.pattern : "")}</span>
                                                                                                        <span className="mr-2">in </span>
                                                                                                        <span className="mr-2">{(dig.asset_name ? dig.asset_name : "")}</span>
                                                                                                        <span className="mr-2"> reached a </span>
                                                                                                        <span className={`mr-2 badge ${dig.severity ? "risk-badge-"+(dig.severity ? dig.severity.toLowerCase() : "") : ""}`}>{(dig.severity ? dig.severity : "")}</span>
                                                                                                        <span className="mr-2"> state</span>
                                                                                                    </p>
                                                                                                    <div classN ame="timeline-divider"></div>
                                                                                                    <p className="f12 m-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        : (item.label === "EC2 Service Limits" || item.label === "RDS Service Limits" || item.label === "ElastiCache Service Limits" || item.label === "KMS Service Limits" || item.label === "DynamoDB Service Limits" || item.label === "SecretsManager Service Limits" || item.label === "ApiGateway Service Limits" || item.label === "SQS Service Limits" || item.label === "Lambda Service Limits" || item.label === "SNS Service Limits" || item.label === "Database deadlocks") ?
                                                                                            <div className={`row p-1 ${i ? "border-top" : ""} ${this.state.selectedDiagnostic === "limits_"+index+"_"+i ? "bg-muted disabled" : "cursorPointer"}`} onClick={() => this.onChangeDiagnosticEvent(item.label, item.tag, dig, index, "limits_"+index+"_"+i, dig.asset_id, "")}>
                                                                                                <p className="mb-0">
                                                                                                    <span className="mr-1">
                                                                                                        {dig.applied_quota_value ?
                                                                                                            "Applied value"
                                                                                                        : dig.default_quota_value ?
                                                                                                            "Default value "
                                                                                                        : null}
                                                                                                    </span>
                                                                                                    {dig.metric_details && dig.metric_details.metric_name ?
                                                                                                        <span className="mr-1">{dig.metric_details.metric_name}</span>
                                                                                                    : null}
                                                                                                    <span className="mr-1 text-info">
                                                                                                        {dig.applied_quota_value ?
                                                                                                            dig.applied_quota_value
                                                                                                        : dig.default_quota_value ?
                                                                                                            dig.default_quota_value
                                                                                                        : 0}
                                                                                                    </span>
                                                                                                    <span className="ml-1">we are observing the maximim value of</span> 
                                                                                                    <span className="ml-1"><span className="text-info">{dig.actual_value}</span>, and with the</span> 
                                                                                                    <span className="ml-1">utilization of </span>
                                                                                                    <span className="ml-1 text-info">{dig.utilization}%</span>
                                                                                                </p>
                                                                                            </div>
                                                                                        : (item.label === "Operational Health Events" || item.label === "Maintenance Scheduled Health Events" || item.label === "Availability Health Events" || item.label === "Network Health Events" || item.label === "Security Health Events" || item.label === "Console Health Events" || item.label === "Connectivity Health Events" || item.label === "API Health Events" || item.label === "Error/Failure Health Events" || item.label === "Performance Health Events" || item.label === "Limit Health Events" || item.label === "Service Health Events" || item.label === "Update/Upgrade/Patch Changes" || item.label === "Maintenance Events") ?
                                                                                            <div className={`row p-1 ${i ? "border-top" : ""} ${this.state.selectedDiagnostic === "health_events_"+index+"_"+i ? "bg-muted disabled" : "cursorPointer"}`} onClick={() => this.onChangeDiagnosticEvent(item.label, item.tag, dig, index, "health_events_"+index+"_"+i, dig.asset_id, "")}>
                                                                                                <p className="mb-0">
                                                                                                    <span className="mb-0 mr-2 text-white f12 font-weight-bold text-primary-color">{dig.event_time ? momentConvertionUtcToLocalTime(dig.event_time, "DD MMM YYYY HH:mm") : ""} </span>
                                                                                                    <span className={`mb-0 mr-2 f12 text-purple-2`}>
                                                                                                        {dig.event_source ? dig.event_source : ""}
                                                                                                        {dig.event_name ? " : " + dig.event_name : ""}
                                                                                                    </span>
                                                                                                    {dig.additional_details && dig.additional_details.eventTypeCode ?
                                                                                                        <span className={`mb-0 mr-2 f12`}>{dig.additional_details.eventTypeCode}</span>
                                                                                                    : null}
                                                                                                    {/* {dig.additional_details && dig.additional_details.eventDescription.length ?
                                                                                                        <span className={`mb-0 mr-2 f12`}>{dig.additional_details.eventDescription[0].latestDescription ? dig.additional_details.eventDescription[0].latestDescription : ""}</span>
                                                                                                    : null} */}
                                                                                                </p>
                                                                                            </div>
                                                                                        : (item.tag === "Anomalies" || item.tag === "Latency" || item.tag === "ErrorRate") ?
                                                                                            <div className={`row p-1 ${i ? "border-top" : ""} ${this.state.selectedDiagnostic === "alerts_"+index+"_"+i ? "bg-muted disabled" : "cursorPointer"}`} onClick={() => this.onChangeDiagnosticEvent(item.label, item.tag, dig, index, "alerts_"+index+"_"+i, dig.asset_id, "")}>
                                                                                                <p className="mb-0">
                                                                                                    <span className={`mr-2 risk-${dig.highest_event_severity ? dig.highest_event_severity.toLowerCase() : ""}`}>{capitalizeFirstLetter(dig.highest_event_severity)}</span>
                                                                                                    <span className="mr-2 text-primary-color">{dig.alerts && dig.alerts.length} Alert(s)</span>
                                                                                                    <span className="mr-2">{dig.event_type}</span>
                                                                                                    <span className="small mr-2 text-primary-color">Resource</span>
                                                                                                    {dig.account_id ?
                                                                                                        <span className="small mr-2">{" "+dig.account_id}</span>
                                                                                                    : null}
                                                                                                    {dig.region ?
                                                                                                        <span className="small mr-2">{" : "+dig.region}</span>
                                                                                                    : null}
                                                                                                    {dig.resource_type ?
                                                                                                        <span className="small mr-2">{" : "+(Array.isArray(dig.resource_type) ? dig.resource_type.join(", ") : dig.resource_type)}</span>
                                                                                                    : null}
                                                                                                    {dig.service_name ?
                                                                                                        <span className="small mr-2">{" : "+dig.service_name}</span>
                                                                                                    : null}
                                                                                                    {dig.asset_name ?
                                                                                                        <span className="small mr-2">{" : "+dig.asset_name}</span>
                                                                                                    : null}
                                                                                                    {dig.description ?
                                                                                                        <span className="small mr-2">{" : "+dig.description}</span>
                                                                                                    : null}
                                                                                                </p>
                                                                                            </div>
                                                                                        : item.tag === "Anomalies" ?
                                                                                            <div className={`row p-1 ${i ? "border-top" : ""} ${this.state.selectedDiagnostic === "alerts_"+index+"_"+i ? "bg-muted disabled" : "cursorPointer"}`} onClick={() => this.onChangeDiagnosticEvent(item.label, item.tag, dig, index, "alerts_"+index+"_"+i, dig.asset_id, "")}>
                                                                                                <p className="mb-0">
                                                                                                    <span className="mb-0 mr-2 f12 text-primary-color font-weight-bold">{item.anomaly_time ? momentConvertionUtcToLocalTime(item.anomaly_time, "DD MMM YYYY HH:mm") : ""} </span>
                                                                                                    <span className={`mb-0 mr-1 f12 text-purple-2`}>{item.anomaly_type ? item.anomaly_type : ""} </span>
                                                                                                    <span className="mb-0 mr-2 f12 font-weight-bold text-purple-2">Anomaly</span>
                                                                                                    <span className="mb-0 small text-primary-color mr-1">Resource </span>
                                                                                                    {item.account_id ?
                                                                                                        <span className="mb-0 f12 text-primary-color font-weight-bold">{" "+item.account_id} </span>
                                                                                                    : null}
                                                                                                    {item.region ?
                                                                                                        <span className="mb-0 f12 text-primary-color font-weight-bold">{" : "+item.region} </span>
                                                                                                    : null}
                                                                                                    {item.anomaly_message ?
                                                                                                        <span className="mb-0 ml-2 f12 text-white font-weight-bold">{item.anomaly_message} </span>
                                                                                                    : null}
                                                                                                </p>
                                                                                                <p className="mb-0">
                                                                                                    <span className="mr-2 text-primary-color">{dig.anomalies && dig.anomalies.length}</span> <span className={`mr-2`}>{dig.anomaly_type} Anomalies(s)</span>
                                                                                                    <span className="mr-2">{dig.anomaly_name}</span>
                                                                                                    <span className="small mr-2 text-primary-color">Resource</span>
                                                                                                    {dig.account_id ?
                                                                                                        <span className="small mr-2">{" "+dig.account_id}</span>
                                                                                                    : null}
                                                                                                    {dig.region ?
                                                                                                        <span className="small mr-2">{" : "+dig.region}</span>
                                                                                                    : null}
                                                                                                    {dig.resource_type ?
                                                                                                        <span className="small mr-2">{" : "+(Array.isArray(dig.resource_type) ? dig.resource_type.join(", ") : dig.resource_type)}</span>
                                                                                                    : null}
                                                                                                    {dig.service_name ?
                                                                                                        <span className="small mr-2">{" : "+dig.service_name}</span>
                                                                                                    : null}
                                                                                                    {dig.asset_name ?
                                                                                                        <span className="small mr-2">{" : "+dig.asset_name}</span>
                                                                                                    : null}
                                                                                                    {dig.title ?
                                                                                                        <span className="small mr-2">{" : "+dig.title}</span>
                                                                                                    : null}
                                                                                                </p>
                                                                                            </div>
                                                                                        : (item.tag === "Backup" || item.tag === "Failover" || item.tag === "Failure" || item.tag === "Replication" || item.tag === "Restoration" || item.tag === "Recovery") ?
                                                                                            <div className={`row p-1 ${i ? "border-top" : ""} ${this.state.selectedDiagnostic === "events_"+index+"_"+i ? "bg-muted disabled" : "cursorPointer"}`} onClick={() => this.onChangeDiagnosticEvent(item.label, item.tag, dig, index, "events_"+index+"_"+i, dig.asset_id, "")}>
                                                                                                <p className="mb-0">
                                                                                                    <span className={`mr-2 risk-${dig.importance ? dig.importance.toLowerCase() : ""}`}>{capitalizeFirstLetter(dig.importance)}</span>
                                                                                                    {dig.event_name ?
                                                                                                        <span className="mr-2 text-primary-color">{dig.event_name}</span>
                                                                                                    : null}
                                                                                                    <span className="mr-2">{dig.event_type}</span>
                                                                                                    <span className="small mr-2 text-primary-color">Resource</span>
                                                                                                    {dig.assets && dig.assets.length ?
                                                                                                        <React.Fragment>
                                                                                                        {dig.assets[0].account_id ?
                                                                                                            <span className="small mr-2">{" : "+dig.assets[0].account_id}</span>
                                                                                                        : dig.account_id ?
                                                                                                            <span className="small mr-2">{" "+dig.account_id}</span>
                                                                                                        : null}
                                                                                                        {dig.assets[0].region ?
                                                                                                            <span className="small mr-2">{" : "+dig.assets[0].region}</span>
                                                                                                        : null}
                                                                                                        {dig.assets[0].resource_type ?
                                                                                                            <span className="small mr-2">{" : "+dig.assets[0].resource_type}</span>
                                                                                                        : null}
                                                                                                        {dig.assets[0].service_name ?
                                                                                                            <span className="small mr-2">{" : "+dig.assets[0].service_name}</span>
                                                                                                        : null}
                                                                                                        {dig.assets[0].asset_name ?
                                                                                                            <span className="small mr-2">{" : "+dig.assets[0].asset_name}</span>
                                                                                                        : null}
                                                                                                        </React.Fragment>
                                                                                                    : null}
                                                                                                    
                                                                                                    {dig.description ?
                                                                                                        <span className="small mr-2">{" : "+dig.description}</span>
                                                                                                    : null}
                                                                                                    {/* {dig.additional_details && dig.additional_details.message ?
                                                                                                        <span className="small mr-2">{" : "+dig.additional_details.message}</span>
                                                                                                    : null} */}
                                                                                                </p>
                                                                                            </div>
                                                                                        : (item.tag === "Errors") ?
                                                                                            <div className={`row p-1 ${i ? "border-top" : ""} ${this.state.selectedDiagnostic === "errors_"+index+"_"+i ? "bg-muted disabled" : "cursorPointer"}`} onClick={() => this.onChangeDiagnosticEvent(item.label, item.tag, dig, index, "events_"+index+"_"+i, dig.asset_id, "")}>
                                                                                                <p className="mb-0">
                                                                                                    <span className={`mr-2 risk-${dig.severity ? dig.severity.toLowerCase() : ""}`}>{capitalizeFirstLetter(dig.severity)}</span>
                                                                                                    {dig.event_time ? 
                                                                                                        <span className="mb-0 mr-2 text-white f12 font-weight-bold text-primary-color">{momentConvertionUtcToLocalTime(dig.event_time, "DD MMM YYYY HH:mm")}</span>
                                                                                                    : null}
                                                                                                    {dig.error_type_source ?
                                                                                                        <span className="mr-2 text-primary-color">{dig.error_type_source}</span>
                                                                                                    : null}
                                                                                                    {dig.error_type && dig.error_type.length ?
                                                                                                        <span className="mr-2 text-purple-2">{dig.error_type.join(", ")}</span>
                                                                                                    : null}
                                                                                                    <span className="mr-2">{dig.event_type}</span>
                                                                                                    <span className="small mr-2 text-primary-color">Resource</span>
                                                                                                    {dig.assets && dig.assets.length ?
                                                                                                        <React.Fragment>
                                                                                                        {dig.assets[0].account_id ?
                                                                                                            <span className="small mr-2">{" : "+dig.assets[0].account_id}</span>
                                                                                                        : dig.account_id ?
                                                                                                            <span className="small mr-2">{" "+dig.account_id}</span>
                                                                                                        : null}
                                                                                                        {dig.assets[0].region ?
                                                                                                            <span className="small mr-2">{" : "+dig.assets[0].region}</span>
                                                                                                        : null}
                                                                                                        {dig.assets[0].resource_type ?
                                                                                                            <span className="small mr-2">{" : "+dig.assets[0].resource_type}</span>
                                                                                                        : null}
                                                                                                        {dig.assets[0].service_name ?
                                                                                                            <span className="small mr-2">{" : "+dig.assets[0].service_name}</span>
                                                                                                        : null}
                                                                                                        {dig.assets[0].asset_name ?
                                                                                                            <span className="small mr-2">{" : "+dig.assets[0].asset_name}</span>
                                                                                                        : null}
                                                                                                        </React.Fragment>
                                                                                                    : null}
                                                                                                    
                                                                                                    {dig.description ?
                                                                                                        <span className="small mr-2">{" : "+dig.description}</span>
                                                                                                    : null}
                                                                                                </p>
                                                                                            </div>
                                                                                        : null
                                                                                        )
                                                                                    })
                                                                                : null}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    : null}
                                                </div>
                                                </React.Fragment>
                                            )
                                        })
                                    :
                                        <div className="d-flex justify-content-center m-4">
                                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}
/**
 * Type of the props used in the component
 */
DiagnosticsLeftSection.propTypes = {
    listDiagnostics: PropTypes.func,
    getDiagnosticDetails: PropTypes.func,
    setDiagnosticsPropsDetails: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
	// console.log("state", state)
	return {
        accounts: state.filters.accounts,
        regions: state.filters.regions && state.filters.regions[0] && state.filters.regions[0].provider_regions,
        diagnosticsPropsDetails: state.aiops.diagnosticsPropsDetails ? state.aiops.diagnosticsPropsDetails : {},
        diagnosticsDetails: state.aiops.aiopsDiagnosticsDetails && state.aiops.aiopsDiagnosticsDetails.result ? state.aiops.aiopsDiagnosticsDetails.result : [],
    }
}

export default connect(mapStateToProps, {
	listAllAccounts,
	listAllRegions,
    getDiagnosticDetails,
    setDiagnosticsPropsDetails,
})(withRouter(DiagnosticsLeftSection))