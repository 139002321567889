/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from 'react'
import { setEditRuleDetails, addContainerImage, listOperatingSystem, listAllAssets } from '../../../actions/governance/governanceAction'
import { listAllRegions, listAllAccounts, apiToCall } from '../../../actions/commonAction'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { DURATION_FILTER_OPTIONS } from '../../../utils/constants'
import { currentUTCtime, onlyNumericSemiColon, isNumber, getRegionName } from '../../../utils/utility'
import { Input } from 'reactstrap'

const amiImageArchitectureOption = [
	{label: 'All', value: 'All'},
	{label: '32-bit (i386)', value: '32-bit (i386)'},
	{label: '64-bit (x86_64)', value: '64-bit (x86_64)'},
	{label: '64-bit ARM (arm64)', value: '64-bit ARM (arm64)'},
	{label: '64-bit Mac', value: '64-bit Mac'},	
] 

const rootDeviceTypeOption = [
	{label: 'All', value: 'All'},
	{label: 'EBS store', value: 'EBS store'},
	{label: 'instance store', value: 'instance store'}
] 

class ServiceAmi extends Component {
	constructor(props) {
		super(props)
		this.searchRef = React.createRef()
		this.searchAllowedAmi = React.createRef()
		this.durationRef = React.createRef()
		this.scrollRef = React.createRef()
		
		this.regionRef = React.createRef()
		this.amiArchitecureRef = React.createRef()
		this.rootDeviceTypeRef = React.createRef()
		this.allowedAmiRef = React.createRef()
		this.allowedPlatformsRef = React.createRef()
		
		this.state = {
			startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
			perPage: 3,

			hasError: this.props.hasError,
			hasErrorInRuleForm: false,
			containers: [],
			containerResponse: '',

			selectedAccount: '',
			
			allowedPlatforms: [],
			selectedDuration: 'Last 3 months',
			allowedCommunityImages: this.props.editRuleDetails.selectedInputs && this.props.editRuleDetails.selectedInputs.allowedCommunityImages ? this.props.editRuleDetails.selectedInputs.allowedCommunityImages : false,
			allowedMarketplaceImages: this.props.editRuleDetails.selectedInputs && this.props.editRuleDetails.selectedInputs.allowedMarketplaceImages ? this.props.editRuleDetails.selectedInputs.allowedMarketplaceImages : false,
			searchSavedAmiText: '',
			condition: this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedImages ? this.props.editRuleDetails.condition.allowedImages : [],
			filterCondition: this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedImages ? this.props.editRuleDetails.condition.allowedImages : [],


			selectedRegion: ['All'],
			isRegionOpen: false,

			selectedAmiArchitecture: ['All'],
			isAmiArchitectureOpen: false,

			selectedRootDeviceType: ['All'],
			isRootDeviceTypeOpen: false,

			allowedAmi: [],
			selectedAllowedAmi: ['All'],
			isAllowedAmiOpen: false,

			selectedAllowedPlatforms: ['All'],
			isAllowedPlatformsOpen: false,
			regions: [],

			listOperatingSystem: [],
			newSearchText: '',
		}
	}

	handleClickOutside(event) {
		// if (this.searchRef && !this.searchRef.current.contains(event.target)) {
		// 	this.setState({ showDropdown: false })
		// }

		// if (this.searchAllowedAmi && !this.searchAllowedAmi.current.contains(event.target)) {
		// 	this.setState({ showAllowedAmiDropdown: false })
		// }

		if (this.regionRef && !this.regionRef.current.contains(event.target)) {
			this.setState({ isRegionOpen: false })
		} else {
			this.setState({ isRegionOpen: true })
		}

		if (this.amiArchitecureRef && !this.amiArchitecureRef.current.contains(event.target)) {
			this.setState({ isAmiArchitectureOpen: false })
		} else {
			this.setState({ isAmiArchitectureOpen: true })
		}

		if (this.rootDeviceTypeRef && !this.rootDeviceTypeRef.current.contains(event.target)) {
			this.setState({ isRootDeviceTypeOpen: false })
		} else {
			this.setState({ isRootDeviceTypeOpen: true })
		}		

		if (this.allowedAmiRef && !this.allowedAmiRef.current.contains(event.target)) {
			this.setState({ showDropdown: false })
		} else {
			this.setState({ showDropdown: true })
		}
		
		if (this.allowedPlatformsRef && !this.allowedPlatformsRef.current.contains(event.target)) {
			this.setState({ isAllowedPlatformsOpen: false })
		} else {
			this.setState({ isAllowedPlatformsOpen: true })
		}
		
	}
	
	getMultiSelectedCount = (type, array) => {
		return array.length && array.includes('All') ? 'All' :  array.length ? array.length +' Selected' : 'All'
	}

	handleMultiSelectChange = (childKey, arrayValue) => {
		let value = arrayValue.map(item => item.value)
		// let label = arrayValue.map(item => item)		
		if(childKey === 'region') {
			let prevState = this.state.selectedRegion
			if(prevState.includes('All') && value.includes("All")) {
				const index = value.indexOf('All');
				if (index > -1) {
					value.splice(index, 1);
				}
			} else if(prevState.includes('All') && !value.includes("All")) {
				value = []
			}

			if(value.length && value.includes("All")) {
				let selectedRegion = []
				this.state.regions.forEach(reg => {
					selectedRegion.push(reg.region)
				})
				this.setState({ selectedRegion },
					() => {
						this.handleCondition('regions', this.state.selectedRegion, '', '')
						if(!prevState.length && value.includes("All")) {
							this.setState({ isRegionOpen: false })
						}
					}
				)
			} else {
				this.setState({ selectedRegion: value },
					() => {
						this.handleCondition('regions', this.state.selectedRegion, '', '')
						if(prevState.includes("All") && !value.length) {
							this.setState({ isRegionOpen: false })
						}
					}
				)
			}
		} else if(childKey === 'imageArchitecture') {
			let prevState = this.state.selectedAmiArchitecture
			if(prevState.includes('All') && value.includes("All")) {
				const index = value.indexOf('All');
				if (index > -1) {
					value.splice(index, 1);
				}
			} 
			// else if(prevState.includes('All') && !value.includes("All")) {	
			// 	let array = []
			// }

			if(value.length && value.includes("All")) {
				let selectedAmiArchitecture = []
				amiImageArchitectureOption.forEach(result => {
					selectedAmiArchitecture.push(result.value)
				})
				
				this.setState({ selectedAmiArchitecture },
					() => {
						this.handleCondition('imageArchitecture', this.state.selectedAmiArchitecture, '', '')
						if(!prevState.length && value.includes("All")) {
							this.setState({ isAmiArchitectureOpen: false })
						}
					}
				)
			} else {
				this.setState({ selectedAmiArchitecture: value },
					() => {
						this.handleCondition('imageArchitecture', this.state.selectedAmiArchitecture, '', '')
						if(prevState.includes("All") && !value.length) {
							this.setState({ isAmiArchitectureOpen: false })
						}
					}
				)
			}
		} else if(childKey === 'allowedRootDeviceType') {
			let prevState = this.state.selectedRootDeviceType
			if(prevState.includes('All') && value.includes("All")) {
				const index = value.indexOf('All');
				if (index > -1) {
					value.splice(index, 1);
				}	
			} else if(prevState.includes('All') && !value.includes("All")) {
				value = []
			}

			if(value.length && value.includes("All")) {
				let selectedRootDeviceType = []
				rootDeviceTypeOption.forEach(result => {
					selectedRootDeviceType.push(result.value)
				})
				this.setState({ selectedRootDeviceType },
					() => {
						this.handleCondition('allowedRootDevice', this.state.selectedRootDeviceType, '', '')
						if(!prevState.length && value.includes("All")) {
							this.setState({ isRootDeviceTypeOpen: false })
						}
					}
				)
			} else {
				this.setState({ selectedRootDeviceType: value },
					() => {
						this.handleCondition('allowedRootDevice', this.state.selectedRootDeviceType, '', '')
						if(prevState.includes("All") && !value.length) {
							this.setState({ isRootDeviceTypeOpen: false })
						}
					}
				)
			}
		} else if(childKey === 'allowedAmi') {
			let prevState = this.state.selectedAllowedAmi
			if(prevState.includes('All') && value.includes("All")) {
				const index = value.indexOf('All');
				if (index > -1) {
					value.splice(index, 1);
				}	
			} else if(prevState.includes('All') && !value.includes("All")) {
				value = []
			}

			if(value.length && value.includes("All")) {
				let selectedAllowedAmi = []
				rootDeviceTypeOption.forEach(result => {
					selectedAllowedAmi.push(result.value)
				})
				this.setState({ selectedAllowedAmi },
					() => {
						this.handleCondition('repository', this.state.selectedAllowedAmi, '', '')
						if(!prevState.length && value.includes("All")) {
							this.setState({ isAllowedAmiOpen: false })
						}
					}
				)
			} else {
				this.setState({ selectedAllowedAmi: value },
					() => {
						this.handleCondition('repository', this.state.selectedAllowedAmi, '', '')
						if(prevState.includes("All") && !value.length) {
							this.setState({ isAllowedAmiOpen: false })
						}
					}
				)
			}
		} else if(childKey === 'allowedPlatforms') {
			let prevState = this.state.selectedAllowedPlatforms
			if(prevState.includes('All') && value.includes("All")) {
				const index = value.indexOf('All');
				if (index > -1) {
					value.splice(index, 1);
				}
			} 
			// else if(prevState.includes('All') && !value.includes("All")) {	
			// 	let array = []
			// }

			if(value.length && value.includes("All")) {
				let selectedAllowedPlatforms = []
				amiImageArchitectureOption.forEach(result => {
					selectedAllowedPlatforms.push(result.value)
				})
				
				this.setState({ selectedAllowedPlatforms },
					() => {
						this.handleCondition('allowedPlatforms', this.state.selectedAllowedPlatforms, '', '')
						if(!prevState.length && value.includes("All")) {
							this.setState({ isAllowedPlatformsOpen: false })
						}
					}
				)
			} else {
				this.setState({ selectedAllowedPlatforms: value },
					() => {
						this.handleCondition('allowedPlatforms', this.state.selectedAllowedPlatforms, '', '')
						if(prevState.includes("All") && !value.length) {
							this.setState({ isAllowedPlatformsOpen: false })
						}
					}
				)
			}
		}
	}

	componentDidMount = () => {
		let selectedInputs = {}
		// this.handleMultiSelectChange('region', ['All'])
		// this.handleMultiSelectChange('amiImageArchitecture', ['All'])
		// this.handleMultiSelectChange('allowedRootDeviceType', ['All'])
		// this.handleMultiSelectChange('allowedAmi', ['All'])
		// this.handleMultiSelectChange('allowedPlatforms', ['All'])
		selectedInputs['account'] = ''
		selectedInputs['repository'] = this.state.selectedAllowedAmi
		selectedInputs['imageArchitecture'] = this.state.selectedAmiArchitecture
		selectedInputs['allowedPlatforms'] = this.state.selectedAllowedPlatforms
		selectedInputs['allowedRootDevice'] = this.state.selectedRootDeviceType
		selectedInputs['regions'] = this.state.selectedRegion
		selectedInputs['duration'] = 'Last 3 months'
		selectedInputs['allowedCommunityImages'] = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.generalSettings && this.props.editRuleDetails.condition.generalSettings.allowedCommunityImages ? true : false
		selectedInputs['allowedMarketplaceImages'] = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.generalSettings && this.props.editRuleDetails.condition.generalSettings.allowedMarketplaceImages ? true : false

		this.props.setEditRuleDetails('selectedInputs', selectedInputs)
		this.setState({ 
			accounts: [],
			accountSearchResult: [],
			regions: [],
		},
			() =>  {
				this.getAllFilters()
			}
		)

		let params = {}
		if(!this.props.operatingSystemList || !this.props.operatingSystemList.length) {
			params.provider = this.props.editRuleDetails.provider.toLowerCase()
			params.resource_type = this.props.editRuleDetails.resource_type
			this.props.listOperatingSystem(params, (promise, os) => {
				if(promise) {
					let listOperatingSystem = []
					if(os[0].results && os[0].results.length) {
						listOperatingSystem = os[0].results
					}
					let dataRow = {}
					dataRow.description = 'All'
					dataRow.os_platform = 'All'
					listOperatingSystem.unshift(dataRow)
					this.setState({ listOperatingSystem})
				} else {
					this.setState({ listOperatingSystem: [] })
				}
			})
		} else {
			let listOperatingSystem = this.props.operatingSystemList
			if(!this.props.operatingSystemList.filter(arr => arr.os_platform === 'All').length) {
				let dataRow = {}
				dataRow.description = 'All'
				dataRow.os_platform = 'All'
				listOperatingSystem.unshift(dataRow)
			}
			this.setState({ listOperatingSystem })
		}
		
		params = {}
		params.provider = this.props.editRuleDetails.provider.toLowerCase()
		params.service_name = ["ec2-images"]
		this.props.listAllAssets(params, (promise, assets) => {
			if(promise) {
				this.setState({ allowedAmi: assets.results && assets.results.length ? assets.results : [] })
			}
		})
	}

	componentDidUpdate = (prevProps) => {
		if ( this.props.editRuleDetails.condition !== prevProps.editRuleDetails.condition ) {
			let condition = this.props.editRuleDetails && this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedImages ? this.props.editRuleDetails.condition.allowedImages : []
			this.setState({ condition }, 
				() => this.searchSavedAmi()
			)
		}

		if(this.props.hasError !== this.state.hasError) {
			this.setState({ hasError: this.props.hasError })
		}
	}

	getAllFilters = () => {
		let params = {
			provider: this.props.parameters && this.props.parameters.provider.toLowerCase(),
		}

		if(this.props.accounts && this.props.accounts.length) {
			let accounts = this.props.accounts.filter(arr => arr.account !== 'All');
			
			this.setState({ accounts, accountSearchResult: accounts, allowedAmiSearchResult: accounts })
		} else {
			this.props.listAllAccounts(params, (promise, accounts) => {
				if (promise) {
					this.setState({ accounts, accountSearchResult: accounts, allowedAmiSearchResult: accounts })
				}
			})
		}

		if(this.props.regions && this.props.regions.length) {
			let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
			this.setState({ regions: providerRegions })
		} else {
			this.getRegions()
		}
	}

	getRegions = () => {
		let params = {
			provider: this.props.editRuleDetails && this.props.editRuleDetails.provider.toLowerCase(),
		}
		
		this.props.listAllRegions(params, (promise, regions) => {
			if (promise) {
				let providerRegions = regions && regions[0] && regions[0].provider_regions
				if(providerRegions) {
					let provider_regions_row = {}
					provider_regions_row.availability_zones = []
					provider_regions_row.city = "All"
					provider_regions_row.country = "All"
					provider_regions_row.geography = "All"
					provider_regions_row.name = "All"
					provider_regions_row.region = "All"
					provider_regions_row.region_id = "All"
					provider_regions_row.state = "All"
					providerRegions.unshift(provider_regions_row);
				}
				this.setState({ regions: providerRegions })
			} else {
				this.setState({ regions: [] })
			}
		})
	}

	handleCondition = (conditionValue, value, conditionLabel, label) => {
		let selectedInputs = this.props.editRuleDetails.selectedInputs ? this.props.editRuleDetails.selectedInputs : {}
		selectedInputs[conditionValue] = value
		if(conditionLabel !== '') {
			selectedInputs[conditionLabel] = label
		}
		this.props.setEditRuleDetails('selectedInputs', selectedInputs)
	}

	handleConditionOptionTable = (value) => {
		let selectedInputs = this.props.editRuleDetails.selectedInputs ? this.props.editRuleDetails.selectedInputs : {}
		selectedInputs[value] = this.state[value]
		
		this.props.setEditRuleDetails('selectedInputs', selectedInputs)


		// let result = {}
		// result['allowedCommunityImages'] = this.props.editRuleDetails.condition.allowedCommunityImages ? true : false
		// result['allowedMarketplaceImages'] = this.props.editRuleDetails.condition.allowedMarketplaceImages ? true : false

		// let conditionResult = {}
		// conditionResult['generalSettings'] = result		
		
		// let condition = this.props.editRuleDetails.condition ? this.props.editRuleDetails.condition.allowedImages : []
		// conditionResult['allowedImages'] = condition
		// this.props.setEditRuleDetails('condition', conditionResult)		
		
	}

	addUpdateContainerArray = (type) => {
		
		let condition = this.props.editRuleDetails.condition&& this.props.editRuleDetails.condition.allowedImages ? this.props.editRuleDetails.condition.allowedImages : []

		let selectedInputs = this.props.editRuleDetails.selectedInputs ? this.props.editRuleDetails.selectedInputs : {}
		let hasErrorInRuleForm = false
		if((!this.props.editRuleDetails.selectedInputs.account || !this.props.editRuleDetails.selectedInputs.account === '') && (!this.props.editRuleDetails.selectedInputs.selectedAllowedAmi || !this.props.editRuleDetails.selectedInputs.selectedAllowedAmi.length) ){
			hasErrorInRuleForm = true
		}

		if(!this.props.editRuleDetails.selectedInputs.regions || !this.props.editRuleDetails.selectedInputs.regions.length ) {
			hasErrorInRuleForm = true
		}
		
		this.setState({ hasErrorInRuleForm })
		if(!hasErrorInRuleForm && !this.state.hasAccountError && Object.entries(selectedInputs).length) {
			selectedInputs['timestamp'] = currentUTCtime()
			let result = {}
			result['allowedCommunityImages'] = this.props.editRuleDetails.selectedInputs.allowedCommunityImages ? true : false
			result['allowedMarketplaceImages'] = this.props.editRuleDetails.selectedInputs.allowedMarketplaceImages ? true : false			
			delete selectedInputs['allowedCommunityImages']
			delete selectedInputs['allowedMarketplaceImages']

			if(type === 'Add') {
				selectedInputs['rowIndex'] = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedImages ? this.props.editRuleDetails.condition.allowedImages.length : 0
				condition.push(selectedInputs)
			} else {
				condition[this.state.rowIndex] = selectedInputs
			}
			this.setState({
				condition
			}, () => this.searchSavedAmi())

			let conditionResult = {}
			conditionResult['generalSettings'] = result
			conditionResult['allowedImages'] = condition

			this.props.setEditRuleDetails('condition', conditionResult)			

			let newCondition = {}
			newCondition['imageArchitecture'] = ['All']
			newCondition['allowedPlatforms'] = ['All']
			newCondition['allowedRootDevice'] = ['All']
			newCondition['regions'] = ['All']
			newCondition['duration'] = 'Last 3 months'
			newCondition['repository'] = ['All']

			newCondition['allowedCommunityImages'] = result['allowedCommunityImages']
			newCondition['allowedMarketplaceImages'] = result['allowedMarketplaceImages']

			this.props.setEditRuleDetails('selectedInputs', newCondition)
			this.setState({ 
				editEnabled: false,
				showDatepicker: false,
				allowedCommunityImages: result['allowedCommunityImages'],
				allowedMarketplaceImages: result['allowedMarketplaceImages'],
				selectedAccount: '',
				selectedAllowedAmi: [],
				selectedAllowedPlatforms: [],
				selectedRegion: [],
				selectedRootDeviceType: [],
				selectedAmiArchitecture: [],
				selectedDuration: 'Last 3 months'
			})
		}
	}
	
	actionOnSavedData = (action, index) => {
		if(action === 'edit') {
			this.scrollRef.current.scrollIntoView({ behavior: 'smooth' })
			let editCondtion = {}

			editCondtion['account'] = this.props.editRuleDetails.condition.allowedImages[index].account ? this.props.editRuleDetails.condition.allowedImages[index].account : ''
			editCondtion['regions'] = this.props.editRuleDetails.condition.allowedImages[index].regions
			editCondtion['repository'] = this.props.editRuleDetails.condition.allowedImages[index].repository
			editCondtion['duration'] = this.props.editRuleDetails.condition.allowedImages[index].duration
			editCondtion['imageArchitecture'] = this.props.editRuleDetails.condition.allowedImages[index].imageArchitecture
			editCondtion['allowedPlatforms'] = this.props.editRuleDetails.condition.allowedImages[index].allowedPlatforms
			editCondtion['allowedRootDevice'] = this.props.editRuleDetails.condition.allowedImages[index].allowedRootDevice
			editCondtion['allowedCommunityImages'] = this.props.editRuleDetails.condition.generalSettings.allowedCommunityImages
			editCondtion['allowedMarketplaceImages'] = this.props.editRuleDetails.condition.generalSettings.allowedMarketplaceImages
			editCondtion['rowIndex'] = index
			this.props.setEditRuleDetails('selectedInputs', editCondtion)
			this.setState({ 
				editEnabled: true,
				rowIndex: index,
				selectedAccount: editCondtion['account'],
				selectedRegion: editCondtion['regions'],
				selectedAllowedAmi: editCondtion['repository'],
				selectedAmiArchitecture: editCondtion['imageArchitecture'],
				selectedAllowedPlatforms: editCondtion['allowedPlatforms'],
				selectedRootDeviceType: editCondtion['allowedRootDevice'],
				selectedDuration: editCondtion['duration'],
				allowedCommunityImages: editCondtion['allowedCommunityImages'],
				allowedMarketplaceImages: editCondtion['allowedMarketplaceImages'],
			})
		} else {
			let condition = this.state.condition.allowedImages.filter((item) => item.rowIndex !== index);

			let result = {}
			if(!condition.length) {
				result['allowedCommunityImages'] = false
				result['allowedMarketplaceImages'] = false
			} else {
				result['allowedCommunityImages'] = this.props.editRuleDetails.selectedInputs.allowedCommunityImages
				result['allowedMarketplaceImages'] = this.props.editRuleDetails.selectedInputs.allowedMarketplaceImages
			}

			this.setState({
				allowedCommunityImages: result['allowedCommunityImages'],
				allowedMarketplaceImages: result['allowedMarketplaceImages']
			})
			
			let conditionResult = {}
			conditionResult['generalSettings'] = result
			conditionResult['allowedImages'] = condition
			this.props.setEditRuleDetails('condition', conditionResult)
			this.setState({ condition, filterCondition: condition, searchSavedAmiText: '' })
		}
	}
	
	clearSelectedContainerData = () => {
		let newCondition = {}
		newCondition['imageArchitecture'] = ['All']
		newCondition['allowedPlatforms'] = ['All']
		newCondition['allowedRootDevice'] = ['All']
		newCondition['regions'] = ['All']
		newCondition['duration'] = 'Last 3 months'
		newCondition['repository'] = ['All']

		newCondition['allowedCommunityImages'] = this.props.editRuleDetails.selectedInputs.allowedCommunityImages
		newCondition['allowedMarketplaceImages'] = this.props.editRuleDetails.selectedInputs.allowedMarketplaceImages

		this.props.setEditRuleDetails('selectedInputs', newCondition)

		this.setState({ 
			editEnabled: false,
			selectedAccount: '',
			selectedRegion: [],
			selectedAllowedAmi: [],
			selectedAmiArchitecture: [],
			selectedAllowedPlatforms: [],
			selectedRootDeviceType: [],
			selectedDuration: 'Last 3 months'
		})
	}

	searchSavedAmi = () => {
		let filterCondition = this.state.condition
		if (this.state.searchSavedAmiText !== '') {
			filterCondition =
				filterCondition &&
				filterCondition.filter(item => {
					let isPresent = []
					isPresent = this.recursiveSearch(item, this.state.searchSavedAmiText)
						.flat()
						.filter(bool => bool === true)
					if (isPresent[0]) {
						return true
					} else {
						return false
					}
				})
		}

		let totalPages = 1
		if(filterCondition.length > this.state.perPage) {
			totalPages = Math.ceil(filterCondition.length / this.state.perPage)
		}
		this.setState({ filterCondition, totalPages, startRecord: 0, currentPage: 1 })
	}

	recursiveSearch = (item, searchValue) => {
		return Object.keys(item).map(key => {
			if (item[key] !== null && typeof item[key] === 'object') {
				return this.recursiveSearch(item[key], searchValue)
				// eslint-disable-next-line valid-typeof
			} else if (typeof item[key] === 'array') {
				return item.map((arrayElement, i) => this.recursiveSearch(arrayElement[i], searchValue))
			} else {
				// if(this.props.searchFilter.includes(key)){
				return item[key] !== null
					? item[key]
							.toString()
							.toLowerCase()
							.indexOf(searchValue.toString().toLowerCase()) > -1
					: false
				// }else{
				// return false;
				// }
			}
		})
	}

	handleKeyPress = (event) => {
		if(event.charCode === 13 || event.keyCode === 13 || event.charCode === 32 || event.keyCode === 32){
			event.preventDefault(); // prevents inserting linebreak
		}
	}

	validateAccountNumber = () => {
		this.setState({ hasAccountError: false })
		if(this.state.selectedAccount && this.state.selectedAccount !== "") {
			let strSplit = this.state.selectedAccount.split(":")
			let accountNumberErrorArray = []
			strSplit.forEach(item => {
				item.trim()
				if(item.length < 12 || item.length > 12 || !isNumber(item)) {
					accountNumberErrorArray.push(item)
				}
			})
			let accountNumberError = accountNumberErrorArray.join(', ')

			this.setState({ hasAccountError: accountNumberError !== '' ? true : false, accountNumberError })
		}
	}	

	getAMIList = () => {
		let amiSearchList = []
		if(this.state.newSearchText && this.state.newSearchText !== '') {
			this.state.allowedAmi.length && this.state.allowedAmi.forEach(item => {
				if(item.asset_arn.includes(this.state.newSearchText)) {
					amiSearchList.push(item.asset_arn)
				}
			})
		} else {
			this.state.allowedAmi.length && this.state.allowedAmi.forEach(item => {
				amiSearchList.push(item.asset_arn)
			})
		}
		this.setState({ amiSearchList, showDropdown: true, hasErrorInAmiEntered: false },
			//() => this.handleCondition('account', this.state.newSearchText, '', this.state.newSearchText)
		)
	}
	
	handleAMIKeyPress = (event) => {
		if(event.charCode === 13 || event.keyCode === 13){
			this.setState({ showDropdown: false })
			let hasErrorInAmiEntered = false;
			let split = this.state.newSearchText.split(':')
			// let acc = repository.slice(0,12)
			if(split.length !== 6) {
				hasErrorInAmiEntered = true
			} else {
				// let aws = split[0]
				if(split[0] !== 'arn') {
					hasErrorInAmiEntered = true
				} else if(split[1] !== 'aws') {
					hasErrorInAmiEntered = true
				} else if(split[2] !== 'ec2') {
					hasErrorInAmiEntered = true
				} else {
					let region = split[3]
					let regionExist = this.state.regions.filter((item) => item.region === region);
					if(!regionExist) {
						hasErrorInAmiEntered = true
					}

					let splitRepo = split[5].split('/')
					if(splitRepo.length !== 2) {
						hasErrorInAmiEntered = true
					} else {
						if(splitRepo[0] !== 'image') {
							hasErrorInAmiEntered = true
						}
					}
				}
			}
			if(!hasErrorInAmiEntered) {
				let selectedAllowedAmi = this.state.selectedAllowedAmi
				selectedAllowedAmi.push(this.state.newSearchText)
				selectedAllowedAmi = selectedAllowedAmi.filter(arr => arr !== 'All')
				this.setState({ selectedAllowedAmi, newSearchText: '' })
				this.handleCondition('allowedAmi', selectedAllowedAmi, '', selectedAllowedAmi)
			} else {
				this.setState({ hasErrorInAmiEntered })
			}
		}
	}

	setAllowedAmi = (item, index) => {
		let checked = true
		if(this.state['checkbox_allowed_ami_'+index]) {
			checked = false
		}
		this.setState({ ['checkbox_allowed_ami_'+index] : checked })
		let selectedAllowedAmi = this.state.selectedAllowedAmi
		if(checked) {
			if(item !== 'All') {
				selectedAllowedAmi.push(item)
			} else {
				selectedAllowedAmi = ['All']
				rootDeviceTypeOption.forEach(item => {
					selectedAllowedAmi.push(item.value)
				})
			}
		} else {
			const index = selectedAllowedAmi.indexOf(item);
			if (index > -1) {
				selectedAllowedAmi.splice(index, 1);

			}
		}
		selectedAllowedAmi = selectedAllowedAmi.filter(arr => arr !== 'All')
		this.setState({ selectedAllowedAmi })
	}

	removeSelectedOption = (section, index, option) => {
		let details = this.state.selectedAllowedAmi.filter(e => e !== option)
		this.setState({ selectedAccount: details })
	}
	
	render() {
		return (
			<div ref={this.scrollRef} onClick={ (event) => { this.handleClickOutside(event) } }>
				<h6 class="text-info">General Settings</h6>
				<div class="d-flex form-group">
					<div className="form-check mr-3 mb-0">
						<input 
							type="checkbox" 
							className="form-check-input" 
							checked={ this.state.allowedCommunityImages ? true : false }
							onChange={e => this.setState({ allowedCommunityImages: this.state.allowedCommunityImages ? false : true },
								() => this.handleConditionOptionTable('allowedCommunityImages')
							)}
						/>
						<label className="form-check-label">Allowed Community Images</label>
					</div>
					<div className="form-check mr-3 mb-0">
						<input 
							type="checkbox" 
							className="form-check-input" 
							checked={ this.state.allowedMarketplaceImages ? true : false }
							onChange={e => this.setState({ allowedMarketplaceImages: this.state.allowedMarketplaceImages ? false : true },
								() => this.handleConditionOptionTable('allowedMarketplaceImages')
							)}
						/>
						<label className="form-check-label">Allowed Marketplace Images</label>
					</div>
				</div>
				<div className="row zapInputDark">
					<div className="col-lg-3 border-right">
						<div className="rounded bg-dark p-3 h-100">
							<h6 class="text-info">AMI Settings</h6>
							<div className="form-group">
								<label className="w-100">Allowed Account Numbers</label>
								<small className="text-info w-100">Account numbers seprated by "<b> : </b>"</small>
								<div className="searchDropText">
									<Input
										value={this.state.selectedAccount} 
										rows="3"
										onChange={e => this.setState({ selectedAccount: onlyNumericSemiColon(e.target.value) },
											() => this.handleCondition('account', this.state.selectedAccount, '', this.state.selectedAccount)
										)}
										onKeyDown={this.handleKeyPress}
										onBlur={e => this.validateAccountNumber()}
										className='inputTextbox'
										placeholder='Enter Account'
									/>
									
									{this.state.hasErrorInRuleForm && (this.state.selectedAccount === '') ? (
										<p className='text-danger m-0 p-0'>Account is Required.</p>
									) : null}
									{this.state.hasAccountError && (this.state.accountNumberError && this.state.accountNumberError !== '') ? (
										<p className='text-danger m-0 p-0'>Invalid account numbers -  <span className="text-muted">{this.state.accountNumberError}</span></p>
									) : null}
									
								</div>
							</div>
							<div className="form-group">
								<label>Allowed AMI Regions</label>
								<div className={`multiselectDarkTheme maxWidth align-self-center mr-2 z999 ${this.state.regions && this.state.regions.length > 10 ? '' : 'removeDropdownSearchBar' } `} ref={this.regionRef}>
									<ReactMultiSelectCheckboxes
										placeholderButtonLabel="All"
										getDropdownButtonLabel={() => this.getMultiSelectedCount('region', this.state.selectedRegion)}
										// menuIsOpen ={this.state.isRegionOpen}
										value={this.state.selectedRegion.map(reg => ({
											value: reg,
											label: reg !== 'All' ? getRegionName(reg, this.state.regions) : 'All'
										}))}
										onChange={arr => {
										this.handleMultiSelectChange('region', arr ? arr : []) }}
										options={this.state.regions && this.state.regions.map(region => ({
											value: region.region,
											label: region.name,
										}))}
									/>
								</div>
								{/* <Select
									isSearchable={true}
									value={({
										value: this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.region ? this.props.editRuleDetails.condition.region : 'Select',
										label: this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.regionLabel ? this.props.editRuleDetails.condition.regionLabel :'Select'
									})}
									options={regions && regions.map(item => ({
										value: item.region,
										label: item.name,
									}))}
									placeholder={'Choose Region'}
									className='w-100'
									onChange={event => this.handleCondition('region', event.value, 'regionLabel', event.label )}
								/> */}
								{this.state.hasErrorInRuleForm && !this.state.selectedRegion.length ? (
									<p className='text-danger m-0 p-0'>Allowed AMI Region is Required.</p>
								) : null}
							</div>
							<div className="form-group">
								<label>Allowed AMI</label>
								<div className="position-relative d-inline-block w-100" ref={this.allowedAmiRef}>
									<Input
										type='text'
										value={this.state.newSearchText}
										/* maxlength="12" */
										minlength="5"
										onChange={e => {
											this.setState({ newSearchText: e.target.value}, 
												() => this.getAMIList()
											)
										}}
										onKeyDown={this.handleAMIKeyPress}
										className='inputTextbox'
										placeholder='All'
										onClick={(e => this.setState({ showDropdown : true }, () => this.getAMIList()))}
									/>
									<div className="d-flex">
										<div className="flex-grow align-self-center">
											{this.state.selectedAllowedAmi.length ? 
												this.state.selectedAllowedAmi.length === 1 && this.state.selectedAllowedAmi[0] !== 'All' ? 
													this.state.selectedAllowedAmi.map((item, index) => {
														return(
															<span key={index} className="badge badge-light f14 mr-1 mb-1 mt-1" >
																{item}
																<i onClick={ () => this.removeSelectedOption('allowedAmi', index, item) } className='ml-1 fal fa-times cursorPointer'></i>
															</span>
														)
													})
												:
												this.state.selectedAllowedAmi.length > 1 ? 
													this.state.selectedAllowedAmi.map((item, index) => {
														return(
															<span key={index} className="badge badge-light f14 mr-1 mb-1 mt-1" >
																{item}
																<i onClick={ () => this.removeSelectedOption('allowedAmi', index, item) } className='ml-1 fal fa-times cursorPointer'></i>
															</span>
														)
													})
												: null
											: null
											}
										</div>
									</div>
									{this.state.hasErrorInAmiEntered ? 
										<p className='text-danger m-0 p-0'>AMI is Invalid.</p>
									: null}
									
									{this.state.showDropdown && this.state.amiSearchList && this.state.amiSearchList.length ? 
										<div className={`dropdownOptions topAuto mt-2`}>
											{this.state.amiSearchList.map((item, index) => {
												return (
													<button className="d-flex mb-3" onClick={() => this.setAllowedAmi(item, index)}>
														<input type="checkbox" className={`mt-6p mr-1`} checked={this.state['checkbox_allowed_ami_'+index] ? true : false} />
														<p className="mb-0 text-left">{item}</p>
													</button>
												)
											})}
										</div>
									: null}
								</div>
								{/* <div className={`governanceServiceMultiSelect maxWidth align-self-center mr-2 z998 ${rootDeviceTypeOption && rootDeviceTypeOption.length > 10 ? '' : 'removeDropdownSearchBar' } `} ref={this.allowedAmiRef}>
									<ReactMultiSelectCheckboxes						
										placeholderButtonLabel="All"
										getDropdownButtonLabel={() => this.getMultiSelectedCount('allowedAmi', this.state.selectedAllowedAmi)}
										menuIsOpen ={this.state.isAllowedAmiOpen}
										value={this.state.selectedAllowedAmi.map(result => ({
											value: result,
											label: result
										}))}
										onChange={arr => {
										this.handleMultiSelectChange('allowedAmi', arr ? arr : []) }}
										options={rootDeviceTypeOption && rootDeviceTypeOption.map(option => ({
											value: option.value,
											label: option.label,
										}))}
									/>
								</div> */}
								{this.state.hasErrorInRuleForm && !this.state.selectedAllowedAmi.length ? (
									<p className='text-danger m-0 p-0'>Allowed AMI is Required.</p>
								) : null}
							</div>
							<div className="form-group">
								<label>AMI Image Architecture</label>
								<div className={`multiselectDarkTheme maxWidth align-self-center mr-2 z997 ${amiImageArchitectureOption && amiImageArchitectureOption.length > 10 ? '' : 'removeDropdownSearchBar' } `} ref={this.amiArchitecureRef}>
									<ReactMultiSelectCheckboxes						
										placeholderButtonLabel="All"
										getDropdownButtonLabel={() => this.getMultiSelectedCount('imageArchitecture', this.state.selectedAmiArchitecture)}
										// menuIsOpen ={this.state.isAmiArchitectureOpen}
										value={this.state.selectedAmiArchitecture.map(result => ({
											value: result,
											label: result
										}))}
										onChange={arr => {
										this.handleMultiSelectChange('imageArchitecture', arr ? arr : []) }}
										options={amiImageArchitectureOption && amiImageArchitectureOption.map(option => ({
											value: option.value,
											label: option.label,
										}))}
									/>
								</div>
							</div>
							<div className="form-group">
								<label>Allowed Platforms</label>
								<div className={`multiselectDarkTheme maxWidth align-self-center mr-2 z996 ${this.state.listOperatingSystem && this.state.listOperatingSystem.length > 10 ? '' : 'removeDropdownSearchBar' } `} ref={this.allowedPlatformsRef}>
									<ReactMultiSelectCheckboxes						
										placeholderButtonLabel="All"
										getDropdownButtonLabel={() => this.getMultiSelectedCount('allowedPlatforms', this.state.selectedAllowedPlatforms)}
										// menuIsOpen ={this.state.isAllowedPlatformsOpen}
										value={this.state.selectedAllowedPlatforms.map(result => ({
											value: result,
											label: result
										}))}
										onChange={arr => {
										this.handleMultiSelectChange('allowedPlatforms', arr ? arr : []) }}
										options={this.state.listOperatingSystem && this.state.listOperatingSystem.map(option => ({
											value: option.os_platform,
											label: option.os_platform,
										}))}
									/>
								</div>
							</div>
							<div className="form-group">
								<label>Images Created between</label>
								<div ref={this.durationRef}>
									<Select
										/* menuIsOpen={false} */
										isSearchable={true}
										value={({
											value: this.state.selectedDuration,
											label: this.state.selectedDuration ? this.state.selectedDuration : <span className="placeholder">Select</span>
										})}
										options={DURATION_FILTER_OPTIONS.map(item => ({
											value: item.option,
											label: item.option,
										}))}
										className='w-100 z993'
										onChange={event => 
											this.setState({ 
												selectedDuration: event.value,
												showDatepicker: false, 
												durationType: '' 
											},
												() => this.handleCondition('duration', this.state.selectedDuration, '', event.label )
										)}
									/>
								</div>
							</div>
							<div className="form-group">
								<label>Allowed Root Device Type</label>
								<div className={`multiselectDarkTheme maxWidth align-self-center mr-2 z994 ${rootDeviceTypeOption && rootDeviceTypeOption.length > 10 ? '' : 'removeDropdownSearchBar' } `} ref={this.rootDeviceTypeRef}>
									<ReactMultiSelectCheckboxes						
										placeholderButtonLabel="All"
										getDropdownButtonLabel={() => this.getMultiSelectedCount('allowedRootDeviceType', this.state.selectedRootDeviceType)}
										// menuIsOpen ={this.state.isRootDeviceTypeOpen}
										value={this.state.selectedRootDeviceType.map(result => ({
											value: result,
											label: result
										}))}
										onChange={arr => {
										this.handleMultiSelectChange('allowedRootDeviceType', arr ? arr : []) }}
										options={rootDeviceTypeOption && rootDeviceTypeOption.map(option => ({
											value: option.value,
											label: option.label,
										}))}
									/>
								</div>
							</div>
							<button className='btn btn-sm btn-info align-self-center mr-3' onClick={() => this.addUpdateContainerArray(this.state.editEnabled ? 'Update' : 'Add')}>
								{this.state.editEnabled ? 'Update' : 'Add'}
							</button>
							<button className='btn btn-sm btn-light align-self-center' onClick={() => this.clearSelectedContainerData()}>
								Reset
							</button>										
						</div>
					</div>
					<div className="col-lg-9 serviceAmiTable">
						<div className="rounded bg-dark p-3 h-100">
							<div className="d-flex justify-content-between mb-2">
								<div className="d-flex align-self-end ">
									<h6 className="text-info m-0 p-0">Added Images</h6>
									{this.state.hasError && !this.state.condition.length ? 
										<small className='text-danger align-self-end'>Please add Images.</small>
									: null}
								</div>
								<input
									type='text'
									value={this.state.searchSavedAmiText}
									onChange={e => {
										this.setState({ searchSavedAmiText: e.target.value }, () => this.searchSavedAmi())
									}}
									className='form-control form-control-sm w-20 bg-muted border-gray5 text-white'
									placeholder='Search'
								/>
							</div>
							
							{this.state.filterCondition && this.state.filterCondition.length ?
								<React.Fragment>								
									<div className="d-flex align-self-center mt-2 mb-2">
										<div className="d-flex mr-2">
											<p className="small m-0 p-0 mr-2 align-self-center">Allowed Community Images</p>
											<small className={`badge ${this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.generalSettings && this.props.editRuleDetails.condition.generalSettings.allowedCommunityImages ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.generalSettings && this.props.editRuleDetails.condition.generalSettings.allowedCommunityImages ? 'Yes' : 'No'}</small>
										</div>
										<div className="d-flex">
											<p className="small m-0 p-0 mr-2 align-self-center">Allowed Marketplace Images</p>
											<small className={`badge ${this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.generalSettings && this.props.editRuleDetails.condition.generalSettings.allowedMarketplaceImages ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.generalSettings && this.props.editRuleDetails.condition.generalSettings.allowedMarketplaceImages ? 'Yes' : 'No'}</small>
										</div>
									</div>
									{this.state.filterCondition.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map(item => {
										return(
										<div className="p-3 bg-dark3 rounded mb-3">
											<div className="row">
												<div className="col-lg-2 col-sm-3 align-self-center">
													<div>
														<label className="small mb-0 d-block">Account</label>
														{item.account.split(":").map((acc, i) => {
															return(
																<small className={`badge badge-outline-light mr-1 mb-1`}>{acc}</small>
															)
														})}
													</div>
													<div>
														<button className="btn btn-outline-primary btn-sm border-0" onClick={() => this.actionOnSavedData('edit', item.rowIndex)}>Edit</button>
														<button className="btn btn-outline-danger btn-sm ml-3 border-0" onClick={() => this.actionOnSavedData('remove', item.rowIndex)}>Delete</button>
													</div>
												</div>
												<div className="col-lg-2 col-sm-3 align-self-center">
													<label className="small mb-0 d-block">Region</label>
													<i className={`far fa-location align-self-center`}></i>
													{item.regions && item.regions.length ?
														item.regions.map((reg, i) => {
															return(
																<small className={`badge badge-outline-light mr-1 mb-1`}>{getRegionName(reg, this.state.regions)}</small>
															)
														})
													:
														<small className={`badge badge-outline-light mr-1 mb-1`}>All</small>
													}
												</div>
												<div className="col-lg-2 col-sm-3 align-self-center">
													<label className="small mb-0 d-block">Allowed Platforms</label>
													{item.allowedPlatforms && item.allowedPlatforms.length ?
														item.allowedPlatforms.slice(0,1).map((rep, i) => {
														return(
															<small className={`badge badge-outline-light mr-1 mb-1`}>{rep}</small>
														)
													})
													:
														<small className={`badge badge-outline-light mr-1 mb-1`}>All</small>
													}
												</div>
												<div className="col-lg-2 col-sm-3 align-self-center">
													<label className="small mb-0 d-block">Allowed AMI</label>
													{item.allowedAmi && item.allowedAmi.length ?
														item.allowedAmi.map((rep, i) => {
														return(
															<small className={`badge badge-outline-light mr-1 mb-1`}>{rep}</small>
														)
													})
													:
														<small className={`badge badge-outline-light mr-1 mb-1`}>All</small>
													}
												</div>
												
												<div className="col-lg-2 col-sm-3 align-self-center">
													<label className="small mb-0 d-block">Allowed Image Architecture</label>
													{item.imageArchitecture && item.imageArchitecture.length ?
														item.imageArchitecture.map((rep, i) => {
														return(
															<small className={`badge badge-outline-light mr-1 mb-1`}>{rep}</small>
														)
													})
													:
														<small className={`badge badge-outline-light mr-1 mb-1`}>All</small>
													}
												</div>

												<div className="col-lg-2 col-sm-3 align-self-center">
													<label className="small mb-0 d-block">Allowed Root Device Type</label>
													{item.allowedRootDevice && item.allowedRootDevice.length ?
														item.allowedRootDevice.map((rep, i) => {
														return(
															<small className={`badge badge-outline-light mr-1 mb-1`}>{rep}</small>
														)
													})
													:
														<small className={`badge badge-outline-light mr-1 mb-1`}>All</small>
													}
													<p class="mb-2">
														<i class="far fa-clock"></i> {item.duration}
													</p>
												</div>
											</div>
										</div>
										)
									})}
									
									{this.state.filterCondition.length > this.state.perPage ?
										<div className="pagination mt-3">
											<span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
											<button disabled=""  onClick={() => this.navigatePage('start', this.state.currentPage)}><i className="fal fa-arrow-to-left"></i></button> 
											<button disabled="" onClick={() => this.navigatePage('previous', this.state.currentPage)}><i className="fal fa-angle-left"></i></button> 
											<button><i className="fal fa-angle-right" onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
											<button><i className="fal fa-arrow-to-right" onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
										</div>
									: null
									}
								</React.Fragment>
							: 
							this.state.condition && !this.state.condition.length ?
								<div className='d-flex justify-content-center m-4'>
									<p>There are no data on the images. Try add some images.</p>
								</div>
							:
								<div className='d-flex justify-content-center m-4'>
									<p>There are no data on this criteria. Please try adjusting your search.</p>
								</div>
							}					
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ServiceAmi.propTypes = {
	logType: PropTypes.object,
	setEditRuleDetails: PropTypes.func,
	listAllRegions: PropTypes.func,
	listAllAccounts: PropTypes.func,
	addContainerImage: PropTypes.func,
	apiToCall: PropTypes.func,
	editRuleDetails: PropTypes.object,
	parameters: PropTypes.object,
	provider: PropTypes.string,
	listOperatingSystem: PropTypes.func,
	listAllAssets: PropTypes.func,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('state',state)
	return {
		editRuleDetails: state.governance.editRuleDetails,
		accounts: state.filters.accounts,
		regions: state.filters.regions,
		operatingSystemList: state.filters.operatingSystemList && state.filters.operatingSystemList[0].results &&  state.filters.operatingSystemList[0].results.length ? state.filters.operatingSystemList[0].results : []
	}
}
export default connect(mapStateToProps, {
	setEditRuleDetails,
	apiToCall,
	listAllAccounts,
	listAllRegions,
	addContainerImage,
	listOperatingSystem,
	listAllAssets,
})(ServiceAmi)
