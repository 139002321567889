/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AdvancedSearch.js
 * @author Rajasekar // on 10/03/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getSearchMetaResults, setFavouriteQuery, unsetFavouriteQuery, getResourceSearchMetaResults } from '../../actions/commonAction'
import { listAllDetectors } from '../../actions/detectors/detectorsAction'
import { connect } from 'react-redux'
import { Spinner } from 'reactstrap'
import { getTotalCount } from '../../actions/detectors/detectorsAction'
import ChecksSearchBuilder from '../governance/IAM/filterSearch/ChecksSearchBuilder.js'
import CustomTable from '../designComponents/Table/customTable.js'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import _ from 'lodash'

const savedArray = [
	{search_name: 'List Critical risk Security issues reoccurred in last 14 days', created_by: 'user1', timestamp: '2020-10-01 12:10:00', filters: [{label: 'Account',value: '573921696245'},{label: 'Score',value: '>10'}]},
	{search_name: 'List Critical risk Security issues resolved manually in last 30 days', created_by: 'user2', timestamp: '2020-10-05 18:20:00', filters: [{label: 'Account',value: '293890151343'},{label: 'Score',value: '< 80'}]},
	{search_name: 'List Critical risk Security issues reoccurred in last 45 days', created_by: 'user3', timestamp: '2020-10-05 18:20:00', filters: [{label: 'Account',value: '293890151343'},{label: 'Score',value: '< 80'}]},
	{search_name: 'List Critical risk Security issues resolved manually in last 60 days', created_by: 'user4', timestamp: '2020-10-05 18:20:00', filters: [{label: 'Account',value: '293890151343'},{label: 'Score',value: '< 80'}]}

]

const filters = [
	{
		label: 'Status',
		value: 'status',
	},
	{
		label: 'Category',
		value: 'category',
	},
	{
		label: 'Duration',
		value: 'duration',
	},
	{
		label: 'Account',
		value: 'account',
	},
	{
		label: 'Region',
		value: 'region',
	},
	{
		label: 'Services',
		value: 'services',
	}
]

const filterValues = {
	status: [
		{
			label: 'pass',
			value: 'pass',
		},
		{
			label: 'fail',
			value: 'fail',
		},
	],
	category: [],
	duration: [
		{
			label: 'This Week',
			value: '7d',
		},
		{
			label: 'Last 1 week',
			value: '+7d',
		},
		{
			label: 'Last 2 weeks',
			value: '+14d',
		},
		{
			label: 'Last 1 month',
			value: '+30d',
		},
		{
			label: 'Last 2 months',
			value: '+60d',
		},
		{
			label: 'Last 3 months',
			value: '+90d',
		},
	],
}
class AdvancedSearch extends Component {
	constructor(props) {
		super(props)
		this.state = {
			searchText: '',
			showSearchView: true,
			keyupTimer: undefined,
			isLoading: false,
			filterList: [{}],
			selectedItem: '',
			parameters: {},
			filter: [],
			categotyList: [],
			selectionList: [],
			submittingFavourite: false,
			
			selectedTableRow: {},
			searchResultState: [],
			getAllFavorite: false,
			// searchType: this.props.searchFrom === 'checks' ? 'checksSearchBuilder' : this.props.searchFrom === 'resources' ? 'nlp' : 'resourceSearchBuilder',
			searchType: 'nlp',
			showSavedFilter: false,
			selectedAccount: [],
			selectedServices: [],
			selectedRegion: [],
		}
	}
	// static getDerivedStateFromProps(nextProps, prevState) {
	// 	if (nextProps.resetForm !== prevState.resetForm) {
	// 		return {
	// 			searchText: '',
	// 		}
	// 	}
	// }
	componentDidMount = () => {
		this.getResourceSearchMetaResults('onLoad')
		// let totalParams = {}
		// totalParams.aggregate_by = 'category'
		// this.props.getTotalCount(totalParams, (promise, categotyList) => {
		// 	if (promise) {
		// 		// this.setState({ categotyList })
		// 		const categoryValues = categotyList.map(category => {
		// 			return {
		// 				label: category.category,
		// 				value: category.category,
		// 			}
		// 		})
		// 		filterValues['category'] = categoryValues
		// 	} else {
		// 		this.setState({ categotyList: [] })
		// 	}
		// })
	}

	/**
	 * Method to get meta data
	 */
	getSearchMetaResults = () => {		
		//this.setState({ searchResultState: []})
		if(this.state.searchText.length > 2) {
			if (this.state.keyupTimer) {
				clearTimeout(this.state.keyupTimer)
			}
			let keyupTimer = setTimeout(() => {
				this.setState({ isLoading: true })
				let params = {}
				params.search_input =  this.state.searchText
				params.size =  5
				params.favourites =  false
				if(this.props.searchFrom === 'checks') {
					params.module_name =  'identifier'
				} else {
					params.module_name = 'synchronizer'
				}
				this.props.getSearchMetaResults(params, () => {
						let searchResultArray = []
						this.props.searchResults.length && this.props.searchResults.forEach((item, index) => {
							this.setState({ ['favorite_'+index]: item.favourite ? true : false })
							searchResultArray.push(item)
						})
						// this.setState({ searchResultState: this.props.searchResults, isLoading: false })
						console.log(searchResultArray)
						this.setState({ searchResultState: searchResultArray, isLoading: false, getAllFavorite: false })
					}
				)
			}, 500)
			this.setState({
				keyupTimer,
			})
		}
	}

	getResourceSearchMetaResults = (type) => {		
		//this.setState({ searchResultState: []})
		if(this.state.searchText.length > 2 || type === "onLoad") {
			if (this.state.keyupTimer) {
				clearTimeout(this.state.keyupTimer)
			}
			let keyupTimer = setTimeout(() => {
				this.setState({ isLoading: true })
				let params = {}
				if(this.state.searchText.length) {
					params.search_input =  this.state.searchText
				}
				params.size =  5
				params.favourites =  false
				if(this.props.searchFrom === 'checks') {
					params.module_name =  'identifier'
				} else {
					params.module_name = 'synchronizer'
				}
				this.props.getResourceSearchMetaResults(params, () => {
					let searchResultArray = []
					this.props.searchResults.length && this.props.searchResults.forEach((item, index) => {
						this.setState({ ['favorite_'+index]: item.favourite ? true : false })
						searchResultArray.push(item)
					})
					console.log('searchResultArray',searchResultArray)
					// this.setState({ searchResultState: this.props.searchResults, isLoading: false })
					console.log(searchResultArray)
					this.setState({ searchResultState: searchResultArray, isLoading: false, getAllFavorite: false })
				})
			}, 500)
			this.setState({
				keyupTimer,
			})
		}
	}

	// onSubmitEdit = () => {
	// 	this.setState({ submittingFavourite: true })
	// 	let data = {}
	// 	this.state.filterList.forEach(item => {
	// 		if(item.value) {
	// 			data[item.name] = item.values
	// 		}
	// 	})
	// 	if(this.state.selectedAccount.length) {
	// 		let array = []
	// 		this.state.selectedAccount.forEach(item => {
	// 			array.push(item.value)
	// 		})
	// 		data['account'] = array
	// 	}
	// 	if(this.state.selectedServices.length) {
	// 		let array = []
	// 		this.state.selectedServices.forEach(item => {
	// 			array.push(item.value)
	// 		})
	// 		data['service'] = this.state.selectedServices
	// 	}
	// 	if(this.state.selectedRegion.length) {

	// 		let array = []
	// 		this.state.selectedRegion.forEach(item => {
	// 			array.push(item.value)
	// 		})
	// 		data['region'] = array
	// 	}
	// 	let params = {
	// 		...this.state.selectedObjectInResult,
	// 		parameters: {
	// 			...this.state.selectedObjectInResult.parameters,
	// 			...data,
	// 		},
	// 	}
		
	// 	delete params['hits']
	// 	delete params['created_at']
	// 	this.props.setFavouriteQuery(params, success => {
	// 		this.setState({ submittingFavourite: false })
	// 		if (success) {
	// 			this.setState({ showSearchView: true })
	// 			let test = this.props.getFilteredList(params)
	// 		}
	// 	})
	// }

	getAllFavorite = () => {
		let params = {}
		params.size =  100
		params.favourites =  true
		if(this.props.searchFrom === 'checks') {
			params.module_name =  'identifier'
			this.props.getSearchMetaResults(params, () => {
				let searchResultArray = []
				this.props.searchResults.length && this.props.searchResults.forEach((item, index) => {
					this.setState({ ['favorite_'+index]: item.favourite ? true : false })
					searchResultArray.push(item)
				})
				this.setState({ searchText: '', searchResultState: searchResultArray, isLoading: false, getAllFavorite: true })
			})
		} else {
			params.module_name = 'synchronizer'
			this.props.getResourceSearchMetaResults(params, () => {
				let searchResultArray = []
				this.props.searchResults.length && this.props.searchResults.forEach((item, index) => {
					this.setState({ ['favorite_'+index]: item.favourite ? true : false })
					searchResultArray.push(item)
				})
				this.setState({ searchText: '', searchResultState: searchResultArray, isLoading: false, getAllFavorite: true })
			})
		}
		
	}
	
	setFavourite = (index, params) => {
		// this.setState({ isLoading: true })
		if(this.state['favorite_'+index]) {
			this.props.setFavouriteQuery(params,(success, favourite) => {
				console.log('favourite',favourite)
				// if(success) {
				// 	this.getSearchMetaResults()
				// }
			})
		} else {
			this.props.unsetFavouriteQuery(params,(success, favourite) => {
				// this.getSearchMetaResults()
				console.log('setFa',favourite)
			})			
		}
	}

	

	tablefilterMultiSelectChange= (childKey, arrayValue) => {
		// let label = arrayValue.map(item => item.label)
		// let value = arrayValue.map(item => item.value)
		if(childKey === 'selectedAccount') {
			this.setState({ selectedAccount: arrayValue })
		} else if(childKey === 'selectedServices') {
			this.setState({ selectedServices: arrayValue })
		} else if(childKey === 'selectedRegion') {
			this.setState({ selectedRegion: arrayValue })
		}
	}


	removefilter = (index, label, value) => {
		if(label === 'account') {
			let filterArray = this.state.selectedAccount
			filterArray.splice(index, 1);
			this.setState({ selectedAccount : filterArray });
		} else if(label === 'services') {
			let filterArray = this.state.selectedServices
			filterArray.splice(index, 1);
			this.setState({ selectedServices : filterArray });
		} else if(label === 'region') {
			let filterArray = this.state.selectedRegion
			filterArray.splice(index, 1);
			this.setState({ selectedRegion : filterArray });
		}
		
	}

	render() {
		return (
			<div className='advanced-search'>
				<div className='search-content overflow-auto'>
					<div className='header-search bd-highlight'>
						<div className='flex-fill bd-highlight'>
							<h5>Advanced Search</h5>
							<p className='m-0'>
								Search Cloud resources database by using advanced, custom and NLP queries
							</p>
						</div>
						<div className='text-right flex-fill bd-highlight cursor-pointer'>
							<i className='far fa-times' onClick={() => this.props.onCloseModal()}/>
							<p className='m-0 pt-3 textInfo' 
								onClick={() => this.setState({ searchType : this.state.searchType === 'nlp' ? this.props.searchFrom === 'checks' ? 'checksSearchBuilder' : 'resourceSearchBuilder' : 'nlp' })} >
								{this.props.searchFrom === 'resources' ? '' : this.state.searchType === 'nlp' ? 'Use Search Builder' : 'Use NLP Search' }
							</p>
						</div>
					</div>
					<div className='container-fluid py-3'>
						{this.state.searchType === 'checksSearchBuilder' || this.state.searchType === 'resourceSearchBuilder' ?
							<React.Fragment>
								<div className="mb-2">
									<div className="d-flex justify-content-between">
										<p className='font-weight-bold m-0 f15'>Filters</p>
										<p className='textInfo m-0 f13 text-right cursorPointer' onClick={() => this.setState({ showSavedFilter: this.state.showSavedFilter ? false : true }) }>{this.state.showSavedFilter ? 'Hide Saved Filters' : 'Show Saved Filters' }</p>
									</div>
									<p className="mb-0 small text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
								</div>
								
								{this.state.showSavedFilter ?
									<React.Fragment>
										<div className="d-flex justify-content-between">
											<p className='font-weight-bold m-0 f15'>Saved Filters</p>
											<select
												className='customDropdown float-right mb-1'
												value={this.state.selectedResourceStatus}
												/* onChange={event => {
													this.setState( {
															selectedResourceStatus: event.target.value
														},
															() => this.props.getRelatedTableData(this.state.selectedCategory, this.state.selectedResourceStatus, this.state.selectedStatus)
														) 
													}} */
												>
												<option value='top'>Top</option>
												<option value='recent'>Recent</option>
												<option value='all'>All</option>
											</select>
										</div>
										<div className="savedFilters">
											<CustomTable	
												columns={[
													{
														Header: 'Search Name',
														accessor: 'search_name'
													},
													{
														Header: 'Created By',
														accessor: 'created_by'
													},
													{
														Header: 'Date',
														accessor: 'timestamp'
													},
													{
														Header: ' ',
														accessor: 'filters',
														Cell: cellInfo => (
															<span>
																{cellInfo.row.original.filters && cellInfo.row.original.filters.length} Criteria
															</span>
														),
													},
												]}
												data={savedArray}
												onClickRow={tableRow => {
													this.setState({
														selectedTableRow: tableRow
													})
												}}
												dashboard={savedArray.length > 3 ? true : false }
												tooltipFor={[0]}
												perPage={3}
												colSpan={4}
												borderSubSection=''
												hideRowsPerPageSection={true}
											/>
										</div>
									</React.Fragment>
								: null 
								}
								<div className="d-flex justify-content-between mt-2 mb-1">
									<p className='font-weight-bold m-0 f15'>Search Builder</p>
								</div>
								<div className="row">
									{this.state.searchType === 'checksSearchBuilder' ?
										<ChecksSearchBuilder 
											selectedDuration={this.props.selectedDuration}
											selectedProvider={this.props.selectedProvider}
											selectedAccount={this.props.selectedAccount}
											categoryCounts={this.props.categoryCounts}
											saveButton={true}
											selectedTableRow={this.state.selectedTableRow}
											onSearch={(filterValue) => 
												this.props.getSearchBuilderList(filterValue)												
											}
											resetFilter={() =>
												this.setState({
													selectedTableRow: {}
												})
											}
											//onSave={(label, selectedFilterItem) => this.saveFilterItme(label, selectedFilterItem)}
										/>
									:
									    null
									}
								</div>
							</React.Fragment>
						:
							!this.state.showSearchView ? (
								<React.Fragment>
									<div className='row'>
										<div className='col-12'>
											<p className='font-weight-bold mb-1'>Sit impedit voluptas ipsum eius sed aliquid.</p>
											<p className='mb-1 small'>
												Ea maxime magni. Et libero et voluptas et at sint iusto. Eos cumque quos.
											</p>
										</div>
									</div>
									{this.state.filterList &&
										this.state.filterList.map((filterList, i) => (
											<div className='row mt-3' key={i}>
												<div className='col-lg-6'>
													<div className='form-group mb-0'>
														<label>Select Filters</label>
														<select
															className='form-control'
															onChange={event => {
																if (event.target.value) {
																	const filtersList = this.state.filterList
																	filterList['name'] = event.target.value
																	filtersList[i] = filterList
																	this.setState({ filterList: filtersList })
																}
																// this.onClickFilterOption(event.target.value)
															}}
														>
															<option value>Select</option>
															{filters.map((filter, key) => {
																return (
																	<option key={key} value={filter.value}>
																		{filter.label}
																	</option>
																)
															})}
															{/* <option value='provider'>Provider</option>
																<option value='account'>Account</option>
																<option value='region'>Region</option> */}
															{/* <option value='service'>Service</option> */}
															{/* <option value>Risk</option> */}
															{/* <option value='status'>Status</option>
															<option value='category'>Category</option>
															<option value='duration'>Duration</option> */}
														</select>
													</div>
												</div>
												<div className='col-lg-6'>
													
													{filterList['name'] === 'account' ?
														<div className="advancedNplMultiSelect">
														{/* removeDropdownSearchBar */}
															<label></label>
															<ReactMultiSelectCheckboxes
																value={this.state.selectedAccount.map(acc => ({
																		value: acc.value,
																		label: acc.label
																}))}
																onChange={arr => {
																this.tablefilterMultiSelectChange('selectedAccount', arr ? arr : []) }}

																options={this.props.accounts.map(acc => ({
																	value: acc.account_id,
																	label: acc.account_name,
																}))}
																placeholderButtonLabel='Select'
															/>
														</div>													
													:  filterList['name'] === 'services' ?
														<div className="advancedNplMultiSelect">
															<label></label>
															<ReactMultiSelectCheckboxes
																value={this.state.selectedServices.map(acc => ({
																		value: acc.value,
																		label: acc.label
																}))}
																onChange={arr => {
																this.tablefilterMultiSelectChange('selectedServices', arr ? arr : []) }}
																	
																options={this.props.services &&
																	this.props.services.length &&
																	_.chain(this.props.services)
																	.groupBy('resource_type')
																	.map((value, key) => ({ label: key, sub: value }))
																	.value()
																	.map(service => ({
																		value: service.label,
																		label: service.label,
																	}))}
																placeholderButtonLabel='Select'
															/>
														</div>
													: filterList['name'] === 'region' ?
														<div className="advancedNplMultiSelect">
															<label></label>
															<ReactMultiSelectCheckboxes
																value={this.state.selectedRegion.map(acc => ({
																		value: acc.value,
																		label: acc.label
																}))}
																onChange={arr => {
																this.tablefilterMultiSelectChange('selectedRegion', arr ? arr : []) }}
																	
																options={this.props.regions && this.props.regions.length &&
																	this.props.regions.map(region => ({
																		value: region.region,
																		label: region.name,
																	}))}
																placeholderButtonLabel='Select'
															/>
														</div>	

													

													:
														<div className='form-group mb-0'>
															<label className='text-capitalize'>Select</label>
															<select
																className='form-control'
																// value={this.state.selectedFilters.provider}
																// disabled={!this.props.providers.length}
																onChange={event => {
																	let arr = this.state.filterList
																	let obj = arr[i]
																	if (obj.name && obj.name === 'category') {
																		if (!obj['values']) {
																			obj['values'] = []
																		}
																		obj['values'].push(event.target.value)
																		obj['value'] = event.target.value
																		arr[i] = obj
																		this.setState({ filterList: arr })
																	} else {
																		obj['values'] = event.target.value
																		obj['value'] = event.target.value
																		arr[i] = obj
																		
																		this.setState({ filterList: arr })
																	}
																}}
																value={''}
															>
																<option className='text-capitalize'>Select</option>
																{filterValues &&
																	filterList['name'] &&
																	filterList['name'] !== 'account' &&
																	filterValues[filterList.name].map((item, i) => {
																		return (
																			<option key={i} value={item.value}>
																				{item.label}
																			</option>
																		)
																	})
																}
															</select>
														</div>
													}
													
													<div className='mt-2'>
														{this.state.filterList.length &&
															filterList.name &&
															filterList.name === 'category' &&
															filterList.values &&
															filterList.values.map((filterBadge, filterBadgeKey) => {
																return (
																	<h6 className='mb-0 mr-2' key={i}>
																		<span className="badge badge-light font-weight-normal f14 mr-1 mb-1" >
																			{filterBadge}
																			<span
																				className='px-1 cursor-pointer'
																				onClick={() => {
																					let arr = this.state.filterList
																					let obj = arr[i]
																					obj.values.splice(filterBadgeKey, 1)
																					obj['value'] = ''
																					arr[i] = obj
																					this.setState({ filterList: arr })
																				}}
																			>
																				×
																			</span>
																		</span>
																	</h6>
																)
															})
														}		

														{filterList.name && filterList.name === 'account' && this.state.selectedAccount.length ?
															this.state.selectedAccount.map((item, index) => {
																return (
																	<span className="badge badge-light font-weight-normal f14 mr-1 mb-1" >
																		{item.label}
																		<span
																			className='px-1 cursor-pointer'
																			onClick={ () => this.removefilter(index, 'account', item.value) }
																		>
																			×
																		</span>
																	</span>
																)
														})
														: null
														}

														{filterList.name && filterList.name === 'services' && this.state.selectedServices.length ?
															this.state.selectedServices.map((item, index) => {
																return (
																	<span className="badge badge-light font-weight-normal f14 mr-1 mb-1" >
																		{item.label}
																		<span
																			className='px-1 cursor-pointer'
																			onClick={ () => this.removefilter(index, 'services', item.value) }
																		>
																			×
																		</span>
																	</span>
																)
														})
														: null
														}
														
														{filterList.name && filterList.name === 'region' && this.state.selectedRegion.length ?
															this.state.selectedRegion.map((item, index) => {
																return (
																	<span className="badge badge-light font-weight-normal f14 mr-1 mb-1" >
																		{item.label}
																		<span
																			className='px-1 cursor-pointer'
																			onClick={ () => this.removefilter(index, 'region', item.value) }
																		>
																			×
																		</span>
																	</span>
																)
														})
														: null
														}
														
														{this.state.filterList.length &&
															filterList.name &&
															filterList.name !== 'category' &&
															filterList.values && (
																<h6 className='mb-0 mr-2' key={i}>
																	<span className="badge badge-light font-weight-normal f14 mr-1 mb-1" >
																		{filterValues[filterList.name] &&
																			filterValues[filterList.name].map(
																				filter =>
																					filter.value === filterList.values && (
																						<React.Fragment>
																							{filter.label}
																							<span
																								className='px-1 cursor-pointer'
																								onClick={() => {
																									let arr = this.state.filterList
																									let obj = arr[i]
																									obj.values = ''
																									arr[i] = obj
																									this.setState({ filterList: arr })
																								}}
																							>
																								×
																							</span>
																						</React.Fragment>
																					)
																			)}
																	</span>
																</h6>
															)}
													</div>

													{/* <div className='form-group mb-0'>
												<label>Select Account</label>
												<select className='form-control'>
													<option value>Select Account</option>
												</select>
											</div>
											<div className='mt-2 d-flex'>
												<h6 className='mb-0 mr-2'>
													<span className='badge font-weight-normal pb-1 badge-secondary'>
														ui-demo
														<span aria-hidden='true'>×</span>
													</span>
												</h6>
												<h6 className='mb-0 mr-2'>
													<span className='badge font-weight-normal pb-1 badge-secondary'>
														blockchain
														<span aria-hidden='true'>×</span>
													</span>
												</h6>
												<h6 className='mb-0 mr-2'>
													<span className='badge font-weight-normal pb-1 badge-secondary'>
														Media
														<span aria-hidden='true'>×</span>
													</span>
												</h6>
											</div> */}
												</div>
											</div>
										))}
									<div className='row'>
										<div className='col-12'>
											<button
												onClick={() =>
													this.setState({
														filterList: [...this.state.filterList, {}],
													})
												}
												className='btn btn-link'
											>
												Add filter
											</button>
											<hr />
											<button
												className='btn btn-dark mr-2'
												onClick={() =>
													this.setState({ showSearchView: true, filterList: [...this.state.filterList, {}] })
												}
											>
												Back
											</button>
											<button
												onClick={() => this.onSubmitEdit()}
												className='btn btn-primary'
												disabled={this.state.submittingFavourite}
											>
												Save & Search
												{this.state.submittingFavourite && <Spinner className='text-center' color='light' size='sm' />}
											</button>
										</div>
									</div>
								</React.Fragment>
							) : (
								<React.Fragment>
									<div className='row'>
										<div className='col-9 pr-0'>
											<div className='input-group mb-2'>
												<div className='input-group-prepend'>
													<span className='input-group-text'>
														<i className='fal fa-search' />
													</span>
												</div>
												<input
													type='text'
													className='border-left-0 form-control text-white'
													placeholder='Search'
													value={this.state.searchText}
													onChange={e => {
														this.setState({ searchText: e.target.value, searchResultState: [] }, 
															() => {
																if(this.props.searchFrom === 'checks') {
																	this.getSearchMetaResults() 
																} else {
																	this.getResourceSearchMetaResults()
																} 
															}
														)
													}}
												/>
											</div>
											{!this.state.isLoading && this.state.searchText.length > 2 && this.props.searchResults.length ? (
												<small>About {this.props.searchResults && this.props.searchResults.length} results</small>
											) : null}
											{this.state.isLoading && (
												<div className='d-flex justify-content-center m-4'>
													<Spinner className='text-center' color='dark' size='lg' />
												</div>
											)}
										</div>
										<div className='col-1 p-0 text-right'>
											<button
												className='w-100 btn btn-link text-secondary'
												onClick={() => this.setState({ searchText: '',  getAllFavorite: false })}
											>
												Clear
											</button>
										</div>
										<div className='col-2 p-0 text-center'>
											<p className='m-0 p-2 textInfo cursorPointer' 
												onClick={() => this.setState({ isLoading: true, }, () => this.getAllFavorite() )} >Show Favourites
											</p>
										</div>
									</div>
									{this.state.searchResultState &&
									this.state.searchResultState.length &&
									!this.state.isLoading &&
									(this.state.searchText !== '' || this.state.getAllFavorite) ?
										this.state.searchResultState.map((item, index) => {									
											return (
												<div
													key={index}
													className='row mt-2'
													onClick={() => this.props.getFilteredList(item.parameters, item)}
												>
													<div className='col-12'>
														<div className='box shadow-none bg-white4 p-2'>
															<p className='font-weight-bold mb-1 cursor-pointer'>{item.title}</p>
															{/* <p className='mb-1 small'>{item.description}</p> */}
															{/* <span className="badge badge-outline-secondary font-weight-normal f12 mr-1 mb-1" >
																{item.favourite ? 'test' : ''}
															</span> */}
															<div className="d-flex justify-content-right">
																{/* <button
																	className='btn btn-link btn-sm p-0 fs-12 mr-3'
																	onClick={e => {
																		e.stopPropagation()
																		this.setState({ showSearchView: false, selectedObjectInResult: item })
																	}}
																>
																	Edit
																</button> */}
																<span className="cursorPointer" onClick={e => { 
																		e.stopPropagation() 
																		this.setState({ 
																			['favorite_'+index]: this.state['favorite_'+index] ? false : true
																		},
																			() => this.setFavourite(index, item)
																		)
																	}}
																>
																	<i class={`fal fa-star ${this.state['favorite_'+index] ? 'fillStar' : '' } `}></i>
																	
																	<small className="ml-1 textInfo">
																		{this.state['favorite_'+index] ?
																			'Remove from favourites'
																		: 
																			'Add to favourites'
																		}
																	</small>
																</span>
															</div>
														</div>
													</div>
												</div>
											)
										})
									: 
										!this.state.isLoading && ((this.state.searchText !== '' && this.state.searchText.length > 2) || this.state.getAllFavorite) ? 
											<div className='col-9'>
												<div className='d-flex justify-content-center m-4'>
													{this.state.searchText !== '' ?
														<p>There are no data on this search. Please try a new search.</p>
													:
														<p>There are no favourite. Please try adding a new favourite.</p>
													}
												</div>
											</div>
										:

										null
									}
								</React.Fragment>
							)
						}
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
AdvancedSearch.propTypes = {
	onCloseModal: PropTypes.func,
	listAllDetectors: PropTypes.func,
	getFilteredList: PropTypes.func,
	getSearchBuilderList: PropTypes.func,
	getSearchMetaResults: PropTypes.func,
	getResourceSearchMetaResults: PropTypes.func,
	getTotalCount: PropTypes.func,
	setFavouriteQuery: PropTypes.func,
	unsetFavouriteQuery: PropTypes.func,
	searchResults: PropTypes.array,
	resetForm: PropTypes.number,
}



/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	console.log('state', state)
	return {
		searchResults: state.filters.searchMetaList,
		providers: state.filters.providers,
		services: state.filters.services,
		regions: state.filters.regions.length ? state.filters.regions[0].provider_regions : [],
		accounts: state.filters.accounts,
	}
}

export default connect(mapStateToProps, {
	getSearchMetaResults,
	getResourceSearchMetaResults,
	getTotalCount,
	setFavouriteQuery,
	unsetFavouriteQuery,
	listAllDetectors,
})(AdvancedSearch)
