
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AlertDetails.js
 * @author Prakash // on 02/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'

import _ from 'lodash'
import { momentConvertionUtcToLocalTime, capitalizeFirstLetter } from '../../../../utils/utility'

class AlertDetails extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            expandAlertDetails: true
        }
    }

    componentDidMount = () => {
        
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.tabOptions && Object.entries(this.props.tabOptions).length) {
            if(!_.isEqual(prevProps.tabOptions, this.props.tabOptions)) {
                this.setState({ 
                    showSpinner: true,
                    expandAlertDetails: true,
                    expandAssetDetails: false
                },
                    () => {
                        setTimeout(() => this.setState({ showSpinner: false }), 2000)
                    }
                )
            }
        }
    }

    render() {
        return (
            <div className="mt-2 errorSectionScroll">
                {this.state.showSpinner ?
                    <div className='d-flex justify-content-center m-4'>
                        <Spinner className='text-center' color='white' size='lg' />
                    </div>
                :
                    this.props.details && Object.entries(this.props.details).length ?
                        <div className="rounded bg-dark3 p-3">
                            <div className="accordion" id="accordionExample">
                                <div className={`card bg-dark border-0 mb-2`}>
                                    <div id={'heading_event'} onClick={() => this.setState({ expandAlertDetails: !this.state.expandAlertDetails })}>
                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
                                            <div className="text-white"><span className={`far ${this.state.expandAlertDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Alert Details</div>
                                            {/* <div className="text-info">Showing event details</div> */}
                                        </div>
                                    </div>
                                    <div id={'collapse_event'} className={`collapse ${this.state.expandAlertDetails ? 'show' : ''}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
                                                {this.props.details.type === 'alert' ?
                                                    <div>
                                                        <p className="mb-0 f16 text-white">{this.props.details.event_name ? this.props.details.event_name : ''}</p>
                                                        <p className="mb-0 f12">{this.props.details.description ? this.props.details.description : ''}</p>
                                                    </div>
                                                : 
                                                    <div>
                                                        <div className="d-flex flex-wrap">
                                                            <p className="mb-0 f16 text-white">{this.props.details.resource_type ? this.props.details.resource_type : ''}</p>
                                                            <p className="mb-0 f16 text-white">{this.props.details.event_type ? '_'+this.props.details.event_type : ''}</p>
                                                            <p className="mb-0 f16 text-white">{this.props.details.asset_name ? '_'+this.props.details.asset_name : ''}</p>
                                                        </div>
                                                        <p className="mb-0 f12">{this.props.details.event_type ? this.props.details.event_type : ''}</p>
                                                    </div>
                                                }
                                                <div>
                                                    <p className="b-block mb-0">Severity:</p>
                                                    <span className={`mr-2 badge ${this.props.details.severity ? 'risk-badge-'+this.props.details.severity.toLowerCase() : 'badge-secondary' }`}>  
                                                        {this.props.details.severity ? capitalizeFirstLetter(this.props.details.severity) : 'Unknown'}
                                                    </span>
                                                </div>
                                            </div>
                                            {this.props.details.type === 'group' ?
                                                <React.Fragment>
                                                <div className="d-flex mb-1 border-bottom">
                                                    <div className="py-1 w-50">
                                                        <div className="d-flex flex-wrap"> 
                                                            <p className="b-block mb-0 mr-2 text-white">Severity</p>
                                                            {/* <span className={`align-self-center mr-1 fa fa-circle risk-${item.severity.toLowerCase()}`}></span> */}
                                                            <p className={`align-self-center mb-0 mr-2 badge ${this.props.details.first_event_severity ? 'risk-badge-'+this.props.details.first_event_severity.toLowerCase() : 'badge-secondary' }`}>  
                                                                {this.props.details.first_event_severity ? capitalizeFirstLetter(this.props.details.first_event_severity) : 'Unknown'}
                                                            </p>
                                                            <p className="b-block mb-0 mr-2 text-white">for the first alert observed on</p>
                                                            <p className="mb-0 text-white">{this.props.details.first_event ? momentConvertionUtcToLocalTime(this.props.details.first_event, 'DD MMM YYYY HH:mm') : ''}</p>
                                                        </div>
                                                    </div>
                                                    <div className="py-1 w-50 pl-3">
                                                        <p className="b-block mb-0">Threshold / Statistic / Period:</p>
                                                        <p className="mb-0 text-warning">
                                                            {this.props.details.first_event_threshold && this.props.details.first_event_threshold}
                                                            {this.props.details.unit ? (this.props.details.unit ? (this.props.details.unit.toLowerCase() === 'percent' ? ' %' : this.props.details.unit) : '') : ''}
                                                            {this.props.details.first_event_statistic ? (' : ')+this.props.details.first_event_statistic : ''}
                                                            {this.props.details.first_event_period ? (' : ')+this.props.details.first_event_period : ''}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-1 border-bottom">
                                                    <div className="py-1 w-50">
                                                        <div className="d-flex flex-wrap"> 
                                                            <p className="b-block mb-0 mr-2 text-white">Severity</p>
                                                            {/* <span className={`align-self-center mr-1 fa fa-circle risk-${item.severity.toLowerCase()}`}></span> */}
                                                            <p className={`align-self-center mb-0 mr-2 badge ${this.props.details.last_event_severity ? 'risk-badge-'+this.props.details.last_event_severity.toLowerCase() : 'badge-secondary' }`}>  
                                                                {this.props.details.last_event_severity ? capitalizeFirstLetter(this.props.details.last_event_severity) : 'Unknown'}
                                                            </p>
                                                            <p className="b-block mb-0 mr-2 text-white">for the last alert observed on</p>
                                                            <p className="mb-0 text-white">{this.props.details.last_event ? momentConvertionUtcToLocalTime(this.props.details.last_event, 'DD MMM YYYY HH:mm') : ''}</p>
                                                        </div>
                                                    </div>
                                                    <div className="py-1 w-50 pl-3">
                                                        <p className="b-block mb-0">Threshold / Statistic / Period:</p>
                                                        <p className="mb-0 text-warning">
                                                            {this.props.details.last_event_threshold && this.props.details.last_event_threshold}
                                                            {this.props.details.unit ? (this.props.details.unit ? (this.props.details.unit.toLowerCase() === 'percent' ? ' %' : this.props.details.unit) : '') : ''}
                                                            {this.props.details.last_event_statistic ? (' : ')+this.props.details.last_event_statistic : ''}
                                                            {this.props.details.last_event_period ? (' : ')+this.props.details.last_event_period : ''}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-1 border-bottom">
                                                    <div className="py-1 w-50">
                                                        <div className="d-flex flex-wrap"> 
                                                            <p className="b-block mb-0 mr-2 text-white">Severity</p>
                                                            {/* <span className={`align-self-center mr-1 fa fa-circle risk-${item.severity.toLowerCase()}`}></span> */}
                                                            <p className={`align-self-center mb-0 mr-2 badge ${this.props.details.highest_event_severity ? 'risk-badge-'+this.props.details.highest_event_severity.toLowerCase() : 'badge-secondary' }`}>  
                                                                {this.props.details.highest_event_severity ? capitalizeFirstLetter(this.props.details.highest_event_severity) : 'Unknown'}
                                                            </p>
                                                            <p className="b-block mb-0 mr-2 text-white">for the highest alert observed on</p>
                                                            <p className="mb-0 text-white">{this.props.details.highest_event ? momentConvertionUtcToLocalTime(this.props.details.highest_event, 'DD MMM YYYY HH:mm') : ''}</p>
                                                        </div>
                                                    </div>
                                                    <div className="py-1 w-50 pl-3">
                                                        <p className="b-block mb-0">Threshold / Statistic / Period:</p>
                                                        <p className="mb-0 text-warning">
                                                            {this.props.details.highest_event_threshold && this.props.details.highest_event_threshold}
                                                            {this.props.details.unit ? (this.props.details.unit ? (this.props.details.unit.toLowerCase() === 'percent' ? ' %' : this.props.details.unit) : '') : ''}
                                                            {this.props.details.highest_event_statistic ? (' : ')+this.props.details.highest_event_statistic : ''}
                                                            {this.props.details.highest_event_period ? (' : ')+this.props.details.highest_event_period : ''}
                                                        </p>
                                                    </div>
                                                </div>
                                                </React.Fragment>

                                            : null}
                                            {this.props.details.type === 'alert' ?
                                                <div className="d-flex mb-1 border-bottom">
                                                    <div className="py-1 w-50">
                                                        <p className="b-block mb-0">Alert Time:</p>
                                                        <p className="mb-0 text-white">
                                                            {this.props.details.type === 'alert' ?
                                                                this.props.details.event_time ? momentConvertionUtcToLocalTime(this.props.details.event_time, 'DD MMM YYYY HH:mm') : ''
                                                            :
                                                                this.props.details.created_at ? momentConvertionUtcToLocalTime(this.props.details.created_at, 'DD MMM YYYY HH:mm') : ''
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="py-1 w-50 pl-3">
                                                        <p className="b-block mb-0">Alert Metrics:</p>
                                                        {this.props.details.type === 'alert' ?
                                                            <p className="mb-0 text-white">
                                                                {this.props.details.event_source ? this.props.details.event_source : ''}
                                                                {this.props.details.event_type ? (' : ')+this.props.details.event_type : ''}
                                                            </p>
                                                        :        
                                                            // <p className="mb-0 text-white">
                                                            //     {this.props.details.event_type ? this.props.details.event_type : ''}
                                                            // </p>
                                                        null}
                                                    </div>
                                                </div>
                                            : null}
                                            {this.props.details.type === 'alert' ?
                                                <div className="d-flex mb-1 border-bottom">
                                                    <div className="py-1 w-50">
                                                        <p className="b-block mb-0">Threshold / Statistic / Period:</p>
                                                        <p className="mb-0 text-warning">
                                                            {this.props.details.threshold && this.props.details.threshold}{this.props.details.unit ? (this.props.details.unit ? (this.props.details.unit.toLowerCase() === 'percent' ? ' %' : this.props.details.unit) : '') : ''}
                                                            {this.props.details.statistic ? (' : ')+this.props.details.statistic : ''}
                                                            {this.props.details.period ? (' : ')+this.props.details.period : ''}
                                                        </p>
                                                    </div>
                                                    <div className="py-1 w-50 pl-3">
                                                        <p className="b-block mb-0">Provider / Account / Region :</p>
                                                        <p className="mb-0 text-white">
                                                            {this.props.details.provider ? this.props.details.provider.toUpperCase() : ''}
                                                            {this.props.details.account_id ? (' : ')+this.props.details.account_id : ''}
                                                            {this.props.details.region ? (' : ')+this.props.details.region : ''}
                                                        </p>
                                                    </div>
                                                </div>
                                            : null}
                                            {this.props.details.type !== 'alert' ?
                                                <div className="d-flex mb-1 border-top">
                                                    <div className="py-1 w-50">
                                                        <p className="b-block mb-0">Provider / Account / Region :</p>
                                                        <p className="mb-0 text-white">
                                                            {this.props.details.provider ? this.props.details.provider.toUpperCase() : ''}
                                                            {this.props.details.account_id ? (' : ')+this.props.details.account_id : ''}
                                                            {this.props.details.region ? (' : ')+this.props.details.region : ''}
                                                        </p>
                                                    </div>
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                </div>

                                {this.state.assetDetails && this.state.assetDetails.length ?
                                    <div className={`card bg-dark border-0 mb-2`}>
                                        <div id={'heading_config_assets'} onClick={() => this.setState({ expandAssetDetails: !this.state.expandAssetDetails })}>
                                            <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                                <div className="text-white"><span className={`far ${this.state.expandAssetDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Asset Details</div>
                                                {/* <div className="text-info">Showing asset details</div> */}
                                            </div>
                                        </div>
                                        <div id={'collapse_config_assets'} className={`collapse ${this.state.expandAssetDetails ? 'show' : ''}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                            {this.state.assetDetails.map(item => {
                                                return(
                                                <div className="card-body m-2 pt-2 rounded counts_threads">
                                                    <div className="d-flex mb-2 border-bottom">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Name:</p>
                                                            <p className="mb-0 text-white">{item.asset_name ? item.asset_name : ''}</p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Id:</p>
                                                            <p className="mb-0 text-white">{item.asset_id ? item.asset_id : ""}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-2">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Resource:</p>
                                                            <p className="mb-0 text-white">
                                                                {this.props.details.provider ? this.props.details.provider.toUpperCase() : ""}
                                                                {this.props.details.account_id ? (" : ")+this.props.details.account_id : ""}
                                                                {this.props.details.region ? (" : ")+this.props.details.region : ""}
                                                            </p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Services:</p>
                                                            <p className="mb-0 text-white">
                                                                {item.resource_type ? item.resource_type : ''}
                                                                {item.service_name ? (" : ")+item.service_name : ''}
                                                            </p>
                                                        </div>
                                                    </div>                                                        
                                                    <div className="d-flex mb-2 border-bottom">
                                                        <div className="py-1 w-100">
                                                            <p className="b-block mb-0">ARN:</p>
                                                            <p className="mb-0 text-white">{item.asset_arn ?item.asset_arn : ''}</p>
                                                        </div>
                                                    </div>
                                                    {item.category ? 
                                                        <div className="d-flex mb-2 border-bottom">
                                                            <div className="py-1 w-100">
                                                                <p className="b-block mb-0">Category:</p>
                                                                <div className={`d-flex flex-wrap`}>
                                                                    {item.category.map(item => {
                                                                        return (
                                                                            <small className={`mr-1 mb-1 badge badge-light`}>{item}</small> 
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    : null}
                                                </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                : null}
                            </div>
                        </div>
                    :
                        <div className='d-flex justify-content-center m-4'>
                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                        </div>
                }
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
AlertDetails.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log('errorDashboard',state)
    return {
        tabOptions: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.tabOptions ? state.aiops.aiopsPropsDetails.tabOptions : {},
    }
}

export default connect(mapStateToProps, {})(withRouter(AlertDetails))        