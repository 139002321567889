/*************************************************
 * Tvastar
 * @exports
 * @file Hashing.js
 * @author Prakash // on 13/09/2021
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
 import React, { Component } from 'react'
 import { connect } from 'react-redux'
 import { withRouter } from 'react-router-dom'
 import Select from 'react-select'

 class Hashing extends Component {
	hierarichalBucketBarChartRef = React.createRef()
	constructor(props) {
		super(props)
		this.props = props;
		this.state = {
			showLoading: false,
			yesNoOptions: ['Yes', 'No'],
			watermark: 'No',
            length_by_2: 10,
		}
	}

	componentDidMount = () => {
        this.editEvent()   
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.dlpPropsDetails && typeof this.props.dlpPropsDetails.hasPrivacyPolicyError !== 'undefined' && (prevProps.dlpPropsDetails.hasPrivacyPolicyError !== this.props.dlpPropsDetails.hasPrivacyPolicyError || (!this.state.hasError && (this.state.hasError !== this.props.dlpPropsDetails.hasPrivacyPolicyError)))) {
            this.setState({ hasError: this.props.dlpPropsDetails.hasPrivacyPolicyError })
        }
    }

    editEvent = () => {
        if(this.props.pageType === 'edit') {
			let policyDetails = this.props.policyDetails
            let deidentification_type = policyDetails.config.deidentification_type === 'watermark_number' ? 'hash' : policyDetails.config.deidentification_type 
            
            let watermark_id = policyDetails.config.watermark_id ? policyDetails.config.watermark_id : ''
            let watermark_name = policyDetails.config.watermark_name ? policyDetails.config.watermark_name : ''
            let watermark_type = policyDetails.config.watermark_type ? policyDetails.config.watermark_type : ''
            let length_by_2 = policyDetails.config.length_by_2 ? policyDetails.config.length_by_2 * 2 : 10

            this.setState({ deidentification_type, updated_deidentification_type: deidentification_type, watermark: watermark_id !== '' ? 'Yes' : 'No', watermark_id, watermark_name, watermark_type, length_by_2 }, () => this.onChangePropsEvent())
        }
    }

    onChangePropsEvent = () => {
		let errorMessage = ''
		if(!this.state.deidentification_type || this.state.deidentification_type === '') {
			errorMessage = 'Please select hashing type'
		} else if(this.state.watermark === 'Yes') {
            if(!this.state.watermark_id) {
                errorMessage = 'Please select watermark'
            }
            if(!this.state.watermark_type) {
                errorMessage = 'Please select watermark type'
            }
        } else if(this.state.watermark === 'No') {
            if(!this.state.length_by_2 || this.state.length_by_2 === '') {
                errorMessage = 'Please enter length by'
            }
        }

        let data = {}
        data.deidentification_type = this.state.deidentification_type

        let selectedText = 'Hashing type '+this.state.deidentification_label

        data.watermark_type = this.state.watermark_type
        if(this.props.watermarkTypeArray[this.state.deidentification_type] && this.props.watermarkTypeArray[this.state.deidentification_type].length) {
            
            if(this.state.watermark === 'Yes') {
                if(this.state.watermark_type === 'number') {
                    data.deidentification_type = 'watermark_number'
                }
                let filter = this.props.watermarkTypeArray[this.state.deidentification_type].filter(arr => arr.watermark_type === this.state.watermark_type)
                if(filter.length) {
                    Object.entries(filter[0]).forEach(([key, value]) => {
                        data[key] = value
                    })
                }        

                data.watermark_id = this.state.watermark_id
                data.watermark_name = this.state.watermark_name
                selectedText += ' watermark type '+this.state.watermark_type
                selectedText += ' with watermark '+ data.watermark_name

                if(!data.watermark_type || data.watermark_type === '') {
                    errorMessage = 'Please select watermark type'
                } else if(!data.watermark_name || data.watermark_name === '') {
                    errorMessage = 'Please select watermark'
                }
            }
        } 
        
        if(this.state.watermark === 'No') {
            if(this.state.length_by_2 && this.state.length_by_2 !== '') {
                if(this.state.length_by_2 < 10 || (this.state.length_by_2 % 2) !== 0) {
                    errorMessage = 'Please enter length greater than 9 and in multiples of 2'
                }
            } else {
                errorMessage = 'Please enter length'
            }

            data.length_by_2 = this.state.length_by_2 ? this.state.length_by_2 / 2 : this.state.length_by_2
            selectedText += ' length multiples of 2 '+data.length_by_2
        }

        this.props.selectedData(data, selectedText, errorMessage)
    }

    getSelectLabel = (item, list) => {
        let filter = list.filter(arr => arr.value === item)
        if(filter.length) {
            this.setState({ deidentification_label: filter[0].label })
        } else {
            this.setState({ deidentification_label: '', deidentification_type: '' })
        }
    }

    onChangeType = () => {
        if(this.state.updated_deidentification_type && this.state.deidentification_type === this.state.updated_deidentification_type) {
            this.editEvent()
        } else {
            this.onChangePropsEvent()
        }
    }

    render() {		
		return (
            <div className={`form-group dlpDescriptionForm mt-3`}>
                <div className="d-flex flex-wrap">
                    <p className="mb-0 mr-2 align-self-end text-purple mt-3">Type</p>
                    <Select
                        // menuIsOpen={true}
                        placeholder={'Select'}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        isSearchable={false}
                        className={`selectOption align-self-end mx-2  ${this.state.hasError && (!this.state.deidentification_type || this.state.deidentification_type === '') ? 'dottedDangerSelectLine' : ''}`}
                        value={({
                            value: this.state.deidentification_type ? this.state.deidentification_type : 'Select',
                            label: this.state.deidentification_type ? (this.state.deidentification_label ? this.state.deidentification_label : this.getSelectLabel(this.state.deidentification_type, this.props.list)) : 'Select'
                        })}
                        options={this.props.list && this.props.list.map(item => ({
                            value: item.value,
                            label: item.label,
                        }))}
                        onChange={event => 
                            this.setState({ deidentification_type: event.value, deidentification_label: event.label, watermark_name: '', watermark_type: '', watermark_id: '' }, 
                                () => this.onChangeType()
                            )
                        }
                    />
                    {this.state.deidentification_type && this.state.deidentification_type !== '' ?
                        <React.Fragment>
                            <p className="mb-0 mr-2 align-self-end text-purple mt-3"> add watermark</p>
                            <Select
                                // menuIsOpen={true}
                                placeholder={'Select'}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                isSearchable={false}
                                className={`selectOption mr-2 mt-3`}
                                value={({
                                    value: this.state.watermark ? this.state.watermark : 'Select',
                                    label: this.state.watermark ? this.state.watermark : 'Select',
                                })}
                                options={this.state.yesNoOptions && this.state.yesNoOptions.map(item => ({
                                    value: item,
                                    label: item,
                                }))}
                                onChange={event =>  
                                    this.setState({ watermark: event.value }, () => this.onChangePropsEvent())
                                }
                            />
                            {this.state.watermark === 'No' ?
                                <React.Fragment>
                                    <p className="mb-0 mx-2 align-self-end text-purple mt-3">length</p>
                                    <input 
                                        type="number" 
                                        className={`inputField mt-3 ${this.state.hasError && (!this.state.length_by_2 || this.state.length_by_2 === '') ? 'dottedDangerTextboxLine' : ''}`}
                                        value={this.state.length_by_2 ? this.state.length_by_2 : ''}
                                        onChange={e => this.setState({ length_by_2: e.target.value }, () => this.onChangePropsEvent())}
                                    />
                                </React.Fragment>
                            : null}
                        </React.Fragment>
                    : null}
                </div>

                
                {this.props.watermarkTypeArray && this.props.watermarkTypeArray[this.state.deidentification_type] && this.props.watermarkTypeArray[this.state.deidentification_type].length ?
                    <div className="d-flex flex-wrap">
                        <React.Fragment>                            
                            {this.state.watermark === 'Yes' ?
                                <React.Fragment>
                                <p className="mb-0 mr-2 align-self-end text-purple mt-3">Watermark Type</p>
                                <Select
                                    // menuIsOpen={true}
                                    placeholder={'Select'}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    isSearchable={false}
                                    className={`selectOption mr-2 mt-3 ${this.state.hasError && (!this.state.watermark_type || this.state.watermark_type === '') ? 'dottedDangerSelectLine' : ''}`}
                                    value={({
                                        value: this.state.watermark_type ? this.state.watermark_type : 'Select',
                                        label: this.state.watermark_type ? this.state.watermark_type : 'Select',
                                    })}
                                    options={this.props.watermarkTypeArray[this.state.deidentification_type] && this.props.watermarkTypeArray[this.state.deidentification_type].map(item => ({
                                        value: item.watermark_type,
                                        label: item.watermark_type,
                                    }))}
                                    onChange={event =>  
                                        this.setState({ watermark_type: event.value }, () => this.onChangePropsEvent())
                                    }
                                />
                                <p className="mb-0 mr-2 align-self-end text-purple mt-3">with watermark</p>
                                <Select
                                    // menuIsOpen={true}
                                    placeholder={'Select'}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    isSearchable={false}
                                    className={`selectOption mr-2 mt-3 w-40 ${this.state.hasError && (!this.state.watermark_name || this.state.watermark_name === '') ? 'dottedDangerSelectLine' : ''}`}
                                    value={({
                                        value: this.state.watermark_id ? this.state.watermark_id : 'Select',
                                        label: this.state.watermark_name ? this.state.watermark_name : 'Select',
                                    })}
                                    options={this.props.watermarks && this.props.watermarks.map(item => ({
                                        value: item.watermark_id,
                                        label: item.watermark_name,
                                    }))}
                                    onChange={event =>  
                                        this.setState({ watermark_id: event.value, watermark_name: event.label }, () => this.onChangePropsEvent())
                                    }
                                />
                                </React.Fragment>
                            : null}
                        </React.Fragment>
                    </div>
                : null}
            </div>
        )
    }
}

const mapStateToProps = state => {
	return {
        dlpPropsDetails: state.dlp.dlpPropsDetails,
        watermarks: state.dlp.dlpConfigWatermarklist
	}
}

export default connect(mapStateToProps, {})(withRouter(Hashing))