
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file SlowQueryAnalysis.js
 * @author Prakash // on 14/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Select from 'react-select';
import { Input } from 'reactstrap'
import { setAutomationPropsDetails } from '../../actions/commonAction'
import { onlyNumeric } from '../../utils/utility'

class SlowQueryAnalysis extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            activeSection: this.props.activeSection,
            durationOptions: ["Minutes","Hour", "Day", "Month"],
            selectedPeriod: "Minutes",
            previousAutomations: []
        }
    }

    componentDidMount = () => {
        if(this.props.automationPropsDetails) {
            let obj = this.props.automationPropsDetails
            let exist = obj.actions.filter(e => e.section === this.state.activeSection)
            if(exist.length) {
                let input = exist[0].input ? exist[0].input : []
                input.forEach(item => {
                    if(item.key === "duration") {
                        let duration = ""
                        let selectedPeriod = this.state.selectedPeriod
        
                        let str = item.value ? item.value : "";
                        if(str !== "") {
                            let period = str.substring(str.length, str.length-1)
                            if(period === "h") {
                                selectedPeriod = "Hour"
                            } else if(period === "d") {
                                selectedPeriod = "Day"
                            } else if(period === "M") {
                                selectedPeriod = "Month"
                            } else if(period === "m") {
                                selectedPeriod = "Minutes"
                            }
                            duration = str.substring(0, str.length - 1)
                        }

                        this.setState({ duration, selectedPeriod },
                            () => this.checkAllDataFilled()    
                        )
                    } else {
                        this.setState({ [item.key]: item.value,  },
                            () => {
                                if(item.dynamic) {
                                    this.setState({ ["dynamic_"+item.key]: true })
                                }
                                this.checkAllDataFilled()
                            }
                        )
                    }
                })                
            }
            let actions = this.props.automationPropsDetails.actions ? this.props.automationPropsDetails.actions : []
            let previousAutomations = actions.filter(e => e.section < this.state.activeSection)

            this.setState({ previousAutomations, previousAutomationsSearchResult: previousAutomations })
        }
    }

    componentDidUpdate = (prevProps) => {}

    onActionSelect = (action) => {
        this.setState({ 
            selectedAction: action, 
            showActionResults: false 
        },
            // () => this.formInputProps(["action_"+this.state.activeSection], "action", this.state.selectedAction)
        )
    }

    formInputProps = (section, field, value) => {
        let obj = this.props.automationPropsDetails
        let actions = obj.actions
        let objIndex = actions.findIndex((e => e.section === section))
        if(field === "duration") {
            let period = ""
            if(this.state.selectedPeriod === "Hour") {
                period = "h"
            } else if(this.state.selectedPeriod === "Day") {
                period = "d"
            } else if(this.state.selectedPeriod === "Month") {
                period = "M"
            } else if(this.state.selectedPeriod === "Minutes") {
                period = "m"
            }
            value = this.state.duration+""+period
        }
        let dataRow = {}
        dataRow.key = field
        dataRow.value = value
        if(this.state["dynamic_"+field]) {
            dataRow.dynamic = true
        }

        let input = actions[objIndex].input ? actions[objIndex].input : []
        let inputIndex = input.findIndex((e => e.key === field))
        if(inputIndex > -1) {
            input[inputIndex] = dataRow
        } else {
            input.push(dataRow)
        }
        actions[objIndex].input = input
        obj.actions = actions
        this.props.setAutomationPropsDetails(obj)

        this.checkAllDataFilled()
    }

    checkAllDataFilled = () => {
        if(this.state.resource_type && this.state.resource_type !== "" && this.state.duration && this.state.duration !== "" && this.state.asset_name && this.state.asset_name !== "" ) {
            this.props.actionCompleted(true)
        } else {
            this.props.actionCompleted(false)
        }
    }

    render() {
        return (
            <div className="">
                <div className="d-flex mt-3">
                    <div className="w-50">
                        <label className="mb-1">Resource Type</label>
                        <div className="col-sm-12 p-0"> {/*tabIndex="0" onBlur={()}*/}
                            <Input
                                type="text"
                                placeholder="resource type"
                                className="inputTextbox"
                                value={this.state.resource_type}
                                id={"resource_type"}
                                onChange={event =>
                                    this.setState({ resource_type: event.target.value },
                                        () => {
                                            this.formInputProps(this.state.activeSection, "resource_type", this.state.resource_type)
                                        }
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className="pl-3 w-50">
                        <label className="mb-1">Name of the asset</label>
                        <div className="col-sm-12 p-0">
                            <Input
                                type="text"
                                placeholder="asset name"
                                className="inputTextbox"
                                value={this.state.asset_name}
                                id={"asset_name"}
                                onChange={event =>
                                    this.setState({ asset_name: event.target.value },
                                        () => {
                                            this.formInputProps(this.state.activeSection, "asset_name", this.state.asset_name)
                                        }
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    <div className="f-flex">
                        <div className="w-50">
                            <label className="mb-1">Duration to check before the trigger</label>
                            <div className="d-flex">
                                <Input
                                    type="test"
                                    placeholder="duration"
                                    className="inputTextbox mr-1 w-20"
                                    value={this.state.duration}
                                    onChange={event => 
                                        this.setState({ duration: onlyNumeric(event.target.value) },
                                            () => this.formInputProps(this.state.activeSection, "duration", this.state.duration)
                                        )
                                    }
                                />
                                <div className="w-25">
                                    <Select 
                                        options={this.state.durationOptions && this.state.durationOptions.map(item => ({
                                            value: item,
                                            label: item,
                                        }))}
                                        value={({
                                            value: this.state.selectedPeriod && this.state.selectedPeriod,
                                            label: this.state.selectedPeriod ? this.state.selectedPeriod : <span className="placeholder">Select period</span>
                                        })}
                                        // menuIsOpen={true}
                                        onChange={event => 
                                            this.setState({ selectedPeriod: event.value },
                                                () => {
                                                    this.formInputProps(this.state.activeSection, "duration", this.state.selectedPeriod)
                                                }
                                            )
                                        }
                                    />
                                </div>
                            </div>                    
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
SlowQueryAnalysis.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log("SlowQueryAnalysis",state)
    return {
        automationPropsDetails: state.filters.automationPropsDetails
    }
}

export default connect(mapStateToProps, {
    setAutomationPropsDetails,
})(withRouter(SlowQueryAnalysis))        