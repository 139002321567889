/*************************************************
 * Tvastar
 * @exports
 * @file Table.js
 * @author Rajasekar // on 30/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTable, useRowSelect, useBlockLayout, useResizeColumns, useSortBy, usePagination } from 'react-table'
// import {
// 	useTable,
// 	useBlockLayout,
// 	useResizeColumns,
// 	usePagination,
// 	useSortBy,
// 	useFilters,
// 	useGlobalFilter,
// 	useRowSelect,
// } from 'react-table'
import { UncontrolledTooltip } from 'reactstrap'

// export function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
// 	// const count = preGlobalFilteredRows.length
// 	return (
// 		<div className='input-group dark-feild mb-2 mr-sm-2'>
// 			<div className='input-group-prepend'>
// 				<div className='input-group-text bg-transparent'>
// 					<i className='fal fa-search' />
// 				</div>
// 			</div>
// 			<input
// 				value={globalFilter || ''}
// 				onChange={e => {
// 					setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
// 				}}
// 				type='text'
// 				className='form-control'
// 				placeholder='Search'
// 			/>
// 		</div>
// 		// <span>
// 		// 	Search:{' '}
// 		// 	<input
// 		// 		value={globalFilter || ''}
// 		// 		onChange={e => {
// 		// 			setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
// 		// 		}}
// 		// 		placeholder={`${count} records...`}
// 		// 		style={{
// 		// 			fontSize: '1.1rem',
// 		// 			border: '0',
// 		// 		}}
// 		// 	/>
// 		// </span>
// 	)
// }

const toolTipModifiers = {
	flip: { behavior: ['top'] },
	preventOverflow: { boundariesElement: 'viewport' },
}

const IndeterminateCheckbox = React.forwardRef(
	({ indeterminate, ...rest }, ref) => {
	  const defaultRef = React.useRef()
	  const resolvedRef = ref || defaultRef
  
	  React.useEffect(() => {
		resolvedRef.current.indeterminate = indeterminate
	  }, [resolvedRef, indeterminate])
  
	  return (
		<React.Fragment>
		  <input type="checkbox" ref={resolvedRef} {...rest} />
		</React.Fragment>
	  )
	}
)

function CheckboxTable({ columns, data, onClickRow, dashboard, tooltipFor, riskTooltip = false, perPage = 10, manualWidth = false, sortByColumn }) {
	const [selectedRowIndex, setSelectedRowIndex] = useState(0)
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		// preGlobalFilteredRows,
		// setGlobalFilter,
		// state,
		// rows,
		// selectedFlatRows,
		// state: { pageIndex, pageSize, selectedRowIds },
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState: { 
				pageIndex: 0, pageSize: perPage ? perPage : 10,
				sortBy: [
					{
						id: sortByColumn,
						desc: true
					}
				]
			},
		},
		useBlockLayout,
		useResizeColumns,
		useSortBy,
		usePagination,
		useRowSelect,
		hooks => {
			hooks.visibleColumns.push(columns => [
			  // Let's make a column for selection
			  {
				id: 'selection',
				// The header can use the table's getToggleAllRowsSelectedProps method
				// to render a checkbox
				Header: ({ getToggleAllRowsSelectedProps }) => (
				  <div>
					<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
				  </div>
				),
				// The cell can use the individual row's getToggleRowSelectedProps method
				// to the render a checkbox
				Cell: ({ row }) => (
				  <div>
					<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
				  </div>
				),
			  },
			  ...columns,
			])
		}
	)

	return (
		<React.Fragment>
			<div className='box p-0'>
				{/* <GlobalFilter
				preGlobalFilteredRows={preGlobalFilteredRows}
				globalFilter={state.globalFilter}
				setGlobalFilter={setGlobalFilter}
			/> */}
				<div className='table-responsive'>
					<table {...getTableProps()} className='table table-sm table-hover'>
						<thead>
							{headerGroups.map((headerGroup, i) => (
								<tr key={i} className='bg-primary w-100' {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column, index) => (
										<th className={`bg-primary fs-13 ${!manualWidth ? 'w-100' : ''} `} key={index} {...column.getHeaderProps()}>
											<span
												{...column.getSortByToggleProps({ title: 'Sort' })}
												style={{ marginRight: 10, cursor: 'pointer' }}
											>
												{column.isSorted ? (
													column.isSortedDesc ? (
														<React.Fragment>
															<i className='fas fa-sort-down' /> {column.render('Header')}
														</React.Fragment>
													) : (
														<React.Fragment>
															<i className='fas fa-sort-up' /> {column.render('Header')}
														</React.Fragment>
													)
												) : (
													column.render('Header')
													// <React.Fragment>
													// 	<i className='fas fa-sort' /> {column.render('Header')}
													// </React.Fragment>
												)}
											</span>
											{headerGroup.headers.length - 1 === index ? null : (
												<div {...column.getResizerProps()} className={`resizer`} />
											)}
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{page.map((row, i) => {
								prepareRow(row)
								return (
									<tr
										className={`${selectedRowIndex === i ? 'bg-light' : '' } ${!manualWidth ? 'w-100' : 'w-100'} `}
										key={i}
										{...row.getRowProps()}
										onClick={() => {
											onClickRow(row.original)
											setSelectedRowIndex(i)
										}}
									>
									
										{row.cells.map((cell, index) => {
											return (
												<React.Fragment key={index}>
													{tooltipFor && tooltipFor.includes(index) && (
														<UncontrolledTooltip
															key={index}
															placement='top'
															modifiers={toolTipModifiers}
															target={`targetIdNotify${i}${index}`}
														>
															{cell.render('Cell')}
														</UncontrolledTooltip>
													)}
													{riskTooltip && riskTooltip.includes(index) && (
														<UncontrolledTooltip
															key={index}
															placement='top'
															modifiers={toolTipModifiers}
															target={`targetIdNotify${i}${index}`}
														>
															{cell.row.values.risk}
														</UncontrolledTooltip>
													)}
													<td
														key={index}
														className={`fs-12 text-truncate ${!manualWidth ? 'w-100' : ''} `}
														{...cell.getCellProps()}
														id={`targetIdNotify${i}${index}`}
													>
														{/* {moment(cell.render('Cell').props.cell.value).isValid()
														? cell.render('Cell')
														: cell.render('Cell').props.cell.value && cell.render('Cell').props.cell.value.length > 15
														? cell.render('Cell').props.cell.value.substring(0, 15) + '...' */}
														{cell.render('Cell')}
													</td>
												</React.Fragment>
											)
										})}
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			</div>
			{dashboard ? (
				<div className='pagination mt-3'>
					Rows per page:{' '}
					<select
						value={pageSize}
						onChange={e => {
							setPageSize(Number(e.target.value))
						}}
					>
					{[perPage * 1, perPage * 2, perPage * 3, perPage * 4, perPage * 5].map(pageSize => (
							<option key={pageSize} value={pageSize}>
								{pageSize}
							</option>
						))}
					</select>
					<span className='mx-3'>
						Page{' '}
						<strong>
							{pageIndex + 1} of {pageOptions.length}
						</strong>{' '}
					</span>
					<button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
						<i className='fal fa-arrow-to-left' />
					</button>{' '}
					<button onClick={() => previousPage()} disabled={!canPreviousPage}>
						<i className='fal fa-angle-left' />
					</button>{' '}
					<button
						onClick={() => {
							nextPage()
						}}
						disabled={!canNextPage}
					>
						<i className='fal fa-angle-right' />
					</button>{' '}
					<button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
						<i className='fal fa-arrow-to-right' />
					</button>
					{/* <span>
					| Go to page:{' '}
					<input
						type='number'
						defaultValue={pageIndex + 1}
						onChange={e => {
							const page = e.target.value ? Number(e.target.value) - 1 : 0
							gotoPage(page)
						}}
						style={{ width: '100px' }}
					/>
				</span> */}
				</div>
			) : null}
		</React.Fragment>
	)
}
/**
 * Type of the props used in the component
 */
CheckboxTable.propTypes = {
	columns: PropTypes.array,
	onClickRow: PropTypes.func,
	onClickColumn: PropTypes.func,
	onClickColumnFor: PropTypes.func,
	data: PropTypes.array,
	dashboard: PropTypes.bool,
	tooltipFor: PropTypes.array,
	riskTooltip: PropTypes.array,
}

export default CheckboxTable
