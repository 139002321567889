/*************************************************
 * Tvastar
 * @exports
 * @file DynamicServices.js
 * @author Prakash // on 21/09/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import _ from 'lodash'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

import { setNewEditRuleDetails } from '../../../../../actions/governance/newGovernanceAction'
import { DynamicApiToDevCall, getAllTagsKeys, getAllTagsValues } from '../../../../../actions/commonAction'
import { currentUTCtime, capitalizeFirstLetter, onlyNumeric } from '../../../../../utils/utility'
import ConsolidatedComparison from '../../../../resources/comparison/ConsolidatedComparison'
import ObjectListInputs from './ObjectListInputs'

class DynamicServices extends Component {
	constructor(props) {
		super(props)
		this.optionTypeRef = React.createRef()
		this.scrollRef = React.createRef()
		this.state = {
			policyArray: [0],
			hasErrorInRuleForm: false,
			hasError: this.props.hasError,

			day_options: [
				{label: "Sunday", value: "Sun"},
				{label: "Monday", value: "Mon"},
				{label: "Tuesday", value: "Tue"},
				{label: "Wednesday", value: "Wed"},
				{label: "Thursday", value: "Thu"},
				{label: "Fridayday", value: "Fri"},
				{label: "Saturday", value: "Sat"},
			],
			timeTypeOptions: [
				{value: "h", label: "Hour(s)"},
				{value: "m", label: "Minute(s)"}
			],
			["time_type_0"]: "h"
		}
	}

	componentDidMount = () => {
		this.masterData()
	}

	componentWillReceiveProps = (props) => {
		if(props.onClickFinish) {
			this.validateInput()
		}
	}

	masterData = () => {
		let masterData = this.props.masterData
		let actionDetails = []
		if(masterData.actions && masterData.actions.length) {
			actionDetails = masterData.actions
		}
		let variables = masterData.variables
		variables.forEach(item => {
			if(item.component === "select" || item.component === "multiselect") {
				if(item.options) {
					this.setState({ [item.variable+"_options"]: item.options, [item.variable+"_0"]: item.default ? item.default : "" })
				} else {
					this.setState({ apiCallVariable: item.variable, [item.variable+"_0"]: item.default ? item.default : "" })
				}
			} else if(item.component === "tagging_component") {
				this.getAllTagsKeys()
			}

			this.formLabelBuilder(item)

			this.setState({ ["mode_"+item.variable]: item.mode ? item.mode : "" })
		})
		

		let groupedVariables = _.chain(variables).groupBy("step") .map((value, key) => ({ label: key, data: value })).value();
		
		this.setState({ tags: masterData.tags, groupedVariables, variables, template: false, description: masterData.description ? masterData.description : "", actionDetails },
			() => {
				if(masterData.api_to_call) {
					this.apiToCall(masterData.api_to_call)
				} else {					
					if(this.props.viewMode) {
						this.setState({ savedData: [this.props.masterData] },
							() => this.savedData()	
						)
					} else {
						if(this.props.editNewRuleDetails && this.props.editNewRuleDetails.policies) {
							if(this.props.editNewRuleDetails.policies.filter(e => e.rule_category === this.props.masterData.rule_category).length) {
								let savedData = this.props.editNewRuleDetails.policies.filter(f => f.rule_id === this.props.masterData.rule_id)
								if(savedData.length) {
									this.setState({ savedData },
										() => {
											this.savedData()
										}
									)
								}
							}
						}
					}
				}
			}
		)
		
	}

	formLabelBuilder = (item) => {
		let querybuilder = []
		let query = item.label
		let splitString = query && query !== "" ?query.split(" ") : []
		splitString.forEach(str => {
			let result = str.split("$");
			if(result.length === 1) {
				let dataRow = {}
				dataRow.input = false
				dataRow.string = result[0]				
				querybuilder.push(dataRow)
			} else {
				result.forEach((resRow, index) => {
					if(resRow !== "") {
						if(index === 1) {
							let dataRow = {}
							dataRow.input = true
							dataRow.field = item.variable
							dataRow.component = item.component
							dataRow.string = result[0]
							querybuilder.push(dataRow)
						} else {
							let dataRow = {}
							dataRow.input = false
							dataRow.string = resRow
							querybuilder.push(dataRow)
						}
					}
				})
			}
		})

		this.setState({ [item.variable+"_label"]: querybuilder })
	}

	apiToCall = (apiDetails) => {
		apiDetails.forEach(item => {
			let params = {}
	
			let valueRequest =  item.body && item.body.values
			valueRequest && valueRequest.forEach(req => {
				if(req.value) {
					params[req.key] = req.value
				} else if(req.value_object) {
					let valuObj = {}
					req.value_object.forEach(subReq => {
						valuObj[subReq.key] = subReq.value
					})
					params[req.key] = valuObj
				}
			})
			let dynamicValues = item.body && item.body.dynamic_values
			dynamicValues && dynamicValues.forEach(req => {
				if(this.props.editNewRuleDetails[req.key]) {
					params[req.key] = this.props.editNewRuleDetails[req.key]
				}
			})
	
			let url = item.name
			let API_END_POINT = item.api
			let apiMethod = item.method
			let responseMapping = item.response_mapping
			
			this.props.DynamicApiToDevCall(API_END_POINT, apiMethod, url, params, (promise, response) => {
				if(promise) {
					let results = response
					responseMapping && responseMapping.forEach(res => {
						if(res.index >= 0) {
							results = results[res.index]
						}
						
						let array = results
						if(res.path && res.path !== "") {
							array = results[res.path]
						}

						let options = []
						if(Array.isArray(array)) {
							array.forEach(item => {
								let optionRow = {
									label: res.label && res.label !== "" ? item[res.label] : item,
									value: res.value && res.value !== "" ? item[res.value] : item
								}
								options.push(optionRow)
							})
						}

						this.setState({ [res.variable+"_options"]: options },
							() => {
								if(this.props.viewMode) {
									this.setState({ savedData: [this.props.masterData] },
										() => this.savedData()	
									)
								} else {
									if(this.props.editNewRuleDetails && this.props.editNewRuleDetails.policies) {
										if(this.props.editNewRuleDetails.policies.filter(e => e.rule_category === this.props.masterData.rule_category).length) {
											let savedData = this.props.editNewRuleDetails.policies.filter(f => f.rule_id === this.props.masterData.rule_id)
											if(savedData.length) {
												this.setState({ savedData },
													() => {
														this.savedData()
													}
												)
											}
										}
									}
								}
							}
						)
					})
				}
			})
		})
	}

	savedData = () => {
		let savedData = this.state.savedData
		let policyArray = []
		savedData.forEach((row, index) => {
			policyArray.push(index)
			this.setState({ ["policy_id_"+index]: row.policy_id })
			if(row.action && Object.entries(row.action)) {
				Object.entries(row.action).forEach(([key, value]) => {
					if(key === "schedule") {
						let timeSplit = value.substring(0, value.length - 1)
						let timeTypeSplit = value.substring(value.length - 1, value.length)
						if(timeTypeSplit === "h" || timeTypeSplit === "m") {
							this.setState({ ["time_type_"+index]: timeTypeSplit, [key+"_"+index]: timeSplit })
						} else {
							this.setState({ [key+"_"+index]: value, ["time_type_"+index]: "" })
						}
					} else {
						if(key === "name") {
							key = "selected_action"
						}
						this.setState({ [key+"_"+index]: value })
					}
				})
			}
			row.variables.forEach(item => {
				this.setState({ [item.variable+"_"+index]: item.value_object_list ? item.value_object_list : (item.value_list ? item.value_list : item.value), inputDetected: true },
					() => {
						let dependsOnFieldFullfiled = true
						let paddingLeft = 0
						if(item.depends_on && item.depends_on.length) {
							item.depends_on.forEach(dep => {
								if(!this.state[dep.key+"_"+index] || this.state[dep.key+"_"+index] !== dep.value) {
									dependsOnFieldFullfiled = false
								}
							})
							paddingLeft = parseInt(2 + item.depends_on.length)+"rem"
						}

						if(item.component === "daytimerange") {
							let timeRange = ""
							if(this.state[item.variable+"_"+index] && this.state[item.variable+"_"+index] !== "") {
								let splitTime = this.state[item.variable+"_"+index].split("-")
								if(splitTime.length > 1) {
									splitTime.forEach((dT, dTindex) => {
										let dayTime = dT.split(":")
										this.setState({ ["day_"+index]: dayTime[0] })
										if(dTindex) {
											timeRange += "-"	
										}
										timeRange += dayTime[1]+":"+dayTime[2]
									})
								}
							}
							this.setState({ ["time_"+index]: timeRange })
						}

						this.setState({ [item.variable+"_depends_on"]: dependsOnFieldFullfiled, [item.variable+"_paddingLeft"]: paddingLeft })
					}
				)

			})

			if(row.enabled) {
				this.setState({ ["enabled_"+index]: true })
			}
		})
		this.setState({ policyArray })
	}

	filterEngineDetails = (engineType, item) => {
		let selectedEngineDetails = this.state.engineDetails.filter(e => e.name === engineType)

		this.setState({ ["selectedEngineDetails_"+item]: selectedEngineDetails[0] })
	}

	showPolicy = (array, showField, index) => {		
		this.state[array].forEach(item => {
			if(index === item) {
				this.setState({ [showField+"_"+item]: !this.state[showField+"_"+item] })
			} else {
				// this.setState({ [showField+"_"+item]: false })
			}
		})
	}

	addPolicies = (array, showField, index) => {
        let rowList = this.state[array];
        if(this.state[array]) {
            let value = this.state[array][this.state[array].length - 1]
            value = value+1 
            rowList.push(value);
        }
		rowList.forEach(item => {
			if(index === item) {
				this.setState({ [showField+"_"+item]: true })
			} else {
				this.setState({ [showField+"_"+item]: false })
			}
		})
        
        this.setState({[array]: rowList },
			() => this.onChangeInput()	
		)
    }

	handleMultiSelectChange = (field, arrayValue, state) => {
		let value = arrayValue.map(item => item.value ? item.value : item)
		let selectedValue = []
		// let selectedArray = []

		if(state) {
			let prevState = this.state[field] ? this.state[field] : []
			if(prevState.length === 1 && prevState[0] === "All") {
				prevState = []
			}
			if(value.includes("All")) {
				if(!prevState.includes("All")) {
					if(field === "tag_value_") {
						selectedValue = value
					} else {
						selectedValue = state
					}
				} else {
					const index = value.indexOf("All");
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
					// selectedArray = arrayValue.filter(e => e.value !== "All")
				}
			} else if(!prevState.includes("All")) {
				selectedValue = value
				// selectedArray = arrayValue
			}

			if(selectedValue.length && !selectedValue.includes("All")) {
				if(selectedValue.length === (!state.includes("All") ? state.length : state.length -1)) {
					selectedValue.push("All")
				}
			}

			selectedValue = selectedValue.filter(e => e !== "All")
		} else {
			selectedValue = value
			// selectedArray = arrayValue
		}
		// [field+"_array"]: selectedArray
		this.setState({ [field]: selectedValue },
			() => this.onChangeInput()	
		)
	}
	
	getMultiSelectedCount = (type, array) => {
		return array.length && array.includes("All") ? "All" : (array.length ? array.length +" Selected" : <span className="placeholder w-100">Select</span>)
	}

	removeField = (field, reg, state) => {
        // filteredResult = this.state.selectedTags.filter(e => e.key !== tag.key && e.value !== tag.value)
		let filteredResult = this.state[field].filter(e => e !== reg)
        this.setState({ [field]: filteredResult },
			() => this.handleMultiSelectChange(field, filteredResult, state)
		)
	}

	removeSelectedDetails = (field, removeItem) => {
		let filteredResult = this.state[field].filter(e => e !== removeItem)
        this.setState({ [field]: filteredResult })
	}

	onChangeInput = () => {
		let data = []
		this.state.policyArray.forEach(item => {
			let obj = {}
			if(this.state.edit) {
				obj.policy_id = "id"
			}
			obj.rule_category = this.props.masterData.rule_category
			obj.tags = this.props.masterData.tags
			obj.mode =  this.props.masterData.mode
			obj.enabled = this.state["enabled_"+item] ? true : false
			// obj.disabled = this.state["disable_"+item] ? true : false
			// obj.enabled = false
			obj.rule_id = this.props.masterData.rule_id
			obj.resource_type = this.props.resource_type
			obj.rule_category = this.props.rule_category
			obj.template = false
			obj.governance_type = this.props.governance_type

			if(this.state["policy_id_"+item]) {
				obj.policy_id = this.state["policy_id_"+item]
			}

			let variables = []
			let masterVaiables = this.props.masterData.variables ? this.props.masterData.variables : []
			masterVaiables.forEach(itmVar => {
				let dependsOnFieldFullfiled = true
				let paddingLeft = 0
				if(itmVar.depends_on && itmVar.depends_on.length) {
					itmVar.depends_on.forEach(dep => {
						if(!this.state[dep.key+"_"+item] || this.state[dep.key+"_"+item] !== dep.value) {
							dependsOnFieldFullfiled = false
						}
					})
					paddingLeft = parseInt(2 + itmVar.depends_on.length)+"rem"
				}
				this.setState({ [itmVar.variable+"_depends_on"]: dependsOnFieldFullfiled, [itmVar.variable+"_paddingLeft"]: paddingLeft })

				let inputValue = this.state[itmVar.variable+"_"+item]				
				if(itmVar.component === "daytimerange") {
					let dayTimeRange = ""
					if(this.state["day_"+item] && this.state["time_"+item] && this.state["time_"+item] !== "") {
						let splitTime = this.state["time_"+item].split("-")
						if(splitTime.length > 1) {
							splitTime.forEach((dT, index) => {
								if(index) {
									dayTimeRange += "-"	
								}
								dayTimeRange += this.state["day_"+item]+":"+dT
							})
						}
					}
					this.setState({ [itmVar.variable+"_"+item]: dayTimeRange })

					inputValue = dayTimeRange
				}
				
				if(itmVar.component === "tagging_component") {
					inputValue = this.state[itmVar.variable+"_"+item] ? this.state[itmVar.variable+"_"+item].filter(e => e.value !== "All") : []
				}

				if(itmVar.component === "value_object_list") {
					if(this.state["obj_list_"+itmVar.variable+"_"+item]) {
						inputValue = this.state["obj_list_"+itmVar.variable+"_"+item]
					} else {
						inputValue = itmVar.value_object_list
					}
				}

				
				
				let dataRow = {}
				Object.entries(itmVar).forEach(([key, value]) => {
					if(key === "value_list" || key === "value" || key === "value_object_list") {
						dataRow[key] = inputValue
					} else {
						dataRow[key] = value
					}
				})
				variables.push(dataRow)
			})
			obj.description = this.state.description
			obj.variables = variables

			if(this.state.actionDetails && this.state.actionDetails.length) {
				let actionObj = {}
				actionObj.variables = []
				if(this.state["selected_action_"+item]) {
					actionObj.processor_rule_id = this.state["action_processor_rule_id_"+item]
					actionObj.name = this.state["selected_action_"+item]				
					if(this.state["apply_method_"+item]) {
						actionObj.apply_method = this.state["apply_method_"+item]					
						if(this.state["schedule_"+item] && this.state["schedule_"+item] !== "") {
							if(this.state["apply_method_"+item] === "run_after") {
								actionObj.schedule = this.state["schedule_"+item]+""+this.state["time_type_"+item]
							} else if(this.state["apply_method_"+item] === "run_at") {
								actionObj.schedule = this.state["schedule_"+item]
							}
						}
					}
					obj.action = actionObj
				}
			}

			data.push(obj)
		})

		let allPolicies = this.props.editNewRuleDetails.policies ? this.props.editNewRuleDetails.policies : []

		let currentPolicies = []
		let policies = []

		if(allPolicies.length) {
			allPolicies.forEach(row => {
				if(row.rule_id === this.props.masterData.rule_id) {
					currentPolicies.push(row)
				} else {
					policies.push(row)
				}
			})
		}

		policies = [...data, ...policies]
		
		this.props.setNewEditRuleDetails('policies', policies)

		this.setState({ inputDetected: true })
	}

	validateInput = () => {
		let hasError = false
		let inputDetected = false
		if(this.state.variables && this.state.variables.length) {
			this.state.policyArray.forEach(item => {
				this.state.variables.forEach((varb, varbIndex) => {
					if(varb.value) {
						if(this.state[varb.variable+"_"+item] || this.state[varb.variable+"_"+item] !== "") {
							inputDetected = true
						}
					} else if(varb.value_list) {
						if(this.state[varb.variable+"_"+item] && this.state[varb.variable+"_"+item].length) {
							inputDetected = true
						}
					}

					if(!hasError && (!varb.manditory || varb.manditory === "undefined")) {
						hasError = false
					} else if(!hasError && varb.manditory) {
						if(varb.value) {
							if(!this.state[varb.variable+"_"+item] || this.state[varb.variable+"_"+item] === "") {
								hasError = true
							}
						} else if(varb.value_list) {
							if(!this.state[varb.variable+"_"+item] && !this.state[varb.variable+"_"+item].length) {
								hasError = true
							}
						}
					}
				})
			})
		}

		// console.log(this.props.masterData.rule_id, "hasError", hasError, "inputDetected", inputDetected)

		this.setState({ hasError })
		
		this.props.validateInput(hasError, inputDetected)
	}

	onKeyDownTimeRange = (e, time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		if(e.keyCode === 8) { 
			str = str.slice(0, -1)
			this.validateTimeRange(str, state)
		} else {
			this.validateTimeRange(time, state)
		}
	}

	validateTimeRange = (time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		
		let strLength = str.length
		let firstHour = str.substring(0, 2)
		let data = "" 
		if(strLength > 1 && parseInt(firstHour) > 23) {
			data += "00:"
		} else if(strLength > 1 && parseInt(firstHour) < 10) {
			if(firstHour.length == 2) {
				data = firstHour+":"
			} else {
				data += "0"+firstHour+":"
			}
		} else if(strLength > 1 && parseInt(firstHour) <= 23) {
			data += firstHour+":"
		}

		if(data === "") {
			data = time
		}
		
		let firstMinute = str.substring(2, 4)
		
		if(firstMinute.length) {
			if(strLength > 3 && parseInt(firstMinute) > 59) {
				data += "00-"
			} else if(strLength > 3 && parseInt(firstMinute) > 10) {
				data += firstMinute+"-"
			} else {
				data += firstMinute
			}
		}

		let secondHour = str.substring(4, 6)
		if(secondHour.length) {
			if(strLength > 4 && parseInt(secondHour) > 23) {
				data += "00:"
			} else if(strLength > 4 && parseInt(secondHour) < 10) {
				if(secondHour.length === 2) {
					data += secondHour+":"
				} else {
					data += secondHour
				}
			} else if(strLength > 4 && parseInt(secondHour) <= 23) {
				data += secondHour+":"
			}
		}

		let secondMinute = str.substring(6, 8)
		
		if(secondMinute.length) {
			if(strLength > 6 && parseInt(secondMinute) > 59) {
				data += "00"
			} else if(strLength > 6 && parseInt(secondMinute) > 10) {
				data += secondMinute
			} else {
				data += secondMinute
			}
		}
		this.setState({ [state]: data },
			() => this.onChangeInput()	
		)
	}

	resetPolicy = (item) => {
		let masterVaiables = this.props.masterData.variables ? this.props.masterData.variables : []
		masterVaiables.forEach(itmVar => {
			this.setState({ [itmVar.variable+"_"+item]: itmVar.value_list ? [] : "" })
		},
			() => this.onChangeInput()
		)
	}
	
	deletePolicy = (item) => {
		let array = this.state.policyArray.filter(e => e !== item)

		if(!array.length) {
			array = [0]
		}
		let deletedPolicies = this.props.editNewRuleDetails && this.props.editNewRuleDetails.deleted_policies ? this.props.editNewRuleDetails.deleted_policies : []
		deletedPolicies.push(this.state["policy_id_"+item])
		this.props.setNewEditRuleDetails("deleted_policies", deletedPolicies)
		
		this.setState({ policyArray: array, ["show_child_policy_"+item]: false },
			() => {
				this.resetPolicy(item)
			}
		)
	}

	addNewTextList = (variable, item) => {
		let array = this.state[variable+"_"+item] ? this.state[variable+"_"+item] : []
        if(this.state[["input_"+variable+"_"+item]] && this.state[["input_"+variable+"_"+item]] !== "") {
			array.push(this.state[["input_"+variable+"_"+item]])
		}

		this.setState({ [variable+"_"+item]: array, ["input_"+item]: "" }, 
			() => this.onChangeInput()
		)
	}

	removeTextDetails = (variable, item, value) => {
		let filteredResult = this.state[variable+"_"+item].filter(e => e !== value)
        this.setState({ [variable+"_"+item]: filteredResult }, 
			() => this.onChangeInput()
		)
    }

	onKeyDownTime = (e, time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		if(e.keyCode === 8) { 
			str = str.slice(0, -1)
			this.validateTime(str, state)
		} else {
			this.validateTime(time, state)
		}
	}

	validateTime = (time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		
		let strLength = str.length
		let firstHour = str.substring(0, 2)
		let data = "" 
		if(strLength > 1 && parseInt(firstHour) > 23) {
			data += "00:"
		} else if(strLength > 1 && parseInt(firstHour) < 10) {
			if(firstHour.length == 2) {
				data = firstHour+":"
			} else {
				data += "0"+firstHour+":"
			}
		} else if(strLength > 1 && parseInt(firstHour) <= 23) {
			data += firstHour+":"
		}

		if(data === "") {
			data = time	
		}
		
		let firstMinute = str.substring(2, 4)
		
		if(firstMinute.length) {
			if(strLength > 3 && parseInt(firstMinute) > 59) {
				data += "00"+":"
			} else {
				data += firstMinute+":"
			}
		}
		
		let firstSecond = str.substring(4, 6)
		
		if(firstSecond.length) {
			if(strLength > 5 && parseInt(firstSecond) > 59) {
				data += "00"
			} else {
				data += firstSecond
			}
		}

		this.setState({ [state]: data },
			() => this.onChangeInput("action")	
		)
	}

	getAllTagsKeys = () => {
		
		if(!this.props.tagKeys || !this.props.tagKeys.length) {
			let params = {}
			// params.account_id = "265469181489"
			// params.region = "us-east-2"
			this.props.getAllTagsKeys(params, (promise, response) => {})
		}
	}

	getAllTagsValues = (item) => {
		let params = {}
		params.tags = [{ "key": this.state["tag_key_"+item] }]
		this.props.getAllTagsValues(params, (promise, response) => {
			if(promise) {
				if(response.length) {
					response.unshift("All");
				}
				this.setState({ ["tagValues_"+item]: response })
			}
		})
	}

	addNewTag = (variable, item) => {
		let selectedTags = this.state[variable+"_"+item] ? this.state[variable+"_"+item] : []

		if(this.state["tag_key_"+item] && this.state["tag_key_"+item] !== "") {
			if(this.state["tag_value_"+item] && this.state["tag_value_"+item].length) {
				this.state["tag_value_"+item].forEach(tag => {
					if(tag !== "All" && !(selectedTags.filter(e => e.key === this.state.tag_key && e.value !== tag).length) || selectedTags.filter(e => e.key !== this.state.tag_key)) {
						let dataRow = {}
						dataRow.key = this.state["tag_key_"+item]
						dataRow.value = tag
						selectedTags.push(dataRow)
					}
				})
			} else {
				let dataRow = {}
				dataRow.key = this.state["tag_key_"+item]
				selectedTags.push(dataRow)
			}
				
			// let dataRow = {
			// 	key: this.state["tag_key_"+item],
			// 	value: this.state["tag_value_"+item],
			// }
			// selectedTags.push(dataRow)
		}

		this.setState({ [variable+"_"+item]: selectedTags, ["tag_key_"+item]: "", ["tag_value_"+item]: [], tagValues: [] },
			() => this.onChangeInput()
		)
	}

	removeTagSection = (variable, item, tag) => {
		// filteredResult = this.state.selectedTags.filter(e => e.key !== tag.key && e.value !== tag.value)
		let filteredResult = this.state[variable+"_"+item].filter(e => e !== tag)
		this.setState({ [variable+"_"+item]: filteredResult }, 
			() => this.onChangeInput()
		)
	}
	valueObjListFunction = (inputValues, varb, item) => {
		this.setState({ ["obj_list_"+varb.variable+"_"+item]: inputValues }, () => this.onChangeInput())
	}
	textComponent = (value, field, dataType) =>{
		if(dataType === 'integer'){

			value = onlyNumeric(value)
		}
		this.setState({ [field]: value }, () => this.onChangeInput())
	}

	render() {
		return (
			this.state.policyArray && this.state.policyArray.map((item, index) => {
				return(
					<React.Fragment>
					<div className={`${!this.props.viewMode ? "py-3" : "py-1"} mb-2 border-bottom policiesPage`}>
						{!this.props.viewMode ?
							<React.Fragment>
							<div className={`d-flex ${!this.props.viewMode ? "justify-content-between" : "justify-content-end"} pl-2`}>
								{!this.state.inputDetected ?
									<p className={`mb-0 mx-2 align-self-center mb-1 text-primary-color cursorPointer`} onClick={() => this.showPolicy("policyArray", "show_child_policy", item)}>{this.props.masterData && this.props.masterData.description ? this.props.masterData.description : "Choose policy details"}</p>
								:
									<p className={`mb-0 mx-2 align-self-center mb-1 text-white`} onClick={() => this.showPolicy("policyArray", "show_child_policy", item)}>
										{this.state.variables ?
											this.state.variables.map((varb, varbIndex) => {
												return(
													this.state[varb.variable+"_"+item] && this.state[varb.variable+"_"+item] !== "undefined" && this.state[varb.variable+"_label"] ?
														this.state[varb.variable+"_label"].map((row, rowIndex) => {
															return(
															row.input ?
																row.component === "select" ?
																	<span className={`mb-0 mr-1 mt-2 text-info align-self-center ${!rowIndex && varbIndex ? "ml-2" : ""}`}>
																		{this.state[varb.variable+"_"+item] !== "undefined" ? (this.state[varb.variable+"_options"] && this.state[varb.variable+"_options"].filter(e => e.value === this.state[varb.variable+"_"+item]).length ? this.state[varb.variable+"_options"].filter(e => e.value === this.state[varb.variable+"_"+item])[0].label : this.state[varb.variable+"_"+item]) : ""}
																	</span>
																: row.component === "text" || row.component === "timerange" ?
																	varb.value_list ?
																		this.state[varb.variable+"_"+item] && this.state[varb.variable+"_"+item].map(lst => {
																			return(
																				<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{this.state[varb.variable+"_options"] && this.state[varb.variable+"_options"].filter(e => e.value === lst).length ? this.state[varb.variable+"_options"].filter(e => e.value === lst)[0].label : lst}</span>
																			)
																		})
																	:
																		<span className={`mb-0 mr-1 mt-2 text-info align-self-center ${!rowIndex && varbIndex ? "ml-2" : ""}`}>{this.state[varb.variable+"_"+item]}</span>
																: row.component === "daytimerange" ?
																	<React.Fragment>
																		<span className={`mb-0 mr-1 mt-2 text-info align-self-center ${!rowIndex && varbIndex ? "ml-2" : ""}`}>{this.state[varb.variable + "_" + item] ? this.state[varb.variable+"_"+item] : ""}</span>
																	</React.Fragment>
																: typeof this.state[varb.variable+"_"+item] === 'boolean' ?
																	null
																: row.component === "tagging_component" ?
																	this.state[varb.variable+"_"+item] && this.state[varb.variable+"_"+item].length ? 
																		this.state[varb.variable+"_"+item].filter(e => e.value !== "All").map(tag => {
																			return(
																				<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{tag.key + (tag.value ? " : "+tag.value : "")}</span>
																			)
																		})
																	: null
																: row.component === "value_object_list" ?
																	// <p className={`mb-0 mx-2 align-self-center mb-1 text-primary-color cursorPointer`}>{this.props.masterData && this.props.masterData.description ? this.props.masterData.description : ""}</p>
																	null
																:
																	this.state[varb.variable+"_"+item] && this.state[varb.variable+"_"+item].map(lst => {
																		return(
																			<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{this.state[varb.variable+"_options"] && this.state[varb.variable+"_options"].filter(e => e.value === lst).length ? this.state[varb.variable+"_options"].filter(e => e.value === lst)[0].label : lst}</span>
																		)
																	})
															:
																<span className={`mb-0 mr-1 mt-2 ${!rowIndex && varbIndex ? "ml-2" : ""} align-self-center`}>{!rowIndex ? row.string : (row.string !== "" ? row.string.toLowerCase(): "")}</span>
															)
														})
													: null
												)
											})
										: null}
									</p>
								}
								<div className={`d-flex`}>
									{this.state["tags"] && this.state["tags"].length ?
										this.state["tags"].map(tag => {
											return (
												tag !== "" ?
													<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > {tag} </span>
												: null
											)
										})
									: null}
									{!this.state["show_child_policy_"+index] ?
										<React.Fragment>
											<i className="ml-3 fas fa-pencil text-primary-color mt-1 mr-3 f14" onClick={() => this.showPolicy("policyArray", "show_child_policy", item)}></i>
											<span className={`fa fa-angle-${this.state["show_child_policy_"+item] ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.showPolicy("policyArray", "show_child_policy", item)}></span>
										</React.Fragment>
									: 
										<div className="d-flex">
											<span className={`far fa-redo cursorPointer mx-3 text-primary-color f15`} onClick={() => this.resetPolicy(item)}></span>
											{/* <span className={`fal fa-trash cursorPointer mr-3 text-primary-color f15`} onClick={() => this.deletePolicy(item)}></span> */}
											<span className={`ml-3 fa fa-angle-${this.state["show_child_policy_"+item] ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.showPolicy("policyArray", "show_child_policy", item)}></span>
										</div>
									}
								</div>
							</div>
							</React.Fragment>
						: null}
						{this.state["show_child_policy_"+item] || this.props.viewMode ?
							<div className={`${this.props.viewMode ? "pl-3" : "pl-5"}`}>
								<div className="d-flex justify-content-between">
									<div className={`${this.props.viewMode ? "w-80" : "w-100"}`}>
										{this.state.groupedVariables ?
											this.state.groupedVariables.map((grp, index) => {
												return(
													<div className={`d-flex flex-wrap mb-2 ${parseInt(grp.label) > 1 ? "" : "pl-1"}`}>
														{((!index && grp.label === "undefined") || parseInt(grp.label) === 1) && !this.props.viewMode ?
															<React.Fragment>
															<div className={`form-check form-check-inline align-self-center mt-2 mr-2 ${this.state["enabled_"+item] ? "" : "checkboxGrayColor"}`}>
																<input
																	className='form-check-input m-0'
																	type='checkbox'
																	checked={this.state["enabled_"+item]}
																	onClick={e => this.setState({ ["enabled_"+item]: !this.state["enabled_"+item] },
																		() => this.onChangeInput()
																	)}	
																	title={this.state["enabled_"+item] ? "not enable" : "enable"}								
																/>
																{!this.state["enabled_"+item] ?
																	<span class="checkmark" onClick={e => this.setState({ ["enabled_"+item]: !this.state["enabled_"+item]}, () => this.onChangeInput())}></span>
																: null}
															</div>
															</React.Fragment>
														: null}
														{grp.data.length ?
															grp.data.map(varb => {
																return(
																	!varb.depends_on || !varb.depends_on.length || this.state[varb.variable+"_depends_on"] ?
																		<div className={`d-flex flex-wrap ${parseInt(grp.label) === 1 ? "w-90" : "w-100"} ${!this.state[varb.variable+"_paddingLeft"] && parseInt(grp.label) > 1 ? "pl-4 ml-1" : ""}`} style={{paddingLeft: this.state[varb.variable+"_paddingLeft"] ? this.state[varb.variable+"_paddingLeft"] : ""}}>
																			{this.state[varb.variable+"_label"] ?
																				this.state[varb.variable+"_label"].map(row => {
																					return(
																						row.input ?
																							varb.component === "multiselect" ?
																								<React.Fragment>
																								{!this.props.viewMode ?
																									<div className={`multiSelectOption align-self-end mt-2`}>
																										<ReactMultiSelectCheckboxes
																											placeholderButtonLabel="All"
																											getDropdownButtonLabel={() => this.getMultiSelectedCount(varb.variable+"_"+item, this.state[varb.variable+"_"+item] ? this.state[varb.variable+"_"+item] : [])}
																											options={this.state[varb.variable+"_options"] && this.state[varb.variable+"_options"].map(row => ({
																												value: row.value,
																												label: row.label
																											}))}
																											onChange={arr => { this.handleMultiSelectChange(varb.variable+"_"+item, arr ? arr : [], this.state[varb.variable+"_options"]) }}
																											value={this.state[varb.variable+"_"+item] && this.state[varb.variable+"_"+item].map(acc => ({
																												value: acc
																											}))}
																										/>
																									</div>
																								: null}
																								
																								{this.state[varb.variable+"_"+item] && this.state[varb.variable+"_"+item].length ? 
																									this.state[varb.variable+"_"+item].includes("All") ?
																										<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">All
																											<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.setState({ ["selected_region_"+item]: [] })} ></i>
																										</span>
																									: 
																										this.state[varb.variable+"_"+item].map((reg, index) => {
																											return(
																												<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{this.state[varb.variable+"_options"] && this.state[varb.variable+"_options"].filter(e => e.value === reg).length ? this.state[varb.variable+"_options"].filter(e => e.value === reg)[0].label : reg}
																													<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeField(varb.variable+"_"+item, reg, this.state[varb.variable+"_options"]) } ></i>
																												</span>
																											)
																										})
																								: null}

																								</React.Fragment>
																							: varb.component === "select" ?
																								!this.props.viewMode ?
																									<Select
																										placeholder={'Select'}
																										isSearchable={true}
																										components={{
																											IndicatorSeparator: () => null
																										}}
																										className={`selectOption mr-1`}
																										value={({
																											value: this.state[varb.variable+"_"+item],
																											label: this.state[varb.variable+"_"+item] !== "undefined" && this.state[varb.variable+"_options"] && this.state[varb.variable+"_options"].filter(e => e.value === (this.state[varb.variable+"_"+item])).length ? this.state[varb.variable+"_options"].filter(e => e.value === (this.state[varb.variable+"_"+item]))[0].label : <span className="placeholder">Select</span>
																										})}		
																										options={this.state[varb.variable+"_options"] && this.state[varb.variable+"_options"].map(row => ({
																											value: row.value,
																											label: row.label
																										}))}															
																										onChange={event => this.setState({ [varb.variable+"_"+item]: event.value },
																											() => this.onChangeInput()
																										)}
																									/>
																								: 
																									<span className={`mx-1 mt-2 text-info`}>
																										{this.state[varb.variable+"_"+item] !== "undefined" && this.state[varb.variable+"_options"] && this.state[varb.variable+"_options"].filter(e => e.value === (this.state[varb.variable+"_"+item])).length ? this.state[varb.variable+"_options"].filter(e => e.value === (this.state[varb.variable+"_"+item]))[0].label : <span className="placeholder">Select</span>}
																									</span>
																							: varb.component === "text" ?
																								!this.props.viewMode ?
																									varb.value_list ?
																										<div className="d-flex flex-wrap">
																											<input 
																												type="text" 
																												className={`inputField textBoxWidth`}
																												style={{minHeight: "38px"}}
																												placeholder={"enter"}
																												value={this.state["input_"+varb.variable+"_"+item]}
																												onChange={e => this.setState({ ["input_"+varb.variable+"_"+item]: e.target.value })}
																											/>
																											<span className={`far fa-plus cursor-pointer f18 mt-2 mb-1 align-self-end mr-4`} onClick={() => this.addNewTextList(varb.variable, item)}></span>
																											{this.state[varb.variable+"_"+item] && this.state[varb.variable+"_"+item].length ? 
																												this.state[varb.variable+"_"+item].map((row, index) => {
																													return(
																														<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{row}
																															<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeTextDetails(varb.variable, item, row) } ></i>
																														</span>
																													)
																												})
																											: null}
																										</div>
																									:
																										<input 
																											type="text" 
																											className={`inputField textBoxWidth`}
																											style={{minHeight: "38px"}}
																											placeholder={"enter"}
																											value={this.state[varb.variable+"_"+item]}
																											onChange={e => this.textComponent(e.target.value, varb.variable+"_"+item, varb.data_type)}
																										/>
																								: 
																									<span className={`mx-1 mt-2 text-info`}>{this.state[varb.variable+"_"+item]}</span>
																							: varb.component === "value_object_list" ?
																								<React.Fragment>
            																					<div className="break"></div>
																								<ObjectListInputs
																									masterData={this.props.masterData}
																									item={item}
																									varb={varb}
																									viewMode={this.props.viewMode}
																									objInputChange={(inputValues) => this.valueObjListFunction(inputValues, varb, item)}
																								/>
																								</React.Fragment>
																						    : varb.component === "timerange" ?
																								!this.props.viewMode ?
																									<input 
																										type="text" 
																										className={`inputField w-8 pl-2`}
																										style={{minHeight: "38px"}}
																										placeholder={"HH:MM-HH-MM"}
																										value={this.state[varb.variable+"_"+item]}
																										maxLength="11"
																										onKeyDown={e => this.onKeyDownTimeRange(e, e.target.value, varb.variable+"_"+item)}
																										onChange={e => this.validateTimeRange(e.target.value, varb.variable+"_"+item)}

																										// onChange={e => this.setState({ [varb.variable+"_"+item]: this.validateTimeRange(e.target.value) }, () => this.onChangeInput())}
																									/>
																								: 
																									<span className={`mx-1 mt-2 text-info`}>{this.state[varb.variable+"_"+item]}</span>
																							: varb.component === "daytimerange" ?
																								!this.props.viewMode ?
																									<React.Fragment>
																									<Select
																										placeholder={'Select'}
																										isSearchable={true}
																										components={{
																											IndicatorSeparator: () => null
																										}}
																										className={`selectOption mr-1`}
																										value={({
																											value: this.state["day_"+item],
																											label: this.state["day_"+item] && this.state.day_options && this.state.day_options.filter(e => e.value === (this.state["day_"+item])).length ? this.state.day_options.filter(e => e.value === (this.state["day_"+item]))[0].label : <span className="placeholder">Select</span>
																										})}		
																										options={this.state.day_options && this.state.day_options.map(row => ({
																											value: row.value,
																											label: row.label
																										}))}															
																										onChange={event => this.setState({ ["day_"+item]: event.value },
																											() => this.onChangeInput()
																										)}
																									/>
																									<input 
																										type="text" 
																										className={`inputField w-8 ml-2 pl-2`}
																										style={{minHeight: "38px"}}
																										placeholder={"HH:MM-HH-MM"}
																										value={this.state["time_"+item]}
																										maxLength="11"
																										onKeyDown={e => this.onKeyDownTimeRange(e, e.target.value, "time_"+item)}
																										onChange={e => this.validateTimeRange(e.target.value, "time_"+item)}
																									/>
																									</React.Fragment>
																								: 
																									<React.Fragment>
																										<span className={`mx-1 mt-2 text-info`}>
																											{this.state["day_"+item] && this.state.day_options && this.state.day_options.filter(e => e.value === (this.state["day_"+item])).length ? this.state.day_options.filter(e => e.value === (this.state["day_"+item]))[0].label : <span className="placeholder">Select</span>}
																										</span>
																										<span className={`mx-1 mt-2 text-info`}>{this.state["time_"+item]}</span>
																									</React.Fragment>
																							: varb.component === "tagging_component" ?
																								!this.props.viewMode ?
																									<React.Fragment>
																										<Select
																											isSearchable={true}
																											components={{
																												IndicatorSeparator: () => null
																											}}
																											className={`selectOption mr-2 mt-2`}
																											value={({
																												value: this.state["tag_key_"+item],
																												label: this.state["tag_key_"+item] && this.state["tag_key_"+item] !== "" ? this.state["tag_key_"+item] : <span className="placeholder">Key</span>
																											})}														
																											
																											options={this.props.tagKeys && this.props.tagKeys.map(item => ({
																												value: item,
																												label: item,	
																											}))}
																											onChange={event => this.setState({
																												["tag_key_"+item]: event.value,
																												["tagValues_"+item]: [],
																											},
																												() => this.getAllTagsValues(item)
																											)}
																										/>
																										<div className={`multiSelectOption align-self-center mr-2 z998`} style={{marginTop: "1.3rem"}}>
																											<ReactMultiSelectCheckboxes
																												placeholderButtonLabel="Select"
																												getDropdownButtonLabel={() => this.getMultiSelectedCount("tag_value", this.state["tag_value_"+item] ? this.state["tag_value_"+item] : [])}
																												options={this.state["tagValues_"+item] && this.state["tagValues_"+item].map(row => ({
																													value: row,
																													label: row,
																												}))}
																												onChange={arr => { this.handleMultiSelectChange("tag_value_"+item, arr ? arr : [], this.state["tagValues_"+item]) }}
																												value={this.state["tag_value_"+item] && this.state["tag_value_"+item].map(acc => ({
																													value: acc
																												}))}
																											/>
																										</div>
																										<span className={`far fa-plus cursor-pointer f18 mt-4 mb-1 align-self-center mr-4`} onClick={() => this.addNewTag(varb.variable, item)}></span>
																										{this.state[varb.variable+"_"+item] && this.state[varb.variable+"_"+item].length ? 
																											this.state[varb.variable+"_"+item].filter(e => e.value !== "All").map(tag => {
																												return(
																													<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{tag.key + (tag.value ? " : "+tag.value : "")}
																														<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeTagSection(varb.variable, item, tag) } ></i>
																													</span>
																												)
																											})
																										: null}
																									</React.Fragment>
																								: 
																									this.state[varb.variable+"_"+item] && this.state[varb.variable+"_"+item].length ? 
																									this.state[varb.variable+"_"+item].filter(e => e.value !== "All").map(tag => {
																										return(
																											<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{tag.key +" : "+tag.value}</span>
																										)
																									})
																								: null
																							: null
																						:
																							!this.props.viewMode ?
																								<p className={`mb-0 mr-1 mt-2 align-self-center ${this.state["disable_"+item] ? "text-issues" : !this.state["enabled_"+item] ? "text-issues" : "text-white"}`}>{row.string}</p>
																							:
																								<p className={`mb-0 mr-1 mt-2 align-self-center ${this.props.selectedModeType === varb.mode ? (this.props.selectedModeType === "reactive" ? "text-primary-color" : this.props.selectedModeType === "preventive" ? "text-danger" : "text-white") : "text-white"}`}>{row.string}</p>
																					)
																				})
																			: null}
																		</div>
																	: null																
																)
															})
														: null}
													</div>
												)
											})
										: null}
							
										{this.state.actionDetails && this.state.actionDetails.length ?
											<div className="rounded bg-muted p-3 mt-5">
												<p className="mb-0 f20 text-purple3 cursorPointer">Rule Action</p>
												<div className={`d-flex flex-wrap mb-2`}>
													<p className={`mb-0 mt-2 align-self-center`}>Select Action</p>
													<Select
														placeholder={'Select'}
														isSearchable={true}
														components={{
															IndicatorSeparator: () => null
														}}
														className={`selectOption w-25 mr-1 allow_`+item}
														value={({
															value: this.state["selected_action_"+item],
															label: this.state["selected_action_"+item] ? this.state["selected_action_"+item] : <span className="placeholder">Select</span>
														})}		
														options={this.state.actionDetails && this.state.actionDetails.map(row => ({
															value: row.action,
															label: row.action
														}))}															
														onChange={event => this.setState({ 
															["selected_action_"+item]: event.value,
															["action_processor_rule_id_"+item]: this.state.actionDetails.filter(e => e.action === event.value) && this.state.actionDetails.filter(e => e.action === event.value).length && this.state.actionDetails.filter(e => e.action === event.value)[0].processor_rule_id ? this.state.actionDetails.filter(e => e.action === event.value)[0].processor_rule_id : ""
														},
															() => this.onChangeInput("action")
														)}
													/>
												</div>
												
												{this.state["selected_action_"+item] ?
													<div className={`d-flex flex-wrap mb-2 ml-5`}>
														<p className={`mb-0 mt-2 align-self-center`}>Apply Method</p>
														<Select
															placeholder={'Select'}
															isSearchable={true}
															components={{
																IndicatorSeparator: () => null
															}}
															className={`selectOption mr-1 allow_`+item}
															value={({
																value: this.state["apply_method_"+item],
																label: this.state["apply_method_"+item] && this.state["apply_method_"+item] !== "" && this.props.actionMethods ? this.props.actionMethods.filter(e => e.value === this.state["apply_method_"+item])[0].label : <span className="placeholder">Select</span>
															})}
															options={this.props.actionMethods && this.props.actionMethods.map(row => ({
																value: row.value,
																label: row.label
															}))}															
															onChange={event => this.setState({ ["apply_method_"+item]: event.value, ["schedule_"+item]: "" },
																() => this.onChangeInput("action")
															)}
														/>

														{this.state["apply_method_"+item] && this.state["apply_method_"+item] !== "immediately" ?
															<React.Fragment>
															<p className={`mb-0 ml-1 mr-2 align-self-center`}>Schedule</p>
															{this.state["apply_method_"+item] === "run_at" ?
																<input 
																	type="text" 
																	className={`inputField w-8 pl-2`}
																	style={{minHeight: "38px"}}
																	placeholder={"HH:MM:SS"}
																	value={this.state["schedule_"+item]}
																	maxLength="8"
																	onKeyDown={e => this.onKeyDownTime(e, e.target.value, "schedule_"+item)}
																	onChange={e => this.validateTime(e.target.value, "schedule_"+item)}

																	// onChange={e => this.setState({ [varb.variable+"_"+item]: this.validateTimeRange(e.target.value) }, () => this.onChangeInput())}
																/>
															: this.state["apply_method_"+item] === "run_after" ?
																<div className="d-flex">
																	<input 
																		type="text" 
																		className={`inputField w-15 pl-2`}
																		style={{minHeight: "38px"}}
																		placeholder={""}
																		value={this.state["schedule_"+item]}
																		maxLength="2"
																		onChange={e => this.setState({ ["schedule_"+item]: onlyNumeric(e.target.value) }, () => this.onChangeInput("action"))}
																		// onKeyDown={e => this.onKeyDownTime(e, e.target.value, "schedule_"+item)}
																		// onChange={e => this.validateTime(e.target.value, "schedule_"+item)}

																		// onChange={e => this.setState({ [varb.variable+"_"+item]: this.validateTimeRange(e.target.value) }, () => this.onChangeInput())}
																	/>
																	<Select
																		placeholder={'Select'}
																		isSearchable={true}
																		components={{
																			IndicatorSeparator: () => null
																		}}
																		className={`selectOption ml-2 mr-1 allow_`+item}
																		value={({
																			value: this.state["time_type_"+item],
																			label: this.state["time_type_"+item] && this.state["time_type_"+item] !== "" && this.state.timeTypeOptions ? this.state.timeTypeOptions.filter(e => e.value === this.state["time_type_"+item])[0].label : <span className="placeholder">Select</span>
																		})}
																		options={this.state.timeTypeOptions && this.state.timeTypeOptions.map(row => ({
																			value: row.value,
																			label: row.label
																		}))}															
																		onChange={event => this.setState({ ["time_type_"+item]: event.value },
																			() => this.onChangeInput("action")
																		)}
																	/>
																</div>

															: null}
															</React.Fragment>
														: null}
													</div>
												: null}
											</div>
										: null}
									</div>
									{this.props.viewMode && !index ?
										<React.Fragment>
											<div className={`d-flex flex-wrap w-20 align-self-start justify-content-end mt-2`}>
												{this.state["tags"] && this.state["tags"].length ?
													this.state["tags"].map(tag => {
														return (
															tag !== "" ?
																<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2 mb-2`} > {tag} </span>
															: null
														)
													})
												: null}
											</div>
										</React.Fragment>
									: null}
								</div>
								{this.props.viewMode && this.state["selected_action_"+item] && this.state["selected_action_"+item] !== "" ?
									<div className="rounded bg-muted p-3 ml-4 mt-2">
										<div className={`d-flex flex-wrap mb-2`}>
											<span className={`mb-0 ml-2 mt-2 align-self-center text-primary-color mt-2`}>Selected Action <span className="text-white mx-1">{this.state["selected_action_"+item]}</span></span>
											{this.state["apply_method_"+item] && this.state["apply_method_"+item] ?
												<span className={`mb-0 ml-2 mt-2 align-self-center text-primary-color mt-2`}><span className="text-white mx-1">{this.props.actionMethods.filter(e => e.value === this.state["apply_method_"+item]) && this.props.actionMethods.filter(e => e.value === this.state["apply_method_"+item]).length ? this.props.actionMethods.filter(e => e.value === this.state["apply_method_"+item])[0].label : ""}</span></span>
											: null}
											{this.state["schedule_"+item] && this.state["schedule_"+item] ?
												<span className={`mb-0 ml-2 mt-2 align-self-center text-primary-color mt-2`}><span className="text-white mx-1">{this.state["schedule_"+item]} {this.state["time_type_"+item] !== "" ? <span className="ml-1">{this.state["time_type_"+item]}</span>: null}</span></span>
											: null}
										</div>
									</div>
								: null}
							</div>
						: null}
					</div>
					{this.state.template && this.state.policyArray.length === (index+1) ?
						<span className="text-info mt-2 ml-3 mb-5 cursorPointer" onClick={() => this.addPolicies("policyArray", "show_child_policy", item+1)}>+ Add new {this.props.masterData.rule_category} policy</span>
					: null}
					</React.Fragment>
				)
			})
		)
	}
}

/**
 * Type of the props used in the component
 */
 DynamicServices.propTypes = {
	setNewEditRuleDetails: PropTypes.func,
	editNewRuleDetails: PropTypes.object,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('stateVersion',state.governance)
	return {
		editNewRuleDetails: state.governance.editNewRuleDetails,
		tagKeys: state.filters.tagKeys,
	}
}

export default connect(mapStateToProps, { 
	setNewEditRuleDetails,
	DynamicApiToDevCall,
    getAllTagsKeys,
    getAllTagsValues
})(DynamicServices)
