/*************************************************
 * Tvastar
 * @exports
 * @file configurationsReducer.js
 * @author Rajasekar // on 27/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from '../../actions/types'

const INITIAL_STATE = {
	identifiers: {},
}

/**
 * To store identifiers/configurations list in redux store
 * @param {Object} state
 * @param {Object} action
 */
export const identifiersReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ACTION_TYPES.SET_IDENTIFIERS_LIST:
			return {
				...state,
				identifiers: { ...action.payload },
			}
		default:
			return state
	}
}
