/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 01/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 * 
 *************************************************/
import React, { Component } from 'react'
import { AppWrapper } from '../common/AppWrapper'
// import PropTypes from 'prop-types'
import _ from 'lodash'
import { UncontrolledTooltip } from 'reactstrap'
import Select from 'react-select'

import Search from '../common/SearchComponent'
import { listTestDetails, getCollectedData } from '../../actions/commonAction'
import {  capitalizeFirstLetter, momentConvertionUtcToLocalTime } from '../../utils/utility'

class LandingPage extends Component {
    constructor(props) {
        super(props)
        this.toggleRef = React.createRef()
        
        this.state = {
            minMaxHeight: "700px",
            showLoading: true,
            selectedDuration: "+7d",

            startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
			perPage: 100,
            
            pageTypes: [
                {label: "All", value: "All"},
                {label: "Alerts", value: "alerts"},
                {label: "Events", value: "events"},
                {label: "Anomalies", value: "anomalies"},
                {label: "Errors", value: "errors"}
            ],
            pageType: "All",
            sortBy: "Latest",

            selectedCategory: "Important / All",
            groupedBy: "severity",
            allCategory: [],
        }
    }

	componentDidMount = () => {
        this.getWindowSize()
        this.listTestDetails()        
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 100
        let paddingHeight = 90 
                
		let outerHeight = (window.outerHeight - browserBorder - headerHeight - paddingHeight ) + "px"

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }

    listTestDetails = () => {
        let params = {}
        params.duration = this.state.selectedDuration

        this.props.listTestDetails(params, (promise, response) => {
            if(promise) {
                this.setState({ issueList: response, allCategory: [], totalFilteredArray: [], filteredArray: [], selectedIssue: response.length ? response[0].test_id : "" },
                    () => this.getCollectedData()
                )
            } else {
               this.setState({ allCategory: [], totalFilteredArray: [], filteredArray: [] }) 
            }
        })
    }
    
	getCollectedData = () => {
        let params = {}
        params.test_id = this.state.selectedIssue

        if(this.state.pageType !== "All") {
            params.type = [this.state.pageType]
        }
        if(this.state.pageType === "anomalies") {
            params.aggregate_by = ["anomaly_type"]
        } else {
            params.aggregate_by = [this.state.groupedBy]
        }

        if(this.state.selectedIssue !== "") {
            this.props.getCollectedData(params, (promise, response) => {
                if(promise) {
                    let results = response.results && response.results.details ? response.results.details : []
                    this.setState({ issueData: results, makeApiCall: this.state.pageType === "All" ? false : true },
                        () => this.structureIssueData()    
                    )
                } else {
                    this.setState({ issueData: [], showLoading: false })
                }
            })

        }
    }

    structureIssueData = () => {

        let listAllData = []
        
        let totalCount = 0
        let category = []
        if(this.state.issueData && this.state.issueData.length) {
            totalCount = this.state.issueData.reduce( function(a, b){ return a + b.count;}, 0);
            this.state.issueData.forEach((item, index) => {
                let dataRow = {}
                dataRow.count = item.count
                dataRow.category = item.label
                dataRow.orderBy = index+1
                let percentage = Math.ceil(((item.count / totalCount) * 100)/10)
                let array = []
                for(let i=1; i<=percentage; i++) {
                    array.push(i)
                }
                dataRow.totalBars = array
                category.push(dataRow)

                item.alerts.forEach(alt => {
                    if(alt.data_type === "anomalies") {
                        alt.event_time = alt.anomaly_time
                    }
                    listAllData.push(alt)
                })
            })
        }

        let dataRow = {}
        dataRow.count = ''
        dataRow.category = 'Important / All'
        dataRow.orderBy = 0
        category.push(dataRow)

        console.log("category", category)

        this.setState({ showLoading: false, listAllData, allCategory: _.orderBy(category, ['orderBy'], ['asc'])},
            () => this.getCategoryWiseData()
        )
    }

    getCategoryWiseData = () => {
        let filterdCategoryAlerts = this.state.listAllData
        if(this.state.selectedCategory !== 'Important / All') {
            filterdCategoryAlerts = this.state.issueData.filter(arr => arr.label === this.state.selectedCategory)
            if(filterdCategoryAlerts && filterdCategoryAlerts.length) {
                filterdCategoryAlerts = filterdCategoryAlerts[0].alerts ? filterdCategoryAlerts[0].alerts : []
            }
        }

        let orderedArray = _.orderBy(filterdCategoryAlerts, ['event_time'], [this.state.sortBy === "Latest" ? "desc" : "asc"])

        let totalPages = 1
		if(orderedArray.length > this.state.perPage) {
			totalPages = Math.ceil(orderedArray.length / this.state.perPage)
		}

        if(this.state.pageType !== "All") {
            orderedArray = orderedArray.filter(e => e.data_type === this.state.pageType)
        }

        let category = this.state.allCategory
        if(this.state.selectedCategory === 'Important / All') {
            category = []
            this.state.allCategory.forEach(cat => {
                if(cat.category === "Important / All") {
                    cat.count = ""
                }  else if(this.state.pageType === "anomalies") {
                    cat.count = orderedArray.filter(e => e.data_type === this.state.pageType).length
                } else if(this.state.groupedBy === "severity") {
                    let countData = []
                    if(cat.category === "Others") {
                        countData = orderedArray.filter(e => (e.severity === cat.category || !e.severity))
                    } else {
                        countData = orderedArray.filter(e => e.severity === cat.category)
                    }

                    if(this.state.pageType !== "All") {
                        countData = countData.filter(e => e.data_type === this.state.pageType)
                    }

                    cat.count = countData.length

                } else if(this.state.groupedBy === "asset") {
                    if(this.state.pageType !== "All") {
                        cat.count = orderedArray.filter(e => e.asset_name === cat.category && e.data_type === this.state.pageType).length
                    } else {
                        cat.count = orderedArray.filter(e => e.asset_name === cat.category).length
                    }
                }
    
                category.push(cat)
            })
        }

        this.setState({ allCategory: category, totalFilteredArray: orderedArray, filteredArray: orderedArray, totalPages, startRecord: 0, currentPage: 1, pageNumber: 1, showLoading: false })
    }

    navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = this.state.startRecord
            if(action === 'next' && currentPage !== this.state.totalPages) {
                startRecord = startRecord + this.state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - this.state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = this.state.totalPages
                startRecord = (this.state.totalPages - 1) * this.state.perPage
            }
            this.setState({ currentPage, startRecord })
        } 
	}

    gotoPage = (pageNumber) => {
        if(pageNumber > 0  && pageNumber <= this.state.totalPages) {
            let startRecord = (pageNumber - 1) * this.state.perPage
            this.setState({ currentPage: pageNumber, pageNumber, startRecord })
        } else  if(pageNumber > this.state.totalPages) {
            this.setState({ pageNumber: this.state.currentPage })  
        } else {
            this.setState({ pageNumber })
        }
    }


    handleClickOutside(event) {
        if (this.toggleRef && !this.toggleRef.current.contains(event.target)) {
            this.setState({ showPageTypeOptions: false })
        } else {
            this.setState({ showPageTypeOptions: true })
        }
    }

    handleChange = (event) => {
        let sortBy = event.target.value

        let orderedArray = _.orderBy(this.state.filteredArray, ['event_time'], [sortBy === "Latest" ? "desc" : "asc"])
        
        this.setState({ filteredArray: [], sortBy },
            () => this.setState({ filteredArray: orderedArray })    
        )
    }

	render() {
		return (
            <div className="container-fluid overflow-auto flex-grow-1 bg-muted" onClick={ (event) => { this.handleClickOutside(event) } }>
                <div className={`loaderOverlay ${this.state.showLoading ? "" : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
                <div className='row h-100'>
                    <div className='col-sm-12 p-0'>
                        <div className="title-section pb-0">
							<div className="d-flex">
                                <div className={`col-5`}>
                                    <h6 className="text-white m-0">Issue Dump</h6>
                                    <p className="text-white m-0">Unified view of Alerts, Events and Errors in your Cloud</p>
                                </div>

                                <div className={`col-7 d-flex justify-content-end ${this.state.showLoading ? 'disabled' : ''}`}>
                                    <div className={`d-flex`}>
                                        <span className={`mr-2 f12 align-self-center`}>Last</span>
                                        <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+30d' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '+30d', showLoading: true }, () => this.listTestDetails())}>1 Month</span>
                                        <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+14d' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '+14d', showLoading: true }, () => this.listTestDetails())}>2 Week</span>
                                        <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+7d' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '+7d', showLoading: true }, () => this.listTestDetails())}>1 Week</span>
                                        <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+3d' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '+3d', showLoading: true }, () => this.listTestDetails())}>3 Days</span>
                                        <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+1d' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '+1d', showLoading: true }, () => this.listTestDetails())}>1 Day</span>
                                    </div>
                                    <div className={`form-group cursorPointer flex-fill mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: '150px' }}>
                                        <label className="mb-1">Dumps</label>
                                        <Select
                                            placeholder={'All'}
                                            isSearchable={false}
                                            className='f13 p-0 colorBlack reactSelectFilterDrop'
                                            value={({
                                                value: this.state.selectedIssue,
                                                label: this.state.selectedIssue && this.state.selectedIssue !== "" ? this.state.issueList.filter(e => e.test_id === this.state.selectedIssue)[0].test_name : <span className="placeholder">Select</span>
                                            })}
                                            options={this.state.issueList && this.state.issueList.map(item => ({
                                                value: item.test_id,
                                                label: item.test_name,	
                                            }))}
                                            onChange={event =>  
                                                this.setState({
                                                    // pageType: "All",
                                                    selectedIssue: event.value,
                                                    showLoading: true
                                                },
                                                    () => this.getCollectedData()
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='p-2'>
                            <div className="rounded bg-dark px-3 py-2">
                                <div className="d-flex mb-2 justify-content-between">
                                    <div className="d-flex mr-2 w-40">
                                        <p className="f12 m-0 align-self-center pt-1">Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.state.totalFilteredArray && this.state.totalFilteredArray.length} issues </p>

                                        <p className="f12 m-0 align-self-center pt-1 text-info ml-2">({this.state.issueList && this.state.issueList.length && this.state.issueList.filter(e => e.test_id === this.state.selectedIssue).length ? momentConvertionUtcToLocalTime(this.state.issueList.filter(e => e.test_id === this.state.selectedIssue)[0].start_time, 'DD MMM YYYY HH:mm') +' - '+ momentConvertionUtcToLocalTime(this.state.issueList.filter(e => e.test_id === this.state.selectedIssue)[0].end_time, 'DD MMM YYYY HH:mm') : ""})</p>
                                    </div>
                                    <div className="d-flex justify-content-end w-60 mb-n2 align-self-center">
                                        {this.state.totalFilteredArray && this.state.totalFilteredArray.length ?
                                            <Search
                                                data={this.state.totalFilteredArray ? this.state.totalFilteredArray : []}
                                                applyTags={false}
                                                applyLiteDarkTags={true}
                                                topClassName={'bg-black5 align-self-center w-30 mr-1 border-gray5 rounded-5 f12'}
                                                searchClassName={'px-2 f12'}
                                                searchIconColor={'text-gray5 f12'}
                                                searchPlaceHolder={'Search....'}
                                                className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                                filteredData={(filteredArray) => {
                                                    let totalPages = 1
                                                    if(filteredArray.length > this.state.perPage) {
                                                        totalPages = Math.ceil(filteredArray.length / this.state.perPage)
                                                    }
                                                    let orderedBy = _.orderBy(filteredArray, ['event_time'], [this.state.sortBy === "Latest" ? "desc" : "asc"])

                                                    this.setState({ filteredArray: orderedBy, emptySearchText: false, totalPages, startRecord: 0, currentPage: 1, pageNumber: 1 })
                                                }}
                                                resetSearchBox={this.state.emptySearchText}
                                            />
                                        : null}
                                    </div>
                                </div>
                                <div className={`d-flex justify-content-between mt-2 ml-1`}>
                                    <div className="d-flex">

                                        <div className="select-sm-bg curvedDropdown minWidth120 mr-2">
                                            <div className="dropdown">
                                                <span className="dropdown-toggle d-flex justify-content-between px-2 text-white" ref={this.toggleRef}>
                                                    {this.state.pageType && this.state.pageTypes.filter(e => e.value === this.state.pageType).length ? this.state.pageTypes.filter(e => e.value === this.state.pageType)[0].label : <span className="placeholder">Select</span>}
                                                </span>
                                                <ul className={`dropdown-menu p-0 ${this.state.showPageTypeOptions ? "show" : ''}`}>
                                                    {this.state.pageTypes.map(item => {
                                                        return (
                                                            <li 
                                                                onClick={() => {
                                                                    this.setState({ 
                                                                        pageType: item.value, 
                                                                        showPageTypeOptions: false,
                                                                        emptySearchText: true,
                                                                        showLoading: true,
                                                                        selectedCategory: "Important / All"
                                                                    }, 
                                                                        () => {
                                                                            if(this.state.makeApiCall || this.state.pageType === "anomalies") {
                                                                                this.getCollectedData()
                                                                            } else {
                                                                                this.getCategoryWiseData()
                                                                            } 
                                                                            // else {
                                                                            //     this.filterTypeCategoryWiseData()
                                                                            // }
                                                                        }
                                                                    )
                                                                }}
                                                                className={`${this.state.pageType === item.value ? "selected" :  ""}`}
                                                            >
                                                                {item.label}
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                        <p className="m-0 align-self-end small mr-2">Sort by</p>
                                        <div class={`transparentSelectBorderBottom align-self-end`}>
                                            <select class="form-control" 
                                            onChange={this.handleChange}
                                            value={this.state.sortBy}
                                            >
                                                <option key="Latest" value="Latest">Latest</option>
                                                <option key="Old" value="Old">Old</option>
                                            </select>
                                        </div> 
                                        {this.state.pageType === "anomalies" ?
                                            <p className="m-0 align-self-end small mr-2"> 
                                                <span>alerts groupd by </span> 
                                                <span className="">Anomaly Type</span>
                                            </p>
                                        :
                                            <React.Fragment>
                                            <p className="m-0 align-self-end small mr-2"> alerts groupd by</p>                                                
                                            <div class={`transparentSelectBorderBottom align-self-end`}>
                                                <select class="form-control" 
                                                    onChange={(event) => {
                                                        this.setState({ groupedBy: event.target.value, selectedAlertId: '', selectedCategory: "Important / All", alertListLoader: true, emptySearchText: true, makeApiCall: true, showLoading: true },
                                                            () => {
                                                                this.getCollectedData()
                                                            }    
                                                        )
                                                    }}
                                                    value={this.state.groupedBy}                            
                                                    >
                                                    <option value='severity'>Severity</option>
                                                    <option value='asset'>Assets</option>
                                                </select>
                                            </div>

                                            </React.Fragment>
                                        }
                                    </div>
                                    
                                    {this.state.filteredArray && this.state.filteredArray.length > this.state.perPage ?
                                        <div className="pagination errorPagePagination f12">
                                            <span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
                                            <button><i className={`fal fa-arrow-to-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('start', this.state.currentPage)}></i></button> 
                                            <button><i className={`fal fa-angle-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('previous', this.state.currentPage)}></i></button> 
                                            <span>
                                                Go to
                                                <input
                                                    type='number'
                                                    value={this.state.pageNumber || this.state.pageNumber === "" ? this.state.pageNumber : this.state.currentPage}
                                                    onChange={e => {
                                                        const page = e.target.value ? Number(e.target.value) : ""
                                                        this.gotoPage(page)
                                                    }}
                                                    className="ml-1 inputClass"
                                                />
                                            </span>
                                            <button><i className={`fal fa-angle-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
                                            <button><i className={`fal fa-arrow-to-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
                                        </div>
                                    : null}
                                </div>
                                
                                <div className="d-flex mt-2">
                                    {this.state.allCategory ? 
                                        <div className="w-20 pr-3 categoryBoxSection w-12 overflowYAuto" style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
                                            <div class="bg-muted px-2 pt-2 select-bgg">
                                                {this.state.groupedBy === "severity" && this.state.pageType !== "anomalies" ?
                                                    this.state.allCategory.map(item => {
                                                        return(
                                                            item.category === "Important / All" ?
                                                                <div className={`d-flex p-2 cursorPointer ${this.state.selectedCategory === item.category ? 'text-info disabled' : ''}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryWiseData())}>
                                                                    <p className="m-0 f-20">{item.category}</p>
                                                                    <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p>
                                                                </div>
                                                            :
                                                                <div class={`d-flex justify-content-end mb-2 ${!item.count ? 'disabled opacity50' : 'cursorPointer'}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryWiseData())}>
                                                                    <div class="d-flex">
                                                                        {item.totalBars.map(bar => {
                                                                            return(
                                                                            <div className={`line-survey-1 risk-bg-${item.category.toLowerCase()}`}></div>
                                                                            )
                                                                        })}
                                                                        <div class={`font-weight-bold text-center risk-border-${item.category.toLowerCase()} text-white p-2`}>
                                                                            <p class="small mb-0" style={{minWidth: "75px"}}>{item.category+" "+item.count}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        )
                                                    })                                            
                                                : 
                                                    this.state.allCategory.map((item, index) => {
                                                        return(
                                                            item.category === "Important / All" ?
                                                                <div className={`d-flex cursorPointer mb-3 ${this.state.selectedCategory === item.category ? 'text-info disabled' : ''}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryWiseData())}>
                                                                    <p className="m-0 f-20">{item.category}</p>
                                                                    <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p>
                                                                </div>
                                                            :
                                                            <div className={`d-flex cursorPointer mb-2 ${this.state.selectedCategory === item.category ? 'text-info disabled' : (!item.count ? 'text-gray4 disabled test' : 'text-white')}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryWiseData())}>
                                                                <div class="d-flex justify-content-between mb-2" style={{maxWidth: "160px"}}>
                                                                    <p class="mb-0 align-self-center ellipseCategoryBoxSection w-60" id={'category_'+index}>{item.category}</p>
                                                                    <UncontrolledTooltip target={'category_'+index}>{item.category}</UncontrolledTooltip>
                                                                    <input class="form-control form-control-sm w-25 border-0 bg-grey text-white readOnly  disabled rounded-0 form-number text-right" style={{minWidth: "40px"}} type="text" placeholder="1" value={item.count !== '' ? item.count : ''} aria-label=".form-control-sm example" />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    : null}
                                    <div className={`bg-dark3 py-3 pl-2 w-88 overflowYAuto`} style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
                                        {this.state.filteredArray && this.state.filteredArray.length ?
                                            this.state.filteredArray.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map((item, i) => {
                                                return(
                                                    <React.Fragment>
                                                    <div className={`d-flex mr-2 px-2 mb-2 pb-1 ${this.state.startRecord + this.state.perPage !== this.state.startRecord + i ? 'border-bottom-black5' : ''} ${this.state.selectedAlertId !== item.group_id ? 'cursorPointer' : 'bg-muted text-white pt-1'}`}>  
                                                        <div className="d-flex">
                                                            <span className={`f10 mr-2 align-self-start mt-1 badge-sm risk-badge-${item.severity ? item.severity.toLowerCase() : 'others'}`}>
                                                                {item.severity ? 
                                                                    (item.severity.toLowerCase() === 'critical' ? 'C' : item.severity.toLowerCase() === 'high' ? 'H' : item.severity.toLowerCase() === 'medium' ? 'M' : 'L') 
                                                                : 'O'}
                                                            </span>
                                                            <p className="mb-0">
                                                                {item.data_type === "alerts" ?
                                                                    <React.Fragment>
                                                                    <span className="mb-0 mr-2 text-white f12 font-weight-bold text-primary-color">{item.event_time ? momentConvertionUtcToLocalTime(item.event_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                    <span className="mb-0 mr-2 f12 font-weight-bold text-purple-2">Alerts</span>
                                                                    <span className={`mb-0 mr-2 f12 text-purple-2`}>
                                                                        {item.event_source ? (Array.isArray(item.event_source) ? item.event_source.join(", ") : item.event_source) : ""}
                                                                        {item.event_name ? ' : ' + item.event_name : ''}
                                                                        {item.event_type ? ' : '+ item.event_type : ''}
                                                                    </span>
                                                                    {item.asset_name ?
                                                                        <span className="mb-0 mr-2 f12 text-white font-weight-bold">{item.asset_name}</span>
                                                                    : null}
                                                                    </React.Fragment>
                                                                : item.data_type === "anomalies" ?
                                                                    <React.Fragment>
                                                                    <span className="mb-0 mr-2 f12 text-primary-color font-weight-bold">{item.anomaly_time ? momentConvertionUtcToLocalTime(item.anomaly_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                    <span className="mb-0 mr-2 f12 font-weight-bold text-purple-2">Anomalies</span>
                                                                    <span className={`mb-0 mr-1 f12`}>{item.anomaly_name ? item.anomaly_name : ''} </span>
                                                                    <span className={`mb-0 mr-1 f12 text-purple-2`}>{item.anomaly_type ? item.anomaly_type : ''} </span>
                                                                    {item.asset_name ?
                                                                        <span className="mb-0 mr-2 f12 text-white font-weight-bold">{item.asset_name}</span>
                                                                    : null}
                                                                    </React.Fragment>
                                                                : item.data_type === "errors" ?
                                                                    <React.Fragment>
                                                                    <span className="mb-0 mr-2 text-primary-color f12 font-weight-bold">{item.event_time ? momentConvertionUtcToLocalTime(item.event_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                    <span className="mb-0 mr-2 f12 font-weight-bold text-purple-2">Errors</span>
                                                                    {item.origin && item.origin.asset_name ? 
                                                                        <span className="f12 mb-0 mr-2 text-purple-2"> Asset {item.origin.asset_name}</span> 
                                                                    : null}
                                                                    </React.Fragment>    
                                                                : item.data_type === "events" ?
                                                                    <React.Fragment>
                                                                    <span className="mb-0 mr-2 f12 text-primary-color font-weight-bold">{item.event_time ? momentConvertionUtcToLocalTime(item.event_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                    <span className="mb-0 mr-2 f12 font-weight-bold text-purple-2">Events</span>
                                                                    <span className={`mb-0 mr-2 f12 text-purple-2`}>{(item.event_source ? capitalizeFirstLetter(item.event_source) : "") + (item.event_name ? ' : ' + item.event_name : '') + (item.event_type ? ' : '+item.event_type : '')}</span>
                                                                    {item.assets && item.assets ? 
                                                                        <span className="mb-0 mr-2 f12 text-white font-weight-bold"> 
                                                                            {item.assets.map((ast, astIndex) => (item.assets.length - 1 === astIndex ? (ast.asset_name) : ast.asset_name + ', '))}
                                                                        </span>
                                                                    : null}
                                                                    </React.Fragment>    
                                                                :  null}

                                                                <span className="mb-0 small mr-1 text-primary-color">Resource</span>
                                                                {item.account_id ?
                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold">{' '+item.account_id}</span>
                                                                : null}
                                                                {item.region ?
                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+item.region}</span>
                                                                : null}
                                                                {item.resource_type ?
                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+(Array.isArray(item.resource_type) ? item.resource_type.join(', ') : item.resource_type)}</span>
                                                                : null}
                                                                {item.service_name ?
                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+item.service_name}</span>
                                                                : null}
                                                                
                                                                {item.data_type === "alerts" ?
                                                                    item.description ?
                                                                        <span className="mb-0 ml-2 f12 text-white font-weight-bold">{item.description}</span>
                                                                    : null
                                                                : item.data_type === "anomalies" ?
                                                                    item.anomaly_message ?
                                                                        <span className="mb-0 ml-2 f12 text-white font-weight-bold">{item.anomaly_message} </span>
                                                                    : null
                                                                : item.data_type === "errors" ?
                                                                    <span className="mb-0 ml-2 f12 font-weight-bold">{item.message ? item.message : ''}</span>
                                                                : item.data_type === "events" ?
                                                                    item.description ?
                                                                        <span className="mb-0 ml-2 f12 text-white font-weight-bold">{item.description}</span>
                                                                    : null
                                                                : null}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        :
                                            <div className='d-flex justify-content-center m-4'>
                                                <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
LandingPage.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('observability landing page',state)
	return {
        accounts: state.filters.accounts,
		regions: state.filters.regions,
    }
}

export default AppWrapper(LandingPage, mapStateToProps, {
    listTestDetails,
	getCollectedData
})