/*************************************************
 * Tvastar
 * @exports
 * @file observabilityReducer.js
 * @author Prakash // on 07/03/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from '../actions/types'

const INITIAL_STATE = {
    observabilityAlertTrend: {},
    observabilityEventTrend: {},
    observabilityAnomalyTrend: {},
    observabilityErrorTrend: {},
    observabilityQueryResult: {},
    observabilityQueryList: [],
    microServiesPropsDetails: {},
    observabilityMicroServicesRcaDetails: [],
}

/**
 * To store assets list in redux store
 * @param {Object} state
 * @param {Object} action
 */
export const observabilityReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
        case ACTION_TYPES.SET_OBSERVABILITY_ALERT_TREND:
            return {
                ...state,
                observabilityAlertTrend: { ...action.payload },
            }
        case ACTION_TYPES.SET_OBSERVABILITY_EVENT_TREND:
            return {
                ...state,
                observabilityEventTrend: { ...action.payload },
            }
        case ACTION_TYPES.SET_OBSERVABILITY_ANOMALY_TREND:
        return {
            ...state,
            observabilityAnomalyTrend: { ...action.payload },
        }
        case ACTION_TYPES.SET_OBSERVABILITY_ERROR_TREND:
            return {
                ...state,
                observabilityErrorTrend: { ...action.payload },
            }
        case ACTION_TYPES.SET_OBSERVABILITY_QUERY_RESULT:
			return {
				...state,
				observabilityQueryResult: { ...action.payload },
			}            
        case ACTION_TYPES.SET_OBSERVABILITY_QUERY_LIST:
			return {
				...state,
				observabilityQueryList: [ ...action.payload ],
			}            
        case ACTION_TYPES.SET_MICROSERVICES_PROPS_DETAILS:
            return {
                ...state,
                microServiesPropsDetails: { ...action.payload },
            }
        case ACTION_TYPES.SET_OBSERVABILITY_MICROSERVCIES_RCA_DETAILS:
            return {
                ...state,
                observabilityMicroServicesRcaDetails: [ ...action.payload ],
            }
		default:
			return state
	}
}
