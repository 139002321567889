/*************************************************
 * Tvastar
 * @exports
 * @file ProvisionConcurrency.js
 * @author Rajasekar // on 26/02/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ProvisionConcurrency extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	render() {
		return (
			<div className='box p-3 bg-dark3'>
				<div className='d-flex'>
					<p className='flex-grow-1 text-white'>
						<b>Provisioned concurrency</b>
					</p>
				</div>
				{this.props.provisionedConcurrencyConfig.map((item, index) => {
					return (
						<div className='d-flex border-bottom' key={index}>
							<div className='flex-grow-1'>
								<div className='d-flex'>
									<h4 className='mb-0 text-break text-white'>{item.functionArn}</h4>
									<span className='align-self-center ml-2 badge badge-light'>Version 8</span>
								</div>
								<p className='mt-2'>Type: Alise</p>
							</div>
							<div className='text-right'>
								<p>
									<span className='badge-outline-success rounded px-2 text-white'>{item.status}</span>
								</p>
								<p>Provisioned concurrency : 0</p>
							</div>
						</div>
					)
				})}
			</div>
		)
	}
}
/**
 * Type of the props used in the component
 */
ProvisionConcurrency.propTypes = {
	provisionedConcurrencyConfig: PropTypes.array,
}

export default ProvisionConcurrency
