import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import ModalWrapper from '../../common/ModalWrapper'
import { UncontrolledTooltip, CustomInput, Spinner } from 'reactstrap'
import {
	getMasterTagGroups,
	setEditTagGroupDetails,
	insertTagGroups,
	getTagGroupsById,
	getTagGroupsConditionById,
	setTagIdInStore,
	updateTagGroups,
} from '../../../actions/governance/taggingAction'
import { showNotification } from '../../../actions/commonAction'
import _ from 'lodash'
import { ERROR, SUCCESS } from '../../../utils/constants'

class ManageTagGroup extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showModal: false,
			newRuleTags: [{ key: '', value: '', editable: false, section_name: '', regex: false }],
			tagsButtonClicked: false,
			isEditResource: false,
			sectionDescription: '',
			sectionName: '',
			editIndex: 0,
			hasError: false,
			showNextLoading: false,
			hasErrorInTagGroup: false,
		}
	}

	componentDidMount = () => {
		if (this.props.match.params.id) {
			this.props.setTagIdInStore(this.props.match.params.id)
			this.setState({ isEditLoading: false })
			this.props.getTagGroupsById({ tag_group_id: this.props.match.params.id }, () => {
				this.props.getTagGroupsConditionById({ tag_group_id: this.props.match.params.id }, () => {
					this.setState({ isEditLoading: false })
				})
			})
		} else {
			if (this.props.editTagGroupDetails.tags && this.props.editTagGroupDetails.tags.length) {
				this.props.setEditTagGroupDetails('tags', this.props.editTagGroupDetails.tags)
			} else {
				this.props.getMasterTagGroups({}, masterTags => {
					this.props.setEditTagGroupDetails('tags', masterTags)
				})
			}
		}
	}

	/**
	 * Add new rule form
	 */
	addNewRuleTags = () => {
		const temp = this.state.newRuleTags
		temp.push({ key: '', value: '', editable: false, section_name: '', regex: false })
		this.setState({ newRuleTags: temp })
	}
	/**
	 * Delete new rule form
	 */
	deleteNewRuleTags = index => {
		if (this.state.newRuleTags.length === 1) {
			this.setState({ tagsButtonClicked: false })
			const temp = this.state.newRuleTags
			temp.splice(index, 1)
			temp.push({ key: '', value: '', editable: false, section_name: '', regex: false })
			this.setState({ newRuleTags: temp })
		} else {
			const temp = this.state.newRuleTags
			temp.splice(index, 1)
			this.setState({ newRuleTags: temp })
		}
	}

	getRegexCount = tags => {
		let temp = _.filter(tags, tag => tag.regex === true)
		return temp.length
	}
	getEditableCount = tags => {
		let temp = _.filter(tags, tag => tag.editable === true)
		return temp.length
	}

	handleChange = (event, index) => {
		if (event.target.type === 'checkbox') {
			if (event.target.checked) {
				let tmp = this.state.newRuleTags
				tmp[index][event.target.name] = true
				this.setState({ newRuleTags: tmp })
			} else {
				let tmp = this.state.newRuleTags
				tmp[index][event.target.name] = false
				this.setState({ newRuleTags: tmp })
			}
		} else {
			this.props.setEditTagGroupDetails(event.target.name, event.target.value)
		}
	}
	deleteSection = index => {
		this.setState({ showModal: false, isEditResource: false })
		let temp = this.props.editTagGroupDetails ? this.props.editTagGroupDetails.tags : []
		temp.splice(index, 1)
		this.props.setEditTagGroupDetails('tags', temp)
	}

	validateTagkeysAndValuesEmpty = () => {
		let count = 0
		this.props.editTagGroupDetails.tags &&
			this.props.editTagGroupDetails.tags.forEach(section => {
				section.tags.forEach(item => {
					if (item.key === '' || item.value === '') {
						count++
					}
				})
			})
		if (count > 0) {
			this.props.showNotification(ERROR, 'Please fill out key and value in each tag.')
			return true
		} else {
			return false
		}
	}

	saveTagGroupDetails = () => {
		if (this.props.editTagGroupDetails.tags && this.props.editTagGroupDetails.tags.length === 0) {
			this.props.showNotification(ERROR, 'Please add a section.')
		}
		if (
			!this.props.editTagGroupDetails.tag_group_name ||
			this.props.editTagGroupDetails.tag_group_name === '' ||
			(this.props.editTagGroupDetails.tags && this.props.editTagGroupDetails.tags.length === 0) ||
			this.validateTagkeysAndValuesEmpty()
		) {
			this.setState({ hasError: true })
		} else {
			this.setState({ showNextLoading: true, hasError: false })
			let data = {}
			data.tag_group_name = this.props.editTagGroupDetails ? this.props.editTagGroupDetails.tag_group_name : ''
			data.tags = []
			this.props.editTagGroupDetails &&
				this.props.editTagGroupDetails.tags &&
				this.props.editTagGroupDetails.tags.forEach(section => {
					for (let i = 0; i < section.tags.length; i++) {
						let tag = {}
						tag.section_name = section.section_name
						let tagDeatils = section.tags[i]
						tag.key = tagDeatils.key
						if (tagDeatils.regex) {
							tag.regex = tagDeatils.value
						} else {
							tag.value = tagDeatils.value
						}
						tag.editable = tagDeatils.editable
						data.tags.push(tag)
					}
				})
			if (this.props.tagGroupId !== '') {
				delete data['tag_group_name']
				data.tag_group_id = this.props.tagGroupId
				this.props.updateTagGroups(data, (success, res) => {
					this.setState({ showNextLoading: false })
					if (success) {
						this.props.onClickNext()
						this.props.showNotification(SUCCESS, (res && res.message) || 'Tag group updated successfully.')
					} else {
						this.setState({ hasErrorInTagGroup: true })
						this.props.showNotification(ERROR, (res && res.message) || 'Tag group not updated.')
					}
				})
			} else {
				delete data['tag_group_id']
				this.props.insertTagGroups(data, (success, res) => {
					this.setState({ showNextLoading: false })
					if (success) {
						this.props.onClickNext()
						this.props.showNotification(SUCCESS, res && res.message ? res.message : 'Tag group created successfully')
					} else {
						this.setState({ hasErrorInTagGroup: true })
						this.props.showNotification(ERROR, res && res.message ? res.message : 'Tag group not created.')
					}
				})
			}
		}
	}

	render() {
		return (
			<>
				<h4>Manage Tag Group</h4>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
					magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
					consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
					pariatur.
				</p>
				<div className='row'>
					<div className='col-sm-12'>
						<div className='row'>
							<div className='col-6'>
								<div className='form-group'>
									<span>Name</span>
									<br />
									<small className='mb-2'>Lorem ipsum dolor sit amet, consectetur</small>
									<input
										type='text'
										className='form-control'
										placeholder=''
										name='tag_group_name'
										value={this.props.editTagGroupDetails ? this.props.editTagGroupDetails.tag_group_name : ''}
										onChange={e => this.handleChange(e)}
									/>
									{(!this.props.editTagGroupDetails.tag_group_name ||
										this.props.editTagGroupDetails.tag_group_name === '') &&
									this.state.hasError ? (
										<p className='text-danger'>Tag group name is required.</p>
									) : null}
								</div>
								<button
									onClick={() => {
										this.setState({
											showModal: true,
											newRuleTags: [{ key: '', value: '', editable: false, section_name: '', regex: false }],
											tagsButtonClicked: false,
											isEditResource: false,
											sectionDescription: '',
											sectionName: '',
										})
									}}
									className='btn btn-outline-primary'
								>
									Add Section
								</button>
								{this.props.editTagGroupDetails.tags &&
								this.props.editTagGroupDetails.tags.length === 0 &&
								this.state.hasError ? (
									<p className='text-danger'>Tag section is required.</p>
								) : null}
							</div>
						</div>
						<ModalWrapper
							showModal={this.state.showModal}
							modalTitle={this.state.isEditResource ? 'Edit Section' : 'New Section'}
							buttonPrimaryLabel='Add'
							buttonSecondaryLabel='Cancel'
							onClickPrimaryButton={() => {
								let tempArr = this.props.editTagGroupDetails ? this.props.editTagGroupDetails.tags : []
								if (
									this.state.sectionName === '' ||
									this.state.sectionDescription === '' ||
									this.state.newRuleTags[0].key === '' ||
									this.state.newRuleTags[0].value === ''
								) {
									this.setState({ hasErrorInForm: true, showModal: true })
								} else {
									if (!this.state.isEditResource) {
										tempArr.push({
											description: this.state.sectionDescription,
											section_name: this.state.sectionName,
											tags: this.state.newRuleTags,
										})
									} else {
										tempArr[this.state.editIndex].section_name = this.state.sectionName
										tempArr[this.state.editIndex].description = this.state.sectionDescription
										tempArr[this.state.editIndex].tags = this.state.newRuleTags
										this.setState({ resources: tempArr })
									}
									this.props.setEditTagGroupDetails('tags', tempArr)
									this.setState({ showModal: false, isEditResource: false })
								}
							}}
							toggle={boolean => this.setState({ showModal: boolean })}
							showLoading={this.state.creatingRule}
							responseMessage={this.state.responseMessage}
							showMessage={this.state.showMessage}
							className='w-50'
						>
							<div className='row'>
								<div className='col-sm'>
									<div className='form-group'>
										<label data-toggle='tooltip' data-placement='right'>
											Name
											<i className='fa fa-info-circle pl-2' id='account' />
											<UncontrolledTooltip placement='right' target='account'>
												Enter a section name.
											</UncontrolledTooltip>
										</label>
										<input
											type='text'
											className='form-control'
											value={this.state.sectionName}
											onChange={e => this.setState({ sectionName: e.target.value })}
											placeholder='Enter section name'
											disabled={this.state.isEditResource}
										/>
										{this.state.selectedAccount && (
											<div className='d-flex mt-2'>
												<h6 className='mb-0 mr-2'>
													<span className='badge font-weight-normal pb-1 badge-secondary'>
														{this.state.selectedAccount}
														<span className='px-1 cursor-pointer'>×</span>
													</span>
												</h6>
											</div>
										)}
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col-sm'>
									<div className='form-group'>
										<label data-toggle='tooltip' data-placement='right'>
											Description
											<i className='fa fa-info-circle pl-2' id='region' />
											<UncontrolledTooltip placement='right' target='region'>
												Enter a description.
											</UncontrolledTooltip>
										</label>
										<input
											type='text'
											className='form-control'
											placeholder='Enter Description'
											value={this.state.sectionDescription}
											onChange={e => this.setState({ sectionDescription: e.target.value })}
											disabled={this.state.isEditResource}
										/>
										{this.state.hasErrorInForm && this.state.sectionDescription === '' ? (
											<p className='text-danger'>Description is required.</p>
										) : null}
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col-sm'>
									<div className='form-group'>
										{!this.state.tagsButtonClicked && this.state.newRuleTags[0].key === '' ? (
											<button
												onClick={() => this.setState({ tagsButtonClicked: true })}
												className='btn btn-sm btn-primary'
											>
												ADD TAGS
											</button>
										) : (
											<>
												<label>Tags</label>
												{this.state.newRuleTags.map((rule, index) => {
													return (
														<div className='row' key={index}>
															<div className='row mb-2 col-sm-7'>
																<div className='col-sm'>
																	<input
																		value={rule.key}
																		onChange={event => {
																			let tags = this.state.newRuleTags
																			tags[index].key = event.target.value
																			this.setState({ newRuleTags: tags })
																		}}
																		type='text'
																		className='form-control form-control-sm'
																		placeholder='Key'
																	/>
																</div>
																:
																<div className='col-sm'>
																	{rule.value_list ? (
																		<select
																			value={rule.value}
																			onChange={event => {
																				let tags = this.state.newRuleTags
																				tags[index].value = event.target.value
																				this.setState({ newRuleTags: tags })
																			}}
																			name={'value@' + rule.id}
																			className='form-control form-control-sm'
																		>
																			<option>Select</option>
																			{rule.value_list.map((item, i) => {
																				return (
																					<option value={item} key={i}>
																						{item}
																					</option>
																				)
																			})}
																		</select>
																	) : (
																		<input
																			value={rule.value}
																			onChange={event => {
																				let tags = this.state.newRuleTags
																				tags[index].value = event.target.value
																				this.setState({ newRuleTags: tags })
																			}}
																			type='text'
																			className='form-control form-control-sm'
																			placeholder='Value'
																		/>
																	)}
																</div>
															</div>
															<div className='col-sm'>
																<div className='d-flex justify-content-between'>
																	<div className='custom-control custom-checkbox'>
																		<CustomInput
																			type='checkbox'
																			label='Regex'
																			id={`regex${index}`}
																			name='regex'
																			onChange={e => this.handleChange(e, index)}
																			checked={this.state.newRuleTags[index].regex === true ? true : false}
																		/>
																	</div>
																	<div className='custom-control custom-checkbox'>
																		<CustomInput
																			type='checkbox'
																			label='Editable'
																			id={`editable${index}`}
																			name='editable'
																			onChange={e => this.handleChange(e, index)}
																			checked={this.state.newRuleTags[index].editable === true ? true : false}
																		/>
																	</div>
																	<div>
																		<i
																			title='Add'
																			onClick={() => this.addNewRuleTags()}
																			className='fal fa-plus-circle p-1 ml-2 text-success cursor-pointer'
																		/>
																		<i
																			title='Delete'
																			onClick={() => this.deleteNewRuleTags(index)}
																			className='fal fa-trash-alt text-danger p-1 cursor-pointer'
																		/>
																	</div>
																</div>
															</div>
														</div>
													)
												})}
											</>
										)}
									</div>
								</div>
							</div>
							{this.state.hasErrorInForm &&
							(this.state.newRuleTags[0].key === '' || this.state.newRuleTags[0].value === '') ? (
								<p className='text-danger'>Tags is required.</p>
							) : null}
						</ModalWrapper>
						{this.props.editTagGroupDetails.tags && this.props.editTagGroupDetails.tags.length ? (
							<div className='row mt-3'>
								<div className='col-12'>
									<div className='box table-responsive rounded overflow-hidden'>
										<table className='table table-sm table-hover'>
											<thead>
												<tr className='bg-light'>
													<th>Section Name</th>
													<th>Description</th>
													<th>Tags</th>
													<th>Regex</th>
													<th>Editable</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												{this.props.editTagGroupDetails.tags &&
													this.props.editTagGroupDetails.tags.map((item, i) => {
														return (
															<tr key={i}>
																<td>{item.section_name}</td>
																<td>{item.description}</td>
																<td>{item.tags ? item.tags.length : 0}</td>
																<td>{this.getRegexCount(item.tags)}</td>
																<td>{this.getEditableCount(item.tags)}</td>
																<td>
																	<span
																		onClick={() =>
																			this.setState({
																				showModal: true,
																				sectionDescription: this.props.editTagGroupDetails.tags[i].description,
																				sectionName: this.props.editTagGroupDetails.tags[i].section_name,
																				newRuleTags: this.props.editTagGroupDetails.tags[i].tags,
																				isEditResource: true,
																				editIndex: i,
																			})
																		}
																	>
																		<i className='fal fa-pen text-primary mr-2 cursor-pointer' />
																	</span>
																	<span onClick={() => this.deleteSection(i)}>
																		<i className='fal fa-trash text-primary cursor-pointer' />
																	</span>
																</td>
															</tr>
														)
													})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						) : null}
						<div className='row'>
							<div className='col-12'>
								<hr />
								{/* <button className='btn btn-link mr-3'>Cancel</button> */}
								<button
									className='btn btn-primary'
									disabled={this.state.showNextLoading}
									onClick={() => this.saveTagGroupDetails()}
								>
									Next {this.state.showNextLoading ? <Spinner size='sm' color='light' className='ml-2' /> : null}
								</button>
								{this.state.hasErrorInTagGroup ? <p className='text-danger pt-2'>Unable to save tag group.</p> : null}
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}

/**
 * Type of the props used in the component
 */
ManageTagGroup.propTypes = {
	onClickNext: PropTypes.func,
	getMasterTagGroups: PropTypes.func,
	setEditTagGroupDetails: PropTypes.func,
	insertTagGroups: PropTypes.func,
	editTagGroupDetails: PropTypes.object,
	governance: PropTypes.object,
	match: PropTypes.object,
	location: PropTypes.object,
	tagGroupId: PropTypes.string,
	masterTags: PropTypes.array,
	updateTagGroups: PropTypes.func,
	getTagGroupsConditionById: PropTypes.func,
	getTagGroupsById: PropTypes.func,
	setTagIdInStore: PropTypes.func,
	showNotification: PropTypes.func,
}

const mapStateToProps = state => {
	return {
		masterTags: state.governance.masterTags,
		editTagGroupDetails: state.governance.editTagGroupDetails,
		tagGroupId: state.governance.tagGroupId,
		governance: state.governance,
	}
}

export default connect(mapStateToProps, {
	getMasterTagGroups,
	setEditTagGroupDetails,
	getTagGroupsById,
	getTagGroupsConditionById,
	updateTagGroups,
	showNotification,
	insertTagGroups,
	setTagIdInStore,
})(withRouter(ManageTagGroup))
