/*************************************************
 * Tvastar
 * @exports
 * @file CostAction.js
 * @author parakash // on 3-02-2023
 * @copyright © 20214Tvastar. All rights reserved.
 *************************************************/
// import cognitoService from '../../services/cognitoService'
import { numberFormater, capitalizeFirstLetter } from '../../utils/utility'
import { API } from 'aws-amplify'

import {
	COST_SERVICE,
	GET_COST_RECOMMENDATIONS,
	GET_POWER_TUNING_ASSETS,
	START_POWER_TUNING,
	GET_POWER_TUNING_RESULTS,

	GET_ANOMALY_TOTAL_COUNT,
	GET_ANOMALY_DAILY_TREND,
	LIST_ANOMALY_RESULTS,
	GET_TOP_ANOMALIES,
	COST_GET_COST_TREND,
	GET_COST_FILTERS,
	GET_COST_FILTER_VALUES,
	GET_COST_TOTAL_COUNT,
	LIST_COST_TEMPLATE,
	SAVE_TEMPLATE,
	COST_COMPARISON,
	GET_BUDGET_DETAILS,
	GET_BUDGET_FILTERS,
	INSERT_BUDGET_DETAILS,
	UPDATE_BUDGET_DETAILS,

	GET_COST_ALL_TAGS_KEYS,
	GET_COST_ALL_TAGS_VALUES,
	LIST_BUDGET_DETAILS
} from '../../config'

import { ACTION_TYPES } from '../types'


/**
 * Action to set cost props details
 *   for cost page
 * @author Prakash // on 13/01/2022
*/
export const setCostPropsDetails = (key, value) => {
	return async (dispatch, getState) => {
		let costPropsDetails = JSON.parse(JSON.stringify(getState().cost.costPropsDetails))
		costPropsDetails[key] = value
		dispatch({
			type: ACTION_TYPES.SET_COST_PROPS_DETAILS,
			payload: costPropsDetails,
		})
	}
}

/**
 * Action to get-cost-recommendations
 * @param {Object} body
 * @param {Function} callback
 */
export const getCostRecommendations = (body, callback) => {
	return async () => {
		await API.post(COST_SERVICE, GET_COST_RECOMMENDATIONS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to get-power-tuning-assets
 * @param {Object} body
 * @param {Function} callback
 */
export const getPowerTuningAssets = (body, callback) => {
	return async () => {
		await API.post(COST_SERVICE, GET_POWER_TUNING_ASSETS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to start-power-tuning
 * @param {Object} body
 * @param {Function} callback
 */
export const startPowerTuning = (body, callback) => {
	return async () => {
		await API.post(COST_SERVICE, START_POWER_TUNING, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to get-power-tuning-results
 * @param {Object} body
 * @param {Function} callback
 */
export const getPowerTuningResults = (body, callback) => {
	return async () => {
		await API.post(COST_SERVICE, GET_POWER_TUNING_RESULTS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to get-anomaly-total-count
 * @param {Object} body
 * @param {Function} callback
 */
export const getCostAnomalyTotalCount = (body, callback) => {
	return async () => {
		await API.post(COST_SERVICE, GET_ANOMALY_TOTAL_COUNT, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to get-anomaly-daily-trend
 * @param {Object} body
 * @param {Function} callback
 */
export const getCostAnomalyDailyTrend = (body, callback) => {
	return async () => {
		await API.post(COST_SERVICE, GET_ANOMALY_DAILY_TREND, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to list-anomaly-results
 * @param {Object} body
 * @param {Function} callback
 */
export const listCostAnomalyResults = (body, callback) => {
	return async () => {
		await API.post(COST_SERVICE, LIST_ANOMALY_RESULTS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to get-top-anomalies
 * @param {Object} body
 * @param {Function} callback
 */
export const getCostTopAnomalies = (body, callback) => {
	return async () => {
		await API.post(COST_SERVICE, GET_TOP_ANOMALIES, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to get-cost-trend
 * @param {Object} body
 * @param {Function} callback
 */
export const costGetCostTrend = (body, callback) => {
	return async () => {
		await API.post(COST_SERVICE, COST_GET_COST_TREND, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to get-cost-filters
 * @param {Object} body
 * @param {Function} callback
 */
export const getCostFilters = (body, callback) => {
	return async () => {
		await API.post(COST_SERVICE, GET_COST_FILTERS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to get-cost-filter-values
 * @param {Object} body
 * @param {Function} callback
 */
export const getCostFilterValues = (body, callback) => {
	return async () => {
		await API.post(COST_SERVICE, GET_COST_FILTER_VALUES, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to get-cost-total-count
 * @param {Object} body
 * @param {Function} callback
 */
export const getCostTotalCount = (body, callback) => {
	return async () => {
		await API.post(COST_SERVICE, GET_COST_TOTAL_COUNT, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to list-templates
 * @param {Object} body
 * @param {Function} callback
 */
export const listCostTemplates = (body, callback) => {
	return async () => {
		await API.post(COST_SERVICE, LIST_COST_TEMPLATE, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to list-templates
 * @param {Object} body
 * @param {Function} callback
 */
export const saveTemplate = (body, callback) => {
	return async () => {
		await API.post(COST_SERVICE, SAVE_TEMPLATE, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to cost-comparison
 * @param {Object} body
 * @param {Function} callback
 */
export const costComparison = (body, callback) => {
	return async () => {
		await API.post(COST_SERVICE, COST_COMPARISON, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to get-budget-details
 * @param {Object} body
 * @param {Function} callback
 */
export const getBudgetDetails = (body, callback) => {
	return async () => {
		await API.post(COST_SERVICE, GET_BUDGET_DETAILS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to get-budget-filters
 * @param {Object} body
 * @param {Function} callback
 */
export const getBudgetFilters = (body, callback) => {
	return async () => {
		await API.post(COST_SERVICE, GET_BUDGET_FILTERS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to insert-budget-details
 * @param {Object} body
 * @param {Function} callback
*/
export const insertBudgetDetails = (body, callback) => {
	return async () => {
		await API.post(COST_SERVICE, INSERT_BUDGET_DETAILS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to update-budget-details
 * @param {Object} body
 * @param {Function} callback
*/
export const updateBudgetDetails = (body, callback) => {
	return async () => {
		await API.post(COST_SERVICE, UPDATE_BUDGET_DETAILS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to get-all-tags-keys
 * @param {Object} body
 * @param {Function} callback
*/
export const getCostAllTagsKeys = (body, callback) => {	
	return async dispatch => {
		try {
			const response = await API.post(COST_SERVICE, GET_COST_ALL_TAGS_KEYS, { body })
			dispatch({
				type: ACTION_TYPES.SET_COST_TAG_KEYS,
				payload: response,
			})
			callback(true, response)
		} catch (error) {
			// myLog('response', error)
			callback(false)
		}
	}
}

/**
 * Action to get-all-tags-values
 * @param {Object} body
 * @param {Function} callback
*/
export const getCostAllTagsValues = (body, callback) => {
	return async () => {
		try {
			const response = await API.post(COST_SERVICE, GET_COST_ALL_TAGS_VALUES, { body })
			//let aggreateBy = body.aggregate_by
			//const covertToMapObject = await convert_to_map(searchInputs, totalAssetCount)
			callback(true, response)
		} catch (error) {
			// console.log(error)
			callback(false)
		}
	}
}

/**
 * Action to list-budget-details
 * @param {Object} body
 * @param {Function} callback
*/
export const listBudgetDetails = (body, callback) => {
	return async () => {
		try {
			const response = await API.post(COST_SERVICE, LIST_BUDGET_DETAILS, { body })
			//let aggreateBy = body.aggregate_by
			//const covertToMapObject = await convert_to_map(searchInputs, totalAssetCount)
			callback(true, response)
		} catch (error) {
			// console.log(error)
			callback(false)
		}
	}
}