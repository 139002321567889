/*************************************************
 * Tvastar
 * @exports
 * @file remediationAction.js
 * @author Prakash
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
// import cognitoService from '../../services/cognitoService'
import { myLog, numberFormater, capitalizeFirstLetter } from '../../utils/utility'
import { API } from 'aws-amplify'
import axios from 'axios'

import {
	REMEDIATION,
	GET_REMEDIATION_COUNT,
	GET_REMEDIATION_STATUS,
	GET_REMEDIATION_DETAILS,
	GET_ARTIFACT_DETAILS,
	LIST_REMEDIATIONS,
	START_REMEDIATIONS,
	GET_TOTAL_REMEDIATION_COUNT,

	AIOPS_SERVICE,
	GET_AIOPS_REMEDIATION_TREND,

	//remediation parameters
	COMMON_SERVICES,
	GET_VM_TYPES,

	PRICING_END_POINT,
	PRICING_LIST_VMS,

	SYNCHRONIZER,
	GET_ASSET_DETAIL_EXT,
	//remediation parameters end

} from '../../config'
import { ACTION_TYPES } from '../types'



/**
 * Action to get Remediation Count
 * @param {Object} body
 * @param {Function} callback
 */
export const getRemediationCount = (body, callback) => {
	return async dispatch => {
		try {
			const remediationCount = await API.post(REMEDIATION, GET_REMEDIATION_COUNT, { body })
			dispatch({
				type: ACTION_TYPES.SET_REMEDIATION_COUNT,
				payload: remediationCount,
			})
			// console.log(response);
			callback(true, remediationCount)
		} catch(err) {
			// let message = ''
			dispatch({
				type: ACTION_TYPES.SET_REMEDIATION_COUNT,
				payload: {},
			})
			callback(false, {})
		}
	}
}

/**
 * Action to get Total Remediation Count
 * @param {Object} body
 * @param {Function} callback
 */
export const getTotalRemediationCount = (body, callback) => {
	return async dispatch => {
		try {
			const remediationTotalCount = await API.post(REMEDIATION, GET_TOTAL_REMEDIATION_COUNT, { body })
			let result = {}
			if(remediationTotalCount && remediationTotalCount.length) {
				result = getRemediationGraph(remediationTotalCount)
			}
			dispatch({
				type: ACTION_TYPES.SET_TOTAL_REMEDIATION_COUNT,
				payload: result,
			})
			callback(true, result)
		} catch(err) {
			// let message = ''
			dispatch({
				type: ACTION_TYPES.SET_TOTAL_REMEDIATION_COUNT,
				payload: {},
			})
			callback(false, {})
		}
	}
}

const getRemediationGraph = (remediationTotalCount) => {
	let donutSeries = []
	let labels = []
	let overAll = 0
	let overAllCount = 0
	remediationTotalCount.forEach(item => {
		donutSeries.push(item.total_count)
		labels.push(item.invocation_type)
		overAll += item.total_count
		overAllCount += 1
	})


	let total = {}

	if(overAllCount > 1) {
		total['show'] = true
		total['label'] = 'OverAll'
		total['fontSize'] = '10px'
		total['formatter'] = function (w) {
			return overAll
		}
	}

	let donutOptions = {
		stroke: {
			width: 0
		},
		chart: {
				type: 'donut',
		},
		legend: {
			show: true,
		},
		dataLabels: {
				enabled: false
		},
		fill: {
			type: 'gradient',
		},
		labels: labels,
		colors: ['#08B089', '#39BFA0', '#4A397C', '#6E6096', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'],
		tooltip: {
			enabled: false,
		},
		plotOptions: {
			pie: {
				donut: {
					size: '80%',
					labels: {
						show: true,
						value: {
							fontSize: '10px',
							fontWeight: 700,
							offsetY: 0,
							formatter: function (val) {
								return numberFormater(val)
							}
						},
						name: {
							fontSize: '10px',
							offsetY: -2,
							formatter: function (val) {
								return capitalizeFirstLetter(val)
							}
						},	
						total:  total
					}
				},      
			}
		},
	}

	let graph = {}
	graph.series = donutSeries
	graph.options = donutOptions

	return graph
}

/**
 * Action to get Remediation Count
 * @param {Object} body
 * @param {Function} callback
 */
export const getAiopsRemediationTrend = (body, callback) => {
	return async dispatch => {
		try {
			const remediationTrend = await API.post(AIOPS_SERVICE, GET_AIOPS_REMEDIATION_TREND, { body })
			dispatch({
				type: ACTION_TYPES.SET_AIOPS_REMEDIATION_TREND,
				payload: remediationTrend,
			}) 
			callback(true, remediationTrend)
		} catch(err) {
			callback(false, {})
		}
	}
}

/**
 * Action to get Remediation Status
 * @param {Object} body
 * @param {Function} callback
 */
export const getRemediationStatus = (body, callback) => {
	return async dispatch => {
		try {
			const remediationStatus = await API.post(REMEDIATION, GET_REMEDIATION_STATUS, { body })
			dispatch({
				type: ACTION_TYPES.SET_REMEDIATION_STATUS,
				payload: remediationStatus,
			})
			// console.log(response);
			callback(true, remediationStatus)
		} catch(err) {
			// let message = ''
			dispatch({
				type: ACTION_TYPES.SET_REMEDIATION_STATUS,
				payload: {},
			})
			callback(false, {})
		}
    }
}

/**
 * Action to get Remediation Details
 * @param {Object} body
 * @param {Function} callback
 */
export const getRemediationDetails = (body, callback) => {
	return async dispatch => {
		try {
			const remediationDetails = await API.post(REMEDIATION, GET_REMEDIATION_DETAILS, { body })
			dispatch({
				type: ACTION_TYPES.SET_REMEDIATION_DETAILS,
				payload: remediationDetails,
			})
			// console.log(response);
			callback(true, remediationDetails)
		} catch (error) {
			dispatch({
				type: ACTION_TYPES.SET_REMEDIATION_DETAILS,
				payload: {},
			})
			callback(false, {})
		}
    }
}

/**
 * Action to get Remediation Details
 * @param {Object} body
 * @param {Function} callback
 */
export const getArtifactDetails = (body, callback) => {
	return async dispatch => {
		try {
			const artifactDetails = await API.post(REMEDIATION, GET_ARTIFACT_DETAILS, { body })
			dispatch({
				type: ACTION_TYPES.SET_ARTIFACT_DETAILS,
				payload: artifactDetails,
			})
			callback(true, artifactDetails)
		} catch (error) {
			dispatch({
				type: ACTION_TYPES.SET_ARTIFACT_DETAILS,
				payload: {},
			})
			callback(false, {})
		}
    }
}

/**
 * Action to list Remediation
 * @param {Object} body
 * @param {Function} callback
 */
export const listRemediation = (body, callback) => {
	return async () => {
		await API.post(REMEDIATION, LIST_REMEDIATIONS, { body })
		.then((response) => {
			// console.log(response);
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to start Remediation
 * @param {Object} body
 * @param {Function} callback
 */
export const startRemediation = (body, callback) => {
	return async () => {
		await API.post(REMEDIATION, START_REMEDIATIONS, { body })
		.then((response) => {
			// console.log(response);
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to GET_ASSET_DETAIL_EXT
 * @param {Object} body
 * @param {Function} callback
 */
 export const getAssetDetailExt = (body, callback) => {
	return async () => {
		await API.post(SYNCHRONIZER, GET_ASSET_DETAIL_EXT, { body })
		.then((response) => {
			// console.log(response);
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to get vm types
 * @param {Object} body
 * @param {Function} callback
 */
 export const getVmTypes = (body, callback) => {
	return async () => {
		await API.post(COMMON_SERVICES, GET_VM_TYPES, { body })
		.then((response) => {
			// console.log(response);
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to get vm types
 * @param {Object} body
 * @param {Function} callback
 */
export const listVms = (body, callback) => {
	
	return async dispatch => {
		try {
			const url = PRICING_END_POINT + PRICING_LIST_VMS;
			const response = await axios.post(url, body)
			// dispatch({
			// 	type: ACTION_TYPES.SET_GOVERNANCE_SIZING_EC2,
			// 	payload: response
			// })
			callback(true, response)
		} catch (error) {
			myLog('exception', error)
			// dispatch({
			// 	type: ACTION_TYPES.SET_GOVERNANCE_SIZING_EC2,
			// 	payload: {}
			// })
			callback(false, '')
		}
	}
}
	
	