/*************************************************
 * Tvastar
 * @exports
 * @file SidePanel.js
 * @author Prakash // on 28/06/2020
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import { capitalizeFirstLetter, momentConvertionUtcToLocalTime } from '../../utils/utility'
import { Spinner } from 'reactstrap'
import {  closeCase } from '../../actions/aiops/AiopsAction'

import { store as CommonNotification } from 'react-notifications-component';

class CloseSidePanel extends Component {
	constructor(props) {
		super(props)
		this.props = props;
		this.state = {
			activeTab: 0,
			showLoading: false,
			caseId: this.props.caseId,
		}
	}

	componentDidMount = () => {
		document.addEventListener('keydown', this._handleKeyDown)

		let filteredListCaseDetails = {}
		if(this.props.aiopsListCaseDetails.length) {
			let filter = this.props.aiopsListCaseDetails.filter(arr => arr.case_id === this.props.caseDetails.case_id)
			if(filter.length) {
				filteredListCaseDetails = filter[0]
			}
		}
		this.setState({ filteredListCaseDetails })
	}

	_handleKeyDown = event => {
		switch (event.keyCode) {
			case 27:
				this.props.closeSidePanel()
				break
			default:
				break
		}
	}

	onClickCloseCase = (caseId) => {
		if(caseId) {
			let hasError = false

			let caseIdMessage = ''
			if(!this.state.enteredcaseId || this.state.enteredcaseId === '') {
				hasError = true
				caseIdMessage = 'Please enter Case ID'
			} else if(this.state.enteredcaseId !== caseId) {
				hasError = true
				caseIdMessage = 'Please check your case Id and try again'
			}

			let reasonMessage = ''
			// if(!this.state.close_reason || this.state.close_reason === '') {
			// 	hasError = true
			// 	reasonMessage = 'Please enter reason to close this case'
			// } else if(this.state.close_reason.length < 10) {
			// 	hasError = true
			// 	reasonMessage = 'Please enter minimum 10 characters to close this case'
			// }
			this.setState({ hasError, caseIdMessage, reasonMessage, showLoading: hasError ? false : true })
			if(!hasError) {
				let params = {}
				params.case_id = caseId
				params.close_reason = this.state.close_reason
				// this.setTimeoutFunction(caseId)
				this.props.closeCase(params, (promise, result) => {
					if(promise) {
						let messageType = 'danger'		
						if(result.status) {
							messageType = 'success'
						}
						let message = result.message
						CommonNotification.addNotification({
							message: message,
							type: messageType,
							insert: "top",
							container: "top-center",
							dismiss: {
								duration: 5000,
								onScreen: false,
								pauseOnHover: true,
								showIcon: true,
							}
						});
						if(result.status) {
							this.setState({ showLoading: false },
								() => {
									this.props.onCaseClose(caseId)
									setTimeout(() => { this.props.closeSidePanel() }, 5000)
								}
							)
						} else {
							this.setState({ showLoading: false })
						}
					} else {
						this.setState({ showLoading: false })
					}
				})	
			}
		}
	}

	render() {
		return (
			<div className="advanced-search" style={{zIndex:9999999}}>
				<div className="search-content overflow-auto bg-muted">
					<div className="header-search bd-highlight justify-content-between">
						<div className="flex-fill bd-highlight">
							<h5>Close Case</h5>
						</div>
						<div className="flex-fill bd-highlight ml-3 align-self-center cursorPointer text-right">
							<i className="far fa-times" onClick={() => this.props.closeSidePanel()}></i>
						</div>
					</div>
					<div className="col-sm-12 mt-3">
						<h6 className="text-info">{this.props.caseDetails.title}</h6>
						<p>To close the case, please enter the case id (<span className="font-weight-bold">{this.props.caseDetails.case_id}</span>) in box below to confirm the action</p>
						<div className="mt-3 mb-3">
							<div className="w-100">
								<input 
									type="text" 
									readOnly={this.state.showLoading}
									className="form-control bg-gray3 border-black6"
									onChange={(event) => this.setState({ enteredcaseId:  event.target.value })} 
									value={this.state.enteredcaseId}
								/>
								
								{this.state.hasError && this.state.caseIdMessage && this.state.caseIdMessage !== '' ? 
									<p className='m-0 text-danger align-self-end mb-1'>{this.state.caseIdMessage}</p>
								: null}
							</div>
							<div className="w-100 mt-2">
								<p className="b-block mb-0">Reason:</p>
								<textarea
									readOnly={this.state.showLoading}
									className="form-control bg-gray3 border-black6"
									onChange={(event) => this.setState({ close_reason: event.target.value })} 
									value={this.state.close_reason}
									maxLength={10}
								/>
								{this.state.hasError && this.state.reasonMessage && this.state.reasonMessage !== '' ? 
									<p className='m-0 text-danger align-self-end mb-1'>{this.state.reasonMessage}</p>
								: null}
							</div>
							<div className="d-flex justify-content-end mt-2">
								{!this.state.showLoading ? 
										// ${this.state.enteredcaseId === this.props.caseDetails.case_id ? '' : 'disabled'}
									<button className={`ml-2 btn btn-primary text-white`} type="button" onClick={() => this.onClickCloseCase(this.props.caseDetails.case_id) }>Close</button>
								: 
									<button className={`btn btn-light text-dark disabled`}>
										<Spinner size='sm' className='mx-2 text-dark' /> Closing
									</button>
								}				
							</div>
						</div>
						<div className='mt-3'>
							<p className="mb-0 f16 text-white mb-1">Case Details</p>
							<p className="mb-1 f12">{Array.isArray(this.props.caseDetails.description) ?  this.props.caseDetails.description.join(", ") : capitalizeFirstLetter(this.props.caseDetails.description)}</p>
							<div className="p-3 bg-dark rounded">
								<div className="d-flex mb-1 border-bottom">
									<div className="py-1 w-50">
										<p className="b-block mb-0">Case ID:</p>
										<p className="mb-0 text-white">{this.props.caseDetails.case_id}</p>
									</div>
									<div className="py-1 w-50 pl-3">
										<p className="b-block mb-0">Created Date:</p>
										<p className="mb-0 text-white">{this.props.caseDetails.created_at && this.props.caseDetails.created_at !== ''  ? momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'DD MMM YYYY HH:ss') : ''}</p>
									</div>
								</div>
								<div className="d-flex mb-1 border-bottom">
									<div className="py-1 w-50">
										<p className="b-block mb-0">Severity:</p>
										<p className={`mb-0 badge ${this.props.caseDetails && this.props.caseDetails.severity ? (this.props.caseDetails.severity.toLowerCase() !== 'warning' ? 'risk-badge-'+this.props.caseDetails.severity.toLowerCase() :  'badge-warning  text-white') : ''} `}>{this.props.caseDetails && this.props.caseDetails.severity ? this.props.caseDetails.severity : ''}</p>
									</div>
									<div className="py-1 w-50 pl-3">
										<p className="b-block mb-0">Case Status:</p>
										<p className="mb-0 text-white">{this.props.caseDetails.status && this.props.caseDetails.status !== ''  ? this.props.caseDetails.status : ''}</p>
									</div>
								</div>
								<div className="d-flex mb-1 border-bottom">
									<div className="py-1 w-50">
										<p className="b-block mb-0">Provider / Account /Region:</p>
										<p className="mb-0 text-white">
											{this.props.caseDetails.provider ? (Array.isArray(this.props.caseDetails.provider) ? this.props.caseDetails.provider[0].toUpperCase() : this.props.caseDetails.provider.toUpperCase()) : ''}

											{this.props.caseDetails.account_id ? (' : ')+(Array.isArray(this.props.caseDetails.account_id) ? this.props.caseDetails.account_id[0] : this.props.caseDetails.account_id) : ''}

											{this.props.caseDetails.region ? (' : ')+(Array.isArray(this.props.caseDetails.region) ? this.props.caseDetails.region[0] : this.props.caseDetails.region) : ''}
										</p>
									</div>
									<div className="py-1 w-50 pl-3">
										<p className="b-block mb-0">No of Assets:</p>
										<p className="mb-0 text-white">{this.state.filteredListCaseDetails && this.state.filteredListCaseDetails.total_assets ? this.state.filteredListCaseDetails.total_assets : ''}</p>
									</div>											
								</div>
								<div className="d-flex mb-1 border-bottom">
									<div className="py-1 w-50">
										<p className="b-block mb-0">No of Alerts:</p>
										<p className="mb-0 text-white">{this.state.filteredListCaseDetails && this.state.filteredListCaseDetails.total_alerts ? this.state.filteredListCaseDetails.total_alerts : ''}</p>
									</div>
									<div className="py-1 w-50 pl-3">
										<p className="b-block mb-0">No of Events:</p>
										<p className="mb-0 text-white">{this.state.filteredListCaseDetails && this.state.filteredListCaseDetails.total_events ? this.state.filteredListCaseDetails.total_events : ''}</p>
									</div>
								</div>
								<div className="d-flex">
									<div className="py-1 w-100">
										<p className="b-block mb-0">Service:</p>
										<p className="mb-0 text-white">{this.props.caseDetails.services_impacted ? this.props.caseDetails.services_impacted.join(', ') : ''}</p>
									</div>
									{/* <div className="py-1 w-50 pl-3">
										<p className="b-block mb-0">No of Alerts:</p>
										<p className="mb-0 text-white">{this.props.caseDetails.status && this.props.caseDetails.status !== ''  ? this.props.caseDetails.status : ''}</p>
									</div> */}
								</div>
							</div>
						</div>				
					</div>
				</div>
			</div>
		)
	}
}
	
/**
 * Type of the props used in the component
 */
CloseSidePanel.propTypes = {
	closeCase: PropTypes.func,
}

const mapStateToProps = state => {
	// console.log('Aiops close',state)
	return {
		caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : {},
		aiopsListCaseDetails: state.aiops.aiopsPropsDetails.aiopsListCaseDetails ? state.aiops.aiopsPropsDetails.aiopsListCaseDetails : [],
	}
}

export default connect(mapStateToProps, { 
	closeCase 
})(withRouter(CloseSidePanel))



