/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Dashboard.js
 * @author Prakash // on 05/06/2020
 * @copyright © 2020 Tvastar. All rights reserved.
 **************************************************/
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { URL_PATH } from '../../../config/urlPath'
import { UncontrolledTooltip, UncontrolledPopover, PopoverBody, Spinner } from 'reactstrap'
import _ from 'lodash'
import { AppWrapper } from '../../common/AppWrapper'

import { capitalizeFirstLetter, numberFormater, getAccountNameFromId, momentDateGivenFormat } from '../../../utils/utility'

import { listAllProviders, listAllAccounts, listAllServices, getVariousCountsByAccount } from '../../../actions/commonAction'
import {
	getTotalCount,
	getDayWiseCount
} from '../../../actions/detectors/detectorsAction'
import {
	getScore,
	getBillingCount,
	getDayWiseCountTags,
	getIssueStatusCount,
	getStatusCount,
	getCount,
	getCostTrend,
	getDailyScore,
	getComplianceCoverage
} from '../../../actions/detectors/checksDashboardAction'
import { getTransitionTrend } from '../../../actions/detectors/complianceDashboardAction'

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { Doughnut, Line } from 'react-chartjs-2';
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

import SearchWithHiddenInput from '../../common/SearchWithHiddenInput'

import Chart from 'react-apexcharts'
import ApexRiskStackedBarChart from '../../common/charts/ApexRiskStackedBarChart'
import ApexAreaChart from '../../common/charts/ApexAreaChart'

const environemntDropdown = [
	// {label:"All", value:"All"},
	{label:"Environment", value:"Environment"},
	{label:"Application", value:"Application"},
	{label:"Cost Centre", value:"Cost Centre"}
]

class Dashboard extends Component {
	p = 0;
	sliderWrap = React.createRef()
	sliderItemWrap = React.createRef()
	proEnvWrap = React.createRef()
	constructor(props) {
		super(props)		
		this.searchComponent = React.createRef()
		this.tabRef = React.createRef()		
		this.envRef = React.createRef()
		this.serviceWiseRef = React.createRef()

		this.state = {
			minMaxHeight: "770px",
			left: 0,
			showLoading: true,
			providers: [],
			selectedProvider: "",

			accounts: [],
			selectedAccount: "",
			accountsNew: [],

			services: [],
			parentServices: [],
			selectedService: [],
						
			selectedDuration: "+30d",
			
			selectedCategory: [],

			serviceNameCount:[],
			complianceIdCounts: [],	
			
			score:[],
			dayWiseCountTags:[],
			categoryDayWiseCount: [],

			issueStatusCount:[],
			percentStatusCount: [],
			
			cost_pass_day_wise_count_data: [],
			cost_pass_day_wise_count_options: [],

			dayWiseCountGraphData: {},
			
			detectorKey:"",
			envDropIsOpen: false,
			selectedTag: "Environment",
			bar_data:[], 
			bar_option:[],
			doughnut_data:[],
			doughnut_options:[],
			billingCount:[],
			processCount:[],

			totalSum: 0,
			passSum:0,
			failSum:0,
			
			dayWiseRiskCountGraphData:{},
			
			complianceCategoryScore: [],
			categoryScoreList: [],
			dailyCategoryScore: [],
			
			categoryList: [],

			costWiseTrendData: [],
			costSum: 0,

			//for next and previous week record
			showPrevNextRecord: 0,
			daysShownInGraph: 7,
			graphRecordsRemainigToShow: 0,
			disableNextWeekBtn: false,
			disablePreviousWeekBtn: true,
			hidePreviousNextBtn: false,

			transitionTrend: [],

			regionWiseCount: [],

			totalItemShowing: 0,
			showingRightIcon: true,
			showingOffStart: 1,
			showingOff: 0,
			serviceWiseStart: 0,
			serviceWiseheight: 13
			
		}
	}

	componentDidMount = () => {
		if(this.props.providers && this.props.providers.length) {
			this.setState({ providers: this.props.providers, selectedProvider: this.props.providers[0].provider_name },
				() => {
					this.filterIdentifiers("provider")
				}
			)
		} else {
			this.props.listAllProviders((promise, providers) => {
				if (promise) {
					this.setState({ providers, selectedProvider: providers.length ? providers[0].provider_name : "" },
						() => {
							this.filterIdentifiers("provider")
						}
					)
				} else {
					this.setState(
						{ providers: [],  }, 
						() => {
							this.filterIdentifiers("")
						}
					)
				}
			})
		}
	}
	
	/**
	 * Filter identifiers based on the filter selection
	 */
	filterIdentifiers = from => {
		if(from === "envDrop") {
			this.setState({ envDropIsOpen: false })
		}
		if (this.props.location && this.props.location.state && this.props.location.state && this.state.selectedAccount === "" && this.state.selectedAccount !== "All") {
			this.setState({
				selectedAccount: this.props.location.state.selectedAccount,
				selectedProvider: this.props.location.state.selectedProvider,
				selectedDuration: this.props.location.state.selectedDuration
			})
		}
		
		this.onChangeProvider()

		this.onSearch("onLoad")
	
		
		this.getWindowSize()
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 120
		let outerHeight = (window.outerHeight - browserBorder - headerHeight) +"px"

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }

	onChangeProvider = () => {
		if (this.state.selectedProvider && this.state.selectedProvider !== "") {			
			let params = {}
			params.provider = this.state.selectedProvider.toLowerCase()

			if(this.props.accounts && this.props.accounts.length) {
				let allAccounts = this.props.accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
				if(this.props.accounts.length) {
					let accRow = {}
					accRow.account_id = "All"
					accRow.account_name = "All"
					accRow.date = null
					accRow.disabled = false
					accRow.provider = "All"
					allAccounts.unshift(accRow);
				}
				this.setState({ accounts: allAccounts })
			} else {
				this.props.listAllAccounts(params, (promise, allAccounts) => {
					if (promise) {						
						let filterdAccounts = allAccounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
						if(filterdAccounts.length) {
							let accRow = {}
							accRow.account_id = "All"
							accRow.account_name = "All"
							accRow.date = null
							accRow.disabled = false
							accRow.provider = "All"
							filterdAccounts.unshift(accRow);
						}						
						this.setState({ accounts: filterdAccounts })
					}
				})
			}

			this.getVariousCountsByAccount()
		}
	}

	onClickAccFunction = (accountId) => {
		this.setState({ selectedAccount: this.state.selectedAccount !== accountId ? accountId : "" },
			() => this.onSearch()
		)
	}

	onSearch = (type) => {
		if(type === "onLoad") {
			this.getAccountScore()
		}

		this.setState({ dayWiseRiskCountGraphData: {}, new_trend_data: {}, pending_trend_data: {}, resolved_trend_data: {}, reoccured_trend_data: {}, resetEnabled: type !== "onLoad" ? true : false }, 
			() => {
				this.getDayWiseRiskCount();
				this.getTransitionTrend()
			}	
		)
		
		this.getScore();
		this.getScore("account");
		this.getDailyScore();
		this.getBillingCount();
		this.getIssueStatusCount();
		this.getDayWiseCountTags()
		this.getStatusCount();
		this.getCount();
		this.getServiceCount();
		this.getDayWiseCount();
		this.getComplianceCoverage()

		setTimeout(() => { this.setState({ showLoading: false }) }, 5000)
	}	

	onReset = () => {
		this.setState({
			selectedProvider: this.state.providers && this.state.providers.length ? this.state.providers[0].provider_name : "",
			selectedAccount: "",
			selectedDuration: "+30d",
			showLoading: this.state.resetEnabled,
		},
			() => {
                if(this.state.resetEnabled) {
                    this.onSearch("onLoad")
                }
            }
		)
	}

	getVariousCountsByAccount = () => {
		let params = {}
		if(this.state.selectedProvider && this.state.selectedProvider !== "") {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		params.duration = this.state.selectedDuration
		this.props.getVariousCountsByAccount(params, (promise, response) => {
			if (promise) {
				let results = []
				if(response.length) {
					response.forEach(item => {
						if(item.account_id) {
							item.account_name = getAccountNameFromId(item.account_id, this.state.accounts)
						}
						results.push(item)
					})
				}
				this.setState({ accountsNew: results, filteredAccount: results })
			}
		})

	}

	getAccountScore = () => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		params.group_by = ["account_id"]
		params.score_category = ["ALL"]
		this.props.getScore(params, (promise, accScore) => {
			if (promise) {
				let obj = {}
				accScore.forEach(item => {
					if (this.state.selectedAccount !== "" && this.state.selectedAccount !== "All") {
						obj[item.region] = item
					} else {
						obj[item.account_id] = item
					}
				})
				this.setState({ accScore: obj })
			} else {
				this.setState({ accScore: {} })
			}
			
		})
	}

	getServiceCount = () => {
		let params = {}
		if (this.state.selectedProvider !== "" && this.state.selectedProvider !== "All") {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if (this.state.selectedAccount !== "" && this.state.selectedAccount !== "All") {
			params.account_id = this.state.selectedAccount
		}
		if(this.state.selectedService.length) {
			params.resource_type = this.state.selectedService
		}
		params.duration = this.state.selectedDuration
		params.aggregate_by = ["service_name", "risk"]
		this.props.getTotalCount(params, (promise, serviceWiseCount) => {
			if (promise) {
				let checkRiskArray = []
				
				if(serviceWiseCount.length) {
					_.chain(serviceWiseCount)
					.groupBy("service_name")
					.map((value, key) => ({ label: key, sub: value }))
					.value()
					.forEach(( cat, i) => {
						let dataRow = {}
						let totalFail = 0
						dataRow.name = cat.label
						cat.sub.forEach(item=> {
							totalFail = totalFail + item.fail_count 
							if(item.risk === "Low") {
								dataRow.Low = item.fail_count 
							}
							if(item.risk === "Medium") {
								dataRow.Medium = item.fail_count 
							}
							if(item.risk === "High") {
								dataRow.High = item.fail_count 
							}
							if(item.risk === "Critical") {
								dataRow.Critical = item.fail_count 
							}
						})

						dataRow.totalFail = totalFail
						if(dataRow.Low === undefined) {
							dataRow.Low = 0
						}
						if(dataRow.Medium === undefined) {
							dataRow.Medium = 0
						}
						if(dataRow.High === undefined) {
							dataRow.High = 0
						}
						if(dataRow.Critical === undefined) {
							dataRow.Critical = 0
						}
						checkRiskArray.push(dataRow)				
					})
				}
				
				checkRiskArray = checkRiskArray.filter(risk => risk.totalFail > 0)
				checkRiskArray = _.orderBy(checkRiskArray, ["totalFail"], ["desc"])

				let filterServiceCountLength = checkRiskArray.length;
				if(checkRiskArray.length > this.state.serviceWiseheight) {
					filterServiceCountLength = this.state.serviceWiseheight
				}
				this.setState({ serviceWiseStart: 0, serviceWiseCount: checkRiskArray, filterServiceCount : checkRiskArray, filterServiceCountLength, serviceLoading: false })
			} else {
				this.setState({ serviceWiseStart: 0, filterServiceCountLength: this.state.serviceWiseheight, serviceWiseCount: [], filterServiceCount: [], serviceLoading: false })
			}
		})
	}
	
	handleClickOutside(event) {
		if (this.accountRef && !this.accountRef.current.contains(event.target)) {
			this.setState({ isAccountOpen: false })
		} else {
			this.setState({ isAccountOpen: true })
		}
		
		if (this.envRef && !this.envRef.current.contains(event.target)) {
			this.setState({ envDropIsOpen: false })
		} else {
			this.setState({ envDropIsOpen: true })
		}
		if (this.serviceWiseRef && !this.serviceWiseRef.current.contains(event.target)) {
			this.setState({ serwiseDropdown: false })
		} else {
			this.setState({ serwiseDropdown: true })
		}
	}
	
	getBillingCount = () =>{
		// let params = {}
		// params.provider = this.state.selectedProvider
		// params.duration = this.state.selectedDuration
		// if (this.state.selectedAccount !== "" && this.state.selectedAccount !== "All") {
		// 	params.account_id = this.state.selectedAccount
		// }
		// this.props.getBillingCount(params, (promise, billingCount) => {
		// 	if (promise) {
		// 		this.setState({ billingCount }, 
		// 			//() => this.getDayWiseCountTags() 
		// 		)
		// 	} else {
		// 		this.setState({ billingCount: [] })
		// 	}
		// 	this.getDayWiseCountTags() 
		// })
	}

	getScore = account => {
		let params = {}
		
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		if (this.state.selectedAccount !== "" && this.state.selectedAccount !== "All") {
			params.account_id = this.state.selectedAccount
		} 
		if(account === undefined || account === "") {
			params.score_category = ["overall"]
			params.group_by =["tags"]
			params.tags =[{"key":this.state.selectedTag}]
		} else {
			params.score_category = ["ALL"]
			//params.duration = "+10d"
		}

		this.props.getScore(params, (promise, score) => {			
			if (promise) {
				if(account === undefined || account === "") {
					if(score[0] !== undefined &&score[0].tags !== undefined) {
						this.setState({ score, detectorKey: score[0].tags[0].value })
						this.getCostTrend()
					} else {
						this.setState({score: [], detectorKey: [] })
						this.getCostTrend()
					}
				} else {
					let catArray = []
					let secCategoty = {}
					let availCategoty = {}
					let goverCategoty = {}
					let operCategoty = {}
					let tagCategoty = {}
					if(score[0] !== undefined && (score[0].identifier_category !== null || score[0].identifier_category !== undefined)) {
						Object.entries(score[0].identifier_category).forEach(([key, value]) => {
							if(key === "Security") {
								secCategoty.category = key
								secCategoty.data = value.split("/")[0]
								secCategoty.orderBy = 0
							}
							
							if(key === "Governance") {
								goverCategoty.category = key
								goverCategoty.data = value.split("/")[0]
								goverCategoty.orderBy = 1
							}

							if(key === "Availability") {
								availCategoty.category = key
								availCategoty.data = value.split("/")[0]
								availCategoty.orderBy = 2
							}

							if(key === "Operations") {
								operCategoty.category = key
								operCategoty.data = value.split("/")[0]
								operCategoty.orderBy = 3
							}

							if(key === "Tagging") {
								tagCategoty.category = key
								tagCategoty.data = value.split("/")[0]
								tagCategoty.orderBy = 4
							}
						});
					}

					if(!Object.entries(secCategoty).length) {
						secCategoty.category = "Security"
						secCategoty.data = 0
						secCategoty.orderBy = 1
					}
					if(!Object.entries(goverCategoty).length) {
						goverCategoty.category = "Governance"
						goverCategoty.data = 0
						goverCategoty.orderBy = 1
					}
					if(!Object.entries(availCategoty).length) {
						availCategoty.category = "Availability"
						availCategoty.data = 0
						availCategoty.orderBy = 2
					}
					if(!Object.entries(operCategoty).length) {
						operCategoty.category = "Operations"
						operCategoty.data = 0
						operCategoty.orderBy = 3
					}
					if(!Object.entries(tagCategoty).length) {
						tagCategoty.category = "Tagging"
						tagCategoty.data = 0
						tagCategoty.orderBy = 4
					}
					

					catArray.push(secCategoty)
					catArray.push(goverCategoty)
					catArray.push(availCategoty)
					catArray.push(operCategoty)
					catArray.push(tagCategoty)

					catArray = _.orderBy(catArray, ["orderBy"], ["asc"])
					
					let compilanceCat = []
					if(score[0] !== undefined) {
						compilanceCat = score[0].compliance_category
					}
					this.setState({ complianceCategoryScore: compilanceCat, categoryScoreList: catArray},
						() => this.getAllCounts("compliance.complianceId")
					)
				}
			} else {
				this.setState({ score: [] })
			}

			//getting day wise count tags
			// if (this.timeout) clearTimeout(this.timeout);
			// this.timeout = setTimeout(() => {
			// 	this.getDayWiseCountTags();
			// }, 100);
			
		})
	}

	getDailyScore = () => {
		let params ={}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		params.score_category = ["ALL"]
		if (this.state.selectedAccount !== "" && this.state.selectedAccount !== "All") {
			params.account_id = this.state.selectedAccount
		}

		this.props.getDailyScore(params, (promise, dailyScore) => {			
			if (promise) {
				let catArray= []
				let secCategoty = {}
				let availCategoty = {}
				let goverCategoty = {}
				let operCategoty = {}
				let tagCategoty = {}
				Object.entries(dailyScore.data.identifier_category).forEach(([key, value]) => {
					if(key === "Security") {
						secCategoty.category = key
						let data = []
						Object.entries(value).forEach(([catKey, catValue]) => {
							data.push(catValue.split("/")[0])
						})
						secCategoty.data = data
					}
					
					if(key === "Governance") {
						goverCategoty.category = key
						let data = []
						Object.entries(value).forEach(([catKey, catValue]) => {
							data.push(catValue.split("/")[0])
						})
						goverCategoty.data = data
					}

					if(key === "Availability") {
						availCategoty.category = key
						//availCategoty.data = value
						let data = []
						Object.entries(value).forEach(([catKey, catValue]) => {
							data.push(catValue.split("/")[0])
						})
						availCategoty.data = data
					}

					if(key === "Operations") {
						operCategoty.category = key
						let data = []
						Object.entries(value).forEach(([catKey, catValue]) => {
							data.push(catValue.split("/")[0])
						})
						operCategoty.data = data
					}

					if(key === "Tagging") {
						tagCategoty.category = key
						let data = []
						Object.entries(value).forEach(([catKey, catValue]) => {
							data.push(catValue.split("/")[0])
						})
						tagCategoty.data = data
					}
				});
				catArray.push(secCategoty)
				catArray.push(goverCategoty)
				catArray.push(availCategoty)
				catArray.push(operCategoty)
				catArray.push(tagCategoty)

				this.setState({ dailyCategoryScore: dailyScore, categoryList: catArray }, () => this.dayWiseCategoryFailGraph())
				
			} else {
				this.setState({ score: [] })
			}
			
		})
	}

	envSlideLeft() {
		this.proEnvWrap.current.scrollLeft -= 50
	}

	envSlideItemRight() {
		let totalCompliance = this.state.score.length
		if(totalCompliance > 5) {
			totalCompliance = 5
		}

		let reqTabWidth = 0
		for (let i = 0; i < totalCompliance; i++) {
			reqTabWidth += this.proEnvWrap.current.childNodes[i].getBoundingClientRect().width
		}
		const averageSliderItemWidth = reqTabWidth/totalCompliance
		const sliderWrapWidth = this.proEnvWrap.current.getBoundingClientRect().width
		const totalItemShowing = sliderWrapWidth/averageSliderItemWidth;

		//const index = this.state.complianceCategoryScore.indexOf(this.state.selectedSeries)
		//const index = 1
		
		if (totalItemShowing < totalCompliance) {
			this.proEnvWrap.current.scrollLeft += 50
			
		} else {
		 	this.proEnvWrap.current.scrollLeft = 0
		}
	}
	
	slideLeft() {
		let move = 0
		move = parseInt(this.state.totalItemShowing)
		this.setState({ totalItemShowing: this.state.totalItemShowing - 1 })
		
		const totalCompliance = this.state.complianceIdCounts.length;
		let reqTabWidth = 0
		for (let i = 0; i < totalCompliance; i++) {
			reqTabWidth += this.sliderWrap.current.childNodes[i].getBoundingClientRect().width + 16
		}
		const averageSliderItemWidth = reqTabWidth/totalCompliance
		const sliderWrapWidth = this.sliderWrap.current.getBoundingClientRect().width + 16
		const totalItemShowing = sliderWrapWidth/averageSliderItemWidth;
		
		if(move > totalItemShowing) {
			if (this.sliderWrap.current.childNodes[move-1] && this.sliderWrap.current.childNodes[move-1].getBoundingClientRect().width) {
				this.sliderWrap.current.scrollLeft -= this.sliderWrap.current.childNodes[move-1].getBoundingClientRect().width + 16
				this.setState({ showingRightIcon: true, showingOff: parseInt(this.state.showingOff - 1), showingOffStart: this.state.showingOffStart < 1 ? 1 : parseInt(this.state.showingOffStart-1)})
			} else {
				this.setState({ showingLeftIcon: false, totalItemShowing: totalItemShowing })
			}
		} else {
			this.setState({ showingLeftIcon: false, totalItemShowing: totalItemShowing })
		}
	}

	slideItemRight(item) {		
		const totalCompliance = this.state.complianceIdCounts.length;
		//const sliderItemWidth = this.sliderItemWrap.current.getBoundingClientRect().width + 
		//parseInt(12);

		let reqTabWidth = 0
		for (let i = 0; i < totalCompliance; i++) {
			reqTabWidth += this.sliderWrap.current.childNodes[i].getBoundingClientRect().width + 16
		}

		const averageSliderItemWidth = reqTabWidth/totalCompliance
		const sliderWrapWidth = this.sliderWrap.current.getBoundingClientRect().width + 16
		const totalItemShowing = parseInt(sliderWrapWidth/averageSliderItemWidth)
		//const index = this.state.complianceCategoryScore.indexOf(this.state.selectedSeries)
		//const index = 1
		
		let move = 0
		if(!this.state.totalItemShowing) {
			this.setState({ totalItemShowing })
			move = totalItemShowing
		} else {
			move = parseInt(this.state.totalItemShowing + 1)
			this.setState({ totalItemShowing: this.state.totalItemShowing + 1 })
		}
		if (this.sliderWrap.current.childNodes[move-1] && this.sliderWrap.current.childNodes[move-1].getBoundingClientRect().width) {
			if(item === "") {
				this.sliderWrap.current.scrollLeft += this.sliderWrap.current.childNodes[move-1].getBoundingClientRect().width + 16
			}
			if(!this.state.showingOff && this.state.showingOff < totalItemShowing) {
				this.setState({ showingOff: parseInt(totalItemShowing), showingLeftIcon: true })
			} else{
				this.setState({ showingOffStart: (this.state.showingOffStart + 1), showingOff: parseInt(this.state.showingOff + 1), showingLeftIcon: true })
			}

			//const sliderCenter = sliderWrapWidth / 2 - 110
			//const left = index * 110 - sliderCenter
			//this.sliderWrap.current.scrollLeft = left		
			
		} else {
			this.setState({ showingRightIcon: false, totalItemShowing: this.state.complianceIdCounts.length, showingOff: totalCompliance })
		 	//this.sliderWrap.current.scrollLeft = 0
		}
	}

	getDayWiseCountTags = () =>{
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		if (this.state.selectedAccount !== "" && this.state.selectedAccount !== "All") {
			params.account_id = this.state.selectedAccount
		}
		params.aggregate_by = "tag"
		//params.risk = this.state.selectedRisk,
		params.tags = [{"key":this.state.selectedTag, "value": this.state.detectorKey}]

		this.props.getDayWiseCountTags(params, (promise, dayWiseCountTags) => {
			if (promise) {
				this.setState({dayWiseCountTags:dayWiseCountTags},()=>{
					this.showSummaryGraph();
				});
			} else {
				this.setState({ dayWiseCountTags: [] })
			}
		})
	}

	getStatusCount = () =>{
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		params.past_duration = "week"
		if (this.state.selectedAccount !== "" && this.state.selectedAccount !== "All") {
			params.account_id = this.state.selectedAccount
		}
		this.props.getStatusCount(params, (promise, percentStatusCount) => {
			if (promise) {
				this.setState({percentStatusCount})
			} else {
				this.setState({ percentStatusCount: [] })
			}
		})
	}

	getIssueStatusCount = () =>{
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		if(this.state.selectedDuration === "7d" || this.state.selectedDuration === "+7d" || this.state.selectedDuration === "+14d") {
			params.past_duration = "7d"
		} else {
			params.past_duration = "30d"
		}
		if (this.state.selectedAccount !== "" && this.state.selectedAccount !== "All") {
			params.account_id = this.state.selectedAccount
		}
		this.props.getIssueStatusCount(params, (promise, issueStatusCount) => {
			if (promise) {
				this.setState({issueStatusCount: issueStatusCount},()=>{
					// this.showSummaryGraph();
				});
			} else {
				this.setState({ issueStatusCount: [] })
			}
		})
	}

	getCount = () =>{
		let params = {}
		
		params.fields = ["asset_id","event_id","resource_type"]
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		if (this.state.selectedAccount !== "" && this.state.selectedAccount !== "All") {
			params.account_id = this.state.selectedAccount
		}
		this.props.getCount(params, (promise, processCount) => {
			if (promise) {
				this.setState({processCount:processCount});
			} else {
				this.setState({ processCount: [] })
			}
		})
	}

	getCostTrend = () => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		//params.duration = "+10d"
		params.duration = this.state.selectedDuration
		params.category = "Cost"
		params.aggregate_by = "category"
		params.tags = [{"key":this.state.selectedTag, "value": this.state.detectorKey}]
		if (this.state.selectedAccount !== "" && this.state.selectedAccount !== "All") {
			params.account_id = this.state.selectedAccount
		}
		
		this.props.getCostTrend(params, (promise, costWiseTrendData) => {
			if (promise) {
				this.setState({costWiseTrendData});
				this.dayWiseCostTrendGraph()
				
			} else {
				this.setState({ costWiseTrendData: [] })				
			}
		})
	}

	dayWiseCostTrendGraph = () => {
		let graphData = this.state.costWiseTrendData;
		let costData = [];
		let costSum = 0;
		let labels = [];
		if(graphData){
			labels = graphData.labels;
			costData =  graphData.data.cost
				
			costSum = costData.reduce(function(a, b){
				return a + b;
			}, 0);
		}

		let formated_labels = [];
		if(labels){
			for (const [key] of Object.entries(labels)) {
				formated_labels.push(momentDateGivenFormat(labels[key], "DD MMM Y"))
			}
		}

		let line_options = {
			// legend: {
            //     display: true,                
			// 	position: "bottom",
			// 	labels: {
			// 		usePointStyle: true,
			// 		fill: true,
			// 		fontSize: 5
			// 	},
			// 	fontSize: 10,
			// },
			legend: {
				display: false,
			},
			scales:{
				xAxes: [{
					display: false, //this will remove all the x-axis grid lines
					gridLines: {
						display:false,	
					}
				}],
				yAxes: [{
					display: false, //this will remove all the y-axis grid lines
					gridLines: {
						display:false,	
					}					
				}]
			},
			maintainAspectRatio: true
		}

	
		let line_pass_cost_datasetsColumn = [
			{
				label: "",
				fill: true,
				backgroundColor: "#CEFBFF",
				borderColor: "#7BB9BD",
				borderCapStyle: "butt",
				borderWidth: 1.5,
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: "miter",
				pointBackgroundColor: "#CEFBFF",
				pointBorderColor: "#7BB9BD",
				pointBorderWidth: 1,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: "#CEFBFF",
				pointHoverBorderColor: "#7BB9BD",
				pointHoverBorderWidth: 2,
				pointRadius: 0,
				pointHitRadius: 2, //show tooltip inside bar if not "0"
				data: costData

			}
		];

		let line_pass_cost_datas = {labels:formated_labels, datasets: line_pass_cost_datasetsColumn}; 
		
		this.setState({cost_pass_day_wise_count_data:line_pass_cost_datas, cost_pass_day_wise_count_options:line_options, costSum: costSum});
	}

	getDayWiseCount = cost =>{
		let params = {}
		
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		if (this.state.selectedAccount !== "" && this.state.selectedAccount !== "All") {
			params.account_id = this.state.selectedAccount
		}
		this.props.getDayWiseCount(params, (promise, dayWiseCountGraphData) => {
			if (promise) {
				this.setState({dayWiseCountGraphData:dayWiseCountGraphData, multi_pass_fail_series: [], multi_pass_fail_options: {} },
					() => {
						this.multidayWiseCountGraphApex();
						this.getCategoryDayWiseCount() //catgory circular graph old
					}	
				);
			} else {
				this.setState({ dayWiseCountGraphData: [] })
			}
		})		
	}

	getTagwiseChangeDetails = () => {
		this.setState({showPrevNextRecord: 0, daysShownInGraph: 7, graphRecordsRemainigToShow: 0, disableNextWeekBtn: false, disablePreviousWeekBtn: true })
		this.getDayWiseCountTags();
	}

	//to ge the sum of catefory fail count start
	getCategoryDayWiseCount = () =>{
		let params = {}
		params.aggregate_by = "category"
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		if (this.state.selectedAccount !== "" && this.state.selectedAccount !== "All") {
			params.account_id = this.state.selectedAccount
		}
		this.props.getDayWiseCount(params, (promise, categoryDayWiseCount) => {
			let Availability = {}
			let Operations = {}
			let Security = {}
			let Tagging = {}
			let Governance = {}
			if (promise) {
				if(categoryDayWiseCount.Availability && categoryDayWiseCount.Availability.data.fail.length > 0) {
					Availability.fail = categoryDayWiseCount.Availability.data.fail.reduce(function(a, b){ return a + b;}, 0);
					Availability.pass = categoryDayWiseCount.Availability.data.pass.reduce(function(a, b){ return a + b;}, 0);
				}
				if(categoryDayWiseCount.Operations && categoryDayWiseCount.Operations.data.fail.length > 0) {
					Operations.fail = categoryDayWiseCount.Operations.data.fail.reduce(function(a, b){ return a + b;}, 0);
					Operations.pass = categoryDayWiseCount.Operations.data.pass.reduce(function(a, b){ return a + b;}, 0);
				}
				if(categoryDayWiseCount.Security && categoryDayWiseCount.Security.data.fail.length > 0) {
					Security.fail = categoryDayWiseCount.Security.data.fail.reduce(function(a, b){ return a + b;}, 0);
					Security.pass = categoryDayWiseCount.Security.data.pass.reduce(function(a, b){ return a + b;}, 0);
				}
				if(categoryDayWiseCount.Tagging && categoryDayWiseCount.Tagging.data.fail.length > 0) {
					Tagging.fail = categoryDayWiseCount.Tagging.data.fail.reduce(function(a, b){ return a + b;}, 0);
					Tagging.pass = categoryDayWiseCount.Tagging.data.pass.reduce(function(a, b){ return a + b;}, 0);
				}
				if(categoryDayWiseCount.Governance && categoryDayWiseCount.Governance.data.fail.length > 0) {
					Governance.fail = categoryDayWiseCount.Governance.data.fail.reduce(function(a, b){ return a + b;}, 0);
					Governance.pass = categoryDayWiseCount.Governance.data.pass.reduce(function(a, b){ return a + b;}, 0);
				}

				this.setState({categoryDayWiseCount, Availability, Operations, Security, Tagging, Governance });
			} else {
				this.setState({ categoryDayWiseCount: [] })
			}
		})
	}
	//to ge the sum of catefory fail count end

	dayWiseCategoryFailGraph = () => {
		let graphData = this.state.categoryList
		let graphLabel = this.state.dailyCategoryScore.labels

		let label = []
		for(let i=0;i<graphLabel.length;i++) {			
			label.push(momentDateGivenFormat(graphLabel[i], "DD MMM Y"))
		}
		for(let i=0;i<graphData.length;i++) {			
			let dailyScore = [];
			let barChartData = [];
			
			barChartData = {
				name: graphData[i].category,
				data: graphData[i].data,

			};
			dailyScore.push(barChartData)

			const barChartOptions = {	
                chart: {
                    toolbar: {
                        show: false,
                    },
                    sparkline: {
                        enabled: true
                    },
                    type: "bar",
                    height: 200,
                    stacked: false,
                },
                colors: ["#039BE5"],
				
                fill: {
                    colors: ["#039BE5"],
                    type: "gradient", // gradient
                    gradient: {
                        type: "vertical", // The gradient in the horizontal direction
                        gradientToColors: ["#5F5BA2"], // The color at the end of the gradient
                        opacityFrom: .9, // transparency
                        opacityTo: 1,
                        stops:[0, 120]
                    }
                },        
                plotOptions: {
					bar: {
						horizontal: false,
						barHeight: "50%",
						startingShape: "rounded",
						endingShape: "rounded",
						columnWidth: "50%",
						distributed: false,
						rangeBarOverlap: false,
						colors: {
							backgroundBarColors: ["#333947"],
							backgroundBarOpacity: 1,
							backgroundBarRadius: 2,
						}        
					},          
                },
                dataLabels: {
                enabled: false
                },                
                grid: {  				        
					xaxis: {
						lines: {
							show: false
						},
					},
					yaxis: {
						lines: {
							show: false
						}
					},
                },
                yaxis: {
                    labels: {
                        show:false,
                        style: {
                            fontSize: "9px"
                        }
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        fontSize: "9px",
                        show: false,
                        borderType: "none",
                        color: "#78909C",
                        width: 6,
                        offsetX: 0,
                        offsetY: 0
                    },
                },
                xaxis: {				
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        tickAmount: 15,
                        fontSize: "9px",
                        show: false,
                        borderType: "none",
                        color: "#78909C",
                        width: 6,
                        offsetX: 0,
                        offsetY: 0
                    },
                    categories: label,
                    labels: {
                        show:false,
                        style: {
                            fontSize: "9px"
                        }
                    }
                },
				tooltip: {
                    enabled: true,
                    //enabledOnSeries: undefined,
                    //shared: false,
                    //followCursor: false,
                    //intersect: false,
                    //inverseOrder: false,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
						let val  = series[seriesIndex][dataPointIndex];
						val = Math.abs(Math.round(val))
						//let labelName = w.globals.initialSeries[seriesIndex].name
						//let val = str.replace("-",series[seriesIndex][dataPointIndex]);
						//return '<div className="arrow_box">' + val + '</div>'
						return '<div class="arrow_box"> '+ w.globals.labels[dataPointIndex] +' <span style="color:'+ w.globals.colors[seriesIndex] +'"> score </span>' + val + '</div>'
                    },
                    //fillSeriesColor: false,
                    //theme: true,
                    style: {
                    fontSize: "9px",
                    },
                    onDatasetHover: {
                        highlightDataSeries: true,
                    },
                    x: {
                        //show: true,
                        format: "dd MMM Y",
                        formatter: undefined,
                    },
                    y: {
                        show: true,
                        format: "dd",
                        //formatter: (value) => { return value },
                        formatter: undefined,
                        title: {
                            formatter: (seriesName) => seriesName,
                        },
                    },
                    z: {
                        formatter: undefined,
                        title: "Size: 9px"
                    },
                    marker: {
                        show: true,
                    },
                    fixed: {
                        enabled: false,
                        position: "topRight",
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
			}

			this.setState({ ["bar_"+graphData[i].category+"_data"]: dailyScore, bar_category_option:barChartOptions });
		}

	}

	getDayWiseRiskCount = () =>{
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		params.aggregate_by = "risk"
		if (this.state.selectedAccount !== "" && this.state.selectedAccount !== "All") {
			params.account_id = this.state.selectedAccount
		}
		this.props.getDayWiseCount(params, (promise, response) => {
			if (promise) {
				this.setState({dayWiseRiskCountGraphData: response })
			} else {
				this.setState({ dayWiseRiskCountGraphData: {} })
			}
		})
	}

	/**
	 * Method to get all category counts
	 */
	getAllCounts = aggregateBy => {
		let params = {}
		if (this.state.selectedProvider !== "" && this.state.selectedProvider !== "All") {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if (this.state.selectedAccount !== "" && this.state.selectedAccount !== "All") {
			params.account_id = this.state.selectedAccount
		}
		
		if(aggregateBy === "compliance.complianceId") {
			params.duration = this.state.selectedDuration
		}
		params.aggregate_by = aggregateBy
		this.props.getTotalCount(params, (promise, getAllCounts) => {
			if (promise) {
				if(aggregateBy === "compliance.complianceId") {
					let complianceCategoryArray = []
					if(getAllCounts !== null) {
						getAllCounts.forEach((item, i) => {
							item.value = "0";
							if(Object.entries(this.state.complianceCategoryScore).length){
								Object.entries(this.state.complianceCategoryScore).sort().forEach(([key, value]) => {
									if(item.complianceId === key) {
										item.value = (value.split("/"))[0]
									}
								})
							}
							complianceCategoryArray.push(item)
						})
					}
					this.setState({ complianceIdCounts: complianceCategoryArray }, 
						() => this.slideItemRight("initiate")//get number of item showing
					)
				} 
			} else {				
				if(aggregateBy === "compliance.complianceId") {
					this.setState({ complianceIdCounts: [] })
				}
			}
			//console.log(";;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;",this.state)
		})
	}	
	
	/**
	 * Method to show summary graph
	 */
	
	dynamicColors = () => {
		let r = Math.floor(Math.random() * 255);
		let g = Math.floor(Math.random() * 255);
		let b = Math.floor(Math.random() * 255);
		return "rgb(" + r + "," + g + "," + b + ")";
	};

	getTransitionTrend = () => {
        let params = {}
        params.provider = this.state.selectedProvider.toLowerCase()
        params.duration = this.state.selectedDuration
        if (this.state.selectedAccount !== "" && this.state.selectedAccount !== "All") {
			params.account_id = this.state.selectedAccount
        }
		this.props.getTransitionTrend(params, (promise, transitionTrend) => {
			if (promise) {
                this.setState({ transitionTrend, showLoading: false });
                this.dayWiseTransitionGraph();
			} else {
                this.setState({ transitionTrend: [], showLoading: false })
            }
		})
	}

	dayWiseTransitionGraph = () => {
		let graphDetails = this.state.transitionTrend.details
		let graphLabels = this.state.transitionTrend.labels
		
		if(graphDetails) {
			for (const [key, value] of Object.entries(graphDetails)) {
				let data = []
				let graphData = value.daywise_trend
				for(let x=0;x<graphLabels.length;x++) {
					let dataItems = {}
					dataItems.x = momentDateGivenFormat(graphLabels[x], "DD MMM Y")
					dataItems.y  = graphData[x]
					data.push(dataItems)
				}

				let trendGraphData = {}
				trendGraphData.labels = graphLabels
				trendGraphData.data = data

				this.setState({ [key+"_trend_data"]: trendGraphData });
			}
		}
	}

	multidayWiseCountGraphApex = () => {

		let graphData = this.state.dayWiseCountGraphData;
		let data1 = []
		let data2 = []
		let passData = [];
		let failData = [];
		let totalSum = 0;
		let passSum = 0;
		let failSum = 0;

		if(graphData){
			for(let x=0;x<graphData.labels.length;x++) {
				let dataItems1 = {}
				dataItems1.x = momentDateGivenFormat(graphData.labels[x], "DD MMM Y")
				dataItems1.y  = graphData.data.pass[x]				
				data1.push(dataItems1)

				let dataItems2 = {}
				dataItems2.x = momentDateGivenFormat(graphData.labels[x], "DD MMM Y")
				dataItems2.y  = graphData.data.fail[x]
				data2.push(dataItems2)
			}

			passData =  graphData.data.pass;
			failData =  graphData.data.fail;
			
			passSum = passData.reduce(function(a, b){
				return a + b;
			}, 0);

			failSum = failData.reduce(function(a, b){
				return a + b;
			}, 0);

			totalSum = failSum + passSum;

			let multi_series = [
				{
				  name: "Pass",
				  data: data1,
				},
				{
				  name: "Fail",
				  data: data2,
				}
			]		
			
			let xAxisRow = {}
			if(graphData.labels.length >= 7) {
				xAxisRow.type = "datetime"
			}
			let aAxixRowTool = {}
			aAxixRowTool.enabled = false
			xAxisRow.tooltip = aAxixRowTool
			let aAxixRowTicks = {}
			aAxixRowTicks.show = false
			xAxisRow.labels = aAxixRowTicks
			
			let multi_options = {
				chart: {
					type: "bar",
					height: 100,
					stacked: true,
					toolbar: {
						show: false,
					},
					zoom: {
						enabled: false
					},
					sparkline: {
						enabled: true
					},
				},
				colors: ["#FFD200", "#B96422"],
				// colors: ["#17522E", "#6E2527"],
				plotOptions: {
					bar: {
						horizontal: false,
						barHeight: "100%",
						borderRadius: "flat",
						startingShape: "flat",
						endingShape: "flat",
						columnWidth: "60%",
						distributed: false,
						rangeBarOverlap: false,
						colors: {
							ranges: [{
								from: 1,
								to: 0.9,
								color: "red"
							}],
							backgroundBarColors: ["#333947"],
							backgroundBarOpacity: 1,
							backgroundBarRadius: 0,
						}
					},       
				},
				dataLabels: {
				  enabled: false
				},
				legend: {
				  show: false
				},
				grid: {
                    show: false,			        
                    xaxis: {
                        lines: {
                            show: false
                        },
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: -5
                    },
                },
				xaxis: xAxisRow,
				yaxis: {
					show: false,
					labels: {
						show:false,					
					}
				},
				tooltip: {
                    enabled: true,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        let val  = series[seriesIndex][dataPointIndex];
                        let labelName = w.globals.initialSeries[seriesIndex].name
                        return '<div class="arrow_box">' +
                            '<span> '+ w.globals.initialSeries[0].data[dataPointIndex].x + 
                            ' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val + ' </span>' +
                            '</div>'
                    },
                    style: {
                 	   fontSize: "9px",
                    },
                }
			}
			this.setState({ multi_pass_fail_series: multi_series, multi_pass_fail_options: multi_options, totalSum, passSum, failSum });
		}
	}

	showSummaryGraph= () => {	
		let graphData = this.state.dayWiseCountTags.data;
		let barChartData = [];
		//let summaryGraphLabels = [];
		let i =0;
		let tagAccount = this.state.detectorKey
		let summaryGraphLabelsData = [];

		let daysShownInGraph = this.state.daysShownInGraph
		let week = this.state.showPrevNextRecord
		if(week < 1) {
			this.setState({disablePreviousWeekBtn:true})
		}

		let graphLength = 0

		if(graphData) {			
			for (const [key] of Object.entries(graphData)) {				
				for(const [detKey, detValue] of Object.entries(graphData[key])) {
					if(tagAccount === detKey) {
						let length = 0
						if(detValue.Low !== undefined) {
							length = detValue.Low.pass.length
						} else if(detValue.Medium !== undefined) {
							length = detValue.Medium.pass.length
						} else if(detValue.high !== undefined) {
							length = detValue.high.pass.length
						} else if(detValue.Critical !== undefined) {
							length = detValue.Critical.pass.length
						}						
						let passValueArray = []
						let lowExistArray = []
						let mediumExistArray = []
						let highExistArray = []
						let criticalExistArray = []
						let totalArray = []
						
						if(week > 0) {
							this.setState({disableNextWeekBtn:true, disablePreviousWeekBtn: false})
						}
						
						this.setState({disableNextWeekBtn:true})
						i = daysShownInGraph * week
						graphLength = daysShownInGraph * (week + 1)
						let graphRecordsRemainigToShow = length - graphLength;

						if(graphLength > length && graphRecordsRemainigToShow < daysShownInGraph){
							//graphLength = graphLength + graphRecordsRemainigToShow
							//graphLength = graphLength
						} else {
							this.setState({ disableNextWeekBtn: false })
						}

						summaryGraphLabelsData = []
						let x = i
						for (x;x<graphLength;x++) {
							if(x < graphLength) {
								if(this.state.dayWiseCountTags.labels[x] !== undefined) {
									summaryGraphLabelsData.push(momentDateGivenFormat(this.state.dayWiseCountTags.labels[x], "DD MMM Y"))
								} else {
									summaryGraphLabelsData.push("")
								}
							} else {
								break;
							}
						}
						
						//let i = thisweek;
						for(i;i<parseInt(graphLength);i++) {
							let passValue = 0;
							if(detValue.Low !== undefined) {
								passValue = passValue + detValue.Low.pass[i]
								lowExistArray.push(detValue.Low.fail[i])
							}
							
							if(detValue.Medium !== undefined) {
								passValue = passValue + detValue.Medium.pass[i]
								mediumExistArray.push(detValue.Medium.fail[i])
							}
							
							if(detValue.High !== undefined) {
								passValue = passValue + detValue.High.pass[i]
								highExistArray.push(detValue.High.fail[i])
							}
							
							if(detValue.Critical !== undefined) {
								passValue = passValue + detValue.Critical.pass[i]
								criticalExistArray.push(detValue.Critical.fail[i])
							}
							passValueArray.push(-passValue)							
						}	
						totalArray.push(passValueArray)
						if(lowExistArray.length>0) {
							totalArray.push(lowExistArray)
						} else {
							totalArray.push(0)
						}
						if(mediumExistArray.length>0) {
							totalArray.push(mediumExistArray)
						} else {
							totalArray.push(0)
						}
						if(highExistArray.length>0) {
							totalArray.push(highExistArray)
						} else {
							totalArray.push(0)
						}
						if(criticalExistArray.length>0) {
							totalArray.push(criticalExistArray)
						} else {
							totalArray.push(0)
						}
						
						//let graphColors = ["#5D38AA", "#24A89D", "#22B5B5", "#2DCBDC", "#1FCCE2"]

						let dataRows = ""
						for(let m=0; m<totalArray.length; m++) {
							if(totalArray[m] === 0) {
								//dataRows = Array.apply(null, Array(length)).map(Number.prototype.valueOf,0);
								dataRows = Array.apply(null, Array(this.state.daysShownInGraph)).map(Number.prototype.valueOf,0);
							} else {
								dataRows = totalArray[m]
							}
							
							let summaryDataSet = {};
							summaryDataSet["data"] = dataRows
							if(m === 0) {
								summaryDataSet["name"] = "Pass";
							} else if(m === 1) {
								summaryDataSet["name"] = "Low";
							} else if(m === 2) {
								summaryDataSet["name"] = "Medium";
							} else if(m === 3) {
								summaryDataSet["name"] = "High";
							} else if(m === 4) {
								summaryDataSet["name"] = "Critical";
							}
							barChartData.push(summaryDataSet);
						}
					}
				}
				break;
			}
		} else {
			let summaryDataSet = {};
			summaryDataSet["name"] = "No Data";
			for (let m=0;m<30;m++) {
				summaryGraphLabelsData.push("")
			}
			summaryDataSet["data"] = Array.apply(null, Array(30)).map(Number.prototype.valueOf,0)
			barChartData.push(summaryDataSet);
		}
		
		// barChartData = {
		// 	labels:summaryGraphLabelsData,
		// 	datasets: barChartData
		// };
		
		const barChartOptions = {
			tooltip: {
				enabled: true,
				//enabledOnSeries: undefined,
				//shared: false,
				//followCursor: false,
				//intersect: false,
				//inverseOrder: false,
				custom: function({series, seriesIndex, dataPointIndex, w}) {
				  let val  = series[seriesIndex][dataPointIndex];
				  val = Math.abs(Math.round(val))
				  let labelName = w.globals.initialSeries[seriesIndex].name
				  //let val = str.replace("-",series[seriesIndex][dataPointIndex]);
				  return '<div class="arrow_box">' +
					'<span> '+ w.globals.labels[dataPointIndex] + 
					' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val + ' </span>' +
					'</div>'
				},
				//fillSeriesColor: false,
				//theme: true,
				style: {
				  fontSize: "9px",
				},
				onDatasetHover: {
					highlightDataSeries: true,
				},
				x: {
					//show: true,
					format: "dd MMM Y",
					formatter: undefined,
				},
				y: {
					show: true,
					format: "dd",
					//formatter: (value) => { return value },
					formatter: undefined,
					title: {
						formatter: (seriesName) => seriesName,
					},
				},
				z: {
					formatter: undefined,
					title: "Size: 9px"
				},
				marker: {
					show: true,
				},
				fixed: {
					enabled: false,
					position: "topRight",
					offsetX: 0,
					offsetY: 0,
				},
			},
			legend: {
				position: "bottom",
				fontSize: "11px",
				labels: {
					colors: "#60687C",
				},
				markers: {
					width: 9,
					height: 9,
					strokeWidth: 0,
					strokeColor: "#fff",
					fillColors: undefined,
					radius: 9,
					customHTML: undefined,
					onClick: undefined,
					offsetX: 0,
					offsetY: 0
				}
			},	
			chart: {
				toolbar: {
					show: false,
				},
				sparkline: {
					enabled: false
				},
				padding: {
					top:400
				},
				type: "bar",
				height: 440,
				stacked: true,
			},
			colors: ["#FFD200", "#039BE5", "#3F73AD", "#9A77D1", "#5F5BA2"],
			// colors: ["#5D38AA", "#00B5D4", "#67E1D6", "#2ED1BE", "#26B68F"],
	
			plotOptions: {
				bar: {
					horizontal: true,
					barHeight: "40%",
					startingShape: "flat",
					endingShape: "flat",
					columnWidth: "80%",
					distributed: false,
					rangeBarOverlap: false,
					colors: {
						ranges: [{
							from: 1,
							to: 0.9,
							color: "#FF4560"
						}],
						backgroundBarColors: ["#333947"],
						backgroundBarOpacity: 1,
						backgroundBarRadius: 5,
					}        
				},          
			},
			dataLabels: {
			  	enabled: false
			},
			stroke: {
				width: 0,
				colors: ["#fff"]
			},			
			grid: {  				        
			  xaxis: {
				lines: {
				  show: false
				},
			  },
			  yaxis: {
				lines: {
				  show: false
				}
			  },
			//   row: {
			// 	colors: undefined,
			// 	opacity: 0.5
			//   },  
			//   column: {
			// 	  colors: undefined,
			// 	  opacity: 0.5
			//   },  
			//   padding: {
			// 	  top: 0,
			// 	  right: 0,
			// 	  bottom: 0,
			// 	  left: 0
			//   }, 
			},
			yaxis: {
			  labels: {				
				style: {
					fontSize: "9px",
					colors: "#60687C"
				}
			  },
			  axisBorder: {
				show: false
			  },
			  axisTicks: {
				  fontSize: "9px",
				  show: false,
				  borderType: "none",
				  color: "#78909C",
				  width: 6,
				  offsetX: 0,
				  offsetY: 0
			  },
			},
			xaxis: {				
				axisBorder: {
					show: false
				},
				axisTicks: {
					tickAmount: 15,
					fontSize: "9px",
					show: false,
					borderType: "none",
					color: "#78909C",
					width: 6,
					offsetX: 0,
					offsetY: 0
				},
				categories: summaryGraphLabelsData,
				title: {
					text: ""
				},
				labels: {
					formatter: function (val) {
						return Math.abs(Math.round(val))
					},
					style: {
						fontSize: "9px",
						colors: "#60687C"
					},
					show: false
				}
			},
		}

		this.setState({bar_data:barChartData, bar_option:barChartOptions});
	}
	/**
	 * Method to return total fail counts
	 */
	getTotalFailCounts = () => {
		let count = 0
		if (this.state.statusCounts && this.state.statusCounts.length > 1) {
			this.state.statusCounts.forEach(item => {
				if (item.status === "fail") {
					count = item.fail_count
				}
			})
		}
		if (this.state.statusCounts && this.state.statusCounts.length === 1) {
			this.state.statusCounts.forEach(item => {
				count = item.fail_count
			})
		}
		return count
	}

	navigateServieWise = (slide) => {
		if(slide === "left") {
			this.setState({
				serviceWiseStart: this.state.serviceWiseStart - this.state.serviceWiseheight,
				filterServiceCountLength: this.state.filterServiceCountLength - this.state.serviceWiseheight
			})
		} else {
			this.setState({ 
				filterServiceCountLength: this.state.filterServiceCount.length > this.state.filterServiceCountLength 
					? this.state.filterServiceCountLength + this.state.serviceWiseheight 
					: this.state.serviceWiseheight,
				serviceWiseStart: this.state.serviceWiseStart + this.state.serviceWiseheight 
			})
		}

	}

	tablefilterMultiSelectChange= (key, childKey, arrayValue) => {
		let value = arrayValue.map(item => item.value)
		if(childKey === "selectedService") {
			this.setState({ selectedService: value, serviceLoading: true },
				() => this.getServiceCount()
			)
		}
	}

	getComplianceCoverage = () => {
		let params = {}
		this.props.getComplianceCoverage(params, (promise, response) => {
			if(promise) {
				this.setState({ complianceCoverage: response })
			}
		})
	}
    
    handleMultiSelectChange = (field, arrayValue) => {
		let value = arrayValue.map(item => item.value)

		let selectedValue = []
		if(field === "selectedAccount") {
			let prevState = this.state[field]
			if(value.includes("All")) {
				if(!prevState.includes("All")) {
					this.state.accounts.forEach(acc => {
						selectedValue.push(acc.account_id)
					})
				} else {
					const index = value.indexOf("All");
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes("All")) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes("All")) {
				if(!selectedValue.includes("All") && selectedValue.length === (this.state.accounts.length -1)) {
					selectedValue.push("All")
				}
			}	
            this.setState({ [field]: selectedValue, changesInFilter: true })		
		}
    }
    
    getMultiSelectedCount = (type, array) => {
        return array.length && array.includes("All") ? "All" : (array.length ? array.length +" Selected" : "All")
    }

	/**
	 * Renders Compliance Dashboard
	 */
	render() {
		return (
			<div className="container-fluid overflow-auto flex-grow-1 bg-muted" onClick={ (event) => { this.handleClickOutside(event) } }>
				<div className={`loaderOverlay ${this.state.showLoading ? "" : "displayNone"}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
				<div className="row h-100">
					<div className="col-sm-12 p-0">
						<div className="title-section p-2">
							<div className="d-flex">
								<div className="col-5 pl-0">
									<h6 className="text-white m-0">Checks Dashboard</h6>
									<p className="text-white m-0">Dashboard view of various types of checks performed on your Cloud</p>
								</div>
								<div className={`col-7 pl-0 text-right ${this.state.showLoading ? "disabled" : ""}`}>
									<div className={`d-md-flex justify-content-end`}>
										<div className={`form-group cursorPointer flex-fill mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: "150px" }}>
											<label className="mb-1">Provider</label>
											<Select
												placeholder={"All"}
												isSearchable={false}
												className="f13 p-0 colorBlack reactSelectFilterDrop"
												value={({
													value: this.state.selectedProvider,
													label: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : <span className="placeholder">Select</span>
												})}
												options={this.state.providers && this.state.providers.map(item => ({
													value: item.provider_name,
													label: item.provider_name,	
												}))}
												onChange={event =>  
													this.setState({
														selectedProvider: event.value,
														changesInFilter: true
													},
														() => this.onChangeProvider()
													)
												}
											/>
										</div>										
                                        {/* <div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} ref={this.accountRef} style={{maxWidth: "150px" }}>
                                            <label className="mb-1">Account</label>
                                            <div className={`dlpHeaderMultiSelection ${this.state.accounts && this.state.accounts.length < 10 ? "removeDropdownSearchBar" : ""}`}>
                                                <ReactMultiSelectCheckboxes						
                                                    placeholderButtonLabel="All"
                                                    menuIsOpen ={this.state.isAccountOpen}
                                                    getDropdownButtonLabel={() => this.getMultiSelectedCount("selectedAccount", this.state.selectedAccount)}
                                                    value={this.state.selectedAccount && this.state.selectedAccount.map(comp => ({
                                                        value: comp,
                                                    }))}
                                                    onChange={arr => { this.handleMultiSelectChange("selectedAccount", arr ? arr : []) }}
                                                    options={this.state.accounts && this.state.accounts.map(account => ({
                                                        value: account.account_id,
                                                        label: account.account_name,
                                                    }))}
                                                />
                                            </div>
                                        </div> */}
										{/* <div className={`form-group cursorPointer flex-fill mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: "150px" }}>
											<label className="mb-1">Account</label>
											<Select
												placeholder={"All"}
												isSearchable={false}
												className="f13 p-0 colorBlack reactSelectFilterDrop"
												value={({
													value: this.state.selectedAccount,
													label: this.state.selectedAccount && this.state.selectedAccount !== "" ? getAccountNameFromId(this.state.selectedAccount, this.state.accounts) : <span className="placeholder">All</span>
												})}
												options={this.state.accounts && this.state.accounts.map(item => ({
													value: item.account_id,
													label: item.account_name,	
												}))}
												onChange={event =>  
													this.setState({
														selectedAccount: event.value,
														changesInFilter: true
													})
												}
											/>
										</div> */}
										<div className={`d-flex`}>
                                            <span className={`mr-2 f12 align-self-center`}>Last</span>
                                            <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === "+90d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => this.setState({ selectedDuration: "+90d" })}>3 Months</span>
                                            <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === "+60d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => this.setState({ selectedDuration: "+60d" })}>2 Month</span>
                                            <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === "+30d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => this.setState({ selectedDuration: "+30d" })}>1 Month</span>
                                            <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === "+14d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => this.setState({ selectedDuration: "+14d" })}>2 Week</span>
                                            <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === "+7d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => this.setState({ selectedDuration: "+7d" })}>1 Week</span>
                                        </div>
										<div className={`ml-2 align-self-center`}>
											<div className="btn-group" role="group" aria-label="checks-dash">
												<button type="button" className={`${this.state.showLoading ? "disabled" : "cursorPointer"} btn btn-secondary border-right`} onClick={() => this.setState({ showLoading: true }, this.onSearch()) }> 
													{this.state.showLoading ?
														<Spinner className={`align-self-center mr-2`} size="sm" />
													: null}
													Search
												</button>
												<button type="button" className={`${this.state.showLoading ? "disabled" : "cursorPointer"} btn btn-secondary border-left`} onClick={() => this.onReset()}><i className="far fa-redo"></i></button>
											</div>
										</div>	
									</div>
									
								</div>
							</div>
						</div>
						<div className={`box rounded-0 shadow-none bg-transparent`}> {/* p-3 */}
							<div className="row">
								<div className="col-sm-12">
									<div className={`p-3 bg-muted`}> {/* rounded */}
										<div className="row">
											<div className="col-lg-5 col-xl-3 border-right">
												<div className="bg-dark rounded overflowYAuto" style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
													<div className="pt-3">
														<div className="d-flex justify-content-between mb-3 px-3">
															<p className="mb-0 mt-1 small text-white">Showing {this.state.filteredAccount && this.state.filteredAccount.length} of Total {this.state.accountsNew && this.state.accountsNew.length} Account(s)</p>
															<div className="">
																{this.state.accountsNew && this.state.accountsNew.length ?
																	<SearchWithHiddenInput
																		data={this.state.accountsNew}
																		applyLiteDarkTags={true}
																		searchClassName={'border-0'}
																		searchIconColor={'text-gray3'}
																		className="form-control-sm text-gray3 bg-muted border-0"
																		hideInputSection={true}
																		topClassName={'dark-search'}
																		searchPlaceHolder={'Search....'}
																		filteredData={(filteredAccount) => this.setState({ filteredAccount })}
																	/>
																: null}
															</div>
														</div>
														{this.state.filteredAccount && this.state.filteredAccount.length ? 
															this.state.filteredAccount.map((item, i) => {
																return(
																	<div className={`cursorPointer ${this.state.selectedAccount === item.account_id ? "bg-dark3" : ""} px-3`} onClick={() => this.onClickAccFunction(item.account_id) }>
																		<div className={`d-flex justify-content-between py-2 ${i !== 0 ? "border-top" : ""}`}>
																			<div className="w-50 align-self-center">
																				<h7 className="font-weight-bold text-white">{capitalizeFirstLetter(getAccountNameFromId(item.account_id, this.state.accounts))}</h7>
																				<p className="f12 m-0">{item.account_id}</p>
																			</div>
																			{/* <div className="align-self-center">
																				h7 className="mb-0 font-weight-bold blackMuted">{acc}</h7>
																			</div> */}
																			<div className="box bg-muted d-flex p-2 shadow-none heihgtFitContent mr-2 align-self-center">
																				<div className="mr-2 width75">														
																					<p className="font-weight-bold m-0 f12">Pass&nbsp;:&nbsp;
																						<h7 className="font-weight-bold mb-0 text-warning">{item.pass_count && item.pass_count}</h7>
																					</p>
																				</div>
																				<div className="mr-2 width75">														
																					<p className="font-weight-bold m-0 f12">Fail&nbsp;:&nbsp;<h7 className="font-weight-bold mb-0 text-dark-theme-orange">{item.fail_count && item.fail_count}</h7></p>
																				</div>
																			</div>
																			<div className="w-10 align-self-center cursorPointer" id={`scorePopover_`+i}>
																				{this.state.accScore && Object.entries(this.state.accScore).length && this.state.accScore.hasOwnProperty(item.account_id) ?
																					<CircularProgressbar
																						value={this.state.accScore[item.account_id].overall ? (this.state.accScore[item.account_id].overall.split("/"))[0] : 0}
																						text={this.state.accScore[item.account_id].overall ? (this.state.accScore[item.account_id].overall.split("/"))[0] : "-"}
																						counterClockwise={false}
																						styles={{trail: {stroke: "#353C4C",strokeWidth:"8"},path: {stroke: "#039BE5",strokeWidth:"6"},text: {fill: "#039BE5",fontSize: "10px"}}}
																					/>
																				: 
																					<CircularProgressbar
																						value={0}
																						text={"-"}
																						counterClockwise={false}
																						styles={{trail: {stroke: "#353C4C",strokeWidth:"8"},path: {stroke: "#039BE5",strokeWidth:"6"},text: {fill: "#039BE5",fontSize: "10px"}}}
																					/>
																				}
																			</div>
																			<UncontrolledPopover className="scorePopOver" trigger="hover" placement="top" target={`scorePopover_`+i}>
																				<PopoverBody>
																					{this.state.accScore && Object.entries(this.state.accScore).length && this.state.accScore.hasOwnProperty(item.account_id) && Object.entries(this.state.accScore[item.account_id].identifier_category) ?
																						Object.entries(this.state.accScore[item.account_id].identifier_category).map(([key, value], i) => {
																							return(
																								<React.Fragment>
																									{i===0 ? <p className={`font-weight-bold mb-2 mt-1 text-white`}>Scores</p> : null }			
																									<div className={` ${Object.entries(this.state.accScore[item.account_id].identifier_category).length === 1 ? "w-100" :"w-50 pr-2"} ${i%2 ? "float-right" : Object.entries(this.state.accScore[item.account_id].identifier_category).length === 1 ? "" :"border-right float-left" } `}>
																										<div className="d-flex justify-content-between mb-2 ml-2">
																											<div className="mb-0 align-self-center">
																												<h8 className="font-weight-bold text-white">{key}</h8>
																											</div>
																											<div className={`mb-0 text-right ${Object.entries(this.state.accScore[item.account_id].identifier_category).length === 1 ? "w-10" :"w-25"} `}>
																												<CircularProgressbar
																													value={(value.split("/"))[0]}
																													text={(value.split("/"))[0]}
																													counterClockwise={false}
																													styles={{trail: {stroke: "#353C4C",strokeWidth:"5"},path: {stroke: "#039BE5",strokeWidth:"3"},text: {fill: "#039BE5",fontSize: "10px"}}}
																												/>
																											</div>
																										</div>
																									</div>	
																								</React.Fragment>
																							)
																							})
																					:
																						<React.Fragment>
																								<div className="mb-0 align-self-center">
																									<h8 className="font-weight-bold blackMuted">No Score</h8>
																								</div>
																								<div className={`mb-0 text-right w-10`}>
																									<CircularProgressbar
																										value={0}
																										text={"-"}
																										counterClockwise={false}
																										styles={{trail: {stroke: "#353C4C",strokeWidth:"5"},path: {stroke: "#039BE5",strokeWidth:"3"},text: {fill: "#039BE5",fontSize: "10px"}}}
																									/>
																								</div>
																							</React.Fragment>
																					}
																				</PopoverBody>
																			</UncontrolledPopover>
																		</div>
																	</div>
																)
															})
														:
															<div className='d-flex justify-content-center m-4'>
																<p>There are no data on this criteria. Please try adjusting your search.</p>
															</div>
														}
													</div>
												</div>
											</div>
											<div className="col-lg-7 col-xl-9 overflowYAuto" style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
												<div className="row border-bottom pb-3 ml-0 ">
													<div className="col-sm-9">
														<div className="row d-flex p-2 bg-dark rounded">
															{this.state.transitionTrend && this.state.transitionTrend.details && Object.entries(this.state.transitionTrend.details).length ?
																Object.entries(this.state.transitionTrend.details).map(([key, value]) => {
																	return(
																		<div className="w-25 pr-2">
																			<div className="d-flex">
																				<h5 className="text-white">
																					<small className="mr-2">{capitalizeFirstLetter(key)}</small>
																					<b>{value.count ? value.count : 0}</b>
																					<span className={`ml-2 f14 far fa-arrow-${(value.trend === null || value.trend === "increase") ?"up  text-success" : "down text-danger"} mr-2`}></span>
																					<small>{value.percent ? value.percent : 0}%</small>
																				</h5>
																			</div>
																			<div className="d-flex">
																				<div className={`mr-3 risk-critical`}>
																					<p className="mb-0 font-weight-bold">{value.severity_wise && value.severity_wise.Critical ? value.severity_wise.Critical : 0}</p>
																					<p className="small font-weight-bold align-self-end">Critical
																					</p>
																				</div>
																				<div className="mr-3 risk-high">
																					<p className="mb-0 font-weight-bold">{value.severity_wise && value.severity_wise.High ? value.severity_wise.High : 0}</p>
																					<p className="small font-weight-bold align-self-end">High</p>
																				</div>
																				<div className="mr-3 risk-medium">
																					<p className="mb-0 font-weight-bold">{value.severity_wise && value.severity_wise.Medium ? value.severity_wise.Medium : 0}</p>
																					<p className="small font-weight-bold align-self-end">Medium
																					</p>
																				</div>
																				<div className="risk-low">
																					<p className="mb-0 font-weight-bold">{value.severity_wise && value.severity_wise.Low ? value.severity_wise.Low : 0}</p>
																					<p className="small font-weight-bold align-self-end">Low</p>
																				</div>
																			</div>
																			{this.state[key+"_trend_data"] && Object.keys(this.state[key+"_trend_data"]).length ?
																				<div className="w-100 ml-n2 mt-n4 mb-n4">
																					<ApexAreaChart 
																						graphData={this.state[key+"_trend_data"]}
																						name={"New"}
																						xaxis={false}
																						yaxis={true}
																						yaxisLabe={false}
																						markerSize={2}
																						hideMarkerLength={30}
																						gradient={true}
																						gradientColor={["#775BA2", "#775BA2"]}
																						colors={["#775BA2"]}
																						sparkline={false}
																						zoom={false}
																						height={90}
																						width={"100%"}
																						grid={true}
																						className={"transparentTooltip"}
																					/>
																			</div>
																			: null}
																		</div>		
																	)
																})
															: null}
														</div>
													</div>
													<div className="col-xl-3 m-0">
														<div className="bg-dark p-2 rounded">
															{/* <div className="d-flex justify-content-between bg-light rounded p-2 text-muted">
																<div className="d-flex ml-1">
																	<h3 className="pr-2 mr-2 border-right mb-0 align-self-center">{numberFormater(this.state.passSum ? this.state.passSum : "")}</h3>
																	<p className="f12 mb-0 pr-3 align-self-center">Checks passed is <b>{this.state.passSum ? this.state.passSum : ""}</b></p>
																</div>
																<div className="d-flex">
																	<h3 className="pr-2 mr-2 border-right mb-0 align-self-center">{numberFormater(this.state.failSum ? this.state.failSum : "")}</h3>
																	<p className="f12 mb-0 align-self-center">Checks failed is <b>{this.state.failSum ? this.state.failSum : ""}</b></p>
																</div>
															</div> */}
															<div className={``}>
																<p className="mb-1 f16 text-white">Checks</p>
																<div className="d-flex">
																	<div className="pr-2 text-warning">
																		<p className="f12 d-flex mb-0 font-weight-bold mr-1">{this.state.passSum ? this.state.passSum : ""}<small className="d-flex pl-1 text-gray3 align-self-center">Pass (
																			{this.state.percentStatusCount && Object.entries(this.state.percentStatusCount).length ?
																				<div className="d-flex">
																					<span className= {`mr-2 align-self-center ${(this.state.percentStatusCount["current"].pass > this.state.percentStatusCount["past"].pass) ? "far fa-arrow-up text-success mr-2": "far fa-arrow-down text-success"} `} ></span>
																					<b>{this.state.percentStatusCount["percent"].pass} %</b>
																				</div>
																			: null
																			}
																		) </small></p>
																		
																	</div>
																	<div className="text-dark-theme-orange">
																		<p className="f12 d-flex mb-0 font-weight-bold mr-1">{this.state.failSum ? this.state.failSum : ""}
																		<small className="d-flex pl-1 text-gray3 align-self-center">Fail (
																			{this.state.percentStatusCount && Object.entries(this.state.percentStatusCount).length ?
																				<div className="d-flex">
																					<span className= {`mr-2 align-self-center ${(this.state.percentStatusCount["current"].fail > this.state.percentStatusCount["past"].fail) ? "far fa-arrow-up text-danger": "far fa-arrow-down text-danger"}`} ></span>
																				<b>{this.state.percentStatusCount["percent"].fail} %</b>			</div>
																			: null}
																		)</small></p>
																	</div>
																</div>
															</div>
															<p className="m-0 f12 mb-0 pr-3 displayNone"> Daily trend is shown below</p>
															{this.state.multi_pass_fail_series && this.state.multi_pass_fail_series.length ? 
																<div className="transparentTooltip mt-3 mb-2">
																	<Chart type="bar" series={this.state.multi_pass_fail_series} options={this.state.multi_pass_fail_options} height={75}/>
																</div>		
															: null}
														</div>
														<div className="d-flex flex-column displayNone">
															<div className="d-flex">
															<h2 className="mr-3 pr-3 border-right mb-0 align-self-center">{numberFormater(this.state.passSum ? this.state.passSum : "" ? this.state.failSum : "")}</h2>
															<p className="f12 mb-0">Number of checks failed is <b>{this.state.failSum ? this.state.failSum : ""}</b>. Daily trend is shown below</p>
															</div>
															{this.state.fail_series && this.state.fail_series.length ? 
																<div className="transparentTooltip mt-n1 mb-n2">
																	<Chart type="area" series={this.state.fail_series} options={this.state.fail_options} height={100}/>
																</div>
															: null}
															<div className="d-flex mt-3">
																{this.state.percentStatusCount && Object.entries(this.state.percentStatusCount).length ?
																	<p className="mr-3">
																		<span className= { (this.state.percentStatusCount["current"].fail > this.state.percentStatusCount["past"].fail) ? "far fa-arrow-up text-danger mr-2": "far fa-arrow-down text-danger mr-2"} ></span>
																		<b>{this.state.percentStatusCount["percent"].fail} %</b>
																		<small className="ml-2">decrease in fail</small>
																	</p>
																: null
																}
															</div>
														</div>
													</div>
												</div>
												<div className="row border-bottom py-3 ml-0 ">
													<div className="col-xl-7 pl-0">
														<div className="m-0 p-2 bg-dark rounded" style={{minHeight: "378px"}}>
															{this.state.dayWiseRiskCountGraphData && Object.keys(this.state.dayWiseRiskCountGraphData).length ?
																<ApexRiskStackedBarChart
																	graphData={this.state.dayWiseRiskCountGraphData}
																	graphRiskCountSection={true}
																	sparkline={false}
																	yaxis={true}
																	yaxisLabel={true}
																	xaxis={true}
																	xaxisFormat={"string"}
																	xaxisLabel={true}
																	axisLabelColor={"#50586A"}
																	legend={false}
																	stacked={true}
																	height={280}
																	horizontal={false}
																	barHeight={"40%"}
																	barEndShape={"flat"}
																	columnWidth={"25%"}
																	backgroundBarShape={"rounded"}
																	backgroundBarColors={["#333947"]}
																	showBackgroundBarColors={true}
																	className={"transparentTooltip"}
																	colors={["#5F5BA2", "#9A77D1", "#3F73AD", "#039BE5"]}
																/>
															: null}
														</div>
													</div>
													<div className="col-xl-5 pl-0">
														<div className="m-0 p-2 bg-dark rounded">
															<p className="mb-0 f16 text-white">Category wise scores & Daily trends</p>
															{this.state.categoryScoreList && this.state.categoryScoreList.map((categoryItem, y) => {
																return (
																	<div key={y} className="d-flex mb-2">
																		<div id={categoryItem.category} className="w-8 align-self-center" >
																			<Link
																				to={{
																					pathname: URL_PATH.CHECKS_SEARCH,
																					state: { 
																						selectedProvider: this.state.selectedProvider, 
																						selectedDuration: this.state.selectedDuration, 
																						selectedAccount: this.state.selectedAccount, 
																						selectedAccountName: this.state.selectedAccount ? getAccountNameFromId(this.state.selectedAccount, this.state.accounts): "",
																						selectedCategory: categoryItem.category, 
																						from: this.props.location 
																					}
																				}}
																			>
																				<CircularProgressbar
																					value={categoryItem.data}
																					text={categoryItem.data?categoryItem.data:"0"}
																					counterClockwise={true}
																					styles={{trail: {stroke: "#353C4C",strokeWidth:"8"},path: {stroke: "#039BE5 ",strokeWidth:"6"},text: {fill: "#039BE5 ",fontSize: "20px"}}}
																				/>
																				<UncontrolledTooltip placement="top" target={categoryItem.category}>{categoryItem.category + " overall score is " +categoryItem.data}</UncontrolledTooltip>
																			</Link>
																		</div>
																		<div className="w-90 d-flex border-blue rounded-pill p-1 float-left ml-3 align-self-center text-muted">
																			<div className="transparentTooltip col-sm-5 p-2 mt-1 align-self-center">
																				{this.state["bar_"+categoryItem.category+"_data"] && this.state["bar_"+categoryItem.category+"_data"].length &&  this.state.bar_category_option ?
																					<Chart type="bar" series={this.state["bar_"+categoryItem.category+"_data"]} width="100%" height={30} options={this.state.bar_category_option}/> 
																				: null}
																			</div>
																			<div className="col-sm-7 p-0 m-0 align-self-center d-flex">
																				<div className="align-self-center w-60 p-0 m-0">
																					<small className="f16 text-white">{categoryItem.category}</small>
																				</div>
																				<div className="w-40 pl-3 m-0">
																					<div className="d-flex">
																						<small className="w-50 text-white">Pass</small> 
																						<small className="w-50 text-white align-self-center">: <b className="text-dark-theme-green">{this.state[categoryItem.category] && this.state[categoryItem.category].pass ? this.state[categoryItem.category].pass : 0}</b></small>
																					</div>
																					<div className="d-flex">
																						<small className="w-50 text-white">Fail</small> 
																						<small className="w-50 text-white align-self-center">: <b className="text-dark-theme-green">{this.state[categoryItem.category] && this.state[categoryItem.category].fail ? this.state[categoryItem.category].fail : 0}</b></small>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																)
															})}
														</div>
													</div>
												</div>
												{/* <div className="row border-bottom py-3 ml-0">
													<div className="d-flex align-self-start">
														<div className="col-sm-3 d-flex flex-column text-center align-self-end">
															<p className="mb-2 f12">
																<span className="text-highlight mr-3">{this.state.processCount.event_id}</span>
																<span className="text-success font-weight-bolder">Total Events processed is </span>
															</p>
														</div>
														<div className="col-sm-3 d-flex flex-column text-center align-self-end mx-3">
															<h4 className="text-danger">{numberFormater(this.state.processCount.asset_id)}</h4>
															<small className="f8">Total Assets Scanned is {this.state.processCount.asset_id} Lorem ipsum dolor sit amet, consectetu.</small>
														</div>
														<div className="col-sm-3 d-flex flex-column text-center align-self-end mx-3">
															<h5>{this.state.processCount.resource_type}</h5>
															<small className="f8">Services</small>
														</div>
													</div>
												</div> */}
												<div className="my-3 bg-dark p-2 rounded">
													<div className="col-12">
														<div className="d-flex justify-content-between">
															<p className="f16 text-white mb-2">Compliance Controls</p>
															<div className="d-flex">
																<small className="mr-3">
																	Showing 
																	{this.state.complianceIdCounts &&  this.state.complianceIdCounts.length ?  
																		" "+(this.state.showingOffStart) +" - "+ this.state.showingOff +" ("+this.state.complianceIdCounts.length+") "
																	: " 0"
																	} 
																</small>
																<span className="far fa-chevron-left mr-3 text-muted cursorPointer" onClick={() => this.slideLeft()}></span>
																<span className={`far fa-chevron-right cursorPointer ${this.state.showingRightIcon ? "" : "disabled"}`}  onClick={() => this.slideItemRight("")}></span>
															</div>
														</div>
														{/* <p className="f12 fw400">Sed in semper ex. Nulla sed finibus orci, eget semper odio. Aenean viverra bibendum ultrices viverra bibendum ultrices..</p> */}
														<div ref={this.sliderWrap} className="d-flex flex-nowrap overflow-hidden">
															{this.state.complianceIdCounts.map((res, i) => {
																return (
																	<Link key={i}
																		className="box hippa-box bg-muted shadow-none p-3 mr-3"
																		to={{
																			pathname: URL_PATH.COMPLIANCE_DASHBOARD,
																			state: { 
																				selectedProvider: this.state.selectedProvider, 
																				selectedDuration: this.state.selectedDuration, 
																				selectedAccount: this.state.selectedAccount, 
																				selectedAccountName: this.state.selectedAccount ? getAccountNameFromId(this.state.selectedAccount, this.state.accounts): "",
																				selectedComplicance: res.complianceId, 
																				from: this.props.location,
																				complianceCoverage: this.state.complianceCoverage && this.state.complianceCoverage[res.complianceId] ? this.state.complianceCoverage[res.complianceId] : "0"
																			}
																		}}
																	>
																		<div ref={this.sliderItemWrap}>
																			<h6 className="text-white">{res.complianceId.toUpperCase()}</h6>
																			<div className="d-flex">
																				<div className="mr-3 hippa-chart">
																				<CircularProgressbar
																					value={res.value}
																					text={res.value}
																					counterClockwise={true}
																					styles={{trail: {stroke: "#353C4C",strokeWidth:"8"},path: {stroke: "#039BE5",strokeWidth:"6"},text: {fill: "#039BE5",fontSize: "25px"}}}/>
																				</div>
																				
																					
																				<React.Fragment>
																					<div className="d-flex flex-column text-center align-self-center mr-3">
																						<p className="mb-0 text-warning">{numberFormater(res.pass_count)}</p>
																						<p className="mb-0 text-warning">Pass</p>
																						{/* <div className="progress mt-2">
																							<div className="progress-bar bg-warning" role="progressbar" style={{width: "50%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
																						</div> */}
																					</div>
																					<div className="d-flex flex-column text-center align-self-center">
																						<p className="mb-0 text-dark-theme-orange">{numberFormater(res.fail_count)}</p>
																						<p className="mb-0 text-dark-theme-orange">Fail</p>
																						{/* <div className="progress mt-2">
																							<div className="progress-bar bg-dark-theme-orange" role="progressbar" style={{width: "50%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
																						</div> */}
																					</div>
																				</React.Fragment>
																			</div>
																		</div>					
																	</Link>
																)
															})}
														</div>
													</div>
												</div>
												<div className="border-bottom bg-dark p-2 displayNone">
													<p className="f16 text-white">Observations</p>
													<div className="row">
														<div className="col-sm-6 col-xl-3 mb-3">
															<p className="mb-2 f12 d-flex">
																{/* <span className="d-block text-highlight mr-2">{(this.state.processCount.event_id)}</span> */}
																<span className="d-block text-muted font-weight-bolder"><b className="text-primary f18">500</b> checks were conducted on <b className="text-primary f18">324</b> important events occured in your cloud</span>
															</p>
														</div>
														<div className="col-sm-6 col-xl-3 mb-3">
															<p className="mb-2 f12 d-flex">
																<span className="d-block text-muted font-weight-bolder">Events checked were 
																<span className="font-weight-bold mx-2 text-danger f18">
																	{(this.state.processCount && this.state.processCount.event_id ? this.state.processCount.event_id : "")}
																	<span className="far fa-arrow-up"></span>
																</span>
																than previuos month/week.</span>
															</p>
														</div>
														<div className="col-sm-6 col-xl-3 mb-3">
															<p className="mb-2 f12 d-flex">
																<span className="text-muted font-weight-bolder"><span className="f18 mr-2 text-success">25</span> events/types had contributed to 256 failed checks.</span>
															</p>
														</div>
														<div className="col-sm-6 col-xl-3 mb-3">
															<p className="mb-2 f12 d-flex">
																<span className="text-muted font-weight-bolder"><span className="f18 mr-2 text-primary">50%</span> of failed checks were generated in EC2, S3 service</span>
															</p>
														</div>
														<div className="col-sm-6 col-xl-3">
															<p className="mb-2 f12 d-flex">
																{/* <span className="d-block text-highlight mr-2">{(this.state.processCount.event_id)}</span> */}
																<span className="d-block text-muted font-weight-bolder"><b className="text-primary f18">500</b> checks were conducted on <b className="text-primary f18">324</b> important events occured in your cloud</span>
															</p>
														</div>
														<div className="col-sm-6 col-xl-3">
															<p className="mb-2 f12 d-flex">
																<span className="d-block text-muted font-weight-bolder">Events checked were 
																<span className="font-weight-bold mx-2 text-danger f18">
																	{(this.state.processCount && this.state.processCount.event_id ? this.state.processCount.event_id : "")}
																	<span className="far fa-arrow-up"></span>
																</span>
																than previuos month/week.</span>
															</p>
														</div>
														<div className="col-sm-6 col-xl-3">
															<p className="mb-2 f12 d-flex">
																<span className="text-muted font-weight-bolder"><span className="f18 mr-2 text-success">25</span> events/types had contributed to 256 failed checks.</span>
															</p>
														</div>
														<div className="col-sm-6 col-xl-3">
															<p className="mb-2 f12 d-flex">
																<span className="text-muted font-weight-bolder"><span className="f18 mr-2 text-primary">50%</span> of failed checks were generated in EC2, S3 service</span>
															</p>
														</div>
														{/* <div className="col-sm-4">
															<h4 className="text-danger">{numberFormater(this.state.processCount.asset_id)}</h4>
															<small className="f8">Total Assets Scanned is {this.state.processCount.asset_id} Lorem ipsum dolor sit amet, consectetu.</small>
															<div className="progress mt-2">
																<div className="progress-bar bg-danger" role="progressbar" style={{width: "50%"}}
																aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
															</div>
														</div>
														<div className="col-sm-4">
															<h5>234</h5>
															<small className="f8">Lorem ipsum dolor sit amet, consectetu.</small>
															<div className="progress mt-2">
																<div className="progress-bar bg-success" role="progressbar" style={{width: "50%"}}
																aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
															</div>
														</div> */}
													</div>
												</div>
														
												<div className="row border-top py-3 ml-0">
													<div className="col-sm-6 pl-0">
														<div className="m-0 px-3 py-2 bg-dark rounded overflowYAuto" style={{minHeight: "414px"}}>
															<div className="d-flex justify-content-between border-bottom pb-1">
																<div>
																	<p className="f16 text-left m-0 text-white">Service&nbsp;Wise</p>
																	<p className="f12 fw400 m-0">Showing top services by risk count</p>
																</div>
																<div className="float-right align-self-center chDServiceWise">
																	<div className="d-flex">
																		<Spinner className={`align-self-center mr-2 ${this.state.serviceLoading ? "" : "displayNone"}`} size="sm" />
																		<div className="d-flex">
																			<div className="mr-3 w-fit-content">
																				<div className={`multiSelection-minWidth removeMargin z999 removeDropdownSearchBar position-relative bg-muted`} ref={this.serviceWiseRef}>
																					<ReactMultiSelectCheckboxes						
																						placeholderButtonLabel="All"
																						menuIsOpen ={this.state.serwiseDropdown}
																						value={this.state.selectedService.map(service => ({
																							value: service,
																							label: service
																						}))}
																						onChange={ arr => {this.tablefilterMultiSelectChange("users", "selectedService", arr ? arr : []) }}
																						options={this.state.parentServices.map(service => ({
																							value: service.label,
																							label: service.label,
																						}))}
																					/>
																				</div>
																			</div>
																		</div>
																		{/* <ReactMultiSelectCheckboxes
																			value={this.state.selectedService.map(service => ({
																					value: service,
																					label: service
																			}))}
																			onChange={ arr => {this.tablefilterMultiSelectChange("users", "selectedService", arr ? arr : []) }}

																			options={this.state.parentServices.map(service => ({
																				value: service.label,
																				label: service.label,
																			}))}

																		/> */}
																	</div>
																</div>
															</div>
															<div className="mt-1">
																{this.state.filterServiceCount && this.state.filterServiceCount.length > 0 ? 
																this.state.filterServiceCount.slice(this.state.serviceWiseStart, this.state.filterServiceCountLength).map((risk,i) => {
																	let lowRiskBar = ((risk.Low * 100) / risk.totalFail) +"%"
																	let mediumRiskBar = ((risk.Medium * 100) / risk.totalFail) +"%"
																	let highRiskBar = ((risk.High * 100) / risk.totalFail) +"%"
																	let criticalRiskBar = ((risk.Critical * 100) / risk.totalFail) +"%"
																	return (
																		<div className="d-flex justify-content-between mb-1" key={i}>
																			<div className="w-30 text-left align-self-center">
																				<p className="font-weight-bold f10 m-0">{risk.name}</p>
																			</div>
																			<div className="w-50 align-self-center">
																			{risk.totalFail > 0 ?
																				<div className="stock-progress">
																					<div className="risk-bg-critical" style={{width:criticalRiskBar}} id={`assetCritical-${i}`}></div>
																					<div className="risk-bg-high" style={{width:highRiskBar}} id={`assetHigh-${i}`}></div>
																					<div className="risk-bg-medium" style={{width:mediumRiskBar}} id={`assetMedium-${i}`}></div>
																					<div className="risk-bg-low" style={{width:lowRiskBar}} id={`assetLow-${i}`}></div>

																					<UncontrolledTooltip placement="top" target={`assetCritical-${i}`}>Critical {risk.Critical}</UncontrolledTooltip>		
																					<UncontrolledTooltip placement="top" target={`assetHigh-${i}`}>High {risk.High}</UncontrolledTooltip>
																					<UncontrolledTooltip placement="top" target={`assetMedium-${i}`}>Medium {risk.Medium}</UncontrolledTooltip>
																					<UncontrolledTooltip placement="top" target={`assetLow-${i}`}>Low {risk.Low}</UncontrolledTooltip>
																				</div>
																			: 
																				<div className="stock-progress">
																					<div className="stock-5"></div>
																				</div>
																			}
																			</div>
																			<div className="w-20 pl-2 text-left"><small>{risk.totalFail}</small></div>
																		</div>
																	)
																})
																
																:
																	<div className="d-flex justify-content-between">
																		<div className="w-30 text-left align-self-center">
																			<p className="font-weight-bold f10 m-0">{this.state.selectedService !== "" ? this.state.selectedService : "All"}</p>
																		</div>
																		<div className="w-50 align-self-center">
																			<div className="stock-progress">
																				<div className="stock-5"></div>
																			</div>
																		</div>
																		<div className="w-20 pl-2 text-left"><small>No&nbsp;Data</small></div>
																	</div>
																}
																{/* <p className={`text-info text-right mb-0 pt-2 small cursorPointer 
																	${this.state.filterServiceCount && this.state.filterServiceCount.length > 13 ? "" : "displayNone"} `}
																	onClick={()=>this.setState({ filterServiceCountLength: this.state.filterServiceCount.length > this.state.filterServiceCountLength ? this.state.filterServiceCount.length : 13 })}>
																	{this.state.filterServiceCount && this.state.filterServiceCount.length > this.state.filterServiceCountLength ? "See more services.." : this.state.filterServiceCount ? "See less services" : ""}
																</p> */}
																<div className="d-flex justify-content-between py-1">
																	{/* <small className="mr-3">
																		Showing 
																		{this.state.complianceIdCounts &&  this.state.complianceIdCounts.length ?  
																			" "+(this.state.showingOffStart) +" - "+ this.state.showingOff +" ("+this.state.complianceIdCounts.length+") "
																		: " 0"
																		} 
																	</small> */}
																	<div className="w-90">
																		<span className={`text-left far fa-chevron-left mr-3 text-muted cursorPointer ${!this.state.serviceWiseStart  ? "displayNone": ""}`} 
																			onClick={() => this.navigateServieWise("left") }>
																		</span>
																	</div>
																	<span className={`${(this.state.filterServiceCount && this.state.filterServiceCount.length > this.state.serviceWiseheight) && (this.state.filterServiceCount && this.state.filterServiceCount.length > this.state.filterServiceCountLength) ? "": "displayNone"} float-right text-right far fa-chevron-right cursorPointer`} 
																		onClick={() => this.navigateServieWise("right") }>
																	</span>
																</div>
															</div>
														</div>
													</div>
													<div className="col-sm-6 pl-0">
														<div className="m-0 px-3 py-2 bg-dark rounded overflowYAuto" style={{minHeight: "414px"}}>
															<div className="zapInputDark d-flex jus mt-2" ref={this.envRef}>
																<Select
																	placeholder={"All"}
																	isSearchable={false}
																	className={`f13 p-0 dark-select-theme-with-dropdown-indicator w-30`}
																	value={({
																		value: this.state.selectedTag,
																		label: this.state.selectedTag
																	})}
																	options={environemntDropdown.map(item => ({
																		value: item.value,
																		label: item.label,	
																	}))}
																	onChange={event =>  
																		this.setState({ selectedTag: event.value },
																			() => {
																				this.getScore()
																				this.getDayWiseCountTags()
																			}
																		)
																	}
																/>
																<small className="ml-3 align-self-center">Health Scores by Popular Tags like Environment, Application, Cost Center, ..</small>
															</div>
															<div className="d-flex mt-3">
																{this.state.score && this.state.score.length > 0 ?
																	this.state.score.slice(0,5).map((res,i)=>{
																		let circleColor = "blue"
																		if(i === 0) { circleColor = "#DC2B55";
																		} else if(i === 1) { circleColor = "#06CB9E";
																		} else if(i === 2) { circleColor = "#693EBC";
																		} else if(i === 3) { circleColor = "#1FCFE7";
																		} else if(i === 4) { circleColor = "#2F2A40"; }
																		return (
																			<div className={"text-center m0 p3 mr10 "+(i!==0 && (i+1) !== this.state.score.length?"col-sm-2":"col-sm-2")} key={i}>
																				<CircularProgressbar value={(res.overall.split("/"))[0]} text={(res.overall.split("/"))[0]} counterClockwise={true} styles={{trail: {stroke: "#353C4C",strokeWidth:"8"},path: {stroke: circleColor,strokeWidth:"6"}}}/>
																				<p className="f10 p3 m0">{res.tags[0].value}</p>
																			</div>
																		)
																	})
																: null}
																														
																<div className="pill-tabs">
																	{this.state.score && this.state.score.length ?
																		<React.Fragment>
																			<span className="col-sm-1 far fa-chevron-left text-muted mr-2 cursorPointer float-left" style={{lineHeight:2}} onClick={() => this.envSlideLeft()}></span>											
																			<ul className="col-sm-9 nav nav-tabs mb0 d-flex flex-nowrap overflow-hidden float-left" style={{paddingBottom:2}} id="myTab" ref={this.proEnvWrap} role="tablist">
																				
																				{this.state.score.slice(0,5).map((res,i)=>{
																					return(
																						<li key={i} className="nav-item mb5">
																							<span className={"nav-link text-nowrap cursorPointer "+(this.state.detectorKey === res.tags[0].value? "active":"")} onClick={(e)=>{this.setState({detectorKey:res.tags[0].value},()=>{this.getTagwiseChangeDetails()})}}>{res.tags[0].value}</span>
																						</li>
																					)
																				})}
																				
																			</ul>
																			<span className="col-sm-1 far fa-chevron-right text-dark cursorPointer ml-2" onClick={() => this.envSlideItemRight()}></span>
																		</React.Fragment>
																	: 
																		<p className="d-flex justify-content-center">No Score for the selected Tag</p>
																	}
																	
																	<div className="tab-content mt-3" id="myTabContent">
																		{this.state.bar_data && this.state.bar_data.length && this.state.bar_option ?
																			<div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
																				<p className="f12 mb-1">Status by Risk level for the duration selected is shown.</p>
																				<div className="d-flex justify-content-between f12 mt-3 position-relative z999 mb-n4">
																					<div className={`cursorPointer text-info ${this.state.disablePreviousWeekBtn ? "disabled" : ""} ${this.state.hidePreviousNextBtn ? "displayNone" : "displayBlock"}`} onClick={()=> {this.setState({showPrevNextRecord:(this.state.showPrevNextRecord - 1)},() => {this.showSummaryGraph()})}}><span className="far fa-angle-double-left"></span> Previous </div>
																					<div className={`text-info ${this.state.disableNextWeekBtn ? "disabled cursorPointer" : " cursorPointer"} ${this.state.hidePreviousNextBtn ? "displayNone" : "displayBlock"}`} onClick={()=> {this.setState({showPrevNextRecord:(this.state.showPrevNextRecord + 1)},() => {this.showSummaryGraph()})}}>Next <span className="far fa-angle-double-right"></span></div>
																				</div>
																			
																				<div className="transparentTooltip">
																					<Chart type="bar" series={this.state.bar_data} width="100%" height={254} options={this.state.bar_option}/>
																				</div>
																			</div>
																		: 
																		null

																		}
																		<div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">...</div>
																		<div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">...</div>
																	</div>
																</div>
																<div className="d-flex mt-3 displayNone">
																	<div className="mr-4">
																		<h4 className="font-weight-normal">
																			{this.state.billingCount.total_bill && "$"+this.state.billingCount.total_bill}
																		</h4>
																		<span className="badge badge-outline-primary d-table my-3 f12 mx-auto">Pellentesque </span>
																		
																		{this.state.cost_pass_day_wise_count_data && this.state.cost_pass_day_wise_count_data.length && this.state.cost_pass_day_wise_count_options ?
																			<Line data={this.state.cost_pass_day_wise_count_data} options={this.state.cost_pass_day_wise_count_options} width={80} height={35}/>

																		: null
																		}
																	</div>
																	<div>
																		<h4 className="text-info font-weight-normal">
																			{
																				this.state.billingCount.savings&&
																				"$"+this.state.billingCount.savings
																			}
																		</h4>
																		<p className="mb-1"><b>Lorem Ipsum</b></p>
																		<p className="f12 fw400 mb-1">Sed in semper ex. Nulla sed finibus orci, eget semper odio. Aenean viverra bibendum ultrices viverra bibendum ultrices..</p>
																	</div>
																</div>
																<div className="displayNone">
																	<p className="mt-3 mb-0 font-weight-bold">
																		Service wise
																	</p>
																	<div className="d-flex">
																		<div>
																		{
																			this.state.doughnut_data && Object.entries(this.state.doughnut_data).length ?
																			<Doughnut data={this.state.doughnut_data} options={this.state.doughnut_options} width={250} height={220} />
																			: null
																		}
																		</div>
																		<div>
																			<p className="mb-2 f12">
																				<span className="text-highlight mr-3">47%</span>
																				<span className="text-success font-weight-bolder">Lorem ipsum dolor sit amet, consectetur adipiscing elit</span>
																			</p>
																			<p className="f12">
																				<span className="text-highlight mr-3">47%</span>
																				<span className="text-success font-weight-bolder">Lorem ipsum dolor sit amet, consectetur adipiscing elit</span>
																			</p>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
Dashboard.propTypes = {
	listAllProviders: PropTypes.func,
	listAllAccounts: PropTypes.func,
	listAllServices: PropTypes.func,
	getTotalCount: PropTypes.func,
	getDayWiseCount: PropTypes.func,	
	location: PropTypes.object,
	getScore:PropTypes.func,
	getDayWiseCountTags:PropTypes.func,
	getBillingCount:PropTypes.func,
	getIssueStatusCount:PropTypes.func,
	getStatusCount:PropTypes.func,
	getCount:PropTypes.func,
	getCostTrend:PropTypes.func,
	getDailyScore:PropTypes.func,
	getTransitionTrend:PropTypes.func
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		providers: state.filters.providers,
		accounts: state.filters.accounts,
	}
}

export default AppWrapper(Dashboard, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllServices,
	getVariousCountsByAccount,
	getTotalCount,
	getDayWiseCount,	
	getScore,	
	getBillingCount,
	getDayWiseCountTags,
	getIssueStatusCount,
	getStatusCount,
	getCount,
	getCostTrend,
	getDailyScore,
	getTransitionTrend,
	getComplianceCoverage
})



