
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file RevokeSecurityGroupIngress.js
 * @author Prakash // on 25/05/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Select from 'react-select';
import { Input } from 'reactstrap'
import { setAutomationPropsDetails } from '../../../actions/commonAction'
import { onlyNumeric } from '../../../utils/utility';

const ipOptions = [
    {label: "Ip Ranges", value: "IpRanges"},
    {label: "Ipv6 anges", value: "Ipv6Ranges"},
    {label: "User Id Group Pairs", value: "UserIdGroupPairs"}
]

const groupOptions = [
    {label: "Group Id", value: "GroupId"},
    {label: "Group Name", value: "GroupName"},
    {label: "Peering Status", value: "PeeringStatus"},
    {label: "User Id", value: "UserId"},
    {label: "Vpc Id", value: "VpcId"},
    {label: "Vpc Peering Connection Id", value: "VpcPeeringConnectionId"}
]

class RevokeSecurityGroupIngress extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            activeSection: this.props.activeSection,
        }
    }

    componentDidMount = () => {
        if(this.props.automationPropsDetails) {
            if(this.props.automationPropsDetails) {
                let obj = this.props.automationPropsDetails
                let exist = obj.actions.filter(e => e.section === this.state.activeSection)
    
                if(exist.length) {
                    this.setState({ 
                        inputDetailArray: exist[0].input ? exist[0].input : [],
                    },
                        () => this.structureInput()
                    )
                }
            }
        }
    }

    componentDidUpdate = (prevProps) => {}

    structureInput = () => {
        let data = this.props.identifierDetail && this.props.identifierDetail.additional_details ? this.props.identifierDetail.additional_details : ""
        if(this.state.inputDetailArray) {
            this.state.inputDetailArray.forEach(item => {
                // if(item.value_object_list && item.value_object_list.length)
            })
        }

        if(data !== "") {
            
            let additionalDetails = JSON.parse(data)
            // let portDetails = additionalDetails.fromPort +" - "+ additionalDetails.fromPort
            additionalDetails && additionalDetails.length && additionalDetails.forEach((adDet, index) => {
                let portAddress = adDet.fromPort ? adDet.fromPort : "" +" - "+ adDet.toPort ? adDet.toPort : ""
                let ipProtocol = adDet.ipProtocol ? adDet.ipProtocol : ""

                let ipAddress = ""
                let selectedRange = ""
                if(adDet.ipv4Ranges && adDet.ipv4Ranges.length) {
                    ipAddress = adDet.ipv4Ranges[0].cidrIp
                    selectedRange = "IpRanges"
                } else if(adDet.ipv4Ranges && adDet.ipv4Ranges.length) {
                    ipAddress = adDet.ipv6Ranges[0].cidrIp
                    selectedRange = "Ipv6Ranges"
                } 
                let groupId = ""
                if(adDet.userIdGroupPairs && adDet.userIdGroupPairs.length) {
                    groupId = adDet.userIdGroupPairs[0].GroupId
                    selectedRange = "UserIdGroupPairs"
                }
    
                this.setState({ 
                    additionalDetails,
                    // portDetails,
                    ["portAddress_"+index]: portAddress,
                    ["FromPort_"+index]: adDet.fromPort,
                    ["ToPort_"+index]: adDet.toPort,
                    ["IpProtocol_"+index]: ipProtocol,
                    ["selectedRange_exisitng_"+index]: selectedRange,
                    ["ipAddress_existing_"+index]: ipAddress,
                    ["group_existing_"+index]: "GroupId",
                    ["group_input_existing_"+index]: groupId,
                },
                    () => {
                        if(additionalDetails.length === (index+1)) {
                            this.formInputProps()
                        }
                    }
                )
            })
        }
    }

    formInputProps = (section, field, value) => {
        let additionalDetails = this.state.additionalDetails
        let obj = this.props.automationPropsDetails
        let actions = obj.actions.filter(e => e.section === this.state.activeSection)
        let inputDetails = actions[0].input ? actions[0].input : []
        let input = []

        let existingRow = {}
        existingRow.key = "IpPermissions"
        let existingValueObjectList = []
        additionalDetails.forEach((addDet, index) => {
            inputDetails.forEach(item => {
                if(item.key === "IpPermissions") {
                    let data = []
                    item.value_object_list.forEach(objList => {
                        if(objList.key === "FromPort" || objList.key === "ToPort" || objList.key === "IpProtocol") {
                            let dataRow = {}
                            dataRow.key = objList.key
                            dataRow.value = this.state[objList.key+"_"+index]
                            data.push(dataRow)
                        } else if(objList.key === "IpRanges") {                        
                            if(this.state["selectedRange_exisitng_"+index] === "IpRanges") {
                                let dataRow = {}
                                dataRow.key = objList.key
                                let objectList = []
                                objList.value_object_list.forEach(childObj => {
                                    let dataChildRow = {}
                                    dataChildRow.key = childObj.key
                                    if(childObj.key === "Description") {
                                        dataChildRow.value = childObj.value
                                    } else {
                                        dataChildRow.value = this.state["ipAddress_existing_"+index]
                                    }
                                    objectList.push(dataChildRow)
                                })
                                dataRow.value_object_list = objectList
                                data.push(dataRow)
                            } else {
                                let dataRow = {}
                                dataRow.key = objList.key
                                let objectList = []
                                objList.value_object_list.forEach(childObj => {
                                    let dataChildRow = {}
                                    dataChildRow.key = childObj.key
                                    dataChildRow.value = ""
                                    objectList.push(dataChildRow)
                                })
                                dataRow.value_object_list = objectList
                                data.push(dataRow)
                            }
                        } else if(objList.key === "Ipv6Ranges") {
                            if(this.state["selectedRange_exisitng_"+index] === "Ipv6Ranges") {
                                let dataRow = {}
                                dataRow.key = objList.key
                                let objectList = []
                                objList.value_object_list.forEach(childObj => {
                                    let dataChildRow = {}
                                    dataChildRow.key = childObj.key
                                    if(childObj.key === "Description") {
                                        dataChildRow.value = childObj.value
                                    } else {
                                        dataChildRow.value = this.state["ipAddress_existing_"+index]
                                    }
                                    objectList.push(dataChildRow)
                                })
                                dataRow.value_object_list = objectList
                                data.push(dataRow)
                            } else {
                                let dataRow = {}
                                dataRow.key = objList.key
                                let objectList = []
                                objList.value_object_list.forEach(childObj => {
                                    let dataChildRow = {}
                                    dataChildRow.key = childObj.key
                                    dataChildRow.value = ""
                                    objectList.push(dataChildRow)
                                })
                                dataRow.value_object_list = objectList
                                data.push(dataRow)
                            }
                        } else if(objList.key === "UserIdGroupPairs") {
                            if(this.state["selectedRange_exisitng_"+index] === "UserIdGroupPairs") {
                                let dataRow = {}
                                dataRow.key = objList.key
                                let objectList = []
                                objList.value_object_list.forEach(childObj => {
                                    let dataChildRow = {}
                                    dataChildRow.key = childObj.key
                                    if(childObj.key === this.state["group_existing_"+index]) {
                                        dataChildRow.value = this.state["group_input_existing_"+index]
                                    } else {
                                        dataChildRow.value = ""
                                    }
                                    objectList.push(dataChildRow)
                                })
                                dataRow.value_object_list = objectList
                                data.push(dataRow)
                            } else {
                                let dataRow = {}
                                dataRow.key = objList.key
                                let objectList = []
                                objList.value_object_list.forEach(childObj => {
                                    let dataChildRow = {}
                                    dataChildRow.key = childObj.key
                                    dataChildRow.value = ""
                                    objectList.push(dataChildRow)
                                })
                                dataRow.value_object_list = objectList
                                data.push(dataRow)
                            }
                        }
                    })
                    existingValueObjectList.push(data)
                }
            })
        })
        existingRow.value_object_list = existingValueObjectList
        input.push(existingRow)

        actions[0].input = input
        obj.actions = actions
        console.log(obj)
        
        this.props.setAutomationPropsDetails(obj)
        this.checkAllDataFilled()
    }

    checkAllDataFilled = () => {
        let actionCompleted = true
        // if(!this.state.newSelectedRange || this.state.newSelectedRange === "") {
        //     actionCompleted = false
        // } else if((this.state.newSelectedRange === "IpRanges" || this.state.newSelectedRange === "Ipv6Ranges") && (!this.state.ipAddressValue || this.state.ipAddressValue === "")) {
        //     actionCompleted = false
        // } else if(this.state.newSelectedRange === "UserIdGroupPairs" && (!this.state.selectedGroup || this.state.selectedGroup === "" || !this.state.groupValue || this.state.groupValue === "")) {
        //     actionCompleted = false
        // }
        this.props.actionCompleted(actionCompleted)
    }

    render() {
        return (
            <div className="">
                <label className="text-info mt-3 mb-0">IP Permissions</label>
                {this.state.additionalDetails && this.state.additionalDetails.length ?
                    this.state.additionalDetails.map((item, index) => {
                        return(
                            <div className="d-flex mt-3">
                                <div className="w-15">
                                    <label className="mb-1">Port</label>
                                    <Input
                                        type="text"
                                        placeholder="endpoint"
                                        className="inputTextbox bg-gray5"
                                        readOnly={true}
                                        value={this.state["portAddress_"+index]}
                                        id={"source_endpoint"}
                                    />
                                </div>
                                <div className="pl-3 w-15">
                                    <label className="mb-1">Ip Protocol</label>
                                    <Input
                                        type="text"
                                        placeholder="port"
                                        className="inputTextbox bg-gray5"
                                        readOnly={true}
                                        value={this.state["IpProtocol_"+index]}
                                        id={"source_port"}
                                    />
                                </div>
                                <div className="pl-3 w-20">
                                    <label className="mb-1">Ranges</label>
                                    <Select 
                                        options={ipOptions.map(item => ({
                                            value: item.value,
                                            label: item.label,
                                        }))}
                                        isDisabled={true}
                                        value={({
                                            value: this.state["selectedRange_exisitng_"+index] && this.state["selectedRange_exisitng_"+index],
                                            label: this.state["selectedRange_exisitng_"+index] ? ipOptions.filter(e => e.value === this.state["selectedRange_exisitng_"+index])[0].label : <span className="placeholder">Select Range</span>
                                        })}
                                        onChange={event => 
                                            this.setState({ ["selectedRange_exisitng_"+index]: event.value },
                                                () => {
                                                    // this.formInputProps(this.state.activeSection, "cred_store", this.state.cred_store)
                                                }
                                            )
                                        }
                                    />
                                </div>
                                {this.state["selectedRange_exisitng_"+index] === "IpRanges" || this.state["selectedRange_exisitng_"+index] === "Ipv6Ranges" ?
                                    <div className="pl-3 w-30">
                                        <label className="mb-1">Ip</label>
                                        <Input
                                            type="text"
                                            placeholder="endpoint"
                                            className="inputTextbox bg-gray5"
                                            readOnly={true}
                                            value={this.state["ipAddress_existing_"+index]}
                                            id={"ip_address"}
                                        />
                                    </div>
                                : this.state.selectedRange === "UserIdGroupPairs" ?
                                    <React.Fragment>
                                    <div className="w-20">
                                        <label className="mb-1">Group Options</label>
                                        <Select 
                                            options={groupOptions.map(item => ({
                                                value: item.value,
                                                label: item.label,
                                            }))}
                                            readOnly={true}
                                            value={({
                                                value: this.state["group_existing_"+index] && this.state["group_existing_"+index],
                                                label: this.state["group_existing_"+index] ? this.state["group_existing_"+index] : <span className="placeholder">Select Group</span>
                                            })}
                                            onChange={event => 
                                                this.setState({["group_existing_"+index]: event.value },
                                                    () => {
                                                        // this.formInputProps(this.state.activeSection, "cred_store", this.state.cred_store)
                                                    }
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="pl-3 w-30">
                                        <label className="mb-1">Group Id</label>
                                        <Input
                                            type="text"
                                            placeholder="endpoint"
                                            className="inputTextbox bg-gray5"
                                            readOnly={true}
                                            value={this.state["group_input_existing_"+index]}
                                            id={"source_endpoint"}
                                        />
                                    </div>
                                    </React.Fragment>
                                : null}
                            </div>
                        )
                    })
                : null}
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
RevokeSecurityGroupIngress.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log("RevokeSecurityGroupIngress",state)
    return {
        automationPropsDetails: state.filters.automationPropsDetails
    }
}

export default connect(mapStateToProps, {
    setAutomationPropsDetails,
})(withRouter(RevokeSecurityGroupIngress))        