import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import TextField from '../../../common/formFields/TextField'
import { setAiopsPropsDetails } from '../../../../actions/aiops/AiopsAction'
import { getAssetDetailExt } from '../../../../actions/aiops/RemediationAction'

class ModifyLambdaTimeout extends Component {

  constructor(props) {
    super(props);
    this.state = props || {};
    this.state = {
      validationError: this.props.validationError,
      validationErrorMessage: this.props.validationErrorMessage,
      parentValue: this.props.value,
    }
    this.updateTextFormFieldValue = this.updateTextFormFieldValue.bind(this);
  }

  componentDidMount = () => {
    let params = {}
    params.provider = this.props.caseDetails.provider ? (Array.isArray(this.props.caseDetails.provider) ? this.props.caseDetails.provider[0].toLowerCase() : this.props.caseDetails.provider.toLowerCase()) : ''
    params.service_name = this.props.aiopsSelectedRemediationProps.service_name
    params.asset_id = this.props.aiopsSelectedRemediationProps.asset_id

    this.props.getAssetDetailExt(params, (promise, result) => {
      console.log(result,'/././././')
      if(promise) {
        this.setState({ lambda_function_timeout: result && result[0] && result[0].lambda_function_timeout ? result[0].lambda_function_timeout : ''  })
      } else {
        this.setState({ result: [] })
      }
    })
  }

	componentDidUpdate = (prevProps) => {
		if(this.props.validationError !== prevProps.validationError) {
			this.setState({ 
        validationError: this.props.validationError, 
        timeoutError: this.props.aiopsRemediationParams.timeoutError ? this.props.aiopsRemediationParams.timeoutError : ''
      })
		}
	}

  updateTextFormFieldValue = (fieldName, fieldValue) => {
    let obj = this.props.aiopsRemediationParams
    obj[fieldName] = fieldValue
    obj.processor_rule_id = this.props.selectedRecord
    this.props.setAiopsPropsDetails('remediationParams', obj)
    this.setState({ [fieldName]: fieldValue, replicaIdentifierError: '',  });
  };

  render() {
    return (
      <div className="row mt-2">
        <div className="col-md-12">
          <div className="form-group">
            <label>Timeout <small className="ml-1">Current TimeOut Configuration: {this.state.lambda_function_timeout ? this.state.lambda_function_timeout : ''} seconds </small></label>
            <TextField
              className="form-control bg-muted text-gray3 col-sm-3"
              labelClassName={'text-gray3'}
              recordSelection={this.updateTextFormFieldValue}
              value={this.state.Timeout ? this.state.Timeout : ''}
              name={'Timeout'}
              label="Name of Replica DB Instance"
              placeholder=""
              required={true}
              unitLabel={''}
              number={true}
            />
            {this.state.validationError && this.state.timeoutError && this.state.timeoutError !== '' ?
              <small className='text-danger'>{this.state.timeoutError}</small>
            : null }
          </div>
        </div>
      </div>
    )
  }
};

/**
 * Type of the props used in the component
 */
 ModifyLambdaTimeout.propTypes = {
  getAssetDetailExt: PropTypes.func,
  setAiopsPropsDetails: PropTypes.func
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
 const mapStateToProps = state => {	
	console.log('ModifyLambdaTimeout', state)  
	return {
    caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : [],
    aiopsRemediationParams: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.remediationParams ? state.aiops.aiopsPropsDetails.remediationParams : {},
    aiopsSelectedRemediationProps: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.selected_remediation ? state.aiops.aiopsPropsDetails.selected_remediation : {},
  }
}


export default connect(mapStateToProps, {
  getAssetDetailExt,
  setAiopsPropsDetails
})(withRouter(ModifyLambdaTimeout))
