/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AutomationDetails.js
 * @author Prakash // on 04/01/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { AppWrapper } from '../common/AppWrapper'
import { momentConvertionUtcToLocalTime, capitalizeFirstLetter, momentDateGivenFormat, unitTimeConvertionDetail, twoDateDiffrenceDayHoursMinutes, subDays, onlyNumeric } from '../../utils/utility'
import { CUSTOM_DURATION_FILTER_OPTIONS } from '../../utils/constants'
import { UncontrolledTooltip, Spinner } from 'reactstrap'
import _ from 'lodash'
import SearchWithHiddenInput from '../common/SearchWithHiddenInput'

import { listAllProviders, listAllAccounts, listAllRegions } from '../../actions/commonAction'
import { listAutomationExecutions, getAutomationExecution, getAutomationExecutionDailyTrend } from '../../actions/AutomationAction'

import ResizeableDarkThemeTable from '../designComponents/Table/ResizeableDarkThemeTable'

import Select from 'react-select'

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";

import  ApexStackedBarChart from '../common/charts/ApexStackedBarChart'

import moment from "moment";
var today = moment()
var valid = current => {
    return current.isBefore( today );
};

const status = [
    {key: "All", value: "All"},
    {key: "Success", value: "Success"},
    {key: "Failed", value: "Failed"}
]

const automationSortBy = ["Date", "Fastest", "Run Time", "Slowest"]

const startInputProps = {
    placeholder: 'Start Datetime',
    className: 'form-control form-control-sm bg-muted text-white cursorPointer border-gray5 f12',
    readOnly: true,
};

const endInputProps = {
    placeholder: 'End Datetime',
    className: 'form-control form-control-sm bg-muted text-white cursorPointer border-gray5 f12',
    readOnly: true,
};

class AutomationDetails extends Component {
    
    constructor(props) {

        super(props)
        this.sortAutoamtionDropdown = React.createRef()
        
        this.state = {
            showLoading: true,
            selectedStatus: "All",
            selectedDuration: "+7d",

            groupedBy: "automation_plan",
            selectedCategory: "Important / All",
	
            expandExecutionDetails: true,

            startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
            overAllTotalPages: 1,
			perPage: 10,

            sortAutomation: "Date"
        }
    }

    componentDidMount = () => {    
        if(this.props.providers && this.props.providers.length) {
            let selectedProvider = this.props.providers.length === 1 ? this.props.providers[0].provider_name : ""
			this.setState({ selectedProvider, defaultProvider: selectedProvider },
				() => {
					this.getFilterData("provider")
                    this.onSearch("onLoad")
				}
			)
		} else {
			this.props.listAllProviders((promise, providers) => {
				if (promise) {
                    let selectedProvider = providers.length === 1 ? providers[0].provider_name : ""
					this.setState({ selectedProvider, defaultProvider: selectedProvider },
						() => {
							this.getFilterData("provider")
                            this.onSearch("onLoad")
						}
					)
				} else {
					this.setState({ providers: [], showLoading: false }, 
                        () => {
                            this.getFilterData("provider")
                            this.onSearch("onLoad")
                        }
					)
				}
			})
		}

        this.durationFunction()
    }

    getFilterData = () => {
        let params = {}

		if (this.state.selectedProvider && this.state.selectedProvider !== "" && this.state.selectedProvider !== "all") {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
        
        if(!this.props.accounts || !this.props.accounts.length) {
            this.props.listAllAccounts(params, (promise, response) => {})
        }

        if(!this.props.regions || !this.props.regions.length) {
            this.props.listAllRegions(params, (promise, regions) => {})
        }
    }

    onSearch = (type) => {
        if(this.state.changesInFilter || type === "onLoad"){
            this.setState({
                changesInFilter: false,
                resetEnabled: type === "onLoad" ? false : true,
                showLoading: true,
                succesFailureTrendData: {}
            },
                () => {
                    this.listAutomationExecutions()
                    this.getAutomationExecutionDailyTrend()
                    this.hideLoading()
                }
            )
        }
    }

    onReset = () => {
        this.setState({
            selectedProvider: this.state.defaultProvider,
            selectedAccount: "",
            selectedRegion: "",
            selectedStatus: "All",
            selectedDuration: "+7d",
        }, 
            () => {
                if(this.state.resetEnabled) {
                    this.onSearch("onLoad")
                }
            }
        )
    }

    getAutomationExecutionDailyTrend = () => {
        let params = {}
        if(this.state.selectedDuration === "custom") {
            params.start_time = this.state.start_date
            params.end_time = this.state.end_date
        }
        params.duration = this.state.selectedDuration

        this.props.getAutomationExecutionDailyTrend(params, (promise, response) => {
            if(promise) {
                this.setState({ trendData: response },
                    () => this.barTrendGraph()    
                )
            }
        })
    }

    barTrendGraph = () => {
        let graphDetails = this.state.trendData
        
        let graphData = {}
        if(graphDetails) {
            graphData.labels = graphDetails.labels ? graphDetails.labels : []
            graphDetails.data && Object.entries(graphDetails.data).forEach(([key,value]) => {
                graphData[key] = value
            })
        }
        this.setState({ succesFailureTrendData: graphData })
    }

    listAutomationExecutions = () => {
        let params = {}
        params.duration = "+7d"
        params.aggregate_by = this.state.groupedBy
        if(this.state.selectedProvider && this.state.selectedProvider !== "") {
            params.provider = this.state.selectedProvider.toLowerCase()
        }
        if(this.state.selectedAccount && this.state.selectedAccount !== "") {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion !== "") {
            params.region = this.state.selectedRegion
        }
        if(this.state.selectedStatus && this.state.selectedStatus !== "All") {
            params.status = this.state.selectedStatus
        }

        this.props.listAutomationExecutions(params, (promise, response) => {
            if(promise) {
                let listAutomations = response.results && response.results.details ? response.results.details : []
                this.setState({ listAutomations, totalAutomations: [], automations: [], filteredArray: [], showLoading: false },
                    () => this.structureData()    
                )
            } else {
                this.setState({ listAutomations: [], totalAutomations: [], automations: [], filteredArray: [], showLoading: false })
            }
        })
    }

    structureData = () => {
        let totalAutomations = []
        
        let totalCount = 0
        let automationcategory = []
        if(this.state.listAutomations && this.state.listAutomations.length) {
            totalCount = this.state.listAutomations.reduce( function(a, b){ return a + b.count;}, 0);
            this.state.listAutomations.forEach((item, index) => {
                let dataRow = {}
                dataRow.count = item.count
                dataRow.category = item.label
                dataRow.orderBy = index+1
                let percentage = Math.ceil(((item.count / totalCount) * 100)/10)
                let array = []
                for(let i=1; i<=percentage; i++) {
                    array.push(i)
                }
                dataRow.totalBars = array
                
                automationcategory.push(dataRow)
                item.executions.forEach(alt => {
                    totalAutomations.push(alt)
                })
            })
        }

        let orderBy = "desc"
        let column = ""
        if(this.state.sortAutomation === "Fastest") {
            column = "elapsed_time"
            orderBy = "asc"
        } else if(this.state.sortAutomation === "Slowest") {            
            column = "elapsed_time"
        } else if(this.state.sortAutomation === "Run Time") {
            orderBy = "asc"
            column = "duration"
        } else if(this.state.sortAutomation === "Date") {
            column = "start_time"
        }
        
        totalAutomations = _.orderBy(totalAutomations, [column], [orderBy])

        let dataRow = {}
        dataRow.count = ""
        dataRow.category = "Important / All"
        dataRow.orderBy = 0
        automationcategory.push(dataRow)

        this.setState({ totalAutomations, automations: totalAutomations, automationcategory: _.orderBy(automationcategory, ["orderBy"], ["asc"])},
            () => this.filterData()
        )
    }

    getCategoryAutomations = () => {
        let filterdCategoryAutomations = this.state.totalAutomations
        if(this.state.selectedCategory !== "Important / All") {
            filterdCategoryAutomations = this.state.listAutomations.filter(arr => arr.label === this.state.selectedCategory)
            if(filterdCategoryAutomations && filterdCategoryAutomations.length) {
                filterdCategoryAutomations = filterdCategoryAutomations[0].executions ? filterdCategoryAutomations[0].executions : []
            }
        }        

        this.setState({ automations: filterdCategoryAutomations, emptySearchText: true },
            () => this.filterData()
        )
    }

    filterData = (type) => {
        let data = this.state.automations
        if(this.state.searchText && this.state.searchText !== "") {
            data = this.state.searchArray
        }

        // let orderData = _.orderBy(data, [this.state.sortAutomation], ["desc"])
        let orderBy = "desc"
        let column = ""
        if(this.state.sortAutomation === "Fastest") {
            column = "elapsed_time"
            orderBy = "asc"
        } else if(this.state.sortAutomation === "Slowest") {            
            column = "elapsed_time"
        } else if(this.state.sortAutomation === "Run Time") {
            orderBy = "asc"
            column = "duration"
        } else if(this.state.sortAutomation === "Date") {
            column = "start_time"
        }

        data = _.orderBy(data, [column], [orderBy])

        let totalPages = 1
		if(data.length > this.state.perPage) {
			totalPages = Math.ceil(data.length / this.state.perPage)
		}

        this.setState({ filteredArray: data, showDetailsLoading: true, selectedAutomation: data.length ? data[0] : {}, totalPages, currentPage: 1, startRecord: 0 },
            () => {
                if(this.state.filteredArray.length) {
                    this.getAutomationExecution()
                } else {
                    this.setState({ showDetailsLoading: false })
                }
            }
        )
    }

    getAutomationExecution = () => {
        let params = {
            "execution_id": this.state.selectedAutomation.execution_id
        }

        this.props.getAutomationExecution(params, (promise, response) => {
            if(promise) {
                let automationdetails = response.overall_status ? response.overall_status : {}
                let automaitonTaskDetails = response.task_status ? response.task_status : []
                this.setState({ automationdetails, automaitonTaskDetails, showDetailsLoading: false })
            } else {
                this.setState({ automationdetails: {}, automaitonTaskDetails: [], showDetailsLoading: false })
            }
        })
    }

    hideLoading = () => {
        setTimeout(() => { this.setState({ showLoading: false }) }, 5000)
    }

    navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = this.state.startRecord
            if(action === "next" && currentPage !== this.state.totalPages) {
                startRecord = startRecord + this.state.perPage
                currentPage = currentPage + 1
            } else if(action === "previous" && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - this.state.perPage
            } else if(action === "start" && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === "end" && currentPage !== 0) {
                currentPage = this.state.totalPages
                startRecord = (this.state.totalPages - 1) * this.state.perPage
            }
            this.setState({ currentPage, startRecord })
        } 
	}

    handleSortBy = (e) => {
        this.setState({ 
            sortAutomation: e.target.value, 
            sortAutomationLabel: e.target.selectedOptions[0].getAttribute("name")  //e.target.name wont work
        })
    }

    handleDateRangeChange = (date, type) => {
        let validationError = this.state.validationError
        let validationErrorMessage = ''
        if(type === 'start_date') {
            let startDate = momentDateGivenFormat(date, 'YYYY-MM-DD hh:mm A')
            
            let endDate = this.state.end_date ? this.state.end_date : ''
            if(endDate !== '') {
                if(new Date(startDate).getTime() > new Date(endDate).getTime() ) {
                    endDate = startDate
                }
            } else {
                endDate = startDate
            }
            this.setState({ start_date: startDate, end_date: endDate, validationError, validationErrorMessage, showDateRangePicker: false },
                () => {
                    this.setState({ showDateRangePicker: true })
                }    
            )
        } else {
            let endDate = momentDateGivenFormat(date, 'YYYY-MM-DD hh:mm A')
            let startDate = this.state.start_date ? this.state.start_date : ''
            if(startDate !== '') {
                if(new Date(startDate).getTime() > new Date(endDate).getTime() ) {
                    startDate = endDate
                }
            } else {
                startDate = endDate
            }
            this.setState({ start_date: startDate, end_date: endDate, validationError, validationErrorMessage, showDateRangePicker: false },
                () => {
                    this.setState({ showDateRangePicker: true })
                }    
            )
        }
    }

    durationFunction = (type) => {
        let days = this.state.selectedDuration.replace('+', '').replace('d', '')
        let reducedDays = days - 1
        let start_date = subDays(momentDateGivenFormat(new Date(), 'YYYY-MM-DD 00:00:00'), reducedDays)
        let end_date = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')

        this.setState({ start_date, end_date })
    }

    handleClickOutside = (event) => {
		if (this.sortAutoamtionDropdown && !this.sortAutoamtionDropdown.current.contains(event.target)) {
			this.setState({ showsortAutomationOption: false })
		}
	}

	render() {
		return (
            <div className={`main-content bg-muted`} onClick={e => { this.handleClickOutside(e) } }>
                <div className={`container-fluid overflow-auto flex-grow-1`}>
                    <div className={`loaderOverlay ${this.state.showLoading ? "" : "displayNone"}`}>
                        <div className="overlayEqualizerLoader">
                            <div className="spinner-item"></div>
                            <div className="spinner-item"></div>
                            <div className="spinner-item"></div>
                            <div className="spinner-item"></div>
                            <div className="spinner-item"></div>
                        </div>
                    </div>
                    <div className="row h-100">
                        <div className={`border-right col-md-6 p-0`}>
                            <div className="title-section pb-3">
                                <h6 className="text-white m-0 f16 text-break align-self-center w-100">Executions</h6>
                                <p className="small m-0">Showing automation execution details</p>
                            </div>
                            <div className={`col-sm-12 my-1`}>
                                <div className="d-flex justify-content-between mt-2">
                                    <div className="d-flex">
                                        <Select
                                            placeholder={"Provider"}
                                            isSearchable={false}
                                            // menuIsOpen={true}
                                            className="f13 p-0 filterSelectDrop smallFitler mr-3 border-gray5"
                                            options={this.props.providers && this.props.providers.map(item => ({
                                                value: item.provider_name,
                                                label: item.provider_name,	
                                            }))}
                                            value={{
                                                value: this.state.selectedProvider,
                                                label: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.props.providers.filter(e => e.provider_name === this.state.selectedProvider)[0].provider_name : <span className="placeholder">Provder</span>
                                            }}
                                            onChange={event => this.setState({
                                                selectedProvider: event.value,
                                                changesInFilter: true,
                                            })}
                                        />
                                        <Select
                                            placeholder={"Account"}
                                            isSearchable={false}
                                            // menuIsOpen={true}
                                            className="f13 p-0 filterSelectDrop smallFitler mr-3 border-gray5"
                                            options={this.props.accounts && this.props.accounts.map(item => ({
                                                value: item.account_id,
                                                label: item.account_name,	
                                            }))}
                                            value={{
                                                value: this.state.selectedAccount,
                                                label: this.state.selectedAccount ? this.props.accounts.filter(e => e.account_id === this.state.selectedAccount)[0].account_name : <span className="placeholder">Acccount</span>
                                            }}
                                            onChange={event => this.setState({
                                                selectedAccount: event.value,
                                                changesInFilter: true,
                                            })}
                                        />
                                        <Select
                                            placeholder={"Region"}
                                            isSearchable={false}
                                            // menuIsOpen={true}
                                            className="f13 p-0 filterSelectDrop smallFitler mr-3 border-gray5"
                                            options={this.props.regions && this.props.regions.map(item => ({
                                                value: item.region,
                                                label: item.name,	
                                            }))}
                                            value={{
                                                value: this.state.selectedRegion,
                                                label: this.state.selectedRegion ? this.props.regions.filter(e => e.region === this.state.selectedRegion)[0].name : <span className="placeholder">Region</span>
                                            }}
                                            onChange={event => this.setState({
                                                selectedRegion: event.value,
                                                changesInFilter: true,
                                            })}
                                        />
                                        <Select
                                            placeholder={"Status"}
                                            isSearchable={false}
                                            // menuIsOpen={true}
                                            className="f13 p-0 filterSelectDrop smallFitler mr-3 border-gray5"
                                            options={status.map(item => ({
                                                value: item.value,
                                                label: item.key,	
                                            }))}
                                            value={{
                                                value: this.state.selectedStatus,
                                                label: this.state.selectedStatus ? status.filter(e => e.value === this.state.selectedStatus)[0].key : <span className="placeholder">Status</span>
                                            }}
                                            onChange={event => this.setState({
                                                selectedStatus: event.value,
                                                changesInFilter: true,
                                            })}
                                        />
                                        <div className="">
                                            <Select
                                                placeholder={"Select"}
                                                isSearchable={false}
                                                // menuIsOpen={true}
                                                className="f13 p-0 filterSelectDrop smallFitler2 mr-3 border-gray5"
                                                value={{
                                                    value: this.state.selectedDuration,
                                                    label: CUSTOM_DURATION_FILTER_OPTIONS.filter(e => e.value === this.state.selectedDuration)[0].option
                                                }}
                                                options={CUSTOM_DURATION_FILTER_OPTIONS && CUSTOM_DURATION_FILTER_OPTIONS.map(item => ({
                                                    value: item.value,
                                                    label: item.option,
                                                }))}
                                                onChange={event => {
                                                    if(event.value !== 'custom') {
                                                        this.setState(
                                                            {
                                                                selectedDuration: event.value,
                                                                showDateRangePicker: false,
                                                                changesInFilter: true,
                                                            },
                                                            () => {
                                                                this.durationFunction()
                                                            }
                                                        )
                                                    } else {
                                                        this.setState({ showDateRangePicker: true, selectedDuration: event.value })
                                                    }
                                                }}
                                                onClick={event => {
                                                    if(event.value === 'custom' && this.state.selectedDuration === 'custom') {
                                                        this.setState({ showDateRangePicker: true })
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className={`ml-3 align-self-center`}>
                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                <button type="button" className={`${this.state.showLoading ? "disabled" : "cursorPointer"} btn btn-secondary border-right px-2 py-1`} onClick={() => this.setState({ showLoading: false, series: [] }, () => this.onSearch() )}>Search</button>
                                                <button type="button" className={`${this.state.showLoading ? "disabled" : "cursorPointer"} btn btn-secondary border-left px-2 py-1`} onClick={()=> this.onReset()}><i className="far fa-redo"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.showDateRangePicker ? 
                                    <div className="d-flex justify-content-end w-80 mt-2">
                                        <div className="mr-1">
                                            <Datetime 
                                                value={this.state.start_date}
                                                onChange={(e) => this.handleDateRangeChange(e._d, 'start_date')}
                                                dateFormat={'DD MMM YYYY'}
                                                timeFormat={'hh:mm A'}
                                                inputProps={ startInputProps }
                                                className="w-100"
                                                //maxDate={new Date().getTime()}
                                                isValidDate={valid}
                                            />
                                        </div>
                                        <div className="">
                                            <Datetime 
                                                value={this.state.end_date}
                                                onChange={(e) => this.handleDateRangeChange(e._d, 'end_date')}
                                                dateFormat={'DD MMM YYYY'}
                                                timeFormat={'hh:mm A'}
                                                inputProps={ endInputProps }
                                                className="w-100"
                                                isValidDate={valid}
                                            />
                                        </div>
                                    </div>
                                : null}
                            </div>
                            <div className="col-12">
                                <div className="rounded bg-dark px-3 py-2 mt-2">
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex">
                                            <div className="d-flex">
                                                <p className="f12 m-0 align-self-center pt-1">Showing automation Trend</p>
                                                {/* <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{this.state.brushStartDate ? momentDateGivenFormat(this.state.brushStartDate, "DD MMM YYYY HH:mm") +" - "+ momentDateGivenFormat(this.state.brushEndDate, "DD MMM YYYY HH:mm") : ""}</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <p className="mb-0 align-self-center text-nowrap text-right pr-3 w-10">Automations</p>
                                        {this.state.succesFailureTrendData && Object.entries(this.state.succesFailureTrendData).length ?
                                            <div className=" w-90 mt-n3">
                                                <ApexStackedBarChart 
                                                    graphData={this.state.succesFailureTrendData}
                                                    sparkline={false}
                                                    yaxis={false}
                                                    yaxisLabel={true}
                                                    xaxis={true}
                                                    xaxisFormat={'date'}
                                                    xaxisLabel={true}
                                                    axisLabelFontSize={'9px'}
                                                    axisLabelColor={'#B8BBBE'}
                                                    paddingLeft={-25}
                                                    legend={false}
                                                    stacked={true}
                                                    height={120}
                                                    horizontal={false}
                                                    barHeight={'40%'}
                                                    barEndShape={'flat'}
                                                    columnWidth={'25%'}
                                                    gradient={true}
                                                    gradientColor={['#039BE5', '#5F5BA2']}
                                                    hideTooltipValue={true}
                                                    backgroundBarShape={'flat'}
                                                    backgroundBarColors={['#333947']}
                                                    showBackgroundBarColors={true}
                                                    className={"transparentTooltip"}
                                                />
                                            </div>
                                        : null}
                                    </div> 
                                </div>
                                <div className="rounded bg-dark p-3 mt-2">
                                    <div className="d-flex justify-content-between mb-1">
                                        <div className="d-flex">
                                            <div className={`d-flex mb-1`}>
                                                <p className="m-0 align-self-end small mx-2">Grouped by</p>
                                                <div className={`transparentSelectBorderBottom align-self-end`}>
                                                    <select className="form-control" 
                                                        onChange={(event) => {
                                                            this.setState({ groupedBy: event.target.value, showLoading: true },
                                                                () => {
                                                                    this.listAutomationExecutions()
                                                                    this.hideLoading()
                                                                }
                                                            )
                                                        }}
                                                        value={this.state.groupedBy}                            
                                                        >
                                                        <option value="automation_plan">Automation Plan</option>
                                                    </select>
                                                </div>
                                                <p className="m-0 align-self-end small mx-2">sort by</p>
                                                <div className={`transparentSelectBorderBottom align-self-end`}>
                                                    <select className="form-control"
                                                        onChange={e => this.setState({ sortAutomation: e.target.value},
                                                            () =>this.filterData()
                                                        )}                         
                                                        value={this.state.sortAutomation}
                                                        >
                                                        {automationSortBy.map(item => {
                                                            return (
                                                                <option value={item}>{item}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            {/* <small className="align-self-center ml-2">Showing {this.state.filteredArray && this.state.filteredArray.length} {this.state.filteredArray && this.state.filteredArray.length > 1 ? "automations" : "automation"} </small> */}
                                        </div>
                                        <div className="d-flex displayNone">
                                            <div className="select-sm-bg displayNone" style={{minWidth: "150px"}}>
                                                <div className="form-group bd-highlight styled-feild bg-transparent mb-0">
                                                    <select className="form-control margin-8"
                                                        value={this.state.sortAutomation}
                                                        onChange={e => this.setState({ sortAutomation: e.target.value},
                                                            () =>this.filterData()
                                                        )}
                                                        >
                                                        {automationSortBy.map(item => {
                                                            return (
                                                                <option value={item}>{item}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="select-sm-bg curvedDropdown minWidth150 mr-2" ref={this.sortAutoamtionDropdown}>
                                                <div className="dropdown">
                                                    <span className="dropdown-toggle d-flex justify-content-between px-2 text-white" onClick={() => this.setState({ showsortAutomationOption: !this.state.showsortAutomationOption })}>
                                                        {this.state.sortAutomation ? this.state.sortAutomation : <span className="placeholder">Select</span>}
                                                        <span className="caret"></span>
                                                        {/* <i className="fas fa-angle-down"></i> */}
                                                    </span>
                                                    <ul className={`dropdown-menu p-0 ${this.state.showsortAutomationOption ? "show" : ''}`}>
                                                        {automationSortBy.map(item => {
                                                            return (
                                                                <li onClick={() => this.setState({ sortAutomation: item, showsortAutomationOption: false }, () =>this.filterData())} value={item} className={`${this.state.sortAutomation === item ? "selected" :  ""}`}>
                                                                    {item}
                                                                </li>
                                                            )
                                                        })}
                                                        {/* <li><a href="#">HTML</a></li>
                                                        <li><a href="#">CSS</a></li>
                                                        <li><a href="#">JavaScript</a></li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                            {this.state.automations && this.state.automations.length ?
                                                <div className="form-group mb-0 align-self-end ml-2">
                                                    <SearchWithHiddenInput
                                                        ID={"automation_plan_search"}
                                                        data={this.state.automations ? this.state.automations : []}
                                                        applyLiteDarkTags={true}
                                                        searchClassName={"border-0"}
                                                        searchIconColor={"text-gray3"}
                                                        className="form-control-sm text-gray3 bg-muted border-0"
                                                        hideInputSection={false}
                                                        topClassName={"dark-search"}
                                                        filteredData={(searchArray, searchText) => {
                                                            this.setState({ searchArray, searchText, emptySearchText: false },
                                                                () => this.filterData("search")
                                                            )
                                                        }}
                                                        resetSearchBox={this.state.emptySearchText}
                                                    />
                                                </div>
                                            : null}                                            
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end mb-1">
                                        {this.state.filteredArray && this.state.filteredArray.length > this.state.perPage ?
                                            <div className="pagination errorPagePagination f12 align-self-end ml-2">
                                                <span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
                                                <button><i className={`fal fa-arrow-to-left cursorPointer ${this.state.currentPage === 1 ? "disabled text-muted" : "text-info"}`} onClick={() => this.navigatePage("start", this.state.currentPage)}></i></button> 
                                                <button><i className={`fal fa-angle-left cursorPointer ${this.state.currentPage === 1 ? "disabled text-muted" : "text-info"}`} onClick={() => this.navigatePage("previous", this.state.currentPage)}></i></button> 
                                                <span className="displayNone">
                                                    Go to
                                                    <input
                                                        type="text"
                                                        value={this.state.currentPage}
                                                        onChange={e => {
                                                            const page = e.target.value ? onlyNumeric(e.target.value) : 1
                                                            this.gotoPage(page)
                                                        }}
                                                        className="ml-1 inputClass"
                                                    />
                                                </span>
                                                <button><i className={`fal fa-angle-right cursorPointer ${this.state.currentPage === this.state.totalPages ? "disabled text-muted" : "text-info"}`} onClick={() => this.navigatePage("next", this.state.currentPage)}></i></button> 
                                                <button><i className={`fal fa-arrow-to-right cursorPointer ${this.state.currentPage === this.state.totalPages ? "disabled text-muted" : "text-info"}`} onClick={() => this.navigatePage("end", this.state.currentPage)}></i></button>
                                            </div>
                                        : null}
                                    </div>
                                    <div className="d-flex">
                                        {this.state.automationcategory ? 
                                            <div className="categoryBoxSection">
                                                <div className="bg-muted px-2 pt-2 select-bgg">
                                                    {this.state.groupedBy === "severity" ?
                                                        this.state.automationcategory.map(item => {
                                                            return(
                                                                item.category === "Important / All" ?
                                                                    <div className={`d-flex p-2 cursorPointer ${this.state.selectedCategory === item.category ? "text-info disabled" : ""}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryAutomations())}>
                                                                        <p className="m-0 f-20">{item.category}</p>
                                                                        <p className="m-0 align-self-end ml-2 f-18">{item.count !== "" ? item.count : ""}</p>
                                                                    </div>
                                                                :
                                                                    <div className={`d-flex justify-content-end mb-2 ${!item.count ? "disabled opacity50" : "cursorPointer"}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryAutomations())}>
                                                                        <div className="d-flex">
                                                                            {item.totalBars.map(bar => {
                                                                                return(
                                                                                <div className={`line-survey-1 risk-bg-${item.category.toLowerCase()}`}></div>
                                                                                )
                                                                            })}
                                                                            <div className={`font-weight-bold text-center risk-border-${item.category.toLowerCase()} text-white p-2`}>
                                                                                <p className="small mb-0" style={{minWidth: "75px"}}>{item.category+" "+item.count}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            )
                                                        })                                            
                                                    : 
                                                        this.state.automationcategory.map((item, index) => {
                                                            return(
                                                                item.category === "Important / All" ?
                                                                    <div className={`d-flex cursorPointer mb-3 ${this.state.selectedCategory === item.category ? "text-info disabled" : ""}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryAutomations())}>
                                                                        <p className="m-0 f-20">{item.category}</p>
                                                                        <p className="m-0 align-self-end ml-2 f-18">{item.count !== "" ? item.count : ""}</p>
                                                                    </div>
                                                                :
                                                                <div className={`d-flex cursorPointer mb-2 ${this.state.selectedCategory === item.category ? "text-info disabled" : (!item.count ? "text-gray4 disabled test" : "text-white")}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryAutomations())}>
                                                                    <div className="d-flex justify-content-between mb-2" style={{maxWidth: "160px"}}>
                                                                        <p className="mb-0 align-self-center ellipseCategoryBoxSection w-60" id={"category_"+index}>{item.category}</p>
                                                                        <UncontrolledTooltip target={"category_"+index}>{item.category}</UncontrolledTooltip>
                                                                        <input className="form-control form-control-sm w-25 border-0 bg-grey text-white readOnly  disabled rounded-0 form-number text-right" style={{minWidth: "40px"}} type="text" placeholder="1" value={item.count !== "" ? item.count : ""} aria-label=".form-control-sm example" />
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        : null}
                                        <div className="ml-2">
                                            <div className="timeline-style">
                                                {this.state.filteredArray && this.state.filteredArray.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map(item => {
                                                    return (
                                                        <div className={`d-flex justify-content-between ${this.state.selectedAutomation.execution_id === item.execution_id ? "bg-muted": ""}`}>
                                                            <div className={`d-flex ${this.state.selectedAutomation.execution_id === item.execution_id ? "disabled" : "cursorPointer"} border-bottom-black5 mt-1`} onClick={() => this.setState({ selectedAutomation: item, showDetailsLoading: true }, 
                                                                () => this.getAutomationExecution()
                                                            )}>     
                                                                <div className="d-flex">
                                                                    {/* {item.status ?
                                                                    :null} */}
                                                                    <p className="mb-0">
                                                                        <span className={`f10 mr-2 align-self-start mt-1 badge-sm badge-${item.status.toLowerCase() === "success" ? "success" : item.status.toLowerCase() === "failed" ? "danger" : "warning"}`}>
                                                                            {item.status.toLowerCase() === "success" ? "Success" : item.status.toLowerCase() === "failed" ? "Failed" : "InProgress"}
                                                                        </span>
                                                                        <span className="mb-0 mr-2 text-white f12 font-weight-bold">{item.start_time ? momentConvertionUtcToLocalTime(item.start_time, "DD MMM YYYY HH:mm:ss") : ""} </span>
                                                                        <span className="mb-0 mr-2 f12 text-purple-2 font-weight-bold">{item.automation_plan}</span>
                                                                        <span className="mb-0 mr-2 f12 font-weight-bold">{item.automation_type}</span>
                                                                        <span className="mb-0 mr-2 f12 font-weight-bold">{item.automation_name}</span>
                                                                        {/* {item.assets && item.assets.length ?
                                                                            <span className="mb-0 mr-2 f12 text-white font-weight-bold">{" : "+item.assets[0].asset_name}</span>
                                                                        : null} */}
                                                                        
                                                                        {item.provider ?
                                                                            <span className="mb-0 mr-2 f12 text-white font-weight-bold">{item.provider.toUpperCase()}</span>
                                                                        : null}
                                                                        {item.account_id ?
                                                                            <span className="mb-0 mr-2 f12 text-white font-weight-bold">{" : "+item.account_id}</span>
                                                                        : null}
                                                                        {item.region ?
                                                                            <span className="mb-0 mr-2 f12 text-white font-weight-bold">{" : "+ (Array.isArray(item.region) ? item.region[0] : item.region)}</span>
                                                                        : null}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>              
                        <div className="col-md-6">
                            {!this.state.showDetailsLoading && this.state.selectedAutomation && Object.entries(this.state.selectedAutomation).length ?
                                <React.Fragment>
                                <div className="accordion" id="accordionDetails">
                                    <div className={`card bg-dark border-0 my-2`}>
                                        <div id={"heading_details"} onClick={() => this.setState({ expandExecutionDetails: !this.state.expandExecutionDetails })}>
                                            <div className={`p-3 mb-0 d-flex justify-content-between ${this.state.expandExecutionDetails ? "" : ""}`} data-toggle="collapse" data-target={"#collapse_details"} aria-expanded="true" aria-controls={"collapse_details"}>
                                                <div className="d-flex">
                                                    <div className="text-white">
                                                        <span className={`far ${this.state.expandExecutionDetails ? "fa-minus-circle" : "fa-plus-circle"} mr-2`}></span>
                                                    </div>
                                                    <div className="">
                                                        <p className="text-white mb-0">{this.state.selectedAutomation.automation_name}</p>
                                                        <p className="small mb-0">{this.state.selectedAutomation.automation_plan}</p>
                                                    </div>
                                                </div>
                                                <div className="mt-n1">
                                                    <p className="b-block mb-0 mr-2">Severity</p>
                                                    <span className={`mr-2 badge ${this.state.selectedAutomation.importance ? "risk-badge-"+this.state.selectedAutomation.importance.toLowerCase() : "badge-secondary" }`}>  
                                                        {this.state.selectedAutomation.importance ? capitalizeFirstLetter(this.state.selectedAutomation.importance) : "Unknown"}
                                                    </span>
                                                </div>
                                                <div className="mt-n1">
                                                    <p className="b-block mb-0 mr-2">Status</p>
                                                    <span className={`mr-2 badge badge-${this.state.selectedAutomation.status.toLowerCase() === "success" ? "success" : "danger"}`}>{this.state.selectedAutomation.status}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div id={"collapse_details"} className={`collapse ${this.state.expandExecutionDetails ? "show" : ""}`} aria-labelledby={"heading_details"} data-parent="#accordionDetails">
                                            <div className="card-body">
                                                <div className="d-flex mb-1 border-bottom">
                                                    <div className="py-1 w-50">
                                                        <p className="b-block mb-0">Type</p>
                                                        <p className="mb-0 text-white">{this.state.selectedAutomation.automation_type}</p>
                                                    </div>
                                                    <div className="py-1 w-50 pl-3">
                                                        <p className="b-block mb-0">Provider / Account / Region </p>
                                                        <p className="mb-0 text-white">
                                                            {this.state.selectedAutomation.provider ? this.state.selectedAutomation.provider.toUpperCase() : ""}
                                                            {this.state.selectedAutomation.account_id ? (" / ")+this.state.selectedAutomation.account_id : ""}
                                                            {this.state.selectedAutomation.region ? (" / ")+this.state.selectedAutomation.region : ""}
                                                        </p>
                                                        {/* <p className="b-block mb-0">Sub Type</p>
                                                        <p className="mb-0 text-white">{this.state.selectedAutomation.automation_subtype}</p> */}
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-1 border-bottom">
                                                    <div className="py-1 w-50">
                                                        <p className="b-block mb-0">Start Time</p>
                                                        <p className="mb-0 text-warning">
                                                            {this.state.automationdetails.start_time ? momentConvertionUtcToLocalTime(this.state.automationdetails.start_time, "DD MMM YYYY HH:mm:ss") : ""}
                                                        </p>
                                                    </div>
                                                    <div className="py-1 w-50 pl-3">
                                                        <p className="b-block mb-0">End Time</p>
                                                        <p className="mb-0 text-warning">
                                                            {this.state.automationdetails.end_time ? momentConvertionUtcToLocalTime(this.state.automationdetails.end_time, "DD MMM YYYY HH:mm:ss") : ""}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-1 border-bottom">
                                                    <div className="py-1 w-50">
                                                        <p className="b-block mb-0">Time Saved</p>
                                                        <p className="mb-0 text-info">{unitTimeConvertionDetail(this.state.selectedAutomation.time_saved, "Seconds")}</p>
                                                    </div>
                                                    <div className="py-1 w-50 pl-3">
                                                        <p className="b-block mb-0">Cost Saved</p>
                                                        <p className="mb-0 text-info">{this.state.selectedAutomation.cost_saved} USD</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-1 border-bottom">
                                                    <div className="py-1 w-50">
                                                        <p className="b-block mb-0">Duration</p>
                                                        <p className="mb-0 text-info">{unitTimeConvertionDetail(this.state.selectedAutomation.duration, "Seconds")}</p>
                                                    </div>
                                                    <div className="py-1 w-50 pl-3">
                                                        <p className="b-block mb-0">Elapsed Time</p>
                                                        <p className="mb-0 text-info">{unitTimeConvertionDetail(this.state.selectedAutomation.elapsed_time, "Seconds")}</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-1 border-bottom">
                                                    <div className="py-1 w-100">
                                                        <p className="b-block mb-0">Tags</p>
                                                        {this.state.selectedAutomation.automation_tags && this.state.selectedAutomation.automation_tags.length ? 
                                                            this.state.selectedAutomation.automation_tags.map(tag => {
                                                                return(
                                                                    <small className={`mr-1 badge badge-secondary`}>{tag}</small> 
                                                                )
                                                            })  
                                                        : 
                                                            <p className="mb-0 text-info">&nbsp;</p>
                                                        }
                                                    </div>
                                                </div>
                                                
                                                <div className="d-flex">
                                                    <div className="py-1 w-100">
                                                        <p className="b-block mb-0">Description</p>
                                                        <p className="mb-0 text-white">{this.state.selectedAutomation.description ? this.state.selectedAutomation.description : ""}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion" id="accordionTrigger">
                                    <div className={`card bg-dark border-0 my-2`}>
                                        <div id={"heading_trigger"} onClick={() => this.setState({ expandTriggerDetails: !this.state.expandTriggerDetails })}>
                                            <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={"#collapse_trigger"} aria-expanded="true" aria-controls={"collapse_trigger"}>
                                                <div className="text-white"><span className={`far ${this.state.expandTriggerDetails ? "fa-minus-circle" : "fa-plus-circle"} mr-2`}></span>Trigger Details</div>
                                                <div className="text-info">Showing trigger details</div>
                                            </div>
                                        </div>
                                        <div id={"collapse_trigger"} className={`collapse ${this.state.expandTriggerDetails ? "show" : ""}`} aria-labelledby={"heading_trigger"} data-parent="#accordionTrigger">
                                            <div className="card-body">
                                                <div className="d-flex mb-1 border-bottom">
                                                    <div className="py-1 w-50">
                                                        <p className="b-block mb-0">Name</p>
                                                        <p className="mb-0 text-white">
                                                            {this.state.selectedAutomation.trigger_name ? this.state.selectedAutomation.trigger_name : <span>&nbsp;</span>}
                                                        </p>
                                                    </div>
                                                    <div className="py-1 w-50 pl-3">
                                                        <p className="b-block mb-0">Source</p>
                                                        <p className="mb-0 text-white">
                                                            {this.state.selectedAutomation.trigger_type ? this.state.selectedAutomation.trigger_type : <span>&nbsp;</span>}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-1 border-bottom">
                                                    <div className="py-1 w-50">
                                                        <p className="b-block mb-0">Type</p>
                                                        <p className="mb-0 text-white">
                                                            {this.state.selectedAutomation.trigger_source ? this.state.selectedAutomation.trigger_source : <span>&nbsp;</span>}
                                                        </p>
                                                    </div>
                                                    <div className="py-1 w-50 pl-3">
                                                        <p className="b-block mb-0">Type Detail</p>
                                                        <p className="mb-0 text-white">
                                                            {this.state.selectedAutomation.trigger_source_name? this.state.selectedAutomation.trigger_source_name : <span>&nbsp;</span>}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-1">
                                                    <div className="py-1 w-50">
                                                        <p className="b-block mb-0">Trigger Time</p>
                                                        <p className="mb-0 text-warning">
                                                            {this.state.automationdetails.start_time ? momentConvertionUtcToLocalTime(this.state.automationdetails.trigger_time, "DD MMM YYYY HH:mm:ss") : <span>&nbsp;</span>}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.automationdetails.assets && this.state.automationdetails.assets.length ?
                                    <div className="accordion" id="accordionExample">
                                        <div className={`card bg-dark border-0 my-2`}>
                                            <div id={"heading_assets"} onClick={() => this.setState({ expandAssetDetails: !this.state.expandAssetDetails })}>
                                                <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={"#collapse_assets"}aria-expanded="true" aria-controls={"collapse_assets"}>
                                                    <div className="text-white"><span className={`far ${this.state.expandAssetDetails ? "fa-minus-circle" : "fa-plus-circle"} mr-2`}></span>Asset Details</div>
                                                    <div className="text-info">Showing asset details</div>
                                                </div>
                                            </div>
                                            <div id={"collapse_assets"} className={`collapse ${this.state.expandAssetDetails ? "show" : ""}`} aria-labelledby={"heading_assets"} data-parent="#accordionExample">
                                                <div className="card-body">
                                                    <ResizeableDarkThemeTable
                                                        columns={[
                                                            {
                                                                Header: "Name",
                                                                accessor: "asset_name"
                                                            },
                                                            {
                                                                Header: "ID",
                                                                accessor: "asset_id"
                                                            },
                                                            {
                                                                Header: "VPC",
                                                                accessor: d => d.vpc_id ? d.vpc_id : ""
                                                            },
                                                            {
                                                                Header: "Resource Type",
                                                                accessor: d => (d.resource_type ? d.resource_type : "")
                                                            },
                                                            {
                                                                Header: "Service",
                                                                accessor: d => (d.service_name ? d.service_name : "")
                                                            }
                                                        ]}
                                                        data={this.state.automationdetails.assets}
                                                        perPage={10}
                                                        dashboard={true}
                                                        sortByColumn={"asset_name"}
                                                        riskTooltip={[]}
                                                        onClickRow={tableRow => {}}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                : null}
                                </React.Fragment>
                            : this.state.showDetailsLoading ?
                                <div className="d-flex justify-content-center m-4">
                                    <Spinner className="text-center" color="white" size="lg" />
                                </div>
                            : null}
                            {!this.state.showDetailsLoading && this.state.automaitonTaskDetails && this.state.automaitonTaskDetails.length ?
                                <div className="rounded bg-dark p-3 mt-2">
                                    <div className="timeline-style ml-2">
                                        {this.state.automaitonTaskDetails && this.state.automaitonTaskDetails.map(item => {
                                            return (
                                                item.taskStartMessage && item.taskStartMessage !== "" ?
                                                    <div className={`d-flex justify-content-between}`}>
                                                        <div className={`d-flex`}>
                                                            <div className="d-flex border-right">
                                                                <div className="p-2 pr-3 align-self-center">
                                                                    <div className={`font-weight-bold text-center p-2 ${item.taskStatus.toLowerCase() === "completed" ? "text-success success-border" : item.taskStatus.toLowerCase() === "failed" ? "text-danger danger-border" : "text-warning warning--border"} `}>
                                                                        <p className="small mb-0">{momentConvertionUtcToLocalTime(item.taskStartTime, "MMM DD")}</p>
                                                                        <p className="m-0 f10">{momentConvertionUtcToLocalTime(item.taskStartTime, "HH:mm:ss")}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="timeline-space mt-0 align-self-center">
                                                                <span className="border bg-gray3"></span>
                                                                <div className="bg-gray3"></div>
                                                            </div>
                                                            <div className={`align-self-center ml-2 border-bottom-muted`}>
                                                                <div className="d-flex flex-wrap pt-2">
                                                                    <span className={`align-self-center mr-1 fa fa-circle ${item.taskStatus.toLowerCase() === "completed" ? "text-success" : item.taskStatus.toLowerCase() === "failed" ? "text-danger" : "text-warning"}`}></span>
                                                                    <p className={`mb-0 mr-2 ${item.taskStatus.toLowerCase() === "completed" ? "text-success" : item.taskStatus.toLowerCase() === "failed" ? "text-danger" : "text-warning"}`}>{capitalizeFirstLetter(item.taskStatus)}</p>
                                                                    <p className={`mb-0 ${item.taskStatus.toLowerCase() === "completed" ? "text-success" : item.taskStatus.toLowerCase() === "failed" ? "text-danger" : "text-warning"}`}>{item.taskName}</p>
                                                                    {item.taskEndTime && item.taskEndTime !== "" ?
                                                                        <p className="mb-0 ml-2">({twoDateDiffrenceDayHoursMinutes(item.taskStartTime, item.taskEndTime) === "-" ? "0 second" : twoDateDiffrenceDayHoursMinutes(item.taskStartTime, item.taskEndTime) })</p>
                                                                    : null}
                                                                </div>
                                                                {item.description ? 
                                                                    <div className="d-flex flex-wrap pt-1">
                                                                        <p className="mb-0">{item.description}</p>
                                                                    </div>
                                                                : null}
                                                                <div className="d-flex flex-wrap pt-2">
                                                                    <p className="mb-0">{momentConvertionUtcToLocalTime(item.taskStartTime, "DD MMM YYYY HH:mm:ss")}<span className="mr-1">:</span>{item.taskStartMessage}</p>
                                                                </div>
                                                                {item.taskStatus && item.taskMessage && item.taskMessage !== "" && item.taskMessage !== "{}" ?
                                                                    <div className="w-100 mt-2 ml-3">
                                                                        {item.taskMessage.split("\n").length ? 
                                                                            item.taskMessage.split("\n").map((msg, i) => {
                                                                                return(
                                                                                    <div className="d-flex">
                                                                                        <p className="mb-0">{i+1}.</p>
                                                                                        <p className="ml-2 mb-0">{msg}</p>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        : null}
                                                                    </div>
                                                                : null }
                                                                {item.taskEndTime && item.taskEndTime !== "" ?
                                                                    <div className="d-flex flex-wrap mt-1">
                                                                        <div className="w-100">
                                                                            <span>{momentConvertionUtcToLocalTime(item.taskEndTime, "DD MMM YYYY HH:mm:ss")}<span className="mr-1">:</span>{item.taskEndMessage} </span>
                                                                        </div>
                                                                    </div>
                                                                : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                : null
                                            )
                                        })}
                                    </div>
                                </div>
                            : null}
                        </div>
                    </div>
                </div>
            </div>
        )
	}
}

/**
 * Type of the props used in the component
 */
AutomationDetails.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log("-",state)
	return {
        providers: state.filters.providers, 
        accounts: state.filters.accounts,
		regions: state.filters.regions && state.filters.regions[0] && state.filters.regions[0].provider_regions ? state.filters.regions[0].provider_regions : []
	}
}

export default AppWrapper(AutomationDetails, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
    listAutomationExecutions,
    getAutomationExecutionDailyTrend,
    getAutomationExecution
})