/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file SummaryRightSection.js
 * @author Prakash // on 09/03/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
// import { Spinner, UncontrolledPopover, PopoverHeader, PopoverBody, Modal, ModalHeader, ModalBody } from 'reactstrap'
// import { getRemediationStatus } from '../../../actions/aiops/RemediationAction'
// import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'
// import { PRIORITY_FILTER_OPTIONS } from '../../../utils/constants'
// import { momentDateTimeSec, twoDateDiffrenceDayHoursMinutes, capitalizeFirstLetter, momentDateGivenFormat } from '../../../utils/utility'
// import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'

class RemediationRightSection extends Component {
    
    constructor(props) {

        super(props)
        this.providerRef = React.createRef()
        this.scrollRef = React.createRef()
        
        this.state = {

        }
    }

    componentDidMount = () => {}
    

	render() {
		return (
            <div className="row remediationRightSection">
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
RemediationRightSection.propTypes = {
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('-',state)
	return {}
}

export default connect(mapStateToProps, { })(withRouter(RemediationRightSection))