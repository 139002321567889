import React, { Component } from 'react'

import { onlyNumeric } from '../../../utils/utility'

class TextField extends Component {

  constructor(props) {
    super(props);
    this.state = props || {};
    this.state = {
      validationMessage: '',
      parentValue: this.props.value,
    }
    this.props.recordSelection(this.props.name, this.props.value);
    this.handleChange = this.handleChange.bind(this);
    
    // this.state.isTextFieldDirty = false;

    // this.updateParentFormWithDebouncedFieldValue = _.debounce(  //if need a dealy in update call this function with delay in milliseconds
    //   this.updateParentFormFieldValue,
    //   this.props.delay || 0
    // );
  }

  componentDidMount = () => {
    // this.validateTextField();
    console.log(this.props)
  };

  componentWillReceiveProps(nextProps) {
    // let value;

    // let isParentValueChanged = this.state.parentValue !== nextProps.value;
    // const shouldStateUpdate = isParentValueChanged
    //   if (isParentValueChanged) {
    //     value = nextProps.value;
    //     this.props.recordSelection(this.props.name, value);
    //   } else {
    //     value = this.state.value;
    //   }

    //   this.setState(
    //   {
    //     ...nextProps.data,
    //     value,
    //     parentValue: isParentValueChanged ? value : this.state.parentValue,
    //   },
    // );
  }

  handleChange = ({ target }) => {
    let value = target.value;
    if(this.props.number) {
      value = onlyNumeric(value);
    }
    this.setState({ value },
      () => {
        this.props.recordSelection(this.props.name, value);
      }
    )
  }

  render() {
    return (
      <div style={{ display: "inline" }}>
        <div className="d-flex">
          <input
            type="text" 
            className={`${this.props.className ? this.props.className : ''}`}
            onChange={this.handleChange}
            name={this.props.name}
            required={this.state.required}
            placeholder={this.state.placeholder}
            value={this.state.value}
          />
          {this.props.unitLabel && this.props.unitLabel !== '' ?
            <small className={`ml-2 align-self-center ${this.props.labelClassName}`}>{this.props.unitLabel}</small>
          : null }
        </div>
        {this.state.validationMessage}
      </div>
    )
  }
};

export default TextField