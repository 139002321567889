/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AnomalyDashboard.js
 * @author Prakash // on 05/01/2022
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { Spinner, UncontrolledTooltip } from 'reactstrap'
import { setAiopsPropsDetails } from '../../../../actions/aiops/AiopsAction'
import { getEventDetails } from '../../../../actions/aiops/EventAction'
import { listObservabilityAnomalyResults } from '../../../../actions/aiops/ObservabilityAction'

import _ from 'lodash'
import { CSVLink } from "react-csv";
import { momentDateGivenFormat, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord } from '../../../../utils/utility'

import Search from '../../../common/SearchComponent'

import RightSection from '../RightSection'

class AnomalyDashboard extends Component {
    constructor(props) {
        super(props)
        this.toggleRef = React.createRef()
        
        this.state = {
            showLoading: true,
            jumpDate: '',
            details: {},

            startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
            overAllTotalPages: 1,
			perPage: 100,

            groupType: 'All',
            groupedBy: 'severity',
            selectedCategory: 'Important / All',
            pageType: "Anomalies",
            showRightSide: true
        }
    }

	componentDidMount = () => {
        if(this.props.aiopsCommonPageFilter && Object.entries(this.props.aiopsCommonPageFilter).length) {
            this.setState({ 
                start_time: this.props.aiopsCommonPageFilter.start_time,
                end_time: this.props.aiopsCommonPageFilter.end_time,
                brushStartTime: this.props.aiopsCommonPageFilter.brushStartTime ? this.props.aiopsCommonPageFilter.brushStartTime : this.props.aiopsCommonPageFilter.start_time,
                brushEndTime: this.props.aiopsCommonPageFilter.brushEndTime ? this.props.aiopsCommonPageFilter.brushEndTime : this.props.aiopsCommonPageFilter.end_time,
                showRightSide: false,                
                anomalyListLoader: true
            },
                () => {
                    this.setState({ showRightSide: true })
                    this.listObservabilityAnomalyResults()
                }
            )
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.aiopsCommonPageFilter && Object.entries(this.props.aiopsCommonPageFilter).length) {
            if(!_.isEqual(prevProps.aiopsCommonPageFilter, this.props.aiopsCommonPageFilter)) {
                this.setState({ 
                    start_time: this.props.aiopsCommonPageFilter.start_time,
                    end_time: this.props.aiopsCommonPageFilter.end_time,
                    brushStartTime: this.props.aiopsCommonPageFilter.brushStartTime ? this.props.aiopsCommonPageFilter.brushStartTime : this.props.aiopsCommonPageFilter.start_time,
                    brushEndTime: this.props.aiopsCommonPageFilter.brushEndTime ? this.props.aiopsCommonPageFilter.brushEndTime : this.props.aiopsCommonPageFilter.end_time,
                    showRightSide: false,
                    anomalyListLoader: true
                },
                    () => {
                        this.setState({ showRightSide: true })
                        this.listObservabilityAnomalyResults()
                    }
                )
            }
        }
    }

    navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = this.state.startRecord
            if(action === 'next' && currentPage !== this.state.totalPages) {
                startRecord = startRecord + this.state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - this.state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = this.state.totalPages
                startRecord = (this.state.totalPages - 1) * this.state.perPage
            }
            this.setState({ currentPage, startRecord })
        } 
	}

    gotoPage = (pageNumber) => {
        if(pageNumber > 0  && pageNumber <= this.state.totalPages) {
            let startRecord = (pageNumber - 1) * this.state.perPage
            this.setState({ currentPage: pageNumber, pageNumber, startRecord })
        } else  if(pageNumber > this.state.totalPages) {
            this.setState({ pageNumber: this.state.currentPage })  
        } else {
            this.setState({ pageNumber })
        }
    }

    listObservabilityAnomalyResults = () => {
        let params = {}
        params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss')
        params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss')
        params.aggregate_by = this.state.groupedBy
        if(this.state.groupType !== 'All') {
            params.anomaly_type = this.state.groupType
        }

        this.props.listObservabilityAnomalyResults(params, (promise, response) => {
            if(promise) {
                let data = response.results && response.results.details ? response.results.details : []
                this.setState({ anomalyResponse: data, showGroupLoading: false },
                    () => {
                        this.structureAnomalyData()
                        this.props.setAiopsPropsDetails('tabOptions', {})
                        // this.filterData()
                    }    
                )
            } else {
                this.setState({ anomalyResponse: [], showGroupLoading: false, anomalyListLoader: false })
            }
        })
    }

    structureAnomalyData = () => {
        let groupAllAnomaly = []

        let totalCount = 0
        
        let groupAnomalycategory = []
        if(this.state.anomalyResponse && this.state.anomalyResponse.length) {
            totalCount = this.state.anomalyResponse.reduce( function(a, b){ return a + (b.count ? b.count : (b.details ? b.details.length : 0))}, 0);
            this.state.anomalyResponse.forEach((item, index) => {
                let dataRow = {}
                dataRow.count = item.count ? item.count : (item.details ? item.details.length : 0)
                dataRow.category = item.label
                dataRow.orderBy = index+1
                let percentage = Math.ceil(((dataRow.count / totalCount) * 100)/10)
                let array = []
                for(let i=1; i<=percentage; i++) {
                    array.push(i)
                }
                dataRow.totalBars = array
                groupAnomalycategory.push(dataRow)
                item.events.forEach(alt => {
                    groupAllAnomaly.push(alt)
                })
            })
        }
        
        let groupAnomalyTotalCount = this.state.anomalyResponse.total_count ? this.state.anomalyResponse.total_count : 0
        let groupAnomalyfilteredCount = this.state.anomalyResponse.filtered_count ? this.state.anomalyResponse.filtered_count : 0
        
        let dataRow = {}
        dataRow.count = ''
        dataRow.category = 'Important / All'
        dataRow.orderBy = 0
        groupAnomalycategory.push(dataRow)

        let orderedArray = _.orderBy(groupAnomalycategory, ['orderBy'], ['asc'])

        this.setState({ groupAllAnomaly, groupAnomaly: groupAllAnomaly, groupAnomalycategory: orderedArray, groupAnomalyTotalCount, groupAnomalyfilteredCount, anomalyListLoader: false },
            () => this.filterData()    
        )
    }

    getCategoryEvents = () => {
        let filterdCategoryAnomaly = this.state.groupAllAnomaly
        if(this.state.selectedCategory !== 'Important / All') {
            filterdCategoryAnomaly = this.state.anomalyResponse.filter(arr => arr.label === this.state.selectedCategory)
            if(filterdCategoryAnomaly && filterdCategoryAnomaly.length) {
                filterdCategoryAnomaly = filterdCategoryAnomaly[0].events ? filterdCategoryAnomaly[0].events : []
            }
        }

        this.setState({ groupAnomaly: filterdCategoryAnomaly, selectedAnomalyId: '' },
            () => {
                this.filterData()
                // this.redirectToAnalysis()
                let scroll = document.getElementById('scrollTop');
                scroll.scrollIntoView();
            }
        )
    }

    filterData = () => {
        let data = this.state.groupAnomaly ? this.state.groupAnomaly : []
        data = _.orderBy(data, ['anomaly_time'], ['desc'])

        let totalPages = 1
		if(data.length > this.state.perPage) {
			totalPages = Math.ceil(data.length / this.state.perPage)
		}

        this.setState({ totalFilteredArray: data, filteredArray: data, totalPages },
            () => {
                this.formHeaders()
                if(this.state.filteredArray.length) {
                    this.getAnomlayDetails(this.state.filteredArray[0])
                }
            }    
        )
    }

	formHeaders = () => {
		let dataResult = this.state.filteredArray && this.state.filteredArray.length ? this.state.filteredArray : []
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
                console.log(key, typeof value)
				if(typeof value === "string" && key !== "event_data") {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})
			this.setState({ headers })
		}
	}

    getAnomlayDetails = (selectedRecord) => {
        
        this.setState({ selectedAnomalyId: selectedRecord.anomaly_id })

        let obj = {}
        obj.selectedTab = 'Details'
        obj.details = selectedRecord
        obj.merticsAssetNameInvolved = selectedRecord.asset_id ? [selectedRecord.asset_id] : []
        obj.brushStartTime = this.state.brushStartTime
        obj.brushEndTime = this.state.brushEndTime
        obj.pageType = this.state.pageType
        this.props.setAiopsPropsDetails('tabOptions', obj)
        this.props.setAiopsPropsDetails('alertsPageMonitoringList', {})
        this.props.setAiopsPropsDetails('errorEventPageMonitoringList', {})
    }
    
    handleChange = (event) => {

        this.setState({ groupType: event.target.value, showGroupLoading: true, selectedAnomalyId: '', anomalyListLoader: true },
            () => {
                if(this.state.groupType !== "All" && this.state.groupedBy === "anomaly_type") {
                    this.setState({ groupedBy: "severity"},
                        () => this.listObservabilityAnomalyResults()
                    )
                } else {
                    this.listObservabilityAnomalyResults()
                }
                // this.redirectToAnalysis()
            }    
        )
    }    

    // redirectToAnalysis = () => {
    //     let obj = {}
    //     obj.selectedTab = 'Analysis'
    //     obj.details = {}
    //     obj.merticsAssetNameInvolved = []
    //     obj.brushStartTime = this.state.brushStartTime
    //     obj.brushEndTime = this.state.brushEndTime
    //     obj.pageType = this.state.selectedPageType
    //     this.props.setAiopsPropsDetails('tabOptions', obj)
    // }

    handleClickOutside(event) {
        if (this.toggleRef && !this.toggleRef.current.contains(event.target)) {
            this.setState({ showPageTypeOptions: false })
        } else {
            this.setState({ showPageTypeOptions: true })
        }
    }
    
	render() {
		return (
            <div className="" id="scrollTop" onClick={(event) => this.handleClickOutside(event)}>
                <div className="w-100">
                    <div className={`d-flex mt-2 ml-1 ${this.state.anomalyListLoader ? 'disabled' : ''}`}>                        
                        {/* <p className="m-0 align-self-end small mr-2">Show the </p>
                        <div class={`transparentSelectBorderBottom`}>
                            <select class="form-control" 
                            onChange={this.handleChange}
                            value={this.state.groupType}
                            >
                                <option key="All" value="All">ALL</option>
                                <option key="service_name" value="service_name">Service</option>
                                <option key="asset_name" value="asset_name">Asset</option>
                                <option key="category" value="category">Category</option>
                                <option key="product" value="product">Product</option>
                                <option key="anomaly_name" value="anomaly_name">Anomaly Name</option>
                                <option key="anomaly_type" value="anomaly_type">Anomaly Type</option>
                            </select>
                        </div>  */}
                        <div className="select-sm-bg curvedDropdown minWidth120 mr-2" ref={this.toggleRef}>
                            <div className="dropdown">
                                <span className="dropdown-toggle d-flex justify-content-between px-2 text-white">
                                    {this.state.pageType ? this.state.pageType : <span className="placeholder">Select</span>}
                                </span>
                                <ul className={`dropdown-menu p-0 ${this.state.showPageTypeOptions ? "show" : ''}`}>
                                    {this.props.pageTypes.map(item => {
                                        return (
                                            <li 
                                                onClick={() => {
                                                    
                                                    this.props.setAiopsPropsDetails('tabOptions', {})
                                                    this.setState({ 
                                                        pageType: item, 
                                                        showPageTypeOptions: false
                                                    }, 
                                                        () => this.props.onChangePageType(item)
                                                    )
                                                }}
                                                className={`${this.state.pageType === item ? "selected" :  ""}`}
                                            >
                                                {item}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                        <p className="m-0 align-self-end small mr-2">Show </p>
                        <div class={`transparentSelectBorderBottom align-self-end`}>
                            <select class="form-control" 
                            onChange={this.handleChange}
                            value={this.state.groupType}
                            >
                                <option key="All" value="All">ALL</option>
                                <option key="metric" value="metric">Metric</option>
                                <option key="lifecycle" value="lifecycle">Lifecycle</option>
                                <option key="apicalls" value="apicalls">API Calls</option>
                                <option key="slow_query" value="slow_query">Slow Queries</option>
                                <option key="logs" value="logs">Logs</option>
                            </select>
                        </div> 
                        <p className="m-0 align-self-end small mx-2">anomalies groupd by</p>
                        {/* <p className="m-0 align-self-end small mx-2">Showing Anomalies groupd by</p> */}
                        <div class={`transparentSelectBorderBottom align-self-end`}>
                            <select class="form-control" 
                                onChange={(event) => {
                                    this.setState({ groupedBy: event.target.value, anomalyListLoader: true, selectedCategory: "Important / All", selectedAnomalyId: '' },
                                        () => {
                                            // this.redirectToAnalysis()
                                            this.listObservabilityAnomalyResults()
                                        }
                                    )
                                }}
                                value={this.state.groupedBy}                            
                                >
                                    <option key="severity" value="severity">Severity</option>
                                    <option key="asset_name" value="asset_name">Asset</option>
                                    <option key="service_name" value="service_name">Service</option>
                                    {(this.state.groupType === "All" || this.state.groupType === "logs") ? 
                                        <option key="anomaly_type" value="anomaly_type">Anomaly Type</option>
                                    : null}
                                {/* {this.state.aggregateByArray && this.state.aggregateByArray.map(item => {
                                    return(
                                        <option value={item}>{capitalizeFirstLetter(item)}</option>
                                    )
                                })} */}
                            </select>
                        </div>
                    </div>
                    
                    <div className="d-flex mt-1">
                        <div className={`${this.state.expandRIghtSection ? 'w-60' : 'w-60'} mr-1`}>
                            <div className="rounded bg-dark p-3">
                                <div className="d-flex mb-2 justify-content-between">
                                    <div className="d-flex mr-2 w-40">
                                        <p className="f12 m-0 align-self-center pt-1">Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.state.totalFilteredArray && this.state.totalFilteredArray.length}{this.state.totalFilteredArray && this.state.totalFilteredArray.length > 1 ? ' anomalies' : ' anomalies'}</p>
                                        <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{this.state.brushStartTime ? momentDateGivenFormat(this.state.brushStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.brushEndTime, 'DD MMM YYYY HH:mm') : ''}</p>
                                    </div>
                                    <div className="d-flex justify-content-end w-60 mb-n2">
                                        {this.state.totalFilteredArray && this.state.totalFilteredArray.length ?
                                            <CSVLink 
                                                data={this.state.filteredArray} 
                                                headers={this.state.headers ? this.state.headers : []}
                                                filename={"observability-alerts.csv"}
                                                className={"align-self-center"}
                                                target="_blank"
                                            >
                                                <i className="fas fa-download text-primary-color"></i>
                                                {/* <span className="text-primary-color mx-2">csv</span> */}
                                            </CSVLink>
                                        : null}
                                        {this.state.totalFilteredArray && this.state.totalFilteredArray.length ?
                                            <Search
                                                data={this.state.totalFilteredArray ? this.state.totalFilteredArray : []}
                                                applyTags={false}
                                                applyLiteDarkTags={true}
                                                topClassName={'bg-black5 align-self-center w-50 mr-1 border-gray5 rounded-5 f12'}
                                                searchClassName={'px-2 f12'}
                                                searchIconColor={'text-gray5 f12'}
                                                searchPlaceHolder={'Search anomaly....'}
                                                className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                                filteredData={(filteredArray) => {
                                                    this.setState({ filteredArray, errorDetails: {}, merticsAssetNameInvolved: [] },
                                                        () => {
                                                            if(this.state.filteredArray.length) {
                                                                this.getAnomlayDetails(this.state.filteredArray[0])
                                                            }
                                                        }    
                                                    )
                                                }}
                                            />
                                        : null}
                                        {this.state.filteredArray && this.state.filteredArray.length > this.state.perPage ?
                                            <div className="pagination errorPagePagination f12">
                                                <span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
                                                <button><i className={`fal fa-arrow-to-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('start', this.state.currentPage)}></i></button> 
                                                <button><i className={`fal fa-angle-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('previous', this.state.currentPage)}></i></button> 
                                                <span>
                                                    Go to
                                                    <input
                                                        type='number'
                                                        value={this.state.pageNumber || this.state.pageNumber === "" ? this.state.pageNumber : this.state.currentPage}
                                                        onChange={e => {
                                                            const page = e.target.value ? Number(e.target.value) : ""
                                                            this.gotoPage(page)
                                                        }}
                                                        className="ml-1 inputClass"
                                                    />
                                                </span>
                                                <button><i className={`fal fa-angle-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
                                                <button><i className={`fal fa-arrow-to-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
                                            </div>
                                        : null}
                                    </div>
                                </div>
                                
                                <div className="d-flex errorSectionScroll">
                                    {this.state.groupAnomalycategory ? 
                                        <div className="w-20 pr-3 categoryBoxSection">
                                            <div class="bg-muted px-2 pt-2 select-bgg">
                                                {this.state.groupedBy === "severity" ?
                                                    this.state.groupAnomalycategory.map(item => {
                                                        return(
                                                            item.category === "Important / All" ?
                                                                <div className={`d-flex p-2 cursorPointer ${this.state.selectedCategory === item.category ? 'text-info disabled' : ''}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryEvents())}>
                                                                    <p className="m-0 f-20">{item.category}</p>
                                                                    <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p>
                                                                </div>
                                                            :
                                                                <div class={`d-flex justify-content-end mb-2 ${!item.count ? 'disabled opacity50' : 'cursorPointer'}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryEvents())}>
                                                                    <div class="d-flex">
                                                                        {item.totalBars.map(bar => {
                                                                            return(
                                                                            <div className={`line-survey-1 risk-bg-${item.category.toLowerCase()}`}></div>
                                                                            )
                                                                        })}
                                                                        <div class={`font-weight-bold text-center risk-border-${item.category.toLowerCase()} text-white p-2`}>
                                                                            <p class="small mb-0" style={{minWidth: "75px"}}>{item.category+" "+item.count}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        )
                                                    })                                            
                                                : 
                                                    this.state.groupAnomalycategory.map((item, index) => {
                                                        return(
                                                            item.category === "Important / All" ?
                                                                <div className={`d-flex cursorPointer mb-3 ${this.state.selectedCategory === item.category ? 'text-info disabled' : ''}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryEvents())}>
                                                                    <p className="m-0 f-20">{item.category}</p>
                                                                    <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p>
                                                                </div>
                                                            :
                                                                <div className={`d-flex cursorPointer mb-2 ${this.state.selectedCategory === item.category ? 'text-info disabled' : (!item.count ? 'text-gray4 disabled test' : 'text-white')}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryEvents())}>
                                                                    <div class="d-flex justify-content-between mb-2" style={{maxWidth: "160px"}}>
                                                                        <p class="mb-0 align-self-center ellipseCategoryBoxSection w-60" id={'category_'+index}>{item.category}</p>
                                                                        <UncontrolledTooltip target={'category_'+index}>{item.category}</UncontrolledTooltip>
                                                                        <input class="form-control form-control-sm w-25 border-0 bg-grey text-white readOnly disabled rounded-0 form-number text-right" style={{minWidth: "40px"}} type="text" placeholder="1" value={item.count !== '' ? item.count : ''} aria-label=".form-control-sm example" />
                                                                    </div>
                                                                </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    : null}
                                    {/* {this.state.groupAnomalycategory ? 
                                        <div className="mr-1 w-20">
                                            <div className="col-12 p-0 bg-dark3 h-100">
                                                {this.state.groupAnomalycategory.map(item => {
                                                    return(
                                                        <div className={`d-flex p-2 border-bottom cursorPointer ${this.state.selectedCategory === item.category ? 'text-info disabled' : (!item.count ? 'text-gray4 disabled' : 'text-white')}`} onClick={() => this.setState({ selectedCategory: item.category, selectedAnomalyId: '' }, () => this.getCategoryEvents())}>
                                                            <p className="m-0 small">{item.category}</p>
                                                            <p className="m-0 align-self-end ml-2 small">{item.count !== '' ? item.count : ''}</p>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    : null} */}
                                    <div className={`${this.state.groupAnomalycategory ? 'w-80' : 'w-100'}`}>
                                        <div className={`bg-dark3 py-3 pl-2`} style={{minHeight: "545px"}}>
                                            {this.state.anomalyListLoader ? 
                                                <div className='d-flex justify-content-center m-4'>
                                                    <Spinner className='text-center' color='white' size='lg' />
                                                </div>
                                            : this.state.filteredArray && this.state.filteredArray.length ?
                                                this.state.filteredArray.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map((item, i) => {
                                                    return(
                                                        <div className={`d-flex mr-2 px-2 mb-2 pb-1 ${this.state.startRecord + this.state.perPage !== this.state.startRecord + i ? 'border-bottom-black5' : ''} ${this.state.selectedAnomalyId !== item.anomaly_id ? 'cursorPointer' : 'bg-muted text-white pt-1'}`} onClick={() => {
                                                            
                                                            this.props.setAiopsPropsDetails('tabOptions', {})
                                                            this.getAnomlayDetails(item)
                                                        }}
                                                        >
                                                            {this.state.groupType !== "logs" ?
                                                                <div className="d-flex">
                                                                    <span className={`f10 mr-2 align-self-start mt-1 badge-sm risk-badge-${item.severity ? item.severity.toLowerCase() : 'critical'}`}>
                                                                        {item.severity ? 
                                                                            (item.severity.toLowerCase() === 'critical' ? 'C' : item.severity.toLowerCase() === 'high' ? 'H' : item.severity.toLowerCase() === 'medium' ? 'M' : 'L') 
                                                                        : 'U'}
                                                                    </span>
                                                                    <p className="mb-0">
                                                                        <span className="mb-0 mr-2 f12 text-primary-color font-weight-bold">{item.anomaly_time ? momentConvertionUtcToLocalTime(item.anomaly_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                        <span className={`mb-0 mr-1 f12 text-purple-2`}>{item.anomaly_type ? item.anomaly_type : ''} </span>
                                                                        <span className="mb-0 mr-2 f12 font-weight-bold text-purple-2">Anomaly</span>
                                                                        {/* <span className={`mb-0 mr-2 f12 ${item.event_source === 'checks' ? 'text-purple' : item.event_source === 'config' ? 'text-secondary' : (item.event_source === 'cloudtrail' || item.event_source === 'cloudwatch_alarm') ? 'text-info' : 'text-gray3'} font-weight-bold`}>{capitalizeFirstLetter(item.event_source) + (item.event_name ? ' : ' + item.event_name : '') + (item.event_type ? ' : '+item.event_type : '')}</span> */}
                                                                        {/* <span className={`mb-0 mr-2 f12 text-purple-2`}>{item.anomaly_name + '  '+ (item.anomaly_type ? ' : ' + item.anomaly_type : '') + (item.title ? ' : ' + item.title : '') + ' score '+ (item.anomaly_score ? ' : '+item.anomaly_score : 0)} </span> */}
                                                                        {/* {item.asset_name && item.asset_name !== "" ? 
                                                                            <span className="mb-0 mr-2 f12 text-white font-weight-bold">{item.asset_name} </span>
                                                                        : null} */}
                                                                        <span className="mb-0 small text-primary-color mr-1">Resource </span>
                                                                        {item.account_id ?
                                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">{' '+item.account_id} </span>
                                                                        : null}
                                                                        {item.region ?
                                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+item.region} </span>
                                                                        : null}
                                                                        {item.anomaly_message ?
                                                                            <span className="mb-0 ml-2 f12 text-white font-weight-bold">{item.anomaly_message} </span>
                                                                        : null}
                                                                    </p>
                                                                </div>
                                                            :
                                                                <div className="d-flex">
                                                                    <span className={`f10 mr-2 align-self-start mt-1 badge-sm risk-badge-${item.severity ? item.severity.toLowerCase() : 'critical'}`}>
                                                                        {item.severity ? 
                                                                            (item.severity.toLowerCase() === 'critical' ? 'C' : item.severity.toLowerCase() === 'high' ? 'H' : item.severity.toLowerCase() === 'medium' ? 'M' : 'L') 
                                                                        : 'U'}
                                                                    </span>
                                                                    {item.type === "sequence" ?
                                                                        <p className="mb-0">
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.anomaly_time ? momentConvertionUtcToLocalTime(item.anomaly_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                            <span className={`mb-0 mr-1 f12 text-purple-2`}>Sequence/combination Anomaly </span>
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">Log lines matching the template </span>
                                                                            {item.additional_details && item.additional_details.template ?
                                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.additional_details.template} </span>
                                                                            : null}
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">came in an abnormal sequence. The anomalous behavior was observed </span>
                                                                            <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.count && item.count} times </span>
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold"> in </span>
                                                                            {item.asset_name && item.asset_name !== "" ? 
                                                                                <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.asset_name}  </span>
                                                                            : null}
                                                                            {item.service_name && item.service_name !== "" ? 
                                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">(service: {item.service_name},  </span>
                                                                            : null}
                                                                            {item.asset_id && item.asset_id !== "" ? 
                                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">ID: {item.asset_id}),  </span>
                                                                            : null}
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">writing to log group </span>
                                                                            {item.additional_details && item.additional_details.log_group ?
                                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.additional_details.log_group} </span>
                                                                            : null}
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">owned by account ID </span>
                                                                            {item.account_id ?
                                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.account_id} </span>
                                                                            : null}
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">in </span>
                                                                            {item.region ?
                                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.region} </span>
                                                                            : null}
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">region. This is a  </span>
                                                                            <span className={`mb-0 f12 ${item.severity ? "risk-"+item.severity.toLowerCase() : "white"} font-weight-bold`}>{item.severity} </span>
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold"> severity anomaly predicted on the  </span>
                                                                            <span className="mb-0 mr-1 f12 text-white font-weight-bold">last {item.period} </span>
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">data. </span>
                                                                        </p>
                                                                    : item.type === "numerical" ?
                                                                        <p className="mb-0">
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.anomaly_time ? momentConvertionUtcToLocalTime(item.anomaly_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                            <span className={`mb-0 mr-1 f12 text-purple-2`}>Numerical value Anomaly </span>
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">The numerical values for keys </span>
                                                                            {item.keys && item.keys.length ?
                                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.keys.join(", ")} </span>
                                                                            : null}
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">are not normal occurrences. The anomalous behavior was observed </span>
                                                                            <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.count && item.count} times </span>
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">in </span>
                                                                            {item.asset_name && item.asset_name !== "" ? 
                                                                                <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.asset_name}  </span>
                                                                            : null}
                                                                            {item.service_name && item.service_name !== "" ? 
                                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">(service: {item.service_name},  </span>
                                                                            : null}
                                                                            {item.asset_id && item.asset_id !== "" ? 
                                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">ID: {item.asset_id}),  </span>
                                                                            : null}
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">writing to log group </span>
                                                                            {item.additional_details && item.additional_details.log_group ?
                                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.additional_details.log_group} </span>
                                                                            : null}
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">owned by account ID </span>
                                                                            {item.account_id ?
                                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.account_id} </span>
                                                                            : null}
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">in </span>
                                                                            {item.region ?
                                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.region} </span>
                                                                            : null}
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">region. This is a  </span>
                                                                            <span className={`mb-0 f12 ${item.severity ? "risk-"+item.severity.toLowerCase() : "white"} font-weight-bold`}>{item.severity} </span>
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold"> severity anomaly predicted on the </span>
                                                                            <span className="mb-0 mr-1 f12 text-white font-weight-bold">last {item.period} </span>
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">data. </span>
                                                                        </p>
                                                                    : item.type === "categorical" ?
                                                                        <p className="mb-0">
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.anomaly_time ? momentConvertionUtcToLocalTime(item.anomaly_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                            <span className={`mb-0 mr-1 f12 text-purple-2`}>Categorical value Anomaly </span>
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">The categorical value </span>
                                                                            {item.cat_value ?
                                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.cat_value} </span>
                                                                            : null}
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">is not a normal occurrence. The anomalous behavior was observed </span>
                                                                            <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.count && item.count} times </span>
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">in </span>
                                                                            {item.asset_name && item.asset_name !== "" ? 
                                                                                <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.asset_name}  </span>
                                                                            : null}
                                                                            {item.service_name && item.service_name !== "" ? 
                                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">(service: {item.service_name},  </span>
                                                                            : null}
                                                                            {item.asset_id && item.asset_id !== "" ? 
                                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">ID: {item.asset_id}),  </span>
                                                                            : null}
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">writing to log group </span>
                                                                            {item.additional_details && item.additional_details.log_group ?
                                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.additional_details.log_group} </span>
                                                                            : null}
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">owned by account ID </span>
                                                                            {item.account_id ?
                                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.account_id} </span>
                                                                            : null}
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">in </span>
                                                                            {item.region ?
                                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.region} </span>
                                                                            : null}
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">region. This is a  </span>
                                                                            <span className={`mb-0 f12 ${item.severity ? "risk-"+item.severity.toLowerCase() : "white"} font-weight-bold`}>{item.severity} </span>
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold"> severity anomaly predicted on the </span>
                                                                            <span className="mb-0 mr-1 f12 text-white font-weight-bold">last {item.period} </span>
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">data. </span>
                                                                        </p>
                                                                    : item.type === "newtemplate" ?
                                                                        <p className="mb-0">
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.anomaly_time ? momentConvertionUtcToLocalTime(item.anomaly_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                            <span className={`mb-0 mr-1 f12 text-purple-2`}>New log line Anomaly </span>
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">Log lines matching the template </span>
                                                                            {item.additional_details && item.additional_details.template ?
                                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.additional_details.template} </span>
                                                                            : null}
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">had not been observed previously. Such lines were observed </span>
                                                                            <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.count && item.count} times </span>
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">in the </span>
                                                                            <span className="mb-0 mr-1 f12 text-white font-weight-bold">last {item.period} </span>
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">data from </span>
                                                                            {item.asset_name && item.asset_name !== "" ? 
                                                                                <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.asset_name}  </span>
                                                                            : null}
                                                                            {item.service_name && item.service_name !== "" ? 
                                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">(service: {item.service_name},  </span>
                                                                            : null}
                                                                            {item.asset_id && item.asset_id !== "" ? 
                                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">ID: {item.asset_id}),  </span>
                                                                            : null}
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">writing to log group </span>
                                                                            {item.additional_details && item.additional_details.log_group ?
                                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.additional_details.log_group} </span>
                                                                            : null}
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">owned by account ID </span>
                                                                            {item.account_id ?
                                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.account_id} </span>
                                                                            : null}
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">in </span>
                                                                            {item.region ?
                                                                                <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.region} </span>
                                                                            : null}
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">region. This is a  </span>
                                                                            <span className={`mb-0 f12 ${item.severity ? "risk-"+item.severity.toLowerCase() : "white"} font-weight-bold`}>{item.severity} </span>
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold"> severity anomaly  </span>
                                                                        </p>
                                                                    : null}
                                                                </div>

                                                            }
                                                        </div>
                                                    )
                                                })
                                            :
                                                <div className='d-flex justify-content-center m-4'>
                                                    <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!this.state.anomalyListLoader ? 
                            <div className={`${this.state.expandRIghtSection ? 'w-40' : 'w-40'}`}>
                                <RightSection
                                    updateDetail = {() => this.setState({ selectedAnomalyId: '' }, () => this.props.setAiopsPropsDetails('tabOptions', {}))}
                                    pageType={this.state.pageType}
                                />
                            </div>
                        : null}
                    </div>
                </div>
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
AnomalyDashboard.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('errorDashboard',state)
	return {
        aiopsCommonPageFilter: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.aiopsCommonPageFilter ? state.aiops.aiopsPropsDetails.aiopsCommonPageFilter : {},
    }
}

export default connect(mapStateToProps, {
    setAiopsPropsDetails,
    listObservabilityAnomalyResults,
    getEventDetails
})(withRouter(AnomalyDashboard))