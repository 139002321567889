
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file RunCloudwatchInsightsQuery.js
 * @author Prakash // on 09/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Select from 'react-select';
import { Input } from 'reactstrap'
import { setAutomationPropsDetails } from '../../actions/commonAction'
import { listAssets } from '../../actions/detectors/detectorsAction'
import { onlyNumeric } from '../../utils/utility'
import { Spinner } from 'reactstrap'

class RunCloudwatchInsightsQuery extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            activeSection: this.props.activeSection,
            durationOptions: ["Minutes", "Hour", "Day", "Month"],
            selectedPeriod: "Minutes",
            previousAutomations: [],
            logGroupLoader: true
        }
    }

    componentDidMount = () => {
        if(this.props.automationPropsDetails) {
            let obj = this.props.automationPropsDetails
            let exist = obj.actions.filter(e => e.section === this.state.activeSection)
            if(exist.length) {
                let input = exist[0].input ? exist[0].input : []
                input.forEach(item => {
                    if(item.key === "duration") {
                        let duration = ""
                        let selectedPeriod = this.state.selectedPeriod
        
                        let str = item.value ? item.value : "";
                        if(str !== "") {
                            let period = str.substring(str.length, str.length-1)
                            if(period === "h") {
                                selectedPeriod = "Hour"
                            } else if(period === "d") {
                                selectedPeriod = "Day"
                            } else if(period === "M") {
                                selectedPeriod = "Month"
                            } else if(period === "m") {
                                selectedPeriod = "Minutes"
                            }
                            duration = str.substring(0, str.length - 1)
                        }

                        this.setState({ duration, selectedPeriod },
                            () => this.checkAllDataFilled()    
                        )
                    } else {
                        this.setState({ [item.key]: item.value,  },
                            () => {
                                if(item.dynamic) {
                                    this.setState({ ["dynamic_"+item.key]: true })
                                }
                                if(item.key === "queryString") {
                                    this.onLoadDropFields(item, "")
                                }
                                this.checkAllDataFilled()
                            }
                        )
                    }
                })                
            }
            let actions = this.props.automationPropsDetails.actions ? this.props.automationPropsDetails.actions : []
            let previousAutomations = actions.filter(e => e.section < this.state.activeSection)

            this.setState({ previousAutomations, previousAutomationsSearchResult: previousAutomations })

            this.listAssets()
        }
    }

    componentDidUpdate = (prevProps) => {}

    onActionSelect = (action) => {
        this.setState({ 
            selectedAction: action, 
            showActionResults: false 
        },
            // () => this.formInputProps(["action_"+this.state.activeSection], "action", this.state.selectedAction)
        )
    }

    listAssets = () => {
        let params = {}
        params.provider = this.props.automationPropsDetails.provider
        params.account_id = this.props.automationPropsDetails.account_id
        params.region = this.props.automationPropsDetails.region
        params.service_name = "cloudwatch-loggroups"
        
        if(params.resource_type !== '' && params.region !== '') {
            this.props.listAssets(params, (promise, response) => {
                if (promise) {
                    this.setState({
                        logGroupOptions: response && response.results ? response.results : "", 
                        // logGroupName: response && response.results && response.results.length === 1 ? response.results[0].asset_name : "",
                        logGroupLoader: false, 
                    })
                } else {
                    this.setState({ logGroupOptions: [], logGroupLoader: false })
                }
            })
        }
    }

    formInputProps = (section, field, value) => {
        let obj = this.props.automationPropsDetails
        let actions = obj.actions
        let objIndex = actions.findIndex((e => e.section === section))
        if(field === "duration") {
            let period = ""
            if(this.state.selectedPeriod === "Hour") {
                period = "h"
            } else if(this.state.selectedPeriod === "Day") {
                period = "d"
            } else if(this.state.selectedPeriod === "Month") {
                period = "M"
            } else if(this.state.selectedPeriod === "Minutes") {
                period = "m"
            }
            value = this.state.duration+""+period
        }
        let dataRow = {}
        dataRow.key = field
        dataRow.value = value
        if(this.state["dynamic_"+field]) {
            dataRow.dynamic = true
        }

        let input = actions[objIndex].input ? actions[objIndex].input : []
        let inputIndex = input.findIndex((e => e.key === field))
        if(inputIndex > -1) {
            input[inputIndex] = dataRow
        } else {
            input.push(dataRow)
        }
        actions[objIndex].input = input
        obj.actions = actions
        this.props.setAutomationPropsDetails(obj)

        this.checkAllDataFilled()
    }

    checkAllDataFilled = () => {
        if(this.state.logGroupName && this.state.logGroupName !== "" && this.state.duration && this.state.duration !== "" && this.state.queryString && this.state.queryString !== "" ) {
            this.props.actionCompleted(true)
        } else {
            this.props.actionCompleted(false)
        }
    }

    checkEnteredCharacter = (e, val, field, index) => {
        // var editable = document.querySelectorAll('div[contentEditable]');
        let className = field+"_"+this.state.activeSection
        let fieldState = field
        if(index !== "") {
            fieldState = field+"_"+index
            className = field+"_"+this.state.activeSection+"_"+index
        }

        let innerHtml = document.getElementsByClassName(className)[0].innerHTML
        let regex = /(style=".+?")/gm;
        innerHtml = innerHtml.replace(regex, "");
        innerHtml = innerHtml.replaceAll('<span class="text-white">&nbsp;</span>', "")
        innerHtml = innerHtml.replaceAll('<span class="text-info">', "")
        innerHtml = innerHtml.replaceAll('<span class="text-white">', "")
        innerHtml = innerHtml.replaceAll('</span>', "")
        // innerHtml = innerHtml.replace(/<span[^>]*>.*<\/span>/gm, '')

        // let html = document.getElementsByClassName(className)[0].innerHTML
        // html = html $('<some element/>')[0].outerHTML;
        // var target = document.querySelectorAll('sapn');
        // Array.prototype.forEach.call(target, function(element){
        //     element.removeAttribute('style');
        // });
        // var onlyText = this.removeHTML(html);
        // let onlyText = html.find('span').removeAttr('style')
        // console.log("onlyText", onlyText)

        // html = html.replace(/<(.|\n)*?>/g, '');
        // html = html.replace(/<\/?[^>]+(>|$)/g, "");
        // html = html.replace(/<[^>]*>?/gm, '');
        // html = html.replace(/<(?:.|\n)*?>/gm, '');
        // html = html.replace(/(<([^>]+)>)/gi, "");
        // html = html.replace(/(<style[\w\W]+style>)/g, "")
        // var div = document.createElement("div");
        // div.innerHTML = html;
        // let text = div.textContent || div.innerText || "";
        // console.log(text); // Hello, World
        // console.log("html", html)
        // console.log("innerHtml", innerHtml)

        var re = /<div>(.*?)<\/div>/;
        var result = innerHtml.split(re);

        let stringToSave = ""
        if(result.length) {
            let visit = 0
            result.forEach((item, rIndex) => {
                if(item !== "") {
                    
                    let br = ""
                    // if(rIndex && visit) {
                    //     br = "/n"
                    // }
                    // if(result.length - 1 !== rIndex) {
                    //     br = "/n"
                    // }
                    if(item === "<br>") {
                        item = "/n"
                    } else if(rIndex && visit) {
                        br = "/n"
                    }
                    stringToSave += br+""+item
                    visit++;
                }
            })
        }

        let position = stringToSave.indexOf("$$");
        if(position >= 0) {
            this.setState({ ["show_"+field+"_drop_option_"+index]: true, [fieldState]: stringToSave })
        } else {
            this.setState({ ["show_"+field+"_drop_option_"+index]: false, [fieldState]: stringToSave })
        }
        this.setState({ [fieldState]: stringToSave },
            () => this.formInputProps(this.state.activeSection, field, this.state[fieldState])
        )
    }

    removeHTML = (str) => {
        var tmp = document.createElement("DIV");
        tmp.innerHTML = str;
        return tmp.textContent || tmp
    }

    selectedpreviousAutomation = (field, index, actionDetails, childIndex, childDetails) => {
        this.state.previousAutomations.forEach((item, i) => {
            this.setState({ ["showChild_"+i]: false })
        })

        //if there is child it will be confiigured when real data is available for child section
        // this.setState({ ["showChild_"+childIndex]: !this.state["showChild_"+childIndex] })

        //if there is no child
        this.selectedField(field, index, actionDetails, childIndex, childDetails)
    }

    selectedField = (field, index, actionDetails, childIndex, childDetails) => {
        let className = field+"_"+this.state.activeSection
        let fieldState = field
        if(index !== "") {
            fieldState = field+"_"+index
            className = field+"_"+this.state.activeSection+"_"+index
        }

        let stringToSave = this.state[fieldState]

        if(actionDetails !== "") {
            let res = "${"+actionDetails.name+".}"
            if(childDetails !== "") {
                res = "${"+actionDetails.name+"."+childDetails.name+"}"
            }
            stringToSave = stringToSave.replace("$$", res)
        }
        //form html from the string to show in the contenteditable        
        let innertHtmlData = ""
        let splitString = stringToSave.split("/n")
        let re = /{(.*?)}/;
        splitString.forEach(str => {
            innertHtmlData += "<div>"
            if(str !== "") {
                let result = str.split(re);
                let previousdata = ""
                result.forEach((item, i) => {
                    let lastTwoLetters = item.substring(item.length, item.length-2)
                    let lastLetter = item.charAt(item.length-1)
                    if(previousdata === "$") {
                        innertHtmlData += '<span class="text-info">${'+item+'}</span> <span class="text-white">&nbsp;</span>'
                    } else if(item !== "$") {
                        if(lastLetter === '$' && item !== "$$" && lastTwoLetters !== "$$") {
                            item = item.substring(0, item.length - 1)
                        }
                        innertHtmlData += '<span class="text-white">'+item+'</span>'
                    }

                    previousdata = lastTwoLetters === "$$" ? "" : lastLetter
                })
            } else {
                innertHtmlData += "<br>"    
            }

            innertHtmlData += "</div>"
        })

        document.getElementsByClassName(className)[0].innerHTML = innertHtmlData

        this.setState({ ["show_"+field+"_drop_option_"+index]: false, [fieldState]: stringToSave },
            () => this.formInputProps(this.state.activeSection, field, this.state[fieldState])
        )
    }

    onLoadDropFields = (list, index) => {
        let className = list.key+"_"+this.state.activeSection
        if(index !== "") {
            className = list.key+"_"+this.state.activeSection+"_"+index
        }

        let stringToSave = list.value ? list.value : ""
        
        //form html from the string to show in the contenteditable        
        if(stringToSave && stringToSave !== "") {
            let innertHtmlData = ""
            let splitString = stringToSave.split("/n")
            let re = /{(.*?)}/;
            splitString.forEach(str => {
                innertHtmlData += "<div>"
                if(str !== "") {
                    let result = str.split(re);
                    let previousdata = ""
                    result.forEach((item, i) => {
                        let lastTwoLetters = item.substring(item.length, item.length-2)
                        let lastLetter = item.charAt(item.length-1)
                        if(previousdata === "$") {
                            innertHtmlData += '<span class="text-info">${'+item+'}</span> <span class="text-white">&nbsp;</span>'
                        } else if(item !== "$") {
                            if(lastLetter === '$' && item !== "$$" && lastTwoLetters !== "$$") {
                                item = item.substring(0, item.length - 1)
                            }
                            innertHtmlData += '<span class="text-white">'+item+'</span>'
                        }

                        previousdata = lastTwoLetters === "$$" ? "" : lastLetter
                    })
                } else {
                    innertHtmlData += "<br>"
                }
                innertHtmlData += "</div>"
            })
            
            document.getElementsByClassName(className)[0].innerHTML = innertHtmlData
        }
        // this.setState({ [fieldState]: stringToSave })

        // this.formInputProps(this.state.activeSection, field, stringToSave)
    }

    render() {
        return (
            <div className="">
                <div className="d-flex mt-3">
                    <div className="w-50" >
                        <div className="d-flex justify-content-between">
                            <label className="mb-1">
                                {this.state.logGroupLoader ? <Spinner color="whtie" size="sm" className="mx-1" /> :null}
                                Name of log group over which the insights query will be run
                            </label>
                            {/* <small className={`${this.state.hasError && (!this.state.selectedTriggerName || this.state.selectedTriggerName === "") ? "text-danger" : ""} mr-2`}>(required)</small> */}
                        </div>
                        
                        <div className="col-sm-12 p-0">{/*onBlur={() => this.setState({ show_logGroupName: false })}*/}
                            <Select 
                                options={this.state.logGroupOptions && this.state.logGroupOptions.map(item => ({
                                    value: item.asset_name,
                                    label: item.asset_name,
                                }))}
                                value={({
                                    value: this.state.logGroupName && this.state.logGroupName,
                                    label: this.state.logGroupName ? this.state.logGroupName : <span className="placeholder">Select log group</span>
                                })}
                                // menuIsOpen={true}
                                onChange={event => 
                                    this.setState({ logGroupName: event.value },
                                        () => {
                                            this.formInputProps(this.state.activeSection, "logGroupName", this.state.logGroupName)
                                        }
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className="pl-3 w-50">
                        <label className="mb-1">Duration to check before the trigger</label>
                        <div className="d-flex">
                            <Input
                                type="text"
                                placeholder="duration"
                                className="inputTextbox mr-1 w-20"
                                value={this.state.duration}
                                onChange={event => 
                                    this.setState({ duration: onlyNumeric(event.target.value) },
                                        () => this.formInputProps(this.state.activeSection, "duration", this.state.duration)
                                    )
                                }
                            />
                            <div className="w-25">
                                <Select 
                                    options={this.state.durationOptions && this.state.durationOptions.map(item => ({
                                        value: item,
                                        label: item,
                                    }))}
                                    value={({
                                        value: this.state.selectedPeriod && this.state.selectedPeriod,
                                        label: this.state.selectedPeriod ? this.state.selectedPeriod : <span className="placeholder">Select period</span>
                                    })}
                                    // menuIsOpen={true}
                                    onChange={event => 
                                        this.setState({ selectedPeriod: event.value },
                                            () => {
                                                this.formInputProps(this.state.activeSection, "duration", this.state.selectedPeriod)
                                            }
                                        )
                                    }
                                />
                            </div>
                        </div>                    
                    </div>
                </div>
                <div className="mt-3">
                    <div className="position-relative d-inline-block w-100">
                        <label className="mb-1">Insights Query String</label>
                        <div contenteditable="true" 
                            className={`form-control inputTextbox contentEditable h-auto f14 minHeight75px queryString_${this.state.activeSection}`}
                            placeholder="Enter query string..."
                            id={"queryString"}
                            onKeyUp={e => this.checkEnteredCharacter(e, e.currentTarget.textContent, "queryString", "")}
                            // onInput={e => this.setState({ queryString: e.currentTarget.textContent })}
                        >
                        </div>
                        {this.state["show_queryString_drop_option_"] && this.state.previousAutomations && this.state.previousAutomations.length ? 
                            <div className={`dropdownOptions topAuto mt-2`}>
                                {this.state.previousAutomations.map((prevAct, j) => {
                                    return (
                                        <React.Fragment>
                                            <button className="d-flex flex-column mb-3" onClick={() => this.selectedpreviousAutomation("queryString", "", prevAct, j, "")}>
                                                <div className="d-flex justify-content-between w-100">
                                                    <div>
                                                        <p className="mb-0 text-left">{prevAct.name}</p>
                                                        <p className="mb-0 small description">{prevAct.action_name}</p>
                                                    </div>
                                                    {/* if there is child it will be confiigured when real data is available for child section */}
                                                    {/* {this.state["showChild_"+j] ? 
                                                        <i className="fas fa-angle-up mr-2 f18"></i>
                                                    :
                                                        <i className="fas fa-angle-down mr-2 f18"></i>
                                                    } */}
                                                    
                                                </div>
                                            </button>
                                            {/* {this.state["showChild_"+j] ? 
                                                this.state.previousAutomations.map(child => {
                                                    return(
                                                    <button className={`d-flex flex-column ml-2 ${j === this.state.previousAutomations.length - 1 ? "mb-3" : "mb-2 mt-n2"} `}  onClick={() => this.selectedField("queryString", index, prevAct, j, child)}>
                                                        <p className="mb-0 f12 label">{child.action_name}</p>
                                                    </button>
                                                    )
                                                })
                                            : null} */}
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                        : null}
                    </div>
                </div>
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
RunCloudwatchInsightsQuery.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log("RunCloudwatchInsightsQuery",state)
    return {
        automationPropsDetails: state.filters.automationPropsDetails
    }
}

export default connect(mapStateToProps, {
    listAssets,
    setAutomationPropsDetails,
})(withRouter(RunCloudwatchInsightsQuery))        