/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Remediation.js
 * @author Prakash // on 10/02/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Spinner, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap'
import { apiToDevCall } from '../../../actions/commonAction'
import { 
    getRemediationDetails,
    getArtifactDetails,
    getRemediationStatus,
    listRemediation,
    startRemediation,
} from '../../../actions/aiops/RemediationAction'
import { setAiopsPropsDetails, getCaseDetails } from '../../../actions/aiops/AiopsAction'
import { capitalizeFirstLetter, onlyNumeric, momentDateGivenFormat } from '../../../utils/utility'
import _ from 'lodash'
import moment from 'moment'

import TextField from '../../common/formFields/TextField'
import Checkbox from '../../common/formFields/Checkbox'
import SelectField from '../../common/formFields/SelectField'

import ModifyVolume from './pages/ModifyVolume'
import VmTypeRemediations from './pages/VmTypeRemediations'
import CleanupDisk from './pages/CleanupDisk'
import ListStorageTypes from './pages/ListStorageTypes'
import IncreaseDecreseInstanceAiops from './pages/IncreaseDecreseInstanceAiops'
import RdsStorageAutoscaling from './pages/RdsStorageAutoscaling'
import LambdaRuntimeVersion from './pages/LambdaRuntimeVersion'
import EnableRDSMulti from './pages/EnableRDSMulti'
import CreateRDSReadReplica from './pages/CreateRDSReadReplica'
import ModifyLambdaMemory from './pages/ModifyLambdaMemory'
import ModifyLambdaTimeout from './pages/ModifyLambdaTimeout'
import IncreaseRDSInstanceIOPS from './pages/IncreaseRDSInstanceIOPS'

import ExpandRDSVolume from './pages/ExpandRDSVolume'
import ChangeRDSVolumeType from './pages/ChangeRDSVolumeType'

const modifyVolume = ['REM_001', 'REM_026']
const increaseDecreseInstanceAiops = ['REM_014', 'REM_016']
const lambdaRuntimeVersion = ['REM_020']


const expandRDSVolume =  ['REM_011']
const changeRDSVolumeType = ['REM_012']
const listStorageTypes = ['REM_013']//REM_017
const cleanupDisk = ['REM_009']
const vmTypeRemediations = ['REM_004', 'REM_005', 'REM_024', 'REM_025']
const rdsStorageAutoscaling = ['REM_015']
const enableRDSMulti = ['REM_021']
const createRDSReadReplica = ['REM_0221']
const modifyLambdaMemory = ['REM_018']
const modifyLambdaTimeout = ['REM_019']
const increaseRDSInstanceIOPS = ['REM_022']


let sepratePages = ['REM_001', 'REM_011', 'REM_018', 'REM_019', 'REM_026', 'REM_004', 'REM_005', 'REM_012', 'REM_024', 'REM_025', 'REM_008', 'REM_009', 'REM_013', 'REM_017', 'REM_014', 'REM_016', 'REM_015', 'REM_020', 'REM_021', 'REM_022']


const instanceType = [
    'Mac',
    'T4g',
    'T3'
]

// const paramaters = [ 
//     {
//         "label": "Increase by",
//         "name": "IncreasePercentage",
//         "component": "textbox",
//         "mandatory": true,
//         "validation": {
//             "data_type": "number",
//             "min": 0,
//             "max": 100
//         },
//         "unit": "Percent",
//         "apiToCall": {
//             "api": "commonServices",
//             "name": "/list-log-type",
//             "method": "post",
//             "body": {
//                 "keys": [{
//                         "key": "provider",
//                         "value": "provider"
//                     },
//                     {
//                         "key": "service",
//                         "value": "service_name"
//                     }
//                 ]
//             }
//         }
//     },
//     {
//         "label": "List of files/directories to remove",
//         "helper_text": "Eg:/home/ec2-user/dir1*",
//         "name": "objects",
//         "component": "multiple_textbox",
//         "mandatory": false,
//         "validation": {
//             "data_type": "string_list",
//             "min_array_length": 1,
//             "min_str_length": 1
//         }
//     },
//     {
//         "label": "Date",
//         "helper_text": "Eg:/home/ec2-user/dir1*",
//         "name": "date",
//         "component": "date",
//         "mandatory": true,
//         "validation": {
//             "data_type": "date",
//             "future_date": true,
//         }
//     },
//     {
//         "label": "Date Range",
//         "helper_text": "Eg:/home/ec2-user/dir1*",
//         "name": "date_range",
//         "component": "date_range",
//         "mandatory": true,
//         "validation": {
//             "data_type": "date_range",
//             "past_date": true,
//         }
//     },
//     {
//         "label": "Select Option",
//         "helper_text": "Eg:/home/ec2-user/dir1*",
//         "name": "select",
//         "component": "select",
//         "mandatory": true,
//         "validation": {
//             "data_type": "select",
//             "past_date": true,
//         }
//     },
//     {
//         "label": "Multi Select Option",
//         "helper_text": "Eg:/home/ec2-user/dir1*",
//         "name": "multi_select",
//         "component": "multi_select",
//         "mandatory": true,
//         "validation": {
//             "data_type": "multi_select",
//             "past_date": true,
//         }
//     }
    
// ]


const today = moment();
const disableFutureDt = current => {
  return current.isBefore(today)
}
const yesterday = moment().subtract(1, 'day');
const disablePastDt = current => {
    return current.isAfter(yesterday)
}

class RemediationLeftSection extends Component {
    
    constructor(props) {

        super(props)
        this.scrollRef = React.createRef()
        this.sliderWrap = React.createRef()
        
        this.state = {
            showLoading: false,
            
            remediationList: [],
            filteredRemediationList: [],
            selectedRecord: {},
            selectedRecordId: '',

            remediationDetails: {},
            showDetailLoading: false,
            
            remediationStared: false,
            remediationProcessed: false,

            progress: 30,
            remediationStatus: {},
            instanceType: instanceType,
            loop: 0,

            totalItemShowing: 0,
            showingLeftIcon: false,
            showingRightIcon: true,
			showingOffStart: 1,
            showingOff: 0,
            caseId: this.props.caseId,

            validationError: false,
            sampleState: [true]
        }
        this.updateFormFieldValue = this.updateFormFieldValue.bind(this);
        this.updateAcknowledgDetails = this.updateAcknowledgDetails.bind(this);
        
    }

    // handleDateTimeChange  = this.handleDateTimeChange.bind(this)

	componentDidMount = () => {
        this.setState({ showLoading: true })
        this.listRemediation()
        
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.aiopsPropsDetails && Object.entries(this.props.aiopsPropsDetails).length) {
            if(prevProps.aiopsPropsDetails.remediation_status_change && prevProps.aiopsPropsDetails.remediation_status_change !== this.props.aiopsPropsDetails.remediation_status_change && this.props.aiopsPropsDetails.remediation_status_change !== '')  {
                this.refreshListRemediation()
                this.updateCaseDetails()
            } else if(!prevProps.aiopsPropsDetails.remediation_status_change && this.props.aiopsPropsDetails.remediation_status_change && this.props.aiopsPropsDetails.remediation_status_change !== '') {
                this.refreshListRemediation()
                this.updateCaseDetails()
            }
        }
    }

    formInputState = () => {
        Object.entries(this.props.aiopsRemediationParams).forEach(([key, value]) => {
            this.setState({ [key]: value }, () => {
                console.log(this.state)
            })
        })
    }

    updateCaseDetails = () => {
        let caseDetailParams = {}
        caseDetailParams.case_id = this.state.caseId
        caseDetailParams.alerts = true
        caseDetailParams.assets = true
        this.props.getCaseDetails(caseDetailParams, (promise, result) => {})
    }

    refreshListRemediation = () => {
        let remList = {}
        remList.case_id = this.state.caseId
        this.props.listRemediation(remList, (promise, result) => {
            if(promise) {
                result = result.filter(e => (e.remediation_id || (e.remediations && e.remediations.length)))
                let selectedRecord = []
                let filteredRemediationList = []
                let filteredResult = result.filter(arr => arr.asset_name === this.props.selectedRemediation.asset_name)
                let selectedRecordStatus = filteredResult.status ? filteredResult.status : ' '
                let selectedAsset = filteredResult.asset_name
                let selectedAssetId = filteredResult.asset_id ? filteredResult.asset_id : ''
                let selectedServiceName = filteredResult.service_name ? filteredResult.service_name : ''
                let selectedResourceType = filteredResult.resource_type ? filteredResult.resource_type : ''
                let selectedAccountId = filteredResult.account_id
                let selectedRegion = filteredResult.region
                let selectedRecordId = filteredResult.remediation_id                

                this.setState({ remediationList: result, filteredRemediationList, selectedAsset, selectedAssetId, selectedServiceName, selectedResourceType, selectedAccountId, selectedRegion, selectedRecord, selectedRecordId, selectedRecordStatus })
                
            } else {
                this.setState({ remediationList: [] })
            }
        })
    }
    
    listRemediation = () => {
        this.props.setAiopsPropsDetails('remediationParams', {})
        let remList = {}
        remList.case_id = this.state.caseId
        this.props.listRemediation(remList, (promise, result) => {
            if(promise) {
                result = result.filter(e => (e.remediation_id || (e.remediations && e.remediations.length)))
                let selectedRecord = []
                let selectedRecordId = ''
                let filteredRemediationList = []
                let selectedRecordStatus = result[0].status ? result[0].status : ' '
                let selectedAsset = result[0].asset_name
                let selectedAssetId = result[0].asset_id ? result[0].asset_id : ''
                let selectedServiceName = result[0].service_name ? result[0].service_name : ''
                let selectedResourceType = result[0].resource_type ? result[0].resource_type : ''
                let selectedAccountId = result[0].account_id
                let selectedRegion = result[0].region

                if(result.length && (!result[0].status || result[0].status === 'Open')) {
                    filteredRemediationList = _.orderBy(result[0].remediations, ['recommended'])
                    selectedRecord = filteredRemediationList[0]
                    selectedRecordId = filteredRemediationList[0].processor_rule_id
                    let obj = {}
                    obj.asset_name = selectedAsset
                    obj.asset_id = selectedAssetId
                    obj.service_name = selectedServiceName
                    obj.resource_type = selectedResourceType
                    obj.remediation_id = selectedRecordId
                    obj.account_id = selectedAccountId
                    obj.region = selectedRegion
                    obj.selectedRecord = selectedRecord
                    obj.sideMenu = 'Overview'
                    this.props.setAiopsPropsDetails('selected_remediation', obj)

                    if(filteredRemediationList.length) {
                        this.setState({ showLoading: false, remediationList: result, filteredRemediationList, selectedAsset, selectedAssetId, selectedServiceName, selectedResourceType, selectedAccountId, selectedRegion, selectedRecord, selectedRecordId, selectedRecordStatus },
                            () => {
                                this.getRemediationDetails()
                                this.slideItemRight('initiate')
                                if(!sepratePages.includes(this.state.selectedRecord.processor_rule_id)) {
                                    this.checkParamaters(selectedRecord)
                                }
                            }
                        )
                    } 
                } else {
                    selectedRecordId = result[0].remediation_id

                    let obj = {}
                    obj.asset_name = selectedAsset
                    obj.asset_id = selectedAssetId
                    obj.service_name = selectedServiceName
                    obj.resource_type = selectedResourceType
                    obj.remediation_id = selectedRecordId
                    obj.account_id = selectedAccountId
                    obj.region = selectedRegion
                    obj.selectedRecord = selectedRecord
                    obj.sideMenu = 'Execution'
                    this.props.setAiopsPropsDetails('selected_remediation', obj)
                    // this.props.setAiopsPropsDetails('selectedRemediationAssetDetail', result && result[0] ? result[0] : {} )

                    this.setState({ showLoading: false, remediationList: result, filteredRemediationList, selectedAsset, selectedAssetId, selectedServiceName, selectedResourceType, selectedAccountId, selectedRegion, selectedRecord, selectedRecordId, selectedRecordStatus },
                        () => {
                            this.getRemediationStatus(selectedRecordId)
                            this.slideItemRight('initiate')
                        }
                    )
                }                
            } else {
                this.setState({ showLoading: false, remediationList: [], filteredRemediationList: [] })
            }
        })
    }

    onClickAsset = (asset_name) => {
        this.props.setAiopsPropsDetails('remediationStatus', {})
        if(this.state.selectedAsset !== asset_name) {
            this.setState({ selectedViewRecord: {}, viewSelectedParameters: [], remediationStatus: {}, validationError: false, remediationDetails: {} })
            let selectedAssetDetails = this.state.remediationList.filter(arr => arr.asset_name === asset_name)
            let selectedRecord = []
            let selectedRecordId = ''
            let filteredRemediationList = []
            let selectedRecordStatus = selectedAssetDetails[0].status ? selectedAssetDetails[0].status : ' '
            let selectedAsset = selectedAssetDetails[0].asset_name
            let selectedAssetId = selectedAssetDetails[0].asset_id ? selectedAssetDetails[0].asset_id : ''
            let selectedServiceName = selectedAssetDetails.service_name ? selectedAssetDetails.service_name : ''
            let selectedResourceType = selectedAssetDetails.resource_type ? selectedAssetDetails.resource_type : ''
            let selectedAccountId = selectedAssetDetails[0].account_id
            let selectedRegion = selectedAssetDetails[0].region
            
            if(selectedAssetDetails.length && (!selectedAssetDetails[0].status || selectedAssetDetails[0].status === 'Open')) {
                filteredRemediationList = _.orderBy(selectedAssetDetails[0].remediations, ['recommended'])
                if(selectedAssetDetails[0].remediations && selectedAssetDetails[0].remediations.length) {
                    selectedRecord = filteredRemediationList[0]
                    selectedRecordId = filteredRemediationList[0].processor_rule_id

                    let obj = {}
                    obj.asset_name = selectedAsset
                    obj.asset_id = selectedAssetId
                    obj.service_name = selectedServiceName
                    obj.resource_type = selectedResourceType
                    obj.remediation_id = selectedRecordId
                    obj.account_id = selectedAccountId
                    obj.region = selectedRegion
                    obj.selectedRecord = selectedRecord
                    obj.sideMenu = 'Overview'
                    this.props.setAiopsPropsDetails('selected_remediation', obj)

                    this.setState({ filteredRemediationList, selectedAsset, selectedAssetId, selectedServiceName, selectedResourceType, selectedAccountId, selectedRegion, selectedRecord, selectedRecordId, selectedRecordStatus, remediationStatus: [] },
                        () => {
                            this.getRemediationDetails()
                        }
                    )
                } else {
                    this.setState({ noRemediationForselectedAsset: true, showLoading: false })
                }
            } else {
                selectedRecordId = selectedAssetDetails[0].remediation_id 

                let obj = {}
                obj.asset_name = selectedAsset
                obj.asset_id = selectedAssetId
                obj.service_name = selectedServiceName
                obj.resource_type = selectedResourceType
                obj.remediation_id = selectedRecordId
                obj.account_id = selectedAccountId
                obj.region = selectedRegion
                obj.selectedRecord = selectedRecord
                obj.sideMenu = 'Execution'
                this.props.setAiopsPropsDetails('selected_remediation', obj)

                this.setState({ filteredRemediationList, selectedAsset, selectedAssetId, selectedServiceName, selectedResourceType, selectedAccountId, selectedRegion, selectedRecord, selectedRecordId, selectedRecordStatus },
                    () => {
                        this.getRemediationStatus(selectedRecordId)
                    }
                )
            }
        }
    }

    getRemediationStatus = (processorId) => {
        let remStatus = {}
        remStatus.provider = Array.isArray(this.props.caseDetails.provider) ? this.props.caseDetails.provider[0].toLowerCase() : this.props.caseDetails.provider
        remStatus.account_id =  this.state.selectedAccountId
        remStatus.region = this.state.selectedRegion
        remStatus.processor_id = processorId

        this.props.getRemediationStatus(remStatus, (promise, result) => {
            if(promise) {
                let selectedRecordId = result.overall_status && result.overall_status.processor_rule_id ? result.overall_status.processor_rule_id : ''
                let selectedViewRecord = result.overall_status && result.overall_status.request && result.overall_status.request.parameters ? result.overall_status.request.parameters : {}
                this.setState({ remediationStatus: result, executionStatus: result.overall_status.status, selectedRecordId, selectedViewRecord, validationError: false },
                    () => this.getRemediationDetails()
                )
            } else {
                this.setState({ remediationStatus: [], validationError: false })
            }
        })
    }

    getRemediationDetails = () => {
        this.setState({ showDetailLoading: true, remediationDetails: {}, remediationProcessed: false, remediationStared: false, acknowledgment: false })
        let remDetails = {}
        remDetails.processor_rule_id =  this.state.selectedRecordId
        
        this.props.getRemediationDetails(remDetails, (promise, result) => {
            if(promise) {
                this.setState({ remediationDetails: result.details }, 
                    () => {
                        if(this.state.selectedViewRecord && Object.entries(this.state.selectedViewRecord).length) {
                            this.formViewParameters()
                        } else {
                            this.setState({ showLoading: false, showDetailLoading: false })
                        }
                    }
                )
            } else {
                this.setState({ showDetailLoading: false, remediationDetails: [] })
            }
        })

        let artifactDetails = {}
        artifactDetails.remediation_id =  this.state.selectedRecordId

        this.props.getArtifactDetails(artifactDetails, (promise, result) => {})
    }

    formViewParameters = () => {
        let selectedViewRecord = JSON.parse(this.state.selectedViewRecord)
        // let parameters = this.state.remediationDetails && this.state.remediationDetails.parameters

        let array = []
        this.state.remediationDetails && this.state.remediationDetails.parameters && this.state.remediationDetails.parameters.forEach((item, index) => {
            let dataRow = {}
            dataRow.label = item.label
            dataRow.value = selectedViewRecord[item.name]
            // Object.entries(selectedViewRecord).map(([key, value]) => {
            //     alert(key)
            //     alert(value)
            //     if(key === item.name) {
            //         dataRow.value = value
            //     }
            // })
            array.push(dataRow)
        })

        this.setState({ viewSelectedParameters: array, showLoading: false, showDetailLoading: false })
    }

    handleMultiSelectChange = (name, arrayValue) => {
        // let value = arrayValue.map(item => item.value)
        let label = arrayValue.map(item => item)
        let currentState = arrayValue

        let selectedOptions = arrayValue
        let prevState = this.state[name] ? this.state[name] : []

        // let value = arrayValue.map(item => item.value)
        // let label = arrayValue.map(item => item)

        prevState = prevState.filter(obj => obj.value === 'All')
        currentState = arrayValue.filter(obj => obj.value === 'All')
        if(prevState.length && currentState.length) {
            arrayValue = arrayValue.filter(obj => obj.value !== 'All')
            selectedOptions = []
            arrayValue.forEach(item => {
                instanceType.forEach(ins => {
                    if(ins === item) {
                        label = {}
                        label.value = ins
                        label.label = ins
                        selectedOptions.push(label)
                    }
                })
            })
        } else if(prevState.length && !currentState.length) {
            selectedOptions = []
        } else if(!prevState.length && !currentState.length) {
            selectedOptions = arrayValue
        } else if(!prevState.length && currentState.length) {
            selectedOptions = []
            instanceType.forEach(ins => {
                label = {}
                label.value = ins
                label.label = ins
                selectedOptions.push(label)
            })
        }

        this.setState({ [name]: selectedOptions })
    }

    getMultiSelectedCount = (type, array) => {
        return array.length && array.filter(obj => obj.value === 'All').length ? 'All' :  array.length ? array.length +' Selected' : 'All'
    }

    handleClickGroup = (val) => {
        this.setState({ selectedTableDrop: this.state.selectedTableDrop === val ? '' : val })
    }

    getCmdResult = (event) => {
        if(event.charCode === 13 || event.keyCode === 13) {
            let cmdResult = [
                {
                    "taskStartTime": "2021-02-15 12:58:43",
                    "processorId": "07e5faf3-5c84-4f4e-8301-45bede259b3a",
                    "eventName": "StopEC2Instance",
                    "type": "loop",
                    "taskStatus": "completed",
                    "taskDuration": "0:01:07",
                    "index": null,
                    "processor_rule_id": null,
                    "output": "{\"status\": \"Success\", \"data\": {\"WAIT60\": [{}], \"DescribeInstanceState\": [{\"id\": \"i-0dfbaa84a60153f25\", \"message\": \"Current State and Desired state is stopped\", \"status\": \"stopped\"}]}}",
                    "sequence": 4,
                    "taskMessage": "{}",
                    "taskName": "loop-sequence",
                    "taskId": "InstanceStopStateDecider",
                    "taskEndTime": "2021-02-15 12:59:50"
                },
                {
                    "taskStartTime": "2021-02-15 12:58:47",
                    "processorId": "07e5faf3-5c84-4f4e-8301-45bede259b3a",
                    "eventName": "StopEC2Instance",
                    "type": "task",
                    "taskStatus": "completed",
                    "taskDuration": "0:01:01",
                    "index": 0,
                    "processor_rule_id": null,
                    "output": "[{\"id\": \"i-0dfbaa84a60153f25\", \"message\": \"Current State and Desired state is stopped\", \"status\": \"stopped\"}]",
                    "sequence": 4,
                    "taskMessage": "{}",
                    "taskName": "DescribeInstanceState",
                    "taskId": "DescribeInstanceState",
                    "taskEndTime": "2021-02-15 12:59:48"
                }
            ]

            this.setState({ cmdResult })
        }
    }

    scrollBottom = () => {
        this.scrollRef.current.scrollIntoView({ behavior: 'smooth' })
        // window.scrollTo(0, this.scrollRef);
    }
	
	slideLeft() {
		let move = 0
		move = parseInt(this.state.totalItemShowing)
		this.setState({ totalItemShowing: this.state.totalItemShowing - 1 })
		
        const totalAssets = this.state.remediationList.length
		let reqTabWidth = 0
		for (let i = 0; i < totalAssets; i++) {
			reqTabWidth += this.sliderWrap.current.childNodes[i].getBoundingClientRect().width
		}
		const averageSliderItemWidth = reqTabWidth/totalAssets
		const sliderWrapWidth = this.sliderWrap.current.getBoundingClientRect().width
		const totalItemShowing = sliderWrapWidth/averageSliderItemWidth;
		if(move > totalItemShowing) {
			if (this.sliderWrap.current.childNodes[move-1] && this.sliderWrap.current.childNodes[move-1].getBoundingClientRect().width) {
				this.sliderWrap.current.scrollLeft -= (this.sliderWrap.current.childNodes[move-1].getBoundingClientRect().width + 25)	
				this.setState({ showingRightIcon: true, showingOff: parseInt(this.state.showingOff - 1), showingOffStart: this.state.showingOffStart < 1 ? 1 : parseInt(this.state.showingOffStart-1)})
			} else {
				this.setState({ showingLeftIcon: false, totalItemShowing: totalItemShowing })
			}
		} else {
			this.setState({ showingLeftIcon: false, totalItemShowing: totalItemShowing })
		}
	}

	slideItemRight(item) {
        const totalAssets = this.state.remediationList.length
		let reqTabWidth = 0
		for (let i = 0; i < totalAssets; i++) {
			reqTabWidth += this.sliderWrap.current.childNodes[i].getBoundingClientRect().width
        }
		const averageSliderItemWidth = reqTabWidth/totalAssets
		const sliderWrapWidth = this.sliderWrap.current.getBoundingClientRect().width
        let totalItemShowing = sliderWrapWidth/averageSliderItemWidth;

        if(totalItemShowing > Object.entries(this.state.remediationList).length) {
            totalItemShowing = Object.entries(this.state.remediationList).length
        }

		let move = 0
		if(this.state.totalItemShowing === 0) {
			this.setState({ totalItemShowing: parseInt(totalItemShowing) })
			move = parseInt(totalItemShowing)
		} else {
			move = parseInt(this.state.totalItemShowing + 1)
			this.setState({ totalItemShowing: this.state.totalItemShowing + 1 })
        }
        
		if (this.sliderWrap.current.childNodes[move-1] && this.sliderWrap.current.childNodes[move-1].getBoundingClientRect().width) {

            if(item === '') {
                this.sliderWrap.current.scrollLeft += (this.sliderWrap.current.childNodes[move-1].getBoundingClientRect().width + 25)
            }
            
			if(!this.state.showingOff && this.state.showingOff < totalItemShowing) {
				this.setState({ showingOff: parseInt(totalItemShowing), showingLeftIcon: true })
			} else{
				this.setState({ showingOffStart: (this.state.showingOffStart + 1), showingOff: parseInt(this.state.showingOff + 1), showingLeftIcon: true })
			}
		} else {
			this.setState({ showingRightIcon: false, showingOff: this.props.assetsList.length, totalItemShowing: this.props.assetsList.length })
		}
    }
    
    validationProcess = (index, value, mandatory, validation, name, label) => {
        let string = value ? value : ''
        let validationError = false
        let validationErrorMessage = ''
        if(validation.data_type) {
            if(validation.data_type === 'number' && string.length > 0) {
                string = onlyNumeric(string)

                if(validation.min && parseInt(string) < validation.min) {
                    validationError = true
                } 

                if(validation.max && parseInt(string) > validation.max) {
                    validationError = true
                }

                if(validation.min && validation.max && validationError === true) {
                    validationErrorMessage = label +' should be greater than '+ validation.min +' and lesser than '+ validation.max
                } else if(validation.min && !validation.max && validationError === true) {
                    validationErrorMessage = label +' should be greater than '+ validation.min
                } else if(!validation.min && validation.max && validationError === true) {
                    validationErrorMessage = label +' should be lesser than '+ validation.max
                }
            } 
        }
        this.setState({ [name+'_'+index]: string, validationError, [name+'_'+index+'_validationErrorMessage']: validationErrorMessage })
    }

    startRemediationProcess = () => {
        let validationError = false
        let obj = {}
        Object.entries(this.props.aiopsRemediationParams).forEach(([key, value]) => {
            

            if(this.state.selectedRecord.processor_rule_id === 'REM_011') {
                let AllocatedStorageError = ''
                if(key === 'AllocatedStorage') {
                    obj['AllocatedStorage'] = value
                    if(value.length === 0) {
                        validationError = true
                        AllocatedStorageError = 'Allocated Storage is required'
                    } else {
                        let min = 10
                        let max = 30
                        if(value < min) {
                            validationError = true
                            AllocatedStorageError = 'Allocated Storage should be minimum of '+ min +' GiB'
                        }
        
                        if(value > max) {
                            validationError = true
                            AllocatedStorageError = 'Allocated Storage should be lesser then '+ max +' GiB'
                        }
                    }
                    let validation = this.props.aiopsRemediationParams
                    validation.AllocatedStorageError = AllocatedStorageError
                    this.props.setAiopsPropsDetails('remediationParams', validation)
                }
                
                let ApplyImmediatelyError = ''
                if(key === 'ApplyImmediately') {
                    obj['ApplyImmediately'] = value
                    if(!value || value === '') {
                        validationError = true
                        ApplyImmediatelyError = 'Apply Immediately required'
                    }
                    let validation = this.props.aiopsRemediationParams
                    validation.ApplyImmediatelyError = ApplyImmediatelyError
                    this.props.setAiopsPropsDetails('remediationParams', validation)
                }
            } else if(this.state.selectedRecord.processor_rule_id === 'REM_012') {
                let InstanceTypeError = ''
                if(key === 'InstanceType') {
                    obj['InstanceType'] = value
                    if(value === '') {
                        validationError = true
                        InstanceTypeError = 'Instance Type is required'
                    }
                    let validation = this.props.aiopsRemediationParams
                    validation.InstanceTypeError = InstanceTypeError
                    this.props.setAiopsPropsDetails('remediationParams', validation)
                }
                
                let ApplyImmediatelyError = ''
                if(key === 'ApplyImmediately') {
                    obj['ApplyImmediately'] = value
                    if(!value || value === '') {
                        validationError = true
                        ApplyImmediatelyError = 'Apply Immediately required'
                    }
                    let validation = this.props.aiopsRemediationParams
                    validation.ApplyImmediatelyError = ApplyImmediatelyError
                    this.props.setAiopsPropsDetails('remediationParams', validation)
                }
            } else if(this.state.selectedRecord.processor_rule_id === 'REM_013') {
                let StorageTypeError = ''
                if(key === 'StorageType') {
                    obj['StorageType'] = value
                    if(value === '') {
                        validationError = true
                        StorageTypeError = 'Storage Type is required'
                    }
                    let validation = this.props.aiopsRemediationParams
                    validation.StorageTypeError = StorageTypeError
                    this.props.setAiopsPropsDetails('remediationParams', validation)
                }
                
                let ApplyImmediatelyError = ''
                if(key === 'ApplyImmediately') {
                    obj['ApplyImmediately'] = value
                    if(!value || value === '') {
                        validationError = true
                        ApplyImmediatelyError = 'Apply Immediately required'
                    }
                    let validation = this.props.aiopsRemediationParams
                    validation.ApplyImmediatelyError = ApplyImmediatelyError
                    this.props.setAiopsPropsDetails('remediationParams', validation)
                }
            } else if(this.state.selectedRecord.processor_rule_id === 'REM_009') {
                if(key === 'objects') {
                    // let validationObjectErrorMessage = 'validationObjectErrorMessage'
                    let array = []
                    value.forEach((item, i) => {
                        let obj = {}
                        if(item.length < 1) {
                            validationError = true
                            obj.field = 'validationObjectsError_'+i
                            obj.error = 'List of files/directories to remove is required'
                            array.push(obj)
                        } else {
                            obj.field = 'validationObjectsError_'+i
                            obj.error = ''
                            array.push(obj)
                        }
                    })

                    if(value.length < 2) {
                        validationError = true
                        let validation = this.props.aiopsRemediationParams
                        validation.overallValidationError = 'Minimum 1 is requried';
                        this.props.setAiopsPropsDetails('remediationParams', validation)
                    } else if(array.length) {
                        let validation = this.props.aiopsRemediationParams
                        validation.validationObjectErrorMessage = array
                        validation.overallValidationError = ''
                        this.props.setAiopsPropsDetails('remediationParams', validation)
                    }

                    obj['objects'] = value
                }
            } else if(vmTypeRemediations.includes(this.state.selectedRecord.processor_rule_id)) {
                let InstanceTypeError = ''
                if(key === 'InstanceType') {
                    obj['InstanceType'] = value
                    if(value === '') {
                        validationError = true
                        InstanceTypeError = 'Instance Type is required'
                    }
                    let validation = this.props.aiopsRemediationParams
                    validation.InstanceTypeError = InstanceTypeError
                    this.props.setAiopsPropsDetails('remediationParams', validation)
                }
                
                let ApplyImmediatelyError = ''
                if(key === 'ApplyImmediately') {
                    obj['ApplyImmediately'] = value
                    if(!value || value === '') {
                        validationError = true
                        ApplyImmediatelyError = 'Apply Immediately required'
                    }
                    let validation = this.props.aiopsRemediationParams
                    validation.ApplyImmediatelyError = ApplyImmediatelyError
                    this.props.setAiopsPropsDetails('remediationParams', validation)
                }
            } else if(this.state.selectedRecord.processor_rule_id === 'REM_015') {
                let MaxAllocatedStorageError = ''
                if(key === 'MaxAllocatedStorage') {
                    obj['MaxAllocatedStorage'] = value
                    if(value === '') {
                        validationError = true
                        MaxAllocatedStorageError = 'Max Allocated Storage is required'
                    } else {
                        let min = 0
                        let max = 10000
                        if(value < min) {
                            validationError = true
                            MaxAllocatedStorageError = 'Max Allocated Storage should be minimum of '+ min +' GiB'
                        }
        
                        if(value > max) {
                            validationError = true
                            MaxAllocatedStorageError = 'Max Allocated Storage should be maximum of '+ max +' GiB'
                        }
                    }
                    let validation = this.props.aiopsRemediationParams
                    validation.MaxAllocatedStorageError = MaxAllocatedStorageError
                    this.props.setAiopsPropsDetails('remediationParams', validation)
                }
                
                let ApplyImmediatelyError = ''
                if(key === 'ApplyImmediately') {
                    obj['ApplyImmediately'] = value
                    if(!value || value === '') {
                        validationError = true
                        ApplyImmediatelyError = 'Apply Immediately required'
                    }
                    let validation = this.props.aiopsRemediationParams
                    validation.ApplyImmediatelyError = ApplyImmediatelyError
                    this.props.setAiopsPropsDetails('remediationParams', validation)
                }
            } else if(this.state.selectedRecord.processor_rule_id === 'REM_021') {
                let ApplyImmediatelyError = ''
                if(key === 'ApplyImmediately') {
                    obj['ApplyImmediately'] = value
                    if(!value || value === '') {
                        validationError = true
                        ApplyImmediatelyError = 'Apply Immediately required'
                    }
                    let validation = this.props.aiopsRemediationParams
                    validation.ApplyImmediatelyError = ApplyImmediatelyError
                    this.props.setAiopsPropsDetails('remediationParams', validation)
                }
            } else if(this.state.selectedRecord.processor_rule_id === 'REM_0222') {
                let replicaIdentifierError = ''
                if(key === 'replica_identifier') {
                    obj['replica_identifier'] = value
                    if(value === '') {
                        validationError = true
                        replicaIdentifierError = 'Replica DB Instance is required'
                    }
                    let validation = this.props.aiopsRemediationParams
                    validation.replicaIdentifierError = replicaIdentifierError
                    this.props.setAiopsPropsDetails('remediationParams', validation)
                }
                
                let targetRegionError = ''
                if(key === 'target_region') {
                    obj['target_region'] = value
                    if(value === '') {
                        validationError = true
                        targetRegionError = 'Region to Replicate required'
                    }
                    let validation = this.props.aiopsRemediationParams
                    validation.targetRegionError = targetRegionError
                    this.props.setAiopsPropsDetails('remediationParams', validation)
                }
            } else if(this.state.selectedRecord.processor_rule_id === 'REM_018') {
                let memoryError = ''
                if(key === 'MemorySize') {
                    obj['MemorySize'] = value
                    if(value === '') {
                        validationError = true
                        memoryError = 'Memory is required'
                    } else {
                        let min = 12
                        let max = 10240
                        if(value < min) {
                            validationError = true
                            memoryError = 'Timeout should be minimum of '+ min +' MB'
                        }
        
                        if(value > max) {
                            validationError = true
                            memoryError = 'Timeout should be maximim of '+ max +' MB'
                        }
                    }
                    let validation = this.props.aiopsRemediationParams
                    validation.memoryError = memoryError
                    this.props.setAiopsPropsDetails('remediationParams', validation)
                }
            } else if(this.state.selectedRecord.processor_rule_id === 'REM_019') {
                let timeoutError = ''
                if(key === 'Timeout') {
                    obj['Timeout'] = value
                    if(value === '') {
                        validationError = true
                        timeoutError = 'Timeout is required'
                    } else {
                        let min = 1
                        let max = 900
                        if(value < min) {
                            validationError = true
                            timeoutError = 'Timeout should be minimum of '+ min +' seconds'
                        }
        
                        if(value > max) {
                            validationError = true
                            timeoutError = 'Timeout should be maximim of '+ max +' seconds'
                        }
                    }
                    let validation = this.props.aiopsRemediationParams
                    validation.timeoutError = timeoutError
                    this.props.setAiopsPropsDetails('remediationParams', validation)
                }
            } else if(this.state.selectedRecord.processor_rule_id === 'REM_022') {
                let rdsInstanceError = ''
                if(key === 'target_region') {
                    obj['target_region'] = value
                    if(value === '') {
                        validationError = true
                        rdsInstanceError = 'Rds Instance is required'
                    }
                    let validation = this.props.aiopsRemediationParams
                    validation.rdsInstanceError = rdsInstanceError
                    this.props.setAiopsPropsDetails('remediationParams', validation)
                }
            }
        })
        this.setState({ validationError })

        this.state.test && this.state.selectedRecord.processor_rule_id && this.state.selectedRecord.parameters && Object.entries(this.state.selectedRecord.parameters).length &&
            this.state.selectedRecord.parameters.forEach((item, index) => {
            if(item.component) {
                let validationErrorMessage = ''
                if(item.component === 'textbox') {
                    let number = this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index] ? this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index] : ''
                    if(item.mandatory) {
                        if(number.length === 0) {
                            validationError = true
                            validationErrorMessage = item.label +' is required'
                        }
                    }
                    
                    if(!validationError && item.validation.data_type && item.validation.data_type === 'number') {
                        number = onlyNumeric(number)

                        number = parseInt(number)
        
                        if(item.validation.min && number < item.validation.min) {
                            validationError = true
                        }
        
                        if(item.validation.max && number > item.validation.max) {
                            validationError = true
                        }

                        
        
                        if(item.validation.min && item.validation.max && validationError === true) {
                            validationErrorMessage = item.label +' should be greater than '+ item.validationmin +' and lesser than '+ item.validationmax
                        } else if(item.validation.min && !item.validation.max && validationError === true) {
                            validationErrorMessage = item.label +' should be greater than '+ item.validation.min
                        } else if(!item.validation.min && item.validation.max && validationError === true) {
                            validationErrorMessage = item.label +' should be lesser than '+ item.validation.max
                        }
                    }

                    obj[item.name] = number
                    this.setState({ [item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index]: number, validationError, [item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index+'_validationErrorMessage']: validationErrorMessage })
                }

                if(item.component === 'checkbox') {
                    obj[item.name] = this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index]
                }

                validationErrorMessage = ''
                // validationError = false
                if(item.component === 'multiple_textbox' && (item.validation.data_type && item.validation.data_type === 'string_list')) {
                    let length = 1
                    if(this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index+'_length']) {
                        length = this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index+'_length']
                    }
                    let input = this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index] ? this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index] : []
                    for(let i=0;i<length;i++) {
                        let string = this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index+'_'+i] ? this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index+'_'+i] : []
                        input.push(string)
                    }

                    if(item.mandatory) {
                        if(item.validation.min_array_length) {
                            if(input.length < item.validation.min_array_length) {
                                validationError = true
                                validationErrorMessage = 'Please add minimium of '+item.validation.min_array_length+' '+item.label
                            }
                        } else if(!input.length) {
                            validationError = true
                            validationErrorMessage = 'Please enter '+item.label
                        }
                    }
                    obj[item.name] = input
                    this.setState({ [item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index]: input, validationError, [item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index+'_validationErrorMessage']: validationErrorMessage })

                }

                validationErrorMessage = ''
                // validationError = false
                if(item.component === 'date') {
                    let date = ""
                    if(this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index]) {
                        date = this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index]
                    }

                    if(item.mandatory) {
                        if(date === "") {
                            validationError = true
                            validationErrorMessage = item.label +' is required'
                        }
                    }

                    this.setState({ [item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index]: date, validationError, [item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index+'_validationErrorMessage']: validationErrorMessage })
                }

                validationErrorMessage = ''
                if(item.component === 'date_range') {
                    let startDate = ""
                    if(this.state[item.name+'_start_date_'+index]) {
                        startDate = this.state[item.name+'_start_date_'+index]
                    }

                    let endDate = ""
                    if(this.state[item.name+'_end_date_'+index]) {
                        endDate = this.state[item.name+'_end_date_'+index]
                    }

                    if(startDate !== "" && endDate !== "") {
                        if(new Date(startDate).getTime() > new Date(endDate).getTime() ) {
                            validationError = true
                            validationErrorMessage = 'End date should be greater than start date'
                        }
                    }

                    if(item.mandatory) {
                        if(startDate === "" && endDate === "") {
                            validationError = true
                            validationErrorMessage = 'start and End date is required'
                        } else if(startDate === "") {
                            validationError = true
                            validationErrorMessage = 'Start date is required'
                        } else if(endDate === "") {
                            validationError = true
                            validationErrorMessage = 'End date is required'
                        }
                    } else {
                        if(startDate !== "" || endDate !== "") {
                            if(startDate === "") {
                                validationError = true
                                validationErrorMessage = 'Start date is required'
                            } else if(endDate === "") {
                                validationError = true
                                validationErrorMessage = 'End date is required'
                            }
                        }
                    }
                    

                    this.setState({ [item.name+'_start_date_'+index]: startDate, [item.name+'_end_date_'+index]: endDate, validationError, [item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index+'_validationErrorMessage']: validationErrorMessage })
                }

                validationErrorMessage = ''
                // validationError = false
                if(item.component === 'select') {
                    let data = ""
                    if(this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index]) {
                        data = this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index]
                    }

                    if(item.mandatory) {
                        if(data === "") {
                            validationError = true
                            validationErrorMessage = item.label +' is required'
                        }
                    }

                    obj[item.name] = data

                    this.setState({ [item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index]: data, validationError, [item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index+'_validationErrorMessage']: validationErrorMessage }
                    )
                }

                validationErrorMessage = ''
                // validationError = false
                if(item.component === 'multi_select') {
                    let data = []
                    if(this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index]) {
                        data = this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index]
                    }
                    if(item.mandatory) {
                        if(!data.length) {
                            validationError = true
                            validationErrorMessage = item.label +' is required'
                        }
                    }

                    this.setState({ [item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index]: data, validationError, [item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index+'_validationErrorMessage']: validationErrorMessage })
                }                
            }
        })

        if(!validationError) {
            let remStart = {}
            remStart.provider = Array.isArray(this.props.caseDetails.provider) ? this.props.caseDetails.provider[0].toLowerCase() : this.props.caseDetails.provider
            remStart.accountId = this.state.selectedAccountId
            remStart.region = this.state.selectedRegion
            remStart.processor_rule_id =  this.state.selectedRecordId
            remStart.asset_id = this.state.selectedAsset
            remStart.source = "aiops"
            remStart.source_id = this.state.caseId
            remStart.parameters = obj

            this.props.setAiopsPropsDetails('remediation_status_change', '')
            if(remStart.accountId !== '' && remStart.accountId !== '') {
                this.props.startRemediation(remStart, (promise, result) => {
                    if(promise) {
                        this.setState({ startRemediation: result, remediationStared: false, remediationProcessed: true },
                            () => {
                                this.callRemediationStatus(result.processor_id)
                            }
                        )
                    } else {
                        this.setState({ startRemediation: [], remediationStared: false, remediationProcessed: false })
                    }
                })
            }

        } else {
            this.setState({ remediationStared: false })
        }
    }

    callRemediationStatus = (processor_id) => {
        setTimeout(() => {
            let obj = {}
            obj.asset_name = this.state.selectedAsset
            obj.asset_id = this.state.selectedAssetId
            obj.service_name = this.state.selectedServiceName
            obj.resource_type = this.state.selectedResourceType
            obj.remediation_id = processor_id
            obj.account_id = this.state.selectedAccountId
            obj.region = this.state.selectedRegion
            obj.selectedRecord = this.state.selectedRecord
            obj.sideMenu = 'Execution'
            obj.startExection = 'true'
            this.props.setAiopsPropsDetails('selected_remediation', obj)
        }, 5000)
	}

    addTextbox = (name, label, index) => {
        let rowList = [true]
        if(this.state[name+'_'+index+'_length']) {
            rowList = [...this.state[name+'_'+index+'_length']];
            rowList.push(true);
        } else {
            rowList = [...this.state.sampleState];
            rowList.push(true);
        }
        
        this.setState({ [name+'_'+index+'_length']: rowList })
        // <div className="d-flex">
        // <input 
        //     type="text" 
        //     className="form-control mr-1" 
        //     placeholder={`Enter ${label}`}
        //     value={this.state[name+'_'+index] ? this.state[name+'_'+index] : ''}
        // />
    }
  
    handleDateTimeChange(date, name) {
        // let startDate = momentDateGivenFormat(date, 'YYYY-MM-DD hh:mm A')
        // let endDate = this.state.endDate
        // if(new Date(startDate).getTime() > new Date(endDate).getTime() ) {
        // endDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD hh:mm A')
        // }
        // this.setState({ startDate, endDate })
    }

    handleDateChange = (date, name, index) => {
        date = momentDateGivenFormat(date, 'YYYY-MM-DD hh:mm A')
        this.setState({ [name+'_'+index]: date })
    }

    handelDateRangeChange = (date, name, index, type) => {
        let validationError = this.state.validationError
        let validationErrorMessage = ''
        if(type === 'start_date') {
            let startDate = momentDateGivenFormat(date, 'YYYY-MM-DD hh:mm A')
            let endDate = this.state[name+'_end_date_'+index] ? this.state[name+'_end_date_'+index] : ''
            if(endDate !== '') {
                if(new Date(startDate).getTime() > new Date(endDate).getTime() ) {
                    // endDate = startDate
                    validationError = true
                    validationErrorMessage = 'End date should be greater than start date'
                }

            }
            this.setState({ [name+'_start_date_'+index]: startDate, validationError, [name+'_'+index+'_validationErrorMessage']: validationErrorMessage },
                //() => this.checkState()
            )
        } else {
            let endDate = momentDateGivenFormat(date, 'YYYY-MM-DD hh:mm A')
            let startDate = this.state[name+'_start_date_'+index] ? this.state[name+'_start_date_'+index] : ''
            if(startDate !== '') {
                if(new Date(startDate).getTime() > new Date(endDate).getTime() ) {
                    // startDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD hh:mm A')
                    validationError = true
                    validationErrorMessage = 'Start date should be lesset than end date'
                }
            }
            this.setState({ [name+'_end_date_'+index]: endDate, validationError, [name+'_'+index+'_validationErrorMessage']: validationErrorMessage })
        }
    }

    validateDateFunction = (validation) => {
        // alert(1)
        if(validation) {
            if(validation.future_date) {
                return disablePastDt
            } else if(validation.past_date) {
                return disableFutureDt
            } else {
                return true
            }
        } else {
            return true
        }
    }

    checkParamaters = (selectedRecord) => {
        if(selectedRecord.parameters && Object.entries(selectedRecord.parameters).length) {
            this.state.selectedRecord.parameters.forEach((item, index) => {
                if(item.component === 'select' && item.apiToCall && Object.entries(item.apiToCall).length) {
                    // this.funcitonApiCall(item, index)
                    let params = {}
                    let keyRequest =  item.apiToCall.body && item.apiToCall.body.keys
                    keyRequest && keyRequest.forEach(item => {
                        params[item.key] = item.value
                    })
        
                    let valueRequest =  item.apiToCall.body && item.apiToCall.body.values
                    valueRequest && valueRequest.forEach(item => {
                        params[item.key] = item.value
                    })

                    let response_field = item.apiToCall.response_field ? item.apiToCall.response_field : ''
                    let name = item.apiToCall.name
        
                    if(!this.state[item.name+'_apiCall_'+index]) {
                        this.props.apiToDevCall(name, params, (success, result) => {
                            if (success) {
                                let dropdowntValues = []
                                result.length && result[0].results && result[0].results.forEach(res => {
                                    // let str = response_field
                                    let split = response_field.split('.')
                                    // split = this.strToObj(res, split)
                                    dropdowntValues.push(this.strToObj(res, split))
                                })
                                this.setState({ [item.name+'_apiCall_'+index]: dropdowntValues })
                            }
                        })
                    }
                }
            })
        }                                                                     
    }

    strToObj = (res, split) => {
        for (var i = 0; i < split.length; i++)
            res = res[split[i]];
        return res;
        // let obj = {}
        // var parts = string.split('.');
        // var newObj = obj[parts[0]];
        // if (parts[1]) {
        //   parts.splice(0, 1);
        //   var newString = parts.join('.');
        //   return this.strToObj(newObj, newString);
        // }
        // return newObj;
    }

    updateFormFieldValue = (fieldName, fieldValue) => {
        this.setState({ [fieldName]: fieldValue });
    };

    updateAcknowledgDetails = (fieldName, fieldValue) => {
        this.setState({ [fieldName]: fieldValue, showApplyBtn: fieldValue })
    }

    vmTypeSetState = (selectedOption, checkOptions) => {
        this.state.selectedRecord && this.state.selectedRecord.parameters.forEach((item, index) => {
            if(item.component === 'select') {
                this.setState({ [item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index]: selectedOption})
            } else if(item.component === 'checkbox') {
                this.setState({ [item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index]: checkOptions })
            }
        })
    }

    storageSetState = (selectedOption, checkOptions) => {
        this.state.selectedRecord && this.state.selectedRecord.parameters.forEach((item, index) => {
            if(item.component === 'select') {
                this.setState({ [item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index]: selectedOption})
            } else if(item.component === 'checkbox') {
                this.setState({ [item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index]: checkOptions })
            }
        })
    }

    modifySetState = (selectedOption, checkOptions) => {
        this.state.selectedRecord && this.state.selectedRecord.parameters.forEach((item, index) => {
            if(item.component === 'textbox') {
                this.setState({ [item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index]: selectedOption})
            } else if(item.component === 'checkbox') {
                this.setState({ [item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index]: checkOptions })
            }
        })
    }

    changeRemediation = (item) => {
        this.setState({ showDetailLoading: true, selectedRecordId: item.processor_rule_id, selectedRecord: item, showApplyBtn: false },
            () => {
                this.getRemediationDetails()
                let obj = {}
                obj.asset_name = this.state.selectedAsset
                obj.asset_id = this.state.selectedAssetId
                obj.service_name = this.state.selectedServiceName
                obj.resource_type = this.state.selectedResourceType
                obj.remediation_id = this.state.selectedRecordId
                obj.account_id = this.state.selectedAccountId
                obj.region = this.state.selectedRegion
                obj.selectedRecord = this.state.selectedRecord
                obj.sideMenu = 'Overview'
                this.props.setAiopsPropsDetails('selected_remediation', obj)

                if(!sepratePages.includes(this.state.selectedRecord.processor_rule_id)) {
                    this.checkParamaters(item)
                }
            }
        )
    }

    

	render() {
		return (
            <div className='col-md-12'>
                <div className='tab-content p-0' id='myTabContent'>                                        
                    <div className='tab-pane fade show active'>
                        <p className="mb-0 f16 text-white">Remediation</p>
                        <div className="d-flex justify-content-between">
                            <p className="mb-0 f12">Showing the remediation options</p>
                            <div className="d-flex">
                                <small className="mr-3">
                                    Showing 
                                    {this.props.assetsList && Object.entries(this.props.assetsList).length ?  
                                        ' '+(this.state.showingOffStart) +' - '+ (this.state.showingOff ? this.state.showingOff : Object.entries(this.state.remediationList).length) +' ('+Object.entries(this.state.remediationList).length+') '
                                    : ' 0'
                                    } 
                                </small>
                                <span className={`far fa-chevron-left mr-3 text-muted cursorPointer ${this.state.showingLeftIcon ? '' : 'displayNone'}`} onClick={() => this.slideLeft()}></span>
                                <span className={`far fa-chevron-right cursorPointer ${this.state.showingRightIcon ? '' : 'displayNone'}`}  onClick={() => this.slideItemRight('')}></span>
                            </div>
                        </div>
                        <div className="container-fluid mt-3">
                            <div className={`row ${!this.state.showLoading ? '' : 'disabled'}`}>
                                <div className="col-12 d-flex flex-nowrap overflow-hidden" ref={this.sliderWrap}>
                                    {this.state.remediationList && this.state.remediationList.length ? 
                                        this.state.remediationList.map(item => {
                                            return(
                                            <div onClick={() => this.setState({ showLoading: this.state.selectedAsset !== item.asset_name ? true : false, noRemediationForselectedAsset: false }, () => this.onClickAsset(item.asset_name))} 
                                                className={`alert text-${item.status ? ((item.status.toLowerCase() === 'completed' || item.status.toLowerCase() === 'success') ? 'success' : item.status.toLowerCase() === 'failed' ? 'danger' : item.status.toLowerCase() === 'inprogress' ? 'warning' : 'secondary text-white') : 'secondary text-white'}
                                                font-weight-bold mr-3 remediationListWidth cursorPointer bg-dark
                                                ${item.asset_name === this.state.selectedAsset && item.status ? ((item.status.toLowerCase() === 'completed' || item.status.toLowerCase() === 'success') ? 'success success-border' : item.status.toLowerCase() === 'failed' ? 'danger border-danger' : item.status.toLowerCase() === 'inprogress' ? 'warning warning-border' : 'border-gray5') : (item.asset_name === this.state.selectedAsset ? 'border-gray5' : '')}`}>
                                                <div className="d-flex">
                                                    <div>
                                                        <i className="mr-2 fa fa-check-circle"></i>
                                                    </div>
                                                    <div>
                                                        {item.asset_name}
                                                    </div>
                                                </div>
                                                {item.resource_type ?
                                                    <div className="d-flex">
                                                        <div className={`text-${item.resource_type && item.status ? ((item.status.toLowerCase() === 'completed' || item.status.toLowerCase() === 'success') ? 'success' : item.status.toLowerCase() === 'failed' ? 'danger' : item.status.toLowerCase() === 'inprogress' ? 'warning' : 'white') : 'tranparent'}`}>
                                                            <i className="mr-2 fa fa-circle f10"></i>
                                                        </div>
                                                        <div>
                                                            {item.resource_type}
                                                        </div>
                                                    </div>
                                                : null}
                                            </div>
                                            )
                                        })
                                    : null
                                    }
                                </div>
                            </div>
                            {this.state.remediationList && this.state.remediationList.length && !this.state.showLoading && !this.state.noRemediationForselectedAsset ?
                                <div className="row">
                                    <div className="col-md-5 vh-border-right">
                                        <p className="mb-0 f12 text-white">Remediations</p>
                                        <div className="bg-dark rounded p-2">
                                            {this.state.filteredRemediationList.length ? 
                                                this.state.filteredRemediationList.map(item => {
                                                    return (
                                                        <div className={`d-flex rounded p-2 mb-3 ${this.state.selectedRecordId === item.processor_rule_id ? 'bg-muted disabled' :''} ${this.state.remediationStared ? 'disabled' : ''} `}  
                                                        onClick={() => {
                                                            this.props.setAiopsPropsDetails('remediationParams', {})
                                                            this.changeRemediation(item)
                                                        }}>

                                                            {this.state.selectedRecordId === item.processor_rule_id ?
                                                                <div className="align-self-center mr-2">
                                                                    <i className="fad fa-check-circle text-success"></i>
                                                                </div>
                                                            : 
                                                                <div className="align-self-center mr-2">
                                                                    <i className="fad fa-circle text-light"></i>
                                                                </div>
                                                            }
                                                            <div>
                                                                <p className="mb-0 text-white">{item.processor_rule_id}</p>
                                                                <small className="text-white">{item.description}</small>
                                                            </div>
                                                                {item.recommended ?
                                                                    <span className="badge badge-primary align-self-center ml-auto text-white">
                                                                    Recommended
                                                                    </span>
                                                                : this.state.selectedRecordId === item.processor_rule_id ?
                                                                    <span className="badge badge-purple align-self-center ml-auto text-white">
                                                                    Selected
                                                                    </span>
                                                                : null
                                                                }
                                                        </div>
                                                    )
                                                })
                                            : 
                                                this.state.remediationDetails && Object.entries(this.state.remediationDetails).length ?
                                                    <div className={`d-flex rounded p-2 mb-3 bg-muted`}>
                                                        <div className="align-self-center mr-2">
                                                            <i className="fad fa-check-circle text-purple"></i>
                                                        </div>
                                                        <div>
                                                            <p className="mb-0 text-white">{this.state.remediationDetails.processor_rule_id}</p>
                                                            <small className="text-white">{this.state.remediationDetails.description}</small>
                                                        </div>
                                                        <span className="badge badge-purple align-self-center ml-auto text-white">
                                                            Selected
                                                        </span>
                                                    </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        {this.state.remediationDetails && Object.entries(this.state.remediationDetails).length ?
                                            <div className="rounded bg-dark p-3 mb-3">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <p className="mb-0 f16 text-white">Tvastr Remediation</p>
                                                        <p className="mb-0 f12">Why this is recommended <span className="f14" id="whyRecommended">?</span></p>
                                                    </div>
                                                    <UncontrolledPopover className="recommendedPopup" trigger="hover" placement="top" target='whyRecommended'>
                                                        <PopoverHeader>
                                                            <label className="mb-0 font-weight-bold"> Why is this recommended ?</label>
                                                        </PopoverHeader>
                                                        <PopoverBody>
                                                            <div className="col-md-12">
                                                                <small>Log in to your Windows instance using Remote Desktop. For more information, see Connect to your Windows instance.</small>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <small>2.Start the Disk Management utility. On the taskbar, open the context (right-click) menu for the Windows logo and choose Disk Management.</small>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <small>3.Bring the volume online. In the lower pane, open the context (right-click) menu for the left panel for the disk for the EBS volume. Choose Online.</small>
                                                            </div>
                                                        </PopoverBody>
                                                    </UncontrolledPopover>
                                                </div>
                                                
                                                <div className="row mt-2">
                                                    <div className="col-md-6">
                                                        <p className="mb-2">Title: <span className="mb-0 text-white">{this.state.remediationDetails.title}</span></p>
                                                        <p className="mb-2">ID: <span className="mb-0 text-white">{this.state.remediationDetails.processor_rule_id}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <p className="mb-2">Description: <span className="mb-0 text-white">{this.state.remediationDetails.description}</span></p>
                                                    </div>
                                                    {Object.entries(this.state.selectedRecord).length ?
                                                        <div className="col-md-12">
                                                            <p className="mb-0 f12">Configuration Required: <small className={`mb-1 badge ${this.state.selectedRecord.parameters && Object.entries(this.state.selectedRecord.parameters).length ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.state.selectedRecord.parameters && Object.entries(this.state.selectedRecord.parameters).length ? 'Yes' : 'No'}</small></p>
                                                        </div>
                                                    : 
                                                    null }
                                                </div>
                                                {this.state.remediationStatus && this.state.remediationStatus.overall_status && this.state.remediationStatus.overall_status.status ?
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <p class="mb-2">Status:
                                                            <small className={`ml-1 badge badge-outline-${(this.props.remediationStatus.overall_status.status.toLowerCase() === 'completed' || this.props.remediationStatus.overall_status.status.toLowerCase() === 'success') ? 'success' : this.props.remediationStatus.overall_status.status.toLowerCase() === 'failed' ?'danger' : 'warning' } text-white`}>
                                                                {capitalizeFirstLetter(this.props.remediationStatus.overall_status.status.toLowerCase())}
                                                            </small>
                                                            </p>
                                                        </div>
                                                    </div>                                                
                                                : null}
                                            </div>
                                        : null }

                                        {this.state.remediationStatus && !this.state.remediationStatus.overall_status ?
                                            this.props.caseDetails.status !== 'Closed' && this.state.remediationDetails && !this.state.remediationProcessed && this.state.selectedRecord.parameters && Object.entries(this.state.selectedRecord.parameters).length ?
                                                <div className="rounded bg-dark p-3 mb-3">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <p className="mb-0 f16 text-white">Configure Remediation</p>
                                                            {/* <p className="small mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p> */}
                                                        </div>
                                                    </div>
                                                    {expandRDSVolume.includes(this.state.selectedRecord.processor_rule_id) ?
                                                        <ExpandRDSVolume
                                                            selectedRecord={this.state.selectedRecord}
                                                            validationError={this.state.validationError}
                                                        />
                                                    : changeRDSVolumeType.includes(this.state.selectedRecord.processor_rule_id) ?
                                                        <ChangeRDSVolumeType
                                                            selectedRecord={this.state.selectedRecord}
                                                            validationError={this.state.validationError}
                                                        />
                                                    : listStorageTypes.includes(this.state.selectedRecord.processor_rule_id) ?
                                                        <ListStorageTypes 
                                                            selectedRecord={this.state.selectedRecord}
                                                            validationError={this.state.validationError}
                                                        />
                                                    : cleanupDisk.includes(this.state.selectedRecord.processor_rule_id) ? 
                                                        <CleanupDisk
                                                            selectedRecord={this.state.selectedRecord}
                                                            validationError={this.state.validationError}
                                                        />
                                                    : vmTypeRemediations.includes(this.state.selectedRecord.processor_rule_id) ? 
                                                        <VmTypeRemediations 
                                                            selectedRecord={this.state.selectedRecord}
                                                            validationError={this.state.validationError}
                                                        />
                                                    : rdsStorageAutoscaling.includes(this.state.selectedRecord.processor_rule_id) ?
                                                        <RdsStorageAutoscaling
                                                            selectedRecord={this.state.selectedRecord}
                                                            validationError={this.state.validationError}
                                                        />
                                                    : enableRDSMulti.includes(this.state.selectedRecord.processor_rule_id) ?
                                                        <EnableRDSMulti
                                                            selectedRecord={this.state.selectedRecord}
                                                            validationError={this.state.validationError}
                                                        />
                                                    : createRDSReadReplica.includes(this.state.selectedRecord.processor_rule_id) ?
                                                        <CreateRDSReadReplica
                                                            selectedRecord={this.state.selectedRecord}
                                                            validationError={this.state.validationError}
                                                        />
                                                    : modifyLambdaMemory.includes(this.state.selectedRecord.processor_rule_id) ?
                                                        <ModifyLambdaMemory
                                                            selectedRecord={this.state.selectedRecord}
                                                            validationError={this.state.validationError}
                                                        />
                                                    : modifyLambdaTimeout.includes(this.state.selectedRecord.processor_rule_id) ?
                                                        <ModifyLambdaTimeout
                                                            selectedRecord={this.state.selectedRecord}
                                                            validationError={this.state.validationError}
                                                        />
                                                    : increaseRDSInstanceIOPS.includes(this.state.selectedRecord.processor_rule_id) ?
                                                        <IncreaseRDSInstanceIOPS
                                                            selectedRecord={this.state.selectedRecord}
                                                            validationError={this.state.validationError}
                                                        />
                                                    :  modifyVolume.includes(this.state.selectedRecord.processor_rule_id) ? 
                                                        <ModifyVolume
                                                            selectedRecord={this.state.selectedRecord}
                                                            formInputState={() => this.formInputState()}
                                                        />
                                                    : increaseDecreseInstanceAiops.includes(this.state.selectedRecord.processor_rule_id) ?
                                                        <IncreaseDecreseInstanceAiops
                                                            selectedRecord={this.state.selectedRecord}
                                                            formInputState={() => this.formInputState()}
                                                        />
                                                    : lambdaRuntimeVersion.includes(this.state.selectedRecord.processor_rule_id) ?
                                                        <LambdaRuntimeVersion
                                                            selectedRecord={this.state.selectedRecord}
                                                            formInputState={() => this.formInputState()}
                                                        />                                                    
                                                    : 
                                                        this.state.selectedRecord.parameters.map((item, index) => {
                                                            return (
                                                                <div className="row mt-2">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                            <div className="d-flex justify-content-between">
                                                                                {item.component !== 'checkbox' ? 
                                                                                    <label>{item.label}</label>
                                                                                : null}
                                                                                {item.component === 'multiple_textbox' ?
                                                                                    <small className="textInfo cursorPointer" onClick={() => this.addTextbox(item.name, item.label, index) }>Add New Row</small>
                                                                                : null }
                                                                            </div>
                                                                            {item.component === 'textbox' ?
                                                                                <React.Fragment>
                                                                                    <TextField 
                                                                                        className="form-control col-sm-3 bg-muted text-white border-gray5"
                                                                                        recordSelection={this.updateFormFieldValue}
                                                                                        value={this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index] ? this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index] : ''}
                                                                                        name={[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index]}
                                                                                        label="First Field"
                                                                                        placeholder=""
                                                                                        required={item.mandatory ? true : false}
                                                                                        unitLabel = {item.unit ? item.unit : ''}
                                                                                        validation = {item.validation ? item.validation : {}}
                                                                                    />
                                                                                    {/* <div className="d-flex">
                                                                                        <input 
                                                                                            type="text" 
                                                                                            className={`form-control mr-1 f12 ${item.validation && item.validation.data_type === 'number' ? 'w-25': 'w-75'}`}
                                                                                            placeholder={`Enter ${item.label}`}
                                                                                            value={this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index] ? this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index] : ''}
                                                                                            onChange={e => this.validationProcess(index, e.target.value, item.mandatory, item.validation, item.name, item.label)}
                                                                                        />                                                        
                                                                                    
                                                                                        {item.unit ?
                                                                                            <small className="align-self-center">{item.unit}</small>
                                                                                        : null
                                                                                        }
                                                                                    </div> */}
                                                                                    {this.state.validationError && this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index+'_validationErrorMessage'] !=='' ? (
                                                                                        <small className='text-danger'>{this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index+'_validationErrorMessage']}</small>
                                                                                    ) : null}
                                                                                </React.Fragment>
                                                                            : item.component === 'multiple_textbox' ?
                                                                                <React.Fragment>
                                                                                    {this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index+'_length'] ? 
                                                                                        this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index+'_length'].map((add, i) => {
                                                                                            return (
                                                                                                <div className="d-flex">
                                                                                                    <input 
                                                                                                        type="text" 
                                                                                                        className="form-control mr-1 mb-2" 
                                                                                                        placeholder={`Enter ${item.label}`}
                                                                                                        value={this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index+'_'+i] ? this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index+'_'+i] : ''}
                                                                                                        onChange={e => this.setState({ [item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index+'_'+i]: e.target.value })}
                                                                                                    />   
                                                                                                    {item.unit ?
                                                                                                        <small className="align-self-center">{item.unit}</small>
                                                                                                    : null
                                                                                                    }
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    : 
                                                                                        <div className="d-flex">
                                                                                            <input 
                                                                                                type="text" 
                                                                                                className="form-control mr-1 mb-2" 
                                                                                                placeholder={`Enter ${item.label}`}
                                                                                                value={this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index+'_0'] ? this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index+'_0'] : ''}
                                                                                                onChange={e => this.setState({ [item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index+'_0']: e.target.value })}
                                                                                            />   
                                                                                            {item.unit ?
                                                                                                <small className="align-self-center">{item.unit}</small>
                                                                                            : null
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                                                                
                                                                                    {this.state.validationError && this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index+'_validationErrorMessage'] !=='' ? (
                                                                                        <small className='text-danger'>{this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index+'_validationErrorMessage']}</small>
                                                                                    ) : null}
                                                                                
                                                                                </React.Fragment>
                                                                            : item.component === 'select' ?
                                                                                <React.Fragment>
                                                                                    <SelectField 
                                                                                        className="f13 p-0 form-control reactSelectDrop col-sm-6 bg-muted text-white border-gray5"
                                                                                        recordSelection={this.updateFormFieldValue}
                                                                                        value={this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index] ? this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index] : ''}
                                                                                        name={[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index]}
                                                                                        label='Select'
                                                                                        placeholder="All"
                                                                                        required={item.mandatory ? true : false}
                                                                                        unitLabel = {item.unit ? item.unit : ''}
                                                                                        apiToCall={item.apiToCall? item.apiToCall : {}}
                                                                                    />
                                                                                    {/* <Select
                                                                                        placeholder={'All'}
                                                                                        isSearchable={false}
                                                                                        className='f13 p-0 form-control reactSelectDrop'
                                                                                        value={({
                                                                                            value: this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index] ? this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index] : 'Select',
                                                                                            label: this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index] ? this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index] : 'Select',
                                                                                        })}
                                                                                        options={this.state[item.name+'_apiCall_'+index] && this.state[item.name+'_apiCall_'+index].map(item => ({
                                                                                            value: item,
                                                                                            label: item,	
                                                                                        }))}
                                                                                        onChange={event =>  
                                                                                            this.setState({[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index]: event.value})
                                                                                        }
                                                                                    /> */}
                                                                                    {this.state.validationError && this.state+[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index+'_validationErrorMessage'] !=='' ? (
                                                                                        <small className='text-danger'>{this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index+'_validationErrorMessage']}</small>
                                                                                    ) : null}
                                                                                </React.Fragment>
                                                                            : item.component === "checkbox" ? 
                                                                                <React.Fragment>
                                                                                    <Checkbox 
                                                                                        className="form-check-input"
                                                                                        recordSelection={this.updateFormFieldValue}
                                                                                        value={this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index] && this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index] !== '' ? this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index] : false}
                                                                                        name={[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index]}
                                                                                        label={item.label ? item.label : ''}
                                                                                    />
                                                                                    {/* <div className="d-flex">
                                                                                        <div className="form-group form-check">
                                                                                            <input type="checkbox" 
                                                                                                onChange={() => this.setState({ [item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index]: !this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index]})} 
                                                                                                className="form-check-input" 
                                                                                            />
                                                                                            
                                                                                            <label className="form-check-label">{item.label ? item.label : ''}</label>
                                                                                        </div>
                                                                                    </div> */}
                                                                                    {this.state.validationError && this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index+'_validationErrorMessage'] !=='' ? (
                                                                                        <small className='text-danger'>{this.state[item.name+'_'+this.state.selectedRecord.processor_rule_id+'_'+index+'_validationErrorMessage']}</small>
                                                                                    ) : null}
                                                                                </React.Fragment>
                                                                            : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    
                                                    <div>
                                                        <Checkbox
                                                            className='form-check-input'
                                                            recordSelection={this.updateAcknowledgDetails}
                                                            value={this.state.acknowledgment ? this.state.acknowledgment : false}
                                                            name={'acknowledgment'}
                                                            label='I hereby confirm to apply the above choosen remediation1'
                                                            labelClassName='text-white'
                                                        />
                                                        {this.state.remediationStared ?
                                                            <button className="mt-2 btn btn-success">
                                                                Applying <Spinner size='sm' color='light' className='ml-2' />
                                                            </button>
                                                        : 
                                                            <button className={`mt-2 btn ${this.state.showApplyBtn ? 'btn-success' : 'disabled btn-primary'}`} 
                                                            onClick={ () => this.setState({ validationError: false, remediationStared: true }, () => this.startRemediationProcess())}>Apply</button>
                                                        }
                                                    </div>
                                                </div>
                                            : this.props.caseDetails.status !== 'Closed' && !this.state.remediationProcessed && this.state.selectedRecordStatus && this.state.selectedRecordStatus === ' ' ?
                                                <div class="rounded bg-dark p-3 mb-3">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <p className="mb-0 f16 text-white">Configure Remediation</p>
                                                            {/* <p className="small mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p> */}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="form-check mb-1">
                                                            <input type="checkbox" onClick={() => this.setState({ showApplyBtn: !this.state.showApplyBtn })} class="form-check-input" />
                                                            <label class="form-check-label text-white">I hereby confirm to apply the above choosen remediation</label>
                                                        </div>
                                                        {this.state.remediationStared ?
                                                            <button className="btn btn-success">
                                                                Applying <Spinner size='sm' color='light' className='ml-2' />
                                                            </button>
                                                        : 
                                                            <button class={`btn ${this.state.showApplyBtn ? 'btn-success' : 'disabled btn-primary'}`} 
                                                            onClick={ () => this.setState({ remediationStared: true }, () => this.startRemediationProcess())}>Apply</button>
                                                        }
                                                    </div>
                                                </div>  
                                            : null        
                                        : null }   
                                        {this.state.viewSelectedParameters && this.state.viewSelectedParameters.length ?
                                            <div className="rounded bg-dark p-3 mb-3">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <p className="mb-0 f16 text-white">Configure Remediation</p>
                                                        {/* <p className="small mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p> */}
                                                    </div>
                                                </div>
                                                {this.state.viewSelectedParameters.map((item, index) => {
                                                    return(
                                                        <div className="row mt-2">
                                                            <div className="col-md-12">
                                                                <p className="mb-2">{item.label}: <b>{item.value}</b></p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        : null          
                                        }
                                    </div>
                                </div>
                            : this.state.remediationList && !this.state.remediationList.length && !this.state.showLoading ?
                                <div className='d-flex justify-content-center m-4'>
                                    <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                </div>
                            : this.state.noRemediationForselectedAsset ? 
                                <div className='d-flex justify-content-center m-4'>
                                    <p>There are no Remediations. Please try adjusting your filter.</p>
                                </div>
                            : this.state.showLoading ?
                                <div className='d-flex justify-content-center m-4'>
                                    <Spinner className='text-center' color='white' size='lg' />
                                </div>
                            : null
                            }
                        </div>
                    </div>                
                </div>
            </div>
		)
	}
}
 
/**
 * Type of the props used in the component
 */
RemediationLeftSection.propTypes = {
    getRemediationDetails: PropTypes.func,
    getArtifactDetails: PropTypes.func,
    getRemediationStatus: PropTypes.func,
    listRemediation: PropTypes.func,
    startRemediation: PropTypes.func,   
    apiToDevCall: PropTypes.func,
    setAiopsPropsDetails: PropTypes.func,
    caseDetails: PropTypes.func
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('----------------------',state)
	return {
        caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : [],
        alertsList: state.aiops.caseDetails.results && state.aiops.caseDetails.results.alerts ? state.aiops.caseDetails.results.alerts : [],
        assetsList: state.aiops.caseDetails.results && state.aiops.caseDetails.results.assets ? state.aiops.caseDetails.results.assets : [],
        remediationStatus: state.aiops.remediationStatus,
        aiopsPropsDetails: state.aiops.aiopsPropsDetails ? state.aiops.aiopsPropsDetails : {},        
        selectedRemediation: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.selected_remediation ? state.aiops.aiopsPropsDetails.selected_remediation : {},
        aiopsRemediationParams: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.remediationParams ? state.aiops.aiopsPropsDetails.remediationParams : {},
    }
}

export default connect(mapStateToProps, {
  getRemediationDetails,
  getArtifactDetails,
  getRemediationStatus,
  listRemediation,
  startRemediation,
  apiToDevCall,
  setAiopsPropsDetails,
  getCaseDetails
})(withRouter(RemediationLeftSection))