/*************************************************
 * Tvastar
 * @exports
 * @class AppWrapper.js
 * @extends Component
 * @author Rajasekar // on 20/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Sidebar from './Sidebar'
import Header from './Header'
import { Auth } from 'aws-amplify'
import axios from 'axios'
import { logout } from '../../utils/utility'
import { URL_PATH } from '../../config/urlPath'
import {
	USER_SERVICES_END_POINT,
	LIST_ACCOUNTS
} from '../../config'
let test = []

export const AppWrapper = (Content, ...propsMapping) => {
	// notificationSystem = React.createRef();
	class HOC extends Component {
		/**
		 * Creates an instance of HOC.
		 * @param {any} props
		 * @memberof HOC
		 */
		constructor(props) {
			super(props)
			this.state = {}
		}

		componentDidMount = async () => {
			if(!test.length) {
				try {
					const url = USER_SERVICES_END_POINT + LIST_ACCOUNTS;
					const resolveIssue = await axios.get(url, {
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}`
						}
					})
					this.setState({wraperAccounts: resolveIssue.data})
					test = resolveIssue.data
				} catch (error) {
					if(error === 'No current user') {
						logout()
					}
				}
			} else {
				this.setState({wraperAccounts: test})
			}

			// window.ononline = (event) => {
			// 	console.log("Back Online")
			// };
		  
			// window.onoffline = (event) => {
			// 	console.log("Connection Lost")
			// };
		}

		componentDidCatch(error, errorInfo) {
			// Log error to an error reporting service like Sentry
			// console.log("error", error)
			// console.log("errorInfo", errorInfo)
			this.props.history.push({
				pathname: URL_PATH.ERROR_PAGE,
				state: { error, errorInfo, pathName: window.location.pathname, pathUrl: window.location.href }
			})
		}

		render() {
			// alert(test.length)
			// alert(this.props.location.pathname)
			return (
				// 	this.state.wraperAccounts && !this.state.wraperAccounts.length && this.props.location.pathname === '/account-register' ?
				// 	<Redirect to={{pathname: URL_PATH.ACCOUNT_REGISTER}} />
				// :	
				// 	<React.Fragment>
				// 		<Sidebar />
				// 		<div className='main-content'>
				// 			<Header />
				// 			<Content {...this.props} />
				// 		</div>
				// 	</React.Fragment>
				<React.Fragment>
					<Sidebar />
					<div className='main-content'>
						<Header />
						<Content {...this.props} />
					</div>
				</React.Fragment>
			)
				
		}
	}

	return connect(...propsMapping)(HOC)
}
