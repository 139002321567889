
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file DynamicSection.js
 * @author Prakash // on 19/05/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Select from 'react-select';
import { Spinner, Input } from 'reactstrap'
import { setAutomationPropsDetails, DynamicApiToDevCall } from '../../../actions/commonAction'
import { onlyNumeric } from '../../../utils/utility'

class DynamicSection extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            activeSection: this.props.activeSection,
        }
    }

    componentDidMount = () => {
        if(this.props.automationPropsDetails) {
            let obj = this.props.automationPropsDetails
            let exist = obj.actions.filter(e => e.section === this.state.activeSection)

            if(exist.length) {
                this.setState({ 
                    inputDetailArray: exist[0].input ? exist[0].input : [],
                    actionData: exist[0] ? exist[0] : {}
                },
                    () => this.structureInput()
                )
            }
        }
    }

    componentDidUpdate = (prevProps) => {}

    structureInput = () => {
        if(this.state.inputDetailArray && this.state.inputDetailArray.length) {
            this.state.inputDetailArray.forEach(item => {
                if(item.component === "select") {
                    if(item.apiToCall) {
                        this.setState({ ["loading_"+item.key]: true })
                        let params = {}

                        let valueRequest =  item.apiToCall.body && item.apiToCall.body.values
                        valueRequest && valueRequest.forEach(req => {
                            params[req.key] = req.value
                        })

                        console.log("item.apiToCall", item.apiToCall)
                        let url = item.apiToCall.name
                        let API_END_POINT = item.apiToCall.api
                        let apiMethod = item.method

                        // let url = "/utils/get-encryption-kms-keys"
                        // let API_END_POINT = "AIOPS_AUTOMATION"

                        // params.account_id = "265469181489"
                        // params.region = "us-east-2"
                        // params.service = "ebs"

                        
                        this.props.DynamicApiToDevCall(API_END_POINT, apiMethod, url, params, (promise, response) => {
                            if (promise) {
                                if(url === '/get-vm-types') {
                                    let vmArray = []
                                    response.length && response[0].results && response[0].results.forEach(res => {
                                        res.vm_series && res.vm_series.length && res.vm_series.forEach(series => {
                                            series.vm_types && series.vm_types.forEach(types => {
                                                let typeRow = {}
                                                // typeRow.description = res.description
                                                // typeRow.series = series.series
                                                // typeRow.options = types.instanceType
                                                // typeRow.vm_types = types
                                                typeRow.label = types.instanceType
                                                typeRow.value = types.instanceType
                                                vmArray.push(typeRow)
                                            }) 
                                        })
                                    })
                                    this.setState({ ["options_"+item.key]: vmArray, ["loading_"+item.key]: false })
                                } else if(url === '/utils/get-encryption-kms-keys') {
                                    let array = []
                                    response.length && response.forEach(res => {
                                        let dataRow = {}
                                        dataRow.label = res.alias_name
                                        dataRow.value = res.key_id
                                        dataRow.description = res.description
                                        array.push(dataRow)
                                    })
                                    this.setState({ ["options_"+item.key]: array, ["loading_"+item.key]: false })
                                } else {
                                    this.setState({ ["options_"+item.key]: response, ["loading_"+item.key]: false })
                                }
                            }
                        })
                        
                    } else {
                        let options = []
                        item.options && item.options.forEach( opt => {
                            let dataRow = {}
                            dataRow.label = opt
                            dataRow.value = opt
                            options.push(dataRow)
                        })
                        this.setState({ ["options_"+item.key]: options })
                    }
                }
            })
        } else {
            this.props.actionCompleted(true)
        }
    }

    formInputProps = (field, value) => {
        let obj = this.props.automationPropsDetails
        let exist = obj.actions.filter(e => e.section === this.state.activeSection)

        let array = []
        let inputDetails = exist[0].input ? exist[0].input : []
        if(inputDetails && inputDetails.length) {
            inputDetails.forEach(item => {
                //item.mandatory && 
                if(this.state[item.key] && this.state[item.key] !== "") {
                    if(item.value_list) {
                        item.value_list = this.state[item.key] 
                    } else {
                        item.value = this.state[item.key] 
                    }
                }
                array.push(item)
            })
        }
        inputDetails = array
        this.props.setAutomationPropsDetails(obj)

        this.checkAllDataFilled()
    }

    checkAllDataFilled = () => {
        let hasError = false
        let obj = this.props.automationPropsDetails
        
        let exist = obj.actions.filter(e => e.section === this.state.activeSection)
        let inputDetails = exist[0].input ? exist[0].input : []
        if(inputDetails && inputDetails.length) {
            inputDetails.forEach(item => {
                if(item.mandatory && (!this.state[item.key] || this.state[item.key] === "")) {
                    hasError = true
                }
            })
        }

        if(!hasError) {
            this.props.actionCompleted(true)
        } else {
            this.props.actionCompleted(false)
        }
    }

    render() {
        return (
            <div className={`${this.props.identifierDetail && this.props.identifierDetail.resolution_status_info && this.props.identifierDetail.resolution_status_info.status && this.props.identifierDetail.resolution_status_info.status === "resolved" ? "disabled" : ""} `}>
                {this.state.inputDetailArray && this.state.inputDetailArray.length ? 
                    this.state.inputDetailArray.map(item => {
                        return(
                        <div className="p-3 bg-dark3 mt-2 rounded">
                            {item.component === "select" ?
                                <React.Fragment>
                                    <div className="d-flex justify-content-between">
                                        <p className="d-flex b-block mb-0 text-white">
                                            {this.state["loading_"+item.key] ? 
                                                <Spinner color='white' size='sm' className="mr-1" />
                                            : null}
                                            {item.label}
                                        </p>
                                        {this.state.hasError && (!this.state[item.key] || this.state[item.key] === "") ?
                                            <p className="mb-0 text-danger">required</p>
                                        : null}
                                    </div>
                                    <Select
                                        placeholder={"Select"}
                                        isSearchable={true}
                                        // menuIsOpen={true}
                                        className="f13 p-0 selectDarkTheme w-40"
                                        value={({
                                            value: this.state[item.key],
                                            label: this.state[item.key] && this.state[item.key] !== "" ? this.state["options_"+item.key].filter(e => e.value === this.state[item.key])[0].label : <span className="placeholder">Select</span>
                                        })}
                                        options={this.state["options_"+item.key] && this.state["options_"+item.key].map(opt => ({
                                            value: opt.value,
                                            label: opt.label,	
                                        }))}
                                        onChange={event =>  
                                            this.setState({
                                                [item.key]: event.value
                                            },
                                                () => this.formInputProps(item.key, this.state[item.key])
                                            )
                                        }
                                    />
                                </React.Fragment>
                            : null}
                        </div>
                        )
                    })
                :
                    <div className="d-flex mt-2">
                        <div className="w-100">
                            <label className="mb-2 w-100">Description</label>
                            <label className="text-white">{this.state.actionData && this.state.actionData.description ? this.state.actionData.description : ""}</label>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
DynamicSection.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log("DynamicSection",state)
    return {
        automationPropsDetails: state.filters.automationPropsDetails
    }
}

export default connect(mapStateToProps, {
    setAutomationPropsDetails,
    DynamicApiToDevCall
})(withRouter(DynamicSection))        