/*************************************************
 * Tvastar
 * @exports
 * @file comparisonAction.js
 * @author Rajasekar // on 05/02/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'
import _ from 'lodash'
import moment from 'moment'

import {
	SYNCHRONIZER,
	GET_TOTAL_ASSET_COUNT,
	GET_DAILY_ACTIVE_ASSET_COUNT,
	GET_ASSET_COUNT_BY_PIRCING_MODEL,
	
	BILLING,
	GET_TOTAL_BILLING,

	SCORE,
	GET_SCORE_DETAILS,
	
	TAGGING,
	GET_ALL_TAGS
} from '../../config'
import { myLog, capitalizeFirstLetter, numberFormater } from '../../utils/utility'

/**
 * Action to get total Asset Count
 * @param {Object} body
 * @param {Function} callback
 */

export const getAllTags = (body, callback) => {
	return async () => {
		try {
			const totalTags = await API.post(TAGGING, GET_ALL_TAGS, { body })
			//let aggreateBy = body.aggregate_by
			//const covertToMapObject = await convert_to_map(searchInputs, totalAssetCount)
			callback(true, totalTags)
		} catch (error) {
			myLog('getTotalAssetCount', error)
			callback(false)
		}
	}
}

// function getTagsBody(body){
// 	var result = {};
// 	if(body){
// 		Object.entries(body).forEach(([key,value]) => {
// 			if((key === 'provider' || key === 'account_id' || key === 'region' )&& value.length > 0){
// 				alert(1)
// 				result[key] = value;
// 			}
// 		});
// 	}
// 	return result;

// }

export const getTotalAssetCountObject = (body, searchInputs, callback) => {
	return async () => {
		try {
			const totalAssetCount = await API.post(SYNCHRONIZER, GET_TOTAL_ASSET_COUNT, { body })
			//let aggreateBy = body.aggregate_by
			let filters = []
			if(body.tags) {
				filters = body.tags
			}
			const covertToMapObject = await convert_to_map(searchInputs, filters,  totalAssetCount)
			callback(true, covertToMapObject)
		} catch (error) {
			myLog('getTotalAssetCount', error)
			callback(false)
		}
	}
}

export const getDailyActiveAssetCount = (body, searchInputs, totalAccArray, callback) => {
	return async () => {
		try {
			const totalDailyAssetCount = await API.post(SYNCHRONIZER, GET_DAILY_ACTIVE_ASSET_COUNT, { body })
			//let aggreateBy = body.aggregate_by
			let filters = []
			if(body.tags) {
				filters = body.tags
			}
			const covertToMapObject = await convert_to_map(searchInputs, filters,  totalDailyAssetCount.data)
			var mapped_line = {};
			totalAccArray.forEach((item,index) => {
				if(covertToMapObject[item.key] !== undefined) {
					//mapped_line[item.key] = formLineChart(covertToMapObject[item.key].data, totalDailyAssetCount.labels, index)
					mapped_line[item.key] = formApexAreaChart(covertToMapObject[item.key].data, totalDailyAssetCount.labels, index)
				}
			})
			// Object.entries(covertToMapObject).map(([key,value], index) => {
			// 	mapped_line[key] = formLineChart(value.data, totalDailyAssetCount.labels, index)
			// })
			//console.log(mapped_line)
			callback(true, mapped_line)
		} catch (error) {
			myLog('getTotalAssetCount', error)
			callback(false)
		}
	}
}

export const getTotalAssetCountArray = (body, searchInputs, totalAccArray, callback) => {
	return async () => {
		try {
			const totalAssetCount = await API.post(SYNCHRONIZER, GET_TOTAL_ASSET_COUNT, { body })
			//let aggreateBy = body.aggregate_by
			let filters = []
			if(body.tags) {
				filters = body.tags
			}
			const covertToMapArray = await convert_to_map_array(searchInputs, filters, totalAssetCount)
			if(body.aggregate_by.includes("resource_type")) {
				var mapped_donut = {};
				totalAccArray.forEach((item,index) => {
					if(covertToMapArray[item.key] !== undefined) {
						mapped_donut[item.key] = formDoughnutChart(covertToMapArray[item.key], index)
					}
				})
				// Object.entries(covertToMapArray).map(([key,value]) => {
				// 	  mapped_donut[key] = formDoughnutChart(value, 'resource_type')
				// })

				callback(true, mapped_donut)
			} else {
				callback(true, covertToMapArray)
			}
		} catch (error) {
			myLog('getTotalAssetCount', error)
			callback(false)
		}
	}
}

export const getAssetCountByPricingModel = (body, searchInputs, callback) => {
	return async () => {
		try {
			const totalAssetCountPricing = await API.post(SYNCHRONIZER, GET_ASSET_COUNT_BY_PIRCING_MODEL, { body })
			//let aggreateBy = body.aggregate_by
			//const covertToMapArray = await convert_to_map_array(searchInputs, totalAssetCountPricing)
			// if(body.aggregate_by.includes("resource_type")) {
			// 	var mapped_donut = {};
			// 	Object.entries(covertToMapArray).map(([key,value]) => {
			// 		  mapped_donut[key] = formDoughnutChart(value, 'resource_type')
			// 	})

			// 	callback(true, mapped_donut)
			// } else {
			// 	callback(true, covertToMapArray)
			// }
			let filters = []
			if(body.tags) {
				filters = body.tags
			}
			const covertToMapObject = await convert_to_map(searchInputs, filters, totalAssetCountPricing)
			callback(true, covertToMapObject)
		} catch (error) {
			myLog('getTotalAssetCount', error)
			callback(false)
		}
	}
}

export const getScoreDetails = (body, searchInputs, totalAccArray, callback) => {
	return async () => {
		try {
			const score = await API.post(SCORE, GET_SCORE_DETAILS, { body })
			//let aggreateBy = body.aggregate_by
			//let filters = {}
			let mapResult = {};
			let filters = []
			if(body.tags) {
				filters = body.tags
			}
			if(searchInputs.includes('tags')) {
				mapResult = await convert_to_map_with_tags(searchInputs, score, filters) 
			} else {
				mapResult = await convert_to_map(searchInputs, filters, score)
			}

			let formedData = {}
			totalAccArray.forEach((item,index) => {
				if(mapResult[item.key] !== undefined) {
					formedData[item.key] = formCirlceGraph(mapResult[item.key], index)
				}
			})
			// Object.entries(mapResult).map(([key,value]) => {
			// 	formedData[key] = formCirlceGraph(value)
			// })
			
			callback(true, formedData)
			
		} catch (error) {
			myLog('getTotalAssetCount', error)
			callback(false)
		}
	}
}

const formCirlceGraph = (value, index) => {
	let colors = ['#039BE5', '#8E7CC2', '#08B089', '#6ACFB8', '#0092F3', '#49E1FC']
	let x = index
	if(x > 5) {
		for(let y = 5; y < x; y++) {
			y--
			x = (x - 6)
		}
	}
	
	if(x===0) { colors = ['#039BE5', '#8E7CC2', '#08B089', '#6ACFB8', '#0092F3', '#49E1FC'] }
	else if(x===1) {colors = ['#8E7CC2', '#039BE5', '#08B089', '#6ACFB8', '#0092F3', '#49E1FC'] }
	else if(x===2) {colors = ['#08B089', '#039BE5', '#8E7CC2', '#6ACFB8', '#0092F3', '#49E1FC'] }
	else if(x===3) {colors = [ '#6ACFB8','#039BE5', '#8E7CC2', '#08B089', '#0092F3', '#49E1FC'] }
	else if(x===4) {colors = ['#0092F3', '#039BE5', '#8E7CC2', '#08B089', '#6ACFB8', '#49E1FC'] }
	else if(x===5) {colors = ['#49E1FC', '#039BE5', '#8E7CC2', '#08B089', '#6ACFB8', '#0092F3'] }
	let Security = value.identifier_category && value.identifier_category.hasOwnProperty('Security') ? parseInt(value.identifier_category.Security.split('/')[0]) : 0
	let Governance = value.identifier_category && value.identifier_category.hasOwnProperty('Governance') ? parseInt(value.identifier_category.Governance.split('/')[0]) : 0
	let Availability = value.identifier_category && value.identifier_category.hasOwnProperty('Availability') ? parseInt(value.identifier_category.Availability.split('/')[0]) : 0
	let Opertations = value.identifier_category && value.identifier_category.hasOwnProperty('Opertations') ? parseInt(value.identifier_category.Opertations.split('/')[0]) : 0

	let series = [] 
	let labels = []
	if(Security > 0) {
		series.push(Security)
		labels.push('Security')
	}
	if(Governance > 0) {
		series.push(Governance)
		labels.push('Governance')
	}
	if(Availability > 0) {
		series.push(Availability)
		labels.push('Availability')
	}
	if(Opertations > 0) {
		series.push(Opertations)
		labels.push('Opertations')
	}
	
	let overAll = value.overall ? parseInt(value.overall.split('/')[0]) : 0

	let total = {}
	total['show'] = true
	total['label'] = labels[0] === 'No Data' ? '' : 'Over All'
	total['fontSize'] = '10px'
	total['color'] = '#FFF'
	total['formatter'] = function (w) {
		return labels[0] === 'No Data' ? '' : overAll
	}
		
	let options = {
		stroke: {
			width: 0
		},
		chart: {
			type: 'donut',
			sparkline: {
				enabled: true
			},
			zoom: {
				enabled: false,
			},
		},
		legend: {
			show: false,
		},
		dataLabels: {
			enabled: false
		},
		fill: {
			type: 'gradient',
		},
		labels: labels,
		colors: colors,
		tooltip: {
			enabled: false,
			custom: function({ series, seriesIndex, w }) {
				let color = w.globals.colors[seriesIndex]
				if(color === '#393F4E' || color === '#2D323E') {
					color = '#EDEDED'
				}
				let val = ''
				if(labels[0] !== 'No Data') {
					val = series[seriesIndex]
				}
				return '<div class="arrow_box">' +
					'<span class="mr-2">' +  w.globals.labels[seriesIndex] + '</span>' +
					'<span style="color:'+ color +'">' + val  + '</span>' +
					'</div>'
			},
			// fixed: {
			// 	enabled: true,
			// 	position: 'topRight',
			// 	offsetX: 0,
			// 	offsetY: 0,
			// }
		},
		plotOptions: {
			offsetX: 10,
			offsetY: 0,
			pie: {
				donut: {
					size: '80%',
					labels: {
						show: true,
						value: {
							fontSize: '10px',
							fontWeight: 700,
							offsetY: 0,
							color: '#fff',
						},
						name: {
							fontSize: '10px',
							offsetY: -2,
							color: '#fff',
						},	
						total: total
					}
				}
			}
		}
	}

	let graph = {}
	graph.series = series
	graph.options = options
	return graph
}


export const getTotalBilling = (body, searchInputs, callback) => {
	return async () => {
		try {
			const totalBilling = await API.post(BILLING, GET_TOTAL_BILLING, { body })
			//let aggreateBy = body.aggregate_by
			let filters = []
			if(body.filters.tags) {
				filters = body.filters.tags
			}
			let covertToMapObject = await convert_to_map(searchInputs, filters, totalBilling)
			callback(true, covertToMapObject)
		} catch (error) {
			myLog('getTotalAssetCount', error)
			callback(false)
		}
	}
}

// export function setGroupByKey(form,filters){
// 	var group_by_keys = [];
// 	Object.entries(form).map(([key,value])=>{
// 	  	if(value.length > 0){
// 			group_by_keys.push(key)
// 	  	}
// 	})
// }

const convert_to_map = (searchInputs, filters, data) => {
	//let filters = []
	var result = {};
	if(!searchInputs.includes('tags')) {
		data.forEach((item) => {
			var keys = [];	
			searchInputs.forEach((groupByKey) => {
				if(item[groupByKey] === 'NA') {
					keys.push('Global')
				} else {
					keys.push(item[groupByKey])
				}
			})
		  	result[keys.join(' / ')] = item
		});
	} else {
	  	if(filters) {
			data.forEach((item)=>{
				filters.forEach((tag)=> {
					if(tag.value && tag.value.length > 0) {
						tag.value.forEach((tag_value) => {
							var keys = [];
							if(item.hasOwnProperty(tag.key) && item[tag.key] === tag_value) {
								searchInputs.forEach((groupByKey) => {									
									if(groupByKey !== 'tags') {
										if(item[groupByKey] === 'NA') {
											keys.push('Global')
										} else {
											keys.push(item[groupByKey])
										}
									}
								})
		
								keys.push(tag.key + ":" + tag_value);
								result[keys.join(' / ')] = item;
							}
						})
					} else {
						var keys = [];			  
						if(item.hasOwnProperty(tag.key)) {
							searchInputs.forEach((groupByKey) => {
								if(groupByKey !== 'tags') {
									if(item[groupByKey] === 'NA') {
										keys.push('Global')
									} else {
										keys.push(item[groupByKey])
									}
								}
							})
							keys.push(tag.key + ":" + item[tag.key]);
							result[keys.join(' / ')] = item;
						}
					}
				
				}) 
			})
		}
	}
	
	return result;
}	

const convert_to_map_array = (searchInputs, filters, data) => {
	var result = {};
	//let filters = []
	if(!searchInputs.includes('tags')){
		data.forEach((item) => {
			var keys = [];
			searchInputs.forEach((groupByKey) => {
				if(item[groupByKey] === 'NA') {
					keys.push('Global')
				} else {
					keys.push(item[groupByKey])
				}
			})
			if(!result.hasOwnProperty(keys.join(' / '))) {
				result[keys.join(' / ')] = [item]
			} else {
				result[keys.join(' / ')].push(item);
			}
		});
	} else {
		if(filters){
			data.forEach((item)=>{
				filters.forEach((tag)=>{
					if(tag.value && tag.value.length > 0) {
						tag.value.forEach((tag_value) => {
							var keys = [];
							if(item.hasOwnProperty(tag.key) && item[tag.key] === tag_value){
								searchInputs.forEach((groupByKey) => {
									if(groupByKey !== 'tags') {
										if(item[groupByKey] === 'NA') {
											keys.push('Global')
										} else {
											keys.push(item[groupByKey])
										}
									}
								})
								keys.push(tag.key + ":" + tag_value);
								if(!result.hasOwnProperty(keys.join(' / '))) {
									result[keys.join(' / ')] = [item]
								} else {
									result[keys.join(' / ')].push(item);
								}
							}
						})
					} else {
						var keys = [];
			
						if(item.hasOwnProperty(tag.key)) {
							searchInputs.forEach((groupByKey) => {
								if(groupByKey !== 'tags') {
									if(item[groupByKey] === 'NA') {
										keys.push('Global')
									} else {
										keys.push(item[groupByKey])
									}
								}
							})
							keys.push(tag.key + ":" + item[tag.key]);
							if(!result.hasOwnProperty(keys.join(' / '))){
									result[keys.join(' / ')] = [item]
							} else {
									result[keys.join(' / ')].push(item);
							}
						}
					}
				}) 
			})
		}
	}

	return result;
}

function convert_to_map_with_tags(searchInputs, data, filters){
	var result = {};
	if(filters){
		data.forEach((item)=>{
		 	filters.forEach((tag)=>{
		  		if(tag.value && tag.value.length > 0){
			 		tag.value.forEach((tag_value) =>{
			  			item.tags.forEach((item_tag) => {
							if(item_tag.key === tag.key && item_tag.value === tag_value) {
				  				var keys = [];
					  			searchInputs.forEach((groupByKey) => {
									if(groupByKey !== 'tags') {
										if(item[groupByKey] === 'NA') {
											keys.push('Global')
										} else {
											keys.push(item[groupByKey])
										}
					  				}
								})
								keys.push(tag.key + ":" + tag_value);
								result[keys.join(' / ')] = item;
							}
						})
					})
				} else {
  
					item.tags.forEach((item_tag)=>{
						var keys = [];
						if(item_tag.key === tag.key){
							searchInputs.forEach((groupByKey) => {
								if(groupByKey !== 'tags'){
									if(item[groupByKey] === 'NA') {
										keys.push('Global')
									} else {
										keys.push(item[groupByKey])
									}
								}
							})
							keys.push(tag.key + ":" + item_tag.value);
							result[keys.join(' / ')] = item;
						}
			  		})
				}
	  		}) 
	 	})
	}
   
	return result;
  
}

const formDoughnutChart = (data, index) => {
	data = _.orderBy(data, ['total_count'], ['desc'])

	let colors = ['#039BE5', '#8E7CC2', '#08B089', '#6ACFB8', '#0092F3', '#49E1FC']
	let x = index
	if(x > 5) {
		for(let y = 5; y < x; y++) {
			y--
			x = (x - 6)
		}
	}
	
	if(x===0) { colors = ['#039BE5', '#8E7CC2', '#08B089', '#6ACFB8', '#0092F3', '#49E1FC'] }
	else if(x===1) {colors = ['#8E7CC2', '#039BE5', '#08B089', '#6ACFB8', '#0092F3', '#49E1FC'] }
	else if(x===2) {colors = ['#08B089', '#039BE5', '#8E7CC2', '#6ACFB8', '#0092F3', '#49E1FC'] }
	else if(x===3) {colors = [ '#6ACFB8','#039BE5', '#8E7CC2', '#08B089', '#0092F3', '#49E1FC'] }
	else if(x===4) {colors = ['#0092F3', '#039BE5', '#8E7CC2', '#08B089', '#6ACFB8', '#49E1FC'] }
	else if(x===5) {colors = ['#49E1FC', '#039BE5', '#8E7CC2', '#08B089', '#6ACFB8', '#0092F3'] }

	let series = [] 
	let labels = []
	let overAll = 0
	data.forEach((item) => {
		// labels.push(item.resource_type+': '+item.total_count);
		labels.push(item.resource_type);
		series.push(item.total_count);

		overAll += item.total_count 
	})

	let total = {}
	total['show'] = true
	total['label'] = labels[0] === 'No Data' ? '' : 'Over All'
	total['fontSize'] = '10px'
	total['color'] = '#FFF'
	total['formatter'] = function (w) {
		return labels[0] === 'No Data' ? '' : overAll
	}
		
	let options = {
		stroke: {
			width: 0
		},
		chart: {
			type: 'donut',
			sparkline: {
				enabled: true
			},
			zoom: {
				enabled: false,
			},
		},
		legend: {
			show: false,
		},
		dataLabels: {
			enabled: false
		},
		fill: {
			type: 'gradient',
		},
		labels: labels,
		colors: colors,
		tooltip: {
			enabled: true,
			custom: function({ series, seriesIndex, w }) {
				let color = w.globals.colors[seriesIndex]
				if(color === '#393F4E' || color === '#2D323E') {
					color = '#EDEDED'
				}
				let val = ''
				if(labels[0] !== 'No Data') {
					val = series[seriesIndex]
				}
				return '<div class="arrow_box">' +
				  '<span class="mr-2">' +  w.globals.labels[seriesIndex] + '</span>' +
				  '<span style="color:'+ color +'">' + val  + '</span>' +
				  '</div>'
			},
		},
		plotOptions: {
			offsetX: 10,
			offsetY: 0,
			pie: {
				donut: {
					size: '65%',
					labels: {
						show: false,
						value: {
							show: false,
							fontSize: '10px',
							fontWeight: 700,
							offsetY: 0,
							color: '#fff',
						},
						name: {
							show: false,
							fontSize: '10px',
							offsetY: -2,
							color: '#fff',
							title: {
								formatter: (seriesName) => {
									if(seriesName === '0ver ALl') {
										return seriesName
									} else {
										return  ''
									}
								},
							},
						},	
						total: total
					}
				}
			}
		}
	}

	let graph = {}
	graph.series = series
	graph.options = options
	return graph
}

// const formLineChart = (data, labels, index) => {

// 	// let backgroundColor
// 	// let borderColor       

// 	let labelArray = [];
// 	if(labels){
// 		for (const [key] of Object.entries(labels)) {
// 			labelArray.push(moment(labels[key]).format('DD MMM'))
// 		}
// 	}

// 	let line_options = {
// 		responsive: true,
// 		maintainAspectRatio: true,
// 		tooltips: {
// 			enabled: false
// 		},
// 		legend: {
// 			display: false,
// 		},
// 		scales:{
// 			xAxes: [{
// 				display: false, //this will remove all the x-axis grid lines
// 				 gridLines: {
// 					color: "rgba(0, 0, 0, 0)",
// 				}
// 			}],
// 			yAxes: [{
// 				display: false, //this will remove all the y-axis grid lines
// 				 gridLines: {
// 					color: "rgba(0, 0, 0, 0)",
// 				}
// 			}]
// 		},
// 	}
// 	let color = '#039BE5'
// 	let x = index
// 	if(x > 5) {
// 		for(let y = 5; y < x; y++) {
// 			y--
// 			x = (x - 6)
// 		}
// 	}
	
// 	if(x===0) { color = '#039BE5' }
// 	else if(x===1) {color = '#8E7CC2' }
// 	else if(x===2) {color = '#08B089' }
// 	else if(x===3) {color = '#6ACFB8' }
// 	// else if(x===4) {color = '#70798F' }
// 	// else if(x===5) {color = '#BBBFC7' }
// 	else if(x===4) {color = '#0092F3' }
// 	else if(x===5) {color = '#49E1FC';}

// 	let line_datasetsColumn = [
// 		{
// 			label: '',
// 			fill: false,
// 			backgroundColor: color,
// 			borderColor: color,
// 			borderCapStyle: 'butt',
// 			borderWidth: 4,
// 			borderDash: [],
// 			borderDashOffset: 0.0,
// 			borderJoinStyle: 'miter',
// 			pointBackgroundColor: color,
// 			pointBorderColor: color,
// 			pointBorderWidth: 1,
// 			pointHoverRadius: 5,
// 			pointHoverBackgroundColor: color,
// 			pointHoverBorderColor: color,
// 			pointHoverBorderWidth: 2,
// 			pointRadius: 0,
// 			pointHitRadius: 2,
// 			data: data

// 		}
// 	];
	

// 	let line_datas = {labels: labelArray, datasets: line_datasetsColumn};

// 	var graph = {};
// 	graph.line_datas = line_datas
// 	graph.line_option = line_options

// 	return graph
// }

const formApexAreaChart = (data, labels, index) => {
	let graphLabel = []
	let createdGraphData = []
	let fontColors = []
	let startEndLabels = []
	if(labels){
		for (const [key] of Object.keys(labels)) {
			graphLabel.push(moment(labels[key]).format('DD MMM'))
			if(key === 0 || key === (labels.length - 1)) {
				startEndLabels.push(moment(labels[key]).format('DD MMM'))
			} 
		}
	}
	
	let data1 = []	
	data.forEach((item, x) => {
		createdGraphData.push(item)
		let dataItems1 = {}
		dataItems1.x = moment(labels[x]).format('DD MMM')
		dataItems1.y  = item
		data1.push(dataItems1)

		fontColors.push('#000')
	})

	let series = []
	let dataRow = {}
	dataRow.data = data1
	dataRow.name = 'line'
	dataRow.labels = graphLabel
	series.push(dataRow)

	
	let color = '#039BE5'
	let x = index
	if(x > 5) {
		for(let y = 5; y < x; y++) {
			y--
			x = (x - 6)
		}
	}
	
	if(x===0) { color = '#039BE5' }
	else if(x===1) {color = '#8E7CC2' }
	else if(x===2) {color = '#08B089' }
	else if(x===3) {color = '#6ACFB8' }
	// else if(x===4) {color = '#70798F' }
	// else if(x===5) {color = '#BBBFC7' }
	else if(x===4) {color = '#0092F3' }
	else if(x===5) {color = '#49E1FC';}

	let options = {
		chart: {
			height: 100,
			type: 'area',
			zoom: {
				enabled: false
			},
			sparkline: {
				enabled: true
			},
			toolbar: {
				show: false,
			}
		},
		legend: {
			show: false
		},
		dataLabels: {
			enabled: false
		},
		stroke: {
			show: true,
			curve: 'smooth',
			lineCap: 'butt',
			colors: undefined,
			width: 2,
			dashArray: 0,      
		},
		title: {
			text: ''
		},
		grid: {
			xaxis: {
				lines: {
					show: false
				},
			},
			yaxis: {
				lines: {
					show: false
				}
			},
		},
		labels: graphLabel,
		colors: [color],
		fill: {
			type: 'solid',
			// colors: '#FFF',
		},
		tooltip: {
			enabled: true,
			enabledOnSeries: false,
			shared: false,
			followCursor: false,
			intersect: false,
			inverseOrder: false,
			custom: function({series, seriesIndex, dataPointIndex, w}) {
				let val  = series[seriesIndex][dataPointIndex];
				//let val  = series[seriesIndex][dataPointIndex];
				let labelName = w.globals.initialSeries[0].labels[dataPointIndex]
				//let label = w.globals.initialSeries[seriesIndex][dataPointIndex]
				//val = Math.abs(Math.round(val))
				//let labelName = w.globals.categoryLabels[dataPointIndex]
				//let val = str.replace('-',series[seriesIndex][dataPointIndex]);
				return '<div class="arrow_box"> <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val +
				'</div>'
			},
			fillSeriesColor: false,
			theme: false,
			style: {
			fontSize: '12px',				
			},
			onDatasetHover: {
				highlightDataSeries: false,
			},
			x: {
				show: false,
				format: 'dd MMM',
				formatter: undefined,
			},
			y: {
				show: false,
				formatter: undefined,
				title: {
					formatter: (seriesName) => seriesName,
				},
			},
			marker: {
				show: false,
			}
		}
	}

	var graph = {};
	graph.line_datas = series
	graph.line_option = options
	return graph
}

// export const getTotalAssetCount = (body, callback) => {
// 	return async dispatch => {
// 		getTotalCountDetails(body)
// 		.then((response) => {
// 				if (response.err){
// 					dispatch(Action.showMessage({message:response.err,variant:"error"}));
// 				} else{
// 					const score = await API.post(SCORE, GET_SCORE, { body })
// 					callback(true, score)
// 					return dispatch({
// 						type: GET_TOTAL_ASSET_COUNT,
// 						payload: convert_to_map(groupBy, response.res, filters)
						
// 					});
// 				}
// 			}
// 		)
// }

// export const getTotalCountDetails = (body) => {
// 	return async dispatch => {
// 		try {
// 			const totalAssetCount = await API.post(SYNCHRONIZER, GET_TOTAL_ASSET_COUNT, { body })
// 			 if(results.length > 0){
// 				 return ({res:results, err:null});
// 			 } else {
// 				 return ({res:null,err:"Categories are empty"});
// 			 }
	 
// 	   	} catch (error) {
// 			myLog('totalAssetCount', error)
// 			callback(false)
// 		}
// 	}
// }