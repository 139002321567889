/*************************************************
 * Tvastar
 * @exports
 * @file PrimaryMetricsSection  .js
 * @author Prakash // on 14/12/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { momentDateGivenFormat, addHours, momentConvertionUtcToLocalTime, getConvertedBytesUnit, convertBytes, convertBytesTo, convertBytesWithoutUnit, convertSeconds, convertSecondsWithoutUnit, countFormater, countFormaterWithoutUnit, convertBits, convertBitsWithoutUnit, subDays, getDayFromSelectedDuration, subHours, twoDateDiffrenceDayHoursMinutes } from '../../../utils/utility'
import Chart from 'react-apexcharts'
import { getSreDetails } from '../../../actions/aiops/DiagnosticsAction'

import { getMonitoringMetricsByTrend } from '../../../actions/aiops/MetricsAction'
import { setCostPropsDetails } from  '../../../actions/cost/CostAction'
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

class PrimaryMetricsSection extends Component {
    constructor(props) {
        super(props)
        this.annotationTypeRef = React.createRef()
        this.state = {
            showLoading: false,
            sreMetricsDetails: [],
            show_latency: true,
            show_error_rate: true,
            showAnnotation: true,
            showPrimaryMertics: false,
            showSecondaryMertics: true,
        }
    }
    
    componentDidMount = () => {
        this.setState({ 
            metricsStartTime: subDays(this.props.endTime, 14),  //for only last 14 days
            metricsEndTime: this.props.endTime  //for only last 14 days
        },
            // () => this.getMonitoringMetricsByTrend()    
        )
    }

    getMonitoringMetricsByTrend = () => {
        if(this.props.assetDetails.asset_id) {
            let params = {}
            params.provider = this.props.assetDetails && this.props.assetDetails.provider ? this.props.assetDetails.provider : 'aws'
            params.anomaly = false
            if(this.props.assetDetails.asset_id) {
                params.asset_id = this.props.assetDetails.asset_id
            }

            let additionalMetrics = []
            if(this.props.assetDetails && this.props.assetDetails.primary_metric_details && this.props.assetDetails.primary_metric_details.length) {
                additionalMetrics = [...additionalMetrics, ...this.props.assetDetails.primary_metric_details]
            }

            if(this.props.assetDetails && this.props.assetDetails.secodary_metric_details && this.props.assetDetails.secodary_metric_details.length) {
                additionalMetrics = [...new Set([...additionalMetrics,...this.props.assetDetails.secodary_metric_details])]
            }
            if(additionalMetrics.length) {
                params.metric_name = additionalMetrics
            } else {
                params.top = 4
            }

            params.start_time = this.state.metricsStartTime
            params.end_time = this.state.metricsEndTime

            console.log(this.props.assetDetails.asset_id, this.props.priSecMetricDetails[this.props.assetDetails.asset_id])
            if(!this.props.priSecMetricDetails || !this.props.priSecMetricDetails.hasOwnProperty(this.props.assetDetails.asset_id)) {
                this.props.getMonitoringMetricsByTrend(params, (promise, result) => {
                    if(promise) {
                        this.setState({ metricsByTrend : [] },
                            () => {
                                this.setState({ metricsByTrend: result, showLoading: false },
                                    () => {
                                        this.primaryMetricsListGraph()
                                        let obj = {}
                                        obj = this.props.priSecMetricDetails
                                        obj[this.props.assetDetails.asset_id] = this.state.metricsByTrend
                                        this.props.setCostPropsDetails('priSecMetricDetails', obj)
                                    }
                                )
                            }
                        )
                    } else {
                        this.setState({ showLoading: false })
                    }
                })
            } else {
                this.setState({ 
                    metricsByTrend: this.props.priSecMetricDetails[this.props.assetDetails.asset_id],
                    showLoading: false,
                },
                    () => this.primaryMetricsListGraph()
                )
            }
        } else {
            this.setState({ showLoading: false })
        }
    }

    primaryMetricsListGraph = () => {
        let graphDetails = this.state.metricsByTrend
        if(graphDetails) {

            graphDetails.forEach((graph, index) => {
                let graphData = graph.data
                let graphDates = graph.dates

                Object.entries(graphData).forEach(([key, item]) => {
                    

                    let array = []
                    let graphLabels = []

                    let maxValue = item.statistics && item.statistics.max ? item.statistics.max : 1
                    let avgValue = item.statistics && item.statistics.avg ? item.statistics.avg : 1
                    let minValue = item.statistics && item.statistics.min ? item.statistics.min : 1
                    
                    let graphConvertTo = ""

                    let maxRandomNumber = maxValue
                    let minRandomNumber = minValue
                    if(item.unit) {
                        graphConvertTo = this.getConvertedUnit(maxValue, item.unit)
                        maxRandomNumber = this.getGrapUnitValueWithoutUnit(maxValue, item.unit, "round")
                        minRandomNumber = this.getGrapUnitValueWithoutUnit(minValue, item.unit, "round")
                    }
                    item.values.forEach((val, j) => {
                        graphLabels.push(momentConvertionUtcToLocalTime(graphDates[j], 'YYYY-MM-DD HH:mm:ss'))
                        let arrayValues = []
                        arrayValues.push(momentConvertionUtcToLocalTime(graphDates[j], 'YYYY-MM-DD HH:mm:ss'))
                        if(item.unit) {
                            val = this.getGrapUnitValue(val, item.unit, "", graphConvertTo)
                        }
                        arrayValues.push(val)
                        array.push(arrayValues)
                    })

                    if(array.length === 1) {
                        let arrayValues = []
                        arrayValues.push(addHours(momentConvertionUtcToLocalTime(graphDates[0], 'YYYY-MM-DD HH:mm:ss'), 0.5))
                        arrayValues.push(0)
                        array.push(arrayValues)
                    }

                    let series = [{
                        data: array,
                        name: key,
                    }]
                    let xaxisAnnotation = []
                    if(this.state.showAnnotation && this.props.changeDetails && this.props.changeDetails.event_time && this.props.changeDetails.event_time !== "") {
                        xaxisAnnotation = [{
                            x: new Date(momentConvertionUtcToLocalTime(this.props.changeDetails.event_time, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                            strokeDashArray: 0,
                            borderColor: '#F75E3F',
                            label: {
                                borderColor: '#F75E3F',
                                style: {
                                    color: '#fff',
                                    background: '#F75E3F',
                                },
                                orientation: 'horizontal',
                                text: "C"
                            }
                        }]
                    }

                    let alertLocation = 20
                    let anomalyLoaction = 1
                    let errorLocation = 40
                    if(maxValue <= 2) {
                        alertLocation = 1
                        anomalyLoaction = 1
                        errorLocation = 1
                    }

                    let points = []

                    // let gandalfData = this.props.assetDetails && this.props.assetDetails.primary_metric_events && this.props.assetDetails.primary_metric_events.filter(e => e.event_name === key).length ? this.props.assetDetails.primary_metric_events.filter(e => e.event_name === key) : []

                    let events = this.props.changeDetails && this.props.changeDetails.events && this.props.changeDetails.events.length ? this.props.changeDetails.events : []

                    let gandalfData = events && events.filter(e => e.event_name === key).length ? events.filter(e => e.event_name === key) : []
                     
                    if(this.state["selectedMetricsLegend_"+key] === key && this.state["selectedLegendType_"+key] && this.state["selectedLegendType_"+key] !== "") {
                        gandalfData = gandalfData.filter(e => e.event_type === this.state["selectedLegendType_"+key]).length ? gandalfData.filter(e => e.event_type === this.state["selectedLegendType_"+key]) : []
                    }
                    
                    gandalfData.forEach(evnt => {
                        points.push({
                            x: new Date(momentConvertionUtcToLocalTime(evnt.event_time, "YYYY-MM-DD HH:mm:ss")).getTime(),
                            y: Math.floor(Math.random() * (maxValue - 1) + 1),
                            // mouseEnter: function(e) {
                            //     // do something on marker click
                            //     alert(1)
                            // },
                            marker: {
                                size: 6,
                                fillColor: evnt.event_type === "alerts" ? "#3F73AD" : evnt.event_type === "anomalies" ? "#775BA2" : evnt.event_type === "errors" ? "#24A597" : evnt.event_type === "issues" ? "#FF4560" : "#858B99",
                                strokeColor: evnt.event_type === "alerts" ? "#3F73AD" : evnt.event_type === "anomalies" ? "#775BA2" : evnt.event_type === "errors" ? "#24A597" : evnt.event_type === "issues" ? "#FF4560" : "#858B99",
                                radius: 2,
                                OffsetX: 0,
                                OffsetY: 0,
                                cssClass: '',
                            },
                            label: {
                                // borderColor: "#FF4560",
                                // offsetY: 0,
                                // style: {
                                //     color: "#fff",
                                //     background: "#FF4560"
                                // },                
                                // text: ""
                            }
                        })
                    })
                    
                    let annotations = {
                        xaxis: xaxisAnnotation,
                        // position: 'back',
                        points: points
                    }

                    let options = {
                        chart: {
                            // group: 'metrics_and_sre',
                            // id:'metrics_'+i,
                            type: 'area',
                            width: '100%',
                            toolbar: {
                                show: false,
                            },
                            zoom: {
                                enabled: false
                            },
                            sparkline: {
                                enabled: false
                            }
                        },
                        stroke: {
                            show: true,
                            curve: 'smooth',
                            lineCap: 'butt',
                            width: 2,
                            dashArray: 0,      
                        },
                        colors: ['#775BA2'],
                        fill: {
                            type: "gradient",
                            gradient: {
                                // shadeIntensity: 1,
                                // opacityFrom: 0.7,
                                // opacityTo: 0.9,
                                // stops: [0, 90, 100]
                                gradientToColors: ['#775BA2'],
                                shadeIntensity: 0,
                                opacityFrom: .3,
                                opacityTo: 1,
                                stops: [30, 90]
                            }
                        },
                        tooltip: {
                            custom: function({series, seriesIndex, dataPointIndex, w}) {
                                let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
                                dateLable = dateLable.toString()
                                dateLable = momentDateGivenFormat(dateLable, 'DD MMM HH:mm')
                                let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]
                                return '<div class="arrow_box">'+dateLable+' <span style="color:'+ w.globals.colors +'">' + parseFloat(val).toFixed(2) + '</span> </div>'
                            },
                            fixed: {
                                enabled: false
                            },
                            x: {
                                show: false,
                            },
                            y: {
                                show: false
                            },        
                            marker: {
                                show: false
                            }
                        },
                        xaxis: {
                            type: 'datetime',
                            labels: {
                                datetimeUTC: false,
                                style: {
                                    colors: '#60687C'
                                },
                                // formatter: function (value) {
                                //     return momentDateGivenFormat(value, 'HH:mm')
                                // },
                            },                            
                            axisTicks: {
                                show: false
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        yaxis: {
                            show: true,
                            axisTicks: {
                                show: false,
                            },
                            axisBorder: {
                                show: false,
                                color: '#60687C'
                            },
                            tooltip: {
                                enabled: false
                            },
                            // tickAmount: 2,
                            min: 0,
                            // max: yaxisMaxValue, 
                            tickAmount: 1,
                            labels: {
                                offsetX: -10,
                                // minWidth: 50,
                                show: true,
                                style: {
                                    fontSize: '9px',
                                    colors: '#60687C'
                                },
                                // formatter: (value) => { 
                                //     if(Number(value) === value && value % 1 === 0) {
                                //         return Math.ceil(value)
                                //     } else {
                                //         return Math.ceil(value)
                                //     }
                                // },
                                // offsetX: 0,
                                // offsetY: 0,
                            }
                        },
                        grid: {
                            show: true,
                            borderColor: '#2D323E',
                            strokeDashArray: 0,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: true
                                },
                                labels: {
                                    style: {
                                        colors: ['#FFF'],
                                    }
                                },
                                axisTicks: {
                                    show: false
                                }
                            },   
                            yaxis: {
                                lines: {
                                    show: false
                                },
                                labels: {
                                    style: {
                                        colors: ['#969AA7'],
                                    }
                                }
                            },                
                            padding: {
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0
                            },
                        },
                        annotations: annotations,
                    }
                    this.setState({ [key+'_primary_'+graph.asset_id]: series, [key+'_primary_options_'+graph.asset_id]: options })
                })
            })
        }
    }
	
	handleClickOutside(event) {
        // if (this.annotationTypeRef && !this.annotationTypeRef.current.contains(event.target)) {
        //     this.setState({ isannotationTypeOpen: false })
        // } else {
        //     this.setState({ isannotationTypeOpen: true })
        // }
    }

    getConvertedUnit = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = getConvertedBytesUnit(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                // val = getConvertedSecondsUnit(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                // val = getConvertedBitsUnit(val, unit)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                // val = getConvertedCountFormater(val)
            } else {
                val = ""
            }
        } else if(val !== '') {           
            val = ""
        }
        return val
    }

    getGrapUnitValue = (val ,unit, valueType, convertTo) => {
        console.log(val, unit, valueType, convertTo)
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseInt(val))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                if(convertTo) {
                    val = convertBytesTo(val, unit, valueType, convertTo)
                } else {
                    val = convertBytes(val, unit, valueType)
                }                         
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit, valueType, convertTo)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit, valueType, convertTo)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    getGrapUnitValueWithoutUnit = (val ,unit, valueType) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytesWithoutUnit(val, unit, valueType)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSecondsWithoutUnit(val, unit, valueType)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBitsWithoutUnit(val, unit, valueType)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                val = countFormaterWithoutUnit(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseInt(val))
        }
        return val
    }

    onClickPrimaryElipMenus = (data, key, i) => {
        Object.keys(data).forEach((item, index) => {
            if(key !== item) {
                this.setState({ ["showElipMenu_"+item]: false })
            } else {
                this.setState({ ["showElipMenu_"+item]: !this.state["showElipMenu_"+item] })
            }
        })
    }

    render() {
        return (
            <div className="" onClick={ (event) => { this.handleClickOutside(event) } }>
                {this.props.assetDetails ?
                    <div className={`card bg-dark border-0 p-3`}>
                        <div className={`${this.state.showPrimaryMertics ? "mb-1" : ""} d-flex justify-content-between`} onClick={() => this.setState({ showPrimaryMertics: !this.state.showPrimaryMertics, showLoading: !this.state.showPrimaryMertics }, () => {
                            if(this.state.showPrimaryMertics) {
                                this.getMonitoringMetricsByTrend()
                            }
                        })}>
                            <div className="text-white cursorPointer"><span className={`far ${this.state.showPrimaryMertics ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Metrics</div>
                            <div className="d-flex">
                                <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{"("+momentConvertionUtcToLocalTime(this.state.metricsStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentConvertionUtcToLocalTime(this.state.metricsEndTime, 'DD MMM YYYY HH:mm')+")"}</p>
                            </div>
                        </div>
                        <p className={`${this.state.showPrimaryMertics ? "mb-2" : "mb-1"}`}>{this.props.assetDetails.summary ? this.props.assetDetails.summary : ""}</p>
                        <div className={`collapse ${this.state.showPrimaryMertics ? 'show' : ''}`}>
                            <div class="row">
                                {!this.state.showLoading ?
                                    this.state.metricsByTrend && this.state.metricsByTrend.length ? 
                                        this.state.metricsByTrend.map((item, index) => {
                                            return (
                                                Object.keys(item.data).map((key, i) => {
                                                    return (
                                                        <div className="col-sm-6 mb-3 ">
                                                            <div class="p-3 rounded bg-dark3">
                                                                <div className="d-flex justify-content-between">
                                                                    <p class="text-white f16 p-1 mb-0">{key}</p>
                                                                </div>
                                                                {this.state[key+'_primary_options_'+item.asset_id] && Object.keys(this.state[key+'_primary_options_'+item.asset_id]).length && this.state[key+'_primary_'+item.asset_id] && this.state[key+'_primary_'+item.asset_id].length ?
                                                                    <div className="cursorPointer transparentTooltip mb-n4 mt-n3">
                                                                        <Chart options={this.state[key+'_primary_options_'+item.asset_id]} series={this.state[key+'_primary_'+item.asset_id]} type="area" height={120} width={'100%'}/>
                                                                    </div>
                                                                :
                                                                    <div style={{minHeight:"90px"}} className="d-flex align-self-center justify-content-center">
                                                                        <div className='align-self-center'>
                                                                            No Data
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            )
                                        })
                                    :
                                        <div className='d-flex justify-content-center m-4'>
                                            No Metrics details found
                                        </div>
                                :
                                    <div className='d-flex justify-content-center m-0 p-0 col-sm-12'>
                                        <Spinner className='text-center' color='white' size='lg' />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                : 
                    <div className={`bg-dark rounded p-3`}>
                        <p className={`mb-2 text-white cursorPointer`}><b>Metrics</b></p>
                        <div className='d-flex justify-content-center m-4'>
                            No Metrics details found
                        </div>
                    </div>
                }
            </div>
        )
    }
}

/**
* Type of the props used in the component
*/
PrimaryMetricsSection.propTypes = {
    getSreDetails: PropTypes.func,
    getMonitoringMetricsByTrend: PropTypes.func,
    setCostPropsDetails: PropTypes.func
}   

const mapStateToProps = state => {
    return {
        priSecMetricDetails: state.cost.costPropsDetails && state.cost.costPropsDetails.priSecMetricDetails ? state.cost.costPropsDetails.priSecMetricDetails : {}
    }
}

export default connect(mapStateToProps, {
    getSreDetails,
    getMonitoringMetricsByTrend,
    setCostPropsDetails
})(withRouter(PrimaryMetricsSection))