/*************************************************
 * Tvastar
 * @exports
 * @file InventoryProcessorDaily.js
 * @author Prakash // on 04/10/2021
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { restrictCharacter } from '../../../utils/utility'
import {
    getInventoryProcessorBaseParams,
    insertMsc,
    updateMsc,
} from '../../../actions/dlp/DlpJobsAction'
import { Spinner } from 'reactstrap'

class InventoryProcessorDaily extends Component {
    hierarichalBucketBarChartRef = React.createRef()
    constructor(props) {
        super(props)
        this.props = props;
        this.state = {
            showLoading: true,
            bucketArray: [0],
            eventsBucketArray: [0],
        }
    }

    componentDidMount = () => {        
        this.getInventoryProcessorBaseParams()
        if(this.props.pageType === 'edit' || this.props.pageType === 'view') {
            this.editJobs()
        }
    }

    editJobs = () => {
        let selectedRecord = this.props.selectedRecord

        let mandatory = selectedRecord.mandatory

        let bucketArray = []
        if(selectedRecord.dlp_data_events_processor_config) {
            selectedRecord.dlp_data_events_processor_config.bucket.forEach((item, index) => {
                bucketArray.push(index)
                this.setState({ ['bucket_name_'+index]: item.bucket_name, ['prefix_name_'+index]: item.prefix })
            })
        } else {
            bucketArray.push(0)
        }

        let eventsBucketArray = []
        if(selectedRecord.dlp_inventory_processor_config.bucket_name_with_prefix.length) {
            selectedRecord.dlp_inventory_processor_config.bucket_name_with_prefix.forEach((item, index) => {
                eventsBucketArray.push(index)
                let bucketName = item.split('/')[0]
                let prefix = item.split('/').slice(1).join('/')
                this.setState({ ['events_bucket_name_'+index]: bucketName, ['events_prefix_name_'+index]: prefix })
            })
        } else {
            eventsBucketArray.push(0)
        }


        this.setState({ bucketArray, eventsBucketArray, mandatory })
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.dlpPropsDetails && typeof this.props.dlpPropsDetails.hasJobError !== 'undefined' &&  prevProps.dlpPropsDetails.hasJobError !== this.props.dlpPropsDetails.hasJobError) {
            this.setState({ hasError: true })
        }
    }

    getInventoryProcessorBaseParams = () => {
        let params = {}
        this.props.getInventoryProcessorBaseParams(params, (promise, response) => {
            this.setState({ masterData: response, showLoading: false })
        })
    }

    removeSelectedOption = (field, value) => {
        let filteredReslt = this.state[field].filter(e => e !== value)
        this.setState({ [field]: filteredReslt })
    }

    onChangePropsEvent = () => {
        let hasError = false

        let checkBucketDataExist = 0
        this.state.bucketArray.forEach(item => {
            if(this.state['bucket_name_'+item] === '' && this.state['prefix_name_'+item] !== '') {
                hasError = true
            } else if(this.state['bucket_name_'+item] !== '') {
                checkBucketDataExist++
            }
        })
        
        if(!checkBucketDataExist) {
            hasError = true
        }

        let checkEventsBucketDataExist = 0
        this.state.eventsBucketArray.forEach(item => {
            if(this.state['events_bucket_name_'+item] === '' && this.state['events_prefix_name_'+item] !== '') {
                hasError = true
            } else if(this.state['events_bucket_name_'+item] !== '') {
                checkEventsBucketDataExist++
            }
        })
        
        if(!checkEventsBucketDataExist) {
            hasError = true
        }

        let data = {}
        data.mandatory = this.props.pageType === 'create' ? true : this.state.mandatory
        let jobText = ' for buckets'

        let buckets = []
        this.state.bucketArray.forEach(item => {
            if(this.state['bucket_name_'+item] !== '') {
                let dataRow = {}
                dataRow.bucket_name = this.state['bucket_name_'+item]
                dataRow.prefix = this.state['prefix_name_'+item] ? this.state['prefix_name_'+item] : ''
                buckets.push(dataRow)
                jobText += ' bucket name '+this.state['bucket_name_'+item]
                
                if(this.state['prefix_name_'+item] && this.state['prefix_name_'+item] !== '') {
                    jobText += ' prefix '+this.state['prefix_name_'+item]
                }
            }
        })
        data.bucket = buckets
        
        
        let  config= {}

        jobText += ' from the inventory '
        let eventBuckets = []
        this.state.eventsBucketArray.forEach(item => {
            if(this.state['events_bucket_name_'+item] !== '') {
                let bucketPrefix = this.state['events_bucket_name_'+item]
                bucketPrefix += this.state['events_prefix_name_'+item] ? ('/'+this.state['events_prefix_name_'+item]) : ''
                buckets.push(bucketPrefix)
                if(this.state['events_prefix_name_'+item] && this.state['events_prefix_name_'+item] !== '') {
                    jobText += ' bucket name '+this.state['events_prefix_name_'+item]+' prefix '+this.state['events_prefix_name_'+item]
                }
            }
        })
        config.bucket_name_with_prefix = eventBuckets
        
        if(this.state.masterData && this.state.masterData.static_fields) {
            Object.entries(this.state.masterData.static_fields).forEach(([key, value]) => {
                if(key !== 'dlp_inventory_processor_config') {
                    data[key] = value
                } else {
                    Object.entries(value).forEach(([key, value]) => {
                        config[key] = value
                    })
                }
            })
        }

        data.dlp_inventory_processor_config = config

        this.props.jobData(data, jobText, hasError)
    }    

    onKeyDown = (e) => {
        let selectedTags = this.state.selectedTags ? this.state.selectedTags : []
        if (e.keyCode === 13 || e.keyCode === 9) {
            if(this.state.selected_tag !== '') {
                selectedTags.push(this.state.selected_tag.trim())
                if(e.keyCode === 9) {
                    e.preventDefault();
                }
                this.setState({ selectedTags, selected_tag: '' })
            }
        }
    }

    addSection = (array) => {
        let rowList = this.state[array];
        if(this.state[array]) {
            let value = this.state[array][this.state[array].length - 1]
            value = value+1
            rowList.push(value);
        }
        
        this.setState({[array]: rowList })
    }

    removeBucketSection = (i) => {
        this.setState({ ['bucket_name_'+i]: '', ['prefix_name_'+i]: '' },
            () => {
                let rowList = this.state.bucketArray;
                rowList.splice(i, 1);
                this.setState({ bucketArray: rowList })
            }
        );
    }

    removeEventsBucketSection = (i) => {
        this.setState({ ['events_bucket_name_'+i]: '', ['events_prefix_name_'+i]: '' },
            () => {
                let rowList = this.state.eventsBucketArray;
                rowList.splice(i, 1);
                this.setState({ eventsBucketArray: rowList })
            }
        );
    }

    render() {		
        return (
            !this.state.showLoading ?
                <div className={`${this.props.pageType === 'view' ? 'disabled disableInputColor' : ''}`}>                                
                    <div className={`form-group dlpDescriptionForm`}>
                        <p className="mb-0 mr-2 align-self-end text-info mt-4">for the buckets</p>
                        {this.state.bucketArray.map((item, i) => {
                            return(
                                <div className="w-100">
                                    <div className="d-flex flex-wrap">
                                        <p className="mb-0 mr-2 align-self-end text-info mt-3">Bucket</p>
                                        <input 
                                            type="text" 
                                            className={`inputField mt-3 w-30 ${this.state.hasError && (!this.state['bucket_name_'+item] || this.state['bucket_name_'+item] === '') ? 'dottedDangerTextboxLine' : ''}`}
                                            value={this.state['bucket_name_'+item] ? this.state['bucket_name_'+item] : ''}
                                            onChange={e => this.setState({ ['bucket_name_'+item]: restrictCharacter(e.target.value, '/') }, () => this.onChangePropsEvent())}
                                        />
                                        <p className="mb-0 mx-2 align-self-end text-info mt-3">Prefix</p>
                                        <input 
                                            type="text" 
                                            className={`inputField mt-3 w-30`}
                                            value={this.state['prefix_name_'+item] ? this.state['prefix_name_'+item] : ''}
                                            onChange={e => this.setState({ ['prefix_name_'+item]: e.target.value }, () => this.onChangePropsEvent())}
                                        />
                                        {i ?
                                            <span className={`far fa-trash cursor-pointer f18 align-self-end ml-4`} onClick={() => this.removeBucketSection(i)}></span>
                                        : null}
                                    </div>
                                </div>
                            )
                        })}
                        
                        <div className={`d-flex col-sm-12 justify-content-end ${this.props.pageType.toLowerCase() === 'view' ? 'displayNone' : ''}`}>
                            <span className='btn btn-secondary' onClick={() => this.addSection('bucketArray')}>Add</span>
                        </div>

                        <p className="mb-0 mr-2 align-self-end text-info mt-4">store in </p>
                        {this.state.eventsBucketArray.map((item, i) => {
                            return(
                                <div className="w-100">
                                    <div className="d-flex flex-wrap">
                                        <p className="mb-0 mr-2 align-self-end text-info mt-3">Bucket</p>
                                        <input 
                                            type="text" 
                                            className={`inputField mt-3 w-30 ${this.state.hasError && (!this.state['events_bucket_name_'+item] || this.state['events_bucket_name_'+item] === '') ? 'dottedDangerTextboxLine' : ''}`}
                                            value={this.state['events_bucket_name_'+item] ? this.state['events_bucket_name_'+item] : ''}
                                            onChange={e => this.setState({ ['events_bucket_name_'+item]: restrictCharacter(e.target.value, '/') }, () => this.onChangePropsEvent())}
                                        />
                                        <p className="mb-0 mx-2 align-self-end text-info mt-3">Prefix</p>
                                        <input 
                                            type="text" 
                                            className={`inputField mt-3 w-30`}
                                            value={this.state['events_prefix_name_'+item] ? this.state['events_prefix_name_'+item] : ''}
                                            onChange={e => this.setState({ ['events_prefix_name_'+item]: e.target.value }, () => this.onChangePropsEvent())}
                                        />
                                        {i ?
                                            <span className={`far fa-trash cursor-pointer f18 align-self-end ml-4`} onClick={() => this.removeEventsBucketSection(i)}></span>
                                        : null}
                                    </div>
                                </div>
                            )
                        })}
                                        
                        <div className={`d-flex col-sm-12 justify-content-end ${this.props.pageType.toLowerCase() === 'view' ? 'displayNone' : ''}`}>
                            <span className='btn btn-secondary' onClick={() => this.addSection('eventsBucketArray')}>Add</span>
                        </div>
                    </div>
                    {this.props.pageType !== 'create' ?
                        <div style={{ display: "inline" }} className="mr-2 align-self-end">
                            <div className="form-check">
                                <input type="checkbox"
                                    onChange={() => this.setState({ mandatory: !this.state.mandatory }, () => this.onChangePropsEvent())}
                                    className="form-check-input"
                                    checked={this.state.mandatory}
                                />
                                <p className="form-check-label mb-0 mr-2 text-info">Enable</p>
                            </div>
                        </div>
                    : null}
                </div>
            :
                <div className='text-center mt-5' >
                    <Spinner color='white' size='lg' />
                </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        dlpPropsDetails: state.dlp.dlpPropsDetails
    }
}

export default connect(mapStateToProps, {
    getInventoryProcessorBaseParams,
    insertMsc,
    updateMsc,
})(withRouter(InventoryProcessorDaily))