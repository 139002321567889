

  /*************************************************
 * Tvastar
 * @exports
 * @file SidePanel.js
 * @author Prakash // on 28/06/2020
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Chart from 'react-apexcharts'


class ApexProgressCircleChart extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    
    componentDidMount = () => {
        let series = [parseInt(this.props.value)]
        let gridPadding = {}
        if(this.props.gridPadding) {
            gridPadding = this.props.gridPadding
        }
        let track = {
            background: this.props.strokeColor,
            strokeWidth: this.props.strokeWidth ? this.props.strokeWidth : '',
            // margin: 0, // margin is in pixels
            // dropShadow: {
            //   enabled: true,
            //   top: 0,
            //   left: 0,
            //   blur: 4,
            //   opacity: 0.35
            // }
        }

        if(typeof this.props.trackMargin !== 'undefined') {
            track.margin = this.props.trackMargin // margin is in pixels
        }

        let options = {
            chart: {
                type: 'radialBar',
                sparkline: {
                    enabled: true
                },
                toolbar: {
                    show: false
                }
            },
            colors: this.props.colors,
            labels: [this.props.label],
            // stroke: {
            //     lineCap: "round"
            // },
            plotOptions: {
                radialBar: {
                    // offsetY: -20,
                    hollow: {
                        margin: 0,
                        size: this.props.size,
                        image: undefined,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        position: 'front',
                        dropShadow: {
                            enabled: true,
                            top: 3,
                            left: 0,
                            blur: 4,
                            opacity: 0.24
                        }
                        // background: "#293450"
                    },
                    track: track,
                    dataLabels: {
                        show: true,
                        name: {
                            offsetY: this.props.labeloffsetY,
                            color: "#fff",
                            fontSize: "10px"
                        },
                        value: {
                            offsetY: this.props.valueoffsetY,
                            color: "#fff",
                            formatter: function(val) {
                                return parseInt(val);
                            },
                            fontSize: "10px",
                            show: true
                        }
                    }
                }
            },
            grid: {
                padding: gridPadding,
            }  
        }
        
        this.setState({ series, options })
    }

    render() {
        return(
            this.state.series ?
				<div className={`${this.props.className}`}>
					<p className={`font-weight-bold mb-1 text-center ${this.props.showGraphHeading ? '' : 'displayNone'}`}>{this.state.graphHeading ? this.state.graphHeading : ''}</p>
					<Chart options={this.state.options} series={this.state.series} type="radialBar" height={this.props.height} width={this.props.width}/>
				</div>
            : null
        )
    }
}
 
/**
 * Type of the props used in the component
 */
ApexProgressCircleChart.propTypes = {}

const mapStateToProps = state => {}

export default connect(mapStateToProps, {})(withRouter(ApexProgressCircleChart))