/*************************************************
 * Tvastar
 * @exports
 * @file StorageDetails.js
 * @author Rajasekar // on 20/02/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class StorageDetails extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	render() {
		return (
			<div className="box p-3 h-100 bg-dark3">
				<div className="d-flex mb-2">
					<div className="flex-grow-1 text-white">
						<b>Storage Details</b>
					</div>
					<div>
						<small className="badge-outline-success badge">Enabled</small>
					</div>
				</div>
				<div className="d-flex flex-column">
					{this.props.storageSize && (
						<div className="mr-3 mb-1">
							Size <br />
							<b className="text-white">{this.props.storageSize}</b>
						</div>
					)}
					{this.props.rootDeviceName && (
						<div className="mr-3 mb-1">
							Device Type <br />
							<b className="text-white">{this.props.rootDeviceName}</b>
						</div>
					)}
					{this.props.blockDeviceMappings && (
						<div className="mr-3 mb-1">
							Details <br />
							<b className="text-white">{this.props.blockDeviceMappings}</b>
						</div>
					)}
					{this.props.blockDeviceMappingsVolume && (
						<div className="mr-3 mb-1">
							Volume Name <br />
							<b className="text-white">{this.props.blockDeviceMappingsVolume}</b>
						</div>
					)}
					{this.props.maximumStorageThreshold && (
						<div className="mr-3 mb-1">
							Threshold <br />
							<b className="text-white">{this.props.maximumStorageThreshold}</b>
						</div>
					)}
					{this.props.storageType && (
						<div className="mr-3 mb-1">
							Type <br />
							<b className="text-white">{this.props.storageType}</b>
						</div>
					)}
					{this.props.dataStorageNodes && (
						<div className="mr-3 mb-1">
							Data nodes storage type <br />
							<b className="text-white">{this.props.dataStorageNodes}</b>
						</div>
					)}
					{this.props.es_ebs_volume_size && (
						<div className="mr-3 mb-1">
							EBS volume size <br />
							<b className="text-white">{this.props.es_ebs_volume_size}</b>
						</div>
					)}
					{this.props.storageSubTypeName && (
						<div className="mr-3 mb-1">
							EBS volume type <br />
							<b className="text-white">{this.props.storageSubTypeName}</b>
						</div>
					)}
				</div>
				<div className="d-flex mt-2">
					{this.props.encyption && (
						<div className="mr-3 mb-1">
							Encryption <br />
							<b className="text-white">{this.props.encyption}</b>
						</div>
					)}
					{this.props.IOPS && (
						<div className="mr-3 mb-1">
							IOPS <br />
							<b className="text-white">{this.props.IOPS}</b>
						</div>
					)}
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
StorageDetails.propTypes = {
	storageSize: PropTypes.string,
	IOPS: PropTypes.string,
	es_ebs_volume_size: PropTypes.string,
	storageType: PropTypes.string,
	encyption: PropTypes.string,
	maximumStorageThreshold: PropTypes.string,
	blockDeviceMappingsVolume: PropTypes.string,
	blockDeviceMappings: PropTypes.string,
	rootDeviceName: PropTypes.string,
	dataStorageNodes: PropTypes.string,
	storageSubTypeName: PropTypes.string,
}

export default StorageDetails
