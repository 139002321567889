/*************************************************
 * Tvastar
 * @exports
 * @file AddEdit.js
 * @author Prakash // on 08/10/2021
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { capitalizeFirstLetter } from '../../../../utils/utility'
import {
    getSourceDataCatalogBaseParams,
    listSourceDataCatalog,
    insertSourceDataCatalog,
    updateSourceDataCatalog,
} from '../../../../actions/dlp/BucketTableAction'

import { 
	listAthenaResources
} from '../../../../actions/dlp/PoliciesAction'

import { 
    listCustomerAccountInfo
} from '../../../../actions/dlp/CustomerAccountAction'

import _ from 'lodash'

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import DropdownTreeSelect from 'react-dropdown-tree-select'
import { Spinner } from 'reactstrap'
import { store as CommonNotification } from 'react-notifications-component';

class AddEdit extends Component {
    hierarichalBucketBarChartRef = React.createRef()
    constructor(props) {
        super(props)
        this.props = props;
        this.accessByRef = React.createRef()
        this.scrolltoTop2 = React.createRef()

        this.state = {
            showLoading: true,
            pathArray: [0],
            accessByArray: [0],
            tableArray: [0],
            selectedService: 'athena',
            authorized: true,
            environments: ['Dev', 'Stage', 'Production', 'Testing'],
            options: [ 
                {label: 'General', value:'General'},
                {label: 'Sensitive', value: 'Sensitive'},
                {label: 'Compliance', value: 'Compliance'}
            ],
            dataSourceOptions: ['Database', 'Database Table'],
            selectedTagArrayContent: 'Select'
        }
    }

    componentDidMount = () => {
        this.getSourceDataCatalogBaseParams()
        this.listCustomerAccountInfo()
        this.getDataSource()
    }

    editEvents = () => {
        if(this.props.pageType === 'edit' || this.props.pageType === 'view') {
            let selectedRecord = this.props.selectedRecord
            
            let service_name = selectedRecord.selectedService
            let selectedOption = selectedRecord.option 
            let catalog_name = selectedRecord.catalog_name
            let database_name = selectedRecord.database_name
            let selectedTable = selectedRecord.table_name ? selectedRecord.table_name : []
            let selectedDataSource = !selectedTable.length ? 'Database' : 'Database Table'
            let selected_tag = selectedRecord.tags
            let authorized = typeof selectedRecord.authorized === 'undefined' ? false : selectedRecord.authorized
            let selectedEnvironment = selectedRecord.environment
            let selectedProjects = selectedRecord.projects
            let selectedApplications = selectedRecord.applications

            let array = []
            selectedRecord.can_access_by.forEach(item  => {
                if(item.access_type.length === 1) {
                    item.type = item.access_type[0]
                } else {
                    item.type = 'read_write'
                }
                array.push(item)
            })
            
            let groups = _.groupBy(array, 'type');

            let selectedAccount = []

            let accessByArray = []
            Object.entries(groups).forEach(([key, value], index) => {
                accessByArray.push(index)
                let read = false
                let write = false
                let array = []
                value.forEach(acc => {
                    let dataRow = {}
                    dataRow.account_id = acc.account_id
                    dataRow.canonical_id = acc.canonical_id
                    
                    selectedAccount.push(acc.account_id)

                    array.push(dataRow)
                    
                    if(key === 'read') {
                        read = true
                    } else if(key === 'write') {
                        write = true
                    } else {                        
                        read = true
                        write = true
                    }
                })

                this.setState({ ['selectedAccessBy_'+index]: array, ['read_'+index]: read, ['write_'+index]: write })
            })

            let pathArray = []
            selectedRecord.data_lake_prperties.paths.forEach((item, index) => {
                pathArray.push(index)
                this.setState({ ['path_'+index]: item.path })
            })

            if(selected_tag.length) {
                this.onLoadContains(selected_tag)
            }

            let selectedTypeArray = this.state.types.filter(item => selectedRecord.type.includes(item.value))
            
            this.setState({ service_name, selectedTypeArray: selectedTypeArray[0], selectedEnvironment, selectedProjects, selectedApplications, authorized, selectedOption, catalog_name, database_name, selectedTable, selectedDataSource, selected_tag, accessByArray, pathArray, selectedAccount },
                () => this.getDataSource()
            )
        }
    }

	getDataSource = () => {
        let params = {}
        this.props.listAthenaResources(params, (promise, response) => {
            if(promise) {
                this.setState({ catalogs: response, loadingDataSource: false, disablePage: false },
                    () => {
                        if(this.state.catalog_name !== '') {
                            this.getDatabaseList()
                        }
                    }    
                )
            }
        })
	}

    getDatabaseList = () => {
		let params = {}
		params.catalog_name = this.state.catalog_name
		this.props.listAthenaResources(params, (promise, response) => {
			if(promise) {
				this.setState({ database: response, loadingDatabase: false, disablePage: false },
                    () => {   
                        if(this.state.database_name !== '' && this.state.selectedDataSource === 'Database Table') {
                            this.getTables()
                        }
                    }
                )
			}
		})
	}

	getTables = () => {
		let params = {}
		params.catalog_name = this.state.catalog_name
		params.database_name = this.state.database_name
		this.props.listAthenaResources(params, (promise, response) => {
			this.setState({ tables: response, loadingTables: false, disablePage: false })
		})
	}

    listCustomerAccountInfo = () => {
        if(!this.props.dlpCustomerAccountInfoList || !this.props.dlpCustomerAccountInfoList.length) {
            let params = {}
            this.props.listCustomerAccountInfo(params, (promise, response) => {
                if(promise) {
                    this.formAccountArray(response)
                }
            })
        } else{
            this.formAccountArray(this.props.dlpCustomerAccountInfoList)
        }
    }

    formAccountArray = (accountList) => {
        let masterAccounts = []
        accountList.forEach(item => {
            item.accounts && item.accounts.length && item.accounts.forEach(acc => {
                masterAccounts.push(acc)
            })
        })

        this.setState({ masterAccounts, accounts: masterAccounts })
    }

    getSourceDataCatalogBaseParams = () => {
        let params = {}
        this.props.getSourceDataCatalogBaseParams(params, (promise, response) => {
            if(promise) {
                this.setState({ masterData: response, showLoading: false },
                    () => {
                        this.formData()                        
                    }
                )
            }
        })
    }

    formData = () => {
        let types = []
        this.state.masterData.type && Object.entries(this.state.masterData.type).forEach(([key, value]) => {
            let dataRow = {}
            dataRow.value = key
            dataRow.label = value
            types.push(dataRow)
        })

        let totalContainsTag = 0
        this.state.masterData.tags && Object.values(this.state.masterData.tags).forEach(item => {
            Object.entries(item).forEach(([key, value]) => {
                totalContainsTag++
            })
        })
        
        let contains = []
        this.state.masterData.tags && Object.entries(this.state.masterData.tags).forEach(([tagkey, tagValue], index) => {
            if(!index) {
                let dataRow = {}
                dataRow.label = 'All'
                dataRow.value = 'All'
                dataRow.expanded = true
                dataRow.children = []
                contains.push(dataRow)
            }
            let dataRow = {}
            dataRow.label = capitalizeFirstLetter(tagkey)
            dataRow.value = tagkey
            dataRow.expanded = true
            let childArray = []
            Object.entries(tagValue).forEach(([key, value]) => {
                let dataItem = {}
                dataItem.label = value
                dataItem.value = key
                dataItem.expanded = true
                childArray.push(dataItem)
            })
            dataRow.children = childArray
            contains.push(dataRow)
        })

        this.setState({ types, contains, masterContains: contains, totalContainsTag },
            () => {
                this.editEvents()
                this.scrolltoTop2.current.scrollIntoView()
                let scroll = document.getElementById("scrollTop")
                scroll.scrollIntoView({ behavior: 'smooth' })   
            }
        )
    }

    listSourceDataCatalog = () => {
        let params = {}
        this.props.listSourceDataCatalog(params, (promise, response) => {})
    }

    removeSelectedOption = (field, value) => {
        let selectedAccount = this.state.selectedAccount.filter(acc => acc !== value)

        let filteredReslt = this.state[field].filter(e => e.account_id !== value)
        this.setState({ [field]: filteredReslt, selectedAccount },
            () => this.hideSuggetionDropdown()    
        )
    }

    actionPorcess = () => {
        let hasError = false

        let errorMessage = ''
        if(!this.state.selectedTypeArray) {
            hasError = true
            errorMessage = 'Please select type'
        }
                
        if(!this.state.selectedOption) {
            hasError = true
            errorMessage = 'Please select option'
        }

        if(!this.state.dataSourceOptions) {
            hasError = true
            errorMessage = 'Please enter catalog name'
        }
        

        if(!this.state.catalog_name) {
            hasError = true
            errorMessage = 'Please enter catalog name'
        }

        if(!this.state.database_name) {
            hasError = true
            errorMessage = 'Please enter database name'
        }

        if(!this.state.selectedTagArray) {
            hasError = true
            errorMessage = 'Please select contains'
        }

        let accessByExist = 0
        this.state.accessByArray.forEach(item => {
            if((!this.state['selectedAccessBy_'+item] || !this.state['selectedAccessBy_'+item].length) && (this.state['read_'+item] || this.state['write_'+item])) {
                hasError = true
                errorMessage = 'Please select access by'
            } else if((this.state['selectedAccessBy_'+item] && this.state['selectedAccessBy_'+item].length) && (!this.state['read_'+item] && !this.state['write_'+item])) {
                hasError = true
                errorMessage = 'Please select read write'
            } else if(this.state['selectedAccessBy_'+item] && this.state['selectedAccessBy_'+item].length && (this.state['read_'+item] || this.state['write_'+item])) {
                accessByExist++
            }
        })

        if(!accessByExist) {
            hasError = true
            errorMessage = 'Please select access 2'
        }

        let pathsExist = 0
        this.state.pathArray.forEach(item => {
            if(!pathsExist && (!this.state['path_'+item] || this.state['path_'+item] === '')) {
                hasError = true
                errorMessage = 'Please select path by'
            } else if(this.state['path_'+item] !== '') {
                pathsExist++
            }
        })

        if(!pathsExist) {
            hasError = true
            errorMessage = 'Please select path'
        }      
        errorMessage = ''  
        this.setState({ hasError, errorMessage, showActionLoading: !hasError, accessByExist, pathsExist })

        if(!hasError) {
            let params = {}
            params.service_name = this.state.selectedService
            params.type = this.state.selectedTypeArray.value
            params.environment = this.state.selectedEnvironment
            params.projects = this.state.selectedProjects
            params.applications = this.state.selectedApplications
            params.authorized = this.state.authorized
            params.option = this.state.selectedOption
            params.catalog_name = this.state.catalog_name
            params.database_name = this.state.database_name ? this.state.database_name : ''
            params.table_name = this.state.selectedTable ? this.state.selectedTable : []
            params.tags = this.state.selected_tag

            let table_text = ' Service '+this.state.selectedService+' type'+this.state.selectedTypeArray.value+' bucket information for'+this.state.selectedOption+' Data source'+this.state.catalog_name
            
            if(params.database_name !== '') {
                table_text += ' Database'+params.database_name
            }

            // if(params.table_name !== '') {
            //     table_text += ' Database'+params.table_name
            // }

            if(params.bucket_prefix !== '') {
                table_text += ' prefix '+this.state.bucket_prefix
            }

            let accessBy = []
            this.state.accessByArray.forEach(item => {
                this.state['selectedAccessBy_'+item].forEach(acc => {
                    let dataRow = {}
                    dataRow.account_id = acc.account_id
                    dataRow.canonical_id = acc.canonical_id
                    
                    let accessType = []
                    if(this.state['read_'+item]) {
                        accessType.push('read')
                    }
                    if(this.state['write_'+item]) {
                        accessType.push('write')
                    }

                    dataRow.access_type = accessType
                    accessBy.push(dataRow)
                })
            })
            params.can_access_by = accessBy

            let paths = []
            this.state.pathArray.forEach(item => {
                let row = {}
                row.path = this.state['path_'+item]
                paths.push(row)
            })

            params.data_lake_prperties =  { 
                paths: paths
            }
            params.bucket_text = table_text

            if(this.props.pageType === 'create') {
                this.props.insertSourceDataCatalog(params, (promise, response) => {
                    if(promise) {                        
                        let messageType = 'danger'		
                        let message = 'Check the data'
                        if(response.status && response.status.message && response.status.message === 'inserted') {
                            messageType = 'success'
                            message = 'Saved Successfully'
                        }
                        CommonNotification.addNotification({
                            message: message,
                            type: messageType,
                            insert: "top",
                            container: "top-center",
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
                        
                        if(response.status && response.status.message && response.status.message === 'inserted') {
                            this.setState({ showActionCompleted: true },
                                () => {
                                    setTimeout(() => { this.listSourceDataCatalog() }, 3000)
                                    setTimeout(() => { this.props.showListPage() }, 4000)
                                }
                            )
                        } else {
                            this.setState({ showActionLoading: false })
                        }
                    } else {
                        this.setState({ showActionLoading: false })
                    }
                })
            } else if(this.props.pageType === 'edit') {
                params.doc_id = this.props.selectedRecord.doc_id
                this.props.updateSourceDataCatalog(params, (promise, response) => {
                    if(promise) {						
                        let messageType = 'danger'		
                        let message = 'Check the data'
                        if(response.status && response.status.message && response.status.message === 'updated') {
                            messageType = 'success'
                            message = 'Updated Successfully'
                        }
                        CommonNotification.addNotification({
                            message: message,
                            type: messageType,
                            insert: "top",
                            container: "top-center",
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
                        
                        if(response.status && response.status.message && response.status.message === 'updated') {
                            this.setState({ showActionCompleted: true },
                                () => {
                                    setTimeout(() => { this.listSourceDataCatalog() }, 3000)
                                    setTimeout(() => { this.props.showListPage() }, 4000)
                                }
                            )
                        } else {
                            this.setState({ showActionLoading: false })
                        }
                    } else {
                        this.setState({ showActionLoading: false })
                    }
                })
            }
        }
    }

    onKeyDown = (e, field, stateField) => {
        let array = this.state[stateField] ? this.state[stateField] : []
		if (e.keyCode === 13 || e.keyCode === 9) {
            if(this.state[field] && this.state[field] !== '') {
                array.push(this.state[field].trim())
                if(e.keyCode === 9) {
                    e.preventDefault();
                }
                this.setState({ [stateField]: array, [field]: '' })
            }
        }
    }

    onBlur = (e, field, stateField) => {
        let array = this.state[stateField] ? this.state[stateField] : []
        if(this.state[field] && this.state[field] !== '') {
            array.push(this.state[field].trim())
            this.setState({ [stateField]: array, [field]: '' })
        }
    }

    addSection = (array) => {
        let rowList = this.state[array];
        if(this.state[array]) {
            let value = this.state[array][this.state[array].length - 1]
            value = value+1
            rowList.push(value);
        }
        
        this.setState({[array]: rowList })
    }

    removeSection = (item, i) => {
        let accounts = this.state.accounts
        if(this.state['selectedAccessBy_'+item] && this.state['selectedAccessBy_'+item].length) {
            accounts = this.state.accounts.concat(this.state['selectedAccessBy_'+item])
        }
        this.setState({ ['selectedAccessBy_'+item]: [], ['access_by_'+item]: '', ['read_'+item]: false, ['write_'+item]: false, accounts },
            () => {
                let rowList = this.state.accessByArray;
                rowList.splice(i, 1);
                this.setState({ accessByArray: rowList })
            }
        );
    }

    addAccessSection = (array) => {
        let rowList = this.state[array];
        if(this.state[array]) {
            let value = this.state[array][this.state[array].length - 1]
            value = value+1
            rowList.push(value);
        }
        rowList.forEach(itm => {
            this.setState({ ['showDropdown_'+itm]: false })
        })
        
        this.setState({[array]: rowList })
    }   
    
    removeAccessSection = (item, i) => {
        let accounts = this.state.accounts
        if(this.state['selectedAccessBy_'+item] && this.state['selectedAccessBy_'+item].length) {
            accounts = this.state.accounts.concat(this.state['selectedAccessBy_'+item])
        }
        this.setState({ ['selectedAccessBy_'+item]: [], ['access_by_'+item]: '', ['read_'+item]: false, ['write_'+item]: false, accounts },
            () => {
                let rowList = this.state.accessByArray;
                rowList.splice(i, 1);
                this.setState({ accessByArray: rowList })
                rowList.forEach(itm => {
                    this.setState({ ['showDropdown_'+itm]: false })
            
                })
            }
        );
    }

    removePathSection = (item, i) => {
        this.setState({ ['path_'+item]: '' },
            () => {
                let rowList = this.state.pathArray;
                rowList.splice(i, 1);
                this.setState({ pathArray: rowList })
            }
        );
    }

    getSearchMetaResults = (index) => {
        /**
         * Handles search using the search text
         */
        let currentList = []
        let newList = this.state.accounts

        if(this.state.selectedAccount && this.state.selectedAccount.length) {
            this.state.selectedAccount.forEach(res => {
                newList = newList.filter(acc => acc.account_id !== res)
            })
        }
        if (this.state['access_by_'+index] && this.state['access_by_'+index] !== '') {
            currentList = newList
            newList =
                currentList &&
                currentList.filter(item => {
                    let isPresent = []
                    isPresent = this.recursiveSearch(item, this.state['access_by_'+index])
                        .flat()
                        .filter(bool => bool === true)
                    if (isPresent[0]) {
                        return true
                    } else {
                        return false
                    }
                })
        }

        this.state.accessByArray.forEach(item => {
            this.setState({ ['showDropdown_'+item]: item === index ? true : false })
        })

        this.setState({ ['searchResultState_'+index]: newList })
    }

    // function to search every object in array by recurseive
    recursiveSearch = (item, searchValue) => {
        return Object.keys(item).map(key => {
            if (item[key] !== null && typeof item[key] == 'object') {
                return this.recursiveSearch(item[key], searchValue)
                // eslint-disable-next-line valid-typeof
            } else if (typeof item[key] === 'array') {
                return item.map((arrayElement, i) => this.recursiveSearch(arrayElement[i], searchValue))
            } else {
                // if(this.props.searchFilter.includes(key)){
                return item[key] !== null
                    ? item[key]
                            .toString()
                            .toLowerCase()
                            .indexOf(searchValue.toString().toLowerCase()) > -1
                    : false
                // }else{
                // return false;
                // }
            }
        })
    }

    accessBySelection = (res, index) => {
        let selectedAccount = this.state.selectedAccount
        selectedAccount.push(res.account_id)

        let array = this.state['selectedAccessBy_'+index] ? this.state['selectedAccessBy_'+index] : []
        
        array.push(res)
        this.setState({ 
            ['selectedAccessBy_'+index]: array,
            selectedAccount 
        },
            () => this.getSearchMetaResults(index)
        )
    }    

    handleMultiSelectChange = (field, choosen) => {
        let selectedValue = []
		if(field === 'selectedOption') {
			let value = choosen.map(item => item.value)
            let prevState = this.state[field] ? this.state[field] : []

            if(value.includes('General')) {
                if(!prevState.includes('General')) {
                    this.state.options.forEach(opt => {
                        if(opt.value === 'General') {
                            selectedValue.push(opt.value)
                        }
                    })
                } else {
                    const index = value.indexOf('General');
                    if (index > -1) {
                        value.splice(index, 1);
                    }
                    selectedValue = value
                }
            } else if(!prevState.includes('General')) {
                selectedValue = value
            }

            let newOptions = []
            if(selectedValue.includes('General')) {
                this.state.options.forEach(opt => {
                    if(opt.value !== 'General') {
                        opt.isdisabled = true
                    } else {
                        opt.isdisabled = false
                    }
                    newOptions.push(opt)
                })
            } else if(selectedValue.length && !selectedValue.includes('General')) {
                this.state.options.forEach(opt => {
                    if(opt.value === 'General') {
                        opt.isdisabled = true
                    } else {
                        opt.isdisabled = false
                    }
                    newOptions.push(opt)
                })
            } else if(!selectedValue.length) {
                this.state.options.forEach(opt => {
                    opt.isdisabled = false
                    newOptions.push(opt)
                })
            }
			this.setState({ [field]: selectedValue, options: newOptions })
		} else if(field === 'selectedTable') {
			let value = choosen.map(item => item.value)
			this.setState({ [field]: value })
		}
	}
    handleClickOutside(event) {            
        if(this.accessByRef && !this.accessByRef.current.contains(event.target)) {
            this.state.accessByArray.forEach(item => {
                this.setState({ ['showDropdown_'+item]: false })
            })
        }
    }

    removeSelectedBadge = (field, value) => {
        let filteredReslt = this.state[field].filter(e => e !== value)
        this.setState({ [field]: filteredReslt })
    }

    onLoadContains  = (selectedTags) => {
		let contains = []
		let selectedTagArray = []
        if(this.state.totalContainsTag !== selectedTags.length) {
			this.state.contains.forEach(item => {
				let dataRow = {}
				dataRow.label = item.label
				dataRow.expanded = true               
				let childArray = []
                let seletedChildLength = 0
				item.children && item.children.forEach(child => {
					let childRow = {}
					childRow.label = child.label
					childRow.value = child.value      
                    if(selectedTags.includes(child.value)) {
                        seletedChildLength++
                        childRow.expanded = true
                        childRow.checked = true
                        selectedTagArray.push(childRow.label)
                    }
					childArray.push(childRow)
					dataRow.children = childArray
				})
                if(item.children && seletedChildLength && seletedChildLength === item.children.length) {
                    dataRow.checked = true  
                }
				contains.push(dataRow)
			})
		} else {
            this.state.contains.forEach(item => {
                let dataRow = {}
                dataRow.label = item.label
                dataRow.expanded = true
                dataRow.checked = true

                let childArray = []
                item.children && item.children.forEach(child => {
                    let childRow = {}
                    childRow.label = child.label
                    childRow.value = child.value
                    childRow.expanded = true
                    childRow.parent = 1
                    childRow.checked = true
                    selectedTagArray.push(childRow.label)
                    childArray.push(childRow)
                    dataRow.children = childArray					
                })
                contains.push(dataRow)
            })
        }

        if(!selectedTagArray.length) {
            contains = this.state.masterContains
        }

		this.setState({ contains, selectedTagArray: this.state.totalContainsTag !== selectedTags.length ? selectedTagArray : ['All'] })
	}

    onChangeContains  = (currentNode, selectedNodes) => {
		// console.log('onChange::', currentNode)
		// console.log('selectedNodes::', selectedNodes)	
		let contains = []	
		let selectedTreeContains = []
		let selectedTagArray = []
        let selected_tag = []
		if(selectedNodes.length && currentNode.label !== 'All') {
			selectedNodes.forEach(item => {
				if(item._depth === 0) {
					let child = {}
					child.label = item.label
                    child.value = item.value
					child.parent = 1
					selectedTreeContains.push(child)
					this.state.contains.forEach(cont => {
						if(cont.label === item.label) {
							cont.children && cont.children.forEach(child => {
								selectedTagArray.push(child.label)
                                selected_tag.push(child.value)
							})
						}
					})
				} else {
					let child = {}
					child.label = item.label
                    child.value = item.value
					child.parent = 0
					selectedTreeContains.push(child)
					selectedTagArray.push(child.label)
                    selected_tag.push(child.value)
				}
			})            

			contains = []
			this.state.contains.forEach(item => {
				let dataRow = {}
				dataRow.label = item.label
				dataRow.expanded = true

				let childArray = []
				item.children && item.children.forEach(child => {
					let childRow = {}
					childRow.label = child.label
					childRow.value = child.value
					childRow.expanded = true
					if(selectedTreeContains.length) {					
						selectedTreeContains.forEach(ser => {
							if(ser.parent === 1 && item.label === ser.label) {
								dataRow.checked = true
							}						
							if(ser.parent === 1 && item.label === ser.label) {
								childRow.checked = true
							} else if(ser.parent === 0 && ser.label === child.label){
								childRow.checked = true
							}			
						})
					}
					childArray.push(childRow)
					dataRow.children = childArray
				})		
				
				if((item.label === 'All' && currentNode.label === 'All') || this.state.totalContainsTag === selectedTagArray.length) {
					dataRow.checked = true
				}
	
				contains.push(dataRow)
			})
		} else if(currentNode.label === 'All') {
			selectedTreeContains= []
			selectedTagArray = []
            selected_tag = []
			contains = []
			this.state.contains.forEach(item => {
				let dataRow = {}
				dataRow.label = item.label
				dataRow.expanded = true
				if(currentNode.checked) {
					dataRow.checked = true
				} else if(!currentNode.checked) {
					dataRow.checked = false
				}

				let childArray = []
				item.children && item.children.forEach(child => {
					let childRow = {}
					childRow.label = child.label
					childRow.value = child.value
					childRow.expanded = true
					childRow.parent = 1
					if(currentNode.checked) {
						childRow.checked = true
						selectedTagArray.push(childRow.label)
                        selected_tag.push(childRow.value)
					} else if(!currentNode.checked) {
						childRow.checked = false
					}
					childArray.push(childRow)
					selectedTreeContains.push(childRow)
					dataRow.children = childArray					
				})
				contains.push(dataRow)
			})
        }

        if(!selectedTagArray.length) {
            contains = this.state.masterContains
        }

		// let selectedTagArrayContent = selectedTagArray.length === 1 ? selected_tag : selectedTagArray.length > 2 ? selectedTagArray.length +' Selected' : 'All'
		this.setState({ contains, selectedTagArray: this.state.totalContainsTag !== selectedTagArray.length ? selectedTagArray : ['All'], selected_tag })
	}

    hideSuggetionDropdown = () => {
        this.state.accessByArray.forEach(itm => {
            this.setState({ ['showDropdown_'+itm]: false })
    
        })
    }

    render() {		
        return (    
            <div className="" id="scrollTop" ref={this.scrolltoTop2}>
                {!this.state.showLoading ?
                    <div className={`mt-3 rounded ${(this.state.showActionLoading) ? 'disabled' : ''}`} onClick={(event) => this.handleClickOutside(event)}>
                        <div className="row"> 
                            <div className="col-sm-12">
                                <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-normal">{(this.props.pageType !== 'create' ? capitalizeFirstLetter(this.props.pageType) : 'Add') +' Table'}</h6>
                                    <i className="fas fa-arrow-left cursorPointer" onClick={() => this.props.showListPage()}> back</i>
                                    {/* <button className='btn btn-sm btn-light mr-1' onClick={() => this.props.showListPage()}>Cancel</button> */}
                                </div>
                                <p>Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim.</p>
                            </div>
                        </div>
                        <div className={`dlpDescriptionFormWhite ${this.props.pageType === 'view' ? 'disabled disableInputColor' : ''}`}>
                            <div className="d-flex flex-wrap">
                                <p className="mb-0 mr-2 align-self-end text-purple">Please input meta data information for the Amazon Athena</p>
                                <Select
                                    placeholder={'Select'}
                                    isSearchable={false}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    className={`selectOption w-10 mt-2 ${this.state.hasError && !this.state.dataSourceOptions ? 'dottedDangerSelectLine' : ''}`}
                                    value={({
                                        value: this.state.selectedDataSource,
                                        label: this.state.selectedDataSource ? this.state.selectedDataSource : 'Select',
                                    })}
                                    options={this.state.dataSourceOptions.map(item => ({
                                        value: item,
                                        label: item,	
                                    }))}
                                    onChange={event => this.setState({ selectedDataSource: event.value } )}
                                />

                                {this.state.selectedDataSource ?
                                    <React.Fragment>
                                        <p className="mb-0 mx-2 align-self-end text-purple mt-2">
                                            {this.state.loadingDataSource ? <Spinner size='sm' className='mx-2' color='dark' /> : ''} 
                                            Data Soruce
                                        </p>
                                        <Select
                                            // menuIsOpen={true}
                                            placeholder={'Select'}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            isSearchable={false}
                                            className={`selectOption w-10 mt-2 ${this.state.hasError && !this.state.dataSourceOptions ? 'dottedDangerSelectLine' : ''}`}
                                            value={({
                                                value: this.state.catalog_name ? this.state.catalog_name : 'Select',
                                                label: this.state.catalog_name ? this.state.catalog_name : 'Select'
                                            })}
                                            options={this.state.catalogs && this.state.catalogs.map(cat => ({
                                                value: cat,
                                                label: cat,
                                            }))}
                                            onChange={event =>  
                                                this.setState({ catalog_name: event.value, loadingDatabase: true, disablePage: true }, () => this.getDatabaseList() )
                                            }
                                        />
                                        <p className="mb-0 mx-2 align-self-end text-purple mt-2">
                                            {this.state.loadingDatabase ? <Spinner size='sm' className='mx-2' color='dark' /> : ''}
                                            database
                                        </p>
                                        <Select
                                            // menuIsOpen={true}
                                            placeholder={'Select'}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            isSearchable={true}
                                            className={`selectOption mr-2 mt-2 w-30`}
                                            value={({
                                                value: this.state.database_name ? this.state.database_name : 'Select',
                                                label: this.state.database_name ? this.state.database_name : 'Select'
                                            })}
                                            options={this.state.database && this.state.database.map(dbs => ({
                                                value: dbs,
                                                label: dbs,
                                            }))}
                                            onChange={event =>  
                                                this.setState({ database_name: event.value, loadingTables: true, disablePage: true }, () => this.getTables() )
                                            }
                                        />
                                        {this.state.selectedDataSource === 'Database Table' ?
                                            <React.Fragment>
                                                <p className="mb-0 mr-2 align-self-end text-purple mt-2">
                                                {this.state.loadingTables ? <Spinner size='sm' className='mx-2' color='dark' /> : ''}
                                                    Tables
                                                </p>
                                                {this.state.selectedTable && this.state.selectedTable.map((value, index) => {
                                                    return(
                                                    <span key={index} className="badge-square badge-secondary f13 align-self-end p-1 mr-2" >
                                                        {value}
                                                        <i onClick={ () => this.removeSelectedOption('selectedTable', value) } className='ml-1 fal fa-times cursorPointer'></i>
                                                    </span>
                                                    )
                                                })}
                                                <div className="multiSelectOption align-self-end mt-2">
                                                    <ReactMultiSelectCheckboxes						
                                                        placeholderButtonLabel="ALL"
                                                        isSearchable={false}
                                                        options={this.state.tables && this.state.tables.map(tbt => ({
                                                            value: tbt,
                                                            label: tbt,
                                                        }))}														
                                                        onChange={event => {
                                                            this.handleMultiSelectChange('selectedTable', event) }
                                                        }
                                                        value={this.state.selectedTable && this.state.selectedTable.map(tbt => ({
                                                            value: tbt,
                                                            label: tbt
                                                        }))}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        : null}
                                    </React.Fragment>
                                : null}                            
                            </div>

                            <div className="d-flex flex-wrap mt-3">
                                <p className="mb-0 mr-2 align-self-end text-purple">This Amazon Athena {this.state.selectedDataSource ? this.state.selectedDataSource : ''} is</p>
                                <div style={{ display: "inline" }} className="align-self-end">
                                    <div className="form-check">
                                        <input type="radio"
                                            onChange={() => this.setState({ authorized: true }) }
                                            className="form-check-input"
                                            checked={this.state.authorized}
                                        />
                                        <p className="form-check-label mb-0 mr-2 text-purple">Authorized</p>
                                    </div>
                                </div>
                                <div style={{ display: "inline" }} className="ml-2 align-self-end">
                                    <div className="form-check">
                                        <input type="radio"
                                            onChange={() => this.setState({ authorized: false }) }
                                            className="form-check-input"
                                            checked={!this.state.authorized}
                                        />
                                        <p className="form-check-label mb-0 text-purple">Unauthorized</p>
                                    </div>
                                </div>
                            <p className="mb-0 mx-2 align-self-end text-purple">to hold</p>
                            {this.state.selectedOption && this.state.selectedOption.length ?
                                <div className=" d-flex flex-wrap mr-2 text-dark align-self-end">
                                    ({this.state.selectedOption.join(', ')})
                                </div>
                            : null}
                            <div className={`multiSelectOption align-self-end removeDropdownSearchBar ${this.state.hasError && (!this.state.selectedOption || !this.state.selectedOption.length) ? 'dottedDangerMultiSelectLine' : ''}`}>
                                    <ReactMultiSelectCheckboxes						
                                        placeholderButtonLabel="Select"
                                        options={this.state.options.map(opt => ({
                                            value: opt.value,
                                            label: opt.label
                                        }))}
                                        isOptionDisabled={(option) => option.isdisabled}
                                        onChange={event => {
                                            this.handleMultiSelectChange('selectedOption', event) }
                                        }
                                        // getDropdownButtonLabel={() => this.getMultiSelectedCount('account', this.state.selectedAccount)}
                                        value={this.state.selectedOption && this.state.selectedOption.map(item => ({
                                            value: item,
                                            label: item
                                        }))}
                                    />
                                </div>
                                <p className="mb-0 mx-2 align-self-end text-purple">data.</p>
                            </div>                        
                            
                            <div className="d-flex flex-wrap mt-4">
                                <p className="mb-0 mr-2 align-self-end text-purple">This Amazon Athena {this.state.selectedDataSource ? this.state.selectedDataSource : ''} will hold </p>
                                <Select
                                    placeholder={'Select'}
                                    isSearchable={false}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    className={`selectOption w-15 ${this.state.hasError && !this.state.selectedTypeArray ? 'dottedDangerSelectLine' : ''}`}
                                    value={({
                                        value: this.state.selectedTypeArray ? this.state.selectedTypeArray.value : 'Select',
                                        label: this.state.selectedTypeArray ? this.state.selectedTypeArray.label : 'Select'
                                    })}
                                    options={this.state.types && this.state.types.map(item => ({
                                        value: item.value,
                                        label: item.label,	
                                    }))}
                                    onChange={event => this.setState({ selectedTypeArray: event } )}
                                />
                                <p className="mb-0 mx-2 align-self-end text-purple">data with files like</p>
                                {this.state.selectedTagArray && this.state.selectedTagArray.length ?
                                    <p className="mb-0 mr-2 text-dark align-self-end">
                                        ({this.state.selectedTagArray.join(', ')})
                                    </p>
                                : null}                            
                                <div className={`serviceTreeDropdown`}>
                                    <DropdownTreeSelect 
                                        texts={{ placeholder: this.state.selectedTagArrayContent }}
                                        data={this.state.contains ? this.state.contains : []}
                                        onChange={this.onChangeContains}
                                        className="bootstrap-demo"
                                        keepTreeOnSearch={true}
                                        keepChildrenOnSearch={true}
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-wrap mt-4">
                                <p className="mb-0 mr-2 align-self-end text-purple">This Amazon Athena {this.state.selectedDataSource ? this.state.selectedDataSource : ''} can be accessed from following environments</p>
                                <Select
                                    placeholder={'Select'}
                                    isSearchable={false}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    className={`selectOption w-15 ${this.state.hasError && !this.state.selectedTypeArray ? 'dottedDangerSelectLine' : ''}`}
                                    value={({
                                        value: this.state.selectedEnvironment ? this.state.selectedEnvironment : 'Select',
                                        label: this.state.selectedEnvironment ? this.state.selectedEnvironment : 'Select'
                                    })}
                                    options={this.state.environments && this.state.environments.map(item => ({
                                        value: item,
                                        label: item,	
                                    }))}
                                    onChange={event => this.setState({ selectedEnvironment: event.value } )}
                                />
                            </div>
                            <div className="d-flex flex-wrap mt-4">
                                <p className="mb-0 mr-2 align-self-end text-purple">This Amazon Athena {this.state.selectedDataSource ? this.state.selectedDataSource : ''} can be accessed from following Projects </p>
                                <div className="d-flex flex-wrap">
                                    {this.state.selectedProjects && this.state.selectedProjects.map((tab, index) => {
                                        return(
                                        <span key={index} className="badge-square badge-secondary f13 align-self-center p-1 mx-1 mb-1" >
                                        {tab}
                                            <i onClick={ () => this.removeSelectedBadge('selectedProjects', tab) } className='ml-1 fal fa-times cursorPointer'></i>
                                        </span>
                                        )
                                    })}
                                </div>
                                <input 
                                    type="text" 
                                    className={`inputField mt-3 w-20`}
                                    placeholder="project name"
                                    value={this.state.project_name ? this.state.project_name : ''}
                                    onChange={e => this.setState({ project_name: e.target.value })}
                                    onKeyDown={e => this.onKeyDown(e, 'project_name', 'selectedProjects')}
                                    onBlur={e => this.onBlur(e, 'project_name', 'selectedProjects')}
                                />
                            </div>

                            <div className="d-flex flex-wrap mt-4">
                                <p className="mb-0 mr-2 align-self-end text-purple">This Amazon Athena {this.state.selectedDataSource ? this.state.selectedDataSource : ''} can be accessed from following Applications </p>
                                <div className="d-flex flex-wrap">
                                    {this.state.selectedApplications && this.state.selectedApplications.map((tab, index) => {
                                        return(
                                        <span key={index} className="badge-square badge-secondary f13 align-self-center p-1 mx-1 mb-1" >
                                        {tab}
                                            <i onClick={ () => this.removeSelectedBadge('selectedApplications', tab) } className='ml-1 fal fa-times cursorPointer'></i>
                                        </span>
                                        )
                                    })}
                                </div>
                                <input 
                                    type="text" 
                                    className={`inputField mt-3 w-20`}
                                    placeholder="applicaiton name"
                                    value={this.state.application_name ? this.state.application_name : ''}
                                    onChange={e => this.setState({ application_name: e.target.value })}
                                    onKeyDown={e => this.onKeyDown(e, 'application_name', 'selectedApplications')}
                                    onBlur={e => this.onBlur(e, 'application_name', 'selectedApplications')}
                                />
                            </div>
                        
                            <p className="mb-0 mr-2 align-self-end text-purple mt-4">This Amazon Athena {this.state.selectedDataSource ? this.state.selectedDataSource : ''} can be accessed by following Account ID / Canonical ID </p>
                            <div className="" ref={this.accessByRef} >
                                {this.state.accessByArray.map((item, i) => {
                                    return(
                                        <div className="d-flex flex-wrap mr-3">
                                            {this.state['selectedAccessBy_'+item] && this.state['selectedAccessBy_'+item].length ?
                                                <div className=" d-flex flex-wrap maxWidth40Percent mr-2">
                                                    {this.state['selectedAccessBy_'+item].map((acceBy, index) => {
                                                        return(
                                                        <span key={index} className="badge-square badge-secondary f13 align-self-center p-1 mx-1 mb-1" >
                                                        {acceBy.account_id}
                                                            <i onClick={ () => this.removeSelectedOption('selectedAccessBy_'+item, acceBy.account_id) } className='ml-1 fal fa-times cursorPointer'></i>
                                                        </span>
                                                        )
                                                    })}
                                                </div>
                                            : null}
                                            <div className="input-group search-drop w-30">
                                                <input 
                                                    type="text" 
                                                    className={`inputField mt-3 w-100 
                                                        ${this.state.hasError && (!this.state.accessByExist || ((!this.state['selectedAccessBy_'+item] || !this.state['selectedAccessBy_'+item].length) && (this.state['read_'+item] || this.state['read_'+item]))) ? 'dottedDangerTextboxLine' : '' }
                                                    `}
                                                    value={this.state['access_by_'+item] ? this.state['access_by_'+item] : ''}
                                                    onClick={() =>  this.getSearchMetaResults(item)}
                                                    onChange={e => this.setState({ ['access_by_'+item]: e.target.value, ['searchResultState_'+item]: [] }, 
                                                            () => this.getSearchMetaResults(item)
                                                        )
                                                    }
                                                    // onKeyDown={e => this.onKeyDown(e, 'access_by_'+item, 'selectedAccessBy')}
                                                    placeholder="Search account Id / canonical Id"
                                                />
                                                
                                                <div className={`search-suggestion`}>
                                                    {this.state['showDropdown_'+item] && 
                                                        this.state['searchResultState_'+item] && this.state['searchResultState_'+item].length ? 
                                                        this.state['searchResultState_'+item].map((res, index) => {
                                                            return (
                                                                <div key={index} className="d-flex justify-content-between cursorPointer bg-white2 border-bottom-gray3 b p-2"
                                                                    onClick ={() => this.accessBySelection(res, item)}
                                                                >
                                                                    <p className='font-weight-bold m-0 text-dark text-left p-0'>
                                                                        {res.account_id + ' '+ res.canonical_id}
                                                                    </p>
                                                                </div>
                                                            )
                                                        })
                                                    : 
                                                        this.state['showDropdown_'+item] && this.state.isLoading && (
                                                            <div className='d-flex justify-content-center m-4'>
                                                                <Spinner className='text-center' color='dark' size='sm' />
                                                            </div>
                                                        ) 
                                                    }
                                                </div>
                                            </div>
                                            <p className="mb-0 mx-2 align-self-end text-purple" onClick={() => this.hideSuggetionDropdown()}>has permission to</p>
                                            <div style={{ display: "inline" }} className="ml-2 align-self-end" onClick={() => this.hideSuggetionDropdown()}>
                                                <div className="form-check">
                                                    <input type="checkbox"
                                                        onChange={() => this.setState({ ['read_'+item]: !this.state['read_'+item] }) }
                                                        className={`form-check-input`}
                                                        checked={this.state['read_'+item]}
                                                    />
                                                    <p className={`form-check-label mb-0 mr-2 ${this.state.hasError && (this.state['selectedAccessBy_'+item] && this.state['selectedAccessBy_'+item].length) && (!this.state['read_'+item] && !this.state['write_'+item]) ? 'text-danger' : 'text-purple'}`}>Read</p>
                                                </div>
                                            </div>
                                            <div style={{ display: "inline" }} className="ml-2 align-self-end" onClick={() => this.hideSuggetionDropdown()}>
                                                <div className="form-check">
                                                    <input type="checkbox"
                                                        onChange={() => this.setState({ ['write_'+item]: !this.state['write_'+item] }) }
                                                        className={`form-check-input`}
                                                        checked={this.state['write_'+item]}
                                                    />
                                                    <p className={`form-check-label mb-0 mr-2 ${this.state.hasError && (this.state['selectedAccessBy_'+item] && this.state['selectedAccessBy_'+item].length) && (!this.state['read_'+item] && !this.state['write_'+item]) ? 'text-danger' : 'text-purple'}`}>Write</p>
                                                </div>
                                            </div>
                                            <p className="mb-0 ml-2 align-self-end text-purple" onClick={() => this.hideSuggetionDropdown()}>in this {this.state.selectedDataSource ? this.state.selectedDataSource : ''}</p>
                                            {this.props.pageType.toLowerCase() !== 'view' && this.state.accessByArray.length > 1 ?
                                                <span className={`far fa-trash cursor-pointer f18 align-self-end ml-4`} onClick={() => this.removeAccessSection(item, i)}></span>
                                            : null}
                                            {this.props.pageType.toLowerCase() !== 'view' && (this.state.accessByArray.length - 1) ===  i ?
                                                <span className={`far fa-plus cursor-pointer f18 align-self-end ml-4`} onClick={() => this.addAccessSection('accessByArray')}></span>
                                            : null}
                                        </div>
                                    )
                                })}
                            </div>

                            <p className="mb-0 mr-2 align-self-end text-purple mt-4">Paths</p>
                            {this.state.pathArray.map((item, i) => {
                                return(
                                    <div className="d-flex flex-wrap">
                                        <input 
                                            type="text" 
                                            className={`inputField mt-3 w-40 
                                                ${this.state.hasError && (!this.state.pathsExist || (!this.state['path_'+item] || this.state['path_'+item] === '')) ? 'dottedDangerTextboxLine' : '' }
                                            `}
                                            value={this.state['path_'+item] ? this.state['path_'+item] : ''}
                                            onChange={e => this.setState({ ['path_'+item]: e.target.value })}
                                            placeholder="path"
                                        />
                                        
                                        {this.props.pageType.toLowerCase() !== 'view' && this.state.pathArray.length > 1 ?
                                            <span className={`far fa-trash cursor-pointer f18 align-self-end ml-4`} onClick={() => this.removePathSection(item, i)}></span>
                                        : null}
                                        {this.props.pageType.toLowerCase() !== 'view' && (this.state.pathArray.length - 1) ===  i ?
                                            <span className={`far fa-plus cursor-pointer f18 align-self-end ml-4`} onClick={() => this.addSection('pathArray')}></span>
                                        : null}
                                    </div>
                                )
                            })}
                        </div>
                        {this.state.hasError && this.state.errorMessage !== '' ?
                            <p className='m-0 text-danger align-self-end mt-5 mb-2'>{this.state.errorMessage}</p>
                        : null}
                        <div className={`border-top pt-2 ${this.state.hasError ? 'mt-3' : 'mt-5'}`}>
                            <button className='btn btn-light mr-1' onClick={() => this.props.showListPage()}>Cancel</button>
                            {this.props.pageType.toLowerCase() !== 'view' ?
                                !this.state.showActionCompleted ?
                                    this.state.showActionLoading ?
                                        <button className='btn btn-primary' onClick={() => this.actionPorcess()}>
                                            <Spinner size='sm' className='mx-2' color='white' /> {this.props.pageType.toLowerCase() === 'create' ? 'Creating ... ' : 'Updating ...' }
                                        </button>
                                    : 
                                        <button className='btn btn-primary' onClick={() => this.actionPorcess()}>{this.props.pageType.toLowerCase() === 'create' ? 'Create' : 'Update' }</button>
                                :
                                    <button className='btn btn-primary disabled'>
                                        {this.props.pageType.toLowerCase() === 'create' ? 'Created' : 'Updated' }
                                    </button>
                            : null}
                        </div>
                    </div>
                :
                    <div className='text-center mt-5' >
                        <Spinner color='dark' size='lg' />
                    </div>
                }
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
AddEdit.propTypes = {
    insertSourceDataCatalog: PropTypes.func,
    updateSourceDataCatalog: PropTypes.func
}

const mapStateToProps = state => {
    return {
		providers: state.filters.providers,
		dlpSourceDataList: state.dlp.dlpSourceDataList ? state.dlp.dlpSourceDataList.filter(res => res.service_name === 'athena') : [],
        dlpCustomerAccountInfoList: state.dlp.dlpCustomerAccountInfoList
    }
}

export default connect(mapStateToProps, {
    getSourceDataCatalogBaseParams,
    insertSourceDataCatalog,
    updateSourceDataCatalog,
    listSourceDataCatalog,
    listCustomerAccountInfo,
    listAthenaResources
})(withRouter(AddEdit))