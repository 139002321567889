/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Dashboard.js
 * @author Rajasekar // on 07/01/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Chart from 'chart.js'
import ApexChart from 'react-apexcharts'
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import {
	ButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Spinner,
	UncontrolledTooltip,
	UncontrolledPopover,
	PopoverBody,
	ListGroup,
	ListGroupItem,
	Col, Row, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import moment from 'moment'
import { Link } from 'react-router-dom'

import { CSVLink } from "react-csv";
import {
	listAllAssets,
	getAdvancedSearchResults,
	getTotalAssetCount,
	getDailyAssetCount,
	setSelectedTableRow
} from '../../../actions/assets/assetsAction'
import { CUSTOM_DURATION_FILTER_OPTIONS } from '../../../utils/constants'
import { URL_PATH } from '../../../config/urlPath'
import { AppWrapper } from '../../common/AppWrapper'
import { listAllProviders, listAllAccounts, listAllRegions, listAllServices, getResourceSearchMetaResults } from '../../../actions/commonAction'
import { listAllSuppressors } from '../../../actions/detectors/suppressorsAction'
import {
	getTotalCount,
	getDayWiseCount,
	getAssetDetails,
	getRelatedIssues,
	getBillingSnapshot,
	getMonitoringSnapShot,
	getTopEvents,
	getRecentEvents,
	getEventGraphCounts,
	resolveIssue,
} from '../../../actions/detectors/detectorsAction'
import { getMonitoringMetricsByTrend } from '../../../actions/aiops/MetricsAction'

import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'
import Search from '../../common/SearchComponent'
import AdvancedSearch from '../../common/AdvancedSearch'
import DetailsSection from './DetailsSection'
import SupressSection from './SupressSection'
import ConfigureSection from './ConfigureSection'
import { myLog, getAccountNameFromId, capitalizeFirstLetter, momentDateTime, datestartEndTime, subHours, subDays, capitalizeTheFirstLetterOfEachWord } from '../../../utils/utility'
import _ from 'lodash'
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'
import ResourcesAdvancedSearch from './AdvancedSearch/ResourcesAdvancedSearch';

// const actionOptions = ["Fix", "Suppress", "Configure"]
const actionOptions = ["Configure"]

class AssetsDashboard extends Component {
	constructor(props) {
		super(props)

		this.tabRef = React.createRef()
		this.dayPickerRef = React.createRef()
		this.durationRef = React.createRef()
		this.accountRef = React.createRef()
		this.providerRef = React.createRef()
		this.regionRef = React.createRef()
		this.serviceRef = React.createRef()
		this.searchRef = React.createRef()

		this.handleDayClick = this.handleDayClick.bind(this);
		this.handleResetClick = this.handleResetClick.bind(this);
		this.state = this.getInitialState();

		this.state = {
			selectedTab: 'asset_details',
			scoreRangeValue: { min: 0, max: 100 },
			showLoading: false,
			showTabListDropdown: false,
			isActionDropdownOpen: false,
			showSupressInDetailsSection: false,
			showConfigureSection: false,

			showDetailsAssetSectionLoading: false,
			showDetailsMonitoringSectionLoading: false,
			showDetailsBillingSectionLoading: false,
			showDetailsEventsSectionLoading: false,
			showDetailsRelatedIssueLoading: false,
			showSupressSectionLoading: false,

			supressRules: [],
			showDetailsSection: false,
			showSupressForm: false,

			providers: [],
			providerIsOpen: false,
			selectedProvider: 'All',
			
			accounts: [],
			allAccounts: [],
			selectedAccount: [],
			selectedAccountArray: [],
			isAccountOpen: false,
			
			selectedRegion: [],
			selectedRegionArray: [],
			isRegionOpen: false,

			services: [],
			showDropdownTree: false,
			serviceResourceArray: [],
			filteredServiceResourceArray: [],
			selectedService: [],
			selectedServiceContent: 'All',
			isServiceOpen: false, 

			selectedCategory: '',
			selectedMinScore: 0,
			selectedMaxScore: 100,
			selectedDuration: '+14d',
			relatedDurtaion: '+14d',
			start_time : '',
			end_time: '',
			previousCustomDays: moment().subtract('180', 'days'),
			showDatepicker: false,
			durationType: '',
			filteredArray: [],
			totalAssetsList: [],
			dynamicHeaders: [],
			selectedTableRow: {},

			// providerCounts: [],
			// statusCounts: [],
			// regionCounts: [],
			// scoreCounts: [],
			categoryCounts: [],
			// dailyAssetCount: {},
			series: [],
			options: [],

			assetActionSelectedTab: 0,

			topEventsData: {},
			recentEventsData: [],
			eventCountData: {},

			detailsTabNames: [
				{ name: 'Resource', value: 'asset_details' },
				{ name: 'Issues', value: 'related_issues' },
				{ name: 'Events', value: 'events' },
				{ name: 'Monitoring', value: 'monitoring_details' },
				// { name: 'Billing', value: 'billing' },
				//{ name: 'Availability', value: 'availability' },
			],
			selectedTableTab: -1,
			selectedDetailsTab: 0,
			showDetailsTabListDropdown: false,
			onClickSupressCreateButton: false,
			selectedRowAssetDetails: [],
			selectedRowBillingDetails: [],
			selectedRowMonitoringSnapshot: {},

			dayWiseCountGraphData: {},
			relatedIssuesData: [],
			filteredRelatedIssuesData: [],
			assetConfigrationsList: [],
			summaryGraphData: {},

			tabItemsToShow: 0,

			detailsTabItemsToShow: 2,

			selectedMonitoringDuration: '2h',

			tableRowRegions: [],
			recentOptions: [],
			recentSeries: [],
			eventSeries: [],
			eventOptions: [],

			providerDoughnutOptions: [],
			providerDoughnutSeries: [],

			statusDoughnutOptions: [],
			statusDoughnutSeries: [],

			showAdvancedSearchModal: false,
			selectedAdvancedSearch: {},
			newSearchText: '',
			showSearchOptions: false,
			keyupTimer: undefined,
			showDropdown: false,
			
			modalHeader: 'Close Issue',
			modalClose: false,
			modalAlert: false,
			
			closeLoading: false,
			closeStatus: 'resolved',
			closeStatusType: '',
			closeAttachment: '',
			closeAttachmentKey: false,
			
			
			responseMessage: '',
			showMessage: false,
			hasErrorInRuleForm: false,

			tableClass: '',
		}
		this.toggleCloseModal = this.toggleCloseModal.bind(this)
		
	}

	componentDidMount = () => {
		
		let assetTableColumns = []
		let dataRow = {}
		dataRow.Header = ''
		dataRow.accessor = 'score'
		dataRow.Cell = cellInfo => (
			<React.Fragment>
				<span className={`badge badge-primary`}>
					{cellInfo.row.original.score !== undefined ? cellInfo.row.original.score : 'NA'}
				</span>
			</React.Fragment>
		)
		dataRow.width = 80
		assetTableColumns.push(dataRow)

		dataRow = {}
		dataRow.Header = 'Date'
		dataRow.accessor = 'asset_time'
		dataRow.Cell = cellInfo => (
			<React.Fragment>
				<span>{momentDateTime(cellInfo.row.original.asset_time)}</span>
			</React.Fragment>
		)
		dataRow.width = 150
		assetTableColumns.push(dataRow)

		dataRow = {}
		dataRow.Header = 'Provider'
		dataRow.accessor = d => d.provider + ': ' + getAccountNameFromId(d.account_id, this.state.accounts) + ': ' + d.region === 'NA' ? 'Global' : d.region
		dataRow.Cell = cellInfo => (
			<React.Fragment>
			<span className=''>
				{cellInfo.row.original.provider + ': ' + getAccountNameFromId(cellInfo.row.original.account_id, this.state.accounts) + ': ' + (cellInfo.row.original.region === 'NA' ? 'Global' : cellInfo.row.original.region)}
			</span>
			</React.Fragment>
		)
		dataRow.width = 300
		assetTableColumns.push(dataRow)

		dataRow = {}
		dataRow.Header = 'Resource'
		dataRow.accessor = d => d.service_name + ' : ' + d.asset_name
		dataRow.Cell = cellInfo => (
			<span className=''>{cellInfo.row.original.service_name + ' : ' + cellInfo.row.original.asset_name}
			</span>
		)
		dataRow.width = 500
		assetTableColumns.push(dataRow)

		dataRow = {}
		dataRow.Header = 'Network'
		dataRow.accessor = 'vpc_id'		
		dataRow.width = 270			
		assetTableColumns.push(dataRow)

		this.setState({ assetTableColumns })
		
		if (!this.state.totalAssetsList.length) {
			this.setState({ showLoading: true })
			this.props.listAllProviders((promise, providers) => {
				if (promise) {
					// let dataRow = {}
					// dataRow.provider_id = "All"
					// dataRow.provider_name = "All"
					// providers.unshift(dataRow)
					
					this.setState({ providers, selectedProvider: providers.length ? providers[0].provider_name : "", showLoading: true },
						() => this.filterIdentifiers('provider')
					)
				} else {
					this.setState({ providers: [] },
						() => this.filterIdentifiers()
					)
				}
			})
		} else {
			this.showApexAssetActionGraph()
			this.showProvidersApexGraph()
			this.showStatusApexGraph()
			this.setState({ showDetailsSection: true })
			this.getAssetDetails(this.props.selectedTableRow)
			this.filterIdentifiers()
		}

		const _this = this
		window.addEventListener('resize', () => {
			_this.setTabs()
		})
	}
	/**
	 * Method to show providers graph
	 */

	getResourceSearchMetaResultsOnLoad = () => {		
		this.props.getResourceSearchMetaResults({size: 5,module_name: 'synchronizer',default: true}, (promise, result) => {
			if(promise) {
				this.setState({ searchResultState: this.props.searchNewResults, onSearchResultState: this.state.searchResultState, isLoading: false })
			} else {
				this.setState({ searchResultState: [], onSearchResultState: [], isLoading: false })
			}
		})
	}

	showProvidersApexGraph = () => {

		this.setState({ providerDoughnutSeries: [], providerDoughnutOptions: [] })
		let data =this.props.providerCounts
		let colors = ['#f68d11', '#34a853', '#2075b8']
		let donut_series = []
		let label = []
		data.forEach(item => {
			label.push(item.provider+' '+item.total_count)
			donut_series.push(item.total_count);
		})

		let doughnut_option = {
			stroke: {
				width: 0
			},
			chart: {
				  type: 'donut',
				  fontSize: '12px',
			},
			legend: {
				show: true,
				position: 'right',
			},
			dataLabels: {
				  enabled: false
			},
			fill: {
				type: 'gradient',
			},
			labels: label,
			colors: colors,
			tooltip: {
				custom: function({series, seriesIndex, dataPointIndex, w}) {
					return '<div class="arrow_box"> <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  capitalizeFirstLetter(w.globals.seriesNames[seriesIndex]) + '</span> </div>'
				},
				enabled: true
			},
			plotOptions: {
				pie: {
					donut: {
						size: '50%'
					},      
				}
			},
		}
		this.setState({ providerDoughnutSeries: donut_series, providerDoughnutOptions: doughnut_option })
	}
	/**
	 * Method to show status graph
	 */

	showStatusApexGraph = () => {
		this.setState({ statusDoughnutSeries: [], statusDoughnutOptions: [] })
		let colors = []
		let donut_series = []
		let label = []
		// let totalCount = 0;
		this.props.statusCounts.forEach(item => {
			// totalCount += item.total_count
			if (item.status === 'active') {
				colors.push('#67B865')
			}
			if (item.status === 'inactive') {
				colors.push('#E15858')
			}
			label.push(item.status+' '+item.total_count)
			donut_series.push(item.total_count);
		})

		// if(totalCount === 0) {
		// 	// colors=['#ECECEC']
		// 	label = 
		// 	donut_series = [100, 100]
		// }
		
		let doughnut_option = {
			stroke: {
				width: 0
			},
			chart: {
				type: 'donut',
				fontSize: '12px',
			},
			legend: {
				show: true,
				position: 'right',
			},
			dataLabels: {
				enabled: false
			},
			fill: {
				type: 'gradient',
			},
			labels: label,
			colors: colors,
			tooltip: {
				custom: function({series, seriesIndex, dataPointIndex, w}) {
					return '<div class="arrow_box"> <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  capitalizeFirstLetter(w.globals.seriesNames[seriesIndex]) + '</span> </div>'
				},
				enabled: true
			},
			plotOptions: {
				pie: {
					donut: {
						size: '50%'
					},      
				}
			},
		}
		this.setState({ statusDoughnutSeries: donut_series, statusDoughnutOptions: doughnut_option })
	}
	/**
	 * Method to show asset created , modified and deleted graph
	 */
	showApexAssetActionGraph = () => {
		this.setState({ series: [], options: [] });
		Chart.defaults.scale.display = true
		let graphLabel = []
		let createdGraphData = []
		let modifiedGraphData = []
		// let deletedGraphData = []
		let fontColors = []
		let startEndLabels = []
		this.props.dailyAssetCount && this.props.dailyAssetCount.labels && this.props.dailyAssetCount.labels.forEach((item, x) => {
			graphLabel.push(moment(item).format('DD MMM'))
			if(x === 0 || x === (this.props.dailyAssetCount.labels.length - 1)) {
				startEndLabels.push(moment(item).format('DD MMM'))
			}
		})
		let data1 = []	
		this.props.dailyAssetCount && this.props.dailyAssetCount.data && this.props.dailyAssetCount.data && this.props.dailyAssetCount.data.created &&this.props.dailyAssetCount.data.created.forEach((item, x) => {
			createdGraphData.push(item)
			let dataItems1 = {}
			dataItems1.x = moment(this.props.dailyAssetCount.labels[x]).format('DD MMM')
			dataItems1.y  = item
			data1.push(dataItems1)

			fontColors.push('#fff')
		})		

		let data2 = []
		this.props.dailyAssetCount && this.props.dailyAssetCount.data && this.props.dailyAssetCount.data && this.props.dailyAssetCount.data.modified && this.props.dailyAssetCount.data.modified.forEach((item, x) => {
			modifiedGraphData.push(item)
			let dataItems2 = {}
			dataItems2.x = moment(this.props.dailyAssetCount.labels[x]).format('DD MMM')
			dataItems2.y  = item
			data2.push(dataItems2)
		})
		

		let series = []
		let dataRow = {}
		dataRow.data = data1
		dataRow.name = 'Created'
		dataRow.labels = graphLabel
		series.push(dataRow)

		dataRow = {}
		dataRow.data = data2
		dataRow.name = 'Modified'
		dataRow.labels = graphLabel
		series.push(dataRow)

		let options = {
			chart: {
			  height: 100,
			  type: 'area',
			  zoom: {
				enabled: false
			  },
			  sparkline: {
				  enabled: false
			  },
			  toolbar: {
				show: false,
			  }
			},
			legend: {
				show: false
			},
			dataLabels: {
			  enabled: false
			},
			stroke: {
				show: true,
				curve: 'smooth',
				lineCap: 'butt',
				colors: undefined,
				width: 2,
				dashArray: 0,      
			},
			title: {
			  text: ''
			},
			grid: {
			  	xaxis: {
					lines: {
						show: false
					},
				},
				yaxis: {
					lines: {
						show: false
					}
				},
			},			
			yaxis: {
				labels: {
					show: true,
					style: {
						colors: fontColors
					},
					formatter: function(value) {
						return parseInt(value)
					}
				},
				tickAmount: 2
			},
			labels: graphLabel,
			xaxis: {
				Categories: [graphLabel],
				show: true,
				labels: {
					rotate: 0,
					show: true,
					formatter: function(value) {
						if(value === startEndLabels[0] || value === startEndLabels[1]) {
							return value
						}
					},
					style: {
						colors: fontColors
					},
					axisTicks: {
						show: false
					}
				},
				tooltip: {
					enabled: false
				},
				// min: min,
				// max: max,
			},
			colors: ['#09D261', '#F44336'],
			// fill: {
			// 	colors: ["#CEFBFF"],
			// 	opacity: 0.7,
			// 	type: 'solid',
			// 	//opacity: 0.5,
			// },
			tooltip: {
				enabled: true,
				enabledOnSeries: false,
				shared: false,
				followCursor: false,
				intersect: false,
				inverseOrder: false,
				custom: function({series, seriesIndex, dataPointIndex, w}) {
					// let val  = series[seriesIndex][dataPointIndex];
					// //let val  = series[seriesIndex][dataPointIndex];
					// let labelName = w.globals.initialSeries[0].labels[dataPointIndex]
					// let name = w.globals.seriesNames[seriesIndex]
					// //let label = w.globals.initialSeries[seriesIndex][dataPointIndex]
					// //val = Math.abs(Math.round(val))
					// //let labelName = w.globals.categoryLabels[dataPointIndex]
					// //let val = str.replace('-',series[seriesIndex][dataPointIndex]);
					// return '<div class="arrow_box"> '+ labelName +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  capitalizeFirstLetter(name) + '  '+ val + '</span> </div>'

					let labelName = w.globals.initialSeries[0].labels[dataPointIndex]

					let returnData = '<div class="metricsDetailCurrentTooltip">'
					if(w.globals.initialSeries && w.globals.initialSeries.length) {
						w.globals.initialSeries.forEach((item, index) => {
							if(!index) {
								returnData += '<div class="apexcharts-tooltip-title f12 border-bottom-black5">'+labelName+'</div>'
							}
							returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+item.name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+item.data[dataPointIndex].y+'</span></div></div></div>'
						})
					}

					returnData += '</div>'
					return returnData
				},
				fillSeriesColor: false,
				theme: false,
				style: {
				  fontSize: '12px',				
				},
				onDatasetHover: {
					highlightDataSeries: false,
				},
				x: {
					show: false,
					format: 'dd MMM',
					formatter: undefined,
				},
				y: {
					show: false,
					formatter: undefined,
					title: {
						formatter: (seriesName) => seriesName,
					},
				},
				marker: {
					show: false,
				}
			}

		}
		
		this.setState({ series, options });
	}
	/**
	 * Method to calculate top 4 region
	 */
	renderRegionList = popover => {
		let myArray = this.props.regionCounts
		myArray.sort((a, b) => b.total_count - a.total_count)
		if (popover) {
			return myArray.map((item, index) => {
				//if (index >= 4) {
					return (
						<ListGroupItem key={index} className='p-1 d-flex justify-content-between fs-12'>
							<span>{item.region === 'NA' ? 'Global' : item.region}</span>
							<span>{item.total_count}</span>
						</ListGroupItem>
					)
				//}
				//return null
			})
		} else {
			return myArray.map((item, index) => {
				if (index < 4) {
					return (
						<p key={index} className={`mb-0 d-flex justify-content-between fs-12`}>
							{item.region === 'NA' ? 'Global' : item.region} <span className='align-self-center ml-2'>{item.total_count}</span>
						</p>
					)
				}
				return null
			})
		}
	}
	/**
	 * Method to get all status, risk and category counts
	 */
	getAllCounts = getCategory => {
		let params = {}
		if(this.state.selectedAdvancedSearch && this.state.selectedAdvancedSearch.parameters && Object.entries(this.state.selectedAdvancedSearch.parameters).length) {
			if(this.props.assetsAdvancedList && this.props.assetsAdvancedList.data.length) {
				params.asset_id = this.props.assetsAdvancedList.data.map(x => x.asset_id);
			}
		} else {
			if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'All') {
				params.provider = this.state.selectedProvider.toLowerCase()
			}
			if (this.state.selectedAccount.length && !this.state.selectedAccount.includes('All')) {
				params.account_id = this.state.selectedAccount
			}
	
			if (this.state.selectedRegion.length && !this.state.selectedRegion.includes('All')) {
				params.region = this.state.selectedRegion
			}
	
			if (this.state.selectedService.length && !this.state.selectedService.includes('All')) {
				params.service_name = this.state.selectedService
			}
	
			if (this.state.selectedCategory !== '' && this.state.selectedCategory !== 'all') {
				params.category = this.state.selectedCategory
			}
	
			if (this.state.selectedDuration !== '' && this.state.selectedDuration !== 'all') {
				if(this.state.selectedDuration === 'custom')  {
					params.start_time = this.state.start_time
					params.end_time = this.state.end_time
				} else {
					params.duration = this.state.selectedDuration
				}
			}
		}
		params.aggregate_by = ['category']
		
		if (getCategory) {
			this.props.getTotalAssetCount(params, 'category', (promise, categoryCounts) => {
				if (promise) {
					this.setState({ categoryCounts, tabItemsToShow: categoryCounts.length },
						() => this.setTabs()
					)
				} else {
					this.setState({ categoryCounts: [] }, 
						() => this.setTabs()
					)
				}
			})
		}
	}
		
	setTabs = () => {
		if (this.tabRef.current) {
			// myLog(this.tabRef.current)
			// myLog(this.tabRef.current.clientWidth)
			// myLog(this.tabRef.current.childNodes.length)
			// myLog(this.tabRef.current.childNodes)
			// myLog(this.tabRef.current.childNodes[0].clientWidth)
			// myLog(this.tabRef.current.childNodes[1].clientWidth)
			const maxTabWidth = this.tabRef.current.clientWidth - 130
			let reqTabWidth = 0
			let allowedTabLength = -1
			for (let i = 0; i < this.tabRef.current.childNodes.length; i++) {
				reqTabWidth += this.tabRef.current.childNodes[i].clientWidth
				if (reqTabWidth < maxTabWidth) {
					allowedTabLength++
				}
			}
			// myLog(maxTabWidth, reqTabWidth, allowedTabLength)
			this.setState({ tabItemsToShow: allowedTabLength })
		}
	}

	/**
	 * Filter identifiers based on the filter selection
	 */
	filterIdentifiers = from => {
		this.setState({ 
			showLoading: true, 
			filteredArray: [], 
			totalAssetsList: [],
			dynamicHeaders: this.state.assetTableColumns,
			selectedTab: 'asset_details', 
			relatedDurtaion: this.state.selectedDuration,
			providerDoughnutSeries: [], 
			providerDoughnutOptions: [],
			statusDoughnutSeries: [], 
			statusDoughnutOptions: [],
			categoryCounts: [],
			showDetailsSection: false,
		})

		if(from === 'advancedSearch') {
			if(this.state.advancedResourceType !== '') {
				let selectedServiceArray = this.state.serviceArray.filter(item => item.resource_type === this.state.advancedResourceType)	
				let selectedService = []
				selectedServiceArray.forEach(ser => {
					selectedService.push(ser.service_name)
				})
				let filteredServiceResourceArray = []
				this.state.serviceResourceArray.forEach(item => {
					let dataRow = {}
					dataRow.label = item.label
					dataRow.expanded = true
					let childArray = []
					item.children.forEach(child => {
						let childRow = {}
						childRow.label = child.label
						childRow.value = child.label
						childRow.expanded = true
						if(item.label === this.state.advancedResourceType) {
							dataRow.checked = true
							childRow.checked = true
						}
						childArray.push(childRow)
						dataRow.children = childArray
					})
		
					filteredServiceResourceArray.push(dataRow)
				})
				let selectedServiceContent = selectedService.length === 1 ? selectedService : selectedService.length > 2 ? selectedService.length +' Selected' : 'All'
				this.setState({ selectedService, filteredServiceResourceArray, selectedServiceContent })
			}

			if(this.state.advancedServiceName !== '') {
				let filteredServiceResourceArray = []
				this.state.serviceResourceArray.forEach(item => {
					let dataRow = {}
					dataRow.label = item.label
					dataRow.expanded = true
					dataRow.checked = false
					let childArray = []
					item.children.forEach(child => {
						let childRow = {}
						childRow.label = child.label
						childRow.value = child.label
						childRow.expanded = true
						childRow.checked = false
						if(child.label === this.state.advancedServiceName) {
							childRow.checked = true
						}
						childArray.push(childRow)
						dataRow.children = childArray
					})
		
					filteredServiceResourceArray.push(dataRow)
				})
				let selectedServiceContent = this.state.advancedServiceName !== '' ? this.state.advancedServiceName : 'All'
				this.setState({ selectedService: [this.state.advancedServiceName], filteredServiceResourceArray, selectedServiceContent })
			}
		} else {
			this.setState({ selectedAdvancedSearch: {} })
		}

		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'All') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if (from === 'provider') {
			this.setState({ providerIsOpen: false })
			this.props.listAllAccounts(params, (promise, accounts) => {
				let allAccounts = []
				if (promise) {
					accounts.length && accounts.forEach((item, i) => {
						if(i === 0) {
							let accRow = {}
							accRow.account_id = "All"
							accRow.account_name = "All"
							accRow.date = null
							accRow.disabled = false
							accRow.provider = "All"
							allAccounts.push(accRow);
						}

						allAccounts.push(item);
					})
					this.setState({ accounts, allAccounts })
				}
			})			

			this.props.listAllRegions(params, (promise, regions) => {

				let providerRegions = regions[0] && regions[0].provider_regions
				if(providerRegions) {
					let provider_regions_row = {}
					provider_regions_row.availability_zones = []
					provider_regions_row.city = "All"
					provider_regions_row.country = "All"
					provider_regions_row.geography = "All"
					provider_regions_row.name = "All"
					provider_regions_row.region = "All"
					provider_regions_row.region_id = "All"
					provider_regions_row.state = "All"
					providerRegions.unshift(provider_regions_row);
				}
				if (promise) {
					this.setState({ regions: providerRegions, tableRowRegions: providerRegions })
				} else {
					this.setState({ regions: [], tableRowRegions: [] })
				}
			})
			this.props.listAllServices(params, (promise, services) => {
				if (promise) {
					let serviceResourceArray = []
					let serviceArray = []
					services && services.length &&
					_.chain(services)
					.groupBy('resource_type')
					.map((value, key) => ({ label: key, sub: value }))
					.value()
					.forEach((service, i) => {
						if(i === 0) {
							let dataRow = {}							
							dataRow.category = 'All'
							dataRow.default_region = 'All'
							dataRow.provider_name = 'All'
							dataRow.resource_type = 'All'
							dataRow.service_id = 'All'
							dataRow.service_name = 'All'
							serviceArray.push(dataRow)

							let serRow = {}
							serRow.label = 'All'
							serRow.value = 'All'
							serRow.expanded = true
							let serArray = []
							serRow.children = serArray
							serviceResourceArray.push(serRow)
						}
						
						let serRow = {}
						serRow.label = service.label
						serRow.value = service.label
						serRow.expanded = true
						let serArray = []
						//serviceResourceArray.push(service)
						service.sub.forEach((item, i) => {
							let serItem = {}
							serItem.label = item.service_name
							serItem.value = item.service_name
							serItem.expanded = true
							serArray.push(serItem)
							serviceArray.push(item)
						})
						serRow.children = serArray
						serviceResourceArray.push(serRow)
					})
					this.setState({ services, serviceArray, serviceResourceArray, filteredServiceResourceArray: serviceResourceArray  })
				} else {
					this.setState({ services: [] })
				}
			})
		}

		if (this.state.selectedAccount.length && !this.state.selectedAccount.includes('All')) {
			params.account_id = this.state.selectedAccount
		}
		
		if (this.state.selectedRegion.length && !this.state.selectedRegion.includes('All')) {
			params.region = this.state.selectedRegion
		}

		if (this.state.selectedService.length && !this.state.selectedService.includes('All')) {
			params.service_name = this.state.selectedService
		}

		if (this.state.selectedCategory !== '' && this.state.selectedCategory !== 'all') {
			params.category = this.state.selectedCategory
		}

		if (this.state.selectedDuration !== '' && this.state.selectedDuration !== 'all') {
			if(this.state.selectedDuration === 'custom')  {
				params.start_time = this.state.start_time
				params.end_time = this.state.end_time
			} else {
				params.duration = this.state.selectedDuration
			}
		}
		
		if (this.state.selectedCategory.length) {
			params.category = this.state.selectedCategory
		}

		//header action trend area chart
		let duration = {}
		if (this.state.selectedDuration !== '' && this.state.selectedDuration !== 'all') {
			if(this.state.selectedDuration === 'custom')  {
				duration.start_time = this.state.start_time
				duration.end_time = this.state.end_time
			} else {
				duration.duration = this.state.selectedDuration
			}
		}
		this.props.getDailyAssetCount(duration , promise => {
			if (promise) {
				this.showApexAssetActionGraph()
			}
		})
		
		//header provider pies chart
		params.aggregate_by = ['provider']
		this.props.getTotalAssetCount(params, 'provider', promise => {
			if (promise) {
				this.showProvidersApexGraph()
			}
		})

		//header satus pies chart
		params.aggregate_by = ['status']
		this.props.getTotalAssetCount(params, 'status', promise => {
			if (promise) {
				this.showStatusApexGraph()
			}
		})

		//header region table
		params.aggregate_by = ['region']
		this.props.getTotalAssetCount(params, 'region', () => {})		

		//header score bar
		params.aggregate_by = ['score']
		this.props.getTotalAssetCount(params, 'score', () => {})
		
		delete params['aggregate_by']
		params.max_score = parseInt(this.state.selectedMaxScore)
		params.min_score = parseInt(this.state.selectedMinScore)
		if (this.state.selectedDuration !== '' && this.state.selectedDuration !== 'all') {
			if(this.state.selectedDuration === 'custom')  {
				params.start_time = this.state.start_time
				params.end_time = this.state.end_time
			} else {
				params.duration = this.state.selectedDuration
			}
		}
		
		if(this.state.selectedAdvancedSearch && this.state.selectedAdvancedSearch.parameters && Object.entries(this.state.selectedAdvancedSearch.parameters).length) {
			params = this.state.selectedAdvancedSearch
			this.props.getAdvancedSearchResults(params, () => {
				if (this.props.assetsAdvancedList && this.props.assetsAdvancedList.data.length) {
					let tableClass = ""
					let dynamicHeaders = this.state.assetTableColumns
					if(this.props.assetsAdvancedList.headers && this.props.assetsAdvancedList.headers.length) {						
						this.props.assetsAdvancedList.headers.forEach(item => {
							let dataRow = {}
							dataRow.Header = item.label
							dataRow.accessor = item.value
							dynamicHeaders.push(dataRow)
						})
						tableClass = 'assetTable'
					}

					this.setState({
						tableClass,
						showLoading: false,
						filteredArray: this.props.assetsAdvancedList.data,
						totalAssetsList: this.props.assetsAdvancedList.data,
						dynamicHeaders,
						// selectedTableRow: this.props.assets[0],
						showDetailsSection: true,
						selectedDetailsTab: 0,
					},
						() => {
							this.getProviderRegion()
							this.getAllCounts(true)
							this.formHeaders()
						}
					)
				} else {
					this.setState({ showLoading: false, showDropdownTree: false })
				}
				if (
					this.props.selectedTableRow && Object.entries(this.props.selectedTableRow).length !== 0 &&
					this.props.selectedTableRow.constructor === Object
				) {
					this.getAssetSupressList(this.props.selectedTableRow)
					this.getAssetDetails(this.props.selectedTableRow)
				}
			})
		} else {
			this.props.listAllAssets(params, () => {
				this.setState({ showLoading: false })
				if (this.props.assets && this.props.assets.length) {
					let data = _.orderBy(this.props.assets, ['asset_time'], ['desc'])
					this.props.setSelectedTableRow(data[0])
					this.setState({
						showLoading: false,
						filteredArray: data,
						totalAssetsList: data,
						dynamicHeaders: this.state.assetTableColumns,
						selectedTableRow: data[0],
						showDetailsSection: true,
						selectedDetailsTab: 0,
					},
						() => {
							this.getProviderRegion()
							this.getAllCounts(true)
							this.formHeaders()
						}
					)
				} else {
					this.setState({ showDropdownTree: false, selectedTableRow: {} })
					this.props.setSelectedTableRow({})
				}
				if (
					this.props.selectedTableRow && Object.entries(this.props.selectedTableRow).length !== 0 &&
					this.props.selectedTableRow.constructor === Object
				) {
					this.getAssetSupressList(this.props.selectedTableRow)
					this.getAssetDetails(this.props.selectedTableRow)
				}
			})
		}
	}

	getProviderRegion = () => {
		let params = {
			provider: this.props.selectedTableRow.provider.toLowerCase()
		}
		this.props.listAllRegions(params, (promise, regions) => {
			if (promise) {
				this.setState({ tableRowRegions: regions[0] && regions[0].provider_regions, selectedDetailsTab: 0 })
			} else {
				this.setState({ tableRowRegions: [] })
			}
		})
	}
	/**
	 * Method to return total category counts
	 */
	getTotalCategoryCounts = () => {
		let count = 0
		if (this.state.categoryCounts && this.state.categoryCounts.length && this.props.statusCounts.length) {
			this.state.categoryCounts.forEach(item => {
				count += item.total_count
			})
		}
		return count
	}
	/**
	 * toggles tab list dropdown
	 */
	toggleShowTabListDropdown = () => {
		this.setState(prevState => ({
			showTabListDropdown: !prevState.showTabListDropdown,
		}))
	}
	/**
	 * toggles action dropdown
	 */
	toggleActionDropdown = () => {
		this.setState(prevState => ({
			isActionDropdownOpen: !prevState.isActionDropdownOpen,
		}))
	}
	/**
	 * toggles details tab dropdown
	 */
	toggleDetailsTabListDropdown = () => {
		this.setState(prevState => ({
			showDetailsTabListDropdown: !prevState.showDetailsTabListDropdown,
		}))
	}
	/**
	 * Handles on click tab dropdown
	 */
	onClickDropDownItem = (item, index) => {
		let tempArray = this.state.categoryCounts
		tempArray.splice(index, 1)
		tempArray.splice(this.state.tabItemsToShow - 2, 0, item)
		this.setState({
			categoryCounts: tempArray,
			selectedTableTab: this.state.tabItemsToShow - 2,
			selectedCategory: item.category,
		})
		this.getAllAssetsOnTabChange(item.category)
	}
	/**
	 * Get all detectors on change the tab
	 */
	getAllAssetsOnTabChange = selectedCategory => {
		this.setState({ selectedCategory }, 
			() => this.filterIdentifiers()
		)
	}

	getAdavanceAssetsOnTabChange = selectedCategory => {
		this.setState({ selectedCategory }, 
			() => this.filterIdentifiers('advancedSearch')
		)
	}
	/**
	 * Handles on click details tab dropdown
	 */
	onClickDetailsTabDropDownItem = (item, index) => {
		let tempArray = this.state.detailsTabNames
		tempArray.splice(index, 1)
		// tempArray.splice(0, 0, item)
		tempArray.splice(this.state.detailsTabItemsToShow - 1, 0, item)
		this.setState({ detailsTabNames: tempArray })
		this.setState({ selectedDetailsTab: this.state.detailsTabItemsToShow - 1 })
	}

	getRelatedTableData = (category, selectedResourceStatus, status) => {

		let filteredRelatedIssuesData = this.state.relatedIssuesData
		if(category !== '' && category !== undefined) {
			filteredRelatedIssuesData = filteredRelatedIssuesData.filter(item => item.category.includes(category))
		} 
		if(selectedResourceStatus !== '' && selectedResourceStatus !== undefined) {
			filteredRelatedIssuesData = filteredRelatedIssuesData.filter(item => item.resolution_status_info ? item.resolution_status_info.status === selectedResourceStatus : selectedResourceStatus === 'pending' ? item.status === 'fail' : item.status === 'pass')
		}
		if(status !== '' && status !== undefined) {
			filteredRelatedIssuesData = filteredRelatedIssuesData.filter(item => item.status === status)
		} 
		this.setState({ filteredRelatedIssuesData })
	}
	/**
	 * Get asset details of the detector
	 */
	getAssetDetails = asset => {
		this.setState({ showDetailsAssetSectionLoading: true, showSupressSectionLoading: true })
		let params = {
			service_name: asset.service_name,
			asset_id: asset.asset_id,
		}
		this.props.getAssetDetails(params, (promise, assetDetails) => {
			if (!promise) {
				this.setState({
					selectedRowAssetDetails: [],
					showDetailsAssetSectionLoading: false,
					showSupressSectionLoading: false,
				})
			} else {
				this.setState({
					selectedRowAssetDetails: assetDetails,
					showDetailsAssetSectionLoading: false,
					showSupressSectionLoading: false,
				})
			}
		})
	}

	/**
	 * Get related details of the detector
	 */
	getRelatedIssues = (asset, relatedIssueDurtaion) => {
		this.setState({ showDetailsRelatedIssueLoading: true, relatedIssuesData: [], filteredRelatedIssuesData: [], relatedDurtaion: relatedIssueDurtaion })
		let params = {}
		params.asset_id = asset.asset_id
		params.risk = asset.risk
		params.status = asset.status
		if(relatedIssueDurtaion === 'custom') {
			params.start_time = this.state.start_time
			params.end_time = this.state.end_time
		} else {
			params.duration = relatedIssueDurtaion
		}

		this.props.getRelatedIssues(params, (promise, relatedIssuesData) => {
			if (promise) {
				this.setState({ showLoading: false, relatedIssuesData, filteredRelatedIssuesData: relatedIssuesData })
			} else {
				this.setState({ relatedIssuesData: [], filteredRelatedIssuesData: [] })
			}
		})
		
		const countParams = {}
		countParams.asset_id = asset.asset_id
		if(relatedIssueDurtaion === 'custom') {
			countParams.start_time = this.state.start_time
			countParams.end_time = this.state.end_time
		} else {
			countParams.duration = relatedIssueDurtaion
		}

		this.props.getDayWiseCount(countParams, (promise, dayWiseCountGraphData) => {
			if (promise) {
				this.setState({ dayWiseCountGraphData },
					() => this.showApexAssetRecentGraph()
				)
			}
			this.setState({ showDetailsRelatedIssueLoading: false })
		})
	}
	
	
    toggleCloseModal = (event) => {
        if(event.keyCode !== 27)  {
            this.setState({
                modalClose: !this.state.modalClose
            })
        }
	}

	openCloseModal = (closeRow, relatedIssueDurtaion) => {
		this.setState({
			modalClose: !this.state.modalClose, selectedRelativeIssue: closeRow, relatedIssueDurtaion, closeLoading: false
		})
	}
    
    onModalSubmit = () => {

		this.setState({ closeLoading: true, responseMessage: '', disableOnSubmit: true })
		let params = {}
		params.status = this.state.closeStatus
		params.sub_status =  this.state.closeStatusType
		params.identifiers = [{"checksum": this.state.selectedRelativeIssue.checksum,
		"identifier_id": this.state.selectedRelativeIssue.identifier_id}]
		params.resolve_mode = 'mannual'
		params.reason = 'solved manually'
		
		const data = new FormData() 
		if(this.state.closeAttachment) {
			data.append('proof', this.state.closeAttachment);
		}
		data.append('request', JSON.stringify(params));

		if(!this.state.hasErrorInRuleForm) {
			this.props.resolveIssue(data, (promise, response) => {
                if(promise) {
                    this.setState({
                        closeLoading: false,
                        closeStatusType: '',
                        closeReason: '',
                        closeAttachment: '',
                        responseMessage: response.data.message,
                        showMessage: true,
                        showLoading: false,
                        hasErrorInRuleForm: false,
                        responseStatus: response.data.status,
                    }, 
                        () => this.hideMessage()
                    )
                } else {
                    this.setState({ 
                        disableOnSubmit: false, 
                        closeLoading: false,
                        showMessage: true,
                        showLoading: false,
                        responseMessage: response.message,
                        responseStatus: response.status,
                    })
                }
			})
		}
	}

    hideMessage = () => {
        setTimeout(() => {
            this.setState({
                modalClose: !this.state.modalClose,
                showMessage: false,
            }, 
                this.getRelatedIssues(this.props.selectedTableRow, this.state.relatedIssueDurtaion)
            );
        }, 5000)
	}

	showApexAssetRecentGraph = () => {
		let graphLabel = []
		let createdGraphData = []
		let modifiedGraphData = []
		let fontColors = []
		let startEndLabels = []

		this.state.dayWiseCountGraphData && this.state.dayWiseCountGraphData.labels && this.state.dayWiseCountGraphData.labels.forEach((item, x) => {
			graphLabel.push(moment(item).format('DD MMM'))
			if(x === 0 || x === (this.state.dayWiseCountGraphData.labels.length - 1)) {
				startEndLabels.push(moment(this.state.dayWiseCountGraphData.labels[x]).format('DD MMM'))
			} 

		})
		let data1 = []	
		this.state.dayWiseCountGraphData && this.state.dayWiseCountGraphData.data && this.state.dayWiseCountGraphData.data.pass && this.state.dayWiseCountGraphData.data.pass.forEach((item, x) => {
			createdGraphData.push(item)
			let dataItems1 = {}
			dataItems1.x = moment(this.state.dayWiseCountGraphData.labels[x]).format('DD MMM')
			dataItems1.y  = item
			data1.push(dataItems1)

			fontColors.push('#000')
		})

		let data2 = []
		this.state.dayWiseCountGraphData && this.state.dayWiseCountGraphData.data && this.state.dayWiseCountGraphData.data.fail && this.state.dayWiseCountGraphData.data.fail.forEach((item, x) => {
			modifiedGraphData.push(item)
			let dataItems2 = {}
			dataItems2.x = moment(this.state.dayWiseCountGraphData.labels[x]).format('DD MMM')
			dataItems2.y  = item
			data2.push(dataItems2)
		})

		let series = []
		let dataRow = {}
		dataRow.data = data1
		dataRow.name = 'pass'
		dataRow.labels = graphLabel
		series.push(dataRow)

		dataRow = {}
		dataRow.data = data2
		dataRow.name = 'fail'
		dataRow.labels = graphLabel
		series.push(dataRow)
		let options = {
			chart: {
			height: 100,
			type: 'area',
			zoom: {
				enabled: false
			},
			sparkline: {
				enabled: false
			},
			toolbar: {
				show: false,
			}
			},
			legend: {
				show: false
			},
			dataLabels: {
			enabled: false
			},
			stroke: {
				show: true,
				curve: 'smooth',
				lineCap: 'butt',
				colors: undefined,
				width: 2,
				dashArray: 0,      
			},
			title: {
			text: ''
			},
			grid: {
				xaxis: {
					lines: {
						show: false
					},
				},
				yaxis: {
					lines: {
						show: false
					}
				},
			},			
			yaxis: {
				labels: {
					show: true,
					style: {
						colors: fontColors
					},
					formatter: function(value) {
						return parseInt(value)
					}
				},
				tickAmount: 1
			},
			labels: graphLabel,
			xaxis: {
				Categories: [graphLabel],
				show: true,
				labels: {
					rotate: 0,
					show: true,
					formatter: function(value) {
						if(value === startEndLabels[0] || value === startEndLabels[1]) {
							return value
						}
					},
					style: {
						colors: fontColors
					},
					axisTicks: {
						show: false
					}
				},
				tooltip: {
					enabled: false
				},
				// min: min,
				// max: max,
			},
			colors: ['#FFD200', '#B96422'],
			// colors: ['#09D261', '#F44336'],
			// fill: {
			// 	colors: ["#CEFBFF"],
			// 	opacity: 0.7,
			// 	type: 'solid',
			// 	//opacity: 0.5,
			// },
			tooltip: {
				enabled: true,
				enabledOnSeries: false,
				shared: false,
				followCursor: false,
				intersect: false,
				inverseOrder: false,
				custom: function({series, seriesIndex, dataPointIndex, w}) {
					// let val  = series[seriesIndex][dataPointIndex];
					// let labelName = w.globals.initialSeries[0].labels[dataPointIndex]
					// return '<div class="arrow_box"> <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val +
					// '</div>'

					let labelName = w.globals.initialSeries[0].labels[dataPointIndex]

					let returnData = '<div class="metricsDetailCurrentTooltip">'
					if(w.globals.initialSeries && w.globals.initialSeries.length) {
						w.globals.initialSeries.forEach((item, index) => {
							if(!index) {
								returnData += '<div class="apexcharts-tooltip-title f12 border-bottom-black5">'+labelName+'</div>'
							}
							returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+item.name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+item.data[dataPointIndex].y+'</span></div></div></div>'
						})
					}

					returnData += '</div>'
					return returnData
				},
				fillSeriesColor: false,
				theme: false,
				style: {
				fontSize: '12px',				
				},
				onDatasetHover: {
					highlightDataSeries: false,
				},
				x: {
					show: false,
					format: 'dd MMM',
					formatter: undefined,
				},
				y: {
					show: false,
					formatter: undefined,
					title: {
						formatter: (seriesName) => seriesName,
					},
				},
				marker: {
					show: false,
				}
			}

		}
		
		this.setState({ recentSeries: series, recentOptions: options });
	
	}

	/**
	 * Get billing details of the detector
	 */
	getBillingSnap = asset => {
		this.setState({ showDetailsBillingSectionLoading: true })
		let params = {
			resource_id: asset.asset_id,
			provider: asset.provider.toLowerCase(),
			type: 'extended',
			current_month: true,
			last_month: true,
			duration: '90d',
			aggregate: 'daily',
		}
		this.props.getBillingSnapshot(params, billingDetails => {
			this.setState({ selectedRowBillingDetails: billingDetails, showDetailsBillingSectionLoading: false })
		})
	}
	/**
	 * Get asset events
	 */
	getAssetEvents = asset => {
		this.setState({ showDetailsEventsSectionLoading: true })
		
		const params = {}
		params.provider = asset.provider.toLowerCase()
		params.service_name = asset.service_name
		params.asset_id = asset.asset_id
		params.aggregate_by = 'event'
		if (this.state.selectedDuration !== '' && this.state.selectedDuration !== 'all') {
			if(this.state.selectedDuration === 'custom')  {
				params.start_time = this.state.start_time
				params.end_time = this.state.end_time
			} else {
				params.duration = this.state.selectedDuration
			}
		}
		params.account_id = asset.account_id
		// network = 'vpc-0804b9afc7e81dbcc'
		params.region = asset.region
			// tags: [
			// 	{
			// 		key: 'Name',
			// 		value: 'Test_Public_EC2',
			// 	},
			// ],
		this.props.getTopEvents(params, (promise, topEvents) => {
			if (promise) {
				this.setState({ topEventsData: topEvents, showDetailsEventsSectionLoading: false })
			} else {
				this.setState({ showDetailsEventsSectionLoading: false })
			}
		})

		const recentEventsParams = {
			service_name: asset.service_name,
			provider: asset.provider.toLowerCase(),
			asset_id: asset.asset_id,
		}
		this.props.getRecentEvents(recentEventsParams, (promise, recentEvents) => {
			if (promise) {
				this.setState({ recentEventsData: recentEvents, showDetailsEventsSectionLoading: false })
			} else {
				this.setState({ showDetailsEventsSectionLoading: false })
			}
		})

		const eventCountsParams = {}
		eventCountsParams.service_name = asset.service_name
		eventCountsParams.provider = asset.provider.toLowerCase()
		eventCountsParams.asset_id = asset.asset_id
		if (this.state.selectedDuration !== '' && this.state.selectedDuration !== 'all') {
			if(this.state.selectedDuration === 'custom')  {
				eventCountsParams.start_time = this.state.start_time
				eventCountsParams.end_time = this.state.end_time
				// eventCountsParams.duration = '+14d'
			} else {
				eventCountsParams.duration = this.state.selectedDuration
			}
		}
		this.props.getEventGraphCounts(eventCountsParams, (promise, eventCounts) => {
			if (promise) {
				this.setState({ eventCountData: eventCounts, showDetailsEventsSectionLoading: false, eventSeries: [], eventOptions: [] },
					() => this.showApexEventsGraph()
				)
			} else {
				this.setState({ showDetailsEventsSectionLoading: false })
			}
		})
	}

	

	showApexEventsGraph = () => {
		let graphLabel = []
		let createdGraphData = []
		// let modifiedGraphData = []
		let fontColors = []
		let startEndLabels = []
		if(this.state.eventCountData.data && this.state.eventCountData.data.count && this.state.eventCountData.data.count.length) {
			this.state.eventCountData.labels.forEach((item, x) => {
				graphLabel.push(moment(item).format('DD MMM'))
				if(x === 0 || x === (this.state.eventCountData.labels.length - 1)) {
					startEndLabels.push(moment(this.state.eventCountData.labels[x]).format('DD MMM'))
				} 

			})
			let data1 = []	
			this.state.eventCountData.data.count.forEach((item, x) => {
				createdGraphData.push(item)
				let dataItems1 = {}
				dataItems1.x = moment(this.state.eventCountData.labels[x]).format('DD MMM')
				dataItems1.y  = item
				data1.push(dataItems1)

				fontColors.push('#000')
			})

			// let data2 = []
			// this.state.eventCountData.data.modified.forEach((item, x) => {
			// 	modifiedGraphData.push(item)
			// 	let dataItems2 = {}
			// 	dataItems2.x = moment(this.state.eventCountData.labels[x]).format('DD MMM')
			// 	dataItems2.y  = item
			// 	data2.push(dataItems2)
			// })

			let series = []
			let dataRow = {}
			dataRow.data = data1
			dataRow.name = 'pass'
			dataRow.labels = graphLabel
			series.push(dataRow)

			// dataRow = {}
			// dataRow.data = data2
			// dataRow.name = 'fail'
			// dataRow.labels = graphLabel
			// series.push(dataRow)

			let options = {
				chart: {
				height: 100,
				type: 'area',
				zoom: {
					enabled: false
				},
				sparkline: {
					enabled: false
				},
				toolbar: {
					show: false,
				}
				},
				legend: {
					show: false
				},
				dataLabels: {
				enabled: false
				},
				stroke: {
					show: true,
					curve: 'smooth',
					lineCap: 'butt',
					colors: undefined,
					width: 2,
					dashArray: 0,      
				},
				title: {
				text: ''
				},
				grid: {
					xaxis: {
						lines: {
							show: false
						},
					},
					yaxis: {
						lines: {
							show: false
						}
					},
				},			
				yaxis: {
					labels: {
						show: true,
						style: {
							colors: fontColors
						},
						formatter: function(value) {
							return parseInt(value)
						}
					},
					tickAmount: 1
				},
				labels: graphLabel,
				xaxis: {
					Categories: [graphLabel],
					show: true,
					labels: {
						rotate: 0,
						show: true,
						formatter: function(value) {
							if(value === startEndLabels[0] || value === startEndLabels[1]) {
								return value
							}
						},
						style: {
							colors: fontColors
						},
						axisTicks: {
							show: false
						}
					},
					tooltip: {
						enabled: false
					},
					// min: min,
					// max: max,
				},
				colors: ['#FFD200', '#B96422'],
				// colors: ['#09D261', '#F44336'],
				// fill: {
				// 	colors: ["#CEFBFF"],
				// 	opacity: 0.7,
				// 	type: 'solid',
				// 	//opacity: 0.5,
				// },
				tooltip: {
					enabled: true,
					enabledOnSeries: false,
					shared: false,
					followCursor: false,
					intersect: false,
					inverseOrder: false,
					custom: function({series, seriesIndex, dataPointIndex, w}) {
						let val  = series[seriesIndex][dataPointIndex];
						//let val  = series[seriesIndex][dataPointIndex];
						let labelName = w.globals.initialSeries[0].labels[dataPointIndex]
						//let label = w.globals.initialSeries[seriesIndex][dataPointIndex]
						//val = Math.abs(Math.round(val))
						//let labelName = w.globals.categoryLabels[dataPointIndex]
						//let val = str.replace('-',series[seriesIndex][dataPointIndex]);
						return '<div class="arrow_box"> <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val +
						'</div>'
					},
					fillSeriesColor: false,
					theme: false,
					style: {
					fontSize: '12px',				
					},
					onDatasetHover: {
						highlightDataSeries: false,
					},
					x: {
						show: false,
						format: 'dd MMM',
						formatter: undefined,
					},
					y: {
						show: false,
						formatter: undefined,
						title: {
							formatter: (seriesName) => seriesName,
						},
					},
					marker: {
						show: false,
					}
				}
			}
			
			this.setState({ eventSeries: series, eventOptions: options })
		}
	}

	/**
	 * Get monitoring details of the detector
	 */
	getMonitoringSnap = (asset, duration) => {
		this.setState({
			showDetailsMonitoringSectionLoading: true,
			selectedRowMonitoringSnapshot: {},
			selectedMonitoringDuration: duration ? duration : '2h',
		})

		// let findHour = duration ? duration : '2h'
		// let dayType = findHour[findHour.length - 1]
		// let hour = findHour.substring(0, findHour.length - 1);
		// let startDate = addSubTime(asset.asset_time, 'sub', dayType, hour)
		// let endDate = addSubTime(asset.asset_time, 'add', dayType, hour)

		// let params = {
		// 	provider: asset.provider.toLowerCase(),
		// 	start_date: startDate,
		// 	end_date: endDate,
		// 	resource_id: asset.asset_name,
		// 	servicename: asset.service_name,
		// 	anamoly: 2,
		// 	account_id: asset.account_id,
		// 	region: asset.region,
		// 	cache: true
		// }
		

		// this.props.getMonitoringSnapShot(params, dayType, snapshot => {
		// 	this.setState({
		// 		selectedRowMonitoringSnapshot: snapshot,
		// 		showDetailsMonitoringSectionLoading: false,
		// 		showDetailsSection: true,
		// 	})
		// })

		let findHour = duration ? duration : '2h'
		let dayType = findHour[findHour.length - 1]
		let hour = findHour.substring(0, findHour.length - 1);
		let startTime = subDays(asset.asset_time, hour)
		// let endTime = addDays(asset.asset_time, hour)
		let endTime = asset.asset_time
		if(dayType === 'h') {
			startTime = subHours(asset.asset_time, hour)
			// endTime = addHours(asset.asset_time, hour)
		}
		let params = {}
        params.provider = asset.provider.toLowerCase()
        params.start_time = startTime
        params.end_time = endTime
        params.asset_id = asset.asset_id
        params.top = 5
    
        this.props.getMonitoringMetricsByTrend(params, (promise, response) => {
            if(promise) {
				let obj = {}
				obj.start_time = startTime
				obj.end_time = endTime
				obj.response = response
                this.setState({ 
					selectedRowMonitoringSnapshot: obj,
					showDetailsMonitoringSectionLoading: false,
					showDetailsSection: true
				})
            } else {
 				this.setState({
					showDetailsMonitoringSectionLoading: false,
					showDetailsSection: true
				})
			}
        })
	}

	/**
	 * Get suppress details of the detector
	 */
	getAssetSupressList = asset => {
		let params = {
			module_name: 'identifier',
			module_id: asset.asset_id,
		}
		/**
		 * List of rules against each identifier
		 */
		this.props.listAllSuppressors(params, supressRules => {
			this.setState({ supressRules })
			myLog(supressRules, 'supressRules')
		})
	}
	/**
	 * Get total maximum count of the asset action label
	 */
	// getAssetActionGraphxLabel = () => {
	// 	const combinedLabel = this.state.dailyAssetCount.data.created.concat(this.state.dailyAssetCount.data.modified)
	// 	const value = Math.max(...combinedLabel)
	// 	return <small>{value}</small>
	// }

	/**
	 * Get configuration details of the asset
	 */
	// getAssetConfigurationsList = asset => {
	// 	let params = {
	// 		service_id: asset.service_id,
	// 	}
	// 	/**
	// 	 * List of configs against each a identifier
	// 	 */
	// 	this.props.listAllIdentifiers(params, configurations => {
	// 		this.setState({ assetConfigrationsList: configurations ? configurations.results : [] })
	// 	})
	// }

	/**
	 * Handles loading toggle from details section screen
	 */
	handleLoadingToggle = (from, boolean) => {
		if (from === 'asset_details') this.setState({ showDetailsAssetSectionLoading: boolean })
		if (from === 'related_issues') this.setState({ showDetailsRelatedIssueLoading: boolean })
		if (from === 'events') this.setState({ showDetailsEventsSectionLoading: boolean })
		if (from === 'monitoring_details') this.setState({ showDetailsMonitoringSectionLoading: boolean })
		if (from === 'billing') this.setState({ showDetailsBillingSectionLoading: boolean })
		//if (from === 'availability') this.setState({ showDetailsAvailSectionLoading: boolean })

		this.setState({ selectedTab: from })
	}
	/**
	 * Method to return total score counts
	 */
	getTotalScoreCounts = () => {
		let count = 0
		if (this.props.scoreCounts && this.props.scoreCounts.length) {
			this.props.scoreCounts.forEach(item => {
				count += item.total_count
			})
		}
		return count
	}

	scoreRangeFunction = (scoreRange) => {
		if(this.state.selectedMinScore !== scoreRange.min || this.state.selectedMaxScore !== scoreRange.max) {
			this.setState({
				selectedMinScore: scoreRange.min,
				selectedMaxScore: scoreRange.max
			},
				() => this.filterIdentifiers('score')
			)
		}
	}
	
	onClickAdvancedSearhBox = () => {
		if(this.state.newSearchText === '') {
			this.setState({ isLoading: true, showDropdown: true, searchResultState: [] },
				this.getResourceSearchMetaResultsOnLoad()
			)
		}
	}

	getResourceSearchMetaResults = () => {		
		if (this.state.keyupTimer) {
			clearTimeout(this.state.keyupTimer)
		}
		if(this.state.newSearchText.length > 2) {
			this.setState({ showDropdown: true })
			let keyupTimer = setTimeout(() => {
				this.setState({ isLoading: true })
				this.props.getResourceSearchMetaResults(
					{
						search_input: this.state.newSearchText,
						size: 5,
						module_name: 'synchronizer',
						favourites: false,
					},
					() => {
						this.setState({ searchResultState: this.props.searchNewResults, isLoading: false })
					}
				)
			}, 500)
			this.setState({
				keyupTimer,
			})
		} else {
			this.setState({ showDropdown: false })
		}		
	}
	
	getInitialState() {
		return {
			from: undefined,
			to: undefined,
		};	
	}

	handleResetClick = () => {
		this.setState(() => this.getInitialState());
	}

	handleDayClick(day) {
		if(this.state.from === undefined) {
			const range = DateUtils.addDayToRange(day, this.state);
			this.setState({ range, 
				from: day, 
				durationTypeFrom: moment(range.from).format('DD MMM'),
				start_time: datestartEndTime(day, 'start')
			});
		} else {
			const newRange = {
				from: this.state.from,
				to: day,
			}
			var Difference_In_Time = day.getTime() - this.state.from.getTime(); 			
			var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
			// if(Difference_In_Days < 6) {
			// 	alert('Please select 7 days for better view')
			// 	return;
			// }
			// let durtaion = '+'+(Difference_In_Days+1)+'d';
			if(Difference_In_Days > 0) {
				const range = DateUtils.addDayToRange(day, newRange);
				this.setState(
					{ 
						range, 
						to: day, 
						showDatepicker: false, 
						relatedIssueDurtaion: 'custom',
						relatedDurtaion: 'custom',
						selectedDuration: 'custom',
						durationType: this.state.durationTypeFrom + ' - ' + moment(range.from).format('DD MMM'),
						end_time: datestartEndTime(day, 'end')
					},
					() => {
						this.filterIdentifiers('duration')
					} 
				);
			}
		}
	}
	handleMultiSelectChange = (childKey, arrayValue) => {
		let value = arrayValue.map(item => item.value)
		let label = arrayValue.map(item => item)

		if(childKey === 'selectedAccount') {
			let prevState = this.state.selectedAccount
			let selectedAccountArray = arrayValue
			if(prevState.includes('All') && value.includes("All")) {
				const index = value.indexOf('All');
				if (index > -1) {
					value.splice(index, 1);
				}
				let array = []
				value.forEach(item => {
					this.state.allAccounts.forEach(acc => {
						if(acc.account_id === item) {
							label = {}
							label.value = acc.account_id
							label.label = acc.account_name
							array.push(label)
						}
					})
				})
				selectedAccountArray = array
			} else if(prevState.includes('All') && !value.includes("All")) {
				value = []
				selectedAccountArray = []
			}
			if(value.length && value.includes("All")) {
				let selectedAccount = []
				selectedAccountArray = []
				this.state.allAccounts.forEach(item => {
					label = {}
					label.value = item.account_id
					label.label = item.account_name
					selectedAccount.push(item.account_id)
					selectedAccountArray.push(label)
				})
				this.setState({ selectedAccount, selectedAccountArray },
					() => {
						if(!prevState.length && value.includes("All")) {
							this.setState({ isAccountOpen: false })
						} else {
							this.filterIdentifiers('account')
						}
					}
				)
			} else {
				this.setState({ selectedAccount: value, selectedAccountArray },
					() => {
						if(prevState.includes("All") && !value.length) {
							this.setState({ isAccountOpen: false })
						} else {
							this.filterIdentifiers('account')
						}
					}
				)
			}
		} else if(childKey === 'selectedRegion') {
			let prevState = this.state.selectedRegion
			let selectedRegionArray = arrayValue
			if(prevState.includes('All') && value.includes("All")) {
				const index = value.indexOf('All');
				if (index > -1) {
					value.splice(index, 1);
				}
				let array = []
				value.forEach(item => {
					this.state.regions.forEach(reg => {
						if(reg.region === item) {
							let label = {}
							label.value = reg.region
							label.label = reg.name
							array.push(label)
						}
					})
				})
				selectedRegionArray = array
			} else if(prevState.includes('All') && !value.includes("All")) {
				value = []
				selectedRegionArray = []
			}

			if(value.length && value.includes("All")) {
				let selectedRegion = []
				selectedRegionArray = []
				this.state.regions.forEach(item => {
					let label = {}
					label.value = item.region
					label.label = item.name
					selectedRegion.push(item.region)
					selectedRegionArray.push(label)
				})
				this.setState({ selectedRegion, selectedRegionArray },
					() => {
						if(!prevState.length && value.includes("All")) {
							this.setState({ isRegionOpen: false })
						} else {
							this.filterIdentifiers('regions')
						}
					}
				)
			} else {
				this.setState({ selectedRegion: value, selectedRegionArray },
					() => {
						if(prevState.includes("All") && !value.length) {
							this.setState({ isRegionOpen: false })
						} else {
							this.filterIdentifiers('regions')
						}
					}
				)
			}
		}
	}
	
	getMultiSelectedCount = (type, array) => {
		return array.length && array.includes('All') ? 'All' :  array.length ? array.length +' Selected' : 'All'
		// return array.length && array.includes('All') ? 'All' : array.length ? array.length === 1 ? array[0].label : array.length +' Selected' : 'All'
	}

	onChangeServiceDropdown  = (currentNode, selectedNodes) => {
		let filteredServiceResourceArray = []	
		let selectedTreeService = []
		let selectedService = []
		if(selectedNodes.length && currentNode.label !== 'All') {
			selectedNodes.forEach(item => {
				if(item._depth === 0) {
					let child = {}
					child.label = item.label
					child.parent = 1
					selectedTreeService.push(child)
					this.state.serviceResourceArray.forEach(service => {
						if(service.label === item.label) {
							service.children.forEach(child => {
								selectedService.push(child.label)
							})
						}
					})
				} else {
					let child = {}
					child.label = item.label
					child.parent = 0
					selectedTreeService.push(child)
					selectedService.push(item.label)
				}
			})

			filteredServiceResourceArray = []
			this.state.serviceResourceArray.forEach(item => {
				let dataRow = {}
				dataRow.label = item.label
				dataRow.expanded = true
				let childArray = []
				item.children.forEach(child => {
					let childRow = {}
					childRow.label = child.label
					childRow.value = child.label
					childRow.expanded = true
					if(selectedTreeService.length) {					
						selectedTreeService.forEach(ser => {
							if(ser.parent === 1 && item.label === ser.label) {
								dataRow.checked = true
							}						
							if(ser.parent === 1 && item.label === ser.label) {
								childRow.checked = true
							} else if(ser.label === child.label){
								childRow.checked = true
							}			
						})
					}
					childArray.push(childRow)
					dataRow.children = childArray
				})		
				
				if(item.label === 'All' && currentNode.label === 'All') {
					dataRow.checked = true
				}
	
				filteredServiceResourceArray.push(dataRow)
			})
		} else if(currentNode.label === 'All' || !selectedNodes.length) {
			selectedTreeService= []
			selectedService = []
			filteredServiceResourceArray = []
			this.state.serviceResourceArray.forEach(item => {
				let dataRow = {}
				dataRow.label = item.label
				dataRow.expanded = true
				if(currentNode.checked) {
					dataRow.checked = true
				} else if(!currentNode.checked) {
					dataRow.checked = false
				}
				let childArray = []
				item.children.forEach(child => {
					let childRow = {}
					childRow.label = child.label
					childRow.value = child.label
					childRow.expanded = true
					childRow.parent = 1
					if(currentNode.checked) {
						childRow.checked = true
						selectedService.push(childRow.value)
					} else if(!currentNode.checked) {
						childRow.checked = false
					}
					childArray.push(childRow)
					selectedTreeService.push(childRow)
					dataRow.children = childArray					
				})
				filteredServiceResourceArray.push(dataRow)
			})
		}	

		let selectedServiceContent = selectedService.length === 1 ? selectedService : selectedService.length > 2 ? selectedService.length +' Selected' : 'All'
		this.setState({ filteredServiceResourceArray, selectedService, selectedServiceContent },
			() => {
				if(selectedNodes.length || (!selectedNodes.length && currentNode.label !== 'All')) {
					this.filterIdentifiers('services')
				}
			}
		)
	}

	handleClickOutside(event) {

		
			if(this.durationRef && !this.durationRef.current.contains(event.target)) {
				if (this.dayPickerRef && !this.dayPickerRef.current.contains(event.target)) {
					this.setState({ showDatepicker: false })
					this.handleResetClick()
				}
			}

			if (this.providerRef && !this.providerRef.current.contains(event.target)) {
				this.setState({ providerIsOpen: false })
			} else {
				this.setState({ providerIsOpen: true })
			}
			
			if (this.accountRef && !this.accountRef.current.contains(event.target)) {
				this.setState({ isAccountOpen: false })
			} else {
				this.setState({ isAccountOpen: true })
			}
			
			// if (this.tagRef && !this.tagRef.current.contains(event.target)) {
			// 	this.setState({ isTagOpen: false })
			// } else {
			// 	this.setState({ isTagOpen: true })
			// }

			if (this.regionRef && !this.regionRef.current.contains(event.target)) {
				this.setState({ isRegionOpen: false })
			} else {
				this.setState({ isRegionOpen: true })
			}

			// if (this.serviceRef && !this.serviceRef.current.contains(event.target)) {
			// 	this.setState({ isServiceOpen: false })
			// } else {
			// 	this.setState({ isServiceOpen: true })
			// }

			// if (this.checksStatusRef && !this.checksStatusRef.current.contains(event.target)) {
			// 	this.setState({ isChecksStatusOpen: false })
			// } else {
			// 	this.setState({ isChecksStatusOpen: true })
			// }
			
			// if (this.riskRef && !this.riskRef.current.contains(event.target)) {
			// 	this.setState({ isRiskOpen: false })
			// } else {
			// 	this.setState({ isRiskOpen: true })
			// }

			if (this.searchRef && !this.searchRef.current.contains(event.target)) {
				this.setState({ showDropdown: false })
			}

			this.setState({ showDropdownTree: false })
		// } else {
		// 	this.setState({ showDropdownTree: false })
		// }
	}

	categogryTabFunction = (params) => {

		if(this.state.selectedAdvancedSearch && Object.entries(this.state.selectedAdvancedSearch).length) {
			params = this.state.selectedAdvancedSearch;
			this.props.getAdvancedSearchResults(params, () => {
				if (this.props.assetsAdvancedList && this.props.assetsAdvancedList.length) {
					let dynamicHeaders = this.state.assetTableColumns
					if(this.props.assetsAdvancedList.headers && this.props.assetsAdvancedList.headers.length) {						
						this.props.assetsAdvancedList.headers.forEach(item => {
							let dataRow = {}
							dataRow.Header = item.label
							dataRow.accessor = item.value
							dynamicHeaders.push(dataRow)
						})
					}
					this.setState({
						showLoading: false,
						totalAssetsList: this.props.assetsAdvancedList.data,
						filteredArray: this.props.assetsAdvancedList.data,
						dynamicHeaders,
						// selectedTableRow: this.props.assets[0],
						showDetailsSection: true,
					},
						() => {
							this.getAllCounts(true)
							this.setTabs()
							this.formHeaders()
						}
					)
				}
			})
		} else {
			this.props.listAllAssets(params, () => {
				if (this.props.assets && this.props.assets.length) {
					this.setState({
						showLoading: false,
						totalAssetsList: this.props.assets,
						filteredArray: this.props.assets,
						dynamicHeaders: this.state.assetTableColumns,
						// selectedTableRow: this.props.assets[0],
						showDetailsSection: true,
					},
						() => {
							this.setTabs()
							this.formHeaders()
						}
					)
				}
			})
		}
	}	

	formHeaders = () => {
		let dataResult = this.state.filteredArray && this.state.filteredArray.length ? this.state.filteredArray : []
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === "string") {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})
			this.setState({ headers })
		}
	}

	actionFunction = () => {
		if(this.state.selectedAction === "Fix") {
			// this.redirectCheckDetails(this.state.selectedTableRow ? this.state.selectedTableRow : {})
		} else if(this.state.selectedAction === "Suppress") {
			this.setState({ showSupressInDetailsSection: true, showDetailsSection: true, showCloseSection: false })
		} else if(this.state.selectedAction === "Configure") {
			this.setState({
				showSupressInDetailsSection: false,
				showConfigureSection: true,
				showDetailsSection: true,
				showCloseSection: false,
			})
		}		
	}

	/**
	 * Renders detectors Dashboard
	 */
	render() {
		const { from, to } = this.state;
		const modifiers = { start: from, end: to };
		return (
			<div className='container-fluid overflow-auto flex-grow-1 bg-muted' onClick={ (event) => { this.handleClickOutside(event) } }> 
				{this.state.showAdvancedSearchModal ? (
					<ResourcesAdvancedSearch
						start_time={this.state.start_time}
						end_time={this.state.end_time}
						resetForm={this.state.resetForm}
						categoryCounts={this.state.categoryCounts}
						searchFrom='resources'
						onCloseModal={() => this.setState({ showAdvancedSearchModal: false })}
						selectedProvider={this.state.selectedProvider}
						selectedAccount={this.state.selectedAccount}
						getFilteredList={(adParams, searchTitle) => {
							this.setState({
								showAdvancedSearchModal: false,
								showLoading: true,
								selectedDuration: adParams.duration ? adParams.duration : this.state.selectedDuration,
								advancedResourceType: adParams.resource_type ? adParams.resource_type : '',
								advancedServiceName: adParams.service_name ? adParams.service_name : '',
								selectedTableTab: adParams.category ? this.state.categoryCounts.findIndex(item => item.category === adParams.category[0]) : 0,
								selectedAdvancedSearch: searchTitle
							}, 
								() => this.filterIdentifiers('advancedSearch')
							)
						}}
					/>
				) : null}
				<div className='row h-100'>
					<div
						className={
							this.state.filteredArray && this.state.filteredArray.length && this.state.showDetailsSection
								? 'col-sm-8 col-lg-9 p-0'
								: 'p-0 col-sm-12'
						}
					>
						<div className='title-section-new'>
							<div className='row'>
								{/* <div className='col-lg-4 align-self-center'> */}
								<div className='col-lg-4 d-flex'>
									<div>
										<div className='d-flex'>
											<h6 className='text-white m-0'>Search Resources</h6>
										</div>
										<p className='text-white m-0 mb-2'>
											Search the resources present in your Cloud
										</p>
									</div>
								</div>
								<div className={`col-lg-8 text-right ${this.state.showLoading ? 'disabled' : ''}`}>
								<div className="input-group search-drop dark-feild mb-2 mr-sm-2">
										<div className="input-group-prepend">
											<div className="input-group-text bg-transparent">
												<i className="fal fa-search"></i>
											</div>
										</div>
										<input
											ref={this.searchRef}
											type='text'
											value={this.state.newSearchText}
											onChange={e => {
												this.setState({ newSearchText: e.target.value, searchResultState: [], isLoading: true }, () => this.getResourceSearchMetaResults())
											}}
											className='form-control form-control-sm'
											placeholder='Search'
											onClick={(e => this.onClickAdvancedSearhBox())}
										/>
										
										<div className={`search-suggestion`}>
											{this.state.showDropdown && this.state.searchResultState && this.state.searchResultState.length ? 
												this.state.searchResultState.map((item, index) => {
													return (
														<div key={index} className="d-flex justify-content-between cursorPointer border-bottom p-2"
															onClick ={() =>
																this.setState({
																	showAdvancedSearchModal: false,
																	showLoading: true,
																	selectedDuration: item.parameters && item.parameters.duration ? item.parameters.duration : this.state.selectedDuration,
																	advancedResourceType: item.resource_type ? item.resource_type : '',
																	advancedServiceName: item.service_name ? item.service_name : '',
																	selectedTableTab: item.parameters && item.parameters.category ? this.state.categoryCounts.findIndex(arr => arr.category === item.parameters.category) : 0,
																	selectedAdvancedSearch: item
																}, () => this.getAdavanceAssetsOnTabChange(item.parameters && item.parameters.category ? item.parameters.category  : ''))
															}
														>
															<div className="w-70">
																<p className='font-weight-bold m-0 text-dark text-left p-0'>{item.title ? item.title : ''}</p>
																{/* <p className='small m-0 text-dark text-left p-0'>{item.description ? item.description : ''}</p> */}
															</div>
															<div className="w-20 align-self-center">
																<span className="badge badge-outline-secondary">{item.parameters && item.parameters.action ? capitalizeFirstLetter(item.parameters.action) : ''}</span>
															</div>
															{/* <div className="w-20 align-self-center">
																<span className="badge badge-outline-success mr-1">123</span>
																<span className="badge badge-outline-danger">123</span>
															</div> */}
															<div className="w-10 align-self-center">
																<i class={`fal fa-star ${item.favourite ? 'fillStar' : 'displayNone' } `}></i>
															</div>
														</div>
													)
												})
											: 
												this.state.showDropdown && this.state.isLoading && (
													<div className='d-flex justify-content-center m-4'>
														<Spinner className='text-center' color='dark' size='sm' />
													</div>
												) 
											}
												

											{this.state.showDropdown && this.state.searchResultState && !this.state.isLoading ?
												<div className="d-flex justify-content-between cursorPointer border-bottom p-2 bg-light">
													<p className="m-0 text-info" onClick={() =>
													this.setState({ resetForm: this.state.resetForm + 1, showAdvancedSearchModal: true, showDropdown: false, newSearchText: '' })}>
													<span className="fal fa-search mr-1"></span> Advanced Search</p>
												</div>
											: null
											}
										</div>
									</div>
									<div className='justify-content-between'>
										<button
											className='btn btn-link mr-5 p-0 fs-13'
											onClick={() => {
												this.setState({
													newSearchText: '',
													selectedAdvancedSearch: {},
													selectedCategory: [],
													selectedProvider: 'All',
													selectedAccount: [],
													selectedAccountArray: [],
													selectedRegion: [],
													selectedRegionArray: [],
													selectedDuration: '+14d',
													selectedService: [],
													selectedServiceContent: 'All',
													filteredServiceResourceArray: [],
													selectedMinScore: '0',
													selectedMaxScore: '100',
													scoreRangeValue: { min: 0, max: 100 }
												},
													() => this.filterIdentifiers()
												)
											}}
										>
											Reset Filter
										</button>
										<button
											className='btn btn-link p-0 fs-13'
											onClick={() =>
												this.setState({ resetForm: this.state.resetForm + 1, showAdvancedSearchModal: true })
											}
										>
											Advanced Search
										</button>
									</div>
								</div>
							</div>
							<div className='row mt-1'>
								<div className={`col-md-12 d-flex flex-wrap bd-highlight ${this.state.showLoading ? 'disabled' : ''}`}>
									<div
										className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} ref={this.providerRef}>
										<label>Provider</label>
										<Select
											placeholder={'All'}
											menuIsOpen={this.state.providerIsOpen}
											isSearchable={false}
											className='f13 p-0 colorBlack reactSelectDrop'
											value={({
												value: this.state.selectedProvider,
												label: this.state.selectedProvider
											})}
											options={this.state.providers &&
												this.state.providers.map(item => ({
												value: item.provider_name,
												label: item.provider_name,	
											}))}
											onChange={event => {
												this.setState({
														selectedProvider: event.value
													},
													() => this.filterIdentifiers('provider')
												)
											}}
										/>
									</div>
									<div
										className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} ref={this.accountRef}>
										<label>Account</label>
										<div className={`filterMultiSelectDropdown ${this.state.accounts && this.state.accounts.length > 10 ? '' : 'removeDropdownSearchBar'}`}>
											<ReactMultiSelectCheckboxes						
												placeholderButtonLabel="All"
												getDropdownButtonLabel={() => this.getMultiSelectedCount('account', this.state.selectedAccount)}
												menuIsOpen ={this.state.isAccountOpen}
												value={this.state.selectedAccountArray.map(acc => ({
													value: acc.value,
													label: acc.label
												}))}
												onChange={arr => {
												this.handleMultiSelectChange('selectedAccount', arr ? arr : []) }}
												options={this.state.allAccounts.map(account => ({
													value: account.account_id,
													label: account.account_name,
												}))}
											/>
										</div>
									</div>
									<div className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} ref={this.regionRef}>
										<label>Region</label>
										<div className={`filterMultiSelectDropdown ${this.state.regions && this.state.regions.length > 10 ? '' : 'removeDropdownSearchBar' } `}>
											<ReactMultiSelectCheckboxes						
												placeholderButtonLabel="All"
												getDropdownButtonLabel={() => this.getMultiSelectedCount('region', this.state.selectedRegion)}
												menuIsOpen ={this.state.isRegionOpen}
												value={this.state.selectedRegionArray.map(user => ({
													value: user.value,
													label: user.label
												}))}
												onChange={arr => {
												this.handleMultiSelectChange('selectedRegion', arr ? arr : []) }}
												options={this.state.regions && this.state.regions.map(region => ({
													value: region.region,
													label: region.name,
												}))}
											/>
										</div>
									</div>
									<div
										className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 text-dark serviceFilterDropdown`} ref={this.serviceRef}>
										<label className="mb-2 text-white w-100">Service</label>
										{/* <label className="text-white w-100 f13 font-weight-normal" onClick={() => this.setState({ showDropdownTree: true }) } >{this.state.selectedService && this.state.selectedService.length ? this.state.selectedService.length ===  1 ? this.state.selectedService : this.state.selectedService.length +' Selected' : 'All' }</label> */}
										<div className={`serviceTreeDropdown position-absolute mt-n1  ${this.state.filteredServiceResourceArray.length ?'': 'disabled'}`}>
											<DropdownTreeSelect 
												//values={this.state.selectedServiceContent}
												texts={{ placeholder: this.state.selectedServiceContent }}
												data={this.state.filteredServiceResourceArray}
												onChange={this.onChangeServiceDropdown}
												className="bootstrap-demo"
												keepTreeOnSearch={true}
												keepChildrenOnSearch={true}
											/>
										</div>
									</div>
									<div className='form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 resourceScore' >
										<label>Score 
											<i className="fa fa-info-circle ml-1" id="score_info"></i>
											<UncontrolledTooltip placement='top' target="score_info">Health Score of Cloud resource based on Cloud Well-Architected principles.</UncontrolledTooltip>
										</label>
										<InputRange
										maxValue={100}
										minValue={0}
										formatLabel={value => value}
										value={this.state.scoreRangeValue}
										onChange={value => this.setState({ scoreRangeValue: value })}
										onChangeComplete={value => this.scoreRangeFunction(value)} />
									</div>
									<div className='form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild' ref={this.durationRef}>
										<label>Duration
											{this.state.durationType !== '' ? <small className="pl-3">{this.state.durationType}</small> : ''}
										</label>
										<select
											className='form-control'
											value={this.state.durationType !== '' ? 'custom' : this.state.selectedDuration}
											onChange={event => {
												if(event.target.value !== 'custom') {													
													this.handleResetClick()
													this.setState({ 
														selectedDuration: event.target.value, 
														relatedDurtaion: event.target.value, 
														showDatepicker: false, 
														selectedDatePicker: false, 
														durationType: '' 
													},
														() => this.filterIdentifiers('duration')
													)
												} else { 
													this.setState({
														showDatepicker: true,
													})
													//this.handleClickOutside('')
												}
											}}
											onClick={event => {
												if(event.target.value === 'custom') {
													this.setState({
														showDatepicker: true
													})
												}
											}}
										>
											{CUSTOM_DURATION_FILTER_OPTIONS.map((item, index) => {
												return (
													<option key={index} value={item.value}>
														{item.option}
													</option>
												)
											})}
										</select>
									</div>
								</div>
							</div>
							<div className={`RangeExample customDayPicker ${this.state.showDatepicker ? '' : 'displayNone'}`} ref={this.dayPickerRef}>
								<DayPicker
								toMonth={new Date()}
								disabledDays={[
									{
										after: new Date(),
										before: (new Date(this.state.previousCustomDays))
									},
								]}
								className="Selectable"
								numberOfMonths= {3}																			
								selectedDays={[from, { from, to }]}
								modifiers={modifiers}
								onDayClick={this.handleDayClick}
								tabIndex={0}
								/>								
								<div class="col-sm-12">
									<button class="resetBtn f15 float-right mb-1" onClick={this.handleResetClick}>Reset</button>
								</div>
							</div>
							<div className={`row mt-2 ${this.state.filteredArray && this.state.filteredArray.length ? '' : 'displayNone'}`}>
								<div className='col-lg-2 col-md-6'>
									{this.state.showDetailsSection && this.state.providerDoughnutSeries && this.state.providerDoughnutSeries.length ? 
										<div className="providerDonut">
											<div className="text-white text-center font-weight-bold fs-12">Provider</div>
											<ApexChart options={this.state.providerDoughnutOptions} series={this.state.providerDoughnutSeries} type="donut" width={210}/>
										</div>
									: null
									}
								</div>
								<div className='col-lg-2 col-md-6 align-self-center'>
									{this.state.showDetailsSection && this.state.statusDoughnutSeries && this.state.statusDoughnutSeries.length ? 
										<div className="providerDonut">
											<div className="text-white text-center font-weight-bold fs-12">Status</div>
											<ApexChart options={this.state.statusDoughnutOptions} series={this.state.statusDoughnutSeries} type="donut" width={220}/>
										</div>
									: null
									}
								</div>
								<div className='col-lg-2 col-md-6 align-self-center'>
									{this.props.regionCounts && this.props.regionCounts.length ? (
										<div className='d-flex flex-column text-white ml-2'>
											<div className='d-flex mb-2 justify-content-between'>
												<div className='mr-3 text-nowrap'>
													<p class="text-white text-center font-weight-bold fs-12 mb-0">Regions
														{this.props.regionCounts.length > 4 ? 
															<span class="ml-4 btn-link text-decoration-none cursor-pointer align-self-center ml-2" 	id='moreRegionButton'>More</span>
														: null }
													</p>
												</div>
												{this.props.regionCounts.length > 4 ? (
													<UncontrolledPopover
														placement='bottom'
														trigger='legacy'
														target='moreRegionButton'
														className='h-25'
													>
														<div className="col-sm-12 float-left m-0 p-0 px-3 mt-2">
															<p className="col-sm-6 m-0 p-0 float-left font-weight-bold text-primary">Regions</p>
															<p className="col-sm-6 m-0 p-0 float-right text-right font-weight-bold text-primary">Resources</p>
														</div>
														<PopoverBody>
															<div className="col-sm-12 float-left m-0 p-0">
																<ListGroup>{this.renderRegionList(true)}</ListGroup>
															</div>
														</PopoverBody>
													</UncontrolledPopover>
												) : null}
											</div>
											<div className='d-flex fs-12'>
												<div className='mr-3 text-nowrap'>{this.renderRegionList()}</div>
											</div>
										</div>
									) : null}
								</div>
								<div className='col-lg-3 col-md-6 align-self-center'>
									{this.props.scoreCounts && this.props.scoreCounts.length ? (
										<div className='score-visual-asset'>
											{this.props.scoreCounts.length ? (
												<div className='text-white text-center mb-3 font-weight-bold fs-12'>Score</div>
											) : null}
											<div className='d-flex flex-wrap'>
												{this.props.scoreCounts.length
													? this.props.scoreCounts.map((item, index) => {
															return (
																<div className='pr-2 mb-1 w-50' key={index}>
																	<div className='bd-highlight d-flex'>
																		<small className='text-white bd-highlight fs-8 align-self-center'>
																			{item.score}
																		</small>
																		<small className='text-white bd-highlight ml-auto'>{item.total_count}</small>
																	</div>
																	<div className='progress'>
																		<div
																			className={`${
																				item.score === '0-25' ? item.total_count > 0 ? `scorebg-${index + 1}` : `scorebg-${index + 1} bg-white1`
																					: item.score === '25-50'
																					? item.total_count > 0 ? `scorebg-${index + 1}` : `scorebg-${index + 1} bg-white1`
																					: item.score === '50-75'
																					? item.total_count > 0 ? `scorebg-${index + 1}` : `scorebg-${index + 1} bg-white1`
																					: item.score === '75-100'
																					? item.total_count > 0 ? `scorebg-${index + 1}` : `scorebg-${index + 1} bg-white1`
																					: ''
																			} progress-bar`}
																			role='progressbar'
																			style={{ width: `${(item.total_count / this.getTotalScoreCounts()) * 100}%` }}
																			// aria-valuenow={item.total_count}
																			// aria-valuemin={item.total_count}
																			// aria-valuemax={this.getTotalScoreCounts()}
																		/>
																	</div>
																</div>
															)
													  })
													: null}
											</div>
										</div>
									) : null}
								</div>
								<div className='col-lg-3 col-md-6 align-self-center'>
									{this.props.dailyAssetCount && Object.keys(this.props.dailyAssetCount).length ? (
										<React.Fragment>
											{/* <div className='text-light d-flex row justify-content-between'>
												<small className='ml-5'>{moment(this.state.dailyAssetCount.labels[0]).format('DD MMM')}</small>
												<small>
													{moment(
														this.state.dailyAssetCount.labels[this.state.dailyAssetCount.labels.length - 1]
													).format('DD MMM')}
												</small>
											</div> */}
										<div>

											{this.state.showDetailsSection && this.state.series && this.state.series.length ? (
												<React.Fragment>
													<div className="text-white text-center font-weight-bold fs-12 displayNone">Trend</div>
													<div className='mt-n4 mb-n4 transparentTooltip'>
													<ApexChart type="area" series={this.state.series} options={this.state.options} height={120}/>
													</div>
												</React.Fragment>
											) : null}
										</div>
										</React.Fragment>
									) : null}
								</div>
							</div>
						</div>

						<div className='box custom-margin bg-dark3'>
							{this.state.categoryCounts && this.state.categoryCounts.length ? (
								<div>
									<ul
										ref={this.tabRef}
										className={`nav nav-tabs bd-highlight bg-dark3 ${this.state.showLoading ? 'disabled' : ''}`}
										id='myTab'
										role='tablist'
									>
										<li className='nav-item'>
											<span
												className={`nav-link custom-link text-white ${this.state.selectedTableTab === -1 ? 'active' : ''}`}
												id='home-tab'
												data-toggle='tab'
												onClick={() => {
													const params = {}
													if (this.state.selectedDuration !== '' && this.state.selectedDuration !== 'all') {
														if(this.state.selectedDuration === 'custom')  {
															params.start_time = this.state.start_time
															params.end_time = this.state.end_time
														} else {
															params.duration = this.state.selectedDuration
														}
													}
													this.setState({ selectedCategory: 'All', showLoading: true, selectedTableTab: -1 }, 
														() => this.categogryTabFunction(params)
													)
												}}
												role='tab'
												aria-selected='true'
											>
												All
												<span className='badge badge-outline-light d-table m-auto'>{this.getTotalCategoryCounts()}</span>
											</span>
										</li>
										{this.state.categoryCounts &&
											this.getTotalCategoryCounts() !== 0 &&
											this.state.categoryCounts.length &&
											this.state.categoryCounts.map((item, index) => {
												if (index < this.state.tabItemsToShow && item.total_count !== 0)
													return (
														<li
															className='nav-item'
															key={index}
															onClick={() => this.getAllAssetsOnTabChange(item.category)}
														>
															<span
																className={`nav-link custom-link text-white ${
																	this.state.selectedTableTab === index ? 'active' : ''
																}`}
																id='profile-tab'
																data-toggle='tab'
																onClick={() => this.setState({ selectedTableTab: index })}
																role='tab'
																aria-selected='false'
															>
																{item.category}
																<span className='badge badge-outline-light d-table m-auto'>{item.total_count}</span>
															</span>
														</li>
													)
												return null
											})}
										<li
											className={`nav-item ml-auto bd-highlight align-self-center ${
												this.state.categoryCounts && this.state.categoryCounts.length >= this.state.tabItemsToShow ? '' : 'd-none'
											}`}
										>
											<ButtonDropdown
												isOpen={this.state.showTabListDropdown}
												toggle={() => this.toggleShowTabListDropdown()}
											>
												<DropdownToggle
													tag='a'
													className={`nav-link custom-link ${
														this.state.categoryCounts && !this.state.categoryCounts.length && this.getTotalCategoryCounts() === 0 ? 'd-none' : ''
													}`}
												>
													<i className='fal fa-angle-double-right cursor-pointer' />
												</DropdownToggle>
												<DropdownMenu
													right
													modifiers={{
														setMaxHeight: {
															enabled: true,
															order: 890,
															fn: data => {
																return {
																	...data,
																	styles: {
																		...data.styles,
																		overflow: 'auto',
																		maxHeight: 300,
																	},
																}
															},
														},
													}}
												>
													{this.state.categoryCounts &&
														this.getTotalCategoryCounts() !== 0 &&
														this.state.categoryCounts.map((item, index) => {
															if (index >= this.state.tabItemsToShow && item.total_count !== 0)
																return (
																	<DropdownItem key={index} onClick={() => this.onClickDropDownItem(item, index)}>
																		{item.category}
																	</DropdownItem>
																)
															return null
														})}
												</DropdownMenu>
											</ButtonDropdown>
										</li>
									</ul>
								</div>
							) : null}
							
							{this.state.totalAssetsList && this.state.totalAssetsList.length && !this.state.showLoading ?
								<div className="container-fluid">
									<div className="row mt-2">
										<div className="col-sm-7 align-self-center">
											{this.state.selectedAdvancedSearch && Object.entries(this.state.selectedAdvancedSearch).length ? 
												<React.Fragment>
													<p className='font-weight-bold mb-0'>{this.state.selectedAdvancedSearch.title}</p>
													{/* <p className='mb-0 small'>{this.state.selectedAdvancedSearch.description}</p> */}
												</React.Fragment>
											: null
											}
										</div>
										<div className={`d-flex col-sm-5 justify-content-end`}>
											<CSVLink 
												data={this.state.filteredArray} 
												headers={this.state.headers ? this.state.headers : []}
												filename={"resource-search.csv"}
												className={"align-self-center"}
												target="_blank"
											>
												<i className="fas fa-download text-primary-color"></i>
												{/* <span className="text-primary-color mx-2">csv</span> */}
											</CSVLink>
											<Search
												data={this.state.totalAssetsList && this.state.totalAssetsList}
												topClassName={'ml-2 w-80'}
												// applyTags={false}
												// applyLiteDarkTags={true}
												filteredData={filteredArray => {
													if (filteredArray.length) {
														this.props.setSelectedTableRow(filteredArray[0])
														this.setState({ filteredArray })
													} else {
														this.setState({ filteredArray: [], selectedTableRow: {} })
													}
												}}
												// className='form-control-sm bg-transparent textboxBorder'
												// topClassName={'bg-gray1'}
												// searchClassName={'textboxBorder displayNone'}
												// searchIconColor={'text-dark'}
											/>
										</div>
									</div>
								</div>
							: null}
							<div className='tab-content px-3 py-1' id='myTabContent'>
								<div className={`tab-pane fade show active ${this.state.tableClass}`} id='home' role='tabpanel' aria-labelledby='home-tab'>
									{this.state.filteredArray && this.state.filteredArray.length && !this.state.showLoading ? (
										<ResizeableDarkThemeTable
											columns={this.state.dynamicHeaders}											
											data={this.state.filteredArray}
											onClickRow={tableRow => {
												this.props.setSelectedTableRow(tableRow)
												this.setState({
													selectedTableRow: tableRow,
													selectedMonitoringDuration: '2h',
													selectedDetailsTab: 0,
													selectedTab: 'asset_details',
													relatedDurtaion: this.state.selectedDuration
												}, 
													() => this.getProviderRegion()
												)
												this.getAssetDetails(tableRow)
												this.getAssetSupressList(tableRow)
											}}
											selectedRecord={this.state.selectedTableRow}
											perPage={20}
											tooltipFor={[1, 2]}
											riskTooltip={[0]}
											tableBody={"bg-dark"}
											rounded={true}
											dashboard={this.state.filteredArray && this.state.filteredArray.length ? true : false}
										/>
									) : (!this.state.showLoading && this.state.filteredArray && !this.state.filteredArray.length) ||
									  (this.state.filteredArray && !this.state.filteredArray.length && !this.state.showLoading) ? (
										<div className='d-flex justify-content-center m-4'>
											<p>There are no data on this criteria. Please try adjusting your filter.</p>
										</div>
									) : null}
									{this.state.showLoading ? (
										<div className='d-flex justify-content-center m-4'>
											<Spinner className='text-center' color='white' size='lg' />
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>
					<div className={this.state.filteredArray && this.state.filteredArray.length  ? 'col-sm-4 col-lg-3 p-0 bg-dark3' : 'displayNone'}>
						{this.state.showConfigureSection ? (
							<ConfigureSection
								data={this.state.assetConfigrationsList}
								onClickBackButton={() => this.setState({ showConfigureSection: false, showDetailsSection: true, selectedAction: "" })}
							/>
						) : null}

						<div
							className={
								this.state.filteredArray &&
								this.state.filteredArray.length &&
								this.props.selectedTableRow &&
								Object.entries(this.props.selectedTableRow).length &&
								!this.state.showConfigureSection
								//this.props.selectedTableRow.constructor === Object &&
									? 'detail'
									: 'd-none'
							}
						>
							<div className='detail-title bg-dark3'>
								<div className='d-flex justify-content-between'>
									<Link
										className='text-decoration-none badge badge-secondary align-self-start'
										to={{
											pathname: URL_PATH.RESOURCES_DETAILS + '/' + this.props.selectedTableRow.asset_id,
											state: { asset: this.props.selectedTableRow, backToScreenUrl: URL_PATH.RESOURCES_SEARCH },
										}}
									>
										View more details
									</Link>
									<div className="select-sm-bg curvedDropdown minWidth120" toggle={() => this.toggleActionDropdown()}>
										<div className="dropdown">
											<span className="dropdown-toggle d-flex justify-content-between px-2 text-white cursorPointer" onClick={() => this.setState({ isActionDropdownOpen: !this.state.isActionDropdownOpen })}>
												{this.state.selectedAction && this.state.selectedAction !== "" ? this.state.selectedAction : <span className="placeholder">Action</span>}
												<span className="caret"></span>
												{/* <i className="fas fa-angle-down"></i> */}
											</span>
											<ul className={`dropdown-menu cursorPointer p-0 ${this.state.isActionDropdownOpen ? "show" : ''}`}>
												{actionOptions.map(item => {
													return (
														<li 
															onClick={() => this.setState({ 
																selectedAction: item, 
																isActionDropdownOpen: false
															},
																() => {
																	this.actionFunction()
																}
															)}
															className={`${this.state.selectedAction === item ? "selected" :  ""}`}
														>
															{item}
														</li>
													)
												})}
											</ul>
										</div>
									</div>
									
									<div className='dropdown mb-2 displayNone'>
										<ButtonDropdown isOpen={this.state.isActionDropdownOpen} toggle={() => this.toggleActionDropdown()} />
									</div>
								</div>
								<div className='d-flex'>
									<div className={`d-flex flex-column health-score mr-3 justify-content-end  bg-muted`}>
										<h5 className='m-0 text-white'>{this.props.selectedTableRow.score !== undefined ? this.props.selectedTableRow.score : 'NA' }</h5>
										<small className='fs-12 badge badge-primary rounded-0 mt-1'>Health</small>
									</div>
									<div className='align-self-center text-white'>
										<table>
											<tbody>
												<tr>
													<td className='text-right pr-2 align-top'>{this.props.selectedTableRow.provider ? this.props.selectedTableRow.provider.toUpperCase() : ''}&nbsp;: </td>
													<td>
														{this.props.selectedTableRow.region === 'NA' ? 'Global' :
														this.state.tableRowRegions && this.state.tableRowRegions.length && this.props.selectedTableRow && Object.keys(this.props.selectedTableRow).length
														? 
															this.state.tableRowRegions.filter(region => region.region === 		this.props.selectedTableRow.region).length 
															? 
																this.state.tableRowRegions.filter(region => region.region === this.props.selectedTableRow.region)[0].name
															:  this.props.selectedTableRow.region
														: 
														this.props.selectedTableRow.region}
													</td>
												</tr>
												<tr>
													<td className='text-right pr-2 align-top'>Account&nbsp;: </td>
													<td>
														{this.state.accounts.length && this.props.selectedTableRow && Object.keys(this.props.selectedTableRow).length
															? this.state.accounts.filter(
																	account => account.account_id === this.props.selectedTableRow.account_id
															)
																? this.state.accounts.filter(
																		account => account.account_id === this.props.selectedTableRow.account_id
																)[0].account_name
																: '-'
															: ':' + getAccountNameFromId(this.props.selectedTableRow.account_id, this.state.accounts)}
													</td>
												</tr>
												<tr>
													<td className='text-right pr-2 align-top'>Resource&nbsp;: </td>
													<td>{this.props.selectedTableRow.resource_type}</td>
												</tr>
												<tr>
													<td className='text-right pr-2 align-top'>Service&nbsp;: </td>
													<td>{this.props.selectedTableRow.service_name}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
								<span className='badge badge-pill badge-secondary p-2 mt-2 text-break text-wrap text-white font-weight-bold'>
									{this.props.selectedTableRow.asset_name}
								</span>
								{/* <p className='mt-2 mb-0'>
									<small className='text-white mt-2'>
										{this.props.selectedTableRow.status === 'pass'
											? this.props.selectedTableRow.description
											: this.props.selectedTableRow.detail}
									</small>
									<small className='text-white mt-2'>{this.props.selectedTableRow.additional_details}</small>
								</p> */}
								{/* <p className='mt-2 mb-0'>
									<small className='text-white mt-2'>
										{this.props.selectedTableRow && this.props.selectedTableRow.description ? this.props.selectedTableRow.description : ''}
									</small>
								</p> */}
							</div>
							<div className='detail-content bg-dark3'>
								{/* View Configuration */}
								{this.state.showSupressInDetailsSection ? (
									<SupressSection
										onClickSupressBackButton={() => {
											this.setState({
												showSupressInDetailsSection: false,
												showDetailsSection: true,
												onClickSupressCreateButton: false,
												// selectedDetailsTab: 0,
											})
										}}
										supressRules={this.state.supressRules}
										showLoading={this.state.showSupressSectionLoading}
										providersData={this.props.providers}
										selectedRowAssetDetails={this.state.selectedRowAssetDetails}
										showSupressForm={this.state.onClickSupressCreateButton}
										onClickSupressCreateButton={bool => this.setState({ onClickSupressCreateButton: bool, selectedAction: "" })}
									/>
								) : null}

								{this.state.showDetailsSection ? (
									<DetailsSection
										selectedTableRow={this.props.selectedTableRow}
										selectedTab={this.state.selectedTab}
										detailsTabNames={this.state.detailsTabNames}
										detailsTabItemsToShow={this.state.detailsTabItemsToShow}
										showDetailsTabListDropdown={this.state.showDetailsTabListDropdown}
										selectedDetailsTab={this.state.selectedDetailsTab}
										selectedRowAssetDetails={this.state.selectedRowAssetDetails}
										selectedRowMonitoringSnapshot={this.state.selectedRowMonitoringSnapshot}
										selectedRowBillingDetails={this.state.selectedRowBillingDetails}
										showDetailsAssetSectionLoading={this.state.showDetailsAssetSectionLoading}
										showDetailsRelatedIssueLoading={this.state.showDetailsRelatedIssueLoading}
										showDetailsBillingSectionLoading={this.state.showDetailsBillingSectionLoading}
										showDetailsMonitoringSectionLoading={this.state.showDetailsMonitoringSectionLoading}
										showDetailsEventsSectionLoading={this.state.showDetailsEventsSectionLoading}
										toggleDetailsSectionLoading={(from, bool) => this.handleLoadingToggle(from, bool)}
										getAssetDetails={item => this.getAssetDetails(item)}
										openCloseModal={(item, relatedIssueDurtaion) => this.openCloseModal(item, relatedIssueDurtaion)}
										getRelatedIssues={(asset, relatedIssueDurtaion) => this.getRelatedIssues(asset, relatedIssueDurtaion)}
										getBillingSnap={item => this.getBillingSnap(item)}
										getAssetEvents={(item, duration) => this.getAssetEvents(item, duration)}
										eventSeries={this.state.eventSeries}
										eventOptions={this.state.eventOptions}
										getMonitoringSnap={(item, duration) => this.getMonitoringSnap(item, duration)}
										dayWiseCountGraphData={this.state.dayWiseCountGraphData}
										recentSeries = {this.state.recentSeries}
										recentOptions = {this.state.recentOptions}
										toggleDetailsTabListDropdown={() => this.toggleDetailsTabListDropdown()}
										onClickDetailsTabDropDownItem={(item, index) => this.onClickDetailsTabDropDownItem(item, index)}
										setSelectedDetailsTabIndex={index => this.setState({ selectedDetailsTab: index })}
										relatedIssuesData={this.state.relatedIssuesData}
										filteredRelatedIssuesData={this.state.filteredRelatedIssuesData}
										getRelatedTableData={(category, selectedResourceStatus, status) => this.getRelatedTableData(category, selectedResourceStatus, status)}
										topEventsData={this.state.topEventsData}
										recentEventsData={this.state.recentEventsData}
										eventGraphData={this.state.eventCountData}
										selectedMonitoringDuration={this.state.selectedMonitoringDuration}
										selectedDuration={this.state.selectedDuration}
										start_time={this.state.start_time ? this.state.start_time: ''}
										end_time={this.state.end_time ? this.state.end_time: ''}
										relatedDurtaion={this.state.relatedDurtaion}
										durationType={this.state.durationType}
										categoryCounts={this.state.categoryCounts}
									/>
								) : null}
							</div>
						</div>
					</div>
				</div>
				
				<React.Fragment>
					<Modal className="closeIssue" backdrop="static" isOpen={this.state.modalClose} toggle={this.toggleCloseModal}>
						<ModalHeader toggle={this.toggleCloseModal}>  
							{this.state.modalHeader} 
						</ModalHeader>
						<ModalBody>
							<Form>
								<Row>
									<Col md={6}>
										<FormGroup>
											<Label>Status</Label>
											<Input
												disabled={true}
												type='select'
												className="form-control-small"
												value={this.state.closeStatus}
												onChange={event => {
													this.setState(
														{
															closeStatus: event.target.value
														}
													)
												}}
											>
												<option value='resolved'>Resolved</option>
											</Input>
										</FormGroup>
									</Col>
									<Col md={6}>
										<FormGroup>
											<Label>Status Type</Label>
											<Input
												type='select'
												className="form-control-small"
												value={this.state.closeStatusType}
												onChange={event => {
													this.setState(
														{
															closeStatusType: event.target.value
														}
													)
												}}
											>
												<option value=''>Select</option>
												<option value='resolved_verified'>Resolved & Verified</option>
												<option value='not_issue'>Not an Issue</option>
												<option value='duplicate'>Duplicate</option>
											</Input>
											{this.state.hasErrorInRuleForm && this.state.selectedStatusType === '' ? (
												<p className='text-danger'>Status Type is required.</p>
											) : null}
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col md={12}>
										<FormGroup>
											<Label>Reason</Label>
											<textarea className="w-100 borderGray" value={this.state.closeReason} onChange={event => this.setState({ closeReason: event.target.value })} rows="2" placeholder='Reason for closing'>
												
											</textarea>
											{this.state.hasErrorInRuleForm && this.state.closeReason === '' ? (
												<p className='text-danger'>Reason is required.</p>
											) : null}
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col md={12}>
										<FormGroup>
											<Label>Proof <span className={`f11 resetBtn ${this.state.closeAttachment !== '' ? 'cursorPointer' : 'displayNone' }`} onClick={()=> this.setState({ closeAttachmentKey: true, closeAttachment: '', hasErrorInRuleForm: false })}>Clear </span></Label>
											<Input
												type='file'
												//accept="image/jpg, image/png"
												className="form-control-sm"
												id="closeAttachmentId"
												key={this.state.closeAttachmentKey &&	this.state.closeAttachment}
												onChange={event => { this.fileChangedHandler(event) }}
											/>
											{this.state.hasErrorInRuleForm && this.state.closeAttachment === '' ? (
												<p className='text-danger f11'>{this.state.attachmentMessage}</p>
											) : (
												''
											)}
										</FormGroup>
									</Col>
									{/* <Col md={2}>
										<button className={`f11 rfes 'info cursorPointer' : 'displayNone'} `} onClick={()=> this.setState({ closeAttachmentKey: true, closeAttachment: '' })}>Clear </button>
									</Col> */}
								</Row>
							</Form>
						</ModalBody>
						<ModalFooter className="col-sm-12 m-0 p-0">
							<div className="col-sm-5 m-0 p-0">
								{this.state.showMessage ?
									<p className={`m-0 p-0 align-self-center ${this.state.responseStatus ? 'text-success' : 'text-danger' }`}>{this.state.responseMessage}</p> 
								: ''}
							</div>
							<div className={`col-sm-6 float-right text-right ${this.state.disableOnSubmit ? 'disabled' : ''}`}>
								<button className='btn btn-sm btn-light mr-3' onClick={this.toggleCloseModal}>
									Back
								</button>
								<button
									className={`btn btn-sm btn-primary ${this.state.closeLoading ? 'disabled' : ''}`}
									onClick={() => this.onModalSubmit()}
									>
									{this.state.closeLoading ? (
										<React.Fragment>
											{'submitting'}
											<Spinner className='text-center ml-2' color='light' size='sm' />{' '}
										</React.Fragment>
									) : 'Submit'
									}
								</button>
							</div>
						</ModalFooter>
					</Modal>
					
				</React.Fragment>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
AssetsDashboard.propTypes = {
	listAllProviders: PropTypes.func,
	listAllAccounts: PropTypes.func,
	listAllRegions: PropTypes.func,
	listAllServices: PropTypes.func,
	listAllAssets: PropTypes.func,
	getAdvancedSearchResults: PropTypes.func,
	getTotalCount: PropTypes.func,
	getDayWiseCount: PropTypes.func,
	getMonitoringSnapShot: PropTypes.func,
	getTopEvents: PropTypes.func,
	getRecentEvents: PropTypes.func,
	listAllSuppressors: PropTypes.func,
	getAssetDetails: PropTypes.func,
	getBillingSnapshot: PropTypes.func,
	getEventGraphCounts: PropTypes.func,
	getRelatedIssues: PropTypes.func,
	getTotalAssetCount: PropTypes.func,
	getDailyAssetCount: PropTypes.func,
	history: PropTypes.object,
	assets: PropTypes.array,
	setSelectedTableRow: PropTypes.func,
	providers: PropTypes.array,
	accounts: PropTypes.array,
	services: PropTypes.array,
	regions: PropTypes.array,
	selectedTableRow: PropTypes.object,
	providerCounts: PropTypes.array,
	statusCounts: PropTypes.array,
	regionCounts: PropTypes.array,
	scoreCounts: PropTypes.array,
	dailyAssetCount: PropTypes.object,
	selectedFilters: PropTypes.object,
	getResourceSearchMetaResults: PropTypes.func,
	searchNewResults: PropTypes.array,
	resolveIssue: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('state',state)
	return {
		assets: state.assets.assets.results,
		assetsAdvancedList: state.assets.assetsAdvancedList,
		providers: state.filters.providers,
		services: state.filters.services,
		regions: state.filters.regions && state.filters.regions.length ? state.filters.regions[0].provider_regions : [], 
		accounts: state.filters.accounts,
		selectedTableRow: state.assets.selectedTableRow,
		providerCounts: state.assets.providerCounts,
		statusCounts: state.assets.statusCounts,
		regionCounts: state.assets.regionCounts,
		scoreCounts: state.assets.scoreCounts,
		dailyAssetCount: state.assets.dailyAssetCount,
		selectedFilters: state.assets.selectedFilters,
		searchNewResults: state.filters.searchMetaList,
	}
}

export default AppWrapper(AssetsDashboard, mapStateToProps, {
	listAllAssets,
	getAdvancedSearchResults,
	listAllProviders,
	listAllAccounts,
	listAllServices,
	listAllRegions,
	getAssetDetails,
	getBillingSnapshot,
	getRelatedIssues,
	getMonitoringSnapShot,
	getMonitoringMetricsByTrend,
	listAllSuppressors,
	getTotalCount,
	getDayWiseCount,
	getTopEvents,
	getRecentEvents,
	getEventGraphCounts,
	getTotalAssetCount,
	getDailyAssetCount,
	setSelectedTableRow,
	getResourceSearchMetaResults,
	resolveIssue,
})
