import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import TextField from '../../../common/formFields/TextField'
import SelectField from '../../../common/formFields/SelectField'
import { setAiopsPropsDetails } from '../../../../actions/aiops/AiopsAction'
import { listAllRegions} from '../../../../actions/commonAction'

class CreateRDSReadReplica extends Component {

  constructor(props) {
    super(props);
    this.state = props || {};
    this.state = {
      validationError: this.props.validationError,
      validationErrorMessage: this.props.validationErrorMessage,
      parentValue: this.props.value,
    }
    this.updateFormFieldValue = this.updateFormFieldValue.bind(this);
    this.updateTextFormFieldValue = this.updateTextFormFieldValue.bind(this);
  }

  componentDidMount = () => {
    let params = {}
    params.provider = Array.isArray(this.props.caseDetails.provider) ? this.props.caseDetails.provider[0].toLowerCase() : this.props.caseDetails.provider.toLowerCase()

    this.props.listAllRegions(params, (promise, result) => {
      if(promise) {
        let regionOptions = []
        if(result[0] && result[0].provider_regions) {
          result[0].provider_regions.forEach(item => {
            let dataRow = {}
            dataRow.label = item.name
            dataRow.options = item.region
            regionOptions.push(dataRow)
          })
        }
        this.setState({ regionOptions })
      } else {
        this.setState({ regionOptions: [] })
      }
    })
  }

	componentDidUpdate = (prevProps) => {
		if(this.props.validationError !== prevProps.validationError) {
			this.setState({ 
        validationError: this.props.validationError, 
        replicaIdentifierError: this.props.aiopsRemediationParams.replicaIdentifierError ? this.props.aiopsRemediationParams.replicaIdentifierError : '',
        targetRegionError: this.props.aiopsRemediationParams.targetRegionError ? this.props.aiopsRemediationParams.targetRegionError : ''
      })
		}
	}

  updateFormFieldValue = (fieldName, fieldValue) => {
    let obj = this.props.aiopsRemediationParams
    obj[fieldName] = fieldValue
    obj.processor_rule_id = this.props.selectedRecord
    this.props.setAiopsPropsDetails('remediationParams', obj)
    this.setState({ [fieldName]: fieldValue, targetRegionError: '' });
  };

  updateTextFormFieldValue = (fieldName, fieldValue) => {
    let obj = this.props.aiopsRemediationParams
    obj[fieldName] = fieldValue
    obj.processor_rule_id = this.props.selectedRecord
    this.props.setAiopsPropsDetails('remediationParams', obj)
    this.setState({ [fieldName]: fieldValue, replicaIdentifierError: '',  });
  };

  render() {
    return (
      <div className="row mt-2">
        <div className="col-md-12">
          <div className="form-group">
            <label>Name of Replica DB Instance</label>
            <TextField
              className="form-control bg-muted text-gray3 col-sm-3"
              labelClassName={'text-gray3'}
              recordSelection={this.updateTextFormFieldValue}
              value={this.state.AllocatedStorage ? this.state.AllocatedStorage : ''}
              name={'replica_identifier'}
              label="Name of Replica DB Instance"
              placeholder=""
              required={true}
              unitLabel={''}
              number={true}
            />
            {this.state.validationError && this.state.replicaIdentifierError && this.state.replicaIdentifierError !== '' ?
              <small className='text-danger'>{this.state.replicaIdentifierError}</small>
            : null }
          </div>
          <div className="form-group">
            <label>Region to Replicate</label>
            <SelectField 
              className="f13 p-0 form-control dark-select-theme col-sm-6"
              recordSelection={this.updateFormFieldValue}
              value={this.state.target_region ? this.state.target_region : ''}
              name={'target_region'}
              label='Select'
              placeholder="All"
              required={false}
              selectOptions={this.state.regionOptions ? this.state.regionOptions : []}
            />
            {this.state.validationError && this.state.targetRegionError && this.state.targetRegionError !== '' ?
              <small className='text-danger'>{this.state.targetRegionError}</small>
            : null }
          </div>
        </div>
      </div>
    )
  }
};

/**
 * Type of the props used in the component
 */
 CreateRDSReadReplica.propTypes = {
  listAllRegions: PropTypes.func,
  setAiopsPropsDetails: PropTypes.func
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
 const mapStateToProps = state => {	
	console.log('CreateRDSReadReplica', state)  
	return {
    caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : [],
    aiopsRemediationParams: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.remediationParams ? state.aiops.aiopsPropsDetails.remediationParams : {},
    aiopsSelectedRemediationProps: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.selected_remediation ? state.aiops.aiopsPropsDetails.selected_remediation : {},
  }
}


export default connect(mapStateToProps, {
  listAllRegions,
  setAiopsPropsDetails
})(withRouter(CreateRDSReadReplica))
