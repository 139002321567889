/*************************************************
 * Tvastar
 * @exports
 * @file ViewPowerTuningSidePanel.js
 * @author Prakash // on 13/03/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _, { filter } from 'lodash'
// import PropTypes from 'prop-types'
import { Spinner, Input } from 'reactstrap'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

import { store as CommonNotification } from 'react-notifications-component';

import { getAccountNameFromId, capitalizeFirstLetter, onlyNumeric, momentConvertionUtcToLocalTime, getRegionName } from '../../../utils/utility'
import Search from '../../common/SearchComponent'
import { listAllAccounts, listAllRegions, getAllTagsKeys, getAllTagsValues } from '../../../actions/commonAction'
import { getPowerTuningResults } from '../../../actions/cost/CostAction'

import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'

class ViewPowerTuningSidePanel extends Component {
	constructor(props) {
		super(props)
        this.toggleRef = React.createRef()
		this.state = {
            minMaxHeight: 700,
			showLoading: true
		}
	}

	componentDidMount = () => {
		document.addEventListener('keydown', this._handleKeyDown)
		this.getWindowSize()
		window.addEventListener('resize', () => {
            this.getWindowSize()
        })

		this.filters()

		this.getPowerTuningResults()
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
        let headerHeihgt = 100
		let outerHeight = (window.outerHeight - browserBorder - headerHeihgt)

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }

	_handleKeyDown = event => {
		if(this.state.onSaveFunction) {
			switch (event.keyCode) {
				case 27:
					this.props.closeSidePanel()
					break
				default:
					break
			}
		}
	}

	filters = () => {
		let params = {}
		
		if(this.props.accounts && this.props.accounts.length) {
			let filterdAccounts = this.props.accounts
			this.setState({ accounts: filterdAccounts })
		} else {
			this.props.listAllAccounts(params, (promise, allAccounts) => {
				if (promise) {						
					let filterdAccounts = allAccounts
					this.setState({ accounts: filterdAccounts })
				}
			})
		}
		

		if(this.props.regions && this.props.regions.length) {
			let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
			this.setState({ regions: providerRegions })
		} else {
			this.props.listAllRegions(params, (promise, regions) => {
				if (promise) {
					let providerRegions = regions && regions[0] && regions[0].provider_regions
					this.setState({ regions: providerRegions })
				} else {
					this.setState({ regions: [] })
				}
			})
		}
	}

    handleClickOutside(event) {}

	getPowerTuningResults = () => {
		console.log("this.props.selectedItem", this.props.selectedItem)
		let params = {}
		params.asset_id = this.props.selectedItem.asset_id
		if(this.props.selectedItem && this.props.selectedItem.power_tuning_details && this.props.selectedItem.power_tuning_details.total) {
			params.size = this.props.selectedItem.power_tuning_details.total
		}

		this.props.getPowerTuningResults(params, (promise, response) => {
			if(promise) {
				this.setState({ powerTuningDetails: response, filteredArray: response, showLoading: false })
			} else {
				this.setState({ powerTuningDetails: [], showLoading: false })
			}
		})
	}
	
	render() {		
		return (
			<div className="advanced-search" onClick={(event) => this.handleClickOutside(event)}>
				<div className={`loaderOverlay ${this.state.queryLoading ? "" : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
				<div className="search-content bg-muted w-80">
					<div className="header-search bd-highlight d-flex justify-content-between">
						<div className="flex-fill bd-highlight">
							<h5>Manage PowerTuning </h5>
							<div className="mr-2 d-flex">
							</div>
						</div>

						<div className="text-right flex-fill bd-highlight align-self-center">
							<i className={`far fa-times cursorPointer ${this.state.onSaveFunction ? "disbaled" : ""}`} onClick={() => this.props.closeSidePanel()}></i>
						</div>
					</div>
					<div className={`overflow-auto p-2`}>
						<div className={`d-flex pb-2 justify-content-end`}>
							<div className="d-flex mr-2 w-40">
								<p className="f12 m-0 align-self-center pt-1">Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.state.powerTuningDetails && this.state.powerTuningDetails.length} powerTuning(s)</p>
							</div>
							<div className="d-flex justify-content-end w-60 mb-n2">
								{this.state.powerTuningDetails && this.state.powerTuningDetails.length ?
									<Search
										data={this.state.powerTuningDetails ? this.state.powerTuningDetails : []}
										applyTags={false}
										applyLiteDarkTags={true}
										topClassName={'bg-black5 align-self-center w-50 mr-1 border-gray5 rounded-5 f12'}
										searchClassName={'px-2 f12'}
										searchIconColor={'text-gray5 f12'}
										searchPlaceHolder={'Search....'}
										className={"bg-transparent text-white pl-0 form-control-sm f12"}
										filteredData={(filteredArray) => {
											this.setState({ filteredArray, startRecord: 0, currentPage: 1 })
										}}
									/>
								: null}
								{/* {this.state.filteredArray && this.state.filteredArray.length > this.state.perPage ?
									<div className="pagination errorPagePagination f12">
										<span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
										<button><i className={`fal fa-arrow-to-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('start', this.state.currentPage)}></i></button> 
										<button><i className={`fal fa-angle-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('previous', this.state.currentPage)}></i></button> 
										<button><i className={`fal fa-angle-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
										<button><i className={`fal fa-arrow-to-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
									</div>
								: null} */}
							</div>
						</div>
						<div className='tab-content' id='myTabContent'>
							<div className='tab-pane fade show active' id='home' role='tabpanel' aria-labelledby='home-tab'>
								{this.state.filteredArray && this.state.filteredArray.length ?
									<ResizeableDarkThemeTable
										columns={[
											{
												Header: "Name",
												accessor: "asset_name",
												width: 300
											},
											{
												Header: "Arn",
												accessor: "asset_arn",
												width: 200
											},
											// {
											// 	Header: "Resources",
											// 	Cell: cellInfo => (
											// 		(cellInfo.row.original.provider ? cellInfo.row.original.provider.toUpperCase() : '') + ': ' + getAccountNameFromId(cellInfo.row.original.account_id, this.state.accounts) + ': ' + (cellInfo.row.original.region ? (cellInfo.row.original.region === 'NA' ? 'Global' : getRegionName(cellInfo.row.original.region, this.state.regions)) : '')
											// 	),
											// 	width: 300
											// },
											// {
											// 	Header: "Service",
											// 	accessor: d => d.resource_type+" : "+d.service_name,
											// 	width: 200
											// },
											{
												Header: "Time",
												Cell: cellInfo => (
													<React.Fragment>
													<div className="d-flex">
														{cellInfo.row.original.start_time ?
															<div className="d-flex mr-2">
																<div className={`f12 align-self-center`}>Start</div>
																<p className="f12 m-0 text-white align-self-center"><span className="mx-1">:</span>
																{momentConvertionUtcToLocalTime(cellInfo.row.original.start_time , "DD MMM YYYY HH:mm")}</p>
															</div>
														: null}
														{cellInfo.row.original.end_time  ?
															<div className="d-flex">
																<div className={`f12 align-self-center`}>End</div>
																<p className="f12 m-0 text-white align-self-center"><span className="mx-1">:</span>
																{momentConvertionUtcToLocalTime(cellInfo.row.original.end_time, "DD MMM YYYY HH:mm")}</p>
															</div>
														: null}
													</div>
													</React.Fragment>
												),
												width: 400
											},
											{
												Header: "Recommended Features",
												Cell: cellInfo => (
													<div className="d-flex">
														<div className="d-flex mr-1">
															<div className={`f12 align-self-center`}>Memory</div>
															{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.recommended_features.memory ?
																<React.Fragment>
																<p className="f12 m-0 text-white align-self-center"><span className="mx-1">:</span>{cellInfo.row.original.additional_details.recommended_features.memory}</p>
																{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.recommended_features.memory_unit ?
																	<p className="mb-0 text-primary-color f11">({cellInfo.row.original.additional_details.recommended_features.memory_unit})</p>
																: null}
																</React.Fragment>
															: null}
														</div>
														{/* <div className="d-flex mr-1">
															<div className={`f12 align-self-center`}>Price:</div>
															<p className="f12 m-0 text-white align-self-center"><span className="mx-1">:</span>{(cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.recommended_features.price ? cellInfo.row.original.additional_details.recommended_features.price : "")}</p>
															<p className="mb-0 text-primary-color f11">({(cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.recommended_features.price_unit ? cellInfo.row.original.additional_details.recommended_features.price_unit : "")})</p>
														</div>
														<div className="d-flex">
															<div className={`f12 align-self-center`}>Duration:</div>
															<p className="f12 m-0 text-white align-self-center"><span className="mx-1">:</span>{(cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.recommended_features.duration ? cellInfo.row.original.additional_details.recommended_features.duration : "")}</p>
															<p className="mb-0 text-primary-color f11">({(cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.recommended_features.duration_unit ? cellInfo.row.original.additional_details.recommended_features.duration_unit : "")})</p>
														</div> */}
													</div>
												),
												width: 500
												
											},
											{
												Header: "Status",
												Cell: cellInfo => (
													cellInfo.row.original.status ?
														<span className={`badge badge-outline-border-${cellInfo.row.original.status.toLowerCase()}`}>{cellInfo.row.original.status}</span>
													: 
														<span className={`badge badge-outline-secondary`}>No Status</span>
												),
												width: 100
											}
										]}
										data={this.state.filteredArray}
										perPage={20}
										dashboard={this.props.filteredArray ? true : false}
										sortByColumn={"Date"}
										riskTooltip={[0]}
										onClickRow={tableRow => {}}
										// tableSize={"table-sm"}
										// tableHead={"bg-dark"}
										tooltipFor={[1,2]}
									/>
								: (!this.state.showLoading && !this.state.assetsList) ||
									(this.state.assetsList && !this.state.assetsList.length && !this.state.showLoading) ? 
									<div className="d-flex justify-content-center m-4">
										<p>There are no data on this criteria. Please try adjusting your filter.</p>
									</div>
								: null}
								{this.state.showLoading ? (
									<div className="d-flex justify-content-center m-4">
										<Spinner className="text-center" color="white" size="lg" />
									</div>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ViewPowerTuningSidePanel.propTypes = {}

const mapStateToProps = state => {
	// console.log('csot power tuning ViewPowerTuningSidePanel',state)
	return {
        accounts: state.filters.accounts,
        regions: state.filters.regions,
		tagKeys: state.filters.tagKeys
	}
}

export default connect(mapStateToProps, {
	getPowerTuningResults
})(withRouter(ViewPowerTuningSidePanel))