/*************************************************
 * Tvastar
 * @exports
 * @file ObservabilityAction.js
 * @author prakash // on 06/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
// import cognitoService from '../../services/cognitoService'
// import { myLog } from '../../utils/utility'
import { API } from 'aws-amplify'

import {
    AIOPS_SERVICE,
	OBSERVABILITY_GET_ALERTS_DAILY_TREND,
	OBSERVABILITY_GET_ALERTS_GROUP_DAILY_TREND,
	OBSERVABILITY_LIST_ALERT_GROUPS,
	OBSERVABILITY_GET_EVENTS_DAILY_TREND,
	OBSERVABILITY_GET_IMPORTANT_EVENTS_DAILY_TREND,
	OBSERVABILITY_GET_EVENTS_TOTAL_COUNT,
	OBSERVABILITY_LIST_ALERTS,
	OBSERVABILITY_LIST_EVENTS,
	OBSERVABILITY_GET_TOP_ALERTS,
	OBSERVABILITY_GET_ALERTS_TOTAL_COUNT,
	OBSERVABILITY_GET_TOP_EVENTS,
	OBSERVABILITY_LIST_EVENT_GROUPS,
	GET_PEAK_DIP_DETECTION_RESULTS,
	OBSERVABILITY_GET_CHANGE_EVENTS,
	OBSERVABILITY_LIST_CHANGE_EVENTS,


	OBSERVABILITYANOMALYENDPOINT,
	OBSERVABILITY_ANOMALY_GET_DAILY_COUNT,
	OBSERVABILITY_ANOMALY_GET_TOTAL_COUNT,
	OBSERVABILITY_ANOMALY_GET_TOP_METRIC_ANOMALIES,

	OBSERVABILITY_ANOMALY_LIST_ANOMALY_RESULTS,
	OBSERVABILITY_ANOMALY_GET_ANOMALY_DAILY_TREND,
	OBSERVABILITY_ANOMALY_GET_TOP_ANOMALIES,
	GET_ANOMALY_TOTAL_COUNT,

	OBSERVABILITYEVENTSENDPOINT,
	OBSERVABILITY_EVENTS_LIST_EVENT_GROUPS,

} from '../../config'

import { ACTION_TYPES } from '../types'

/**
 * Action to get Metrics Alert
 * @param {Object} body
 * @param {Function} callback
 */
export const getObservabilityAlertsDailyTrend = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(AIOPS_SERVICE, OBSERVABILITY_GET_ALERTS_DAILY_TREND, { body })
			dispatch({
				type: ACTION_TYPES.SET_OBSERVABILITY_ALERT_TREND,
				payload: response,
			})
			callback(true, response)
		} catch (error) {
			dispatch({
				type: ACTION_TYPES.SET_OBSERVABILITY_ALERT_TREND,
				payload: {},
			})
			callback(false, {})
		}
	}
}

/**
 * Action to get Alert Group Daily Trend
 * @param {Object} body
 * @param {Function} callback
 */
 export const getObservabilityAlertGroupDailyTrend = (body, callback) => {
	return async () => {
		await API.post(AIOPS_SERVICE, OBSERVABILITY_GET_ALERTS_GROUP_DAILY_TREND, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to list alert groups
 * @param {Object} body
 * @param {Function} callback
 */
export const listObservabilityAlertGroups = (body, callback) => {
	return async () => {
		await API.post(AIOPS_SERVICE, OBSERVABILITY_LIST_ALERT_GROUPS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}


/**
 * Action to get AIOPS get events daily trend 16 Mar 2021
 * @param {Object} body
 * @param {Function} callback
 */
export const getObservabilityEventsDailyTrend = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(AIOPS_SERVICE, OBSERVABILITY_GET_EVENTS_DAILY_TREND, { body })
			dispatch({
				type: ACTION_TYPES.SET_OBSERVABILITY_EVENT_TREND,
				payload: response,
			})
			callback(true, response)
		} catch (error) {
			dispatch({
				type: ACTION_TYPES.SET_OBSERVABILITY_EVENT_TREND,
				payload: {},
			})
			callback(false, {})
		}
	}
}

/**
 * Action to get important events daily trend
 * @param {Object} body
 * @param {Function} callback
 */
export const getObservabilityImportantEventsDailyTrend = (body, callback) => {
	return async () => {
		await API.post(AIOPS_SERVICE, OBSERVABILITY_GET_IMPORTANT_EVENTS_DAILY_TREND, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
	}
}

/**
 * Action to get events total count
 * @param {Object} body
 * @param {Function} callback
 */
export const getObservabilityEventsTotalCount = (body, callback) => {
	return async () => {
		await API.post(AIOPS_SERVICE, OBSERVABILITY_GET_EVENTS_TOTAL_COUNT, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
	}
}

/**
 * Action to list alerts
 * @param {Object} body
 * @param {Function} callback
 */
export const listObservabilityAlerts = (body, callback) => {
	return async () => {
		await API.post(AIOPS_SERVICE, OBSERVABILITY_LIST_ALERTS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
	}
}

/**
 * Action to list events
 * @param {Object} body
 * @param {Function} callback
 */
export const listObservabilityEvents = (body, callback) => {
	return async () => {
		await API.post(AIOPS_SERVICE, OBSERVABILITY_LIST_EVENTS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
	}
}

/**
 * Action to get top alerts
 * @param {Object} body
 * @param {Function} callback
 */
 export const getObservabilityTopAlerts = (body, callback) => {
	return async () => {
		await API.post(AIOPS_SERVICE, OBSERVABILITY_GET_TOP_ALERTS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
	}
}

/**
 * Action to get alerts total count
 * @param {Object} body
 * @param {Function} callback
*/
export const getObservabilityAlertsTotalCount = (body, callback) => {
	return async () => {
		await API.post(AIOPS_SERVICE, OBSERVABILITY_GET_ALERTS_TOTAL_COUNT, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
	}
 }

/**
 * Action to get top evetns
 * @param {Object} body
 * @param {Function} callback
*/
export const getObservabilityTopEvents = (body, callback) => {
	return async () => {
		await API.post(AIOPS_SERVICE, OBSERVABILITY_GET_TOP_EVENTS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
	}
}

/**
 * Action to list event groups
 * @param {Object} body
 * @param {Function} callback
 */
export const listObservabilityEventGroups = (body, callback) => {
	return async () => {
		await API.post(AIOPS_SERVICE, OBSERVABILITY_LIST_EVENT_GROUPS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to get-peak-dip-detection-results
 * @param {Object} body
 * @param {Function} callback
 */
 export const getPeakDipDetectionResults = (body, callback) => {
	return async () => {
		await API.post(AIOPS_SERVICE, GET_PEAK_DIP_DETECTION_RESULTS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to get-change-events
 * @param {Object} body
 * @param {Function} callback
 */
 export const getChangeEvents = (body, callback) => {
	return async () => {
		await API.post(AIOPS_SERVICE, OBSERVABILITY_GET_CHANGE_EVENTS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to list-change-events
 * @param {Object} body
 * @param {Function} callback
 */
 export const listChangeEvents = (body, callback) => {
	return async () => {
		await API.post(AIOPS_SERVICE, OBSERVABILITY_LIST_CHANGE_EVENTS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}
/**
 * Action to list event groups
 * @param {Object} body
 * @param {Function} callback
 */
 export const listEventsObservabilityEventGroups = (body, callback) => {
	return async () => {
		await API.post(OBSERVABILITYEVENTSENDPOINT, OBSERVABILITY_EVENTS_LIST_EVENT_GROUPS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to get daily cont
 * @param {Object} body
 * @param {Function} callback
 */
 export const getObservabilityAnomalyDailyCount = (body, callback) => {
	return async () => {
		await API.post(OBSERVABILITYANOMALYENDPOINT, OBSERVABILITY_ANOMALY_GET_DAILY_COUNT, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

// /**
//  * Action to get total count
//  * @param {Object} body
//  * @param {Function} callback
// */
// export const getAnomalyTotalCount = (body, callback) => {
// 	return async () => {
// 		await API.post(OBSERVABILITYANOMALYENDPOINT, OBSERVABILITY_ANOMALY_GET_TOTAL_COUNT, { body })
// 		.then((response) => {
// 			callback(true, response)
// 		}).catch((err) => {
// 			let message = ''
// 			callback(false, message)
// 		})
//     }
// }

/**
 * Action to list anomaly results
 * @param {Object} body
 * @param {Function} callback
*/
export const getObservabilityTopMetricsAnomalies = (body, callback) => {
	return async () => {
		await API.post(OBSERVABILITYANOMALYENDPOINT, OBSERVABILITY_ANOMALY_GET_TOP_METRIC_ANOMALIES, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}


/**
 * Action to list anomaly results
 * @param {Object} body
 * @param {Function} callback
*/
export const listObservabilityAnomalyResults = (body, callback) => {
	return async () => {
		await API.post(OBSERVABILITYANOMALYENDPOINT, OBSERVABILITY_ANOMALY_LIST_ANOMALY_RESULTS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to get anomaly daily trend
 * @param {Object} body
 * @param {Function} callback
*/
export const getObservabilityAnomalyDailyTrend = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(OBSERVABILITYANOMALYENDPOINT, OBSERVABILITY_ANOMALY_GET_ANOMALY_DAILY_TREND, { body })
			dispatch({
				type: ACTION_TYPES.SET_OBSERVABILITY_ANOMALY_TREND,
				payload: response,
			})
			callback(true, response)
		} catch (error) {
			dispatch({
				type: ACTION_TYPES.SET_OBSERVABILITY_ANOMALY_TREND,
				payload: {},
			})
			callback(false, {})
		}
	}
}

/**
 * Action to get top anomalies
 * @param {Object} body
 * @param {Function} callback
*/
export const getObservabilityTopAnomalies = (body, callback) => {
	return async () => {
		await API.post(OBSERVABILITYANOMALYENDPOINT, OBSERVABILITY_ANOMALY_GET_TOP_ANOMALIES, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to get anomaly total count
 * @param {Object} body
 * @param {Function} callback
*/
export const getAnomalyTotalCount = (body, callback) => {
	return async () => {
		await API.post(OBSERVABILITYANOMALYENDPOINT, GET_ANOMALY_TOTAL_COUNT, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}