import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Checkbox from '../../../common/formFields/Checkbox'
import TextField from '../../../common/formFields/TextField'
import { setAiopsPropsDetails } from '../../../../actions/aiops/AiopsAction'

class RdsStorageAutoscaling extends Component {

  constructor(props) {
    super(props);
    this.state = props || {};
    this.state = {
      validationError: this.props.validationError,
      validationErrorMessage: this.props.validationErrorMessage,
      parentValue: this.props.value,
    }
    this.updateFormFieldValue = this.updateFormFieldValue.bind(this);
    this.updateTextFormFieldValue = this.updateTextFormFieldValue.bind(this);
  }

  componentDidMount = () => {}

	componentDidUpdate = (prevProps) => {
		if(this.props.validationError !== prevProps.validationError) {
			this.setState({ 
        validationError: this.props.validationError, 
        MaxAllocatedStorageError: this.props.aiopsRemediationParams.MaxAllocatedStorageError ? this.props.aiopsRemediationParams.MaxAllocatedStorageError : '',
        ApplyImmediatelyError: this.props.aiopsRemediationParams.ApplyImmediatelyError ? this.props.aiopsRemediationParams.ApplyImmediatelyError : ''
      })
		}
	}

  updateFormFieldValue = (fieldName, fieldValue) => {
    let obj = this.props.aiopsRemediationParams
    obj[fieldName] = fieldValue
    obj.processor_rule_id = this.props.selectedRecord
    this.props.setAiopsPropsDetails('remediationParams', obj)
    this.setState({ [fieldName]: fieldValue, ApplyImmediatelyError: '' });
  };

  updateTextFormFieldValue = (fieldName, fieldValue) => {
    let obj = this.props.aiopsRemediationParams
    obj[fieldName] = fieldValue
    obj.processor_rule_id = this.props.selectedRecord
    this.props.setAiopsPropsDetails('remediationParams', obj)
    this.setState({ [fieldName]: fieldValue, MaxAllocatedStorageError: '',  });
  };

  render() {
    return (
      <div className="row mt-2">
        <div className="col-md-12">
          <div className="form-group">
            <label>Maximum Storage allowed after auto scaling</label>
            <TextField
              className="form-control bg-muted text-gray3 col-sm-3"
              labelClassName={'text-gray3'}
              recordSelection={this.updateTextFormFieldValue}
              value={this.state.MaxAllocatedStorage ? this.state.MaxAllocatedStorage : ''}
              name={'MaxAllocatedStorage'}
              label="Maximum Storage allowed after auto scaling"
              placeholder=""
              required={true}
              unitLabel={'GiB'}
              number={true}
            />
            {this.state.validationError && this.state.MaxAllocatedStorageError && this.state.MaxAllocatedStorageError !== '' ?
              <small className='text-danger'>{this.state.MaxAllocatedStorageError}</small>
            : null }
          </div>
          <div className="form-group">
            <Checkbox 
              className="form-check-input"
              labelClassName={'text-white'}
              recordSelection={this.updateFormFieldValue}
              value={this.state.ApplyImmediately ? true : false}
              name={'ApplyImmediately'}
              label={'ApplyImmediately'}
            />
            {this.state.validationError && this.state.ApplyImmediatelyError && this.state.ApplyImmediatelyError !== '' ?
                <small className='text-danger'>{this.state.ApplyImmediatelyError}</small>
              : null }
          </div>
        </div>
      </div>
    )
  }
};

/**
 * Type of the props used in the component
 */
 RdsStorageAutoscaling.propTypes = {
  setAiopsPropsDetails: PropTypes.func
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
 const mapStateToProps = state => {	
	console.log('RdsStorageAutoscaling', state)  
	return {
    caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : [],
    aiopsRemediationParams: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.remediationParams ? state.aiops.aiopsPropsDetails.remediationParams : {},
    aiopsSelectedRemediationProps: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.selected_remediation ? state.aiops.aiopsPropsDetails.selected_remediation : {},
  }
}


export default connect(mapStateToProps, {
  setAiopsPropsDetails
})(withRouter(RdsStorageAutoscaling))
