/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ErrorRightSection.js
 * @author Prakash // on 25/10/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'  
import _ from 'lodash'

// import { Spinner } from 'reactstrap'
import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'
import { listAssets } from '../../../actions/detectors/detectorsAction'
import { capitalizeFirstLetter, momentConvertionUtcToLocalTime } from '../../../utils/utility'

import AlertMetricList from './AlertMetricList'
import AlertMetricDetails from './AlertMetricDetails'

class ErrorRightSection extends Component {
    
    constructor(props) {
        super(props)
        this.scrolltoTop = React.createRef()

        this.state = {
            showMetricsDetails: false,
            showFilerSection: true,
            alertDetails: {},

            sliderStartDate: '',
            sliderEndDate: '',
            showMetrics: false,

        }
    }

    componentDidMount = (prevProps) => {
        // this.props.setAiopsPropsDetails('showMetricsList', true)
    }

    componentDidUpdate = (prevProps) => {        
        if(this.props.errorDetails && Object.entries(this.props.errorDetails).length) {
            console.log("this.props.errorDetails.details", this.props.errorDetails)
            if(!_.isEqual(prevProps.errorDetails, this.props.errorDetails)) {
                this.setState({ showMetrics: false, details: {} },
                    () => {
                        this.setState({ details: this.props.errorDetails.details })
                    }    
                )
            }
        }
    }

    getAlertDetails = () => {
        this.setState({ alertDetails: this.props.alertPageDetails })
    }

    listAssets = () => {        
        let assetDetail = this.props.assetsList.filter(obj => obj.asset_name === this.props.alertPageDetails.asset_name)     

        let asset_name = assetDetail && assetDetail.length && assetDetail[0].asset_name ? assetDetail[0].asset_name : this.props.alertPageDetails.asset_name
        let resource_type = assetDetail &&  assetDetail.length && assetDetail[0].resource_type ? assetDetail[0].resource_type: ''
        let region = assetDetail &&  assetDetail.length && assetDetail[0].region ? assetDetail[0].region : ''
        
        let params = {}
        params.asset_name = asset_name ? asset_name : this.props.alertPageDetails.asset_name
        params.resource_type = resource_type ? resource_type : this.props.alertPageDetails.resource_type
        params.region = region ? region : this.props.alertPageDetails.region
        
        if(params.resource_type !== '' && params.region !== '') {
            this.props.listAssets(params, (promise, response) => {
                if (promise) {
                    if(response.results && response.results.length) {
                        let obj = this.props.alertAssetDetails
                        obj[asset_name] = response.results
                        // this.props.setAiopsPropsDetails('alertAssetDetails', obj)
                    }
                    this.setState({ assetDetails: response.results ? response.results : [] })
                } else {
                    this.setState({ assetDetails: [] })
                }
            })
        }
    }

    getMetricsDeatils = (assetId, assetName) => {
        if(Array.isArray(assetName)) {
            assetName = assetName[0]
        }

        if(Array.isArray(assetId)) {
            assetId = assetId[0]
        }
        this.setState({ selectedMetricAssetName: assetName, selectedMetricAssetId: assetId, showMetricsDetails: true },
            () => {
                this.scrolltoTop.current.scrollIntoView()
            }   
        )
    }

    render() {
        return (
            <div className=""  ref={this.scrolltoTop}>
            {!this.state.showMetricsDetails ?
                <div className='row'>
                    <div className="col-sm-12 p-3">
                        {this.props.errorDetails && Object.entries(this.props.errorDetails).length && this.state.details ?
                            <div className="rounded bg-dark3 p-3">
                                <div className="accordion" id="accordionExample">
                                    <div className={`card bg-dark border-0 mb-2`}>
                                        <div id={'heading_event'} onClick={() => this.setState({ expanderrorDetails: !this.state.expanderrorDetails })}>
                                            <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
                                                <div className="text-white"><span className={`far ${this.state.expanderrorDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Error Details</div>
                                            </div>
                                        </div>
                                        <div id={'collapse_event'} className={`collapse ${this.state.expanderrorDetails ? 'show' : <span>&nbsp;</span>}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
                                                    <div>
                                                        <p className="mb-0 f16 text-white">{this.state.details.qid ? this.state.details.qid : <span>&nbsp;</span>}</p>
                                                        <p className="mb-0 f12">{this.state.details.description ? this.state.details.description : <span>&nbsp;</span>}</p>
                                                    </div>
                                                    <div className="ml-3">
                                                        <p className="b-block mb-0">Severity</p>
                                                        <span className={`mr-2 badge ${this.state.details.severity && this.state.details.severity.toLowerCase() !== 'nan' ? 'risk-badge-'+this.state.details.severity.toLowerCase() : 'badge-secondary' }`}>  
                                                            {this.state.details.severity ? capitalizeFirstLetter(this.state.details.severity) : 'Unknown'}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="py-1 w-50">
                                                        <p className="b-block mb-0">Log Type Source</p>
                                                        <p className="mb-0 text-white">{this.state.details.error_type_source ? this.state.details.error_type_source : ''}</p>
                                                    </div>
                                                    <div className="py-1 w-50 pl-3">
                                                        <p className="b-block mb-0">Log File Type Seen:</p>
                                                        <p className="mb-0 text-white">
                                                            {this.state.details.log_file_type ? this.state.details.log_file_type : ''}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {this.state.details && (this.state.details.asset_name || this.state.details.asset_id) ?
                                    <div className={`card bg-dark border-0 mb-2`}>
                                        <div id={'heading_config_assets'} onClick={() => this.setState({ expandPropsAssetDetails: !this.state.expandPropsAssetDetails })}>
                                            <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                                <div className="text-white"><span className={`far ${this.state.expandPropsAssetDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Asset Details</div>
                                                {/* <div className="text-info">Showing asset details</div> */}
                                            </div>
                                        </div>
                                        <div id={'collapse_config_assets'} className={`collapse ${this.state.expandPropsAssetDetails ? 'show' : <span>&nbsp;</span>}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                            <div className="card-body m-2 pt-2 rounded counts_threads">
                                                <div className="d-flex">
                                                    <div className="py-1 w-50">
                                                        <p className="b-block mb-0">Name:</p>
                                                        <p className="mb-0 text-white">{this.state.details.asset_name ? this.state.details.asset_name : <span>&nbsp;</span>}</p>
                                                    </div>
                                                    <div className="py-1 w-50 pl-3">
                                                        <p className="b-block mb-0">Id:</p>
                                                        <p className="mb-0 text-white">{this.state.details.asset_id ? this.state.details.asset_id : <span>&nbsp;</span>}</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex pt-2 border-top">
                                                    <div className="py-1 w-50">
                                                        <p className="b-block mb-0">Provider / Account / Region </p>
                                                        <p className="mb-0 text-white">
                                                            {this.state.details.provider ? this.state.details.provider.toUpperCase() : <span>&nbsp;</span>}
                                                            {this.state.details.account_id ? (' : ')+this.state.details.account_id : <span>&nbsp;</span>}
                                                            {this.state.details.region ? (' : ')+this.state.details.region : <span>&nbsp;</span>}
                                                        </p>
                                                    </div>
                                                    <div className="py-1 w-50 pl-3">
                                                        <p className="b-block mb-0">Service</p>
                                                        <p className="mb-0 text-white">
                                                            {this.state.details.resource_type ? this.state.details.resource_type : <span>&nbsp;</span>}
                                                            {this.state.details.service_name ? ('  ')+this.state.details.service_name : <span>&nbsp;</span>}
                                                        </p>
                                                    </div>
                                                </div>
                                                {this.props.asset_arn ? 
                                                    <div className="d-flex pt-2 border-top">
                                                        <div className="py-1 w-100">
                                                            <p className="b-block mb-0">ARN:</p>
                                                            <p className="mb-0 text-white">{this.props.asset_arn}</p>
                                                        </div>
                                                    </div>
                                                : null}
                                                {this.state.details.category ? 
                                                    <div className="d-flex pt-2 border-top">
                                                        <div className="py-1 w-100">
                                                            <p className="b-block mb-0">Category:</p>
                                                            <div className={`d-flex flex-wrap`}>
                                                                {this.state.details.category && Array.isArray(this.state.details.category) ? 
                                                                    this.state.details.category.map(item => {
                                                                        return (
                                                                        <small className={`badge badge-secondary mr-1`}>{item}</small>
                                                                        )
                                                                    }) 
                                                                : this.state.details.category ?
                                                                    <small className={`badge badge-secondary mr-1`}>{this.state.details.category}</small>
                                                                : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                : null}
                                            </div>
                                        </div>
                                    </div>
                                : null}

                                {this.state.details && this.state.details.logs && this.state.details.logs.length ?
                                    <div className={`card bg-dark border-0 mb-2`}>
                                        <div id={'heading_config_assets'} onClick={() => this.setState({ expandPropsLogDetails: !this.state.expandPropsLogDetails })}>
                                            <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                                <div className="text-white"><span className={`far ${this.state.expandPropsLogDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Log Details</div>
                                                {this.state.expandPropsLogDetails ?
                                                    <div className="text-info">Showing {this.state.details.count} observed anomalies</div>
                                                    // <p className="small mb-0 ml-3">Showing {this.state.details.count} observed anomalies</p>
                                                : null}
                                            </div>
                                        </div>                                    
                                        <div id={'collapse_config_assets'} className={`collapse ${this.state.expandPropsLogDetails ? 'show' : <span>&nbsp;</span>}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                            <div className={`rounded bg-dark3 mx-3 mb-3`} style={{maxHeight: "600px", overflowY: "auto"}}>
                                                {this.state.details.logs.map((item, i) => {
                                                        return(
                                                            <div className={`d-flex flex-wrap mr-2 px-2 mb-2 pb-1 pt-1 ${i ? "border-top-black5" : ""}`}>
                                                                <p className="mb-0 text-break">
                                                                    <span className="mb-0 mr-2 f12 text-white font-weight-bold">{item.timestamp ? momentConvertionUtcToLocalTime(item.timestamp, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                    <span className={`mb-0 mr-2 f12 text-purple-2`}>{item.log_file ? item.log_file : ""}</span>
                                                                    {item.log_event_id ?
                                                                        <span className="mb-0 f12 text-primary-color font-weight-bold">{item.log_event_id}</span>
                                                                    : null}
                                                                    {item.message ?
                                                                        <span className="mb-0 ml-2 f12 text-white font-weight-bold">{item.message}</span>
                                                                    : null}
                                                                </p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                : null}
                            </div>
                        : this.props.errorDetails && !Object.entries(this.props.errorDetails).length ? 
                            <div className='col-md-12 d-flex justify-content-center m-4'>
                                <p>Please select the anomlay to get the anomaly event details.</p>
                            </div>
                        : null}
                        <div className="mt-3">
                            {console.log("this.state.details", this.state.details)}
                            {this.state.details && Object.entries(this.state.details).length ?
                                <AlertMetricList
                                    from={'errorPage'}
                                    eventType={''}
                                    details={this.state.details}
                                    caseId={this.props.caseId}
                                    getMetricsDeatils={(assetId, assetName) => this.getMetricsDeatils(assetId, assetName)}
                                    textColor={'text-white'}
                                    backGroudColor={'bg-dark'}
                                    heading={'bg-muted text-info'}
                                />
                            : null}
                        </div>
                    </div>                             
                </div>
            :
                <AlertMetricDetails 
                    from={'anomalyPage'}
                    caseId={this.props.caseId}
                    details={this.state.details}
                    showMetricsDetails={(bool) => this.setState({ showMetricsDetails: false })}
                    backGroudColor={'bg-muted'}
                    heading={'bg-black text-info'}
                    textColor={'text-white'}
                />
            }
            </div>
        )
    }
}
/**
 * Type of the props used in the component
 */
ErrorRightSection.propTypes = {
    listAssets: PropTypes.func,
    getMonitoringMetricsByTrend: PropTypes.func,
    getSreDetails: PropTypes.func,
    setAiopsPropsDetails: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
    // console.log('state', state)
    return {
        aiopsPropsDetails: state.aiops.aiopsPropsDetails ? state.aiops.aiopsPropsDetails : {},
        errorDetails: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.errorDetails ? state.aiops.aiopsPropsDetails.errorDetails : {},
        caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : {}
    }
}

export default connect(mapStateToProps, {
    listAssets,
    setAiopsPropsDetails,
})(withRouter(ErrorRightSection))