/*************************************************
 * Tvastar
 * @exports
 * @file SidePanel.js
 * @author Prakash // on 28/06/2020
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import { capitalizeFirstLetter, capitalizeTheFirstLetterOfEachWord, getAccountNameFromId } from '../../../utils/utility'
import { 
	getResourceChart,  //Resource
	getTotalAssetCount,  //Resource
	getPurchaseDetails,  //Purchase
	getPurchaseByInstanceType  //Purchase
} from '../../../actions/assets/assetBoardAction'
import {
	getTotalCount,  //checks
	getDayWiseCount  //checks
} from '../../../actions/detectors/detectorsAction'
import { Spinner, UncontrolledTooltip, UncontrolledPopover, PopoverBody   } from 'reactstrap'
import { Doughnut } from 'react-chartjs-2';
import Chart from 'react-apexcharts'
import CustomTable from '../../designComponents/Table/customTable'
import ApexDonutChart from '../../common/charts/ApexDonutChart'
import _ from 'lodash'

class SidePanel extends Component {
	proEnvWrap = React.createRef()
	constructor(props) {
		super(props)
		this.props = props;
		this.state = {
			activeTab: 0,
			showLoading: false,
			accounts: this.props.accountsList,
			selectedProvider: this.props.selectedProvider,
			selectedAccount: this.props.selectedAccount,
			selectedRegion: this.props.selectedRegion,
			selectedTags: this.props.selectedTags,
			selectedDuration: this.props.selectedDuration,
			selectedServiceDetail: this.props.selectedServiceDetail,


			resourceChart: [],
			resourceDonutType: 'instance_type',
			resourceTable: [],

			donutData: {},
			donutOptions: {},

			purchaseDetails: [],
			purchaseDonutData: {},
			purchaseDonutOptions: {},
			selectedReservationType: '',			
			reservationDonutData: {},
			reservationDonutOptions: {},
			purchaseTableDetails: [],


			checkStatus: [],
			checkDaywiseCount: [],
			check_bar_data: [],
			check_bar_option: [],
			
			check_doughnut_datas: [],
			check_doughnut_options: [],
			checkRisk: [],
		}
	}

	componentDidMount = () => {
		this.getResourceChart()
	}

	getResourceChart = () => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		
		if(this.state.selectedAccount.length) {
			params.account_id = this.state.selectedAccount
		}

		if(this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
			params.region = this.state.selectedRegion
		}

		// if(this.state.selectedTags.length) {
		// 	params.tags  = this.state.selectedTags
		// }	

		params.resource_type = this.state.selectedServiceDetail
		
		this.props.getResourceChart(params, (promise, resourceChart) => {
			if (promise) {
				this.setState({ resourceChart, showLoading: false }, 
					() => this.getResourceDontuData() 
				)
			} else {
				this.setState({ resourceChart: [] })
			}
		})
	}

	getResourceDontuData = () => {
		if(this.state.resourceChart) {
			//donut chart start
			let donut = []
			let donut_total_count = 0				
			Object.entries(this.state.resourceChart[this.state.resourceDonutType]).forEach(([key, value]) => {
				let donutRow = {}
				donutRow.item_count = key
				donutRow.item_name = value
				donut.push(donutRow)
				donut_total_count += value 
			})
			donut = _.orderBy(donut, ['item_count'], ['desc'])
			// services = services.slice(0,10)
			let detailsDonutData = {
				"items": donut,
				"label": capitalizeTheFirstLetterOfEachWord(this.state.resourceDonutType.replaceAll("_", " ")),
				"total_count": donut_total_count
			}

			this.setState({ detailsDonutData },
				() => this.getTotalAssetCount()
			)
			//donut chart end	
		}	
	}

	getTotalAssetCount = () => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		
		let searchInputs = []
		searchInputs.push('account_id')
		
		let aggregateBy = []
		aggregateBy.push('account_id')
		aggregateBy.push('action')
		if(this.state.selectedAccount.length) {
			params.account_id = this.state.selectedAccount
		}

		if(this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
			params.region = this.state.selectedRegion
		}
		
		if(this.state.selectedRegion.length) {
			aggregateBy.push('region')
			searchInputs.push('region')
		}

		// if(this.state.selectedTags.length) {
		// 	aggregateBy.push('tags')
		// 	searchInputs.push('tags')
		// 	params.tags  = this.state.selectedTags
		// }	

		params.resource_type = this.state.selectedServiceDetail

		if(this.state.resourceDonutType === 'instance_type') {
			aggregateBy.push('instance_type')
			searchInputs.push('instance_type')
		}

		if(this.state.resourceDonutType === 'language') {
			aggregateBy.push('language_version')
			searchInputs.push('language_version')
		}

		if(this.state.resourceDonutType === 'engine') {
			aggregateBy.push('engine_type')
			searchInputs.push('engine_type')
		}

		// if(this.state.resourceDonutType === 'os') {
		// 	//aggregateBy.push('os_version')
		// 	aggregateBy.push('os')
		//  searchInputs.push('os')
		// }
		params.aggregate_by = aggregateBy
		//console.log('resource', params)
		this.props.getTotalAssetCount(params, searchInputs, (promise, resourceTable) => {
			//console.log('resource-value', resourceTable)
			if (promise) {
				const resourceTableArray = []
				if(Object.entries(resourceTable).length) {
					Object.entries(resourceTable).forEach(([key,value]) => {
						let dataRow = {}
						let totalCount = 0
						value.forEach((item, index) => {
							if(index === 0) {
								dataRow.provider = item.provider
								dataRow.duration = item.duration
								dataRow.account_id = item.account_id
								dataRow.resource_type = item.resource_type
								if(item.region !== undefined) {
									dataRow.region = item.region
								} else {
									dataRow.region = ''
								}
								dataRow.title = item.account_id + (dataRow.region !== '' ? ' / ' : '') + dataRow.region

								if(item.engine_type !== undefined) {
									dataRow.resourceType = item.engine_type
								} else if(item.language_version !== undefined) {
									dataRow.resourceType = item.language_version
								} else if(item.instance_type !== undefined) {
									dataRow.resourceType = item.instance_type
								} else {
									dataRow.resourceType = ''
								}
							}
							totalCount = totalCount + item.total_count
							if(item.action === 'created') {
								dataRow.created = item.total_count
							}
							if(item.action === 'deleted') {
								dataRow.deleted = item.total_count
							}
							if(item.action === 'modified') {
								dataRow.modified = item.total_count
							}
						})
						dataRow.totalCount = totalCount
						if(dataRow.created === undefined) {
							dataRow.created = 0;
						}
						if(dataRow.deleted === undefined) {
							dataRow.deleted = 0;
						}
						if(dataRow.modified === undefined) {
							dataRow.modified = 0;
						}
						resourceTableArray.push(dataRow)					
					})
				}
				//console.log(resourceTableArray)
				this.setState({ resourceTable, resourceTableArray, showLoading: false })
			} else {
				this.setState({ resourceTable: [] })
			}
		})
	}

	getPurchaseDetails = () => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration

		if(this.state.selectedAccount.length) {
			params.account_id = this.state.selectedAccount
		}

		if(this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
			params.region = this.state.selectedRegion
		}

		params.resource_type = this.state.selectedServiceDetail

		this.props.getPurchaseDetails(params, (promise, purchaseDetails) => {
			let donutType = ''
			if(Object.entries(purchaseDetails.reservation_details).length) { 
				Object.entries(purchaseDetails.reservation_details).sort().slice(0,1).forEach(([key,value]) => {
				donutType = key
				})
			}
			if (promise) {
				this.setState({ purchaseDetails, selectedReservationType: donutType, showLoading: false }, 
					() => {
						this.getPurchaseDonutDetails()
						this.getReservationDonutDetails()
					}
				)
			} else {
				this.setState({ purchaseDetails: [] })
			}
		})
	}

	getPurchaseByInstanceType = () => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration

		if(this.state.selectedAccount.length) {
			params.account_id = this.state.selectedAccount
		}

		if(this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
			params.region = this.state.selectedRegion
		}

		params.resource_type = this.state.selectedServiceDetail

		this.props.getPurchaseByInstanceType(params, (promise, purchaseTableDetails) => {
			if (promise) {
				this.setState({ purchaseTableDetails, showLoading: false })
			} else {
				this.setState({ purchaseTableDetails: [] })
			}
		})
	}
	
	getResourceDonutDetails = () => {
		this.setState({ 
			donutData: this.state.resourceChart[this.state.resourceDonutType].donut.doughnut_datas, 
			donutOptions: this.state.resourceChart[this.state.resourceDonutType].donut.doughnut_option, 
			resourceTableArray: [] }, 
			() => this.getTotalAssetCount()
		)	
	}

	getPurchaseDonutDetails = () => {
		this.setState({ 
			purchaseDonutData: this.state.purchaseDetails.pricing_model.donut.doughnut_datas, 
			purchaseDonutOptions: this.state.purchaseDetails.pricing_model.donut.doughnut_option},
			() => this.getPurchaseByInstanceType()
		)
	}

	getReservationDonutDetails = () => {
		this.setState({ 
			reservationDonutData: this.state.purchaseDetails.reservation_details[this.state.selectedReservationType].donut.doughnut_datas, 
			reservationDonutOptions: this.state.purchaseDetails.reservation_details[this.state.selectedReservationType].donut.doughnut_option
		})
	}

	getCheckDetails = () => {
		this.getTotalCount('status')
		this.getTotalCount('risk')
		this.getTotalCount('table')
		this.getDayWiseCount()
	}

	getTotalCount = aggregateBy => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		params.resource_type = this.state.selectedServiceDetail
		if(aggregateBy !== 'table') {
			params.aggregate_by = aggregateBy
		} else {
			let array = []
			array.push('account_id')
			array.push('service_name')
			array.push('risk')
			if(this.state.selectedAccount.length) {
				params.account_id = this.state.selectedAccount
			}
			if(this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
				params.region = this.state.selectedRegion
			}

			if(this.state.selectedRegion.length) {
				array.push('region')
			}

			params.aggregate_by = array
		}

		this.props.getTotalCount(params, (promise, totalCount) => {

			if (promise) {
				if(aggregateBy === 'status') {
					this.setState({ checkStatus: totalCount, showLoading: false },
						() => this.formDoughnutChart()
					)
				} else if(aggregateBy === 'risk') {
					let checkRiskArray = []
					let dataRow = {}
					let totalFail = 0
					if(totalCount.length) {
						totalCount.forEach((item, index) => {					
							if(index === 0) {
								dataRow.name = item.resource_type
							}
							totalFail = totalFail + item.fail_count 
							if(item.risk === 'Low') {
								dataRow.Low = item.fail_count 
							}
							if(item.risk === 'Medium') {
								dataRow.Medium = item.fail_count 
							}
							if(item.risk === 'High') {
								dataRow.High = item.fail_count 
							}
							if(item.risk === 'Critical') {
								dataRow.Critical = item.fail_count 
							}
						})
						dataRow.totalFail = totalFail
						if(dataRow.Low === undefined) {
							dataRow.Low = 0
						}
						if(dataRow.Medium === undefined) {
							dataRow.Medium = 0
						}
						if(dataRow.High === undefined) {
							dataRow.High = 0
						}
						if(dataRow.Critical === undefined) {
							dataRow.Critical = 0
						}
						checkRiskArray.push(dataRow)				
					}
					this.setState({ checkRisk: totalCount, checkRiskArray, showLoading: false })
				} else if(aggregateBy === 'table') {
					const checkTableArrray = this.formatChecksTableData(params.aggregate_by, totalCount)	
					this.setState({ checkTable: totalCount, checkTableArrray,  showLoading: false })
				}
			} else {
				if(aggregateBy === 'status') {
					this.setState({ checkStatus: [], showLoading: false })
				} else if(aggregateBy === 'status') {
					this.setState({ checkRisk: [], showLoading: false })
				} else if(aggregateBy === 'table') {
					this.setState({ checkTable: [], showLoading: false })
				}
			}
		})
	}

	getDayWiseCount = aggregateBy =>{
		let params = {}        
        params.duration = this.state.selectedDuration
        // if (this.state.selectedAccount !== '') {
		// 	params.account_id = this.state.selectedAccount
        // }
        params.provider = this.state.selectedProvider.toLowerCase()

		params.aggregate_by = 'risk'
		params.resource_type = this.state.selectedServiceDetail
		
		this.props.getDayWiseCount(params, (promise, checkDaywiseCount) => {
			if (promise) {
				this.setState({ checkDaywiseCount, showLoading: false }, 
					() => this.checkBarGraph()
				)
			} else {
				this.setState({ checkDaywiseCount: [], showLoading: false })
			}
		})		
	}
	
	checkBarGraph= () => {	
		let graphData = this.state.checkDaywiseCount
		let graphLabels = []
		let barChartData = [];
		let summaryGraphLabelsData = [];

		let title ='';
		if(graphData && Object.entries(graphData).length) {
			let graphLabels = [];
			for (const [key, value] of Object.entries(graphData)) {
				graphLabels = value.labels
				let summaryDataSet = {};
				summaryDataSet['data'] = value.data.fail
				summaryDataSet['name'] = key;
				barChartData.push(summaryDataSet);
			}

			let length = graphLabels.length;	
			let labelInterval = 1;
			let showLabel = 1
			for (let x = 0;x<length;x++) {
				if(graphLabels[x] !== undefined) {
					if(length === 90 || length === 60) {
						if(labelInterval !== 1) {
							showLabel = 0;
							labelInterval--;
						} else {
							showLabel = 1;
							labelInterval = 10;
						}
					} else if(length === 30) {
						if(labelInterval !== 1) {
							showLabel = 0;
							labelInterval--;
						} else {
							showLabel = 1;
							labelInterval = 5							
						}
					} 
					if(showLabel === 1) {
						summaryGraphLabelsData.push(moment(graphLabels[x]).format('DD MMM'))
					} else {
						summaryGraphLabelsData.push('')		
					}
				} else {
					summaryGraphLabelsData.push('')
				}
			}
		} else {
			let summaryDataSet = {};
			let length = graphLabels.length;
			let emptyArray = []
			for (let x = 0;x<length;x++) {
				emptyArray.push(0)
			}

			summaryDataSet['data'] = emptyArray
			summaryDataSet['name'] = 'No Record';
			barChartData.push(summaryDataSet);
		}
		const barChartOptions = {
			tooltip: {
				enabled: true,
				custom: function({series, seriesIndex, dataPointIndex, w}) {
				  	let val  = series[seriesIndex][dataPointIndex];
					val = Math.abs(Math.round(val))
					let labelName = w.globals.initialSeries[seriesIndex].name
					//let val = str.replace('-',series[seriesIndex][dataPointIndex]);
					return '<div className="arrow_box">' +
						'<span> '+ w.globals.labels[dataPointIndex] + 
						' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val + ' </span>' +
						'</div>'
				},
				style: {
				  	fontSize: '9px',
				}
			},				
			chart: {
				toolbar: {
					show: false,
				},
				type: 'bar',
				height: 440,
				stacked: true,
			},
			colors: ['#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'],
	
			plotOptions: {
				bar: {
					horizontal: false,
					barHeight: '40%',
					startingShape: 'flat',
					endingShape: 'flat',
					columnWidth: '60%',
					distributed: false,
					rangeBarOverlap: false,
					colors: {
						ranges: [{
							from: 1,
							to: 0.9,
							color: '#FF4560'
						}],
						backgroundBarColors: ['#EDEDED'],
						backgroundBarOpacity: 1,
						backgroundBarRadius: 0,
					}        
				},          
			},
			dataLabels: {
			  enabled: false
			},
			
			grid: {
				xaxis: {
					lines: {
						show: false
					},
				},
				yaxis: {
					lines: {
						show: false
					}
				},
				row: {
					colors: undefined,
					opacity: 0.5
				},  
				column: {
					colors: undefined,
					opacity: 0.5
				}, 
			},
			title: {
			  text: title
			},
			xaxis: {
				categories: summaryGraphLabelsData,
				labels: {
					show: true,
					style: {
						fontSize: '9px'
					}
				}
			},
			legend: {
				show: true,
				position: 'bottom',
			},
		}
		
		this.setState({check_bar_data:barChartData, check_bar_option:barChartOptions});
	}

	formDoughnutChart = () => {
		let data = this.state.checkStatus
		let doughnutChartData = [];
		let doughnuGraphLabels = [];
		let backgroundColor = [];
		let hoverBackgroundColor = [];
	
		if(data.length) {
			backgroundColor = [
				'#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'
			];
			hoverBackgroundColor = [
				'#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'
			];
			let sum = 0;
			data.forEach(item => {
				if(item.status === 'fail') {
					sum = sum + parseInt(item.fail_count)
					doughnuGraphLabels.push(item.resource_type+': '+item.fail_count);
					doughnutChartData.push(item.fail_count);
				}
			})
	
			if(sum === 0) {
				doughnutChartData = [100, 100];
				backgroundColor = ['#ececec'];
				hoverBackgroundColor = ['#ececec'];
			}
		} else {
			doughnuGraphLabels.push('No records');
			doughnutChartData.push(100);
			backgroundColor = ['#ececec'];
			hoverBackgroundColor = ['#ececec'];
		}
			
		let datasetsColumn = [{
			data: doughnutChartData,
			backgroundColor: backgroundColor,
			hoverBackgroundColor: hoverBackgroundColor,
			barThickness : 5,
		}];
	
		let doughnut_datas = {labels:doughnuGraphLabels, datasets: datasetsColumn}; 
		
		let doughnut_option = {
			tooltips: {
				callbacks: {
					label: function(tooltipItem, doughnutChartData) {
						//let value = doughnutChartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
						//let color = doughnutChartData.datasets[tooltipItem.datasetIndex].backgroundColor[tooltipItem.index]
						let label = doughnutChartData.labels[tooltipItem.index]
						return label;
					}
				}
			},
			cutoutPercentage: 50,
			animate   : true,
			animation: { duration: 1000 },
			maintainAspectRatio: false,
			responsive: false,
			legend: {				
				display:true,
				position: 'right',
				labels: {
					boxWidth: 15,
					fontSize: 9
				}
			}
		}
		this.setState({ check_doughnut_datas: doughnut_datas, check_doughnut_options: doughnut_option })
	}

	formatChecksTableData = (searchInputs, data) => {
		const index = searchInputs.indexOf('risk');
		if (index > -1) {
			searchInputs.splice(index, 1);
		}
		var result = {};
		data.forEach((item) => {
			var keys = [];
			searchInputs.forEach((groupByKey) => {
				keys.push(item[groupByKey])
			})
			if(!result.hasOwnProperty(keys.join(' / '))) {
				result[keys.join(' / ')] = [item]
			} else {
				result[keys.join(' / ')].push(item);
			}
		})
		
		let resultArrray = []
		if(Object.entries(result).length) {
			Object.entries(result).forEach(([key,value]) => {
				let dataRow = {}
				let totalFail = 0
				let totalPass = 0
				let totalPending = 0
				let totalNa = 0
				let totalResolved = 0
				let totalReoccured = 0
				if(value.length) { 
					console.log(value)
					value.forEach((item, index) => {
						totalFail = totalFail + item.fail_count
						totalPass = totalPass + item.pass_count 
						totalPending = totalPending + item.pending_count 
						totalNa = totalNa + item.NA_count 
						totalResolved = totalResolved + item.resolved_count 
						totalReoccured = totalReoccured + item.reoccurred_count 
						
						if(index === 0) {
							if(item.region !== undefined) {
								dataRow.region = item.region
							} else {
								dataRow.region = ''
							}
							dataRow.service_name = item.service_name
							dataRow.account_id = item.account_id
							dataRow.duration = item.duration
							dataRow.provider = item.provider
							dataRow.resource_type = item.resource_type
							dataRow.title = item.account_id + (dataRow.region !== '' ? ' / ' : '') + dataRow.region
						}

						if(item.risk === 'Low') {
							dataRow.Low_fail = item.fail_count 
							dataRow.Low_pass = item.pass_count 
							dataRow.Low_pending = item.pending_count 
							dataRow.Low_na = item.NA_count 
							dataRow.Low_resolved = item.resolved_count 
							dataRow.Low_reoccured = item.reoccurred_count 
						}
						if(item.risk === 'Medium') {
							dataRow.Medium_fail = item.fail_count 
							dataRow.Medium_pass = item.pass_count 
							dataRow.Medium_pending = item.pending_count 
							dataRow.Medium_na = item.NA_count 
							dataRow.Medium_resolved = item.resolved_count 
							dataRow.Medium_reoccured = item.reoccurred_count 
						}
						if(item.risk === 'High') {
							dataRow.High_fail = item.fail_count 
							dataRow.High_pass = item.pass_count 
							dataRow.High_pending = item.pending_count 
							dataRow.High_na = item.NA_count 
							dataRow.High_resolved = item.resolved_count 
							dataRow.High_reoccured = item.reoccurred_count 
						}
						if(item.risk === 'Critical') {
							dataRow.Critical_fail = item.fail_count 
							dataRow.Critical_pass = item.pass_count 
							dataRow.Critical_pending = item.pending_count 
							dataRow.Critical_na = item.NA_count 
							dataRow.Critical_resolved = item.resolved_count 
							dataRow.Critical_reoccured = item.reoccurred_count 
						}
					})
					
					dataRow.totalFail = totalFail
					dataRow.totalPass = totalPass
					dataRow.totalPending = totalPending
					dataRow.totalNa = totalNa
					dataRow.totalResolved = totalResolved
					dataRow.totalReoccured = totalReoccured

					if(dataRow.Low_fail === undefined) {
						dataRow.Low_fail = 0
						dataRow.Low_pass = 0
						dataRow.Low_pending = 0
						dataRow.Low_na = 0
						dataRow.Low_resolved = 0
						dataRow.Low_reoccured = 0
					}
					if(dataRow.Medium_fail === undefined) {
						dataRow.Medium_fail = 0
						dataRow.Medium_pass = 0
						dataRow.Medium_pending = 0
						dataRow.Medium_na = 0
						dataRow.Medium_resolved = 0
						dataRow.Medium_reoccured = 0
					}
					if(dataRow.High_fail === undefined) {
						dataRow.High_fail = 0
						dataRow.High_pass = 0
						dataRow.High_pending = 0
						dataRow.High_na = 0
						dataRow.High_resolved = 0
						dataRow.High_reoccured = 0
					}
					if(dataRow.Critical_fail === undefined) {
						dataRow.Critical_fail = 0
						dataRow.Critical_pass = 0
						dataRow.Critical_pending = 0
						dataRow.Critical_na = 0
						dataRow.Critical_resolved = 0
						dataRow.Critical_reoccured = 0
					}
					resultArrray.push(dataRow)				
				}
			})
		}
		return resultArrray;
	}

	render() {		
		return (
			<div className="advanced-search" style={{zIndex:9999}}>
				<div className="search-content">
					<div className="header-search bd-highlight">
						<div className="flex-fill bd-highlight">
						<h5>{(this.state.selectedServiceDetail)}</h5>
						<div className="mr-2 d-flex">
								<div>
									<p className="m-0 pl-2 small">Duration: {this.state.selectedDuration === '7d'? ' This Week' : this.state.selectedDuration === '+7d'? ' Last 1 week' : 
										this.state.selectedDuration === '+14d'? ' Last 2 weeks' : 
										this.state.selectedDuration === '+30d'? ' Last 1 month' : 
										this.state.selectedDuration === '+60d'? ' Last 2 months' : 
										this.state.selectedDuration === '+90d'? ' Last 3 months' : 
										this.state.selectedDuration }</p>
								</div>
							</div>
						</div>
						<div className="text-right flex-fill bd-highlight ml-2 align-self-center">
							<i className="far fa-times cursorPointer" onClick={() => this.props.closeSidePanel()}></i>
						</div>
					</div>
					<div className="overflow-auto">
						<ul className="nav nav-tabs bd-highlight" id="myTab" role="tablist">
							<li className="nav-item">
								<span
									className={`nav-link cursor-pointer ${this.state.activeTab === 0 ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
									style={{fontSize:'12px'}}
									onClick={() => this.setState(
										{ activeTab: 0, showLoading:true},
										() => {
											if(Object.entries(this.state.resourceChart).length === 0)  {
												this.setState({ donutData: {}, donutOptions: {} },
													() => this.getResourceChart()
												)
											} else {
												this.setState({ detailsDonutData: {}, showLoading:false }, 
													() => this.getResourceDontuData()
												)
											}
										}
									)}
								>
								Resources
								</span>
							</li>
							<li className="nav-item">
								<span 
									className={`nav-link cursor-pointer ${this.state.activeTab === 1 ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
									style={{fontSize:'12px'}}
									onClick={() => this.setState({ activeTab: 1, showLoading:true}, 
									() => {
										if(Object.entries(this.state.purchaseDetails).length === 0) {
											this.setState({ purchaseDonutData: {}, purchaseDonutOptions: {}, reservationDonutData: {}, reservationDonutOptions: {} },
													() => this.getPurchaseDetails()
												)
											} else {
												this.setState({ purchaseDonutData: {}, purchaseDonutOptions: {}, reservationDonutData: {}, reservationDonutOptions: {}, showLoading:false }, 
													() => {
														this.getPurchaseDonutDetails()
														this.getReservationDonutDetails()
													}
												)
											}
										}
									)}
								>
									Purchase
								</span>
							</li>
							<li className="nav-item">
								<span
									className={`nav-link cursor-pointer ${this.state.activeTab === 2 ? 'active' : ''} ${this.state.showLoading ? 'disabled'	 : ''}`}
									style={{fontSize:'12px'}}
									onClick={() => this.setState({ activeTab: 2, showLoading:true}, 
									() => {
										if(Object.entries(this.state.checkDaywiseCount).length === 0) {
											this.getCheckDetails()
										} else {
											this.setState({ showLoading: false })
										}
									})}
								>
								Checks
								</span>
							</li>
						</ul>
						{
							!this.state.showLoading?
							<div className="tab-content" id="myTabContent">
								<div className="tab-pane fade show active" id="assets" role="tabpanel"
									aria-labelledby="related-tab">
									{
										this.state.activeTab === 0 &&
										<div className="col-sm-12">
											<div className="row">
												<div className="col-sm-12">
												<p className="mb-0">Results of Top 10 Resources that are contributing to most failed checks in your cloud infrastructure. Status by Fail and Risk count are shown
												</p>
												</div>
												<div className="col-sm-6 align-self-center border-right">
													<div className="d-flex justify-content-end">
														<select
															className='customDropdown mr-3 '
															value={this.state.resourceDonutType}
															onChange={event => {
																this.setState(
																	{
																		resourceDonutType: event.target.value,
																		detailsDonutData: {}
																	},
																	() => this.getResourceDontuData()
																)
															}}
														>														
															{Object.entries(this.state.resourceChart).length && Object.entries(this.state.resourceChart).sort().map(([key,value]) => {
																return (
																	(key === 'instance_type' || key === 'os' || key === 'language' || key === 'engine') ?
																		<option key={key} value={key} name={key === 'instance_type' ? 'size ' : key}>
																			{key === 'instance_type' ? 'size ' : key}
																		</option>
																	: null
																)
															})}
														</select>
													</div>
													{this.state.detailsDonutData && Object.entries(this.state.detailsDonutData).length ?
														<ApexDonutChart
															graphData={this.state.detailsDonutData}
															legend={true}
															showTotal={true}
															showTotalLable={true}
															showGraphHeading={false}
															height={230}
															size={'60%'}
															gradient={false}
															colors={['#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5']}
															gradientColor={['#A88CCC', '#D88ACF']}
															className={"transparentTooltip leftSide"}
															emptyGraphColor={"#2D323E"}
															totalColor={'#000000'}
														/>
													: null}
												</div>
												<div className="col-sm-6 align-self-center">
													<p className='pt-2 mb-2 w-100'><b>By Region</b></p>
													{Object.entries(this.state.resourceChart).length && Object.entries(this.state.resourceChart.region).length ? this.state.resourceChart.region.map((regions,x) => {
														return (
															<div className="bar-label">
																<p className="m-0 p-0"> <span className="cursorPointer" id={`allRegion_`+x}>{regions.region}</span></p>
																
																<div className="d-flex mt-1 mb-2">
																	<div className="w-100">
																		<div className="progress">
																			{regions.data.length ?
																				<UncontrolledPopover className="regionPopOver" trigger="hover" placement="top" target={`allRegion_`+x}>
																					<PopoverBody>
																						{regions.data.map((item,i) => {
																							return(
																							<React.Fragment>
																								{i===0 ?
																								<p className={`font-weight-bold mb-2`}>Region : {regions.region}</p>
																								: null }
																								
																								<div className={`w-50 pb-2 cursorPointer ${i%2 ? 'float-right pl-2' : regions.data.length === 1 ?  'float-left' : 'float-left border-right' } `}>
																									<div className="d-flex mb-2">
																										<p className="mb-0 text-left">{item.key}: <span className="font-weight-bold mb-0 text-left">{item.count}</span></p>
																									</div>
																								</div>
																							</React.Fragment>
																							)
																						})}
																					</PopoverBody>
																				</UncontrolledPopover>
																				: null }
																			{regions.data.map((item,i) => {														
																				let totalCount = regions.totalCount
																				return (
																					<div className="progress-bar" role="progressbar" id={`region_${x}_${i}`} style={{backgroundColor:item.color, width: parseFloat(item.count*100/totalCount)+'%'}} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">
																					<UncontrolledTooltip placement='top' target={`region_${x}_${i}`}>{capitalizeFirstLetter(item.key) +': '+item.count}</UncontrolledTooltip>
																					</div>
																				)
																			})}
																		</div>
																	</div>
																</div>
															</div>
														)
													})
													: null 
													}
												</div>
												<div className="container-fluid">
													<div className="row displayNone">
														<div className="col-md-6">
															<ul className="list-unstyle m-0 p-0">
																<li className="mb-2 f12 d-flex"> 
		
																	<span className="d-block text-muted font-weight-bolder">
																		<b className="text-primary f18">5370</b> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the
																	</span>
																</li>
															</ul>
														</div>
														<div className="col-md-6">
															<ul className="list-unstyle m-0 p-0">
																<li className="mb-2 f12 d-flex"> 
		
																	<span className="d-block text-muted font-weight-bolder">
																		<b className="text-primary f18">5370</b> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the
																	</span>
																</li>
															</ul>
														</div>
													</div>
													{/* <div className="row mt-3"> */}
													<div className="mt-3 mb-3">
														<div className="col-sm-12 p-0">
															<div className="mb-1 d-flex">
																<div className="align-self-center">
																	<p className="mb-1 small text-muted">
																		Showing {this.state.resourceTableArray && this.state.resourceTableArray.length > 10 ? 10 : this.state.resourceTableArray && this.state.resourceTableArray.length} results out of {this.state.resourceTableArray && this.state.resourceTableArray.length} resources
																	</p>
																</div>
															</div>
															{this.state.resourceTableArray &&
																<CustomTable
																	columns={[
																		{
																			//'title'
																			Header: 'Account Name',
																			accessor: d =>  getAccountNameFromId(d.title, this.state.accounts),
																			Cell: cellInfo => (
																				<React.Fragment>
																				<span className=''>
																					{getAccountNameFromId(cellInfo.row.original.account_id, this.state.accounts)}
																				</span>
																				</React.Fragment>
																			),
																			
																			
																		},
																		{
																			Header: (this.state.resourceDonutType === 'instance_type' ? 'size' : this.state.resourceDonutType),
																			accessor: 'resourceType'
																			
																		},
																		{
																			Header: 'Total',
																			accessor: 'totalCount'
																		},
																		{
																			Header: 'Created',
																			accessor: 'created',
																		},
																		{
																			Header: 'Modified',
																			accessor: 'modified',
																		},
																		{
																			Header: 'Deleted',
																			accessor: 'deleted',
																		}
																	]}
																	data={this.state.resourceTableArray}
																	onClickRow={tableRow => {}}
																	dashboard = {this.state.resourceTableArray.length > 10 ? true : false }
																	perPage={10}
																	tooltipFor={[0, 1]}
																/>
															}
														</div>
													</div>
												</div>
											</div>
										</div>
									}
									{
										this.state.activeTab === 1 &&
										<div className="col-sm-12">
											<div className="row">
												<div className="col-sm-12">
												<p className="mb-0">Results of Purchase are contributing to most failed checks in your cloud infrastructure. Status by Fail and Risk count are shown
												</p>
												</div>
												<div className="col-sm-6 align-self-center border-right">
													<p className='pt-2 mb-2'><b>Pricing Model</b></p>
												</div>
												<div className="col-sm-6 align-self-center">
													<div className="d-flex">
														<p className='pt-2 mb-2 w-50'><b>Reservation Details</b></p>				
														<select
															className='customDropdown w-50 float-right'
															value={this.state.selectedReservationType}
															onChange={event => {
																this.setState({ selectedReservationType: event.target.value },
																	() => this.getReservationDonutDetails()
																)
															}}>			
															{Object.entries(this.state.purchaseDetails).length && Object.entries(this.state.purchaseDetails.reservation_details).map(([key,value]) => {
																let label = key.replace(/_/g, ' ');
																return (
																	<option key={key} value={key}>
																		{label}
																	</option>
																)
															})}
														</select>
													</div>
												</div>

												<div className="col-sm-6 align-self-center pt-3 mb-2 border-right">
													<Doughnut data={this.state.purchaseDonutData} options={this.state.purchaseDonutOptions} width={100} height={70} />												
												</div>
												<div className="col-sm-6 align-self-center pt-3 mb-2">
													<Doughnut data={this.state.reservationDonutData} options={this.state.reservationDonutOptions} width={100} height={70} />										
												</div>
												<div className="container-fluid">
													<div className="row displayNone">
														<div className="col-md-6">
															<ul className="list-unstyle m-0 p-0">
																<li className="mb-2 f12 d-flex"> 
		
																	<span className="d-block text-muted font-weight-bolder">
																		<b className="text-primary f18">5370</b> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the
																	</span>
																</li>
															</ul>
														</div>
														<div className="col-md-6">
															<ul className="list-unstyle m-0 p-0">
																<li className="mb-2 f12 d-flex"> 
		
																	<span className="d-block text-muted font-weight-bolder">
																		<b className="text-primary f18">5370</b> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the
																	</span>
																</li>
															</ul>
														</div>
													</div>
													{/* <div className="row mt-3"> */}
													<div className="mt-3 mb-3">
														<div className="col-sm-12 p-0">
															<div className="mb-1 d-flex">
																<div className="align-self-center">
																	<p className="mb-1 small text-muted">
																		Showing {this.state.purchaseTableDetails && this.state.purchaseTableDetails.length > 10 ? 10 : this.state.purchaseTableDetails && this.state.purchaseTableDetails.length} results out of {this.state.purchaseTableDetails && this.state.purchaseTableDetails.length} Pricing
																	</p>
																</div>
															</div>
															{this.state.purchaseTableDetails &&
																<CustomTable
																	columns={[
																		{
																			Header: 'Size',
																			accessor: 'instance_type'
																			
																		},
																		{
																			Header: 'Count',
																			accessor: 'count',
																		},
																		{
																			Header: 'Model',
																			accessor: 'model',
																		},
																		{
																			Header: 'Reservation Detail',
																			accessor: 'reservation_details',
																		}
																	]}
																	data={this.state.purchaseTableDetails}
																	onClickRow={tableRow => {}}
																	dashboard = {this.state.purchaseTableDetails.length > 10 ? true : false }
																	perPage={10}
																	//tooltipFor={[3, 6]}
																/>
															}
														</div>
													</div>
												</div>
											</div>
										</div>
									}
									{
										this.state.activeTab === 2 &&
										<div className="col-sm-12">
											<div className="row">
												<div className="col-sm-12">
												<p className="mb-0">Results of Top Checks that are contributing to most failed checks in your cloud infrastructure. Status by Fail and Risk count are shown
												</p>
												</div>
												<div className="col-sm-12 align-self-center">													
													<p className='w-100 pt-2 mb-2'><b>By Risk</b></p>
													{this.state.checkRiskArray ?
														<div className="w-100 mb-2 align-self-center">
															<table>
																<tbody>
																	<tr>
																		<td>
																			<div className="stock-bar">
																				<ul className="stock-legend mb-1 p-0"> 
																					<li><span className="legend-circle-critical"></span> <span className="text-muted d-inline-block"> &nbsp;Critical: {this.state.checkRiskArray[0].Critical}</span></li> 
																					<li><span className="legend-circle-high"></span> <span className="text-muted d-inline-block"> &nbsp; High: {this.state.checkRiskArray[0].High}</span></li> 
																					<li><span className="legend-circle-medium"></span> <span className="text-muted d-inline-block"> &nbsp; Medium: {this.state.checkRiskArray[0].Medium}</span></li>
																					<li><span className="legend-circle-low"></span> <span className="text-muted d-inline-block"> &nbsp; Low: {this.state.checkRiskArray[0].Low}</span></li>  
																					
																				</ul>
																				<div className="table-div">
																					<div style={{width: parseFloat(this.state.checkRiskArray[0].Critial *100 / this.state.checkRiskArray[0].totalFail) + '%'}}></div>
																					<div style={{width: parseFloat(this.state.checkRiskArray[0].High *100 / this.state.checkRiskArray[0].totalFail) + '%'}}></div>
																					<div style={{width: parseFloat(this.state.checkRiskArray[0].Medium *100 / this.state.checkRiskArray[0].totalFail) + '%'}}></div>
																					<div style={{width: parseFloat(this.state.checkRiskArray[0].Low *100 / this.state.checkRiskArray[0].totalFail) + '%'}}></div>
																				</div>
																			</div>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													: null
													}

												</div>

												<div className="col-sm-12 align-self-center pt-3 mb-2">
													<p className='pt-2 mb-2'><b>By Trend</b></p>
													{this.state.check_bar_data && this.state.check_bar_data.length && this.state.check_bar_option ?
														<div className="mt-n4">																		
															<Chart type="bar" series={this.state.check_bar_data} width="100%" height={300} options={this.state.check_bar_option}/>
														</div>
													: null
													}
												</div>
												<div className="container-fluid">
													<div className="row displayNone">
														<div className="col-md-6">
															<ul className="list-unstyle m-0 p-0">
																<li className="mb-2 f12 d-flex"> 
		
																	<span className="d-block text-muted font-weight-bolder">
																		<b className="text-primary f18">5370</b> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the
																	</span>
																</li>
															</ul>
														</div>
														<div className="col-md-6">
															<ul className="list-unstyle m-0 p-0">
																<li className="mb-2 f12 d-flex"> 
		
																	<span className="d-block text-muted font-weight-bolder">
																		<b className="text-primary f18">5370</b> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the
																	</span>
																</li>
															</ul>
														</div>
													</div>
													{/* <div className="row mt-3"> */}
													<div className="mt-3 mb-3">
														<div className="col-sm-12 p-0">
															<div className="mb-1 d-flex">
																<div className="align-self-center">
																	<p className="mb-1 small text-muted">
																		Showing {this.state.checkTableArrray && this.state.checkTableArrray.length > 10 ? 10 : this.state.checkTableArrray && this.state.checkTableArrray.length} results out of {this.state.checkTableArrray && this.state.checkTableArrray.length} resources
																	</p>
																</div>
															</div>
															{this.state.checkTableArrray &&
																<CustomTable
																	columns={[
																		{
																			//Header: 'Title',
																			//accessor: 'title',
																			Header: 'Account Name',
																			accessor: d =>  getAccountNameFromId(d.title, this.state.accounts),
																			Cell: cellInfo => (
																				<React.Fragment>
																				<span className=''>
																					{getAccountNameFromId(cellInfo.row.original.account_id, this.state.accounts)}
																				</span>
																				</React.Fragment>
																			),
																			//width: 200
																		},
																		{
																			Header: 'Service',
																			accessor: 'service_name',
																			width: 200
																		},
																		{
																			Header: 'Total',
																			accessor: 'totalFail',
																		},
																		{
																			Header: 'Critical',
																			accessor: 'Critical_fail',
																		},
																		{
																			Header: 'High',
																			accessor: 'High_fail',
																		},
																		{
																			Header: 'Medium',
																			accessor: 'Medium_fail',
																		},
																		{
																			Header: 'Low',
																			accessor: 'Low_fail',
																		}
																	]}
																	data={this.state.checkTableArrray}
																	onClickRow={tableRow => {}}
																	dashboard = {this.state.checkTableArrray.length > 10 ? true : false }
																	perPage={10}
																	tooltipFor={[0, 1]}
																/>
															}
														</div>
													</div>
												</div>
											</div>
										</div>
									}

								</div>
							</div>
							:
							<div className='text-center mt-5' >
								<Spinner color='dark' size='lg' />
							</div>
						}
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
SidePanel.propTypes = {
	getResourceChart: PropTypes.func,  //Resource
	getTotalAssetCount: PropTypes.func,  //Resource
	getPurchaseDetails: PropTypes.func,  //Purchase
	getPurchaseByInstanceType: PropTypes.func,  //Purchase
	getTotalCount: PropTypes.func, //checks
	getDayWiseCount: PropTypes.func, //checks
}

const mapStateToProps = state => {
	return {		
	}
}

export default connect(mapStateToProps, {
	getResourceChart, //Resource
	getTotalAssetCount, //Resource
	getPurchaseDetails, //Purchase
	getPurchaseByInstanceType, //Purchase
	getTotalCount, //checks
	getDayWiseCount, //checks
})(withRouter(SidePanel))



