/*************************************************
 * Tvastar
 * @exports
 * @file Encryption.js
 * @author Prakash // on 14/09/2021
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
 import React, { Component } from 'react'
 import { connect } from 'react-redux'
 import { withRouter } from 'react-router-dom'
 import Select from 'react-select'

 class Encryption extends Component {
	hierarichalBucketBarChartRef = React.createRef()
	constructor(props) {
		super(props)
		this.props = props;
		this.state = {
			encryptOption: ['Customer1 - Department1', 'Customer1 - Department2'],
            yesNoOptions: ['Yes', 'No'],
			watermark: 'Yes',
            watermark_type: 'encryption'
		}
	}

	componentDidMount = () => {
        this.editEvent()
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.dlpPropsDetails && typeof this.props.dlpPropsDetails.hasPrivacyPolicyError !== 'undefined' && (prevProps.dlpPropsDetails.hasPrivacyPolicyError !== this.props.dlpPropsDetails.hasPrivacyPolicyError || (!this.state.hasError && (this.state.hasError !== this.props.dlpPropsDetails.hasPrivacyPolicyError)))) {
            this.setState({ hasError: this.props.dlpPropsDetails.hasPrivacyPolicyError })
        }
    }

    editEvent = () => {
        if(this.props.pageType === 'edit' || this.props.pageType === 'view') {
			let policyDetails = this.props.policyDetails
            let deidentification_type = policyDetails.config.deidentification_type
            let encryption_type = policyDetails.config.encryption_type ? policyDetails.config.encryption_type : ''

            let watermark_id = policyDetails.config.watermark_id ? policyDetails.config.watermark_id : ''
            let watermark_name = policyDetails.config.watermark_name ? policyDetails.config.watermark_name : ''
            let watermark_type = policyDetails.config.watermark_type ? policyDetails.config.watermark_type : ''

            this.setState({ deidentification_type, encryption_type, updted_encryption_type: encryption_type, watermark_id, watermark: watermark_id !== '' ? 'Yes' : 'No',watermark_name, watermark_type  }, () => this.onChangePropsEvent())
        }
    }

    onChangePropsEvent = () => {
        
		let errorMessage = ''
		if(!this.state.deidentification_type || this.state.deidentification_type === '') {
			errorMessage = 'Please select encryption type'
		}

        let data = {}
        data.deidentification_type = this.state.deidentification_type ? this.state.deidentification_type : ''
        data.encryption_type = this.state.encryption_type ? this.state.encryption_type : ''

        
        let selectedText = 'Encrypt type '+this.state.deidentification_label
        
        if(this.props.watermarkTypeArray[this.state.encryption_type] && this.props.watermarkTypeArray[this.state.encryption_type].length) {
            if(this.state.watermark === 'Yes') {
                data.watermark_type = this.state.watermark_type
                let filter = this.props.watermarkTypeArray[this.state.encryption_type].filter(arr => arr.watermark_type === this.state.watermark_type)
                if(filter.length) {
                    Object.entries(filter[0]).forEach(([key, value]) => {
                        data[key] = value
                    })
                }
                data.watermark_id = this.state.watermark_id
                data.watermark_name = this.state.watermark_name
                selectedText += ' watermark type '+this.state.watermark_type
                selectedText += ' with watermark '+this.state.watermark_name

                if(!data.watermark_name || data.watermark_name === '') {
                    errorMessage = 'Please select watermark'
                }
            }
        }

        this.props.selectedData(data, selectedText, errorMessage)
    }

    getSelectLabel = (item, list) => {
        let filter = list.filter(arr => arr.type === item)
        if(filter.length) {
            this.setState({ deidentification_label: filter[0].label })
        } else {
            this.setState({ deidentification_label: '', deidentification_type: '', encryption_type: '' })
        }
    }    

    onChangeType = () => {
        if(this.state.updted_encryption_type && this.state.encryption_type === this.state.updted_encryption_type) {
            this.editEvent()
        } else {
            this.onChangePropsEvent()
        }
    }

    render() {		
		return (
            <div className={`form-group mt-3`}>
                <div className="d-flex flex-wrap">
                    <p className="mb-0 mr-2 align-self-end text-purple mt-3">Encryption using</p>
                    <Select
                        // menuIsOpen={true}
                        placeholder={'Select'}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        isSearchable={false}
                        className={`selectOption align-self-end mx-2 ${this.state.hasError && (!this.state.encryption_type || this.state.encryption_type === '') ? 'dottedDangerSelectLine' : ''}`}
                        value={({
                            value: this.state.encryption_type ? this.state.encryption_type : 'Select',
                            label: this.state.encryption_type ? (this.state.deidentification_label ? this.state.deidentification_label : this.getSelectLabel(this.state.encryption_type, this.props.list)) : 'Select'
                        })}
                        options={this.props.list && this.props.list.map(item => ({
                            value: item.type,
                            label: item.label,
                            type: item.value,
                        }))}
                        onChange={event => 
                            this.setState({ deidentification_type: event.type, encryption_type: event.value, deidentification_label: event.label, showKey: true, watermark_name: '', watermark_type: '', watermark_id: '' }, 
                                () => this.onChangeType()
                            )
                        }
                    />
                    {this.props.watermarkTypeArray && this.props.watermarkTypeArray[this.state.encryption_type] && this.props.watermarkTypeArray[this.state.encryption_type].length ?
                        <div className="d-flex flex-wrap">
                            <p className="mb-0 mr-2 align-self-end text-purple mt-3">key</p>
                            <Select
                                // menuIsOpen={true}
                                placeholder={'Select'}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                isSearchable={false}
                                className={`selectOption mr-2 mt-3 w-30 ${this.state.hasError && (!this.state.watermark_name || this.state.watermark_name === '') ? 'dottedDangerSelectLine' : ''}`}
                                value={({
                                    value: this.state.watermark_id ? this.state.watermark_id : 'Select',
                                    label: this.state.watermark_name ? this.state.watermark_name : 'Select',
                                })}
                                options={this.props.watermarks && this.props.watermarks.map(item => ({
                                    value: item.watermark_id,
                                    label: item.watermark_name,
                                }))}
                                onChange={event =>  
                                    this.setState({ watermark_id: event.value, watermark_name: event.label }, () => this.onChangePropsEvent())
                                }
                            />
                        </div>
                    : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    // console.log('state', state)
	return {
        dlpPropsDetails: state.dlp.dlpPropsDetails,
        watermarks: state.dlp.dlpConfigWatermarklist
	}
}

export default connect(mapStateToProps, {})(withRouter(Encryption))