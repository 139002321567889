/*************************************************
 * Tvastar
 * @exports
 * @file ApexHorizontalStackedBar.js
 * @author Prakash // on 18/05/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { momentConvertionUtcToLocalTime, capitalizeFirstLetter, momentDateGivenFormat } from '../../../utils/utility'
import Chart from 'react-apexcharts'

class ApexHorizontalStackedBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tooltipType: this.props.tooltipType
        }
    }
    
    componentDidMount = () => {
        let graphData = this.props.graphData;
        if(graphData){

            let colors = this.props.colors ? this.props.colors : ['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']
            let series = []
            let categories = this.props.categories
            Object.entries(graphData).forEach(([key, value]) => {
                // categories.push(key)
                let seriesRow = {}
                seriesRow.name = capitalizeFirstLetter(key)
                seriesRow.data = value
                series.push(seriesRow)
            })

            let options = {
                tooltip: {
                    enabled: true,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        let returnData = ''
                        if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                            Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                if(!index) {
                                    returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'
                                    returnData += w.globals.initialSeries[index].data[dataPointIndex].x
                                    returnData += '</div>'
                                }
                                returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex].y+'</span></div></div></div>'
                            })
                        }

                        returnData += '</div>'
                        return returnData
                    },
                    style: {
                        fontSize: '9px',
                    },
                },				
                chart: {
                    redrawOnParentResize: true,
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: this.props.zoom,
                    },
                    sparkline: {
                        enabled: this.props.sparkline
                    },
                    animations: {
                        enabled: this.props.animation
                    },
                    type: 'bar',
                    height: 150,
                    stacked: this.props.stacked,
                },
                colors: colors,
                plotOptions: {
                    bar: {
                        horizontal: true,
                        barHeight: this.props.barHeight,
                        borderRadius: this.props.barEndShape && this.props.barEndShape === 'rounded' ? (this.props.barRadius ? this.props.barRadius : 10) : 0,
                        endingShape: this.props.barEndShape ? this.props.barEndShape : 'flat',
                        columnWidth: this.props.columnWidth ? this.props.columnWidth : '60%',
                        // columnWidth: "1%",
                        // colors: backgroundColors
                                
                    },       
                },
                dataLabels: {
                    enabled: false
                },                
                grid: {
                    show: false,			        
                    xaxis: {
                        lines: {
                            show: false
                        },
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: -5
                    },
                },
                xaxis: {
                    show: this.props.xaxis,
                    tooltip: {
                        enabled: false
                    },
                    axisTicks: {
                        show: false
                    },
                    categories: categories,
                    axisBorder: {
                        show: false,
                        color: '#434B5E',
                    },
                    labels: {
                        show: this.props.xaxisLabel,
                        style: {
                            colors: this.props.axisLabelColor,
                            fontSize: this.props.axisLabelFontSize ?  this.props.axisLabelFontSize : '13px',
                            fontWeight: 'bolder',
                        },
                    },
                    offsetX: 5,
                },
                yaxis: {
                    show: true,
                    axisBorder: {
                        show: false,
                        color: '#434B5E',
                    },
                    // tickAmount: parseInt(highestValue) <= 1 ? 1 : "",
                    labels: {
                        style: {
                            colors: this.props.axisLabelColor,
                            fontSize: '11px',
                            fontWeight: 'bolder',
                        },
                        offsetX: 5
                    },
                },
                legend: {
                    show: true,
                    offsetY: 7,
                    markers: {
                        radius: 12,
                    },
                    labels: {
                        colors: this.props.colors,
                        useSeriesColors: true
                    },
                },
            }
            
            this.setState({ series, options });
        }
    }

    render() {
        return(
            <div className={`${this.props.className}`}>
                {this.state.series ?
                    <Chart options={this.state.options} series={this.state.series} type="bar" height={this.props.height} />
                : null}
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
ApexHorizontalStackedBar.propTypes = {}

const mapStateToProps = state => {}

export default connect(mapStateToProps, {})(withRouter(ApexHorizontalStackedBar))