/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AssetBoard.js
 * @author Prakash // on 05/06/2020
 * @copyright © 2020 Tvastar. All rights reserved.
 **************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
////import Chart from 'chart.js'
import moment from 'moment'
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { AppWrapper } from '../common/AppWrapper'
import { 
	listOrganizations,
	listModules,
	listAllAccountsDetails,
} from '../../actions/commonAction'
import { CUSTOM_YESTERDAY_DURATION_FILTER_OPTIONS } from '../../utils/constants'
import { addSubTime, datestartEndTime } from '../../utils/utility'


class operationalSummary extends Component {
	constructor(props) {
		super(props)
		
		this.accountRef = React.createRef()
		this.organizationRef = React.createRef()
		this.moduleRef = React.createRef()
		this.handleDayClick = this.handleDayClick.bind(this);
		this.handleResetClick = this.handleResetClick.bind(this);
		this.state = this.getInitialState();

		this.state = {
			showDetailsPanel: false,
			left: 0,
			showLoading: false,
			
			providers: [],
            selectedProvider: '',
			accounts: [],
			accArray: [],
			accountArray: [],
			accountFilter: [],
			selectedAccount: [],
			selectedAccountArray: [],
			isAccountOpen: false,
			accountShowLength: 10,
			accStartRow: 0,
			accEndRow: 10,

			selectedUserOrgType: '',

            organizations: [],
			selectedOrganization: [],
			selectedOrganizationArray: [],			
			isOrganizationOpen: false,

			modules: [],
			selectedModule: [],
			selectedModuleArray: [],
			isModuleOpen: false,

			selectedDuration: 'yesterday',
			totalselectedTag: 'Select',
			selectedTags: [],
			tagFilter: false,
			
			isServiceOpen: false,
			selectedService: [],
			selectedType: 'Service',
			filterData: [],
			assetService: [],
			filterDetailsData: [],
			assetByServiceDetails: [],
			assetCategory: [],
			assetByCategoryDetails: [],
			
			overallCount: [],
			activeTab: 0,
			totalBilling: [],
		}
	}
	
	componentDidMount = () => {		
		this.setState({ showLoading: false })
		// this.props.listAllProviders((promise, providers) => {
		// 	if (promise) {
		// 		this.setState({ providers })
		// 		if (providers.length >= 1) {
		// 			this.setState(
		// 				{ selectedProvider: providers[0].provider_name },
		// 			)
		// 			this.filterIdentifiers('provider')
		// 			this.setState({ showLoading: true })
		// 		} else {
		// 			this.filterIdentifiers()
		// 		}
		// 	} else {
		// 		this.setState(
		// 			{ providers: [] }, 
		// 			() => this.filterIdentifiers()
		// 		)
		// 	}
		// })
		let params = {}
		this.props.listOrganizations(params, (promise, organizations) => {
			if (promise) {
				if(organizations.length) {
					let dataRow = {}
					dataRow.organization = "All"
					dataRow.org_name = "All"
					organizations.unshift(dataRow);
				}
				this.setState({ organizations })
			} else {
				this.setState({ organizations: [] })
			}
		})

		this.props.listModules(params, (promise, modules) => {
			if (promise) {
				if(modules.length) {
					let dataRow = {}
					dataRow.module_id = "All"
					dataRow.module_name = "All"
					modules.unshift(dataRow);
				}
				this.setState({ modules })
			} else {
				this.setState({ modules: [] })
			}
		})

		this.props.listAllAccountsDetails(params, (promise, accounts) => {
			if (promise) {
				let accountArray = []
				accounts.length && accounts.forEach((acc, i) => {
					if(i === 0) {
						let accRow = {}
						accRow.account_id = "All"
						accRow.account_name = "All"
						accRow.date = null
						accRow.disabled = false
						accRow.provider = "All"
						accountArray.push(accRow);
					}	
					accountArray.push(acc)			
				})
				console.log(accounts)
				this.setState({ accounts, accArray: accounts, accountArray, accountFilter: accountArray })
			} else {
				this.setState({ accounts: [], accountArray: [] })
			}
		})

		this.getYesterdaystartEndDate()

	}
	
	/**
	 * Filter identifiers based on the filter selection
	 */
	filterIdentifiers = from => {
		this.setState({ showLoading: false })
		if (from === 'organization') { //userOrg selectedUserOrgType
			let accArray = this.state.accounts
			if(this.state.selectedOrganization.length && this.state.selectedOrganization[0] !== 'All') {
				let formArray = []
				let formArrayFilter = []
				this.state.selectedOrganization.forEach((item, i) => {

					Array.prototype.push.apply(formArray, accArray.filter(account => account.organization === item));
					Array.prototype.push.apply(formArrayFilter, accArray.filter(account => account.organization === item));
				})
				this.setState({
					selectedAccountArray: [],
					accArray: formArray,
					accountFilter: formArrayFilter
				},
					() => this.formAccountFilter()
				)
				
			} else {
				this.setState({
					accArray,
					accountFilter: this.state.accountArray,
					selectedAccountArray: []
				})
			}
		}

		if(from === 'duration') {
			if(this.state.selectedDuration === 'yesterday') {
				this.getYesterdaystartEndDate() 
			}
			this.handleResetClick()
		}
	}
	
	formAccountFilter = () => {
		let accountFilter = this.state.accountFilter
		if(accountFilter.length) {
			let accRow = {}
			accRow.account_id = "All"
			accRow.account_name = "All"
			accRow.date = null
			accRow.disabled = false
			accRow.provider = "All"
			accRow.organization = 'All'
			accountFilter.unshift(accRow);
	
			this.setState({ accountFilter })
		}
	}
	getYesterdaystartEndDate = () => {
		let yesterday = addSubTime('', 'sub', 'd', 1)
		this.setState({ start_time: datestartEndTime(yesterday, 'start'), end_time: datestartEndTime(yesterday, 'end') })
	}
	
	handleMultiSelectChange = (childKey, arrayValue) => {
		let value = arrayValue.map(item => item.value)
		let label = arrayValue.map(item => item)	
		if(childKey === 'selectedAccount') {
			if(value.length && (value.includes("All") || this.state.selectedAccount.includes("All"))) {
				value = ['All']
				label = {}
				label.value = 'All'
				label.label = 'All'
				this.setState({ selectedAccount: ['All'], selectedAccountArray: [label], isAccountOpen: false },
					() => this.filterIdentifiers('account')
				)
			} else {
				this.setState({ selectedAccount: value, selectedAccountArray: arrayValue},
					() => this.filterIdentifiers('account')
				)

			}
		}
		if(childKey === 'selectedOrganization') {
			if(value.length && (value.includes("All") || this.state.selectedOrganization.includes("All"))) {
				value = ['All']
				label = {}
				label.value = 'All'
				label.label = 'All'
				this.setState({ selectedOrganization: ['All'], selectedOrganizationArray: [label], isOrganizationOpen: false },
					() => this.filterIdentifiers('organization')
				)
			} else {
				this.setState({ selectedOrganization: value, selectedOrganizationArray: arrayValue},
					() => this.filterIdentifiers('organization')
				)

			}
		}
		if(childKey === 'selectedModule') {
			if(value.length && (value.includes("All") || this.state.selectedModule.includes("All"))) {
				value = ['All']
				label = {}
				label.value = 'All'
				label.label = 'All'
				this.setState({ selectedModule: ['All'], selectedModuleArray: [label], isModuleOpen: false },
					() => this.filterIdentifiers('module')
				)
			} else {
				this.setState({ selectedModule: value, selectedModuleArray: arrayValue},
					() => this.filterIdentifiers('module')
				)

			}
		}		
	}
	
	handleClickOutside(event) {
		if(this.durationRef && !this.durationRef.current.contains(event.target)) {
			if (this.dayPickerRef && !this.dayPickerRef.current.contains(event.target)) {
				this.setState({ showDatepicker: false })
				this.handleResetClick()
			}
		}
		
		if (this.accountRef && !this.accountRef.current.contains(event.target)) {
			this.setState({ isAccountOpen: false })
		} else {
			this.setState({ isAccountOpen: true })
		}

		if (this.organizationRef && !this.organizationRef.current.contains(event.target)) {
			this.setState({ isOrganizationOpen: false })
		} else {
			this.setState({ isOrganizationOpen: true })
		}

		if (this.moduleRef && !this.moduleRef.current.contains(event.target)) {
			this.setState({ isModuleOpen: false })
		} else {
			this.setState({ isModuleOpen: true })
		}

	}
	
	getInitialState() {
		return {
			from: undefined,
			to: undefined,
		};	
	}

	handleResetClick = () => {
		this.setState(() => this.getInitialState());
	}

	handleDayClick(day) {
		if(this.state.from === undefined) {
			const range = DateUtils.addDayToRange(day, this.state);
			this.setState(
				{ 
					range, 
					from: day, 
					durationTypeFrom: moment(range.from).format('DD MMM'), 
					start_time: datestartEndTime(day, 'start') 
				}
			);
		} else {
			const newRange = {
				from: this.state.from,
				to: day
			}
			var Difference_In_Time = day.getTime() - this.state.from.getTime(); 			
			var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
			// if(Difference_In_Days < 6) {
			// 	alert('Please select 7 days for better view')
			// 	return;
			// }
			// let durtaion = '+'+(Difference_In_Days+1)+'d';
			if(Difference_In_Days > 0) {
				const range = DateUtils.addDayToRange(day, newRange);
				this.setState(
					{ 
						range, 
						to: day,
						showDatepicker: false, 
						selectedDuration: 'custom', 
						relatedIssueDurtaion: 'custom',
						durationType: this.state.durationTypeFrom + ' - ' + moment(range.from).format('DD MMM'),
						end_time: datestartEndTime(day, 'end')
					},
					() => this.filterIdentifiers('duration')
				);
			}
		}
	}

	onSearch = () => {
		return true;
	}

	onReset = () => {
		this.setState({ 
			selectedOrganization: [], 
			selectedOrganizationArray:[],
			selectedAccount: [], 
			selectedAccountArray: [], 
			selectedModule: [], 
			selectedModuleArray: [] ,
			selectedDuration: 'yesterday', 
		},
			() => this.getYesterdaystartEndDate()
		)
	}

	navigateAccountLIst = (slide) => {
		if(slide === 'left') {
			this.setState({
				accStartRow: this.state.accStartRow - this.state.accountShowLength,
				accEndRow: this.state.accEndRow - this.state.accountShowLength
			})
		} else {
			this.setState({ 
				accStartRow: this.state.accStartRow + this.state.accountShowLength,
				accEndRow: this.state.accEndRow + this.state.accountShowLength 
			})
		}

	}

	/**
	 * Renders Asset Board
	 */
	render() {
		const { from, to } = this.state;
		const modifiers = { start: from, end: to };
		return (
			<div className='container-fluid overflow-auto flex-grow-1' onClick={ (event) => { this.handleClickOutside(event) } }>
				<div className='row h-100'>
					<div className='col-sm-12 p-0'>
						<div className='title-section' style={{padding: "1rem 1rem 1rem 1rem"}} >
							<div className='row'>
								<div className='col-lg-4 d-flex align-self-center'>
									<div>
										<div className='d-flex'>
                                            <h6 className='text-white m-0'>Operational Summary</h6>
                                        </div>
										<p className='text-white mb-2'>
										Vero sed expedita sint tenetur voluptatibus. Tenetur iure ipsum sint et.
										</p>
									</div>
								</div>
								<div className={`col-lg-8 text-right`}>
									<div className='d-flex'>
										<div	
											className={`form-group my-2 flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} ref={this.organizationRef} style={{maxWidth: '200px' }}>
											<label className="mb-1">Organizations</label>
											<div className={`filterMultiSelectDropdown ${this.state.organizations && this.state.organizations.length > 10 ? '' : 'removeDropdownSearchBar'}`}>
												<ReactMultiSelectCheckboxes						
													placeholderButtonLabel="All"
													menuIsOpen ={this.state.isOrganizationOpen}
													value={this.state.selectedOrganizationArray.map(org => ({
														value: org.value,
														label: org.label
													}))}
													//setState={this.state.selectedAccountArray}
													onChange={arr => {
													this.handleMultiSelectChange('selectedOrganization', arr ? arr : []) }}
													options={this.state.organizations.map(org => ({
														value: org.organization,
														label: org.org_name,
													}))}
												/>
											</div>
										</div>
										<div	
											className={`form-group my-2 flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} ref={this.accountRef} style={{maxWidth: '200px' }}>
											<label className="mb-1">Account</label>
											<div className={`filterMultiSelectDropdown ${this.state.accounts && this.state.accounts.length > 10 ? '' : 'removeDropdownSearchBar'}`}>
												<ReactMultiSelectCheckboxes						
													placeholderButtonLabel="All"
													menuIsOpen ={this.state.isAccountOpen}
													value={this.state.selectedAccountArray.map(acc => ({
														value: acc.value,
														label: acc.label
													}))}
													//setState={this.state.selectedAccountArray}
													onChange={arr => {
													this.handleMultiSelectChange('selectedAccount', arr ? arr : []) }}
													options={this.state.accountFilter && this.state.accountFilter.map(account => ({
														value: account.account_id,
														label: account.account_name,
													}))}
												/>
											</div>
										</div>
										<div	
											className={`form-group my-2 flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} ref={this.moduleRef} style={{maxWidth: '200px' }}>
											<label className="mb-1">Modules</label>
											<div className={`filterMultiSelectDropdown ${this.state.modules && this.state.modules.length > 10 ? '' : 'removeDropdownSearchBar'}`}>
												<ReactMultiSelectCheckboxes						
													placeholderButtonLabel="All"
													menuIsOpen ={this.state.isModuleOpen}
													value={this.state.selectedModuleArray.map(org => ({
														value: org.value,
														label: org.label
													}))}
													//setState={this.state.selectedAccountArray}
													onChange={arr => {
													this.handleMultiSelectChange('selectedModule', arr ? arr : []) }}
													options={this.state.modules.map(org => ({
														value: org.module_id,
														label: org.module_name,
													}))}
												/>
											</div>
										</div>
										<div className='form-group my-2 flex-fill bd-highlight mb-sm-0 styled-feild' ref={this.durationRef}>
											<label className="w-100">Duration
												{this.state.durationType !== '' ? <small className="pl-3">{this.state.durationType}</small> : ''}
											</label>
											<select
												className='form-control'
												value={this.state.selectedDuration}
												onChange={event => {
													if(event.target.value !== 'custom') {
														this.setState(
															{
																durationType: '',
																selectedDuration: event.target.value,
																relatedIssueDurtaion: event.target.value,
																showDatepicker: false,
															},
															() => {
																this.filterIdentifiers('duration')															
															}
														)
													} else {
														this.setState({
															showDatepicker: true
														})
													}
												}}
											>
												{CUSTOM_YESTERDAY_DURATION_FILTER_OPTIONS.map((item, index) => {
													return (
														<option key={index} value={item.value}>
															{item.option}
														</option>
													)
												})}
											</select>
										</div>
										
										<div className={`ml-3 align-self-center text-center`}>
											<div className="btn-group" role="group" aria-label="ops-summary example">
												<button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-right`} onClick={()=> () => this.onSearch() }>Search</button>
												<button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-left`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
											</div>
										</div>
									</div>
										<div className={`RangeExample customDayPicker ${this.state.showDatepicker ? '' : 'displayNone'}`} ref={this.dayPickerRef}>
										<DayPicker
										toMonth={new Date()}
										disabledDays={[
											{
												after: new Date(),
												before: (new Date(this.state.pervious90Days))
											},
										]}
										className="Selectable"
										numberOfMonths= {3}																			
										selectedDays={[from, { from, to }]}
										modifiers={modifiers}
										onDayClick={this.handleDayClick}
										tabIndex={0}
										/>								
										<div className="col-sm-12">
											<button className="resetBtn f15 float-right mb-1" onClick={this.handleResetClick}>Reset</button>
										</div>
									</div>								
								</div>
							</div>
						</div>
						<div className='bashboard-bg'>
							<div className="box shadow-none">
								<div className="container-fluid mt-3 pb-3">
									<div className="row">
										<div className="col-12">
											<h5>Operational Summary</h5>
											<p className="text-muted">Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim.</p>
										</div>
									</div>
									<div className="row">
										<div className="col-sm-3">
											<div className="d-flex justify-content-between mb-2">
												
												<p className="mb-0 small">Account showing {this.state.accArray.length > this.state.accountShowLength ? '('+this.state.accountShowLength +' of '+ this.state.accArray.length +')' : '('+this.state.accArray.length +' of '+ this.state.accArray.length +')' }</p>
											</div>
											{this.state.accounts && this.state.accArray.length > 0 ?
												this.state.accArray.slice(this.state.accStartRow, this.state.accEndRow).map((acc,i) => {
													return (
														<div className="d-flex justify-content-between border-bottom mb-2">
															<div>
																<h7 className="font-weight-bold blackMuted">{acc.account_name}</h7>
																<p className="mb-2 f12 text-muted">{acc.account_id}</p>
															</div>
															<div className="align-self-center">
																<h7 className="mb-0 font-weight-bold text-muted">USD {1234*(i+1)}</h7>
															</div>
														</div>
													)
												})
											:
												null
											}

											<div className="d-flex justify-content-between py-1">
												<div className="w-90">
													<span className={`text-left far fa-chevron-left mr-3 text-muted cursorPointer ${this.state.accStartRow === 0 || this.state.accounts.length < this.state.accountShowLength ? 'displayNone': ''}`} 
														onClick={() => this.navigateAccountLIst('left') }>
													</span>
												</div>
												
												<span className={`${(this.state.accounts.length > this.state.accountShowLength) && (this.state.accounts.length > this.state.accEndRow) ? '': 'displayNone'} float-right text-right far fa-chevron-right cursorPointer`} 
													onClick={() => this.navigateAccountLIst('right') }>
												</span>
											</div>
										</div>
										<div className="col-sm-9">
											<div className="row operationalBoxSection">
												<div className="col-sm-2">&nbsp;</div>
												<div className="col-sm-4">
													<div className="card">
														<div className="card-header">Lambda</div>
														<div className="card-body p-1 f12">
															<div className={`d-flex justify-content-between border-bottom py-2`}>
																<div className="w-35 align-self-center">
																	<h7 className="font-weight-bold blackMuted">Invocations</h7>
																	{/* <p className="f12 text-muted m-0">{item.account_id}</p> */}
																</div>
																<div className="box bg-light d-flex p-2 shadow-none heihgtFitContent mr-2 align-self-center">
																	<div className="mr-2">														
																		<p className="font-weight-bold m-0 f12">Total&nbsp;:&nbsp;
																			<h7 className="font-weight-bold mb-0 text-dark">1245</h7>
																		</p>
																	</div>
																	<div className="mr-2">														
																		<p className="font-weight-bold m-0 f12">Success&nbsp;:&nbsp;
																			<h7 className="font-weight-bold mb-0 text-success">1241</h7>
																		</p>
																	</div>
																	<div className="mr-2">														
																		<p className="font-weight-bold m-0 f12">Failure &nbsp;:&nbsp;<h7 className="font-weight-bold mb-0 text-danger">4</h7></p>
																	</div>
																</div>
															</div>
															<div className={`d-flex justify-content-between border-bottom py-2`}>
																<div className="w-35 align-self-center">
																	<h7 className="font-weight-bold blackMuted">Total billed duration</h7>
																	{/* <p className="f12 text-muted m-0">{item.account_id}</p> */}
																</div>
																<div className="box bg-light d-flex p-2 shadow-none heihgtFitContent mr-2 align-self-center">
																	<div className="mr-2">														
																		<p className="font-weight-bold m-0 f12">
																			<h7 className="font-weight-bold mb-0">1628190 seconds</h7>
																		</p>
																	</div>
																</div>
															</div>
															<div className={`d-flex justify-content-between border-bottom py-2`}>
																<div className="w-35 align-self-center">
																	<h7 className="font-weight-bold blackMuted">Total max memory used</h7>
																	{/* <p className="f12 text-muted m-0">{item.account_id}</p> */}
																</div>
																<div className="box bg-light d-flex p-2 shadow-none heihgtFitContent mr-2 align-self-center">
																	<div className="mr-2">														
																		<p className="font-weight-bold m-0 f12">
																			<h7 className="font-weight-bold mb-0">1237 GB</h7>
																		</p>
																	</div>
																</div>
															</div>
															<div className={`d-flex justify-content-between pt-2`}>
																<div className="w-35 align-self-center">
																	<h7 className="font-weight-bold blackMuted">Total cost spent</h7>
																	{/* <p className="f12 text-muted m-0">{item.account_id}</p> */}
																</div>
																<div className="box bg-light d-flex p-2 shadow-none heihgtFitContent mr-2 align-self-center">
																	<div className="mr-2">														
																		<p className="font-weight-bold m-0 f12">Total&nbsp;:&nbsp;
																			<h7 className="font-weight-bold mb-0">1223.51 USD</h7>
																		</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-sm-4">
													<div className="card">
														<div className="card-header">API Gateway</div>
														<div className="card-body p-1 f12">
															<div className={`d-flex justify-content-between py-2`}>
																<div className="w-35 align-self-center">
																	<h7 className="font-weight-bold blackMuted">Total requests</h7>
																	{/* <p className="f12 text-muted m-0">{item.account_id}</p> */}
																</div>
																<div className="box bg-light d-flex p-2 shadow-none heihgtFitContent mr-2 align-self-center">
																	<div className="mr-2">														
																		<p className="font-weight-bold m-0 f12">
																			<h7 className="font-weight-bold mb-0">162888 requests</h7>
																		</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="row operationalBoxSection my-3">
												<div className="col-sm-2">&nbsp;</div>
												<div className="col-sm-4">
													<div className="card">
														<div className="card-header">Batch</div>
														<div className="card-body p-1 f12">
															<div className={`d-flex justify-content-between border-bottom py-2`}>
																<div className="w-35 align-self-center">
																	<h7 className="font-weight-bold blackMuted">Total Jobs</h7>
																	{/* <p className="f12 text-muted m-0">{item.account_id}</p> */}
																</div>
																<div className="box bg-light d-flex p-2 shadow-none heihgtFitContent mr-2 align-self-center">
																	<div className="mr-2">														
																		<p className="font-weight-bold m-0 f12">Total&nbsp;&nbsp;
																			<h7 className="font-weight-bold mb-0 text-dark">1245</h7>
																		</p>
																	</div>
																	<div className="mr-2">														
																		<p className="font-weight-bold m-0 f12">Success&nbsp;:&nbsp;
																			<h7 className="font-weight-bold mb-0 text-success">1241</h7>
																		</p>
																	</div>
																	<div className="mr-2">														
																		<p className="font-weight-bold m-0 f12">Failure&nbsp;:&nbsp;<h7 className="font-weight-bold mb-0 text-danger">4</h7></p>
																	</div>
																</div>
															</div>
															<div className={`d-flex justify-content-between border-bottom py-2`}>
																<div className="w-35 align-self-center">
																	<h7 className="font-weight-bold blackMuted">Total memory</h7>
																	{/* <p className="f12 text-muted m-0">{item.account_id}</p> */}
																</div>
																<div className="box bg-light d-flex p-2 shadow-none heihgtFitContent mr-2 align-self-center">
																	<div className="mr-2">														
																		<p className="font-weight-bold m-0 f12">
																			<h7 className="font-weight-bold mb-0">1221 GB</h7>
																		</p>
																	</div>
																</div>
															</div>
															<div className={`d-flex justify-content-between border-bottom py-2`}>
																<div className="w-35 align-self-center">
																	<h7 className="font-weight-bold blackMuted">Total vcpu </h7>
																	{/* <p className="f12 text-muted m-0">{item.account_id}</p> */}
																</div>
																<div className="box bg-light d-flex p-2 shadow-none heihgtFitContent mr-2 align-self-center">
																	<div className="mr-2">														
																		<p className="font-weight-bold m-0 f12">
																			<h7 className="font-weight-bold mb-0">1223 vCPU</h7>
																		</p>
																	</div>
																</div>
															</div>
															<div className={`d-flex justify-content-between pt-2`}>
																<div className="w-35 align-self-center">
																	<h7 className="font-weight-bold blackMuted">Most Failed Batch Job</h7>
																	{/* <p className="f12 text-muted m-0">{item.account_id}</p> */}
																</div>
																<div className="box bg-light d-flex p-2 shadow-none heihgtFitContent mr-2 align-self-center">
																	<div className="mr-2">														
																		<p className="font-weight-bold m-0 f12">Batch Job name&nbsp;:&nbsp;
																			<h7 className="font-weight-bold mb-0">1920</h7>
																		</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-sm-4">
													<div className="card">
														<div className="card-header">Elasticsearch</div>
														<div className="card-body p-1 f12">
															<div className={`d-flex justify-content-between py-2`}>
																<div className="w-35 align-self-center">
																	<h7 className="font-weight-bold blackMuted">Queries </h7>
																	{/* <p className="f12 text-muted m-0">{item.account_id}</p> */}
																</div>
																<div className="box bg-light d-flex p-2 shadow-none heihgtFitContent mr-2 align-self-center">
																	<div className="mr-2">														
																		<p className="font-weight-bold m-0 f12">Total&nbsp;&nbsp;
																			<h7 className="font-weight-bold mb-0 text-dark">5362</h7>
																		</p>
																	</div>
																	<div className="mr-2">														
																		<p className="font-weight-bold m-0 f12">Read&nbsp;:&nbsp;
																			<h7 className="font-weight-bold mb-0 text-success">1241</h7>
																		</p>
																	</div>
																	<div className="mr-2">														
																		<p className="font-weight-bold m-0 f12">Wrire&nbsp;:&nbsp;<h7 className="font-weight-bold mb-0 text-danger">4121</h7></p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
operationalSummary.propTypes = { 
	listOrganizations: PropTypes.func,
	listModules: PropTypes.func,
	listAllAccountsDetails: PropTypes.func
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return { }
}

export default AppWrapper(operationalSummary, mapStateToProps, {
	listOrganizations,
	listModules,
	listAllAccountsDetails
})



