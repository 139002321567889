/*************************************************
 * Tvastar
 * @exports
 * @file SlowQueryAction.js
 * @author parakash // 26/11/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'

import {
	SLOWQUERYENDPOINT,
	ATHENA_QUERY_GET_QUERY_SUMMARY,
	ATHENA_QUERY_GET_EACH_QUERY_DAILY_TREND,
} from '../../config'

/**
 * Action to get-query-summary
 * @param {Object} body
 * @param {Function} callback
 */
export const athenaGetQuerySummary = (body, callback) => {
	return async () => {
		await API.post(SLOWQUERYENDPOINT, ATHENA_QUERY_GET_QUERY_SUMMARY, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			callback(false)
		})
    }
}

/**
 * Action to get each-query-daily-trend
 * @param {Object} body
 * @param {Function} callback
 */
export const athenaGetEachQueryDailyTrend = (body, callback) => {
	return async () => {
		await API.post(SLOWQUERYENDPOINT, ATHENA_QUERY_GET_EACH_QUERY_DAILY_TREND, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			callback(false)
		})
    }
}