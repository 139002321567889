/*************************************************
 * Tvastar
 * @exports
 * @file SreMetricsTab.js
 * @author Prakash // on 27/11/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _, { filter } from 'lodash'
// import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import Chart from 'react-apexcharts'

import { momentDateGivenFormat, momentConvertionUtcToLocalTime, getConvertedBytesUnit, convertBytes, convertBytesTo, convertBytesWithoutUnit, convertSeconds, convertSecondsWithoutUnit, countFormater, countFormaterWithoutUnit, convertBits, convertBitsWithoutUnit, subHours, addHours, momentConvertionLocalToUtcTime, currentLocaltime, currentUTCtime, subDays } from '../../../../utils/utility'
import { setResourcePropsDetails } from '../../../../actions/assets/assetsAction'
import { getSreDetails } from '../../../../actions/aiops/DiagnosticsAction'

class SreMetricsTab extends Component {
	constructor(props) {
		super(props)
        this.toggleRef = React.createRef()
		this.state = {
            loadingSreMetrics: true,
            selectedDuration: "1",
		}
	}

	componentDidMount = () => {
		if(this.props.selectedAsset && Object.entries(this.props.selectedAsset).length) {
            this.setState({ 
                // start_time: this.props.caseAssetPageFilter.start_time,
                // end_time: this.props.caseAssetPageFilter.end_time,
                selectedAsset: this.props.selectedAsset ? this.props.selectedAsset : {},
                graphData: {}
            },
                () => {
                    this.getSreDetails()
                }
            )
        } else {
			this.setState({ loadingSreMetrics: false })
		}
    }

    getSreDetails = () => {
        let assetId = this.props.selectedAsset.asset_id
        let assetName = this.props.selectedAsset.asset_name

        // let params =  {
        //     "start_time":"2022-11-15 06:00:00","end_time":"2022-11-16 06:00:00","asset_id":"19ceec7949058f64a0e8ce0e24f7b3cb"
        // }
        let params = {}
        params.startTime = momentDateGivenFormat(subDays(currentUTCtime(), this.state.selectedDuration), 'YYYY-MM-DD HH:mm:ss')
        params.endTime = momentDateGivenFormat(currentUTCtime(), 'YYYY-MM-DD HH:mm:ss')
        params.asset_id = assetId

        if(assetId && assetId !== null && assetId !== '') {
			if(!this.props.scheduleSreMetricProps.hasOwnProperty(assetId)) {
				this.props.getSreDetails(params, (promise, response) => {
					if(promise) {
						let obj = this.props.scheduleSreMetricProps
						obj[assetName] = response
						this.props.setResourcePropsDetails('scheduleSreMetricProps', obj)
						this.setState({ sreDetails: response, loadingSreMetrics: true }, 
							() => {
								this.formSreLatencyGraph()
								this.formSreRateGraph() 
							}
						)
					} else {
                        this.setState({ loadingSreMetrics: false })
                    }
				})
			} else {
                this.setState({ 
                    sreDetails: this.props.scheduleSreMetricProps[assetId],
                    loadingSreMetrics: true,
                },
                    () => {
						this.formSreLatencyGraph()
						this.formSreRateGraph() 
                    }
                )

			}
            
        }
    }
    
    metricsListGraph = () => {
        let graphDetails = this.state.metricsData ? this.state.metricsData : []
        if(graphDetails && graphDetails.length) {
            graphDetails.forEach(graph => {
                let graphData = graph.data
                let graphLabels = graph.dates
                Object.entries(graphData).forEach(([key, item], i) => {
                    let colors = []
                    let series = []
                    let options = {}

                    let  strokeWidth = []
                    let maxValue = item.statistics && item.statistics.max ? item.statistics.max : 1
                    let avgValue = item.statistics && item.statistics.avg ? item.statistics.avg : 1
                    let minValue = item.statistics && item.statistics.min ? item.statistics.min : 1
                    
                    
                    let graphConvertTo = ""

                    let maxRandomNumber = maxValue
                    let minRandomNumber = minValue
                    if(item.unit) {
                        graphConvertTo = this.getConvertedUnit(maxValue, item.unit)
                    }

                    if(item.values && item.values.length) {
                        if(item.values && item.values.length) { //statistic
                            if(item.statistic !== "Maximum") {
                                colors.push('#775BA2')
                            } else {
                                colors.push('#3DAFE8')
                            }
                            strokeWidth.push(2)
                            let dataRow = {}
                            dataRow.name = item.statistic === "Maximum" ? "max" : item.statistic === "Average" ? "avg" : item.statistic === "Sum" ? "sum" : item.statistic
                            dataRow.type = item.statistic !== "Maximum" ? "area" : "line"
                            let plotValue = []
                            graphLabels.map((lab, i) => {
                                let dataArray = []
                                dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                                let val = item.values[i]
                                if(item.unit) {
                                    val = this.getGrapUnitValue(val, item.unit, "", graphConvertTo)
                                }
                                dataArray.push(val)
                                plotValue.push(dataArray)
                            })
                            dataRow.data = plotValue
                            series.push(dataRow)
                        }

                        if(item.additional_statistics && item.additional_statistics.length) {
                            if(item.values && item.values.length) {
                                if(item.additional_statistics[0] !== "Maximum") {
                                    colors.push('#775BA2')
                                } else {
                                    colors.push('#3DAFE8')
                                }
                                strokeWidth.push(2)
                                let dataRow = {}
                                dataRow.name = item.additional_statistics[0] === "Maximum" ? "max" : item.additional_statistics[0] === "Average" ? "avg" : item.additional_statistics[0] === "Sum" ? "sum" : item.additional_statistics[0]
                                dataRow.type = item.additional_statistics[0] !== "Maximum" ? "area" : "line"
                                let plotValue = []
                                graphLabels.map((lab, i) => {
                                    let dataArray = []
                                    dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                                    let val = item.values[i]
                                    if(item.unit) {
                                        val = this.getGrapUnitValue(val, item.unit)
                                    }
                                    dataArray.push(val)
                                    plotValue.push(dataArray)
                                })
                                dataRow.data = plotValue
                                series.push(dataRow)
                            }
                        }

                        // console.log(key, series)
                        
                        let xaxisAnnotation = []
                        // if(this.state.showAnnotation) {
                        //     let alerts = [this.props.details.event_time]

                        //     if(alerts.length) {
                        //         alerts.forEach((item, z) => {
                        //             let alertRow = {}
                        //             // alertRow.x = new Date(momentConvertionUtcToLocalTime(addMinutes(item, (z*10)), 'YYYY-MM-DD HH:mm:ss')).getTime()
                        //             alertRow.x = new Date(momentConvertionUtcToLocalTime(item, 'YYYY-MM-DD HH:mm:ss')).getTime()
                        //             alertRow.strokeDashArray = 0
                        //             alertRow.borderColor = '#FF6F00'
                        //             alertRow.label = {
                        //                 borderColor: '#FF6F00',
                        //                 style: {
                        //                     // fontSize: '12px',
                        //                     color: '#fff',
                        //                     background: '#FF6F00'
                        //                 },
                        //                 orientation: 'horizontal',
                        //                 text: ''
                        //                 // text: momentConvertionUtcToLocalTime(item, 'DD MMM YY HH:mm'),
                        //             }

                        //             xaxisAnnotation.push(alertRow)
                        //         })
                        //     }
                        // }

                        let annotations = {
                            xaxis: xaxisAnnotation
                        }

                        options = {
                            chart: {
                                // group: 'metrics_sre',
                                // id: 'chart_rate_'+index,
                                // redrawOnParentResize: true,
                                stacked: true,
                                toolbar: {
                                    show: false,
                                },
                                zoom: {
                                    enabled: false
                                },
                                sparkline: {
                                    enabled: false
                                },
                                type: "line",
                                width: '100%',
                                animations: {
                                    enabled: false
                                }
                            },
                            colors: colors,
                            dataLabels: {
                                enabled: false,
                            },
                            stroke: {
                                width: strokeWidth,
                                curve: 'smooth',
                            },
                            legend: {
                                show: false
                            },
                            fill: {
                              type:'solid',
                              opacity: [0.25, 1],
                            },
                            // fill: {
                            //     type: "gradient",
                            //     gradient: {
                            //         // shadeIntensity: 1,
                            //         // opacityFrom: 0.7,
                            //         // opacityTo: 0.9,
                            //         // stops: [0, 90, 100]
                            //         gradientToColors: ['#775BA2'],
                            //         shadeIntensity: 0,
                            //         opacityFrom: .3,
                            //         opacityTo: 1,
                            //         stops: [30, 90]
                            //     }
                            // },
                            tooltip: {
                                // custom: function({series, seriesIndex, dataPointIndex, w}) {
                                //     let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
                                //     dateLable = dateLable.toString()
                                //     dateLable = momentDateGivenFormat(dateLable, 'DD MMM HH:mm')
                                //     let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]
                                //     return '<div class="arrow_box">'+dateLable+' <span style="color:'+ w.globals.colors +'">' + parseFloat(val).toFixed(2) + '</span> </div>'
                                // },
                                custom: function({series, seriesIndex, dataPointIndex, w}) {         
                                    let returnData = ''
                                    
                                    if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                        Object.entries(w.globals.initialSeries).map(([key, value], index) => {
                                            if(!index) {
                                                returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                            }
                                            returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'
    
                                        })
                                    }
    
                                    returnData += '</div>'
                                    return returnData
                                },
                                fixed: {
                                    enabled: false
                                },
                                x: {
                                    show: false,
                                },
                                y: {
                                    show: false
                                },        
                                marker: {
                                    show: false
                                }
                            },
                            xaxis: {
                                type: 'datetime',
                                labels: {
                                    datetimeUTC: false,
                                    style: {
                                        colors: '#60687C'
                                    },
                                    // formatter: function (value) {
                                    //     return momentDateGivenFormat(value, 'HH:mm')
                                    // },
                                },                            
                                axisTicks: {
                                    show: false
                                },
                                tooltip: {
                                    enabled: false
                                },
                            },
                            dataLabels: {
                                enabled: false
                            },
                            yaxis: [
                                {
                                    show: true,
                                    axisTicks: {
                                        show: false,
                                    },
                                    axisBorder: {
                                        show: false,
                                        color: '#60687C'
                                    },
                                    tooltip: {
                                        enabled: false
                                    },
                                    // tickAmount: 2,
                                    min: 0,
                                    // max: yaxisMaxValue, 
                                    tickAmount: 1,
                                    labels: {
                                        offsetX: -10,
                                        // minWidth: 50,
                                        show: true,
                                        style: {
                                            fontSize: '9px',
                                            colors: '#FFFFFF'
                                        },
                                        formatter: (value) => { 
                                            if(Number(value) === value && value % 1 === 0) {
                                                return Math.ceil(value.toFixed(1))
                                            } else {
                                                return Math.ceil(value.toFixed(1))
                                            }
                                            
                                            // if(!value) {
                                            //     return 0
                                            // } else {
                                            //     let val = value.toFixed(2)
                                            //     val = val.split(".")
                                            //     if(val[1] && val[1] > 0) {
                                            //         return value.toFixed(2)
                                            //     } else {
                                            //         return parseInt(val[0])
                                            //     }
                                            // }
                                        },
                                        // offsetX: 0,
                                        // offsetY: 0,
                                    },
                                },
                                {
                                    show: false,
                                }
                            ],
                            grid: {
                                show: false,
                                borderColor: '#2D323E',
                                strokeDashArray: 0,
                                position: 'back',
                                xaxis: {
                                    lines: {
                                        show: true
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#FFF'],
                                        }
                                    },
                                    axisTicks: {
                                        show: false
                                    }
                                },   
                                yaxis: {
                                    lines: {
                                        show: false
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#969AA7'],
                                        }
                                    }
                                },                
                                padding: {
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0
                                },
                            },
                            annotations: annotations,
                        }
                    }
                    this.setState({ [key+"_series_"+graph.asset_id]: series, [key+"_options_"+graph.asset_id]: options, loadingSreMetrics: false },
                        () => {
                            // console.log([key+"_series_"+graph.asset_id], series)
                            // console.log([key+"_options_"+graph.asset_id], options)
                        }
                    )
                })
                
            })
        }
    }

    formSreLatencyGraph = () => {
        let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']
        if(this.state.sreDetails && this.state.sreDetails.length) {
            let filteredSreDetails = this.state.sreDetails
            filteredSreDetails.forEach((res, index) => {
                let graphData = res.data
                let graphLabels = res.dates
                let yDetails = []
                let series =[]
                let colorInc = 0
                graphData.latency && Object.entries(graphData.latency).forEach(([key, item]) => {
                    let yRow = {}
                    if(item.values && item.values.length) {
                        if(!colorInc) {
                            yRow = {
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                    color: '#60687C'
                                },
                                tooltip: {
                                    enabled: false
                                },
                                tickAmount: 2,
                                min: 0,
                                labels: {
                                    offsetX: -10,
                                    show: true,
                                    style: {
                                        fontSize: '9px',
                                        colors: '#60687C'
                                    },
                                    // formatter: (value) => { 
                                    //     return value.toFixed(2)
                                    // },
                                    formatter: (value) => { 
                                        if(Number(value) === value && value % 1 === 0) {
                                            return Math.ceil(value.toFixed(1))
                                        } else {
                                            return Math.ceil(value.toFixed(1))
                                        }
                                    },
                                }
                            }
                        } else {
                            yRow = {
                                show: false
                            }
                        }
                        yDetails.push(yRow)
                        let dataRow = {}
                        dataRow.name = key
                        dataRow.type = 'line'
                        let plotValue = []
                        graphLabels.forEach((lab, i) => {
                            let dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            let val = item.values[i]
                            if(item.unit && item.unit !== '') {
                                val = this.getGrapUnitValue(val, item.unit)
                            }
                            dataArray.push(val)
                            plotValue.push(dataArray)
                        })
                        dataRow.data = plotValue
                        series.push(dataRow)

                        colorInc++;
                    }

                })

                let xaxisAnnotation = []
                if(this.state.showAnnotation) {
                    let caseTimeAnnotation = {
                        x: new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                        strokeDashArray: 0,
                        borderColor: '#FF6F00',
                        label: {
                            borderColor: '#FF6F00',
                            style: {
                                // fontSize: '12px',
                                color: '#fff',
                                background: '#FF6F00'
                            },
                            orientation: 'horizontal',
                            text: '',
                        }
                    }

                    xaxisAnnotation.push(caseTimeAnnotation)

                    let alerts = []
                    if(this.props.selectedAsset && this.props.selectedAsset.alerts && this.props.selectedAsset.alerts.length) {
                        this.props.selectedAsset.alerts.forEach(et => {
                            alerts.push(et.event_time)
                        })
                    } else {
                        alerts.push(this.props.selectedAsset.event_time)
                    }

                    if(alerts.length) {
                        alerts.forEach((item, z) => {
                            let alertRow = {}
                            // alertRow.x = new Date(momentConvertionUtcToLocalTime(addMinutes(item, (z*10)), 'YYYY-MM-DD HH:mm:ss')).getTime()
                            alertRow.x = new Date(momentConvertionUtcToLocalTime(item, 'YYYY-MM-DD HH:mm:ss')).getTime()
                            alertRow.strokeDashArray = 0
                            alertRow.borderColor = '#039BE5'
                            alertRow.label = {
                                borderColor: '#039BE5',
                                style: {
                                    // fontSize: '12px',
                                    color: '#fff',
                                    background: '#039BE5'
                                },
                                orientation: 'horizontal',
                                text: '',
                            }

                            xaxisAnnotation.push(alertRow)
                        })
                    }
                }

                let annotations = {
                    xaxis: xaxisAnnotation
                }

                let options = {
                    chart: {
                        // group: 'metrics_sre',
                        type: 'line',
                        // id: 'chart_latency_'+index,
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false
                        },                        
                        sparkline: {
                            enabled: false
                        },
                    },
                    colors: colors,
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        width: [2, 3, 4],
                        curve: 'smooth',
                        // dashArray: [0, 8, 5]
                    },
                    xaxis: {
                        // categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
                        type: 'datetime',
                        // categories: labels,
                        labels: {
                            datetimeUTC: false,
                            show: true,
                            style: {
                                fontSize: '9px',
                                colors: '#60687C'
                            },
                        }
                    },
                    
                    yaxis: yDetails,
                    // yaxis: {
                    //     show: false,
                    //     tickAmount: 2,
                    //     min: 0,
                    //     // max: 1,
                    //     labels: {
                    //         show: true,
                    //         style: {
                    //             fontSize: '9px',
                    //             colors: '#60687C'
                    //         },
                    //         formatter: (value) => { 
                    //             if(Number(value) === value && value % 1 === 0) {
                    //                 return Math.ceil(value)
                    //             } else {
                    //                 return value.toFixed(1)
                    //             }
                    //         },
                    //     }
                    // },
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            show: false,
                        },
                        x: {
                            show: false
                        },
                        marker: {
                            show: false
                        },
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                                // let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]
                                // let labelName = w.globals.initialSeries[seriesIndex].name
                                // // return '<div className="arrow_box"> '+ capitalizeFirstLetter(labelName) +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' + '  '+ val + '</span> </div>'         
                                let returnData = ''
                                
                                if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                    Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                        if(!index) {
                                            returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                        }
                                        returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'

                                    })
                                }

                                returnData += '</div>'
                                return returnData
                        }
                    },
                    grid: {
                        show: true,
                        borderColor: '#2D323E',
                        strokeDashArray: 0,
                        position: 'back',
                        xaxis: {
                            lines: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: ['#2D323E'],
                                }
                            },  
                        },   
                        yaxis: {
                            lines: {
                                show: false
                            },
                            labels: {
                                style: {
                                    colors: ['#2D323E'],
                                }
                            }
                        },
                        padding: {
                            // top: 0,
                            right: 0,
                            // bottom: 0,
                            left: 0
                        }, 
                    },
                    legend: {
                        show: false
                        // horizontalAlign: 'left',
                        // offsetX: 40
                    },
                    annotations: annotations
                }

                this.setState({ [this.props.selectedAsset.asset_name+'_series_'+index]: series, [this.props.selectedAsset.asset_name+'_options_'+index]: options, loadingSreMetrics: false })
            })
        } else {
            this.setState({ showLoading: false })
        }
    }

    formSreRateGraph = () => {
        let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']
        if(this.state.sreDetails && this.state.sreDetails.length) {
            let filteredSreDetails = this.state.sreDetails
            filteredSreDetails.forEach((res, index) => {
                let graphData = res.data
                let graphLabels = res.dates

                let series = []
                
                let  strokeWidth = []
                let x = 0
                let yDetails = []
                let yRow = {}
                graphData.error_rate && Object.entries(graphData.error_rate).forEach(([key, item], j) => {
                    strokeWidth.push(x+2)
                    if(item.values && item.values.length) {
                        if(!x) {
                            yRow = {
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                    color: '#60687C'
                                },
                                tooltip: {
                                    enabled: false
                                },
                                tickAmount: 2,
                                min: 0,
                                labels: {
                                    offsetX: -10,
                                    show: true,
                                    style: {
                                        fontSize: '9px',
                                        colors: '#60687C'
                                    },
                                },
                                formatter: (value) => { 
                                    // console.log(value)
                                    if(Number(value) === value && value % 1 === 0) {
                                        return Math.ceil(value.toFixed(1))
                                    } else {
                                        return Math.ceil(value.toFixed(1))
                                    }
                                },
                            }
                            
                        } else {
                            yRow = {
                                show: false
                            }
                        }
                        // yDetails.push(yRow)

                        let dataRow = {}
                        dataRow.name = key
                        dataRow.type = 'line'
                        
                        let plotValue = []
                        graphLabels.forEach((lab, i) => {
                            let dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))   
                            dataArray.push(item.values[i])                         
                            // let val = item.values[i]
                            // if(item.unit && item.unit !== '') {
                            //     val = this.getGrapUnitValue(val, item.unit)
                            // }
                            plotValue.push(dataArray)
                        })
                        dataRow.data = plotValue
                        series.push(dataRow)
                    }
                    x++;
                })

                let xaxisAnnotation = []
                if(this.state.showAnnotation) {
                    let caseTimeAnnotation = {
                        x: new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                        strokeDashArray: 0,
                        borderColor: '#FF6F00',
                        label: {
                            borderColor: '#FF6F00',
                            style: {
                                // fontSize: '12px',
                                color: '#fff',
                                background: '#FF6F00'
                            },
                            orientation: 'horizontal',
                            text: '',
                        }
                    }

                    xaxisAnnotation.push(caseTimeAnnotation)

                    let alerts = []
                    if(this.props.selectedAsset && this.props.selectedAsset.alerts && this.props.selectedAsset.alerts.length) {
                        this.props.selectedAsset.alerts.forEach(et => {
                            alerts.push(et.event_time)
                        })
                    } else {
                        alerts.push(this.props.selectedAsset.event_time)
                    }

                    if(alerts.length) {
                        alerts.forEach((item, z) => {
                            let alertRow = {}
                            // alertRow.x = new Date(momentConvertionUtcToLocalTime(addMinutes(item, (z*10)), 'YYYY-MM-DD HH:mm:ss')).getTime()
                            alertRow.x = new Date(momentConvertionUtcToLocalTime(item, 'YYYY-MM-DD HH:mm:ss')).getTime()
                            alertRow.strokeDashArray = 0
                            alertRow.borderColor = '#039BE5'
                            alertRow.label = {
                                borderColor: '#039BE5',
                                style: {
                                    // fontSize: '12px',
                                    color: '#fff',
                                    background: '#039BE5'
                                },
                                orientation: 'horizontal',
                                text: '',
                            }

                            xaxisAnnotation.push(alertRow)
                        })
                    }
                }

                let annotations = {
                    xaxis: xaxisAnnotation
                }

                let options = {
                    chart: {
                        // group: 'metrics_sre',
                        // id: 'chart_rate_'+index,
                        redrawOnParentResize: true,
                        stacked: true,
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false
                        },
                        sparkline: {
                            enabled: false
                        },
                        type: "line",
                        animations: {
                            enabled: false
                        }
                    },
                    colors: colors,
                    dataLabels: {
                        enabled: false,
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            barHeight: '100%',
                            // borderRadius: 8,
                            startingShape: 'flat',
                            endingShape: 'flat',
                            columnWidth: '60%',
                            distributed: false,
                            rangeBarOverlap: false,
                        }
                    },
                    stroke: {
                        width: strokeWidth,
                        curve: 'smooth',
                    },
                    xaxis: {
                        show: true,
                        type: 'datetime',
                        // categories: labels,
                        labels: {
                            datetimeUTC: false,
                            show: true,
                            style: {
                                fontSize: '9px',
                                colors: '#60687C'
                            },
                        },
                    },
                    yaxis: yDetails,
                    grid: {
                        show: false,
                        borderColor: '#2D323E',
                        strokeDashArray: 0,
                        position: 'back',
                        xaxis: {
                            lines: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: ['#969AA7'],
                                }
                            },  
                        },   
                        yaxis: {
                            lines: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: ['#969AA7'],
                                }
                            },
                        },
                        padding: {
                            // top: 0,
                            right: 0,
                            // bottom: 0,
                            left: 0
                        },
                    },
                    legend: {
                        show: false
                    },
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            show: false,
                        },                        
                        x: {
                            show: false
                        },
                        marker: {
                            show: false
                        },
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                                // let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]
                                // let labelName = w.globals.initialSeries[seriesIndex].name
                                // // return '<div className="arrow_box"> '+ capitalizeFirstLetter(labelName) +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' + '  '+ val + '</span> </div>'         
                                let returnData = ''
                                
                                if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                    Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                        if(!index) {
                                            returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                        }
                                        returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'

                                    })
                                }

                                returnData += '</div>'
                                return returnData
                        }
                    },
                    annotations: annotations
                }

                this.setState({ [this.props.selectedAsset.asset_name+'_error_rate_series_'+index]: series, [this.props.selectedAsset.asset_name+'_error_rate_options_'+index]: options, loadingSreMetrics: false })                

                series = []
                graphData.request_rate && Object.entries(graphData.request_rate).forEach(([key, item]) => {
                    if(item.values && item.values.length) {
                        yRow = {
                            axisTicks: {
                                show: false,
                            },
                            axisBorder: {
                                show: false,
                                color: '#60687C'
                            },
                            tooltip: {
                                enabled: false
                            },
                            tickAmount: 2,
                            min: 0,
                            labels: {
                                offsetX: -10,
                                show: true,
                                style: {
                                    fontSize: '9px',
                                    colors: '#60687C'
                                },
                            }
                        }
                        yDetails.push(yRow)
                        let dataRow = {}
                        dataRow.name = key
                        dataRow.type = 'line'
                        let plotValue = []
    
                        graphLabels.forEach((lab, i) => {
                            let dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))   
                            dataArray.push(item.values[i])                         
                            plotValue.push(dataArray)
                        })
                        dataRow.data = plotValue
                        series.push(dataRow)
                    }
                })

                colors = ['#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']

                options = {
                    chart: {
                        // group: 'metrics_sre',
                        // id: 'chart_rate_'+index,
                        redrawOnParentResize: true,
                        stacked: true,
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false
                        },
                        sparkline: {
                            enabled: false
                        },
                        type: "line",
                        animations: {
                            enabled: false
                        }
                    },
                    colors: colors,
                    dataLabels: {
                        enabled: false,
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            barHeight: '100%',
                            // borderRadius: 8,
                            startingShape: 'flat',
                            endingShape: 'flat',
                            columnWidth: '60%',
                            distributed: false,
                            rangeBarOverlap: false,
                        }
                    },
                    stroke: {
                        width: strokeWidth,
                        curve: 'smooth',
                    },
                    xaxis: {
                        show: true,
                        type: 'datetime',
                        // categories: labels,
                        labels: {
                            datetimeUTC: false,
                            show: true,
                            style: {
                                fontSize: '9px',
                                colors: '#60687C'
                            },
                        },
                    },
                    yaxis: yDetails,
                    grid: {
                        show: false,
                        borderColor: '#2D323E',
                        strokeDashArray: 0,
                        position: 'back',
                        xaxis: {
                            lines: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: ['#969AA7'],
                                }
                            },  
                        },   
                        yaxis: {
                            lines: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: ['#969AA7'],
                                }
                            },
                        },
                        padding: {
                            // top: 0,
                            right: 0,
                            // bottom: 0,
                            left: 0
                        },
                    },
                    legend: {
                        show: false
                    },
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            show: false,
                        },                        
                        x: {
                            show: false
                        },
                        marker: {
                            show: false
                        },
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                                // let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]
                                // let labelName = w.globals.initialSeries[seriesIndex].name
                                // // return '<div className="arrow_box"> '+ capitalizeFirstLetter(labelName) +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' + '  '+ val + '</span> </div>'         
                                let returnData = ''
                                
                                if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                    Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                        if(!index) {
                                            returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                        }
                                        returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'

                                    })
                                }

                                returnData += '</div>'
                                return returnData
                        }
                    },
                    annotations: annotations
                }

                this.setState({ [this.props.selectedAsset.asset_name+'_request_rate_series_'+index]: series, [this.props.selectedAsset.asset_name+'_request_rate_options_'+index]: options, loadingSreMetrics: false })
            })

        } else {
            this.setState({ loadingSreMetrics: false })
        }
    }

    getConvertedUnit = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = getConvertedBytesUnit(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                // val = getConvertedSecondsUnit(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                // val = getConvertedBitsUnit(val, unit)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                // val = getConvertedCountFormater(val)
            } else {
                val = ""
            }
        } else if(val !== '') {           
            val = ""
        }
        return val
    }

    getGrapUnitValue = (val ,unit, valueType, convertTo) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseInt(val))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                if(convertTo) {
                    val = convertBytesTo(val, unit, valueType, convertTo)
                } else {
                    val = convertBytes(val, unit, valueType)
                }                         
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit, valueType, convertTo)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit, valueType, convertTo)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    getGrapUnitValueWithoutUnit = (val ,unit, valueType) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytesWithoutUnit(val, unit, valueType)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSecondsWithoutUnit(val, unit, valueType)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBitsWithoutUnit(val, unit, valueType)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                val = countFormaterWithoutUnit(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseInt(val))
        }
        return val
    }

    handleClickOutside(event) {}
	
	render() {		
		return (
            <React.Fragment>
                <div className={`d-flex justify-content-end`}>
                    <span className={`mr-2 f12 align-self-center`}>Last</span>
                    <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '30' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '30' }, () => this.getSreDetails())}>1 Month</span>
                    <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '14' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '14' }, () => this.getSreDetails())}>2 Week</span>
                    <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '7' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '7' }, () => this.getSreDetails())}>1 Week</span>
                    <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '1' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '1' }, () => this.getSreDetails())}>24 Hours</span>
                </div>
                {this.state.metricsListGraph ?
                    <div className='d-flex justify-content-center m-4'>
                        <Spinner className='text-center' color='white' size='lg' />
                    </div>
                :
                    !this.state.loadingSreMetrics && this.state.sreDetails && this.state.sreDetails.length ?
                        this.state.sreDetails.map((res, index) => {
                            return(
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="mb-3 col-12 px-0">
                                        <div class="p-3 border rounded bg-dark3 mx-2 mt-2">
                                            <p class="f16 text-white m-0 p-1">Latency</p>
                                            {this.state[this.props.selectedAsset.asset_name+'_series_'+index] && this.state[this.props.selectedAsset.asset_name+'_series_'+index].length ?
                                                <div className={`cursorPointer transparentTooltip mt-n4 mb-n4`}>
                                                    <Chart options={this.state[this.props.selectedAsset.asset_name+'_options_'+index]} series={this.state[this.props.selectedAsset.asset_name+'_series_'+index]} type="line" height={115} />
                                                </div>
                                            : null}    
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="row">
                                    <div class="mb-3 col-12 px-0">
                                        <div class="p-3 border rounded bg-dark3 mx-2 mt-2">
                                            <p class="f16 text-white m-0 p-1">Request Rate</p>
                                            {this.state[this.props.selectedAsset.asset_name+'_request_rate_series_'+index] && this.state[this.props.selectedAsset.asset_name+'_request_rate_series_'+index].length ?
                                                <div className={`cursorPointer transparentTooltip mt-n4 mb-n4`}>
                                                    <Chart options={this.state[this.props.selectedAsset.asset_name+'_request_rate_options_'+index]} series={this.state[this.props.selectedAsset.asset_name+'_request_rate_series_'+index]} type="line" height={115} />
                                                </div>
                                            :
                                                <div style={{minHeight:"85px"}} className="d-flex align-self-center justify-content-center">
                                                    <div className='align-self-center'>
                                                        No Data
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="mb-3 col-12 px-0">
                                        <div class="p-3 border rounded bg-dark3 mx-2 mt-2">
                                            <p class="f16 text-white m-0 p-1">Error Rate</p>
                                            {this.state[this.props.selectedAsset.asset_name+'_error_rate_series_'+index] && this.state[this.props.selectedAsset.asset_name+'_error_rate_series_'+index].length ?
                                                <div className={`cursorPointer transparentTooltip mt-n4 mb-n4`}>
                                                    <Chart options={this.state[this.props.selectedAsset.asset_name+'_error_rate_options_'+index]} series={this.state[this.props.selectedAsset.asset_name+'_error_rate_series_'+index]} type="line" height={115} />
                                                </div>
                                            :
                                                <div style={{minHeight:"85px"}} className="d-flex align-self-center justify-content-center">
                                                    <div className='align-self-center'>
                                                        No Data
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )
                        })
                    : this.state.loadingSreMetrics ?
                        <div className='d-flex justify-content-center m-4'>
                            <Spinner className='text-center' color='white' size='lg' />
                        </div>
                    : this.state.sreDetails && !this.state.sreDetails.length ?
                        <div className='d-flex justify-content-center m-4'>
                            No SRE Metrics for the this asset.
                        </div>
                    : null
                }
            </React.Fragment>
		)
	}
}

/**
 * Type of the props used in the component
 */
SreMetricsTab.propTypes = {}

const mapStateToProps = state => {
	// console.log('observability query page',state)
	return {
		scheduleSreMetricProps: state.assets.resourcesPropsDetails && state.assets.resourcesPropsDetails.scheduleSreMetricProps ? state.assets.resourcesPropsDetails.scheduleSreMetricProps : [],
	}
}

export default connect(mapStateToProps, {
	setResourcePropsDetails,
	getSreDetails
})(withRouter(SreMetricsTab))