/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Metrics.js
 * @author Prakash // on 03/03/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import _ from 'lodash'
import { ContextMenu, MenuItem } from "react-contextmenu"; //ContextMenuTrigger

import { momentConvertionUtcToLocalTime } from '../../../utils/utility'
import ResizeChecboxTable from '../../designComponents/Table/ResizeChecboxTable'
import { listAlerts } from '../../../actions/aiops/AiopsAction'
import { listEvents } from '../../../actions/aiops/EventAction'
import { getAssetDetails } from '../../../actions/detectors/detectorsAction'


class TopologyRightSection extends Component {
    
    constructor(props) {
        super(props)
        this.scrollRef = React.createRef()
        this.state = {
            caseId: this.props.caseId,
            nodeAlerts: [],

            placeHolder: '',
            
            searchAlertText: '',
            filterAlertValue: [],
            filteredAlertArray: [],
            showAlertsTableLoading: false,            

            searchEventText: '',
            filterEventValue: [],
            filteredEventArray: [],
            showEventsTableLoading: false,

            searchAssetText: '',
            filterAssetValue: [],
            filteredAssetArray: [],
            showAssetsTableLoading: false,
            rowsPerPage: 4
        }
    }

    componentDidMount = () => {
        // this.onClickNode('')
        this.listAlerts()
        this.listAssets()

        let evnetParams = {}
        evnetParams.case_id = this.state.caseId
        
        this.setState({ showEventsTableLoading: true })
        this.props.listEvents(evnetParams, (promise, result) => {
            if(promise) {

                let eventsTableColumns = []
                let dataRow = {}
        
                dataRow.Header = ' '
                dataRow.accessor = 'severity'
                dataRow.Cell = cellInfo => (
                    <span className={`badge ${
                        cellInfo.row.original.severity && cellInfo.row.original.severity !== '' ?
                            'risk-badge-'+cellInfo.row.original.severity.toLowerCase()
                        : 'badge-secondary'
                        }`}
                    >
                        {cellInfo.row.original.severity && cellInfo.row.original.severity !== '' ? (cellInfo.row.original.severity.toLowerCase() === 'low' ? 'L' : cellInfo.row.original.severity.toLowerCase() === 'medium' ? 'M' :  cellInfo.row.original.severity.toLowerCase() === 'high' ? 'H' : cellInfo.row.original.severity.toLowerCase() === 'critical' ? 'C' : '-') : '-'}
                    </span>
                )
                dataRow.width = 40
                eventsTableColumns.push(dataRow)
        
                dataRow = {}
                dataRow.Header = 'Date'
                dataRow.accessor = 'event_time'
                dataRow.width = 110
                dataRow.Cell = cellInfo => (
                    <React.Fragment>
                        <span>{cellInfo.row.original.event_time ? momentConvertionUtcToLocalTime(cellInfo.row.original.event_time, 'DD MMM YYYY HH:mm') : ''}</span>
                    </React.Fragment>
                )
                eventsTableColumns.push(dataRow)
                
                dataRow = {}
                dataRow.Header = 'Resource'
                dataRow.accessor = d => (d.provider ? d.provider.toUpperCase() + ' : ' : '') + (d.account_id ? d.account_id + ' : ' : '') + (d.region ? d.region + ' : ' : '')
                dataRow.width = 180
                eventsTableColumns.push(dataRow)
        
                dataRow = {}
                dataRow.Header = 'Asset'
                dataRow.accessor =  d =>Array.isArray(d.assets) ? d.assets.map((item, index) => (d.assets.length - 1 === index ? (item.asset_name + ' '+item.asset_id) : item.asset_name + ', ')) : (d.asset_name ? d.asset_name : '')
                dataRow.width = 100
                eventsTableColumns.push(dataRow)
                
                dataRow = {}
                dataRow.Header = 'Event'
                dataRow.accessor = 'event_name'
                dataRow.width = 100
                eventsTableColumns.push(dataRow)
        
                dataRow = {}
                dataRow.Header = 'Description'
                dataRow.accessor = 'description'	
                dataRow.width = 200				
                eventsTableColumns.push(dataRow)
        
                dataRow = {}
                dataRow.Header = 'Source'
                dataRow.accessor = 'event_source'		
                dataRow.width = 100			
                eventsTableColumns.push(dataRow)

                let eventSource = []
                if(result.results) {   
                    eventSource = _.chain(result.results).groupBy('event_source').map((value, key) => ({ label: key, sub: value })).value()
                }

                this.setState({ eventsList: result.results ? result.results : [], filteredEventArray: result.results ? result.results : [], eventsTableColumns, eventsDynamicColumns: eventsTableColumns, eventSource, showEventsTableLoading: false })
                
            } else {
                this.setState({ eventsList: {} })
            }
        })

        if(this.props.aiopsPropsDetails && this.props.aiopsPropsDetails.topology_selected_node_edges) {
            this.filterTableResult()
        }

    }

    componentDidUpdate = (prevProps) => {
        if((!prevProps.aiopsPropsDetails.topology_selected_node_edges && this.props.aiopsPropsDetails.topology_selected_node_edges) || (this.props.aiopsPropsDetails.topology_selected_node_edges !== prevProps.aiopsPropsDetails.topology_selected_node_edges)) {
            // this.onClickNode(this.props.nodeDetailsId) 
            // this.getAssetDetails()
            this.filterTableResult()
        }

        if(this.props.alertsList !== prevProps.alertsList) {
            this.listAlerts()
        }

        if(this.props.assetsList !== prevProps.assetsList) {
            this.listAssets()
        }
    }

    
    filterTableResult = () => {

        let selectedNode = this.props.aiopsPropsDetails.topology_selected_node_edges && this.props.aiopsPropsDetails.topology_selected_node_edges.selected_node
        
        let alertsList = this.state.alertsList && this.state.alertsList ? this.state.alertsList.filter(obj => selectedNode.includes(obj.asset_name)) : []
        let assetsList = this.state.assetsList && this.state.assetsList ? this.state.assetsList.filter(obj => selectedNode.includes(obj.asset_name)) : []

        let eventsList = []
        this.state.eventsList && this.state.eventsList.length && this.state.eventsList.forEach((item, index) => {
            item.assets && item.assets.forEach((asst, i) => {
                if(selectedNode.includes(asst.asset_name)) {
                    eventsList.push(item)
                }
            })
        })

        // if(alertsList.length) {
        //     this.getAssetDetails(alertsList[0])
        // } else if(assetsList.length) {
        //     this.getAssetDetails(assetsList[0])
        // }
        this.setState({ filteredAlertArray: alertsList, filteredAssetArray: assetsList, filteredEventArray: eventsList })
    }

    resetfilterTableResult = () => {
        this.setState({ filteredAlertArray: this.state.alertsList, filteredAssetArray: this.state.assetsList, filteredEventArray: this.state.eventsList })
    }

    getAssetDetails = (assets) => {
        // this.setState({ showDetailsAssetSectionLoading: true, showSupressSectionLoading: true })
        let assetId = assets.asset_id ? assets.asset_id : ''
        let serviceName = assets.service_name ? assets.service_name : ''
        if(assetId !== '' && serviceName !== '') {
            let params = {}
            params.service_name = serviceName
            params.asset_id = assetId
    
            this.props.getAssetDetails(params, (promise, assetDetails) => {
                if (promise) {
                    this.setState({ assetDetails })
                } else {
                    this.setState({ assetDetails: [] })
                }
            })
        } else {
            this.setState({ assetDetails: [] })
        }
    }
    
    handleAlertKeyPress = (event) => {
        let filterAlertValue = this.state.filterAlertValue
        if(this.state.searchAlertText !== '') {
            if(event.charCode === 13 || event.keyCode === 13 || event.charCode === 32 || event.keyCode === 32){
                filterAlertValue.push(this.state.searchAlertText.trim())
                this.setState({ filterAlertValue, searchAlertText: '' })
            }
        }
    }
    
    handleEventKeyPress = (event) => {
        let filterEventValue = this.state.filterEventValue
        if(this.state.searchEventText !== '') {
            if(event.charCode === 13 || event.keyCode === 13 || event.charCode === 32 || event.keyCode === 32){
                filterEventValue.push(this.state.searchEventText.trim())
                this.setState({ filterEventValue, searchEventText: '' })
            }
        }
    }
    
    handleAssetKeyPress = (event) => {
        let filterAssetValue = this.state.filterAssetValue
        if(this.state.searchAssetText !== '') {
            if(event.charCode === 13 || event.keyCode === 13 || event.charCode === 32 || event.keyCode === 32){
                filterAssetValue.push(this.state.searchAssetText.trim())
                this.setState({ filterAssetValue, searchAssetText: '' })
            }
        }
    }

    removefilter = (index, type) => {
        if(type === 'alert') {
            let filterArray = this.state.filterAlertValue
            filterArray.splice(index, 1);
            this.setState({ filterAlertValue : filterArray });
            if(!filterArray.length) {
                this.setState({ filteredAlertArray: this.state.alertsList })
            }
        } else if(type === 'event') {
            let filterArray = this.state.filterEventValue
            filterArray.splice(index, 1);
            this.setState({ filterEventValue : filterArray });
            if(!filterArray.length) {
                this.setState({ filteredEventArray: this.state.eventsList })
            }
        } else if(type === 'asset') {
            let filterArray = this.state.filterAssetValue
            filterArray.splice(index, 1);
            this.setState({ filterAssetValue : filterArray });
            if(!filterArray.length) {
                this.setState({ filteredAssetArray: this.state.assetsList })
            }
        }
    }
    
    searchTableResult = (type) => {
        let filterCondition = []
        let filterValue = []
        if(type === 'alert') {
            filterCondition = this.state.alertsList
            filterValue = this.state.filterAlertValue
        } else if(type === 'event') {
            filterCondition = this.state.eventsList
            filterValue = this.state.filterEventValue
        } else if(type === 'asset') {
            filterCondition = this.state.assetsList
            filterValue = this.state.filterAssetValue
        }

        if (filterValue.length) {
            filterCondition = filterCondition &&
                filterCondition.filter(item => {
                    let isPresent = []
                        filterValue.forEach(searchItem => {
                            isPresent = this.recursiveSearch(item, searchItem)
                        })
                        isPresent.flat()
                        .filter(bool => bool === true)
                    if (isPresent[0]) {
                        return true
                    } else {
                        return false
                    }
                })
        }

        if(type === 'alert') {
            this.setState({ filteredAlertArray: filterCondition })
        } else if(type === 'event') {
            this.setState({ filteredEventArray: filterCondition })
        } else if(type === 'asset') {
            this.setState({ filteredAssetArray: filterCondition })
        }
        
    }
    
    recursiveSearch = (item, searchValue) => {
        return Object.keys(item).map(key => {
            if (item[key] !== null && typeof item[key] == 'object') {
                return this.recursiveSearch(item[key], searchValue)
                // eslint-disable-next-line valid-typeof
            } else if (typeof item[key] === 'array') {
                return item.map((arrayElement, i) => this.recursiveSearch(arrayElement[i], searchValue))
            } else {
                // if(this.props.searchFilter.includes(key)){
                return item[key] !== null
                    ? item[key]
                            .toString()
                            .toLowerCase()
                            .indexOf(searchValue.toString().toLowerCase()) > -1
                    : false
                // }else{
                // return false;
                // }
            }
        })
    }

    handleClick = (e, data) => {
        this.setState({context: data});
    }

    listAlerts = () => {
        this.setState({ showAlertsTableLoading: true })
        let alertsTableColumns = []
        let dataRow = {}

        dataRow.Header = ' '
        dataRow.accessor = 'severity'
        dataRow.Cell = cellInfo => (
            <span className={`badge ${
                cellInfo.row.original.severity && cellInfo.row.original.severity !== '' ?
                    'risk-badge-'+cellInfo.row.original.severity.toLowerCase()
                : 'badge-secondary'
                }`}
            >
                {cellInfo.row.original.severity && cellInfo.row.original.severity !== '' ? (cellInfo.row.original.severity.toLowerCase() === 'low' ? 'L' : cellInfo.row.original.severity.toLowerCase() === 'medium' ? 'M' :  cellInfo.row.original.severity.toLowerCase() === 'high' ? 'H' : cellInfo.row.original.severity.toLowerCase() === 'critical' ? 'C' : '-') : '-'}
            </span>
        )
        dataRow.width = 40
        alertsTableColumns.push(dataRow)

        dataRow = {}
        dataRow.Header = 'Date'
        dataRow.accessor = 'event_time'
        dataRow.width = 110
        dataRow.Cell = cellInfo => (
            <React.Fragment>
                <span>{cellInfo.row.original.event_time ? momentConvertionUtcToLocalTime(cellInfo.row.original.event_time, 'DD MMM YYYY HH:mm') : ''}</span>
            </React.Fragment>
        )
        alertsTableColumns.push(dataRow)
        
        dataRow = {}
        dataRow.Header = 'Resource'
        dataRow.accessor = d => (d.provider ? d.provider.toUpperCase() + ' : ' : '') + (d.account_id ? d.account_id + ' : ' : '') + (d.region ? d.region + ' : ' : '') + (d.resource_type ? d.resource_type : '')
        dataRow.width = 180
        alertsTableColumns.push(dataRow)

        dataRow = {}
        dataRow.Header = 'Asset'
        dataRow.accessor =  d =>Array.isArray(d.asset_name) ? d.asset_name.map((item, index) => (d.asset_name.length - 1 === index ? item : item + ', ')) : (d.asset_name ? d.asset_name : '')
        dataRow.width = 100
        alertsTableColumns.push(dataRow)
        
        dataRow = {}
        dataRow.Header = 'Alert'
        dataRow.accessor = 'event_type'
        dataRow.width = 100
        alertsTableColumns.push(dataRow)

        dataRow = {}
        dataRow.Header = 'Description'
        dataRow.accessor = 'event_name'	
        dataRow.width = 200				
        alertsTableColumns.push(dataRow)

        dataRow = {}
        dataRow.Header = 'Source'
        dataRow.accessor = 'event_source'
        dataRow.width = 100			
        alertsTableColumns.push(dataRow)

        this.setState({ alertsList: this.props.alertsList ? this.props.alertsList : [], filteredAlertArray: this.props.alertsList ? this.props.alertsList : [], alertDynamicColumns: alertsTableColumns, showAlertsTableLoading: false })
    }

    listAssets = () => {
        this.setState({ showAssetsTableLoading: true })
        let assetsTableColumns = []
        let dataRow = {}
        dataRow.Header = 'Asset'
        dataRow.accessor =  d =>Array.isArray(d.asset_name) ? d.asset_name.map((item, index) => (d.asset_name.length - 1 === index ? item : item + ', ')) : (d.asset_name ? d.asset_name : '')
        assetsTableColumns.push(dataRow)
        
        dataRow = {}
        dataRow.Header = 'Asset Id'
        dataRow.accessor = 'asset_id'
        assetsTableColumns.push(dataRow)

        dataRow = {}
        dataRow.Header = 'Provider'
        // dataRow.accessor = 'provider'
        dataRow.accessor =  d  => d.provider.toUpperCase()
        dataRow.width = 80
        assetsTableColumns.push(dataRow)

        dataRow = {}
        dataRow.Header = 'Account'
        dataRow.accessor = 'account_id'
        assetsTableColumns.push(dataRow)

        dataRow = {}
        dataRow.Header = 'Region'
        dataRow.accessor = 'region'
        assetsTableColumns.push(dataRow)

        dataRow = {}
        dataRow.Header = 'Resource'
        dataRow.accessor = 'resource_type'
        dataRow.width = 100
        assetsTableColumns.push(dataRow)

        dataRow = {}
        dataRow.Header = 'Service'
        dataRow.accessor = 'service_name'
        dataRow.width = 100
        assetsTableColumns.push(dataRow)

        this.setState({ assetsList: this.props.assetsList ? this.props.assetsList : [], filteredAssetArray: this.props.assetsList ? this.props.assetsList : [], assetDynamicColumns: assetsTableColumns, showAssetsTableLoading: false })
    }

	render() {
		return (
            <div className='row topologyRightSection'>
                <div className="col-sm-12 p-3">
                    {this.state.assetDetails && this.state.assetDetails.length ?
                        <div className="rounded bg-dark-50 p-3 mb-3">
                            <p className="mb-0 f16 text-white">Asset Details</p>
                            <p className="mb-1 f12">Showing the asset details</p>
                            <div className="row">
                                <p className="col-sm-12">{this.state.assetDetails.event_name ? this.state.assetDetails.event_name : ''}</p>
                                <div className="col-sm-6">
                                    <p className="mb-2">Asset Date: <b>{this.state.assetDetails.action_date ? momentConvertionUtcToLocalTime(this.state.assetDetails.action_date, 'DD MMM YYYY HH:mm') : ''}</b></p>
                                    <p className="mb-2">Event Source: <b>{this.state.assetDetails.event_source ?this.state.assetDetails.event_source : ''}</b></p>
                                    <p className="mb-2">Account: <b>{this.state.assetDetails.account_id ?this.state.assetDetails.account_id : ''}</b></p>
                                    <p className="mb-2">Resource: <b>{this.state.assetDetails.resource_type ?this.state.assetDetails.resource_type : ''}</b></p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="mb-2">Asset Name: <b>{this.state.assetDetails.asset_name ? this.state.assetDetails.asset_name : ''}</b></p>
                                    <p className="mb-2">Service: <b>{this.state.assetDetails.service_name ? this.state.assetDetails.service_name : ''}</b>
                                    </p>
                                    <p className="mb-2">Resource: <b>{this.state.assetDetails.provider ? 
                                        (Array.isArray(this.state.assetDetails.provider) ? this.state.assetDetails.provider[0].toUpperCase() : this.state.assetDetails.provider).toUpperCase() +' : '+(this.state.assetDetails.region ? 
                                        this.state.assetDetails.region +' : ' :'') : ''}</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                    : null
                    }

                    <div className="col-lg-12">
                        <div className="rounded bg-dark p-3">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="mb-0 f16 text-white">Assets</p>
                                    {this.state.filteredAssetArray ?
                                        <p className="mb-1 f12">Showing {this.state.filteredAssetArray.length > this.state.rowsPerPage ? this.state.rowsPerPage : this.state.filteredAssetArray.length} out of {this.state.filteredAssetArray.length} Assets</p>
                                    : null
                                    }
                                </div>
                                <span onClick={this.resetfilterTableResult} className="fa fa-redo-alt pr-2 cursorPointer align-self-center"></span>
                            </div>
                            <div className='d-flex mb-2'>
                                <div className="d-flex flex-wrap bg-muted rounded_25rem w-100">
                                    {this.state.filterAssetValue && this.state.filterAssetValue.length ? this.state.filterAssetValue.map((row, index) =>
                                        <span className="badge badge-light f14 mr-1 mb-1" >
                                            {row} 
                                            <i onClick={ () => this.removefilter(index, 'asset') } className='ml-1 fal fa-times cursorPointer'></i>
                                        </span>
                                        ) 
                                    : null
                                    }
                                    <div className="comparisonTextboxDiv w-99">
                                        <input type="text" className={`comparisonTextbox bg-muted text-gray3`}
                                        onChange={e => {this.setState({ searchAssetText: e.target.value })}}
                                        onKeyDown={this.handleAssetKeyPress}
                                        value={this.state.searchAssetText}
                                        placeholder={this.state.placeHolder}
                                    />
                                    </div>
                                </div>
                                
                                <i onClick={ () => this.searchTableResult('asset') } className='align-self-center ml-2 mr-2 fal fa-search cursorPointer'></i>
                                <i onClick={ () => this.setState({ showTable: false }) } className='align-self-center ml-2 mr-2 fal fa-times cursorPointer'></i>
                            </div>
                            {this.state.filteredAssetArray && this.state.filteredAssetArray.length && !this.state.showAssetsTableLoading ?
                                <div className="topologyNodeAssetsTable scrollbarDesign">
                                    <ResizeChecboxTable
                                        columns={this.state.assetDynamicColumns}
                                        data={this.state.filteredAssetArray ? this.state.filteredAssetArray : []}
                                        perPage={this.state.rowsPerPage}
                                        dashboard={this.state.filteredAssetArray && this.state.filteredAssetArray.length ? true : false}
                                        sortByColumn={'Asset'}
                                        riskTooltip={[]}
                                        onClickRow={tableRow => {}}
                                        tableHead={'bg-muted'}
                                        tableBody={'bg-secondary'}
                                        tableSize={'table-xs'}
                                        tableHover={'table-hover-dark'}
                                        textColor={'text-white'}
                                        columnBorder={'border-0'}
                                    />
                                </div>
                            : this.state.filteredAssetArray && !this.state.filteredAssetArray.length && !this.state.showAssetsTableLoading ?
                                <div className='d-flex justify-content-center m-4'>
                                    <p>There are no data on this criteria. Please try adjusting your Search.</p>
                                </div>
                            : null}
                            {this.state.showAssetsTableLoading ? (
                                <div className='d-flex justify-content-center m-4'>
                                    <Spinner className='text-center' color='dark' size='sm' />
                                </div>
                            ) : null}
                        </div>
                    </div>
                    
                    <div className="col-lg-12">
                        <div className="rounded bg-dark p-3 mt-3">
                            <p className="mb-0 f16 text-white"><b>Alerts</b></p>
                            {this.state.filteredAlertArray ?
                                <p className="mb-1 f12">Showing {this.state.filteredAlertArray.length > this.state.rowsPerPage ? this.state.rowsPerPage : this.state.filteredAlertArray.length} out of {this.state.filteredAlertArray.length} Alerts {this.props.aiopsPropsDetails.topology_selected_node && this.props.aiopsPropsDetails.topology_selected_node.asset_name ? 'of '+ this.props.aiopsPropsDetails.asset_name +' Asset' : ''}</p>
                            : null
                            }
                            <div className='d-flex mb-2'>
                                <div className="d-flex flex-wrap bg-muted rounded_25rem w-100">
                                    {this.state.filterAlertValue && this.state.filterAlertValue.length ? this.state.filterAlertValue.map((row, index) =>
                                        <span className="badge badge-light f14 mr-1 mb-1" >
                                            {row} 
                                            <i onClick={ () => this.removefilter(index, 'alert') } className='ml-1 fal fa-times cursorPointer'></i>
                                        </span>
                                        ) 
                                    : null}
                                    <div className="comparisonTextboxDiv w-99">
                                        <input type="text" className={`comparisonTextbox bg-muted text-gray3`}
                                        onChange={e => {this.setState({ searchAlertText: e.target.value })}}
                                        onKeyDown={this.handleAlertKeyPress}
                                        value={this.state.searchAlertText}
                                        placeholder={this.state.placeHolder}
                                    />
                                    </div>
                                </div>
                                
                                <i onClick={ () => this.searchTableResult('alert') } className='align-self-center ml-2 mr-2 fal fa-search cursorPointer'></i>
                                <i onClick={ () => this.setState({ showTable: false }) } className='align-self-center ml-2 mr-2 fal fa-times cursorPointer'></i>
                            </div>
                            {this.state.filteredAlertArray && this.state.filteredAlertArray.length && !this.state.showAlertsTableLoading ?
                                <div className="topologyNodeAlertsTable scrollbarDesign">
                                    {/* <ContextMenuTrigger id="some_unique_identifier"> */}
                                        <ResizeChecboxTable
                                            columns={this.state.alertDynamicColumns}
                                            data={this.state.filteredAlertArray ? this.state.filteredAlertArray : []}
                                            perPage={this.state.rowsPerPage}
                                            dashboard={this.state.filteredAlertArray && this.state.filteredAlertArray.length ? true : false}
                                            sortByColumn={'event_time'}
                                            riskTooltip={[0]}
                                            onClickRow={tableRow => {}}
                                            tableHead={'bg-muted'}
                                            tableBody={'bg-secondary'}
                                            tableSize={'table-xs'}
                                            tableHover={'table-hover-dark'}
                                            textColor={'text-white'}
                                            columnBorder={'border-0'}	
                                        />
                                    {/* </ContextMenuTrigger> */}

                                    <ContextMenu id="some_unique_identifier">
                                        <MenuItem data="some_data" onClick={this.handleClick}>
                                            Menu 1
                                        </MenuItem>
                                        <MenuItem data={"some_data"} onClick={this.handleClick}>
                                            Menu 2
                                        </MenuItem>
                                        <MenuItem divider />
                                        <MenuItem data={"some_data"} onClick={this.handleClick}>
                                            Menu 3
                                        </MenuItem>
                                    </ContextMenu>
                                </div>
                            : this.state.filteredAlertArray && !this.state.filteredAlertArray.length && !this.state.showAlertsTableLoading ?
                                <div className='d-flex justify-content-center m-4'>
                                    <p>There are no data on this criteria. Please try adjusting your Search.</p>
                                </div>
                            : null}
                            {this.state.showAlertsTableLoading ? (
                                <div className='d-flex justify-content-center m-4'>
                                    <Spinner className='text-center' color='dark' size='sm' />
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="rounded bg-dark p-3 mt-3">
                            <p className="mb-0 f16 text-white"><b>Events</b></p>                        
                            {this.state.filteredEventArray ?
                                <p className="mb-1 f12">Showing {this.state.filteredEventArray.length > this.state.rowsPerPage ? this.state.rowsPerPage : this.state.filteredEventArray.length} out of {this.state.filteredEventArray.length} Events {this.props.aiopsPropsDetails.topology_selected_node && this.props.aiopsPropsDetails.topology_selected_node.asset_name ? 'of '+ this.props.aiopsPropsDetails.asset_name +' Asset' : ''}</p>
                            : null
                            }
                            <div className='d-flex mb-2'>
                                <div className="d-flex flex-wrap bg-muted rounded_25rem w-100">
                                    {this.state.filterEventValue && this.state.filterEventValue.length ? this.state.filterEventValue.map((row, index) =>
                                        <span className="badge badge-light f14 mr-1 mb-1" >
                                            {row} 
                                            <i onClick={ () => this.removefilter(index, 'event') } className='ml-1 fal fa-times cursorPointer'></i>
                                        </span>
                                        ) 
                                    : null}
                                    <div className="comparisonTextboxDiv w-99">
                                        <input type="text" className={`comparisonTextbox bg-muted text-gray3`}
                                        onChange={e => {this.setState({ searchEventText: e.target.value })}}
                                        onKeyDown={this.handleEventKeyPress}
                                        value={this.state.searchEventText}
                                        placeholder={this.state.placeHolder}
                                    />
                                    </div>
                                </div>
                                
                                <i onClick={ () => this.searchTableResult('event') } className='align-self-center ml-2 mr-2 fal fa-search cursorPointer'></i>
                                <i onClick={ () => this.setState({ showTable: false }) } className='align-self-center ml-2 mr-2 fal fa-times cursorPointer'></i>
                            </div>
                            {this.state.filteredEventArray && this.state.filteredEventArray.length && !this.state.showEventsTableLoading ?
                                <div className="topologyNodeAssetsTable scrollbarDesign">
                                    <ResizeChecboxTable
                                        columns={this.state.eventsDynamicColumns}
                                        data={this.state.filteredEventArray ? this.state.filteredEventArray : []}
                                        perPage={this.state.rowsPerPage}
                                        dashboard={this.state.filteredEventArray && this.state.filteredEventArray.length ? true : false}
                                        sortByColumn={'Asset'}
                                        riskTooltip={[]}
                                        onClickRow={tableRow => {}}
                                        tableHead={'bg-muted'}
                                        tableBody={'bg-secondary'}
                                        tableSize={'table-xs'}
                                        tableHover={'table-hover-dark'}
                                        textColor={'text-white'}
                                        columnBorder={'border-0'}	
                                    />
                                </div>
                            : this.state.filteredEventArray && !this.state.filteredEventArray.length && !this.state.showEventsTableLoading ?
                                <div className='d-flex justify-content-center m-4'>
                                    <p>There are no data on this criteria. Please try adjusting your Search.</p>
                                </div>
                            : null}
                            {this.state.showEventsTableLoading ? (
                                <div className='d-flex justify-content-center m-4'>
                                    <Spinner className='text-center' color='dark' size='sm' />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}
/**
 * Type of the props used in the component
 */
TopologyRightSection.propTypes = {
    listAlerts: PropTypes.func,
    listEvents: PropTypes.func,
    getAssetDetails: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
	// console.log('state', state)
	return {
        alertsList: state.aiops.caseDetails.results && state.aiops.caseDetails.results.alerts ? state.aiops.caseDetails.results.alerts : [],
        assetsList: state.aiops.caseDetails.results && state.aiops.caseDetails.results.assets ? state.aiops.caseDetails.results.assets : [],
        aiopsPropsDetails: state.aiops.aiopsPropsDetails ? state.aiops.aiopsPropsDetails : {},
    }
}

export default connect(mapStateToProps, {
    listAlerts,
    listEvents,
    getAssetDetails,
})(withRouter(TopologyRightSection))