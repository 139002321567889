
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file IssuesTab.js
 * @author Prakash // on 20/04/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'

import { Spinner } from 'reactstrap'
import { getCoveredListsSummary, getCoveredTrendsSummary } from '../../../actions/aiops/MicroServicesAction'
import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'

import _ from 'lodash'
import { momentDateGivenFormat, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, capitalizeFirstLetter } from '../../../utils/utility'

import Search from '../../common/SearchComponent'
import ApexTrendBarChart from '../../common/charts/ApexTrendBarChart'

class IssuesTab extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            minMaxListDivHeight: "400px",
            listLoader: true,

            startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
            overAllTotalPages: 1,
			perPage: 100,
            
            groupedBy: 'severity',
            selectedCategory: 'Important / All',
            minimizeGraphSection: false,
        }
    }

    componentDidMount = () => {
        this.getWindowSize()
        if(this.props.microSericesFilters && Object.entries(this.props.microSericesFilters).length) {
            this.setState({ 
                start_time: this.props.microSericesFilters.start_time,
                end_time: this.props.microSericesFilters.end_time,
                brushStartTime: this.props.microSericesFilters.brushStartDate ? this.props.microSericesFilters.brushStartDate : this.props.microSericesFilters.start_time,
                brushEndTime: this.props.microSericesFilters.brushEndDate ? this.props.microSericesFilters.brushEndDate : this.props.microSericesFilters.end_time,
                listLoader: true,
                graphData: {}
            },
                () => {
                    this.getCoveredTrendsSummary()
                    this.getCoveredListsSummary()
                }
            )
        }
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 50
        let graphSearchHeight = this.state.minimizeGraphSection ? 170 : 270
                
		let outerHeight = (window.outerHeight - browserBorder - headerHeight - graphSearchHeight) + "px"

        this.setState({ minMaxListDivHeight: outerHeight })
    }

    getCoveredTrendsSummary = () => {
        let params = {
            "start_time": momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss'),
            "end_time":  momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss'),
            "provider": "aws",
            "account_id": this.props.selectedAccount,
            "microservice_id": [this.props.selectedMicroServies.microservice_id],
            "duration_aggregate_by": "hourly",
            "aggregate_by": "microservice",
            "flag": "issues"
        }

        this.props.getCoveredTrendsSummary(params, (promise, response) => {
            if(promise) {
                this.setState({ trendData: response },
                    () => this.trendDetails()    
                )
            } else {
                this.setState({ trendData: {} })
            }
        })        
    }

    trendDetails = () => {
        let graphData = {
            start_time: momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss'), 
            end_time: momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss')
        }

        let graphDetails = this.state.trendData

        let trendSeverityCount = graphDetails && graphDetails.total_count && graphDetails.total_count ? graphDetails.total_count : {}
                    
        if(graphDetails && graphDetails.trend && graphDetails.trend.data) {
            graphData = {
                data: graphDetails.trend.data,
                labels: graphDetails.dates,
                start_time: momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss'), 
                end_time: momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss')
            }
        }

        this.setState({ graphData, trendSeverityCount })
    }

    resetGraph = () => {
        let graphData = this.state.graphData
        this.setState({ graphData: {}, zoomedStartDate: "", zoomedEndDate: "" },
            () => {
                this.getCategoryData()
                this.setState({ graphData })
            }
        )
    }

    navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = this.state.startRecord
            if(action === 'next' && currentPage !== this.state.totalPages) {
                startRecord = startRecord + this.state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - this.state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = this.state.totalPages
                startRecord = (this.state.totalPages - 1) * this.state.perPage
            }
            this.setState({ currentPage, startRecord })
        } 
	}

    gotoPage = (pageNumber) => {
        if(pageNumber > 0  && pageNumber <= this.state.totalPages) {
            let startRecord = (pageNumber - 1) * this.state.perPage
            this.setState({ currentPage: pageNumber, pageNumber, startRecord })
        } else  if(pageNumber > this.state.totalPages) {
            this.setState({ pageNumber: this.state.currentPage })  
        } else {
            this.setState({ pageNumber })
        }
    }

    getCoveredListsSummary = () => {
        let params = {
            "start_time": momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss'),
            "end_time":  momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss'),
            "provider": "aws",
            "account_id": this.props.selectedAccount,
            "microservice_id": [this.props.selectedMicroServies.microservice_id],
            "duration_aggregate_by": "hourly",
            "aggregate_by": "microservice",
            "flag": "issues",
            "list_aggregate_by": this.state.groupedBy
        }

        this.props.getCoveredListsSummary(params, (promise, response) => {
            if(promise) {
                let startRecord = 0
                let currentPage = 1
                
                let data = response.list ? response.list : []
        
                let totalPages = 1
                if(data.length > this.state.perPage) {
                    totalPages = Math.ceil(data.length / this.state.perPage)
                }

                this.setState({
                    listResponse: data,
                    listLoader: false,
                    totalPages,
                    startRecord,
                    currentPage 
                },
                    () => {
                        this.structureListData()
                    }
                )
            } else {
                this.setState({ listLoader: false })
            }
        })
    }

    structureListData = () => {
        let listAllData = []
        let totalCount = 0
        let listCategory = []
        if(this.state.listResponse && this.state.listResponse.length) {
            totalCount = this.state.listResponse.reduce( function(a, b){ return a + b.count;}, 0);
            this.state.listResponse.forEach((item, index) => {
                let dataRow = {}
                dataRow.count = item.count
                dataRow.category = item.label
                dataRow.orderBy = index+1
                let percentage = Math.ceil(((item.count / totalCount) * 100)/10)
                let array = []
                for(let i=1; i<=percentage; i++) {
                    array.push(i)
                }
                dataRow.totalBars = array

                listCategory.push(dataRow)
                item.list.forEach(row => {
                    listAllData.push(row)
                })
            })
        }

        let dataRow = {}
        dataRow.count = ""
        dataRow.category = 'Important / All'
        dataRow.orderBy = 0
        listCategory.push(dataRow)

        this.setState({ listAllData, listCategory: _.orderBy(listCategory, ['orderBy'], ['asc'])},
            () => this.getCategoryData()
        )
    }
    
    getCategoryData = () => {
        let filterdCategoryData = this.state.listAllData
        if(this.state.selectedCategory !== 'Important / All') {
            filterdCategoryData = this.state.listResponse.filter(arr => arr.label === this.state.selectedCategory)
            if(filterdCategoryData && filterdCategoryData.length) {
                filterdCategoryData = filterdCategoryData[0].list ? filterdCategoryData[0].list : []
            }
        }

        let orderedArray = _.orderBy(filterdCategoryData, ['event_time'], ['desc'])

        let totalPages = 1
		if(orderedArray.length > this.state.perPage) {
			totalPages = Math.ceil(orderedArray.length / this.state.perPage)
		}
        console.log("orderedArray", orderedArray)
        this.setState({ totalFilteredArray: orderedArray, filteredArray: orderedArray, selectedItem: "", selectedItemId: "", totalPages, startRecord: 0, currentPage: 1 })
    }
    
    handleChange = (event) => {
        this.setState({ groupType: event.target.value, selectedItem: {}, selectedItemId: "", listLoader: true },
            () => {
                this.getCoveredListsSummary()
                // this.redirectToAnalysis()
            }    
        )
    }

    filterZoomData = (zoomedStartDate, zoomedEndDate) => {

        let data = this.state.totalFilteredArray ? this.state.totalFilteredArray : []

        data = data.filter(e => momentConvertionUtcToLocalTime(e.event_time, "YYYY-MM-DD HH:mm:ss") >= zoomedStartDate && momentConvertionUtcToLocalTime(e.event_time, "YYYY-MM-DD HH:mm:ss") <= zoomedEndDate)

        data = _.orderBy(data, ['event_time'], ['desc'])

        let totalPages = 1
		if(data.length > this.state.perPage) {
			totalPages = Math.ceil(data.length / this.state.perPage)
		}

        this.setState({ totalFilteredArray: data, filteredArray: data, totalPages, startRecord: 0, currentPage: 1, zoomedStartDate, zoomedEndDate },
            () => {
                if(this.state.filteredArray.length) {
                    // this.getEventDetails(this.state.filteredArray[0])
                }
            }    
        )

    }

    handleClickOutside(event) {
        // if (this.toggleRef && !this.toggleRef.current.contains(event.target)) {
        //     this.setState({ showPageTypeOptions: false })
        // } else {
        //     this.setState({ showPageTypeOptions: true })
        // }
    }

    render() {
        return (
            <div className="" onClick={(event) => this.handleClickOutside(event)}>
                <div className="bg-dark3 mt-2 p-3 rounded">
                    <div className={`${this.state.trendSeverityCount && Object.entries(this.state.trendSeverityCount).length ? "d-flex justify-content-between" : ""}`}>
                        {this.state.trendSeverityCount && Object.entries(this.state.trendSeverityCount).length ? 
                            <div className="d-flex">
                                <label className="m-0 p-0">Total ({this.state.trendSeverityCount.total ? this.state.trendSeverityCount.total : 0})</label>
                                <div className="d-flex ml-2 align-self-center">
                                    <div className={`f10 badge-square-sm risk-badge-critical`}>C</div>
                                    <p className="m-0 f11 text-white"><span className="mx-1">:</span> {this.state.trendSeverityCount.severity && this.state.trendSeverityCount.severity.Critical ? this.state.trendSeverityCount.severity.Critical : 0}</p>
                                </div>
                                <div className="d-flex ml-2 align-self-center">
                                    <div className={`f10 badge-square-sm risk-badge-high`}>H</div>
                                    <p className="m-0 f11 text-white"><span className="mx-1">:</span> {this.state.trendSeverityCount.severity && this.state.trendSeverityCount.severity.High ? this.state.trendSeverityCount.severity.High : 0}</p>
                                </div>
                                <div className="d-flex ml-2 align-self-center">
                                    <div className={`f10 badge-square-sm risk-badge-medium`}>M</div>
                                    <p className="m-0 f11 text-white"><span className="mx-1">:</span> {this.state.trendSeverityCount.severity && this.state.trendSeverityCount.severity.Medium ? this.state.trendSeverityCount.severity.Medium : 0}</p>
                                </div>
                                <div className="d-flex ml-2 align-self-center">
                                    <div className={`f10 badge-square-sm risk-badge-low`}>L</div>
                                    <p className="m-0 f11 text-white"><span className="mx-1">:</span> {this.state.trendSeverityCount.severity && this.state.trendSeverityCount.severity.Low ? this.state.trendSeverityCount.severity.Low : 0}</p>
                                </div>
                            </div>
                        : null}
                        <div className="d-flex justify-content-end">
                            {!this.state.minimizeGraphSection ?
                                <i className='far fa-redo cursorPointer' onClick={()=> this.resetGraph()}></i>
                            : null}
                            <i className={`fa fa-caret-${this.state.minimizeGraphSection ? "down" : "down"} cursorPointer ml-3 text-gray2`} onClick={() => this.setState({ minimizeGraphSection: !this.state.minimizeGraphSection }, () => this.getWindowSize())}></i>
                        </div>
                    </div>
                    {this.state.graphData && Object.keys(this.state.graphData).length && !this.state.minimizeGraphSection ?
                        <div className="mb-n2">
                            <ApexTrendBarChart
                                graphData={this.state.graphData}
                                graphRiskCountSection={false}
                                sparkline={false}
                                yaxis={false}
                                yaxisLabel={true}
                                yaxisTickAmount={2}
                                xaxis={true}
                                xaxisFormat={'string'}
                                xaxisLabel={true}
                                axisLabelColor={'#50586A'}
                                legend={false}
                                stacked={false}
                                height={100}
                                horizontal={false}
                                barHeight={'40%'}
                                barEndShape={'flat'}
                                columnWidth={'0%'}
                                backgroundBarShape={'rounded'}
                                backgroundBarColors={['#333947']}
                                showBackgroundBarColors={true}
                                className={"transparentTooltip"}
                                colors={['#3DAFE8']}
                                zoomEnabled={true}
                                getZoomPeriod={true}
                                zoomPeriod={(zoomedStartDate, zoomedEndDate) => {this.filterZoomData(zoomedStartDate, zoomedEndDate)}}
                            />
                        </div>
                    : null}
                </div>
                <div className="d-flex mt-2">
                    <div className="p-0 col-sm-12">
                        {/* <div className={`d-flex justify-content-between mt-2 mb-2 ${this.state.listLoader ? 'disabled' : ''}`}>
                            <div className="d-flex">
                                <p className="m-0 align-self-end small mr-2">Show All</p>
                                <p className="m-0 align-self-end small mr-2">issues groupd by</p>
                                <div class={`transparentSelectBorderBottom align-self-end`}>
                                    <select class="form-control" 
                                        onChange={(event) => {
                                            this.setState({ groupedBy: event.target.value, showGroupLoading: true, selectedCategory: "Important / All", selectedItem: {}, selectedItemId: "", listLoader: true },
                                                () => {
                                                    this.getCoveredListsSummary()
                                                }
                                            )
                                        }}
                                        value={this.state.groupedBy}                            
                                        >
                                        <option key="severity" value="severity">Severity</option>
                                        <option key="impact_type" value="impact_type">Impact</option>
                                        <option key="category" value='category'>Category</option>
                                    </select>
                                </div>
                            </div>
                            <div className="d-flex ml-2 w-30">
                                <p className="m-0 align-self-end small mx-2">Category</p>
                                {this.state.listCategory ? 
                                    <div class={`transparentSelectBorderBottom align-self-end`}>
                                        <select className="form-control" 
                                            value={this.state.selectedCategory}
                                            onChange={(event) => {
                                                this.setState({ selectedCategory: event.target.value },
                                                    () => {
                                                        this.getCategoryData()
                                                    }    
                                                )
                                            }}>
                                            {this.state.listCategory.map(item => {
                                                return(
                                                <option value={item.category}>{item.category+" "+item.count}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                : null}
                            </div>
                        </div> */}
                        <div className="d-flex mb-2 justify-content-between">
                            <div className="d-flex mr-2 w-70">
                                <p className="f12 m-0 align-self-center pt-1">Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.state.totalFilteredArray && this.state.totalFilteredArray.length} issue(s)</p>
                                {this.state.zoomedStartDate && this.state.zoomedStartDate !== "" ? 
                                    <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{momentDateGivenFormat(this.state.zoomedStartDate, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.zoomedEndDate, 'DD MMM YYYY HH:mm')}</p>
                                : 
                                    <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{this.state.brushStartTime ? momentDateGivenFormat(this.state.brushStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.brushEndTime, 'DD MMM YYYY HH:mm') : ''}</p>
                                }
                            </div>
                            <div className="d-flex justify-content-end w-30 mb-n2">
                                {this.state.totalFilteredArray && this.state.totalFilteredArray.length ?
                                    <Search
                                        data={this.state.totalFilteredArray ? this.state.totalFilteredArray : []}
                                        applyTags={false}
                                        applyLiteDarkTags={true}
                                        topClassName={'bg-black5 align-self-center w-100 mr-1 border-gray5 rounded-5 f12'}
                                        searchClassName={'px-2 f12'}
                                        searchIconColor={'text-gray5 f12'}
                                        searchPlaceHolder={'Search....'}
                                        className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                        filteredData={(filteredArray) => {
                                            this.setState({ filteredArray, selectedItem: {}, merticsAssetNameInvolved: [] })
                                        }}
                                    />
                                : null}
                                {this.state.filteredArray && this.state.filteredArray.length > this.state.perPage ?
                                    <div className="pagination errorPagePagination f12 displayNone">
                                        <span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
                                        <button><i className={`fal fa-arrow-to-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('start', this.state.currentPage)}></i></button> 
                                        <button><i className={`fal fa-angle-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('previous', this.state.currentPage)}></i></button> 
                                        <span>
                                            Go to
                                            <input
                                                type='number'
                                                value={this.state.pageNumber || this.state.pageNumber === "" ? this.state.pageNumber : this.state.currentPage}
                                                onChange={e => {
                                                    const page = e.target.value ? Number(e.target.value) : ""
                                                    this.gotoPage(page)
                                                }}
                                                className="ml-1 inputClass"
                                            />
                                        </span>
                                        <button><i className={`fal fa-angle-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
                                        <button><i className={`fal fa-arrow-to-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
                                    </div>
                                : null}
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className={`w-100`}>
                                <div className={`bg-dark3 py-3 pl-2 overflowYAuto`} style={{minHeight: this.state.minMaxListDivHeight, maxHeight: this.state.minMaxListDivHeight}}>
                                    {this.state.listLoader ? 
                                        <div className='d-flex justify-content-center m-4'>
                                            <Spinner className='text-center' color='white' size='lg' />
                                        </div>
                                    : this.state.filteredArray && this.state.filteredArray.length ?
                                        // this.state.filteredArray.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map((item, i) => {
                                        this.state.filteredArray.map((item, i) => {
                                            return(
                                                <div className={`px-2 mb-2 pb-1 border-bottom-black5 ${this.state.selectedItemId && this.state.selectedItemId === item.event_id ? "bg-muted" : ""}`}>
                                                    <div className="d-flex cursorPointer">
                                                        <span className={`f10 mr-2 align-self-start mt-1 badge-sm risk-badge-${item.severity ? item.severity.toLowerCase() : 'critical'}`}>
                                                            {item.severity ? 
                                                                (item.severity.toLowerCase() === 'critical' ? 'C' : item.severity.toLowerCase() === 'high' ? 'H' : item.severity.toLowerCase() === 'medium' ? 'M' : 'L') 
                                                            : 'U'}
                                                        </span>
                                                        <p className="mb-0 text-white">
                                                            <span className="mb-0 mr-2 f12 text-primary-color font-weight-bold">{item.event_time ? momentConvertionUtcToLocalTime(item.event_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                            <span className="mb-0 mr-2 f12 font-weight-bold text-purple-2">Issues</span>
                                                            {item.origin && item.origin.asset_name ? 
                                                                <span className="f12 mb-0 mr-2 text-purple-2"> Asset {item.origin.asset_name}</span> 
                                                            : null}
                                                            <span className="mb-0 small mr-1 text-primary-color">Resource</span>
                                                            {item.account_id ?
                                                                <span className="mb-0 f12 text-primary-color font-weight-bold">{' '+item.account_id}</span>
                                                            : null}
                                                            {item.region ?
                                                                <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+item.region}</span>
                                                            : null}
                                                            {item.resource_type ?
                                                                <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+(Array.isArray(item.resource_type) ? item.resource_type.join(', ') : item.resource_type)}</span>
                                                            : null}
                                                            {item.service_name ?
                                                                <span className="mb-0 f12 text-primary-color font-weight-bold mr-2">{' : '+item.service_name}</span>
                                                            : null}
                                                            <span className="mb-0 mr-2 f12 font-weight-bold">{item.description ? item.description : ''}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                
                                            )
                                        })
                                    :
                                        <div className='d-flex justify-content-center m-4'>
                                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
IssuesTab.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log('errot tab microservcies',state)
    return {
        microSericesFilters: state.observability.microServiesPropsDetails.microSericesFilters ? state.observability.microServiesPropsDetails.microSericesFilters : {}
    }
}

export default connect(mapStateToProps, {
    setAiopsPropsDetails,
    getCoveredTrendsSummary,
    getCoveredListsSummary,
})(withRouter(IssuesTab))