/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from 'react'
import { setEditRuleDetails } from '../../../actions/governance/governanceAction'
import { apiToCall } from '../../../actions/commonAction'
import { Label, Input, FormGroup } from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import TimeRangePicker from '@wojtekmaj/react-timerange-picker'
import Select from 'react-select'

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

class ServiceBusinessHours extends Component {
	constructor(props) {
		super(props)
		this.state = {
			apis: [],
			timing: [],
		}
	}

	componentDidUpdate = () => {
		if (this.props.condition && this.props.condition.length > 0 && !this.state.apis.length) {
			let apiList = []
			this.props.condition[0].events.forEach(event => {
				apiList.push(event)
			})
			this.setState({ apis: apiList })
		}
	}
	getTimingType = api => {
		if (_.has(this.props.editRuleDetails.condition.actions, api)) {
			return this.props.editRuleDetails.condition.actions[api].timing_type
		} else {
			return 'anytime'
		}
	}

	handleChipChange = (value, api, duration_type) => {
		if (duration_type === 'timings') {
			let tmp = this.props.editRuleDetails.condition
			let temp = tmp.actions[api].values[duration_type]
			temp.push(value.join('-'))
			tmp.actions[api].values[duration_type] = temp
			this.props.setEditRuleDetails('condition', { ...this.props.editRuleDetails.condition, ...tmp })
		} else {
			let tmp = this.props.editRuleDetails.condition
			tmp.actions[api].values[duration_type] = value.map(item => item.value)
			this.props.setEditRuleDetails('condition', { ...this.props.editRuleDetails.condition, ...tmp })
		}
	}

	onChange = time => this.setState({ timing: time })

	handleChange(event) {
		let api = event.target.name.split('@')[1]
		let choice = event.target.value
		let tmp = this.props.editRuleDetails.condition
		if (choice.length !== 'anytime') {
			let new_api = {
				timing_type: choice,
			}
			if (choice === 'weekDays') {
				new_api['values'] = {
					days: [],
					timings: [],
				}
			}
			tmp.actions[api] = new_api
		} else {
			if (_.has(tmp.actions, api)) {
				delete tmp.actions[api]
			}
		}
		this.props.setEditRuleDetails('condition', { ...this.props.editRuleDetails.condition, ...tmp })
	}

	handleBusinessHoursChange(time) {
		this.props.setEditRuleDetails('condition', {
			...this.props.editRuleDetails.condition,
			business_hours: time.join('-'),
		})
	}

	render() {
		const { apis } = this.state
		return (
			<div>
				<FormGroup>
					<Label htmlFor='business_hours'>Business Hours</Label>
					<div className='form-group'>
						<TimeRangePicker
							autoFocus
							disableClock
							format='HH:mm:ss'
							rangeDivider='to'
							hourPlaceholder='hh'
							minutePlaceholder='mm'
							secondPlaceholder='ss'
							clearIcon={null}
							maxDetail='second'
							onChange={time => this.handleBusinessHoursChange(time)}
							value={
								this.props.editRuleDetails.condition.business_hours
									? this.props.editRuleDetails.condition.business_hours === ''
										? ['', '']
										: this.props.editRuleDetails.condition.business_hours.split('-')
									: ''
							}
						/>
					</div>
				</FormGroup>

				<Label className='mt-3'>Select duration for Actions:</Label>

				<table>
					<tbody>
						{apis &&
							apis.map((api, index) => {
								return (
									<tr key={index}>
										<td className='d-flex flex-column py-2'>
											{api.name}
											<small className='text-muted'>Lorem ipsum dolor sit amet aedtr.</small>
										</td>
										<td>
											<FormGroup className=''>
												<Label htmlFor={index + 'duration-native-simple'}>Duration</Label>
												<Input
													type='select'
													onChange={e => this.handleChange(e)}
													value={this.getTimingType(api.name)}
													name={'timing_type@' + api.name}
													id={index + 'duration-native-simple'}
												>
													<option>Select</option>
													<option value='anytime' key={'anytime' + index}>
														Any Time
													</option>
													<option value='business_hours' key={'business_hours' + index}>
														Business Hours
													</option>
													<option value='weekDays' key={'weekDays' + index}>
														Specific Duration
													</option>
												</Input>
											</FormGroup>
										</td>
										<td>
											{_.has(this.props.editRuleDetails.condition.actions, api.name) &&
												this.props.editRuleDetails.condition.actions[api.name].timing_type === 'weekDays' && (
													<FormGroup className='mx-3'>
														<Label>Select Day</Label>
														<Select
															onChange={selected => this.handleChipChange(selected, api.name, 'days')}
															options={days.map(item => ({
																value: item,
																label: item,
															}))}
															value={this.props.editRuleDetails.condition.actions[api.name]['values']['days'].map(
																item => ({
																	value: item,
																	label: item,
																})
															)}
															isMulti
															classNamePrefix='Select Days'
															className='basic-multi-select'
														/>
													</FormGroup>
												)}
										</td>
										<td>
											{_.has(this.props.editRuleDetails.condition.actions, api.name) &&
												this.props.editRuleDetails.condition.actions[api.name].timing_type === 'weekDays' && (
													<div className='form-group'>
														<Label>Select Timing</Label>
														<div className='d-flex justify-content-between'>
															<TimeRangePicker
																autoFocus
																disableClock
																format='HH:mm:ss'
																rangeDivider='to'
																className='form-control'
																hourPlaceholder='hh'
																minutePlaceholder='mm'
																secondPlaceholder='ss'
																maxDetail='second'
																clearIcon={null}
																value={this.state.timing}
																// value={
																// 	this.props.editRuleDetails.condition.actions[api.name]['values']['timings'][0] &&
																// 	this.props.editRuleDetails.condition.actions[api.name]['values']['timings'][0].split(
																// 		'-'
																// 	)
																// }
																// onChange={time => this.handleChipChange(time, api.name, 'timings')}
																onChange={time => this.onChange(time)}
															/>
															<button
																className='btn btn-outline-success border-0'
																onClick={() => this.handleChipChange(this.state.timing, api.name, 'timings')}
															>
																<i className='far fa-plus-circle' />
															</button>
														</div>
														<div className='mt-2 d-flex'>
															{this.props.editRuleDetails.condition.actions[api.name]['values']['timings'].length
																? this.props.editRuleDetails.condition.actions[api.name]['values']['timings'].map(
																		(filterBadge, filterBadgeKey) => {
																			return (
																				<h6 className='mb-0 mr-2' key={filterBadgeKey}>
																					<span className='badge font-weight-normal pb-1 badge-secondary'>
																						{filterBadge}
																						<span
																							className='px-1 cursor-pointer'
																							onClick={() => {
																								let arr = this.props.editRuleDetails.condition.actions[api.name][
																									'values'
																								]['timings']
																								arr.splice(filterBadgeKey, 1)
																								this.props.setEditRuleDetails('condition', {
																									...this.props.editRuleDetails.condition,
																									...arr,
																								})
																							}}
																						>
																							×
																						</span>
																					</span>
																				</h6>
																			)
																		}
																  )
																: null}
														</div>
													</div>
												)}
										</td>
									</tr>
								)
							})}
					</tbody>
				</table>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ServiceBusinessHours.propTypes = {
	logType: PropTypes.object,
	setEditRuleDetails: PropTypes.func,
	apiToCall: PropTypes.func,
	editRuleDetails: PropTypes.object,
	condition: PropTypes.array,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		editRuleDetails: state.governance.editRuleDetails,
	}
}
export default connect(mapStateToProps, { setEditRuleDetails, apiToCall })(ServiceBusinessHours)
