/*************************************************
 * Tvastar
 * @exports
 * @file SidePanel.js
 * @author Prakash // on 28/06/2020
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { capitalizeFirstLetter } from '../../../utils/utility'
import {
	getPrivileageAnalysis,//privileage
	getActionsCategoryWise,//privileage
	getAnomalyDetails,//behaviour
	listFrequentlyAccessed,//access
	getDeniedServiceWise,//denied
	getDaywiseDenied,//denied
	getRecentBehavior,//denied
	getIdentifierResults,//checks

	getRelatedIdentifierResults,//resources
	getUserRelatedIssues,//resources
	
} from '../../../actions/governance/userInsightsAction'
import { 
	getDayWiseCount,//checks
	getTotalCount,//checks
} from '../../../actions/detectors/detectorsAction'
import { Spinner, UncontrolledTooltip  } from 'reactstrap'
import { Doughnut } from 'react-chartjs-2';
import Chart from 'react-apexcharts'
import InsightsTable from './Table'
import InsightsPrivileageTable from './InsightsPrivileageTable'
class ViewSidePanel extends Component {
	proEnvWrap = React.createRef()
	constructor(props) {
		super(props)
		this.props = props;
		this.state = {
			activeTab: 0,
			showSpinner: true,
			showLoading: true,

			selectedProvider:this.props.selectedProvider,
			selectedComplicance:this.props.selectedComplicance,
			complianceScore: this.props.complianceScore,
			selectedDuration:this.props.selectedDuration,
			selectedAccount:this.props.selectedAccount,
			
			selectedServiceType: this.props.selectedServiceType,
			selectedRole: this.props.selectedRole,
			selectedCreatedAt: this.props.selectedCreatedAt,
			selectedModifiedAt: this.props.selectedModifiedAt,
			selectedMangedPoliciesCount: this.props.selectedMangedPoliciesCount,


			privileageTypeData: [],
			privileageTotalCountData: [],
			privileageTypeCountData: [],
			privileageData: [],
			privileageAnalysis:[],
			selectedAllowedType: '',
			privileage_doughnut_data: [],
			selectedPrivileageServiceType: '',
			privileageTypeFilterData: [],
			privileage_doughnut_options: [],
			actionsCategoryWise: [],
			category_doughnut_data:[],
			category_doughnut_options:[],
			privileageSortBy: 'sort',
			
			selectedFrequentlyData: 'region',
			selectedFrequentlyTag: 'application',
			frequentlyAccessed:[],
			frequently_doughnut_data: [],
			frequently_doughnut_options: [],
			
			anomalyDetails:[],
			
			deniedServiceWise:[],
			denied_doughnut_data:[],
			denied_doughnut_options:[],
			daywiseDenied:[],
			recentBehavior:[],
			
			uiDayWiseCount:[],
			uiTotalCount:[],

			checksTotalCount: [],
			checks_doughnut_data: [],
			checks_doughnut_options: [],
			checksDaywiseCounts: [],
			checks_bar_data:[],
			checks_bar_options: [],
			identifierResults: [],
			identifierFilterResults: [],
			selectedChecksRisk: '',
			selectedChecksStatus: '',

			resource_doughnut_data: [],
			resource_doughnut_options: [],
			relatedIdentifierResults: [],
			relatedIdentifierFitlerResults: [],
			resourceGraph: [],
			resourceRisk: [],
			relatedRisks:[],
			selectedResourceType: '',
			selectedResourceRisk: '',
			selectedResourceStatus: '',
			
		}
	}

	componentDidMount = () => {
		this.getPrivileageAnalysis()
	}

	//privileage function start

	getPrivileageAnalysis = () => {
		let params = {}
		params.provider = this.state.selectedProvider
		params.account_id = this.state.selectedAccount
		params.identity = this.state.selectedRole
		params.service_name = this.state.selectedServiceType

		//params.account_id = '722932469181'
		//params.identity = "production-odyssey-role"
		//params.service_name = "iam-users"
		//params.identity = "devopsuser"

		this.props.getPrivileageAnalysis(params, (promise, privileageAnalysis) => {
			if (promise) {
				this.setState({ privileageAnalysis: privileageAnalysis.results, showLoading: false },
					() => {
						this.getAllowedTypeData('allowed_actions_not_done')
						this.privileageDoughnutGraph()
						this.categoryWiseDoughnutGraph()
					}
				)
			} else {
				this.setState({ privileageAnalysis: [] })
			}
		})
	}

	getAllowedTypeData = (actionType) => {	
		this.setState({ selectedAllowedType: actionType, privileage_doughnut_data: [], privileage_doughnut_options:[], category_doughnut_data: [], category_doughnut_options:[] },
			() => {
				this.privileageDoughnutGraph()
				this.categoryWiseDoughnutGraph()
			}
		)

		if(this.state.privileageAnalysis) { 
			Object.entries(this.state.privileageAnalysis).forEach(([key, value], index) => {
				if(key === actionType) {
					let totalArray = []
					let countArray = []
					value.forEach(prevData => {
						totalArray.push(prevData)
						let count = {}
						if(actionType === 'denied_actions_done' || actionType === 'allowed_actions_done') {
							count.service = prevData.service
							prevData.actions.forEach(action => {
								//action.service = prevData.service
								count.action = action.action
								count.count = action.count
								count.time = action.time
								countArray.push(count)
							})
						}
					})
					console.log(countArray)
					console.log(totalArray)
					this.setState({ 
							privileageTypeData: _.orderBy(totalArray, ['total_count'], ['desc']),
							privileageTotalCountData: _.orderBy(countArray, ['count'], ['desc'])
						},
						() =>this.privileageDoughnutGraph()
					)
				}
			})
		}		
	}

	privileageDoughnutGraph= () => {
		let graphData = this.state.privileageTypeData;
		console.log(this.state.privileageTypeData)
		let doughnutChartData = [];
		let doughnuGraphLabels = [];
		let backgroundColor = [];
		let hoverBackgroundColor = [];
		if(graphData && graphData.length){
			graphData.slice(0,10).forEach(privileageGraph => {
				// if(key > 9) {
				// 	break;
				// }
				//doughnuGraphLabels.push(catGraph.category+': '+catGraph.actions_allowed);
				doughnuGraphLabels.push(privileageGraph.service+': '+privileageGraph.total_count);
				doughnutChartData.push(privileageGraph.total_count);
				//backgroundColor.push(this.dynamicColors());
				//hoverBackgroundColor.push(this.dynamicColors());
			})
			backgroundColor = [
				'#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'
				];
			hoverBackgroundColor = [
				'#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'
				];
			
		} else {
			doughnuGraphLabels.push('No records');
			doughnutChartData.push(100);
			backgroundColor = ['#ececec'];
			hoverBackgroundColor = ['#ececec'];
		}
			
			
		let datasetsColumn = [{
			data: doughnutChartData,
			backgroundColor: backgroundColor,
			hoverBackgroundColor: hoverBackgroundColor,
			barThickness : 5,
		}];

		let doughnut_datas = {labels:doughnuGraphLabels, datasets: datasetsColumn}; 

		let doughnut_option = {
			tooltips: {
				callbacks: {
					label: function(tooltipItem, doughnutChartData) {
						//let value = doughnutChartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
						//let color = doughnutChartData.datasets[tooltipItem.datasetIndex].backgroundColor[tooltipItem.index]
						let label = doughnutChartData.labels[tooltipItem.index]
						return label;
					}
				}
			},
			cutoutPercentage: 50,
			animate   : true,
			animation: { duration: 1000 },
			legend: {
				display:true,
				position: 'right',
				labels: {
					boxWidth: 15,
					fontSize: 9
				}
			},

		}
		
		this.setState({
			privileage_doughnut_data:doughnut_datas, privileage_doughnut_options:doughnut_option, selectedPrivileageServiceType: 'All'},
			() => this.getPrivileageTypeDetails()
		)

		if(this.state.selectedAllowedType === 'allowed_actions_not_known' || this.state.selectedAllowedType === 'allowed_actions_not_done') {
			this.getActionsCategoryWise()
		} else {
			this.setState(
				{category_doughnut_data: [], category_doughnut_options: []}
			);
		}

		//doughnuGraphLabels = doughnuGraphLabels.slice(0, 8);

		
	}

	getActionsCategoryWise = () => {
		let params = {}
		params.provider = this.state.selectedProvider
		params.account_id = this.state.selectedAccount
		params.identity_type = this.state.selectedServiceType
		params.identity = this.state.selectedRole

		this.props.getActionsCategoryWise(params, (promise, actionsCategoryWise) => {
			if (promise) {
				this.setState({ actionsCategoryWise: _.orderBy(actionsCategoryWise.results, ['actions_allowed'], ['desc']) },
					() => this.categoryWiseDoughnutGraph()
				)
			} else {
				this.setState({ actionsCategoryWise: [] })
			}
		})
	}

	categoryWiseDoughnutGraph= () => {
		let graphData = this.state.actionsCategoryWise;
		let doughnutChartData = [];
		let doughnuGraphLabels = [];
		let backgroundColor = [];
		let hoverBackgroundColor = [];
		if(graphData && graphData.length){
			graphData.slice(0,10).forEach(categoryGraph => {
				// if(key > 9) {
				// 	break;
				// }
				//doughnuGraphLabels.push(categoryGraph.category+': '+categoryGraph.actions_allowed);
				doughnuGraphLabels.push(categoryGraph.category+': '+categoryGraph.actions_allowed);
				doughnutChartData.push(categoryGraph.actions_allowed);
				//backgroundColor.push(this.dynamicColors());
				//hoverBackgroundColor.push(this.dynamicColors());
			})
			
			backgroundColor = [
				'#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'
				];
			hoverBackgroundColor = [
				'#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'
				];
			
		} else {
			doughnuGraphLabels.push('No records');
			doughnutChartData.push(100);
			backgroundColor = ['#ececec'];
			hoverBackgroundColor = ['#ececec'];
		}			
			
		let datasetsColumn = [{
			data: doughnutChartData,
			backgroundColor: backgroundColor,
			hoverBackgroundColor: hoverBackgroundColor,
			barThickness : 5,
		}];
			
		let doughnut_datas = {labels:doughnuGraphLabels, datasets: datasetsColumn}; 

		let doughnut_option = {
			tooltips: {
				callbacks: {
					label: function(tooltipItem, doughnutChartData) {
						//let value = doughnutChartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
						//let color = doughnutChartData.datasets[tooltipItem.datasetIndex].backgroundColor[tooltipItem.index]
						let label = doughnutChartData.labels[tooltipItem.index]
						return label;
					}
				}
			},
			cutoutPercentage: 50,
			animate   : true,
			animation: { duration: 1000 },
			legend: {				
				display:true,
				position: 'right',
				labels: {
					boxWidth: 15,
					fontSize: 9
				}
			}
		}
		this.setState({category_doughnut_data:doughnut_datas, category_doughnut_options:doughnut_option});
	}

	getPrivileageTypeDetails = () => {
		let fitlerData = this.state.privileageTypeData;
		let countData = this.state.privileageTotalCountData
		
		if(this.state.selectedPrivileageServiceType === 'All' || this.state.selectedPrivileageServiceType === '') {
			if(this.state.selectedAllowedType === 'allowed_actions_not_done' || this.state.selectedAllowedType === 'allowed_actions_not_known') {
				this.setState({ privileageTypeFilterData: fitlerData, showLoading: false })
			} else {
				this.setState({ privileageTypeCountData: countData, showLoading: false })
			}
		} else {
			if(this.state.selectedAllowedType === 'allowed_actions_not_done' || this.state.selectedAllowedType === 'allowed_actions_not_known') {
				this.setState({ privileageTypeFilterData: fitlerData.filter(data => data.service === this.state.selectedPrivileageServiceType), showLoading: false })
			} else {
				this.setState({ privileageTypeCountData: countData.filter(data => data.service === this.state.selectedPrivileageServiceType), showLoading: false })
			}
		}
	}

	getSortByDetail = () => {
		let countData = this.state.privileageTotalCountData
		
		if(this.state.privileageSortBy === 'sortBy') {
			this.setState({ privileageTypeCountData: countData, showLoading: false })
		} else {
			let sortBy = '';
			let sortType = '';
			if(this.state.privileageSortBy === 'timeDesc') {
				sortBy = 'time';
				sortType = 'desc';
				// this.setState ({
				// 	sortOptions: [{ id: 'time', desc: true }],
				// })
			} else if(this.state.privileageSortBy === 'timeAsc') {
				sortBy = 'time';
				sortType = 'Asc';
				// this.setState ({
				// 	sortOptions: [{ id: 'time', desc: false }],
				// })
			} else if(this.state.privileageSortBy === 'countDesc') {
				sortBy = 'count';
				sortType = 'desc';
			} else if(this.state.privileageSortBy === 'countAsc') {
				sortBy = 'count';
				sortType = 'Asc';

			}
			this.setState({ privileageTypeCountData: _.orderBy(countData, [sortBy], [sortType]) })
		}
		
	}

	//privileage function end

	//anomaly behaviour start
	
	getAnomalyDetails = () => {
		let params = {}
		params.provider = this.state.selectedProvider
		params.account_id = this.state.selectedAccount
		params.identity_type  = this.state.selectedServiceType
		params.identity = this.state.selectedRole
		//params.identity = "production-odyssey-role"
		
		this.props.getAnomalyDetails(params, (promise, anomalyDetails) => {
			if (promise) {
				this.setState({ anomalyDetails: anomalyDetails.results, showLoading: false })
			} else {
				this.setState({ anomalyDetails: [] })
			}
		})
	}

	//anomaly behaviour end
	
	//access function start	
	
	getFrequentSelectedData = () => {
		let params = {}
		
		params.provider = this.state.selectedProvider
		params.account_id = this.state.selectedAccount
		params.identity_type = this.state.selectedServiceType
		params.identity = this.state.selectedRole
		params.group_by = this.state.selectedFrequentlyData
		if(this.state.selectedFrequentlyData === 'tags') {
			params.tags = [{"key": this.state.selectedFrequentlyTag}]
			this.setState({ selectedFreqValue: this.state.selectedFrequentlyTag  })
		} else {
			this.setState({ selectedFreqValue: this.state.selectedFrequentlyData })
		}
		params.duration = '+90d'
		//params.duration = this.state.selectedDuration
		//params.identity = "production-odyssey-role"
		this.props.listFrequentlyAccessed(params, (promise, frequentlyAccessed) => {
			//console.log('frequentlyAccessed', frequentlyAccessed)
			if (promise) {
				this.setState({ frequentlyAccessed:  _.orderBy(frequentlyAccessed.results, ['count'], ['desc']), showLoading: false },
					() => {
						this.frequentDoughnutGraph()
					}
				)
			} else {
				this.setState({ frequentlyAccessed: [] })
			}
		})	
	}

	frequentDoughnutGraph= () => {
		let graphData = this.state.frequentlyAccessed;
		let doughnutChartData = [];
		let doughnuGraphLabels = [];
		let backgroundColor = [];
		let hoverBackgroundColor = [];
		if(graphData && graphData.length){
			graphData.slice(0,10).forEach(freqGraph => {
				doughnuGraphLabels.push(freqGraph.data +': '+freqGraph.event_count);
				doughnutChartData.push(freqGraph.event_count);
			})

			backgroundColor = [
				'#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'
				];
			hoverBackgroundColor = [
				'#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'
				];
	
		} else {
			doughnuGraphLabels.push('No records');
			doughnutChartData.push(100);
			backgroundColor = ['#ececec'];
			hoverBackgroundColor = ['#ececec'];
		}

		let datasetsColumn = [{
			data: doughnutChartData,
			backgroundColor: backgroundColor,
			hoverBackgroundColor: hoverBackgroundColor,
			barThickness : 5,
		}];
		let legend = true;
		if(this.state.selectedFreqValue === 'asset') {
			legend = false;
		}

		let doughnut_datas = {labels:doughnuGraphLabels, datasets: datasetsColumn}; 

		let doughnut_option = {
			tooltips: {
				callbacks: {
					label: function(tooltipItem, doughnutChartData) {
						//let value = doughnutChartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
						//let color = doughnutChartData.datasets[tooltipItem.datasetIndex].backgroundColor[tooltipItem.index]
						let label = doughnutChartData.labels[tooltipItem.index]
						return label;
					}
				}
			},
			cutoutPercentage: 50,
			animate   : true,
			animation: { duration: 1000 },
			legend: {							
				display: legend,
				position: 'right',
				labels: {
					boxWidth: 15,
					fontSize: 9
				}
			}			
		}
		this.setState({frequently_doughnut_data:doughnut_datas, frequently_doughnut_options:doughnut_option});

	}

	//access function end

	//denied functions starts

	//denied doughnut
	getDeniedServiceWise = () => {
		let params = {}
		params.provider = this.state.selectedProvider
		params.account_id = this.state.selectedAccount
		params.identity_type = this.state.selectedServiceType
		params.identity = this.state.selectedRole
		params.duration = '+90d'
		//params.duration = this.state.selectedDuration
		//params.identity = "production-odyssey-role"
		this.props.getDeniedServiceWise(params, (promise, deniedServiceWise) => {
			if (promise) {
				this.setState({ deniedServiceWise: deniedServiceWise.results, showLoading:false },
					() => {
						this.deniedDoughnutGraph()
						this.getRecentBehavior()
						this.getDaywiseDenied()							
					}
				)
			} else {
				this.setState({ deniedServiceWise: [] })
			}
		})
	}

	deniedDoughnutGraph= () => {
		let graphData = this.state.deniedServiceWise;
		let doughnutChartData = [];
		let doughnuGraphLabels = [];
		let backgroundColor = [];
		let hoverBackgroundColor = [];
		if(graphData && graphData.length){
			graphData.slice(0,10).forEach(deniedGraph => {
				doughnuGraphLabels.push(deniedGraph.service+': '+deniedGraph.count);
				doughnutChartData.push(deniedGraph.count);
			})
			
			backgroundColor = [
				'#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'
				];
			hoverBackgroundColor = [
				'#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'
				];
		} else {
			doughnuGraphLabels.push('No records');
			doughnutChartData.push(100);
			backgroundColor = ['#ececec'];
			hoverBackgroundColor = ['#ececec'];
		}
			
		let datasetsColumn = [{
			data: doughnutChartData,
			backgroundColor: backgroundColor,
			hoverBackgroundColor: hoverBackgroundColor,
			barThickness : 5,
		}];

		let doughnut_datas = {labels:doughnuGraphLabels, datasets: datasetsColumn}; 

		let doughnut_option = {
			tooltips: {
				callbacks: {
					label: function(tooltipItem, doughnutChartData) {
						//let value = doughnutChartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
						//let color = doughnutChartData.datasets[tooltipItem.datasetIndex].backgroundColor[tooltipItem.index]
						let label = doughnutChartData.labels[tooltipItem.index]
						return label;
					}
				}
			},
			cutoutPercentage: 50,
			animate   : true,
			animation: { duration: 1000 },
			legend: {				
				display:true,
				position: 'right',
				labels: {
					boxWidth: 15,
					fontSize: 9
				}
			}
		}
		this.setState({denied_doughnut_data:doughnut_datas, denied_doughnut_options:doughnut_option});
	}
	
	//denied bar/line graph
	getDaywiseDenied = () => {
		let params = {}
		params.provider = this.state.selectedProvider
		params.account_id = this.state.selectedAccount
		params.identity_type = this.state.selectedServiceType
		params.identity = this.state.selectedRole
		params.duration = '+30d'
		//params.duration = this.state.selectedDuration
		//params.identity = "production-odyssey-role"

		this.props.getDaywiseDenied(params, (promise, daywiseDenied) => {
			if (promise) {
				this.setState({ daywiseDenied: daywiseDenied.results},
					() => this.daywiseDeniedGraph()
				)
			} else {
				this.setState({ daywiseDenied: [] })
			}
		})
	}

	daywiseDeniedGraph = () => {
		let graphData = this.state.daywiseDenied.trend;
		//let deniedTotal =  this.state.daywiseDenied.total
				
		let summaryGraphLabelsData = [];
		let dailyChecksData = [];
		let barChartData = {};
		
		if(graphData) {	
			if(graphData.labels){
				graphData.labels.forEach(value => {
                    summaryGraphLabelsData.push(moment(value).format('DD MMM'))
                })
			}

			let deniedData = [];

			deniedData = graphData.data.denied
			
			// for(let x=0;x<=graphLabels.length;x++) {
			// 	summaryGraphLabelsData.push(moment(graphLabels[x]).format('DD MMM'))
			// }

			barChartData = {
				name: 'denied',
				data: deniedData
			};
		}

		dailyChecksData.push(barChartData)
		
		let backgroundColor = "#BA8FEB"
		let grayColor = '#BDBDBD'
		const barChartOptions = {
			tooltip: {
				enabled: true,
				//enabledOnSeries: undefined,
				//shared: false,
				//followCursor: false,
				//intersect: false,
				//inverseOrder: false,
				custom: function({series, seriesIndex, dataPointIndex, w}) {
				let val  = series[seriesIndex][dataPointIndex];
				val = Math.abs(Math.round(val))
				//let labelName = w.globals.initialSeries[seriesIndex].name
				//let val = str.replace('-',series[seriesIndex][dataPointIndex]);
				return '<div className="arrow_box">' +
					'<span> '+ w.globals.labels[dataPointIndex] + 
					' <span style="color:'+ w.globals.colors[seriesIndex] +'"> </span> ' + val + ' </span>' +
					'</div>'
				},
				//fillSeriesColor: false,
				//theme: true,
				style: {
				fontSize: '9px',
				},
				onDatasetHover: {
					highlightDataSeries: true,
				},
				x: {
					//show: true,
					format: 'dd MMM',
					formatter: undefined,
				},
				y: {
					show: true,
					format: 'dd',
					//formatter: (value) => { return value },
					formatter: undefined,
					title: {
						formatter: (seriesName) => seriesName,
					},
				},
				z: {
					formatter: undefined,
					title: 'Size: 9px'
				},
				marker: {
					show: true,
				},
				fixed: {
					enabled: false,
					position: 'topRight',
					offsetX: 0,
					offsetY: 0,
				},
			},
			legend: {
				position: 'bottom',
				fontSize: '11px',
				markers: {
					width: 9,
					height: 9,
					strokeWidth: 0,
					strokeColor: '#fff',
					fillColors: undefined,
					radius: 9,
					customHTML: undefined,
					onClick: undefined,
					offsetX: 0,
					offsetY: 0
				}
			},	
			chart: {
				toolbar: {
					show: false,
				},
				sparkline: {
					enabled: true
				},
				type: 'bar',
				height: 200,
				stacked: false,
			},
			//colors: ['#1FCCE2'],<
			colors: [backgroundColor],
	
			plotOptions: {
			bar: {
				horizontal: false,
				barHeight: '40%',
				startingShape: 'rounded',
				endingShape: 'rounded',
				columnWidth: '60%',
				distributed: false,
				rangeBarOverlap: false,
				colors: {
					backgroundBarColors: [grayColor],
					backgroundBarOpacity: 1,
					backgroundBarRadius: 4,
				}        
			},          
			},
			dataLabels: {
			enabled: false
			},
			stroke: {
			width: 0,
			colors: ["#fff"]
			},
			
			grid: {  				        
				xaxis: {
					lines: {
					show: false
					},
				},
				yaxis: {
					lines: {
					show: false
					}
				},
			},
			yaxis: {
				labels: {
					show:true,
					style: {
						fontSize: '9px'
					}
				},
				axisBorder: {
					show: false
				},
				axisTicks: {
					fontSize: '9px',
					show: false,
					borderType: 'none',
					color: '#78909C',
					width: 6,
					offsetX: 0,
					offsetY: 0
				},
			},
			xaxis: {				
				axisBorder: {
					show: false
				},
				axisTicks: {
					tickAmount: 15,
					fontSize: '9px',
					show: false,
					borderType: 'none',
					color: '#78909C',
					width: 6,
					offsetX: 0,
					offsetY: 0
				},
				categories: summaryGraphLabelsData,
				labels: {
					show:false,
					style: {
						fontSize: '9px'
					}
				}
			},
		}

		this.setState({ denied_bar_data:dailyChecksData, denied_bar_options:barChartOptions, showLoading: false});
	}

	//denied table
	getRecentBehavior = () => {
		let params = {}
		params.provider = this.state.selectedProvider
		params.account_id = this.state.selectedAccount
		params.identity_type  = this.state.selectedServiceType
		params.identity = this.state.selectedRole
		//params.identity = "production-odyssey-role"

		this.props.getRecentBehavior(params, (promise, recentBehavior) => {
			if (promise) {
				this.setState({ recentBehavior: recentBehavior.results, showLoading: false })
			} else {
				this.setState({ recentBehavior: [] })
			}
		})
	}

	//denied functions ends


	//checks functions start

	uiGetTotalCount = () => {
		let params = {}
		params.provider = this.state.selectedProvider
		params.account_id = this.state.selectedAccount
		params.aggregate_by = 'risk'
		params.duration = '+80d'
		params.resource_id = this.state.selectedRole
		//params.duration = this.state.selectedDuration
		//params.account_id = "740250289965"
		//params.resource_id = 'test_temp'
		this.props.getTotalCount(params, (promise, checksTotalCount) => {
			if (promise) {
				this.setState({ 
						checksTotalCount: _.orderBy(checksTotalCount, ['fail_count'], ['desc']),
						showLoading: false
					},
					() => {
						this.cheksDoughnutGraph()
						this.getIdentifierResults()
						this.uiGetDayWiseCount()
					}
				)
			} else {
				this.setState({ checksTotalCount: [] })
			}
		})		
	}

	cheksDoughnutGraph= () => {
		let graphData = this.state.checksTotalCount;
		let doughnutChartData = [];
		let doughnuGraphLabels = [];
		let backgroundColor = [];
		let hoverBackgroundColor = [];
		if(graphData && graphData.length){
			graphData.slice(0,10).forEach(checksGraph => {
				doughnuGraphLabels.push(checksGraph.risk+': '+checksGraph.fail_count);
				doughnutChartData.push(checksGraph.fail_count);
			})
			backgroundColor = [
				'#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'
				];
			hoverBackgroundColor = [
				'#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'
				];
			
		} else {
			doughnuGraphLabels.push('No records');
			doughnutChartData.push(100);
			backgroundColor = ['#ececec'];
			hoverBackgroundColor = ['#ececec'];
		}

		let datasetsColumn = [{
			data: doughnutChartData,
			backgroundColor: backgroundColor,
			hoverBackgroundColor: hoverBackgroundColor,
			barThickness : 5,
		}];

		let doughnut_datas = {labels:doughnuGraphLabels, datasets: datasetsColumn}; 

		let doughnut_option = {
			tooltips: {
				callbacks: {
					label: function(tooltipItem, doughnutChartData) {
						//let value = doughnutChartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
						//let color = doughnutChartData.datasets[tooltipItem.datasetIndex].backgroundColor[tooltipItem.index]
						let label = doughnutChartData.labels[tooltipItem.index]
						return label;
					}
				}
			},
			cutoutPercentage: 50,
			animate   : true,
			animation: { duration: 1000 },
			legend: {				
				display:true,
				position: 'right',
				labels: {
					boxWidth: 15,
					fontSize: 9
				}
			}
		}

		this.setState({checks_doughnut_data:doughnut_datas, checks_doughnut_options:doughnut_option});
	}
	
	uiGetDayWiseCount = () => {
		let params = {}
        
		params.provider = this.state.selectedProvider
		params.account_id = this.state.selectedAccount
		params.duration = '+30d'
		params.resource_id = this.state.selectedRole
		//params.resource_id = 'test_temp'
		//params.duration = this.state.selectedDuration
		
		this.props.getDayWiseCount(params, (promise, checksDaywiseCounts) => {
			if (promise) {
				this.setState({ checksDaywiseCounts },
					() => this.checkdDaywiseGraph()
				)
			} else {
				this.setState({ checksDaywiseCounts: [] })
			}
		})		
	}
	
	checkdDaywiseGraph = () => {
		let graphData = this.state.checksDaywiseCounts.data;
		//let graphLabels = this.state.checksDaywiseCounts.labels;
				
		let summaryGraphLabelsData = [];
		let dailyChecksData = [];
		let barChartData = {};
		
		if(graphData) {	
			// let passData = [];
			let failData = [];
			//let labels = [];
			//let passSum = 0;
			//let failSum = 0;

			// for(let x=0;x<=graphLabels.length;x++) {
			// 	summaryGraphLabelsData.push(moment(graphLabels[x]).format('DD MMM'))
			// }

			for (const [key, value] of Object.entries(graphData)) {
				if(key === 'fail') {
				 	failData =  value;
				// 	// failSum = failData.reduce(function(a, b){
				// 	//     return a + b;
				// 	// }, 0);
				// } else {
				// 	passData =  value;
				// 	// passSum = passData.reduce(function(a, b){
				// 	// 	return a + b;
				// 	// }, 0);
				}
			}
			barChartData = {
				name: 'fail',
				data: failData
			};
		}

		dailyChecksData.push(barChartData)
		
		let backgroundColor = "#BA8FEB"
		let grayColor = '#BDBDBD'
		const barChartOptions = {
			tooltip: {
				enabled: true,
				//enabledOnSeries: undefined,
				//shared: false,
				//followCursor: false,
				//intersect: false,
				//inverseOrder: false,
				custom: function({series, seriesIndex, dataPointIndex, w}) {
				let val  = series[seriesIndex][dataPointIndex];
				val = Math.abs(Math.round(val))
				//let labelName = w.globals.initialSeries[seriesIndex].name
				//let val = str.replace('-',series[seriesIndex][dataPointIndex]);
				return '<div className="arrow_box">' +
					'<span> '+ w.globals.labels[dataPointIndex] + 
					' <span style="color:'+ w.globals.colors[seriesIndex] +'"> </span> ' + val + ' </span>' +
					'</div>'
				},
				//fillSeriesColor: false,
				//theme: true,
				style: {
				fontSize: '9px',
				},
				onDatasetHover: {
					highlightDataSeries: true,
				},
				x: {
					//show: true,
					format: 'dd MMM',
					formatter: undefined,
				},
				y: {
					show: true,
					format: 'dd',
					//formatter: (value) => { return value },
					formatter: undefined,
					title: {
						formatter: (seriesName) => seriesName,
					},
				},
				z: {
					formatter: undefined,
					title: 'Size: 9px'
				},
				marker: {
					show: true,
				},
				fixed: {
					enabled: false,
					position: 'topRight',
					offsetX: 0,
					offsetY: 0,
				},
			},
			legend: {
				position: 'bottom',
				fontSize: '11px',
				markers: {
					width: 9,
					height: 9,
					strokeWidth: 0,
					strokeColor: '#fff',
					fillColors: undefined,
					radius: 9,
					customHTML: undefined,
					onClick: undefined,
					offsetX: 0,
					offsetY: 0
				}
			},	
			chart: {
				toolbar: {
					show: false,
				},
				sparkline: {
					enabled: true
				},
				type: 'bar',
				height: 200,
				stacked: false,
			},
			//colors: ['#1FCCE2'],<
			colors: [backgroundColor],
	
			plotOptions: {
			bar: {
				horizontal: false,
				barHeight: '40%',
				startingShape: 'rounded',
				endingShape: 'rounded',
				columnWidth: '60%',
				distributed: false,
				rangeBarOverlap: false,
				colors: {
					backgroundBarColors: [grayColor],
					backgroundBarOpacity: 1,
					backgroundBarRadius: 4,
				}        
			},          
			},
			dataLabels: {
			enabled: false
			},
			stroke: {
			width: 0,
			colors: ["#fff"]
			},
			
			grid: {  				        
			xaxis: {
				lines: {
				show: false
				},
			},
			yaxis: {
				lines: {
				show: false
				}
			},
			},
			yaxis: {
				labels: {
					show:false,
					style: {
						fontSize: '9px'
					}
				},
				axisBorder: {
					show: false
				},
				axisTicks: {
					fontSize: '9px',
					show: false,
					borderType: 'none',
					color: '#78909C',
					width: 6,
					offsetX: 0,
					offsetY: 0
				},
			},
			xaxis: {				
				axisBorder: {
					show: false
				},
				axisTicks: {
					tickAmount: 15,
					fontSize: '9px',
					show: false,
					borderType: 'none',
					color: '#78909C',
					width: 6,
					offsetX: 0,
					offsetY: 0
				},
				categories: summaryGraphLabelsData,
				labels: {
					show:false,
					style: {
						fontSize: '9px'
					}
				}
			},
		}

		this.setState({checks_bar_data:dailyChecksData, checks_bar_options:barChartOptions, showLoading: false});
	}

	getIdentifierResults = () => {
		let params = {}
		params.provider = this.state.selectedProvider
		params.account_id = this.state.selectedAccount
		params.service_name = this.state.selectedServiceType
		params.resource_id = this.state.selectedRole
		params.duration = '+30d'
		//params.identity = "production-odyssey-role"
		//params.account_id = "740250289965"
		//params.duration = this.state.selectedDuration
		//params.resource_id = "test_temp"
		this.props.getIdentifierResults(params, (promise, identifierResults) => {
			if (promise) {
				for (const [key] of Object.entries(identifierResults)) {
					if(key === 'results') {
						this.setState({ identifierResults: identifierResults.results, identifierFilterResults: identifierResults.results, showLoading:false })						
					} else {
						this.setState({ identifierResults: [] })
					}
				}
			} else {
				this.setState({ identifierResults: [] })
			}
		})
	}

	checksTableFilter = () => {
		let filterData = this.state.identifierResults
		let risk = this.state.selectedChecksRisk
		let status = this.state.selectedChecksStatus
		let result = filterData;
		if(risk !== '') {
			result = result.filter(data => data.risk === risk)
		}

		if(status !== '') {
			result = result.filter(data => data.status === status)
		}

		this.setState({ identifierFilterResults: result })
	}

	//checks function end


	//resources function start
	
	getUserRelatedIssues = aggregateBy => {
		let params = {}

		params.provider = this.state.selectedProvider
		params.account_id = this.state.selectedAccount
		params.identity_type = this.state.selectedServiceType
		params.identity = this.state.selectedRole
		params.duration = "+80d"
		//params.identity = "production-odyssey-role"
		//params.duration = this.state.selectedDuration

		// params.provider = "aws"
		// params.account_id = "666665886686"		
		// params.identity_type = "iam-users"
		// params.identity = "sandip"
		// params.duration = "+80d"

		if(aggregateBy === 'resource_type') {
			params.aggregate_by = "resource_type"
		} else if(aggregateBy === 'risk') {
			params.aggregate_by = ["resource_type", "risk"]
		}
		

		this.props.getUserRelatedIssues(params, (promise, userRelatedIssues) => {
			if (promise) {
				if(aggregateBy === 'resource_type') {
					this.setState({ resourceGraph: _.orderBy(userRelatedIssues, ['fail_count'], ['desc']) },
						() => {
							this.relatedIssuesDoughnutGraph()
							this.getUserRelatedIssues('risk')
						}
					)
				} else if(aggregateBy === 'risk') {
					this.setState({ resourceRisk: userRelatedIssues },
						() => {
							this.getRelatedRisks()
						}
					)
				}
				
			} else {
				if(aggregateBy === 'resource_type') {
					this.setState({ resourceGraph: [] })
				} else if(aggregateBy === 'risk') {
					this.setState({ resourceRisk: [] })
				}
				
			}
		})
	}

	relatedIssuesDoughnutGraph= () => {
		let graphData = this.state.resourceGraph;
		let doughnutChartData = [];
		let doughnuGraphLabels = [];
		let backgroundColor = [];
		let hoverBackgroundColor = [];
		if(graphData && graphData.length){
			graphData.slice(0,10).forEach(relatedIssueGraph => {
				doughnuGraphLabels.push(relatedIssueGraph.resource_type+': '+relatedIssueGraph.fail_count);
				doughnutChartData.push(relatedIssueGraph.fail_count);
			})

			backgroundColor = [
				'#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'
				];
			hoverBackgroundColor = [
				'#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'
				];
			
		} else {
			doughnuGraphLabels.push('No records');
			doughnutChartData.push(100);
			backgroundColor = ['#ececec'];
			hoverBackgroundColor = ['#ececec'];
		}
			
			
		let datasetsColumn = [{
			data: doughnutChartData,
			backgroundColor: backgroundColor,
			hoverBackgroundColor: hoverBackgroundColor,
			barThickness : 5,
		}];
			

		let doughnut_datas = {labels:doughnuGraphLabels, datasets: datasetsColumn}; 

		let doughnut_option = {
			tooltips: {
				callbacks: {
					label: function(tooltipItem, doughnutChartData) {
						//let value = doughnutChartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
						//let color = doughnutChartData.datasets[tooltipItem.datasetIndex].backgroundColor[tooltipItem.index]
						let label = doughnutChartData.labels[tooltipItem.index]
						return label;
					}
				}
			},
			cutoutPercentage: 50,
			animate   : true,
			animation: { duration: 1000 },
			legend: {				
				display:true,
				position: 'right',
				labels: {
					boxWidth: 15,
					fontSize: 9
				}
			}
		}
		this.setState({resource_doughnut_data:doughnut_datas, resource_doughnut_options:doughnut_option});
	}

	getRelatedRisks = () => {
		let riskArray = []
		if(Object.entries(this.state.resourceRisk).length) {
			const groups = _.groupBy(this.state.resourceRisk, 'resource_type');
			Object.entries(groups).forEach(([key, value])=>{
				let riskObject = {}
				let totalFail = 0;
				riskObject.name = key
				value.forEach(risk => {
					totalFail = totalFail + risk.fail_count
					if(risk.risk === 'Critical') {
						riskObject.Critical = risk.fail_count
					} else if(risk.risk === 'High') {
						riskObject.High = risk.fail_count
					} else if(risk.risk === 'Medium') {
						riskObject.Medium = risk.fail_count
					} else if(risk.risk === 'Low') {
						riskObject.Low = risk.fail_count
					}
				})

				if(riskObject.Critical === undefined) {
					riskObject.Critical = 0
				}
				if(riskObject.High === undefined) {
					riskObject.High = 0
				}
				if(riskObject.Medium === undefined) {
					riskObject.Medium = 0
				}
				if(riskObject.Low === undefined) {
					riskObject.Low = 0
				}
				riskObject.totalFail = totalFail
				riskArray.push(riskObject)
			})
		} else {
			let riskObject = {}
			riskObject.name = 'No Record'
			riskObject.Critical = 0
			riskObject.High = 0
			riskObject.Medium = 0
			riskObject.Low = 0
			riskObject.totalFail = 0
			riskArray.push(riskObject)
		}
		this.setState({ relatedRisks:  _.orderBy(riskArray, ['totalFail'], ['desc']), showLoading: false},
			() => this.getRelatedIdentifierResults()
		)
	}

	resourceTableFilter = () => {
		let filterData = this.state.relatedIdentifierResults
		let risk = this.state.selectedResourceRisk
		let status = this.state.selectedResourceStatus
		let result = filterData;
		if(risk !== '') {
			result = result.filter(data => data.risk === risk)
		}

		if(status !== '') {
			result = result.filter(data => data.status === status)
		}

		this.setState({ relatedIdentifierFitlerResults: result })
	}

	getRelatedIdentifierResults = () => {
		let params = {}
		this.setState({ selectedResourceRisk: '', selectedResourceStatus:'' })
		params.provider = this.state.selectedProvider
		params.account_id = this.state.selectedAccount
		params.identity_type = this.state.selectedServiceType
		params.identity = this.state.selectedRole
		params.duration = "+80d"
		//params.identity = "production-odyssey-role"
		//params.duration = this.state.selectedDuration
		if(this.state.selectedResourceType === '') {
			if(this.state.relatedRisks.length) {
				this.setState({ selectedResourceType: this.state.relatedRisks[0].name})
				params.resource_type = this.state.relatedRisks[0].name
			}
		} else {
			params.resource_type = this.state.selectedResourceType
		}

		// params.provider = "aws"
		// params.account_id = "666665886686"
		// params.identity_type = "iam-users"
		// params.identity = "sandip"
		// params.duration = "+80d"
		this.props.getRelatedIdentifierResults(params, (promise, relatedIdentifierResults) => {
			if (promise) {
				if(relatedIdentifierResults.results) {
					this.setState({ relatedIdentifierResults: relatedIdentifierResults.results, relatedIdentifierFitlerResults: relatedIdentifierResults.results})
				} else {
					this.setState({ relatedIdentifierResults: [], relatedIdentifierFitlerResults: []})
				}
			} else {
				this.setState({ relatedIdentifierResults: [], relatedIdentifierFitlerResults: [] })
			}
		})
	}

	//resource function end
	
	render() {		
		return (
			<div className="advanced-search" style={{zIndex:9999999}}>
				<div className="search-content">
					<div className="header-search bd-highlight">
						<div className="flex-fill bd-highlight">
						<h5>{capitalizeFirstLetter(this.state.selectedRole)}</h5>
						<div className="mr-2 d-flex">
								<div className="mr-3">
									<p className="m-0 small">Created Date: {this.state.selectedCreatedAt}</p>
								</div>
								<div className="mr-3">
									<p className="m-0 pl-2 small">Last Updated:  {this.state.selectedModifiedAt}</p>
								</div>
								<div>
									<p className="m-0 pl-2 small">Policies: {this.state.selectedMangedPoliciesCount}</p>
								</div>
							</div>
						</div>
						{/* <div className="mr-3 align-self-center">
							<h5 className="font-weight-bold mb-0 text-center">{this.state.complianceScore}</h5>
							<p className="font-weight-bold mb-0">Score</p>
						</div>
						<div>
							<div className="box bg-light justify-content-between d-flex p-2 shadow-none">
								<div className="mr-3">
									<h5 className="font-weight-bold mb-0 text-success">{numberFormater(this.state.totalPassSum)}</h5>
									<p className="font-weight-bold mb-0 text-dark text-center">Pass</p>
								</div>
								<div className="mr-3">
									<h5 className="font-weight-bold mb-0 text-danger">{numberFormater(this.state.totalFailSum)}</h5>
									<p className="font-weight-bold mb-0 text-dark text-center">Fail</p>
								</div>
								<div>
									<h5 className="font-weight-bold mb-0 text-dark">234</h5>
									<p className="font-weight-bold mb-0 text-dark text-center">NA</p>
								</div>
							</div>
						</div> */}
						<div className="text-right flex-fill bd-highlight ml-2 align-self-center">
						<i className="far fa-times" onClick={() => this.props.closeViewSidePanel()}></i>
						</div>
					</div>
					<div className="overflow-auto">
						<ul className="nav nav-tabs bd-highlight" id="myTab" role="tablist">
							<li className="nav-item">
								<a
									className={`nav-link cursor-pointer ${this.state.activeTab === 0 ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
									style={{fontSize:'12px'}}
									onClick={() => this.setState(
										{ activeTab: 0, showLoading:true},
										
										() => {
											if(Object.entries(this.state.privileageAnalysis).length === 0)  {
												this.getPrivileageAnalysis()
											} else {
												this.setState({ showLoading:false })
											}
										}
									)}
									href={() => false}
								>
								Privilege
								</a>
							</li>
							<li className="nav-item">
								<a
									className={`nav-link cursor-pointer ${this.state.activeTab === 1 ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
									style={{fontSize:'12px'}}
									onClick={() => this.setState({ activeTab: 1, showLoading:true}, 
									() => {
											if(this.state.anomalyDetails.length === 0) {
												this.getAnomalyDetails()
											} else {
												this.setState({ showLoading:false })
											}
										}
									)}
									href={() => false}
								>
									Behaviour
								</a>
							</li>
							<li className="nav-item">
								<a
									className={`nav-link cursor-pointer ${this.state.activeTab === 2 ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
									style={{fontSize:'12px'}}
									onClick={() => this.setState({ activeTab: 2, showLoading:true}, 
									() => {
										if(this.state.frequentlyAccessed.length === 0) {
											this.getFrequentSelectedData()
										} else {
											this.setState({ showLoading:false })
										}
									})}
									href={() => false}
								>
								Access
								</a>
							</li>
							<li className="nav-item">
								<a
									className={`nav-link cursor-pointer ${this.state.activeTab === 3 ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
									style={{fontSize:'12px'}}
									onClick={() => this.setState({ activeTab: 3, showLoading:true}, 
									() => {
										if(this.state.deniedServiceWise.length === 0) {
											this.getDeniedServiceWise()
										} else {
											this.setState({ showLoading:false })
										}
									})}
									href={() => false}
								>
								Denied
								</a>
							</li>
							<li className="nav-item">
								<a
									className={`nav-link cursor-pointer ${this.state.activeTab === 4 ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
									style={{fontSize:'12px'}}
									onClick={() => this.setState({ activeTab: 4, showLoading:true}, 
									() => {
										if(this.state.identifierFilterResults.length === 0) {
											this.uiGetTotalCount()
										} else {
											this.setState({ showLoading:false })
										}
									})}
									href={() => false}
								>
								Checks
								</a>
							</li>
							<li className="nav-item">
								<a
									className={`nav-link cursor-pointer ${this.state.activeTab === 5 ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
									style={{fontSize:'12px'}}
									onClick={() => this.setState({ activeTab: 5, showLoading:false}, 
									() => {
										if(this.state.resourceGraph.length === 0) {
											this.getUserRelatedIssues('resource_type')
										} else {
											this.setState({ showLoading:false })
										}
									})}
									href={() => false}
								>
								Resources
								</a>
							</li>
						</ul>
						{
							!this.state.showLoading?
							<div className="tab-content" id="myTabContent">
								<div className="tab-pane fade show active" id="assets" role="tabpanel"
									aria-labelledby="insights-tab">
									{										
										this.state.activeTab === 0&&
										<div className="col-sm-12">
											<p className="mb-1 f11">Results of Top 10 Resources that are contributing to most failed checks in your cloud infrastructure. Status by Fail and Risk count are shown
											</p>
											<p className="mb-1 text-muted small">
												Privilege Analysis was done on <b>{this.state.privileageAnalysis && this.state.privileageAnalysis.analysis_on ? moment(this.state.privileageAnalysis.analysis_on).format('YYYY-MM-DD hh:mm') : null } </b> for a duration between <b>{this.state.privileageAnalysis && this.state.privileageAnalysis.log_period_from ? moment(this.state.privileageAnalysis.log_period_from).format('YYYY-MM-DD hh:mm') : null } </b> to <b>{this.state.privileageAnalysis && this.state.privileageAnalysis.log_period_to ? moment(this.state.privileageAnalysis.log_period_to).format('YYYY-MM-DD hh:mm') : null } </b>
											</p>

											<div className='container-fluid'>
												<div className='row mb-3 mt-3 pt-2 f14 box bg-light'>
													<div className="col-sm-6 mb-2">
														<p className="mb-2 f12 d-flex">
															{/* <span className="d-block text-highlight mr-2">{(this.state.processCount.event_id)}</span> */}
															<span className='d-block text-muted font-weight-bolder'><b className='text-primary f18'>{this.state.privileageAnalysis.allowed_not_done_count}</b> Actions Allowed in IAM Policy but NOT performed in Cloud <span className="text-info cursorPointer" onClick={() => this.getAllowedTypeData('allowed_actions_not_done')} >click here</span></span>
														</p>
													</div>
													<div className="col-sm-6 mb-2">
														<p className="mb-2 f12 d-flex">
														<span className='d-block text-muted font-weight-bolder'><b className='text-primary f18'>{this.state.privileageAnalysis.not_allowed_count}</b> Actions NOT allowed in IAM policy and Denied while performed in Cloud <span className="text-info cursorPointer" onClick={() => this.getAllowedTypeData('denied_actions_done')} >click here</span></span>
														</p>
													</div>
													<div className="col-sm-6 mb-2">
														<p className="mb-2 f12 d-flex">
															<span className='d-block text-muted font-weight-bolder'><b className='text-primary f18'>{this.state.privileageAnalysis.allowed_not_known_count}</b> Actions Allowed in IAM Policy but NOT tracked in Audit Trail <span className="text-info cursorPointer" onClick={() => this.getAllowedTypeData('allowed_actions_not_known')} >click here</span></span>
														</p>
													</div>
													<div className="col-sm-6 mb-2">
														<p className="mb-2 f12 d-flex">
															<span className='d-block text-muted font-weight-bolder'><b className='text-primary f18'>{this.state.privileageAnalysis.allowed_done_count}</b> Actions Allowed in IAM Policy and performed in Cloud <span className="text-info cursorPointer" onClick={() => this.getAllowedTypeData('allowed_actions_done')} >click here</span></span>
														</p>
													</div>
												</div>
											</div>

											{/* {this.state.showSpinner ?
												<div className='text-center mt-5' >
													<Spinner color='dark' size='lg' />
												</div>
											: null
											} */}
											
											<div className='row mb-3'>
												<div className="col-sm-12 align-self-center">
													<p className="font-weight-bold mb-1  text-muted">
														{this.state.selectedAllowedType && this.state.selectedAllowedType === 'allowed_actions_not_done' ? ' Actions Allowed in IAM Policy but NOT performed in Cloud' : this.state.selectedAllowedType === 'denied_actions_done' ? ' Action NOT allowed in IAM policy and Denied while performed in Cloud' : this.state.selectedAllowedType === 'allowed_actions_not_known' ? ' Action Allowed in IAM Policy but NOT tracked in Audit Trail' : ' Actions Allowed in IAM Policy and performed in Cloud' }
													</p>
												</div>
												<div className="col-sm-6 align-self-center border-right">
													<p className='mt-2 mb-2'><b>By Service</b></p>
												</div>
												<div className="col-sm-6 align-self-center">
													{(this.state.selectedAllowedType === 'allowed_actions_done' ||  this.state.selectedAllowedType === 'denied_actions_done') ?
														<p className='mt-2 mb-2'><b>Observation</b></p>
														:
														<p className='mt-2 mb-2'><b>By Category</b></p>
													}
												</div>
												<div className="col-sm-6 align-self-center  border-right pt-3 ">
													{this.state.privileage_doughnut_data ?
													
														<Doughnut data={this.state.privileage_doughnut_data} options={this.state.privileage_doughnut_options} width={100} height={70}/>
													: null
													}
												</div>
												<div className="col-sm-6 align-self-center pt-3 ">
													{(this.state.selectedAllowedType === 'allowed_actions_done' ||  this.state.selectedAllowedType === 'denied_actions_done') ?
														<ul className="list-unstyle m-0 p-0">
															<li className="mb-2 f18 d-flex"> 
	
																<span className="d-block text-muted font-weight-bolder">
																	<b className="text-primary f20">5370</b> Actions Allowed in IAM Policy but NOT performed in Cloud 
																</span>
															</li>
															<li className="mb-2 f18 d-flex"> 
	
																<span className="d-block text-muted font-weight-bolder">
																	<b className="text-primary f20">5370</b> Actions Allowed in IAM Policy but NOT performed in Cloud 
																</span>
															</li>
															<li className="mb-2 f18 d-flex"> 
	
																<span className="d-block text-muted font-weight-bolder">
																	<b className="text-primary f20">5370</b> Actions Allowed in IAM Policy but NOT performed in Cloud 
																</span>
															</li>
														</ul>
													:
														<div>
															{this.state.category_doughnut_data ?
															<Doughnut data={this.state.category_doughnut_data} options={this.state.category_doughnut_options} width={100} height={70}/>
															: null
															}
														</div>
													}

													
												</div>
											</div>
											<div className="mt-3">
												{/* <div className="mt-3 mx-n3"> */}
												{this.state.privileageTypeData.length ?
													<div className='row mt-1 mb-1 d-flex'>
														<div className='col-sm-2'>
															<div className='d-flex'>
																<select
																	className='form-control custom-dropdown'
																	value={this.state.selectedPrivileageServiceType}
																	onChange={event => {
																		this.setState(
																			{
																				selectedPrivileageServiceType: event.target.value,
																				showLoading: false,
																			},
																			() => this.getPrivileageTypeDetails()
																		)
																	}}
																>
																	<option key='All' value='All'>All</option>
																	{this.state.privileageTypeData.map(privService => {
																	return (
																		<option key={privService.service} value={privService.service}>
																			{privService.service}
																		</option>
																	)
																	})}
																</select>
															</div>
														</div>
														{(this.state.selectedAllowedType === 'allowed_actions_done' ||  this.state.selectedAllowedType === 'denied_actions_done') ?
														<div className='col-sm-2'>
															<div className='d-flex'>
																<select
																	className='form-control custom-dropdown'
																	value={this.state.privileageSortBy}
																	onChange={event => {
																		this.setState(
																			{
																				privileageSortBy: event.target.value,
																				showLoading: false,
																			},
																			() => this.getSortByDetail()
																		)
																	}}
																>
																	<option key='sortBy' value='sortBy'>Sort By</option>
																	<option key='timeDesc' value='timeDesc'>Date</option>
																	<option key='countDesc' value='countDesc'>Count</option>
																	{/* <option key='timeAsc' value='timeAsc'>Sort by Date Ascending</option> */}
																	{/* <option key='countAsc' value='countAsc'>Sort by Count Ascending</option> */}
																</select>
															</div>
														</div>
														: null
														}
														
														<div className='col-sm-10 align-self-center'>										{this.state.privileageTypeFilterData.length ?
																<p className="mb-1 small  text-muted">Showing <b>{this.state.selectedPrivileageServiceType === '' ? 'All' : this.state.selectedPrivileageServiceType}</b> service  <b> {this.state.privileageTypeData.length}</b> services from {this.state.selectedAllowedType === 'allowed_actions_not_done' ? ' Actions Allowed in IAM Policy but NOT performed in Cloud' : this.state.selectedAllowedType === 'denied_actions_done' ? ' Action NOT allowed in IAM policy and Denied while performed in Cloud' : this.state.selectedAllowedType === 'allowed_actions_not_known' ? ' Action Allowed in IAM Policy but NOT tracked in Audit Trail' : ' Actions Allowed in IAM Policy and performed in Cloud' }
																</p>
															: null
															}
														</div>
													</div>
												: 

													this.state.selectedAllowedType === 'allowed_actions_not_done' ? ' Actions Allowed in IAM Policy but NOT performed in Cloud' : this.state.selectedAllowedType === 'denied_actions_done' ? ' Action NOT allowed in IAM policy and Denied while performed in Cloud' : this.state.selectedAllowedType === 'allowed_actions_not_known' ? ' Action Allowed in IAM Policy but NOT tracked in Audit Trail' : ' Actions Allowed in IAM Policy and performed in Cloud' 
												
												}
												{this.state.privileageTypeFilterData && this.state.privileageTypeFilterData.length && this.state.selectedAllowedType === 'allowed_actions_not_done' ?
													<p className="font-weight-bold mb-2">The following Actions are allowed in IAM Policy but NOT performed in your cloud. We recommend you to review the IAM policy and remove these actions for Privileged Access Control </p> 
												: null
												}
												{this.state.privileageTypeFilterData ?
													(this.state.selectedAllowedType === 'allowed_actions_not_done' || this.state.selectedAllowedType === 'allowed_actions_not_known') ?
													<InsightsPrivileageTable
														columns={[
															{
																Header: 'Action',
																Cell: cellInfo => (
																	<div>
																	<span className="font-weight-bold">{cellInfo.row.original && cellInfo.row.original.service} <span className="text-muted">( {cellInfo.row.original && cellInfo.row.original.total_count} {this.state.selectedAllowedType === 'allowed_actions_not_done' ? 'actions not performed': 'actions not tracked'} )</span>
																	</span>
																	<br />
																	<div className="d-flex flex-wrap">
																	{cellInfo.row.original && cellInfo.row.original.actions.length && cellInfo.row.original.actions.map((action, x) => {
																		return (
																			<div key={x}  className="pr-2">
                                                                                    <p className="f12 mb-0 text-nowrap">{action} {x < (cellInfo.row.original.actions.length-1) ? ', ' : ' ' }</p>
																			</div>
																		)
																	})}
																	</div>
																	</div>
																)
															}
														]}
														data={this.state.privileageTypeFilterData.length ? this.state.privileageTypeFilterData : this.state.privileageTypeFilterData}
														onClickRow={tableRow => {}}
														dashboard ={this.state.privileageTypeFilterData.length ? 1 : 0}
														tooltipFor={[]}
													/>

													:

													<InsightsPrivileageTable
														columns={[
															{
																Header: 'Action',
																Cell: cellInfo => (
																	<div>
																	<span className="font-weight-bold">{cellInfo.row.original && cellInfo.row.original.action }
																	</span>
																	<br />
																	
																	<span>
																		{cellInfo.row.original && cellInfo.row.original.time &&  'Action was last performed on '+moment(cellInfo.row.original.time).format('YYYY-MM-DD hh:mm')}
																	</span>
																	</div>
																)
															},
															{
																Header: 'count',
																Cell: cellInfo => (
																	<span className="badge badge-outline-purple my-2 float-right">
																		{cellInfo.row.original && cellInfo.row.original.count}
																	</span>
																)
															}
														]}
														data={this.state.privileageTypeCountData ? this.state.privileageTypeCountData : this.state.privileageTypeCountData}
														onClickRow={tableRow => {}}
														dashboard ={this.state.privileageTypeCountData.length ? 1 : 0}
														tooltipFor={[]}
														sorted={this.state.sortOptions}
													/>
												
												: null
												}
											</div>
										</div>
										
									}
									{
										this.state.activeTab === 1&&
										<div className="col-sm-12">
											<div className="row">
												<div className="col-sm-12">
												<p className="mb-0">Results of Top 10 Roles that are contributing to most failed checks in your cloud infrastructure. Status by Fail and Risk count are shown
												</p>
												</div>
												<div className="col-sm-6 align-self-center border-right">
													<p className='mt-2 mb-2'><b>By Service</b></p>
												</div>
												<div className="col-sm-6 align-self-center">
													<p className='mt-2 mb-2'><b>By Risk</b></p>
												</div>
												<div className="col-sm-6 align-self-center pt-3 mb-2 border-right">
													{this.state.category_doughnut_data ?
														<Doughnut data={this.state.category_doughnut_data} options={this.state.category_doughnut_options} width={100} height={70}/>
													: null
													}
												</div>
												<div className="col-sm-6 align-self-center mb-2">
													<ul className="list-unstyle m-0 p-0">
														
														<li className="mb-2 f12 d-flex"> 

															<span className="d-block text-muted font-weight-bolder">
																<b className="text-primary f18">5370</b> Actions Allowed in IAM Policy but NOT performed in Cloud 
															</span>
														</li>
														<li className="mb-2 f12 d-flex"> 

															<span className="d-block text-muted font-weight-bolder">
																<b className="text-primary f18">5370</b> Actions Allowed in IAM Policy but NOT performed in Cloud 
															</span>
														</li>
														<li className="mb-2 f12 d-flex"> 

															<span className="d-block text-muted font-weight-bolder">
																<b className="text-primary f18">5370</b> Actions Allowed in IAM Policy but NOT performed in Cloud 
															</span>
														</li>
													</ul>
												</div>
												<div className="container-fluid mt-3">
													<div className="col-sm-12 p-0">
														<div className="row mb-1">
															<div className="col-sm-2">
																<select className='form-control customDropdown'>
																	<option value="Anomalies">Anomalies</option>
																	<option defaultValue value="Environment">Environment</option>
																	<option value="Project">Project</option>
																</select>
																
															</div>
															<div className="col-sm-10 align-self-center">
																{/* <p className='font-weight-bold m-0'>Anomaly</p> */}
																{this.state.anomalyDetails.length >= 10 ?
																<p className="mb-1 small">
																	Showing 10 results out of {this.state.anomalyDetails.length} Roles
																</p>
																:
																<p className="mb-1 small text-muted">
																	Showing {this.state.anomalyDetails.length} results out of {this.state.anomalyDetails.length} Behaviour analysie
																</p>
																}
															</div>
														</div>
														{this.state.anomalyDetails &&
															<InsightsTable
																columns={[
																	{
																		Header: 'Title',
																		accessor: 'title',
																		width: 150
																	},
																	{
																		Header: 'Timestamp',
																		Cell: cellInfo => (
																			<span>
																		{cellInfo.row.original && cellInfo.row.original.timestamp && moment(cellInfo.row.original.timestamp).format(
																					'YYYY-MM-DD hh:mm'
																				)}
																			</span>
																		),
																		width: 150
																	},
																	{
																		Header: 'Risk',
																		Cell: cellInfo => (
																			<span
																				className={
																					cellInfo.row.original.risk === 'Critical' ? 'criticalTextColor' : cellInfo.row.original.risk === 'High' ? 'highTextColor' : cellInfo.row.original.risk === 'Medium' ? 'mediumTextColor' : 'lowTextColor'}
																			>
																				{cellInfo.row.original.risk}
																			</span>
																		),
																		width: 100
																	},
																	
																	{
																		Header: 'Observation',
																		accessor: 'detail',
																		width: 400

																	}
																]}
																data={this.state.anomalyDetails.length ? this.state.anomalyDetails : this.state.anomalyDetails}
																onClickRow={tableRow => {}}
																dashboard ={this.state.anomalyDetails.length ? 1 : 0 }
																//tooltipFor={[3, 6]}
															/>
														}
													</div>
												</div>
											</div>
										</div>
									}
									{
										this.state.activeTab === 2&&
										<div>
											<div className="container-fluid">
												<div className='row'>
													<div className="col-sm-12">
														<p className="mb-0">Results of Top 10 Services that are contributing to most failed checks in your cloud infrastructure. Status by Fail and Risk count are shown
														</p>
													</div>
												</div>
												<div className='row'>
													<div className='col-sm-12 p-0'>
														<div className="col-sm-3 float-left">
															<p className="m-0 mt-1 mb-1 blackMuted">Frequently Accessed</p>
															<select
																className='form-control custom-dropdown mb-2'
																value={this.state.selectedFrequentlyData}
																onChange={event => {
																	this.setState(
																		{
																			selectedFrequentlyData: event.target.value,
																			showLoading: false,
																		},
																		() => this.getFrequentSelectedData()
																	)
																}}
															>
																<option key='region' value='region'>Region</option>
																<option key='service' value='service'>Service</option>
																<option key='action' value='action'>Action</option>
																<option key='asset' value='asset'>Asset</option>
																{/* <option key='vpc' value='vpc'>VPC</option> */}
																<option key='tag' value='tags'>Tag</option>
															</select>
														</div>
														<div className={`col-sm-3  float-left ${this.state.selectedFrequentlyData === 'tags' ? '' : 'displayNone'}`}>
															<p className="m-0 mt-1 mb-1 blackMuted">Tags</p>
															<select
																className='form-control customDropdown'
																value={this.state.selectedFrequentlyTag}
																onChange={event => {
																	this.setState(
																		{
																			selectedFrequentlyTag: event.target.value,
																			showLoading: false,
																		},
																		() => this.getFrequentSelectedData()
																	)
																}}
															>
																<option key='application' value='application'>Application</option>
																<option key='environment' value='environment'>Environment</option>
															</select>
														</div>
													</div>
													<div className="col-sm-6 align-self-center border-right">
														<p className='mt-2 mb-2'><b>By Service</b></p>
													</div>
													<div className="col-sm-6 align-self-center">
														<p className='mt-2 mb-2'><b>By {capitalizeFirstLetter(this.state.selectedFreqValue)}</b></p>
													</div>
													<div className="col-sm-6 pt-1 border-right">
														<div className="col-sm-11">														
															{this.state.frequently_doughnut_data && this.state.frequently_doughnut_options ?
																<Doughnut data={this.state.frequently_doughnut_data} options={this.state.frequently_doughnut_options} width={100} height={70}/>
															: null
															}
														</div>
													</div>
													<div className="col-sm-6">
														<div className="col-sm-12 m-0 p-0">
														{this.state.frequentlyAccessed && this.state.frequentlyAccessed.length ?
														
															<div className="card mt-3">
																<div className="card-body cardBodyManul">
																	<p className="card-title m-0">Frequently Accessed</p>
																	<p className="small text-muted m-0">Frequently Accessed Regions Frequently Accessed Regions Frequently Accessed Regions</p>
																	<div className="d-flex flex-wrap">
																		{console.log(this.state.frequentlyAccessed)}
																		{ this.state.frequentlyAccessed.map((feqAccess, x) => {
																			if(this.state.selectedFrequentlyData === 'asset')	{
																				const splitSlash = feqAccess.data.split('/');
																				let slashLength = splitSlash.length
																				const splitColon = splitSlash[slashLength-1].split(':');
																				let colonLength = splitColon.length
																				console.log('feqAccess',splitColon[colonLength-1])
																				return(
																					<React.Fragment>
																				{/* d-table */}
																					<span className="badge badge-outline-purple text-white f13 my-1 mr-1 assetfreq" id={`asset_`+x}>{splitColon[colonLength-1]}</span>
																					<UncontrolledTooltip placement='top' target={`asset_`+x}>{feqAccess.resource_type+'/'+feqAccess.region+'/'+feqAccess.data}</UncontrolledTooltip>
																					</React.Fragment>
																				)
																			} else {
																				return(
																					<span className="badge badge-outline-purple text-white f13 my-1 mr-1">{feqAccess.data}</span>
																				)
																			}
																		})}															
																	</div>
																</div>
															</div>
														
														: null
														}
														</div>
													</div>
												</div>
												
												<div className="mt-3">
													<div className="col-sm-12 p-0">
														<div className="row mb-1">
															<div className="col-sm-2">
																<select className='form-control customDropdown'>
																	<option value="Anomalies">Anomalies</option>
																	<option defaultValue value="Environment">Environment</option>
																	<option value="Project">Project</option>
																</select>
																
															</div>
															<div className="col-sm-10 align-self-center">{this.state.frequentlyAccessed.length >= 10 ?
																<p className="mb-1 small  text-muted">
																	Showing 10 results out of {this.state.frequentlyAccessed.length} frequently accessed
																</p>
																:
																<p className="mb-1 small  text-muted">
																	Showing {this.state.frequentlyAccessed.length} results out of {this.state.frequentlyAccessed.length} frequently accessed 
																</p>
																}
															</div>
														</div>
														{this.state.frequentlyAccessed ?
															<InsightsTable
																columns={[
																	{
																		Header:capitalizeFirstLetter(this.state.selectedFreqValue),
																		
																		//accessor: 'data',
																		Cell: cellInfo => (
																			<span>
																		{cellInfo.row.original && cellInfo.row.original.resource_type +'/'+ cellInfo.row.original.region +'/'+ cellInfo.row.original.data}
																		</span>
																		),
																		width: 150
																	},
																	{
																		Header: 'First Accessed',
																		Cell: cellInfo => (
																			<span>
																		{cellInfo.row.original && cellInfo.row.original.first_accessed_time && moment(cellInfo.row.original.first_accessed_time).format('YYYY-MM-DD hh:mm')}
																			</span>
																		),
																		width: 150
																	},
																	{
																		Header: 'Last Accessed',
																		Cell: cellInfo => (
																			<span className='pl-2'>
																		{cellInfo.row.original && cellInfo.row.original.last_accessed_time && moment(cellInfo.row.original.last_accessed_time).format('YYYY-MM-DD hh:mm')}
																			</span>
																		),
																		width: 150
																	},
																	{
																		Header: 'Last Event',
																		accessor: 'last_event',
																		width: 350
																	}
																]}
																data={this.state.frequentlyAccessed.length ? this.state.frequentlyAccessed : this.state.frequentlyAccessed}
																onClickRow={tableRow => {}}
																dashboard ={this.state.frequentlyAccessed.length ? 1 : 0 }
																//tooltipFor={[3, 6]}
															/>
														: null
														}
													</div>
												</div>
											</div>										
										</div>
									}
									{
										this.state.activeTab === 3 &&
										<div className="col-sm-12">
											<div className="row">
												<div className="col-sm-12">
												<p className="mb-0">Results of Top 10 Roles that are contributing to most failed checks in your cloud infrastructure. Status by Fail and Risk count are shown
												</p>
												</div>
												<div className="col-sm-6 align-self-center border-right">
													<p className='mt-2 mb-1'><b>By Service</b></p>
												</div>
												<div className="col-sm-6 align-self-center">
													<p className='mt-2 mb-1'><b>By Trend</b></p>
													<p className='small mb-2'>Showing 30 days fail trend for the denied</p>
												</div>
												<div className="col-sm-6 align-self-center  mt-n4 pt-3 mb-2 border-right">
													
													{this.state.denied_doughnut_data ?
														<Doughnut data={this.state.denied_doughnut_data} options={this.state.denied_doughnut_options} width={100} height={70}/>
														: null
													}
													
												</div>
												<div className="col-sm-6 align-self-center mb-2">
													{this.state.denied_bar_data &&  this.state.denied_bar_data.length && this.state.checks_bar_options ? 
														<Chart type="bar" series={this.state.denied_bar_data} width="100%" height={120} options={this.state.denied_bar_options}/>
													: null
													}
													<div className="ml-1 pt-3">
														<ul className="list-unstyle m-0 p-0">
															<li className="mb-2 f12 d-flex"> 
	
																<span className="d-block text-muted font-weight-bolder">
																	<b className="text-primary f18">5370</b> Actions Allowed in IAM Policy but NOT performed in Cloud 
																</span>
															</li>
															<li className="mb-2 f12 d-flex"> 
	
																<span className="d-block text-muted font-weight-bolder">
																	<b className="text-primary f18">5370</b> Actions Allowed in IAM Policy but NOT performed in Cloud 
																</span>
															</li>
														</ul>
													</div>
												</div>
												<div className="container-fluid">
													{/* <div className="row mt-3"> */}
													<div className="mt-3">
														<div className="col-sm-12 p-0">
															<div className="row mb-1">
																<div className="col-sm-2">
																	
																	
																</div>
																<div className="col-sm-10 align-self-center">
																	{/* <p className='font-weight-bold m-0'>Denied</p> */}
																	{this.state.recentBehavior.length >= 10 ?
																	<p className="mb-1 small  text-muted">
																		Showing 10 results out of {this.state.recentBehavior.length} denied
																	</p>
																	:
																	<p className="mb-1 small  text-muted">
																		Showing {this.state.recentBehavior.length} results out of {this.state.recentBehavior.length} denied 
																	</p>
																	}
																</div>
															</div>
															{this.state.recentBehavior &&
																<InsightsTable
																	columns={[
																		{
																			Header: 'Title',
																			accessor: 'title',
																			width: 150
																		},
																		{
																			Header: 'Timestamp',
																			Cell: cellInfo => (
																				<span>
																			{cellInfo.row.original && cellInfo.row.original.timestamp && moment(cellInfo.row.original.timestamp).format(
																						'YYYY-MM-DD hh:mm'
																					)}
																				</span>
																			),
																			width: 150
																		},
																		{
																			Header: 'Risk',
																			Cell: cellInfo => (
																				<span
																					className={
																						cellInfo.row.original.risk === 'Critical' ? 'criticalTextColor' : cellInfo.row.original.risk === 'High' ? 'highTextColor' : cellInfo.row.original.risk === 'Medium' ? 'mediumTextColor' : 'lowTextColor'}
																				>
																					{cellInfo.row.original.risk}
																				</span>
																			),
																			width: 100
																		},
																		
																		{
																			Header: 'Observation',
																			accessor: 'detail',
																			width: 400
																		}
																	]}
																	data={this.state.recentBehavior.length ? this.state.recentBehavior : this.state.recentBehavior}
																	onClickRow={tableRow => {}}
																	dashboard ={this.state.recentBehavior.length ? 1 : 0 }
																	//tooltipFor={[3, 6]}
																/>
															}
														</div>
													</div>
												</div>
											</div>
										</div>
									}
									{
										this.state.activeTab === 4 &&
										<div className="col-sm-12">
											<div className="row">
												<div className="col-sm-12">
												<p className="mb-0">Results of Top 10 Roles that are contributing to most failed checks in your cloud infrastructure. Status by Fail and Risk count are shown
												</p>
												</div>
												<div className="col-sm-6 align-self-center border-right">
													<p className='mt-2 mb-1'><b>By Service</b></p>
												</div>
												<div className="col-sm-6 align-self-center">
													<p className='mt-2 mb-1'><b>By Trend</b></p>
													<p className='small mb-2'>Showing 30 days fail trend for the checks</p>
												</div>
												<div className="col-sm-6 align-self-center  mt-n4 pt-3 mb-2 border-right">
													
													{this.state.checks_doughnut_data ?
														<Doughnut data={this.state.checks_doughnut_data} options={this.state.checks_doughnut_options} width={100} height={70}/>
														: null
													}
													
												</div>
												<div className="col-sm-6 align-self-center mb-2">
													{this.state.checks_bar_data &&  this.state.checks_bar_data.length && this.state.checks_bar_options ?
														<div className="marginLeftMinus5">
															<Chart type="bar" series={this.state.checks_bar_data} width="100%" height={120} options={this.state.checks_bar_options}/>
														</div>
													: null
													}
													<div className="ml-1 pt-3">
														<ul className="list-unstyle m-0 p-0">
															<li className="mb-2 f12 d-flex"> 
	
																<span className="d-block text-muted font-weight-bolder">
																	<b className="text-primary f18">5370</b> Actions Allowed in IAM Policy but NOT performed in Cloud 
																</span>
															</li>
															<li className="mb-2 f12 d-flex"> 
	
																<span className="d-block text-muted font-weight-bolder">
																	<b className="text-primary f18">5370</b> Actions Allowed in IAM Policy but NOT performed in Cloud 
																</span>
															</li>
														</ul>
													</div>
												</div>
												<div className="container-fluid">
													
													{/* <div className="row mt-3"> */}
													<div className="mt-3">
														<div className="col-sm-12 p-0">
															<div className="mb-1 d-flex">
																<div className="mr-2">
																	<select className='form-control customDropdown'
																		value={this.state.selectedChecksStatus}
																		onChange={event => {
																			this.setState(
																				{
																					selectedChecksStatus: event.target.value,
																					showLoading: false,
																				},
																				() => this.checksTableFilter()
																			)
																		}}
																	>
																		<option value="">All</option>
																		<option value="pass">Pass</option>
																		<option value="fail">Fail</option>
																	</select>
																</div>
																<div className="mr-2">
																	<select className='form-control customDropdown'
																		value={this.state.selectedChecksRisk}
																		onChange={event => {
																			this.setState(
																				{
																					selectedChecksRisk: event.target.value,
																					showLoading: false,
																				},
																				() => this.checksTableFilter()
																			)
																		}}
																	>
																		<option value="">All</option>
																		<option value="Critical">Critical</option>
																		<option value="High">High</option>
																		<option  value="Medium">Medium</option>
																		<option value="Low">Low</option>
																	</select>
																</div>
																<div className="align-self-center">
																	{/* <p className='font-weight-bold m-0'>Checks</p> */}				{this.state.identifierFilterResults && this.state.identifierFilterResults.length >= 10 ?
																	<p className="mb-1 small text-muted">
																		Showing 10 results out of {this.state.identifierFilterResults} checks
																	</p>
																	:
																	<p className="mb-1 small text-muted">
																		Showing {this.state.identifierFilterResults && this.state.identifierFilterResults.length} results out of {this.state.identifierFilterResults && this.state.identifierFilterResults.length} checks 
																	</p>
																	}
																</div>
															</div>
															{this.state.identifierFilterResults &&
																<InsightsTable
																	columns={[
																		{
																			Header: 'Asset',
																			Cell: cellInfo => (
																				<span
																					className="breakWord"
																				>
																					{cellInfo.row.original.asset_id}
																				</span>
																			),
																			width: 250,
																			
																		},
																		{
																			Header: 'Risk',
																			Cell: cellInfo => (
																				<span
																					className={
																						cellInfo.row.original.risk === 'Critical' ? 'criticalTextColor' : cellInfo.row.original.risk === 'High' ? 'highTextColor' : cellInfo.row.original.risk === 'Medium' ? 'mediumTextColor' : 'lowTextColor'}
																				>
																					{cellInfo.row.original.risk}
																				</span>
																			),
																			width: 100
																		},
																		{
																			Header: 'Status',
																			accessor: 'status',
																			width: 100
																		},
																		{
																			Header: 'Timestamp',
																			Cell: cellInfo => (
																			<span>
																			{
																			cellInfo.row.original && cellInfo.row.original.timestamp && moment(cellInfo.row.original.timestamp).format('YYYY-MM-DD hh:mm')
																			}
																			</span>
																			),
																			width: 200
																		}
																	]}
																	data={this.state.identifierFilterResults.length ? this.state.identifierFilterResults : this.state.identifierFilterResults}
																	onClickRow={tableRow => {}}
																	dashboard = {this.state.identifierFilterResults.length ? 1 : 0 }
																	//tooltipFor={[3, 6]}
																/>
															}
														</div>
													</div>
												</div>
											</div>
										</div>
									}
									{
										this.state.activeTab === 5 &&
										<div className="col-sm-12">
											<div className="row">
												<div className="col-sm-12">
												<p className="mb-0">Results of Top 10 Roles that are contributing to most failed checks in your cloud infrastructure. Status by Fail and Risk count are shown
												</p>
												</div>
												<div className="col-sm-6 align-self-center border-right">
													<p className='pt-2 mb-2'><b>By Service</b></p>
												</div>
												<div className="col-sm-6 align-self-center">
													<p className='pt-2 mb-2'><b>By Risk</b></p>
													<p className='small mt- mb-2'>Showing 30 days fail trend for the checks</p>
												</div>
												<div className="col-sm-6 align-self-center pt-3 mb-2 border-right">
												{
													this.state.resource_doughnut_data&&
													<Doughnut data={this.state.resource_doughnut_data} options={this.state.resource_doughnut_options} width={100} height={70}/>
												}
												</div>
												<div className="col-sm-6 align-self-center pt-3 mb-2">
													{this.state.relatedRisks && this.state.relatedRisks.length > 0 && this.state.relatedRisks.slice(0,10).map((risk,i) => {

														let color = '#ececec'
														if(i===0) {
															color = '#4A397C'
														} else if(i===1) {
															color = '#6E6096'
														} else if(i===2) {
															color = '#08B089'
														} else if(i===3) {
															color = '#39BFA0'
														} else if(i===4) {
															color = '#60687C'
														} else if(i===5) {
															color = '#8E919B'
														} else if(i===6) {
															color = '#0092F3'
														} else if(i===7) {
															color = '#23D0E7'
														} else if(i===8) {
															color = '#8E6ECC'
														} else if(i===9) {
															color = '#57E7C5'
														}

														let lowRiskBar = ((risk.Low * 100) / risk.totalFail) +'%'
														let mediumRiskBar = ((risk.Medium * 100) / risk.totalFail) +'%'
														let highRiskBar = ((risk.High * 100) / risk.totalFail) +'%'
														let criticalRiskBar = ((risk.Critical * 100) / risk.totalFail) +'%'
														return (
															<div className='d-flex flex-column mb-1'>
																<div className='d-flex mb-1'>
																	<div className='legend-circle mr-2' style={{backgroundColor:color}}></div> <p className='mb-0 short-lineheight'>{risk.name}</p>
																</div>
																{risk.totalFail > 0 ?
																	<div className='stock-progress'>
																		<div className='stock-1' style={{width:criticalRiskBar}} id={`roleCritical-${i}`}></div>
																		<div className='stock-2' style={{width:highRiskBar}} id={`roleHigh-${i}`}></div>
																		<div className='stock-3' style={{width:mediumRiskBar}} id={`roleMedium-${i}`}></div>
																		<div className='stock-4' style={{width:lowRiskBar}} id={`roleLow-${i}`}></div>
													<UncontrolledTooltip placement='top' target={`roleCritical-${i}`}>Critical {risk.Critical}</UncontrolledTooltip>	
													<UncontrolledTooltip placement='top' target={`roleHigh-${i}`}>High {risk.High}</UncontrolledTooltip>
													<UncontrolledTooltip placement='top' target={`roleMedium-${i}`}>Medium {risk.Medium}</UncontrolledTooltip>
													<UncontrolledTooltip placement='top' target={`roleLow-${i}`}>low {risk.Low}</UncontrolledTooltip>
																	</div>
																: 
																	<div className='stock-progress'>
																		<div className='stock-5'></div>
																	</div>
																}
															</div>
														)
													})}
												</div>
												<div className="container-fluid">
													<div className="row">
														<div className="col-md-6">
															<ul className="list-unstyle m-0 p-0">
																<li className="mb-2 f12 d-flex"> 
		
																	<span className="d-block text-muted font-weight-bolder">
																		<b className="text-primary f18">5370</b> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the
																	</span>
																</li>
															</ul>
														</div>
														<div className="col-md-6">
															<ul className="list-unstyle m-0 p-0">
																<li className="mb-2 f12 d-flex"> 
		
																	<span className="d-block text-muted font-weight-bolder">
																		<b className="text-primary f18">5370</b> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the
																	</span>
																</li>
															</ul>
														</div>
													</div>
													{/* <div className="row mt-3"> */}
													<div className="mt-3">
														<div className="col-sm-12 p-0">
															<div className="mb-1 d-flex">
																<div className="mr-2">
																	<select
																		className='form-control customDropdown'
																		value={this.state.selectedResourceType}
																		onChange={event => {
																			this.setState(
																				{
																					selectedResourceType: event.target.value,
																					showLoading: false,
																				},
																				() => this.getRelatedIdentifierResults()
																			)
																		}}
																	>
																		{this.state.relatedRisks.map(resourceType => {
																		return (
																			<option key={resourceType.name} value={resourceType.name}>
																				{capitalizeFirstLetter(resourceType.name)}
																			</option>
																		)
																		})}
																	</select>
																	
																</div>
																<div className="mr-2">
																	<select className='form-control customDropdown'
																		value={this.state.selectedResourceStatus}
																		onChange={event => {
																			this.setState(
																				{
																					selectedResourceStatus: event.target.value,
																					showLoading: false,
																				},
																				() => this.resourceTableFilter()
																			)
																		}}
																	>
																		<option value="">All</option>
																		<option value="pass">Pass</option>
																		<option value="fail">Fail</option>
																	</select>
																</div>
																<div className="mr-2">
																	<select className='form-control customDropdown'
																		value={this.state.selectedResourceRisk}
																		onChange={event => {
																			this.setState(
																				{
																					selectedResourceRisk: event.target.value,
																					showLoading: false,
																				},
																				() => this.resourceTableFilter()
																			)
																		}}
																	>
																		<option value="">All</option>
																		<option value="Critical">Critical</option>
																		<option value="High">High</option>
																		<option  value="Medium">Medium</option>
																		<option value="Low">Low</option>
																	</select>
																</div>
																<div className="align-self-center">
																	{/* <p className='font-weight-bold m-0'>Resources</p> */}
																	{this.state.relatedIdentifierFitlerResults && this.state.relatedIdentifierFitlerResults.length >= 10 ?
																	<p className="mb-1 small text-muted">
																		Showing 10 results out of {this.state.relatedIdentifierResults.length} resource
																	</p>
																	:
																	<p className="mb-1 small text-muted">
																	Showing {this.state.relatedIdentifierFitlerResults && this.state.relatedIdentifierFitlerResults.length}  results out of {this.state.relatedIdentifierFitlerResults && this.state.relatedIdentifierFitlerResults.length} 
resource																	</p>
																	}
																</div>
															</div>
															{this.state.relatedIdentifierFitlerResults &&
																<InsightsTable
																	columns={[
																		{
																			Header: 'Title',
																			accessor: 'title',
																			width:150
																		},
																		{
																			Header: 'Risk',
																			Cell: cellInfo => (
																				<span
																					className={
																						cellInfo.row.original.risk === 'Critical' ? 'criticalTextColor' : cellInfo.row.original.risk === 'High' ? 'highTextColor' : cellInfo.row.original.risk === 'Medium' ? 'mediumTextColor' : 'lowTextColor'}
																				>
																					{cellInfo.row.original.risk}
																				</span>
																			),
																			width: 50
																		},
																		{
																			Header: 'Category',
																			accessor: d => Array.isArray(d.category) ? d.category.map((item, index) => (d.category.length - 1 === index ? item : item + ', ')) : d.category,
																			width: 150
																		},
																		{
																			Header: 'Asset',
																			Cell: cellInfo => (
																				<span>																					{cellInfo.row.original && cellInfo.row.original.region +' / ' + cellInfo.row.original.resource_id}
																				</span>
																			),
																			width:150
																		},
																		{
																			Header: 'Status',
																			accessor: 'status',
																			width:50
																		},
																		{
																			Header: 'Timestamp',
																			Cell: cellInfo => (
																			<span>
																			{
																			cellInfo.row.original && 
																			cellInfo.row.original.timestamp && moment(cellInfo.row.original.timestamp).format('YYYY-MM-DD hh:mm')
																			}
																			</span>
																			),
																			width:150
																		}
																	]}
																	data={this.state.relatedIdentifierFitlerResults.length ? this.state.relatedIdentifierFitlerResults : this.state.relatedIdentifierFitlerResults}
																	onClickRow={tableRow => {}}
																	dashboard = {this.state.relatedIdentifierFitlerResults.length ? 1 : 0 }
																	//tooltipFor={[3, 6]}
																/>
															}
														</div>
													</div>
												</div>
											</div>
										</div>
									}
								</div>
							</div>
							:
							<div className='text-center mt-5' >
								<Spinner color='dark' size='lg' />
							</div>
						}
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ViewSidePanel.propTypes = {
	getPrivileageAnalysis:PropTypes.func,
	getRecentBehavior:PropTypes.func,
	listFrequentlyAccessed:PropTypes.func,
	getAnomalyDetails:PropTypes.func,
	getActionsCategoryWise:PropTypes.func,
	getDeniedServiceWise:PropTypes.func,
	getDaywiseDenied:PropTypes.func,
	getIdentifierResults:PropTypes.func,
	getTotalCount:PropTypes.func,
	getDayWiseCount:PropTypes.func,
	getRelatedIdentifierResults:PropTypes.func,
	getUserRelatedIssues:PropTypes.func,
}

const mapStateToProps = state => {
	return {		
	}
}

export default connect(mapStateToProps, {
	getPrivileageAnalysis,
	getRecentBehavior,
	listFrequentlyAccessed,
	getAnomalyDetails,
	getActionsCategoryWise,
	getDeniedServiceWise,
	getDaywiseDenied,
	getIdentifierResults,
	getTotalCount,
	getDayWiseCount,
	getRelatedIdentifierResults,
	getUserRelatedIssues
})(withRouter(ViewSidePanel))



