import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
	getIdentitiesList,
} from '../../../../actions/governance/userInsightsAction'
import CommonDropdown from '../../../common/CommonDropdown'

class AccessedByFilterSection extends React.Component {
    constructor(props) {
        super(props)
        console.log('accessedByProps', props)
        this.state = {
            identitiesRoleList: [],
            identitiesUserList: [],
            selectedUsers: [],
            selectedRoles: [],
            selectedRootAccess: false,
            selectedCrossAccess: false,
        };
    };

    componentDidMount = () => {
        this.getIdentitiesList('iam-roles')
        this.getIdentitiesList('iam-users')
	}

    getIdentitiesList = (roleType) => {
        let params = {}
        if(this.props.selectedProvider !== 'All') {
            params.provider = this.props.selectedProvider
        }
        if(this.props.selectedAccount.length && this.props.selectedAccount[0] !== 'All') {
            params.account_id = this.props.selectedAccount
        }
        params.account_id = '573921696245'
		params.service_name = roleType

		//console.log('-------------getIdentitiesList-----------',params)
		this.props.getIdentitiesList(params, (promise, identitiesList) => {
			console.log('----------getIdentitiesList--------------',identitiesList)
			if (promise) {
				if(roleType === 'iam-roles') {
                    let roleArray = []
                    identitiesList.results.forEach(identities => {
                        let dataRow = {}
                        dataRow.label = identities.role_name
                        dataRow.value = identities.role_name
                        roleArray.push(dataRow)
                    })
					this.setState({ identitiesRoleList: roleArray })
				} else {
                    let userArray = []
                    identitiesList.results.forEach(identities => {
                        let dataRow = {}
                        dataRow.label = identities.user_name
                        dataRow.value = identities.user_name
                        userArray.push(dataRow)
                    })
					this.setState({ identitiesUserList: userArray })
				}
			} else {
				this.setState({ identitiesRoleList: [], identitiesUserList: [] })
			}
		})
	}

    selectedOptionFunction = (label, value) => {
        let elementExist = 0
        if(label === 'Roles') {
            var totalArray = this.state.selectedRoles; 
            totalArray.forEach((row, index) => {
                if(row === value) {
                    elementExist = 1
                    //totalArray.splice(index, 1);
                }
            })
            if(elementExist === 0) {
                this.setState(prevState => ({
                    selectedRoles: [...prevState.selectedRoles, value]
                }))
            }
        } else if(label === 'Users') {
            var totalArrayUsers = this.state.selectedUsers; 
            totalArrayUsers.forEach((row, index) => {
                if(row === value) {
                    elementExist = 1
                    //totalArrayUsers.splice(index, 1);
                }
                //this.setState({ selectedUsers : totalArrayUsers });
            })
            if(elementExist === 0) {
                this.setState(prevState => ({
                    selectedUsers: [...prevState.selectedUsers, value]
                }))
            }
        } else if(label === 'Root') {
            this.setState({ selectedRootAccess: this.selectedRootAccess ? false : true })
        } else {
            this.setState({ selectedCrossAccess: this.selectedCrossAccess ? false : true })
        }
    }

    applyFilter = () => {
        
        let obj = {}
        obj['Role'] = this.state.selectedRoles
        obj['User'] = this.state.selectedUsers
        obj['root'] = this.state.selectedRootAccess
        obj['cross'] = this.state.selectedCrossAccess
        
        let result = []
        result.push(obj)
        
        return this.props.data(obj)
    }

    closeFilter = () => {
        return this.props.data(false);
    }

    remove = (type, index) => {
        var totalArray = '' 
        if(type === 'role') {
            totalArray = this.state.selectedRoles; 
            totalArray.splice(index, 1);
            this.setState({ selectedRoles : totalArray });
        } else {
            totalArray = this.state.selectedUsers; 
            totalArray.splice(index, 1);
            this.setState({ selectedUsers : totalArray });
        }
    }

    render() {
        return (
            <div className="cardDropSection">
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <p className="text-white my-2">Lorem ipsum text industry since 1500s</p>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-sm-4 align-self-center'>
                            <CommonDropdown 
                                data = {this.selectedOptionFunction.bind(this, 'Roles')}
                                hideHeader = {false}
                                headerName = {'Roles'}
                                dropOptions = {this.state.identitiesRoleList}
                                displayMenu = {false}
                                isMultiSelect = {false}
                                isDefaultDropdown = {false}
                                styleTop={'topAuto'}
                                searchOption={true}
                                label={'Roles'}
                            />
                        </div>
                        <div className='col-sm-6'>
                            {
                                this.state.selectedRoles.length ? 
                                this.state.selectedRoles.map((item, index) => {
                                    return (
                                        index < 3 ?
                                            <span className='badge badge-primary d-flex ml-2 text-white p-2 justify-content-between mb-1'>
                                                <span className='text-wrap'>{item}</span>
                                                <i className='ml-1 fal fa-times cursorPointer align-self-center' onClick={() => this.remove('role', index)}></i>
                                            </span> 
                                        :
                                        null
                                    )       
                                })
                            :
                                null
                            }
                        </div>
                        <div className='col-sm-2 pl-0'>
                            {
                                this.state.selectedRoles.length > 3 ?
                                    <p className="text-white f10 mb-0">  {this.state.selectedRoles.length+' Selected '}</p>
                                    :
                                null
                            }
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-sm-4 align-self-center'>
                            <CommonDropdown 
                                data = {this.selectedOptionFunction.bind(this, 'Users')}
                                hideHeader = {false}
                                headerName = {'Users'}
                                dropOptions = {this.state.identitiesUserList}
                                displayMenu = {false}
                                isMultiSelect = {false}
                                isDefaultDropdown = {false}
                                styleTop={'topAuto'}
                                searchOption={true}
                                label={'Users'}
                            />
                        </div>
                        <div className='col-sm-6'>
                            {
                                this.state.selectedUsers.length ? 
                                this.state.selectedUsers.map((item, index) => {
                                    return (
                                        index < 3 ?
                                            <span className='badge badge-primary d-flex ml-2 text-white p-2 justify-content-between mb-1'>
                                                <span className='text-wrap'>{item}</span>
                                                <i className='ml-1 fal fa-times cursorPointer align-self-center' onClick={() => this.remove('user', index)}></i>
                                            </span> 
                                        :
                                        null
                                    )       
                                })
                            :
                                null
                            }
                        </div>
                        <div className='col-sm-2 pl-0'>
                            {
                                this.state.selectedUsers.length > 3 ?
                                <p className="text-white f10 mb-0">  {this.state.selectedUsers.length+' Selected '}</p>
                            :
                                null
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className="form-group form-check mb-2">
                                <input type="checkbox" className="form-check-input" onChange={() => this.selectedOptionFunction('Root', '')} />
                                <label className="form-check-label text-white">Accessed By Root Account</label>
                            </div>
                        </div>
                        <div className='col-sm-12'>
                            <div className="form-group form-check mb-0">
                                <input type="checkbox" className="form-check-input" onChange={() => this.selectedOptionFunction('Cross', '')} />
                                <label className="form-check-label text-white">Accessed By Cross Account</label>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className="col-sm-6"></div>
                        <div className="col-sm-6 justify-content-end d-flex">
                            <span className="dropCancelBtn d-block p-2 mr-2" onClick={ () => this.closeFilter()}>
                                Cancel
                            </span>
                            <span className="dropApplyBtn d-block p-2" onClick={ () => this.applyFilter()}>
                                Apply
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

/**
 * Type of the props used in the component
 */
AccessedByFilterSection.propTypes = {
    getIdentitiesList:PropTypes.func
}

export default connect(null, { getIdentitiesList })(AccessedByFilterSection);

//export default connect(AccessedByFilterSection, null, {getIdentitiesList})

//export default AccessedByFilterSection;
