/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file RightSection.js
 * @author Prakash // on 01/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import { getCostAnomalyTotalCount, getCostTopAnomalies } from '../../../actions/cost/CostAction'
import { capitalizeFirstLetter, momentConvertionUtcToLocalTime, momentConvertionLocalToUtcTime } from '../../../utils/utility'

import MetricsTab from './MetricsTab'
import ApexBarChart from '../../common/charts/ApexBarChart'
// import AnomalyAnalysis from './anomaly/AnomalyAnalysis'

class RightSection extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            selectedTab: 'Details',
            details: {},
        }
    }

    componentDidMount = () => {}

    componentDidUpdate = (prevProps) => {
        if(this.props.costCommonPageFilter && Object.entries(this.props.costCommonPageFilter).length) {
            if(!_.isEqual(prevProps.costCommonPageFilter, this.props.costCommonPageFilter)) {
                this.setState({ 
                    start_time: this.props.costCommonPageFilter.start_time,
                    end_time: this.props.costCommonPageFilter.end_time,
                    brushStartTime: this.props.costCommonPageFilter.brushStartTime ? this.props.costCommonPageFilter.brushStartTime : this.props.costCommonPageFilter.start_time,
                    brushEndTime: this.props.costCommonPageFilter.brushEndTime ? this.props.costCommonPageFilter.brushEndTime : this.props.costCommonPageFilter.end_time,
                    selectedTab: '',
                    topAssets: [],
                    topAnomalies: []
                },
                    () => {
                        this.setState({ selectedTab: 'Details' })
                        this.getCostTopAnomalies()
                    }
                )
            }
        }

        if(this.props.tabOptions && Object.entries(this.props.tabOptions).length) {
            if(!_.isEqual(prevProps.tabOptions, this.props.tabOptions)) {
                this.setState({ 
                    selectedTab: "Details",
                    details: this.props.tabOptions.details,
                    merticsAssetNameInvolved: this.props.tabOptions.merticsAssetNameInvolved,
                    expandAnomalyDetails: true, 
                    expandAnomalyPrecedingDetails: false,
                    expandPropsAssetDetails: false,
                    expandPropsLogDetails: false,
                    expandTopDetails: false,
                    brushStartTime: this.props.tabOptions.brushStartTime,
                    brushEndTime: this.props.costCommonPageFilter.brushEndTime,
                    topAssets: [],
                    topAnomalies: []
                },
                    () => this.getCostTopAnomalies()
                )
            }
        }
    }

    getCostTopAnomalies = () => {
        let params = {
            "start_time": momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss'),
            "end_time": momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss'),
            'top': 5,            
	        "aggregate_by": [["asset_name", "anomaly_name"]],
        }

        this.props.getCostTopAnomalies(params, (promise, response) => {
            if(promise) {
                this.setState({ topCostAnomalies: response.length && response[0].data ? response[0].data : {}  },
                    () => this.topAnamolySectionGraph()
                )
            }
        })
    }

    topAnamolySectionGraph = () => {
        if(this.state.topCostAnomalies.asset_name) {
            let list = this.state.topCostAnomalies.asset_name
            let labels = Object.keys(list).sort(function(a,b){return list[b] - list[a]})

            let topStatusData = {}
            let data = []
            labels.forEach(item => {
                data.push(list[item])
            })
            
            for(let i=labels.length; i<5; i++) {
                data.push(0)
                labels.push('empty')
            }

            topStatusData.data = data
            topStatusData.labels = labels
            this.setState({ topAssets: topStatusData })
        }
        
        if(this.state.topCostAnomalies.anomaly_name) {
            let list = this.state.topCostAnomalies.anomaly_name
            let labels = Object.keys(list).sort(function(a,b){return list[b] - list[a]})

            let topStatusData = {}
            let data = []
            labels.forEach(item => {
                data.push(list[item])
            })
            
            for(let i=labels.length; i<5; i++) {
                data.push(0)
                labels.push('empty')
            }

            topStatusData.data = data
            topStatusData.labels = labels

            console.log("topAnomalies", topStatusData)
            this.setState({ topAnomalies: topStatusData })
        }        
    }

    render() {
        return (
            <div className="rounded bg-dark p-3 h-100">
                <ul className={`row nav nav-tabs border-bottom bg-dark shadow-none rounded justify-content-between`} id="myTab" role="tablist">
                    {this.state.selectedTab !== 'Analysis' ?
                        <span className={`d-flex ${this.state.menuDisabled ? '' : '' }`}>
                            <li className="nav-item">
                                <span className={`nav-link text-white cursorPointer ${this.state.selectedTab === 'Details' ? ' active' : ''}`} onClick={() => this.setState({ selectedTab: 'Details' })}>Details</span>
                            </li>
                            <li className="nav-item">
                                <span className={`nav-link text-white cursorPointer ${this.state.selectedTab === 'Metrics' ? ' active' : ''}`} onClick={() => this.setState({ selectedTab: 'Metrics', selectedAssetId: '', menuDisabled: true })}>Metrics</span>
                            </li>
                        </span>
                    :
                        <li className="nav-item">
                            <span className={`nav-link text-white cursorPointer ${this.state.selectedTab === 'Analysis' ? ' active' : ''}`} onClick={() => this.setState({ details: {}, selectedTab: 'Analysis' })}>Analysis</span>
                        </li>
                    } 
                </ul>
                {this.state.selectedTab === 'Details' ?
                    this.state.details && Object.entries(this.state.details).length ?
                        <React.Fragment>
                            <div className="accordion" id="accordionExample">
                                <div className={`card bg-dark3 border-0 mb-2`}>
                                    <div id={'heading_event'} onClick={() => this.setState({ expandAnomalyDetails: !this.state.expandAnomalyDetails })}>
                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
                                            <div className="text-white"><span className={`far ${this.state.expandAnomalyDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Anomaly Details</div>
                                            {/* <div className="text-info">Showing event details</div> */}
                                            <div className="toggleOnOffSwitchTable d-flex mr-1 align-self-center" >
                                                {/* onClick={() => this.automaiotnUpdateStatus(cellInfo.row.original)}> */}
                                                <label className="switch mb-0">
                                                    <input type="checkbox" 
                                                        className="inputdemo" 
                                                        checked={this.state['enabled_'+this.state.details.anomaly_id] ? false : true}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={'collapse_event'} className={`collapse ${this.state.expandAnomalyDetails ? 'show' : <span>&nbsp;</span>}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
                                                <div>
                                                    <p className="mb-0 f16 text-white">{this.state.details.anomaly_name ? this.state.details.anomaly_name : <span>&nbsp;</span>}</p>
                                                    <p className="mb-0 f12">{this.state.details.anomaly_message ? this.state.details.anomaly_message : <span>&nbsp;</span>}</p>
                                                </div>
                                                <div className="ml-3">
                                                    <p className="b-block mb-0">Severity</p>
                                                    <span className={`mr-2 badge ${this.state.details.severity && this.state.details.severity.toLowerCase() !== 'nan' ? 'risk-badge-'+this.state.details.severity.toLowerCase() : 'badge-secondary' }`}>  
                                                        {this.state.details.severity ? capitalizeFirstLetter(this.state.details.severity) : 'Unknown'}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <div className="py-1 w-50">
                                                    <p className="b-block mb-0">Time</p>
                                                    <p className="mb-0 text-white">
                                                            {this.state.details.anomaly_time ? momentConvertionUtcToLocalTime(this.state.details.anomaly_time, 'DD MMM YYYY HH:mm') : <span>&nbsp;</span>}
                                                    </p>
                                                </div>
                                                <div className="py-1 w-50 pl-3">
                                                    <p className="b-block mb-0">Type</p>
                                                    <p className="mb-0 text-white">
                                                        {this.state.details.anomaly_type ? this.state.details.anomaly_type : <span>&nbsp;</span>}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="d-flex pt-2 border-top">
                                                <div className="py-1 w-50">
                                                    <p className="b-block mb-0">Score</p>
                                                    <p className="mb-0 text-white">
                                                            {this.state.details.anomaly_score}
                                                    </p>
                                                </div>
                                                <div className="py-1 w-50 pl-3">
                                                    <p className="b-block mb-0">Value</p>
                                                    <p className="mb-0 text-white">
                                                        {this.state.details.anomaly_value}
                                                    </p>
                                                </div>
                                            </div>                    
                                            
                                            <div className="d-flex pt-2 border-top">
                                                <div className="py-1 w-50">
                                                    <p className="b-block mb-0">Run Time</p>
                                                    <p className="mb-0 text-white">
                                                        {this.state.details.run_time ? momentConvertionUtcToLocalTime(this.state.details.run_time, 'DD MMM YYYY HH:mm') : <span>&nbsp;</span>}
                                                    </p>
                                                </div>
                                                <div className="py-1 w-50 pl-3">
                                                    <p className="b-block mb-0">Product</p>
                                                    {this.state.details.product ? 
                                                        this.state.details.product.map(item => {
                                                            return (
                                                            <small className={`badge badge-secondary mr-1`}>{item}</small>
                                                            )
                                                        }) 
                                                    : null
                                                    }
                                                </div>
                                            </div>

                                            {this.state.details.additional_details && Object.entries(this.state.details.additional_details).length ?
                                                <div className="col-sm-12">
                                                    <div className="row">
                                                    {Object.entries(this.state.details.additional_details).map(([key, value], addIdx) => {
                                                        return(
                                                            typeof value === "string" ? 
                                                                <React.Fragment>
                                                                    <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                    <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                        <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                        <p className="mb-0 text-white">{value}</p>
                                                                    </div>
                                                                </React.Fragment>
                                                            : null
                                                        )
                                                    })}
                                                    </div>
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.state.details && this.state.details.type === "sequence" && this.state.details.preceding_sequence && this.state.details.preceding_sequence.length ?
                                <div className={`card bg-dark3 border-0 mb-2`}>
                                    <div id={'heading_config_assets'} onClick={() => this.setState({ expandAnomalyPrecedingDetails: !this.state.expandAnomalyPrecedingDetails })}>
                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                            <div className="text-white"><span className={`far ${this.state.expandAnomalyPrecedingDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Preceding Sequence</div>
                                        </div>
                                    </div>
                                    <div id={'collapse_config_assets'} className={`collapse ${this.state.expandAnomalyPrecedingDetails ? 'show' : <span>&nbsp;</span>}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                        <ul className="precedingUl">
                                            {this.state.details.preceding_sequence.map((item, i) => {
                                                    return(
                                                        <li className="d-flex mt-2">
                                                            <span className="ulBullet mt-1 p-1"></span>
                                                            <span className="ml-2 align-self-end cursorPointer text-white" id={"asset_name_"+i}>{item}</span>
                                                            {/* <UncontrolledTooltip target={"asset_name_"+i}>{item.asset_name}</UncontrolledTooltip> */}
                                                        
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            : null}

                            {this.state.details && (this.state.details.asset_name || this.state.details.asset_id) ?
                                <div className={`card bg-dark3 border-0 mb-2`}>
                                    <div id={'heading_config_assets'} onClick={() => this.setState({ expandPropsAssetDetails: !this.state.expandPropsAssetDetails })}>
                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                            <div className="text-white"><span className={`far ${this.state.expandPropsAssetDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Asset Details</div>
                                            {/* <div className="text-info">Showing asset details</div> */}
                                        </div>
                                    </div>
                                    <div id={'collapse_config_assets'} className={`collapse ${this.state.expandPropsAssetDetails ? 'show' : <span>&nbsp;</span>}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                        <div className="card-body m-2 pt-2 rounded counts_threads">
                                            <div className="d-flex">
                                                <div className="py-1 w-50">
                                                    <p className="b-block mb-0">Name:</p>
                                                    <p className="mb-0 text-white">{this.state.details.asset_name ? this.state.details.asset_name : <span>&nbsp;</span>}</p>
                                                </div>
                                                <div className="py-1 w-50 pl-3">
                                                    <p className="b-block mb-0">Id:</p>
                                                    <p className="mb-0 text-white">{this.state.details.asset_id ? this.state.details.asset_id : <span>&nbsp;</span>}</p>
                                                </div>
                                            </div>
                                            <div className="d-flex pt-2 border-top">
                                                <div className="py-1 w-50">
                                                    <p className="b-block mb-0">Provider / Account / Region </p>
                                                    <p className="mb-0 text-white">
                                                        {this.state.details.provider ? this.state.details.provider.toUpperCase() : <span>&nbsp;</span>}
                                                        {this.state.details.account_id ? (' : ')+this.state.details.account_id : <span>&nbsp;</span>}
                                                        {this.state.details.region ? (' : ')+this.state.details.region : <span>&nbsp;</span>}
                                                    </p>
                                                </div>
                                                <div className="py-1 w-50 pl-3">
                                                    <p className="b-block mb-0">Service</p>
                                                    <p className="mb-0 text-white">
                                                        {this.state.details.resource_type ? this.state.details.resource_type : <span>&nbsp;</span>}
                                                        {this.state.details.service_name ? ('  ')+this.state.details.service_name : <span>&nbsp;</span>}
                                                    </p>
                                                </div>
                                            </div>
                                            {this.props.asset_arn ? 
                                                <div className="d-flex pt-2 border-top">
                                                    <div className="py-1 w-100">
                                                        <p className="b-block mb-0">ARN:</p>
                                                        <p className="mb-0 text-white">{this.props.asset_arn}</p>
                                                    </div>
                                                </div>
                                            : null}
                                            {this.state.details.category ? 
                                                <div className="d-flex pt-2 border-top">
                                                    <div className="py-1 w-100">
                                                        <p className="b-block mb-0">Category:</p>
                                                        <div className={`d-flex flex-wrap`}>
                                                            {this.state.details.category && Array.isArray(this.state.details.category) ? 
                                                                this.state.details.category.map(item => {
                                                                    return (
                                                                    <small className={`badge badge-secondary mr-1`}>{item}</small>
                                                                    )
                                                                }) 
                                                            : this.state.details.category ?
                                                                <small className={`badge badge-secondary mr-1`}>{this.state.details.category}</small>
                                                            : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                            : null}

                            {this.state.details && this.state.details.logs && this.state.details.logs.length ?
                                <div className={`card bg-dark3 border-0 mb-2`}>
                                    <div id={'heading_config_assets'} onClick={() => this.setState({ expandPropsLogDetails: !this.state.expandPropsLogDetails })}>
                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                            <div className="text-white"><span className={`far ${this.state.expandPropsLogDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Log Details</div>
                                            {this.state.expandPropsLogDetails ?
                                                <div className="text-info">Showing {this.state.details.count} observed anomalies</div>
                                                // <p className="small mb-0 ml-3">Showing {this.state.details.count} observed anomalies</p>
                                            : null}
                                        </div>
                                    </div>                                    
                                    <div id={'collapse_config_assets'} className={`collapse ${this.state.expandPropsLogDetails ? 'show' : <span>&nbsp;</span>}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                        <div className={`rounded bg-dark3 mx-3 mb-3`} style={{maxHeight: "600px", overflowY: "auto"}}>
                                            {this.state.details.logs.map((item, i) => {
                                                    return(
                                                        <div className={`d-flex flex-wrap mr-2 px-2 mb-2 pb-1 pt-1 ${i ? "border-top-black5" : ""}`}>
                                                            <p className="mb-0 text-break">
                                                                <span className="mb-0 mr-2 f12 text-white font-weight-bold">{item.timestamp ? momentConvertionUtcToLocalTime(item.timestamp, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                <span className={`mb-0 mr-2 f12 text-purple-2`}>{item.log_file ? item.log_file : ""}</span>
                                                                {item.log_event_id ?
                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold">{item.log_event_id}</span>
                                                                : null}
                                                                {item.message ?
                                                                    <span className="mb-0 ml-2 f12 text-white font-weight-bold">{item.message}</span>
                                                                : null}
                                                            </p>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            : null}

                            <div className={`card bg-dark3 border-0 mb-2`}>
                                <div id={'heading_config_assets'} onClick={() => this.setState({ expandTopDetails: !this.state.expandTopDetails })}>
                                    <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                        <div className="text-white"><span className={`far ${this.state.expandTopDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Top 5 Anomalies</div>
                                    </div>
                                </div>
                                <div id={'collapse_config_assets'} className={`collapse ${this.state.expandTopDetails ? 'show' : <span>&nbsp;</span>}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                    <div className="row mb-3">
                                        <div className="col-lg-6">
                                            <div className="bg-dark p-3 rounded">
                                                <label>Top 5 Anomalies</label>
                                                {this.state.topAnomalies && Object.entries(this.state.topAnomalies).length ?
                                                    <div className="mt-n4"> 
                                                        <ApexBarChart
                                                            graphData={this.state.topAnomalies}
                                                            sparkline={false}
                                                            yaxis={false}
                                                            yaxisLabel={false}
                                                            xaxis={true}
                                                            xaxisFormat={'string'}
                                                            xaxisLabel={true}
                                                            axisLabelColor={'#50586A'}
                                                            paddingLeft={-10}
                                                            legend={false}
                                                            stacked={false}
                                                            height={240}
                                                            horizontal={true}
                                                            barHeight={'40%'}
                                                            barEndShape={'rounded'}
                                                            columnWidth={'25%'}
                                                            gradient={true}
                                                            gradientColor={['#039BE5', '#5F5BA2']}
                                                            hideTooltipValue={true}
                                                            backgroundBarShape={'rounded'}
                                                            backgroundBarColors={['#333947']}
                                                            showBackgroundBarColors={false}
                                                            className={"transparentTooltip"}
                                                        />
                                                    </div>
                                                : null}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 pl-0">
                                            <div className="bg-dark p-3 rounded">
                                                <label>Top 5 Assets</label>
                                                {this.state.topAssets && Object.entries(this.state.topAssets).length ?
                                                    <div className="mt-n4"> 
                                                        <ApexBarChart
                                                            graphData={this.state.topAssets}
                                                            sparkline={false}
                                                            yaxis={false}
                                                            yaxisLabel={false}
                                                            xaxis={true}
                                                            xaxisFormat={'string'}
                                                            xaxisLabel={true}
                                                            axisLabelColor={'#50586A'}
                                                            paddingLeft={-10}
                                                            legend={false}
                                                            stacked={false}
                                                            height={240}
                                                            horizontal={true}
                                                            barHeight={'40%'}
                                                            barEndShape={'rounded'}
                                                            columnWidth={'25%'}
                                                            gradient={true}
                                                            gradientColor={['#039BE5', '#5F5BA2']}
                                                            hideTooltipValue={true}
                                                            backgroundBarShape={'rounded'}
                                                            backgroundBarColors={['#333947']}
                                                            showBackgroundBarColors={false}
                                                            className={"transparentTooltip"}
                                                        />
                                                    </div>
                                                : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    : null
                : this.state.selectedTab === 'Metrics' ? 
                    <MetricsTab 
                        merticsAssetNameInvolved = {this.state.merticsAssetNameInvolved}
                        brushStartTime={this.state.brushStartTime}
                        brushEndTime={this.state.brushEndTime}
                        details= {this.state.details}
                        // menuDisabled= {() => this.setState({ menuDisabled: false })}
                    />
                : null}
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
RightSection.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log('errorDashboard',state)
    return {
        accounts: state.filters.accounts,
        tabOptions: state.cost.costPropsDetails && state.cost.costPropsDetails.tabOptions ? state.cost.costPropsDetails.tabOptions : {},
        costCommonPageFilter: state.cost.costPropsDetails && state.cost.costPropsDetails.costCommonPageFilter ? state.cost.costPropsDetails.costCommonPageFilter : {},
    }
}

export default connect(mapStateToProps, {
    getCostTopAnomalies
})(withRouter(RightSection))