/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file MetricsTab.js
 * @author Prakash // on 01/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import _ from 'lodash'

import { Spinner } from 'reactstrap'

import { momentDateGivenFormat, momentConvertionUtcToLocalTime, convertBytes, convertSeconds, countFormater, convertBits } from '../../../utils/utility'

import Chart from 'react-apexcharts'

class MetricsTab extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            showAnnotation: true,
            loadingMetrics: true,
            details: this.props.details
        }
    }

    componentDidMount = () => {
        console.log("this.props.observabilityMicroServicesList", this.props.observabilityMicroServicesList)
        console.log("this.state.details", this.state.details)
        let microServiceTrends = this.props.observabilityMicroServicesList && this.props.observabilityMicroServicesList.filter(e => e.microservice_id === this.state.details.microservice_id) && this.props.observabilityMicroServicesList.filter(e => e.microservice_id === this.state.details.microservice_id).length ? this.props.observabilityMicroServicesList.filter(e => e.microservice_id === this.state.details.microservice_id)[0] : {}
        console.log("microServiceTrends", microServiceTrends)
        this.setState({ microServiceTrends  },
            () => this.formGraphDetails()                
        )
    }

    componentDidUpdate = (prevProps) => {
        // if(!prevProps.observabilityMicroServicesList && this.props.observabilityMicroServicesList && !_.isEqual(prevProps.observabilityMicroServicesList, this.props.observabilityMicroServicesList)) {
            
        // }
    }
    
    formGraphDetails = () => {
        let graphDetails = this.state.microServiceTrends
        graphDetails && graphDetails.trends && graphDetails.trends.data && Object.entries(graphDetails.trends.data).forEach(([key, item], i) => {
            let array = []
            let series = []
            let options = {}
            if(item.values && item.values.length) {
                item.values.forEach((val, j) => {
                    let arrayValues = []
                    arrayValues.push(momentConvertionUtcToLocalTime(graphDetails.trends.dates[j], 'YYYY-MM-DD HH:mm:ss'))
                    // if(item.unit) {
                    //     val = this.getGrapUnitValue(val, item.unit)
                    // }
                    arrayValues.push(val)
                    array.push(arrayValues)
                })

                series = [{
                    data: array
                }]
                
                let xaxisAnnotation = []

                let annotations = {
                    xaxis: xaxisAnnotation
                }

                options = {
                    chart: {
                        // group: 'metrics_and_sre',
                        // id:'metrics_'+i,
                        type: 'area',
                        width: '100%',
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false
                        },
                        sparkline: {
                            enabled: false
                        }
                    },
                    stroke: {
                        show: true,
                        curve: 'smooth',
                        lineCap: 'butt',
                        width: 2,
                        dashArray: 0,      
                    },
                    colors: ['#775BA2'],
                    fill: {
                        type: "gradient",
                        gradient: {
                            // shadeIntensity: 1,
                            // opacityFrom: 0.7,
                            // opacityTo: 0.9,
                            // stops: [0, 90, 100]
                            gradientToColors: ['#775BA2'],
                            shadeIntensity: 0,
                            opacityFrom: .3,
                            opacityTo: 1,
                            stops: [30, 90]
                        }
                    },
                    tooltip: {
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                            let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
                            dateLable = dateLable.toString()
                            dateLable = momentDateGivenFormat(dateLable, 'DD MMM HH:mm')
                            let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]
                            return '<div class="arrow_box">'+dateLable+' <span style="color:'+ w.globals.colors +'">' + parseFloat(val).toFixed(2) + '</span> </div>'
                        },
                        fixed: {
                            enabled: false
                        },
                        x: {
                            show: false,
                        },
                        y: {
                            show: false
                        },        
                        marker: {
                            show: false
                        }
                    },
                    xaxis: {
                        type: 'datetime',
                        labels: {
                            datetimeUTC: false,
                            style: {
                                colors: '#60687C'
                            },
                            // formatter: function (value) {
                            //     return momentDateGivenFormat(value, 'HH:mm')
                            // },
                        },                            
                        axisTicks: {
                            show: false
                        },
                        tooltip: {
                            enabled: false
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    yaxis: {
                        show: true,
                        axisTicks: {
                            show: false,
                        },
                        axisBorder: {
                            show: false,
                            color: '#60687C'
                        },
                        tooltip: {
                            enabled: false
                        },
                        // tickAmount: 2,
                        min: 0,
                        // max: yaxisMaxValue, 
                        tickAmount: 1,
                        labels: {
                            offsetX: -10,
                            // minWidth: 50,
                            show: true,
                            style: {
                                fontSize: '9px',
                                colors: '#60687C'
                            },
                            formatter: (value) => { 
                                if(Number(value) === value && value % 1 === 0) {
                                    return Math.ceil(value.toFixed(1))
                                } else {
                                    return Math.ceil(value.toFixed(1))
                                }
                            },
                            // offsetX: 0,
                            // offsetY: 0,
                        }
                    },
                    grid: {
                        show: true,
                        borderColor: '#2D323E',
                        strokeDashArray: 0,
                        position: 'back',
                        xaxis: {
                            lines: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: ['#FFF'],
                                }
                            },
                            axisTicks: {
                                show: false
                            }
                        },   
                        yaxis: {
                            lines: {
                                show: false
                            },
                            labels: {
                                style: {
                                    colors: ['#969AA7'],
                                }
                            }
                        },                
                        padding: {
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0
                        },
                    },
                    annotations: annotations,
                }
            }
            console.log(key+"_series_"+graphDetails.microservice_id, series)
            this.setState({ [key+"_series_"+graphDetails.microservice_id]: series, [key+"_options_"+graphDetails.microservice_id]: options, loadingMetrics: false })
        })
    }

    getGrapUnitValue = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytes(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit)
            } else if(unit === 'Count') {
                // val = val > 1 ? parseInt(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    render() {
        return (
            <div className="mt-3">
                <div className="d-flex mb-1 justify-content-between">
                    <div className='d-flex'>
                        <p className="f12 m-0 align-self-center">Showing metrics for the period of</p>
                        <p className="f12 mb-0 align-self-center text-info ml-2">{this.props.microSericesFilters && this.props.microSericesFilters.start_time ? momentConvertionUtcToLocalTime(this.props.microSericesFilters.start_time, 'DD MMM HH:mm') +' - '+ momentConvertionUtcToLocalTime(this.props.microSericesFilters.end_time, 'DD MMM YYYY HH:mm') : ''}</p>
                    </div>
                    <p className="mb-0 text-info d-flex justify-content-end align-self-center cursorPointer" onClick={() => this.props.backtoMicroServiceList()} >Back</p>
                </div>
                {this.state.loadingMetrics ?
                    <div className='d-flex justify-content-center m-4'>
                        <Spinner className='text-center' color='white' size='lg' />
                    </div>
                : this.state.microServiceTrends ?
                    <div className="p-2">
                        {this.state.microServiceTrends.trends && this.state.microServiceTrends.trends.data ?
                            Object.entries(this.state.microServiceTrends.trends.data).map(([key, keyData], i) => {
                                console.log(key, this.state.microServiceTrends.microservice_id)
                                return (
                                    <div className="p-3 border rounded bg-dark3 mb-2">
                                        <p className="text-white f16 p-1 mb-1">{key} <span className="text-info f12">({keyData.unit})</span></p>
                                        {this.state[key+"_series_"+this.state.microServiceTrends.microservice_id] && this.state[key+"_series_"+this.state.microServiceTrends.microservice_id].length && this.state[key+"_options_"+this.state.microServiceTrends.microservice_id] ?
                                            <div className="cursorPointer transparentTooltip mb-n4 mt-n4">
                                                <Chart options={this.state[key+'_options_'+this.state.microServiceTrends.microservice_id]} series={this.state[key+'_series_'+this.state.microServiceTrends.microservice_id]} type="area" height={120}/>
                                            </div>
                                        : 
                                            <div style={{minHeight:"85px"}} className="d-flex align-self-center justify-content-center">
                                                <div className='align-self-center'>
                                                    No Data
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        : 
                            <div className='d-flex justify-content-center m-4'>
                                <p>There are no metrics for the selected Asset.</p>
                            </div>
                        }
                    </div>
                : 
                    <div className='d-flex justify-content-center m-4'>
                        <p>There are no metrics for the selected Asset.</p>
                    </div>
                }
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
MetricsTab.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log('errorDashboard',state)
    return {
        errorEventPageMonitoringList: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.errorEventPageMonitoringList ? state.aiops.aiopsPropsDetails.errorEventPageMonitoringList : {},
        observabilityMicroServicesList: state.observability.microServiesPropsDetails.observabilityMicroServicesList ? state.observability.microServiesPropsDetails.observabilityMicroServicesList : [],
        microSericesFilters: state.observability.microServiesPropsDetails.microSericesFilters ? state.observability.microServiesPropsDetails.microSericesFilters : {}
    }
}

export default connect(mapStateToProps, {})(withRouter(MetricsTab))