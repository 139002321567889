import React from 'react';
import { Link } from 'react-router-dom';
import { URL_PATH } from '../config/urlPath'
//import PageNotFound from '../assets/images/PageNotFound';
class NotFoundPage extends React.Component{
    render(){
        return <div className="m-auto">
            {/* <img src={PageNotFound}  /> */}
            <h1>404 - Page Not Found!</h1>
            <p className="text-center">
              <Link to={URL_PATH.AIOPS_DASHBOARD}>Go to Tvaṣṭṛ Home </Link>
            </p>
          </div>;
    }
}
export default NotFoundPage;