/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file diagnosticsLeftSection.js
 * @author Prakash // on 22/02/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component  } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'

import SearchWithHiddenInput from '../../common/SearchWithHiddenInput'
import { momentConvertionUtcToLocalTime } from '../../../utils/utility'
import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'
import { 
    listDiagnostics, 
    getDiagnosticDetails,
    startDiagnostics
} from '../../../actions/aiops/DiagnosticsAction'

// const useScript = url => {
//     useEffect(() => {
//       const script = document.createElement('script');
//       script.src = url;
//       script.async = true;
//       document.body.appendChild(script);
//       return () => {
//         document.body.removeChild(script);
//       };
//     }, [url]);
// };

class DiagnosticsLeftSection extends Component {
    timelineCircleChartRef = React.createRef()
	diagnosticsSlider = React.createRef()

    constructor(props) {
        super(props)
        this.state = {
            expandMenuId: 0,
            activeCollapseMenu: 0,
            showLoading: true,

            totalItemShowing: 0,
        }
    }

	componentDidMount = () => {
        // const script = document.createElement("script");
        // script.src = "../../assets/js/bootstrap.bundle.js";
        // script.async = true;
        // script.onload = () => this.scriptLoaded();
        // const script = document.createElement("script");
        // script.src = "../../assets/js/bootstrap.bundle.js";
        // script.async = true;
        // document.body.appendChild(script)

        
        // let startParams = {}
        // startParams.case_id = this.state.caseId
        // this.props.startDiagnostics(startParams, (promise, response) => {
        //     if(promise) {
        //         this.setState({ diagnostics: response.results ? response.results : {} })
        //     } else {
        //         this.setState({ diagnostics: {} })
        //     }
        // })
        
        if(!this.props.aiopsDiagnosticsList || !this.props.aiopsDiagnosticsList.length) {
            let params = {}
            params.case_id = this.props.caseDetails.case_id
            // params.case_id = 'sample_case4'
            this.props.listDiagnostics(params, (promise, response) => {
                if(promise) {
                    this.setState({ diagnosticsList: response.results, diagnostic_id: response.results.length ? response.results[0].diagnostic_id : "", showTimeline: true },
                        () => {
                            this.getDiagnosticDetails()
                            this.slideRight('initialize')
                            
                            window.addEventListener('resize', () => {
                                this.setState({ showTimeline : false },
                                    () => this.setState({ showTimeline: true, totalItemShowing: 0 }, () => this.slideRight('initialize'))    
                                )
                            })
                        }    
                    )
                } else {
                    this.setState({ diagnosticsList: {}, showLoading: false })
                }
            })
        } else {
            this.setState({ diagnosticsList: this.props.aiopsDiagnosticsList, diagnostic_id: this.props.aiopsDiagnosticsList.length ? this.props.aiopsDiagnosticsList[0].diagnostic_id : "", showTimeline: true },
                () => {
                    this.getDiagnosticDetails()
                    this.slideRight('initialize')
                    window.addEventListener('resize', () => {
                        this.setState({ showTimeline : false },
                            () => this.setState({ showTimeline: true, totalItemShowing: 0 }, () => this.slideRight('initialize'))    
                        )
                    })
                }    
            )
        }

        setTimeout(() => { this.setState({ showLoading: false }) }, 5000)
    }

    scriptLoaded() {
        window.A.sort();
    }

    getDiagnosticDetails = () => {
        // if(!this.props.aiopsDiagnosticsDetails || !Object.entries(this.props.aiopsDiagnosticsDetails).length) {
            let params = {}
            params.diagnostic_id = this.state.diagnostic_id
            this.props.getDiagnosticDetails(params, (promise, response) => {
                if(promise) {
                    this.setState({ diagnosticDetails: response.result, showLoading: false, expandMenuId: 0, activeCollapseMenu: 0 },
                        () => {
                            this.props.setAiopsPropsDetails('selectedDiagnosticEvent', {})
                            this.filterObservedandNotObservedDiagnosticsDetails()
                        }
                    )
                } else {
                    this.setState({ diagnosticDetails: {}, showLoading: false, expandMenuId: 0, activeCollapseMenu: 0 })
                }
            })
        // } else {
        //     this.setState({ diagnosticDetails: this.props.aiopsDiagnosticsDetails, showLoading: false },
        //         () => {
        //             this.props.setAiopsPropsDetails('selectedDiagnosticEvent', {})
        //             this.filterObservedandNotObservedDiagnosticsDetails()
        //         }
        //     )
        // }
    }

    filterObservedandNotObservedDiagnosticsDetails = () => {
        let observedDiagnostics = []
        let notObservedDiagnostics = []
        let obInc = 0;
        if(this.state.diagnosticDetails && Object.entries(this.state.diagnosticDetails).length) {
            this.state.diagnosticDetails.diagnostics && 
            this.state.diagnosticDetails.diagnostics.forEach(item => {
                if(item.data && item.data.length && item.label !== "DB SlowQuery Events") {
                    item.orderBy = obInc
                    if(item.label === 'Application Changes') {
                        this.setState({ ['diagnostic_label_'+item.orderBy]: item.label, ['diagnostic_data_'+item.orderBy]: item.data, ['filtered_diagnostic_data_'+item.orderBy]: item.data },
                            () => {
                                if(!item.orderBy) {
                                    this.onChangeDiagnosticEvent(item.label, item.data[0], item.orderBy, 'app_changes_'+item.orderBy+'_0', item.data[0].asset_id, "")
                                }
                            }
                        )
                    } else if(item.label === 'DB Audit MySQL' || item.label === 'DB Audit PostgreSQL') {
                        let array = []
                        let totalCount = 0
                        let totalDatabases = item.data.length
                        item.data.forEach(changes => {
                            Object.entries(changes).forEach(([key, value], j) => {
                                if(key === 'index_changes' || key === 'table_changes' || key === 'view_changes' || key === 'other_changes') {
                                    let dataRow = {}
                                    dataRow.asset_id = changes.asset_id
                                    dataRow.key = key
                                    dataRow.count = value.statistics.reduce(function(a, b){ return a + b['count']; }, 0);
                                    dataRow.action = dataRow.count > 1 ? 'Changes' : 'Change'
                                    totalCount +=dataRow.count
                                    dataRow.description = value.description
                                    dataRow.details = value.details
                                    array.push(dataRow)
                                }
                            })
                        })
    
                        this.setState({ ['diagnostic_label_'+item.orderBy]: item.label, ['diagnostic_db_count_'+item.orderBy]: totalDatabases, ['diagnostic_count_'+item.orderBy]: totalCount, ['diagnostic_data_'+item.orderBy]: array, ['filtered_diagnostic_data_'+item.orderBy]: array },
                            () => {
                                if(!item.orderBy) {
                                    this.onChangeDiagnosticEvent(item.label, this.state['filtered_diagnostic_data_'+item.orderBy][0], item.orderBy, 'db_'+item.orderBy+'_0', this.state['filtered_diagnostic_data_'+item.orderBy][0].asset_id, this.state['filtered_diagnostic_data_'+item.orderBy][0].key)
                                }
                            }
                        )
                    } else if(item.label === 'Health Events') {
                        this.setState({ ['diagnostic_label_'+item.orderBy]: item.label, ['diagnostic_data_'+item.orderBy]: item.data, ['filtered_diagnostic_data_'+item.orderBy]: item.data },
                            () => {
                                if(!item.orderBy) {
                                    this.onChangeDiagnosticEvent(item.label, item.data[0], item.orderBy, 'health_event_'+item.orderBy+'_0', "", "")
                                }
                            }
                        )
                    } else if(item.label === 'Maintenance and Scheduled Events') {
                        this.setState({ ['diagnostic_label_'+item.orderBy]: item.label, ['diagnostic_data_'+item.orderBy]: item.data, ['filtered_diagnostic_data_'+item.orderBy]: item.data },
                            () => {
                                if(!item.orderBy) {
                                    this.onChangeDiagnosticEvent(item.label, item.data[0], item.orderBy, 'maintsch_event_'+item.orderBy+'_0', "", "")
                                }
                            }
                        )
                    } else if(item.label === 'Capacity Changes') {
                        this.setState({ ['diagnostic_label_'+item.orderBy]: item.label, ['diagnostic_data_'+item.orderBy]: item.data, ['filtered_diagnostic_data_'+item.orderBy]: item.data },
                            () => {
                                if(!item.orderBy) {
                                    this.onChangeDiagnosticEvent(item.label, item.data[0], item.orderBy, 'capacity_changes_'+item.orderBy+'_0', item.data[0].asset_id, "")
                                }
                            }
                        )
                    } else if(item.label === 'Infra Changes') {
                        this.setState({ ['diagnostic_label_'+item.orderBy]: item.label, ['diagnostic_data_'+item.orderBy]: item.data, ['filtered_diagnostic_data_'+item.orderBy]: item.data },
                            () => {
                                if(!item.orderBy) {
                                    this.onChangeDiagnosticEvent(item.label, item.data[0], item.orderBy, 'infra_changes_'+item.orderBy+'_0', item.data[0].asset_id, "")
                                }
                            }
                        )
                    } else if(item.label === 'SRE Observations') {
                        this.setState({ ['diagnostic_label_'+item.orderBy]: item.label, ['diagnostic_data_'+item.orderBy]: item.data, ['filtered_diagnostic_data_'+item.orderBy]: item.data },
                            () => {
                                if(!item.orderBy) {
                                    this.onChangeDiagnosticEvent(item.label, item.data[0], item.orderBy, 'sre_events_'+item.orderBy+'_0', item.data[0].sre_asset_id, "")
                                }
                            }
                        )
                    } else if(item.label === 'DB SlowQuery Events') {
                        this.setState({ ['diagnostic_label_'+item.orderBy]: item.label, ['diagnostic_data_'+item.orderBy]: item.data, ['filtered_diagnostic_data_'+item.orderBy]: item.data },
                            () => {
                                if(!item.orderBy) {
                                    this.onChangeDiagnosticEvent(item.label, item.data[0], item.orderBy, 'db_slow_query_event_'+item.orderBy+'_0', item.data[0].dbslowq_asset_id, "")
                                }
                            }
                        )
                    } else if(item.label === 'DB SlowQuery MySQL' || item.label === 'DB SlowQuery PostgreSQL') {
                        this.setState({ ['diagnostic_label_'+item.orderBy]: item.label, ['diagnostic_data_'+item.orderBy]: item.data, ['filtered_diagnostic_data_'+item.orderBy]: item.data },
                            () => {
                                if(!item.orderBy) {
                                    this.onChangeDiagnosticEvent(item.label, item.data[0], item.orderBy, 'db_slow_query_mysql_'+item.orderBy+'_0', item.data[0].dbslowq_asset_id, "")
                                }
                            }
                        )
                    } else if(item.label === 'Reachability Analysis') {
                        this.setState({ ['diagnostic_label_'+item.orderBy]: item.label, ['diagnostic_data_'+item.orderBy]: item.data, ['filtered_diagnostic_data_'+item.orderBy]: item.data },
                            () => {
                                if(!item.orderBy) {
                                    this.onChangeDiagnosticEvent(item.label, item.data[0], item.orderBy, 'reachability_analysis_'+item.orderBy+'_0', item.data[0].dbslowq_asset_id, "")
                                }
                            }
                        )
                    } else if(item.label === 'Connectivity Checks') {
                        this.setState({ ['diagnostic_label_'+item.orderBy]: item.label, ['diagnostic_data_'+item.orderBy]: item.data, ['filtered_diagnostic_data_'+item.orderBy]: item.data },
                            () => {
                                if(!item.orderBy) {
                                    this.onChangeDiagnosticEvent(item.label, item.data[0], item.orderBy, 'connectivity_'+item.orderBy+'_0', item.data[0].dbslowq_asset_id, "")
                                }
                            }
                        )
                    }
                    obInc++;
                    if(item.label !== "DB SlowQuery Events") {
                        observedDiagnostics.push(item)
                    }
                } else {
                    if(item.label !== "DB SlowQuery Events") {
                        notObservedDiagnostics.push(item)
                    }
                }
            })
        }

        this.setState({ observedDiagnostics, notObservedDiagnostics })
    }

    expandCollapse = (expandMenuId, label) => {
        if(this.state.activeCollapseMenu !== expandMenuId) {
            this.onClickExpandMenu(label)
        }
        this.setState({ expandMenuId: expandMenuId === this.state.expandMenuId ? "" : expandMenuId, activeCollapseMenu: expandMenuId })
    }

    onClickExpandMenu = (label) => {
        if(this.state.observedDiagnostics && Object.entries(this.state.observedDiagnostics).length) {
            this.state.observedDiagnostics && this.state.observedDiagnostics.forEach((item, index) => {
                if(item.label === 'Application Changes' && label === 'Application Changes') {
                    this.setState({ ['diagnostic_label_'+index]: item.label, ['diagnostic_data_'+index]: item.data, ['filtered_diagnostic_data_'+index]: item.data },
                        () => {
                            this.onChangeDiagnosticEvent(item.label, item.data[0], index, 'app_changes_'+index+'_0', item.data[0].asset_id, "")
                        }
                    )
                } else if((item.label === 'DB Audit MySQL' && label === 'DB Audit MySQL') || (item.label === 'DB Audit PostgreSQL' && label === 'DB Audit PostgreSQL')) {
                    let array = []
                    let totalCount = 0
                    let totalDatabases = item.data.length
                    item.data.forEach(changes => {
                        Object.entries(changes).forEach(([key, value], j) => {
                            if(key === 'index_changes' || key === 'table_changes' || key === 'view_changes' || key === 'other_changes') {
                                let dataRow = {}
                                dataRow.asset_id = changes.asset_id
                                dataRow.key = key
                                dataRow.count = value.statistics.reduce(function(a, b){ return a + b['count']; }, 0);
                                dataRow.action = dataRow.count > 1 ? 'Changes' : 'Change'
                                totalCount +=dataRow.count
                                dataRow.description = value.description
                                dataRow.details = value.details
                                array.push(dataRow)
                            }
                        })
                    })

                    this.setState({ ['diagnostic_label_'+index]: item.label, ['diagnostic_db_count_'+index]: totalDatabases, ['diagnostic_count_'+index]: totalCount, ['diagnostic_data_'+index]: array, ['filtered_diagnostic_data_'+index]: array },
                        () => {
                            this.onChangeDiagnosticEvent(item.label, this.state['filtered_diagnostic_data_'+index][0], index, 'db_'+index+'_0', this.state['filtered_diagnostic_data_'+index][0].asset_id, this.state['filtered_diagnostic_data_'+index][0].key)
                        }
                    )
                } else if(item.label === 'Health Events' && label === 'Health Events') {
                    this.setState({ ['diagnostic_label_'+index]: item.label, ['diagnostic_data_'+index]: item.data, ['filtered_diagnostic_data_'+index]: item.data },
                        () => {
                            this.onChangeDiagnosticEvent(item.label, item.data[0], index, 'health_event_'+index+'_0', "", "")
                        }
                    )
                } else if(item.label === 'Maintenance and Scheduled Events' && label === 'Maintenance and Scheduled Events') {
                    this.setState({ ['diagnostic_label_'+index]: item.label, ['diagnostic_data_'+index]: item.data, ['filtered_diagnostic_data_'+index]: item.data },
                        () => {
                            this.onChangeDiagnosticEvent(item.label, item.data[0], index, 'maintsch_event_'+index+'_0', "", "")
                        }
                    )
                } else if(item.label === 'Capacity Changes' && label === 'Capacity Changes') {
                    this.setState({ ['diagnostic_label_'+index]: item.label, ['diagnostic_data_'+index]: item.data, ['filtered_diagnostic_data_'+index]: item.data },
                        () => {
                            this.onChangeDiagnosticEvent(item.label, item.data[0], index, 'capacity_changes_'+index+'_0', item.data[0].asset_id, "")
                        }
                    )
                } else if(item.label === 'Infra Changes' && label === 'Infra Changes') {
                    this.setState({ ['diagnostic_label_'+index]: item.label, ['diagnostic_data_'+index]: item.data, ['filtered_diagnostic_data_'+index]: item.data },
                        () => {
                            if(item.data && item.data.length) {
                                this.onChangeDiagnosticEvent(item.label, item.data[0], index, 'infra_changes_'+index+'_0', item.data[0].asset_id, "")
                            } else if(item.data || (item.data && !item.data.length)) {
                                this.props.setAiopsPropsDetails('selectedDiagnosticEvent', {})
                            }
                        }
                    )
                } else if(item.label === 'SRE Observations' && label === 'SRE Observations') {
                    this.setState({ ['diagnostic_label_'+index]: item.label, ['diagnostic_data_'+index]: item.data, ['filtered_diagnostic_data_'+index]: item.data },
                        () => {
                            if(item.data && item.data.length) {
                                this.onChangeDiagnosticEvent(item.label, item.data[0], index, 'sre_events_'+index+'_0', item.data[0].sre_asset_id, "")
                            } else if(item.data || (item.data && !item.data.length)) {
                                this.props.setAiopsPropsDetails('selectedDiagnosticEvent', {})
                            }
                        }
                    )
                } else if(item.label === 'DB SlowQuery Events' && label === 'DB SlowQuery Events') {
                    this.setState({ ['diagnostic_label_'+index]: item.label, ['diagnostic_data_'+index]: item.data, ['filtered_diagnostic_data_'+index]: item.data },
                        () => {
                            this.onChangeDiagnosticEvent(item.label, item.data[0], index, 'db_slow_query_event_'+index+'_0', item.data[0].dbslowq_asset_id, "")
                        }
                    )
                } else if((item.label === 'DB SlowQuery MySQL' && label === 'DB SlowQuery MySQL') || (item.label === 'DB SlowQuery PostgreSQL' && label === 'DB SlowQuery PostgreSQL')) {
                    this.setState({ ['diagnostic_label_'+index]: item.label, ['diagnostic_data_'+index]: item.data, ['filtered_diagnostic_data_'+index]: item.data },
                        () => {
                            this.onChangeDiagnosticEvent(item.label, item.data[0], index, 'db_slow_query_mysql_'+index+'_0', item.data[0].dbslowq_asset_id, "")
                        }
                    )
                } else if(item.label === 'Reachability Analysis' && label === 'Reachability Analysis') {
                    this.setState({ ['diagnostic_label_'+index]: item.label, ['diagnostic_data_'+index]: item.data, ['filtered_diagnostic_data_'+index]: item.data },
                        () => {
                            this.onChangeDiagnosticEvent(item.label, item.data[0], index, 'reachability_analysis_'+index+'_0', item.data[0].dbslowq_asset_id, "")
                        }
                    )
                } else if(item.label === 'Connectivity Checks' && label === 'Connectivity Checks') {
                    this.setState({ ['diagnostic_label_'+index]: item.label, ['diagnostic_data_'+index]: item.data, ['filtered_diagnostic_data_'+index]: item.data },
                        () => {
                            this.onChangeDiagnosticEvent(item.label, item.data[0], index, 'connectivity_'+index+'_0', item.data[0].dbslowq_asset_id, "")
                        }
                    )
                }
            })
        }
    }

    onChangeDiagnosticEvent = (label, data, index, selectedEvent, asset_id, tableHeading) => {
        let asset_details = []
        if(this.state.diagnosticDetails) {
            if(label === 'DB SlowQuery MySQL' || label === 'DB SlowQuery PostgreSQL') {
                let assetRow = {}
                assetRow.asset_id = data.asset_id ? data.asset_id : ""
                assetRow.asset_name = data.asset_name ? data.asset_name : ""
                assetRow.asset_arn = data.asset_arn ? data.asset_arn : ""
                assetRow.resource_type = data.resource_type ? data.resource_type : ""
                assetRow.service_name = data.service_name ? data.service_name : ""
                assetRow.provider = data.provider ? data.provider : ""
                assetRow.account_id = data.account_id ? data.account_id : ""
                assetRow.region = data.region ? data.region : ""
                asset_details.push(assetRow)
            } else if(label === 'Reachability Analysis') {
                let assetRow = {}
                assetRow.asset_id = data.asset_id ? data.asset_id : ""
                assetRow.asset_name = data.asset_name ? data.asset_name : ""
                assetRow.asset_arn = data.asset_arn ? data.asset_arn : ""
                assetRow.resource_type = data.resource_type ? data.resource_type : ""
                assetRow.service_name = data.service_name ? data.service_name : ""
                assetRow.provider = data.provider ? data.provider : ""
                assetRow.account_id = data.account_id ? data.account_id : ""
                assetRow.region = data.region ? data.region : ""
                asset_details.push(assetRow)
            } else if(label === 'DB SlowQuery Events') {
                let assetRow = {}
                assetRow.asset_id = data.dbslowq_asset_id
                assetRow.asset_name = data.dbslowq_asset_name
                assetRow.asset_arn = data.dbslowq_asset_arn
                assetRow.resource_type = data.dbslowq_resource_type
                assetRow.service_name = data.dbslowq_service_name ? data.dbslowq_service_name : ""
                assetRow.provider = data.dbslowq_provider ? data.dbslowq_provider : ""
                assetRow.region = data.dbslowq_region ? data.dbslowq_region : ""
                asset_details.push(assetRow)
            } else if(label === 'Connectivity Checks') {
                let assetRow = {}
                assetRow.asset_id = data.conntchks_asset_id
                assetRow.asset_name = data.conntchks_asset_name
                assetRow.asset_arn = data.conntchks_asset_arn
                assetRow.resource_type = data.conntchks_resource_type
                assetRow.service_name = data.conntchks_service_name ? data.conntchks_service_name : ""
                assetRow.provider = data.conntchks_provider ? data.conntchks_provider : ""
                assetRow.region = data.conntchks_region ? data.conntchks_region : ""
                assetRow.account = data.conntchks_account ? data.conntchks_account : ""
                asset_details.push(assetRow)
            } else {
                if(this.state.diagnosticDetails.case_assets && asset_id !=="") {
                    asset_details = this.state.diagnosticDetails.case_assets.filter(arr => arr.asset_id === asset_id)
                    if(!asset_details.length) {
                        asset_details = this.state.diagnosticDetails.case_assets.filter(arr => arr.asset_name === asset_id)
                    }
                } else if(this.state.diagnosticDetails.assets && asset_id !=="") {
                    asset_details = this.state.diagnosticDetails.assets.filter(arr => arr.asset_id === asset_id)
                    if(!asset_details.length) {
                        asset_details = this.state.diagnosticDetails.assets.filter(arr => arr.asset_name === asset_id)
                    }
                }
            }
        } 
        let obj = {}
        obj.label = label
        obj.data = data
        obj.asset_details = asset_details.length ? asset_details[0] : {}

        let selectedDiagnostic = ""
        if(label === 'DB Audit MySQL' || label === 'DB Audit PostgreSQL') {
            let heading = tableHeading === 'index_changes' ? 'Index Changes' : tableHeading === 'table_changes' ? 'Table Changes' : tableHeading === 'view_changes' ? 'View Changes' : tableHeading === 'other_changes' ? 'Other Changes' : ""            
            obj.tableHeading = heading            
            selectedDiagnostic = selectedEvent
            
        } else if(label === 'Application Changes' || label === 'Capacity Changes' || label === 'Health Events' || label === 'Maintenance and Scheduled Events' || label === 'Infra Changes' || label === 'SRE Observations' || label === 'DB SlowQuery Events' || label === 'DB SlowQuery MySQL' || label === 'DB SlowQuery PostgreSQL' || label === 'Reachability Analysis' || label === 'Connectivity Checks' ) {
            selectedDiagnostic = selectedEvent
        }

        this.setState({ 
            selectedDiagnostic,
            selectedDiagnosticEvent: data 
        },
            () => this.props.setAiopsPropsDetails('selectedDiagnosticEvent', obj)
        )
    }
    
	slideLeft() {
		let move = 0
		move = parseInt(this.state.totalItemShowing)
		this.setState({ totalItemShowing: this.state.totalItemShowing - 1 })
		
		let totalDiagnostics = this.state.diagnosticsList && this.state.diagnosticsList.length;
		let reqTabWidth = 0
		for (let i = 0; i < totalDiagnostics; i++) {
			reqTabWidth += this.diagnosticsSlider.current.childNodes[i].getBoundingClientRect().width + 30
		}
		let averageSliderItemWidth = reqTabWidth/totalDiagnostics
		let sliderWrapWidth = this.diagnosticsSlider.current.getBoundingClientRect().width        
		let totalItemShowing = sliderWrapWidth/averageSliderItemWidth;
        totalItemShowing = parseInt(totalItemShowing)
		if(move > totalItemShowing) {
			if (this.diagnosticsSlider.current.childNodes[move-1] && this.diagnosticsSlider.current.childNodes[move-1].getBoundingClientRect().width) {
				this.diagnosticsSlider.current.scrollLeft -= this.diagnosticsSlider.current.childNodes[move-1].getBoundingClientRect().width + 30
                this.setState({ disableRightSlider: true, disableLeftSlider: false })
                // this.setState({ showingRightIcon: true, showingOff: parseInt(this.state.showingOff - 1), showingOffStart: this.state.showingOffStart < 1 ? 1 : parseInt(this.state.showingOffStart-1)})
                // this.setState({ showingRightIcon: true, showingOff: parseInt(this.state.showingOff - 1), showingOffStart: this.state.showingOffStart < 1 ? 1 : parseInt(this.state.showingOffStart-1)})
                // this.diagnosticsSlider.current.scrollLeft += this.diagnosticsSlider.current.childNodes[move-1].getBoundingClientRect().width
			} else {
				// this.setState({ showingLeftIcon: false, totalItemShowing: totalItemShowing })
                this.setState({ totalItemShowing })
			}
		} else {
			// this.setState({ showingLeftIcon: false, totalItemShowing: totalItemShowing })
            this.setState({ totalItemShowing })
		}
	}

	slideRight(item) {
		let totalDiagnostics = this.state.diagnosticsList && this.state.diagnosticsList.length;
        
        let averageSliderItemWidth = 0
		let reqTabWidth = 0
		for (let i = 0; i < totalDiagnostics; i++) {
            reqTabWidth += this.diagnosticsSlider.current.childNodes[i].getBoundingClientRect().width + 30
            // averageSliderItemWidth = reqTabWidth //enable if all tab has same width
		}
		averageSliderItemWidth = reqTabWidth/totalDiagnostics //remove or comment if all tab are same with
		let sliderWrapWidth = this.diagnosticsSlider.current.getBoundingClientRect().width
		let totalItemsPerPage = sliderWrapWidth/averageSliderItemWidth;
        let totalItemShowing = parseInt(totalItemsPerPage)
        if(totalItemShowing > totalDiagnostics) {
            this.setState({ disableRightSlider: true })
        } else {
            this.setState({ disableRightSlider: false, disableLeftSlider: true })
        }
		let move = 0
		if(this.state.totalItemShowing === 0) {
			this.setState({ totalItemShowing })
			move = totalItemShowing
		} else {
			move = parseInt(this.state.totalItemShowing + 1)
			this.setState({ totalItemShowing: this.state.totalItemShowing + 1, disableLeftSlider: true })
		}

		if (this.diagnosticsSlider.current.childNodes[move-1] && this.diagnosticsSlider.current.childNodes[move-1].getBoundingClientRect().width) {
            if(item === "") {
                this.diagnosticsSlider.current.scrollLeft += this.diagnosticsSlider.current.childNodes[move-1].getBoundingClientRect().width + 30
                if(move > totalItemsPerPage) {
                    this.setState({ disableRightSlider: true })
                }
            }
			// if(!this.state.showingOff && this.state.showingOff < totalItemShowing) {
			// 	this.setState({ showingOff: parseInt(totalItemShowing), showingLeftIcon: true })
			// } else{
			// 	this.setState({ showingOffStart: (this.state.showingOffStart + 1), showingOff: parseInt(this.state.showingOff + 1), showingLeftIcon: true })
			// }

            // 	//const sliderCenter = sliderWrapWidth / 2 - 110
            // 	//const left = index * 110 - sliderCenter
            // 	//this.diagnosticsSlider.current.scrollLeft = left		
                
        } else {
            this.setState({ totalItemShowing: this.state.diagnosticsList.length })
		}
	}

	render() {
		return (
            <div className='col-md-12 p-3'>
				<div className={`loaderOverlay ${this.state.showLoading ? "" : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
                <div className={`d-flex ${this.state.showTimeline ? "" : 'displayNone'}`}>
                    <div className="align-self-center">
                        <span className={`fas fa-chevron-left ${this.state.disableLeftSlider ? 'disabled' : 'cursorPointer'}`} onClick={() => this.slideLeft()}></span>
                    </div>
                    <div className="stepper" ref={this.diagnosticsSlider}>
                        {this.state.diagnosticsList && this.state.diagnosticsList.length ?
                            this.state.diagnosticsList.map(item => {
                                return(
                                    <div className={`${this.state.diagnostic_id === item.diagnostic_id ? 'bg-info text-white' : 'bg-secondary cursorPointer'} px-2 pt-2p rounded`} onClick={() => this.setState({ diagnostic_id: item.diagnostic_id, diagnosticDetails: {}, showLoading: true }, () => this.getDiagnosticDetails(item.diagnostic_id))}>{item.diagnostic_end_time ? momentConvertionUtcToLocalTime(item.diagnostic_end_time, 'DD MMM HH:mm') : ""}</div>
                                )
                            })
                        : null}
                    </div>
                    <div className="align-self-center">
                        <span className={`fas fa-chevron-right ${this.state.disableRightSlider ? 'disabled' : 'cursorPointer'}`} onClick={() => this.slideRight("")}></span>
                    </div>
                </div>
                <div className="p-3 text-white">
                    <h6>Observed Diagnostics</h6>
                    <p>Summary of analysis and observations of the case environment</p>
                    <div className="accordion mt-3" id="accordionExample">
                        {this.state.observedDiagnostics && this.state.observedDiagnostics.length ? 
                            this.state.observedDiagnostics.map((item, index) => {
                                return(
                                    <div className={`card bg-dark border-0 ${index !== this.state.observedDiagnostics.length-1 ? 'mb-3' : ""}`}>
                                        <div id={'heading_'+index} onClick={() => this.expandCollapse(index, item.label)}>
                                            <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_'+index}aria-expanded="true" aria-controls={'collapse_'+index}>
                                                <div><span className={`far ${this.state.expandMenuId === index ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>{item.label}</div>
                                                <div className="">
                                                    {item.label === 'Application Changes' && this.state.expandMenuId !== index ?
                                                        <span className="text-info">Showing {this.state['filtered_diagnostic_data_'+index] && this.state['filtered_diagnostic_data_'+index].length} {item.label} </span>
                                                    : item.label === 'DB Audit MySQL' && this.state.expandMenuId !== index ?
                                                        <span className="text-info">Showing {this.state['diagnostic_count_'+index]} changes in {this.state['diagnostic_db_count_'+index]} MySQL database </span>
                                                    : item.label === 'DB Audit PostgreSQL' && this.state.expandMenuId !== index ?
                                                        <span className="text-info">Showing {this.state['diagnostic_count_'+index]} changes in {this.state['diagnostic_db_count_'+index]} PostgreSQL database </span>
                                                    : this.state['diagnostic_label_'+index] === 'Capacity Changes' && this.state.expandMenuId !== index ?
                                                        <span className="text-info">Showing {this.state['filtered_diagnostic_data_'+index] && this.state['filtered_diagnostic_data_'+index].length} {item.label} </span>
                                                    : this.state['diagnostic_label_'+index] === 'Health Events' && this.state.expandMenuId !== index ?
                                                        <span className="text-info">Showing {this.state['filtered_diagnostic_data_'+index] && this.state['filtered_diagnostic_data_'+index].length} {item.label} </span>
                                                    :  this.state['diagnostic_label_'+index] === 'Maintenance and Scheduled Events' && this.state.expandMenuId !== index ?
                                                        <span className="text-info">Showing {this.state['filtered_diagnostic_data_'+index] && this.state['filtered_diagnostic_data_'+index].length} {item.label} </span>
                                                    : this.state['diagnostic_label_'+index] === 'Infra Changes' && this.state.expandMenuId !== index ?
                                                        <span className="text-info">Showing {this.state['filtered_diagnostic_data_'+index] && this.state['filtered_diagnostic_data_'+index].length} {item.label} </span>
                                                    : this.state['diagnostic_label_'+index] === 'SRE Observations' && this.state.expandMenuId !== index ?
                                                        <span className="text-info">Showing {this.state['filtered_diagnostic_data_'+index] && this.state['filtered_diagnostic_data_'+index].length} {item.label} </span>
                                                    : this.state['diagnostic_label_'+index] === 'DB SlowQuery Events' && this.state.expandMenuId !== index ?
                                                        <span className="text-info">Showing {this.state['filtered_diagnostic_data_'+index] && this.state['filtered_diagnostic_data_'+index].length} {item.label} </span>
                                                    : this.state['diagnostic_label_'+index] === 'DB SlowQuery MySQL' && this.state.expandMenuId !== index ?
                                                            <span className="text-info">Showing {this.state['filtered_diagnostic_data_'+index] && this.state['filtered_diagnostic_data_'+index].length} MySQL Slow Query Analysis </span>
                                                            : this.state['diagnostic_label_'+index] === 'DB SlowQuery PostgreSQL' && this.state.expandMenuId !== index ?
                                                            <span className="text-info">Showing {this.state['filtered_diagnostic_data_'+index] && this.state['filtered_diagnostic_data_'+index].length} PostgreSQL Slow Query Analysis </span>
                                                        : this.state['diagnostic_label_'+index] === 'Reachability Analysis' && this.state.expandMenuId !== index ?
                                                        <span className="text-info">Showing {this.state['filtered_diagnostic_data_'+index] && this.state['filtered_diagnostic_data_'+index].length} {item.label} </span>
                                                    : this.state['diagnostic_label_'+index] === 'Connectivity Checks' && this.state.expandMenuId !== index ?
                                                        <span className="text-info">Showing {this.state['filtered_diagnostic_data_'+index] && this.state['filtered_diagnostic_data_'+index].length} {item.label} </span>
                                                    : null}
                                                    <span className="far fa-exclamation-triangle text-danger ml-3"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div id={'collapse_'+index} className={`collapse ${this.state.expandMenuId === index ? 'show' : ""}`} aria-labelledby={'heading_'+index} data-parent="#accordionExample">
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between">
                                                    <div className="d-flex">
                                                        {this.state['diagnostic_label_'+index] === 'Application Changes' ?
                                                            <p className="small mb-0 mr-2 mt-1">Showing {this.state['filtered_diagnostic_data_'+index] && this.state['filtered_diagnostic_data_'+index].length} {item.label} </p>
                                                        : this.state['diagnostic_label_'+index] === 'DB Audit MySQL' ?
                                                            <p className="small mb-0 mr-2 mt-1">Showing {this.state['diagnostic_count_'+index]} changes in {this.state['diagnostic_db_count_'+index]} MySQL database </p>
                                                        : this.state['diagnostic_label_'+index] === 'DB Audit PostgreSQL' ?
                                                        <p className="small mb-0 mr-2 mt-1">Showing {this.state['diagnostic_count_'+index]} changes in {this.state['diagnostic_db_count_'+index]} PostgreSQL database </p>
                                                    : this.state['diagnostic_label_'+index] === 'Capacity Changes' && this.state.expandMenuId !== index ?
                                                            <p className="small mb-0 ml-2 align-self-center pt-1 text-info">Showing {this.state['filtered_diagnostic_data_'+index] && this.state['filtered_diagnostic_data_'+index].length} {item.label} </p>
                                                        : this.state['diagnostic_label_'+index] === 'Health Events' && this.state.expandMenuId !== index ?
                                                            <p className="small mb-0 ml-2 align-self-center pt-1 text-info">Showing {this.state['filtered_diagnostic_data_'+index] && this.state['filtered_diagnostic_data_'+index].length} {item.label} </p>
                                                        : this.state['diagnostic_label_'+index] === 'Maintenance and Scheduled Events' && this.state.expandMenuId !== index ?
                                                            <p className="small mb-0 ml-2 align-self-center pt-1 text-info">Showing {this.state['filtered_diagnostic_data_'+index] && this.state['filtered_diagnostic_data_'+index].length} {item.label} </p>
                                                        : this.state['diagnostic_label_'+index] === 'Infra Changes' && this.state.expandMenuId !== index ?
                                                            <p className="small mb-0 ml-2 align-self-center pt-1 text-info">Showing {this.state['filtered_diagnostic_data_'+index] && this.state['filtered_diagnostic_data_'+index].length} {item.label} </p>
                                                        : this.state['diagnostic_label_'+index] === 'SRE Observations' && this.state.expandMenuId !== index ?
                                                            <p className="small mb-0 ml-2 align-self-center pt-1 text-info">Showing {this.state['filtered_diagnostic_data_'+index] && this.state['filtered_diagnostic_data_'+index].length} {item.label} </p>
                                                        : this.state['diagnostic_label_'+index] === 'DB SlowQuery Events' && this.state.expandMenuId !== index ?
                                                            <span className="text-info">Showing {this.state['filtered_diagnostic_data_'+index] && this.state['filtered_diagnostic_data_'+index].length} {item.label} </span>
                                                        : this.state['diagnostic_label_'+index] === 'DB SlowQuery MySQL' && this.state.expandMenuId !== index ?
                                                            <span className="text-info">Showing {this.state['filtered_diagnostic_data_'+index] && this.state['filtered_diagnostic_data_'+index].length} MySQL Slow Query Analysis </span>
                                                            : this.state['diagnostic_label_'+index] === 'DB SlowQuery PostgreSQL' && this.state.expandMenuId !== index ?
                                                            <span className="text-info">Showing {this.state['filtered_diagnostic_data_'+index] && this.state['filtered_diagnostic_data_'+index].length} PostgreSQL Slow Query Analysis </span>
                                                        : this.state['diagnostic_label_'+index] === 'Reachability Analysis' && this.state.expandMenuId !== index ?
                                                            <span className="text-info">Showing {this.state['filtered_diagnostic_data_'+index] && this.state['filtered_diagnostic_data_'+index].length} {item.label} </span>
                                                        : this.state['diagnostic_label_'+index] === 'Connectivity Checks' && this.state.expandMenuId !== index ?
                                                            <span className="text-info">Showing {this.state['filtered_diagnostic_data_'+index] && this.state['filtered_diagnostic_data_'+index].length} {item.label} </span>
                                                        :   
                                                            <p className="small mb-0 mr-2 mt-1">Showing {this.state['filtered_diagnostic_data_'+index] && this.state['filtered_diagnostic_data_'+index].length} {item.label} </p>
                                                        }
                                                    </div>
                                                    <div className="form-group mb-0">
                                                        <SearchWithHiddenInput
                                                            ID={'search_id_'+index}
                                                            data={this.state['diagnostic_data_'+index]}
                                                            applyLiteDarkTags={true}
                                                            className="form-control-sm text-white4 bg-muted border-0"
                                                            hideInputSection={true}
                                                            searchClassName={'border-0'}
                                                            searchIconColor={'text-white4'}
                                                            topClassName={"mb-2"}
                                                            filteredData={(filteredArray, searchText) => {
                                                                this.setState({ 
                                                                    ['filtered_diagnostic_data_'+index]: filteredArray,
                                                                    ['diagnostic_count_'+index]: filteredArray.length   
                                                                },
                                                                    () => {
                                                                        if(this.state['diagnostic_label_'+index] === 'DB Audit MySQL' || this.state['diagnostic_label_'+index] === 'DB Audit PostgreSQL') {
                                                                            let dbLength = 0
                                                                            if(filteredArray.length) {
                                                                                dbLength = _.chain(filteredArray).groupBy("asset_id").map((value, key) => ({ key})).value().length
                                                                            } //to get number of database involved in the changes
                                                                            this.setState({
                                                                                ['diagnostic_db_count_'+index]: dbLength
                                                                            })
                                                                        }
                                                                    }
                                                                )
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="input-group mb-3 w-auto">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="basic-addon1">
                                                        <i className="far fa-search"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" className="form-control" placeholder="Search..." aria-label="Username" aria-describedby="basic-addon1" />
                                                </div> */}
                                                <div className="container-fluid">
                                                    {this.state['filtered_diagnostic_data_'+index] && this.state['filtered_diagnostic_data_'+index].length ?
                                                        this.state['filtered_diagnostic_data_'+index].map((dig, i) => {
                                                            return (
                                                            item.label === 'Application Changes' ?
                                                                <div className={`row mt-4 ${this.state.selectedDiagnostic === 'app_changes_'+index+'_'+i ? 'bg-muted rounded disabled' : 'cursorPointer'}`} onClick={() => this.onChangeDiagnosticEvent(item.label, dig, index, 'app_changes_'+index+'_'+i, dig.asset_id, "")}>
                                                                    <div className="col-2 border-right align-self-center">
                                                                        <p className="text-warning 20 m-0">{momentConvertionUtcToLocalTime(dig.event_time, 'MMM DD')}</p>
                                                                        <p className="text-warning 20 m-0">{momentConvertionUtcToLocalTime(dig.event_time, 'HH:mm:ss')}</p>
                                                                    </div>
                                                                    <div className="col-10 border-left py-3">
                                                                        <p className="f16 m-0">{(dig.provider).toUpperCase() +' : ' +dig.resource_type +' '}</p>
                                                                        <div className="timeline-divider"></div>
                                                                        <p className="f12 m-0">Resource 
                                                                            {dig.account_id ? (' : '+dig.account_id) : ""}
                                                                            {dig.region ? (' : '+dig.region) : ""}
                                                                            {dig.asset_name ? (' : '+dig.asset_name) : ""}
                                                                            {dig.description ? (' : '+dig.description) : ""}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            : item.label === 'DB Audit MySQL' ?
                                                                <div className={`row mt-4 ${this.state.selectedDiagnostic === 'db_'+index+'_'+i ? 'bg-muted rounded disabled' : 'cursorPointer'}`} onClick={() => this.onChangeDiagnosticEvent(item.label, dig, index, 'db_'+index+'_'+i, dig.asset_id, dig.key)}>
                                                                    <div className="col-2 border-right align-self-center">
                                                                        <h6 className="text-warning 20 m-0">{dig.count}</h6>
                                                                        <p className="text-warning 20 m-0">{dig.action}</p>
                                                                    </div>
                                                                    <div className="col-10 border-left py-3">
                                                                        <p className="f16 m-0">{(dig.key === 'index_changes' ? 'Index' : dig.key === 'table_changes' ? 'Table' : dig.key === 'view_changes' ? 'View' : dig.key === 'other_changes' ? 'Other' : "")}</p>
                                                                        <div className="timeline-divider"></div>
                                                                        <p className="f12 m-0">{dig.description ? dig.description : ""}</p>
                                                                    </div>
                                                                </div>
                                                            : item.label === 'DB Audit PostgreSQL' ?
                                                                <div className={`row mt-4 ${this.state.selectedDiagnostic === 'db_'+index+'_'+i ? 'bg-muted rounded disabled' : 'cursorPointer'}`} onClick={() => this.onChangeDiagnosticEvent(item.label, dig, index, 'db_'+index+'_'+i, dig.asset_id, dig.key)}>
                                                                    <div className="col-2 border-right align-self-center">
                                                                        <h6 className="text-warning 20 m-0">{dig.count}</h6>
                                                                        <p className="text-warning 20 m-0">{dig.action}</p>
                                                                    </div>
                                                                    <div className="col-10 border-left py-3">
                                                                        <p className="f16 m-0">{(dig.key === 'index_changes' ? 'Index' : dig.key === 'table_changes' ? 'Table' : dig.key === 'view_changes' ? 'View' : dig.key === 'other_changes' ? 'Other' : "")}</p>
                                                                        <div className="timeline-divider"></div>
                                                                        <p className="f12 m-0">{dig.description ? dig.description : ""}</p>
                                                                    </div>
                                                                </div>
                                                            : item.label === 'Health Events' ?
                                                                <div className={`row mt-4 ${this.state.selectedDiagnostic === 'health_event_'+index+'_'+i ? 'bg-muted rounded disabled' : 'cursorPointer'}`} onClick={() => this.onChangeDiagnosticEvent(item.label, dig, index, 'health_event_'+index+'_'+i, "", "")}>
                                                                    <div className="col-2 border-right align-self-center">
                                                                        <p className="text-warning 20 m-0">{momentConvertionUtcToLocalTime(dig.health_event_time, 'MMM DD')}</p>
                                                                        <p className="text-warning 20 m-0">{momentConvertionUtcToLocalTime(dig.health_event_time, 'HH:mm:ss')}</p>
                                                                    </div>
                                                                    <div className="col-10 border-left py-3">
                                                                        <p className="f16 m-0">{dig.health_event_service +' : ' +dig.health_event_category +' '}</p>
                                                                        <div className="timeline-divider"></div>
                                                                        <p className="f12 m-0">{dig.health_event_description ? dig.health_event_description : ""}</p>
                                                                    </div>
                                                                </div>
                                                            : item.label === 'Maintenance and Scheduled Events' ?
                                                                <div className={`row mt-4 ${this.state.selectedDiagnostic === 'maintsch_event_'+index+'_'+i ? 'bg-muted rounded disabled' : 'cursorPointer'}`} onClick={() => this.onChangeDiagnosticEvent(item.label, dig, index, 'maintsch_event_'+index+'_'+i, "", "")}>
                                                                    <div className="col-2 border-right align-self-center">
                                                                        <p className="text-warning 20 m-0">{momentConvertionUtcToLocalTime(dig.maintsch_event_time, 'MMM DD')}</p>
                                                                        <p className="text-warning 20 m-0">{momentConvertionUtcToLocalTime(dig.maintsch_event_time, 'HH:mm:ss')}</p>
                                                                    </div>
                                                                    <div className="col-10 border-left py-3">
                                                                        <p className="f16 m-0">{dig.maintsch_event_service +' : ' +dig.maintsch_event_category +' '}</p>
                                                                        <div className="timeline-divider"></div>
                                                                        <p className="f12 m-0">{dig.maintsch_event_description ? dig.maintsch_event_description : ""}</p>
                                                                    </div>
                                                                </div>
                                                            : item.label === 'Capacity Changes' ?
                                                                <div className={`row mt-4 ${this.state.selectedDiagnostic === 'capacity_changes_'+index+'_'+i ? 'bg-muted rounded disabled' : 'cursorPointer'}`} onClick={() => this.onChangeDiagnosticEvent(item.label, dig, index, 'capacity_changes_'+index+'_'+i, dig.asset_id, "")}>
                                                                    <div className="col-2 border-right align-self-center">
                                                                        <p className="text-warning 20 m-0">{momentConvertionUtcToLocalTime(dig.event_time, 'MMM DD')}</p>
                                                                        <p className="text-warning 20 m-0">{momentConvertionUtcToLocalTime(dig.event_time, 'HH:mm:ss')}</p>
                                                                    </div>
                                                                    <div className="col-10 border-left py-3">
                                                                        <p className="f16 m-0">{dig.event_source +' : ' +dig.event_name +' '}</p>
                                                                        <div className="timeline-divider"></div>
                                                                        {dig.changes && dig.changes.map(des => {
                                                                            return(
                                                                                <p className="f12 m-0">{des.description ? des.description : ""}</p>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            : item.label === 'Infra Changes' ?
                                                                <div className={`row mt-4 ${this.state.selectedDiagnostic === 'infra_changes_'+index+'_'+i ? 'bg-muted rounded disabled' : 'cursorPointer'}`} onClick={() => this.onChangeDiagnosticEvent(item.label, dig, index, 'infra_changes_'+index+'_'+i, dig.asset_id, "")}>
                                                                    <div className="col-2 border-right align-self-center">
                                                                        <p className="text-warning 20 m-0">{momentConvertionUtcToLocalTime(dig.event_time, 'MMM DD')}</p>
                                                                        <p className="text-warning 20 m-0">{momentConvertionUtcToLocalTime(dig.event_time, 'HH:mm:ss')}</p>
                                                                    </div>
                                                                    <div className="col-10 border-left py-3">
                                                                        <p className="f16 m-0">{dig.event_source +' : ' +dig.event_name +' '}</p>
                                                                        <div className="timeline-divider"></div>
                                                                        {dig.changes && dig.changes.map(des => {
                                                                            return(
                                                                                <p className="f12 m-0">{des.description ? des.description : ""}</p>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            : item.label === 'SRE Observations' ?
                                                                <div className={`row mt-4 ${this.state.selectedDiagnostic === 'sre_events_'+index+'_'+i ? 'bg-muted rounded disabled' : 'cursorPointer'}`} onClick={() => this.onChangeDiagnosticEvent(item.label, dig, index, 'sre_events_'+index+'_'+i, dig.sre_asset_id, "")}>
                                                                    <div className="col-2 border-right align-self-center">
                                                                        <p className="text-warning 20 m-0">
                                                                            {dig.sre_events_count && dig.sre_events_count}
                                                                        </p>
                                                                        <p className="text-warning 20 m-0">{dig.sre_events_count ? (dig.sre_events_count > 1 ? 'events' : 'event') : 'event'}</p>
                                                                    </div>
                                                                    <div className="col-10 border-left py-3">
                                                                        <p className="f16 m-0">{dig.sre_resource_type ? dig.sre_resource_type : ""}</p>
                                                                        <div className="timeline-divider"></div>
                                                                        <p className="f12 m-0">{dig.sre_event_description ? dig.sre_event_description : ""}</p>
                                                                    </div>
                                                                </div>
                                                            : item.label === 'DB SlowQuery Events' ?
                                                                <div className={`row mt-4 ${this.state.selectedDiagnostic === 'db_slow_query_event_'+index+'_'+i ? 'bg-muted rounded disabled' : 'cursorPointer'}`} onClick={() => this.onChangeDiagnosticEvent(item.label, dig, index, 'db_slow_query_event_'+index+'_'+i, "", "")}>
                                                                    <div className="col-2 border-right align-self-center">
                                                                        <p className="text-warning 20 m-0">{dig.dbslowq_count ? dig.dbslowq_count : ""}</p>
                                                                        <p className="text-warning 20 m-0">{dig.dbslowq_count && dig.dbslowq_count > 1 ? 'Slow Queries' : 'Slow Query'}</p>
                                                                    </div>
                                                                    <div className="col-10 border-left py-3">
                                                                        <p className="f16 m-0">{dig.dbslowq_resource_type +' ' +dig.dbslowq_engine +' : '+ dig.dbslowq_asset_id}</p>
                                                                        <div className="timeline-divider"></div>
                                                                        <p className="f12 m-0">{dig.dbslowq_summary ? dig.dbslowq_summary : ""}</p>
                                                                    </div>
                                                                </div>
                                                            : item.label === 'DB SlowQuery MySQL' ?
                                                                <div className={`row mt-4 ${this.state.selectedDiagnostic === 'db_slow_query_analysis_'+index+'_'+i ? 'bg-muted rounded disabled' : 'cursorPointer'}`} onClick={() => this.onChangeDiagnosticEvent(item.label, dig, index, 'db_slow_query_analysis_'+index+'_'+i, "", "")}>
                                                                    <div className="col-2 border-right align-self-center text-center">
                                                                        <p className="text-warning 20 m-0">{dig.total}</p>
                                                                        <p className="text-warning 20 m-0">Slow</p>
                                                                        <p className="text-warning 20 m-0">Queries</p>
                                                                    </div>
                                                                    <div className="col-10 border-left py-3">
                                                                        <p className="small  m-0">Unique Slow Queries {dig.unique}</p>
                                                                        <div className="d-flex">
                                                                            <small className="mr-2">Min: {dig.count && dig.count.min ? dig.count.min : 0}</small>
                                                                            <small className="mr-2">Avg: {dig.count && dig.count.avg ? dig.count.avg : 0}</small>
                                                                            <small className="mr-2">Max: {dig.count && dig.count.max ? dig.count.max : 0}</small>
                                                                            <small className="mr-2">STD: {dig.count && dig.count.stddev ? dig.count.stddev : 0}</small>
                                                                            <small className="mr-2">Med: {dig.count && dig.count.median ? dig.count.median : 0}</small>
                                                                            <small className="mr-2">95th Per: {dig.count && dig.count["95%"] ? dig.count["95%"] : 0}</small>
                                                                        </div>
                                                                        <p className="small m-0">Query with highest time: {dig.query_time && dig.query_time.max ? dig.query_time.max : 0} seconds</p>
                                                                        <p className="small m-0">Query with lowest time: {dig.query_time && dig.query_time.min ? dig.query_time.min : 0} seconds</p>
                                                                        <p className="small m-0">Total time taken by slow queries: {dig.query_time && dig.query_time.sum ? dig.query_time.sum : 0} seconds</p>
                                                                    </div>
                                                                </div>
                                                            : item.label === 'DB SlowQuery PostgreSQL' ?
                                                                <div className={`row mt-4 ${this.state.selectedDiagnostic === 'db_slow_query_mysql_'+index+'_'+i ? 'bg-muted rounded disabled' : 'cursorPointer'}`} onClick={() => this.onChangeDiagnosticEvent(item.label, dig, index, 'db_slow_query_mysql_'+index+'_'+i, "", "")}>
                                                                    <div className="col-2 border-right align-self-center text-center">
                                                                        <p className="text-warning 20 m-0">{dig.total}</p>
                                                                        <p className="text-warning 20 m-0">Slow</p>
                                                                        <p className="text-warning 20 m-0">Queries</p>
                                                                    </div>
                                                                    <div className="col-10 border-left py-3">
                                                                        <p className="small  m-0">Unique Slow Queries {dig.unique}</p>
                                                                        <div className="d-flex">
                                                                            <small className="mr-2">Min: {dig.count && dig.count.min ? dig.count.min : 0}</small>
                                                                            <small className="mr-2">Avg: {dig.count && dig.count.avg ? dig.count.avg : 0}</small>
                                                                            <small className="mr-2">Max: {dig.count && dig.count.max ? dig.count.max : 0}</small>
                                                                            <small className="mr-2">STD: {dig.count && dig.count.stddev ? dig.count.stddev : 0}</small>
                                                                            <small className="mr-2">Med: {dig.count && dig.count.median ? dig.count.median : 0}</small>
                                                                            <small className="mr-2">95th Per: {dig.count && dig.count["95%"] ? dig.count["95%"] : 0}</small>
                                                                        </div>
                                                                        <p className="small m-0">Query with highest time: {dig.query_time && dig.query_time.max ? dig.query_time.max : 0} seconds</p>
                                                                        <p className="small m-0">Query with lowest time: {dig.query_time && dig.query_time.min ? dig.query_time.min : 0} seconds</p>
                                                                        <p className="small m-0">Total time taken by slow queries: {dig.query_time && dig.query_time.sum ? dig.query_time.sum : 0} seconds</p>
                                                                    </div>
                                                                </div>
                                                            : item.label === 'Reachability Analysis' ?
                                                                <div className={`row mt-4 ${this.state.selectedDiagnostic === 'reachability_analysis_'+index+'_'+i ? 'bg-muted rounded disabled' : 'cursorPointer'}`} onClick={() => this.onChangeDiagnosticEvent(item.label, dig, index, 'reachability_analysis_'+index+'_'+i, "", "")}>
                                                                    <div className="col-2 border-right align-self-center text-center">
                                                                        <h6 className="text-warning 20 m-0">{dig.total_impacted_assets && dig.total_impacted_assets.length}</h6>
                                                                        <p className="text-warning 20 m-0">Assets Impacted</p>
                                                                        {/* <p className="text-warning 20 m-0">Impacted</p> */}
                                                                    </div>
                                                                    <div className="col-10 border-left py-3">
                                                                        <p className="f16 m-0">{(dig.provider).toUpperCase() +' : ' +dig.resource_type +' '}</p>
                                                                        <div className="timeline-divider"></div>
                                                                            {dig.description ? 
                                                                                dig.description.split('\n').map(item => {
                                                                                    return(
                                                                                        <p className="f12 mb-1">{item}</p>
                                                                                    )
                                                                                })
                                                                            : 
                                                                                <p className="f12 m-0">Resource 
                                                                                    {dig.account_id ? (' : '+dig.account_id) : ""}
                                                                                    {dig.region ? (' : '+dig.region) : ""}
                                                                                    {dig.asset_name ? (' : '+dig.asset_name) : ""}
                                                                                    {/* {dig.description ? (' : '+dig.description) : ""} */}
                                                                                </p>
                                                                            }
                                                                    </div>
                                                                </div>
                                                            : item.label === 'Connectivity Checks' ?
                                                                <div className={`row mt-4 ${this.state.selectedDiagnostic === 'connectivity_'+index+'_'+i ? 'bg-muted rounded disabled' : 'cursorPointer'}`} onClick={() => this.onChangeDiagnosticEvent(item.label, dig, index, 'connectivity_'+index+'_'+i, "", "")}>
                                                                    <div className="col-2 border-right align-self-center">
                                                                        <p className="text-warning 20 m-0">{dig.conntchks_count ? dig.conntchks_count : ""}</p>
                                                                        <p className="text-warning 20 m-0">{dig.conntchks_count && dig.conntchks_count > 1 ? 'Checks' : 'Checks'}</p>
                                                                    </div>
                                                                    <div className="col-10 border-left py-3">
                                                                        <p className="f16 m-0">
                                                                            {
                                                                            (dig.conntchks_resource_type ? dig.conntchks_resource_type : "") 
                                                                            +' '+
                                                                            (dig.conntchks_engine ? dig.conntchks_engine : "") 
                                                                            +' '+
                                                                            (dig.conntchks_asset_id ? dig.conntchks_asset_id : "")}
                                                                        </p>
                                                                        <div className="timeline-divider"></div>
                                                                        <p className="f12 m-0">{dig.conntchks_summary ? dig.conntchks_summary : ""}</p>
                                                                    </div>
                                                                </div>
                                                            : null
                                                            )
                                                        })
                                                    : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        : null}
                        {this.state.notObservedDiagnostics && this.state.notObservedDiagnostics.length ? 
                            <div class="borderBottomMiddleBlue m-4"></div>
                        : null}
                        {this.state.notObservedDiagnostics && this.state.notObservedDiagnostics.length ? 
                            this.state.notObservedDiagnostics.map((item, index) => {
                                return(
                                    <div className={`card bg-dark border-0 mb-3`}>
                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_'+index}aria-expanded="true" aria-controls={'collapse_'+index}>
                                                <div><span className={`text-muted far ${this.state.expandMenuId === index ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>{item.label}</div>
                                                <div className="">
                                                    {item.label === 'Application Changes' || item.label === 'DB Audit MySQL' || item.label === 'DB Audit PostgreSQL' || item.label === 'Capacity Changes' || item.label === 'Infra Changes' || item.label === 'Health Events' || item.label === 'Maintenance and Scheduled Events' || item.label === 'DB SlowQuery Events' || item.label === 'Database deadlocks' ?
                                                        <span className="text-info">No {item.label} Observed </span>
                                                    : item.label === 'SRE Observations' ?
                                                        <span className="text-info">No {item.label} Observed </span>
                                                    : item.label === 'Connectivity Checks' ?
                                                        <span className="text-info">No Connectivity Issues Observed </span>
                                                    : item.label === 'Connectivity' ?
                                                        <span className="text-info">No {item.label} Observed </span>
                                                    : item.label === 'DB SlowQuery MySQL' ?
                                                        <span className="text-info">No {item.label} events Observed </span>
                                                    : item.label === 'DB SlowQuery PostgreSQL' ?
                                                        <span className="text-info">No {item.label} events Observed </span>
                                                    : item.label === 'Reachability Analysis' ?
                                                        <span className="text-info">No Reachability Issues Observed </span>
                                                    : null}
                                                    <span className="far fa-check-circle f16 text-success ml-3"></span>
                                                </div>
                                            </div>
                                    </div>
                                )
                            })
                        : null}
                    </div>
                </div>
            </div>
		)
	}
}
/**
 * Type of the props used in the component
 */
DiagnosticsLeftSection.propTypes = {
    listDiagnostics: PropTypes.func,
    getDiagnosticDetails: PropTypes.func,
    startDiagnostics: PropTypes.func,
    setAiopsPropsDetails: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
	// console.log('state', state)
	return {
        caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : {},
        aiopsPropsDetails: state.aiops.aiopsPropsDetails ? state.aiops.aiopsPropsDetails : {},        
        aiopsDiagnosticsList: state.aiops.aiopsDiagnosticsList && state.aiops.aiopsDiagnosticsList.results ? state.aiops.aiopsDiagnosticsList.results : [],
        aiopsDiagnosticsDetails: state.aiops.aiopsDiagnosticsDetails && state.aiops.aiopsDiagnosticsDetails.result ? state.aiops.aiopsDiagnosticsDetails.result : [],
    }
}

export default connect(mapStateToProps, {
    listDiagnostics,
    getDiagnosticDetails,
    startDiagnostics,
    setAiopsPropsDetails,
})(withRouter(DiagnosticsLeftSection))