/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file LeftSideTab.js
 * @author Prakash // on 06/06/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { Spinner, UncontrolledTooltip } from 'reactstrap'
import { listAllServices, getAllTagsKeys, getAllTagsValues } from '../../../actions/commonAction'
import { getObservabilityAlertsTotalCount, getAnomalyTotalCount, getChangeEvents } from '../../../actions/aiops/ObservabilityAction'
import { getAggregatedIdentifierResults, getCoveredServices } from '../../../actions/detectors/detectorsAction'
import { getTotalCount } from '../../../actions/aiops/ErrorAction'
import { getAggregatedEvents } from '../../../actions/aiops/AiopsAction'
import { setResourcePropsDetails } from '../../../actions/assets/assetsAction'

import _ from 'lodash'
import { momentConvertionLocalToUtcTime, capitalizeTheFirstLetterOfEachWord, capitalizeAllLetter } from '../../../utils/utility'

import SearchWithHiddenInput from '../../common/SearchWithHiddenInput'

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

const Categories = ["All", "compute", "Networking", "CDN", "Database", "Storage"]

class LeftSideTab extends Component {
    constructor(props) {
        super(props)
        this.toggleRef = React.createRef()
        
        this.state = {
            showLoading: true,

            startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
            overAllTotalPages: 1,
			perPage: 100,

            selectedShowBy: "Services",

            categoryList: [],
            assetCategory: [],
            selectedService: [],
            tagsArray: [0],
        }
    }

	componentDidMount = () => {
        // if(this.props.dashboardDetails && Object.entries(this.props.dashboardDetails).length) {
        //     this.setState({ 
        //         start_time: this.props.dashboardDetails.start_time,
        //         end_time: this.props.dashboardDetails.end_time,
        //         brushStartTime: this.props.dashboardDetails.brushStartTime ? this.props.dashboardDetails.brushStartTime : this.props.dashboardDetails.start_time,
        //         brushEndTime: this.props.dashboardDetails.brushEndTime ? this.props.dashboardDetails.brushEndTime : this.props.dashboardDetails.end_time,
        //         selectedProvider: this.props.dashboardDetails.selectedProvider,
        //         selectedAccount: this.props.dashboardDetails.selectedAccount,
        //         selectedRegion: this.props.dashboardDetails.selectedRegion,
        //         selectedDuration: this.props.dashboardDetails.selectedDuration,
        //         selectedShowBy: this.props.dashboardDetails.selectedShowBy,
        //         showLoading: true,
        //     },
        //         () => {
        //             this.getObservabilityAlertsTotalCount()
        //         }
        //     )
        // }

        if(this.state.selectedShowBy === "Assets") {
            this.props.selectedShowBy(this.state.selectedShowBy)
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.dashboardDetails && Object.entries(this.props.dashboardDetails).length) {
            if(!_.isEqual(prevProps.dashboardDetails, this.props.dashboardDetails)) {
                this.setState({ 
                    start_time: this.props.dashboardDetails.start_time,
                    end_time: this.props.dashboardDetails.end_time,
                    brushStartTime: this.props.dashboardDetails.brushStartTime ? this.props.dashboardDetails.brushStartTime : this.props.dashboardDetails.start_time,
                    brushEndTime: this.props.dashboardDetails.brushEndTime ? this.props.dashboardDetails.brushEndTime : this.props.dashboardDetails.end_time,
                    selectedProvider: this.props.dashboardDetails.selectedProvider,
                    selectedAccount: this.props.dashboardDetails.selectedAccount,
                    selectedRegion: this.props.dashboardDetails.selectedRegion,
                    selectedDuration: this.props.dashboardDetails.selectedDuration,
                    showLoading: true,
                    selectedGroup: "",
                    groupOptions: [],
                    listData: [],
                    filteredList: []
                },
                    () => {
                        if(this.props.pageType === "Alerts") {
                            let groupOptions = [
                                {value: "severity", label:"Severity"},
                                // {value: "category", label:"Category"}
                            ]
                            this.setState({ groupOptions, selectedGroup: "severity" },
                                () => {
                                    this.getObservabilityAlertsTotalCount()
                                    this.getCoveredServices()
                                }
                            )
                            
                        } else if(this.props.pageType === "Anomalies") {
                            let groupOptions = [
                                {value: "anomaly_type", label:"Anomaly Type"},
                                {value: "severity", label:"Severity"},
                            ]
                            this.setState({ groupOptions, selectedGroup: "anomaly_type" },
                                () => {
                                    this.getAnomalyTotalCount()
                                    this.getCoveredServices()
                                }
                            )
                        } else if(this.props.pageType === "Checks" || this.props.pageType === "Compliance") {
                            let groupOptions = [
                                {value: "risk", label:"Severity"},
                                // {value: "category", label:"Category"}
                            ]
                            
                            this.setState({ groupOptions, selectedGroup: "risk" },
                                () => {
                                    this.getAggregatedIdentifierResults()                            
                                    this.getCoveredServices()
                                }
                            )
                        } else if(this.props.pageType === "Errors") {
                            let groupOptions = [
                                {value: "category", label:"Category"},
                                {value: "severity", label:"Severity"},
                            ]
                            this.setState({ groupOptions, selectedGroup: "severity" },
                                () => {
                                    this.getTotalCount()
                                    this.getCoveredServices()
                                }
                            )
                        } else if(this.props.pageType === "IssueEvents" || this.props.pageType === "Notifications") {
                            // let groupOptions = [
                            //     {value: "impact_type", label:"Impact Type"},
                            // ]
                            this.getAggregatedEvents()
                            // this.setState({ groupOptions }, //selectedGroup: "impact_type"
                            // )
                        } else if(this.props.pageType === "LifeCycle") {
                            let groupOptions = [
                                {value: "impact_type", label:"Impact Type"},
                            ]
                            this.setState({ groupOptions, selectedGroup: "impact_type" },
                                () => {
                                    if(this.props.selectedShowBy === "Assets") {
                                        this.getLifeCycleServices()
                                    } else {
                                        this.getAggregatedEvents()
                                    }
                                    this.getCoveredServices()
                                }
                            )
                        } else if(this.props.pageType === "ChangeEvents") {
                            let groupOptions = []
                            this.setState({ groupOptions, selectedGroup: "impact_type" },
                                () => {
                                    this.getChangeEvents()
                                    this.getCoveredServices()
                                }
                            )
                        }
                        this.listAllServices()
                    }
                )
            }
        }
    }

    getCoveredServices = () => {
        let params = {}
        params.provider = this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider.toLowerCase() : ""
        if(this.state.selectedAccount && this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
            params.region = this.state.selectedRegion
        }
        params.aggregate_by = ["category"]
        
        if(params.provider !== "") {
            this.props.getCoveredServices(params, (promise, response) => {
                if(promise) {
                    let results = response.results && response.results.length ? response.results : []
                    this.setState({ 
                        categoryList: results
                    })
                } else {
                    this.setState({ categoryList: [] })
                }
            })
        } else {
            this.setState({ categoryList: [] })
        }
    }

    listAllServices = () => {
        if(!this.props.services || !this.props.services.length) {
            let params = {}
            if (this.state.selectedProvider !== "" && this.state.selectedProvider !== 'all') {
                params.provider = this.state.selectedProvider.toLowerCase()
            }
            this.props.listAllServices(params, (promise, services) => {})
        }
    }

    getObservabilityAlertsTotalCount = () => {        
        let params = {}
        params.provider = this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider.toLowerCase() : ""
        if(this.state.selectedAccount && this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
            params.region = this.state.selectedRegion
        }
        params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartTime, "YYYY-MM-DD HH:mm:00")
        params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndTime, "YYYY-MM-DD HH:mm:00")
        // params.duration = "2w"
        params.radial = true
        params.distribute_labels = true
        
        if(this.state.selectedShowBy === "Services") {
            params.aggregate_by = ["resource_type", "severity"]
        } else {
            params.include_name = true
            params.aggregate_by = ["resource_type", "service_name", "asset_id", "asset_name", "severity"]
        }
        

        this.props.getObservabilityAlertsTotalCount(params, (promise, response) => {
            if(promise) {
                this.setState({ listResponse: response.results && response.results.length ? response.results : [] },
                    () => this.structureListResponse()
                )
            } else {
                this.setState({ showLoading: false })
            }
        })
    }

    getAnomalyTotalCount = () => {        
        let params = {}
        params.provider = this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider.toLowerCase() : ""
        if(this.state.selectedAccount && this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
            params.region = this.state.selectedRegion
        }
        params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartTime, "YYYY-MM-DD HH:mm:00")
        params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndTime, "YYYY-MM-DD HH:mm:00")
        params.radial = true
        params.distribute_labels = true
        
        if(this.state.selectedShowBy === "Services") {
            params.aggregate_by = ["resource_type", this.state.selectedGroup]
        } else {
            params.include_name = true
            params.aggregate_by = ["resource_type", "service_name", "asset_id", "asset_name", this.state.selectedGroup]
        }
        if(this.state.selectedGroup === "severity") {
            params.include_severity_others = true
            // params.severity = ["Critical", "High"]
        }

        this.props.getAnomalyTotalCount(params, (promise, response) => {
            if(promise) {
                this.setState({ listResponse: response.results && response.results.length ? response.results : [] },
                    () => this.structureListResponse()
                )
            } else {
                this.setState({ showLoading: false })
            }
        })
    }

    getAggregatedIdentifierResults = () => {        
        let params = {}
        params.provider = this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider.toLowerCase() : ""
        if(this.state.selectedAccount && this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
            params.region = this.state.selectedRegion
        }
        params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartTime, "YYYY-MM-DD HH:mm:00")
        params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndTime, "YYYY-MM-DD HH:mm:00")
        params.radial = true
        params.latest = true
        params.distribute_labels = true  
        
        let aggregateBy = []
        if(this.state.selectedShowBy === "Services") {
            aggregateBy.push("resource_type")
            if(this.props.pageType === "Checks") {
                aggregateBy.push(this.state.selectedGroup)
            } else {
                aggregateBy.push("compliance.complianceId")
            }
        } else {
            params.include_name = true
            aggregateBy = ["resource_type", "service_name", "asset_id", "resource_id"]
            if(this.props.pageType === "Checks") {
                aggregateBy.push(this.state.selectedGroup)
            } else {
                aggregateBy.push("compliance.complianceId")
            }
        }

        params.aggregate_by = aggregateBy

        this.props.getAggregatedIdentifierResults(params, (promise, response) => {
            if(promise) {
                this.setState({ listResponse: response.length ? response : [] },
                    () => this.structureListResponse()
                )
            } else {
                this.setState({ showLoading: false })
            }
        })
    }

    getTotalCount = () => {        
        let params = {}
        params.provider = this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider.toLowerCase() : ""
        if(this.state.selectedAccount && this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
            params.region = this.state.selectedRegion
        }
        params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartTime, "YYYY-MM-DD HH:mm:00")
        params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndTime, "YYYY-MM-DD HH:mm:00")
        // params.duration = "90d"
        params.radial = true
        params.distribute_labels = true        
        if(this.state.selectedShowBy === "Services") {
            params.aggregate_by = ["resource_type", this.state.selectedGroup]
            params.include_category_others = true
        } else {
            params.include_name = true
            params.aggregate_by = ["resource_type", "service_name", "asset_id", "asset_name", this.state.selectedGroup]
        }
        
        // if(this.state.selectedGroup === "severity") {
        //     params.severity = ["Critical", "High"]
        // }

        this.props.getTotalCount(params, (promise, response) => {
            if(promise) {
                this.setState({ listResponse: response.results && response.results.length ? response.results : [] },
                    () => this.structureListResponse()
                )
            } else {
                this.setState({ showLoading: false })
            }
        })
    }

    getLifeCycleServices = () => {
        let params = {}
        params.provider = this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider.toLowerCase() : ""
        if(this.state.selectedAccount && this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
            params.region = this.state.selectedRegion
        }
        params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartTime, "YYYY-MM-DD HH:mm:00")
        params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndTime, "YYYY-MM-DD HH:mm:00")
        
        params.event_source = "cloudtrail"
        params.aggregate_by = ["service_name"]
        params.count_by = "asset_id"
        
        this.props.getAggregatedEvents(params, (promise, response) => {
            if(promise) {
                let result = response && response.length ? response : []
                let selectedLifecycleService = response && response.length && response[0].service_name ? response[0].service_name : "" 
                this.setState({ serviceList: result, selectedLifecycleService },
                    () => {
                        if(selectedLifecycleService !== "") {
                            this.getAggregatedEvents()
                        }
                    }
                )
            } else {
                this.setState({ showLoading: false })
            }
        })
    }

    getAggregatedEvents = () => {        
        let params = {}
        params.provider = this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider.toLowerCase() : ""
        if(this.state.selectedAccount && this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
            params.region = this.state.selectedRegion
        }
        params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartTime, "YYYY-MM-DD HH:mm:00")
        params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndTime, "YYYY-MM-DD HH:mm:00")
                
        params.distribute_labels = true
        
        if(this.props.pageType === "LifeCycle") {
            params.radial = true
            params.event_source = "cloudtrail"
            if(this.state.selectedShowBy === "Services") {
                params.aggregate_by = ["service_name", "asset_action"]
            } else {
                params.service_name = this.state.selectedLifecycleService
                params.include_name = true
                params.aggregate_by = ["resource_type", "service_name", "asset_id", "asset_name", "asset_action"]
            }
        } else {
            params.event_source = "aws.health"
            if(this.props.pageType === "Notifications") {
                params.category = "accountNotification"
            } else if(this.props.pageType === "IssueEvents") {
                params.category = "issue"
            }

            if(this.state.selectedShowBy === "Services") {
                params.aggregate_by = ["additional_details.service.keyword"] //, "impact_type"
            } else {
                params.include_name = true
                params.aggregate_by = ["resource_type", "service_name", "asset_id", "asset_name"] //, "impact_type"
            }
        }
        
        this.props.getAggregatedEvents(params, (promise, response) => {
            if(promise) {
                this.setState({ listResponse: response && response.length ? response : [] },
                    () => {
                        if(this.props.pageType === "LifeCycle") {
                            this.structureListResponse()
                        } else {
                            this.structureIssNotListResponse()
                        }
                    }
                )
            } else {
                this.setState({ showLoading: false })
            }
        })
    }

    getChangeEvents = () => {   
        let params = {}
        params.provider = this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider.toLowerCase() : ""
        if(this.state.selectedAccount && this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
            params.region = this.state.selectedRegion
        }
        params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartTime, "YYYY-MM-DD HH:mm:00")
        params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndTime, "YYYY-MM-DD HH:mm:00")
        // params.duraiton = "90d"
        // params.radial = true
        // params.distribute_labels = true

        if(this.state.selectedShowBy === "Services") {
            params.aggregate_by = "resource_type"
        } else {
            // params.include_name = true
            params.aggregate_by = "asset"
        }

        params.include_name = true

        
        this.props.getChangeEvents(params, (promise, response) => {
            if(promise) {
                this.setState({ listResponse: response && response.length ? response : [] },
                    () => this.structureListResponse()
                )
            } else {
                this.setState({ showLoading: false })
            }
        })
    }

    structureListResponse = () => {
        let listData = []
        if(this.state.listResponse && this.state.listResponse.length) {
            this.state.listResponse.forEach(item => {
                let label = ""
                if(this.props.pageType === "Alerts" || this.props.pageType === "Anomalies" || this.props.pageType === "Errors") { 
                    if(this.state.selectedShowBy === "Services") { label = item.resource_type } 
                    else { label = item.asset_name }                    
                } else if(this.props.pageType === "LifeCycle") {
                    if(this.state.selectedShowBy === "Services") { label = item.service_name } 
                    else { label = item.asset_name }
                } else if(this.props.pageType === "Checks" || this.props.pageType === "Compliance") {
                    if(this.state.selectedShowBy === "Services") { label = item.resource_type } 
                    else { label = item.resource_id}
                } else if(this.props.pageType === "IssueEvents" || this.props.pageType === "Notifications") {
                    if(this.state.selectedShowBy === "Services") { label = item.service } 
                    else { label = item.asset_name }
                } else if(this.props.pageType === "ServiceQuota") {
                    if(this.state.selectedShowBy === "Services") { label = item.label } 
                    else { label = item.label }
                } else if(this.props.pageType === "ChangeEvents") {
                    if(this.state.selectedShowBy === "Services") { label = item.resource_type } 
                    else { label = item.asset_arn }
                }
                    

                item.label = label
                if(this.state.selectedGroup !== "severity" && this.state.selectedGroup !== "risk") {
                    item.data  = Object.keys(item.data).sort().reduce((obj, key) => (obj[key] = item.data[key], obj), {})
                }
                listData.push(item)
            })
        }
        
        this.setState({ listData, filteredList: listData, showLoading: false },
            () => {
                if(listData.length) {
                    this.selectedColumn("all", "", 0, listData[0])
                } else {
                    this.selectedColumn("all", "", 0, {})
                }
            }    
        )
    }

    structureIssNotListResponse = () => {
        let listData = []
        if(this.state.listResponse && this.state.listResponse.length) {
            this.state.listResponse.forEach(item => {
                let label = ""
                if(this.props.pageType === "IssueEvents" || this.props.pageType === "Notifications") {
                    if(this.state.selectedShowBy === "Services") { label = item.service } 
                    else { label = item.asset_name }
                }                    

                item.label = label
                item.count = item.count
                listData.push(item)
            })
        }
        
        this.setState({ listData, filteredList: listData, showLoading: false },
            () => {
                if(listData.length) {
                    this.selectedColumn("all", "", 0, listData[0])
                } else {
                    this.selectedColumn("all", "", 0, {})
                }
            }    
        )
    }

    selectedColumn = (column, field, index, item) => {
        let columnLabel = ""
        let columnValue = ""
        let allFields = item.data && Object.entries(item.data).length ? Object.keys(item.data) : []
        // if(this.state.selectedGroup !== "severity" && this.state.selectedGroup !== "risk") {
        //     allFields = ["Critical", "High"]
        // }
        let selectedRecord = item.label ? item.label : ""
        if(field !== "") {
            selectedRecord = field+"_"+index
        }

        if(Object.entries(item).length) {
            if(this.state.selectedShowBy === "Services") {
                columnLabel = item.resource_type
            } else {
                if(this.props.pageType === "Checks" || this.props.pageType === "Compliance") {
                    columnLabel = item.resource_id
                } else {
                    columnLabel = item.asset_name
                }
            }

            if(this.props.pageType === "Alerts" || this.props.pageType === "Anomalies" || this.props.pageType === "Checks" || this.props.pageType === "Compliance" || this.props.pageType === "Errors") {
                if(this.state.selectedShowBy === "Services") { columnValue = item.resource_type } 
                else { columnValue = item.asset_id }
            } else if(this.props.pageType === "LifeCycle") {
                if(this.state.selectedShowBy === "Services") { columnValue = item.service_name } 
                else { columnValue = item.asset_id }
            } else if(this.props.pageType === "IssueEvents" || this.props.pageType === "Notifications") {
                if(this.state.selectedShowBy === "Services") { columnValue = item.service } 
                else { columnValue = item.asset_id }
            } else if(this.props.pageType === "ServiceQuota") {
                columnValue = item.label
                columnLabel = item.label
            } else if(this.props.pageType === "ChangeEvents") {
                if(this.state.selectedShowBy === "Services") { columnValue = item.resource_type } 
                else { columnValue = item.asset_arn }
            }
        }
        

        this.setState({ selectedRecord })

        let obj = {}
        obj.columnLabel = columnLabel
        obj.columnValue = columnValue
        obj.selectedField = field
        obj.allFields = allFields
        obj.selectedGroup = this.state.selectedGroup
        obj.selectedShowBy = this.state.selectedShowBy
        obj.start_time =this.state.start_time
        obj.end_time =this.state.end_time
        obj.brushStartTime =this.state.brushStartTime ? this.state.brushStartTime : ""
        obj.brushEndTime =this.state.brushEndTime ? this.state.brushEndTime : ""
        obj.selectedProvider = this.state.selectedProvider
        obj.pageType = this.props.pageType
        
        this.props.setResourcePropsDetails('leftSectionSelection', obj)

        this.props.reloadRightSide()
    }

    onChangeShowBy = (showBy) => {
        this.props.setResourcePropsDetails('leftSectionSelection', {})
        this.setState({ selectedShowBy: showBy, menuDisabled: true, emptySearchText: true, showLoading: true },
            () =>  {
                if(this.props.pageType === "Alerts") {
                    this.getObservabilityAlertsTotalCount()
                } else if(this.props.pageType === "Anomalies") {
                    this.getAnomalyTotalCount()
                } else if(this.props.pageType === "Checks" || this.props.pageType === "Compliance") {
                    this.getAggregatedIdentifierResults()
                } else if(this.props.pageType === "Errors") {
                    this.getTotalCount()
                } else if(this.props.pageType === "Notifications" || this.props.pageType === "IssueEvents") {
                    this.getAggregatedEvents()
                } else if(this.props.pageType === "LifeCycle") {
                    if(this.state.selectedShowBy === "Assets") {
                        this.getLifeCycleServices()
                    } else {
                        this.getAggregatedEvents()
                    }
                } else if(this.props.pageType === "ChangeEvents") {
                    this.getChangeEvents()
                }
                this.props.selectedShowBy(this.state.selectedShowBy)
            }
        )
    }
    

    addSection = (array) => {
        let rowList = this.state[array];
        if(this.state[array]) {
            let value = this.state[array][this.state[array].length - 1]
            value = value+1 
            rowList.push(value);
        }
        
        this.setState({[array]: rowList },
            // () => this.checkAllDataFilled()    
        )
    }

    removeSection = (item, i) => {
        this.setState({ ["tag_key_"+item]: "", ["tag_value_"+item]: "" },
            () => {
                let rowList = this.state.tagsArray;
                rowList.splice(i, 1);
                this.setState({ tagsArray: rowList })
            }
        )
    }

    resetFilterSelection = () => {
        this.state.tagsArray.forEach(item => {
            this.setState({ ["tag_key_"+item]: "", ["tag_value_"+item]: "" })
        })

        this.setState({ tagsArray: [0], selectedService: [], assetCategory: [] },
            // () => this.filterResults()
        )
    }

    removefilter = (index, value, field) => {
        let filteredResult = this.state[field]
        filteredResult = this.state[field].filter(e => e !== value)
        
        // filteredResult = this.state[field].filter(e => e.key !== option.key && e.value !== option.value)
        this.setState({ [field]: filteredResult })
    }

    getAllTagsKeys = () => {
        if(!this.props.tagKeys || !this.props.tagKeys.length) {
            let params = {}
            this.props.getAllTagsKeys(params, (promise, response) => {})
        }
    }

    getAllTagsValues = (index) => {
        let params = {}
        params.tags = [{ "key": this.state["tag_key_"+index] }]
        this.props.getAllTagsValues(params, (promise, response) => {
            if(promise) {
                this.setState({ ["tagValues_"+index]: response })
            }
        })
    }
    
    handleMultiSelectChange = (field, arrayValue) => {
		let value = arrayValue.map(item => item.value)

		let selectedValue = []
		if(field === 'selectedService') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.props.services.length -1)) {
					selectedValue.push('All')
				}
			}
            this.setState({ [field]: selectedValue })
		} else if(field === 'assetCategory') {
			let prevState = this.state[field] ? this.state[field] : []
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.categoryList.forEach(item => {
						selectedValue.push(item)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.categoryList.length -1)) {
					selectedValue.push('All')
				}
			}	
            this.setState({ [field]: selectedValue, changesInFilter: true },
                // () => this.filterResults()    
            )		
		}
    }
    
    getMultiSelectedCount = (type, array) => {
        return array.length && array.includes('All') ? 'All' : (array.length ? array.length +' Selected' : 'All')
    }    
    
    handleChange = (event) => {
        let pageType = this.props.pageType        
        this.setState({ selectedGroup: event.target.value, showLoading: true },
            () => {
                if(pageType === "Checks" || pageType === "Compliance") {
                    this.getAggregatedIdentifierResults()
                } else if(pageType === "Anomalies") {
                    this.getAnomalyTotalCount()
                } else if(pageType === "Errors") {
                    this.getTotalCount()
                }
            }    
        )
    }

    filteredResults = () => {
        let results = this.state.listData ? this.state.listData : []
        if(results.length && this.state.assetCategory && this.state.assetCategory.length) {
            let data = []
            this.state.assetCategory.forEach(cat => {
                if(results.filter(e=> e.category.includes(cat)).length) {
                    let categoryResults = results.filter(e=> e.category.includes(cat))
                    data = data.concat(categoryResults)
                    data = data.filter((item,index) => { return (data.indexOf(item) === index) })
                }
            })
            results = data
        }
        if(this.state.selectedService && this.state.selectedService.length) {
            results = results.filter(e => this.state.selectedService.includes(e.service_name))
        }
        
        if(results && results.length && this.state.tagsArray && this.state.tagsArray.length) {
            let tagMatchedData = []
            let recordExist = false
            this.state.tagsArray.forEach(item => {
                if(this.state["tag_key_"+item] && this.state["tag_key_"+item] !== "" && this.state["tag_value_"+item] && this.state["tag_value_"+item] !== "") {
                    recordExist = true
                    if(tagMatchedData && tagMatchedData.length) {
                        let newTagsMatched = []
                        tagMatchedData.forEach(itm => {
                            if(itm.tags && itm.tags.length && itm.tags.filter(e => e.key === this.state["tag_key_"+item] && e.value === this.state["tag_value_"+item]).length) {
                                newTagsMatched.push(itm)
                            }
                        })
                        tagMatchedData = newTagsMatched
                    } else {
                        results.forEach(itm => {
                            if(itm.tags && itm.tags.length && itm.tags.filter(e => e.key === this.state["tag_key_"+item] && e.value === this.state["tag_value_"+item]).length) {
                                tagMatchedData.push(itm)
                            }
                        })                        
                    }
                }
            })
            console.log("recordExist", recordExist)
            if(recordExist) {
                results = tagMatchedData
            }

            console.log("results", results)
            console.log("tagMatchedData", tagMatchedData)
        }

        if(this.state.searchText && this.state.searchText !== '') {
			results =
				results &&
				results.filter(item => {
					let isPresent = []
					isPresent = this.recursiveSearch(item, this.state.searchText)
						.flat()
						.filter(bool => bool === true)
					if (isPresent[0]) {
						return true
					} else {
						return false
					}
				})
		}

        let totalPages = 1
        if(results && results.length > this.state.perPage) {
            totalPages = Math.ceil(results.length / this.state.perPage)
        }
        let showLoadMore = false
        if(results.length > this.state.endRecord) {
            showLoadMore = true
        } 

        this.setState({ filteredList: results, totalPages, startRecord: 0, currentPage: 1, showLoadMore })
    }
    
	render() {
		return (
            <div className="" id="scrollTop">
                <div className={`d-flex justify-content-between pt-1 ${this.state.showLoading ? "disabled" : ""}`}>
                    <div className="d-flex">
                        <ul className={`nav nav-tabs border-bottom bg-dark shadow-none rounded justify-content-between`} id="myTab1" role="tablist1">
                            <span className={`d-flex`}>
                                <li className="nav-item">
                                    <span className={`nav-link text-white cursorPointer ${this.state.selectedShowBy === 'Services' ? ' active' : ''}`} onClick={() => this.onChangeShowBy("Services")}>
                                        Services 
                                    </span>
                                </li>
                                <li className="nav-item">
                                    <span className={`nav-link text-white cursorPointer ${this.state.selectedShowBy === 'Assets' ? ' active' : ''}`} onClick={() => this.onChangeShowBy("Assets")}>
                                        Assets 
                                    </span>
                                </li>
                            </span>
                        </ul>
                        <div className="d-flex ml-5">
                            <p className="m-0  align-self-center small mt-2">Showing <span className="text-info">{!this.state.showLoading && this.state.listData && this.state.listData.length} {this.state.selectedShowBy ? this.state.selectedShowBy : ""}</span> </p>
                            <p className="m-0 align-self-center small ml-1 mt-2"> in {this.props.pageType ? (this.props.pageType === "IssueEvents" ? "Issues" : this.props.pageType) : ""}</p>
                            {this.state.groupOptions && this.state.groupOptions.length ?
                                <React.Fragment>
                                <p className="m-0 align-self-center small mx-1 mt-2"> by </p>
                                <div class={`transparentSelectBorderBottom  align-self-center`}>
                                    <select class="form-control" 
                                    onChange={this.handleChange}
                                    value={this.state.selectedGroup}
                                    >
                                        {this.state.groupOptions && this.state.groupOptions.map(item => {
                                            return(
                                                <option value={item.value}>{item.label}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                </React.Fragment>
                            : null}
                            
                        </div>
                    </div>
                    
                    {this.state.listData && this.state.listData.length ?
                        <div class="bg-dark pt-2">
                            <div className={`d-flex`}>
                                <div className="align-self-center">
                                    <SearchWithHiddenInput
                                        data={this.state.listData}
                                        applyLiteDarkTags={true}
                                        searchClassName={'border-0'}
                                        searchIconColor={'text-gray3'}
                                        className="form-control-sm text-gray3 bg-muted border-0"
                                        hideInputSection={true}
                                        topClassName={'dark-search'}
                                        searchPlaceHolder={'Search....'}
                                        filteredData={(filteredList) => this.setState({ filteredList, emptySearchText: false })}
                                        resetSearchBox={this.state.emptySearchText}
                                    />

                                    {/* <Search
                                        data={this.state.listData}
                                        applyTags={false}
                                        applyLiteDarkTags={true}
                                        topClassName={'bg-black5 border-gray5 rounded-5 f12'}
                                        searchClassName={'px-2 f12'}
                                        searchIconColor={'text-gray5 f12'}
                                        searchPlaceHolder={'Search....'}
                                        className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                        filteredData={(filteredList) => this.setState({ filteredList, emptySearchText: false })}
                                        resetSearchBox={this.state.emptySearchText}
                                    /> */}
                                </div>
                                {this.state.selectedShowBy === "Assets" ? 
                                    <i className="fas fa-filter align-self-center cursorPointer mr-2" onClick={() => this.setState({ showFilterSection: !this.state.showFilterSection }, () => this.getAllTagsKeys())}></i>
                                : null}
                            </div>
                            {this.state.showFilterSection ?
                                <div className="resourceDashboardFilter border mt-1">
                                    <div className="p-3 zapInputDark bg-muted">
                                        <div className="w-100">
                                            <div className="d-flex flex-wrap mb-1">
                                                <label className="mb-0">Category</label>
                                                {this.state.assetCategory && this.state.assetCategory.length && !this.state.assetCategory.includes("All") ?
                                                    this.state.assetCategory.map((cat, index) => {
                                                        return(
                                                            <span className="badge badge-secondary ml-2 mb-1">
                                                                {cat}
                                                                <i onClick={ () => this.removefilter(index, cat, 'assetCategory') } className='ml-1 fal fa-times cursorPointer'></i>
                                                            </span>
                                                        )
                                                    })
                                                : this.state.assetCategory && this.state.assetCategory.includes("All") ?
                                                    <span className="badge badge-secondary ml-2 mb-1">All</span>
                                                : null}
                                            </div>                                                        
                                            <div className={`multiselectDarkTheme align-self-center mr-2 z998`} ref={this.categoryRef}>
                                                <ReactMultiSelectCheckboxes						
                                                    placeholderButtonLabel="All"
                                                    getDropdownButtonLabel={() => this.getMultiSelectedCount('assetCategory', this.state.assetCategory)}
                                                    // menuIsOpen ={this.state.isCategoryOpen}
                                                    value={this.state.assetCategory && this.state.assetCategory.map(cat => ({
                                                        value: cat
                                                    }))}
                                                    onChange={arr => { this.handleMultiSelectChange('assetCategory', arr ? arr : []) }}
                                                    options={this.state.categoryList.map(item => ({
                                                        value: item.category,
                                                        label: item.category,
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-100 mt-2">
                                            <div className="d-flex flex-wrap mb-1">
                                                <label className="mb-0">Services</label>
                                                {this.state.selectedService && this.state.selectedService.length && !this.state.selectedService.includes("All") ?
                                                    this.state.selectedService.map((ser, index) => {
                                                        return(
                                                            <span className="badge badge-secondary ml-2 mb-1">
                                                                {ser}
                                                                <i onClick={ () => this.removefilter(index, ser, 'selectedService') } className='ml-1 fal fa-times cursorPointer'></i>
                                                            </span>
                                                        )
                                                    })
                                                : this.state.selectedService && this.state.selectedService.includes("All") ?
                                                    <span className="badge badge-secondary ml-2 mb-1">All</span>
                                                : null}
                                            </div>
                                            <div className={`multiselectDarkTheme align-self-center mr-2 z999`}>
                                                <ReactMultiSelectCheckboxes						
                                                    placeholderButtonLabel="All"
                                                    // menuIsOpen ={this.state.serviceDropdown}
                                                    getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedService', this.state.selectedService)}
                                                    value={this.state.selectedService.map(service => ({
                                                        value: service,
                                                        label: service
                                                    }))}
                                                    onChange={arr => { this.handleMultiSelectChange('selectedService', arr ? arr : []) }}
                                                    options={this.props.services.map(service => ({
                                                        value: service.service_name,
                                                        label: service.service_name,
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                        <div className={`w-100 mt-2`}>
                                            <label className="mb-1">Tags</label>
                                            {this.state.tagsArray.map((index, i) => {
                                                return(
                                                    <div className={`w-100 ${i ? "mt-2 border-top-black4" : ""}`}>
                                                        <div className={`d-flex ${!i ? "" : "mt-3"}`}>
                                                            <div className="w-80">
                                                                <Select
                                                                    placeholder={'Select'}
                                                                    isSearchable={true}
                                                                    // menuIsOpen={true}
                                                                    className='f13 p-0 dark-select-theme-with-dropdown-indicator'
                                                                    value={({
                                                                        value: this.state["tag_key_"+index] && this.state["tag_key_"+index] !== "" ? this.state["tag_key_"+index] : 'Select',
                                                                        label: this.state["tag_key_"+index] && this.state["tag_key_"+index] !== "" ? this.state["tag_key_"+index] : <span className="placeholder">Select</span>
                                                                    })}
                                                                    options={this.props.tagKeys && this.props.tagKeys.map(item => ({
                                                                        value: item,
                                                                        label: item,	
                                                                    }))}
                                                                    onChange={event => this.setState({
                                                                        ["tag_key_"+index]: event.value,
                                                                        ["tagValues_"+index]: [],
                                                                    },
                                                                        () => this.getAllTagsValues(index)
                                                                    )}
                                                                />
                                                                {/* <Input
                                                                    type="text"
                                                                    placeholder="key"
                                                                    className="inputTextbox-sm w-100 f12"
                                                                    value={this.state["tag_key_"+index]}
                                                                    id={"tagsKey"}
                                                                    onChange={event =>
                                                                        this.setState({ ["tag_key_"+index]: event.target.value })
                                                                    }
                                                                /> */}
                                                                {/* <Input
                                                                    type="text"
                                                                    placeholder="value"
                                                                    className="inputTextbox-sm mt-2 w-100 f12"
                                                                    value={this.state["tag_value_"+index]}
                                                                    id={"tagsValue"}
                                                                    onChange={event =>
                                                                        this.setState({ ["tag_value_"+index]: event.target.value })
                                                                    }
                                                                /> */}
                                                                <Select
                                                                    placeholder={'Select'}
                                                                    isSearchable={true}
                                                                    // menuIsOpen={true}
                                                                    className='f13 p-0 dark-select-theme-with-dropdown-indicator mt-2'
                                                                    value={({
                                                                        value: this.state["tag_value_"+index] && this.state["tag_value_"+index] !== "" ? this.state["tag_value_"+index] : 'Select',
                                                                        label: this.state["tag_value_"+index] && this.state["tag_value_"+index] !== "" ? this.state["tag_value_"+index] : <span className="placeholder">Select</span>
                                                                    })}
                                                                    options={this.state["tagValues_"+index] && this.state["tagValues_"+index].map(item => ({
                                                                        value: item,
                                                                        label: item,	
                                                                    }))}
                                                                    onChange={event => this.setState({
                                                                        ["tag_value_"+index]: event.value
                                                                    })}
                                                                />
                                                            </div>
                                                            {this.state.tagsArray.length > 1 ?
                                                                <span className={`far fa-trash cursor-pointer f18 align-self-center ml-4`} onClick={() => this.removeSection(index, i)}></span>
                                                            : null}
                                                            {(this.state.tagsArray.length - 1) ===  i ?
                                                                <span className={`far fa-plus cursor-pointer f18 align-self-center ml-4`} onClick={() => this.addSection("tagsArray")}></span>
                                                            : null}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className="d-flex justify-content-end mt-3">
                                            <span className={`text-info mr-2 align-self-center`} onClick={() => this.resetFilterSelection()}>Clear</span>
                                            <button className={`btn btn-sm bg-info text-white mr-2`} onClick={() => this.setState({ showFilterSection: false }, () => this.filteredResults())}>Filter</button>
                                            <button className={`btn btn-sm btn-light`} onClick={() => this.setState({ showFilterSection: false })}>Close</button>
                                        </div>
                                    </div>
                                </div>
                            : null}
                        </div>
                    : null}
                </div>
                {this.state.showLoading ?
                    <div className='d-flex justify-content-center pt-4'>
                        <Spinner className='text-center' color='white' size='lg' />
                    </div>
                :
                    <div className="d-flex">
                        {this.props.pageType === "LifeCycle" && this.state.selectedShowBy === "Assets" ?
                            <div class="bg-dark3 w-25 overflowHidden-y-scroll border-right-info minMaxHeight820">
                                {this.state.serviceList && this.state.serviceList.length ?
                                    this.state.serviceList.map((serlist, i) => {
                                        return (
                                        <div className={`px-2 cursorPointer ${i ? "border-top" : ""} ${this.state.selectedLifecycleService === serlist.service_name ? 'text-info disabled' : ''}`} onClick={() => this.setState({ selectedLifecycleService: serlist.service_name }, () => this.getAggregatedEvents())}>
                                            <div className="hiddenEllipses d-flex" id={"service_name_"+i}>
                                                <p className={`m-0 f-20 pl pr-1 pt-2 pb-2 ${this.state.selectedLifecycleService === serlist.service_name ? 'text-info' : 'text-white'}`}>{serlist.service_name + (serlist.count ? " ("+serlist.count+") " : "")}</p>
                                            </div>
                                            <UncontrolledTooltip placement='top' target={"service_name_"+i}>
                                                <div className="text-left">  
                                                    <p className="mb-1 text-primary-color"><span className="text-white mr-1">Asset: </span>{serlist.service_name + (serlist.count ? " ("+serlist.count+") " : "")}</p>
                                                </div>    
                                            </UncontrolledTooltip>
                                        </div>
                                        )
                                    })
                                : null}
                            </div>
                        : null}
                        <div className="stickyTable table-responsive">
                            <table className="table text-white mb-0 obsSectionTableHeight overflowYAuto displayBlock" >
                                <thead className="" >
                                    <tr class="w-100">                                    
                                        <th style={{width: "300px"}}>{this.state.selectedShowBy}</th>
                                        <th className={`text-center ${this.state.filteredList && this.state.filteredList.length ? "" : "w-100"}`}>Total</th>
                                        {this.state.filteredList && this.state.filteredList.length && this.state.filteredList[0].data ?
                                            Object.keys(this.state.filteredList[0].data).map(key => {
                                                return(
                                                    <th className={`text-center ${Object.entries(this.state.filteredList[0].data).length === 3 ? "w-33" : Object.entries(this.state.filteredList[0].data).length === 2 ? "w-50" : Object.entries(this.state.filteredList[0].data).length === 1 ? "w-100" : ""}`}>
                                                        {this.props.pageType === "Notifications" ?
                                                            capitalizeTheFirstLetterOfEachWord(key.toLowerCase().replace("_notification", ""))
                                                        : this.props.pageType === "IssueEvents" ?
                                                            capitalizeTheFirstLetterOfEachWord(key.toLowerCase().replace("_issue", ""))
                                                        : this.props.pageType === "Compliance" ?
                                                            capitalizeAllLetter(key)
                                                        : 
                                                            capitalizeTheFirstLetterOfEachWord(key)
                                                        }
                                                    </th>
                                                )
                                            })
                                        : null}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.filteredList && this.state.filteredList.length ?
                                        this.state.filteredList.map((item, index) => {
                                            return (
                                                <tr className={`w-100 border-top ${this.state.selectedRecord === item.label ? "text-info" : ""}`} >
                                                    <td className="align-middle py-1" style={{maxWidth: "300px"}} onClick={() => this.selectedColumn("all", "", index, item)} id={"asset_name_"+index}>
                                                        <div className="d-flex">
                                                            {this.state.selectedShowBy === "Services" ?
                                                                <p className={`mb-0 hiddenEllipses ${this.state.selectedRecord === item.label ? "text-info" : "text-white"}`}>{item.label}</p>
                                                            :
                                                                item.id_name_map && item.id_name_map !== "" ?
                                                                    <React.Fragment>
                                                                        <span className={`hiddenEllipses ${this.state.selectedRecord === item.label ? 'text-info' : 'text-white'}`}>{(item.id_name_map.value ? item.id_name_map.value : "")}</span>
                                                                        <span className={`ml-1 hiddenEllipses ${this.state.selectedRecord === item.label ? 'text-info' : 'text-primary-color'} mb-0`}>
                                                                            {item.id_name_map.key ? " : "+item.id_name_map.key : ""}
                                                                        </span>
                                                                    </React.Fragment>
                                                                :
                                                                    <React.Fragment>
                                                                    <span className={`hiddenEllipses ${this.state.selectedRecord === item.label ? 'text-info' : 'text-white'}`}>{item.label}</span>
                                                                    {item.resource_type ?
                                                                        item.resource_type === "ApiGateway" ?
                                                                            item.rest_api_name && item.rest_api_name !== "" ?
                                                                                <p className={`ml-1 hiddenEllipses ${this.state.selectedRecord === item.label ? 'text-info' : 'text-primary-color'} mb-0`}>({item.rest_api_name})</p>
                                                                            : null
                                                                        : item.resource_type === "EC2" ?
                                                                            item.tags && item.tags.length && item.tags.filter(e => e.key === "Name").length ?
                                                                                <p className={`ml-1 hiddenEllipses ${this.state.selectedRecord === item.label ? 'text-info' : 'text-primary-color'} mb-0`}>({item.tags.filter(e => e.key === "Name")[0].value})</p>
                                                                            : null
                                                                        : null
                                                                    : null}
                                                                    </React.Fragment>
                                                            }            
                                                        </div>

                                                        {this.state.selectedShowBy === "Assets" && item.resource_type ?
                                                            <p className={`pt-1 mb-0 ${this.state.selectedRecord === item.label ? "text-info" :     "text-primary-color"}`}> {item.resource_type +""+(item.service_name ? " : "+item.service_name : "")} </p>
                                                        : null}
                                                    </td>
                                                    {item.data ?
                                                        <React.Fragment>
                                                        <td className={`align-middle text-center f18 ${this.state.selectedRecord === item.label ? "text-info" : ""}`} onClick={() => this.selectedColumn("all", "", index, item)}>{Object.values(item.data).reduce((a, b) => a + b, 0)}</td>
                                                        {Object.entries(item.data).map(([key, val]) => {
                                                            return(
                                                                <td className={`align-middle text-center f18 ${this.state.selectedRecord === key+"_"+index ? "text-info" : ""}`} onClick={() => this.selectedColumn("", key, index, item)}>{val ? val : "-"}</td>
                                                            )
                                                        })}
                                                        </React.Fragment>
                                                    : item.count ?
                                                        <td className={`align-middle text-center f18 ${this.state.selectedRecord === item.label ? "text-info" : ""}`} onClick={() => this.selectedColumn("all", "", index, item)}>{item.count}</td>
                                                    : null}
                                                    
                                                    <UncontrolledTooltip placement='top' target={"asset_name_"+index}>
                                                        <div className="uncontrolledTooltipLeftAlign">
                                                            <p className="mb-1 text-primary-color wordBreakAll"><span className="text-white mr-1">Name: </span> 
                                                                {item.id_name_map && item.id_name_map !== "" ?
                                                                    <span className={`ml-1`}>{(item.id_name_map.value ? item.id_name_map.value : "")}</span>
                                                                :
                                                                    <span className={`ml-1`}>{item.label}</span>
                                                                }
                                                            </p>

                                                            {this.state.selectedShowBy === "Assets" ?
                                                                item.id_name_map && item.id_name_map !== "" ?
                                                                    item.id_name_map.key ? 
                                                                        <p className={`mb-1 wordBreakAll`}>{item.id_name_map.key}</p>
                                                                    : null
                                                                :
                                                                    item.resource_type ?
                                                                        item.resource_type === "ApiGateway" ?
                                                                            item.rest_api_name && item.rest_api_name !== "" ?
                                                                                <p className={`mb-1 wordBreakAll`}>{item.rest_api_name})</p>
                                                                            : null
                                                                        : item.resource_type === "EC2" ?
                                                                            item.tags && item.tags.length && item.tags.filter(e => e.key === "Name").length ?
                                                                                <p className={`mb-1 wordBreakAll`}>{item.tags.filter(e => e.key === "Name")[0].value}</p>
                                                                            : null
                                                                        : null
                                                                    : null
                                                            : null}
                                                            
                                                            {this.state.selectedShowBy === "Assets" && item.resource_type ?
                                                                <p className={`mb-1 text-primary-color`}><span className="text-white mr-1">Services: </span> {item.resource_type+""+(item.service_name ? " : "+item.service_name : "")} </p>
                                                            : null}
                                                            <div className="d-flex flex-wrap">
                                                                {item.alerts ?
                                                                    <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">Alerts {item.alerts}</span>
                                                                : null}
                                                            </div>
                                                        </div>    
                                                    </UncontrolledTooltip>
                                                </tr>
                                            )
                                        })
                                    :
                                        <tr>
                                            <td colSpan="5" className="text-center bg-transparent"><p className="mb-0">There are no data on criteria. Please try adjusting your filter.</p></td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
LeftSideTab.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('LeftSideTab',state)
	return {
        services: state.filters.services,
        dashboardDetails: state.assets.resourcesPropsDetails && state.assets.resourcesPropsDetails.dashboardDetails ? state.assets.resourcesPropsDetails.dashboardDetails : {},
        tagKeys: state.filters.tagKeys,
    }
}

export default connect(mapStateToProps, {
    listAllServices,
    getAllTagsKeys,
    getAllTagsValues,
    getObservabilityAlertsTotalCount,
    getAnomalyTotalCount,
    getAggregatedIdentifierResults,
    getTotalCount,
    getAggregatedEvents,
    getChangeEvents,
    getCoveredServices,
    setResourcePropsDetails
})(withRouter(LeftSideTab))