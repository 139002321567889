/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file CaseDetails.js
 * @author Prakash // on 10/02/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { URL_PATH } from '../../config/urlPath'
import { listAllRegions } from '../../actions/commonAction'
import { setAiopsPropsDetails, getCaseDetails, updateCaseDetails } from '../../actions/aiops/AiopsAction'
import { AppWrapper } from '../common/AppWrapper'
import { momentConvertionUtcToLocalTime, twoDateDiffrenceDayHoursMinutes, getRegionName, capitalizeFirstLetter } from '../../utils/utility'


import SummaryLeftSection from './Summary/SummaryLeftSection'
import SummaryRightSection from './Summary/SummaryRightSection'

import RemediationLeftSection from './remediation/RemediationLeftSection'
import RemediationRightSection from './remediation/RemediationRightSection'

import AlertLeftSection from './alerts/AlertLeftSection'
import AlertRightSection from './alerts/AlertRightSection'

import EventsLeftSection from './events/EventsLeftSection'
import EventsRightSection from './events/EventsRightSection'

import AnomaliesLeftSection from './anomalies/AnomaliesLeftSection'
import AnomaliesRightSection from './anomalies/AnomaliesRightSection'

import ErrorsLeftSection from './errors/ErrorLeftSection'
import ErrorsRightSection from './errors/ErrorRightSection'

import AssetsLeftSection from './assets/AssetsLeftSection'
import AssetsRightSection from './assets/AssetsRightSection'

import AssetDetailPage from './assets_new/AssetDetailPage'
import AssetDetailPageRight from './assets_new/AssetDetailPageRight'

import TopologyLeftSection from './topology/TopologyLeftSection'
import TopologyRightSection from './topology/TopologyRightSection'

import DiagnosticsLeftSection from './diagnostics/DiagnosticsLeftSection'
import DiagnosticsRightSection from './diagnostics/DiagnosticsRightSection'

import RootCauseLeftSection from './rootCause/RootCauseLeftSection'
import RootCauseRightSection from './rootCause/RootCauseRightSection'


import CloseSidePanel from './CloseSidePanel'
import Spinner from 'reactstrap/lib/Spinner'


class CaseDetails extends Component {
    
    constructor(props) {

        super(props)
        this.scrollRef = React.createRef()
        
        this.state = {
            disableMenu: true,
            caseRecord: {},
            showLoading: false,	
            regions: [],
            selectedRecord: [],
            selectedRecordId: '',
            startProcessorId: '',
            caseView: '',
            
            activeParentTab: '', 
            activeTopTab: '',
            enteredTitle: '',
        }
    }

    componentDidMount = () => {
        if(this.props.match && this.props.match.params && this.props.match.params.id) {
            this.setState({ caseId: this.props.match.params.id },
                () => this.getCaseDetails(this.state.caseId)
            )
        } else if(this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state) {
            this.setState({ 
                    caseId: this.props.history.location.state.caseId,
                    caseView: this.props.history.location.state.caseView 
                }, 
                () => {
                    if(this.props.history.location.state.listCaseDetails) {
                        this.props.setAiopsPropsDetails('aiopsListCaseDetails', this.props.history.location.state.listCaseDetails)
                    }
                    if(this.props.history.location.state.caseId) {
                        this.getCaseDetails(this.state.caseId)
                    }
                }
            )
        }
        // this.getCaseDetails(this.state.caseId)
    }

    getCaseDetails = (caseId) => {
        this.props.setAiopsPropsDetails('caseDetails', {})
        let caseDetailParams = {}
        caseDetailParams.case_id = caseId
        caseDetailParams.alerts = true
        caseDetailParams.assets = true
        this.props.getCaseDetails(caseDetailParams, (promise, result) => {
            this.setState({ disableMenu: false },
                () => {
                    if(this.state.activeParentTab === '') {
                        this.setState({ activeParentTab:  'Observe', activeTopTab: 'Alert' })
                    }    
                }
            )
        })
    }
    
    componentDidUpdate = (prevProps, prevState) => {
        // if(this.state.caseId === '') {
        //     if(prevProps.aiopsPropsDetails.selectedCase !== this.props.aiopsPropsDetails.selectedCase || this.props.aiopsPropsDetails.selectedCase) {
        //         this.setState({ caseId: this.props.aiopsPropsDetails.selectedCase.case_id },
        //             () => this.getCaseDetails(this.props.aiopsPropsDetails.selectedCase.case_id)
        //         )
        //     }

        //     if(!prevProps.aiopsPropsDetails.selectedCase && !this.props.aiopsPropsDetails.selectedCase) {
        //         this.props.history.push({ pathname: URL_PATH.AIOPS_DASHBOARD })
        //     }
        // }

        if(this.props.caseDetails !== prevProps.caseDetails || this.state.regions !== prevState.regions) {
            this.getRegion()
        }
    }

    getRegion = () => {
        if(!this.props.regions || !this.props.regions[0] || !this.props.regions[0].provider_regions) {
            let params = {
                provider: this.props.caseDetails ? (Array.isArray(this.props.caseDetails.provider) ? this.props.caseDetails.provider[0] : this.props.caseDetails.provider) : ''
            }

            if(params.provider && params.provider !== '') {
                this.props.listAllRegions(params, (promise, regions) => {
                    if(promise) {
                        this.setState({ regions: regions && regions[0] && regions[0].provider_regions })
                    }
                })
            }
        } else {
            this.setState({ regions: this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions })
        }
    }

    updateCaseDetails = () => {
        let hasError = false
        let errorMessage = ''
        if(this.state.enteredTitle === '') {
            hasError = true
            errorMessage = 'Title should not be empty'
        }
        this.setState({ hasError, errorMessage, saveLoading: hasError ? false : true })
        if(!hasError) {
            let params = {}
            params.case_id = this.props.caseDetails.case_id
            params.title = this.state.enteredTitle
                this.props.updateCaseDetails(params, (promise, response) => {
                    if(promise) {
                    this.setState({ caseUpdated: response, saveLoading: false, editCase: false })
                } else {
                    this.setState({ caseUpdated: false })
                }
            })
        }
    }

	render() {
		return (
            this.state.caseId !== '' ?
                <div class={`main-content bg-muted ${this.state.disableMenu ? 'disabled' : ''}`}>
                    <div className={`container-fluid overflow-auto flex-grow-1`}>
                        {this.state.caseCloseSection ? 
                            <CloseSidePanel 
                                closeSidePanel={() => this.setState({ caseCloseSection: false })}
                                caseId = {this.state.caseId}
                                onCaseClose={caseId => this.getCaseDetails(caseId)}
                            />
                        : null 
                        }
                        <div className='row h-100'>
                            <div className={` border-right ${this.state.activeParentTab === 'Observe' && this.state.activeTopTab === 'Topology' 
                                ? (!this.state.fullScreen ? 'col-md-7' : 'col-md-12') 
                                : this.state.activeParentTab === 'Observe' && this.state.activeTopTab === 'Assets New' 
                                ? "col-md-7"
                                : (this.state.activeParentTab === 'Remediation' ? 'col-md-7' : 'col-md-6')} p-0`}>
                                {!this.state.expandCaseDetailSection ?
                                    <div className="title-section pb-3">
                                        {this.props.caseDetails ? 
                                            <div className='d-flex justify-content-between'>
                                                <div className="d-flex">
                                                    <h6 className="text-white m-0 f16 text-break align-self-center">
                                                        {this.state.enteredTitle !== '' ?
                                                            this.state.enteredTitle
                                                        :
                                                            this.props.caseDetails.title 
                                                        }
                                                    </h6>
                                                    <p className="mb-0 ml-3">ID <span className="text-white f12">#{this.state.caseId}</span></p>
                                                    <p className="mb-0 ml-3">Start: <span className="text-white f12"><b>{this.props.caseDetails && this.props.caseDetails.created_at ? momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'DD MMM YYYY HH:mm') : ''}</b></span></p>
                                                    {this.props.caseDetails && this.props.caseDetails.status_changes && this.props.caseDetails.status_changes.filter(e => e.status === "Resolved").length ?
                                                        <p className="mb-0 ml-3">End: <span className="text-white f12"><b>{momentConvertionUtcToLocalTime(this.props.caseDetails.status_changes.filter(e => e.status === "Resolved")[this.props.caseDetails.status_changes.filter(e => e.status === "Resolved").length - 1].start_time, 'DD MMM YYYY HH:mm')}</b></span></p>
                                                    : null}
                                                </div>
                                                <div className="d-flex">
                                                    <div class="d-flex ml-1">
                                                        <label class="mb-0 text-white">Severity</label>
                                                        <span class={`badge risk-badge-${this.props.caseDetails.severity ? this.props.caseDetails.severity.toLowerCase() : 'secondary'} ml-1`}>{this.props.caseDetails.severity ? capitalizeFirstLetter(this.props.caseDetails.severity) : 'Unknown'}</span>
                                                    </div>
                                                    {this.props.caseDetails && this.props.caseDetails.status ?
                                                    <div class="ml-1 d-flex">
                                                        <label class="mb-0 text-white">Status</label>
                                                        <span class="badge badge-primary mx-1">{this.props.caseDetails.status}</span>
                                                    </div>
                                                    : null}
                                                    <i className={`fa fa-caret-${this.state.expandCaseDetailSection ? "down" : "down"} cursorPointer ml-3 text-gray2 align-self-center`} onClick={() => this.setState({ expandCaseDetailSection: !this.state.expandCaseDetailSection })}></i>
                                                </div>
                                            </div>
                                        : null}
                                    </div>
                                :
                                    <div className="title-section pb-3">
                                        <div className='row d-flex justify-content-between'>
                                            <div className='d-flex align-self-center col-7'>
                                                <div className={`mr-3 ${this.state.caseView !== '' ? '' : 'displayNone'}`}>
                                                    <h6 className='m-0 text-white'>
                                                        <Link
                                                            className='text-white far fa-arrow-left'
                                                            to={{
                                                                pathname: URL_PATH.AIOPS_DASHBOARD,
                                                                state:{ caseView: this.state.caseView }
                                                            }}
                                                        >
                                                        </Link>
                                                    </h6>
                                                </div>
                                                <div className="w-100">
                                                    {this.state.editCase ? 
                                                        <React.Fragment>
                                                        <div className="d-flex">
                                                            <input type="text" name className={`my-1 w-100 align-self-center`}
                                                                readOnly={this.state.saveLoading}
                                                                onChange={(event) => this.setState({ enteredTitle:  event.target.value })} 
                                                                value={this.state.enteredTitle}
                                                            />
                                                            {!this.state.saveLoading ? 
                                                                <div className="d-flex">
                                                                    <i className="far fa-check-circle f20 ml-3 text-gray3 cursorPointer align-self-center" onClick={() => this.updateCaseDetails()}></i>
                                                                    <i className="far fa-times-circle f20 ml-2 text-gray3 cursorPointer align-self-center" onClick={() => this.setState({ editCase: false, enteredTitle: '' })}></i>
                                                                </div>
                                                            :
                                                                <Spinner className='ml-3  align-self-center' color='white' size='sm' />
                                                            }
                                                            {/* <button className="btn btn-sm btn-info" onClick={() => this.updateCaseDetails()}>Save</button> */}
                                                        </div>
                                                        {this.state.hasError ? 
                                                            <p className='m-0 text-danger align-self-end'>{this.state.errorMessage}</p>
                                                        : null}
                                                        </React.Fragment>
                                                    :  
                                                        this.props.caseDetails && this.props.caseDetails.title ? 
                                                            <div className="d-flex">
                                                                <h6 className="text-white m-0 f16 text-break align-self-center">
                                                                    {this.state.enteredTitle !== '' ?
                                                                        this.state.enteredTitle
                                                                    :
                                                                        this.props.caseDetails.title 
                                                                    }
                                                                </h6>
                                                                <i className="fas fa-pen ml-3 f12 text-gray3 cursorPointer align-self-center" onClick={() => this.setState({ editCase: true, enteredTitle: this.state.enteredTitle !== '' ? this.state.enteredTitle : this.props.caseDetails.title })}></i>
                                                            </div>
                                                        : null
                                                    }
                                                    <p className="text-white m-0">Case #{this.state.caseId}</p>
                                                    {/* <p className="text-white small m-0">{this.props.caseDetails && this.props.caseDetails.description ? this.props.caseDetails.description : ''}</p> */}
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-end col-5 text-white">
                                                <div className="w-75">
                                                    <p className="b-block mb-0">Provider:</p>
                                                    <p className="mb-0 f12"><b>{this.props.caseDetails && this.props.caseDetails.provider ? (Array.isArray(this.props.caseDetails.provider) ? this.props.caseDetails.provider[0].toUpperCase() : this.props.caseDetails.provider.toUpperCase()) : ''}</b></p>
                                                </div>
                                                <div className="w-100">
                                                    <p className="b-block mb-0">Account:</p>
                                                    <p className="mb-0 f12"><b>{this.props.caseDetails && this.props.caseDetails.account_id ? (Array.isArray(this.props.caseDetails.account_id) ? this.props.caseDetails.account_id[0] : this.props.caseDetails.account_id) : ''}</b></p>
                                                </div>
                                                <div className="w-100">
                                                    <p className="b-block mb-0">Region:</p>
                                                    <p className="mb-0 f12"><b>{this.props.caseDetails && this.props.caseDetails.region ? (Array.isArray(this.props.caseDetails.region) ? getRegionName(this.props.caseDetails.region[0], this.state.regions) : getRegionName(this.props.caseDetails.region, this.state.regions)) : ''}</b></p>
                                                </div>
                                                <i className={`fa fa-caret-left cursorPointer ml-3 text-gray2`} onClick={() => this.setState({ expandCaseDetailSection: !this.state.expandCaseDetailSection })}></i>
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col-12 d-flex justify-content-between">
                                                <div className="d-flex">
                                                    <p className="text-white mb-0 mr-3">Case Start Time: <b>{this.props.caseDetails && this.props.caseDetails.created_at ? momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'DD MMM YYYY HH:mm') : ''}</b></p>
                                                    {/* <p className="text-white mb-0 mr-3">Last Modified: <b>{this.props.caseDetails && this.props.caseDetails.last_change ? momentDateGivenFormat(this.props.caseDetails.last_change, 'DD MMM YYYY HH:mm:ss') : ''}</b></p> */}
                                                    {this.props.caseDetails && this.props.caseDetails.status_changes && this.props.caseDetails.status_changes.filter(e => e.status === "Resolved").length ?
                                                        <p className="mb-0 ml-3">Case End Time: <span className="text-white mb-0"><b>{momentConvertionUtcToLocalTime(this.props.caseDetails.status_changes.filter(e => e.status === "Resolved")[this.props.caseDetails.status_changes.filter(e => e.status === "Resolved").length - 1].start_time, 'DD MMM YYYY HH:mm')}</b></span></p>
                                                    : 
                                                        <p className="text-white mb-0">Last Modified: <b>
                                                            {this.props.caseDetails && this.props.caseDetails.last_change && this.props.caseDetails.last_change !== '' ?
                                                                twoDateDiffrenceDayHoursMinutes(this.props.caseDetails.last_change, 'currentTime')+' ago'
                                                            : this.props.caseDetails && this.props.caseDetails.last_event && this.props.caseDetails.last_event !== '' ?
                                                                twoDateDiffrenceDayHoursMinutes(this.props.caseDetails.last_event, 'currentTime')+' ago'
                                                            : ''}
                                                            </b>
                                                        </p>
                                                    }
                                                </div>
                                                <div className="d-flex text-light">
                                                    {this.props.caseDetails ?
                                                    <div class="d-flex">
                                                        <label class="mb-0">Severity</label>
                                                        <span class={`badge risk-badge-${this.props.caseDetails.severity ? this.props.caseDetails.severity.toLowerCase() : 'secondary'} ml-1`}>{this.props.caseDetails.severity ? capitalizeFirstLetter(this.props.caseDetails.severity) : 'Unknown'}</span>
                                                    </div>
                                                    : null}
                                                    {this.props.caseDetails && this.props.caseDetails.status ?
                                                    <div class="ml-3 d-flex">
                                                        <label class="mb-0">Status</label>
                                                        <span class="badge badge-primary mx-1">{this.props.caseDetails.status}</span>
                                                    </div>
                                                    : null}
                                                    
                                                    <div className="ml-2 btn-group dropDown" onClick={() => this.setState({ showCaseDetailDropDown: !this.state.showCaseDetailDropDown })}>
                                                        <i className="far fa-cog f15 align-self-center cursorPointer"></i>
                                                        <div className={`dropdown-menu dropdown-menu-right dropdownSettingDark bg-dark cursorPointer ${this.state.showCaseDetailDropDown ? 'show active' : ''}`}>
                                                            {/* <i className="fa fa-caret-down text-gray2"></i> */}
                                                            {this.props.caseDetails && this.props.caseDetails.status && this.props.caseDetails.status !== 'Closed' ?
                                                                <button className="dropdown-item text-white" type="button" onClick={() => this.setState({ caseCloseSection: true })}>
                                                                    Close
                                                                </button>
                                                            : null}
                                                            {/* <button className="dropdown-item" type="button">None</button> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-12 row text-white">
                                                <div className="col-12">
                                                    <p className="m-0 p-0 mb-2">Description: Service unavailable. Memory Issue</p>
                                                </div>
                                                <div className="col-6">
                                                    <label className="small mb-1 d-block">Created Date</label>
                                                    <h6>27 Jan 2021 11:18</h6>
                                                </div>
                                                <div className="col-6">
                                                    <label className="small mb-1 d-block">Last Updated</label>
                                                    <h6>27 Jan 2021 13:18</h6>
                                                </div>
                                                <div className="col-6">
                                                    <label className="small mb-1 d-block">Time since last updated</label>
                                                    <h6>2 hours</h6>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                }   
                                <div className={`${this.state.activeParentTab === 'Remediation' ? '' : ''}`}>
                                    <ul className={`nav nav-tabs border-bottom bd-highlight bg-muted shadow-none ${this.state.showLoading ? 'disabled' : ''}`} id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <span className={`nav-link text-white cursorPointer ${this.state.activeParentTab === 'Observe' ? ' active' : ''}`} onClick={() => this.setState({ activeParentTab: 'Observe', activeTopTab: 'Alert' })}>Observe</span>
                                        </li>
                                        <li className="nav-item">
                                            <span className={`nav-link text-white cursorPointer ${this.state.activeParentTab === 'Analysis' ? ' active' : ''}`} onClick={() => this.setState({ activeParentTab: 'Analysis', activeTopTab: 'Diagnostics' })}>Analysis</span>
                                        </li>
                                        <li className="nav-item">
                                            <span className={`nav-link text-white cursorPointer ${this.state.activeParentTab === 'Remediation' ? ' active' : ''}`} onClick={() => this.setState({ activeParentTab: 'Remediation' })}>Remediation</span>
                                        </li>
                                        <li className="nav-item">
                                            <span className={`nav-link text-white cursorPointer ${this.state.activeParentTab === 'Summary' ? ' active' : ''}`} onClick={() => this.setState({ activeParentTab: 'Summary' })}>Summary</span>
                                        </li>
                                        <li className="nav-item ml-auto bd-highlight align-self-center displayNone">
                                            <div className="form-group mr-2 mb-0">
                                                <select className="form-control form-control-sm">
                                                    <option value="">
                                                        Action
                                                    </option>
                                                    <option value="">1</option>
                                                    <option value="">2</option>
                                                    <option value="">3</option>
                                                </select>
                                            </div>
                                        </li>
                                    </ul>
                                    {this.state.activeParentTab === 'Observe' ?
                                        <ul className={`nav nav-tabs border-bottom bd-highlight bg-muted shadow-none ${this.state.showLoading ? 'disabled' : ''}`} id="myTab" role="tablist">
                                            <li className="nav-item">
                                                <span className={`nav-link text-white cursorPointer ${this.state.activeTopTab === 'Alert' ? ' active' : ''}`} onClick={() => this.setState({ activeTopTab: 'Alert' })}>Alerts</span>
                                            </li>
                                            <li className="nav-item">
                                                <span className={`nav-link text-white cursorPointer ${this.state.activeTopTab === 'Events' ? ' active' : ''}`} onClick={() => this.setState({ activeTopTab: 'Events' })}>Events</span>
                                            </li>
                                            <li className="nav-item">
                                                <span className={`nav-link text-white cursorPointer ${this.state.activeTopTab === 'Anomalies' ? ' active' : ''}`} onClick={() => this.setState({ activeTopTab: 'Anomalies' })}>Anomalies</span>
                                            </li>
                                            <li className="nav-item">
                                                <span className={`nav-link text-white cursorPointer ${this.state.activeTopTab === 'Errors' ? ' active' : ''}`} onClick={() => this.setState({ activeTopTab: 'Errors' })}>Errors</span>
                                            </li>
                                            <li className="nav-item">
                                                <span className={`nav-link text-white cursorPointer ${this.state.activeTopTab === 'Assets' ? ' active' : ''}`} onClick={() => this.setState({ activeTopTab: 'Assets' })}>Assets</span>
                                            </li>
                                            <li className="nav-item">
                                                <span className={`nav-link text-white cursorPointer ${this.state.activeTopTab === 'Assets New' ? ' active' : ''}`} onClick={() => this.setState({ activeTopTab: 'Assets New' })}>Assets New</span>
                                            </li>
                                            <li className="nav-item">
                                                <span className={`nav-link text-white cursorPointer ${this.state.activeTopTab === 'Topology' ? ' active' : ''}`} onClick={() => this.setState({ activeTopTab: 'Topology' })}>Topology</span>
                                            </li>
                                            
                                            {/* <li className="nav-item">
                                                <span className={`nav-link cursorPointer ${this.state.activeTopTab === 'Distribution' ? ' active' : ''}`} onClick={() => this.setState({ activeTopTab: 'Distribution' })}>
                                                    Distribution
                                                </span>
                                            </li> */}
                                        </ul>
                                    : null}
                                    {this.state.activeParentTab === 'Analysis' ?
                                        <ul className={`nav nav-tabs border-bottom bd-highlight bg-muted shadow-none ${this.state.showLoading ? 'disabled' : ''}`} id="myTab" role="tablist">
                                            <li className="nav-item">
                                                <span className={`nav-link text-white cursorPointer ${this.state.activeTopTab === 'Diagnostics' ? ' active' : ''}`} onClick={() => this.setState({ activeTopTab: 'Diagnostics' })}>Diagnostics</span>
                                            </li>
                                            <li className="nav-item">
                                                <span className={`nav-link text-white cursorPointer ${this.state.activeTopTab === 'Root Cause' ? ' active' : ''}`} onClick={() => this.setState({ activeTopTab: 'Root Cause' })}>Root Cause</span>
                                            </li>
                                        </ul>
                                    : null}
                                </div>
                                <div className={``}>
                                    {this.state.activeParentTab === 'Summary' ?
                                        <SummaryLeftSection 
                                            caseId = {this.state.caseId}
                                        />                                
                                    : this.state.activeParentTab === 'Remediation' ?
                                        <RemediationLeftSection 
                                            caseId = {this.state.caseId}
                                        />                                
                                    : (this.state.activeParentTab === 'Analysis' && this.state.activeTopTab === 'Root Cause') ?
                                        <RootCauseLeftSection 
                                            caseId = {this.state.caseId}
                                            caseRecord = {this.props.caseDetails}
                                        />                                
                                    : (this.state.activeParentTab === 'Analysis' && this.state.activeTopTab === 'Diagnostics') ?
                                        <DiagnosticsLeftSection 
                                            caseId = {this.state.caseId}
                                        />                                
                                    : (this.state.activeParentTab === 'Observe' && this.state.activeTopTab === 'Alert') ?
                                        <AlertLeftSection
                                            caseId = {this.state.caseId}
                                            caseRecord = {this.props.caseDetails}
                                            alertsGraphBrushInterval = {(alertStartDate, alertSndDate, alertStartInput, alertEndInput) => 
                                                this.setState({ alertStartDate, alertSndDate, alertStartInput, alertEndInput })
                                            }
                                        />
                                    : (this.state.activeParentTab === 'Observe' && this.state.activeTopTab === 'Anomalies') ?
                                        <AnomaliesLeftSection
                                            caseId = {this.state.caseId}
                                            caseRecord = {this.props.caseDetails}
                                            alertsGraphBrushInterval = {(alertStartDate, alertSndDate, alertStartInput, alertEndInput) => 
                                                this.setState({ alertStartDate, alertSndDate, alertStartInput, alertEndInput })
                                            }
                                        />
                                    : (this.state.activeParentTab === 'Observe' && this.state.activeTopTab === 'Errors') ?
                                        <ErrorsLeftSection
                                            caseId = {this.state.caseId}
                                            caseRecord = {this.props.caseDetails}
                                            alertsGraphBrushInterval = {(alertStartDate, alertSndDate, alertStartInput, alertEndInput) => 
                                                this.setState({ alertStartDate, alertSndDate, alertStartInput, alertEndInput })
                                            }
                                        />
                                    : (this.state.activeParentTab === 'Observe' && this.state.activeTopTab === 'Assets New') ? 
                                        <AssetDetailPage />
                                    : (this.state.activeParentTab === 'Observe' && this.state.activeTopTab === 'Assets') ?
                                        <AssetsLeftSection
                                            caseId = {this.state.caseId}
                                            caseRecord = {this.props.caseDetails}
                                        />
                                    : (this.state.activeParentTab === 'Observe' && this.state.activeTopTab === 'Topology') ?
                                        <TopologyLeftSection
                                            caseId = {this.state.caseId}
                                            caseRecord = {this.props.caseDetails}
                                            fullScreenOption = {(fullScreen) => 
                                                this.setState({ fullScreen })
                                            }
                                        />
                                    : (this.state.activeParentTab === 'Observe' && this.state.activeTopTab === 'Events') ?
                                        <EventsLeftSection
                                            caseId = {this.state.caseId}
                                            caseRecord = {this.props.caseDetails}
                                        />
                                    : null
                                    }
                                </div>
                            </div>
                            <div className={`${this.state.activeParentTab === 'Observe' && this.state.activeTopTab === 'Topology' 
                                ? (!this.state.fullScreen ? 'col-md-5' : 'col-md-12')
                                : this.state.activeParentTab === 'Observe' && this.state.activeTopTab === 'Assets New' 
                                ? 'col-md-5'
                                : (this.state.activeParentTab === 'Remediation' ? 'col-md-5' : 'col-md-6')}`}
                            >
                                {this.state.activeParentTab === 'Summary' ?
                                    <SummaryRightSection
                                        caseId = {this.state.caseId}
                                        caseRecord = {this.props.caseDetails}
                                    /> 
                                : this.state.activeParentTab === 'Remediation' ?
                                    <RemediationRightSection
                                        caseId = {this.state.caseId}
                                    />
                                : (this.state.activeParentTab === 'Analysis' && this.state.activeTopTab === 'Root Cause') ?
                                    <RootCauseRightSection 
                                        caseId = {this.state.caseId}
                                    />                                
                                : (this.state.activeParentTab === 'Analysis' && this.state.activeTopTab === 'Diagnostics') ?
                                    <DiagnosticsRightSection 
                                        caseId = {this.state.caseId}
                                    />                                
                                : this.state.activeTopTab === 'Alert' ?
                                    <AlertRightSection
                                        caseId = {this.state.caseId}
                                        caseRecord = {this.props.caseDetails}
                                        startDate = {this.state.startDate ? this.state.startDate : ''}
                                        endDate = {this.state.endDate ? this.state.endDate : ''}
                                        metricsStartInput = {this.state.metricsStartInput ? this.state.metricsStartInput : ''}
                                        metricsEndInput = {this.state.metricsEndInput ? this.state.metricsEndInput : ''}
                                    />
                                : this.state.activeTopTab === 'Anomalies' ?
                                    <AnomaliesRightSection
                                        caseId = {this.state.caseId}
                                        caseRecord = {this.props.caseDetails}
                                        startDate = {this.state.startDate ? this.state.startDate : ''}
                                        endDate = {this.state.endDate ? this.state.endDate : ''}
                                    />
                                : this.state.activeTopTab === 'Errors' ?
                                    <ErrorsRightSection
                                        caseId = {this.state.caseId}
                                        caseRecord = {this.props.caseDetails}
                                        startDate = {this.state.startDate ? this.state.startDate : ''}
                                        endDate = {this.state.endDate ? this.state.endDate : ''}
                                    />
                                : this.state.activeTopTab === 'Assets New' ?
                                    <AssetDetailPageRight />
                                : this.state.activeTopTab === 'Assets' ?
                                    <AssetsRightSection
                                        caseId = {this.state.caseId}
                                    />
                                : this.state.activeTopTab === 'Topology' ?
                                    <TopologyRightSection
                                        caseId = {this.state.caseId}
                                        caseRecord = {this.props.caseDetails}
                                    />
                                : this.state.activeTopTab === 'Events' ?
                                    <EventsRightSection
                                        caseId = {this.state.caseId}
                                        caseRecord = {this.props.caseDetails}
                                    />
                                : null}
                            </div>
                        </div>
                    </div>
                </div>
            :
                // <Redirect to={URL_PATH.AIOPS_DASHBOARD} />
                null
		)
	}
}

/**
 * Type of the props used in the component
 */
CaseDetails.propTypes = {
    listAllRegions: PropTypes.func,
    getCaseDetails: PropTypes.func,
    updateCaseDetails: PropTypes.func,
    setAiopsPropsDetails: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('-',state)
	return {
		regions: state.filters.regions,
        aiopsPropsDetails: state.aiops.aiopsPropsDetails ? state.aiops.aiopsPropsDetails : {},
        caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : {},
        // alertsList: state.aiops.caseDetails.results && state.aiops.caseDetails.results.alerts ? state.aiops.caseDetails.results.alerts : [],
        // assetsList: state.aiops.caseDetails.results && state.aiops.caseDetails.results.assets ? state.aiops.caseDetails.results.assets : [],
	}
}

export default AppWrapper(CaseDetails, mapStateToProps, {
    listAllRegions,
    getCaseDetails,
    updateCaseDetails,
    setAiopsPropsDetails,
})