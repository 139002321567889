/*************************************************
 * Tvastar
 * @exports
 * @file EventAction.js
 * @author parakash // on 20/09/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'

import { ACTION_TYPES } from '../types'

import {
	AIOPS_SERVICE,
	AIOPS_EVENT_GET_EVENTS_TOTAL_COUNT,
	AIOPS_EVENT_GET_IMPORTANT_EVENTS_DAILY_TREND,
	
    LIST_AIOPS_EVENTS,
	GET_EVENTS_DAILY_TREND,
	GET_EVENTS_DETAILS,
	LIST_EVENTS_BY_ASSETS

} from '../../config'

/**
 * Action to get events total count
 * @param {Object} body
 * @param {Function} callback
 */
export const getEventsTotalCount = (body, callback) => {
	return async () => {
		await API.post(AIOPS_SERVICE, AIOPS_EVENT_GET_EVENTS_TOTAL_COUNT, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to get important events daily trend
 * @param {Object} body
 * @param {Function} callback
 */
export const getImportantEventsDailyTrend = (body, callback) => {
	return async () => {
		await API.post(AIOPS_SERVICE, AIOPS_EVENT_GET_IMPORTANT_EVENTS_DAILY_TREND, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to get AIOPS Events 16 Mar 2021
 * @param {Object} body
 * @param {Function} callback
 */
 export const listEvents = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(AIOPS_SERVICE, LIST_AIOPS_EVENTS, { body })
			// console.log(result);
			callback(true, result)
		} catch(err) {
			// let message = ''
			callback(false, {})
		}
    }
}

/**
 * Action to get AIOPS get events daily trend 16 Mar 2021
 * @param {Object} body
 * @param {Function} callback
 */
export const getEventDetails = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(AIOPS_SERVICE, GET_EVENTS_DETAILS, { body })
			// console.log('getEventDetails', result);
			
			if(response.status) {
				let formatedResult = response.results && response.results.length ? response.results[0] : {}
				if(body.event_source) {
					formatedResult.event_source = body.event_source
				}
				dispatch({
					type: ACTION_TYPES.SET_AIOPS_EVENT_DETAILS,
					payload: formatedResult,
				})
			} else {
				dispatch({
					type: ACTION_TYPES.SET_AIOPS_EVENT_DETAILS,
					payload: {},
				})
			}
			callback(true, response)
		} catch(err) {
			// let message = ''
			dispatch({
				type: ACTION_TYPES.SET_AIOPS_EVENT_DETAILS,
				payload: {},
			})
			callback(false, {})
		}
    }
}


/**
 * Action to get AIOPS get events daily trend 16 Mar 2021
 * @param {Object} body
 * @param {Function} callback
 */
export const getEventsDailyTrend = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(AIOPS_SERVICE, GET_EVENTS_DAILY_TREND, { body })
			// console.log(result);
			callback(true, result)
		} catch(err) {
			// let message = ''
			callback(false, {})
		}
    }
}

/**
 * Action to get Alerts by type Alert
 * @param {Object} body
 * @param {Function} callback
 */
export const listEventsByAssets = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(AIOPS_SERVICE, LIST_EVENTS_BY_ASSETS, { body })
			// console.log('getEventDetails', result);
			// if(result.status) {
			// 	dispatch({
			// 		type: ACTION_TYPES.SET_AIOPS_EVENT_BY_ASSETS,
			// 		payload: result,
			// 	})
			// } else {
			// 	dispatch({
			// 		type: ACTION_TYPES.SET_AIOPS_EVENT_BY_ASSETS,
			// 		payload: {},
			// 	})
			// }
			callback(true, result)
		} catch(err) {
			// let message = ''
			// dispatch({
			// 	type: ACTION_TYPES.SET_AIOPS_EVENT_BY_ASSETS,
			// 	payload: {},
			// })
			callback(false, {})
		}
	}
}