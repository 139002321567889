/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file EditResource.js
 * @author Rajasekar // on 14/03/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { listAllRegions, listAllAccounts } from '../../actions/commonAction'
import { connect } from 'react-redux'
import { setEditRuleDetails, getAllTags } from '../../actions/governance/governanceAction'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import _ from 'lodash'
import { currentUTCtime, getRegionName, getAccountNameFromId } from '../../utils/utility'
import { Link } from 'react-router-dom'
import { URL_PATH } from '../../config/urlPath'

class EditResource extends Component {
	constructor(props) {
		super(props)
		this.scrollRef = React.createRef()
		this.accountRef = React.createRef()
		this.regionRef = React.createRef()
		this.tagRef = React.createRef()

		this.state = {			
			startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
			perPage: 3,

			hasError: this.props.hasError,
			accounts: [],
			regions: [],
			showModal: false,
			showLoading: false,

			selectedAccount: [],
			isAccountOpen: false,
			
			selectedRegion: [],
			isRegionOpen: false,

			tagsSelected: [],
			tagsSelectedArray: [],
			isTagOpen: false,

			newRuleTags: [{ key: '', value: '' }],
			resources: [],
			isEditResource: false,
			selectedTags: [],
			editIndex: 0,

			searchText: '',
		}
	}
	componentDidMount = () => {
		this.setState({ 
			selectedAccount: [], 
			selectedRegion: [],
			tagsSelected: [],
			// tagsSelectedArray: [{label:'All', value:'All'}],	
		})
		if (this.props.editRuleDetails.resources && this.props.editRuleDetails.resources.length) {
			this.setState({
				resources: this.props.editRuleDetails.resources,
				savedResources: this.props.editRuleDetails.resources,
				filterSavedData: this.props.editRuleDetails.resources,
				selectedTags: this.props.editRuleDetails.resources.tags ? this.props.editRuleDetails.resources.tags : [],
			})
		}
		this.getAllFilters()
	}
	
	componentDidUpdate = () => {
		if(this.props.hasError !== this.state.hasError) {
			this.setState({ hasError: this.props.hasError })
		}
	}
	/**
	 * Filter identifiers based on the filter selection
	 */
	getAllFilters = () => {
		let params = {
			provider: this.props.editRuleDetails.provider ? this.props.editRuleDetails.provider.toLowerCase() : '',
		}
		if(this.props.accounts && this.props.accounts.length) {
			let allAccounts = []
			if(this.props.accounts.length) {
				let accRow = {}
				accRow.account_id = "All"
				accRow.account_name = "All"
				accRow.date = null
				accRow.disabled = false
				accRow.provider = "All"
				allAccounts.unshift(accRow);
			}
			this.setState({ accounts: this.props.accounts, allAccounts })
		} else {
			this.props.listAllAccounts(params, (promise, allAccounts) => {
				if (promise) {
					if(allAccounts.length) {
						let accRow = {}
						accRow.account_id = "All"
						accRow.account_name = "All"
						accRow.date = null
						accRow.disabled = false
						accRow.provider = "All"
						allAccounts.unshift(accRow);
					}
					this.setState({ allAccounts, accounts: allAccounts })
				}
			})
		}
		if(this.props.regions && this.props.regions.length) {
			let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
			this.setState({ regions: providerRegions })
		} else {
			this.props.listAllRegions(params, (promise, regions) => {
				if (promise) {
					let providerRegions = regions && regions[0] && regions[0].provider_regions
					if(providerRegions) {
						let provider_regions_row = {}
						provider_regions_row.availability_zones = []
						provider_regions_row.city = "All"
						provider_regions_row.country = "All"
						provider_regions_row.geography = "All"
						provider_regions_row.name = "All"
						provider_regions_row.region = "All"
						provider_regions_row.region_id = "All"
						provider_regions_row.state = "All"
						providerRegions.unshift(provider_regions_row);
					}
					this.setState({ regions: providerRegions })
				} else {
					this.setState({ regions: [] })
				}
			})
		}

		this.getAllTags()
	}

	getAllTags = () => {
		let params = {}
		params.provider = this.props.editRuleDetails.provider ? this.props.editRuleDetails.provider.toLowerCase : ''
		if (this.state.selectedAccount.length && !this.state.selectedAccount.include('All')) {
			params.account_id = this.state.selectedAccount
		}
		if (this.state.selectedRegion.length && !this.state.selectedRegion.include('All')) {
			params.region = this.state.selectedRegion
		}
		this.props.getAllTags(params)
	}

	getMultiSelectedCount = (type, array) => {
		if(array) {
			return array.length && array.includes('All') ? 'All' :  array.length ? array.length +' Selected' : 'All'
		} else {
			return 'All'
		}
	}

	handleMultiSelectChange = (field, arrayValue) => {
		let value = arrayValue.map(item => item.value)
		// let onlyLabel = arrayValue.map(item => item.label)
		// let label = arrayValue.map(item => item)
		let selectedValue = []
		if(field === 'selectedAccount') {			
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.allAccounts.forEach(acc => {
						selectedValue.push(acc.account_id)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(selectedValue.length === (this.state.accounts.length -1)) {
					selectedValue.push('All')
				}
			}

			this.setState({ [field]: selectedValue })
		} else if(field === 'selectedRegion') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.regions.length -1)) {
					selectedValue.push('All')
				}
			}
			
			this.setState({ [field]: selectedValue })
		} else if(field === 'tagsSelected') {
			let selectedValu = []
			let prevState = this.state[field]
			let currentState = arrayValue

			prevState = prevState.filter(obj => obj.value === 'All')
			currentState = arrayValue.filter(obj => obj.value === 'All')
			if(currentState.length) {
				if(prevState.length) {
					arrayValue = arrayValue.filter(obj => obj.value !== 'All')
				}
				selectedValu = []
				arrayValue.forEach(item => {
					this.props.tags.forEach(({ tags }) => {
						if(tags.value === item.tagValue) {
							let dataRow = {}
							dataRow.value = tags.key+' '+tags.value
							dataRow.tagKey = tags.key
							dataRow.tagValue = tags.value
							dataRow.label = tags.key+' '+tags.value
							selectedValu.push(dataRow)
						}
					})
				})
			} else if(!prevState.length && !currentState.length) {
				selectedValu = arrayValue
			}
			this.setState({ [field]: selectedValu })
		}
	}
	
	handleClickOutside(event) {	
		if (this.accountRef && !this.accountRef.current.contains(event.target)) {
			this.setState({ isAccountOpen: false })
		} else {
			this.setState({ isAccountOpen: true })
		}
		
		if (this.regionRef && !this.regionRef.current.contains(event.target)) {
			this.setState({ isRegionOpen: false })
		} else {
			this.setState({ isRegionOpen: true })
		}
		
		if (this.tagRef && !this.tagRef.current.contains(event.target)) {
			this.setState({ isTagOpen: false })
		} else {
			this.setState({ isTagOpen: true })
		}
	
	}

	removeSelectedOption = (field, index, option) => {
		let filteredResult = this.state[field]
		if(field === 'selectedAccount' || field === 'selectedRegion') {
			filteredResult = this.state[field].filter(e => e !== option)
		} else if(field === 'tagsSelected') {
			filteredResult = this.state[field].filter(e => e.value !== option.value)
		}
		this.setState({ [field]: filteredResult })		
	}
	
	addUpdateSavedData = (type) => {
		//let condition = this.props.editRuleDetails.resourcesData ? this.props.editRuleDetails.resourcesData : {}
		let savedResources = this.props.editRuleDetails.resources ? this.props.editRuleDetails.resources : []
		let hasErrorInRuleForm = false
		let hasErrorAlreadyExist = false;
		// if(condition['option'] === '') {
		// 	hasErrorInRuleForm = true
		// }

		let tagLabel = []
		if(this.state.tagsSelected && !this.state.tagsSelected.filter(arr => arr.value !== 'All').lenght) {
			this.state.tagsSelected.forEach(item => {
				let dataRow = {}
				dataRow.key = item.tagKey
				dataRow.value = item.tagValue
				tagLabel.push(dataRow)
			})
		}

		savedResources.forEach( item => {
			let sameAccount = false
			let sameRegion = false
			let sameTag = false

			let accArray1 = item.account
			let accArray2 = !this.state.selectedAccount.length ? ['All'] : (this.state.selectedAccount.length > 1 ? this.state.selectedAccount.filter(arr => arr !== 'All') : this.state.selectedAccount)
			var is_same_account = accArray1.length === accArray2.length && accArray1.every(function(element, index) {
				return element === accArray2[index]; 
			});
			sameAccount = is_same_account

			if(sameAccount) {
				let regArray1 = item.region
				let regArray2 = !this.state.selectedRegion.length ? ['All'] : (this.state.selectedRegion.length > 1 ? this.state.selectedRegion.filter(arr => arr !== 'All') : this.state.selectedRegion)
				var is_same_region = regArray1.length === regArray2.length && regArray1.every(function(element, index) {
					return element === regArray2[index]; 
				});
				sameRegion = is_same_region
				
				let tagArray1 = item.tags ? item.tags : []
				let tagArray2 = tagLabel
				if(sameRegion && tagArray1.length === tagArray2.length) {
					let tagExist = 0
					if(!tagArray1.length) {
						sameTag = true
						tagExist = 1
					} else {
						tagArray1.forEach(item1 => {
							tagArray2.forEach(item2 => {
								let exist = _.isEqual(item1, item2);
								if(exist) {
									tagExist += 1
								}
							})
						})
					}

					if(tagArray1.length === tagExist) {
						sameTag = true
					}
				}
			}
			if(sameAccount && sameRegion && sameTag) {
				hasErrorAlreadyExist = true
			}
			
		})

		this.setState({ hasErrorInRuleForm, hasErrorAlreadyExist })
		if(!hasErrorInRuleForm && !hasErrorAlreadyExist) {
			let saveData = {}
			saveData['account'] = !this.state.selectedAccount.length ? ['All'] : (this.state.selectedAccount.length > 1 ? this.state.selectedAccount.filter(arr => arr !== 'All') : this.state.selectedAccount)
			// saveData['accountLabel'] = accountLabel
			saveData['region'] = !this.state.selectedRegion.length ? ['All'] : (this.state.selectedRegion.length > 1 ? this.state.selectedRegion.filter(arr => arr !== 'All') : this.state.selectedRegion)
			// saveData['regionLabel'] = regionLabel
			if(tagLabel.length) {
				saveData['tags'] = tagLabel
			}

			saveData['timestamp'] = currentUTCtime()

			if(type === 'Add') {
				saveData['rowIndex'] = savedResources.length
				savedResources.push(saveData)
			} else {				
				saveData['rowIndex'] = this.state.rowIndex
				savedResources[this.state.rowIndex] = saveData
			}
			this.setState({
				savedResources
			}, () => this.searchSavedData())			
			
			this.props.setEditRuleDetails('resources', savedResources)
			this.setState({ 
				savedResources,
				editEnabled: false,
				selectedAccount: [],
				selectedRegion: [],
				tagsSelected: [],
				searchText: ''
			})
		}
	}

	actionOnSavedData = (action, index) => {
		if(action === 'edit') {
			this.scrollRef.current.scrollIntoView({ behavior: 'smooth' })
			let editCondtion = {}
			editCondtion['account'] = this.props.editRuleDetails.resources[index].account ? this.props.editRuleDetails.resources[index].account : []
			editCondtion['region'] = this.props.editRuleDetails.resources[index].region ? this.props.editRuleDetails.resources[index].region : []
			editCondtion['tags'] = this.props.editRuleDetails.resources[index].tags ? this.props.editRuleDetails.resources[index].tags : []
			// editCondtion['accountLabel'] = this.props.editRuleDetails.resources[index].accountLabel ? this.props.editRuleDetails.resources[index].accountLabel : []
			// editCondtion['regionLabel'] = this.props.editRuleDetails.resources[index].regionLabel ? this.props.editRuleDetails.resources[index].regionLabel : []
			editCondtion['rowIndex'] = index
			// editCondtion['savedResources'] = this.props.editRuleDetails.resources ? this.props.editRuleDetails.resources : []
			let tags = []
			if(editCondtion['tags'].length) {
				editCondtion['tags'].forEach(tag => {
					let dataRow = {}
					dataRow.tagKey = tag.key
					dataRow.tagValue = tag.value
					dataRow.label = tag.key+ ' : ' + tag.value
					dataRow.value = tag.key+ ' : ' + tag.value
					tags.push(dataRow)
				})
			}
			this.setState({ 
				editEnabled: true,
				rowIndex: index,
				selectedAccount: editCondtion['account'],
				selectedRegion: editCondtion['region'],
				tagsSelected: tags,
			})
		} else {
			let savedResources = this.state.savedResources.filter((item) => item.rowIndex !== index);
			
			this.props.setEditRuleDetails('resources', savedResources)

			this.setState({ savedResources, filterSavedData: savedResources, searchText: '' })
		}
	}
	
	searchSavedData = () => {
		let filterSavedData = this.state.savedResources
		if (this.state.searchText !== '') {
			filterSavedData =
				filterSavedData &&
				filterSavedData.filter(item => {
					let isPresent = []
					isPresent = this.recursiveSearch(item, this.state.searchText)
						.flat()
						.filter(bool => bool === true)
					if (isPresent[0]) {
						return true
					} else {
						return false
					}
				})
		}
		
		let totalPages = 1
		if(filterSavedData.length > this.state.perPage) {
			totalPages = Math.ceil(filterSavedData.length / this.state.perPage)
		}
		this.setState({ filterSavedData, totalPages, startRecord: 0, currentPage: 1 })
	}
	
	recursiveSearch = (item, searchValue) => {
		return Object.keys(item).map(key => {
			if (item[key] !== null && typeof item[key] === 'object') {
				return this.recursiveSearch(item[key], searchValue)
				// eslint-disable-next-line valid-typeof
			} else if (typeof item[key] === 'array') {
				return item.map((arrayElement, i) => this.recursiveSearch(arrayElement[i], searchValue))
			} else {
				// if(this.props.searchFilter.includes(key)) {
				return item[key] !== null && item[key] !== undefined
					? item[key]
							.toString()
							.toLowerCase()
							.indexOf(searchValue.toString().toLowerCase()) > -1
					: false
				// }else{
				// return false;
				// }
			}
		})
	}
	
	navigatePage = (action, currentPage) => {
		let startRecord = this.state.startRecord
		if(action === 'next' && currentPage !== this.state.totalPages) {
			startRecord = startRecord + this.state.perPage
			currentPage = currentPage + 1
		} else if(action === 'previous' && currentPage !== 0) {
			currentPage = currentPage - 1
			startRecord = startRecord - this.state.perPage
		} else if(action === 'start' && currentPage !== 0) {
			currentPage = 1
			startRecord = 0
		} else if(action === 'end' && currentPage !== 0) {
			currentPage = this.state.totalPages
			startRecord = (this.state.totalPages - 1) * this.state.perPage
		}
		this.setState({ currentPage, startRecord })
	}

	clearForm = () => {
		this.setState({ 
			selectedAccount: ['All'],
			selectedRegion: ['All'], 
			tagsSelected: []
		})
	}

	render() {
		// const { showModal } = this.state
		return (

			<div ref={this.scrollRef} onClick={ (event) => { this.handleClickOutside(event) } }>
				<h4 className="text-white">Resource</h4>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
					magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
					consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
					pariatur.
				</p>
				<div className='row'>
					<div className="col-lg-4 border-right zapInputDark">
						<div className="rounded bg-dark p-3 h-100">
							<div className='form-group'>
								<div className="d-flex">
									<label className="align-self-center m-0 mr-3 text-right w-10">Account </label>
									<div className={`multiselectDarkTheme align-self-center mr-2 z999 w-50 ${this.state.accounts && this.state.accounts.length > 10 ? '' : 'removeDropdownSearchBar'}`} ref={this.accountRef}>
										<ReactMultiSelectCheckboxes						
											placeholderButtonLabel="All"
											getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedAccount', this.state.selectedAccount)}
											// menuIsOpen ={this.state.isAccountOpen}
											value={this.state.selectedAccount.map(acc => ({
												value: acc
											}))}
											onChange={arr => {
											this.handleMultiSelectChange('selectedAccount', arr ? arr : []) }}
											options={this.state.accounts.map(account => ({
												value: account.account_id,
												label: account.account_name,
											}))}
										/>
									</div>
								</div>
								<div className="d-flex">
									<label className="mr-3 w-10">&emsp;</label>
									<div className="flex-grow align-self-center">
										{this.state.selectedAccount.length ? this.state.selectedAccount.map((item, index) => {
											return(
												item !== 'All' ?
													<span key={index} className="badge badge-light f14 mr-1 mb-1 mt-1" >
														{getAccountNameFromId(item, this.state.accounts)}
														<i onClick={ () => this.removeSelectedOption('selectedAccount', index, item) } className='ml-1 fal fa-times cursorPointer'></i>
													</span>
												: null
											)
										})
										: null
										}
									</div>
								</div>
							</div>
							<div className='form-group'>
								<div className="d-flex">
									<label className="align-self-center m-0 mr-3 text-right w-10">Region </label>
									<div className={`multiselectDarkTheme align-self-center mr-2 z998 w-50 ${this.state.regions && this.state.regions.length > 10 ? '' : 'removeDropdownSearchBar' } `} ref={this.regionRef}>
										<ReactMultiSelectCheckboxes						
											placeholderButtonLabel="All"
											getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedRegion', this.state.selectedRegion)}
											// menuIsOpen ={this.state.isRegionOpen}										
											value={this.state.selectedRegion.map(reg => ({
												value: reg,
											}))}
											onChange={arr => {
											this.handleMultiSelectChange('selectedRegion', arr ? arr : []) }}
											options={this.state.regions && this.state.regions.map(region => ({
												value: region.region,
												label: region.name,
											}))}
										/>
									</div>
								</div>
								<div className="d-flex">
									<label className="mr-3 w-10">&emsp;</label>
									<div className="flex-grow align-self-center">
										{this.state.selectedRegion.length ? this.state.selectedRegion.map((item, index) => {
											return(
												item !== 'All' ?
													<span key={index} className="badge badge-light f14 mr-1 mb-1 mt-1" >
														{getRegionName(item, this.state.regions)}
														<i onClick={ () => this.removeSelectedOption('selectedRegion', index, item) } className='ml-1 fal fa-times cursorPointer'></i>
													</span>
												:
												null
											)
										})
										: null
										}
									</div>
								</div>
							</div>
							<div className='form-group'>
								<div className="d-flex">
									<label className="align-self-center m-0 mr-3 text-right w-10">Tags </label>
									<div className={`multiselectDarkTheme align-self-center z997 mr-3 w-60`} ref={this.tagRef}>
										<ReactMultiSelectCheckboxes						
											placeholderButtonLabel="All"
											getDropdownButtonLabel={() => this.getMultiSelectedCount('tagsSelected', this.state.tagsSelected)}
											// menuIsOpen ={this.state.isTagOpen}
											value={this.state.tagsSelected.map(item => ({
												value: item.value,
												label: item.value,
												tagKey: item.tagKey,
												tagValue: item.tagValue,
											}))}
											onChange={arr => {
											this.handleMultiSelectChange('tagsSelected', arr ? arr : []) }}
											options={this.props.tags.map(({ tags }) => ({
												value: tags.key + ' : ' + tags.value,
												tagKey: tags.key,
												tagValue: tags.value,
												label: tags.key + ' : ' + tags.value,
											}))}
										/>
									</div>
								</div>
								<div className="d-flex">
									<label className="mr-3 w-10">&emsp;</label>
									<div className="flex-grow align-self-center">
										{this.state.tagsSelected.length ? this.state.tagsSelected.map((item, index) => {
											return(
												<span key={index} className="badge badge-light f14 mr-1 mb-1 mt-1" >
													{item.tagKey+' : '+item.tagValue}
													<i onClick={ () => this.removeSelectedOption('tagsSelected', index, item) } className='ml-1 fal fa-times cursorPointer'></i>
												</span>
											)
										})
										: null
										}
									</div>
								</div>
							</div>
							
							<button className='btn btn-sm btn-info align-self-center mr-3' onClick={() => this.addUpdateSavedData(this.state.editEnabled ? 'Update' : 'Add')}>
								{this.state.editEnabled ? 'Update' : 'Add'}
							</button>
							<button className='btn btn-sm btn-light align-self-center' onClick={() => this.clearForm()}>
								Reset
							</button>
							{this.state.hasErrorAlreadyExist ?
								<span className='ml-3 text-danger align-self-end m-0 p-0'>Record already exist</span>
							: null }
						</div>
					</div>
					<div className="col-lg-8">
						<div className="rounded bg-dark p-3 h-100">
							<div className="d-flex justify-content-between mb-2">
								<div className="d-flex align-self-end ">
									<h6 className="text-info m-0 p-0">Added Resource</h6>
									{this.state.hasError && (!this.props.editRuleDetails.resources || !this.props.editRuleDetails.resources.length) ? 
										<small className='ml-3 text-danger align-self-end'>Please add resource.</small>
									: null}
								</div>
								
								<input
									type='text'
									value={this.state.searchText}
									onChange={e => {
										this.setState({ searchText: e.target.value }, () => this.searchSavedData())
									}}
									className='form-control form-control-sm w-20 bg-muted border-gray5 text-white'
									placeholder='Search'
								/>
							</div>
							{this.state.filterSavedData && this.state.filterSavedData.length ?
								<React.Fragment>
									{this.state.filterSavedData.length && this.state.filterSavedData.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map(item => {
										return (
										<div className="p-3 bg-dark3 rounded mb-3">
											<div className="row">
												<div className="col-lg-3 col-sm-4 align-self-center">
													<label className="small mb-1 d-block">Account</label>
													{item.account.map((acc, i) => {
														return(
														<React.Fragment>
															<div className="d-flex">
																<label className={`badge badge-outline-light mr-1 mb-1`}>{getAccountNameFromId(acc, this.state.accounts)}</label>
															</div>
														</React.Fragment>
														)
													})}
												</div>											
												<div className="col-lg-3 col-sm-4 align-self-center">
													<label className="small mb-1 d-block"><i className={`far fa-location align-self-center mr-1`}></i>Region</label>
													{item.region && item.region.map((reg, i) => {
														return(
															<label className={`badge badge-outline-light ml-1 mb-1`}>{getRegionName(reg, this.state.regions)}</label>
														)
													})}
												</div>
												<div className="col-lg-4 col-sm-4 align-self-center">
													<label className="small mb-1 d-block"><i className={`far fa-tag align-self-center mr-1`}></i>Tag</label>
													{item.tags && item.tags.length ? 
														item.tags.map((tag, i) => {
															return (
																<label key={i} className="badge badge-outline-light ml-1 mb-1">{tag.key+' : '+tag.value}</label>
															)
														})
													:
														<label className="badge badge-outline-light ml-1">All</label>
													}
												</div>
												<div className="col-lg-2 col-sm-2 align-self-center">
													<button className="btn btn-outline-primary btn-sm border-0" onClick={() => this.actionOnSavedData('edit', item.rowIndex)}>Edit</button>
													<button className="btn btn-outline-danger btn-sm ml-3 border-0" onClick={() => this.actionOnSavedData('remove', item.rowIndex)}>Delete</button>
												</div>
											</div>
										</div>
										)
									})}	
									{this.state.filterSavedData.length > this.state.perPage ?
										<div className="pagination mt-3">
											<span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
											<button disabled=""  onClick={() => this.navigatePage('start', this.state.currentPage)}><i className="fal fa-arrow-to-left"></i></button> 
											<button disabled="" onClick={() => this.navigatePage('previous', this.state.currentPage)}><i className="fal fa-angle-left"></i></button> 
											<button><i className="fal fa-angle-right" onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
											<button><i className="fal fa-arrow-to-right" onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
										</div>
									: null
									}			
								</React.Fragment>
							:
								this.state.savedResources && !this.state.savedResources.length ?
									<div className='d-flex justify-content-center m-4'>
										<p>There are no data on this resource. Try add some resource.</p>
									</div>
								:
									<div className='d-flex justify-content-center m-4'>
										<p>There are no data on this criteria. Please try adjusting your search.</p>
									</div>						
							}
						</div>
					</div>
				</div>
				<div className="border-top pt-2 mt-3">
					<button className='btn btn-light mr-1' onClick={() => this.props.onClickBack()}>Back</button>
					<button className='btn btn-primary' onClick={() => {this.props.onClickNext()}}>Next</button>
					<Link className='float-right btn btn-light text-muted text-decoration-none ml-1' to={URL_PATH.GOVERNANCE_RULES} >
						Cancel
					</Link>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
EditResource.propTypes = {
	onClickNext: PropTypes.func,
	onClickBack: PropTypes.func,
	listAllAccounts: PropTypes.func,
	setEditRuleDetails: PropTypes.func,
	getAllTags: PropTypes.func,
	listAllRegions: PropTypes.func,
	ruleDetails: PropTypes.object,
	editRuleDetails: PropTypes.object,
	tags: PropTypes.array,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('resource', state)
	return {
		editRuleDetails: state.governance.editRuleDetails,
		tags: state.governance.tags,
		accounts: state.filters.accounts,
		regions: state.filters.regions,
	}
}
export default connect(mapStateToProps, {
	listAllAccounts,
	listAllRegions,
	setEditRuleDetails,
	getAllTags,
})(EditResource)
