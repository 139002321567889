/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from 'react'
import { setEditRuleDetails } from '../../../actions/governance/governanceAction'
import { apiToCall } from '../../../actions/commonAction'
import { onlyNumeric } from '../../../utils/utility'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { Input } from 'reactstrap'

const encryptOptions = [
	{label: 'Default', value: 'Default'},
	{label: 'AWS Managed Kms', value: 'AWS Managed Kms'},
	{label: 'Customer Managed Kms', value: 'Customer Managed Kms'}
]

const keyTypeOptions = [
	{label: 'Sym', value: 'Sym'},
	{label: 'Asym', value: 'Asym'}
]

const keyUsageOptions = [
	{label: 'Encrypt and Decrypt', value: 'Encrypt and Decrypt'},
	{label: 'Sign and Verify', value: 'Sig and Verify'}
]

const keySpecOptions = [
	{label: 'RSA_2048', value: 'RSA_2048'},
	{label: 'RSA_3072', value: 'RSA_3072'},
	{label: 'RSA_4096', value: 'RSA_4096'},
	{label: 'ECC_NIST_P256', value: 'ECC_NIST_P256'},
	{label: 'ECC_NIST_P384', value: 'ECC_NIST_P384'},
	{label: 'ECC_NIST_P521', value: 'ECC_NIST_P521'},
	{label: 'ECC_SECG_P256K1', value: 'ECC_SECG_P256K1'},
]

class ServiceEncryption extends Component {
	constructor(props) {
		super(props)
		this.encyOptionRef = React.createRef()
		this.keyTypeRef = React.createRef()
		this.keyUsageRef = React.createRef()
		this.keySpecRef = React.createRef()
		
		this.state = {
			hasErrorInRuleForm: false,

			isEncyOptionOpen: false,
			isKeyTypeOpen: false,
			isKeyUsageOpen: false,
			isKeySpecOpen: false,

			encryptOptions: [],
			encryptOptionsArray: [],
			keyTypeOptions: [],
			keyTypeOptionsArray: [],
			keyUsageOptions: [],
			keyUsageOptionsArray: [],
			keySpecOptions: [],
			keySpecOptionsArray: [],
		}
	}

	componentDidMount = () => {		
		
		let encryptionAtRestState = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.encryptionAtRest ? true : false
		let encryptionAtTransitState = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.encryptionAtTransit ? true : false
		let encryptOptionsState = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.encryptOptions ? this.props.editRuleDetails.condition.encryptOptions : []
		if(this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.encryptOptions && this.props.editRuleDetails.condition.encryptOptions.length) {
			let optionTypeArray = []
			this.props.editRuleDetails.condition.encryptOptions.forEach(item => {
				if (encryptOptions.filter(e => e.value === item).length > 0) {
					let array = encryptOptions.filter(e => e.value === item)
					optionTypeArray.push(array[0])
				}
			})
			this.setState({ encryptOptionsArray: optionTypeArray })
		}
		let crossAccountAccessforCusmtomerMgmtKMSState = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.crossAccountAccessforCusmtomerMgmtKMS ? true : false
		let crossAccountKMSEncryptionState = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.crossAccountKMSEncryption ? true : false
		let customerManagedKMSState = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.customerManagedKMS ? this.props.editRuleDetails.condition.customerManagedKMS : ''
		let keyTypeOptionsState = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.keyTypeOptions ? this.props.editRuleDetails.condition.keyTypeOptions : []

		if(this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.keyTypeOptions && this.props.editRuleDetails.condition.keyTypeOptions.length) {
			let optionTypeArray = []
			this.props.editRuleDetails.condition.keyTypeOptions.forEach(item => {
				if (keyTypeOptions.filter(e => e.value === item).length > 0) {
					let array = keyTypeOptions.filter(e => e.value === item)
					optionTypeArray.push(array[0])
				}
			})
			this.setState({ keyTypeOptionsArray: optionTypeArray })
		}
		let keyUsageOptionsState = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.keyUsageOptions ? this.props.editRuleDetails.condition.keyUsageOptions : []

		if(this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.keyUsageOptions && this.props.editRuleDetails.condition.keyUsageOptions.length) {
			let optionTypeArray = []
			this.props.editRuleDetails.condition.keyUsageOptions.forEach(item => {
				if (keyUsageOptions.filter(e => e.value === item).length > 0) {
					let array = keyUsageOptions.filter(e => e.value === item)
					optionTypeArray.push(array[0])
				}
			})
			this.setState({ keyUsageOptionsArray: optionTypeArray })
		}

		let keySpecOptionsState = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.keySpecOptions ? this.props.editRuleDetails.condition.keySpecOptions : []
		if(this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.keySpecOptions && this.props.editRuleDetails.condition.keySpecOptions.length) {
			let optionTypeArray = []
			this.props.editRuleDetails.condition.keySpecOptions.forEach(item => {
				if (keySpecOptions.filter(e => e.value === item).length > 0) {
					let array = keySpecOptions.filter(e => e.value === item)
					optionTypeArray.push(array[0])
				}
			})
			this.setState({ keySpecOptionsArray: optionTypeArray })
		}

		this.setState({ 
			encryptionAtRest: encryptionAtRestState,
			encryptionAtTransit: encryptionAtTransitState,
			encryptOptions: encryptOptionsState,
			crossAccountAccessforCusmtomerMgmtKMS: crossAccountAccessforCusmtomerMgmtKMSState,
			crossAccountKMSEncryption: crossAccountKMSEncryptionState,
			customerManagedKMS: customerManagedKMSState,
			keyTypeOptions: keyTypeOptionsState,
			keyUsageOptions: keyUsageOptionsState,
			keySpecOptions:  keySpecOptionsState 
		})
		
		let condition = {}
		condition['encryptionAtRest'] = encryptionAtRestState
		condition['encryptionAtTransit'] = encryptionAtTransitState
		condition['encryptOptions'] = encryptOptionsState
		condition['crossAccountAccessforCusmtomerMgmtKMS'] = crossAccountAccessforCusmtomerMgmtKMSState
		condition['crossAccountKMSEncryption'] = crossAccountKMSEncryptionState
		condition['customerManagedKMS'] = customerManagedKMSState
		condition['keyTypeOptions'] = keyTypeOptionsState
		condition['keyUsageOptions'] = keyUsageOptionsState
		condition['keySpecOptions'] = keySpecOptionsState

		this.props.setEditRuleDetails('condition', condition)
	}

	handleClickOutside = (event) => {

		if (this.encyOptionRef && !this.encyOptionRef.current.contains(event.target)) {
			this.setState({ isEncyOptionOpen: false })
		} else {
			this.setState({ isEncyOptionOpen: true })
		}

		if (this.keyTypeRef && !this.keyTypeRef.current.contains(event.target)) {
			this.setState({ isKeyTypeOpen: false })
		} else {
			this.setState({ isKeyTypeOpen: true })
		}

		if (this.keyUsageRef && !this.keyUsageRef.current.contains(event.target)) {
			this.setState({ isKeyUsageOpen: false })
		} else {
			this.setState({ isKeyUsageOpen: true })
		}

		if (this.keySpecRef && !this.keySpecRef.current.contains(event.target)) {
			this.setState({ isKeySpecOpen: false })
		} else {
			this.setState({ isKeySpecOpen: true })
		}
	}

	handleMultiSelectChange = (childKey, arrayValue) => {
		let value = arrayValue.map(item => item.value)
		// let label = arrayValue.map(item => item)
		if(childKey === 'encryptOptions') {
			this.setState({ encryptOptions: value, encryptOptionsArray: arrayValue })
		} else if(childKey === 'keyTypeOptions') {
			this.setState({ keyTypeOptions: value, keyTypeOptionsArray: arrayValue })
		} else if(childKey === 'keyUsageOptions') {
			this.setState({ keyUsageOptions: value, keyUsageOptionsArray: arrayValue })
		} else if(childKey === 'keySpecOptions') {
			this.setState({ keySpecOptions: value, keySpecOptionsArray: arrayValue })
		}
		let condition = this.props.editRuleDetails.condition
		condition[childKey] = value
		this.props.setEditRuleDetails('condition', condition)
	}

	removeSelectedOption = (type, index, option) => {
		let details = ''
		let state = []
		let stateArray = []
		if(type === 'encryptOptions') {
			stateArray = this.state.encryptOptionsArray
			state = this.state.encryptOptions
			details = state.filter(e => e !== option.value)
		} else if(type === 'keyTypeOptions') {
			stateArray = this.state.keyTypeOptionsArray
			state = this.state.keyTypeOptions
			details = state.filter(e => e !== option.value)			
		} else if(type === 'keyUsageOptions') {
			stateArray = this.state.keyUsageOptionsArray
			state = this.state.keyUsageOptions
			details = state.filter(e => e !== option.value)			
		} else if(type === 'keySpecOptions') {
			stateArray = this.state.keySpecOptionsArray
			state = this.state.keySpecOptions
			details = state.filter(e => e !== option.value)			
		}
		let optionTypeArray = []
		if(details.length) {
			details.forEach(item => {
				if (stateArray.filter(e => e.value === item).length > 0) {
					let array = stateArray.filter(e => e.value === item)
					optionTypeArray.push(array[0])
				}
			})
		}
		
		if(type === 'encryptOptions') {
			this.setState({ encryptOptions: details, encryptOptionsArray: optionTypeArray })
		} else if(type === 'keyTypeOptions') {
			this.setState({ keyTypeOptions: details, keyTypeOptionsArray: optionTypeArray })
		} else if(type === 'keyUsageOptions') {
			this.setState({ keyUsageOptions: details, keyUsageOptionsArray: optionTypeArray })
		} else if(type === 'keySpecOptions') {
			this.setState({ keySpecOptions: details, keySpecOptionsArray: optionTypeArray })
		}

		let condition = this.props.editRuleDetails.condition
		condition[type] = details
		this.props.setEditRuleDetails('condition', condition)
		
		this.setState({ optionType: details, optionTypeArray })
	}


	handleSelectedInput = (input, value) => {
		let condition = this.props.editRuleDetails.condition
		condition[input] = value
		this.props.setEditRuleDetails('condition', condition)
		
	}
	
	render() {
		return (
			<div ref={this.scrollRef} onClick={ (event) => { this.handleClickOutside(event) } }> 
				<div className="row zapInputDark"> 
					<div className="col-lg-12 col-sm-12">
						<div className="row"> 
							<div className="col-lg-3 col-sm-4">
								<div className='form-group form-check form-check-inline'>
									<input
										type='checkbox'
										className='form-check-input'
										checked={this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.encryptionAtRest ? true : false}
										onChange={e => this.setState({ encryptionAtRest: this.state.encryptionAtRest ? false : true },
											() => this.handleSelectedInput('encryptionAtRest', this.state.encryptionAtRest) 
										)}
									/>
									<label className="form-check-label" for="latestVersion">Enforce Encryption at Rest</label>
								</div>
							</div>
							<div className="col-lg-3 col-sm-4">
								<div className='form-group form-check form-check-inline'>
									<input
										type='checkbox'
										className='form-check-input'
										checked={this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.encryptionAtTransit ? true : false}
										onChange={e => this.setState({ encryptionAtTransit: this.state.encryptionAtTransit ? false : true },
											() => this.handleSelectedInput('encryptionAtTransit', this.state.encryptionAtTransit) 
										)}
									/>
									<label className="form-check-label" for="latestVersion">Enforce Encryption at Transit</label>
								</div>
							</div>
						</div>
					
						<div className='row'>
							<div className="col-lg-12 col-sm-12">
								<div className='form-group d-flex'>
									<div>
										<label className="mr-3 align-self-center">Encryption Options</label>
										<div className={`multiselectDarkTheme minWidth300 z999 ${encryptOptions.length > 10 ? '' : 'removeDropdownSearchBar'}`} ref={this.encyOptionRef}>
											<ReactMultiSelectCheckboxes						
												placeholderButtonLabel="All"
												// menuIsOpen ={this.state.isEncyOptionOpen}
												value={this.state.encryptOptionsArray && this.state.encryptOptionsArray.map(acc => ({
													value: acc.value,
													label: acc.label ? acc.label : <span className="placeholder">Select</span>
												}))}
												onChange={arr => {
												this.handleMultiSelectChange('encryptOptions', arr ? arr : []) }}
												options={encryptOptions.map(item => ({
													value: item.value,
													label: item.label,
												}))}
											/>
										</div>
									</div>
									<div className="ml-2 flex-grow align-self-center">
										{this.state.encryptOptionsArray.length ? this.state.encryptOptionsArray.map((item, index) => {
											return(
												<span key={index} className="badge badge-light f14 mr-1 mb-1" >
													{item.label}
													<i onClick={ () => this.removeSelectedOption('encryptOptions', index, item) } className='ml-1 fal fa-times cursorPointer'></i>
												</span>
											)
										})
										: null
										}
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<h6 className="font-weight-bold col-lg-12 mb-3 text-info">KMS Settings</h6>
							<div className="col-lg-3 col-sm-4 ">
								<div className='form-group form-check form-check-inline'>
									<input
										className='form-check-input'
										type='checkbox'
										checked={this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.crossAccountAccessforCusmtomerMgmtKMS ? true : false}
										onChange={e => this.setState({ crossAccountAccessforCusmtomerMgmtKMS: this.state.crossAccountAccessforCusmtomerMgmtKMS ? false : true },
											() => this.handleSelectedInput('crossAccountAccessforCusmtomerMgmtKMS', this.state.crossAccountAccessforCusmtomerMgmtKMS) 
										)}
									/>
									<label className="form-check-label" for="latestVersion">Allow Cross account Access for Customer Managment KMS</label>
								</div>
							</div>
							<div className="col-lg-3 col-sm-4">
								<div className='form-group form-check form-check-inline'>
									<input
										className='form-check-input'
										type='checkbox'
										checked={this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.crossAccountKMSEncryption ? true : false}
										onChange={e => this.setState({ crossAccountKMSEncryption: this.state.crossAccountKMSEncryption ? false : true },
											() => this.handleSelectedInput('crossAccountKMSEncryption', this.state.crossAccountKMSEncryption) 
										)}
									/>
									<label className="form-check-label" for="latestVersion">Allow Cross account KMS for Encryption</label>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-6 col-sm-8">
								<div className="form-group">
									<label className="align-self-center mr-3">Max. Key Administrators in Customer Managed KMS</label>
									<Input
										type='text'
										value={this.state.customerManagedKMS}
										onChange={e => {
											this.setState({ customerManagedKMS: onlyNumeric(e.target.value) },
												() => this.handleSelectedInput('customerManagedKMS', this.state.customerManagedKMS) 
											)
										}}
										className='inputTextbox w-20'
										placeholder=''
									/>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-lg-12 col-sm-12">
								<h6 className="font-weight-bold mb-3 text-info">Advanced Settings</h6>
								<div className="d-flex form-group">
									<div>
										<label>Allowed Key Types</label>
										<div className={`multiselectDarkTheme minWidth300 z999 ${keyTypeOptions.length > 10 ? '' : 'removeDropdownSearchBar'}`} ref={this.keyTypeRef}>
											<ReactMultiSelectCheckboxes						
												placeholderButtonLabel="All"
												/* getDropdownButtonLabel={() => this.getMultiSelectedCount('optionType', this.state.optionType)} */
												// menuIsOpen ={this.state.isKeyTypeOpen}
												value={this.state.keyTypeOptionsArray && this.state.keyTypeOptionsArray.map(acc => ({
													value: acc.value,
													label: acc.label ? acc.label : <span className="placeholder">Select</span>
												}))}
												onChange={arr => {
												this.handleMultiSelectChange('keyTypeOptions', arr ? arr : []) }}
												options={keyTypeOptions.map(item => ({
													value: item.value,
													label: item.label,
												}))}
											/>
										</div>
									</div>

									<div className="ml-2 flex-grow align-self-center">
										{this.state.keyTypeOptionsArray.length ? this.state.keyTypeOptionsArray.map((item, index) => {
											return(
												<span key={index} className="badge badge-light f14 mr-1 mb-1" >
													{item.label}
													<i onClick={ () => this.removeSelectedOption('keyTypeOptions', index, item) } className='ml-1 fal fa-times cursorPointer'></i>
												</span>
											)
										})
										: null
										}
									</div>
								</div>
								<div className="d-flex form-group">
									<div>
										<label>Allowed Key usage</label>
										<div className={`multiselectDarkTheme minWidth300 z998 w-18 ${keyUsageOptions.length > 10 ? '' : 'removeDropdownSearchBar'}`} ref={this.keyUsageRef}>
											<ReactMultiSelectCheckboxes						
												placeholderButtonLabel="All"
												/* getDropdownButtonLabel={() => this.getMultiSelectedCount('optionType', this.state.optionType)} */
												// menuIsOpen ={this.state.isKeyUsageOpen}
												value={this.state.keyUsageOptionsArray && this.state.keyUsageOptionsArray.map(acc => ({
													value: acc.value,
													label: acc.label ? acc.label : <span className="placeholder">Select</span>
												}))}
												onChange={arr => {
												this.handleMultiSelectChange('keyUsageOptions', arr ? arr : []) }}
												options={keyUsageOptions.map(item => ({
													value: item.value,
													label: item.label,
												}))}
											/>
										</div>	
									</div>

									<div className="ml-2 flex-grow align-self-center">
										{this.state.keyUsageOptionsArray.length ? this.state.keyUsageOptionsArray.map((item, index) => {
											return(
												<span key={index} className="badge badge-light f14 mr-1 mb-1" >
													{item.label}
													<i onClick={ () => this.removeSelectedOption('keyUsageOptions', index, item) } className='ml-1 fal fa-times cursorPointer'></i>
												</span>
											)
										})
										: null
										}
									</div>
								</div>
								<div className="d-flex form-group">
									<div>
										<label>Key Spec</label>
										<div className={`multiselectDarkTheme minWidth300 z997 w-18 ${keySpecOptions.length > 10 ? '' : 'removeDropdownSearchBar'}`} ref={this.keySpecRef}>
											<ReactMultiSelectCheckboxes						
												placeholderButtonLabel="All"
												/* getDropdownButtonLabel={() => this.getMultiSelectedCount('optionType', this.state.optionType)} */
												// menuIsOpen ={this.state.isKeySpecOpen}
												value={this.state.keySpecOptionsArray && this.state.keySpecOptionsArray.map(acc => ({
													value: acc.value,
													label: acc.label ? acc.label : <span className="placeholder">Select</span>
												}))}
												onChange={arr => {
												this.handleMultiSelectChange('keySpecOptions', arr ? arr : []) }}
												options={keySpecOptions.map(item => ({
													value: item.value,
													label: item.label,
												}))}
											/>
										</div>	
									</div>

									<div className="ml-2 flex-grow align-self-center">
										{this.state.keySpecOptionsArray.length ? this.state.keySpecOptionsArray.map((item, index) => {
											return(
												<span key={index} className="badge badge-light f14 mr-1 mb-1" >
													{item.label}
													<i onClick={ () => this.removeSelectedOption('keySpecOptions', index, item) } className='ml-1 fal fa-times cursorPointer'></i>
												</span>
											)
										})
										: null
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ServiceEncryption.propTypes = {
	logType: PropTypes.object,
	setEditRuleDetails: PropTypes.func,
	apiToCall: PropTypes.func,
	editRuleDetails: PropTypes.object,
	parameters: PropTypes.object,
	provider: PropTypes.string,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('encruptionState',state)
	return {
		editRuleDetails: state.governance.editRuleDetails		
	}
}
export default connect(mapStateToProps, {
	setEditRuleDetails,
	apiToCall,
})(ServiceEncryption)
