/*************************************************
 * Tvastar
 * @exports
 * @file ApexGandalfBarChart.js
 * @author Prakash // on 15/07/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { capitalizeTheFirstLetterOfEachWord, momentConvertionUtcToLocalTime, unitTimeConvertionDetail } from '../../../utils/utility'
import Chart from 'react-apexcharts'


class ApexGandalfBarChart extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    
    componentDidMount = () => {
        let graphData = this.props.graphData;
        if(graphData === 'mockData') {
            graphData = {
                data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,5,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
                labels: ["14 July 2022 09:48", "14 July 2022 09:49", "14 July 2022 09:50", "14 July 2022 09:51", "14 July 2022 09:52", "14 July 2022 09:53", "14 July 2022 09:54", "14 July 2022 09:55", "14 July 2022 09:56", "14 July 2022 09:57", "14 July 2022 09:58", "14 July 2022 09:59", "14 July 2022 10:00", "14 July 2022 10:01", "14 July 2022 10:02", "14 July 2022 10:03", "14 July 2022 10:05", "14 July 2022 10:05", "14 July 2022 10:06", "14 July 2022 10:07", "14 July 2022 10:08", "14 July 2022 10:09", "14 July 2022 10:10", "14 July 2022 10:11", "14 July 2022 10:12", "14 July 2022 10:13", "14 July 2022 10:14", "14 July 2022 10:15", "14 July 2022 10:16", "14 July 2022 10:17", "14 July 2022 10:18"],
                impactsObserved: [{date: "14 July 2022 09:52", value: 2, event_type: "alert"}, {date: "14 July 2022 10:04", value: 3, event_type: "anomalies"}, {date: "14 July 2022 10:06", value: 1, event_type: "error"}, {date: "14 July 2022 10:07", value: 2, event_type: "alert"}, {date: "14 July 2022 10:010", value: 3, event_type: "anomalies"},  {date: "14 July 2022 10:12", value: 2, event_type: "alert"}],
                event_type_count: {"alerts": 0, "anomalies": 0, "errors": 0, "issues": 0}

            }
        }

        this.setState({ graphData },
            () => this.drawGraph()    
        )
    }

    drawGraph = () => {
        let graphData = this.state.graphData
        let points = []
        let gandalfData = graphData && graphData.impactsObserved ? graphData.impactsObserved : []

        console.log("gandalfData", gandalfData)
            
        if(this.state.selectedLegendType && this.state.selectedLegendType !== "" ) {
            gandalfData = gandalfData.filter(e => e.event_type === this.state.selectedLegendType).length ? gandalfData.filter(e => e.event_type === this.state.selectedLegendType) : []
        }
        let maxValue = gandalfData.length
        gandalfData && gandalfData.forEach(item => {
            points.push({
                x: new Date(item.date).getTime(),
                y: Math.floor(Math.random() * (maxValue - 1) + 1),
                // y: item.value,
                // mouseEnter: function(e) {
                //     // do something on marker click
                //     alert(1)
                // },
                marker: {
                    size: 6,
                    fillColor: item.event_type === "alerts" ? "#3F73AD" : item.event_type === "anomalies" ? "#775BA2" : item.event_type === "errors" ? "#24A597" : item.event_type === "issues" ? "#FF4560" : "#858B99",
                    strokeColor: item.event_type === "alerts" ? "#3F73AD" : item.event_type === "anomalies" ? "#775BA2" : item.event_type === "errors" ? "#24A597" : item.event_type === "issues" ? "#FF4560" : "#858B99",
                    radius: 2,
                    OffsetX: 0,
                    OffsetY: 0,
                    cssClass: '',
                },
                label: {
                    // borderColor: "#FF4560",
                    // offsetY: 0,
                    // style: {
                    //     color: "#fff",
                    //     background: "#FF4560"
                    // },                
                    // text: ""
                }
            })
        })
        
        let graphLables = graphData.labels
        let series = [{
            data: graphData.data,
            labels: graphData.labels
        }]

        let options = {
            tooltip: {
                enabled: true,
                // custom: function({series, seriesIndex, dataPointIndex, w}) {
                //     
                //     //val = Math.abs(Math.round(val))
                //     
                //     //let val = str.replace('-',series[seriesIndex][dataPointIndex]);
                //     return '<div class="arrow_box">' +
                //         '<span> '+ w.globals.initialSeries[0].data[dataPointIndex].x + 
                //         ' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val + ' </span>' +
                //         '</div>'
                // },
                style: {
                    fontSize: '9px',
                },
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    let val  = series[seriesIndex][dataPointIndex]
                    let labelName = "change"
                    let unit = w.globals.initialSe
                    let retrunData = '<div class="arrow_box">'
                    retrunData += ' <span>' +  w.globals.initialSeries[0].labels[dataPointIndex] + '</span> '
                    if(labelName !== '') {
                        retrunData += '<span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> '+ val
                    } else {
                        retrunData += '<span style="color:#039BE5"> ' + val + '</span>'
                    }

                    retrunData += '</div>'

                    return retrunData
                }
            },				
            chart: {
                redrawOnParentResize: true,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
                sparkline: {
                    enabled: this.props.sparkline
                },
                animations: {
                    enabled: true
                },
                type: 'bar',
                height: 150,
                stacked: this.props.stacked,
            },
            colors: this.props.colors ? this.props.colors : ['#26B68F', '#2ED1BE', '#67E1D6', '#00B5D4'],
            plotOptions: {
                bar: {
                    horizontal: this.props.horizontal,
                },       
            },
            dataLabels: {
                enabled: false
            },  
            grid: {
                show: false,                                
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                },
            },
            labels: graphLables,
            xaxis: {
                show: this.props.xaxis,
                tooltip: {
                    enabled: false
                },
                type: this.props.xaxisFormat !== 'string' ? 'datetime' : 'category',
                axisBorder: {
                    show: true,
                    color:'#434B5E'
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: this.props.xaxisLabel,
                    datetimeUTC: false,
                    style: {
                        colors: this.props.axisLabelColor,
                        fontSize: this.props.axisLabelFontSize ?  this.props.axisLabelFontSize : '13px',
                        fontWeight: 'bolder',
                    },
                },
                
                crosshairs: {
                    show: false
                },
            },
            yaxis: {
                show: this.props.yaxis,
                // this.props.tickAmount: this.props.yaxisTickAmont ? this.props.yaxisTickAmont : 4,
                axisBorder: {
                    show: false,
                    color: '#969AA7',
                },
                labels: {
                    show: this.props.yaxisLabel,
                    style: {
                        colors: this.props.axisLabelColor,
                        fontSize: '13px',
                        fontWeight: 'bolder',
                    },
                    formatter: function(value) {
                        return parseInt(value)
					}
                }
            },
            legend: {
                show: this.props.legend,
            },            
            annotations: {
                position: 'back',
                points: points
            },
        }

        this.setState({ series, options, eventTypesCount: graphData.event_type_count })
    }

    onClickLegend = (type) => {
        this.setState({ selectedLegendType: type, showElipMenu: false },
            () => this.drawGraph()    
        )
    }

    render() {
        return(
            <React.Fragment>
                {this.props.showHeading ?
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <p className="m-0 text-white">Change Impact Analysis</p>
                            <p className="small mb-1">Event changed at 
                                <span className="ml-1 text-info">{this.props.impactAnalysis && this.props.impactAnalysis.change_event_time ? momentConvertionUtcToLocalTime(this.props.impactAnalysis.change_event_time, "YYYY-MM-DD HH:mm:ss") : ""} </span>
                                <span className="ml-1">is a {this.props.impactAnalysis && this.props.impactAnalysis.change_events && this.props.impactAnalysis && this.props.impactAnalysis.change_events.length ? "bad" : "good"} change, with</span>
                                <span className={`ml-1 risk-${this.props.impactAnalysis && this.props.impactAnalysis.severity ? this.props.impactAnalysis.severity.toLowerCase() : ""}`}>{this.props.impactAnalysis && this.props.impactAnalysis.severity}</span>
                                <span className="ml-1">severity</span>
                            </p>
                            {console.log("this.props.summary", this.props.summary)}
                            {this.props.summary ? 
                                <p className="small mb-1">{this.props.summary}</p>
                            : null}
                        </div>
                        <div className="btn-group ellipsisDropDownBlack dropleft">
                            <div className={`cursorPointer`} onClick={() => this.setState({ showElipMenu: !this.state.showElipMenu }) }>
                                <i className="fa fa-ellipsis-v text-secondary"></i>
                            </div>
                            <div className={`dropdown-menu ${this.state.showElipMenu ? "show active" : ""}`}>
                                <div>
                                    <i className="fa fa-caret-up"></i>
                                    <button className="dropdown-item" type="button"
                                    onClick={() => this.onClickLegend("")}>All</button>
                                </div>
                                <div>
                                    <i className="fa fa-caret-up"></i>
                                    <button className="dropdown-item" type="button"
                                    onClick={() => this.onClickLegend("alerts")}>Alerts</button>
                                </div>
                                <div>
                                    <i className="fa fa-caret-up"></i>
                                    <button className="dropdown-item" type="button"
                                    onClick={() => this.onClickLegend("anomalies")}>Anomalies</button>
                                </div>
                                <div>
                                    <i className="fa fa-caret-up"></i>
                                    <button className="dropdown-item" type="button"
                                    onClick={() => this.onClickLegend("errors")}>Errors</button>
                                </div>
                                <div>
                                    <i className="fa fa-caret-up"></i>
                                    <button className="dropdown-item" type="button"
                                    onClick={() => this.onClickLegend("issues")}>Issues</button>
                                </div>
                            </div>
                        </div>
                    </div>
                : null}
                {this.state.series && this.state.series.length ?
                    <div className={`${this.props.className}`}>
                        <Chart options={this.state.options} series={this.state.series} type="bar" height={this.props.height} width={this.props.width ? this.props.width : "100%"}/>
                        {this.state.eventTypesCount ?
                            <ul className="stock-legend mt-n2 d-flex justify-content-center">
                                <li><span className="legend-circle bg-alerts"></span> <span className="text-muted d-inline-block"> &nbsp;Alerts: {this.state.eventTypesCount.alerts}</span></li> 
                                <li><span className="legend-circle bg-anomalies"></span> <span className="text-muted d-inline-block"> &nbsp; Anomalies: {this.state.eventTypesCount.anomalies}</span></li> 
                                <li><span className="legend-circle bg-errors"></span> <span className="text-muted d-inline-block"> &nbsp; Errors: {this.state.eventTypesCount.errors}</span></li>
                                <li><span className="legend-circle bg-issues"></span> <span className="text-muted d-inline-block"> &nbsp; Issues: {this.state.eventTypesCount.issues}</span></li>
                            </ul>
                        : null}
                    </div>
                : null}
            </React.Fragment>
        )
    }
}
 
/**
 * Type of the props used in the component
 */
ApexGandalfBarChart.propTypes = {}

const mapStateToProps = state => {}

export default connect(mapStateToProps, {})(withRouter(ApexGandalfBarChart))