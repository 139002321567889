/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Dashboard.js
 * @author Prakash // on 10/02/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { AppWrapper } from '../common/AppWrapper'
import PropTypes from 'prop-types'
import { UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap'
import {
    listCasesWithDetails,
    getAiopsCoveredResources,
    getAiopsRemediationEstimate,
    getCasesTotalCount,
    getAiopsDailyTrend,
    setAiopsPropsDetails,
    // listAssets,
} from '../../actions/aiops/AiopsAction'
import { getTotalRemediationCount, getRemediationCount, getAiopsRemediationTrend } from '../../actions/aiops/RemediationAction'
import _ from 'lodash'
import Search from '../common/SearchComponent'
import { momentDateGivenFormat, capitalizeFirstLetter, calculteOpenHours, countFormater, calculateSeconds, currentLocaltime, MathRound, getPastDaysNew,  subDays, momentConvertionUtcToLocalTime, twoDateDiffrence } from '../../utils/utility'

import Chart from 'react-apexcharts'

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";

import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

import Sunburst from "sunburst-chart";

import ResizeableDarkThemeTable from '../designComponents/Table/ResizeableDarkThemeTable'

// const ResizeableDarkThemeTable = lazy(() => import('../designComponents/Table/ResizeableDarkThemeTable'));
// const renderLoader = () => <p>Loading</p>;

const sunburstData = {
    name: "Operations summary",
    color: "#17a2b8",
    description: 'Lorem ipsum',
    children: [
      {
        name: "Running",
        color: "#C2E358",
        children: [
          {
            name: "Alarm",
            color: "#9ED763",
            children: [
              {
                name: "Low",
                color: "#2C9E4B",
                size: 89
              },
              {
                name: "Medium",
                color: "#2C9E4B",
                size: 10
              }
            ]
          },
          {
            name: "Operational",
            color: "#9ED763",
            size: 63
          },
          {
            name: "Stand-by",
            color: "#9ED763",
            size: 18
          }
        ]
      },
      {
        name: "Stopped",
        color: "#F78608",
        children: [
          {
            name: "Fault",
            color: "#EE3711",
            size: 10
          },
          {
            name: "Maintenence",
            color: "#EE3711",
            size: 2
          },
          {
            name: "Alarm",
            color: "#EE3711",
            children: [
              {
                name: "Hight",
                color: "#E6172F",
                size: 2
              }
            ]
          }
        ]
      }
    ]
};

const caseStatus = [
    {label: 'All', value: 'All'},
    {label: 'Open', value: 'Open'},
    {label: 'InProgress', value: 'InProgress'},
    {label: 'Resolved', value: 'Resolved'},
    {label: 'Closed', value: 'Closed'}
]

const caseSeverity = [
    {label: 'All', value: 'All'},
    {label: 'Critical', value: 'Critical'},
    {label: 'High', value: 'High'},
    {label: 'Medium', value: 'Medium'},
    {label: 'Low', value: 'Low'}
]

const caseSortOptions = [
    {label: 'Alert', value: 'total_alerts'},
    {label: 'Date', value: 'created_at'},
    {label: 'Severity', value: 'severity'},
    {label: 'Status', value: 'status'}
]

// const today = moment();
const disableFutureDt = current => {
  return current.isAfter(currentLocaltime())
}

class Dashboard extends Component {    
    
    constructor(props) {
        super(props)
        this.accountRef = React.createRef()
        this.regionRef = React.createRef()
        this.serviceRef = React.createRef() 
        this.statusRef = React.createRef()
        this.severityRef = React.createRef()
        this.byCaseGridRef = React.createRef()
        this.dashboardSunBurstRef = React.createRef()
        
        this.state = {
            caseView: 'Grid',
            caseLoading: true,

            //filter section start
            allAccounts: [],
            selectedAccount: [],
            isAccountOpen: false,
            
            allRegions: [],
            selectedRegion: [],
            isRegionOpen: false,

            services: [],
            selectedService: [],
            selectedServiceArray: [],
            serviceResourceArray: [],
            isServiceOpen: false,
            

            selectedStatus: [],
            isStatusOpen: false,

            selectedSeverity: [],
            isSeverityOpen: false,

            		
			startRecord: 0, 
			currentPage: 1,
			perPage: 30,
            
            //filter section end
        }
        this.updateFormFieldValue = this.updateFormFieldValue.bind(this);
    }

    handleStartTimeChange  = this.handleStartTimeChange.bind(this)
    handleEndTimeChange    = this.handleEndTimeChange.bind(this)

	componentDidMount = () => {

        if(this.props.listCaseDetails && this.props.listCaseDetails.length) {
            let totalPages = Math.ceil(this.props.listCaseDetails.length/this.state.perPage)
            this.setState({ filteredArray: this.props.listCaseDetails, totalPages })
        }

        this.setFilters()

        // this.setState({ showLoading: true })
        if (this.props.location && this.props.location.state && Object.keys(this.props.location.state).length !== 0) {
            if(this.props.location.state.caseView && this.props.location.state.caseView !== '') {
                this.setState({ caseView: this.props.location.state.caseView })
            }
        }

        if(!this.props.aiopsPropsDetails || !this.props.aiopsPropsDetails.search_params) {
            this.sliderPeriod()
        } else {
            let dateArray = this.props.aiopsPropsDetails.search_params.dateArray
            let sliderStartDate = this.props.aiopsPropsDetails.search_params.start_time
            let sliderEndDate = this.props.aiopsPropsDetails.search_params.end_time

            this.setState({ dateArray, sliderStartDate, sliderEndDate, caseLoading: true },
                () => {
                    this.brushSliderChart(dateArray)
                    this.onSearch('onLoad')
                }
            )
        }
        // this.sliderPeriod()

        // params.aggregate_by = ["category"]
        // this.props.getCasesTotalCount(params , (promise, result) => {
        //     //console.log('category', result)
        // })

        // params.aggregate_by = ["services"]
        // this.props.getCasesTotalCount(params , (promise, result) => {
        //     //console.log('services', result)
        // })
        
        
        // params.start_time = this.state.sliderStartDate
        // params.end_time = this.state.sliderEndDate

        this.renderSunBurstGraph()
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevProps.aiopsDailyTrend !== this.props.aiopsDailyTrend) {
            this.aiopsTrendGraph()
        }

        if(prevProps.listCaseDetails !== this.props.listCaseDetails) {
            let totalPages = Math.ceil(this.props.listCaseDetails.length/this.state.perPage)
            this.setState({ filteredArray: this.props.listCaseDetails, totalPages })
        }

        if(this.state.intervalChanged) {
            if(this.props.aiopsPropsDetails && this.props.aiopsPropsDetails.search_params) {
                let prevData = this.props.aiopsPropsDetails.search_params
                if(prevData.start_time !== this.state.sliderStartDate || prevData.end_time !== this.state.sliderEndDate) {
                    this.setState({ intervalChanged: false, caseLoading: true },
                        this.onSearch('search')
                    )
                }
            }
        }

    }

    setFilters = () => {

        if(this.props.accountCasesTotalCount && this.props.accountCasesTotalCount.length) {
            let result = this.props.accountCasesTotalCount ? this.props.accountCasesTotalCount : []
            if(!result.filter(obj => obj.account_id === 'All').length) {
                let accRow = {}
                accRow.case = true
                accRow.account_id = "All"
                accRow.total_count = 0
                result.unshift(accRow);
            } else {
                result = []
            }
			this.setState({ allAccounts: result  })
        } else {
            let params = {}
            params.aggregate_by = ["account_id"]
            this.props.getCasesTotalCount(params , (promise, result) => {
                let allAccounts = result
                if(result.length) {
                    let accRow = {}
                    accRow.case = true
                    accRow.account_id = "All"
                    accRow.total_count = 0
                    allAccounts.unshift(accRow);
                } else {
                    allAccounts = []
                }
                this.setState({ allAccounts })
            })
        }

        if(this.props.regionCasesTotalCount && this.props.regionCasesTotalCount.length) {
            let result = this.props.regionCasesTotalCount
            if(!result.filter(obj => obj.region === 'All').length) {
                let accRow = {}
                accRow.case = true
                accRow.region = "All"
                accRow.total_count = 0
                result.unshift(accRow);
            } else {
                result = []
            }
			this.setState({ allRegions: result  })
        } else {
            let params = {}
            params.aggregate_by = ["region"]
            this.props.getCasesTotalCount(params , (promise, result) => {
                let allRegions = result
                if(result.length) {
                    let accRow = {}
                    accRow.case = true
                    accRow.region = "All"
                    accRow.total_count = 0
                    allRegions.unshift(accRow);
                } else {
                    allRegions = []
                }
                this.setState({ allRegions })
            })
        }

        if(this.props.resourceCasesTotalCount && this.props.resourceCasesTotalCount.length) {
            let result = this.props.resourceCasesTotalCount
            if(!result.filter(obj => obj.resource_type === 'All').length) {
                let accRow = {}
                accRow.case = true
                accRow.resource_type = "All"
                accRow.total_count = 0
                result.unshift(accRow);
            } else {
                result = []
            }
			this.setState({ allServices: result  })
        } else {
            let params = {}
            params.aggregate_by = ["resource_type"]
            params.searchInputs = 'filter'
            this.props.getCasesTotalCount(params, (promise, result) => {
                let allServices = result
                if(result.length) {
                    let accRow = {}
                    accRow.case = true
                    accRow.resource_type = "All"
                    accRow.total_count = 0
                    allServices.unshift(accRow);
                } else {
                    allServices = []
                }
                this.setState({ allServices })
            })
        }
        
        if(this.props.aiopsPropsDetails && this.props.aiopsPropsDetails.search_params) {
            let propParams = this.props.aiopsPropsDetails.search_params
            this.setState({ 
                start_time: propParams.start_time,
                end_time: propParams.end_time,
                // selectedAccount: propParams.account_id && propParams.account_id.length ? propParams.account_id : [],
                // selectedRegion: propParams.region && propParams.region.length ? propParams.region : [],
                // selectedService: propParams.service_name && propParams.service_name.length ? propParams.service_name : [],
                // selectedStatus: propParams.status && propParams.status.length ? propParams.status : [],
                // selectedSeverity: propParams.severity && propParams.severity.length ? propParams.severity : [],
            })
            
            if(propParams.account_id && propParams.account_id.length) {
                this.setState({ selectedAccount: propParams.account_id})
            }

            if(propParams.resource_type && propParams.resource_type.length) {
                let array = []
                propParams.resource_type.forEach(item => {
                    this.props.resourceCasesTotalCount.forEach(ser => {
                        if(ser.resource_type === item) {
                            let serRow = {}
                            serRow.value = ser.resource_type
                            serRow.label = ser.resource_type
                            array.push(serRow)
                        }
                    })
                })
                this.setState({ selectedRegion: propParams.region})
            }

            if(propParams.region && propParams.region.length) {
                this.setState({ selectedRegion: propParams.region })
            }

            if(propParams.status && propParams.status.length) {
                this.setState({ selectedStatus: propParams.status })
            }            

            if(propParams.severity && propParams.severity.length) {
                this.setState({ selectedSeverity: propParams.severity })
            }
        }
    }    
    
    sliderPeriod = () => {
        let sliderEndDate = momentDateGivenFormat(currentLocaltime(), 'YYYY-MM-DD 23:59:59')
        let sliderStartDate = momentDateGivenFormat(subDays(sliderEndDate, 14), 'YYYY-MM-DD 00:00:00');

        let dateArray = []
        let month = ''
        let length = 90
        for(let x=1;x<=length;x++) {
            let date = subDays(sliderEndDate, (length-x))
            
            let value = 5
            if(month === '' || month !== momentDateGivenFormat(date, 'MM')) {
                month = momentDateGivenFormat(date, 'MM')
                value = 10
            }
            if(new Date(date).getTime() <= new Date(sliderEndDate).getTime()) {
                dateArray.push([date, value])
            }
        }
        
        this.setState({ dateArray, brushSliderSeries: [], sliderStartDate, sliderEndDate },
            () => {
                this.brushSliderChart(dateArray)
                this.onSearch('search')
                window.addEventListener('resize', () => {
                    this.brushSliderChart(dateArray)
                })
            }
        )
    }
    
    brushSliderChart = (dateArray) => {
        let sliderStartDate = this.state.sliderStartDate
        let sliderEndDate = this.state.sliderEndDate
        
        if(dateArray.length) {
            let brushSliderSeries = [{
                data: dateArray
            }]
            let dataArray = []
            dateArray.forEach(item => {
                dataArray.push(item[0])
            })

            let startEndLabels = []
            dataArray.forEach((item, x) => {
                if(dataArray.length>8) {
                    let length = dataArray.length - (2)
                    let interval = parseInt(length / 4)
                    let second = interval
                    let third =  interval * 2
                    let fourth = interval * 3
                    if(x === 0 || x === second || x === third || x=== fourth || x === (dataArray.length - 1)) {
                        startEndLabels.push(item)
                    }
                } else {
                    startEndLabels.push(item)
                }
            })

            let columnWidth = '5%'
            let brushSliderOptions = {
                plotOptions: {
                    bar: {
                        barHeight: '100%',
                        columnWidth: columnWidth,
                    },          
                },
                chart: {
                    id: 'chart1',
                    height: 130,
                    type: 'bar',
                    animations: {
                        enabled: false
                    },
                    brush:{
                        target: 'none',
                        enabled: true,
                    },
                    selection: {
                        enabled: true,
                        fill: {
                            color: "#039BE5",
                            opacity: 1,
                            // opacity: 0.6
                        },  
                        stroke: {
                            show: false,
                            curve: 'straight',
                            lineCap: 'round',
                            // colors: '#00A3CB',
                            colors: '#039BE5',
                            width: 0,
                            dashArray: 0,      
                        },
                        xaxis: {
                            min: new Date(sliderStartDate).getTime(),
                            max: new Date(sliderEndDate).getTime(),
                        },
                        style: {
                            fontSize: '28px',
                            color: '#000000'
                        }
                    },
                    events: {
                        selection: (chartContext, { xaxis, yaxis }) => {
                            var minDate = momentDateGivenFormat(new Date(xaxis.min), 'YYYY-MM-DD')
							var maxDate = momentDateGivenFormat(new Date(xaxis.max), 'YYYY-MM-DD')

							let startSlice = momentDateGivenFormat(minDate, 'YYYY-MM-DD 00:00:00');
							let endSlice = momentDateGivenFormat(maxDate, 'YYYY-MM-DD 23:59:59');

                            this.setState({ sliderStartDate: startSlice, sliderEndDate: endSlice },
                                () => this.getAttribute()
                            )
                        },
                        click: (event, chartContext, config) => {
                            this.setState({ intervalChanged: true })
                        }
                    }
                },
                colors: ['#515151'],
                xaxis: {
                    tickAmount: 8,
                    type: 'datetime',
                    tooltip: {
                        enabled: false
                    },
                    position: 'top',
                    labels: {
                        show: true,
                        datetimeUTC: false,
                        style: {
                            colors: '#E0E0E0'
                        },
                        formatter: function(val) {
                            return momentDateGivenFormat(val, "DD MMM");
                        }
                    },
                    
                },
                yaxis: {
                    show: false,
                    tickAmount: 2,
                    labels: {
                        show: false
                    },
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: false
                        },
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                }
            }
        
            this.setState({ datePickerSliderSeries: brushSliderSeries, datePickerSliderOptions: brushSliderOptions })
        }
    }

    onSearch = (type) => {
        this.setState({ resetEnabled: (type === 'onLoad' || type === 'reset') ? false : true })
        if(type === 'reset') {
            type = 'search'
        }
        let params = {}
        params.dateArray = this.state.dateArray
        params.start_time = this.state.sliderStartDate
        params.end_time = this.state.sliderEndDate

        if(this.state.selectedAccount.length && !this.state.selectedAccount.includes('All')) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion.length && !this.state.selectedRegion.includes('All')) {
            params.region = this.state.selectedRegion
        }
        if(this.state.selectedService.length && !this.state.selectedService.includes('All')) {
            params.resource_type = this.state.selectedService
        }
        if(this.state.selectedStatus.length && !this.state.selectedStatus.includes('All')) {
            params.status = this.state.selectedStatus
        }
        if(this.state.selectedSeverity.length && !this.state.selectedSeverity.includes('All')) {
            params.severity = this.state.selectedSeverity
        }

        this.props.setAiopsPropsDetails('search_params', params)
            
        this.statusCount(type)
        this.severityCount(type)
        this.servicesCount(type)

        if(!this.props.aiopsDailyTrend || !Object.entries(this.props.aiopsDailyTrend).length || type === 'search') {
            params = {}
            params.start_time = this.state.sliderStartDate
            params.end_time = this.state.sliderEndDate
            params.all = true

            if(this.state.selectedAccount.length && !this.state.selectedAccount.includes('All')) {
                params.account_id = this.state.selectedAccount
            }
            if(this.state.selectedRegion.length && !this.state.selectedRegion.includes('All')) {
                params.region = this.state.selectedRegion
            }
            if(this.state.selectedService.length && !this.state.selectedService.includes('All')) {
                params.resource_type = this.state.selectedService
            }
            if(this.state.selectedStatus.length && !this.state.selectedStatus.includes('All')) {
                params.status = this.state.selectedStatus
            }
            if(this.state.selectedSeverity.length && !this.state.selectedSeverity.includes('All')) {
                params.severity = this.state.selectedSeverity
            }

            this.props.getAiopsDailyTrend(params, (promise, result) => {})
        } else {
            this.aiopsTrendGraph()
        }

        let listCaseParams = {}
        listCaseParams.start_time = this.state.sliderStartDate
        listCaseParams.end_time = this.state.sliderEndDate

        if(this.state.selectedAccount.length && !this.state.selectedAccount.includes('All')) {
            listCaseParams.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion.length && !this.state.selectedRegion.includes('All')) {
            listCaseParams.region = this.state.selectedRegion
        }
        if(this.state.selectedService.length && !this.state.selectedService.includes('All')) {
            listCaseParams.resource_type = this.state.selectedService
        }
        if(this.state.selectedStatus.length && !this.state.selectedStatus.includes('All')) {
            listCaseParams.status = this.state.selectedStatus
        }
        if(this.state.selectedSeverity.length && !this.state.selectedSeverity.includes('All')) {
            listCaseParams.severity = this.state.selectedSeverity
        }
        if(!this.props.listCaseDetails || !this.props.listCaseDetails.length || type === 'search') {
            this.props.listCasesWithDetails(listCaseParams, (promise, result) => {
                this.setState({ caseLoading : false })
            })
        }

        
        if(!this.props.aiopsCoveredResources || !Object.entries(this.props.aiopsCoveredResources).length|| type === 'search') {
            let resourcesParams = {}
            this.props.getAiopsCoveredResources(resourcesParams, (promise, result) => {})
        }

        if(!this.props.aiopsRemediationEstimate || !Object.entries(this.props.aiopsRemediationEstimate).length || type === 'search') {
            let remEstimateParams = {}
            remEstimateParams.start_time = this.state.sliderStartDate
            remEstimateParams.end_time = this.state.sliderEndDate
    
            if(this.state.selectedAccount.length && !this.state.selectedAccount.includes('All')) {
                remEstimateParams.account_id = this.state.selectedAccount
            }
            if(this.state.selectedRegion.length && !this.state.selectedRegion.includes('All')) {
                remEstimateParams.region = this.state.selectedRegion
            }
            if(this.state.selectedService.length && !this.state.selectedService.includes('All')) {
                remEstimateParams.resource_type = this.state.selectedService
            }
            if(this.state.selectedStatus.length && !this.state.selectedStatus.includes('All')) {
                remEstimateParams.status = this.state.selectedStatus
            }
            if(this.state.selectedSeverity.length && !this.state.selectedSeverity.includes('All')) {
                remEstimateParams.severity = this.state.selectedSeverity
            }
            
            this.props.getAiopsRemediationEstimate(remEstimateParams, (promise, result) => {})
        }
        
        let remCountParams = {}
        remCountParams.start_time = this.state.sliderStartDate
        remCountParams.end_time = this.state.sliderEndDate
        
        if(this.state.selectedAccount.length && !this.state.selectedAccount.includes('All')) {
            remCountParams.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion.length && !this.state.selectedRegion.includes('All')) {
            remCountParams.region = this.state.selectedRegion
        }
        if(this.state.selectedService.length && !this.state.selectedService.includes('All')) {
            remCountParams.resource_type = this.state.selectedService
        }
        if(this.state.selectedStatus.length && !this.state.selectedStatus.includes('All')) {
            remCountParams.status = this.state.selectedStatus
        }
        if(this.state.selectedSeverity.length && !this.state.selectedSeverity.includes('All')) {
            remCountParams.severity = this.state.selectedSeverity
        }
        remCountParams.source = 'aiops'
        this.props.getRemediationCount(remCountParams, (promise, result) => {})

        if(!this.props.remediationTotalCount || !Object.entries(this.props.remediationTotalCount).length || type === 'search') {
            let remTotalCountParams = {}
            remTotalCountParams.start_time = this.state.sliderStartDate
            remTotalCountParams.end_time = this.state.sliderEndDate
            remTotalCountParams.aggregate_by = ['invocation_type']
            
            if(this.state.selectedAccount.length && !this.state.selectedAccount.includes('All')) {
                remTotalCountParams.account_id = this.state.selectedAccount
            }
            if(this.state.selectedRegion.length && !this.state.selectedRegion.includes('All')) {
                remTotalCountParams.region = this.state.selectedRegion
            }
            if(this.state.selectedService.length && !this.state.selectedService.includes('All')) {
                remTotalCountParams.resource_type = this.state.selectedService
            }
            if(this.state.selectedStatus.length && !this.state.selectedStatus.includes('All')) {
                remTotalCountParams.status = this.state.selectedStatus
            }
            if(this.state.selectedSeverity.length && !this.state.selectedSeverity.includes('All')) {
                remTotalCountParams.severity = this.state.selectedSeverity
            }
            
            // this.props.getTotalRemediationCount(remTotalCountParams, (promise, result) => {})
        }

        if(!this.props.remediationTrend || !Object.entries(this.props.remediationTrend).length || type === 'search') {
            let remTrendParams = {}

            if(this.state.selectedAccount.length && !this.state.selectedAccount.includes('All')) {
                remTrendParams.account_id = this.state.selectedAccount
            }
            if(this.state.selectedRegion.length && !this.state.selectedRegion.includes('All')) {
                remTrendParams.region = this.state.selectedRegion
            }
            if(this.state.selectedService.length && !this.state.selectedService.includes('All')) {
                remTrendParams.resource_type = this.state.selectedService
            }
            if(this.state.selectedStatus.length && !this.state.selectedStatus.includes('All')) {
                remTrendParams.status = this.state.selectedStatus
            }
            if(this.state.selectedSeverity.length && !this.state.selectedSeverity.includes('All')) {
                remTrendParams.severity = this.state.selectedSeverity
            }

            this.props.getAiopsRemediationTrend(remTrendParams, (promise, result) => {})
        }

        setTimeout(() => { this.setState({ caseLoading: false }) }, 5000)
    }

    onReset = () => {
        this.setState({ 
            selectedAccount: [],
            selectedRegion: [],
            selectedService: [],
            selectedServiceArray: [],
            selectedStatus: [],
            selectedSeverity: [],
            resetEnabled: this.state.resetEnabled
        },
            () => {
                if(this.state.resetEnabled) {
                    this.onSearch('reset')
                }
            }
        )
    }

    aiopsTrendGraph = () => {
        let graphDetails = this.props.aiopsDailyTrend
        if(graphDetails && Object.entries(graphDetails).length) {
            let graphDate = graphDetails.labels
            Object.entries(graphDetails).forEach(([key, value], index) => {
                // let series = []
                // let dataRow = {}
                // dataRow.name = key
                if(key !== 'labels' && key !== 'total_count') {
                    let array = []
                    let mttrSeries = []
                    value.count.forEach((item, i) => {
                        let dataArray = []
                        dataArray.push(graphDate[i])
                        dataArray.push(item)
                        array.push(dataArray)
                    })
                    let series = [{
                        name: capitalizeFirstLetter(key),
                        data: array
                    }]

                    if(key === 'remediations') {
                        array = []
                        value.avg_mttr.forEach((item, i) => {
                            let dataArray = []
                            dataArray.push(graphDate[i])
                            if(item > 0) {
                                let convertedValue = this.calculateMttrSeconds(item)
                                dataArray.push(convertedValue)
                            } else {
                                dataArray.push(item)
                            }
                            // console.log('convertSeconds', convertSeconds)
                            array.push(dataArray)
                        })
                        mttrSeries = [{
                            name: 'MTTR',
                            data: array
                        }]

                        let mttrOptions = {
                            plotOptions: {
                                bar: {
                                    horizontal: false,
                                    barHeight: '40%',
                                    startingShape: 'flat',
                                    endingShape: 'rounded',
                                    columnWidth: '20%',
                                    distributed: false,
                                    rangeBarOverlap: false,
                                    colors: {
                                      backgroundBarColors: ['#383E4D'],
                                      backgroundBarOpacity: 1,
                                      backgroundBarRadius: 0,
                                    }
                                },          
                            },
                            chart: {
                                id: 'chart2',
                                //group: 'barBrushChart',
                                type: 'bar',
                                height: 230,
                                sparkline: {
                                    enabled: true
                                },
                                animations: {
                                    enabled: false
                                },
                                redrawOnParentResize: true
                            },
                            colors: ['#24A597'],
                            stroke: {
                                width: 0
                            },
                            dataLabels: {
                                enabled: false
                            },
                            markers: {
                                size: 0
                            },
                            xaxis: {
                                type: 'datetime',
                                labels: {
                                    datetimeUTC: false
                                },
                            },
                            tooltip: {
                                fixed: {
                                    enabled: false
                                },
                                x: {
                                    show: false
                                },
                                y: {
                                    title: {
                                    formatter: (seriesName) => seriesName,
                                    }
                                },
                                marker: {
                                    show: false
                                },
                                custom: function({series, seriesIndex, dataPointIndex, w}) {
                                    let dateLabel = momentDateGivenFormat(w.globals.initialSeries[0].data[dataPointIndex][0], 'MMM DD')
                                    let name = w.globals.initialSeries[0].name
                                    let val = w.globals.initialSeries[0].data[dataPointIndex][1]
        
                                    return '<div class="arrow_box"> ' + dateLabel + ' <span style="color:'+ w.globals.colors[0] +'">'+ name +' '+ val+'</span></div>'
                                }
                            }
                        }

                        this.setState({ [key+'_mttr_series']: mttrSeries, [key+'_mttr_options']: mttrOptions })
                    }

                    let colors = []
                    let options = {}
                    let plotOptions = {}
                    let fillData = {}
                    if(key === 'cases' || key === 'remediations') {
                        if(key === 'cases') {
                            fillData = {
                                colors: ['#039BE5'],
                                type: 'gradient', // gradient
                                gradient: {
                                    type: 'vertical', // The gradient in the horizontal direction
                                    gradientToColors: ['#5F5BA2'], // The color at the end of the gradient
                                    opacityFrom: .9, // transparency
                                    opacityTo: 1,
                                    stops:[0, 120]
                                }
                            }
                            colors = ['#00B5D4']
                            plotOptions = {
                                bar: {
                                    horizontal: false,
                                    barHeight: '100%',
                                    // columnWidth: '100%',
                                    // startingShape: 'flat',
                                    endingShape: 'rounded',
                                    columnWidth: '20%',
                                    distributed: false,
                                    // rangeBarOverlap: false
                                },          
                            }
                        } else {
                            colors = ['#693EBC']
                            plotOptions = {
                                bar: {
                                    horizontal: false,
                                    barHeight: '40%',
                                    startingShape: 'flat',
                                    endingShape: 'rounded',
                                    columnWidth: '20%',
                                    distributed: false,
                                    rangeBarOverlap: false,
                                    colors: {
                                      backgroundBarColors: ['#383E4D'],
                                      backgroundBarOpacity: 1,
                                      backgroundBarRadius: 0,
                                    }
                                },          
                            }
                        }
                        options = {
                            plotOptions: plotOptions,
                            chart: {
                                id: 'chart2',
                                //group: 'barBrushChart',
                                type: 'bar',
                                height: 230,
                                sparkline: {
                                    enabled: true
                                },
                                animations: {
                                    enabled: false
                                },
                                redrawOnParentResize: true
                            },
                            // colors: ['#546E7A'],
                            colors: colors,
                            fill: fillData,
                            stroke: {
                                width: 0
                            },
                            dataLabels: {
                                enabled: false
                            },
                            markers: {
                                size: 0
                            },
                            xaxis: {
                                type: 'datetime',
                                labels: {
                                    datetimeUTC: false
                                },
                            },
                            tooltip: {
                                fixed: {
                                    enabled: false
                                },
                                x: {
                                    show: false
                                },
                                y: {
                                    title: {
                                    formatter: (seriesName) => seriesName,
                                    }
                                },
                                marker: {
                                    show: false
                                },
                                custom: function({series, seriesIndex, dataPointIndex, w}) {
                                    let dateLabel = momentDateGivenFormat(w.globals.initialSeries[0].data[dataPointIndex][0], 'MMM DD')
                                    let name = w.globals.initialSeries[0].name
                                    let val = w.globals.initialSeries[0].data[dataPointIndex][1]
        
                                    return '<div class="arrow_box"> ' + dateLabel + ' <span style="color:'+ w.globals.colors[0] +'">'+ name +' '+ val+'</span></div>'
                                }
                            }
                        }
                    } else {
                        if(key === 'events') {
                            colors = ['#039BE5']
                        } else {
                            colors = ['#00B5D4']
                        }
                        options = {
                            chart: {
                                // id: key+'_'+index,
                                // group: 'slider',
                                type: 'line',
                                width: 100,
                                height: 35,
                                sparkline: {
                                    enabled: true
                                }
                            },
                            stroke: {
                                show: true,
                                curve: 'straight',
                                lineCap: 'butt',
                                colors: undefined,
                                width: 3,
                                dashArray: 0,      
                            },
                            colors: colors,
                            tooltip: {
                                custom: function({series, seriesIndex, dataPointIndex, w}) {
                                    let dateLabel = momentDateGivenFormat(w.globals.initialSeries[0].data[dataPointIndex][0], 'MMM DD')
                                    let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]
                                    
                                    return '<div class="arrow_box">'+dateLabel+' <span style="color:'+ w.globals.colors[0] +'">' + parseFloat(val)+ '</span> </div>'
                                },
                                fixed: {
                                    enabled: false
                                },
                                x: {
                                    show: false,
                                },
                                y: {
                                    show: false
                                },        
                                marker: {
                                    show: false
                                }
                            },
                            xaxis: {
                                type: 'datetime',
                                labels: {
                                    datetimeUTC: false,
                                }       
                                
                            }
                        }
                    }

                    this.setState({ [key+'_series']: series, [key+'_options']: options })
                }
            })
        }
    }

    statusCount = (type) => {
        let params = {}
        // params.duration = "+90d"
        params.start_time = this.state.sliderStartDate
        params.end_time = this.state.sliderEndDate
        
        if(this.state.selectedAccount.length && !this.state.selectedAccount.includes('All')) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion.length && !this.state.selectedRegion.includes('All')) {
            params.region = this.state.selectedRegion
        }
        if(this.state.selectedService.length && !this.state.selectedService.includes('All')) {
            params.resource_type = this.state.selectedService
        }
        if(this.state.selectedStatus.length && !this.state.selectedStatus.includes('All')) {
            params.status = this.state.selectedStatus
        }
        if(this.state.selectedSeverity.length && !this.state.selectedSeverity.includes('All')) {
            params.severity = this.state.selectedSeverity
        }

        params.aggregate_by = ["status"]
        if(!this.props.statusCasesTotalCount || !Object.entries(this.props.statusCasesTotalCount).length || type === 'search') {
            this.props.getCasesTotalCount(params, (promise, result) => {
                this.formStatusResult()
            })
        } else {
            this.formStatusResult()
        }   
    }

    formStatusResult = () => {
        let statusObject = {}
        let count = 0
        let result = this.props.statusCasesTotalCount

        let array = []
        let dataRow = {}
        if(result && result.length && result.filter(arr => arr.status === 'Open').length) {
            let statusResult = result.filter(arr => arr.status === 'Open')
            array.push(statusResult[0])
        } else {
            dataRow = {}
            dataRow.status = 'Open'
            dataRow.total_count = 0
            array.push(dataRow)
        }       

        if(result && result.length && result.filter(arr => arr.status === 'InProgress').length) {
            let statusResult = result.filter(arr => arr.status === 'InProgress')
            array.push(statusResult[0])
        } else {
            dataRow = {}
            dataRow.status = 'InProgress'
            dataRow.total_count = 0
            array.push(dataRow)
        }  
        
        if(result && result.length && result.filter(arr => arr.status === 'Resolved').length) {
            let statusResult = result.filter(arr => arr.status === 'Resolved')
            array.push(statusResult[0])
        } else {
            dataRow = {}
            dataRow.status = 'Resolved'
            dataRow.total_count = 0
            array.push(dataRow)
        }           
        
        if(result && result.length && result.filter(arr => arr.status === 'Closed').length) {
            let statusResult = result.filter(arr => arr.status === 'Closed')
            array.push(statusResult[0])
        } else {
            dataRow = {}
            dataRow.status = 'Closed'
            dataRow.total_count = 0
            array.push(dataRow)
        }

        result && result.length && result.forEach(item => {
            count += item.total_count
        })

        statusObject.status = array
        statusObject.statusCount = count

        this.setState({ statusObject })
    }

    severityCount = (type) => {
        let params = {}
        // params.duration = "+90d"
        params.start_time = this.state.sliderStartDate
        params.end_time = this.state.sliderEndDate
        params.aggregate_by = ["severity"]
        
        if(this.state.selectedAccount.length && !this.state.selectedAccount.includes('All')) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion.length && !this.state.selectedRegion.includes('All')) {
            params.region = this.state.selectedRegion
        }
        if(this.state.selectedService.length && !this.state.selectedService.includes('All')) {
            params.resource_type = this.state.selectedService
        }
        if(this.state.selectedStatus.length && !this.state.selectedStatus.includes('All')) {
            params.status = this.state.selectedStatus
        }
        if(this.state.selectedSeverity.length && !this.state.selectedSeverity.includes('All')) {
            params.severity = this.state.selectedSeverity
        }
        
        if(!this.props.severityCasesTotalCount || !Object.entries(this.props.severityCasesTotalCount).length || type === 'search') {
            this.props.getCasesTotalCount(params , (promise, result) => {
                this.formSeverityResult()
            })
        } else {
            this.formSeverityResult()
        }
    }

    formSeverityResult = () => {
        let severityObject = {}
        let count = 0
        let result = this.props.severityCasesTotalCount

        let array = []
        let dataRow = {}
        if(result && result.length && result.filter(arr => arr.severity === 'Critical').length) {
            let severityResult = result.filter(arr => arr.severity === 'Critical')
            array.push(severityResult[0])
        } else {
            dataRow = {}
            dataRow.severity = 'Critical'
            dataRow.total_count = 0
            array.push(dataRow)
        }

        if(result && result.length && result.filter(arr => arr.severity === 'High').length) {
            let severityResult = result.filter(arr => arr.severity === 'High')
            array.push(severityResult[0])
        } else {
            dataRow = {}
            dataRow.severity = 'High'
            dataRow.total_count = 0
            array.push(dataRow)
        }
            

        if(result && result.length && result.filter(arr => arr.severity === 'Medium').length) {
            let severityResult = result.filter(arr => arr.severity === 'Medium')
            array.push(severityResult[0])
        } else {
            dataRow = {}
            dataRow.severity = 'Medium'
            dataRow.total_count = 0
            array.push(dataRow)
        }

        if(result && result.length && result.filter(arr => arr.severity === 'Low').length) {
            let severityResult = result.filter(arr => arr.severity === 'Low')
            array.push(severityResult[0])
        } else {
            dataRow = {}
            dataRow.severity = 'Low'
            dataRow.total_count = 0
            array.push(dataRow)
        }

        result && result.length && result.forEach(item => {
            count += item.total_count
        })
        severityObject.severity = array
        severityObject.severityCount = count

        this.setState({ severityObject })
    }

    servicesCount = (type) => {
        let params = {}
        // params.duration = "+90d"
        params.start_time = this.state.sliderStartDate
        params.end_time = this.state.sliderEndDate
        params.aggregate_by = ["resource_type"]
        
        if(this.state.selectedAccount.length && !this.state.selectedAccount.includes('All')) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion.length && !this.state.selectedRegion.includes('All')) {
            params.region = this.state.selectedRegion
        }
        if(this.state.selectedService.length && !this.state.selectedService.includes('All')) {
            params.resource_type = this.state.selectedService
        }
        if(this.state.selectedStatus.length && !this.state.selectedStatus.includes('All')) {
            params.status = this.state.selectedStatus
        }
        if(this.state.selectedSeverity.length && !this.state.selectedSeverity.includes('All')) {
            params.severity = this.state.selectedSeverity
        }

        if(!this.props.serviceCasesTotalCount || !Object.entries(this.props.serviceCasesTotalCount).length || type === 'search') {
            this.props.getCasesTotalCount(params, (promise, result) => {})
        }
    }

    getCaseDetails = (selectedCase) => {
        // this.props.setAiopsPropsDetails('selectedCase', selectedCase)
        // this.props.history.push({ 
        //     pathname: URL_PATH.AIOPS_CASE_DETAILS,
        //     state: { caseId: selectedCase.case_id, caseView: this.state.caseView, listCaseDetails: this.props.listCaseDetails },
        //     // target={"_blank"}
        // })

        let url = "/cases/details/"+selectedCase.case_id

        window.open(url, '_blank').focus();
        // <Link className='' target="_blank" 
        // to={{
        //     pathname: URL_PATH.AIOPS_CASE_DETAILS,
        //     state: { caseId: tableRow.case_id, caseView: this.state.caseView, listCaseDetails: this.props.listCaseDetails }
        // }}/>
        // <Link
    }

    calculateAverageCompletionTime = (seconds) => {
        let avgTime = calculateSeconds(seconds)
        let calTime = ''
        if(avgTime[0] !== 0) {
            calTime = avgTime[0] + 'D '             
        }
        if(avgTime[1] !== 0) {
            calTime += avgTime[1] + 'hrs ' 
        }
        if(avgTime[0] === 0 && avgTime[2] !== 0) {
            calTime += avgTime[2] + 'min ' 
        }

        if(avgTime[0] === 0 && avgTime[1] === 0 && avgTime[3] !== 0) {
            calTime += avgTime[3] + 'sec' 
        }

        return calTime

    }

    calculateMttrSeconds = (sec) => {
        let mttr = calculateSeconds(sec)
        let convert = ''
        if(mttr[0] > 0) {
            convert += mttr[0] +'D '
        }
        if(mttr[1] > 1) {
            convert += mttr[1] +'H '
        }

        if(mttr[0] === 0 && mttr[2] > 0) {
            convert += mttr[2] +'M '
        }

        if(mttr[0] === 0 && mttr[1] === 0 && mttr[3] > 0) {
            convert += mttr[3] +'S'
        }
        return convert
    }

    handleClickOutside(event) {
            
        if(this.accountRef && !this.accountRef.current.contains(event.target)) {
            this.setState({ isAccountOpen: false })
        } else {
            this.setState({ isAccountOpen: true })
        }

        if(this.regionRef && !this.regionRef.current.contains(event.target)) {
            this.setState({ isRegionOpen: false })
        } else {
            this.setState({ isRegionOpen: true })
        }

        if(this.statusRef && !this.statusRef.current.contains(event.target)) {
            this.setState({ isStatusOpen: false })
        } else {
            this.setState({ isStatusOpen: true })
        }

        if(this.severityRef && !this.severityRef.current.contains(event.target)) {
            this.setState({ isSeverityOpen: false })
        } else {
            this.setState({ isSeverityOpen: true })
        }
        
        if(this.serviceRef && !this.serviceRef.current.contains(event.target)) {
            this.setState({ isServiceOpen: false })
        } else {
            this.setState({ isServiceOpen: true })
        }
    }
    
    handleMultiSelectChange = (field, arrayValue) => {
		let value = arrayValue.map(item => item.value)

        let selectedValue = []
        let prevState = this.state[field]
        if(value.includes('All')) {
            if(!prevState.includes('All')) {
                if(field === "selectedAccount") {
                    this.state.allAccounts.forEach(item => {
                        selectedValue.push(item.account_id)
                    })
                } else if(field === "selectedRegion") {
                    this.state.allRegions.forEach(item => {
                        selectedValue.push(item.region)
                    })
                } else if(field === "selectedSeverity") {
                    caseSeverity.forEach(item => {
                        selectedValue.push(item)
                    })
                } else if(field === "selectedStatus") {
                    caseStatus.forEach(item => {
                        selectedValue.push(item)
                    })
                } else if(field === "selectedService") {
                    this.state.allServices.forEach(item => {
                        selectedValue.push(item.resource_type)
                    })
                }
            } else {
                const index = value.indexOf('All');
                if (index > -1) {
                    value.splice(index, 1);
                }
                selectedValue = value
            }
        } else if(!prevState.includes('All')) {
            selectedValue = value
        }

        if(selectedValue.length && !selectedValue.includes('All')) {
            if(field === "selectedAccount") {
                if(!selectedValue.includes('All') && selectedValue.length === (this.state.allAccounts.length -1)) {
                    selectedValue.push('All')
                }
            } else if(field === "selectedRegion") {
                if(!selectedValue.includes('All') && selectedValue.length === (this.state.allRegions.length -1)) {
					selectedValue.push('All')
				}
            } else if(field === "selectedSeverity") {
                if(!selectedValue.includes('All') && selectedValue.length === (caseSeverity.length -1)) {
                    selectedValue.push('All')
                }
            } else if(field === "selectedStatus") {
                if(!selectedValue.includes('All') && selectedValue.length === (caseStatus.length -1)) {
                    selectedValue.push('All')
                }
            } else if(field === "selectedService") {
                if(!selectedValue.includes('All') && selectedValue.length === (this.state.allServices.length -1)) {
                    selectedValue.push('All')
                }
            }
        }
        this.setState({ [field]: selectedValue })
    }
    
    getMultiSelectedCount = (type, array) => {
        return array.length && array.includes('All') ? 'All' : (array.length ? array.length +' Selected' : 'All')
    }   
	
	navigatePage = (action, currentPage) => {
		let startRecord = this.state.startRecord
		if(action === 'next' && currentPage !== this.state.totalPages) {
			startRecord = startRecord + this.state.perPage
			currentPage = currentPage + 1
		} else if(action === 'previous' && currentPage !== 0) {
			currentPage = currentPage - 1
			startRecord = startRecord - this.state.perPage
		} else if(action === 'start' && currentPage !== 0) {
			currentPage = 1
			startRecord = 0
		} else if(action === 'end' && currentPage !== 0) {
			currentPage = this.state.totalPages
			startRecord = (this.state.totalPages - 1) * this.state.perPage
		}
		this.setState({ currentPage, startRecord },
            () => this.executeByCaseGridScroll()    
        )
	}
  
    handleStartTimeChange(date) {
        let sliderStartDate = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
        let sliderEndDate = this.state.sliderEndDate
        if(new Date(sliderStartDate).getTime() > new Date(sliderEndDate).getTime() ) {
            sliderEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')
        }
        this.setState({ sliderStartDate, sliderEndDate, caseLoading: true },
            () => {
                this.onSearch('search')
            }
        )
    }

    handleEndTimeChange(date) {
        let sliderStartDate = this.state.sliderStartDate
        let sliderEndDate = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
        if(new Date(sliderEndDate).getTime() > new Date(sliderStartDate).getTime() ) {
            this.setState({ sliderEndDate, caseLoading: true },
                () => {
                    this.onSearch('search')
                }
            )
        }
    }
    
    getAttribute = () => {
        var attributes = document.getElementsByClassName("apexcharts-selection-rect") && document.getElementsByClassName("apexcharts-selection-rect")[0] &&document.getElementsByClassName("apexcharts-selection-rect")[0].attributes;
        if(attributes) {
            let width = attributes.width.value
            let x1 = parseInt(attributes.x.value) + parseInt(20)
            let x2 = parseInt(attributes.x.value) + parseInt(width) + parseInt(10)

            let leftLPosition = x1
            let rightLPosition = x2
            let diff = twoDateDiffrence(this.state.sliderEndDate, this.state.sliderStartDate)
            if(diff < 8) {
                leftLPosition = x1 - 20
                if(diff < 4) {
                    leftLPosition = x1 - 40
                }
                rightLPosition = x2 + 10
            }

            var d1 = document.getElementById('leftSliderSpan');
            d1.style.position = "absolute";
            d1.style.left = leftLPosition+'px';
            d1.style.top = '200px';
            d1.style.color = '#039BE5';

            var d2 = document.getElementById('rightSliderSpan');
            d2.style.position = "absolute";
            d2.style.left = rightLPosition+'px';
            d2.style.top = '200px';
            d2.style.color = '#039BE5';
        }
    }

    caseSortBy = (sortBy) => {
        let sortType = this.state['sortType_'+sortBy] && this.state['sortType_'+sortBy] === 'asc' ? 'desc' : 'asc'
        this.setState({ 
            sortBy, 
            ['sortType_'+sortBy]: sortType,
            sortType
        })
        
        caseSortOptions.forEach(item => {
            if(sortBy !== item.value) {
                this.setState({ ['sortType_'+item.value]: '' })
            }
        })

        let filteredArray = this.state.filteredArray
        if(filteredArray && filteredArray.length){
            filteredArray = _.orderBy(filteredArray, [sortBy], [sortType])
        }
        this.setState({ filteredArray })
    }

    executeByCaseGridScroll = () => {
        this.byCaseGridRef.current.scrollIntoView()
    }

    // checkIntervalUpdates = (prevState) => {
    //     console.log(this.state.sliderStartDate)
    //     console.log(prevState.sliderStartDate)
    // }

    renderSunBurstGraph = () => {
        Sunburst()
            .data(sunburstData)
            .label("name")
            .size("size")
            .color("color")
            .strokeColor("color")
            .width("300")
            .height("300")
            // .children('Hight')
            // .centerRadius(0.1)
            // .radiusScaleExponent(0.5)
            // .showLabels(true)
            // .excludeRoot(true)
            // .sort((a, b) => a.value - b.value)
            .labelOrientation('auto') //[angular, radial or auto]
            // .minSliceAngle(0.2)
            .radiusScaleExponent(.5)
			.excludeRoot(true)
            // .centerRadius([.4])
			// .minSliceAngle([.5])
            // .focusOnNode({})
            // .focusOnNode((d) => d)
            // .focusOnNode('Running')
            // .onClick((d, node) => {this.onClickNode(d, node)})
            // .onHover((node) => this.onHoverNode(node))
            // .tooltipTitle((d, node) => `<i>${node.data.name}</i>`)(this.sunBrustRef.current)
            .tooltipContent((d, node) => `<i>${node.value}</i>`)(this.dashboardSunBurstRef.current);
    }

    onClickNode = (d, node) => {
      
        // console.log(';;;;;;;;;;;;;', d)
        // this.state.myChart.focusOnNode(node)
        // this.state.myChart.onClick(this.state.myChart.focusOnNode)
        // console.log(';;;;;;;;;;;;;', d)
        // console.log(';;;;;;;;;;;;;', d.__dataNode)
        // console.log(';;;;;;node;;;;;;;', d.__dataNode.data)
        // console.log(this.sunBrustRef.d)
        // let data = {"width": '800'}
        // this.myChart.focusOnNode(d.__dataNode)
        // return node;
    }

    updateFormFieldValue = (fieldName, fieldValue) => {
        this.setState({ [fieldName]: fieldValue });
    };

	render() {
		return (
            <div className="container-fluid overflow-auto flex-grow-1 bg-muted" onClick={ (event) => { this.handleClickOutside(event) } }>
                <div className={`loaderOverlay ${this.state.caseLoading ? '' : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
                <div className='row h-100'>
                    <div className='col-sm-6 p-0'>
                        <div className="title-section pb-3">
                            <div className='row'>
                                <div className='col-12 align-self-center'>
                                    <h6 className="text-white m-0">Cases Dashboard</h6>
                                    <p className="text-white m-0">Consolidated view of Cases, Alerts, Events and Remediations analyzed by AI Engine</p>
                                </div>
                                {/* <TextField 
                                    className="form-control"
                                    recordSelection={this.updateFormFieldValue}
                                    value=""
                                    name={['this_'+1]}
                                    label="First Field"
                                    placeholder=""
                                    required={true}
                                /> */}
                                <div className='col-12 mt-3'>
                                    <div className="d-flex">
                                        <div className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} ref={this.accountRef}>
											<label className="mb-1">Account</label>
											<div className={`filterMultiSelectDropdown ${this.props.accounts && this.props.accounts.length > 10 ? '' : 'removeDropdownSearchBar'}`}>
												<ReactMultiSelectCheckboxes						
													placeholderButtonLabel="All"
													getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedAccount', this.state.selectedAccount)}
													menuIsOpen ={this.state.isAccountOpen}
													value={this.state.selectedAccount.map(acc => ({
														value: acc
													}))}
													onChange={arr => {
													this.handleMultiSelectChange('selectedAccount', arr ? arr : []) }}
													options={this.state.allAccounts && this.state.allAccounts.map(account => ({
														value: account.account_id,
														label: account.account_id,
													}))}
												/>
											</div>
										</div>
                                        <div className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} ref={this.regionRef}>
											<label className="mb-1">Region</label>
											<div className={`filterMultiSelectDropdown ${this.props.regions && this.props.regions.length > 10 ? '' : 'removeDropdownSearchBar'}`}>
                                                <ReactMultiSelectCheckboxes						
                                                    placeholderButtonLabel="All"
                                                    getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedRegion', this.state.selectedRegion)}
                                                    menuIsOpen ={this.state.isRegionOpen}
                                                    value={this.state.selectedRegion.map(reg => ({
                                                        value: reg
                                                    }))}
                                                    onChange={arr => {
                                                    this.handleMultiSelectChange('selectedRegion', arr ? arr : []) }}
                                                    options={this.state.allRegions && this.state.allRegions.map(region => ({
                                                        value: region.region,
                                                        label: region.region,
                                                    }))}
                                                />
											</div>
										</div>
                                        <div className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} ref={this.serviceRef}>
											<label className="mb-1">Service</label>
											<div className={`filterMultiSelectDropdown ${this.state.serviceResourceArray && this.state.serviceResourceArray.length > 10 ? '' : 'removeDropdownSearchBar'}`}>
                                                <ReactMultiSelectCheckboxes						
                                                    placeholderButtonLabel="All"
                                                    getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedService', this.state.selectedService)}
                                                    menuIsOpen ={this.state.isServiceOpen}
                                                    value={this.state.selectedService.map(res => ({
                                                        value: res
                                                    }))}
                                                    onChange={arr => {
                                                    this.handleMultiSelectChange('selectedService', arr ? arr : []) }}
                                                    options={this.state.allServices && this.state.allServices.map(ser => ({
                                                        value: ser.resource_type,
                                                        label: ser.resource_type,
                                                    }))}
                                                />
											</div>
										</div>
                                        <div className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} ref={this.statusRef}>
											<label className="mb-1">Status</label>
											<div className={`filterMultiSelectDropdown ${caseStatus && caseStatus.length > 10 ? '' : 'removeDropdownSearchBar'}`}>
                                                <ReactMultiSelectCheckboxes						
                                                    placeholderButtonLabel="All"
                                                    getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedStatus', this.state.selectedStatus)}
                                                    menuIsOpen ={this.state.isStatusOpen}
                                                    value={this.state.selectedStatus.map(item => ({
                                                        value: item
                                                    }))}
                                                    onChange={arr => {
                                                    this.handleMultiSelectChange('selectedStatus', arr ? arr : []) }}
                                                    options={caseStatus && caseStatus.map(item => ({
                                                        value: item.value,
                                                        label: item.label,
                                                    }))}
                                                />
											</div>
										</div>
                                        <div className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} ref={this.severityRef}>
											<label className="mb-1">Severity</label>
											<div className={`filterMultiSelectDropdown ${caseSeverity && caseSeverity.length > 10 ? '' : 'removeDropdownSearchBar'}`}>
                                            <ReactMultiSelectCheckboxes						
                                                    placeholderButtonLabel="All"
                                                    getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedSeverity', this.state.selectedSeverity)}
                                                    menuIsOpen ={this.state.isSeverityOpen}
                                                    value={this.state.selectedSeverity.map(item => ({
                                                        value: item
                                                    }))}
                                                    onChange={arr => {
                                                    this.handleMultiSelectChange('selectedSeverity', arr ? arr : []) }}
                                                    options={caseSeverity && caseSeverity.map(item => ({
                                                        value: item.value,
                                                        label: item.label,
                                                    }))}
                                                />
											</div>
										</div>
										
										<div className={`ml-3 align-self-center text-center`}>
                                            <div className="mb-1 displayNone">
                                                <p className="f11 m-0 p-0">{this.state.sliderStartDate ? momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM YYYY HH:mm:ss'): ''}</p>
                                                <p className="f11 m-0 p-0">{this.state.sliderEndDate ?momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM YYYY HH:mm:ss'): ''}</p>
                                            </div>
											<div className="btn-group" role="group" aria-label="Basic example">
												<button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-right`} onClick={() => this.setState({ caseLoading: true }, () => this.onSearch('search') )}>Search</button>
												<button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-left`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
											</div>
										</div>
                                    </div>
                                </div>
                                {this.state.datePickerSliderOptions && this.state.datePickerSliderSeries ?
                                    <div className={`col-md-12 mb-n3 ${this.state.disableDateSlider ? 'disabled' : ''}`} >
                                        <Chart options={this.state.datePickerSliderOptions} series={this.state.datePickerSliderSeries} type="bar" height={80} />
                                    </div>
                                : null}
                                
                                <div className="d-flex">
                                    <div id="leftSliderSpan" className="f12">{momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM')}</div>
                                    <div id="rightSliderSpan" className="f12">{momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM')}</div>
                                </div>
                                <div className="col-12 d-flex justify-content-end mb-1">
                                    <div className={`bg-filterBlue text-white align-self-center p-1 mr-1 f10 cursorPointer`}>
                                    {getPastDaysNew(this.state.sliderStartDate, this.state.sliderEndDate)}
                                    </div>
                                    <div className="d-flex aiopsTopFiltersection">
                                        <Datetime 
                                            value={momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM YYYY hh:mm A')}
                                            onChange={this.handleStartTimeChange}
                                            dateFormat={'DD MMM YYYY'}
                                            timeFormat={'hh:mm A'}
                                            inputProps={{ readOnly: true }}
                                            className="w-100 mr-1 aiopsMetricsDatepicker"
                                            isValidDate={disableFutureDt}
                                        />
                                        <Datetime
                                            value={momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM YYYY hh:mm A')}
                                            onChange={this.handleEndTimeChange}
                                            dateFormat={'DD MMM YYYY'}
                                            timeFormat={'hh:mm A'}
                                            inputProps={{ readOnly: true }}
                                            className="w-100 aiopsMetricsDatepicker"
                                            isValidDate={disableFutureDt}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='container-fluid'>                                
                            <div className="row mt-3">
                                <div className="col-sm-12 mb-1">
                                    {/* <p className='font-weight-bold m-0 f15'>AIOps</p> */}
                                    <small className="mb-0 font-weight-bold">Showing information for the period 
                                        <span className="f12 mb-0 align-self-center text-info ml-2">{this.state.sliderStartDate ? momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM YYYY'): ''} to {this.state.sliderEndDate ? momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM YYYY'): ''}</span>
                                        <span className="ml-2">(local time)</span>
                                    </small>
                                </div>
                                <div className='container-fluid'>
                                    <div className="row mt-1">
                                        <div className="col-sm-6">
                                            <div className="rounded bg-dark p-3 h-100">
                                                <div className="d-flex justify-content-between">
                                                    <p className="mb-0 text-white"><b>Trend</b></p>
                                                    {/* <span className={`fa fa-caret-${this.state.showTrendDetails ? 'left' : 'down'} cursorPointer`} onClick={() => this.setState({ showTrendDetails: !this.state.showTrendDetails })}></span> */}
                                                </div>
                                            
                                                {this.props.aiopsDailyTrend && Object.entries(this.props.aiopsDailyTrend).length ?
                                                    Object.entries(this.props.aiopsDailyTrend).map(([key, value], index) => {
                                                        return (
                                                        key !== 'labels' && key !== 'total_count' && key !== 'remediations' ?
                                                            <React.Fragment>
                                                            <div className="cols-m-12 row py-2">
                                                                <p className="mb-0 align-self-center text-right w-15 ml-n2 mr-2 ">{capitalizeFirstLetter(key)}</p>
                                                                <div className="w-75">
                                                                    {this.state[key+'_series'] && [key+'_series'].length && this.state[key+'_options'] ?
                                                                        <div className="d-flex cursorPointer transparentTooltip">
                                                                            {key === 'cases' ?
                                                                                <Chart options={this.state[key+'_options']} series={this.state[key+'_series']} type="bar" height={20}/>
                                                                            :
                                                                                <Chart options={this.state[key+'_options']} series={this.state[key+'_series']} type="line" height={20}/>
                                                                            }

                                                                        </div>
                                                                    :
                                                                        <div className='col-md-12 justify-content-center'>
                                                                            <label className="align-self-center">-</label>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <p className="mb-0 align-self-center w-10">{this.props.aiopsDailyTrend.total_count && this.props.aiopsDailyTrend.total_count[key] ? countFormater(this.props.aiopsDailyTrend.total_count[key]) : 0}</p>
                                                            </div>
                                                            {index !== (Object.entries(this.props.aiopsDailyTrend).length - 1) } {
                                                                <div className='dropdown-divider'></div>
                                                            }
                                                            </React.Fragment>
                                                        : null   
                                                        )
                                                    })
                                                : null}
                                            </div>
                                        </div>
                                        <div className="col-sm-6 pl-md-0">
                                            <div className="rounded bg-dark p-3">
                                                <p className="mb-0 text-white"><b>KPIs</b></p>
                                                {/* <p className="small mb-0">Showing the AIOps metrics</p> */}
                                                <div className="row mt-3">
                                                    {this.props.aiopsRemediationEstimate ?
                                                        <React.Fragment>
                                                        {this.props.aiopsRemediationEstimate.estimated_cost || this.props.aiopsRemediationEstimate.estimated_cost === 0 ?
                                                            <div className="col-md-6 pr-0">
                                                                <div className="bg-muted p-2 rounded h-100">
                                                                    <h6 className="text-primary-50">{parseFloat(this.props.aiopsRemediationEstimate.estimated_cost).toFixed(2)} USD</h6>
                                                                    <div className="bg-primary-2 small-divder"></div>
                                                                    <p className="mb-0 text-primary-50 mt-2" id="estimatedCost">~ Cost saved</p>
                                                                    <UncontrolledTooltip placement='top' target="estimatedCost">{'Estimated cost for this remediation is $'+ parseFloat(this.props.aiopsRemediationEstimate.estimated_cost).toFixed(2)}</UncontrolledTooltip>
                                                                </div>
                                                            </div>
                                                        : null}
                                                        {this.props.aiopsRemediationEstimate.total_hours || this.props.aiopsRemediationEstimate.total_hours === 0 ? 
                                                            <div className="col-md-6">
                                                                <div className="bg-muted p-2 rounded h-100">
                                                                    <h6 className="text-info-50">{Math.round(this.props.aiopsRemediationEstimate.total_hours)} Hours</h6>
                                                                    <div className="bg-info-2 small-divder"></div>
                                                                    <p className="mb-0 text-info-50 mt-2" id="estimatedHours">~ Hours saved</p>
                                                                    <UncontrolledTooltip placement='top' target="estimatedHours">{'Estimated hours taken for remediation is '+ this.props.aiopsRemediationEstimate.total_hours +' hours.'}</UncontrolledTooltip>
                                                                </div>
                                                            </div>
                                                        : null}
                                                        </React.Fragment>
                                                    : null}
                                                    <div className="col-md-6 pr-0 mt-2">
                                                        <div className="bg-muted p-2 rounded h-100">
                                                            <h6 className="text-success-50">99.5%</h6>
                                                            <div className="bg-success-3 small-divder"></div>
                                                            <p className="text-success-50 mt-1 mb-1" id="alertReduction">Alert Reduction</p>
                                                            <UncontrolledTooltip placement='top' target="alertReduction">Alert reduction for the remediation is 99.5%.</UncontrolledTooltip>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mt-2">
                                                        <div className="bg-muted p-2 rounded h-100">
                                                            <h6 className="text-dark-theme-purple2">87%</h6>
                                                            <div className="bg-dark-theme-purple2 small-divder"></div>
                                                            <p className="text-dark-theme-purple2 mt-1 mb-1" id="autoRca">Auto RCA</p>
                                                            <UncontrolledTooltip placement='top' target="autoRca">Auto root casuse analysis coverage is 87%.</UncontrolledTooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className={`row mt-3 ${this.state.showTrendDetails ? '' : 'displayNone'}`}>
                                <div className="col-sm-12">
                                    <div className="rounded bg-dark-50 p-3">
                                        <div>
                                            <p className="mb-0"><b>SunBurst</b></p>
                                            <p className="small mb-0">Showing the AIOps Resources</p>
                                            <div className="mt-3" ref={this.dashboardSunBurstRef} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-sm-12">
                                    <div className="rounded bg-dark p-3 h-100">
                                        <p className="mb-0 f16 text-white"><b>Coverage & Distribution</b></p>
                                        {/* <p className="f1 text-white">Showing the case and remediation details</p> */}
                                        <div className="row mt-3">
                                            {this.props.aiopsCoveredResources && Object.entries(this.props.aiopsCoveredResources).length ?
                                                <React.Fragment>
                                                    {this.props.aiopsCoveredResources.total_assets_in_organization ?
                                                    <div className="col-lg-4 col-md-6 col-sm-6 pr-2 transparentTooltip">
                                                        <p className="f12 text-white">Assets Coverage</p>
                                                        {this.props.aiopsCoveredResources.graphData && this.props.aiopsCoveredResources.graphData.series &&  this.props.aiopsCoveredResources.graphData.options ?
                                                            <Chart options={this.props.aiopsCoveredResources.graphData.options} series={this.props.aiopsCoveredResources.graphData.series} type="donut" height={150}/>
                                                        : null
                                                        }
                                                        <div className="bg-success-50 p-2 rounded h-100 displayNone">
                                                            <h6 className="text-success-50">{countFormater(this.props.aiopsCoveredResources.total_assets_in_organization)}</h6>
                                                            <div className="bg-success-2 small-divder"></div>
                                                            <p className="text-success-50 mt-1 mb-1">Total Assets in Organization</p>
                                                            {this.props.aiopsCoveredResources.total_covered_assets ?
                                                                <div>
                                                                    <h6 className="text-success-50">{countFormater(this.props.aiopsCoveredResources.total_covered_assets)}</h6>
                                                                    <div className="bg-success-2 small-divder"></div>
                                                                    <p className="text-success-50 mt-1 mb-1">Total Covered Assets</p>
                                                                </div>
                                                            : null}
                                                            {this.props.aiopsCoveredResources.total_covered_assets ?
                                                                <div>
                                                                    <h6 className="text-success-50">{this.props.aiopsCoveredResources.covered_percentage+'%' }</h6>
                                                                    <div className="bg-success-2 small-divder"></div>
                                                                    <p className="text-success-50">AIOps Coverage</p>
                                                                </div>
                                                            : null}
                                                        </div>
                                                    </div>
                                                : null}
                                                </React.Fragment>
                                            : null}
                                            <div className="col-lg-4 col-md-6 col-sm-6 transparentTooltip borderMiddleBlue-lg">
                                                <p className="f12 text-white">Service with distribution of Cases</p>
                                                {this.props.serviceCasesTotalCount && Object.entries(this.props.serviceCasesTotalCount).length ?
                                                    <Chart options={this.props.serviceCasesTotalCount.options} series={this.props.serviceCasesTotalCount.series} type="donut" height={150}/>
                                                : null
                                                }
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-6 transparentTooltip displayNone">
                                                <p>Manual vs Automated</p>
                                                {this.props.remediationTotalCount && Object.entries(this.props.remediationTotalCount).length ?
                                                    <Chart options={this.props.remediationTotalCount.options} series={this.props.remediationTotalCount.series} type="donut" height={150}/>
                                                : null
                                                }
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-6 align-self-center">
                                                <h5 className="mt-0 d-flex ">{this.props.aiopsDailyTrend.total_count && this.props.aiopsDailyTrend.total_count.remediations && this.props.aiopsDailyTrend.total_count.remediations.total ? countFormater(this.props.aiopsDailyTrend.total_count.remediations.total) : 0}<small className="align-self-center ml-2 text-white">Remediations Completed</small></h5>
                                                <p className="f16 text-white">MTTR ({this.props.aiopsDailyTrend.total_count && this.props.aiopsDailyTrend.total_count.remediations && this.props.aiopsDailyTrend.total_count.remediations.mttr ? this.calculateAverageCompletionTime(this.props.aiopsDailyTrend.total_count.remediations.mttr) : 0})</p>
                                                {this.props.aiopsDailyTrend && Object.entries(this.props.aiopsDailyTrend).length ?
                                                    Object.entries(this.props.aiopsDailyTrend).map(([key, value], index) => {
                                                        return (
                                                        key === 'remediations' ?
                                                            <div className="d-flex py-2">
                                                                <div className="">
                                                                    {this.state[key+'_series'] && [key+'_series'].length && this.state[key+'_options'] ?
                                                                        <React.Fragment>
                                                                        <small className="font-weight-bold text-white">Remediation</small>
                                                                        <div className="ml-n2 transparentTooltip">
                                                                            <Chart options={this.state[key+'_options']} series={this.state[key+'_series']} type="bar" height={20}/>
                                                                        </div>
                                                                        </React.Fragment>
                                                                    :
                                                                        <div className='col-md-12 justify-content-center'>
                                                                            <label className="align-self-center">-</label>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                {/* <p className="mb-0 align-self-center ml-3">{this.props.aiopsDailyTrend.total_count && this.props.aiopsDailyTrend.total_count[key] ? countFormater(this.props.aiopsDailyTrend.total_count[key].total) : 0}</p> */}
                                                            </div>
                                                        : null
                                                        )
                                                    })
                                                : null}
                                                {this.props.aiopsDailyTrend && Object.entries(this.props.aiopsDailyTrend).length ?
                                                    Object.entries(this.props.aiopsDailyTrend).map(([key, value], index) => {
                                                        return (
                                                            key === 'remediations' ?
                                                                this.state[key+'_mttr_series'] && [key+'_mttr_series'].length && this.state[key+'_options'] ?
                                                                    <React.Fragment>
                                                                    <small className="font-weight-bold text-white">MTTR</small>
                                                                    <div className="ml-n2 transparentTooltip">
                                                                        <Chart options={this.state[key+'_mttr_options']} series={this.state[key+'_mttr_series']} type="bar" height={20}/>
                                                                    </div>
                                                                    </React.Fragment>
                                                                :
                                                                    <div className='col-md-12 justify-content-center'>
                                                                        <label className="align-self-center">-</label>
                                                                    </div>
                                                                
                                                            : null
                                                        )
                                                    })
                                                : 
                                                    null
                                                }
                                            </div>
                                        </div>
                                        <div className="dropdown-divider"></div>
                                        {this.props.remediationTrend && Object.entries(this.props.remediationTrend).length ?
                                            <div className="row mt-3">
                                                <div className="col-md-12">
                                                    <p className="mb-0 f16 text-white">Remediations</p>
                                                    <div className="row mt-3">
                                                        <div className="col-lg-3 pr-2">
                                                            <div className="bg-muted rounded d-flex">
                                                                <div className="icon-holder"></div>
                                                                <div className="p-2 w-80">
                                                                    <h6>Today</h6>
                                                                    <div className="ml-icon">
                                                                        <div className="d-flex mt-3">
                                                                            <div className="rounded icon-tile align-self-center">
                                                                                <i className="far fa-exclamation-circle cursorPointer" id="caseInfo"></i>
                                                                                <UncontrolledTooltip placement='top' target="caseInfo">No. of cases attached with remediations for today, yesterday, weekly and monthly</UncontrolledTooltip>
                                                                            </div>
                                                                            <div className="ml-2 align-self-center">
                                                                                <p className="mb-0">
                                                                                    Cases
                                                                                </p>
                                                                                <p className="mb-0">{this.props.remediationTrend.current && this.props.remediationTrend.current.cases ? this.props.remediationTrend.current.cases.count : ''}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex mt-3">
                                                                            <div className="rounded icon-tile align-self-center">
                                                                                <i className="far fa-exclamation-circle cursorPointer" id="remediationInfo"></i>
                                                                                <UncontrolledTooltip placement='top' target="remediationInfo">Total remediations executed for today, yesterday, weekly and monthly</UncontrolledTooltip>
                                                                            </div>
                                                                            <div className="ml-2 align-self-center">
                                                                                <p className="mb-0">
                                                                                    Remediations
                                                                                </p>
                                                                                <p className="mb-0">{this.props.remediationTrend.current && this.props.remediationTrend.current.remediations ? this.props.remediationTrend.current.remediations.count : ''}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex mt-3">
                                                                            <div className="rounded icon-tile align-self-center">
                                                                                <i className="far fa-exclamation-circle cursorPointer" id="mttrInfo"></i>
                                                                                <UncontrolledTooltip placement='top' target="mttrInfo">Mean time to resolution for today, yesterday, weekly and monthly</UncontrolledTooltip>
                                                                            </div>
                                                                            <div className="ml-2 align-self-center">
                                                                                <p className="mb-0">
                                                                                    MTTR
                                                                                </p>
                                                                                <p className="mb-0">{this.props.remediationTrend.current && this.props.remediationTrend.current.mttr ? this.calculateAverageCompletionTime(this.props.remediationTrend.current.mttr.count) : ''}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.props.remediationTrend.past && this.props.remediationTrend.past.map((item, index) => {
                                                            return (
                                                            <div className="col-lg-3 px-2">
                                                                <div className="bg-muted rounded d-flex">
                                                                    <div className="icon-holder"></div>
                                                                    <div className="p-2 w-80">
                                                                        <h6>{item.duration === '1d' ? 'Yesterday' : item.duration === '7d' ? 'Last Week' : item.duration === '30d' ? 'Last Month' : ''}</h6>
                                                                        <div className="ml-icon">
                                                                            <div className="d-flex mt-3">
                                                                                <div className="rounded icon-tile align-self-center">
                                                                                    {item.cases.trend === 'decrease' ?
                                                                                        <i className="far fa-chart-line-down"></i>
                                                                                    : 
                                                                                        <i className="far fa-chart-line"></i>
                                                                                    }
                                                                                </div>
                                                                                <div className="ml-2 align-self-center">
                                                                                    <p className="mb-0">
                                                                                        Cases
                                                                                    </p>
                                                                                    <p className="mb-0">{item.cases.count && item.cases.count !== null ? item.cases.count : 0} </p>
                                                                                </div>
                                                                                <p className={`small m-0 align-self-end ml-auto text-${item.cases.trend === 'increase' ? 'success' : item.cases.trend === 'decrease' ? 'danger': 'success'}`}><span className={`${item.cases.trend === 'increase' ? 'fa fa-arrow-up' : item.cases.trend === 'decrease' ? 'fa fa-arrow-down': ''}`}></span> {MathRound(item.cases.percentage)+'%'}</p>
                                                                            </div>
                                                                            <div className="d-flex mt-3">
                                                                                <div className="rounded icon-tile align-self-center">
                                                                                    {item.remediations.trend === 'decrease' ?
                                                                                        <i className="far fa-chart-line-down"></i>
                                                                                    : 
                                                                                        <i className="far fa-chart-line"></i>
                                                                                    }
                                                                                </div>
                                                                                <div className="ml-2 align-self-center">
                                                                                    <p className="mb-0">
                                                                                        Remediations
                                                                                    </p>
                                                                                    <p className="mb-0">{item.remediations.count && item.remediations.count !== null ? item.remediations.count : 0} </p>
                                                                                </div>
                                                                                <p className={`small m-0 align-self-end ml-auto text-${item.remediations.trend === 'increase' ? 'success' : item.remediations.trend === 'decrease' ? 'danger': 'success'}`}><span className={`${item.remediations.trend === 'increase' ? 'fa fa-arrow-up' : item.remediations.trend === 'decrease' ? 'fa fa-arrow-down': ''}`}></span> {MathRound(item.remediations.percentage)+'%'}</p>
                                                                            </div>
                                                                            <div className="d-flex mt-3">
                                                                                <div className="rounded icon-tile align-self-center">
                                                                                    {item.mttr.trend === 'decrease' ?
                                                                                        <i className="far fa-chart-line-down"></i>
                                                                                    : 
                                                                                        <i className="far fa-chart-line"></i>
                                                                                    }
                                                                                </div>
                                                                                <div className="ml-2 align-self-center">
                                                                                    <p className="mb-0">
                                                                                        MTTR
                                                                                    </p>
                                                                                    <p className="mb-0">{item.mttr.count && item.mttr.count !== null ? this.calculateAverageCompletionTime(item.mttr.count) : '0'}</p>
                                                                                </div>
                                                                                <p className={`small m-0 align-self-end ml-auto text-${item.mttr.trend === 'increase' ? 'success' : item.mttr.trend === 'decrease' ? 'danger': 'success'}`}><span className={`${item.mttr.trend === 'increase' ? 'fa fa-arrow-up' : item.mttr.trend === 'decrease' ? 'fa fa-arrow-down': ''}`}></span> {MathRound(item.mttr.percentage)+'%'}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                        null}
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-sm-12">
                                    <p className="mb-0 f16 text-white"><b>Remediations List</b></p>
                                    {/* <p className="f12 text-white">Showing the Remediations details</p> */}
                                    <div className="rounded bg-dark p-2 my-2 w-100">
                                        {this.props.remediationCount ?
                                            // <Suspense fallback={renderLoader()}>
                                                <ResizeableDarkThemeTable
                                                    columns={[
                                                        {
                                                            Header: 'Remediation',
                                                            accessor: 'title'
                                                        },
                                                        {
                                                            Header: 'No of Executions',
                                                            accessor: 'no_of_times_executed',
                                                        },
                                                        {
                                                            Header: 'Cases Engaged',
                                                            accessor: 'no_of_cases',
                                                            width: 120,
                                                        },
                                                        {
                                                            Header: 'Avg Completion Time',
                                                            accessor: 'avg_completion_time',
                                                            Cell: cellInfo => (<span>{cellInfo.row.original.avg_completion_time ? this.calculateAverageCompletionTime(cellInfo.row.original.avg_completion_time) : ''}</span>)
                                                        },
                                                        {
                                                            Header: 'Last Executed',
                                                            accessor: 'last_run_at',
                                                            Cell: cellInfo => (<span>{cellInfo.row.original.last_run_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.last_run_at, 'DD MMM YYYY HH:mm')  : ''}</span>)
                                                        },
                                                        {
                                                            Header: 'Status',
                                                            accessor: 'Status',
                                                            Cell: cellInfo => (
                                                                <div className="stock-bar">
                                                                    {cellInfo.row.original.no_of_times_executed ?
                                                                        <div className="aiopsStatusDiv">
                                                                            {cellInfo.row.original.status && cellInfo.row.original.status.Success ?
                                                                                <React.Fragment>
                                                                                <div className="aiopsStatus-success" style={{width: parseFloat(cellInfo.row.original.status.Success * 100 / cellInfo.row.original.no_of_times_executed) + '%'}} id={`Success_`+cellInfo.row.original.remediation_id}></div>
                                                                                <UncontrolledTooltip placement='top' target={`Success_`+cellInfo.row.original.remediation_id}>Success {cellInfo.row.original.status.Success}</UncontrolledTooltip>
                                                                                </React.Fragment>
                                                                            : null }
                                                                            {cellInfo.row.original.status && cellInfo.row.original.status.InProgress ?
                                                                                <React.Fragment>
                                                                                <div className="aiopsStatus-warning" style={{width: parseFloat(cellInfo.row.original.status.InProgress * 100 / cellInfo.row.original.no_of_times_executed) + '%'}} id={`InProgress_`+cellInfo.row.original.remediation_id}></div>
                                                                                <UncontrolledTooltip placement='top' target={`InProgress_`+cellInfo.row.original.remediation_id}>InProgress {cellInfo.row.original.status.InProgress}</UncontrolledTooltip>
                                                                                </React.Fragment>
                                                                            : null }
                                                                            {cellInfo.row.original.status && cellInfo.row.original.status.Failed ?
                                                                                <React.Fragment>
                                                                                <div className="aiopsStatus-danger" style={{width: parseFloat(cellInfo.row.original.status.Failed * 100 / cellInfo.row.original.no_of_times_executed) + '%'}} id={`Failed_`+cellInfo.row.original.remediation_id}></div>
                                                                                <UncontrolledTooltip placement='top' target={`Failed_`+cellInfo.row.original.remediation_id}>Failed {cellInfo.row.original.status.Failed}</UncontrolledTooltip>
                                                                                </React.Fragment>
                                                                            : null }
                                                                        </div>
                                                                    : 
                                                                        <div style={{width: '100%'}}></div>
                                                                    }
                                                                </div>
                                                            ),
                                                        }
                                                    ]}
                                                    data={this.props.remediationCount ? this.props.remediationCount : this.props.remediationCount}
                                                    perPage={20}
                                                    dashboard={(this.props.remediationCount ? this.props.remediationCount.length : this.props.remediationCount.length) ? true : false}
                                                    sortByColumn={'no_of_times_executed'}
                                                    riskTooltip={[0]}
                                                    onClickRow={tableRow => {}}
                                                    tableSize={'table-xs'}
                                                />
                                            // </Suspense>
                                        : 
                                            <div className='d-flex justify-content-center m-4'>
                                                <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6 p-0 text-white4'>
                        <div className="detail">
                            <div className="detail-title">
                                <div className="d-flex text-white justify-content-between">
                                    <p className="mb-0 font-weight-bold">Case by Status / Severity</p>
                                    <div className="d-flex">
                                        <p className={`${this.state.caseView !== 'Grid' ? 'text-secondary cursorPointer' : '' } mb-0`} onClick={() => this.setState({ caseView: 'Grid' })}>Grid</p>
                                        <span className="mx-3">|</span>
                                        <p className={`${this.state.caseView !== 'Table' ? 'text-secondary cursorPointer' : '' } mb-0`} onClick={() => this.setState({ caseView: 'Table' })}>Table</p>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-lg-4 align-self-center">
                                        {this.state.statusObject && Object.entries(this.state.statusObject).length ? 
                                            <p className='text-white m-0 displayNone'>Status Count: {this.state.statusObject.statusCount}</p> 
                                        : null}
                                        {this.state.statusObject && Object.entries(this.state.statusObject).length ? 
                                            <div className="d-flex bg-dark-50 p-3 justify-content-between rounded">
                                                {this.state.statusObject.status.map((item, index) => {
                                                    return(
                                                        <div className="mr-1">
                                                            <h6 className="m-0">{item.total_count}</h6>
                                                            <p className="m-0 text-nowrap">{item.status}</p>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        :
                                        null}
                                    </div>
                                    <div className="col-lg-4 align-self-center">
                                        {this.state.severityObject && Object.entries(this.state.severityObject).length ? 
                                            <p className='text-white m-0 displayNone'>Risk Count: {this.state.severityObject.severityCount}</p> 
                                        : null}
                                        <div className="d-flex flex-column">
                                            <div className="d-flex flex-wrap">
                                                {this.state.severityObject && Object.entries(this.state.severityObject).length ? 
                                                    this.state.severityObject.severity.map((item, index) => {
                                                        return(
                                                        <div className="pr-2 mb-1 w-50">
                                                            <div className="bd-highlight d-flex">
                                                                <small className="text-white bd-highlight">{item.severity}</small>
                                                                <small className="text-white bd-highlight ml-auto">{item.total_count}</small>
                                                            </div>
                                                            <div className="progress">
                                                                <div className={`progress-bar ${
                                                                        item.severity ? 
                                                                            'risk-bg-'+item.severity.toLowerCase()
                                                                        : ''
                                                                        }`} 
                                                                    role="progressbar"
                                                                    style={{"width": this.state.severityObject.severityCount ? ((item.total_count/this.state.severityObject.severityCount)*100): 0+'%'}} aria-valuenow="25" aria-valuemin="0"
                                                                    aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                        )
                                                    })
                                                :
                                                null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="detail-content bg-muted p-3">
                                {this.state.caseView === 'Table' ?
                                    <React.Fragment>
                                        <p className="mb-0 f16 text-white"><b>Cases List</b></p>
                                        {/* <p className="mb-1 f12 text-white">Showing the list of cases</p> */}
                                        <div className="row">
                                            <div className={`col-lg-12`}>
                                                <div className="rounded bg-dark-50 p-2 my-2 w-100">
                                                    {!this.props.listCaseDetails || !this.props.listCaseDetails.lenght ?
                                                        <div className={`d-flex justify-content-end`}>
                                                            <div className="form-group mb-0">
                                                                <Search
                                                                    data={this.props.listCaseDetails}
                                                                    applyLiteDarkTags={true}
                                                                    topClassName={'dark-search'}
                                                                    className="form-control-sm text-gray3 bg-muted border-0"
                                                                    hideInputSection={true}
                                                                    searchClassName={'border-0'}
                                                                    searchIconColor={'text-gray3'}
                                                                    filteredData={(filteredArray, searchText) => {
                                                                        let totalPages = Math.ceil(filteredArray.length/this.state.perPage)
                                                                        this.setState({ filteredArray, totalPages, currentPage: 1 })
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    : null}
                                                    {!this.state.caseLoading && this.state.filteredArray && this.state.filteredArray.length ?
                                                        <div className={`font-weight-normal`}>
                                                            <ResizeableDarkThemeTable
                                                                columns={[
                                                                    {
                                                                        Header: ' ',
                                                                        accessor: 'severity',
                                                                        Cell: cellInfo => (
                                                                            <span className={`badge risk-badge-${
                                                                                cellInfo.row.original.severity ?
                                                                                    cellInfo.row.original.severity.toLowerCase()
                                                                                : ''
                                                                                } aiopsTableBadgeWidth`}
                                                                            >
                                                                                {cellInfo.row.original.severity ?
                                                                                    (cellInfo.row.original.severity.toLowerCase() === 'critical' ? 
                                                                                        'C' 
                                                                                    : cellInfo.row.original.severity.toLowerCase() === 'high' ? 
                                                                                        'H'
                                                                                    : cellInfo.row.original.severity.toLowerCase() === 'medium' ? 
                                                                                        'M'
                                                                                    : cellInfo.row.original.severity.toLowerCase() === 'low' ? 
                                                                                        'L' 
                                                                                    : '') 
                                                                                : 'U'}
                                                                            </span>
                                                                        ),
                                                                        width: 50
                                                                    },
                                                                    {
                                                                        Header: 'Date',
                                                                        accessor: 'created_at',
                                                                        Cell: cellInfo => (<span>{cellInfo.row.original.created_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, 'DD MMM YYYY HH:mm')  : ''}</span>),
                                                                    },
                                                                    {
                                                                        Header: 'Title',
                                                                        accessor: 'case_title',
                                                                        width: 220
                                                                    },
                                                                    {
                                                                        Header: 'Id',
                                                                        accessor: 'case_id'
                                                                    },
                                                                    {
                                                                        Header: 'Status',
                                                                        accessor: 'status',
                                                                        Cell: cellInfo => (
                                                                            <span className={`badge badge-${cellInfo.row.original.status ? (cellInfo.row.original.status.toLowerCase() === 'open' ? 'info' : cellInfo.row.original.status.toLowerCase() === 'inprogress' ? 'warning' : (cellInfo.row.original.status.toLowerCase() === 'closed' || cellInfo.row.original.status.toLowerCase() === 'resolved')  ? 'success' : cellInfo.row.original.status.toLowerCase() === 'failed' ? 'danger' : 'secondary') : 'secondary'} aiopsTableBadgeWidth`}>
                                                                                {cellInfo.row.original.status ? 
                                                                                    (cellInfo.row.original.status.toLowerCase() === 'open' ? 'O' : cellInfo.row.original.status.toLowerCase() === 'warning' ? 'P' : cellInfo.row.original.status.toLowerCase() === 'closed' ? 'C' : 
                                                                                    cellInfo.row.original.status.toLowerCase() === 'resolved' ? 'R' : cellInfo.row.original.status.toLowerCase() === 'failed' ? 'F' : 
                                                                                    cellInfo.row.original.status.toLowerCase() === 'inprogress' ? 'IP' :
                                                                                    '')
                                                                                : ''}
                                                                            </span>
                                                                        ),
                                                                        width: 50
                                                                    },
                                                                    {
                                                                        Header: 'Duration',
                                                                        accessor: 'open_for',
                                                                        Cell: cellInfo => (<span>{cellInfo.row.original.open_for ? calculteOpenHours(cellInfo.row.original.open_for) : ''}</span>),
                                                                        width:100,
                                                                    },
                                                                    {
                                                                        Header: 'Alerts/Events',
                                                                        accessor: d => d.total_alerts +' / '+ d.total_events,
                                                                        width:120,
                                                                    },
                                                                    {
                                                                        Header: " ",
                                                                        accessor: "",
                                                                        Cell: ({ cell }) => (
                                                                            <i className="fa fa-external-link"></i>
                                                                        ),
                                                                        width:30,
                                                                    }
                                                                ]}
                                                                data={this.state.filteredArray ? this.state.filteredArray : []}
                                                                perPage={20}
                                                                dashboard={this.state.filteredArray && this.state.filteredArray.length ? true : false}
                                                                riskTooltip={[0]}
                                                                sortByColumn={this.state.sortBy ? this.state.sortBy : false}
                                                                sortByType={this.state.sortType ? (this.state.sortType === 'desc' ? true : false) : true}
                                                                onClickRow={tableRow => {
                                                                    this.getCaseDetails(tableRow)
                                                                }}
                                                                tableSize={'table-xs'}
                                                            />
                                                        </div>
                                                    : this.state.caseLoading ?
                                                        // <div className='d-flex justify-content-center m-4'>
                                                        //     <Spinner className='text-center' color='white' size='lg' />
                                                        // </div>
                                                        null
                                                    :
                                                        <div className='d-flex justify-content-center m-4'>
                                                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                :
                                    <div ref={this.byCaseGridRef}>
                                        <div className={`d-flex justify-content-between`}>
                                            <div className="form-group mb-0">
                                                <p className="mb-0 f16 text-white"><b>Cases List</b></p>
                                                {/* <p className="f12 text-white">Showing the list of cases</p> */}
                                            </div>
                                            {!this.props.listCaseDetails || !this.props.listCaseDetails.lenght ?
                                                <div className={`d-flex justify-content-end`}>
                                                    <div className="form-group mb-2 align-self-center">
                                                        <div className="mr-2 btn-group dropDown" onClick={() => this.setState({ showSortByDropdown: !this.state.showSortByDropdown })}>
                                                            <i className="fas fa-sort f18 cursorPointer"></i>
                                                            <div className={`dropdown-menu dropdown-menu-right bg-gray2 cursorPointer ${this.state.showSortByDropdown ? 'show active' : ''}`}>
                                                                {caseSortOptions.map(item => {
                                                                    return(
                                                                        <React.Fragment>
                                                                        <button className="dropdown-item text-body" type="button" onClick={() => this.caseSortBy(item.value)}>
                                                                            {this.state['sortType_'+item.value] === 'asc' ? 
                                                                                <i className="fas fa-sort-up"><span className="ml-1 font-weight-normal fontFamily">{item.label}</span></i> 
                                                                            : this.state['sortType_'+item.value] === 'desc' ?
                                                                                <i className="fas fa-sort-down"><span className="ml-1 fonyt-weight-normal fontFamily">{item.label}</span></i>
                                                                            : item.label
                                                                            }
                                                                        </button>
                                                                        </React.Fragment>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group mb-0 align-self-center">
                                                        <Search
                                                            data={this.props.listCaseDetails}
                                                            applyLiteDarkTags={true}                                                        
                                                            topClassName={'dark-search'}
                                                            className="form-control-sm text-gray3 bg-dark-50 border-0"
                                                            hideInputSection={true}
                                                            searchClassName={'border-0'}
                                                            searchIconColor={'text-gray3'}
                                                            filteredData={(filteredArray, searchText) => {
                                                                let totalPages = Math.ceil(filteredArray.length/this.state.perPage)
                                                                this.setState({ filteredArray, totalPages, currentPage: 1 })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            : null}
                                        </div>
                                        {!this.state.caseLoading && this.state.filteredArray && this.state.filteredArray.length ?
                                            <div className="row">
                                                {this.state.filteredArray.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map((item, index) => {
                                                    return(
                                                        <div className="col-lg-4 col-md-6 col-sm-6 mb-3 cursorPointer" 
                                                            onClick={() => this.getCaseDetails(item)}
                                                            >
                                                            {/* <a href={"/aiops/case-details/"+item.case_id} target="_blank" className="text-case-color text-decoration-none"> */}
                                                                <div className="p-3 bg-dark rounded">
                                                                    <div className="d-flex justify-content-between">
                                                                        <div className="w-75">
                                                                            <p className="my-0 hiddenEllipses" id={`caseTitleTooltip_`+index}>{item.case_title && item.case_title !== '' ? item.case_title : <span>&nbsp;</span>}</p>
                                                                            <UncontrolledPopover className="dark-theme-popover" trigger="hover" placement="top" target={`caseTitleTooltip_`+index}>
                                                                                <PopoverHeader>
                                                                                    <label className="mb-1 text-white font-weight-bold"> {item.case_title ? item.case_title : ''}</label>
                                                                                </PopoverHeader>
                                                                                <PopoverBody>
                                                                                    {/* <label className="mb-1 f16 border-bottom-white1 text-white font-weight-bold"> {item.case_title ? item.case_title : ''}</label> */}
                                                                                    {item.description ? 
                                                                                        <div className="mt-1">
                                                                                            <p className="mb-1 f14 text-white font-weight-bold">Description:<span
                                                                                            className="f12 ml-2">{item.description}</span></p>
                                                                                        </div>
                                                                                    : null}
                                                                                </PopoverBody>
                                                                            </UncontrolledPopover>
                                                                        </div>
                                                                        <span className={`badge ${item.severity ? 'risk-badge-'+item.severity.toLowerCase() : 'badge-secondary'} align-self-start aiopsBadgeWidth`} >{item.severity ? item.severity : 'Unknown'}</span>
                                                                    </div>
                                                                    <div className="w-100">
                                                                        <p className="mb-0 f10 hiddenEllipses" id={`caseIdTitleTooltip_`+index}>{item.case_id && item.case_id !== '' ? '#'+item.case_id : <span>&nbsp;</span>}</p>
                                                                        <UncontrolledTooltip placement='top' className="text-gray3" target={`caseIdTitleTooltip_`+index}>{item.case_id ? item.case_id : ''}</UncontrolledTooltip>
                                                                        <p className="mb-0 f10">{item.created_at ? momentConvertionUtcToLocalTime(item.created_at, 'DD MMM YYYY HH:mm') : ''}<span className="mx-2">|</span><span className={`text-${item.status.toLowerCase() === 'open' ? 'info' : item.status.toLowerCase() === 'inprogress' ? 'warning' : (item.status.toLowerCase() === 'closed' || item.status.toLowerCase() === 'resolved') ? 'success' : item.status.toLowerCase() === 'failed' ? 'danger' : 'secondary'}`}>{item.status ? item.status : ' '}</span></p>
                                                                    </div>
                                                                    <div className="d-flex bd-highlight mt-2">
                                                                        <div className="flex-fill bd-highlight">
                                                                            <p className="my-0">{item.total_alerts}</p>
                                                                            <p className="mb-0 small">Alerts</p>
                                                                        </div>
                                                                        <div className="flex-fill bd-highlight">
                                                                            <p className="my-0">{item.total_assets}</p>
                                                                            <p className="mb-0 small">Assets</p>
                                                                        </div>
                                                                        <div className="flex-fill bd-highlight">
                                                                            <p className="my-0">{item.total_events}</p>
                                                                            <p className="mb-0 small">Events</p>
                                                                        </div>
                                                                        <div className="flex-fill bd-highlight">
                                                                            <p className="my-0">{item.open_for ? calculteOpenHours(item.open_for) : '-'}</p>
                                                                            <p className="mb-0 small">Duration</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            {/* </a> */}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        : this.state.caseLoading ?
                                            // <div className='d-flex justify-content-center m-4'>
                                            //     <Spinner className='text-center' color='white' size='lg' />
                                            // </div>
                                            null
                                        : !this.state.caseLoading && this.state.filteredArray && !this.state.filteredArray.length ?
                                            <div className='d-flex justify-content-center m-4 text-gray3'>
                                                <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                            </div>
                                        : null}	
                                        
                                        {this.state.filteredArray && this.state.filteredArray.length > this.state.perPage ?
                                            <div className="pagination">
                                                <span className="mx-3">Page <strong>{this.state.currentPage} of {Math.ceil(this.state.totalPages)}</strong> </span>
                                                <button className={`${this.state.currentPage <= 1 ? 'disabled text-muted' : 'text-gray3'}`} onClick={() => this.navigatePage('start', this.state.currentPage)}><i className="fal fa-arrow-to-left"></i></button> 
                                                <button className={`${this.state.currentPage <= 1 ? 'disabled text-muted' : 'text-gray3'}`} onClick={() => this.navigatePage('previous', this.state.currentPage)}><i className="fal fa-angle-left"></i></button> 
                                                <button className={`${this.state.currentPage !== this.state.totalPages ? 'text-gray3' : 'disabled text-muted'}`}><i className="fal fa-angle-right" onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
                                                <button className={`${this.state.currentPage !== this.state.totalPages ? 'text-gray3' : 'disabled text-muted'}`}><i className="fal fa-arrow-to-right" onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
                                            </div>
                                        : null}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
Dashboard.propTypes = {
    listCasesWithDetails: PropTypes.func,
    getAiopsCoveredResources: PropTypes.func,
    getAiopsRemediationEstimate: PropTypes.func,
    getCasesTotalCount: PropTypes.func,
    getAiopsDailyTrend: PropTypes.func,
    setAiopsPropsDetails: PropTypes.func,
    getRemediationCount: PropTypes.func,
    getTotalRemediationCount: PropTypes.func,
    getAiopsRemediationTrend: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('Aiops dashbaord',state)
	return {
        aiopsPropsDetails: state.aiops.aiopsPropsDetails ? state.aiops.aiopsPropsDetails : {},
        listCaseDetails: state.aiops.listCaseDetails.results ? state.aiops.listCaseDetails.results : [],
        remediationCount: state.aiops.remediationCount && state.aiops.remediationCount.results ? state.aiops.remediationCount.results : [],
        remediationTrend: state.aiops.remediationTrend.results ? state.aiops.remediationTrend.results : {},
        remediationTotalCount: state.aiops.remediationTotalCount,
        serviceCasesTotalCount: state.aiops.serviceCasesTotalCount,
        accountCasesTotalCount: state.aiops.accountCasesTotalCount,
        regionCasesTotalCount: state.aiops.regionCasesTotalCount,
        resourceCasesTotalCount: state.aiops.resourceCasesTotalCount,
        severityCasesTotalCount: state.aiops.severityCasesTotalCount,
        statusCasesTotalCount: state.aiops.statusCasesTotalCount,
        aiopsDailyTrend: state.aiops.aiopsDailyTrend,
        aiopsCoveredResources: state.aiops.aiopsCoveredResources.results ? state.aiops.aiopsCoveredResources.results : {},
        aiopsRemediationEstimate: state.aiops.aiopsRemediationEstimate,
        
	}
}

export default AppWrapper(Dashboard, mapStateToProps, {
  listCasesWithDetails,
  getAiopsCoveredResources,
  getAiopsRemediationEstimate,
  getCasesTotalCount,
  getAiopsDailyTrend,
  setAiopsPropsDetails,
  getRemediationCount,
  getTotalRemediationCount,
  getAiopsRemediationTrend,
})