/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Inventory.js
 * @author Prakash // on 18/06/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 * 
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { listAllAssets } from '../../../actions/governance/governanceAction'
import { getCoveredServices } from '../../../actions/detectors/detectorsAction'

import { UncontrolledTooltip, Spinner } from 'reactstrap'
import { momentDateGivenFormat, subHours, subDays, getDayFromSelectedDuration, momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord } from '../../../utils/utility'

import _ from 'lodash'
import { CSVLink } from "react-csv";

import Search from '../../common/SearchComponent'
import SearchWithHiddenInput from '../../common/SearchWithHiddenInput'

const durationOptions = [    
    { value: '+1h', option: 'Last 1 hour' },
    { value: '+6h', option: 'Last 6 hours' },
    { value: '+12h', option: 'Last 12 hours' },
    { value: '+3h', option: 'Last 3 hours' },
    { value: '+24h', option: 'Last 24 hours' }
]

class Inventory extends Component {
    constructor(props) {
        super(props)
        this.accountRef = React.createRef()
        this.regionRef = React.createRef()
        this.toggleRef = React.createRef()
        
        this.state = {
            showLoading: true,

            //filter section start

            selectedProvider: "",

            selectedAccount: [],
            isAccountOpen: false,

            selectedRegion: [],
            isRegionOpen: false,

            selectedDuration: "+3h",

            //filter section end

            catServiceType: ["Category", "Services"],
            selectedCatSer: "Services", 

            startRecord: 0, 
            currentPage: 1,
            totalPages: 1,
            perPage: 100,
            changesInFilter: true,
            filteredAssetList: []
        }
    }

    componentDidMount = () => {
        // if(this.props.dashboardDetails && Object.entries(this.props.dashboardDetails).length) {
        //     this.setState({ 
        //         start_time: this.props.dashboardDetails.start_time,
        //         end_time: this.props.dashboardDetails.end_time,
        //         brushStartTime: this.props.dashboardDetails.brushStartTime ? this.props.dashboardDetails.brushStartTime : this.props.dashboardDetails.start_time,
        //         brushEndTime: this.props.dashboardDetails.brushEndTime ? this.props.dashboardDetails.brushEndTime : this.props.dashboardDetails.end_time,
        //         selectedProvider: this.props.dashboardDetails.selectedProvider,
        //         selectedAccount: this.props.dashboardDetails.selectedAccount,
        //         selectedRegion: this.props.dashboardDetails.selectedRegion,
        //         selectedDuration: this.props.dashboardDetails.selectedDuration,
        //         showLoading: true,
        //         selectedGroup: "",
        //         groupOptions: [],
        //     },
        //         () => {
        //             this.getCoveredServices()
        //         }
        //     )
        // }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.dashboardDetails && Object.entries(this.props.dashboardDetails).length) {
            if(!_.isEqual(prevProps.dashboardDetails, this.props.dashboardDetails)) {
                this.setState({ 
                    start_time: this.props.dashboardDetails.start_time,
                    end_time: this.props.dashboardDetails.end_time,
                    brushStartTime: this.props.dashboardDetails.brushStartTime ? this.props.dashboardDetails.brushStartTime : this.props.dashboardDetails.start_time,
                    brushEndTime: this.props.dashboardDetails.brushEndTime ? this.props.dashboardDetails.brushEndTime : this.props.dashboardDetails.end_time,
                    selectedProvider: this.props.dashboardDetails.selectedProvider,
                    selectedAccount: this.props.dashboardDetails.selectedAccount,
                    selectedRegion: this.props.dashboardDetails.selectedRegion,
                    selectedDuration: this.props.dashboardDetails.selectedDuration,
                    showLoading: true,
                    selectedGroup: "",
                    groupOptions: [],
                },
                    () => {
                        this.getCoveredServices()
                    }
                )
            }
        }
    }

    getCoveredServices = () => {
        let params = {}
        params.provider = this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider.toLowerCase() : ""
        if(this.state.selectedAccount && this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
            params.region = this.state.selectedRegion
        }
        let aggregateBy = "resource_type"
        if(this.state.selectedCatSer === "Category") {
            aggregateBy = "category"
        }
        params.aggregate_by = [aggregateBy, "service_name"]
        params.assets_present = true
        params.radial = true
        
        if(params.provider !== "") {
            this.props.getCoveredServices(params, (promise, response) => {
                if(promise) {
                    let results = response.results && response.results.length ? response.results : []
                    let selectedCatService = results.length && results[0] ? results[0] : {}
                    let selectedService = selectedCatService.data && Object.entries(selectedCatService.data).length ? Object.keys(selectedCatService.data)[0] : ""

                    let selectedCategory = results.length && results[0] ? results[0].resource_type : ""
                    if(this.state.selectedCatSer === "Category") {
                        selectedCategory = results.length && results[0] ? results[0].category : ""
                    }
                    
                    this.setState({ 
                        categoryList: results,
                        filterCategoryList: results,
                        selectedCategory,
                        selectedCatService,
                        selectedService,
                        showLoading: false,
                        assetListLoader: results.length ? true : false,
                    },
                        () => {
                            if(this.state.categoryList.length) {
                                this.listAllAssets()
                            }
                        }
                    )
                } else {
                    this.setState({ categoryList: [], filterCategoryList: [], showLoading: false })
                }
            })
        } else {
            this.setState({ categoryList: [], filterCategoryList: [], showLoading: false })
        }
    }

    onClickCategory = (item) => {
        let selectedCategory = item.category ? item.category : item.resource_type

        let selectedService = item.data && Object.entries(item.data).length ? Object.keys(item.data)[0] : ""

        this.setState({ selectedCategory, selectedCatService: item, selectedService, assetsList: [], filteredAssetList: [], assetListLoader: true },
            () => {
                if(this.state.selectedService !== "") {
                    this.listAllAssets()
                }
            }
        )
    }

    listAllAssets = () => {
        let params = {}
        params.provider = this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider.toLowerCase() : ""
        if(this.state.selectedAccount && this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
            params.region = this.state.selectedRegion
        }
        params.service_name = this.state.selectedService

        if(this.state.selectedService && this.state.selectedService !== "") {
            this.props.listAllAssets(params, (promise, response) => {
                if(promise) {
                    let results = response.results && response.results.length ? response.results : []
    
                    let totalPages = 1
                    if(results && results.length > this.state.perPage) {
                        totalPages = Math.ceil(results.length / this.state.perPage)
                    }
    
                    this.setState({ assetsList: results, filteredAssetList: results, totalPages, currentPage: 1, startRecord: 0, assetListLoader: false },
                        () => this.formHeaders()    
                    )
                } else {
                    this.setState({ assetsList: [], filteredAssetList: [], assetListLoader: false })
                }
            })
        } else {
            this.setState({ assetsList: [], filteredAssetList: [], assetListLoader: false })
        }
    }	

	formHeaders = () => {
		let dataResult = this.state.filteredAssetList && this.state.filteredAssetList.length ? this.state.filteredAssetList : []
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === "string") {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})
			this.setState({ headers })
		}
	}
    
    handleMultiSelectChange = (field, arrayValue) => {
        let value = arrayValue.map(item => item.value)

        let selectedValue = []
        if(field === 'selectedAccount') {
            let prevState = this.state[field]
            if(value.includes('All')) {
                if(!prevState.includes('All')) {
                    this.state.accounts.forEach(acc => {
                        selectedValue.push(acc.account_id)
                    })
                } else {
                    const index = value.indexOf('All');
                    if (index > -1) {
                        value.splice(index, 1);
                    }
                    selectedValue = value
                }
            } else if(!prevState.includes('All')) {
                selectedValue = value
            }

            if(selectedValue.length && !selectedValue.includes('All')) {
                if(!selectedValue.includes('All') && selectedValue.length === (this.state.accounts.length -1)) {
                    selectedValue.push('All')
                }
            }	
            
            this.setState({ [field]: selectedValue, changesInFilter: true })		
        } else if(field === 'selectedRegion') {
            let prevState = this.state[field]
            if(value.includes('All')) {
                if(!prevState.includes('All')) {
                    this.state.regions.forEach(reg => {
                        selectedValue.push(reg.region)
                    })
                } else {
                    const index = value.indexOf('All');
                    if (index > -1) {
                        value.splice(index, 1);
                    }
                    selectedValue = value
                }
            } else if(!prevState.includes('All')) {
                selectedValue = value
            }

            if(selectedValue.length && !selectedValue.includes('All')) {
                if(!selectedValue.includes('All') && selectedValue.length === (this.state.regions.length -1)) {
                    selectedValue.push('All')
                }
            }
            
            this.setState({ [field]: selectedValue, changesInFilter: true })
        } 
    }
    
    getMultiSelectedCount = (type, array) => {
        return array.length && array.includes('All') ? 'All' : (array.length ? array.length +' Selected' : 'All')
    }

    durationFunction = (type) => {
        let duration = this.state.selectedDuration
        let period = duration.substring(duration.length, duration.length-1)
        let durationBtw = getDayFromSelectedDuration(duration)
        let sliderStartDate = ""
        if(period === "d") {
            sliderStartDate = momentDateGivenFormat(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
        } else {
            sliderStartDate = momentDateGivenFormat(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
        }
        let sliderEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')

        this.setState({ sliderStartDate, sliderEndDate, changesInFilter: true },
            () => {
                if(type === "onLoad") {
                    this.onSearch('onLoad')
                }
            }
        )
    }

    handleClickOutside = (event) => {
        // if(!this.state.showQueryResults) {

        //     if (this.accountRef && !this.accountRef.current.contains(event.target)) {
        //         this.setState({ isAccountOpen: false })
        //     } else {
        //         this.setState({ isAccountOpen: true })
        //     }

        //     if (this.regionRef && !this.regionRef.current.contains(event.target)) {
        //         this.setState({ isRegionOpen: false })
        //     } else {
        //         this.setState({ isRegionOpen: true })
        //     }            
        // }
        if (this.toggleRef && !this.toggleRef.current.contains(event.target)) {
            this.setState({ showServiceTypeOptions: false })
        } else {
            this.setState({ showServiceTypeOptions: !this.state.showServiceTypeOptions })
        }
    } 

    navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = this.state.startRecord
            if(action === 'next' && currentPage !== this.state.totalPages) {
                startRecord = startRecord + this.state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - this.state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = this.state.totalPages
                startRecord = (this.state.totalPages - 1) * this.state.perPage
            }

            this.setState({ currentPage, startRecord })
        } 
    }

    gotoPage = (pageNumber) => {
        if(pageNumber > 0  && pageNumber <= this.state.totalPages) {
            let startRecord = (pageNumber - 1) * this.state.perPage
            this.setState({ currentPage: pageNumber, pageNumber, startRecord })
        } else  if(pageNumber > this.state.totalPages) {
            this.setState({ pageNumber: this.state.currentPage })  
        } else {
            this.setState({ pageNumber })
        }
    }    

    getAssetDetails = (item) => {
        this.setState({ 
            selectedAssetId: item.asset_id === this.state.selectedAssetId ? "" : item.asset_id, 
            selectedAsset: item.asset_id === this.state.selectedAssetId ? "" : item,
            expandAssetDetails: item.asset_id === this.state.selectedAssetId ? false : true,
        })
    }

    render() {
        return (
            <div className="container-fluid overflow-auto flex-grow-1 bg-muted" onClick={ (event) => { this.handleClickOutside(event) } }>
                <div className={`loaderOverlay ${this.state.showLoading ? "" : 'displayNone'}`}>
                    <div className="overlayEqualizerLoader">
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                    </div>
                </div>
                <div className='row h-100'>
                    <div className='col-sm-12 p-0'>
                        <div className='p-2 overflow-x-hidden'>
                            <div className="row">
                                <div className={`col-sm-12`}>
                                    <div class="bg-dark rounded">
                                        <div className="col-sm-12 d-flex justify-content-between p-2">
                                            <div className="d-flex">
                                                <div className="select-sm-bg curvedDropdown minWidth120" ref={this.toggleRef}>
                                                    <div className="dropdown">
                                                        <span className="dropdown-toggle d-flex justify-content-between px-2 text-white">
                                                            {this.state.selectedCatSer ? this.state.selectedCatSer : <span className="placeholder">Select</span>}
                                                        </span>
                                                        <ul className={`dropdown-menu p-0 ${this.state.showServiceTypeOptions ? "show" : ''}`}>
                                                            {this.state.catServiceType.map(item => {
                                                                return (
                                                                    <li 
                                                                        onClick={() => {
                                                                            this.setState({ 
                                                                                selectedCatSer: item, 
                                                                                showServiceTypeOptions: false,
                                                                                categoryList: [],
                                                                                filterCategoryList: [],
                                                                                emptySearchText: true,
                                                                                selectedCatService: [],
                                                                                assetListLoader: true 

                                                                                // showLoading: true
                                                                            },
                                                                                () => this.getCoveredServices()
                                                                            )
                                                                        }}
                                                                        className={`${this.state.selectedCatSer === item ? "selected" :  ""}`}
                                                                    >
                                                                        {item}
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                                {this.state.categoryList && this.state.categoryList.length ?
                                                    <SearchWithHiddenInput
                                                        data={this.state.categoryList ? this.state.categoryList : []}
                                                        applyLiteDarkTags={true}
                                                        searchClassName={"border-0"}
                                                        searchIconColor={"text-gray3"}
                                                        className="form-control-sm text-gray3 bg-muted border-0"
                                                        hideInputSection={true}
                                                        searchPlaceHolder={'Search....'}
                                                        topClassName={"dark-search align-self-center ml-2"}
                                                        filteredData={(filterCategoryList, searchText) => {
                                                            this.setState({ filterCategoryList, emptySearchText: false })
                                                        }}
                                                        resetSearchBox={this.state.emptySearchText}
                                                    />
                                                : null}
                                            </div>
                                            <CSVLink 
												data={this.state.filteredAssetList} 
												headers={this.state.headers ? this.state.headers : []}
												filename={"resource-inventory.csv"}
												className={"align-self-center"}
												target="_blank"
											>
												<i className="fas fa-download text-primary-color"></i>
												{/* <span className="text-primary-color mx-2">csv</span> */}
											</CSVLink>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-1 pr-0 border-right-info">
                                                <div class="bg-dark2 w-100 overflowHidden-y-scroll minMaxHeight820">
                                                    {this.state.filterCategoryList && this.state.filterCategoryList.length ?
                                                        this.state.filterCategoryList.map((item, i) => {
                                                            return (
                                                            <div className={`px-2 cursorPointer ${i ? "border-top" : ""} ${this.state.selectedCatService && this.state.selectedCatService.category === (item.category || item.resource_type) ? 'text-info disabled' : ''}`} onClick={() => this.onClickCategory(item)}>
                                                                <div className="hiddenEllipses d-flex" id={"category_"+i}>
                                                                    <p className={`m-0 f-20 pl pr-1 pt-2 pb-2 ${this.state.selectedCategory && this.state.selectedCategory === (item.category || item.resource_type) ? 'text-info' : 'text-white'}`}>{item.category ? item.category : item.resource_type}</p>
                                                                </div>
                                                                <UncontrolledTooltip placement='top' target={"category_"+i}>
                                                                    <div className="text-left">  
                                                                        <p className="mb-1 text-primary-color"><span className="text-white mr-1">{this.state.selectedCatSer}: </span>{(item.category ? item.category : item.resource_type) + (item.total ? " ("+item.total+") " : "")}</p>
                                                                        <p className="mb-1 text-white">Assets: </p>
                                                                        {item.data && Object.entries(item.data).length ?
                                                                            Object.entries(item.data).map(([key, value]) => {
                                                                                return(
                                                                                    <p className="mb-1 text-primary-color">{key+" : "+value}</p>
                                                                                )
                                                                            })
                                                                        : null}
                                                                    </div>    
                                                                </UncontrolledTooltip>
                                                            </div>
                                                            )
                                                        })
                                                    : null}
                                                </div>
                                            </div>
                                            <div className="col-sm-2 p-0 border-right-info">
                                                <div class="bg-dark3 w-100 overflowHidden-y-scroll minMaxHeight820">
                                                    {this.state.selectedCatService && this.state.selectedCatService.data ?
                                                        Object.entries(this.state.selectedCatService.data).map(([key, value], i) => {
                                                            return (
                                                            <div className={`px-2 cursorPointer ${i ? "border-top" : ""} ${this.state.selectedService === key ? 'text-info disabled' : ''}`} onClick={() => this.setState({ assetListLoader: true, selectedService: key }, () => this.listAllAssets())}>
                                                                <div className="hiddenEllipses d-flex" id={"services_"+i}>
                                                                    <p className={`m-0 f-20 pl pr-1 pt-2 pb-2 ${this.state.selectedService === key ? 'text-info' : 'text-white'}`}>{key + (value ? " ("+value+") " : "")}</p>
                                                                </div>
                                                                <UncontrolledTooltip placement='top' target={"services_"+i}>
                                                                    <div className="text-left">  
                                                                        <p className="mb-1 text-primary-color"><span className="text-white mr-1">Asset: </span>{key + (value ? " ("+value+") " : "")}</p>
                                                                    </div>    
                                                                </UncontrolledTooltip>
                                                            </div>
                                                            )
                                                        })
                                                    : null}
                                                </div>
                                            </div>
                                            <div className="col-sm-9 pl-0">
                                                {this.state.assetListLoader ? 
                                                    <div className='d-flex justify-content-center m-4'>
                                                        <Spinner className='text-center' color='white' size='lg' />
                                                    </div>
                                                : 
                                                    this.state.filteredAssetList ?
                                                        <div class="bg-dark3 w-100">
                                                            <div className="d-flex justify-content-between mb-1 pt-2 bg-dark">
                                                                <p className="mb-0 ml-2 align-self-center">Showing {this.state.filteredAssetList && this.state.filteredAssetList.length} of total {this.state.assetsList && this.state.assetsList.length} assets in <span className="text-info">{this.state.selectedService}</span></p>
                                                                <div className="d-flex justify-content-end w-60">
                                                                    {this.state.filteredAssetList && this.state.filteredAssetList.length > this.state.perPage ?
                                                                        <div className="mr-3 pagination errorPagePagination f12 mt-n1">
                                                                            <span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
                                                                            <button><i className={`fal fa-arrow-to-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('start', this.state.currentPage)}></i></button> 
                                                                            <button><i className={`fal fa-angle-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('previous', this.state.currentPage)}></i></button> 
                                                                            <button><i className={`fal fa-angle-right cursorPointer ${!this.state.nextErrorListToken && this.state.nextErrorListToken ==="" && this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
                                                                            <button><i className={`fal fa-arrow-to-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
                                                                        </div>
                                                                    : null}
                                                                    {this.state.assetsList && this.state.assetsList.length ?
                                                                        <Search
                                                                            data={this.state.assetsList ? this.state.assetsList : []}
                                                                            applyTags={false}
                                                                            applyLiteDarkTags={true}
                                                                            topClassName={'bg-black5 mr-1 border-gray5 rounded-5 f12 align-self-center w-40'}
                                                                            searchClassName={'px-2 f12'}
                                                                            searchIconColor={'text-gray5 f12'}
                                                                            searchPlaceHolder={'Search assets....'}
                                                                            className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                                                            filteredData={(filteredAssetList) => {
                                                                                let totalPages = 1
                                                                                if(filteredAssetList && filteredAssetList.length > this.state.perPage) {
                                                                                    totalPages = Math.ceil(filteredAssetList.length / this.state.perPage)
                                                                                }
                    
                                                                                this.setState({ filteredAssetList, totalPages, currentPage: 1, startRecord: 0 })
                                                                            }}
                                                                        />
                                                                    : null}                                                                
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="overflowHidden-y-scroll minMaxHeight770">
                                                                {this.state.filteredAssetList && this.state.filteredAssetList.length ?
                                                                    this.state.filteredAssetList.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map((item, i) => {
                                                                        return(
                                                                            <React.Fragment>
                                                                            <div className={`p-2 ${i ? "border-top-black5" : ""} ${this.state.selectedAssetId && this.state.selectedAssetId === item.asset_id ? "bg-muted" : ""}`}> 
                                                                                <div className="d-flex cursorPointer" onClick={() => this.getAssetDetails(item)}>
                                                                                    <p className="mb-0">
                                                                                    <span className="mb-0 small mr-1 text-primary-color">Resource</span>
                                                                                        {item.account_id ?
                                                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">{' '+item.account_id}</span>
                                                                                        : null}
                                                                                        {item.region ?
                                                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+item.region}</span>
                                                                                        : null}
                                                                                        {item.resource_type ?
                                                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+item.resource_type}</span>
                                                                                        : null}
                                                                                        {item.service_name ?
                                                                                            <span className="mb-0 f12 text-primary-color font-weight-bold mr-2">{' : '+item.service_name}</span>
                                                                                        : null}
                                                                                        {item.id_name_map ?
                                                                                            <React.Fragment>
                                                                                            <span className="mb-0 mr-2 f12 text-purple-2 font-weight-bold">
                                                                                                {item.id_name_map.value}
                                                                                            </span>
                                                                                            {item.id_name_map.key ?
                                                                                                <span className="mb-0 mr-2 f12 text-purple-2 font-weight-bold">
                                                                                                    {item.id_name_map.key}
                                                                                                </span>
                                                                                            : null}
                                                                                            </React.Fragment>
                                                                                        : item.asset_name ?
                                                                                            <span className="mb-0 mr-2 f12 text-purple-2 font-weight-bold">{item.asset_name}</span>
                                                                                        : null}
                                                                                        {item.asset_id ?
                                                                                            <span className="mb-0 mr-2 f12 text-primary-color font-weight-bold">{item.asset_id}</span>
                                                                                        : null}
                                                                                        {item.asset_arn && item.asset_arn !== item.asset_name ?
                                                                                            <span className="mb-0 mr-2 f12 text-primary-color font-weight-bold">{item.asset_arn}</span>
                                                                                        : null}                
                                                                                    </p>
                                                                                </div>
                                                                                {this.state.selectedAssetId && this.state.selectedAssetId === item.asset_id ?
                                                                                    <div className="rounded bg-dark3 p-3">
                                                                                        <div className="accordion" id="accordionExample">
                                                                                            <div className={`card bg-dark border-0 mb-2`}>
                                                                                                <div id={'heading_config_assets'} onClick={() => this.setState({ expandAssetDetails: !this.state.expandAssetDetails })}>
                                                                                                    <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                                                                                        <div className="text-white"><span className={`far ${this.state.expandAssetDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Asset Details</div>
                                                                                                        {/* <div className="text-info">Showing asset details</div> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div id={'collapse_config_assets'} className={`collapse ${this.state.expandAssetDetails ? 'show' : ""}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                                                                                    <div className="card-body">
                                                                                                        <div className="d-flex mb-2">
                                                                                                            <div className="py-1 w-33">
                                                                                                                <p className="b-block mb-0">Name</p>
                                                                                                                <p className="mb-0 text-white">{this.state.selectedAsset.asset_name ? this.state.selectedAsset.asset_name : ""}</p>
                                                                                                            </div>
                                                                                                            <div className="py-1 w-33 pl-3">
                                                                                                                <p className="b-block mb-0">Id</p>
                                                                                                                <p className="mb-0 text-white">{this.state.selectedAsset.asset_id ? this.state.selectedAsset.asset_id : ""}</p>
                                                                                                            </div>
                                                                                                            <div className="py-1 w-33 pl-3">
                                                                                                                <p className="b-block mb-0">ARN</p>
                                                                                                                <p className="mb-0 text-white">{this.state.selectedAsset.asset_arn ? this.state.selectedAsset.asset_arn : ""}</p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="d-flex mb-2 border-top">
                                                                                                            <div className="py-1 w-33">
                                                                                                                <p className="b-block mb-0">Resource</p>
                                                                                                                <p className="mb-0 text-white">
                                                                                                                    {this.state.selectedAsset.provider ? this.state.selectedAsset.provider.toUpperCase() : ""}
                                                                                                                    {this.state.selectedAsset.account_id ? (" : ")+this.state.selectedAsset.account_id : ""}
                                                                                                                    {this.state.selectedAsset.region ? (" : ")+this.state.selectedAsset.region : ""}
                                                                                                                </p>
                                                                                                            </div>
                                                                                                            <div className="py-1 w-33 pl-3">
                                                                                                                <p className="b-block mb-0">Services</p>
                                                                                                                <p className="mb-0 text-white">
                                                                                                                    {this.state.selectedAsset.resource_type ? this.state.selectedAsset.resource_type : ""}
                                                                                                                    {this.state.selectedAsset.service_name ? (" : ")+this.state.selectedAsset.service_name : ""}
                                                                                                                </p>
                                                                                                            </div>
                                                                                                            <div className="py-1 w-33 pl-3">
                                                                                                                <p className="b-block mb-0">Created At</p>
                                                                                                                <p className="mb-0 text-white">
                                                                                                                    {this.state.selectedAsset.created_at ? momentConvertionUtcToLocalTime(this.state.selectedAsset.created_at, 'DD MMM YYYY HH:mm') : ""}
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className="d-flex mb-2 border-top">
                                                                                                            <div className="py-1 w-33">
                                                                                                                <p className="b-block mb-0">Created By</p>
                                                                                                                <p className="mb-0 text-white">
                                                                                                                    {this.state.selectedAsset.created_by ? this.state.selectedAsset.created_by : "-"}
                                                                                                                </p>
                                                                                                            </div>
                                                                                                            <div className="py-1 w-33 pl-3">
                                                                                                                <p className="b-block mb-0">Modified At</p>
                                                                                                                <p className="mb-0 text-white">
                                                                                                                    {this.state.selectedAsset.modified_at ? momentConvertionUtcToLocalTime(this.state.selectedAsset.modified_at, 'DD MMM YYYY HH:mm') : this.state.selectedAsset.last_modified_at ? momentConvertionUtcToLocalTime(this.state.selectedAsset.last_modified_at, 'DD MMM YYYY HH:mm') : ""}
                                                                                                                </p>
                                                                                                            </div><div className="py-1 w-33 pl-3">
                                                                                                                <p className="b-block mb-0">Modified By</p>
                                                                                                                <p className="mb-0 text-white">
                                                                                                                    {this.state.selectedAsset.modified_by ? this.state.selectedAsset.modified_by : "-"}
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="d-flex mb-2 border-top">
                                                                                                            <div className="py-1 w-50">
                                                                                                                <p className="b-block mb-0">Category:</p>
                                                                                                                <div className={`d-flex flex-wrap`}>
                                                                                                                    {this.state.selectedAsset.category && Array.isArray(this.state.selectedAsset.category) ?
                                                                                                                        this.state.selectedAsset.category.map(cat => {
                                                                                                                            return (
                                                                                                                                <small className={`mr-1 mb-1 badge badge-light`}>{cat}</small>
                                                                                                                            )
                                                                                                                        })
                                                                                                                    : 
                                                                                                                        <small className={`mr-1 mb-1 badge badge-light`}>{this.state.selectedAsset.category}</small>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="py-1 w-50 pl-3">
                                                                                                                <p className="b-block mb-0">Tags:</p>
                                                                                                                {this.state.selectedAsset.tags && this.state.selectedAsset.tags.map(tag => {
                                                                                                                    return (
                                                                                                                        <small className={`mr-1 mb-1 badge badge-light text-left whiteSpaceNoarmal`}>{tag.key+': '+tag.value}</small>
                                                                                                                    )
                                                                                                                })}
                                                                                                                {/* <ul className='tags my-1 px-3'>
                                                                                                                    {this.state.selectedAsset.tags && this.state.selectedAsset.tags.map((tag, index) => {
                                                                                                                        return (
                                                                                                                            <li key={index}>
                                                                                                                                <span className='tag f14'>{tag.key+': '+tag.value}</span>
                                                                                                                            </li>
                                                                                                                        )
                                                                                                                    })}
                                                                                                                </ul> */}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                : null}
                                                                            </div>
                                                                            </React.Fragment>
                                                                        )
                                                                    })
                                                                :
                                                                    <div className='d-flex justify-content-center m-4'>
                                                                        <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
Inventory.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log('observability landing page',state)
    return {
        services: state.filters.services,
        dashboardDetails: state.assets.resourcesPropsDetails && state.assets.resourcesPropsDetails.dashboardDetails ? state.assets.resourcesPropsDetails.dashboardDetails : {},
        tagKeys: state.filters.tagKeys,
    }
}

export default connect(mapStateToProps, {
    getCoveredServices,
    listAllAssets,
})(withRouter(Inventory))