/*************************************************
 * Tvastar
 * @exports
 * @file ViewSidePanel.js
 * @author Prakash // on 18/05/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _, { filter } from 'lodash'
// import PropTypes from 'prop-types'
import { Spinner, Input } from 'reactstrap'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

import { getAllTagsValues } from '../../../actions/commonAction'
import { getBudgetDetails, getCostTotalCount } from '../../../actions/cost/CostAction'

import { addDays, addMonths, currentLocaltime, currentStartofYear, getAccountNameFromId, getRegionName, momentDateGivenFormat } from '../../../utils/utility'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'
import ApexHorizontalStackedBar from '../../common/charts/ApexHorizontalStackedBar'
import ExpandedDarkThemeTable from '../../designComponents/Table/ExpandedDarkThemeTable'
import ApexDonutChart from '../../common/charts/ApexDonutChart'
import ApexStackedBarChart from '../../common/charts/ApexStackedBarChart'
// import ApexBoxplotChart from '../../common/charts/ApexBoxplotChart'

class ViewSidePanel extends Component {
	constructor(props) {
		super(props)
        this.toggleRef = React.createRef()
		this.state = {
            minMaxHeight: 700,
			showDetails: true,
			forecastOptions: [
				{label: "Monthly", value: "monthly"},
				{label: "Quarterly", value: "quarterly"},
				{label: "Half Yearly", value: "half-yearly"},
				{label: "Annual", value: "yearly"},
			],
			selectedProvider: this.props.selectedDetails.provider,
			selectedAccount: this.props.selectedDetails.account_id,
			selectedRegion: this.props.selectedDetails.region,
			selectedTags: this.props.selectedDetails && this.props.selectedDetails.tags ? this.props.selectedDetails.tags : [],
			selectedPeriod: this.props.selectedDetails.period,
			selectedDetails: this.props.selectedDetails,
			groupBy: [
				{label: "Service", value: "Service"},
				{label: "Service Api", value: "Service Api"}
			],
			selectedGroupBy: "Service"
		}
	}

	componentDidMount = () => {
		document.addEventListener('keydown', this._handleKeyDown)
		this.getWindowSize()
		window.addEventListener('resize', () => {
            this.getWindowSize()
        })

		this.getFilters()
		this.formTrendData()
		this.formTableData()
		this.stackedBarTrendGraph()
    }

	getFilters = () => {
		if(this.props.providers && this.props.providers.length) {
			this.setState({ providers: this.props.providers })
		}
		if(this.props.accounts && this.props.accounts.length) {
			this.setState({ accounts: this.props.accounts.filter(e => e.provider === this.props.selectedProvider) })
		}
		if(this.props.regions && this.props.regions.length) {
			this.setState({ regions: this.props.regions })
		}
	}

	formTableData = () => {
		let costBreakup = this.state.selectedDetails && this.state.selectedDetails.cost_breakup ? this.state.selectedDetails.cost_breakup : []

		let tableData = []
		costBreakup.forEach(row => {
			row.subRows = row.cost_breakup
			tableData.push(row)
		})

		this.setState({ tableData, selectedTableRecord: tableData[0], loadingDonut: true },
			() => {
				this.getCostTotalCount()
			}
		)
	}

	formTrendData = () => {
		let trendData = {}
		
		let costBreakup = this.state.selectedDetails && this.state.selectedDetails.cost_breakup ? this.state.selectedDetails.cost_breakup : []

		let budget = []
		let forecast = []
		let spend = []
		let variance = []
		let categories = []
		costBreakup.forEach(row => {
			let date = ""
			if(row.start_time) {
				date += momentDateGivenFormat(row.start_time, "MMM")
			}
			if(row.end_time) {
				date += " - "+momentDateGivenFormat(row.end_time, "MMM")
			}
			categories.push(date)
			budget.push(row.budget)
			forecast.push(row.forecast)
			spend.push(row.spend)
			variance.push(row.variance)
		})		

		trendData = {
			"budget": budget,
			"forecast": forecast,
			"spend": spend,
			// "variance": variance
		}

		this.setState({ categories, trendData })
	}

	getBudgetDetails = () => {let params = {}
		if(this.state.selectedDetail && this.state.selectedDetail.provider) {
			params.provider = this.state.selectedDetails.provider
		}
		params.period = this.state.selectedPeriod
		if(this.state.selectedDetails && this.state.selectedDetails.account_id) {
			params.account_id = this.state.selectedDetails.account_id
		}
		if(this.state.selectedDetails && this.state.selectedDetails.region) {
			params.region = this.state.selectedDetails.region
		}
		if(this.state.selectedDetails.tags && this.state.selectedDetails.tags.length) {
			params.tags = this.state.selectedDetails.tags
		}

		if(this.state.changesInFilter) {
			this.props.getBudgetDetails(params, (promise, response) => {
				if(promise) {
					this.setState({ 
						selectedDetails: response.results && response.results.length ? response.results[0] : {}, 
						showLoading: false,
						selectedProvider: response.results && response.results.length && response.results[0].provider ? response.results[0].provider : "",
						selectedAccount: response.results && response.results.length && response.results[0].account_id ? response.results[0].account_id : "",
						selectedRegion: response.results && response.results.length && response.results[0].region ? response.results[0].region : "",
						selectedTags: response.results && response.results.length && response.results[0].tags ? response.results[0].tags : [],
						changesInFilter: false,
						showCostGraph: false
					},
						() => {
							this.formTableData()	
							this.formTrendData()
							this.stackedBarTrendGraph()
						}
					)
				} else {
					this.setState({ showLoading: false, changesInFilter: false, showCostGraph: false })
				}
			})
		}
	}

	getCostTotalCount = () => {
		let params = {}
		params.provider = this.state.selectedDetails.provider
		params.account_id = this.state.selectedDetails.account_id
		if(this.state.selectedDetails.region) {
			params.region = this.state.selectedDetails.region
		}
		if(this.state.selectedDetails.tags && this.state.selectedDetails.tags.length) {
			params.tags = this.state.selectedDetails.tags
		}
		params.start_time = this.state.selectedTableRecord.start_time
		params.end_time = this.state.selectedTableRecord.end_time
		params.period = this.state.selectedPeriod
		params.radial = false
		// params.top = 4

		let aggregateBy = ["resource_type"]
		if(this.state.selectedGroupBy !== "Service") {
			aggregateBy = ["operation"]
		}
		params.aggregate_by = aggregateBy
		
		this.props.getCostTotalCount(params, (promise, response) => {
			if(promise) {
				this.setState({ costTotalCount: response },
					() => this.getDonutGraphDetails()	
				)
			}
		})
	}
	
    getDonutGraphDetails = () => { 
        //services Donut start
        let services = []
        let services_total_count = 0
        if(this.state.costTotalCount && this.state.costTotalCount.results) {
			let results = this.state.costTotalCount.results
			results = _.orderBy(results, ['cost'], ['desc'])
			if(this.state.groupType && this.state.groupType !== "All") {
				results = results.slice(0, this.state.groupType)
			}

			results.forEach(row => {
				let donutRow = {}
				donutRow.item_count = row.cost
				donutRow.item_name = row.resource_type
				services.push(donutRow)
				services_total_count += row.cost 
			})
		}

        // services = _.orderBy(services, ['item_count'], ['desc'])
		// if(this.state.groupType && this.state.groupType !== "All") {
		// 	services = services.slice(0, 5)
		// }

        let serviceDonutData = {
            "items": services,
            "label": "Cost",
            "total_count": services_total_count
        }

        //services Donut start

        this.setState({ serviceDonutData, showDonut: true, loadingDonut: false })
    }

	getAllTagsValues = () => {
		let params = {}
		params.tags = [{ "key": this.state.tag_key }]
		this.props.getAllTagsValues(params, (promise, response) => {
			if(promise) {
				this.setState({ tagValues: response },
					() => {
						if(this.state.tagsSelected && this.state.tagsSelected.filter(e => e.key === this.state.tag_key).length) {
							let value = this.state.tagsSelected.filter(e => e.key === this.state.tag_key).map(e => e.value)
							this.handleMultiSelectChange("tag_value", value, this.state.tagValues)
						}
					}	
				)
			}
		})
	}

	addNewTag = () => {
		let tagsSelected = this.state.tagsSelected ? this.state.tagsSelected : []

		if(this.state.tag_key && this.state.tag_key !== "") {
			let filteredTags = tagsSelected.filter(e => e.key !== this.state.tag_key)
			this.state.tag_value.forEach(row => {
				let dataRow = {
					key: this.state.tag_key,
					value: row,
				}
				filteredTags.push(dataRow)
			})

			this.setState({ tagsSelected: filteredTags, tag_key: "",  tag_value: "", tagValues: [], changesInFilter: true })
		}
	}

	removeTagSection = (tag) => {
		let filteredResult = this.state.tagsSelected.filter(e => e !== tag)
		this.setState({ tagsSelected: filteredResult })
	}

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
        let headerHeihgt = 100
		let outerHeight = (window.outerHeight - browserBorder - headerHeihgt)

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }

	_handleKeyDown = event => {
		if(this.state.onSaveFunction) {
			switch (event.keyCode) {
				case 27:
					this.props.closeSidePanel()
					break
				default:
					break
			}
		}
	}

    handleClickOutside(event) {}

	getAllTagsKeys = () => {
		
		if(!this.props.tagKeys || !this.props.tagKeys.length) {
			let params = {}
			// params.account_id = "265469181489"
			// params.region = "us-east-2"
			this.props.getAllTagsKeys(params, (promise, response) => {})
		}
	}

	getAllTagsValues = () => {
		let params = {}
		params.tags = [{ "key": this.state.tag_key }]
		this.props.getAllTagsValues(params, (promise, response) => {
			if(promise) {
				let result = response
				if(response.length) {
					result.unshift("All");
				}
				this.setState({ tagValues: response })
			}
		})
	}
	
	handleMultiSelectChange = (field, arrayValue, state) => {
		let value = arrayValue.map(item => item.value ? item.value : item)
		let selectedValue = []
		let prevState = this.state[field] ? this.state[field] : []
		if(prevState.length === 1 && prevState[0] === "All") {
			prevState = []
		}
		if(value.includes("All")) {
			if(!prevState.includes("All")) {
				if(state.filter(e => e.value).length) {
					selectedValue = state.map(e => e.value)
				} else {
					selectedValue = state
				}
			} else {
				const index = value.indexOf("All");
				if (index > -1) {
					value.splice(index, 1);
				}
				selectedValue = value
			}
		} else if(!prevState.includes("All")) {
			selectedValue = value
		}

		if(selectedValue.length && !selectedValue.includes("All")) {
			if(!selectedValue.includes("All") && selectedValue.length === (state.length -1)) {
				selectedValue.push("All")
			}
		}
		
		this.setState({ [field]: selectedValue })
	}
	
	getMultiSelectedCount = (type, array) => {
		return array.length && array.includes("All") ? "All" : (array.length ? array.length +" Selected" : <span className="placeholder w-100 align-self-center">Select</span>)
	}
	
	handleInputChange = (label, value) => {
		let obj = this.props.selectedItem
		// let payload = ""
		// if(value !== "") {
		// 	payload = JSON.parse(JSON.stringify(value))

		// 	console.log("payload", value)
		// 	console.log("payload", value)
		// }
		obj[label] = value

		this.setState({ [label]: value })

		this.props.setCostPropsDetails('selectedItem', obj)
	}

	onFinish = () => {
		let params = this.props.selectedItem

		this.props.startPowerTuning(params, (promise, response) => {
			let messageType = ''
			let message = ''
			let createErrorMessage = ""
			if(response) {
				if(response.status) {
					messageType = 'success'
					message = 'Power tuning stared successfully'
				} else {
					messageType = 'danger'
					message = 'Power tuning not started'
					createErrorMessage = response.error

				}
			} else {
				messageType = 'danger'
				message = 'Power tuning not started'
			}
		})
	}

	addNewActions = () => {
		let alarm_actions = this.props.selectedItem && this.props.selectedItem.alarm_actions ? this.props.selectedItem.alarm_actions : []

		if(this.state.alarmActions && this.state.alarmActions !== "") {
			if(!alarm_actions.includes(this.state.alarmActions)) {
				alarm_actions.push(this.state.alarmActions)
			}
		}

		this.setState({ alarm_actions, alarmActions: "" },
			() => this.handleInputChange("alarm_actions", alarm_actions)
		)
	}

	removeActionsSection = (row) => {
		// filteredResult = this.state.selectedTags.filter(e => e.key !== tag.key && e.value !== tag.value)
		let filteredResult = this.props.selectedItem.alarm_actions.filter(e => e !== row)
		this.handleInputChange("alarm_actions", filteredResult)
	}	

    stackedBarTrendGraph = () => {
        let costDetails = this.state.selectedDetails && this.state.selectedDetails.cost ? this.state.selectedDetails.cost : []
		let budgetAmt = this.state.selectedDetails && this.state.selectedDetails.budget ? this.state.selectedDetails.budget : 0
        
		console.log("costDetails", costDetails)
        let graphData = {}
        if(costDetails) {
			let labels = []
			let cost = []
			let foreCast = []
			for(let i=0;i<=12;i++){
				if(i<12) {
					labels.push(momentDateGivenFormat(addMonths(currentStartofYear(), i), "MMM"))
					cost.push(costDetails[i] ? costDetails[i] : 0)
					if(budgetAmt) {
						let value = budgetAmt-(costDetails[i] ? costDetails[i] : 0)
						if(value < 0) {
							value = 0
						}
						foreCast.push(value)
					}
				}
			}
			graphData.labels = labels
			graphData.cost = cost
			graphData.forecast = foreCast
        }
		
        this.setState({ costForecastData: graphData, showCostGraph: true })
    }
	
	render() {		
		return (
			<div className="advanced-search" onClick={(event) => this.handleClickOutside(event)}>
				<div className={`loaderOverlay ${this.state.queryLoading ? "" : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
				<div className="search-content bg-muted w-80">
					<div className="header-search bd-highlight d-flex justify-content-between">
						<div className="flex-fill bd-highlight">
							<h5>Budget Forecast Details</h5>
							<div className="mr-2 d-flex">
								<div className="containerFilterSection d-flex justify-content-end mt-2">
									<div className="mr-4">
										<div className="d-flex justify-content-between">
											<label className="align-self-center m-0">Provider </label>
										</div>
										<Select
											placeholder={'Select'}
											isSearchable={false}
											// menuIsOpen={true}
											className={`selectOptionsm f13 p-0`}
											components={{
												IndicatorSeparator: () => null
											}}
											value={({
												value: this.state.selectedProvider,
												label: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider.toUpperCase() : <span className="placeholder">Select</span>
											})}
											options={{
												value: this.state.selectedProvider,
												label: this.state.selectedProvider,
											}}
										/>
									</div>

									<div className="mr-4">
										<div className="d-flex justify-content-between">
											<label className="align-self-center m-0">Account </label>
										</div>
										<Select
											placeholder={'Select'}
											isSearchable={false}
											// menuIsOpen={true}
											className={`selectOptionsm f13 p-0`}
											components={{
												IndicatorSeparator: () => null
											}}
											value={({
												value: this.state.selectedAccount,
												label: this.state.selectedAccount && this.state.selectedAccount !== "" ? getAccountNameFromId(this.state.selectedAccount, this.props.accounts) : <span className="placeholder">Select</span>
											})}
											options={{
												value: this.state.selectedAccount,
												label: this.state.selectedAccount,
											}}
										/>
										{/* <div className={`multiSelectOptionSm align-self-end`}>
											<ReactMultiSelectCheckboxes
												placeholderButtonLabel="All"
												getDropdownButtonLabel={() => this.getMultiSelectedCount("selectedAccount", this.state.selectedAccount ? this.state.selectedAccount : [])}
												options={{
													value: row.account_id,
													label: row.account_name,
												}}
												onChange={arr => { this.handleMultiSelectChange("selectedAccount", arr ? arr : [], this.state.regions)}}
												value={this.state.selectedAccount && this.state.selectedAccount.map(row => ({
													value: row
												}))}
											/>
										</div> */}
									</div>

									<div className="mr-4">
										<div className="d-flex justify-content-between">
											<label className="align-self-center m-0">Region </label>
										</div>										
										<Select
											placeholder={'Select'}
											isSearchable={false}
											// menuIsOpen={true}
											className={`selectOptionsm f13 p-0`}
											components={{
												IndicatorSeparator: () => null
											}}
											value={({
												value: this.state.selectedRegion,
												label: this.state.selectedRegion && this.state.selectedRegion !== "" ? getRegionName(this.state.selectedRegion, this.props.regions) : <span className="placeholder">Select</span>
											})}
											options={{
												value: this.state.selectedRegion,
												label: this.state.selectedRegion,
											}}
										/>
										{/* <div className={`multiSelectOptionSm align-self-end`}>
											<ReactMultiSelectCheckboxes
												placeholderButtonLabel="All"
												getDropdownButtonLabel={() => this.getMultiSelectedCount("selectedRegion", this.state.selectedRegion ? this.state.selectedRegion : [])}
												options={this.state.regions && this.state.regions.map(row => ({
													value: row.region,
													label: row.name,
												}))}
												onChange={arr => { this.handleMultiSelectChange("selectedRegion", arr ? arr : [], this.state.regions)}}
												value={this.state.selectedRegion && this.state.selectedRegion.map(row => ({
													value: row
												}))}
											/>
										</div> */}
									</div>

									<div className="mr-4" ref={this.tagsRef}>
										<div className="" onClick={() => this.setState({ showTagFilter: true })}>
											<label className="mb-0">Tags</label>
											<p className='mb-0 STDborderBottom mt-3' style={{ minWidth: "150px" }}>
												{!this.state.selectedTags || (this.state.selectedTags && !this.state.selectedTags.length) ?
													<span>All</span>
												: this.state.selectedTags && this.state.selectedTags.length ?
													<span>{this.state.selectedTags.length} selected</span>
												: null}
											</p>
										</div>
										{this.state.showTagFilter ?
											<div className="costPopupTagSection border mt-1" style={{ right: "20%" }}>
												<div className="p-3 bg-dark3">
													<div className={`w-100 mt-3`}>
														<p className="mb-2 w-100 text-white">Tags</p>
													</div>
													{this.state.selectedTags && this.state.selectedTags.length ? 
														this.state.selectedTags.map((tag, index) => {
															return(
																<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">{tag.key +" : "+tag.value}</span>
															)
														})
													: null}
												</div>
											</div>
										: null}
									</div>
									
									<div className="mr-4">
										<div className="d-flex justify-content-between">
											<label className="align-self-center m-0">Period</label>
										</div>
										<Select
											placeholder={'Select'}
											isSearchable={false}
											// menuIsOpen={true}
											className={`selectOptionsm f13 p-0`}
											components={{
												IndicatorSeparator: () => null
											}}
											value={({
												value: this.state.selectedPeriod,
												label: this.state.selectedPeriod ? this.state.forecastOptions.filter(e => e.value === this.state.selectedPeriod)[0].label : <span className="placeholder">Select</span>
											})}
											options={this.state.forecastOptions && this.state.forecastOptions.map(row => ({
												value: row.value,
												label: row.label,
											}))}
											onChange={event => this.setState({ changesInFilter: true, selectedPeriod: event.value })}
										/>
									</div>
									
									<div className={`align-self-center`}>
										<div className="btn-group" role="group" aria-label="Basic example">
											<button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-right px-2 py-1`} onClick={() => this.setState({ showLoading: true }, () => this.getBudgetDetails() )}>Search</button>
											<button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-left px-2 py-1`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="text-right flex-fill bd-highlight align-self-center">
							<i className={`far fa-times cursorPointer ${this.state.onSaveFunction ? "disbaled" : ""}`} onClick={() => this.props.closeSidePanel()}></i>
						</div>
					</div>
					<div className={`${!this.state.showLoading ? 'overflow-auto' : ''} p-2`}>
						<div className="dlpDescriptionForm overflowYAuto" style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
							{this.state.createErrorMessage && this.state.createErrorMessage !== "" ?
								<div className="p-3">
									<p  className="text-danger">{this.state.createErrorMessage}</p>
								</div>
							: null}
							<div className="p-3 rounded bg-dark">
								<div className="d-flex justify-content-between">
									<h5 className="mb-2 cursorPointer d-flex">Details</h5>
									
									{/* <span className={`ml-4 fa fa-angle-${this.state.showDetails ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.setState({ showDetails: !this.state.showDetails })}></span> */}
								</div>
								{/* {this.state.tableData ?
									<div className='col-sm-12'>
										<ApexBoxplotChart 
											graphData={this.state.tableData}
										/>
									</div>
								: null} */}
									{this.state.showCostGraph && this.state.costForecastData ?
										<div className='col-sm-12'>
											<ApexStackedBarChart
												graphData={this.state.costForecastData}
												sparkline={false}
												yaxis={false}
												yaxisLabel={true}
												xaxis={true}
												xaxisFormat={'categoryString'}
												xaxisLabel={true}
												axisLabelFontSize={'9px'}
												axisLabelColor={'#B8BBBE'}
												paddingLeft={-25}
												legend={false}
												stacked={true}
												height={120}
												horizontal={false}
												barHeight={'40%'}
												barEndShape={'flat'}
												columnWidth={'25%'}
												gradient={true}
												gradientColor={['#039BE5', '#5F5BA2']}
												hideTooltipValue={true}
												backgroundBarShape={'flat'}
												backgroundBarColors={['#333947']}
												showBackgroundBarColors={true}
												className={"transparentTooltip"}
												colors={["#9A77D1", "#2D323E"]}
											/>
										</div>
									: null}
									{this.state.showLoading ? 
										<div className='d-flex justify-content-center m-4'>
											<Spinner className='text-center' color='white' size='lg' />
										</div> 
									:
										<div className='d-flex justify-content-between'>
											<div className='w-60 d-flex'>
												{this.state.tableData ?
													<ExpandedDarkThemeTable
														data={this.state.tableData ? this.state.tableData : []}
														perPage={20}
														dashboard={this.state.tableData && this.state.tableData.length ? true : false}
														sortByColumn={''}
														selectedPeriod={this.state.selectedPeriod}
														riskTooltip={[]}
														onClickRow={tableRow => {
															this.setState({ selectedTableRecord: tableRow, showDonut: false, loadingDonut: true },
																() => {
																	this.getCostTotalCount()
																}	
															)
														}}
														// tableSize={'table-xs'}
													/>
												: null}												
											</div>
											<div className='w-40'>
												<div className='d-flex justify-content-end'>
													<div className={`d-flex ml-1 mr-4`}>
														<p className="m-0 align-self-end small mr-2">Show </p>
														<div class={`transparentSelectBorderBottom align-self-end`}>
															<select class="form-control" onChange={event => this.setState({ groupType: event.target.value, showDonut: false }, () => this.getDonutGraphDetails() )} value={this.state.groupType}>
																<option value="All">ALL</option>
																<option value="10">Top 10 Metic</option>
															</select>
														</div>
														<p className="m-0 mx-2 align-self-end small">resources </p>
													</div>
													<div className="select-sm-bg curvedDropdown minWidth120">
														<div className="dropdown">
															<span className="dropdown-toggle d-flex justify-content-between px-2 text-white" onClick={() => this.setState({ serviceFilter: !this.state.serviceFilter })}>
																{this.state.selectedGroupBy ? this.state.groupBy.filter(e => e.value === this.state.selectedGroupBy)[0].label : <span className="placeholder">Status</span>}
																<span className="caret"></span>
															</span>
															<ul className={`dropdown-menu p-0 ${this.state.serviceFilter ? "show" : ''}`}>
																{this.state.groupBy.map(item => {
																	return (
																		<li 
																			onClick={() => this.setState({ 
																				selectedGroupBy: item.value, 
																				serviceFilter: false,
																				showDonut: false
																			}, 
																				() => this.getCostTotalCount()
																			)}
																		>
																			{item.label}
																		</li>
																	)
																})}
															</ul>
														</div>
													</div>
												</div>
												<div className='mx-2 mt-4'>
													{!this.state.loadingDonut ?
														this.state.showDonut && this.state.serviceDonutData ?
															<div className='d-flex justify-content-center'>
																<ApexDonutChart
																	graphData={this.state.serviceDonutData}
																	legend={false}
																	showTotal={true}
																	showTotalLable={true}
																	showGraphHeading={false}
																	height={250}
																	width={250}
																	size={'70%'}
																	gradient={false}
																	gradientColor={['#A88CCC', '#D88ACF']}
																	colors={['#A88CCC', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7']}
																	className={"transparentTooltip"}
																/>
															</div>
														: null
													: this.state.loadingDonut ?
														<div className='d-flex justify-content-center m-4'>
															<Spinner className='text-center' color='white' size='lg' />
														</div> 
													: null}
												
													{this.state.trendData1 ?
														<ApexHorizontalStackedBar
															graphData={this.state.trendData ? this.state.trendData : {}}
															categories={this.state.categories}
															sparkline={false}
															yaxis={false}
															yaxisLabel={true}
															xaxis={true}
															xaxisFormat={'date'}
															xaxisLabel={true}
															axisLabelColor={'#B8BBBE'}
															paddingLeft={-25}
															legend={false}
															stacked={false}
															height={300}
															// width={40}
															horizontal={true}
															barHeight={'40%'}
															barEndShape={'flat'}
															columnWidth={'25%'}
															gradient={true}
															gradientColor={['#039BE5', '#5F5BA2']}
															hideTooltipValue={true}
															backgroundBarShape={'flat'}
															backgroundBarColors={['#333947']}
															showBackgroundBarColors={false}
															className={"transparentTooltip"}
														/>
													: null}
												</div>
											</div>
										</div>
									}
								<div className='mt-4'>
									<button className={`btn btn-secondary ${this.state.onSaveFunction ? "disbaled" : ""}`} onClick={() => this.props.closeSidePanel()} >Cancel</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ViewSidePanel.propTypes = {}

const mapStateToProps = state => {
	// console.log('csot power tuning viewsidepanel',state)
	return {
		providers: state.filters.providers,
		accounts: state.filters.accounts,
		regions: state.filters.regions && state.filters.regions[0] && state.filters.regions[0].provider_regions ? state.filters.regions[0].provider_regions :[],
		tagKeys: state.filters.tagKeys
	}
}

export default connect(mapStateToProps, {
	getAllTagsValues,
	getBudgetDetails,
	getCostTotalCount
})(withRouter(ViewSidePanel))