/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Watermark.js
 * @author Prakash // on 16/09/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Spinner } from 'reactstrap'
import { 
    listWatermark,
    deleteWatermark
} from '../../../../actions/dlp/WatermarkAction'

import { momentConvertionUtcToLocalTime } from '../../../../utils/utility'

import Search from '../../../common/SearchComponent'

import { store as CommonNotification } from 'react-notifications-component';

import ResizeableTable from '../../../designComponents/Table/ResizeableTable'

class Watermark extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showWatermarkLoading: true
        }
    }
    
    componentDidMount = () => {	
        if(!this.props.dlpConfigWatermarklist || !this.props.dlpConfigWatermarklist.length) {
            this.listWatermark()
        } else {
            this.setState({ showWatermarkLoading: false })
        }
    }

    listWatermark = () => {
        this.setState({ showWatermarkLoading: true }, 
            () => { 
                let params = {}
                this.props.listWatermark(params, (promise, response) => {
                    this.setState({ showWatermarkLoading: false })
                })
            }
        )
    }

    deleteConfirmation = (watermark_id, watermark_name) => {
        if (window.confirm('Are you sure, you want to delete the watermark "'+watermark_name+'" ?')) {
            this.setState({ showDeleteLoading: true },
                () => this.deleteWatermark(watermark_id)
            )
        }
    }

    deleteWatermark = (watermark_id) => {
        let params = {}
        params.watermark_id = [watermark_id]

        this.props.deleteWatermark(params, (promise, response) => {
            if(promise) {
                this.setState({ showDeleteLoading: false },
                    () => this.listWatermark()
                )
                let messageType = 'danger'		
                let message = 'Watermark deleted'
                CommonNotification.addNotification({
                    message: message,
                    type: messageType,
                    insert: "top",
                    container: "top-center",
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                })
            }
        })
    }

    render() {
        return(
            !this.state.showWatermarkLoading ?
                <React.Fragment>
                    <div className="row"> 
                        <div className="col-sm-12">
                            <h6 className="font-weight-normal">Watermark</h6>
                            <p>Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim.</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <select className="custom-select w-auto mr-3">
                                <option selected>Filter</option>
                                <option value="1">InActive</option>
                                <option value="2">Active</option>
                            </select>
                            {/* <select className="custom-select w-auto">
                                <option selected>Provider</option>
                                <option value="aws">AWS</option>
                                <option value="gcp">GCP</option>
                                <option value="azure">AZURE</option>
                            </select> */}
                        </div>
                        <div className='col-sm-6 d-flex justify-content-end'>
                            {this.props.dlpConfigWatermarklist && this.props.dlpConfigWatermarklist.length ?
                                <div className="form-group mb-0">
                                    <Search
                                        data={this.props.dlpConfigWatermarklist}
                                        applyTags={false}
                                        applyLiteDarkTags={true}
                                        topClassName={'bg-white'}
                                        searchClassName={'textboxBorder'}
                                        searchIconColor={'text-dark'}
                                        className={"bg-transparent textboxBorder"}
                                        filteredData={(filteredArray) => {
                                            this.setState({ filteredList: _.orderBy(filteredArray, ['date'], ['desc']) })
                                        }}
                                    />
                                </div>
                            : null}
                            <span className='btn btn-primary cursorPointer text-center ml-2 mb-2' onClick={() => this.props.onClickAction({}, 'create')}>Add Watermark</span>
                        </div>
                    </div>
                    {this.props.dlpConfigWatermarklist ?
                        <ResizeableTable
                            columns={[
                                {
                                    Header: 'Date',
                                    accessor: d => d.modified_at ? d.modified_at : d.created_at,
                                    Cell: cellInfo => (<span>{cellInfo.row.original.modified_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.modified_at, 'DD MMM YYYY HH:mm') : (cellInfo.row.original.created_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, 'DD MMM YYYY HH:mm')  : '')}</span>)
                                },
                                {
                                    Header: 'Title',
                                    accessor: 'watermark_name',
                                    width: 200	
                                },
                                {
                                    Header: 'Tags',
                                    // accessor: 'data_association',
                                    Cell: cellInfo => (<span>{cellInfo.row.original.tags ? cellInfo.row.original.tags.join(', ') : ''}</span>),
                                    width: 300	
                                },
                                {
                                    Header: 'Description',
                                    accessor: 'description',
                                    width: 500
                                },
                                {
                                    Header: 'Action',
                                    Cell: cellInfo => (
                                        <div className="d-flex justify-content-between align-self-center">
                                            <p className="text-dark m-0 p-0 cursorPointer">
                                                <i className="fal fa-book-open" onClick={() => this.props.onClickAction(cellInfo.row.original, 'view')}></i>
                                            </p>
                                            <p className="text-dark m-0 p-0 cursorPointer">
                                                <i className="fal fa-user-edit" onClick={() => this.props.onClickAction(cellInfo.row.original, 'edit') }></i>
                                            </p>
                                            <p className="text-dark m-0 p-0 cursorPointer">
                                                {this.state.showDeleteLoading && cellInfo.row.original.watermark_id === this.state.selectedWatermarkId ? 
                                                    <Spinner color='dark' size='sm' />
                                                :
                                                    <i className="fal fa-trash" onClick={() => this.setState({ selectedWatermarkId: cellInfo.row.original.watermark_id }, () => this.deleteConfirmation(cellInfo.row.original.watermark_id, cellInfo.row.original.watermark_name))}></i>
                                                }
                                            </p>
                                    </div>
                                ),
                                width:120
                            }
                        ]}
                        data={this.state.filteredList ? this.state.filteredList : this.props.dlpConfigWatermarklist}
                        perPage={10}
                        dashboard={this.state.filteredList ? this.state.filteredList.length : this.props.dlpConfigWatermarklist.length}
                        sortByColumn={'Date'}
                        tooltipFor={[3]}
                        onClickRow={tableRow => {}}
                        tableSize={'table-striped'}
                    />
                    :
                        <div className='d-flex justify-content-center m-4'>
                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                        </div>
                    }
                </React.Fragment>
            : this.state.showWatermarkLoading ?
                <div className='d-flex justify-content-center m-4'>
                    <Spinner color='dark' size='lg' />
                </div>
            :
                <div className='d-flex justify-content-center m-4'>
                    <p>There are no data on this criteria. Please try adjusting your filter.</p>
                </div>
        )
    }
}
    

/**
 * Type of the props used in the component
 */
Watermark.propTypes = {
	listWatermark: PropTypes.func,
	deleteWatermark: PropTypes.func
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
 const mapStateToProps = state => {
    return {
		dlpConfigWatermarklist: state.dlp.dlpConfigWatermarklist
	}
}
export default connect(mapStateToProps, {
	listWatermark,
	deleteWatermark
})(withRouter(Watermark))