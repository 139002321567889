
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file RunDiagnostics.js
 * @author Prakash // on 17/02/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Select from 'react-select';
import { Input } from 'reactstrap'
import { setAutomationPropsDetails } from '../../actions/commonAction'
import { listDiagnosticChecks, listDiagnosticTemplate } from '../../actions/aiops/DiagnosticsAction'

import { getRegionName, getAccountNameFromId, momentDateGivenFormat, subDays, currentLocaltime, } from '../../utils/utility'
import { HOURLY_DURATION } from '../../utils/constants'

import { UncontrolledTooltip } from 'reactstrap'

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";

const disableFutureDt = current => {
	return current.isBefore(currentLocaltime())
}

const startInputProps = {
    placeholder: 'Start Datetime',
    className: 'form-control bg-black5 text-white border-0',
    readOnly: true,
    disabled: true
};

const endInputProps = {
    placeholder: 'End Datetime',
    className: 'form-control bg-black5 text-white border-0',
    readOnly: true,
    disabled: true
};

class RunDiagnostics extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            activeSection: this.props.activeSection,
            durationOptions: ["Minutes", "Hour", "Day", "Month"],
            selectedPeriod: "Minutes",
            previousAutomations: [],
            logGroupLoader: true,

            tagsArray: [0],
			selectedTags: [],
            
			allChecks: true,
        }

		this.handleStartTimeChange  = this.handleStartTimeChange.bind(this)
        this.handleEndTimeChange    = this.handleEndTimeChange.bind(this)
    }

    componentDidMount = () => {        
        this.listDiagnosticChecks()
        if(this.props.automationPropsDetails) {
            let obj = this.props.automationPropsDetails
            let exist = obj.actions.filter(e => e.section === this.state.activeSection)

            if(exist.length) {
                let input = exist[0].input ? exist[0].input : []
                input.forEach(item => {
                    if(item.key === "tags") {
                        let tagsArray = []
                        item.value_list.forEach((list, i) => {
                            tagsArray.push(i)
                            list.forEach(lst => {
                                this.setState({
                                    ["tag_key_"+i]: lst.key,
                                    ["tag_value_"+i]: lst.value
                                })
                            })
                        })
                        this.setState({ tagsArray },
                            () => this.checkAllDataFilled()    
                        )
                    } else {
                        this.setState({ [item.key]: item.value_list ? item.value_list : item.value,  },
                            () => {
                                this.checkAllDataFilled()
                            }
                        )
                    }
                })                
            }
            let actions = this.props.automationPropsDetails.actions ? this.props.automationPropsDetails.actions : []
            let previousAutomations = actions.filter(e => e.section < this.state.activeSection)

            this.setState({ previousAutomations, previousAutomationsSearchResult: previousAutomations },
                () => {
                    this.listDiagnosticTemplate()
                }
            )            
        } else {
            this.listDiagnosticTemplate()
        }

        // if(this.props.providers && this.props.providers.length) {
        //     let selectedProvider = this.props.providers.length === 1 ? this.props.providers[0].provider_name.toLowerCase() : ""
        //     if(selectedProvider !== "") {
        //         this.setState({ selectedProvider },
        //             () => {
        //                 this.getCommonFilters()
        //                 this.formInputProps(this.state.activeSection, "provider", this.state.selectedProvider)
        //             }
        //         )
        //     }
        // }
    }

    getCommonFilters = () => {
        let params = {}
        if(this.props.accounts && this.props.accounts.length) {
            let accounts = this.props.accounts
            if(this.state.selectedProvider) {
                accounts = this.props.accounts.filter(e => e.provider === this.state.selectedProvider.toLowerCase())
                let selectedAccount = accounts && accounts.length === 1 ? accounts[0].account_id : ""
                this.setState({ accounts, selectedAccount },
                    () => {
                        if(selectedAccount !== "") {
                            this.formInputProps(this.state.activeSection, "account_id", this.state.selectedAccount)
                        }
                    }
                )
            }
        } else {
            this.props.listAllAccounts(params, (promise, response) => {
                if(promise) {
                    let filterdAccounts = response.filter(e => e.provider === this.state.selectedProvider.toLowerCase())
                    let selectedAccount = filterdAccounts && filterdAccounts.length === 1 ? filterdAccounts[0].account_id : ""
                    this.setState({ accounts: filterdAccounts, selectedAccount },
                        () => {
                            if(selectedAccount !== "") {
                                this.formInputProps(this.state.activeSection, "account_id", this.state.selectedAccount)
                            }
                        }
                    )
                }
            })
        }

        if(this.state.selectedProvider) {
            params.provider = this.state.selectedProvider.toLowerCase()

            if(this.props.regions && this.props.regions.length) {
                let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
                // providerRegions = providerRegions.filter(e => e.name !== "All")
                let selectedRegion = providerRegions && providerRegions.length === 1 ? providerRegions[0].region : ""
                this.setState({ regions: providerRegions, selectedRegion },
                    () => {
                        if(selectedRegion !== "") {
                            this.formInputProps(this.state.activeSection, "region", this.state.selectedRegion)
                        }
                    }
                )
            } else {
                this.props.listAllRegions(params, (promise, response) => {
                    if (promise) {
                        let providerRegions = response && response[0] && response[0].provider_regions ? response[0].provider_regions : []
                        providerRegions = providerRegions.filter(e => e.name !== "All")
                        let selectedRegion = providerRegions && providerRegions.length === 1 ? providerRegions[0].region : ""
                        this.setState({ regions: providerRegions, selectedRegion },
                            () => {
                                if(selectedRegion !== "") {
                                    this.formInputProps(this.state.activeSection, "region", this.state.selectedRegion)
                                }
                            }
                        )
                    }
                })
            }
        }
    }

    componentDidUpdate = (prevProps) => {}

    onActionSelect = (action) => {
        this.setState({ 
            selectedAction: action, 
            showActionResults: false 
        },
            // () => this.formInputProps(["action_"+this.state.activeSection], this.state.activeSection, this.state.selectedAction)
        )
    }

    listDiagnosticChecks = () => {
		this.props.listDiagnosticChecks((promise, response) => {
			if (promise) {
				this.setState({ diagnosticChecks: response })
			} else {
				this.setState({ diagnosticChecks: [] })
			}
		})
	}

    listDiagnosticTemplate = () => {
        let params = {}
        params.provider =this.props.automationPropsDetails.provider
        params.account_id =this.props.automationPropsDetails.account_id
        params.region =this.props.automationPropsDetails.region

		this.props.listDiagnosticTemplate(params, (promise, response) => {
			if (promise) {
				if(this.state.template_id && this.state.template_id !== "") {                    
                    this.selectedDiagnotic()
                }
			} else {
				this.setState({ diagnosticTemplates: [] })
			}
		})
	}

    formInputProps = (section, field, value) => {
        let obj = this.props.automationPropsDetails
        let actions = obj.actions
        let objIndex = actions.findIndex((e => e.section === section))
        let dataRow = {}
        if(field === 'tag_value' || field === 'tag_key') {
            let arrayList = []
            this.state.tagsArray.forEach(item => {
                let dataRow = {
                    key:  this.state["tag_key_"+item] ? this.state["tag_key_"+item] : "", 
                    value: this.state["tag_value_"+item] ?  this.state["tag_value_"+item] : ""
                }
                arrayList.push(dataRow)
            })
            field = "tags"
            dataRow.key = field
            dataRow.value_list = arrayList
            
        } else if(field === "checks") {
            dataRow.key = field
            dataRow.value_list = value
        }else {
            dataRow.key = field
            dataRow.value = value
        }

        let input = actions[objIndex].input ? actions[objIndex].input : []
        let inputIndex = input.findIndex((e => e.key === field))
        if(inputIndex > -1) {
            input[inputIndex] = dataRow
        } else {
            input.push(dataRow)
        }
        actions[objIndex].input = input
        obj.actions = actions

        this.props.setAutomationPropsDetails(obj)

        this.checkAllDataFilled()
    }

    checkAllDataFilled = () => {
        if(this.state.selectedProvider && this.state.selectedProvider !== "" && this.state.selectedAccount && this.state.selectedAccount !== "" && this.state.selectedRegion && this.state.selectedRegion !== "") {
            this.props.actionCompleted(true)
        } else {
            this.props.actionCompleted(false)
        }
    }

    checkEnteredCharacter = (e, val, field, index) => {
        // var editable = document.querySelectorAll('div[contentEditable]');
        let className = field+"_"+this.state.activeSection
        let fieldState = field
        if(index !== "") {
            fieldState = field+"_"+index
            className = field+"_"+this.state.activeSection+"_"+index
        }
        let innerHtml = document.getElementsByClassName(className)[0].innerHTML
        innerHtml = innerHtml.replaceAll('<span class="text-white">&nbsp;</span>', "")
        innerHtml = innerHtml.replaceAll('<span class="text-info">', "")
        innerHtml = innerHtml.replaceAll('<span class="text-white">', "")
        innerHtml = innerHtml.replaceAll('</span>', "")

        var re = /<div>(.*?)<\/div>/;
        var result = innerHtml.split(re);

        let stringToSave = ""
        if(result.length) {
            let visit = 0
            result.forEach((item, rIndex) => {
                if(item !== "") {
                    
                    let br = ""
                    // if(rIndex && visit) {
                    //     br = "/n"
                    // }
                    // if(result.length - 1 !== rIndex) {
                    //     br = "/n"
                    // }
                    if(item === "<br>") {
                        item = "/n"
                    } else if(rIndex && visit) {
                        br = "/n"
                    }
                    stringToSave += br+""+item
                    visit++;
                }
            })
        }

        let position = stringToSave.indexOf("$$");
        if(position >= 0) {
            this.setState({ ["show_"+field+"_drop_option_"+index]: true, [fieldState]: stringToSave })
        } else {
            this.setState({ ["show_"+field+"_drop_option_"+index]: false, [fieldState]: stringToSave })
        }
        this.setState({ [fieldState]: stringToSave },
            () => this.formInputProps(this.state.activeSection, field, this.state[fieldState])
        )
    }

    selectedpreviousAutomation = (field, index, actionDetails, childIndex, childDetails) => {
        this.state.previousAutomations.forEach((item, i) => {
            this.setState({ ["showChild_"+i]: false })
        })

        //if there is child it will be confiigured when real data is available for child section
        // this.setState({ ["showChild_"+childIndex]: !this.state["showChild_"+childIndex] })

        //if there is no child
        this.selectedField(field, index, actionDetails, childIndex, childDetails)
    }

    selectedField = (field, index, actionDetails, childIndex, childDetails) => {
        let className = field+"_"+this.state.activeSection
        let fieldState = field
        if(index !== "") {
            fieldState = field+"_"+index
            className = field+"_"+this.state.activeSection+"_"+index
        }

        let stringToSave = this.state[fieldState]

        if(actionDetails !== "") {
            let res = "${"+actionDetails.name+".}"
            if(childDetails !== "") {
                res = "${"+actionDetails.name+"."+childDetails.name+"}"
            }
            stringToSave = stringToSave.replace("$$", res)
        }
        //form html from the string to show in the contenteditable        
        let innertHtmlData = ""
        let splitString = stringToSave.split("/n")
        let re = /{(.*?)}/;
        splitString.forEach(str => {
            innertHtmlData += "<div>"
            if(str !== "") {
                let result = str.split(re);
                let previousdata = ""
                result.forEach((item, i) => {
                    let lastTwoLetters = item.substring(item.length, item.length-2)
                    let lastLetter = item.charAt(item.length-1)
                    if(previousdata === "$") {
                        innertHtmlData += '<span class="text-info">${'+item+'}</span> <span class="text-white">&nbsp;</span>'
                    } else if(item !== "$") {
                        if(lastLetter === '$' && item !== "$$" && lastTwoLetters !== "$$") {
                            item = item.substring(0, item.length - 1)
                        }
                        innertHtmlData += '<span class="text-white">'+item+'</span>'
                    }

                    previousdata = lastTwoLetters === "$$" ? "" : lastLetter
                })
            } else {
                innertHtmlData += "<br>"    
            }

            innertHtmlData += "</div>"
        })

        document.getElementsByClassName(className)[0].innerHTML = innertHtmlData

        this.setState({ ["show_"+field+"_drop_option_"+index]: false, [fieldState]: stringToSave },
            () => this.formInputProps(this.state.activeSection, field, this.state[fieldState])
        )
    }

    onLoadDropFields = (list, index) => {
        let className = list.key+"_"+this.state.activeSection
        if(index !== "") {
            className = list.key+"_"+this.state.activeSection+"_"+index
        }

        let stringToSave = list.value ? list.value : ""
        
        //form html from the string to show in the contenteditable        
        if(stringToSave && stringToSave !== "") {
            let innertHtmlData = ""
            let splitString = stringToSave.split("/n")
            let re = /{(.*?)}/;
            splitString.forEach(str => {
                innertHtmlData += "<div>"
                if(str !== "") {
                    let result = str.split(re);
                    let previousdata = ""
                    result.forEach((item, i) => {
                        let lastTwoLetters = item.substring(item.length, item.length-2)
                        let lastLetter = item.charAt(item.length-1)
                        if(previousdata === "$") {
                            innertHtmlData += '<span class="text-info">${'+item+'}</span> <span class="text-white">&nbsp;</span>'
                        } else if(item !== "$") {
                            if(lastLetter === '$' && item !== "$$" && lastTwoLetters !== "$$") {
                                item = item.substring(0, item.length - 1)
                            }
                            innertHtmlData += '<span class="text-white">'+item+'</span>'
                        }

                        previousdata = lastTwoLetters === "$$" ? "" : lastLetter
                    })
                } else {
                    innertHtmlData += "<br>"
                }
                innertHtmlData += "</div>"
            })
            
            document.getElementsByClassName(className)[0].innerHTML = innertHtmlData
        }
        // this.setState({ [fieldState]: stringToSave })

        // this.formInputProps(this.state.activeSection, field, stringToSave)
    }

	durationFunction = (type) => {
		if(this.state.selectedDuration !== "custom") {
			let days = this.state.selectedDuration.replace('+', "").replace('d', "")
			let  reducedDays = days - 1
        
			let start_time = momentDateGivenFormat(subDays(new Date(), reducedDays), 'YYYY-MM-DD 00:00:00')
			let end_time = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:00')

			this.setState({ start_time, end_time, showDateTimePicker: false },
                () => {
                    this.formInputProps(this.state.activeSection, "duration", this.state.selectedDuration)                    
                    if(this.state.selectedDuration === "custom") {
                        this.formInputProps(this.state.activeSection, "start_time", this.state.start_time)
                        this.formInputProps(this.state.activeSection, "end_time", this.state.end_time)
                    }
                }
            )
		} else {
			this.setState({ showDateTimePicker: true })
		}
    }
  
    handleStartTimeChange(date) {
        let start_time = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
        let end_time = this.state.end_time
		if(this.state.end_time) {
			if(new Date(start_time).getTime() > new Date(end_time).getTime()) {
				end_time = start_time
			}
		} else {
			end_time = start_time	
		}
		this.setState({ showDateTimePicker: false },
			() => this.setState({ start_time, end_time, showDateTimePicker: true },
                    () => {
                        this.formInputProps(this.state.activeSection, "start_time", this.state.start_time)
                        this.formInputProps(this.state.activeSection, "end_time", this.state.end_time)
                        this.formInputProps(this.state.activeSection, "selectedDuration", this.state.selectedDuration)
                    }
                )
		)
    }

    handleEndTimeChange(date) {
        let start_time = this.state.start_time
        let end_time = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
		if(this.state.start_time) {
			if(new Date(end_time).getTime() < new Date(start_time).getTime() ) {
				start_time = end_time
			} 
		} else {
			start_time = end_time	
		}
		this.setState({ showDateTimePicker: false },
			() => this.setState({ start_time, end_time, showDateTimePicker: true },
                () => {
                    this.formInputProps(this.state.activeSection, "start_time", this.state.start_time)
                    this.formInputProps(this.state.activeSection, "end_time", this.state.end_time)
                    this.formInputProps(this.state.activeSection, "selectedDuration", this.state.selectedDuration)
                }
            )
		)
    }
    
	addSection = () => {
        let rowList = this.state.tagsArray;
        if(this.state.tagsArray) {
            let value = this.state.tagsArray[this.state.tagsArray.length - 1]
            value = value+1
            rowList.push(value);
        }
        
        this.setState({tagsArray: rowList })
    }

    removeSection = (item, i) => {
        this.setState({ ["tag_key_"+item]: "", ["tag_value_"+item]: "" },
            () => {
                let rowList = this.state.tagsArray ? this.state.tagsArray : [];
                rowList.splice(i, 1);
                this.setState({ tagsArray: rowList })
            }
        );
    }

	onChangeEvent = (index, type) => {
		let dataRow = {}
		dataRow.key = this.state["tag_key_"+index] ? this.state["tag_key_"+index] : ""
		dataRow.value = this.state["tag_value_"+index] ? this.state["tag_value_"+index] : ""
		
		let tags = this.state.selectedTags ? this.state.selectedTags : []

		if(tags.length && tags.length-1 >= index) {
			tags[index] = dataRow
		} else {
            tags.push(dataRow)
        }

		this.setState({ selectedTags: tags })
	}

    onChangeDiagnotics = (check) => {
		let selectedDiagnoticChecks = this.state.selectedDiagnoticChecks ? this.state.selectedDiagnoticChecks : []
		if(this.state.allChecks) {
			selectedDiagnoticChecks = []
		}

		if(selectedDiagnoticChecks.length || check) {
			let idExist = selectedDiagnoticChecks.filter(e => e === check)
			if(idExist.length) {
				selectedDiagnoticChecks = selectedDiagnoticChecks.filter(e => e !== check)
			} else {
				selectedDiagnoticChecks.push(check)
			}
		}
		this.setState({ selectedDiagnoticChecks },
            () => this.formInputProps(this.state.activeSection, "checks", selectedDiagnoticChecks)
        )
	}

    selectedDiagnotic = (type) => {
        let diagnostics = {}
        let selectedDuration = ""
        let showDateTimePicker = false
        if(type !== "new") {
            diagnostics = this.state.selected_template_details ? this.state.selected_template_details : this.props.diagnosticTemplates.filter(e => e.template_id === this.state.template_id)[0]

            selectedDuration = diagnostics.duration && diagnostics.duration !== "" ? diagnostics.duration : "custom"
            showDateTimePicker = (!diagnostics.duration || diagnostics.duration === "" || diagnostics.duration === "custom") ? true: false
        }

        let tagsArray = []
        if(diagnostics.tags && diagnostics.tags.length) {
            diagnostics.tags.forEach((list, i) => {
                tagsArray.push(i)
                this.setState({
                    ["tag_key_"+i]: list.key,
                    ["tag_value_"+i]: list.value
                },
                    // () => this.formInputProps(this.state.activeSection, "tag_value", this.state["tag_value_"+i])
                )
            })
        } else {
            tagsArray = [0]
        }        

        this.setState({ 
            showdiagnosticsOptions: false,
            template_id: diagnostics.template_id ? diagnostics.template_id : "",
            selected_template: diagnostics.name ? diagnostics.name : "",
            selectedProvider: diagnostics.provider ? diagnostics.provider : "",
            selectedAccount: diagnostics.account_id ? diagnostics.account_id : "",
            selectedRegion: diagnostics.region ? diagnostics.region : "",
            selectedDuration,
            showDateTimePicker,
            start_time: diagnostics.start_time ? diagnostics.start_time : "",
            end_time: diagnostics.end_time ? diagnostics.end_time : "",
            description: diagnostics.description ? diagnostics.description : "",
            tagsArray,
            selectedDiagnoticChecks: diagnostics.checks ? diagnostics.checks : "",
            allChecks: diagnostics.checks && diagnostics.checks.length ? false : true,
        },
            () => {
                this.formInputProps(this.state.activeSection, "template_id", this.state.template_id)
                // this.formInputProps(this.state.activeSection, "provider", this.state.selectedProvider)
                // this.formInputProps(this.state.activeSection, "account_id", this.state.selectedAccount) 
                // this.formInputProps(this.state.activeSection, "region", this.state.selectedRegion)
                // this.formInputProps(this.state.activeSection, "description", this.state.description)
                // this.formInputProps(this.state.activeSection, "duration", this.state.selectedDuration)
                // if(this.state.start_time !=="" && this.state.end_time !== "") {
                //     this.formInputProps(this.state.activeSection, "start_time", this.state.start_time)
                //     this.formInputProps(this.state.activeSection, "end_time", this.state.end_time)
                // }
            }
        )
    }

    render() {
        return (
            <div className="">
                <div className="d-flex mt-3">
                    <div className="position-relative d-inline-block w-50">
                        <label className="mb-1">Template</label>
                        <Input
                            type="text"
                            placeholder="Select Template"
                            className="inputTextbox"
                            value={this.state.selected_template ? this.state.selected_template : this.state.searchKey}
                            onChange={event => 
                                this.setState({ searchKey: event.target.value, showDiagnosticsOptions: true })
                            }
                            onClick={() => this.setState({ showDiagnosticsOptions: true })}
                        />

                        {this.state.showDiagnosticsOptions ? 
                            <div className={`dropdownOptions topAuto mt-2`}>
                                <button className="d-flex justify-content-between mb-3" onClick={() => this.setState({ selected_template: 'Add Template', showDiagnosticsOptions: false }, 
                                    () => {
                                        this.props.showHelperSection("diagnostics", "new")
                                        this.selectedDiagnotic('new')
                                    }
                                )}>
                                    <p className="mb-0 text-info">Add Template</p>
                                </button>
                                {this.props.diagnosticTemplates && this.props.diagnosticTemplates.length ? 
                                    this.props.diagnosticTemplates.map(dat => {
                                        return (
                                            <button className="d-flex justify-content-between mb-3" onClick={() => this.setState({ selected_template: dat.name, showDiagnosticsOptions: false, searchKey: "", selected_template_details: dat},
                                                () => this.selectedDiagnotic()
                                            )}>
                                                <p className="mb-0 text-left">{dat.name ? dat.name : dat.diagnostic_id}</p>
                                                {/* <p className="mb-0 small btn btn-sm btn-primary" onClick={() => this.editDatabase(dat)}>Edit</p> */}
                                            </button>
                                        )
                                    })
                                : null}
                            </div>
                        : null}
                    </div>
                    <div className="pl-3 w-50 displayNone">
                        <label className="mb-1">Provider</label>
                        <Select 
                            options={this.props.providers && this.props.providers.length && this.props.providers.map(item => ({
                                value: item.provider_name,
                                label: item.provider_name,
                            }))}
                            value={({
                                value: this.state.selectedProvider && this.state.selectedProvider.toUpperCase(),
                                label: this.state.selectedProvider ? this.state.selectedProvider.toUpperCase() : <span className="placeholder">Select provider</span>
                            })}
                            menuIsOpen={false}
                            // isSearchable={!this.state.disableFields}
                            // menuIsOpen={this.state.disableFields ? false : undefined}
                            onChange={event => 
                                this.setState({ selectedProvider: event.value.toLowerCase() },
                                    () => {
                                        // this.getCommonFilters()
                                        this.formInputProps(this.state.activeSection, "provider", this.state.selectedProvider)
                                    }
                                )
                            }
                        />
                    </div>
                </div>
                <div className="d-flex mt-3 displayNone">
                    <div className="w-50">
                        <label className="mb-1">Accounts </label>
                        <Select 
                            options={this.props.accounts && this.props.accounts.length &&this.props.accounts.map(item => ({
                                value: item.account_id,
                                label: item.account_name,
                            }))}
                            value={({
                                value: this.state.selectedAccount,
                                label: this.state.selectedAccount && this.state.selectedAccount !== "" ? getAccountNameFromId(this.state.selectedAccount, this.props.accounts) : <span className="placeholder">Select account</span>
                            })}
                            // isSearchable={!this.state.disableFields}
                            // menuIsOpen={this.state.disableFields ? false : undefined}
                            menuIsOpen={false}
                            onChange={event => 
                                this.setState({ selectedAccount: event.value },
                                    () => this.formInputProps(this.state.activeSection, "account_id", this.state.selectedAccount)
                                )
                            }
                        />
                    </div>
                    <div className="pl-3 w-50">
                        <label className="mb-1">Region </label>
                        <Select 
                            options={this.props.regions && this.props.regions.map(item => ({
                                value: item.region,
                                label: item.name,
                            }))}
                            value={({
                                value: this.state.selectedRegion,
                                label: this.state.selectedRegion && this.state.selectedRegion !== ""  ? getRegionName(this.state.selectedRegion, this.props.regions) : <span className="placeholder">Select region</span>
                            })}
                            // menuIsOpen={true}
                            menuIsOpen={false}
                            onChange={event => 
                                this.setState({ selectedRegion: event.value },
                                    () => {
                                        this.formInputProps(this.state.activeSection, "region", this.state.selectedRegion)
                                    }
                                )
                            }
                        />
                    </div>
                </div>
                
                <div className="d-flex mt-2 ">
                    <div className="py-1 w-30">
                        <div className="d-flex justify-content-between">
                            <p className="b-block mb-0">Duration</p>
                            {this.state.hasError && (!this.state.selectedDuration || this.state.selectedDuration === "") ?
                                <p className="mb-0 text-danger">required</p>
                            : null}
                        </div>
                        <Select
                            placeholder={"Select"}
                            isSearchable={false}
                            // menuIsOpen={true}
                            menuIsOpen={false}
                            className="f13 p-0 selectDarkTheme"
                            options={HOURLY_DURATION.map(dur => ({
                                value: dur.value,
                                label: dur.option,
                            }))}
                            value={({
                                value: this.state.selectedDuration && this.state.selectedDuration !== "" ? this.state.selectedDuration : "Select",
                                label: this.state.selectedDuration && this.state.selectedDuration !== "" ? (HOURLY_DURATION.filter(e => e.value === this.state.selectedDuration).length ? HOURLY_DURATION.filter(e => e.value === this.state.selectedDuration)[0].option : <span className="placeholder">Select</span>) : <span className="placeholder">Select</span>
                            })}
                            onChange={event =>  
                                this.setState({
                                    selectedDuration: event.value
                                },
                                    () => this.durationFunction()
                                )
                            }
                        />
                    </div>
                    {this.state.showDateTimePicker ?
                        <div className="py-1 w-70 pl-3">
                            <div className="d-flex justify-content-between">
                                <p className="b-block mb-0 text-white">&nbsp;</p>
                                {this.state.hasError && (!this.state.start_time || !this.state.end_time) ?
                                    <p className="mb-0 text-danger">required</p>
                                : null}
                            </div>
                            
                            <div className="d-flex">
                                <Datetime 
                                    value={momentDateGivenFormat(this.state.start_time, 'DD MMM YYYY hh:mm A')}
                                    onChange={this.handleStartTimeChange}
                                    dateFormat={'DD MMM YYYY'}
                                    timeFormat={'hh:mm A'}
                                    inputProps={ startInputProps }
                                    className="w-100 mr-3"
                                    isValidDate={disableFutureDt}
                                />
                                <Datetime
                                    value={momentDateGivenFormat(this.state.end_time, 'DD MMM YYYY hh:mm A')}
                                    onChange={this.handleEndTimeChange}
                                    dateFormat={'DD MMM YYYY'}
                                    timeFormat={'hh:mm A'}
                                    inputProps={ endInputProps }
                                    className="w-100"
                                    isValidDate={disableFutureDt}
                                />
                            </div>
                        </div>
                    : null}
                </div>

                <div className="mt-2">
                    <div className="w-82 d-flex justify-content-between">
                        <p className="b-block mb-0">Tags</p>
                        {this.state.hasError && (!this.state.selectedTags || !this.state.selectedTags.length) ?
                            <p className="mb-0 text-danger">required</p>
                        : null}
                    </div>
                    {this.state.tagsArray.map((item, i) => {
                        return(
                            <div className={`w-100 ${i ? "mt-3" : ""}`}>
                                <div className="d-flex">
                                    {/* <p className="mb-0 mr-2 align-self-end text-info">Bucket</p> */}
                                    <Input 
                                        type="text" 
                                        placeholder="key (environment)"
                                        className={`w-40 bg-black5 text-white border-0 ${this.state.hasError && (this.state["tag_value_"+item] && this.state["tag_value_"+item] !== "" && (!this.state["tag_key_"+item] || this.state["tag_key_"+item] === "")) ? "danger-border" : ""}`}
                                        value={this.state["tag_key_"+item] ? this.state["tag_key_"+item] : ""}
                                        onChange={e => this.setState({ ["tag_key_"+item]: e.target.value }, 
                                            () => this.formInputProps(this.state.activeSection, "tag_key", this.state["tag_key_"+item])
                                        )}
                                        disabled={true}
                                    />
                                    {/* <p className="mb-0 mx-2 align-self-end text-info">Prefix</p> */}
                                    <Input 
                                        type="text" 
                                        placeholder="value (prod)"
                                        className={`w-40 bg-black5 text-white border-0 ml-3 ${this.state.hasError && (this.state["tag_key_"+item] && this.state["tag_key_"+item] !== "" && (!this.state["tag_value_"+item] || this.state["tag_value_"+item] === "")) ? "danger-border-3" : ""}`}
                                        value={this.state["tag_value_"+item] ? this.state["tag_value_"+item] : ""}
                                        onChange={e => this.setState({ ["tag_value_"+item]: e.target.value }, 
                                            () => this.formInputProps(this.state.activeSection, "tag_value", this.state["tag_value_"+item])
                                        )}
                                        disabled={true}
                                    />
                                    {this.state.tagsArray.length > 1 ?
                                        <span className={`far fa-trash cursor-pointer f18 align-self-center ml-4 displayNone`} onClick={() => this.removeSection(item, i)}></span>
                                    : null}
                                    {(this.state.tagsArray.length - 1) ===  i ?
                                        <span className={`far fa-plus cursor-pointer f18 align-self-center ml-4 displayNone`} onClick={() => this.addSection()}></span>
                                    : null}
                                </div>
                            </div>
                        )
                    })}
                </div>
                {!this.state.selectedDiagnoticChecks || !this.state.selectedDiagnoticChecks.length ?
                    <div className="form-check mt-2 mb-4">
                        <input 
                            type="checkbox" 
                            className="form-check-input displayNone"
                            checked={this.state.allChecks}
                            onChange={e => this.setState({ allChecks: !this.state.allChecks }, () => this.onChangeDiagnotics())}
                            disabled={true}
                        />
                        <label className="form-check-label text-white f14">
                            Run all Diagnostic checks
                        </label>
                    </div>
                : null}
                {!this.state.allChecks ?
                    <div className="mt-3 displayNone">
                        <div className="table-responsive rounded border">
                            <table className="table table-sm mb-0 text-primary-color">
                                <thead className="bg-dark3">
                                    <tr>
                                        <th colspan="2" className="w-40">Diagnostic Checks</th>
                                        {/* <th className="text-nowrap w-20">Check</th> */}
                                        <th className="w-60">Description</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-dark text-white">
                                    {this.state.diagnosticChecks && this.state.diagnosticChecks.map((item, index) => {
                                        return(
                                            <tr className={`${index === this.state.diagnosticChecks.length-1 ? "" : "border-bottom-dark"}`}>
                                                <td>
                                                    <input
                                                        type='checkbox'
                                                        min='1'
                                                        className="form-check-input m-0 mt-n2 align-self-center"
                                                        onChange={e => this.onChangeDiagnotics(item.check)}
                                                        checked={this.state.selectedDiagnoticChecks && this.state.selectedDiagnoticChecks.includes(item.check) ? true : false}
                                                    />
                                                </td>
                                                <td>
                                                    {item.name}
                                                </td>
                                                <td>
                                                    <p className="mb-0" id={`description_`+index}>{item.description}</p>
                                                    <UncontrolledTooltip placement='top' target={`description_`+index}>{item.description}</UncontrolledTooltip>		
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                : null}

                {this.state.selectedDiagnoticChecks && this.state.selectedDiagnoticChecks.length ?
                    <div className="mt-3">
                        <div className="table-responsive rounded border">
                            <table className="table table-sm mb-0 text-primary-color">
                                <thead className="bg-dark3">
                                    <tr>
                                        <th colspan="1" className="w-40">Diagnostic Checks</th>
                                        {/* <th className="text-nowrap w-20">Check</th> */}
                                        <th className="w-60">Description</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-dark text-white">
                                    {this.state.diagnosticChecks && this.state.diagnosticChecks.map((item, index) => {
                                        return(
                                            this.state.selectedDiagnoticChecks.includes(item.check) ?
                                                <tr className={`${index === this.state.diagnosticChecks.length-1 ? "" : "border-bottom-dark"}`}>
                                                    <td>
                                                        {item.name}
                                                    </td>
                                                    <td>
                                                        <p className="mb-0" id={`description_`+index}>{item.description}</p>
                                                        <UncontrolledTooltip placement='top' target={`description_`+index}>{item.description}</UncontrolledTooltip>		
                                                    </td>
                                                </tr>
                                            : null
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                : null}
                
                <div className="mt-3">
                    <p className="mb-0">Description</p>
                    <Input 
                        type="textarea" 
                        rows={3}
                        placeholder="enter description"
                        className={`inputTextbox`}
                        value={this.state.description ? this.state.description : ""}
                        onChange={e => this.setState({ description: e.target.value },
                            () => this.formInputProps(this.state.activeSection, "description", this.state.description)    
                        )}
                        disabled={true}
                    />
                </div>
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
RunDiagnostics.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log("RunDiagnostics",state)
    return {
        providers: state.filters.providers,
        accounts: state.filters.accounts,
		regions: state.filters.regions && state.filters.regions.length && state.filters.regions[0].provider_regions ? state.filters.regions[0].provider_regions : [],
        automationPropsDetails: state.filters.automationPropsDetails,
        diagnosticTemplates: state.aiops.aiopsDiagnosticTemplateList
    }
}

export default connect(mapStateToProps, {
    listDiagnosticChecks,
    listDiagnosticTemplate,
    setAutomationPropsDetails,
})(withRouter(RunDiagnostics))        