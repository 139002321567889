/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AutomationPerView.js
 * @author Prakash // on 13/11/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getTotalCount, getApis } from '../../actions/AutomationAction'
import _ from 'lodash'
import { capitalizeTheFirstLetterOfEachWord, momentConvertionLocalToUtcTime } from '../../utils/utility'

import StackedCircleBarChart from '../common/charts/StackedCircleBarChart'
import ApexBarChart from '../common/charts/ApexBarChart'
import ApexStackedBarChart from '../common/charts/ApexStackedBarChart'

class AutomationPerView extends Component {
    
    leftRadialStackedBarWidthRef = React.createRef()	
    leftSideTableRef = React.createRef()
    
    rightRadialStackedBarWidthRef = React.createRef()
    rightSideTableRef = React.createRef()

    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,

            // automationPerType: [
            //     {key: 'Cost Saved', value: 'cost_saved'},
            //     {key: 'Count', value: 'count'},
            //     {key: 'Duration', value: 'duration'},
            //     {key: 'Time Saved', value: 'time_saved'},
            // ]
        }
    }
    
    componentDidMount = () => {
        if(this.props.aiopsAutomationFilters) {
            this.onSearch()
        }
            
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.aiopsAutomationFilters && this.props.aiopsAutomationFilters !== prevProps.aiopsAutomationFilters) {
            this.setState({
                showRightSideRadialChart: false, 
                showRightSideTrendChart: false, 
                showRightSideStatusTrendChart: false,
                showLeftSideRadialChart: false, 
                showLeftSideTrendChart: false, 
                showLeftSideStatusTrendChart: false,
            },
                () => this.onSearch()
            )
        }
    }
    
    onSearch = () => {
        this.setState({ 
            showLoading: true,
            rightSideStateName: 'parentData',
            rightSideChartType: 'cost_saved',
            leftSideStateName: 'parentData',
            leftSideChartType: 'count',
            selectedProvider: this.props.aiopsAutomationFilters.selectedProvider,
            selectedAccount: this.props.aiopsAutomationFilters.selectedAccount,
            selectedRegion: this.props.aiopsAutomationFilters.selectedRegion,
            start_time: this.props.aiopsAutomationFilters.start_time,
            end_time: this.props.aiopsAutomationFilters.end_time,
        },
            () => {
                this.getTotalCount()
            }
        )

        setTimeout(() => { this.setState({ showLoading: false }) }, 5000)
    }

    getTotalCount = () => {
        let params = {}
        if(this.state.selectedProvider && this.state.selectedProvider !== '') {
            params.provider =  this.state.selectedProvider.toLowerCase()
        }
        if(this.state.selectedAccount && this.state.selectedAccount.length) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length) {
            params.region = this.state.selectedRegion
        }
        params.start_time = this.state.start_time
        params.end_time = this.state.end_time
        params.aggregate_by = ["automation_plan", ["cost_saved", "time_saved", "duration"]]

        this.props.getTotalCount(params, (promise, response) => {
            if(promise) {
                this.setState({ automationPerData: response, showLoading: false }, 
                    () => this.formChartTypes()
                    // this.renderRightSideRadialBarChart()
                    // this.renderLeftSideRadialBarChart()
                )
            } else {
                this.setState({ showLoading: false })
            }
        })
    }

    formChartTypes = () => {
        let automationPerType = []
        this.state.automationPerData.length && this.state.automationPerData.forEach(item => {
            Object.keys(item).forEach(key => {
                let value = automationPerType.filter( e => e.value).map(e => e.value)
                if(key !== 'automation_plan' && !value.includes(key)) {
                    let dataRow = {}
                    dataRow.key = capitalizeTheFirstLetterOfEachWord(key.replace("_", " "))
                    dataRow.value = key
                    automationPerType.push(dataRow)
                }
            })
        })

        this.setState({ automationPerType },
            () => {
                this.renderRightSideRadialBarChart()
                this.renderLeftSideRadialBarChart()
            }    
        )
    }

    onChangeGetcharts = (from) => {
        let rightSideChartType = this.state.rightSideChartType
        let leftSideChartType = this.state.leftSideChartType

        if(from === 'right' || from === '') {
            this.setState({ showRightSideRadialChart: false, rightSideStateName: 'parentData', showRightSideTrendChart: false, showRightSideStatusTrendChart: false, rightSideChartType },
                () => {
                    this.renderRightSideRadialBarChart()
                }	
            )
        }

        if(from === 'left' || from === '') {
            this.setState({ showLeftSideRadialChart: false, leftSideStateName: 'parentData', showLeftSideTrendChart: false, showLeftSideStatusTrendChart: false, leftSideChartType },
                () => {
                    this.renderLeftSideRadialBarChart()
                }
            )
        }
    }

    renderRightSideRadialBarChart = () => {
        let rightSideGraphColors = []
        let rightSideLegend = ''
        if(this.state.rightSideChartType === 'cost_saved') {
            rightSideGraphColors = ["#9029cc", "#a22ee6", "#bc47ff", "#c35cff", "#cb70ff", "#d285ff", "#da99ff", "#e15ad2", "#fb74eb", "#fb83ed", "#fc93f0", "#fca2f2", "#fdb2f4", "#fdc1f6"]
            rightSideLegend = 'Cost'
        } else if(this.state.rightSideChartType === 'count') {
            rightSideGraphColors = ['#024d73', '#026ca0', '#038bce', '#35aeea', '#68c2ef', '#81cdf2', '#9ad7f5', '#b3e1f7', '#cdebfa']
            rightSideLegend = 'Count'
        } else if(this.state.rightSideChartType === 'duration') {
            rightSideGraphColors = ["#9029cc", "#a22ee6", "#bc47ff", "#c35cff", "#cb70ff", "#d285ff", "#da99ff", "#e15ad2", "#fb74eb", "#fb83ed", "#fc93f0", "#fca2f2", "#fdb2f4", "#fdc1f6"]
            rightSideLegend = 'Duration'
        } else if(this.state.rightSideChartType === 'time_saved') {
            rightSideGraphColors = ['#24a597', '#10a49a', '#00a29e', '#00a0a1', '#009fa4', '#009da7', '#009baa', '#0099ad', '#0098b0', '#0096b3', '#0093b5', '#0091b7', '#008fb9', '#008dba', '#008abc', '#0088bd', '#0085bd', '#0082bd', '#0080bd', '#1d7dbd', '#2d7abc', '#3977bb', '#4373b9', '#4d70b7', '#556db4', '#5d69b1', '#6466ae', '#6b62aa', '#715fa6', '#775ba2']
            rightSideLegend = 'Time'
        }

        let rightSideArray = []
        let rightSideKeys = [this.state.rightSideChartType]
        let rightSideHighest = 0
        let rightSideTotalData = {}
        let rightSideTotal = 0
        let rightSideGraphId = this.state.rightSideChartType
        let rightSideSvgGraphId = 'svg'+this.state.rightSideChartType

        let graphData = this.state.automationPerData

        if(graphData && graphData.length) {
            rightSideKeys = graphData.filter(e => e.automation_plan).map(e => e.automation_plan)

            graphData.forEach((item, index) => {
                let State = 'state_'+index
                let dataRow = {}
                let stateValue = item[this.state.rightSideChartType] ? item[this.state.rightSideChartType] : 0
                dataRow.State = State
                dataRow.name = item.automation_plan
                dataRow.total = stateValue
                dataRow[item.automation_plan] = stateValue
                rightSideTotal += stateValue
                if(rightSideHighest < stateValue) {
                    rightSideHighest = stateValue
                }
                rightSideKeys.forEach(key => {
                    if(!dataRow.hasOwnProperty(key)) {
                        dataRow[key] = 0
                    }
                })
                rightSideArray.push(dataRow)
            })

            // rightSideArray = _.orderBy(rightSideArray, ['name'], ['desc'])
            
            if(rightSideArray.length < 25) {
                let length = rightSideArray.length
                for(let i=length; i<=25; i++) {
                    let dataRow = {}
                    dataRow.State = 'automation_per_'+i
                    dataRow.name = 'empty_data'
                    dataRow.total = 0
                    rightSideKeys.forEach((key, j) => {
                        dataRow[key] = 0
                    })
                    rightSideArray.push(dataRow)
                }
            }

            
            let data = {}
            data.name = 'RightSide Data'
            rightSideKeys.forEach((key, j) => {
				data[key] = this.count(rightSideArray, key)
			})
            rightSideTotalData.data = data
            
            this.setState({ rightSideGraphColors, rightSideLegend, rightSideArray, rightSideKeys, rightSideHighest, rightSideTotalData, rightSideTotal, rightSideGraphId, rightSideSvgGraphId, showRightSideRadialChart: true })
        } else {
            this.setState({ rightSideGraphColors, rightSideLegend, rightSideArray, rightSideKeys, rightSideHighest, rightSideTotalData, rightSideTotal, rightSideGraphId, rightSideSvgGraphId, showRightSideRadialChart: false })
        }
    }

    renderLeftSideRadialBarChart = () => {
        let leftSideGraphColors = []
        let leftSideLegend = ''
        if(this.state.leftSideChartType === 'cost_saved') {
            leftSideGraphColors = ["#9029cc", "#a22ee6", "#bc47ff", "#c35cff", "#cb70ff", "#d285ff", "#da99ff", "#e15ad2", "#fb74eb", "#fb83ed", "#fc93f0", "#fca2f2", "#fdb2f4", "#fdc1f6"]
            leftSideLegend = 'Cost'
        } else if(this.state.leftSideChartType === 'count') {
            leftSideGraphColors = ['#024d73', '#026ca0', '#038bce', '#35aeea', '#68c2ef', '#81cdf2', '#9ad7f5', '#b3e1f7', '#cdebfa']
            leftSideLegend = 'Count'
        } else if(this.state.leftSideChartType === 'duration') {
            leftSideGraphColors = ["#9029cc", "#a22ee6", "#bc47ff", "#c35cff", "#cb70ff", "#d285ff", "#da99ff", "#e15ad2", "#fb74eb", "#fb83ed", "#fc93f0", "#fca2f2", "#fdb2f4", "#fdc1f6"]
            leftSideLegend = 'Duration'
        } else if(this.state.leftSideChartType === 'time_saved') {
            leftSideGraphColors = ['#24a597', '#10a49a', '#00a29e', '#00a0a1', '#009fa4', '#009da7', '#009baa', '#0099ad', '#0098b0', '#0096b3', '#0093b5', '#0091b7', '#008fb9', '#008dba', '#008abc', '#0088bd', '#0085bd', '#0082bd', '#0080bd', '#1d7dbd', '#2d7abc', '#3977bb', '#4373b9', '#4d70b7', '#556db4', '#5d69b1', '#6466ae', '#6b62aa', '#715fa6', '#775ba2']
            leftSideLegend = 'Time'
        }

        let leftSideArray = []
        let leftSideKeys = [this.state.leftSideChartType]
        let leftSideHighest = 0
        let leftSideTotalData = {}
        let leftSideTotal = 0
        let leftSideGraphId = this.state.leftSideChartType
        let leftSideSvgGraphId = 'svg'+this.state.leftSideChartType

        let graphData = this.state.automationPerData

        if(graphData && graphData.length) {
            leftSideKeys = graphData.filter(e => e.automation_plan).map(e => e.automation_plan)

            graphData.forEach((item, index) => {
                let State = 'state_'+index
                let dataRow = {}
                let stateValue = item[this.state.leftSideChartType] ? item[this.state.leftSideChartType] : 0
                dataRow.State = State
                dataRow.name = item.automation_plan
                dataRow.total = stateValue
                dataRow[item.automation_plan] = stateValue
                leftSideTotal += stateValue
                if(leftSideHighest < stateValue) {
                    leftSideHighest = stateValue
                }
                leftSideKeys.forEach(key => {
                    if(!dataRow.hasOwnProperty(key)) {
                        dataRow[key] = 0
                    }
                })
                leftSideArray.push(dataRow)
            })

            // leftSideArray = _.orderBy(leftSideArray, ['name'], ['desc'])
            
            if(leftSideArray.length < 25) {
                let length = leftSideArray.length
                for(let i=length; i<=25; i++) {
                    let dataRow = {}
                    dataRow.State = 'automation_per_'+i
                    dataRow.name = 'empty_data'
                    dataRow.total = 0
                    leftSideKeys.forEach((key, j) => {
                        dataRow[key] = 0
                    })
                    leftSideArray.push(dataRow)
                }
            }

            
            let data = {}
            data.name = 'LeftSide Data'
            leftSideKeys.forEach((key, j) => {
				data[key] = this.count(leftSideArray, key)
			})
            leftSideTotalData.data = data
            
            this.setState({ leftSideGraphColors, leftSideLegend, leftSideArray, leftSideKeys, leftSideHighest, leftSideTotalData, leftSideTotal, leftSideGraphId, leftSideSvgGraphId, showLeftSideRadialChart: true })
        } else {
            this.setState({ leftSideGraphColors, leftSideLegend, leftSideArray, leftSideKeys, leftSideHighest, leftSideTotalData, leftSideTotal, leftSideGraphId, leftSideSvgGraphId, showLeftSideRadialChart: false })
        }
    }

    count = (array, key) => {
        array = array.filter(arr => arr.name !== 'empty_data')
        return array.reduce(function (r, a) {
            return r + a[key];
        }, 0);
    }

	rightSideDataFormat = (data, State) => {
        let rightSideStateName = this.state.rightSideStateName === State ? 'parentData' : (State ? State : 'parentData')
		this.setState({ 
            rightSideStateName,
            showRightSideRadialChart: true,
            rightSideAutomationPlan: data.name
        },
            () => {
                if(this.state.rightSideStateName !== 'parentData') {
                    this.getRightSideStatusTrendChart()
                    this.getRightSideTrendChart()
                }
            }
        )
	}

    getRightSideStatusTrendChart = () => {
        let filterParams = {}
        if(this.state.selectedProvider && this.state.selectedProvider !== '') {
            filterParams.provider =  this.state.selectedProvider.toLowerCase()
        }
        if(this.state.selectedAccount && this.state.selectedAccount.length) {
            filterParams.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length) {
            filterParams.region = this.state.selectedRegion
        }

        let trend = {
            "start_time": momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'),
            "end_time": momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss'),
            "aggregate_by": ["status"],
            "radial": true,
            "automation_plan": this.state.rightSideAutomationPlan,
        }
        let trendParams = {...filterParams, ...trend}

        let total = {
            "automation_plan": this.state.rightSideAutomationPlan,                
            "duration_aggregate_by": ["daily","monthly", "weekly"],
        }
        let totalParams = {...filterParams, ...total}

        let params = {
            "trend": trendParams,
            "total": totalParams
        }

        if(this.state.rightSideAutomationPlan !== 'RightSide Data') {
            this.props.getApis(params, (promise, response) => {
                if(promise) {
                    this.setState({ statusSection: response, succesFailureTrendData: {}, topStatusData: {} },
                        () => {
                            this.rightSideStatusTrendSection()
                        }
                    )
                }
            })
        }
    }

    rightSideStatusTrendSection = () => {
        let graphData = {}
        let rightSideTotalSuccessRate = 0
        let rightSideTotalFailureRatre = 0
        this.state.statusSection.trend && this.state.statusSection.trend.forEach(item => {
            graphData.labels = item.dates ? item.dates : []
            item.data && Object.entries(item.data).forEach(([key,value]) => {
                if(key !== 'InProgress') {
                    graphData[key] = value
                } else if(key === 'InProgress' && Object.entries(item.data).length === 1) {
                    let array = []
                    graphData.labels.forEach(item => {
                        array.push(0)
                    })
                    graphData.Success = array
                }
            })

            rightSideTotalSuccessRate = item.rate && item.rate.success_rate ? item.rate.success_rate : ''
            rightSideTotalFailureRatre = item.rate && item.rate.failure_rate ? item.rate.failure_rate : ''
        })

        this.setState({ rightSideSuccesFailureTrendData: graphData, rightSideTotalSuccessRate, rightSideTotalFailureRatre, showRightSideStatusTrendChart: true })
    }

    getRightSideTrendChart = () => {        
        let filterParams = {}
        if(this.state.selectedProvider && this.state.selectedProvider !== '') {
            filterParams.provider =  this.state.selectedProvider.toLowerCase()
        }
        if(this.state.selectedAccount && this.state.selectedAccount.length) {
            filterParams.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length) {
            filterParams.region = this.state.selectedRegion
        }

        let trend = {
            "start_time": momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'),
            "end_time": momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss'),
            "aggregate_by": ["automation_plan", ["cost_saved", "time_saved", "duration"]],
            "automation_plan": this.state.rightSideAutomationPlan
        }
        let trendParams = {...filterParams, ...trend}

        let total = {
            "automation_plan": this.state.rightSideAutomationPlan,
            "duration_aggregate_by": ["daily", "weekly", "monthly"],
        }
        let totalParams = {...filterParams, ...total}

        let params = {
            "trend": trendParams,
            "total": totalParams
        }
        if(this.state.rightSideAutomationPlan !== 'RightSide Data') {
            this.props.getApis(params, (promise, response) => {
                if(promise) {
                    this.setState({ rightSideTrendSection: response },
                        () => this.rightSideTrend()    
                    )
                }
            })
        }
    }

    rightSideTrend = () => {
        let rightSideTrendTypes = []
        let rightSideTrendTotal = {}
        let rightMinMaxDuration = {}
        this.state.rightSideTrendSection.trend && this.state.rightSideTrendSection.trend.forEach(item => {
            item.data && Object.entries(item.data).length && Object.entries(item.data).forEach(([key, value]) => {
                if(key !== 'count') {
                    let orderBy = 0
                    if(key.toLowerCase() === 'duration') {
                        orderBy = 1
                    } else if(key.toLowerCase() === 'cost_saved') {
                        orderBy = 2
                    } else if(key.toLowerCase() === 'time_saved') {
                        orderBy = 3
                    } 
                    let typeRow = {}
                    typeRow.orderBy = orderBy
                    typeRow.label = key
                    rightSideTrendTypes.push(typeRow)

                    let graphData = {}
                    graphData.name = capitalizeTheFirstLetterOfEachWord(key.replace("_", " "))
                    graphData.labels = item.dates ? item.dates : []
                    graphData.data = value
                    graphData.unit = (key === 'cost_saved' ? '$' : (key === 'time_saved' || key === 'duration') ? 'seconds' : '')

                    this.setState({ ['rightSideTrendData_'+key]: graphData, showRightSideTrendChart: true })
                }
            })
            rightSideTrendTotal = item.total ? item.total : {}
            rightMinMaxDuration = item.duration ? item.duration : {}
        })
        this.setState({ rightSideTrendTypes: _.orderBy(rightSideTrendTypes, ['orderBy'], ['asc']), rightSideTrendTotal, rightMinMaxDuration })
    }

    leftSideDataFormat = (data, State) => {
		let leftSideStateName = this.state.leftSideStateName === State ? 'parentData' : (State ? State : 'parentData')
		this.setState({ 
            leftSideStateName,
            showLeftSideRadialChart: true,
            leftSideAutomationPlan: data.name
        },
            () => {
                if(this.state.leftSideStateName !== 'parentData') {
                    this.getLeftSideStatusTrendChart()
                    this.getLeftSideTrendChart()
                }
            }
        )
	}

    getLeftSideStatusTrendChart = () => {        
        let filterParams = {}
        if(this.state.selectedProvider && this.state.selectedProvider !== '') {
            filterParams.provider =  this.state.selectedProvider.toLowerCase()
        }
        if(this.state.selectedAccount && this.state.selectedAccount.length) {
            filterParams.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length) {
            filterParams.region = this.state.selectedRegion
        }

        let trend = {
            "start_time": momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'),
            "end_time": momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss'),
            "aggregate_by": ["status"],
            "radial": true,
            "automation_plan": this.state.leftSideAutomationPlan,
        }
        let trendParams = {...filterParams, ...trend}

        let total = {
            "automation_plan": this.state.leftSideAutomationPlan,                
            "duration_aggregate_by": ["daily","monthly", "weekly"]
        }
        let totalParams = {...filterParams, ...total}

        let params = {
            "trend": trendParams,
            "total": totalParams
        }

        if(this.state.leftSideAutomationPlan !== 'LeftSide Data') {
            this.props.getApis(params, (promise, response) => {
                if(promise) {
                    this.setState({ statusSection: response, succesFailureTrendData: {}, topStatusData: {} },
                        () => {
                            this.leftSideStatusTrendSection()
                        }
                    )
                }
            })
        }
    }

    leftSideStatusTrendSection = () => {
        let graphData = {}
        let leftSideTotalSuccessRate = 0
        let leftSideTotalFailureRatre = 0
        this.state.statusSection.trend && this.state.statusSection.trend.forEach(item => {
            graphData.labels = item.dates ? item.dates : []
            item.data && Object.entries(item.data).forEach(([key,value]) => {
                if(key !== 'InProgress') {
                    graphData[key] = value
                } else if(key === 'InProgress' && Object.entries(item.data).length === 1) {
                    let array = []
                    graphData.labels.forEach(item => {
                        array.push(0)
                    })
                    graphData.Success = array
                }
            })

            leftSideTotalSuccessRate = item.rate && item.rate.success_rate ? item.rate.success_rate : ''
            leftSideTotalFailureRatre = item.rate && item.rate.failure_rate ? item.rate.failure_rate : ''
        })

        this.setState({ leftSideSuccesFailureTrendData: graphData, leftSideTotalSuccessRate, leftSideTotalFailureRatre, showLeftSideStatusTrendChart: true })
    }

    getLeftSideTrendChart = () => {        
        let filterParams = {}
        if(this.state.selectedProvider && this.state.selectedProvider !== '') {
            filterParams.provider =  this.state.selectedProvider.toLowerCase()
        }
        if(this.state.selectedAccount && this.state.selectedAccount.length) {
            filterParams.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length) {
            filterParams.region = this.state.selectedRegion
        }

        let trend = {
            "start_time": momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'),
            "end_time": momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss'),
            "aggregate_by": ["automation_plan", ["cost_saved", "time_saved", "duration"]],
            "automation_plan": this.state.leftSideAutomationPlan
         }
         let trendParams = {...filterParams, ...trend}

        let total = {
            "automation_plan": this.state.leftSideAutomationPlan,
            "duration_aggregate_by": ["daily", "weekly", "monthly"]
        }         
        let totalParams = {...filterParams, ...total}

        let params = {
            "trend": trendParams,
            "total": totalParams
        }
        if(this.state.automationPlan !== 'LeftSide Data') {
            this.props.getApis(params, (promise, response) => {
                if(promise) {
                    this.setState({ leftSideTrendSection: response },
                        () => this.leftSideTrend()    
                    )
                }
            })
        }
    }

	leftSideTrend = () => {
        let leftSideTrendTypes = []
        let leftSideTrendTotal = {}
        let leftMinMaxDuration = {}
		this.state.leftSideTrendSection.trend && this.state.leftSideTrendSection.trend.forEach(item => {
            item.data && Object.entries(item.data).length && Object.entries(item.data).forEach(([key, value]) => {
                if(key !== 'count') {
                    let orderBy = 0
                    if(key.toLowerCase() === 'duration') {
                        orderBy = 1
                    } else if(key.toLowerCase() === 'cost_saved') {
                        orderBy = 2
                    } else if(key.toLowerCase() === 'time_saved') {
                        orderBy = 3
                    } 
                    let typeRow = {}
                    typeRow.orderBy = orderBy
                    typeRow.label = key
                    leftSideTrendTypes.push(typeRow)
                    
                    let graphData = {}
                    graphData.name = capitalizeTheFirstLetterOfEachWord(key.replace("_", " "))
                    graphData.labels = item.dates ? item.dates : []
                    graphData.data = value
                    graphData.unit = (key === 'cost_saved' ? '$' : key === 'time_saved' ? 'seconds' : '')                    
                    this.setState({ ['leftSideTrendData_'+key]: graphData, showLeftSideTrendChart: true })
                }
            })
            leftSideTrendTotal = item.total ? item.total : {}
            leftMinMaxDuration = item.duration ? item.duration : {}
        })
        this.setState({ leftSideTrendTypes: _.orderBy(leftSideTrendTypes, ['orderBy'], ['asc']), leftSideTrendTotal, leftMinMaxDuration })
	}

    render() {
        return (
            <div className="h-100">
                <div className={`loaderOverlay ${this.state.showLoading ? '' : 'displayNone'}`}>
                    <div className="overlayEqualizerLoader">
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                    </div>
                </div>
                <div className={`rounded mb-3 h-100`}>
                    {this.state.automationPerData && this.state.automationPerData.length ?
                        <div className="row text-white h-100">
                            <div className={`col-sm-6 borderMiddleBlue`} ref={this.leftSideTableRef}>
                                <div className="bg-muted p-3 rounded">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-lg-6 p-0" ref={this.leftRadialStackedBarWidthRef}>
                                                <div className="d-flex">
                                                    <label className="align-self-center mr-2 mb-0">Detection By</label>
                                                    <div className={`form-group bd-highlight mb-sm-1 styled-feild w-30`}>
                                                        <select className="form-control" 
                                                            value={this.state.leftSideChartType}
                                                            onChange={event => {
                                                                this.setState(
                                                                    {
                                                                        leftSideChartType: event.target.value
                                                                    },
                                                                    () => {
                                                                        this.onChangeGetcharts('left')
                                                                    }
                                                                )
                                                            }}>
                                                            {this.state.automationPerType && this.state.automationPerType.map(item => {
                                                                return (
                                                                    item.value !== this.state.rightSideChartType ?
                                                                        <option value={item.value}>{item.key}</option>
                                                                    : null
                                                                )
                                                            })}
                                                            
                                                        </select>
                                                    </div>
                                                </div>
                                                <p className="small text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</p>
                                                {this.state.showLeftSideRadialChart ? 
                                                    <StackedCircleBarChart
                                                        ID={this.state.leftSideGraphId}
                                                        svgID={this.state.leftSideSvgGraphId}
                                                        width={this.leftRadialStackedBarWidthRef.current.offsetWidth}
                                                        data={this.state.leftSideArray}
                                                        keys={this.state.leftSideKeys}
                                                        highestValue={this.state.leftSideHighest}
                                                        hoverData={this.state.leftSideTotalData}
                                                        totalValue={this.state.leftSideTotal}
                                                        selectedState={this.state.leftSideStateName ? this.state.leftSideStateName : ''}
                                                        tableData={(data, State) => 
                                                            this.setState({ 
                                                                showLeftSideRadialChart: !State ? true : false,
                                                                showLeftSideTrendChart: false,
                                                                showLeftSideStatusTrendChart: false 
                                                            },  
                                                                () => {
                                                                    if(State || (!State && this.state.leftSideStateName === 'parentData')) {
                                                                        this.leftSideDataFormat(data, State)
                                                                    }
                                                                }
                                                            )
                                                        }
                                                        legendText={this.state.leftSideLegend}
                                                        colors={this.state.leftSideGraphColors}
                                                    />
                                                : null}
                                            </div>
                                            <div className="col-lg-6 mt-4">
                                                <div className={``}>
                                                    {/* <p className="mb-1">{this.state.leftSideSummary ? this.state.leftSideSummary : ''}</p> */}
                                                    <p className="small mb-0">
                                                        Lorem ipsum dolor sit amet, 
                                                        <p className="text-muted small">(adipiscing elit, sed do eiusmod tempor incididunt ut)</p>
                                                    </p>
                                                    <div className="d-flex text-warning">
                                                        <h4 className="mb-0 mr-3">0325</h4>
                                                        <h3 className="mb-0 mr-2"><i className="fad fa-map-marker-alt"></i></h3>
                                                        <h3 className="mb-0 mr-2"><i className="fad fa-map-marker-alt"></i></h3>
                                                        <h3 className="mb-0 mr-2"><i className="fad fa-map-marker-alt"></i></h3>
                                                        <div className="flex-row ml-1">
                                                            <p className="mb-0 text-nowrap small">2032, 2031</p>
                                                            <p className="mb-0 text-nowrap small">2032, 2031</p>
                                                        </div>
                                                    </div>
                                                    {this.state.showLeftSideStatusTrendChart && this.state.leftSideSuccesFailureTrendData && Object.entries(this.state.leftSideSuccesFailureTrendData).length ?
                                                        <div className="mt-n3">
                                                            <ApexStackedBarChart 
                                                                graphData={this.state.leftSideSuccesFailureTrendData}
                                                                sparkline={false}
                                                                yaxis={false}
                                                                yaxisLabel={true}
                                                                xaxis={true}
                                                                xaxisFormat={'date'}
                                                                xaxisLabel={true}
                                                                axisLabelFontSize={'9px'}
                                                                axisLabelColor={'#B8BBBE'}
                                                                paddingLeft={-25}
                                                                legend={false}
                                                                stacked={true}
                                                                height={120}
                                                                horizontal={false}
                                                                barHeight={'40%'}
                                                                barEndShape={'flat'}
                                                                columnWidth={'25%'}
                                                                gradient={true}
                                                                gradientColor={['#039BE5', '#5F5BA2']}
                                                                hideTooltipValue={true}
                                                                backgroundBarShape={'flat'}
                                                                backgroundBarColors={['#333947']}
                                                                showBackgroundBarColors={true}
                                                                className={"transparentTooltip"}
                                                            />
                                                            <div className="d-flex">
                                                                <p className="mb-0 mr-2 text-gray3">Success <span className="text-success">{this.state.leftSideTotalSuccessRate ? this.state.leftSideTotalSuccessRate : 0}%</span></p>
                                                                <p className="mb-0 text-gray3">Failed <span className="text-danger">{this.state.leftSideTotalFailureRatre ? this.state.leftSideTotalFailureRatre : 0}%</span></p>
                                                            </div>
                                                        </div>
                                                    : null}
                                                    {this.state.showLeftSideTrendChart && this.state.leftSideTrendTypes && this.state.leftSideTrendTypes.length ? this.state.leftSideTrendTypes.map(item => {
                                                        return(
                                                            <div className="mt-2">
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="mb-1 d-flex">
                                                                        <p className="mb-0">{capitalizeTheFirstLetterOfEachWord(item.label.replace("_", " "))}</p>
                                                                        <p className="mb-0 text-gray3 ml-2">
                                                                            ({(
                                                                                item.label.toLowerCase() === 'cost_saved' 
                                                                                ? '$' 
                                                                                : (item.label.toLowerCase() === 'time_saved' || item.label.toLowerCase() === 'duration') ? 
                                                                                    'Seconds' 
                                                                                : 'Count'
                                                                            )})
                                                                        </p>
                                                                    </div>
                                                                    <div className={`d-flex f12 ${item.label.toLowerCase() === 'duration' ? '' : 'displayNone'}`}>
                                                                        <p className="mb-0 mr-2 text-gray3">Min <span className="text-white">{this.state.leftMinMaxDuration && this.state.leftMinMaxDuration.min ? this.state.leftMinMaxDuration.min : 0}</span></p>
                                                                        <p className="mb-0 mr-2 text-gray3">Avg <span className="text-white">{this.state.leftMinMaxDuration && this.state.leftMinMaxDuration.avg ? this.state.leftMinMaxDuration.avg : 0}</span></p>
                                                                        <p className="mb-0 text-gray3">Max <span className="text-white">{this.state.leftMinMaxDuration && this.state.leftMinMaxDuration.max ? this.state.leftMinMaxDuration.max : 0}</span></p>
                                                                    </div>
                                                                </div>
                                                                <div className={`d-flex`}>
                                                                    <div className={`${item.label.toLowerCase() === 'duration' ? 'displayNone' : 'p-0 col-sm-3 mt-4 align-self-start mr-2'}`}>
                                                                        <div className="bg-black5 px-2 py-1 rounded">
                                                                            <h6 className="text-success-50 mb-1">
                                                                                {this.state.leftSideTrendTotal && this.state.leftSideTrendTotal[item.label] !== 'undefined' ? 
                                                                                    item.label.toLowerCase() === 'cost_saved' ? 
                                                                                        '$' + this.state.leftSideTrendTotal[item.label] ? this.state.leftSideTrendTotal[item.label] : 0
                                                                                    : (item.label.toLowerCase() === 'time_saved' || item.label.toLowerCase() === 'duration') ?
                                                                                        this.state.leftSideTrendTotal[item.label] ? (this.state.leftSideTrendTotal[item.label] / 3600).toFixed(2) : 0
                                                                                    : null
                                                                                : null}
                                                                            </h6>
                                                                            <div className="bg-success-3 small-divder"></div>
                                                                            <p className="text-success-50 mt-1 mb-0">
                                                                                {item.label.toLowerCase() === 'cost_saved' ? 
                                                                                    <span>USD</span>
                                                                                : (item.label.toLowerCase() === 'time_saved' || item.label.toLowerCase() === 'duration') ?
                                                                                    <span>hrs</span>
                                                                                : null}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className={`${item.label === 'duration' ? 'w-100' : ''}`}>
                                                                        <React.Fragment>
                                                                            
                                                                            <ApexBarChart
                                                                                graphData={this.state['leftSideTrendData_'+item.label]}
                                                                                sparkline={false}
                                                                                yaxis={false}
                                                                                xaxis={true}
                                                                                xaxisLabel={true}
                                                                                axisLabelFontSize={'9px'}
                                                                                axisLabelColor={'#B8BBBE'}
                                                                                paddingLeft={-5}
                                                                                legend={false}
                                                                                stacked={false}
                                                                                height={120}
                                                                                horizontal={false}
                                                                                barHeight={'40%'}
                                                                                barEndShape={'flat'}
                                                                                barRadius={6}
                                                                                columnWidth={'25%'}
                                                                                gradient={true}
                                                                                gradientColor={['#19B2C0', '#CBF2D5']}
                                                                                backgroundBarShape={'flat'}
                                                                                backgroundBarColors={['#333947']}
                                                                                showBackgroundBarColors={true}
                                                                                className={"transparentTooltip"}
                                                                            />
                                                                        
                                                                        </React.Fragment>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                        })
                                                    : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`col-md-6 mb-n4`} ref={this.rightSideTableRef}>
                                <div className={`bg-muted p-3 rounded`}>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className={`col-lg-6 p-0`} ref={this.rightRadialStackedBarWidthRef}>
                                                <div className="d-flex">
                                                    <label className="align-self-center mr-2 mb-0">Detection By</label>
                                                    <div className={`form-group bd-highlight mb-sm-1 styled-feild w-30`}>
                                                        <select className="form-control" 
                                                            value={this.state.rightSideChartType}
                                                            onChange={event => {
                                                                this.setState(
                                                                    {
                                                                        rightSideChartType: event.target.value
                                                                    },
                                                                    () => {
                                                                        this.onChangeGetcharts('right')
                                                                    }
                                                                )
                                                            }}>
                                                            {this.state.automationPerType && this.state.automationPerType.map(item => {
                                                                return (
                                                                    item.value !== this.state.leftSideChartType ?
                                                                        <option value={item.value}>{item.key}</option>
                                                                    : null
                                                                )
                                                            })}
                                                            
                                                        </select>
                                                    </div>
                                                </div>
                                                <p className="small text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</p>
                                                {this.state.showRightSideRadialChart ? 
                                                    <StackedCircleBarChart
                                                        ID={this.state.rightSideGraphId}
                                                        svgID={this.state.rightSideSvgGraphId}
                                                        width={this.rightRadialStackedBarWidthRef.current.offsetWidth}
                                                        data={this.state.rightSideArray}
                                                        keys={this.state.rightSideKeys}
                                                        highestValue={this.state.rightSideHighest}
                                                        hoverData={this.state.rightSideTotalData}
                                                        totalValue={this.state.rightSideTotal}
                                                        selectedState={this.state.rightSideStateName ? this.state.rightSideStateName : ''}
                                                        tableData={(data, State) => 
                                                            this.setState({ 
                                                                showRightSideRadialChart: !State ? true : false,
                                                                showRightSideTrendChart: false,
                                                                showRightSideStatusTrendChart: false,
                                                            },  
                                                                () => {
                                                                    if(State || (!State && this.state.rightSideStateName === 'parentData')) {
                                                                        this.rightSideDataFormat(data, State)
                                                                    }
                                                                }
                                                            )
                                                        }
                                                        legendText={this.state.rightSideLegend}
                                                        colors={this.state.rightSideGraphColors}
                                                    />
                                                : null}
                                            </div>
                                            <div className="col-lg-6">
                                                <span className={`justify-content-end d-flex`}>
                                                    <i className="fa fa-compress cursorPointer" 
                                                        onClick={() => this.setState({ rightSideChartType: 'detections_by_standard'},
                                                            () => {
                                                                this.onChangeGetcharts('right')
                                                                this.props.showExpandedView()
                                                            }
                                                        )}
                                                    ></i>
                                                </span>
                                                <p className="small mb-0 mt-2">
                                                    Lorem ipsum dolor sit amet, 
                                                    <p className="text-muted small">(adipiscing elit, sed do eiusmod tempor incididunt ut)</p>
                                                </p>
                                                <div className="d-flex text-warning">
                                                    <h4 className="mb-0 mr-3">0325</h4>
                                                    <h3 className="mb-0 mr-2"><i className="fad fa-map-marker-alt"></i></h3>
                                                    <h3 className="mb-0 mr-2"><i className="fad fa-map-marker-alt"></i></h3>
                                                    <h3 className="mb-0 mr-2"><i className="fad fa-map-marker-alt"></i></h3>
                                                    <div className="flex-row ml-1">
                                                        <p className="mb-0 text-nowrap small">2032, 2031</p>
                                                        <p className="mb-0 text-nowrap small">2032, 2031</p>
                                                    </div>
                                                </div>
                                                
                                                {this.state.showRightSideStatusTrendChart && this.state.rightSideSuccesFailureTrendData && Object.entries(this.state.rightSideSuccesFailureTrendData).length ?
                                                    <div className="mt-n3">
                                                        <ApexStackedBarChart 
                                                            graphData={this.state.rightSideSuccesFailureTrendData}
                                                            sparkline={false}
                                                            yaxis={false}
                                                            yaxisLabel={true}
                                                            xaxis={true}
                                                            xaxisFormat={'date'}
                                                            xaxisLabel={true}
                                                            axisLabelFontSize={'9px'}
                                                            axisLabelColor={'#B8BBBE'}
                                                            paddingLeft={-25}
                                                            legend={false}
                                                            stacked={true}
                                                            height={120}
                                                            horizontal={false}
                                                            barHeight={'40%'}
                                                            barEndShape={'flat'}
                                                            columnWidth={'25%'}
                                                            gradient={true}
                                                            gradientColor={['#039BE5', '#5F5BA2']}
                                                            hideTooltipValue={true}
                                                            backgroundBarShape={'flat'}
                                                            backgroundBarColors={['#333947']}
                                                            showBackgroundBarColors={true}
                                                            className={"transparentTooltip"}
                                                        />
                                                        <div className="d-flex">
                                                            <p className="mb-0 mr-2 text-gray3">Success <span className="text-success">{this.state.rightSideTotalSuccessRate ? this.state.rightSideTotalSuccessRate : 0}%</span></p>
                                                            <p className="mb-0 text-gray3">Failed <span className="text-danger">{this.state.rightSideTotalFailureRatre ? this.state.rightSideTotalFailureRatre : 0}%</span></p>
                                                        </div>
                                                    </div>
                                                : null}
                                                {this.state.showRightSideTrendChart && this.state.rightSideTrendTypes && this.state.rightSideTrendTypes.length ? this.state.rightSideTrendTypes.map(item => {
                                                    return(
                                                        <div className="mt-2">
                                                            {/* <div className="mb-1 d-flex">
                                                                <p className="mb-0">{capitalizeTheFirstLetterOfEachWord(item.label.replace("_", " "))}</p>
                                                                <p className="mb-0 text-gray3 ml-2">({(item.label.toLowerCase() === 'cost_saved' ? '$' : (item.label.toLowerCase() === 'time_saved' || item.label.toLowerCase() === 'duration') ? 'Seconds' : 'Count')})</p>
                                                            </div> */}
                                                            <div className="d-flex justify-content-between">
                                                                <div className="mb-1 d-flex">
                                                                    <p className="mb-0">{capitalizeTheFirstLetterOfEachWord(item.label.replace("_", " "))}</p>
                                                                    <p className="mb-0 text-gray3 ml-2">
                                                                        ({(
                                                                            item.label.toLowerCase() === 'cost_saved' 
                                                                            ? '$' 
                                                                            : (item.label.toLowerCase() === 'time_saved' || item.label.toLowerCase() === 'duration') ? 
                                                                                'Seconds' 
                                                                            : 'Count'
                                                                        )})
                                                                    </p>
                                                                </div>
                                                                <div className={`d-flex f12 ${item.label.toLowerCase() === 'duration' ? '' : 'displayNone'}`}>
                                                                    <p className="mb-0 mr-2 text-gray3">Fast <span className="text-white">{this.state.rightMinMaxDuration && this.state.rightMinMaxDuration.min ? this.state.rightMinMaxDuration.min : 0}</span></p>
                                                                    <p className="mb-0 mr-2 text-gray3">Avg <span className="text-white">{this.state.rightMinMaxDuration && this.state.rightMinMaxDuration.avg ? this.state.rightMinMaxDuration.avg : 0}</span></p>
                                                                    <p className="mb-0 text-gray3">Slow <span className="text-white">{this.state.rightMinMaxDuration && this.state.rightMinMaxDuration.max ? this.state.rightMinMaxDuration.max : 0}</span></p>
                                                                </div>
                                                            </div>
                                                            <div className={`d-flex`}>
                                                                <div className={`${item.label.toLowerCase() === 'duration' ? 'displayNone' : 'p-0 col-sm-3 mt-4 align-self-start mr-2'}`}>
                                                                    <div className="bg-black5 px-2 py-1 rounded">
                                                                        <h6 className="text-success-50 mb-1">
                                                                            {this.state.rightSideTrendTotal && this.state.rightSideTrendTotal[item.label] !== 'undefined' ? 
                                                                                item.label.toLowerCase() === 'cost_saved' ? 
                                                                                    '$' + this.state.rightSideTrendTotal[item.label] ? this.state.rightSideTrendTotal[item.label] : 0
                                                                                : (item.label.toLowerCase() === 'time_saved' || item.label.toLowerCase() === 'duration') ?
                                                                                    this.state.rightSideTrendTotal[item.label] ? (this.state.rightSideTrendTotal[item.label] / 3600).toFixed(2) : 0
                                                                                : null
                                                                            : null}
                                                                        </h6>
                                                                        <div className="bg-success-3 small-divder"></div>
                                                                        <p className="text-success-50 mt-1 mb-0">
                                                                            {item.label.toLowerCase() === 'cost_saved' ? 
                                                                                <span>USD</span>
                                                                            : (item.label.toLowerCase() === 'time_saved' || item.label.toLowerCase() === 'duration') ?
                                                                                <span>hrs</span>
                                                                            : null}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className={`${item.label === 'duration' ? 'w-100' : ''}`}>
                                                                    <ApexBarChart
                                                                        graphData={this.state['rightSideTrendData_'+item.label]}
                                                                        sparkline={false}
                                                                        yaxis={false}
                                                                        xaxis={true}
                                                                        xaxisLabel={true}
                                                                        axisLabelFontSize={'9px'}
                                                                        axisLabelColor={'#B8BBBE'}
                                                                        paddingLeft={-5}
                                                                        legend={false}
                                                                        stacked={false}
                                                                        height={120}
                                                                        horizontal={false}
                                                                        barHeight={'40%'}
                                                                        barEndShape={'flat'}
                                                                        barRadius={6}
                                                                        columnWidth={'25%'}
                                                                        gradient={true}
                                                                        gradientColor={['#19B2C0', '#CBF2D5']}
                                                                        backgroundBarShape={'flat'}
                                                                        backgroundBarColors={['#333947']}
                                                                        showBackgroundBarColors={true}
                                                                        className={"transparentTooltip"}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                    })
                                                : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    : 
                        <div className='d-flex justify-content-center mt-5 text-white'>
                            <h6>There are no data on this criteria. Please try adjusting your filter.</h6>
                            <span className={`cursorPointer text-info ml-2`} onClick={() => this.props.showExpandedView()}>Back to dashboard</span>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
AutomationPerView.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log(state,'--------------')
    return {
        aiopsAutomationFilters: state.aiops.aiopsPropsDetails.aiopsAutomationFilters
    }
    
}

export default connect(mapStateToProps, {
    getApis,
    getTotalCount,
})(withRouter(AutomationPerView))