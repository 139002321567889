/*************************************************
 * Tvastar
 * @exports
 * @file IamAction.js
 * @author Rajasekar // on 27/03/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from '../types'
import {
	SYNCHRONIZER,
	LIST_ASSETS,
	
	COMMON_SERVICES,
	LIST_EVENTS,

	IAM_GOVERNANCE,
	CHECK_POLICY_NAME,
	GENERATE_POLICY,
	CREATE_POLICY,
	REMOVE_POLICY,

	GOVERNANCE,
	LIST_IAM_CONDITIONS,
	
} from '../../config'
import { API } from 'aws-amplify'
import { myLog } from '../../utils/utility'
import _ from 'lodash'
/**
 * Action to set create IAM details
 */
export const setCreatePolicyDetails = (key, value) => {
	return async dispatch => {
		dispatch({
			type: ACTION_TYPES.SET_CREATE_POLICY_DETAILS,
			payload: { [key]: value },
		})
	}
}
/**
 * Action to empty
 */
export const setCreatePolicyDetailsEmpty = () => {
	return async dispatch => {
		dispatch({
			type: ACTION_TYPES.SET_CREATE_POLICY_DETAILS_EMPTY,
		})
	}
}
/**
 * Action to set edit policy
 */
export const setEditPolicyDetailsObjectInStore = object => {
	return async dispatch => {
		dispatch({
			type: ACTION_TYPES.SET_CREATE_POLICY_DETAILS,
			payload: object,
		})
	}
}
/**
 * Action to set edit policy
 */
export const setPolicyCategoriesSelectedForEdit = categories => {
	return async dispatch => {
		dispatch({
			type: ACTION_TYPES.SET_EDIT_CATEGORY_SELECTED,
			payload: categories,
		})
	}
}
/**
 * Action to get all assets list
 * @param {Object} body
 * @param {Function} callback
 */
export const listIamAssets = (body, type, callback) => {
	return async dispatch => {
		try {
			if (type === 'roles') {
				const assetsList = await API.post(SYNCHRONIZER, LIST_ASSETS, { body })
				dispatch({
					type: ACTION_TYPES.SET_IAM_ROLES_ASSETS_LIST,
					payload: assetsList,
				})
				callback(true)
			}
			if (type === 'users') {
				const assetsList = await API.post(SYNCHRONIZER, LIST_ASSETS, { body })
				dispatch({
					type: ACTION_TYPES.SET_IAM_USERS_ASSETS_LIST,
					payload: assetsList,
				})
				callback(true)
			}
		} catch (error) {
			myLog('assetsList-iam', error)
			callback(false)
		}
	}
}

const form_category_map = (service, response) => {
	let results = {}
	let service_name = (service.includes('-') ? service.split('-')[0] : service) + ':'
	if (response && response.events) {
		response.events.forEach(event => {
			event.event_category.forEach(category => {
				let usecases = []
				category.role_case.forEach(item => {
					usecases.push({ usecase: item, api: service_name + event.name })
				})
				if (_.has(results, category.role_category)) {
					usecases.forEach(usecase_item => {
						let index = _.findIndex(results[category.role_category]['usecases'], { usecase: usecase_item.usecase })
						if (index >= 0) {
							results[category.role_category]['usecases'][index]['api'].push(usecase_item.api)
						} else {
							let actions = []
							actions.push(usecase_item.api)
							results[category.role_category]['usecases'].push({ usecase: usecase_item.usecase, api: actions })
						}
					})
				} else {
					let modified_usecases = []
					usecases.forEach(usecase_item => {
						let actions = []
						actions.push(usecase_item.api)
						modified_usecases.push({ usecase: usecase_item.usecase, api: actions })
					})
					results[category.role_category] = {
						usecases: modified_usecases,
					}
				}
			})
		})
	}
	return results
}

/**
 * Action to check policy name
 * @param {Object} body
 * @param {Function} callback
 */
export const checkPolicyName = (body, callback) => {
	return async () => {
		try {
			const res = await API.post(IAM_GOVERNANCE, CHECK_POLICY_NAME, { body })
			if (res.status) callback(true)
			else callback(false, res)
		} catch (error) {
			myLog('checkPolicyName-iam', error)
			callback(false)
		}
	}
}
/**
 * Action to list events
 * @param {Object} body
 * @param {Function} callback
 */
export const listEvents = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(COMMON_SERVICES, LIST_EVENTS, { body })
			if (response.length) {
				let data = {}
				data.categories = form_category_map(body.service, response[0])
				data.roleConditions = response[0].role_conditions ? response[0].role_conditions : []
				data.globalConditions = response[0].global_conditions ? response[0].global_conditions : []
				data.accessTagConditions = response[0].access_tag_conditions ? response[0].access_tag_conditions : []
				dispatch({
					type: ACTION_TYPES.SET_IAM_EVENTS_CONDITIONS,
					payload: data,
				})
				callback(true)
			} else {
				callback(false)
				dispatch({
					type: ACTION_TYPES.SET_IAM_EVENTS_CONDITIONS_EMPTY,
				})
			}
		} catch (error) {
			myLog('listEvents-iam', error)
			callback(false)
			dispatch({
				type: ACTION_TYPES.SET_IAM_EVENTS_CONDITIONS_EMPTY,
			})
		}
	}
}
/**
 * Action to list iam conditions
 * @param {Object} body
 * @param {Function} callback
 */
export const listIamConditions = (body, callback) => {
	return async dispatch => {
		try {
			const res = await API.post(GOVERNANCE, LIST_IAM_CONDITIONS, { body })
			if (res.results) {
				let data = {}
				data.service_conditions = res && res.results.service_conditions
				data.global_conditions = res && res.results.global_conditions
				dispatch({
					type: ACTION_TYPES.SET_IAM_EVENTS_CONDITIONS,
					payload: data,
				})
				callback(true, res.results)
			} else {
				callback(false, null)
				dispatch({
					type: ACTION_TYPES.SET_IAM_EVENTS_CONDITIONS_EMPTY,
				})
			}
		} catch (error) {
			myLog('listEvents-iam', error)
			callback(false)
			dispatch({
				type: ACTION_TYPES.SET_IAM_EVENTS_CONDITIONS_EMPTY,
			})
		}
	}
}
/**
 * Action to generate policy
 * @param {Object} body
 * @param {Function} callback
 */
export const generatePolicy = (body, callback) => {
	return async () => {
		try {
			const res = await API.post(IAM_GOVERNANCE, GENERATE_POLICY, { body })
			if (res) {
				callback(true, res)
			} else {
				callback(false, null)
			}
		} catch (error) {
			myLog('generatePolicy-iam', error)
			callback(false)
		}
	}
}

/**
 * Action to create policy
 * @param {Object} body
 * @param {Function} callback
 */
export const createPolicy = (body, callback) => {
	return async () => {
		try {
			const res = await API.post(IAM_GOVERNANCE, CREATE_POLICY, { body })
			if (res) {
				callback(true, res)
			} else {
				callback(false, null)
			}
		} catch (error) {
			myLog('createPolicy-iam', error)
			callback(false)
		}
	}
}
/**
 * Action to remove policy
 * @param {Object} body
 * @param {Function} callback
 */
export const removePolicy = (body, callback) => {
	return async (dispatch, getState) => {
		try {
			const res = await API.post(IAM_GOVERNANCE, REMOVE_POLICY, { body })
			if (res.status) {
				let policies = getState().governance.policies
				let policiesList = policies.results
				_.remove(policiesList, n => {
					return n.policy_details.Arn === body.policy_arn
				})
				dispatch({
					type: ACTION_TYPES.SET_POLICY_LIST,
					payload: policies,
				})
				dispatch({
					type: ACTION_TYPES.SET_POLICY_SELECTED_TABLE_ROW,
					payload: policiesList[0],
				})
				callback(true, res)
			} else callback(false, res)
		} catch (error) {
			myLog('removePolicy-iam', error)
			callback(false)
		}
	}
}
