/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AnomaliesLeftSection.js
 * @author Prakash // on 19/10/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { UncontrolledTooltip } from 'reactstrap'
import { listAlertGroups, getAlertGroupDailyTrend } from '../../../actions/aiops/AlertAction'
import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'

import { getObservabilityAnomalyDailyTrend } from '../../../actions/aiops/ObservabilityAction'
import { listObservabilityAnomalyResults } from '../../../actions/aiops/ObservabilityAction'

import SearchWithHiddenInput from '../../common/SearchWithHiddenInput'

import { momentDateGivenFormat, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, capitalizeFirstLetter, twoDateTimeInterval, subMinutes, subHours, addMinutes, subDays, twoDateDiffrence } from '../../../utils/utility'
import _ from 'lodash'

import Chart from 'react-apexcharts'

let timer = null;

class AnomaliesLeftSection extends Component {
    
    constructor(props) {

        super(props)
        this.state = {
            showLoading: true,

            selectedFilterType: 'H',
            selectedFilterPeriod: 1,
            
            groupType: 'All',
            groupedBy: 'severity',
            selectedCategory: 'Important / All',
        }
    }

	componentDidMount = () => {
        if(this.props.caseDetails  && Object.entries(this.props.caseDetails).length ) {
            this.changeGraphTimings()
        }
    }
        
    componentDidUpdate = (prevProps) => {
        if(this.props.caseDetails && Object.entries(this.props.caseDetails).length && prevProps.caseDetails !== this.props.caseDetails) {
            this.changeGraphTimings()
        }
    }

    changeGraphTimings = () => {
        this.setState({ brushStartDate: '', brushEndDate: '' }, 
            () => {
                let caseStartTime = this.props.caseDetails.created_at
                let caseEndTime = addMinutes(this.props.caseDetails.last_event, 1)

                if(caseEndTime < caseStartTime) {
                    caseEndTime = caseStartTime
                }

                let selectedFilterType = this.state.selectedFilterType
                let selectedFilterPeriod = this.state.selectedFilterPeriod
    
                let sliderStartDate = caseStartTime
                let sliderEndDate = caseEndTime
                
                if(selectedFilterType === 'D') {
                    sliderStartDate = subDays(caseStartTime, selectedFilterPeriod)
                } else if(selectedFilterType === 'H') {
                    sliderStartDate = subHours(caseStartTime, selectedFilterPeriod)
                }

                let sliderObj = {}
                sliderObj.sliderStartDate = sliderStartDate
                sliderObj.sliderEndDate = sliderEndDate
                sliderObj.selectedFilterPeriod = selectedFilterPeriod
                sliderObj.selectedFilterType = selectedFilterType
                this.props.setAiopsPropsDetails('anomaly_slider_changes', sliderObj)
    
                this.setState({ caseStartTime, caseEndTime, sliderStartDate, sliderEndDate },
                    () => {
                        this.anomalySection()
                        this.listObservabilityAnomalyResults(this.state.sliderStartDate, this.state.sliderEndDate)
                        this.props.setAiopsPropsDetails('anomalyDetails', {})
                        this.props.setAiopsPropsDetails('anomalyMonitoringList', {})
                        this.props.setAiopsPropsDetails('anomalySreMetricsList', {})
                        // this.props.setAiopsPropsDetails('anomalyMonitoringDetails', {})
                        // this.props.setAiopsPropsDetails('anomalyMonitoringEvent', {})
                        // this.props.setAiopsPropsDetails('anomalySreMonitoringDetails', {})
                    }
                )   
            }
        )
    }

    anomalySection = () => {
        let params = {}
        // params.alerts_case_id = this.props.caseDetails.case_id
        params.start_time = this.state.sliderStartDate
        params.end_time = this.state.sliderEndDate
        params.duration_aggregate_by = "seconds"

        this.props.getObservabilityAnomalyDailyTrend(params, (promise, response) => {
            if(promise) {
                let results = response && response.results && response.results.length ? response.results[0] : {}
                this.setState({ showLoading: false, anomalyDailyTrend: results },
                    () => {
                        this.brushDuration()
                    }
                )
            } else {
                this.setState({ showLoading: false, anomalyDailyTrend: {} })
            }
        })

    }

    brushDuration = () => {
        let sliderStartDate = this.state.sliderStartDate
        let sliderEndDate = this.state.sliderEndDate
        
        let difference = twoDateTimeInterval(this.state.sliderStartDate, this.state.sliderEndDate)

        let brushStartDate = sliderStartDate
        let brushEndDate = sliderEndDate

        if(difference && difference.length) {
            if(difference[0] > 0) {
                if(difference[0] === 1) {
                    brushStartDate = subHours(brushEndDate, 1)
                } else if(difference[0] < 2) {
                    brushStartDate = subHours(brushEndDate, 3)
                } else if(difference[0] < 3) {
                    brushStartDate = subHours(brushEndDate, 4)
                } else {
                    brushStartDate = subHours(brushEndDate, 8)
                }
            } else if(difference[1] > 0) {
                if(difference[1] <= 2) {
                    brushStartDate = subMinutes(brushEndDate, 20)
                } else if(difference[1] < 3) {
                    brushStartDate = subMinutes(brushEndDate, 30)
                } else if(difference[1] < 6) {
                    brushStartDate = subMinutes(brushEndDate, 50)
                } else if(difference[1] < 12) {
                    brushStartDate = subMinutes(brushEndDate, 1)
                } else if(difference[1] < 24) {
                    brushStartDate = subHours(brushEndDate, 1)
                }
            } else {
                if(difference[2] <= 2) {
                    brushStartDate = subMinutes(brushEndDate, .1)
                } else if(difference[2] <= 5) {
                    brushStartDate = subMinutes(brushEndDate, .5)
                } else if(difference[2] <= 10) {
                    brushStartDate = subMinutes(brushEndDate, 1)
                } else {
                    brushStartDate = subMinutes(brushEndDate, 5)
                }
            }
        }

        //convert brush time to local
        if(this.state.caseStartTime < brushEndDate) {
            brushStartDate = momentConvertionUtcToLocalTime(this.state.caseStartTime, 'YYYY-MM-DD HH:mm:ss')
        } else {
            brushStartDate = momentConvertionUtcToLocalTime(brushStartDate, 'YYYY-MM-DD HH:mm:ss')
        }
        brushEndDate = momentConvertionUtcToLocalTime(brushEndDate, 'YYYY-MM-DD HH:mm:ss')

        this.setState({ brushStartDate, brushEndDate, brushBarOptions: {}, }, 
            () => {
                this.anomalyBrushGraph()
            }
        )
    }

    anomalyBrushGraph = () => {
        let graphDetails = this.state.anomalyDailyTrend
        let array = []
        let anomalyTotalCount = 0
        if(graphDetails && graphDetails.data && graphDetails.data) {
            let graphLabels = graphDetails.dates
            anomalyTotalCount = graphDetails.data.reduce((a, b) => a + b, 0)

            graphDetails.data.forEach((val, index) => {
                let arrayValues = []
                let time = momentConvertionUtcToLocalTime(graphLabels[index], 'YYYY-MM-DD HH:mm:ss')
                arrayValues.push(time)
                arrayValues.push(val) 
                array.push(arrayValues)
            })

            if(!graphLabels.includes(momentConvertionLocalToUtcTime(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:00'))) {
                let arrayValues = []
                let time = momentConvertionUtcToLocalTime(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:00')
                arrayValues.push(time)
                arrayValues.push(0) 
                array.push(arrayValues)
            }
            
            if(!graphLabels.includes(momentConvertionLocalToUtcTime(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:00'))) {
                let arrayValues = []
                let time = momentConvertionUtcToLocalTime(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:00')
                arrayValues.push(time)
                arrayValues.push(0) 
                array.push(arrayValues)
            }

            console.log("array", array)
            
            let brushBarSeries = [{
                data: array
            }]

            let xaxis = {
                tickAmount: 50,
                type: 'datetime',
                labels: {
                    datetimeUTC: false,
                    style: {
                        colors: '#60687C'
                    },
                },
                axisTicks: {
                    show: false
                },
                tooltip: {
                    enabled: false,
                },
                axisBorder: {
                    show: true,
                    color:'#434959',
                    widht: 2,
                    // offsetX: -15,
                    // offsetY: 0
                },
            }

            // let sliderObj = {}
            // sliderObj.brushStartDate = this.state.brushStartDate
            // sliderObj.brushEndDate = this.state.brushEndDate
            // this.props.setAiopsPropsDetails('anomalies_brush_changes', sliderObj)

            let columnWidth = '70%'
            if(brushBarSeries[0].data.length <= 10) {
                columnWidth = '10%'
            } else if(brushBarSeries[0].data.length <= 20) {
                columnWidth = '15%'
            } else if(brushBarSeries[0].data.length <= 30) {
                columnWidth = '20%'
            } else if(brushBarSeries[0].data.length <= 40) {
                columnWidth = '25%'
            } else if(brushBarSeries[0].data.length <= 50) {
                columnWidth = '30%'
            }
            
            let brushBarOptions = {
                chart: {
                    redrawOnParentResize: true,
                    id: 'chart1',
                    height: 130,
                    type: 'bar',
                    toolbar: {
                        show: false
                    },
                    brush:{
                        target: 'chartx',
                        enabled: true
                    },
                    selection: {
						enabled: true,
						fill: {
							color: "#c9ebf5",
							opacity: 0.1
						},  
						stroke: {
							show: false,
							curve: 'straight',
							lineCap: 'round',
							colors: '#039BE5',
							// colors: '#00A3CB',
							width: 0,
							dashArray: 0,      
						},
						xaxis: {
							min: this.getTimeLocal(this.state.brushStartDate),
                            max: this.getTimeLocal(this.state.brushEndDate)
						},
						style: {
							fontSize: '28px',
							color: '#000000'
						}
					},
                    events: {
                        selection: (chartContext, { xaxis, yaxis }) => {
                            this.brushSelection(xaxis)
                        },
                        // click: (event, chartContext, config) => {
                        //     this.setSliderPropDetails()
                        // },
                    }
                    // events:{
                    //     selection: (chartContext, { xaxis, yaxis }) => {
                    //         var minDate = new Date(xaxis.min);
                    //         var maxDate = new Date(xaxis.max);
                    //         let brushStartDate = momentDateGivenFormat(minDate, 'YYYY-MM-DD HH:mm:ss');
                    //         let brushEndDate = momentDateGivenFormat(maxDate, 'YYYY-MM-DD HH:mm:ss');
                    //         this.setState({ brushStartDate, brushEndDate },
                    //             () => {
                    //                 this.getAttribute()
                    //             }
                    //         )
                    //     },
                    //     click: (event, chartContext, config) => {
                    //         this.setSliderPropDetails()
                    //     },
                    // }
                },
                stroke: {
                    width: 0
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        endingShape: 'rounded',
                        columnWidth: '1%',
                    }
                },

                colors: ['#19B2C0'],
                fill: {
                    colors: ['#039BE5'],
                    type: 'gradient', // gradient
                    gradient: {
                        type: 'vertical', // The gradient in the horizontal direction
                        gradientToColors: ['#5F5BA2'], // The color at the end of the gradient
                        opacityFrom: .9, // transparency
                        opacityTo: 1,
                        stops:[0, 120]
                    }
                },
                xaxis: xaxis,
                yaxis: {
                    show: false,
                    labels: {
                        show: false
                    }
                },            
                grid: {
                    position: 'back',
                    borderColor: '#2D323E',
                    show: true,
                    xaxis: {
                        lines: {
                            show: false
                        },
                    },
                    yaxis: {
                        lines: {
                            show: true
                        }
                    },
                    padding: {
                        top: 0,
                        right: 20,
                        bottom: 0,
                        left: 0
                    },
                },
                    
                tooltip: {
                    enabled: true,
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        show: false
                    },
                    marker: {
                        show: false
                    },
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]                    
                        let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
                        dateLable = dateLable.toString()
                        dateLable = momentDateGivenFormat(dateLable, 'DD MMM Y HH:mm:ss')
                        return '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+dateLable+'</div><div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[0] +'" ></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">Alerts</span><span class="font-weight-normal ml-2">'+val+'</span></div></div></div></div>'
                    },
                },
                annotations: {
                    position: 'back' ,
                    xaxis: [{
                        x:  new Date(momentConvertionUtcToLocalTime(this.state.caseStartTime, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                        strokeDashArray: 0,
                        borderColor: '#FF6F00',
                        label: {
                            borderColor: '#FF6F00',
                            style: {
                                // fontSize: '12px',
                                color: '#fff',
                                background: '#FF6F00'
                            },
                            orientation: 'horizontal',
                            text: 'CS',
                        }
                    }]
                }
            }

            this.setState({ brushBarSeries, brushBarOptions, anomalyTotalCount })
        } else {
            this.setState({ brushBarSeries: [], brushBarOptions: {} })
        }
    }

    resetGraph = () => {
        this.brushDuration()
        this.filterData()
    }

    brushSelection = (xaxis, type) => {
        clearTimeout(timer)
        let brushStartDate = momentDateGivenFormat(xaxis.min, 'YYYY-MM-DD HH:mm:ss')
        let brushEndDate = momentDateGivenFormat(xaxis.max, 'YYYY-MM-DD HH:mm:ss')
        if(this.state.brushStartTime && this.state.brushStartTime !== "" && (this.state.brushStartTime !== this.state.sliderStartDate || this.state.brushEndTime !== this.state.sliderEndDate)) {
            timer = setTimeout(() => this.filterData("brush"), 1000)
        }
        
        if(brushStartDate && brushEndDate) {
            this.setState({ brushStartDate, brushEndDate, brushStartTime: brushStartDate, brushEndTime: brushEndDate },
                () => {
                    this.getAttribute()
                }    
            )
        }
    }
    
    getAttribute = () => {
        var attributes = document.querySelector('.caseTimeAnnotation .apexcharts-selection-rect') && document.querySelector('.caseTimeAnnotation .apexcharts-selection-rect').attributes
        if(attributes) {
            let width = attributes.width.value

            let x1 = parseInt(attributes.x.value)
            let x2 = parseInt(attributes.x.value) + parseInt(width)

            let leftLPosition = x1
            let rightLPosition = x2
            let diff = twoDateDiffrence(this.state.brushStartDate, this.state.brushEndDate)
            if(diff < 8) {
                leftLPosition = x1 - 20
                if(diff < 4) {
                    leftLPosition = x1 - 40
                }
                rightLPosition = x2
            }

            var d1 = document.getElementById('leftSliderSpan');
            d1.style.position = "absolute";
            d1.style.left = leftLPosition+'px';
            d1.style.top = '95px';
            d1.style.color = '#039BE5';
            
            var d2 = document.getElementById('rightSliderSpan');
            d2.style.position = "absolute";
            d2.style.left = rightLPosition+'px';
            d2.style.top = '95px';
            d2.style.color = '#039BE5';

            let caseTime = twoDateTimeInterval(this.state.sliderStartDate, this.state.sliderEndDate)
            let sliderBelowStartDate = ''
            let sliderBelowEndDate = ''
            // if(width > 40) {
            if(caseTime[0] > 0) {
                sliderBelowStartDate = momentDateGivenFormat(this.state.brushStartDate, 'DD MMM')
                sliderBelowEndDate = momentDateGivenFormat(this.state.brushEndDate, 'DD MMM')
            } else {
                sliderBelowStartDate = momentDateGivenFormat(this.state.brushStartDate, 'HH:mm')
                sliderBelowEndDate = momentDateGivenFormat(this.state.brushEndDate, 'HH:mm')
            }
            // }
            this.setState({ sliderBelowStartDate, sliderBelowEndDate })
        }
    }

    listObservabilityAnomalyResults = (startTime, endTime) => {
        let params = {}
        params.start_time = startTime
        params.end_time = endTime
        params.aggregate_by = this.state.groupedBy
        if(this.state.groupType !== 'All') {
            params.anomaly_type = this.state.groupType
        }

        this.props.listObservabilityAnomalyResults(params, (promise, response) => {

            if(promise) {
                let data = response.results && response.results.details ? response.results.details : []
                this.setState({ anomalyResponse: data, showGroupLoading: false },
                    () => this.structureAnomalyData()
                )
            } else {
                this.setState({ anomalyResponse: [], showGroupLoading: false, anomalyListLoader: false })
            }
        })
    }

    structureAnomalyData = () => {
        let groupAllAnomaly = []

        let totalCount = 0
        
        let groupAnomalycategory = []
        if(this.state.anomalyResponse && this.state.anomalyResponse.length) {
            totalCount = this.state.anomalyResponse.reduce( function(a, b){ return a + (b.count ? b.count : (b.details ? b.details.length : 0))}, 0);
            this.state.anomalyResponse.forEach((item, index) => {
                let dataRow = {}
                dataRow.count = item.count ? item.count : (item.details ? item.details.length : 0)
                dataRow.category = item.label
                dataRow.orderBy = index+1
                let percentage = Math.ceil(((dataRow.count / totalCount) * 100)/10)
                let array = []
                for(let i=1; i<=percentage; i++) {
                    array.push(i)
                }
                dataRow.totalBars = array
                groupAnomalycategory.push(dataRow)
                item.events.forEach(alt => {
                    groupAllAnomaly.push(alt)
                })
            })
        }
        
        let groupAnomalyTotalCount = this.state.anomalyResponse.total_count ? this.state.anomalyResponse.total_count : 0
        let groupAnomalyfilteredCount = this.state.anomalyResponse.filtered_count ? this.state.anomalyResponse.filtered_count : 0
        
        let dataRow = {}
        dataRow.count = ''
        dataRow.category = 'Important / All'
        dataRow.orderBy = 0
        groupAnomalycategory.push(dataRow)

        let orderedArray = _.orderBy(groupAnomalycategory, ['orderBy'], ['asc'])

        this.setState({ groupAllAnomaly, groupAnomaly: groupAllAnomaly, groupAnomalycategory: orderedArray, groupAnomalyTotalCount, groupAnomalyfilteredCount, anomalyListLoader: false },
            () => this.getCategoryEvents()
        )
    }

    getCategoryEvents = () => {
        let filterdCategoryAnomaly = this.state.groupAllAnomaly
        if(this.state.selectedCategory !== 'Important / All') {
            filterdCategoryAnomaly = this.state.anomalyResponse.filter(arr => arr.label === this.state.selectedCategory)
            if(filterdCategoryAnomaly && filterdCategoryAnomaly.length) {
                filterdCategoryAnomaly = filterdCategoryAnomaly[0].events ? filterdCategoryAnomaly[0].events : []
            }
        }

        this.setState({ groupAnomaly: filterdCategoryAnomaly, selectedAnomalyId: '' },
            () => {
                this.filterData()
                // this.redirectToAnalysis()
                // let scroll = document.getElementById('scrollTop');
                // scroll.scrollIntoView();
            }
        )
    }

    filterData = (type) => {
        console.log("type", type)
        let data = this.state.groupAnomaly ? this.state.groupAnomaly : []
        data = _.orderBy(data, ['anomaly_time'], ['desc'])
        console.log("data 1", data.length)
        console.log("this.state.brushStartTime", this.state.brushStartTime)

        if(type === "brush" && data.length && this.state.brushStartTime && this.state.brushStartTime !== "" && this.state.brushEndTime) {
            data = data.filter(d => {
                var time = this.getTimeLocal(momentConvertionUtcToLocalTime(d.anomaly_time, 'YYYY-MM-DD HH:mm:ss'));
                return (time >= this.getTimeLocal(this.state.brushStartTime) && time <= this.getTimeLocal(this.state.brushEndTime));
            });
        }
        console.log(data)

        let totalPages = 1
		if(data.length > this.state.perPage) {
			totalPages = Math.ceil(data.length / this.state.perPage)
		}

        console.log("data", data.length)

        this.setState({ totalFilteredArray: data, filteredArray: data, totalPages },
            () => {
                if(this.state.filteredArray.length) {
                    this.getAnomlayDetails(this.state.filteredArray[0])
                }
            }    
        )
    }

    getAnomlayDetails = (selectedRecord) => {
        
        this.setState({ selectedAnomalyId: selectedRecord.anomaly_id })

        let obj = {}
        obj.details = selectedRecord
        obj.merticsAssetNameInvolved = selectedRecord.asset_id ? selectedRecord.asset_id : []
        obj.brushStartDate = this.state.brushStartDate
        obj.brushEndDate = this.state.brushEndDate
        obj.sliderStartDate = this.state.sliderStartDate
        obj.sliderEndDate = this.state.sliderEndDate
        console.log("obj", obj)
        this.props.setAiopsPropsDetails('anomalyDetails', obj)
    }
    
    handleChange = (event) => {
        this.setState({ groupType: event.target.value, showGroupLoading: true, selectedAnomalyId: '', anomalyListLoader: true },
            () => {
                if(this.state.groupType !== "All" && this.state.groupedBy === "anomaly_type") {
                    this.setState({ groupedBy: "severity"},
                        () => this.listObservabilityAnomalyResults()
                    )
                } else {
                    this.listObservabilityAnomalyResults()
                }
            }    
        )
    }

    getTimeLocal = (time) => {
        time = new Date(time).getTime()
        return time
    }

	render() {
		return (
            <div className='col-md-12'>
                <div className={`loaderOverlay ${this.state.showLoading ? '' : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
                <div className={`d-flex justify-content-between`}>
                    <div className="d-flex">
                        <p className="indicatoreBox bg-orange mb-0 mr-2"></p>
                        <p className="f12 mb-0 align-self-center">CS - Case Start</p>
                    </div>
                    <div className={`d-flex justify-content-end f10`}>
                        <span className={`mr-2 align-self-center ${this.state.selectedFilterType === 'H' && this.state.selectedFilterPeriod === 3 ? 'badge badge-info disabled' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'H', selectedFilterPeriod: 3, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>3H</span>
                        <span className={`mr-2 align-self-center ${this.state.selectedFilterType === 'H' && this.state.selectedFilterPeriod === 1 ? 'badge badge-info disabled' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'H', selectedFilterPeriod: 1, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>1H</span>
                        <span className={`mr-2 f12 align-self-center `}>before</span>
                        <span className={`mr-2 align-self-center ${this.state.selectedFilterType === 'caseTime' && this.state.selectedFilterPeriod === 0 ? 'badge badge-info disabled' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'caseTime', selectedFilterPeriod: 0, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>Case Time</span>
                    </div>
                </div>
                <div className="rounded bg-dark px-3 py-2 mt-2">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex">
                            <div className="d-flex">
                                <p className="f12 m-0 align-self-center pt-1">Showing {this.state.anomalyTotalCount && this.state.anomalyTotalCount} total anomaies </p>
                                <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{this.state.sliderStartDate ? momentConvertionUtcToLocalTime(this.state.sliderStartDate, 'DD MMM YYYY HH:mm') +' - '+ momentConvertionUtcToLocalTime(this.state.sliderEndDate, 'DD MMM YYYY HH:mm') : ''}</p>                                
                                <span className="ml-2 align-self-center">(local time)</span>
                            </div>
                        </div>
                        <span onClick={this.resetGraph} className="fa fa-redo-alt border-right cursorPointer mr-2 align-self-center"></span>
                    </div>
                    
                    <div className="d-flex">
                        <p className="mb-0 align-self-center text-nowrap text-right pr-3 w-10">Anomalies</p>
                        <div className="w-90 mt-n2 mb-n2 transparentTooltip caseTimeAnnotation">
                            {this.state.brushBarOptions && Object.entries(this.state.brushBarOptions).length && this.state.brushBarSeries && this.state.brushBarSeries.length ?
                                <Chart options={this.state.brushBarOptions} series={this.state.brushBarSeries} type="bar" height={100} />
                            : null}
                            <div className="d-flex">
                                <div id="leftSliderSpan" className="f12">{this.state.sliderBelowStartDate ? this.state.sliderBelowStartDate : ''}</div>
                                <div id="rightSliderSpan" className="f12">{this.state.sliderBelowEndDate ? this.state.sliderBelowEndDate : ''}</div>
                            </div>
                        </div>
                    </div> 
                </div>

                <div className="rounded bg-dark px-3 py-2 mt-2">                   
                    <div className="d-flex justify-content-between mb-2">     
                        <div className={`d-flex ml-1 ${this.state.showGroupLoading ? 'disabled' : ''}`}>
                            <p className="m-0 align-self-end small mr-2">Show </p>
                            <div class={`transparentSelectBorderBottom align-self-end`}>
                                <select class="form-control" 
                                onChange={this.handleChange}
                                value={this.state.groupType}
                                >
                                    <option key="All" value="All">ALL</option>
                                    <option key="metric" value="metric">Metric</option>
                                    <option key="lifecycle" value="lifecycle">Lifecycle</option>
                                    <option key="apicalls" value="apicalls">API Calls</option>
                                    <option key="slow_query" value="slow_query">Slow Queries</option>
                                    <option key="logs" value="logs">Logs</option>
                                </select>
                            </div> 
                            <p className="m-0 align-self-end small mx-2">anomalies groupd by</p>
                            <div class={`transparentSelectBorderBottom align-self-end`}>
                                <select class="form-control" 
                                    onChange={(event) => {
                                        this.setState({ groupedBy: event.target.value, anomalyListLoader: true, selectedCategory: "Important / All", selectedAnomalyId: '' },
                                            () => {
                                                // this.redirectToAnalysis()
                                                this.listObservabilityAnomalyResults()
                                            }
                                        )
                                    }}
                                    value={this.state.groupedBy}                            
                                    >
                                        <option key="severity" value="severity">Severity</option>
                                        <option key="asset_name" value="asset_name">Asset</option>
                                        <option key="service_name" value="service_name">Service</option>
                                        {(this.state.groupType === "All" || this.state.groupType === "logs") ? 
                                            <option key="anomaly_type" value="anomaly_type">Anomaly Type</option>
                                        : null}
                                </select>
                            </div>
                        </div>                   
                        {this.state.totalFilteredArray && this.state.totalFilteredArray.length ?
                            <div className="d-flex">
                                <div className="form-group mb-0">
                                    <SearchWithHiddenInput
                                        ID={'anomaly_search'}
                                        data={this.state.totalFilteredArray ? this.state.totalFilteredArray : this.props.anomaliesList}
                                        applyLiteDarkTags={true}
                                        searchClassName={'border-0'}
                                        searchIconColor={'text-gray3'}
                                        className="form-control-sm text-gray3 bg-muted border-0"
                                        hideInputSection={true}
                                        topClassName={'dark-search'}
                                        filteredData={(filteredArray, searchText) => {
                                            this.setState({ filteredArray },
                                                () => {
                                                    if(filteredArray.length) {
                                                        let filteredArrayOrder = _.orderBy(filteredArray, ['event_time'], ['desc'])
                                                        this.getAnomlayDetails(filteredArrayOrder[0])
                                                    }
                                                }
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                        : null}
                    </div>
                    <div class="row">
                        {this.state.groupAnomalycategory ? 
                            <div className="col-3 categoryBoxSection">
                                <div class="bg-muted px-2 pt-2 select-bgg">
                                    {this.state.groupedBy === "severity" ?
                                        this.state.groupAnomalycategory.map(item => {
                                            return(
                                                item.category === "Important / All" ?
                                                    <div className={`d-flex p-2 cursorPointer ${this.state.selectedCategory === item.category ? 'text-info disabled' : ''}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryEvents())}>
                                                        <p className="m-0 f-20">{item.category}</p>
                                                        <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p>
                                                    </div>
                                                :
                                                    <div class={`d-flex justify-content-end mb-2 ${!item.count ? 'disabled opacity50' : 'cursorPointer'}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryEvents())}>
                                                        <div class="d-flex">
                                                            {item.totalBars.map(bar => {
                                                                return(
                                                                <div className={`line-survey-1 risk-bg-${item.category.toLowerCase()}`}></div>
                                                                )
                                                            })}
                                                            <div class={`font-weight-bold text-center risk-border-${item.category.toLowerCase()} text-white p-2`}>
                                                                <p class="small mb-0" style={{minWidth: "75px"}}>{item.category+" "+item.count}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                            )
                                        })                                            
                                    : 
                                        this.state.groupAnomalycategory.map((item, index) => {
                                            return(
                                                item.category === "Important / All" ?
                                                    <div className={`d-flex cursorPointer mb-3 ${this.state.selectedCategory === item.category ? 'text-info disabled' : ''}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryEvents())}>
                                                        <p className="m-0 f-20">{item.category}</p>
                                                        <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p>
                                                    </div>
                                                :
                                                    <div className={`d-flex cursorPointer mb-2 ${this.state.selectedCategory === item.category ? 'text-info disabled' : (!item.count ? 'text-gray4 disabled test' : 'text-white')}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryEvents())}>
                                                        <div class="d-flex justify-content-between mb-2" style={{maxWidth: "160px"}}>
                                                            <p class="mb-0 align-self-center ellipseCategoryBoxSection w-60" id={'category_'+index}>{item.category}</p>
                                                            <UncontrolledTooltip target={'category_'+index}>{item.category}</UncontrolledTooltip>
                                                            <input class="form-control form-control-sm w-25 border-0 bg-grey text-white readOnly disabled rounded-0 form-number text-right" style={{minWidth: "40px"}} type="text" placeholder="1" value={item.count !== '' ? item.count : ''} aria-label=".form-control-sm example" />
                                                        </div>
                                                    </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        : null}
                        <div className="col-9 pl-0">
                            <div className="timeline-style">
                                {this.state.filteredArray && this.state.filteredArray.map(item => {
                                    return (
                                        <div className={`d-flex justify-content-between mb-2 ${this.state.selectedAnomalyId === item.anomaly_id ? 'bg-muted': ''}`}>
                                            <React.Fragment>
                                                <div className={`timelineAlertGroup d-flex ${this.state.selectedAnomalyId === item.anomaly_id ? '' : 'cursorPointer'} `} onClick={() => {
                                                        if(this.state.selectedAnomalyId !== item.anomaly_id) {
                                                            this.setState({ selectedAnomalyId: item.anomaly_id, ['anomaly_'+this.state.selectedAnomalyId]: false, ['anomaly_'+item.anomaly_id]: true }, 
                                                                () => {
                                                                    this.getAnomlayDetails(item)
                                                                }
                                                            )
                                                        }
                                                    }}>
                                                    <div className="border-right p-2 pr-3">
                                                        <div className={`font-weight-bold text-center p-2 border-gray3 text-gray3`}>
                                                            <p className="small mb-0">{momentConvertionUtcToLocalTime(item.anomaly_time, 'MMM DD')}</p>
                                                            <p className="m-0 f10">{momentConvertionUtcToLocalTime(item.anomaly_time, 'HH:mm:ss')}</p>
                                                        </div>
                                                    </div>
                                                    <div className="timeline-space">
                                                        <span className="border bg-gray3"></span>
                                                        <div className="bg-gray3"></div>
                                                    </div>
                                                    <div className={`align-self-center ml-3`}>
                                                        {this.state.groupType !== "logs" ?
                                                            <div className="d-flex flex-wrap">
                                                                {item.severity ?
                                                                    <div className="d-flex">
                                                                        <span className={`align-self-center mr-1 fa fa-circle risk-${item.severity.toLowerCase()}`}></span>
                                                                        <p className={`mb-0 mr-2 risk-${item.severity.toLowerCase()}`}>
                                                                            {capitalizeFirstLetter(item.severity)}
                                                                        </p>
                                                                    </div>
                                                                : null}
                                                                <span className="mb-0 mr-2 font-weight-bold text-purple-2">Anomaly</span>
                                                                <span className="mb-0 small mr-2">Resource</span>
                                                                {item.account_id ?
                                                                    <span className="mb-0 small mr-2">{' '+item.account_id}</span>
                                                                : null}
                                                                {item.region ?
                                                                    <span className="mb-0 small mr-2">{' : '+item.region}</span>
                                                                : null}
                                                                {item.resource_type ?
                                                                    <span className="mb-0 small mr-2">{' : '+(Array.isArray(item.resource_type) ? item.resource_type.join(', ') : item.resource_type)}</span>
                                                                : null}
                                                                {item.service_name ?
                                                                    <span className="mb-0 small mr-2">{' : '+item.service_name}</span>
                                                                : null}
                                                                {item.asset_name ?
                                                                    <span className="mb-0 small mr-2">{' : '+item.asset_name}</span>
                                                                : null}
                                                                {item.anomaly_message ?
                                                                    <span className="mb-0 small mr-2">{' : '+item.anomaly_message}</span>
                                                                : null}
                                                            </div>
                                                        : 
                                                            <div className="d-flex flex-wrap">
                                                                {item.severity ?
                                                                    <div className="d-flex">
                                                                        <span className={`align-self-center mr-1 fa fa-circle risk-${item.severity.toLowerCase()}`}></span>
                                                                        <p className={`mb-0 mr-2 risk-${item.severity.toLowerCase()}`}>
                                                                            {capitalizeFirstLetter(item.severity)}
                                                                        </p>
                                                                    </div>
                                                                : null}
                                                                {item.type === "sequence" ?
                                                                    <p className="mb-0">
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.anomaly_time ? momentConvertionUtcToLocalTime(item.anomaly_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                        <span className={`mb-0 mr-1 f12 text-purple-2`}>Sequence/combination Anomaly </span>
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">Log lines matching the template </span>
                                                                        {item.additional_details && item.additional_details.template ?
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.additional_details.template} </span>
                                                                        : null}
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">came in an abnormal sequence. The anomalous behavior was observed </span>
                                                                        <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.count && item.count} times </span>
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold"> in </span>
                                                                        {item.asset_name && item.asset_name !== "" ? 
                                                                            <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.asset_name}  </span>
                                                                        : null}
                                                                        {item.service_name && item.service_name !== "" ? 
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">(service: {item.service_name},  </span>
                                                                        : null}
                                                                        {item.asset_id && item.asset_id !== "" ? 
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">ID: {item.asset_id}),  </span>
                                                                        : null}
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">writing to log group </span>
                                                                        {item.additional_details && item.additional_details.log_group ?
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.additional_details.log_group} </span>
                                                                        : null}
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">owned by account ID </span>
                                                                        {item.account_id ?
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.account_id} </span>
                                                                        : null}
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">in </span>
                                                                        {item.region ?
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.region} </span>
                                                                        : null}
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">region. This is a  </span>
                                                                        <span className={`mb-0 f12 ${item.severity ? "risk-"+item.severity.toLowerCase() : "white"} font-weight-bold`}>{item.severity} </span>
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold"> severity anomaly predicted on the  </span>
                                                                        <span className="mb-0 mr-1 f12 text-white font-weight-bold">last {item.period} </span>
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">data. </span>
                                                                    </p>
                                                                : item.type === "numerical" ?
                                                                    <p className="mb-0">
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.anomaly_time ? momentConvertionUtcToLocalTime(item.anomaly_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                        <span className={`mb-0 mr-1 f12 text-purple-2`}>Numerical value Anomaly </span>
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">The numerical values for keys </span>
                                                                        {item.keys && item.keys.length ?
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.keys.join(", ")} </span>
                                                                        : null}
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">are not normal occurrences. The anomalous behavior was observed </span>
                                                                        <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.count && item.count} times </span>
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">in </span>
                                                                        {item.asset_name && item.asset_name !== "" ? 
                                                                            <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.asset_name}  </span>
                                                                        : null}
                                                                        {item.service_name && item.service_name !== "" ? 
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">(service: {item.service_name},  </span>
                                                                        : null}
                                                                        {item.asset_id && item.asset_id !== "" ? 
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">ID: {item.asset_id}),  </span>
                                                                        : null}
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">writing to log group </span>
                                                                        {item.additional_details && item.additional_details.log_group ?
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.additional_details.log_group} </span>
                                                                        : null}
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">owned by account ID </span>
                                                                        {item.account_id ?
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.account_id} </span>
                                                                        : null}
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">in </span>
                                                                        {item.region ?
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.region} </span>
                                                                        : null}
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">region. This is a  </span>
                                                                        <span className={`mb-0 f12 ${item.severity ? "risk-"+item.severity.toLowerCase() : "white"} font-weight-bold`}>{item.severity} </span>
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold"> severity anomaly predicted on the </span>
                                                                        <span className="mb-0 mr-1 f12 text-white font-weight-bold">last {item.period} </span>
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">data. </span>
                                                                    </p>
                                                                : item.type === "categorical" ?
                                                                    <p className="mb-0">
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.anomaly_time ? momentConvertionUtcToLocalTime(item.anomaly_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                        <span className={`mb-0 mr-1 f12 text-purple-2`}>Categorical value Anomaly </span>
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">The categorical value </span>
                                                                        {item.cat_value ?
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.cat_value} </span>
                                                                        : null}
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">is not a normal occurrence. The anomalous behavior was observed </span>
                                                                        <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.count && item.count} times </span>
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">in </span>
                                                                        {item.asset_name && item.asset_name !== "" ? 
                                                                            <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.asset_name}  </span>
                                                                        : null}
                                                                        {item.service_name && item.service_name !== "" ? 
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">(service: {item.service_name},  </span>
                                                                        : null}
                                                                        {item.asset_id && item.asset_id !== "" ? 
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">ID: {item.asset_id}),  </span>
                                                                        : null}
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">writing to log group </span>
                                                                        {item.additional_details && item.additional_details.log_group ?
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.additional_details.log_group} </span>
                                                                        : null}
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">owned by account ID </span>
                                                                        {item.account_id ?
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.account_id} </span>
                                                                        : null}
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">in </span>
                                                                        {item.region ?
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.region} </span>
                                                                        : null}
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">region. This is a  </span>
                                                                        <span className={`mb-0 f12 ${item.severity ? "risk-"+item.severity.toLowerCase() : "white"} font-weight-bold`}>{item.severity} </span>
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold"> severity anomaly predicted on the </span>
                                                                        <span className="mb-0 mr-1 f12 text-white font-weight-bold">last {item.period} </span>
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">data. </span>
                                                                    </p>
                                                                : item.type === "newtemplate" ?
                                                                    <p className="mb-0">
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.anomaly_time ? momentConvertionUtcToLocalTime(item.anomaly_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                        <span className={`mb-0 mr-1 f12 text-purple-2`}>New log line Anomaly </span>
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">Log lines matching the template </span>
                                                                        {item.additional_details && item.additional_details.template ?
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.additional_details.template} </span>
                                                                        : null}
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">had not been observed previously. Such lines were observed </span>
                                                                        <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.count && item.count} times </span>
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">in the </span>
                                                                        <span className="mb-0 mr-1 f12 text-white font-weight-bold">last {item.period} </span>
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">data from </span>
                                                                        {item.asset_name && item.asset_name !== "" ? 
                                                                            <span className="mb-0 mr-1 f12 text-white font-weight-bold">{item.asset_name}  </span>
                                                                        : null}
                                                                        {item.service_name && item.service_name !== "" ? 
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">(service: {item.service_name},  </span>
                                                                        : null}
                                                                        {item.asset_id && item.asset_id !== "" ? 
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">ID: {item.asset_id}),  </span>
                                                                        : null}
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">writing to log group </span>
                                                                        {item.additional_details && item.additional_details.log_group ?
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.additional_details.log_group} </span>
                                                                        : null}
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">owned by account ID </span>
                                                                        {item.account_id ?
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.account_id} </span>
                                                                        : null}
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">in </span>
                                                                        {item.region ?
                                                                            <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">{item.region} </span>
                                                                        : null}
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold">region. This is a  </span>
                                                                        <span className={`mb-0 f12 ${item.severity ? "risk-"+item.severity.toLowerCase() : "white"} font-weight-bold`}>{item.severity} </span>
                                                                        <span className="mb-0 mr-1 f12 text-primary-color font-weight-bold"> severity anomaly  </span>
                                                                    </p>
                                                                : null}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}
/**
 * Type of the props used in the component
 */
AnomaliesLeftSection.propTypes = {
    getObservabilityAnomalyDailyTrend: PropTypes.func,
    alertsGraphBrushInterval: PropTypes.func,
    setAiopsPropsDetails: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
	// console.log('state', state)
	return {
		accounts: state.filters.accounts,
        regions: state.filters.regions,
        caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : {},
        anomaliesList: state.aiops.caseDetails.results && state.aiops.caseDetails.results.alerts ? state.aiops.caseDetails.results.alerts : [],
        aiopsPropsDetails: state.aiops.aiopsPropsDetails ? state.aiops.aiopsPropsDetails : {},
    }
}

export default connect(mapStateToProps, {
    getObservabilityAnomalyDailyTrend,
    listObservabilityAnomalyResults,

    listAlertGroups,
    getAlertGroupDailyTrend,

    setAiopsPropsDetails,
})(withRouter(AnomaliesLeftSection))