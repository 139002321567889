/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file PoliciesList.js
 * @author Rajasekar // on 15/04/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AppWrapper } from '../../common/AppWrapper'
import { listAllPolicies, setSelectedPolicyTableRow } from '../../../actions/governance/governanceAction'
import Search from '../../common/SearchComponent'
import { Spinner } from 'reactstrap'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'
import { listAllProviders, showNotification } from '../../../actions/commonAction'
import { removePolicy, setCreatePolicyDetailsEmpty } from '../../../actions/governance/IamAction'
import { URL_PATH } from '../../../config/urlPath'
import ViewSidePanel from './ViewSidePanel'
import { SUCCESS, ERROR } from '../../../utils/constants'
import Select from 'react-select'

class PoliciesList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showDetailsPanel: false,
			providers: [],
			selectedProvider: 'All',
			showLoading: false,
			filteredArray: [],
			showDeleteLoading: false,
		}
	}

	componentDidMount = () => {
		this.setState({ showLoading: true })
		this.props.listAllProviders((promise) => {			
			if (promise) {
				if (this.props.providers.length === 1) {
					let providers = this.props.providers
					if (providers.length) {
						let dataRow = {}
						dataRow.provider_id = "All"
						dataRow.provider_name = "All"
						providers.unshift(dataRow);
					}
					this.setState({ providers, showLoading: true, selectedProvider: this.props.providers[0].provider_name },
						() => this.filterIdentifiers()
					)
					
				} else {
					this.filterIdentifiers()
				}
			}
		})
	}

	filterIdentifiers = () => {
		this.setState({ showLoading: true, filteredArray: [] })
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'All') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		this.props.setCreatePolicyDetailsEmpty()
		this.props.listAllPolicies(params, () => {
			this.setState({ showLoading: false })
		})
	}

	removePolicy = arn => {
		this.setState({ showDeleteLoading: true })
		this.props.removePolicy({ policy_arn: arn }, (success, res) => {
			if (success) {
				this.props.showNotification(SUCCESS, res.message || 'Policy removed successfully')
			} else {
				this.props.showNotification(ERROR, res.message || 'Policy not removed.')
			}
			this.setState({ showDeleteLoading: false, showDetailsPanel: false })
		})
	}

	render() {
		return (
			<div className="container-fluid overflow-auto flex-grow-1 bg-muted">				
				{this.state.showDetailsPanel ? (
					<ViewSidePanel
						closeViewSidePanel={() => this.setState({ showDetailsPanel: false })}
						onClickDelete={arn => this.removePolicy(arn)}
						showDeleteLoading={this.state.showDeleteLoading}
					/>
				) : null}
				<div className="row h-100">
					<div className="col-sm-12 p-0">
						<div className="title-section p-2">
							<div className="row">
								<div className="col-4 align-self-center">
									<h6 className="text-white m-0">IAM</h6>
									<p className="text-white m-0">
										Manage user created IAM polices on your Cloud
									</p>
								</div>
								<div className="col-8 align-self-center">
									<div className={`col-md-12 d-flex flex-wrap justify-content-end ${this.state.showLoading ? "disabled" : ""}`}>
										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: "150px" }}>
											<label className="mb-1">Provider</label>
											<Select
												placeholder={"All"}
												isSearchable={false}
												//onFocus={this.openProivderMenu}
												className="f13 p-0 colorBlack reactSelectFilterDrop"
												value={({
													value: this.state.selectedProvider,
													label: this.state.selectedProvider ? this.state.selectedProvider : <span className="placeholder">Select</span>
												})}
												options={this.state.providers && this.state.providers.map(item => ({
													value: item.provider_name,
													label: item.provider_name,	
												}))}
												onChange={event => this.setState({ selectedProvider: event.value }, 
													() => {
														this.filterGovernance()
													}
												)}
											/>
										</div>
										<button className='btn btn-primary ml-3 mb-2 align-self-center'
											onClick={() => {
												this.props.setCreatePolicyDetailsEmpty()
												this.props.history.push(URL_PATH.GOVERNANCE_CREATE_POLICIES)
											}}
										>
											Create Policy
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="container-fluid bg-dark m-2 rounded h-100">
							<div className="d-flex justify-content-between py-2">
								<p className="mb-0 align-self-center">Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.props.rules && this.props.rules.length} Rules</p>
								<div className={`mb-n2 ${this.state.showLoading ? "disabled" : ""}`}>
									<Search
										data={this.props.policies ? this.props.policies : []}
										filteredData={filteredArray => this.setState({ filteredArray }, 
											() => {
												if(filteredArray.length) {
													this.props.setSelectedPolicyTableRow(filteredArray[0])
												}
											}
										)}
									/>
								</div>
							</div>
							<div className='tab-content' id='myTabContent'>
								<div className='tab-pane fade show active governanceDashboardTable' id='home' role='tabpanel' aria-labelledby='policies-tab'>
									{this.props.policies && this.props.policies.length && !this.state.showLoading ? (
										<ResizeableDarkThemeTable
											columns={[
												{
													Header: 'Provider',
													accessor: 'provider',
												},
												{
													Header: 'Account',
													accessor: 'account_id',
												},
												{
													Header: 'Policy Name',
													Cell: cellInfo => (
														<>
															<span className='pl-2'>{cellInfo.row.original.policy_details.PolicyName}</span>
														</>
													),
												},
												{
													Header: 'Created On',
													Cell: cellInfo => (
														<>
															<span className='pl-2'>{cellInfo.row.original.policy_details.CreateDate}</span>
														</>
													),
												},
												{
													Header: 'Modified On',
													Cell: cellInfo => (
														<>
															<span className='pl-2'>{cellInfo.row.original.policy_details.UpdateDate}</span>
														</>
													),
												},
											]}
											data={this.state.filteredArray ? this.state.filteredArray : this.props.policies}
											perPage={20}
											dashboard={(this.props.filteredArray ? this.props.filteredArray.length : (this.props.rules && this.props.rules.length)) ? true : false}
											sortByColumn={"Date"}
											riskTooltip={[0]}
											onClickRow={tableRow => {
												this.props.setSelectedPolicyTableRow(tableRow)
												this.setState({ showDetailsPanel: true })
											}}
											// tableSize={"table-sm"}
											// tableHead={"bg-dark"}
											tooltipFor={[2]}
										/>
									) : (!this.state.showLoading && !this.props.policies) ||
									(this.props.policies && !this.props.policies.length && !this.state.showLoading) ? (
										<div className='d-flex justify-content-center m-4'>
											<p>No policies found</p>
										</div>
									) : null}
									{this.state.showLoading ? (
										<div className='d-flex justify-content-center m-4'>
											<Spinner className='text-center' color='white' size='lg' />
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
PoliciesList.propTypes = {
	policies: PropTypes.array,
	providers: PropTypes.array,
	selectedPolicyTableRow: PropTypes.object,
	history: PropTypes.array,
	listAllPolicies: PropTypes.func,
	listAllProviders: PropTypes.func,
	setSelectedPolicyTableRow: PropTypes.func,
	removePolicy: PropTypes.func,
	showNotification: PropTypes.func,
	setCreatePolicyDetails: PropTypes.func,
	setCreatePolicyDetailsEmpty: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		policies: state.governance.policies.results,
		providers: state.filters.providers,
		selectedPolicyTableRow: state.governance.selectedPolicyTableRow,
	}
}

export default AppWrapper(PoliciesList, mapStateToProps, {
	listAllPolicies,
	listAllProviders,
	setSelectedPolicyTableRow,
	removePolicy,
	setCreatePolicyDetailsEmpty,
	showNotification,
})
