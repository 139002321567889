/*************************************************
 * Tvastar
 * @exports
 * @file SupressForm.js
 * @author Rajasekar // on 09/01/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Input, Spinner } from 'reactstrap'
import { listAllProviders, listAllServices, listAllRegions, listAllAccounts } from '../../../actions/commonAction'
import { createSupression } from '../../../actions/detectors/suppressorsAction'
import _ from 'lodash'
import { getAccountNameFromId } from '../../../utils/utility'

import Select from 'react-select'

class SupressForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedProvider: '',
			selectedService: '',
			selectedRegion: '',
			selectedAccount: '',

			providers: [],
			services: [],
			accounts: [],
			regions: [],

			newRuleTags: [{ key: '', value: '' }],
			ruleName: '',
			ruleDescription: '',
			ruleDetectorId: '',
			ruleDuration: '',
			ruleProvider: '',
			ruleOrganization: '',
			ruleAccount: '',
			ruleRegion: '',
			ruleService: '',
			ruleAsset: '',

			responseMessage: '',
			showMessage: false,
			showLoading: false,
			hasErrorInRuleForm: false,
		}
	}

	componentDidMount = () => {
		// if (this.props.data) {
		// 	this.setState({
		// 		ruleName:this.props.data.title ,
		// 	ruleDescription: this.props.data.description,
		// 	ruleDetectorId: this.props.data.service_id,
		// 	ruleDuration: this.props.data,
		// 	ruleProvider: this.props.data.provider,
		// 	ruleOrganization: this.props.data,
		// 	ruleAccount: this.props.data,
		// 	ruleRegion: this.props.data,
		// 	ruleService: this.props.data,
		// 	ruleAsset: this.props.data,
		// 	})
		// }
		this.props.listAllProviders((promise, providers) => {
			if (promise) {
				this.setState({ providers })
			}
		})
	}

	/**
	 * Filter identifiers based on the filter selection
	 */
	filterIdentifiers = from => {
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'all') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if (this.state.selectedService !== '' && this.state.selectedService !== 'all') {
			params.service_name = this.state.selectedService
		}
		if (this.state.selectedRegion !== '' && this.state.selectedRegion !== 'all') {
			params.region = this.state.selectedRegion
		}
		if (from === 'provider') {
			this.props.listAllAccounts(params, (promise, accounts) => {
				if (promise) {
					this.setState({ accounts })
				}
			})
			this.props.listAllRegions(params, (promise, regions) => {
				if (promise) {
					this.setState({ regions: regions.provider_regions })
				}
			})
			this.props.listAllServices(params, (promise, services) => {
				if (promise) {
					this.setState({ services },
						() => this.formSericeSelect()	
					)
				}
			})
		}	
	}
	
	formSericeSelect = () => {
		let data = []
		if(this.state.services && this.state.services.length) {
			_.chain(this.state.services)
				.groupBy('resource_type')
				.map((value, key) => ({ label: key, sub: value }))
				.value()
				.ForEach((service, i) => {
					let child = []
					service.sub.ForEach((item, i) => {
						let childRow = {}
						childRow.value = service.service_name
						childRow.label = item.service_name
						child.push(childRow)
					})
					let dataRow = {}
					dataRow.label = service.label
					dataRow.options = child

					data.push(dataRow)
				})
		}

		this.setState({ servicesOptGrop: data })
	}

	/**
	 * Add new rule form
	 */
	addNewRuleTags = () => {
		const temp = this.state.newRuleTags
		temp.push({
			key: '',
			value: '',
		})
		this.setState({ newRuleTags: temp })
	}

	/**
	 * Delete new rule form
	 */
	deleteNewRuleTags = index => {
		if (this.state.newRuleTags.length === 1) {
			const temp = this.state.newRuleTags
			temp.splice(index, 1)
			temp.push({ key: '', value: '' })
			this.setState({ newRuleTags: temp })
		} else {
			const temp = this.state.newRuleTags
			temp.splice(index, 1)
			this.setState({ newRuleTags: temp })
		}
	}

	/**
	 * Handles on submit new rule form
	 */
	onSubmitNewRuleForm = () => {
		if (
			this.state.ruleName === '' ||
			this.state.ruleProvider === '' ||
			this.state.ruleDescription === '' ||
			this.state.ruleDetectorId === '' ||
			this.state.ruleDuration === '' ||
			this.state.ruleService === '' ||
			this.state.ruleOrganization === '' ||
			this.state.ruleAccount === '' ||
			this.state.ruleRegion === '' ||
			this.state.ruleAsset === '' ||
			this.state.newRuleTags[0].key === '' ||
			this.state.newRuleTags[0].value === ''
		) {
			this.setState({ hasErrorInRuleForm: true })
		} else {
			this.setState({ showLoading: true })
			const formData = {
				name: this.state.ruleName,
				provider: this.state.ruleProvider,
				description: this.state.ruleDescription,
				module_id: this.state.ruleDetectorId,
				duration: this.state.ruleDuration,
				service_name: this.state.ruleService,
				organization: this.state.ruleOrganization,
				account_id: this.state.ruleAccount,
				region: this.state.ruleRegion,
				asset_name: this.state.ruleAsset,
				tags: this.state.newRuleTags,
				module_name: 'identifier',
			}
			this.props.createSupression(formData, res => {
				this.setState({
					newRuleTags: [{ key: '', value: '' }],
					ruleName: '',
					ruleDescription: '',
					ruleDetectorId: '',
					ruleDuration: '',
					ruleProvider: '',
					ruleOrganization: '',
					ruleAccount: '',
					ruleRegion: '',
					ruleService: '',
					ruleAsset: '',
					selectedProvider: '',
					selectedService: '',
					selectedRegion: '',
					selectedAccount: '',
					responseMessage: res.message,
					showMessage: true,
					showLoading: false,
					hasErrorInRuleForm: false,
				})
			})
		}
	}

	/**
	 * Renders suppress form
	 */
	render() {
		return (
			<div className="p-2 rounded bg-dark">
				<div className="d-flex">
					<div className="py-1 w-50">
						<div className="d-flex justify-content-between">
							<p className="mb-0 text-white">Name</p>																			
						</div>
						<Input 
							type="text" 
							placeholder="Name"
							maxLength={80}
							className={`inputDarkTheme`}
							value={this.state.ruleName ? this.state.ruleName : ""}
							onChange={e => this.setState({ ruleName: e.target.value })}
						/>
						{this.state.hasErrorInRuleForm && (!this.state.ruleName || this.state.ruleName === "") ?
							<p className="mb-0 text-danger">required</p>
						: null}
					</div>
					<div className="pl-3 py-1 w-50">
						<div className="d-flex justify-content-between">
							<p className="b-block mb-0 text-white">Detector ID</p>									
						</div>
						<Input 
							type="text" 
							placeholder="Detector ID"
							maxLength={80}
							className={`inputDarkTheme`}
							value={this.state.ruleDetectorId ? this.state.ruleDetectorId : ""}
							onChange={e => this.setState({ ruleDetectorId: e.target.value })}
						/>
						{this.state.hasErrorInRuleForm && (!this.state.ruleDetectorId || this.state.ruleDetectorId === "") ?
							<p className="mb-0 text-danger">required</p>
						: null}
					</div>
				</div>
				<div className="d-flex mt-2">
					<div className="py-1 w-50">
						<div className="d-flex justify-content-between">
							<p className="mb-0 text-white">Description</p>																			
						</div>
						<Input 
							type="text" 
							placeholder="Description"
							maxLength={80}
							className={`inputDarkTheme`}
							value={this.state.ruleDescription ? this.state.ruleDescription : ""}
							onChange={e => this.setState({ ruleDescription: e.target.value })}
						/>
						{this.state.hasErrorInRuleForm && (!this.state.ruleDescription || this.state.ruleDescription === "") ?
							<p className="mb-0 text-danger">required</p>
						: null}
					</div>
					<div className="pl-3 py-1 w-50">
						<div className="d-flex justify-content-between">
							<p className="b-block mb-0 text-white">Duration</p>									
						</div>
						<Input 
							type="text" 
							placeholder="Duration"
							maxLength={80}
							className={`inputDarkTheme`}
							value={this.state.ruleDuration ? this.state.ruleDuration : ""}
							onChange={e => this.setState({ ruleDuration: e.target.value })}
						/>
						{this.state.hasErrorInRuleForm && (!this.state.ruleDuration || this.state.ruleDuration === "") ?
							<p className="mb-0 text-danger">required</p>
						: null}
					</div>
				</div>
				<div className="d-flex mt-2">
					<div className="py-1 w-50">
						<div className="d-flex justify-content-between">
							<p className="b-block mb-0 text-white">Provider</p>
						</div>
						<Select
							placeholder={"Select"}
							isSearchable={true}
							// isDisabled={this.props.actionType === "Run" ? true : false}
							className="f13 p-0 selectDarkTheme"
							options={this.props.providersData && this.props.providersData.map(item => ({
								value: item.provider_name,
								label: item.provider_name,
							}))}
							value={({
								value: this.state.selectedProvider,
								label: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider.toUpperCase() : <span className="placeholder">Select</span>
							})}
							onChange={event =>  
								this.setState({
									selectedProvider: event.value.toLowerCase(),
									ruleProvider: event.value.toLowerCase()
								},
									() => this.filterIdentifiers('provider')
								)
							}
						/>
						{this.state.hasErrorInRuleForm && (!this.state.provider || this.state.provider === "") ?
							<p className="mb-0 text-danger">required</p>
						: null}
					</div>					
					<div className="pl-3 py-1 w-50">
						<div className="d-flex justify-content-between">
							<p className="b-block mb-0 text-white">Organization</p>									
						</div>
						<Input 
							type="text" 
							placeholder="Organization"
							maxLength={80}
							className={`inputDarkTheme`}
							value={this.state.ruleOrganization ? this.state.ruleOrganization : ""}
							onChange={e => this.setState({ ruleOrganization: e.target.value })}
						/>
						{this.state.hasErrorInRuleForm && (!this.state.ruleOrganization || this.state.ruleOrganization === "") ?
							<p className="mb-0 text-danger">required</p>
						: null}
					</div>
				</div>
				<div className="d-flex mt-2">
					<div className="py-1 w-50">
						<div className="d-flex justify-content-between">
							<p className="b-block mb-0 text-white">Accounts</p>
						</div>
						<Select
							placeholder={"Select"}
							isSearchable={true}
							// isDisabled={this.props.actionType === "Run" ? true : false}
							className="f13 p-0 selectDarkTheme"
							options={this.state.accounts && this.state.accounts.map(item => ({
								value: item.account_id,
								label: item.account_name,
							}))}
							value={({
								value: this.state.selectedAccount,
								label: this.state.selectedAccount && this.state.selectedAccount !== "" ? getAccountNameFromId(this.state.selectedAccount, this.state.accounts) : <span className="placeholder">Select</span>
							})}
							onChange={event =>  
								this.setState({
									selectedAccount: event.value.toLowerCase(),
									ruleAccount: event.value.toLowerCase()
								},
									() => this.filterIdentifiers('account')
								)
							}
						/>
						{this.state.hasErrorInRuleForm && (!this.state.ruleAccount || this.state.ruleAccount === "") ?
							<p className="mb-0 text-danger">required</p>
						: null}
					</div>					
					<div className="pl-3 py-1 w-50">
						<div className="d-flex justify-content-between">
							<p className="b-block mb-0 text-white">Regions</p>
						</div>
						<Select
							placeholder={"Select"}
							isSearchable={true}
							// isDisabled={this.props.actionType === "Run" ? true : false}
							className="f13 p-0 selectDarkTheme"
							options={this.state.regions && this.state.regions.map(item => ({
								value: item.region,
								label: item.name,
							}))}
							value={({
								value: this.state.selectedRegion,
								label: this.state.selectedRegion && this.state.selectedRegion !== "" ? getAccountNameFromId(this.state.selectedRegion, this.state.accounts) : <span className="placeholder">Select</span>
							})}
							onChange={event =>  
								this.setState({
									selectedRegion: event.value.toLowerCase(),
									ruleRegion: event.value.toLowerCase()
								},
									() => this.filterIdentifiers('regions')
								)
							}
						/>
						{this.state.hasErrorInRuleForm && (!this.state.ruleRegion || this.state.ruleRegion === "") ?
							<p className="mb-0 text-danger">required</p>
						: null}
					</div>
				</div>
				<div className="d-flex mt-2">
					<div className="py-1 w-50">
						<div className="d-flex justify-content-between">
							<p className="b-block mb-0 text-white">Services</p>
						</div>
						<Select
							placeholder={"Select"}
							// isSearchable={false}
							// menuIsOpen={true}
							//onFocus={this.openProivderMenu}
							className="f13 p-0 selectDarkTheme reactSelectOptGroupFilterDrop"
							// label={({
							// 	label: this.state.selectedService,
							// 	// label: this.state.selectedService ? getRegionName(this.state.selectedService, regions) : <span className="placeholder">Select</span>
							// })}
							
							value={({
								value: this.state.service && this.state.service !== "" ? this.state.service : "Select",
								label: this.state.service && this.state.service !== "" ?this.state.service : <span className="placeholder">Select</span>
							})}
							options={this.state.servicesOptGrop && this.state.servicesOptGrop}
							onChange={event => this.setState({ 
								selectedService: event.label, 
								ruleService: event.label, 
								changesInFilter: true 
							},
									() => this.filterIdentifiers('')
							)}
						/>
						{this.state.hasErrorInRuleForm && (!this.state.ruleService || this.state.ruleService === "") ?
							<p className="mb-0 text-danger">required</p>
						: null}
					</div>
					<div className="pl-3 py-1 w-50">
						<div className="d-flex justify-content-between">
							<p className="b-block mb-0 text-white">Asset</p>									
						</div>
						<Input 
							type="text" 
							placeholder="Asset"
							maxLength={80}
							className={`inputDarkTheme`}
							value={this.state.ruleAsset ? this.state.ruleAsset : ""}
							onChange={e => this.setState({ ruleAsset: e.target.value })}
						/>
						{this.state.hasErrorInRuleForm && (!this.state.ruleAsset || this.state.ruleAsset === "") ?
							<p className="mb-0 text-danger">required</p>
						: null}
					</div>
				</div>
				<div className="d-flex mt-2">
					<div className="py-1 w-100">
						<div className="d-flex justify-content-between">
							<p className="b-block mb-0 text-white">Tags</p>									
						</div>
						{this.state.newRuleTags.map((rule, index) => {
							return (
								<React.Fragment>
									<div className='d-flex justify-content-start mt-3 col-12' key={index}>
										<div className='row col-9 p-0'>
											<div className='col p-0'>
												<Input
													value={rule.key}
													onChange={event => {
														let tags = this.state.newRuleTags
														tags[index].key = event.target.value
														this.setState({ newRuleTags: tags })
													}}
													type='text'
													className='inputDarkTheme'
													placeholder='Key'
												/>
											</div>
											<span className="align-self-center">:</span>
											<div className='col p-0'>
												<Input
													value={rule.value}
													onChange={event => {
														let tags = this.state.newRuleTags
														tags[index].value = event.target.value
														this.setState({ newRuleTags: tags })
													}}
													type='text'
													className='inputDarkTheme'
													placeholder='Value'
												/>
											</div>
										</div>
										<div className='col-3 d-flex align-self-center'>
											{this.state.newRuleTags.length > 1 ?
												<span className={`far fa-trash cursor-pointer f18 align-self-center ml-4`} title='Delete' onClick={() => this.deleteNewRuleTags(index)}></span>
											: null}
											{this.state.newRuleTags.length - 1 === index?
												<span className={`far fa-plus cursor-pointer f18 align-self-center ml-4`} title='Add' onClick={() => this.addNewRuleTags()}></span>
											: null}
										</div>
									</div>
								</React.Fragment>
							)
						})}
						{this.state.hasErrorInRuleForm &&
						(this.state.newRuleTags[0].key === '' || this.state.newRuleTags[0].value === '') ? (
							<p className='text-danger'>Tags is required.</p>
						) : (
							''
						)}
					</div>
				</div>
				{this.state.showMessage ? <p className='text-success'>{this.state.responseMessage}</p> : ''}
				
				<div className='text-right my-3 pt-3 border-top-gray5'>
					<button className='btn btn-sm btn-light mr-3' onClick={() => this.props.onClickBackButton()}>
						{this.props.isCreateClicked ? 'Cancel' : 'Back'}
					</button>
					<button
						className='btn btn-sm btn-primary'
						onClick={() => this.onSubmitNewRuleForm()}
						disabled={this.state.showLoading}
					>
						{this.state.showLoading ? (
							<React.Fragment>
								{this.props.isCreateClicked ? 'Creating' : 'Updating'}
								<Spinner className='text-center ml-2' color='light' size='sm' />{' '}
							</React.Fragment>
						) : this.props.isCreateClicked ? (
							'Create'
						) : (
							'Update'
						)}
					</button>
				</div>
			</div>
		)
	}
}
/**
 * Type of the props used in the component
 */
SupressForm.propTypes = {
	showEditSupress: PropTypes.bool,
	isCreateClicked: PropTypes.bool,
	onClickBackButton: PropTypes.func,
	providersData: PropTypes.array,
	listAllProviders: PropTypes.func,
	listAllRegions: PropTypes.func,
	listAllServices: PropTypes.func,
	listAllAccounts: PropTypes.func,
	createSupression: PropTypes.func,
}
export default connect(null, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
	listAllServices,
	createSupression,
})(SupressForm)
