/*************************************************
 * Tvastar
 * @exports
 * @file EditNotification.js
 * @author Rajasekar // on 14/03/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setEditRuleDetails } from '../../actions/governance/governanceAction'
import { Link } from 'react-router-dom'
import { URL_PATH } from '../../config/urlPath'
import { Input } from 'reactstrap'

class EditNotification extends Component {
	constructor(props) {
		super(props)
		this.state = {
			emailChecked: false,
			slackChecked: false,
			emails: '',
			slackChannels: ''
		}
	}

	handleNotification = (type, label, value) => {
		this.props.setEditRuleDetails('notifications', {
			...this.props.editRuleDetails.notifications,
			[label]: type === 'textbox' ? value : this.props.editRuleDetails && this.props.editRuleDetails.notifications && this.props.editRuleDetails.notifications[label] ? false : true,
		})

		// let notifications = this.props.editRuleDetails.preview && this.props.editRuleDetails.preview['notifications'] ? this.props.editRuleDetails.preview['notifications'] : []

		// let dataRow = {}
		// dataRow.label = label
		// dataRow.value = value !== '' ? value : this.props.editRuleDetails && this.props.editRuleDetails.notifications && this.props.editRuleDetails.notifications[label] ? false : true
		// let index = notifications.findIndex(({ label, value }) => label === dataRow.label);
		// if (index === -1) {
		// 	notifications.push(dataRow);
		// } else {
		// 	notifications[index] = dataRow;
		// }
		
		// let preview = this.props.editRuleDetails.preview ? this.props.editRuleDetails.preview : {}
		// preview['notifications'] = notifications
		// this.props.setEditRuleDetails('preview', preview)

	}

	render() {
		return (
			<div>
				<h4 className="text-white">Notification</h4>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
					magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
					consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
					pariatur.
				</p>
				<div className='form-check form-check-inline zapInputDark'>
					<input
						className='form-check-input'
						type='checkbox'
						checked={(this.props.editRuleDetails.notifications && this.props.editRuleDetails.notifications.emailChecked && this.props.editRuleDetails.notifications.emailChecked === true ? true : false)}
						/* onChange={e => {
							this.props.setEditRuleDetails('notifications', {
								...this.props.editRuleDetails.notifications,
								emailChecked: this.props.editRuleDetails && this.props.editRuleDetails.notifications && this.props.editRuleDetails.notifications.emailChecked ? false : true,
							})
						}} */
						onChange={e => this.handleNotification('checkox', 'emailChecked', '') }
					/>
					<label className='form-check-label' htmlFor='forCheckbox'>
						Send notification to email
					</label>
				</div>
				<div className='row'>
					{(this.props.editRuleDetails.notifications &&
						this.props.editRuleDetails.notifications.emailChecked &&
						this.props.editRuleDetails.notifications.emailChecked) ? (
						<div className='col-sm-6'>
							<div className='form-group'>
								{/* <label>Email</label> */}
								<Input
									type='text'
									className="inputTextbox"
									placeholder='Email'
									value={
										this.props.editRuleDetails && this.props.editRuleDetails.notifications && this.props.editRuleDetails.notifications.email.join(',')
									}
									/* onChange={e => {
										this.props.setEditRuleDetails('notifications', {
											...this.props.editRuleDetails.notifications,
											email: e.target.value.split(','),
										})
									}} */
									onChange={e => this.handleNotification('textbox', 'email', e.target.value.split(',')) }
								/>
							</div>
						</div>
					) : null}
				</div>
				<div className='form-check form-check-inline'>
					<input
						className='form-check-input'
						type='checkbox'
						checked={this.props.editRuleDetails.notifications &&
								this.props.editRuleDetails.notifications.slackChecked &&
								this.props.editRuleDetails.notifications.slackChecked === true ? true : false}
						/*onChange={e => {
							this.props.setEditRuleDetails('notifications', {
								...this.props.editRuleDetails.notifications,
								slackChecked: this.props.editRuleDetails && this.props.editRuleDetails.notifications && this.props.editRuleDetails.notifications.slackChecked ? false : true,
							})
							//this.setState({ slackChecked: e.target.checked })
						}}*/
						onChange={e => this.handleNotification('checkbox', 'slackChecked', '') }
					/>
					<label className='form-check-label' htmlFor='forCheckbox'>
						Send notification to slack (channel)
					</label>
				</div>
				<div className='row'>
					{(this.props.editRuleDetails.notifications &&
						this.props.editRuleDetails.notifications.slackChecked &&
						this.props.editRuleDetails.notifications.slackChecked) ? (
						<React.Fragment>
							<div className='col-sm-6'>
								<div className='form-group'>
									{/* <label>Slack</label> */}
									<Input
										type='text'
										className="inputTextbox"
										placeholder='Slack'
										value={
											this.props.editRuleDetails.notifications &&
											this.props.editRuleDetails.notifications.slack.join(',')
										}
										/*onChange={e => {
											this.props.setEditRuleDetails('notifications', {
												...this.props.editRuleDetails.notifications,
												slack: e.target.value.split(','),
											})
										}}*/
										onChange={e => this.handleNotification('textbox', 'slack', e.target.value.split(',')) }
									/>
								</div>
							</div>
							{/* <small className='form-text text-muted'></small> */}
						</React.Fragment>
					) : null}
				</div>
				
				<div className="border-top pt-2 mt-3">
					<button className='btn btn-light mr-1' onClick={() => this.props.onClickBack()}>Back</button>
					<button className='btn btn-primary' onClick={() => {this.props.onClickNext()}}>Next</button>
					<Link className='float-right btn btn-light text-muted text-decoration-none ml-1' to={URL_PATH.GOVERNANCE_RULES} >
						Cancel
					</Link>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
EditNotification.propTypes = {
	onClickNext: PropTypes.func,
	onClickBack: PropTypes.func,
	setEditRuleDetails: PropTypes.func,
	editRuleDetails: PropTypes.object,
	disableFinish: PropTypes.bool,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	//console.log('notification state',state)
	return {
		editRuleDetails: state.governance.editRuleDetails,
	}
}
export default connect(mapStateToProps, { setEditRuleDetails })(EditNotification)
