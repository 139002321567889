/*************************************************
 * Tvastar
 * @exports
 * @file ServiceDetails.js
 * @author Rajasekar // on 20/02/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ServiceDetails extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	render() {
		return (
			<div className="box p-3 h-100 bg-dark3">
				<p className="text-white">
					<b>Service Details</b>
				</p>
				{this.props.status ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">Instance State</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.status}</div>
					</div>
				) : null}
				{this.props.instanceType ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">Instance Type</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.instanceType}</div>
					</div>
				) : null}
				{this.props.dbInstanceEngineName ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">Engine</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.dbInstanceEngineName}</div>
					</div>
				) : null}
				{this.props.publicIp ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">IPv4 Public IP</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.publicIp}</div>
					</div>
				) : null}
				{this.props.publicIp ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">Elastic Ips</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.publicIp}</div>
					</div>
				) : null}
				{this.props.publicDns ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">Public DNS</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.publicDns}</div>
					</div>
				) : null}
				{this.props.privateIp ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">Private Ip</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.privateIp}</div>
					</div>
				) : null}
				{this.props.privateDns ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">Private DNS</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.privateDns}</div>
					</div>
				) : null}
				{this.props.keyPairName ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">Key Pair Name</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.keyPairName}</div>
					</div>
				) : null}
				{this.props.amiId ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">AMI Id</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.amiId}</div>
					</div>
				) : null}
				{this.props.iamRole ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">IAM Role</div>
						<div className="flex-grow-1 text-right py-2 text-white">
							{this.props.iamRole.split(":")[this.props.iamRole.split(":").length - 1]}
						</div>
					</div>
				) : null}
				{this.props.dbInstanceClass ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">DB Instance Type</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.dbInstanceClass}</div>
					</div>
				) : null}
				{this.props.dbInstanceEndpointAddress ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">Endpoint</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.dbInstanceEndpointAddress}</div>
					</div>
				) : null}
				{this.props.dbInstancePort ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">DB Port</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.dbInstancePort}</div>
					</div>
				) : null}
				{this.props.dbInstancePublicAccessible ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">Public accessibility</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.dbInstancePublicAccessible ? "Yes" : "No"}</div>
					</div>
				) : null}
				{this.props.iamDatabaseAuthenticationEnabled ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">IAM DB authentication</div>
						<div className="flex-grow-1 text-right py-2 text-white">
							{this.props.iamDatabaseAuthenticationEnabled ? "Yes" : "No"}
						</div>
					</div>
				) : null}
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ServiceDetails.propTypes = {
	dbInstanceClass: PropTypes.string,
	dbInstanceEndpointAddress: PropTypes.string,
	dbInstancePort: PropTypes.number,
	dbInstancePublicAccessible: PropTypes.bool,
	iamDatabaseAuthenticationEnabled: PropTypes.bool,
	dbInstanceEngineName: PropTypes.string,
	status: PropTypes.string,
	instanceType: PropTypes.string,
	publicIp: PropTypes.string,
	iamRole: PropTypes.string,
	amiId: PropTypes.string,
	keyPairName: PropTypes.string,
	privateDns: PropTypes.string,
	privateIp: PropTypes.string,
	publicDns: PropTypes.string,
}
export default ServiceDetails
