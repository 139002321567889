/*************************************************
 * Tvastar
 * @exports
 * @file Tokenization.js
 * @author Prakash // on 13/09/2021
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
 import React, { Component } from 'react'
 import { connect } from 'react-redux'
 import { withRouter } from 'react-router-dom'
 import Select from 'react-select'

 class Tokenization extends Component {
	hierarichalBucketBarChartRef = React.createRef()
	constructor(props) {
		super(props)
		this.props = props;
		this.state = {
			showLoading: false,
            
			yesNoOptions: ['Yes', 'No'],
			watermark: 'No',

            separator: '-',
            date_pattern: '%Y-%m-%d %H:%M:%S.%f',
            output_pattern: '%Y-%m-%d'
		}
	}

	componentDidMount = () => {
        this.editEvent()
	}

    componentDidUpdate = (prevProps) => {
        if(this.props.dlpPropsDetails && typeof this.props.dlpPropsDetails.hasPrivacyPolicyError !== 'undefined' && (prevProps.dlpPropsDetails.hasPrivacyPolicyError !== this.props.dlpPropsDetails.hasPrivacyPolicyError || (!this.state.hasError && (this.state.hasError !== this.props.dlpPropsDetails.hasPrivacyPolicyError)))) {
            this.setState({ hasError: this.props.dlpPropsDetails.hasPrivacyPolicyError })
        }
    }

    editEvent = () => {
        if(this.props.pageType === 'edit' || this.props.pageType === 'view') {
			let policyDetails = this.props.policyDetails
            let deidentification_type = policyDetails.config.deidentification_type
            let forge_type = policyDetails.config.forge_type ? policyDetails.config.forge_type : ''
            let separator = policyDetails.config.separator ? policyDetails.config.separator : '-'
            let date_pattern = policyDetails.config.date_pattern ? policyDetails.config.date_pattern : '%Y-%m-%d %H:%M:%S.%f'
            let output_pattern = policyDetails.config.output_pattern ? policyDetails.config.output_pattern : '%Y-%m-%d'
            let start_date = policyDetails.config.start_date ? policyDetails.config.start_date : ''
            let end_date = policyDetails.config.end_date ? policyDetails.config.end_date : ''

            let forge_pattern_chars = policyDetails.config.forge_pattern_chars ? policyDetails.config.forge_pattern_chars : ''

            let forge_pattern = policyDetails.config.forge_pattern
            if(!forge_pattern && forge_type === 'hex_pattern') {
                forge_pattern = '^^:^^:^^'
            } else if(!forge_pattern && forge_type === 'number_pattern') {
                forge_pattern = '####-#####'
            } else if(!forge_pattern && forge_type === 'text_pattern') {
                forge_pattern = '??? ??????-??'
            }

            let watermark_id = policyDetails.config.watermark_id ? policyDetails.config.watermark_id : ''
            let watermark_name = policyDetails.config.watermark_name ? policyDetails.config.watermark_name : ''
            let watermark_type = policyDetails.config.watermark_type ? policyDetails.config.watermark_type : ''

            this.setState({ deidentification_type, forge_type, updated_forge_type: forge_type, separator, date_pattern, output_pattern, start_date, end_date, forge_pattern, forge_pattern_chars, watermark: watermark_id !== '' ? 'Yes' : 'No', watermark_id, watermark_name, watermark_type }, () => this.onChangePropsEvent())
        }
    }

    onChangeType = () => {        
        let forge_pattern = ''
        if(this.state.forge_type === 'hex_pattern') {
            forge_pattern = '^^:^^:^^'
        } else if(this.state.forge_type === 'number_pattern') {
            forge_pattern = '####-#####'
        } else if(this.state.forge_type === 'text_pattern') {
            forge_pattern = '??? ??????-??'
        }
        this.setState({ forge_pattern }, 
            () => {
                if(this.state.updated_forge_type && this.state.forge_type === this.state.updated_forge_type) {
                    this.editEvent()
                } else {
                    this.onChangePropsEvent()
                }
            }
        )
    }

    onChangePropsEvent = () => {
		let errorMessage = ''
		if(!this.state.deidentification_type || this.state.deidentification_type === '') {
			errorMessage = 'Please select tokenization type'
		} else if(this.state.watermark === 'Yes') {
            if(!this.state.watermark_id) {
                errorMessage = 'Please select watermark'
            }
            if(!this.state.watermark_type) {
                errorMessage = 'Please select watermark type'
            }
        }

        let data = {}
        data.deidentification_type = this.state.deidentification_type ? this.state.deidentification_type : ''
        data.forge_type = this.state.forge_type ? this.state.forge_type : ''

        let selectedText = 'Tokenization '+this.state.deidentification_label

        // data.deflect_lb = this.state.deflect_lb ? this.state.deflect_lb : ''
        // data.deflect_ub = this.state.deflect_ub ? this.state.deflect_ub : ''
        
        // selectedText += ' lower bound '+data.deflect_lb+' upper bound '+data.deflect_ub

        if(this.state.forge_type === 'creditcard') {
            data.separator = this.state.separator ? this.state.separator : ''
            selectedText += ' separator '+data.separator

            if(data.separator === '') {
                errorMessage = 'Please select watermark'
            }
        } else if(this.state.forge_type === 'date') {
            let date = {}
            date.date_pattern = this.state.date_pattern ? this.state.date_pattern : ''
            date.output_pattern = this.state.output_pattern ? this.state.output_pattern : ''
            date.start_date = this.state.start_date ? this.state.start_date : ''
            date.end_date = this.state.end_date ? this.state.end_date : ''
            data.rules = date
            selectedText += ' date pattern '+data.date_pattern+' output pattern '+data.output_pattern+' start date '+data.start_date+' end date '+data.end_date

            if(date.date_pattern === '' || date.output_pattern === '' || date.start_date === '' || date.end_date === '') {
                errorMessage = 'Please select watermark'
            }
        } else if(this.state.forge_type === 'hex_pattern' || this.state.forge_type === 'number_pattern') {
            data.forge_pattern = this.state.forge_pattern ? this.state.forge_pattern : ''
            selectedText += ' pattern '+data.forge_pattern

            if(data.forge_pattern === '') {
                errorMessage = 'Please select watermark'
            }
        } else if(this.state.forge_type === 'text_pattern') {
            data.forge_pattern = this.state.forge_pattern ? this.state.forge_pattern : ''
            data.forge_pattern_chars = this.state.forge_pattern_chars ? this.state.forge_pattern_chars : ''
            selectedText += ' pattern '+data.forge_pattern+' pattern chars'+data.forge_pattern_chars

            if(data.forge_pattern === '' || data.forge_pattern_chars === '') {
                errorMessage = 'Please select watermark'
            }
        } else if(this.state.forge_type === 'ssn') {
            data.separator = this.state.separator ? this.state.separator : ''
            selectedText += ' seprator '+data.separator
            if(data.separator === '') {
                errorMessage = 'Please select watermark'
            }
        } else if(this.state.forge_type === 'phonenumber' || this.state.forge_type === 'ipv4' || this.state.forge_type === 'ipv6' || this.state.forge_type === 'iban' || this.state.forge_type === 'macaddress') {
            data.separator = this.state.separator ? this.state.separator : ''
            selectedText += ' seprator '+data.separator

            if(data.separator === '') {
                errorMessage = 'Please select watermark'
            }
        }

        if(this.props.watermarkTypeArray[this.state.forge_type] && this.props.watermarkTypeArray[this.state.forge_type].length) {
            if(this.state.watermark === 'Yes') {
                data.watermark_type = this.state.watermark_type
                let filter = this.props.watermarkTypeArray[this.state.forge_type].filter(arr => arr.watermark_type === this.state.watermark_type)
                if(filter.length) {
                    Object.entries(filter[0]).forEach(([key, value]) => {
                        data[key] = value
                    })
                }               
    
                data.watermark_id = this.state.watermark_id
                data.watermark_name = this.state.watermark_name
                selectedText += ' watermark type '+this.state.watermark_type
                selectedText += ' with watermark '+this.state.watermark_name
            }
        }        

        this.props.selectedData(data, selectedText, errorMessage)
    }

    getSelectLabel = (item, list) => {
        let filter = list.filter(arr => arr.type === item)
        if(filter.length) {
            this.setState({ deidentification_label: filter[0].label })
        } else {
            this.setState({ deidentification_label: '', deidentification_type: '', forge_type: '' })
        }
    }

    render() {		
		return (
            <div className={`form-group dlpDescriptionForm mt-3`}>
                <div className="d-flex flex-wrap">
                    <p className="mb-0 mr-2 align-self-end text-purple mt-3">Type</p>
                    <Select
                        // menuIsOpen={true}
                        placeholder={'Select'}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        isSearchable={false}
                        className={`selectOption align-self-end mx-2 ${this.state.hasError && (!this.state.forge_type || this.state.forge_type === '') ? 'dottedDangerSelectLine' : ''}`}
                        value={({
                            value: this.state.forge_type ? this.state.forge_type : 'Select',
                            label: this.state.forge_type ? (this.state.deidentification_label ? this.state.deidentification_label : this.getSelectLabel(this.state.forge_type, this.props.list)) : 'Select'
                        })}
                        options={this.props.list && this.props.list.map(item => ({
                            value: item.type,
                            label: item.label,
                            type: item.value,
                        }))}
                        onChange={event => 
                            this.setState({ deidentification_type: event.type, forge_type: event.value, deidentification_label: event.label, watermark_name: '', watermark_type: '', watermark_id: '' }, () => this.onChangeType())
                        }
                    />
                    {this.state.forge_type === 'creditcard' ?
                        <React.Fragment>
                            <p className="mb-0 mx-2 align-self-end text-purple mt-3">separator</p>
                            <input 
                                type="text" 
                                className={`inputField mt-3 w-10 ${this.state.hasError && (!this.state.separator || this.state.separator === '') ? 'dottedDangerTextboxLine' : ''}`}
                                value={this.state.separator ? this.state.separator : ''}
                                onChange={e => this.setState({ separator: e.target.value }, () => this.onChangePropsEvent())}
                            />
                        </React.Fragment>
                    : this.state.forge_type === 'date' ?
                        <React.Fragment>
                            <p className="mb-0 mx-2 align-self-end text-purple mt-3">date pattern</p>
                            <input 
                                type="text" 
                                className={`inputField mt-3 w-25 ${this.state.hasError && (!this.state.date_pattern || this.state.date_pattern === '') ? 'dottedDangerTextboxLine' : ''}`}
                                value={this.state.date_pattern ? this.state.date_pattern : ''}
                                onChange={e => this.setState({ date_pattern: e.target.value }, () => this.onChangePropsEvent())}
                            />
                            <p className="mb-0 mx-2 align-self-end text-purple mt-3">output pattern</p>
                            <input 
                                type="text" 
                                className={`inputField mt-3 w-25 ${this.state.hasError && (!this.state.output_pattern || this.state.output_pattern === '') ? 'dottedDangerTextboxLine' : ''}`}
                                value={this.state.output_pattern ? this.state.output_pattern : ''}
                                onChange={e => this.setState({ output_pattern: e.target.value }, () => this.onChangePropsEvent())}
                            />
                            <p className="mb-0 mx-2 align-self-end text-purple mt-3">Start Date</p>
                            <input 
                                type="text" 
                                className={`inputField mt-3 w-25 ${this.state.hasError && (!this.state.start_date || this.state.start_date === '') ? 'dottedDangerTextboxLine' : ''}`}
                                value={this.state.start_date ? this.state.start_date : ''}
                                onChange={e => this.setState({ start_date: e.target.value }, () => this.onChangePropsEvent())}
                            />
                            <p className="mb-0 mx-2 align-self-end text-purple mt-3">End Date</p>
                            <input 
                                type="text" 
                                className={`inputField mt-3 w-25 ${this.state.hasError && (!this.state.end_date || this.state.end_date === '') ? 'dottedDangerTextboxLine' : ''}`}
                                value={this.state.end_date ? this.state.end_date : ''}
                                onChange={e => this.setState({ end_date: e.target.value }, () => this.onChangePropsEvent())}
                            />
                        </React.Fragment>
                    : this.state.forge_type === 'hex_pattern' ?
                        <React.Fragment>
                            <p className="mb-0 mx-2 align-self-end text-purple mt-3">pattern</p>
                            <input 
                                type="text" 
                                className={`inputField mt-3 w-25 ${this.state.hasError && (!this.state.forge_pattern || this.state.forge_pattern === '') ? 'dottedDangerTextboxLine' : ''}`}
                                value={this.state.forge_pattern ? this.state.forge_pattern : ''}
                                onChange={e => this.setState({ forge_pattern: e.target.value }, () => this.onChangePropsEvent())}
                            />
                        </React.Fragment>
                    : this.state.forge_type === 'number_pattern' ?
                        <React.Fragment>
                            <p className="mb-0 mx-2 align-self-end text-purple mt-3">pattern</p>
                            <input 
                                type="text" 
                                className={`inputField mt-3 w-25 ${this.state.hasError && (!this.state.forge_pattern || this.state.forge_pattern === '') ? 'dottedDangerTextboxLine' : ''}`}
                                value={this.state.forge_pattern ? this.state.forge_pattern : ''}
                                onChange={e => this.setState({ forge_pattern: e.target.value }, () => this.onChangePropsEvent())}
                            />
                        </React.Fragment>
                    : this.state.forge_type === 'text_pattern' ?
                        <React.Fragment>
                            <p className="mb-0 mx-2 align-self-end text-purple mt-3">pattern</p>
                            <input 
                                type="text" 
                                className={`inputField mt-3 w-25 ${this.state.hasError && (!this.state.forge_pattern || this.state.forge_pattern === '') ? 'dottedDangerTextboxLine' : ''}`}
                                value={this.state.forge_pattern ? this.state.forge_pattern : ''}
                                onChange={e => this.setState({ forge_pattern: e.target.value }, () => this.onChangePropsEvent())}
                            />
                            <p className="mb-0 mx-2 align-self-end text-purple mt-3">pattern chars</p>
                            <input 
                                type="text" 
                                className={`inputField mt-3 w-10 ${this.state.hasError && (!this.state.forge_pattern_chars || this.state.forge_pattern_chars === '') ? 'dottedDangerTextboxLine' : ''}`}
                                value={this.state.forge_pattern_chars ? this.state.forge_pattern_chars : ''}
                                onChange={e => this.setState({ forge_pattern_chars: e.target.value }, () => this.onChangePropsEvent())}
                            />
                        </React.Fragment>
                    : this.state.forge_type === 'ssn' ?
                        <React.Fragment>
                            <p className="mb-0 mx-2 align-self-end text-purple mt-3">Separator</p>
                            <input 
                                type="text" 
                                className={`inputField mt-3 w-10 ${this.state.hasError && (!this.state.separator || this.state.separator === '') ? 'dottedDangerTextboxLine' : ''}`}
                                value={this.state.separator ? this.state.separator : ''}
                                onChange={e => this.setState({ separator: e.target.value }, () => this.onChangePropsEvent())}
                            />
                        </React.Fragment>
                    : (this.state.forge_type === 'phonenumber' || this.state.forge_type === 'ipv4' || this.state.forge_type === 'ipv6' || this.state.forge_type === 'iban' || this.state.forge_type === 'macaddress') ? 
                        <React.Fragment>
                            <p className="mb-0 mx-2 align-self-end text-purple mt-3">Separator</p>
                            <input 
                                type="text" 
                                className={`inputField mt-3 w-10 ${this.state.hasError && (!this.state.separator || this.state.separator === '') ? 'dottedDangerTextboxLine' : ''}`}
                                value={this.state.separator ? this.state.separator : ''}
                                onChange={e => this.setState({ separator: e.target.value }, () => this.onChangePropsEvent())}
                            />
                        </React.Fragment>

                    : null}
                </div>
                    
                {this.props.watermarkTypeArray && this.props.watermarkTypeArray[this.state.forge_type] && this.props.watermarkTypeArray[this.state.forge_type].length ?
                    <div className="d-flex flex-wrap">
                        <p className="mb-0 mr-2 align-self-end text-purple mt-3">Add watermark</p>
                        <Select
                            // menuIsOpen={true}
                            placeholder={'Select'}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            isSearchable={false}
                            className={`selectOption mr-2 mt-3 w-15`}
                            value={({
                                value: this.state.watermark ? this.state.watermark : 'Select',
                                label: this.state.watermark ? this.state.watermark : 'Select',
                            })}
                            options={this.state.yesNoOptions && this.state.yesNoOptions.map(item => ({
                                value: item,
                                label: item,
                            }))}
                            onChange={event =>  
                                this.setState({ watermark: event.value }, () => this.onChangePropsEvent())
                            }
                        />
                        
                        {this.state.watermark === 'Yes' ?
                            <React.Fragment>
                                <p className="mb-0 mr-2 align-self-end text-purple mt-3">watermark Type</p>
                                <Select
                                    // menuIsOpen={true}
                                    placeholder={'Select'}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    isSearchable={false}
                                    className={`selectOption mr-2 mt-3 ${this.state.hasError && (!this.state.watermark_type || this.state.watermark_type === '') ? 'dottedDangerSelectLine' : ''}`}
                                    value={({
                                        value: this.state.watermark_type ? this.state.watermark_type : 'Select',
                                        label: this.state.watermark_type ? this.state.watermark_type : 'Select',
                                    })}
                                    options={this.props.watermarkTypeArray[this.state.forge_type] && this.props.watermarkTypeArray[this.state.forge_type].map(item => ({
                                        value: item.watermark_type,
                                        label: item.watermark_type,
                                    }))}
                                    onChange={event =>  
                                        this.setState({ watermark_type: event.value }, () => this.onChangePropsEvent())
                                    }
                                />
                                <p className="mb-0 mr-2 align-self-end text-purple mt-3">with watermark</p>
                                <Select
                                    // menuIsOpen={true}
                                    placeholder={'Select'}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    isSearchable={false}
                                    className={`selectOption mr-2 mt-3 w-30 ${this.state.hasError && (!this.state.watermark_name || this.state.watermark_name === '') ? 'dottedDangerSelectLine' : ''}`}
                                    value={({
                                        value: this.state.watermark_id ? this.state.watermark_id : 'Select',
                                        label: this.state.watermark_name ? this.state.watermark_name : 'Select',
                                    })}
                                    options={this.props.watermarks && this.props.watermarks.map(item => ({
                                        value: item.watermark_id,
                                        label: item.watermark_name,
                                    }))}
                                    onChange={event =>  
                                        this.setState({ watermark_id: event.value, watermark_name: event.label }, () => this.onChangePropsEvent())
                                    }
                                />
                            </React.Fragment>
                        : null}
                    </div>
                : null}
            </div>
        )
    }
}

const mapStateToProps = state => {
	return {
        dlpPropsDetails: state.dlp.dlpPropsDetails,
        watermarks: state.dlp.dlpConfigWatermarklist
	}
}

export default connect(mapStateToProps, {})(withRouter(Tokenization))