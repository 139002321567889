/*************************************************
 * Tvastar
 * @exports
 * @file DynamicLoadAction.js
 * @author Prakash // on 02/05/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'
import {
	SYNCHRONIZER,
	
} from '../config'
import { myLog } from '../utils/utility'

export const dynamicCallApi = (EndPoint, URL, body, callback) => {
	let endPoint = ""
	let urlDetails = URL
	if(EndPoint === "synchronizer") {
		endPoint = SYNCHRONIZER
		urlDetails = "/"+URL
	}
	if(endPoint !== "") {
		return async () => {
			try {
				const response = await API.post(endPoint, urlDetails, { body })
				callback(true, response)
			} catch (error) {
				myLog('apiToCall', error)
				callback(false)
			}
		}
	} else {
		return callback(false)
	}
}