/*************************************************
 * Tvastar
 * @exports
 * @file DLP complianceAction.js
 * @author Prakash // on 18/06/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import { API, Auth } from 'aws-amplify'
import axios from 'axios'

import {
    DLPTHREATENDPOINT,
    DLP_THREAT_END_POINT,
    DLP_COMPLIANCE_GET_COMPLIANCE_CONTROL_DETAILS,
    DLP_COMPLIANCE_GET_COMPLIANCE_DETAILS,
    DLP_COMPLIANCE_GET_COMPLIANCE_COVERAGE_AND_TOP_STATS,
    DLP_COMPLIANCE_DOWNLOAD_CHECKS_RESULTS
} from '../../config'

/**
 * Action to get compliance Control details
 * @param {Object} body
 * @param {Function} callback
 */
export const getComplianceControlDetails = (body, callback) => {
    return async () => {
        try {
            const response = await API.post(DLPTHREATENDPOINT, DLP_COMPLIANCE_GET_COMPLIANCE_CONTROL_DETAILS, { body })
            callback(true, response)
        } catch (error) {
            callback(false)
        }
    }
}

/**
 * Action to get threat details
 * @param {Object} body
 * @param {Function} callback
 */
export const getComplianceDetails = (body, callback) => {
    return async () => {
        try {
            const response = await API.post(DLPTHREATENDPOINT, DLP_COMPLIANCE_GET_COMPLIANCE_DETAILS, { body })		
            callback(true, response)
        } catch (error) {
            callback(false)
        }
    }
}

/**
 * Action to get compliance coverage and top stats
 * @param {Object} body
 * @param {Function} callback
 */
export const getComplianceCoverageAndTopStats = (body, callback) => {
    return async () => {
        try {
            const results = await API.post(DLPTHREATENDPOINT, DLP_COMPLIANCE_GET_COMPLIANCE_COVERAGE_AND_TOP_STATS, { body })		
            callback(true, results)
        } catch (error) {
            callback(false)
        }
    }
}

/**
 * Action to download chedks results
 * @param {Object} body
 * @param {Function} callback
 */
export const downloadChecksResults = (body, callback) => {
    return async dispatch => {
        try {
            const apiUrl = DLP_THREAT_END_POINT + DLP_COMPLIANCE_DOWNLOAD_CHECKS_RESULTS;

            let contentType = 'application/octet-stream'
            if(body.response_format === 'json') {
                contentType = 'application/json'
            } 
            
            const download = await axios.post(apiUrl, body, {
                headers: {
                    'Content-Type': contentType,
                    Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}`
                }
            })

            let data = download.data;
            if(body.response_format === 'json') {
                data = JSON.stringify(download.data);
            }
            const url = window.URL.createObjectURL(new Blob([data])); 
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'report.'+body.response_format); //or any other extension
            document.body.appendChild(link);
            link.click();
            callback(true, download)
        } catch (error) {
            let respone = {}
            respone.message = error.response.data.message
            respone.status = error.response.data.status
            callback(false, respone)
        }
    }
}