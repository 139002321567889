/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from 'react'
import { setEditRuleDetails } from '../../../actions/governance/governanceAction'
import { apiToCall } from '../../../actions/commonAction'
import { Label, Input } from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Select from 'react-select'

class ServiceAccessibility extends Component {
	constructor(props) {
		super(props)
		this.state = {
			services: [],
			accessList: this.getList(this.props.parameters.condition),
		}
	}
	componentDidMount = () => {
		this.props.apiToCall('/list-services', { provider: this.props.parameters.provider }, (success, services) => {
			if (success) {
				this.setState({ services })
			}
		})
	}

	getList = params => {
		let list = []
		if (this.props.editRuleDetails.condition) {
			Object.entries(this.props.editRuleDetails.condition).forEach(([key, item]) => {
				let access_item = {}
				access_item['service'] = key
				access_item['descision'] = Object.keys(item)[0]
				access_item['values'] = Object.values(item)[0]
				list.push(access_item)
			})
		} else {
			if (params) {
				Object.entries(params).forEach(([key, item]) => {
					let access_item = {}
					access_item['service'] = key
					access_item['descision'] = Object.keys(item)[0]
					access_item['values'] = Object.values(item)[0]
					list.push(access_item)
				})
			}
		}
		return list
	}
	setList = list => {
		let condition = {}
		if (list) {
			list.forEach(item => {
				if ('service' in item && item.service.length > 0) {
					condition[item.service] = {
						[item.decision]: item.values,
					}
				}
			})
		}
		this.props.setEditRuleDetails('condition', condition)
	}
	addAccessibilityToList = () => {
		let accessibility_item = {
			service: '',
			decision: 'allow',
			values: [],
		}
		let list = this.state.accessList
		list.push(accessibility_item)
		this.setList(list)
		this.setState({ accessList: list })
	}

	removeAccessibilityFromList = service => {
		let list = _.reject(this.state.accessList, { service: service })
		this.setList(list)
		this.setState({ accessList: list })
	}

	handleChange = event => {
		let index = event.target.name.split('@')[1]
		let valueToChange = event.target.name.split('@')[0]
		let item = this.state.accessList[index]
		item[valueToChange] = event.target.value
		let list = this.state.accessList
		list.splice(index, 1, item)
		this.setList(list)
	}

	handleChipChange = (value, index) => {
		let item = this.state.accessList[index]
		item['values'] = value.map(item => item.value)
		let list = this.state.accessList
		list.splice(index, 1, item)
		this.setList(list)
		this.setState({ accessList: list })
	}

	render() {
		const { services, accessList } = this.state
		return (
			<div>
				{accessList && accessList.length === 0 ? (
					<button type='button' className='btn btn-outline-primary' onClick={() => this.addAccessibilityToList()}>
						Add Accessibility
					</button>
				) : (
					<label>Accessibility:</label>
				)}
				<table>
					<tbody>
						{accessList && accessList.length
							? accessList.map((item, index) => {
									return (
										<tr key={index}>
											<td>
												<Label htmlFor={index + 'service-native-simple'}>Service</Label>
												<small className='text-muted'>Lorem ipsum dolor sit amet aedtr.</small>

												<Input
													type='select'
													onChange={e => this.handleChange(e)}
													value={item.service}
													name={'service@' + index}
													id={index + 'service-native-simple'}
												>
													<option value=''>Select</option>
													{services.map(service => {
														return (
															<option value={service.service_name} key={service.service_name}>
																{service.service_name}
															</option>
														)
													})}
												</Input>
											</td>
											<td>
												<Label htmlFor={index + 'decision-native-simple'}>Decision</Label>
												<small className='text-muted'>Lorem ipsum dolor sit amet aedtr.</small>

												<Input
													type='select'
													onChange={e => this.handleChange(e)}
													value={item.decision ? item.decision : 'allow'}
													name={'decision@' + index}
													id={index + 'decision-native-simple'}
												>
													<option value=''>Select</option>
													<option value='allow' key='allow'>
														Allow
													</option>
													<option value='deny' key='deny'>
														Deny
													</option>
												</Input>
											</td>
											<td>
												<Label>Accessible Services</Label>
												<small className='text-muted'>Lorem ipsum dolor sit amet aedtr.</small>

												<Select
													onChange={arr => this.handleChipChange(arr, index)}
													options={services.map(item => ({
														value: item.service_name,
														label: item.service_name,
													}))}
													value={item.values.map(item => ({
														value: item,
														label: item,
													}))}
													isMulti
													classNamePrefix='Select Services'
													className='basic-multi-select'
													name={'decision@' + index}
													id={index + 'decision-native-simple'}
												/>
											</td>
											<td>
												<button
													className='btn btn-outline-success border-0'
													onClick={() => this.addAccessibilityToList()}
												>
													<i className='far fa-plus-circle' />
												</button>
											</td>
											<td>
												<button
													className='btn btn-outline-danger border-0'
													onClick={() => this.removeAccessibilityFromList(item.service)}
												>
													<i className='far fa-trash-alt' />
												</button>
											</td>
										</tr>
									)
							  })
							: null}
					</tbody>
				</table>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ServiceAccessibility.propTypes = {
	logType: PropTypes.object,
	setEditRuleDetails: PropTypes.func,
	apiToCall: PropTypes.func,
	editRuleDetails: PropTypes.object,
	parameters: PropTypes.object,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		editRuleDetails: state.governance.editRuleDetails,
	}
}
export default connect(mapStateToProps, { setEditRuleDetails, apiToCall })(ServiceAccessibility)
