/*************************************************
 * Tvastar
 * @exports
 * @file BasicDetails.js
 * @author Rajasekar // on 20/02/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class BasicDetails extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	render() {
		return (
			<div className="box p-3 h-100 bg-dark3">
				<div className="d-flex row">
					{this.props.serviceName ? (
						<h1 className="bx-watermark display-3 watermark-tr">{this.props.serviceName.toUpperCase()}</h1>
					) : null}
					<div className="flex-grow-1">
						<h3 className="font-weight-light text-nowrap ml-2 text-white">{this.props.dbInstanceId}</h3>
						{this.props.availabilityZone && (
							<p className="mb-1 ml-2">
								<span className="fad fa-map-marker-alt mr-1" /> <b className="text-nowrap text-white">{this.props.availabilityZone}</b>
							</p>
						)}
						{this.props.region && (
							<p className="mb-0 ml-2">
								<span className="fad fa-location mr-1" /> <b className="text-nowrap text-white">{this.props.region}</b>
							</p>
						)}
					</div>
					<div className="ml-3 pr-3 text-break">
						{this.props.dbName && (
							<p className="mb-1">
								DB Name: <b className="text-white">{this.props.dbName}</b>
							</p>
						)}
						{this.props.description && (
							<p className="mb-1">
								Description: <b className="text-white">{this.props.description}</b>
							</p>
						)}
						{this.props.endpoint && (
							<p className="mb-1">
								Endpoint: <b className="text-white">{this.props.endpoint}</b>
							</p>
						)}
						{this.props.dbInstanceArn && (
							<p className="mb-0">
								Resource ARN:
								<b className="text-white">{this.props.dbInstanceArn}</b>
							</p>
						)}
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
BasicDetails.propTypes = {
	dbInstanceArn: PropTypes.string,
	dbName: PropTypes.string,
	dbInstanceId: PropTypes.string,
	availabilityZone: PropTypes.string,
	region: PropTypes.string,
	description: PropTypes.string,
	serviceName: PropTypes.string,
	endpoint: PropTypes.string,
}

export default BasicDetails
