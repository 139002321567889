/*************************************************
 * Tvastar
 * @exports
 * @file diagnosticsAction.js
 * @author Prakash // on 08/07/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
// import cognitoService from '../../services/cognitoService'
// import { myLog, numberFormater, capitalizeFirstLetter } from '../../utils/utility'
import { API } from 'aws-amplify'

import {
	AIOPS_DIAGNOSTICS,
	AIOPS_DIAGNOSTICS_LIST_DIAGNOSTICS,
	AIOPS_DIAGNOSTICS_GET_DIAGNOSTIC_DETAILS,
	AIOPS_DIAGNOSTICS_START_DIAGNOSTICS,
	AIOPS_DIAGNOSTICS_LIST_DIAGNOSTIC_CHECKS,
	AIOPS_DIAGNOSTICS_LIST_DIAGNOSTIC_TEMPLATE,
	AIOPS_DIAGNOSTICS_SAVE_DIAGNOSTIC_TEMPLATE,
	AIOPS_DIAGNOSTICS_GET_TROUBLSHOOT_DETAILS,

	
	AIOPS_SRE,
	AIOPS_DIAGNOSTICS_GET_SRE_DETAILS,
	

} from '../../config'

import { ACTION_TYPES } from '../types'

/**
 * Action to list Diagnostics
 * @param {Object} body
 * @param {Function} callback
 */
 export const listDiagnostics = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(AIOPS_DIAGNOSTICS, AIOPS_DIAGNOSTICS_LIST_DIAGNOSTICS, { body })
			dispatch({
				type: ACTION_TYPES.SET_AIOPS_DIAGNOSTICS_LIST,
				payload: result,
			})
			callback(true, result)
		} catch(err) {
			// let message = ''
			callback(false, {})
		}
    }
}

export const getDiagnosticDetails = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(AIOPS_DIAGNOSTICS, AIOPS_DIAGNOSTICS_GET_DIAGNOSTIC_DETAILS, { body })
			dispatch({
				type: ACTION_TYPES.SET_AIOPS_DIAGNOSTICS_DETAILS,
				payload: result,
			})
			callback(true, result)
		} catch(err) {
			// let message = ''
			callback(false, {})
		}
    }
}

/**
 * Action to start diagnostics
 * @param {Object} body
 * @param {Function} callback
 * @author Prakash // on 18/08/2021
 */
export const startDiagnostics = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(AIOPS_DIAGNOSTICS, AIOPS_DIAGNOSTICS_START_DIAGNOSTICS, { body })
			// dispatch({
			// 	type: ACTION_TYPES.SET_AIOPS_ASSETS_LSIT,
			// 	payload: result,
			// })
			callback(true, result)
		} catch(err) {
			// let message = ''
			// dispatch({
			// 	type: ACTION_TYPES.SET_AIOPS_ASSETS_LSIT,
			// 	payload: [],
			// })
			callback(false, {})
		}
    }
}

/**
 * Action to get sre details 
 * @param {Function} callback
 * @param {Function} callback
 * @author Prakash // on 18/02/2022
 */
 export const listDiagnosticChecks = callback => {
	return async dispatch => {
		try {
			const response = await API.get(AIOPS_DIAGNOSTICS, AIOPS_DIAGNOSTICS_LIST_DIAGNOSTIC_CHECKS)
			callback(true, response)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to get sre details 
 * @param {Object} body
 * @param {Function} callback
 * @author Prakash // on 21/07/2021
 */
 export const getSreDetails = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(AIOPS_SRE, AIOPS_DIAGNOSTICS_GET_SRE_DETAILS, { body })
			// dispatch({
			// 	type: ACTION_TYPES.SET_AIOPS_ASSETS_LSIT,
			// 	payload: result,
			// })
			callback(true, result)
		} catch(err) {
			// let message = ''
			// dispatch({
			// 	type: ACTION_TYPES.SET_AIOPS_ASSETS_LSIT,
			// 	payload: [],
			// })
			callback(false, {})
		}
    }
}


/**
 * Action to set diagnostics props details
 *   for diagnostics page
 * @author Prakash // on 13/01/2022
*/
export const setDiagnosticsPropsDetails = (key, value) => {
	return async (dispatch, getState) => {
		let diagnosticsPropsDetails = JSON.parse(JSON.stringify(getState().aiops.diagnosticsPropsDetails))
		diagnosticsPropsDetails[key] = value
		dispatch({
			type: ACTION_TYPES.SET_DIAGNOSTICS_PROPS_DETAILS,
			payload: diagnosticsPropsDetails,
		})
	}
}


/**
 * Action to list-diagnostic-template
 * @param {Object} body
 * @param {Function} callback
 */
 export const listDiagnosticTemplate = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(AIOPS_DIAGNOSTICS, AIOPS_DIAGNOSTICS_LIST_DIAGNOSTIC_TEMPLATE, { body })
			let result = response.results ? response.results : []
			dispatch({
				type: ACTION_TYPES.SET_AIOPS_DIAGNOSTICS_TEMPLATE_LIST,
				payload: result,
			})
			callback(true, response)
		} catch(err) {
			callback(false, {})
		}
    }
}

/**
 * Action to save-diagnostic-template
 * @param {Object} body
 * @param {Function} callback
 */
 export const saveDiagnosticTemplate = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(AIOPS_DIAGNOSTICS, AIOPS_DIAGNOSTICS_SAVE_DIAGNOSTIC_TEMPLATE, { body })
			callback(true, result)
		} catch(err) {
			// let message = ''
			callback(false, {})
		}
    }
}

/**
 * Action to get-troubleshoot-details
 * @param {Object} body
 * @param {Function} callback
 */
 export const getTroubleshootDetails = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(AIOPS_DIAGNOSTICS, AIOPS_DIAGNOSTICS_GET_TROUBLSHOOT_DETAILS, { body })
			dispatch({
				type: ACTION_TYPES.SET_DIAGNOSTICS_TROUBLESHOOT_DETAILS,
				payload: result,
			})
			callback(true, result)
		} catch(err) {
			// let message = ''
			dispatch({
				type: ACTION_TYPES.SET_DIAGNOSTICS_TROUBLESHOOT_DETAILS,
				payload: [],
			})
			callback(false, {})
		}
    }
}