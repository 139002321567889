/*************************************************
 * Tvastar
 * @exports
 * @file AccountARegisterction.js
 * @author Prakash
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'

import {
	USER_SIGNUP,
	ADD_ACCOUNT
} from '../../config'


/**
 * Action to signup
 * @param {Object} body
 * @param {Function} callback
 */
export const addAccount = (body, callback) => {
	return async () => {
		await API.post(USER_SIGNUP, ADD_ACCOUNT, { body })
		.then((response) => {
			console.log(response);
			callback(true, response)
		}).catch((err) => {
			// console.log(err.response.data.error); // you can get the response like this
			// console.log(err.response.data.status); // status code of the request
			let message = ''
			callback(false, message)
		})
    }
}