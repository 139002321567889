/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file DLP Trend Dashbaord.js
 * @author Prakash // on 18/06/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
 import React, { Component } from 'react'
 import { AppWrapper } from '../../common/AppWrapper'
import PropTypes from 'prop-types'
import { 
	listAllProviders, 
	listAllAccounts, 
	listAllRegions
} from '../../../actions/commonAction'

import {
	getDlpFilterProperties,
	startDataEventsDailyTrend,
	getDataEventsDailyTrend
} from '../../../actions/dlp/trendAction'

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import _ from 'lodash'

import Chart from 'react-apexcharts'

import {  currentLocaltime, previousDay, subDays, momentDateGivenFormat, momentConvertionUtcToLocalTime, momentConvertionLocalToUtcTime,  getPastDaysNew, capitalizeFirstLetter, removeUnderScore, splitString, twoDateDiffrence } from '../../../utils/utility'

import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'

import TrendTable from './TrendTable'

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";

class TrendDashboard extends Component {
    accountRef = React.createRef()
    regionRef = React.createRef()
    serviceRef = React.createRef()
    controlRef = React.createRef()
    searchControlComponent = React.createRef()
	
	trendFilterRef = React.createRef()

    constructor(props) {
        super(props)
        this.state = {

			providers: [],
            selectedProvider: '',

			accounts: [],
			accountArray: [],
			selectedAccount: [],
			selectedAccountName: [],
			isAccountOpen: false,

            regions: [],
			selectedRegion: [],
			selectedRegionName: [],
			isRegionOpen: false,
			selectedFilterPeriod: 30,
			
            selectedCompliance: 'dc v1.0',

            
			showFilterBatch: false,
			trendFilters: [],

			left: 0,
			showLoading: true,
		
			accessTrend: [],
			trend_bar_data: [],
			trend_bar_option: [],
			selectedTrend:'daily',
			showGroupByLabel: '',
			defaultSelectedGroupBy: '',
			selectedGroupBy: '',
			selectedGroupByTags: '',
			
			dlpTrendTable: [],
			columns: [],

			grouping_sets: {},
			selectedTotalTrendFilter: [],
			selectedAccessedBy: [],
			selectedTrendOption: 'count',
			selectedAgentContent: 'Agent',
			filteredAgentArray: [],

			selectedActionContent: 'Action',
			filteredActionArray: [],
        }

        this.handleStartTimeChange  = this.handleStartTimeChange.bind(this)
        this.handleEndTimeChange    = this.handleEndTimeChange.bind(this)
    }

	componentDidMount = () => {	
		if(this.props.providers && this.props.providers.length) {
			this.setState({ providers: this.props.providers, selectedProvider: this.props.providers[0].provider_name },
				() => {
					this.filterDlp('provider')
					this.sliderPeriod()	
				}
			)
		} else {
			this.props.listAllProviders((promise, providers) => {
				if (promise) {
					this.setState({ providers, selectedProvider: providers[0].provider_name },
						() => {
							this.filterDlp('provider')
							this.sliderPeriod()	
						}
					)
				} else {
					this.setState(
						{ providers: [],  }, 
						() => {
							this.filterDlp()
							this.sliderPeriod()	
						}
					)
				}
			})
		}

        this.getDlpFilterProperties()
	}

	filterDlp = from => {
		let params = {}

		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'all') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}

		if (from === 'provider') {
			if(this.props.accounts && this.props.accounts.length) {
				let allAccounts = this.props.accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
				if(this.props.accounts.length) {
					let accRow = {}
					accRow.account_id = "All"
					accRow.account_name = "All"
					accRow.date = null
					accRow.disabled = false
					accRow.provider = "All"
					allAccounts.unshift(accRow);
				}
				this.setState({ accounts: allAccounts })
			} else {
				this.props.listAllAccounts(params, (promise, allAccounts) => {
					if (promise) {						
						let filterdAccounts = allAccounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
						if(filterdAccounts.length) {
							let accRow = {}
							accRow.account_id = "All"
							accRow.account_name = "All"
							accRow.date = null
							accRow.disabled = false
							accRow.provider = "All"
							filterdAccounts.unshift(accRow);
						}						
						this.setState({ accounts: filterdAccounts })
					}
				})
			}			

			if(this.props.regions && this.props.regions.length) {
				let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
				this.setState({ regions: providerRegions })
			} else {
				this.props.listAllRegions(params, (promise, regions) => {
					if (promise) {
						let providerRegions = regions && regions[0] && regions[0].provider_regions
						if(providerRegions) {
							let provider_regions_row = {}
							provider_regions_row.availability_zones = []
							provider_regions_row.city = "All"
							provider_regions_row.country = "All"
							provider_regions_row.geography = "All"
							provider_regions_row.name = "All"
							provider_regions_row.region = "All"
							provider_regions_row.region_id = "All"
							provider_regions_row.state = "All"
							providerRegions.unshift(provider_regions_row);
						}
						this.setState({ regions: providerRegions })
					} else {
						this.setState({ regions: [] })
					}
				})
			}
		}
	}

    getDlpFilterProperties = () => {
        let params = {}
		params.page = 'trends'
		this.props.getDlpFilterProperties(params, (promise, result) => {
			if(promise) {
				let trendFilterArray = []
				result.length && result.forEach(item => {
					let dataRow = {}
					if(item.filter === 'Format' || item.filter === 'Storage Option' || item.filter === 'Geography') {
						dataRow.key = item.key
						dataRow.filter = item.filter
						let values = []
						item.values.forEach(val => {
							let dataValues ={}
							dataValues.label = val
							dataValues.value = item.key+'=='+val
							values.push(dataValues)
						})
						dataRow.values = values
					} else if(item.filter === 'Device' || item.filter === 'Properties') {
						dataRow.filter = item.filter
						dataRow.key = item.filter
						let values = []
						item.properties.forEach(prop => {
							let dataValues = {}
							dataValues.label = prop.filter
							dataValues.value = prop.key+'=='+prop.filter
							values.push(dataValues)
						})
						dataRow.values = values
					} else if(item.filter === 'Action') {
						dataRow.filter = item.filter
						dataRow.key = item.filter
						let array = []
						item.properties.forEach((prop, i) => {
							if(i === 0) {
								let parent = {}
								parent.label = 'All'
								parent.value = 'All'
								parent.expanded = true
								let childArray = []
								parent.children = childArray
								array.push(parent)
							}

							let parent = {}
							parent.label = prop.filter
							parent.value = prop.filter+'=='+prop.key
							parent.expanded = true
							let childArray = []							
							prop.values.forEach((itm, i) => {
								let child = {}
								child.label = itm
								child.value = prop.key+'=='+itm
								child.expanded = true
								childArray.push(child)
							})
							parent.children = childArray
							array.push(parent)
						})
						dataRow.values = array
						this.setState({ actionArray: array, filteredActionArray: array })
						
					} else if(item.filter === 'Agent') {
						dataRow.filter = item.filter
						dataRow.key = item.filter
						let array = []
						item.properties.forEach((prop, i) => {
							if(i === 0) {
								let parent = {}
								parent.label = 'All'
								parent.value = 'All'
								parent.expanded = true
								let childArray = []
								parent.children = childArray
								array.push(parent)
							}

							let parent = {}
							parent.label = prop.filter
							parent.value = prop.filter+'=='+prop.key
							parent.expanded = true
							let childArray = []							
							prop.values.forEach((itm, i) => {
								let child = {}
								child.label = itm
								child.value = prop.key+'=='+itm
								child.expanded = true
								childArray.push(child)
							})
							parent.children = childArray
							array.push(parent)
						})
						dataRow.values = array
						this.setState({ agentArray: array, filteredAgentArray: array })
					} else if(item.filter === 'Accessed By') {
						dataRow.filter = item.filter
						dataRow.key = 'accessed_by'
						
						let values = []
						item.properties.forEach(prop => {
							let dataValues = {}
							if(prop.key === 'public') {
								dataValues.label = prop.filter
								dataValues.key = prop.key
								dataValues.value = prop.key+'=='+prop.filter
							} else {
								dataValues.label = prop.filter
								dataValues.key = prop.key
								dataValues.value = prop.key+'=='+prop.filter
								let dataChild = []
								prop.values.forEach((child, x) => {
									let dataChildObject = {}
									// child = 'child'+x
									dataChildObject.label = child
									dataChildObject.value = prop.key+'=='+child
									dataChild.push(dataChildObject)
								})
								dataValues.child = dataChild
							}
							values.push(dataValues)
						})
						dataRow.values = values
					}
					trendFilterArray.push(dataRow)		
				})

				this.setState({ trendFilters: result, trendFilterArray })
			}
		})
    }

	onSearch = () => {
        let changesInFilter = this.state.changesInFilter;
		if(!this.state.previousSliderStartDate || (this.state.previousSliderStartDate !== this.state.sliderStartDate || this.state.previousSliderEndDate !== this.state.sliderEndDate)) {
			changesInFilter = true
			this.setState({ previousSliderStartDate: this.state.sliderStartDate, previousSliderEndDate: this.state.sliderEndDate })
		}

		if(changesInFilter) {
			let startTime = this.state.sliderStartDate
			let endTime = this.state.sliderEndDate
			// if(this.state.changesInDatePicker) {
			// 	startTime = this.state.start_time
			// 	endTime = this.state.end_time
			// }
			this.setState({
				start_time: momentConvertionLocalToUtcTime(startTime, 'YYYY-MM-DD HH:mm:ss'),
				end_time: momentConvertionLocalToUtcTime(endTime, 'YYYY-MM-DD HH:mm:ss'),
				showLoading: true
			},
				() => {	       
                    this.startDataEventsDailyTrend()
				}	
			)
		}

		this.setState({ changesInFilter: false, changesInDatePicker: false })

		setTimeout(() => { this.setState({ showLoading: false }) }, 5000)
	}

    startDataEventsDailyTrend = () => {
        if(this.props.startDailyTrendProps && !Object.entries(this.props.startDailyTrendProps).length) {
			let params = {}		
			if(Object.entries(this.state.grouping_sets).length) {
				params = this.state.grouping_sets
			} else {
				params.start_time = this.state.start_time
				params.end_time = this.state.end_time
			}

			let aggregate_by = []
			if (this.state.selectedAccount.length && !this.state.selectedAccount.includes('All')) {
				params.account_id = this.state.selectedAccount
				aggregate_by.push('account_id')
			}

			if (this.state.selectedRegion.length && !this.state.selectedRegion.includes('All')) {
				params.region = this.state.selectedRegion
				aggregate_by.push('region')
			}

			if(aggregate_by.length) {
				params.aggregate_by = aggregate_by
			}
			this.props.startDataEventsDailyTrend(params, (promise) => {
				if(promise) {
					this.getDataEventsDailyTrend()
				}
			})
		} else {
			this.getDataEventsDailyTrend()
		}
    }

	onReset = () => {
		this.setState({
			selectedProvider: this.state.providers && this.state.providers.length ? this.state.providers[0].provider_name : '',
			selectedRegion: [],
			selectedRegionName: [],
			selectedAccount: [],
			sliderStartDate: this.state.initialStartDate,
			sliderEndDate: this.state.initialEndDate,
			changesInFilter: true,
			changesInDatePicker: false,
		},
			() => this.onSearch()
		)
	}    

	applyFilter = () => {
		
		let grouping_sets = {}
		let selectedTotalTrendFilter = []
		if(this.state.format && this.state.format.length) {
			let array = []
			this.state.format.forEach(item => {				
				let splitArray = splitString(item, '==')
				array.push(splitArray[1])

			})
			grouping_sets['format'] = array
			
			let dataRow = {}
			dataRow.label = 'Format'
			dataRow.value = array
			selectedTotalTrendFilter.push(dataRow)
		}

		if(this.state.storage_class && this.state.storage_class.length) {
			let array = []
			this.state.storage_class.forEach(item => {
				let splitArray = splitString(item, '==')
				array.push(splitArray[1])

			})
			grouping_sets['storage_class'] = array

			let dataRow = {}
			dataRow.label = 'Storage Option'
			dataRow.value = array
			selectedTotalTrendFilter.push(dataRow)
		}
		
		if(this.state.geo_location && this.state.geo_location.length) {
			let array = []
			this.state.geo_location.forEach(item => {
				let splitArray = splitString(item, '==')
				// let dataRow = {}
				// dataRow[splitArray[0]] = splitArray[1]
				// array.push(dataRow)
				let geoArray = grouping_sets[splitArray[0]] ? grouping_sets[splitArray[0]] : []
				geoArray.push(splitArray[1])
				grouping_sets[splitArray[0]] = geoArray		
				array = geoArray		
			})

			let dataRow = {}
			dataRow.label = 'Geography'
			dataRow.value = array
			selectedTotalTrendFilter.push(dataRow)
		}

		if(this.state.Action && this.state.Action.length) {
			let array = []
			this.state.Action.forEach(item => {
				let splitArray = splitString(item, '==')
				array.push(splitArray[1])
			})
			if(array.length) {
				grouping_sets['sub_action'] = array
			}
			let dataRow = {}
			dataRow.label = 'Action'
			dataRow.value = array
			selectedTotalTrendFilter.push(dataRow)
		}

		if(this.state.Agent && this.state.Agent.length) {
			let array = []
			this.state.Agent.forEach(item => {
				let splitArray = splitString(item, '==')
				let agentArray = grouping_sets[splitArray[0]] ? grouping_sets[splitArray[0]] : []
				agentArray.push(splitArray[1])
				grouping_sets[splitArray[0]] = agentArray
				array.push(splitArray[1])
			})
			let dataRow = {}
			dataRow.label = 'Agent'
			dataRow.value = array
			selectedTotalTrendFilter.push(dataRow)

		}

		if(this.state.Properties && this.state.Properties.length) {
			let array = []
			this.state.Properties.forEach(item => {
				let splitArray = splitString(item, '==')
				// array.push(splitArray[0])
				grouping_sets[splitArray[0]] = [true]

				array.push(splitArray[0])
			})

			let dataRow = {}
			dataRow.label = 'Properties'
			dataRow.value = array
			selectedTotalTrendFilter.push(dataRow)
		}

		if(this.state.Device && this.state.Device.length) {
			let array = []
			this.state.Device.forEach(item => {
				let splitArray = splitString(item, '==')
				// let agentArray = grouping_sets[splitArray[0]] ? grouping_sets[splitArray[0]] : []
				// agentArray.push(splitArray[1])
				grouping_sets[splitArray[0]] = [true]
				array.push(splitArray[1])
			})

			let dataRow = {}
			dataRow.label = 'Device'
			dataRow.value = array
			selectedTotalTrendFilter.push(dataRow)
		}

		if(this.state.accessed_by && this.state.accessed_by.length) {
			this.state.accessed_by.forEach(item => {
				let array = []
				if(item === 'public') {
					grouping_sets['public'] = [true]

					let dataRow = {}
					dataRow.label = 'Accessed By'
					dataRow.value = 'Public'
					selectedTotalTrendFilter.push(dataRow)
				} else if(this.state[item] && this.state[item].length) {
					
					let stateValue = this.state[item]
					stateValue.forEach(details => {
						let splitArray = splitString(details, '==')
						array.push(splitArray[1])						
					})
					grouping_sets[item] = array

					let dataRow = {}
					dataRow.label = item === 'cross_account_details' ? 'Cross Account' : 'Cross Organization'
					dataRow.value = array
					selectedTotalTrendFilter.push(dataRow)
				}
				// let splitArray = splitString(item, '==')
			})
		}

		// if(this.state.start_time && this.state.end_time) {

		// 	var Difference_In_Days = twoDateDiffrence(this.state.start_time, this.state.end_time)
		// 	let durtaion = Difference_In_Days+'d';
		// 	grouping_sets['durtaion'] = durtaion
		// }
		grouping_sets.start_time = this.state.start_time
		grouping_sets.end_time = this.state.end_time
		this.setState({ grouping_sets, selectedTotalTrendFilter }, 
			() => this.startDataEventsDailyTrend()
		)
	}

	clearFilter = () => {
		
		if(this.state.geo_location && this.state.geo_location.length) {
			this.state.geo_location.forEach(item => {
				let splitArray = splitString(item, '==')
				this.setState({ [splitArray[0]]: [], ['label_'+splitArray[0]]:  [] })
			})
		}

		if(this.state.accessed_by && this.state.accessed_by.length) {
			this.state.accessed_by.forEach(item => {
				this.setState({ [item]: [], ['label_'+item]: [], ['checkbox_'+item]: false , accessed_by: [], label_accessed_by: [] })
			})
		}

		this.setState({ 
			showFilterBatch: false,
			grouping_sets: {}, 
			Device: [], 
			label_Device: [],
			Properties: [], 
			label_Properties: [],
			Agent: [], 
			selectedAgent: [],
			label_Agent: [],
			selectedAgentContent: 'Agent',
			Action: [], 
			selectedAction: [],
			label_Action: [],
			selectedActionContent: 'Action',
			geo_location: [], 
			storage_class: [], 
			label_storage_class: [],			
			format: [],
			label_format: [],
			filteredAgentArray: this.state.agentArray,
			filteredActionArray: this.state.actionArray
		})
	}
	
	fitlerBtnClick = (from, action) => {
		this.setState({ ['label_'+from]: [] })
		if(from === 'Format') {
			this.setState({ format: [] })
		} else if(from === 'Device') {
			this.state.Device && this.state.Device.forEach(item => {
				this.setState({ [item]: [] })
			})
			this.setState({ Device: []})
		} else if(from === 'Storage Option') {
			this.setState({ storage_class: [] })
		} else if(from === 'Geography') {
			this.state.geo_location && this.state.geo_location.forEach(item => {
				this.setState({ [item]: [] })
			})
			this.setState({ geo_location: [] })
		} else if(from === 'Properties' ) {
			this.setState({ Properties: [] })
		} else if(from === 'Action') {
			this.setState({ Action: [], selectedActionContent: 'Action', filteredActionArray : this.state.actionArray, })
		} else if(from === 'Agent') {
			this.setState({ Agent: [], selectedAgentContent: 'Agent', filteredAgentArray : this.state.agentArray, })
		} else if(from === 'Accessed By') {
			if(this.state.accessed_by && this.state.accessed_by.length) {
				this.state.accessed_by.forEach(item => {
					this.setState({ [item]: [], ['label_'+item]: [], ['checkbox_'+item]: false , accessed_by: [], label_accessed_by: [] })
				})
			}
		}
	}

	getSelectedGroupBy = (groupBy) => {
		this.setState({ selectedGroupBy: groupBy, showGroupByLabel: removeUnderScore(groupBy)},
			() => this.trendChange()
		)
	}

	sliderPeriod = () => {
		let sliderEndDate = momentDateGivenFormat(currentLocaltime(), 'YYYY-MM-DD 23:59:59')		
		let brushInterval = 0
		let fullBar = 0
		let smallBar = 0
		let filterMonth = 0
		if(this.state.selectedFilterPeriod === 7) {
			fullBar = 10
			brushInterval = 7
			filterMonth = 90
		} else if(this.state.selectedFilterPeriod === 30) {
			fullBar = 5
			brushInterval = 30
			filterMonth = 180
		} else if(this.state.selectedFilterPeriod === 90) {
			fullBar = 10
			brushInterval = 90
			filterMonth = 365
		}
		// else if(this.state.selectedFilterPeriod === 180) {
		// 	fullBar = 15
		// 	smallBar = 3
		// 	brushInterval = 30
		// } else if(this.state.selectedFilterPeriod === 365) {
		// 	fullBar = 15
		// 	smallBar = 3
		// 	brushInterval = 60
		// }
		let dateArray = []

		let y = 1
		let z = 1
		for(let x=1;x<=filterMonth;x++) {
			
			let date = subDays(sliderEndDate, (filterMonth-x))

			let value = smallBar ? 0 : 5
			if(smallBar === z) {
				value = 5
				z = 1
			}
			z++
			if(y === fullBar || y === 1) {
				value = 10
				y = 1
			}
			y++;
			if(new Date(date).getTime() <= new Date(sliderEndDate).getTime()) {
				dateArray.push([date, value])
			}
		}

		let sliderStartDate = ''
		if(this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.sliderStartDate && this.props.history.location.state.sliderEndDate) {
			sliderStartDate = momentDateGivenFormat(this.props.history.location.state.sliderStartDate, 'YYYY-MM-DD 00:00:00');
			sliderEndDate = momentDateGivenFormat(this.props.history.location.state.sliderEndDate, 'YYYY-MM-DD 00:00:00');
        } else {
			sliderStartDate = subDays(sliderEndDate, brushInterval)
			sliderStartDate = momentDateGivenFormat(sliderStartDate, 'YYYY-MM-DD')
			sliderEndDate = momentDateGivenFormat(sliderEndDate, 'YYYY-MM-DD')
		}
		
		let startSlice = momentDateGivenFormat(sliderStartDate, 'YYYY-MM-DD 00:00:00');
		let endSlice = momentDateGivenFormat(sliderEndDate, 'YYYY-MM-DD 23:59:59');

		this.setState({ dateArray, brushSliderSeries: [], sliderStartDate, sliderEndDate, initialStartDate: sliderStartDate, initialEndDate: sliderEndDate, startSlice, endSlice },
			() => {
				this.brushSliderChart(dateArray)
				this.onSearch()
                window.addEventListener('resize', () => {
                    this.brushSliderChart(dateArray)
                })
			}
		)
	}
	
	brushSliderChart = (dateArray) => {
        let sliderStartDate = this.state.sliderStartDate
        let sliderEndDate = this.state.sliderEndDate
		if(dateArray.length) {
			// let labels = []
			// let dataOneLength = dateArray.length
			// let lastDate = dateArray[dataOneLength-1][0]
			let brushSliderSeries = [{
				data: dateArray
			}]
			let dataArray = []
			dateArray.forEach(item => {
				dataArray.push(item[0])
			})

			let columnWidth = '5%'
			let brushSliderOptions = {
				plotOptions: {
					bar: {
						barHeight: '100%',
						columnWidth: columnWidth,
					},          
				},
				chart: {
					redrawOnParentResize: true,
					id: 'chart1',
					height: 130,
					type: 'bar',
					brush:{
						target: 'none',
						enabled: true,
					},
					zoom: {
						enabled: false,
					},
					selection: {
						enabled: true,
						fill: {
							color: "#039BE5",
							opacity: 1,
							// opacity: 0.6
						},  
						stroke: {
							show: false,
							curve: 'straight',
							lineCap: 'round',
							// colors: '#00A3CB',
							colors: '#039BE5',
							width: 0,
							dashArray: 0,      
						},
						xaxis: {
							min: new Date(sliderStartDate).getTime(),
							max: new Date(sliderEndDate).getTime(),
						},
						style: {
							fontSize: '28px',
							color: '#000000'
						}
					},
					events: {
						selection: (chartContext, { xaxis, yaxis }) => {
							var minDate = momentDateGivenFormat(new Date(xaxis.min), 'YYYY-MM-DD')
							var maxDate = momentDateGivenFormat(new Date(xaxis.max), 'YYYY-MM-DD')

							let startSlice = momentDateGivenFormat(minDate, 'YYYY-MM-DD 00:00:00');
							let endSlice = momentDateGivenFormat(maxDate, 'YYYY-MM-DD 23:59:59');

							this.setState({ sliderStartDate: startSlice, sliderEndDate: endSlice, startSlice, endSlice },
								() => this.getAttribute()	
							)
						},
						click: (event, chartContext, config) => {
							this.onSearch()
						}
					}
				},
				colors: ['#515151'],
				xaxis: {
					tickAmount: 8,
					type: 'datetime',
					tooltip: {
						enabled: false
					},
					position: 'top',
					labels: {
						show: true,
						datetimeUTC: false,
						style: {
							colors: '#E0E0E0'
						},
						formatter: function(val) {
							return momentDateGivenFormat(new Date(val), "DD MMM");
						}
					},
					
				},
				yaxis: {
					show: false,
					tickAmount: 2,
					labels: {
						show: false
					},
				},
				grid: {
					xaxis: {
						lines: {
							show: false
						},
					},
					yaxis: {
						lines: {
							show: false
						}
					},
				}
			}
		
			this.setState({ datePickerSliderSeries: brushSliderSeries, datePickerSliderOptions: brushSliderOptions })
		}
	}
	
	getAttribute = () => {
		var attributes = document.getElementsByClassName("apexcharts-selection-rect") && document.getElementsByClassName("apexcharts-selection-rect")[0] &&document.getElementsByClassName("apexcharts-selection-rect")[0].attributes;
		if(attributes) {
			let width = attributes.width.value
			let x1 = parseInt(attributes.x.value) + parseInt(20)
			let x2 = parseInt(attributes.x.value) + parseInt(width) + parseInt(10)
			
			let leftLPosition = x1
            let rightLPosition = x2
            let diff = twoDateDiffrence(this.state.sliderEndDate, this.state.sliderStartDate)
            if(diff < 8) {
                leftLPosition = x1 - 20
                if(diff < 4) {
                    leftLPosition = x1 - 40
                }
                rightLPosition = x2 + 10
            }

			var d1 = document.getElementById('leftSliderSpan');
			d1.style.position = "absolute";
			d1.style.left = leftLPosition+'px';
			d1.style.top = '110px';
			d1.style.color = '#039BE5';

			var d2 = document.getElementById('rightSliderSpan');
			d2.style.position = "absolute";
			d2.style.left = rightLPosition+'px';
			d2.style.top = '110px';
			d2.style.color = '#039BE5';
		}
	}

	handleStartTimeChange(date) {
		let startSlice = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
		let endSlice = this.state.endSlice
		if(new Date(startSlice).getTime() > new Date(endSlice).getTime() ) {
			endSlice = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')
		}

		this.setState({ startSlice, endSlice, changesInFilter: true })
	}

	handleEndTimeChange(date) {
		let startSlice = this.state.startSlice
		let endSlice = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
		if(new Date(endSlice).getTime() > new Date(startSlice).getTime() ) {
			this.setState({ endSlice, changesInFilter: true })
		}
	}
	
	getDataEventsDailyTrend = () => {
		let params = {}
		let data = this.props.startDailyTrendProps
		params = data
		this.props.getDataEventsDailyTrend(params, (promise, result) => {
			if(promise) {
				if(result.status === 'QUEUED' || result.status === 'RUNNING') {
					setTimeout(() => {
						this.getDataEventsDailyTrend()
					}, 5000)
				} else if(result.status === 'SUCCEEDED' ) {
					let trendArray = []
					let trendFormedObject = {}
					let trendDates = []
					Object.entries(result).length && result.results.forEach((item, i) => {
						if(i === 0) {
							this.setState({ defaultSelectedGroupBy: item.group_name,  showGroupByLabel: removeUnderScore(item.group_name), selectedGroupBy: item.group_name })
						}
						trendDates = item.dates
						let dataRow = {}
						dataRow.count = item.data.count
						dataRow.hits = item.data.hits
						dataRow.size_in_gb = item.data.size_in_gb
						trendFormedObject[item.group_name] = dataRow

						trendArray.push(item)
					})

					this.setState({ trendData: result, trendDates, trendFormedObject, showLoading: false, trendArray },
						() => this.trendChange()
					)
				} else if(result.status === 'FAILED' || result.status === 'CANCELLED') {
					this.setState({ showLoading: false })
				}
			} else {
				this.setState({ showLoading: false })
			}
		})
	}

	trendChange = () => {
		this.getDlpTrend()
	}

	getDlpTrend = () => {
		let data = this.state.trendFormedObject
		let dates = this.state.trendDates
		let selectedTrendOption = this.state.selectedTrendOption
		let selectedGroupBy = this.state.selectedGroupBy

		let graphData = {}
		let graphTotalData = {}
		Object.entries(data).length && Object.entries(data).forEach(([key, value], i) => {
			if(key === selectedGroupBy) {
				graphData = value[selectedTrendOption]
				graphTotalData = value
			}
		})
		this.trendGraph(graphData, dates)
		this.trendTable(graphTotalData, dates)
	}

	trendGraph= (graphData, graphLabels) => {
		let barChartData = [];
		let summaryGraphLabelsData = [];
		let title ='';
		let length = graphLabels.length;
		let labelInterval = 1;
		let showLabel = 1
		for (let x = 0;x<length;x++) {
			if(graphLabels[x] !== undefined) {
				if(length >= 60) {
					if(labelInterval !== 1) {
						showLabel = 0;
						labelInterval--;
					} else {
						showLabel = 1;
						labelInterval = 10;
					}
				} else if(length >= 14) {
					if(labelInterval !== 1) {
						showLabel = 0;
						labelInterval--;
					} else {
						showLabel = 1;
						labelInterval = 5							
					}
				} 
				if(showLabel === 1) {
					summaryGraphLabelsData.push(momentConvertionUtcToLocalTime(graphLabels[x], 'DD MMM'))
				} else {
					summaryGraphLabelsData.push('')		
				}					
			} else {
				summaryGraphLabelsData.push('')
			}			
		}
		if(graphData && Object.entries(graphData).length) {
			for (const [key, value] of Object.entries(graphData)) {
				let summaryDataSet = {};

				let data = []
				for (let x = 0;x<graphLabels.length;x++) {
					let dataItem={}
					dataItem.x = momentConvertionUtcToLocalTime(graphLabels[x], 'DD MMM')
					dataItem.y = value[x]
					data.push(dataItem)
				}
				summaryDataSet['data'] = data
				summaryDataSet['name'] = key
				barChartData.push(summaryDataSet);
			}
		} else {
			let summaryDataSet = {};
			let length = graphLabels.length;
			let data = []
			for (let x = 0;x<length;x++) {
				let dataItem={}
				dataItem.x = momentConvertionUtcToLocalTime(graphLabels[x], 'DD MMM')
				dataItem.y = 0
				data.push(dataItem)
			}

			summaryDataSet['data'] = data
			summaryDataSet['name'] = 'No Record';
			barChartData.push(summaryDataSet);
		}

		const barChartOptions = {
			tooltip: {
				enabled: true,
				custom: function({series, seriesIndex, dataPointIndex, w}) {
				  let val  = series[seriesIndex][dataPointIndex];
				  let date = w.globals.initialSeries[seriesIndex].data[dataPointIndex].x
				  let labelName = w.globals.initialSeries[seriesIndex].name
				  //let val = str.replace('-',series[seriesIndex][dataPointIndex]);
				  return '<div class="arrow_box">' + date + 
					' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + ' '+ val + '</span> </div>'
				},
				style: {
				  fontSize: '9px',
				},
			},				
			chart: {
				toolbar: {
					show: false,
				},
				type: 'bar',
				height: 440,
				stacked: true,
			},			
			// colors: ['#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'],
			colors: ['#24a597', '#10a49a', '#00a29e', '#00a0a1', '#009fa4', '#009da7', '#009baa', '#0099ad', '#0098b0', '#0096b3', '#0093b5', '#0091b7', '#008fb9', '#008dba', '#008abc', '#0088bd', '#0085bd', '#0082bd', '#0080bd', '#1d7dbd', '#2d7abc', '#3977bb', '#4373b9', '#4d70b7', '#556db4', '#5d69b1', '#6466ae', '#6b62aa', '#715fa6', '#775ba2'],
			// colors: ['#039BE5', '#775BA2', '#24A597', '#B96422', '#B96422', '#FFD200'],
			plotOptions: {
				bar: {
					horizontal: false,
					barHeight: '40%',
					startingShape: 'flat',
					endingShape: 'flat',
					columnWidth: '60%',
					distributed: false,
					rangeBarOverlap: false,
					colors: {
						ranges: [{
							from: 1,
							to: 0.9,
							color: '#FF4560'
						}],
						backgroundBarColors: ['#EDEDED'],
						backgroundBarOpacity: 1,
						backgroundBarRadius: 0,
					}        
				},          
			},
			dataLabels: {
			  enabled: false
			},
			stroke: {
			  width: 0,
			  colors: ["#fff"]
			},
			
			grid: {  				        
			  xaxis: {
				lines: {
				  show: false
				},
			  },
			  yaxis: {
				lines: {
				  show: false
				}
			  },
			  row: {
				colors: undefined,
				opacity: 0.5
			  },  
			  column: {
				  colors: undefined,
				  opacity: 0.5
			  }, 
			},
			title: {
			  text: title
			},
			yaxis: {
				labels: {
					show: true,
					formatter: (value) => { return parseInt(value) },
					style: {
						colors: '#969AA7'
					}
				}
			},
			xaxis: {
				categories: summaryGraphLabelsData,
				labels: {
					show: true,
					style: {
						fontSize: '9px',
						colors: '#969AA7'	
					}
				}
			},
			legend: {
				show:true,
				position: 'bottom',
				fontSize: '11px',
				labels: {
					colors: '#969AA7'
				}
			},
		}			
		
		this.setState({trend_bar_data: barChartData, trend_bar_option: barChartOptions});
	}

	trendTable = (graphTotalData) => {
		let label = this.state.showGroupByLabel;
		let data = this.state.trendArray
		data = data.filter(obj => obj.group_name === label)
		if(data.length) {
			data = data[0]
			let dlpTrendTable = []
			
			Object.entries(data).forEach(([key,value]) => {
				if(key === 'data') {
					Object.entries(value).forEach(([vKey,vValue], index) => {
						if(vKey !== 'size_in_bytes') {
							Object.entries(vValue).forEach(([sub, count], i) => {
								var result = {}
								// result.type = sub
								let labelKey = vKey
								if(labelKey === 'size_in_gb') {
									labelKey = 'size (GB)'
								}
								result.label = capitalizeFirstLetter(sub)+' '+capitalizeFirstLetter(labelKey)
								result.total = data.total[vKey][sub]
								if(labelKey === 'size (GB)') {
									result.total = parseFloat(data.total[vKey][sub]).toFixed(2)
								}
								count.forEach((item, index) => {
									if(labelKey === 'size (GB)') {
										item = item === 0 ? '-' : parseFloat(item).toFixed(2)
									}
									result[data.dates[index]] = item
								})
								dlpTrendTable.push(result);
							})
						}
					})
				}
			})

			dlpTrendTable = _.orderBy(dlpTrendTable, ['label'], ['asc'])
			// dlpTrendTable = dlpTrendTable.forEach(function(v){ delete v.type; return v; });
			// dlpTrendTable = dlpTrendTable.filter(obj)
			if(dlpTrendTable.length) {
				const columns = Object.entries(dlpTrendTable[0]).map(([tKey, tValue]) =>{
					let header = ''
					let width = '100%'
					if(tKey === 'label') {
						header = capitalizeFirstLetter(this.state.showGroupByLabel)
						width = 200
					} else if(tKey === 'total') {
						header = 'Total'
						width = 75
					} else {
						header = momentConvertionUtcToLocalTime(tKey, 'MMM DD')
						width = 75
					}
					return {
						Header: header,
						accessor: tKey,
						width: width
					}
				})
				this.setState({ dlpTrendTable, columns })
			}
			
		} else {
			// const columns = []	
			// this.setState({ accessTrend: accessTrend.results, assessTrendTable, columns: columns },
			// 	() => this.trendGraph()
			// )
		}
		// Object.entries(graphTotalData).map(([key, value], index) => {
		// 	Object.entries(value).map(([vkey, vValue], i) => {
		// 		let dataRow = {}
		// 		let totalCount = vValue.reduce(function(a, b){
		// 			return a + b;
		// 		}, 0);
				

		// 		dataRow.header = capitalizeFirstLetter(key)+' '+capitalizeFirstLetter(vkey)
		// 		dataRow.value = totalCount
		// 		if(key === 'size_in_gb') {
		// 			dataRow.header = 'Size '+capitalizeFirstLetter(vkey)
		// 			dataRow.value = parseFloat(totalCount, 2)+' GB'
		// 		}
		// 		array.push(dataRow)
		// 	})
		// })
	}

	trendfilterhideShow = (index) => {
		let length = this.state.trendFilters.length
		for(let i = 0; i < length; i++) {
			if(i === index) {
				this.setState({ ['openFitler_'+i]: true  })
			} else {
				this.setState({ ['openFitler_'+i]: false  })
			}
		}
	}

	onChangeAgentDropdown  = (currentNode, selectedNodes) => {
		let filteredAgentArray = []	
		let selectedTreeAgent = []
		let selectedAgent = []
		let selectedAgentValue = []
		if(selectedNodes.length && currentNode.label !== 'All') {
			selectedNodes.forEach(item => {
				if(item._depth === 0) {
					let child = {}
					child.label = item.label
					child.value = item.value
					child.parent = 1
					selectedTreeAgent.push(child)
					this.state.agentArray.forEach(service => {
						if(service.label === item.label) {
							service.children.forEach(child => {
								selectedAgent.push(child.label)
								selectedAgentValue.push(child.value)
							})
						}
					})
				} else {
					let child = {}
					child.label = item.label
					child.value = item.value
					child.parent = 0
					selectedTreeAgent.push(child)
					selectedAgent.push(item.label)
					selectedAgentValue.push(item.value)
				}
			})
			
			filteredAgentArray = []
			this.state.agentArray.forEach(item => {
				let dataRow = {}
				dataRow.label = item.label
				dataRow.expanded = true
				let childArray = []
				item.children.forEach(child => {
					let childRow = {}
					childRow.label = child.label
					childRow.value = child.value
					childRow.expanded = true
					if(selectedTreeAgent.length) {					
						selectedTreeAgent.forEach(ser => {
							if(ser.parent === 1 && item.value === ser.value) {
								dataRow.checked = true
							}						
							if(ser.parent === 1 && item.value === ser.value) {
								childRow.checked = true
							} else if(ser.value === child.value){
								childRow.checked = true
							}			
						})
					}
					childArray.push(childRow)
					dataRow.children = childArray
				})		
				
				if(item.label === 'All' && currentNode.label === 'All') {
					dataRow.checked = true
				}
	
				filteredAgentArray.push(dataRow)
			})
		} else if(currentNode.label === 'All') {
			selectedTreeAgent= []
			selectedAgent = []
			filteredAgentArray = []
			this.state.agentArray.forEach(item => {
				let dataRow = {}
				dataRow.label = item.label
				dataRow.expanded = true
				if(currentNode.checked) {
					dataRow.checked = true
				} else if(!currentNode.checked) {
					dataRow.checked = false
				}
				let childArray = []
				item.children.forEach(child => {
					let childRow = {}
					childRow.label = child.label
					childRow.value = child.label
					childRow.expanded = true
					childRow.parent = 1
					if(currentNode.checked) {
						childRow.checked = true
						selectedAgent.push(childRow.value)
						selectedAgentValue.push(childRow.value)
					} else if(!currentNode.checked) {
						childRow.checked = false
					}
					childArray.push(childRow)
					selectedTreeAgent.push(childRow)
					dataRow.children = childArray					
				})
				filteredAgentArray.push(dataRow)
			})
		}	

		let selectedAgentContent = selectedAgent.length === 1 ? selectedAgent : selectedAgent.length > 1 ? selectedAgent.length +' Selected' : 'Agent'

		this.setState({ filteredAgentArray: filteredAgentArray.length ? filteredAgentArray : this.state.agentArray, selectedAgent, selectedAgentContent, Agent: selectedAgentValue },
			() => {
				if(selectedNodes.length || (!selectedNodes.length && currentNode.label !== 'All')) {}
			}
		)
	}
	
	onChangeActionDropdown  = (currentNode, selectedNodes) => {
		let filteredActionArray = []	
		let selectedDeviceAgent = []
		let selectedAction = []
		let selectedActionValue = []
		if(selectedNodes.length && currentNode.label !== 'All') {
			selectedNodes.forEach(item => {
				if(item._depth === 0) {
					let child = {}
					child.label = item.label
					child.value = item.value
					child.parent = 1
					selectedDeviceAgent.push(child)
					this.state.actionArray.forEach(service => {
						if(service.label === item.label) {
							service.children.forEach(child => {
								selectedAction.push(child.label)
								selectedActionValue.push(child.value)
							})
						}
					})
				} else {
					let child = {}
					child.label = item.label
					child.value = item.value
					child.parent = 0
					selectedDeviceAgent.push(child)
					selectedAction.push(item.label)
					selectedActionValue.push(item.value)
				}
			})

			filteredActionArray = []
			this.state.actionArray.forEach(item => {
				let dataRow = {}
				dataRow.label = item.label
				dataRow.expanded = true
				let childArray = []
				item.children.forEach(child => {
					let childRow = {}
					childRow.label = child.label
					childRow.value = child.value
					childRow.expanded = true
					if(selectedDeviceAgent.length) {					
						selectedDeviceAgent.forEach(ser => {
							if(ser.parent === 1 && item.label === ser.label) {
								dataRow.checked = true
							}						
							if(ser.parent === 1 && item.label === ser.label) {
								childRow.checked = true
							} else if(ser.label === child.label){
								childRow.checked = true
							}			
						})
					}
					childArray.push(childRow)
					dataRow.children = childArray
				})		
				
				if(item.label === 'All' && currentNode.label === 'All') {
					dataRow.checked = true
				}
	
				filteredActionArray.push(dataRow)
			})
		} else if(currentNode.label === 'All') {
			selectedDeviceAgent= []
			selectedAction = []
			filteredActionArray = []
			this.state.actionArray.forEach(item => {
				let dataRow = {}
				dataRow.label = item.label
				dataRow.expanded = true
				if(currentNode.checked) {
					dataRow.checked = true
				} else if(!currentNode.checked) {
					dataRow.checked = false
				}
				let childArray = []
				item.children.forEach(child => {
					let childRow = {}
					childRow.label = child.label
					childRow.value = child.value
					childRow.expanded = true
					childRow.parent = 1
					if(currentNode.checked) {
						childRow.checked = true
						selectedAction.push(childRow.label)
						selectedActionValue.push(childRow.value)
					} else if(!currentNode.checked) {
						childRow.checked = false
					}
					childArray.push(childRow)
					selectedDeviceAgent.push(childRow)
					dataRow.children = childArray					
				})
				filteredActionArray.push(dataRow)
			})
		}	

		let selectedActionContent = selectedAction.length === 1 ? selectedAction : selectedAction.length > 1 ? selectedAction.length +' Selected' : 'Action'

		this.setState({ filteredActionArray: filteredActionArray.length ? filteredActionArray : this.state.actionArray, selectedAction, selectedActionContent, Action: selectedActionValue },
			() => {
				if(selectedNodes.length || (!selectedNodes.length && currentNode.label !== 'All')) {}
			}
		)
	}

	setAccessedByFunction = (key, accessedBy) => {
		let checked = true
		if(this.state['checkbox_'+accessedBy]) {
			checked = false
		}
		this.setState({ ['checkbox_'+accessedBy] : checked })
		let accessedByState = this.state.selectedAccessedBy
		if(checked) {
			accessedByState.push(accessedBy)
		} else {
			const index = accessedByState.indexOf(accessedBy);
			if (index > -1) {
				accessedByState.splice(index, 1);
			}
		}

		this.setState({ selectedAccessedBy: accessedByState, [key]: accessedByState })

	}

	handleMultiSelectChange = (childKey, arrayValue) => {
		this.setState({ changesInFilter: true})
		let value = arrayValue.map(item => item.value)
		let label = arrayValue.map(item => item)		
		if(childKey === 'selectedAccount') {
			let prevState = this.state.selectedAccount
			let selectedAccountName = arrayValue
			if(prevState.includes('All') && value.includes("All")) {
				const index = value.indexOf('All');
				if (index > -1) {
					value.splice(index, 1);
				}
				let array = []
				value.forEach(item => {
					this.state.accounts.forEach(acc => {
						if(acc.account_id === item) {
							label = {}
							label.value = acc.account_id
							label.label = acc.account_name
							array.push(label)
						}
					})
				})
				selectedAccountName = array
			} else if(prevState.includes('All') && !value.includes("All")) {
				value = []
				selectedAccountName = []
			}
			if(value.length && value.includes("All")) {
				let selectedAccount = []
				selectedAccountName = []
				this.state.accounts.forEach(acc => {
					label = {}
					label.value = acc.account_id
					label.label = acc.account_name

					selectedAccount.push(label.value)
					selectedAccountName.push(label)
				})
				this.setState({ selectedAccount, selectedAccountName, changesInFilter: true })
			} else {
				this.setState({ selectedAccount: value, selectedAccountName, changesInFilter: true })
			}
		} else if(childKey === 'selectedRegion') {
			let prevState = this.state.selectedRegion
			let selectedRegionName = arrayValue
			if(prevState.includes('All') && value.includes("All")) {
				const index = value.indexOf('All');
				if (index > -1) {
					value.splice(index, 1);
				}
				let array = []
				value.forEach(item => {
					this.state.regions.forEach(reg => {
						if(reg.region === item) {
							let label = {}
							label.value = reg.region
							label.label = reg.name
							array.push(label)
						}
					})
				})
				selectedRegionName = array
			} else if(prevState.includes('All') && !value.includes("All")) {
				value = []
				selectedRegionName = []
			}

			if(value.length && value.includes("All")) {
				let selectedRegion = []
				selectedRegionName = []
				this.state.regions.forEach(reg => {
					let label = {}
					label.value = reg.region
					label.label = reg.name

					selectedRegion.push(label.value)
					selectedRegionName.push(label)
				})
				this.setState({ selectedRegion, selectedRegionName, changesInFilter: true  })
			} else {
				this.setState({ selectedRegion: value, selectedRegionName, changesInFilter: true  })
			}
		}
	}	

	getMultiSelectedCount = (type, array) => {
		if(array) {
			return array.length && array.includes('All') ? 'All' :  array.length ? array.length +' Selected' : 'All'
		} else {
			return 'All'
		}
	}

	handleMultiSelectFilterChange = (fitler, childKey, arrayValue) => {
		let value = arrayValue.map(item => item.value)
		// let label = arrayValue.map(item => item.label)
		if(fitler === 'Geography') {
			this.setState({ geo_location: value })
		}
		this.setState({ [childKey]: value, ['label_'+childKey]: arrayValue })
	}
		
	handleClickOutside(event) {
		
		if (this.accountRef && !this.accountRef.current.contains(event.target)) {
			this.setState({ isAccountOpen: false })
		} else {
			this.setState({ isAccountOpen: true })
		}
		
		// if (this.tagRef && !this.tagRef.current.contains(event.target)) {
		// 	this.setState({ isTagOpen: false })
		// } else {
		// 	this.setState({ isTagOpen: true })
		// }

		if (this.regionRef && !this.regionRef.current.contains(event.target)) {
			this.setState({ isRegionOpen: false })
		} else {
			this.setState({ isRegionOpen: true })
		}
        
		if (this.trendFilterRef && !this.trendFilterRef.current.contains(event.target)) {
			this.trendfilterhideShow(-1)
		}
	}

    render() {
		return (
			<div className='container-fluid overflow-auto flex-grow-1' onClick={ (event) => { this.handleClickOutside(event) } }>  
				<div className={`loaderOverlay ${this.state.showLoading ? '' : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
				<div className='row h-100'>
					<div className='col-sm-12 p-0'>
						<div className='title-section p-2'>
							<div className="row">
								<div className="col-5 mt-0">
									<h6 className="text-white m-0">DLP Trend</h6>
									<p className="text-white m-0">Count board view of DLP trend on your Cloud</p>
									<div className="row mt-3">
                              			<div className={`col-md-12 d-md-flex bd-highlight ${this.state.showLoading ? 'disabled' : ''}`}>
											<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} ref={this.providerRef} style={{maxWidth: '150px' }}>
												<label className="mb-1">Provider</label>
												<Select
													placeholder={'All'}
													menuIsOpen={this.state.providerIsOpen}
													isSearchable={false}
													//onFocus={this.openProivderMenu}
													className='f13 p-0 colorBlack reactSelectFilterDrop'
													value={({
														value: this.state.selectedProvider,
														label: this.state.selectedProvider
													})}
													options={this.state.providers.map(item => ({
														value: item.provider_name,
														label: item.provider_name,	
													}))}
													onChange={event =>  
														this.setState(
															{
																selectedProvider: event.value,
																changesInFilter: true
															}
														)
													}
												/>
											</div>
											<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1 ${!this.state.providers.length}`} ref={this.accountRef} style={{maxWidth: '150px' }}>
												<label className="mb-1">Account</label>
												<div className='dlpHeaderMultiSelection removeDropdownSearchBar'>
													<ReactMultiSelectCheckboxes						
														placeholderButtonLabel="All"
														menuIsOpen ={this.state.isAccountOpen}
														getDropdownButtonLabel={() => this.getMultiSelectedCount('account', this.state.selectedAccount)}
														value={this.state.selectedAccountName.map(user => ({
															value: user.value,
															label: user.label
														}))}
														onChange={arr => {
														this.handleMultiSelectChange('selectedAccount', arr ? arr : []) }}
														options={this.state.accounts.map(account => ({
															value: account.account_id,
															label: account.account_name,
														}))}
													/>
												</div>
											</div>
											<div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 ${!this.state.providers.length}`} ref={this.regionRef} style={{maxWidth: '150px'}}>
												<label className="mb-1">Region</label>
												<div className={`dlpHeaderMultiSelection ${this.state.regions && this.state.regions.length > 10 ? '' : 'removeDropdownSearchBar' } `}>
													<ReactMultiSelectCheckboxes						
														placeholderButtonLabel="Select"
														menuIsOpen ={this.state.isRegionOpen}
														getDropdownButtonLabel={() => this.getMultiSelectedCount('region', this.state.selectedRegion)}
														value={this.state.selectedRegionName.map(user => ({
															value: user.value,
															label: user.label
														}))}
														onChange={arr => {
														this.handleMultiSelectChange('selectedRegion', arr ? arr : []) }}
														options={this.state.regions && this.state.regions.map(region => ({
															value: region.region,
															label: region.name,
														}))}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>						
								<div className="col-7 text-right">
									<div className={`d-flex justify-content-end align-self-center`}>
										<div className={`d-flex justify-content-between align-self-end`}>
											{/* <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === 365 ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedFilterPeriod: 365 }, () => this.sliderPeriod())}>1 Year</span>
											<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === 180 ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedFilterPeriod: 180 }, () => this.sliderPeriod())}>6 Month</span> */}
										
											<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === 90 ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedFilterPeriod: 90 }, () => this.sliderPeriod())}>3 Months</span>
											<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === 30 ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedFilterPeriod: 30 }, () => this.sliderPeriod())}>1 Month</span>
											<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === 7 ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedFilterPeriod: 7 }, () => this.sliderPeriod())}>1 Week</span>
										</div>
										<div className={`bg-filterBlue text-white align-self-end p-1 f12 ml-2`}>
											{getPastDaysNew(this.state.sliderStartDate, this.state.sliderEndDate)}
										</div>
										<div className="align-self-center ml-3">
											<Datetime 
												value={momentDateGivenFormat(this.state.startSlice, 'DD MMM YYYY hh:mm A')}
												onChange={this.handleStartTimeChange}
												dateFormat={'DD MMM YYYY'}
												timeFormat={'hh:mm A'}
												inputProps={{ readOnly: true, disabled: this.state.showLoading }}
												className="w-100 mr-1 dateSliderInputReszie"
												isValidDate={previousDay()}
												closeOnSelect={true}
											/>
											<Datetime
												value={momentDateGivenFormat(this.state.endSlice, 'DD MMM YYYY hh:mm A')}
												onChange={this.handleEndTimeChange}
												dateFormat={'DD MMM YYYY'}
												timeFormat={'hh:mm A'}
												inputProps={{ readOnly: true, disabled: this.state.showLoading }}
												className="w-100 dateSliderInputReszie"
												isValidDate={previousDay()}
												closeOnSelect={true}
											/>
										</div>
										<div className={`ml-2 align-self-center`}>
											<div className="btn-group" role="group" aria-label="trend-dash">
												<button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-right`} onClick={() => this.onSearch() }>Search</button>
												<button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-left`} onClick={() => this.onReset()}><i className='far fa-redo'></i></button>
											</div>
										</div>	
									</div>
								
									{this.state.datePickerSliderOptions && this.state.datePickerSliderSeries ?
										<div className={`w-100 mb-n3 ${this.state.showLoading ? 'disabled' : ''}`} >
											<Chart options={this.state.datePickerSliderOptions} series={this.state.datePickerSliderSeries} type="bar" height={80} />
										</div>
									: null}	
									<div className="d-flex">
										<div id="leftSliderSpan" className="f12">{momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM')}</div>
										<div id="rightSliderSpan" className="f12">{momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM')}</div>
									</div>
								</div>
							</div>
						</div>
                        <div className={`bg-background`}>
							<div className={`box p-3 rounded-0 shadow-none bg-transparent`}>
								<div className="col-sm-12 p-0">
									<div className={`rounded p-3 bg-muted`}>
										<div className="col-sm-12 pl-0 text-white">
											<div className="mb-1">
												<p className='m-0'>Trends</p>
												<p className="small text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
											</div>
											<div className='row mt-2'>
												<div className={`col-md-7 col-xl-10 p1-0 ${this.state.showLoading ? 'disabled' : ''}`}>
													<div className='d-flex border-dark2 flex-column mt-1 minHeight300'>
														<div className='bg-dark d-flex'>
															<div className={`form-group bd-highlight mb-sm-1 styled-feild p-0 m-2 bg-gray5`}>
																<select className={`form-control cursorPointer`} 
																	value={this.state.selectedTrendOption}
																	onChange={event => {
																		this.setState(
																			{
																				selectedTrendOption: event.target.value
																			},
																			() => this.trendChange()
																		)
																	}}>
																	<option value='count'>Count</option>
																	<option value='hits'>Hits</option>
																	<option value='size_in_gb'>Size</option>
																</select>
															</div>
															<div className='f12 p-2 mt-1 ml-2 align-self-center'>
																<label className='mb-0 font-weight-bold text-white4 f11'>Show by:
																	<span className='badge badge-primary custom-badge ml-2 text-white p-1'>
																		{this.state.showGroupByLabel} <i onClick={ this.state.selectedGroupBy === this.state.defaultSelectedGroupBy ? '' : () => this.getSelectedGroupBy(this.state.defaultSelectedGroupBy) } className='ml-1 fal fa-times cursorPointer'></i>
																	</span>											
																	<span className='mx-2 text-blue cursorPointer groupBySection displayNone'>
																		<select
																			className='text-blue tagDropSection groupBySection'
																			style={{minWidth:"500px"}}
																			value={this.state.selectedGroupByTags}
																			onChange={event => {
																				this.setState(
																					{
																						selectedGroupByTags: event.target.value
																					},
																					() => this.getSelectedGroupBy('tag')
																				)
																			}}
																		>
																			<option key='0' value=''>Tag</option>
																			<option key='environment' value='Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard</option>
																			<option key='application' value='Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard</option>
																			<option key='cost_centre' value='Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard</option>				
																		</select>
																	</span>
																</label>
															</div>
															<div className='d-flex p-2 mt-2 overflow-auto align-self-center'>
																{this.state.trendFormedObject && Object.entries(this.state.trendFormedObject).length ?
																	Object.entries(this.state.trendFormedObject).map(([key, value]) => {
																		return (
																		<span className={`mx-2 text-gray5 cursorPointer groupBySection ${this.state.selectedGroupBy === key ? 'displayNone' : ''}`} onClick={ () => this.getSelectedGroupBy(key)}>{removeUnderScore(key)}</span>	
																		)
																	})
																: null
																}
															</div>
														</div>
														<div className='bg-gray5 text-white4 m-1'>
															<p className="p-2 m-0 small text-center f10">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
														</div>
														
														{this.state.showLoading ?
															<div className='d-flex justify-content-center mt-5'>
																{/* <Spinner className='text-center' color='dark' size='lg' /> */}
															</div>
														: this.state.trend_bar_data && this.state.trend_bar_data.length && this.state.trend_bar_option ?
															<div className="transparentTooltip mt-n2">
																<Chart type="bar" series={this.state.trend_bar_data} width="100%" height={400} options={this.state.trend_bar_option}/>
															</div>
														: null
														}
													</div>
												</div>
												<div className='col-md-5 col-xl-2 p-0' ref={this.trendFilterRef} >
													<div className=" bg-dark p-2 rounded counts_threads filterDarkTheme">
														<div className="d-flex justify-content-between">
															<div className="">
																<p className="font-weight-bold mb-1 text-white4">Filter</p>
															</div>
															<div className={`d-flex justify-content-end ${this.state.showLoading ? 'disabled' : ''}`}>
																<small className="text-blue font-weight-bold mb-1 f13 cursorPointer" onClick={ () => this.setState({ showLoading: true }, () => this.applyFilter())}>APPLY</small>
																<small>&nbsp;|&nbsp;</small>
																<small className="text-blue font-weight-bold mb-1 f13 cursorPointer" onClick={ () => this.clearFilter()}>CLEAR ALL</small>
															</div>
														</div>
															{/* <label className='f12 mb-0 font-weight-bold text-muted'>Service</label> */}
															{/* <label className="f10 mb-0 font-weight-bold cursorPointer" style={{color: "#898989"}} id="serviceSelected" onClick={ () => this.setState({showServiceDropdown: this.state.showServiceDropdown ? false : true}, () => this.handleClick())}>Select... <span className="pl-2 mr-2 far fa-chevron-down selectDrop"></span></label> */}
														{this.state.trendFilterArray && this.state.trendFilterArray.length ?
															this.state.trendFilterArray.map((item, i) => {
																return(
																item.filter === 'Format' || item.filter === 'Device' || item.filter === 'Storage Option' || item.filter === 'Geography' || item.filter === 'Properties' ?
																	<div className={`border-top border-bottom pt-1`} onClick={() => this.trendfilterhideShow(i)}>
																		<small className={`${this.state[item.key] && this.state[item.key].length ? '' : 'displayNone'} filterLightLabel`}>{item.filter}</small>
																		<div className={`col-sm-12 p-0 d-flex justify-content-between position-relative mb-1 ${item.values && item.values.length > 5 ? '' : 'removeDropdownSearchBar'}`}>
																			<div className='custom-dropdown-dark-theme'>
																				<ReactMultiSelectCheckboxes
																					placeholderButtonLabel={item.filter}
																					menuIsOpen ={this.state['openFitler_'+i] ? true : false}
																					value={this.state['label_'+item.key] && this.state['label_'+item.key].map(item => ({
																						value: item.value,
																						label: item.label
																					}))}
																					onChange={arr => {this.handleMultiSelectFilterChange(item.filter, item.key, arr ? arr : []) }}
																					options={item.values.map(val => ({
																						value: val.value,
																						label: val.label
																					}))}
																				/> 	
																			</div>
																			<div>
																				<select className='cursorPointer position-absolute right-0 small border-0 text-muted includeDropDiv'
																				value={this.state['include_'+item.key]}
																					onChange={event => {
																						this.setState({['include_'+item.key]: event.target.value})
																					}}>
																					<option value='include'>Include</option>
																					<option value='exclude'>Exclude</option>
																				</select>
																			</div>
																		</div>
																		<div className={`container-fluid mb-2 mt-2 ${this.state[item.key] && this.state[item.key].length ? '' : 'd-none'} `}>
																			<div className='row'>
																				<div className="pl-0">
																					<span className="dropCancelBtn" onClick={ () => this.fitlerBtnClick(item.key, 'cancel') }>
																						Cancel
																					</span>	
																				</div>
																			</div>
																		</div>
																	</div>
																: item.filter === 'Agent' ?
																	<div className={`border-top border-bottom pt-1 ${this.state[item.key] && this.state[item.key].length ? 'minHeight50px' : 'minHeight30px'} `} onClick={() => this.trendfilterhideShow(i)}>
																		<small className={`${this.state[item.key] && this.state[item.key].length ? '' : 'displayNone'} filterLightLabel`}>{item.filter}</small>
																		<div className="col-sm-12 p-0 d-flex justify-content-between position-relative mb-1">
																			<div className={`serviceTreeDropdown position-absolute ${this.state[item.key] && this.state[item.key].length ? 'mt-n1' : ''}`}>
																				<DropdownTreeSelect 
																					texts={{ placeholder: this.state.selectedAgentContent }}
																					data={this.state.filteredAgentArray}
																					onChange={this.onChangeAgentDropdown}
																					className="bootstrap-demo text-muted"
																					keepTreeOnSearch={true}
																					keepChildrenOnSearch={true}
																				/>
																			</div>
																			<div>
																				<select className={`cursorPointer position-absolute right-0 small border-0 text-muted includeDropDiv ${this.state[item.key] && this.state[item.key].length ? '' : 'mt-1'}`}
																				value={this.state['include_'+item.key]}
																				onChange={event => {
																					this.setState({['include_'+item.key]: event.target.value})
																				}}>
																					<option value='include'>Include</option>
																					<option value='exclude'>Exclude</option>
																				</select>
																			</div>
																		</div>
																		<div className={`container-fluid mb-2 mt2rem ${this.state[item.key] && this.state[item.key].length ? '' : 'd-none'} `}>
																			<div className='row'>
																				<div className="pl-0">
																					<span className="dropCancelBtn" onClick={ () => this.fitlerBtnClick('Agent', 'cancel') }>
																						Cancel
																					</span>	
																				</div>
																			</div>
																		</div>
																	</div>
																: item.filter === 'Action' ?
																	<div className={`border-top border-bottom pt-1 ${this.state[item.key] && this.state[item.key].length ? 'minHeight50px' : 'minHeight30px'}`} onClick={() => this.trendfilterhideShow(i)}>
																		<small className={`${this.state[item.key] && this.state[item.key].length ? '' : 'displayNone'} filterLightLabel`}>{item.filter}</small>
																		{/* <small className={`	filterLightLabel`}>{item.filter}</small> */}
																		<div className="col-sm-12 p-0 d-flex justify-content-between position-relative mb-1">
																			<div className={`serviceTreeDropdown position-absolute ${this.state[item.key] && this.state[item.key].length ? 'mt-n1' : ''}`}>
																				<DropdownTreeSelect 
																					texts={{ placeholder: this.state.selectedActionContent }}
																					data={this.state.filteredActionArray}
																					onChange={this.onChangeActionDropdown}
																					className="bootstrap-demo text-muted"
																					keepTreeOnSearch={true}
																					keepChildrenOnSearch={true}
																				/>
																			</div>
																			<div>
																				<select className={`cursorPointer position-absolute right-0 small border-0 text-muted includeDropDiv ${this.state[item.key] && this.state[item.key].length ? '' : ' mt-1'}`}
																				value={this.state['include_'+item.key]}
																				onChange={event => {
																					this.setState({['include_'+item.key]: event.target.value})
																				}}>
																					<option value='include'>Include</option>
																					<option value='exclude'>Exclude</option>
																				</select>
																			</div>
																		</div>
																		<div className={`container-fluid mb-2 mt2rem ${this.state[item.key] && this.state[item.key].length ? '' : 'd-none'} `}>
																			<div className='row'>
																				<div className="pl-0">
																					<span className="dropCancelBtn" onClick={ () => this.fitlerBtnClick('Action', 'cancel') }>
																						Cancel
																					</span>	
																				</div>
																			</div>
																		</div>
																	</div>
																: item.filter === 'Accessed By' ?
																	<div className={`border-top border-bottom pt-1 minHeight30px`} onClick={() => this.trendfilterhideShow(i)}>
																		<div className="d-flex justify-content-between">
																			<div id="dLabel" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
																				{this.state[item.key] && this.state[item.key].length ? 
																					<React.Fragment>
																					<label className={`text-white4 f14 mt-1 mb-0`}>{item.filter}</label>
																					<div className="filterLightLabel"> {this.state[item.key].length+' selected'}</div>
																					</React.Fragment>
																				:
																					<label className={`text-white4 f14 mt-1 `}>{item.filter}</label>
																				}
																			</div>
																			<div>
																				<select className={`cursorPointer position-absolute right-10 small border-0 text-muted includeDropDiv ${this.state[item.key] && this.state[item.key].length ? 'mt-3' : ' mt-1'}`}
																				value={this.state['include_'+item.filter]}
																				onChange={event => {
																					this.setState({['include_'+item.key]: event.target.value})
																				}}>
																					<option value='include'>Include</option>
																					<option value='exclude'>Exclude</option>
																				</select>
																			</div>
																		</div>
																		<div className={`col-sm-12 p-0 ${this.state['openFitler_'+i] ? '' : 'displayNone'}`}>
																			<div className="dropdownMenu">
																				{item.values.map(val => {
																					if(val.key === 'public') {
																						return(
																						<span className="dropdownItem">
																							<div className="customControl custom-checkbox">
																								<input type="checkbox" className="custom-control-input" id={val.key} 
																									checked={this.state['checkbox_'+val.key] ? true : false}
																									onChange={ () => this.setAccessedByFunction(item.key, val.key)}
																								/>
																								<label className="custom-control-label" for={val.key}>{val.label}</label>
																							</div>
																						</span>
																						)
																					} else {
																						return(
																						<React.Fragment>
																						<span className="dropdownItem">
																							<div className="customControl custom-checkbox">
																								<input type="checkbox" className="custom-control-input" id={val.key} 
																									checked={this.state['checkbox_'+val.key] ? true : false}
																									onChange={ () => this.setAccessedByFunction(item.key, val.key)}
																									/* onChange={ event => {
																										this.setState({ ['checkbox_'+val.key] : this.state['checkbox_'+val.key] ? false : true })
																									}} */
																								/>
																								<label className="custom-control-label" for={val.key}>{val.label}</label>
																							</div>
																						</span>
																						<input type="text" className={`form-control w-90 mx-4 f11 ${this.state['checkbox_'+val.key] ? 'displayNone' : 'displayNone'}`} placeholder="Search..." />
																						<div className={`col-sm-12 p-0 d-flex justify-content-between position-relative mb-1 ${val.child && val.child.length > 5 ? '' : ''} ${this.state['checkbox_'+val.key] ? '' : 'displayNone'}`}>
																							<div className='ml-4 mt-n3 custom-dropdown insideDropdown'>
																								<ReactMultiSelectCheckboxes
																									menuIsOpen ={true}
																									value={this.state[val.key] && this.state['label_'+val.key].map(item => ({
																										value: item.value,
																										label: item.label
																									}))}
																									onChange={arr => {this.handleMultiSelectFilterChange(item.filter, val.key, arr ? arr : []) }}
																									options={val.child && val.child.map(val => ({
																										value: val.value,
																										label: val.label
																									}))}
																								/>
																							</div>
																						</div>
																						</React.Fragment>
																						)
																					}
																				})}
																			</div>
																		</div>
																		<div className={`container-fluid mb-2 mt-2 ${this.state[item.key] && this.state[item.key].length ? '' : 'd-none'} `}>
																			<div className='row'>
																				<div className="pl-0">
																					<span className="dropCancelBtn" onClick={ () => this.fitlerBtnClick('Accessed By', 'cancel') }>
																						Cancel
																					</span>	
																				</div>
																			</div>
																		</div>
																	</div>
																: null
																)
															})
															:
															null
														}
														<div className="d-flex w-100 mt-3 justify-content-end">
															<small className={`text-white badge badge-primary custom-badge font-weight-bold f12 ${this.state.showLoading ? 'disabled' : 'cursorPointer'}`} onClick={ () => this.setState({ showLoading: true }, () => this.applyFilter())}>APPLY FILTER</small>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									
									<div className={`mt-3 rounded p-3 bg-gray5 text-white`}>
										<div className="col-sm-12 pl-0">
											{/* <p className='font-weight-bold m-0'>Trends</p> */}		
											{this.state.dlpTrendTable && this.state.dlpTrendTable.length > 10 ?
												<div className="d-flex">
													<p className="mb-1 small mr-3">
														Showing 10 results out of {this.state.dlpTrendTable.length} in {this.state.selectedGroupByTags === '' ? capitalizeFirstLetter(this.state.selectedGroupBy) : capitalizeFirstLetter(this.state.selectedGroupByTags)} Trends for provider {this.state.selectedProvider}&nbsp;with&nbsp;{this.state.selectedAccount.length === 1 ? this.state.selectedAccountName && this.state.selectedAccountName.length && this.state.selectedAccountName[0].label +' account' : this.state.selectedAccount.length === this.props.account && this.props.accounts.length ? 'All account' : this.state.selectedAccount.length > 1 ? this.state.selectedAccount.length +' account' : 'All account'}&nbsp;in&nbsp;{this.state.selectedRegion && this.state.selectedRegion.length === 1 ? this.state.selectedRegionName && this.state.selectedRegionName.length && this.state.selectedRegionName[0].label +' region' : this.state.selectedRegion.length === this.state.regions && this.state.regions.length ? 'All region' : this.state.selectedRegion.length > 1 ? this.state.selectedRegion.length +' region' : 'All region'}
													</p>
													<small className={`text-info cursorPointer ${this.state.selectedTotalTrendFilter.length ? '' : 'displayNone'}`} onClick={(e) => this.setState({ showFilterBatch: this.state.showFilterBatch ? false : true })}>Show Selected Filters</small>
												</div>

											: 
												<div className="d-flex">
													<p className="mb-1 small mr-3">
														Showing {this.state.dlpTrendTable.length} results out of {this.state.dlpTrendTable.length} in {this.state.selectedGroupByTags === '' ? capitalizeFirstLetter(this.state.selectedGroupBy) : capitalizeFirstLetter(this.state.selectedGroupByTags)} Trends for provider {this.state.selectedProvider}&nbsp;with&nbsp;{this.state.selectedAccount.length === 1 ? this.state.selectedAccountName && this.state.selectedAccountName.length && this.state.selectedAccountName[0].label +' account' : this.state.selectedAccount.length === this.props.account && this.props.accounts.length ? 'All account' : this.state.selectedAccount.length > 1 ? this.state.selectedAccount.length +' account' : 'All account'}&nbsp;in&nbsp;{this.state.selectedRegion && this.state.selectedRegion.length === 1 ? this.state.selectedRegionName && this.state.selectedRegionName.length && this.state.selectedRegionName[0].label +' region' : this.state.selectedRegion.length === this.state.regions && this.state.regions.length ? 'All region' : this.state.selectedRegion.length > 1 ? this.state.selectedRegion.length +' region' : 'All region'}
													</p>
													<small className={`text-info cursorPointer ${this.state.selectedTotalTrendFilter.length ? '' : 'displayNone'}`} onClick={(e) => this.setState({ showFilterBatch: this.state.showFilterBatch ? false : true })}>Show Selected Filters</small>
												</div>
											
											}
											{this.state.showFilterBatch && this.state.selectedTotalTrendFilter && this.state.selectedTotalTrendFilter.map(item => {
												return(
													<span className="badge badge-outline-secondary ml-2 text-muted p-1 mb-2">
														<span className="font-weight-bold text-dark">{item.label}&nbsp;:&nbsp;</span>  <span>{item.value === 'Public' ? item.value : item.value.join(', ')}</span>
													</span>
												)
											})}

											{this.state.dlpTrendTable && this.state.dlpTrendTable.length ?
												<div className="scrollbarDesign">
													<TrendTable
														data={this.state.dlpTrendTable}
														columns = { this.state.columns }
														
														onClickRow={tableRow => {}}
														dashboard = {this.state.dlpTrendTable.length ? true : false}
														tooltipFor={[]}
													/>
												</div>
											: 
												<div className='d-flex justify-content-center m-4'>
													<p>There are no data on this criteria. Please try adjusting your filter.</p>
												</div>
											}
										</div>
									</div>
								</div>
							</div>
                        </div>
                    </div>    
                </div>
            </div>
        )
    }
}

TrendDashboard.propTypes = {
	getDlpFilterProperties: PropTypes.func,
	startDataEventsDailyTrend: PropTypes.func,
	getDataEventsDailyTrend: PropTypes.func
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		providers: state.filters.providers,
		accounts: state.filters.accounts,
		regions: state.filters.regions,
		startDailyTrendProps: state.dlp.startDailyTrend ? state.dlp.startDailyTrend : {},
		getDailyTrendProps: state.dlp.getDailyTrend ? state.dlp.getDailyTrend : {},
		selectedFilters: state.dlp && state.dlp.selectedFilters ? state.dlp.selectedFilters : {},
	}
}

export default AppWrapper(TrendDashboard, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
	getDlpFilterProperties,
	startDataEventsDailyTrend,
	getDataEventsDailyTrend,
})
            