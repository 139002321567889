/*************************************************
 * Tvastar
 * @exports
 * @file OPAServiceTemplate.js  //copy of query list from the observability Events QueryDetail page
 * @author Prakash // on 14/02/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import _ from 'lodash'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'

import { setNewEditRuleDetails } from '../../../../../actions/governance/newGovernanceAction'
import { DynamicApiToDevCall } from '../../../../../actions/commonAction'
import { onlyNumeric } from '../../../../../utils/utility'

class OPAServiceTemplate extends Component {
	constructor(props) {
		super(props)
		this.optionTypeRef = React.createRef()
		this.scrollRef = React.createRef()
		this.state = {
			policyArray: [0],

			hasErrorInRuleForm: false,
			hasError: this.props.hasError,
			timeTypeOptions: [
				{value: "h", label: "Hour(s)"},
				{value: "m", label: "Minute(s)"}
			],
			["time_type_0"]: "h",
			selectedServiceContent: "Select",
			services_not_to_use_0: [],
		}
	}

	componentDidMount = () => {
		this.masterData()
	}

	componentWillReceiveProps = (props) => {
		if(props.onClickFinish) {
			this.validateInput()
		}
	}

	masterData = () => {
		let masterData = this.props.masterData
		let actionDetails = []
		if(masterData.actions && masterData.actions.length) {
			actionDetails = masterData.actions
		}
		
		let variables = masterData.variables
		variables.forEach(item => {
			if(item.options && (item.component === "select" || item.component === "multiselect")) {
				this.setState({ [item.variable+"_options"]: item.options, [item.variable+"_0"]: item.default ? item.default : "" })
			} else {
				this.setState({ apiCallVariable: item.variable, [item.variable+"_0"]: item.default ? item.default : "" })
			}

			this.setState({ ["mode_"+item.variable]: item.mode ? item.mode : "" })
		})
		this.setState({ tags: masterData.tags, description: masterData.description ? masterData.description : "", actionDetails },
			() => {
				if(masterData.api_to_call) {
					this.apiToCall(masterData.api_to_call)
				} else if(this.props.viewMode) {
					this.setState({ savedData: [this.props.masterData] },
						() => this.savedData()	
					)
				}
			}
		)
	}

	apiToCall = (apiDetails) => {
		apiDetails.forEach(item => {
			let params = {}
	
			let valueRequest =  item.body && item.body.values
			valueRequest && valueRequest.forEach(req => {
				if(req.value) {
					params[req.key] = req.value
				} else if(req.value_object) {
					let valuObj = {}
					req.value_object.forEach(subReq => {
						valuObj[subReq.key] = subReq.value
					})
					params[req.key] = valuObj
				}
			})
	
			let url = item.name
			let API_END_POINT = item.api
			let apiMethod = item.method
			
			this.props.DynamicApiToDevCall(API_END_POINT, apiMethod, url, params, (promise, response) => {
				if(promise) {
					this.setState({ services: response.length ? response : [], loadingConditionPage: false },
						() => {
							if(this.props.viewMode) {
								this.setState({ savedData: [this.props.masterData] },
									() => this.savedData()	
								)
							} else {
								if(this.props.editNewRuleDetails && this.props.editNewRuleDetails.policies) {
									if(this.props.editNewRuleDetails.policies.filter(e => e.rule_category === this.props.masterData.rule_category).length) {						
										let savedData = this.props.editNewRuleDetails.policies.filter(e => e.rule_id === this.props.masterData.rule_id)
										if(savedData.length) {
											this.setState({ savedData },
												() => this.savedData()	
											)
										}
									}
								}

								this.structureServices()
							}
						}	
					)
				} else {
					this.setState({ engineDetails: [], loadingConditionPage: false })
				}
			})
		})
	}

	structureServices = () => {
		let services = this.state.services
		let serviceResourceArray = []
		let serviceArray = []
		services && services.length &&
		_.chain(services)
		.groupBy('resource_type')
		.map((value, key) => ({ label: key, sub: value }))
		.value()
		.forEach((service, i) => {
			if(i === 0) {
				let dataRow = {}							
				dataRow.category = 'All'
				dataRow.default_region = 'All'
				dataRow.provider_name = 'All'
				dataRow.resource_type = 'All'
				dataRow.service_id = 'All'
				dataRow.service_name = 'All'
				serviceArray.push(dataRow)

				let serRow = {}
				serRow.label = 'All'
				serRow.value = 'All'
				serRow.expanded = true
				let serArray = []
				serRow.children = serArray
				serviceResourceArray.push(serRow)
			}
			
			let serRow = {}
			serRow.label = service.label
			serRow.value = service.label
			serRow.expanded = true
			let serArray = []
			//serviceResourceArray.push(service)
			service.sub.forEach((item, i) => {
				let serItem = {}
				serItem.label = item.service_name
				serItem.value = item.service_name
				serItem.expanded = true
				serArray.push(serItem)
				serviceArray.push(item)
			})
			serRow.children = serArray
			serviceResourceArray.push(serRow)
		})

		this.setState({ services, serviceArray, serviceResourceArray, filteredServiceResourceArray: serviceResourceArray  })
	}

	savedData = () => {
		let savedData = this.state.savedData
		let policyArray = []
		savedData.forEach((row, index) => {
			policyArray.push(index)
			this.setState({ ["policy_id_"+index]: row.policy_id })
			if(row.action && Object.entries(row.action)) {
				Object.entries(row.action).forEach(([key, value]) => {
					if(key === "schedule") {
						let timeSplit = value.substring(0, value.length - 1)
						let timeTypeSplit = value.substring(value.length - 1, value.length)
						if(timeTypeSplit === "h" || timeTypeSplit === "m") {
							this.setState({ ["time_type_"+index]: timeTypeSplit, [key+"_"+index]: timeSplit })
						} else {
							this.setState({ [key+"_"+index]: value, ["time_type_"+index]: "" })
						}
					} else {
						if(key === "name") {
							key = "selected_action"
							this.setState({ ["action_processor_rule_id_"+index]: this.state.actionDetails.filter(e => e.action === value) && this.state.actionDetails.filter(e => e.action === value).length && this.state.actionDetails.filter(e => e.action === value)[0].processor_rule_id ? this.state.actionDetails.filter(e => e.action === value)[0].processor_rule_id : "" })
						}
						this.setState({ [key+"_"+index]: value })
					}
				})
			}
			row.variables.forEach(item => {
				let selectedService = item.value_list ? item.value_list : item.value
				let selectedServiceContent = (selectedService.length === 1 ? selectedService : selectedService.length > 2 ? selectedService.length +' Selected' : 'All')
				//filteredServiceResourceArray,
				this.setState({ [item.variable+"_"+index]: selectedService, selectedServiceContent, inputDetected: true, ["show_child_policy_0"]: true },
					() => this.onChangeServiceDropdown()
				)
			})

			if(row.enabled) {
				this.setState({ ["enabled_"+index]: true })
			}
			
		})
		this.setState({ policyArray })
	}

	showPolicy = (array, showField, index) => {		
		this.state[array].forEach(item => {
			if(index === item) {
				this.setState({ [showField+"_"+item]: !this.state[showField+"_"+item] })
			} else {
				// this.setState({ [showField+"_"+item]: false })
			}
		})
	}

	onChangeInput = (type) => {
		let data = []
		this.state.policyArray.forEach(item => {
			let obj = {}
			obj.rule_category = this.props.masterData.rule_category
			obj.rule_id = this.props.masterData.rule_id
			obj.tags = this.props.masterData.tags
			obj.mode =  this.props.masterData.mode
			obj.enabled = this.state["enabled_"+item] ? true : false
			// obj.disabled = this.state["disable_"+item] ? true : false
			obj.resource_type = this.props.resource_type
			obj.rule_category = this.props.rule_category
			obj.template = true
			obj.governance_type = this.props.governance_type

			if(this.state["policy_id_"+item]) {
				obj.policy_id = this.state["policy_id_"+item]
			}

			let variables = []
			let masterVaiables = this.props.masterData.variables ? this.props.masterData.variables : []
			masterVaiables.forEach(itmVar => {
				let dataRow = {}
				let inputValue = this.state[itmVar.variable+"_"+item]
				Object.entries(itmVar).forEach(([key, value]) => {
					if(key === "value_list" || key === "value") {
						dataRow[key] = inputValue
					} else {
						dataRow[key] = value
					}
				})
				variables.push(dataRow)
			})
			obj.variables = variables
			obj.description = this.state.description

			if(this.state.actionDetails && this.state.actionDetails.length) {
				let actionObj = {}
				actionObj.variables = []
				if(this.state["selected_action_"+item]) {
					actionObj.processor_rule_id = this.state["action_processor_rule_id_"+item]
					actionObj.name = this.state["selected_action_"+item]				
					if(this.state["apply_method_"+item]) {
						actionObj.apply_method = this.state["apply_method_"+item]					
						if(this.state["schedule_"+item] && this.state["schedule_"+item] !== "") {
							if(this.state["apply_method_"+item] === "run_after") {
								actionObj.schedule = this.state["schedule_"+item]+""+this.state["time_type_"+item]
							} else if(this.state["apply_method_"+item] === "run_at") {
								actionObj.schedule = this.state["schedule_"+item]
							}
						}
					}
					obj.action = actionObj
				}
			}

			data.push(obj)
		})

		// console.log("data", data)

		let allPolicies = this.props.editNewRuleDetails.policies ? this.props.editNewRuleDetails.policies : []

		let currentPolicies = []
		let policies = []

		if(allPolicies.length) {
			allPolicies.forEach(row => {
				if(row.rule_id === this.props.masterData.rule_id) {
					currentPolicies.push(row)
				} else {
					policies.push(row)
				}
			})
		}

		policies = [...data, ...policies]

		this.props.setNewEditRuleDetails('policies', policies)
	}

	validateInput = () => {
		let hasError = false
		this.state.policyArray.forEach(item => {
			if((!this.state["allow_"+item] || this.state["allow_"+item] === "") || (!this.state["engine_type_"+item] || this.state["engine_type_"+item] === "") || (!this.state["latest_"+item] || this.state["latest_"+item] === "")) {
				hasError = true
			} else if(this.state["latest_"+item] === "Specific" && !this.state["versions_to_use_"+item].length){
				hasError = true
			}
		})

		this.setState({ hasError })
		
		this.props.validateInput(hasError)
	}

	onKeyDownTime = (e, time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		if(e.keyCode === 8) { 
			str = str.slice(0, -1)
			this.validateTime(str, state)
		} else {
			this.validateTime(time, state)
		}
	}

	validateTime = (time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		
		let strLength = str.length
		let firstHour = str.substring(0, 2)
		let data = "" 
		if(strLength > 1 && parseInt(firstHour) > 23) {
			data += "00:"
		} else if(strLength > 1 && parseInt(firstHour) < 10) {
			if(firstHour.length == 2) {
				data = firstHour+":"
			} else {
				data += "0"+firstHour+":"
			}
		} else if(strLength > 1 && parseInt(firstHour) <= 23) {
			data += firstHour+":"
		}

		if(data === "") {
			data = time	
		}
		
		let firstMinute = str.substring(2, 4)
		
		if(firstMinute.length) {
			if(strLength > 3 && parseInt(firstMinute) > 59) {
				data += "00"+":"
			} else {
				data += firstMinute+":"
			}
		}
		
		let firstSecond = str.substring(4, 6)
		
		if(firstSecond.length) {
			if(strLength > 5 && parseInt(firstSecond) > 59) {
				data += "00"
			} else {
				data += firstSecond
			}
		}

		this.setState({ [state]: data },
			() => this.onChangeInput("action")	
		)
	}

	resetPolicy = (item) => {
		this.setState({ 
			["allow_"+item]: "", 
			["engine_type_"+item]: "",
			["latest_"+item]: "",
			["versions_to_use_"+item]: []
		},
			() => this.onChangeInput()
		)
	}
	
	deletePolicy = (item) => {
		let array = this.state.policyArray.filter(e => e !== item)

		if(!array.length) {
			array = [0]
		}
		let deletedPolicies = this.props.editNewRuleDetails && this.props.editNewRuleDetails.deleted_policies ? this.props.editNewRuleDetails.deleted_policies : []
		deletedPolicies.push(this.state["policy_id_"+item])
		this.props.setNewEditRuleDetails("deleted_policies", deletedPolicies)
		
		this.setState({ policyArray: array, ["show_child_policy_"+item]: false },
			() => {
				this.resetPolicy(item)
			}
		)
	}

	onChangeServiceDropdown  = (currentNode, selectedNodes) => {
		let filteredServiceResourceArray = []	
		let selectedTreeService = []
		let selectedService = []
		if(selectedNodes && selectedNodes.length && currentNode.label !== 'All') {
			selectedNodes.forEach(item => {
				if(item._depth === 0) {
					let child = {}
					child.label = item.label
					child.parent = 1
					selectedTreeService.push(child)
					this.state.serviceResourceArray.forEach(service => {
						if(service.label === item.label) {
							service.children.forEach(child => {
								selectedService.push(child.label)
							})
						}
					})
				} else {
					let child = {}
					child.label = item.label
					child.parent = 0
					selectedTreeService.push(child)
					selectedService.push(item.label)
				}
			})

			filteredServiceResourceArray = []
			this.state.serviceResourceArray.forEach(item => {
				let dataRow = {}
				dataRow.label = item.label
				dataRow.expanded = true
				let childArray = []
				item.children.forEach(child => {
					let childRow = {}
					childRow.label = child.label
					childRow.value = child.label
					childRow.expanded = true
					if(selectedTreeService.length) {					
						selectedTreeService.forEach(ser => {
							if(ser.parent === 1 && item.label === ser.label) {
								dataRow.checked = true
							}						
							if(ser.parent === 1 && item.label === ser.label) {
								childRow.checked = true
							} else if(ser.label === child.label){
								childRow.checked = true
							}			
						})
					}
					childArray.push(childRow)
					dataRow.children = childArray
				})		
				
				if(item.label === 'All' && currentNode.label === 'All') {
					dataRow.checked = true
				}
	
				filteredServiceResourceArray.push(dataRow)
			})
		} else if(currentNode && (currentNode.label === 'All' || !selectedNodes.length)) {
			selectedTreeService= []
			selectedService = []
			filteredServiceResourceArray = []
			this.state.serviceResourceArray.forEach(item => {
				let dataRow = {}
				dataRow.label = item.label
				dataRow.expanded = true
				if(currentNode.checked) {
					dataRow.checked = true
				} else if(!currentNode.checked) {
					dataRow.checked = false
				}
				let childArray = []
				item.children.forEach(child => {
					let childRow = {}
					childRow.label = child.label
					childRow.value = child.label
					childRow.expanded = true
					childRow.parent = 1
					if(currentNode.checked) {
						childRow.checked = true
						selectedService.push(childRow.value)
					} else if(!currentNode.checked) {
						childRow.checked = false
					}
					childArray.push(childRow)
					selectedTreeService.push(childRow)
					dataRow.children = childArray					
				})
				filteredServiceResourceArray.push(dataRow)
			})
		} else {
			this.state.serviceResourceArray.forEach(item => {
				let dataRow = {}
				dataRow.label = item.label
				dataRow.expanded = true
				if(currentNode) {
					if(currentNode.checked) {
						dataRow.checked = true
					} else if(!currentNode.checked) {
						dataRow.checked = false
					}
				} else if(item.label !== "All") {
					let checkChildArrayExist = this.checkAllChildArrayExit(this.state["services_not_to_use_0"], item.children)
					if(checkChildArrayExist) {
						dataRow.checked = true
					} else {
						dataRow.checked = false
					}
				}
				let childArray = []
				item.children.forEach(child => {
					let childRow = {}
					childRow.label = child.label
					childRow.value = child.label
					childRow.expanded = true
					childRow.parent = 1
					if((currentNode && currentNode.checked) || this.state["services_not_to_use_0"].includes(child.label)) {
						childRow.checked = true
						selectedService.push(childRow.value)
					} else if(currentNode && !currentNode.checked) {
						childRow.checked = false
					}
					childArray.push(childRow)
					selectedTreeService.push(childRow)
					dataRow.children = childArray					
				})
				filteredServiceResourceArray.push(dataRow)
			})
		}

		let selectedServiceContent = selectedService.length === 1 ? selectedService : selectedService.length > 2 ? selectedService.length +' Selected' : 'All'
		this.setState({ filteredServiceResourceArray, ["services_not_to_use_0"]: selectedService, selectedServiceContent },
			() => {
				this.onChangeInput()
				// if(selectedNodes.length || (!selectedNodes.length && currentNode.label !== 'All')) {
				// 	this.filterIdentifiers('services')
				// }
			}
		)
	}

	checkAllChildArrayExit = (parent, child) => {	
		let allChildExist = true
		child.forEach(item => {
			if(!parent.includes(item.value)) {
				allChildExist = false
			}
		})
		return allChildExist
	}

	render() {
		return (
			<React.Fragment>
			{this.state.policyArray && this.state.policyArray.map((item, index) => {
				return(
					<React.Fragment>
					<div className={`${!this.props.viewMode ? "py-3" : "py-1"} mb-2 border-bottom`}>
						{!this.props.viewMode ?
							<React.Fragment>
							<div className={`d-flex justify-content-between pl-2`}>
								{(!this.state["services_not_to_use_"+item] || this.state["services_not_to_use_"+item] === "") ?
									<p className={`mb-0 mx-2 align-self-center mb-1 text-primary-color cursorPointer`} onClick={() => this.showPolicy("policyArray", "show_child_policy", item)}>{this.props.masterData && this.props.masterData.description ? this.props.masterData.description : "Choose policy details"}</p>
								:
									<p className={`mb-0 mx-2 align-self-center mb-1 text-white`} onClick={() => this.showPolicy("policyArray", "show_child_policy", item)}>
										{this.state["services_not_to_use_"+item] ?
											<React.Fragment>
												<span className="mr-2">Services that should not be used</span>
												{this.state["services_not_to_use_"+item].length ?
													this.state["services_not_to_use_"+item].map(row => {
														return(
														<span className={`badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2`}>{row}
															{/* <i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeRegionSection(item, index, row) } ></i> */}
														</span>
														)
													})
												: null}
											</React.Fragment>
										: null}
									</p>
								}
								<div className="d-flex align-self-center">
									{this.state["tags"] && this.state["tags"].length ?
										this.state["tags"].map(tag => {
											return(
												<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > {tag} </span>
											)
										})
									: null}
									{!this.state["show_child_policy_"+index] ?
										<React.Fragment>
											<i className="ml-3 fas fa-pencil text-primary-color mt-1 mr-3 f14" onClick={() => this.showPolicy("policyArray", "show_child_policy", item)}></i>
											<span className={`fa fa-angle-${this.state["show_child_policy_"+item] ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.showPolicy("policyArray", "show_child_policy", item)}></span>
										</React.Fragment>
									: 
										<div className="">
											<span className={`far fa-redo cursorPointer mx-3 text-primary-color f15`} onClick={() => this.resetPolicy(item)}></span>
											{/* <span className={`fal fa-trash cursorPointer mr-3 text-primary-color f15`} onClick={() => this.deletePolicy(item)}></span> */}
											<span className={`ml-3 fa fa-angle-${this.state["show_child_policy_"+item] ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.showPolicy("policyArray", "show_child_policy", item)}></span>
										</div>
									}
								</div>
							</div>
							
							{this.state["show_child_policy_"+item] ?
								<div className={`pl-5`}>
									<div className={`d-flex flex-wrap mb-2`}>
										<div className={`form-check form-check-inline align-self-center mt-2 mr-2 ${this.state["enabled_"+item] ? "" : "checkboxGrayColor"}`}>
											<input
												className='form-check-input m-0'
												type='checkbox'
												checked={this.state["enabled_"+item]}
												onClick={e => this.setState({ ["enabled_"+item]: !this.state["enabled_"+item] },
													() => this.onChangeInput()
												)}	
												title={this.state["enabled_"+item] ? "not enable" : "enable"}								
											/>
											{!this.state["enabled_"+item] ?
												<span class="checkmark" onClick={e => this.setState({ ["enabled_"+item]: !this.state["enabled_"+item]}, () => this.onChangeInput())}></span>
											: null}
										</div>

										<p className={`mb-0 ml-2 mr-1 mt-2 align-self-center ${this.state["disable_"+item] ? "text-issues" : !this.state["enabled_"+item] ? "text-gray3" : "text-white"}`}>Services that should not be used</p>

										{this.state.filteredServiceResourceArray ?
											<div className={`serviceTreeDropdown STDborderBottom w-8`}>
												<DropdownTreeSelect 
													//values={this.state.selectedServiceContent}
													texts={{ placeholder: this.state.selectedServiceContent }}
													data={this.state.filteredServiceResourceArray}
													onChange={this.onChangeServiceDropdown}
													className="bootstrap-demo"
													keepTreeOnSearch={true}
													keepChildrenOnSearch={true}
												/>
											</div>
										: null}
										{this.state["services_not_to_use_"+item] && this.state["services_not_to_use_"+item].length ?
											this.state["services_not_to_use_"+item].map(row => {
												return(
												<span className={`badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2`}>{row}
													{/* <i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeRegionSection(item, index, row) } ></i> */}
												</span>
												)
											})
										: null}
									</div>
									{this.state.actionDetails && this.state.actionDetails.length ?
										<div className="rounded bg-muted p-3 mt-5">
											<p className="mb-0 f20 text-purple3 cursorPointer">Rule Action</p>
											<div className={`d-flex flex-wrap mb-2`}>
												<p className={`mb-0 mt-2 align-self-center`}>Select Action</p>
												<Select
													placeholder={'Select'}
													isSearchable={true}
													components={{
														IndicatorSeparator: () => null
													}}
													className={`selectOption w-25 mr-1 allow_`+item}
													value={({
														value: this.state["selected_action_"+item],
														label: this.state["selected_action_"+item] ? this.state["selected_action_"+item] : <span className="placeholder">Select</span>
													})}		
													options={this.state.actionDetails && this.state.actionDetails.map(row => ({
														value: row.action,
														label: row.action
													}))}															
													onChange={event => this.setState({ 
														["selected_action_"+item]: event.value,
														["action_processor_rule_id_"+item]: this.state.actionDetails.filter(e => e.action === event.value) && this.state.actionDetails.filter(e => e.action === event.value).length && this.state.actionDetails.filter(e => e.action === event.value)[0].processor_rule_id ? this.state.actionDetails.filter(e => e.action === event.value)[0].processor_rule_id : ""
													},
														() => this.onChangeInput("action")
													)}
												/>
											</div>
											
											{this.state["selected_action_"+item] ?
												<div className={`d-flex flex-wrap mb-2 ml-5`}>
													<p className={`mb-0 mt-2 align-self-center`}>Apply Method</p>
													<Select
														placeholder={'Select'}
														isSearchable={true}
														components={{
															IndicatorSeparator: () => null
														}}
														className={`selectOption mr-1 allow_`+item}
														value={({
															value: this.state["apply_method_"+item],
															label: this.state["apply_method_"+item] && this.state["apply_method_"+item] !== "" && this.props.actionMethods ? this.props.actionMethods.filter(e => e.value === this.state["apply_method_"+item])[0].label : <span className="placeholder">Select</span>
														})}
														options={this.props.actionMethods && this.props.actionMethods.map(row => ({
															value: row.value,
															label: row.label
														}))}															
														onChange={event => this.setState({ ["apply_method_"+item]: event.value, ["schedule_"+item]: "" },
															() => this.onChangeInput("action")
														)}
													/>

													{this.state["apply_method_"+item] && this.state["apply_method_"+item] !== "immediately" ?
														<React.Fragment>
														<p className={`mb-0 ml-1 mr-2 align-self-center`}>Schedule</p>
														{this.state["apply_method_"+item] === "run_at" ?
															<input 
																type="text" 
																className={`inputField w-8 pl-2`}
																style={{minHeight: "38px"}}
																placeholder={"HH:MM:SS"}
																value={this.state["schedule_"+item]}
																maxLength="8"
																onKeyDown={e => this.onKeyDownTime(e, e.target.value, "schedule_"+item)}
																onChange={e => this.validateTime(e.target.value, "schedule_"+item)}

																// onChange={e => this.setState({ [varb.variable+"_"+item]: this.validateTimeRange(e.target.value) }, () => this.onChangeInput())}
															/>
														: this.state["apply_method_"+item] === "run_after" ?
															<div className="d-flex">
																<input 
																	type="text" 
																	className={`inputField w-15 pl-2`}
																	style={{minHeight: "38px"}}
																	placeholder={""}
																	value={this.state["schedule_"+item]}
																	maxLength="2"
																	onChange={e => this.setState({ ["schedule_"+item]: onlyNumeric(e.target.value) }, () => this.onChangeInput("action"))}
																	// onKeyDown={e => this.onKeyDownTime(e, e.target.value, "schedule_"+item)}
																	// onChange={e => this.validateTime(e.target.value, "schedule_"+item)}

																	// onChange={e => this.setState({ [varb.variable+"_"+item]: this.validateTimeRange(e.target.value) }, () => this.onChangeInput())}
																/>
																<Select
																	placeholder={'Select'}
																	isSearchable={true}
																	components={{
																		IndicatorSeparator: () => null
																	}}
																	className={`selectOption ml-2 mr-1 allow_`+item}
																	value={({
																		value: this.state["time_type_"+item],
																		label: this.state["time_type_"+item] && this.state["time_type_"+item] !== "" && this.state.timeTypeOptions ? this.state.timeTypeOptions.filter(e => e.value === this.state["time_type_"+item])[0].label : <span className="placeholder">Select</span>
																	})}
																	options={this.state.timeTypeOptions && this.state.timeTypeOptions.map(row => ({
																		value: row.value,
																		label: row.label
																	}))}															
																	onChange={event => this.setState({ ["time_type_"+item]: event.value },
																		() => this.onChangeInput("action")
																	)}
																/>
															</div>

														: null}
														</React.Fragment>
													: null}
												</div>
											: null}
										</div>
									: null}
								</div>
							: null}
							</React.Fragment>							
						: 
							<React.Fragment>
							<div className="d-flex justify-content-between ml-3">
								<div className={`w-80"`}>
									<div className={`d-flex flex-wrap mb-2`}>
										<p className={`mb-0 mt-2 align-self-center text-info`}>{this.state["allow_"+item] && this.state["allow_"+item] !== "" && this.state.allow_options ? this.state.allow_options.filter(e => e.value === this.state["allow_"+item])[0].label : ""}</p>

										<p className={`mb-0 ml-2 mt-2 align-self-center ${this.props.selectedModeType === this.state.mode_engine_type ? (this.props.selectedModeType === "reactive" ? "text-primary-color" : this.props.selectedModeType === "preventive" ? "text-danger" : "text-white") : "text-white"}`}>usage of</p>

										<p className={`mb-0 ml-2 mt-2 align-self-center text-info`}>{this.state["engine_type_"+item] ? this.state["engine_type_"+item] : ""}</p>

										<p className={`mb-0 ml-2 mt-2 align-self-center ${this.props.selectedModeType === this.state. mode_engine_type ? (this.props.selectedModeType === "reactive" ? "text-primary-color" : this.props.selectedModeType === "preventive" ? "text-danger" : "text-white") : "text-white"}`}>engine</p>
									</div>

									<div className={`d-flex flex-wrap mb-2 ml-3`}>
										<p className={`mb-0 ml-2 mt-2 align-self-center ${this.props.selectedModeType === this.state.mode_engine_type ? (this.props.selectedModeType === "reactive" ? "text-primary-color" : this.props.selectedModeType === "preventive" ? "text-danger" : "text-white") : "text-white"}`}>Use</p>

										<p className={`mb-0 ml-2 mt-2 align-self-center text-info`}>{this.state["latest_"+item]}</p>

										<p className={`mb-0 mx-2 mt-2 align-self-center ${this.props.selectedModeType === this.state.mode_engine_type ? (this.props.selectedModeType === "reactive" ? "text-primary-color" : this.props.selectedModeType === "preventive" ? "text-danger" : "text-white") : "text-white"}`}>engine versions</p>
										{this.state["versions_to_use_"+item] && this.state["versions_to_use_"+item].length ? 
											this.state["versions_to_use_"+item].map(row => {
												return(
													<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{row}</span>
												)
											})
										: null}
									</div>
								</div>
								{!index ?
									<div className={`d-flex flex-wrap w-20 align-self-start justify-content-end mt-2`}>
										{this.state["tags"] && this.state["tags"].length ?
											this.state["tags"].map(tag => {
												return (
													tag !== "" ?
														<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2 mb-2`} > {tag} </span>
													: null
												)
											})
										: null}
									</div>
								: null}
							</div>
							{this.state["selected_action_"+item] && this.state["selected_action_"+item] !== "" ?
								<div className="rounded bg-muted p-3 ml-4 mt-2">
									<div className={`d-flex flex-wrap mb-2`}>
										<span className={`mb-0 ml-2 mt-2 align-self-center text-primary-color mt-2`}>Selected Action <span className="text-white mx-1">{this.state["selected_action_"+item]}</span></span>
										{this.state["apply_method_"+item] && this.state["apply_method_"+item] ?
											<span className={`mb-0 ml-2 mt-2 align-self-center text-primary-color mt-2`}><span className="text-white mx-1">{this.props.actionMethods.filter(e => e.value === this.state["apply_method_"+item]) && this.props.actionMethods.filter(e => e.value === this.state["apply_method_"+item]).length ? this.props.actionMethods.filter(e => e.value === this.state["apply_method_"+item])[0].label : ""}</span></span>
										: null}
										{this.state["schedule_"+item] && this.state["schedule_"+item] ?
											<span className={`mb-0 ml-2 mt-2 align-self-center text-primary-color mt-2`}><span className="text-white mx-1">{this.state["schedule_"+item]} {this.state["time_type_"+item] !== "" ? <span className="ml-1">{this.state["time_type_"+item]}</span>: null}</span></span>
										: null}
									</div>
								</div>
							: null}
							</React.Fragment>
						}
					</div>
					</React.Fragment>
				)
			})}
			</React.Fragment>
		)
	}
}

/**
 * Type of the props used in the component
 */
 OPAServiceTemplate.propTypes = {
	setNewEditRuleDetails: PropTypes.func,
	editNewRuleDetails: PropTypes.object,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('stateVersion',state.governance)
	return {
		editNewRuleDetails: state.governance.editNewRuleDetails,
	}
}

export default connect(mapStateToProps, { 
	setNewEditRuleDetails,
	DynamicApiToDevCall
})(OPAServiceTemplate)
