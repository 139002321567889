/*************************************************
 * Tvastar
 * @exports
 * @file ScheduleActions.js
 * @author Prakash // on 21/11/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'
import React from 'react'
import { Auth } from 'aws-amplify'
import axios from 'axios'

import {

	SCHEDULEENDPOINT,
	LIST_LIFECYCLE_POLICIES,
	PUT_LIFECYCLE_POLICIES,
	LIST_LIFECYCLE_RULES
	
} from '../config'
import { myLog } from '../utils/utility'
import { ACTION_TYPES } from './types'

/**
 *  Action to list-lifecycle-policies
 * @param {Object} body
 * @param {Function} callback
 * @author prakash // on 21/11/2022
 */
 export const listLifecyclePolicies = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(SCHEDULEENDPOINT, LIST_LIFECYCLE_POLICIES, { body })
			callback(true, response)
		} catch (error) {
			myLog('listLifecyclePolicies', error)
			callback(false)
		}
	}
}

/**
 *  Action to put-lifecycle-policy
 * @param {Object} body
 * @param {Function} callback
 * @author prakash // on 21/11/2022
 */
 export const putLifecyclePolicy = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(SCHEDULEENDPOINT, PUT_LIFECYCLE_POLICIES, { body })
			callback(true, response)
		} catch (error) {
			myLog('putLifecyclePolicy', error)
			callback(false)
		}
	}
}

/**
 *  Action to list-lifecycle-rules
 * @param {Object} body
 * @param {Function} callback
 * @author prakash // on 21/11/2022
 */
 export const listLifecycleRules = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(SCHEDULEENDPOINT, LIST_LIFECYCLE_RULES, { body })
			callback(true, response)
		} catch (error) {
			myLog('listLifecycleRules', error)
			callback(false)
		}
	}
}