/*************************************************
 * Tvastar
 * @exports
 * @file Header.js
 * @author Rajasekar // on 02/01/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { URL_PATH } from '../../config/urlPath'

import cognitoService from '../../services/cognitoService'
import { getUserProfile } from '../../actions/commonAction'
import { capitalizeFirstLetter } from '../../utils/utility'
import NotificationSystem from 'react-notification-system';

class Header extends Component {
	
	constructor(props) {
		super(props)
		this.state = { showDropdown: false, profile: {} }
	}

	addNotification = (message)=> {
		const notification = this.notificationSystem.current;
		notification.addNotification({
		  message: 'Notification message',
		  level: message,
		  position: 'tc',
		  dismissible: 'none'
		});
	};

	/**
	 * toggles profile settings dropdown
	 */
	toggleDropdown = () => {
		this.setState(prevState => ({
			showDropdown: !prevState.showDropdown,
		}))
	}
	componentDidMount = () => {
		// console.log('showAlertMessage', this.props.profileDetails)
		if(!Object.entries(this.props.profileDetails).length) {
			this.props.getUserProfile((promise, profile) => {
				if (promise) {
					this.setState({ profile })
				} else {
					this.setState({ profile: {} })
				}
			})
		} else {
			this.setState({ profile: this.props.profileDetails })
		}
	}

	/**
	 * Renders Page header
	 */
	render() {
		return (
			<div className='top-header'>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-4 align-self-center'>
							<h6 className='m-0'>Tvaṣṭṛ</h6>
						</div>						
						<div className="col-4">
							{/* <div className="alert alert-danger text-center m-0 w-fit-content m-auto">
								<i className="fa fa-check-circle f20 mr-2 text-danger"></i><span className="align-self-center">Rule has been deleted</span>
							</div> */}
							<NotificationSystem />
							{/* <div className="alert alert-success text-center m-0 w-fit-content m-auto">
								<i className="fa fa-check-circle f20 mr-2 text-success"></i><span className="align-self-center">Rule has been deleted</span>
							</div> */}
						</div>
						<div className='col-4 text-right'>
							<div className='btn-group'>
								<ButtonDropdown isOpen={this.state.showDropdown} toggle={this.toggleDropdown}>
									<DropdownToggle tag='a' className='nav-link pr-0 cursor-pointer' caret>
										{Object.entries(this.state.profile).length && capitalizeFirstLetter(this.state.profile.firstName)+ ' '+capitalizeFirstLetter(this.state.profile.lastName)}
									</DropdownToggle>
									<DropdownMenu right>
										<DropdownItem>
											Profile
										</DropdownItem>
										<DropdownItem>
											<Link
												className="userDropOptions"
												to={URL_PATH.SETTINGS}
											>
												Settings
											</Link>
										</DropdownItem>
										<DropdownItem onClick={() => cognitoService.logout()}>Logout</DropdownItem>
									</DropdownMenu>
								</ButtonDropdown>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
 const mapStateToProps = state => {	
	// console.log('header state', state)
	return {
		profileDetails: state.filters.profileDetails,
    }
}

export default connect(mapStateToProps, {
	getUserProfile
})(withRouter(Header))