/*************************************************
 * Tvastar
 * @exports
 * @file ApexGandalfLineChart.js
 * @author Prakash // on 15/07/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { capitalizeTheFirstLetterOfEachWord, momentConvertionUtcToLocalTime, unitTimeConvertionDetail } from '../../../utils/utility'
import Chart from 'react-apexcharts'


class ApexGandalfLineChart extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    
    componentDidMount = () => {
        let graphData = this.props.graphData;
        if(graphData === 'mockData') {
            graphData = {
                data: [1,4,2,0,5,1,1,1,4,2,3,3,2,5,1,5,0,0,0,4,1,3,2,1,4,2,0,5,4,3,1],
                labels: ["14 July 2022 09:48", "14 July 2022 09:49", "14 July 2022 09:50", "14 July 2022 09:51", "14 July 2022 09:52", "14 July 2022 09:53", "14 July 2022 09:54", "14 July 2022 09:55", "14 July 2022 09:56", "14 July 2022 09:57", "14 July 2022 09:58", "14 July 2022 09:59", "14 July 2022 10:00", "14 July 2022 10:01", "14 July 2022 10:02", "14 July 2022 10:03", "14 July 2022 10:05", "14 July 2022 10:05", "14 July 2022 10:06", "14 July 2022 10:07", "14 July 2022 10:08", "14 July 2022 10:09", "14 July 2022 10:10", "14 July 2022 10:11", "14 July 2022 10:12", "14 July 2022 10:13", "14 July 2022 10:14", "14 July 2022 10:15", "14 July 2022 10:16", "14 July 2022 10:17", "14 July 2022 10:18"],
                alerts: [{date: "14 July 2022 09:52", y: 2, z: "alert"}, {date: "14 July 2022 10:04", y: 3, z: "anomalies"}, {date: "14 July 2022 10:06", y: 1, z: "error"}, {date: "14 July 2022 10:07", y: 2, z: "alert"}, {date: "14 July 2022 10:010", y: 3, z: "anomalies"},  {date: "14 July 2022 10:12", y: 2, z: "alert"}]
            }
        }
        if(graphData && Object.entries(graphData).length) {
            this.drawGraph(graphData)
        }
    }

    drawGraph = (graphData) => {
        let points = []
        graphData.alerts && graphData.alerts.forEach(item => {
            points.push({
                x: new Date(item.date).getTime(),
                y: item.y,
                // mouseEnter: function(e) {
                //     // do something on marker click
                //     alert(1)
                // },
                marker: {
                    size: 6,
                    fillColor: item.z === "alert" ? "#FF4560" : item.z === "anomalies" ? "#775BA2" : item.z === "error" ? "#858B99" : "#3DAFE8",
                    strokeColor: item.z === "alert" ? "#FF4560" : item.z === "anomalies" ? "#775BA2" : item.z === "error" ? "#858B99" : "#3DAFE8",
                    radius: 2,
                    OffsetX: 0,
                    OffsetY: 0,
                    cssClass: '',
                },
                label: {
                    // borderColor: "#FF4560",
                    // offsetY: 0,
                    // style: {
                    //     color: "#fff",
                    //     background: "#FF4560"
                    // },                
                    // text: ""
                }
            })
        })
        
        let graphLables = graphData.labels
        let series = [{
            data: graphData.data,
            labels: graphData.labels
        }]

        let options = {
            tooltip: {
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    let val  = series[seriesIndex][dataPointIndex]
                    let labelName = "change"
                    let unit = w.globals.initialSe
                    let retrunData = '<div class="arrow_box">'
                    retrunData += ' <span>' +  w.globals.initialSeries[0].labels[dataPointIndex] + '</span> '
                    if(labelName !== '') {
                        retrunData += '<span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> '+ val
                    } else {
                        retrunData += '<span style="color:#039BE5"> ' + val + '</span>'
                    }

                    retrunData += '</div>'

                    return retrunData
                },
                fixed: {
                    enabled: false
                },
                x: {
                    show: false,
                },
                y: {
                    show: false
                },        
                marker: {
                    show: false
                },
            },				
            chart: {
                redrawOnParentResize: true,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
                sparkline: {
                    enabled: this.props.sparkline
                },
                animations: {
                    enabled: true
                },
                type: 'line',
                height: 150,
                stacked: this.props.stacked,
            },
            colors: this.props.colors ? this.props.colors : ['#26B68F', '#2ED1BE', '#67E1D6', '#00B5D4'],
            stroke: {
                curve: 'straight',
                width: 1,
                lineCap: 'butt',
                // colors: ["#775DD0"]
            },
            dataLabels: {
                enabled: false
            },  
            grid: {
                show: false,
                borderColor: '#2D323E',
                strokeDashArray: 0,
                position: 'back',
                xaxis: {
                    lines: {
                        show: true
                    },
                    labels: {
                        style: {
                            colors: ['#FFF'],
                        }
                    },
                    axisTicks: {
                        show: false
                    }
                },   
                yaxis: {
                    lines: {
                        show: false
                    },
                    labels: {
                        style: {
                            colors: ['#969AA7'],
                        }
                    }
                },   
            },
            labels: graphLables,
            xaxis: {
                show: this.props.xaxis,
                tooltip: {
                    enabled: false
                },
                type: this.props.xaxisFormat !== 'string' ? 'datetime' : 'category',
                axisBorder: {
                    show: true,
                    color:'#434B5E'
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: this.props.xaxisLabel,
                    datetimeUTC: false,
                    style: {
                        colors: this.props.axisLabelColor,
                        fontSize: this.props.axisLabelFontSize ?  this.props.axisLabelFontSize : '13px',
                        fontWeight: 'bolder',
                    },
                },
                
                crosshairs: {
                    show: false
                },
            },
            yaxis: {
                show: this.props.yaxis,
                axisTicks: {
                    show: false,
                },
                // this.props.tickAmount: this.props.yaxisTickAmont ? this.props.yaxisTickAmont : 4,
                axisBorder: {
                    show: false,
                    color: '#969AA7',
                },
                tooltip: {
                    enabled: false
                },
                labels: {
                    show: this.props.yaxisLabel,
                    style: {
                        colors: this.props.axisLabelColor,
                        fontSize: '13px',
                        fontWeight: 'bolder',
                    },
                    formatter: function(value) {
                        return parseInt(value)
					}
                },
            },
            legend: {
                show: this.props.legend,
            }, 
            // markers: {
            //     // colors: "transparent", //to hide marker on hover in the apex line chart
            //     // strokeWidth: 0,
            //     // size: 0,
            //     // colors: this.props.colors,
            //     // strokeColors: this.props.colors,
            // },           
            annotations: {
                position: 'back',
                points: points,                
                xaxis: [{
                    x: new Date('14 July 2022 10:03').getTime(),
                    strokeDashArray: 0,
                    borderColor: '#F75E3F',
                    label: {
                      borderColor: '#F75E3F',
                      style: {
                        color: '#fff',
                        background: '#F75E3F',
                      },
                      text: 'ipsum',
                    }
                }]
            },
        }

        this.setState({ series, options })
    }

    render() {
        return(
            this.state.series && this.state.series.length ?
                <div className={`${this.props.className}`}>
                    <Chart options={this.state.options} series={this.state.series} type="line" height={this.props.height} width={this.props.width ? this.props.width : "100%"}/>
                    <ul className="stock-legend mt-n2 d-flex justify-content-center"> 
                        <li><span className="legend-circle bg-alerts"></span> <span className="text-muted d-inline-block"> &nbsp;Alerts: {3}</span></li> 
                        <li><span className="legend-circle bg-anomalies"></span> <span className="text-muted d-inline-block"> &nbsp; Anomalies: {2}</span></li> 
                        <li><span className="legend-circle bg-errors"></span> <span className="text-muted d-inline-block"> &nbsp; Errors: {1}</span></li>
                    </ul>
                </div>
            : null
        )
    }
}
 
/**
 * Type of the props used in the component
 */
ApexGandalfLineChart.propTypes = {}

const mapStateToProps = state => {}

export default connect(mapStateToProps, {})(withRouter(ApexGandalfLineChart))