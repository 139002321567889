import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { apiToDevCall } from '../../../../actions/commonAction'
import { setAiopsPropsDetails } from '../../../../actions/aiops/AiopsAction'

import { splitString } from '../../../../utils/utility'

import TextField from '../../../common/formFields/TextField'

class CleanupDisk extends Component {

  constructor(props) {
    super(props);
    this.state = props || {};
    this.state = {
      validationMessage: '',
      parentValue: this.props.value,
      sampleState: [true],
      values: [""] 
    }
    this.updateTextFormFieldValue = this.updateTextFormFieldValue.bind(this);
  }

  addClick(){
    this.setState(prevState => ({ values: [...prevState.values, '']}))
  }
  
  removeClick(name, i){
    let values = [...this.state.values];
    values.splice(i,1);
    values.forEach((arr, idx) => {
      this.setState({ ['objects_'+idx]: arr })
    })
    this.setState({ ['objects_'+i]: "", values })
  }

  componentDidMount = () => {}

  componentDidUpdate = (prevProps) => {
    if(this.props.validationError !== prevProps.validationError) {
      this.props.aiopsRemediationParams.validationObjectErrorMessage && this.props.aiopsRemediationParams.validationObjectErrorMessage.length && this.props.aiopsRemediationParams.validationObjectErrorMessage.forEach(item => {
        this.setState({ [item.field]: item.error })
      })
			this.setState({ 
        validationError: this.props.validationError, 
        overallValidationError: this.props.aiopsRemediationParams.overallValidationError ? this.props.aiopsRemediationParams.overallValidationError : '',
      }, () => {console.log(this.state)})
		}
  }

  // updateTextFormFieldValue = (fieldName, fieldValue) => {
  //   console.log(fieldName, fieldValue)
  //   console.log(this.state.values)

  //   // let name = splitString(fieldName, '_')
  //   // let length = name[name.length - 1]
    
  //   // console.log('name', name)
    
  //   let values = [...this.state.values];
  //   values[fieldName] = fieldValue;
  //   console.log('values', values)
    
  //   this.setState({ values });
  //   // name.splice(name.length-1, 1).join('_') //to get the state name for main page refference
    
  //   // let obj = this.props.aiopsRemediationParams
  //   // obj[name] = values
  //   // this.props.setAiopsPropsDetails('remediationParams', obj)
  //   // this.setState({ [fieldName]: fieldValue }, () => {
  //   //   this.props.formInputState()
  //   // });

    
  // };

  updateTextFormFieldValue = (fieldName, fieldValue) => {
    console.log(fieldName, fieldValue)

    let name = splitString(fieldName, '_')
    let length = name[name.length - 1]
    
    let values = [...this.state.values];
    values[length] = fieldValue;
    this.setState({ values },
      () => {
        let obj = this.props.aiopsRemediationParams
        obj.objects = values
        this.props.setAiopsPropsDetails('remediationParams', obj)
      }
    )

    
  };

  // updateFormFieldValue = (fieldName, fieldValue) => {
  //   console.log(fieldName, fieldValue)
  //   // let split = splitString(fieldName, '_')
  //   // let length = split[split.length - 1]

  //   // let withoutLastString = fieldName.split('_')[fieldName.split('_').length - 1]
  //   // console.log('withoutLastString', withoutLastString)
  //   // let array = []
  //   // for(let i=0;i< length, i++) {
  //     // array.push()
  //   // }
  //   let obj = this.props.aiopsRemediationParams
  //   obj[fieldName] = fieldValue
  //   this.props.setAiopsPropsDetails('remediationParams', obj)
  //   this.setState({ [fieldName]: fieldValue }, () => {
  //     this.props.formInputState()
  //   });
  // };

  addTextbox = (name, index) => {
      let rowList = [true]
      if(this.state[name+'_'+this.props.selectedRecord.processor_rule_id+'_'+index+'_length']) {
          rowList = [...this.state[name+'_'+this.props.selectedRecord.processor_rule_id+'_'+index+'_length']];
          rowList.push(true);
      } else {
          rowList = [...this.state.sampleState];
          rowList.push(true);
      }
      
      this.setState({ [name+'_'+this.props.selectedRecord.processor_rule_id+'_'+index+'_length']: rowList })
  }

  render() {
    return (
      <div className="row mt-2">
        <div className="col-md-12">
          <div className="form-group">
            <label>
              List of files/directories to remove 
              {this.state.validationError && this.state.overallValidationError && this.state.overallValidationError !== '' ?
                  <small className='text-danger m1-2'>{this.state.overallValidationError ? this.state.overallValidationError : ''}</small>
                : null }
            </label>
            {this.state.values.map((add, i) => {
              return(
                <div>
                  <div className="d-flex">
                    <TextField 
                      className={`form-control bg-muted text-gray3 ${this.state['validationObjectsError_'+i] && this.state['validationObjectsError_'+i] !== '' ? '' : 'mb-2'}`}
                      labelClassName={'text-gray3'}
                      recordSelection={this.updateTextFormFieldValue}
                      value={this.state['objects_'+i] ? this.state['objects_'+i] : ''}
                      name={'objects_'+i}
                      label="List of files/directories to remove"
                      placeholder=""
                      required={true}
                      unitLabel = {''}
                      validation = {{}}
                      helper_text = {'Eg:/home/ec2-user/dir1*'}
                    />
                    {this.state.values.length === 1  ?
                      <div className="align-self-center ml-2">
                          <i className='far fa-plus-circle' onClick={() => this.addClick(this)}/>
                      </div>
                    : 
                      <div className="align-self-center ml-2">
                          <i className='far fa-plus-circle mr-2 text-white' onClick={() => this.addClick(this)}/>
                          <i className='far fa-trash-alt text-white' onClick={() => this.removeClick('objects', i)}/>
                      </div>
                    }
                  </div>
                  {this.state.validationError && this.state['validationObjectsError_'+i] && this.state['validationObjectsError_'+i] !== '' ?
                    <small className='text-danger mb-2'>{this.state['validationObjectsError_'+i] ? this.state['validationObjectsError_'+i] : ''}</small>
                  : null }
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
};

/**
 * Type of the props used in the component
 */
 CleanupDisk.propTypes = {
  apiToDevCall: PropTypes.func,
  setAiopsPropsDetails: PropTypes.func
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
 const mapStateToProps = state => {	
	console.log('CleanupDisk', state)  
	return {
      caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : [],
      aiopsRemediationParams: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.remediationParams ? state.aiops.aiopsPropsDetails.remediationParams : {},
      aiopsSelectedRemediationProps: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.selected_remediation ? state.aiops.aiopsPropsDetails.selected_remediation : {},
    }
}


export default connect(mapStateToProps, {
  apiToDevCall, 
  setAiopsPropsDetails
})(withRouter(CleanupDisk))
