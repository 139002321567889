import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import CommonDropdown from '../../../common/CommonDropdown'

const includeOptions = [
	{ value: 'include', label: 'Include'},
	{ value: 'exclude', label: 'Exclude'}
];

class ResourceFilterSection extends React.Component {
    constructor(props) {
        super(props)
        console.log('resourceProps', props)
        this.state = {
            services: [],
            serviceResourceType: [],
            definedService: [],

            sectionDetails: this.props.sectionDetails,

            selectedResource: [],
            selectedResourceType: 'include',
            resourceIds: [],
            textBox: ''
        };
    };

    componentDidMount = () => {
        console.log('00000',this.props)
        let serviceResourceArray = []
        let serviceArray = []
        this.props.services &&
            this.props.services.length &&
            _.chain(this.props.services)
            .groupBy('resource_type')
            .map((value, key) => ({ label: key, sub: value }))
            .value()
            .forEach((service, i) => {
                let resourceRow = {}
                resourceRow.label = service.label
                resourceRow.value = service.label
                serviceResourceArray.push(resourceRow)
                //serviceResourceArray.push(service)
                service.sub.forEach((item, j) => {
                    let serviceRow = {}
                    serviceRow.label = item.service_name
                    serviceRow.value = item.service_name
                    serviceArray.push(serviceRow)
                    //serviceArray.push(item)
                })
            })
        
        this.setState({ services: this.props.services, serviceResourceType: serviceResourceArray, definedService: serviceArray })
	}

    selectedOptionFunction = (label, value) => {
        if(label === 'Resource_Type') {
            this.setState({ selectedResourceType: value })
        } else if(label === 'Resource') {
            this.setState({ selectedResource: value})
            //var totalArray = this.state.selectedResource; 
            // totalArray.forEach((row, index) => {
            //     if(row === value) {
            //         elementExist = 1
            //         totalArray.splice(index, 1);
            //     }
            // })
            // if(elementExist === 0) {
            //     this.setState(prevState => ({
            //         selectedResource: [...prevState.selectedResource, value]
            //     }))
            // }
        } else if(label === 'Resource_Id') {
            let splitValue = this.state.textBox.split(',')
            let array = []
            splitValue.length && 
            splitValue.forEach(item => {
                let resourceArray = this.state.resourceIds
                resourceArray.length ? 
                    resourceArray.forEach((row, index) => {
                        let elementExist = 0
                        if(row === item) {
                            elementExist = 1
                            //resourceArray.splice(index, 1);
                        }
                        if(elementExist === 0) {
                            array.push(item)
                            this.setState(prevState => ({
                                resourceIds: [...prevState.resourceIds, item]
                            }))
                        }
                    })
                :
                    array.push(item)
                    this.setState(prevState => ({
                        resourceIds: [...prevState.resourceIds, item]
                    }))
            })
        }
    }

    applyFilter = () => {
        
        let obj = {}
        obj['Resource'] = this.state.selectedResource
        obj['type'] = this.state.selectedResourceType
        obj['resourceIds'] = this.state.resourceIds
        
        return this.props.data(obj)
    }

    closeFilter = () => {
        return this.props.data(false);
    }

    remove = (type, index) => {
        var totalArray = '' 
        if(type === 'Resource') {
            totalArray = this.state.selectedResource; 
            totalArray.splice(index, 1);
            this.setState({ selectedResource : totalArray, selectedResourceType: 'include' });
        }
    }

    render() {
        return (
            <div className="cardDropSection">
                <div className='container-fluid'>
                    <div className="row mt-3">
                        <div className="col-sm-10 align-self-center">
                            <p className="text-white mb-0">Lorem ipsum text industry since 1500s</p>
                        </div>
                    </div>

                    <div className='row my-3'>
                        <div className="col-sm-3 pr-0">
                            <CommonDropdown 
                                data = {this.selectedOptionFunction.bind(this, 'Resource_Type')}
                                hideHeader = {false}
                                headerName = {'Include'}
                                dropOptions = {includeOptions}
                                displayMenu = {false}
                                isMultiSelect = {false}
                                isDefaultDropdown = {false}
                                styleTop={'top57'}
                                searchOption={false}
                                label={'&nbsp;'}
                            />
                        </div>
                        <div className="col-sm-3 pr-0">
                            <CommonDropdown 
                                data = {this.selectedOptionFunction.bind(this, 'Resource')}
                                hideHeader = {false}
                                headerName = {'Select'}
                                dropOptions = {this.state.serviceResourceType}
                                displayMenu = {false}
                                isMultiSelect = {false}
                                isDefaultDropdown = {false}
                                styleTop={'top57'}
                                searchOption={true}
                                searchOptionWithSingleSelect={true}
                                label={'Resource'}
                            />
                        </div>
                        <div className="col-sm-5 pr-0">
                            <div className="dropdown w-100">
                                <p className="text-white m-0 p-0 f14 font-weight-bold ">Resource&nbsp;<small>(Enter ids with , seprated)</small></p>
                                <div className="customDropdown">
                                    <input type="text" placeholder="Select" className="border-0 w-100" id={'resourceId'} name={'resourceId'} value={this.state.textBox} onChange={(event) => this.setState({ textBox: event.target.value })} />
                                </div>
                            </div>
                        </div>
                            {/* <input className="customTextbox w-100" type="text" id={'resourceId'} name={'resourceId'} value={this.state.textBox}
                            onChange={(event) => this.setState({ textBox: event.target.value })}/> */}
                        <div className="col-sm-1">
                            <label className="w-100 text-white m-0 p-0">&nbsp;</label>
                            <span className={`cursor-pointer text-white f18`} onClick={(event) => this.selectedOptionFunction('Resource_Id', event)}>Ok</span>
                        </div>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="mr-3 minWidth300 align-self-center">
                            {this.state.resourceIds && this.state.resourceIds.length ? 
                                this.state.resourceIds.map((item, index) => {
                                    return (
                                        <span className='badge badge-primary custom-badge ml-2 text-white p-1'> {this.state.selectedResource+ ' '+ item}
                                            <i className='ml-1 fal fa-times cursorPointer'onClick={() => this.remove('ResourceId', index)}></i>
                                        </span> 
                                    )       
                                })
                            :
                                null
                            }
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className="col-sm-6"></div>
                        <div className="col-sm-6 justify-content-end d-flex">
                            <span className="dropCancelBtn d-block p-2 mr-2" onClick={ () => this.closeFilter()}>
                                Cancel
                            </span>
                            <span className="dropApplyBtn d-block p-2" onClick={ () => this.applyFilter()}>
                                Apply
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

/**
 * Type of the props used in the component
 */
ResourceFilterSection.propTypes = {
}

const mapStateToProps = state => {
	return {
		services: state.filters.services,
	}
}

export default connect(mapStateToProps, {})(ResourceFilterSection);

//export default connect(AccessedByFilterSection, null, {getIdentitiesList})

//export default AccessedByFilterSection;
