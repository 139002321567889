/*************************************************
 * Tvastar
 * @exports
 * @file Template.js  //copy of query list from the observability Events QueryDetail page
 * @author Prakash // on 09/06/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { AppWrapper } from '../../common/AppWrapper'
// import PropTypes from 'prop-types'
import { Spinner, Input } from 'reactstrap'
import { currentUTCtime, currentLocaltime, subDays, getAccountNameFromId, getRegionName } from '../../../utils/utility'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import Search from '../../common/SearchComponent'	
import { listAllProviders, listAllAccounts, listAllRegions, listAllServices, getAllTagsKeys, getAllTagsValues } from '../../../actions/commonAction'
import { getQueries, startQueryExection, getQueryResult } from '../../../actions/QueryAction'
import { dynamicCallApi } from '../../../actions/DynamicLoadAction'
import { setResourcePropsDetails } from '../../../actions/assets/assetsAction'
import { setNewEditRuleDetails } from '../../../actions/governance/newGovernanceAction'

import _ from 'lodash'

import { store as CommonNotification } from 'react-notifications-component';

const durationOptions = [
	{ value: '+1h', option: 'Last 1 hour' },
    { value: '+6h', option: 'Last 6 hours' },
    { value: '+12h', option: 'Last 12 hours' },
	{ value: '+3h', option: 'Last 3 hours' }, 
	{ value: '+24h', option: 'Last 24 hours' },
	{ value: '+3d', option: 'Last 3 days' },
	{ value: '+7d', option: 'Last 1 week' },
	{ value: '+14d', option: 'Last 2 weeks' },
	{ value: '+30d', option: 'Last 1 month' },
	{ value: 'custom', option: 'Custom Date' },
]

class Template extends Component {
	constructor(props) {
		super(props)
		this.accountRef = React.createRef()
		this.regionRef = React.createRef()
		this.daterRangeListRef = React.createRef()

		this.state = {
			listLoading: false,

			selectedProvider: "",
			
			selectedRegion: [],
			isRegionOpen: false,

			selectedDuration: "+7d",		

			startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
			overAllTotalPages: 1,
			perPage: 10,

			selectedCategory: "All",
			showRuleInfoSection: true,

			showAppliedSection: true,
			accountsArray: [0],
			policiesList: [1, 2, 3, 4],
			filteredArray: [1, 2, 3, 4],

			ruleFilterType: [
				{label: "Category", data: [{key: "Cost", value: "cost"}]},
				{label: "Status", data: [{key: "Enabled", value: "enabled"}, {key: "Not Enabled", value: "not-enabled"}, {key: "Disabled", value: "disabled"}]}, 
				{label: "Mode", data: [{key: "Reactive", value: "reactive"}, {key: "Proactive", value: "proactive"}, {key: "Mutative", value: "mutative"}]},
				{label: "Severity", data: [{key: "Critical", value: "CRITICAL"}, {key: "High", value: "HIGH"}, {key: "Medium", value: "MEDIUM"}, {key: "Low", value: "LOW"}]}
			],
			
			sizeType: ["GB", "TB"],

			totalLogPolicy: [0],
			logTypeOptions: ["Access Log", "Execution Log"],
			logDestination: ["CloudWatch", "CloudLog"],
			logEncryptionType: ["Default", "AWS Managed KMS", "Customer Managed KMS"],
			
			totalEnginePolicy: [0],
			engineTypes: ["aurora", "mysql"],
			engionVersionType: ["Latest", "Specific"],
			engineVersions: ["5.6.10a", "5.6.10b", "5.6.11a", "5.7.101"],
			
			totalBlockStorePolicy: [0],
			blockstoreTypes: ["Provisioned IOPS SSD (io1)", "Provisioned IOPS SSD (io2)", "General Purpose SSD (gp2)", "General Purpose SSD (gp3)", "Throughput Optimized HDD (st1)", "Cold HDD (sc1)", "Magnetic (standard)"],

			showActionSection: true,
			actionArray: [0],

			showNotificationSection: true,
			notificationArray: [0],
			notificationType: ["Slack", "Email"]
		}
	}

	componentDidMount = () => {
		if(this.props.providers && this.props.providers.length) {
			this.setState({ providers: this.props.providers, selectedProvider: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : this.props.providers[0].provider_name },
				() => {
					this.filterData("provider")
				}
			)
		} else {
			this.props.listAllProviders((promise, providers) => {
				if (promise) {
					this.setState({ providers, selectedProvider: providers.length ? providers[0].provider_name : "" },
						() => {
							if(this.state.selectedProvider) {
								this.handleDescription("provider", this.state.selectedProvider)
							}
							this.filterData("provider")
						}
					)
				} else {
					this.setState(
						{ providers: [],  }, 
						() => {
							this.filterData()
						}
					)
				}
			})
		}

		this.getAllTagsKeys()
	}

	filterData = () => {
		let params = {}
		if(this.state.selectedProvider && this.state.selectedProvider !== "") {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if(this.props.accounts && this.props.accounts.length) {
			let allAccounts = this.props.accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
			if(this.props.accounts.length) {
				let accRow = {}
				accRow.account_id = "All"
				accRow.account_name = "All"
				accRow.date = null
				accRow.disabled = false
				accRow.provider = "All"
				allAccounts.unshift(accRow);
			}
			this.setState({ accounts: allAccounts })
		} else {
			this.props.listAllAccounts(params, (promise, allAccounts) => {
				if (promise) {						
					let filterdAccounts = allAccounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
					if(filterdAccounts.length) {
						let accRow = {}
						accRow.account_id = "All"
						accRow.account_name = "All"
						accRow.date = null
						accRow.disabled = false
						accRow.provider = "All"
						filterdAccounts.unshift(accRow);
					}				
					console.log("filterdAccounts", filterdAccounts)		
					this.setState({ accounts: filterdAccounts })
				}
			})
		}

		if(this.props.regions && this.props.regions.length) {
			let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
			this.setState({ regions: providerRegions })
		} else {			
			this.props.listAllRegions(params, (promise, regions) => {
				if (promise) {
					let providerRegions = regions && regions[0] && regions[0].provider_regions
					if(providerRegions) {
						let provider_regions_row = {}
						provider_regions_row.availability_zones = []
						provider_regions_row.city = "All"
						provider_regions_row.country = "All"
						provider_regions_row.geography = "All"
						provider_regions_row.name = "All"
						provider_regions_row.region = "All"
						provider_regions_row.region_id = "All"
						provider_regions_row.state = "All"
						providerRegions.unshift(provider_regions_row);
					}
					this.setState({ regions: providerRegions })
				} else {
					this.setState({ regions: [] })
				}
			})
		}


		this.props.listAllServices(params, (promise, response) => {
			if (promise) {
				this.setState({ services: response }, 
					() => {
						this.formSericeSelect()	
					}
				)
			}
		})
	}

	formSericeSelect = () => {
		let data = []
		if(this.state.services && this.state.services.length) {
			_.chain(this.state.services)
				.groupBy('resource_type')
				.map((value, key) => ({ label: key, sub: value }))
				.value()
				.forEach((service, i) => {
					let child = []
					service.sub.forEach((item, i) => {
						let childRow = {}
						childRow.value = service.service_name
						childRow.label = item.service_name
						child.push(childRow)
					})
					let dataRow = {}
					dataRow.label = service.label
					dataRow.options = child

					data.push(dataRow)
				})
		}

		this.setState({ resourceTypes: data })
	}
	
	handleMultiSelectChange = (field, arrayValue, state) => {
		console.log(field, arrayValue)
		let value = arrayValue.map(item => item.value ? item.value : item)
		let selectedValue = []
		if(field === "selectedRegion" || field === "selected_region") {
			let prevState = this.state[field] ? this.state[field] : []
			if(value.includes("All")) {
				if(!prevState.includes("All")) {
					this.state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf("All");
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes("All")) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes("All")) {
				if(!selectedValue.includes("All") && selectedValue.length === (this.state.regions.length -1)) {
					selectedValue.push("All")
				}
			}
			if(field === "selected_region") {
				field = field+"_"+state
			}
			this.setState({ [field]: selectedValue })
		} else {
			//for array			
			if(state) {
				let prevState = this.state[field] ? this.state[field] : []
                if(prevState.length === 1 && prevState[0] === "All") {
                    prevState = []
                }
				if(value.includes("All")) {
					if(!prevState.includes("All")) {
						if(state.filter(e => e.value).length) {
							selectedValue = state.map(e => e.value)
						} else {
							selectedValue = state
						}
					} else {
						const index = value.indexOf("All");
						if (index > -1) {
							value.splice(index, 1);
						}
						selectedValue = value
					}
				} else if(!prevState.includes("All")) {
					selectedValue = value
				}
	
				if(selectedValue.length && !selectedValue.includes("All")) {
					if(!selectedValue.includes("All") && selectedValue.length === (state.length -1)) {
						selectedValue.push("All")
					}
				}
			} else {
				selectedValue = value
			}
			this.setState({ [field]: selectedValue })
		}
	}
	
	getMultiSelectedCount = (type, array) => {
		return array.length && array.includes("All") ? "All" : (array.length ? array.length +" Selected" : "All")
	}

	handleClickOutside = (event) => {}

	handleDescription = (label, value) => {
		this.props.setNewEditRuleDetails(label, value)
	}

	removeRegionSection = (item, index, reg) => {
        // filteredResult = this.state.selectedTags.filter(e => e.key !== tag.key && e.value !== tag.value)
		let filteredResult = this.state["selected_region_"+item].filter(e => e !== reg)
        this.setState({ ["selected_region_"+item]: filteredResult },
			() => this.handleMultiSelectChange("selected_region", filteredResult, item)
		)
	}

    getAllTagsKeys = () => {
		
        if(!this.props.tagKeys || !this.props.tagKeys.length) {
            let params = {}
			// params.account_id = "265469181489"
			// params.region = "us-east-2"
            this.props.getAllTagsKeys(params, (promise, response) => {})
        }
    }

    getAllTagsValues = (item) => {
        let params = {}
        params.tags = [{ "key": this.state["tag_key_"+item] }]
        this.props.getAllTagsValues(params, (promise, response) => {
            if(promise) {
                this.setState({ ["tagValues"+item]: response })
            }
        })
    }

	addNewTag = (item) => {
		let selectedTags = this.state["selected_tags_"+item] ? this.state["selected_tags_"+item] : []

        if(this.state["tag_key_"+item] && this.state["tag_key_"+item] !== "" && this.state["tag_value_"+item] && this.state["tag_value_"+item] !== "" ) {
			let dataRow = {
				key: this.state["tag_key_"+item],
				value: this.state["tag_value_"+item],
			}
			selectedTags.push(dataRow)
		}

		this.setState({ ["selected_tags_"+item]: selectedTags, ["tag_key_"+item]: "",  ["tag_value_"+item]: "", tagValues: [] })
    }

    removeTagSection = (item, tag) => {
        // filteredResult = this.state.selectedTags.filter(e => e.key !== tag.key && e.value !== tag.value)
		let filteredResult = this.state["selected_tags_"+item].filter(e => e !== tag)
        this.setState({ ["selected_tags_"+item]: filteredResult })
    }	

	addSection = (array) => {
        let rowList = this.state[array];
        if(this.state[array]) {
            let value = this.state[array][this.state[array].length - 1]
            value = value+1 
            rowList.push(value);
        }
        
        this.setState({[array]: rowList })
    }

	addNewSharedDetails = (item) => {
		let sharedDetails = this.state["shared_details_"+item] ? this.state["shared_details_"+item] : []
        if(this.state["shared_to_"+item] && this.state["shared_to_"+item] !== "") {
			sharedDetails.push(this.state["shared_to_"+item])
		}

		this.setState({ ["shared_details_"+item]: sharedDetails, ["shared_to_"+item]: "" })
    }

	removeSharedDetails = (item, sharedTo) => {
		let filteredResult = this.state["shared_details_"+item].filter(e => e !== sharedTo)
        this.setState({ ["shared_details_"+item]: filteredResult })
    }

	showPolicy = (array, showField, index) => {		
		this.state[array].forEach(item => {
			if(index === item) {
				this.setState({ [showField+"_"+item]: !this.state[showField+"_"+item] })
			} else {
				this.setState({ [showField+"_"+item]: false })
			}
		})
	}

	addPolicies = (array, showField, index) => {
        let rowList = this.state[array];
        if(this.state[array]) {
            let value = this.state[array][this.state[array].length - 1]
            value = value+1 
            rowList.push(value);
        }
		rowList.forEach(item => {
			if(index === item) {
				this.setState({ [showField+"_"+item]: true })
			} else {
				this.setState({ [showField+"_"+item]: false })
			}
		})
        
        this.setState({[array]: rowList })
    }
	
	render() {		
		return (
			<div className="container-fluid overflow-auto flex-grow-1 bg-muted" onClick={ (event) => { this.handleClickOutside(event) } }>
                <div className={`loaderOverlay ${this.state.queryLoading ? "" : "displayNone"}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
                <div className="row h-100">
                    <div className="col-sm-12 p-0">
                        <div className="title-section pb-3">
							<div className="d-flex">
                                <div className="col-12 p-0 align-self-center">
                                    <div className="d-flex">
                                        <h6 className="text-white m-0">Rules</h6>
                                    </div>
                                    <p className="text-white m-0">Governance rules on your Cloud</p>
								</div>
							</div>
						</div>
						<div className="p-2">
							<div className="rounded bg-dark p-3 zapInputDark">
								<div className="d-flex justify-content-between">
									<p className="mb-2 text-white f20 cursorPointer" onClick={() => this.setState({ showRuleInfoSection: !this.state.showRuleInfoSection })}>Rule Information</p>
									<span className={`ml-4 fa fa-angle-${this.state.showRuleInfoSection ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.setState({ showRuleInfoSection: !this.state.showRuleInfoSection })}></span>
								</div>
								{this.state.showRuleInfoSection ?
									<React.Fragment>
									<div className={`d-flex`}>
										<div className="col-sm-6 pl-0">
											<div className="d-flex">
												<div className="col-sm-4 pl-0">
													<div className="">
														<div className="d-flex justfy-content-between">
															<label className="align-self-center m-0">Provider </label>
															{(this.state.hasError || this.state.hasNextError) && (!this.props.editNewRuleDetails.provider || this.props.editNewRuleDetails.provider === "") ? (
																<small className="text-issues">required</small>
															) : null}
														</div>
														<Select
															isSearchable={false}
															className={`f13 p-0 ${this.props.ruleDetails && Object.entries(this.props.ruleDetails).length ? "disabled disabledDarkBg" : ""}`}
															value={({
																value: this.props.editNewRuleDetails.provider && this.props.editNewRuleDetails.provider !== "" ? this.props.editNewRuleDetails.provider : "",
																label: this.props.editNewRuleDetails.provider && this.props.editNewRuleDetails.provider.toLowerCase() !== "" ? this.props.editNewRuleDetails.provider : <span className="placeholder">Select</span>
															})}
															options={this.state.providers && this.state.providers.map(row => ({
																value: row.provider_name,
																label: row.provider_name,	
															}))}
															onChange={event => this.setState({ selectedProvider: event.value },
																() => {
																	if(this.props.editNewRuleDetails.provider !== event.value) {
																		this.handleDescription('provider', event.value)
																	}
																}
															)}
														/>
													</div>
												</div>
												<div className="col-sm-8 p-0">
													<div className="d-flex justfy-content-between">
														<label className="align-self-center m-0">Name of the policy</label>
														{(this.state.hasError || this.state.hasNextError) && (!this.props.editRuleDetails.description || this.props.editRuleDetails.description === "") ? (
															<small className="text-issues">Description is required</small>
														) : null}
													</div>
													<Input
														type="text"
														placeholder="Enter Rule Name"
														className="inputTextbox"
														maxlength="128"
														value={this.state.rule_name}
														onChange={e => this.handleDescription("rule_name", e.target.value)}
													/>
												</div>
											</div>
											<div className="d-flex mt-3">
												<div className="col-sm-4 pl-0">
													<div className="d-flex justfy-content-between">
														<label className="align-self-center m-0">Service </label>
														{(this.state.hasError || this.state.hasNextError) && (!this.props.editNewRuleDetails.Service || this.props.editNewRuleDetails.Service === "") ? (
															<small className="text-issues">required</small>
														) : null}
													</div>
													<Select
														isSearchable={true}
														className={`f13 p-0 ${this.props.ruleDetails && Object.entries(this.props.ruleDetails).length ? "disabled disabledDarkBg" : ""}`}
														value={({
															value: this.props.editNewRuleDetails.service && this.props.editNewRuleDetails.service !== "" ? this.props.editNewRuleDetails.service : "",
															label: this.props.editNewRuleDetails.service && this.props.editNewRuleDetails.service.toLowerCase() !== "" ? this.props.editNewRuleDetails.service : <span className="placeholder">Select</span>
														})}
														options={this.state.resourceTypes && this.state.resourceTypes.map(item => ({ 
															value: item.label,
															label: item.label,
														}))}
														onChange={event => this.setState({ selectedService: event.value },
															() => {
																if(this.props.editNewRuleDetails.service !== event.value) {
																	this.handleDescription('service', event.value)
																}
															}
														)}
													/>
												</div>
												<div className="col-sm-4 pl-0">
													<div className="d-flex justfy-content-between">
														<label className="align-self-center m-0">Show By </label>
														{(this.state.hasError || this.state.hasNextError) && (!this.props.editNewRuleDetails.type || this.props.editNewRuleDetails.type === "") ? (
															<small className="text-issues">required</small>
														) : null}
													</div>
													<Select
														isSearchable={true}
														className={`f13 p-0 ${this.props.ruleDetails && Object.entries(this.props.ruleDetails).length ? "disabled disabledDarkBg" : ""}`}
														value={({
															value: this.props.editNewRuleDetails.show_by && this.props.editNewRuleDetails.show_by !== "" ? this.props.editNewRuleDetails.show_by : "",
															label: this.props.editNewRuleDetails.show_by && this.props.editNewRuleDetails.show_by.toLowerCase() !== "" ? this.props.editNewRuleDetails.show_by : <span className="placeholder">Select</span>
														})}
														options={this.state.ruleFilterType && this.state.ruleFilterType.map(item => ({ 
															value: item.label,
															label: item.label,
														}))}
														onChange={event => this.setState({ selectedShowBy: event.value },
															() => {
																if(this.props.editNewRuleDetails.show_by !== event.value) {
																	this.handleDescription('show_by', event.value)
																}
															}
														)}
													/>
												</div>
												<div className="col-sm-4 p-0">
													<div className="d-flex justfy-content-between">
														<label className="align-self-center m-0">Type </label>
														{(this.state.hasError || this.state.hasNextError) && (!this.props.editNewRuleDetails.type || this.props.editNewRuleDetails.type === "") ? (
															<small className="text-issues">required</small>
														) : null}
													</div>
													<Select
														isSearchable={true}
														className={`f13 p-0 ${this.props.ruleDetails && Object.entries(this.props.ruleDetails).length ? "disabled disabledDarkBg" : ""}`}
														value={({
															value: this.props.editNewRuleDetails.Show_by_type && this.props.editNewRuleDetails.Show_by_type !== "" ? this.props.editNewRuleDetails.Show_by_type : "",
															label: this.props.editNewRuleDetails.Show_by_type && this.props.editNewRuleDetails.Show_by_type.toLowerCase() !== "" ? this.props.editNewRuleDetails.Show_by_type : <span className="placeholder">Select</span>
														})}
														options={this.state.ruleFilterType && this.state.selectedShowBy && this.state.selectedShowBy !== "" && this.state.ruleFilterType.filter(e => e.label === this.state.selectedShowBy)[0].data.map(item => ({ 
															value: item.value,
															label: item.key,
														}))}
														onChange={event => this.setState({ selectedShowByType: event.value },
															() => {
																if(this.props.editNewRuleDetails.service !== event.value) {
																	this.handleDescription('Show_by_type', event.value)
																}
															}
														)}
													/>
												</div>
											</div>
										</div>										
										<div className="col-sm-6 pr-0">
											<label className="align-self-center m-0">Description</label>
											<Input
												type="textarea"
												className="inputTextbox" 
												placeholder="Enter Rule Description"
												rows="4"
												id="comment"
												maxlength="256"
												onChange={e => this.handleDescription("description", e.target.value)}
												value={this.state.description}
											/>
										</div>
									</div>
									</React.Fragment>
								: null}
							</div>

							<div className="rounded bg-dark3 p-3 border-info mt-3 dlpDescriptionForm dlpDescriptionFormPlain">
								<div className="d-flex justify-content-between">
									<p className="mb-2 text-purple3 f20 cursorPointer" onClick={() => this.setState({ showAppliedSection: !this.state.showAppliedSection })}>Applied to the policies</p>
									<span className={`ml-4 fa fa-angle-${this.state.showAppliedSection ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.setState({ showAppliedSection: !this.state.showAppliedSection })}></span>
								</div>
								{this.state.showAppliedSection ? 
									<React.Fragment>
									{this.state.accountsArray.map(item => {
										return(
										<div className="d-flex flex-wrap mb-2">
											<p className={`mb-0 mr-2 mt-2 mb-1 align-self-end text-white`}>Restricted to the account</p>
											<Select
												isSearchable={true}
												components={{
													IndicatorSeparator: () => null
												}}
												className={`selectOption selectBoxBottomColor mr-2 mt-2`}
												value={({
													value: this.state["selected_account_"+item],
													label: this.state["selected_account_"+item] && this.state["selected_account_"+item] !== "" ? this.state["selected_account_"+item] : <span className="placeholder">Select</span>
												})}
												options={this.props.accounts && this.props.accounts.map(row => ({
													value: row.account_id,
													label: row.account_name,	
												}))}
												onChange={event => this.setState({ ["selected_account_"+item]: event.value })}
											/>
											<p className={`mb-0 ml-2 mr-2 mt-2 mb-1 align-self-end text-white`}>and region</p>
											<div className={`multiSelectOption selectBoxBottomColor align-self-end mt-2`}>
												<ReactMultiSelectCheckboxes
													placeholderButtonLabel="All"
													getDropdownButtonLabel={() => this.getMultiSelectedCount("selected_region_"+item, this.state["selected_region_"+item] ? this.state["selected_region_"+item] : [])}
													options={this.state.regions && this.state.regions.map(region => ({
														value: region.region,
														label: region.name,
													}))}
													onChange={arr => { this.handleMultiSelectChange("selected_region", arr ? arr : [], item) }}
													value={this.state["selected_region_"+item] && this.state["selected_region_"+item].map(acc => ({
														value: acc
													}))}
												/>
											</div>
											{this.state["selected_region_"+item] && this.state["selected_region_"+item].length ? 
												this.state["selected_region_"+item].map((reg, index) => {
													return(
														<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{reg}
															<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeRegionSection(item, index, reg) } ></i>
														</span>
													)
												})
											: null}
											<p className={`mb-0 ml-2 mr-2 mt-2 mb-1 align-self-end text-white`}>tags</p>
											<Select
												isSearchable={true}
												components={{
													IndicatorSeparator: () => null
												}}
												className={`selectOption selectBoxBottomColor mr-2 mt-2`}
												value={({
													value: this.state["tag_key_"+item],
													label: this.state["tag_key_"+item] && this.state["tag_key_"+item] !== "" ? this.state["tag_key_"+item] : <span className="placeholder">Key</span>
												})}														
												
												options={this.props.tagKeys && this.props.tagKeys.map(item => ({
													value: item,
													label: item,	
												}))}
												onChange={event => this.setState({
													["tag_key_"+item]: event.value,
													["tagValues"+item]: [],
												},
													() => this.getAllTagsValues(item)
												)}
											/>
											<Select
												isSearchable={true}
												components={{
													IndicatorSeparator: () => null
												}}
												className={`selectOption selectBoxBottomColor mr-2 mt-2`}
												value={({
													value: this.state["tag_value_"+item] && this.state["tag_value_"+item] !== "" ? this.state["tag_value_"+item] : 'Select',
													label: this.state["tag_value_"+item] && this.state["tag_value_"+item] !== "" ? this.state["tag_value_"+item] : <span className="placeholder">Value</span>
												})}													
												
												options={this.state["tagValues"+item] && this.state["tagValues"+item].map(item => ({
													value: item,
													label: item,	
												}))}
												onChange={event => this.setState({ ["tag_value_"+item]: event.value })}
											/>
											<span className={`far fa-plus cursor-pointer f18 mt-2 mb-1 align-self-end mr-4`} onClick={() => this.addNewTag(item)}></span>
											{this.state["selected_tags_"+item] && this.state["selected_tags_"+item].length ? 
												this.state["selected_tags_"+item].map((tag, index) => {
													return(
														<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{tag.key +" : "+tag.value}
															<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeTagSection(item, tag) } ></i>
														</span>
													)
												})
											: null}
										</div>
										)
									})}
									<span className="text-info mt-2 mb-5 cursorPointer" onClick={() => this.addSection("accountsArray")}>+ Add Applied Section</span>
									</React.Fragment>
								: null}
							</div>

							<div className="rounded bg-dark p-3 mt-3 dlpDescriptionForm dlpDescriptionFormPlain">
								{this.state.listLoading ?
									<div className="d-flex justify-content-center m-4">
										<Spinner className="text-center" color="white" size="lg" />
									</div>
								:	
									<React.Fragment>
									<div className={`d-flex pb-2 justify-content-between displayNone`}>
										<div className="d-flex mr-2 w-40">
											<p className="f12 m-0 align-self-center pt-1">Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.state.policiesList && this.state.policiesList.length} policie(s)</p>
										</div>
										<div className="d-flex justify-content-end w-60 mb-n2">
											{this.state.policiesList && this.state.policiesList.length ?
												<Search
													data={this.state.policiesList ? this.state.policiesList : []}
													applyTags={false}
													applyLiteDarkTags={true}
													topClassName={"bg-black5 align-self-center w-50 mr-1 border-gray5 rounded-5 f12"}
													searchClassName={"px-2 f12"}
													searchIconColor={"text-gray5 f12"}
													searchPlaceHolder={"Search...."}
													className={"bg-transparent text-white pl-0 form-control-sm f12"}
													filteredData={(filteredArray) => {
														this.setState({ filteredArray, startRecord: 0, currentPage: 1 })
													}}
												/>
											: null}
											{this.state.filteredArray && this.state.filteredArray.length > this.state.perPage ?
												<div className="pagination errorPagePagination f12">
													<span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
													<button><i className={`fal fa-arrow-to-left cursorPointer ${this.state.currentPage === 1 ? "disabled text-muted" : "text-info"}`} onClick={() => this.navigatePage("start", this.state.currentPage)}></i></button> 
													<button><i className={`fal fa-angle-left cursorPointer ${this.state.currentPage === 1 ? "disabled text-muted" : "text-info"}`} onClick={() => this.navigatePage("previous", this.state.currentPage)}></i></button> 
													<button><i className={`fal fa-angle-right cursorPointer ${this.state.currentPage === this.state.totalPages ? "disabled text-muted" : "text-info"}`} onClick={() => this.navigatePage("next", this.state.currentPage)}></i></button> 
													<button><i className={`fal fa-arrow-to-right cursorPointer ${this.state.currentPage === this.state.totalPages ? "disabled text-muted" : "text-info"}`} onClick={() => this.navigatePage("end", this.state.currentPage)}></i></button>
												</div>
											: null}
										</div>
									</div>
								
									{/* <div className="d-flex errorSectionScroll h-95" > */}
									<div className="d-flex">
										<div className={`w-100`}>
											<div className="accordion" id="accordionExample">												
												<div className={`card bg-dark border-0 mb-2 overflow-unset`}>
													<div className="d-flex justify-content-between pb-2 mb-2 text-white border-bottom">
														<h5 className="mb-0 text-warning cursorPointer" onClick={() => this.setState({ showEnginePolicies: !this.state.showEnginePolicies, ["show_engine_policy_0"]: true })}>Engine Policies</h5>
														<div className="d-flex">
															<span className="text-white mr-3">1 enabled</span>
															<span className="text-gray3 mr-3">2 not-enabled</span>
															<span className="text-issues mr-3">1 disabled</span>
															<span className={`ml-4 fa fa-angle-${this.state.showEnginePolicies ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.setState({ showEnginePolicies: !this.state.showEnginePolicies, ["show_engine_policy_0"]: true })}></span>
														</div>
													</div>
													{this.state.showEnginePolicies ?
														this.state.totalEnginePolicy && this.state.totalEnginePolicy.map((item, index) => {
															return(
															<React.Fragment>
															<div className={`py-3 mb-2 border-bottom`}>
																{this.state.totalEnginePolicy && this.state.totalEnginePolicy.length ?
																	<div className={`d-flex justify-content-between pl-2`}>
																		{(!this.state["selected_engine_type_"+item] || this.state["selected_engine_type_"+item] === "") && (!this.state["selected_engine_version_"+item] || this.state["selected_engine_version_"+item] === "") ?
																			<p className={`mb-0 mx-2 align-self-center mb-1 text-primary-color`}>Choose policy details</p>
																		:
																			<p className={`mb-0 mx-2 align-self-center mb-1 text-white`}>
																				{this.state["selected_engine_type_"+item] && this.state["selected_engine_type_"+item] !== "" ?
																					<React.Fragment>
																						<span className="mr-2">Engine type</span>
																						<span className="mr-2 text-info">{this.state["selected_engine_type_"+item]}</span>
																					</React.Fragment>
																				: null}
																				<span className="mr-2">allow</span>
																				{this.state["latest_version_"+item] ?
																					<span className="mr-2 text-info">Only Latest</span>
																				: 
																					<span className="mr-2 text-info">All</span>
																				}
																				<span className="mr-2">version</span>
																				{!this.state["latest_version_"+item] && this.state["selected_engine_version_"+item] && this.state["selected_engine_version_"+item] !== "" ?
																					<React.Fragment>
																						<span className="mr-2">Engine version</span>
																						<span className="mr-2 text-info">{this.state["selected_engine_version_"+item]}</span>
																					</React.Fragment>
																				: null}
																			</p>
																		}

																		{!this.state["show_engine_policy_"+item] ?
																			<div className="d-flex">
																				<i className="fas fa-pencil text-primary-color mt-1 mr-3 f14" onClick={() => this.showPolicy("totalEnginePolicy", "show_engine_policy", item)}></i>
																				<span className={`fa fa-angle-${this.state["show_engine_policy_"+item] ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.showPolicy("totalEnginePolicy", "show_engine_policy", item)}></span>
																			</div>
																		: 
																			<span className={`fa fa-angle-${this.state["show_engine_policy_"+item] ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.showPolicy("totalEnginePolicy", "show_engine_policy", item)}></span>
																		}
																	</div>
																: null}
																{this.state["show_engine_policy_"+item] ?
																	<div className={`pl-5`}>
																		<div className={`d-flex flex-wrap mb-2`}>
																			<div className={`form-check form-check-inline align-self-center mt-2 mr-2 ${this.state["enableEngineOptions_1_"+item] ? "" : "checkboxGrayColor"}`}>
																				<input
																					className='form-check-input m-0'
																					type='checkbox'
																					checked={this.state["enableEngineOptions_1_"+item]}
																					onChange={e => this.setState({ ["enableEngineOptions_1_"+item]: !this.state["enableEngineOptions_1_"+item]})}	
																					title={this.state["enableEngineOptions_1_"+item] ? "not enable" : "enable"}								
																				/>
																				{!this.state["enableEngineOptions_1_"+item] ?
																					<span class="checkmark" onClick={e => this.setState({ ["enableEngineOptions_1_"+item]: !this.state["enableEngineOptions_1_"+item]})}></span>
																				: null}
																			</div>
																			<span className={`fas fa-ban align-self-center mt-2 mr-2 ${this.state["disbaleEngineOptions_1_"+item] ? "text-issues" : "text-gray3"}`} onClick={() => this.setState({ ["disbaleEngineOptions_1_"+item]: !this.state["disbaleEngineOptions_1_"+item] })} title={this.state["disbaleEngineOptions_1_"+item] ? "disable" : "not disbale"}></span>

																			<p className={`mb-0 ml-2 mr-1 mt-2 align-self-center ${this.state["disbaleEngineOptions_1_"+item] ? "text-issues" : !this.state["enableEngineOptions_1_"+item] ? "text-gray3" : "text-white"}`}>Allowed Engine type</p>

																			<Select
																				placeholder={'Select'}
																				isSearchable={true}
																				components={{
																					IndicatorSeparator: () => null
																				}}
																				className={`selectOption mr-1`}
																				value={({
																					value: this.state["selected_engine_type_"+item] && this.state["selected_engine_type_"+item],
																					label: this.state["selected_engine_type_"+item]  ? this.state["selected_engine_type_"+item] : <span className="placeholder">Select</span>
																				})}		
																				options={this.state.engineTypes && this.state.engineTypes.map(row => ({
																					value: row,
																					label: row
																				}))}															
																				onChange={event => this.setState({ ["selected_engine_type_"+item]: event.value })}
																			/>
																			<i class="fas fa-circle-xmark"></i>
																			<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Security </span>
																			<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Availability </span>
																			{this.state["disbaleOptions_1_"+item] ?
																				<span className="text-issues ml-4 align-self-center"> (disabled) </span>
																			: null}
																		</div>
																		<div className={`d-flex flex-wrap mb-2`}>
																			<div className={`form-check form-check-inline align-self-center mt-2 mr-2 ${this.state["enableEngineOptions_2_"+item] ? "" : "checkboxGrayColor"}`}>
																				<input
																					className='form-check-input m-0'
																					type='checkbox'
																					checked={this.state["enableEngineOptions_2_"+item]}
																					onChange={e => this.setState({ ["enableEngineOptions_2_"+item]: !this.state["enableEngineOptions_2_"+item]})}	
																					title={this.state["enableEngineOptions_2_"+item] ? "not enable" : "enable"}								
																				/>
																				{!this.state["enableEngineOptions_2_"+item] ?
																					<span class="checkmark" onClick={e => this.setState({ ["enableEngineOptions_2_"+item]: !this.state["enableEngineOptions_2_"+item]})}></span>
																				: null}
																			</div>
																			<span className={`fas fa-ban align-self-center mt-2 mr-2 ${this.state["disbaleEngineOptions_2_"+item] ? "text-issues" : "text-gray3"}`} onClick={() => this.setState({ ["disbaleEngineOptions_2_"+item]: !this.state["disbaleEngineOptions_2_"+item] })} title={this.state["disbaleEngineOptions_2_"+item] ? "disable" : "not disbale"}></span>

																			<p className={`mb-0 ml-2 mr-1 mt-2 align-self-center ${this.state["disbaleEngineOptions_2_"+item] ? "text-issues" : !this.state["enableEngineOptions_2_"+item] ? "text-gray3" : "text-white"}`}>Allow</p>
																			
																			<Select
																				placeholder={'Select'}
																				isSearchable={true}
																				components={{
																					IndicatorSeparator: () => null
																				}}
																				className={`selectOption mr-1`}
																				value={({
																					value: this.state["selected_engine_version_type_"+item] && this.state["selected_engine_version_type_"+item],
																					label: this.state["selected_engine_version_type_"+item]  ? this.state["selected_engine_version_type_"+item] : <span className="placeholder">Select</span>
																				})}		
																				options={this.state.engionVersionType && this.state.engionVersionType.map(row => ({
																					value: row,
																					label: row
																				}))}															
																				onChange={event => this.setState({ ["selected_engine_version_type_"+item]: event.value })}
																			/>
																			<p className={`mb-0 ml-2 mr-1 mt-2 align-self-center ${this.state["disbaleEngineOptions_2_"+item] ? "text-issues" : !this.state["enableEngineOptions_2_"+item] ? "text-gray3" : "text-white"}`}>version</p>
																		</div>
																		{(!this.state["selected_engine_version_type_"+item] || this.state["selected_engine_version_type_"+item] !== "Latest") ?
																			<div className={`d-flex flex-wrap mb-2`}>
																				<div className={`form-check form-check-inline align-self-center mt-2 mr-2 ${this.state["enableEngineOptions_3_"+item] ? "" : "checkboxGrayColor"}`}>
																					<input
																						className='form-check-input m-0'
																						type='checkbox'
																						checked={this.state["enableEngineOptions_3_"+item]}
																						onChange={e => this.setState({ ["enableEngineOptions_3_"+item]: !this.state["enableEngineOptions_3_"+item]})}	
																						title={this.state["enableEngineOptions_3_"+item] ? "not enable" : "enable"}								
																					/>
																					{!this.state["enableEngineOptions_3_"+item] ?
																						<span class="checkmark" onClick={e => this.setState({ ["enableEngineOptions_3_"+item]: !this.state["enableEngineOptions_3_"+item]})}></span>
																					: null}
																				</div>
																				<span className={`fas fa-ban align-self-center mt-2 mr-2 ${this.state["disbaleEngineOptions_3_"+item] ? "text-issues" : "text-gray3"}`} onClick={() => this.setState({ ["disbaleEngineOptions_3_"+item]: !this.state["disbaleEngineOptions_3_"+item] })} title={this.state["disbaleEngineOptions_3_"+item] ? "disable" : "not disbale"}></span>

																				<p className={`mb-0 ml-2 mr-1 mt-2 align-self-center ${this.state["disbaleEngineOptions_3_"+item] ? "text-issues" : !this.state["enableEngineOptions_3_"+item] ? "text-gray3" : "text-white"}`}>Allowed Db engine version</p>

																				<Select
																					placeholder={'Select'}
																					isSearchable={true}
																					components={{
																						IndicatorSeparator: () => null
																					}}
																					className={`selectOption mr-1`}
																					value={({
																						value: this.state["selected_engine_version_"+item] && this.state["selected_engine_version_"+item],
																						label: this.state["selected_engine_version_"+item]  ? this.state["selected_engine_version_"+item] : <span className="placeholder">Select</span>
																					})}		
																					options={this.state.engineVersions && this.state.engineVersions.map(row => ({
																						value: row,
																						label: row
																					}))}															
																					onChange={event => this.setState({ ["selected_engine_version_"+item]: event.value })}
																				/>
																				<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Security </span>
																				<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Availability </span>
																				{this.state["disbaleOptions_1_"+item] ?
																					<span className="text-issues ml-4 align-self-center"> (disabled) </span>
																				: null}
																			</div>
																		: null}
																	</div>
																: null}
															</div>
															{this.state.totalEnginePolicy.length === (index+1) ?
																<span className="text-info mt-2 ml-3 mb-5 cursorPointer" onClick={() => this.addPolicies("totalEnginePolicy", "show_engine_policy", item+1)}>+ Add new Engine policy</span>
															: null}
															</React.Fragment>
															)
														})
													: null}
												</div>

												<div className={`card bg-dark border-0 mb-2 overflow-unset`}>
													<div className="d-flex justify-content-between pb-2 mb-2 text-white border-bottom">
														{/* <span className={`far fa-${this.state.showLogPolicies ? "minus-circle" : "plus-circle"} mr-2 align-self-center`} onClick={() => this.setState({ showLogPolicies: !this.state.showLogPolicies, ["show_policy_0"]: true })}></span> */}
														<h5 className="mb-0 text-warning cursorPointer" onClick={() => this.setState({ showLogPolicies: !this.state.showLogPolicies, ["show_policy_0"]: true })}>Log Policies</h5>
														<div className="d-flex">
															<span className="text-white mr-3">3 enabled</span>
															<span className="text-gray3 mr-3">1 not-enabled</span>
															<span className="text-issues mr-3">1 disabled</span>
															<span className={`ml-4 fa fa-angle-${this.state.showLogPolicies ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.setState({ showLogPolicies: !this.state.showLogPolicies, ["show_policy_0"]: true })}></span>
														</div>
													</div>
													{this.state.showLogPolicies ?
														this.state.totalLogPolicy && this.state.totalLogPolicy.map((item, index) => {
															return(
															<React.Fragment>
															<div className={`py-3 mb-2 border-bottom`}>
																{this.state.totalLogPolicy && this.state.totalLogPolicy.length ?
																	<div className={`d-flex justify-content-between pl-2`}>
																		{(!this.state["selected_log_type_"+item] || this.state["selected_log_type_"+item] === "") && (!this.state["selected_log_destination_"+item] || this.state["selected_log_destination_"+item] === "") && (!this.state["retentionPeriod_"+item] || this.state["retentionPeriod_"+item] === "") && (!this.state["logLimit_"+item] || this.state["logLimit_"+item] === "") ?
																			<p className={`mb-0 mx-2 align-self-center mb-1 text-primary-color`}>Choose policy details</p>
																		:
																			<p className={`mb-0 mx-2 align-self-center mb-1 text-white`}>
																				{this.state["selected_log_type_"+item] && this.state["selected_log_type_"+item] !== "" ?
																					<React.Fragment>
																						<span className="mr-2">Type of log</span>
																						<span className="mr-2 text-info">{this.state["selected_log_type_"+item]}</span>
																					</React.Fragment>
																				: null}
																				{this.state["selected_log_destination_"+item] && this.state["selected_log_destination_"+item] !== "" ?
																					<React.Fragment>
																						<span className="mr-2">Log to be stored in the destination</span>
																						<span className="mr-2 text-info">{this.state["selected_log_destination_"+item]}</span>
																					</React.Fragment>
																				: null}
																				{this.state["retentionPeriod_"+item] && this.state["retentionPeriod_"+item] !== "" ?
																					<React.Fragment>
																						<span className="mr-2">Log retention period</span>
																						<span className="mr-2 text-info">{this.state["retentionPeriod_"+item]}</span>
																					</React.Fragment>
																				: null}
																				{this.state["logLimit_"+item] && this.state["logLimit_"+item] !== "" ?
																					<React.Fragment>
																						<span className="mr-2">Log limit</span>
																						<span className="mr-1 text-info">{this.state["logLimit_"+item]}</span>
																					</React.Fragment>
																				: null}
																				{this.state["selected_log_limit_type_"+item] && this.state["selected_log_limit_type_"+item] !== "" ?
																					<React.Fragment>
																						{/* <span className="mr-2">Log unit</span> */}
																						<span className="mr-2 text-info">{this.state["selected_log_limit_type_"+item]}</span>
																					</React.Fragment>
																				: null}
																			</p>
																		}

																		{!this.state["show_policy_"+item] ?
																			<div className="d-flex">
																				<i className="fas fa-pencil text-primary-color mt-1 mr-3 f14" onClick={() => this.showPolicy("totalLogPolicy", "show_policy", item)}></i>
																				<span className={`fa fa-angle-${this.state["show_policy_"+item] ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.showPolicy("totalLogPolicy", "show_policy", item)}></span>
																			</div>
																		: 
																			<span className={`fa fa-angle-${this.state["show_policy_"+item] ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.showPolicy("totalLogPolicy", "show_policy", item)}></span>
																		}
																	</div>
																: null}
																{this.state["show_policy_"+item] ?
																	<div className={`pl-5`}>
																		<div className={`d-flex flex-wrap mb-2`}>
																			<div className={`form-check form-check-inline align-self-center mt-2 mr-2 ${this.state["enableOptions_1_"+item] ? "" : "checkboxGrayColor"}`}>
																				<input
																					className='form-check-input m-0'
																					type='checkbox'
																					checked={this.state["enableOptions_1_"+item]}
																					onChange={e => this.setState({ ["enableOptions_1_"+item]: !this.state["enableOptions_1_"+item]})}	
																					title={this.state["enableOptions_1_"+item] ? "not enable" : "enable"}								
																				/>
																				{!this.state["enableOptions_1_"+item] ?
																					<span class="checkmark" onClick={e => this.setState({ ["enableOptions_1_"+item]: !this.state["enableOptions_1_"+item]})}></span>
																				: null}
																			</div>
																			<span className={`fas fa-ban align-self-center mt-2 mr-2 ${this.state["disbaleOptions_1_"+item] ? "text-issues" : "text-gray3"}`} onClick={() => this.setState({ ["disbaleOptions_1_"+item]: !this.state["disbaleOptions_1_"+item] })} title={this.state["disbaleOptions_1_"+item] ? "disable" : "not disbale"}></span>

																			<p className={`mb-0 ml-2 mr-1 mt-2 align-self-center ${this.state["disbaleOptions_1_"+item] ? "text-issues" : !this.state["enableOptions_1_"+item] ? "text-gray3" : "text-white"}`}>Type of log</p>

																			<Select
																				placeholder={'Select'}
																				isSearchable={true}
																				components={{
																					IndicatorSeparator: () => null
																				}}
																				className={`selectOption mr-1`}
																				value={({
																					value: this.state["selected_log_type_"+item] && this.state["selected_log_type_"+item],
																					label: this.state["selected_log_type_"+item]  ? this.state["selected_log_type_"+item] : <span className="placeholder">Select</span>
																				})}		
																				options={this.state.logTypeOptions && this.state.logTypeOptions.map(row => ({
																					value: row,
																					label: row
																				}))}															
																				onChange={event => this.setState({ ["selected_log_type_"+item]: event.value })}
																			/>
																			<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Security </span>
																			<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Availability </span>
																			{this.state["disbaleOptions_1_"+item] ?
																				<span className="text-issues ml-4 align-self-center"> (disabled) </span>
																			: null}
																		</div>
																		<div className={`d-flex flex-wrap mb-2`}>
																			<div className={`w-fit-content d-flex flex-wrap`}>
																				<div className={`form-check form-check-inline align-self-center mt-2 mr-2 ${this.state["enableOptions_2_"+item] ? "" : "checkboxGrayColor"}`}>
																					<input
																						className='form-check-input m-0'
																						type='checkbox'
																						checked={this.state["enableOptions_2_"+item]}
																						onChange={e => this.setState({ ["enableOptions_2_"+item]: !this.state["enableOptions_2_"+item]})}	
																						title={this.state["enableOptions_2_"+item] ? "not enable" : "enable"}								
																					/>
																					{!this.state["enableOptions_2_"+item] ?
																						<span class="checkmark" onClick={e => this.setState({ ["enableOptions_2_"+item]: !this.state["enableOptions_2_"+item]})}></span>
																					: null}
																				</div>
																				<span className={`fas fa-ban align-self-center mt-2 mr-2 ${this.state["disbaleOptions_2_"+item] ? "text-issues" : "text-gray3"}`} onClick={() => this.setState({ ["disbaleOptions_2_"+item]: !this.state["disbaleOptions_2_"+item] })} title={this.state["disbaleOptions_2_"+item] ? "disable" : "not disbale"}></span>

																				<p className={`mb-0 ml-2 mr-1 mt-2 align-self-center ${this.state["disbaleOptions_2_"+item] ? "text-issues" : !this.state["enableOptions_2_"+item] ? "text-gray3" : "text-white"}`}>Log to be stored in the destination</p>

																				<Select
																					placeholder={'Select'}
																					isSearchable={true}
																					components={{
																						IndicatorSeparator: () => null
																					}}
																					className={`selectOption mr-2`}
																					value={({
																						value: this.state["selected_log_destination_"+item] && this.state["selected_log_destination_"+item],
																						label: this.state["selected_log_destination_"+item]  ? this.state["selected_log_destination_"+item] : <span className="placeholder">Select</span>
																					})}		
																					options={this.state.logDestination && this.state.logDestination.map(row => ({
																						value: row,
																						label: row
																					}))}															
																					onChange={event => this.setState({ ["selected_log_destination_"+item]: event.value })}
																				/>
																				
																				<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Compute </span>
																				<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Lambda </span>
																				<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Database </span>
																				<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > EC2 </span>
																				{this.state["disbaleOptions_2_"+item] ?
																				<span className="text-issues ml-4 align-self-center"> (disabled) </span>
																			: null}
																			</div>
																		</div>
																		<div className={`d-flex flex-wrap mb-2`}>
																			<div className={`form-check form-check-inline align-self-center mt-2 mr-2 ${this.state["enableOptions_3_"+item] ? "" : "checkboxGrayColor"}`}>
																				<input
																					className='form-check-input m-0'
																					type='checkbox'
																					checked={this.state["enableOptions_3_"+item]}
																					onChange={e => this.setState({ ["enableOptions_3_"+item]: !this.state["enableOptions_3_"+item]})}	
																					title={this.state["enableOptions_3_"+item] ? "not enable" : "enable"}								
																				/>
																				{!this.state["enableOptions_3_"+item] ?
																					<span class="checkmark" onClick={e => this.setState({ ["enableOptions_3_"+item]: !this.state["enableOptions_3_"+item]})}></span>
																				: null}
																			</div>
																			
																			<span className={`fas fa-ban align-self-center mt-2 mr-2 ${this.state["disbaleOptions_3_"+item] ? "text-issues" : "text-gray3"}`} onClick={() => this.setState({ ["disbaleOptions_3_"+item]: !this.state["disbaleOptions_3_"+item] })} title={this.state["disbaleOptions_3_"+item] ? "disable" : "not disbale"}></span>
																			
																			<p className={`mb-0 ml-2 mr-1 mt-2 align-self-center ${this.state["disbaleOptions_3_"+item] ? "text-issues" : !this.state["enableOptions_3_"+item] ? "text-gray3" : "text-white"}`}>Log retention period</p>

																			<input 
																				type="text" 
																				className={`inputField w-15`}
																				style={{minHeight: "38px"}}
																				placeholder={"Enter retention period"}
																				value={this.state["retentionPeriod_"+item]}
																				onChange={e => this.setState({ ["retentionPeriod_"+item]: e.target.value })}
																			/>
																				
																			<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Security </span>
																			<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Lambda </span>
																			{this.state["disbaleOptions_3_"+item] ?
																				<span className="text-issues ml-4 align-self-center"> (disabled) </span>
																			: null}
																		</div>
																		<div className={`d-flex flex-wrap mb-2`}>
																			<div className={`w-fit-content d-flex flex-wrap text-white`}>
																				<div className={`form-check form-check-inline align-self-center mt-2 mr-2 ${this.state["enableOptions_4_"+item] ? "" : "checkboxGrayColor"}`}>
																					<input
																						className='form-check-input m-0'
																						type='checkbox'
																						checked={this.state["enableOptions_4_"+item]}
																						onChange={e => this.setState({ ["enableOptions_4_"+item]: !this.state["enableOptions_4_"+item]})}	
																						title={this.state["enableOptions_4_"+item] ? "not enable" : "enable"}								
																					/>
																					{!this.state["enableOptions_4_"+item] ?
																						<span class="checkmark" onClick={e => this.setState({ ["enableOptions_4_"+item]: !this.state["enableOptions_4_"+item]})}></span>
																					: null}
																				</div>
																				<span className={`fas fa-ban align-self-center mt-2 mr-2 ${this.state["disbaleOptions_4_"+item] ? "text-issues" : "text-gray3"}`} onClick={() => this.setState({ ["disbaleOptions_4_"+item]: !this.state["disbaleOptions_4_"+item] })} title={this.state["disbaleOptions_4_"+item] ? "disable" : "not disbale"}></span>
																				
																				<p className={`mb-0 ml-2 mr-1 mt-2 align-self-center ${this.state["disbaleOptions_4_"+item] ? "text-issues" : !this.state["enableOptions_4_"+item] ? "text-gray3" : "text-white"}`}>Log encryption type</p>
																				<Select
																					placeholder={'Select'}
																					isSearchable={true}
																					components={{
																						IndicatorSeparator: () => null
																					}}
																					isDisabled={this.state["disbaleOptions_4_"+item] ? true : ""}
																					className={`selectOption mr-2`}
																					value={({
																						value: this.state["selected_log_encryption_"+item] && this.state["selected_log_encryption_"+item],
																						label: this.state["selected_log_encryption_"+item]  ? this.state["selected_log_encryption_"+item] : <span className="placeholder">Select</span>
																					})}		
																					options={this.state.logEncryptionType && this.state.logEncryptionType.map(row => ({
																						value: row,
																						label: row
																					}))}															
																					onChange={event => this.setState({ ["selected_log_encryption_"+item]: event.value })}
																				/>
																				<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Lambda </span>
																				<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Security </span>
																				{this.state["disbaleOptions_4_"+item] ?
																				<span className="text-issues ml-4 align-self-center"> (disabled) </span>
																			: null}
																			</div>
																		</div>
																		<div className={`d-flex flex-wrap mb-2`}>
																			<div className={`form-check form-check-inline align-self-center mt-2 mr-2 ${this.state["enableOptions_5_"+item] ? "" : "checkboxGrayColor"}`}>
																				<input
																					className='form-check-input m-0'
																					type='checkbox'
																					checked={this.state["enableOptions_5_"+item]}
																					onChange={e => this.setState({ ["enableOptions_5_"+item]: !this.state["enableOptions_5_"+item]})}	
																					title={this.state["enableOptions_5_"+item] ? "not enable" : "enable"}								
																				/>
																				{!this.state["enableOptions_5_"+item] ?
																					<span class="checkmark" onClick={e => this.setState({ ["enableOptions_5_"+item]: !this.state["enableOptions_5_"+item]})}></span>
																				: null}
																			</div>
																			<span className={`fas fa-ban align-self-center mt-2 mr-2 ${this.state["disbaleOptions_5_"+item] ? "text-issues" : "text-gray3"}`} onClick={() => this.setState({ ["disbaleOptions_5_"+item]: !this.state["disbaleOptions_5_"+item] })} title={this.state["disbaleOptions_5_"+item] ? "disable" : "not disbale"}></span>
																			
																			<p className={`mb-0 ml-2 mr-1 mt-2 align-self-center ${this.state["disbaleOptions_5_"+item] ? "text-issues" : !this.state["enableOptions_5_"+item] ? "text-gray3" : "text-white"}`}>Log limit</p>

																			<input 
																				type="text" 
																				className={`inputField w-15 mr-1`}
																				style={{minHeight: "38px"}}
																				placeholder={"Enter log limit"}
																				value={this.state["logLimit_"+item]}
																				onChange={e => this.setState({ ["logLimit_"+item]: e.target.value })}
																			/>
																			<p className={`mb-0 ml-2 mr-1 mt-2 align-self-center ${this.state["disbaleOptions_5_"+item] ? "text-issues" : !this.state["enableOptions_5_"+item] ? "text-gray3" : "text-white"}`}>log unit</p>
																			<Select
																				placeholder={'Select'}
																				isSearchable={true}
																				components={{
																					IndicatorSeparator: () => null
																				}}
																				className={`selectOption mr-1`}
																				value={({
																					value: this.state["selected_log_limit_type_"+item] && this.state["selected_log_limit_type_"+item],
																					label: this.state["selected_log_limit_type_"+item]  ? this.state["selected_log_limit_type_"+item] : <span className="placeholder">Select</span>
																				})}		
																				options={this.state.sizeType && this.state.sizeType.map(row => ({
																					value: row,
																					label: row
																				}))}															
																				onChange={event => this.setState({ ["selected_log_limit_type_"+item]: event.value })}
																			/>

																			<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Security </span>
																			<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Lambda </span>
																			{this.state["disbaleOptions_5_"+item] ?
																				<span className="text-issues ml-4 align-self-center"> (disabled) </span>
																			: null}
																		</div>
																	</div>
																: null}
															</div>
															{this.state.totalLogPolicy.length === (index+1) ?
																<span className="text-info mt-2 ml-3 mb-5 cursorPointer" onClick={() => this.addPolicies("totalLogPolicy", "show_policy", item+1)}>+ Add new log policy</span>
															: null}
															</React.Fragment>
															)
														})
													: null}
												</div>

												<div className={`card bg-dark border-0 mb-2 overflow-unset`}>
													<div className="d-flex justify-content-between pb-2 mb-2 text-white border-bottom">
														<h5 className="mb-0 text-warning cursorPointer" onClick={() => this.setState({ showBlockstorePolicies: !this.state.showBlockstorePolicies, ["show_blockstore_policy_0"]: true })}>Blockstore Policies</h5>
														<div className="d-flex">
															<span className="text-white mr-3">1 enabled</span>
															<span className="text-gray3 mr-3">2 not-enabled</span>
															<span className="text-issues mr-3">1 disablered</span>
															<span className={`ml-4 fa fa-angle-${this.state.showBlockstorePolicies ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.setState({ showBlockstorePolicies: !this.state.showBlockstorePolicies, ["show_blockstore_policy_0"]: true })}></span>
														</div>
													</div>
													{this.state.showBlockstorePolicies ?
														this.state.totalBlockStorePolicy && this.state.totalBlockStorePolicy.map((item, index) => {
															return(
															<React.Fragment>
															<div className={`py-3 mb-2 border-bottom`}>
																{this.state.totalBlockStorePolicy && this.state.totalBlockStorePolicy.length ?
																	<div className={`d-flex justify-content-between pl-2`}>
																		{(!this.state["selected_blockstore_type_"+item] || this.state["selected_blockstore_type_"+item] === "") && (!this.state["total_volume_"+item] || this.state["total_volume_"+item] === "") && (!this.state["total_size_volume_"+item] || this.state["total_size_volume_"+item] === "") && (!this.state["max_volume_"+item] || this.state["max_volume_"+item] === "") ?
																			<p className={`mb-0 mx-2 align-self-center mb-1 text-primary-color`}>Choose policy details</p>
																		:
																			<React.Fragment>
																			<p className={`mb-0 mx-2 align-self-center mb-1 text-white`}>
																				<span className="mr-2 text-info">Volume Size: </span>
																				{this.state["selected_blockstore_type_"+item] && this.state["selected_blockstore_type_"+item] !== "" ?
																					<React.Fragment>
																						<span className="mr-2">Allowed blockstore type</span>
																						<span className="mr-2 text-info">{this.state["selected_blockstore_type_"+item]}</span>
																					</React.Fragment>
																				: null}
																				{this.state["total_volume_"+item] && this.state["total_volume_"+item] && this.state["total_volume_"+item] !== "" ?
																					<React.Fragment>
																						<span className="mr-2">its total volume</span>
																						<span className="mr-2 text-info">{this.state["total_volume_"+item]}</span>
																					</React.Fragment>
																				: null}
																				{this.state["total_size_volume_"+item] && this.state["total_size_volume_"+item] && this.state["total_size_volume_"+item] !== "" ?
																					<React.Fragment>
																						<span className="mr-2">its total volume</span>
																						<span className="mr-2 text-info">{this.state["total_size_volume_"+item]}</span>
																						{this.state["selected_total_size_volume_unit_"+item] && this.state["selected_total_size_volume_unit_"+item] !== "" ?
																							<span className="mr-2 text-info">{this.state["selected_total_size_volume_unit_"+item]}</span>
																						: null}
																					</React.Fragment>
																				: null}
																				{this.state["max_volume_"+item] && this.state["max_volume_"+item] && this.state["max_volume_"+item] !== "" ?
																					<React.Fragment>
																						<span className="mr-2">its total volume</span>
																						<span className="mr-2 text-info">{this.state["max_volume_"+item]}</span>
																						{this.state["selected_max_volume_unit_"+item] && this.state["selected_max_volume_unit_"+item] !== "" ?
																							<span className="mr-2 text-info">{this.state["selected_max_volume_unit_"+item]}</span>
																						: null}
																					</React.Fragment>
																				: null}
																			</p>
																			<p className={`mb-0 mx-2 mt-2 align-self-center mb-1 text-white`}>
																				{this.state["selected_blockstore_type_"+item] && (this.state["selected_blockstore_type_"+item] === "Provisioned IOPS SSD (io1)" || this.state["selected_blockstore_type_"+item] === "Provisioned IOPS SSD (io2)") ?
																					<React.Fragment>
																					{this.state["total_piops_"+item] && this.state["total_piops_"+item] && this.state["total_piops_"+item] !== "" ?
																						<React.Fragment>
																						<span className="mr-2 text-info">PIOPS Configuration: </span>
																						<span className="mr-2">total piops</span>
																						<span className="mr-2 text-info">{this.state["total_piops_"+item]}</span>
																						</React.Fragment>
																					: null}
																					{this.state["max_piops_"+item] && this.state["max_piops_"+item] && this.state["max_piops_"+item] !== "" ?
																						<React.Fragment>
																						<span className="mr-2">max piops per volume</span>
																						<span className="mr-2 text-info">{this.state["max_piops_"+item]}</span>
																						</React.Fragment>
																					: null}
																					</React.Fragment>
																				: null}
																			</p>
																			</React.Fragment>
																		}

																		{!this.state["show_blockstore_policy_"+item] ?
																			<div className="d-flex">
																				<i className="fas fa-pencil text-primary-color mt-1 mr-3 f14" onClick={() => this.showPolicy("totalBlockStorePolicy", "show_blockstore_policy", item)}></i>
																				<span className={`fa fa-angle-${this.state["show_blockstore_policy_"+item] ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.showPolicy("totalBlockStorePolicy", "show_blockstore_policy", item)}></span>
																			</div>
																		: 
																			<span className={`fa fa-angle-${this.state["show_blockstore_policy_"+item] ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.showPolicy("totalBlockStorePolicy", "show_blockstore_policy", item)}></span>
																		}
																	</div>
																: null}
																{this.state["show_blockstore_policy_"+item] ?
																	<div className={`pl-5`}>
																		<div className={`d-flex flex-wrap mb-2`}>
																			<div className={`form-check form-check-inline align-self-center mt-2 mr-2 ${this.state["enableBlockstoreOptions_1_"+item] ? "" : "checkboxGrayColor"}`}>
																				<input
																					className='form-check-input m-0'
																					type='checkbox'
																					checked={this.state["enableBlockstoreOptions_1_"+item]}
																					onChange={e => this.setState({ ["enableBlockstoreOptions_1_"+item]: !this.state["enableBlockstoreOptions_1_"+item]})}	
																					title={this.state["enableBlockstoreOptions_1_"+item] ? "not enable" : "enable"}								
																				/>
																				{!this.state["enableBlockstoreOptions_1_"+item] ?
																					<span class="checkmark" onClick={e => this.setState({ ["enableBlockstoreOptions_1_"+item]: !this.state["enableBlockstoreOptions_1_"+item]})}></span>
																				: null}
																			</div>
																			<span className={`fas fa-ban align-self-center mt-2 mr-2 ${this.state["disbaleBlockstoreOptions_1_"+item] ? "text-issues" : "text-gray3"}`} onClick={() => this.setState({ ["disbaleBlockstoreOptions_1_"+item]: !this.state["disbaleBlockstoreOptions_1_"+item] })} title={this.state["disbaleBlockstoreOptions_1_"+item] ? "disable" : "not disbale"}></span>

																			<p className={`mb-0 ml-2 mr-1 mt-2 align-self-center ${this.state["disbaleBlockstoreOptions_1_"+item] ? "text-issues" : !this.state["enableBlockstoreOptions_1_"+item] ? "text-gray3" : "text-white"}`}>Allowed Blockstore type</p>

																			<Select
																				placeholder={'Select'}
																				isSearchable={true}
																				components={{
																					IndicatorSeparator: () => null
																				}}
																				className={`selectOption mr-1`}
																				value={({
																					value: this.state["selected_blockstore_type_"+item] && this.state["selected_blockstore_type_"+item],
																					label: this.state["selected_blockstore_type_"+item]  ? this.state["selected_blockstore_type_"+item] : <span className="placeholder">Select</span>
																				})}		
																				options={this.state.blockstoreTypes && this.state.blockstoreTypes.map(row => ({
																					value: row,
																					label: row
																				}))}															
																				onChange={event => this.setState({ ["selected_blockstore_type_"+item]: event.value })}
																			/>
																			<i class="fas fa-circle-xmark"></i>
																			<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Security </span>
																			<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Availability </span>
																			{this.state["disbaleBlockstoreOptions_1_"+item] ?
																				<span className="text-issues ml-4 align-self-center"> (disabled) </span>
																			: null}
																		</div>
																		<p className="my-2 ml-4 f16 text-info">Volume Size</p>
																		<div className="ml-4">
																			<div className={`d-flex flex-wrap mb-2`}>
																				<div className={`form-check form-check-inline align-self-center mt-2 mr-2 ${this.state["enableBlockstoreOptions_2_"+item] ? "" : "checkboxGrayColor"}`}>
																					<input
																						className='form-check-input m-0'
																						type='checkbox'
																						checked={this.state["enableBlockstoreOptions_2_"+item]}
																						onChange={e => this.setState({ ["enableBlockstoreOptions_2_"+item]: !this.state["enableBlockstoreOptions_2_"+item]})}	
																						title={this.state["enableBlockstoreOptions_2_"+item] ? "not enable" : "enable"}								
																					/>
																					{!this.state["enableBlockstoreOptions_2_"+item] ?
																						<span class="checkmark" onClick={e => this.setState({ ["enableBlockstoreOptions_2_"+item]: !this.state["enableBlockstoreOptions_2_"+item]})}></span>
																					: null}
																				</div>
																				<span className={`fas fa-ban align-self-center mt-2 mr-2 ${this.state["disbaleBlockstoreOptions_2_"+item] ? "text-issues" : "text-gray3"}`} onClick={() => this.setState({ ["disbaleBlockstoreOptions_2_"+item]: !this.state["disbaleBlockstoreOptions_2_"+item] })} title={this.state["disbaleBlockstoreOptions_2_"+item] ? "disable" : "not disbale"}></span>

																				<p className={`mb-0 ml-2 mr-1 mt-2 align-self-center ${this.state["disbaleBlockstoreOptions_2_"+item] ? "text-issues" : !this.state["enableBlockstoreOptions_2_"+item] ? "text-gray3" : "text-white"}`}>Total volume</p>
																				<input 
																					type="text" 
																					className={`inputField w-15`}
																					style={{minHeight: "38px"}}
																					placeholder={"Enter details"}
																					value={this.state["total_volume_"+item]}
																					onChange={e => this.setState({ ["total_volume_"+item]: e.target.value })}
																				/>
																				<i class="fas fa-circle-xmark"></i>
																				<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Security </span>
																				<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Availability </span>
																				{this.state["disbaleBlockstoreOptions_2_"+item] ?
																					<span className="text-issues ml-4 align-self-center"> (disabled) </span>
																				: null}
																			</div>
																			<div className={`d-flex flex-wrap mb-2`}>
																				<div className={`form-check form-check-inline align-self-center mt-2 mr-2 ${this.state["enableBlockstoreOptions_3_"+item] ? "" : "checkboxGrayColor"}`}>
																					<input
																						className='form-check-input m-0'
																						type='checkbox'
																						checked={this.state["enableBlockstoreOptions_3_"+item]}
																						onChange={e => this.setState({ ["enableBlockstoreOptions_3_"+item]: !this.state["enableBlockstoreOptions_3_"+item]})}	
																						title={this.state["enableBlockstoreOptions_3_"+item] ? "not enable" : "enable"}								
																					/>
																					{!this.state["enableBlockstoreOptions_3_"+item] ?
																						<span class="checkmark" onClick={e => this.setState({ ["enableBlockstoreOptions_3_"+item]: !this.state["enableBlockstoreOptions_3_"+item]})}></span>
																					: null}
																				</div>
																				<span className={`fas fa-ban align-self-center mt-2 mr-2 ${this.state["disbaleBlockstoreOptions_3_"+item] ? "text-issues" : "text-gray3"}`} onClick={() => this.setState({ ["disbaleBlockstoreOptions_3_"+item]: !this.state["disbaleBlockstoreOptions_3_"+item] })} title={this.state["disbaleBlockstoreOptions_3_"+item] ? "disable" : "not disbale"}></span>

																				<p className={`mb-0 ml-2 mr-1 mt-2 align-self-center ${this.state["disbaleBlockstoreOptions_3_"+item] ? "text-issues" : !this.state["enableBlockstoreOptions_3_"+item] ? "text-gray3" : "text-white"}`}>Total volume size</p>
																				<input 
																					type="text" 
																					className={`inputField w-15`}
																					style={{minHeight: "38px"}}
																					placeholder={"Enter details"}
																					value={this.state["total_size_volume_"+item]}
																					onChange={e => this.setState({ ["total_size_volume_"+item]: e.target.value })}
																				/>

																				<p className={`mb-0 ml-2 mr-1 mt-2 align-self-center ${this.state["disbaleBlockstoreOptions_3_"+item] ? "text-issues" : !this.state["enableBlockstoreOptions_3_"+item] ? "text-gray3" : "text-white"}`}>in unit</p>
																				<Select
																					placeholder={'Select'}
																					isSearchable={true}
																					components={{
																						IndicatorSeparator: () => null
																					}}
																					className={`selectOption mr-1`}
																					value={({
																						value: this.state["selected_total_size_volume_unit_"+item] && this.state["selected_total_size_volume_unit_"+item],
																						label: this.state["selected_total_size_volume_unit_"+item]  ? this.state["selected_total_size_volume_unit_"+item] : <span className="placeholder">Select</span>
																					})}		
																					options={this.state.sizeType && this.state.sizeType.map(row => ({
																						value: row,
																						label: row
																					}))}															
																					onChange={event => this.setState({ ["selected_total_volume_unit_"+item]: event.value })}
																				/>
																				<i class="fas fa-circle-xmark"></i>
																				<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Security </span>
																				<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Availability </span>
																				{this.state["disbaleBlockstoreOptions_3_"+item] ?
																					<span className="text-issues ml-4 align-self-center"> (disabled) </span>
																				: null}
																			</div>
																						
																			<div className={`d-flex flex-wrap mb-2`}>
																			<div className={`form-check form-check-inline align-self-center mt-2 mr-2 ${this.state["enableBlockstoreOptions_4_"+item] ? "" : "checkboxGrayColor"}`}>
																				<input
																					className='form-check-input m-0'
																					type='checkbox'
																					checked={this.state["enableBlockstoreOptions_4_"+item]}
																					onChange={e => this.setState({ ["enableBlockstoreOptions_4_"+item]: !this.state["enableBlockstoreOptions_4_"+item]})}	
																					title={this.state["enableBlockstoreOptions_4_"+item] ? "not enable" : "enable"}								
																				/>
																				{!this.state["enableBlockstoreOptions_4_"+item] ?
																					<span class="checkmark" onClick={e => this.setState({ ["enableBlockstoreOptions_4_"+item]: !this.state["enableBlockstoreOptions_4_"+item]})}></span>
																				: null}
																			</div>
																			<span className={`fas fa-ban align-self-center mt-2 mr-2 ${this.state["disbaleBlockstoreOptions_4_"+item] ? "text-issues" : "text-gray3"}`} onClick={() => this.setState({ ["disbaleBlockstoreOptions_4_"+item]: !this.state["disbaleBlockstoreOptions_4_"+item] })} title={this.state["disbaleBlockstoreOptions_4_"+item] ? "disable" : "not disbale"}></span>

																			<p className={`mb-0 ml-2 mr-1 mt-2 align-self-center ${this.state["disbaleBlockstoreOptions_4_"+item] ? "text-issues" : !this.state["enableBlockstoreOptions_4_"+item] ? "text-gray3" : "text-white"}`}>Max Size per volume</p>
																			<input 
																				type="text" 
																				className={`inputField w-15`}
																				style={{minHeight: "38px"}}
																				placeholder={"Enter details"}
																				value={this.state["max_volume_"+item]}
																				onChange={e => this.setState({ ["max_volume_"+item]: e.target.value })}
																			/>

																			<p className={`mb-0 ml-2 mr-1 mt-2 align-self-center ${this.state["disbaleBlockstoreOptions_4_"+item] ? "text-issues" : !this.state["enableBlockstoreOptions_4_"+item] ? "text-gray3" : "text-white"}`}>in unit</p>
																			<Select
																				placeholder={'Select'}
																				isSearchable={true}
																				components={{
																					IndicatorSeparator: () => null
																				}}
																				className={`selectOption mr-1`}
																				value={({
																					value: this.state["selected_max_volume_unit_"+item] && this.state["selected_max_volume_unit_"+item],
																					label: this.state["selected_max_volume_unit_"+item]  ? this.state["selected_max_volume_unit_"+item] : <span className="placeholder">Select</span>
																				})}		
																				options={this.state.sizeType && this.state.sizeType.map(row => ({
																					value: row,
																					label: row
																				}))}															
																				onChange={event => this.setState({ ["selected_max_volume_unit_"+item]: event.value })}
																			/>
																			<i class="fas fa-circle-xmark"></i>
																			<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Security </span>
																			<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Availability </span>
																			{this.state["disbaleBlockstoreOptions_4_"+item] ?
																				<span className="text-issues ml-4 align-self-center"> (disabled) </span>
																			: null}
																		</div>
																		</div>
																		{this.state["selected_blockstore_type_"+item] && (this.state["selected_blockstore_type_"+item] === "Provisioned IOPS SSD (io1)" || this.state["selected_blockstore_type_"+item] === "Provisioned IOPS SSD (io2)") ?
																			<React.Fragment>
																			<p className="my-2 ml-4 f16 text-info">PIOPS Configuration</p>
																			<div className="ml-4">
																				<div className={`d-flex flex-wrap mb-2`}>
																					<div className={`form-check form-check-inline align-self-center mt-2 mr-2 ${this.state["enablePiopsBlockstoreOptions_1_"+item] ? "" : "checkboxGrayColor"}`}>
																						<input
																							className='form-check-input m-0'
																							type='checkbox'
																							checked={this.state["enablePiopsBlockstoreOptions_1_"+item]}
																							onChange={e => this.setState({ ["enablePiopsBlockstoreOptions_1_"+item]: !this.state["enablePiopsBlockstoreOptions_1_"+item]})}	
																							title={this.state["enablePiopsBlockstoreOptions_1_"+item] ? "not enable" : "enable"}								
																						/>
																						{!this.state["enablePiopsBlockstoreOptions_1_"+item] ?
																							<span class="checkmark" onClick={e => this.setState({ ["enablePiopsBlockstoreOptions_1_"+item]: !this.state["enablePiopsBlockstoreOptions_1_"+item]})}></span>
																						: null}
																					</div>
																					<span className={`fas fa-ban align-self-center mt-2 mr-2 ${this.state["disbaleIoopsBlockstoreOptions_1_"+item] ? "text-issues" : "text-gray3"}`} onClick={() => this.setState({ ["disbaleIoopsBlockstoreOptions_1_"+item]: !this.state["disbaleIoopsBlockstoreOptions_1_"+item] })} title={this.state["disbaleIoopsBlockstoreOptions_1_"+item] ? "disable" : "not disbale"}></span>

																					<p className={`mb-0 ml-2 mr-1 mt-2 align-self-center ${this.state["disbaleIoopsBlockstoreOptions_1_"+item] ? "text-issues" : !this.state["enablePiopsBlockstoreOptions_1_"+item] ? "text-gray3" : "text-white"}`}>Total PIOPS</p>
																					<input 
																						type="text" 
																						className={`inputField w-15`}
																						style={{minHeight: "38px"}}
																						placeholder={"Enter details"}
																						value={this.state["total_piops_"+item]}
																						onChange={e => this.setState({ ["total_piops_"+item]: e.target.value })}
																					/>
																					<i class="fas fa-circle-xmark"></i>
																					<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Security </span>
																					<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Availability </span>
																					{this.state["disbaleIoopsBlockstoreOptions_1_"+item] ?
																						<span className="text-issues ml-4 align-self-center"> (disabled) </span>
																					: null}
																				</div>
																				<div className={`d-flex flex-wrap mb-2`}>
																					<div className={`form-check form-check-inline align-self-center mt-2 mr-2 ${this.state["enablePiopsBlockstoreOptions_2_"+item] ? "" : "checkboxGrayColor"}`}>
																						<input
																							className='form-check-input m-0'
																							type='checkbox'
																							checked={this.state["enablePiopsBlockstoreOptions_2_"+item]}
																							onChange={e => this.setState({ ["enablePiopsBlockstoreOptions_2_"+item]: !this.state["enablePiopsBlockstoreOptions_2_"+item]})}	
																							title={this.state["enablePiopsBlockstoreOptions_2_"+item] ? "not enable" : "enable"}								
																						/>
																						{!this.state["enablePiopsBlockstoreOptions_2_"+item] ?
																							<span class="checkmark" onClick={e => this.setState({ ["enablePiopsBlockstoreOptions_2_"+item]: !this.state["enablePiopsBlockstoreOptions_2_"+item]})}></span>
																						: null}
																					</div>
																					<span className={`fas fa-ban align-self-center mt-2 mr-2 ${this.state["disbaleIoopsBlockstoreOptions_2_"+item] ? "text-issues" : "text-gray3"}`} onClick={() => this.setState({ ["disbaleIoopsBlockstoreOptions_2_"+item]: !this.state["disbaleIoopsBlockstoreOptions_2_"+item] })} title={this.state["disbaleIoopsBlockstoreOptions_2_"+item] ? "disable" : "not disbale"}></span>

																					<p className={`mb-0 ml-2 mr-1 mt-2 align-self-center ${this.state["disbaleIoopsBlockstoreOptions_2_"+item] ? "text-issues" : !this.state["enablePiopsBlockstoreOptions_2_"+item] ? "text-gray3" : "text-white"}`}>Max PIOPS per volume</p>
																					<input 
																						type="text" 
																						className={`inputField w-15`}
																						style={{minHeight: "38px"}}
																						placeholder={"Enter details"}
																						value={this.state["max_piops_"+item]}
																						onChange={e => this.setState({ ["max_piops_"+item]: e.target.value })}
																					/>
																					<i class="fas fa-circle-xmark"></i>
																					<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Security </span>
																					<span className={`badge-square badge-outline-gray text-gray5 f13 align-self-center p-1 ml-2`} > Availability </span>
																					{this.state["disbaleIoopsBlockstoreOptions_2_"+item] ?
																						<span className="text-issues ml-4 align-self-center"> (disabled) </span>
																					: null}
																				</div>
																			</div>
																			</React.Fragment>
																		: null}
																	</div>
																: null}
															</div>
															{this.state.totalBlockStorePolicy.length === (index+1) ?
																<span className="text-info mt-2 ml-3 mb-5 cursorPointer" onClick={() => this.addPolicies("totalBlockStorePolicy", "show_blockstore_policy", item+1)}>+ Add new block store policy</span>
															: null}
															</React.Fragment>
															)
														})
													: null}
												</div>
											</div>
										</div>
									</div>
									</React.Fragment>
								}
							</div>

							<div className="rounded bg-dark3 p-3 border-info mt-3 dlpDescriptionForm dlpDescriptionFormPlain">
								<div className="d-flex justify-content-between">
									<p className="mb-2 text-purple3 f20 cursorPointer" onClick={() => this.setState({ showActionSection: !this.state.showActionSection })}>Actions</p>
									<span className={`ml-4 fa fa-angle-${this.state.showActionSection ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.setState({ showActionSection: !this.state.showActionSection })}></span>
								</div>
								{this.state.showActionSection ?
									<React.Fragment>
									{this.state.actionArray.map(item => {
										return(
											<div className="d-flex flex-wrap mb-2">
												<p className={`mb-0 mr-1 mt-2 align-self-center text-white`}>By which medium it should be notified</p>
												<Select
													placeholder={'Select'}
													isSearchable={true}
													components={{
														IndicatorSeparator: () => null
													}}
													className={`selectOption selectBoxBottomColor mr-1`}
													value={({
														value: this.state["selected_notfication_type_"+item] && this.state["selected_notfication_type_"+item],
														label: this.state["selected_notfication_type_"+item]  ? this.state["selected_notfication_type_"+item] : <span className="placeholder">Select</span>
													})}		
													options={this.state.notificationType && this.state.notificationType.map(row => ({
														value: row,
														label: row
													}))}															
													onChange={event => this.setState({ ["selected_notfication_type_"+item]: event.value })}
												/>
												<p className={`mb-0 mr-1 ml-2 mt-2 align-self-center text-white`}>to whom it should be shared</p>
												<div className="selectBoxBottomColor w-20">
													<input 
														type="text" 
														className={`inputField w-100`}
														style={{minHeight: "38px"}}
														placeholder={"Enter details"}
														value={this.state["shared_to_"+item]}
														onChange={e => this.setState({ ["shared_to_"+item]: e.target.value })}
													/>
												</div>
											
												<span className={`far fa-plus cursor-pointer f18 mt-2 mb-1 align-self-end mr-4`} onClick={() => this.addNewSharedDetails(item)}></span>
												{this.state["shared_details_"+item] && this.state["shared_details_"+item].length ? 
													this.state["shared_details_"+item].map((share, index) => {
														return(
															<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{share}
																<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeSharedDetails(item, share) } ></i>
															</span>
														)
													})
												: null}
											</div>
										)
									})}								
									<span className="text-info mt-2 mb-5 cursorPointer" onClick={() => this.addSection("actionArray")}>+ Add Notfications</span>
									</React.Fragment>
								: null}
							</div>

							<div className="rounded bg-dark3 p-3 border-info mt-3 dlpDescriptionForm dlpDescriptionFormPlain">
								<div className="d-flex justify-content-between">
									<p className="mb-2 text-purple3 f20 cursorPointer" onClick={() => this.setState({ showNotificationSection: !this.state.showNotificationSection })}>Notifications</p>
									<span className={`ml-4 fa fa-angle-${this.state.showNotificationSection ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.setState({ showNotificationSection: !this.state.showNotificationSection })}></span>
								</div>
								{this.state.showNotificationSection ?
									<React.Fragment>
									{this.state.notificationArray.map(item => {
										return(
											<div className="d-flex flex-wrap mb-2">
												<p className={`mb-0 mr-1 mt-2 align-self-center text-white`}>By which medium it should be notified</p>
												<Select
													placeholder={'Select'}
													isSearchable={true}
													components={{
														IndicatorSeparator: () => null
													}}
													className={`selectOption selectBoxBottomColor mr-1`}
													value={({
														value: this.state["selected_notfication_type_"+item] && this.state["selected_notfication_type_"+item],
														label: this.state["selected_notfication_type_"+item]  ? this.state["selected_notfication_type_"+item] : <span className="placeholder">Select</span>
													})}		
													options={this.state.notificationType && this.state.notificationType.map(row => ({
														value: row,
														label: row
													}))}															
													onChange={event => this.setState({ ["selected_notfication_type_"+item]: event.value })}
												/>
												<p className={`mb-0 mr-1 ml-2 mt-2 align-self-center text-white`}>to whom it should be shared</p>
												<div className="selectBoxBottomColor w-20">
													<input 
														type="text" 
														className={`inputField w-100`}
														style={{minHeight: "38px"}}
														placeholder={"Enter details"}
														value={this.state["shared_to_"+item]}
														onChange={e => this.setState({ ["shared_to_"+item]: e.target.value })}
													/>
												</div>
											
												<span className={`far fa-plus cursor-pointer f18 mt-2 mb-1 align-self-end mr-4`} onClick={() => this.addNewSharedDetails(item)}></span>
												{this.state["shared_details_"+item] && this.state["shared_details_"+item].length ? 
													this.state["shared_details_"+item].map((share, index) => {
														return(
															<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{share}
																<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeSharedDetails(item, share) } ></i>
															</span>
														)
													})
												: null}
											</div>
										)
									})}								
									<span className="text-info mt-2 mb-5 cursorPointer" onClick={() => this.addSection("notificationArray")}>+ Add Notfications</span>
									</React.Fragment>
								: null}
							</div>
						</div>
					</div>						
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
 Template.propTypes = {}

const mapStateToProps = state => {
	// console.log("Template rule new",state)
	return {
		providers: state.filters.providers,
		accounts: state.filters.accounts,
		regions: state.filters.regions,
		editNewRuleDetails: state.governance.editNewRuleDetails,
		tagKeys: state.filters.tagKeys,
	}
}

export default AppWrapper(Template, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
	listAllServices,
	setNewEditRuleDetails,
    getAllTagsKeys,
    getAllTagsValues,
	getQueries, 
	startQueryExection, 
	getQueryResult,
	dynamicCallApi,
	setResourcePropsDetails
})