/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AssetDetailPageRight.js
 * @author Prakash // on 12/04/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 * 
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { listAllProviders, listAllAccounts, listAllRegions, listAllServices, getAllTagsKeys, getAllTagsValues } from '../../../actions/commonAction'
import { getCoveredServices, listCoveredAssets } from '../../../actions/detectors/detectorsAction'
import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'
import { getMonitoringMetricsByTrend } from '../../../actions/aiops/MetricsAction'
import _ from 'lodash'

import AlertsTab from './AlertsTab'
import EventsTab from './EventsTab'
import AnomalyTab from './AnomalyTab'
import ErrorsTab from './ErrorsTab'
import ObservationTab from './ObservationTab'

class AssetDetailPageRight extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            minMaxHeight: "700px",
            pageType: "Alerts",
        }
    }

	componentDidMount = () => {
        this.getWindowSize()

        window.addEventListener('resize', () => {
            this.getWindowSize()
        })
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 20
                
		let outerHeight = (window.outerHeight - browserBorder - headerHeight ) + "px"

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }

    onChangePageType = () => {
        this.setState({ brushStartTime: "", brushEndTime: "" })
        this.props.setAiopsPropsDetails("assetBrushTimings", "reset")
    }

	render() {
		return (
            <div className="rounded bg-dark mt-2 p-3 overflowYAuto" style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
                <div className="w-100">
                    <ul className={`row nav nav-tabs border-bottom bg-dark shadow-none rounded justify-content-between`} id="myTab" role="tablist">
                        <span className={`d-flex ${this.state.menuDisabled ? "" : "" }`}>
                            <li className="nav-item">
                                <span className={`nav-link text-white cursorPointer ${this.state.pageType === 'Alerts' ? ' active' : ""}`} onClick={() => this.setState({ pageType: 'Alerts', menuDisabled: true }, () => this.onChangePageType())}>
                                    Alerts 
                                    {this.state.selectedAsset && this.state.selectedAsset.alerts ?
                                        <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">{this.state.selectedAsset.alerts}</span>
                                    : null} 
                                </span>
                            </li>
                            <li className="nav-item">
                                <span className={`nav-link text-white cursorPointer ${this.state.pageType === 'Events' ? ' active' : ""}`} onClick={() => this.setState({ pageType: 'Events', menuDisabled: true }, () => this.onChangePageType())}>
                                    Events
                                    {this.state.selectedAsset && this.state.selectedAsset.events ?
                                        <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">{this.state.selectedAsset.events}</span>
                                    : null} 
                                </span>
                            </li>
                            <li className="nav-item">
                                <span className={`nav-link text-white cursorPointer ${this.state.pageType === 'Anomalies' ? ' active' : ""}`} onClick={() => this.setState({ pageType: 'Anomalies', menuDisabled: true }, () => this.onChangePageType())}>
                                    Anomalies
                                    {this.state.selectedAsset && this.state.selectedAsset.anomalies ?
                                        <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">{this.state.selectedAsset.anomalies}</span>
                                    : null}
                                </span>
                            </li>
                            <li className="nav-item">
                                <span className={`nav-link text-white cursorPointer ${this.state.pageType === 'Errors' ? ' active' : ""}`} onClick={() => this.setState({ pageType: 'Errors', menuDisabled: true }, () => this.onChangePageType())}>
                                    Errors
                                    {this.state.selectedAsset && this.state.selectedAsset.errors ?
                                        <span class="badge risk-badge-critical f10 mx-1 align-self-center py-1">{this.state.selectedAsset.errors}</span>
                                    : null}
                                </span>
                            </li>
                            {/* {this.state.selectedMetrics && this.state.selectedMetrics !== "" ? */}
                            <li className="nav-item">
                                <span className={`nav-link text-white cursorPointer ${this.state.pageType === 'Observation' ? ' active' : ""}`} onClick={() => this.setState({ pageType: 'Observation', menuDisabled: true }, () => this.onChangePageType())}>Observation</span>
                            </li>
                            {/* : null} */}
                        </span>
                    </ul>
                    <div className={`obsSectionBodyHeight overflowYAuto`}>
                        {this.state.pageType === 'Alerts' ?
                            <AlertsTab 
                                menuDisabled={() => this.setState({ menuDisabled: false })}
                            />
                        : this.state.pageType === 'Events' ?
                            <EventsTab 
                                menuDisabled={() => this.setState({ menuDisabled: false })}
                            />
                        : this.state.pageType === 'Anomalies' ?
                            <AnomalyTab 
                                menuDisabled={() => this.setState({ menuDisabled: false })}
                            />
                        : this.state.pageType === 'Errors' ?
                            <ErrorsTab 
                                menuDisabled={() => this.setState({ menuDisabled: false })}
                            />
                        : this.state.pageType === 'Observation' ?
                            <ObservationTab
                                selectedMetrics={this.state.selectedMetrics ? this.state.selectedMetrics : ""}
                                menuDisabled={() => this.setState({ menuDisabled: false })}
                            />
                        : null}
                    </div>
                </div>
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
AssetDetailPageRight.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('observability landing page',state)
	return {
        caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : {},
    }
}

export default connect(mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
    listAllServices,
    getCoveredServices,
    listCoveredAssets,
    setAiopsPropsDetails,
    getMonitoringMetricsByTrend,
    getAllTagsKeys,
    getAllTagsValues
})(withRouter(AssetDetailPageRight))