/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file UebaDashboard.js
 * @author Prakash // on 05/06/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 **************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AppWrapper } from '../../common/AppWrapper'
import _ from 'lodash'
import { 
	listAllProviders, 
	listAllAccounts, 
	listAllRegions
} from '../../../actions/commonAction'
import {
	getDayWiseCount, 
	getComplianceControlDetails,
	getTransitionTrend,
	getThreatCoverageAndTopStats } from '../../../actions/dlp/uebaAction'

import { splitterFirstPart, splitterSecondPart, currentLocaltime, previousDay, subDays, momentDateGivenFormat, momentConvertionLocalToUtcTime, getPastDaysNew, twoDateDiffrence } from '../../../utils/utility'

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
	
import SidePanel from './SidePanel'
import SearchControls from '../SearchControls'

import Chart from 'react-apexcharts'

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";

import HorizontalLimitLollipopChart from '../../common/charts/HorizontalLimitLollipopChart'
import StackedCircleBarChart from '../../common/charts/StackedCircleBarChart'
import ApexBarChart from '../../common/charts/ApexBarChart'
import ApexRiskStackedBarChart from '../../common/charts/ApexRiskStackedBarChart'
import ApexDonutChart from '../../common/charts/ApexDonutChart'
import ApexAreaChart from '../../common/charts/ApexAreaChart'


class UebaDashboard extends Component {
	accountRef = React.createRef()
	regionRef = React.createRef()
	
	rightRadialStackedBarWidthRef = React.createRef()
	rightHorizontalLimitLollipopRef = React.createRef()
	rightSideTableRef = React.createRef()
	
	leftRadialStackedBarWidthRef = React.createRef()
	leftHorizontalLimitLollipopRef = React.createRef()
	leftSideTableRef = React.createRef()

	searchControlComponent = React.createRef()

	constructor(props) {
		super(props)
		this.state = {
			showLoading: true,
			
			providers: [],
			selectedProvider: '',

			accounts: [],
			selectedAccount: [],
			selectedAccountName: [],
			isAccountOpen: false,

			regions: [],
			selectedRegion: [],
			selectedRegionName: [],
			isRegionOpen: false,

			selectedFilterPeriod: this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.selectedFilterPeriod ? this.props.history.location.state.selectedFilterPeriod : 7,
			selectedCompliance: 'dc v1.0',
			riskTotalCounts:[],
			failSum: 0,

			dayWiseCounts: [],
			complianceControlDetails: [],

			filteredArray: [],
			
			transitionTrend: [],
			new_bar_transition_data:[],
			new_bar_transition_option:[],
			pending_bar_transition_data:[],
			pending_bar_transition_option:[],
			resolved_bar_transition_data:[],
			resolved_bar_transition_option:[],

			newTransitionFailSum: 0,
			pendingTransitionFailSum: 0,
			resolvedTransitionFailSum: 0,

			radialStackbarType: 'Compress',

			rightSideStateName: 'parentData',
			leftSideStateName: 'parentData',
			userChartType: 'user',
			entityChartType: ''
		}
	}

	handleStartTimeChange  = this.handleStartTimeChange.bind(this)
	handleEndTimeChange    = this.handleEndTimeChange.bind(this)
	
	componentDidMount = () => {
		// window.addEventListener('resize', () => {
        //     this.setState({ showRightSideRadialChart: false, showRightSideLollipopChart : false, showLeftSideRadialChart: false, showLeftSideLollipopChart : false},
        //         () => this.setState({ showRightSideRadialChart: true, showRightSideLollipopChart: true, showLeftSideRadialChart: true, showLeftSideLollipopChart : true })    
        //     )
        // })
		if(this.props.providers && this.props.providers.length) {
			this.setState({ providers: this.props.providers, selectedProvider: this.props.providers[0].provider_name },
				() => {
					this.filterDlp('provider')
					this.sliderPeriod()	
				}
			)
		} else {
			this.props.listAllProviders((promise, providers) => {
				if (promise) {
					this.setState({ providers, selectedProvider: providers[0].provider_name },
						() => {
							this.filterDlp('provider')
							this.sliderPeriod()	
						}
					)
				} else {
					this.setState(
						{ providers: [],  }, 
						() => {
							this.filterDlp()
							this.sliderPeriod()	
						}
					)
				}
			})
		}
		this.getInitializePaginationRight()
		this.getInitializePaginationLeft()
	}

	componentDidUpdate = (prevProps, prevState) => {
		if(this.state.rightSideStateName !== prevState.rightSideStateName) {
			this.setState({ 
				showRightSideTrendChart: (this.state.entityChartType === 'entity' || this.state.entityChartType === '') ? true : false,
				showRightSideRiskTrendChart: this.state.entityChartType === 'entityRisk' ? true : false, 
				showRightSideLollipopChart: true })
		}

		if(this.state.leftSideStateName !== prevState.leftSideStateName) {
			this.setState({ 
				showLeftSideTrendChart: (this.state.userChartType === 'user') ? true : false,
				showLeftSideRiskTrendChart: this.state.userChartType === 'userRisk' ? true : false, 
				showLeftSideLollipopChart: true })
		}
	}

	sliderPeriod = () => {
		let sliderEndDate = momentDateGivenFormat(currentLocaltime(), 'YYYY-MM-DD 23:59:59')		
		let brushInterval = 0
		let fullBar = 0
		let smallBar = 0
		let filterMonth = 0
		if(this.state.selectedFilterPeriod === 7) {
			fullBar = 10
			brushInterval = 7
			filterMonth = 90
		} else if(this.state.selectedFilterPeriod === 30) {
			fullBar = 5
			brushInterval = 30
			filterMonth = 180
		} else if(this.state.selectedFilterPeriod === 90) {
			fullBar = 10
			brushInterval = 90
			filterMonth = 365
		}
		// else if(this.state.selectedFilterPeriod === 180) {
		// 	fullBar = 15
		// 	smallBar = 3
		// 	brushInterval = 30
		// } else if(this.state.selectedFilterPeriod === 365) {
		// 	fullBar = 15
		// 	smallBar = 3
		// 	brushInterval = 60
		// }
		
		let dateArray = []
		// let month = ''

		let y = 1
		let z = 1
		for(let x=1;x<=filterMonth;x++) {
			
			let date = subDays(sliderEndDate, (filterMonth-x))

			let value = smallBar ? 0 : 5
			if(smallBar === z) {
				value = 5
				z = 1
			}
			z++
			if(y === fullBar || y === 1) {
				value = 10
				y = 1
			}
			y++;
			if(new Date(date).getTime() <= new Date(sliderEndDate).getTime()) {
				dateArray.push([date, value])
			}
		}

		let sliderStartDate = ''
		if(this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.sliderStartDate && this.props.history.location.state.sliderEndDate) {
			sliderStartDate = momentDateGivenFormat(this.props.history.location.state.sliderStartDate, 'YYYY-MM-DD 00:00:00');
			sliderEndDate = momentDateGivenFormat(this.props.history.location.state.sliderEndDate, 'YYYY-MM-DD 00:00:00');
        } else {
			sliderStartDate = subDays(sliderEndDate, brushInterval)
			sliderStartDate = momentDateGivenFormat(sliderStartDate, 'YYYY-MM-DD')
			sliderEndDate = momentDateGivenFormat(sliderEndDate, 'YYYY-MM-DD')
		}
		
		let startSlice = momentDateGivenFormat(sliderStartDate, 'YYYY-MM-DD 00:00:00');
		let endSlice = momentDateGivenFormat(sliderEndDate, 'YYYY-MM-DD 23:59:59');

		this.setState({ dateArray, brushSliderSeries: [], sliderStartDate, sliderEndDate, initialStartDate: sliderStartDate, initialEndDate: sliderEndDate, startSlice, endSlice },
			() => {
				this.brushSliderChart(dateArray, sliderStartDate, sliderEndDate)
				this.onSearch()
                window.addEventListener('resize', () => {
                    this.brushSliderChart(dateArray)
                })
			}
		)
	}
	
	brushSliderChart = (dateArray) => {
        let sliderStartDate = this.state.sliderStartDate
        let sliderEndDate = this.state.sliderEndDate
		if(dateArray.length) {
			// let labels = []
			// let dataOneLength = dateArray.length
			// let lastDate = dateArray[dataOneLength-1][0]
			let brushSliderSeries = [{
				data: dateArray
			}]
			let dataArray = []
			dateArray.forEach(item => {
				dataArray.push(item[0])
			})

			let columnWidth = '5%'
			let brushSliderOptions = {
				plotOptions: {
					bar: {
						barHeight: '100%',
						columnWidth: columnWidth,
					},          
				},
				chart: {
					redrawOnParentResize: true,
					id: 'chart1',
					height: 130,
					type: 'bar',
					brush:{
						target: 'none',
						enabled: true,
					},
					zoom: {
						enabled: false,
					},
					selection: {
						enabled: true,
						fill: {
							color: "#039BE5",
							opacity: 1,
							// opacity: 0.6
						},  
						stroke: {
							show: false,
							curve: 'straight',
							lineCap: 'round',
							// colors: '#00A3CB',
							colors: '#039BE5',
							width: 0,
							dashArray: 0,      
						},
						xaxis: {
							min: new Date(sliderStartDate).getTime(),
							max: new Date(sliderEndDate).getTime(),
						},
						style: {
							fontSize: '28px',
							color: '#000000'
						}
					},
					events: {
						selection: (chartContext, { xaxis, yaxis }) => {
							var minDate = momentDateGivenFormat(new Date(xaxis.min), 'YYYY-MM-DD')
							var maxDate = momentDateGivenFormat(new Date(xaxis.max), 'YYYY-MM-DD')

							let startSlice = momentDateGivenFormat(minDate, 'YYYY-MM-DD 00:00:00');
							let endSlice = momentDateGivenFormat(maxDate, 'YYYY-MM-DD 23:59:59');

							this.setState({ sliderStartDate: startSlice, sliderEndDate: endSlice, startSlice, endSlice },
								() => this.getAttribute()
							)
						},
						click: (event, chartContext, config) => {
							this.onSearch()
						}
					}
				},
				colors: ['#515151'],
				xaxis: {
					tickAmount: 8,
					type: 'datetime',
					tooltip: {
						enabled: false
					},
					position: 'top',
					labels: {
						show: true,
						datetimeUTC: false,
						style: {
							colors: '#E0E0E0'
						},
						formatter: function(val) {
							return momentDateGivenFormat(new Date(val), "DD MMM");
						}
					},
					
				},
				yaxis: {
					show: false,
					tickAmount: 2,
					labels: {
						show: false
					},
				},
				grid: {
					xaxis: {
						lines: {
							show: false
						},
					},
					yaxis: {
						lines: {
							show: false
						}
					},
				}
			}
		
			this.setState({ datePickerSliderSeries: brushSliderSeries, datePickerSliderOptions: brushSliderOptions })
		}
	}
	
	getAttribute = () => {
		var attributes = document.getElementsByClassName("apexcharts-selection-rect") && document.getElementsByClassName("apexcharts-selection-rect")[0] &&document.getElementsByClassName("apexcharts-selection-rect")[0].attributes;
		if(attributes) {
			let width = attributes.width.value
			let x1 = parseInt(attributes.x.value) + parseInt(20)
			let x2 = parseInt(attributes.x.value) + parseInt(width) + parseInt(10)
			
			let leftLPosition = x1
            let rightLPosition = x2
            let diff = twoDateDiffrence(this.state.sliderEndDate, this.state.sliderStartDate)
            if(diff < 8) {
                leftLPosition = x1 - 20
                if(diff < 4) {
                    leftLPosition = x1 - 40
                }
                rightLPosition = x2 + 10
            }

			var d1 = document.getElementById('leftSliderSpan');
			d1.style.position = "absolute";
			d1.style.left = leftLPosition+'px';
			d1.style.top = '110px';
			d1.style.color = '#039BE5';

			var d2 = document.getElementById('rightSliderSpan');
			d2.style.position = "absolute";
			d2.style.left = rightLPosition+'px';
			d2.style.top = '110px';
			d2.style.color = '#039BE5';
		}
	}

	handleStartTimeChange(date) {
		let startSlice = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
		let endSlice = this.state.endSlice
		if(new Date(startSlice).getTime() > new Date(endSlice).getTime() ) {
			endSlice = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')
		}

		this.setState({ startSlice, endSlice, changesInFilter: true })
	}

	handleEndTimeChange(date) {
		let startSlice = this.state.startSlice
		let endSlice = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
		if(new Date(endSlice).getTime() > new Date(startSlice).getTime() ) {
			this.setState({ endSlice, changesInFilter: true })
		}
	}
	
	/**
	 * Filter identifiers based on the filter selection
	 */
	filterDlp = from => {
		let params = {}

		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'all') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}

		if (from === 'provider') {
			if(this.props.accounts && this.props.accounts.length) {
				let allAccounts = this.props.accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
				if(this.props.accounts.length) {
					let accRow = {}
					accRow.account_id = "All"
					accRow.account_name = "All"
					accRow.date = null
					accRow.disabled = false
					accRow.provider = "All"
					allAccounts.unshift(accRow);
				}
				this.setState({ accounts: allAccounts })
			} else {
				this.props.listAllAccounts(params, (promise, allAccounts) => {
					if (promise) {						
						let filterdAccounts = allAccounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
						if(filterdAccounts.length) {
							let accRow = {}
							accRow.account_id = "All"
							accRow.account_name = "All"
							accRow.date = null
							accRow.disabled = false
							accRow.provider = "All"
							filterdAccounts.unshift(accRow);
						}						
						this.setState({ accounts: filterdAccounts })
					}
				})
			}

			if(this.props.regions && this.props.regions.length) {
				let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
				this.setState({ regions: providerRegions })
			} else {
				this.props.listAllRegions(params, (promise, regions) => {
					if (promise) {
						let providerRegions = regions && regions[0] && regions[0].provider_regions
						if(providerRegions) {
							let provider_regions_row = {}
							provider_regions_row.availability_zones = []
							provider_regions_row.city = "All"
							provider_regions_row.country = "All"
							provider_regions_row.geography = "All"
							provider_regions_row.name = "All"
							provider_regions_row.region = "All"
							provider_regions_row.region_id = "All"
							provider_regions_row.state = "All"
							providerRegions.unshift(provider_regions_row);
						}
						this.setState({ regions: providerRegions })
					} else {
						this.setState({ regions: [] })
					}
				})
			}
		}
	}

	onSearch = () => {
		let changesInFilter = this.state.changesInFilter;
		if(!this.state.previousStartDate || (this.state.previousStartDate !== this.state.startSlice || this.state.previousEndDate !== this.state.endSlice)) {
			changesInFilter = true
			this.setState({ previousStartDate: this.state.startSlice, previousEndDate: this.state.endSlice })
		}
		
		if(changesInFilter) {
            let startTime = this.state.sliderStartDate
            let endTime = this.state.sliderEndDate
            if(this.state.sliderStartDate !== this.state.startSlice || this.state.sliderEndDate !== this.state.endSlice) {
                startTime = this.state.startSlice
                endTime = this.state.endSlice
            }

            this.setState({
                start_time: momentConvertionLocalToUtcTime(startTime, 'YYYY-MM-DD HH:mm:ss'),
                end_time: momentConvertionLocalToUtcTime(endTime, 'YYYY-MM-DD HH:mm:ss'),
				showLoading: true,
			},
				() => {
					this.getDayWiseCount('risk');
					this.getComplianceControlDetails();
					this.getTransitionTrend()
					this.getThreatCoverageAndTopStats()
				}	
			)
		}

		this.setState({ changesInFilter: false })

		setTimeout(() => { this.setState({ showLoading: false }) }, 5000)
	}	

	onReset = () => {
		this.setState({
			selectedProvider: this.state.providers && this.state.providers.length ? this.state.providers[0].provider_name : '',
			selectedRegion: [],
			selectedRegionName: [],
			selectedAccount: [],
			sliderStartDate: this.state.initialStartDate,
			sliderEndDate: this.state.initialEndDate,
			changesInFilter: true
		},
			() => this.onSearch()
		)
	}

	getThreatCoverageAndTopStats = () => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		if (this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
			params.account_id = this.state.selectedAccount
        }
		if (this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
			params.region = this.state.selectedRegion
        }
		params.compliance = [{"complianceId": "dc v1.0"}]
		params.start_time = this.state.start_time
		params.end_time = this.state.end_time
	
		this.props.getThreatCoverageAndTopStats(params, (promise, result) => {
			if(promise) {

				let threatCoverage = result.threat_coverage ? result.threat_coverage : {}
                let topThreatByBuckets = result.top_threat_by_buckets ? result.top_threat_by_buckets : {}
				let topThreatByBucketsEntity = result.top_threat_by_buckets_entity ? result.top_threat_by_buckets_entity : {}
				let topBucketsByRisksEntity = result.top_buckets_by_risks_entitry ? result.top_buckets_by_risks_entitry : {}
				
				let topThreatByBucketsUser = result.top_threat_by_buckets_user ? result.top_threat_by_buckets_user : {}
				let topBucketsByRisksUser = result.top_buckets_by_risks_user ? result.top_buckets_by_risks_user : {}

                let topThreat = result.top_threat ? result.top_threat : []
				this.setState({ threatCoverage: [], topThreatsGraphData: {} },
					() => {
						this.setState({ threatCoverage, topThreatByBuckets, topThreatByBucketsEntity, topBucketsByRisksEntity, topThreatByBucketsUser, topBucketsByRisksUser, topThreat, showLoading: false },
							() => {
				
								if(this.state.topThreat && this.state.topThreat.length) {
									this.topThreatRiskCountApexGraph()
								}

								this.onChangeGetcharts('')
							}    
						)
					}
				)
			} else {
				this.setState({ showLoading: false })
			}
		})
	}

	onChangeGetcharts = (from) => {
		if(this.state.radialStackbarType === 'Expand') {
			if(from === 'entity' || from === '') {
				this.setState({ showRightSideRadialChart: false, rightSideStateName: 'parentData', showRightSideLollipopChart: false, showRightSideTrendChart: false, showRightSideRiskTrendChart: false },
					() => {
						if(this.state.entityChartType === 'entityRisk') {
							if(this.state.topBucketsByRisksEntity && this.state.topBucketsByRisksEntity.buckets && this.state.topBucketsByRisksEntity.buckets.length) {
								this.renderEntityRiskRadialBarChart(this.state.topBucketsByRisksEntity)
							}
						} else if(this.state.entityChartType === 'entity') {
							if(this.state.topThreatByBucketsEntity && this.state.topThreatByBucketsEntity.buckets && this.state.topThreatByBucketsEntity.buckets.length) {
								this.renderEntityBucketsRadialBarChart('entity', this.state.topThreatByBucketsEntity)
							}
						}
					}
				)
			}

			if(from === 'user' || from === '') {
				this.setState({ showLeftSideRadialChart: false, leftSideStateName: 'parentData', showLeftSideLollipopChart: false, showLeftSideTrendChart: false, showLeftSideRiskTrendChart: false },
					() => {
						if(this.state.userChartType === 'userRisk') {
							if(this.state.topBucketsByRisksUser && this.state.topBucketsByRisksUser.buckets && this.state.topBucketsByRisksUser.buckets.length) {
								this.renderUserRiskRadialBarChart(this.state.topBucketsByRisksUser)
							}
						} else if(this.state.userChartType === 'user') {
							if(this.state.topThreatByBucketsUser && this.state.topThreatByBucketsUser.buckets && this.state.topThreatByBucketsUser.buckets.length) {
								this.renderUserBucketRadialBarChart(this.state.topThreatByBucketsUser)
							}
						}
					}
				)
			}
		} else {
			
			this.setState({ showRightSideRadialChart: false, rightSideStateName: 'parentData', showRightSideLollipopChart: false, showRightSideTrendChart: false },
				() => {
					if(this.state.topThreatByBuckets && this.state.topThreatByBuckets.buckets && this.state.topThreatByBuckets.buckets.length) {
						this.renderEntityBucketsRadialBarChart('totalThreats', this.state.topThreatByBuckets)
					}
				}	
			)
		}
	}

	renderEntityBucketsRadialBarChart = (graphType, graphData) => {
		let rightSideGraphColors = []
		let rightSideTableHeading = ''
		if(graphType === 'totalThreats') {
			rightSideGraphColors = ["#9029cc", "#a22ee6", "#bc47ff", "#c35cff", "#cb70ff", "#d285ff", "#da99ff", "#e15ad2", "#fb74eb", "#fb83ed", "#fc93f0", "#fca2f2", "#fdb2f4", "#fdc1f6"]
			rightSideTableHeading = 'Threats by Bucket'
		} else {
			rightSideGraphColors = ['#024d73', '#026ca0', '#038bce', '#35aeea', '#68c2ef', '#81cdf2', '#9ad7f5', '#b3e1f7', '#cdebfa']
			rightSideTableHeading = 'Threats by Entity'
		}
		
        let bucketData = graphData.buckets
        let keys = []
		bucketData.forEach((item, index) => {
            if(item.prefix_wise_count && item.prefix_wise_count.length) {
                item.prefix_wise_count.forEach((pref,i) => {
                    if(pref.prefix_wise_threats && Object.entries(pref.prefix_wise_threats).length) {
                        Object.entries(pref.prefix_wise_threats).forEach(([key, value]) => {		
							if (!keys.includes(key)) {
								keys.push(key)
							}
                        })
                    }
                })
            } else {
                if(item.bucket_wise_threats && Object.entries(item.bucket_wise_threats).length) {
                    Object.entries(item.bucket_wise_threats).forEach(([key, value]) => {
                        if (!keys.includes(key)) {
							keys.push(key)
						}
                    })
                }
            }
        })

        let trendData = {}
		trendData['parentData'] = graphData.trend
		let topStatsData = {}
		if(graphData.top_stats) {
			let topStatsRow = {}
			topStatsRow.count = graphData.overall_count
			topStatsRow.data = graphData.top_stats
			topStatsData['parentData'] = topStatsRow
		}

        let rightSideSummary = ''		
        let rightSideArray = []
		let total = 0
		let rightSideTotal = 0
        bucketData.forEach((item, index) => {
            if(item.prefix_wise_count && item.prefix_wise_count.length) {
                item.prefix_wise_count.forEach((pref,i) => {
					let State = 'prefix_'+index+'_'+i
					rightSideSummary = pref.summary
					
					// for trend graph
					if(pref.trend) {
						trendData[State] = pref.trend
					}

					//for lolliopo graph
					if(pref.top_stats && pref.top_stats.length) {
						let topStatsRow = {}
						topStatsRow.count = pref.count
						topStatsRow.data = pref.top_stats
						topStatsData[State] = topStatsRow
					}

					// for staked circle bar
                    let dataRow = {}
                    dataRow.State = State
                    dataRow.name = (item.bucket_name ? item.bucket_name : '')+' '+ (pref.bucket_prefix ? pref.bucket_prefix : '')
                    dataRow.total = pref.count

					rightSideTotal += item.count
					if(total < pref.count) {
						total = pref.count
					}
                    if(pref.prefix_wise_threats && Object.entries(pref.prefix_wise_threats).length) {
                        Object.entries(pref.prefix_wise_threats).forEach(([key, value]) => {
                            dataRow[key] = value
                        })
                    }
                    keys.forEach(key => {
                        if(!dataRow.hasOwnProperty(key)) {
                            dataRow[key] = 0
                        }
                    })
                    rightSideArray.push(dataRow)
                })
            } else {
				let State = 'bucket_'+index
				rightSideSummary = item.summary
				
				// for trend graph
				if(item.trend) {
					trendData[State] = item.trend
				}

				//for lolliopo graph
				if(item.top_stats && item.top_stats.length) {
					let topStatsRow = {}
					topStatsRow.count = item.count
					topStatsRow.data = item.top_stats
					topStatsData[State] = topStatsRow
				}

				// for staked circle bar
				let dataRow = {}
                dataRow.State = State
                dataRow.name = item.bucket_name ? item.bucket_name : ''
                dataRow.total = item.count ? item.count : ''

				if(total < item.count) {
					total = item.count
				}
                if(item.bucket_wise_threats && Object.entries(item.bucket_wise_threats).length) {
                    Object.entries(item.bucket_wise_threats).forEach(([key, value]) => {
                        dataRow[key] = value
                    })
                }
                keys.forEach(key => {
                    if(!dataRow.hasOwnProperty(key)) {
                        dataRow[key] = 0
                    }
                })
                rightSideArray.push(dataRow)
            }
        })
		
		if(rightSideArray.length < 25) {			
			let length = rightSideArray.length
			for(let i=length; i<=25; i++) {
				let dataRow = {}
				dataRow.State = 'entity_'+i
				dataRow.name = 'empty_data'
				dataRow.total = 0
				keys.forEach((key, j) => {
					if(j===0) {
						dataRow[key] = 1
					} else {
						dataRow[key] = 0
					}
				})
				rightSideArray.push(dataRow)
			}
		}

		let rightSideTotalData = {}
		let data = {}
		data.name = 'Entity Data'
		keys.forEach((key, j) => {
			data[key] = this.count(rightSideArray, key)
		})
		rightSideTotalData.data = data

        this.setState({ rightSideGraphColors, rightSideTableHeading, rightSideSummary, rightSideArray, rightSideKeys: keys, rightSideHighest: total, rightSideTotalData, rightSideTotal, showRightSideRadialChart: true, rightSideTrendData: trendData, rightSideTopStatsData: topStatsData, rightSideGraphId: 'totalThreats', rightSideSvgGraphId: 'svgTotalThreats', showRightSideLollipopChart: true, showRightSideTrendChart: true })
	}

	renderEntityRiskRadialBarChart = (graphData) => {
		let rightSideGraphColors = ['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']
		let rightSideTableHeading = 'Entity by Risk'
        let bucketData = graphData.buckets
        let keys = []
		bucketData.forEach((item, index) => {
            if(item.prefix_wise_count && item.prefix_wise_count.length) {
                item.prefix_wise_count.forEach((pref,i) => {
                    if(pref.prefix_wise_risks && Object.entries(pref.prefix_wise_risks).length) {
                        Object.entries(pref.prefix_wise_risks).forEach(([key, value]) => {		
							if (!keys.includes(key)) {
								keys.push(key)
							}
                        })
                    }
                })
            } else {
                if(item.bucket_wise_risks && Object.entries(item.bucket_wise_risks).length) {
                    Object.entries(item.bucket_wise_risks).forEach(([key, value]) => {
                        if (!keys.includes(key)) {
							keys.push(key)
						}
                    })
                }
            }
        })

        let trendData = {}
		trendData['parentData'] = graphData.trend
		let topStatsData = {}
		if(graphData.top_stats) {
			let topStatsRow = {}
			topStatsRow.count = graphData.overall_count
			topStatsRow.data = graphData.top_stats
			topStatsData['parentData'] = topStatsRow
		}
        let rightSideSummary = ''
        let rightSideArray = []
		let total = 0
		let rightSideTotal = 0
        bucketData.forEach((item, index) => {
            if(item.prefix_wise_count && item.prefix_wise_count.length) {
                item.prefix_wise_count.forEach((pref,i) => {
					let State = 'prefix_'+index+'_'+i
					rightSideSummary = pref.summary
					
					// for trend graph
					if(pref.trend) {
						trendData[State] = pref.trend
					}

					//for lolliopo graph
					if(pref.top_stats && pref.top_stats.length) {
						let topStatsRow = {}
						topStatsRow.count = pref.count
						topStatsRow.data = pref.top_stats
						topStatsData[State] = topStatsRow
					}

					// for staked circle bar
                    let dataRow = {}
                    dataRow.State = State
                    dataRow.name = (item.bucket_name ? item.bucket_name : '')+' '+ (pref.bucket_prefix ? pref.bucket_prefix : '')
                    dataRow.total = pref.count

					rightSideTotal += item.count
					if(total < pref.count) {
						total = pref.count
					}
                    if(pref.prefix_wise_risks && Object.entries(pref.prefix_wise_risks).length) {
                        Object.entries(pref.prefix_wise_risks).forEach(([key, value]) => {
                            dataRow[key] = value
                        })
                    }
                    keys.forEach(key => {
                        if(!dataRow.hasOwnProperty(key)) {
                            dataRow[key] = 0
                        }
                    })
                    rightSideArray.push(dataRow)
                })
            } else {
                let State = 'bucket_'+index
				rightSideSummary = item.summary
				
				// for trend graph
				if(item.trend) {
					trendData[State] = item.trend
				}

				//for lolliopo graph
				if(item.top_stats && item.top_stats.length) {
					let topStatsRow = {}
					topStatsRow.count = item.count
					topStatsRow.data = item.top_stats
					topStatsData[State] = topStatsRow
				}
				
				// for staked circle bar
                let dataRow = {}
                dataRow.State = State
                dataRow.name = item.bucket_name ? item.bucket_name : ''
                dataRow.total = item.count ? item.count : ''

				if(total < item.count) {
					total = item.count
				}
                if(item.bucket_wise_risks && Object.entries(item.bucket_wise_risks).length) {
                    Object.entries(item.bucket_wise_risks).forEach(([key, value]) => {
                        dataRow[key] = value
                    })
                }
                keys.forEach(key => {
                    if(!dataRow.hasOwnProperty(key)) {
                        dataRow[key] = 0
                    }
                })
                rightSideArray.push(dataRow)
            }
        })
		
		if(rightSideArray.length < 25) {			
			let length = rightSideArray.length
			for(let i=length; i<=25; i++) {
				let dataRow = {}
				dataRow.State = 'hits_'+i
				dataRow.name = 'empty_data'
				dataRow.total = 0
				keys.forEach((key, j) => {
					if(j===0) {
						dataRow[key] = 1
					} else {
						dataRow[key] = 0
					}
				})
				rightSideArray.push(dataRow)
			}
		}

		let rightSideTotalData = {}
		let data = {}
		data.name = 'Entity Risk Data'
		keys.forEach((key, j) => {
			data[key] = this.count(rightSideArray, key)
		})
		rightSideTotalData.data = data

		this.setState({ rightSideGraphColors, rightSideTableHeading, rightSideSummary, rightSideArray, rightSideKeys: keys, rightSideHighest: total, rightSideTotalData, rightSideTotal, showRightSideRadialChart: true, rightSideTrendData: trendData, rightSideTopStatsData: topStatsData, rightSideGraphId: 'rightSideThreats', rightSideSvgGraphId: 'svgRightSideThreats', showRightSideLollipopChart: true, showRightSideRiskTrendChart: true })
	}	

	renderUserBucketRadialBarChart = (graphData) => {
		let leftSideGraphColors = ['#024d73', '#026ca0', '#038bce', '#35aeea', '#68c2ef', '#81cdf2', '#9ad7f5', '#b3e1f7', '#cdebfa']
		let leftSideTableHeading = 'Threats by Users'
        let bucketData = graphData.buckets
        let keys = []
		bucketData.forEach((item, index) => {
            if(item.prefix_wise_count && item.prefix_wise_count.length) {
                item.prefix_wise_count.forEach((pref,i) => {
                    if(pref.prefix_wise_threats && Object.entries(pref.prefix_wise_threats).length) {
                        Object.entries(pref.prefix_wise_threats).forEach(([key, value]) => {		
							if (!keys.includes(key)) {
								keys.push(key)
							}
                        })
                    }
                })
            } else {
                if(item.bucket_wise_threats && Object.entries(item.bucket_wise_threats).length) {
                    Object.entries(item.bucket_wise_threats).forEach(([key, value]) => {
                        if (!keys.includes(key)) {
							keys.push(key)
						}
                    })
                }
            }
        })

        let trendData = {}
		trendData['parentData'] = graphData.trend
		let topStatsData = {}
		if(graphData.top_stats) {
			let topStatsRow = {}
			topStatsRow.count = graphData.overall_count
			topStatsRow.data = graphData.top_stats
			topStatsData['parentData'] = topStatsRow
		}

        let leftSideSummary = ''		
        let leftSideArray = []
		let total = 0
		let leftSideTotal = 0
        bucketData.forEach((item, index) => {
            if(item.prefix_wise_count && item.prefix_wise_count.length) {
                item.prefix_wise_count.forEach((pref,i) => {
					let State = 'prefix_'+index+'_'+i
					leftSideSummary = pref.summary
					
					// for trend graph
					if(pref.trend) {
						trendData[State] = pref.trend
					}

					//for lolliopo graph
					if(pref.top_stats && pref.top_stats.length) {
						let topStatsRow = {}
						topStatsRow.count = pref.count
						topStatsRow.data = pref.top_stats
						topStatsData[State] = topStatsRow
					}

					// for staked circle bar
                    let dataRow = {}
                    dataRow.State = State
                    dataRow.name = (item.bucket_name ? item.bucket_name : '')+' '+ (pref.bucket_prefix ? pref.bucket_prefix : '')
                    dataRow.total = pref.count

					leftSideTotal += item.count
					if(total < pref.count) {
						total = pref.count
					}
                    if(pref.prefix_wise_threats && Object.entries(pref.prefix_wise_threats).length) {
                        Object.entries(pref.prefix_wise_threats).forEach(([key, value]) => {
                            dataRow[key] = value
                        })
                    }
                    keys.forEach(key => {
                        if(!dataRow.hasOwnProperty(key)) {
                            dataRow[key] = 0
                        }
                    })
                    leftSideArray.push(dataRow)
                })
            } else {
				let State = 'bucket_'+index
				leftSideSummary = item.summary
				
				// for trend graph
				if(item.trend) {
					trendData[State] = item.trend
				}

				//for lolliopo graph
				if(item.top_stats && item.top_stats.length) {
					let topStatsRow = {}
					topStatsRow.count = item.count
					topStatsRow.data = item.top_stats
					topStatsData[State] = topStatsRow
				}

				// for staked circle bar
				let dataRow = {}
                dataRow.State = State
                dataRow.name = item.bucket_name ? item.bucket_name : ''
                dataRow.total = item.count ? item.count : ''

				if(total < item.count) {
					total = item.count
				}
                if(item.bucket_wise_threats && Object.entries(item.bucket_wise_threats).length) {
                    Object.entries(item.bucket_wise_threats).forEach(([key, value]) => {
                        dataRow[key] = value
                    })
                }
                keys.forEach(key => {
                    if(!dataRow.hasOwnProperty(key)) {
                        dataRow[key] = 0
                    }
                })
                leftSideArray.push(dataRow)
            }
        })
		
		if(leftSideArray.length < 25) {			
			let length = leftSideArray.length
			for(let i=length; i<=25; i++) {
				let dataRow = {}
				dataRow.State = 'entity_'+i
				dataRow.name = 'empty_data'
				dataRow.total = 0
				keys.forEach((key, j) => {
					if(j===0) {
						dataRow[key] = 1
					} else {
						dataRow[key] = 0
					}
				})
				leftSideArray.push(dataRow)
			}
		}

		let leftSideTotalData = {}
		let data = {}
		data.name = 'Entity Data'
		keys.forEach((key, j) => {
			data[key] = this.count(leftSideArray, key)
		})
		leftSideTotalData.data = data

        this.setState({ leftSideGraphColors, leftSideTableHeading, leftSideSummary, leftSideArray, leftSideKeys: keys, leftSideHighest: total, leftSideTotalData, leftSideTotal, showLeftSideRadialChart: true, leftSideTrendData: trendData, leftSideTopStatsData: topStatsData, leftSideGraphId: 'totalUserThreats', leftSideSvgGraphId: 'svgTotalUserThreats', showLeftSideLollipopChart: true, showLeftSideTrendChart: true })
	}

	renderUserRiskRadialBarChart = (graphData) => {
		let leftSideGraphColors = ['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']
		let leftSideTableHeading = 'Users by Risk'
        let bucketData = graphData.buckets
        let keys = []
		bucketData.forEach((item, index) => {
            if(item.prefix_wise_count && item.prefix_wise_count.length) {
                item.prefix_wise_count.forEach((pref,i) => {
                    if(pref.prefix_wise_risks && Object.entries(pref.prefix_wise_risks).length) {
                        Object.entries(pref.prefix_wise_risks).forEach(([key, value]) => {		
							if (!keys.includes(key)) {
								keys.push(key)
							}
                        })
                    }
                })
            } else {
                if(item.bucket_wise_risks && Object.entries(item.bucket_wise_risks).length) {
                    Object.entries(item.bucket_wise_risks).forEach(([key, value]) => {
                        if (!keys.includes(key)) {
							keys.push(key)
						}
                    })
                }
            }
        })

        let trendData = {}
		trendData['parentData'] = graphData.trend
		let topStatsData = {}
		if(graphData.top_stats) {
			let topStatsRow = {}
			topStatsRow.count = graphData.overall_count
			topStatsRow.data = graphData.top_stats
			topStatsData['parentData'] = topStatsRow
		}
        let leftSideSummary = ''
        let leftSideArray = []
		let total = 0
		let leftSideTotal = 0
        bucketData.forEach((item, index) => {
            if(item.prefix_wise_count && item.prefix_wise_count.length) {
                item.prefix_wise_count.forEach((pref,i) => {
					let State = 'prefix_'+index+'_'+i
					leftSideSummary = pref.summary
					
					// for trend graph
					if(pref.trend) {
						trendData[State] = pref.trend
					}

					//for lolliopo graph
					if(pref.top_stats && pref.top_stats.length) {
						let topStatsRow = {}
						topStatsRow.count = pref.count
						topStatsRow.data = pref.top_stats
						topStatsData[State] = topStatsRow
					}

					// for staked circle bar
                    let dataRow = {}
                    dataRow.State = State
                    dataRow.name = (item.bucket_name ? item.bucket_name : '')+' '+ (pref.bucket_prefix ? pref.bucket_prefix : '')
                    dataRow.total = pref.count

					leftSideTotal += item.count
					if(total < pref.count) {
						total = pref.count
					}
                    if(pref.prefix_wise_risks && Object.entries(pref.prefix_wise_risks).length) {
                        Object.entries(pref.prefix_wise_risks).forEach(([key, value]) => {
                            dataRow[key] = value
                        })
                    }
                    keys.forEach(key => {
                        if(!dataRow.hasOwnProperty(key)) {
                            dataRow[key] = 0
                        }
                    })
                    leftSideArray.push(dataRow)
                })
            } else {
                let State = 'bucket_'+index
				leftSideSummary = item.summary
				
				// for trend graph
				if(item.trend) {
					trendData[State] = item.trend
				}

				//for lolliopo graph
				if(item.top_stats && item.top_stats.length) {
					let topStatsRow = {}
					topStatsRow.count = item.count
					topStatsRow.data = item.top_stats
					topStatsData[State] = topStatsRow
				}
				
				// for staked circle bar
                let dataRow = {}
                dataRow.State = State
                dataRow.name = item.bucket_name ? item.bucket_name : ''
                dataRow.total = item.count ? item.count : ''

				if(total < item.count) {
					total = item.count
				}
                if(item.bucket_wise_risks && Object.entries(item.bucket_wise_risks).length) {
                    Object.entries(item.bucket_wise_risks).forEach(([key, value]) => {
                        dataRow[key] = value
                    })
                }
                keys.forEach(key => {
                    if(!dataRow.hasOwnProperty(key)) {
                        dataRow[key] = 0
                    }
                })
                leftSideArray.push(dataRow)
            }
        })
		
		if(leftSideArray.length < 25) {			
			let length = leftSideArray.length
			for(let i=length; i<=25; i++) {
				let dataRow = {}
				dataRow.State = 'hits_'+i
				dataRow.name = 'empty_data'
				dataRow.total = 0
				keys.forEach((key, j) => {
					if(j===0) {
						dataRow[key] = 1
					} else {
						dataRow[key] = 0
					}
				})
				leftSideArray.push(dataRow)
			}
		}

		let leftSideTotalData = {}
		let data = {}
		data.name = 'User Risk Data'
		keys.forEach((key, j) => {
			data[key] = this.count(leftSideArray, key)
		})
		leftSideTotalData.data = data

		this.setState({ leftSideGraphColors, leftSideTableHeading, leftSideSummary, leftSideArray, leftSideKeys: keys, leftSideHighest: total, leftSideTotalData, leftSideTotal, showLeftSideRadialChart: true, leftSideTrendData: trendData, leftSideTopStatsData: topStatsData, leftSideGraphId: 'leftSideThreats', leftSideSvgGraphId: 'svgLeftSideThreats', showLeftSideLollipopChart: true, showLeftSideRiskTrendChart: true })
	}	

	count = (array, key) => {
		return array.reduce(function (r, a) {
			return r + a[key];
		}, 0);
	}

	getTransitionTrend = () => {
		let params = {}                
		params.provider = this.state.selectedProvider.toLowerCase()
		if (this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
			params.account_id = this.state.selectedAccount
        }
		if (this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
			params.region = this.state.selectedRegion
        }
		params.compliance = [{"complianceId":this.state.selectedCompliance}]
		params.start_time = this.state.start_time
		params.end_time = this.state.end_time
		
				
		this.props.getTransitionTrend(params, (promise, transitionTrend) => {
			if (promise) {
                this.setState({ transitionTrend, newGraphData: {}, pendingGraphData: {}, resolvedGraphData: {} },
					() => this.dayWiseTransitionGraph()
				)
			} else {
                this.setState({ transitionTrend: [] })
            }
		})
	}
	
	getDayWiseCount = aggregateBy =>{
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		if (this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
			params.account_id = this.state.selectedAccount
        }
		if (this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
			params.region = this.state.selectedRegion
        }
		params.compliance = [{"complianceId":this.state.selectedCompliance}]
		params.aggregate_by = aggregateBy
		params.start_time = this.state.start_time
		params.end_time = this.state.end_time
		
		this.props.getDayWiseCount(params, (promise, result) => {
			if (promise) {
				this.setState({ dayWiseCounts: {} }, 
					() => this.setState({ dayWiseCounts: result })
				)
			} else {
				this.setState({ dayWiseCounts: [] })
			}
		})		
	}
	
	getComplianceControlDetails = () =>{
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		if (this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
			params.account_id = this.state.selectedAccount
        }
		if (this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
			params.region = this.state.selectedRegion
        }
		params.compliance = [{"complianceId":this.state.selectedCompliance}]
		params.start_time = this.state.start_time
		params.end_time = this.state.end_time
		
		this.props.getComplianceControlDetails(params, (promise, complianceControlDetails) => {
			if (promise) {
				this.setState({ complianceControlDetails, filteredArray: complianceControlDetails });
			} else {
				this.setState({ complianceControlDetails: [], filteredArray: [] })
			}
		})
	}

    dayWiseTransitionGraph = () => {
		let graphData = this.state.transitionTrend.data
		let graphLabels = this.state.transitionTrend.labels
		if(graphData) {
			for (const [key, value] of Object.entries(graphData)) {
				let failSum = value.reduce(function(a, b){
					return a + b;
				}, 0);

				let trendGraphData = {}
				trendGraphData.labels = graphLabels
				trendGraphData.data = value

				if(key === 'New' || key === 'new') {
					this.setState({ newGraphData: trendGraphData, newFailSum: failSum});
				} else if(key === 'Pending' || key === 'pending') {
					this.setState({ pendingGraphData: trendGraphData, pendingFailSum: failSum });
				} else if(key === 'Resolved' || key === 'resolved') {                    
					this.setState({ resolvedGraphData: trendGraphData, resolvedFailSum: failSum });
				}
			}
		}
	}

	topThreatRiskCountApexGraph= () => {
		let data = []
		let labels = []
		this.state.topThreat.forEach(item => {
			data.push(item.count)
			labels.push(item.threat)
		})

		let topThreatsGraphData = {}
		topThreatsGraphData.data = data
		topThreatsGraphData.labels = labels
		
		this.setState({ topThreatsGraphData });
	}	

	handleMultiSelectChange = (childKey, arrayValue) => {

		this.setState({ caches: true})
		let value = arrayValue.map(item => item.value)
		let label = arrayValue.map(item => item)	
		if(childKey === 'selectedAccount') {
			let prevState = this.state.selectedAccount
			let selectedAccountName = arrayValue
			if(prevState.includes('All') && value.includes("All")) {
				const index = value.indexOf('All');
				if (index > -1) {
					value.splice(index, 1);
				}
				let array = []
				value.forEach(item => {
					this.state.accounts.forEach(acc => {
						if(acc.account_id === item) {
							label = {}
							label.value = acc.account_id
							label.label = acc.account_name
							array.push(label)
						}
					})
				})
				selectedAccountName = array
			} else if(prevState.includes('All') && !value.includes("All")) {
				value = []
				selectedAccountName = []
			}
			if(value.length && value.includes("All")) {
				let selectedAccount = []
				selectedAccountName = []
				this.state.accounts.forEach(acc => {
					label = {}
					label.value = acc.account_id
					label.label = acc.account_name

					selectedAccount.push(label.value)
					selectedAccountName.push(label)
				})
				this.setState({ selectedAccount, selectedAccountName, changesInFilter: true })
			} else {
				this.setState({ selectedAccount: value, selectedAccountName, changesInFilter: true })
			}
		} else if(childKey === 'selectedRegion') {
			let prevState = this.state.selectedRegion
			let selectedRegionName = arrayValue
			if(prevState.includes('All') && value.includes("All")) {
				const index = value.indexOf('All');
				if (index > -1) {
					value.splice(index, 1);
				}
				let array = []
				value.forEach(item => {
					this.state.regions.forEach(reg => {
						if(reg.region === item) {
							let label = {}
							label.value = reg.region
							label.label = reg.name
							array.push(label)
						}
					})
				})
				selectedRegionName = array
			} else if(prevState.includes('All') && !value.includes("All")) {
				value = []
				selectedRegionName = []
			}

			if(value.length && value.includes("All")) {
				let selectedRegion = []
				selectedRegionName = []
				this.state.regions.forEach(reg => {
					let label = {}
					label.value = reg.region
					label.label = reg.name

					selectedRegion.push(label.value)
					selectedRegionName.push(label)
				})
				this.setState({ selectedRegion, selectedRegionName, changesInFilter: true  })
			} else {
				this.setState({ selectedRegion: value, selectedRegionName, changesInFilter: true  })
			}
		}
	}	

	getMultiSelectedCount = (type, array) => {
		if(array) {
			return array.length && array.includes('All') ? 'All' :  array.length ? array.length +' Selected' : 'All'
		} else {
			return 'All'
		}
	}
		
	handleClickOutside(event) {
		
		if (this.accountRef && !this.accountRef.current.contains(event.target)) {
			this.setState({ isAccountOpen: false })
		} else {
			this.setState({ isAccountOpen: true })
		}

		if (this.regionRef && !this.regionRef.current.contains(event.target)) {
			this.setState({ isRegionOpen: false })
		} else {
			this.setState({ isRegionOpen: true })
		}
	}	

	getInitializePaginationRight = () => {
		this.setState({
			rightSidePageCount: 1,
			currentPage: 1,
			rightSideStart: 0, 
			rightSidePerPage: 5,
			disableLeftAction: true,
			disableRightAction: false,
		})
	}

	rightSideDataFormat = (data, State) => {
		let propsToRemove = ['name', 'State', 'total']
		let rightSideTableData = this.removeProperties(data, ...propsToRemove)
		let rightSideDataLength = Object.entries(rightSideTableData).length
		let rightSidePageCount = 1
		let rightSidePageCountArray = []
		let ritPageStart = 0
		let ritPageEnd = 1

		if(rightSideDataLength > this.state.rightSidePerPage) {
			rightSidePageCount = Math.ceil(rightSideDataLength / this.state.rightSidePerPage)
			ritPageEnd = rightSidePageCount > 5 ? 5 : rightSidePageCount
			for(let i=0;i<rightSidePageCount;i++) {
				rightSidePageCountArray.push(i+1)
			}
		}
		
		this.setState({ 
			ritPageStart,
			ritPageEnd,
			rightSidePageCount,
			rightSidePageCountArray,
			rightSideTableData: _(rightSideTableData).toPairs().orderBy([1], ['desc']).fromPairs().value(), 
			rightSideStateName: State ? State : 'parentData', 
			showRightSideLollipopChart: (State ? State : 'parentData') !== this.state.rightSideStateName ? false : true,
			showRightSideTrendChart: (this.state.entityChartType === 'entity' || this.state.entityChartType === '') ? ((State ? State : 'parentData') !== this.state.rightSideStateName ? false : true) : false,
			showRightSideRiskTrendChart: this.state.entityChartType === 'entityRisk' ? ((State ? State : 'parentData') !== this.state.rightSideStateName ? false : true) : false
		})
	}

	removeProperties = (object, ...keys) => {
		return Object.entries(object).reduce((prev, [key, value]) => ({...prev, ...(!keys.includes(key) && { [key]: value }) }), {})
	}

	navigatePage = (action, currentPage) => {
		let ritPageStart = 0
		let ritPageEnd = this.state.rightSidePageCount > 5 ? 5 : this.state.rightSidePageCount

		let startRecord = this.state.rightSideStart
		if(action === 'next') {
			startRecord = startRecord + this.state.rightSidePerPage
			currentPage = currentPage + 1
		} else if(action === 'previous' && currentPage !== 1) {
			currentPage = currentPage - 1
			startRecord = startRecord - this.state.rightSidePerPage
		} else if(action === 'start' && currentPage !== 1) {
			currentPage = 1
			startRecord = 0
		} else if(action === 'end') {
			currentPage = this.state.rightSidePageCount
			ritPageStart = this.state.ritPageStart + (currentPage - 5)
			ritPageEnd = this.state.rightSidePageCount
			startRecord = (this.state.rightSidePageCount - 1) * this.state.rightSidePerPage
		} else if(action === 'page') {
			startRecord = (currentPage - 1) * this.state.rightSidePerPage
		}	
		
		if(this.state.rightSidePageCount > 5) {
			if(currentPage > 2 && (action === 'next' || action === 'page')) {
				ritPageStart = this.state.ritPageStart + 1
				ritPageEnd = this.state.ritPageEnd + 1
			}

			if(currentPage < 3 && (action === 'previous' || action === 'page') && this.state.ritPageStart > 1) {
				ritPageStart = this.state.ritPageStart - 1
				ritPageEnd = this.state.ritPageEnd - 1
			}

			if(ritPageEnd > this.state.rightSidePageCount) {
				ritPageStart = this.state.ritPageStart
				ritPageEnd = this.state.rightSidePageCount
			}
		}

		let disableLeftAction = true
		if(currentPage > 1) {
			disableLeftAction = false
		}

		let disableRightAction = false
		if(currentPage === this.state.rightSidePageCount) {
			disableRightAction = true
		}

		this.setState({ currentPage, ritPageStart, ritPageEnd, rightSideStart: startRecord, disableLeftAction, disableRightAction },
            () => this.rightSideTableRef.current.scrollIntoView()    
        )
	}

	getInitializePaginationLeft = () => {
		this.setState({ 
			leftSidePageCount: 1,
			leftSideCurrentPage: 1,
			leftSideStart: 0, 
			leftSidePerPage: 5,
			disableNextAction: true,
			disablePreviousAction: false,
		})
	}

	leftSideDataFormat = (data, State) => {
		let propsToRemove = ['name', 'State', 'total']
		let leftSideTableData = this.removeProperties(data, ...propsToRemove)
		let leftSideDataLength = Object.entries(leftSideTableData).length
		let leftSidePageCount = 1
		let leftSidePageCountArray = []
		let leftPageStart = 0
		let leftPageEnd = 1

		if(leftSideDataLength > this.state.rightSidePerPage) {
			leftSidePageCount = Math.ceil(leftSideDataLength / this.state.rightSidePerPage)
			leftPageEnd = leftSidePageCount > 5 ? 5 : leftSidePageCount
			for(let i=0;i<leftSidePageCount;i++) {
				leftSidePageCountArray.push(i+1)
			}
		}							

		this.setState({ 
			leftPageStart,
			leftPageEnd,
			leftSidePageCount,
			leftSidePageCountArray,
			leftSideTableData: _(leftSideTableData).toPairs().orderBy([1], ['desc']).fromPairs().value(), 
			leftSideStateName: State ? State : 'parentData', 
			showLeftSideLollipopChart: (State ? State : 'parentData') !== this.state.leftSideStateName ? false : true,
			showLeftSideTrendChart: (this.state.userChartType === 'user' || this.state.userChartType === '') ? ((State ? State : 'parentData') !== this.state.leftSideStateName ? false : true) : false,
			showLeftSideRiskTrendChart: this.state.userChartType === 'userRisk' ? ((State ? State : 'parentData') !== this.state.leftSideStateName ? false : true) : false
		})
	}

	navigateLeftSidePage = (action, leftSideCurrentPage) => {
		let leftPageStart = 0
		let leftPageEnd = this.state.leftSidePageCount > 5 ? 5 : this.state.leftSidePageCount

		let startRecord = this.state.leftSideStart
		if(action === 'next') {
			startRecord = startRecord + this.state.leftSidePerPage
			leftSideCurrentPage = leftSideCurrentPage + 1
		} else if(action === 'previous' && leftSideCurrentPage !== 1) {
			leftSideCurrentPage = leftSideCurrentPage - 1
			startRecord = startRecord - this.state.leftSidePerPage
		} else if(action === 'start' && leftSideCurrentPage !== 1) {
			leftSideCurrentPage = 1
			startRecord = 0
		} else if(action === 'end') {
			leftSideCurrentPage = this.state.leftSidePageCount
			leftPageStart = this.state.leftPageStart + (leftSideCurrentPage - 5)
			leftPageEnd = this.state.leftSidePageCount
			startRecord = (this.state.leftSidePageCount - 1) * this.state.leftSidePerPage
		} else if(action === 'page') {
			startRecord = (leftSideCurrentPage - 1) * this.state.leftSidePerPage
		}	
		
		if(this.state.leftSidePageCount > 5) {
			if(leftSideCurrentPage > 2 && (action === 'next' || action === 'page')) {
				leftPageStart = this.state.leftPageStart + 1
				leftPageEnd = this.state.leftPageEnd + 1
			}

			if(leftSideCurrentPage < 3 && (action === 'previous' || action === 'page') && this.state.leftPageStart > 1) {
				leftPageStart = this.state.leftPageStart - 1
				leftPageEnd = this.state.leftPageEnd - 1
			}

			if(leftPageEnd > this.state.leftSidePageCount) {
				leftPageStart = this.state.leftPageStart
				leftPageEnd = this.state.leftSidePageCount
			}
		}

		let disablePreviousAction = true
		if(leftSideCurrentPage > 1) {
			disablePreviousAction = false
		}

		let disableNextAction = false
		if(leftSideCurrentPage === this.state.leftSidePageCount) {
			disableNextAction = true
		}

		this.setState({ leftSideCurrentPage, leftPageStart, leftPageEnd, leftSideStart: startRecord, disablePreviousAction, disableNextAction },
            () => this.leftSideTableRef.current.scrollIntoView()    
        )
	}

	/**
	 * Renders Asset Board
	 */
	render() {
		return (
			<div className='container-fluid overflow-auto flex-grow-1' onClick={ (event) => { this.handleClickOutside(event) } }>
				{this.state.showDlpThreatDetails ? (            
					<SidePanel
						closeViewSidePanel={() => this.setState({ showDlpThreatDetails: false })} 
						selectedProvider = {this.state.selectedProvider}
						start_time = {this.state.start_time && this.state.start_time}
						end_time = {this.state.end_time && this.state.end_time}
						majorControls = {this.state.majorControls ? this.state.majorControls : {}}
					/>
				) : null}
				<div className={`loaderOverlay ${this.state.showLoading ? '' : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
				<div className='row h-100'>
					<div className='col-sm-12 p-0'>
						<div className='title-section p-2'>
							<div className="row">
								<div className="col-5 mt-0">
									<h6 className="text-white m-0">DLP UEBA</h6>
									<p className="text-white m-0">Count board view of DLP ueba on your Cloud</p>
									<div className="row mt-3">
										<div className={`col-md-12 d-md-flex bd-highlight ${this.state.showLoading ? 'disabled' : ''}`}>
											<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} ref={this.providerRef} style={{maxWidth: '150px' }}>
												<label className="mb-1">Provider</label>
												<Select
													placeholder={'All'}
													menuIsOpen={this.state.providerIsOpen}
													isSearchable={false}
													//onFocus={this.openProivderMenu}
													className='f13 p-0 colorBlack reactSelectFilterDrop'
													value={({
														value: this.state.selectedProvider,
														label: this.state.selectedProvider
													})}
													options={this.state.providers.map(item => ({
														value: item.provider_name,
														label: item.provider_name,	
													}))}
													onChange={event =>  
														this.setState(
															{
																selectedProvider: event.value,
																changesInFilter: true
															}
														)
													}
												/>
											</div>
											<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1 ${!this.state.providers.length}`} ref={this.accountRef} style={{maxWidth: '150px' }}>
												<label className="mb-1">Account</label>
												<div className='dlpHeaderMultiSelection removeDropdownSearchBar'>
													<ReactMultiSelectCheckboxes						
														placeholderButtonLabel="All"
														menuIsOpen ={this.state.isAccountOpen}
														getDropdownButtonLabel={() => this.getMultiSelectedCount('account', this.state.selectedAccount)}
														value={this.state.selectedAccountName.map(user => ({
															value: user.value,
															label: user.label
														}))}
														onChange={arr => {
														this.handleMultiSelectChange('selectedAccount', arr ? arr : []) }}
														options={this.state.accounts.map(account => ({
															value: account.account_id,
															label: account.account_name,
														}))}
													/>
												</div>
											</div>
											<div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 ${!this.state.providers.length}`} ref={this.regionRef} style={{maxWidth: '150px'}}>
												<label className="mb-1">Region</label>
												<div className={`dlpHeaderMultiSelection ${this.state.regions && this.state.regions.length > 10 ? '' : 'removeDropdownSearchBar' } `}>
													<ReactMultiSelectCheckboxes						
														placeholderButtonLabel="Select"
														menuIsOpen ={this.state.isRegionOpen}
														getDropdownButtonLabel={() => this.getMultiSelectedCount('region', this.state.selectedRegion)}
														value={this.state.selectedRegionName.map(user => ({
															value: user.value,
															label: user.label
														}))}
														onChange={arr => {
														this.handleMultiSelectChange('selectedRegion', arr ? arr : []) }}
														options={this.state.regions && this.state.regions.map(region => ({
															value: region.region,
															label: region.name,
														}))}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-7 text-right">
									<div className={`d-flex justify-content-end align-self-center`}>
										<div className={`d-flex justify-content-between align-self-end`}>
										<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === 90 ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedFilterPeriod: 90 }, () => this.sliderPeriod())}>3 Months</span>
											<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === 30 ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedFilterPeriod: 30 }, () => this.sliderPeriod())}>1 Month</span>
											<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === 7 ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedFilterPeriod: 7 }, () => this.sliderPeriod())}>1 Week</span>
										</div>
										<div className={`bg-filterBlue text-white align-self-end p-1 f12 ml-2`}>
											{getPastDaysNew(this.state.sliderStartDate, this.state.sliderEndDate)}
										</div>
										<div className="align-self-center ml-3">
											<Datetime 
												value={momentDateGivenFormat(this.state.startSlice, 'DD MMM YYYY hh:mm A')}
												onChange={this.handleStartTimeChange}
												dateFormat={'DD MMM YYYY'}
												timeFormat={'hh:mm A'}
												inputProps={{ readOnly: true, disabled: this.state.showLoading }}
												className="w-100 mr-1 dateSliderInputReszie"
												isValidDate={previousDay()}
												closeOnSelect={true}
											/>
											<Datetime
												value={momentDateGivenFormat(this.state.endSlice, 'DD MMM YYYY hh:mm A')}
												onChange={this.handleEndTimeChange}
												dateFormat={'DD MMM YYYY'}
												timeFormat={'hh:mm A'}
												inputProps={{ readOnly: true, disabled: this.state.showLoading }}
												className="w-100 dateSliderInputReszie"
												isValidDate={previousDay()}
												closeOnSelect={true}
											/>
										</div>
										<div className={`ml-2 align-self-center`}>
											<div className="btn-group" role="group" aria-label="ueba-dash">
												<button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-right`} onClick={() => this.onSearch() }>Search</button>
												<button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-left`} onClick={() => this.onReset()}><i className='far fa-redo'></i></button>
											</div>
										</div>	
									</div>
								
									{this.state.datePickerSliderOptions && this.state.datePickerSliderSeries ?
										<div className={`w-100 mb-n3 ${this.state.showLoading ? 'disabled' : ''}`} >
											<Chart options={this.state.datePickerSliderOptions} series={this.state.datePickerSliderSeries} type="bar" height={80} />
										</div>
									: null}	
									<div className="d-flex">
										<div id="leftSliderSpan" className="f12">{momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM')}</div>
										<div id="rightSliderSpan" className="f12">{momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM')}</div>
									</div>
								</div>
							</div>
						</div>
						
						<div className={`bg-background`}>
							<div className={`box p-3 rounded-0 shadow-none bg-transparent`}>
								<div className="row">
									<div className="col-sm-12">
										<div className={`rounded mb-3 ${this.state.radialStackbarType !== 'Expand' ? 'p-3 bg-muted' : ''}`}>
											<div className="row text-white">
												<div className={`col-md-6 ${this.state.radialStackbarType !== 'Expand' ? '' : 'displayNone'}`}>
													<div className="bg-dark p-3 rounded counts_threads">
														<div className="row mb-2">
															<div className="col-sm-6 d-flex h-100">
																<div className="d-flex justify-content-between">
																	{this.state.radialStackbarType !== 'Expand' && this.state.threatCoverage && this.state.threatCoverage.length && this.state.threatCoverage.map((item, index) => {
																		return(
																			<div className={`${index ? 'ml-2' : ''}`}>
																				<ApexDonutChart
																					graphData={item}
																					legend={false}
																					showTotal={true}
																					showTotalLable={true}
																					showGraphHeading={false}
																					height={100}
																					width={100}
																					size={'70%'}
																					gradient={false}
																					gradientColor={['#A88CCC', '#D88ACF']}
																					colors={['#A88CCC', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7']}
																					className={"transparentTooltip"}
																				/>
																			</div>
																		)
																	})}						
																</div>
															</div>
															<div className="col-sm-6 align-self-center">
																<h6>Lorem Ipsum</h6>
																<p className="small text-muted">Officia amet eiusmod eu sunt tempor</p>
																<div className="d-flex flex-column mb-3">
																	<div className="d-flex">
																		<h2 className="mr-3 border-right pr-3 mb-0 align-self-center text-white">
																		686</h2>
																		<div>
																			<p className="mb-0 text-primary font-weight-bold">Sit Amet</p>
																			<p className="small align-self-end mb-0">Sed in semper ex. Nulla
																				sed finibus</p>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														
														<div className="row mb-1">
															<div className="col-sm-6">
																{this.state.radialStackbarType !== 'Expand' && this.state.dayWiseCounts && Object.entries(this.state.dayWiseCounts).length ?
																	<div className="mt-n2"> 
																		<ApexRiskStackedBarChart
																			graphData={this.state.dayWiseCounts }
																			graphRiskCountSection={true}
																			sparkline={false}
																			yaxis={true}
																			yaxisLabel={true}
																			xaxis={true}
																			xaxisFormat={'string'}
																			xaxisLabel={true}
																			axisLabelColor={'#50586A'}
																			legend={false}
																			stacked={true}
																			height={220}
																			horizontal={false}
																			barHeight={'40%'}
																			barEndShape={'flat'}
																			columnWidth={'25%'}
																			backgroundBarShape={'rounded'}
																			backgroundBarColors={['#333947']}
																			showBackgroundBarColors={true}
																			className={"transparentTooltip"}
																			colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']}
																		/>
																	</div>
																: null}
															</div>
															<div className="col-sm-6">
																<p><b>Top Threads</b></p>
																{this.state.radialStackbarType !== 'Expand' && this.state.topThreatsGraphData && Object.entries(this.state.topThreatsGraphData).length ?
																	<div className="mt-n2"> 
																		<ApexBarChart
																			graphData={this.state.topThreatsGraphData ? this.state.topThreatsGraphData : {}}
																			sparkline={false}
																			yaxis={false}
																			yaxisLabel={false}
																			xaxis={true}
																			xaxisFormat={'string'}
																			xaxisLabel={true}
																			axisLabelColor={'#B8BBBE'}
																			paddingLeft={-25}
																			legend={false}
																			stacked={false}
																			height={280}
																			horizontal={true}
																			barHeight={'40%'}
																			barEndShape={'rounded'}
																			columnWidth={'25%'}
																			gradient={true}
																			gradientColor={['#039BE5', '#5F5BA2']}
																			hideTooltipValue={true}
																			backgroundBarShape={'rounded'}
																			backgroundBarColors={['#333947']}
																			showBackgroundBarColors={false}
																			className={"transparentTooltip"}
																		/>
																	</div>
																: null}
															</div>
														</div>
														<div className="row">
															<div className="col-md-12">
																<div className="bg-muted rounded d-flex p-3">
																	{this.state.radialStackbarType !== 'Expand' && this.state.newGraphData && Object.entries(this.state.newGraphData).length ?
																		<div className="w-33 mr-2">
																			<div className="d-flex">
																				<h5 className="">
																					<small className="mr-2">New</small>
																					<b>{this.state.newFailSum}</b>
																					<span className="far fa-arrow-up text-success mr-2"></span>
																					<small>10% day</small>
																				</h5>
																			</div>
																			<div className="d-flex">
																				<div className="mr-3 text-primary-2">
																					<p className="mb-0 font-weight-bold">254</p>
																					<p className="small font-weight-bold align-self-end">Critical
																					</p>
																				</div>
																				<div className="mr-3 text-purple-2">
																					<p className="mb-0 font-weight-bold">258</p>
																					<p className="small font-weight-bold align-self-end">High</p>
																				</div>
																				<div className="mr-3 text-info-50">
																					<p className="mb-0 font-weight-bold">180</p>
																					<p className="small font-weight-bold align-self-end">Medium
																					</p>
																				</div>
																				<div className="text-success-2">
																					<p className="mb-0 font-weight-bold">357</p>
																					<p className="small font-weight-bold align-self-end">Low</p>
																				</div>
																			</div>
																			<div className='w-100 ml-n2 mt-n4 mb-n4'>
																				<ApexAreaChart 
																					graphData={this.state.newGraphData}
																					name={'New'}
																					xaxis={false}
																					yaxis={true}
																					yaxisLabe={false}
																					markerSize={2}
																					hideMarkerLength={30}
																					gradient={true}
																					gradientColor={['#775BA2', '#775BA2']}
																					colors={['#775BA2']}
																					sparkline={false}
																					zoom={false}
																					height={90}
																					width={'100%'}
																					grid={true}
																					className={"transparentTooltip"}
																				/>
																			</div>

																		</div>
																	: null}
																	{this.state.radialStackbarType !== 'Expand' && this.state.pendingGraphData && Object.entries(this.state.pendingGraphData).length ?
																		<div className="w-33 mr-2">
																			<div className="d-flex">
																				<h5 className="">
																					<small className="mr-2">Pending</small>
																					<b>{this.state.pendingFailSum}</b>
																					<span className="far fa-arrow-up text-success mr-2"></span>
																					<small>10% day</small>
																				</h5>
																			</div>
																			<div className="d-flex">
																				<div className="mr-3 text-primary-2">
																					<p className="mb-0 font-weight-bold">254</p>
																					<p className="small font-weight-bold align-self-end">Critical
																					</p>
																				</div>
																				<div className="mr-3 text-purple-2">
																					<p className="mb-0 font-weight-bold">258</p>
																					<p className="small font-weight-bold align-self-end">High</p>
																				</div>
																				<div className="mr-3 text-info-50">
																					<p className="mb-0 font-weight-bold">180</p>
																					<p className="small font-weight-bold align-self-end">Medium
																					</p>
																				</div>
																				<div className="text-success-2">
																					<p className="mb-0 font-weight-bold">357</p>
																					<p className="small font-weight-bold align-self-end">Low</p>
																				</div>
																			</div>
																			<div className='w-100 ml-n2 mt-n4 mb-n4'>
																				<ApexAreaChart 
																					graphData={this.state.pendingGraphData}
																					name={'Pending'}
																					xaxis={false}
																					yaxis={true}
																					yaxisLabe={false}
																					markerSize={2}
																					hideMarkerLength={30}
																					gradient={true}
																					gradientColor={['#775BA2', '#775BA2']}
																					colors={['#775BA2']}
																					sparkline={false}
																					zoom={false}
																					height={90}
																					width={'100%'}
																					grid={true}
																					className={"transparentTooltip"}				
																				/>
																			</div>

																		</div>
																	: null}
																	{this.state.radialStackbarType !== 'Expand' && this.state.resolvedGraphData && Object.entries(this.state.resolvedGraphData).length ?
																		<div className="w-33 mr-2">
																			<div className="d-flex">
																				<h5 className="">
																					<small className="mr-2">Resolved</small>
																					<b>{this.state.resolvedFailSum}</b>
																					<span className="far fa-arrow-up text-success mr-2"></span>
																					<small>10% day</small>
																				</h5>
																			</div>
																			<div className="d-flex">
																				<div className="mr-3 text-primary-2">
																					<p className="mb-0 font-weight-bold">254</p>
																					<p className="small font-weight-bold align-self-end">Critical
																					</p>
																				</div>
																				<div className="mr-3 text-purple-2">
																					<p className="mb-0 font-weight-bold">258</p>
																					<p className="small font-weight-bold align-self-end">High</p>
																				</div>
																				<div className="mr-3 text-info-50">
																					<p className="mb-0 font-weight-bold">180</p>
																					<p className="small font-weight-bold align-self-end">Medium
																					</p>
																				</div>
																				<div className="text-success-2">
																					<p className="mb-0 font-weight-bold">357</p>
																					<p className="small font-weight-bold align-self-end">Low</p>
																				</div>
																			</div>
																			<div className='w-100 ml-n2 mt-n4 mb-n4'>
																				<ApexAreaChart 
																					graphData={this.state.resolvedGraphData}
																					name={'Resolved'}
																					xaxis={false}
																					yaxis={true}
																					yaxisLabe={false}
																					markerSize={2}
																					hideMarkerLength={30}
																					gradient={true}
																					gradientColor={['#775BA2', '#775BA2']}
																					colors={['#775BA2']}
																					sparkline={false}
																					zoom={false}
																					height={90}
																					width={'100%'}
																					grid={true}
																					className={"transparentTooltip"}
																				/>
																			</div>
																		</div>
																	: null}
																</div>
															</div>
														</div>
													</div>
												</div>

												<div className={`col-sm-6 borderMiddleBlue ${this.state.radialStackbarType === 'Expand' ? '' : 'displayNone'}`} ref={this.leftSideTableRef}>
													<div className="bg-muted p-3 rounded h-100">
														<div className="col-md-12">
															<div className="row">
																<div className="col-lg-6 p-0" ref={this.leftRadialStackedBarWidthRef}>
																	<div className="d-flex">
																		<label className="align-self-center mr-2 mb-0">Threats By</label>
																		<div className={`form-group bd-highlight mb-sm-1 styled-feild w-30 ${this.state.radialStackbarType === 'Expand' ? '' : 'displayNone'}`}>
																			<select className="form-control" 
																				value={this.state.userChartType}
																				onChange={event => {
																					this.setState(
																						{
																							userChartType: event.target.value
																						},
																						() => {
																							this.onChangeGetcharts('user')
																							this.getInitializePaginationLeft()
																						}
																					)
																				}}>
																				<option value='user'>User</option>
																				<option value='userRisk'>User Risk</option>
																			</select>
																		</div>
																	</div>
																	<p className="small text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit, </p>
																	{this.state.radialStackbarType === 'Expand' && this.state.showLeftSideRadialChart ?
																		<StackedCircleBarChart
																			ID={this.state.leftSideGraphId}
																			svgID={this.state.leftSideSvgGraphId}
																			width={this.leftRadialStackedBarWidthRef.current.offsetWidth}
																			data={this.state.leftSideArray}
																			keys={this.state.leftSideKeys}
																			highestValue={this.state.leftSideHighest}
																			hoverData={this.state.leftSideTotalData}
																			totalValue={this.state.leftSideTotal}
																			tableData={(data, State) => this.leftSideDataFormat(data, State)}
																			legendText={'Threats'}
																			colors={this.state.leftSideGraphColors}
																		/>
																	: null}
																	
																	{this.state.showLeftSideTrendChart ?
																		<div className="mt-4">
																			<ApexBarChart
																				graphData={this.state.leftSideTrendData ? this.state.leftSideTrendData[this.state.leftSideStateName] : {}}
																				sparkline={false}
																				yaxis={false}
																				xaxis={true}
																				xaxisLabel={true}
																				axisLabelColor={'#B8BBBE'}
																				legend={false}
																				stacked={false}
																				height={150}
																				horizontal={false}
																				barHeight={'40%'}
																				barEndShape={'rounded'}
																				barRadius={4}
																				columnWidth={'25%'}
																				gradient={true}
																				gradientColor={['#19B2C0', '#CBF2D5']}
																				backgroundBarShape={'rounded'}
																				backgroundBarColors={['#333947']}
																				showBackgroundBarColors={false}
																				className={"transparentTooltip"}
																			/>
																		</div>
																	: null}
																	
																	{this.state.showLeftSideRiskTrendChart ?
																		<div className="mt-4"> 
																			<ApexRiskStackedBarChart
																				graphData={this.state.leftSideTrendData ? this.state.leftSideTrendData[this.state.leftSideStateName] : {}}
																				graphRiskCountSection={false}
																				sparkline={false}
																				yaxis={false}
																				yaxisLabel={false}
																				xaxis={true}
																				xaxisFormat={'date'}
																				xaxisLabel={true}
																				axisLabelColor={'#B8BBBE'}
																				legend={false}
																				stacked={true}
																				height={150}
																				horizontal={false}
																				barHeight={'40%'}
																				barEndShape={'flat'}
																				columnWidth={'25%'}
																				backgroundBarShape={'rounded'}
																				backgroundBarColors={['#333947']}
																				showBackgroundBarColors={true}
																				className={"transparentTooltip"}
																				colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']}
																			/>
																		</div>
																	: null}
																</div>
																<div className="col-lg-6 mt-4">
																	<div className={`${this.state.radialStackbarType === 'Expand' ? '' : 'displayNone'}`}>
																		{/* <p className="mb-1">{this.state.leftSideSummary ? this.state.leftSideSummary : ''}</p> */}
																		<p className="small mb-0">
																			Lorem ipsum dolor sit amet, 
																			<p className="text-muted small">(adipiscing elit, sed do eiusmod tempor incididunt ut)</p>
																		</p>
																		{/* <p className="mb-1">{this.state.leftSideSummary ? this.state.leftSideSummary : ''}</p> */}
																		<div className="d-flex text-warning">
																			<h4 className="mb-0 mr-3">0325</h4>
																			<h3 className="mb-0 mr-2"><i className="fad fa-map-marker-alt"></i></h3>
																			<h3 className="mb-0 mr-2"><i className="fad fa-map-marker-alt"></i></h3>
																			<h3 className="mb-0 mr-2"><i className="fad fa-map-marker-alt"></i></h3>
																			<div className="flex-row ml-1">
																				<p className="mb-0 text-nowrap small">2032, 2031</p>
																				<p className="mb-0 text-nowrap small">2032, 2031</p>
																			</div>
																		</div>
																		<div className="radialTableSection" style={{minHeight: "300px"}}>
																			{this.state.leftSideTableData && Object.entries(this.state.leftSideTableData).length ?
																				<div className="" style={{minHeight: "260px"}}>
																					{Object.entries(this.state.leftSideTableData).slice(this.state.leftSideStart, (this.state.leftSideStart + this.state.leftSidePerPage)).map(([key, value]) => {
																						return(
																							<div className="mt-2 col-12 p-0 bg-dark">
																								<div className="count border-info d-flex p-2">
																									<h5 className="m-0 w-20">{value}</h5>
																									<p className="m-0 align-self-end ml-2 small">{key}</p>
																								</div>
																							</div>
																						)
																					})}
																				</div>
																			: null}
																			
																			{this.state.leftSidePageCount > 1 ?
																				<div className="pagination mt-3 justify-content-center d-flex">
																					<span className={`${this.state.disablePreviousAction ? 'disabled text-muted' : ''}`} onClick={(e) => {e.preventDefault(); this.navigateLeftSidePage('start', this.state.leftSideCurrentPage)}}><i className="fas fa-chevron-double-left"></i></span>
																					<span className={`${this.state.disablePreviousAction ? 'disabled text-muted' : ''}`} onClick={(e) => {e.preventDefault(); this.navigateLeftSidePage('previous', this.state.leftSideCurrentPage)}}><i className="fas fa-chevron-left"></i></span>

																					{this.state.leftSidePageCountArray.slice(this.state.ritPageStart, this.state.ritPageEnd).map((pag, index) => {
																						return(
																							<span  className={`${this.state.leftSideCurrentPage === pag ? 'active' : ''}`} onClick={(e) => {e.preventDefault(); this.navigateLeftSidePage('page', (index+1))}}>{pag}</span>
																						)
																					})}
																					<span className={`${this.state.disableNextAction ? 'disabled text-muted' : ''}`} onClick={(e) => {e.preventDefault(); this.navigateLeftSidePage('next', this.state.leftSideCurrentPage)}}><i className="fas fa-chevron-right"></i></span>
																					<span className={`${this.state.disableNextAction ? 'disabled text-muted' : ''}`} onClick={(e) => {e.preventDefault(); this.navigateLeftSidePage('end', this.state.leftSideCurrentPage)}}><i className="fas fa-chevron-double-right"></i></span>
																				</div>
																			: null}
																		</div>
																		<div  className="pt-5" style={{minHeight: "240px"}} ref={this.leftHorizontalLimitLollipopRef}>
																			<div className={`${this.state.radialStackbarType === 'Expand' ? '' : 'displayNone'}`}>
																				{this.state.showLeftSideLollipopChart ? 
																					<React.Fragment>
																						<p className="mb-0 mt-2 small">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
																						<HorizontalLimitLollipopChart
																							width={this.leftHorizontalLimitLollipopRef.current.offsetWidth - 20}
																							height={170}
																							ID={'leftSideLollipop'}
																							graphData={this.state.leftSideTopStatsData ? this.state.leftSideTopStatsData[this.state.leftSideStateName] : {}}
																							showEndPointCircle={true}
																							colors={['#039BE5', '#039BE5', '#FFD200', '#FFD200']}
																						/>
																					</React.Fragment>
																				: null}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className={`col-md-6 mb-n4`} ref={this.rightSideTableRef}>
													<div className={`${this.state.radialStackbarType === 'Expand' ? 'bg-muted p-3 rounded' : ''}`}>
														<div className="col-md-12">
															<div className="row">
																<div className={`col-lg-6 p-0`} ref={this.rightRadialStackedBarWidthRef}>
																	{this.state.radialStackbarType !== 'Expand' ?
																		<div className="">
																			<p className="mb-0">Threats by Buckets</p>
																			<p className="small text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</p>
																		</div>											
																	:
																		<div className="">
																			<div className="d-flex">
																				<label className="align-self-center mr-2 mb-0">Threats By</label>
																				<div className={`form-group bd-highlight mb-sm-1 styled-feild w-30 ${this.state.radialStackbarType === 'Expand' ? '' : 'displayNone'}`}>
																					<select className="form-control" 
																						value={this.state.entityChartType}
																						onChange={event => {
																							this.setState(
																								{
																									entityChartType: event.target.value
																								},
																								() => {
																									this.onChangeGetcharts('entity')
																									this.getInitializePaginationRight()
																								}
																							)
																						}}>
																						<option value='entity'>Entity</option>
																						<option value='entityRisk'>Entity Risk</option>
																					</select>
																				</div>
																			</div>
																			<p className="small text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit, </p>
																		</div>
																	}
																	{this.state.showRightSideRadialChart ? 
																		<StackedCircleBarChart
																			ID={this.state.rightSideGraphId}
																			svgID={this.state.rightSideSvgGraphId}
																			width={
																				this.state.radialStackbarType === 'Expand' ? 
																					this.leftRadialStackedBarWidthRef.current.offsetWidth
																				: 
																					this.rightRadialStackedBarWidthRef.current.offsetWidth
																			}
																			data={this.state.rightSideArray}
																			keys={this.state.rightSideKeys}
																			highestValue={this.state.rightSideHighest}
																			hoverData={this.state.rightSideTotalData}
																			totalValue={this.state.rightSideTotal}
																			tableData={(data, State) => this.rightSideDataFormat(data, State)}
																			legendText={'Threats'}
																			colors={this.state.rightSideGraphColors}
																		/>
																	: null}
																	{this.state.showRightSideTrendChart ?
																		<div className="mt-4">
																			<ApexBarChart
																				graphData={this.state.rightSideTrendData ? this.state.rightSideTrendData[this.state.rightSideStateName] : {}}
																				sparkline={false}
																				yaxis={false}
																				xaxis={true}
																				xaxisLabel={true}
																				axisLabelColor={'#B8BBBE'}
																				legend={false}
																				stacked={false}
																				height={150}
																				horizontal={false}
																				barHeight={'40%'}
																				barEndShape={'rounded'}
																				barRadius={4}
																				columnWidth={'25%'}
																				gradient={true}
																				gradientColor={['#19B2C0', '#CBF2D5']}
																				backgroundBarShape={'rounded'}
																				backgroundBarColors={['#333947']}
																				showBackgroundBarColors={false}
																				className={"transparentTooltip"}
																			/>
																		</div>
																	: null}
																	{this.state.showRightSideRiskTrendChart ?
																		<div className="mt-4"> 
																			<ApexRiskStackedBarChart
																				graphData={this.state.rightSideTrendData ? this.state.rightSideTrendData[this.state.rightSideStateName] : {}}
																				graphRiskCountSection={false}
																				sparkline={false}
																				yaxis={false}
																				yaxisLabel={false}
																				xaxis={true}
																				xaxisFormat={'date'}
																				xaxisLabel={true}
																				axisLabelColor={'#B8BBBE'}
																				legend={false}
																				stacked={true}
																				height={150}
																				horizontal={false}
																				barHeight={'40%'}
																				barEndShape={'flat'}
																				columnWidth={'25%'}
																				backgroundBarShape={'rounded'}
																				backgroundBarColors={['#333947']}
																				showBackgroundBarColors={true}
																				className={"transparentTooltip"}
																				colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']}
																			/>
																		</div>
																	: null}
																</div>
																<div className="col-lg-6">
																	{this.state.radialStackbarType !== 'Expand' ?
																		<span className={`justify-content-end d-flex`}>
																			<i className="fa fa-expand-wide cursorPointer" 
																				onClick={() => this.setState({ radialStackbarType: 'Expand', entityChartType: 'entity', userChartType: 'user' },
																					() => {
																						this.onChangeGetcharts('')
																					}
																				)}
																			></i>
																		</span>
																	:
																		<span className={`justify-content-end d-flex`}>
																			<i className="fa fa-compress cursorPointer" 
																				onClick={() => this.setState({ radialStackbarType: 'Compress', entityChartType: '', userChartType: '' },
																					() => {
																						this.onChangeGetcharts('')
																					}
																				)}
																			></i>
																		</span>
																	}
																	<p className={`small mb-0 ${this.state.radialStackbarType !== 'Expand' ? 'mt-2' : 'mt-1'}`}>
																		Lorem ipsum dolor sit amet, 
																		<p className="text-muted small">(adipiscing elit, sed do eiusmod tempor incididunt ut)</p>
																	</p>
																	{/* <p className="mb-1">{this.state.rightSideSummary ? this.state.rightSideSummary : ''}</p> */}
																	<div className="d-flex text-warning">
																		<h4 className="mb-0 mr-3">0325</h4>
																		<h3 className="mb-0 mr-2"><i className="fad fa-map-marker-alt"></i></h3>
																		<h3 className="mb-0 mr-2"><i className="fad fa-map-marker-alt"></i></h3>
																		<h3 className="mb-0 mr-2"><i className="fad fa-map-marker-alt"></i></h3>
																		<div className="flex-row ml-1">
																			<p className="mb-0 text-nowrap small">2032, 2031</p>
																			<p className="mb-0 text-nowrap small">2032, 2031</p>
																		</div>
																	</div>
																	<div className="radialTableSection mt-3" style={{minHeight: "300px"}}>
																		{this.state.rightSideTableData && Object.entries(this.state.rightSideTableData).length ?
																			<div className="" style={{minHeight: "260px"}}>
																				{Object.entries(this.state.rightSideTableData).slice(this.state.rightSideStart, (this.state.rightSideStart + this.state.rightSidePerPage)).map(([key, value]) => {
																					return(
																						<div className="mt-2 col-12 p-0 bg-dark">
																							<div className="count border-info d-flex p-2">
																								<h5 className="m-0 w-20">{value}</h5>
																								<p className="m-0 align-self-end ml-2 small">{key}</p>
																							</div>
																						</div>
																					)
																				})}
																			</div>
																		: null}
																		{this.state.rightSidePageCount > 1 ?
																			<div className="pagination mt-3 justify-content-center d-flex">
																				<span className={`${this.state.disableLeftAction ? 'disabled text-muted' : ''}`} onClick={(e) => {e.preventDefault(); this.navigatePage('start', this.state.currentPage)}}><i className="fas fa-chevron-double-left"></i></span>
																				<span className={`${this.state.disableLeftAction ? 'disabled text-muted' : ''}`} onClick={(e) => {e.preventDefault(); this.navigatePage('previous', this.state.currentPage)}}><i className="fas fa-chevron-left"></i></span>

																				{this.state.rightSidePageCountArray.slice(this.state.ritPageStart, this.state.ritPageEnd).map((pag, index) => {
																					return(
																						<span  className={`${this.state.currentPage === pag ? 'active' : ''}`} onClick={(e) => {e.preventDefault(); this.navigatePage('page', (index+1))}}>{pag}</span>
																					)
																				})}
																				<span className={`${this.state.disableRightAction ? 'disabled text-muted' : ''}`} onClick={(e) => {e.preventDefault(); this.navigatePage('next', this.state.currentPage)}}><i className="fas fa-chevron-right"></i></span>
																				<span className={`${this.state.disableRightAction ? 'disabled text-muted' : ''}`} onClick={(e) => {e.preventDefault(); this.navigatePage('end', this.state.currentPage)}}><i className="fas fa-chevron-double-right"></i></span>
																			</div>
																		: null}
																	</div>
																	<div className="pt-5" style={{minHeight: "240px"}} ref={this.rightHorizontalLimitLollipopRef} >
																	{this.state.showRightSideLollipopChart ? 
																		<React.Fragment>
																			<p className="mb-0 mt-2 small">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
																			<HorizontalLimitLollipopChart
																				width={this.rightHorizontalLimitLollipopRef.current.offsetWidth}
																				height={170}
																				ID={'rightSideLollipop'}
																				graphData={this.state.rightSideTopStatsData ? this.state.rightSideTopStatsData[this.state.rightSideStateName] : {}}
																				showEndPointCircle={true}
																				colors={['#039BE5', '#039BE5', '#FFD200', '#FFD200']}
																			/>
																		</React.Fragment>
																	: null}
																</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										
										{this.state.complianceControlDetails ?
											<div className="row">
												<div className="col-sm-12">
													<div className="bg-gray5 p-2 rounded">
														<div className="col-sm-12 ">
															<div className="row">
																<div className="col-sm-8">
																	<p className="font-weight-bold text-white mb-0">Status by controls</p>
																	<p className="small f12 mb-2 text-gray3">Find the category wise listing of identified vulnerabilities on account for the audit policy <b>{this.state.selectedCompliance}</b></p>
																</div>                                            
																<div className="col-sm-4">
																	{this.state.complianceControlDetails.length ?
																		<SearchControls
																			data={this.state.complianceControlDetails && this.state.complianceControlDetails}
																			filteredData={filteredArray => { this.setState({ filteredArray }) }}
																			className={'dlpSerachControlsPlaceholder'}
																			inputClassName={'text-gray5 bg-white4'}
																			searchIconColor={'text-white4'}
																			ref={this.searchControlComponent}
																		/>
																	: null}
																</div>
															</div>
														</div>
														{this.state.filteredArray && this.state.filteredArray.length ?
															this.state.filteredArray.map((item,i) =>{
																return(
																	<div className="border-gray">
																		<div className="row m-2 pb-1">
																		<div className="col-sm-6 align-self-center d-flex p-0">
																			<button className="border-0 text-white bg-dark text-center p-2 mr-2">
																				<b>{splitterFirstPart(item.control, ':')}</b>
																			</button>
																			<p className="mb-0 text-white align-self-center"> : {splitterSecondPart(item.control, ':')}</p>
																		</div>
																		<div className="col-sm-4 align-self-center">
																			<label className="small mb-0 d-block text-white"><b>{item.count_description}</b></label>
																		</div>
																		<div className="col-sm-2">
																			<div className="d-flex justify-content-end">
																				<button className="btn btn-primary btn-sm text-right" onClick={() => this.setState({ showDlpThreatDetails: true, majorControls: item })}>Details</button>
																			</div>
																		</div>
																	</div>
																</div>
																)
															})
														: 
															<div className='d-flex justify-content-center m-4 text-white'>
																<p>Your search did not match any records. Please try different keywords.</p>
															</div>
														}
													</div>
												</div>
											</div>
										: null}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
UebaDashboard.propTypes = { 
	getDayWiseCount: PropTypes.func,    
	getComplianceControlDetails: PropTypes.func,
	getThreatCoverageAndTopStats: PropTypes.func,
	getTransitionTrend: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log(state,'--------------')
	return {
		providers: state.filters.providers,
		accounts: state.filters.accounts,
		regions: state.filters.regions,
	}
}

export default AppWrapper(UebaDashboard, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
	getDayWiseCount,    
	getComplianceControlDetails,
	getTransitionTrend,
	getThreatCoverageAndTopStats,
})