/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Diagnostics.js
 * @author Prakash // on 13/01/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import { UncontrolledTooltip } from 'reactstrap'

import { momentConvertionUtcToLocalTime, getRegionName, getAccountNameFromId, twoDateDiffrenceDayHoursMinutes, capitalizeFirstLetter, addMinutes, subMinutes } from '../../utils/utility'
import ResizeableDarkThemeTable from '../designComponents/Table/ResizeableDarkThemeTable'
import { listAllAccounts, listAllRegions } from '../../actions/commonAction'
import { getChangeImpactAnalysisResults } from '../../actions/aiops/AiopsAction'

import Search from '../common/SearchComponent'

import SreMetricDetails from './SreMetricDetails'
import MetricsSection from './MetricsSection'
import TrafficMetrics from './TrafficMetrics'
import InterventionMetrics from './InterventionMetrics'
import SelectedMonitoringMetrics from './SelectedMonitoringMetrics'
import SelectedSreMetrics from './SelectedSreMetrics'

import DbSlowQueryMySQL from './DbSlowQueryMySQL'
import DbSlowQueryPostgreSQL from './DbSlowQueryPostgreSQL'
import Reachability from './Reachability'
import AthenaAnalysis from './AthenaAnalysis'
import MonitoringTab from './MonitoringTab'

import ApexGandalfBarChart from '../common/charts/ApexGandalfBarChart'
import ApexGandalfLineChart from '../common/charts/ApexGandalfLineChart'
import ApexBarProgressChart from '../common/charts/ApexBarProgressChart'
import ApexGandalfInterventionChart from '../common/charts/ApexGandalfInterventionChart'

class DiagnosticsRightSection extends Component {
    constructor(props) {
        super(props)
        this.annotationTypeRef = React.createRef()
        // this.scrolltoTop = React.createRef()
        this.state = {
            minMaxHeight: "700px",
            accounts: [],
            regions: [],

            startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
			overAllTotalPages: 1,
			perPage: 10,
            series: [],
            options: {}
        }
    }

    componentDidMount = () => {
        this.getWindowSize()

        window.addEventListener('resize', () => {
            this.getWindowSize()
        })
    }
    

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 15  
		let outerHeight = (window.outerHeight - browserBorder - headerHeight) +"px"

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }

    componentDidUpdate = (prevProps) => {
        if(!_.isEqual(prevProps.selectedDiagnosticEvent, this.props.selectedDiagnosticEvent) && this.props.selectedDiagnosticEvent && Object.entries(this.props.selectedDiagnosticEvent).length) {
            let mertcisAnnotationTypes = [
                // {prefix: 'CS', label: ' - Case Start Time', value: 'CS'}, 
                // {prefix: 'CE', label: ' - Case End Time', value: 'CE'}
            ]
            if(this.props.selectedDiagnosticEvent.label === 'MySQL DB Changes' || this.props.selectedDiagnosticEvent.label === 'DB Audit PostgreSQL' || this.props.selectedDiagnosticEvent.label === 'Capacity Changes' || this.props.selectedDiagnosticEvent.label === 'General Changes' || this.props.selectedDiagnosticEvent.label === 'SRE Observations') {
                mertcisAnnotationTypes.push({prefix: 'C', label: ' - Change', value: 'C'})
            } else if(this.props.selectedDiagnosticEvent.label === 'Health Events' || this.props.selectedDiagnosticEvent.label === 'Maintenance and Scheduled Events' || this.props.selectedDiagnosticEvent.label === 'DB SlowQuery Events' || this.props.selectedDiagnosticEvent.label === 'Connectivity Checks') {
                mertcisAnnotationTypes.push({prefix: 'E', label: ' - Event', value: 'E'})
            }
            this.setState({ 
                reloadPage: true,
                selectedDiagnosticEventLabel: this.props.selectedDiagnosticEvent.label,
                selectedDiagnosticEventTag: this.props.selectedDiagnosticEvent.eventGroup,
                selectedDiagnosticEvent: this.props.selectedDiagnosticEvent.data, 
                assetDetails: this.props.selectedDiagnosticEvent.asset_details,
                mertcisAnnotationTypes,
                showMeticsChart: false,
                showSelectedMonitoringMetrics: false,
                showSelecteSreMetrics: false,
                showMonitoringChart: false,
                showInterventionMetrics: false,
                assetsList: [],
                filteredAssetArray: [],
                gandalfBarData: {},
            },
                () => {
                    this.setState({ reloadPage: false })
                    if(this.state.selectedDiagnosticEventLabel === "General Changes") {
                        this.getChangeImpactAnalysisResults()
                    }
                    // this.scrolltoTop.current.scrollIntoView()
                    // this.getRegionAccountDetails()
                    // let lastTwowString = this.state.selectedDiagnosticEventLabel.match(/[^ ]* [^ ]*$/g))
                    // let string = this.state.selectedDiagnosticEventLabel.split(" ").splice(-2).join(" ")
                    let lastTwoString = this.state.selectedDiagnosticEventLabel.split(" ").splice(-2).join(" ")
                    if(lastTwoString === 'Service Limits') {
                        this.setState({ showMonitoringChart: true })
                    } else if(this.state.selectedDiagnosticEventTag === "Alerts" || this.state.selectedDiagnosticEventTag === "Latency" || this.state.selectedDiagnosticEventTag === "ErrorRate") {
                        if(this.state.assetDetails && this.state.assetDetails.asset_id) {
                            this.setState({ showMeticsChart: true })

                            if(this.state.selectedDiagnosticEventTag === "Alerts") {
                                this.setState({ showSelectedMonitoringMetrics: true })
                            } else if(this.state.selectedDiagnosticEventTag === "Latency" || this.state.selectedDiagnosticEventTag === "ErrorRate") {
                                this.setState({ showSelecteSreMetrics: true })
                            }
                        }
                    } else if(this.state.selectedDiagnosticEventTag === "Backup" || this.state.selectedDiagnosticEventTag === "Failover" || this.state.selectedDiagnosticEventTag === "Failure" || this.state.selectedDiagnosticEventTag === "Replication" || this.state.selectedDiagnosticEventTag === "Restoration" || this.state.selectedDiagnosticEventTag === "Recovery" || this.state.selectedDiagnosticEventTag === "Errors") {
                        if(this.state.assetDetails && this.state.assetDetails.asset_id) {
                            this.setState({ showMeticsChart: true })
                        }
                    } else if(this.state.selectedDiagnosticEventTag === "Anomalies") {
                        if(this.state.assetDetails && this.state.assetDetails.asset_id) {
                            this.setState({ showMeticsChart: true })
                        }
                    } else if(this.state.selectedDiagnosticEventLabel === "assets_list") {
                        let assetsList = this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.assets ? this.state.selectedDiagnosticEvent.assets : []
                        let totalPages = 1
                        if(assetsList.length > this.state.perPage) {
                            totalPages = Math.ceil(assetsList.length / this.state.perPage)
                        }
                        this.setState({ assetsList, filteredAssetArray: assetsList, totalPages })
                    } else if(this.state.selectedDiagnosticEventLabel === 'Application Changes' || this.state.selectedDiagnosticEventLabel === 'Capacity Changes') {
                        this.setState({ showInterventionMetrics: true })
                    } else if(this.state.selectedDiagnosticEventLabel === "Security Changes" || this.props.selectedDiagnosticEvent.label === 'Performance Changes' || this.props.selectedDiagnosticEvent.label === 'Network Changes' || this.props.selectedDiagnosticEvent.label === 'Configuration Changes' || this.props.selectedDiagnosticEvent.label === 'Tag Changes' || this.props.selectedDiagnosticEvent.label === 'Policy Changes' || this.props.selectedDiagnosticEvent.label === 'Disk Changes') {
                        this.formatChangesEvent()
                        if(this.state.assetDetails && this.state.assetDetails.asset_id) {
                            this.setState({ showMeticsChart: true })
                        }
                    } else if(this.state.selectedDiagnosticEventLabel !== 'DB SlowQuery MySQL' && this.state.selectedDiagnosticEventLabel !== 'DB SlowQuery PostgreSQL' && this.state.selectedDiagnosticEventLabel !== 'Reachability Analysis' && this.state.selectedDiagnosticEventLabel !== 'Athena Analysis' && this.state.selectedDiagnosticEventLabel !== 'Traffic Surge') {
                        if(this.state.assetDetails && this.state.assetDetails.asset_id) {
                            this.setState({ showMeticsChart: true })
                        }
                    }
                }
            )
        } else if(prevProps.selectedDiagnosticEvent !== this.props.selectedDiagnosticEvent && this.props.selectedDiagnosticEvent && !Object.entries(this.props.selectedDiagnosticEvent).length) {
            this.setState({ 
                selectedDiagnosticEventLabel: '', 
                selectedDiagnosticEvent: {}, 
                assetDetails: {}
            })
        }
    }
    
    getChangeImpactAnalysisResults = () => {
        if(this.state.selectedDiagnosticEvent.event_id) {
            let params = {}
            params.event_id = this.state.selectedDiagnosticEvent.event_id
            params.impacted_events = false

            this.props.getChangeImpactAnalysisResults(params, (promise, response) => {
                if(promise) {
                    this.setState({ impactAnalysis: response },
                        () => {
                            if(Object.entries(this.state.impactAnalysis).length) {
                                this.structureImpactAnalysis()      
                            }                  
                        }
                    )
                } else {
                    this.setState({ impactAnalysis: [] })
                }
            })
        }
    }

    structureImpactAnalysis = () => {
        let impactAnalysis = this.state.impactAnalysis

        let eventTime = impactAnalysis.change_event_time ? momentConvertionUtcToLocalTime(impactAnalysis.change_event_time, "YYYY-MM-DD HH:mm:ss") : ""        
        let startTime = impactAnalysis.counter_start_time ? momentConvertionUtcToLocalTime(impactAnalysis.counter_start_time, "YYYY-MM-DD HH:mm:ss") : ""
        let addMinuteToStartTime = addMinutes(startTime, 1)
        let endTime = impactAnalysis.counter_end_time ? momentConvertionUtcToLocalTime(impactAnalysis.counter_end_time, "YYYY-MM-DD HH:mm:ss") : ""    
        let subMinuteToEndTime = subMinutes(endTime, 1)
        
        let labels = [startTime, eventTime, endTime]
        let data = [0, 10, 0]

        let totalIssues = 0
        let totalErrors = 0
        let impactsObserved = []
        if(impactAnalysis.before_events && impactAnalysis.before_events.length) {
            impactAnalysis.before_events.forEach(item => {
                let date = momentConvertionUtcToLocalTime(item.event_time, "YYYY-MM-DD HH:mm:ss")
                labels.push(date)
                data.push(0)
                
                item.date = date
                item.value = Math.floor(Math.random() * (10 - 1) + 1)
                impactsObserved.push(item)

                if(item.event_type === "errors") {
                    totalErrors += 1
                } else if(item.event_type === "issues") {
                    totalIssues += 1
                }
            })
        }
        if(impactAnalysis.after_events && impactAnalysis.after_events.length) {
            impactAnalysis.after_events.forEach(item => {
                let date = momentConvertionUtcToLocalTime(item.event_time, "YYYY-MM-DD HH:mm:ss")
                labels.push(date)
                data.push(0)

                item.date = date
                item.value = Math.floor(Math.random() * (10 - 1) + 1)
                impactsObserved.push(item)

                if(item.event_type === "errors") {
                    totalErrors += 1
                } else if(item.event_type === "issues") {
                    totalIssues += 1
                }
            })
        }
        
        let eventTypeCount = {"alerts": 0, "anomalies": 0, "errors": 0, "issues": 0}
        if(impactsObserved.length) {
            let groupByEventType = _.groupBy(impactsObserved, 'event_type');
            Object.entries(groupByEventType).forEach(([key, value]) => {
                eventTypeCount[key] = value.length
            })
        } else {
            labels.push(addMinuteToStartTime)
            labels.push(subMinuteToEndTime)
            data.push(0)
            data.push(0)
        }

        let gandalfBarData = {}
        gandalfBarData.eventTime = eventTime        
        gandalfBarData.labels = labels
        gandalfBarData.data = data
        gandalfBarData.impactsObserved = impactsObserved
        gandalfBarData.event_type_count = eventTypeCount

        let eventTypes = ["alerts", "anomalies", 'errors',  "issues"]
        
        let beforeCount = []
        eventTypes.forEach(item => { 
            let dataRow = {}
            if(this.state.impactAnalysis && this.state.impactAnalysis.after_count && this.state.impactAnalysis.before_count.hasOwnProperty(item)) {
                dataRow.type = item
                Object.entries(this.state.impactAnalysis.before_count[item]).forEach(([key, value]) => {
                    if(key === "severity") {
                        Object.entries(value).forEach(([sKey, sValue]) => {
                            dataRow[sKey] = sValue
                        })
                    } else {
                        dataRow[key] = value
                    }
                })
            } else {
                dataRow.type = item
                dataRow.count = 0
                dataRow.Critical = 0
                dataRow.High = 0
            }
            
            beforeCount.push(dataRow)
        })

        let afterCount = []
        eventTypes.forEach(item => { 
            let dataRow = {}
            if(this.state.impactAnalysis && this.state.impactAnalysis.after_count && this.state.impactAnalysis.after_count.hasOwnProperty(item)) {
                dataRow.type = item
                Object.entries(this.state.impactAnalysis.after_count[item]).forEach(([key, value]) => {
                    if(key === "severity") {
                        Object.entries(value).forEach(([sKey, sValue]) => {
                            dataRow[sKey] = sValue
                        })
                    } else {
                        dataRow[key] = value
                    }
                })
            } else {
                dataRow.type = item
                dataRow.count = 0
                dataRow.Critical = 0
                dataRow.High = 0
            }
            
            afterCount.push(dataRow)
        })

        let beforeIssueCount = beforeCount.filter(e => e.type === "issues")[0].count
        let afterIssueCount = afterCount.filter(e => e.type === "issues")[0].count

        

        let beforeIssuesProgressData = {
            data: [Math.ceil((beforeIssueCount/totalIssues) * 100)],
            name: [beforeIssueCount],
            labels: ["Issues"]
        }

        let afterIssuesProgressData = {
            data: [Math.ceil((afterIssueCount/totalIssues) * 100)],
            name: [afterIssueCount],
            labels: ["Issues"]
        }


        let beforeErrorsCount = beforeCount.filter(e => e.type === "errors")[0].count
        let afterErrorsCount = afterCount.filter(e => e.type === "errors")[0].count

        let beforeErrorsProgressData = {
            data: [Math.ceil((beforeErrorsCount/totalErrors) * 100)],
            name: [beforeErrorsCount],
            labels: ["Errors"]
        }

        let afterErrorsProgressData = {
            data: [Math.ceil((afterErrorsCount/totalErrors) * 100)],
            name: [afterErrorsCount],
            labels: ["Errors"]
        }

        let progressData = {
            data: [10],
            name: "",
            labels: ["Progress1"]
        }

        this.setState({ gandalfBarData, beforeCount, afterCount, progressData, impactsObserved, beforeIssuesProgressData, afterIssuesProgressData, beforeErrorsProgressData, afterErrorsProgressData })
    }

    formatChangesEvent = () => {
        let selectedDiagnosticEvent = this.state.selectedDiagnosticEvent
        if(this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.changes) {
            let data = []
            this.state.selectedDiagnosticEvent.changes.map(cng => {
                if(cng.current_value) {
                    let currentValue = cng.current_value
                    try {
                        let replaceString = currentValue.replaceAll("'", "''''")
                        replaceString = replaceString.replaceAll("''''", '"')
                        currentValue = JSON.parse(replaceString)
                    } catch (error) {
                        currentValue = {}
                    }
                    if(Object.entries(currentValue).length) {                        
                        let arrayValue = []
                        Object.entries(currentValue).map(([key,item]) => {                            
                            let arrayOfStrings = false
                            if(Array.isArray(item)) {
                                // arrayOfStrings = item.every(i => (typeof i === "string"));
                                arrayOfStrings = item.every(i => (typeof i !== "object"))
                            }
                            if(arrayOfStrings) {
                                let dataRow = {
                                    [key]: item
                                }
                                arrayValue.push(dataRow)
                            } else if(typeof item === 'string' || typeof item === 'number' || typeof item === 'boolean') {
                                let dataRow = {
                                    [key]: item
                                }
                                arrayValue.push(dataRow)
                            } else {
                                if(Array.isArray(item)) {
                                    item.forEach(obj => {
                                        let array = []
                                        let depthofObject = this.depthofObject(obj)
                                        array = this.validateValue(obj).flat(depthofObject)
                                        arrayValue.push(array)
                                    })                                    
                                } else {
                                    let array = []
                                    let depthofObject = this.depthofObject(item)
                                    array = this.validateValue(item).flat(depthofObject)
                                    arrayValue.push(array)
                                }
                            }
                        })
                        currentValue = arrayValue
                    }

                    cng.current_value_converted = currentValue
                    cng.current_value_type = typeof currentValue
                }
                if(cng.previous_value) {
                    let previousValue = cng.previous_value
                    try {
                        let replaceString = previousValue.replaceAll("'", "''''")
                        replaceString = replaceString.replaceAll("''''", '"')
                        previousValue = JSON.parse(replaceString)
                    } catch (error) {
                        previousValue = {}
                    }

                    if(Object.entries(previousValue).length) {
                        let arrayValue = []
                        Object.entries(previousValue).map(([key,item]) => {                            
                            let arrayOfStrings = false
                            if(Array.isArray(item)) {
                                // arrayOfStrings = item.every(i => (typeof i === "string"));
                                arrayOfStrings = item.every(i => (typeof i !== "object"))
                            }
                            if(arrayOfStrings) {
                                let dataRow = {
                                    [key]: item
                                }
                                arrayValue.push(dataRow)
                            } else if(typeof item === 'string' || typeof item === 'number' || typeof item === 'boolean') {
                                let dataRow = {
                                    [key]: item
                                }
                                arrayValue.push(dataRow)
                            } else {
                                if(Array.isArray(item)) {
                                    item.forEach(obj => {
                                        let array = []
                                        let depthofObject = this.depthofObject(obj)
                                        array = this.validateValue(obj).flat(depthofObject)
                                        arrayValue.push(array)
                                    })                                    
                                } else {
                                    let array = []
                                    let depthofObject = this.depthofObject(item)
                                    array = this.validateValue(item).flat(depthofObject)
                                    arrayValue.push(array)
                                }
                            }
                            
                        })
                        previousValue = arrayValue
                    }

                    cng.previous_value_converted = previousValue    
                    cng.previous_value_type = typeof previousValue 
                }

                data.push(cng)
            })
            selectedDiagnosticEvent.changes = data
        }

        this.setState({ selectedDiagnosticEvent: selectedDiagnosticEvent })
    }

    depthofObject = (object) => {
        var level = 1;
        for(var key in object) {
            if (!object.hasOwnProperty(key)) continue;
    
            if(typeof object[key] == 'object'){
                var depth = this.depthofObject(object[key]) + 1;
                level = Math.max(depth, level);
            }
        }
        return level;
    }

    validateValue = (item, valKey) => {
        return Object.keys(item).map(key => {
            // let arrayOfStrings = false
            // if(Array.isArray(item)) {
            //     arrayOfStrings = item.every(i => (typeof i === "string"));
            // }
            // if(arrayOfStrings) {                
            //     let dataRow = {
            //         [key]: item[key]
            //     }
            //     return dataRow
            // } else 
            if(typeof item[key] === 'string' || typeof item[key] === 'number' || typeof item[key] === 'boolean') {
                let dataRow = {
                    [key]: item[key]
                }
                return dataRow
            } else if (item[key] !== null && typeof item[key] == 'object') {
                return this.validateValue(item[key], key)
            } else {
                // if(this.props.searchFilter.includes(key)){
                return item[key] !== null
                    ? item[key].toString().toLowerCase()
                    : false
                // }else{
                // return false;
                // }
            }
        })
        
    }

	getRegionAccountDetails = () => {
        if(!this.state.accounts || !this.state.accounts.length || !this.state.regions || !this.state.regions.length) {
            let params = {}
            if (this.state.assetDetails.provider) {
                params.provider = this.state.assetDetails.provider.toLowerCase()
            }
            if(this.props.accounts && this.props.accounts.length) {
                let filterdAccounts = this.props.accounts
                if(this.state.assetDetails.provider) {
                    filterdAccounts = this.props.accounts.filter(account => account.provider === this.state.assetDetails.provider.toLowerCase())
                }
                this.setState({ accounts: filterdAccounts })
            } else {
                this.props.listAllAccounts(params, (promise, allAccounts) => {
                    if (promise) {						
                        let filterdAccounts = allAccounts
                        if(this.state.assetDetails.provider) {
                            filterdAccounts = allAccounts.filter(account => account.provider === this.state.assetDetails.provider.toLowerCase())
                        }
                        this.setState({ accounts: filterdAccounts })
                    }
                })
            }

            if(this.props.regions && this.props.regions.length) {
                let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
                this.setState({ regions: providerRegions })
            } else {
                this.props.listAllRegions(params, (promise, regions) => {
                    if (promise) {
                        let providerRegions = regions && regions[0] && regions[0].provider_regions
                        this.setState({ regions: providerRegions })
                    } else {
                        this.setState({ regions: [] })
                    }
                })
            }
        }
	}

    redirect = (url) => {
        window.open(url, '_blank');
    }    

	navigatePage = (action, currentPage) => {
		if(currentPage > 0) {
			let startRecord = this.state.startRecord
			if(action === 'next' && currentPage !== this.state.totalPages) {
				startRecord = startRecord + this.state.perPage
				currentPage = currentPage + 1
			} else if(action === 'previous' && currentPage !== 0) {
				currentPage = currentPage - 1
				startRecord = startRecord - this.state.perPage
			} else if(action === 'start' && currentPage !== 0) {
				currentPage = 1
				startRecord = 0
			} else if(action === 'end' && currentPage !== 0) {
				currentPage = this.state.totalPages
				startRecord = (this.state.totalPages - 1) * this.state.perPage
			}
			this.setState({ currentPage, startRecord },
				() => {
					// let scroll = document.getElementById('scrollTop');
					// scroll.scrollIntoView();
				}
			)
		} 
	}

	render() {
		return (
            !this.state.reloadPage ?
                <div className=''> {/* ref={this.scrolltoTop} */}
                    <div className={`m-0 h-100 overflowYAuto`} style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
                        {this.state.selectedDiagnosticEventLabel === "assets_list" ?
                            <div className="mt-3" id="scrollTop">
                                <div className="bg-dark p-3 rounded">
                                    <div className="d-flex justify-content-between mb-2 pb-2 border-bottom-dark">
                                        <label className="text-white mb-0">Diagnostics Details</label>
                                        <div className="d-flex">
                                            <p className="b-block mb-0 mr-2">Status</p>
                                            <span className={`mr-2 badge ${this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.status ? (this.state.selectedDiagnosticEvent.status.toLowerCase() === "completed" ? "badge-success" : this.state.selectedDiagnosticEvent.status.toLowerCase() === "failed" ? "badge-danger" : "badge-warning text-dark") : "" }`}>  
                                                {capitalizeFirstLetter(this.state.selectedDiagnosticEvent.status)}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-1 border-bottom-dark">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Diagnostics Name</p>
                                            <p className="mb-0 text-white">
                                                {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.name ? this.state.selectedDiagnosticEvent.name : " -- "}
                                            </p>
                                        </div>
                                        <div className="pl-3 py-1 w-50">
                                            <p className="b-block mb-0">Diagnostics ID</p>
                                            <p className="mb-0 text-white">
                                                {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.diagnostic_id}
                                            </p>
                                        </div>
                                    </div>                                
                                    <div className="d-flex mb-1 border-bottom-dark">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Provider / Account / Region</p>
                                            <p className="mb-0 text-white">
                                                {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.provider ? this.state.selectedDiagnosticEvent.provider.toUpperCase() : <span>&nbsp;</span>}
                                                {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.account_id ? (' : ')+this.state.selectedDiagnosticEvent.account_id : <span>&nbsp;</span>}
                                                {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.region ?  (' : ')+this.state.selectedDiagnosticEvent.region : <span>&nbsp;</span>}
                                            </p>
                                        </div>           
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.duration ? "Diagnostics Duration" : "Diagnostics Duration"}</p>
                                            <p className="mb-0 text-white">
                                                {/* {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.duration ?
                                                    HOURLY_DURATION.filter(e => e.value === this.state.selectedDiagnosticEvent.duration).length ?
                                                        <span>{HOURLY_DURATION.filter(e => e.value === this.state.selectedDiagnosticEvent.duration)[0].option}</span>
                                                    :
                                                        <span>{this.state.selectedDiagnosticEvent.duration}</span>
                                                : this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.start_time ? */}
                                                    <div className="d-flex">
                                                        <p className="mb-0 text-white">
                                                            {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.start_time ? momentConvertionUtcToLocalTime(this.state.selectedDiagnosticEvent.start_time, "DD MMM YYYY HH:mm") : <span>&nbsp;</span>}
                                                        </p>
                                                        <p className="mb-0 text-white ml-2">
                                                            {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.end_time ? (" : ")+momentConvertionUtcToLocalTime(this.state.selectedDiagnosticEvent.end_time, "DD MMM YYYY HH:mm") : <span>&nbsp;</span>}
                                                        </p>
                                                    </div>
                                                {/* : null} */}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-1 border-bottom-dark">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Submitted Time</p>
                                            <p className="mb-0 text-white">
                                                {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.diagnostic_start_time ? momentConvertionUtcToLocalTime(this.state.selectedDiagnosticEvent.diagnostic_start_time, "DD MMM YYYY HH:mm:ss") : <span>&nbsp;</span>}
                                            </p>
                                        </div>           
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">Completed Time</p>
                                            <p className="mb-0 text-white">
                                                {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.diagnostic_end_time ? momentConvertionUtcToLocalTime(this.state.selectedDiagnosticEvent.diagnostic_end_time, "DD MMM YYYY HH:mm:ss") : <span>&nbsp;</span>}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-1 border-bottom-dark">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Diagnostics Mode</p>
                                            <p className="mb-0 text-white">
                                                {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.checks && this.state.selectedDiagnosticEvent.checks.length ? "Partial" : "Full"}
                                            </p>
                                        </div>
                                        {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.checks && this.state.selectedDiagnosticEvent.checks.length ? 
                                            <div className="pl-3 py-1 w-50">
                                                <p className="b-block mb-0">Checks</p>
                                                {this.state.selectedDiagnosticEvent.checks.map(chk => {
                                                    return (
                                                        <label className="f12 badge badge-outline-light mr-1">{chk}</label>
                                                    )
                                                })}
                                            </div>
                                        : null}
                                    </div>
                                    <div className="d-flex mb-1 border-bottom-dark">
                                        <div className="d-flex mb-1">
                                            <div className="py-1 w-50">
                                                <p className="b-block mb-0">Tags</p>
                                                {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.tags && this.state.selectedDiagnosticEvent.tags.length ? 
                                                    <div className="d-flex flex-wrap">
                                                        {this.state.selectedDiagnosticEvent.tags.map(tag => {
                                                            return (
                                                                <label className="f12 badge badge-outline-light mr-1">{tag.key+"  : "+tag.value}</label>
                                                            )
                                                        })}
                                                    </div>
                                                : 
                                                    <small className={`text-white`}>--</small> 
                                                }
                                            </div>
                                        </div>
                                    </div>          
                                    <div className="d-flex mb-1">
                                        <div className="py-1 w-100">
                                            <p className="b-block mb-0">Description</p>
                                            <p className="mb-0 text-white">
                                                {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.description ? this.state.selectedDiagnosticEvent.description : " -- "}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3 rounded bg-dark p-3">
                                    <p className="mb-1 f16 text-white">Diagnostics - Asset List</p>
                                    <p calssName="mb-1 small w-100">Diagnostics checks performed to the following resources listed below</p>
                                    <div className={`d-flex pb-2 justify-content-between`}>
                                        <div className="d-flex mr-2 w-40">
                                            <p className="f12 m-0 align-self-center pt-1">Showing {this.state.filteredAssetArray && this.state.filteredAssetArray.length} of total {this.state.assetsList && this.state.assetsList.length}  asset(s)</p>
                                        </div>
                                        <div className="d-flex justify-content-end w-60 mb-n2">
                                            {this.state.assetsList && this.state.assetsList.length ?
                                                <Search
                                                    data={this.state.assetsList ? this.state.assetsList : []}
                                                    applyTags={false}
                                                    applyLiteDarkTags={true}
                                                    topClassName={'bg-black5 align-self-center w-50 mr-1 border-gray5 rounded-5 f12'}
                                                    searchClassName={'px-2 f12'}
                                                    searchIconColor={'text-gray5 f12'}
                                                    searchPlaceHolder={'Search assets....'}
                                                    className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                                    filteredData={(filteredAssetArray) => {
                                                        let totalPages = 1
                                                        if(filteredAssetArray.length > this.state.perPage) {
                                                            totalPages = Math.ceil(filteredAssetArray.length / this.state.perPage)
                                                        }
                                                        this.setState({ filteredAssetArray, startRecord: 0, currentPage: 1, totalPages })
                                                    }}
                                                />
                                            : null}
                                            {this.state.filteredAssetArray && this.state.filteredAssetArray.length > this.state.perPage ?
                                                <div className="pagination errorPagePagination f12">
                                                    <span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
                                                    <button><i className={`fal fa-arrow-to-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('start', this.state.currentPage)}></i></button> 
                                                    <button><i className={`fal fa-angle-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('previous', this.state.currentPage)}></i></button> 
                                                    <button><i className={`fal fa-angle-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
                                                    <button><i className={`fal fa-arrow-to-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead className="text-white">
                                                <tr>
                                                    <th></th>
                                                    <th>Name</th>
                                                    <th>Resources</th>
                                                    <th>Services</th>
                                                </tr>
                                            </thead>
                                            <tbody className="text-primary-color">
                                                {this.state.filteredAssetArray && this.state.filteredAssetArray.length ? 
                                                    this.state.filteredAssetArray.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map((ast, index) => {
                                                        return(
                                                            <tr key={index}>
                                                                <td>{this.state.startRecord+(index+1)}</td>
                                                                <td className="align-middle hiddenEllipses" id={"asset_name_"+index} style={{maxWidth: "150px"}}>
                                                                    {ast.asset_name}
                                                                    <UncontrolledTooltip placement='top' target={"asset_name_"+index}>{ast.asset_name}</UncontrolledTooltip>
                                                                </td>
                            
                                                                <td className="align-middle">
                                                                    {ast.provider ? ast.provider.toUpperCase() : <span>&nbsp;</span>}
                                                                    {ast.account_id ? (' : ')+getAccountNameFromId(ast.account_id, this.props.accounts) : <span>&nbsp;</span>}
                                                                    {ast.region ?  (' : ')+getRegionName(ast.region, this.state.regions) : <span>&nbsp;</span>}
                                                                </td>
                                                                <td className="align-middle">
                                                                    {ast.resource_type ? ast.resource_type : <span>&nbsp;</span>}
                                                                    {ast.service_name ?  (' : ')+ast.service_name : <span>&nbsp;</span>}
                                                                </td>
                                                            </tr>
                                                        )

                                                    })
                                                : 
                                                    <tr key={-1}>
                                                        <td colSpan={4} className="text-center">No Records</td>
                                                    </tr>
                                                }
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        : this.state.selectedDiagnosticEventLabel === 'Health Events' ?
                            <div className="mt-3">
                                <div className="rounded bg-dark p-3">
                                    {/* <p className="mb-1"><b>Event Details</b></p> */}
                                    <div className="mb-1">
                                        <p className="mb-0 f16 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.health_event_name ? this.state.selectedDiagnosticEvent.health_event_name : <span>&nbsp;</span>}</p>
                                        <p className="f12">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.health_event_description ? this.state.selectedDiagnosticEvent.health_event_description : <span>&nbsp;</span>}</p>
                                    </div>
                                    <div className="d-flex mb-1 border-bottom">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Event ID</p>
                                            <p className="mb-0 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.health_event_id ? this.state.selectedDiagnosticEvent.health_event_id : <span>&nbsp;</span>}</p>
                                        </div>
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">Event Time</p>
                                            <p className="mb-0 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.health_event_time ? momentConvertionUtcToLocalTime(this.state.selectedDiagnosticEvent.health_event_time, 'DD MMM YYYY HH:mm:ss') : <span>&nbsp;</span>}</p>
                                        </div>
                                    </div>

                                    <div className="d-flex mb-1 border-bottom">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Event Type</p>
                                            <p className="mb-0 text-info" onClick={() => this.redirect('https://status.aws.amazon.com/')}>{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.health_event_type ? this.state.selectedDiagnosticEvent.health_event_type : <span>&nbsp;</span>}</p>
                                        </div>
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">Arn</p>
                                            <p className="mb-0 text-white text-break">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.health_event_arn ? this.state.selectedDiagnosticEvent.health_event_arn : <span>&nbsp;</span>}</p>
                                        </div>
                                    </div>
                            
                                    <div className="d-flex mb-1 border-bottom">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Region</p>
                                            <p className="mb-0 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.health_event_region ? getRegionName(this.state.selectedDiagnosticEvent.health_event_region, this.state.regions) : <span>&nbsp;</span>}</p>
                                        </div>
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">Service</p>
                                            <p className="mb-0 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.health_event_service ? this.state.selectedDiagnosticEvent.health_event_service : <span>&nbsp;</span>}</p>
                                        </div>
                                    </div>

                                    <div className="d-flex mb-1 border-bottom">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Start Time</p>
                                            <p className="mb-0 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.health_start_time ? momentConvertionUtcToLocalTime(this.state.selectedDiagnosticEvent.health_start_time, 'DD MMM YYYY HH:mm:ss') : <span>&nbsp;</span>}</p>
                                        </div>
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">End Time</p>
                                            <p className="mb-0 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.health_end_time ? momentConvertionUtcToLocalTime(this.state.selectedDiagnosticEvent.health_end_time, 'DD MMM YYYY HH:mm:ss') : <span>&nbsp;</span>}</p>
                                        </div>
                                    </div>

                                    <div className="d-flex mb-1">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Category</p>
                                            {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.health_event_category ? 
                                                <small className={`badge badge-secondary`}>{this.state.selectedDiagnosticEvent.health_event_category}</small> 
                                            : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : this.state.selectedDiagnosticEventLabel === 'Maintenance and Scheduled Events'  ?
                            <div className="mt-3">
                                <div className="rounded bg-dark p-3">
                                    <div className="d-flex justify-content-between mb-1">
                                        <p className="mb-0 f16 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.maintsch_event_name ? this.state.selectedDiagnosticEvent.maintsch_event_name : <span>&nbsp;</span>}</p>
                                        <p className="f12">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.maintsch_event_description ? this.state.selectedDiagnosticEvent.maintsch_event_description : <span>&nbsp;</span>}</p>
                                    </div>
                                    <div className="d-flex mb-1 border-bottom">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Event ID</p>
                                            <p className="mb-0 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.maintsch_event_id ? this.state.selectedDiagnosticEvent.maintsch_event_id : <span>&nbsp;</span>}</p>
                                        </div>
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">Event Time</p>
                                            <p className="mb-0 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.maintsch_event_time ? momentConvertionUtcToLocalTime(this.state.selectedDiagnosticEvent.maintsch_event_time, 'DD MMM YYYY HH:mm:ss') : <span>&nbsp;</span>}</p>
                                        </div>
                                    </div>

                                    <div className="d-flex mb-1 border-bottom">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Event Type</p>
                                            <p className="mb-0 text-info" onClick={() => this.redirect('https://status.aws.amazon.com/')}>{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.maintsch_event_type ? this.state.selectedDiagnosticEvent.maintsch_event_type : <span>&nbsp;</span>}</p>
                                        </div>
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">Arn</p>
                                            <p className="mb-0 text-white text-break">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.maintsch_event_arn ? this.state.selectedDiagnosticEvent.maintsch_event_arn : <span>&nbsp;</span>}</p>
                                        </div>
                                    </div>
                            
                                    <div className="d-flex mb-1 border-bottom">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Region</p>
                                            <p className="mb-0 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.maintsch_event_region ? getRegionName(this.state.selectedDiagnosticEvent.maintsch_event_region, this.state.regions) : <span>&nbsp;</span>}</p>
                                        </div>
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">Service</p>
                                            <p className="mb-0 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.maintsch_event_service ? this.state.selectedDiagnosticEvent.maintsch_event_service : <span>&nbsp;</span>}</p>
                                        </div>
                                    </div>

                                    <div className="d-flex mb-1 border-bottom">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Start Time</p>
                                            <p className="mb-0 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.maintsch_start_time ? momentConvertionUtcToLocalTime(this.state.selectedDiagnosticEvent.maintsch_start_time, 'DD MMM YYYY HH:mm:ss') : <span>&nbsp;</span>}</p>
                                        </div>
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">End Time</p>
                                            <p className="mb-0 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.maintsch_end_time ? momentConvertionUtcToLocalTime(this.state.selectedDiagnosticEvent.maintsch_end_time, 'DD MMM YYYY HH:mm:ss') : <span>&nbsp;</span>}</p>
                                        </div>
                                    </div>

                                    <div className="d-flex mb-1">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Category</p>
                                            {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.maintsch_event_category ? 
                                                <small className={`badge badge-secondary`}>{this.state.selectedDiagnosticEvent.maintsch_event_category}</small> 
                                            : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : null}

                        {this.state.selectedDiagnosticEvent && Object.entries(this.state.selectedDiagnosticEvent).length ? 
                            this.state.selectedDiagnosticEventLabel === 'Reachability Analysis' ?
                                <Reachability
                                    assetDetails= {this.state.assetDetails}
                                    diagnostics = {this.props.diagnostics}
                                    selectedDiagnosticEvent = {this.state.selectedDiagnosticEvent}
                                />
                            : this.state.selectedDiagnosticEventLabel === 'DB SlowQuery MySQL' ?
                                <DbSlowQueryMySQL
                                    assetDetails= {this.state.assetDetails}
                                    selectedEvent={this.state.selectedDiagnosticEvent}
                                    diagnostics = {this.props.diagnostics}
                                />
                            : this.state.selectedDiagnosticEventLabel === 'DB SlowQuery PostgreSQL' ?
                                <DbSlowQueryPostgreSQL
                                    assetDetails= {this.state.assetDetails}
                                    selectedEvent={this.state.selectedDiagnosticEvent}
                                    diagnostics = {this.props.diagnostics}
                                />
                            : this.state.selectedDiagnosticEventLabel === 'Athena Analysis' ?
                                <AthenaAnalysis
                                    assetDetails= {this.state.assetDetails}
                                    selectedDiagnosticEvent={this.state.selectedDiagnosticEvent}
                                    diagnostics = {this.props.diagnostics}
                                />
                            : this.state.selectedDiagnosticEventLabel === 'Application Changes' ?
                                <div className="mt-3">
                                    <div className="bg-dark rounded p-3">
                                        {this.state.selectedDiagnosticEvent.deployment_events ?
                                            <div className="mb-3">
                                                <p className="f16 text-white mb-1">Deployment Events</p>
                                                <p className="f12 text-white">Showing {this.state.selectedDiagnosticEvent.deployment_events && this.state.selectedDiagnosticEvent.deployment_events.length} deployment events in application</p>
                                                <ResizeableDarkThemeTable
                                                    columns={[
                                                        {
                                                            Header: 'Tool',
                                                            // accessor: d => d.additional_details.tool,
                                                            Cell: cellInfo => (
                                                                <span className=''>
                                                                    {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.tool ? cellInfo.row.original.additional_details.tool : ''}
                                                                </span>
                                                            ),
                                                        },
                                                        {
                                                            Header: 'Deployment ID',
                                                            // accessor: d => d.additional_details.deployment_id,
                                                            Cell: cellInfo => (
                                                                <span className=''>
                                                                    {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.deployment_id ? cellInfo.row.original.additional_details.deployment_id : ''}
                                                                </span>
                                                            ),
                                                        },                                                        
                                                        {
                                                            Header: 'Date / User',
                                                            Cell: cellInfo => (
                                                                <span className=''>
                                                                    {momentConvertionUtcToLocalTime(cellInfo.row.original.event_time, 'DD MMM YYYY HH:mm') +' / '+ (cellInfo.row.original.additional_details.user ? cellInfo.row.original.additional_details.user.name : '')}
                                                                </span>
                                                            ),
                                                        },
                                                        {
                                                            Header: 'Description',
                                                            Cell: cellInfo => (
                                                                <span className=''>
                                                                    {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.artifacts && cellInfo.row.original.additional_details.artifacts.location ? cellInfo.row.original.additional_details.artifacts.location : ''}
                                                                </span>
                                                            ),
                                                            width: 200
                                                        },
                                                        {
                                                            Header: ' ',
                                                            Cell: cellInfo => (
                                                                <span className=''>&nbsp;</span>
                                                            ),
                                                            width: 1
                                                        }
                                                    ]}
                                                    data={this.state.selectedDiagnosticEvent.deployment_events}
                                                    perPage={2}
                                                    dashboard={(this.state.selectedDiagnosticEvent.deployment_events && this.state.selectedDiagnosticEvent.deployment_events.length) ? true : false}
                                                    sortByColumn={''}
                                                    riskTooltip={[]}
                                                    onClickRow={tableRow => {}}
                                                    tableSize={'table-xs'}
                                                />
                                            </div>
                                        :null}
                                        {this.state.selectedDiagnosticEvent.build_events ?
                                            <div className="mb-3">
                                                <p className="f16 text-white mb-1">Build Events</p>
                                                <p className="f12 text-white">Showing {this.state.selectedDiagnosticEvent.build_events && this.state.selectedDiagnosticEvent.build_events.length} build events in application</p>
                                                <ResizeableDarkThemeTable
                                                    columns={[
                                                        {
                                                            Header: 'Tool',
                                                            // accessor: d => d.additional_details.tool,
                                                            Cell: cellInfo => (
                                                                <span className=''>
                                                                    {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.tool ? cellInfo.row.original.additional_details.tool : ''}
                                                                </span>
                                                            ),
                                                        },
                                                        {
                                                            Header: 'Reference',
                                                            Cell: cellInfo => (
                                                                <span className=''>
                                                                    {cellInfo.row.original.additional_details ? cellInfo.row.original.additional_details.build_job_name +' : '+ cellInfo.row.original.additional_details.build_number : ''}
                                                                </span>
                                                            ),
                                                        },
                                                        {
                                                            Header: 'Date / User',
                                                            // accessor: 'event_time'
                                                            Cell: cellInfo => (
                                                                <span className=''>
                                                                    {momentConvertionUtcToLocalTime(cellInfo.row.original.event_time, 'DD MMM YYYY HH:mm') +' / '+ (cellInfo.row.original.additional_details.user ? cellInfo.row.original.additional_details.user.name : '')}
                                                                </span>
                                                            ),
                                                        },
                                                        {
                                                            Header: 'Artifacts',
                                                            // accessor: 'event_time'
                                                            Cell: cellInfo => (
                                                                <span className=''>
                                                                    {Object.entries(cellInfo.row.original.additional_details.artifacts).length+' Artifacts'}
                                                                </span>
                                                            ),
                                                        },
                                                        {
                                                            Header: 'Description',
                                                            Cell: cellInfo => (
                                                                <span className=''>
                                                                    {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.artifacts && cellInfo.row.original.additional_details.artifacts.location ? cellInfo.row.original.additional_details.artifacts.location : ''}
                                                                </span>
                                                            ),
                                                            width: 200
                                                        },
                                                        {
                                                            Header: ' ',
                                                            Cell: cellInfo => (
                                                                <span className=''>&nbsp;</span>
                                                            ),
                                                            width: 1
                                                        }
                                                    ]}
                                                    data={this.state.selectedDiagnosticEvent.build_events}
                                                    perPage={2}
                                                    dashboard={(this.state.selectedDiagnosticEvent.build_events && this.state.selectedDiagnosticEvent.build_events.length) ? true : false}
                                                    sortByColumn={''}
                                                    riskTooltip={[]}
                                                    onClickRow={tableRow => {}}
                                                    tableSize={'table-xs'}
                                                />
                                            </div>
                                        :null}
                                        {this.state.selectedDiagnosticEvent.commit_events ?
                                            <div className="mb-3">
                                                <p className="f16 text-white mb-1">Commit Events</p>
                                                <p className="f12 text-white">Showing {this.state.selectedDiagnosticEvent.commit_events && this.state.selectedDiagnosticEvent.commit_events.length} commit events in application</p>
                                                <ResizeableDarkThemeTable
                                                    columns={[
                                                        {
                                                            Header: 'Tool',
                                                            Cell: cellInfo => (
                                                                <span className=''>
                                                                    {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.tool ? cellInfo.row.original.additional_details.tool : ''}
                                                                </span>
                                                            ),
                                                        },
                                                        {
                                                            Header: 'Repo / URL',
                                                            Cell: cellInfo => (
                                                                <span className=''>
                                                                    {cellInfo.row.original.additional_details ? cellInfo.row.original.additional_details.repository_name +' / '+ cellInfo.row.original.additional_details.branch_name : ''}
                                                                </span>
                                                            ),
                                                        },                                                    
                                                        {
                                                            Header: 'Date / User',
                                                            Cell: cellInfo => (
                                                                <span className=''>
                                                                    {momentConvertionUtcToLocalTime(cellInfo.row.original.event_time, 'DD MMM YYYY HH:mm') +' / '+ (cellInfo.row.original.additional_details.user ? cellInfo.row.original.additional_details.user.name : '')}
                                                                </span>
                                                            ),
                                                        },
                                                        {
                                                            Header: 'Commit ID',
                                                            Cell: cellInfo => (
                                                                <span className=''>
                                                                    {cellInfo.row.original.additional_details ? cellInfo.row.original.additional_details.commit_id : ''}
                                                                </span>
                                                            ),
                                                        },
                                                        {
                                                            Header: 'Files',
                                                            Cell: cellInfo => (
                                                                <span className=''>&nbsp;</span>
                                                            ),
                                                        }
                                                    ]}
                                                    data={this.state.selectedDiagnosticEvent.commit_events}
                                                    perPage={2}
                                                    dashboard={(this.state.selectedDiagnosticEvent.commit_events && this.state.selectedDiagnosticEvent.commit_events.length) ? true : false}
                                                    sortByColumn={''}
                                                    riskTooltip={[]}
                                                    onClickRow={tableRow => {}}
                                                    tableSize={'table-xs'}
                                                />
                                            </div>
                                        :null}
                                    </div>
                                </div>
                            : (this.state.selectedDiagnosticEventLabel === 'MySQL DB Changes' || this.state.selectedDiagnosticEventLabel === 'DB Audit PostgreSQL') ?
                                <div className="mt-3">
                                    <div className="bg-dark rounded p-3">
                                        <p className="f16 text-white mb-1">{this.props.selectedDiagnosticEvent && this.props.selectedDiagnosticEvent.tableHeading}</p>
                                        <p className="f12 text-white">Showing {this.state.selectedDiagnosticEvent.details && this.state.selectedDiagnosticEvent.details.length} changes in {this.props.selectedDiagnosticEvent && this.props.selectedDiagnosticEvent.tableHeading ? this.props.selectedDiagnosticEvent.tableHeading.toLowerCase() : ''}</p>
                                        <ResizeableDarkThemeTable
                                            columns={[
                                                {
                                                    Header: 'Date',
                                                    Cell: cellInfo => (
                                                        <span className=''>
                                                            {momentConvertionUtcToLocalTime(cellInfo.row.original.event_time, 'DD MMM YYYY HH:mm')}
                                                        </span>
                                                    ),
                                                },
                                                {
                                                    Header: 'Type',
                                                    Cell: cellInfo => (
                                                        <span className=''>
                                                            {(cellInfo.row.original.additional_details.db_object ? cellInfo.row.original.additional_details.db_object : '')}
                                                        </span>
                                                    ),
                                                    width: 75
                                                },
                                                {
                                                    Header: 'DB Name',
                                                    Cell: cellInfo => (
                                                        <span className=''>
                                                            {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.database ? cellInfo.row.original.additional_details.database : ''}
                                                        </span>
                                                    ),
                                                },
                                                {
                                                    Header: 'DB Object',
                                                    Cell: cellInfo => (
                                                        <span className=''>
                                                            {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.db_object_name ? cellInfo.row.original.additional_details.db_object_name : ''}
                                                        </span>
                                                    ),
                                                },
                                                {
                                                    Header: 'Operation',
                                                    Cell: cellInfo => (
                                                        <span className=''>
                                                            {(cellInfo.row.original.additional_details.action ? cellInfo.row.original.additional_details.action : '')}
                                                        </span>
                                                    ),
                                                },
                                                {
                                                    Header: 'User',
                                                    Cell: cellInfo => (
                                                        <span className=''>
                                                            {(cellInfo.row.original.additional_details.user ? cellInfo.row.original.additional_details.user.name : '')}
                                                        </span>
                                                    ),
                                                },
                                                {
                                                    Header: ' ',
                                                    Cell: cellInfo => (
                                                        <span className=''>&nbsp;</span>
                                                    ),
                                                    width: 1
                                                }
                                            ]}
                                            
                                            data={this.state.selectedDiagnosticEvent.details && this.state.selectedDiagnosticEvent.details.length ? this.state.selectedDiagnosticEvent.details : []}
                                            perPage={5}
                                            dashboard={(this.state.selectedDiagnosticEvent.details && this.state.selectedDiagnosticEvent.details.length) ? true : false}
                                            sortByColumn={''}
                                            riskTooltip={[]}
                                            onClickRow={tableRow => {}}
                                            tableSize={'table-xs'}
                                        />
                                    </div>
                                </div>
                            : (this.state.selectedDiagnosticEventLabel === 'Health Events' || this.state.selectedDiagnosticEventLabel === 'Maintenance and Scheduled Events') && this.state.selectedDiagnosticEvent.details && this.state.selectedDiagnosticEvent.details !== 'None' && this.state.selectedDiagnosticEvent.details.length ?
                                <div className="mt-3">
                                    <div className="bg-dark rounded p-3">
                                        <p className="f16 text-white mb-1">Assets</p>
                                        <p className="f12 text-white">Showing {this.state.selectedDiagnosticEvent.details.length} {this.state.selectedDiagnosticEvent.details.length > 1 ? ' assets' : 'asset'} involved in { this.state.selectedDiagnosticEventLabel === 'Health Events' ? 'Health events' : 'Maintenance and Scheduled events'}</p>
                                        <ResizeableDarkThemeTable
                                            columns={[
                                                {
                                                    Header: 'Asset',
                                                    accessor: 'asset_name'
                                                },
                                                {
                                                    Header: 'Arn',
                                                    accessor: 'asset_arn',
                                                    width: 300
                                                },
                                                {
                                                    Header: 'Region',
                                                    accessor: 'region',
                                                    Cell: cellInfo => (
                                                        <span className=''>
                                                            {(cellInfo.row.original.region ? getRegionName(cellInfo.row.original.region, this.state.regions): '')}
                                                        </span>
                                                    )
                                                },
                                                {
                                                    Header: 'Resource',
                                                    Cell: cellInfo => (
                                                        <span className=''>
                                                            {((cellInfo.row.original.resource_type ? cellInfo.row.original.resource_type : '') + ' : '+(cellInfo.row.original.service_name ? cellInfo.row.original.service_name : ''))}
                                                        </span>
                                                    ),
                                                },
                                                {
                                                    Header: ' ',
                                                    Cell: cellInfo => (
                                                        <span className=''>&nbsp;</span>
                                                    ),
                                                    width: 1
                                                }
                                            ]}
                                            
                                            data={this.state.selectedDiagnosticEvent.details}
                                            perPage={5}
                                            dashboard={(this.state.selectedDiagnosticEvent.details && this.state.selectedDiagnosticEvent.details !== 'None' && this.state.selectedDiagnosticEvent.details.length) ? true : false}
                                            sortByColumn={''}
                                            riskTooltip={[]}
                                            onClickRow={tableRow => {}}
                                            tableSize={'table-xs'}
                                        />
                                    </div>
                                </div>
                            : this.state.selectedDiagnosticEventLabel === 'Capacity Changes' ?
                                <React.Fragment>
                                <div className="mt-3">
                                    <div className="bg-dark rounded p-3">
                                        {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.changes ?
                                            this.state.selectedDiagnosticEvent.changes.map((cng,index) => {
                                                return(
                                                    <React.Fragment>
                                                    <div className={`mb-1 ${!index ? "" : "displayNone"}`}>
                                                        <p className="mb-0 f16 text-white">Before Changes</p>
                                                        <p className="f12 mb-0">{(cng.field ? cng.field : '')+''+(cng.previous_value ? +' : '+cng.previous_value : '')}</p>
                                                    </div>
                                                    {cng.previous_features ? 
                                                        Object.entries(cng.previous_features).map(([key, value]) => {
                                                            return(
                                                            <div className="py-1">
                                                                <p className={`mb-0 f16 ${typeof value === 'object' ? 'text-white' : ''}`}>{key}</p>
                                                                <div className="d-flex mb-1 border-bottom">
                                                                    {typeof value === 'object' ? 
                                                                        Object.entries(value).map(([vKey, vValue]) => {
                                                                            return(
                                                                            <div className="py-1 w-33">
                                                                                <p className="b-block mb-0">{vKey}:</p>
                                                                                <p className="mb-0 text-white">{vValue.toLowerCase() === 'y' ? 'Yes' : vValue.toLowerCase() === 'n' ? 'No' : vValue }</p>
                                                                            </div>
                                                                            )
                                                                        })
                                                                    :     
                                                                        <p className="f12 text-white">{value}</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            )
                                                        })
                                                    : null}
                                                    </React.Fragment>
                                                )
                                            })
                                        : null}
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className="bg-dark rounded p-3">
                                        {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.changes ?
                                            this.state.selectedDiagnosticEvent.changes.map((cng,index) => {
                                                return(
                                                    <React.Fragment>
                                                    <div className={`mb-1 ${!index ? "" : "displayNone"}`}>
                                                        <p className="mb-0 f16 text-white">After Changes</p>
                                                        <p className="f12">{(cng.field ? cng.field : '')+''+(cng.updated_value ? +' : '+cng.updated_value : '')}</p>
                                                    </div>
                                                    {cng.current_features ? 
                                                        Object.entries(cng.current_features).map(([key, value]) => {
                                                            return(
                                                            <div className="py-1">
                                                                <p className={`mb-0 f16 ${typeof value === 'object' ? 'text-white' : ''}`}>{key}</p>
                                                                <div className="d-flex mb-1 border-bottom">
                                                                    {typeof value === 'object' ? 
                                                                        Object.entries(value).map(([vKey, vValue]) => {
                                                                            return(
                                                                            <div className="py-1 w-33">
                                                                                <p className="b-block mb-0">{vKey}:</p>
                                                                                <p className="mb-0 text-white">{vValue.toLowerCase() === 'y' ? 'Yes' : vValue.toLowerCase() === 'n' ? 'No' : vValue }</p>
                                                                            </div>
                                                                            )
                                                                        })
                                                                    :     
                                                                        <p className="f12 text-white">{value}</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            )
                                                        })
                                                    : null}
                                                    </React.Fragment>
                                                )
                                            })
                                        : null}
                                    </div>
                                </div>
                                </React.Fragment>
                            : this.state.selectedDiagnosticEventLabel === 'General Changes' ?
                                this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.changes ?
                                    <React.Fragment>
                                    <div className="mt-3">
                                        <div className="bg-dark rounded p-3">
                                                {this.state.selectedDiagnosticEvent.changes.map((cng,index) => {
                                                    return(
                                                        <React.Fragment>
                                                        <div className={`mb-1 ${!index ? "" : "displayNone"}`}>
                                                            <p className="mb-0 f16 text-white">Before Changes</p>
                                                            <p className="f12">{(cng.field ? cng.field : '')+''+(cng.previous_value ? +' : '+cng.previous_value : '')}</p>
                                                        </div>
                                                        {cng.previous_features ? 
                                                            Object.entries(cng.previous_features).map(([key, value]) => {
                                                                return(
                                                                <div className="py-1">
                                                                    <p className={`mb-0 f16 ${typeof value === 'object' ? 'text-white' : ''}`}>{key}</p>
                                                                    <div className="d-flex mb-1 border-bottom">
                                                                        {typeof value === 'object' ? 
                                                                            Object.entries(value).map(([vKey, vValue]) => {
                                                                                return(
                                                                                <div className="py-1 w-33">
                                                                                    <p className="b-block mb-0">{vKey}:</p>
                                                                                    <p className="mb-0 text-white">{vValue.toLowerCase() === 'y' ? 'Yes' : vValue.toLowerCase() === 'n' ? 'No' : vValue }</p>
                                                                                </div>
                                                                                )
                                                                            })
                                                                        :     
                                                                            <p className="f12 text-white">{value}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                )
                                                            })
                                                        : null}
                                                        </React.Fragment>
                                                    )
                                                })}
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <div className="bg-dark rounded p-3">
                                            {this.state.selectedDiagnosticEvent.changes.map((cng,index) => {
                                                return(
                                                    <React.Fragment>
                                                    <div className={`mb-1 ${!index ? "" : "displayNone"}`}>
                                                        <p className="mb-0 f16 text-white">After Changes</p>
                                                        <p className="f12">{(cng.field ? cng.field : '')+'  '+(cng.updated_value ? +' : '+cng.updated_value : '')}</p>
                                                    </div>
                                                    {cng.current_features ? 
                                                        Object.entries(cng.current_features).map(([key, value]) => {
                                                            return(
                                                            <div className="py-1">
                                                                <p className={`mb-0 f16 ${typeof value === 'object' ? 'text-white' : ''}`}>{key}</p>
                                                                <div className="d-flex mb-1 border-bottom">
                                                                    {typeof value === 'object' ? 
                                                                        Object.entries(value).map(([vKey, vValue]) => {
                                                                            return(
                                                                            <div className="py-1 w-33">
                                                                                <p className="b-block mb-0">{vKey}:</p>
                                                                                <p className="mb-0 text-white">{vValue.toLowerCase() === 'y' ? 'Yes' : vValue.toLowerCase() === 'n' ? 'No' : vValue }</p>
                                                                            </div>
                                                                            )
                                                                        })
                                                                    :     
                                                                        <p className="f12 text-white">{value}</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            )
                                                        })
                                                    : null}
                                                    </React.Fragment>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    </React.Fragment>
                                : null
                            : (this.state.selectedDiagnosticEventLabel === 'Security Changes' || this.props.selectedDiagnosticEvent.label === 'Performance Changes' || this.props.selectedDiagnosticEvent.label === 'Network Changes' || this.props.selectedDiagnosticEvent.label === 'Configuration Changes' || this.props.selectedDiagnosticEvent.label === 'Tag Changes' || this.props.selectedDiagnosticEvent.label === 'Policy Changes' || this.props.selectedDiagnosticEvent.label === 'Disk Changes') ?
                                <React.Fragment>
                                <div className="mt-3">
                                    <div className="bg-dark rounded p-3">
                                        {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.changes ?
                                            this.state.selectedDiagnosticEvent.changes.map((cng,index) => {
                                                return(
                                                    <React.Fragment>
                                                    <div className={`mb-1 ${!index ? "" : "displayNone"}`}>
                                                        <p className="mb-0 f16 text-white">Before Changes</p>
                                                        {/* <p className="f12">{(cng.field ? cng.field : '')}</p> */}
                                                    </div>
                                                    {cng.previous_value && cng.previous_value !== "" ?
                                                        <div className="col-sm-12 py-1">  
                                                            <div className="row">
                                                                {cng.previous_value_type === 'object' ? 
                                                                    Object.entries(cng.previous_value_converted).map(([key, value]) => {
                                                                        return(
                                                                            Array.isArray(value) ?
                                                                                value.every(i => (typeof i !== "object")) ?
                                                                                    value.map(arr => {
                                                                                        return(
                                                                                        (typeof arr === "string" || typeof arr === "number" || typeof arr === "boolean") ?
                                                                                            <div className={`py-1 col-sm-6 mb-2 pl-0`}>
                                                                                                <p className="b-block mb-0">{key}:</p>
                                                                                                {typeof arr === "number" ?
                                                                                                    <p className="mb-0 text-white">{arr}</p>
                                                                                                :  typeof arr === "string" ?
                                                                                                    <p className="mb-0 text-white">{arr.toLowerCase() === 'y' ? 'Yes' : arr.toLowerCase() === 'n' ? 'No' : arr}</p>
                                                                                                :
                                                                                                    <p className="mb-0 text-white">{arr ? "True" : "False"}</p>
                                                                                                }
                                                                                            </div>
                                                                                        : null
                                                                                        )
                                                                                    })
                                                                                :
                                                                                    <div className="col-sm-12 bg-dark3 rounded mb-2">
                                                                                    <div className="row p-2">
                                                                                    {value.map(arr => {
                                                                                        return(
                                                                                            typeof arr === 'object' ?
                                                                                            Object.entries(arr).map(([arrKey, arrValue]) => {
                                                                                                return(
                                                                                                (typeof arrValue === "string" || typeof arrValue === "number"|| typeof arrValue === "boolean") ?
                                                                                                    <div className={`py-1 col-sm-6 mb-2 pl-0`}>
                                                                                                        <p className="b-block mb-0">{arrKey}:</p>
                                                                                                        {typeof arrValue === "number" ?
                                                                                                            <p className="mb-0 text-white">{arrValue}</p>
                                                                                                        : typeof arrValue === "string" ?
                                                                                                            <p className="mb-0 text-white">{arrValue.toLowerCase() === 'y' ? 'Yes' : arrValue.toLowerCase() === 'n' ? 'No' : arrValue}</p>
                                                                                                        :
                                                                                                            <p className="mb-0 text-white">{arrValue ? "True" : "False"}</p>
                                                                                                        }
                                                                                                    </div>
                                                                                                :null
                                                                                                )
                                                                                            })
                                                                                        : null
                                                                                        )
                                                                                    })}
                                                                                    </div>
                                                                                    </div>

                                                                            :
                                                                                (typeof value === "string" || typeof value === "number"|| typeof value === "boolean") ?
                                                                                    <div className={`py-1 col-sm-6 mb-2 pl-0`}>
                                                                                        <p className="b-block mb-0">{key}:</p>
                                                                                        {typeof value === "number" ?
                                                                                            <p className="mb-0 text-white">{value}</p>
                                                                                        : typeof value === "string" ?
                                                                                            <p className="mb-0 text-white">{value.toLowerCase() === 'y' ? 'Yes' : value.toLowerCase() === 'n' ? 'No' : value}</p>
                                                                                        :
                                                                                            <p className="mb-0 text-white">{value ? "True" : "False"}</p>
                                                                                        }
                                                                                    </div>
                                                                                :
                                                                                    Object.entries(value).map(([newKey, newValue]) => {
                                                                                        return(
                                                                                        Array.isArray(newValue) ?
                                                                                            newValue.every(i => (typeof i !== "object")) ? 
                                                                                            <div className={`py-1 col-sm-6 mb-2 pl-0`}>
                                                                                                <p className="b-block mb-0">{newKey}:</p>
                                                                                                {newValue.map(arr => {
                                                                                                    return(
                                                                                                    (typeof arr === "string" || typeof arr === "number" || typeof arr === "boolean") ?
                                                                                                        <small className="badge badge-secondary mr-2 mb-1">
                                                                                                        {typeof arr === "number" || typeof arr === "string" ?
                                                                                                            arr
                                                                                                        :
                                                                                                            arr ? "True" : "False"
                                                                                                        }
                                                                                                        </small>
                                                                                                    : null
                                                                                                    )
                                                                                                })}
                                                                                            </div>
                                                                                        : 
                                                                                            newValue.map(arr => {
                                                                                                return(
                                                                                                (typeof arr === "string" || typeof arr === "number" || typeof arr === "boolean") ?
                                                                                                    <div className={`py-1 col-sm-6 mb-2 pl-0`}>
                                                                                                        <p className="b-block mb-0">{newKey}:</p>
                                                                                                        {typeof arr === "number" ?
                                                                                                            <p className="mb-0 text-white">{arr}</p>
                                                                                                        : typeof arr === "string" ?
                                                                                                            <p className="mb-0 text-white">{arr.toLowerCase() === 'y' ? 'Yes' : arr.toLowerCase() === 'n' ? 'No' : arr}</p>
                                                                                                        :
                                                                                                            <p className="mb-0 text-white">{arr ? "True" : "False"}</p>
                                                                                                        }
                                                                                                    </div>
                                                                                                : null
                                                                                                )
                                                                                            })
                                                                                        : (typeof newValue === "string" || typeof newValue === "number" || typeof newValue === "boolean") ?
                                                                                            <div className={`py-1 col-sm-6 mb-2 pl-0`}>
                                                                                                <p className="b-block mb-0">{newKey}:</p>
                                                                                                {typeof newValue === "number" ?
                                                                                                    <p className="mb-0 text-white">{newValue}</p>
                                                                                                : typeof newValue === "string" ?
                                                                                                    <p className="mb-0 text-white">{newValue.toLowerCase() === 'y' ? 'Yes' : newValue.toLowerCase() === 'n' ? 'No' : newValue}</p>
                                                                                                :
                                                                                                    <p className="mb-0 text-white">{newValue ? "True" : "False"}</p>
                                                                                                }
                                                                                            </div>
                                                                                        : null
                                                                                        )
                                                                                    })
                                                                            
                                                                        )
                                                                    })
                                                                :     
                                                                    <div className="d-flex">
                                                                        <div className="py-1 w-50">
                                                                            <p className="b-block mb-0">{cng.field ? cng.field : <span>&nbsp;</span>}</p>
                                                                            <p className="mb-0 text-white">{cng.previous_value}</p>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>                                                
                                                    : null}
                                                    </React.Fragment>
                                                )
                                            })
                                        : null}
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className="bg-dark rounded p-3">
                                        {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.changes ?
                                            this.state.selectedDiagnosticEvent.changes.map((cng,index) => {
                                                return(
                                                    <React.Fragment>
                                                    <div className={`mb-1 ${!index ? "" : "displayNone"}`}>
                                                        <p className="mb-0 f16 text-white">After Changes</p>
                                                        <p className="mb-0 small">{(cng.description ? cng.description : '')}</p>
                                                    </div>
                                                    {cng.current_value && cng.current_value !== "" ?
                                                        <div className="col-sm-12 py-1">  
                                                            <div className="row">
                                                                {cng.current_value_type === 'object' ? 
                                                                    Object.entries(cng.current_value_converted).map(([key, value]) => {
                                                                        return(
                                                                            Array.isArray(value) ?
                                                                                value.every(i => (typeof i !== "object")) ?
                                                                                    value.map(arr => {
                                                                                        return(
                                                                                        (typeof arr === "string" || typeof arr === "number" || typeof arr === "boolean") ?
                                                                                            <div className={`py-1 col-sm-6 mb-2 pl-0`}>
                                                                                                <p className="b-block mb-0">{key}:</p>
                                                                                                {typeof arr === "number" ?
                                                                                                    <p className="mb-0 text-white">{arr}</p>
                                                                                                :  typeof arr === "string" ?
                                                                                                    <p className="mb-0 text-white">{arr.toLowerCase() === 'y' ? 'Yes' : arr.toLowerCase() === 'n' ? 'No' : arr}</p>
                                                                                                :
                                                                                                    <p className="mb-0 text-white">{arr ? "True" : "False"}</p>
                                                                                                }
                                                                                            </div>
                                                                                        : null
                                                                                        )
                                                                                    })
                                                                                :
                                                                                    <div className="col-sm-12 bg-dark3 rounded mb-2">
                                                                                    <div className="row p-2">
                                                                                    {value.map(arr => {
                                                                                        return(
                                                                                            typeof arr === 'object' ?
                                                                                            Object.entries(arr).map(([arrKey, arrValue]) => {
                                                                                                return(
                                                                                                (typeof arrValue === "string" || typeof arrValue === "number"|| typeof arrValue === "boolean") ?
                                                                                                    <div className={`py-1 col-sm-6 mb-2 pl-0`}>
                                                                                                        <p className="b-block mb-0">{arrKey}:</p>
                                                                                                        {typeof arrValue === "number" ?
                                                                                                            <p className="mb-0 text-white">{arrValue}</p>
                                                                                                        : typeof arrValue === "string" ?
                                                                                                            <p className="mb-0 text-white">{arrValue.toLowerCase() === 'y' ? 'Yes' : arrValue.toLowerCase() === 'n' ? 'No' : arrValue}</p>
                                                                                                        :
                                                                                                            <p className="mb-0 text-white">{arrValue ? "True" : "False"}</p>
                                                                                                        }
                                                                                                    </div>
                                                                                                :null
                                                                                                )
                                                                                            })
                                                                                        : null
                                                                                        )
                                                                                    })}
                                                                                    </div>
                                                                                    </div>

                                                                            :
                                                                                (typeof value === "string" || typeof value === "number"|| typeof value === "boolean") ?
                                                                                    <div className={`py-1 col-sm-6 mb-2 pl-0`}>
                                                                                        <p className="b-block mb-0">{key}:</p>
                                                                                        {typeof value === "number" ?
                                                                                            <p className="mb-0 text-white">{value}</p>
                                                                                        : typeof value === "string" ?
                                                                                            <p className="mb-0 text-white">{value.toLowerCase() === 'y' ? 'Yes' : value.toLowerCase() === 'n' ? 'No' : value}</p>
                                                                                        :
                                                                                            <p className="mb-0 text-white">{value ? "True" : "False"}</p>
                                                                                        }
                                                                                    </div>
                                                                                :
                                                                                    Object.entries(value).map(([newKey, newValue]) => {
                                                                                        return(
                                                                                        Array.isArray(newValue) ?
                                                                                            newValue.every(i => (typeof i !== "object")) ? 
                                                                                            <div className={`py-1 col-sm-6 mb-2 pl-0`}>
                                                                                                <p className="b-block mb-0">{newKey}:</p>
                                                                                                {newValue.map(arr => {
                                                                                                    return(
                                                                                                    (typeof arr === "string" || typeof arr === "number" || typeof arr === "boolean") ?
                                                                                                        <small className="badge badge-secondary mr-2 mb-1">
                                                                                                        {typeof arr === "number" || typeof arr === "string" ?
                                                                                                            arr
                                                                                                        :
                                                                                                            arr ? "True" : "False"
                                                                                                        }
                                                                                                        </small>
                                                                                                    : null
                                                                                                    )
                                                                                                })}
                                                                                            </div>
                                                                                        : 
                                                                                            newValue.map(arr => {
                                                                                                return(
                                                                                                (typeof arr === "string" || typeof arr === "number" || typeof arr === "boolean") ?
                                                                                                    <div className={`py-1 col-sm-6 mb-2 pl-0`}>
                                                                                                        <p className="b-block mb-0">{newKey}:</p>
                                                                                                        {typeof arr === "number" ?
                                                                                                            <p className="mb-0 text-white">{arr}</p>
                                                                                                        : typeof arr === "string" ?
                                                                                                            <p className="mb-0 text-white">{arr.toLowerCase() === 'y' ? 'Yes' : arr.toLowerCase() === 'n' ? 'No' : arr}</p>
                                                                                                        :
                                                                                                            <p className="mb-0 text-white">{arr ? "True" : "False"}</p>
                                                                                                        }
                                                                                                    </div>
                                                                                                : null
                                                                                                )
                                                                                            })
                                                                                        : (typeof newValue === "string" || typeof newValue === "number" || typeof newValue === "boolean") ?
                                                                                            <div className={`py-1 col-sm-6 mb-2 pl-0`}>
                                                                                                <p className="b-block mb-0">{newKey}:</p>
                                                                                                {typeof newValue === "number" ?
                                                                                                    <p className="mb-0 text-white">{newValue}</p>
                                                                                                : typeof newValue === "string" ?
                                                                                                    <p className="mb-0 text-white">{newValue.toLowerCase() === 'y' ? 'Yes' : newValue.toLowerCase() === 'n' ? 'No' : newValue}</p>
                                                                                                :
                                                                                                    <p className="mb-0 text-white">{newValue ? "True" : "False"}</p>
                                                                                                }
                                                                                            </div>
                                                                                        : null
                                                                                        )
                                                                                    })
                                                                            
                                                                        )
                                                                    })
                                                                :     
                                                                    <div className="d-flex">
                                                                        <div className="py-1 w-50">
                                                                            <p className="b-block mb-0">{cng.field ? cng.field : <span>&nbsp;</span>}</p>
                                                                            <p className="mb-0 text-white">{cng.current_value}</p>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>                                                
                                                    : null}
                                                    </React.Fragment>
                                                )
                                            })
                                        : null}
                                    </div>
                                </div>
                                </React.Fragment>
                            : this.state.selectedDiagnosticEventLabel === 'Connectivity Checks' && this.state.selectedDiagnosticEvent.conntchks_results && this.state.selectedDiagnosticEvent.conntchks_results !== 'None' && this.state.selectedDiagnosticEvent.conntchks_results.length ?
                                <div className="mt-3">
                                    <div className="bg-dark rounded p-3">
                                        <p className="f16 text-white mb-1">Connectivity Checks List</p>
                                        <p className="f12 text-white">Showing {this.state.selectedDiagnosticEvent.conntchks_results.length} {this.state.selectedDiagnosticEvent.conntchks_results.length > 1 ? 'checks' : 'checks'} involved in { this.state.selectedDiagnosticEventLabel}</p>
                                        <ResizeableDarkThemeTable
                                            columns={[
                                                {
                                                    Header: 'Date',
                                                    accessor: 'conntchks_event_time',
                                                    Cell: cellInfo => (
                                                        <span className=''>
                                                            {momentConvertionUtcToLocalTime(cellInfo.row.original.conntchks_event_time, 'DD MMM YYYY HH:mm')}
                                                        </span>
                                                    ),
                                                },
                                                {
                                                    Header: 'Evnet ID',
                                                    accessor: 'conntchks_event_id',
                                                },
                                                {
                                                    Header: 'Type',
                                                    // accessor: 'dbslowq_event_querytime',
                                                    Cell: cellInfo => (
                                                        <span>
                                                            {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.connectivity_type ? cellInfo.row.original.additional_details.connectivity_type : ''}
                                                        </span>
                                                    ),
                                                    width: 80,
                                                },
                                                {
                                                    Header: 'EndPoint',
                                                    // accessor: 'dbslowq_event_querytime',
                                                    Cell: cellInfo => (
                                                        <span>
                                                            {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.connectivity_endpoint ? cellInfo.row.original.additional_details.connectivity_endpoint : ''}
                                                        </span>
                                                    ),
                                                    width: 100,
                                                },
                                                {
                                                    Header: 'Protocol',
                                                    // accessor: 'dbslowq_event_querytime',
                                                    Cell: cellInfo => (
                                                        <span>
                                                            {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.connectivity_protocol ? cellInfo.row.original.additional_details.connectivity_protocol : ''}
                                                        </span>
                                                    ),
                                                    width: 100,
                                                },
                                                {
                                                    Header: 'Response',
                                                    // accessor: 'dbslowq_event_querytime',
                                                    Cell: cellInfo => (
                                                        <span>
                                                            {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.connectivity_resp ? cellInfo.row.original.additional_details.connectivity_resp : ''}
                                                        </span>
                                                    ),
                                                    width: 100,
                                                },
                                                {
                                                    Header: 'Duration',
                                                    // accessor: 'dbslowq_event_sqlquery',
                                                    Cell: cellInfo => (
                                                        <span className=''>
                                                            {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.connectivity_time ? cellInfo.row.original.additional_details.connectivity_time.toFixed(1) +' '+ (cellInfo.row.original.connectivity_time > 1 ? ' Seconds' : ' Second') : ''}
                                                        </span>
                                                    ),
                                                    width: 100,
                                                },
                                                {
                                                    Header: 'Status',
                                                    // accessor: 'dbslowq_event_sqlquery',
                                                    Cell: cellInfo => (
                                                        <span>
                                                            {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.connectivity_status ? cellInfo.row.original.additional_details.connectivity_status : ''}
                                                        </span>
                                                    ),
                                                    width: 100
                                                },
                                                {
                                                    Header: ' ',
                                                    Cell: cellInfo => (
                                                        <span className=''>&nbsp;</span>
                                                    ),
                                                    width: 1
                                                }
                                            ]}
                                            
                                            data={this.state.selectedDiagnosticEvent.conntchks_results}
                                            perPage={5}
                                            dashboard={(this.state.selectedDiagnosticEvent.conntchks_results && this.state.selectedDiagnosticEvent.conntchks_results !== 'None' && this.state.selectedDiagnosticEvent.conntchks_results.length) ? true : false}
                                            sortByColumn={''}
                                            riskTooltip={[]}
                                            onClickRow={tableRow => {}}
                                            tableSize={'table-xs'}
                                        />
                                    </div>
                                </div>
                            :  (this.state.selectedDiagnosticEventLabel === 'EC2 Service Limits' || this.state.selectedDiagnosticEventLabel === 'RDS Service Limits' || this.state.selectedDiagnosticEventLabel === 'ElastiCache Service Limits' || this.state.selectedDiagnosticEventLabel === 'KMS Service Limits' || this.state.selectedDiagnosticEventLabel === 'DynamoDB Service Limits') ?
                                <div className="mt-3">
                                    <div className="rounded bg-dark p-3">
                                        {/* <p className="mb-1 f16 text-white">Asset Details</p> */}
                                        <div className="d-flex mb-1">
                                            <div className="py-1 w-50">
                                                <p className="b-block mb-0">Quota Name</p>
                                                <p className="mb-0 text-white">
                                                    {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.quota_name ? this.state.selectedDiagnosticEvent.quota_name : "-"}
                                                </p>
                                            </div>
                                            <div className="py-1 w-50 pl-3">
                                                <p className="b-block mb-0">Applied Value</p>
                                                <p className="mb-0 text-white">
                                                    {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.applied_quota_value ? this.state.selectedDiagnosticEvent.applied_quota_value : "-"}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="d-flex mb-1 border-top">
                                            <div className="py-1 w-50">
                                                <p className="b-block mb-0">Default Value</p>
                                                <p className="mb-0 text-white">
                                                    {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.default_quota_value ? this.state.selectedDiagnosticEvent.default_quota_value : "-"}
                                                </p>
                                            </div>
                                            <div className="py-1 w-50 pl-3">
                                                <p className="b-block mb-0">Actual Value</p>
                                                <p className="mb-0 text-white">
                                                    {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.actual_value ? this.state.selectedDiagnosticEvent.actual_value : "-"}
                                                </p>
                                            </div>
                                        </div>
                                        {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.asset_level ?
                                            <div className="d-flex mb-1 border-top">
                                                <div className="py-1 w-50">
                                                    <p className="b-block mb-0">Asset Name</p>
                                                    <p className="mb-0 text-white">
                                                        {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.asset_name ? this.state.selectedDiagnosticEvent.asset_name : "-"}
                                                    </p>
                                                </div>
                                                <div className="py-1 w-50 pl-3">
                                                    <p className="b-block mb-0">Actual Id</p>
                                                    <p className="mb-0 text-white">
                                                        {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.asset_id ? this.state.selectedDiagnosticEvent.asset_id : "-"}
                                                    </p>
                                                </div>
                                            </div>
                                        : null}
                                        <div className="d-flex mb-1 border-top">
                                            <div className="py-1 w-50">
                                                <p className="b-block mb-0">Provider : Account : Region</p>
                                                <p className="mb-0 text-white">
                                                    {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.provider ? this.state.selectedDiagnosticEvent.provider.toUpperCase() : <span>&nbsp;</span>}
                                                    {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.account_id ? (' : ')+getAccountNameFromId(this.state.selectedDiagnosticEvent.account_id, this.state.accounts) : <span>&nbsp;</span>}
                                                    {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.region ?  (' : ')+getRegionName(this.state.selectedDiagnosticEvent.region, this.state.regions) : <span>&nbsp;</span>}
                                                </p>
                                            </div>
                                            <div className="py-1 w-50 pl-3">
                                                <p className="b-block mb-0">Service</p>
                                                <p className="mb-0 text-white">
                                                    {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.resource_type ? this.state.selectedDiagnosticEvent.resource_type : <span>&nbsp;</span>}
                                                    {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.service_name ?  (' : ')+this.state.selectedDiagnosticEvent.service_name : <span>&nbsp;</span>}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            : (this.state.selectedDiagnosticEventLabel === 'Operational Health Events' || this.state.selectedDiagnosticEventLabel === 'Availability Health Events' || this.state.selectedDiagnosticEventLabel === 'Network Health Events' || this.state.selectedDiagnosticEventLabel === 'Service Health Events' || this.state.selectedDiagnosticEventLabel === 'Console Health Events' || this.state.selectedDiagnosticEventLabel === 'Connectivity Health Events' || this.state.selectedDiagnosticEventLabel === 'API Health Events' || this.state.selectedDiagnosticEventLabel === 'Error/Failure Health Events' || this.state.selectedDiagnosticEventLabel === 'Performance Health Events' || this.state.selectedDiagnosticEventLabel === 'Security Health Events' || this.state.selectedDiagnosticEventLabel === 'Limit Health Events' || this.state.selectedDiagnosticEventLabel === 'Service Health Events' || this.state.selectedDiagnosticEventLabel === 'Update/Upgrade/Patch Changes' || this.state.selectedDiagnosticEventLabel === 'Maintenance Events' || this.state.selectedDiagnosticEventLabel === 'Maintenance Scheduled Health Events') ?
                                <div className="mt-3">
                                    <div className="rounded bg-dark p-3">
                                        <div className="d-flex mb-1">
                                            <div className="py-1 w-50">
                                                <p className="b-block mb-0">Event Name</p>
                                                <p className="mb-0 text-white">
                                                    {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.event_name ? this.state.selectedDiagnosticEvent.event_name : "-"}
                                                </p>
                                            </div>
                                            <div className="py-1 w-50 pl-3">
                                                <p className="b-block mb-0">Severity</p>
                                                {this.state.selectedDiagnosticEvent.importance ?
                                                    <span class={`badge risk-badge-${this.state.selectedDiagnosticEvent.importance.toLowerCase()}`}>{this.state.selectedDiagnosticEvent.importance}</span>
                                                : null}
                                            </div>
                                        </div>
                                        <div className="d-flex mb-1 border-top">
                                            <div className="py-1 w-50">
                                                <p className="b-block mb-0">Event Id</p>
                                                <p className="mb-0 text-white">
                                                    {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.event_id ? this.state.selectedDiagnosticEvent.event_id : "-"}
                                                </p>
                                            </div>
                                            <div className="py-1 w-50 pl-3">
                                                <p className="b-block mb-0">Event Source</p>
                                                <p className="mb-0 text-white">
                                                    {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.event_source ? this.state.selectedDiagnosticEvent.event_source : "-"}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="d-flex mb-1 border-top">
                                            <div className="py-1 w-50">
                                                <p className="b-block mb-0">Event Type Code</p>
                                                <p className="mb-0 text-white">
                                                    {this.state.selectedDiagnosticEvent.additional_details && this.state.selectedDiagnosticEvent.additional_details.eventTypeCode ?
                                                        <span>{this.state.selectedDiagnosticEvent.additional_details.eventTypeCode}</span>
                                                    : <span>&nbsp;</span>}
                                                </p>
                                            </div>
                                            <div className="py-1 w-50 pl-3">
                                                <p className="b-block mb-0">Category</p>
                                                {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.category ? 
                                                    <small className={`badge badge-secondary`}>{this.state.selectedDiagnosticEvent.category}</small> 
                                                : null}
                                            </div>
                                        </div>
                                        <div className="d-flex mb-1 border-top">
                                            <div className="py-1 w-100">
                                                <p className="b-block mb-0">Description</p>
                                                {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.additional_details && this.state.selectedDiagnosticEvent.additional_details.eventDescription.length ?
                                                    this.state.selectedDiagnosticEvent.additional_details.eventDescription.map((des, desIndex) => {
                                                        return(
                                                            des.latestDescription ? 
                                                                <p className={`d-flex mt-1`}>
                                                                    <span className="text-primary-color mr-1">{desIndex+1}.</span>
                                                                    <span className={`mb-0 text-white`}>{des.latestDescription}</span>
                                                                </p>
                                                            : null
                                                        )
                                                    })
                                                : null}
                                            </div>
                                        </div>
                                        
                                        {/* {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.asset_level ?
                                            <div className="d-flex mb-1 border-top">
                                                <div className="py-1 w-50">
                                                    <p className="b-block mb-0">Asset Name</p>
                                                    <p className="mb-0 text-white">
                                                        {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.asset_name ? this.state.selectedDiagnosticEvent.asset_name : "-"}
                                                    </p>
                                                </div>
                                                <div className="py-1 w-50 pl-3">
                                                    <p className="b-block mb-0">Actual Id</p>
                                                    <p className="mb-0 text-white">
                                                        {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.asset_id ? this.state.selectedDiagnosticEvent.asset_id : "-"}
                                                    </p>
                                                </div>
                                            </div>
                                        : null} */}
                                        {/* <div className="d-flex mb-1 border-top">
                                            <div className="py-1 w-50">
                                                <p className="b-block mb-0">Provider : Account : Region</p>
                                                <p className="mb-0 text-white">
                                                    {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.provider ? this.state.selectedDiagnosticEvent.provider.toUpperCase() : <span>&nbsp;</span>}
                                                    {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.account_id ? (' : ')+getAccountNameFromId(this.state.selectedDiagnosticEvent.account_id, this.state.accounts) : <span>&nbsp;</span>}
                                                    {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.region ?  (' : ')+getRegionName(this.state.selectedDiagnosticEvent.region, this.state.regions) : <span>&nbsp;</span>}
                                                </p>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            : null
                        : null}

                        {this.state.selectedDiagnosticEventTag === "Alerts" ?
                            <div className="mt-3">
                                <div className="rounded bg-dark p-3">
                                    <p className="mb-1 f16 text-white">Alerts</p>
                                    {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.alerts  && this.state.selectedDiagnosticEvent.alerts.length ?
                                        this.state.selectedDiagnosticEvent.alerts.map(item => {
                                            return(
                                                <div className="d-flex cursorPointer">
                                                    <span className={`f10 mr-2 align-self-start mt-1 badge-sm risk-badge-${item.severity ? item.severity.toLowerCase() : 'critical'}`}>
                                                        {item.severity ? 
                                                            (item.severity.toLowerCase() === 'critical' ? 'C' : item.severity.toLowerCase() === 'high' ? 'H' : item.severity.toLowerCase() === 'medium' ? 'M' : 'L') 
                                                        : 'U'}
                                                    </span>
                                                    <p className="mb-0">
                                                        <span className="mb-0 mr-2 f12 text-primary-color font-weight-bold">{item.event_time ? momentConvertionUtcToLocalTime(item.event_time, 'DD MMM YYYY HH:mm') : ""} </span>
                                                        <span className={`mb-0 mr-1 f12 text-white`}>{item.alert_type ? item.alert_type : ""} </span>
                                                        <span className={`mb-0 mr-2 f12 text-white`}>
                                                            {item.event_source ? (Array.isArray(item.event_source) ? item.event_source.join(", ") : item.event_source) : ""}
                                                            {item.event_name ? ' : ' + item.event_name : ""}
                                                            {item.event_type ? ' : '+ item.event_type : ""}
                                                        </span>
                                                        {item.asset_name ?
                                                            <span className="mb-0 mr-2 f12 text-white font-weight-bold">{item.asset_name}</span>
                                                        : null}
                                                        {item.account_id ?
                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">{' '+item.account_id} </span>
                                                        : null}
                                                        {item.region ?
                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+item.region} </span>
                                                        : null}
                                                        {item.description ?
                                                            <span className="mb-0 ml-2 f12 text-white font-weight-bold">{item.description} </span>
                                                        : null}
                                                    </p>
                                                </div>
                                            )
                                        })
                                    : null}
                                </div>
                            </div>
                        : this.state.selectedDiagnosticEventTag === "Anomalies" ?
                            <div className="mt-3">
                                <div className="rounded bg-dark p-3">
                                    <p className="mb-1 f16 text-white">Anomalies</p>
                                    {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.anomalies  && this.state.selectedDiagnosticEvent.anomalies.length ?
                                        this.state.selectedDiagnosticEvent.anomalies.map(item => {
                                            return(
                                                <div className="d-flex cursorPointer">
                                                    <span className={`f10 mr-2 align-self-start mt-1 badge-sm risk-badge-${item.severity ? item.severity.toLowerCase() : 'critical'}`}>
                                                        {item.severity ? 
                                                            (item.severity.toLowerCase() === 'critical' ? 'C' : item.severity.toLowerCase() === 'high' ? 'H' : item.severity.toLowerCase() === 'medium' ? 'M' : 'L') 
                                                        : 'U'}
                                                    </span>
                                                    <p className="mb-0">
                                                        <span className="mb-0 mr-2 f12 text-primary-color font-weight-bold">{item.anomaly_time ? momentConvertionUtcToLocalTime(item.anomaly_time, 'DD MMM YYYY HH:mm') : ""} </span>
                                                        <span className={`mb-0 mr-1 f12 text-purple-2`}>{item.anomaly_type ? item.anomaly_type : ""} </span>
                                                        <span className={`mb-0 mr-1 f12`}>{item.anomaly_name ? item.anomaly_name : ""} </span>
                                                        <span className="mb-0 small text-primary-color mr-1">Resource </span>
                                                        {item.account_id ?
                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">{' '+item.account_id} </span>
                                                        : null}
                                                        {item.region ?
                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+item.region} </span>
                                                        : null}
                                                        {item.anomaly_message ?
                                                            <span className="mb-0 ml-2 f12 text-white font-weight-bold">{item.anomaly_message} </span>
                                                        : null}
                                                    </p>
                                                </div>
                                            )
                                        })
                                    : null}
                                </div>
                            </div>
                        : (this.state.selectedDiagnosticEventTag === "Backup" || this.state.selectedDiagnosticEventTag === "Failover" || this.state.selectedDiagnosticEventTag === "Failure" || this.state.selectedDiagnosticEventTag === "Replication" || this.state.selectedDiagnosticEventTag === "Restoration" || this.state.selectedDiagnosticEventTag === "Recovery") ?
                            <div className="mt-3">
                                <div className="rounded bg-dark p-3">
                                    <p className="mb-1 f16 text-white">Event Details</p>
                                    <div className="d-flex mb-1">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Event Name</p>
                                            <p className="mb-0 text-white">
                                                {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.event_name ? this.state.selectedDiagnosticEvent.event_name : "-"}
                                            </p>
                                        </div>
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">Severity</p>
                                            {this.state.selectedDiagnosticEvent.importance ?
                                                <span class={`badge risk-badge-${this.state.selectedDiagnosticEvent.importance.toLowerCase()}`}>{this.state.selectedDiagnosticEvent.importance}</span>
                                            : null}
                                        </div>
                                    </div>
                                    <div className="d-flex mb-1 border-top">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Event Time</p>
                                            <p className="mb-0 text-white">
                                                {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.event_time ? momentConvertionUtcToLocalTime(this.state.selectedDiagnosticEvent.event_time, "DD MMM YYYY HH:mm:ss") : <span>&nbsp;</span>}
                                            </p>
                                        </div>
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">Event Type</p>
                                            <p className="mb-0 text-white">
                                                {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.event_type ? this.state.selectedDiagnosticEvent.event_type : "-"}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-1 border-top">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Event Source</p>
                                            <p className="mb-0 text-white">
                                                {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.event_source ? this.state.selectedDiagnosticEvent.event_source : "-"}
                                            </p>
                                        </div>
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">Category</p>
                                            {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.category ? 
                                                <small className={`badge badge-secondary`}>{this.state.selectedDiagnosticEvent.category}</small> 
                                            : null}
                                        </div>
                                    </div>
                                    <div className="d-flex mb-1 border-top">
                                        <div className="py-1 w-100">
                                            <p className="b-block mb-0">Message</p>
                                            {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.additional_details && this.state.selectedDiagnosticEvent.additional_details.message ?
                                                <p className="mb-0 text-white">
                                                    {this.state.selectedDiagnosticEvent.additional_details.message}
                                                </p>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : (this.state.selectedDiagnosticEventTag === "Errors") ?
                            <div className="mt-3">
                                <div className="rounded bg-dark p-3">
                                    <p className="mb-1 f16 text-white">Error Details</p>
                                    <div className="d-flex mb-1">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Event Time</p>
                                            <p className="mb-0 text-white">
                                                {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.event_time ? momentConvertionUtcToLocalTime(this.state.selectedDiagnosticEvent.event_time, "DD MMM YYYY HH:mm:ss") : <span>&nbsp;</span>}
                                            </p>
                                        </div>
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">Severity</p>
                                            <p className="mb-0 text-white">
                                                {this.state.selectedDiagnosticEvent.severity ?
                                                    <span class={`badge risk-badge-${this.state.selectedDiagnosticEvent.severity.toLowerCase()}`}>{this.state.selectedDiagnosticEvent.severity}</span>
                                                : null}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-1 border-top">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Error Type</p>
                                            <p className="mb-0 text-white">
                                                {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.error_type ? this.state.selectedDiagnosticEvent.error_type.join(", ") : "-"}
                                            </p>
                                        </div>
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">Error Type Source</p>
                                            <p className="mb-0 text-white">
                                                {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.error_type_source ? this.state.selectedDiagnosticEvent.error_type_source : "-"}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-1 border-top">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Event Source</p>
                                            <p className="mb-0 text-white">
                                                {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.event_source ? this.state.selectedDiagnosticEvent.event_source : "-"}
                                            </p>
                                        </div>
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">Log Group Name</p>
                                            <p className="mb-0 text-white">
                                                {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.log_group_name ? this.state.selectedDiagnosticEvent.log_group_name : "-"}
                                            </p>
                                        </div>
                                    </div>
                                    {this.state.selectedDiagnosticEvent.category ? 
                                        <div className="d-flex mb-1 border-top">
                                            <div className="py-1 w-100">
                                                <p className="b-block mb-0">Category</p>
                                                <p className={`d-flex flex-wrap mb-0`}>
                                                    {this.state.selectedDiagnosticEvent.category.map(row => {
                                                        return(
                                                            <small className={`badge badge-secondary mr-1`}>{row}</small> 
                                                        )
                                                    })}
                                                </p>
                                            </div>
                                        </div>
                                    : null}
                                    <div className="d-flex mb-1 border-top">
                                        <div className="py-1 w-100">
                                            <p className="b-block mb-0">Cause</p>
                                            {this.state.selectedDiagnosticEvent.cause && this.state.selectedDiagnosticEvent.cause.map((row, rowIndex) => {
                                                return(
                                                    <p className={`d-flex mt-1 mb-1`}>
                                                        <span className="text-primary-color mr-1">{rowIndex+1}.</span>
                                                        <span className={`mb-0 text-white`}>{row}</span>
                                                    </p>
                                                )
                                            })}
                                        </div>
                                    </div>                                
                                    <div className="d-flex mb-1 border-top">
                                        <div className="py-1 w-100">
                                            <p className="b-block mb-0">Cause Tags</p>
                                            <p className={`d-flex flex-wrap mb-0`}>
                                                {this.state.selectedDiagnosticEvent.cause_tags ? 
                                                    this.state.selectedDiagnosticEvent.cause_tags.map(row => {
                                                        return(
                                                            <small className={`badge badge-secondary mr-1`}>{row}</small> 
                                                        )
                                                    })
                                                : null}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-1 border-top">
                                        <div className="py-1 w-100">
                                            <p className="b-block mb-0">Solution</p>
                                            {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.solution ?
                                                <p className="mb-0 text-white">
                                                    {this.state.selectedDiagnosticEvent.solution}
                                                </p>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : null}

                        {this.state.selectedDiagnosticEventTag !== "Slow Query" && this.state.assetDetails && Object.entries(this.state.assetDetails).length ?
                            <div className="mt-3">
                                <div className="rounded bg-dark p-3">
                                    <p className="mb-1 f16 text-white">Asset Details</p>
                                    <div className="d-flex mb-1 border-bottom">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Provider / Account / Region</p>
                                            <p className="mb-0 text-white">
                                                {this.state.assetDetails && this.state.assetDetails.provider ? this.state.assetDetails.provider.toUpperCase() : <span>&nbsp;</span>}
                                                {this.state.assetDetails && this.state.assetDetails.account_id ? (' : ')+getAccountNameFromId(this.state.assetDetails.account_id, this.props.accounts) : (this.state.selectedDiagnosticEvent.account_id ? this.state.selectedDiagnosticEvent.account_id : <span>&nbsp;</span>)}
                                                {this.state.assetDetails && this.state.assetDetails.region ?  (' : ')+getRegionName(this.state.assetDetails.region, this.state.regions) : <span>&nbsp;</span>}
                                            </p>
                                        </div>                                    
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">Service</p>
                                            <p className="mb-0 text-white">
                                                {this.state.assetDetails && this.state.assetDetails.resource_type ? this.state.assetDetails.resource_type : <span className='mx-2'>-</span>}
                                                {this.state.assetDetails && this.state.assetDetails.service_name ?  (' : ')+this.state.assetDetails.service_name : <span>&nbsp;</span>}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="d-flex">
                                        <div className="py-1 w-50">
                                            <p className="b-block mb-0">Asset</p>
                                            <p className="mb-0 text-white">{this.state.assetDetails && this.state.assetDetails.asset_name ? this.state.assetDetails.asset_name : <span>&nbsp;</span>}</p>
                                        </div>
                                        <div className="py-1 w-50 pl-3">
                                            <p className="b-block mb-0">Asset Time</p>
                                            <p className="mb-0 text-white">{this.state.assetDetails && this.state.assetDetails.event_time ? momentConvertionUtcToLocalTime(this.state.assetDetails.event_time, 'DD MMM YYYY HH:mm:ss') : <span>&nbsp;</span>}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : null}

                        {this.state.selectedDiagnosticEvent && Object.entries(this.state.selectedDiagnosticEvent).length ? 
                            this.state.selectedDiagnosticEventLabel === 'Traffic Surge' ?
                                <div className="mt-3">
                                    <div className="mt-2">
                                        <TrafficMetrics
                                            page={"diagnostics"}
                                            metricsType={this.state.selectedDiagnosticEventLabel}
                                            selectedEvent = {this.state.selectedDiagnosticEvent}
                                            assetDetails={this.state.assetDetails}
                                            diagnostics = {this.props.diagnostics}
                                            brush={false}
                                            sparkline={false}
                                            yaxis={true}
                                            yaxisLabel={true}
                                            xaxis={true}
                                            xaxisFormat={''}
                                            xaxisLabel={true}
                                            grid={false}
                                            axisLabelColor={'#B8BBBE'}
                                            legend={false}
                                            stacked={false}
                                            height={115}
                                            horizontal={true}
                                            barHeight={'40%'}
                                            barEndShape={'rounded'}
                                            columnWidth={'50%'}
                                            gradient={true}
                                            gradientColor={['#039BE5', '#5F5BA2']}
                                            hideTooltipValue={true}
                                            backgroundBarShape={'rounded'}
                                            backgroundBarColors={['#333947']}
                                            showBackgroundBarColors={false}
                                            className={"transparentTooltip mt-n4 mb-n4"}
                                            annotations={true}
                                            annotaionOptions={this.state.mertcisAnnotationTypes}
                                        />
                                    </div>
                                    <div className="mt-2">
                                        <div className="rounded bg-dark p-3">
                                            <h7 className="text-white">Detail</h7>
                                            <p className="mb-0">Traffic Surge detected at {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.event_time ? momentConvertionUtcToLocalTime(this.state.selectedDiagnosticEvent.event_time, "DD MMM YYYY HH:mm") : ""}. It followed a {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.pattern ? this.state.selectedDiagnosticEvent.pattern : ""} pattern. The normal traffic range is {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.lower_value ? this.state.selectedDiagnosticEvent.lower_value : ""} to {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.upper_value ? this.state.selectedDiagnosticEvent.upper_value : ""} {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.unit ? this.state.selectedDiagnosticEvent.unit : ""} and a total of {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.total_anomalies} anomalies had been detected in the duration of {twoDateDiffrenceDayHoursMinutes(this.state.selectedDiagnosticEvent.start_time, this.state.selectedDiagnosticEvent.end_time)}  </p>
                                        </div>
                                    </div>
                                </div>
                            : this.state.selectedDiagnosticEventLabel === 'SRE Observations' ?
                                <div className=" mt-3">
                                    <SreMetricDetails 
                                        page={"diagnostics"}
                                        assetDetails={this.state.assetDetails}
                                        diagnostics = {this.props.diagnostics}
                                        brush={true}
                                        sparkline={false}
                                        yaxis={true}
                                        yaxisLabel={true}
                                        xaxis={true}
                                        xaxisFormat={''}
                                        xaxisLabel={true}
                                        grid={false}
                                        axisLabelColor={'#B8BBBE'}
                                        legend={false}
                                        stacked={false}
                                        height={200}
                                        horizontal={true}
                                        barHeight={'40%'}
                                        barEndShape={'rounded'}
                                        columnWidth={'50%'}
                                        gradient={true}
                                        gradientColor={['#24A597', '#775BA2']}
                                        hideTooltipValue={true}
                                        backgroundBarShape={'rounded'}
                                        backgroundBarColors={['#333947']}
                                        showBackgroundBarColors={false}
                                        className={"transparentTooltip"}
                                        annotations={true}
                                    />
                                </div>
                            : null
                        : null}

                        {this.state.gandalfBarData && Object.entries(this.state.gandalfBarData).length ?
                            <div className="my-3 bg-dark p-3 rounded">
                                <p className="m-0 text-white">Change Impact Analysis</p>
                                <p className="small mb-1">Event changed at 
                                    <span className="ml-1 text-info">{this.state.impactAnalysis && this.state.impactAnalysis.change_event_time ? momentConvertionUtcToLocalTime(this.state.impactAnalysis.change_event_time, "YYYY-MM-DD HH:mm:ss") : ""} </span>
                                    <span className="ml-1">is a {this.state.impactAnalysis && this.state.impactAnalysis.change_events && this.state.impactAnalysis && this.state.impactAnalysis.change_events.length ? "bad" : "good"} change, with</span>
                                    <span className={`ml-1 risk-${this.state.impactAnalysis && this.state.impactAnalysis.severity ? this.state.impactAnalysis.severity.toLowerCase() : ""}`}>{this.state.impactAnalysis && this.state.impactAnalysis.severity}</span>
                                    <span className="ml-1">severity</span>
                                </p>
                                <ApexGandalfBarChart
                                    page={"diagnostics"}
                                    graphData={this.state.gandalfBarData}
                                    sparkline={false}
                                    yaxis={false}
                                    yaxisLabel={true}
                                    xaxis={true}
                                    xaxisFormat={'datetime'}
                                    xaxisLabel={true}
                                    axisLabelColor={'#50586a'}
                                    axisLabelFontSize={'12px'}
                                    paddingLeft={-25}
                                    legend={false}
                                    stacked={false}
                                    height={180}
                                    horizontal={false}
                                    barHeight={'40%'}
                                    className={"transparentTooltip bg-dark3 rounded"}
                                    colors={['#3DAFE8', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7']}
                                />
                                <div className="d-flex bg-dark3 rounded p-2 mt-3">
                                    <div className="w-50 border-right-info">
                                        <label className="f16 mb-2 text-info text-center d-flex justify-content-center">Before</label>
                                        {this.state.beforeCount && this.state.beforeCount.length ?
                                            this.state.beforeCount.map(item => {
                                                return(
                                                <div className="d-flex justify-content-between mb-1">
                                                    <p className="mb-0 text-white">{capitalizeFirstLetter(item.type)} : <span className="text-primary-color">{item.count}</span></p>
                                                    {item.type === "alerts" || item.type === "anomalies" ?
                                                        <ul className="stock-legend mb-0 d-flex justify-content-center f12">
                                                            <li calssName="d-flex"><span className="legend-circle risk-bg-critical mt-3p"></span> <span className="text-muted d-inline-block"> &nbsp;C: {item.Critical}</span></li>
                                                            <li calssName="d-flex"><span className="legend-circle risk-bg-high mt-3p"></span> <span className="text-muted d-inline-block"> &nbsp;H: {item.High}</span></li>
                                                        </ul>
                                                    : item.type === "issues" && this.state.beforeIssuesProgressData ?
                                                        <div className="w-40 mr-2">
                                                            <ApexBarProgressChart
                                                                graphData={this.state.beforeIssuesProgressData}
                                                                sparkline={true}
                                                                horizontal={true}
                                                                yaxis={false}
                                                                yaxisLabel={false}
                                                                xaxis={true}
                                                                xaxisFormat={'string'}
                                                                xaxisLabel={true}
                                                                axisLabelColor={'#B8BBBE'}
                                                                // paddingLeft={-25}
                                                                legend={false}
                                                                stacked={false}
                                                                height={20}
                                                                barHeight={'20%'}
                                                                barEndShape={'rounded'}
                                                                columnWidth={'25%'}
                                                                gradient={true}                                                                
                                                                colors={["#FF4560"]}
                                                                backgroundBarColors={["#40475D"]}
                                                                titleColor={"#B8BBBE"}
                                                                className={"transparentTooltip"}
                                                            />
                                                        </div>
                                                    : item.type === "errors" && this.state.beforeErrorsProgressData ?
                                                        <div className="w-40 mr-2">
                                                            <ApexBarProgressChart
                                                                graphData={this.state.beforeErrorsProgressData}
                                                                sparkline={true}
                                                                horizontal={true}
                                                                yaxis={false}
                                                                yaxisLabel={false}
                                                                xaxis={true}
                                                                xaxisFormat={'string'}
                                                                xaxisLabel={true}
                                                                axisLabelColor={'#B8BBBE'}
                                                                // paddingLeft={-25}
                                                                legend={false}
                                                                stacked={false}
                                                                height={20}
                                                                barHeight={'20%'}
                                                                barEndShape={'rounded'}
                                                                columnWidth={'25%'}
                                                                gradient={true}                                                                
                                                                colors={["#24A597"]}
                                                                backgroundBarColors={["#40475D"]}
                                                                titleColor={"#B8BBBE"}
                                                                className={"transparentTooltip"}
                                                            />
                                                        </div>
                                                    : this.state.progressData ?
                                                        <div className="w-40 mr-2">
                                                                <ApexBarProgressChart
                                                                    graphData={this.state.progressData}
                                                                    sparkline={true}
                                                                    horizontal={true}
                                                                    yaxis={false}
                                                                    yaxisLabel={false}
                                                                    xaxis={true}
                                                                    xaxisFormat={'string'}
                                                                    xaxisLabel={true}
                                                                    axisLabelColor={'#B8BBBE'}
                                                                    // paddingLeft={-25}
                                                                    legend={false}
                                                                    stacked={false}
                                                                    height={20}
                                                                    barHeight={'20%'}
                                                                    barEndShape={'rounded'}
                                                                    columnWidth={'25%'}
                                                                    gradient={true}
                                                                    backgroundBarColors={["#40475D"]}
                                                                    titleColor={"#B8BBBE"}
                                                                    className={"transparentTooltip"}
                                                                />
                                                            </div>
                                                    : null}
                                                </div>
                                                )
                                            })
                                        : null}
                                    </div>

                                    <div className="w-50 pl-2">
                                        <label className="f16 mb-2 text-info text-center d-flex justify-content-center">After</label>
                                        {this.state.afterCount && this.state.afterCount.length ?
                                            this.state.afterCount.map(item => {
                                                return(
                                                <div className="d-flex justify-content-between mb-1">
                                                    <p className="mb-0 text-white">{capitalizeFirstLetter(item.type)} : <span className="text-primary-color">{item.count}</span></p>
                                                    {item.type === "alerts" || item.type === "anomalies" ?
                                                        <ul className="stock-legend mb-0 d-flex justify-content-center f12">
                                                            <li calssName="d-flex"><span className="legend-circle risk-bg-critical mt-3p"></span> <span className="text-muted d-inline-block"> &nbsp;C: {item.Critical}</span></li>
                                                            <li calssName="d-flex"><span className="legend-circle risk-bg-high mt-3p"></span> <span className="text-muted d-inline-block"> &nbsp;H: {item.High}</span></li>
                                                        </ul>
                                                    : item.type === "issues" && this.state.afterIssuesProgressData ?
                                                        <div className="w-40 mr-2">
                                                            <ApexBarProgressChart
                                                                graphData={this.state.afterIssuesProgressData}
                                                                sparkline={true}
                                                                horizontal={true}
                                                                yaxis={false}
                                                                yaxisLabel={false}
                                                                xaxis={true}
                                                                xaxisFormat={'string'}
                                                                xaxisLabel={true}
                                                                axisLabelColor={'#B8BBBE'}
                                                                // paddingLeft={-25}
                                                                legend={false}
                                                                stacked={false}
                                                                height={20}
                                                                barHeight={'20%'}
                                                                barEndShape={'rounded'}
                                                                columnWidth={'25%'}
                                                                gradient={true}
                                                                colors={["#FF4560"]}
                                                                backgroundBarColors={["#40475D"]}
                                                                titleColor={"#B8BBBE"}
                                                                className={"transparentTooltip"}
                                                            />
                                                        </div>
                                                    : item.type === "errors" && this.state.afterErrorsProgressData ?
                                                        <div className="w-40 mr-2">
                                                            <ApexBarProgressChart
                                                                graphData={this.state.afterErrorsProgressData}
                                                                sparkline={true}
                                                                horizontal={true}
                                                                yaxis={false}
                                                                yaxisLabel={false}
                                                                xaxis={true}
                                                                xaxisFormat={'string'}
                                                                xaxisLabel={true}
                                                                axisLabelColor={'#B8BBBE'}
                                                                // paddingLeft={-25}
                                                                legend={false}
                                                                stacked={false}
                                                                height={20}
                                                                barHeight={'20%'}
                                                                barEndShape={'rounded'}
                                                                columnWidth={'25%'}
                                                                gradient={true}                                                                
                                                                colors={["#24A597"]}
                                                                backgroundBarColors={["#40475D"]}
                                                                titleColor={"#B8BBBE"}
                                                                className={"transparentTooltip"}
                                                            />
                                                        </div>
                                                    : this.state.progressData ?
                                                        <div className="w-40 mr-2">
                                                                <ApexBarProgressChart
                                                                    graphData={this.state.progressData}
                                                                    sparkline={true}
                                                                    horizontal={true}
                                                                    yaxis={false}
                                                                    yaxisLabel={false}
                                                                    xaxis={true}
                                                                    xaxisFormat={'string'}
                                                                    xaxisLabel={true}
                                                                    axisLabelColor={'#B8BBBE'}
                                                                    // paddingLeft={-25}
                                                                    legend={false}
                                                                    stacked={false}
                                                                    height={20}
                                                                    barHeight={'20%'}
                                                                    barEndShape={'rounded'}
                                                                    columnWidth={'25%'}
                                                                    gradient={true}
                                                                    backgroundBarColors={["#40475D"]}
                                                                    titleColor={"#B8BBBE"}
                                                                    className={"transparentTooltip"}
                                                                />
                                                            </div>
                                                    : null}
                                                </div>
                                                )
                                            })
                                        : null}
                                    </div>
                                </div>
                                {this.state.impactAnalysis && this.state.impactAnalysis.intervention && this.state.impactAnalysis.intervention.length ?
                                    <React.Fragment>
                                    <p className="m-0 text-white mt-3">Interventions</p>
                                    <p className="small mb-1">Event changed at 
                                        <span className="ml-1 text-info">{this.state.impactAnalysis && this.state.impactAnalysis.change_event_time ? momentConvertionUtcToLocalTime(this.state.impactAnalysis.change_event_time, "YYYY-MM-DD HH:mm:ss") : ""} </span>
                                        <span className="ml-1">is a {this.state.impactAnalysis && this.state.impactAnalysis.change_events && this.state.impactAnalysis && this.state.impactAnalysis.change_events.length ? "bad" : "good"} change, with</span>
                                        <span className={`ml-1 risk-${this.state.impactAnalysis && this.state.impactAnalysis.severity ? this.state.impactAnalysis.severity.toLowerCase() : ""}`}>{this.state.impactAnalysis && this.state.impactAnalysis.severity}</span>
                                        <span className="ml-1">severity</span>
                                    </p>
                                    <ApexGandalfInterventionChart
                                        graphData={""}
                                        impactAnalysis={this.state.impactAnalysis}
                                        sparkline={false}
                                        yaxis={false}
                                        yaxisLabel={true}
                                        xaxis={true}
                                        xaxisFormat={'datetime'}
                                        xaxisLabel={true}
                                        axisLabelColor={'#50586a'}
                                        axisLabelFontSize={'12px'}
                                        paddingLeft={-25}
                                        legend={false}
                                        stacked={false}
                                        height={180}
                                        showMarkers={false}
                                        className={"transparentTooltip"}
                                        colors={['#775DD0', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7']}
                                    />
                                    </React.Fragment>
                                : null}
                            </div>
                        : null}
                        
                        {this.state.showSelectedMonitoringMetrics ?
                            <div className="my-3">
                                <SelectedMonitoringMetrics
                                    page={"diagnostics"}
                                    assetDetails={this.state.assetDetails}
                                    diagnostics = {this.props.diagnostics}
                                    brush={true}
                                    sparkline={false}
                                    yaxis={true}
                                    yaxisLabel={true}
                                    xaxis={true}
                                    xaxisFormat={''}
                                    xaxisLabel={true}
                                    grid={false}
                                    axisLabelColor={'#B8BBBE'}
                                    legend={false}
                                    stacked={false}
                                    height={150}
                                    horizontal={true}
                                    barHeight={'40%'}
                                    barEndShape={'rounded'}
                                    columnWidth={'50%'}
                                    gradient={true}
                                    gradientColor={['#039BE5', '#5F5BA2']}
                                    hideTooltipValue={true}
                                    backgroundBarShape={'rounded'}
                                    backgroundBarColors={['#333947']}
                                    showBackgroundBarColors={false}
                                    className={"transparentTooltip mt-n4 mb-n4"}
                                    annotations={true}
                                    annotaionOptions={this.state.mertcisAnnotationTypes}
                                />
                            </div>
                        : this.state.showSelecteSreMetrics ?
                            <div className="my-3">
                                <SelectedSreMetrics
                                    page={"diagnostics"}
                                    assetDetails={this.state.assetDetails}
                                    diagnostics = {this.props.diagnostics}
                                    brush={true}
                                    sparkline={false}
                                    yaxis={true}
                                    yaxisLabel={true}
                                    xaxis={true}
                                    xaxisFormat={''}
                                    xaxisLabel={true}
                                    grid={false}
                                    axisLabelColor={'#B8BBBE'}
                                    legend={false}
                                    stacked={false}
                                    height={150}
                                    horizontal={true}
                                    barHeight={'40%'}
                                    barEndShape={'rounded'}
                                    columnWidth={'50%'}
                                    gradient={true}
                                    gradientColor={['#039BE5', '#5F5BA2']}
                                    hideTooltipValue={true}
                                    backgroundBarShape={'rounded'}
                                    backgroundBarColors={['#333947']}
                                    showBackgroundBarColors={false}
                                    className={"transparentTooltip mt-n4 mb-n4"}
                                    annotations={true}
                                    annotaionOptions={this.state.mertcisAnnotationTypes}
                                />
                            </div>
                        : null}
                        
                        <div className="my-3">
                            {this.state.showMeticsChart ?
                                <MetricsSection
                                    page={"diagnostics"}
                                    metricsType={this.state.selectedDiagnosticEventLabel}
                                    selectedEvent = {this.state.selectedDiagnosticEvent}
                                    assetDetails={this.state.assetDetails}
                                    diagnostics = {this.props.diagnostics}
                                    brush={true}
                                    sparkline={false}
                                    yaxis={true}
                                    yaxisLabel={true}
                                    xaxis={true}
                                    xaxisFormat={''}
                                    xaxisLabel={true}
                                    grid={false}
                                    axisLabelColor={'#B8BBBE'}
                                    legend={false}
                                    stacked={false}
                                    height={115}
                                    horizontal={true}
                                    barHeight={'40%'}
                                    barEndShape={'rounded'}
                                    columnWidth={'50%'}
                                    gradient={true}
                                    gradientColor={['#039BE5', '#5F5BA2']}
                                    hideTooltipValue={true}
                                    backgroundBarShape={'rounded'}
                                    backgroundBarColors={['#333947']}
                                    showBackgroundBarColors={false}
                                    className={"transparentTooltip mt-n4 mb-n4"}
                                    annotations={true}
                                    annotaionOptions={this.state.mertcisAnnotationTypes}
                                />
                            : this.state.showMonitoringChart ?
                                <MonitoringTab 
                                    page={"diagnostics"}
                                    selectedDiagnosticEvent={this.state.selectedDiagnosticEvent}
                                />
                            : this.state.showInterventionMetrics ?
                                <InterventionMetrics
                                    page={"diagnostics"}
                                    metricsType={this.state.selectedDiagnosticEventLabel}
                                    assetDetails={this.state.assetDetails}
                                    diagnostics = {this.props.diagnostics}
                                    brush={false}
                                    sparkline={false}
                                    yaxis={true}
                                    yaxisLabel={true}
                                    xaxis={true}
                                    xaxisFormat={''}
                                    xaxisLabel={true}
                                    grid={false}
                                    axisLabelColor={'#B8BBBE'}
                                    legend={false}
                                    stacked={false}
                                    height={115}
                                    horizontal={true}
                                    barHeight={'40%'}
                                    barEndShape={'rounded'}
                                    columnWidth={'50%'}
                                    gradient={true}
                                    gradientColor={['#039BE5', '#5F5BA2']}
                                    hideTooltipValue={true}
                                    backgroundBarShape={'rounded'}
                                    backgroundBarColors={['#333947']}
                                    showBackgroundBarColors={false}
                                    className={"transparentTooltip mt-n4 mb-n4"}
                                    annotations={true}
                                    annotaionOptions={this.state.mertcisAnnotationTypes}
                                />
                            : null}
                        </div>
                    </div>
                </div>
            : null
		)
	}
}
/**
 * Type of the props used in the component
 */
DiagnosticsRightSection.propTypes = {
    listAllAccounts: PropTypes.func,
	listAllRegions: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
    return {
        accounts: state.filters.accounts,
        regions: state.filters.regions,
        selectedDiagnosticEvent: state.aiops.diagnosticsPropsDetails && state.aiops.diagnosticsPropsDetails.selectedDiagnosticEvent ? state.aiops.diagnosticsPropsDetails.selectedDiagnosticEvent : {},
    }
}

export default connect(mapStateToProps, {
    listAllAccounts,
	listAllRegions,
    getChangeImpactAnalysisResults,
})(withRouter(DiagnosticsRightSection))