/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ManagePowerTuning.js
 * @author Prakash // on 13/12/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { 
	Input,
	Spinner
} from 'reactstrap'
import { listAllProviders, listAllAccounts, listAllRegions, getAllTagsKeys, getAllTagsValues } from '../../../actions/commonAction'
// import { listAssets } from '../../../actions/detectors/detectorsAction'

import { setCostPropsDetails, getPowerTuningAssets } from '../../../actions/cost/CostAction'
import { AppWrapper } from '../../common/AppWrapper'
import _ from 'lodash'
import { CSVLink } from "react-csv";

import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'
import Search from '../../common/SearchComponent'
import { URL_PATH } from '../../../config/urlPath'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

import { getAccountNameFromId, getRegionName, capitalizeTheFirstLetterOfEachWord, onlyNumeric, momentConvertionUtcToLocalTime } from '../../../utils/utility'
import ViewSidePanel from './ViewSidePanel'
import ViewPowerTuningSidePanel from './ViewPowerTuningSidePanel'

class ManagePowerTuning extends Component {
	constructor(props) {
		super(props)
        this.regionRef = React.createRef()
		this.advanceSearchRef = React.createRef()
		this.serviceRef = React.createRef()		
		this.tagsRef = React.createRef()

		this.state = {
			queryLoading: true,
			showLoading: false,
			selectedProvider: "",

			selectedAccount: "",
            isAccountOpen: false,

            selectedRegion: [],
            isRegionOpen: false,

			services: ["lambda"],			
			selectedService: "lambda",

			filteredArray: [],

			configurationTypes: [
				{label: "All", value: "All"},
				{label: "Configured", value: "Configured"},
				{label: "Not Configured", value: "Not Configured"},
			],
			alarmTypes: [
				{label: "All", value: "All"},
				{label: "Metric", value: "Metric"},
				{label: "Application", value: "Application"},
			],
			policyTypes: [
				{label: "Configured", value: "Configured"},
				{label: "Not Configured", value: "Not Configured"}
			],
			operators: [
				{label: "Equal To", value: "="},
				{label: "GreaterThan", value: ">"},
				{label: "Lesser Than", value: "<>"}
			],

			selectedViewOption: "policy_wise",
			selectedView: "policy_wise",
			selectedPolicyType: "Configured",
			
		}
	}

	componentDidMount = () => {
		if(!this.props.providers || !this.props.providers.length) {
			this.props.listAllProviders(promise => {
				if (promise) {
					let providers = this.props.providers
					this.setState({ providers, selectedProvider: providers[0].provider_name },
						() => this.filerInputs("provider")
					)
				} else {
					this.filerInputs()
				}
			})
		} else {
			let providers = this.props.providers.filter(arr => arr.provider_name !== "All")
			this.setState({ providers, selectedProvider: providers[0].provider_name },
				() => this.filerInputs("provider")
			)
		}		
	}

	/**
	 * Filter identifiers based on the filter selection
	 */
	filerInputs = from => {
		this.getAllTagsKeys()

		let params = {}
		if (this.state.selectedProvider) {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		
		if (from === "provider") {
			this.props.listAllAccounts(params, (promise, accounts) => {
				if (promise) {
					this.setState({ accounts })
				}
			})
			
			this.props.listAllRegions(params, (promise, regions) => {
				if (promise) {
					let providerRegions = regions && regions[0] && regions[0].provider_regions
					if(providerRegions) {
						let provider_regions_row = {}
						provider_regions_row.availability_zones = []
						provider_regions_row.city = "All"
						provider_regions_row.country = "All"
						provider_regions_row.geography = "All"
						provider_regions_row.name = "All"
						provider_regions_row.region = "All"
						provider_regions_row.region_id = "All"
						provider_regions_row.state = "All"
						providerRegions.unshift(provider_regions_row);
					}
					this.setState({ regions: providerRegions })
				} else {
					this.setState({ regions: [] })
				}
			})
		}
		
		this.setState({ queryLoading: true, filteredArray: [] },
			() => this.onSearch()
		)
	}

    getPowerTuningAssets = () => {
        
        let params = {}
		if(this.state.selectedProvider && this.state.selectedProvider !== "") {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if(this.state.selectedAccount && this.state.selectedAccount !== "") {
			params.account_id = this.state.selectedAccount
		}
		if (this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
			params.region = this.state.selectedRegion
		}
		if (this.state.selectedTags && this.state.selectedTags.length) {
			params.tags = this.state.selectedTags
		}
        params.service_name = this.state.selectedService
        
		this.props.getPowerTuningAssets(params, (promise, response) => {
			if (promise) {
				this.setState({ assetsList: response.results ? response.results : [], filteredArray: response.results ? response.results : [], queryLoading: false },
					() => this.formHeaders()	
				)
			} else {
				this.setState({ assetsList: [], filteredArray: [], queryLoading: false })
			}
		})
    }

	formHeaders = () => {
		let dataResult = this.state.filteredArray && this.state.filteredArray.length ? this.state.filteredArray : []
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === "string") {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})
			this.setState({ headers })
		}
	}

	getAllTagsKeys = () => {
		
		if(!this.props.tagKeys || !this.props.tagKeys.length) {
			let params = {}
			// params.account_id = "265469181489"
			// params.region = "us-east-2"
			this.props.getAllTagsKeys(params, (promise, response) => {})
		}
	}

	getAllTagsValues = () => {
		let params = {}
		params.tags = [{ "key": this.state.tag_key }]
		this.props.getAllTagsValues(params, (promise, response) => {
			if(promise) {
				this.setState({ tagValues: response },
					() => {
						if(this.state.tagsSelected && this.state.tagsSelected.filter(e => e.key === this.state.tag_key).length) {
							let value = this.state.tagsSelected.filter(e => e.key === this.state.tag_key).map(e => e.value)
							this.handleMultiSelectChange("tag_value", value, this.state.tagValues)
						}
					}	
				)
			}
		})
	}

	addNewTag = () => {
		let tagsSelected = this.state.tagsSelected ? this.state.tagsSelected : []

		if(this.state.tag_key && this.state.tag_key !== "") {
			let filteredTags = tagsSelected.filter(e => e.key !== this.state.tag_key)
			this.state.tag_value.forEach(row => {
				let dataRow = {
					key: this.state.tag_key,
					value: row,
				}
				filteredTags.push(dataRow)
			})

			this.setState({ tagsSelected: filteredTags, tag_key: "",  tag_value: "", tagValues: [] })
		}
	}

	removeTagSection = (tag) => {
		// filteredResult = this.state.tagsSelected.filter(e => e.key !== tag.key && e.value !== tag.value)
		let filteredResult = this.state.tagsSelected.filter(e => e !== tag)
		this.setState({ tagsSelected: filteredResult })
	}

    handleClickOutside = (event) => {

		if (this.regionRef && !this.regionRef.current.contains(event.target)) {
			this.setState({ isRegionOpen: false })
		} else {
			this.setState({ isRegionOpen: true })
		}

		if(this.state.showTagFilter) {
			if (this.tagsRef && !this.tagsRef.current.contains(event.target)) {
				this.setState({ showTagFilter: false })
			}
		}
    }

	handleMultiSelectChange = (field, arrayValue, state) => {
		let value = arrayValue.map(item => item.value ? item.value : item)
		let selectedValue = []
		let prevState = this.state[field] ? this.state[field] : []

		console.log("value", value)
		if(value.includes("All")) {
			if(!prevState.includes("All")) {
				if(state.filter(e => e.value).length) {
					console.log(state.map(e => e.value))
					selectedValue = state.map(e => e.value)
				} else {
					selectedValue = state
				}
			} else {
				const index = value.indexOf("All");
				if (index > -1) {
					value.splice(index, 1);
				}
				selectedValue = value
			}
		} else if(!prevState.includes("All")) {
			selectedValue = value
		}

		if(selectedValue.length && !selectedValue.includes("All") && state.includes("All")) {
			if(selectedValue.length === state.length -1) {
				selectedValue.push("All")
			}
		}

		this.setState({ [field]: selectedValue })
	}

	getMultiSelectedCount = (type, array) => {
		return array.length && array.includes("All") ? "All" :  array.length ? array.length +" Selected" : "All"
	}

	onSearch = () => {
		this.setState({ queryLoading: true }, () => this.getPowerTuningAssets())
	}
	
	onReset = () => {
		this.setState({
			selectedAccount: "",
			selectedRegion: [],
			selectedTags: [],
			selectedFilters: []
		})
	}
	
	onClickPrice = (selectedItem) => {
		this.props.setCostPropsDetails('selectedItem', selectedItem)
		this.setState({ selectedItem: selectedItem, showAlarmDetailView: true  })
	}

	onClickPowerTuningDetails = (selectedItem) => {
		console.log("selectedItem", selectedItem)
		this.setState({ selectedPowerTuningDetails: selectedItem },
			() => this.setState({ showPowerTuningView: true })	
		)

	}

	/**
	 * Renders governance Dashboard
	 */
	render() {
		return (
			<div className="container-fluid overflow-auto flex-grow-1 bg-muted" onClick={ (event) => { this.handleClickOutside(event) } }>
				<div className={`loaderOverlay ${this.state.queryLoading ? "" : "displayNone"}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
				{this.state.showAlarmDetailView ? (
					<ViewSidePanel
						selectedItem={this.state.selectedItem}
						closeSidePanel={() => this.setState({ showAlarmDetailView: false })}
					/>
				) : null}

				{this.state.showPowerTuningView ? (
					<ViewPowerTuningSidePanel
						selectedItem={this.state.selectedPowerTuningDetails}
						closeSidePanel={() => this.setState({ showPowerTuningView: false })}
					/>
				) : null}
				

				<div className="row">
					<div className="col-sm-12 p-0">
						<div className="title-section p-2">
							<div className="row">
								<div className="col-4 align-self-center">
									<h6 className="text-white m-0">Power Tuning</h6>
									<p className="text-white m-0">
										Showing the power tuning assets on your Cloud
									</p>
								</div>
							</div>
						</div>
						<div className="container-fluid bg-dark m-2 pt-3 rounded">
							<div className="bg-dark3 rounded p-3">
								<div className="containerFilterSection mb-2">
									<div className="d-flex">
										<div className="mr-4">
											<div className="d-flex justify-content-between">
												<label className="align-self-center m-0">Provider </label>
												{this.state.hasError && (this.state.selectedProvider || this.stae.selectedProvider === "") ? (
													<small className="text-issues">required</small>
												) : null}
											</div>
											<Select
												isSearchable={false}
												className={`selectOption f13 p-0`}
												components={{
													IndicatorSeparator: () => null
												}}
												value={({
													value: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : "",
													label: this.state.selectedProvider && this.state.selectedProvider.toLowerCase() !== "" ? this.state.selectedProvider.toUpperCase() : <span className="placeholder">Select</span>
												})}
												options={this.state.providers && this.state.providers.map(row => ({
													value: row.provider_name,
													label: row.provider_name,	
												}))}
												onChange={event => this.setState({
													selectedProvider: event.value,
													changesInFilter: true,
												})}
											/>
										</div>
										<div className="mr-4">
											<div className="d-flex justify-content-between">
												<label className="align-self-center m-0">Account </label>
												{this.state.hasError && (this.state.selectedAccount || this.stae.selectedAccount === "") ? (
													<small className="text-issues">required</small>
												) : null}
											</div>										
											<Select
												isSearchable={false}
												className={`selectOption f13 p-0`}
												components={{
													IndicatorSeparator: () => null
												}}
												value={({
													value: this.state.selectedAccount && this.state.selectedAccount !== "" ? this.state.selectedAccount : "",
													label: this.state.selectedAccount && this.state.selectedAccount !== "" ? getAccountNameFromId(this.state.selectedAccount, this.state.accounts) : <span className="placeholder">Select</span>
												})}
												options={this.state.accounts && this.state.accounts.map(row => ({
													value: row.account_id,
													label: row.account_name,	
												}))}
												onChange={event => this.setState({
													selectedAccount: event.value,
													changesInFilter: true,
												})}
											/>
										</div>
										<div className="mr-4" ref={this.regionRef}>
											<div className="d-flex justify-content-between">
												<label className="align-self-center pb-5p">Region </label>
												{this.state.hasError && (!this.state.selectedAccount || !this.state.selectedAccount.length) ? (
													<small className="text-issues">required</small>
												) : null}
											</div>
											<div className={`multiSelectOption align-self-end`}>
												<ReactMultiSelectCheckboxes
													placeholderButtonLabel="All"
													getDropdownButtonLabel={() => this.getMultiSelectedCount("selectedRegion", this.state.selectedRegion ? this.state.selectedRegion : [])}
													options={this.state.regions && this.state.regions.map(row => ({
														value: row.region,
														label: row.name,
													}))}
													onChange={arr => { this.handleMultiSelectChange("selectedRegion", arr ? arr : [], this.state.regions, "region")}}
													value={this.state.selectedRegion && this.state.selectedRegion.map(row => ({
														value: row
													}))}
												/>
											</div>
										</div>
										<div className="mr-4">
											<div className="d-flex justify-content-between">
												<label className="align-self-center m-0">Service </label>
												{this.state.hasError && (this.state.selectedService || this.stae.selectedService === "") ? (
													<small className="text-issues">required</small>
												) : null}
											</div>
											<Select
												isSearchable={false}
												className={`selectOption f13 p-0`}
												components={{
													IndicatorSeparator: () => null
												}}
												value={({
													value: this.state.selectedService && this.state.selectedService !== "" ? this.state.selectedService : "",
													label: this.state.selectedService && this.state.selectedService !== "" ? this.state.selectedService : <span className="placeholder">Select</span>
												})}
												options={this.state.services && this.state.services.map(row => ({
													value: row,
													label: row,	
												}))}
												onChange={event => this.setState({
													selectedService: event.value,
													changesInFilter: true,
												})}
											/>
										</div>
										
										<div className={`form-group flex-fill bd-highlight mb-sm-0 mr-sm-3`} style={{maxWidth: '150px' }} ref={this.tagsRef}>
											<div className="" onClick={() => this.setState({ showTagFilter: true })}>
												<label className="mb-0">Tags</label>
												<p className='mb-0 STDborderBottom mt-3'>
													{!this.state.selectedTags || (this.state.selectedTags && !this.state.selectedTags.length) ?
														<span>All</span>
													: this.state.selectedTags && this.state.selectedTags.length ?
														<span>{this.state.selectedTags.length} selected</span>
													: null}
												</p>
											</div>
											{this.state.showTagFilter ?
												<div className="costPopupTagSection border mt-1">
													<div className="p-3 	bg-muted">
														<div className={`w-100 mt-3`}>
															<p className="mb-2 w-100 text-white">Tags</p>
															<div className={`d-flex`}>
																<label className="mb-1 w-40">Key</label>
																<label className="mb-1 w-40 ml-3">Value</label>
															</div>
															<div className={`w-100`}>
																<div className={`d-flex mt-3`}>
																	<Select
																		isSearchable={true}
																		components={{
																			IndicatorSeparator: () => null
																		}}
																		className={`selectOption mr-2 mt-2`}
																		value={({
																			value: this.state.tag_key,
																			label: this.state.tag_key && this.state.tag_key !== "" ? this.state.tag_key : <span className="placeholder">Key</span>
																		})}														
																		
																		options={this.props.tagKeys && this.props.tagKeys.map(item => ({
																			value: item,
																			label: item,	
																		}))}
																		onChange={event => this.setState({
																			tag_key: event.value,
																			tagValues: [],
																		},
																			() => this.getAllTagsValues()
																		)}
																	/>
																	{this.state.tag_key && this.state.tag_key !== "" && !this.state.tagValues.length ?
																		<div className='ml-3'>
																			<Spinner className='text-center align-self-center' color='white' size='sm' />
																		</div>
																	:
																		<React.Fragment>
																			<div className={`multiSelectOption align-self-center mr-2 z998`} style={{marginTop: "1.3rem"}}>
																				<ReactMultiSelectCheckboxes
																					placeholderButtonLabel="Select"
																					getDropdownButtonLabel={() => this.getMultiSelectedCount("tag_value", this.state.tag_value ? this.state.tag_value : [])}
																					options={this.state.tagValues && this.state.tagValues.map(row => ({
																						value: row,
																						label: row,
																					}))}
																					onChange={arr => { this.handleMultiSelectChange("tag_value", arr ? arr : [], this.state.tagValues) }}
																					value={this.state.tag_value && this.state.tag_value.map(acc => ({
																						value: acc
																					}))}
																				/>
																			</div>
																			<span className={`far fa-plus cursorPointer f18 align-self-center ml-4`} onClick={() => this.addNewTag("tags")}></span>
																		</React.Fragment>
																	}
																</div>
															</div>
														</div>
														{this.state.tagsSelected && this.state.tagsSelected.length ? 
															this.state.tagsSelected.map((tag, index) => {
																return(
																	<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">{tag.key +" : "+tag.value}
																		<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeTagSection(tag) } ></i>
																	</span>
																)
															})
														: null}
														<div className="d-flex mt-3">
															<span className={`text-info mr-2 align-self-center cursorPointer`} onClick={() => this.setState({ tagsSelected: [], selectedTags: [] })}>Clear</span>
															<button className={`btn btn-sm btn-light cursorPointer`} onClick={() => this.setState({ showTagFilter: false, selectedTags: this.state.tagsSelected })}>Select</button>
														</div>
													</div>
												</div>
											: null}
										</div>
										<div className="align-self-center">
											<div className="d-flex align-self-center">
												<div className="btn-group" role="group" aria-label="governance-dash">
													<button type="button" className={`cursorPointer btn btn-secondary border-right`} onClick={()=> this.onSearch()}>Search</button>
													<button type="button" className={`cursorPointer btn btn-secondary border-left`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="d-flex justify-content-between mt-3 py-2">
								<p className="mb-0 align-self-center">Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.state.assetsList && this.state.assetsList.length} Alarm(s)</p>
								{this.state.assetsList && this.state.assetsList.length ?
									<div className="d-flex">
										<CSVLink 
											data={this.state.filteredArray ? this.state.filteredArray : []} 
											headers={this.state.headers ? this.state.headers : []}
											filename={"resource-alarms.csv"}
											className={"mt-2"}
											target="_blank"
										>
											<span className="d-flex text-primary-color mr-2">
												<i className="fas fa-download text-primary-color mr-2"></i>
												{/* csv */}
											</span>
										</CSVLink>
										<div className={`mb-n2`}>
											<Search
												data={this.state.assetsList ? this.state.assetsList : []}
												filteredData={filteredArray => {
													this.setState({ filteredArray })
												}}
											/>
										</div>
									</div>
								: null}
							</div>
							<div className='tab-content' id='myTabContent'>
								<div className='tab-pane fade show active' id='home' role='tabpanel' aria-labelledby='home-tab'>
									{this.state.assetsList && this.state.assetsList.length && !this.state.showLoading ?
										<ResizeableDarkThemeTable
											columns={[
												{
													Header: "Name",
													accessor: "asset_name",
													width: 300
												},
												{
													Header: "Arn",
													accessor: "asset_arn",
													width: 200
												},
												// {
												// 	Header: "Resources",
												// 	Cell: cellInfo => (
												// 		(cellInfo.row.original.provider ? cellInfo.row.original.provider.toUpperCase() : '') + ': ' + getAccountNameFromId(cellInfo.row.original.account_id, this.state.accounts) + ': ' + (cellInfo.row.original.region ? (cellInfo.row.original.region === 'NA' ? 'Global' : getRegionName(cellInfo.row.original.region, this.state.regions)) : '')
												// 	),
												// 	width: 200
												// },
												{
													Header: "Current Memory",
													accessor: d => d.memory_size,
													Cell: cellInfo => (
														cellInfo.row.original.memory_size ?
															<div className="d-flex mr-2">
																<p className="f12 m-0 text-white align-self-center">
																{cellInfo.row.original.memory_size}</p>
																{cellInfo.row.original.memory_unit ?
																	<p className="f12 m-0 text-primary-color align-self-center">({cellInfo.row.original.memory_unit})</p>
																: null}
															</div>
														: null
													),
													width: 200
												},
												// {
												// 	Header: "Service",
												// 	accessor: d => d.resource_type+" : "+d.service_name,
												// 	width: 200
												// },
												{
													
													Header: "Power Tuning Details",
													Cell: cellInfo => (
														<React.Fragment>
														<div className="d-flex" onClick={() => this.onClickPowerTuningDetails(cellInfo.row.original)}>
															{cellInfo.row.original.power_tuning_details && cellInfo.row.original.power_tuning_details.last_run_time ?
																<div className="d-flex mr-2">
																	<div className={`f12 align-self-center`}>Last Ran</div>
																	<p className="f12 m-0 text-white align-self-center"><span className="mx-1">:</span>
																	{momentConvertionUtcToLocalTime(cellInfo.row.original.power_tuning_details.last_run_time, "DD MMM YYYY HH:mm")}</p>
																</div>
															: null}
															{cellInfo.row.original.power_tuning_details && cellInfo.row.original.power_tuning_details.last_run_recommended_features && cellInfo.row.original.power_tuning_details.last_run_recommended_features.memory ?
																<div className="d-flex mr-2">
																	<div className={`f12 align-self-center`}>Recommended Memory</div>
																	<p className="f12 m-0 text-white align-self-center"><span className="mx-1">:</span>
																	{cellInfo.row.original.power_tuning_details.last_run_recommended_features.memory}</p>
																	{cellInfo.row.original.power_tuning_details.last_run_recommended_features.memory_unit ?
																		<p className="f12 m-0 text-primary-color align-self-center">({cellInfo.row.original.power_tuning_details.last_run_recommended_features.memory_unit})</p>
																	: null}
																</div>
															: null}
															{cellInfo.row.original.power_tuning_details && cellInfo.row.original.power_tuning_details.last_run_status ?
																<span className="mr-2">
																	<span className={`badge status-badge badge-outline-${cellInfo.row.original.power_tuning_details.last_run_status.toLowerCase()}`}>{cellInfo.row.original.power_tuning_details.last_run_status}</span>
																</span>
															: null}

															{cellInfo.row.original.power_tuning_details && cellInfo.row.original.power_tuning_details.status ?
																<React.Fragment>
																<div className="d-flex mr-2">
																	<div className={`f12 badge-square-sm badge-success align-self-center`}>C</div>
																	<p className="f12 m-0 text-white align-self-center"><span className="mx-1">:</span>{cellInfo.row.original.power_tuning_details.status.Completed && cellInfo.row.original.power_tuning_details.status.Completed}</p>
																</div>

																<div className="d-flex mr-2">
																	<div className={`f12 badge-square-sm badge-warning align-self-center`}>I</div>
																	<p className="f12 m-0 text-white align-self-center"><span className="mx-1">:</span>{cellInfo.row.original.power_tuning_details.status.InProgress && cellInfo.row.original.power_tuning_details.status.InProgress}</p>
																</div>

																<div className="d-flex">
																	<div className={`f12 badge-square-sm badge-danger align-self-center`}>F</div>
																	<p className="f12 m-0 text-white align-self-center"><span className="mx-1">:</span>{cellInfo.row.original.power_tuning_details.status.Failed && cellInfo.row.original.power_tuning_details.status.Failed}</p>
																</div>
																</React.Fragment>
															: null}
														</div>
														</React.Fragment>
													),
													width: 600
												},
												{
													Header: "Status",
													Cell: cellInfo => (
														cellInfo.row.original.status ?
														    <span className={`badge badge-outline-border-${cellInfo.row.original.status.toLowerCase()}`}>{cellInfo.row.original.status}</span>
														: 
														    <span className={`badge badge-outline-secondary`}>No Status</span>
													),
													width: 100
												},
												{
													Header: "Actions",
													Cell: cellInfo => (
														<div className="d-flex justify-content-between align-self-center">
															<p className="m-0 p-0 mr-1 cursorPointer" onClick={() => this.onClickPrice(cellInfo.row.original)}><i className="fas fa-pencil"></i></p>
															{/* <p className="m-0 p-0 mx-1 cursorPointer" onClick={() => this.setState({ queryLoading: true }, () => this.onClik(cellInfo.row.original.policy_name))}><i className="fal fa-book-open"></i></p> */}
														</div>
													),
													width: 100
												},
												{
													Header: "Tags",
													Cell: cellInfo => (
														<div className="d-flex">
															{cellInfo.row.original.tags && cellInfo.row.original.tags.length ? <span className={`badge badge-outline-gray4`}>{cellInfo.row.original.tags[0].key+" : "+cellInfo.row.original.tags[0].value}</span> : "No Tags"}
															{cellInfo.row.original.tags && cellInfo.row.original.tags.length > 1 ?
																<span className={`badge status-badge badge-outline-info ml-1 cursorPointer`} onClick={() => this.onClickPrice(cellInfo.row.original)}>more</span>
															: null}
														</div>
													),
													width: 450
												}
											]}
											data={this.state.filteredArray ? this.state.filteredArray : this.state.assetsList}
											perPage={20}
											dashboard={(this.props.filteredArray ? this.props.filteredArray.length : (this.state.assetsList && this.state.assetsList.length)) ? true : false}
											sortByColumn={"Date"}
											riskTooltip={[0]}
											onClickRow={tableRow => {}}
											// tableSize={"table-sm"}
											// tableHead={"bg-dark"}
											tooltipFor={[1,2]}
										/>
									: (!this.state.showLoading && !this.state.assetsList) ||
										(this.state.assetsList && !this.state.assetsList.length && !this.state.showLoading) ? 
										<div className="d-flex justify-content-center m-4">
											<p>There are no data on this criteria. Please try adjusting your filter.</p>
										</div>
									: null}
									{this.state.showLoading ? (
										<div className="d-flex justify-content-center m-4">
											<Spinner className="text-center" color="white" size="lg" />
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ManagePowerTuning.propTypes = {
	listAllProviders: PropTypes.func,
	listAllAccounts: PropTypes.func,
	listAllRegions: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log("-",state)
	return {
		providers: state.filters.providers,
		services: state.filters.services,
		tagKeys: state.filters.tagKeys,
	}
}

export default AppWrapper(ManagePowerTuning, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
	// listAssets,
	getAllTagsKeys,
	getAllTagsValues,

	getPowerTuningAssets,
	setCostPropsDetails,
})
