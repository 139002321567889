/*************************************************
 * Tvastar
 * @exports
 * @file SelectedSreMetrics.js
 * @author Prakash // on 01/09/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { momentDateGivenFormat, addHours, momentConvertionUtcToLocalTime, getConvertedBytesUnit, convertBytes, convertBytesTo, convertBytesWithoutUnit, convertSeconds, convertSecondsWithoutUnit, countFormater, countFormaterWithoutUnit, convertBits, convertBitsWithoutUnit, subDays, getDayFromSelectedDuration, subHours } from '../../utils/utility'
import Chart from 'react-apexcharts'
import { getSreDetails } from '../../actions/aiops/DiagnosticsAction'

import { setDiagnosticsPropsDetails } from '../../actions/aiops/DiagnosticsAction'
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

class SelectedSreMetrics extends Component {
    constructor(props) {
        super(props)
        this.annotationTypeRef = React.createRef()
        this.state = {
            showLoading: true,
            showAnnotation: true,
        }
    }
    
    componentDidMount = () => {

        let startTime = ""
        let endTime = this.props.diagnostics.diagnostic_end_time ? this.props.diagnostics.diagnostic_end_time : this.props.diagnostics.diagnostic_start_time

        let duration = this.props.diagnosticsDetails.duration
        if(duration && duration !== "custom") {
            let period = duration.substring(duration.length, duration.length-1)
            let durationBtw = getDayFromSelectedDuration(duration)
            if(period === "d") {
                startTime = momentDateGivenFormat(subDays(this.props.diagnostics.diagnostic_start_time, durationBtw), "YYYY-MM-DD HH:mm:ss")
            } else {
                startTime = momentDateGivenFormat(subHours(this.props.diagnostics.diagnostic_start_time, durationBtw), "YYYY-MM-DD HH:mm:ss")
            }

        } else {
            startTime = this.props.diagnosticsDetails.start_time                
        }        

        this.setState({ metricsStartTime: startTime, metricsEndTime: endTime },
            () => this.onLoad()    
        )
        
    }

    onLoad = () => {
        if(Object.entries(this.props.diagnosticSelectedSreMertrics).length) {
            if(this.props.diagnosticSelectedSreMertrics.hasOwnProperty(this.props.assetDetails.asset_id)) {
                this.setState({ sreMetricsDetails: this.props.diagnosticSelectedSreMertrics[this.props.assetDetails.asset_id], showLoading: false },
                    () => {
                        this.formSreMetricsLatencyGraph()
                    }
                )
            } else {
                this.getSreDetails()
            }
        } else {
            this.getSreDetails()
        }
    }

    getSreDetails = () => {
        if(this.props.assetDetails.asset_id) {
            let params = {}
            params.asset_id = this.props.assetDetails.asset_id
            params.start_time = this.state.metricsStartTime
            params.end_time = this.state.metricsEndTime
            params.metric_name = this.props.selectedDiagnosticEvent.data && this.props.selectedDiagnosticEvent.data.event_type ? [this.props.selectedDiagnosticEvent.data.event_type] : []
            params.sre_metrics = this.props.selectedDiagnosticEvent.eventGroup ? [this.props.selectedDiagnosticEvent.eventGroup.toLowerCase()] : []
            if(params.sre_metrics.length) {
                this.props.getSreDetails(params, (promise, response) => {
                    if(promise) {
                        this.setState({ 
                            sreMetricsDetails: response,
                            showLoading: false
                        },
                            () => {
                                this.formSreMetricsLatencyGraph()
                                let obj = this.props.diagnosticSelectedSreMertrics
                                obj[this.props.assetDetails.asset_id] = response
                                this.props.setDiagnosticsPropsDetails('diagnosticSelectedSreMertrics', obj)
                            }
                        )
                    } else {
                        this.setState({ sreMetricsDetails: [], showLoading: false })                    
                    }
                })
            }
        } else {
            this.setState({ showLoading: false })
        }
    }

    formSreMetricsLatencyGraph = () => {
        let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']
        if(this.state.sreMetricsDetails && this.state.sreMetricsDetails.length) {
            let filteredSreDetails = this.state.sreMetricsDetails
            filteredSreDetails.forEach((res, index) => {
                let graphData = res.data
                let graphLabels = res.dates
                
                let yDetails = []
                let series =[]
                let colorInc = 0
                graphData.latency &&  Object.entries(graphData.latency).forEach(([key, item], j) => {
                    let yRow = {}
                    let maxValue = item.statistics && item.statistics.max ? item.statistics.max : 1
                    let avgValue = item.statistics && item.statistics.avg ? item.statistics.avg : 1
                    let minValue = item.statistics && item.statistics.min ? item.statistics.min : 1
                    
                    
                    let graphConvertTo = ""

                    let maxRandomNumber = maxValue
                    let minRandomNumber = minValue
                    if(item.unit) {
                        graphConvertTo = this.getConvertedUnit(maxValue, item.unit)
                        maxRandomNumber = this.getGrapUnitValueWithoutUnit(maxValue, item.unit, "round")
                        minRandomNumber = this.getGrapUnitValueWithoutUnit(minValue, item.unit, "round")
                    }

                    if(item.values && item.values.length) {
                        if(!colorInc) {
                            yRow = {
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                    color: '#60687C'
                                },
                                tooltip: {
                                    enabled: false
                                },
                                tickAmount: 2,
                                min: 0,
                                labels: {
                                    offsetX: -10,
                                    show: true,
                                    style: {
                                        fontSize: '9px',
                                        colors: '#60687C'
                                    },
                                    formatter: (value) => { 
                                        return value.toFixed(2)
                                    },
                                }
                            }
                        } else {
                            yRow = {
                                show: false
                            }
                        }
                        yDetails.push(yRow)
                        
                        let dataRow = {}
                        dataRow.name = key
                        dataRow.type = 'line'
                        let plotValue = []
                        graphLabels.forEach((lab, i) => {
                            let dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            let val = item.values[i]
                            if(item.unit && item.unit !== '') {
                                val = this.getGrapUnitValue(val, item.unit, "", graphConvertTo)
                            }
                            dataArray.push(val)
                            plotValue.push(dataArray)
                        })
                        dataRow.data = plotValue
                        series.push(dataRow)

                        colorInc++;
                    }
                })

                let xaxisAnnotation = []
                if(this.state.showAnnotation) {
                    // xaxisAnnotation = [{
                    //     x: new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                    //     strokeDashArray: 0,
                    //     borderColor: '#FF6F00',
                    //     label: {
                    //         borderColor: '#FF6F00',
                    //         style: {
                    //             // fontSize: '12px',
                    //             color: '#fff',
                    //             background: '#FF6F00'
                    //         },
                    //         orientation: 'horizontal',
                    //         text: '',
                    //     }
                    // }
                    // ]
                }
                    
                if(this.state.selectedAnnotationTypes && this.state.selectedAnnotationTypes.length) {
                    let borderColor = ''
                    let background = ''
                    let color = ''
                    let text = ''
                    let location = ''
                    this.state.selectedAnnotationTypes.forEach(item => {
                        text = item.value
                        if(item.value === 'C' || item.value === 'E') {
                            borderColor = '#24A597'
                            background = '#24A597'
                            color = '#fff'
                            location = new Date(momentConvertionUtcToLocalTime(graphLabels[180], 'YYYY-MM-DD HH:mm:ss')).getTime()

                            let dataRow = {
                                x: location,
                                strokeDashArray: 0,
                                borderColor: borderColor,
                                opacity: 1,
                                label: {
                                    borderColor: borderColor,
                                    orientation: 'horizontal',
                                    text: text,
                                    style: {
                                        color: color,
                                        background: background,
                                        fontSize: '10px',
                                        // width: '10px',
                                    },
                                }
                            }
                            xaxisAnnotation.push(dataRow)
                        }
                    })
                }
                
                let annotations = {
                    xaxis: xaxisAnnotation
                }

                let options = {
                    chart: {
                        // group: 'metrics_sre',
                        type: 'line',
                        // id: 'chart_latency_'+index,
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false
                        },                        
                        sparkline: {
                            enabled: false
                        },
                    },
                    colors: colors,
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        width: [2, 3, 4],
                        curve: 'smooth',
                        // dashArray: [0, 8, 5]
                    },
                    xaxis: {
                        // categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
                        type: 'datetime',
                        // categories: labels,
                        labels: {
                            datetimeUTC: false,
                            show: this.props.xaxis,
                            style: {
                                fontSize: '9px',
                                colors: this.props.axisLabelColor
                            },
                        }
                    },
                    yaxis: yDetails,
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            show: false,
                        },
                        
                        x: {
                            show: false
                        },
                        marker: {
                            show: false
                        },
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                                // let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]
                                // let labelName = w.globals.initialSeries[seriesIndex].name
                                // // return '<div className="arrow_box"> '+ capitalizeFirstLetter(labelName) +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' + '  '+ val + '</span> </div>'         
                                let returnData = ''
                                
                                if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                    Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                        if(!index) {
                                            returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                        }
                                        returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'

                                    })
                                }

                                returnData += '</div>'
                                return returnData
                        }
                    },                    
                    grid: {
                        show: true,
                        borderColor: '#2D323E',
                        strokeDashArray: 0,
                        position: 'back',
                        xaxis: {
                            lines: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: ['#FFF'],
                                }
                            },
                            axisTicks: {
                                show: false
                            }
                        },   
                        yaxis: {
                            lines: {
                                show: false
                            },
                            labels: {
                                style: {
                                    colors: ['#969AA7'],
                                }
                            }
                        },                
                        padding: {
                            top: 0,
                            right: 20,
                            bottom: 0,
                            left: 0
                        },
                    },
                    legend: {
                        show: false
                        // horizontalAlign: 'left',
                        // offsetX: 40
                    },
                    annotations: annotations
                }

                this.setState({ ['metrics_series_'+index]: series, ['metrics_options_'+index]: options })
            })
        }
    }
	
	handleClickOutside(event) {}

    getConvertedUnit = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = getConvertedBytesUnit(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                // val = getConvertedSecondsUnit(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                // val = getConvertedBitsUnit(val, unit)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                // val = getConvertedCountFormater(val)
            } else {
                val = ""
            }
        } else if(val !== '') {           
            val = ""
        }
        return val
    }

    getGrapUnitValue = (val ,unit, valueType, convertTo) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseInt(val))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                if(convertTo) {
                    val = convertBytesTo(val, unit, valueType, convertTo)
                } else {
                    val = convertBytes(val, unit, valueType)
                }                         
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit, valueType, convertTo)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit, valueType, convertTo)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    getGrapUnitValueWithoutUnit = (val ,unit, valueType) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytesWithoutUnit(val, unit, valueType)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSecondsWithoutUnit(val, unit, valueType)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBitsWithoutUnit(val, unit, valueType)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                val = countFormaterWithoutUnit(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseInt(val))
        }
        return val
    }

    render() {
        return (
            !this.state.showLoading ?
                <div class={`bg-dark rounded p-3`} onClick={ (event) => { this.handleClickOutside(event) } }>
                    <div class="d-flex justify-content-between">
                        <div>
                            <p class="f16 text-white mb-1">{this.props.selectedDiagnosticEvent.data && this.props.selectedDiagnosticEvent.data.event_type ? this.props.selectedDiagnosticEvent.data.event_type : ""} Metrics</p>
                            {this.state.metricsByTrend && this.state.metricsByTrend.length ?
                                <div className="d-flex">
                                    <p class="f12 text-primary-color mb-0">Key Metrics for {this.props.assetDetails.resource_type ? this.props.assetDetails.resource_type : ''}</p>
                                    <p class="f12 text-primary-color mb-0 ml-2">{this.props.assetDetails.asset_name ? this.props.assetDetails.asset_name : ''}</p>
                                </div>
                            : null}
                        </div>
                        <div className="d-flex">
                            <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{"("+momentConvertionUtcToLocalTime(this.state.metricsStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentConvertionUtcToLocalTime(this.state.metricsEndTime, 'DD MMM YYYY HH:mm')+")"}</p>
                        </div>
                    </div>
                    <div class="container-fluid mt-2">                    
                        {this.state.sreMetricsDetails && this.state.sreMetricsDetails.length ?
                            this.state.sreMetricsDetails.map((item, index) => {
                                return(
                                Object.keys(item.data).map(key => {
                                    return (
                                        <React.Fragment>
                                        {/* <p class="text-white f16 p-1 mb-0">{key}</p> */}
                                        {this.state['metrics_series_'+index] && Object.keys(this.state['metrics_options_'+index]).length && this.state[key+'_'+item.asset_id] && this.state[key+'_'+item.asset_id].length ?
                                            <div className="cursorPointer transparentTooltip mb-n4 mt-n4">
                                                <Chart options={this.state['metrics_options_'+index]} series={this.state['metrics_series_'+index]} type="area" height={this.props.height ? this.props.height : 115} width={'100%'}/>
                                            </div>
                                        : null}
                                        </React.Fragment>
                                    )
                                })
                                )
                            })
                        : 
                            <div className='d-flex justify-content-center m-4'>
                                No Metrics for the this criteria, Please adjust your filter to get the metric details.
                            </div>
                        }
                    </div>
                </div>
            : 
                <div className={`bg-dark rounded p-3`}>
                    <p className={`mb-2 text-white`}><b>Metrics</b></p>
                    <div className='d-flex justify-content-center m-4'>
                        <Spinner className='text-center' color='white' size='lg' />
                    </div>
                </div>
        )
    }
}

/**
* Type of the props used in the component
*/
SelectedSreMetrics.propTypes = {
    getSreDetails: PropTypes.func,
    setDiagnosticsPropsDetails: PropTypes.func
}   

const mapStateToProps = state => {
    return {

        diagnosticSelectedSreMertrics: state.aiops.diagnosticsPropsDetails && state.aiops.diagnosticsPropsDetails.diagnosticSelectedSreMertrics ? state.aiops.diagnosticsPropsDetails.diagnosticSelectedSreMertrics : {},
        
        selectedDiagnosticEvent: state.aiops.diagnosticsPropsDetails && state.aiops.diagnosticsPropsDetails.selectedDiagnosticEvent ? state.aiops.diagnosticsPropsDetails.selectedDiagnosticEvent : {},

        diagnosticsDetails: state.aiops.aiopsDiagnosticsDetails && state.aiops.aiopsDiagnosticsDetails.result ? state.aiops.aiopsDiagnosticsDetails.result : [],
    }
}

export default connect(mapStateToProps, {
    getSreDetails,
    setDiagnosticsPropsDetails
})(withRouter(SelectedSreMetrics))