/*************************************************
 * Tvastar
 * @exports
 * @file Redaction.js
 * @author Prakash // on 13/09/2021
 * @copyright © 2020 Tvastar. All rights reserved.
*************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { onlyNumeric } from '../../../../utils/utility'
import Select from 'react-select'

class Redaction extends Component {
	hierarichalBucketBarChartRef = React.createRef()
	constructor(props) {
		super(props)
		this.props = props;
		this.state = {
			showLoading: false,
			
            value: 'X',
			n_chars: 5,
			periodList: ['Start', 'End'],
			selectedPeriod: 'Start',
			yesNoOptions: ['Yes', 'No'],
			watermark: 'No',
			watermarkOption: [{id: '123', type: 'Customer1 - Department1'}, {id: '234', type:'Customer1 - Department2'}]
		}
	}

	componentDidMount = () => {
        this.editEvent()
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.dlpPropsDetails && typeof this.props.dlpPropsDetails.hasPrivacyPolicyError !== 'undefined' && (prevProps.dlpPropsDetails.hasPrivacyPolicyError !== this.props.dlpPropsDetails.hasPrivacyPolicyError || (!this.state.hasError && (this.state.hasError !== this.props.dlpPropsDetails.hasPrivacyPolicyError)))) {
            this.setState({ hasError: this.props.dlpPropsDetails.hasPrivacyPolicyError })
        }
    }

    editEvent = () => {
        if(this.props.pageType === 'edit' || this.props.pageType === 'view') {
			let policyDetails = this.props.policyDetails
            let deidentification_type = policyDetails.config.deidentification_type
            let n_chars = policyDetails.config.n_chars ? policyDetails.config.n_chars : 5
            let regex = policyDetails.config.regex ? policyDetails.config.regex : ''
            let value = policyDetails.config.value ? policyDetails.config.value : 'X'
            let watermark_id = policyDetails.config.watermark_id ? policyDetails.config.watermark_id : ''            
            let watermark_name = policyDetails.config.watermark_name ? policyDetails.config.watermark_name : ''
            let watermark_type = policyDetails.config.watermark_type ? policyDetails.config.watermark_type : ''
            let selectedPeriod = policyDetails.config.from_end ? 'End' : 'Start'

            this.setState({ deidentification_type, updated_deidentification_type: deidentification_type, n_chars, regex, value, watermark: watermark_id !== '' ? 'Yes' : 'No', watermark_id, watermark_name, watermark_type, selectedPeriod }, () => this.onChangePropsEvent())
        }
    }

    onChangePropsEvent = () => {
		let errorMessage = ''
		if(!this.state.deidentification_type || this.state.deidentification_type === '') {
			errorMessage = 'Please select react using'
		} else if(!this.state.regex || this.state.regex === '') {
            if(!this.state.value || this.state.value === '') {
                errorMessage = 'Please enter value'
            }
		} else if(this.state.watermark === 'Yes') {
            if(!this.state.watermark_id) {
                errorMessage = 'Please select watermark'
            }
            if(!this.state.watermark_type) {
                errorMessage = 'Please select watermark type'
            }
        }
        


        let data = {}
        data.deidentification_type = this.state.deidentification_type ? this.state.deidentification_type : ''
        
        let selectedText = 'Redact using '+this.state.deidentification_label
        if(this.state.deidentification_type === 'mask') {
            if(this.state.regex && this.state.regex !== '') {
                data.regex = this.state.regex ? this.state.regex : ''                
            } else {
                data.value = this.state.value ? this.state.value : ''
                data.n_chars = this.state.n_chars
                if(this.state.selectedPeriod === 'Start') {
                    data.from_end = false
                } else if(this.state.selectedPeriod === 'End') {
                    data.from_end = true
                }

                if(this.state.value === '' ) {
                    errorMessage = 'Please enter value'
                }

                if(this.state.n_chars === '' ) {
                    errorMessage = 'Please enter no of characters'
                }

                if(!this.state.selectedPeriod) {
                    errorMessage = 'Please select period'
                }
            }   

            if(data.regex === '') {
                selectedText += ' value '+data.value +' for no of chars '+data.n_chars+' from the '+this.state.selectedPeriod
            } else {
                selectedText += ' value '+data.value +' using regex '+data.regex
            }
        }

        if(this.props.watermarkTypeArray[this.state.deidentification_type] && this.props.watermarkTypeArray[this.state.deidentification_type].length) {
            
            if(this.state.watermark === 'Yes') {
                data.watermark_type = this.state.watermark_type
                let filter = this.props.watermarkTypeArray[this.state.deidentification_type].filter(arr => arr.watermark_type === this.state.watermark_type)
                if(filter.length) {
                    Object.entries(filter[0]).forEach(([key, value]) => {
                        data[key] = value
                    })
                }
                
                data.watermark_id = this.state.watermark_id
                data.watermark_name = this.state.watermark_name
                selectedText += ' watermark type '+this.state.watermark_type
                selectedText += ' with watermark '+this.state.watermark_name

                if(!data.watermark_type || data.watermark_type === '') {
                    errorMessage = 'Please select watermark type'
                } else if(!data.watermark_name || data.watermark_name === '') {
                    errorMessage = 'Please select watermark'
                }

            }
        }

        this.props.selectedData(data, selectedText, errorMessage)
    }

    getSelectLabel = (item, list) => {
        let filter = list.filter(arr => arr.value === item)
        if(filter.length) {
            this.setState({ deidentification_label: filter[0].label })
        } else {
            this.setState({ deidentification_label: '', deidentification_type: '' })
        }
    }

    onChangeType = () => {
        if(this.state.updated_deidentification_type && this.state.deidentification_type === this.state.updated_deidentification_type) {
            this.editEvent()
        } else {
            this.onChangePropsEvent()
        }
    }

    render() {		
		return (
            <div className={`form-group dlpDescriptionForm mt-3`}>
                <div className="d-flex flex-wrap">
                    <p className="mb-0 mr-2 align-self-end text-purple mt-3">Type</p>
                    <Select
                        // menuIsOpen={true}
                        placeholder={'Select'}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        isSearchable={false}
                        className={`selectOption align-self-end mx-2 ${this.state.hasError && (!this.state.deidentification_type || this.state.deidentification_type === '') ? 'dottedDangerSelectLine' : ''}`}
                        value={({
                            value: this.state.deidentification_type ? this.state.deidentification_type : 'Select',
                            label: this.state.deidentification_type ? (this.state.deidentification_label ? this.state.deidentification_label : this.getSelectLabel(this.state.deidentification_type, this.props.list)) : 'Select'
                        })}
                        options={this.props.list && this.props.list.map(item => ({
                            value: item.value,
                            label: item.label,
                        }))}
                        onChange={event => 
                            this.setState({ deidentification_type: event.value, deidentification_label: event.label, watermark_name: '', watermark_type: '', watermark_id: '' }, 
                                () => this.onChangeType()
                            )
                        }
                    />
                    {this.state.deidentification_type === 'mask' ?
                        <React.Fragment>
                            <p className="mb-0 mx-2 align-self-end text-purple mt-3">with value</p>
                            <input 
                                type="text" 
                                className={`inputField mt-3 ${this.state.hasError && (!this.state.regex || this.state.regex === '') && (!this.state.value || this.state.value === '') ? 'dottedDangerTextboxLine' : ''}`}
                                value={this.state.value ? this.state.value : ''}
                                onChange={e => this.setState({ value: e.target.value }, () => this.onChangePropsEvent())}
                            />
                            <p className={`mb-0 mx-2 align-self-end ${this.state.regex && this.state.regex !== '' ? 'text-muted': 'text-purple'} mt-3`}>for no. of chars</p>
                            <input 
                                type="number"
                                min={1}
                                className={`inputField mt-3 w-10 ${this.state.hasError && (!this.state.regex || this.state.regex === '') && (!this.state.n_chars || this.state.n_chars === '') ? 'dottedDangerTextboxLine' : ''}`}
                                value={this.state.n_chars ? this.state.n_chars : ''}
                                onChange={e => this.setState({ n_chars: onlyNumeric(e.target.value) }, () => this.onChangePropsEvent())}
                            />												
                            <p className={`mb-0 mx-2 align-self-end ${this.state.regex && this.state.regex !== '' ? 'text-muted': 'text-purple'} mt-3`}>from the</p>
                            <Select
                                // menuIsOpen={true}
                                placeholder={'Select'}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                isSearchable={false}
                                className={`selectOption mr-2 mt-3 ${this.state.hasError && (!this.state.selectedPeriod || this.state.selectedPeriod === '') ? 'dottedDangerSelectLine' : ''}`}
                                value={({
                                    value: this.state.selectedPeriod ? this.state.selectedPeriod : 'Select',
                                    label: this.state.selectedPeriod ? this.state.selectedPeriod : 'Select',
                                })}
                                options={this.state.periodList && this.state.periodList.map(item => ({
                                    value: item,
                                    label: item,
                                }))}
                                onChange={event =>  
                                    this.setState({ selectedPeriod: event.value }, () => this.onChangePropsEvent())
                                }
                            />
                            <p className={`mb-0 mx-2 align-self-end ${this.state.regex && this.state.regex !== '' ? 'text-purple' : 'text-muted'} mt-3`}> (or) using regex</p>
                            <input 
                                type="text" 
                                className={`inputField mt-3 ${this.state.hasError && this.state.value === '' && (!this.state.regex || this.state.regex === '') ? 'dottedDangerTextboxLine' : ''}`}
                                value={this.state.regex ? this.state.regex : ''}
                                onChange={e => this.setState({ regex: e.target.value }, () => this.onChangePropsEvent())}
                            />
                        </React.Fragment>
                    : null}
                </div>
                
                {this.props.watermarkTypeArray && this.props.watermarkTypeArray[this.state.deidentification_type] && this.props.watermarkTypeArray[this.state.deidentification_type].length ?
                    <div className="d-flex flex-wrap">
                        <p className="mb-0 mr-2 align-self-end text-purple mt-3">Add watermark</p>
                        <Select
                            // menuIsOpen={true}
                            placeholder={'Select'}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            isSearchable={false}
                            className={`selectOption mr-2 mt-3 w-15`}
                            value={({
                                value: this.state.watermark ? this.state.watermark : 'Select',
                                label: this.state.watermark ? this.state.watermark : 'Select',
                            })}
                            options={this.state.yesNoOptions && this.state.yesNoOptions.map(item => ({
                                value: item,
                                label: item,
                            }))}
                            onChange={event =>  
                                this.setState({ watermark: event.value }, () => this.onChangePropsEvent())
                            }
                        />
                        
                        {this.state.watermark === 'Yes' ?
                            <React.Fragment>
                                <p className="mb-0 mr-2 align-self-end text-purple mt-3">watermark Type</p>
                                <Select
                                    // menuIsOpen={true}
                                    placeholder={'Select'}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    isSearchable={false}
                                    className={`selectOption mr-2 mt-3 ${this.state.hasError && (!this.state.watermark_type || this.state.watermark_type === '') ? 'dottedDangerSelectLine' : ''}`}
                                    value={({
                                        value: this.state.watermark_type ? this.state.watermark_type : 'Select',
                                        label: this.state.watermark_type ? this.state.watermark_type : 'Select',
                                    })}
                                    options={this.props.watermarkTypeArray[this.state.deidentification_type] && this.props.watermarkTypeArray[this.state.deidentification_type].map(item => ({
                                        value: item.watermark_type,
                                        label: item.watermark_type,
                                    }))}
                                    onChange={event =>  
                                        this.setState({ watermark_type: event.value }, () => this.onChangePropsEvent())
                                    }
                                />
                                <p className="mb-0 mr-2 align-self-end text-purple mt-3">with watermark</p>
                                <Select
                                    // menuIsOpen={true}
                                    placeholder={'Select'}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    isSearchable={false}
                                    className={`selectOption mr-2 mt-3 w-30 ${this.state.hasError && (!this.state.watermark_name || this.state.watermark_name === '') ? 'dottedDangerSelectLine' : ''}`}
                                    value={({
                                        value: this.state.watermark_id ? this.state.watermark_id : 'Select',
                                        label: this.state.watermark_name ? this.state.watermark_name : 'Select',
                                    })}
                                    options={this.props.watermarks && this.props.watermarks.map(item => ({
                                        value: item.watermark_id,
                                        label: item.watermark_name,
                                    }))}
                                    onChange={event =>  
                                        this.setState({ watermark_id: event.value, watermark_name: event.label }, () => this.onChangePropsEvent())
                                    }
                                />
                            </React.Fragment>
                        : null}
                    </div>
                : null}
            </div>
        )
    }
}

const mapStateToProps = state => {
	return {
        dlpPropsDetails: state.dlp.dlpPropsDetails,
        watermarks: state.dlp.dlpConfigWatermarklist
	}
}

export default connect(mapStateToProps, {})(withRouter(Redaction))