/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from 'react'
import { AppWrapper } from '../../common/AppWrapper'
import PropTypes from 'prop-types'
import {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
	listAllServices,
	showNotification,
} from '../../../actions/commonAction'
import {
	setGovernanceFilter,
	getAssetsByTagOperations,
	getNonComplaintAssets,
	getTagComplianceAggregationCount,
	getTagComplianceTotalCount,
	getTagsByOperationCount,
	getAppliedTagsDayWiseCount,
	getAllTags,
} from '../../../actions/governance/governanceAction'
import { setSelectedFilter } from '../../../actions/assets/assetsAction'
import ManageTagGroup from './ManageTagGroup'
import ManageCondition from './ManageCondition'
import ApplyTags from './ApplyTags'
import { URL_PATH } from '../../../config/urlPath'
import { applyTag, setToInitial } from '../../../actions/governance/taggingAction'
import { Spinner } from 'reactstrap'
import { SUCCESS, ERROR } from '../../../utils/constants'

class EditTagGroups extends Component {
	constructor(props) {
		super(props)
		this.state = {
			activeTab: 1,
			showApplyLoading: false,
			appliedIndex: -1,
		}
	}
	handleOnFinish = () => {
		this.props.setToInitial()
		this.props.history.push(URL_PATH.GOVERNANCE_TAG_GROUPS)
	}
	/**
	 * Method to render active tab
	 */
	tabToShow = () => {
		switch (this.state.activeTab) {
			case 1:
				return (
					<ManageTagGroup
						onClickNext={() => this.setState({ activeTab: this.state.activeTab + 1 })}
						// ruleDetails={this.props.location.state && this.props.location.state.ruleDetails}
					/>
				)
			case 2:
				return (
					<ManageCondition
						onClickNext={() => this.setState({ activeTab: this.state.activeTab + 1 })}
						onClickBack={() => this.setState({ activeTab: this.state.activeTab - 1 })}
						// ruleDetails={this.props.location.state && this.props.location.state.ruleDetails}
					/>
				)
			case 3:
				return (
					<ApplyTags
						onClickFinish={() => this.handleOnFinish()}
						onClickBack={() => this.setState({ activeTab: this.state.activeTab - 1 })}
						// ruleDetails={this.props.location.state && this.props.location.state.ruleDetails}
					/>
				)
			default:
				break
		}
	}

	render() {
		return (
			<div className='container-fluid overflow-auto flex-grow-1'>
				<div className='row h-100'>
					<div className='col-sm p-0'>
						<div className='title-section pb-2'>
							<div className='row'>
								<div className='col-12 d-flex align-self-center'>
									<h6 className='text-white'>Tag Group</h6>
								</div>
							</div>
						</div>
						<div className='container-fluid mt-3'>
							<div className='row'>
								<div className='col-12'>
									<div className='box'>
										<ul className='nav nav-tabs bd-highlight disabled' id='myTab' role='tablist'>
											<li className='nav-item' onClick={() => this.setState({ activeTab: 1 })}>
												<span className={`cursor-pointer nav-link ${this.state.activeTab === 1 ? 'active' : ''}`}>
													1. Manage Tag Group
												</span>
											</li>
											<li className='nav-item' onClick={() => this.setState({ activeTab: 2 })}>
												<span className={`cursor-pointer nav-link ${this.state.activeTab === 2 ? 'active' : ''}`}>
													2. Manage Conditions
												</span>
											</li>
											<li className='nav-item' onClick={() => this.setState({ activeTab: 3 })}>
												<span className={`cursor-pointer nav-link ${this.state.activeTab === 3 ? 'active' : ''}`}>
													3. Apply Tags
												</span>
											</li>
										</ul>
										<div className='tab-content p-3 text-primary' id='myTabContent'>
											<div className='tab-pane fade show active' role='tabpanel'>
												{this.tabToShow()}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{this.props.editTagGroupDetails.tags && this.props.editTagGroupDetails.tags.length ? (
						<div className='col-sm-3 p-0'>
							<div className='detail'>
								{this.state.activeTab === 2 ? (
									<div className='detail-title'>
										{/* <div className='d-flex justify-content-end'>
									<button className='btn btn-primary btn-sm mr-3'>Edit</button>
									<button className='btn btn-danger btn-sm'>Delete</button>
								</div> */}
										<p className='mt-2 mb-0'>
											<small className='text-white mt-2'>{this.props.editTagGroupDetails.tag_group_name}</small>
										</p>
									</div>
								) : null}
								<div className='detail-content'>
									{this.props.tagGroupConditions &&
									this.props.tagGroupConditions.length &&
									this.state.activeTab === 3 ? (
										<>
											<h6 className='m-2 pb-2 font-weight-bold'>Conditions</h6>
											<table className='table table-borderless w-100'>
												<thead>
													<tr className='d-flex justify-content-between'>
														<th className='col-sm'>ID</th>
														<th className='col-sm' />
													</tr>
												</thead>
												<tbody>
													{this.props.tagGroupConditions && this.props.tagGroupConditions.length
														? this.props.tagGroupConditions.map((item, i) => {
																return (
																	<tr key={i}>
																		<td className='p-2'>{item.condition_id}</td>
																		<td className='p-2 text-muted text-right'>
																			{item.applied ? (
																				<span className='badge badge-success'>Applied</span>
																			) : (
																				<button
																					disabled={this.state.showApplyLoading}
																					onClick={() => {
																						this.setState({ showApplyLoading: true, appliedIndex: i })
																						let params = {
																							provider: item.provider,
																							condition_id: item.condition_id,
																							mode: 'equalize',
																							tag_group_id: this.props.tagGroupId,
																							option: 'tag_group',
																							operation: 'batch',
																						}
																						this.props.applyTag(params, (success, res) => {
																							this.setState({ showApplyLoading: false })
																							if (success) {
																								this.props.showNotification(
																									SUCCESS,
																									(res && res.message) || 'Job submission requested successfully'
																								)
																							} else {
																								this.props.showNotification(
																									ERROR,
																									(res && res.message) || 'Job submission failed.'
																								)
																							}
																						})
																					}}
																					className='btn btn-sm btn-xs btn-outline-primary'
																				>
																					Apply Tag{' '}
																					{this.state.showApplyLoading && this.state.appliedIndex === i ? (
																						<Spinner size='sm' color='dark' className='ml-2' />
																					) : null}
																				</button>
																			)}
																		</td>
																	</tr>
																)
														  })
														: null}
												</tbody>
											</table>
											<hr />
										</>
									) : null}
									<h6 className='m-2 pb-2 font-weight-bold'>Tags to Apply</h6>
									{this.props.editTagGroupDetails &&
									this.props.editTagGroupDetails.tags &&
									this.props.editTagGroupDetails.tags.length
										? this.props.editTagGroupDetails.tags.map(item => {
												return (
													<>
														<p className='text-danger ml-2 mb-2'>{item.section_name}</p>
														<table className='w-100'>
															<tbody>
																{item.tags &&
																	item.tags.map((item, i) => {
																		return (
																			<tr key={i}>
																				<td className='p-2'>{item.key}</td>
																				<td className='p-2 text-muted text-right'>{item.value}</td>
																			</tr>
																		)
																	})}
															</tbody>
														</table>
													</>
												)
										  })
										: null}
								</div>
							</div>
						</div>
					) : null}
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
EditTagGroups.propTypes = {
	listAllProviders: PropTypes.func,
	listAllAccounts: PropTypes.func,
	listAllRegions: PropTypes.func,
	listAllServices: PropTypes.func,
	getAssetsByTagOperations: PropTypes.func,
	getNonComplaintAssets: PropTypes.func,
	getTagComplianceAggregationCount: PropTypes.func,
	getTagComplianceTotalCount: PropTypes.func,
	getTagsByOperationCount: PropTypes.func,
	tags: PropTypes.array,
	setSelectedFilter: PropTypes.func,
	applyTag: PropTypes.func,
	getAppliedTagsDayWiseCount: PropTypes.func,
	setToInitial: PropTypes.func,
	getAllTags: PropTypes.func,
	tagging: PropTypes.object,
	history: PropTypes.object,
	tagGroupConditions: PropTypes.array,
	editTagGroupDetails: PropTypes.object,
	tagGroupId: PropTypes.string,
	showNotification: PropTypes.func,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		providers: state.filters.providers,
		accounts: state.filters.accounts,
		services: state.filters.services,
		selectedFilters: state.governance.selectedFilters,
		tagging: state.governance.tagging,
		tags: state.governance.tags,
		editTagGroupDetails: state.governance.editTagGroupDetails,
		tagGroupId: state.governance.tagGroupId,
		tagGroupConditions: state.governance.tagGroupConditions,
	}
}

export default AppWrapper(EditTagGroups, mapStateToProps, {
	setGovernanceFilter,
	listAllProviders,
	getNonComplaintAssets,
	listAllAccounts,
	listAllServices,
	getAssetsByTagOperations,
	getTagComplianceAggregationCount,
	listAllRegions,
	getTagComplianceTotalCount,
	getTagsByOperationCount,
	setToInitial,
	setSelectedFilter,
	applyTag,
	showNotification,
	getAppliedTagsDayWiseCount,
	getAllTags,
})
