/*************************************************
 * Tvastar
 * @exports
 * @file ViewMetricDetails.js
 * @author Prakash // on 2/11/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _, { filter } from 'lodash'
// import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import Chart from 'react-apexcharts'

import { momentDateGivenFormat, momentConvertionUtcToLocalTime, getConvertedBytesUnit, convertBytes, convertBytesTo, convertBytesWithoutUnit, convertSeconds, convertSecondsWithoutUnit, countFormater, countFormaterWithoutUnit, convertBits, convertBitsWithoutUnit, subHours, addHours, momentConvertionLocalToUtcTime, currentLocaltime, currentUTCtime, subDays } from '../../../../utils/utility'
import { setResourcePropsDetails } from '../../../../actions/assets/assetsAction'
import { getMonitoringMetricsByTrend } from '../../../../actions/aiops/MetricsAction'
import MetricsTab from './MetricsTab'
import SreMetricsTab from './SreMetricsTab'
import PeakDipMetrics from './PeakDipMetrics'

class ViewMetricDetails extends Component {
	constructor(props) {
		super(props)
        this.toggleRef = React.createRef()
		this.state = {
            selectedTab: "coreMetrics",
            selectedDuration: "+1d"
		}
	}

	componentDidMount = () => {
    }

    handleClickOutside(event) {}

    // onChangeDuration = () => {
    //     let page = this.state.selectedTab
    //     this.setState({ selectedTab: "" },
    //         () => {
    //             this.setState({ selectedTab: page })       
    //         }
    //     )
    // }
	
	render() {		
		return (
			<div className="advanced-search" onClick={(event) => this.handleClickOutside(event)}>
				<div className="search-content bg-muted w-50">
					<div className="header-search bd-highlight d-flex justify-content-between">
						<div className="flex-fill bd-highlight">
							<h5>Metrics Details of <span className="text-info ml-2">{this.props.selectedAsset && this.props.selectedAsset.asset_name ? this.props.selectedAsset.asset_name : ""}</span></h5>
						</div>

						<div className="text-right flex-fill bd-highlight align-self-center">
							<i className="far fa-times cursorPointer" onClick={() => this.props.closeSidePanel()}></i>
						</div>
					</div>
					<ul className="nav nav-tabs border-bottom bd-highlight bg-dark shadow-none mb-2 w-100">
                        <li className="nav-item">
                            <span
                                className={`nav-link text-white cursor-pointer f12 ${this.state.selectedTab === "coreMetrics" ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
                                onClick={() => this.setState({ selectedTab: "coreMetrics" }, () => this.listRemediation())}
                            >
                            Core Metrics
                            </span>
                        </li>
                        <li className="nav-item">
                            <span 
                                className={`nav-link text-white cursor-pointer f12 ${this.state.selectedTab === "sreMetrics" ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
                                onClick={() => this.setState({ selectedTab: "sreMetrics" })}
                            >
                                Sre Metrics
                            </span>
                        </li>
                        <li className="nav-item">
                            <span 
                                className={`nav-link text-white cursor-pointer f12 ${this.state.selectedTab === "peakDip" ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
                                onClick={() => this.setState({ selectedTab: "peakDip" })}
                            >
                                Peak and Dip
                            </span>
                        </li>
                    </ul>
                    {/* <div className={`d-flex justify-content-end`}>
                        <span className={`mr-2 f12 align-self-center`}>Last</span>
                        <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+30d' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '+30d' }, () => this.onChangeDuration())}>1 Month</span>
                        <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+14d' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '+14d' }, () => this.onChangeDuration())}>2 Week</span>
                        <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+7d' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '+7d' }, () => this.onChangeDuration())}>1 Week</span>
                        <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === '+1d' ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedDuration: '+1d' }, () => this.onChangeDuration())}>24 Hours</span>
                    </div> */}
                    <div className="overflow-auto">
                        {this.state.selectedTab === "coreMetrics" ?
                            <MetricsTab 
                                selectedAsset={this.props.selectedAsset}
                                selectedDuration={this.state.selectedDuration}
                            />
                        : this.state.selectedTab === "sreMetrics" ?
                            <SreMetricsTab 
                                selectedAsset={this.props.selectedAsset}
                                selectedDuration={this.state.selectedDuration}
                            />
                        : this.state.selectedTab === "peakDip" ?
                            <PeakDipMetrics 
                                selectedAsset={this.props.selectedAsset}
                                selectedDuration={this.state.selectedDuration}
                            />
                        : null}
                    </div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ViewMetricDetails.propTypes = {}

const mapStateToProps = state => {
	// console.log('observability query page',state)
	return {
		accounts: state.filters.accounts,
		regions: state.filters.regions,
		editNewRuleDetails: state.governance.editNewRuleDetails,
		scheduleMonMetricProps: state.assets.resourcesPropsDetails && state.assets.resourcesPropsDetails.scheduleMonMetricProps ? state.assets.resourcesPropsDetails.scheduleMonMetricProps : [],
	}
}

export default connect(mapStateToProps, {
	setResourcePropsDetails,
	getMonitoringMetricsByTrend
})(withRouter(ViewMetricDetails))