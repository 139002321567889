/*************************************************
 * Tvastar
 * @exports
 * @file detectorsReducer.js
 * @author Rajasekar // on 26/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from '../../actions/types'

const INITIAL_STATE = {
	detectors: {},
}

/**
 * To store detectors list in redux store
 * @param {Object} state
 * @param {Object} action
 */
export const detectorsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ACTION_TYPES.SET_DETECTORS_LIST:
			return {
				...state,
				detectors: { ...action.payload },
			}
		default:
			return state
	}
}