/*************************************************
 * Tvastar
 * @exports
 * @file DbSlowQueryMySQL.js
 * @author Prakash // on 27/11/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { capitalizeTheFirstLetterOfEachWord, momentConvertionUtcToLocalTime, subDays, momentDateGivenFormat } from '../../../utils/utility'
import Chart from 'react-apexcharts'
import { getSlowQuerySummary, getEachSlowQueryDailyTrend } from '../../../actions/aiops/SlowQueryAction'
import _ from 'lodash'

import Search from '../../common/SearchComponent'
import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'
import ApexBarChart from '../../common/charts/ApexBarChart'

class DbSlowQueryMySQL extends Component {
    constructor(props) {
        super(props)
        this.annotationTypeRef = React.createRef()
        this.state = {
            showLoading: false,
            summaryLoader: true,
            slowQueryType: 'Summary',
            totalQueryList: [],
            filteredQueryList: [],
            selectedSortBy: 'max'
        }
    }
    
    componentDidMount = () => {
        this.getSlowQuerySummary()
        // if(this.props.metricsType !== 'SRE Observations') {
        //     if(!this.props.assetDetails || !this.props.assetDetails.resource_type || this.props.assetDetails.resource_type !== 'EC2') {
        //         if(Object.entries(this.props.diagnosticSreMertrics).length) {
        //             if(this.props.diagnosticSreMertrics.hasOwnProperty(this.props.assetDetails.asset_name)) {
        //                 this.setState({ 
        //                     sreMetricsDetails: this.props.diagnosticSreMertrics[this.props.assetDetails.asset_name],
        //                     showLoading: false
        //                 },
        //                     () => {
        //                         this.formSreMetricsLatencyGraph()
        //                         this.formSreMetricsRateGraph()
        //                     }
        //                 )
        //             } else {
        //                 this.getSreDetails()
        //             }
        //         } else {
        //             this.getSreDetails()
        //         }
        //     }
        // }        
    }

    getSlowQuerySummary = () => {
        let params = {}
        let startTime = ""
        let endTime = ""
        if(this.props.selectedDiagnosticEvent.start_time && this.props.selectedDiagnosticEvent.start_time !== "" && this.props.selectedDiagnosticEvent.end_time && this.props.selectedDiagnosticEvent.end_time !== "") {
            startTime = this.props.selectedDiagnosticEvent.start_time
            endTime = this.props.selectedDiagnosticEvent.end_time
        } else {
            endTime = this.props.diagnostics.diagnostic_end_time ?  this.props.diagnostics.diagnostic_end_time :  this.props.diagnostics.diagnostic_start_time
            startTime = momentDateGivenFormat(subDays(endTime, 3), "YYYY-MM-DD HH:mm:ss")
        }
        params.start_time = startTime
        params.end_time = endTime
        params.asset_id = this.props.assetDetails.asset_id
        
        // params.end_time = "2022-01-07 02:03:23"
        // params.start_time = "2022-01-04 01:59:39"

        this.props.getSlowQuerySummary(params, (promise, response) => {
            if(promise) {
                this.setState({ summaryDetails: response, summaryLoader: false },
                    () => {
                        this.getQueryList()
                        this.histogramChart()
                        this.trendGraph()
                        this.getTopQueries()
                    }    
                )
            }
        })
    }

    getQueryList = () => {
        if(this.state.summaryDetails && this.state.summaryDetails.total_count && this.state.summaryDetails.total_count.queries) {
            this.setState({ totalQueryList: this.state.summaryDetails.total_count.queries },
                () => this.filterQueryResult()    
            )
        }
    }

    filterQueryResult = () => {
        this.state.filteredQueryList.forEach((item, i) => {
            this.setState({ ['expand_'+i]: false })
        })

        let queryList = this.state.totalQueryList
        if(this.state.selectedSortBy !== '') {
            // let sortby = _.orderBy(queryList, item => [item.query_time[this.state.selectedSortBy], item.count], ['desc', 'desc'])
            let sortby = _.orderBy(queryList, item => item.query_time[this.state.selectedSortBy], ['desc'])
            queryList = sortby
        }

        this.setState({ filteredQueryList: queryList })
        
    }

    histogramChart = () => {
        let categories = [" "]
        let data = [0]
        if(this.state.summaryDetails && this.state.summaryDetails.histogram && this.state.summaryDetails.histogram.length) {
            categories = this.state.summaryDetails.histogram[0].data ? Object.keys(this.state.summaryDetails.histogram[0].data) : []
            data = this.state.summaryDetails.histogram[0].data ? Object.values(this.state.summaryDetails.histogram[0].data) : []
        } else {
            let histogramData = {
                "<1s": 0,
                "1s-5s": 0,
                "5s-10s": 0,
                "10s-30s": 0,
                "30s-60s": 0,
                ">=60s": 0
            }

            categories = Object.keys(histogramData)
            data = Object.values(histogramData)
        }
            
        let histogramSeries = [{
            data: data
        }]

        let histogramOptions = {
            chart: {
                height: 350,
                type: 'bar',
                redrawOnParentResize: true,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
                
                sparkline: {
                    enabled: false
                },
                stacked: false,
                // events: {
                //     click: function(chart, w, e) {
                //         // console.log(chart, w, e)
                //     }
                // }
            },
            colors: ['#A88CCC', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7'],
            plotOptions: {
                bar: {
                    columnWidth: '45%',
                    distributed: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            xaxis: {
                categories: categories,
                labels: {
                    style: {
                        colors: '#B8BBBE',
                        // colors: ['#B8BBBE', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7'],
                        fontSize: '12px'
                    }
                },
                axisTicks: {
                    show: false
                },
            },
            yaxis: {
                labels: {
                    style: {
                        colors: '#B8BBBE',
                        fontSize: '12px'
                    }
                }
            },
            grid: {
                show: false,			        
                xaxis: {
                    lines: {
                        show: false
                    },
                },
                yaxis: {
                    lines: {
                        show: false
                    }
                },
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: -5
                },
            },
            tooltip: {
                enabled: true,
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    // let val  = series[seriesIndex][dataPointIndex];
                    // let labelName = w.globals.initialSeries[seriesIndex].name
                    // return '<div class="arrow_box">' +
                    //     '<span> '+ w.globals.initialSeries[0].data[dataPointIndex].x + 
                    //     ' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val + ' </span>' +
                    //     '</div>'
                    return ''
                },
                style: {
                    fontSize: '9px',
                },
            },
        };

        let graphData = {}
        graphData.labels = categories
        graphData.data = data

        this.setState({ histogramSeries, histogramOptions, graphData })
    }

    trendGraph = () => {
        if(this.state.summaryDetails && this.state.summaryDetails.trend && this.state.summaryDetails.trend.length) {
            let graphLables = this.state.summaryDetails.trend[0].dates ? this.state.summaryDetails.trend[0].dates : []
            let data = this.state.summaryDetails.trend[0].data ?  this.state.summaryDetails.trend[0].data : {}
            
            let summaryTrendGraph = []            
            let colorsInc = 0
            Object.entries(data).forEach(([key, value]) => {
                let colors = ['#19B2C0', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']
                let series = []
                let dataArray = []
                if(key !== 'count' && key !== 'avg' && key !== 'min' && key !== 'max') {
                    summaryTrendGraph.push(key)
                    let unit = ""
                    if(key === "query_time" || key === "lock_time") {
                        unit = "sec"
                    }
                    graphLables && graphLables.forEach((date, i) => {

                        let toolTipData = {}
                        toolTipData.count = data.count[i]
                        toolTipData[capitalizeTheFirstLetterOfEachWord(key.replace('_', ' '))] = value[i] +" "+unit
                        toolTipData.avg = data.avg[key][i] +" "+unit
                        toolTipData.min = data.min[key][i] +" "+unit
                        toolTipData.max = data.max[key][i] +" "+unit

                        let dataItems = {}
                        dataItems.x = momentConvertionUtcToLocalTime(date, 'DD MMM YYYY HH:mm')
                        dataItems.y = value[i]
                        dataItems.toolTipData = toolTipData
                        dataArray.push(dataItems)
                    })

                    let seriesRow = {}
                    seriesRow.name = capitalizeTheFirstLetterOfEachWord(key.replace('_', ' '))
                    seriesRow.data = dataArray
                    series.push(seriesRow)

                    let options = {
                        chart: {
                            id:'bar1',
                            // redrawOnParentResize: true,
                            toolbar: {
                                show: false,
                            },
                            zoom: {
                                enabled: false,
                            },
                            sparkline: {
                                // enabled: key === 'rows_examined' ? false : true
                                enabled: false
                            },
                            animations: {
                                enabled: false,
                            },
                            type: 'bar',
                            height: 150,
                            stacked: false,
                        },
                        markers: {
                            size: 0
                        },
                        colors: [colors[colorsInc]],
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '1%',
                                // distributed: false,
                                // rangeBarOverlap: true,
                                // rangeBarGroupRows: false,
                            },       
                        },
                        dataLabels: {
                            enabled: false
                        },                
                        grid: {
                            show: false,			        
                            xaxis: {
                                lines: {
                                    show: false
                                },
                            },
                            yaxis: {
                                lines: {
                                    show: false
                                }
                            },
                            padding: {
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: -5
                            },
                        },
                        xaxis: {
                            show: false,
                            tooltip: {
                                enabled: false
                            },
                            axisTicks: {
                                show: false
                            },
                            type: 'datetime',
                            axisBorder: {
                                show: true,
                                color: '#434B5E',
                            },
                            labels: {
                                show: key === 'rows_examined' ? true : false,
                                datetimeUTC: false,
                                style: {
                                    colors: '#B8BBBE',
                                    fontSize: '10px',
                                    fontWeight: 'bolder',
                                },
                                offsetX: 0,
                                offsetY: -5,
                            },
                        },
                        yaxis: {
                            show: false,
                        },
                        legend: {
                            show: false,
                        },
                        tooltip: {
                            enabled: true,
                            custom: function({series, seriesIndex, dataPointIndex, w}) {
                                // let val  = series[seriesIndex][dataPointIndex];
                                // let labelName = w.globals.initialSeries[seriesIndex].name
                                
                                let toolTip = w.globals.initialSeries[0].data[dataPointIndex].toolTipData
                                // return '<div class="arrow_box">' +
                                //     '<span> '+ w.globals.initialSeries[0].data[dataPointIndex].x + 
                                //     ' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val + ' </span>' +

                                let retrunData = '<div class="arrow_box">'
                                retrunData += '<div class="">'
                                retrunData += '<span style="color:'+ w.globals.colors[seriesIndex] +'">'+w.globals.initialSeries[0].data[dataPointIndex].x+ '<span></div>'
                                // retrunData += '<div class="small"> <span class="small text-gray3">'+labelName+'</span> : '+val+'</div>'
                                Object.entries(toolTip).forEach(([key, value]) => {
                                    if(value !== '') {
                                        retrunData += '<div class="small"> <span class="text-gray3">'+capitalizeTheFirstLetterOfEachWord(key)+'</span> : '+value+'</div>'
                                    }
                                })
                                retrunData += '</div>'

                                return retrunData
                            },
                            style: {
                                fontSize: '9px',
                            },
                        },
                    }

                    this.setState({ [key+'_series']: series, [key+'_options']: options })
                    if(colorsInc === 4) {
                        colorsInc = 0
                    } else {
                        colorsInc++
                    }
                }
            })

            this.setState({ summaryTrendGraph })

            // let graphData = {}
            // graphData.labels = graphLables
            // graphData.data = data.query_time

            // this.setState({ queryTimeGraphData: graphData })
        }
    }

    getTopQueries = () => {
        if(this.state.summaryDetails && this.state.summaryDetails.total_count && this.state.summaryDetails.total_count.queries) {
            let data = []
            let labels = []
            let queries = this.state.summaryDetails.total_count.queries
            // console.log(_.orderBy(queries, item => item.query_time.max, ['asc'])) //order by nested
            queries.slice(0,5).forEach(item => {
                labels.push(item.query)
                let value = item.query_time && item.query_time.max ? item.query_time.max : 0
                data.push(value)
            })

            for(let i=labels.length; i<5; i++) {
                data.push(0)
                labels.push('empty')
            }
            let topQueriesByTime = {}
            topQueriesByTime.data = data
            topQueriesByTime.labels = labels
            topQueriesByTime.unit = 'sec'

            // top query by hits

            let hitsData = []
            let hitsLabels = []
            let orderedData = _.orderBy(this.state.summaryDetails.total_count.queries, ['count'], ['desc'])
            orderedData.slice(0,5).forEach(item => {
                hitsLabels.push(item.query)
                let value = item.count ? item.count : 0
                hitsData.push(value)
            })

            for(let i=hitsLabels.length; i<5; i++) {
                hitsData.push(0)
                hitsLabels.push('empty')
            }
            let topQueriesByHits = {}
            topQueriesByHits.data = hitsData
            topQueriesByHits.labels = hitsLabels

            this.setState({ topQueriesByTime, topQueriesByHits })
        }
    }

    showButton = (e, index) => {
        e.preventDefault()
        this.setState({ ['expandBtn_'+index]: true })
    };
    
    hideButton = (e, index) => {
        e.preventDefault();
        this.setState({ ['expandBtn_'+index]: false })
    }

    expandQuerySection = (queryDetails, index) => {
        this.state.filteredQueryList.forEach((item, i) => {
            if(index !== i) {
                this.setState({ ['expand_'+i]: false })
            }
        })
        if(this.state['expand_'+index]) {
            let params = {}
            let startTime = ""
            let endTime = ""
            if(this.props.selectedDiagnosticEvent.start_time && this.props.selectedDiagnosticEvent.start_time !== "" && this.props.selectedDiagnosticEvent.end_time && this.props.selectedDiagnosticEvent.end_time !== "") {
                startTime = this.props.selectedDiagnosticEvent.start_time
                endTime = this.props.selectedDiagnosticEvent.end_time
            } else {
                endTime = this.props.diagnostics.diagnostic_end_time ?  this.props.diagnostics.diagnostic_end_time :  this.props.diagnostics.diagnostic_start_time
                startTime = momentDateGivenFormat(subDays(endTime, 3), "YYYY-MM-DD HH:mm:ss")
            }
            params.start_time = startTime
            params.end_time = endTime
            params.query = queryDetails.query ? queryDetails.query : ''

            if(params.query !== '') {
                this.props.getEachSlowQueryDailyTrend(params, (promise, response) => {
                    if(promise) {
                        this.setState({ queryDetails: response, queryTrendLoading: false },
                            () => this.queryTrendSection()    
                        )
                    } else {
                        this.setState({ queryTrendLoading: false })
                    }
                })
            }
        } else {
            this.setState({ queryTrendLoading: false })
        }
    }

    queryTrendSection = () => {
        if(this.state.queryDetails && this.state.queryDetails.queries && this.state.queryDetails.queries.length) {
            let graphLables = this.state.queryDetails.queries[0].dates ? this.state.queryDetails.queries[0].dates : []
            let data = this.state.queryDetails.queries[0].data ?  this.state.queryDetails.queries[0].data : {}
            
            let queryTrendGraph = []            
            let colorsInc = 0
            Object.entries(data).forEach(([key, value]) => {
                let colors = ['#19B2C0', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']
                let series = []
                let dataArray = []
                if(key !== 'count' && key !== 'avg' && key !== 'min' && key !== 'max') {
                    let unit = ""
                    if(key === "query_time" || key === "lock_time") {
                        unit = "sec"
                    }
                    queryTrendGraph.push(key)
                    graphLables && graphLables.forEach((date, i) => {

                        let toolTipData = {}
                        toolTipData.count = data.count[i]
                        toolTipData[capitalizeTheFirstLetterOfEachWord(key.replace('_', ' '))] = value[i] +" "+unit
                        toolTipData.avg = data.avg[key][i] +" "+unit
                        toolTipData.min = data.min[key][i] +" "+unit
                        toolTipData.max = data.max[key][i] +" "+unit

                        let dataItems = {}
                        dataItems.x = momentConvertionUtcToLocalTime(date, 'DD MMM YYYY HH:mm')
                        dataItems.y = value[i]
                        dataItems.toolTipData = toolTipData
                        dataArray.push(dataItems)                        
                    })

                    let seriesRow = {}
                    seriesRow.name = capitalizeTheFirstLetterOfEachWord(key.replace('_', ' '))
                    seriesRow.data = dataArray
                    series.push(seriesRow)

                    let options = {
                        chart: {
                            id:'bar1',
                            // redrawOnParentResize: true,
                            toolbar: {
                                show: false,
                            },
                            zoom: {
                                enabled: false,
                            },
                            sparkline: {
                                enabled: false
                            },
                            animations: {
                                enabled: false,
                            },
                            type: 'bar',
                            height: 150,
                            stacked: false,
                        },
                        markers: {
                            size: 0
                        },
                        colors: [colors[colorsInc]],
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '1%',
                                // distributed: false,
                                // rangeBarOverlap: true,
                                // rangeBarGroupRows: false,
                            },       
                        },
                        dataLabels: {
                            enabled: false
                        },                
                        grid: {
                            show: false,			        
                            xaxis: {
                                lines: {
                                    show: false
                                },
                            },
                            yaxis: {
                                lines: {
                                    show: false
                                }
                            },
                            padding: {
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: -5
                            },
                        },
                        xaxis: {
                            show: false,
                            tooltip: {
                                enabled: false
                            },
                            axisTicks: {
                                show: false
                            },
                            type: 'datetime',
                            axisBorder: {
                                show: true,
                                color: '#434B5E',
                            },
                            labels: {
                                show: key === 'rows_examined' ? true : false,
                                datetimeUTC: false,
                                style: {
                                    colors: '#B8BBBE',
                                    fontSize: '10px',
                                    fontWeight: 'bolder',
                                },
                                offsetX: 0,
                                offsetY: -5,
                            },
                        },
                        yaxis: {
                            show: false,
                        },
                        legend: {
                            show: false,
                        },
                        tooltip: {
                            enabled: true,
                            custom: function({series, seriesIndex, dataPointIndex, w}) {
                                // let val  = series[seriesIndex][dataPointIndex];
                                // let labelName = w.globals.initialSeries[seriesIndex].name
                                let toolTip = w.globals.initialSeries[0].data[dataPointIndex].toolTipData
                                // return '<div class="arrow_box">' +
                                //     '<span> '+ w.globals.initialSeries[0].data[dataPointIndex].x + 
                                //     ' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val + ' sec </span>' +
                                //     '</div>'

                                let retrunData = '<div class="arrow_box">'
                                retrunData += '<div class="">'
                                retrunData += '<span style="color:'+ w.globals.colors[seriesIndex] +'">'+w.globals.initialSeries[0].data[dataPointIndex].x+ '<span></div>'
                                // retrunData += '<div class="small"> <span class="small text-gray3">'+labelName+'</span> : '+val+'</div>'
                                Object.entries(toolTip).forEach(([key, value]) => {
                                    if(value !== '') {
                                        retrunData += '<div class="small"> <span class="text-gray3">'+capitalizeTheFirstLetterOfEachWord(key)+'</span> : '+value+'</div>'
                                    }
                                })
                                retrunData += '</div>'

                                return retrunData
                            },
                            style: {
                                fontSize: '9px',
                            },
                        },
                    }

                    this.setState({ [key+'_query_series']: series, [key+'_query_options']: options })
                    if(colorsInc === 4) {
                        colorsInc = 0
                    } else {
                        colorsInc++
                    }
                }
            })

            this.setState({ queryTrendGraph, queryTrendLoading: false })
        }
    }

    render() {
        return (
            !this.state.showLoading ?
                <div className="mt-2">
                    <ul className={`row nav nav-tabs border-bottom bg-muted shadow-none rounded ${this.state.summaryLoader ? 'disabled' : ''}`} id="myTab" role="tablist">
                        <li className="nav-item">
                            <span className={`nav-link text-white cursorPointer ${this.state.slowQueryType === 'Summary' ? ' active' : ''}`} onClick={() => this.setState({ slowQueryType: 'Summary' })}>Summary</span>
                        </li>
                        <li className="nav-item">
                            <span className={`nav-link text-white cursorPointer ${this.state.slowQueryType === 'Analysis' ? ' active' : ''}`} onClick={() => this.setState({ slowQueryType: 'Analysis' })}>Analysis</span>
                        </li>
                    </ul>
                    <div className="rounded bg-dark p-3 mt-2">
                        <p className="mb-1 f16 text-white">Asset Details</p>
                        <div className="d-flex mb-1 border-bottom">
                            <div className="py-1 w-33">
                                <p className="b-block mb-0">Provider</p>
                                <p className="mb-0 text-white">
                                    {this.props.assetDetails && this.props.assetDetails.provider ? this.props.assetDetails.provider.toUpperCase() : <span>&nbsp;</span>}
                                    {/* {this.state.assetDetails && this.state.assetDetails.account_id ? (' : ')+getAccountNameFromId(this.state.assetDetails.account_id, this.props.accounts) : <span>&nbsp;</span>}
                                    {this.state.assetDetails && this.state.assetDetails.region ?  (' : ')+getRegionName(this.state.assetDetails.region, this.props.regions) : <span>&nbsp;</span>} */}
                                </p>
                            </div>
                            <div className="py-1 w-33 pl-3">
                                <p className="b-block mb-0">Asset Name:</p>
                                <p className="mb-0 text-white">{this.props.assetDetails && this.props.assetDetails.asset_name ? this.props.assetDetails.asset_name : <span>&nbsp;</span>}</p>
                            </div>
                            <div className="py-1 w-33 pl-3">
                                <p className="b-block mb-0">Service:</p>
                                <p className="mb-0 text-white">
                                    {this.props.assetDetails && this.props.assetDetails.resource_type ? this.props.assetDetails.resource_type : <span>&nbsp;</span>}
                                    {this.props.assetDetails && this.props.assetDetails.service_name ?  (' : ')+this.props.assetDetails.service_name : <span>&nbsp;</span>}
                                </p>
                            </div>
                        </div>
                        <p className="mb-2">Total number of Slow Queries : {this.props.selectedDiagnosticEvent && this.props.selectedDiagnosticEvent.total ? this.props.selectedDiagnosticEvent.total : 0}</p>
                        <p className="mb-2">Total number of Unique Slow Queries : {this.props.selectedDiagnosticEvent && this.props.selectedDiagnosticEvent.unique ? this.props.selectedDiagnosticEvent.unique : 0}</p>
                        <p className="mb-0">DB Tables frequently present in Slow Queries: jobs, employee, movies</p>
                    </div>
                    {this.state.slowQueryType === 'Summary' ? 
                        this.state.summaryLoader ?
                            <div className='d-flex justify-content-center m-4'>
                                <Spinner className='text-center' color='white' size='lg' />
                            </div>          
                        :
                            <React.Fragment>
                                <div className="rounded bg-dark p-3 mt-2">
                                    <div className="mt-2 d-flex justify-content-between">
                                        {/* {this.state.histogramSeries ? 
                                            <div className="bg-dark3 rounded w-75 transparentTooltip">
                                                <Chart options={this.state.histogramOptions} series={this.state.histogramSeries} type="bar" height={180} />
                                            </div>
                                        : null} */}
                                        {this.state.graphData ?
                                            <div className="bg-dark3 rounded w-75">
                                                <ApexBarChart
                                                    graphData={this.state.graphData}
                                                    sparkline={false}
                                                    chartDistributed={true}
                                                    yaxis={true}
                                                    yaxisLabel={true}
                                                    // yaxisTickAmont={3}
                                                    xaxis={true}
                                                    xaxisFormat={'string'}
                                                    xaxisLabel={true}
                                                    axisLabelColor={'#50586a'}
                                                    axisLabelFontSize={'12px'}
                                                    paddingLeft={-25}
                                                    legend={false}
                                                    stacked={false}
                                                    height={180}
                                                    horizontal={false}
                                                    barHeight={'40%'}
                                                    barEndShape={'flat'}
                                                    columnWidth={'45%'}
                                                    gradient={false}
                                                    gradientColor={['#039BE5', '#5F5BA2']}
                                                    hideTooltipValue={false}
                                                    backgroundBarShape={'flat'}
                                                    backgroundBarColors={['#333947']}
                                                    showBackgroundBarColors={false}
                                                    className={"transparentTooltip"}
                                                    colors={['#A88CCC', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7']}
                                                />
                                            </div>
                                        : null}
                                        <div className="w-25 ml-2">
                                            <div className="d-flex justify-content-between mb-2">
                                                <div class="bg-dark3 px-2 py-1 rounded w-50">
                                                    <p class="text-success-50 mb-1">{this.state.summaryDetails && this.state.summaryDetails.total_count && this.state.summaryDetails.total_count.query_time && this.state.summaryDetails.total_count.query_time.min ? this.state.summaryDetails.total_count.query_time.min : 0} sec</p>
                                                    <div class="bg-success-3 small-divder"></div>
                                                    <p class="text-success-50 mt-1 mb-0">Min</p>
                                                </div>
                                                <div class="bg-dark3 px-2 py-1 rounded w-50 ml-2">
                                                    <p class="text-info-50 mb-1">{this.state.summaryDetails && this.state.summaryDetails.total_count && this.state.summaryDetails.total_count.query_time && this.state.summaryDetails.total_count.query_time.avg ? this.state.summaryDetails.total_count.query_time.avg : 0} sec</p>
                                                    <div class="bg-info small-divder"></div>
                                                    <p class="text-info-50 mt-1 mb-0">Avg</p>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between mb-2">
                                                <div class="bg-dark3 px-2 py-1 rounded w-50">
                                                    <p class="text-success-50 mb-1">{this.state.summaryDetails && this.state.summaryDetails.total_count && this.state.summaryDetails.total_count.query_time && this.state.summaryDetails.total_count.query_time.max ? this.state.summaryDetails.total_count.query_time.max : 0} sec</p>
                                                    <div class="bg-success-3 small-divder"></div>
                                                    <p class="text-success-50 mt-1 mb-0">Max</p>
                                                </div>
                                                <div class="bg-dark3 px-2 py-1 rounded w-50 ml-2">
                                                    <p class="text-info-50 mb-1">{this.state.summaryDetails && this.state.summaryDetails.total_count && this.state.summaryDetails.total_count.query_time && this.state.summaryDetails.total_count.query_time.stddev ? this.state.summaryDetails.total_count.query_time.stddev : 0} sec</p>
                                                    <div class="bg-info small-divder"></div>
                                                    <p class="text-info-50 mt-1 mb-0">STD</p>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div class="bg-dark3 px-2 py-1 rounded w-50">
                                                    <p class="text-success-50 mb-1">{this.state.summaryDetails && this.state.summaryDetails.total_count && this.state.summaryDetails.total_count.query_time && this.state.summaryDetails.total_count.query_time.median ? this.state.summaryDetails.total_count.query_time.median : 0} sec</p>
                                                    <div class="bg-success-3 small-divder"></div>
                                                    <p class="text-success-50 mt-1 mb-0">Med</p>
                                                </div>
                                                <div class="bg-dark3 px-2 py-1 rounded w-50 ml-2">
                                                    <p class="text-info-50 mb-1">{this.state.summaryDetails && this.state.summaryDetails.total_count && this.state.summaryDetails.total_count.query_time && this.state.summaryDetails.total_count.query_time['95%'] ? this.state.summaryDetails.total_count.query_time['95%'] : 0} sec</p>
                                                    <div class="bg-info small-divder"></div>
                                                    <p class="text-info-50 mt-1 mb-0">95%</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rounded bg-dark p-3 mt-2">
                                    <div className="row">
                                        <p className="col-sm-12 mb-0 f16 text-white">Trend</p>
                                        {this.state.summaryTrendGraph && this.state.summaryTrendGraph.map((graph, i) => {
                                            return (
                                                this.state[graph+'_series'] && this.state[graph+'_series'].length ?
                                                    <div className={`col-sm-12 d-flex ${graph === 'query_time' ? '' : ' mt-n4 mb-n1'}`}>
                                                        <p className="mb-0 f12 align-self-center text-right mr-1" style={{minWidth: "100px"}}>{capitalizeTheFirstLetterOfEachWord(graph.replace('_', ' '))}</p>
                                                        <div className="w-100 transparentTooltip bg-dark3">
                                                            <Chart options={this.state[graph+'_options']} series={this.state[graph+'_series']} type="bar" height={graph === 'rows_examined' ? 100 : 82} />
                                                        </div>
                                                    </div>
                                                : null
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="row mt-2">
                            <div className="col-lg-6">
                                <div className="bg-dark p-3 rounded">
                                    <label>Top Query by Time</label>
                                    {this.state.topQueriesByTime && Object.entries(this.state.topQueriesByTime).length ?
                                        <div className="mt-n4"> 
                                            <ApexBarChart
                                                graphData={this.state.topQueriesByTime}
                                                sparkline={false}
                                                yaxis={false}
                                                yaxisLabel={false}
                                                xaxis={true}
                                                xaxisFormat={'string'}
                                                xaxisLabel={true}
                                                axisLabelColor={'#B8BBBE'}
                                                paddingLeft={-25}
                                                legend={false}
                                                stacked={false}
                                                height={240}
                                                horizontal={true}
                                                barHeight={'40%'}
                                                barEndShape={'rounded'}
                                                columnWidth={'25%'}
                                                gradient={true}
                                                gradientColor={['#039BE5', '#5F5BA2']}
                                                hideTooltipValue={true}
                                                backgroundBarShape={'rounded'}
                                                backgroundBarColors={['#333947']}
                                                showBackgroundBarColors={false}
                                                className={"transparentTooltip"}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>
                            <div className="col-lg-6 pl-0">
                                <div className="bg-dark p-3 rounded">
                                    <label>Top Query by Hits</label>
                                    {this.state.topQueriesByHits && Object.entries(this.state.topQueriesByHits).length ?
                                        <div className="mt-n4"> 
                                            <ApexBarChart
                                                graphData={this.state.topQueriesByHits}
                                                sparkline={false}
                                                yaxis={false}
                                                yaxisLabel={false}
                                                xaxis={true}
                                                xaxisFormat={'string'}
                                                xaxisLabel={true}
                                                axisLabelColor={'#B8BBBE'}
                                                paddingLeft={-25}
                                                legend={false}
                                                stacked={false}
                                                height={240}
                                                horizontal={true}
                                                barHeight={'40%'}
                                                barEndShape={'rounded'}
                                                columnWidth={'25%'}
                                                gradient={true}
                                                gradientColor={['#039BE5', '#5F5BA2']}
                                                hideTooltipValue={true}
                                                backgroundBarShape={'rounded'}
                                                backgroundBarColors={['#333947']}
                                                showBackgroundBarColors={false}
                                                className={"transparentTooltip"}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>
                        </div>
                            </React.Fragment>
                    : this.state.slowQueryType === 'Analysis' ?
                        <div className="">
                            <div className="rounded bg-dark p-3 mt-2">
                                <div className="d-flex mb-2 justify-content-between">
                                    <div className="d-flex mr-2 w-60">
                                        <p className="f12 m-0 align-self-center pt-1">Showing {this.state.filteredQueryList && this.state.filteredQueryList.length} of total {this.state.totalQueryList && this.state.totalQueryList.length}{this.state.totalQueryList && this.state.totalQueryList.length > 1 ? ' Queries' : ' Query'}</p>
                                        <div className="d-flex ml-3">
                                            <p className="font-weight-bold mb-0 mr-2 align-self-center">Sort by time</p>
                                            <div className={`form-group bd-highlight mb-0 styled-feild pt-0 pb-0 align-self-center`}>
                                                <select className="form-control-sm" 
                                                    value={this.state.selectedSortBy}
                                                    onChange={event => {
                                                        this.setState({
                                                            selectedSortBy: event.target.value,
                                                            emptySearchText: true
                                                        },
                                                            () => this.filterQueryResult()
                                                        )
                                                    }}>
                                                    <option value={'min'}>Min</option>
                                                    <option value={'avg'}>Avg</option>
                                                    <option value={'max'}>Max</option>
                                                    <option value={'median'}>Median</option>
                                                    <option value={'stddev'}>Standard deviation</option>
                                                    <option value={'95%'}>95 Percentile</option>                                                    
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end w-40 mb-n2">
                                        {this.state.totalQueryList && this.state.totalQueryList.length ?
                                            <Search
                                                data={this.state.totalQueryList}
                                                applyTags={false}
                                                applyLiteDarkTags={true}
                                                topClassName={'bg-black5 w-50 mr-1 border-gray5 rounded-5 f12'}
                                                searchClassName={'px-2 f12'}
                                                searchIconColor={'text-gray5 f12'}
                                                searchPlaceHolder={'Search querys....'}
                                                className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                                filteredData={(filteredArray) => {
                                                    this.setState({ filteredQueryList: filteredArray, errorDetails: {}, merticsAssetNameInvolved: [], emptySearchText: false  },
                                                        () => {
                                                            if(filteredArray.length) {
                                                                // this.getErrorDetails(filteredArray[0])
                                                            }
                                                        }    
                                                    )
                                                }}
                                                resetSearchBox={this.state.emptySearchText}
                                                // searchTextReenter={this.state.searchText} //to maintian the search text after filte search
                                            />
                                        : null}
                                        {/* {this.state.filteredQueryList && this.state.filteredQueryList.length > this.state.perPage ?
                                            <div className="pagination errorPagePagination f12">
                                                <span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
                                                <button><i className={`fal fa-arrow-to-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('start', this.state.currentPage)}></i></button> 
                                                <button><i className={`fal fa-angle-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('previous', this.state.currentPage)}></i></button> 
                                                <span>
                                                    Go to
                                                    <input
                                                        type='number'
                                                        value={this.state.currentPage}
                                                        onChange={e => {
                                                            const page = e.target.value ? Number(e.target.value) : 1
                                                            this.gotoPage(page)
                                                        }}
                                                        className="ml-1 inputClass"
                                                    />
                                                </span>
                                                <button><i className={`fal fa-angle-right cursorPointer ${this.state.currentPage === this.state.overAllTotalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
                                                <button><i className={`fal fa-arrow-to-right cursorPointer ${this.state.currentPage === this.state.overAllTotalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
                                            </div>
                                        : null} */}
                                    </div>
                                </div>
                                <div className={`bg-dark3 errorSectionScroll p-3`} style={{minHeight: "400px"}}>
                                    {this.state.filteredQueryList && this.state.filteredQueryList.length ?
                                        this.state.filteredQueryList.map((item, i) => {
                                            return(
                                                <div className={`${this.state.filteredQueryList.length - 1 !== i ? 'border-bottom-black5 pb-1' : ''}`}>
                                                    <div className={`d-flex justify-content-between mb-2 cursorPointer`} onMouseEnter={e => this.showButton(e, i)} onMouseLeave={e => this.hideButton(e, i)} onClick={() => this.setState({ ['expand_'+i]: !this.state['expand_'+i], queryTrendLoading: true  }, () => this.expandQuerySection(item, i))}>
                                                        <p className="mb-0 secondLineEllipses mr-2">
                                                            <span className="mb-0 mr-2 f12 font-weight-bold">{item.count ? item.count : ''} Occurances </span>
                                                            <span className="mb-0 mr-2 f12 font-weight-bold">{this.state.selectedSortBy} {item.query_time && item.query_time[this.state.selectedSortBy] ? item.query_time[this.state.selectedSortBy] : 0} seconds </span>
                                                            <span className="mb-0 mr-2 f12 text-success-light font-weight-bold">{item.query ? item.query : ''}</span>
                                                        </p>
                                                        <button className={`btn btn-sm d-flex align-self-center slowQueryExpandBtn ${!this.state['expand_'+i] && this.state['expandBtn_'+i] ? '' : 'displayNone'}`}>Expand</button>
                                                    </div>            
                                                    {this.state['expand_'+i] ? 
                                                        this.state.queryTrendLoading ?
                                                            <div className='d-flex justify-content-center m-4'>
                                                                <Spinner className='text-center' color='white' size='lg' />
                                                            </div>
                                                        :
                                                            <div className="my-2">
                                                                <div className="d-flex">
                                                                    <p className="mb-1 f16 text-white">Query Details</p>
                                                                    <i className="ml-3 fas fa-copy align-self-center cursorPointer" onClick={() => {navigator.clipboard.writeText(item.query)}}></i>
                                                                </div>
                                                                <p className="bg-dark p-3 rounded font-weight-bold mb-2 d-flex flex-wrap">{item.query}</p>
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="bg-dark rounded w-75 rounded">
                                                                        <div className="row">
                                                                            {this.state.queryTrendGraph && this.state.queryTrendGraph.map((query, i) => {
                                                                                return (
                                                                                    this.state[query+'_query_series'] && this.state[query+'_query_series'].length ?
                                                                                        <div className={`col-sm-12 d-flex ${query === 'query_time' ? '' : ' mt-n4 mb-n1'}`}>
                                                                                            <p className="mb-0 f12 align-self-center text-right mr-1" style={{minWidth: "100px"}}>{capitalizeTheFirstLetterOfEachWord(query.replace('_', ' '))}</p>
                                                                                            <div className="w-100 transparentTooltip bg-dark">
                                                                                                <Chart options={this.state[query+'_query_options']} series={this.state[query+'_query_series']} type="bar" height={query === 'rows_examined'  ? 100 : 82} />
                                                                                            </div>
                                                                                        </div>
                                                                                    : null
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-25 ml-2">
                                                                        <div className="d-flex justify-content-between mb-3">
                                                                            <div class="bg-dark px-2 py-1 rounded w-50">
                                                                                <p class="text-success-50 mb-1">{this.state.queryDetails && this.state.queryDetails.query_time && this.state.queryDetails.query_time.min ? this.state.queryDetails.query_time.min : 0} sec</p>
                                                                                <div class="bg-success-3 small-divder"></div>
                                                                                <p class="text-success-50 mt-1 mb-0">Min</p>
                                                                            </div>
                                                                            <div class="bg-dark px-2 py-1 rounded w-50 ml-2">
                                                                                <p class="text-info-50 mb-1">{this.state.queryDetails && this.state.queryDetails.query_time && this.state.queryDetails.query_time.avg ? this.state.queryDetails.query_time.avg : 0} sec</p>
                                                                                <div class="bg-info small-divder"></div>
                                                                                <p class="text-info-50 mt-1 mb-0">Avg</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex justify-content-between mb-3">
                                                                            <div class="bg-dark px-2 py-1 rounded w-50">
                                                                                <p class="text-success-50 mb-1">{this.state.queryDetails && this.state.queryDetails.query_time && this.state.queryDetails.query_time.max ? this.state.queryDetails.query_time.max : 0} sec</p>
                                                                                <div class="bg-success-3 small-divder"></div>
                                                                                <p class="text-success-50 mt-1 mb-0">Max</p>
                                                                            </div>
                                                                            <div class="bg-dark px-2 py-1 rounded w-50 ml-2">
                                                                                <p class="text-info-50 mb-1">{this.state.queryDetails && this.state.queryDetails.query_time && this.state.queryDetails.query_time.stddev ? this.state.queryDetails.query_time.stddev : 0} sec</p>
                                                                                <div class="bg-info small-divder"></div>
                                                                                <p class="text-info-50 mt-1 mb-0">STD</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex justify-content-between">
                                                                            <div class="bg-dark px-2 py-1 rounded w-50">
                                                                                <p class="text-success-50 mb-1">{this.state.queryDetails && this.state.queryDetails.query_time && this.state.queryDetails.query_time.median ? this.state.queryDetails.query_time.median : 0} sec</p>
                                                                                <div class="bg-success-3 small-divder"></div>
                                                                                <p class="text-success-50 mt-1 mb-0">Med</p>
                                                                            </div>
                                                                            <div class="bg-dark px-2 py-1 rounded w-50 ml-2">
                                                                                <p class="text-info-50 mb-1">{this.state.queryDetails && this.state.queryDetails.query_time && this.state.queryDetails.query_time['95%'] ? this.state.queryDetails.query_time['95%'] : 0} sec</p>
                                                                                <div class="bg-info small-divder"></div>
                                                                                <p class="text-info-50 mt-1 mb-0">95%</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    : null}  
                                                </div>                              
                                            )
                                        })
                                    :
                                        <div className='d-flex justify-content-center m-4'>
                                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    : null}
                </div>
            : null
        )
    }
}

/**
* Type of the props used in the component
*/
DbSlowQueryMySQL.propTypes = {}   

const mapStateToProps = state => {
    return {
        accounts: state.filters.accounts,
        regions: state.filters.regions && state.filters.regions[0] && state.filters.regions[0].provider_regions ? state.filters.regions[0].provider_regions :[],
    }
}

export default connect(mapStateToProps, {
    getSlowQuerySummary,
    getEachSlowQueryDailyTrend,
    setAiopsPropsDetails
})(withRouter(DbSlowQueryMySQL))