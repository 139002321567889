/*************************************************
 * Tvastar
 * @exports
 * @file CustomerAccountAction.js
 * @author Prakash // on 14/10/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'

import {
	DLPPOLICYENDPOINT,
	DLP_CONFIGURATION_LIST_CUSTOMER_ACCOUNT_INFO,
	DLP_CONFIGURATION_INSERT_CUSTOMER_ACCOUNT_INFO,
	DLP_CONFIGURATION_UPDATE_CUSTOMER_ACCOUNT_INFO,
	DLP_CONFIGURATION_DELETE_CUSTOMER_ACCOUNT_INFO
} from '../../config'
import { ACTION_TYPES } from '../types'

/**
 * Action to list customer account info
 * @param {Object} body
 * @param {Function} callback
 */

export const listCustomerAccountInfo = (body, callback) => {
    return async dispatch => {
		try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_CONFIGURATION_LIST_CUSTOMER_ACCOUNT_INFO, { body })
			dispatch({
				type: ACTION_TYPES.SET_DLP_CONFIGURATION_LIST_CUSTOMER_ACCOUNT,
				payload: response,
			})
			callback(true, response)
		} catch (error) {
			dispatch({
				type: ACTION_TYPES.SET_DLP_CONFIGURATION_LIST_CUSTOMER_ACCOUNT,
				payload: [],
			})
			callback(false, {})
		}
	}
}

/**
 * Action to insert customer account info
 * @param {Object} body
 * @param {Function} callback
 */

export const insertCustomerAccountInfo = (body, callback) => {
    return async dispatch => {
		try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_CONFIGURATION_INSERT_CUSTOMER_ACCOUNT_INFO, { body })
			callback(true, response)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to Update cusotmer account info
 * @param {Object} body
 * @param {Function} callback
 */

export const updateCustomerAccountInfo = (body, callback) => {
    return async dispatch => {
		try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_CONFIGURATION_UPDATE_CUSTOMER_ACCOUNT_INFO, { body })
			callback(true, response)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to Delete customer account info
 * @param {Object} body
 * @param {Function} callback
 */

export const deleteCustomerAccountInfo = (body, callback) => {
    return async dispatch => {
		try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_CONFIGURATION_DELETE_CUSTOMER_ACCOUNT_INFO, { body })
			callback(true, response)
		} catch (error) {
			callback(false)
		}
	}
}
