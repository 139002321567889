/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AnomalyTab.js
 * @author Prakash // on 05/01/2022
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'

import { Spinner } from 'reactstrap'
import { addHours, momentConvertionUtcToLocalTime, momentConvertionLocalToUtcTime, subHours, currentLocaltime } from '../../../../utils/utility'
import { listObservabilityAnomalyResults } from '../../../../actions/aiops/ObservabilityAction'
import { setAiopsPropsDetails } from '../../../../actions/aiops/AiopsAction'

import Search from '../../../common/SearchComponent'

class AnomalyTab extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            eventMessageLoader: true,

            startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
            overAllTotalPages: 1,
			perPage: 30,
        }
        this.drawLine = 0
    }

    componentDidMount = () => {
        this.listObservabilityAnomalyResults()
        let obj = this.props.tabOptions
        obj.selectedTab = 'Events'
        this.props.setAiopsPropsDetails('tabOptions', obj)
    }

    componentDidUpdate = (prevProps) => {
        // if(this.props.tabOptions && Object.entries(this.props.tabOptions).length) {
        //     if(!_.isEqual(prevProps.tabOptions, this.props.tabOptions)) {
        //         this.setState({ 
        //             details: this.props.tabOptions.details,
        //         })
        //     }
        // }
    }

    listObservabilityAnomalyResults = () => {
        
        let eventTime = this.props.details.event_time
        if(eventTime) {
            let eventId = this.props.details.event_id
            let assetId = ''
            let assetName = ''
            if(this.props.pageType === 'Alerts') {
                assetId = this.props.details.asset_id
                assetName = this.props.details.asset_name
            } else if(this.props.pageType === 'Errors') {
                if(this.props.details.origin && this.props.details.origin.asset_name) { 
                    assetId = this.props.details.origin.asset_id
                    assetName = this.props.details.origin.asset_name
                } else if(this.props.details.raised_in && this.props.details.raised_in.asset_name) { 
                    assetId = this.props.raised_in.asset_id
                    assetName = this.props.raised_in.asset_name
                }
            } else if(this.props.pageType === 'Events') {
                assetId = this.props.details.assets && this.props.details.assets.length && this.props.details.assets[0].asset_id
                assetName = this.props.details.assets && this.props.details.assets.length && this.props.details.assets[0].asset_name
            }

            let startTime = subHours(eventTime, 1)
            let endTime = addHours(eventTime, 1)
            if(endTime > momentConvertionLocalToUtcTime(currentLocaltime(), 'YYYY-MM-DD HH:mm:ss')) {
                endTime = momentConvertionLocalToUtcTime(currentLocaltime(), 'YYYY-MM-DD HH:mm:ss')
            }
            let params = {}
            params.start_time = startTime
            params.end_time = endTime
            params.event_time = eventTime
            if(assetId && assetId !== '') {
                params.asset_id = assetId
            } else if(assetName && assetName !== '') {
                params.asset_name = assetName
            }

            if(!this.props.anomalyTabDetails.hasOwnProperty(eventId)) {
                this.props.listObservabilityAnomalyResults(params, (promise, response) => {
                    if(promise) {
                        let results = response.results && response.results.details ? response.results.details : []
        
                        let totalPages = 1
                        if(results.length > this.state.perPage) {
                            totalPages = Math.ceil(results.length / this.state.perPage)
                        }

                        this.setState({
                            anomlayList: results,
                            filteredList: results,
                            eventMessageLoader: false,
                            totalPages
                        },
                            () => this.props.menuDisabled()
                        )
                        if(results.length) {
                            let obj = this.props.anomalyTabDetails ? this.props.anomalyTabDetails : {}
                            obj[eventId] = results
                            this.props.setAiopsPropsDetails('anomalyTabDetails', obj)
                        }
                    } else {
                        this.setState({ eventMessageLoader: false },
                            () => this.props.menuDisabled()
                        )
                    }
                })
            } else {
                let results = this.props.anomalyTabDetails[eventId]
                let totalPages = 1
                if(results.length > this.state.perPage) {
                    totalPages = Math.ceil(results.length / this.state.perPage)
                }

                this.setState({
                    anomlayList: results,
                    filteredList: results,
                    eventMessageLoader: false,
                    totalPages
                },
                    () => this.props.menuDisabled()
                )
            }
            this.setState({ startTime, endTime, eventTime })
        }
    }

    navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = this.state.startRecord
            if(action === 'next' && currentPage !== this.state.totalPages) {
                startRecord = startRecord + this.state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - this.state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = this.state.totalPages
                startRecord = (this.state.totalPages - 1) * this.state.perPage
            }
            this.setState({ currentPage, startRecord })
        }
	}

    render() {
        return (
            <div className="mt-2">
                <div className="d-flex mb-2 justify-content-between">
                    <div className="mr-2">
                        <p className="f12 m-0 align-self-center pt-1">Showing {this.state.filteredList && this.state.filteredList.length} of total {this.state.anomlayList && this.state.anomlayList.length}{this.state.anomlayList && this.state.anomlayList.length > 1 ? ' events' : ' event'}</p>
                        <p className="f12 m-0 align-self-center pt-1 text-info">{this.state.startTime ? momentConvertionUtcToLocalTime(this.state.startTime, 'DD MMM HH:mm') +' - '+ momentConvertionUtcToLocalTime(this.state.endTime, 'DD MMM YYYY HH:mm') : ''}</p>
                    </div>
                    <div className="justify-content-end mb-n2 align-self-end">
                        {this.state.filteredList && this.state.filteredList.length ?
                            <Search
                                data={this.state.anomlayList ? this.state.anomlayList : []}
                                applyTags={false}
                                applyLiteDarkTags={true}
                                topClassName={'bg-black5 border-gray5 rounded-5 f12'}
                                searchClassName={'px-2 f12'}
                                searchIconColor={'text-gray5 f12'}
                                searchPlaceHolder={'Search anomaly....'}
                                className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                filteredData={(filteredList) => this.setState({ filteredList })}
                            />
                        : null}
                        {this.state.filteredList && this.state.filteredList.length > this.state.perPage ?
                            <div className="pagination errorPagePagination f12 mt-n1">
                                <span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
                                <button><i className={`fal fa-arrow-to-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('start', this.state.currentPage)}></i></button> 
                                <button><i className={`fal fa-angle-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('previous', this.state.currentPage)}></i></button> 
                                <button><i className={`fal fa-angle-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
                                <button><i className={`fal fa-arrow-to-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
                            </div>
                        : null}
                    </div>
                </div>
                <div className={`rounded bg-muted p-3 tabWithSearchScroll`}>
                    {this.state.eventMessageLoader ? 
                        <div className='d-flex justify-content-center m-4'>
                            <Spinner className='text-center' color='white' size='lg' />
                        </div>
                    : this.state.filteredList && this.state.filteredList.length ?
                        this.state.filteredList.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map((item, i) => {
                            let drawLine = 0
                            if(item.anomaly_time < this.state.eventTime && this.drawLine === 0) {
                                drawLine = 1
                                this.drawLine = 1
                            } else if(!i && item.anomaly_time > this.state.eventTime) {
                                drawLine = 1
                                this.drawLine = 1
                            }
                            return(
                                <React.Fragment>
                                    {drawLine ?
                                        <div className={`d-flex justify-content-end my-1`}>
                                            <div className="line"></div>
                                            <div className="ml-3 arrow-left"></div>
                                        </div>
                                    : null}
                                    <div className={`d-flex flex-wrap mr-2 px-2 mb-2 pb-1 pt-1 border-bottom-black5`}>
                                        <p className="mb-0">
                                            <span className="mb-0 mr-2 f12 text-white font-weight-bold">{item.anomaly_time ? momentConvertionUtcToLocalTime(item.anomaly_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                            <span className="mb-0 mr-2 f12 font-weight-bold text-purple-2">Anomaly</span>
                                            {/* <span className={`mb-0 mr-2 f12 ${item.event_source === 'checks' ? 'text-purple' : item.event_source === 'config' ? 'text-secondary' : (item.event_source === 'cloudtrail' || item.event_source === 'cloudwatch_alarm') ? 'text-info' : 'text-gray3'} font-weight-bold`}>{capitalizeFirstLetter(item.event_source) + (item.event_name ? ' : ' + item.event_name : '') + (item.event_type ? ' : '+item.event_type : '')}</span> */}
                                            <span className={`mb-0 mr-2 f12 text-purple-2`}>{item.anomaly_name + '  '+ (item.anomaly_type ? ' : ' + item.anomaly_type : '') + ' score '+ (item.anomaly_score ? ' : '+item.anomaly_score : 0)}</span>
                                            {item.asset_name && item.asset_name ? 
                                                <span className="mb-0 mr-2 f12 text-white font-weight-bold">{item.asset_name}</span>
                                            : null}
                                            <span className="mb-0 small text-primary-color mr-2">Resource</span>
                                            {item.account_id ?
                                                <span className="mb-0 f12 text-primary-color font-weight-bold">{' '+item.account_id}</span>
                                            : null}
                                            {item.region ?
                                                <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+item.region}</span>
                                            : null}
                                            {item.anomaly_message ?
                                                <span className="mb-0 ml-2 f12 text-white font-weight-bold">{item.anomaly_message}</span>
                                            : null}
                                        </p>
                                    </div>
                                </React.Fragment>                                       
                            )
                        })
                    :
                        <div className='d-flex justify-content-center m-4'>
                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
AnomalyTab.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log('errorDashboard',state)
    return {
        tabOptions: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.tabOptions ? state.aiops.aiopsPropsDetails.tabOptions : {},
        anomalyTabDetails: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.anomalyTabDetails ? state.aiops.aiopsPropsDetails.anomalyTabDetails : {},
    }
}

export default connect(mapStateToProps, {
    setAiopsPropsDetails,
    listObservabilityAnomalyResults
})(withRouter(AnomalyTab))        