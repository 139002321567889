/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file compilanceDasboard.js
 * @author Prakash // on 5/06/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import PropTypes from 'prop-types'

import {
	getComplianceControlDetails,
	getComplianceCoverageAndTopStats } from '../../../actions/dlp/complianceAction'
	
import SidePanel from './SidePanel_Athena'
import SearchControls from '../SearchControls'
import _ from 'lodash'

import StackedCircleBarChart from '../../common/charts/StackedCircleBarChart'
import ApexBarChart from '../../common/charts/ApexBarChart'
import ApexRiskStackedBarChart from '../../common/charts/ApexRiskStackedBarChart'
import ApexDonutChart from '../../common/charts/ApexDonutChart'

const DonutColors = [
	['#24A597', '#393F4E'],
	['#775BA2', '#393F4E'],
	['#3F73AD', '#393F4E'],
	['#88792E', '#393F4E'],
	['#5F5BA2', '#393F4E']
]

class Service_Athena extends Component {
    
	leftRadialStackedBarWidthRef = React.createRef()
	leftSideTableRef = React.createRef()
	
	rightRadialStackedBarWidthRef = React.createRef()
	rightSideTableRef = React.createRef()

    constructor(props) {
        super(props)
        this.state = {
			showLoading: false,
			failSum: 0,

			radialStackbarType: 'Compress',			
			rightSideStateName: 'parentData',
			rightSideChartType: 'detections_by_entity',
			leftSideStateName: 'parentData',
			leftSideChartType: 'detections_by_deidentification_technique',

			detectionType: [
				{key: 'Entity', value: 'detections_by_entity'},
				{key: 'Deidentification', value: 'detections_by_deidentification_technique'},
				{key: 'Risk', value: 'detections_by_risk'}
			]
        }
    }
    
    componentDidMount = () => {
		if(this.props.complianceFilters && this.props.complianceFilters.selectedCompliance) {
			this.setState({ 
				showLoading: true,
				selectedProvider: this.props.complianceFilters.selectedProvider,
				selectedAccount: this.props.complianceFilters.selectedAccount,
				selectedRegion: this.props.complianceFilters.selectedRegion,
				selectedCompliance: this.props.complianceFilters.selectedCompliance,
				selectedService: this.props.complianceFilters.selectedService,
				start_time: this.props.complianceFilters.start_time,
				end_time: this.props.complianceFilters.end_time,
			},
				() => {
					this.getComplianceControlDetails()
					this.getComplianceCoverageAndTopStats()
					this.getInitializePaginationRight() //initialize table paginfation for right side
					this.getInitializePaginationLeft() //initialize table paginfation for left side
				}
			)
		}
	}

	componentDidUpdate = (prevProps) => {
		if(this.props.complianceFilters && this.props.complianceFilters !== prevProps.complianceFilters) {
			this.setState({ 
				showLoading: true,
				selectedProvider: this.props.complianceFilters.selectedProvider,
				selectedAccount: this.props.complianceFilters.selectedAccount,
				selectedRegion: this.props.complianceFilters.selectedRegion,
				selectedCompliance: this.props.complianceFilters.selectedCompliance,
				selectedService: this.props.complianceFilters.selectedService,
				start_time: this.props.complianceFilters.start_time,
				end_time: this.props.complianceFilters.end_time,
			},
				() => {
					this.getComplianceControlDetails()
					this.getComplianceCoverageAndTopStats()
					this.getInitializePaginationRight() //initialize table paginfation for right side
					this.getInitializePaginationLeft() //initialize table paginfation for left side
				}
			)
		}
	}

	getComplianceControlDetails = () =>{

		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		if (this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
			params.account_id = this.state.selectedAccount
        }
		if (this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
			params.region = this.state.selectedRegion
        }
		let compliance = []
		// if(this.state.selectedCompliance.length && !this.state.selectedCompliance.includes('All')) {
		// 	this.state.selectedCompliance.forEach(item => {
		// 		let compRow ={
		// 			complianceId: item
		// 		}
		// 		compliance.push(compRow)
		// 	})
		// }
		let compRow = {
			complianceId: this.state.selectedCompliance
		}
		compliance.push(compRow)
		params.compliance = compliance
		params.service = this.state.selectedService
		params.start_time = this.state.start_time
		params.end_time = this.state.end_time
		
		this.props.getComplianceControlDetails(params, (promise, response) => {
			if (promise) {
				let complianceControlDetails = []
				if(response.controls) {
					complianceControlDetails = response.controls
				}
				this.setState({ complianceControlDetails, filteredArray: complianceControlDetails });
			} else {
				this.setState({ complianceControlDetails: [], filteredArray: [] })
			}
		})
	}

	getComplianceCoverageAndTopStats = () => {
		let detectionType = this.state.detectionType
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		if (this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
			params.account_id = this.state.selectedAccount
        }
		if (this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
			params.region = this.state.selectedRegion
        }
		let compliance = []
		// if(this.state.selectedCompliance.length && !this.state.selectedCompliance.includes('All')) {
		// 	this.state.selectedCompliance.forEach(item => {
		// 		let compRow ={
		// 			complianceId: item
		// 		}
		// 		compliance.push(compRow)
		// 	})
		// }
		let compRow = {
			complianceId: this.state.selectedCompliance
		}
		compliance.push(compRow)
		params.compliance = compliance
		params.service = this.state.selectedService


		params.start_time = this.state.start_time
		params.end_time = this.state.end_time
	
		this.props.getComplianceCoverageAndTopStats(params, (promise, response) => {
			if(promise) {
				let detectionCoverage = response.detection_coverage ? response.detection_coverage : {} //onload left side coverage donut graph
				let dayWiseCount = response.day_wise_count ? response.day_wise_count : {} //onload left side detection and redaction stacked graph
				let topDetections = response.top_detections ? response.top_detections : [] // onload left side top 5 detections horizontal graph
				let topDeidentifications = response.top_deidentifications ? response.top_deidentifications : [] // onload left side top 5 deidentifications horizontal graph
				let detections_by_entity = response.top_detections_by_entity ? response.top_detections_by_entity : {} //onload right side radial stacked, trend, table and donut graph
				let detections_by_deidentification_technique = response.top_detections_by_deidentification_technique ? response.top_detections_by_deidentification_technique : {} //onchange right side radial stacked, trend, table and donut graph
				let detections_by_risk = response.top_detections_by_risk ? response.top_detections_by_risk : {} //onchange right side radial stacked, trend, table and donut graph

				this.setState({ detectionCoverage: [], topDetectionsGraphData: {}, deidentificationDonutData: {}, rightSideTableData: {}, leftSideTableData: {},  detectionType }, 
					() => {
						this.setState({ detectionCoverage, dayWiseCount, topDetections, topDeidentifications, detections_by_entity, detections_by_deidentification_technique, detections_by_risk, showLoading: false },
							() => {
								this.topDetectionsGraph()
								this.topDeidentificationsGraph()
								this.onChangeGetcharts('')
							}    
						)
					}
				)
			} else {
				this.setState({ showLoading: false })
			}
		})

		setTimeout(() => { this.setState({ showLoading: false }) }, 5000)
	}	

	topDetectionsGraph = () => {
		if(this.state.topDetections && this.state.topDetections.length) {
			let data = []
			let labels = []
			this.state.topDetections.forEach(item => {
				data.push(item.count)
				labels.push(item.detection)
			})
			for(let i=this.state.topDetections.length; i<5; i++) {
				data.push(0)
				labels.push('empty')
			}

			let topDetectionsGraphData = {}
			topDetectionsGraphData.data = data
			topDetectionsGraphData.labels = labels

			
			this.setState({ topDetectionsGraphData });
		}
	}

	topDeidentificationsGraph = () => {
		if(this.state.topDeidentifications && this.state.topDeidentifications.length) {
			let data = []
			let labels = []
			this.state.topDeidentifications.forEach(item => {
				data.push(item.count)
				labels.push(item.detection)
			})
			for(let i=this.state.topDeidentifications.length; i<5; i++) {
				data.push(0)
				labels.push('empty')
			}

			let topDeidentificationsGraphData = {}
			topDeidentificationsGraphData.data = data
			topDeidentificationsGraphData.labels = labels

			
			this.setState({ topDeidentificationsGraphData });
		}
	}

	onChangeGetcharts = (from) => {
		if(from === 'right' || from === '') {
			this.setState({ showRightSideRadialChart: false, rightSideStateName: 'parentData', showRightSideDonutChart: false, showRightSideTrendChart: false, showRightSideRiskTrendChart: false },
				() => {
					this.renderRightSideRadialBarChart()
				}	
			)
		}

		if(from === 'left' || from === '') {
			this.setState({ showLeftSideRadialChart: false, leftSideStateName: 'parentData', showLeftSideDonutChart: false, showLeftSideTrendChart: false, showLeftSideRiskTrendChart: false },
				() => {
					this.renderLeftSideRadialBarChart()
				}
			)
		}
	}

	renderRightSideRadialBarChart = () => {
		let rightSideGraphColors = []
		let rightSideLegend = 'Detections'
		let rightSideTableHeading = ''
		if(this.state.rightSideChartType === 'detections_by_entity') {
			rightSideGraphColors = ['#024d73', '#026ca0', '#038bce', '#35aeea', '#68c2ef', '#81cdf2', '#9ad7f5', '#b3e1f7', '#cdebfa']
			rightSideTableHeading = 'Detection by Entity'
		} else if(this.state.rightSideChartType === 'detections_by_deidentification_technique') {
			rightSideGraphColors = ["#9029cc", "#a22ee6", "#bc47ff", "#c35cff", "#cb70ff", "#d285ff", "#da99ff", "#e15ad2", "#fb74eb", "#fb83ed", "#fc93f0", "#fca2f2", "#fdb2f4", "#fdc1f6"]
			rightSideTableHeading = 'Detection by Deidentification'
		} else if(this.state.rightSideChartType === 'detections_by_risk') {
			rightSideGraphColors = ['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']
			rightSideTableHeading = 'Detection by Risk'
		}
		let rightSideSummary = ''
		let rightSideArray = []
		let rightSideKeys = []
		let rightSideHighest = 0
		let rightSideTotalData = {}
		let rightSideTotal = 0
		let rightSideTrendData = {}
		let rightSideTopStatsData = {}
		let rightSideGraphId = this.state.rightSideChartType
		let rightSideSvgGraphId = 'svg'+this.state.rightSideChartType

		let graphData = this.state[this.state.rightSideChartType] ? this.state[this.state.rightSideChartType] : {}

		if(graphData && graphData.databases &&  graphData.databases.length) {		
			let bucketData = graphData.databases			
			if(this.state.rightSideChartType === 'detections_by_risk') {
				rightSideKeys = ['Critical', 'High', 'Medium', 'Low']
			} else {
				bucketData.length && bucketData.forEach(item  => {
					if(item.table_wise_count && item.table_wise_count.length) {
						item.table_wise_count.forEach(pref => {
							if(pref.table_wise_detections && Object.entries(pref.table_wise_detections).length) {
								Object.keys(pref.table_wise_detections).forEach(item => {		
									if (!rightSideKeys.includes(item)) {
										rightSideKeys.push(item)
									}
								})
							}
						})
					} else {
						if(item.database_wise_detections && Object.entries(item.database_wise_detections).length) {
							Object.keys(item.database_wise_detections).forEach(item => {		
								if (!rightSideKeys.includes(item)) {
									rightSideKeys.push(item)
								}
							})
						}
					}
				})
			}

			rightSideTrendData['parentData'] = graphData.trend						
			bucketData.forEach((item, index) => {
				if(item.table_wise_count && item.table_wise_count.length) {
					item.table_wise_count.forEach((pref, i) => {
						let State = 'prefix_'+index+'_'+i
						rightSideSummary = pref.summary
						
						// for trend graph
						if(pref.trend) {
							rightSideTrendData[State] = pref.trend
						}

						// for staked circle bar
						let dataRow = {}
						dataRow.State = State
						dataRow.name = (item.database_name ? item.database_name : '')+' '+ (pref.table ? pref.table : '')
						dataRow.total = pref.count

						rightSideTotal += pref.count
						if(rightSideHighest < pref.count) {
							rightSideHighest = pref.count
						}
						if(pref.table_wise_detections && Object.entries(pref.table_wise_detections).length) {
							Object.entries(pref.table_wise_detections).forEach(([key, value]) => {
								dataRow[key] = value
							})
						}
						rightSideKeys.forEach(key => {
							if(!dataRow.hasOwnProperty(key)) {
								dataRow[key] = 0
							}
						})
						rightSideArray.push(dataRow)
					})
				} else {
					let State = 'bucket_'+index
					rightSideSummary = item.summary
					
					// for trend graph
					if(item.trend) {
						rightSideTrendData[State] = item.trend
					}

					// for staked circle bar
					let dataRow = {}
					dataRow.State = State
					dataRow.name = item.database_name ? item.database_name : ''
					dataRow.total = item.count ? item.count : ''

					rightSideTotal += item.count
					if(rightSideHighest < item.count) {
						rightSideHighest = item.count
					}
					if(item.database_wise_detections && Object.entries(item.database_wise_detections).length) {
						Object.entries(item.database_wise_detections).forEach(([key, value]) => {
							dataRow[key] = value
						})
					}
					rightSideKeys.forEach(key => {
						if(!dataRow.hasOwnProperty(key)) {
							dataRow[key] = 0
						}
					})
					rightSideArray.push(dataRow)
				}
			})

			// rightSideArray = _.orderBy(rightSideArray, ['name'], ['desc'])
			
			if(rightSideArray.length < 25) {			
				let length = rightSideArray.length
				for(let i=length; i<=25; i++) {
					let dataRow = {}
					dataRow.State = 'detection_'+i
					dataRow.name = 'empty_data'
					dataRow.total = 0
					rightSideKeys.forEach((key, j) => {
						// if(j===0) {
						// 	dataRow[key] = rightSideHighest < 10 ? .1 : 1
						// } else {
						// 	dataRow[key] = 0
						// }
						dataRow[key] = 0
					})
					rightSideArray.push(dataRow)
				}
			}
			
			let data = {}
			data.name = 'Detection Data'
			rightSideKeys.forEach((key, j) => {
				data[key] = this.count(rightSideArray, key)
			})
			rightSideTotalData.data = data
			
			this.setState({ rightSideGraphColors, rightSideTableHeading, rightSideLegend, rightSideSummary, rightSideArray, rightSideKeys, rightSideHighest, rightSideTotalData, rightSideTotal, rightSideTrendData, rightSideTopStatsData, rightSideGraphId, rightSideSvgGraphId, showRightSideRadialChart: true, },
				() => {
					window.addEventListener('resize', () => {
						this.setState({ showRightSideRadialChart: false },
							() => this.setState({ showRightSideRadialChart: true })    
						)
					})
				}
			)
		} else {
			this.setState({ rightSideGraphColors, rightSideTableHeading, rightSideLegend, rightSideSummary, rightSideArray, rightSideKeys, rightSideHighest, rightSideTotalData, rightSideTotal, rightSideTrendData, rightSideTopStatsData, rightSideGraphId, rightSideSvgGraphId, showRightSideRadialChart: false, showRightSideDonutChart: false, showRightSideTrendChart: false, showRightSideRiskTrendChart: false, rightSideTableData: {} })
		}
	}

	renderLeftSideRadialBarChart = () => {
		let leftSideGraphColors = []
		let leftSideLegend = 'Detections'
		let leftSideTableHeading = ''
		if(this.state.leftSideChartType === 'detections_by_entity') {
			leftSideGraphColors = ['#024d73', '#026ca0', '#038bce', '#35aeea', '#68c2ef', '#81cdf2', '#9ad7f5', '#b3e1f7', '#cdebfa']
			leftSideTableHeading = 'Detection by Entity'
		} else if(this.state.leftSideChartType === 'detections_by_deidentification_technique') {
			leftSideGraphColors = ["#9029cc", "#a22ee6", "#bc47ff", "#c35cff", "#cb70ff", "#d285ff", "#da99ff", "#e15ad2", "#fb74eb", "#fb83ed", "#fc93f0", "#fca2f2", "#fdb2f4", "#fdc1f6"]
			leftSideTableHeading = 'Detection by Deidentification'
		} else if(this.state.leftSideChartType === 'detections_by_risk') {
			leftSideGraphColors = ['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']
			leftSideTableHeading = 'Detection by Risk'
		}
		let leftSideSummary = ''
		let leftSideArray = []
		let leftSideKeys = []
		let leftSideHighest = 0
		let leftSideTotalData = {}
		let leftSideTotal = 0
		let leftSideTrendData = {}
		let leftSideTopStatsData = {}
		let leftSideGraphId = this.state.leftSideChartType
		let leftSideSvgGraphId = 'svg'+this.state.leftSideChartType
		let graphData = this.state[this.state.leftSideChartType] ? this.state[this.state.leftSideChartType] : {}

		if(graphData && graphData.databases &&  graphData.databases.length) {		
			let bucketData = graphData.databases
			if(this.state.leftSideChartType === 'detections_by_risk') {
				leftSideKeys = ['Critical', 'High', 'Medium', 'Low']
			} else {
				bucketData.length && bucketData.forEach(item  => {
					if(item.table_wise_count && item.table_wise_count.length) {
						item.table_wise_count.forEach(pref => {
							if(pref.table_wise_detections && Object.entries(pref.table_wise_detections).length) {
								Object.keys(pref.table_wise_detections).forEach(item => {		
									if (!leftSideKeys.includes(item)) {
										leftSideKeys.push(item)
									}
								})
							}
						})
					} else {
						if(item.database_wise_detections && Object.entries(item.database_wise_detections).length) {
							Object.keys(item.database_wise_detections).forEach(item => {		
								if (!leftSideKeys.includes(item)) {
									leftSideKeys.push(item)
								}
							})
						}
					}
				})
			}

			leftSideTrendData['parentData'] = graphData.trend
						
			bucketData.forEach((item, index) => {
				if(item.table_wise_count && item.table_wise_count.length) {
					item.table_wise_count.forEach((pref, i) => {
						let State = 'prefix_'+index+'_'+i
						leftSideSummary = pref.summary
						
						// for trend graph
						if(pref.trend) {
							leftSideTrendData[State] = pref.trend
						}

						// for staked circle bar
						let dataRow = {}
						dataRow.State = State
						dataRow.name = (item.database_name ? item.database_name : '')+' '+ (pref.table ? pref.table : '')
						dataRow.total = pref.count

						leftSideTotal += pref.count
						if(leftSideHighest < pref.count) {
							leftSideHighest = pref.count
						}
						if(pref.table_wise_detections && Object.entries(pref.table_wise_detections).length) {
							Object.entries(pref.table_wise_detections).forEach(([key, value]) => {
								dataRow[key] = value
							})
						}
						leftSideKeys.forEach(key => {
							if(!dataRow.hasOwnProperty(key)) {
								dataRow[key] = 0
							}
						})
						leftSideArray.push(dataRow)
					})
				} else {
					let State = 'bucket_'+index
					leftSideSummary = item.summary
					
					// for trend graph
					if(item.trend) {
						leftSideTrendData[State] = item.trend
					}

					// for staked circle bar
					let dataRow = {}
					dataRow.State = State
					dataRow.name = item.database_name ? item.database_name : ''
					dataRow.total = item.count ? item.count : ''

					leftSideTotal += item.count
					if(leftSideHighest < item.count) {
						leftSideHighest = item.count
					}
					if(item.database_wise_detections && Object.entries(item.database_wise_detections).length) {
						Object.entries(item.database_wise_detections).forEach(([key, value]) => {
							dataRow[key] = value
						})
					}
					leftSideKeys.forEach(key => {
						if(!dataRow.hasOwnProperty(key)) {
							dataRow[key] = 0
						}
					})
					leftSideArray.push(dataRow)
				}
			})

			// leftSideArray = _.orderBy(leftSideArray, ['name'], ['desc'])
			
			if(leftSideArray.length < 25) {			
				let length = leftSideArray.length
				for(let i=length; i<=25; i++) {
					let dataRow = {}
					dataRow.State = 'detection_'+i
					dataRow.name = 'empty_data'
					dataRow.total = 0
					leftSideKeys.forEach((key, j) => {
						// if(j===0) {
						// 	dataRow[key] = 1
						// } else {
						// 	dataRow[key] = 0
						// }
						dataRow[key] = 0
					})
					leftSideArray.push(dataRow)
				}
			}

			
			let data = {}
			data.name = 'Detection Data'
			leftSideKeys.forEach((key, j) => {
				data[key] = this.count(leftSideArray, key)
			})
			leftSideTotalData.data = data
			
			this.setState({ leftSideGraphColors, leftSideTableHeading, leftSideLegend, leftSideSummary, leftSideArray, leftSideKeys, leftSideHighest, leftSideTotalData, leftSideTotal, leftSideTrendData, leftSideTopStatsData, leftSideGraphId, leftSideSvgGraphId, showLeftSideRadialChart: true, showLeftSideTrendChart: true },
				() => {
					window.addEventListener('resize', () => {
						this.setState({ showLeftSideRadialChart: false },
							() => this.setState({ showLeftSideRadialChart: true })    
						)
					})
				}
			)
		} else {
			this.setState({ leftSideGraphColors, leftSideTableHeading, leftSideLegend, leftSideSummary, leftSideArray, leftSideKeys, leftSideHighest, leftSideTotalData, leftSideTotal, leftSideTrendData, leftSideTopStatsData, leftSideGraphId, leftSideSvgGraphId, showLeftSideRadialChart: false, showLeftSideDonutChart: false, showLeftSideTrendChart: false, leftSideTableData: {} })
		}
	}

	count = (array, key) => {
		array = array.filter(arr => arr.name !== 'empty_data')
		return array.reduce(function (r, a) {
			return r + a[key];
		}, 0);
	}	

	getInitializePaginationRight = () => {
		this.setState({
			rightSidePageCount: 1,
			currentPage: 1,
			rightSideStart: 0, 
			rightSidePerPage: 5,
			disableLeftAction: true,
			disableRightAction: false,
		})
	}

	rightSideDataFormat = (data, State) => {
		let rightSideStateName = this.state.rightSideStateName === State ? 'parentData' : (State ? State : 'parentData')
		let tableData = this.state.rightSideArray.filter(arr => arr.State === rightSideStateName)
		let rightSideTableData = tableData && tableData.length ? tableData[0] : (this.state.rightSideTotalData ? this.state.rightSideTotalData.data : {})
		
		let propsToRemove = ['name', 'State', 'total']
		rightSideTableData = this.removeProperties(data, ...propsToRemove)

		let rightSideDataLength = Object.entries(rightSideTableData).length
		let rightSidePageCount = 1
		let rightSidePageCountArray = []
		let ritPageStart = 0
		let ritPageEnd = 1

		if(rightSideDataLength > this.state.rightSidePerPage) {
			rightSidePageCount = Math.ceil(rightSideDataLength / this.state.rightSidePerPage)
			ritPageEnd = rightSidePageCount > 5 ? 5 : rightSidePageCount
			for(let i=0;i<rightSidePageCount;i++) {
				rightSidePageCountArray.push(i+1)
			}
		}

		let rightSideDonutData = {}
		let donutItems = []
		let totalCount = 0
		if(this.state.rightSideChartType === 'detections_by_risk') {
			let tableSortData = {}
			this.state.rightSideKeys.forEach(item => {
				let donutRow = {}
				donutRow.item_name = item
				donutRow.item_count = rightSideTableData[item] ? rightSideTableData[item] : 0
				donutItems.push(donutRow)
				totalCount += rightSideTableData[item] ? rightSideTableData[item] : 0
				
				tableSortData[item] = rightSideTableData[item]
			})

			rightSideTableData = tableSortData
		} else {
			Object.entries(rightSideTableData).length && Object.entries(rightSideTableData).forEach(([key, value]) => {
				let donutRow = {}
				donutRow.item_name = key
				donutRow.item_count = value
				donutItems.push(donutRow)
				totalCount += value
			})
			donutItems = _.orderBy(donutItems, ['item_count'], ['desc'])

			rightSideTableData = _(rightSideTableData).toPairs().orderBy([1], ['desc']).fromPairs().value()
		}

		if(donutItems.length) {
			rightSideDonutData.items =  donutItems
			rightSideDonutData.label = 'Detections'
			rightSideDonutData.total_count = totalCount
		}
		this.setState({ showRightSideDonutChart: false },
			() => this.setState({ 
				ritPageStart,
				ritPageEnd,
				rightSidePageCount,
				rightSidePageCountArray,
				rightSideTableData, 
				
				rightSideStateName,
				showRightSideRadialChart: true,
				showRightSideTrendChart: this.state.rightSideChartType !== 'detections_by_risk' ? true : false,
				showRightSideRiskTrendChart: this.state.rightSideChartType === 'detections_by_risk' ? true : false,

				showRightSideDonutChart: true, 
				rightSideDonutData,
			})
		)
	}

	removeProperties = (object, ...keys) => {
		return Object.entries(object).reduce((prev, [key, value]) => ({...prev, ...(!keys.includes(key) && { [key]: value }) }), {})
	}

	navigatePage = (action, currentPage) => {
		let ritPageStart = 0
		let ritPageEnd = this.state.rightSidePageCount > 5 ? 5 : this.state.rightSidePageCount

		let startRecord = this.state.rightSideStart
		if(action === 'next') {
			startRecord = startRecord + this.state.rightSidePerPage
			currentPage = currentPage + 1
		} else if(action === 'previous' && currentPage !== 1) {
			currentPage = currentPage - 1
			startRecord = startRecord - this.state.rightSidePerPage
		} else if(action === 'start' && currentPage !== 1) {
			currentPage = 1
			startRecord = 0
		} else if(action === 'end') {
			currentPage = this.state.rightSidePageCount
			ritPageStart = this.state.ritPageStart + (currentPage - 5)
			ritPageEnd = this.state.rightSidePageCount
			startRecord = (this.state.rightSidePageCount - 1) * this.state.rightSidePerPage
		} else if(action === 'page') {
			startRecord = (currentPage - 1) * this.state.rightSidePerPage
		}	
		
		if(this.state.rightSidePageCount > 5) {
			if(currentPage > 2 && (action === 'next' || action === 'page')) {
				ritPageStart = this.state.ritPageStart + 1
				ritPageEnd = this.state.ritPageEnd + 1
			}

			if(currentPage < 3 && (action === 'previous' || action === 'page') && this.state.ritPageStart > 1) {
				ritPageStart = this.state.ritPageStart - 1
				ritPageEnd = this.state.ritPageEnd - 1
			}

			if(ritPageEnd > this.state.rightSidePageCount) {
				ritPageStart = this.state.ritPageStart
				ritPageEnd = this.state.rightSidePageCount
			}
		}

		let disableLeftAction = true
		if(currentPage > 1) {
			disableLeftAction = false
		}

		let disableRightAction = false
		if(currentPage === this.state.rightSidePageCount) {
			disableRightAction = true
		}

		this.setState({ currentPage, ritPageStart, ritPageEnd, rightSideStart: startRecord, disableLeftAction, disableRightAction },
            () => this.rightSideTableRef.current.scrollIntoView()    
        )
	}

	getInitializePaginationLeft = () => {
		this.setState({ 
			leftSidePageCount: 1,
			leftSideCurrentPage: 1,
			leftSideStart: 0, 
			leftSidePerPage: 5,
			disableNextAction: true,
			disablePreviousAction: true,
		})
	}

	leftSideDataFormat = (data, State) => {
		let leftSideStateName = this.state.leftSideStateName === State ? 'parentData' : (State ? State : 'parentData')
		let tableData = this.state.leftSideArray.filter(arr => arr.State === leftSideStateName)
		let leftSideTableData = tableData && tableData.length ? tableData[0] : (this.state.leftSideTotalData ? this.state.leftSideTotalData.data : {})
		
		let propsToRemove = ['name', 'State', 'total']
		leftSideTableData = this.removeProperties(data, ...propsToRemove)

		let leftSideDataLength = Object.entries(leftSideTableData).length
		let leftSidePageCount = 1
		let leftSidePageCountArray = []
		let leftPageStart = 0
		let leftPageEnd = 1

		if(leftSideDataLength > this.state.leftSidePerPage) {
			leftSidePageCount = Math.ceil(leftSideDataLength / this.state.leftSidePerPage)
			leftPageEnd = leftSidePageCount > 5 ? 5 : leftSidePageCount
			for(let i=0;i<leftSidePageCount;i++) {
				leftSidePageCountArray.push(i+1)
			}
		}

		let leftSideDonutData = {}
		let donutItems = []
		let totalCount = 0
		if(this.state.leftSideChartType === 'detections_by_risk') {
			let tableSortData = {}
			this.state.leftSideKeys.forEach(item => {
				let donutRow = {}
				donutRow.item_name = item
				donutRow.item_count = leftSideTableData[item] ? leftSideTableData[item] : 0
				donutItems.push(donutRow)
				totalCount += leftSideTableData[item] ? leftSideTableData[item] : 0

				tableSortData[item] = leftSideTableData[item]
			})
			
			leftSideTableData = tableSortData
		} else {
			Object.entries(leftSideTableData).length && Object.entries(leftSideTableData).forEach(([key, value]) => {
				let donutRow = {}
				donutRow.item_name = key
				donutRow.item_count = value
				donutItems.push(donutRow)
				totalCount += value
			})
			donutItems = _.orderBy(donutItems, ['item_count'], ['desc'])

			leftSideTableData = _(leftSideTableData).toPairs().orderBy([1], ['desc']).fromPairs().value()
		}
		if(donutItems.length) {
			leftSideDonutData.items = donutItems
			leftSideDonutData.label = 'Detections'
			leftSideDonutData.total_count = totalCount
		}
		this.setState({ showLeftSideDonutChart: false },
			() => this.setState({ 
				leftPageStart,
				leftPageEnd,
				leftSidePageCount,
				leftSidePageCountArray,
				leftSideTableData, 

				showLeftSideRadialChart: true,
				leftSideStateName, 
				showLeftSideTrendChart: this.state.leftSideChartType !== 'detections_by_risk' ? true : false,
				showLeftSideRiskTrendChart: this.state.leftSideChartType === 'detections_by_risk' ? true : false,
				
				showLeftSideDonutChart: true,
				leftSideDonutData,
			})
		)
	}

	navigateLeftSidePage = (action, leftSideCurrentPage) => {
		let leftPageStart = 0
		let leftPageEnd = this.state.leftSidePageCount > 5 ? 5 : this.state.leftSidePageCount

		let startRecord = this.state.leftSideStart
		if(action === 'next') {
			startRecord = startRecord + this.state.leftSidePerPage
			leftSideCurrentPage = leftSideCurrentPage + 1
		} else if(action === 'previous' && leftSideCurrentPage !== 1) {
			leftSideCurrentPage = leftSideCurrentPage - 1
			startRecord = startRecord - this.state.leftSidePerPage
		} else if(action === 'start' && leftSideCurrentPage !== 1) {
			leftSideCurrentPage = 1
			startRecord = 0
		} else if(action === 'end') {
			leftSideCurrentPage = this.state.leftSidePageCount
			leftPageStart = this.state.leftPageStart + (leftSideCurrentPage - 5)
			leftPageEnd = this.state.leftSidePageCount
			startRecord = (this.state.leftSidePageCount - 1) * this.state.leftSidePerPage
		} else if(action === 'page') {
			startRecord = (leftSideCurrentPage - 1) * this.state.leftSidePerPage
		}	
		
		if(this.state.leftSidePageCount > 5) {
			if(leftSideCurrentPage > 2 && (action === 'next' || action === 'page')) {
				leftPageStart = this.state.leftPageStart + 1
				leftPageEnd = this.state.leftPageEnd + 1
			}

			if(leftSideCurrentPage < 3 && (action === 'previous' || action === 'page') && this.state.leftPageStart > 1) {
				leftPageStart = this.state.leftPageStart - 1
				leftPageEnd = this.state.leftPageEnd - 1
			}

			if(leftPageEnd > this.state.leftSidePageCount) {
				leftPageStart = this.state.leftPageStart
				leftPageEnd = this.state.leftSidePageCount
			}
		}

		let disablePreviousAction = true
		if(leftSideCurrentPage > 1) {
			disablePreviousAction = false
		}

		let disableNextAction = false
		if(leftSideCurrentPage === this.state.leftSidePageCount) {
			disableNextAction = true
		}

		this.setState({ leftSideCurrentPage, leftPageStart, leftPageEnd, leftSideStart: startRecord, disablePreviousAction, disableNextAction },
            () => this.leftSideTableRef.current.scrollIntoView()    
        )
	}

    render() {
		return (
			<div className="">
				{this.state.showSideView ? (            
					<SidePanel
						closeViewSidePanel={() => this.setState({ showSideView: false })} 
						selectedProvider = {this.state.selectedProvider}
						start_time = {this.state.start_time && this.state.start_time}
						end_time = {this.state.end_time && this.state.end_time}
						majorControls = {this.state.majorControls ? this.state.majorControls : {}}
						selectedService = {this.state.selectedService}
						selectedCompliance = {this.state.selectedCompliance}
					/>
				) : null}
				<div className={`loaderOverlay ${this.state.showLoading ? '' : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
				<div className='row h-100'>
					<div className="col-sm-12">
						<div className={`rounded mb-3 ${this.state.radialStackbarType !== 'Expand' ? 'p-3 bg-muted' : ''}`}>
							<div className="row text-white">
								<div className={`col-md-6 h-100 ${this.state.radialStackbarType !== 'Expand' ? '' : 'displayNone'}`}>
									<div className="bg-dark p-3 rounded counts_threads">
										<div className="row mb-2">
											<div className="col-sm-6 d-flex h-100">
												<div className="d-flex justify-content-between">
													{this.state.radialStackbarType !== 'Expand' && this.state.detectionCoverage && this.state.detectionCoverage.length && this.state.detectionCoverage.map((item, index) => {
														return(
															<div className={`${index ? 'ml-2' : ''}`}>
																<ApexDonutChart
																	graphData={item}
																	legend={false}
																	showTotal={true}
																	showTotalLable={true}
																	showGraphHeading={false}
																	height={100}
																	width={100}
																	size={'70%'}
																	gradient={false}
																	colors={DonutColors[index]}
																	gradientColor={['#A88CCC', '#D88ACF']}
																	className={"transparentTooltip"}
																/>
															</div>
														)
													})}						
												</div>
											</div>
											<div className="col-sm-6 align-self-center">
												<h6>Lorem Ipsum</h6>
												<p className="small text-muted">Officia amet eiusmod eu sunt tempor</p>
												<div className="d-flex flex-column mb-3">
													<div className="d-flex">
														<h2 className="mr-3 border-right pr-3 mb-0 align-self-center text-white">
														686</h2>
														<div>
															<p className="mb-0 text-primary font-weight-bold">Sit Amet</p>
															<p className="small align-self-end mb-0">Sed in semper ex. Nulla
																sed finibus</p>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="row mb-2">
											<div className="col-sm-6">
												<p><b>Top Detections</b></p>
												{this.state.radialStackbarType !== 'Expand' && this.state.topDetectionsGraphData && Object.entries(this.state.topDetectionsGraphData).length ?
													<div className="mt-n2"> 
														<ApexBarChart
															graphData={this.state.topDetectionsGraphData ? this.state.topDetectionsGraphData : {}}
															sparkline={false}
															yaxis={false}
															yaxisLabel={false}
															xaxis={true}
															xaxisFormat={'string'}
															xaxisLabel={true}
															axisLabelColor={'#B8BBBE'}
															paddingLeft={-25}
															legend={false}
															stacked={false}
															height={280}
															horizontal={true}
															barHeight={'40%'}
															barEndShape={'rounded'}
															columnWidth={'25%'}
															gradient={true}
															gradientColor={['#039BE5', '#5F5BA2']}
															hideTooltipValue={true}
															backgroundBarShape={'rounded'}
															backgroundBarColors={['#333947']}
															showBackgroundBarColors={false}
															className={"transparentTooltip"}
														/>
													</div>
												: null}
											</div>
											<div className="col-sm-6">
												<p><b>Top Deidentification</b></p>
												{this.state.radialStackbarType !== 'Expand' && this.state.topDeidentificationsGraphData && Object.entries(this.state.topDeidentificationsGraphData).length ?
													<div className="mt-n2"> 
														<ApexBarChart
															graphData={this.state.topDeidentificationsGraphData ? this.state.topDeidentificationsGraphData : {}}
															sparkline={false}
															yaxis={false}
															yaxisLabel={false}
															xaxis={true}
															xaxisFormat={'string'}
															xaxisLabel={true}
															axisLabelColor={'#B8BBBE'}
															paddingLeft={-25}
															legend={false}
															stacked={false}
															height={280}
															horizontal={true}
															barHeight={'40%'}
															barEndShape={'rounded'}
															columnWidth={'25%'}
															gradient={true}
															gradientColor={['#039BE5', '#5F5BA2']}
															hideTooltipValue={true}
															backgroundBarShape={'rounded'}
															backgroundBarColors={['#333947']}
															showBackgroundBarColors={false}
															className={"transparentTooltip"}
														/>
													</div>
												: null}
											</div>
										</div>
									</div>
								</div>
								<div className={`col-sm-6 h-100 borderMiddleBlue ${this.state.radialStackbarType === 'Expand' ? '' : 'displayNone'}`} ref={this.leftSideTableRef}>
									<div className="bg-muted p-3 rounded h-100">
										<div className="col-md-12">
											<div className="row">
												<div className="col-lg-6 p-0" ref={this.leftRadialStackedBarWidthRef}>
													<div className="d-flex">
														<label className="align-self-center mr-2 mb-0">Detection By</label>
														<div className={`form-group bd-highlight mb-sm-1 styled-feild w-30 ${this.state.radialStackbarType === 'Expand' ? '' : 'displayNone'}`}>
															<select className="form-control" 
																value={this.state.leftSideChartType}
																onChange={event => {
																	this.setState(
																		{
																			leftSideChartType: event.target.value
																		},
																		() => {
																			this.onChangeGetcharts('left')
																			this.getInitializePaginationLeft()
																		}
																	)
																}}>
																{this.state.detectionType.map(item => {
																	return (
																		item.value !== this.state.rightSideChartType ?
																			<option value={item.value}>{item.key}</option>
																		: null
																	)
																})}
																
															</select>
														</div>
													</div>
													<p className="small text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</p>
													{this.state.showLeftSideRadialChart ? 
														<StackedCircleBarChart
															ID={this.state.leftSideGraphId}
															svgID={this.state.leftSideSvgGraphId}
															width={this.leftRadialStackedBarWidthRef.current.offsetWidth}
															data={this.state.leftSideArray}
															keys={this.state.leftSideKeys}
															highestValue={this.state.leftSideHighest}
															hoverData={this.state.leftSideTotalData}
															totalValue={this.state.leftSideTotal}
															selectedState={this.state.leftSideStateName ? this.state.leftSideStateName : ''}
															tableData={(data, State) => 
																this.setState({ 
																	showLeftSideRadialChart: !State ? true : false,
																	showLeftSideTrendChart: this.state.leftSideChartType !== 'detections_by_risk' ? (!State ? true : false) : false,
																	showLeftSideRiskTrendChart: this.state.leftSideChartType === 'detections_by_risk' ? (!State ? true : false) : false,
																	// showLeftSideDonutChart: this.state.leftSideDonutData ? true : false
																},  
																	() => {
																		if(State || (!State && this.state.leftSideStateName === 'parentData')) {
																			this.leftSideDataFormat(data, State)
																		}
																	}
																)
															}
															legendText={this.state.leftSideLegend}
															colors={this.state.leftSideGraphColors}
														/>
													: null}
													{this.state.showLeftSideTrendChart ?
														<div className="mt-4 displayNone">
															<ApexBarChart
																graphData={this.state.leftSideTrendData ? this.state.leftSideTrendData[this.state.leftSideStateName] : {}}
																sparkline={false}
																yaxis={false}
																xaxis={true}
																xaxisLabel={true}
																axisLabelColor={'#B8BBBE'}
																paddingLeft={-5}
																legend={false}
																stacked={false}
																height={150}
																horizontal={false}
																barHeight={'40%'}
																barEndShape={'flat'}
																barRadius={4}
																columnWidth={'25%'}
																gradient={true}
																gradientColor={['#19B2C0', '#CBF2D5']}
																backgroundBarShape={'flat'}
																backgroundBarColors={['#333947']}
																showBackgroundBarColors={true}
																className={"transparentTooltip"}
															/>
														</div>
													: null}
													{this.state.showLeftSideRiskTrendChart ?
														<div className="mt-4 displayNone"> 
															<ApexRiskStackedBarChart
																graphData={this.state.leftSideTrendData ? this.state.leftSideTrendData[this.state.leftSideStateName] : {}}
																graphRiskCountSection={false}
																sparkline={false}
																yaxis={false}
																yaxisLabel={false}
																xaxis={true}
																xaxisFormat={'date'}
																xaxisLabel={true}
																axisLabelColor={'#B8BBBE'}
																legend={false}
																stacked={true}
																height={150}
																horizontal={false}
																barHeight={'40%'}
																barEndShape={'flat'}
																columnWidth={'25%'}
																backgroundBarShape={'rounded'}
																backgroundBarColors={['#333947']}
																showBackgroundBarColors={true}
																className={"transparentTooltip"}
																colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']}
															/>
														</div>
													: null}
												</div>
												<div className="col-lg-6 mt-4">
													<div className={`${this.state.radialStackbarType === 'Expand' ? '' : 'displayNone'}`}>
														{/* <p className="mb-1">{this.state.leftSideSummary ? this.state.leftSideSummary : ''}</p> */}
														<p className="small mb-0">
															Lorem ipsum dolor sit amet, 
															<p className="text-muted small">(adipiscing elit, sed do eiusmod tempor incididunt ut)</p>
														</p>
														<div className="d-flex text-warning">
															<h4 className="mb-0 mr-3">0325</h4>
															<h3 className="mb-0 mr-2"><i className="fad fa-map-marker-alt"></i></h3>
															<h3 className="mb-0 mr-2"><i className="fad fa-map-marker-alt"></i></h3>
															<h3 className="mb-0 mr-2"><i className="fad fa-map-marker-alt"></i></h3>
															<div className="flex-row ml-1">
																<p className="mb-0 text-nowrap small">2032, 2031</p>
																<p className="mb-0 text-nowrap small">2032, 2031</p>
															</div>
														</div>
														<div className="radialTableSection mt-3" style={{minHeight: "305px"}}>
															{this.state.leftSideTableData && Object.entries(this.state.leftSideTableData).length ?
																<div className="" style={{minHeight: "260px"}}>
																	{Object.entries(this.state.leftSideTableData).slice(this.state.leftSideStart, (this.state.leftSideStart + this.state.leftSidePerPage)).map(([key, value]) => {
																		return(
																			<div className="mt-2 col-12 p-0 bg-dark">
																				<div className="count border-info d-flex p-2">
																					<h5 className="m-0 w-20">{value}</h5>
																					<p className="m-0 align-self-end ml-2 small">{key}</p>
																				</div>
																			</div>
																		)
																	})}
																</div>
															: null}
															{this.state.leftSideTableData && Object.entries(this.state.leftSideTableData).length && this.state.leftSidePageCount > 1 ?
																<div className="pagination mt-3 justify-content-center d-flex">
																	<span className={`${this.state.disablePreviousAction ? 'disabled text-muted' : ''}`} onClick={(e) => {e.preventDefault(); this.navigateLeftSidePage('start', this.state.leftSideCurrentPage)}}><i className="fas fa-chevron-double-left"></i></span>
																	<span className={`${this.state.disablePreviousAction ? 'disabled text-muted' : ''}`} onClick={(e) => {e.preventDefault(); this.navigateLeftSidePage('previous', this.state.leftSideCurrentPage)}}><i className="fas fa-chevron-left"></i></span>

																	{this.state.leftSidePageCountArray.slice(this.state.leftPageStart, this.state.leftPageEnd).map((pag, index) => {
																		return(
																			<span  className={`${this.state.leftSideCurrentPage === pag ? 'active' : ''}`} onClick={(e) => {e.preventDefault(); this.navigateLeftSidePage('page', (index+1))}}>{pag}</span>
																		)
																	})}
																	<span className={`${this.state.disableNextAction ? 'disabled text-muted' : ''}`} onClick={(e) => {e.preventDefault(); this.navigateLeftSidePage('next', this.state.leftSideCurrentPage)}}><i className="fas fa-chevron-right"></i></span>
																	<span className={`${this.state.disableNextAction ? 'disabled text-muted' : ''}`} onClick={(e) => {e.preventDefault(); this.navigateLeftSidePage('end', this.state.leftSideCurrentPage)}}><i className="fas fa-chevron-double-right"></i></span>
																</div>
															: null}
														</div>
														<div className={`mt-4 displayNone`} style={{minHeight: "240px"}}>
															{this.state.radialStackbarType === 'Expand' && this.state.showLeftSideDonutChart ?
																<React.Fragment>
																<p className="mb-0 mt-2 small">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
																<div className="d-flex justify-content-center mt-4">
																	<ApexDonutChart
																		graphData={this.state.leftSideDonutData}
																		legend={false}
																		showTotal={true}
																		showTotalLable={true}
																		showGraphHeading={true}
																		height={120}
																		width={120}
																		size={'75%'}
																		gradient={false}
																		gradientColor={['#A88CCC', '#D88ACF']}
																		colors={this.state.leftSideGraphColors}
																		className={"transparentTooltip"}
																	/>
																</div>
																</React.Fragment>
															: null}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className={`col-md-6 h-100 mb-n4`} ref={this.rightSideTableRef}>
									<div className={`${this.state.radialStackbarType === 'Expand' ? 'bg-muted p-3 rounded' : ''}`}>
										<div className="col-md-12">
											<div className="row">
												<div className={`col-lg-6 p-0`} ref={this.rightRadialStackedBarWidthRef}>
													{this.state.radialStackbarType === 'Expand' ?
														<div className="d-flex">
															<label className="align-self-center mr-2 mb-0">Detection By</label>
															<div className={`form-group bd-highlight mb-sm-1 styled-feild w-30 ${this.state.radialStackbarType === 'Expand' ? '' : 'displayNone'}`}>
																<select className="form-control" 
																	value={this.state.rightSideChartType}
																	onChange={event => {
																		this.setState(
																			{
																				rightSideChartType: event.target.value
																			},
																			() => {
																				this.onChangeGetcharts('right')
																				this.getInitializePaginationLeft()
																			}
																		)
																	}}>
																	{this.state.detectionType.map(item => {
																		return (
																			item.value !== this.state.leftSideChartType ?
																				<option value={item.value}>{item.key}</option>
																			: null
																		)
																	})}
																	
																</select>
															</div>
														</div>
													: 
														<p className="mb-0">{this.state.rightSideTableHeading}</p>
													}
													<p className="small text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</p>
													{this.state.showRightSideRadialChart ? 
														<StackedCircleBarChart
															ID={this.state.rightSideGraphId}
															svgID={this.state.rightSideSvgGraphId}
															width={
																this.state.radialStackbarType === 'Expand' ? 
																	this.leftRadialStackedBarWidthRef.current.offsetWidth
																: 
																	this.rightRadialStackedBarWidthRef.current.offsetWidth
															}
															data={this.state.rightSideArray}
															keys={this.state.rightSideKeys}
															highestValue={this.state.rightSideHighest}
															hoverData={this.state.rightSideTotalData}
															totalValue={this.state.rightSideTotal}
															selectedState={this.state.rightSideStateName ? this.state.rightSideStateName : ''}
															tableData={(data, State) => 
																this.setState({ 
																	showRightSideRadialChart: !State ? true : false,
																	showRightSideTrendChart: this.state.rightSideChartType !== 'detections_by_risk' ? (!State ? true : false) : false,
																	showRightSideRiskTrendChart: this.state.rightSideChartType === 'detections_by_risk' ? (!State ? true : false) : false,
																	// showRightSideDonutChart: this.state.leftSideDonutData ? true : false
																},  
																	() => {
																		if(State || (!State && this.state.rightSideStateName === 'parentData')) {
																			this.rightSideDataFormat(data, State)
																		}
																	}
																)
															}
															legendText={this.state.rightSideLegend}
															colors={this.state.rightSideGraphColors}
														/>
													: null}
													{this.state.showRightSideTrendChart ?
														<div className="mt-4 displayNone">
															<ApexBarChart
																graphData={this.state.rightSideTrendData ? this.state.rightSideTrendData[this.state.rightSideStateName] : {}}
																sparkline={false}
																yaxis={false}
																xaxis={true}
																xaxisLabel={true}
																axisLabelColor={'#B8BBBE'}
																paddingLeft={-5}
																legend={false}
																stacked={false}
																height={150}
																horizontal={false}
																barHeight={'40%'}
																barEndShape={'flat'}
																barRadius={6}
																columnWidth={'25%'}
																gradient={true}
																gradientColor={['#19B2C0', '#CBF2D5']}
																backgroundBarShape={'flat'}
																backgroundBarColors={['#333947']}
																showBackgroundBarColors={true}
																className={"transparentTooltip"}
															/>
														</div>
													: null}
													{this.state.showRightSideRiskTrendChart ?
														<div className="mt-4 displayNone"> 
															<ApexRiskStackedBarChart
																graphData={this.state.rightSideTrendData ? this.state.rightSideTrendData[this.state.rightSideStateName] : {}}
																graphRiskCountSection={false}
																sparkline={false}
																yaxis={false}
																yaxisLabel={false}
																xaxis={true}
																xaxisFormat={'date'}
																xaxisLabel={true}
																axisLabelColor={'#B8BBBE'}
																legend={false}
																stacked={true}
																height={150}
																horizontal={false}
																barHeight={'40%'}
																barEndShape={'flat'}
																columnWidth={'25%'}
																backgroundBarShape={'rounded'}
																backgroundBarColors={['#333947']}
																showBackgroundBarColors={true}
																className={"transparentTooltip"}
																colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']}
															/>
														</div>
													: null}
												</div>
												<div className="col-lg-6">
													{this.state.radialStackbarType !== 'Expand' ?
														<span className={`justify-content-end d-flex`}>
															<i className="fa fa-expand-wide cursorPointer" 
																onClick={() => this.setState({ radialStackbarType: 'Expand', leftSideChartType: 'detections_by_deidentification_technique' },
																	() => {
																		this.onChangeGetcharts('left')
																		this.onChangeGetcharts('right')
																	}
																)}
															></i>
														</span>
													:
														<span className={`justify-content-end d-flex`}>
															<i className="fa fa-compress cursorPointer" 
																onClick={() => this.setState({ radialStackbarType: 'Compress', rightSideChartType: 'detections_by_entity'},
																	() => {
																		this.onChangeGetcharts('right')
																	}
																)}
															></i>
														</span>
													}
													
													{/* <p className="small mb-1">{this.state.rightSideSummary ? this.state.rightSideSummary : ''}</p> */}
													<p className="small mb-0 mt-2">
														Lorem ipsum dolor sit amet, 
														<p className="text-muted small">(adipiscing elit, sed do eiusmod tempor incididunt ut)</p>
													</p>
													<div className="d-flex text-warning">
														<h4 className="mb-0 mr-3">0325</h4>
														<h3 className="mb-0 mr-2"><i className="fad fa-map-marker-alt"></i></h3>
														<h3 className="mb-0 mr-2"><i className="fad fa-map-marker-alt"></i></h3>
														<h3 className="mb-0 mr-2"><i className="fad fa-map-marker-alt"></i></h3>
														<div className="flex-row ml-1">
															<p className="mb-0 text-nowrap small">2032, 2031</p>
															<p className="mb-0 text-nowrap small">2032, 2031</p>
														</div>
													</div>
													<div className="radialTableSection mt-3" style={{minHeight: "305px"}}>
														{this.state.rightSideTableData && Object.entries(this.state.rightSideTableData).length ?
															<div className="" style={{minHeight: "260px"}}>
																{Object.entries(this.state.rightSideTableData).slice(this.state.rightSideStart, (this.state.rightSideStart + this.state.rightSidePerPage)).map(([key, value]) => {
																	return(
																		<div className="mt-2 col-12 p-0 bg-dark">
																			<div className="count border-info d-flex p-2">
																				<h5 className="m-0 w-20">{value}</h5>
																				<p className="m-0 align-self-end ml-2 small">{key}</p>
																			</div>
																		</div>
																	)
																})}
															</div>
														: null}
														{this.state.rightSideTableData && Object.entries(this.state.rightSideTableData).length && this.state.rightSidePageCount > 1 ?
															<div className="pagination mt-3 justify-content-center d-flex">
																<span className={`${this.state.disableLeftAction ? 'disabled text-muted' : ''}`} onClick={(e) => {e.preventDefault(); this.navigatePage('start', this.state.currentPage)}}><i className="fas fa-chevron-double-left"></i></span>
																<span className={`${this.state.disableLeftAction ? 'disabled text-muted' : ''}`} onClick={(e) => {e.preventDefault(); this.navigatePage('previous', this.state.currentPage)}}><i className="fas fa-chevron-left"></i></span>

																{this.state.rightSidePageCountArray.slice(this.state.ritPageStart, this.state.ritPageEnd).map((pag, index) => {
																	return(
																		<span  className={`${this.state.currentPage === pag ? 'active' : ''}`} onClick={(e) => {e.preventDefault(); this.navigatePage('page', (index+1))}}>{pag}</span>
																	)
																})}
																<span className={`${this.state.disableRightAction ? 'disabled text-muted' : ''}`} onClick={(e) => {e.preventDefault(); this.navigatePage('next', this.state.currentPage)}}><i className="fas fa-chevron-right"></i></span>
																<span className={`${this.state.disableRightAction ? 'disabled text-muted' : ''}`} onClick={(e) => {e.preventDefault(); this.navigatePage('end', this.state.currentPage)}}><i className="fas fa-chevron-double-right"></i></span>
															</div>
														: null}
													</div>
													<div className={`mt-4 displayNone`} style={{minHeight: "240px"}}>
														{this.state.showRightSideDonutChart ? 
															<React.Fragment>
															<p className="mb-0 mt-2 small">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
															<div className="d-flex justify-content-center mt-4">
																<ApexDonutChart
																	graphData={this.state.rightSideDonutData}
																	legend={false}
																	showTotal={true}
																	showTotalLable={true}
																	showGraphHeading={true}
																	height={120}
																	width={120}
																	size={'75%'}
																	gradient={false}
																	gradientColor={['#A88CCC', '#D88ACF']}
																	colors={this.state.rightSideGraphColors}
																	className={"transparentTooltip"}
																/>
															</div>
															</React.Fragment>
														: null}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						{this.state.complianceControlDetails ?
							<div className="row">
								<div className="col-sm-12">
									<div className="bg-gray5 p-2 rounded">
										<div className="col-sm-12 ">
											<div className="row">
												<div className="col-sm-8">
													<p className="font-weight-bold text-white mb-0">Status by controls</p>
													<p className="small f12 mb-2 text-gray3">Find the category wise listing of identified vulnerabilities on account for the audit policy <b>{this.state.selectedCompliance}</b></p>
												</div>                                            
												<div className="col-sm-4">
													{this.state.complianceControlDetails.length ?
														<SearchControls
															data={this.state.complianceControlDetails && this.state.complianceControlDetails}
															filteredData={filteredArray => { this.setState({ filteredArray }) }}
															className={'dlpSerachControlsPlaceholder'}
															inputClassName={'text-gray5 bg-white4'}
															searchIconColor={'text-white4'}
														/>
													: null}
												</div>
											</div>
										</div>
										<table className="table">
											<tbody>
												{this.state.filteredArray && this.state.filteredArray.length ?
													this.state.filteredArray.map((item,i) =>{
														return(
															<tr key={i}>
																<td className='align-middle w-60'>
																	<p className="mb-1 fw500 text-white"><span className="badge-square bg-dark p-2 text-wrap text-white mr-1 f13">{item.control}</span></p>
																	<p className="small text-white4 m-0">{item.description}</p>
																</td>
																<td className='align-middle w-30'>
																	{/* <h5 className="font-weight-bold mb-0 text-info">{item.detail}</h5> */}
																	<p className="font-weight-bold text-white f12 mb-0">{item.count_description}</p>
																</td>
																<td className='align-middle text-right'>
																	<div className="dflex">
																		<button className="btn btn-primary btn-sm text-right" onClick={() => this.setState({ showSideView: true, majorControls: item })}>Details</button>
																		{/* <span className="link cursorPointer" onClick={() => this.closeIssue(item.control, item.description, item.sub_control.length ? item.sub_control : item.minor_control, item.sub_control.length ? 'authoritySubControl' : 'authorityMinorControl')}> <span className="text-muted"> / </span> Close</span> */}
																	</div>
																</td>
															</tr>
														)
													})  
												: 
													<div className='d-flex justify-content-center m-4 text-white'>
														<p>Your search did not match any records. Please try different keywords.</p>
													</div>
												}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						: null}
					</div>
				</div>
			</div>
        )
    }
}

/**
 * Type of the props used in the component
*/
Service_Athena.propTypes = { 
	getComplianceControlDetails: PropTypes.func,
	getComplianceCoverageAndTopStats: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		complianceFilters: state.dlp.dlpPropsDetails.complianceFilters
	}
	
}

export default connect(mapStateToProps, {
	getComplianceControlDetails,
	getComplianceCoverageAndTopStats,
})(withRouter(Service_Athena))