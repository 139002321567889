/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ChecksTab.js
 * @author Prakash // on 04/06/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { getLatestIdentifierResults } from '../../../actions/detectors/detectorsAction'

import _ from 'lodash'
import { CSVLink } from "react-csv";
import { momentDateGivenFormat, momentConvertionLocalToUtcTime, capitalizeAllLetter, dayDiffrence, momentConvertionUtcToLocalTime, capitalizeFirstLetter, capitalizeTheFirstLetterOfEachWord } from '../../../utils/utility'

import Search from '../../common/SearchComponent'

class ChecksTab extends Component {
    constructor(props) {
        super(props)
        this.toggleRef = React.createRef()
        
        this.state = {
            checksListLoader: true,
            
            startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
			perPage: 100,

            selectedSeverity: "Critical/High/Medium/Low"
        }
    }

	componentDidMount = () => {
        if(this.props.leftSectionSelection && Object.entries(this.props.leftSectionSelection).length) {
            this.setState({ 
                start_time: this.props.leftSectionSelection.start_time,
                end_time: this.props.leftSectionSelection.end_time,
                brushStartTime: this.props.leftSectionSelection.brushStartTime ? this.props.leftSectionSelection.brushStartTime : this.props.leftSectionSelection.start_time,
                brushEndTime: this.props.leftSectionSelection.brushEndTime ? this.props.leftSectionSelection.brushEndTime : this.props.leftSectionSelection.end_time,
                selectedShowBy: this.props.leftSectionSelection.selectedShowBy ? this.props.leftSectionSelection.selectedShowBy : "",
                columnLabel: this.props.leftSectionSelection.columnLabel ? this.props.leftSectionSelection.columnLabel : "",
                columnValue: this.props.leftSectionSelection.columnValue ? this.props.leftSectionSelection.columnValue : "",
                selectedField: this.props.leftSectionSelection.selectedField ? this.props.leftSectionSelection.selectedField : "",
                allFields: this.props.leftSectionSelection.allFields ? this.props.leftSectionSelection.allFields : [],
                selectedGroup: this.props.leftSectionSelection.selectedGroup ? this.props.leftSectionSelection.selectedGroup : "",
                // selectedSeverity: this.props.leftSectionSelection.selectedGroup && this.props.leftSectionSelection.selectedGroup ? "Critical/High" : "",
                pageType: this.props.leftSectionSelection.pageType ? this.props.leftSectionSelection.pageType : "",
                checksListLoader: true,
                graphData: {}
            },
                () => {
                    this.getLatestIdentifierResults()
                    this.hideLoading()
                }
            )
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.leftSectionSelection && Object.entries(this.props.leftSectionSelection).length) {
            if(!_.isEqual(prevProps.leftSectionSelection, this.props.leftSectionSelection)) {
                this.setState({ 
                    start_time: this.props.leftSectionSelection.start_time,
                    end_time: this.props.leftSectionSelection.end_time,
                    brushStartTime: this.props.leftSectionSelection.brushStartTime ? this.props.leftSectionSelection.brushStartTime : this.props.leftSectionSelection.start_time,
                    brushEndTime: this.props.leftSectionSelection.brushEndTime ? this.props.leftSectionSelection.brushEndTime : this.props.leftSectionSelection.end_time,
                    selectedShowBy: this.props.leftSectionSelection.selectedShowBy ? this.props.leftSectionSelection.selectedShowBy : "",
                    columnLabel: this.props.leftSectionSelection.columnLabel ? this.props.leftSectionSelection.columnLabel : "",
                    columnValue: this.props.leftSectionSelection.columnValue ? this.props.leftSectionSelection.columnValue : "",
                    selectedField: this.props.leftSectionSelection.selectedField ? this.props.leftSectionSelection.selectedField : "",
                    allFields: this.props.leftSectionSelection.allFields ? this.props.leftSectionSelection.allFields : [],
                    selectedGroup: this.props.leftSectionSelection.selectedGroup ? this.props.leftSectionSelection.selectedGroup : "",
                    // selectedSeverity: this.props.leftSectionSelection.selectedGroup && this.props.leftSectionSelection.selectedGroup ? "Critical/High" : "",
                    checksListLoader: true,
                    graphData: {},
                },
                    () => {
                        this.getLatestIdentifierResults()
                        this.hideLoading()
                    }
                )
            } else if(this.props.dashboardDetails && Object.entries(this.props.dashboardDetails).length) {
                if(!_.isEqual(prevProps.dashboardDetails, this.props.dashboardDetails)) {
                    this.setState({ 
                        start_time: this.props.dashboardDetails.start_time,
                        end_time: this.props.dashboardDetails.end_time,
                        brushStartTime: this.props.dashboardDetails.brushStartTime ? this.props.dashboardDetails.brushStartTime : this.props.dashboardDetails.start_time,
                        brushEndTime: this.props.dashboardDetails.brushEndTime ? this.props.dashboardDetails.brushEndTime : this.props.dashboardDetails.end_time,                        
                        checksListLoader: true,
                    },
                        () => {
                            if(this.state.columnValue) {
                                this.getLatestIdentifierResults()
                                this.hideLoading()
                            }
                        }
                    )
                }
            }
        } else if(!_.isEqual(prevProps.leftSectionSelection, this.props.leftSectionSelection)) {
            this.setState({ 
                columnValue: "",
                checksListLoader: true
            },
                () => {
                    this.getLatestIdentifierResults()
                }
            )
        }
    }

    navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = this.state.startRecord
            if(action === 'next' && currentPage !== this.state.totalPages) {
                startRecord = startRecord + this.state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - this.state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = this.state.totalPages
                startRecord = (this.state.totalPages - 1) * this.state.perPage
            }
            this.setState({ currentPage, startRecord })
        } 
	}

    gotoPage = (pageNumber) => {
        if(pageNumber > 0  && pageNumber <= this.state.totalPages) {
            let startRecord = (pageNumber - 1) * this.state.perPage
            this.setState({ currentPage: pageNumber, pageNumber, startRecord })
        } else  if(pageNumber > this.state.totalPages) {
            this.setState({ pageNumber: this.state.currentPage })  
        } else {
            this.setState({ pageNumber })
        }
    }

    getLatestIdentifierResults = () => {
        
        let params = {}
        params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:00')
        params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:00')
        if(this.state.selectedShowBy === "Assets") {
            params.asset_id = this.state.columnValue
        } else {
            params.resource_type = this.state.columnValue
        }

        params.risk = this.state.selectedSeverity.split('/')
        if(this.state.pageType === "Checks") {
            if(this.state.selectedField !== "") {
                params.risk = this.state.selectedField
            }
        } else {
            if(this.state.selectedField !== "") {
                params.compliance = {
                    complianceId: this.state.selectedField
                }
            } else {
                params.compliance = {
                    complianceId: this.state.allFields
                }
            }
        }

        if(this.state.columnValue && this.state.columnValue !== "") {
            this.props.getLatestIdentifierResults(params, (promise, response) => {
                if (promise) {
                    let results = response && response.results && response.results.length ? response.results : []
                    
                    let totalPages = 1
                    if(results.length > this.state.perPage) {
                        totalPages = Math.ceil(results.length / this.state.perPage)
                    }

                    this.setState({ identifierResults: results, totalFilteredArray: results, filteredArray: results, checksListLoader: false, totalPages, startRecord: 0, currentPage: 1 },
                        () => {                            
                            this.formHeaders()
                        }    
                    )
                } else {
                    this.setState({ identifierResults: [], totalFilteredArray: [], filteredArray: [], checksListLoader: false })
                }
            })
        } else {
            this.setState({ identifierResults: [], totalFilteredArray: [], filteredArray: [], checksListLoader: false })
        }
    }

	formHeaders = () => {
		let dataResult = this.state.filteredArray && this.state.filteredArray.length ? this.state.filteredArray : []
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === "string") {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})
			this.setState({ headers })
		}
	}

    hideLoading = () => {
        setTimeout(() => { this.setState({ checksListLoader: false }) }, 5000)
    }

    handleClickOutside(event) {
        // if (this.toggleRef && !this.toggleRef.current.contains(event.target)) {
        //     this.setState({ showPageTypeOptions: false })
        // } else {
        //     this.setState({ showPageTypeOptions: true })
        // }
    }

	render() {
		return (
            <div className="" onClick={(event) => this.handleClickOutside(event)}>
                <div className="d-flex mt-2">
                    <div className="rounded bg-dark p-0 col-sm-12">
                        <div className="d-flex mb-2 justify-content-between">
                            <div className="d-flex mr-2">
                                <p className="d-flex flex-wrap m-0 align-self-center pt-1">
                                    <span className={`small mt-2`}>Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.state.totalFilteredArray && this.state.totalFilteredArray.length} check(s) with severity of</span> 
                                    {this.state.selectedField && this.state.selectedField !== "" && this.state.selectedGroup === "severity" ? 
                                        <span className={`small ml-1 mt-2 d-flex align-self-center risk-${this.state.selectedField.toLowerCase()}`}>{this.state.selectedField}</span>
                                    : 
                                        <div class={`transparentSelectBorderBottom ml-1 align-self-center`}>
                                            <select class="form-control" 
                                            onChange={e => this.setState({ selectedSeverity: e.target.value, checksListLoader: true }, () => this.getLatestIdentifierResults())}
                                            value={this.state.selectedSeverity}
                                            >
                                                <option value="Critical/High/Medium/Low">All</option>
                                                <option value="Critical/High">Critical/High</option>
                                                <option value="Critical">Critical</option>
                                                <option value="High">High</option>
                                                <option value="Medium">Medium</option>
                                                <option value="Low">Low</option>
                                            </select>
                                        </div>
                                    }
                                    {this.state.selectedField && this.state.selectedField !== "" && this.state.selectedGroup !== "risk" ?
                                        <span className={`small ml-1 mt-2 d-flex align-self-center risk-${this.state.selectedField.toLowerCase()}`}>{this.state.selectedField}</span>
                                    : null}
                                    {this.state.selectedGroup && this.state.selectedGroup !== "risk" ?
                                        <span className={`ml-1 f12 text-primary-color mt-2`}>{this.state.selectedGroup && this.state.selectedGroup !== "" ? this.state.selectedGroup.replace("_", " ") : ""}</span>
                                    : null}
                                    <span className={`small align-self-center text-info ml-1 mt-2`}>between </span>
                                    <span className={`small align-self-center text-info ml-1 mt-2`}>{this.state.brushStartTime ? momentDateGivenFormat(this.state.brushStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.brushEndTime, 'DD MMM YYYY HH:mm') : ""}</span>
                                </p>
                            </div>
                            {this.state.totalFilteredArray && this.state.totalFilteredArray.length ?
                                <div className="d-flex">
                                    <CSVLink 
                                        data={this.state.filteredArray ? this.state.filteredArray : []} 
                                        headers={this.state.headers ? this.state.headers : []}
                                        filename={"resource-alerts.csv"}
                                        className={"mt-2"}
                                        target="_blank"
                                    >
                                        <span className="d-flex text-primary-color mr-2">
                                            <i className="fas fa-download text-primary-color mr-2"></i>
                                            {/* csv */}
                                        </span>
                                    </CSVLink>
                                    <div className="justify-content-end w-100 mb-n2">
                                        <Search
                                            data={this.state.totalFilteredArray ? this.state.totalFilteredArray : []}
                                            applyTags={false}
                                            applyLiteDarkTags={true}
                                            topClassName={'bg-black5 align-self-center w-100 mr-1 border-gray5 rounded-5 f12'}
                                            searchClassName={'px-2 f12'}
                                            searchIconColor={'text-gray5 f12'}
                                            searchPlaceHolder={'Search....'}
                                            className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                            filteredData={(filteredArray) => this.setState({ filteredArray },
                                                () => {
                                                    if(this.state.filteredArray.length) {
                                                        // this.getAlertDetails(this.state.filteredArray[0])
                                                    }
                                                }
                                            )}
                                        />
                                        {this.state.filteredArray && this.state.filteredArray.length > this.state.perPage ?
                                            <div className="pagination errorPagePagination f12">
                                                <span className="d-flex mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
                                                <button><i className={`fal fa-arrow-to-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('start', this.state.currentPage)}></i></button> 
                                                <button><i className={`fal fa-angle-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('previous', this.state.currentPage)}></i></button> 
                                                {/* <span>
                                                    Go to
                                                    <input
                                                        type='number'
                                                        value={this.state.pageNumber || this.state.pageNumber === "" ? this.state.pageNumber : this.state.currentPage}
                                                        onChange={e => {
                                                            const page = e.target.value ? Number(e.target.value) : ""
                                                            this.gotoPage(page)
                                                        }}
                                                        className="ml-1 inputClass"
                                                    />
                                                </span> */}
                                                <button><i className={`fal fa-angle-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
                                                <button><i className={`fal fa-arrow-to-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
                                            </div>
                                        : null}
                                    </div>
                                </div>
                            : null} 
                        </div>
                        
                        
                        <div className="d-flex">
                            <div className={`w-100`}>
                                <div className={`bg-dark3 py-3 pl-2 obsMessageSectionXL`}>
                                    {this.state.checksListLoader ? 
                                        <div className='d-flex justify-content-center m-4'>
                                            <Spinner className='text-center' color='white' size='lg' />
                                        </div>
                                    : this.state.filteredArray && this.state.filteredArray.length ?
                                        this.state.filteredArray.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map((item, i) => {
                                            return(
                                                <React.Fragment>
                                                <div className={`px-2 mb-2 pb-1 border-bottom-black5 ${this.state.selectedIdentifierId && this.state.selectedIdentifierId === item.identifier_id ? "bg-muted" : ""}`}> 
                                                    <div className="d-flex cursorPointer" onClick={() => this.setState({ selectedIdentifierId: item.identifier_id })}>
                                                        <span className={`f10 mr-2 align-self-start mt-1 badge-sm risk-badge-${item.risk ? item.risk.toLowerCase() : 'critical'}`}>
                                                            {item.risk ? 
                                                                (item.risk.toLowerCase() === 'critical' ? 'C' : item.risk.toLowerCase() === 'high' ? 'H' : item.risk.toLowerCase() === 'medium' ? 'M' : 'L') 
                                                            : 'U'}
                                                        </span>
                                                        <p className="mb-0">
                                                            <span className="mb-0 mr-2 text-white f12 font-weight-bold text-primary-color">{item.timestamp ? momentConvertionUtcToLocalTime(item.timestamp, 'DD MMM YYYY HH:mm') : ""} </span>
                                                            {/* <span className="f12 mb-0 mr-2 text-purple-2"> {item.total_alerts+ (item.total_alerts>1 ?' alerts':' alert')}</span>  */}
                                                            <span className={`mb-0 mr-2 f12 text-purple-2`}>
                                                                {/* {item.event_source ? (Array.isArray(item.event_source) ? item.event_source.join(", ") : item.event_source) : ""} */}
                                                                {item.event_name ? item.event_name : ""}
                                                                {/* {item.event_type ? ' : '+ item.event_type : ""} */}
                                                            </span>
                                                            {item.resource_id ?
                                                                <span className="mb-0 mr-2 f12 text-white font-weight-bold">{item.resource_id}</span>
                                                            : null}
                                                            <span className="mb-0 small mr-1 text-primary-color">Resource</span>
                                                            {item.account_id ?
                                                                <span className="mb-0 f12 text-primary-color font-weight-bold">{' '+item.account_id}</span>
                                                            : null}
                                                            {item.region ?
                                                                <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+item.region}</span>
                                                            : null}
                                                            {item.resource_type ?
                                                                <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+(Array.isArray(item.resource_type) ? item.resource_type.join(', ') : item.resource_type)}</span>
                                                            : null}
                                                            {item.service_name ?
                                                                <span className="mb-0 f12 text-primary-color font-weight-bold mr-2">{' : '+item.service_name}</span>
                                                            : null}
                                                            <span className="mb-0 small mr-1 text-primary-color">Category</span>
                                                            {item.category && Array.isArray(item.category) ? 
                                                                item.category.map((item, index) => {
                                                                    return (
                                                                        <span className={`f10 mr-2 align-self-start mt-1 badge badge-secondary`}>
                                                                            {item}
                                                                        </span>
                                                                    )
                                                                })
                                                            : item.category ?
                                                                <span className={`f10 mr-2 align-self-start mt-1 badge badge-secondary`}>{item.category}</span>
                                                            : null}
                                                            <span className="mb-0 small mr-1 text-primary-color">Status</span>
                                                            {item.status ?
                                                                <span className={`f10 mr-2 align-self-start mt-1 badge ${item.status === "fail" ? "badge-outline-fail" : "badge-outline-pass"}`}>{capitalizeFirstLetter(item.status)}</span>
                                                            : null}
                                                            <span className="mb-0 small mr-1 text-info">Compliance</span>
                                                            <span className="mb-0 f12 font-weight-bold mr-2">
                                                                {item.compliance && item.compliance.length &&
                                                                    _.chain(item.compliance)
                                                                    .groupBy('complianceId')
                                                                    .map((value, key) => ({ label: key, sub: value }))
                                                                    .value()
                                                                    .map((compliance, i) => {
                                                                        //let array = []
                                                                        return (
                                                                            <span className="mr-1">
                                                                                <span className="mr-1 p-0 m-0 f12 text-info">{capitalizeAllLetter(compliance.label)}:</span>
                                                                                <span className="p-0 m-0 f12 align-self-center text-primary-color">
                                                                                {compliance.sub.map((item, index) => {
                                                                                    let value = item.authoritySubControl ? item.authoritySubControl : item.authorityMinorControl ? item.authorityMinorControl : item.authorityMajorControl ? item.authorityMajorControl : ''
                                                                                    return ( index ? ', '+value : value)
                                                                                })}
                                                                                </span>
                                                                            </span>
                                                                        )
                                                                    })
                                                                }
                                                            </span>
                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">
                                                                {item.resolution_status_info ? 
                                                                    item.resolution_status_info.status === 'resolved' ? 
                                                                        <React.Fragment>
                                                                            <span className="mb-0 f12 text-primary-color font-weight-bold mr-2">Resolved Date:</span>
                                                                            <span className="mb-0 f12 text-primary-color font-weight-bold mr-2">{momentConvertionUtcToLocalTime(item.resolution_status_info.timestamp, 'DD MMM YYYY HH:mm')}</span>
                                                                            </React.Fragment>
                                                                    :
                                                                        <React.Fragment>
                                                                            <span className="mb-0 f12 text-primary-color font-weight-bold mr-2">Number of days issue is Pending: </span>
                                                                            <span className="mb-0 f12 text-primary-color font-weight-bold mr-2">{dayDiffrence(item.resolution_status_info.timestamp)}</span>
                                                                        </React.Fragment>
                                                                : null}
                                                            </span>
                                                            {item.details ?
                                                                <span className="mb-0 f12 text-white font-weight-bold mr-2">{item.details}</span>
                                                            : item.description ?
                                                                <span className="mb-0 f12 text-white font-weight-bold mr-2">{item.description}</span> 
                                                            : null}
                                                        </p>
                                                    </div>
                                                    {this.state.selectedIdentifierId && this.state.selectedIdentifierId === item.event_id ?
                                                        <div className="rounded bg-dark3 p-3">
                                                            <div className="accordion" id="accordionExample">
                                                                <div className={`card bg-dark border-0 mb-2`}>
                                                                    <div id={'heading_event'} onClick={() => this.setState({ expandAlertDetails: !this.state.expandAlertDetails })}>
                                                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
                                                                            <div className="text-white"><span className={`far ${this.state.expandAlertDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Alert Details</div>
                                                                            {/* <div className="text-info">Showing event details</div> */}
                                                                        </div>
                                                                    </div>
                                                                    <div id={'collapse_event'} className={`collapse ${this.state.expandAlertDetails ? 'show' : ""}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
                                                                        <div className="card-body">
                                                                            <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
                                                                                <div>
                                                                                    <p className="mb-0 f16 text-white">{this.state.selectedAlert.event_name ? this.state.selectedAlert.event_name : ""}</p>
                                                                                    <p className="mb-0 f12">{this.state.selectedAlert.description ? this.state.selectedAlert.description : ""}</p>
                                                                                </div>
                                                                                <div>
                                                                                    <p className="b-block mb-0">Severity:</p>
                                                                                    <span className={`mr-2 badge ${this.state.selectedAlert.severity ? 'risk-badge-'+this.state.selectedAlert.severity.toLowerCase() : 'badge-secondary' }`}>  
                                                                                        {this.state.selectedAlert.severity ? capitalizeFirstLetter(this.state.selectedAlert.severity) : 'Unknown'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex mb-1 border-bottom">
                                                                                <div className="py-1 w-50">
                                                                                    <p className="b-block mb-0">Alert Time:</p>
                                                                                    <p className="mb-0 text-white">
                                                                                        {this.state.selectedAlert.type === 'alert' ?
                                                                                            this.state.selectedAlert.event_time ? momentConvertionUtcToLocalTime(this.state.selectedAlert.event_time, 'DD MMM YYYY HH:mm') : ""
                                                                                        :
                                                                                            this.state.selectedAlert.created_at ? momentConvertionUtcToLocalTime(this.state.selectedAlert.created_at, 'DD MMM YYYY HH:mm') : ""
                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                                <div className="py-1 w-50 pl-3">
                                                                                    <p className="b-block mb-0">Alert Metrics:</p>
                                                                                    {this.state.selectedAlert.type === 'alert' ?
                                                                                        <p className="mb-0 text-white">
                                                                                            {this.state.selectedAlert.event_source ? this.state.selectedAlert.event_source : ""}
                                                                                            {this.state.selectedAlert.event_type ? (' : ')+this.state.selectedAlert.event_type : ""}
                                                                                        </p>
                                                                                    :        
                                                                                        // <p className="mb-0 text-white">
                                                                                        //     {this.state.selectedAlert.event_type ? this.state.selectedAlert.event_type : ""}
                                                                                        // </p>
                                                                                    null}
                                                                                </div>
                                                                            </div>
                                                                        
                                                                            <div className="d-flex mb-1 border-bottom">
                                                                                <div className="py-1 w-50">
                                                                                    <p className="b-block mb-0">Threshold / Statistic / Period:</p>
                                                                                    <p className="mb-0 text-warning">
                                                                                        {this.state.selectedAlert.threshold && this.state.selectedAlert.threshold}{this.state.selectedAlert.unit ? (this.state.selectedAlert.unit ? (this.state.selectedAlert.unit.toLowerCase() === 'percent' ? ' %' : this.state.selectedAlert.unit) : "") : ""}
                                                                                        {this.state.selectedAlert.statistic ? (' : ')+this.state.selectedAlert.statistic : ""}
                                                                                        {this.state.selectedAlert.period ? (' : ')+this.state.selectedAlert.period : ""}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="py-1 w-50 pl-3">
                                                                                    <p className="b-block mb-0">Provider / Account / Region :</p>
                                                                                    <p className="mb-0 text-white">
                                                                                        {this.state.selectedAlert.provider ? this.state.selectedAlert.provider.toUpperCase() : ""}
                                                                                        {this.state.selectedAlert.account_id ? (' : ')+this.state.selectedAlert.account_id : ""}
                                                                                        {this.state.selectedAlert.region ? (' : ')+this.state.selectedAlert.region : ""}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        
                                                                            <div className="d-flex mb-1 border-top">
                                                                                <div className="py-1 w-50">
                                                                                    <p className="b-block mb-0">Provider / Account / Region :</p>
                                                                                    <p className="mb-0 text-white">
                                                                                        {this.state.selectedAlert.provider ? this.state.selectedAlert.provider.toUpperCase() : ""}
                                                                                        {this.state.selectedAlert.account_id ? (' : ')+this.state.selectedAlert.account_id : ""}
                                                                                        {this.state.selectedAlert.region ? (' : ')+this.state.selectedAlert.region : ""}
                                                                                    </p>
                                                                                </div>
                                                                            </div>                                                                        
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className={`card bg-dark border-0 mb-2`}>
                                                                    <div id={'heading_config_assets'} onClick={() => this.setState({ expandAssetDetails: !this.state.expandAssetDetails })}>
                                                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                                                            <div className="text-white"><span className={`far ${this.state.expandAssetDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Asset Details</div>
                                                                            {/* <div className="text-info">Showing asset details</div> */}
                                                                        </div>
                                                                    </div>
                                                                    <div id={'collapse_config_assets'} className={`collapse ${this.state.expandAssetDetails ? 'show' : ""}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                                                        <div className="card-body">
                                                                            <div className="d-flex mb-2 border-bottom">
                                                                                <div className="py-1 w-50">
                                                                                    <p className="b-block mb-0">Name:</p>
                                                                                    <p className="mb-0 text-white">{this.state.selectedAlert.asset_name ? this.state.selectedAlert.asset_name : ""}</p>
                                                                                </div>
                                                                                <div className="py-1 w-50 pl-3">
                                                                                    <p className="b-block mb-0">Id:</p>
                                                                                    <p className="mb-0 text-white">{this.state.selectedAlert.asset_id ? this.state.selectedAlert.asset_id : ""}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex mb-2">
                                                                                <div className="py-1 w-50">
                                                                                    <p className="b-block mb-0">Resource:</p>
                                                                                    <p className="mb-0 text-white">
                                                                                        {this.state.selectedAlert.provider ? this.state.selectedAlert.provider.toUpperCase() : ""}
                                                                                        {this.state.selectedAlert.account_id ? (" : ")+this.state.selectedAlert.account_id : ""}
                                                                                        {this.state.selectedAlert.region ? (" : ")+this.state.selectedAlert.region : ""}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="py-1 w-50 pl-3">
                                                                                    <p className="b-block mb-0">Services:</p>
                                                                                    <p className="mb-0 text-white">
                                                                                        {this.state.selectedAlert.resource_type ? this.state.selectedAlert.resource_type : ""}
                                                                                        {this.state.selectedAlert.service_name ? (" : ")+this.state.selectedAlert.service_name : ""}
                                                                                    </p>
                                                                                </div>
                                                                            </div>                                                        
                                                                            <div className="d-flex mb-2 border-bottom">
                                                                                <div className="py-1 w-100">
                                                                                    <p className="b-block mb-0">ARN:</p>
                                                                                    <p className="mb-0 text-white">{this.state.selectedAlert.asset_arn ? this.state.selectedAlert.asset_arn : ""}</p>
                                                                                </div>
                                                                            </div>
                                                                            {this.state.selectedAlert && this.state.selectedAlert.category ? 
                                                                                <div className="d-flex mb-2 border-bottom">
                                                                                    <div className="py-1 w-100">
                                                                                        <p className="b-block mb-0">Category:</p>
                                                                                        <div className={`d-flex flex-wrap`}>
                                                                                            {Array.isArray(this.state.selectedAlert.category) ?
                                                                                                this.state.selectedAlert.category.map(item => {
                                                                                                    return (
                                                                                                        <small className={`mr-1 mb-1 badge badge-light`}>{item}</small>
                                                                                                    )
                                                                                                })
                                                                                            : 
                                                                                                <small className={`mr-1 mb-1 badge badge-light`}>{this.state.selectedAlert.category}</small>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            : null}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    : null}
                                                </div>
                                                </React.Fragment>
                                            )
                                        })
                                    :
                                        <div className='d-flex justify-content-center m-4'>
                                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ChecksTab.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('errorDashboard',state)
	return {
        leftSectionSelection: state.assets.resourcesPropsDetails.leftSectionSelection ? state.assets.resourcesPropsDetails.leftSectionSelection : {},
        dashboardDetails: state.assets.resourcesPropsDetails && state.assets.resourcesPropsDetails.dashboardDetails ? state.assets.resourcesPropsDetails.dashboardDetails : {},
    }
}

export default connect(mapStateToProps, {
    getLatestIdentifierResults,
})(withRouter(ChecksTab))