/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Dashboard.js
 * @author Prakash // on 12/11/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { URL_PATH } from '../../config/urlPath'
import { AppWrapper } from '../common/AppWrapper'
// import PropTypes from 'prop-types'
import { UncontrolledTooltip } from 'reactstrap'
import { setAiopsPropsDetails } from '../../actions/aiops/AiopsAction'
import { listAllProviders, listAllAccounts, listAllRegions } from '../../actions/commonAction'
import { getApis } from '../../actions/AutomationAction'
import _ from 'lodash'
// import SearchWithHiddenInput from '../common/SearchWithHiddenInput'
import { momentDateGivenFormat, momentConvertionLocalToUtcTime, currentLocaltime, subDays, twoDateDiffrence, numberFormater, unitTimeConvertion, unitTimeConvertionDetail, capitalizeFirstLetter } from '../../utils/utility'

import Chart from 'react-apexcharts'

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

// import ApexDonutChart from '../common/charts/ApexDonutChart'
import ApexStackedBarChart from '../common/charts/ApexStackedBarChart'
import ApexBarChart from '../common/charts/ApexBarChart'


import AutomationPerView from './AutomationPerView'

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.accountRef = React.createRef()
        this.regionRef = React.createRef()
        this.statusRef = React.createRef()
        this.severityRef = React.createRef()
        this.daterRangeRef = React.createRef()
        this.topStatusRef = React.createRef()
        this.topAutomationRef = React.createRef()
        
        this.state = {
            showLoading: true,

            //filter section start

            selectedProvider: '',

            selectedAccount: [],
            isAccountOpen: false,

            selectedRegion: [],
            isRegionOpen: false,
            
            //filter section end

            selectionRange: [{
				startDate: new Date(),
				endDate: new Date(),
				key: 'selection'
			}],
            // selectedTopStatusFilter: 'Success',

            // activeAutomationTab: 'Application'
        }
    }

	componentDidMount = () => {
        if(this.props.providers && this.props.providers.length) {
			this.setState({ providers: this.props.providers, selectedProvider: this.props.providers[0].provider_name },
				() => {
					this.getFilterData('provider')
				}
			)
		} else {
			this.props.listAllProviders((promise, providers) => {
				if (promise) {
					this.setState({ providers, selectedProvider: providers[0].provider_name },
						() => {
							this.getFilterData('provider')
                            this.sliderPeriod()
						}
					)
				} else {
					this.setState(
						{ providers: [], showLoading: false }, 
						() => {
							this.getFilterData()
                            this.sliderPeriod()
						}
					)
				}
			})
		}
    }

    componentDidUpdate = (prevProps, prevState) => {}
    
	getFilterData = from => {		
		let params = {}

		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'all') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}

		if (from === 'provider') {
			if(this.props.accounts && this.props.accounts.length) {
				let allAccounts = this.props.accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
				if(this.props.accounts.length) {
					let accRow = {}
					accRow.account_id = "All"
					accRow.account_name = "All"
					accRow.date = null
					accRow.disabled = false
					accRow.provider = "All"
					allAccounts.unshift(accRow);
				}
				this.setState({ accounts: allAccounts })
			} else {
				this.props.listAllAccounts(params, (promise, allAccounts) => {
					if (promise) {						
						let filterdAccounts = allAccounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
						if(filterdAccounts.length) {
							let accRow = {}
							accRow.account_id = "All"
							accRow.account_name = "All"
							accRow.date = null
							accRow.disabled = false
							accRow.provider = "All"
							filterdAccounts.unshift(accRow);
						}						
						this.setState({ accounts: filterdAccounts })
					}
				})
			}

			if(this.props.regions && this.props.regions.length) {
				let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
				this.setState({ regions: providerRegions })
			} else {
				this.props.listAllRegions(params, (promise, regions) => {
					if (promise) {
						let providerRegions = regions && regions[0] && regions[0].provider_regions
						if(providerRegions) {
							let provider_regions_row = {}
							provider_regions_row.availability_zones = []
							provider_regions_row.city = "All"
							provider_regions_row.country = "All"
							provider_regions_row.geography = "All"
							provider_regions_row.name = "All"
							provider_regions_row.region = "All"
							provider_regions_row.region_id = "All"
							provider_regions_row.state = "All"
							providerRegions.unshift(provider_regions_row);
						}
						this.setState({ regions: providerRegions })
					} else {
						this.setState({ regions: [] })
					}
				})
			}
		}
	}
    
    sliderPeriod = () => {
        let sliderEndDate = momentDateGivenFormat(currentLocaltime(), 'YYYY-MM-DD 23:59:59')
        let sliderStartDate = momentDateGivenFormat(subDays(sliderEndDate, 14), 'YYYY-MM-DD 00:00:00');

        let dateArray = []
        let month = ''
        let length = 90
        for(let x=1;x<=length;x++) {
            let date = subDays(sliderEndDate, (length-x))
            
            let value = 5
            if(month === '' || month !== momentDateGivenFormat(date, 'MM')) {
                month = momentDateGivenFormat(date, 'MM')
                value = 10
            }
            if(new Date(date).getTime() <= new Date(sliderEndDate).getTime()) {
                dateArray.push([date, value])
            }
        }

        let selectionRange = [{
			startDate: new Date(sliderStartDate),
			endDate: new Date(sliderEndDate),
			key: 'selection'
		}]
        
        this.setState({ dateArray, brushSliderSeries: [], sliderStartDate, sliderEndDate, selectionRange },
            () => {
                this.brushSliderChart(dateArray)
                this.onSearch('search')
                window.addEventListener('resize', () => {
                    this.brushSliderChart(dateArray)
                })
            }
        )
    }
    
    brushSliderChart = (dateArray) => {
        let sliderStartDate = this.state.sliderStartDate
        let sliderEndDate = this.state.sliderEndDate
        if(dateArray.length) {
            let brushSliderSeries = [{
                data: dateArray
            }]
            let dataArray = []
            dateArray.forEach(item => {
                dataArray.push(item[0])
            })

            let startEndLabels = []
            dataArray.forEach((item, x) => {
                if(dataArray.length>8) {
                    let length = dataArray.length - (2)
                    let interval = parseInt(length / 4)
                    let second = interval
                    let third =  interval * 2
                    let fourth = interval * 3
                    if(x === 0 || x === second || x === third || x=== fourth || x === (dataArray.length - 1)) {
                        startEndLabels.push(item)
                    }
                } else {
                    startEndLabels.push(item)
                }
            })

            let columnWidth = '5%'
            let brushSliderOptions = {
                plotOptions: {
                    bar: {
                        barHeight: '100%',
                        columnWidth: columnWidth,
                    },          
                },
                chart: {
                    id: 'chart1',
                    redrawOnWindowResize: true,         
                    height: 130,
                    type: 'bar',
                    animations: {
                        enabled: false
                    },
                    brush:{
                        target: 'none',
                        enabled: true,
                    },
                    selection: {
                        enabled: true,
                        fill: {
                            color: "#039BE5",
                            opacity: 1,
                            // opacity: 0.6
                        },  
                        stroke: {
                            show: false,
                            curve: 'straight',
                            lineCap: 'round',
                            // colors: '#00A3CB',
                            colors: '#039BE5',
                            width: 0,
                            dashArray: 0,      
                        },
                        xaxis: {
                            min: new Date(sliderStartDate).getTime(),
                            max: new Date(sliderEndDate).getTime(),
                        },
                        style: {
                            fontSize: '28px',
                            color: '#000000'
                        }
                    },
                    events: {
                        selection: (chartContext, { xaxis, yaxis }) => {
                            var minDate = momentDateGivenFormat(new Date(xaxis.min), 'YYYY-MM-DD')
							var maxDate = momentDateGivenFormat(new Date(xaxis.max), 'YYYY-MM-DD')

							let startSlice = momentDateGivenFormat(minDate, 'YYYY-MM-DD 00:00:00');
							let endSlice = momentDateGivenFormat(maxDate, 'YYYY-MM-DD 23:59:59');

                            let selectionRange = [{
								startDate: new Date(startSlice),
								endDate: new Date(endSlice),
								key: 'selection'
							}]

                            this.setState({ sliderStartDate: startSlice, sliderEndDate: endSlice, selectionRange },
                                () => this.getAttribute()
                            )
                        },
                        click: (event, chartContext, config) => {
                            this.onSearch()   
                            // this.onSearch('search')
                            // this.checkIntervalUpdates()
                            this.setState({ intervalChanged: true })
                        }
                    }
                },
                colors: ['#515151'],
                xaxis: {
                    tickAmount: 8,
                    type: 'datetime',
                    tooltip: {
                        enabled: false
                    },
                    position: 'top',
                    labels: {
                        show: true,
                        datetimeUTC: false,
                        style: {
                            colors: '#E0E0E0'
                        },
                        formatter: function(val) {
                            return momentDateGivenFormat(val, "DD MMM");
                        }
                    },
                    
                },
                yaxis: {
                    show: false,
                    tickAmount: 2,
                    labels: {
                        show: false
                    },
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: false
                        },
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                }
            }
        
            this.setState({ datePickerSliderSeries: brushSliderSeries, datePickerSliderOptions: brushSliderOptions })
        }
    }

    onSearch = (type) => {
        //status
        this.getStatusApis()        
        this.getBusinessApis()
        this.getAutomationApis()

        let obj = {}
        obj.selectedProvider = this.state.selectedProvider
        obj.selectedAccount = this.state.selectedAccount
        obj.selectedRegion = this.state.selectedRegion
        obj.start_time = this.state.sliderStartDate
        obj.end_time = this.state.sliderEndDate

        this.props.setAiopsPropsDetails('aiopsAutomationFilters', obj)

        this.setState({ numberofDays: twoDateDiffrence(this.state.sliderEndDate, this.state.sliderStartDate) + 1 })

        setTimeout(() => { this.setState({ showLoading: false }) }, 5000)
    }

    onReset = () => {
        this.setState({ 
            selectedProvider: '',
            selectedAccount: [],
            selectedRegion: [],
        },
            () => this.sliderPeriod()
        )
    }

    getStatusApis = () => {
        let filterParams = {}
        if(this.state.selectedProvider && this.state.selectedProvider !== '') {
            filterParams.provider =  this.state.selectedProvider.toLowerCase()
        }
        if(this.state.selectedAccount && this.state.selectedAccount.length) {
            filterParams.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length) {
            filterParams.region = this.state.selectedRegion
        }

        let top = {
            "start_time": momentConvertionLocalToUtcTime(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:ss'),
            "end_time": momentConvertionLocalToUtcTime(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:ss'),
            "aggregate_by": ["automation_plan"],
            "top": 5,
        }
        let topParams = {...filterParams, ...top}

        let trend = {
            "start_time": momentConvertionLocalToUtcTime(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:ss'),
            "end_time": momentConvertionLocalToUtcTime(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:ss'),
            "aggregate_by": ["status"],
            "radial": true,
        }
        let trendParams = {...filterParams, ...trend}

        let total = {
            "duration_aggregate_by": ["daily","monthly", "weekly"],
        }
        let totalParams = {...filterParams, ...total}

        let params = {         
            'top': topParams,
            'trend': trendParams,
            'total': totalParams,
        }

        this.props.getApis(params, (promise, response) => {
            if(promise) {
                this.setState({ statusSection: response, succesFailureTrendData: {}, topStatusData: {}, showLoading: false },
                    () => {
                        this.statusCountSection()
                        this.statusTrendSection()
                        this.topStatusSection()
                    }
                )
            } else {
                this.setState({ showLoading: false })
            }
        })
    }

    statusCountSection = () => {
        let total = 0
        this.state.statusSection.trend && this.state.statusSection.trend.forEach(item => {
            total = item.total ? (item.total.Success ? item.total.Success : 0) + (item.total.Failed ? item.total.Failed : 0) : 0
        })

        let statusCount = 0
        let statusDailyCount = 0
        let statusWeeklyCount = 0
        let statusMonthlyCount = 0

        if(this.state.statusSection.total) {
            let total = this.state.statusSection.total && this.state.statusSection.total.length ? this.state.statusSection.total[0] : {}
            statusCount = total.count ? total.count : 0
            statusDailyCount = total.daily && total.daily.count ? total.daily.count : 0
            statusWeeklyCount = total.weekly && total.weekly.count ? total.weekly.count : 0
            statusMonthlyCount = total.monthly && total.monthly.count ? total.monthly.count : 0
        }

        this.setState({ statusPeriodCount: total, statusCount, statusDailyCount, statusWeeklyCount, statusMonthlyCount })
    }

    statusTrendSection = () => {
        let graphData = {}
        let totalSuccessRate = 0
        let totalFailureRate = 0
        this.state.statusSection.trend && this.state.statusSection.trend.forEach(item => {
            graphData.labels = item.dates ? item.dates : []
            item.data && Object.entries(item.data).forEach(([key,value]) => {
                if(key !== 'InProgress') {
                    graphData[key] = value
                }
            })

            totalSuccessRate = item.rate && item.rate.success_rate ? item.rate.success_rate : ''
            totalFailureRate = item.rate && item.rate.failure_rate ? item.rate.failure_rate : ''
        })

        this.setState({ succesFailureTrendData: graphData, totalSuccessRate, totalFailureRate })
    }

    topStatusSection = () => {
        let top = this.state.statusSection.top ? this.state.statusSection.top : []
        let topStatusType = top.filter(e => e.label).map(e => e.label)
        topStatusType = _(topStatusType).sortBy().value()

        let filterData = []
        let type = this.state.selectedTopStatusFilter ? this.state.selectedTopStatusFilter : topStatusType[0]
        if(top && top.length) {
            filterData = top.filter(e => e.label === type)
            filterData = filterData.length ? filterData[0].data : []
        }

        let data = []
        let labels = []
        let dataUnit = ''
        let tooltip = []

        filterData.length && filterData.forEach(item => {
            let value = item.top && item.top.value ? item.top.value : 0
            if(type === 'RunTime' || type === 'RunTime' || type === 'RunTime') {
                value = value.toFixed(2)
            }
            data.push(value)
            labels.push(item.automation_plan)
            dataUnit = item.top && item.top.unit ? item.top.unit : ''

            let tooltipRow = {}
            tooltipRow.cost_saved = item.cost_saved ? item.cost_saved+' USD' : '0 USD'
            tooltipRow.duration = item.duration ? unitTimeConvertion(item.duration, 'seconds') : '0 second'
            tooltipRow.time_saved = item.time_saved ?  unitTimeConvertion(item.time_saved, 'seconds') : '0 second'
            tooltipRow.description = item.description ? item.description : ''
            tooltip.push(tooltipRow)
        })

        for(let i=filterData.length; i<5; i++) {
            data.push(0)
            labels.push('empty')
        }

        let graphData = {}
        graphData.data = data
        graphData.labels = labels        
        graphData.unit = dataUnit
        graphData.tooltipValues = tooltip
        this.setState({ topStatusType, topStatusData: graphData, selectedTopStatusFilter: type })
    }

    getBusinessApis = () => {
        let filterParams = {}
        if(this.state.selectedProvider && this.state.selectedProvider !== '') {
            filterParams.provider =  this.state.selectedProvider.toLowerCase()
        }
        if(this.state.selectedAccount && this.state.selectedAccount.length) {
            filterParams.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length) {
            filterParams.region = this.state.selectedRegion
        }

        let trend = {
            "start_time": momentConvertionLocalToUtcTime(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:ss'),
            "end_time": momentConvertionLocalToUtcTime(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:ss'),
            "aggregate_by": [["cost_saved", "time_saved", "duration"]],
        }
        let trendParams = {...filterParams, ...trend}

        let total = { 
            "duration_aggregate_by": ["daily","monthly", "weekly"],
            "total_aggregate_by": ["cost_saved", "time_saved", "duration"],
        }
        let totalParams = {...filterParams, ...total}

        let params = {
            "trend": trendParams,
            "total": totalParams
        }

        this.props.getApis(params, (promise, response) => {
            if(promise) {
                this.setState({ businessSectionData: response, showLoading: false },
                    () => {
                        this.businessCountSection()
                        this.businessTrendSection()
                    }
                )
            }
        })
    }

    businessCountSection = () => {
        let businessPeriodCounts = {}
        let businessTotalCounts = {}
        this.state.businessSectionData.trend && this.state.businessSectionData.trend.forEach(item => {
            businessPeriodCounts = item.total ? item.total : {}
            businessTotalCounts = this.state.businessSectionData.total && this.state.businessSectionData.total.length ? this.state.businessSectionData.total[0] : {}
        })

        this.setState({ businessPeriodCounts, businessTotalCounts })
    }

    businessTrendSection = () => {
        this.state.businessSectionData.trend && this.state.businessSectionData.trend.forEach(item => {
            item.data && Object.entries(item.data).length && Object.entries(item.data).forEach(([key, value]) => {
                let graphData = {}
                graphData.labels = item.dates ? item.dates : []
                graphData.data = value
                graphData.name = key
                graphData.unit = (key === 'cost_saved' ? 'USD' : (key === 'time_saved' || key === 'duration') ? 'seconds' : '')
                graphData.convertTime = (key === 'cost_saved' ? false : (key === 'time_saved' || key === 'duration') ? true : false)
                
                let count = item.rate && item.rate[key] ? item.rate[key] : 0
                this.setState({ ['business_trend_'+key]: {}, ['business_count_'+key]: count }, 
                    () => {
                        this.setState({ ['business_trend_'+key]: graphData })
                    }
                )
            })
        })
    }

    getAutomationApis = () => {
        let filterParams = {}
        if(this.state.selectedProvider && this.state.selectedProvider !== '') {
            filterParams.provider =  this.state.selectedProvider.toLowerCase()
        }
        if(this.state.selectedAccount && this.state.selectedAccount.length) {
            filterParams.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length) {
            filterParams.region = this.state.selectedRegion
        }

        let trend = {
            "start_time": this.state.sliderStartDate,
            "end_time": this.state.sliderEndDate,
            "duration_aggregate_by": "daily",
            "aggregate_by": ["automation_type"],
            "radial": true
        }        
        let trendParams = {...filterParams, ...trend}

        let top = {
            "start_time": this.state.sliderStartDate,
            "end_time": this.state.sliderEndDate,
            "aggregate_by": ["automation_type", "automation_plan"],
            "top": 5,
        }
        let topParams = {...filterParams, ...top}

        let total = { 
            "duration_aggregate_by": ["daily","monthly", "weekly"],
            "aggregate_by": ["automation_type"],
        }
        let totalParams = {...filterParams, ...total}

        let params = {
            "trend": trendParams,
            "top": topParams,
            "total": totalParams
        }

        this.props.getApis(params, (promise, response) => {
            if(promise) {
                this.setState({ automationSection: response, showLoading: false },
                    () => {
                        this.automationCountSection()
                        this.automationTrendSection()
                        this.automationTotalSection()
                    }
                )
            }
        })
    }

    onChangeAutomationType = () => {
        this.setState({ showAutomationDonut: false, showAutomationTrend: false, topAutomationData: {} },
            () => {
                this.automationTrendSection()
                this.topAutomationSection()
                this.setState({ showAutomationDonut: true })
            }    
        )
    }

    automationCountSection = () => {
        let applicationTotal = 0
        this.state.automationSection.trend && this.state.automationSection.trend.forEach(item => {
            item.total && Object.entries(item.total).length && Object.entries(item.total).forEach(([key, value]) => {
                applicationTotal = value
                let donutData = {
                    "items": [
                        {
                            "item_count": value,
                            "item_name": "Total"
                        }
                    ],
                    "label": "Total",
                    "total_count": value
                }

                this.setState({ ['automation_donut_'+key]: {} },
                    () => {
                        this.setState({ ['automation_donut_'+key]: donutData, showAutomationDonut: true, applicationTotal })
                    }
                )
            })
        })
    }

    automationTrendSection = () => {
        let automationType = []
        this.state.automationSection.trend && this.state.automationSection.trend.forEach(item => {
            item.data && Object.entries(item.data).length && Object.entries(item.data).forEach(([key, value]) => {
                let graphData = {}
                graphData.name = key
                graphData.labels = item.dates ? item.dates : []
                graphData.data = value
                automationType.push(key)
                
                let count = item.total && item.total[key] && item.total[key].count ? item.total[key].count : 0  

                this.setState({ ['automation_trend_'+key]: {}, ['automation_count_'+key]: count },
                    () => this.setState({ ['automation_trend_'+key]: graphData })
                )
            })

            let activeAutomationTab = this.state.activeAutomationTab ? this.state.activeAutomationTab : (automationType.length ? automationType[0] : '')
            this.setState({ automationType, activeAutomationTab, showAutomationTrend: true, topAutomationData: {} },
                () => this.topAutomationSection()
            )
        })
    }

    automationTotalSection = () => {
        this.state.automationSection.total && this.state.automationSection.total.forEach(item => {
            this.setState({ 
                [item.automation_type+"_total"]: item.count ? item.count : 0,
                [item.automation_type+"_daily"]: item.daily && item.daily.count ? item.daily.count : 0,
                [item.automation_type+"_monthly"]: item.monthly && item.monthly.count ? item.monthly.count : 0,
                [item.automation_type+"_weekly"]: item.weekly && item.weekly.count ? item.weekly.count : 0
            })
        })
    }

    topAutomationSection = () => {
        let top = this.state.automationSection.top ? this.state.automationSection.top : []
        let topAutomationTab = top.filter(e => e.automation_type === this.state.activeAutomationTab)
        let topAutomationType = []
        if(topAutomationTab.length) {
            topAutomationType = topAutomationTab[0].data.filter(e => e.label).map(e => e.label)
            topAutomationType = _(topAutomationType).sortBy().value()
        }

        let filterData = []
        let type = this.state.selectedTopAutomationFilter ? this.state.selectedTopAutomationFilter : topAutomationType[0]
        if(topAutomationTab && topAutomationTab.length) {
            filterData = topAutomationTab[0].data.filter(e => e.label === type)
            filterData = filterData.length ? filterData[0].data : []
        }

        let data = []
        let labels = []
        let dataUnit = ''
        let tooltip = []
        
        filterData.length && filterData.forEach(item => {
            let value = item.top && item.top.value ? item.top.value : 0
            if(type === 'RunTime' || type === 'RunTime' || type === 'RunTime') {
                value = value.toFixed(2)
            }
            data.push(value)
            labels.push(item.automation_plan)
            dataUnit = item.top && item.top.unit ? item.top.unit : ''

            let tooltipRow = {}
            tooltipRow.cost_saved = item.cost_saved ? '$ '+item.cost_saved : '$ 0'
            tooltipRow.duration = item.duration ? unitTimeConvertion(item.duration, 'seconds') : '0 second'
            tooltipRow.time_saved = item.time_saved ? unitTimeConvertion(item.time_saved, 'seconds') : '0 second'
            tooltipRow.description = item.description ? item.description : ''
            tooltip.push(tooltipRow)
        })

        for(let i=filterData.length; i<5; i++) {
            data.push(0)
            labels.push('empty')
        }

        let graphData = {}
        graphData.data = data
        graphData.labels = labels
        graphData.unit = dataUnit
        graphData.tooltipValues = tooltip

        this.setState({ topAutomationType, topAutomationData: graphData, selectedTopAutomationFilter: type })
    }

    handleClickOutside(event) {
            
        if(this.accountRef && !this.accountRef.current.contains(event.target)) {
            this.setState({ isAccountOpen: false })
        } else {
            this.setState({ isAccountOpen: true })
        }

        if(this.regionRef && !this.regionRef.current.contains(event.target)) {
            this.setState({ isRegionOpen: false })
        } else {
            this.setState({ isRegionOpen: true })
        }

		if (this.daterRangeRef && !this.daterRangeRef.current.contains(event.target)) {
			this.setState({ showDateRangePicker: false })
		}

        if (this.topStatusRef && !this.topStatusRef.current.contains(event.target)) {
			this.setState({ showTopStatusFilter: false })
		}

        if (this.topAutomationRef && !this.topAutomationRef.current.contains(event.target)) {
			this.setState({ showTopAutomationFilter: false })
		}
    }
    
    handleMultiSelectChange = (field, arrayValue) => {
		let value = arrayValue.map(item => item.value)

		let selectedValue = []
		if(field === 'selectedAccount') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.accounts.forEach(acc => {
						selectedValue.push(acc.account_id)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.accounts.length -1)) {
					selectedValue.push('All')
				}
			}	
            this.setState({ [field]: selectedValue })		
		} else if(field === 'selectedRegion') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.regions.length -1)) {
					selectedValue.push('All')
				}
			}
            this.setState({ [field]: selectedValue })
		} 
    }
    
    getMultiSelectedCount = (type, array) => {
        return array.length && array.includes('All') ? 'All' : (array.length ? array.length +' Selected' : 'All')
    }   
	
	handleSelect = (date) => {
		this.setState({ selectionRange: [date.selection] })
        let sliderStartDate = momentDateGivenFormat(date.selection.startDate, 'YYYY-MM-DD 00:00:00')
		let sliderEndDate = momentDateGivenFormat(date.selection.endDate, 'YYYY-MM-DD 23:59:59')

		// let sliderStartDate = momentDateGivenFormat(date.selection.startDate, 'YYYY-MM-DD HH:mm:ss')
		// let sliderEndDate = momentDateGivenFormat(date.selection.endDate, 'YYYY-MM-DD HH:mm:ss')
		this.setState({ sliderStartDate, sliderEndDate })
	}
    
    getAttribute = () => {
        var attributes = document.getElementsByClassName("apexcharts-selection-rect") && document.getElementsByClassName("apexcharts-selection-rect")[0] &&document.getElementsByClassName("apexcharts-selection-rect")[0].attributes;
        if(attributes) {
            let width = attributes.width.value
            let x1 = parseInt(attributes.x.value) + parseInt(20)
            let x2 = parseInt(attributes.x.value) + parseInt(width) + parseInt(10)

            let leftLPosition = x1
            let rightLPosition = x2
            let diff = twoDateDiffrence(this.state.sliderEndDate, this.state.sliderStartDate)
            if(diff < 8) {
                leftLPosition = x1 - 20
                if(diff < 4) {
                    leftLPosition = x1 - 40
                }
                rightLPosition = x2 + 10
            }

            var d1 = document.getElementById('leftSliderSpan');
            d1.style.position = "absolute";
            d1.style.left = leftLPosition+'px';
            d1.style.top = '90px';
            d1.style.color = '#039BE5';

            var d2 = document.getElementById('rightSliderSpan');
            d2.style.position = "absolute";
            d2.style.left = rightLPosition+'px';
            d2.style.top = '90px';
            d2.style.color = '#039BE5';
        }
    }

	render() {
		return (
            <div className="container-fluid overflow-auto flex-grow-1 bg-muted" onClick={ (event) => { this.handleClickOutside(event) } }>
                <div className={`loaderOverlay ${this.state.showLoading ? '' : 'displayNone'}`}>
                    <div className="overlayEqualizerLoader">
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                    </div>
                </div>
                <div className='row h-100'>
                    <div className='col-sm-12 p-0'>
                        <div className="title-section pb-3">
							<div className="row">
								<div className="col-6 mt-0">
                                    <h6 className="text-white m-0">Automations - <Link target="_blank" className='text-info f13 text-decoration-none' to={URL_PATH.LIST_AUTOMATION}> Manage Automations</Link></h6>
                                    <p className="text-white m-0">Consolidated view of Automations and its Savings</p>
                                    <div className="row mt-3">
                                        <div className={`col-md-12 d-md-flex`}>
                                            <div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} style={{maxWidth: '150px' }}>
												<label className="mb-1">Provider</label>
												<Select
													placeholder={'All'}
													isSearchable={false}
													//onFocus={this.openProivderMenu}
													className='f13 p-0 colorBlack reactSelectFilterDrop'
													value={({
														value: this.state.selectedProvider,
														label: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : <span className="placeholder">Select</span>
													})}
													options={this.state.providers && this.state.providers.map(item => ({
														value: item.provider_name,
														label: item.provider_name,	
													}))}
													onChange={event =>  
														this.setState(
															{
																selectedProvider: event.value,
																changesInFilter: true
															}
														)
													}
												/>
											</div>
											
                                            <div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} style={{maxWidth: '150px' }} ref={this.accountRef}>
												<label className="mb-1">Account</label>
												<div className='dlpHeaderMultiSelection removeDropdownSearchBar'>
													<ReactMultiSelectCheckboxes						
														placeholderButtonLabel="All"
														menuIsOpen ={this.state.isAccountOpen}
														getDropdownButtonLabel={() => this.getMultiSelectedCount('account', this.state.selectedAccount)}
														onChange={arr => {
														this.handleMultiSelectChange('selectedAccount', arr ? arr : []) }}
														options={this.state.accounts && this.state.accounts.map(account => ({
															value: account.account_id,
															label: account.account_name,
														}))}
														value={this.state.selectedAccount.map(comp => ({
															value: comp,
														}))}
													/>
												</div>
											</div>
											<div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 `} style={{maxWidth: '150px' }} ref={this.regionRef}>
												<label className="mb-1">Region</label>
												<div className={`dlpHeaderMultiSelection ${this.state.regions && this.state.regions.length > 10 ? '' : 'removeDropdownSearchBar' } `}>
													<ReactMultiSelectCheckboxes						
														placeholderButtonLabel="Select"
														menuIsOpen ={this.state.isRegionOpen}
														getDropdownButtonLabel={() => this.getMultiSelectedCount('region', this.state.selectedRegion)}
														onChange={arr => {
														this.handleMultiSelectChange('selectedRegion', arr ? arr : []) }}
														options={this.state.regions && this.state.regions.map(region => ({
															value: region.region,
															label: region.name,
														}))}
														value={this.state.selectedRegion.map(reg => ({
															value: reg,
														}))}
													/>
												</div>
											</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-5 text-right">
									<div className={`d-flex justify-content-end align-self-center`}>
										<div className={`d-flex justify-content-between align-self-end displayNone`}>
											<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === 90 ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedFilterPeriod: 90 }, () => this.sliderPeriod('onClicl'))}>3 Months</span>
											<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === 30 ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedFilterPeriod: 30 }, () => this.sliderPeriod('onClicl'))}>1 Month</span>
											<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === 14 ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedFilterPeriod: 14 }, () => this.sliderPeriod('onClicl'))}>2 Weeks</span>
											<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === 7 ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedFilterPeriod: 7 }, () => this.sliderPeriod('onClicl'))}>1 Week</span>
										</div>
										<div className="align-self-end" ref={this.daterRangeRef}>
											<div className={'d-flex'}>
                                                <p className="small mb-0 text-info mr-2 align-self-center">Duration selected</p>
												<input type="text" className="form-control-sm f12 cursorPointer" readOnly value={momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM')+ ' to '+ momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM YYYY')} onClick={() => this.setState({ showDateRangePicker: !this.state.showDateRangePicker }) }/>
											</div>
											{this.state.showDateRangePicker ?
												<div className="headerDateRange" style={{"z-index": "999999999","position": "absolute","right": "30px", "top": "50px"}}>
													<DateRange
														onChange={item => this.handleSelect(item) }
														showSelectionPreview={true}
														moveRangeOnFirstSelection={false}
														months={2}
														ranges={this.state.selectionRange}
														direction="horizontal"
														maxDate={new Date()}
														preventSnapRefocus={true}
														// calendarFocus="backwards"
													/>
												</div>
											: null}
										</div>                                                                                
									</div>
                                    {this.state.datePickerSliderOptions && this.state.datePickerSliderSeries ?
                                        <div className={`col-md-12 mb-n3 pr-0 ${this.state.disableDateSlider ? 'disabled' : ''}`} >
                                            <Chart options={this.state.datePickerSliderOptions} series={this.state.datePickerSliderSeries} type="bar" height={80} />
                                        </div>
                                    : null}
                                    
                                    <div className="d-flex">
                                        <div id="leftSliderSpan" className="f12">{momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM')}</div>
                                        <div id="rightSliderSpan" className="f12">{momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM')}</div>
                                    </div>
                                </div>
                                <div className="col-1 text-right align-self-center ">
                                    <div className={`ml-3`}>
                                        <div className="btn-group" role="group" aria-label="Basic example">
                                            <button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-right`} onClick={() => this.setState({ showLoading: true }, () => this.onSearch('search') )}>Search</button>
                                            <button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-left`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box rounded-0 shadow-none bg-transparent h-100">
                            {!this.state.showExpandedView ? 
                                <div className="bg-grey h-100">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row text-white">
                                                <div className="col-lg-4">
                                                    <div className="p-3">
                                                        <div className="row">
                                                            <div className="col-lg-12 d-flex">
                                                                <h6 className="text-white mb-0">By Status -</h6>
                                                                <small className="m-0 ml-1 align-self-center text-primary-color">View the status of Automation Jobs</small>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex mt-4 mb-3">
                                                            <div className="d-flex">
                                                                {/* <div className="align-self-center">
                                                                    <div className="rectangle-green text-success"></div>
                                                                </div> */}
                                                                <p className="mb-0 ml-2 font-weight-normal mb-0">
                                                                    Success Rate: <span className="text-success">{this.state.totalSuccessRate ? this.state.totalSuccessRate : 0}%</span>
                                                                </p>
                                                            </div>
                                                            <div className="d-flex pl-3">
                                                                {/* <div className="align-self-center">
                                                                    <div className="rectangle-danger danger"></div>
                                                                </div> */}
                                                                <p className="mb-0 ml-2 font-weight-normal mb-0">
                                                                    Failure Rate: <span className="text-danger">{this.state.totalFailureRate ? this.state.totalFailureRate : 0}%</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex bg-muted justify-content-center">
                                                            <div className="align-self-center">
                                                                <p className="mb-0 small py-2">
                                                                Automation Job Status for ({momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM')+ ' to '+ momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM')}) {this.state.numberofDays && this.state.numberofDays} {this.state.numberofDays && this.state.numberofDays > 1 ? ' days' : ' day'} is <span className="font-weight-bolder text-purple-2">{this.state.statusPeriodCount ? this.state.statusPeriodCount : 0}</span>
                                                                </p>                                                                
                                                            </div>
                                                        </div>                                                        
                                                        <div className="d-flex mt-2">
                                                            <div className="mr-4 pr-1">
                                                                <div className="font-weight-bold text-center box-shadow cursorPointer" id="statusView_total">
                                                                    <p className="mb-0 align-self-center font-weight-normal">{numberFormater(this.state.statusCount ? this.state.statusCount : 0)}</p>
                                                                </div>
                                                                <UncontrolledTooltip target="statusView_total">Total: {this.state.statusCount ? this.state.statusCount : 0}</UncontrolledTooltip>
                                                                <h6 className="my-2 f16  text-white text-center font-weight-normal">Total</h6>
                                                            </div>
                                                            <div className="mr-4 pr-1 align-self-end">
                                                                <div className="font-weight-bold text-center box-shadow cursorPointer" id="statusView_monthly">
                                                                    <p className="mb-0 align-self-center font-weight-normal">{numberFormater(this.state.statusMonthlyCount ? this.state.statusMonthlyCount : 0)}</p>
                                                                </div>
                                                                <UncontrolledTooltip target="statusView_monthly">Monthly: {this.state.statusMonthlyCount ? this.state.statusMonthlyCount : 0}</UncontrolledTooltip>
                                                                <p className="my-2 f12 text-white text-center font-weight-normal">Monthly
                                                                </p>
                                                            </div>
                                                            <div className="mr-4 pr-1 align-self-end">
                                                                <div className="font-weight-bold text-center box-shadow box-shadow-1 cursorPointer" id="statusView_weekly">
                                                                    <p className="mb-0 align-self-center font-weight-normal">{numberFormater(this.state.statusWeeklyCount ? this.state.statusWeeklyCount : 0)}</p>
                                                                </div>
                                                                <UncontrolledTooltip target="statusView_weekly">Weekly: {this.state.statusWeeklyCount ? this.state.statusWeeklyCount : 0}</UncontrolledTooltip>
                                                                <p className="my-2 f12 text-white text-center font-weight-normal">Weekly</p>
                                                            </div>
                                                            <div className="align-self-end">
                                                                <div className="font-weight-bold text-center box-shadow box-shadow-1 cursorPointer" id="statusView_daily">
                                                                    <p className="mb-0 align-self-center font-weight-normal">{numberFormater(this.state.statusDailyCount ? this.state.statusDailyCount : 0)}</p>
                                                                </div>
                                                                <UncontrolledTooltip target="statusView_daily">Daily: {this.state.statusDailyCount ? this.state.statusDailyCount : 0}</UncontrolledTooltip>
                                                                <p className="my-2 f12 text-white text-center font-weight-normal">Daily</p>
                                                            </div>
                                                        </div>
                                                        {this.state.succesFailureTrendData && Object.entries(this.state.succesFailureTrendData).length ?
                                                            <React.Fragment>
                                                                <div className="mt-n3 mb-n3">
                                                                    <ApexStackedBarChart 
                                                                        graphData={this.state.succesFailureTrendData ? this.state.succesFailureTrendData : {}}
                                                                        sparkline={false}
                                                                        yaxis={false}
                                                                        yaxisLabel={true}
                                                                        xaxis={true}
                                                                        xaxisFormat={'date'}
                                                                        xaxisLabel={true}
                                                                        axisLabelColor={'#B8BBBE'}
                                                                        paddingLeft={-25}
                                                                        legend={false}
                                                                        stacked={true}
                                                                        height={160}
                                                                        horizontal={false}
                                                                        barHeight={'40%'}
                                                                        barEndShape={'flat'}
                                                                        columnWidth={'25%'}
                                                                        gradient={true}
                                                                        gradientColor={['#039BE5', '#5F5BA2']}
                                                                        hideTooltipValue={true}
                                                                        backgroundBarShape={'flat'}
                                                                        backgroundBarColors={['#333947']}
                                                                        showBackgroundBarColors={false}
                                                                        className={"transparentTooltip"}
                                                                    />
                                                                </div>
                                                                <div className="border-bottom mt-2"></div>
                                                            </React.Fragment>
                                                        : null}  
                                                        <div className="d-flex justify-content-between mt-2 w-100">
                                                            <div className="align-self-center">
                                                                <h6 className="text-white mb-0 ">Top 5 Automations</h6>
                                                            </div>
                                                            <div className="w-25 select-sm-bg curvedDropdown minWidth150 mr-2" ref={this.topStatusRef}>
                                                                <div className="dropdown">
                                                                    <span className="dropdown-toggle d-flex justify-content-between px-2 text-white" onClick={() => this.setState({ showTopStatusFilter: !this.state.showTopStatusFilter })}>
                                                                        {this.state.selectedTopStatusFilter ? this.state.selectedTopStatusFilter : <span className="placeholder">Select</span>}
                                                                        <span className="caret"></span>
                                                                        {/* <i className="fas fa-angle-down"></i> */}
                                                                    </span>
                                                                    <ul className={`dropdown-menu p-0 ${this.state.showTopStatusFilter ? "show" : ''}`}>
                                                                        {this.state.topStatusType && this.state.topStatusType.map(item => {
                                                                            return (
                                                                                <li 
                                                                                    onClick={() => this.setState({ 
                                                                                            selectedTopStatusFilter: item, 
                                                                                            showTopStatusFilter: false,
                                                                                            topStatusData: {} 
                                                                                    }, 
                                                                                        () => this.topStatusSection()
                                                                                    )} 
                                                                                    value={item} 
                                                                                    className={`${this.state.selectedTopStatusFilter === item ? "selected" :  ""}`}
                                                                                >
                                                                                    {item}
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            {/* <p className="mb-0 mr-2 small text-primary-color align-self-center">View top 5 automation jobs by selection</p> */}
                                                            {/* <div className="select-bg w-25">
                                                                <div className="form-group bd-highlight styled-feild bg-transparent mb-0">
                                                                    <select className="form-control margin-8"
                                                                        value={this.state.selectedTopStatusFilter}
                                                                        onChange={event => {
                                                                            this.setState(
                                                                                {
                                                                                    selectedTopStatusFilter: event.target.value,
                                                                                    topStatusData: {}
                                                                                },
                                                                                () => {
                                                                                    this.topStatusSection()
                                                                                }
                                                                            )
                                                                        }}>
                                                                        {this.state.topStatusType && this.state.topStatusType.map(item => {
                                                                            return (
                                                                                <option value={item}>{item}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                        <small className="mt-1 mb-0 small text-primary-color">View top 5 automation jobs by selection - {this.state.selectedTopStatusFilter}</small>
                                                        {this.state.topStatusData && Object.entries(this.state.topStatusData).length ?
                                                            <div className="mt-n2"> 
                                                                <ApexBarChart
                                                                    graphData={this.state.topStatusData}
                                                                    sparkline={false}
                                                                    yaxis={false}
                                                                    yaxisLabel={false}
                                                                    xaxis={true}
                                                                    xaxisFormat={'string'}
                                                                    xaxisLabel={true}
                                                                    axisLabelColor={'#B8BBBE'}
                                                                    paddingLeft={-25}
                                                                    legend={false}
                                                                    stacked={false}
                                                                    height={240}
                                                                    horizontal={true}
                                                                    barHeight={'40%'}
                                                                    barEndShape={'rounded'}
                                                                    columnWidth={'25%'}
                                                                    gradient={true}
                                                                    gradientColor={['#039BE5', '#5F5BA2']}
                                                                    hideTooltipValue={true}
                                                                    backgroundBarShape={'rounded'}
                                                                    backgroundBarColors={['#333947']}
                                                                    showBackgroundBarColors={false}
                                                                    className={"transparentTooltip"}
                                                                />
                                                            </div>
                                                        : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 bg-dark-75 border-info-2 px-0">
                                                    {/* <h4 className="f24 text-center borderBottom"></h4> */}
                                                    <h4 className="f24 text-center p-2 borderBottom mx-4">
                                                        <span className="borderStyle borderLeftStyle"></span>
                                                        <span className="borderStyle borderRightStyle"></span>   
                                                        Business
                                                    </h4>
                                                    <div className="px-3">
                                                        <div className="bg-dark-25 px-3 py-1 mb-2 text-center">
                                                            <div className="d-flex justify-content-center">
                                                                <h6 className="text-white mb-0">Cost Saved</h6>
                                                                <small className="m-0 ml-1 align-self-center text-primary-color">(in USD)</small>
                                                            </div>
                                                            <div className="d-flex bg-muted justify-content-center mt-1">
                                                                <div className="align-self-center">
                                                                    <p className="mb-0 small py-2">
                                                                        Cost saved for ({momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM')+ ' to '+ momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM')}) {this.state.numberofDays && this.state.numberofDays} {this.state.numberofDays && this.state.numberofDays > 1 ? ' days' : ' day'} is <span className="font-weight-bolder text-blue-50">{this.state.businessPeriodCounts && this.state.businessPeriodCounts.cost_saved ? this.state.businessPeriodCounts.cost_saved : 0 } USD</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex mt-2">
                                                                <div className="mr-4 pr-1">
                                                                    <div className="font-weight-bold text-center box-shadow box-shadow-border-blue cursorPointer" id="bv_cost_saved_total">
                                                                        <p className="mb-0 align-self-center font-weight-normal">{numberFormater(this.state.businessTotalCounts && this.state.businessTotalCounts.cost_saved ? this.state.businessTotalCounts.cost_saved : 0)}</p>
                                                                    </div>                                                                    
                                                                    <UncontrolledTooltip target="bv_cost_saved_total">Total: {this.state.businessTotalCounts && this.state.businessTotalCounts.cost_saved ? this.state.businessTotalCounts.cost_saved : 0} USD</UncontrolledTooltip>
                                                                    <h6 className="my-2 f16  text-white text-center font-weight-normal">Total</h6>
                                                                </div>
                                                                <div className="mr-4 pr-1 align-self-end">
                                                                    <div className="font-weight-bold text-center box-shadow box-shadow-border-blue cursorPointer" id="bv_cost_saved_monthly">
                                                                        <p className="mb-0 align-self-center font-weight-normal">{numberFormater(this.state.businessTotalCounts && this.state.businessTotalCounts.monthly && this.state.businessTotalCounts.monthly.cost_saved ? this.state.businessTotalCounts.monthly.cost_saved : 0)}</p>
                                                                    </div>
                                                                    <UncontrolledTooltip target="bv_cost_saved_monthly">Monthly: {this.state.businessTotalCounts && this.state.businessTotalCounts.monthly && this.state.businessTotalCounts.monthly.cost_saved ? this.state.businessTotalCounts.monthly.cost_saved : 0} USD</UncontrolledTooltip>
                                                                    <p className="my-2 f12 text-white text-center font-weight-normal">Monthly
                                                                    </p>
                                                                </div>
                                                                <div className="mr-4 pr-1 align-self-end">
                                                                    <div className="font-weight-bold text-center box-shadow box-shadow-border-blue box-shadow-1 cursorPointer" id="bv_cost_saved_weekly">
                                                                        <p className="mb-0 align-self-center font-weight-normal">{numberFormater(this.state.businessTotalCounts && this.state.businessTotalCounts.weekly && this.state.businessTotalCounts.weekly.cost_saved ? this.state.businessTotalCounts.weekly.cost_saved : 0)}</p>
                                                                    </div>
                                                                    <UncontrolledTooltip target="bv_cost_saved_weekly">Weekly: {this.state.businessTotalCounts && this.state.businessTotalCounts.weekly && this.state.businessTotalCounts.weekly.cost_saved ? this.state.businessTotalCounts.weekly.cost_saved : 0} USD</UncontrolledTooltip>
                                                                    <p className="my-2 f12 text-white text-center font-weight-normal">Weekly</p>
                                                                </div>
                                                                <div className="align-self-end">
                                                                    <div className="font-weight-bold text-center box-shadow box-shadow-border-blue box-shadow-1 cursorPointer" id="bv_cost_saved_daily">
                                                                        <p className="mb-0 align-self-center font-weight-normal">{numberFormater(this.state.businessTotalCounts && this.state.businessTotalCounts.daily && this.state.businessTotalCounts.daily.cost_saved ? this.state.businessTotalCounts.daily.cost_saved : 0)}</p>
                                                                    </div>
                                                                    <UncontrolledTooltip target="bv_cost_saved_daily">Daily: {this.state.businessTotalCounts && this.state.businessTotalCounts.daily && this.state.businessTotalCounts.daily.cost_saved ? this.state.businessTotalCounts.daily.cost_saved : 0} USD</UncontrolledTooltip>
                                                                    <p className="my-2 f12 text-white text-center font-weight-normal">Daily</p>
                                                                </div>
                                                            </div>
                                                            {this.state.business_trend_cost_saved && Object.entries(this.state.business_trend_cost_saved).length ?
                                                                <div className="mt-n4 mb-n2">
                                                                    <ApexBarChart
                                                                        graphData={this.state.business_trend_cost_saved}
                                                                        sparkline={false}
                                                                        yaxis={false}
                                                                        yaxisLabel={true}
                                                                        xaxis={true}
                                                                        xaxisFormat={'datetime'}
                                                                        xaxisLabel={true}
                                                                        axisLabelColor={'#B8BBBE'}
                                                                        paddingLeft={0}
                                                                        legend={false}
                                                                        stacked={true}
                                                                        height={160}
                                                                        horizontal={false}
                                                                        barHeight={'40%'}
                                                                        barEndShape={'flat'}
                                                                        columnWidth={'25%'}
                                                                        gradient={false}
                                                                        gradientColor={['#039BE5', '#5F5BA2']}
                                                                        hideTooltipValue={true}
                                                                        backgroundBarShape={'flat'}
                                                                        colors={["#5F5BA2"]}
                                                                        backgroundBarColors={['#333947']}
                                                                        showBackgroundBarColors={false}
                                                                        className={"transparentTooltip"}
                                                                    />
                                                                </div>
                                                            : null}
                                                        </div>
                                                        <div className="bg-dark-25 px-3 py-1 mb-2 text-center">
                                                            <h6 className="text-white mb-0">Time Saved</h6>
                                                            <div className="d-flex bg-muted justify-content-center mt-1">
                                                                <div className="align-self-center">
                                                                    <p className="mb-0 small py-2">
                                                                        Time saved for ({momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM')+ ' to '+ momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM')}) {this.state.numberofDays && this.state.numberofDays} {this.state.numberofDays && this.state.numberofDays > 1 ? ' days' : ' day'} is <span className="font-weight-bolder text-purple-2">{unitTimeConvertionDetail(this.state.businessPeriodCounts && this.state.businessPeriodCounts.time_saved ? this.state.businessPeriodCounts.time_saved : 0, 'Seconds')}</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex mt-2">
                                                                <div className="mr-4 pr-1">
                                                                    <div className="font-weight-bold text-center box-shadow cursorPointer" id="bv_time_saved_total">
                                                                        <p className="mb-0 align-self-center font-weight-normal">{unitTimeConvertion(this.state.businessTotalCounts && this.state.businessTotalCounts.time_saved ? this.state.businessTotalCounts.time_saved : 0, (this.state.businessTotalCounts && this.state.businessTotalCounts.time_saved_unit ? capitalizeFirstLetter(this.state.businessTotalCounts.time_saved_unit) : 'Seconds'))}</p>
                                                                    </div>
                                                                    <UncontrolledTooltip target="bv_time_saved_total">Total: {this.state.businessTotalCounts && this.state.businessTotalCounts.time_saved_converted ? this.state.businessTotalCounts.time_saved_converted : 0}</UncontrolledTooltip>
                                                                    <h6 className="my-2 f16  text-white text-center font-weight-normal">Total</h6>
                                                                </div>
                                                                <div className="mr-4 pr-1 align-self-end">
                                                                    <div className="font-weight-bold text-center box-shadow cursorPointer" id="bv_time_saved_monthly">
                                                                        <p className="mb-0 align-self-center font-weight-normal">{unitTimeConvertion(this.state.businessTotalCounts && this.state.businessTotalCounts.monthly ? this.state.businessTotalCounts.monthly.time_saved : 0, (this.state.businessTotalCounts && this.state.businessTotalCounts.monthly.time_saved_unit ? capitalizeFirstLetter(this.state.businessTotalCounts.monthly.time_saved_unit) : 'Seconds'))}</p>
                                                                    </div>
                                                                    <UncontrolledTooltip target="bv_time_saved_monthly">Monthly: {this.state.businessTotalCounts && this.state.businessTotalCounts.monthly && this.state.businessTotalCounts.monthly.time_saved_converted ? this.state.businessTotalCounts.monthly.time_saved_converted : 0}</UncontrolledTooltip>
                                                                    <p className="my-2 f12 text-white text-center font-weight-normal">Monthly
                                                                    </p>
                                                                </div>
                                                                <div className="mr-4 pr-1 align-self-end">
                                                                    <div className="font-weight-bold text-center box-shadow box-shadow-1 cursorPointer" id="bv_time_saved_weekly">
                                                                        <p className="mb-0 align-self-center font-weight-normal">{unitTimeConvertion(this.state.businessTotalCounts && this.state.businessTotalCounts.weekly ? this.state.businessTotalCounts.weekly.time_saved : 0, (this.state.businessTotalCounts && this.state.businessTotalCounts.weekly.time_saved_unit ? capitalizeFirstLetter(this.state.businessTotalCounts.weekly.time_saved_unit) : 'Seconds'))}</p>
                                                                    </div>
                                                                    <UncontrolledTooltip target="bv_time_saved_weekly">Weekly: {this.state.businessTotalCounts && this.state.businessTotalCounts.weekly && this.state.businessTotalCounts.weekly.time_saved_converted ? this.state.businessTotalCounts.weekly.time_saved_converted : 0}</UncontrolledTooltip>
                                                                    <p className="my-2 f12 text-white text-center font-weight-normal">Weekly</p>
                                                                </div>
                                                                <div className="align-self-end">
                                                                    <div className="font-weight-bold text-center box-shadow box-shadow-1 cursorPointer" id="bv_time_saved_daily">
                                                                        <p className="mb-0 align-self-center font-weight-normal">{unitTimeConvertion(this.state.businessTotalCounts && this.state.businessTotalCounts.daily ? this.state.businessTotalCounts.daily.time_saved : 0, (this.state.businessTotalCounts && this.state.businessTotalCounts.daily.time_saved_unit ? capitalizeFirstLetter(this.state.businessTotalCounts.daily.time_saved_unit) : 'Seconds'))}</p>
                                                                    </div>
                                                                    <UncontrolledTooltip target="bv_time_saved_daily">Daily: {this.state.businessTotalCounts && this.state.businessTotalCounts.daily && this.state.businessTotalCounts.daily.time_saved_converted ? this.state.businessTotalCounts.daily.time_saved_converted : 0}</UncontrolledTooltip>
                                                                    <p className="my-2 f12 text-white text-center font-weight-normal">Daily</p>
                                                                </div>
                                                            </div>
                                                            {this.state.business_trend_time_saved && Object.entries(this.state.business_trend_time_saved).length ?
                                                                <div className="mt-n4 mb-n2">
                                                                    <ApexBarChart
                                                                        graphData={this.state.business_trend_time_saved}
                                                                        sparkline={false}
                                                                        yaxis={false}
                                                                        yaxisLabel={true}
                                                                        xaxis={true}
                                                                        xaxisFormat={'datetime'}
                                                                        xaxisLabel={true}
                                                                        axisLabelColor={'#B8BBBE'}
                                                                        paddingLeft={0}
                                                                        legend={false}
                                                                        stacked={true}
                                                                        height={160}
                                                                        horizontal={false}
                                                                        barHeight={'40%'}
                                                                        barEndShape={'flat'}
                                                                        columnWidth={'25%'}
                                                                        gradient={false}
                                                                        gradientColor={['#039BE5', '#5F5BA2']}
                                                                        hideTooltipValue={true}
                                                                        backgroundBarShape={'flat'}
                                                                        colors={["#5F5BA2"]}
                                                                        backgroundBarColors={['#333947']}
                                                                        showBackgroundBarColors={false}
                                                                        className={"transparentTooltip"}
                                                                    />
                                                                </div>
                                                            : null}
                                                        </div>
                                                        <div className="bg-dark-25 px-3 py-1 text-center">
                                                            <h6 className="text-white mb-0">Duration</h6>
                                                            <div className="d-flex bg-muted justify-content-center mt-1">
                                                                <div className="align-self-center">
                                                                    <p className="mb-0 small py-2">
                                                                        Total duration Jobs ran ({momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM')+ ' to '+ momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM')}) {this.state.numberofDays && this.state.numberofDays} {this.state.numberofDays && this.state.numberofDays > 1 ? ' days' : ' day'} is <span className="font-weight-bolder text-success" id="bv_duration_days"> {unitTimeConvertionDetail(this.state.businessPeriodCounts && this.state.businessPeriodCounts.duration ? this.state.businessPeriodCounts.duration : 0, 'Seconds')}</span>
                                                                    </p>                                                                 
                                                                    {/* <UncontrolledTooltip target="bv_duration_days">Total: {this.state.businessTotalCounts && this.state.businessTotalCounts.duration_converted ? this.state.businessTotalCounts.duration_converted : 0}</UncontrolledTooltip> */}
                                                                </div>
                                                            </div>
                                                            <div className="d-flex mt-2">
                                                                <div className="mr-4 pr-1">
                                                                    <div className="font-weight-bold text-center box-shadow box-shadow-border-green cursorPointer" id="bv_duration_total">
                                                                        <p className="mb-0 align-self-center font-weight-normal">{unitTimeConvertion(this.state.businessTotalCounts && this.state.businessTotalCounts.duration ? this.state.businessTotalCounts.duration : 0, (this.state.businessTotalCounts && this.state.businessTotalCounts.duration_unit ? capitalizeFirstLetter(this.state.businessTotalCounts.duration_unit) : 'Seconds'))}</p>
                                                                    </div>                                                                    
                                                                    <UncontrolledTooltip target="bv_duration_total">Total: {this.state.businessTotalCounts && this.state.businessTotalCounts.duration_converted ? this.state.businessTotalCounts.duration_converted : 0}</UncontrolledTooltip>
                                                                    <h6 className="my-2 f16  text-white text-center font-weight-normal">Total</h6>
                                                                </div>
                                                                <div className="mr-4 pr-1 align-self-end">
                                                                    <div className="font-weight-bold text-center box-shadow box-shadow-border-green cursorPointer" id="bv_duration_monthly">
                                                                        <p className="mb-0 align-self-center font-weight-normal">{unitTimeConvertion(this.state.businessTotalCounts && this.state.businessTotalCounts.monthly ? this.state.businessTotalCounts.monthly.duration : 0, (this.state.businessTotalCounts && this.state.businessTotalCounts.monthly.duration_unit ? capitalizeFirstLetter(this.state.businessTotalCounts.monthly.duration_unit) : 'Seconds'))}</p>
                                                                    </div>                                                                    
                                                                    <UncontrolledTooltip target="bv_duration_monthly">Monthly: {this.state.businessTotalCounts && this.state.businessTotalCounts.monthly && this.state.businessTotalCounts.monthly.duration_converted ? this.state.businessTotalCounts.monthly.duration_converted : 0}</UncontrolledTooltip>
                                                                    <p className="my-2 f12 text-white text-center font-weight-normal">Monthly
                                                                    </p>
                                                                </div>
                                                                <div className="mr-4 pr-1 align-self-end">
                                                                    <div className="font-weight-bold text-center box-shadow box-shadow-border-green box-shadow-1 cursorPointer" id="bv_duration_weekly">
                                                                        <p className="mb-0 align-self-center font-weight-normal">{unitTimeConvertion(this.state.businessTotalCounts && this.state.businessTotalCounts.weekly ? this.state.businessTotalCounts.weekly.duration : 0, (this.state.businessTotalCounts && this.state.businessTotalCounts.weekly.duration_unit ? capitalizeFirstLetter(this.state.businessTotalCounts.weekly.duration_unit) : 'Seconds'))}</p>
                                                                    </div>                                                                    
                                                                    <UncontrolledTooltip target="bv_duration_weekly">Weekly: {this.state.businessTotalCounts && this.state.businessTotalCounts.weekly && this.state.businessTotalCounts.weekly.duration_converted ? this.state.businessTotalCounts.weekly.duration_converted : 0}</UncontrolledTooltip>
                                                                    <p className="my-2 f12 text-white text-center font-weight-normal">Weekly</p>
                                                                </div>
                                                                <div className="align-self-end">
                                                                    <div className="font-weight-bold text-center box-shadow box-shadow-border-green box-shadow-1 cursorPointer" id="bv_duration_daily">
                                                                        <p className="mb-0 align-self-center font-weight-normal">{unitTimeConvertion(this.state.businessTotalCounts && this.state.businessTotalCounts.daily ? this.state.businessTotalCounts.daily.duration : 0, (this.state.businessTotalCounts && this.state.businessTotalCounts.daily.duration_unit ? capitalizeFirstLetter(this.state.businessTotalCounts.daily.duration_unit) : 'Seconds'))}</p>
                                                                    </div>                                                                    
                                                                    <UncontrolledTooltip target="bv_duration_daily">Daily: {this.state.businessTotalCounts && this.state.businessTotalCounts.daily && this.state.businessTotalCounts.daily.duration_converted ? this.state.businessTotalCounts.daily.duration_converted : 0}</UncontrolledTooltip>
                                                                    <p className="my-2 f12 text-white text-center font-weight-normal">Daily</p>
                                                                </div>
                                                            </div>
                                                            {this.state.business_trend_duration && Object.entries(this.state.business_trend_duration).length ?
                                                                <div className="mt-n4 mb-n2">
                                                                    <ApexBarChart
                                                                        graphData={this.state.business_trend_duration}
                                                                        sparkline={false}
                                                                        yaxis={false}
                                                                        yaxisLabel={true}
                                                                        xaxis={true}
                                                                        xaxisFormat={'datetime'}
                                                                        xaxisLabel={true}
                                                                        axisLabelColor={'#B8BBBE'}
                                                                        paddingLeft={0}
                                                                        legend={false}
                                                                        stacked={true}
                                                                        height={160}
                                                                        horizontal={false}
                                                                        barHeight={'40%'}
                                                                        barEndShape={'flat'}
                                                                        columnWidth={'25%'}
                                                                        gradient={false}
                                                                        gradientColor={['#039BE5', '#5F5BA2']}
                                                                        hideTooltipValue={true}
                                                                        backgroundBarShape={'flat'}
                                                                        colors={["#5F5BA2"]}
                                                                        backgroundBarColors={['#333947']}
                                                                        showBackgroundBarColors={false}
                                                                        className={"transparentTooltip"}
                                                                    />
                                                                </div>
                                                            : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="p-3">
                                                        <div className="row">
                                                            <div className="d-flex justify-content-between mb-1 col-sm-12">
                                                                <div className="d-flex">
                                                                    <h6 className="text-white mb-0">By Type -</h6>
                                                                    <small className="m-0 ml-1 align-self-center text-primary-color">View the Status of Automations by Type</small>
                                                                </div>
                                                                <i className="fa fa-expand-wide cursorPointer f15 align-self-center" onClick={() => this.setState({ showExpandedView: true })}></i>
                                                            </div>
                                                        </div>
                                                        <div className="tab-content p-0" id="myTabContent">
                                                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                                {this.state.automationType && this.state.automationType.length ?
                                                                    <ul className={`nav nav-tabs border-bottom bd-highlight bg-transparent shadow-none ${this.state.showLoading ? 'disabled' : ''}`} id="myTab" role="tablist">
                                                                        {this.state.automationType.map(item => {
                                                                            return(
                                                                                <li className="nav-item">
                                                                                    <span className={`nav-link text-white cursorPointer ${this.state.activeAutomationTab === item ? ' active' : ''}`} onClick={() => this.setState({ activeAutomationTab: item }, () => this.onChangeAutomationType())}>{item}</span>
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                : null}
                                                            </div>
                                                            <div className="tab-content p-0" id="myTabContent">
                                                                <div className="tab-pane fade show active" id="1home" role="tabpanel" aria-labelledby="1home-tab">
                                                                    <div className="px-3 py-2 h-100">
                                                                        <div className="d-flex bg-muted justify-content-center">
                                                                            <div className="align-self-center">
                                                                            <p className="mb-0 small py-2">
                                                                                Automation Job Status for ({momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM')+ ' to '+ momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM')}) {this.state.numberofDays && this.state.numberofDays} {this.state.numberofDays && this.state.numberofDays > 1 ? ' days' : ' day'} is <span className="font-weight-bolder text-purple-2">{this.state.applicationPeriodCount ? this.state.applicationPeriodCount : 0}</span>
                                                                            </p>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        <div className="d-flex mt-2">
                                                                            <div className="mr-4 pr-1">
                                                                                <div className="font-weight-bold text-center box-shadow" id="application_total">
                                                                                    <p className="mb-0 align-self-center font-weight-normal">{numberFormater(this.state[this.state.activeAutomationTab+"_total"] ? this.state[this.state.activeAutomationTab+"_total"] : 0)}</p>
                                                                                </div>
                                                                                <UncontrolledTooltip target="application_total">Total: {this.state[this.state.activeAutomationTab+"_total"] ? this.state[this.state.activeAutomationTab+"_total"] : 0}</UncontrolledTooltip>
                                                                                <h6 className="my-2 f16  text-white text-center font-weight-normal">Total</h6>
                                                                            </div>
                                                                            <div className="mr-4 pr-1 align-self-end">
                                                                                <div className="font-weight-bold text-center box-shadow" id="application_montly">
                                                                                    <p className="mb-0 align-self-center font-weight-normal">{numberFormater(this.state[this.state.activeAutomationTab+"_monthly"] ? this.state[this.state.activeAutomationTab+"_monthly"] : 0)}</p>
                                                                                </div>
                                                                                <UncontrolledTooltip target="application_montly">Monthly: {this.state[this.state.activeAutomationTab+"_monthly"] ? this.state[this.state.activeAutomationTab+"_monthly"] : 0}</UncontrolledTooltip>
                                                                                <p className="my-2 f12 text-white text-center font-weight-normal">Monthly
                                                                                </p>
                                                                            </div>
                                                                            <div className="mr-4 pr-1 align-self-end">
                                                                                <div className="font-weight-bold text-center box-shadow box-shadow-1" id="application_weekly">
                                                                                    <p className="mb-0 align-self-center font-weight-normal">{numberFormater(this.state[this.state.activeAutomationTab+"_weekly"] ? this.state[this.state.activeAutomationTab+"_weekly"] : 0)}</p>
                                                                                </div>
                                                                                <UncontrolledTooltip target="application_weekly">Weekl;y: {this.state[this.state.activeAutomationTab+"_weekly"] ? this.state[this.state.activeAutomationTab+"_weekly"] : 0}</UncontrolledTooltip>
                                                                                <p className="my-2 f12 text-white text-center font-weight-normal">Weekly</p>
                                                                            </div>
                                                                            <div className="align-self-end">
                                                                                <div className="font-weight-bold text-center box-shadow box-shadow-1" id="application_daily">
                                                                                    <p className="mb-0 align-self-center font-weight-normal">{numberFormater(this.state[this.state.activeAutomationTab+"_daily"] ? this.state[this.state.activeAutomationTab+"_daily"] : 0)}</p>
                                                                                </div>
                                                                                <UncontrolledTooltip target="application_daily">Daily: {this.state[this.state.activeAutomationTab+"_daily"] ? this.state[this.state.activeAutomationTab+"_daily"] : 0}</UncontrolledTooltip>
                                                                                <p className="my-2 f12 text-white text-center font-weight-normal">Daily</p>
                                                                            </div>
                                                                        </div>
                                                                        {this.state.showAutomationTrend && this.state['automation_trend_'+this.state.activeAutomationTab] && Object.entries(this.state['automation_trend_'+this.state.activeAutomationTab]).length ?
                                                                            <React.Fragment>
                                                                            <div className="mt-n3 mb-n3">
                                                                                <ApexBarChart
                                                                                    graphData={this.state['automation_trend_'+this.state.activeAutomationTab]}
                                                                                    sparkline={false}
                                                                                    yaxis={false}
                                                                                    yaxisLabel={true}
                                                                                    xaxis={true}
                                                                                    xaxisFormat={'datetime'}
                                                                                    xaxisLabel={true}
                                                                                    axisLabelColor={'#B8BBBE'}
                                                                                    paddingLeft={0}
                                                                                    legend={false}
                                                                                    stacked={true}
                                                                                    height={160}
                                                                                    horizontal={false}
                                                                                    barHeight={'40%'}
                                                                                    barEndShape={'flat'}
                                                                                    columnWidth={'25%'}
                                                                                    gradient={false}
                                                                                    gradientColor={['#039BE5', '#5F5BA2']}
                                                                                    hideTooltipValue={true}
                                                                                    backgroundBarShape={'flat'}
                                                                                    colors={["#5F5BA2"]}
                                                                                    backgroundBarColors={['#333947']}
                                                                                    showBackgroundBarColors={false}
                                                                                    className={"transparentTooltip"}
                                                                                />
                                                                            </div>
                                                                            <div className="border-bottom mt-2"></div>
                                                                            </React.Fragment>
                                                                        : null}  
                                                                        <div className="d-flex justify-content-between mt-2">
                                                                            <div className="align-self-center">
                                                                                <h6 className="text-white mb-0 ">Top 5 Automations</h6>
                                                                            </div>
                                                                            
                                                                            <div className="w-25 select-sm-bg curvedDropdown minWidth150 mr-2" ref={this.topAutomationRef}>
                                                                                <div className="dropdown">
                                                                                    <span className="dropdown-toggle d-flex justify-content-between px-2 text-white" onClick={() => this.setState({ showTopAutomationFilter: !this.state.showTopAutomationFilter })}>
                                                                                        {this.state.selectedTopAutomationFilter ? this.state.selectedTopAutomationFilter : <span className="placeholder">Select</span>}
                                                                                        <span className="caret"></span>
                                                                                        {/* <i className="fas fa-angle-down"></i> */}
                                                                                    </span>
                                                                                    <ul className={`dropdown-menu p-0 ${this.state.showTopAutomationFilter ? "show" : ''}`}>
                                                                                        {this.state.topAutomationType && this.state.topAutomationType.map(item => {
                                                                                            return (
                                                                                                <li 
                                                                                                    onClick={() => this.setState({ 
                                                                                                            selectedTopAutomationFilter: item, 
                                                                                                            showTopAutomationFilter: false,
                                                                                                            topAutomationData: {} 
                                                                                                    }, 
                                                                                                        () => this.topAutomationSection()
                                                                                                    )} 
                                                                                                    value={item} 
                                                                                                    className={`${this.state.selectedTopAutomationFilter === item ? "selected" :  ""}`}
                                                                                                >
                                                                                                    {item}
                                                                                                </li>
                                                                                            )
                                                                                        })}
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                            {/* <div className="select-bg w-25">
                                                                                <div className="form-group bd-highlight styled-feild bg-transparent mb-0">
                                                                                    <select className="form-control margin-8" 
                                                                                        value={this.state.selectedTopAutomationFilter}
                                                                                        onChange={event => {
                                                                                            this.setState(
                                                                                                {
                                                                                                    selectedTopAutomationFilter: event.target.value,
                                                                                                    topAutomationData: {}
                                                                                                },
                                                                                                () => {
                                                                                                    this.topAutomationSection()
                                                                                                }
                                                                                            )
                                                                                        }}>
                                                                                        {this.state.topAutomationType && this.state.topAutomationType.map(item => {
                                                                                            return (
                                                                                                <option value={item}>{item}</option>
                                                                                            )
                                                                                        })}
                                                                                    </select>
                                                                                </div>
                                                                            </div> */}
                                                                        </div>

                                                                        <small className="mt-1 mb-0 small text-primary-color">View top 5 automation jobs by selection - {this.state.selectedTopAutomationFilter}</small>
                                                                        
                                                                        {this.state.topAutomationData && Object.entries(this.state.topAutomationData).length ?
                                                                            <div className="mt-n2"> 
                                                                                <ApexBarChart
                                                                                    graphData={this.state.topAutomationData}
                                                                                    sparkline={false}
                                                                                    yaxis={false}
                                                                                    yaxisLabel={false}
                                                                                    xaxis={true}
                                                                                    xaxisFormat={'string'}
                                                                                    xaxisLabel={true}
                                                                                    axisLabelColor={'#B8BBBE'}
                                                                                    paddingLeft={-25}
                                                                                    legend={false}
                                                                                    stacked={false}
                                                                                    height={240}
                                                                                    horizontal={true}
                                                                                    barHeight={'40%'}
                                                                                    barEndShape={'rounded'}
                                                                                    columnWidth={'25%'}
                                                                                    gradient={true}
                                                                                    gradientColor={['#039BE5', '#5F5BA2']}
                                                                                    hideTooltipValue={true}
                                                                                    backgroundBarShape={'rounded'}
                                                                                    backgroundBarColors={['#333947']}
                                                                                    showBackgroundBarColors={false}
                                                                                    className={"transparentTooltip"}
                                                                                />
                                                                            </div>
                                                                        : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            : 
                                <AutomationPerView
                                    showExpandedView={() => this.setState({ showExpandedView: false })}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
Dashboard.propTypes = {
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('automation',state)
	return {
        accounts: state.filters.accounts,
		regions: state.filters.regions,
    }
}

export default AppWrapper(Dashboard, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
    setAiopsPropsDetails,
    getApis,
})