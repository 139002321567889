/*************************************************
 * Tvastar
 * @exports
 * @file governanceAction.js
 * @author Rajasekar // on 27/02/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'

import {
    DLPTHREATENDPOINT,
	DLP_REPORT_LIST_REPORTS,
	DLP_REPORT_GET_SCHEDULED_REPORTS,
	DLP_REPORT_INSERT_REPORTS,
	DLP_REPORT_UPDATE_REPORTS,
	DLP_REPORT_DELETE_REPORTS,
	DLP_REPORT_GENERATE_REPORT,
	DLP_REPORT_LIST_GENERATED_REPORTS,
	DLP_REPORT_GET_BUCKETS_PREFIXES,
	DLP_REPORT_LIST_MASTER_REPORTS,
	DLP_REPORT_DOWNLOAD_REPORT,
	// DLP_REPORT_INSERT_MASTER_REPORTS,
	// DLP_REPORT_UPDATE_MASTER_REPORTS,
	// DLP_REPORT_DELTE_MASTER_REPORTS,
	
} from '../../config'
import { myLog } from '../../utils/utility'

/**
 * Action to get list of reports
 * @param {Object} body
 * @param {Function} callback
*/
export const listReports = (body, callback) => {
	return async () => {
		try {
			const results = await API.post(DLPTHREATENDPOINT, DLP_REPORT_LIST_REPORTS, { body })
			callback(true, results)
		} catch (error) {
			callback(false)
			myLog('daywiseCount', error)
		}
	}
}

/**
 * Action to get get scheduled of reports
 * @param {Object} body
 * @param {Function} callback
*/
export const getSchedulReports = (body, callback) => {
	return async () => {
		try {
			const results = await API.post(DLPTHREATENDPOINT, DLP_REPORT_GET_SCHEDULED_REPORTS, { body })
			callback(true, results)
		} catch (error) {
			callback(false)
			myLog('daywiseCount', error)
		}
	}
}

/** Action to Insert report
* @param {Object} body
* @param {Function} callback
*/
export const insertReports = (body, callback) => {
	return async () => {
		try {
			const results = await API.post(DLPTHREATENDPOINT, DLP_REPORT_INSERT_REPORTS, { body })
			callback(true, results)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to update report
 * @param {Object} body
 * @param {Function} callback
*/
export const updateReports = (body, callback) => {
	return async () => {
		try {
			const results = await API.post(DLPTHREATENDPOINT, DLP_REPORT_UPDATE_REPORTS, { body })		
			callback(true, results)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to delete report
 * @param {Object} body
 * @param {Function} callback
*/
export const deleteReports = (body, callback) => {
	return async () => {
		try {
			const result = await API.post(DLPTHREATENDPOINT, DLP_REPORT_DELETE_REPORTS, { body })
			callback(true, result)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to list generated reports
 * @param {Object} body
 * @param {Function} callback
*/
export const generateReport = (body, callback) => {
	return async () => {
		try {
			const result = await API.post(DLPTHREATENDPOINT, DLP_REPORT_GENERATE_REPORT, { body })
			callback(true, result)
		} catch (error) {
			callback(false)
		}
	}
}
/**
 * Action to list generated reports
 * @param {Object} body
 * @param {Function} callback
*/
export const listGeneratedReports = (body, callback) => {
	return async () => {
		try {
			const result = await API.post(DLPTHREATENDPOINT, DLP_REPORT_LIST_GENERATED_REPORTS, { body })
			callback(true, result)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to list master reports
 * @param {Object} body
 * @param {Function} callback
*/
export const listMasterReports = (body, callback) => {
	return async () => {
		try {
			const result = await API.post(DLPTHREATENDPOINT, DLP_REPORT_LIST_MASTER_REPORTS, { body })
			callback(true, result)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to get buckets prefixes
 * @param {Object} body
 * @param {Function} callback
*/
export const getBucketsPrefixes = (body, callback) => {
	return async () => {
		try {
			const result = await API.post(DLPTHREATENDPOINT, DLP_REPORT_GET_BUCKETS_PREFIXES, { body })
			callback(true, result)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to get download reports
 * @param {Object} body
 * @param {Function} callback
*/
export const downloadReport = (body, callback) => {
	return async () => {
		try {
			const results = await API.post(DLPTHREATENDPOINT, DLP_REPORT_DOWNLOAD_REPORT, { body })
			callback(true, results)
		} catch (error) {
			callback(false)
			myLog('daywiseCount', error)
		}
	}
}