/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file RootCauseAnalysis.js
 * @author Prakash // on 07/10/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { URL_PATH } from '../../../config/urlPath'
import { AppWrapper } from '../../common/AppWrapper'

import { setDiagnosticsPropsDetails, getTroubleshootDetails } from '../../../actions/aiops/DiagnosticsAction'

import RcaLeftSection from './RcaLeftSection'
import RcaRightSection from './RcaRightSection'

class RootCauseAnalysis extends Component {
    
    constructor(props) {

        super(props)
        this.scrollRef = React.createRef()
        
        this.state = {}
    }

    componentDidMount = () => {
        if (this.props.location && this.props.location.state && Object.keys(this.props.location.state).length !== 0) {
            this.setState({ diagnostics: this.props.location.state.diagnostics, showLoading: true },
                () => this.getTroubleshootDetails()    
            )
        } else {
            this.props.history.push({
                pathname: URL_PATH.LIST_DIAGNOSTICS,
            })
        }
    }

    getTroubleshootDetails = () => {
        if(this.state.diagnostics && this.state.diagnostics.diagnostic_id) {
            let params = {}
            params.diagnostic_id = this.state.diagnostics.diagnostic_id //"a6d590ef-51b1-42b5-a290-97af140b1141" //"31819266-da40-4220-b1cb-a324e14e83d8" //"c92dfbe8-cf53-482b-966a-9b9b0815f17d" //"2992fbea-d543-4133-b24b-f100ce0235fc" //this.state.diagnostics.diagnostics_id

            this.props.getTroubleshootDetails(params, (promise, response) => {
                if(promise) {
                    this.setState({ showLoading: false })
                } else {
                    this.setState({ showLoading: false })
                }
            })

        }
    }

	render() {
		return (
            <div class={`main-content bg-muted`}>
                <div className={`loaderOverlay ${this.state.showLoading ? "" : "displayNone"}`}>
                    <div className="overlayEqualizerLoader">
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                    </div>
                </div>
                <div className={`container-fluid overflow-auto flex-grow-1`}>
                    {/* {this.props.troubleshootDetails && this.props.troubleshootDetails.length ? */}
                        <div className="row h-100">
                            <div className={`border-right col-md-6 p-0`}>
                                <RcaLeftSection 
                                    diagnostics = {this.state.diagnostics}
                                />
                            </div>
                            <div className={`col-md-6`}>
                                <RcaRightSection 
                                    diagnostics = {this.state.diagnostics}
                                />
                            </div>
                        </div>
                    {/* : null} */}
                </div>
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
 RootCauseAnalysis.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log("-",state)
	return {
        troubleshootDetails: state.aiops.diagnosticsTroubleshootDetails ? state.aiops.diagnosticsTroubleshootDetails : {},
    }
}

export default AppWrapper(RootCauseAnalysis, mapStateToProps, {
    getTroubleshootDetails
})