import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Checkbox from '../../../common/formFields/Checkbox'
import { setAiopsPropsDetails } from '../../../../actions/aiops/AiopsAction'
import { propTypes } from 'react-notification-system/dist/NotificationContainer'

class EnableRDSMulti extends Component {

  constructor(props) {
    super(props);
    this.state = props || {};
    this.state = {
      validationMessage: '',
    }
    this.updateFormFieldValue = this.updateFormFieldValue.bind(this);
  }

  componentDidMount = () => {}

	componentDidUpdate = (prevProps) => {
		if(this.props.validationError !== prevProps.validationError) {
			this.setState({ 
        validationError: this.props.validationError,
        ApplyImmediatelyError: this.props.aiopsRemediationParams.ApplyImmediatelyError ? this.props.aiopsRemediationParams.ApplyImmediatelyError : ''
      })
		}
	}

  updateFormFieldValue = (fieldName, fieldValue) => {
    let obj = this.props.aiopsRemediationParams
    obj[fieldName] = fieldValue
    obj.processor_rule_id = this.props.selectedRecord
    this.props.setAiopsPropsDetails('remediationParams', obj)

    this.setState({ ApplyImmediatelyError: '', [fieldName]: fieldValue });
  };

  render() {
    return (
      this.props.selectedRecord && this.props.selectedRecord.parameters.map((item, index) => {
        return (
        <div className="row mt-2">
          <div className="col-md-12">
            <div className="form-group">
              <Checkbox 
                className="form-check-input"
                labelClassName={'text-white'}
                recordSelection={this.updateFormFieldValue}
                value={this.state.ApplyImmediately ? true : false}
                name={'ApplyImmediately'}
                label={'ApplyImmediately'}
              />
              {this.state.validationError && this.state.ApplyImmediatelyError && this.state.ApplyImmediatelyError !== '' ?
                <small className='text-danger'>{this.state.ApplyImmediatelyError}</small>
              : null }
            </div>
          </div>
        </div>
        )
      })
    )
  }
};

/**
 * Type of the props used in the component
 */
 EnableRDSMulti.propTypes = {
  setAiopsPropsDetails: PropTypes.func,
  formInputState: propTypes.func
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
 const mapStateToProps = state => {	
	// console.log('mapStateToProps', state)  
	return {
    caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : [],
    aiopsRemediationParams: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.remediationParams ? state.aiops.aiopsPropsDetails.remediationParams : {},
    aiopsSelectedRemediationProps: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.selected_remediation ? state.aiops.aiopsPropsDetails.selected_remediation : {},
  }
}


export default connect(mapStateToProps, {
  setAiopsPropsDetails
})(withRouter(EnableRDSMulti))
