/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file DlpJobs.js
 * @author Prakash // on 16/09/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { AppWrapper } from '../../common/AppWrapper'

import _ from 'lodash'
import { Spinner } from 'reactstrap'
import { store as CommonNotification } from 'react-notifications-component';

import { momentConvertionUtcToLocalTime, getAccountNameFromId } from '../../../utils/utility'
import SearchWithHiddenInput from '../../common/SearchWithHiddenInput'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'

import { listMsc, deleteMsc } from '../../../actions/dlp/DlpJobsAction'
import { listAllAccounts } from '../../../actions/commonAction'
import SidePanel from './SidePanel'

class DlpJobs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            jobType: ''
        }
    }

	componentDidMount = () => {
		this.getAccounts()
        if(!this.props.dlpJobsList || !this.props.dlpJobsList.length) {
            this.listMsc()
        } else {
            this.setState({ showLoading: false })
        }
    }

	getAccounts = () => {
        if(!this.props.accounts || !this.props.accounts.length) {
            let params = {}
            this.props.listAllAccounts(params, (promise, response) => {})
        }
    }

	listMsc = () => {
        this.setState({ showLoading: true }, 
            () => { 
                let params = {}
                params.job_type = ['dlp_threats_job', 'dlp_ueba_job', 'dlp_data_events_processor', 'dlp_data_events_consolidator_hourly', 'dlp_data_events_consolidator_daily', 'dlp_access_logs_processor', 'dlp_data_events_aggregator_daily', 'dlp_inventory_processor_daily']
                this.props.listMsc(params, (promise, response) => {
                    this.setState({ showLoading: false })
                })
            }
        )
    }

    deleteConfirmation = (msc_id) => {
        if (window.confirm("Are you sure, you want to delete the selected job?")) {
            this.setState({ showDeleteLoading: true },
                () => this.deleteMsc(msc_id)
            )
        }
    }

    deleteMsc = (msc_id) => {
        let params = {}
        params.msc_id = [msc_id]

        this.props.deleteMsc(params, (promise, response) => {
            if(promise) {
                this.setState({ showDeleteLoading: false },
                    () => this.listMsc()
                )
                let messageType = 'danger'		
                let message = 'Job deleted'
                CommonNotification.addNotification({
                    message: message,
                    type: messageType,
                    insert: "top",
                    container: "top-center",
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                })
            }
        })
    }

    filterList = () => {
        let list = this.props.dlpJobsList
        if(list.length && this.state.jobType !== '') {
            if(this.state.jobType === 'dlp_data_events_consolidator') {
                list = this.props.dlpJobsList.filter(arr => (arr.job_type === 'dlp_data_events_consolidator_hourly' || arr.job_type === 'dlp_data_events_consolidator_daily'))
            } else {
                list = this.props.dlpJobsList.filter(arr => arr.job_type === this.state.jobType)
            }
        }

        this.setState({ filteredList: list, jobData: list, emptySearchText: true  })
    }

	searchJobsData = (searchArray, searchText) => {
		let filteredList = this.props.dlpJobsList
		if(this.state.jobType && this.state.jobType !== '') {
			filteredList = this.state.jobData
		}
		if(searchText !== '') {
			filteredList = searchArray
		}
		this.setState({ filteredList: _.orderBy(filteredList, ['date'], ['desc']), emptySearchText: false })
	}

    render() {
        return(
            <div className='container-fluid overflow-auto flex-grow-1'>
				{this.state.showSidePanel ? 
					<SidePanel
						closeViewSidePanel={() => this.setState({ showSidePanel: false })} 
						pageType={this.state.sideViewType}
						selectedRecord={this.state.selectedRecord ? this.state.selectedRecord : {}}
						onUpdate={() => this.listMsc()}
					/>
				: null}
                <div className='row h-100'>
					<div className='col-sm-12 p-0'>
						<div className='title-section p-2'>
							<div className="row">
								<div className="col-5 mt-0">
									<h6 className="text-white m-0">DLP Jobs</h6>
									<p className="text-white m-0">Showing the job details</p>
									<div className="row mt-3">
									</div>
								</div>
                                <div className="col-7 text-right">
									<div className={`d-flex justify-content-end align-self-center`}>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='container-fluid'>                                
                            <div className="row">
                                <div className="col-sm-12 mt-3">
									<div className="bg-muted p-3 rounded h-100">
										<div className="d-flex justify-content-end mb-2">
											<span className='btn-sm btn-primary align-self-center cursorPointer' 
												onClick={() => { this.setState({ showSidePanel: true, sideViewType: 'create' })}}>
												Create
											</span>
										</div>
										<div className="rounded bg-dark p-2 w-100">
											{!this.state.showLoading ?
												<React.Fragment>
													<div className="d-flex justify-content-between">
														<div className="d-flex">
															<div class={`form-group bd-highlight mb-sm-1 styled-feild`}>
																<select class="form-control" 
																	value={this.state.jobType}
																	onChange={event => {this.setState({ jobType: event.target.value }, () => this.filterList())}}>
																	<option value=''>All</option>
																	<option value='dlp_threats_job'>Threat</option>
																	<option value='dlp_ueba_job'>Ueba</option>
																	<option value='dlp_data_events_processor'>Events Processor</option>
																	<option value='dlp_data_events_consolidator'>Events Consolidator</option>
																	<option value='dlp_access_logs_processor'>Access Logs</option>
																	<option value='dlp_data_events_aggregator_daily'>Aggregator Daily</option>
																	<option value='dlp_inventory_processor_daily'>Inventory Daily</option>
																</select>
															</div>
															<small className="ml-2 align-self-center">Showing {this.state.filteredList ? this.state.filteredList.length : (this.props.dlpJobsList && this.props.dlpJobsList.length)} {(this.state.filteredList ? this.state.filteredList.length : (this.props.dlpJobsList && this.props.dlpJobsList.length)) > 1 ? 'jobs' : 'job'} </small>
														</div>
														{this.props.dlpJobsList && this.props.dlpJobsList.length ?
															<div className="d-flex">
																<div className="form-group mb-0">
																	<SearchWithHiddenInput
																		ID={'alert_search'}
																		data={this.state.jobData ? this.state.jobData : this.props.dlpJobsList}
																		applyLiteDarkTags={true}
																		searchClassName={'border-0'}
																		searchIconColor={'text-gray3'}
																		className="form-control-sm text-gray3 bg-muted border-0"
																		hideInputSection={true}
																		topClassName={'dark-search mb-2'}
																		filteredData={(searchArray, searchText) => {
																			this.setState({ filteredList: _.orderBy(searchArray, ['date'], ['desc']), emptySearchText: false })
																		}}
																		resetSearchBox={this.state.emptySearchText}
																	/>
																</div>
															</div>
														: null}
													</div>
														
													{this.props.dlpJobsList ?
														<ResizeableDarkThemeTable
															columns={[
																{
																	Header: 'Date',
																	accessor: d => d.modified_at ? d.modified_at : d.created_at,
																	Cell: cellInfo => (<span>{cellInfo.row.original.modified_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.modified_at, 'DD MMM YYYY HH:mm') : (cellInfo.row.original.created_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, 'DD MMM YYYY HH:mm')  : '')}</span>)
																},
																{
																	Header: 'Title',
																	accessor: 'name',
																	width: 200
																},
																{
																	Header: 'Type',
																	accessor: 'job_type',
																	Cell: cellInfo => (
																		<React.Fragment>
																		<span className=''>
																			{cellInfo.row.original.job_type === 'dlp_threats_job' ? 
																				'Threats' 
																			: cellInfo.row.original.job_type === 'dlp_ueba_job' ? 
																				'Ueba' 
																			: cellInfo.row.original.job_type === 'dlp_data_events_processor' ? 
																				'Events Processor' 
																			: cellInfo.row.original.job_type === 'dlp_data_events_consolidator_hourly' ? 
																				'Hourly Events'
																			: cellInfo.row.original.job_type === 'dlp_data_events_consolidator_daily' ? 
																				'Daily Events'
																			: cellInfo.row.original.job_type === 'dlp_access_logs_processor' ? 
																				'Access Logs'
																			: cellInfo.row.original.job_type === 'dlp_data_events_aggregator_daily' ? 
																				'Aggregator Daily'
																			: cellInfo.row.original.job_type === 'dlp_inventory_processor_daily' ? 
																				'Inventory Daily'
																			:
																				cellInfo.row.original.job_type
																			}
																		</span>
																		</React.Fragment>
																	),
																	width: 200	
																},
																{
																	Header: 'Provider / Account',
																	accessor: d => d.provider + ' : ' + getAccountNameFromId(d.account_id, this.props.accounts),
																	Cell: cellInfo => (
																		<React.Fragment>
																		<span className=''>
																			{cellInfo.row.original.provider.toUpperCase() + ' : ' + getAccountNameFromId(cellInfo.row.original.account_id, this.props.accounts)}
																		</span>
																		</React.Fragment>
																	),
																	width: 250	
																},
																{
																	Header: 'Details',
																	accessor: 'configuration_text',
																	width: 800
																},
																{
																	Header: 'Action',
																	Cell: cellInfo => (
																		<div className="d-flex justify-content-between align-self-center">
																			<p className="text-white m-0 p-0 cursorPointer">
																				<i className="fal fa-book-open" onClick={() => this.setState({ showSidePanel: true, selectedRecord: cellInfo.row.original, sideViewType: 'view'}) }></i>
																			</p>
																			<p className="text-white m-0 p-0 cursorPointer">
																				<i className="fal fa-user-edit" onClick={() => this.setState({ showSidePanel: true, selectedRecord: cellInfo.row.original, sideViewType: 'edit'}) }></i>
																			</p>
																			<p className="text-white m-0 p-0 cursorPointer">
																				{this.state.showDeleteLoading && cellInfo.row.original.msc_id === this.state.selectedId ? 
																					<Spinner color='dark' size='sm' />
																				:
																					<i className="fal fa-trash" onClick={() => this.setState({ selectedId: cellInfo.row.original.msc_id }, () => this.deleteConfirmation(cellInfo.row.original.msc_id))}></i>
																				}
																			</p>
																		</div>
																	),
																	width:120
																}
															]}
															data={this.state.filteredList ? this.state.filteredList : this.props.dlpJobsList && this.props.dlpJobsList.length ? this.props.dlpJobsList : []}
															perPage={10}
															dashboard={this.state.filteredList ? this.state.filteredList.length : (this.props.dlpJobsList && this.props.dlpJobsList.length ? this.props.dlpJobsList.length : 0)}
															sortByColumn={'Date'}
															tooltipFor={[2,4]}
															onClickRow={tableRow => {}}
															tableSize={'table-sm'}
														/>
													:
														<div className='d-flex justify-content-center m-4'>
															<p>There are no data on this criteria. Please try adjusting your filter.</p>
														</div>
													}
												</React.Fragment>
											: this.state.showLoading ?
												<div className='d-flex justify-content-center m-4'>
													<Spinner color='white' size='lg' />
												</div>
											:
												<div className='d-flex justify-content-center m-4'>
													<p>There are no data on this criteria. Please try adjusting your filter.</p>
												</div>
											}
										</div>
									</div>
								</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
 const mapStateToProps = state => {
    return {
		dlpJobsList: state.dlp.dlpJobsList,
		accounts: state.filters.accounts,
	}
}

export default AppWrapper(DlpJobs, mapStateToProps, {
	listMsc,
	deleteMsc,
    listAllAccounts,
})