import React, { Component } from 'react'
import { setEditRuleDetails } from '../../../actions/governance/governanceAction'
import { apiToCall } from '../../../actions/commonAction'
import { Label, CustomInput } from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

class ServiceRegions extends Component {
	constructor(props) {
		super(props)
		this.state = {
			regions: [],
			selectedState: {},
			selectedTypes: [],
			decision: 'allow',
		}
	}

	componentDidMount = () => {
		this.props.apiToCall('/list-regions', { provider: this.props.parameters.provider }, (success, response) => {
			if (success) {
				this.setState({ regions: response.provider_regions })
			}
		})
		const { parameters } = this.props
		if (parameters) {
			if (parameters.allow && parameters.allow.length > 0) {
				this.setState({ decision: 'allow', selectedTypes: parameters.allow })
				let tmp = {}
				parameters.allow.forEach(item => {
					tmp[item] = true
				})
				this.setState({ selectedState: tmp })
			}
			if (parameters.deny && parameters.deny.length > 0) {
				this.setState({ decision: 'deny', selectedTypes: parameters.deny })
				let tmp = {}
				parameters.deny.forEach(item => {
					tmp[item] = true
				})
				this.setState({ selectedState: tmp })
			}
		}
	}
	handleChange = event => {
		let condition = {}
		if (event.target.name === 'decision') {
			this.setState({ decision: event.target.value })
			condition[event.target.value] = this.state.selectedTypes
		}
		this.props.setEditRuleDetails('condition', condition)
		if (event.target.type === 'checkbox') {
			let name = event.target.name
			this.setState({ selectedState: { ...this.state.selectedState, [name]: event.target.checked } })
			if (event.target.checked) {
				let tmp = this.state.selectedTypes
				tmp.push(event.target.name)
				this.setState({ selectedTypes: tmp })
			} else {
				let tmp = this.state.selectedTypes
				_.pull(tmp, event.target.name)
				this.setState({ selectedTypes: tmp })
			}
			condition[this.state.decision] = this.state.selectedTypes
		}
		this.props.setEditRuleDetails('condition', condition)
	}

	// dividedRegionTypes = region_types => {
	// 	let dividedRegionTypes = []
	// 	for (let index = 0; index < region_types.length; index += 4) {
	// 		if (index < region_types.length) {
	// 			dividedRegionTypes.push(region_types.slice(index, index + 4))
	// 		}
	// 	}
	// 	return dividedRegionTypes
	// }

	render() {
		// const regionTypes = this.dividedRegionTypes(this.state.regions)
		return (
			<div>
				<div className='w-50'>
					<Label for='exampleCustomSelect'>Decision</Label>
					<small className='text-muted'>Lorem ipsum dolor sit amet aedtr.</small>

					<CustomInput
						type='select'
						name='decision'
						id='decision-native-simple'
						onChange={e => this.handleChange(e)}
						value={this.state.decision}
					>
						<option value='allow'>Allow</option>
						<option value='deny'>Deny</option>
					</CustomInput>
				</div>
				<div className='mt-3 font-weight-bold'> Select regions to use:</div>
				<div className='row mt-3 col-12'>
					{this.state.regions &&
						this.state.regions.map(region_type => {
							return (
								<div key={region_type.region} className='col-2'>
									<div className='custom-control custom-checkbox'>
										<CustomInput
											type='checkbox'
											name={region_type.region}
											id={region_type.region}
											label={region_type.name}
											onChange={e => this.handleChange(e)}
											checked={this.state.selectedState[region_type.region] === true ? true : false}
										/>
									</div>
								</div>
							)
						})}
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ServiceRegions.propTypes = {
	logType: PropTypes.object,
	setEditRuleDetails: PropTypes.func,
	apiToCall: PropTypes.func,
	editRuleDetails: PropTypes.object,
	parameters: PropTypes.object,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		editRuleDetails: state.governance.editRuleDetails,
	}
}
export default connect(mapStateToProps, { setEditRuleDetails, apiToCall })(ServiceRegions)
