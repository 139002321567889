/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ConsolidatedComparison.js
 * @author Prakash // on 05/06/2020
 * @copyright © 2020 Tvastar. All rights reserved.
 **************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
////import Chart from 'chart.js'
import { Spinner, UncontrolledTooltip } from 'reactstrap'
import Select from 'react-select';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import _ from 'lodash'
import moment from 'moment'
import { AppWrapper } from '../../common/AppWrapper'
import { listAllProviders, listAllAccounts, listAllRegions, getAllTagsKeys, getAllTagsValues } from '../../../actions/commonAction'
import { 
	getTotalAssetCountObject,
	getDailyActiveAssetCount,
	getTotalAssetCountArray,
	getTotalBilling,
	getScoreDetails,
	getAssetCountByPricingModel
} from '../../../actions/assets/comparisonAction'
import { capitalizeFirstLetter, getAccountNameFromId } from '../../../utils/utility'
import { DURATION_FILTER_OPTIONS } from '../../../utils/constants'
import Chart from 'react-apexcharts'

import TagFilterSection from '../../common/TagFilterSection'

class ConsolidatedComparison extends Component {
	sliderWrap = React.createRef()
	accRegRef = React.createRef()
	tagsRef = React.createRef()

	constructor(props) {
		super(props)

		this.state = {
            changesInFilter: true,
			minMaxHeight: "770px",
			showLoading: false,
			
			totalAccArray: [],
			moveToDestination: [],

			providers: [],
            selectedProvider: '',
			
			accounts: [],
			selectedAccount: [],

            regions: [],
			selectedRegion: [],
			accRegionArray: [0],

			selectedDuration: '+7d',

			tagsArray: [0],
			selectedTags: [],
			tagFilter: false,

			resources: [],
			resourcesLineData: [],
			services: [],
			status: [],
			actions: [],
			dailyBilling: [],
			monthBilling: [],
			threeMonthBilling: [],

			scores: [],
			pricingModel: [],
			
			empty_doughnut_data: [],
			empty_doughnut_option: [],

			slideRightactive: true,
			slideLeftactive: false,

			selectedAction: [],
			tagPlaceholder: 'Select Tag',

			showBy: ["Account", "Region", "Account/Region"],
			showByValue: "Account"
		}
		//this.totalAccounts = [] //number of account for comparison
		this.totalItemShowing = 3
    }
    	
	componentDidMount = () => {
		
		this.props.listAllProviders((promise, providers) => {
			if (promise) {
				this.setState({ providers })
				if (providers.length) {
					this.setState(
						{ selectedProvider: providers[0].provider_name },
					)
					this.filterIdentifiers('provider')
				} else {
					this.filterIdentifiers()
				}
			} else {
				this.setState(
					{ providers: [] }, 
					() => this.filterIdentifiers()
				)
			}
		})
		this.getWindowSize()
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 120
		let sliderHeight = 40
		let outerHeight = (window.outerHeight - browserBorder - headerHeight - sliderHeight) +"px"

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }
	/**
	 * Filter identifiers based on the filter selection
	 */
	filterIdentifiers = from => {
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'all') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if (from === 'provider') {
			if(this.props.accounts && this.props.accounts.length) {
                let allAccounts = this.props.accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
                if(this.props.accounts.length) {
                    let accRow = {}
                    accRow.account_id = "All"
                    accRow.account_name = "All"
                    accRow.date = null
                    accRow.disabled = false
                    accRow.provider = "All"
                    allAccounts.unshift(accRow);
                }
                this.setState({ accounts: allAccounts })
            } else {
                this.props.listAllAccounts(params, (promise, allAccounts) => {
                    if (promise) {						
                        let filterdAccounts = allAccounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
                        if(filterdAccounts.length) {
                            let accRow = {}
                            accRow.account_id = "All"
                            accRow.account_name = "All"
                            accRow.date = null
                            accRow.disabled = false
                            accRow.provider = "All"
                            filterdAccounts.unshift(accRow);
                        }						
                        this.setState({ accounts: filterdAccounts })
                    }
                })
            }

            if(this.props.regions && this.props.regions.length) {
                let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
                this.setState({ regions: providerRegions })
            } else {
                this.props.listAllRegions(params, (promise, regions) => {
                    if (promise) {
                        let providerRegions = regions && regions[0] && regions[0].provider_regions
                        if(providerRegions) {
                            let provider_regions_row = {}
                            provider_regions_row.availability_zones = []
                            provider_regions_row.city = "All"
                            provider_regions_row.country = "All"
                            provider_regions_row.geography = "All"
                            provider_regions_row.name = "All"
                            provider_regions_row.region = "All"
                            provider_regions_row.region_id = "All"
                            provider_regions_row.state = "All"
                            providerRegions.unshift(provider_regions_row);
                        }
                        this.setState({ regions: providerRegions })
                    } else {
                        this.setState({ regions: [] })
                    }
                })
            }
		}

		this.onSearch()
	}

	onSearch = () => {
        let changesInFilter = this.state.changesInFilter
        if(this.state.showByValue === "Account/Region") {
            if(!this.state.selectedAccReg || !this.state.selectedAccReg.length) {
                changesInFilter = false
                this.setState({ showFilterSection: true })
            }
        }

        if(changesInFilter) {
			this.setState({ totalAccArray: [], scores: [], services: [], pricingModel: [], dailyBilling: [], monthBilling: [], threeMonthBilling: [], actions: [], status: [], movetoAccount: this.state.selectedRegion, moveToRetion: this.state.selectedRegion, moveToTags: this.state.selectedTags, showLoading: true },
				() => {
					this.getTotalAssetCountObject()
				}
			)
			this.totalItemShowing = 3
		}
	}

	onReset = () => {
		this.setState({ selectedAccount:[], selectedRegion: [], selectedTags: [], selectedDuration: '+7d', totalAccArray: [], totalAccountArray: [] })
	}

	getTotalAssetCountObject = () => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		if(this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
			params.region = this.state.selectedRegion
		}
		if(this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
			params.account_id = this.state.selectedAccount
		}
		
		let aggregateArray = []
		aggregateArray.push('account_id')
		if(this.state.selectedRegion.length) {
			aggregateArray.push('region')
		}

		if(this.state.selectedTags.length) {
			aggregateArray.push('tags')
			params.tags  = this.state.selectedTags
		}

		params.aggregate_by = aggregateArray
		let searchInputs = aggregateArray
		//console.log('params', params)
		this.props.getTotalAssetCountObject(params, searchInputs, (promise, resources) => {
			if (promise) {
				let totalAccArray = []
				if(Object.entries(resources).length) {
					Object.entries(resources).forEach(([key, resource], index) => {
						let dataRow = {}
						dataRow.key = key
						dataRow.status = 1
						dataRow.index = index
						totalAccArray.push(dataRow)
					})
				}
				this.setState({ resources, totalAccArray, totalAccountArray: totalAccArray, showLoading: false }, 
					() => this.callAllFunctions()
				)
			} else {
				this.setState({ resources: [], showLoading: false })
			}
		})

		this.hideLoading()
	}

	callAllFunctions = () => {
		this.getDailyActiveAssetCount()
		this.getTotalAssetCountArray('resource_type')
		this.getTotalAssetCountArray('status')
		this.getTotalAssetCountArray('action')
		this.getAssetCountByPricingModel()
		this.getTotalBilling('daily')
		this.getTotalBilling('0M')
		this.getTotalBilling('-3M')
		this.getScoreDetails()
	}

	getDailyActiveAssetCount = () => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		if(this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
			params.region = this.state.selectedRegion
		}
		if(this.state.selectedAccount.length && !this.state.selectedAccount.includes('All')) {
			params.account_id = this.state.selectedAccount
		}
		
		let aggregateArray = []
		aggregateArray.push('account_id')
		if(this.state.selectedRegion.length) {
			aggregateArray.push('region')
		}

		if(this.state.selectedTags.length) {
			aggregateArray.push('tags')
			params.tags  = this.state.selectedTags
		}

		params.aggregate_by = aggregateArray
		let searchInputs = aggregateArray
		//console.log('params', params)
		this.props.getDailyActiveAssetCount(params, searchInputs, this.state.totalAccArray, (promise, resourcesApexLineData) => {
			//console.log('resourcesLineData', resourcesLineData)
			if (promise) {
				this.setState({ resourcesLineData: [] },
					() => this.clearResourceLineData(resourcesApexLineData)					
				)
			} else {
				this.setState({ resourcesLineData: [] })
			}
		})
	}

	clearResourceLineData = (resourcesApexLineData) => {
		this.setState({ resourcesLineData: resourcesApexLineData })
	}

	getTotalAssetCountArray = (aggregateBy) => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		if(this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
			params.region = this.state.selectedRegion
		}
		if(this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
			params.account_id = this.state.selectedAccount
		}
		let aggregateArray = []
		let searchInputsArray = []
		aggregateArray.push(aggregateBy)
		aggregateArray.push('account_id')
		searchInputsArray.push('account_id')
		if(this.state.selectedRegion.length) {
			aggregateArray.push('region')
			searchInputsArray.push('region')
		}

		if(this.state.selectedTags.length) {
			aggregateArray.push('tags')
			searchInputsArray.push('tags')
			params.tags  = this.state.selectedTags
		}

		params.aggregate_by = aggregateArray
		let searchInputs = searchInputsArray
		//console.log('getTotalAssetCountArray', params)
		this.props.getTotalAssetCountArray(params, searchInputs, this.state.totalAccArray, (promise, count) => {
			//console.log(aggregateBy, count)
			if (promise) {
				if(aggregateBy === 'status') {
					//delete count['666665886686']; //to check if anyone of key(Account) is not available
					//console.log('status',count)
					let statusCountArray = {}
					Object.entries(count).length && Object.entries(count).forEach(([key, value] ,index) => {
						let active = 0
						let inactive = 0
						let account_id = ''
						let duration = this.state.selectedDuration
						let provider = this.state.selectedProvider.toLowerCase()
						// let region = ''
						let total_count = 0

						let dataArray = []
						let dataRow = {}
						value.length && value.forEach(item => {
							account_id = item.account_id
							duration = item.duration
							provider = item.provider
							// region = item.region ? item.region : ''
							if(item.status === 'active') {
								active = 1
							} if(item.status === 'inactive') {
								inactive = 1
							}
							dataArray.push(item)
						})

						if(active === 0) {
							dataRow = {}
							dataRow.account_id = account_id
							dataRow.duration = duration
							dataRow.provider = provider
							dataRow.total_count = total_count
							dataRow.status = 'active'
							dataArray.push(dataRow)
						}

						if(inactive === 0) {
							dataRow = {}
							dataRow.account_id = account_id
							dataRow.duration = duration
							dataRow.provider = provider
							dataRow.total_count = total_count
							dataRow.status = 'inactive'
							dataArray.push(dataRow)
						}

						statusCountArray[key] = dataArray
					})
					//console.log('statusCountArray',statusCountArray)
					this.setState({ status: statusCountArray })
				} else if(aggregateBy === 'resource_type') {
					//delete count['666665886686']; //to check if anyone of key(Account) is not available
					this.setState({ services: count })
				} else if(aggregateBy === 'action') {
					//delete count['666665886686']; //to check if anyone of key(Account) is not available
					//console.log('action', count)
					let countArray = {}
					Object.entries(count).length && Object.entries(count).forEach(([key, value] ,index) => {
						let created = 0
						let modified = 0
						let deleted = 0
						let account_id = ''
						let duration = this.state.selectedDuration
						let provider = this.state.selectedProvider.toLowerCase()
						// let region = ''
						let total_count = 0

						let dataArray = []
						let dataRow = {}
						value.length && value.forEach(item => {
							account_id = item.account_id
							duration = item.duration
							provider = item.provider
							// region = item.region ? item.region : ''
							if(item.action === 'created') {
								created = 1
							} if(item.action === 'modified') {
								modified = 1
							} if(item.action === 'deleted') {
								deleted = 1
							}
							dataArray.push(item)
						})

						if(created === 0) {
							dataRow = {}
							dataRow.account_id = account_id
							dataRow.duration = duration
							dataRow.provider = provider
							dataRow.total_count = total_count
							dataRow.action = 'created'
							dataArray.push(dataRow)
						}

						if(modified === 0) {
							dataRow = {}
							dataRow.account_id = account_id
							dataRow.duration = duration
							dataRow.provider = provider
							dataRow.total_count = total_count
							dataRow.action = 'modified'
							dataArray.push(dataRow)
						}

						if(deleted === 0) {
							dataRow = {}
							dataRow.account_id = account_id
							dataRow.duration = duration
							dataRow.provider = provider
							dataRow.total_count = total_count
							dataRow.action = 'deleted'
							dataArray.push(dataRow)
						}
						countArray[key] = dataArray
					})
					//console.log(countArray)
					this.setState({ actions: countArray })
				}
			} else {
				if(aggregateBy === 'status') {
					this.setState({ status: [] })
				} else if(aggregateBy === 'resource_type') {
					this.setState({ services: [] })
				} else if(aggregateBy === 'action') {
					this.setState({ actions: [] })
				}
			}
		})
	}

	getAssetCountByPricingModel = () => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		if(this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
			params.region = this.state.selectedRegion
		}
		if(this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
			params.account_id = this.state.selectedAccount
		}
		
		let aggregateArray = []
		aggregateArray.push('account_id')
		if(this.state.selectedRegion.length) {
			aggregateArray.push('region')
		}

		if(this.state.selectedTags.length) {
			aggregateArray.push('tags')
			params.tags  = this.state.selectedTags
		}

		params.aggregate_by = aggregateArray
		let searchInputs = aggregateArray
		//console.log('params', params)
		this.props.getAssetCountByPricingModel(params, searchInputs, (promise, pricingModel) => {
			//console.log('pricingModel', pricingModel)
			if (promise) {
				//delete pricingModel['666665886686 / us-east-2'];
				this.setState({ pricingModel })
			} else {
				this.setState({ pricingModel: [] })
			}
		})
	}

	getScoreDetails = () => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		if(this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
			params.region = this.state.selectedRegion
		}
		if(this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
			params.account_id = this.state.selectedAccount
		}

		let aggregateArray = []
		aggregateArray.push('account_id')
		if(this.state.selectedRegion.length) {
			aggregateArray.push('region')
		}

		if(this.state.selectedTags.length) {
			aggregateArray.push('tags')
			params.tags  = this.state.selectedTags
		}

		params.score_category = ["ALL"]
		params.group_by = aggregateArray
		let searchInputs = aggregateArray
		//console.log('params', params)
		this.props.getScoreDetails(params, searchInputs, this.state.totalAccArray, (promise, scores) => {
			//console.log('scores', scores)
			if (promise) {
				//delete scores['666665886686']; //to check if anyone of key(Account) is not available
				this.setState({ scores })
			} else {
				this.setState({ scores: [] })
			}
		})
	}

	getTotalBilling = (aggregate) => {
		let params = {}
        params.provider = this.state.selectedProvider.toLowerCase()
				
		if(aggregate === 'daily') {
			params.provider = this.state.selectedProvider.toLowerCase()
			params.aggregate = 'daily'
			params.start_time = moment().subtract(1, 'days').format("YYYY-MM-DD")
			params.end_time = moment().subtract(1, 'days').format("YYYY-MM-DD")
		} else if(aggregate === '0M') {
			params.aggregate = 'monthly'
			params.duration = '0M'

		} else if(aggregate === '-3M') {
			params.aggregate = 'monthly'
			params.duration = '-3M'
		}
		
		let aggregateArray = []
		aggregateArray.push('account_id')
		let filters = {}
		if(this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
			//params.account_id = this.state.selectedAccount
			filters.account_id = this.state.selectedAccount
		}

		if(this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
			//params.region = this.state.selectedRegion
			filters.region = this.state.selectedRegion
		}
		if(this.state.selectedRegion.length) {
			aggregateArray.push('region')
		}

		if(this.state.selectedTags.length) {
			aggregateArray.push('tags')
			filters.tags = this.state.selectedTags
		}
		params.filters = filters
		params.group_by = aggregateArray
		let searchInputs = aggregateArray

		//console.log(aggregate, params)
		this.props.getTotalBilling(params, searchInputs, (promise, billing) => {
			if (promise) {
				//console.log(params.duration, billing)
				if(params.aggregate === 'daily') {
					this.setState({ dailyBilling: billing })
				} else if(params.duration === '0M') {
					this.setState({ monthBilling: billing })
				} else if(params.duration === '-3M') {
					this.setState({ threeMonthBilling: billing })
				} 
			} else {
				if(params.aggregate === 'daily') {
					this.setState({ dailyBilling: [] })
				} else if(params.duration === '0M') {
					this.setState({ monthBilling: [] })
				} else if(params.duration === '-3M') {
					this.setState({ threeMonthBilling: [] })
				}
			}
		})
	}

	componentWillUnmount() {
		//document.removeEventListener('keydown', this._handleKeyDown)
	}

	slideLeft(movingArrayIndex) {
		if(movingArrayIndex !== '') {
			// const totalAccounts = this.state.totalAccArray.length;
			this.totalItemShowing = parseInt(this.totalItemShowing)-1;

			this.setState({ slideLeftactive: this.totalItemShowing < 4 ? false : true })
			
			let updateArray = movingArrayIndex - 2
	
			let tot = this.state.totalAccArray
			tot[updateArray] = {...tot[updateArray], status: 1}
			this.setState({ totalAccArray: tot, slideRightactive: true, slideLeftactive: this.totalItemShowing < 4 ? false : true })
		}
	}

	slideRight(movingArrayIndex) {
		if(movingArrayIndex !== '') {
			const totalAccounts = this.state.totalAccArray.length;				
			this.setState({ slideLeftactive: true })
			this.totalItemShowing = parseInt(this.totalItemShowing)+1;
			// if (this.totalItemShowing < totalAccounts) {
			// 	this.sliderWrap.current.scrollLeft += 350
			// } else {
			// 	 this.sliderWrap.current.scrollLeft += 500
			// 	 this.setState({ slideRightactive: false })
			// }
			if (this.totalItemShowing >= totalAccounts) {
				this.setState({ slideRightactive: false })
			}			

			let updateArray = movingArrayIndex - 1
	
			let tot = this.state.totalAccArray
			// console.log('slide', totalAccounts)
			tot[updateArray] = {...tot[updateArray], status: 0}
			// console.log(tot,'-------------')			
			this.setState({ totalAccArray: tot })
		}
		
		// if(movingArrayIndex !== '') {
		// 	let test = this.state.totalAccArray
		// 	console.log(test)
		// 	test.splice(0, 1);
		// 	console.log(test,'--------------')

		// }
	}

	handleMultiSelectChange = (field, arrayValue) => {
        let value = arrayValue.map(item => item.value)

        let selectedValue = []
        if(field === 'selectedAccount') {
            let prevState = this.state[field]
            if(value.includes('All')) {
                if(!prevState.includes('All')) {
                    this.state.accounts.forEach(acc => {
                        selectedValue.push(acc.account_id)
                    })
                } else {
                    const index = value.indexOf('All');
                    if (index > -1) {
                        value.splice(index, 1);
                    }
                    selectedValue = value
                }
            } else if(!prevState.includes('All')) {
                selectedValue = value
            }

            if(selectedValue.length && !selectedValue.includes('All')) {
                if(!selectedValue.includes('All') && selectedValue.length === (this.state.accounts.length -1)) {
                    selectedValue.push('All')
                }
            }	
            
            this.setState({ [field]: selectedValue })
        } else if(field === 'selectedRegion') {
            let prevState = this.state[field]
            if(value.includes('All')) {
                if(!prevState.includes('All')) {
                    this.state.regions.forEach(reg => {
                        selectedValue.push(reg.region)
                    })
                } else {
                    const index = value.indexOf('All');
                    if (index > -1) {
                        value.splice(index, 1);
                    }
                    selectedValue = value
                }
            } else if(!prevState.includes('All')) {
                selectedValue = value
            }

            if(selectedValue.length && !selectedValue.includes('All')) {
                if(!selectedValue.includes('All') && selectedValue.length === (this.state.regions.length -1)) {
                    selectedValue.push('All')
                }
            }
            
            this.setState({ [field]: selectedValue })
        } 
    }
    
    getMultiSelectedCount = (type, array) => {
        return array.length && array.includes('All') ? 'All' : (array.length ? array.length +' Selected' : 'All')
    }

	handleClickOutside(event) {
		if(this.state.showFilterSection) {
			if (this.accRegRef && !this.accRegRef.current.contains(event.target)) {
				this.setState({ showFilterSection: false })
			}
		}

		if(this.state.showTagFilter) {
			if (this.tagsRef && !this.tagsRef.current.contains(event.target)) {
				this.setState({ showTagFilter: false })
			}
		}
	}

	getTagValue = () => {
		let filterData = this.state.tagSubArray
		if(this.state.selectedTagKey !== '') {
			filterData = filterData.filter(tag => tag.key === this.state.selectedTagKey)
		}
		this.setState({ filterData, selectedTagValue: [] })
	}

	selectedTagFunction = (value) => {
		if(value.length > 0 && value !== false) {
			this.setState({ totalselectedTag: value.length+ ' Key Selected'})

			this.setState({selectedTags: value})
		}

		this.setState({ tagFilter: this.state.tagFilter ? false : true })
	}

	removeColumn = (index) => {
		if(this.totalItemShowing > 3) {
			this.sliderWrap.current.scrollLeft -= 350
		}
		let totalArray = this.state.totalAccArray
		totalArray.splice(index, 1);
		this.setState({ totalAccArray: totalArray, ['text_'+index]: false, ['showDrop'+index]: false, moveToDestination: ''})
	}

	onChangeText = (e) => {
		const re = /^[0-9\b]+$/;
		// if value is not blank, then test the regex
	
		if (e.target.value === '' || re.test(e.target.value)) {
		   this.setState({moveToDestination: e.target.value})
		}
	}

	moveColumnTo = (index, source) => {
		let destination = this.state.moveToDestination
		if(destination > 0 && destination !== '' && this.state.totalAccArray.length > 1 && destination <= this.state.totalAccArray.length) {
			let totalArray = this.state.totalAccArray
			//totalArray.splice(destination, 0, totalArray.splice(source, 1).pop());
			destination = destination-1
			if(this.state.totalAccArray.length > 3) {
				if(this.totalItemShowing-2 > this.state.moveToDestination) {
					this.totalItemShowing = parseInt(this.totalItemShowing)+1;
					totalArray[source] = {...totalArray[source], status: 0, index: destination}	
				} else {
					totalArray[source] = {...totalArray[source], index: destination}	
				}

				//remove `from` item and store it
				let f = totalArray.splice(source, 1)[0];
				
				// insert stored item into position `to`
				totalArray.splice(destination, 0, f);

				if(destination < this.state.totalAccArray.length) {
					for(let i = (destination+1); i < this.state.totalAccArray.length; i++) {
						totalArray[i] = {...totalArray[i], index: i}
					}
				}
			} else if(this.state.totalAccArray.length > 1) {
				totalArray[source] = {...totalArray[source], status: 1, index: destination}	
				let f = totalArray.splice(source, 1)[0];
				
				// insert stored item into position `to`
				totalArray.splice(destination, 0, f);
				if(destination < this.state.totalAccArray.length) {
					for(let i = (destination+1); i < this.state.totalAccArray.length; i++) {
						totalArray[i] = {...totalArray[i], index: i}
					}
				}
			}
			
			this.setState({ totalAccArray: totalArray, ['text_'+index]: false, ['showDrop'+index]: false, moveToDestination: ''})
		}
		
		


		// if(destination > 0 && destination !== '') {
		// 	destination = destination - 1 //to equal the array key
		// 	let totalArray = this.state.totalAccArray
		// 	if(destination > totalArray.length) {
		// 		destination = (totalArray.length - 1) //move to last position
		// 	}
		// 	if(source !== destination) {
		// 		if (source < totalArray.length) {
		// 			//splice(destination, 0, totalArray.splice(source, 1).pop());
		// 			// if(this.totalItemShowing-2 > this.state.moveToDestination) {
						
		// 			// }
		// 			totalArray.splice(destination, 0, totalArray.splice(source, 1).pop());
		// 			this.setState({ totalAccArray: totalArray, ['text_'+source]: false, ['showDrop'+source]: false, moveToDestination: ''},
		// 				() => this.intfunc()
		// 			)
		// 		}
		// 	}

		// }
	}

	// intfunc = () => {
	// }

	showDropFunction = (index) => {
		for(let i=0; i<this.state.totalAccArray.length;i++) {
			if(i === index) {
				this.setState({ ['showDrop'+i]: this.state['showDrop'+i] ? false : true, moveToDestination: '' })
			} else {
				this.setState({ ['showDrop'+i]: false, moveToDestination: '' })
			}
		}
	}

	hideLoading = () => {
        setTimeout(() => { this.setState({ showLoading: false }) }, 5000)
    }

    accRegSelected = () => {
        let data = []
        this.state.accRegionArray.forEach(item => {
            let dataRow = {}
            if(this.state["accounts_"+item] && this.state["accounts_"+item] !== "" && this.state["regions_"+item] && this.state["regions_"+item] !== "") {
                dataRow.account_id = this.state["accounts_"+item]
                dataRow.region = this.state["regions_"+item]
                data.push(dataRow)
            }
        })

        this.setState({ selectedAccReg : data })
    }

	addSection = (array) => {
        let rowList = this.state[array];
        if(this.state[array]) {
            let value = this.state[array][this.state[array].length - 1]
            value = value+1 
            rowList.push(value);
        }
        
        this.setState({[array]: rowList },
            () =>{
                if(array === "accRegionArray") {
                    this.accRegSelected()
                }
            }
        )
    }

    removeSection = (item, i) => {
        this.setState({ ["accounts_"+item]: "", ["regions_"+item]: "" },
            () => {
                let rowList = this.state.accRegionArray;
                rowList.splice(i, 1);
                this.setState({ accRegionArray: rowList },
                    () => this.accRegSelected()    
				)
            }
        )
    }

    removeTagSection = (item, i) => {
        this.setState({ ["tag_key_"+item]: "", ["tag_value_"+item]: "" },
            () => {
                let rowList = this.state.tagsArray;
                rowList.splice(i, 1);
                this.setState({ tagsArray: rowList },
                    () => this.accRegSelected()    
				)
            }
        )
    }

	resetFilterSelection = () => {
		this.state.accRegionArray.forEach(item => {
			this.setState({ ["accounts_"+item]: "", ["regions_"+item]: "" })
		})
		this.setState({ selectedAccount: [], selectedRegion: [], selectedTags: [] })
	}

    removefilter = (index, value, field) => {
        let filteredResult = this.state[field]
        filteredResult = this.state[field].filter(e => e !== value)
        
        // filteredResult = this.state[field].filter(e => e.key !== option.key && e.value !== option.value)
        this.setState({ [field]: filteredResult })
    }

	resetTagFilterSelection = () => {
		
        this.state.tagsArray.forEach(item => {
            this.setState({ ["tag_key_"+item]: "", ["tag_value_"+item]: "" })
        })

        this.setState({ tagsArray: [0] })
	}

    getAllTagsKeys = () => {
        if(!this.props.tagKeys || !this.props.tagKeys.length) {
            let params = {}
            this.props.getAllTagsKeys(params, (promise, response) => {})
        }
    }

    getAllTagsValues = (index) => {
        let params = {}
        params.tags = [{ "key": this.state["tag_key_"+index] }]
        this.props.getAllTagsValues(params, (promise, response) => {
            if(promise) {
                this.setState({ ["tagValues_"+index]: response })
            }
        })
    }
	
	/**
	 * Renders Asset Comparison
	 */
	render() {
		return (
			<div className='container-fluid flex-grow-1 bg-muted' onClick={ (event) => { this.handleClickOutside(event) } }>
				<div className={`loaderOverlay ${this.state.showLoading ? "" : "displayNone"}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
				<div className='row'>
					<div className='col-sm-12 p-0'>
						<div className='title-section p-2'>
							<div className='d-flex'>
								<div className='col-lg-4 pl-2'>
									<h6 className='text-white m-0'>Consolidated Comparison</h6>
									<p className='text-white mb-0'>Compilation of resource perspectives between your accounts and regions</p>
								</div>
								<div className={`col-lg-8 text-right`}>
									<div className='d-flex justify-content-end'>
										<div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 ${!this.state.providers.length}`} style={{maxWidth: '150px' }}>
											<label className="mb-1">Provider</label>
											<Select
												placeholder={'All'}
												isSearchable={false}
												//onFocus={this.openProivderMenu}
												className='f13 p-0 colorBlack reactSelectDrop'
												value={({
													value: this.state.selectedProvider,
													label: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : <span className="placeholder">Select</span>
												})}
												options={this.state.providers && this.state.providers.map(item => ({
													value: item.provider_name,
													label: item.provider_name,	
												}))}
												onChange={event =>{
													if(this.state.selectedProvider !== event.value) {
														this.setState({ selectedProvider: event.value },
															() => {
																this.filterIdentifiers('provider')
															}
														)
													}
												}}
											/>
										</div>
										<div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} style={{maxWidth: '150px' }}>
											<label className="mb-1">Show By</label>
											<Select
												placeholder={'All'}
												isSearchable={false}
												//onFocus={this.openProivderMenu}
												className='f13 p-0 colorBlack reactSelectDrop'
												value={({
													value: this.state.showByValue,
													label: this.state.showByValue && this.state.showByValue !== "" ? this.state.showByValue : <span className="placeholder">Select</span>
												})}
												options={this.state.showBy && this.state.showBy.map(item => ({
													value: item,
													label: item,	
												}))}
												onChange={event =>{
													this.setState({ showByValue: event.value, selectedAccount: [],  selectedRegion: [], selectedTags: [] })
													this.resetFilterSelection()
												}}
											/>
										</div>
										{this.state.tagFilter ?
											<TagFilterSection 
												data = {this.selectedTagFunction.bind(this)}
												selectedProvider = {this.state.selectedProvider.toLowerCase()}
												selectedAccount = {this.state.selectedAccount}
												selectedRegion = {this.state.selectedRegion}
												selectedTags = {this.state.selectedTags}
											/>
										: null}
										<div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} style={{maxWidth: '150px' }} ref={this.accRegRef}>
											<div className="" onClick={() => this.setState({ showFilterSection: true })}>
												<label className="mb-1">Account/Region</label>
												<p className='pt-1 mb-0'>
                                                    {this.state.showByValue === "Account/Region" ?
                                                        this.state.selectedAccReg && this.state.selectedAccReg.length ? 
                                                            <span>{this.state.selectedAccReg.length} Selected</span>
                                                        : 
                                                            <span className="placeholder">Select</span>
                                                    :
                                                        <React.Fragment>
                                                        {this.state.selectedAccount && this.state.selectedAccount.length && this.state.selectedAccount.includes("All") ?
                                                            <span>All / </span>
                                                        : this.state.selectedAccount && this.state.selectedAccount.length && !this.state.selectedAccount.includes("All") ?
                                                            <span>{this.state.selectedAccount.length} selected / </span>
                                                        :
                                                            <span className="placeholder">Select</span>
                                                        }

                                                        {this.state.selectedRegion && this.state.selectedRegion.length && this.state.selectedRegion.includes("All") ?
                                                            <span>All</span>
                                                        : this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All") ?
                                                            <span>{this.state.selectedRegion.length} selected</span>
                                                        : this.state.selectedAccount && this.state.selectedAccount.length ?
                                                            <span>All</span>
                                                        :
                                                            <span className="placeholder">&nbsp;</span>
                                                        }
                                                        </React.Fragment>
                                                    }
                                                </p>
											</div>
											{this.state.showFilterSection ?
												<div className="resourcePopupFilterSection border mt-1">
													<div className="p-3 zapInputDark bg-muted">
														{this.state.showByValue === "Account/Region" ?
															<div className={`w-100 mt-2`}>
																<div className={`d-flex`}>
																	<label className="mb-1 w-40">Account</label>
																	<label className="mb-1 w-40 ml-3">Region</label>
																</div>
                                                                {this.state.accRegionArray.map((index, i) => {
                                                                    return(
                                                                        <div className={`w-100 ${i ? "mt-2 border-top-black4" : ""}`}>
                                                                            <div className={`d-flex ${!i ? "" : "mt-3"}`}>
                                                                                <Select
																					placeholder={'Select'}
																					isSearchable={false}
																					//onFocus={this.openProivderMenu}
																					className='f13 p-0 dark-select-theme-with-dropdown-indicator w-40'
																					value={({
																						value: this.state["accounts_"+index] && this.state["accounts_"+index] !== "" ? this.state["accounts_"+index] : 'Select',
																						label: this.state["accounts_"+index] && this.state["accounts_"+index] !== "" ? this.state.accounts.filter(e => e.account_id === this.state["accounts_"+index])[0].account_name : <span className="placeholder">Select</span>
																					})}
																					options={this.state.accounts.filter(e => e.account_id !== "All").map(item => ({
                                                                                        value: item.account_id,
                                                                                        label: item.account_name
                                                                                    }))}
                                                                                    onChange={event =>
                                                                                        this.setState({ ["accounts_"+index]: event.value, changesInFilter: true })
                                                                                    }
                                                                                />
                                                                                <Select
                                                                                    placeholder={'Select'}
                                                                                    isSearchable={false}
                                                                                    //onFocus={this.openProivderMenu}
                                                                                    className='f13 p-0 dark-select-theme-with-dropdown-indicator w-40 ml-3'
                                                                                    value={({
                                                                                        value: this.state["regions_"+index] && this.state["regions_"+index] !== "" ? this.state["regions_"+index] : 'Select',
                                                                                        label: this.state["regions_"+index] && this.state["regions_"+index] !== "" ? this.state.regions.filter(e => e.region === this.state["regions_"+index])[0].name : <span className="placeholder">Select</span>
                                                                                    })}
                                                                                    options={this.state.regions.filter(e => e.region !== "All").map(item => ({
                                                                                        value: item.region,
                                                                                        label: item.name,	
                                                                                    }))}
                                                                                    onChange={event =>
                                                                                        this.setState({ ["regions_"+index]: event.value, changesInFilter: true }, 
                                                                                            () => this.accRegSelected()
                                                                                        )
                                                                                    }
                                                                                />
                                                                                
                                                                                {this.state.accRegionArray.length > 1 ?
                                                                                    <span className={`far fa-trash cursor-pointer f18 align-self-center ml-4`} onClick={() => this.removeSection(index, i)}></span>
                                                                                : null}
                                                                                {(this.state.accRegionArray.length - 1) ===  i ?
                                                                                    <span className={`far fa-plus cursor-pointer f18 align-self-center ml-4`} onClick={() => this.addSection("accRegionArray")}></span>
                                                                                : null}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
															</div>
														:
															<React.Fragment>
															<div className="w-100">
                                                                <div className="d-flex flex-wrap mb-1">
                                                                    <label className="mb-0">Account</label>
                                                                    {this.state.selectedAccount && this.state.selectedAccount.length && !this.state.selectedAccount.includes("All") ?
                                                                        this.state.selectedAccount.map((cat, index) => {
                                                                            return(
                                                                                <span className="badge badge-secondary ml-2 mb-1">
                                                                                    {cat}
                                                                                    <i onClick={ () => this.removefilter(index, cat, 'selectedAccount') } className='ml-1 fal fa-times cursorPointer'></i>
                                                                                </span>
                                                                            )
                                                                        })
                                                                    : this.state.selectedAccount && this.state.selectedAccount.includes("All") ?
                                                                        <span className="badge badge-secondary ml-2 mb-1">All</span>
                                                                    : null}
                                                                </div>
                                                                {this.state.showByValue === "Account" ?
                                                                    <div className={`multiselectDarkTheme align-self-center z998`}>
                                                                        <ReactMultiSelectCheckboxes						
                                                                            placeholderButtonLabel="All"
                                                                            getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedAccount', this.state.selectedAccount)}
																			value={this.state.selectedAccount.map(acc => ({
																				value: acc
                                                                            }))}
                                                                            onChange={arr => {
                                                                            this.handleMultiSelectChange('selectedAccount', arr ? arr : []) }}
                                                                            options={this.state.accounts.map(account => ({
                                                                                value: account.account_id,
                                                                                label: account.account_name,
                                                                            }))}
                                                                        />
                                                                    </div>
                                                                : 
                                                                    <Select
                                                                        placeholder={'Select'}
                                                                        isSearchable={false}
                                                                        //onFocus={this.openProivderMenu}
                                                                        className='f13 p-0 dark-select-theme-with-dropdown-indicator'
                                                                        value={({
                                                                            value: this.state.selectedAccount && this.state.selectedAccount.length && this.state.selectedAccount[0],
                                                                            label: this.state.selectedAccount && this.state.selectedAccount.length ? this.state.accounts.filter(e => e.account_id === this.state.selectedAccount[0])[0].account_name : <span className="placeholder">Select</span>
                                                                        })}
                                                                        options={this.state.accounts.filter(e => e.account_id !== "All").map(item => ({
                                                                            value: item.account_id,
                                                                            label: item.account_name
                                                                        }))}
                                                                        onChange={event =>
                                                                            this.setState({ selectedAccount: [event.value], changesInFilter: true })
                                                                        }
                                                                    />
                                                                }
                                                            </div>
                                                            <div className="w-100 mt-3">
                                                                <div className="d-flex flex-wrap mb-1">
                                                                    <label className="mb-0">Region</label>
                                                                    {this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All") ?
                                                                        this.state.selectedRegion.map((cat, index) => {
                                                                            return(
                                                                                <span className="badge badge-secondary ml-2 mb-1">
                                                                                    {cat}
                                                                                    <i onClick={ () => this.removefilter(index, cat, 'selectedRegion') } className='ml-1 fal fa-times cursorPointer'></i>
                                                                                </span>
                                                                            )
                                                                        })
                                                                    : this.state.selectedRegion && this.state.selectedRegion.includes("All") ?
                                                                        <span className="badge badge-secondary ml-2 mb-1">All</span>
                                                                    : null}
                                                                </div>
                                                                {this.state.showByValue === "Region" ?
                                                                    <div className={`multiselectDarkTheme align-self-center z998`}>
                                                                        <ReactMultiSelectCheckboxes						
                                                                            placeholderButtonLabel="All"
                                                                            getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedRegion', this.state.selectedRegion)}
                                                                            value={this.state.selectedRegion.map(reg => ({
                                                                                value: reg
                                                                            }))}
                                                                            onChange={arr => {
                                                                            this.handleMultiSelectChange('selectedRegion', arr ? arr : []) }}
                                                                            options={this.state.regions.map(reg => ({
                                                                                value: reg.region,
                                                                                label: reg.name,
                                                                            }))}
                                                                        />
                                                                    </div>
                                                                : 
                                                                    <Select
                                                                        placeholder={'Select'}
                                                                        isSearchable={false}
                                                                        //onFocus={this.openProivderMenu}
                                                                        className='f13 p-0 dark-select-theme-with-dropdown-indicator'
                                                                        value={({
                                                                            value: this.state.selectedRegion && this.state.selectedRegion.length && this.state.selectedRegion[0],
                                                                            label: this.state.selectedRegion && this.state.selectedRegion.length ? this.state.regions.filter(e => e.region === this.state.selectedRegion[0])[0].name : <span className="placeholder">Select</span>
                                                                        })}
                                                                        options={this.state.regions.filter(e => e.region !== "All").map(item => ({
                                                                            value: item.region,
                                                                            label: item.name,	
                                                                        }))}
                                                                        onChange={event =>
                                                                            this.setState({ selectedRegion: [event.value], changesInFilter: true })
                                                                        }
                                                                    />
                                                                }
                                                            </div>
															</React.Fragment>			
														}
														<div className="d-flex justify-content-end mt-3">
															<span className={`text-info mr-2 align-self-center`} onClick={() => this.resetFilterSelection()}>Clear</span>
															{/* <button className={`btn btn-sm bg-info text-white mr-2`} onClick={() => this.setState({ showFilterSection: false }, () => this.filteredResults())}>Filter</button> */}
															<button className={`btn btn-sm btn-light`} onClick={() => this.setState({ showFilterSection: false })}>Select</button>
														</div>
													</div>
												</div>
											: null}
										</div>
										<div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 displayNone`} style={{maxWidth: '150px' }} ref={this.tagsRef}>
											<div className="" onClick={() => this.setState({ showTagFilter: true }, () => this.getAllTagsKeys())}>
												<label>Tags</label>
												<p className='pt-1 mb-0 placeholder'>Select</p>
											</div>
											{this.state.showTagFilter ?
												<div className="resourcePopupFilterSection border mt-1">
													<div className="p-3 zapInputDark bg-muted">	
														<div className={`w-100`}>
															<p className="mb-2 w-100">Tags</p>
															<div className={`d-flex`}>
																<label className="mb-1 w-40">Key</label>
																<label className="mb-1 w-40 ml-3">Value</label>
															</div>
															{this.state.tagsArray.map((index, i) => {
																return(
																	<div className={`w-100 ${i ? "mt-2 border-top-black4" : ""}`}>
																		<div className={`d-flex ${!i ? "" : "mt-3"}`}>
																			<Select
																				placeholder={'Select'}
																				isSearchable={true}
																				// menuIsOpen={true}
																				className='f13 p-0 dark-select-theme-with-dropdown-indicator w-40'
																				value={({
																					value: this.state["tag_key_"+index] && this.state["tag_key_"+index] !== "" ? this.state["tag_key_"+index] : 'Select',
																					label: this.state["tag_key_"+index] && this.state["tag_key_"+index] !== "" ? this.state["tag_key_"+index] : <span className="placeholder">Select</span>
																				})}
																				options={this.props.tagKeys && this.props.tagKeys.map(item => ({
																					value: item,
																					label: item,	
																				}))}
																				onChange={event => this.setState({
																					["tag_key_"+index]: event.value,
																					["tagValues_"+index]: [],
																				},
																					() => this.getAllTagsValues(index)
																				)}
																			/>
																			{this.state["tag_key_"+index] && this.state["tag_key_"+index] !== "" && !this.state["tagValues_"+index].length ?
																				<div className='ml-3'>
																					<Spinner className='text-center align-self-center' color='white' size='sm' />
																				</div>
																			:
																				<React.Fragment>
																					<Select
																						placeholder={'Select'}
																						isSearchable={true}
																						// menuIsOpen={true}
																						className='f13 p-0 dark-select-theme-with-dropdown-indicator w-40 ml-3'
																						value={({
																							value: this.state["tag_value_"+index] && this.state["tag_value_"+index] !== "" ? this.state["tag_value_"+index] : 'Select',
																							label: this.state["tag_value_"+index] && this.state["tag_value_"+index] !== "" ? this.state["tag_value_"+index] : <span className="placeholder">Select</span>
																						})}
																						options={this.state["tagValues_"+index] && this.state["tagValues_"+index].map(item => ({
																							value: item,
																							label: item,	
																						}))}
																						onChange={event => this.setState({
																							["tag_value_"+index]: event.value
																						})}
																					/>
																					{this.state.tagsArray.length > 1 ?
																						<span className={`far fa-trash cursor-pointer f18 align-self-center ml-4`} onClick={() => this.removeTagSection(index, i)}></span>
																					: null}
																					{(this.state.tagsArray.length - 1) ===  i ?
																						<span className={`far fa-plus cursor-pointer f18 align-self-center ml-4`} onClick={() => this.addSection("tagsArray")}></span>
																					: null}
																				</React.Fragment>
																			}
																		</div>
																	</div>
																)
															})}
														</div>
														<div className="d-flex justify-content-end mt-3">
															<span className={`text-info mr-2 align-self-center`} onClick={() => this.resetTagFilterSelection()}>Clear</span>
															{/* <button className={`btn btn-sm bg-info text-white mr-2`} onClick={() => this.setState({ showFilterSection: false }, () => this.filteredResults())}>Filter</button> */}
															<button className={`btn btn-sm btn-light`} onClick={() => this.setState({ showTagFilter: false })}>Select</button>
														</div>
													</div>
												</div>
											: null}
											
										</div>
										<div className='form-group flex-fill bd-highlight mb-sm-0 styled-feild' style={{maxWidth: '150px' }}>
											<label>Duration</label>
											<Select
												isSearchable={false}
												className='f13 p-0 colorBlack reactSelectDrop'
												value={({
													value: this.state.selectedDuration,
													label: this.state.selectedDuration ? DURATION_FILTER_OPTIONS.filter(e => e.value === this.state.selectedDuration)[0].option : ''
												})}
												options={DURATION_FILTER_OPTIONS.map(item => ({
													value: item.value,
													label: item.option,	
												}))}
												onChange={event =>{
													if(this.state.selectedDuration !== event.value) {
														this.setState({ selectedDuration: event.value })
													}
												}}
											/>
										</div>
										<div className="ml-3 d-flex align-self-center">
											<div className="btn-group" role="group" aria-label="ConsolidatedComparison">
												<button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-right`} onClick={()=> this.onSearch()}>Search</button>
												<button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-left`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
											</div>
										</div>
									</div>									
								</div>
							</div>
						</div>
						<div className="m-2 bg-dark rounded">
							<div className="container-fluid p-2">
								<div className="d-flex justify-content-end">
									{this.state.totalAccArray.length ?
										<div className="align-self-center">
											<div className="d-flex justify-content-end">
												<small className="align-self-center">
													Showing 
													{this.state.totalAccArray &&  this.state.totalAccArray.length && this.state.totalAccArray.length > 3 ?  
														' '+(this.totalItemShowing - 2) +' - '+ this.totalItemShowing +' ('+this.state.totalAccArray.length+') '
													: 
														this.state.totalAccArray.length ? 
														' '+this.state.totalAccArray.length +' ('+this.state.totalAccArray.length+') '
														: ' 0'
													} 
												</small>
												<h5 className={`mb-0 mx-2 ${this.state.slideLeftactive ? 'text-white bg-info rounded-50' : 'bg-muted disabled rounded-50'} `}><span className="fad fa-arrow-circle-left cursorPointer" onClick={() => this.slideLeft(this.state.totalAccArray && this.state.totalAccArray.length && this.state.totalAccArray.length > 3 ?  ' '+(this.totalItemShowing - 2) : '')}></span></h5>
												<h5 className={`mb-0 ${this.state.slideRightactive ? this.state.totalAccArray.length > 3 ? 'text-white bg-info rounded-50' : 'bg-muted disabled rounded-50' : 'bg-muted disabled rounded-50'}`}><span className="fad fa-arrow-circle-right cursorPointer" onClick={() => this.slideRight(this.state.totalAccArray && this.state.totalAccArray.length && this.state.totalAccArray.length > 3 ?  ' '+(this.totalItemShowing - 2) : '')}></span></h5>
											</div>
										</div>
									: null}
								</div>
								<div className="row mt-2 overflowYAuto" style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
									{this.state.totalAccArray.length ?
										<div className={`col-sm-12 overflow-auto`} ref={this.sliderWrap}>
											<div className="d-flex">
												<div className="side-title"></div>													
												{this.state.totalAccArray.filter(item => item.status === 1).map((acc, index) => {
													return(
														<div key={index} className={`d-flex ${index === 0 ? 'compare-components-first' : 'compare-components'} justify-content-between pt-2 pr-2`}>
															{this.state.resources.hasOwnProperty(acc.key) ?
																<React.Fragment>
																<div className="d-flex" id="headerName">
																	<p className="m-0 font-weight-bold text-white">{acc.key}</p>
																	<p className="ml-2 mb-0 text-muted f12 align-self-center">{this.state.selectedProvider && this.state.selectedProvider !== "" && this.state.selectedProvider.toUpperCase()+' : '+getAccountNameFromId(this.state.resources[acc.key].account_id, this.state.accounts)}</p>
																	{/* <p className="mb-0 text-muted f12">{this.state.resources[acc.key].account_id}</p> */}
																	<UncontrolledTooltip placement='top' target={'headerName'}>{acc.key+' '+this.state.selectedProvider.toUpperCase()+' : '+getAccountNameFromId(this.state.resources[acc.key].account_id, this.state.accounts)}</UncontrolledTooltip> 
																</div>
																<div className={`text-right`}>												
																	<div className="dropdown w-100">	
																		<div><span className="far fa-chevron-square-down fa-lg cursorPointer" onClick={ () => this.showDropFunction(index) }>
																		</span></div>
																		<ul className={`comparisonRemoveDrop f10 ${this.state['showDrop'+index] && this.state['showDrop'+index] ? '' : 'displayNone' }`}>
																			<li onClick={ () => this.removeColumn(index)}><span>Remove</span></li>
																			<li className={` ${this.state['text_'+index] ? '' : 'mb-1'} `} onClick={()=> this.setState({['text_'+index]: this.state['text_'+index] ? false : true })}><span>Move To</span></li>
																			<li className={`mb-1 ${this.state['text_'+index] ? '' : 'displayNone'} `}>
																				<span>
																					<input type="text" className="moveTextbox" onChange={e => this.onChangeText(e)} value={this.state.moveToDestination} />
																					<button className="btn btn-sm btn-primary align-self-left moveBtn" onClick={()=> this.moveColumnTo(index, acc.index) }>Submit</button>
																				</span>
																			</li>
																		</ul>
																	</div>
																	{/* <p className="mb-0 text-muted f12">{getAccountNameFromId(this.state.resources[acc.key].account_id, this.state.accounts)}</p> */}
																</div>
															</React.Fragment>
															: null
															}
														</div>
													)

												})}
											</div>

											<div className="d-flex">
												<div className="side-title d-flex justify-content-end text-right border-right pr-3">
													<div className="d-flex flex-column align-self-center">
														<h6 className="text-primary mb-0">Resources</h6>
														<p className="small text-muted m-0 p-0">Number of Resources</p>
													</div>
												</div>
												{this.state.totalAccArray.filter(item => item.status === 1).map((acc, index) => {
													return(
														this.state.resources.hasOwnProperty(acc.key) ?
															<div key={index} className={`d-flex py-3 ${index === 0 ? 'compare-components-first' : 'compare-components'} border-bottom`}>
																<div className="d-flex flex-column w-100">
																	<div className="d-flex" style={{width:'100%',height:'30px'}}>
																	{this.state.resourcesLineData.hasOwnProperty(acc.key) ?
																		<div className="fix-chart mt-n3 transparentTooltip">
																			<Chart type="area" series={this.state.resourcesLineData[acc.key].line_datas} options={this.state.resourcesLineData[acc.key].line_option} height={50}/>
																			{/* <Line data={this.state.resourcesLineData[acc.key].line_datas} options={this.state.resourcesLineData[acc.key].line_option} style={{width:'100%',height:'30px'}} width={100} height={10}/> */}
																		</div>
																		: null}
																		{/* <h6 className="mr-3 border-right pr-3 mb-1 pb-0">{numberFormater(this.state.resources[acc].total_count)}</h6> */}
																		<p className="small align-self-end text-muted ml-3 pl-3 mb-0 border-left">Total of {this.state.resources[acc.key].total_count} resources.</p>
																	</div>
																	
																</div>
															</div>
														:
														null
													)
												})
												}
											</div>

											<div className="d-flex">
												<div className="side-title d-flex justify-content-end text-right border-right pr-3">
													<div className="d-flex flex-column align-self-center">
														<h6 className="text-primary mb-0">Score</h6>
														<p className="small text-muted mb-0">Resources by Score</p>
													</div>
												</div>
												{Object.entries(this.state.scores).length ? 
												this.state.totalAccArray.filter(item => item.status === 1).map((acc, index) => {
													return(
												
													<div key={index} className={`d-flex ${index === 0 ? 'compare-components-first' : 'compare-components'} border-bottom pt-2 col-sm-12 justify-content-center `}>

														{this.state.scores.hasOwnProperty(acc.key) ?
															<div className="">
																<div className={'m-0 p-0 col-sm-4 transparentTooltip'}>
																	<Chart options={this.state.scores[acc.key].options} series={this.state.scores[acc.key].series} type="donut"/>
																</div>

																
															</div>
														:
															<p className="m-auto text-muted mb-0 minHeight90px"> No Data available for this criteria</p>
														}
													</div>
													)
												})
												:
													this.state.totalAccArray.map((acc, index) => {
														return (
														<div key={index} className={`d-flex ${index === 0 ? 'compare-components-first' : 'compare-components'} border-bottom pt-2 col-sm-12 justify-content-center minHeight90px`}>
														<p className="m-auto text-muted mb-0"> No Data available for this criteria</p>
														</div>
														)
													})
												}
											</div>
											<div className="d-flex">
												<div className="side-title d-flex justify-content-end text-right border-right pr-3">
													<div className="d-flex flex-column align-self-center">
														<h6 className="text-primary mb-0">Services</h6>
														<p className="small text-muted mb-0">Resources distribution by Service</p>
													</div>
												</div>
												{Object.entries(this.state.services).length ? this.state.totalAccArray.filter(item => item.status === 1).map((acc, index) => {
													return (
														<div key={index} className={`d-flex ${index === 0 ? 'compare-components-first' : 'compare-components'} border-bottom pt-2 col-sm-12 justify-content-center`}>
														{this.state.services.hasOwnProperty(acc.key) ?
															<div className="">
																<div className={'m-0 p-0 col-sm-4 transparentTooltip'}>
																	<Chart options={this.state.services[acc.key].options} series={this.state.services[acc.key].series} type="donut"/>
																</div>
															</div>
														: 

														<p className="m-auto text-muted mb-0"> No Data available for this criteria</p>
														
														}
														
														</div>
													)
												})
												:
													this.state.totalAccArray.map((acc, index) => {
													return (
														<div key={index} className={`d-flex ${index === 0 ? 'compare-components-first' : 'compare-components'} border-bottom pt-2 col-sm-12 justify-content-center minHeight90px`}>
														<p className="m-auto text-muted mb-0"> No Data available for this criteria</p>
														</div>
														)
													})
												}
											</div>

											<div className="d-flex">
												<div className="side-title d-flex justify-content-end text-right border-right pr-3">
													<div className="d-flex flex-column align-self-center">
														<h6 className="text-primary mb-0">Model</h6>
														<p className="small text-muted pb-0">Resources distribution by Purchase model</p>
													</div>
												</div>
												
												{Object.entries(this.state.pricingModel).length ? this.state.totalAccArray.filter(item => item.status === 1).map((acc, index) => {
													let x = index
													if(x > 5) {
														for(let y = 5; y < x; y++) {
															y--
															x = (x - 6)
														}
													}
													let totalCount = this.state.pricingModel.hasOwnProperty(acc.key) ? 
													this.state.pricingModel[acc.key].OnDemand + this.state.pricingModel[acc.key].reservation_count + this.state.pricingModel[acc.key].Spot 
													: 0
													let onDemandWidth = 0	
													let reservedWidth = 0
													let spotWidth = 0																			
													if(totalCount > 0) {
														onDemandWidth = parseInt(this.state.pricingModel[acc.key].OnDemand * 100 / totalCount) +'%'
														reservedWidth = parseInt(this.state.pricingModel[acc.key].reservation_count * 100 / totalCount) +'%'
														spotWidth = parseInt(this.state.pricingModel[acc.key].Spot * 100 / totalCount) +'%'
													}

													return (
													<div key={index} className={`${index === 0 ? 'compare-components-first' : 'compare-components'} border-bottom py-3`}>
														{this.state.pricingModel.hasOwnProperty(acc.key) ? 
															<React.Fragment>
																<div className="w-100">
																	<div className="pr-2 flex-grow-1 w-100 pb-1">
																		<div className="bd-highlight d-flex">
																			<small className={`bg-comparison-text-${x} bd-highlight w-30`}>OnDemand</small>
																			<div className={`progress w-55 m-auto ${onDemandWidth === 0 ? 'bg-muted' : ''}`}>
																				<div className={`progress-bar bg-comparison-${x}`} style={{width: onDemandWidth}}></div>
																			</div>
																			<small className={`bg-comparison-text-${x} bd-highlight w-15 border-left ml-2 pl-2`}>{this.state.pricingModel[acc.key].OnDemand}</small>
																		</div>
																		{/* <small className="bd-highlight">{this.state.pricingModel[acc.key].onDemand}</small> */}
																	</div>
																	<div className="pr-2 flex-grow-1 w-100 pb-1">
																		<div className="bd-highlight d-flex">
																			<small className={`bg-comparison-text-${x} bd-highlight w-30`}>Reserved</small>
																			<div className={`progress w-55 m-auto ${reservedWidth === 0 ? 'bg-muted' : ''}`}>
																				<div className={`progress-bar bg-comparison-${x}`} style={{width: reservedWidth}}></div>
																			</div>
																			<small className={`bg-comparison-text-${x} bd-highlight w-15 border-left ml-2 pl-2`}>{this.state.pricingModel[acc.key].reservation_count}</small>
																		</div>
																		{/* <small className="bd-highlight">{this.state.pricingModel[acc.key].reservation_count}</small> */}
																	</div>
																	<div className="pr-2 flex-grow-1 w-100 pb-1">
																		<div className="bd-highlight d-flex">
																			<small className={`bg-comparison-text-${x} bd-highlight w-30`}>Spot</small>
																			<div className={`progress w-55 m-auto ${spotWidth === 0 ? 'bg-muted' : ''}`}>
																				<div className={`progress-bar bg-comparison-${x}`} style={{width: spotWidth}}></div>
																			</div>
																			<small className={`bg-comparison-text-${x} bd-highlight w-15 border-left ml-2 pl-2`}>{this.state.pricingModel[acc.key].Spot}</small>
																		</div>
																		{/* <small className="bd-highlight">{this.state.pricingModel[acc.key].onDemand}</small> */}
																	</div>
																</div>
															</React.Fragment>
														:
															<p className="m-auto text-muted mb-0"> No Data available for this criteria</p>
														}
													</div>
													)
												})
												:
													this.state.totalAccArray.map((acc, index) => {
													return (
														<div key={index} className={`d-flex ${index === 0 ? 'compare-components-first' : 'compare-components'} border-bottom pt-2 col-sm-12 justify-content-center minHeight90px`}>
														<p className="m-auto text-muted mb-0"> No Data available for this criteria</p>
														</div>
														)
													})
												}
											</div>

											<div className="d-flex">
												<div className="side-title d-flex justify-content-end text-right border-right pr-3">
													<div className="d-flex flex-column align-self-center">
														<h6 className="text-primary mb-0">Cost</h6>
														<p className="small text-muted mb-0">Resources Cost Usage</p>
													</div>
												</div>

												{(Object.entries(this.state.dailyBilling).length || Object.entries(this.state.monthBilling).length || Object.entries(this.state.threeMonthBilling).length) ? this.state.totalAccArray.filter(item => item.status === 1).map((bill, index)  => {

													return (
													<div key={index} className={`d-flex flex-column ${index === 0 ? 'compare-components-first' : 'compare-components'} border-bottom py-3 justify-content-center `}>
														{this.state.dailyBilling.hasOwnProperty(bill.key) || this.state.monthBilling.hasOwnProperty(bill.key) || this.state.threeMonthBilling.hasOwnProperty(bill.key) ? 
															<div className='d-table m-auto'>
																<div className="d-table">
																	<p className="mb-0 d-flex">																														
																	{this.state.dailyBilling.hasOwnProperty(bill.key) ? 
																		<React.Fragment>
																		<span className="align-self-center far fa-arrow-up text-success align-self-end mr-1"></span>
																		<small className="align-self-center f12 font-weight-bold mx-1">
																			{this.state.dailyBilling[bill.key].total_cost}																		
																		</small>	
																		<small className="align-self-center ml-1 text-muted align-self-end">yesterday</small>
																		</React.Fragment>
																		:
																		null
																	}																	
																	</p>
																	<p className="d-flex mb-0">
																		{this.state.monthBilling.hasOwnProperty(bill.key) ?
																		<React.Fragment>
																		<span className="align-self-center far fa-arrow-up text-success align-self-end mr-1"></span>
																		<small className="align-self-center f12 mx-1 font-weight-bold">
																			{this.state.monthBilling[bill.key].total_cost}
																		</small>
																		<small className="align-self-center ml-1 text-muted align-self-end">This Month</small>
																		</React.Fragment>
																		:
																		null
																		} 																			
																	</p>
																</div>
																<div className="d-flex">
																	<p className="d-flex mb-0">
																		{this.state.threeMonthBilling.hasOwnProperty(bill.key) ? 
																		<React.Fragment>
																		<span className="align-self-center far fa-arrow-up text-success align-self-end mr-1"></span>
																		<small className="align-self-center mx-1 f12 font-weight-bold">
																			{this.state.threeMonthBilling[bill.key].total_cost}																		
																		</small>
																		<small className="align-self-center ml-1 text-muted align-self-end">Last 3 Month</small>
																		</React.Fragment>
																		:
																		null
																		}																		
																	</p>
																</div>
															</div>
															:
															<p className="m-auto text-muted mb-0"> No Data available for this criteria</p>
														}
													</div>
													)
												})
												:
													this.state.totalAccArray.map((acc, index) => {
													return (
														<div key={index} className={`d-flex ${index === 0 ? 'compare-components-first' : 'compare-components'} border-bottom pt-2 col-sm-12 justify-content-center minHeight90px`}>
															<p className="m-auto text-muted mb-0"> No Data available for this criteria</p>
														</div>
														)
													})
												}
											</div>
											
											<div className={`d-flex`}>
												<div className="side-title d-flex justify-content-end text-right border-right pr-3">
													<div className="d-flex flex-column align-self-center">
														<h6 className="text-primary mb-0">Life Cycle</h6>
														<p className="small text-muted mb-0">Lifecycle of the resources</p>
													</div>
												</div>
												{Object.entries(this.state.actions).length ? this.state.totalAccArray.filter(item => item.status === 1).map((acc, index) => {
													let x = index
													if(x > 5) {
														for(let y = 5; y < x; y++) {
															y--
															x = (x - 6)
														}
													}
													let totalCount = this.state.actions[acc.key].reduce(function(a, b){
															return a + b.total_count;
													}, 0);
													return (
													<div key={index} className={`d-flex flex-column ${index === 0 ? 'compare-components-first' : 'compare-components'} border-bottom py-3`}>
														<div className="d-flex justify-content-center">
															{this.state.actions.hasOwnProperty(acc.key) ? 
															_.chain(this.state.actions[acc.key])
															.groupBy('action')
															.map((value, key) => ({ label: key, sub: value }))
															.value()
															.map(( act, i) => {
																let actionCount = act.sub.reduce(function(a, b){ return a + b.total_count; }, 0);
																return (
																<div key={i} className="text-center">
																	<small>{actionCount}</small>
																	<div className="">
																		<div className={`vertical-bar bg-muted`}>
																		<div style={{height: 100-parseInt((actionCount*100/totalCount))+'%'}}></div>

																		{act.sub[0] ? 
																			//act.sub[0].status === 'inactive' ? 
																				<div className={`v-progress bg-comparison-${x}`}
																				style={{height: parseInt(act.sub[0].total_count*100 /totalCount)+'%' }}></div>
																			/* : act.sub[1] ? 
																				act.sub[1].status === 'inactive' ?
																					<div className={`v-progress bg-danger`} data-title={act.sub[1].status +' - '+act.sub[1].total_count} 
																					style={{height: parseInt(act.sub[1].total_count*100 /totalCount)+'%' }}></div>
																				: null
																			: null*/
																		: null
																		}

																		{/* {act.sub[0] ? 
																			//act.sub[0].status === 'active' ? 
																				<div className={`v-progress`} data-title={act.sub[0].status +' - '+act.sub[0].total_count} 
																				style={{height: parseInt(act.sub[0].total_count*100 /totalCount)+'%' }}></div>
																			/* : act.sub[1] ? 
																				act.sub[1].status === 'active' ?
																					<div className={`v-progress`} data-title={act.sub[1].status +' - '+act.sub[1].total_count} 
																					style={{height: parseInt(act.sub[1].total_count*100 /totalCount)+'%' }}></div>
																				: null
																			: null 
																		: null
																		} */}
																		
																		{/* {act.sub.map((cat, j) => {
																		return(
																			<React.Fragment>
																		<div className={`v-progress ${cat.status!=='active' ? 'bg-danger' : ''} `} style={{height: parseInt(cat.total_count*100 /totalCount)+'%' }} id={`${cat.status+'_'+cat.action+'_'+i+'_'+j}`}></div>
																		<UncontrolledTooltip placement='top' target={`${cat.status+'_'+cat.action+'_'+i+'_'+j}`}>{cat.status+' - '+cat.total_count}</UncontrolledTooltip> 
																		</React.Fragment> 
																		)

																		})}*/}
																		</div>
																	</div>
																	<small className={`${i===1 ? 'mx-5' : ''}`}>{capitalizeFirstLetter(act.label)}</small>
																</div>
																)
															}) 
															
															:
																<p className="m-auto text-muted mb-0 pt-5"> No Data available for this criteria</p>
															}
														</div>
													</div>
													)
												})
												:
													this.state.totalAccArray.map((acc, index) => {
													return (
														<div key={index} className={`d-flex ${index === 0 ? 'compare-components-first' : 'compare-components'} border-bottom pt-2 col-sm-12 justify-content-center minHeight90px`}>
														<p className="m-auto text-muted mb-0"> No Data available for this criteria</p>
														</div>
														)
													})
												}
											</div>

											<div className="d-flex">
												<div className="side-title d-flex justify-content-end text-right border-right pr-3">
													<div className="d-flex flex-column align-self-center">
														<h6 className="text-primary mb-0">Status</h6>
														<p className="small text-muted mb-0">Status of the resources.</p>
													</div>
												</div>
												
												{Object.entries(this.state.status).length ? this.state.totalAccArray.filter(item => item.status === 1).map((acc, index) => {
													let x = index
													if(x > 5) {
														for(let y = 5; y < x; y++) {
															y--
															x = (x - 6)
														}
													}
													return (
														<div key={index} className={`${index === 0 ? 'compare-components-first' : 'compare-components'} py-3`}>
														{this.state.status.hasOwnProperty(acc.key) ? 
														this.state.status[acc.key].map((item, y) => {
														
															let totalCount = this.state.status[acc.key].reduce(function(a, b){
																	return a + b.total_count;
															}, 0);
															let barWidth = parseInt(item.total_count * 100 / totalCount)+ '%'
															return (
															<div key={y} className="w-100">
																<div className="pr-2 flex-grow-1 w-100 pb-1">
																	<div className="bd-highlight d-flex">
																		<small className={`bg-comparison-text-${x} bd-highlight w-25`}>{capitalizeFirstLetter(item.status)}</small>
																		<div className="progress w-60 m-auto">
																			<div className={`progress-bar bg-comparison-${x}`} style={{width: barWidth}}></div>
																		</div>
																		<small className={`bg-comparison-text-${x} bd-highlight w-15 border-left ml-2 pl-2`}>{item.total_count}</small>
																	</div>
																</div>
															</div>
															)
														})
														:
														<p className="m-auto text-muted mb-0 pt-5"> No Data available for this criteria</p>
														}
														</div>
														)
													})
													
												:
													this.state.totalAccArray.map((acc, index) => {
													return (
														<div key={index} className={`d-flex ${index === 0 ? 'compare-components-first' : 'compare-components'} pt-2 col-sm-12 justify-content-center minHeight90px`}>
														<p className="m-auto text-muted mb-0"> No Data available for this criteria</p>
														</div>
														)
													})
												}
											</div>
											<div className="d-flex displayNone">
												<div className="side-title d-flex justify-content-end text-right border-right pr-3">
													<div className="d-flex flex-column align-self-center">
														<h6 className="text-primary mb-0">Observation</h6>
														<p className="small text-muted mb-0">Officia amet eiusmod eu sunt tempo.</p>
													</div>
												</div>
												{Object.entries(this.state.resources) ? this.state.totalAccArray.filter(item => item.status === 1).map((acc, index) => {
													return (
														<div key={index} className={`d-flex flex-column ${index === 0 ? 'compare-components-first' : 'compare-components'} py-3`}>
															<ul className="list-unstyled pl-3 mb-0 f12 text-muted font-weight-bolder">
																<li> <span className="ml-n3 mr-1 fal fa-chevron-right"></span> Account GE-stag failure count has increased 39% from yesterday 25 April 2020</li>
																<li> <span className="ml-n3 mr-1 fal fa-chevron-right"></span> Account GE-stag failure count has
																	increased 39% from yesterday 25 April 2020</li>
																<li> <span className="ml-n3 mr-1 fal fa-chevron-right"></span> Account GE-stag failure count has
																	increased 39% from yesterday 25 April 2020</li>
															</ul>
														</div>
													)
												})
												: null
												}
											</div>

										</div>
									:
									null}
									{this.state.showLoading ?
										<div className='d-flex justify-content-center m-auto'>
											<Spinner className='text-center' color='dark' size='lg' />
										</div>
									: null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ConsolidatedComparison.propTypes = {
	getTotalAssetCountObject: PropTypes.func,
	getDailyActiveAssetCount: PropTypes.func,
	getTotalAssetCountArray: PropTypes.func,
	getTotalBilling: PropTypes.func,
	getScoreDetails: PropTypes.func,
	getAssetCountByPricingModel: PropTypes.func
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		tagKeys: state.filters.tagKeys,
	}
}

export default AppWrapper(ConsolidatedComparison, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,	
    getAllTagsKeys,
    getAllTagsValues,
	getTotalAssetCountObject,
	getDailyActiveAssetCount,
	getTotalAssetCountArray,
	getTotalBilling,
	getScoreDetails,
	getAssetCountByPricingModel
})
