/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ListDiagnostics.js
 * @author Prakash // on 18/01/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { URL_PATH } from '../../config/urlPath'
import { AppWrapper } from '../common/AppWrapper'
import PropTypes from 'prop-types'

import { momentConvertionUtcToLocalTime, momentDateGivenFormat, subDays, subHours, getDayFromSelectedDuration, capitalizeFirstLetter, capitalizeTheFirstLetterOfEachWord } from '../../utils/utility'
import { HOURLY_DURATION, HOURLY_DURATION_WITHOUT_CUSTOM_DATE, HOURLY_DURATION_2 } from '../../utils/constants'

import { listAllProviders, listAllAccounts, listAllRegions } from '../../actions/commonAction'
import { listDiagnostics, listDiagnosticTemplate } from '../../actions/aiops/DiagnosticsAction'

import Search from '../common/SearchComponent'
import ResizeableFullWidthDarkTable from '../designComponents/Table/ResizeableFullWidthDarkTable'
import ResizeableDarkThemeTable from '../designComponents/Table/ResizeableDarkThemeTable'
import _ from 'lodash'
import { CSVLink } from "react-csv";

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';

import CreateDiagnostics from './CreateDiagnostics'

let timer = null;

class ListDiagnostics extends Component {
	
	constructor(props) {
		super(props)
		
		this.accountRef = React.createRef()
		this.regionRef = React.createRef()
		this.daterRangeRef = React.createRef()

		this.state = {
			showLoading: true,

            //filter section start

            selectedProvider: "",

			accounts: [],
            selectedAccount: [],
            isAccountOpen: false,

            selectedRegion: [],
            isRegionOpen: false,

            selectedDuration: "+7d",

            //filter section end
			
			diagnosticsList: [],
			filteredArray: [],

			selectedTableRow: {},

			activeTab: "Diagnostics",
			selectedTemplate : {}
		}
	}

	componentDidMount = () => {
        if(this.props.providers && this.props.providers.length) {
			this.setState({ providers: this.props.providers, selectedProvider: this.props.providers[0].provider_name },
				() => {
					this.getFilterData('provider')
				}
			)
		} else {
			this.props.listAllProviders((promise, providers) => {
				if (promise) {
					this.setState({ providers, selectedProvider: providers[0].provider_name },
						() => {
							this.getFilterData('provider')
						}
					)
				} else {
					this.setState(
						{ providers: [],  }, 
						() => {
							this.getFilterData()
						}
					)
				}
			})
		}	
	}
	/**
	 * Filter identifiers based on the filter selection
	 */
	getFilterData = () => {
		let params = {}
		if (this.state.selectedProvider) {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		
        if(this.props.accounts && this.props.accounts.length) {
			let allAccounts = this.props.accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
			if(this.props.accounts.length) {
				let accRow = {}
				accRow.account_id = "All"
				accRow.account_name = "All"
				accRow.date = null
				accRow.disabled = false
				accRow.provider = "All"
				allAccounts.unshift(accRow);
			}
			this.setState({ accounts: allAccounts })
		} else {
			this.props.listAllAccounts(params, (promise, allAccounts) => {
				if (promise) {						
					let filterdAccounts = allAccounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
					if(filterdAccounts.length) {
						let accRow = {}
						accRow.account_id = "All"
						accRow.account_name = "All"
						accRow.date = null
						accRow.disabled = false
						accRow.provider = "All"
						filterdAccounts.unshift(accRow);
					}						
					this.setState({ accounts: filterdAccounts })
				}
			})
		}

		this.durationFunction('onLoad')
	}	

    durationFunction = (type) => {
        let duration = this.state.selectedDuration
        let period = duration.substring(duration.length, duration.length-1)
        let durationBtw = getDayFromSelectedDuration(duration)
        let startDate = ""
        if(period === "d") {
            startDate = momentDateGivenFormat(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
        } else {
            startDate = momentDateGivenFormat(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
        }
        let endDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')

        this.setState({ startDate, endDate, changesInFilter: true },
            () => {
                if(type === "onLoad") {
                    this.onSearch('onLoad')
                }
            }
        )
    }

    onSearch = (type) => {
        if(this.state.changesInFilter || !this.state.changesInFilter) {
            this.setState({ 
                changesInFilter: false,
                showLoading: true,
                resetEnabled: type === 'onLoad' ? false : true
            },
                () => {
					if(this.state.selectedProvider !== "") {
						this.listDiagnostics()
					}
                }
            )
        }

		this.resetLoading()
    }

    onReset = () => {
        this.setState({ 
            selectedProvider: this.state.providers && this.state.providers.length === 1 ? this.state.providers[0].provider_name : "",
            selectedAccount: [],
            selectedRegion: [],
            selectedDuration: '+7d',
            changesInFilter: this.state.resetEnabled,
        },
            () => {
                if(this.state.resetEnabled) {
                    this.durationFunction('onLoad')
                }
            }
        )
    }

	listDiagnostics = () => {
        let params = {}
        params.provider = this.state.selectedProvider.toLowerCase()
        if(this.state.selectedAccount.length && !this.state.selectedAccount.includes('All')) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion.length && !this.state.selectedRegion.includes('All')) {
            params.region = this.state.selectedRegion
        }
	    params.start_time = this.state.startDate
	    params.end_time = this.state.endDate

        this.props.listDiagnostics(params, (promise, response) => {
            if(promise) {
				if(!_.isEqual(this.state.diagnosticsList, response.results)) {
					this.setState({ 
						diagnosticsList: response.results ? response.results : [], 
						filteredArray:  response.results ? response.results: [],
					},
						() => {
							this.formDiagnosticHeader()
							if(this.state.diagnosticsList.filter(e => !e.diagnostic_end_time && capitalizeFirstLetter(e.status) !== "Failed" && capitalizeFirstLetter(e.status) !== "Completed").length) {
								if(!this.state.stopRefresh) {
									timer = setTimeout(() => this.listDiagnostics(), 5000)
								}
								// setTimeout(() => { this.listDiagnostics() }, 5000)
							}
						}    
					)
				} else {
					if(this.state.diagnosticsList.length && this.state.diagnosticsList.filter(e => !e.diagnostic_end_time && capitalizeFirstLetter(e.status) !== "Failed" && capitalizeFirstLetter(e.status) !== "Completed").length) {
						// setTimeout(() => { this.listDiagnostics() }, 5000)
						if(!this.state.stopRefresh) {
							timer = setTimeout(() => this.listDiagnostics(), 5000)
						}
					}
				}
            } else {
                this.setState({ diagnosticsList: {}, showLoading: false })
            }
        })
	}

	formDiagnosticHeader = () => {
		let dataResult = this.state.filteredArray && this.state.filteredArray.length ? this.state.filteredArray : []
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === "string") {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})
			this.setState({ headers, downloadDignosticName: "diagnostics" })
		}
	}

	listDiagnosticTemplate = () => {
        let params = {}
        params.provider = this.state.selectedProvider.toLowerCase()
        if(this.state.selectedAccount.length && !this.state.selectedAccount.includes('All')) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion.length && !this.state.selectedRegion.includes('All')) {
            params.region = this.state.selectedRegion
        }
        // if(this.state.selectedDuration === 'custom') {
        //     params.start_time = this.state.startDate
        //     params.end_time = this.state.endDate
        // } else {
        //     params.duration = this.state.selectedDuration
        // }

        this.props.listDiagnosticTemplate(params, (promise, response) => {
            if(promise) {
				this.setState({ 
					diagnosticsTemplateList: response.results ? response.results : [], 
					filteredTemplateArray:  response.results ? response.results: [],
					showLoading: false
				},
					() => this.formTemplateHeaders()	
				)
            } else {
                this.setState({ diagnosticsTemplateList: [], filteredTemplateArray: [], showLoading: false })
            }
        })
	}

	formTemplateHeaders = () => {
		let dataResult = this.state.filteredArray && this.state.filteredArray.length ? this.state.filteredArray : []
		if(dataResult.length) {
			let templateHeaders = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === "string") {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					templateHeaders.push(headerRow)
				}
			})
			this.setState({ templateHeaders, downloadTempName: "diagnostics_tempalte" })
		}
	}
	

	handleClickOutside = (event) => {
		if (this.accountRef && !this.accountRef.current.contains(event.target)) {
			this.setState({ isAccountOpen: false })
		} else {
			this.setState({ isAccountOpen: true })
		}

		if (this.regionRef && !this.regionRef.current.contains(event.target)) {
			this.setState({ isRegionOpen: false })
		} else {
			this.setState({ isRegionOpen: true })
		}
	}	

	handleMultiSelectChange = (field, arrayValue) => {
		let value = arrayValue.map(item => item.value)
		// let label = arrayValue.map(item => item)
		let selectedValue = []
		if(field === 'selectedAccount') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.accounts.forEach(acc => {
						selectedValue.push(acc.account_id)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.accounts.length -1)) {
					selectedValue.push('All')
				}
			}			
		} else if(field === 'selectedRegion') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.regions.length -1)) {
					selectedValue.push('All')
				}
			}
		}

		this.setState({ [field]: selectedValue, changesInFilter: true })
	}

	getMultiSelectedCount = (type, array) => {
		return array.length && array.filter(e => e.toLowerCase() === 'all').length ? 'All' :  array.length ? array.length +' Selected' : 'All'
	}

	
	resetLoading = () => {
		setTimeout(() => { this.setState({ showLoading: false }) }, 5000)		
	}

	onClickViewTroubleshooting = (diagnostics) => {
		timer = null

		this.props.history.push({
            pathname: URL_PATH.DIAGNOSTICS_DETAILS,
            state: {
				diagnostics: diagnostics
			}
        })
	}

	/**
	 * Renders diagnostics list
	*/
	render() {
		return (
			<div className='container-fluid overflow-auto flex-grow-1 bg-muted' onClick={ (event) => { this.handleClickOutside(event) } }>
				<div className={`loaderOverlay ${this.state.showLoading ? '' : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
				{this.state.showCreateDiagnostics ? (            
					<CreateDiagnostics 
						closePanel={() => this.setState({ showCreateDiagnostics: false })}
						callListApi={() => this.setState({ showCreateDiagnostics: false, activeTab: "Diagnostics" }, () => this.listDiagnostics())}
						actionType={this.state.actionType}
						selectedTemplate = {this.state.selectedTemplate}
					/>
				) : null}
				<div className='row h-100'>
					<div className='col-sm-12 p-0'>
						<div className='title-section p-2'>
							<div className='row'>
								<div className='col-4 align-self-center'>
									<h6 className='text-white m-0'>Diagnostics</h6>
									<p className='text-white m-0'>Consolidated list of Diagnostics performed on Cloud resources</p>
								</div>
								<div className='col-8 align-self-center'>
									<div className={`col-md-12 d-flex flex-wrap justify-content-end bd-highlight`}>
										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: '150px' }}>
											<label className="mb-1">Provider</label>
											<Select
												placeholder={'All'}
												isSearchable={false}
												//onFocus={this.openProivderMenu}
												className='f13 p-0 colorBlack reactSelectFilterDrop'
												value={({
													value: this.state.selectedProvider,
													label: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : <span className="placeholder">Select</span>
												})}
												options={this.state.providers && this.state.providers.map(item => ({
													value: item.provider_name,
													label: item.provider_name,	
												}))}
												onChange={event =>  
													this.setState(
														{
															selectedProvider: event.value,
															changesInFilter: true
														}
													)
												}
											/>
										</div>
										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} ref={this.accountRef} style={{maxWidth: '150px' }}>
											<label className="mb-1">Account</label>
											<div className='dlpHeaderMultiSelection removeDropdownSearchBar'>
												<ReactMultiSelectCheckboxes						
													placeholderButtonLabel="All"
													menuIsOpen ={this.state.isAccountOpen}
													getDropdownButtonLabel={() => this.getMultiSelectedCount('account', this.state.selectedAccount)}
													onChange={arr => {
													this.handleMultiSelectChange('selectedAccount', arr ? arr : []) }}
													options={this.state.accounts && this.state.accounts.map(account => ({
														value: account.account_id,
														label: account.account_name,
													}))}
													value={this.state.selectedAccount.map(comp => ({
														value: comp,
													}))}
												/>
											</div>
										</div>
										<div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} ref={this.regionRef} style={{maxWidth: '150px'}}>
											<label className="mb-1">Region</label>
											<div className={`dlpHeaderMultiSelection ${this.state.regions && this.state.regions.length > 10 ? '' : 'removeDropdownSearchBar' } `}>
												<ReactMultiSelectCheckboxes						
													placeholderButtonLabel="Select"
													menuIsOpen ={this.state.isRegionOpen}
													getDropdownButtonLabel={() => this.getMultiSelectedCount('region', this.state.selectedRegion)}
													onChange={arr => {
													this.handleMultiSelectChange('selectedRegion', arr ? arr : []) }}
													options={this.state.regions && this.state.regions.map(region => ({
														value: region.region,
														label: region.name,
													}))}
													value={this.state.selectedRegion.map(reg => ({
														value: reg,
													}))}
												/>
											</div>
										</div>
										<div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zindex1`} ref={this.daterRangeRef} style={{maxWidth: '150px'}}>
											<label className="mb-1">Duration</label>
											<Select
                                                placeholder={'Select'}
                                                isSearchable={false}
                                                // menuIsOpen={true}
                                                className='f13 p-0 colorBlack reactSelectFilterDrop'
                                                value={({
                                                    value: this.state.selectedDuration && this.state.selectedDuration !== "" ? this.state.selectedDuration : 'Select',
                                                    label: this.state.selectedDuration && this.state.selectedDuration !== "" ? HOURLY_DURATION_WITHOUT_CUSTOM_DATE.filter(e => e.value === this.state.selectedDuration).length && HOURLY_DURATION_WITHOUT_CUSTOM_DATE.filter(e => e.value === this.state.selectedDuration)[0].option : <span className="placeholder">Select</span>
                                                })}
                                                options={HOURLY_DURATION_WITHOUT_CUSTOM_DATE && HOURLY_DURATION_WITHOUT_CUSTOM_DATE.map(item => ({
                                                    value: item.value,
                                                    label: item.option,	
                                                }))}
                                                onChange={event => 
                                                    this.setState({
                                                        selectedDuration: event.value,
                                                        changesInFilter: true
                                                    },
                                                        () => {
                                                            this.durationFunction()
                                                        }
                                                    )
                                                }
                                            />
										</div>

										<div className="d-flex align-self-center">
											<div className="btn-group" role="group" aria-label="governance-dash">
												<button type="button" className={`cursorPointer btn btn-secondary border-right`} onClick={()=> this.onSearch()}>Search</button>
												<button type="button" className={`cursorPointer btn btn-secondary border-left`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
											</div>
										</div>
										<button className='ml-3 btn btn-primary btn-sm align-self-center' onClick={() => this.setState({ showCreateDiagnostics: true, actionType: "Create", selectedTemplate: {} })}>
											New Diagnostics
										</button>
									</div>
								</div>
							</div>
						</div>

                        <div className='container-fluid bg-dark m-2 rounded h-100'>
							<div className="d-flex justify-content-between py-2">
								<ul className={`nav nav-tabs border-0 bd-highlight bg-transparent mt-n2 shadow-none mb-0 ${this.state.showLoading ? 'disabled' : ''}`} id="myTab" role="tablist">
									<li className="nav-item">
										<span className={`nav-link text-white cursorPointer ${this.state.activeTab === 'Diagnostics' ? ' active' : ''}`} onClick={() => this.setState({ activeTab: 'Diagnostics' })}>Diagnostics</span>
									</li>
									<li className="nav-item">
										<span className={`nav-link text-white cursorPointer ${this.state.activeTab === 'Templates' ? ' active' : ''}`} onClick={() => this.setState({ activeTab: 'Templates', showLoading: true }, () => this.listDiagnosticTemplate())}>Templates</span>
									</li>
								</ul>
								<div className={`mb-n2 w-20`}>
									{this.state.activeTab === 'Diagnostics' ?
										<div className="d-flex">
											<CSVLink 
												data={this.state.filteredArray ? this.state.filteredArray : []} 
												headers={this.state.headers ? this.state.headers : []}
												filename={this.state.downloadDignosticName+".csv"}
												className={"mt-2"}
												target="_blank"
											>
												<span className="d-flex text-primary-color mr-2">
													<i className="fas fa-download text-primary-color mr-2"></i>
													{/* csv */}
												</span>
											</CSVLink>
											<Search
												data={this.state.diagnosticsList}
												applyTags={false}
												applyLiteDarkTags={true}
												topClassName={'bg-black5 align-self-center mr-1 border-gray5 rounded-5 f12'}
												searchClassName={'px-2 f12'}
												searchIconColor={'text-gray5 f12'}
												searchPlaceHolder={'Search....'}
												className={"bg-transparent text-white pl-0 form-control-sm f12"}
												filteredData={searchResult => {
													this.setState({ filteredArray: searchResult })
												}}
											/>
										</div>
									:
										<div className="d-flex">
											<CSVLink 
												data={this.state.filteredTemplateArray ? this.state.filteredTemplateArray : []} 
												headers={this.state.templateHeaders ? this.state.templateHeaders : []}
												filename={this.state.downloadTempName+".csv"}
												className={"mt-2"}
												target="_blank"
											>
												<span className="d-flex text-primary-color mr-2">
													<i className="fas fa-download text-primary-color mr-2"></i>
													{/* csv */}
												</span>
											</CSVLink> 
											<Search
												data={this.state.diagnosticsTemplateList}
												applyTags={false}
												applyLiteDarkTags={true}
												topClassName={'bg-black5 align-self-center mr-1 border-gray5 rounded-5 f12'}
												searchClassName={'px-2 f12'}
												searchIconColor={'text-gray5 f12'}
												searchPlaceHolder={'Search....'}
												className={"bg-transparent text-white pl-0 form-control-sm f12"}
												filteredData={searchResult => {
													this.setState({ filteredTemplateArray: searchResult })
												}}
											/>
										</div>
									}
								</div>
							</div>
							{this.state.activeTab === 'Diagnostics' ?
								<React.Fragment>
								{this.state.diagnosticsList && !this.state.showLoading ? (
									<div className="resizeTablePadding">
										<ResizeableFullWidthDarkTable
											columns={[
												{
													Header: "Diagnostics Id",
													accessor: "diagnostic_id",
													
												},
												{
													Header: "Name",
													accessor: "name",
													
												},
												{
													Header: "Checks",
													accessor: "checks_count",
													
												},
												{
													Header: "Type",
													accessor: "invocation_method",
													
												},
												{
													Header: "Submitted Date",
													accessor: "diagnostic_start_time",
													Cell: cellInfo => cellInfo.row.original.diagnostic_start_time && cellInfo.row.original.diagnostic_start_time !== "" ?  momentConvertionUtcToLocalTime(cellInfo.row.original.diagnostic_start_time, "DD MMM YYYY HH:mm") : ""
												},
												{
													Header: "Duration",
													accessor: "duration",
													Cell: cellInfo => cellInfo.row.original.duration && cellInfo.row.original.duration !== "" && cellInfo.row.original.duration !== "custom" ? (HOURLY_DURATION.filter(e => e.value === cellInfo.row.original.duration).length ? HOURLY_DURATION.filter(e => e.value === cellInfo.row.original.duration)[0].option : (HOURLY_DURATION_2.filter(e => e.value === cellInfo.row.original.duration).length ? HOURLY_DURATION_2.filter(e => e.value === cellInfo.row.original.duration)[0].option : cellInfo.row.original.duration)) : (cellInfo.row.original.start_time ? (momentConvertionUtcToLocalTime(cellInfo.row.original.start_time, "DD MMM YYYY HH:mm")+ " - "+momentConvertionUtcToLocalTime(cellInfo.row.original.end_time, "DD MMM YYYY HH:mm")): ""),

													// Cell: cellInfo => cellInfo.row.original.duration && (cellInfo.row.original.duration !== "" || cellInfo.row.original.duration !== "custom") ? HOURLY_DURATION.filter(e => e.value === cellInfo.row.original.duration)[0].option : (cellInfo.row.original.start_time ? (momentConvertionUtcToLocalTime(cellInfo.row.original.start_time, "DD MMM YYYY HH:mm")+ " - "+momentConvertionUtcToLocalTime(cellInfo.row.original.end_time, "DD MMM YYYY HH:mm")): ""),
													
												},
												{
													Header: "Provider/Account/Region",
													Cell: cellInfo => (
														(cellInfo.row.original.provider ? cellInfo.row.original.provider.toUpperCase() : '') + ': ' + cellInfo.row.original.account_id + ': ' + (cellInfo.row.original.region ? (cellInfo.row.original.region === 'NA' ? 'Global' : cellInfo.row.original.region) : '')
													),
													

												},
												{
													Header: "Tags",
													accessor: "tags",
													Cell: cellInfo => (
														<div className="d-flex flex-wrap">
														{cellInfo.row.original.tags && cellInfo.row.original.tags.length ? 
															cellInfo.row.original.tags.map(tag => {
																return (
																	tag.key !== "" && tag.value !== "" ?
																		<small className="badge badge-secondary mr-2 mb-1">{tag.key+' : '+tag.value} </small>
																	: null
																)
															})
														: null}
														</div>
													),
													
												},
												// {
												// 	Header: "Description",
												// 	accessor: "description",
												// 	
												// },
												{
													Header: 'Status',
													accessor: 'status',
													Cell: cellInfo => (
														<span
															className={`badge status-badge badge-outline-${
																cellInfo.row.original.status && cellInfo.row.original.status.toLowerCase() === 'completed' ? 'success' : cellInfo.row.original.status && cellInfo.row.original.status.toLowerCase() === 'failed' ? 'danger' : 'warning'
																} badge-pill`}
														>
															{cellInfo.row.original.status ? cellInfo.row.original.status : ''}
														</span>
													),
												},
												{
													Header: 'Actions',
													Cell: cellInfo => (
														<span className="badge badge-info mr-1 cursorPointer" onClick={() => this.setState({ stopRefresh: true }, () => this.onClickViewTroubleshooting(cellInfo.row.original))}>View</span>
													),
													width: 80
												},
												{
													Header: ' ',
													width: 1
												}
											]}
											data={this.state.filteredArray && this.state.filteredArray}
											perPage={20}
											// tableHead={'bg-dark'}
											dashboard
											tooltipFor={[]}
											onClickRow={tableRow => {}}
											manualWidth={true}
											sortByColumn={"diagnostic_start_time"}
											// tableSize="table-xs"
										/>
									</div>
								) : !this.state.showLoading && !this.state.diagnosticsList && this.state.diagnosticsList.length ?
									<div className='d-flex justify-content-center m-4'>
										<p>There are no data on this criteria. Please try adjusting your filter.</p>
									</div>
								: null}
								</React.Fragment>
							: 
								<React.Fragment>
								{this.state.diagnosticsTemplateList && !this.state.showLoading ? (
									<div className="resizeTablePadding">
										<ResizeableDarkThemeTable
											columns={[
												{
													Header: "Date",
													accessor: "diagnostic_id",
													Cell: cellInfo => cellInfo.row.original.created_at && cellInfo.row.original.created_at !== "" ?  momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, "DD MMM YYYY HH:mm") : ""
												},
												{
													Header: "Name",
													accessor: "name",
													width: 250
												},
												{
													Header: "Duration",
													accessor: "duration",
													Cell: cellInfo => cellInfo.row.original.duration && cellInfo.row.original.duration !== "" && cellInfo.row.original.duration !== "custom" ? (HOURLY_DURATION.filter(e => e.value === cellInfo.row.original.duration).length ? HOURLY_DURATION.filter(e => e.value === cellInfo.row.original.duration)[0].option : (HOURLY_DURATION_2.filter(e => e.value === cellInfo.row.original.duration).length ? HOURLY_DURATION_2.filter(e => e.value === cellInfo.row.original.duration)[0].option : cellInfo.row.original.duration)) : (cellInfo.row.original.start_time ? (momentConvertionUtcToLocalTime(cellInfo.row.original.start_time, "DD MMM YYYY HH:mm")+ " - "+momentConvertionUtcToLocalTime(cellInfo.row.original.end_time, "DD MMM YYYY HH:mm")): ""),
													width: 200
												},
												{
													Header: "Provider/Account/Region",
													Cell: cellInfo => (
														(cellInfo.row.original.provider ? cellInfo.row.original.provider.toUpperCase() : '') + ': ' + cellInfo.row.original.account_id + ': ' + (cellInfo.row.original.region ? (cellInfo.row.original.region === 'NA' ? 'Global' : cellInfo.row.original.region) : '')
													),
													width: 250

												},
												{
													Header: "Tags",
													accessor: "tags",
													Cell: cellInfo => (
														<div className="d-flex flex-wrap">
														{cellInfo.row.original.tags ? 
															cellInfo.row.original.tags.map(tag => {
																return (
																	<small className="badge badge-secondary mr-2 mb-1">{tag.key+' : '+tag.value} </small>
																)
															})
														: null}
														</div>
													),
													width: 250
												},
												{
													Header: "Description",
													accessor: "description",
													width: 600
												},
												{
													Header: 'Actions',
													Cell: cellInfo => (
														<span className="badge cursorPointer badge-info f13" onClick={() => this.setState({ showCreateDiagnostics: true, actionType: "Run", selectedTemplate: cellInfo.row.original })}>
															Run 
														</span>
													),
													width: 80
												},
												{
													Header: ' ',
													width: 1
												}
											]}
											data={this.state.filteredTemplateArray && this.state.filteredTemplateArray}
											perPage={20}
											// tableHead={'bg-dark'}
											dashboard
											tooltipFor={[]}
											onClickRow={tableRow => {}}
											manualWidth={true}
											tableSize="table-small"
										/>
									</div>
								) : !this.state.showLoading && !this.state.diagnosticsTemplateList ?
									<div className='d-flex justify-content-center m-4'>
										<p>There are no data on this criteria. Please try adjusting your filter.</p>
									</div>
								: null}
								</React.Fragment>
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ListDiagnostics.propTypes = {
	listAllProviders: PropTypes.func,
	listAllAccounts: PropTypes.func,
	listAllRegions: PropTypes.func,	
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('-',state)
	return {
		providers: state.filters.providers,
        accounts: state.filters.accounts,
		regions: state.filters.regions,
	}
}

export default AppWrapper(ListDiagnostics, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
	listDiagnostics,
	listDiagnosticTemplate
})