/*************************************************
 * Tvastar
 * @exports
 * @file AutomationAction.js
 * @author parakash // 12/11/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'

import {
	AIOPS_AUTOMATION,
	AIOPS_AUTOMATION_GET_APIS,
	AIOPS_AUTOMATION_GET_TOTAL_COUNT,
	
	AIOPS_AUTOMATION_GET_DAILY_COUNT,
	AIOPS_AUTOMATION_GET_TOP_RESULTS,

	LIST_AUTOMATION_ACTIONS,
	CREATE_AUTOMATION,
	EDIT_AUTOMATION,
	LIST_AUTOMATION_PLANS,
	LIST_PREBUILD_AUTOMATION_PLANS,
	LIST_AUTOMATION_EXECUTIONS,
	GET_AUTOMATION_EXECUTION,
	GET_AUTOMATION_EXECUTION_DAILY_TREND,
	VALIDATE_GROK_PATTERN,
	ENDISABLE_AUTOMATION,
	VIEW_AUTOMATION,
	START_AUTOMATION,

	USER_SERVICES,
	AUTOMATION_TRIGGER_LIST_EVENTS,


} from '../config'
import { ACTION_TYPES } from './types'

/**
 * Action to get apis
 * @param {Object} body
 * @param {Function} callback
*/
export const getApis = (body, callback) => {
	return async () => {
		await API.post(AIOPS_AUTOMATION, AIOPS_AUTOMATION_GET_APIS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			callback(false)
		})
    }
}

/**
 * Action to get total count
 * @param {Object} body
 * @param {Function} callback
*/
export const getTotalCount = (body, callback) => {
	return async () => {
		await API.post(AIOPS_AUTOMATION, AIOPS_AUTOMATION_GET_TOTAL_COUNT, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			callback(false)
		})
    }
}

/**
 * Action to get daily count
 * @param {Object} body
 * @param {Function} callback
*/
export const getDailyCount = (body, callback) => {
	return async () => {
		await API.post(AIOPS_AUTOMATION, AIOPS_AUTOMATION_GET_DAILY_COUNT, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			callback(false)
		})
    }
}


/**
 * Action to get top results
 * @param {Object} body
 * @param {Function} callback
*/
export const getTopResults = (body, callback) => {
	return async () => {
		await API.post(AIOPS_AUTOMATION, AIOPS_AUTOMATION_GET_TOP_RESULTS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			callback(false)
		})
    }
}

/**
 * Action to list automation actionS
 * @param {Object} body
 * @param {Function} callback
*/
export const listAutomationActions = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(AIOPS_AUTOMATION, LIST_AUTOMATION_ACTIONS, { body })
			dispatch({
				type: ACTION_TYPES.SET_LIST_AUTOMATION_ACTIONS,
				payload: result,
			})
			callback(true, result)
		} catch(err) {
			callback(false, {})
		}
    }
}

/**
 * Action to create automation
 * @param {Object} body
 * @param {Function} callback
*/
export const createAutomation = (body, callback) => {
	return async () => {
		await API.post(AIOPS_AUTOMATION, CREATE_AUTOMATION, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let errMessage = {}
			if(!err.response || !err.response.data) {
				let dataRow = {}
				dataRow.status = false
				dataRow.message = "Error in inserting data"
				errMessage = dataRow
			} else {
				errMessage = err.response.data
			}
			callback(false, errMessage)
		})
    }
}

/**
 * Action to edit automation
 * @param {Object} body
 * @param {Function} callback
*/
export const editAutomation = (body, callback) => {
	return async () => {
		await API.post(AIOPS_AUTOMATION, EDIT_AUTOMATION, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let errMessage = {}
			if(!err.response || !err.response.data) {
				let dataRow = {}
				dataRow.status = false
				dataRow.message = "Error in updating data"
				errMessage = dataRow
			} else {
				errMessage = err.response.data
			}
			callback(false, errMessage)
		})
    }
}

/**
 * Action to view-automation
 * @param {Object} body
 * @param {Function} callback
*/
export const viewAutomation = (body, callback) => {
	return async () => {
		await API.post(AIOPS_AUTOMATION, VIEW_AUTOMATION, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			callback(false)
		})
    }
}

/**
 * Action to endisable-automation
 * @param {Object} body
 * @param {Function} callback
*/
export const enableDisableAutomation = (body, callback) => {
	return async () => {
		await API.post(AIOPS_AUTOMATION, ENDISABLE_AUTOMATION, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			// let errMessage = {}
			// if(!err.response || !err.response.data) {
			// 	let dataRow = {}
			// 	dataRow.status = false
			// 	dataRow.message = "Error in updating data"
			// 	errMessage = dataRow
			// } else {
			// 	errMessage = err.response.data
			// }
			callback(false)
		})
    }
}

/**
 * Action to list automation plans
 * @param {Object} body
 * @param {Function} callback
*/
export const listAutomationPlans = (body, callback) => {
	return async () => {
		await API.post(AIOPS_AUTOMATION, LIST_AUTOMATION_PLANS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let errMessage = {}
			if(!err.response || !err.response.data) {
				let dataRow = {}
				dataRow.status = false
				dataRow.message = "Error in inserting data"
				errMessage = dataRow
			} else {
				errMessage = err.response.data
			}
			callback(false, errMessage)
		})
    }
}

/**
 * Action to list-prebuilt-automation-plans
 * @param {Object} body
 * @param {Function} callback
*/
export const listPrebuildAutomationPlans = (body, callback) => {
	return async () => {
		await API.post(AIOPS_AUTOMATION, LIST_PREBUILD_AUTOMATION_PLANS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let errMessage = {}
			callback(false, errMessage)
		})
    }
}
/**
 * Action to list automation executions
 * @param {Object} body
 * @param {Function} callback
*/
export const listAutomationExecutions = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(AIOPS_AUTOMATION, LIST_AUTOMATION_EXECUTIONS, { body })
			callback(true, result)
		} catch(err) {
			callback(false, {})
		}
    }
}

/**
 * Action to start-automation
 * @param {Object} body
 * @param {Function} callback
*/
export const startAutomation = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(AIOPS_AUTOMATION, START_AUTOMATION, { body })
			callback(true, result)
		} catch(err) {
			callback(false, {})
		}
    }
}

/**
 * Action to get automation execution
 * @param {Object} body
 * @param {Function} callback
*/
export const getAutomationExecution = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(AIOPS_AUTOMATION, GET_AUTOMATION_EXECUTION, { body })
			callback(true, result)
		} catch(err) {
			callback(false, {})
		}
    }
}

/**
 * Action to get-automation-execution-daily-trend
 * @param {Object} body
 * @param {Function} callback
*/
export const getAutomationExecutionDailyTrend = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(AIOPS_AUTOMATION, GET_AUTOMATION_EXECUTION_DAILY_TREND, { body })
			callback(true, result)
		} catch(err) {
			callback(false, {})
		}
    }
}

/**
 * Action to get-automation-execution-daily-trend
 * @param {Object} body
 * @param {Function} callback
*/
export const validateGrokPattern = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(AIOPS_AUTOMATION, VALIDATE_GROK_PATTERN, { body })
			callback(true, result)
		} catch(err) {
			callback(false, {})
		}
    }
}

/**
 * Action to list events
 * @param {Object} body
 * @param {Function} callback
*/
export const automationListEvents = (body, callback) => {
	return async () => {
		await API.post(USER_SERVICES, AUTOMATION_TRIGGER_LIST_EVENTS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			callback(false)
		})
    }
}