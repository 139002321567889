/*************************************************
 * Tvastar
 * @exports
 * @file ObjectListInputs.js
 * @author Prakash // on 7/4/23
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import _ from 'lodash'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

import { setNewEditRuleDetails } from '../../../../../actions/governance/newGovernanceAction'
import { DynamicApiToDevCall, getAllTagsKeys, getAllTagsValues } from '../../../../../actions/commonAction'
import { currentUTCtime, capitalizeFirstLetter, onlyNumeric } from '../../../../../utils/utility'
import ConsolidatedComparison from '../../../../resources/comparison/ConsolidatedComparison'

class ObjectListInputs extends Component {
	constructor(props) {
		super(props)
		this.optionTypeRef = React.createRef()
		this.scrollRef = React.createRef()
		this.state = {
			objArray: [0],
			hasErrorInRuleForm: false,
			hasError: this.props.hasError,
		}
	}

	componentDidMount = () => {
        let masterData = this.props.masterData
        let actionDetails = []
        if(masterData.actions && masterData.actions.length) {
            actionDetails = masterData.actions
        }
        let variables = masterData.variables
        variables.forEach(item => {
            item.value_object_list.forEach(objList => {
                if(item.component === "select" || item.component === "multiselect") {
                    if(item.options) {
                        this.setState({ [item.variable+"_options"]: item.options, [item.variable+"_0"]: item.default ? item.default : "" })
                    }
                }
            })
        })

        if(this.props.editNewRuleDetails && this.props.editNewRuleDetails.policies) {
            if(this.props.editNewRuleDetails.policies.filter(e => e.rule_category === this.props.masterData.rule_category).length) {
                let savedData = this.props.editNewRuleDetails.policies.filter(f => f.rule_id === this.props.masterData.rule_id)
                if(savedData.length) {
                    this.setState({ savedData },
                        () => {
                            this.savedData()
                        }
                    )
                }
            }
        }
    }

    savedData = () => {
        let objArray = []
		let savedData = this.state.savedData
		savedData.forEach((row, index) => {
			row.variables.forEach(item => {
                if(item.component === "value_object_list") {
                    item.value_object_list.forEach((listArray, objInd) => {
                        objArray.push(objInd)
                        listArray.forEach(objLst => {
                            if(objLst.component === "text") {
                                this.setState({ ["input_"+objLst.key+"_"+objInd+"_"+this.props.item]: objLst.value })
                            }
                        })
                    })
                }
			})
		})
		this.setState({ objArray })
	}

	onChangeInput = () => {        
        let totalArray = []
        this.state.objArray.map(objInd => {
            let inputValue = []
            this.props.varb.value_object_list.forEach((objLst, index) => {
                let dataRow = {}
                Object.entries(objLst).forEach(([oKey, oValue]) => {
                    if(oKey === "value") {
                        dataRow.value = this.state["input_"+objLst.key+"_"+objInd+"_"+this.props.item]
                    } else {
                        dataRow[oKey] = oValue
                    }
                })
                inputValue.push(dataRow)

            })
            totalArray.push(inputValue)
        })
        this.props.objInputChange(totalArray)
	}

	addNewList = (item) => {
		let rowList = this.state.objArray
        if(rowList) {
            let value = rowList[rowList.length - 1]
            value = value+1 
            rowList.push(value);
        }
        
        this.setState({ objArray: rowList })
	}    

    removeNewList = (objInd) => {
        this.props.varb.value_object_list.forEach(objLst => {
            if(objLst.component === "text") {
                this.setState({ ["input_"+objLst.key+"_"+objInd+"_"+this.props.item]: ""  })
            }
        })        

        let rowList = this.state.objArray.filter(e => e !== objInd);
        // rowList.splice(objInd, 1);
        this.setState({ objArray: rowList },
            () => this.onChangeInput()    
        )
    }

    render() {
		return (
            this.state.objArray.map(objInd => {
                return(
                    <div className="d-flex flex-wrap w-100">
                        {this.props.varb.value_object_list.map(objLst => {
                            return(
                                !this.props.viewMode ?
                                    <React.Fragment>
                                    {objLst.component === "text" ?
                                        <input 
                                            type="text" 
                                            className={`inputField textBoxWidth mr-2`}
                                            style={{minHeight: "38px"}}
                                            placeholder={objLst.label}
                                            value={this.state["input_"+objLst.key+"_"+objInd+"_"+this.props.item]}
                                            onChange={e => this.setState({ ["input_"+objLst.key+"_"+objInd+"_"+this.props.item]: e.target.value },
                                                () => this.onChangeInput(this.props.varb.variable)
                                            )}
                                        />
                                        : objLst.component === "select" ?
                                            <Select
                                                isSearchable={true}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                className={`selectOption mr-2 mt-2`}
                                                value={({
                                                    value: this.state["select_"+objLst.key+"_"+objInd+"_"+this.props.item],
                                                    label: this.state["select_"+objLst.key+"_"+objInd+"_"+this.props.item] && this.state["select_"+objLst.key+"_"+objInd+"_"+this.props.item] !== "" ? this.state["select_"+objLst.key+"_"+objInd+"_"+this.props.item] : <span className="placeholder">Key</span>
                                                })}														
                                                
                                                options={this.state[this.props.varb.variable+"_options"] && this.state[this.props.varb.variable+"_options"].map(item => ({
                                                    value: item,
                                                    label: item,
                                                }))}
                                                onChange={event => this.setState({
                                                    ["select_"+objLst.key+"_"+objInd+"_"+this.props.item]: event.value
                                                },
                                                    () => this.onChangeInput(this.props.varb.variable)
                                                )}
                                            />
                                        : null}
                                    </React.Fragment>
                                :
                                    <span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{this.state["input_"+objLst.key+"_"+objInd+"_"+this.props.item]}</span>
                            )
                        })}
                        {this.state.objArray.length > 1 ?
                            <span className={`far fa-trash cursor-pointer f18 align-self-center ml-4 mt-3`} onClick={() => this.removeNewList(objInd)}></span>
                        : null}
                        <span className={`far fa-plus cursor-pointer f18 mt-3 align-self-center mr-4 ml-2`} onClick={() => this.addNewList(this.props.item)}></span>
                    </div>
                )
            })
        )
    }
}

/**
 * Type of the props used in the component
 */
 ObjectListInputs.propTypes = {
	setNewEditRuleDetails: PropTypes.func,
	editNewRuleDetails: PropTypes.object,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('stateVersion',state.governance)
	return {
		editNewRuleDetails: state.governance.editNewRuleDetails,
		tagKeys: state.filters.tagKeys,
	}
}

export default connect(mapStateToProps, { 
	setNewEditRuleDetails,
	DynamicApiToDevCall,
    getAllTagsKeys,
    getAllTagsValues
})(ObjectListInputs)
