/*************************************************
 * Tvastar
 * @exports
 * @file assetsReducer.js
 * @author Rajasekar // on 26/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from '../../actions/types'

const INITIAL_STATE = {
	assets: {},
	assetDetails: {},
	assetNetworkDetails: {},
	assetSGDetails: {},
	assetStorageDetails: {},
	selectedTableRow: {},
	selectedFilters: {
		provider: '',
		account: '',
		region: '',
		service: '',
		minScore: '0',
		maxScore: '100',
		duration: '+14d',
	},	
	resourcesPropsDetails: {},
	providerCounts: [],
	statusCounts: [],
	regionCounts: [],
	scoreCounts: [],
	dailyAssetCount: {},
}

/**
 * To store assets list in redux store
 * @param {Object} state
 * @param {Object} action
 */
export const assetsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {		
		case ACTION_TYPES.SET_ASSETS_LIST:
			return {
				...state,
				assets: { ...action.payload },
			}
		case ACTION_TYPES.SET_SELECTED_TABLE_ROW:
			return {
				...state,
				selectedTableRow: { ...action.payload },
			}
		case ACTION_TYPES.SET_ADVANCED_ASSETS_LIST:
			return {
				...state,
				assetsAdvancedList: { ...action.payload },
			}
		case ACTION_TYPES.SET_ADVANCED_SELECTED_TABLE_ROW:
			return {
				...state,
				selectedTableRow: { ...action.payload },
			}
		case ACTION_TYPES.SET_ASSET_DETAILS:
			return {
				...state,
				assetDetails: { ...action.payload },
			}
		case ACTION_TYPES.SET_ASSET_NETWORK_DETAILS:
			return {
				...state,
				assetNetworkDetails: { ...action.payload },
			}
		case ACTION_TYPES.SET_ASSET_SG_DETAILS:
			return {
				...state,
				assetSGDetails: { ...action.payload },
			}
		case ACTION_TYPES.SET_ASSET_STORAGE_DETAILS:
			return {
				...state,
				assetStorageDetails: { ...action.payload },
			}
		case ACTION_TYPES.SET_PROVIDER_COUNT:
			return {
				...state,
				providerCounts: action.payload,
			}
		case ACTION_TYPES.SET_ASSET_COUNT:
			return {
				...state,
				dailyAssetCount: { ...action.payload },
			}
		case ACTION_TYPES.SET_STATUS_COUNT:
			return {
				...state,
				statusCounts: action.payload,
			}
		case ACTION_TYPES.SET_REGION_COUNT:
			return {
				...state,
				regionCounts: action.payload,
			}
		case ACTION_TYPES.SET_SCORE_COUNT:
			return {
				...state,
				scoreCounts: action.payload,
			}
		case ACTION_TYPES.SET_SELECTED_FILTER:
			return {
				...state,
				selectedFilters: action.payload,
			}
		case ACTION_TYPES.SET_RESOURCES_PROPS_DETAILS:
			return {
				...state,
				resourcesPropsDetails: action.payload,
			}
		default:
			return state
	}
}
