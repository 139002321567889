import React, { Component } from 'react'

class Checkbox extends Component {

  constructor(props) {
    super(props);
    this.state = props || {};
    this.state = {
      validationMessage: '',
      value: this.props.value,
    }
    this.props.recordSelection(this.props.name, this.props.value);
    this.handleChange = this.handleChange.bind(this);
  }

  // componentDidMount = () => {
  //   console.log(this.state.value)
  //   // this.validateTextField();
  // };
    

  handleChange = ({ target }) => {
    // let value = target.value
    let value = !this.state.value
    this.setState({ value })
    // this.updateParentFormFieldValue(this.props.name, value);
    this.props.recordSelection(this.props.name, value);
  }

  updateParentFormFieldValue = (name, value) => {
    this.props.recordSelection(name, value);
  };

  componentDidUpdate = (prevProps) => {
    if(this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value })
    }
  }

  render() {
    return (
      <div style={{ display: "inline" }}>
        <div className="d-flex">
          <div className="form-check">
              <input type="checkbox"
                  onChange={this.handleChange}
                  className="form-check-input"
                  checked={this.state.value}
              />
              <label className={`form-check-label ${this.props.labelClassName}`}>{this.props.label ? this.props.label : ''}</label>
          </div>
        </div>
      </div>
    )
  }
};

export default Checkbox