/*************************************************
 * Tvastar
 * @exports
 * @file SelectedMonitoringMetrics.js
 * @author Prakash // on 01/09/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { momentDateGivenFormat, addHours, momentConvertionUtcToLocalTime, getConvertedBytesUnit, convertBytes, convertBytesTo, convertBytesWithoutUnit, convertSeconds, convertSecondsWithoutUnit, countFormater, countFormaterWithoutUnit, convertBits, convertBitsWithoutUnit, subDays, getDayFromSelectedDuration, subHours } from '../../utils/utility'
import Chart from 'react-apexcharts'
import { getSreDetails } from '../../actions/aiops/DiagnosticsAction'

import { getMonitoringMetricsByTrend } from '../../actions/aiops/MetricsAction'
import { setDiagnosticsPropsDetails } from '../../actions/aiops/DiagnosticsAction'
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

class SelectedMonitoringMetrics extends Component {
    constructor(props) {
        super(props)
        this.annotationTypeRef = React.createRef()
        this.state = {
            showLoading: true,
            showAnnotation: true,
        }
    }
    
    componentDidMount = () => {

        let startTime = this.props.diagnostics.start_time ? this.props.diagnostics.start_time : this.props.diagnostics.diagnostic_start_time
        let endTime = this.props.diagnostics.end_time ? this.props.diagnostics.end_time : this.props.diagnostics.diagnostic_end_time

        let duration = this.props.diagnosticsDetails.duration
        if(duration && duration !== "custom") {
            let period = duration.substring(duration.length, duration.length-1)
            let durationBtw = getDayFromSelectedDuration(duration)
            if(period === "d") {
                startTime = momentDateGivenFormat(subDays(startTime, durationBtw), "YYYY-MM-DD HH:mm:ss")
            } else {
                startTime = momentDateGivenFormat(subHours(startTime, durationBtw), "YYYY-MM-DD HH:mm:ss")
            }

        }        

        this.setState({ metricsStartTime: startTime, metricsEndTime: endTime },
            () => this.onLoad()    
        )
        
    }

    onLoad = () => {
        if(Object.entries(this.props.diagnosticSelectedMonitMertrics).length) {
            if(this.props.diagnosticSelectedMonitMertrics.hasOwnProperty(this.props.assetDetails.asset_id)) {
                this.setState({ metricsByTrend: this.props.diagnosticSelectedMonitMertrics[this.props.assetDetails.asset_id], showLoading: false },
                    () => {
                        this.metricsListGraph()
                    }
                )
            } else {
                this.getMonitoringMetricsByTrend()
            }
        } else {
            this.getMonitoringMetricsByTrend()
        }
    }

    getMonitoringMetricsByTrend = () => {
        if(this.props.assetDetails.asset_id) {
            let params = {}
            params.provider = this.props.selectedDiagnosticEvent.data && this.props.selectedDiagnosticEvent.data.provider ? this.props.selectedDiagnosticEvent.data.provider : 'aws'
            params.anomaly = false
            if(this.props.assetDetails.asset_id) {
                params.asset_id = this.props.assetDetails.asset_id
            } else {
                params.asset_name = [this.props.assetDetails.asset_name]
            }
            if(this.props.assetDetails.service_name) {
                params.service_name = this.props.assetDetails.service_name
            }
            params.start_time = this.state.metricsStartTime
            params.end_time = this.state.metricsEndTime
            params.metric_name = this.props.selectedDiagnosticEvent.data && this.props.selectedDiagnosticEvent.data.event_type ? this.props.selectedDiagnosticEvent.data.event_type : ""
            if(params.metric_name) {
                this.props.getMonitoringMetricsByTrend(params, (promise, result) => {
                    if(promise) {
                        this.setState({ metricsByTrend : [] },
                            () => {
                                this.setState({ metricsByTrend: result, showLoading: false },
                                    () => {
                                        this.metricsListGraph()
                                        let obj = this.props.diagnosticSelectedMonitMertrics
                                        obj[this.props.assetDetails.asset_id] = result
                                        this.props.setDiagnosticsPropsDetails('diagnosticSelectedMonitMertrics', obj)
                                    }
                                )
                            }
                        )
                    } else {
                        this.setState({ showLoading: false })
                    }
                })
            }
        } else {
            this.setState({ showLoading: false })
        }
    }

    metricsListGraph = () => {
        let graphDetails = this.state.metricsByTrend
        if(graphDetails) {
            graphDetails.forEach((graph, index) => {
                let graphData = graph.data
                let graphDates = graph.dates
                Object.entries(graphData).forEach(([key, item]) => {

                    let maxValue = item.statistics && item.statistics.max ? item.statistics.max : 1
                    let avgValue = item.statistics && item.statistics.avg ? item.statistics.avg : 1
                    let minValue = item.statistics && item.statistics.min ? item.statistics.min : 1
                    
                    
                    let graphConvertTo = ""

                    let maxRandomNumber = maxValue
                    let minRandomNumber = minValue
                    if(item.unit) {
                        graphConvertTo = this.getConvertedUnit(maxValue, item.unit)
                        maxRandomNumber = this.getGrapUnitValueWithoutUnit(maxValue, item.unit, "round")
                        minRandomNumber = this.getGrapUnitValueWithoutUnit(minValue, item.unit, "round")
                    }

                    let array = []
                    let graphLabels = []
                    item.values.forEach((val, j) => {
                        graphLabels.push(momentConvertionUtcToLocalTime(graphDates[j], 'YYYY-MM-DD HH:mm:ss'))
                        let arrayValues = []
                        arrayValues.push(momentConvertionUtcToLocalTime(graphDates[j], 'YYYY-MM-DD HH:mm:ss'))
                        if(item.unit) {
                            val = this.getGrapUnitValue(val, item.unit, "", graphConvertTo)
                        }
                        arrayValues.push(val)
                        array.push(arrayValues)
                    })

                    if(array.length === 1) {
                        let arrayValues = []
                        arrayValues.push(addHours(momentConvertionUtcToLocalTime(graphDates[0], 'YYYY-MM-DD HH:mm:ss'), 0.5))
                        arrayValues.push(0)
                        array.push(arrayValues)
                    }

                    let series = [{
                        data: array,
                        name: key,
                    }]
                    let xaxisAnnotation = []
                    if(this.state.showAnnotation) {
                        // xaxisAnnotation = [{
                        //     x: new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                        //     strokeDashArray: 0,
                        //     borderColor: '#FF6F00',
                        //     label: {
                        //         borderColor: '#FF6F00',
                        //         style: {
                        //             // fontSize: '12px',
                        //             color: '#fff',
                        //             background: '#FF6F00'
                        //         },
                        //         orientation: 'horizontal',
                        //         text: '',
                        //     }
                        // }
                        // ]
                    }
                        
                    if(this.state.selectedAnnotationTypes && this.state.selectedAnnotationTypes.length) {
                        let borderColor = ''
                        let background = ''
                        let color = ''
                        let text = ''
                        let location = ''
                        this.state.selectedAnnotationTypes.forEach(item => {
                            text = item.value
                            if(item.value === 'C' || item.value === 'E') {
                                borderColor = '#24A597'
                                background = '#24A597'
                                color = '#fff'
                                location = new Date(momentConvertionUtcToLocalTime(graphLabels[180], 'YYYY-MM-DD HH:mm:ss')).getTime()                        

                                let dataRow = {
                                    x: location,
                                    strokeDashArray: 0,
                                    borderColor: borderColor,
                                    opacity: 1,
                                    label: {
                                        borderColor: borderColor,
                                        orientation: 'horizontal',
                                        text: text,
                                        style: {
                                            color: color,
                                            background: background,
                                            fontSize: '10px',
                                            // width: '10px',
                                        },
                                    }
                                }
                                xaxisAnnotation.push(dataRow)
                            }
                        })
                    }
                    
                    let annotations = {
                        xaxis: xaxisAnnotation
                    }

                    let options = {
                        chart: {
                            // group: 'metrics_and_sre',
                            // id:'metrics_'+i,
                            type: 'area',
                            width: '100%',
                            toolbar: {
                                show: false,
                            },
                            zoom: {
                                enabled: false
                            },
                            sparkline: {
                                enabled: false
                            }
                        },
                        stroke: {
                            show: true,
                            curve: 'smooth',
                            lineCap: 'butt',
                            width: 2,
                            dashArray: 0,      
                        },
                        colors: ['#775BA2'],
                        fill: {
                            type: "gradient",
                            gradient: {
                                // shadeIntensity: 1,
                                // opacityFrom: 0.7,
                                // opacityTo: 0.9,
                                // stops: [0, 90, 100]
                                gradientToColors: ['#775BA2'],
                                shadeIntensity: 0,
                                opacityFrom: .3,
                                opacityTo: 1,
                                stops: [30, 90]
                            }
                        },
                        tooltip: {
                            custom: function({series, seriesIndex, dataPointIndex, w}) {
                                let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
                                dateLable = dateLable.toString()
                                dateLable = momentDateGivenFormat(dateLable, 'DD MMM HH:mm')
                                let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]
                                return '<div class="arrow_box">'+dateLable+' <span style="color:'+ w.globals.colors +'">' + parseFloat(val).toFixed(2) + '</span> </div>'
                            },
                            fixed: {
                                enabled: false
                            },
                            x: {
                                show: false,
                            },
                            y: {
                                show: false
                            },        
                            marker: {
                                show: false
                            }
                        },
                        xaxis: {
                            type: 'datetime',
                            labels: {
                                datetimeUTC: false,
                                style: {
                                    colors: '#60687C'
                                },
                                // formatter: function (value) {
                                //     return momentDateGivenFormat(value, 'HH:mm')
                                // },
                            },                            
                            axisTicks: {
                                show: false
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        yaxis: {
                            show: true,
                            axisTicks: {
                                show: false,
                            },
                            axisBorder: {
                                show: false,
                                color: '#60687C'
                            },
                            tooltip: {
                                enabled: false
                            },
                            // tickAmount: 2,
                            min: 0,
                            // max: yaxisMaxValue, 
                            tickAmount: 1,
                            labels: {
                                offsetX: -10,
                                // minWidth: 50,
                                show: true,
                                style: {
                                    fontSize: '9px',
                                    colors: '#60687C'
                                },
                                // formatter: (value) => { 
                                //     if(Number(value) === value && value % 1 === 0) {
                                //         return Math.ceil(value)
                                //     } else {
                                //         return Math.ceil(value)
                                //     }
                                // },
                                // offsetX: 0,
                                // offsetY: 0,
                            }
                        },
                        grid: {
                            show: true,
                            borderColor: '#2D323E',
                            strokeDashArray: 0,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: true
                                },
                                labels: {
                                    style: {
                                        colors: ['#FFF'],
                                    }
                                },
                                axisTicks: {
                                    show: false
                                }
                            },   
                            yaxis: {
                                lines: {
                                    show: false
                                },
                                labels: {
                                    style: {
                                        colors: ['#969AA7'],
                                    }
                                }
                            },                
                            padding: {
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0
                            },
                        },
                        annotations: annotations,
                    }
                    this.setState({ [key+'_'+graph.asset_id]: series, [key+'_options_'+graph.asset_id]: options })
                })
            })
        }
    }
	
	handleClickOutside(event) {}

    getConvertedUnit = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = getConvertedBytesUnit(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                // val = getConvertedSecondsUnit(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                // val = getConvertedBitsUnit(val, unit)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                // val = getConvertedCountFormater(val)
            } else {
                val = ""
            }
        } else if(val !== '') {           
            val = ""
        }
        return val
    }

    getGrapUnitValue = (val ,unit, valueType, convertTo) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseInt(val))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                if(convertTo) {
                    val = convertBytesTo(val, unit, valueType, convertTo)
                } else {
                    val = convertBytes(val, unit, valueType)
                }                         
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit, valueType, convertTo)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit, valueType, convertTo)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    getGrapUnitValueWithoutUnit = (val ,unit, valueType) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytesWithoutUnit(val, unit, valueType)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSecondsWithoutUnit(val, unit, valueType)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBitsWithoutUnit(val, unit, valueType)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                val = countFormaterWithoutUnit(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseInt(val))
        }
        return val
    }

    render() {
        return (
            !this.state.showLoading ?
                <div class={`bg-dark rounded p-3`} onClick={ (event) => { this.handleClickOutside(event) } }>
                    <div class="d-flex justify-content-between">
                        <div>
                            <p class="f16 text-white mb-1">{this.props.selectedDiagnosticEvent.data && this.props.selectedDiagnosticEvent.data.event_type ? this.props.selectedDiagnosticEvent.data.event_type : ""} Metrics</p>
                            {this.state.metricsByTrend && this.state.metricsByTrend.length ?
                                <div className="d-flex">
                                    <p class="f12 text-primary-color mb-0">Key Metrics for {this.props.assetDetails.resource_type ? this.props.assetDetails.resource_type : ''}</p>
                                    <p class="f12 text-primary-color mb-0 ml-2">{this.props.assetDetails.asset_name ? this.props.assetDetails.asset_name : ''}</p>
                                </div>
                            : null}
                        </div>
                        <div className="d-flex">
                            <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{"("+momentConvertionUtcToLocalTime(this.state.metricsStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentConvertionUtcToLocalTime(this.state.metricsEndTime, 'DD MMM YYYY HH:mm')+")"}</p>
                        </div>
                    </div>
                    <div class="container-fluid mt-2">                    
                        {this.state.metricsByTrend && this.state.metricsByTrend.length ?
                            this.state.metricsByTrend.map((item, index) => {
                                return(
                                Object.keys(item.data).map(key => {
                                    return (
                                        <React.Fragment>
                                        {/* <p class="text-white f16 p-1 mb-0">{key}</p> */}
                                        {this.state[key+'_options_'+item.asset_id] && Object.keys(this.state[key+'_options_'+item.asset_id]).length && this.state[key+'_'+item.asset_id] && this.state[key+'_'+item.asset_id].length ?
                                            <div className="cursorPointer transparentTooltip mb-n4 mt-n4">
                                                <Chart options={this.state[key+'_options_'+item.asset_id]} series={this.state[key+'_'+item.asset_id]} type="area" height={this.props.height ? this.props.height : 115} width={'100%'}/>
                                            </div>
                                        : null}
                                        </React.Fragment>
                                    )
                                })
                                )
                            })
                        : 
                            <div className='d-flex justify-content-center m-4'>
                                No Metrics for the this criteria, Please adjust your filter to get the metric details.
                            </div>
                        }
                    </div>
                </div>
            : 
                <div className={`bg-dark rounded p-3`}>
                    <p className={`mb-2 text-white`}><b>Metrics</b></p>
                    <div className='d-flex justify-content-center m-4'>
                        <Spinner className='text-center' color='white' size='lg' />
                    </div>
                </div>
        )
    }
}

/**
* Type of the props used in the component
*/
SelectedMonitoringMetrics.propTypes = {
    getSreDetails: PropTypes.func,
    getMonitoringMetricsByTrend: PropTypes.func,
    setDiagnosticsPropsDetails: PropTypes.func
}   

const mapStateToProps = state => {
    return {
        diagnosticSelectedMonitMertrics: state.aiops.diagnosticsPropsDetails && state.aiops.diagnosticsPropsDetails.diagnosticSelectedMonitMertrics ? state.aiops.diagnosticsPropsDetails.diagnosticSelectedMonitMertrics : {},
        
        selectedDiagnosticEvent: state.aiops.diagnosticsPropsDetails && state.aiops.diagnosticsPropsDetails.selectedDiagnosticEvent ? state.aiops.diagnosticsPropsDetails.selectedDiagnosticEvent : {},

        diagnosticsDetails: state.aiops.aiopsDiagnosticsDetails && state.aiops.aiopsDiagnosticsDetails.result ? state.aiops.aiopsDiagnosticsDetails.result : [],
    }
}

export default connect(mapStateToProps, {
    getSreDetails,
    getMonitoringMetricsByTrend,
    setDiagnosticsPropsDetails
})(withRouter(SelectedMonitoringMetrics))