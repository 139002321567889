import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Checkbox from '../../../common/formFields/Checkbox'
import SelectField from '../../../common/formFields/SelectField'

import { setAiopsPropsDetails } from '../../../../actions/aiops/AiopsAction'
import { listStorageTypes } from '../../../../actions/governance/governanceAction'
import { getAssetDetailExt } from '../../../../actions/aiops/RemediationAction'

class ListStorageTypesTypes extends Component {

  constructor(props) {
    super(props);
    this.state = props || {};
    this.state = {
      validationMessage: '',
      parentValue: this.props.value,
      showLoading: true,
    }
    this.updateFormFieldValue = this.updateFormFieldValue.bind(this);
  }

  componentDidMount = () => {
    this.getStorageType()
    let params = {}
    params.provider = Array.isArray(this.props.caseDetails.provider) ? this.props.caseDetails.provider[0].toLowerCase() : this.props.caseDetails.provider.toLowerCase()
    params.resource_type = this.props.aiopsSelectedRemediationProps.resource_type
    this.props.listStorageTypes(params, (success, result) => {
      if (success) {
          let storageTypesOptions = []
          result.length && result[0].results && result[0].results.forEach(res => {
            let storageRow = {}
            storageRow.description = res.description
            storageRow.options = res.volume_details.volume_name
            storageRow.volume_details = res.volume_details
            storageTypesOptions.push(storageRow)
        })
        this.setState({ storageTypesOptions, showLoading: false })
      }
    })
  }

  componentDidUpdate = (prevProps) => {
    if(this.props.validationError !== prevProps.validationError) {
			this.setState({ 
        validationError: this.props.validationError, 
        StorageTypeError: this.props.aiopsRemediationParams.StorageTypeError ? this.props.aiopsRemediationParams.StorageTypeError : '',
        ApplyImmediatelyError: this.props.aiopsRemediationParams.ApplyImmediatelyError ? this.props.aiopsRemediationParams.ApplyImmediatelyError : ''
      })
		}
  }

  getStorageType = () => {
    let params = {}
    params.provider = Array.isArray(this.props.caseDetails.provider) ? this.props.caseDetails.provider[0].toLowerCase() : this.props.caseDetails.provider.toLowerCase()
    params.service_name = this.props.aiopsSelectedRemediationProps.service_name
    params.asset_id = this.props.aiopsSelectedRemediationProps.asset_id

    this.props.getAssetDetailExt(params, (promise, result) => {
      console.log(result,'/././././')
      if(promise) {
        this.setState({ db_instance_storage_type: result && result[0] && result[0].db_instance_storage_type ? result[0].db_instance_storage_type : '' })
      } else {
        this.setState({ result: [] })
      }
    })
  }

  updateFormFieldValue = (fieldName, fieldValue) => {
    let obj = this.props.aiopsRemediationParams
    obj[fieldName] = fieldValue
    

    if(fieldName === 'StorageType') {
      this.setState({ StorageTypeError: '' })
      obj.StorageTypeError = ''
    } else {
      this.setState({ ApplyImmediatelyError: '' })
      obj.ApplyImmediatelyError = ''
    }
    this.props.setAiopsPropsDetails('remediationParams', obj)

    this.setState({ [fieldName]: fieldValue });
  };

  render() {
    return (
      !this.state.showLoading ?
        <div className="row mt-2">
          <div className="col-md-12">
            <div className="form-group">
              <label>Change to<small className="ml-1">Current Storage: {this.state.db_instance_storage_type ? this.state.db_instance_storage_type : ''} </small></label>
              <SelectField 
                className="f13 p-0 form-control dark-select-theme col-sm-6"
                recordSelection={this.updateFormFieldValue}
                value={this.state.StorageType ? this.state.StorageType : ''}
                name={'StorageType'}
                label='Select'
                placeholder="All"
                required={false}
                selectOptions={this.state.storageTypesOptions ? this.state.storageTypesOptions : []}
              />
              {this.state.validationError && this.state.StorageTypeError && this.state.StorageTypeError !== '' ?
                <small className='text-danger'>{this.state.StorageTypeError}</small>
              : null }
            </div>
            <div className="form-group">
              <Checkbox 
                className="form-check-input"
                labelClassName={'text-white'}
                recordSelection={this.updateFormFieldValue}
                value={this.state.ApplyImmediately ? true : false}
                name={'ApplyImmediately'}
                label={'ApplyImmediately'}
              />
              {this.state.validationError && this.state.ApplyImmediatelyError && this.state.ApplyImmediatelyError !== '' ?
                <small className='text-danger'>{this.state.ApplyImmediatelyError}</small>
              : null }
            </div>
          </div>
        </div>
      : null
    )
  }
};

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
 const mapStateToProps = state => {	
	console.log('ListStorageTypesTypes', state)  
	return {  
    caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : [],
    aiopsRemediationParams: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.remediationParams ? state.aiops.aiopsPropsDetails.remediationParams : {},
    aiopsSelectedRemediationProps: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.selected_remediation ? state.aiops.aiopsPropsDetails.selected_remediation : {},
  }
}

/**
 * Type of the props used in the component
 */
 ListStorageTypesTypes.propTypes = {  
  setAiopsPropsDetails: PropTypes.func,
  getAssetDetailExt: PropTypes.func,
  listStorageTypes: PropTypes.func
}


export default connect(mapStateToProps, {
  setAiopsPropsDetails,
  getAssetDetailExt,
  listStorageTypes
})(withRouter(ListStorageTypesTypes))
