/*************************************************
 * Tvastar
 * @exports
 * @file SidePanel.js
 * @author Prakash // on 28/06/2020
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { addMinutes, addHours } from '../../../utils/utility'
import Chart from 'react-apexcharts'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

const mertcisAnnotationTypes = [
    {prefix: 'C', label: ' - Change', value: 'C'},
    {prefix: 'S', label: ' - Case Start Time', value: 'S'},    
    {prefix: 'E', label: ' - Case End Time', value: 'E'}
]
  
const mertcisYAnnotationTypes = [
    {prefix: 'Avg', label: ' - Average', value: 'Avg'},
    {prefix: 'P95', label: ' - P 95', value: 'P95'},    
    {prefix: 'P99', label: ' - P 99', value: 'P99'}
]

class ApexBarLineBrush extends Component {
    constructor(props) {
        super(props)
        this.annotationXTypeRef = React.createRef()
        this.annotationYTypeRef = React.createRef()
        this.state = {
            annotationXaxis: [],
            isannotationXaxisOpen: false,
      
            annotationYaxis: [],
            isannotationYaxisOpen: false
        }
    }
    
    componentDidMount = () => {
        let graphLables = this.dateIntervalFunction('minute')
        let bar = this.valueFunction(10)
        let line = this.valueFunction(10)
        let area = this.valueFunction(10)
        let graphData = this.props.graphData;

        let graphLables1 = this.dateIntervalFunction('date')
        // let graphLables = ['01/01/2003', '01/02/2003', '01/03/2003', '01/04/2003', '01/05/2003', '01/06/2003', '01/07/2003', '01/08/2003', '01/09/2003', '01/10/2003', '01/11/2003']

        let team_a_data = []
        let team_b_data = []
        let team_c_data = []
        let brushData = []
        // let bar =  [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30, 23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30, 23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30, 23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30 ]
        // let line = [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39]
        // let area = [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
        let x=0
        let brushDayCount = 0
        let brush = [0]
        graphLables.forEach((lab, i) => {
            let dataArray = []
            dataArray.push(lab)
            dataArray.push(bar[i])
            team_a_data.push(dataArray)
            brushData.push(dataArray)

            dataArray = []
            dataArray.push(lab)
            dataArray.push(area[i])
            team_b_data.push(dataArray)

            dataArray = []
            dataArray.push(lab)
            dataArray.push(line[i])
            team_c_data.push(dataArray)

            if(x < 60 && i !== (graphLables.length-1)) {
                brushDayCount += area[i]
                x++
            } else {
                brush.push(brushDayCount)
                x=1
                brushDayCount=0
            }
        })

        graphLables1.forEach((lab, i) => {
            let dataArray = []
            dataArray.push(lab)
            dataArray.push(brush[i])
            // brushData.push(dataArray)
        })
        // let annotationXaxis = ['S', 'E']
        // let annotationYaxis = [3, 6, 8]

        // let data = [[1492799400000, 55], [1492885800000, 43], [1492972200000, 37], [1493058600000, 75], [1493145000000, 86], [1493231400000, 33], [1493317800000, 86], [1493404200000, 47], [1493490600000, 64], [1493577000000, 31], [1493663400000, 63], [1493749800000, 83], [1493836200000, 56], [1493922600000, 85], [1494009000000, 77], [1494095400000, 80], [1494181800000, 53], [1494268200000, 80], [1494354600000, 86], [1494441000000, 89], [1494527400000, 52], [1494613800000, 46], [1494700200000, 81], [1494786600000, 86], [1494873000000, 90], [1494959400000, 84], [1495045800000, 64], [1495132200000, 48], [1495218600000, 68], [1495305000000, 80]]

        let brushStartTime = '2021/07/02 23:00:00'
        let brushEndTime = '2021/07/02 23:59:59'

        if(graphData === 'mockData') {
            let series = [
                {
                    name: 'TEAM A',
                    type: 'column',
                    data: team_a_data
                }, 
                {
                    name: 'TEAM B',
                    type: 'area',
                    data: team_b_data
                }, 
                {
                    name: 'TEAM C',
                    type: 'line',
                    data: team_c_data
                }
            ]
            
            let brushSeries = [{
                data: brushData
            }]

            let brushOptions = {
                chart: {
                    id: 'chart_1',
                    height: 130,
                    type: 'area',
                    brush:{
                        target: 'chart_2',
                        enabled: true
                    },
                    selection: {
                        enabled: true,
                        xaxis: {
                            min: new Date(brushStartTime).getTime(),
                            max: new Date(brushEndTime).getTime()
                        },
                        stroke: {
                            show: false,
                            curve: 'straight',
                            lineCap: 'round',
                            colors: '#039BE5',
                            // colors: '#00A3CB',
                            width: 0,
                            dashArray: 0,      
                        },
                        fill: {
                            color: "#FFFFFF",
                            opacity: 0.5
                        },  
                    },
                    // events:{
                    //     selection: (chartContext, { xaxis, yaxis }) => {
                    //         var minDate = new Date(xaxis.min)
                    //         var maxDate = new Date(xaxis.max)
                            
                    //         let brushStartTime = momentDateGivenFormat(minDate, 'YYYY/MM/DD HH:mm:ss');
                    //         let brushEndTime = momentDateGivenFormat(maxDate, 'YYYY/MM/DD HH:mm:ss');
                    //         this.setState({ brushStartTime, brushEndTime })
                    //     },
                    //     click: function(event, chartContext, config) {
                    //         this.options()
                    //     }
                    // }
                },
                
                labels: graphLables,
                colors: ['#008FFB'],
                fill: {
                    type: 'gradient',
                    gradient: {
                        opacityFrom: 0.91,
                        opacityTo: 0.1,
                    }
                },
                xaxis: {
                    show: this.props.xaxis,
                    type: 'datetime',
                    // categories: labels,
                    labels: {
                        datetimeUTC: false,
                        show: this.props.xaxis,
                        style: {
                            fontSize: '9px',
                            colors: this.props.axisLabelColor
                        },
                    },
                },
                yaxis: {
                    show: this.props.yaxis,
                    tickAmount: 2,
                    labels: {
                        show: this.props.yaxisLabel,
                        style: {
                            fontSize: '9px',
                            colors: this.props.axisLabelColor
                        },
                    },
                },
                grid: {
                    show: this.props.grid,
                    borderColor: '#35363A',
                    strokeDashArray: 0,
                    position: 'back',
                    xaxis: {
                        lines: {
                            show: true
                        },
                        labels: {
                            style: {
                                colors: ['#969AA7'],
                            }
                        },  
                    },   
                    yaxis: {
                        lines: {
                            show: true
                        },
                        labels: {
                            style: {
                                colors: ['#969AA7'],
                            }
                        }
                    },
                },
            }

            this.setState({ series, brushSeries, graphLables, brushStartTime, brushEndTime, brushOptions },
                () => this.options()
            )
        }
        // if(graphData && Object.entries(graphData).length) {
        //     this.drawGraph(graphData)
        // }
    }

    options = () => {
        let graphLables = this.state.graphLables
        let annotations = {}
        if(this.props.annotations) {
            let xaxisAnnotaions = []
            if(this.state.annotationXaxis) {
                let borderColor = ''
                let background = ''
                let color = ''
                let text = ''
                let location = ''
                this.state.annotationXaxis.forEach(item => {
                    text = item.value
                    if(item.value === 'S') {
                        location = new Date(graphLables[5]).getTime()
                        borderColor = '#FF6F00'
                        background = '#FF6F00'
                        color = '#fff'
                    } else if(item.value === 'E') {
                        location = new Date(graphLables[120]).getTime()
                        borderColor = '#FF6F00'
                        background = '#FF6F00'
                        color = '#fff'
                    } else if(item.value === 'C') {
                        borderColor = '#24A597'
                        background = '#24A597'
                        color = '#fff'
                        location = new Date(graphLables[180]).getTime()
                    }

                    let dataRow = {
                        x: location,
                        strokeDashArray: 0,
                        borderColor: borderColor,
                        label: {
                            borderColor: borderColor,
                            orientation: 'horizontal',
                            text: text,
                            style: {
                                color: color,
                                background: background,
                                padding: {
                                    left: 0,
                                    right: 10,
                                    // bottom: 0,
                                }
                            },
                        }
                    }
                    xaxisAnnotaions.push(dataRow)
                })
            }

            let yaxisAnnotaions = []
            if(this.state.annotationYaxis) {
                let borderColor = ''
                let background = ''
                let color = ''
                let text = ''
                let y = ''
                let y2 = null
                this.state.annotationYaxis.forEach((item, i) => {
                    if(item.value === 'Avg') {
                        text = ' Avg '
                        borderColor = '#775BA2'
                        background = '#775BA2'
                        color = '#fff'
                        y = 4
                        y2= null
                    } else if(item.value === 'P95') {
                        text = 'P 95'
                        borderColor = '#FF6F00'
                        background = '#FF6F00'
                        color = '#000'
                        y = 6
                        y2 = null
                    }  else if(item.value === 'P99') {
                        text = 'P 99'
                        borderColor = '#F44336'
                        background = '#F44336'
                        color = '#fff'
                        y = 9
                        y2 = null
                    } 

                    let dataRow = {
                        y: y,
                        y2: y2,
                        borderColor: borderColor,
                        strokeDashArray: 0,
                        label: {
                            borderColor: borderColor,
                            style: {
                                color: color,
                                background: background,
                                // padding: {
                                //     left: -58,
                                //     right: 68
                                // }
                            },
                            text: text,
                        }
                    }
                    yaxisAnnotaions.push(dataRow)
                })
            }

            // console.log('yaxisAnnotaions', yaxisAnnotaions)
            
            annotations = {
                xaxis: xaxisAnnotaions,
                yaxis: yaxisAnnotaions
            }
        }

        let options = {
            chart: {
                id: 'chart_2',
                height: 350,
                type: 'line',
                stacked: this.props.stacked,
                redrawOnParentResize: true,
                toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                      download: false,
                      selection: false,
                      zoom: false,
                      zoomin: true,
                      zoomout: true,
                      pan: false,
                      reset: true,
                      customIcons: []
                    },
                    autoSelected: 'zoom'
                },
                animations: {
                  enabled: false
                },
                zoom: {
                    enabled: true
                },
            },
            colors:['#17a2b8', '#9A77D1', '#FFD200'],
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [0, 2, 3],
                curve: 'smooth'
            },
            plotOptions: {
                bar: {
                    columnWidth: this.props.columnWidth
                }
            },
              
            fill: {
                opacity: [0.85, 0.25, 1],
                gradient: {
                    inverseColors: false,
                    // shade: 'light',
                    type: "vertical",
                    opacityFrom: 0.35,
                    opacityTo: 0.1,
                    stops: [0, 100, 100, 100]
                }
            },
            labels: graphLables,
            markers: {
                size: 0
            },
            xaxis: {
                show: this.props.xaxis,
                type: 'datetime',
                // categories: labels,
                labels: {
                    datetimeUTC: false,
                    show: this.props.xaxis,
                    style: {
                        fontSize: '9px',
                        colors: this.props.axisLabelColor
                    },
                },
            },
            yaxis: {
                show: this.props.yaxis,
                min: 0,
                labels: {
                    show: this.props.yaxisLabel,
                    style: {
                        fontSize: '9px',
                        colors: this.props.axisLabelColor
                    },
                },
            },
            tooltip: {
                shared: true,
                intersect: false,
            },
            grid: {
                show: this.props.grid,
                borderColor: '#35363A',
                strokeDashArray: 0,
                position: 'back',
                xaxis: {
                    lines: {
                        show: true
                    },
                    labels: {
                        style: {
                            colors: ['#969AA7'],
                        }
                    },  
                },   
                yaxis: {
                    lines: {
                        show: true
                    },
                    labels: {
                        style: {
                            colors: ['#969AA7'],
                        }
                    }
                },
            },
            legend: {
                show: this.props.legend,
            },

            annotations: annotations,
        }

        this.setState({ options: {} },
            () => { this.setState({ options }) }
        )
    }

    valueFunction = (max) => {
        // var start = '2021-07-01 00:00:00'
        // var end = '2021-07-01 23:59:59'

        let data = []
        // let x = 0
        for(let i=0;i<2881;i++){
            // if(x < 11) {
            //     x++
            //     data.push(x*val)
            // } else {
            //     x=1
            //     data.push(x)
            // }
            data.push(Math.floor(Math.random() * max))
        }
        
        return data
    }

    dateIntervalFunction = (type) => {
        var start = '2021-07-01 00:00:00'
        // var end = '2021-07-01 23:59:59'

        let dateArray = []
        if(type === 'minute') {
            for(let i=0;i<2881;i++){
                dateArray.push(addMinutes(start, i))
            }
        } else {
            for(let i=0;i<48;i++){
                dateArray.push(addHours(start, i))
            }
        }
        // // round starting minutes up to nearest 15 (12 --> 15, 17 --> 30)
        // // note that 59 will round up to 60, and moment.js handles that correctly
        // start.minutes(Math.ceil(start.minutes() / 15) * 15);
    
        // var result = [];
    
        // var current = moment(start);
    
        // while (current <= end) {
        //     result.push(current.format('YYYY-MM-DD HH:mm'));
        //     current.add(15, 'minutes');
        // }
    
        // console.log('------------------',result)
        return dateArray
    }
    

	handleMultiSelectChange = (childKey, arrayValue) => {
		// let value = arrayValue.map(item => item.value)
		// let label = arrayValue.map(item => item)
        
        if(childKey === 'annotationXaxisType') {
            this.setState({ annotationXaxis: arrayValue },
                () => this.options()
            )
        } else if(childKey === 'annotationYaxisType') {
            this.setState({ annotationYaxis: arrayValue },
                () => this.options()
            )
        }
	}

	getMultiSelectedCount = (type, array) => {
		return array.length && array.filter(obj => obj.value === 'All').length ? 'All' :  array.length ? array.length +' Selected' : 'All'
	}
	
	handleClickOutside(event) {
        if (this.annotationXTypeRef && !this.annotationXTypeRef.current.contains(event.target)) {
            this.setState({ isannotationXaxisOpen: false })
        } else {
            this.setState({ isannotationXaxisOpen: true })
        }

        if (this.annotationYTypeRef && !this.annotationYTypeRef.current.contains(event.target)) {
          this.setState({ isannotationYaxisOpen: false })
        } else {
            this.setState({ isannotationYaxisOpen: true })
        }
    }

    render() {
        return(
            this.state.series && this.state.options ?
                <div className="" onClick={(event) => this.handleClickOutside(event)}>
                    <div className="d-flex pt-2 pl-3">
                        <div className="w-fit-content">
                            <div className={`multiSelection-fullWidth removeMargin z999 removeDropdownSearchBar position-relative bg-dark-50`} ref={this.annotationXTypeRef}>
                                <ReactMultiSelectCheckboxes						
                                    placeholderButtonLabel="All"
                                    getDropdownButtonLabel={() => this.getMultiSelectedCount('nodeTypes', this.state.annotationXaxis)}
                                    menuIsOpen ={this.state.isannotationXaxisOpen}
                                    value={this.state.annotationXaxis.map(item => ({
                                        value: item.value,
                                        // label: <div className="d-flex"><span className="badge badge-secondary">{item.prefix}</span> <span className="badge badge-secondary">{item.label}</span></div>
                                    }))}
                                    onChange={arr => {
                                    this.handleMultiSelectChange('annotationXaxisType', arr ? arr : []) }}
                                    options={mertcisAnnotationTypes.map(itm => ({
                                        value: itm.value,
                                        label: <span><span className={`badge-square text-white font-weight-bold f11 bg-dark-theme-${itm.prefix === 'C' ? 'green' : itm.prefix === 'E' ? 'red' : itm.prefix === 'S' ? 'orange' : 'blue'}`}>{itm.prefix}</span>{itm.label}</span>,
                                    }))}
                                />
                            </div>
                        </div>
                        <div className="ml-3 w-fit-content">
                            <div className={`multiSelection-fullWidth removeMargin z999 removeDropdownSearchBar position-relative bg-dark-50`} ref={this.annotationYTypeRef}>
                                <ReactMultiSelectCheckboxes						
                                    placeholderButtonLabel="All"
                                    getDropdownButtonLabel={() => this.getMultiSelectedCount('nodeTypes1', this.state.annotationYaxis)}
                                    menuIsOpen ={this.state.isannotationYaxisOpen}
                                    value={this.state.annotationYaxis.map(item => ({
                                        value: item.value,
                                        // label: <div className="d-flex"><span className="badge badge-secondary">{item.prefix}</span> <span className="badge badge-secondary">{item.label}</span></div>
                                    }))}
                                    onChange={arr => {
                                    this.handleMultiSelectChange('annotationYaxisType', arr ? arr : []) }}
                                    options={mertcisYAnnotationTypes.map(itm => ({
                                        value: itm.value,
                                        label: <span><span className={`badge-square text-white font-weight-bold f11 bg-dark-theme-${itm.value === 'Avg' ? 'purple' : itm.value === 'P95' ? 'orange' : itm.value === 'P99' ? 'red' : 'blue'}`}>{itm.value}</span>{itm.label}</span>,
                                    }))}
                                />
                            </div>
                        </div>
                    </div>
                    {Object.entries(this.state.options).length ?
                        <div className={`mt-n3 ${this.props.className}`}>
                            <Chart options={this.state.options} series={this.state.series} type="line" height={this.props.height} />
                            <div className="mt-n3">
                                <Chart options={this.state.brushOptions} series={this.state.brushSeries} type="area" height={100} />
                            </div>
                        </div>
                    : null}
                </div>
            : null
        )
    }
}
 
/**
 * Type of the props used in the component
 */
ApexBarLineBrush.propTypes = {}

const mapStateToProps = state => {}

export default connect(mapStateToProps, {})(withRouter(ApexBarLineBrush))