import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Spinner, Input } from 'reactstrap'
import { setEditRuleDetails } from '../../../actions/governance/governanceAction'
import moment from 'moment'
import { getDayFromSelectedDuration, momentDateFormat, datestartEndTime, currentUTCtime, onlyNumeric } from '../../../utils/utility'
import Select from 'react-select'


const sizeOption = [
	{label: 'GB', value: 'GB'},
	{label: 'TB', value: 'TB'}
]

class ServiceLogs extends Component {
	constructor(props) {
		super(props)
		this.durationRef = React.createRef()
		this.state = {
			hasError: this.props.hasError,
			condition: this.props.condition,
			destinationModel: [],
			encryptionOptions: [],
		}
	}

	componentDidMount = () => {
		if(this.props.hasError !== this.state.hasError) {
			this.setState({ hasError: this.props.hasError })
		}

		if(this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.log_list && Object.entries(this.props.editRuleDetails.condition.log_list).length) {
			Object.entries(this.props.editRuleDetails.condition.log_list).forEach(([key, value], index) => {
				this.setState({
					['allowedLogs_'+key]: value.allowed,
					['destination_'+key]: value.destination,
					['retentionPeriod_'+key]: value.retentionPeriod,
					['encryption_'+key]: value.encryption,
					['logLimit_'+key]: value.logLimit,
					['logLimitType_'+key]: value.logLimitType,
					['crossAccount_'+key]: value.crossAccount,
				})
			})

			this.setState({ logArray: this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.log_list })
		}

		if(this.props.condition) {
			let destinationModel = []
			if(this.props.condition && this.props.condition[0] && this.props.condition[0].log_destination && this.props.condition[0].log_destination.length) {
				destinationModel = this.props.condition[0].log_destination
			}
			let encryptionOptions = []
			if(this.props.condition && this.props.condition[0] && this.props.condition[0].encryptionOptions && this.props.condition[0].encryptionOptions.length) {
				encryptionOptions = this.props.condition[0].encryptionOptions
			}
			this.setState({ condition: this.props.condition, encryptionOptions, destinationModel })
			if(!this.props.editRuleDetails.condition || ((this.props.editRuleDetails.condition && !this.props.editRuleDetails.condition.log_list) || (this.props.editRuleDetails.condition && !Object.entries(this.props.editRuleDetails.condition.log_list).length))) {
				let hasData = 0
				let logArray = {}
				if(this.props.condition && this.props.condition[0] && this.props.condition[0].logs && this.props.condition[0].logs.length) {
					this.props.condition[0].logs.forEach((log, index) => {
						let dataRow = {}
						if(this.state['allowedLogs_'+log]) {
							hasData += 1
						}
						dataRow['allowed'] = this.state['allowedLogs_'+log] ? true : false
						dataRow['destination'] = log.destination ? log.destination : destinationModel.length && destinationModel.length === 1 ? destinationModel[0] : ''
						dataRow['retentionPeriod'] = log.retentionPeriod ? log.retentionPeriod : encryptionOptions.length && encryptionOptions.length === 1 ? encryptionOptions[0] : ''
						dataRow['encryption'] = log.encryption ? log.encryption : ''
						dataRow['logLimit'] = log.logLimit ? log.logLimit : ''
						dataRow['logLimitType'] = log.logLimitType ? log.logLimitType : 'GB'
						dataRow['crossAccount'] = log.crossAccount ? log.crossAccount : ''
						logArray[log] = dataRow

						this.setState({
							['allowedLogs_'+log]: false,
							['destination_'+log]: destinationModel.length ? destinationModel.length === 1 ? destinationModel[0] : destinationModel : [],
							['retentionPeriod_'+log]: '',
							['encryption_'+log]: encryptionOptions.length ? encryptionOptions.length === 1 ? encryptionOptions[0] : encryptionOptions : [],
							['logLimit_'+log]: '',
							['logLimitType_'+log]: 'GB',
							['crossAccount_'+log]: false,
						})
					})
				}
				this.setState({ logArray })
				
				let condition = this.props.editRuleDetails.condition ? this.props.editRuleDetails.condition : {}
				condition['log_list'] = logArray
				this.props.setEditRuleDetails('condition', condition)

				if(hasData === 0) {
					this.setState({ noRecords: true })
				} else {
					this.setState({ noRecords: false })
				}
			}
		}
	}

	componentDidUpdate = (prevProps) => {
		if(prevProps.condition !== this.props.condition) {
			let destinationModel = []
			if(this.props.condition && this.props.condition[0] && this.props.condition[0].log_destination && this.props.condition[0].log_destination.length) {
				destinationModel = this.props.condition[0].log_destination
			}
			let encryptionOptions = []
			if(this.props.condition && this.props.condition[0] && this.props.condition[0].encryptionOptions && this.props.condition[0].encryptionOptions.length) {
				encryptionOptions = this.props.condition[0].encryptionOptions
			}
			this.setState({ condition: this.props.condition, encryptionOptions, destinationModel })
			if(!this.props.editRuleDetails.condition || ((this.props.editRuleDetails.condition && !this.props.editRuleDetails.condition.log_list) || (this.props.editRuleDetails.condition && !Object.entries(this.props.editRuleDetails.condition.log_list).length))) {
				let hasData = 0
				let logArray = {}
				if(this.props.condition && this.props.condition[0] && this.props.condition[0].logs && this.props.condition[0].logs.length) {
					this.props.condition[0].logs.forEach((log, index) => {
						let dataRow = {}
						if(this.state['allowedLogs_'+log]) {
							hasData += 1
						}
						dataRow['allowed'] = this.state['allowedLogs_'+log] ? true : false
						dataRow['destination'] = log.destination ? log.destination : destinationModel.length && destinationModel.length === 1 ? destinationModel[0] : ''
						dataRow['retentionPeriod'] = log.retentionPeriod ? log.retentionPeriod : encryptionOptions.length && encryptionOptions.length === 1 ? encryptionOptions[0] : ''
						dataRow['encryption'] = log.encryption ? log.encryption : ''
						dataRow['logLimit'] = log.logLimit ? log.logLimit : ''
						dataRow['logLimitType'] = log.logLimitType ? log.logLimitType : 'GB'
						dataRow['crossAccount'] = log.crossAccount ? log.crossAccount : ''
						logArray[log] = dataRow

						this.setState({
							['allowedLogs_'+log]: false,
							['destination_'+log]: destinationModel.length ? destinationModel.length === 1 ? destinationModel[0] : destinationModel : [],
							['retentionPeriod_'+log]: '',
							['encryption_'+log]: encryptionOptions.length ? encryptionOptions.length === 1 ? encryptionOptions[0] : encryptionOptions : [],
							['logLimit_'+log]: '',
							['logLimitType_'+log]: 'GB',
							['crossAccount_'+log]: false,
						})
					})
				}
				this.setState({ logArray })
				
				let condition = this.props.editRuleDetails.condition ? this.props.editRuleDetails.condition : {}
				condition['log_list'] = logArray
				this.props.setEditRuleDetails('condition', condition)

				if(hasData === 0) {
					this.setState({ noRecords: true })
				} else {
					this.setState({ noRecords: false })
				}
			}
		}
		if(this.props.hasError !== this.state.hasError) {
			this.setState({ hasError: this.props.hasError })
		}
	}
	
	handleClickOutside(event) {
		// if(this.durationRef && !this.durationRef.current.contains(event.target)) {
		// 	if (this.dayPickerRef && !this.dayPickerRef.current.contains(event.target)) {
		// 		this.setState({ showDatepicker: false })
		// 		this.handleResetClick()
		// 	}
		// }
	}

	handleDurationCondition = (log) => {
		let endDatePeriod = getDayFromSelectedDuration(this.state.selectedDuration)
		let date = currentUTCtime()
		let startDate = datestartEndTime(moment(date).subtract(endDatePeriod, 'days'), 'start')
		let endDate = datestartEndTime(moment(date).subtract(1, 'days'), 'end')
		this.setState({ 
			['start_time_'+log]: startDate, 
			['end_time_'+log]: endDate,
			['durationType_'+log]: momentDateFormat(startDate, 'DD MMM') + ' - ' + momentDateFormat(endDate, 'DD MMM')
		})
		let condition = this.props.editRuleDetails.condition ? this.props.editRuleDetails.condition : {}

		condition['start_time'] = startDate
		condition['end_time'] = endDate
		condition['duration'] = this.state['selectedDuration_'+log]
		
		this.props.setEditRuleDetails('condition', condition)
	}

	setValueArray = (label, secValue, log, index) => {
		let logList = this.props.editRuleDetails && this.props.editRuleDetails.condition.log_list ? this.props.editRuleDetails.condition.log_list : {}
		let array = {}
		Object.entries(logList).forEach(([key, value], i) => {
			if(key === log) {
				if(label === 'destination') {
					value.destination = secValue
				} else if(label === 'retentionPeriod') {
					value.retentionPeriod = secValue
				} else if(label === 'encryption') {
					value.encryption = secValue
				} else if(label === 'logLimit') {
					value.logLimit = secValue
				} else if(label === 'logLimitType') {
					value.logLimitType = secValue
				} else if(label === 'crossAccount') {
					value.crossAccount = secValue !== '' ? true : false
				} else if(label === 'allowed') {
					value.allowed = this.state['allowedLogs_'+log] ? true : false
					if(!this.state['allowedLogs_'+log]) {
						value.destination = ''
						value.retentionPeriod = ''
						value.encryption = ''
						value.logLimit = ''
						value.crossAccount = false
						this.setState({ ['logLimit_'+log]: '', ['retentionPeriod_'+log]: '', ['crossAccount_'+log]: false })
					}
				}
			}
			array[key] = (value)
		})
		
		let condition = this.props.editRuleDetails.condition
		condition['log_list'] = array
		this.props.setEditRuleDetails('condition', condition)
	}

	render() {
		return (
			<div className=""> 
				{this.state.hasError && this.state.noRecords ? 
					<p className='text-danger m-0 p-0'>Log is Required.</p>
				:
				null}
				{this.state.logArray && Object.entries(this.state.logArray).length ?
					<div className="table table-responsive mb-0 serviceLogs overflow-visible zapInputDark">
						<table className="table table-borderless">
							<thead>
								<tr className="text-info font-weight-bold">
									<td style={{width: '200px'}}></td>
									<td>Destination</td>
									<td>Retention Period</td>
									<td>Encryption</td>
									<td>Log Limit</td>
									<td className="text-nowrap">Log in Cross Account</td>
								</tr>
							</thead>
							<tbody className="align-middle">
								{Object.entries(this.state.logArray).map(([key, value], index) => {
									return (
										<tr key={index}>
											<td className="bg-dark3 text-left">
												<div className='form-check form-check-inline px-2 text-white'>
													<input
														className='form-check-input'
														type='checkbox'
														checked={this.state['allowedLogs_'+key] ? true : false}
														onChange={e => this.setState({ ['allowedLogs_'+key]: this.state['allowedLogs_'+key] ? false : true },
															() => {
																this.setValueArray('allowed', this.state['allowedLogs_'+key], key, index)
															}
														)}
													/>
													<label className='font-weight-bold form-check-label mt-2p' htmlFor='forCheckbox'>
													{key}
													</label>
												</div>
											</td>										
											<td>
												<Select
													placeholder={'Select'}
													menuIsOpen={false}
													isSearchable={false}
													//onFocus={this.openProivderMenu}
													className={`f13 p-0 minWidthSelect ${!this.state['allowedLogs_'+key] ? 'disabled disabledDarkBg': ''}`}
													isDisabled={this.state['destination_'+key] === 'disableField' ? true : false}
													value={({
														value: this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.log_list && this.props.editRuleDetails.condition.log_list[key].destination ? this.props.editRuleDetails.condition.log_list[key].destination !== 'disableField' ?this.props.editRuleDetails.condition.log_list[key].destination : 'Select' : 'Select',
														label: this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.log_list && this.props.editRuleDetails.condition.log_list[key].destination ? this.props.editRuleDetails.condition.log_list[key].destination !== 'disableField' ? this.props.editRuleDetails.condition.log_list[key].destination : <span className="placeholder">Select</span> : <span className="placeholder">Select</span>
													})}
													options={this.state.destinationModel.length && this.state.destinationModel.map(item => ({
														value: item,
														label: item,
													}))}
													onChange={event => this.setState({ ['destination_'+key]: event.value },
														() => {
															this.setValueArray('destination', event.value, key, index)
														}
													)}
												/>
												{this.state.hasError && this.state['allowedLogs_'+key] && (!this.state['destination_'+key] || this.state['destination_'+key] === '') ? (
													<p className='text-danger m-0 p-0'>Destination is Required.</p>
												) : null}
											</td>
											<td>
												<Input
													type='text'
													value={this.state['retentionPeriod_'+key]}
													placeholder="retention period"
													className={`inputTextbox mr-2 ${!this.state['allowedLogs_'+key] ? 'disabled disabledDarkBg': ''} ${this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.log_list && this.props.editRuleDetails.condition.log_list[key].logLimit === 'disableField' && 'disabled bg-1'}`}
													onChange={e => this.setState({ ['retentionPeriod_'+key]: e.target.value },
														() => this.setValueArray('retentionPeriod', this.state['retentionPeriod_'+key], key, index)
													)}
												/>
											</td>
											<td>
												<Select
													placeholder={'Select'}
													//menuIsOpen={true}
													isSearchable={false}
													//onFocus={this.openProivderMenu}
													className={`f13 p-0 minWidthSelect ${!this.state['allowedLogs_'+key] ? 'disabled disabledDarkBg': ''}`}
													value={({
														value: this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.log_list && this.props.editRuleDetails.condition.log_list[key].encryption ? this.props.editRuleDetails.condition.log_list[key].encryption !== 'disableField' ?this.props.editRuleDetails.condition.log_list[key].encryption : 'Select' : 'Select',
														label: this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.log_list && this.props.editRuleDetails.condition.log_list[key].encryption ? this.props.editRuleDetails.condition.log_list[key].encryption !== 'disableField' ?this.props.editRuleDetails.condition.log_list[key].encryption : <span className="placeholder">Select</span> : <span className="placeholder">Select</span>
													})}
													options={this.state.encryptionOptions && this.state.encryptionOptions.map(item => ({
														value: item,
														label: item,
													}))}
													isDisabled={this.state['encryption_'+key] === 'disableField' ? true : false}
													onChange={event => this.setState({ ['encryption_'+key]: event.value },
														() => {
															this.setValueArray('encryption', event.value, key, index)
														}
													)}
												/>
											</td>
											<td>
												<div className="d-flex">
													<Input
														type='text'
														value={this.state['logLimit_'+key]}
														placeholder="log limit"
														className={`inputTextbox mr-2 ${!this.state['allowedLogs_'+key] ? 'disabled disabledDarkBg': ''} ${this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.log_list && this.props.editRuleDetails.condition.log_list[key].logLimit === 'disableField' && 'disabled bg-1'}`}
														onChange={e => this.setState({ ['logLimit_'+key]: onlyNumeric(e.target.value) },
															() => this.setValueArray('logLimit', this.state['logLimit_'+key], key, index)
														)}
													/>
													<Select
														isSearchable={false}
														className={`f13 wpx-100 p-0 ${!this.state['allowedLogs_'+key] ? 'disabled disabledDarkBg': ''}`}
														value={({
															value: this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.log_list && this.props.editRuleDetails.condition.log_list[key].logLimitType ? this.props.editRuleDetails.condition.log_list[key].logLimitType !== 'disableField' ?this.props.editRuleDetails.condition.log_list[key].logLimitType : 'GB' : 'GB',
															label: this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.log_list && this.props.editRuleDetails.condition.log_list[key].logLimitType ? this.props.editRuleDetails.condition.log_list[key].logLimitType !== 'disableField' ?this.props.editRuleDetails.condition.log_list[key].logLimitType : 'GB' : 'GB'
														})}
														options={sizeOption.length && sizeOption.map(item => ({
															value: item.value,
															label: item.label,
														}))}
														isDisabled={this.state['logLimitType_'+key] === 'disableField' ? true : false}
														onChange={event => this.setState({ ['logLimitType_'+key]: event.value },
															() => {
																this.setValueArray('logLimitType', event.value, key, index)
															}
														)}
													/>
												</div>
											</td>
											<td className='text-center"'>
												<div className='w-100 mb-1 form-check form-check-inline p-2'>
													<input
														className={`form-check-input ${!this.state['allowedLogs_'+key] ? 'disabled disabledDarkBg': ''}`}
														type='checkbox'
														disabled={this.state['crossAccount_'+key] === 'disableField' ? true : false}
														checked={this.state['crossAccount_'+key] ? true : false}
														onChange={e => this.setState({ ['crossAccount_'+key]: this.state['crossAccount_'+key] ? false : true },
															() => this.setValueArray('crossAccount', this.state['crossAccount_'+key], key, index)
														)}
													/>
												</div>
											</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>
				: !this.state.logArray ?
					<div className='d-flex justify-content-center m-4'>
						<Spinner className='text-center' color='white' size='lg' />
					</div>
				: 
					<div className='d-flex justify-content-center m-4'>
						<p>There are no log data on {this.props.editRuleDetails.resource_type} service. Please try adjusting your services in description tab.</p>
					</div>
				}
			</div>
		)
	}
}
/**
 * Type of the props used in the component
 */
ServiceLogs.propTypes = {
	logType: PropTypes.object,
	setEditRuleDetails: PropTypes.func,
	editRuleDetails: PropTypes.object,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('state', state)
	return {
		editRuleDetails: state.governance.editRuleDetails,
	}
}
export default connect(mapStateToProps, { setEditRuleDetails })(ServiceLogs)
