/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file DLP EngineEvents.js
 * @author Prakash // on 22/09/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Spinner } from 'reactstrap'
import { 
    listMsc,
    deleteMsc
} from '../../../../actions/dlp/DlpJobsAction'
import { listAllAccounts } from '../../../../actions/commonAction'

import { momentConvertionUtcToLocalTime, getAccountNameFromId } from '../../../../utils/utility'

import { store as CommonNotification } from 'react-notifications-component';
import Search from '../../../common/SearchComponent'
import ResizeableTable from '../../../designComponents/Table/ResizeableTable'

class EngineEvents extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
        }
    }
    
    componentDidMount = () => {	
        this.getAccounts()
        if(!this.props.dlpConfigEngineEventsList || !this.props.dlpConfigEngineEventsList.length) {
            this.listMsc()
        } else {
            this.setState({ showLoading: false })
        }
    }

    getAccounts = () => {
        if(!this.props.accounts || !this.props.accounts.length) {
            let params = {}
            this.props.listAllAccounts(params, (promise, response) => {})
        }        
    }

    listMsc = () => {
        this.setState({ showLoading: true }, 
            () => { 
                let params = {}
                params.job_type = 'dlp_engine_event'
                this.props.listMsc(params, (promise, response) => {
                    this.setState({ showLoading: false })
                })
            }
        )
    }

    deleteConfirmation = (msc_id, name) => {
        if (window.confirm('Are you sure, you want to delete Engine event "'+name+'" ?')) {
            this.setState({ showDeleteLoading: true },
                () => this.deleteMsc(msc_id)
            )
        }
    }

    deleteMsc = (msc_id) => {
        let params = {}
        params.msc_id = [msc_id]

        this.props.deleteMsc(params, (promise, response) => {
            if(promise) {
                this.setState({ showDeleteLoading: false },
                    () => this.listMsc()
                )
                let messageType = 'danger'		
                let message = 'Engine event deleted'
                CommonNotification.addNotification({
                    message: message,
                    type: messageType,
                    insert: "top",
                    container: "top-center",
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                })
            }
        })
    }

    render() {
        return(
            !this.state.showLoading ?
                <React.Fragment>                    
                    <div className="row"> 
                        <div className="col-sm-12">
                            <h6 className="font-weight-normal">Engine Events</h6>
                            <p>Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim.</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <select className="custom-select w-auto mr-3">
                                <option selected>Filter</option>
                                <option value="1">InActive</option>
                                <option value="2">Active</option>
                            </select>
                            {/* <select className="custom-select w-auto">
                                <option selected>Provider</option>
                                <option value="aws">AWS</option>
                                <option value="gcp">GCP</option>
                                <option value="azure">AZURE</option>
                            </select> */}
                        </div>
                        <div className='col-sm-6 d-flex justify-content-end'>
                            {this.props.dlpConfigEngineEventsList && this.props.dlpConfigEngineEventsList.length ?
                                <div className="form-group mb-0">
                                    <Search
                                        data={this.props.dlpConfigEngineEventsList}
                                        applyTags={false}
                                        applyLiteDarkTags={true}
                                        topClassName={'bg-white'}
                                        searchClassName={'textboxBorder'}
                                        searchIconColor={'text-dark'}
                                        className={"bg-transparent textboxBorder"}
                                        filteredData={(filteredArray) => {
                                            this.setState({ filteredList: _.orderBy(filteredArray, ['date'], ['desc']) })
                                        }}
                                    />
                                </div>
                            : null}
                            <span className='btn btn-primary cursorPointer text-center ml-2 mb-2' onClick={() => this.props.onClickAction({}, 'create')}>Add Engine Events</span>
                        </div>
                    </div>
                        
                    {this.props.dlpConfigEngineEventsList ?
                        <ResizeableTable
                            columns={[
                                {
                                    Header: 'Date',
                                    accessor: d => d.modified_at ? d.modified_at : d.created_at,
                                    Cell: cellInfo => (<span>{cellInfo.row.original.modified_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.modified_at, 'DD MMM YYYY HH:mm') : (cellInfo.row.original.created_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, 'DD MMM YYYY HH:mm')  : '')}</span>)
                                },
                                {
                                    Header: 'Title',
                                    accessor: 'name',
                                    width: 250
                                },
                                {
                                    Header: 'Provider / Account',
                                    accessor: d => d.provider + ' : ' + getAccountNameFromId(d.account_id, this.props.accounts),
                                    Cell: cellInfo => (
                                        <React.Fragment>
                                        <span className=''>
                                            {cellInfo.row.original.provider.toUpperCase() + ' : ' + getAccountNameFromId(cellInfo.row.original.account_id, this.props.accounts)}
                                        </span>
                                        </React.Fragment>
                                    ),
                                    width: 250	
                                },
                                {
                                    Header: 'Serivce',
                                    accessor: 'service_name',
                                },
                                {
                                    Header: 'Details',
                                    accessor: 'configuration_text',
                                    width: 400
                                },
                                {
                                    Header: 'Action',
                                    Cell: cellInfo => (
                                        <div className="d-flex justify-content-between align-self-center">
                                            <p className="text-dark m-0 p-0 cursorPointer">
                                                <i className="fal fa-book-open" onClick={() => this.props.onClickAction(cellInfo.row.original, 'view')}></i>
                                            </p>
                                            <p className="text-dark m-0 p-0 cursorPointer">
                                                <i className="fal fa-user-edit" onClick={() => this.props.onClickAction(cellInfo.row.original, 'edit') }></i>
                                            </p>
                                            <p className="text-dark m-0 p-0 cursorPointer">
                                                {this.state.showDeleteLoading && cellInfo.row.original.msc_id === this.state.selectedId ? 
                                                    <Spinner color='dark' size='sm' />
                                                :
                                                    <i className="fal fa-trash" onClick={() => this.setState({ selectedId: cellInfo.row.original.msc_id }, () => this.deleteConfirmation(cellInfo.row.original.msc_id, cellInfo.row.original.name))}></i>
                                                }
                                            </p>
                                        </div>
                                    ),
                                    width:120
                                }
                            ]}
                            data={this.state.filteredList ? this.state.filteredList : this.props.dlpConfigEngineEventsList}
                            perPage={10}
                            dashboard={this.state.filteredList ? this.state.filteredList.length : this.props.dlpConfigEngineEventsList.length}
                            sortByColumn={'Date'}
                            tooltipFor={[2,4]}
                            onClickRow={tableRow => {}}
                            tableSize={'table-striped'}
                        />
                    :
                        <div className='d-flex justify-content-center m-4'>
                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                        </div>
                    }
                </React.Fragment>
            : this.state.showLoading ?
                <div className='d-flex justify-content-center m-4'>
                    <Spinner color='dark' size='lg' />
                </div>
            :
                <div className='d-flex justify-content-center m-4'>
                    <p>There are no data on this criteria. Please try adjusting your filter.</p>
                </div>
        )
    }
}
    

/**
 * Type of the props used in the component
 */
 EngineEvents.propTypes = {
	listMsc: PropTypes.func,
	deleteMsc: PropTypes.func
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
 const mapStateToProps = state => {
    return {
		accounts: state.filters.accounts,
		dlpConfigEngineEventsList: state.dlp.dlpConfigEngineEventsList,
	}
}
export default connect(mapStateToProps, {
    listAllAccounts,
	listMsc,
	deleteMsc
})(withRouter(EngineEvents))