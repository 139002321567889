/*************************************************
 * Tvastar
 * @exports
 * @file urlPath.js
 * @author Rajasekar // on 19/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
export const URL_PATH = {
	INDEX_PAGE: '/',
	LOGIN: '/signin',
	FORGOT_PASSWORD: '/forgot-password',
	REGISTER: '/signup',
	
	//governance policy start
	GOVERNANCE_DASHBOARD: '/governance/dashboard/old', //need theme conversion

	GOVERNANCE_DASHBOARD_NEW: '/governance/dashboard', //need theme conversion
	GOVERNANCE_RULES_NEW_TEMPLATE: '/governance/rules/new/template',

	GOVERNANCE_MANAGE_RULES: '/governance/rules',
	GOVERNANCE_CREATE_NEW_RULE: '/governance/createrule',
	GOVERNANCE_EDIT_NEW_RULE: '/governance/editrule',
	// GOVERNANCE_FILTER_RULES: '/governance/filterrule',


	GOVERNANCE_RULES: '/governance/rules/old', //dark theme completed
	GOVERNANCE_CREATE_RULE: '/governance/createrule/old', //dark theme completed
	GOVERNANCE_EDIT_RULE: '/governance/editrule/old', //dark theme completed
	GOVERNANCE_CONFIGURATION: '/governance/configuration', //dark theme completed
	GOVERNANCE_LIST_POLICIES: '/governance/policies', //dark theme completed
	GOVERNANCE_CREATE_POLICIES: '/governance/create', //dark theme completed
	GOVERNANCE_EDIT_POLICIES: '/governance/edit', //need theme conversion

	GOVERNANCE_TAGGING: '/governance/tagging', //need theme conversion
	GOVERNANCE_TAG_GROUPS: '/governance/tagging/tag-groups', //need theme conversion
	GOVERNANCE_EDIT_TAG_GROUPS: '/governance/tagging/tag-groups/edit', //need theme conversion
	GOVERNANCE_CREATE_TAG_GROUPS: '/governance/tagging/tag-groups/create', //need theme conversion
	GOVERNANCE_APPLIED_ASSETS: '/governance/tagging/applied-assets', //need theme conversion

	//governance policy end
	
	//checks start
	CHECKS_DASHBOARD: '/checks/dashboard', //dark theme completed
	CHECKS_SEARCH: '/checks/search', //dark theme completed
	CHECKS_DETAILS: '/checks/details', //need theme conversion
	CHECKS_SUPPRESSOR: '/checks/suppression', //dark theme completed
	CHECKS_CONFIGURATION: '/checks/configuration', //dark theme completed
	COMPLIANCE_DASHBOARD: '/compliance', //dark theme completed
	//checks end

	//resource start
	RESOURCES_DASHBOARD_OLD: '/resources/dashboard_old',
	RESOURCES_SEARCH: '/resources/search',
	RESOURCE_COMPARISON: '/resources/compare',
	CONSOLIDATED_COMPARISON: '/resources/compare/consolidated',
	ASSETS_COMPARISON: '/resources/compare/assets',
	RESOURCES_DETAILS: '/asset',	
	RESOURCES_QUERY_EVENTS: '/resources/queries',
	// RESOURCES_INVENTORY: '/resources/inventory',
	RESOURCES_DASHBOARD: '/resources/dashboard',
	RESOURCES_LANDING_PAGE: '/resources/inventory',
	//resource end

	//insights start
	USER_INSIGHTS: '/insights/iam',	
	//insights end

	//filters start
	FILTER_SEARCH: '/filter-search',
	//filters end

	//profile start
	ACCOUNT_REGISTER: '/account-register',
	SETTINGS: '/settings',
	//profile end

	CUSTOMER_OPERATIONS: '/customer-operations',
	OPERATIONAL_SUMMARY: '/operational-summary',
	OPERATIONAL_SUMMARY_OLD: '/operational-summary-old',

	//DLP Start
	DLP_DASHBOARD: '/dlp/dashboard',
	DLP_THREATS: '/dlp/threats',
	DLP_UEBA: '/dlp/ueba',
	DLP_COMPLIANCE: '/dlp/compliance',
	DLP_TREND: '/dlp/trend',
	DLP_PROFILE: '/dlp/profile',
	DLP_REPORTS: '/dlp/reports',
	DLP_MALWARE: '/dlp/malware',
	DLP_CONFIGURATION: '/dlp/configuration',
	DLP_POLICY: '/dlp/Policies',
	DLP_JOBS: '/dlp/jobs',
	DLP_QUERY: '/dlp/query',

	//DLP End

	VISGRAPH: '/VisGraph',
	RESIZETABLE: '/resizetable',

	AUTOMATIONS_DASHBOARD: '/automations/dashboard',
	LIST_AUTOMATION: '/automations/manage',
	CREATE_AUTOMATION: '/automations/create',
	EDIT_AUTOMATION: '/automations/edit',
	AUTOMATION_DETAILS: '/automations/executions',

	CHECKS_REMEDIATION: '/checks/remediation',
	RESOURCE_AUTOMATION: '/resources/automation', //tab schedule inside

	SUNBURST_STATIC: '/SunburstStatic',

	AIOPS_DASHBOARD: '/cases/dashboard',
	AIOPS_CASE_DETAILS: '/cases/details/:id',
	AIOPS_ERRORS: '/aiops/errors',
	AIOPS_ALERTS: '/aiops/alerts',
	AIOPS_EVENTS: '/aiops/events',
	
	OBSERVABILITY_EVENTS: '/observability/events',
	OBSERVABILITY_ASSETS: '/observability/assets',
	OBSERVABILITY_ASSET_DETAILS: '/observability/assets/details',
	OBSERVABILITY_MICROSERVICES: '/observability/microservices',
	OBSERVABILITY_MICROSERVICES_RCA_LIST: '/observability/microservices/rca',
	OBSERVABILITY_MICROSERVICES_RCA_DETAILS: '/observability/microservices/rca/details',
	
	LIST_DIAGNOSTICS: '/diagnostics/manage',
	DIAGNOSTICS_DETAILS: '/diagnostics/details',
	DIAGNOSTICS_RCA: '/rca/details',

	TOPOLOGY: '/topology',
	ISSUE_DUMP: '/issue-dump',

	MANAGE_ALARMS: '/resources/alarms',

	COST_RECOMMENDATION: '/cost/recommendations',
	COST_POWER_TUNING: '/cost/powertuning',
	COST_ANOMALIES: '/cost/anomalies',
	COST_EXPLORER: '/cost/explorer',
	COST_COMPARISON: '/cost/comparison',
	COST_QUERIES: '/cost/queries',
	COST_BUDGET_FORECAST: '/cost/budgetforecast',

	ERROR_PAGE: '/error',
	LOADING_PAGE: '/loading',
}
