import React from 'react';
import _ from 'lodash'
import CommonDropdown from '../../../common/CommonDropdown'

const actionOptions = [
	{ value: 'created', label: 'Created'},
	{ value: 'modified', label: 'Modified'},
	{ value: 'deleted', label: 'Deleted'},
];

const criteriaOptions = [
	{ value: 'most_changes', label: 'Most Changes'},
	{ value: 'least_changes', label: 'Least Changes'},
	{ value: 'increase_by', label: 'Increase By'},
	{ value: 'decrease_by', label: 'Decrease By'},
	{ value: 'scale_out_by', label: 'Scale Out By'},
	{ value: 'scale_in_by', label: 'Scale In By'},
];

const conditionOptions = [
	{ value: '>', label: '>'},
	{ value: '<', label: '<'},
	{ value: '=', label: '='},
];

class LifecycleFilterSection extends React.Component {
    constructor(props) {
        super(props)
        console.log('test', props)
        this.state = {
            rowList: [true]
        };
        this.filterArray = []
    };

    selectedOptionFunction = (index, label, value) => {
        let dataRow = {}
        dataRow.label = label
        dataRow.index = index
        if(label === 'text') {
            dataRow.value = value.target.value
        } else {
            dataRow.value = value
        }
        this.filterArray.forEach((item , key) => {
            if(item.index === index && item.label === label) {
                this.filterArray.splice(key, 1);
            }
        })

        this.filterArray.push(dataRow)
    }

    add = () => {
        let rowList = [...this.state.rowList];
        rowList.push(true);
        this.setState({ rowList });
    }

    remove = (i) => {
        let rowList = [...this.state.rowList];
        rowList.splice(i, 1);
        this.setState({ rowList });
    }

    applyFilter = (event) => {
        //console.log(';;;;;;',this.filterArray)
        let array = this.filterArray
        this.filterArray = []
        let result = 
        _.chain(array)
        .groupBy('index').value()

        return this.props.data(result)
    }
    
    closeFilter = () => {
        return this.props.data(false);
    }

    render() {
        return (
            <div className="cardDropSection">
                <div className='container-fluid'>
                    <div className="row mt-3">
                        <div className="col-sm-10 align-self-center">
                            <p className="text-white mb-0">Lorem ipsum text industry since 1500s</p>
                        </div>
                        <div className="col-sm-2 text-right">
                            <span className='far fa-plus-circle cursor-pointer text-white f18' onClick={() => this.add()}></span>
                        </div>
                    </div>
                    {this.state.rowList.map((x, i) => {
                        return (
                            <div className='row my-3' key={i}>
                                <div className="col-sm-3 pr-0">
                                    <CommonDropdown 
                                        data = {this.selectedOptionFunction.bind(this, i, 'Action')}
                                        hideHeader = {false}
                                        headerName = {'Action'}
                                        dropOptions = {actionOptions}
                                        displayMenu = {this.state.dropRef1}
                                        isMultiSelect = {false}
                                        isDefaultDropdown = {false}
                                        styleTop={'top30'}
                                        searchOption={false}
                                        label={''}
                                    />
                                </div>
                                <div className="col-sm-3 pr-0">
                                    <CommonDropdown 
                                        data = {this.selectedOptionFunction.bind(this, i, 'Criteria')}
                                        hideHeader = {false}
                                        headerName = {'Criteria'}
                                        dropOptions = {criteriaOptions}
                                        displayMenu = {this.state.dropRef2}
                                        isMultiSelect = {false}
                                        isDefaultDropdown = {false}
                                        styleTop={'top30'}
                                        searchOption={false}
                                        label={''}
                                    />
                                </div>
                                <div className="col-sm-2 pr-0">
                                    <CommonDropdown 
                                        data = {this.selectedOptionFunction.bind(this, i, 'Condition')}
                                        hideHeader = {false}
                                        headerName = {'Condition'}
                                        dropOptions = {conditionOptions}
                                        displayMenu = {this.state.dropRef3}
                                        isMultiSelect = {false}
                                        isDefaultDropdown = {false}
                                        styleTop={'top30'}
                                        value={this}
                                        searchOption={false}
                                        label={''}
                                    />
                                </div>
                                <div className="col-sm-3 pr-0">
                                    <input className="customTextbox" type="text" id={'textValue_'+i} name={'text_'+i}
                                        onChange={(event) => this.selectedOptionFunction(i, 'text', event)}
                                        placeholder='eg.1000'/>
                                </div>
                                <div className="col-sm-1">
                                    <span className={`far fa-trash cursor-pointer text-white f18 ${i===0 ? 'displayNone' : ''}`} onClick={() => this.remove(i)}></span>
                                </div>
                            </div>
                            )
                        })}
                        <div className='row mb-3'>
                            <div className="col-sm-6"></div>
                            <div className="col-sm-6 justify-content-end d-flex">
                            <span className="dropCancelBtn d-block p-2 mr-2" onClick={ () => this.closeFilter()}>
                                Cancel
                            </span>
                            <span className="dropApplyBtn d-block p-2" onClick={ () => this.applyFilter()}>
                                Apply
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LifecycleFilterSection;
