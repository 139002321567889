/*************************************************
 * Tvastar
 * @exports
 * @file index.js
 * @author Rajasekar // on 26/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import { checkUseSession } from '../utils/utility'
import { URL_PATH } from './../config/urlPath'
import Login from '../components/auth/Login'
import Register from '../components/auth/Register'


import ComparisionOption from '../components/resources/comparison/ComparisionOption'

import ConsolidatedComparison from '../components/resources/comparison/ConsolidatedComparison'
import AssetsComparison from '../components/resources/comparison/AssetsComparison'
import AssetBoard from '../components/resources/assetBoard/AssetBoard'
//assets / resources

import GovernanceDashboardNew from '../components/governance/rulesNewDesign/Dashboard'
import GovernanceRulesNewTemplate from '../components/governance/rulesNewDesign/Template'
import GovernanceManageRules from '../components/governance/rulesNewDesign/ManagePolicies'

import GovernanceCreateNewRule from '../components/governance/rulesNewDesign/PolicyDetails'
import GovernanceEditNewRule from '../components/governance/rulesNewDesign/PolicyDetails'
// import GovernanceFilterRules from '../components/governance/rulesNewDesign/FilterPoliciesList'


import GovernanceRules from '../components/governance/Dashboard'
import GovernanceDashboard from '../components/governance/GovernanceDashboard'

import GovernanceCreateRule from '../components/governance/EditRule'
import GovernanceEditRule from '../components/governance/EditRule'
import TaggingDashboard from '../components/governance/tagging/Dashboard'
import GovernanceConfiguration from '../components/governance/configuration/Configuration'
import TagGroups from '../components/governance/tagging/TagGroups'
import EditTagGroups from '../components/governance/tagging/EditTagGroup'
import AppliedAssets from '../components/governance/tagging/AppliedAssets'
import PoliciesList from '../components/governance/IAM/PoliciesList'
import CreatePolicy from '../components/governance/IAM/CreatePolicy'

import ChecksDashboard from '../components/checks/dashboard/ChecksDashboard'
import ChecksSearch from '../components/checks/dashboard/ChecksSearch'
import ChecksDetails from '../components/checks/ChecksDetail'
import Suppressor from '../components/checks/Suppressor'
import Configuration from '../components/checks/Configuration'
import ComplianceDashboard from '../components/checks/dashboard/ComplianceDashboard'

import UserInsights from '../components/governance/IAM/UserInsights'
import FilterSearch from '../components/governance/IAM/filterSearch/FilterSearch'

import AccountRegister from '../components/accounts/AccountRegister'
import Settings from '../components/accounts/Settings'
import NotFoundPage from '../components/NotFoundPage'
import OperationalSummaryOld from '../components/operationalSummary/OperationalSummaryOld'
import OperationalSummary from '../components/operationalSummary/OperationalSummary'
import CustomerOperations from '../components/CustomerOperations/Operations'


//dlp
import DlpDashboard from '../components/dlp/Dashboard'
import DlpThreats from '../components/dlp/Threat/ThreatDashboard'
import DlpUeba from '../components/dlp/Ueba/UebaDashboard'
import DlpCompliance from '../components/dlp/Compliance/ComplianceDashboard' 
import DlpTrend from '../components/dlp/Trend/TrendDashboard' 
import DlpProfile from '../components/dlp/Profile/ProfileDashboard'
import DlpReport from '../components/dlp/Report/DlpReport'
import DlpMalware from '../components/dlp/Malware/MalwareDashboard'
import DlpConfiguration from '../components/dlp/Configuration/DlpConfiguration'
// import DlpConfigurationOld from '../components/dlp/Configuration_old/DlpConfiguration'
import DlpJobs from '../components/dlp/Jobs/DlpJobs'
import DlpPolicy from '../components/dlp/Policies/DlpPolicy'
import DlpQuery from '../components/dlp/QueryEditor'

import AiopsCaseDetails from '../components/aiops/CaseDetails'
import AiopsDashboard from '../components/aiops/Dashboard'
// import ObservabilityEvents from '../components/aiops/observability/LandingPage'
// import ObservabilityAssets from '../components/aiops/observabilityAssets/LandingPage'
// import ObservabilityAssetDetails from '../components/aiops/observabilityAssets/AssetDetailPage'
import ObservabilityEvents from '../components/Observability/EventView/LandingPage'
import ObservabilityAssets from '../components/Observability/AssetView/LandingPage'
import ObservabilityAssetDetails from '../components/Observability/AssetView/AssetDetailPage'
import ObservabilitMicroServices from '../components/Observability/MicroServices/MicroServicesLandingPage'
import ObservabilitMicroServicesRcaList from '../components/Observability/RCA/ListMicroServices'
import ObservabilitMicroServicesRcaDetails from '../components/Observability/RCA/RootCauseAnalysis'

import AutomationsDashboard from '../components/automations/Dashboard'
import ListAutomation from '../components/automations/ManageAutomations'
import CreateEditAutomaiton from '../components/automations/CreateEditAutomation'
import AutomaitonDetails from '../components/automations/AutomationDetails'

import ChecksRemediation from '../components/automations/ChecksRemediation/LandingPage'

import ResourcesAutomation from '../components/resources/dashboard/Automations/LandingPage'

import ResizeTable from '../components/aiops/ResizeTable'
import VisGraph from  '../components/aiops/VisGraph'
import SunburstStatic from  '../components/dlp/SunburstStatic'

import ListDiagnostics from '../components/diagnostics/ListDiagnostics'
import DiagnosticsDetails from '../components/diagnostics/Diagnostics'
import RcaDetails from '../components/diagnostics/RCA/RootCauseAnalysis'

import errorPage from '../components/errorPage'
import loadingPage from '../components/loadingPage'

//assets / resources
import ResourcesSearch from '../components/resources/searchModule/Search'
import ResourcesDetail from '../components/resources/common/AssetDetail'
import resourcesDashboard from '../components/resources/dashboard/Dashboard'
import resourcesLandingPage from '../components/resources/dashboard/LandingPage'
import resourcesQueryEvents from '../components/resources/Queries/QueryEvents'
// import resourcesInventory from '../components/resources/inventory/LandingPage'

import topology from '../components/Topology'
import issueDump from '../components/issueDump/LandingPage'
import ManageAlarms from '../components/resources/alarms/ManageAlarms'

import CostRecommendation from '../components/cost/Recommendations/Recommendations'
import CostPowerTuning from '../components/cost/PowerTuning/ManagePowerTuning'
import CostAnomalies from '../components/cost/Anomalies/LandingPage'
import CostExplorer from '../components/cost/Explorer/LandingPage'
import CostComparison from '../components/cost/Comparison/LandingPage'
import CostQueries from '../components/cost/Queries/QueryEvents'
import CostBudgetForecast from '../components/cost/Budget/LandingPage'



const PrivateRoute = ({ component: Component, ...rest }) => (		
	<React.Fragment>
	<Route {...rest} render={props => (checkUseSession() ? <Component {...props} /> : <Redirect to={URL_PATH.LOGIN} />)} />

	</React.Fragment>
)

PrivateRoute.propTypes = {
	component: PropTypes.object,
}

const NoMatchPage = () => {
	return (
	  <h3>404 - Not found</h3>
	);
};

const Router = () => {
	return (
		<BrowserRouter>
			<React.Fragment>
				<Switch>
					<Route path={URL_PATH.LOGIN} exact component={Login} />
					<Route path={URL_PATH.REGISTER} exact component={Register} />
					
					<PrivateRoute path={URL_PATH.INDEX_PAGE} exact component={AiopsDashboard} />
					
					<PrivateRoute path={URL_PATH.CHECKS_DASHBOARD} exact component={ChecksDashboard} />
					<PrivateRoute path={URL_PATH.CHECKS_SEARCH} exact component={ChecksSearch} />
					<PrivateRoute path={URL_PATH.CHECKS_DETAILS} exact component={ChecksDetails} />
					<PrivateRoute path={URL_PATH.CHECKS_CONFIGURATION} exact component={Configuration} />
					<PrivateRoute path={URL_PATH.CHECKS_SUPPRESSOR} exact component={Suppressor} />
					<PrivateRoute path={URL_PATH.COMPLIANCE_DASHBOARD} exact component={ComplianceDashboard} />

					<PrivateRoute path={URL_PATH.RESOURCES_SEARCH} exact component={ResourcesSearch} />
					<PrivateRoute path={URL_PATH.RESOURCES_DETAILS + '/:id'} exact component={ResourcesDetail} />
					<PrivateRoute path={URL_PATH.GOVERNANCE_DASHBOARD} exact component={GovernanceDashboard} />
					<PrivateRoute path={URL_PATH.GOVERNANCE_DASHBOARD_NEW} exact component={GovernanceDashboardNew} />
					<PrivateRoute path={URL_PATH.GOVERNANCE_RULES} exact component={GovernanceRules} />
					<PrivateRoute path={URL_PATH.GOVERNANCE_TAGGING} exact component={TaggingDashboard} />
					<PrivateRoute path={URL_PATH.GOVERNANCE_TAG_GROUPS} exact component={TagGroups} />
					<PrivateRoute path={URL_PATH.GOVERNANCE_EDIT_TAG_GROUPS + '/:id'} exact component={EditTagGroups} />
					<PrivateRoute path={URL_PATH.GOVERNANCE_CREATE_TAG_GROUPS} exact component={EditTagGroups} />
					<PrivateRoute path={URL_PATH.GOVERNANCE_APPLIED_ASSETS} exact component={AppliedAssets} />

					<PrivateRoute path={URL_PATH.GOVERNANCE_LIST_POLICIES} exact component={PoliciesList} />
					<PrivateRoute path={URL_PATH.GOVERNANCE_CREATE_POLICIES} exact component={CreatePolicy} />
					<PrivateRoute path={URL_PATH.GOVERNANCE_EDIT_POLICIES} exact component={CreatePolicy} />
					<PrivateRoute path={URL_PATH.USER_INSIGHTS} exact component={UserInsights} />
					<PrivateRoute path={URL_PATH.FILTER_SEARCH} exact component={FilterSearch} />
					<PrivateRoute path={URL_PATH.GOVERNANCE_CONFIGURATION} exact component={GovernanceConfiguration} />
					<PrivateRoute path={URL_PATH.GOVERNANCE_CREATE_RULE} exact component={GovernanceCreateRule} />
					<PrivateRoute path={URL_PATH.GOVERNANCE_EDIT_RULE} exact component={GovernanceEditRule} />
					
					<PrivateRoute path={URL_PATH.RESOURCE_COMPARISON} exact component={ComparisionOption} />
					<PrivateRoute path={URL_PATH.CONSOLIDATED_COMPARISON} exact component={ConsolidatedComparison} />
					<PrivateRoute path={URL_PATH.ASSETS_COMPARISON} exact component={AssetsComparison} />

					<PrivateRoute path={URL_PATH.RESOURCES_DASHBOARD_OLD} exact component={AssetBoard} />
					<PrivateRoute path={URL_PATH.RESOURCES_DASHBOARD} exact component={resourcesDashboard} />
					<PrivateRoute path={URL_PATH.RESOURCES_LANDING_PAGE} exact component={resourcesLandingPage} />
					<PrivateRoute path={URL_PATH.RESOURCES_QUERY_EVENTS} exact component={resourcesQueryEvents} />
					{/* <PrivateRoute path={URL_PATH.RESOURCES_INVENTORY} exact component={resourcesInventory} /> */}

					<PrivateRoute path={URL_PATH.ACCOUNT_REGISTER} exact component={AccountRegister} />
					<PrivateRoute path={URL_PATH.SETTINGS} exact component={Settings} />

					<PrivateRoute path={URL_PATH.OPERATIONAL_SUMMARY_OLD} exact component={OperationalSummaryOld} />
					<PrivateRoute path={URL_PATH.OPERATIONAL_SUMMARY} exact component={OperationalSummary} />
					<PrivateRoute path={URL_PATH.CUSTOMER_OPERATIONS} exact component={CustomerOperations} />

					<PrivateRoute path={URL_PATH.DLP_DASHBOARD} exact component={DlpDashboard} />
					<PrivateRoute path={URL_PATH.DLP_THREATS} exact component={DlpThreats} />
					<PrivateRoute path={URL_PATH.DLP_UEBA} exact component={DlpUeba} />
					<PrivateRoute path={URL_PATH.DLP_COMPLIANCE} exact component={DlpCompliance} />
					<PrivateRoute path={URL_PATH.DLP_TREND} exact component={DlpTrend} />
					<PrivateRoute path={URL_PATH.DLP_PROFILE} exact component={DlpProfile} />
					<PrivateRoute path={URL_PATH.DLP_MALWARE} exact component={DlpMalware} />
					<PrivateRoute path={URL_PATH.DLP_REPORTS} exact component={DlpReport} />
					<PrivateRoute path={URL_PATH.DLP_CONFIGURATION} exact component={DlpConfiguration} />
					<PrivateRoute path={URL_PATH.DLP_QUERY} exact component={DlpQuery} />
					<PrivateRoute path={URL_PATH.DLP_JOBS} exact component={DlpJobs} />					
					<PrivateRoute path={URL_PATH.DLP_POLICY} exact component={DlpPolicy} />

					<PrivateRoute path={URL_PATH.AIOPS_DASHBOARD} exact component={AiopsDashboard} />
					<PrivateRoute path={URL_PATH.AIOPS_CASE_DETAILS} exact component={AiopsCaseDetails} />
					<PrivateRoute path={URL_PATH.AUTOMATIONS_DASHBOARD} exact component={AutomationsDashboard} />
					<PrivateRoute path={URL_PATH.OBSERVABILITY_EVENTS} exact component={ObservabilityEvents} />
					<PrivateRoute path={URL_PATH.OBSERVABILITY_ASSETS} exact component={ObservabilityAssets} />
					<PrivateRoute path={URL_PATH.OBSERVABILITY_ASSET_DETAILS} exact component={ObservabilityAssetDetails} />
					<PrivateRoute path={URL_PATH.OBSERVABILITY_MICROSERVICES} exact component={ObservabilitMicroServices} />
					<PrivateRoute path={URL_PATH.OBSERVABILITY_MICROSERVICES_RCA_LIST} exact component={ObservabilitMicroServicesRcaList} />
					<PrivateRoute path={URL_PATH.OBSERVABILITY_MICROSERVICES_RCA_DETAILS} exact component={ObservabilitMicroServicesRcaDetails} />


					<PrivateRoute path={URL_PATH.RESIZETABLE} exact component={ResizeTable} />
					<PrivateRoute path={URL_PATH.VISGRAPH} exact component={VisGraph} />
					<PrivateRoute path={URL_PATH.SUNBURST_STATIC} exact component={SunburstStatic} />

					<PrivateRoute path={URL_PATH.LIST_AUTOMATION} exact component={ListAutomation} />
					<PrivateRoute path={URL_PATH.CREATE_AUTOMATION} exact component={CreateEditAutomaiton} />
					<PrivateRoute path={URL_PATH.EDIT_AUTOMATION} exact component={CreateEditAutomaiton} />
					<PrivateRoute path={URL_PATH.CHECKS_REMEDIATION} exact component={ChecksRemediation} />
					<PrivateRoute path={URL_PATH.AUTOMATION_DETAILS} exact component={AutomaitonDetails} />
					<PrivateRoute path={URL_PATH.RESOURCE_AUTOMATION} exact component={ResourcesAutomation} />

					<PrivateRoute path={URL_PATH.LIST_DIAGNOSTICS} exact component={ListDiagnostics} />
					<PrivateRoute path={URL_PATH.DIAGNOSTICS_DETAILS} exact component={DiagnosticsDetails} />
					<PrivateRoute path={URL_PATH.DIAGNOSTICS_RCA} exact component={RcaDetails} />

					<PrivateRoute path={URL_PATH.TOPOLOGY} exact component={topology} />
					<PrivateRoute path={URL_PATH.ISSUE_DUMP} exact component={issueDump} />


					<PrivateRoute path={URL_PATH.ERROR_PAGE} exact component={errorPage} />
					<PrivateRoute path={URL_PATH.LOADING_PAGE} exact component={loadingPage} />

					<PrivateRoute path={URL_PATH.GOVERNANCE_RULES_NEW_TEMPLATE} exact component={GovernanceRulesNewTemplate} />
					
					<PrivateRoute path={URL_PATH.GOVERNANCE_MANAGE_RULES} exact component={GovernanceManageRules} />
					<PrivateRoute path={URL_PATH.GOVERNANCE_CREATE_NEW_RULE} exact component={GovernanceCreateNewRule} />
					<PrivateRoute path={URL_PATH.GOVERNANCE_EDIT_NEW_RULE} exact component={GovernanceEditNewRule} />
					<PrivateRoute path={URL_PATH.MANAGE_ALARMS} exact component={ManageAlarms} />
					{/* <PrivateRoute path={URL_PATH.GOVERNANCE_FILTER_RULES} exact component={GovernanceFilterRules} /> */}

					<PrivateRoute path={URL_PATH.COST_RECOMMENDATION} exact component={CostRecommendation} />
					<PrivateRoute path={URL_PATH.COST_POWER_TUNING} exact component={CostPowerTuning} />
					<PrivateRoute path={URL_PATH.COST_ANOMALIES} exact component={CostAnomalies} />
					<PrivateRoute path={URL_PATH.COST_EXPLORER} exact component={CostExplorer} />
					<PrivateRoute path={URL_PATH.COST_COMPARISON} exact component={CostComparison} />
					<PrivateRoute path={URL_PATH.COST_QUERIES} exact component={CostQueries} />
					<PrivateRoute path={URL_PATH.COST_BUDGET_FORECAST} exact component={CostBudgetForecast} />

					<Route exact component={NotFoundPage} />
				</Switch>
			</React.Fragment>
		</BrowserRouter>
	)
}

export default Router
