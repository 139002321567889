let Components = {};

Components['Service-Containers'] = require('./categoryRules/ServiceContainers_new').default //batch and ecs  //theme completed
Components['Service-Images'] = require('./categoryRules/ServiceImages').default //ec2 //theme completed
Components['Service-Availability'] = require('./categoryRules/ServiceReplication').default //elastic and rds //theme completed
Components['Service-Encryption'] = require('./categoryRules/ServiceEncryption').default //s3 //theme completed
Components['Service-Logs'] = require('./categoryRules/ServiceLogs').default //all //theme completed
Components['Service-BlockStore'] = require('./categoryRules/ServiceBlockStore').default // theme completed
Components['Service-Engine'] = require('./categoryRules/ServiceVersion').default //rds // theme completed
Components['Service-Sizing'] = require('./categoryRules/ServiceSizing').default //ec2 // theme completed

Components['ServiceAccessPort'] = require('./categoryRules/ServiceAccessPort').default
Components['ServiceLimits'] = require('./categoryRules/ServiceLimits').default
Components['ServiceRetention'] = require('./categoryRules/ServiceRetention').default
Components['ServiceActionLimits'] = require('./categoryRules/ServiceActionLimits').default
Components['ServiceAccessIpAddress'] = require('./categoryRules/ServiceAccessIpAddress').default
Components['ServiceToUse'] = require('./categoryRules/ServiceToUse').default
Components['ServiceRegions'] = require('./categoryRules/ServiceRegions').default
Components['ServiceAccessibility'] = require('./categoryRules/ServiceAccessibility').default
Components['ServiceBusinessHours'] = require('./categoryRules/ServiceBusinessHours').default
Components['ServiceVersion'] = require('./categoryRules/ServiceVersion').default
Components['ServiceReplication'] = require('./categoryRules/ServiceReplication').default

export default Components