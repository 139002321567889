/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file PatternCheck.js
 * @author Prakash // on 10/02/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Input, Spinner } from 'reactstrap'
import { trimmedUserName } from '../../../utils/utility'
import { validateGrokPattern } from '../../../actions/AutomationAction'

class PatternCheck extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount = () => {
        this.setState({
            name: this.props.databaseDetails && this.props.databaseDetails.name ? this.props.databaseDetails.name : "",
            source_port: this.props.databaseDetails && this.props.databaseDetails.source_port ? this.props.databaseDetails.source_port : "",
            source_endpoint: this.props.databaseDetails && this.props.databaseDetails.source_endpoint ? this.props.databaseDetails.source_endpoint : "",
            cred_store: this.props.databaseDetails && this.props.databaseDetails.cred_store ? this.props.databaseDetails.cred_store : "",
            cred_key_id: this.props.databaseDetails && this.props.databaseDetails.cred_key_id ? this.props.databaseDetails.cred_key_id : "",
            cred_usr_key: this.props.databaseDetails && this.props.databaseDetails.cred_usr_key ? this.props.databaseDetails.cred_usr_key : "",
            cred_pwd_key: this.props.databaseDetails && this.props.databaseDetails.cred_pwd_key ? this.props.databaseDetails.cred_pwd_key : "",
            cred_endpoint_key: this.props.databaseDetails && this.props.databaseDetails.cred_endpoint_key ? this.props.databaseDetails.cred_endpoint_key : "",
            cred_database_key: this.props.databaseDetails && this.props.databaseDetails.cred_database_k ? this.props.databaseDetails.cred_database_k : "",
        })
    }

    validateGrokPattern = () => {
        let params = {}
        params.sample_text = this.state.sample_text
        params.pattern = this.state.pattern
        // "sample_text": "[2022-01-18T07:46:32.777Z] : [TXN] [SOURCE_LOWER_CUMULATIVE] [3CC1F605000306D0]  :: prev_cumulative: 17351910 :: new_cumulative: 146",
        // "pattern": "\\[%{TIMESTAMP_ISO8601:time}\\] : \\[TXN\\] \\[SOURCE_LOWER_CUMULATIVE\\] \\[%{ALPHANUMERIC:component_id}\\]  :: prev_cumulative: %{INT:prev} :: new_cumulative: %{INT:new}"

        this.props.validateGrokPattern(params, (promise, response) => {
            if(promise) {
                this.setState({ patternOutput: response, saveLoader: false })
            } else {
                this.setState({ patternOutput: {}, saveLoader: false })
            }
        })
    }

    render() {
        return (
            <div className="zapInputDark">
                <div className="d-flex justify-content-between p-3 bg-gray5 border-left">
                    <p className="mb-0 font-weight-bold text-white">Validate Grok Pattern</p>
                    <i className="far fa-times mr-3 text-white" onClick={() => this.props.showHelperSection()}></i>
                </div>
                <div className="p-3">
                    <p className="mb-0 small">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"s standard dummy text ever since the 1500s,</p>
                    <div className="mt-2">
                        <label className="mb-1">Sample Text</label>
                        <Input 
                            type="textarea" 
                            name="text" 
                            // readOnly={this.state.disableFields}
                            className="inputTextbox"
                            value={this.state.sample_text}
                            rows={5}
                            onChange={event => 
                                this.setState({ sample_text: event.target.value },
                                    () => {
                                        // this.formInputProps("details", "sample_text", this.state.sample_text)
                                    }
                                )
                            }
                        />
                    </div>
                    <div className="mt-3">
                        <label className="mb-1">Pattern</label>
                        <Input
                            type="textarea" 
                            name="text" 
                            // readOnly={this.state.disableFields}
                            className="inputTextbox"
                            value={this.state.pattern}
                            rows={5}
                            onChange={event => 
                                this.setState({ pattern: event.target.value },
                                    () => {
                                        // this.formInputProps("details", "pattern", this.state.sample_text)
                                    }
                                )
                            }
                        />
                    </div>
                    <div className={`continueButton mt-4 text-center`}>
                        <button className={`btn bg-light p-2 mr-4`} onClick={() => this.props.showHelperSection()}>Back</button>
                        <button className={`btn bg-info p-2 text-white`} onClick={() => this.setState({ saveLoader: true, patternOutput: {} }, () => this.validateGrokPattern())}>
                            {this.state.saveLoader ? <Spinner color='white' size='sm' className="mx-1" /> :null}
                            Validate
                        </button>
                    </div>
                    {this.state.patternOutput && Object.entries(this.state.patternOutput).length ?
                        !this.state.patternOutput.status ?
                            <div className="mt-3">
                                <label className="mb-2 text-white">Output</label>
                                <Input
                                    type="textarea" 
                                    name="text" 
                                    readOnly={trimmedUserName}
                                    className="inputTextbox"
                                    value={this.state.patternOutput.message ? this.state.patternOutput.message : "Pattern not matching"}
                                    rows={3}
                                />
                            </div>
                        : this.state.patternOutput && this.state.patternOutput.result ?
                            <div className="mt-3">
                                <label className="mb-2 text-white">Output</label>
                                {Object.entries(this.state.patternOutput.result).map(([key, value]) => {
                                    return(
                                        <div className="d-flex">
                                            <label className="font-weight-bold">{key}:</label>
                                            <label className="ml-2 text-white">{value}</label>
                                        </div>
                                    )
                                })}
                            </div>
                        : null
                    : null}
                </div>
            </div>
        )
    }
}
/**
 * Type of the props used in the component
 */
PatternCheck.propTypes = {}

//export default 

export default connect(null, {
    validateGrokPattern
})(withRouter(PatternCheck))
