import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { listAllServices } from '../../../../actions/commonAction'
import CommonDropdown from '../../../common/CommonDropdown'

const includeOptions = [
	{ value: 'include', label: 'Include'},
	{ value: 'exclude', label: 'Exclude'}
];

class SizeFilterSection extends React.Component {
    constructor(props) {
        super(props)
        console.log('sizeProps', props)
        this.state = {
            
            selectedProvider: this.props.selectedProvider,
            sizes: [],
            sizeResourceType: [],
            definedSize: [],

            sectionDetails: this.props.sectionDetails,

            selectedSize: [],
            selectedSizeType: 'include',
        };
    };

    componentDidMount = () => {
        this.listAllServices()
	}

    listAllServices = () => {
		let params = {}
		params.provider = this.state.selectedProvider
		this.props.listAllServices(params, (promise, sizes) => {
			if (promise) {
				let sizeArray = []
                let sizeResourceArray = []
				if(sizes) {
                    sizes.length &&
                    _.chain(sizes)
                    .groupBy('resource_type')
                    .map((value, key) => ({ label: key, sub: value }))
                    .value()
                    .forEach((size, i) => {
                        let resourceRow = {}
                        resourceRow.label = size.label
                        resourceRow.value = size.label
                        sizeResourceArray.push(resourceRow)
                        //sizeResourceArray.push(service)
                        size.sub.forEach((item, j) => {
                            let sizeRow = {}
                            sizeRow.label = item.service_name
                            sizeRow.value = item.service_name
                            sizeArray.push(sizeRow)
                            //sizeArray.push(item)
                        })
                    })
                }
                this.setState({ sizes, sizeResourceType: sizeResourceArray,  definedSize: sizeArray }
                )
			} else {
				this.setState({ sizes: [] })
			}
		})
    }

    selectedOptionFunction = (label, value) => {
        let elementExist = 0
        if(label === 'Size') {
            var totalArray = this.state.selectedSize; 
            totalArray.forEach((row, index) => {
                if(row === value) {
                    elementExist = 1
                    //totalArray.splice(index, 1);
                }
            })
            if(elementExist === 0) {
                this.setState(prevState => ({
                    selectedSize: [...prevState.selectedSize, value]
                }))
            }
        } else if(label === 'Size_Type') {
            this.setState({ selectedSizeType: value })
        }
    }

    applyFilter = () => {
        
        let obj = {}
        obj['size'] = this.state.selectedSize
        obj['type'] = this.state.selectedSizeType
        
        return this.props.data(obj)
    }

    closeFilter = () => {
        return this.props.data(false);
    }

    remove = (type, index) => {
        var totalArray = '' 
        if(type === 'Size') {
            totalArray = this.state.selectedSize; 
            totalArray.splice(index, 1);
            this.setState({ selectedSize : totalArray, selectedSizeType: 'include' });
        }
    }

    render() {
        return (
            <div className="card cardDropSection">
                <p className="text-white col-sm-12 f16 pb-0 mb-0">Lorem ipsum text industry since 1500s</p>

                <div className="card-body">
                    <div className="d-flex mb-2">
                    <div className="mr-3">
                            <CommonDropdown 
                                data = {this.selectedOptionFunction.bind(this, 'Size_Type')}
                                hideHeader = {false}
                                headerName = {'Include'}
                                dropOptions = {includeOptions}
                                displayMenu = {false}
                                isMultiSelect = {false}
                                isDefaultDropdown = {false}
                                styleTop={'top57'}
                                searchOption={false}
                                label={'&nbsp;'}
                            />
                        </div>
                        <div className="mr-3">
                            <CommonDropdown 
                                data = {this.selectedOptionFunction.bind(this, 'Size')}
                                hideHeader = {false}
                                headerName = {'Size'}
                                dropOptions = {this.state.sizeResourceType}
                                displayMenu = {false}
                                isMultiSelect = {false}
                                isDefaultDropdown = {false}
                                styleTop={'top57'}
                                searchOption={true}
                                label={'Size'}
                            />
                        </div>
                        <div className="mr-3 minWidth300 align-self-center">
                            {this.state.selectedSize.length ? 
                                this.state.selectedSize.map((item, index) => {
                                    return (
                                        index < 3 ?
                                            <span className='badge badge-primary custom-badge ml-2 text-white p-1'> {item}
                                                <i className='ml-1 fal fa-times cursorPointer'onClick={() => this.remove('role', index)}></i>
                                            </span> 
                                        :
                                        null
                                    )       
                                })
                            :
                                null
                            }
                        </div>
                        <div className="mr-3 align-self-center">
                            {this.state.selectedSize.length > 3 ?
                                <p className="text-white col-sm-12 f10 pb-0 mb-0">  {this.state.selectedSize.length+' Selected '}</p>
                            :
                                null
                            }
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="col-sm-12 m-0 p-0 float-right text-right">
                        <span className="dropCancelBtn p-2 mr-2"
                            onClick={ () => this.closeFilter()}
                        >
                            Cancel
                        </span>
                        <span className="dropApplyBtn p-2" 
                            onClick={ () => this.applyFilter()}
                        >
                            Apply
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

/**
 * Type of the props used in the component
 */
SizeFilterSection.propTypes = {
    listAllServices:PropTypes.func
}

export default connect(null, { listAllServices })(SizeFilterSection);

//export default connect(AccessedByFilterSection, null, {getIdentitiesList})

//export default AccessedByFilterSection;
