/*************************************************
 * Tvastar
 * @exports
 * @file AddEdit.js
 * @author Prakash // on 08/10/2021
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { capitalizeFirstLetter, restrictCharacter } from '../../../../utils/utility'
import { listAllProviders } from '../../../../actions/commonAction'
import { 
    listCustomerAccountInfo,
    insertCustomerAccountInfo,
    updateCustomerAccountInfo,
} from '../../../../actions/dlp/CustomerAccountAction'

import Select from 'react-select'
import { Spinner } from 'reactstrap'
import { store as CommonNotification } from 'react-notifications-component';

class AddEdit extends Component {
    hierarichalBucketBarChartRef = React.createRef()
    constructor(props) {
        super(props)
        this.props = props;
        this.state = {
            showLoading: false,
            accountArray: [0],
            relationshipArray: {
                'Partner': [],
                'Subsidary': [],
                'Business Unit': [],
                'Vendor': ['Observability', 'Security']
            }
        }
    }

    componentDidMount = () => {
        this.getProviders()
        if(this.props.pageType === 'edit' || this.props.pageType === 'view') {
            let selectedRecord = this.props.selectedRecord
            let customer_name = selectedRecord.customer_name
            let accountArray = []
            if(selectedRecord.accounts.length) {
                selectedRecord.accounts.forEach((item, index) => {
                    accountArray.push(index)
                    this.setState({ ['account_id_'+index]: item.account_id, ['canonical_id_'+index]: item.canonical_id })
                })
            } else {
                accountArray.push(0)
            }

            let selectedRelationship = ''
            if(selectedRecord.relationship) {
                selectedRelationship = selectedRecord.relationship
            }

            let selectedSubRelationShip = ''
            if(selectedRecord.relationship) {
                selectedSubRelationShip = selectedRecord.sub_relationship
            }

            this.setState({ customer_name, accountArray, selectedRelationship, selectedSubRelationShip })
        }
    }

    getProviders = () => {
        if(!this.props.providers || !this.props.providers.length) {
            this.props.listAllProviders((promise, response) => {
                if (promise) {
                    let selectedProvider = response.length && response.length === 1 ? response[0].provider_name : ''
                    this.setState({ selectedProvider })
                }
            })
        } else {
            let selectedProvider = this.props.providers.length && this.props.providers.length === 1 ? this.props.providers[0].provider_name : ''
            this.setState({ selectedProvider })
        }

    }

    listCustomerAccountInfo = () => {
        let params = {}
        this.props.listCustomerAccountInfo(params, (promise, response) => {})
    }

    actionPorcess = () => {
        let hasError = false

        let errorMessage = ''
        if(!this.state.customer_name || this.state.customer_name === '') {
            hasError = true
            errorMessage = 'Please enter customer name'
        } else {
            //check watemark name already exist
            if(this.props.pageType === 'create') {
                let checkNameExist = this.props.dlpCustomerAccountInfoList.filter(arr => arr.customer_name === this.state.customer_name)
                if(checkNameExist && checkNameExist.length) {
                    hasError = true
                    errorMessage = 'Customer name already exist'
                }
            } else if(this.props.pageType === 'edit') {
                let checkNameExist = this.props.dlpCustomerAccountInfoList.filter(arr => arr.customer_name === this.state.customer_name && arr.watermark_id !== this.props.selectedRecord.watermark_id)
                console.log(checkNameExist)
                if(checkNameExist && checkNameExist.length) {
                    hasError = true
                    errorMessage = 'Customer name already exist'
                }
            }
        }

        let accountExist = 0
        this.state.accountArray.forEach(item => {
            if((!this.state['account_id_'+item] || this.state['account_id_'+item] === '') && this.state['canonical_id_'+item] !== '') {
                hasError = true
            } else if(this.state['account_id_'+item] !== '') {
                accountExist++
            }
        })

        if(!accountExist) {
            hasError = true
        }

        // if(!this.state.selectedRelationship) {
        //     hasError = true
        // }
        
        this.setState({ hasError, errorMessage, showActionLoading: !hasError, accountExist })

        if(!hasError) {
            let params = {}
            params.customer_name = this.state.customer_name
            params.provider = this.state.selectedProvider.toLowerCase()
            let account_text = ' Customer '+this.state.customer_name+' whose provider '+this.state.selectedProvider
            let accounts = []
            this.state.accountArray.forEach(item => {
                account_text += ' with accounts '
                if(this.state['account_id_'+item] && this.state['account_id_'+item] !== '') {
                    let dataRow = {}
                    dataRow.account_id = this.state['account_id_'+item]
                    dataRow.canonical_id = this.state['canonical_id_'+item] ? this.state['canonical_id_'+item] : ''
                    accounts.push(dataRow)
                    account_text += ' account id '+this.state['account_id_'+item]+' canonical id '+this.state['canonical_id_'+item]
                }
            })
            params.accounts = accounts

            params.relationship = this.state.selectedRelationship ? this.state.selectedRelationship : ''
            if(params.relationship !== '') {
                account_text += ' having relationship '+this.state.selectedRelationship 
            }

            params.sub_relationship = this.state.selectedSubRelationShip ? this.state.selectedSubRelationShip :''
            if(params.sub_relationship !== '') {
                account_text += ' sub relationship '+this.state.selectedSubRelationShip 
            }

            params.account_text = account_text

            console.log('acc', params)

            if(this.props.pageType === 'create') {
                this.props.insertCustomerAccountInfo(params, (promise, response) => {
                    if(promise) {                        
                        let messageType = 'danger'		
                        let message = 'Check the data'
                        if(response.status && response.status.message && response.status.message === 'inserted') {
                            messageType = 'success'
                            message = 'Saved Successfully'
                        }
                        CommonNotification.addNotification({
                            message: message,
                            type: messageType,
                            insert: "top",
                            container: "top-center",
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
                        
                        if(response.status && response.status.message && response.status.message === 'inserted') {
                            this.setState({ showActionCompleted: true },
                                () => {
                                    setTimeout(() => { this.listCustomerAccountInfo() }, 3000)
                                    setTimeout(() => { this.props.showListPage() }, 4000)
                                }
                            )
                        } else {
                            this.setState({ showActionLoading: false })
                        }
                    } else {
                        this.setState({ showActionLoading: false })
                    }
                })
            } else if(this.props.pageType === 'edit') {
                params.doc_id = this.props.selectedRecord.doc_id
                this.props.updateCustomerAccountInfo(params, (promise, response) => {
                    if(promise) {						
                        let messageType = 'danger'		
                        let message = 'Check the data'
                        if(response.status && response.status.message && response.status.message === 'updated') {
                            messageType = 'success'
                            message = 'Updated Successfully'
                        }
                        CommonNotification.addNotification({
                            message: message,
                            type: messageType,
                            insert: "top",
                            container: "top-center",
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
                        
                        if(response.status && response.status.message && response.status.message === 'updated') {
                            this.setState({ showActionCompleted: true },
                                () => {
                                    setTimeout(() => { this.listCustomerAccountInfo() }, 3000)
                                    setTimeout(() => { this.props.showListPage() }, 4000)
                                }
                            )
                        } else {
                            this.setState({ showActionLoading: false })
                        }
                    } else {
                        this.setState({ showActionLoading: false })
                    }
                })
            }
        }
    }

    addSection = (array) => {
        let rowList = this.state[array];
        if(this.state[array]) {
            let value = this.state[array][this.state[array].length - 1]
            value = value+1
            rowList.push(value);
        }
        
        this.setState({[array]: rowList })
    }

    removeAccountSection = (item, i) => {
        this.setState({ ['account_id_'+item]: '', ['canonical_id_'+item]: '' },
            () => {
                let rowList = this.state.accountArray;
                rowList.splice(i, 1);
                this.setState({ accountArray: rowList })
            }
        );
    }

    render() {		
        return (    
            !this.state.showLoading ?
                <div className={`mt-3 rounded ${(this.state.showActionLoading) ? 'disabled' : ''}`}>
                    <div className="row"> 
                        <div className="col-sm-12">
                            <div className="d-flex justify-content-between">
                                <h6 className="font-weight-normal">{(this.props.pageType !== 'create' ? capitalizeFirstLetter(this.props.pageType) : 'Add') +' Account Information'}</h6>
                                <i className="fas fa-arrow-left cursorPointer" onClick={() => this.props.showListPage()}> back</i>
                                {/* <button className='btn btn-sm btn-light mr-1' onClick={() => this.props.showListPage()}>Cancel</button> */}
                            </div>
                            <p>Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim.</p>
                        </div>
                    </div>
                    <div className={`dlpDescriptionFormWhite ${this.props.pageType === 'view' ? 'disabled disableInputColor' : ''}`}>
                        <div className={`form-group`}>
                            <div className="d-flex flex-wrap">
                                <p className="mb-0 mr-2 align-self-end text-purple mt-3">Configure the account details for the</p>  
                                <Select
                                    placeholder={'Select'}
                                    isSearchable={false}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    className={`selectOption w-15`}
                                    value={({
                                        value: this.state.selectedRelationship,
                                        label: this.state.selectedRelationship ? this.state.selectedRelationship : 'Select'
                                    })}
                                    options={Object.keys(this.state.relationshipArray).map(item => ({
                                        value: item,
                                        label: item,	
                                    }))}
                                    onChange={event =>  this.setState({ selectedRelationship: event.value } )}
                                />
                                <p className="mb-0 mx-2 align-self-end text-purple mt-3">organization.</p>
                            </div>
                            <div className="d-flex flex-wrap">
                                <p className="mb-0 mr-2 align-self-end text-purple mt-3">The {this.state.selectedRelationship ? this.state.selectedRelationship :''} organization name is</p>
                                <input 
                                    type="text" 
                                    className={`inputField mt-3 w-30 ${this.state.hasError && (!this.state.customer_name || this.state.customer_name === '') ? 'dottedDangerTextboxLine' : ''}`}
                                    placeholder='organization name'
                                    value={this.state.customer_name ? this.state.customer_name : ''}
                                    onChange={e => this.setState({ customer_name: e.target.value })}
                                />                                
                                {this.state.selectedRelationship && this.state.selectedRelationship !== '' && this.state.relationshipArray[this.state.selectedRelationship].length ?
                                    <React.Fragment>
                                        <p className="mb-0 mx-2 align-self-end text-purple mt-3">and they provide / perform</p>
                                        <Select
                                            placeholder={'Select'}
                                            isSearchable={false}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            className={`selectOption w-15 mt-3`}
                                            value={({
                                                value: this.state.selectedSubRelationShip,
                                                label: this.state.selectedSubRelationShip ? this.state.selectedSubRelationShip : 'Select'
                                            })}
                                            options={this.state.relationshipArray[this.state.selectedRelationship].map(item => ({
                                                value: item,
                                                label: item,	
                                            }))}
                                            onChange={event =>  this.setState({ selectedSubRelationShip: event.value } )}
                                        />
                                        <p className="mb-0 mx-2 align-self-end text-purple mt-3">function.</p>
                                    </React.Fragment>
                                : null}
                            </div>
                            <div className="d-flex flex-wrap">
                                <p className="mb-0 mr-2 align-self-end text-purple mt-3">The {this.state.selectedRelationship ? this.state.selectedRelationship :''} organization uses</p>                                
                                <Select
                                    placeholder={'Select'}
                                    isSearchable={false}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    className={`selectOption mt-3 w-15 ${this.state.hasError && !this.state.selectedProvider ? 'dottedDangerSelectLine' : ''}`}
                                    value={({
                                        value: this.state.selectedProvider,
                                        label: this.state.selectedProvider
                                    })}
                                    options={this.props.providers.length && this.props.providers.map(item => ({
                                        value: item.provider_name,
                                        label: item.provider_name,	
                                    }))}
                                    onChange={event =>  this.setState({ selectedProvider: event.value } )}
                                />
                                <p className="mb-0 mx-2 align-self-end text-purple mt-3">as cloud provider, and they access our data from following accounts :</p>
                            </div>
                        </div>
                        <div className="">
                            <p className="mb-0 mr-2 align-self-end text-purple mt-4">Please input Account ID / Canonical ID</p>
                            {this.state.accountArray.map((item, i) => {
                                return(
                                    <div className="d-flex flex-wrap">
                                        <input 
                                            type="text" 
                                            placeholder="Account Id"
                                            className={`inputField mt-2 w-20 
                                                ${this.state.hasError && (!this.state.accountExist || (!this.state['account_id_'+item] || (this.state['account_id_'+item] === '' && this.state['canonical_id_'+item] && this.state['canonical_id_'+item] !== ''))) ? 'dottedDangerTextboxLine' : '' }
                                            `}
                                            value={this.state['account_id_'+item] ? this.state['account_id_'+item] : ''}
                                            onChange={e => this.setState({ ['account_id_'+item]: restrictCharacter(e.target.value, '/') })}
                                        />
                                        <p className="mb-0 mx-3 align-self-end text-purple mt-2">/</p>
                                        <input 
                                            type="text" 
                                            placeholder="Canonical Id"
                                            className={`inputField mt-2 w-30`}
                                            value={this.state['canonical_id_'+item] ? this.state['canonical_id_'+item] : ''}
                                            onChange={e => this.setState({ ['canonical_id_'+item]: e.target.value })}
                                        />
                                        

                                        {this.props.pageType.toLowerCase() !== 'view' && this.state.accountArray.length > 1 ?
                                            <span className={`far fa-trash cursor-pointer f18 align-self-end ml-4`} onClick={() => this.removeAccountSection(item, i)}></span>
                                        : null}
                                        {this.props.pageType.toLowerCase() !== 'view' && (this.state.accountArray.length - 1) ===  i ?
                                            <span className={`far fa-plus cursor-pointer f18 align-self-end ml-4`} onClick={() => this.addSection('accountArray')}></span>
                                        : null}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {this.state.hasError && this.state.errorMessage !== '' ?
                        <p className='m-0 text-danger align-self-end mt-5 mb-2'>{this.state.errorMessage}</p>
                    : null}
                    <div className={`border-top pt-2 ${this.state.hasError ? 'mt-3' : 'mt-5'}`}>
                        <button className='btn btn-light mr-1' onClick={() => this.props.showListPage()}>Cancel</button>
                        {this.props.pageType.toLowerCase() !== 'view' ?
                            !this.state.showActionCompleted ?
                                this.state.showActionLoading ?
                                    <button className='btn btn-primary' onClick={() => this.actionPorcess()}>
                                        <Spinner size='sm' className='mx-2' color='dark' /> {this.props.pageType.toLowerCase() === 'create' ? 'Creating ... ' : 'Updating ...' }
                                    </button>
                                : 
                                    <button className='btn btn-primary' onClick={() => this.actionPorcess()}>{this.props.pageType.toLowerCase() === 'create' ? 'Create' : 'Update' }</button>
                            :
                                <button className='btn btn-primary disabled'>
                                    {this.props.pageType.toLowerCase() === 'create' ? 'Created' : 'Updated' }
                                </button>
                        : null}
                    </div>
                </div>
            :
                <div className='text-center mt-5' >
                    <Spinner color='white' size='lg' />
                </div>
        
        )
    }
}

/**
 * Type of the props used in the component
 */
AddEdit.propTypes = {
    insertCustomerAccountInfo: PropTypes.func,
    updateCustomerAccountInfo: PropTypes.func
}

const mapStateToProps = state => {
    return {
		providers: state.filters.providers,
		dlpCustomerAccountInfoList: state.dlp.dlpCustomerAccountInfoList
    }
}

export default connect(mapStateToProps, {
    listAllProviders,
    insertCustomerAccountInfo,
    updateCustomerAccountInfo,
    listCustomerAccountInfo
})(withRouter(AddEdit))