/*************************************************
 * Tvastar
 * @exports
 * @file QueryEvents.js  //copy of query list from the observability Events QueryDetail page
 * @author Prakash // on 11/05/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { AppWrapper } from '../../common/AppWrapper'
// import PropTypes from 'prop-types'
import { Spinner, UncontrolledTooltip } from 'reactstrap'
import { getDayFromSelectedDuration, momentDateGivenFormat, currentLocaltime, subHours, subDays, momentConvertionLocalToUtcTime, alphaNumeric } from '../../../utils/utility'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import Search from '../../common/SearchComponent'	
import { listAllProviders, listAllAccounts, listAllRegions, getAllTagsKeys, getAllTagsValues } from '../../../actions/commonAction'
import { getCostQueries, getCostQueryResults } from '../../../actions/cost/CostQueryAction'

import { startQueryExection, setFavouriteQuery, unsetFavouriteQuery } from '../../../actions/QueryAction'
import { dynamicCallApi } from '../../../actions/DynamicLoadAction'
import { setResourcePropsDetails } from '../../../actions/assets/assetsAction'

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import _ from 'lodash'

import { store as CommonNotification } from 'react-notifications-component';

import { RangePicker } from "react-minimal-datetime-range";
import "react-minimal-datetime-range/lib/react-minimal-datetime-range.min.css";
import QueryResults from './QueryResults'

const durationOptions = [
	{ value: '+1h', option: 'Last 1 hour' },
    { value: '+6h', option: 'Last 6 hours' },
    { value: '+12h', option: 'Last 12 hours' },
	{ value: '+3h', option: 'Last 3 hours' }, 
	{ value: '+24h', option: 'Last 24 hours' },
	{ value: '+3d', option: 'Last 3 days' },
	{ value: '+7d', option: 'Last 1 week' },
	{ value: '+14d', option: 'Last 2 weeks' },
	{ value: '+30d', option: 'Last 1 month' },
	{ value: 'custom', option: 'Custom Date' },
]

const disableFutureDt = current => {
	return current.isBefore(currentLocaltime()) && current.isAfter(subDays(currentLocaltime(), 30))
}

const queryDateTime = current => {
	return current.isBefore(currentLocaltime()) && current.isAfter(subDays(currentLocaltime(), 90))
}

class QueryEvents extends Component {
	constructor(props) {
		super(props)
		this.accountRef = React.createRef()
		this.regionRef = React.createRef()
		this.tagsRef = React.createRef()
		this.daterRangeListRef = React.createRef()

		this.state = {
			listLoading: true,

			selectedProvider: "",
			
			selectedAccount: [],
			isAccountOpen: false,

			selectedRegion: [],
			isRegionOpen: false,

			selectedDuration: "+7d",		

			startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
			overAllTotalPages: 1,
			perPage: 10,

			selectedCategory: "All",
		}
	}

	handleStartTimeChange  = this.handleStartTimeChange.bind(this)
	handleEndTimeChange    = this.handleEndTimeChange.bind(this)

	componentDidMount = () => {
		this.getWindowSize()

		window.addEventListener('resize', () => {
			this.getWindowSize()
		})
		if(this.props.providers && this.props.providers.length) {
			this.setState({ providers: this.props.providers, selectedProvider: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : this.props.providers[0].provider_name },
				() => {
					this.filterData('provider')
				}
			)
		} else {
			this.props.listAllProviders((promise, providers) => {
				if (promise) {
					this.setState({ providers, selectedProvider: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : providers[0].provider_name },
						() => {
							this.filterData('provider')
						}
					)
				} else {
					this.setState(
						{ providers: [],  }, 
						() => {
							this.filterData()
						}
					)
				}
			})
		}    
        
        this.getAllTagsKeys()
		this.getCostQueries()
		this.durationFunction()
	}

	getWindowSize = () =>  {
		const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 180
		let searchBarHeight = 20

				
		let outerHeight = (window.outerHeight - browserBorder - headerHeight - searchBarHeight ) + "px"

		this.setState({ outerHeight, minMaxHeight: outerHeight })
	}


    getAllTagsKeys = () => {
        let params = {}
        this.props.getAllTagsKeys(params, (promise, response) => {})
    }

    getAllTagsValues = () => {
        let params = {}
        params.tags = [{ "key": this.state.tag_key }]
        this.props.getAllTagsValues(params, (promise, response) => {
            if(promise) {
                this.setState({ tagValues: response })
            }
        })
    }

	getQueryAllTagsValues = (query_id) => {
        let params = {}
        params.tags = [{ "key": this.state[query_id+"_selectedTagkey"] }]
        this.props.getAllTagsValues(params, (promise, response) => {
            if(promise) {
                this.setState({ [query_id+"_selectedTagValues"]: response })
            }
        })
	}

	addNewTag = () => {
		let tagsSelected = this.state.tagsSelected ? this.state.tagsSelected : []

		if(this.state.tag_key && this.state.tag_key !== "") {
			let filteredTags = tagsSelected.filter(e => e.key !== this.state.tag_key)
			this.state.tag_value.forEach(row => {
				let dataRow = {
					key: this.state.tag_key,
					value: row,
				}
				filteredTags.push(dataRow)
			})

			this.setState({ tagsSelected: filteredTags, tag_key: "",  tag_value: "", tagValues: [], changesInFilter: true })
		}
	}

	removeTagSection = (tag) => {
		let filteredResult = this.state.tagsSelected.filter(e => e !== tag)
		this.setState({ tagsSelected: filteredResult })
	}

	addQueryTag = (query_id) => {
		let tagsSelected = this.state[query_id+"_selectedTags"] ? this.state[query_id+"_selectedTags"] : []

		if(this.state[query_id+"_selectedTagkey"] && this.state[query_id+"_selectedTagkey"] !== "") {
			let filteredTags = tagsSelected.filter(e => e.key !== this.state[query_id+"_selectedTagkey"])
			
			this.state[query_id+"_selectedQueryTagValues"].forEach(row => {
				let dataRow = {
					key: this.state[query_id+"_selectedTagkey"],
					value: row,
				}
				filteredTags.push(dataRow)
			})

			this.setState({ [query_id+"_selectedTags"]: filteredTags, [query_id+"_selectedTagkey"]: "",  [query_id+"_selectedQueryTagValues"]: [], [query_id+"_selectedTagValues"]: [] })
		}
	}

	removeQueryTagSection = (query_id, tag) => {
		let filteredResult = this.state[query_id+"_selectedTags"].filter(e => e !== tag)
		this.setState({ [query_id+"_selectedTags"]: filteredResult })
	}
	
	getCostQueries = () => {
		let params = {
			"aggregate_by": ["category", "sub_category"]
		}
		if(this.props.costQueryList && this.props.costQueryList.length) {
			this.setState({ queryListJson: this.props.costQueryList, listLoading: false },
				() => this.formQueryList()
			)
		} else {
			this.props.getCostQueries(params, (promise, response) => {
				if(promise) {
					this.setState({ queryListJson: response, listLoading: false },
						() => this.formQueryList()
					)
				} else {
					this.setState({ queryListJson: [], listLoading: false })
				}
			})
		}
	}

	updateQueryList = () => {
		let params = {}
		this.props.getCostQueries(params, (promise, response) => {
			if(promise) {
				this.setState({ totalQueryList: response },
					() => {
						let data = this.state.totalQueryList
						if(this.state.selectedSubCategory && this.state.selectedSubCategory !== "") {
							data = data.filter(e => e.category.includes(this.state.selectedCategory) && e.sub_category === this.state.selectedSubCategory)
						} else if(this.state.selectedCategory && this.state.selectedCategory !== "" && this.state.selectedCategory !== "All") {
							data = data.filter(e => e.category.includes(this.state.selectedCategory))
						}

						this.setState({ filteredArray: data })
					}
				)
			}
		})
	}

	formQueryList = () => {
		let catArray = []
		let category = []
		this.state.queryListJson.forEach((item, index) => {
			item.category.forEach(cat => {
				let catRow = {}
				catRow.category = cat
				if(!category.includes(cat)) {
					category.push(cat)
				}
				if(item.sub_category) {
					catRow.sub_category = item.sub_category
				}
				catArray.push(catRow)
			})
		})

		let categories = []
		let totalCatCount = 0
		_.chain(catArray)
			.groupBy('category')
			.map((value, key) => ({ label: key, sub: value })).value()
			.forEach((category, i) => {
				let totalCount = 0
				let dataRow = {}
				dataRow.category = category.label
				let sub_category = []
				_.chain(category.sub)
				.groupBy('sub_category')
				.map((value, key) => ({ slabel: key, sSub: value })).value()
				.forEach((subCat, i) => {
					if(category.sub.filter(e => e.sub_category).length) {
						let subCatRow = {}
						subCatRow.sub_category = subCat.slabel
						subCatRow.count = subCat.sSub.length
						sub_category.push(subCatRow)
					}
					totalCatCount += subCat.sSub.length
					totalCount += subCat.sSub.length
				})
				dataRow.count = totalCount
				if(sub_category && sub_category.length) {
					dataRow.sub_category = sub_category
				}
				categories.push(dataRow)
			})

		
		categories = _.orderBy(categories, ['count'], ['desc'])

		let dataRow = {}
		dataRow.category = "All"
		dataRow.count = totalCatCount
		dataRow.sub_category = ""	
		categories.unshift(dataRow)

		this.setState({ totalQueryList: this.state.queryListJson, queryList: this.state.queryListJson, categories },
			() => {
				this.filterQueryList()
			}
		)
	}

	filterQueryList = () => {
		let data = this.state.totalQueryList
		if(this.state.selectedSubCategory && this.state.selectedSubCategory !== "") {
			data = data.filter(e => e.category.includes(this.state.selectedCategory) && e.sub_category === this.state.selectedSubCategory)
		} else if(this.state.selectedCategory && this.state.selectedCategory !== "" && this.state.selectedCategory !== "All") {
			data = data.filter(e => e.category.includes(this.state.selectedCategory))
		}

		let totalPages = 1
		if(data.length > this.state.perPage) {
			totalPages = Math.ceil(data.length / this.state.perPage)
		}

		this.setState({ filteredArray: data, totalPages, startRecord: 0, currentPage: 1 })
	}

	filterData = () => {
		let params = {}
		if(this.state.selectedProvider && this.state.selectedProvider !== "") {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if(this.props.accounts && this.props.accounts.length) {
			let allAccounts = this.props.accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
			if(this.props.accounts.length) {
				let accRow = {}
				accRow.account_id = "All"
				accRow.account_name = "All"
				accRow.date = null
				accRow.disabled = false
				accRow.provider = "All"
				allAccounts.unshift(accRow);
			}
			this.setState({ accounts: allAccounts })
		} else {
			this.props.listAllAccounts(params, (promise, allAccounts) => {
				if (promise) {						
					let filterdAccounts = allAccounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
					if(filterdAccounts.length) {
						let accRow = {}
						accRow.account_id = "All"
						accRow.account_name = "All"
						accRow.date = null
						accRow.disabled = false
						accRow.provider = "All"
						filterdAccounts.unshift(accRow);
					}
					
					this.setState({ accounts: filterdAccounts })
				}
			})
		}

		if(this.props.regions && this.props.regions.length) {
			let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
			this.setState({ regions: providerRegions })
		} else {
			this.props.listAllRegions(params, (promise, regions) => {
				if (promise) {
					let providerRegions = regions && regions[0] && regions[0].provider_regions
					if(providerRegions) {
						let provider_regions_row = {}
						provider_regions_row.availability_zones = []
						provider_regions_row.city = "All"
						provider_regions_row.country = "All"
						provider_regions_row.geography = "All"
						provider_regions_row.name = "All"
						provider_regions_row.region = "All"
						provider_regions_row.region_id = "All"
						provider_regions_row.state = "All"
						providerRegions.unshift(provider_regions_row);
					}
					this.setState({ regions: providerRegions })
				} else {
					this.setState({ regions: [] })
				}
			})
		}
	}
	
	handleMultiSelectChange = (field, arrayValue, state) => {
		let value = arrayValue.map(item => item.value ? item.value : item)
		let selectedValue = []
		if(field === 'selectedAccount' || field === "selectedQueryAccount") {
			let prevState = this.state[field] ? this.state[field] : []
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.accounts.forEach(acc => {
						selectedValue.push(acc.account_id)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.accounts.length -1)) {
					selectedValue.push('All')
				}
			}	
			this.setState({ [field]: selectedValue })
		} else if(field === 'selectedRegion' || field === "selectedQueryRegion") {
			let prevState = this.state[field] ? this.state[field] : []
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.regions.length -1)) {
					selectedValue.push('All')
				}
			}
			this.setState({ [field]: selectedValue })
		} else {
			//for array			
			if(state) {
				let prevState = this.state[field] ? this.state[field] : []
                if(prevState.length === 1 && prevState[0] === "All") {
                    prevState = []
                }
				if(value.includes('All')) {
					if(!prevState.includes('All')) {
						if(state.filter(e => e.value).length) {
							selectedValue = state.map(e => e.value)
						} else {
							selectedValue = state
						}
					} else {
						const index = value.indexOf('All');
						if (index > -1) {
							value.splice(index, 1);
						}
						selectedValue = value
					}
				} else if(!prevState.includes('All')) {
					selectedValue = value
				}
	
				if(selectedValue.length && !selectedValue.includes('All')) {
					if(!selectedValue.includes('All') && selectedValue.length === (state.length -1)) {
						selectedValue.push('All')
					}
				}
			} else {
				selectedValue = value
			}
			this.setState({ [field]: selectedValue })
		}
	}

	handleMultiSelectChangeNew = (label, field, arrayValue, state) => {
		let value = arrayValue.map(item => item.value)
		let selectedValue = []
		if(label === "accounts") {
			let prevState = this.state[field] ? this.state[field] : []
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.accounts.forEach(acc => {
						selectedValue.push(acc.account_id)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.accounts.length -1)) {
					selectedValue.push('All')
				}
			}	
			this.setState({ [field]: selectedValue })
		} else if(label === 'regions') {
			let prevState = this.state[field] ? this.state[field] : []
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.regions.length -1)) {
					selectedValue.push('All')
				}
			}
			this.setState({ [field]: selectedValue })
		} else {
			if(state) {
				let prevState = this.state[field] ? this.state[field] : []
                if(prevState.length === 1 && prevState[0] === "All") {
                    prevState = []
                }
				if(value.includes('All')) {
					if(!prevState.includes('All')) {
						if(state.filter(e => e.value).length) {
							selectedValue = state.map(e => e.value)
						} else {
							selectedValue = state
						}
					} else {
						const index = value.indexOf('All');
						if (index > -1) {
							value.splice(index, 1);
						}
						selectedValue = value
					}
				} else if(!prevState.includes('All')) {
					selectedValue = value
				}
	
				if(selectedValue.length && !selectedValue.includes('All')) {
					if(!selectedValue.includes('All') && selectedValue.length === (state.length -1)) {
						selectedValue.push('All')
					}
				}
			}

			this.setState({ [field]: selectedValue })
		}
	}
	
	getMultiSelectedCount = (type, array) => {
		return array.length && array.includes('All') ? 'All' : (array.length ? array.length +' Selected' : 'All')
	}

	getMultiSelectedCountNew = (type, array) => {
		return array.length && array.includes('All') ? 'All' : (array.length ? array.length +' Selected' : <span className="placeholder w-100">Select</span>)
		//<span className={`${this.state.hasError ? "text-danger" : "placeholder"} w-100`}>{this.state.hasError ? "required" : "Select"}</span>
	}

	handleClickOutside = (event) => {

		if (this.accountRef && !this.accountRef.current.contains(event.target)) {
			this.setState({ isAccountOpen: false })
		} else {
			this.setState({ isAccountOpen: true })
		}

		if (this.regionRef && !this.regionRef.current.contains(event.target)) {
			this.setState({ isRegionOpen: false })
		} else {
			this.setState({ isRegionOpen: true })
		}
		
		if (this.daterRangeListRef && !this.daterRangeListRef.current.contains(event.target)) {
			this.setState({ showDateRangePicker: false })
		}

		if(this.state.showTagFilter) {
			if (this.tagsRef && !this.tagsRef.current.contains(event.target)) {
				this.setState({ showTagFilter: false })
			}
		}
	}  

    handleSelectNew = (date) => {
		// let startTime = momentDateGivenFormat(date[0], "YYYY-MM-DD 00:00:00")
		// let endTime = momentDateGivenFormat(date[1], "YYYY-MM-DD 23:59:59")
		// let startTime = date[0]
		// let endTime = date[1]
		let startTime = momentDateGivenFormat(date[0], "YYYY-MM-DD HH:mm:00")
		let endTime = momentDateGivenFormat(date[1], "YYYY-MM-DD HH:mm:00")
		this.setState({ datePickerStartDate: startTime, datePickerEndDate: endTime, startTime, endTime, changesInFilter: true, showDateRangePicker: false })
	}

	durationFunction = (type) => {
		let duration = this.state.selectedDuration
		let period = duration.substring(duration.length, duration.length-1)
		let durationBtw = getDayFromSelectedDuration(duration)
		let startTime = ""
		if(period === "d") {
			startTime = momentDateGivenFormat(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
		} else {
			startTime = momentDateGivenFormat(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
		}
		
		let endTime = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')

		let selectionRange = [{
			startDate: new Date(startTime),
			endDate: new Date(endTime),
			key: 'selection',
		}]

		this.setState({ startTime, endTime, selectionRange, changesInFilter: true, queryStartTime: startTime, queryEndTime: endTime })
	}

	navigatePage = (action, currentPage) => {
		if(currentPage > 0) {
			let startRecord = this.state.startRecord
			if(action === 'next' && currentPage !== this.state.totalPages) {
				startRecord = startRecord + this.state.perPage
				currentPage = currentPage + 1
			} else if(action === 'previous' && currentPage !== 0) {
				currentPage = currentPage - 1
				startRecord = startRecord - this.state.perPage
			} else if(action === 'start' && currentPage !== 0) {
				currentPage = 1
				startRecord = 0
			} else if(action === 'end' && currentPage !== 0) {
				currentPage = this.state.totalPages
				startRecord = (this.state.totalPages - 1) * this.state.perPage
			}
			this.setState({ currentPage, startRecord },
				() => {
					let scroll = document.getElementById('scrollTop');
					scroll.scrollIntoView();
				}
			)
		} 
	}

	querySelection = (i, item) => {
		if(this.state.selectedQuery !== item) {
			if(item.display_output && Object.entries(item.display_output).length) {
				Object.values(item.display_output).forEach(out => {
					if(out.default) {
						this.setState({ [item.query_id+"_"+out.output_field]: out.default },
							() => {
								// if(out.output_type === "list" && out.values) {
									// 	this.handleMultiSelectChange(item.query_id+"_"+out.output_field, this.state[item.query_id+"_"+out.output_field], out.values)
								// }
							}
						)
					}
					if(out.api_details && Object.entries(out.api_details).length) {
						let apiDetails = out.api_details
						let params = apiDetails.api_request
						if(apiDetails.additional_fields && apiDetails.additional_fields) {
							apiDetails.additional_fields.forEach(addFields => {
								if(addFields === "account_id" && this.state.selectedAccount && this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
									params.account_id = this.state.selectedAccount
								}
								if(addFields === "regions" && this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
									params.region = this.state.selectedRegion
								}
								if(addFields === "tags" && this.state.selectedTags && this.state.selectedTags.length) {
									params.tags = this.state.selectedTags
								}
							})
						}
						let manditoryFieldsFilled = true
						if(apiDetails.mandatory_fields && apiDetails.mandatory_fields.length) {
							apiDetails.mandatory_fields.forEach(manFields => {
								if(!this.state[item.query_id+"_"+manFields] || this.state[item.query_id+"_"+manFields] === "" || (Array.isArray(this.state[item.query_id+"_"+manFields]) && !this.state[item.query_id+"_"+manFields].length)) {
									manditoryFieldsFilled = false
								} else {
									params[manFields] = this.state[item.query_id+"_"+manFields]
								}
							})
						}
						if(manditoryFieldsFilled) {
							this.dynamicCallApi(params, item, apiDetails, out.output_field, out.output_type)
						}
					} else if(out.type === "dropdown" && out.output_type === "list") {
						let options = ["All"]
						options =  options.concat(out.values)
						this.setState({ [item.query_id+"_drop_options_"+out.output_field]: options },
							() => this.handleMultiSelectChange(item.query_id+"_"+out.output_field, this.state[item.query_id+"_"+out.output_field], options)
						)
					}
				})
			}

			this.setState({
				queryErrorMessage: "",
				selectedQuery: item,
				// selectedQueryAccount: this.state.selectedAccount,
				// selectedQueryRegion: this.state.selectedRegion,
				selectedQueryDuration: this.state.selectedDuration,
				queryStartTime: this.state.startTime,
				queryEndTime: this.state.endTime,
				showQueryDateTimePicker: false,
				hasError: false
			}, 
				() => this.formQueryBuilder()
			)
		} else {
			this.setState({ selectedQuery: "", hasError: false })
		}
	}

	formQueryBuilder = () => {
		let querybuilder = []
		let selectedQuery = this.state.selectedQuery
		let query = selectedQuery.display_query
		let splitString = query.split("$")

		let re = /{(.*?)}/;
		splitString.forEach(str => {
			let result = str.split(re);
			if(result.length === 1) {
				let dataRow = {}
				dataRow.input = false
				dataRow.string = result[0]
				querybuilder.push(dataRow)
			} else {
				result.forEach((item, index) => {
					if(item !== "") {
						if(index === 1) {
							let dataRow = {}
							dataRow.input = true
							dataRow.field = item
							dataRow.string = result[0]
							querybuilder.push(dataRow)
						} else {
							let dataRow = {}
							dataRow.input = false
							dataRow.string = item
							querybuilder.push(dataRow)
						}
					}
				})
			}
		})
		this.setState({ querybuilder })
	}

	queryDurationFunction = (type) => {
		let duration = this.state.selectedQueryDuration
		let period = duration.substring(duration.length, duration.length-1)
		let durationBtw = getDayFromSelectedDuration(duration)
		let startTime = ""
		if(period === "d") {
			startTime = momentDateGivenFormat(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
		} else {
			startTime = momentDateGivenFormat(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
		}
		
		let endTime = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')

		this.setState({ queryStartTime: startTime, queryEndTime: endTime })
	}
	
	handleStartTimeChange(date) {
		let queryStartTime = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
		let queryEndTime = this.state.queryEndTime
		if(new Date(queryStartTime).getTime() > new Date(queryEndTime).getTime() ) {
			queryEndTime = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')
		}
		this.setState({ queryStartTime, queryEndTime})
	}

	handleEndTimeChange(date) {
		let queryStartTime = this.state.queryStartTime
		let queryEndTime = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
		if(new Date(queryEndTime).getTime() > new Date(queryStartTime).getTime() ) {
			this.setState({ queryEndTime})
		}
	}

	executeQuery = () => {
		let selectedQuery  = this.state.selectedQuery
		let hasError = false

		let params = {}
		params.query_id = selectedQuery.query_id
		params.selectedCategory = this.state.selectedCategory && this.state.selectedCategory !== "All" ? this.state.selectedCategory : selectedQuery.category
		if(selectedQuery.required_fields && selectedQuery.required_fields.length) {
			selectedQuery.required_fields.forEach(item => {
				if(item === "account_id" && this.state[this.state.selectedQuery.query_id+"_selectedQueryAccount"] && this.state[this.state.selectedQuery.query_id+"_selectedQueryAccount"].length && !this.state[this.state.selectedQuery.query_id+"_selectedQueryAccount"].includes("All")) {
					// params.account_id = this.state[this.state.selectedQuery.query_id+"_selectedQueryAccount"].filter(e => e !== "All")
					params.account_id = this.state[this.state.selectedQuery.query_id+"_selectedQueryAccount"]
				} else if(item === "account_id")  {
					params.account_id = "ALL"
				}
				if(item === "region" && this.state[this.state.selectedQuery.query_id+"_selectedQueryRegion"] && this.state[this.state.selectedQuery.query_id+"_selectedQueryRegion"].length && !this.state[this.state.selectedQuery.query_id+"_selectedQueryRegion"].includes("All")) {
					params.region = this.state[this.state.selectedQuery.query_id+"_selectedQueryRegion"]
				} else if(item === "region")  {
					params.region = "ALL"
				}
				if(item === "tags" && this.state[this.state.selectedQuery.query_id+"_selectedTags"] && this.state[this.state.selectedQuery.query_id+"_selectedTags"].length && !this.state[this.state.selectedQuery.query_id+"_selectedTags"].includes("All")) {
					params.tags = this.state[this.state.selectedQuery.query_id+"_selectedTags"]
				}

				if(item === "duration") {
					params.start_time = momentConvertionLocalToUtcTime(this.state.queryStartTime, "YYYY-MM-DD HH:mm:00")
					params.end_time = momentConvertionLocalToUtcTime(this.state.queryEndTime, "YYYY-MM-DD HH:mm:00")
				}
			})
		}

		if(selectedQuery.display_output && Object.entries(selectedQuery.display_output).length) {
			Object.entries(selectedQuery.display_output).forEach(([key, value]) => {
				if(value.type === "text") {
					if(value.output_type === "string") {
						if(!this.state[this.state.selectedQuery.query_id+"_"+value.output_field] || this.state[this.state.selectedQuery.query_id+"_"+value.output_field] === "") {
							hasError = true
						} else if(value.validation && Object.entries(value.validation).length) {
							let validation = value.validation
							if(validation.min && this.state[this.state.selectedQuery.query_id+"_"+value.output_field].length < validation.min) {
								hasError = true
								this.setState({ ['error_message_'+this.state.selectedQuery.query_id+"_"+value.output_field]: 'field should be greater than '+ validation.min +' and lesser than '+ validation.max })
							} else if(validation.max && this.state[this.state.selectedQuery.query_id+"_"+value.output_field].length > validation.max) {
								hasError = true
								this.setState({ ['error_message_'+this.state.selectedQuery.query_id+"_"+value.output_field]: 'field should be greater than '+ validation.min +' and lesser than '+ validation.max })
							} else {
								this.setState({ ['error_message_'+this.state.selectedQuery.query_id+"_"+value.output_field]: "" })
								params[value.output_field] = this.state[this.state.selectedQuery.query_id+"_"+value.output_field]
							}
						} else {
							params[value.output_field] = this.state[this.state.selectedQuery.query_id+"_"+value.output_field]
						}
					} else if(value.output_type === "list") {
						if(!this.state[this.state.selectedQuery.query_id+"_"+value.output_field] || !this.state[this.state.selectedQuery.query_id+"_"+value.output_field].length) {
							hasError = true
						} else {
							if(value.api_details && value.include_all) {
								if(this.state[this.state.selectedQuery.query_id+"_"+value.output_field] && this.state[this.state.selectedQuery.query_id+"_"+value.output_field].includes("All")) {
									params[value.output_field] = ["All"]
								} else {
									params[value.output_field] = this.state[this.state.selectedQuery.query_id+"_"+value.output_field]
								}
							} else {
								if(this.state[this.state.selectedQuery.query_id+"_"+value.output_field].length > 1 && this.state[this.state.selectedQuery.query_id+"_"+value.output_field].includes("All")) {
									let data = this.state[this.state.selectedQuery.query_id+"_"+value.output_field]
									params[value.output_field] = data.filter(e => e !== 'All')
								} else {
									params[value.output_field] = this.state[this.state.selectedQuery.query_id+"_"+value.output_field]
								}
							}
						}
					}
				} else {
					let output = this.state[this.state.selectedQuery.query_id+"_"+value.output_field]
					// if(value.output_type === "list") {
					// 	output = this.state[value.output_field]
					// }
                    if(value.output_type === "list") {
                        if(!output || !output.length ) {
                            hasError = true
                        }
                    } else {
                        if(!value || value === "") {
                            hasError = true
                        }
                    }
					if(output.includes("All") && value.output_type === "list") {
						if(value.include_all) {
							output = ["All"]
						} else {
							output = output.filter(e => e !== 'All')
						}
					}
					params[value.output_field] = output
				}
			})
		}

		// console.log("params", params)

		this.setState({ hasError })

		if(!hasError) {

			let queryProps = {
				account_id: this.state[this.state.selectedQuery.query_id+"_selectedQueryAccount"],
				region: this.state[this.state.selectedQuery.query_id+"_selectedQueryRegion"],
				duration: this.state.selectedQueryDuration,
				params: params,
				start_time: this.state.queryStartTime,
				end_time: this.state.queryEndTime,
				selectedQuery: this.state.selectedQuery,
				querybuilder: this.state.querybuilder
			}

			if(selectedQuery.async) {
				this.setState({ queryLoading: true, queryProps, selectedInput: params },
					// () => this.startQueryExection(params)					
				)
			} else {
				this.setState({ queryLoading: true, queryProps },
					() => this.getCostQueryResults(params)
				)
			}
		}
	}

	startQueryExection = (params) => {
		this.props.startQueryExection(params, (promise, response) => {
			if(promise) {
				if(response.status === "FAILED") {
					this.setState({ queryErrorMessage: response, queryLoading: false })
				} else {
					this.getCostQueryResults(response)
				}
			} else {
				this.setState({ queryErrorMessage: response, queryLoading: false })	
			}
		})
	}

	getCostQueryResults = (params) => {
		this.props.getCostQueryResults(params, (promise, response) => {
			if(promise) {
				if(response.status === "RUNNING") {
					setTimeout(() => { this.getCostQueryResults(response) }, 3000)
				} else if(response.status === "FAILED") {
					this.setState({ queryErrorMessage: response, queryLoading: false })
				} else {
					setTimeout(() => {
						let results = response.results && response.results.length ? response.results : []
						if(results.length) {
							this.setState({ queryResult: response, queryLoading: false, showQueryResult: true })
						} else {
							let dataRow = {
								message: "No records to show for the selected options"
							}
							this.setState({ queryErrorMessage: dataRow, queryLoading: false })
						}
					}, 2000)
				}
			} else {
				this.setState({ queryErrorMessage: response, queryLoading: false })	
			}
		})
	}	

	onKeyDown = (e, field, stateField) => {
		let array = this.state[stateField] && Array.isArray(this.state[stateField]) ? this.state[stateField] : []
		//|| e.keyCode === 188
		if (e.keyCode === 13 || e.keyCode === 9) {
			if(this.state[field] && this.state[field] !== "") {
				array.push(this.state[field].trim())
				if(e.keyCode === 9) {
					e.preventDefault();
				}
				this.setState({ [stateField]: array, [field]: "" })
			}
		}
	}

	onBlur = (e, field, stateField) => {
		let array = this.state[stateField] ? this.state[stateField] : []
		if(this.state[field] && this.state[field] !== "") {
			array.push(this.state[field].trim())
			this.setState({ [stateField]: array, [field]: "" })
		}
	}

	removeSelectedBadge = (field, value, index) => {
		let filteredReslt = this.state[field]
		filteredReslt.splice(index, 1);
		this.setState({ [field]: filteredReslt })
	}

	setFavouriteQuery = (index, queryId) => {
		let params = {
			query_id: queryId
		}

		if(this.state['favourite_'+index]) {
			this.props.setFavouriteQuery(params, (promise, response) => {
				if(promise) {
					this.updateQueryList()
					
					CommonNotification.addNotification({
						message: "Query "+queryId+" added to favourite",
						type: "success",
						insert: "top",
						container: "top-center",
						dismiss: {
							duration: 3000,
							onScreen: false,
							pauseOnHover: true,
							showIcon: true,
						}
					})
				}
			})
		} else {
			this.props.unsetFavouriteQuery(params, (promise, response) => {
				if(promise) {
					this.updateQueryList()
					CommonNotification.addNotification({
						message: "Query "+queryId+" removed from favourite",
						type: "success",
						insert: "top",
						container: "top-center",
						dismiss: {
							duration: 3000,
							onScreen: false,
							pauseOnHover: true,
							showIcon: true,
						}
					})
				}
			})
		}
	}

	onChangeTextString = (outputStructure, field, value) => {
		let hasError = false
		let validation = outputStructure.validation
		if(validation && Object.entries(validation).length) {
			if(validation.min && value.length < validation.min) {
				hasError = true
				this.setState({ ['error_message_'+field]: 'field should be greater than '+ validation.min +' and lesser than '+ validation.max })
			} else if(validation.max && value.length > validation.max) {
				hasError = true
				this.setState({ ['error_message_'+field]: 'field should be greater than '+ validation.min +' and lesser than '+ validation.max })
			} else {
				this.setState({ ['error_message_'+field]: "" })
			}
			
			if(validation.data_type) {
				if(validation.data_type === "alphanumeric") {
					value = alphaNumeric(value)
				}
			}
		}
		
		this.setState({ [field]: value, hasError })
	}

	onChangeTextDropDown = (displayOutput, changedKey) => {
		let selectedQuery = this.state.selectedQuery
		let keysToReload = displayOutput[changedKey].api_details.keys_to_reload
		if(keysToReload && keysToReload.length) {
			keysToReload.forEach(item => {
				let out = displayOutput[item]
				// if(out.default) {
				// 	this.setState({ [selectedQuery.query_id+"_"+out.output_field]: selectedQuery.default })
				// }
				if(out.api_details && Object.entries(out.api_details).length) {
					let apiDetails = out.api_details
					let params = apiDetails.api_request
					if(apiDetails.additional_fields && apiDetails.additional_fields) {
						apiDetails.additional_fields.forEach(addFields => {
							if(addFields === "account_id" && this.state.selectedAccount && this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
								params.account_id = this.state.selectedAccount
							}
							if(addFields === "regions" && this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
								params.region = this.state.selectedRegion
							}							
							if(addFields === "tags" && this.state.selectedTags && this.state.selectedTags.length) {
								params.tags = this.state.selectedTags
							}
						})
					}
					let manditoryFieldsFilled = true
					if(apiDetails.mandatory_fields && apiDetails.mandatory_fields.length) {
						apiDetails.mandatory_fields.forEach(manFields => {
							if(!this.state[selectedQuery.query_id+"_"+manFields] || this.state[selectedQuery.query_id+"_"+manFields] === "" || (Array.isArray(this.state[selectedQuery.query_id+"_"+manFields]) && !this.state[selectedQuery.query_id+"_"+manFields].length)) {
								manditoryFieldsFilled = false
							} else {
								params[manFields] = this.state[selectedQuery.query_id+"_"+manFields]
							}
						})
					}
					if(manditoryFieldsFilled) {
						this.setState({ [this.state.selectedQuery.query_id+"_"+out.output_field]: out.output_type === "text" ? "" : [] },
							() => this.dynamicCallApi(params, selectedQuery, apiDetails, out.output_field, out.output_type)						
						)
					}
				}
			})
		}
	}

	dynamicCallApi = (params, selectedQuery, apiDetails, outputField, outputType) => {
		this.props.dynamicCallApi(apiDetails.api_name, apiDetails.api_resource, params, (promise, response) => {
			if(promise) {
				let options = []
				if(outputType === "list") {
					let dataRow = {}
					dataRow.key = "All"
					dataRow.value = "All"
					options.push(dataRow)
				}
				if(response.results) {
					let results = response.results
					results.forEach(res => {
						if(apiDetails.field_name === "id_name_map") {
							options.push(res[apiDetails.field_name])
						} else {
							let dataRow = {}
							dataRow.key = res[apiDetails.field_name]
							dataRow.value = res[apiDetails.field_name]
							options.push(dataRow)
						}
					})
				}
				
				this.setState({ [selectedQuery.query_id+"_options_"+outputField]: options },
                    () => {
                        if(outputType === "list") {
							let stateValue = this.state[selectedQuery.query_id+"_options_"+outputField] ? this.state[selectedQuery.query_id+"_options_"+outputField] : []
                            this.handleMultiSelectChange(outputField, stateValue, this.state[selectedQuery.query_id+"_options_"+outputField])
                        }
                    }
                )
			}
		})
	}
	
	render() {		
		return (
			<div className="container-fluid overflow-auto flex-grow-1 bg-muted" onClick={ (event) => { this.handleClickOutside(event) } }>
                <div className={`loaderOverlay ${this.state.queryLoading ? "" : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
                <div className='row h-100'>
                    <div className='col-sm-12 p-0'>
                        <div className="title-section pb-3">
							<div className="row">
                                <div className='col-4 align-self-center'>
                                    <div className='d-flex'>
                                        <h6 className="text-white m-0">Queries</h6>
                                    </div>
                                    <p className="text-white m-0">Resources queries on your Cloud</p>
								</div>
                                <div className={`col-8 align-self-center ${this.state.showQueryResult ? "displayNone" : ""}`}>
                                    <div className={`col-md-12 d-flex flex-wrap justify-content-end bd-highlight`}>
										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: '150px' }}>
											<label className="mb-1">Provider</label>
											<Select
												placeholder={'Select'}
												isSearchable={false}
												// menuIsOpen={true}
												className='f13 p-0 colorBlack reactSelectFilterDrop'
												value={({
													value: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : 'Select',
													label: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : <span className="placeholder">Select</span>
												})}
												options={this.state.providers && this.state.providers.map(item => ({
													value: item.provider_name,
													label: item.provider_name,	
												}))}
												onChange={event => this.setState({
													selectedProvider: event.value,
													changesInFilter: true,
												})}
											/>
											</div>
										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} ref={this.accountRef} style={{maxWidth: '150px' }}>
											<label className="mb-1">Account</label>
											<div className='dlpHeaderMultiSelection removeDropdownSearchBar'>
												<ReactMultiSelectCheckboxes
													placeholderButtonLabel="All"
													menuIsOpen ={this.state.isAccountOpen}
													getDropdownButtonLabel={() => this.getMultiSelectedCount('account', this.state.selectedAccount)}
													onChange={arr => {
													this.handleMultiSelectChange('selectedAccount', arr ? arr : []) }}
													options={this.state.accounts && this.state.accounts.map(account => ({
														value: account.account_id,
														label: account.account_name,
													}))}
													value={this.state.selectedAccount.map(comp => ({
														value: comp,
													}))}
												/>
											</div>
										</div>
										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} ref={this.regionRef} style={{maxWidth: '150px' }}>
											<label className="mb-1">Region</label>
											<div className='dlpHeaderMultiSelection removeDropdownSearchBar'>
												<ReactMultiSelectCheckboxes
													placeholderButtonLabel="All"
													menuIsOpen ={this.state.isRegionOpen}
													getDropdownButtonLabel={() => this.getMultiSelectedCount('region', this.state.selectedRegion)}
													onChange={arr => {
													this.handleMultiSelectChange('selectedRegion', arr ? arr : []) }}
													options={this.state.regions && this.state.regions.map(item => ({
														value: item.region,
														label: item.name,
													}))}
													value={this.state.selectedRegion.map(comp => ({
														value: comp,
													}))}
												/>
											</div>
										</div>												
										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} ref={this.tagsRef} style={{maxWidth: '150px' }}>
											<div className="" onClick={() => this.setState({ showTagFilter: true })}>
												<label className="mb-0">Tags</label>
												<p className='mb-0 mt-3'>
													{!this.state.selectedTags || (this.state.selectedTags && !this.state.selectedTags.length) ?
														<span>All</span>
													: this.state.selectedTags && this.state.selectedTags.length ?
														<span>{this.state.selectedTags.length} selected</span>
													: null}
												</p>
											</div>
											{this.state.showTagFilter ?
												<div className="resourcePopupFilterSection border mt-2" style={{right: "17%"}}>
													<div className="p-3 bg-muted">
														<div className={`w-100 mt-3`}>
															<p className="mb-2 w-100 text-white">Tags</p>
															<div className={`d-flex`}>
																<label className="mb-1 w-40">Key</label>
																<label className="mb-1 w-40 ml-3">Value</label>
															</div>
															<div className={`topFilterTags w-100`}>
																<div className={`d-flex mt-3`}>
																	<Select
																		isSearchable={true}
																		components={{
																			IndicatorSeparator: () => null
																		}}
																		className={`selectOption w-40 mr-2 mt-2`}
																		value={({
																			value: this.state.tag_key,
																			label: this.state.tag_key && this.state.tag_key !== "" ? this.state.tag_key : <span className="placeholder">Key</span>
																		})}			
																		
																		options={this.props.tagKeys && this.props.tagKeys.map(item => ({
																			value: item,
																			label: item,	
																		}))}
																		onChange={event => this.setState({
																			tag_key: event.value,
																			tagValues: [],
																		},
																			() => this.getAllTagsValues()
																		)}
																	/>
																	{this.state.tag_key && this.state.tag_key !== "" && !this.state.tagValues.length ?
																		<div className='ml-3'>
																			<Spinner className='text-center align-self-center' color='white' size='sm' />
																		</div>
																	:
																		<React.Fragment>
																			<div className={`multiSelectOption align-self-center mr-2 mt-2 z998`}>
																				<ReactMultiSelectCheckboxes
																					placeholderButtonLabel="Select"
																					getDropdownButtonLabel={() => this.getMultiSelectedCount("tag_value", this.state.tag_value ? this.state.tag_value : [])}
																					options={this.state.tagValues && this.state.tagValues.map(row => ({
																						value: row,
																						label: row,
																					}))}
																					onChange={arr => { this.handleMultiSelectChange("tag_value", arr ? arr : [], this.state.tagValues) }}
																					value={this.state.tag_value && this.state.tag_value.map(acc => ({
																						value: acc
																					}))}
																				/>
																			</div>
																			<span className={`far fa-plus cursorPointer f18 align-self-center ml-4`} onClick={() => this.addNewTag("tags")}></span>
																		</React.Fragment>
																	}
																</div>
															</div>
														</div>
														{this.state.tagsSelected && this.state.tagsSelected.length ? 
															this.state.tagsSelected.map((tag, index) => {
																return(
																	<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">{tag.key +" : "+tag.value}
																		<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeTagSection(tag) } ></i>
																	</span>
																)
															})
														: null}
														<div className="d-flex mt-3">
															<span className={`text-info mr-2 align-self-center cursorPointer`} onClick={() => this.setState({ tagsSelected: [], selectedTags: [] })}>Clear</span>
															<button className={`btn btn-sm btn-light cursorPointer`} onClick={() => this.setState({ showTagFilter: false, selectedTags: this.state.tagsSelected })}>Select</button>
														</div>
													</div>
												</div>
											: null}
										</div>
										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zindex1`} ref={this.daterRangeListRef} style={{maxWidth: '150px'}}>
											<label className="mb-1">Duration</label>
											<Select
												placeholder={'Select'}
												isSearchable={false}
												// menuIsOpen={true}
												className='f13 p-0 colorBlack reactSelectFilterDrop'
												value={({
													value: this.state.selectedDuration && this.state.selectedDuration !== "" ? this.state.selectedDuration : 'Select',
													label: this.state.selectedDuration && this.state.selectedDuration !== "" ? durationOptions.filter(e => e.value === this.state.selectedDuration)[0].option : <span className="placeholder">Select</span>
												})}
												options={durationOptions && durationOptions.map(item => ({
													value: item.value,
													label: item.option,	
												}))}
												onChange={event => {
													if(event.value !== 'custom') {
														this.setState(
															{
																selectedDuration: event.value,
																showDateRangePicker: false,
															},
															() => {
																this.durationFunction()
															}
														)
													} else {
														this.setState({ showDateRangePicker: true, selectedDuration: event.value })
													}
												}}
											/>
											{this.state.showDateRangePicker ?
												<div className="obsDateTimePicker text-black5  pt-2 ml-n1" style={{"z-index": "999999999","position": "absolute","right":0}}>
													<RangePicker
														// locale={`en-us`} // default is en-us
														show={true} // default is false
														disabled={false}
														placeholder={["Start Time", "End Time"]}
														// defaultDates={[
														// 	new Date().getFullYear() + '-' + new Date().getMonth() + 1 + '-' + new Date().getDate(), 
														// 	new Date().getFullYear() + '-' + new Date().getMonth() + 1 + '-' + new Date().getDate()
														// ]}
														// defaultDates={[
														// 	momentDateGivenFormat(new Date(), "YYYY-MM-DD"),
														// 	momentDateGivenFormat(new Date(), "YYYY-MM-DD"),
														// ]}
														// defaultDates={[
														// year + "-" + month + "-" + date,
														// year + "-" + month + "-" + date
														// ]} // ['YYYY-MM-DD', 'YYYY-MM-DD']
														// defaultTimes={[hour + ":" + minute, hour + ":" + minute]} // ['hh:mm', 'hh:mm']
														// initialDates={[
														// year + "-" + month + "-" + date,
														// year + "-" + month + "-" + date
														// ]} // ['YYYY-MM-DD', 'YYYY-MM-DD']
														// initialTimes={[new Date().getHours()-1 + ":00", new Date().getHours() + ":00"]} // ['hh:mm', 'hh:mm']
														defaultTimes={["00:00", "23:59"]} // ['hh:mm', 'hh:mm']
														onConfirm={(res) => this.handleSelectNew(res)}
														onClose={() => console.log("onClose")}
														onClear={() => console.log("onClear")}
														style={{ width: "610px", margin: "0 auto" }}
														// markedDates={[
														// `${year}-${month.padStart(2, "0")}-${date.padStart(2, "0") - 1}`,
														// `${year}-${month.padStart(2, "0")}-${date.padStart(2, "0")}`
														// ]} // OPTIONAL. ['YYYY-MM-DD']
													/>
												</div>
											: null}
														
											{this.state.showDateTime ?
											<div className="w-100 pt-1" style={{"z-index": "999999999","position": "absolute","top":"40px"}}>
												<Datetime 
													// onChange={item => this.handleJumpTo(item) }
													dateFormat={'DD MMM YYYY'}
													// timeFormat={'hh:mm A'}
													input={false}
													isValidDate={disableFutureDt}
												/>
											</div>
										: null}
										</div>
										<div className={`ml-3 align-self-center displayNone`}>
											<div className="btn-group" role="group" aria-label="Basic example">
												<button type="button" className={`${this.state.queryLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-right px-2 py-1`} onClick={() => this.setState({ queryLoading: false }, () => this.onSearch() )}>Search</button>
												<button type="button" className={`${this.state.queryLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-left px-2 py-1`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='p-2'>
							{this.state.showQueryResult ?
								<QueryResults 
									selectedQuery = {this.state.selectedQuery}
									queryStartTime = {this.state.queryStartTime}
									queryEndTime = {this.state.queryEndTime}
									selectedInput = {this.state.selectedInput}
									querybuilder = {this.state.querybuilder}
									queryResult = {this.state.queryResult}
									selectedProvider = {this.state.selectedProvider}
									duration = {this.state.selectedQueryDuration}
									account_id = {this.state[this.state.selectedQuery.query_id+"_selectedQueryAccount"]}
									region = {this.state[this.state.selectedQuery.query_id+"_selectedQueryRegion"]}
									hideQueryResults = {() => this.setState({ showQueryResult: false })}
								/>
							:
								<div className="rounded bg-dark p-3">
									{this.state.listLoading ?
										<div className='d-flex justify-content-center m-4'>
											<Spinner className='text-center' color='white' size='lg' />
										</div>
									:	
										<React.Fragment>
										<div className={`d-flex pb-2 justify-content-between`}>
											<div className="d-flex mr-2 w-40">
												<p className="f12 m-0 align-self-center pt-1">Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.state.queryList && this.state.queryList.length}{this.state.queryList && this.state.queryList.length > 1 ? ' queries' : ' query'}</p>
											</div>
											<div className="d-flex justify-content-end w-60 mb-n2">
												{this.state.queryList && this.state.queryList.length ?
													<Search
														data={this.state.queryList ? this.state.queryList : []}
														applyTags={false}
														applyLiteDarkTags={true}
														topClassName={'bg-black5 align-self-center w-50 mr-1 border-gray5 rounded-5 f12'}
														searchClassName={'px-2 f12'}
														searchIconColor={'text-gray5 f12'}
														searchPlaceHolder={'Search queries....'}
														className={"bg-transparent text-white pl-0 form-control-sm f12"}
														filteredData={(filteredArray) => {
															this.setState({ filteredArray, startRecord: 0, currentPage: 1 })
														}}
													/>
												: null}
												{this.state.filteredArray && this.state.filteredArray.length > this.state.perPage ?
													<div className="pagination errorPagePagination f12">
														<span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
														<button><i className={`fal fa-arrow-to-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('start', this.state.currentPage)}></i></button> 
														<button><i className={`fal fa-angle-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('previous', this.state.currentPage)}></i></button> 
														<button><i className={`fal fa-angle-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
														<button><i className={`fal fa-arrow-to-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
													</div>
												: null}
											</div>
										</div>
									
										{/* <div className="d-flex errorSectionScroll h-95" > */}
										<div className="d-flex overflowYAuto" style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
											{this.state.categories ? 
												<div className="w-20 pr-3 categoryBoxSection" id="scrollTop">
													<div class="bg-muted px-2 pt-2 select-bgg">
														{this.state.categories.map((item, index) => {
															return(
																item.category === "All" ?
																	<div className={`d-flex justify-content-between cursorPointer mb-2 ${this.state.selectedCategory === item.category ? 'text-info disabled' : ''}`} onClick={() => this.setState({ selectedCategory: "All", selectedSubCategory: "" }, () => this.filterQueryList())}>
																		<p className="m-0 f-20">{item.category} Queries</p>
																		{/* <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p> */}
																		{/* <input class="form-control form-control-sm w-25 border-0 bg-grey text-white readOnly  disabled rounded-0 form-number text-right" style={{minWidth: "40px"}} type="text" placeholder="1" value={item.count !== '' ? item.count : ''} aria-label=".form-control-sm example" /> */}
																	</div>
																:
																	<React.Fragment>
																	<div className={`d-flex cursorPointer mb-2 ${(!this.state.selectedSubCategory || this.state.selectedSubCategory === '') && item.category.includes(this.state.selectedCategory) ? 'text-info disabled' : (!item.count ? 'text-gray4 disabled test' : 'text-white')}`}>
																		<div class="d-flex justify-content-between mb-2" style={{maxWidth: "250px"}} onClick={() => this.setState({ selectedCategory: item.category, selectedSubCategory: "" }, () => this.filterQueryList())}>
																			<p class="mb-0 align-self-center ellipseCategoryBoxSection w-60" id={'category_'+index}>
																				{item.sub_category && item.sub_category.length ?
																					<span className={`far ${item.category.includes(this.state.selectedCategory) ? 'fa-minus-circle' : 'fa-plus-circle'} mr-1`}></span>
																				: null}
																				{item.category}
																			</p>
																			<UncontrolledTooltip target={'category_'+index}>{item.category}</UncontrolledTooltip>
																			<input class="form-control form-control-sm w-25 border-0 bg-grey text-white readOnly  disabled rounded-0 form-number text-right" style={{minWidth: "40px"}} type="text" placeholder="1" value={item.count !== '' ? item.count : ''} aria-label=".form-control-sm example" />
																		</div>
																	</div>
																	{item.category.includes(this.state.selectedCategory) && item.sub_category && item.sub_category.length ?
																		item.sub_category.map(subCat => {
																			return(
																				<div class={`d-flex justify-content-between mb-2 ml-2 ${subCat.sub_category === this.state.selectedSubCategory ? 'text-info disabled' : 'cursorPointer'}`} style={{maxWidth: "230px"}} onClick={() => this.setState({ selectedSubCategory: subCat.sub_category }, () => this.filterQueryList())}>
																					<p class="mb-0 align-self-center ellipseCategoryBoxSection w-60" id={'sub_category_'+index}>{subCat.sub_category}</p>
																					<UncontrolledTooltip target={'sub_category_'+index}>{subCat.sub_category}</UncontrolledTooltip>
																					<input class="form-control form-control-sm w-25 border-0 bg-grey text-white readOnly  disabled rounded-0 form-number text-right" style={{minWidth: "40px"}} type="text" placeholder="1" value={subCat.count !== '' ? subCat.count : ''} aria-label=".form-control-sm example" />
																				</div>
																			)
																		})															
																	: null}
																	</React.Fragment>
															)
														})}
													</div>
												</div>
											: null}
											<div className={`${this.state.categoires ? 'w-80' : 'w-100'} h-100`} id="scrollTop">
												<div className="accordion" id="accordionExample">
													{this.state.filteredArray && this.state.filteredArray.length ?
														this.state.filteredArray.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map((item, i) => {
															return(
																<div className={`card bg-dark3 border-0 mb-2 overflow-unset`}>
																	<div className="d-flex justify-content-between">
																		<div id={'heading_'+i} class="p-3" onClick={() => this.querySelection(i, item)}>
																			<div className="mb-1 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
																				<div className="text-white"><span className={`far ${this.state.selectedQuery && this.state.selectedQuery.query_id === item.query_id ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>{item.query_id +" - "+ (item.title ? item.title : "")}</div>
																			</div>
																			{!this.state.selectedQuery || this.state.selectedQuery.query_id !== item.query_id ?
																				<p className="ml-4 mb-0 f16"> 
																					<span className="mr-2 text-white">Query:</span>
																					{item.display_query}
																					
																					{item.required_fields && item.required_fields.includes("account_id") && this.state.selectedAccount && this.state.selectedAccount.length && !this.state.selectedAccount.includes("All") ?
																							<span className="ml-1">for the account <span className="ml-1 text-info">{this.state.selectedAccount.join(", ")}</span></span>
																						:
																							<span className="ml-1">for <span className="ml-1 text-info">All</span> <span className="ml-1">the accounts</span></span>
																						}
																						{item.required_fields && item.required_fields.includes("region") && this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All") ?
																							<span className="ml-1">for the region <span className="ml-1 text-info">{this.state.selectedRegion.join(", ")}</span></span>
																						:
																							<span className="ml-1">for <span className="ml-1 text-info">All</span> <span className="ml-1">the regions</span></span>
																						}
																						{item.required_fields && item.required_fields.includes("tags") && this.state.selectedTags && this.state.selectedTags.length ?
																							<span className="ml-1">for the tags 
																								{this.state.selectedTags.map(tag => {
																									return(
																										<span className="ml-1 text-info">{tag.key+" : "+tag.value}</span>
																									)
																								})}
																							</span>
																						: null}
																						{item.required_fields && item.required_fields.includes("duration") && this.state.selectedDuration ?
																							<span className="ml-1">for the duration 
																							{this.state.selectedDuration === "custom" ?
																								<span className="ml-1 text-info">{momentDateGivenFormat(this.state.startTime, "DD MMM YYYY hh:mm A") +" - "+momentDateGivenFormat(this.state.endTime, "DD MMM YYYY hh:mm A")}</span>
																							: 
																								<span className="ml-1 text-info">{this.state.selectedDuration && this.state.selectedDuration !== "" ? durationOptions.filter(e => e.value === this.state.selectedDuration)[0].option : ""}</span>
																							}
																							</span>
																						: null}
																				</p>
																			: null}
																		</div>
																		{/* <span className={`text-right pr-3 ursorPointer mt-3`} onClick={e => { 
																				e.stopPropagation() 
																				this.setState({ 
																					['favourite_'+i]: typeof this.state['favourite_'+i] === 'undefined' ? (item.favourite ? false : true) : (this.state['favourite_'+i] ? false : true)
																				},
																					// () => this.setFavouriteQuery(i, item.query_id)
																				)
																			}}
																		>
																			<i class={`fal fa-star ${typeof this.state['favourite_'+i] === 'undefined' ? (item.favourite ? 'fillStar' : '') : (this.state['favourite_'+i] ? 'fillStar' : '')} `}></i>
																		</span> */}
																	</div>
																	{this.state.selectedQuery && this.state.selectedQuery.query_id === item.query_id ?
																		<div id={'collapse_event'} className={`collapse show`} aria-labelledby={'heading_'+i} data-parent="#accordionExample">
																			<div className="card-body">
																				{item.description && item.description !== "" ?
																					<div className="f16 w-95 mb-1"> 
																						<span className="w-100">Description:</span>
																						{item.description.split("\n").map(newLine => {
																							return(
																								<span className="d-flex">
																								{newLine.split("\t").map((tab, i) => {
																									return (
																										<span className={`${!i ? "" :""}`}>{tab}</span>
																									)
																								})}
																								</span>
																							)
																						})}
																					</div>
																				: null}
																				{item.controls && Object.entries(item.controls).length ?
																					<div className="d-flex mt-2 p-3 rounded bg-dark">
																						{item.controls.hasOwnProperty("Tactic") ?
																							<div className={`mr-2 border-right-black5
																								${(!item.controls.hasOwnProperty("Action/Impact") && !item.controls.hasOwnProperty("Technique")) ? "w-100" 
																								: (!item.controls.hasOwnProperty("Action/Impact") || !item.controls.hasOwnProperty("Technique")) ? "w-50" : "w-20"}
																							`}>
																								<label className="text-white mb-2">Tactic</label>
																								{item.controls.Tactic.map(ctrls => {
																									return(
																										<p className="mb-0 d-flex flex-wrap">{ctrls}</p>
																									)
																								})}
																							</div>
																						: null}
																						{item.controls.hasOwnProperty("Technique") ?
																							<div className={`mr-2 border-right-black5
																								${(!item.controls.hasOwnProperty("Action/Impact") && !item.controls.hasOwnProperty("Tactic")) ? "w-100" 
																								: (!item.controls.hasOwnProperty("Action/Impact") || !item.controls.hasOwnProperty("Tactic")) ? "w-50" : "w-20"}
																							`}>
																								<label className="text-white mb-2">Technique</label>
																								{item.controls.Technique.map(ctrls => {
																									return(
																										<p className="mb-0 d-flex flex-wrap">{ctrls}</p>
																									)
																								})}
																							</div>
																						: null}
																						{item.controls.hasOwnProperty("Action/Impact") ?
																							<div className={`mr-2
																								${(!item.controls.hasOwnProperty("Tactic") && !item.controls.hasOwnProperty("Technique")) ? "w-100" 
																								: (!item.controls.hasOwnProperty("Tactic") || !item.controls.hasOwnProperty("Technique")) ? "w-45" : "w-45"}
																							`}>
																								<label className="text-white mb-2">Action/Impact</label>
																								{item.controls["Action/Impact"].map(ctrls => {
																									return(
																										<p className="mb-0 d-flex flex-wrap">{ctrls}</p>
																									)
																								})}
																							</div>
																						: null}
																					</div>
																				: null}
																				<div className={`form-group dlpDescriptionForm dlpDescriptionFormPlain px-2 py-3 bg-dark rounded mt-2`}>
																					<div className="d-flex flex-wrap">
																						{this.state.querybuilder && this.state.querybuilder.map((qry, j) => {
																							return(
																								!qry.input ?
																									<p className={`mb-0 ${!j ? "mr-2" : "mx-2"} align-self-end text-info mt-3`}>{qry.string}</p>
																								:
																								<React.Fragment>
																									{this.state.selectedQuery.display_output && this.state.selectedQuery.display_output[qry.field] && this.state.selectedQuery.display_output[qry.field].type === "text" ?
																										this.state.selectedQuery.display_output[qry.field].output_type === "string" ?
																											<React.Fragment>
																											{this.state.selectedQuery.display_output[qry.field].api_details && Object.entries(this.state.selectedQuery.display_output[qry.field].api_details).length ?
																												<Select
																													placeholder={'Select'}
																													isSearchable={true}
																													components={{
																														IndicatorSeparator: () => null
																													}}
																													className={`selectOption mr-2 mt-3 ${this.state.hasError && (!this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] || this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] === "") ? 'dangerSelectLine' : ''}`}
																													value={({
																														value: this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] && this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] !== "" ? this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] : 'Select',
																														label: this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] && this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] !== "" ? this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] : <span className={`placeholder`}>Select</span>
																													})}		
																													//<span className={`${this.state.hasError ? "text-danger" : ""} placeholder`}>{this.state.hasError ? "requried" : "Select"}</span>
																													options={this.state[this.state.selectedQuery.query_id+"_options_"+this.state.selectedQuery.display_output[qry.field].output_field] && this.state[this.state.selectedQuery.query_id+"_options_"+this.state.selectedQuery.display_output[qry.field].output_field].map(res => ({
																														value: res.value,
																														label: res.value+(res.value !== res.key ? " "+res.key : ""),
																													}))}															
																													onChange={event => this.setState({ [this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field]: event.value }, () => this.onChangeTextDropDown(this.state.selectedQuery.display_output, qry.field))}
																												/>
																											:
																												<React.Fragment>
																												<input 
																													type="text" 
																													className={`inputField mt-3 w-30 ${this.state.hasError && (!this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] || this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] === "" || this.state["error_message_"+this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] !== "")  ? 'dottedDangerTextboxLine' : ''}`}
																													placeholder={qry.field}
																													value={this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] ? this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] : ''}
																													onChange={e => this.onChangeTextString(this.state.selectedQuery.display_output[qry.field], this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field, e.target.value)}
																												/>
																												{this.state.selectedQuery.display_output[qry.field].validation ?
																													<React.Fragment>
																													<i id={this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field} className="align-self-end fas fa-info-circle ml-2 mt-3 cursorPointer"></i>
																													<UncontrolledTooltip target={this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field}>
																													<div className="uncontrolledTooltipLeftAlign">
																														{this.state.selectedQuery.display_output[qry.field].validation.data_type ?
																															<p className="mb-1 text-primary-color">
																																<span className="text-white mr-1">Data Type:</span>
																																{this.state.selectedQuery.display_output[qry.field].validation.data_type}
																															</p>
																														: null}
																														{this.state.selectedQuery.display_output[qry.field].validation.min ?
																															<p className="mb-1 text-primary-color">
																																<span className="text-white mr-1">Min length:</span>
																																{this.state.selectedQuery.display_output[qry.field].validation.min}
																															</p>
																														: null}
																														{this.state.selectedQuery.display_output[qry.field].validation.max ?
																															<p className="mb-1 text-primary-color">
																																<span className="text-white mr-1">Max length:</span>
																																{this.state.selectedQuery.display_output[qry.field].validation.max}
																															</p>
																														: null}
																													</div>
																													</UncontrolledTooltip>
																													</React.Fragment>
																												: null}
																												</React.Fragment>
																											}
																											</React.Fragment>
																										: this.state.selectedQuery.display_output[qry.field].output_type === "list" ?
																											<React.Fragment>
																											{this.state.selectedQuery.display_output[qry.field].api_details && Object.entries(this.state.selectedQuery.display_output[qry.field].api_details).length ?
																												<div className={`multiSelectOption align-self-end mt-3 ${this.state.hasError && (!this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] || !this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field].length) ? 'dangerMultiSelectLine' : ''}`}>
																													<ReactMultiSelectCheckboxes
																														placeholderButtonLabel="Select"
																														isSearchable={true}			
																														getDropdownButtonLabel={() => this.getMultiSelectedCountNew(this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field, this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] ? this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] : [])}
																														options={this.state[this.state.selectedQuery.query_id+"_options_"+this.state.selectedQuery.display_output[qry.field].output_field] && this.state[this.state.selectedQuery.query_id+"_options_"+this.state.selectedQuery.display_output[qry.field].output_field].map(res => ({
																															value: res.value,
																															label: res.value+(res.value !== res.key ? " ("+res.key+")" : "")
																														}))}
																														onChange={event => {
																															this.handleMultiSelectChange(this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field, event, this.state[this.state.selectedQuery.query_id+"_options_"+this.state.selectedQuery.display_output[qry.field].output_field]) }
																														}
																														value={this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] && this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field].map(item => ({
																															value: item
																														}))}
																													/>
																												</div>
																											:
																												<React.Fragment>
																												<input 
																													type="text" 
																													className={`inputField mt-3 w-30 ${this.state.hasError && (!this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] || !this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field].length)  ? 'dottedDangerTextboxLine' : ''}`}
																													placeholder={qry.field}
																													value={this.state[this.state.selectedQuery.query_id+"_listInput_"+this.state.selectedQuery.display_output[qry.field].output_field]}
																													onKeyDown={e => this.onKeyDown(e, this.state.selectedQuery.query_id+"_listInput_"+this.state.selectedQuery.display_output[qry.field].output_field, this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field)}
																													onBlur={e => this.onBlur(e, this.state.selectedQuery.query_id+"_listInput_"+this.state.selectedQuery.display_output[qry.field].output_field, this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field)}
																													onChange={e => this.setState({ [this.state.selectedQuery.query_id+"_listInput_"+this.state.selectedQuery.display_output[qry.field].output_field]: e.target.value })}
																												/>
																												{this.state.selectedQuery.display_output[qry.field].validation ?
																													<React.Fragment>
																													<i id={this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field} className="align-self-end fas fa-info-circle ml-2 mt-3 cursorPointer"></i>
																													<UncontrolledTooltip target={this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field}>
																													<div className="uncontrolledTooltipLeftAlign">
																														{this.state.selectedQuery.display_output[qry.field].validation.data_type ?
																															<p className="mb-1 text-primary-color">
																																<span className="text-white mr-1">Data Type:</span>
																																{this.state.selectedQuery.display_output[qry.field].validation.data_type}
																															</p>
																														: null}
																														{this.state.selectedQuery.display_output[qry.field].validation.min ?
																															<p className="mb-1 text-primary-color">
																																<span className="text-white mr-1">Min length:</span>
																																{this.state.selectedQuery.display_output[qry.field].validation.min}
																															</p>
																														: null}
																														{this.state.selectedQuery.display_output[qry.field].validation.max ?
																															<p className="mb-1 text-primary-color">
																																<span className="text-white mr-1">Max length:</span>
																																{this.state.selectedQuery.display_output[qry.field].validation.max}
																															</p>
																														: null}
																													</div>
																													</UncontrolledTooltip>
																													</React.Fragment>
																												: null}
																												</React.Fragment>
																											}

																											{this.state.selectedQuery.display_output[qry.field].api_details && Object.entries(this.state.selectedQuery.display_output[qry.field].api_details).length ? 
																												this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] && !this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field].includes("All") ? 
																													this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field].map((tab, addIndex) => {
																														return(
																															<span key={addIndex} className={`badge-square badge-secondary f13 align-self-end p-1 ${!addIndex ? "ml-2" : "ml-1"} mr-1 mb-1`} >
																																{this.state[this.state.selectedQuery.query_id+"_options_"+this.state.selectedQuery.display_output[qry.field].output_field].map(res => {
																																	return(
																																	res.value === tab ?
																																		res.value +(res.key !== res.value ? " ("+res.key+")" : "")
																																	: null
																																	)
																																})}
																																<i onClick={ () => this.removeSelectedBadge(this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field, tab, addIndex) } className="ml-1 fal fa-times cursorPointer"></i>
																															</span>
																														)
																													})
																												: this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] && this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field].includes("All") ?
																													<span className={`badge-square badge-secondary f13 align-self-end p-1 ml-2 mr-1 mb-1`} > All </span>
																												: null
																											:
																												this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] && this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field].map((tab, addIndex) => {
																													return(
																														<span key={addIndex} className={`badge-square badge-secondary f13 align-self-end p-1 ${!addIndex ? "ml-2" : "ml-1"} mr-1 mb-1`} >
																														{tab}
																															<i onClick={ () => this.removeSelectedBadge(this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field, tab, addIndex) } className="ml-1 fal fa-times cursorPointer"></i>
																														</span>
																													)
																												})
																											}
																											</React.Fragment>
																										: null
																									: this.state.selectedQuery.display_output && this.state.selectedQuery.display_output[qry.field] && this.state.selectedQuery.display_output[qry.field].type === "dropdown" ?
																										this.state.selectedQuery.display_output[qry.field].output_type === "list" ?
																											<React.Fragment>
																											<div className={`multiSelectOption ${this.state.hasError && (!this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] || !this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field].length) ? 'dangerMultiSelectLine' : ''} align-self-end mt-3 d-flex`}>
																												<ReactMultiSelectCheckboxes
																													placeholderButtonLabel="Select"
																													isSearchable={true}			
																													getDropdownButtonLabel={() => this.getMultiSelectedCountNew(this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field, this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] ? this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] : [])}
																													options={this.state[this.state.selectedQuery.query_id+"_drop_options_"+this.state.selectedQuery.display_output[qry.field].output_field] && this.state[this.state.selectedQuery.query_id+"_drop_options_"+this.state.selectedQuery.display_output[qry.field].output_field].map(res => ({
																														value: res,
																														label: res,
																													}))}
																													onChange={event => {
																														this.handleMultiSelectChange(this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field, event, this.state[this.state.selectedQuery.query_id+"_drop_options_"+this.state.selectedQuery.display_output[qry.field].output_field]) }
																													}
																													value={this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] && this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field].map(item => ({
																														value: item
																													}))}
																												/>
																											</div>
																											
																											{this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] && !this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field].includes("All") ? 
																											this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field].map((tab, addIndex) => {
																												return(
																													<span key={addIndex} className={`badge-square badge-secondary f13 align-self-end p-1 ${!addIndex ? "ml-2" : "ml-1"} mr-1 mb-1`} >
																													{tab}
																														<i onClick={ () => this.removeSelectedBadge(this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field, tab, addIndex) } className="ml-1 fal fa-times cursorPointer"></i>
																													</span>
																												)
																											})
																											: this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] && this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field].includes("All") ?
																												<span className={`badge-square badge-secondary f13 align-self-end p-1 ml-2 mr-1 mb-1`} > All </span>
																											: null}
																											</React.Fragment>
																										: this.state.selectedQuery.display_output[qry.field].output_type === "string" ? 
																											<Select
																												placeholder={'Select'}
																												isSearchable={true}
																												components={{
																													IndicatorSeparator: () => null
																												}}
																												className={`selectOption mr-2 mt-3 ${this.state.hasError && (!this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] || this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] === "") ? 'dangerSelectLine' : ''}`}
																												value={({
																													value: this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] && this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] !== "" ? this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] : 'Select',
																													label: this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] && this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] !== "" ? this.state[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field] : <span className="placeholder">Select</span>
																												})}															
																												options={this.state.selectedQuery.display_output[qry.field].values && this.state.selectedQuery.display_output[qry.field].values.map(item => ({
																													value: item,
																													label: item,	
																												}))}															
																												onChange={event => {
																													this.setState({
																														[this.state.selectedQuery.query_id+"_"+this.state.selectedQuery.display_output[qry.field].output_field]: event.value
																													})
																												}}
																											/>

																										: null
																									: null}
																								</React.Fragment>
																							)
																						})}
																						{this.state.selectedQuery.required_fields ?
																							<React.Fragment>
																							{this.state.selectedQuery.required_fields.includes("account_id") ?
																								<React.Fragment>
																								<p className="mb-0 mx-2 align-self-end text-info mt-3">for Account</p>
																								<div className={`multiSelectOption align-self-end mt-3`}>
																									<ReactMultiSelectCheckboxes
																										placeholderButtonLabel="Select"
																										isSearchable={true}														
																										getDropdownButtonLabel={() => this.getMultiSelectedCount(this.state.selectedQuery.query_id+"_selectedQueryAccount", this.state[this.state.selectedQuery.query_id+"_selectedQueryAccount"] ? this.state[this.state.selectedQuery.query_id+"_selectedQueryAccount"] : [])}
																										options={this.state.accounts && this.state.accounts.map(acc => ({
																											value: acc.account_id,
																											label: acc.account_name,
																										}))}
																										onChange={event => {
																											this.handleMultiSelectChangeNew('accounts', this.state.selectedQuery.query_id+"_selectedQueryAccount", event) }
																										}
																										value={this.state[this.state.selectedQuery.query_id+"_selectedQueryAccount"] && this.state[this.state.selectedQuery.query_id+"_selectedQueryAccount"].map(item => ({
																											value: item
																										}))}
																									/>
																								</div>
																								</React.Fragment>
																							: null}
																							{this.state.selectedQuery.required_fields.includes("region") ?
																								<React.Fragment>
																								<p className="mb-0 mx-2 align-self-end text-info mt-3">Region</p>
																								<div className={`multiSelectOption align-self-end mt-3`}>
																									<ReactMultiSelectCheckboxes
																										placeholderButtonLabel="Select"														
																										getDropdownButtonLabel={() => this.getMultiSelectedCount(this.state.selectedQuery.query_id+"_selectedQueryRegion", this.state[this.state.selectedQuery.query_id+"_selectedQueryRegion"] ? this.state[this.state.selectedQuery.query_id+"_selectedQueryRegion"] : [])}
																										isSearchable={true}
																										options={this.state.regions && this.state.regions.map(reg => ({
																											value: reg.region,
																											label: reg.name,
																										}))}
																										onChange={event => {
																											this.handleMultiSelectChangeNew("regions", this.state.selectedQuery.query_id+"_selectedQueryRegion", event) }
																										}
																										value={this.state[this.state.selectedQuery.query_id+"_selectedQueryRegion"] && this.state[this.state.selectedQuery.query_id+"_selectedQueryRegion"].map(item => ({
																											value: item
																										}))}
																									/>
																								</div>
																								</React.Fragment>
																							: null}

																							{this.state.selectedQuery.required_fields.includes("tags") ?
																								<React.Fragment>
																								<p className="mb-0 mx-2 align-self-end text-info mt-3">Tags</p>
																								<div className={`select align-self-end`}>
																									<Select
																										placeholder={'Select'}
																										isSearchable={true}
																										components={{
																											IndicatorSeparator: () => null
																										}}
																										className={`selectOption mr-2 mt-3`}
																										value={({
																											value: this.state[this.state.selectedQuery.query_id+"_selectedTagkey"],
																											label: this.state[this.state.selectedQuery.query_id+"_selectedTagkey"] ? this.state[this.state.selectedQuery.query_id+"_selectedTagkey"] : <span className="placeholder">Select</span>
																										})}
																														
																										options={this.props.tagKeys && this.props.tagKeys.map(item => ({
																											value: item,
																											label: item,	
																										}))}

																										onChange={event => { this.setState({ [this.state.selectedQuery.query_id+"_selectedTagkey"]: event.value },
																											() => {
																												this.getQueryAllTagsValues(this.state.selectedQuery.query_id)
																											}
																										)}}
																									/>
																								</div>
																								<div className={`multiSelectOption align-self-end mt-3`}>
																									<ReactMultiSelectCheckboxes
																										placeholderButtonLabel="Select"		
																										getDropdownButtonLabel={() => this.getMultiSelectedCount(this.state.selectedQuery.query_id+"_selectedQueryTagValues", this.state[this.state.selectedQuery.query_id+"_selectedQueryTagValues"] ? this.state[this.state.selectedQuery.query_id+"_selectedQueryTagValues"] : [])}
																										isSearchable={true}
																										options={this.state[this.state.selectedQuery.query_id+"_selectedTagValues"] && this.state[this.state.selectedQuery.query_id+"_selectedTagValues"].map(row => ({
																											value: row,
																											label: row
																										}))}
																										onChange={event => {
																											this.handleMultiSelectChangeNew("selectedQueryTagValues", this.state.selectedQuery.query_id+"_selectedQueryTagValues", event, this.state[this.state.selectedQuery.query_id+"_selectedTagValues"]) }
																										}
																										value={this.state[this.state.selectedQuery.query_id+"_selectedQueryTagValues"] && this.state[this.state.selectedQuery.query_id+"_selectedQueryTagValues"].map(item => ({
																											value: item
																										}))}
																									/>
																								</div>
																								<span className={`far fa-plus cursorPointer f18 align-self-center ml-4 mt-3 mr-2`} onClick={() => this.addQueryTag(this.state.selectedQuery.query_id)}></span>
																								</React.Fragment>
																							: null}
																							
																							{this.state[this.state.selectedQuery.query_id+"_selectedTags"] && this.state[this.state.selectedQuery.query_id+"_selectedTags"].length ? 
																								this.state[this.state.selectedQuery.query_id+"_selectedTags"].map((tag, index) => {
																									return(
																										<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2 mt-3">{tag.key +" : "+tag.value}
																											<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeQueryTagSection(this.state.selectedQuery.query_id, tag) } ></i>
																										</span>
																									)
																								})
																							: null}

																							{this.state.selectedQuery.required_fields.includes("duration") ?
																								<React.Fragment>
																								<p className="mb-0 mx-2 align-self-end text-info mt-3">Duration</p>
																								<div className="position-relative d-inline-block w-20">
																									<Select
																										placeholder={'Select'}
																										isSearchable={false}
																										components={{
																											IndicatorSeparator: () => null
																										}}
																										className={`selectOption mr-2 mt-3 ${this.state.hasError && !this.state.selectedQueryDuration ? 'dangerSelectLine' : ''}`}
																										value={({
																											value: this.state.selectedQueryDuration && this.state.selectedQueryDuration !== "" ? this.state.selectedQueryDuration : 'Select',
																											label: this.state.selectedQueryDuration && this.state.selectedQueryDuration !== "" ? durationOptions.filter(e => e.value === this.state.selectedQueryDuration)[0].option : <span className="placeholder">Select</span>
																										})}															
																										options={durationOptions && durationOptions.map(item => ({
																											value: item.value,
																											label: item.option,	
																										}))}															
																										onChange={event => {
																											if(event.value !== 'custom') {
																												this.setState(
																													{
																														selectedQueryDuration: event.value,
																														showQueryDateTimePicker: false,
																													},
																													() => {
																														this.queryDurationFunction()
																													}
																												)
																											} else {
																												this.setState({ showQueryDateTimePicker: true, selectedQueryDuration: event.value })
																											}
																										}}
																									/>
																								</div>
																								{this.state.showQueryDateTimePicker ?
																									<div className="d-flex mt-2">
																										<p className="mb-0 mx-2 align-self-end text-info mt-3">start&nbsp;time</p>
																										<Datetime 
																											value={momentDateGivenFormat(this.state.queryStartTime, 'DD MMM YYYY hh:mm A')}
																											onChange={this.handleStartTimeChange}
																											dateFormat={'DD MMM YYYY'}
																											timeFormat={'hh:mm A'}
																											inputProps={{ readOnly: true, class: "inputField" }}
																											className="w-100 mt-4"
																											isValidDate={queryDateTime}
																										/>
																										<p className="mb-0 mx-2 align-self-end text-info mt-3">end&nbsp;time</p>
																										<Datetime
																											value={momentDateGivenFormat(this.state.queryEndTime, 'DD MMM YYYY hh:mm A')}
																											onChange={this.handleEndTimeChange}
																											dateFormat={'DD MMM YYYY'}
																											timeFormat={'hh:mm A'}
																											inputProps={{ readOnly: true, class: "inputField" }}
																											className="w-100 mt-4"
																											isValidDate={queryDateTime}
																										/>
																									</div>
																								: 
																									// <div className="d-flex mt-4">
																									// 	<p className="mb-0 mx-2 align-self-end text-info mt-3">start&nbsp;time</p>
																									// 	<span className="mt-3 text-white">{momentDateGivenFormat(this.state.queryStartTime, "DD MMM YYYY hh:mm A")}</span>
																									// 	<p className="mb-0 mx-2 align-self-end text-info mt-3">end&nbsp;time</p>
																									// 	<span className="mt-3 text-white">{momentDateGivenFormat(this.state.queryEndTime, "DD MMM YYYY hh:mm A")}</span>
																									// </div>
																									null
																								}
																								
																								</React.Fragment>
																							: null}
																							</React.Fragment>
																						: null}
																					</div>
																				</div>
																					
																				<div className={`d-flex justify-content-end w-99 mt-2 ${this.state.queryLoading ? "disabled" : ""}`}>
																					{this.state.queryErrorMessage && this.state.queryErrorMessage !== "" ?
																						// <div className="w-100">
																						<React.Fragment>
																							<p className="mb-0 align-self-center text-danger mr-3">{this.state.queryErrorMessage.message ? this.state.queryErrorMessage.message : this.state.queryErrorMessage.errorMessage ? this.state.queryErrorMessage.errorMessage : ""}</p>
																						</React.Fragment>
																					: null}
																					<button className={`btn btn-primary f12 align-self-center mr-2 
																						${this.state.hasError ? "" : ""}`} 
																						onClick={() => this.setState({ queryErrorMessage: "" }, () => this.executeQuery())}>
																							{/* {this.state.queryLoading ?
																							<Spinner className='text-center mr-1' color='white' size='sm' />
																							: null} */}
																							Run Query
																						</button>
																					<button className={`btn btn-light f12 align-self-center`}onClick={() => this.setState({ ['expand_'+i]: false, selectedQuery: "" })}>Close</button>
																				</div>
																			</div>
																		</div>
																	: null}
																</div>
															)
														})
													: null}
												</div>
											</div>
										</div>
										</React.Fragment>
									}
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
QueryEvents.propTypes = {}

const mapStateToProps = state => {
	// console.log('observability query page',state)
	return {
		providers: state.filters.providers,
		accounts: state.filters.accounts,
		regions: state.filters.regions,
		tagKeys: state.filters.tagKeys,
		observabilityQueryList: state.observability.observabilityQueryList
	}
}

export default AppWrapper(QueryEvents, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
	getAllTagsKeys,
	getAllTagsValues,
	getCostQueries, 
	startQueryExection, 
	getCostQueryResults,
	setFavouriteQuery,
	unsetFavouriteQuery,
	dynamicCallApi,
	setResourcePropsDetails
})