/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 17/05/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { AppWrapper } from '../../common/AppWrapper'
import { momentConvertionUtcToLocalTime, capitalizeFirstLetter, capitalizeAllLetter, unitTimeConvertionDetail, twoDateDiffrenceDayHoursMinutes, currentUTCtime } from '../../../utils/utility'
import { Spinner } from 'reactstrap'
import _ from 'lodash'
import { URL_PATH } from '../../../config/urlPath'

import { setAutomationPropsDetails } from '../../../actions/commonAction'
import { getAutomationExecution, startAutomation } from '../../../actions/AutomationAction'
import { listRemediation, startRemediation } from '../../../actions/aiops/RemediationAction'

import { getEventInfo } from '../../../actions/detectors/detectorsAction'
import { getIdentifierResults } from '../../../actions/governance/userInsightsAction'

import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'

import ActionSection from './ActionSection'

class LandingPage extends Component {
    
    constructor(props) {

        super(props)
        this.sortAutoamtionDropdown = React.createRef()
        this.scrolltoDiv = React.createRef()
        this.sliderWrap = React.createRef()
        
        this.state = {
            showLoading: true,
            pageType: "Details",
            expandIdentifierDetails: true,
            expandCheckDetails: false, 
            expandTagDetails: false,
            expandSteps: false,
            
            totalItemShowing: 0,
            showingLeftIcon: false,
            showingRightIcon: true,
			showingOffStart: 1,
            showingOff: 0
        }
    }

    componentDidMount = () => {
        // let identifierId = "cf86c6a287e82d339f2349c7b63a5350AWSCW001fail_1650609778165"
        // let identifierId = "776dfce54486a50a6c9132e02563eb6cAWSCW001fail_1653199390393"
        let identifierId = ""

        if (this.props.location && this.props.location.state && Object.keys(this.props.location.state).length) {
            identifierId = this.props.location.state.identifier && this.props.location.state.identifier.identifier_id ? this.props.location.state.identifier.identifier_id : ""
        }
        if(identifierId !== "") {
            this.listRemediation(identifierId)
        } else {
            this.goBack()
        }
    }

    goBack = () => {
        this.props.history.push({
            pathname: URL_PATH.CHECKS_SEARCH,
        })
    }
    
    listRemediation = (identifierId) => {
        let params = {}
        params.identifier_id = identifierId

        this.props.listRemediation(params, (promise, response) => {
            if(promise) {
                let remediations = response.remediations ? response.remediations : []
                let identifierDetail = response.identifier_detail ? response.identifier_detail : []
                this.setState({ remDetails: response, identifierDetail, remediationsList: remediations },
                    () => {
                        this.slideRight('initiate')
                        this.hideLoading()
                        this.getEventInfo()                        
                        if(identifierDetail.resolution_status_info && identifierDetail.resolution_status_info.status === "resolved" && identifierDetail.resolution_status_info && identifierDetail.resolution_status_info.remediation_id) {
                            this.getAutomationExecution(identifierDetail.resolution_status_info.remediation_id)
                        }
                    }
                )
            } else {
                this.setState({ showLoading: false, remDetails: {}, identifierDetail: {}, remediationsList: [] },
                    () => this.hideLoading()    
                )
            }
        })
    }

    hideLoading = () => {
        setTimeout(() => { this.setState({ showLoading: false }) }, 5000)
    }
	
	slideLeft = () => {
		let move = 0
		move = parseInt(this.state.totalItemShowing)
		this.setState({ totalItemShowing: this.state.totalItemShowing - 1 })
		
        const totalData = this.state.remediationsList.length
		let reqTabWidth = 0
		for (let i = 0; i < totalData; i++) {
			reqTabWidth += this.sliderWrap.current.childNodes[i].getBoundingClientRect().width
		}
		const averageSliderItemWidth = reqTabWidth/totalData
		const sliderWrapWidth = this.sliderWrap.current.getBoundingClientRect().width
		const totalItemShowing = sliderWrapWidth/averageSliderItemWidth;
		if(move > totalItemShowing) {
			if (this.sliderWrap.current.childNodes[move-1] && this.sliderWrap.current.childNodes[move-1].getBoundingClientRect().width) {
				this.sliderWrap.current.scrollLeft -= (this.sliderWrap.current.childNodes[move-1].getBoundingClientRect().width + 25)	
				this.setState({ showingRightIcon: true, showingOff: parseInt(this.state.showingOff - 1), showingOffStart: this.state.showingOffStart < 1 ? 1 : parseInt(this.state.showingOffStart-1)})
			} else {
				this.setState({ showingLeftIcon: false, totalItemShowing: totalItemShowing })
			}
		} else {
			this.setState({ showingLeftIcon: false, totalItemShowing: totalItemShowing })
		}
	}

	slideRight = (item) => {
        const totalData = this.state.remediationsList.length
		let reqTabWidth = 0
		for (let i = 0; i < totalData; i++) {
			reqTabWidth += this.sliderWrap.current.childNodes[i].getBoundingClientRect().width
        }
		const averageSliderItemWidth = reqTabWidth/totalData
		const sliderWrapWidth = this.sliderWrap.current.getBoundingClientRect().width
        let totalItemShowing = sliderWrapWidth/averageSliderItemWidth;

        if(totalItemShowing > Object.entries(this.state.remediationsList).length) {
            totalItemShowing = Object.entries(this.state.remediationsList).length
        }

		let move = 0
		if(this.state.totalItemShowing === 0) {
			this.setState({ totalItemShowing: parseInt(totalItemShowing) })
			move = parseInt(totalItemShowing)
		} else {
			move = parseInt(this.state.totalItemShowing + 1)
			this.setState({ totalItemShowing: this.state.totalItemShowing + 1 })
        }
        
		if (this.sliderWrap.current.childNodes[move-1] && this.sliderWrap.current.childNodes[move-1].getBoundingClientRect().width) {

            if(item === "") {
                this.sliderWrap.current.scrollLeft += (this.sliderWrap.current.childNodes[move-1].getBoundingClientRect().width + 25)
            }
            
			if(!this.state.showingOff && this.state.showingOff < totalItemShowing) {
				this.setState({ showingOff: parseInt(totalItemShowing), showingLeftIcon: true })
			} else{
				this.setState({ showingOffStart: (this.state.showingOffStart + 1), showingOff: parseInt(this.state.showingOff + 1), showingLeftIcon: true })
			}
		} else {
			this.setState({ showingRightIcon: false, showingOff: totalData, totalItemShowing: this.state.remediationsList.length })
		}
    }

    structureActionData = (ruleDetails) => {
        let actions = ruleDetails.actions ? ruleDetails.actions : []            
        let newAction = []
        let tabs = []
        actions.forEach((act, i) => {
            act.section = i+1
            tabs.push(act.section)
            newAction.push(act)
        })

        ruleDetails.actions = newAction

        this.props.setAutomationPropsDetails(ruleDetails)
        this.setState({
            ruleDetails, 
            latestTab: ruleDetails.actions.length,
            activeSection: 1,
            ["showAction_1"]: true,
            tabs,
            enablePlusAction: true,
            loadingAutomation: false
         })
    }

    addAction = (activeSection) => {
        let tabs = this.state.tabs
        if(!this.state.tabs.includes(activeSection)) {
            tabs.push(activeSection)
            this.setState({ latestTab: activeSection })
            // setTimeout(() => { this.scrollToSection(activeSection) }, 1000)
        }
        tabs.forEach(item => {
            this.setState({ ["showAction_"+item]: false })
        })
        this.setState({ 
            tabs: [],
            activeSection,
            ["showAction_"+activeSection]: true,
            enablePlusAction: false
        },
            () => this.setState({ tabs })
        )
    }

    scrollToSection = (activeSection) => {
        this.scrolltoDiv.current.scrollIntoView()
    }

    validateInput = () => {
        this.setState({ showLoading: true }, 
            () => this.startAutomation()
        )
    }

    startAutomation = () => {
        let propsData = this.props.automationPropsDetails
        let params = {}
        params.actions = propsData.actions ? propsData.actions : []
        params.processor_rule_id = propsData.processor_rule_id ? propsData.processor_rule_id : ""
        params.trigger_type = "manual"
        params.source = "checks"
        params.trigger_time = currentUTCtime()
        params.source_id = this.state.identifierDetail && this.state.identifierDetail.identifier_id ? this.state.identifierDetail.identifier_id : ""
        params.account_id = this.state.identifierDetail && this.state.identifierDetail.account_id ? this.state.identifierDetail.account_id : ""
        params.region = this.state.identifierDetail && this.state.identifierDetail.region ? this.state.identifierDetail.region : ""
        params.asset_name = this.state.identifierDetail && this.state.identifierDetail.resource_id ? this.state.identifierDetail.resource_id : ""
        params.resource_type = this.state.identifierDetail && this.state.identifierDetail.resource_type ? this.state.identifierDetail.resource_type : ""
        params.service_name = this.state.identifierDetail && this.state.identifierDetail.service_name ? this.state.identifierDetail.service_name : ""
        params.save_automation = propsData.save_automation ? true : false
        this.props.startAutomation(params, (promise, response) => {
            if(promise) {
                this.setState({ pageType: "Executions", showLoading: false, showDetailsLoading: true },
                    () => {
                        if(response && response.execution_id) {
                            this.getAutomationExecution(response.execution_id)
                        } else {
                            this.setState({ showLoading: false, automationdetails: {}, automaitonTaskDetails: [], showDetailsLoading: false })
                        }
                    }
                )
            } else {
                this.setState({ pageType: "Executions", showLoading: false, automationdetails: {}, automaitonTaskDetails: [], showDetailsLoading: false })
            }
        })
    }

    getAutomationExecution = (executionId) => {
        let params = {
            "execution_id": executionId
        }

        this.props.getAutomationExecution(params, (promise, response) => {
            if(promise) {
                let automationdetails = response.overall_status ? response.overall_status : {}
                let automaitonTaskDetails = response.task_status ? response.task_status : []
                
                this.setState({ automationdetails, automaitonTaskDetails, showDetailsLoading: false, showTaskLoading: false },
                    () => {
                        if(automationdetails.status && automationdetails.status.toLowerCase() !== "success" && automationdetails.status.toLowerCase() !== "failed") {
                            this.setState({ showTaskLoading: true })
                            setTimeout(() => { this.getAutomationExecution(executionId) }, 5000)
                        } else {
                            if(this.state.identifierDetail.resolution_status_info && this.state.identifierDetail.resolution_status_info.status !== "resolved") {
                                this.getIdentifierResults()
                                this.getEventInfo()
                            }
                        }
                    }    
                )
            } else {
                this.setState({ automationdetails: {}, automaitonTaskDetails: [], showDetailsLoading: false })
            }
        })
    }

    onChangePageType = () => {

    }

    getIdentifierResults = () => {
        let identifierId = this.state.identifierDetail && this.state.identifierDetail.identifier_id ? this.state.identifierDetail.identifier_id : ""
		let params = {}
		params.identifier_id = identifierId
		this.props.getIdentifierResults(params, (promise, response) => {
			if (promise) {
                let results = response && response.results && response.results.length ? response.results[0] : {}
                this.setState({ identifierDetail: results  },
                    () => {
                        if(results.resolution_status_info && results.resolution_status_info.remediation_id) {
                            this.getAutomationExecution(results.resolution_status_info.remediation_id)
                        }
                    }    
                )			
			} else {
				this.setState({ identifierDetail: {} })
			}
		})
	}

    getEventInfo = () => {
        let identrifierDetail = this.state.identifierDetail
		if(identrifierDetail.event_id && identrifierDetail.event_id !== "") {	
			let params = {}
			params.service_name = identrifierDetail.service_name
			params.account_id = identrifierDetail.account_id
			params.asset_id = identrifierDetail.asset_id
			params.event_id = identrifierDetail.event_id
			params.provider = identrifierDetail.provider.toLowerCase()
			this.props.getEventInfo(params, (promise, response) => {
				if (promise) {
					this.setState({ eventInfo: response })
				} else {
					this.setState({ eventInfo: []})
				}
			})
		} else {
			this.setState({ eventInfo: [] })
		}
	}

	render() {
		return (
            <div className={`main-content bg-muted`}>
                <div className={`container-fluid overflow-auto flex-grow-1`}>
                    <div className={`loaderOverlay ${this.state.showLoading ? "" : "displayNone"}`}>
                        <div className="overlayEqualizerLoader">
                            <div className="spinner-item"></div>
                            <div className="spinner-item"></div>
                            <div className="spinner-item"></div>
                            <div className="spinner-item"></div>
                            <div className="spinner-item"></div>
                        </div>
                    </div>
                    <div className="row h-100">
                        <div className={`border-right col-md-6 p-0 h-100 overflowYAuto`}>
                            <div className="title-section pb-3">
                                <div className="d-flex justify-content-between">
                                    <div className="">
                                        <h6 className="text-white m-0 f16 text-break align-self-center w-100">Remediation</h6>
                                        <p className="small m-0">Showing remediation options</p>
                                    </div>
                                    <button className="btn btn-sm bg-dark text-decoration-none text-white" onClick={() => this.goBack()} >Back</button>
                                </div>
                            </div>
                            <div className="col-12">
                                {this.state.identifierDetail ?
                                    <React.Fragment>
                                    {/* <p className="ml-2 mb-1 mt-2 f18 text-white">Details</p> */}
                                    <div className="p-3 bg-dark3 mt-2 rounded">
                                        <div className="d-flex justify-content-between">
                                            <div className="">
                                                <h6 className="text-white m-0 f16 text-break align-self-center">
                                                    {this.state.identifierDetail.title ? this.state.identifierDetail.title : ""}
                                                </h6>
                                                <p className="mb-0 mt-1">
                                                    {this.state.identifierDetail.description ? this.state.identifierDetail.description : ""}
                                                </p>
                                            </div>
                                            <div class="d-flex">
                                                {this.state.identifierDetail && this.state.identifierDetail.resolution_status_info && this.state.identifierDetail.resolution_status_info.status ?
                                                    <div className="mr-3">
                                                        <p class="mb-0 text-white">Status</p>
                                                        <span class={`badge badge-${this.state.identifierDetail.resolution_status_info.status.toLowerCase() === "pending" ? "info" : this.state.identifierDetail.resolution_status_info.status.toLowerCase() === "inprogress" ? 'warning' : "purple"}`}>{capitalizeFirstLetter(this.state.identifierDetail.resolution_status_info.status)}</span>
                                                    </div>
                                                : null}
                                                <div className="mr-3">
                                                    <p class="mb-0 text-white">Severity</p>
                                                    <span class={`badge risk-badge-${this.state.identifierDetail.risk ? this.state.identifierDetail.risk.toLowerCase() : 'secondary'}`}>{this.state.identifierDetail.risk ? capitalizeFirstLetter(this.state.identifierDetail.risk) : 'Unknown'}</span>
                                                </div>
                                                <i className={`fa fa-caret-${this.state.expandIdentifierDetails ? "up" : "down"} cursorPointer text-gray2`} onClick={() => this.setState({ expandIdentifierDetails: !this.state.expandIdentifierDetails })}></i>
                                            </div>
                                        </div>
                                        {this.state.expandIdentifierDetails ? 
                                            <div className="mt-2">
                                                <div className='d-flex justify-content-between'>
                                                    <div className="py-1">
                                                        <p className="b-block mb-0">Last Check Failure date</p>
                                                        <p className="mb-0 text-white">
                                                            {this.state.identifierDetail.resolution_status_info && this.state.identifierDetail.resolution_status_info.timestamp ? momentConvertionUtcToLocalTime(this.state.identifierDetail.resolution_status_info.timestamp, 'DD MMM YYYY HH:mm') : ""}
                                                        </p>
                                                    </div>
                                                    {this.state.identifierDetail.resolution_status_info && this.state.identifierDetail.resolution_status_info.status !== "resolved" ?
                                                        <div className="py-1">
                                                            <p className="b-block mb-0">Open for</p>
                                                            <p className="mb-0 text-white">                                                            
                                                                {twoDateDiffrenceDayHoursMinutes(this.state.identifierDetail.resolution_status_info.timestamp, 'currentTime')+' ago'}
                                                            </p>
                                                        </div>
                                                    : null}
                                                    <div className="py-1">
                                                        <p className="b-block mb-0">Provider / Account / Region </p>
                                                        <p className="mb-0 text-white">
                                                            {this.state.identifierDetail.provider ? this.state.identifierDetail.provider.toUpperCase() : ""}
                                                            {this.state.identifierDetail.account_id ? (" / ")+this.state.identifierDetail.account_id : ""}
                                                            {this.state.identifierDetail.region ? (" / ")+this.state.identifierDetail.region : ""}
                                                        </p>
                                                    </div>
                                                    
                                                </div>
                                                <div className='d-flex justify-content-between'><div className="py-1">
                                                    <p className="b-block mb-0">Resources </p>
                                                        <p className="mb-0 text-white">
                                                            {this.state.identifierDetail.resource_type ? this.state.identifierDetail.resource_type : ""}
                                                            {this.state.identifierDetail.service_name ? (" / ")+this.state.identifierDetail.service_name : ""}
                                                        </p>
                                                    </div>
                                                    <div className="py-1">
                                                        <p className="b-block mb-0">Resource Name / ARN</p>
                                                        <p className="mb-0 text-white">
                                                            {this.state.identifierDetail.resource_id ? this.state.identifierDetail.resource_id : ""}
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* <div className='d-flex justify-content-between'>
                                                    <div className="py-1">
                                                        <p className="b-block mb-0">No. of failures present in the Check</p>
                                                        <p className="mb-0 text-white">
                                                            &nbsp;
                                                        </p>
                                                    </div>
                                                </div> */}
                                            </div>
                                        : null}
                                    </div>
                                    </React.Fragment>
                                : null}
                                <div className="d-flex justify-content-between mt-2">
                                    <p className="small mb-0">Showing the remediations options</p>
                                    <div className="d-flex">
                                        <small className="mr-3">
                                            Showing 
                                            {this.state.remediationsList && this.state.remediationsList.length ?  
                                                ' '+(this.state.showingOffStart) +' - '+ (this.state.showingOff ? this.state.showingOff : this.state.remediationsList.length) +' ('+this.state.remediationsList.length+') '
                                            : ' 0'
                                            } 
                                        </small>
                                        <span className={`far fa-chevron-left mr-3 text-muted cursorPointer ${this.state.showingLeftIcon ? "" : 'displayNone'}`} onClick={() => this.slideLeft()}></span>
                                        <span className={`far fa-chevron-right cursorPointer ${this.state.showingRightIcon ? "" : 'displayNone'}`}  onClick={() => this.slideRight("")}></span>
                                    </div>
                                </div>
                                <div className="container-fluid mt-2">
                                    <div className={`row ${!this.state.showLoading ? "" : 'disabled'}`}>
                                        <div className="d-flex flex-nowrap overflow-hidden" ref={this.sliderWrap}>
                                            {this.state.remediationsList && this.state.remediationsList.length ? 
                                                this.state.remediationsList.map((item, index) => {
                                                    return(
                                                    <div onClick={() => this.setState({ selectedAction: "action_"+index, tabs: [] }, () => this.structureActionData(item))} 
                                                        className={`font-weight-bold mr-3 automationActionBox cursorPointer align-self-start ${this.state.selectedAction === "action_"+index ? "bg-dark" : "bg-black5"} border-black6 text-white`}>
                                                        <div className="d-flex">
                                                            <span><i className={`mr-2 fa fa-check-circle ${this.state.selectedAction === "action_"+index ? "text-info" : ""}`}></i></span>
                                                            <span className="align-self-center">{item.name}</span>
                                                        </div>
                                                        
                                                        <div className="d-flex">
                                                            {/* {item.description ? 
                                                                <span><i className="mr-2 fa fa-circle f10"></i></span>
                                                            : null} */}
                                                            <span className={`ml-3 pl-1 align-self-center small ${this.state.selectedAction === "action_"+index ? " text-primary-color" : " text-primary-color"}`}>{item.description ? "    "+item.description : <span>&nbsp;</span>}</span>
                                                        </div>
                                                    </div>
                                                    )
                                                })
                                            : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="rounded p-0 zapDesign zapInputDark">
                                    {this.state.tabs && this.state.tabs.length ? 
                                        this.state.tabs.map((item, index) => {
                                            return(
                                                <ActionSection
                                                    activeSection={item}
                                                    tabCount={index+1}
                                                    totalActions={this.state.tabs.length}
                                                    showAction={this.state["showAction_"+item]}
                                                    latestTab={this.state.latestTab}
                                                    selectAction={(selectedActionTabs) => this.setState({ selectedActionTabs })}
                                                    selectedActionTabs={this.state.selectedActionTabs ? this.state.selectedActionTabs : []}
                                                    scrollToSection={(section) => this.scrollToSection(section)}
                                                    createAutomation={() => this.validateInput()}
                                                    identifierDetail={this.state.identifierDetail}
                                                    goBack={() => this.goBack()}
                                                />
                                            )
                                        })
                                    : null}  
                                </div>
                                <div className={`mt-5 pt-5`} ref={this.scrolltoDiv}></div>
                            </div>
                        </div>
                        <div className="col-md-6 h-100 overflowYAuto">
                            <ul className={`row nav nav-tabs border-bottom bg-muted shadow-none rounded justify-content-between mb-0`} id="myTab" role="tablist">
                                <span className={`d-flex`}>
                                    <li className="nav-item">
                                        <span className={`nav-link text-white cursorPointer ${this.state.pageType === 'Details' ? ' active' : ""}`} onClick={() => this.setState({ pageType: 'Details', menuDisabled: true }, () => this.onChangePageType())}>
                                            Details
                                        </span>
                                    </li>
                                    <li className="nav-item">
                                        <span className={`nav-link text-white cursorPointer ${this.state.pageType === 'Executions' ? ' active' : ""}`} onClick={() => this.setState({ pageType: 'Executions', menuDisabled: true }, () => this.onChangePageType())}>
                                            Executions
                                        </span>
                                    </li>
                                </span>
                            </ul>
                            
                            {this.state.pageType === "Executions" ?
                                <React.Fragment>
                                {!this.state.showDetailsLoading && this.state.automationdetails && Object.entries(this.state.automationdetails).length ?
                                    <React.Fragment>
                                    <div className="accordion" id="accordionDetails">
                                        <div className={`card bg-dark border-0 mb-2`}>
                                            <div id={"heading_details"} onClick={() => this.setState({ expandExecutionDetails: !this.state.expandExecutionDetails })}>
                                                <div className={`p-3 mb-0 d-flex justify-content-between ${this.state.expandExecutionDetails ? "" : ""}`} data-toggle="collapse" data-target={"#collapse_details"} aria-expanded="true" aria-controls={"collapse_details"}>
                                                    <div className="d-flex">
                                                        <div className="text-white">
                                                            <span className={`far ${this.state.expandExecutionDetails ? "fa-minus-circle" : "fa-plus-circle"} mr-2`}></span>
                                                        </div>
                                                        <div className="">
                                                            <p className="text-white mb-0">{this.state.automationdetails.automation_name}</p>
                                                            <p className="small mb-0">{this.state.automationdetails.automation_plan}</p>
                                                        </div>
                                                    </div>
                                                    <div className="mt-n1">
                                                        <p className="b-block mb-0 mr-2">Severity</p>
                                                        <span className={`mr-2 badge ${this.state.automationdetails.importance ? "risk-badge-"+this.state.automationdetails.importance.toLowerCase() : "badge-secondary" }`}>
                                                            {this.state.automationdetails.importance ? capitalizeFirstLetter(this.state.automationdetails.importance) : "Unknown"}
                                                        </span>
                                                    </div>
                                                    <div className="mt-n1">
                                                        <p className="b-block mb-0 mr-2">Status</p>
                                                        <span className={`mr-2 badge badge-${this.state.automationdetails.status.toLowerCase() === "success" ? "success" : "danger"}`}>{this.state.automationdetails.status}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id={"collapse_details"} className={`collapse ${this.state.expandExecutionDetails ? "show" : ""}`} aria-labelledby={"heading_details"} data-parent="#accordionDetails">
                                                <div className="card-body">
                                                    <div className="d-flex mb-1 border-bottom">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Type</p>
                                                            <p className="mb-0 text-white">{this.state.automationdetails.automation_type}</p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Provider / Account / Region </p>
                                                            <p className="mb-0 text-white">
                                                                {this.state.automationdetails.provider ? this.state.automationdetails.provider.toUpperCase() : ""}
                                                                {this.state.automationdetails.account_id ? (" / ")+this.state.automationdetails.account_id : ""}
                                                                {this.state.automationdetails.region ? (" / ")+this.state.automationdetails.region : ""}
                                                            </p>
                                                            {/* <p className="b-block mb-0">Sub Type</p>
                                                            <p className="mb-0 text-white">{this.state.automationdetails.automation_subtype}</p> */}
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-1 border-bottom">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Start Time</p>
                                                            <p className="mb-0 text-warning">
                                                                {this.state.automationdetails.start_time ? momentConvertionUtcToLocalTime(this.state.automationdetails.start_time, "DD MMM YYYY HH:mm:ss") : ""}
                                                            </p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Services </p>
                                                            <p className="mb-0 text-white">
                                                                {this.state.identifierDetail.resource_type ? this.state.identifierDetail.resource_type : ""}
                                                                {this.state.identifierDetail.service_name ? (" : ")+this.state.identifierDetail.service_name : ""}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-1 border-bottom">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">End Time</p>
                                                            <p className="mb-0 text-warning">
                                                                {this.state.automationdetails.end_time ? momentConvertionUtcToLocalTime(this.state.automationdetails.end_time, "DD MMM YYYY HH:mm:ss") : ""}
                                                            </p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Time Saved</p>
                                                            <p className="mb-0 text-info">{unitTimeConvertionDetail(this.state.automationdetails.time_saved, "Seconds")}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-1 border-bottom">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Duration</p>
                                                            <p className="mb-0 text-info">{unitTimeConvertionDetail(this.state.automationdetails.duration, "Seconds")}</p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Cost Saved</p>
                                                            <p className="mb-0 text-info">{this.state.automationdetails.cost_saved} USD</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-1 border-bottom">
                                                        <div className="py-1 w-30">
                                                            <p className="b-block mb-0">Elapsed Time</p>
                                                            <p className="mb-0 text-info">{unitTimeConvertionDetail(this.state.automationdetails.elapsed_time, "Seconds")}</p>
                                                        </div>
                                                        <div className="py-1 w-70 pl-3">
                                                            <p className="b-block mb-0">Tags</p>
                                                            {this.state.automationdetails.automation_tags && this.state.automationdetails.automation_tags.length ? 
                                                                this.state.automationdetails.automation_tags.map(tag => {
                                                                    return(
                                                                        <small className={`mr-1 badge badge-secondary`}>{tag}</small> 
                                                                    )
                                                                })  
                                                            : 
                                                                <p className="mb-0 text-info">&nbsp;</p>
                                                            }
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="d-flex">
                                                        <div className="py-1 w-100">
                                                            <p className="b-block mb-0">Description</p>
                                                            <p className="mb-0 text-white">{this.state.automationdetails.description ? this.state.automationdetails.description : ""}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion" id="accordionTrigger">
                                        <div className={`card bg-dark border-0 my-2`}>
                                            <div id={"heading_trigger"} onClick={() => this.setState({ expandTriggerDetails: !this.state.expandTriggerDetails })}>
                                                <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={"#collapse_trigger"} aria-expanded="true" aria-controls={"collapse_trigger"}>
                                                    <div className="text-white"><span className={`far ${this.state.expandTriggerDetails ? "fa-minus-circle" : "fa-plus-circle"} mr-2`}></span>Trigger Details</div>
                                                    <div className="text-info">Showing trigger details</div>
                                                </div>
                                            </div>
                                            <div id={"collapse_trigger"} className={`collapse ${this.state.expandTriggerDetails ? "show" : ""}`} aria-labelledby={"heading_trigger"} data-parent="#accordionTrigger">
                                                <div className="card-body">
                                                    <div className="d-flex mb-1 border-bottom">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Name</p>
                                                            <p className="mb-0 text-white">
                                                                {this.state.automationdetails.trigger_name ? this.state.automationdetails.trigger_name : <span>&nbsp;</span>}
                                                            </p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Source</p>
                                                            <p className="mb-0 text-white">
                                                                {this.state.automationdetails.trigger_type ? this.state.automationdetails.trigger_type : <span>&nbsp;</span>}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-1 border-bottom">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Type</p>
                                                            <p className="mb-0 text-white">
                                                                {this.state.automationdetails.trigger_source ? this.state.automationdetails.trigger_source : <span>&nbsp;</span>}
                                                            </p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Type Detail</p>
                                                            <p className="mb-0 text-white">
                                                                {this.state.automationdetails.trigger_source_name? this.state.automationdetails.trigger_source_name : <span>&nbsp;</span>}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-1">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Trigger Time</p>
                                                            <p className="mb-0 text-warning">
                                                                {this.state.automationdetails.start_time ? momentConvertionUtcToLocalTime(this.state.automationdetails.trigger_time, "DD MMM YYYY HH:mm:ss") : <span>&nbsp;</span>}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.automationdetails.assets && this.state.automationdetails.assets.length ?
                                        <div className="accordion" id="accordionExample">
                                            <div className={`card bg-dark border-0 my-2`}>
                                                <div id={"heading_assets"} onClick={() => this.setState({ expandAssetDetails: !this.state.expandAssetDetails })}>
                                                    <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={"#collapse_assets"}aria-expanded="true" aria-controls={"collapse_assets"}>
                                                        <div className="text-white"><span className={`far ${this.state.expandAssetDetails ? "fa-minus-circle" : "fa-plus-circle"} mr-2`}></span>Asset Details</div>
                                                        <div className="text-info">Showing asset details</div>
                                                    </div>
                                                </div>
                                                <div id={"collapse_assets"} className={`collapse ${this.state.expandAssetDetails ? "show" : ""}`} aria-labelledby={"heading_assets"} data-parent="#accordionExample">
                                                    <div className="card-body">
                                                        <ResizeableDarkThemeTable
                                                            columns={[
                                                                {
                                                                    Header: "Name",
                                                                    accessor: "asset_name"
                                                                },
                                                                {
                                                                    Header: "ID",
                                                                    accessor: "asset_id"
                                                                },
                                                                {
                                                                    Header: "VPC",
                                                                    accessor: d => d.vpc_id ? d.vpc_id : ""
                                                                },
                                                                {
                                                                    Header: "Resource Type",
                                                                    accessor: d => (d.resource_type ? d.resource_type : "")
                                                                },
                                                                {
                                                                    Header: "Service",
                                                                    accessor: d => (d.service_name ? d.service_name : "")
                                                                }
                                                            ]}
                                                            data={this.state.automationdetails.assets}
                                                            perPage={10}
                                                            dashboard={true}
                                                            sortByColumn={"asset_name"}
                                                            riskTooltip={[]}
                                                            onClickRow={tableRow => {}}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    : null}
                                    </React.Fragment>
                                : 
                                    <div className="d-flex justify-content-center m-4">
                                        <p className="mb-0">No Execution details</p>
                                    </div>
                                }

                                {!this.state.showTaskLoading && this.state.automaitonTaskDetails && this.state.automaitonTaskDetails.length ?
                                    <div className="rounded bg-dark p-3 mt-2">
                                        <div className="timeline-style ml-2">
                                            {this.state.automaitonTaskDetails && this.state.automaitonTaskDetails.map(item => {
                                                return (
                                                    item.taskStartMessage && item.taskStartMessage !== "" ?
                                                        <div className={`d-flex justify-content-between}`}>
                                                            <div className={`d-flex`}>
                                                                <div className="d-flex border-right">
                                                                    <div className="p-2 pr-3 align-self-center">
                                                                        <div className={`font-weight-bold text-center p-2 ${item.taskStatus.toLowerCase() === "completed" ? "text-success success-border" : item.taskStatus.toLowerCase() === "failed" ? "text-danger danger-border" : "text-warning warning--border"} `}>
                                                                            <p className="small mb-0">{momentConvertionUtcToLocalTime(item.taskStartTime, "MMM DD")}</p>
                                                                            <p className="m-0 f10">{momentConvertionUtcToLocalTime(item.taskStartTime, "HH:mm:ss")}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="timeline-space mt-0 align-self-center">
                                                                    <span className="border bg-gray3"></span>
                                                                    <div className="bg-gray3"></div>
                                                                </div>
                                                                <div className={`align-self-center ml-2 border-bottom-muted`}>
                                                                    <div className="d-flex flex-wrap pt-2">
                                                                        <span className={`align-self-center mr-1 fa fa-circle ${item.taskStatus.toLowerCase() === "completed" ? "text-success" : item.taskStatus.toLowerCase() === "failed" ? "text-danger" : "text-warning"}`}></span>
                                                                        <p className={`mb-0 mr-2 ${item.taskStatus.toLowerCase() === "completed" ? "text-success" : item.taskStatus.toLowerCase() === "failed" ? "text-danger" : "text-warning"}`}>{capitalizeFirstLetter(item.taskStatus)}</p>
                                                                        <p className={`mb-0 ${item.taskStatus.toLowerCase() === "completed" ? "text-success" : item.taskStatus.toLowerCase() === "failed" ? "text-danger" : "text-warning"}`}>{item.taskName}</p>
                                                                        {item.taskEndTime && item.taskEndTime !== "" ?
                                                                            <p className="mb-0 ml-2">({twoDateDiffrenceDayHoursMinutes(item.taskStartTime, item.taskEndTime) === "-" ? "0 second" : twoDateDiffrenceDayHoursMinutes(item.taskStartTime, item.taskEndTime) })</p>
                                                                        : null}
                                                                    </div>
                                                                    {item.description ? 
                                                                        <div className="d-flex flex-wrap pt-1">
                                                                            <p className="mb-0">{item.description}</p>
                                                                        </div>
                                                                    : null}
                                                                    <div className="d-flex flex-wrap pt-2">
                                                                        <p className="mb-0">{momentConvertionUtcToLocalTime(item.taskStartTime, "DD MMM YYYY HH:mm:ss")}<span className="mr-1">:</span>{item.taskStartMessage}</p>
                                                                    </div>
                                                                    {item.taskStatus && item.taskMessage && item.taskMessage !== "" && item.taskMessage !== "{}" ?
                                                                        <div className="w-100 mt-2 ml-3">
                                                                            {item.taskMessage.split("\n").length ? 
                                                                                item.taskMessage.split("\n").map((msg, i) => {
                                                                                    return(
                                                                                        <div className="d-flex">
                                                                                            <p className="mb-0">{i+1}.</p>
                                                                                            <p className="ml-2 mb-0">{msg}</p>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            : null}
                                                                        </div>
                                                                    : null }
                                                                    {item.taskEndTime && item.taskEndTime !== "" ?
                                                                        <div className="d-flex flex-wrap mt-1">
                                                                            <div className="w-100">
                                                                                <span>{momentConvertionUtcToLocalTime(item.taskEndTime, "DD MMM YYYY HH:mm:ss")}<span className="mr-1">:</span>{item.taskEndMessage} </span>
                                                                            </div>
                                                                        </div>
                                                                    : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    : null
                                                )
                                            })}
                                        </div>
                                    </div>
                                : this.state.showTaskLoading ?
                                    <div className="d-flex justify-content-center m-4">
                                        <span ><Spinner className="text-center" color="white" size="sm" /></span>
                                    </div>
                                : null}
                                </React.Fragment>
                            : 
                                <div className="accordion" id="accordionDetails">
                                    {this.state.identifierDetail ?
                                        <React.Fragment>
                                            <div className={`card bg-dark border-0 mb-2`}>
                                                <div id={"heading_details"} onClick={() => this.setState({ expandCheckDetails: !this.state.expandCheckDetails })}>
                                                    <div id={"heading_assets"} onClick={() => this.setState({ expandCheckDetails: !this.state.expandCheckDetails })}>
                                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={"#collapse_assets"}aria-expanded="true" aria-controls={"collapse_assets"}>
                                                            <div className="text-white"><span className={`far ${this.state.expandCheckDetails ? "fa-minus-circle" : "fa-plus-circle"} mr-2`}></span>Check Details</div>
                                                            {/* <div className="text-info">Showing check details</div> */}
                                                            {this.state.identifierDetail && this.state.identifierDetail.resolution_status_info && this.state.identifierDetail.resolution_status_info.status ?
                                                                <div className="d-flex">
                                                                    <p class="mb-0 text-white mr-2">Status</p>
                                                                    <span class={`badge badge-${this.state.identifierDetail.resolution_status_info.status.toLowerCase() === "pending" ? "info" : this.state.identifierDetail.resolution_status_info.status.toLowerCase() === "inprogress" ? 'warning' : "purple"}`}>{capitalizeFirstLetter(this.state.identifierDetail.resolution_status_info.status)}</span>
                                                                </div>
                                                            : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id={"collapse_details"} className={`collapse ${this.state.expandCheckDetails ? "show" : ""}`} aria-labelledby={"heading_details"} data-parent="#accordionDetails">
                                                    <div className="card-body">
                                                        <div className="d-flex mb-1 border-bottom">
                                                            <div className="py-1 w-50">
                                                                <p className="b-block mb-0">Services</p>
                                                                <p className="mb-0 text-white">
                                                                    {this.state.identifierDetail.resource_type ? this.state.identifierDetail.resource_type : <span>&nbsp;</span>}
                                                                    {this.state.identifierDetail.service_name ? " : "+this.state.identifierDetail.service_name : <span>&nbsp;</span>}
                                                                </p>
                                                            </div>
                                                            <div className="py-1 w-50">
                                                                <p className="b-block mb-0">Asset</p>
                                                                <p className="mb-0 text-white">
                                                                    {this.state.identifierDetail.resource_id ? this.state.identifierDetail.resource_id : <span>&nbsp;</span>}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="mb-1 border-bottom">
                                                            <p className="b-block mb-0">Details</p>
                                                            <p className="mb-1 text-white">{this.state.identifierDetail.detail ? this.state.identifierDetail.detail : <span>&nbsp;</span>}</p>
                                                            <p className="mb-0 text-white">{this.state.identifierDetail.additional_details ? this.state.identifierDetail.additional_details : <span>&nbsp;</span>}</p>
                                                        </div>
                                                        <div className="d-flex mb-1 border-bottom">
                                                            <div className="py-1 w-50">
                                                                <p className="b-block mb-0">Issue Date</p>
                                                                <p className="mb-0 text-info">{momentConvertionUtcToLocalTime(this.state.identifierDetail.timestamp, 'DD MMM YYYY HH:mm')}</p>
                                                            </div>
                                                            <div className="py-1 w-50 pl-3">
                                                                {this.state.identifierDetail.resolution_status_info && this.state.identifierDetail.resolution_status_info.status === 'resolved' ? 
                                                                    <React.Fragment>
                                                                        <p className="b-block mb-0">Resolved Date:</p>
                                                                        <p className="mb-0 text-info">{momentConvertionUtcToLocalTime(this.state.identifierDetail.resolution_status_info.end_time, 'DD MMM YYYY HH:mm')}</p>

                                                                    </React.Fragment>
                                                                :
                                                                    <React.Fragment>
                                                                        <p className="b-block mb-0">Number of days issue is Pending: </p>
                                                                        <p className="mb-0 text-info">{this.state.identifierDetail && this.state.identifierDetail.resolution_status_info && this.state.identifierDetail.resolution_status_info.timestamp ? twoDateDiffrenceDayHoursMinutes(this.state.identifierDetail.resolution_status_info.timestamp, 'currentTime')+' ago' : ""}</p>
                                                                    </React.Fragment>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="border-bottom">
                                                            <p className="f16 text-left text-info">Compliance</p>
                                                            <div className="d-flex mb-1">
                                                                <div className="py-1 w-50">
                                                                    {this.state.identifierDetail.compliance && this.state.identifierDetail.compliance.length &&
                                                                    _.chain(this.state.identifierDetail.compliance)
                                                                    .groupBy('complianceId')
                                                                    .map((value, key) => ({ label: key, sub: value }))
                                                                    .value()
                                                                    .map((compliance, i) => {
                                                                        //let array = []
                                                                        return (
                                                                            <React.Fragment>
                                                                            <p className="b-block mb-0">{capitalizeAllLetter(compliance.label)}</p>
                                                                            <p className="mb-0 text-white">
                                                                                {compliance.sub.map((item, index) => {
                                                                                    let value = item.authoritySubControl ? item.authoritySubControl : item.authorityMinorControl ? item.authorityMinorControl : item.authorityMajorControl ? item.authorityMajorControl : ""
                                                                                    return ( index ? ', '+value : value)
                                                                                })}
                                                                            </p>
                                                                            </React.Fragment>
                                                                            // <div key={i} className="d-flex position-relative flex-wrap">
                                                                            //     <div className="text-left d-flex mb-1">
                                                                            //         <p className="mr-1 p-0 m-0 f12">{capitalizeAllLetter(compliance.label)}: </p>
                                                                            //         <p className="p-0 m-0 f12 align-self-center text-white4">
                                                                            //         {/* array.push(item.authoritySubControl ? item.authoritySubControl : item.authorityMinorControl ? item.authorityMinorControl : item.authorityMajorControl ? item.authorityMajorControl : "") */}
                                                                            //         {compliance.sub.map((item, index) => {
                                                                            //             let value = item.authoritySubControl ? item.authoritySubControl : item.authorityMinorControl ? item.authorityMinorControl : item.authorityMajorControl ? item.authorityMajorControl : ""
                                                                            //             return ( index ? ', '+value : value)
                                                                            //         })}
                                                                            //         </p>
                                                                            //     </div>
                                                                            // </div>
                                                                        )
                                                                    })}	
                                                                </div>
                                                                <div className="py-1 w-50 pl-3">
                                                                    <p className="mr-1 p-0 m-0 f12">AWS Well Architected Framework: </p>
                                                                    <p className="mb-0 text-white">{this.state.identifierDetail.aws_well_architected && this.state.identifierDetail.aws_well_architected ? this.state.identifierDetail.aws_well_architected.join(",") : <span>&nbsp;</span>}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="border-bottom">
                                                            <p className="f16 text-left text-info">Event Details</p>
                                                            <div className="d-flex mb-1">
                                                                <div className="py-1 w-50">
                                                                    <p className="b-block mb-0">IAM Identity</p>
                                                                    <p className="mb-0 text-white">{this.state.eventInfo && this.state.eventInfo.user_name ? this.state.eventInfo.user_name : <span>&nbsp;</span>}</p>
                                                                </div>
                                                                <div className="py-1 w-50 pl-3">
                                                                    <p className="b-block mb-0">Event Triggered the issue</p>
                                                                    <p className="mb-0 text-white">{this.state.eventInfo && this.state.eventInfo.event_name ? this.state.eventInfo.event_name : <span>&nbsp;</span>}</p>														
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="d-flex mb-1">
                                                                <div className="w-50 mb-1">
                                                                    <p className="b-block mb-0">Time</p>
                                                                    <p className="mb-0 text-white">{this.state.eventInfo && this.state.eventInfo.event_time ? momentConvertionUtcToLocalTime(this.state.eventInfo.event_time, 'DD MMM YYYY HH:mm') : <span>&nbsp;</span>}</p>
                                                                </div>
                                                                <div className="py-1 w-50 pl-3">
                                                                    <p className="b-block mb-0">Event IP: </p>
                                                                    <p className="mb-0 text-white">{this.state.eventInfo && this.state.eventInfo.accessed_from && this.state.eventInfo.accessed_from.ip ? this.state.eventInfo.accessed_from.ip : <span>&nbsp;</span>}</p>
                                                                </div>
                                                            </div>

                                                            <div className="d-flex mb-1">
                                                                <div className="w-50 mb-1">
                                                                    <p className="b-block mb-0">Access Method: </p>
                                                                    <p className="mb-0 text-white">{this.state.eventInfo && this.state.eventInfo.accessed_from && this.state.eventInfo.accessed_from.access_mode ? this.state.eventInfo.accessed_from.access_mode : <span>&nbsp;</span>}</p>
                                                                </div>
                                                            </div>
                                                        </div>                                                    
                                                        <div className="">
                                                            <p className="f16 text-left text-info">Observations</p>
                                                            <div className="d-flex mb-1">
                                                                <div className="py-1 w-50">
                                                                    <p className="b-block mb-0">Complexity Score</p>
                                                                    <p className="mb-0 text-white">
                                                                        {this.state.identifierDetail.complexity ? this.state.identifierDetail.complexity + (this.state.identifierDetail.complexity > 6 ? ' Hard to fix' : this.state.identifierDetail.complexity > 3 ? ' Medium to fix' : ' Easy to fix') : <span>&nbsp;</span>}
                                                                    </p>
                                                                </div>
                                                                <div className="py-1 w-50">
                                                                    <p className="b-block mb-0">Solution</p>
                                                                    <p className="mb-0 text-white">
                                                                        -
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`card bg-dark border-0 mb-2`}>
                                                <div id={"heading_details"} onClick={() => this.setState({ expandTagDetails: !this.state.expandTagDetails })}>
                                                    <div id={"heading_assets"} onClick={() => this.setState({ expandTagDetails: !this.state.expandTagDetails })}>
                                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={"#collapse_assets"}aria-expanded="true" aria-controls={"collapse_assets"}>
                                                            <div className="text-white"><span className={`far ${this.state.expandTagDetails ? "fa-minus-circle" : "fa-plus-circle"} mr-2`}></span>Tag Details</div>
                                                            <div className="text-info">Showing tag details</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id={"collapse_details"} className={`collapse ${this.state.expandTagDetails ? "show" : ""}`} aria-labelledby={"heading_details"} data-parent="#accordionDetails">
                                                    <div className="card-body">
                                                        <ul className='tags my-1 px-3'>
                                                            {this.state.identifierDetail && this.state.identifierDetail.tags && Array.isArray(this.state.identifierDetail.tags) ? 
                                                            this.state.identifierDetail.tags.map((tag, index) => {
                                                                return (
                                                                    <li key={index}>
                                                                        <span className='tagDark f14'>{tag.key+': '+tag.value}</span>
                                                                    </li>
                                                                )
                                                            })
                                                        : null}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.ruleDetails && this.state.ruleDetails.steps ?
                                                <div className={`card bg-dark border-0 mb-2`}>
                                                    <div id={"heading_details"} onClick={() => this.setState({ expandSteps: !this.state.expandSteps })}>
                                                        <div id={"heading_assets"} onClick={() => this.setState({ expandSteps: !this.state.expandSteps })}>
                                                            <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={"#collapse_assets"}aria-expanded="true" aria-controls={"collapse_assets"}>
                                                                <div className="text-white"><span className={`far ${this.state.expandSteps ? "fa-minus-circle" : "fa-plus-circle"} mr-2`}></span>Steps</div>
                                                                {/* <div className="text-info">Showing tag details</div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id={"collapse_details"} className={`collapse ${this.state.expandSteps ? "show" : ""}`} aria-labelledby={"heading_details"} data-parent="#accordionDetails">
                                                        <div className="card-body">
                                                            {this.state.ruleDetails.overview ?
                                                                <p className="mb-1">{this.state.ruleDetails.overview}</p>
                                                            : null}
                                                            <div className="timeline-style">
                                                                {this.state.ruleDetails.steps.map((stp, index) => {
                                                                    return (
                                                                    <div className={`timelineAlertGroup d-flex`}>
                                                                        <div className="border-right p-2 pr-3">
                                                                            <div className={`font-weight-bold text-center p-2 border-purple text-gray3 mt-3`}>
                                                                                <p className="small mb-0">Step {index+1}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="timeline-space">
                                                                            <span className="border bg-gray3"></span>
                                                                            <div className="bg-gray3"></div>
                                                                        </div>
                                                                        <div className={`align-self-center ml-3 mt-3`}>
                                                                            <div className="d-flex flex-wrap">
                                                                                {stp.description ?
                                                                                    <p className="mb-0 small mr-2">{stp.description}</p>
                                                                                : null}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            : null}
                                        </React.Fragment>
                                    : null}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
	}
}

/**
 * Type of the props used in the component
 */
LandingPage.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log("-",state)
	return {
        providers: state.filters.providers, 
        accounts: state.filters.accounts,
		regions: state.filters.regions && state.filters.regions[0] && state.filters.regions[0].provider_regions ? state.filters.regions[0].provider_regions : [],
        automationPropsDetails: state.filters.automationPropsDetails,
	}
}

export default AppWrapper(LandingPage, mapStateToProps, {
    listRemediation, 
    startRemediation,
    startAutomation,
    getAutomationExecution,
    getIdentifierResults,
    getEventInfo,
    setAutomationPropsDetails,
})