/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from 'react'
import { AppWrapper } from '../../common/AppWrapper'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { listAllProviders, listAllAccounts, listAllRegions, listAllServices } from '../../../actions/commonAction'
import {
	setGovernanceFilter,
	getAssetsByTagOperations,
	getNonComplaintAssets,
	getTagComplianceAggregationCount,
	getTagComplianceTotalCount,
	getTagsByOperationCount,
	getAppliedTagsDayWiseCount,
} from '../../../actions/governance/governanceAction'
import { setSelectedFilter } from '../../../actions/assets/assetsAction'
import { getAppliedTagAssets } from '../../../actions/governance/taggingAction'
import { Spinner } from 'reactstrap'
import Search from '../../common/SearchComponent'
import Table from '../../designComponents/Table/Table'

class AppliedAssets extends Component {
	constructor(props) {
		super(props)
		this.state = {
			providers: [],
			accounts: [],
			regions: [],
			services: [],
			selectedProvider: '',
			selectedAccount: '',
			selectedRegion: '',
			selectedService: '',
			selectedStatus: 'failed',
			selectedDuration: '+7d',
			showLoading: false,
		}
	}

	componentDidMount = () => {
		if (this.props.location && this.props.location.state && this.props.location.state.dashboardFilters) {
			this.setState({
				selectedProvider: this.props.location.state.dashboardFilters.selectedProvider,
				selectedAccount: this.props.location.state.dashboardFilters.selectedAccount,
				selectedRegion: this.props.location.state.dashboardFilters.selectedRegion,
				selectedStatus:
					this.props.location.state.dashboardFilters.selectedStatus === 'fail'
						? 'failed'
						: this.props.location.state.dashboardFilters.selectedStatus === 'pass'
						? 'success'
						: 'all',
				selectedDuration: this.props.location.state.dashboardFilters.selectedDuration,
				selectedService: this.props.location.state.dashboardFilters.selectedService,
			})
		}
		this.setState({ showLoading: true })
		this.props.listAllProviders((promise, providers) => {
			if (promise) {
				this.setState({ providers })
				// if (providers.length === 1) {
				this.setState({ selectedProvider: providers[0].provider_name })
				// }
				this.onSelectFilter('provider')
				this.onSelectFilter()
			} else {
				this.setState({ providers: [] })
			}
		})
	}

	onSelectFilter = from => {
		this.setState({ showLoading: true })
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'all') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if (this.state.selectedRegion !== '' && this.state.selectedRegion !== 'all') {
			params.region = this.state.selectedRegion
		}
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		if (this.state.selectedService !== '' && this.state.selectedService !== 'all') {
			params.service_name = this.state.selectedService
		}
		if (this.state.selectedStatus !== '' && this.state.selectedStatus !== 'all') {
			params.status = this.state.selectedStatus
		}
		if (this.state.selectedRisk !== '' && this.state.selectedRisk !== 'all') {
			params.risk = this.state.selectedRisk
		}
		if (this.state.selectedDuration !== '' && this.state.selectedDuration !== 'all') {
			params.duration = this.state.selectedDuration
		}
		if (from === 'provider') {
			delete params['account_id']
			delete params['region']
			delete params['service_name']
			delete params['provider']
			delete params['risk']
			this.props.listAllAccounts(params, (promise, accounts) => {
				if (promise) {
					this.setState({ accounts })
				} else {
					this.setState({ accounts: [] })
				}
			})

			if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'all') {
				params.provider = this.state.selectedProvider.toLowerCase()
			}
			delete params['status']
			delete params['duration']
			this.props.listAllRegions(params, (promise, regions) => {
				if (promise) {
					this.setState({ regions: regions[0].provider_regions })
				} else {
					this.setState({ regions: [] })
				}
			})
			this.props.listAllServices(params, (promise, services) => {
				if (promise) {
					if (this.props.location && this.props.location.state && this.props.location.state.dashboardFilters) {
						this.setState({
							selectedProvider: this.props.location.state.dashboardFilters.selectedProvider,
							selectedAccount: this.props.location.state.dashboardFilters.selectedAccount,
							selectedRegion: this.props.location.state.dashboardFilters.selectedRegion,
							selectedStatus:
								this.props.location.state.dashboardFilters.selectedStatus === 'fail'
									? 'failed'
									: this.props.location.state.dashboardFilters.selectedStatus === 'pass'
									? 'success'
									: 'all',
							selectedDuration: this.props.location.state.dashboardFilters.selectedDuration,
							selectedService: this.props.location.state.dashboardFilters.selectedService,
						})
					}
					this.setState({ services })
				} else {
					this.setState({ services: [] })
				}
			})
		}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'all') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if (this.state.selectedRegion !== '' && this.state.selectedRegion !== 'all') {
			params.region = this.state.selectedRegion
		}
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		if (this.state.selectedService !== '' && this.state.selectedService !== 'all') {
			params.service_name = this.state.selectedService
		}
		if (this.state.selectedStatus !== '' && this.state.selectedStatus !== 'all') {
			params.status = this.state.selectedStatus
		}
		if (this.state.selectedRisk !== '' && this.state.selectedRisk !== 'all') {
			params.risk = this.state.selectedRisk
		}
		if (this.state.selectedDuration !== '' && this.state.selectedDuration !== 'all') {
			params.duration = this.state.selectedDuration
		}
		this.props.getAppliedTagAssets(params, () => {
			this.setState({ showLoading: false })
		})
	}

	render() {
		return (
			<div className='container-fluid overflow-auto flex-grow-1'>
				<div className='row h-100'>
					<div className='col-lg-12 p-0'>
						<div className='title-section p-3'>
							<div className='row'>
								<div className='col-8 align-self-center'>
									<h6 className='text-white m-0'>Applied Assets</h6>
									<p className='text-white m-0'>
										Vero sed expedita sint tenetur voluptatibus. Tenetur iure ipsum sint et.
									</p>
								</div>
								<div className={`col-4 text-right ${this.state.showLoading ? 'disabled' : ''}`}>
									<Search
										data={this.props.appliesTagAssets && this.props.appliesTagAssets}
										filteredData={filteredArray => {
											if (filteredArray.length) {
												this.setState({ filteredArray })
											}
										}}
									/>
								</div>
							</div>
							<div className='row mt-3'>
								<div className={`col-sm d-md-flex bd-highlight`}>
									<div className='form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3'>
										<label>Provider</label>
										<select
											className='form-control'
											value={this.state.selectedProvider}
											onChange={event => {
												this.setState(
													{
														selectedProvider: event.target.value,
														showLoading: true,
													},
													() => this.onSelectFilter('provider')
												)
											}}
										>
											<option value='all'>All</option>
											{this.state.providers.length &&
												this.state.providers.map(provider => {
													return (
														<option key={provider.provider_id} value={provider.provider_name}>
															{provider.provider_name}
														</option>
													)
												})}
										</select>
									</div>
									<div className='form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3'>
										<label>Account</label>
										<select
											className='form-control'
											value={this.state.selectedAccount}
											disabled={!this.state.accounts.length}
											onChange={event => {
												this.setState(
													{
														selectedAccount: event.target.value,
													},
													() => this.onSelectFilter('account')
												)
											}}
										>
											<option value='all'>All</option>
											{this.state.accounts.length &&
												this.state.accounts.map((account, i) => {
													return (
														<option key={i} value={account.account_id}>
															{account.account_name}
														</option>
													)
												})}
										</select>
									</div>
									<div className='form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3'>
										<label>Region</label>
										<select
											className='form-control'
											value={this.state.selectedRegion}
											onChange={event => {
												this.setState(
													{
														selectedRegion: event.target.value,
													},
													() => this.onSelectFilter('regions')
												)
											}}
										>
											<option value='all'>All</option>
											{this.state.regions &&
												this.state.regions.length &&
												this.state.regions.map((region, i) => {
													return (
														<option key={i} value={region.region}>
															{region.name}
														</option>
													)
												})}
										</select>
									</div>
									<div className='form-group flex-fill bd-highlight mb-sm-0 mr-sm-3 styled-feild'>
										<label>Service</label>
										<select
											className='form-control'
											value={this.state.selectedService}
											onChange={event => {
												this.setState(
													{
														selectedService: event.target.value,
													},
													() => this.onSelectFilter()
												)
											}}
										>
											<option value='all'>All</option>
											{this.state.services &&
												this.state.services.length &&
												_.chain(this.state.services)
													.groupBy('resource_type')
													.map((value, key) => ({ label: key, sub: value }))
													.value()
													.map((service, i) => {
														return (
															<optgroup key={i} label={service.label}>
																{service.sub.map((item, i) => {
																	return (
																		<option key={i} value={service.service_name}>
																			{item.service_name}
																		</option>
																	)
																})}
															</optgroup>
														)
													})}
										</select>
									</div>
									<div className='form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3'>
										<label>Status</label>
										<select
											className='form-control'
											value={this.state.selectedStatus}
											onChange={event => {
												this.setState(
													{
														selectedStatus: event.target.value,
													},
													() => this.onSelectFilter('status')
												)
											}}
										>
											<option value='all'>All</option>
											<option value='success'>Success</option>
											<option value='failed'>Fail</option>
										</select>
									</div>
									<div className='form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3'>
										<label>Duration</label>
										<select
											className='form-control'
											value={this.state.selectedDuration}
											onChange={event => {
												this.setState(
													{
														selectedDuration: event.target.value,
													},
													() => this.onSelectFilter('duration')
												)
											}}
										>
											<option value='7d'>This Week</option>
											<option value='+7d'>Last 1 week</option>
											<option value='+14d'>Last 2 weeks</option>
											<option value='+30d'>Last 1 month</option>
											<option value='+60d'>Last 2 months</option>
											<option value='+90d'>Last 3 months</option>
										</select>
									</div>
								</div>
							</div>
						</div>
						<div className='container-fluid mt-3'>
							<div className='row'>
								<div className='col-lg-12'>
									{this.props.appliesTagAssets && this.props.appliesTagAssets.length && !this.state.showLoading ? (
										<div className='box m-3'>
											<Table
												columns={[
													{
														Header: 'Provider',
														accessor: 'provider',
													},
													{
														Header: 'Account',
														accessor: 'account_id',
													},
													{
														Header: 'Region',
														accessor: 'key_name',
													},
													{
														Header: 'Service',
														accessor: 'service_name',
													},
													{
														Header: 'Asset',
														accessor: 'asset_name',
													},
													{
														Header: 'Tag Group',
														accessor: 'tag_group',
													},
													{
														Header: 'Condition',
														accessor: 'condition_name',
													},
													{
														Header: 'Time',
														accessor: 'modified_at',
													},
													{
														Header: 'Status',
														accessor: 'status',
													},
												]}
												data={this.state.filteredArray.length ? this.state.filteredArray : this.props.appliesTagAssets}
												onClickRow={() => {}}
											/>
										</div>
									) : !this.state.showLoading && this.props.appliesTagAssets && !this.props.appliesTagAssets.length ? (
										<div className='d-flex justify-content-center m-4'>
											<p>No applied assets found.</p>
										</div>
									) : null}
									{this.state.showLoading ? (
										<div className='d-flex justify-content-center m-4'>
											<Spinner className='text-center' color='dark' size='lg' />
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
AppliedAssets.propTypes = {
	listAllProviders: PropTypes.func,
	listAllAccounts: PropTypes.func,
	listAllRegions: PropTypes.func,
	listAllServices: PropTypes.func,
	getAssetsByTagOperations: PropTypes.func,
	getNonComplaintAssets: PropTypes.func,
	getTagComplianceAggregationCount: PropTypes.func,
	getTagComplianceTotalCount: PropTypes.func,
	getTagsByOperationCount: PropTypes.func,
	getAppliedTagAssets: PropTypes.func,
	tags: PropTypes.array,
	setSelectedFilter: PropTypes.func,
	getAppliedTagsDayWiseCount: PropTypes.func,
	tagging: PropTypes.object,
	location: PropTypes.object,
	appliesTagAssets: PropTypes.array,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		providers: state.filters.providers,
		accounts: state.filters.accounts,
		services: state.filters.services,
		selectedFilters: state.governance.selectedFilters,
		tagging: state.governance.tagging,
		appliesTagAssets: state.governance.appliesTagAssets,
	}
}

export default AppWrapper(AppliedAssets, mapStateToProps, {
	setGovernanceFilter,
	listAllProviders,
	getNonComplaintAssets,
	listAllAccounts,
	listAllServices,
	getAssetsByTagOperations,
	getTagComplianceAggregationCount,
	listAllRegions,
	getTagComplianceTotalCount,
	getTagsByOperationCount,
	setSelectedFilter,
	getAppliedTagAssets,
	getAppliedTagsDayWiseCount,
})
