/*************************************************
 * Tvastar
 * @exports
 * @file App.js
 * @author Rajasekar // on 19/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'

import cognitoService from '../services/cognitoService'
import '../assets/fontawesome5/css/all.min.css'
import '../assets/css/bootstrap.min.css'
import '../assets/css/style.scss'
import '../assets/css/dev_style.scss'
import '../assets/css/dev.css'
import Router from '../routes'

class App extends Component {
	constructor(props) {
		super(props)
		this.state = {}
		this._cognitoCheck()
	}

	/**
	 * CognitoService Initialization
	 */
	_cognitoCheck = () => {
		cognitoService.init()
	}

	render() {
		return (
			<React.Fragment>
				<React.StrictMode>
					<Router />
				</React.StrictMode>
			</React.Fragment>
		)
	}
}

/**
 * Type of the props used in the component
 */
App.propTypes = {}

export default connect(null, null)(App)
