/*************************************************
 * Tvastar
 * @exports
 * @file SearchComponent.js
 * @author Rajasekar // on 02/01/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Search extends Component {
	constructor(props) {
		super(props)
		this.state = { 
			searchText: ''
		}
	}

	componentDidUpdate = (prevProps) => {
		if(prevProps.dataSearch !== '' && this.props.dataSearch === '') {
			this.setState({ searchText: '' })
		}
	}

	/**
	 * Handles search using the search text
	 */
	_handleSearch = () => {
		
		let currentList = []
		let newList = []
		if (this.state.searchText !== '') {
			currentList = this.props.data
			newList =
				currentList &&
				currentList.filter(item => {
					let isPresent = []
					isPresent = this.recursiveSearch(item, this.state.searchText)
						.flat()
						.filter(bool => bool === true)
					if (isPresent[0]) {
						return true
					} else {
						return false
					}
				})
		} else {
			newList = this.props.data
			this.props.filteredData(newList)
		}
		if (newList) {
			this.props.filteredData(newList)
		}
		// this.props.searchText(this.state.searchText)
	}

	// function to search every object in array by recurseive
	recursiveSearch = (item, searchValue) => {
		return Object.keys(item).map(key => {
			if (item[key] !== null && typeof item[key] == 'object') {
				return this.recursiveSearch(item[key], searchValue)
				// eslint-disable-next-line valid-typeof
			} else if (typeof item[key] === 'array') {
				return item.map((arrayElement, i) => this.recursiveSearch(arrayElement[i], searchValue))
			} else {
				// if(this.props.searchFilter.includes(key)){
				return item[key] !== null
					? item[key]
							.toString()
							.toLowerCase()
							.indexOf(searchValue.toString().toLowerCase()) > -1
					: false
				// }else{
				// return false;
				// }
			}
		})
	}

	render() {
		return (
			<div className={`input-group mb-2 mr-sm-2 float-right w-50 ${this.props.className}`}>
				
				<div className='input-group-prepend'>
					<div className='input-group-text bg-transparent'>
						<i className={`fal fa-search ${this.props.searchIconColor}`} />
					</div>
				</div>
				<input
					value={this.state.searchText}
					onChange={e => {
						this.setState({ searchText: e.target.value }, () => this._handleSearch())
					}}
					type='text'
					className={`form-control ${this.props.inputClassName}`}
					placeholder='Search'
				/>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
Search.propTypes = {
	data: PropTypes.array,
	filteredData: PropTypes.func,
	applyTags: PropTypes.bool,
	searchText: PropTypes.string
}

export default Search
