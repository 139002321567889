/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file MicroServicesLeftSection.js
 * @author Prakash // on 20/03/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Spinner } from 'reactstrap'

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

import Chart from 'react-apexcharts'
import Graph from "react-graph-vis"; //https://visjs.github.io/vis-network/examples/

import { currentLocaltime, currentUTCtime, getAccountNameFromId, getRegionName, momentConvertionLocalToUtcTime, momentDateGivenFormat, momentTimeConvertionLocalToUtcTime, subDays, subHours, twoDateDiffrence } from '../../../utils/utility'
import { listAllProviders, listAllAccounts, listAllRegions, getAllTagsKeys, getAllTagsValues } from '../../../actions/commonAction'
import { setMicroServicePropsDetails, microServicesGetTopology, getGraphSummary } from '../../../actions/aiops/MicroServicesAction'

let timer = null;

class MicroServicesLeftSection extends Component {
    
    constructor(props) {
        super(props)		
        this.nodeTypeRef = React.createRef()
        this.graphRef = React.createRef();
        this.tagsRef = React.createRef();

		this.state = {
            minMaxHeight: 500,
            options: {
                width: '100%',
                height: 500,
                autoResize: true,
                clickToUse: false,
                layout: {
                    hierarchical: {
                        enabled: false,
                        levelSeparation: 40,
                        nodeSpacing: 300,
                        treeSpacing: 100,
                        blockShifting: true,
                        edgeMinimization: true,
                        direction: "LR",
                        sortMethod: "directed",
                        shakeTowards: "roots",
                        //enabled: false,
                        //direction: "LR",
                        //sortMethod: "hubsize",
                        //levelSeparation: 1000,
                        //nodeSpacing: 400,
                        //treeSpacing: 300,
                        //blockShifting: true,
                        //edgeMinimization: false,
                        //sortMethod: "directed",
                        //parentCentralization: false, 
                    }
                },                
                interaction: {
                    navigationButtons: true,
                    keyboard: true,
                    tooltipDelay: 100,
                    hover: true,
                    multiselect: true,
                    hoverConnectedEdges: true
                },
                configure: {
                    enabled: false,
                    showButton: true
                },
                physics: {
                   
                    forceAtlas2Based: {
                        // theta: 0.5,
                        avoidOverlap: 0.5,
                        gravitationalConstant: -95,
                        centralGravity: 0.01,
                        springLength: 150,
                        springConstant: 0.19,
                        nodeDistance: 150,
                        damping: 1
                    },
                    // hierarchicalRepulsion: {
                    //   centralGravity: 1,
                    //   springLength: 200,
                    //   springConstant: 0.1,
                    //   nodeDistance: 150,
                    //   damping: 1
                    // },
                    
                    minVelocity: 0.75,
                    // maxVelocity: 500,
                    solver: 'forceAtlas2Based',
                    stabilization: {
                        enabled: true,
                        iterations: 1000,
                        updateInterval: 1,
                        onlyDynamicEdges: false,
                        fit: true
                    },
                    // timestep: 0.5,
                    adaptiveTimestep: true,
                    // barnesHut: {
                    //     springConstant: 0,
                    //     avoidOverlap: 0.2
                    // },
                    // barnesHut: {
                    //     "avoidOverlap": 1
                    // },
                    enable: true,
                    wind: { x: 1, y: 0 },
                    
                    // forceAtlas2Based: {
                    //   gravitationalConstant: -26,
                    //   centralGravity: 0.005,
                    //   springLength: 500,
                    //   springConstant: 0.18,
                    //   avoidOverlap: 1.5
                    // },
                },
                nodes: {
                    // fixed: {
                    //   x: true,
                    //   y: true
                    // },
                    // margin: 40,
                    widthConstraint: {
                        maximum: 200,
                    },
                    color: {
                        border: "#000",
                    hover: {
                        border: "darkcyan",
                        background: "red"
                    }
                    },
                    font: {
                        size: 20
                    },
                    shape: "dot",
                    size: 25,
                    scaling: {
                        type: "incomingAndOutgoingConnections",
                        min: 10,
                        max: 60,
                        label: {
                            enabled: true,
                            min: 20,
                            max: 32
                        }
                    },
                    margin: {
                        top: 7,
                        bottom: 7,
                        left: 10,
                        right: 10
                    },
                    mass: 1
                },
                edges: {
                    // smooth: {
                    //     enabled: true,
                    //     roundness: 0.5,
                    //     type: 'cubicBezier',
                    //     forceDirection: 'vertical'
                    // }
                    length: 143,
                    hoverWidth: 3,
                    arrows: {
                        to: {
                            enabled: true,
                            scaleFactor: 1,
                            // type: "arrow",
                            type: "triangle",
                            size: 1,
                        },
                        // from: {
                        //     enabled: true,
                        //     scaleFactor: 0.5,
                        //     type: "arrow",
                        //     size: 1,
                        // }
                    },
                    color: {
                        color: "#000000",
                        highlight: "#000000",
                        hover: "#000000",
                        // inherit: 'from',
                        inherit: false
                    },
                    font: {
                        size: 14,
                        color: "black",
                        // color: 'white',
                        // strokeWidth: 0,
                        // strokeColor: "white"
                        // strokeColor: 'black',
                    },
                    smooth: true,
                }
            },

            isnodeTypeOpen: false,
            // topologyType: 'node',
            allGraphData: {nodes:[], edges: []},
            filteredGraphData: {nodes:[], edges: []},

			fullScreen: false,
            directedGraph: false,
            showAssetDetail: false,
            
            removeNode: '',
            removeEdge: '',

            selectedNodeTypes: [],
            nodeCategory: [],
        }
        
        // this.handleClick = this.handleClick.bind(this);
    }

	componentDidMount = () => {
        if(this.props.providers && this.props.providers.length) {
			this.setState({ providers: this.props.providers, selectedProvider: this.props.providers[0].provider_name },
				() => {
					this.getFilterDetails('provider')
				}
			)
		} else {
			this.props.listAllProviders((promise, providers) => {
				if (promise) {
					this.setState({ providers, selectedProvider: providers[0].provider_name },
						() => {
							this.getFilterDetails('provider')
						}
					)
				} else {
					this.setState(
						{ providers: [],  }, 
						() => {
							this.getFilterDetails()
						}
					)
				}
			})
		}
        
        this.getAllTagsKeys()

        this.getWindowSize()
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 100
        let filterHeight = 150
                
		let outerHeight = (window.outerHeight - browserBorder - headerHeight - filterHeight) + "px"

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }

    getFilterDetails = () => {
        let params = {}
        if(this.state.selectedProvider) {
            params.provider = this.state.selectedProvider
        }

        if(this.props.accounts && this.props.accounts.length) {
            let allAccounts = this.props.accounts
            if(this.state.selectedProvider) {
                allAccounts = this.props.accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
            }
            // if(this.props.accounts.length) {
            //     let accRow = {}
            //     accRow.account_id = "All"
            //     accRow.account_name = "All"
            //     accRow.date = null
            //     accRow.disabled = false
            //     accRow.provider = "All"
            //     allAccounts.unshift(accRow);
            // }
            this.setState({ accounts: allAccounts, selectedAccount: allAccounts.length ? allAccounts[0].account_id : "" },
                () => {
                    if(this.state.selectedAccount) {
                        this.onChangeTime()
                    }
                }
            )
        } else {
            this.props.listAllAccounts(params, (promise, allAccounts) => {
                if (promise) {						
                    let filterdAccounts = allAccounts
                    if(this.state.selectedProvider) {
                        filterdAccounts = this.props.accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
                    }
                    // if(filterdAccounts.length) {
                    //     let accRow = {}
                    //     accRow.account_id = "All"
                    //     accRow.account_name = "All"
                    //     accRow.date = null
                    //     accRow.disabled = false
                    //     accRow.provider = "All"
                    //     filterdAccounts.unshift(accRow);
                    // }						
                    this.setState({ accounts: filterdAccounts, selectedAccount: filterdAccounts.length ? filterdAccounts[0].account_id : "" },
                    () => {
                        if(this.state.selectedAccount) {
                            this.onChangeTime()
                        }
                    }
                )
                }
            })
        }

        if(this.props.regions && this.props.regions.length) {
            let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
            this.setState({ regions: providerRegions })
        } else {
            params.provider = "aws"
            this.props.listAllRegions(params, (promise, regions) => {
                if (promise) {
                    let providerRegions = regions && regions[0] && regions[0].provider_regions
                    if(providerRegions) {
                        let provider_regions_row = {}
                        provider_regions_row.availability_zones = []
                        provider_regions_row.city = "All"
                        provider_regions_row.country = "All"
                        provider_regions_row.geography = "All"
                        provider_regions_row.name = "All"
                        provider_regions_row.region = "All"
                        provider_regions_row.region_id = "All"
                        provider_regions_row.state = "All"
                        providerRegions.unshift(provider_regions_row);
                    }
                    this.setState({ regions: providerRegions })
                } else {
                    this.setState({ regions: [] })
                }
            })
        }
        let length = 30

        let sliderEndDate = "2023-03-24 23:59:59" //currentLocaltime()
        let sliderStartDate = momentDateGivenFormat(subDays(sliderEndDate, length), 'YYYY-MM-DD 00:00:00');
        let brushStartDate = momentDateGivenFormat(subDays(sliderEndDate, 1), 'YYYY-MM-DD 00:00:00');

        let dateArray = []
        let month = ''
        for(let x=1;x<=length;x++) {
            let date = subDays(sliderEndDate, (length-x))
            
            let value = 5
            if(month === '' || month !== momentDateGivenFormat(date, 'MM')) {
                month = momentDateGivenFormat(date, 'MM')
                value = 10
            }
            if(new Date(date).getTime() <= new Date(sliderEndDate).getTime()) {
                dateArray.push([date, value])
            }
        }

        this.setState({sliderStartDate, sliderEndDate, brushStartDate, brushEndDate: sliderEndDate },
            () => {
                this.brushSliderChart(dateArray)
            }
        )
    }

    brushSliderChart = (dateArray) => {
        let brushSeries = []
        let brushRow = {}
        brushRow.data = [
            {x: momentDateGivenFormat(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm'), y: 0},
            {x: momentDateGivenFormat(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm'), y: 0}
        ]
        brushSeries.push(brushRow)

        let endTIme = momentDateGivenFormat(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:ss')
        let time = endTIme
        let brushHours = 1
        let diff = twoDateDiffrence(this.state.sliderEndDate, this.state.sliderStartDate)
        
        if(diff > 1 && diff <= 3) {            
            brushHours = 6
        } else if(diff > 3 && diff <= 7) {
            brushHours = 24 //3
        } else if(diff > 7 && diff <= 14) {
            brushHours = 36 //6
        } else if(diff > 14 && diff <= 30) {
            brushHours = 48 //12
        } else if(diff > 30) {
            brushHours = 96 //24
        }

        let brushStartTime = subHours(time, brushHours)
        let brushEndTime = time

        let optionsBrush = {
            chart: {
                background: '#fff',
                id: 'chart1',
                type: 'line',
                sparkline: {
                    enabled: true
                },
                zoom: {
                    enabled: false,
                },
                brush:{
                    // targets: ['bar1', 'bar2'],
                    target: "",
                    enabled: true,
                    autoScaleYaxis: true,
                },
                selection: {
                    enabled: true,
                    fill: {
                        color: "#039BE5",
                        opacity: 1,
                    },
                    xaxis: {
                        min: new Date(momentDateGivenFormat(brushStartTime, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                        max: new Date(momentDateGivenFormat(brushEndTime, 'YYYY-MM-DD HH:mm:ss')).getTime()
                    }
                },
                events: {
                    selection: (chartContext, { xaxis, yaxis }) => {
                        this.brushSelection(xaxis)
                    }
                }
            },
            markers: {
                size: 0
            },
            colors: ['#F44336'],
            fill: {
                type: 'solid',
                color: "#000",
                opacity: 1,
            },
            xaxis: {
                type: 'datetime',
                tooltip: {
                    enabled: false
                }
            },
            yaxis: {
                show: false,
                tickAmount: 2
            },
            annotations: {
                yaxis: [
                  {
                    y: 1,
                    borderColor: '#000',
                    label: {
                      borderColor: '#00E396',
                      style: {
                        color: '#fff',
                        background: '#00E396'
                      },
                      text: ""
                    }
                  }
                ]
            }
        }

        this.setState({ brushSeries, optionsBrush })
    }

    brushSelection = (xaxis) => {
        clearTimeout(timer)
        let brushStartDate = momentDateGivenFormat(xaxis.min, 'YYYY-MM-DD HH:mm:ss')
        let brushEndDate = momentDateGivenFormat(xaxis.max, 'YYYY-MM-DD HH:mm:ss')

        if(brushStartDate && brushStartDate !== "" && brushEndDate !== this.state.sliderEndDate) {
            this.setState({ brushStartDate, brushEndDate },
                () => {
                    timer = setTimeout(() => {
                        this.onChangeTime()

                    }, 3000)
                    // this.getAttribute()
                }
            )
        } else {
            // if(brushStartDate && brushEndDate) {
            //     this.setState({ brushStartDate, brushEndDate },
            //         () => {
            //             this.microServicesGetTopology()
            //             this.getAttribute()
            //         }
            //     )
            // }
        }
    }

    onChangeTime = () => {
        this.microServicesGetTopology()
        this.getGraphSummary()

        let obj = {}
        obj.start_time = this.state.brushStartDate
        obj.end_time = this.state.brushEndDate
        
        obj.selectedProvider = this.state.selectedProvider
        obj.selectedAccount = this.state.selectedAccount
        obj.selectedRegion = this.state.selectedRegion
        obj.selectedTags = this.state.selectedTags

        this.props.setMicroServicePropsDetails("microSericesFilters", obj)
    }
    
    getAttribute = () => {
        var attributes = document.getElementsByClassName("apexcharts-selection-rect") && document.getElementsByClassName("apexcharts-selection-rect")[0] &&document.getElementsByClassName("apexcharts-selection-rect")[0].attributes;
        if(attributes) {
            let width = attributes.width.value
            let x1 = parseInt(attributes.x.value) + parseInt(30)
            let x2 = parseInt(attributes.x.value) + parseInt(width) + parseInt(50)

            let leftLPosition = x1
            let rightLPosition = x2
            // let diff = twoDateDiffrence(this.state.sliderEndDate, this.state.sliderStartDate)
            // if(diff < 8) {
            //     leftLPosition = x1 - 20
            //     if(diff < 4) {
            //         leftLPosition = x1 - 40
            //     }
            //     rightLPosition = x2 + 40
            // }

            var d1 = document.getElementById('leftSliderSpan');
            d1.style.position = "absolute";
            d1.style.left = leftLPosition+'px';
            d1.style.top = '210px';
            d1.style.color = '#039BE5';

            var d2 = document.getElementById('rightSliderSpan');
            d2.style.position = "absolute";
            d2.style.left = rightLPosition+'px';
            d2.style.top = '210px';
            d2.style.color = '#039BE5';
        }
    }

    componentDidUpdate = (prevProps) => {
        // if(this.props.caseDetails && Object.entries(this.props.caseDetails).length && prevProps.caseDetails !== this.props.caseDetails) {
        //     this.microServicesGetTopology()
        // }
    }

    onSearch = () => {
        this.microServicesGetTopology()
        this.getGraphSummary()

        let obj = {}
        obj.start_time = this.state.brushStartDate
        obj.end_time = this.state.brushEndDate
        obj.selectedProvider = this.state.selectedProvider
        obj.selectedAccount = this.state.selectedAccount
        obj.selectedRegion = this.state.selectedRegion
        obj.selectedTags = this.state.selectedTags
        this.props.setMicroServicePropsDetails("microSericesFilters", obj)
    }

    onReset = () => {
        this.setState({
            selectedAccount: "",
            selectedRegion: [],
            selectedTags: [],
            filteredGraphData: {nodes:[], edges: []},
            brushEndDateTime: currentLocaltime(),
            brushStartDate: momentDateGivenFormat(subDays(currentLocaltime(), 1), 'YYYY-MM-DD 00:00:00')
        })
    }    

    getGraphSummary = () => {
        let params = {}
        params.provider = "aws"
        params.account_id = this.state.selectedAccount
        if(this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
            params.region = this.state.selectedRegion
        }
        if(this.state.selectedTags && this.state.selectedTags.length) {
            params.tags = this.state.selectedTags
        }
        params.errors = true
        params.anomalies = true
        params.requests = true
        params.latency = true
        params.aggregate_by = "microservice"
        // params.start_time =  "2023-03-23 00:00:00"
        // params.end_time =  "2023-03-23 23:59:59"
        params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartDate, 'YYYY-MM-DD HH:mm:00')
        params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndDate, 'YYYY-MM-DD HH:mm:00')
        this.props.getGraphSummary(params, (promise, response) => {
            if(promise) {
                this.setState({ microServicesList: response, filteredMicroServicesList: response },
                    () => {
                        let obj = response
                        this.props.setMicroServicePropsDetails('observabilityMicroServicesList', obj)
                    }
                )
            } else {
                this.setState({ microServicesList: [] })
            }
        })
    }

    edgeTitle = () => {
        let data = '<b> Sed in semper ex. Nulla sed finibus orci, eget semper odio.</b><br> <b>  class="d-flex">Aenean viverra bibendum ultrices viverra bibendum ultrices..</b>'

        return data
    }
    
    microServicesGetTopology = () => {
        let params = {}
        params.provider = this.state.selectedProvider ? this.state.selectedProvider.toLowerCase() : "aws"
        if(this.state.selectedAccount && this.state.selectedAccount.length) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length) {
            params.region = this.state.selectedRegion
        }
        if(this.state.selectedTags && this.state.selectedTags.length) {
            params.tags = this.state.selectedTags
        }
        params.errors =  true
        params.anomalies =  true
        params.requests = true
        params.latency = true
        // params.start_time =  "2023-03-23 00:00:00"
        // params.end_time = "2023-03-24 23:59:59"
        params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartDate, 'YYYY-MM-DD HH:mm:00')
        params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndDate, 'YYYY-MM-DD HH:mm:00')

        this.props.microServicesGetTopology(params, (promise, result) => {
            if(promise) {
                let nodeCategory = []
                let topologyGraphData = {}
                let nodes = []
                let edges = []
                let asset = []

                if(result.edge_details && result.edge_details.length) {
                    result.edge_details.forEach((vertex, i) => {
                        let edgeRow = {}
                        
                        edgeRow.from = result.vertex_details[vertex.start] && result.vertex_details[vertex.start].microservice_id
                        edgeRow.to = result.vertex_details[vertex.end] && result.vertex_details[vertex.end].microservice_id
                        if(vertex.data) {
                            let labels = []
                            let labelString = ""
                            Object.entries(vertex.data).forEach(([key, value], index) => {
                                if(key === "errors" || key === "latency") {
                                    if(key === "errors") {
                                        key = "Errors"
                                    } else if(key === "anomalies") {
                                        key = "A"
                                    } else if(key === "requests") {
                                        key = "R"
                                    } else if(key === "latency") {
                                        key = "Latency"
                                    }
                                    let labelRow = {}                                
                                    labelRow.key = key
                                    labelRow.value = value
                                    labels.push(labelRow)
                                    if(value) {
                                        labelString += key+":"+value+"  "
                                    }
                                    // if(Object.entries(vertex.data).length !== index+1) {
                                    //     labelString += ",   "
                                    // }
                                }

                            })

                            edgeRow.label = labelString
                            edgeRow.color = '#FF9900'
                        } else {
                            // edgeRow.label = "A:10 E:2 L:3 R:5"
                            edgeRow.label = vertex.label
                            edgeRow.color = '#FF9900'
                        }
                        // edgeRow.title = "--------------------------------"
                        edgeRow.length = 300
                        edgeRow.title = vertex.label
                        edgeRow.dashes = false
                        if(i === 0) {
                            // edgeRow.dashes = true
                        }
                        edgeRow.font ={
                            color: '#FF6F00',
                            align: "top"
                        }
                        // edgeRow.color = '#FF9900'
                        edges.push(edgeRow)
                        
                        let nodeRow = {}
                        
                        if(!nodes.filter(arr => arr.id === result.vertex_details[vertex.start].microservice_id).length) {
                            nodeRow = {}
                            nodeRow.id = result.vertex_details[vertex.start].microservice_id
                            nodeRow.name = result.vertex_details[vertex.start].microservice_name
                            nodeRow.shape = "custom"
                            nodeRow.borderWidth = 5
                            nodeRow.ctxRenderer = ({ ctx, id, x, y, state: { selected, hover }, style }) => {
                                let message = nodeRow.name
                                let size = 30
                                // return this.drawDoubleCircle(ctx, id, x, y, style, message, size)
                                let lines = []
                                let color = "" 
                                let value = 0
                                if(result.error_count && Object.entries(result.error_count).length && result.error_count.hasOwnProperty(nodeRow.id)) { 
                                    color = "#F75E3F"
                                    value = result.error_count[nodeRow.id] && result.error_count[nodeRow.id].count ? result.error_count[nodeRow.id].count : 0

                                    let lineRow = {}
                                    lineRow.label = "Errors"
                                    lineRow.value = result.error_count[nodeRow.id] && result.error_count[nodeRow.id].count ? result.error_count[nodeRow.id].count : 0
                                    lines.push(lineRow)
                                }
                                if(!value && result.anomaly_count && Object.entries(result.anomaly_count).length && result.anomaly_count.hasOwnProperty(nodeRow.id)) {
                                    color = "#e0ba0e"
                                    let lineRow = {}
                                    lineRow.label = "Anomaly"
                                    lineRow.value = result.anomaly_count[nodeRow.id] && result.anomaly_count[nodeRow.id].count ? result.anomaly_count[nodeRow.id].count : 0
                                    lines.push(lineRow)
                                } else if(!value) {
                                    color = ""
                                    value = ""
                                    lines = []
                                }

                                return this.drawCircle(ctx, id, x, y, style, message, size, color, lines)
                            }
                            let count = 0;
                            let severity = 'Critical'
                            let color = '#d3d3d3'

                            nodeRow.severity = severity                            
                            nodeRow.color = color
                            // nodeRow.size = size
                            nodeRow.count = count
                            nodeRow.fontSize = 12
                            nodeRow.fontWeight = 600
                            nodeRow.title = nodeRow.name

                            nodes.push(nodeRow)                 
                        }
                    })
                    result.edge_details.forEach((vertex, i) => {  
                        let nodeRow = {}
                        if(!nodes.filter(arr => arr.id === result.vertex_details[vertex.end].microservice_id).length) {
                            nodeRow = {}
                            
                            nodeRow.id = result.vertex_details[vertex.end].microservice_id
                            nodeRow.name = result.vertex_details[vertex.end].microservice_name
                            // nodeRow.custom_label = nodeRow.name
                            nodeRow.shape = "custom"
                            nodeRow.borderWidth = 5
                            nodeRow.ctxRenderer = ({ ctx, id, x, y, state: { selected, hover }, style }) => {
                                let message = nodeRow.name
                                let size = 30
                                // return this.drawDoubleCircle(ctx, id, x, y, style, message, size)
                                let lines = []
                                let color = ""
                                let value = 0
                                if(result.error_count && Object.entries(result.error_count).length && result.error_count.hasOwnProperty(nodeRow.id)) {
                                    color = "#F75E3F"
                                    value = result.error_count[nodeRow.id] && result.error_count[nodeRow.id].count ? result.error_count[nodeRow.id].count : 0

                                    let lineRow = {}
                                    lineRow.label = "Errors"
                                    lineRow.value = result.error_count[nodeRow.id] && result.error_count[nodeRow.id].count ? result.error_count[nodeRow.id].count : 0

                                    lines.push(lineRow)
                                } 

                                if(!value && result.anomaly_count && Object.entries(result.anomaly_count).length && result.anomaly_count.hasOwnProperty(nodeRow.id)) {                                    
                                    color = "#e0ba0e"
                                    let lineRow = {}
                                    lineRow.label = "Anomaly"
                                    lineRow.value = result.anomaly_count[nodeRow.id] && result.anomaly_count[nodeRow.id].count ? result.anomaly_count[nodeRow.id].count : 0
                                    lines.push(lineRow)
                                }else if(!value) {
                                    color = ""
                                    value = ""
                                    lines = []
                                }

                                return this.drawCircle(ctx, id, x, y, style, message, size, color, lines)
                            }

                            let count = 0;
                            let severity = 'Critical'
                            let color = '#d3d3d3'
                            
                            nodeRow.shape = "custom"
                            nodeRow.borderWidth = 5
                            
                            nodeRow.severity = severity
                            nodeRow.color = color
                            // nodeRow.size = size
                            nodeRow.count = count
                            
                            nodeRow.fontSize = 12
                            nodeRow.fontWeight = 600
                            nodeRow.title = nodeRow.name
                            
                            nodes.push(nodeRow)                 
                        }
                    })
                }

                topologyGraphData['nodes'] = nodes
                topologyGraphData['edges'] = edges

                asset = asset.filter((thing, index, self) =>
                    index === self.findIndex((t) => (
                        t.label === thing.label && t.id === thing.id
                    ))
                )
                
                let selectedNodeTypes = []
                

                this.setState({ allGraphData: topologyGraphData, filterAsset: asset, nodeCategory, selectedNodeTypes },
                    () => this.updateInput()
                )
            } else {
                this.setState({ allGraphData: {nodes:[], edges: []}, filteredGraphData: {nodes:[], edges: []}, nodeCategory: [] })
            }
        })
    }
    
    getVertexAlertDetails = (microservice_name, result) => {
        let alertDetails = result.alert_count[microservice_name] ? result.alert_count[microservice_name] : {}
        return alertDetails
    }
    
    changeGraphOption = (label) => {
        let myConfig = this.state.myConfig
        if(label === 'directed') {
            myConfig = Object.assign({}, myConfig, {                
                directed: this.state.directedGraph
            });
        }

        this.setState({ myConfig })
    }
    
    resetGraph = () => {
        let selectedNodeTypes = []
        // if(this.state.nodeCategory.includes('Compute')) {
        //     selectedNodeTypes.push('Compute')
        // }
        // if(this.state.nodeCategory.includes('Database')) {
        //     selectedNodeTypes.push('Database')
        // }        
        // if(this.state.nodeCategory.includes('Storage')) {
        //     selectedNodeTypes.push('Storage')
        // }
        this.setState({ fullScreen: false, filteredGraphData: {nodes:[], edges: []}, options: {}, events: {}, selectedNodeTypes }, 
            () => {
                this.updateInput()
                this.props.setMicroServicePropsDetails('fullScreen', false)
                this.props.fullScreenOption(false)
            }
        )
    }
    
    onToggleFullScreen = () => {
        this.setState({ fullScreen: !this.state.fullScreen },
            () => {
                this.props.fullScreenOption(this.state.fullScreen)
                this.props.setMicroServicePropsDetails('fullScreen', this.state.fullScreen)
            }
        )
    }
    
    addRemoveNodes = (type) => {
        let data = this.state.filteredGraphData
        let edges = data.edges
        let nodes = data.nodes
        if(type === 'remove') {
            if(nodes.length > 1) {
                let removeNode = nodes[nodes.length-1].id
                edges = edges.filter(arr => arr.source !== removeNode)
                edges = edges.filter(arr => arr.target !== removeNode)
                nodes = nodes.filter(arr => arr.id !== removeNode)
            }
        } else {
            let allData = this.state.allGraphData
            let allEdges = allData.edges
            let allNodes = allData.nodes
            if(nodes.length < allNodes.length) {
                let addNode = allNodes[nodes.length].id
                allNodes = allNodes.filter(arr => arr.id === addNode)
                nodes = [...nodes, ...allNodes];
                
                let formNewEdgesSource = allEdges.filter(arr => arr.source === addNode)
                let formNewEdgesTarget = allEdges.filter(arr => arr.target === addNode)                
                allEdges = [...formNewEdgesSource, ...formNewEdgesTarget];

                let formEdges = []
                allEdges.length && allEdges.forEach(item => {
                    if(nodes.filter(arr => arr.id === item.source).length) {
                        formEdges.push(item)
                    }
                })
                
                edges = [...edges, ...formEdges];

            }
        }
        data = Object.assign({}, data, {
            edges: edges,
            nodes: nodes
        });

        this.setState({ filteredGraphData: data }, 
            // () => this.updateInput('from_remove_edges')
        )
    }

    decorateGraphNodesWithInitialPositioning = nodes => (nodes.map(n =>
        Object.assign({}, n, {
          x: n.x || Math.floor(Math.random() * 500),
          y: n.y || Math.floor(Math.random() * 500)
        })
    ));

    updateInput = () => {
        // this.setState({ filteredGraphData: {nodes:[], edges: []} })
        let data = this.state.allGraphData

        let edges = data.edges
        let nodes = data.nodes
        // let existNodes = []
        // let arrayDiff = this.state.nodeCategory.filter(x => !this.state.selectedNodeTypes.includes(x));
        // if(this.state.selectedNodeTypes && this.state.selectedNodeTypes.length) {
        //     this.state.selectedNodeTypes.forEach(cat => {
        //         let newNodes = nodes
        //         newNodes.filter((item) => {
        //             if(item.node_category.indexOf(cat) >= 0) {
        //                 existNodes.push(item)
        //             }
        //         })
        //     })

        //     arrayDiff.length && arrayDiff.forEach(cat => {
        //         nodes.filter((item) => {
        //             if(item.node_category.indexOf(cat) >= 0 || !item.node_category.length) {
        //                 edges = edges.filter(arr => arr.from !== item.id)
        //                 edges = edges.filter(arr => arr.to !== item.id)
        //             }
        //         });
        //     })
        //     nodes = existNodes 
        // }

        // nodes.length && nodes.forEach((item, i) => {})

        let graphData = {
            edges: edges,
            nodes: nodes
            // nodes: nodes
        }

        let options = {
            width: '100%',
            height: this.state.minMaxHeight,
            autoResize: true,
            clickToUse: false,
            layout: {
                hierarchical: {
                    enabled: false,
                    levelSeparation: 40,
                    nodeSpacing: 300,
                    treeSpacing: 100,
                    blockShifting: true,
                    edgeMinimization: true,
                    direction: "LR",
                    sortMethod: "directed",
                    shakeTowards: "roots",
                    //enabled: false,
                    //direction: "LR",
                    //sortMethod: "hubsize",
                    //levelSeparation: 1000,
                    //nodeSpacing: 400,
                    //treeSpacing: 300,
                    //blockShifting: true,
                    //edgeMinimization: false,
                    //sortMethod: "directed",
                    //parentCentralization: false, 
                }
            },                
            interaction: {
                navigationButtons: true,
                // keyboard: true,
                tooltipDelay: 100,
                hover: true,
                // multiselect: true,
                // hoverConnectedEdges: true
            },
            configure: {
                enabled: false,
                showButton: true
            },
            physics: {
               
                forceAtlas2Based: {
                    // theta: 0.5,
                    avoidOverlap: 0.5,
                    gravitationalConstant: -138,
                    centralGravity: 0.02,
                    springLength: 150,
                    springConstant: 0.19,
                    nodeDistance: 150,
                    damping: 1
                },
                // hierarchicalRepulsion: {
                //   centralGravity: 1,
                //   springLength: 200,
                //   springConstant: 0.1,
                //   nodeDistance: 150,
                //   damping: 1
                // },
                
                minVelocity: 0.75,
                // maxVelocity: 500,
                solver: 'forceAtlas2Based',
                stabilization: {
                    enabled: true,
                    iterations: 1000,
                    updateInterval: 1,
                    onlyDynamicEdges: false,
                    fit: true
                },
                // timestep: 0.5,
                adaptiveTimestep: true,
                // barnesHut: {
                //     springConstant: 0,
                //     avoidOverlap: 0.2
                // },
                // barnesHut: {
                //     "avoidOverlap": 1
                // },
                enable: true,
                wind: { x: 1, y: 0 },
                
                // forceAtlas2Based: {
                //   gravitationalConstant: -26,
                //   centralGravity: 0.005,
                //   springLength: 500,
                //   springConstant: 0.18,
                //   avoidOverlap: 1.5
                // },
            },
            nodes: {
                // fixed: {
                //   x: true,
                //   y: true
                // },
                // margin: 40,
                widthConstraint: {
                    maximum: 200,
                },
                color: {
                    border: "#000",
                hover: {
                    border: "darkcyan",
                    background: "red"
                }
                },
                font: {
                    size: 20
                },
                shape: "dot",
                size: 25,
                scaling: {
                    type: "incomingAndOutgoingConnections",
                    min: 10,
                    max: 60,
                    label: {
                        enabled: true,
                        min: 20,
                        max: 32
                    }
                },
                margin: {
                    top: 7,
                    bottom: 7,
                    left: 10,
                    right: 10
                },
                mass: 1
            },
            edges: {
                // smooth: {
                //     enabled: true,
                //     roundness: 0.5,
                //     type: 'cubicBezier',
                //     forceDirection: 'vertical'
                // }
                length: 143,
                hoverWidth: 3,
                arrows: {
                    to: {
                        enabled: true,
                        scaleFactor: 1,
                        type: "arrow",
                        size: 1,
                    },
                    // from: {
                    //     enabled: true,
                    //     scaleFactor: 0.5,
                    //     type: "arrow",
                    //     size: 1,
                    // }
                },
                color: {
                    color: "#000000",
                    highlight: "#000000",
                    hover: "#000000",
                    // inherit: 'from',
                    inherit: false
                },
                font: {
                    size: 12,
                    color: "black",
                    strokeWidth: 0,
                    // strokeColor: "white"
                },
                smooth: true,
            },
            tooltip: {
                delay: 300,
                fontColor: '#fff',
                fontSize: 14,
                fontFace: 'Helvetica Neue, Helvetica, Arial, sans-serif',
                color: {
                    border: '#666',
                    background: '#222'
                },
                template: function(item) {
                    return '<div class="vis-tooltip">' +
                        '<div class="vis-tooltip-content">' +
                        '<p>' + item.label + '</p>' +
                        '</div>' +
                        '</div>';
                }
            }
        }

        let events = {
            hoverNode: el => {
                const node = el.node;
                // const node = nodes.get(event.node);
                // const tooltip = `
                //     <div>
                //     <h4>${node.label}</h4>
                //     <p>Node ID: ${node.id}</p>
                //     </div>
                // `;
                const currentGraph = this.graphRef.current;
                const network = currentGraph.Network;
                const tree = document.getElementById("data-tree-container");
                if (tree) {
                    const elements = document.getElementsByClassName(
                        "dataTree_" + node
                    );
                    if (elements) {
                        [...elements].forEach(elVal => {
                            elVal.style.fontWeight = 600;
                        });
                    }
                    network.body.nodes[node].options.borderWidth = 6;
                    const optionsContainer = document.getElementById(
                        "graph-options-container"
                    );
                    if (optionsContainer) {
                        optionsContainer.style.visibility = "hidden";
                        optionsContainer.style.display = "none";
                    }
                    const contextMenuContainer = document.getElementById(
                        "graph-contextmenu-container"
                    );
                    const contextMenuEdgeContainer = document.getElementById(
                        "graph-contextmenu-edge-container"
                    );
                    if (contextMenuContainer) {
                        contextMenuContainer.style.visibility = "hidden";
                        contextMenuContainer.style.display = "none";
                    }
                    if (contextMenuEdgeContainer) {
                        contextMenuEdgeContainer.style.visibility = "hidden";
                        contextMenuEdgeContainer.style.display = "none";
                    }
                    network.unselectAll();
                }
            },
            blurNode: el => {
                const node = el.node;
                const currentGraph = this.graphRef.current;
                const network = currentGraph.Network;
                const tree = document.getElementById("data-tree-container");
                if (tree) {
                const elements = document.getElementsByClassName(
                    "dataTree_" + node
                );
                if (elements) {
                    [...elements].forEach(elValue => {
                    elValue.style.fontWeight = 100;
                    });
                }
                network.body.nodes[node].options.borderWidth = 3;
                }
            },
            hoverEdge: el => {
                const tree = document.getElementById("data-tree-container");
                if (tree) {
                const currentGraph = this.graphRef.current;
                const network = currentGraph.Network;
                const nodeParent = network.body.edges[el.edge].fromId;
                Object.values(network.body.edges).forEach(edge => {
                    if (
                    edge.fromId === nodeParent &&
                    edge.options.label === network.body.edges[el.edge].options.label
                    ) {
                    const index = network.body.edgeIndices.findIndex(
                        id => id === edge.id
                    );
                    const obj = document.getElementById("dataTree_edge" + index);
                    if (obj) {
                        obj.style.fontWeight = 600;
                    }
                    }
                });
                }
            },
            blurEdge: el => {
                const tree = document.getElementById("data-tree-container");
                if (tree) {
                const currentGraph = this.graphRef.current;
                const network = currentGraph.Network;
                const nodeParent = network.body.edges[el.edge].fromId;
                Object.values(network.body.edges).forEach(edge => {
                    if (
                    edge.fromId === nodeParent &&
                    edge.options.label === network.body.edges[el.edge].options.label
                    ) {
                    const index = network.body.edgeIndices.findIndex(
                        id => id === edge.id
                    );
                    const obj = document.getElementById("dataTree_edge" + index);
                    if (obj) {
                        obj.style.fontWeight = 100;
                    }
                    }
                });
                }
            },
            click: event => {
                const { nodes, edges } = event;
                const contextMenuContainer = document.getElementById(
                "graph-contextmenu-container"
                );
                const contextMenuEdgeContainer = document.getElementById(
                "graph-contextmenu-edge-container"
                );
                if (contextMenuContainer) {
                contextMenuContainer.style.visibility = "hidden";
                contextMenuContainer.style.display = "none";
                }
                if (contextMenuEdgeContainer) {
                contextMenuEdgeContainer.style.visibility = "hidden";
                contextMenuEdgeContainer.style.display = "none";
                }
                this.getSelectedNodeEdgeDetails(nodes, edges)
                return { nodes, edges };
            },
            // afterDrawing: ctx => {
            //     var percent = 50
            //     const currentGraph = this.graphRef.current;
            //     const network = currentGraph.Network;
            //     var pos = network.getPositions([1, 2]);
            //     ctx.strokeStyle = ctx.filStyle = 'green';
            //     ctx.moveTo(pos[1].x, pos[1].y);
            //     ctx.lineTo(pos[1].x + (pos[2].x-pos[1].x)*percent/100, pos[1].y + (pos[2].y - pos[1].y)*percent/100);
            //     ctx.fill();
            //     ctx.stroke();   
            // },
            afterDrawing: ctx => ctx,
            // beforeDrawing: ctx => ctx,
            beforeDrawing: ctx => {
                const currentGraph = this.graphRef.current;
                const network = currentGraph.Network;
                let nodeIds = network.body.nodeIndices
                let nodeDetails = network.body.nodes 

                Object.entries(nodeDetails).forEach(([key, value]) => {
                    if(nodeIds.includes(key)) {
                        if(value.options && value.options.name) {
                            var nodeId = value.options.id;
                            var nodePosition = network.getPositions([nodeId]);
                            ctx.fillStyle = '#24A597';
                            ctx.font = "16px Muli";
                            if(value.options.shape === 'image') {
                                ctx.fillText(value.options.name, nodePosition[nodeId].x - 40, nodePosition[nodeId].y - 40);
                            } else if(value.options.shape === 'custom') {
                                ctx.fillText(value.options.name, nodePosition[nodeId].x - 30, nodePosition[nodeId].y - 50);
                            } else {
                                ctx.fillText(value.options.name, nodePosition[nodeId].x - 30, nodePosition[nodeId].y - 50);
                            }
                        }
                    }
                    // let nodeItem = nodeDetails.item
                })
                // body = network.body
                // var nodeId = 1;
                // var nodePosition = network.getPositions([nodeId]);

                // ctx.fillStyle = '#EDEDED';
                // ctx.font = "20px";
                
                // var scale = network.getScale();                
                // var visibleFontSize = 20;
                // if (visibleFontSize > 30) {
                //   ctx.font = 30/scale + "px Arial";  
                // }
                // else {
                //   ctx.font = 20 + "px Arial";
                // }
                
                
                // ctx.fillText('hello world', nodePosition[nodeId].x - 20, nodePosition[nodeId].y - 50);
                
            },
            selectNode: clickEvent => {
                const { nodes } = clickEvent;
                const nodeId = nodes[0];
                const currentGraph = this.graphRef.current;
                currentGraph.lastClickedNode = nodeId;
    
                if (currentGraph) {
                const network = currentGraph.Network;
                // const graphOptionsRef = this.graphOptionsRef.current;
                if (network && nodeId) {
                    if (network.body.nodes[nodeId]) {
                    network.body.nodes[nodeId].options.borderWidth = 6;
                    }
                }
                if (
                    nodeId &&
                    // nodeId.includes("cluster:") &&
                    network.isCluster(nodeId)
                ) {
                    network.openCluster(nodeId);
                    network.setOptions({ physics: true });
                    setTimeout(() => {
                    network.setOptions({ physics: false });
                    }, 300);
                } else if (nodeId) {
                    // graphOptionsRef.setState({});
                    // network.focus(nodeId, {
                    //   scale: 1,
                    //   animation: {
                    //     duration: 120,scc
                    //     easingFunction: 'easeInOutQuad',
                    //   }
                    // });
                    // window.setTimeout(() => {
                    //   const optionsContainer = document.getElementById(
                    //     "graph-options-container"
                    //   );
                    //   optionsContainer.style.visibility = "visible";
                    //   optionsContainer.style.display = "block";
                    //   optionsContainer.style.right = "0px";
                    //   optionsContainer.style.top = "43px";
                    //   // neighbourhoodHighlight(clickEvent, graphOptionsRef.props.getNodes(), network);
                    // }, 220);
                }
                }
            },
            deselectNode: (/* clickEvent */) => {
                const optionsContainer = document.getElementById(
                "graph-options-container"
                );
                if (optionsContainer) {
                optionsContainer.style.visibility = "hidden";
                optionsContainer.style.display = "none";
                }
                const contextMenuContainer = document.getElementById(
                "graph-contextmenu-container"
                );
                const contextMenuEdgeContainer = document.getElementById(
                "graph-contextmenu-edge-container"
                );
                if (contextMenuContainer) {
                contextMenuContainer.style.visibility = "hidden";
                contextMenuContainer.style.display = "none";
                }
                if (contextMenuEdgeContainer) {
                contextMenuEdgeContainer.style.visibility = "hidden";
                contextMenuEdgeContainer.style.display = "none";
                }
                // neighbourhoodHighlight(clickEvent, graphOptionsRef.props.getNodes(), network);
                this.graphRef.current.lastClickedNode = null;
            },
            doubleClick: params => {
                params.event.preventDefault();
                const currentGraph = this.graphRef.current;
                const network = currentGraph.Network;
                const firstDegreeConnections = [
                ...network.getConnectedNodes(params.nodes),
                ...params.nodes
                ];
                const connectedNodes = [];
                firstDegreeConnections.forEach(node => {
                const nodeConnections = network.getConnectedNodes(node);
                connectedNodes.push(node);
                nodeConnections.forEach(scndNode => {
                    if (!connectedNodes.includes(scndNode)) {
                    connectedNodes.push(scndNode); // Will bring second degree node connections
                    }
                });
                });
                const contextMenuContainer = document.getElementById(
                "graph-contextmenu-container"
                );
                const contextMenuEdgeContainer = document.getElementById(
                "graph-contextmenu-edge-container"
                );
                if (contextMenuContainer) {
                contextMenuContainer.style.visibility = "hidden";
                contextMenuContainer.style.display = "none";
                }
                if (contextMenuEdgeContainer) {
                contextMenuEdgeContainer.style.visibility = "hidden";
                contextMenuEdgeContainer.style.display = "none";
                }
                network.selectNodes(connectedNodes);
            },
            stabilizationProgress: params => {
                const maxWidth = 496;
                const minWidth = 20;
                const widthFactor = params.iterations / params.total;
                const width = Math.max(minWidth, maxWidth * widthFactor);
                // document.getElementById('graphLoadingBar').style.visibility = 'auto';
                if(document.getElementById("graphLoadingBar")) {
                    document.getElementById("graphLoadingBar").style.display = "block";
                }
                if(document.getElementById("bar")) {
                    document.getElementById("bar").style.width = width + "px";
                }
                if(document.getElementById("text")) {
                    document.getElementById("text").innerText = Math.round(widthFactor * 100) + "%";
                }
            },
            stabilizationIterationsDone: () => {
                if(document.getElementById("graphLoadingBar")) {
                    document.getElementById("graphLoadingBar").style.display = "none";
                }
                const currentGraph = this.graphRef.current;
                if (currentGraph) {
                const network = currentGraph.Network;
                const nodePositions = network.getPositions();
                const graph = _.cloneDeep(this.state.filteredGraphData);
                graph.nodes = graph.nodes.map(node => ({
                    ...node,
                    ...nodePositions[node.id]
                }));
                // network.setData({ nodes: graph.nodes, edges: graph.edges });
                // network.setOptions({ physics: false });
    
                this.setState({
                    filteredGraphData: { nodes: graph.nodes, edges: graph.edges },
                    options: { ...options, physics: false }
                });
    
                const lastClickedNode = currentGraph.lastClickedNode;
                if (lastClickedNode) {
                    setTimeout(() => {
                    network.selectNodes([lastClickedNode]);
                    currentGraph.props.events.selectNode({
                        nodes: [lastClickedNode]
                    });
                    }, 0);
                }
                }
            }
        }

        this.setState({ filteredGraphData: graphData, options, events })
    }
    
    getMultiSelectedCount = (type, array) => {
        if(array) {
            return array.length && array.includes('All') ? 'All' :  array.length ? array.length +' Selected' : 'All'
        } else {
            return 'All'
        }
    }

    handleMultiSelectChange = (field, arrayValue, state) => {
		let value = arrayValue.map(item => item.value ? item.value : item)
		let selectedValue = []
		let prevState = this.state[field] ? this.state[field] : []

        if(field === 'nodeTypes') {
            if(this.state.selectedNodeTypes.length < value.length) {
                this.setState({ selectedNodeTypes: value, options: {}, events: {}, filteredGraphData: {nodes: [], edges: []} },
                    () => this.updateInput()
                )
            } else {
                this.setState({ selectedNodeTypes: value },
                    () => this.updateInput()
                )
            }
        } else {
            if(value.includes("All")) {
                if(!prevState.includes("All")) {
                    if(state.filter(e => e.value).length) {
                        selectedValue = state.map(e => e.value)
                    } else {
                        selectedValue = state
                    }
                } else {
                    const index = value.indexOf("All");
                    if (index > -1) {
                        value.splice(index, 1);
                    }
                    selectedValue = value
                }
            } else if(!prevState.includes("All")) {
                selectedValue = value
            }
    
            if(selectedValue.length && !selectedValue.includes("All")) {
                if(selectedValue.length === state.length -1) {
                    selectedValue.push("All")
                }
            }
        }

		this.setState({ [field]: selectedValue })
	}
	
	handleClickOutside(event) {
        // if (this.nodeTypeRef && !this.nodeTypeRef.current.contains(event.target)) {
        //     this.setState({ isnodeTypeOpen: false })
        // } else {
        //     this.setState({ isnodeTypeOpen: true })
        // }

		if(this.state.showTagFilter) {
			if (this.tagsRef && !this.tagsRef.current.contains(event.target)) {
				this.setState({ showTagFilter: false })
			}
		}
    }

    drawDoubleCircle = (ctx, id, x, y, style, message, size) => {

        //draw using canvas
        let innerCircle = size
        let outerCircle = size + 10
        const r = style.size;
        const drawNode = () => {

            ctx.beginPath();
            ctx.arc(x, y, outerCircle, 0, 2 * Math.PI);
            ctx.closePath();
            ctx.save();                    
            ctx.fillStyle = "#00D180";
            ctx.fill();
            ctx.lineWidth = 3
            ctx.strokeStyle = "#FFFFFF";
            ctx.stroke();

            
            ctx.beginPath();
            ctx.arc(x, y, innerCircle, 0, 2 * Math.PI);
            ctx.closePath();
            ctx.save();                    
            ctx.fillStyle = "#00D180";
            ctx.fill();
            

            let lines = [
                {label: "A", value: 0},
                {label: "L", value: 10}, 
                {label: "E", value: 6},
            ]
            let yValue = 0
            if(lines.length === 2) {
                yValue = 10
            } else if(lines.length === 3) {
                yValue = 15
            }
            lines.forEach((row, l) => {
                if(l === 1) {
                    yValue = -5
                } else if(l === 2) {
                    yValue = -25
                }
                ctx.font = "18px Verdana";
                ctx.fillText(row.label, x - 20, y - yValue,r - 20 );
                ctx.font = "16px Verdana";
                ctx.fillText(row.value ? row.value : "-", x, y - yValue );
                // ctx.fillText(row.label+ " "+row.value, x - r + 10, y, 2 * r - 20 );
                // ctx.fillText(row.label+ " "+row.value, x - 30, y - 45, r - 20 );
            })
            
            ctx.lineWidth = 3
            ctx.strokeStyle = "#FFFFFF";
            ctx.stroke();

            ctx.restore();

            // ctx.font = "normal 12px sans-serif";
            // ctx.fillStyle = "black";
            // ctx.font = "10px"
            // ctx.fillStyle = "#90CBF6"

            // var lines = message.split('\n');
        
            // for (var i = 0; i<lines.length; i++)
                // ctx.fillText(lines[i], x - 20, y,r - 20 );
                // ctx.fillText(lines[i], x - r + 10, y, 2 * r - 20 );
                // ctx.fillText(lines[i], x - 30, y - 45, r - 20 );
        };
        return {
            drawNode,
            nodeDimensions: { width: 2 * r, height: 2 * r },
        };
    }

    drawCircle = (ctx, id, x, y, style, message, size, color, lines) => {

        //draw using canvas
        let innerCircle = size
        let outerCircle = size + 10
        const r = style.size;
        const drawNode = () => {

            ctx.beginPath();
            ctx.arc(x, y, outerCircle, 0, 2 * Math.PI);
            ctx.closePath();
            ctx.save();
            ctx.fillStyle = color;
            ctx.fill();                    
            ctx.lineWidth = 3
            ctx.strokeStyle = "#ffffff";
            ctx.stroke();

            ctx.restore();

            // ctx.font = "normal 12px sans-serif";
            // ctx.fillStyle = "black";
            
            ctx.font = "20px Verdana";
            ctx.fillStyle = "#FFFFFF"

            // var lines = message.split('\n');
        
            // for (var i = 0; i<lines.length; i++)
            //     ctx.fillText(lines[i], x - 20, y,r - 20 );
            //     ctx.fillText(lines[i], x - r + 10, y, 2 * r - 20 );
            //     ctx.fillText(lines[i], x - 30, y - 45, r - 20 );

            // let lines = [
            //     {label: "A", value: 0},
            //     {label: "L", value: 10}, 
            //     {label: "E", value: 6},
            // ]

            // let lines = [
            //     {label: "Anomaly", value: 10}
            // ]
            let yValue = 0
            if(lines.length === 2) {
                yValue = 10
            } else if(lines.length === 3) {
                yValue = 15
            }
            lines.forEach((row, l) => {
                if(l === 1) {
                    yValue = -5
                } else if(l === 2) {
                    yValue = -25
                }
                // ctx.font = "18px Verdana";
                // ctx.fillText(row.label, x - 20, y - yValue,r - 20 );
                // ctx.font = "16px Verdana";
                // ctx.fillText(row.value ? row.value : "-", x, y - yValue );
                ctx.font = "14px Verdana";
                ctx.textAlign = "center";
                ctx.fillText(row.label, x, y - 5);
                ctx.font = "14px Verdana";
                ctx.textAlign = "center";
                ctx.fillText(row.value, x, y + 15);

                // ctx.fillText(row.label+ " "+row.value, x - r + 10, y, 2 * r - 20 );
                // ctx.fillText(row.label+ " "+row.value, x - 30, y - 45, r - 20 );
            })
        };
        return {
            drawNode,
            nodeDimensions: { width: 2 * r, height: 2 * r },
        };
    }

    getSelectedNodeEdgeDetails = (nodes, edges) => {
        if(nodes.length  || edges.length) {
            let microServiceName = []
            if(this.state.allGraphData && this.state.allGraphData && Object.entries(this.state.allGraphData.nodes).length) {

                if(!nodes.length && edges.length) {
                    this.state.allGraphData.edges.forEach(edg => {
                        if(edges.includes(edg.id)) {
                            nodes.push(edg.from)
                            nodes.push(edg.to)
                        }
                    })
                }
                this.state.allGraphData.nodes.forEach(nod => {
                    if(nodes.includes(nod.id)) {
                        microServiceName.push(nod.name)
                    }
                })
            }

            this.setState({ 
                selectedMicroServiceName: this.state.selectedMicroServiceName && this.state.selectedMicroServiceName.length && this.state.selectedMicroServiceName[0] === microServiceName[0] ? [] : microServiceName,
                selectedMicroServiceId: this.state.selectedMicroServiceId && this.state.selectedMicroServiceId.length && this.state.selectedMicroServiceId[0] === nodes[0] ? [] : nodes,
                
            },
                () => {
                    let obj ={}
                    obj.selected_node_name = this.state.selectedMicroServiceName
                    obj.selected_node_id = this.state.selectedMicroServiceId
                    obj.selected_edge = edges
                    this.props.setMicroServicePropsDetails('microservies_selected_node_edges', obj)
                }
            )

        }
    }

    getAllTagsKeys = () => {
        let params = {}
        this.props.getAllTagsKeys(params, (promise, response) => {})
    }

    getAllTagsValues = () => {
        let params = {}
        params.tags = [{ "key": this.state.tag_key }]
        this.props.getAllTagsValues(params, (promise, response) => {
            if(promise) {
                this.setState({ tagValues: response })
            }
        })
    }

	addNewTag = () => {
		let tagsSelected = this.state.tagsSelected ? this.state.tagsSelected : []

		if(this.state.tag_key && this.state.tag_key !== "") {
			let filteredTags = tagsSelected.filter(e => e.key !== this.state.tag_key)
			this.state.tag_value.forEach(row => {
				let dataRow = {
					key: this.state.tag_key,
					value: row,
				}
				filteredTags.push(dataRow)
			})

			this.setState({ tagsSelected: filteredTags, tag_key: "",  tag_value: "", tagValues: [] })
		}
	}

	removeTagSection = (tag) => {
		let filteredResult = this.state.tagsSelected.filter(e => e !== tag)
		this.setState({ tagsSelected: filteredResult })
	}
    
	render() {
		return (
            <div className="pr-3 overflow-auto" onClick={ (event) => { this.handleClickOutside(event) } }>
                <div className={`loaderOverlay ${this.state.caseLoading ? '' : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
                <div className='w-100'>
                    <div className="bg-dark3 rounded mt-2 p-3">
                        <div className="d-flex justify-content-between">
                            <div className='align-self-center'>
                                <h7 className="text-white m-0">Microservices</h7>
                                <p className="text-primary-color m-0">Showing the microservice topology</p>
                            </div>
                            
                            <div className="containerFilterSection my-2">
                                <div className="d-flex">
                                    {/* <div className="mr-3" style={{maxWidth: "140px"}}>
                                        <div className="d-flex justify-content-between">
                                            <label className="align-self-center m-0">Provider </label>
                                        </div>
                                        <Select
                                            isSearchable={false}
                                            className={`selectOption f13 p-0`}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            value={({
                                                value: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : "",
                                                label: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : <span className="placeholder">Select</span>
                                            })}
                                            options={this.state.accounts && this.state.accounts.map(row => ({
                                                value: row.account_id,
                                                label: row.account_name,	
                                            }))}
                                            onChange={event => this.setState({
                                                selectedAccount: event.value,
                                                changesInFilter: true,
                                            })}
                                        />
                                    </div> */}
                                    <div className="mr-3" style={{maxWidth: "130px"}}>
                                        <div className="d-flex justify-content-between">
                                            <label className="align-self-center m-0">Account </label>
                                            {this.state.hasError && (this.state.selectedAccount || this.stae.selectedAccount === "") ? (
                                                <small className="text-issues">required</small>
                                            ) : null}
                                        </div>
                                        <Select
                                            isSearchable={false}
                                            className={`selectOptionsm f13 p-0`}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            value={({
                                                value: this.state.selectedAccount && this.state.selectedAccount !== "" ? this.state.selectedAccount : "",
                                                label: this.state.selectedAccount && this.state.selectedAccount !== "" ? getAccountNameFromId(this.state.selectedAccount, this.state.accounts) : <span className="placeholder">Select</span>
                                            })}
                                            options={this.state.accounts && this.state.accounts.map(row => ({
                                                value: row.account_id,
                                                label: row.account_name,	
                                            }))}
                                            onChange={event => this.setState({
                                                selectedAccount: event.value,
                                                changesInFilter: true,
                                            })}
                                        />
                                    </div>
                                    <div className="mr-3" ref={this.regionRef}>
                                        <div className="d-flex justify-content-between">
                                            <label className="align-self-center pb-5p">Region </label>
                                            {this.state.hasError && (!this.state.selectedRegion || !this.state.selectedRegion.length) ? (
                                                <small className="text-issues">required</small>
                                            ) : null}
                                        </div>
                                        <div className={`multiSelectOptionSm align-self-end`}>
                                            <ReactMultiSelectCheckboxes
                                                placeholderButtonLabel="All"
                                                getDropdownButtonLabel={() => this.getMultiSelectedCount("selectedRegion", this.state.selectedRegion ? this.state.selectedRegion : [])}
                                                options={this.state.regions && this.state.regions.map(row => ({
                                                    value: row.region,
                                                    label: row.name,
                                                }))}
                                                onChange={arr => { this.handleMultiSelectChange("selectedRegion", arr ? arr : [], this.state.regions)}}
                                                value={this.state.selectedRegion && this.state.selectedRegion.map(row => ({
                                                    value: row
                                                }))}
                                            />
                                        </div>
                                    </div>
                                    <div className={`form-group flex-fill bd-highlight mb-0 mr-3`} style={{minWidth: '100px' }} ref={this.tagsRef}>
                                        <div className="" onClick={() => this.setState({ showTagFilter: true })}>
                                            <label className="mb-0">Tags</label>
                                            <p className='mb-0 STDborderBottom mt-3'>
                                                {!this.state.selectedTags || (this.state.selectedTags && !this.state.selectedTags.length) ?
                                                    <span>All</span>
                                                : this.state.selectedTags && this.state.selectedTags.length ?
                                                    <span>{this.state.selectedTags.length} selected</span>
                                                : null}
                                            </p>
                                        </div>
                                        {this.state.showTagFilter ?
                                            <div className="costPopupTagSection border mt-1">
                                                <div className="p-3 	bg-muted">
                                                    <div className={`w-100 mt-3`}>
                                                        <p className="mb-2 w-100 text-white">Tags</p>
                                                        <div className={`d-flex`}>
                                                            <label className="mb-1 w-40">Key</label>
                                                            <label className="mb-1 w-40 ml-3">Value</label>
                                                        </div>
                                                        <div className={`w-100`}>
                                                            <div className={`d-flex mt-3`}>
                                                                <Select
                                                                    isSearchable={true}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    className={`selectOption mr-2 mt-2`}
                                                                    value={({
                                                                        value: this.state.tag_key,
                                                                        label: this.state.tag_key && this.state.tag_key !== "" ? this.state.tag_key : <span className="placeholder">Key</span>
                                                                    })}			
                                                                    
                                                                    options={this.props.tagKeys && this.props.tagKeys.map(item => ({
                                                                        value: item,
                                                                        label: item,	
                                                                    }))}
                                                                    onChange={event => this.setState({
                                                                        tag_key: event.value,
                                                                        tagValues: [],
                                                                    },
                                                                        () => this.getAllTagsValues()
                                                                    )}
                                                                />
                                                                {this.state.tag_key && this.state.tag_key !== "" && !this.state.tagValues.length ?
                                                                    <div className='ml-3'>
                                                                        <Spinner className='text-center align-self-center' color='white' size='sm' />
                                                                    </div>
                                                                :
                                                                    <React.Fragment>
                                                                        <div className={`multiSelectOption align-self-center mr-2 z998`} style={{marginTop: "1.3rem"}}>
                                                                            <ReactMultiSelectCheckboxes
                                                                                placeholderButtonLabel="Select"
                                                                                getDropdownButtonLabel={() => this.getMultiSelectedCount("tag_value", this.state.tag_value ? this.state.tag_value : [])}
                                                                                options={this.state.tagValues && this.state.tagValues.map(row => ({
                                                                                    value: row,
                                                                                    label: row,
                                                                                }))}
                                                                                onChange={arr => { this.handleMultiSelectChange("tag_value", arr ? arr : [], this.state.tagValues) }}
                                                                                value={this.state.tag_value && this.state.tag_value.map(acc => ({
                                                                                    value: acc
                                                                                }))}
                                                                            />
                                                                        </div>
                                                                        <span className={`far fa-plus cursorPointer f18 align-self-center ml-4`} onClick={() => this.addNewTag("tags")}></span>
                                                                    </React.Fragment>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.state.tagsSelected && this.state.tagsSelected.length ? 
                                                        this.state.tagsSelected.map((tag, index) => {
                                                            return(
                                                                <span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">{tag.key +" : "+tag.value}
                                                                    <i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeTagSection(tag) } ></i>
                                                                </span>
                                                            )
                                                        })
                                                    : null}
                                                    <div className="d-flex mt-3">
                                                        <span className={`text-info mr-2 align-self-center cursorPointer`} onClick={() => this.setState({ tagsSelected: [], selectedTags: [] })}>Clear</span>
                                                        <button className={`btn btn-sm btn-light cursorPointer`} onClick={() => this.setState({ showTagFilter: false, selectedTags: this.state.tagsSelected })}>Select</button>
                                                    </div>
                                                </div>
                                            </div>
                                        : null}
                                    </div>
                                    <div className="align-self-center">
                                        <div className="d-flex align-self-center">
                                            <div className="btn-group" role="group" aria-label="governance-dash">
                                                <button type="button" className={`cursorPointer btn btn-secondary border-right`} onClick={()=> this.onSearch()}>Search</button>
                                                <button type="button" className={`cursorPointer btn btn-secondary border-left`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                        {/* {this.state.brushSliderOptions && this.state.brushSliderSeries ?
                            <div className={`col-md-12 mb-n3 mt-3 bg-muted rounded ${this.state.disableDateSlider ? 'disabled' : ''}`} >
                                <Chart options={this.state.brushSliderOptions} series={this.state.brushSliderSeries} type="bar" height={80} />
                            </div>
                        : null} */}
                        {this.state.brushSeries && this.state.brushSeries.length && this.state.optionsBrush && Object.entries(this.state.optionsBrush).length ?
                            <div className="d-flex mt-3">
                                <div className="w-80 transparentTooltip">
                                    <Chart options={this.state.optionsBrush} series={this.state.brushSeries} type="line" height={8} />
                                </div>
                                <div className="pl-2 w-20">
                                    <small className="mb-0 align-self-center f10">{momentDateGivenFormat(this.state.brushStartDate, 'DD MMM HH:mm') +' - '+momentDateGivenFormat(this.state.brushEndDate, 'DD MMM HH:mm')}</small>
                                </div>
                            </div>
                        : null}
                            
                        {/* <div className="d-flex">
                            <div id="leftSliderSpan" className="f12">{momentDateGivenFormat(this.state.brushStartDate, 'DD MMM')}</div>
                            <div id="rightSliderSpan" className="f12">{momentDateGivenFormat(this.state.brushEndDate, 'DD MMM')}</div>
                        </div> */}
                    </div>
                </div>
                <div className="rounded bg-dark p-3 mt-3">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex justify-content-start">
                            <div className="w-fit-content">
                                <div className="from-group">
                                    {/* <div className={`multi-select-dark-theme align-self-center mr-2 z999 ${this.state.nodeCategory && this.state.nodeCategory.length > 10 ? '' : 'removeDropdownSearchBar'}`} ref={this.nodeTypeRef}>
                                        <ReactMultiSelectCheckboxes						
                                            placeholderButtonLabel="All"
                                            getDropdownButtonLabel={() => this.getMultiSelectedCount('nodeTypes', this.state.selectedNodeTypes)}
                                            menuIsOpen ={this.state.isnodeTypeOpen}
                                            value={this.state.selectedNodeTypes.map(item => ({
                                                value: item,
                                                label: item
                                            }))}
                                            onChange={arr => {
                                            this.handleMultiSelectChange('nodeTypes', arr ? arr : []) }}
                                            options={this.state.nodeCategory.map(itm => ({
                                                value: itm,
                                                label: itm,
                                            }))}
                                        />
                                    </div> */}
                                </div>
                            </div>
                            {/* <div className="form-check mr-3 align-self-center displayNone">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                <input 
                                    type="checkbox" 
                                    className="form-check-input" 
                                    id="assetsInfoCheckbox" 
                                    checked={this.state.showAssetDetail}
                                    onChange={e => this.setState({ showAssetDetail: this.state.showAssetDetail ? false : true })}
                                />
                                <label className="form-check-label" for="assetsInfoCheckbox">Show Asset Info</label>
                            </div> */}
                            {/* <div className="form-check mr-3 align-self-center displayNone">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                <input 
                                    type="checkbox" 
                                    className="form-check-input" 
                                    id="directedCheckbox" 
                                    checked={this.state.directedGraph}
                                    onChange={e => this.setState({ directedGraph: this.state.directedGraph ? false : true },
                                        () => this.changeGraphOption('directed')
                                    )}
                                />
                                <label className="form-check-label" for="directedCheckbox">Directed</label>
                            </div> */}
                        </div>
                        <div className="d-flex justify-content-end align-self-center">
                            {/* <div className="rounded bg-secondary text-white py-1 px-2 mr-2">
                                Node
                                <span className="ml-2 fa fa-plus-square cursorPointer" onClick={() => this.addRemoveNodes('add')}></span>
                                <span className="ml-2 fa fa-minus-square cursorPointer" onClick={() => this.addRemoveNodes('remove')}></span>
                            </div> */}
                            <div className="rounded bg-secondary text-white px-2 py-1">
                                <span onClick={this.resetGraph} className="fa fa-redo-alt border-right pr-2 cursorPointer"></span>
                                {/* <span onClick={this.onClickZoomIn}  className="fa fa-search-plus border-right px-2 cursorPointer"></span>
                                <span onClick={this.onClickZoomOut}  className="fa fa-search-minus border-right px-2 cursorPointer"></span> */}
                                {!this.state.fullScreen ?
                                    <span onClick={this.onToggleFullScreen} className="fa fa-expand-arrows-alt pl-2 cursorPointer"></span>
                                :
                                    <span onClick={this.onToggleFullScreen} className="fa fa-compress-arrows-alt pl-2 cursorPointer"></span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="d-flex mt-2">
                    </div>
                    <div className="topologyGraph">
                        <div id="graphLoadingBar">
                            <div>LOADING</div>
                            <div id="loadingBar">
                                <div className="outerBorder">
                                    <div id="text">0%</div>
                                    <div id="border" />
                                    <div id="bar" />
                                </div>
                            </div>
                        </div>
                        {this.state.filteredGraphData && this.state.filteredGraphData && Object.entries(this.state.filteredGraphData.nodes).length ? 
                            <div id="graph">
                                <Graph
                                    ref={this.graphRef}
                                    graph={this.state.filteredGraphData}
                                    options={this.state.options}
                                    events={this.state.events}
                                />
                            </div>
                        : null }
                    </div> 
                    <div className="mt-2 d-flex justify-content-end displayNone">
                        <div className="rounded bg-secondary text-white py-1 px-2 mr-2">
                            Node
                            <span className="ml-2 fa fa-plus-square cursorPointer" onClick={() => this.addRemoveNodes('add')}></span>
                            <span className="ml-2 fa fa-minus-square cursorPointer" onClick={() => this.addRemoveNodes('remove')}></span>
                        </div>
                        <div className="rounded bg-secondary text-white px-2 py-1">
                            <span onClick={this.resetGraph} className="fa fa-redo-alt border-right pr-2 cursorPointer"></span>
                            <span onClick={this.onClickZoomIn}  className="fa fa-search-plus border-right px-2 cursorPointer"></span>
                            <span onClick={this.onClickZoomOut}  className="fa fa-search-minus border-right px-2 cursorPointer"></span>
                            {!this.state.fullScreen ?
                                <span onClick={this.onToggleFullScreen} className="fa fa-expand-arrows-alt pl-2 cursorPointer"></span>
                            :
                                <span onClick={this.onToggleFullScreen} className="fa fa-compress-arrows-alt pl-2 cursorPointer"></span>
                            }
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}
/**
 * Type of the props used in the component
 */
MicroServicesLeftSection.propTypes = {
    microServicesGetTopology: PropTypes.func,
    fullScreenOption: PropTypes.bool
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
	// console.log('state', state)
	return {
        providers: state.filters.providers,
        accounts: state.filters.accounts,
        regions: state.filters.regions && state.filters.regions[0] && state.filters.regions[0].provider_regions ? state.filters.regions[0].provider_regions :[],
        tagKeys: state.filters.tagKeys,
    }
}

export default connect(mapStateToProps, {
    listAllProviders,
	listAllAccounts,
	listAllRegions,
    getAllTagsKeys,
    getAllTagsValues,
    microServicesGetTopology,
    getGraphSummary,
    setMicroServicePropsDetails
})(withRouter(MicroServicesLeftSection))