/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Buckets.js
 * @author Prakash // on 13/10/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Spinner } from 'reactstrap'
import { 
    listSourceDataCatalog,
    deleteSourceDataCatalog
} from '../../../../actions/dlp/BucketTableAction'

import { momentConvertionUtcToLocalTime } from '../../../../utils/utility'

import Search from '../../../common/SearchComponent'

import { store as CommonNotification } from 'react-notifications-component';

import ResizeableTable from '../../../designComponents/Table/ResizeableTable'

class Buckets extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showDataLoading: true
        }
    }
    
    componentDidMount = () => {	
        if(!this.props.dlpSourceDataList || !this.props.dlpSourceDataList.length) {
            this.listSourceDataCatalog()
        } else {
            this.setState({ showDataLoading: false })
        }
    }

    listSourceDataCatalog = () => {
        this.setState({ showDataLoading: true }, 
            () => { 
                let params = {}
                this.props.listSourceDataCatalog(params, (promise, response) => {
                    this.setState({ showDataLoading: false })
                })
            }
        )
    }

    deleteConfirmation = (doc_id) => {
        if (window.confirm('Are you sure, you want to delete the bucket?')) {
            this.setState({ showDeleteLoading: true },
                () => this.deleteSourceDataCatalo(doc_id)
            )
        }
    }

    deleteSourceDataCatalog = (doc_id) => {
        let params = {}
        params.doc_id = [doc_id]

        this.props.deleteSourceDataCatalog(params, (promise, response) => {
            if(promise) {
                this.setState({ showDeleteLoading: false },
                    () => this.listSourceDataCatalog()
                )
                let messageType = 'danger'		
                let message = 'Bucket deleted'
                CommonNotification.addNotification({
                    message: message,
                    type: messageType,
                    insert: "top",
                    container: "top-center",
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                })
            }
        })
    }

    render() {
        return(
            !this.state.showDataLoading ?
                <React.Fragment>
                    <div className="row"> 
                        <div className="col-sm-12">
                            <h6 className="font-weight-normal">Buckets</h6>
                            <p>Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim.</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <select className="custom-select w-auto mr-3">
                                <option selected>Filter</option>
                                {/* <option value="s3">s3</option>
                                <option value="athena">Athena</option> */}
                            </select>
                            {/* <select className="custom-select w-auto">
                                <option selected>Provider</option>
                                <option value="aws">AWS</option>
                                <option value="gcp">GCP</option>
                                <option value="azure">AZURE</option>
                            </select> */}
                        </div>
                        <div className='col-sm-6 d-flex justify-content-end'>
                            {this.props.dlpSourceDataList && this.props.dlpSourceDataList.length ?
                                <div className="form-group mb-0">
                                    <Search
                                        data={this.props.dlpSourceDataList}
                                        applyTags={false}
                                        applyLiteDarkTags={true}
                                        topClassName={'bg-white'}
                                        searchClassName={'textboxBorder'}
                                        searchIconColor={'text-dark'}
                                        className={"bg-transparent textboxBorder"}
                                        filteredData={(filteredArray) => {
                                            this.setState({ filteredList: _.orderBy(filteredArray, ['date'], ['desc']) })
                                        }}
                                    />
                                </div>
                            : null}
                            <span className='btn btn-primary cursorPointer text-center ml-2 mb-2' onClick={() => this.props.onClickAction({}, 'create')}>Add Bucket</span>
                        </div>
                    </div>
                    {this.props.dlpSourceDataList ?
                        <ResizeableTable
                            columns={[
                                {
                                    Header: 'Date',
                                    accessor: d => d.modified_at ? d.modified_at : d.created_at,
                                    Cell: cellInfo => (<span>{cellInfo.row.original.modified_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.modified_at, 'DD MMM YYYY HH:mm') : (cellInfo.row.original.created_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, 'DD MMM YYYY HH:mm')  : '')}</span>)
                                },
                                {
                                    Header: 'Type',
                                    accessor: 'type',
                                    width: 150	
                                },
                                {
                                    Header: 'Bucket',
                                    accessor: 'bucket_name',
                                    width: 250
                                },
                                {
                                    Header: 'Prefix',
                                    accessor: 'bucket_prefix',
                                    width: 250
                                },
                                {
                                    Header: 'Description',
                                    accessor: 'bucket_text',
                                    width: 350	
                                },
                                {
                                    Header: 'Action',
                                    Cell: cellInfo => (
                                        <div className="d-flex justify-content-between align-self-center">
                                            <p className="text-dark m-0 p-0 cursorPointer">
                                                <i className="fal fa-book-open" onClick={() => this.props.onClickAction(cellInfo.row.original, 'view')}></i>
                                            </p>
                                            <p className="text-dark m-0 p-0 cursorPointer">
                                                <i className="fal fa-user-edit" onClick={() => this.props.onClickAction(cellInfo.row.original, 'edit') }></i>
                                            </p>
                                            <p className="text-dark m-0 p-0 cursorPointer">
                                                {this.state.showDeleteLoading && cellInfo.row.original.doc_id === this.state.selectedWatermarkId ? 
                                                    <Spinner color='dark' size='sm' />
                                                :
                                                    <i className="fal fa-trash" onClick={() => this.setState({ selectedWatermarkId: cellInfo.row.original.doc_id }, () => this.deleteConfirmation(cellInfo.row.original.doc_id))}></i>
                                                }
                                            </p>
                                        </div>
                                    ),
                                    width:120
                                }
                            ]}
                            data={this.state.filteredList ? this.state.filteredList : this.props.dlpSourceDataList}
                            perPage={10}
                            dashboard={this.state.filteredList ? this.state.filteredList.length : this.props.dlpSourceDataList.length}
                            sortByColumn={'Date'}
                            tooltipFor={[3]}
                            onClickRow={tableRow => {}}
                            tableSize={'table-striped'}
                        />
                    :
                        <div className='d-flex justify-content-center m-4'>
                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                        </div>
                    }
                </React.Fragment>
            : this.state.showDataLoading ?
                <div className='d-flex justify-content-center m-4'>
                    <Spinner color='dark' size='lg' />
                </div>
            :
                <div className='d-flex justify-content-center m-4'>
                    <p>There are no data on this criteria. Please try adjusting your filter.</p>
                </div>
        )
    }
}
    

/**
 * Type of the props used in the component
 */
Buckets.propTypes = {
	listSourceDataCatalog: PropTypes.func,
	deleteSourceDataCatalog: PropTypes.func
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
 const mapStateToProps = state => {
    return {
		dlpSourceDataList: state.dlp.dlpSourceDataList ? state.dlp.dlpSourceDataList.filter(res => res.service_name === 's3') : []
	}
}
export default connect(mapStateToProps, {
	listSourceDataCatalog,
	deleteSourceDataCatalog
})(withRouter(Buckets))