import React from 'react';
import moment from 'moment'
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { CUSTOM_DURATION_FILTER_OPTIONS } from '../../../../utils/constants'
import { getDayFromSelectedDuration } from '../../../../utils/utility'
import CommonDropdown from '../../../common/CommonDropdown'

class DurationFilterSection extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showDate: false,
            showDatepicker: false, 
            durationSelector: 7,
            selectedDuration: '+7d',
            durationArray: []
            //pervious90Days: moment().subtract('365', 'days'),
        };

        this.handleDayClick = this.handleDayClick.bind(this);        
		this.handleResetClick = this.handleResetClick.bind(this);
    };

    componentDidMount = () => {
        let durationArray =[]
        CUSTOM_DURATION_FILTER_OPTIONS.forEach(item => {
            let dataRow = {}
            dataRow.value = item.value
            dataRow.label = item.option
            durationArray.push(dataRow)
        })
        this.setState({ durationArray })
    }

    handleResetClick = () => {
        this.setState({ showDatepicker: true }, 
            () => this.getInitialState()
        );
	}

    getInitialState() {
		return {
		  from: undefined,
		  to: undefined,
		};
		
	}

	handleDayClick(day) {
		if(this.state.from === undefined) {
			const range = DateUtils.addDayToRange(day, this.state);
			this.setState({ range, from: day });
		} else {
			const newRange = {
				from: this.state.from,
				to: day
			}
			// var Difference_In_Time = day.getTime() - this.state.from.getTime(); 			
			// var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
			// if(Difference_In_Days < 6) {
			// 	alert('Please select 7 days for better view')
			// 	return;
			// }

			// let durtaion = '+'+(Difference_In_Days+1)+'d';
			
			const range = DateUtils.addDayToRange(day, newRange);
			this.setState(
				{ range, to: day, showDatepicker: false }
			);
		}
	}

	durationFinder = () => {
		if(this.state.selectedDuration === 'custom') {
			this.setState({ from: undefined, to: undefined, showDatepicker: true, showDate: true })
		} else {            
            let days = getDayFromSelectedDuration(this.state.selectedDuration)
			let beginDate = moment().subtract(days, 'days')
			let closeDate = moment()
			//console.log(closeDate._d)
			this.setState({ from: beginDate._d, to: closeDate._d})
		}
	}

    selectedOptionFunction = (label, value) => {
        this.setState({ selectedDuration: value, 
            showDatepicker: value === 'custom' ? true : false,
            showDate: value === 'custom' ? true : false  }, 
            () => this.durationFinder() 
        )
    }

    applyFilter = () => {
        
        let obj = {}
        obj['from'] = this.state.from === undefined ? '' :  moment(this.state.from).format("YYYY-MM-DD");
        obj['to'] = this.state.to === undefined ? '' :  moment(this.state.to).format("YYYY-MM-DD");
        obj['start_time'] = this.state.from === undefined ? '' :  moment(this.state.from).format("YYYY-MM-DD 00:00:00")
		obj['end_time'] = this.state.to === undefined ? '' :  moment(this.state.to).format("YYYY-MM-DD 23:59:59")
        obj['selectedDuration'] = this.state.selectedDuration
        obj['durationSelector'] = this.state.durationSelector
        
        let result = []
        result.push(obj)
        
        return this.props.data(result)
    }

    closeFilter = () => {
        return this.props.data(false);
    }
    
    render() {
        const { from, to } = this.state;
		const modifiers = { start: from, end: to };
        return (
            <div className="cardDropSection">
                <div className='container-fluid'>
                    <div className="row mt-3">
                        <div className="col-sm-10 align-self-center">
                            <p className="text-white mb-0">Lorem ipsum text industry since 1500s</p>
                        </div>
                    </div>
                    <div className='row my-3'>
                        <div className="col-sm-4 pr-0">
                            <CommonDropdown 
                                data = {this.selectedOptionFunction.bind(this, 'duration')}
                                hideHeader = {false}
                                headerName = {'Duration'}
                                dropOptions = {this.state.durationArray}
                                displayMenu = {this.state.dropRef1}
                                isMultiSelect = {false}
                                isDefaultDropdown = {false}
                                styleTop={'top30'}
                                searchOption={false}
                                label={''}
                            />
                        </div>
                    </div>
                    <div className={`d-flex mb-2 ${this.state.showDate ? '' : 'displayNone'}`}>
                        <div className="mr-3">
                            <label className="text-white m-0 f12">From Date</label>
                            <input 
                                className="form-control"
                                type="text" 
                                value={this.state.from === undefined ? '' :  moment(this.state.from).format("YYYY-MM-DD")}
                                placeholder={'From date'}
                                onClick={ () => this.setState({ showDatepicker: this.state.showDatepicker ? false : true }) }
                            />
                        </div>
                        <div className="mr-1">
                            <label className="text-white m-0 f12">To Date</label>
                            <input 
                                className="form-control"
                                type="text" 
                                value={this.state.to === undefined ? '' :  moment(this.state.to).format("YYYY-MM-DD")}
                                placeholder={'To date'}
                                onClick={ () => this.setState({ showDatepicker: this.state.showDatepicker ? false : true }) }
                            />
                        </div>
                        <div className={`mr-1 ${this.state.from !== undefined || this.state.to !== undefined ? '' : 'displayNone'} `}>
                            <label class="text-white m-0 w-100">&nbsp;</label>
                            <button className="resetBtn" onClick={this.handleResetClick}>Reset</button>
                        </div>
                    </div>

                    <div className={`RangeExample ${this.state.showDatepicker ? '' : 'displayNone'}`}>
                        <DayPicker
                            toMonth={new Date()}
                            disabledDays={[
                                {
                                    after: new Date(),
                                    //before: (new Date(this.state.pervious90Days))
                                },
                            ]}
                            className="Selectable"
                            numberOfMonths= {2}
                            selectedDays={[from, { from, to }]}
                            modifiers={modifiers}
                            onDayClick={this.handleDayClick}
                            tabIndex='none'
                        />
                        
                    </div>
                    {/* <ul className={`${this.state.styleTop ? this.state.styleTop : '' }`}>
                        {/* {CUSTOM_DURATION_FILTER_OPTIONS.map((item, index) => {
                            return (
                                <li onClick={ () => this.selectedOptionFunction(item.value, item.option) } key={item.value}>
                                    <label className="checkBox"> <span>{item.option}</span> </label>
                                </li>
                            )
                        })} 
                    </ul> */}
                    
                    <div className='row mb-3'>
                        <div className="col-sm-6"></div>
                        <div className="col-sm-6 justify-content-end d-flex">
                            <span className="dropCancelBtn d-block p-2 mr-2" onClick={ () => this.closeFilter()}>
                                Cancel
                            </span>
                            <span className="dropApplyBtn d-block p-2" onClick={ () => this.applyFilter()}>
                                Apply
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DurationFilterSection;
