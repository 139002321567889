/*************************************************
 * Tvastar
 * @exports
 * @file Login.js
 * @author Rajasekar // on 19/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'

import { validateEmail } from '../../utils/utility'
import { authenticate } from '../../actions/signInAction'
import { getUserMenu } from '../../actions/commonAction'
import logo from '../../assets/images/logo.svg'
import { URL_PATH } from '../../config/urlPath'
import { Col, Row, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap'

class Login extends Component {
	constructor(props) {
		super(props)
		this.state = {
			modalHeader: 'Forgot Password',
			forgotPopup: false,
			email: '',
			showForgetErrorMessage: '',
			showForgotSuccessScreen: false,

			popupLoading: '',
			username: '',
			password: '',
			isChecked: '',
			showLoading: false,
			hasError: false,
			showLoginError: false,
			
		}

		this.toggleCloseModal = this.toggleCloseModal.bind(this)
	}

	componentDidMount() {
        if (localStorage.getItem('checkbox') && localStorage.getItem('username') !== "") {
            this.setState({
                isChecked: true,
                username: JSON.parse(localStorage.username),
                password: JSON.parse(localStorage.password)
            })
        }
    }

	toggleCloseModal = (event) => {
        if(event.keyCode !== 27)  {
            this.setState({
                forgotPopup: !this.state.forgotPopup
            })
        }
    }

	/**
	 * Handles onSubmit Login Form
	 */
	onSubmit = () => {
		this.setState({ showLoginError: false })
		if (this.state.username === '' || this.state.password === '') {
			this.setState({ hasError: true })
		} else {
			if (this.state.isChecked) {
				localStorage.setItem('username', JSON.stringify(this.state.username));
				localStorage.setItem('password', JSON.stringify(this.state.password));
				localStorage.setItem('checkbox', this.state.isChecked);
			} else {
				localStorage.setItem('checkbox', this.state.isChecked);
			}
			this.setState({ showLoading: true })
			this.props.authenticate(this.state.username, this.state.password, result => {
				this.setState({ showLoading: false })
				if (result) {
					// this.props.history.push(URL_PATH.AIOPS_DASHBOARD)
					this.getUserMenu()
					this.props.history.push(URL_PATH.LOADING_PAGE)
				} else {
					this.setState({ showLoginError: true })
				}
			})
		}
	}

	getUserMenu = () => {
		if(!Object.entries(this.props.userMenu).length) {
			this.props.getUserMenu((promise, response) => {
				if (promise) {
					this.setState({ menuList: response }	)
				} else {
					this.setState({ menuList: [] })
				}
			})
		} else {
			this.setState({ menuList: this.props.userMenu })
		}
	}

	onModalSubmit = () => {
		this.setState({ hasError: false })
		if (
			this.state.email === '' ||
			!validateEmail(this.state.email)
		) {
			this.setState({ hasError: true })
		} else {
			let params = {}
			params.email = this.state.email
			this.setState({ popupLoading: true, showForgotSuccessScreen: true })
			// this.props.resolveIssue(data, (promise, response) => {
			// 	console.log(response)
			// 	if(promise) {
			// 		this.setState({ popupLoading: false, showForgotSuccessScreen: true })
			// 	} else {
			// 		this.setState({ popupLoading: false, showForgetErrorMessage: 'failed' })
			// 	}
			// })
		}
	}

	/**
	 * Renders login screen design
	 */
	render() {
		const { username, password, hasError, showLoginError, email, showForgotSuccessScreen } = this.state
		return (
			<React.Fragment>
			<div className="d-flex position-fixed overflow-auto h-100 w-100">
				<div className="flex-grow-1 bg-dark text-white p-5 d-flex align-items-center">
					<div className="align-self-center w-100">
						<div className="d-table m-auto w-25">
							<Link to={URL_PATH.LOGIN} className='d-block text-center mb-2'>
								<img src={logo} alt='' />
							</Link>
							<h5 className="my-3 text-center">Welcome to the Tvaṣṭṛ</h5>
							<div className="bg-white rounded p-4 text-dark">
								<h6 className="font-weight-normal mb-3">Login to your account</h6>
								<form>
								<div className="form-group">
									<label>Username / Email*</label>
									<input
										type='text'
										className={`form-control ${hasError && username === '' ? 'is-invalid' : ''}`}
										value={this.state.username}
										onChange={event => this.setState({ username: event.target.value, showLoginError: false })}
										placeholder='Username / Email'
									/>
									<div className='invalid-feedback'>Please enter a valid username / email.</div>
								</div>
								<div className="form-group">
									<label>Password*</label>
									<input
										type='password'
										className={`form-control ${hasError && password === '' ? 'is-invalid' : ''}`}
										// value={this.state.password}
										onChange={event => this.setState({ password: event.target.value, showLoginError: false })}
										placeholder='Password'
										autoComplete="off"
										onKeyPress={event => {
											if (event.key === 'Enter') this.onSubmit()
										}}
									/>
									<div className='invalid-feedback'>Please enter a valid password.</div>
								</div>
								</form>
								{/* <div className={`d-flex justify-content-between ${this.state.showLoading ? 'disabled': ''} `}>
									<div className="form-group form-check">
										<input type="checkbox" checked={this.state.isChecked} onChange={event => { this.setState({ isChecked: event.target.checked }) }} className="form-check-input" />
										<label className="form-check-label">Remember Me</label>
									</div>
									<span className="textInfo cursorPointer" onClick={() => this.setState({ forgotPopup: !this.state.forgotPopup }) }>Forgot Password?</span>
								</div> */}
								{showLoginError ? <div className='text-danger mb-2'>Invalid username or password.</div> : null}
								<button
									className='btn btn-primary w-100'
									onClick={() => this.onSubmit()}
									disabled={this.state.showLoading}
								>
									{this.state.showLoading ? 'Logging in...' : 'Login'}
								</button>
								<div className={`text-center  ${this.state.showLoading ? 'disabled': ''} `}>
									<p className="small my-2 mb-0">Don&apos;t have an account?</p>
									<Link className='text-center d-block' to={URL_PATH.REGISTER}>
										Create an account
									</Link>
								</div>
							</div>
							<div className="text-center my-3">
								<p className="text-muted small mb-0">Terms and Conditions  |  Privacy Policy</p>
								<p className="text-muted small">© {moment().format('YYYY')} Tvaṣṭṛ. All rights reserved.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal centered className="forgorUrl modal" backdrop="static" isOpen={this.state.forgotPopup} toggle={this.toggleCloseModal}>
				<ModalHeader toggle={this.toggleCloseModal}> {this.state.modalHeader}
				</ModalHeader>
				<ModalBody>
					{showForgotSuccessScreen ? (
						<div className='text-center'>
							<h3 className='text-success'>
								<i className='fas fa-check-circle signup-success-tick-icon' />
							</h3>
							<p className='text-success mt-3'>Reset password link has been sent to you registered email, please click the link to reset your</p>
							<p>
								Click here to <span class={`textInfo cursorPointer`} onClick={this.toggleCloseModal}>login</span>
							</p>
						</div>
					) : (
						<Form>
							<Row>
								<Col md={12}>
									<FormGroup>
										<Label>Registered Email</Label>
										<Input
											type='text'
											className={`form-control bg-white rounded text-dark ${
											(hasError && email === '') || (hasError && !validateEmail(this.state.email)) ? 'is-invalid' : email === '' ? '' : !validateEmail(this.state.email) ? 'is-invalid' : 'is-valid'
										}`}
											value={this.state.email}
											placeholder='Email'
											onChange={event => {
												this.setState(
													{
														email: event.target.value
													}
												)
											}}
										>
										</Input>
										<div className='invalid-feedback'>Please enter a valid email</div>
									</FormGroup>
								</Col>
							</Row>
						</Form>
					)}
				</ModalBody>
				<ModalFooter>
					<div className="col-sm-6 m-0 p-0">
						<div className='text-danger'>{this.state.showForgetErrorMessage}</div>
					</div>
					<div className={`col-sm-6 m-0 p-0 float-right text-right ${this.state.popupLoading ? 'disabled' : ''}`}>
						<button className={`btn btn-sm btn-light mr-3 `} onClick={this.toggleCloseModal}> Close</button>
						<button
							className={`btn btn-sm btn-primary ${showForgotSuccessScreen ? 'displayNone': ''}`}
							onClick={() => this.onModalSubmit()}
							>
							{this.state.popupLoading ? (
								<React.Fragment>
									{'submitting'}
									<Spinner className='text-center ml-2' color='light' size='sm' />{' '}
								</React.Fragment>
							) : 'Submit'
							}
						</button>
					</div>
				</ModalFooter>
			</Modal>
			</React.Fragment>
		)
	}
}
/**
 * Type of the props used in the component
 */
Login.propTypes = {
	authenticate: PropTypes.func,
	history: PropTypes.object,
}

const mapStateToProps = state => {
	// console.log('sidebar state', state)
	return {
		userMenu: state.filters.userMenu,
    }
}

export default connect(mapStateToProps, { authenticate, getUserMenu })(Login)
