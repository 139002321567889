/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file IdleTimeSection.js
 * @author Prakash // on 10/02/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _, { orderBy } from 'lodash'
import { UncontrolledTooltip } from 'reactstrap'

import { momentConvertionUtcToLocalTime, getRegionName, getAccountNameFromId, momentDateGivenFormat, currentLocaltime, subDays, currentUTCtime, capitalizeTheFirstLetterOfEachWord, unitTimeConvertionDetail, addDays } from '../../../utils/utility'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'
import { listAllAccounts, listAllRegions } from '../../../actions/commonAction'

import Search from '../../common/SearchComponent'

import PrimarySecondaryMetricsSection from './PrimarySecondaryMetricsSection'
// import Chart from '../../common/charts/ApexBarChart'
import Chart from 'react-apexcharts'

class IdleTimeSection extends Component {
	sliderWrap = React.createRef()

    constructor(props) {
        super(props)
        this.annotationTypeRef = React.createRef()
		this.groupByRef = React.createRef()
        // this.scrolltoTop = React.createRef()
        this.state = {
            minMaxHeight: "700px",
            recommendedEvent: this.props.recommendedEvent,
            series: [],
            options: {},
            groupBy: "Weekly"
        }
    }

    componentDidMount = () => {        
        this.weeklyIdleGraph()

        this.monthlyIdleGraph()
    }

    weeklyIdleGraph = () => {
        let series = []
        let colors = []

        if(this.state.recommendedEvent && this.state.recommendedEvent.idle_time) {
            let labels = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
            let topStatusData = {}
            let data = []
            labels.forEach(item => {
                // let dataItems = {}
                // dataItems.x = item
                // dataItems.y  = this.state.recommendedEvent.idle_time[item] ? this.state.recommendedEvent.idle_time[item].length : 0
                // data.push(dataItems)
                data.push(this.state.recommendedEvent.idle_time[item] ? this.state.recommendedEvent.idle_time[item].length : 0)
                if(this.state.recommendedEvent.idle_time[item] && this.state.recommendedEvent.idle_time[item].length <= 4) {
                    colors.push("#00e64d")
                } else if(this.state.recommendedEvent.idle_time[item] && this.state.recommendedEvent.idle_time[item].length <= 8) {
                    colors.push("#66ff99")
                } else if(this.state.recommendedEvent.idle_time[item] && this.state.recommendedEvent.idle_time[item].length <= 12) {
                    colors.push("#ff9999")
                } else if(this.state.recommendedEvent.idle_time[item] && this.state.recommendedEvent.idle_time[item].length <= 16) {
                    colors.push("#ff8080")
                } else if(this.state.recommendedEvent.idle_time[item] && this.state.recommendedEvent.idle_time[item].length <= 20) {
                    colors.push("#ff6666")
                } else {
                    colors.push("#ff0000")
                }
                // data.push(this.state.recommendedEvent.idle_time[item] ? this.state.recommendedEvent.idle_time[item].length : 0)
                // datas.push(this.state.recommendedEvent.idle_time[item] ? this.state.recommendedEvent.idle_time[item] : [])
            })

            topStatusData.data = data
            topStatusData.labels = labels
            topStatusData.name = "weekly data"
            // topStatusData.additionalTooltip = datas

            series.push(topStatusData)
        }

        console.log("colors", colors)

        let options = {
            // tooltip: {
            //     enabled: true,
            //     // custom: function({series, seriesIndex, dataPointIndex, w}) {
            //     //     
            //     //     //val = Math.abs(Math.round(val))
            //     //     
            //     //     //let val = str.replace('-',series[seriesIndex][dataPointIndex]);
            //     //     return '<div class="arrow_box">' +
            //     //         '<span> '+ w.globals.initialSeries[0].data[dataPointIndex].x + 
            //     //         ' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val + ' </span>' +
            //     //         '</div>'
            //     // },
            //     style: {
            //         fontSize: '9px',
            //     },
            //     custom: function({series, seriesIndex, dataPointIndex, w}) {
            //         let val  = series[seriesIndex][dataPointIndex]
            //         let labelName = capitalizeTheFirstLetterOfEachWord(w.globals.initialSeries[seriesIndex].name.replace("_", " "))
            //         let unit = w.globals.initialSeries[seriesIndex].unit !== '' ? w.globals.initialSeries[seriesIndex].unit : ''
            //         let additionalTooltip = ''
            //         let convertTime = w.globals.initialSeries[seriesIndex].convertTime
            //         if(unit === "seconds" && convertTime) {
            //             val = unitTimeConvertionDetail(val, unit)
            //         }
            //         let retrunData = '<div class="arrow_box">'
            //         if(additionalTooltip === '' && !Object.entries(additionalTooltip).length) {
            //             retrunData += ' <span>' +  w.globals.initialSeries[0].data[dataPointIndex].x + '</span> '
            //             if(labelName !== '') {
            //                 retrunData += '<span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> '+ val
            //             } else {
            //                 retrunData += '<span style="color:#039BE5"> ' + val + '</span>'
            //             }
            //             if(unit !== '' && !convertTime) {
            //                 retrunData += '<span> ' + unit + '</span>'
            //             }
            //         } else if(additionalTooltip !== '' && Object.entries(additionalTooltip).length) {
            //             if(!!(val % 1)) {
            //                val = val.toFixed(2)
            //             }
            //             retrunData += '<div class="small">'
            //             retrunData += '<span class="small text-gray3">Name</span> : '+w.globals.initialSeries[0].data[dataPointIndex].x+ '</div>'
            //             Object.entries(additionalTooltip).forEach(([key, value]) => {
            //                 if(value !== '') {
            //                     retrunData += '<div class="small"> <span class="small text-gray3">'+capitalizeTheFirstLetterOfEachWord(key.replace('_', ' '))+'</span> : '+value+'</div>'
            //                 }
            //             })
            //             retrunData += '</div>'
            //         }
            //         retrunData += '</div>'

            //         return retrunData
            //     }
            // },				
            chart: {
                redrawOnParentResize: true,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: true,
                },
                sparkline: {
                    enabled: false
                },
                animations: {
                    enabled: false
                },
                type: 'bar',
                height: 150,
                // stacked: false,
            },
            colors: ['#00B5D4'],
            plotOptions: {
                bar: {
                    horizontal: true,
                    // barHeight: this.props.barHeight,
                    borderRadius: 4,
                    endingShape: 'flat',
                    columnWidth: '1%',
                    // distributed: false, //different color for each bar
                },       
            },
            dataLabels: {
                enabled: true,
                offsetX: -6,
                style: {
                    fontSize: '12px',
                    colors: ['#fff']
                },
                formatter: function (val, opts) {
                    return val + " hrs"
                },
            },
            fill: {
                colors:['#039BE5'],
                type: 'gradient', // gradient
                gradient: {
                    type: 'horizontal', // The gradient in the horizontal direction
                    gradientToColors: ['#5F5BA2'], // The color at the end of the gradient
                    opacityFrom: .9, // transparency
                    opacityTo: 1,
                    stops:[0, 120]
                }
            },    
            grid: {
                show: false,			        
                xaxis: {
                    lines: {
                        show: false
                    },
                },
                yaxis: {
                    lines: {
                        show: false
                    }
                },
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: this.props.paddingLeft ? this.props.paddingLeft : 10
                },
            },
            xaxis: {
                show: true,
                tooltip: {
                    enabled: false
                },
                categories: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
                axisBorder: {
                    show: true,
                    color:'#434B5E'
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: true,
                    style: {
                        colors: "#50586A",
                        fontSize: '13px',
                        fontWeight: 'bolder',
                    },
                }
            },
            yaxis: [{
                show: true,
                axisBorder: {
                    show: true,
                    color: '#969AA7',
                },
                labels: {
                    show: true,
                    style: {
                        colors: "#50586A",
                        fontSize: '13px',
                        fontWeight: 'bolder',
                    },
                }
            }],
            legend: {
                show: false,
            },
        }

        console.log("series", series)
        console.log("options", options)
        this.setState({ weeklySeriesData: series, weeklyOptionData: options, colors })
    }  

    monthlyIdleGraph = () => {
        let idleMonthlyData = this.state.recommendedEvent && this.state.recommendedEvent.idle_time_by_date && this.state.recommendedEvent.idle_time_by_date.length ? this.state.recommendedEvent.idle_time_by_date : []
        let seriesPast = []
        let seriesFuture = []
        
        let pastData = []
        let futureData = []
        let orderIdleMonthlyData = _.orderBy(idleMonthlyData, ['date'], ['asc'])
        let index = 0
        let dataValue = 0
        let seriesOrderBy = 0
        let date = ""
        let noDateAddup = 1
        for(let x=0;x<=35;x++){
            index++
            if(index > 7) {
                let daysRequired = 6
                
                let seriesFutureRow = {}
                seriesFutureRow.name = ""
                seriesFutureRow.seriesOrderBy = seriesOrderBy++
                seriesFutureRow.data = futureData
                seriesFuture.push(seriesFutureRow)
                
                index = 1
                pastData = []
                futureData = []
            }            
            let wValue = ""
            let xValue = ""
            let yValue = -2
            let zValue = ""
            if(orderIdleMonthlyData[x] && orderIdleMonthlyData[x].date) {
                date = orderIdleMonthlyData[x].date
            }
            if(orderIdleMonthlyData[x]) {
                wValue = momentConvertionUtcToLocalTime(orderIdleMonthlyData[x].date, "ddd")
                xValue = momentConvertionUtcToLocalTime(orderIdleMonthlyData[x].date, "ddd")
                yValue = orderIdleMonthlyData[x].idle_time && orderIdleMonthlyData[x].idle_time.length
                zValue = momentConvertionUtcToLocalTime(orderIdleMonthlyData[x].date, "YYYY-MM-DD")
            } else {
                xValue = momentConvertionUtcToLocalTime(addDays(date, noDateAddup), "ddd")
                noDateAddup++
            }

            let dataRow = {
                w: wValue,
                x: xValue,
                y: yValue,
                z: zValue
            }

            futureData.push(dataRow)

        }
        
        let heatOptions = {
            chart: {
                height: 240,
                type: "heatmap",
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
                sparkline: {
                    enabled: false
                },
                animations: {
                    enabled: false
                },
            },
            tooltip: {
                enabled: true,
                style: {
                    fontSize: '9px',
                },
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    let val  = w.globals.initialSeries[seriesIndex].data[dataPointIndex].y
                    let labelName = ""

                    let retrunData = ""
                    if(val >=0) {
                        retrunData += '<div class="arrow_box"> <span>' +  w.globals.initialSeries[seriesIndex].data[dataPointIndex].z + ' '+  w.globals.initialSeries[seriesIndex].data[dataPointIndex].w+'</span> '
                        retrunData += '<span style="color:#039BE5"> ' + val + '</span>'                    
                        retrunData += '</div>'
                    }
                    return retrunData
                }
            },
            plotOptions: {
                heatmap: {
                    shadeIntensity: 0.5,
                    radius: 0,
                    useFillColorAsStroke: false,
                    colorScale: {
                        ranges: [
                            {
                                from: -5,
                                to: -1,
                                name: "low",
                                color: "#858B99" //858B99
                            },
                            {
                                from: 0,
                                to: 4,
                                name: "low2",
                                color: "#7FDEFF" //5DE0F0
                            },
                            {
                                from: 5,
                                to: 8,
                                name: "medium1",
                                color: "#ADCFFF" //5DC5E0
                            },
                            {
                                from: 9,
                                to: 12,
                                name: "extreme",
                                color: "#DABFFF" //5EABD1
                            },
                            {
                                from: 13,
                                to: 16,
                                name: "high",
                                color: "#EABAF6" //5E90C1
                            },
                            {
                                from: 17,
                                to: 20,
                                name: "medium",
                                color: "#EF94D5" //5F76B2
                            },
                            {
                                from: 21,
                                to: 24,
                                name: "low",
                                color: "#E574BC" //5F5BA2
                            },
                        ]
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 1
            },          
            xaxis: {
                show: true,
                tooltip: {
                    enabled: false
                },
                type: 'string',
                axisBorder: {
                    show: false,
                    color:'#434B5E'
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    datetimeUTC: false,
                    show: true,
                    style: {
                        colors: '#B8BBBE',
                        fontSize: '13px',
                        fontWeight: 'bolder',
                    },
                },
            },
            yaxis: {
                show: false,
                // this.props.tickAmount: this.props.yaxisTickAmont ? this.props.yaxisTickAmont : 4,
                axisBorder: {
                    show: false,
                    color: '#969AA7',
                },
                // tickAmount: parseInt(highestValue) <= 1 ? 1 : "",
                labels: {
                    show: false,
                }
            },
            legend: {
                show: false,
            },
        };       
        
        seriesFuture = _.orderBy(seriesFuture, ['seriesOrderBy'], ['desc'])

        console.log("seriesFuture", seriesFuture)

        this.setState({ heatOptions, heatFutureSeries: seriesFuture })
    }

    componentDidUpdate = (prevProps) => {}

    redirect = (url) => {
        window.open(url, '_blank');
    }
	
	handleClickOutside(event) {
		
        if (this.groupByRef && !this.groupByRef.current.contains(event.target)) {
            this.setState({ showGroupByOptions: false })
        } else {
            this.setState({ showGroupByOptions: true })
        }
	}

	render() {
		return (
            <div className=''> {/* ref={this.scrolltoTop} */}
                <div className="d-flex justify-content-between">
                    <div className="">
                        <div className={`${this.state.showIdleTimings ? "mb-2" : ""}`} onClick={() => this.setState({ showIdleTimings: !this.state.showIdleTimings })}>
                            <p className="text-white mb-0 cursorPointer">
                                <span className={`far ${this.state.showIdleTimings ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Idle Timings
                            </p>
                            {this.state.showIdleTimings ?
                                <p className="small text-muted w-100 my-1">Showing {this.state.groupBy} idle horurs for the following </p>
                            : null}
                            {/* {this.state.showIdleTimings && this.props.selectedRecommendedEvent && this.props.selectedRecommendedEvent.data && this.props.selectedRecommendedEvent.data.observation ?
                                <p className="f16 mb-1 text-white">Observations: <span className="ml-1 f12 text-primary-color">
                                    {this.props.selectedRecommendedEvent.data.observation}</span>
                                </p>
                            : null} */}
                        </div>
                    </div>
                    {this.state.showIdleTimings ? 
                        <div className="d-flex">
                            <p className="mb-0 mr-2 mt-2 text-muted">show by</p>
                            <div className="w-10 select-sm-bg curvedDropdown minWidth150" ref={this.groupByRef}>
                                <div className="dropdown">
                                    <span className="dropdown-toggle d-flex justify-content-between px-2 text-white" onClick={() => this.setState({ showGroupByOptions: !this.state.showGroupByOptions })}>
                                        {this.state.groupBy === "Weekly" ? "Weekly" : this.state.groupBy === "Monthly" ? "Monthly" : <span className="placeholder">Select</span>}
                                        <span className="caret"></span>
                                        {/* <i className="fas fa-angle-down"></i> */}
                                    </span>
                                    <ul className={`dropdown-menu p-0 ${this.state.showGroupByOptions ? "show" : ''}`}>
                                        <li onClick={() => this.setState({ groupBy: "Weekly", showGroupByOptions: false })}>
                                            Weekly
                                        </li>
                                        <li onClick={() => this.setState({ groupBy: "Monthly", showGroupByOptions: false })}>
                                            Monthly
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    : null}
                </div>
                <div className={`collapse ${this.state.showIdleTimings ? 'show' : ''}`}>
                    <div className={`m-0 overflowYAuto`}>

                        {this.state.groupBy === "Weekly" ?
                            this.state.weeklySeriesData && this.state.weeklySeriesData.length ?
                                <div className="transparentTooltip">
                                    <Chart options={this.state.weeklyOptionData} series={this.state.weeklySeriesData} type="bar" height={250} width={"100%"}/>
                                    {/* <ul className="stock-legend mt-n2 d-flex justify-content-center">
                                        <li><span className="legend-circle" style={{backgroundColor: "#00e64d"}}></span> <span className="text-muted d-inline-block"> &nbsp; 0-4</span></li>
                                        <li><span className="legend-circle" style={{backgroundColor: "#66ff99"}}></span> <span className="text-muted d-inline-block"> &nbsp; 5-8</span></li>
                                        <li><span className="legend-circle" style={{backgroundColor: "#ff9999"}}></span> <span className="text-muted d-inline-block"> &nbsp; 9-12</span></li>
                                        <li><span className="legend-circle" style={{backgroundColor: "#ff8080"}}></span> <span className="text-muted d-inline-block"> &nbsp; 13-16</span></li>
                                        <li><span className="legend-circle" style={{backgroundColor: "#ff6666"}}></span> <span className="text-muted d-inline-block"> &nbsp; 17-20</span></li>
                                        <li><span className="legend-circle" style={{backgroundColor: "#ff0000"}}></span> <span className="text-muted d-inline-block"> &nbsp; 21-24</span></li>
                                    </ul> */}
                                </div>
                            : null
                        :
                            this.state.heatOptions && this.state.heatFutureSeries ?
                                <div className="d-flex">
                                    <div className="w-60 ml-4">
                                        <Chart options={this.state.heatOptions} series={this.state.heatFutureSeries} type="heatmap"  height={250} />
                                    </div>
                                    <div className='ml-4'>
                                        <div className="d-flex mb-2">
                                            <p className="mb-0 rounded" style={{backgroundColor: "#858B99",width:"50px"}}></p>
                                            <p className="mb-0 ml-2">No Data</p>
                                        </div>
                                        <div className="d-flex mb-2">
                                            <p className="mb-0 rounded" style={{backgroundColor: "#7FDEFF",width:"50px"}}></p>
                                            <p className="mb-0 ml-2">0-4</p>
                                        </div>
                                        <div className="d-flex mb-2">
                                            <p className="mb-0 rounded" style={{backgroundColor: "#ADCFFF",width:"50px"}}></p>
                                            <p className="mb-0 ml-2">5-8</p>
                                        </div>
                                        <div className="d-flex mb-2">
                                            <p className="mb-0 rounded" style={{backgroundColor: "#DABFFF",width:"50px"}}></p>
                                            <p className="mb-0 ml-2">9-12</p>
                                        </div>
                                        <div className="d-flex mb-2">
                                            <p className="mb-0 rounded" style={{backgroundColor: "#EABAF6",width:"50px"}}></p>
                                            <p className="mb-0 ml-2">13-16</p>
                                        </div>
                                        <div className="d-flex mb-2">
                                            <p className="mb-0 rounded" style={{backgroundColor: "#EF94D5",width:"50px"}}></p>
                                            <p className="mb-0 ml-2">17-20</p>
                                        </div>
                                        <div className="d-flex mb-2">
                                            <p className="mb-0 rounded" style={{backgroundColor: "#E574BC",width:"50px"}}></p>
                                            <p className="mb-0 ml-2">21-24</p>
                                        </div>
                                    </div>
                                    {/* <ul className="stock-legend mt-n2 d-flex justify-content-center">
                                        <li><span className="legend-circle" style={{backgroundColor: "#858B99"}}></span> <span className="text-muted d-inline-block"> &nbsp; No Data</span></li>
                                        <li><span className="legend-circle" style={{backgroundColor: "#7FDEFF"}}></span> <span className="text-muted d-inline-block"> &nbsp; 0-4</span></li>
                                        <li><span className="legend-circle" style={{backgroundColor: "#ADCFFF"}}></span> <span className="text-muted d-inline-block"> &nbsp; 5-8</span></li>
                                        <li><span className="legend-circle" style={{backgroundColor: "#DABFFF"}}></span> <span className="text-muted d-inline-block"> &nbsp; 9-12</span></li>
                                        <li><span className="legend-circle" style={{backgroundColor: "#EABAF6"}}></span> <span className="text-muted d-inline-block"> &nbsp; 13-16</span></li>
                                        <li><span className="legend-circle" style={{backgroundColor: "#EF94D5"}}></span> <span className="text-muted d-inline-block"> &nbsp; 17-20</span></li>
                                        <li><span className="legend-circle" style={{backgroundColor: "#E574BC"}}></span> <span className="text-muted d-inline-block"> &nbsp; 21-24</span></li>
                                    </ul> */}
                                </div>
                            : null
                        }
                    </div>
                </div>
            </div>
		)
	}
}
/**
 * Type of the props used in the component
 */
IdleTimeSection.propTypes = {
    listAllAccounts: PropTypes.func,
	listAllRegions: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
    // console.log("state", state)
    return {
        accounts: state.filters.accounts,
        regions: state.filters.regions,
        selectedRecommendedEvent: state.cost.costPropsDetails && state.cost.costPropsDetails.selectedRecommendedEvent ? state.cost.costPropsDetails.selectedRecommendedEvent : {},
    }
}

export default connect(mapStateToProps, {
    listAllAccounts,
	listAllRegions,
})(withRouter(IdleTimeSection))