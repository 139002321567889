/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ManageAlarms.js
 * @author Prakash // on 09/12/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { 
	Input,
	Spinner
} from 'reactstrap'
import { listAllProviders, listAllAccounts, listAllRegions, listAllServices, getAllTagsKeys, getAllTagsValues } from '../../../actions/commonAction'
import { listAssets } from '../../../actions/detectors/detectorsAction'

import { listAlarms } from '../../../actions/aiops/AiopsAction'
import { setResourcePropsDetails } from '../../../actions/assets/assetsAction'
import { AppWrapper } from '../../common/AppWrapper'
import _ from 'lodash'
import { CSVLink } from "react-csv";

import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'
import Search from '../../common/SearchComponent'
import { URL_PATH } from '../../../config/urlPath'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import DropdownTreeSelect from 'react-dropdown-tree-select'

import { getAccountNameFromId, getRegionName, capitalizeTheFirstLetterOfEachWord, onlyNumeric } from '../../../utils/utility'
import ViewSidePanel from './ViewSidePanel'

class ManageAlarms extends Component {
	constructor(props) {
		super(props)
        this.regionRef = React.createRef()
		this.advanceSearchRef = React.createRef()
		this.serviceRef = React.createRef()

		this.state = {
			queryLoading: true,
			showLoading: false,
			selectedProvider: "",

			selectedAccount: "",
            isAccountOpen: false,

            selectedRegion: [],
            isRegionOpen: false,

			services: [],
			showDropdownTree: false,
			serviceResourceArray: [],
			filteredServiceResourceArray: [],
			selectedService: [],
			selectedServiceContent: 'All',

			filteredArray: [],

			configurationTypes: [
				{label: "All", value: "All"},
				{label: "Configured", value: "Configured"},
				{label: "Not Configured", value: "Not Configured"},
			],
			alarmTypes: [
				{label: "All", value: "All"},
				{label: "Metric", value: "Metric"},
				{label: "Application", value: "Application"},
			],
			policyTypes: [
				{label: "Configured", value: "Configured"},
				{label: "Not Configured", value: "Not Configured"}
			],
			operators: [
				{label: "Equal To", value: "="},
				{label: "GreaterThan", value: ">"},
				{label: "Lesser Than", value: "<>"}
			],

			selectedViewOption: "policy_wise",
			selectedView: "policy_wise",
			selectedPolicyType: "Configured",
			
		}
	}

	componentDidMount = () => {
		if(!this.props.providers || !this.props.providers.length) {
			this.props.listAllProviders(promise => {
				if (promise) {
					let providers = this.props.providers
					this.setState({ providers, selectedProvider: providers[0].provider_name },
						() => this.filerInputs("provider")
					)
				} else {
					this.filerInputs()
				}
			})
		} else {
			let providers = this.props.providers.filter(arr => arr.provider_name !== "All")
			this.setState({ providers, selectedProvider: providers[0].provider_name },
				() => this.filerInputs("provider")
			)
		}		
	}

	/**
	 * Filter identifiers based on the filter selection
	 */
	filerInputs = from => {
		let params = {}
		if (this.state.selectedProvider) {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		
		if (from === "provider") {
			this.props.listAllAccounts(params, (promise, accounts) => {
				if (promise) {
					this.setState({ accounts })
				}
			})
			
			this.props.listAllRegions(params, (promise, regions) => {
				if (promise) {
					let providerRegions = regions && regions[0] && regions[0].provider_regions
					if(providerRegions) {
						let provider_regions_row = {}
						provider_regions_row.availability_zones = []
						provider_regions_row.city = "All"
						provider_regions_row.country = "All"
						provider_regions_row.geography = "All"
						provider_regions_row.name = "All"
						provider_regions_row.region = "All"
						provider_regions_row.region_id = "All"
						provider_regions_row.state = "All"
						providerRegions.unshift(provider_regions_row);
					}
					this.setState({ regions: providerRegions })
				} else {
					this.setState({ regions: [] })
				}
			})
			
			this.listAllServices()
		}
		
		this.setState({ queryLoading: true, filteredArray: [] },
			() => this.onSearch()
		)
	}

	listAllServices = () => {
		let params = {}
		if (this.state.selectedProvider) {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		// if(this.state.selectedAccount && this.state.selectedAccount !== "") {
		// 	params.account_id = this.state.selectedAccount
		// }
		// if (this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
		// 	params.region = this.state.selectedRegion
		// }
		
		this.props.listAllServices(params, (promise, services) => {
			if (promise) {
				let serviceResourceArray = []
				let serviceArray = []
				services && services.length &&
				_.chain(services)
				.groupBy('resource_type')
				.map((value, key) => ({ label: key, sub: value }))
				.value()
				.forEach((service, i) => {
					if(i === 0) {
						let dataRow = {}							
						dataRow.category = 'All'
						dataRow.default_region = 'All'
						dataRow.provider_name = 'All'
						dataRow.resource_type = 'All'
						dataRow.service_id = 'All'
						dataRow.service_name = 'All'
						serviceArray.push(dataRow)

						let serRow = {}
						serRow.label = 'All'
						serRow.value = 'All'
						serRow.expanded = true
						let serArray = []
						serRow.children = serArray
						serviceResourceArray.push(serRow)
					}
					
					let serRow = {}
					serRow.label = service.label
					serRow.value = service.label
					serRow.expanded = true
					let serArray = []
					//serviceResourceArray.push(service)
					service.sub.forEach((item, i) => {
						let serItem = {}
						serItem.label = item.service_name
						serItem.value = item.service_name
						serItem.expanded = true
						serArray.push(serItem)
						serviceArray.push(item)
					})
					serRow.children = serArray
					serviceResourceArray.push(serRow)
				})
				this.setState({ services, serviceArray, serviceResourceArray, filteredServiceResourceArray: serviceResourceArray  })
			} else {
				this.setState({ services: [] })
			}
		})
	}

	onChangeServiceDropdown  = (currentNode, selectedNodes) => {
		let filteredServiceResourceArray = []	
		let selectedTreeService = []
		let selectedService = []
		if(selectedNodes.length && currentNode.label !== 'All') {
			selectedNodes.forEach(item => {
				if(item._depth === 0) {
					let child = {}
					child.label = item.label
					child.parent = 1
					selectedTreeService.push(child)
					this.state.serviceResourceArray.forEach(service => {
						if(service.label === item.label) {
							service.children.forEach(child => {
								selectedService.push(child.label)
							})
						}
					})
				} else {
					let child = {}
					child.label = item.label
					child.parent = 0
					selectedTreeService.push(child)
					selectedService.push(item.label)
				}
			})

			filteredServiceResourceArray = []
			this.state.serviceResourceArray.forEach(item => {
				let dataRow = {}
				dataRow.label = item.label
				dataRow.expanded = true
				let childArray = []
				item.children.forEach(child => {
					let childRow = {}
					childRow.label = child.label
					childRow.value = child.label
					childRow.expanded = true
					if(selectedTreeService.length) {					
						selectedTreeService.forEach(ser => {
							if(ser.parent === 1 && item.label === ser.label) {
								dataRow.checked = true
							}						
							if(ser.parent === 1 && item.label === ser.label) {
								childRow.checked = true
							} else if(ser.label === child.label){
								childRow.checked = true
							}			
						})
					}
					childArray.push(childRow)
					dataRow.children = childArray
				})		
				
				if(item.label === 'All' && currentNode.label === 'All') {
					dataRow.checked = true
				}
	
				filteredServiceResourceArray.push(dataRow)
			})
		} else if(currentNode.label === 'All' || !selectedNodes.length) {
			selectedTreeService= []
			selectedService = []
			filteredServiceResourceArray = []
			this.state.serviceResourceArray.forEach(item => {
				let dataRow = {}
				dataRow.label = item.label
				dataRow.expanded = true
				if(currentNode.checked) {
					dataRow.checked = true
				} else if(!currentNode.checked) {
					dataRow.checked = false
				}
				let childArray = []
				item.children.forEach(child => {
					let childRow = {}
					childRow.label = child.label
					childRow.value = child.label
					childRow.expanded = true
					childRow.parent = 1
					if(currentNode.checked) {
						childRow.checked = true
						selectedService.push(childRow.value)
					} else if(!currentNode.checked) {
						childRow.checked = false
					}
					childArray.push(childRow)
					selectedTreeService.push(childRow)
					dataRow.children = childArray					
				})
				filteredServiceResourceArray.push(dataRow)
			})
		}	

		let selectedServiceContent = selectedService.length === 1 ? selectedService : selectedService.length > 2 ? selectedService.length +' Selected' : 'All'
		this.setState({ filteredServiceResourceArray, selectedService, selectedServiceContent, assetDetails: [] },
			() => {
				if(selectedNodes.length || (!selectedNodes.length && currentNode.label !== 'All')) {
					// this.filterIdentifiers('services')
				}

				if(selectedService.length) {
					this.listAssets()
				}
			}
		)
	}

    listAssets = () => {
        
        let params = {}		
		if(this.state.selectedAccount && this.state.selectedAccount !== "") {
			params.account_id = this.state.selectedAccount
		}
		if (this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
			params.region = this.state.selectedRegion
		}
        params.service_name = this.state.selectedService
        
        if(params.resource_type !== '' && params.region !== '') {
            this.props.listAssets(params, (promise, response) => {
                if (promise) {
                    this.setState({ assetDetails: response.results ? response.results : [] })
                } else {
                    this.setState({ assetDetails: [] })
                }
            })
        }
    }

	listAlarms = () => {
		let params = {}	

		// if(this.state.selectedGovernanceType && this.state.selectedGovernanceType !== "") {
		// 	params.governance_type = this.state.selectedGovernanceType
		// }

		// if(this.state.selectedViewOption) {
		// 	params.view = this.state.selectedViewOption
		// }

		if(this.state.selectedProvider && this.state.selectedProvider !== "") {
			params.provider = this.state.selectedProvider.toLowerCase()
		}

		if(this.state.selectedAccount && this.state.selectedAccount !== "") {
			params.account_id = this.state.selectedAccount
		}

		if(this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
			params.region = this.state.selectedRegion
		}

		if(this.state.selectedService && this.state.selectedService.length) {
			params.service_name = this.state.selectedService
		}

		if(this.state.selectedConfigurationType && this.state.selectedConfigurationType !== "All") {
			if(this.state.selectedConfigurationType === "Not Configured") {
				params.missing_alarm = true
			} else {
				params.missing_alarm = false
			}
		}

		if(this.state.selectedAlarmType && this.state.selectedAlarmType !== "All") {
			params.alarm_type = this.state.selectedAlarmType
		}
		
		if(this.state.selectedPeriod && this.state.selectedPeriod !== "") {
			params.period = this.state.selectedPeriodOperator+""+this.state.selectedPeriod
		}

		if(this.state.selectedEvaluationPeriod && this.state.selectedEvaluationPeriod !== "") {
			params.evaluation_period = this.state.selectedEvaluationPeriodOperator+""+this.state.selectedEvaluationPeriod
		}

		if(this.state.selectedMetrics && this.state.selectedMetrics.length) {
			params.metric_name = this.state.selectedMetrics
		}

		if(this.state.selectedAsset && this.state.selectedAsset.length) {
			params.asset_id = this.state.selectedAsset
		}

		if(this.state.selectedTags && this.state.selectedTags.length) {
			params.tags = this.state.selectedTags
		}

		// params.service_name = "ec2"

		if(this.state.selectedFilters && this.state.selectedFilters.length) {
			let groupedData = _.chain(this.state.selectedFilters)
							.groupBy('label')
							.map((value, key) => ({ groupLabel: key, groupValue: value })).value()

			groupedData.forEach(row => {
				let data = []
				row.groupValue.forEach(item => {
					data.push(item.value)
				})
				params[row.groupLabel] = data
			})
		}

		// params.not_configured = false
		// if(this.state.selectedPolicyType === "Not Configured") {
		// 	params.not_configured = true
		// }
		
		this.props.listAlarms(params, (promise, response) => {
			if(promise) {
				this.setState({ alarmsList: response, filteredArray: response, queryLoading: false },
					() => this.formHeaders()	
				)
			} else {
				this.setState({ alarmsList: [], filteredArray: [], queryLoading: false })
			}
		})
	}

	formHeaders = () => {
		let dataResult = this.state.filteredArray && this.state.filteredArray.length ? this.state.filteredArray : []
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === "string") {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})
			this.setState({ headers })
		}
	}

	getAllTagsKeys = () => {
		
		if(!this.props.tagKeys || !this.props.tagKeys.length) {
			let params = {}
			// params.account_id = "265469181489"
			// params.region = "us-east-2"
			this.props.getAllTagsKeys(params, (promise, response) => {})
		}
	}

	getAllTagsValues = () => {
		let params = {}
		params.tags = [{ "key": this.state["tag_key"] }]
		this.props.getAllTagsValues(params, (promise, response) => {
			if(promise) {
				this.setState({ ["tagValues"]: response })
			}
		})
	}

	addNewTag = () => {
		let selectedTags = this.state["selectedTags"] ? this.state["selectedTags"] : []
		if(this.state["tag_key"] && this.state["tag_key"] !== "" && this.state["tag_value"] && this.state["tag_value"] !== "" ) {
			let dataRow = {
				key: this.state["tag_key"],
				value: this.state["tag_value"],
			}
			selectedTags.push(dataRow)
		}


		this.setState({ selectedTags: selectedTags, tag_key: "",  tag_value: "", tagValues: [] })
	}

	removeTagSection = (tag) => {
		// filteredResult = this.state.selectedTags.filter(e => e.key !== tag.key && e.value !== tag.value)
		let filteredResult = this.state.selectedTags.filter(e => e !== tag)
		this.setState({ selectedTags: filteredResult })
	}

    handleClickOutside = (event) => {

		if (this.regionRef && !this.regionRef.current.contains(event.target)) {
			this.setState({ isRegionOpen: false })
		} else {
			this.setState({ isRegionOpen: true })
		}

		if (this.advanceSearchRef && this.advanceSearchRef.current && !this.advanceSearchRef.current.contains(event.target)) {
			this.setState({ showMainDropdownOption: false, selectedOption: "" })
		}
    }

	handleMultiSelectChange = (field, arrayValue, state) => {
		let value = arrayValue.map(item => item.value ? item.value : item)
		let selectedValue = []
		let prevState = this.state[field] ? this.state[field] : []

		console.log("value", value)
		if(value.includes("All")) {
			if(!prevState.includes("All")) {
				if(state.filter(e => e.value).length) {
					console.log(state.map(e => e.value))
					selectedValue = state.map(e => e.value)
				} else {
					selectedValue = state
				}
			} else {
				const index = value.indexOf("All");
				if (index > -1) {
					value.splice(index, 1);
				}
				selectedValue = value
			}
		} else if(!prevState.includes("All")) {
			selectedValue = value
		}

		if(selectedValue.length && !selectedValue.includes("All")) {
			if(!selectedValue.includes("All") && selectedValue.length === (state.length -1)) {
				selectedValue.push("All")
			}
		}

		this.setState({ [field]: selectedValue }, 
			() => this.listAllServices()	
		)
	}

	getMultiSelectedCount = (type, array) => {
		return array.length && array.includes("All") ? "All" :  array.length ? array.length +" Selected" : "All"
	}

	onSearch = () => {
		this.setState({ queryLoading: true }, () => this.listAlarms())
	}
	
	onReset = () => {

		if(this.state.selectedFilters && this.state.selectedFilters.length) {
			let groupedData = _.chain(this.state.selectedFilters)
							.groupBy('label')
							.map((value, key) => ({ groupLabel: key, groupValue: value })).value()
			
			groupedData.forEach(row => {
				row.groupValue.forEach(item => {
					this.setState({ ["options_"+this.state.selectedOption+"".value]: false })
				})
			})
		}

		this.setState({ 
			selectedGovernanceType: "",
			selectedAccount: "",
			selectedRegion: [],
			selectedTags: [],
			selectedUsers: [],
			selectedRoles: [],
			selectedFilters: [],
			selectedPolicyType: "Configured",
		})
	}

    comparisonFilter = () => {
		if(!this.state.searchText || this.state.searchText === '') {
            this.setState({ showMainDropdownOption: this.state.showMainDropdownOption ? false : true, selectedDefaultOption: '' })
		}
	}
    
    filterOnchange = () => {
		this.setState({ showMainDropdownOption: false }, 
			() => this.onChangeChildOperation()
		)		
	}
		
	onChangeChildOperation = () => {
		let filterData = _.orderBy(this.state.policyTypeOptions, ['Asc'])
		if(this.state.searchText !== '') {
			filterData = filterData.filter(
				filter => 
				filter.toLocaleLowerCase().includes(this.state.searchText) ||
				filter.includes(this.state.searchText)
			)
		}
		this.setState({ 
			selectedDefaultOption: '',
			mainDropdown: filterData, 
			showMainDropdownOption: 
			this.state.searchText === '' ? false : true 
		})
	}

	getSelectedOptionValues = () => {
		let details = this.state.metaData[this.state.selectedGovernanceType]
		let selectedOptionValues = details && details[this.state.selectedOption] ? details[this.state.selectedOption] : []

		this.setState({ childDropdownOptions: selectedOptionValues, filteredChildDropdownOptions: selectedOptionValues })
	}

	onSelectChildOptions = () => {

		let data = this.state.selectedFilters ? this.state.selectedFilters : []

		let dataRow = {}
		dataRow.label = this.state.selectedOption
		dataRow.value = this.state.selectedChildOptions

		data.push(dataRow)

		this.setState({ mainDropdown: this.state.policyTypeOptions, searchText: "", selectedFilters: data, showMainDropdownOption: false, selectedChildOptions: "", selectedOption: ""  })
	}

	updateMultiSelectedOptions = (row, options) => {
		let data = this.state.selectedFilters ? this.state.selectedFilters : []
		let selectedMultiOptions = this.state.selectedMultiOptions ? this.state.selectedMultiOptions : []

		if(this.state["options_"+this.state.selectedOption+"_"+row]) {				
			let dataRow = {}
			dataRow.label = this.state.selectedOption
			dataRow.value = row

			data.push(dataRow)
		} else {
			data = data.filter(e => e.value !== row)
		}

		this.setState({ selectedFilters: data, selectedMultiOptions })
	}

	onClickAdvancedSearch = () => {
		this.getAllTagsKeys()
	}

	onClickAlarm = (selectedAlarm) => {
		this.props.setResourcePropsDetails('selectedAlarm', selectedAlarm)
		this.setState({ selectedAlarmState: selectedAlarm, showAlarmDetailView: true  })
	}

	removeFilterData = (row) => {
		let selectedFilters = this.state.selectedFilters.filter(e => e.label === row.label && e.value === row.value)

		this.setState({ selectedFilters })
	}

	addMetrics = () => {

		let selectedMetrics = this.state.selectedMetrics ? this.state.selectedMetrics : []

		if(this.state.enteredMetrics !== "" && !selectedMetrics.includes(this.state.enteredMetrics)) {
			selectedMetrics.push(this.state.enteredMetrics)
		}

		this.setState({ selectedMetrics, enteredMetrics: "" })
	}

	removeMetrics = (item) => {
		let selectedMetrics = this.state.selectedMetrics.filter(e => e !== item)
		this.setState({ selectedMetrics })
	}

	/**
	 * Renders governance Dashboard
	 */
	render() {
		return (
			<div className="container-fluid overflow-auto flex-grow-1 bg-muted" onClick={ (event) => { this.handleClickOutside(event) } }>
				<div className={`loaderOverlay ${this.state.queryLoading ? "" : "displayNone"}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
				{this.state.showAlarmDetailView ? (
					<ViewSidePanel
						selectedAlarmState={this.state.selectedAlarmState}
						closeSidePanel={() => this.setState({ showAlarmDetailView: false })}
					/>
				) : null}

				<div className="row">
					<div className="col-sm-12 p-0">
						<div className="title-section p-2">
							<div className="row">
								<div className="col-4 align-self-center">
									<h6 className="text-white m-0">Alarms</h6>
									<p className="text-white m-0">
										Filter resource alarms on your Cloud
									</p>
								</div>
							</div>
						</div>
						<div className="container-fluid bg-dark m-2 pt-3 rounded">
							<div className="bg-dark3 rounded p-3">
								<div className="containerFilterSection mb-2">
									<div className="d-flex">
										<div className="mr-4">
											<div className="d-flex justify-content-between">
												<label className="align-self-center m-0">Provider </label>
												{this.state.hasError && (this.state.selectedProvider || this.stae.selectedProvider === "") ? (
													<small className="text-issues">required</small>
												) : null}
											</div>
											<Select
												isSearchable={false}
												className={`selectOption f13 p-0`}
												components={{
													IndicatorSeparator: () => null
												}}
												value={({
													value: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : "",
													label: this.state.selectedProvider && this.state.selectedProvider.toLowerCase() !== "" ? this.state.selectedProvider.toUpperCase() : <span className="placeholder">Select</span>
												})}
												options={this.state.providers && this.state.providers.map(row => ({
													value: row.provider_name,
													label: row.provider_name,	
												}))}
												onChange={event => this.setState({
													selectedProvider: event.value,
													changesInFilter: true,
												})}
											/>
										</div>
										<div className="mr-4">
											<div className="d-flex justify-content-between">
												<label className="align-self-center m-0">Account </label>
												{this.state.hasError && (this.state.selectedAccount || this.stae.selectedAccount === "") ? (
													<small className="text-issues">required</small>
												) : null}
											</div>										
											<Select
												isSearchable={false}
												className={`selectOption f13 p-0`}
												components={{
													IndicatorSeparator: () => null
												}}
												value={({
													value: this.state.selectedAccount && this.state.selectedAccount !== "" ? this.state.selectedAccount : "",
													label: this.state.selectedAccount && this.state.selectedAccount !== "" ? getAccountNameFromId(this.state.selectedAccount, this.state.accounts) : <span className="placeholder">Select</span>
												})}
												options={this.state.accounts && this.state.accounts.map(row => ({
													value: row.account_id,
													label: row.account_name,	
												}))}
												onChange={event => this.setState({
													selectedAccount: event.value,
													changesInFilter: true,
												})}
											/>
										</div>
										<div className="mr-4" ref={this.regionRef}>
											<div className="d-flex justify-content-between">
												<label className="align-self-center pb-5p">Region </label>
												{this.state.hasError && (!this.state.selectedAccount || !this.state.selectedAccount.length) ? (
													<small className="text-issues">required</small>
												) : null}
											</div>
											<div className={`multiSelectOption align-self-end`}>
												<ReactMultiSelectCheckboxes
													placeholderButtonLabel="All"
													getDropdownButtonLabel={() => this.getMultiSelectedCount("selectedRegion", this.state.selectedRegion ? this.state.selectedRegion : [])}
													options={this.state.regions && this.state.regions.map(row => ({
														value: row.region,
														label: row.name,
													}))}
													onChange={arr => { this.handleMultiSelectChange("selectedRegion", arr ? arr : [], this.state.regions, "region")}}
													value={this.state.selectedRegion && this.state.selectedRegion.map(row => ({
														value: row
													}))}
												/>
											</div>
										</div>
										<div className="mr-4" style={{ minWidth: "150px" }} ref={this.serviceRef}>
											<label className="mb-1 text-white w-100">Service</label>
											{/* <label className="text-white w-100 f13 font-weight-normal" onClick={() => this.setState({ showDropdownTree: true }) } >{this.state.selectedService && this.state.selectedService.length ? this.state.selectedService.length ===  1 ? this.state.selectedService : this.state.selectedService.length +' Selected' : 'All' }</label> */}
											<div className={`serviceTreeDropdown position-absolute ${this.state.filteredServiceResourceArray && this.state.filteredServiceResourceArray.length ?'': 'disabled'}`}>
												<DropdownTreeSelect 
													//values={this.state.selectedServiceContent}
													texts={{ placeholder: this.state.selectedServiceContent }}
													data={this.state.filteredServiceResourceArray}
													onChange={this.onChangeServiceDropdown}
													className="bootstrap-demo"
													keepTreeOnSearch={true}
													keepChildrenOnSearch={true}
												/>
											</div>
										</div>
										<div className="mr-4">
											<div className="d-flex justify-content-between">
												<label className="align-self-center m-0">Config Type</label>
												{this.state.hasError && (!this.state.selectedConfigurationType || this.state.selectedConfigurationType === "") ? (
													<small className="text-issues">required</small>
												) : null}
											</div>
											<Select
												isSearchable={false}
												className={`selectOption f13 p-0`}
												components={{
													IndicatorSeparator: () => null
												}}
												value={({
													value: this.state.selectedConfigurationType && this.state.selectedConfigurationType !== "" ? this.state.selectedConfigurationType : "",
													label: this.state.selectedConfigurationType && this.state.selectedConfigurationType !== "" ? (this.state.governanceTypesMasterData && this.state.governanceTypesMasterData.filter(e => e.value === this.state.selectedConfigurationType).length ? this.state.governanceTypesMasterData.filter(e => e.value === this.state.selectedConfigurationType)[0].label : this.state.selectedConfigurationType) : <span className="placeholder">Select</span>
												})}
												options={this.state.configurationTypes && this.state.configurationTypes.map(row => ({
													value: row.value,
													label: row.label,
												}))}
												onChange={event => this.setState({
													selectedConfigurationType: event.value,
													changesInFilter: true,
												},
													// () => this.getPolicyInformation()
												)}
											/>
										</div>
										<div className="mr-4">
											<div className="d-flex justify-content-between">
												<label className="align-self-center m-0">Alarm Type </label>
												{this.state.hasError && (!this.state.selectedAlarmType || this.state.selectedAlarmType === "") ? (
													<small className="text-issues">required</small>
												) : null}
											</div>
											<Select
												isSearchable={false}
												className={`selectOption f13 p-0`}
												components={{
													IndicatorSeparator: () => null
												}}
												value={({
													value: this.state.selectedAlarmType && this.state.selectedAlarmType !== "" ? this.state.selectedAlarmType : "",
													label: this.state.selectedAlarmType && this.state.selectedAlarmType !== "" ? (this.state.governanceTypesMasterData && this.state.governanceTypesMasterData.filter(e => e.value === this.state.selectedAlarmType).length ? this.state.governanceTypesMasterData.filter(e => e.value === this.state.selectedAlarmType)[0].label : this.state.selectedAlarmType) : <span className="placeholder">Select</span>
												})}
												options={this.state.alarmTypes && this.state.alarmTypes.map(row => ({
													value: row.value,
													label: row.label,
												}))}
												onChange={event => this.setState({
													selectedAlarmType: event.value,
													changesInFilter: true,
												},
													// () => this.getPolicyInformation()
												)}
											/>
										</div>
										{!this.state.showAdvancedSearch ?
											<div className="align-self-center">
												<div className="d-flex align-self-center">
													<div className="btn-group" role="group" aria-label="governance-dash">
														<button type="button" className={`cursorPointer btn btn-secondary border-right`} onClick={()=> this.onSearch()}>Search</button>
														<button type="button" className={`cursorPointer btn btn-secondary border-left`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
													</div>
												</div>
												
												<p className="mb-0 mt-2 text-info cursorPointer" onClick={() => this.setState({ showAdvancedSearch: !this.state.showAdvancedSearch }, () => this.onClickAdvancedSearch())}>Advanced Search</p>
											</div>
										: null}
									</div>
									{this.state.showAdvancedSearch ? 
										<React.Fragment>
										{this.state.selectedFilters && this.state.selectedFilters.length ? 
											<div className="d-flex flex-wrap mt-3 mb-2">
												{this.state.selectedFilters.map(row => {
													return (
														<small className={`badge badge-secondary mr-1`}>{row.label+" : "+row.value}
														<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeFilterData(row) } ></i>
														</small>
														
													)
												})}
											</div>
										: null}
										<div className="d-flex mt-3">
											<div className="d-flex bg-dark p-3 rounded w-25">
												<label className="align-self-end m-0">Period in seconds</label>
												<Select
													isSearchable={true}
													components={{
														IndicatorSeparator: () => null
													}}
													className={`selectOption selectBoxBottomColor mr-3 mt-2 ml-2`}
													value={({
														value: this.state.selectedPeriodOperator,
														label: this.state.selectedPeriodOperator && this.state.selectedPeriodOperator !== "" && this.state.operators.filter(e => e.value === this.state.selectedPeriodOperator).length ? this.state.operators.filter(e => e.value === this.state.selectedPeriodOperator)[0].label : <span className="placeholder">Select</span>
													})}														
													
													options={this.state.operators && this.state.operators.map(item => ({
														value: item.value,
														label: item.label,	
													}))}
													onChange={event => this.setState({ selectedPeriodOperator: event.value })}
												/>
												<input 
													type="text" 
													className={`inputField mt-3 w-20 ml-2`}
													value={this.state.selectedPeriod}
													placeholder={"ex 300"}
													onChange={e => this.setState({ selectedPeriod: onlyNumeric(e.target.value) }) }
												/>
											</div>
											<div className="d-flex bg-dark p-3 rounded ml-3 w-25">
												<label className="align-self-end m-0">Evaluation Period</label>
												<Select
													isSearchable={true}
													components={{
														IndicatorSeparator: () => null
													}}
													className={`selectOption selectBoxBottomColor mr-3 mt-2 ml-2`}
													value={({
														value: this.state.selectedEvaluationPeriodOperator,
														label: this.state.selectedEvaluationPeriodOperator && this.state.selectedEvaluationPeriodOperator !== "" && this.state.operators.filter(e => e.value === this.state.selectedEvaluationPeriodOperator).length ? this.state.operators.filter(e => e.value === this.state.selectedEvaluationPeriodOperator)[0].label : <span className="placeholder">Select</span>
													})}														
													
													options={this.state.operators && this.state.operators.map(item => ({
														value: item.value,
														label: item.label,	
													}))}
													onChange={event => this.setState({ selectedEvaluationPeriodOperator: event.value })}
												/>
												<input 
													type="text" 
													className={`inputField mt-3 w-20 ml-2`}
													value={this.state.selectedEvaluationPeriod}
													onChange={e => this.setState({ selectedEvaluationPeriod: onlyNumeric(e.target.value) }) }
												/>
											</div>
										</div>
										<div className="d-flex mt-3">
											<div className="d-flex bg-dark p-3 rounded">
												<label className="align-self-end m-0">Metric&nbsp;Name</label>
												<input 
													type="text" 
													className={`inputField mt-3 ml-2`}
													style={{minWidth: "300px"}}
													value={this.state.enteredMetrics}
													onChange={e => this.setState({ enteredMetrics: e.target.value }) }
												/>
												
												<span className={`far fa-plus cursor-pointer f18 mt-2 mb-1 align-self-end mr-4`} onClick={() => this.addMetrics()}></span>

												{this.state.selectedMetrics && this.state.selectedMetrics.length ? 
													this.state.selectedMetrics.map(item => {
														return(
															<span className="badge badge-secondary mr-2 mt-2 align-self-center f14 px-2">{item}
																<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeMetrics(item) } ></i>
															</span>
														)
													})
												: null}
											</div>
											{this.state.selectedService && this.state.selectedService.length ?
												<div className="d-flex bg-dark p-3 rounded ml-3" ref={this.assetRef}>
													<div className="d-flex justify-content-between">
														<label className="align-self-end mr-2">Asset </label>
													</div>
													<div className={`multiSelectOption align-self-end`}>
														<ReactMultiSelectCheckboxes
															placeholderButtonLabel="All"
															getDropdownButtonLabel={() => this.getMultiSelectedCount("selectedAsset", this.state.selectedAsset ? this.state.selectedAsset : [])}
															options={this.state.assetDetails && this.state.assetDetails.map(row => ({
																value: row.asset_id,
																label: row.asset_name,
															}))}
															onChange={arr => { this.handleMultiSelectChange("selectedAsset", arr ? arr : [], this.state.assetDetails, "assets")}}
															value={this.state.selectedAsset && this.state.selectedAsset.map(row => ({
																value: row
															}))}
														/>
													</div>
												</div>
											: null}
										</div>
										<div className="d-flex mt-3">
											<div className="d-flex bg-dark p-3 rounded">
												<div className="d-flex justify-content-between">
													<label className="align-self-end mb-0 mr-2">Tags </label>
												</div>
												<div className="d-flex">
													<Select
														isSearchable={true}
														components={{
															IndicatorSeparator: () => null
														}}
														className={`selectOption selectBoxBottomColor mr-3 mt-2`}
														value={({
															value: this.state.tag_key,
															label: this.state.tag_key && this.state.tag_key !== "" ? this.state.tag_key : <span className="placeholder">Key</span>
														})}														
														
														options={this.props.tagKeys && this.props.tagKeys.map(item => ({
															value: item,
															label: item,	
														}))}
														onChange={event => this.setState({
															tag_key: event.value,
															tagValues: [],
														},
															() => this.getAllTagsValues()
														)}
													/>
													<Select
														isSearchable={true}
														components={{
															IndicatorSeparator: () => null
														}}
														className={`selectOption selectBoxBottomColor mr-3 mt-2`}
														value={({
															value: this.state.tag_value && this.state.tag_value !== "" ? this.state.tag_value : 'Select',
															label: this.state.tag_value && this.state.tag_value !== "" ? this.state.tag_value : <span className="placeholder">Value</span>
														})}													
														
														options={this.state.tagValues && this.state.tagValues.map(item => ({
															value: item,
															label: item,	
														}))}
														onChange={event => this.setState({ tag_value: event.value })}
													/>
													<span className={`far fa-plus cursor-pointer f18 mt-2 mb-1 align-self-end mr-4`} onClick={() => this.addNewTag()}></span>

													{this.state.selectedTags && this.state.selectedTags.length ? 
														this.state.selectedTags.map((tag, index) => {
															return(
																<span className="badge badge-secondary mr-2 mt-2 align-self-center f14 px-2">{tag.key +" : "+tag.value}
																	<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeTagSection(tag) } ></i>
																</span>
															)
														})
													: null}
												</div>
											</div>
										</div>

										<div className='mt-4 d-flex'>
											<div className="btn-group" role="group" aria-label="governance-dash">
												<button type="button" className={`cursorPointer btn btn-secondary border-right`} onClick={()=> this.listAlarms()}>Search</button>
												<button type="button" className={`cursorPointer btn btn-secondary border-left`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
											</div>
											<button className="ml-4 btn btn-secondary" onClick={() => this.setState({ showAdvancedSearch: false })} >Cancel</button>
										</div>
										</React.Fragment>
									: null}
								</div>
							</div>

							<div className="d-flex justify-content-between mt-3 py-2">
								<p className="mb-0 align-self-center">Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.state.alarmsList && this.state.alarmsList.length} Alarm(s)</p>
								{this.state.alarmsList && this.state.alarmsList.length ?
									<div className="d-flex">
										<CSVLink 
											data={this.state.filteredArray ? this.state.filteredArray : []} 
											headers={this.state.headers ? this.state.headers : []}
											filename={"resource-alarms.csv"}
											className={"mt-2"}
											target="_blank"
										>
											<span className="d-flex text-primary-color mr-2">
												<i className="fas fa-download text-primary-color mr-2"></i>
												{/* csv */}
											</span>
										</CSVLink>
										<div className={`mb-n2`}>
											<Search
												data={this.state.alarmsList ? this.state.alarmsList : []}
												filteredData={filteredArray => {
													this.setState({ filteredArray })
												}}
											/>
										</div>
									</div>
								: null}
							</div>
							<div className='tab-content' id='myTabContent'>
								<div className='tab-pane fade show active' id='home' role='tabpanel' aria-labelledby='home-tab'>
									{this.state.alarmsList && this.state.alarmsList.length && !this.state.showLoading ?
										<ResizeableDarkThemeTable
											columns={[
												{
													Header: "Name",
													accessor: "alarm_name",
													width: 300
												},
												{
													Header: "Resources",
													Cell: cellInfo => (
														(cellInfo.row.original.provider ? cellInfo.row.original.provider.toUpperCase() : '') + ': ' + cellInfo.row.original.account_id + ': ' + (cellInfo.row.original.region ? (cellInfo.row.original.region === 'NA' ? 'Global' : cellInfo.row.original.region) : '')
													),
													width: 250
												},
												{
													Header: "Asset",
													accessor: "asset_name",
													width: 300
												},
												{
													Header: "Service",
													accessor: d => d.resource_type+" : "+d.service_name,
													width: 250
												},									
												{
													Header: "Description",
													accessor: "description",
													width: 1350
												},
												{
													Header: "Actions",
													Cell: cellInfo => (
														<div className="d-flex justify-content-between align-self-center">
															<p className="m-0 p-0 mr-1 cursorPointer" onClick={() => this.onClickAlarm(cellInfo.row.original)}><i className="fas fa-pencil"></i></p>
														</div>
													),
													width: 50
												}
											]}
											data={this.state.filteredArray ? this.state.filteredArray : this.state.alarmsList}
											perPage={20}
											dashboard={(this.props.filteredArray ? this.props.filteredArray.length : (this.state.alarmsList && this.state.alarmsList.length)) ? true : false}
											sortByColumn={"Date"}
											riskTooltip={[0]}
											onClickRow={tableRow => {}}
											// tableSize={"table-sm"}
											// tableHead={"bg-dark"}
											tooltipFor={[]}
										/>
									: (!this.state.showLoading && !this.state.alarmsList) ||
										(this.state.alarmsList && !this.state.alarmsList.length && !this.state.showLoading) ? 
										<div className="d-flex justify-content-center m-4">
											<p>There are no data on this criteria. Please try adjusting your filter.</p>
										</div>
									: null}
									{this.state.showLoading ? (
										<div className="d-flex justify-content-center m-4">
											<Spinner className="text-center" color="white" size="lg" />
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ManageAlarms.propTypes = {
	listAllProviders: PropTypes.func,
	listAllAccounts: PropTypes.func,
	listAllRegions: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log("-",state)
	return {
		providers: state.filters.providers,
		services: state.filters.services,
		tagKeys: state.filters.tagKeys,
	}
}

export default AppWrapper(ManageAlarms, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
	listAllServices,
	listAssets,
	getAllTagsKeys,
	getAllTagsValues,

	setResourcePropsDetails,
	listAlarms,
})
