/*************************************************
 * Tvastar
 * @exports
 * @file ReachabilityAction.js
 * @author parakash // 21/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'

import {
	RECHABILITYENDPOINT,
	GET_RECHABILITY_ANALYSIS_RESULTS,
} from '../../config'

/**
 * Action to get rechability analysis resutls
 * @param {Object} body
 * @param {Function} callback
 */
export const getRechabilityAnalysisResults = (body, callback) => {
	return async () => {
		await API.post(RECHABILITYENDPOINT, GET_RECHABILITY_ANALYSIS_RESULTS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			callback(false)
		})
    }
}
