import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { apiToDevCall } from '../../../actions/commonAction'

class SelectField extends Component {

  constructor(props) {
    super(props);
    this.state = props || {};
    this.state = {
      validationMessage: '',
      parentValue: this.props.value,
      selectOptions: this.props.selectOptions,
    }
    this.props.recordSelection(this.props.name, this.props.value);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = () => {
      if(this.props.apiToCall && Object.entries(this.props.apiToCall).length && !this.props.selectOptions) {
        let params = {}
        // let keyRequest =  this.props.apiToCall.body && this.props.apiToCall.body.keys
        // keyRequest && keyRequest.map(item => {
        //     params[item.key] = item.value
        // })

        let valueRequest =  this.props.apiToCall.body && this.props.apiToCall.body.values
        valueRequest && valueRequest.forEach(item => {
            params[item.key] = item.value
        })

        // let response_field = this.props.apiToCall.response_field ? this.props.apiToCall.response_field : ''
        let name = this.props.apiToCall.name

        if(!this.state[this.props.name]) {
            this.props.apiToDevCall(name, params, (promise, response) => {
              console.log('response', response)
              if (promise) {
                if(name === '/get-vm-types') {
                    let vmArray = []
                    response.length && response[0].results && response[0].results.forEach(res => {
                      res.vm_series && res.vm_series.length && res.vm_series.forEach(series => {
                        // let vmRow = {}
                        // vmRow.description = res.description
                        // vmRow.series = series.series
                        // vmArray.push(vmRow)

                        res.vm_series && res.vm_series.length && res.vm_series.forEach(series => {
                          series.vm_types && series.vm_types.forEach(types => {
                            let typeRow = {}
                            // typeRow.description = res.description
                            // typeRow.series = series.series
                            // typeRow.options = types.instanceType
                            // typeRow.vm_types = types
                            typeRow.lable = types.instanceType
                            typeRow.options = types.instanceType
                            vmArray.push(typeRow)
                          }) 
                        })
                      })
                  })
                  this.setState({ selectOptions: vmArray })
                }
              }
          })
        }
      } 
  }

  handleChange = (event) => {
    let value = event.value;
    this.props.recordSelection(this.props.name, value);
  }

  render() {
    return (
      <div style={{ display: "inline" }}>
        <Select
          // menuIsOpen={true}
          className={this.props.className ? this.props.className : ''}
          isSearchable={this.state.selectOptions && this.state.selectOptions.length > 10 ? true : false}
          value={({
              value: this.props.value ? this.props.value : 'Select',
              label: this.props.value ? this.props.value : 'Select',
          })}
          options={this.state.selectOptions && this.state.selectOptions.map(item => ({
              value: item.label ? item.label : item.options,
              label: item.options,
          }))}
          onChange={event => this.handleChange(event)}
        />
      </div>
    )
  }
};
 
/**
 * Type of the props used in the component
 */
SelectField.propTypes = {  
    apiToDevCall: PropTypes.func
}


export default connect(null, {
  apiToDevCall
})(withRouter(SelectField))