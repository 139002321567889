/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 01/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 * 
 *************************************************/
import React, { Component } from 'react'
import { AppWrapper } from '../../common/AppWrapper'
// import PropTypes from 'prop-types'
import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'
import { listAllProviders, listAllAccounts, listAllRegions } from '../../../actions/commonAction'
import { getObservabilityAlertsDailyTrend, getObservabilityEventsDailyTrend, getObservabilityAnomalyDailyTrend } from '../../../actions/aiops/ObservabilityAction'
import { getObservabilityDailyTrend } from '../../../actions/aiops/ErrorAction'

import { momentDateGivenFormat, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, addHours, subHours, twoDateDiffrence, currentLocaltime, subDays, capitalizeFirstLetter, getDayFromSelectedDuration } from '../../../utils/utility'
// import { CUSTOM_DURATION_FILTER_OPTIONS } from '../../../utils/constants'

// import _ from 'lodash'

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

import ErrorsDashboard from './errors/ErrorsDashboard'
import AlertsDashboard from './alerts/AlertsDashboard'
import EventsDashboard from './events/EventsDashboard'
import AnomalyDashboard from './anomaly/AnomalyDashboard'

import Chart from 'react-apexcharts'
import Analysis from './Analysis'
import QueryList from './QueryList'
import QueryResults from './QueryResults'

import { RangePicker } from "react-minimal-datetime-range";
import "react-minimal-datetime-range/lib/react-minimal-datetime-range.min.css";

const disableFutureDt = current => {
    return current.isBefore(currentLocaltime()) && current.isAfter(subDays(currentLocaltime(), 30))
}

const durationOptions = [    
	{ value: '+1h', option: 'Last 1 hour' },
    { value: '+3h', option: 'Last 3 hours' },
    { value: '+6h', option: 'Last 6 hours' },
    { value: '+12h', option: 'Last 12 hours' },
	{ value: '+24h', option: 'Last 24 hours' },
	{ value: '+3d', option: 'Last 3 days' },
	{ value: '+7d', option: 'Last 1 week' },
    { value: '+14d', option: 'Last 2 weeks' },
    { value: '+30d', option: 'Last 1 month' },
	{ value: 'custom', option: 'Custom Date' },
]

const pageTypes = ["Alerts", "Events", "Anomalies", "Errors"]

let timer = null;

class LandingPage extends Component {
    constructor(props) {
        super(props)
        this.accountRef = React.createRef()
        this.daterRangeRef = React.createRef()
        this.jumpTo = React.createRef()
        
        this.state = {
            minMaxHeight: "700px",
            showLoading: true,
            providers1:[
                {provider_name: "AWS"}
            ],

            //filter section start

            selectedProvider: "",

            selectedAccount: [],
            isAccountOpen: false,

            selectedRegion: [],
            isRegionOpen: false,

            selectedDuration: "+3h",

            //filter section end

            selectionRange: [{
				startDate: new Date(),
				endDate: new Date(),
				key: "selection"
			}],
            
            jumpDate: "",
            pageType: "Alerts"
        }
    }

	componentDidMount = () => {
        if(this.props.providers && this.props.providers.length) {
			this.setState({ providers: this.props.providers, selectedProvider: this.props.providers[0].provider_name },
				() => {
					this.getFilterData('provider')
				}
			)
		} else {
			this.props.listAllProviders((promise, providers) => {
				if (promise) {
					this.setState({ providers, selectedProvider: providers[0].provider_name },
						() => {
							this.getFilterData('provider')
						}
					)
				} else {
					this.setState(
						{ providers: [],  }, 
						() => {
							this.getFilterData()
						}
					)
				}
			})
		}

        this.durationFunction('onLoad')

        this.getWindowSize()
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 100  
                
		let outerHeight = (window.outerHeight - browserBorder - headerHeight ) + "px"

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }


    // componentDidUpdate = (prevProps, prevState) => {
    //     if(prevState.brushStartTime && !_.isEqual(prevState.brushStartTime, this.state.brushStartTime)) {
    //         let obj = {}
    //         obj.start_time = this.state.sliderStartDate
    //         obj.end_time = this.state.sliderEndDate
    //         obj.brushStartTime = this.state.brushStartTime
    //         obj.brushEndTime = this.state.brushEndTime
    //         this.props.setAiopsPropsDetails('aiopsCommonPageFilter', obj)

    //         this.setState({ brushTime: true })
    //     }
    // }
    
	getFilterData = from => {
		let params = {}

		if (this.state.selectedProvider !== "" && this.state.selectedProvider !== 'all') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}

		if (from === 'provider') {
			if(this.props.accounts && this.props.accounts.length) {
				let allAccounts = this.props.accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
				if(this.props.accounts.length) {
					let accRow = {}
					accRow.account_id = "All"
					accRow.account_name = "All"
					accRow.date = null
					accRow.disabled = false
					accRow.provider = "All"
					allAccounts.unshift(accRow);
				}
				this.setState({ accounts: allAccounts })
			} else {
				this.props.listAllAccounts(params, (promise, allAccounts) => {
					if (promise) {						
						let filterdAccounts = allAccounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
						if(filterdAccounts.length) {
							let accRow = {}
							accRow.account_id = "All"
							accRow.account_name = "All"
							accRow.date = null
							accRow.disabled = false
							accRow.provider = "All"
							filterdAccounts.unshift(accRow);
						}						
						this.setState({ accounts: filterdAccounts })
					}
				})
			}

			if(this.props.regions && this.props.regions.length) {
				let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
				this.setState({ regions: providerRegions })
			} else {
				this.props.listAllRegions(params, (promise, regions) => {
					if (promise) {
						let providerRegions = regions && regions[0] && regions[0].provider_regions
						if(providerRegions) {
							let provider_regions_row = {}
							provider_regions_row.availability_zones = []
							provider_regions_row.city = "All"
							provider_regions_row.country = "All"
							provider_regions_row.geography = "All"
							provider_regions_row.name = "All"
							provider_regions_row.region = "All"
							provider_regions_row.region_id = "All"
							provider_regions_row.state = "All"
							providerRegions.unshift(provider_regions_row);
						}
						this.setState({ regions: providerRegions })
					} else {
						this.setState({ regions: [] })
					}
				})
			}
		}
	}

    onSearch = (type) => {
        if(this.state.changesInFilter) {
            let sliderStartDate = this.state.sliderStartDate
            let sliderEndDate = this.state.sliderEndDate
            if(this.state.selectedDuration === "custom") {
                sliderStartDate = this.state.datePickerStartDate && this.state.datePickerStartDate !== "" ? this.state.datePickerStartDate : sliderStartDate
                sliderEndDate = this.state.datePickerEndDate && this.state.datePickerEndDate !== "" ? this.state.datePickerEndDate : sliderEndDate
            }
            this.setState({
                brushStartTime: "",
                brushEndTime: "",
                alertSeries: [], alertOptions: {},
                eventSeries: [], eventOptions: {},
                anomalySeries: [], anomalyOptions: {},
                errorSeries: [], errorOptions: {},
                changesInFilter: false,
                showLoading: true,
                resetEnabled: type === 'onLoad' ? false : true,
                jumpDate: "",
                sliderStartDate,
                sliderEndDate
            },
                () => {
                    let obj = {}
                    obj.start_time = this.state.sliderStartDate
                    obj.end_time = this.state.sliderEndDate
                    this.props.setAiopsPropsDetails('aiopsCommonPageFilter', obj)

                    this.props.setAiopsPropsDetails('tabOptions', {})
                    this.props.setAiopsPropsDetails('alertTabDetails', {})
                    this.props.setAiopsPropsDetails('errorTabDetails', {})
                    this.props.setAiopsPropsDetails('eventTabDetails', {})                    

                    this.alertSection()
                    this.eventSection()
                    this.anomalySection()
                    this.errorSection()
                }
            )
        }

        setTimeout(() => { this.setState({ showLoading: false }) }, 3000)
    }

    onReset = () => {
        this.setState({ 
            selectedProvider: "",
            selectedAccount: [],
            selectedDuration: '+3h',
            changesInFilter: this.state.resetEnabled,
            jumpDate: "",
            datePickerStartDate: "",
            datePickerEndDate: ""
        },
            () => {
                if(this.state.resetEnabled) {
                    this.durationFunction('onLoad')
                }
            }
        )
    }

    alertSection = () => {
        let params = {}
        params.start_time = momentConvertionLocalToUtcTime(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:ss')
        params.end_time = momentConvertionLocalToUtcTime(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:ss')

        this.props.getObservabilityAlertsDailyTrend(params, (promise, result) => {
            if(promise) {
                this.setState({ alertDailyTrend: result },
                    () => {
                        this.totalAlerts()
                        this.brushOptions('onLoad')
                    }
                )
            } else {
                this.setState({ alertDailyTrend: {} })
            }
        })        
    }

    eventSection = () => {
        let params = {}
        // params.case_id = '2408-1028-7602'
        params.start_time = momentConvertionLocalToUtcTime(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:ss')
        params.end_time = momentConvertionLocalToUtcTime(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:ss')
        this.props.getObservabilityEventsDailyTrend(params, (promise, result) => {
            if(promise) {
                this.setState({ eventsDailyTrend: result },
                    () => this.totalEvents()
                )
            } else {
                this.setState({ eventsDailyTrend: {} })
            }
        })     
    }

    anomalySection = () => {
        let params = {}
        params.start_time = momentConvertionLocalToUtcTime(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:ss')
        params.end_time = momentConvertionLocalToUtcTime(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:ss')
        params.duration_aggregate_by = "seconds"
        
        this.props.getObservabilityAnomalyDailyTrend(params, (promise, response) => {
            if(promise) {
                this.setState({ anomalyDailyTrend: response && response.results && response.results.length ? response.results[0] : {} },
                    () => {
                        this.totalAnomalies()
                    }    
                )
            } else {
                this.setState({ anomalyDailyTrend: [] }, 
                    () => this.totalAnomalies()    
                )
            }
        })
    }

    errorSection = () => {
        let params = {
            "start_time": momentConvertionLocalToUtcTime(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:ss'),
            "end_time": momentConvertionLocalToUtcTime(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:ss'),
            // "aggregate_by": ["event_type"],
            // "radial": true,
            "duration_aggregate_by": "seconds"
        }
        this.props.getObservabilityDailyTrend(params, (promise, response) => {
            if(promise) {
                // let results = response && response.results && response.results.length ? response.results[0] : {}
                this.setState({ eventTypeResponse: response},
                    () => this.totalErrors()
                )
            } else {
                this.setState({ eventTypeResponse: {}},
                    () => this.totalErrors()
                )
            }
        })
    }

    totalAlerts = () => {
        let colors = ['#3DAFE8']
        let graphDetails = this.state.alertDailyTrend.results
        let alertSeries = []
        // let columnWidth = ""
        let alertCount = 0
        if(graphDetails && graphDetails.data && graphDetails.data.count) {
            
            alertCount = graphDetails.data.count.reduce((a, b) => a + b, 0)

            let dataArray = []
            let graphLabels = graphDetails.labels
            graphDetails.data.count.forEach((val, index) => {
                let dataItems = {}
                let time = momentConvertionUtcToLocalTime(graphLabels[index], 'YYYY-MM-DD HH:mm:ss')
                dataItems.x = time
                dataItems.y = val
                dataArray.push(dataItems)
            })            

            if(!graphLabels.includes(momentConvertionLocalToUtcTime(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:00'))) {
                let dataItems = {}
                dataItems.x = momentDateGivenFormat(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:00')
                dataItems.y = 0 
                dataArray.push(dataItems) //to initialize the start time in the graph
            }
            
            if(!graphLabels.includes(momentConvertionLocalToUtcTime(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:00'))) {
                let dataItems = {}
                dataItems.x = momentDateGivenFormat(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:00')
                dataItems.y = 0
                dataArray.push(dataItems) //to initialize the end time in the graph
            }

            let seriesRow = {}
            seriesRow.name = ""
            seriesRow.data = dataArray
            
            alertSeries = [seriesRow]

            // if(dataArray.length <= 10) {
                // columnWidth = '1%'
            // }
        } else {
            let dataArray = []
            let dataItems = {}
            dataItems.x = momentDateGivenFormat(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0 
            dataArray.push(dataItems) //to initialize the start time in the graph

            dataItems = {}
            dataItems.x = momentDateGivenFormat(addHours(this.state.sliderStartDate, 1), 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0 
            dataArray.push(dataItems) //to initialize the start time in the graph

            dataItems = {}
            dataItems.x = momentDateGivenFormat(subHours(this.state.sliderEndDate, 1), 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0
            dataArray.push(dataItems) //to initialize the end time in the graph

            dataItems = {}
            dataItems.x = momentDateGivenFormat(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0
            dataArray.push(dataItems) //to initialize the end time in the graph

            let seriesRow = {}
            seriesRow.name = "No Data"
            seriesRow.data = dataArray
            alertSeries = [seriesRow]

            // if(dataArray.length <= 10) {
                // columnWidth = '1%'
            // }
        }

        let barOptions = {                
            rangeBarOverlap: false,
            rangeBarGroupRows: false,
            columnWidth: "0%"
        }

        // if(columnWidth !== "") {
        //     barOptions.columnWidth = "0%"
        // }
        
        let alertOptions = {
            chart: {
                id:"",
                redrawOnParentResize: false,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
                sparkline: {
                    enabled: false
                },
                animations: {
                    enabled: false,
                },
                type: 'bar',
                height: 150,
                stacked: false,
            },
            markers: {
                size: 0
            },
            colors: colors,
            plotOptions: {
                bar: barOptions
            },
            dataLabels: {
                enabled: false
            },                
            grid: {
                show: false,			        
                xaxis: {
                    lines: {
                        show: false
                    },
                },
                yaxis: {
                    lines: {
                        show: false
                    }
                },
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: -5
                },
            },
            xaxis: {
                show: false,
                tooltip: {
                    enabled: false
                },
                axisTicks: {
                    show: false
                },
                type: 'datetime',
                axisBorder: {
                    show: true,
                    color: '#434B5E',
                },
                labels: {
                    show: false,
                    datetimeUTC: false,
                    style: {
                        colors: '#B8BBBE',
                        fontSize: '10px',
                        fontWeight: 'bolder',
                    },
                    offsetX: 0,
                    offsetY: -5,
                },
            },
            yaxis: {
                show: false,
            },
            legend: {
                show: false,
            },
            tooltip: {
                enabled: true,
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    let val  = series[seriesIndex][dataPointIndex];
                    // let labelName = w.globals.initialSeries[seriesIndex].name
                    return '<div class="arrow_box">' +
                        '<span> '+ momentDateGivenFormat(w.globals.initialSeries[0].data[dataPointIndex].x, 'DD MMM YYYY HH:mm') + 
                        ' <span style="color:'+ w.globals.colors[seriesIndex] +'"> Alerts </span> ' + val + ' </span>' +
                        '</div>'
                },
                style: {
                    fontSize: '9px',
                },
            },
        }

        this.setState({ alertSeries, alertOptions, alertCount })
    }

    totalEvents = () => {
        // let colors = ['#FF0000']
        let colors = ['#3DAFE8']
        let graphDetails = this.state.eventsDailyTrend.results
        let eventSeries = []
        // let columnWidth = ""
        let eventCount = 0
        if(graphDetails && graphDetails.data && graphDetails.data.count) {
            
            eventCount = graphDetails.data.count.reduce((a, b) => a + b, 0)

            let dataArray = []
            let graphLabels = graphDetails.labels            

            graphDetails.data.count.forEach((val, index) => {
                let dataRow = {}
                dataRow = {}
                dataRow.x =  momentConvertionUtcToLocalTime(graphLabels[index], 'YYYY-MM-DD HH:mm:ss')
                dataRow.y = val
                dataArray.push(dataRow)
            })

            if(!graphLabels.includes(momentConvertionLocalToUtcTime(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:00'))) {
                let dataRow = {}
                dataRow.x = momentDateGivenFormat(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:00')
                dataRow.y = 0 
                dataArray.push(dataRow) //to initialize the start time in the graph
            }
            
            if(!graphLabels.includes(momentConvertionLocalToUtcTime(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:00'))) {
                let dataRow = {}
                dataRow.x = momentDateGivenFormat(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:00')
                dataRow.y = 0
                dataArray.push(dataRow) //to initialize the end time in the graph
            }
            let seriesRow = {}
            seriesRow.name = ""
            seriesRow.data = dataArray

            eventSeries = [seriesRow]

            // if(dataArray.length <= 10) {
                // columnWidth = '1%'
            // }
        } else {
            let dataArray = []
            let dataItems = {}
            dataItems.x = momentDateGivenFormat(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0 
            dataArray.push(dataItems) //to initialize the start time in the graph

            dataItems = {}
            dataItems.x = momentDateGivenFormat(addHours(this.state.sliderStartDate, 1), 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0 
            dataArray.push(dataItems) //to initialize the start time in the graph

            dataItems = {}
            dataItems.x = momentDateGivenFormat(subHours(this.state.sliderEndDate, 1), 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0
            dataArray.push(dataItems) //to initialize the end time in the graph

            dataItems = {}
            dataItems.x = momentDateGivenFormat(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0
            dataArray.push(dataItems) //to initialize the end time in the graph

            let seriesRow = {}
            seriesRow.name = "No Data"
            seriesRow.data = dataArray
            eventSeries = [seriesRow]

            // if(dataArray.length <= 10) {
                // columnWidth = '1%'
            // }
        }

        let barOptions = {                
            rangeBarOverlap: false,
            rangeBarGroupRows: false,
            columnWidth: "0%"
        }

        // if(columnWidth !== "") {
        //     barOptions.columnWidth = "0%"
        // }
        
        let eventOptions = {
            chart: {
                id:"",
                // redrawOnParentResize: true,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
                sparkline: {
                    enabled: false
                },
                animations: {
                    enabled: false,
                },
                type: 'bar',
                height: 150,
                stacked: false,
            },
            markers: {
                size: 0
            },
            colors: colors,
            plotOptions: {
                bar: barOptions
                // bar: {
                //     horizontal: false,
                //     columnWidth: columnWidth,
                //     distributed: false,
                //     rangeBarOverlap: false,
                //     rangeBarGroupRows: false,
                // },       
            },
            dataLabels: {
                enabled: false
            },                
            grid: {
                show: false,			        
                xaxis: {
                    lines: {
                        show: false
                    },
                },
                yaxis: {
                    lines: {
                        show: false
                    }
                },
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: -5
                },
            },
            xaxis: {
                show: false,
                tooltip: {
                    enabled: false
                },
                axisTicks: {
                    show: false
                },
                type: 'datetime',
                axisBorder: {
                    show: true,
                    color: '#434B5E',
                },
                labels: {
                    show: false,
                    datetimeUTC: false,
                    style: {
                        colors: '#B8BBBE',
                        fontSize: '10px',
                        fontWeight: 'bolder',
                    },
                    offsetX: 0,
                    offsetY: -5,
                },
            },
            yaxis: {
                show: false,
            },
            legend: {
                show: false,
            },
            tooltip: {
                enabled: true,
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    let val  = series[seriesIndex][dataPointIndex];
                    // let labelName = w.globals.initialSeries[seriesIndex].name
                    return '<div class="arrow_box">' +
                        '<span> '+ momentDateGivenFormat(w.globals.initialSeries[0].data[dataPointIndex].x, "DD MMM YYYY HH:mm") + 
                        ' <span style="color:'+ w.globals.colors[seriesIndex] +'"> Events </span> ' + val + ' </span>' +
                        '</div>'
                },
                style: {
                    fontSize: '9px',
                },
            },
        }

        this.setState({ eventSeries, eventOptions, eventCount })
    }

    totalAnomalies = () => {
        // let colors = ['#FF0000']
        let colors = ['#775BA2']
        let graphDetails = this.state.anomalyDailyTrend
        let anomalySeries = []
        // let columnWidth = ""
        let anomalyCount = 0
        if(graphDetails && graphDetails.data && graphDetails.data) {

            anomalyCount = graphDetails.data.reduce((a, b) => a + b, 0)
            
            let dataArray = []
            let graphLabels = graphDetails.dates

            graphDetails.data.forEach((val, index) => {
                let dataRow = {}
                dataRow = {}
                dataRow.x =  momentConvertionUtcToLocalTime(graphLabels[index], 'YYYY-MM-DD HH:mm:ss')
                dataRow.y = val
                dataArray.push(dataRow)
            })

            if(!graphLabels.includes(momentConvertionLocalToUtcTime(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:00'))) {
                let dataRow = {}
                dataRow.x = momentDateGivenFormat(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:00')
                dataRow.y = 0 
                dataArray.push(dataRow) //to initialize the start time in the graph
            }
            
            if(!graphLabels.includes(momentConvertionLocalToUtcTime(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:00'))) {
                let dataRow = {}
                dataRow.x = momentDateGivenFormat(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:00')
                dataRow.y = 0
                dataArray.push(dataRow) //to initialize the end time in the graph
            }
            let seriesRow = {}
            seriesRow.name = ""
            seriesRow.data = dataArray

            anomalySeries = [seriesRow]

            // if(dataArray.length <= 10) {
                // columnWidth = '1%'
            // }
        } else {
            let dataArray = []
            let dataItems = {}
            dataItems.x = momentDateGivenFormat(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0 
            dataArray.push(dataItems) //to initialize the start time in the graph

            dataItems = {}
            dataItems.x = momentDateGivenFormat(addHours(this.state.sliderStartDate, 1), 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0 
            dataArray.push(dataItems) //to initialize the start time in the graph

            dataItems = {}
            dataItems.x = momentDateGivenFormat(subHours(this.state.sliderEndDate, 1), 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0
            dataArray.push(dataItems) //to initialize the end time in the graph

            dataItems = {}
            dataItems.x = momentDateGivenFormat(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0
            dataArray.push(dataItems) //to initialize the end time in the graph

            let seriesRow = {}
            seriesRow.name = "No Data"
            seriesRow.data = dataArray
            anomalySeries = [seriesRow]

            // if(dataArray.length <= 10) {
                // columnWidth = '1%'
            // }
        }

        let barOptions = {                
            rangeBarOverlap: false,
            rangeBarGroupRows: false,
            columnWidth: "0%"
        }

        // if(columnWidth !== "") {
        //     barOptions.columnWidth = "0%"
        // }
        
        let anomalyOptions = {
            chart: {
                id:"",
                // redrawOnParentResize: true,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
                sparkline: {
                    enabled: false
                },
                animations: {
                    enabled: false,
                },
                type: 'bar',
                height: 150,
                stacked: false,
            },
            markers: {
                size: 0
            },
            colors: colors,
            plotOptions: {
                bar: barOptions
                // bar: {
                //     horizontal: false,
                //     columnWidth: columnWidth,
                //     distributed: false,
                //     rangeBarOverlap: false,
                //     rangeBarGroupRows: false,
                // },       
            },
            dataLabels: {
                enabled: false
            },                
            grid: {
                show: false,			        
                xaxis: {
                    lines: {
                        show: false
                    },
                },
                yaxis: {
                    lines: {
                        show: false
                    }
                },
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: -5
                },
            },
            xaxis: {
                show: false,
                tooltip: {
                    enabled: false
                },
                axisTicks: {
                    show: false
                },
                type: 'datetime',
                axisBorder: {
                    show: true,
                    color: '#434B5E',
                },
                labels: {
                    show: false,
                    datetimeUTC: false,
                    style: {
                        colors: '#B8BBBE',
                        fontSize: '10px',
                        fontWeight: 'bolder',
                    },
                    offsetX: 0,
                    offsetY: -5,
                },
            },
            yaxis: {
                show: false,
            },
            legend: {
                show: false,
            },
            tooltip: {
                enabled: true,
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    let val  = series[seriesIndex][dataPointIndex];
                    // let labelName = w.globals.initialSeries[seriesIndex].name
                    return '<div class="arrow_box">' +
                        '<span> '+ momentDateGivenFormat(w.globals.initialSeries[0].data[dataPointIndex].x, "DD MMM YYYY HH:mm") + 
                        ' <span style="color:'+ w.globals.colors[seriesIndex] +'"> Anomalies </span> ' + val + ' </span>' +
                        '</div>'
                },
                style: {
                    fontSize: '9px',
                },
            },
        }

        this.setState({ anomalySeries, anomalyOptions,anomalyCount })
    }

    totalErrors = () => {
        
        let graphDetails = this.state.eventTypeResponse.results ? this.state.eventTypeResponse.results[0] : {}
        // let columnWidth = ""
        let errorSeries = []
        let colors = ['#775BA2']
        let errorCount = 0        
        if(graphDetails && Object.entries(graphDetails).length) {
            
            errorCount = graphDetails.data && graphDetails.data.reduce((a, b) => a + b, 0)

            let graphLabels = graphDetails.dates
            let graphData = graphDetails.data

            let dataArray = []
            graphLabels && graphLabels.forEach((date, i) => {
                let dataItems = {}
                dataItems.x = momentConvertionUtcToLocalTime(date, 'DD MMM YYYY HH:mm')
                dataItems.y = graphData[i]
                dataArray.push(dataItems)
            })

            if(!graphLabels.includes(momentConvertionLocalToUtcTime(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:00'))) {
                let dataItems = {}
                dataItems.x = momentConvertionLocalToUtcTime(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:00')
                dataItems.y = 0 
                dataArray.push(dataItems) //to initialize the start time in the graph
            }
            
            if(!graphLabels.includes(momentConvertionLocalToUtcTime(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:00'))) {
                let dataItems = {}
                dataItems.x = momentConvertionLocalToUtcTime(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:00')
                dataItems.y = 0
                dataArray.push(dataItems) //to initialize the end time in the graph
            }

            let seriesRow = {}
            seriesRow.name = "Errors"
            seriesRow.data = dataArray
            errorSeries.push(seriesRow)

            // if(dataArray.length <= 10) {
                // columnWidth = '1%'
            // }
        } else {
            let dataArray = []
            let dataItems = {}
            dataItems.x = momentDateGivenFormat(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0 
            dataArray.push(dataItems) //to initialize the start time in the graph

            dataItems = {}
            dataItems.x = momentDateGivenFormat(addHours(this.state.sliderStartDate, 1), 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0 
            dataArray.push(dataItems) //to initialize the start time in the graph

            dataItems = {}
            dataItems.x = momentDateGivenFormat(subHours(this.state.sliderEndDate, 1), 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0
            dataArray.push(dataItems) //to initialize the end time in the graph

            dataItems = {}
            dataItems.x = momentDateGivenFormat(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0
            dataArray.push(dataItems) //to initialize the end time in the graph

            let seriesRow = {}
            seriesRow.name = "No Data"
            seriesRow.data = dataArray
            errorSeries.push(seriesRow)

            // if(dataArray.length <= 10) {
                // columnWidth = '1%'
            // }
        }

        let barOptions = {                
            rangeBarOverlap: false,
            rangeBarGroupRows: false,
            columnWidth: "0%"
        }

        // if(columnWidth !== "") {
        //     // barOptions.columnWidth = "0%"
        // }

        let errorOptions = {                
            chart: {
                id:'bar0',
                // redrawOnParentResize: true,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
                sparkline: {
                    enabled: false
                },
                animations: {
                    enabled: false,
                },
                type: 'bar',
                height: 150,
                stacked: false,
            },
            markers: {
                size: 0
            },
            colors: colors,
            plotOptions: {
                bar: barOptions
            },
            dataLabels: {
                enabled: false
            },                
            grid: {
                show: false,			        
                xaxis: {
                    lines: {
                        show: false
                    },
                },
                yaxis: {
                    lines: {
                        show: false
                    }
                },
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: -5
                },
            },
            xaxis: {
                show: true,
                tooltip: {
                    enabled: false
                },
                axisTicks: {
                    show: false
                },
                type: 'datetime',
                axisBorder: {
                    show: true,
                    color: '#434B5E',
                },
                labels: {
                    show: true,
                    datetimeUTC: false,
                    style: {
                        colors: '#B8BBBE',
                        fontSize: '10px',
                        fontWeight: 'bolder',
                    },
                    offsetX: 0,
                    offsetY: 0,
                },
            },
            yaxis: {
                show: false,
            },
            legend: {
                show: false,
            },
            tooltip: {
                enabled: true,
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    let val  = series[seriesIndex][dataPointIndex];
                    // let labelName = w.globals.initialSeries[seriesIndex].name
                    return '<div class="arrow_box">' +
                        '<span> '+ momentDateGivenFormat(w.globals.initialSeries[0].data[dataPointIndex].x, "DD MMM YYYY HH:mm") + 
                        ' <span style="color:'+ w.globals.colors[seriesIndex] +'"> Errors </span> ' + val + ' </span>' +
                        '</div>'
                },
                style: {
                    fontSize: '9px',
                },
            },
        }
        
        this.setState({ errorSeries, errorOptions, errorCount })
    }

    brushOptions = (type) => {
        let brushSeries = []
        let brushRow = {}
        brushRow.data = [
            {x: momentDateGivenFormat(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm'), y: 0},
            {x: momentDateGivenFormat(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm'), y: 0}
        ]
        brushSeries.push(brushRow)

        let endTIme = momentDateGivenFormat(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:ss')
        let time = this.state.jumpDate !== "" ? this.state.jumpDate : endTIme
        let brushHours = 1
        let diff = twoDateDiffrence(this.state.sliderEndDate, this.state.sliderStartDate)
        
        if(diff > 1 && diff <= 3) {            
            brushHours = 6
        } else if(diff > 3 && diff <= 7) {
            brushHours = 24 //3
        } else if(diff > 7 && diff <= 14) {
            brushHours = 36 //6
        } else if(diff > 14 && diff <= 30) {
            brushHours = 48 //12
        } else if(diff > 30) {
            brushHours = 96 //24
        }

        let brushStartTime = subHours(time, brushHours)
        let brushEndTime = time
        
        // if(type !== "onLoad" && this.state.jumpDate === "" && this.state.brushStartTime && this.state.brushStartTime !== "" && this.state.brushEndTime && this.state.brushEndTime !== "") {
        //     brushStartTime = this.state.brushStartTime
        //     brushEndTime = this.state.brushEndTime
        // } else 
        if(this.state.jumpDate !== "") {
            brushStartTime = time
            brushEndTime = addHours(time, brushHours)
        }

        let optionsBrush = {
            chart: {
                background: '#fff',
                id: 'chart1',
                type: 'line',
                sparkline: {
                    enabled: true
                },
                zoom: {
                    enabled: false,
                },
                brush:{
                    // targets: ['bar1', 'bar2'],
                    target: "",
                    enabled: true,
                    autoScaleYaxis: true,
                },
                selection: {
                    enabled: true,
                    fill: {
                        color: "#039BE5",
                        opacity: 1,
                    },
                    xaxis: {
                        min: new Date(momentDateGivenFormat(brushStartTime, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                        max: new Date(momentDateGivenFormat(brushEndTime, 'YYYY-MM-DD HH:mm:ss')).getTime()
                    }
                },
                events: {
                    selection: (chartContext, { xaxis, yaxis }) => {
                        this.brushSelection(xaxis)
                    }
                }
            },
            markers: {
                size: 0
            },
            colors: ['#F44336'],
            fill: {
                type: 'solid',
                color: "#000",
                opacity: 1,
            },
            xaxis: {
                type: 'datetime',
                tooltip: {
                    enabled: false
                }
            },
            yaxis: {
                show: false,
                tickAmount: 2
            },
            annotations: {
                yaxis: [
                  {
                    y: 1,
                    borderColor: '#000',
                    label: {
                      borderColor: '#00E396',
                      style: {
                        color: '#fff',
                        background: '#00E396'
                      },
                      text: ""
                    }
                  }
                ]
            }
        }

        this.setState({ brushSeries, optionsBrush, jumpDate: "" },
            // () => this.props.loading()
        )
    }

    brushSelection = (xaxis, type) => {
        clearTimeout(timer)
        let brushStartTime = momentDateGivenFormat(xaxis.min, 'YYYY-MM-DD HH:mm:ss')
        let brushEndTime = momentDateGivenFormat(xaxis.max, 'YYYY-MM-DD HH:mm:ss')       

        this.props.setAiopsPropsDetails('alertPageMonitoringList', {})
        if(this.state.brushStartTime && this.state.brushStartTime !== "" && this.state.brushEndTime !== this.state.sliderEndDate) {
            timer = setTimeout(() => this.aiopsCommonPageFilterProps(brushStartTime, brushEndTime, true), 1000)
        } else {
            this.aiopsCommonPageFilterProps(this.state.sliderStartDate, this.state.sliderEndDate, false)
        }
        
        if(brushStartTime && brushEndTime) {
            this.setState({ brushStartTime, brushEndTime, alertListLoader: true })
        }
    }

    aiopsCommonPageFilterProps = (brushStartTime, brushEndTime, brushTime) => {
        let obj = {}
        obj.start_time = this.state.sliderStartDate
        obj.end_time = this.state.sliderEndDate
        obj.brushStartTime = brushStartTime
        obj.brushEndTime = brushEndTime
        this.props.setAiopsPropsDetails('aiopsCommonPageFilter', obj)

        this.setState({ brushTime })
    }
    
    handleMultiSelectChange = (field, arrayValue) => {
		let value = arrayValue.map(item => item.value)

		let selectedValue = []
		if(field === 'selectedAccount') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.accounts.forEach(acc => {
						selectedValue.push(acc.account_id)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.accounts.length -1)) {
					selectedValue.push('All')
				}
			}	
            this.setState({ [field]: selectedValue, changesInFilter: true })		
		} else if(field === 'selectedRegion') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.regions.length -1)) {
					selectedValue.push('All')
				}
			}
            this.setState({ [field]: selectedValue })
		} 
    }
    
    getMultiSelectedCount = (type, array) => {
        return array.length && array.includes('All') ? 'All' : (array.length ? array.length +' Selected' : 'All')
    }   
	
	handleSelect = (date) => {
		this.setState({ selectionRange: [date.selection] })
        let sliderStartDate = momentDateGivenFormat(date.selection.startDate, 'YYYY-MM-DD 00:00:00')
		let sliderEndDate = momentDateGivenFormat(date.selection.endDate, 'YYYY-MM-DD 23:59:59')

        let currentDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD')

        if(sliderEndDate === currentDate) {
            sliderEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')
        }

        let sliderDate = momentDateGivenFormat(sliderStartDate, 'DD MMM') +' '+momentDateGivenFormat(sliderEndDate, 'DD MMM')

		this.setState({ datePickerStartDate: sliderStartDate, datePickerEndDate: sliderEndDate, sliderDate, changesInFilter: true })
	}

    handleSelectNew = (date) => {
        
		// this.setState({ selectionRange: [date.selection] })
        // let sliderStartDate = date[0]
		// let sliderEndDate = date[1]

        // let currentDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD')

        // if(sliderEndDate === currentDate) {
        //     sliderEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')
        // }

        // let sliderDate = momentDateGivenFormat(sliderStartDate, 'DD MMM') +' '+momentDateGivenFormat(sliderEndDate, 'DD MMM')

		// this.setState({ datePickerStartDate: sliderStartDate, datePickerEndDate: sliderEndDate, sliderDate, changesInFilter: true })
        let datePickerStartDate = momentDateGivenFormat(date[0], "YYYY-MM-DD HH:mm:00")
		let datePickerEndDate = momentDateGivenFormat(date[1], "YYYY-MM-DD HH:mm:00")
        this.setState({ datePickerStartDate, datePickerEndDate, changesInFilter: true, showDateRangePicker: false })
	}

    durationFunction = (type) => {
        let duration = this.state.selectedDuration
        let period = duration.substring(duration.length, duration.length-1)
        let durationBtw = getDayFromSelectedDuration(duration)
        let sliderStartDate = ""
        if(period === "d") {
            sliderStartDate = momentDateGivenFormat(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
        } else {
            sliderStartDate = momentDateGivenFormat(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
        }
        // let days = this.state.selectedDuration.replace('+', "").replace('d', "")
        // let reducedDays = days - 1
        // let sliderStartDate = momentDateGivenFormat(subDays(new Date(), reducedDays), 'YYYY-MM-DD 00:00:00')
        
        let sliderEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')

        let selectionRange = [{
			startDate: new Date(sliderStartDate),
			endDate: new Date(sliderEndDate),
			key: 'selection',
		}]

        let dayDiffrence = twoDateDiffrence(this.state.sliderEndDate, this.state.sliderStartDate)

        

        this.setState({ sliderStartDate, sliderEndDate, selectionRange, changesInFilter: true, dayDiffrence },
            () => {
                // if(type === 'onLoad') {
                this.onSearch('onLoad')
                // this.disableFutureDate()
                // }
            }
        )

        // let disableFutureDt = () => {
        //     let current = moment()
        //     return current.isBefore(currentLocaltime()) && current.isAfter(subDays(currentLocaltime(), dayDiffrence))
        // }
    }   
    
    // disableFutureDate = () => {
        
    // }

    handleClickOutside = (event) => {
        if(!this.state.showQueryResults) {
            if (this.daterRangeRef && !this.daterRangeRef.current.contains(event.target)) {
                this.setState({ showDateRangePicker: false })
            }

            if (this.jumpTo && !this.jumpTo.current.contains(event.target)) {
                this.setState({ showDateTime: false })
            }

            if (this.accountRef && !this.accountRef.current.contains(event.target)) {
                this.setState({ isAccountOpen: false })
            } else {
                this.setState({ isAccountOpen: true })
            }
        }
    }

    handleJumpTo = (date) => {
        let jumpDate = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
        this.setState({ jumpDate })
    }

    onChangePageType = (pageType) => {
        this.setState({ pageType, brushStartTime: "", brushEndTime: "" },
            () =>  this.brushOptions()   
        )
    }

	render() {
		return (
            <div className="container-fluid overflow-auto flex-grow-1 bg-muted" onClick={ (event) => { this.handleClickOutside(event) } }>
                <div className={`loaderOverlay ${this.state.showLoading ? "" : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
                {this.state.showAnalysisDetails ?
					<Analysis
                        hideAnalysisDetails={() => this.setState({ showAnalysisDetails: false })}
                        start_time = {this.state.sliderStartDate}
                        end_time = {this.state.sliderEndDate}
                        selectedProvider={this.state.selectedProvider}
                        selectedRegion={this.state.selectedRegion}
                        selectedDuration={this.state.selectedDuration}
					/>
				: this.state.showQueryDetails ?
					<QueryList
                        hideQueryDetails={() => this.setState({ showQueryDetails: false })}
                        start_time = {this.state.sliderStartDate}
                        end_time = {this.state.sliderEndDate}
                        selectedProvider={this.state.selectedProvider}
                        selectedRegion={this.state.selectedRegion}
                        selectedDuration={this.state.selectedDuration}
                        showQueryResults={(selectedQueryDetails, queryResult) => this.setState({ showQueryDetails: false, queryResult, showQueryResults: true, selectedQueryDetails })}
					/>
				: null}
                <div className='row h-100'>
                    <div className='col-sm-12 p-0'>
                        <div className="title-section pb-3">
							<div className="row">
                                <div className='col-4 align-self-center'>
                                    <div className='d-flex'>
                                        <h6 className="text-white m-0">Observability - <span className='mt-1 text-info cursorPointer f14' onClick={ () => this.setState({showQueryDetails:true})}>Detail Queries</span></h6>
                                    </div>
                                    {this.state.showQueryResults ?
                                        <p className="text-white m-0">Unified view of queried Events in your Cloud</p>
                                    : 
									    <p className="text-white m-0">Unified view of Alerts, Events and Errors in your Cloud</p>
                                    }   
								</div>
                                <div className={`col-8 align-self-center`}>
                                    {!this.state.showQueryResults ?
                                        <div className={`col-md-12 d-flex flex-wrap justify-content-end bd-highlight`}>
                                            <div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: '150px' }}>
                                                <label className="mb-1">Provider</label>
                                                <Select
                                                    placeholder={'Select'}
                                                    isSearchable={false}
                                                    // menuIsOpen={true}
                                                    className='f13 p-0 colorBlack reactSelectFilterDrop'
                                                    value={({
                                                        value: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : 'Select',
                                                        label: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : <span className="placeholder">Select</span>
                                                    })}
                                                    options={this.state.providers && this.state.providers.map(item => ({
                                                        value: item.provider_name,
                                                        label: item.provider_name,	
                                                    }))}
                                                    onChange={event => this.setState({
                                                        selectedProvider: event.value,
                                                        changesInFilter: true,
                                                    })}
                                                />
                                            </div>
                                            <div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} ref={this.accountRef} style={{maxWidth: '150px' }}>
                                                <label className="mb-1">Account</label>
                                                <div className='dlpHeaderMultiSelection removeDropdownSearchBar'>
                                                    <ReactMultiSelectCheckboxes						
                                                        placeholderButtonLabel="All"
                                                        menuIsOpen ={this.state.isAccountOpen}
                                                        getDropdownButtonLabel={() => this.getMultiSelectedCount('account', this.state.selectedAccount)}
                                                        onChange={arr => {
                                                        this.handleMultiSelectChange('selectedAccount', arr ? arr : []) }}
                                                        options={this.state.accounts && this.state.accounts.map(account => ({
                                                            value: account.account_id,
                                                            label: account.account_name,
                                                        }))}
                                                        value={this.state.selectedAccount.map(comp => ({
                                                            value: comp,
                                                        }))}
                                                    />
                                                </div>
                                            </div>
                                            <div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zindex1`} ref={this.daterRangeRef} style={{maxWidth: '150px'}}>
                                                <label className="mb-1">Duration</label>
                                                <Select
                                                    placeholder={'Select'}
                                                    isSearchable={false}
                                                    // menuIsOpen={true}
                                                    className='f13 p-0 colorBlack reactSelectFilterDrop'
                                                    value={({
                                                        value: this.state.selectedDuration && this.state.selectedDuration !== "" ? this.state.selectedDuration : 'Select',
                                                        label: this.state.selectedDuration && this.state.selectedDuration !== "" ? durationOptions.filter(e => e.value === this.state.selectedDuration)[0].option : <span className="placeholder">Select</span>
                                                    })}
                                                    options={durationOptions && durationOptions.map(item => ({
                                                        value: item.value,
                                                        label: item.option,	
                                                    }))}
                                                    onChange={event => {
                                                        if(event.value !== 'custom') {
                                                            this.setState(
                                                                {
                                                                    selectedDuration: event.value,
                                                                    showDateRangePicker: false,
                                                                },
                                                                () => {
                                                                    // this.durationFunction()
                                                                }
                                                            )
                                                        } else {
                                                            this.setState({ showDateRangePicker: true, selectedDuration: event.value })
                                                        }
                                                    }}
                                                />
                                                {/* <select
                                                    className='form-control displayNone'
                                                    value={this.state.selectedDuration}
                                                    onChange={event => {
                                                        if(event.target.value !== 'custom') {
                                                            this.setState(
                                                                {
                                                                    selectedDuration: event.target.value,
                                                                    showDateRangePicker: false,
                                                                },
                                                                () => {
                                                                    // this.durationFunction()
                                                                }
                                                            )
                                                        } else {
                                                            this.setState({ showDateRangePicker: true, selectedDuration: event.target.value })
                                                        }
                                                    }}
                                                    onClick={event => {
                                                        if(event.target.value === 'custom' && this.state.selectedDuration === 'custom') {
                                                            this.setState({ showDateRangePicker: true })
                                                        }
                                                    }}
                                                >
                                                    <option value={"+24h"}>Last 24 hours</option>
                                                    <option value={"+3d"}>Last 3 days</option>
                                                    <option value={"+7d"}>Last 1 week</option>
                                                    <option value={"+14d"}>Last 2 weeks</option>
                                                    <option value={"+30d"}>Last 1 month</option>
                                                    <option value={"custom"}>Custom Date</option>
                                                    {CUSTOM_DURATION_FILTER_OPTIONS.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.value}>
                                                                {item.option}
                                                            </option>
                                                        )
                                                    })}
                                                </select> */}
                                                {this.state.showDateRangePicker ?
                                                    <div className="obsDateTimePicker text-black5 pt-2 ml-n1" style={{"z-index": "999999999","position": "absolute","right":0}}>
                                                        {/* <DateRange
                                                            onChange={item => this.handleSelect(item) }
                                                            showSelectionPreview={true}
                                                            moveRangeOnFirstSelection={false}
                                                            months={2}
                                                            ranges={this.state.selectionRange}
                                                            direction="horizontal"
                                                            minDate={new Date(subDays(new Date(), 29))}
                                                            maxDate={new Date()}
                                                            preventSnapRefocus={true}
                                                            // calendarFocus="backwards"
                                                        /> */}
                                                        <RangePicker
                                                            // locale={`en-us`} // default is en-us
                                                            show={true} // default is false
                                                            disabled={false}
                                                            placeholder={["Start Time", "End Time"]}
                                                            // defaultDates={[
                                                            //     new Date().getFullYear() + '-' + new Date().getMonth() + 1 + '-' + new Date().getDate(), 
                                                            //     new Date().getFullYear() + '-' + new Date().getMonth() + 1 + '-' + new Date().getDate()
                                                            // ]}
                                                            // defaultDates={[
                                                            //     momentDateGivenFormat(new Date(), "YYYY-MM-DD"),
                                                            //     momentDateGivenFormat(new Date(), "YYYY-MM-DD"),
                                                            // ]}
                                                            // defaultDates={[
                                                            // year + "-" + month + "-" + date,
                                                            // year + "-" + month + "-" + date
                                                            // ]} // ['YYYY-MM-DD', 'YYYY-MM-DD']
                                                            // defaultTimes={[hour + ":" + minute, hour + ":" + minute]} // ['hh:mm', 'hh:mm']
                                                            // initialDates={[
                                                            // year + "-" + month + "-" + date,
                                                            // year + "-" + month + "-" + date
                                                            // ]} // ['YYYY-MM-DD', 'YYYY-MM-DD']
                                                            // initialTimes={[new Date().getHours()-1 + ":00", new Date().getHours() + ":00"]} // ['hh:mm', 'hh:mm']
                                                            defaultTimes={["00:00", "23:59"]} // ['hh:mm', 'hh:mm']
                                                            onConfirm={(res) => this.handleSelectNew(res)}
                                                            onClose={() => console.log("onClose")}
                                                            onClear={() => console.log("onClear")}
                                                            style={{ width: "610px", margin: "0 auto" }}
                                                            // markedDates={[
                                                            // `${year}-${month.padStart(2, "0")}-${date.padStart(2, "0") - 1}`,
                                                            // `${year}-${month.padStart(2, "0")}-${date.padStart(2, "0")}`
                                                            // ]} // OPTIONAL. ['YYYY-MM-DD']
                                                        />
                                                    </div>
                                                    
                                                : null}
                                            </div>
                                            <div className={`ml-3 align-self-center`}>
                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                    <button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-right px-2 py-1`} onClick={() => this.setState({ showLoading: false }, () => this.durationFunction() )}>Search</button>
                                                    <button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-left px-2 py-1`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    :
                                        <div className={`col-md-12 d-flex flex-wrap justify-content-end bd-highlight`}>
                                            <div className={`ml-3 align-self-center`}>
                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                    <button type="button" className={`btn btn-dark border-right px-2 py-1`} onClick={() => this.setState({ showQueryResults: false })}>Back</button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {!this.state.showQueryResults ?
                            <div className='p-2'>
                                <div className="rounded bg-dark p-3">
                                    <div className="d-flex justify-content-between">
                                        <p className="mb-1">Showing data for 
                                            <span className="text-info ml-1">
                                                {this.state.brushTime ?
                                                    this.state.brushStartTime ? 
                                                        momentDateGivenFormat(this.state.brushStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.brushEndTime, 'DD MMM YYYY HH:mm') 
                                                    : ""
                                                : 
                                                    this.state.sliderStartDate ? 
                                                        momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM YYYY HH:mm') 
                                                    : ""
                                                }
                                            </span>
                                            <span className="ml-2">(local time)</span>
                                        </p>
                                        <div className="d-flex">
                                            <div className="d-flex">
                                                <div className={`errorDateTimePicker input-group ml-3 bg-black5 border-gray5 cursorPointer`} style={{minWidth: "300px"}} ref={this.jumpTo}>
                                                    <div className='input-group-prepend' onClick={e => this.setState({ showDateTime: true })}>   
                                                        <div className={`input-group-text bg-transparent px-2`}>
                                                            <i className={`fas fa-clock text-gray5`} />
                                                        </div>
                                                    </div>
                                                    <input
                                                        value={this.state.jumpDate && this.state.jumpDate !== "" ? momentDateGivenFormat(this.state.jumpDate, 'DD MMM YYYY hh:mm A') : 'jump to time...'}
                                                        onChange={e => {
                                                            this.setState({ searchText: e.target.value })
                                                        }}
                                                        onClick={e => this.setState({ showDateTime: true })}
                                                        type='text'
                                                        className={`form-control bg-transparent text-white pl-0 cursorPointer`}
                                                        readOnly={true}
                                                        placeholder={'Jump to time...'}
                                                    />             
                                                    {this.state.showDateTime ?
                                                        <div className="w-100 pt-1" style={{"z-index": "999999999","position": "absolute","top":"40px"}}>
                                                            <Datetime 
                                                                // open={this.state.showDateTime}
                                                                // value={momentDateGivenFormat(this.state.jumpDate, 'DD MMM YYYY hh:mm A')}
                                                                onChange={item => this.handleJumpTo(item) }
                                                                // closeOnSelect={true}
                                                                // updateOnView="time"
                                                                // closeOnSelect={ true }
                                                                dateFormat={'DD MMM YYYY'}
                                                                // timeFormat={'hh:mm A'}
                                                                input={false}
                                                                // className="w-100 mr-1 aiopsMetricsDatepicker"
                                                                isValidDate={disableFutureDt}
                                                            />
                                                        </div>
                                                    : null}
                                                </div>
                                                <p className={`mt-1 mb-0 ml-1 text-info ${this.state.jumpDate && this.state.jumpDate !== "" ? 'cursorPointer' : 'displayNone'}`} onClick={() => this.setState({ disableJumpTo: true }, () => this.brushOptions())}>Jump</p>
                                            </div>
                                            <i className={`fa fa-expand-wide text-info cursorPointer f14 mx-3 align-self-center`} onClick={ () => this.setState({showAnalysisDetails:true})}> <span className="f12 fontFamily">Expand</span></i>

                                            <i className={`fa fa-caret-${this.state.minimizeGraphSection ? "down" : "down"} cursorPointer ml-3 text-gray2 align-self-center`} onClick={() => this.setState({ minimizeGraphSection: !this.state.minimizeGraphSection })}></i>
                                        </div>
                                    </div>
                                    {/* ${(this.state.pageType !== "Alerts" && this.state.minimizeGraphSection ? "displayNone" : "") */}
                                    <div className={`d-flex w-100 ${this.state.alertSeries && this.state.alertSeries.length ? 'mt-n2' : 'mt-4'} ${(this.state.minimizeGraphSection && this.state.pageType !== "Alerts" ? "displayNone" : "") }`}>
                                        <div className="w-5 d-flex justify-content-end">
                                            <p className="mb-0 align-self-center pr-3 mt-n2">Alerts</p>
                                        </div>

                                        <div className="w-80 transparentTooltip mt-n2">
                                            {this.state.alertSeries && this.state.alertSeries.length ?
                                                <Chart options={this.state.alertOptions} series={this.state.alertSeries} type="bar" height={70} />
                                            : null}
                                        </div>
                                        <div className="d-flex pl-2 w-15 align-self-center">
                                            {/* <small className="mb-0 text-purple-2">Total: </small> */}
                                            <small className="ml-2 mb-0 text-white f14">{this.state.alertCount && this.state.alertCount}</small>
                                        </div>
                                    </div>
                                    <div className={`d-flex w-100 ${!this.state.minimizeGraphSection && this.state.eventSeries && this.state.eventSeries.length ? 'mt-n4' : 'mt-4'} ${(this.state.minimizeGraphSection && this.state.pageType !== "Events" ? "displayNone" : "") }`}>
                                        <div className="w-5 d-flex justify-content-end">
                                            <p className="mb-0 align-self-center pr-3 mt-n2">Events</p>
                                        </div>

                                        <div className="w-80 transparentTooltip mt-n2">
                                            {this.state.eventSeries && this.state.eventSeries.length ?
                                                <Chart options={this.state.eventOptions} series={this.state.eventSeries} type="bar" height={70} />
                                            : null}
                                        </div>
                                        <div className="d-flex pl-2 w-15 align-self-center">
                                            {/* <small className="mb-0 text-purple-2">Total: </small> */}
                                            <small className="ml-2 mb-0 text-white f14">{this.state.eventCount && this.state.eventCount}</small>
                                        </div>
                                    </div>
                                    <div className={`d-flex w-100 ${!this.state.minimizeGraphSection && this.state.anomalySeries && this.state.anomalySeries.length ? 'mt-n4' : 'mt-4'} ${(this.state.minimizeGraphSection && this.state.pageType !== "Anomalies" ? "displayNone" : "") }`}>
                                        <div className="w-5 d-flex justify-content-end mt-n2">
                                            <p className="mb-0 align-self-center pr-3">Anomalies</p>
                                        </div>

                                        <div className="w-80 transparentTooltip mt-n2">
                                            {this.state.anomalySeries && this.state.anomalySeries.length ?
                                                <Chart options={this.state.anomalyOptions} series={this.state.anomalySeries} type="bar" height={70} />
                                            : null}
                                        </div>
                                        <div className="d-flex pl-2 w-15 align-self-center">
                                            {/* <small className="mb-0 text-purple-2">Total: </small> */}
                                            <small className="ml-2 mb-0 text-white f14">{this.state.anomalyCount && this.state.anomalyCount}</small>
                                        </div>
                                    </div>
                                    <div className={`d-flex w-100 ${!this.state.minimizeGraphSection && this.state.errorSeries && this.state.errorSeries.length ? 'mt-n4' : 'mt-4'} ${(this.state.minimizeGraphSection && this.state.pageType !== "Errors" ? "displayNone" : "") }`}>
                                        <div className="w-5 d-flex justify-content-end mt-n2">
                                            <p className="mb-0 align-self-center pr-3">Errors</p>
                                        </div>

                                        <div className="w-80 transparentTooltip mt-n2">
                                            {this.state.errorSeries && this.state.errorSeries.length ?
                                                <Chart options={this.state.errorOptions} series={this.state.errorSeries} type="bar" height={80} />
                                            : null}
                                        </div>
                                        <div className="d-flex pl-2 w-15 align-self-center">
                                            {/* <small className="mb-0 text-purple-2">Total: </small> */}
                                            <small className="ml-2 mb-0 text-white f14">{this.state.errorCount && this.state.errorCount}</small>
                                        </div>
                                    </div>
                                    <div className="d-flex w-100 mt-n2">
                                        {this.state.brushSeries && this.state.brushSeries.length && this.state.optionsBrush && Object.entries(this.state.optionsBrush).length ?
                                            <React.Fragment>
                                            <div className="w-5 d-flex justify-content-end pr-2">
                                                
                                            </div>
                                            <div className="removeSvgPoint w-80">
                                                <Chart options={this.state.optionsBrush} series={this.state.brushSeries} type="line" height={8} />
                                            </div>
                                            <div className="pl-2 w-15">
                                                <small className="mb-0 align-self-center f10">{momentDateGivenFormat(this.state.brushStartTime, 'DD MMM HH:mm') +' - '+momentDateGivenFormat(this.state.brushEndTime, 'DD MMM HH:mm')}</small>
                                            </div>
                                            </React.Fragment>
                                        : null}
                                    </div>
                                </div>
                                {this.state.pageType === 'Alerts' ?
                                    <AlertsDashboard 
                                        loading={() => this.setState({ showLoading: false })}
                                        onChangePageType={(pageType) => this.onChangePageType(pageType)}                            
                                        pageTypes={pageTypes}
                                    />
                                : this.state.pageType === 'Events' ?
                                    <EventsDashboard 
                                        loading={() => this.setState({ showLoading: false })}
                                        onChangePageType={(pageType) => this.onChangePageType(pageType)}
                                        pageTypes={pageTypes}
                                    />
                                : this.state.pageType === 'Anomalies' ?
                                    <AnomalyDashboard 
                                        loading={() => this.setState({ showLoading: false })}
                                        onChangePageType={(pageType) => this.onChangePageType(pageType)}
                                        pageTypes={pageTypes}
                                    />
                                :this.state.pageType === 'Errors' ?
                                    <ErrorsDashboard 
                                        loading={() => this.setState({ showLoading: false })}
                                        onChangePageType={(pageType) => this.onChangePageType(pageType)}
                                        pageTypes={pageTypes}
                                    />
                                :  null}
                            </div>
                        : 
                            <div className="p-2">
                                <QueryResults 
                                    selectedQueryDetails={this.state.selectedQueryDetails}
                                    selectedProvider={this.state.selectedProvider}
                                    durationOptions={durationOptions}
                                    queryResult={this.state.queryResult}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
LandingPage.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('observability landing page',state)
	return {
        accounts: state.filters.accounts,
		regions: state.filters.regions,
    }
}

export default AppWrapper(LandingPage, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
    setAiopsPropsDetails,
    getObservabilityAlertsDailyTrend,
    getObservabilityEventsDailyTrend,
    getObservabilityAnomalyDailyTrend,
    getObservabilityDailyTrend,
})