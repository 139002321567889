/*************************************************
 * Tvastar
 * @exports
 * @file CausalCorrelationAction.js
 * @author prakash // on 12/05/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
// import cognitoService from '../../services/cognitoService'
// import { myLog } from '../../utils/utility'
import { API } from 'aws-amplify'

import {
	CAUSALCORRELATIONENDPOINT,
	GET_CAUSE_AND_IMPACTED_METRICS

} from '../config'

/**
 * Action to get Metrics Alert
 * @param {Object} body
 * @param {Function} callback
*/
export const getCauseAndImpactedMetrics = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(CAUSALCORRELATIONENDPOINT, GET_CAUSE_AND_IMPACTED_METRICS, { body })
			callback(true, response)
		} catch (error) {
			callback(false, {})
		}
	}
}