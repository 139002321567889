/*************************************************
 * Tvastar
 * @exports
 * @file AddEdit.js
 * @author Prakash // on 08/10/2021
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { capitalizeFirstLetter } from '../../../../utils/utility'
import { 
    listWatermark,
    createWatermark,
    updateWatermark,
} from '../../../../actions/dlp/WatermarkAction'

import { Spinner } from 'reactstrap'
import { store as CommonNotification } from 'react-notifications-component';

class AddEdit extends Component {
    hierarichalBucketBarChartRef = React.createRef()
    constructor(props) {
        super(props)
        this.props = props;
        this.state = {
            showLoading: false,
        }
    }

    componentDidMount = () => {
        if(this.props.pageType === 'edit' || this.props.pageType === 'view') {
            let selectedRecord = this.props.selectedRecord
            let name = selectedRecord.watermark_name
            let description = selectedRecord.description ? selectedRecord.description : ''
            let selectedTags = selectedRecord.tags ? selectedRecord.tags : []

            this.setState({ name, description, selectedTags })
        }
    }

    listWatermark = () => {
        let params = {}
        this.props.listWatermark(params, (promise, response) => {})
    }

    removeSelectedOption = (field, value) => {
        let filteredReslt = this.state[field].filter(e => e !== value)
        this.setState({ [field]: filteredReslt })
    }

    actionPorcess = () => {
        let hasError = false

        let nameErrorMessage = ''
        if(!this.state.name || this.state.name === '') {
            hasError = true
            nameErrorMessage = 'Please enter watermark name'
        } else {
            //check watemark name already exist
            if(this.props.pageType === 'create') {
                let checkNameExist = this.props.dlpConfigWatermarklist.filter(arr => arr.watermark_name === this.state.name)
                if(checkNameExist && checkNameExist.length) {
                    hasError = true
                    nameErrorMessage = 'Watermark name already exist'
                }
            } else if(this.props.pageType === 'edit') {
                let checkNameExist = this.props.dlpConfigWatermarklist.filter(arr => arr.watermark_name === this.state.name && arr.watermark_id !== this.props.selectedRecord.watermark_id)
                console.log(checkNameExist)
                if(checkNameExist && checkNameExist.length) {
                    hasError = true
                    nameErrorMessage = 'Watermark name already exist'
                }
            }
        }
        let descriptionErrorMessage = ''
        // if(!this.state.description || this.state.description === '') {
        //     hasError = true
        //     descriptionErrorMessage = 'Please enter description'
        // }

        // let tagsErrorMessage = ''
        // if(!this.state.selectedTags || !this.state.selectedTags.length) {
        //     hasError = true
        //     tagsErrorMessage = 'Please enter tags'
        // }

        this.setState({ hasError, nameErrorMessage, descriptionErrorMessage, showActionLoading: !hasError })

        if(!hasError) {
            let params = {}
            
            params.watermark_name = this.state.name
            params.description = this.state.description
            params.tags = this.state.selectedTags ? this.state.selectedTags : []
            if(this.props.pageType === 'create') {
                this.props.createWatermark(params, (promise, response) => {
                    if(promise) {                        
                        let messageType = 'danger'		
                        let message = 'Check the data'
                        if(response.status && response.status.message && response.status.message === 'inserted') {
                            messageType = 'success'
                            message = 'Saved Successfully'
                        }
                        CommonNotification.addNotification({
                            message: message,
                            type: messageType,
                            insert: "top",
                            container: "top-center",
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
                        
                        if(response.status && response.status.message && response.status.message === 'inserted') {
                            this.setState({ showActionCompleted: true },
                                () => {
                                    setTimeout(() => { this.listWatermark() }, 3000)
                                    setTimeout(() => { this.props.showListPage() }, 4000)
                                }
                            )
                        } else {
                            this.setState({ showActionLoading: false })
                        }
                    } else {
                        this.setState({ showActionLoading: false })
                    }
                })
            } else {
                params.watermark_id = this.props.selectedRecord.watermark_id
                this.props.updateWatermark(params, (promise, response) => {
                    if(promise) {						
                        let messageType = 'danger'		
                        let message = 'Check the data'
                        if(response.status && response.status.message && response.status.message === 'updated') {
                            messageType = 'success'
                            message = 'Updated Successfully'
                        }
                        CommonNotification.addNotification({
                            message: message,
                            type: messageType,
                            insert: "top",
                            container: "top-center",
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
                        
                        if(response.status && response.status.message && response.status.message === 'updated') {
                            this.setState({ showActionCompleted: true },
                                () => {
                                    setTimeout(() => { this.listWatermark() }, 3000)
                                    setTimeout(() => { this.props.showListPage() }, 4000)
                                }
                            )
                        } else {
                            this.setState({ showActionLoading: false })
                        }
                    } else {
                        this.setState({ showActionLoading: false })
                    }
                })
            }
        }
    }

    onKeyDown = (e) => {
        let selectedTags = this.state.selectedTags ? this.state.selectedTags : []
		if (e.keyCode === 13 || e.keyCode === 9) {
            if(this.state.selected_tag && this.state.selected_tag !== '') {
                selectedTags.push(this.state.selected_tag.trim())
                if(e.keyCode === 9) {
                    e.preventDefault();
                }
                this.setState({ selectedTags, selected_tag: '' })
            }
        }
    }

    onBlur = (e) => {
        let selectedTags = this.state.selectedTags ? this.state.selectedTags : []
        if(this.state.selected_tag && this.state.selected_tag !== '') {
            selectedTags.push(this.state.selected_tag.trim())
            this.setState({ selectedTags, selected_tag: '' })
        }
    }

    render() {		
        return (    
            !this.state.showLoading ?
                <div className={`mt-3 rounded ${(this.state.showActionLoading) ? 'disabled' : ''}`}>
                    <div className="row"> 
                        <div className="col-sm-12">
                            <div className="d-flex justify-content-between">
                                <h6 className="font-weight-normal">{capitalizeFirstLetter(this.props.pageType)+' Watermark'}</h6>
                                <i className="fas fa-arrow-left cursorPointer" onClick={() => this.props.showListPage()}> back</i>
                                {/* <button className='btn btn-sm btn-light mr-1' onClick={() => this.props.showListPage()}>Cancel</button> */}
                            </div>
                            <p>Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim.</p>
                        </div>
                    </div>
                    <div className={`${this.props.pageType === 'view' ? 'disabled disableInputColor' : ''}`}>
                        <div className={`form-group`}>
                            <p className="m-0">Watermark Name</p>
                            <input 
                                type="text" 
                                className={`form-control col-sm-6 ${this.state.hasError && (!this.state.name || this.state.name !== '') ? 'border-danger' : ''}`}
                                value={this.state.name ? this.state.name : ''}
                                onChange={e => this.setState({ name: e.target.value })}
                            />
                            {this.state.hasError && this.state.nameErrorMessage !== '' ?
                                <p className='m-0 text-danger align-self-end mb-1'>{this.state.nameErrorMessage}</p>
                            : null}
                        </div>
                        
                        <div className={`form-group`}>
                            <p className="m-0">Description</p>
                            <textarea
                                readOnly={this.props.pageType === 'view' ? true : false}
                                className="form-control textboxBorder"
                                onChange={(event) => this.setState({ description: event.target.value })} 
                                value={this.state.description}
                                maxLength={200}
                            />
                            {this.state.hasError && this.state.descriptionErrorMessage !== '' ?
                                <p className='m-0 text-danger align-self-end mb-1'>{this.state.descriptionErrorMessage}</p>
                            : null}
                        </div>

                        <div className={`form-group`}>
                            <p className="mb-0 mr-2 align-self-end mt-3">Tags</p>
                            <div className="d-flex">
                                <div className="p-0 col-sm-6">
                                    <input 
                                        type="text" 
                                        className="inputField form-control"
                                        value={this.state.selected_tag ? this.state.selected_tag : ''}
                                        onChange={e => this.setState({ selected_tag: e.target.value })}
                                        onKeyDown={e => this.onKeyDown(e)}
                                        onBlur={e => this.onBlur(e)}
                                    />
                                </div>
                                <div className=" d-flex flex-wrap">
                                    {this.state.selectedTags && this.state.selectedTags.map((item, index) => {
                                        return(
                                        <span key={index} className="badge-square badge-secondary f13 align-self-center p-1 mx-1 mb-1" >
                                        {item}
                                            <i onClick={ () => this.removeSelectedOption('selectedTags', item) } className='ml-1 fal fa-times cursorPointer'></i>
                                        </span>
                                        )
                                    })}
                                </div>
                            </div>
                            {/* {this.state.hasError && (!this.state.selectedTags || !this.state.selectedTags.length) ?
                                <p className='m-0 text-danger align-self-end mb-1'>Please enter tags</p>
                            : null} */}
                        </div>
                    </div>
                    <div className={`border-top pt-2 ${this.state.hasError && this.state.errorMessage !== '' ? '' : 'mt-5'}`}>
                        <button className='btn btn-light mr-1' onClick={() => this.props.showListPage()}>Cancel</button>
                        {this.props.pageType.toLowerCase() !== 'view' ?
                            !this.state.showActionCompleted ?
                                this.state.showActionLoading ?
                                    <button className='btn btn-primary' onClick={() => this.actionPorcess()}>
                                        <Spinner size='sm' className='mx-2' color='dark' /> {this.props.pageType.toLowerCase() === 'create' ? 'Creating ... ' : 'Updating ...' }
                                    </button>
                                : 
                                    <button className='btn btn-primary' onClick={() => this.actionPorcess()}>{this.props.pageType.toLowerCase() === 'create' ? 'Create' : 'Update' }</button>
                            :
                                <button className='btn btn-primary disabled'>
                                    {this.props.pageType.toLowerCase() === 'create' ? 'Created' : 'Updated' }
                                </button>
                        : null}
                    </div>
                </div>
            :
                <div className='text-center mt-5' >
                    <Spinner color='white' size='lg' />
                </div>
        
        )
    }
}

/**
 * Type of the props used in the component
 */
AddEdit.propTypes = {
    createWatermark: PropTypes.func,
    updateWatermark: PropTypes.func
}

const mapStateToProps = state => {
    return {
		dlpConfigWatermarklist: state.dlp.dlpConfigWatermarklist
    }
}

export default connect(mapStateToProps, {
    createWatermark,
    updateWatermark,
    listWatermark
})(withRouter(AddEdit))