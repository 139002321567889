/*************************************************
 * Tvastar
 * @exports
 * @file SidePanel.js
 * @author Prakash // on 28/06/2020
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { capitalizeFirstLetter, numberFormater, getAccountNameFromId } from '../../../utils/utility'
import { 
	getComplianceByAsset,
	getComplianceByRole,
	getComplianceByEvent,
	getComplianceByScoreCount,
	getRiskWiseComplexity,
	getObservations,
	getRiskByRole,
	getComplianceByTag,
	getDayWiseAssetCount
} from '../../../actions/detectors/complianceDashboardAction'
import {	
	getDayWiseCountTags,
	getScore,
	getCount
} from '../../../actions/detectors/checksDashboardAction'
import { DURATION_FILTER_OPTIONS } from '../../../utils/constants'
import { getDayWiseCount, getTotalCount } from '../../../actions/detectors/detectorsAction'
import { Spinner, UncontrolledTooltip  } from 'reactstrap'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import Chart from 'react-apexcharts'
import ApexDonutChart from '../../common/charts/ApexDonutChart'

import Select from 'react-select'

const environemntDropdown = [
	// {label:'All', value:'All'},
	{label:'Environment', value:'Environment'},
	{label:'Application', value:'Application'},
	{label:'Cost Centre', value:'Cost Centre'}
]

class ViewSidePanel extends Component {
	proEnvWrap = React.createRef()
	constructor(props) {
		super(props)
		this.props = props;
		this.state = {
			activeTab: 0,
			selectedProvider:this.props.selectedProvider,
			selectedComplicance:this.props.selectedComplicance,
			complianceScore: this.props.complianceScore,
			selectedDuration:this.props.selectedDuration,
			selectedAccount:this.props.selectedAccount,
			totalFailSum:this.props.totalFailSum,
			totalPassSum:this.props.totalPassSum, 
			failDetailsCount:this.props.failDetailsCount, 
			selectedTag:'Environment',
			detectorKey:'',
			complianceByAssets:[],
			selectedAssetType: '',
			complianceRoleCounts:[],
			roleWiseRisk:[],
			selectedServiceType: 'All',
			complianceByService:[],
			servicesFilterData:[],
			complianceByEvents:[],
			complexityByRisk: [],
			showLoading: true,
			assetsGraphCounts: [],
			
			assetRiskBarDetails: [],
			assetRiskProgressBar:[],

			servicesRiskBarDetails: [],
			servicesRiskProgressBar:[],

			events_graph_data:'',
			events_graph_options:[],
			bubble_complexity_data:[],
			bubble_complexity_options: [],
			dayWiseCountTags: [],
			score:[],
			proEnvTable: [],
			bar_data:[], 
			bar_option:[],
			assetObservations: [],
			serviceObservations: [],
			roleObservations: [],
			proEnvObservations: [],
			eventObservations: [],
			complexityObservations: [],
			complexityfilterData: [],
			dayWiseCountGraphData: [],
			dayWiseCounts: [],
			dayWiseAssetCounts: [],
			failSum:0,
			//for next and previous month record
			showPrevNextRecord: 0,
			daysShownInGraph: 30,
			graphRecordsRemainigToShow: 0,
			hideNextWeekBtn: false,
			hidePreviousWeekBtn: true,
			day_wise_fail_graph_data: [],
			day_wise_fail_graph_options: []
		}
	}

	componentDidMount = () => {
		//this.getComplianceByAsset();	
		this.getCount()	
		this.getDayWiseCount()
		this.getTotalCount('service_group')
		this.getDayWiseAssetCount()
	}	

    getCount = () => {
		let params = {}
        params.fields = ["asset_id", "event_id", "resource_type"]
        params.compliance = [{"complianceId":this.state.selectedComplicance}]
        if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
        }
        params.provider = this.state.selectedProvider.toLowerCase()
        params.duration = this.state.selectedDuration
		this.props.getCount(params, (promise, processCount) => {
			if (promise) {
				this.setState({ processCount });
			} else {
				this.setState({ processCount: [] })
			}
		})
    }

	getDayWiseCount = () => {
		let params = {}
        
        params.duration = '+10d'
        params.compliance = [{"complianceId":this.state.selectedComplicance}]
		params.provider = this.state.selectedProvider.toLowerCase()
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		
		this.props.getDayWiseCount(params, (promise, dayWiseCounts) => {
			if (promise) {
				this.setState({dayWiseCounts});
				this.dayWiseCountGraph();
			} else {
				this.setState({ dayWiseCounts: [] })
			}
		})		
    }
	
	dayWiseCountGraph = () => {
		let graphData
        let backgroundColor = "#7D57C5"
		graphData = this.state.dayWiseCounts;
		let graphLabel = []
		
        
		//let passData = [];
		let failData = [];
		//let labels = [];		
		let failSum = 0;
		if(graphData){			
			graphData.labels.forEach((item, x) => {
				graphLabel.push(moment(item).format('DD MMM'))
			})
			//labels = graphData.labels;
			//passData =  graphData.data.pass;
			failData =  graphData.data.fail;

			failSum = failData.reduce(function(a, b){
				return a + b;
			}, 0);
		}
		this.setState({failSum: failSum})

		let dailyFailData = [];
		let barChartData = [];
		
		barChartData = {
			name: '',
			data: failData
			// data: [10,3,0,7,15,2,5]
		};
		dailyFailData.push(barChartData)

		const barChartOptions = {
			tooltip: {
				enabled: true,
				//enabledOnSeries: undefined,
				//shared: false,
				//followCursor: false,
				//intersect: false,
				//inverseOrder: false,
				custom: function({series, seriesIndex, dataPointIndex, w}) {
				let val  = series[seriesIndex][dataPointIndex];
				val = Math.abs(Math.round(val))
				//let labelName = w.globals.initialSeries[seriesIndex].name
				//let val = str.replace('-',series[seriesIndex][dataPointIndex]);
				return '<div className="arrow_box">' +
					'<span> '+ w.globals.labels[dataPointIndex] + ' Fail Count: ' + val + ' </span>' +
					'</div>'
				},
				//fillSeriesColor: false,
				//theme: true,
				style: {
				fontSize: '9px',
				},
				onDatasetHover: {
					highlightDataSeries: true,
				},
				x: {
					//show: true,
					format: 'dd MMM',
					formatter: undefined,
				},
				y: {
					show: true,
					format: 'dd',
					//formatter: (value) => { return value },
					formatter: undefined,
					title: {
						formatter: (seriesName) => seriesName,
					},
				},
				z: {
					formatter: undefined,
					title: 'Size: 9px'
				},
				marker: {
					show: true,
				},
				fixed: {
					enabled: false,
					position: 'topRight',
					offsetX: 0,
					offsetY: 0,
				},
			},
			legend: {
				position: 'bottom',
				fontSize: '11px',
				markers: {
					width: 9,
					height: 9,
					strokeWidth: 0,
					strokeColor: '#fff',
					fillColors: undefined,
					radius: 9,
					customHTML: undefined,
					onClick: undefined,
					offsetX: 0,
					offsetY: 0
				}
			},	
			chart: {
				toolbar: {
					show: false,
				},
				sparkline: {
					enabled: true
				},
				type: 'bar',
				height: 200,
				stacked: false,
			},
			//colors: ['#1FCCE2'],<
			colors: [backgroundColor],
	
			plotOptions: {
			bar: {
				horizontal: false,
				barHeight: '50%',
				startingShape: 'rounded',
				endingShape: 'rounded',
				columnWidth: '50%',
				distributed: false,
				rangeBarOverlap: false,
				colors: {
					backgroundBarColors: ['#EDEDED'],
					backgroundBarOpacity: 1,
					backgroundBarRadius: 2,
				}        
			},          
			},
			dataLabels: {
			enabled: false
			},
			stroke: {
			width: 0,
			colors: ["#fff"]
			},
			
			grid: {  				        
			xaxis: {
				lines: {
				show: false
				},
			},
			yaxis: {
				lines: {
				show: false
				}
			},
			},
			yaxis: {
				labels: {
					show:false,
					style: {
						fontSize: '9px'
					}
				},
				axisBorder: {
					show: false
				},
				axisTicks: {
					fontSize: '9px',
					show: false,
					borderType: 'none',
					color: '#78909C',
					width: 6,
					offsetX: 0,
					offsetY: 0
				},
			},
			xaxis: {				
				axisBorder: {
					show: false
				},
				axisTicks: {
					tickAmount: 15,
					fontSize: '9px',
					show: false,
					borderType: 'none',
					color: '#78909C',
					width: 6,
					offsetX: 0,
					offsetY: 0
				},
				categories: graphLabel,
				labels: {
					show:false,
					style: {
						fontSize: '9px'
					}
				}
			},
		}

		this.setState({ day_wise_fail_graph_data: dailyFailData, day_wise_fail_graph_options: barChartOptions})
	}

	getTotalCount = aggregateBy => {
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
        params.duration = this.state.selectedDuration
		params.compliance = [{"complianceId":this.state.selectedComplicance}]
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
        if(aggregateBy === 'assetRisk') {
			params.aggregate_by = ['service_group','risk']
		} else if(aggregateBy === 'servicesRisk') {
			params.aggregate_by = ['resource_type','risk']
		} else {
			params.aggregate_by = aggregateBy
		}				

        this.props.getTotalCount(params, (promise, totalCount) => {
            if (promise) {
                if(aggregateBy === 'service_group') {
					let assetsGraphCounts = totalCount
					this.setState(
						{ assetsGraphCounts: _.orderBy(assetsGraphCounts, ['fail_count'], ['desc']) }, 
						() => this.loadAssetsGraph())

					this.getObservations('asset')
                } else if(aggregateBy === 'resource_type') {
                    let resourceTypeCounts = totalCount
					this.setState(
						{ resourceTypeCounts: _.orderBy(resourceTypeCounts, ['fail_count'], ['desc'])}, 
						() => this.loadServiceGraph()
					)					
                } else if(aggregateBy === 'assetRisk') {
					this.setState(
						{ assetRiskBarDetails: totalCount },
						() => this.loadAssetRiskDetails()
					)					
                } else if(aggregateBy === 'servicesRisk') {
					this.setState(
						{ servicesRiskBarDetails: totalCount },
						() => this.servicesRiskDetails()
					)										
                }
            } else {
                if(aggregateBy === 'service_group') {
                    this.setState({ assetsGraphCounts: [] })
                } else if(aggregateBy === 'resource_type') {
                    let resourceTypeCounts
                    this.setState({ resourceTypeCounts })
                } else if(aggregateBy === 'assetRisk') {
					this.setState({ assetRiskBarDetails: [] })
                }
            }
        })        
	}
	
	loadAssetsGraph = () => {
		let graphData = this.state.assetsGraphCounts.slice(0,10);
		let resourceDonutData = {}
		if(graphData) {
			resourceDonutData.label = 'Fail'
			let itemArray = []
			graphData.forEach(item=> {
				let dataRow = {}
				dataRow.item_count = item.fail_count
				dataRow.item_name = item.service_group
				itemArray.push(dataRow)
			})
			resourceDonutData.items = itemArray
		
			this.setState({ resourceDonutData }, 
				() => this.getTotalCount('assetRisk')
			)
		}
	}

	loadAssetRiskDetails = () => {
		let graphData = this.state.assetsGraphCounts.slice(0,10);
		let assetRiskArray = []
		if(graphData){
			for (const [key] of Object.entries(graphData)) {
				let value = graphData[key]
				//doughnuGraphLabels.push(+': '+value.fail_count);
				let dataRow = {}
				dataRow.name = value.service_group
				dataRow.totalFail = value.fail_count
				this.state.assetRiskBarDetails.forEach(riskItem => {
					if(value.service_group === riskItem.service_group){
						if(riskItem.risk === 'Low') {
							dataRow.Low = riskItem.fail_count 
						}
						if(riskItem.risk === 'Medium') {
							dataRow.Medium = riskItem.fail_count 
						}
						if(riskItem.risk === 'High') {
							dataRow.High = riskItem.fail_count 
						}
						if(riskItem.risk === 'Critical') {
							dataRow.Critical = riskItem.fail_count 
						}
					}
				})
				if(dataRow.Low === undefined) {
					dataRow.Low = 0
				}
				if(dataRow.Medium === undefined) {
					dataRow.Medium = 0
				}
				if(dataRow.High === undefined) {
					dataRow.High = 0
				}
				if(dataRow.Critical === undefined) {
					dataRow.Critical = 0
				}
				assetRiskArray.push(dataRow)				
			}
		}
		this.setState({
				assetRiskProgressBar: assetRiskArray
			}, 
			() => this.getComplianceByAsset())			
	}

	getDayWiseAssetCount = () => {
		let params = {}
        
        params.duration = '+10d'
        params.compliance = [{"complianceId":this.state.selectedComplicance}]
		params.provider = this.state.selectedProvider.toLowerCase()
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		
		this.props.getDayWiseAssetCount(params, (promise, dayWiseAssetCounts) => {
			if (promise) {
				this.setState({dayWiseAssetCounts});
				this.dayWiseAssetCountGraph();
			} else {
				this.setState({ dayWiseAssetCounts: [] })
			}
		})		
	}
	
	dayWiseAssetCountGraph = () => {
		let backgroundColor = "#7D57C5"
		let graphData = this.state.dayWiseAssetCounts;
		let graphLabel = []
        // console.log('graphData',graphData)
		//let passData = [];
		let failData = [];
		let failSum = 0;
		if(graphData){			
			graphData.labels.forEach((item, x) => {
				graphLabel.push(moment(item).format('DD MMM'))
			})
			//passData =  graphData.data.pass;
			failData =  graphData.data.fail;
			
			// passSum = passData.reduce(function(a, b){
			// 	return a + b;
			// }, 0);

			failSum = failData.reduce(function(a, b){
				return a + b;
			}, 0);
		}

		this.setState({failSum: failSum})

		let dailyAssetData = [];
		let barChartData = [];
		
		barChartData = {
			name: '',
			data: failData
		};
		dailyAssetData.push(barChartData)

		const barChartOptions = {
			tooltip: {
				enabled: true,
				//enabledOnSeries: undefined,
				//shared: false,
				//followCursor: false,
				//intersect: false,
				//inverseOrder: false,
				custom: function({series, seriesIndex, dataPointIndex, w}) {
				let val  = series[seriesIndex][dataPointIndex];
				val = Math.abs(Math.round(val))
				//let labelName = w.globals.initialSeries[seriesIndex].name
				//let val = str.replace('-',series[seriesIndex][dataPointIndex]);
				return '<div className="arrow_box">' +
					'<span> '+ w.globals.labels[dataPointIndex] + ' Resource Count: ' + val + ' </span>' +
					'</div>'
				},
				// return '<div className="arrow_box">' + val + '</div>'
				// },
				//fillSeriesColor: false,
				//theme: true,
				style: {
				fontSize: '9px',
				},
				onDatasetHover: {
					highlightDataSeries: true,
				},
				x: {
					//show: true,
					format: 'dd MMM',
					formatter: undefined,
				},
				y: {
					show: true,
					format: 'dd',
					//formatter: (value) => { return value },
					formatter: undefined,
					title: {
						formatter: (seriesName) => seriesName,
					},
				},
				z: {
					formatter: undefined,
					title: 'Size: 9px'
				},
				marker: {
					show: true,
				},
				fixed: {
					enabled: false,
					position: 'topRight',
					offsetX: 0,
					offsetY: 0,
				},
			},
			legend: {
				position: 'bottom',
				fontSize: '11px',
				markers: {
					width: 9,
					height: 9,
					strokeWidth: 0,
					strokeColor: '#fff',
					fillColors: undefined,
					radius: 9,
					customHTML: undefined,
					onClick: undefined,
					offsetX: 0,
					offsetY: 0
				}
			},	
			chart: {
				toolbar: {
					show: false,
				},
				sparkline: {
					enabled: true
				},
				type: 'bar',
				height: 200,
				stacked: false,
			},
			//colors: ['#1FCCE2'],<
			colors: [backgroundColor],
	
			plotOptions: {
			bar: {
				horizontal: false,
				barHeight: '50%',
				startingShape: 'rounded',
				endingShape: 'rounded',
				columnWidth: '50%',
				distributed: false,
				rangeBarOverlap: false,
				colors: {
					backgroundBarColors: ['#EDEDED'],
					backgroundBarOpacity: 1,
					backgroundBarRadius: 2,
				}        
			},          
			},
			dataLabels: {
			enabled: false
			},
			stroke: {
			width: 0,
			colors: ["#fff"]
			},
			
			grid: {  				        
			xaxis: {
				lines: {
				show: false
				},
			},
			yaxis: {
				lines: {
				show: false
				}
			},
			},
			yaxis: {
				labels: {
					show:false,
					style: {
						fontSize: '9px'
					}
				},
				axisBorder: {
					show: false
				},
				axisTicks: {
					fontSize: '9px',
					show: false,
					borderType: 'none',
					color: '#78909C',
					width: 6,
					offsetX: 0,
					offsetY: 0
				},
			},
			xaxis: {				
				axisBorder: {
					show: false
				},
				axisTicks: {
					tickAmount: 15,
					fontSize: '9px',
					show: false,
					borderType: 'none',
					color: '#78909C',
					width: 6,
					offsetX: 0,
					offsetY: 0
				},
				categories: graphLabel,
				labels: {
					show:false,
					style: {
						fontSize: '9px'
					}
				}
			},
		}

		this.setState( {asset_bar_graph_data: dailyAssetData, asset_bar_graph_options:barChartOptions })
	}

	getComplianceByAsset = () => {
		let params = {}
		// params = {
		// 	provider: this.state.selectedProvider.toLowerCase(),
		// 	duration: this.state.selectedDuration,
		// 	compliance: [{"complianceId":this.state.selectedComplicance}]
		// }

		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		params.compliance = [{"complianceId":this.state.selectedComplicance}]
		params.service_group = this.state.selectedAssetType

		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		
		this.props.getComplianceByAsset(params, (promise, complianceByAssets) => {
			if (promise) {
				//console.log('complianceByAssets', complianceByAssets)
				let result = _.orderBy(complianceByAssets, ['fail'], ['desc']);
				let formedArray = []
				result.forEach(item => {
					let subArray = []
					if(item.controls && item.controls.length > 1) {
						for(let i=0;i<=(item.controls.length-1);i+=2) {
							let dataRow = {}
							dataRow.one = item.controls[i]
							if(item.controls[i+1]) {
								dataRow.two = item.controls[i+1]
							}
							subArray.push(dataRow)
						}
					} else if(item.controls && item.controls.length) {
						let dataRow = {}
						dataRow.one = item.controls
						subArray.push(dataRow)
						// array.push(subArray)
					}
					if(subArray.length) {
						item.controlRedefined = subArray
					}
					formedArray.push(item)
				})
				//console.log('formedArray', formedArray)
				//this.setState({ complianceByAssets: result, complianceByAssetsRedefined: formedArray, showLoading:false });
				this.setState({ complianceByAssets: formedArray, showLoading:false });
			} else {
				this.setState({ complianceByAssets: [],  showLoading:false })
			}
		})
	}

	filterComplianceBySearch = type => {
		if(type === 'serviceType') {
			if(this.state.selectedServiceType !== 'all') {
				let filterData = this.state.complianceByService;
				this.setState({ servicesFilterData: _.orderBy(filterData.filter(serviceBy => serviceBy.resource_type === this.state.selectedServiceType), ['fail_count'], ['desc']), showLoading:false})
			} else {
				let filterData = this.state.complianceByService;
				this.setState({ servicesFilterData: _.orderBy(filterData, ['fail_count'], ['desc']), showLoading:false})
			}
		}
	}

	getRiskWiseComplexity = () => {
		let params = {}
		// params = {
		// 	provider: this.state.selectedProvider.toLowerCase(),
		// 	duration: this.state.selectedDuration,
		// 	compliance: [{"complianceId":this.state.selectedComplicance}]
		// }

		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		params.compliance = [{"complianceId":this.state.selectedComplicance}]
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		// console.log('complexityByRisk',params)
		this.props.getRiskWiseComplexity(params, (promise, complexityByRisk) => {
			// console.log('complexityByRisk',complexityByRisk)
			if (promise) {
				this.setState({ complexityByRisk, showLoading:false});
				this.getObservations('complexity')
				this.loadBubbleGraph();
			} else {
				this.setState({ complexityByRisk: [], showLoading:false })
			}
		})
	}
	
	getObservations = observationFor => {
		let params = {}
		//observationFor = asset/service/role
		// let params = {
		// 	observation_type: observationFor,
		// 	provider: this.state.selectedProvider,
		// 	duration: this.state.selectedDuration,
		// 	compliance:[{"complianceId":this.state.selectedComplicance}]
		// }

		params.observation_type = observationFor
		params.provider = this.state.selectedProvider
		params.duration = this.state.selectedDuration
		params.compliance = [{"complianceId":this.state.selectedComplicance}]

		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}

		if(observationFor === 'complexity') {
			params.observation_type = "complexity"
		}

		this.props.getObservations(params, (promise, observations) => {
			if (promise) {
				if(observationFor === 'asset') {
					this.setState({ assetObservations: observations});
					this.setState({ eventObservations: observations});
					this.setState({ proEnvObservations: observations});
				} else if(observationFor === 'service') {
					this.setState({ serviceObservations: observations});
				} else if(observationFor === 'role') {
					this.setState({ roleObservations: observations});
				} else if(observationFor === 'complexity') {
					this.setState({ complexityObservations: observations, complexityfilterData: observations});
				}
			
			} else {
				if(observationFor === 'asset') {
					this.setState({ assetObservations: [] });
					this.setState({ eventObservations: [] });
					this.setState({ proEnvObservations: [] });
				} else if(observationFor === 'service') {
					this.setState({ serviceObservations: [] });
				} else if(observationFor === 'role') {
					this.setState({ roleObservations: [] });
				} else if(observationFor === 'complexity') {
					this.setState({ complexityObservations: [], complexityfilterData: [] });
				}
			}
		})
	}

	loadBubbleGraph = () => {
		let lowBubble = []
		let mediumBubble = []
		let highBubble = []
		let criticalBubble = []
		this.state.complexityByRisk && this.state.complexityByRisk.forEach(bub => {
			let complexities = bub.complexities;
			let risk = 0;
			if(bub.risk === 'Low') {
				risk = 1;
			} else if(bub.risk === 'Medium') {
				risk = 2;
			} else if(bub.risk === 'High') {
				risk = 3;
			} else if(bub.risk === 'Critical') {
				risk = 4;
			}

			for(let i=0;i<complexities.length;i++){
				let dataRow = []				
				
				let complexity = complexities[i].complexity
				if(complexity === '' || complexity === 0) {
					complexity = 10;
				}
				dataRow.push(complexity)
				dataRow.push(risk)
				let x = 8
				if(parseInt(complexities[i].count) > 100 && parseInt(complexities[i].count) < 500) {
					x = 10
				} else if(parseInt(complexities[i].count) > 500 && parseInt(complexities[i].count) < 1000) {
					x = 13
				} else if(parseInt(complexities[i].count) > 1000 && parseInt(complexities[i].count) < 2500) {
					x = 16
				} else if(parseInt(complexities[i].count) > 2500 && parseInt(complexities[i].count) < 5000) {
					x = 19
				} else if(parseInt(complexities[i].count) > 5000 && parseInt(complexities[i].count) < 10000) {
					x = 22
				} else if(parseInt(complexities[i].count) > 10000)  {
					x = 25
				}

				dataRow.push(x)
				//let complexities = numberFormatercomplexities[i].count * 100) / element.fail
				dataRow.push(complexities[i].count)
				if(complexities[i].count !== '' && complexities[i].count !== 0) {
					if(risk === 1) {
						lowBubble.push(dataRow)
					} else if(risk === 2) {
						mediumBubble.push(dataRow)
					} else if(risk === 3) {
						highBubble.push(dataRow)
					} else if(risk === 4) {
						criticalBubble.push(dataRow)
					}	
				}
			}						
		})
		
		//to prevent bubble to cut in graph start
		let dataRow = []
		dataRow.push(11)
		dataRow.push(0)
		dataRow.push(0)
		dataRow.push(0)
		lowBubble.push(dataRow)
		mediumBubble.push(dataRow)
		highBubble.push(dataRow)
		criticalBubble.push(dataRow)
		//to prevent bubble to cut in graph start

		

		const bubble_series = [{
			name: 'Low',
			data: lowBubble
		},
		{
			name: 'Medium',
			data: mediumBubble
		},
		{
			name: 'High',
			data: highBubble
		},
		{
			name: 'Critical',
			data: criticalBubble
		}]
		const bubble_options = {
			colors: ['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597'],
			tooltip: {
				enabled: true,
				//enabledOnSeries: undefined,
				//shared: false,
				//followCursor: false,
				//intersect: false,
				//inverseOrder: false,
				custom: function({series, seriesIndex, dataPointIndex, w}) {
					//let val  = series[seriesIndex][dataPointIndex];
					//val = Math.abs(Math.round(val))
					//console.log('--------------',w.globals)
					let count = w.globals.initialSeries[seriesIndex].data[dataPointIndex][3]
					let labelName = w.globals.initialSeries[seriesIndex].name					
					// if(val === 1) {
					// 	val = 'Low'
					// } else if(val === 2) {
					// 	val = 'Medium'
					// } else if(val === 3) {
					// 	val = 'High'
					// }  else if(val === 1) {
					// 	val = 'Critical'
					// }     
				  //let val = str.replace('-',series[seriesIndex][dataPointIndex]);
				//   return '<div class="arrow_box">' +
				// 	'<span> '+ (parseInt(w.globals.labels[dataPointIndex]) + parseInt(1)) + 
				// 	' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val + ' </span>' +
				// 	'</div>'
					return '<div class="arrow_box">' +
					'<span> <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span>   '+ count +' </span>' +
					'</div>'
				}           
			},
			legend: {
				show: true,
				position: 'top',
				fontSize: '12px',
				fontFamily: 'Muli, sans-serif',
				labels: {
					colors: '#A9A9A9',
					useSeriesColors: false
				},
			},
			chart: {
				events: {
					click: (event, chartContext, config, dataPointIndex) => {
					  // console.log(event)
					  // console.log(chartContext)
					  // console.log(config)
					  // console.log(config.seriesIndex)
					  // console.log(config.dataPointIndex)
					  let risk = 'all';
					  let complexity = 'all';
					  if([config.seriesIndex] >= 0 && [config.dataPointIndex] >= 0) {
						  risk = config.globals.series[config.seriesIndex][config.dataPointIndex]
						  complexity = config.globals.seriesX[config.seriesIndex][config.dataPointIndex]
					  }
					  this.complextiyFilter(risk, complexity)              
					}
				},
				height: 350,
				type: 'bubble',
				toolbar: {
					show: false,
				},
				zoom: {
					enabled: false,
				}
			},
			dataLabels: {
				enabled: false
			},
			fill: {
				opacity: 0.8
			},
			title: {
				text: ''
			},
			xaxis: {            
				tickAmount: 10,
				type: 'category',
				min: 0,
				labels: {
					formatter: function (val) {
						val = parseInt(val)
						if(Math.abs(Math.round(val)) > 10) {
							return ''
						}
						return Math.abs(Math.round(val))
					},
					style: {
						colors: '#A9A9A9'
					},
					axisTicks: {
						show: false
					}
				},				
			},
			yaxis: {          
				tickAmount: 5, 
				min: 0,
				max: 5,
				//categories: ['85+', '80-84', '75-79', '70-74', '65-69'],
				//categories: ['', 'Low', 'Medium', 'High', 'Critical']
				labels: {
					offsetX: 13,
					align: 'right',
					opposite: false,
					rotate: 0,
					rotateAlways: false,
					hideOverlappingLabels: false,
					showDuplicates: false,
					trim: false,
					style: {
						left: 30,
						colors: '#A9A9A9',
						fontSize: '9px',
						fontFamily: 'Helvetica, Arial, sans-serif',
						fontWeight: 400,
						cssClass: 'apexcharts-yaxis-label',
					},
					formatter: function (val) {
						if(val === 1) {
							return 'Low'
						} else if(val === 2) {
							return 'Medium'
						} else if(val === 3) {
							return 'High'
						} else if(val === 4) {
							return 'Critical'
						}
						return '';
						//return Math.abs(Math.round(val)) + "%"
					},
				},
			},
			grid: {
				padding: {
				  left: 25,
				},
			}
		}

		this.setState( {bubble_complexity_data:bubble_series, bubble_complexity_options:bubble_options, showLoading:false})

	}

	complextiyFilter = (risk, complexity) => {
		let riskText = ''
		if(risk === '1') {
			riskText = "Low"
		} else if(risk === '2') {
			riskText = "Medium"
		} else if(risk === '3') {
			riskText = "High"
		} else if(risk === '4') {
			riskText = "Critical"
		}
		
		let filterData = this.state.complexityObservations;
		if(risk !== 'all') {
			this.setState({ complexityfilterData: filterData.filter(bubble => bubble.risk === riskText && bubble.complexity === complexity)})
		} else {
			this.setState({ complexityfilterData: filterData })
		}
	}

	getComplianceByRole = () => {
		let params = {}
		// params = {
		// 	provider: this.state.selectedProvider.toLowerCase(),
		// 	duration: this.state.selectedDuration,
		// 	compliance: [{"complianceId":this.state.selectedComplicance}]
		// }

		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		params.compliance = [{"complianceId":this.state.selectedComplicance}]

		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		
		this.props.getComplianceByRole(params, (promise, complianceRoleCounts) => {    
			if (promise) {
				let result = _.orderBy(complianceRoleCounts, ['fail'], ['desc']);
				let formedArray = []
				result.forEach(item => {
					let subArray = []
					if(item.controls && item.controls.length > 1) {
						for(let i=0;i<=(item.controls.length-1);i+=2) {
							let dataRow = {}
							dataRow.one = item.controls[i]
							if(item.controls[i+1]) {
								dataRow.two = item.controls[i+1]
							}
							subArray.push(dataRow)
						}
					} else if(item.controls && item.controls.length) {
						let dataRow = {}
						dataRow.one = item.controls
						subArray.push(dataRow)
						// array.push(subArray)
					}
					if(subArray.length) {
						item.controlRedefined = subArray
					}
					formedArray.push(item)
				})

				this.setState({ complianceRoleCounts: formedArray})
				this.getObservations('role')
				this.loadRoleGraph();
				this.getRiskByRole()
				this.getDayWiseCount()
			} else {
				this.setState({ complianceRoleCounts: [],  showLoading:false })
			}
		})
	}
	
	getRiskByRole = () => {
		let params = {}
		// let params = {
		// 	provider: this.state.selectedProvider.toLowerCase(),
		// 	duration: this.state.selectedDuration,
		// 	compliance:[{"complianceId":this.state.selectedComplicance}]
		// }

		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		params.compliance = [{"complianceId":this.state.selectedComplicance}]

		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		
		this.props.getRiskByRole(params, (promise, roleWiseRisk) => {
			if (promise) {				
				let roleRiskBar = [];
				if(roleWiseRisk.length>0) {
					for(let i=0;i<roleWiseRisk.length;i++){
						
						let dataRow = {};
						let totalFail = 0
						dataRow.name = roleWiseRisk[i].role_name
						
						if(roleWiseRisk[i].Critical === undefined) {
							dataRow.Critical = 0
						} else {
							dataRow.Critical = roleWiseRisk[i].Critical.fail
							totalFail = totalFail + dataRow.Critical
						}
						if(roleWiseRisk[i].High === undefined) {
							dataRow.High = 0
						} else {
							dataRow.High = roleWiseRisk[i].High.fail
							totalFail = totalFail + dataRow.High
						}
						if(roleWiseRisk[i].Medium === undefined) {
							dataRow.Medium = 0
						} else {
							dataRow.Medium = roleWiseRisk[i].Medium.fail
							totalFail = totalFail + dataRow.Medium
						}
						if(roleWiseRisk[i].Low === undefined) {
							dataRow.Low = 0
						} else {
							dataRow.Low = roleWiseRisk[i].Low.fail
							totalFail = totalFail + dataRow.Low
						}
						dataRow.totalFail = totalFail

						roleRiskBar.push(dataRow)
					}
				}

				this.setState({ roleWiseRisk: _.orderBy(roleRiskBar, ['totalFail'], ['desc']), showLoading:false })
			} else {
				this.setState({ roleWiseRisk: [] });
			}
		})
	}

	loadRoleGraph= () => {
		let graphData = this.state.complianceRoleCounts.slice(0,10);
		let roleDonutData = {}
		let totalCount = 0
		if(graphData) {
			roleDonutData.label = 'Fail'
			let itemArray = []
			graphData.forEach(item=> {
				let dataRow = {}
				totalCount += item.fail
				dataRow.item_count = item.fail
				dataRow.item_name = item.role_name
				itemArray.push(dataRow)
			})
			roleDonutData.items = itemArray
			roleDonutData.total_count = totalCount
		
			this.setState({ roleDonutData })
		}
	}

	getComplianceByService = serviceType => {
		let params = {}
		// params = {
		// 	provider: this.state.selectedProvider.toLowerCase(),
		// 	duration: this.state.selectedDuration,
		// 	compliance: [{"complianceId":this.state.selectedComplicance}]
		// }

		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		params.compliance = [{"complianceId":this.state.selectedComplicance}]

		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		
		this.props.getComplianceByScoreCount(params, (promise, complianceByService) => {
			if (promise) {
				
				let result = _.orderBy(complianceByService, ['fail_count'], ['desc'])
				let formedArray = []
				result.forEach(item => {
					let subArray = []
					if(item.controls && item.controls.length > 1) {
						for(let i=0;i<=(item.controls.length-1);i+=2) {
							let dataRow = {}
							dataRow.one = item.controls[i]
							if(item.controls[i+1]) {
								dataRow.two = item.controls[i+1]
							}
							subArray.push(dataRow)
						}
					} else if(item.controls && item.controls.length) {
						let dataRow = {}
						dataRow.one = item.controls
						subArray.push(dataRow)
						// array.push(subArray)
					}
					if(subArray.length) {
						item.controlRedefined = subArray
					}
					formedArray.push(item)
				})

				this.setState({ complianceByService: formedArray, servicesFilterData: formedArray})
				this.getObservations('service')
				this.getTotalCount('resource_type')
			} else {
				this.setState({ complianceByService: [], servicesFilterData: [],  showLoading:false })
			}
		})
	}		

	loadServiceGraph= () => {
		let graphData = ''
		if(this.state.resourceTypeCounts && this.state.resourceTypeCounts.length > 0) {
			graphData = this.state.resourceTypeCounts.slice(0,10);
		}
		let serviceDonutData = {}
		if(graphData) {
			serviceDonutData.label = 'Fail'
			let itemArray = []
			graphData.forEach(item=> {
				let dataRow = {}
				dataRow.item_count = item.fail_count
				dataRow.item_name = item.resource_type
				itemArray.push(dataRow)
			})
			serviceDonutData.items = itemArray
		
			this.setState({ serviceDonutData }, 
				() => this.getTotalCount('servicesRisk')
			)
		}
	}

	servicesRiskDetails = () => {
		let graphData = '';
		if(this.state.resourceTypeCounts.length > 0) {
			graphData = this.state.resourceTypeCounts.slice(0,10)
		}
		let servicesRiskArray = []
		if(graphData){
			for (const [key] of Object.entries(graphData)) {
				let value = graphData[key]
				//doughnuGraphLabels.push(+': '+value.fail_count);
				let dataRow = {}
				dataRow.name = value.resource_type
				dataRow.totalFail = value.fail_count
				this.state.servicesRiskBarDetails.forEach(riskItem => {
					if(value.resource_type === riskItem.resource_type){
						if(riskItem.risk === 'Low') {
							dataRow.Low = riskItem.fail_count 
						}
						if(riskItem.risk === 'Medium') {
							dataRow.Medium = riskItem.fail_count 
						}
						if(riskItem.risk === 'High') {
							dataRow.High = riskItem.fail_count 
						}
						if(riskItem.risk === 'Critical') {
							dataRow.Critical = riskItem.fail_count 
						}
					}
				})
				if(dataRow.Low === undefined) {
					dataRow.Low = 0
				}
				if(dataRow.Medium === undefined) {
					dataRow.Medium = 0
				}
				if(dataRow.High === undefined) {
					dataRow.High = 0
				}
				if(dataRow.Critical === undefined) {
					dataRow.Critical = 0
				}
				servicesRiskArray.push(dataRow)				
			}
		}
		this.setState({servicesRiskProgressBar: servicesRiskArray, showLoading:false})			
	}

	getComplianceByEvent = () => {
		let params = {}
		// params = {
		// 	provider: this.state.selectedProvider.toLowerCase(),
		// 	duration: this.state.selectedDuration,
		// 	compliance: [{"complianceId":this.state.selectedComplicance}]
		// }

		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		params.compliance = [{"complianceId":this.state.selectedComplicance}]

		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		
		this.props.getComplianceByEvent(params, (promise, complianceByEvents) => {
			if(promise) {
				let formedArray = []
				complianceByEvents.forEach(item => {
					let subArray = []
					if(item.controls && item.controls.length > 1) {
						for(let i=0;i<=(item.controls.length-1);i+=2) {
							let dataRow = {}
							dataRow.one = item.controls[i]
							if(item.controls[i+1]) {
								dataRow.two = item.controls[i+1]
							}
							subArray.push(dataRow)
						}
					} else if(item.controls && item.controls.length) {
						let dataRow = {}
						dataRow.one = item.controls
						subArray.push(dataRow)
						// array.push(subArray)
					}
					if(subArray.length) {
						item.controlRedefined = subArray
					}
					formedArray.push(item)
				})

				console.log('formedArray', formedArray)
				this.setState({ complianceByEvents: formedArray })
				this.getObservations('asset')
				this.loadEventsGraph();
			} else {
				this.setState({ complianceByEvents: [], showLoading:false })
			}
		})
	}
	
	loadEventsGraph= () => {
		let graphData = this.state.complianceByEvents.slice(0,10);
		let summaryGraphLabels = [];
		//let graphColors = ['#5D38AA', '#24A89D', '#22B5B5', '#2DCBDC', '#1FCCE2'];
		
		let eventRiskArray = [] 
		if(graphData){		
			for (const [key] of Object.entries(graphData)) {
				let value = graphData[key]
				let dataRow = {}
				dataRow.name = value.event_name
				summaryGraphLabels.push(value.event_name);
				let value1 = value.risk_details;
				let lowExist = 0
				let mediumExist = 0
				let highExist = 0
				let criticalExist = 0
				let i =1;
				let totalFail = 0
				for(const [detKey] of Object.entries(value1)) {
					let detValue = value1[detKey]
					if(detValue.risk === "Low"){
						lowExist = 1
						dataRow.Low = detValue.fail
						totalFail = totalFail + detValue.fail
					}
					if(detValue.risk === "Medium"){
						mediumExist = 1
						dataRow.Medium = detValue.fail
						totalFail = totalFail + detValue.fail
					}
					if(detValue.risk === "High"){
						highExist = 1
						dataRow.High = detValue.fail
						totalFail = totalFail + detValue.fail
					}
					if(detValue.risk === "Critical"){
						criticalExist = 1
						dataRow.Critical = detValue.fail
						totalFail = totalFail + detValue.fail
					}
					if(i===value1.length) {
						if(lowExist === 0) {
							dataRow.Low = 0
						}
						if(mediumExist === 0) {
							dataRow.Medium = 0
						}
						if(highExist === 0) {
							dataRow.High = 0
						}
						if(criticalExist === 0) {
							dataRow.Critical = 0
						}
						dataRow.totalFail = totalFail
					}
					i++;					
				}
				eventRiskArray.push(dataRow)
			}
		}


		this.setState({events_graph_data:eventRiskArray, showLoading:false })
	}
	
	//project/env/region start
	getDayWiseCountTags = appChange => {
		let params = {}
		//params.provider = this.state.selectedProvider
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration
		params.aggregate_by = "tag"
		params.compliance = [{"complianceId":this.state.selectedComplicance}]
		
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}

		if(appChange || this.state.detectorKey === '') {
			params.tags = [{"key":this.state.selectedTag}]
		} else {
			params.tags  = [{"key":this.state.selectedTag, "value": this.state.detectorKey}]
		}
		//console.log('params',params)
		this.props.getDayWiseCountTags(params, (promise, dayWiseCountTags) => {
			//console.log('dayWiseCountTags',dayWiseCountTags)
			if (promise) {
				if(appChange ) {					 
					this.setState({detectorKey: ''})
				}
				this.setState({dayWiseCountTags},()=> {
					this.getScore()
					this.getObservations('asset')
					this.getComplianceByTag()
					this.showSummaryGraph();
				});
			} else {
				this.setState({ dayWiseCountTags: [] })
			}
		})
	}

	getScore = () => {
		let params = {}
		
		params.duration = this.state.selectedDuration
		params.provider = this.state.selectedProvider.toLowerCase()
		params.score_category = ["overall"]
		params.group_by =["tags"]
		params.tags =[{"key":this.state.selectedTag}]

		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}

		this.props.getScore(params, (promise, score) => {			
			if (promise) {
				this.setState({ score })
			} else {
				this.setState({ score: [] })
			}
		})
	}
	
	showSummaryGraph = () => {
		let graphData = this.state.dayWiseCountTags.data;
		let barChartData = [];
		let tagAccount = this.state.detectorKey
		let summaryGraphLabelsData = [];

		let daysShownInGraph = this.state.daysShownInGraph
		let week = this.state.showPrevNextRecord
		if(week < 1) {
			this.setState({hidePreviousWeekBtn:true})
		}
		
		let graphLength = 0

		if(graphData) {	
			if(tagAccount === '') {
				for (const [key] of Object.entries(graphData)) {
					let value = graphData[key]
					for(const [detKey] of Object.entries(value)) {
						tagAccount = detKey
						this.setState({ detectorKey: tagAccount})
						break;
					}
				}
			}
			for (const [key] of Object.entries(graphData)) {
				let value = graphData[key]
				let i=0;
				for(const [detKey, detValue] of Object.entries(value)) {
					if(tagAccount === detKey) {
						let length = 0
						if(detValue.Low !== undefined) {
							length = detValue.Low.pass.length
						} else if(detValue.Medium !== undefined) {
							length = detValue.Medium.pass.length
						} else if(detValue.high !== undefined) {
							length = detValue.high.pass.length
						} else if(detValue.Critical !== undefined) {
							length = detValue.Critical.pass.length
						}						
						let passValueArray = []
						let lowExistArray = []
						let mediumExistArray = []
						let highExistArray = []
						let criticalExistArray = []
						let totalArray = []
						// if(length > 30) {
						// 	length = 30
						// }

						if(week > 0) {
							this.setState({hideNextWeekBtn:true, hidePreviousWeekBtn: false})
						}
						
						this.setState({hideNextWeekBtn:true})
						i = daysShownInGraph * week
						graphLength = daysShownInGraph * (week + 1)
						let graphRecordsRemainigToShow = length - graphLength;
						
						if(graphRecordsRemainigToShow === 0) {
							this.setState({hideNextWeekBtn: true})
						} else if(graphLength > length && graphRecordsRemainigToShow < daysShownInGraph){
							graphLength = graphLength + graphRecordsRemainigToShow
						} else {
							this.setState({hideNextWeekBtn: false})
						}


						summaryGraphLabelsData = []
						let x = i
						for (x;x<graphLength;x++) {
							if(x < graphLength) {
								summaryGraphLabelsData.push(moment(this.state.dayWiseCountTags.labels[x]).format('DD MMM'))
							} else {
								break;
							}
						}

						for(i;i<parseInt(graphLength);i++) {
							let passValue = 0;
							if(detValue.Low !== undefined) {
								passValue = passValue + detValue.Low.pass[i]
								lowExistArray.push(detValue.Low.fail[i])
							}
							
							if(detValue.Medium !== undefined) {
								passValue = passValue + detValue.Medium.pass[i]
								mediumExistArray.push(detValue.Medium.fail[i])
							}
							
							if(detValue.High !== undefined) {
								passValue = passValue + detValue.High.pass[i]
								highExistArray.push(detValue.High.fail[i])
							}
							
							if(detValue.Critical !== undefined) {
								passValue = passValue + detValue.Critical.pass[i]
								criticalExistArray.push(detValue.Critical.fail[i])
							}
							passValueArray.push(-passValue)							
						}	
						totalArray.push(passValueArray)
						if(lowExistArray.length>0) {
							totalArray.push(lowExistArray)
						} else {
							totalArray.push(0)
						}
						if(mediumExistArray.length>0) {
							totalArray.push(mediumExistArray)
						} else {
							totalArray.push(0)
						}
						if(highExistArray.length>0) {
							totalArray.push(highExistArray)
						} else {
							totalArray.push(0)
						}
						if(criticalExistArray.length>0) {
							totalArray.push(criticalExistArray)
						} else {
							totalArray.push(0)
						}

						let dataRows = ''
						for(let m=0; m<totalArray.length; m++) {
							if(totalArray[m] === 0) {
								dataRows = Array.apply(null, Array(this.state.daysShownInGraph)).map(Number.prototype.valueOf,0);
							} else {
								dataRows = totalArray[m]
							}
							
							let summaryDataSet = {};
							summaryDataSet['data'] = dataRows
							if(m === 0) {
								summaryDataSet['name'] = 'Pass';
							} else if(m === 1) {
								summaryDataSet['name'] = 'Low';
							} else if(m === 2) {
								summaryDataSet['name'] = 'Medium';
							} else if(m === 3) {
								summaryDataSet['name'] = 'High';
							} else if(m === 4) {
								summaryDataSet['name'] = 'Critical';
							}
							barChartData.push(summaryDataSet);
						}
					}
				}
				break;
			}
		}
		// let summaryGraphLabelsData = [];

		// if(this.state.dayWiseCountTags.labels){
		// 	let x = 0;
		// 	for (const [key, value] of Object.entries(this.state.dayWiseCountTags.labels)) {
		// 		if(x < 30) {
		// 			summaryGraphLabelsData.push(moment(value).format('DD MMM'))
		// 		} else {
		// 			break;
		// 		}
		// 		x++;
		// 	}
		// }
		// barChartData = {
		// 	labels:summaryGraphLabelsData,
		// 	datasets: barChartData
		// };

		const barChartOptions = {
			tooltip: {
				enabled: true,
				//enabledOnSeries: undefined,
				//shared: false,
				//followCursor: false,
				//intersect: false,
				//inverseOrder: false,
				custom: function({series, seriesIndex, dataPointIndex, w}) {
				  let val  = series[seriesIndex][dataPointIndex];
				  val = Math.abs(Math.round(val))
				  //console.log('--------------',w.globals)
				  let labelName = w.globals.initialSeries[seriesIndex].name
				  //let val = str.replace('-',series[seriesIndex][dataPointIndex]);
				  return '<div class="arrow_box">' +
					'<span> '+ w.globals.labels[dataPointIndex] + 
					' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val + ' </span>' +
					'</div>'
				},
				//fillSeriesColor: false,
				//theme: true,
				style: {
				  fontSize: '9px',
				},
				onDatasetHover: {
					highlightDataSeries: true,
				},
				x: {
					//show: true,
					format: 'dd MMM',
					formatter: undefined,
				},
				y: {
					show: true,
					format: 'dd',
					//formatter: (value) => { return value },
					formatter: undefined,
					title: {
						formatter: (seriesName) => seriesName,
					},
				},
				z: {
					formatter: undefined,
					title: 'Size: 9px'
				},
				marker: {
					show: true,
				},
				fixed: {
					enabled: false,
					position: 'topRight',
					offsetX: 0,
					offsetY: 0,
				},
			},
			legend: {
				position: 'bottom',
				fontSize: '11px',
				labels: {
					colors: '#60687C',
				},
				markers: {
					width: 9,
					height: 9,
					strokeWidth: 0,
					strokeColor: '#fff',
					radius: 9,
					offsetX: 0,
					offsetY: 0
				}
			},
			chart: {
				width: '100%',
				toolbar: {
					show: false,
				},
				//   sparkline: {
				// 	enabled: true
				//   },
				padding: {
					top:400
				},
			  	type: 'bar',
			  	height: 440,
			  	stacked: true,
			  
			},
			// colors: ['#5D38AA', '#00B5D4', '#67E1D6', '#2ED1BE', '#26B68F'],
			colors: ['#FFD200', '#039BE5', '#3F73AD', '#9A77D1', '#5F5BA2'],
			fill: {
			  	opacity: 1
			},
			plotOptions: {
				bar: {
					horizontal: false,
					barHeight: '100%',
					startingShape: 'flat',
					endingShape: 'flat',
					columnWidth: '40%',
					barWidth: '50%',
					distributed: false,
					rangeBarOverlap: false,
					colors: {
						ranges: [{
							from: 1,
							to: 0.9,
							color: '#FF4560'
						}],
						backgroundBarColors: ['#333947'],
						backgroundBarOpacity: 1,
						backgroundBarRadius: 2,
					}
				},
			},
			dataLabels: {
			  	enabled: false
			},
			stroke: {
				width: 0,
				colors: ["#fff"]
			},
			
			grid: {  				        
			  xaxis: {
				lines: {
				  show: false
				},
			  },
			  yaxis: {
				lines: {
				  show: false
				}
			  },
			  row: {
				colors: undefined,
				opacity: 0.5
			  },  
			  column: {
				  colors: undefined,
				  opacity: 0.5
			  },  
			  padding: {
				  top: 0,
				  right: 0,
				  bottom: 0,
				  left: 0
			  }, 
			},
			yaxis: {
			  labels: {			
				formatter: function (val) {
					return Math.abs(Math.round(val))
				},	
				style: {
					fontSize: '9px',
					colors: '#60687C'
				}
			  },
			  axisBorder: {
				show: false
			  },
			  axisTicks: {
				  fontSize: '9px',
				  show: false,
				  borderType: 'none',
				  color: '#78909C',
				  width: 6,
				  offsetX: 0,
				  offsetY: 0
			  },
			},
			xaxis: {				
				axisBorder: {
					show: false
				},
				axisTicks: {
					tickAmount: 15,
					fontSize: '9px',
					show: false,
					borderType: 'none',
					color: '#78909C',
					width: 6,
					offsetX: 0,
					offsetY: 0
				},
				categories: summaryGraphLabelsData,
				labels: {					
					style: {
						fontSize: '9px',
						colors: '#60687C'
					}
				}
			},
		}
			
		this.setState({bar_data:barChartData, bar_option:barChartOptions, showLoading: false});
	}

	getComplianceByTag = () => {
		let params = {}
		// params = {
		// 	//tags:[{"key":this.state.selectedTag, "value": this.state.detectorKey}]
		// 	//tags:[{"key": "Project"}],
		// 	tags:[{"key":this.state.selectedTag}],
		// 	compliance: [{"complianceId":this.state.selectedComplicance}],
		// 	provider: this.state.selectedProvider.toLowerCase(),
		// 	duration: this.state.selectedDuration,
		// }
		params.tags = [{"key":this.state.selectedTag}]
		params.compliance = [{"complianceId":this.state.selectedComplicance}]
		params.provider = this.state.selectedProvider.toLowerCase()
		params.duration = this.state.selectedDuration

		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
			params.account_id = this.state.selectedAccount
		}
		this.props.getComplianceByTag(params, (promise, proEnvTable) => {
			if (promise) {
				for (const [key, value] of Object.entries(proEnvTable)) {
					if(this.state.selectedTag === key) {
						this.setState({proEnvTable: value});
					}
				}
			} else {
				this.setState({ proEnvTable: [] })
			}
		})
	}

	slideLeft() {
		this.proEnvWrap.current.scrollLeft -= 50
	}

	slideItemRight() {
		let totalCompliance = 0;;
		for (const [key] of Object.entries(this.state.dayWiseCountTags.data)) {
			let value = this.state.dayWiseCountTags.data[key]
			totalCompliance = Object.entries(value).length
		}
		let reqTabWidth = 0
		for (let i = 0; i < totalCompliance; i++) {
			reqTabWidth += this.proEnvWrap.current.childNodes[i].getBoundingClientRect().width
		}
		const averageSliderItemWidth = reqTabWidth/totalCompliance
		const sliderWrapWidth = this.proEnvWrap.current.getBoundingClientRect().width
		const totalItemShowing = sliderWrapWidth/averageSliderItemWidth;
		//const index = this.state.complianceCategoryScore.indexOf(this.state.selectedSeries)
		if (totalItemShowing < totalCompliance) {
			this.proEnvWrap.current.scrollLeft += 50
			
		} else {
		 	this.proEnvWrap.current.scrollLeft = 0
		}
	}

	//project/env/region end

	getTagwiseChangeDetails = () => {
		this.getDayWiseCountTags();
	}
	
	render() {		
		return (
			<div className="advanced-search">
				<div className="search-content">
					<div className="header-search bd-highlight">
						<div className="flex-fill bd-highlight">
						<h5>{capitalizeFirstLetter(this.state.selectedComplicance)}</h5>
						<div className="mr-2 d-flex">
								<div className="mr-3">
									<p className="m-0 small">Last Scan: 29 Sep 2020 11:10</p>
								</div>
								<div className="mr-3">
									<p className="m-0 pl-2 small">Account:  {this.state.selectedAccount ? this.state.selectedAccount: 'All'}</p>
								</div>
								<div>
									<p className="m-0 pl-2 small">Duration: 
										{DURATION_FILTER_OPTIONS.map((item, index) => {
											return (
												this.state.selectedDuration === item.value ?
													item.option
												: null
											)
										})}
										
									{/* {this.state.selectedDuration === '7d'? ' This Week' : this.state.selectedDuration === '+7d'? ' Last 1 weeks' : 
										this.state.selectedDuration === '+14d'? ' Last 2 weeks' : 
										this.state.selectedDuration === '+30d'? ' Last 1 months' : 
										this.state.selectedDuration === '+60d'? ' Last 2 months' : 
										this.state.selectedDuration === '+90d'? ' Last 3 months' : 
										this.state.selectedDuration } */}
									</p>
								</div>
							</div>
						</div>
						<div className="mr-3 align-self-center">
							<h5 className="font-weight-bold mb-0 text-center">{this.state.complianceScore ? this.state.complianceScore : ''}</h5>
							<p className="font-weight-bold mb-0">Score</p>
						</div>
						<div>
							<div className="box bg-muted justify-content-between d-flex p-2 shadow-none">
								<div className="mr-3">
									<h5 className="font-weight-bold mb-0 text-warning">{numberFormater(this.state.totalPassSum && this.state.totalPassSum)}</h5>
									<p className="font-weight-bold mb-0 text-white text-center">Pass</p>
								</div>
								<div className="mr-3">
									<h5 className="font-weight-bold mb-0 text-dark-theme-orange">{numberFormater(this.state.totalFailSum && this.state.totalFailSum)}</h5>
									<p className="font-weight-bold mb-0 text-white text-center">Fail</p>
								</div>
								<div>
									<h5 className="font-weight-bold mb-0 text-info">234</h5>
									<p className="font-weight-bold mb-0 text-white text-center">NA</p>
								</div>
							</div>
						</div>
						<div className="text-right flex-fill bd-highlight">
						<i className="far fa-times" onClick={() => this.props.closeViewSidePanel()}></i>
						</div>
					</div>
					<div className={`${!this.state.showLoading ? 'overflow-auto' : ''}`}>
						<ul className="nav nav-tabs border-bottom bd-highlight bg-muted shadow-none mb-0" id="myTab" role="tablist">
							<li className="nav-item">
								<span
									className={`nav-link text-white cursor-pointer ${this.state.activeTab === 0 ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
									style={{fontSize:'12px'}}
									onClick={() => this.setState(
										{ activeTab: 0, showLoading:true
										}, () => {
											if(this.state.complianceByAssets && this.state.complianceByAssets.length === 0) {
												this.getComplianceByAsset()
											} else {
												this.setState ( {showLoading:false} )
											}
										}
									)}
								>
								Resources
								</span>
							</li>
							<li className="nav-item">
								<span 
									className={`nav-link text-white cursor-pointer ${this.state.activeTab === 1 ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
									style={{fontSize:'12px'}}
									onClick={() => this.setState({ activeTab: 1, showLoading:true}, 
									() => {
											if(this.state.complianceRoleCounts && this.state.complianceRoleCounts.length === 0) {
												this.getComplianceByRole()
											} else {
												this.setState ( {showLoading:false} )
											}
										}
									)}
								>
									Role
								</span>
							</li>
							<li className="nav-item">
								<span
									className={`nav-link text-white cursor-pointer ${this.state.activeTab === 2 ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
									style={{fontSize:'12px'}}
									onClick={() => this.setState({ activeTab: 2, showLoading:true}, 
									() => {
										if(this.state.complianceByService && this.state.complianceByService.length === 0) {
											this.getComplianceByService()
										} else {
											this.setState ( {showLoading:false} )
										}
									})}
								>
								Services
								</span>
							</li>
							<li className="nav-item">
								<span
									className={`nav-link text-white cursor-pointer ${this.state.activeTab === 3 ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
									style={{fontSize:'12px'}}
									onClick={() => this.setState({ activeTab: 3 })}
								>
									Reports
								</span>
							</li>
							<li className="nav-item">
								<span
									className={`nav-link text-white cursor-pointer ${this.state.activeTab === 4 ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
									style={{fontSize:'12px'}}
									onClick={() => this.setState(
										{ activeTab: 4, showLoading:true },
										() => {
											if(this.state.dayWiseCountTags && this.state.dayWiseCountTags.length === 0) {
												this.getDayWiseCountTags()
											} else {
												this.setState ( {showLoading:false} )
											}
										}
									)}
								>
									Tags
								</span>
							</li>
							<li className="nav-item">
								<span
									className={`nav-link text-white cursor-pointer ${this.state.activeTab === 5 ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
									style={{fontSize:'12px'}}
									onClick={() => this.setState({ activeTab: 5, 
									showLoading:true }, 
									() => {
										if(this.state.complexityByRisk && this.state.complexityByRisk.length === 0) {
											this.getRiskWiseComplexity()
										} else {
											this.setState ( {showLoading:false} )
										}
									})}
								>
									Observations
								</span>
							</li>
							<li className="nav-item">
								<span
									className={`nav-link text-white cursor-pointer ${this.state.activeTab === 6 ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
									style={{fontSize:'12px'}}
									onClick={() => this.setState({ activeTab: 6, showLoading:true}, 
										() => {
											if(this.state.complianceByEvents && this.state.complianceByEvents.length === 0) {
												this.getComplianceByEvent()
											} else {
												this.setState ( {showLoading:false} )
											}
										}
									)}
								>
									Events
								</span>
							</li>
						</ul>
						{!this.state.showLoading ?
							this.state.activeTab === 0 ?
								<div className="">
									<div className="bg-muted p-3">
										<div className="counts_threads p-3 rounded">
											<p className="mb-0 f12 text-white">Results of Top 10 Resources that are contributing to most failed checks in your cloud infrastructure. Status by Fail and Risk count are shown
											</p>
											<div className='row mb-3'>
												<div className="col-sm-6 align-self-center">
													<p className='my-2 f16 text-white'>By Fail</p>
												</div>
												<div className="col-sm-6 align-self-center">
													<p className='my-2 f16 text-white'>By Risk</p>
												</div>
												<div className="col-sm-6 align-self-center borderMiddleblack5">
													{this.state.resourceDonutData ? 
														<ApexDonutChart
															graphData={this.state.resourceDonutData}
															legend={true}
															showTotal={true}
															showTotalLable={true}
															showGraphHeading={false}
															// height={400}
															width={'100%'}
															size={'60%'}
															gradient={false}
															gradientColor={['#A88CCC', '#D88ACF']}
															colors={['#A88CCC', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7']}
															className={"transparentTooltip"}
														/>
													: null}
												</div>
												<div className="col-sm-6 align-self-center">													
													{this.state.assetRiskProgressBar && this.state.assetRiskProgressBar.length > 0 && this.state.assetRiskProgressBar.map((risk,i) => {
														let color
														if(i===0) {
															color = '#4A397C'
														} else if(i===1) {
															color = '#6E6096'
														} else if(i===2) {
															color = '#08B089'
														} else if(i===3) {
															color = '#39BFA0'
														} else if(i===4) {
															color = '#60687C'
														} else if(i===5) {
															color = '#8E919B'
														} else if(i===6) {
															color = '#0092F3'
														} else if(i===7) {
															color = '#23D0E7'
														} else if(i===8) {
															color = '#8E6ECC'
														} else if(i===9) {
															color = '#57E7C5'
														}
														let lowRiskBar = ((risk.Low * 100) / risk.totalFail) +'%'
														let mediumRiskBar = ((risk.Medium * 100) / risk.totalFail) +'%'
														let highRiskBar = ((risk.High * 100) / risk.totalFail) +'%'
														let criticalRiskBar = ((risk.Critical * 100) / risk.totalFail) +'%'
														return (
															<div key={i} className='d-flex flex-column mb-1'>
																<div className='d-flex mb-1'>
																	<div className='legend-circle mr-2' style={{backgroundColor:color}}></div> <p className='mb-0 short-lineheight'>{risk.name}</p>
																</div>
																{risk.totalFail > 0 ?
																	<div className='stock-progress'>
																		<div className='risk-bg-critical' style={{width:criticalRiskBar}} id={`assetCritical-${i}`}></div>
																		<div className='risk-bg-high' style={{width:highRiskBar}} id={`assetHigh-${i}`}></div>
																		<div className='risk-bg-medium' style={{width:mediumRiskBar}} id={`assetMedium-${i}`}></div>
																		<div className='risk-bg-low' style={{width:lowRiskBar}} id={`assetLow-${i}`}></div>

																		<UncontrolledTooltip placement='top' target={`assetCritical-${i}`}>Critical {risk.Critical}</UncontrolledTooltip>		
																		<UncontrolledTooltip placement='top' target={`assetHigh-${i}`}>High {risk.High}</UncontrolledTooltip>
																		<UncontrolledTooltip placement='top' target={`assetMedium-${i}`}>Medium {risk.Medium}</UncontrolledTooltip>
																		<UncontrolledTooltip placement='top' target={`assetLow-${i}`}>low {risk.Low}</UncontrolledTooltip>
																	</div>
																: 
																	<div className='stock-progress'>
																		<div className='stock-5'></div>
																	</div>
																}
															</div>
														)
													})}
												</div>
											</div>
											<div className="row">
												<div className="col-md-6 d-flex">
													<div className="mr-3">
														<h5 className="font-weight-bold mb-0 text-purple">{numberFormater(this.state.processCount.asset_id)}</h5>
														<p className="font-weight-bold text-purple">Resources</p>
														{this.state.asset_bar_graph_data ?
															<div className="ComDetailSmallBarChart">
																<Chart type="bar" series={this.state.asset_bar_graph_data} width="100%" height={35} options={this.state.asset_bar_graph_options}/>
															</div>
														: null}
													</div>
													<div className="mr-3">
													<h5 className="font-weight-bold mb-0 text-info">{numberFormater(this.state.failDetailsCount && this.state.failDetailsCount.assets)}</h5>
													<p className="font-weight-bold text-info mb-1">Resources with fail checks</p>
														<p className="small">We observe {this.state.failDetailsCount && this.state.failDetailsCount.assets && this.state.failDetailsCount.assets} resources have contributed to fail checks in your cloud. Last 10 days trend of failed checks in resources are shown</p>
													</div>
												</div>
												<div className='col-md-6 align-self-center text-white'>
													<ul className="list-unstyle mb-0">
														{this.state.assetObservations && this.state.assetObservations.length > 0 && this.state.assetObservations.map(item =>
															<li className="f12 d-flex mb-1"> 
																<span className='far fa-chevron-right mr-2' style={{lineHeight:2}}></span>
																<span className="align-self-center">{item.observation}</span>
															</li>
														)}
													</ul>
												</div>
											</div>
										</div>
									</div>	
									<div className="col-sm-12 my-3">
										<div class="rounded bg-white4 p-3">
											{this.state.assetsGraphCounts && this.state.assetsGraphCounts.length > 0 ?
												<div className='row my-1 d-flex'>
													<div className='col-sm-3'>
														<div className='d-flex'>
															<select 
																className="form-control f12"
																value={this.state.selectedAssetType}
																onChange={event => {
																	this.setState({
																		selectedAssetType: event.target.value,
																		showLoading: false,
																	},
																		() => this.getComplianceByAsset()
																	)
																}}
															>
																{this.state.assetsGraphCounts.slice(0,10).map(topAsset => {
																return (
																	<option key={topAsset.service_group} value={topAsset.service_group}>
																		{topAsset.service_group}
																	</option>
																)
																})}
															</select>
														</div>
													</div>
													<div className='col-sm-9 m-0 p-0 align-self-center'>
														{this.state.complianceByAssets && this.state.complianceByAssets.length > 10 ?
															<p className="mb-1 small">Showing Top 10 results out of {this.state.complianceByAssets.length} Assets</p>
														: null}
													</div>
												</div>
											: null}
											<div className="table-responsive ComDetailResourceTable">
												<table className="table table-sm table-hover f10 mb-0">
													<thead>
														<tr className="bg-secondary-50">
															<th>Assets</th>
															<th>Service</th>
															<th>Count</th>
															<th>Controls</th>
															<th>Account/Region/Service</th>
														</tr>
													</thead>
													<tbody className="bg-white3">
														{this.state.complianceByAssets && this.state.complianceByAssets.slice(0, 10).map((res,inx) => {
															return [
															<tr key={inx} className="border-bottom-gray2">
																<td>{res.asset_id}</td>
																<td>{res.service_group ? res.service_group.join(",") : null}</td>
																<td>{res.fail}</td>
																<td>
																	{res.controlRedefined && res.controlRedefined.map(controls => {
																		return (
																		<div className="d-flex w-100 font-weight-bold">
																			<span className={`pb-2 ${controls.two ? ' w-50' : ''} pr-2`}>{controls.one}</span>
																			<span className={`${controls.two ? 'pl-2 w-50' :'displayNone'}`}>{controls.two}</span>
																		</div>
																		)
																	})}
																</td>
																<td>{getAccountNameFromId(res.account_id, this.props.accounts)+'/'+res.region+'/'+res.service_name}</td>
															</tr>];
														})}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							: this.state.activeTab === 1 ?
								<div className="">
									<div className="bg-muted p-3">
										<div className="counts_threads p-3 rounded">
											<p className="mb-0">Results of Top 10 Roles that are contributing to most failed checks in your cloud infrastructure. Status by Fail and Risk count are shown</p>
											<div className='row mb-3'>
												<div className="col-sm-7 align-self-center">
													<p className='my-2 f16 text-white'>By Fail</p>
												</div>
												<div className="col-sm-5 align-self-center">
													<p className='my-2 f16 text-white'>By Risk</p>
												</div>
												<div className="col-sm-7 align-self-center borderMiddleblack5">
													{this.state.roleDonutData ?
														<ApexDonutChart
															graphData={this.state.roleDonutData}
															legend={true}
															showTotal={true}
															showTotalLable={true}
															showGraphHeading={false}
															// height={400}
															width={'100%'}
															size={'60%'}
															gradient={false}
															gradientColor={['#A88CCC', '#D88ACF']}
															colors={['#A88CCC', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7']}
															className={"transparentTooltip"}
														/>
													: null}
												</div>
												<div className="col-sm-5 align-self-center">
													{this.state.roleWiseRisk && this.state.roleWiseRisk.length > 0 && this.state.roleWiseRisk.slice(0,10).map((risk,i) => {
														let color
														if(i===0) {
															color = '#4A397C'
														} else if(i===1) {
															color = '#6E6096'
														} else if(i===2) {
															color = '#08B089'
														} else if(i===3) {
															color = '#39BFA0'
														} else if(i===4) {
															color = '#60687C'
														} else if(i===5) {
															color = '#8E919B'
														} else if(i===6) {
															color = '#0092F3'
														} else if(i===7) {
															color = '#23D0E7'
														} else if(i===8) {
															color = '#8E6ECC'
														} else if(i===9) {
															color = '#57E7C5'
														}

														let lowRiskBar = ((risk.Low * 100) / risk.totalFail) +'%'
														let mediumRiskBar = ((risk.Medium * 100) / risk.totalFail) +'%'
														let highRiskBar = ((risk.High * 100) / risk.totalFail) +'%'
														let criticalRiskBar = ((risk.Critical * 100) / risk.totalFail) +'%'
														return (
															<div className='d-flex flex-column mb-1'>
																<div className='d-flex mb-1'>
																	<div className='legend-circle mr-2' style={{backgroundColor:color}}></div> <p className='mb-0 short-lineheight'>{risk.name}</p>
																</div>
																{risk.totalFail > 0 ?
																	<div className='stock-progress'>
																		<div className='risk-bg-critical' style={{width:criticalRiskBar}} id={`roleCritical-${i}`}></div>
																		<div className='risk-bg-high' style={{width:highRiskBar}} id={`roleHigh-${i}`}></div>
																		<div className='risk-bg-medium' style={{width:mediumRiskBar}} id={`roleMedium-${i}`}></div>
																		<div className='risk-bg-low' style={{width:lowRiskBar}} id={`roleLow-${i}`}></div>	
																		<UncontrolledTooltip placement='top' target={`roleCritical-${i}`}>Critical {risk.Critical}</UncontrolledTooltip>	
																		<UncontrolledTooltip placement='top' target={`roleHigh-${i}`}>High {risk.High}</UncontrolledTooltip>
																		<UncontrolledTooltip placement='top' target={`roleMedium-${i}`}>Medium {risk.Medium}</UncontrolledTooltip>
																		<UncontrolledTooltip placement='top' target={`roleLow-${i}`}>low {risk.Low}</UncontrolledTooltip>
																	</div>
																: 
																	<div className='stock-progress'>
																		<div className='stock-5'></div>
																	</div>
																}
															</div>
														)
													})}
												</div>
											</div>
											<div className="row">
												<div className="col-md-6 d-flex">
													<div className="mr-3">
														<h5 className="font-weight-bold mb-0 text-purple">{this.state.failSum}</h5>
														<p className="font-weight-bold text-purple">Events</p>
														{this.state.day_wise_fail_graph_data && this.state.day_wise_fail_graph_options ?
															<div className="ComDetailSmallBarChart">
																<Chart type="bar" series={this.state.day_wise_fail_graph_data} width="100%" height={35} options={this.state.day_wise_fail_graph_options}/>
															</div>
														: null}
													</div>
													<div className="mr-3">
													<h5 className="font-weight-bold mb-0 text-info">234</h5>
													<p className="font-weight-bold text-info mb-1">Events</p>
													<p className="small">Sed in semper ex. Nulla sed finibus orci, eget semper odio. Aenean viverra Sed in semper ex. Nulla sed finibus orci, eget semper odio. Aenean viverra</p>
													</div>
												</div>
												<div className='col-md-6 align-self-center text-white'>
													<ul className="list-unstyle mb-0">
														{this.state.roleObservations && this.state.roleObservations.length > 0 && this.state.roleObservations.map(item =>
															<li className="f12 d-flex mb-1"> 
																<span className='far fa-chevron-right mr-2' style={{lineHeight:2}}></span>
																<span className="align-self-center">{item.observation}</span>
															</li>
														)}
													</ul>
												</div>
											</div>
										</div>
									</div>
									<div className="col-sm-12 my-3">
										<div class="rounded bg-white4 p-3">
											{this.state.complianceRoleCounts && this.state.complianceRoleCounts.length > 10 ?
												<p className="mb-1 small">Showing Top 10 results out of {this.state.complianceRoleCounts.length} Roles
												</p>
											: null}
											<div className="table-responsive ComDetailRoleTable">
												<table className="table table-sm table-hover f10 mb-0">
													<thead>
														<tr className="bg-secondary-50">
															<th>Role Name</th>
															<th>Issue</th>
															<th>Controls</th>
															<th>Account</th>
														</tr>
													</thead>
													<tbody className="bg-white3">
														{this.state.complianceRoleCounts && this.state.complianceRoleCounts.slice(0, 10).map((res,inx) => {
															return [
																<tr key={inx} className="border-bottom-gray2">
																	<td>
																		{res.role_name}
																	</td>
																	<td>{res.fail}</td>
																	{/* <td>{res.controls?res.controls.join(","):null}</td> */}
																	<td>
																		{res.controlRedefined && res.controlRedefined.map(controls => {
																			return (
																			<div className="d-flex w-100 font-weight-bold">
																				<span className={`pb-2 ${controls.two ? 'w-50' : ''} pr-2`}>{controls.one}</span>
																				<span className={`${controls.two ? 'pl-2 w-50' :'displayNone'}`}>{controls.two}</span>
																			</div>
																			)
																		})}
																	</td>
																	<td>{res.account_id}</td>
																</tr>
															];
														})}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							: this.state.activeTab === 2 ?
								<div className="">
									<div className="bg-muted p-3">
										<div className="counts_threads p-3 rounded">
											<p className="mb-0">Results of Top 10 Services that are contributing to most failed checks in your cloud infrastructure. Status by Fail and Risk count are shown</p>
											<div className='row mb-3'>
												<div className="col-sm-6 align-self-center">
													<p className='my-2 f16 text-white'>By Fail</p>
												</div>
												<div className="col-sm-6 align-self-center">
													<p className='my-2 f16 text-white'>By Risk</p>
												</div>
												<div className="col-sm-6 align-self-center borderMiddleblack5">
													<div className="col-sm-11">
														{this.state.serviceDonutData ?
															<ApexDonutChart
																graphData={this.state.serviceDonutData}
																legend={true}
																showTotal={true}
																showTotalLable={true}
																showGraphHeading={false}
																// height={400}
																width={'100%'}
																size={'60%'}
																gradient={false}
																gradientColor={['#A88CCC', '#D88ACF']}
																colors={['#A88CCC', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7']}
																className={"transparentTooltip"}
															/>
														: null}
													</div>
												</div>
												<div className="col-sm-6 align-self-center">
													{this.state.servicesRiskProgressBar && this.state.servicesRiskProgressBar.length > 0 && this.state.servicesRiskProgressBar.slice(0,10).map((risk,i) => {
														let color
														if(i===0) {
															color = '#4A397C'
														} else if(i===1) {
															color = '#6E6096'
														} else if(i===2) {
															color = '#08B089'
														} else if(i===3) {
															color = '#39BFA0'
														} else if(i===4) {
															color = '#60687C'
														} else if(i===5) {
															color = '#8E919B'
														} else if(i===6) {
															color = '#0092F3'
														} else if(i===7) {
															color = '#23D0E7'
														} else if(i===8) {
															color = '#8E6ECC'
														} else if(i===9) {
															color = '#57E7C5'
														}

														let lowRiskBar = ((risk.Low * 100) / risk.totalFail) +'%'
														let mediumRiskBar = ((risk.Medium * 100) / risk.totalFail) +'%'
														let highRiskBar = ((risk.High * 100) / risk.totalFail) +'%'
														let criticalRiskBar = ((risk.Critical * 100) / risk.totalFail) +'%'
														return (
															<div className='d-flex flex-column mb-1'>
																<div className='d-flex mb-1'>
																	<div className='legend-circle mr-2' style={{backgroundColor:color}}></div> <p className='mb-0 short-lineheight'>{risk.name}</p>
																</div>
																{risk.totalFail > 0 ?
																	<div className='stock-progress'>
																		<div className='risk-bg-critical' style={{width:criticalRiskBar}} id={`serviceCritical-${i}`}></div>
																		<div className='risk-bg-high' style={{width:highRiskBar}} id={`serviceHigh-${i}`}></div>
																		<div className='risk-bg-medium' style={{width:mediumRiskBar}} id={`serviceMedium-${i}`}></div>
																		<div className='risk-bg-low' style={{width:lowRiskBar}} id={`serviceLow-${i}`}></div>
																		<UncontrolledTooltip placement='top' target={`serviceCritical-${i}`}>Critical {risk.Critical}</UncontrolledTooltip>
																		<UncontrolledTooltip placement='top' target={`serviceHigh-${i}`}>High {risk.High}</UncontrolledTooltip>
																		<UncontrolledTooltip placement='top' target={`serviceMedium-${i}`}>Medium {risk.Medium}</UncontrolledTooltip>
																		<UncontrolledTooltip placement='top' target={`serviceLow-${i}`}>low {risk.Low}</UncontrolledTooltip>
																	</div>
																: 
																	<div className='stock-progress'>
																		<div className='stock-5'></div>
																	</div>
																}
															</div>
														)
													})}
												</div>
											</div>
											<div className="row">
												<div className="col-md-6 d-flex">
													<div className="mr-3">
														<h5 className="font-weight-bold mb-0 text-purple">{numberFormater(this.state.processCount && this.state.processCount.resource_type && this.state.processCount.resource_type)}</h5>
														<p className="font-weight-bold text-purple">Services</p>
														{this.state.day_wise_fail_graph_data && this.state.day_wise_fail_graph_options ?
															<div className="ComDetailSmallBarChart">
																<Chart type="bar" series={this.state.day_wise_fail_graph_data} width="100%" height={35} options={this.state.day_wise_fail_graph_options}/>
															</div>
														: null}
													</div> 	
													<div className="mr-3">
														<h5 className="font-weight-bold mb-0 text-info">{numberFormater(this.state.failDetailsCount && this.state.failDetailsCount.services && this.state.failDetailsCount.services)}</h5>
															<p className="font-weight-bold text-info mb-1">Services with fail checks</p>
															<p className="small">We observe {this.state.failDetailsCount && this.state.failDetailsCount.services && this.state.failDetailsCount.services} services have contributed to fail checks in your cloud. Last 10 days trend of failed checks in services are shown</p>
													</div>
												</div>
												<div className='col-md-6 align-self-center text-white'>
													<ul className="list-unstyle mb-0">
														{this.state.serviceObservations && this.state.serviceObservations && this.state.serviceObservations.map(item =>
															<li className="f12 d-flex mb-1"> 
																<span className='far fa-chevron-right mr-2' style={{lineHeight:2}}></span>
																<span className="align-self-center">{item.observation}</span>
															</li>
														)}
													</ul>
												</div>
											</div>
										</div>
									</div>
									<div className="col-sm-12 my-3">
										<div class="rounded bg-white4 p-3">
											{this.state.resourceTypeCounts && this.state.resourceTypeCounts ?
												<div className='row my-1 d-flex'>
													<div className='col-sm-3'>
														<div className='d-flex'>
															<select
																className='form-control f12'
																value={this.state.selectedServiceType}
																onChange={event => {
																	this.setState( {
																		selectedServiceType: event.target.value,
																		showLoading: false,
																	},
																		() => this.filterComplianceBySearch('serviceType')
																	)
																}}
															>
																<option value='all'>All</option>
																{this.state.resourceTypeCounts.slice(0,10).map(topServices => {
																return (
																	<option key={topServices.resource_type} value={topServices.resource_type}>
																		{topServices.resource_type}
																	</option>
																)
																})}
															</select>
														</div>
													</div>
													<div className='col-sm-9 m-0 p-0 align-self-center'>
														{this.state.servicesFilterData && this.state.servicesFilterData.length > 10 ?
															<p className="mb-1 small">Showing Top 10 results out of {this.state.servicesFilterData.length} Services
															</p>
														: null}
													</div>
												</div>
											: null}
											<div className="table-responsive ComDetailServicesTable">
												<table className="table table-sm table-hover f10 mb-0">
													<thead>
														<tr className="bg-secondary-50">
															<th>Service</th>
															<th>Resource</th>
															<th>Issue</th>
															<th>Controls</th>
															<th>Account/Region</th>
														</tr>
													</thead>
													<tbody className="bg-white3">
														{this.state.servicesFilterData && this.state.servicesFilterData.slice(0, 10).map((res,inx) => {
															return [
															<tr key={inx} className="border-bottom-gray2">
																<td>{res.service_name}</td>
																<td>{res.resource_type}</td>
																<td>{res.fail}</td>
																<td>
																	{res.controlRedefined && res.controlRedefined.map(controls => {
																		return (
																		<div className="d-flex w-100 font-weight-bold">
																			<span className={`pb-2 ${controls.two ? ' w-50' : ''} pr-2`}>{controls.one}</span>
																			<span className={`${controls.two ? 'pl-2 w-50' :'displayNone'}`}>{controls.two}</span>
																		</div>
																		)
																	})}
																</td>
																<td>{res.account_id+'/'+res.region}</td>
															</tr>
															];
														})}
													</tbody>
												</table>
											</div>
										</div>										
									</div>
								</div>
							: this.state.activeTab === 4 ?
								<div className="">
									<div className="bg-muted p-3">
										<div className="counts_threads p-3 rounded">
											<p className="mb-0">Earum aut eos. Sed praesentium et hic voluptate itaque ut. Aut quia est aspernatur. Expedita unde vel accusantium deleniti ipsum fuga enim Service.</p>
											<div className='row'>
												<div className='col-sm-6 my-2'>
													<div className='d-flex'>
														<Select
															placeholder={'All'}
															isSearchable={false}
															className={`f13 p-0 form-control dark-select-theme-with-dropdown-indicator`}
															value={({
																value: this.state.selectedTag,
																label: this.state.selectedTag
															})}
															options={environemntDropdown.map(item => ({
																value: item.value,
																label: item.label,	
															}))}
															onChange={event => this.setState({
																	selectedTag: event.value,
																	showLoading: true,
																},
																	() => this.getDayWiseCountTags('appChange')
																)
															}
														/>
													</div>
												</div>
											</div>
											<div className='row'>
												<span className="far fa-chevron-left ml-3 text-muted mt-3 cursorPointer" onClick={() => this.slideLeft()}></span>
												<div className="col-sm-5 pill-tabs mt-2">
													<ul className="nav nav-tabs mb0 d-flex flex-nowrap overflow-hidden" id="myTab" ref={this.proEnvWrap} style={{paddingBottom:1}} role="tablist">
														{this.state.dayWiseCountTags && this.state.dayWiseCountTags.data && Object.entries(this.state.dayWiseCountTags.data).map(([key, value]) => {
															return (
																Object.entries(value).map(([detKey, detValue]) => {							
																	return(
																		<li key={detKey} className="nav-item mb5 text-nowrap">
																			<span className={"nav-link text-white cursorPointer f12 "+(this.state.detectorKey === detKey ? 'active':'')} onClick={(e)=> {this.setState({detectorKey:detKey},()=> {this.getTagwiseChangeDetails()})}}>{detKey}</span>
																		</li>
																	)
																})
															)
														})}
													</ul>
												</div>	
												<span className='far fa-chevron-right text-dark cursorPointer mt-3'  onClick={() => this.slideItemRight()}></span>
											</div>
											<div className='row mt-2'>							
												<div className='col-sm-2'>
													{this.state.score && this.state.score.length > 0 ?
														this.state.score.slice(0,5).map((res,i)=> {
															return (
																res.tags[0].value === this.state.detectorKey ?
																	<div className='text-center m0 p5 mr10 col-sm-10'>
																		<CircularProgressbar 
																			key={res.tags[0].value} 
																			value={(res.overall.split("/"))[0]} 
																			text={(res.overall.split("/"))[0]} 
																			counterClockwise={true} 
																			styles={{trail: {stroke: '#353C4C',strokeWidth:'8'},path: {stroke: '#039BE5',strokeWidth:'6'},text: {fill: '#039BE5',fontSize: '10px'}}}
																		/>
																		<p className="f10 p5 m0">{res.tags[0].value}</p>
																	</div>
																: null
															)
														})
													: 
														<div className='text-center m0 p5 mr10 col-sm-10'>
															<CircularProgressbar 
																key="0" 
																value="0" 
																text="0" 
																counterClockwise={true} 
																styles={{trail: {strokeWidth:'5'}, path: {stroke: '#24A597',strokeWidth:'6'}, text: {fill: '#24A597',fontSize: '10px'}}}
															/>	
														</div>
													}
												</div>
												<div className="col-sm-4 p-0">
													<div className='d-flex align-self-start'>
														<div className="d-flex flex-column text-center align-self-end">
															<h6>{numberFormater(this.state.processCount && this.state.processCount.event_id ? this.state.processCount.event_id : '')}</h6>
															<small className="f8">Lorem ipsum dolor sit amet, consectetu.</small>
															<div className="progress mt-2">
																<div className="progress-bar bg-dark-theme-blue" role="progressbar" style={{width: "50%"}}
																aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
															</div>
														</div>
														<div className="d-flex flex-column text-center align-self-end mx-3">
															<h5 className="text-dark-theme-green">{numberFormater(this.state.processCount && this.state.processCount.asset_id ? this.state.processCount.asset_id : '')}</h5>
															<small className="f8">Lorem1 ipsum dolor sit amet, consectetu.</small>
															<div className="progress mt-2">
																<div className="progress-bar bg-dark-theme-green" role="progressbar" style={{width: "50%"}}
																aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
															</div>
														</div>
														<div className="d-flex flex-column text-center align-self-end mx-3">
															<h6>234</h6>
															<small className="f8">Lorem ipsum dolor sit amet, consectetu.</small>
															<div className="progress mt-2">
																<div className="progress-bar bg-dark-theme-blue" role="progressbar" style={{width: "50%"}}
																aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
															</div>
														</div>
													</div>
												</div>
												<div className='col-md-6 align-self-center text-white pr-0'>
													<ul className="list-unstyle mb-0">
														{this.state.proEnvObservations && this.state.proEnvObservations.length > 0 && this.state.proEnvObservations.map(item =>
															<li className="f12 d-flex mb-1"> 
																<span className='far fa-chevron-right mr-2' style={{lineHeight:2}}></span>
																<span className="align-self-center">{item.observation}</span>
															</li>
														)}
													</ul>
												</div>
											</div>
											{this.state.bar_data && this.state.bar_data.length && this.state.bar_option ?
												<div className='row'>
													<div className="col-sm-12">
														<div className='d-flex justify-content-between my-3 f12'>
															<div className={`text-info cursorPointer ${this.state.hidePreviousWeekBtn ? 'disabled' : ''}`} onClick={()=> {this.setState({showPrevNextRecord:(this.state.showPrevNextRecord - 1)},() => {this.showSummaryGraph()})}}><span className='far fa-angle-double-left'></span> Previous </div>
															<div className={`text-info cursorPointer ${this.state.hideNextWeekBtn ? 'disabled' : ''}`} onClick={()=> {this.setState({showPrevNextRecord:(this.state.showPrevNextRecord + 1)},() => {this.showSummaryGraph()})}}> Next <span className='far fa-angle-double-right'></span></div>
														</div>
													</div>

													<div className="col-sm-12">
														<Chart type="bar" series={this.state.bar_data} width="100%" height={300} options={this.state.bar_option}/>
													</div>
												</div>
											: null}
										</div>
									</div>
									<div className="col-sm-12 my-3">
										<div class="rounded bg-white4 p-3">
											<p className="mb-1 small">consectetur adipiscing elit. Morbi tincidunt nibh id tortor iaculis mollis. Donec eu rhoncus risus. Praesent conse</p>
											<div className="table-responsive ComDetailTagTable">
												<table className="table table-sm table-hover mb-0">
													<thead>
														<tr className="bg-secondary-50">
															<th>Name</th>
															<th>Issue</th>
															<th>Controls</th>																
														</tr>
													</thead>
													<tbody className="f10 bg-white3">
														{this.state.proEnvTable && this.state.proEnvTable.slice(0, 10).map((res,inx) => {
															return [
															<tr key={inx} className="border-bottom-gray2">
																<td>{res.value}</td>
																<td>{res.fail}</td>
																<td>{res.controls ? res.controls.join(", ") : null}</td>
															</tr>	
															];
														})}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							: this.state.activeTab === 5 ?
								<div className="">
									<div className="bg-muted p-3">
										<div className="counts_threads p-3 rounded">
											<p className="mb-0 text-white">Distribution of Failed Checks Risk items vs Complexity to remediate them. This graph will help users to plan their remediation easily</p>
											<div className='my-3'>
												{this.state.bubble_complexity_data && this.state.bubble_complexity_data.length > 0 ?
													<div className="transparentTooltip">
														<Chart options={this.state.bubble_complexity_options} series={this.state.bubble_complexity_data} type="bubble" height={350} width="100%"/>
													</div>
												: null}
											</div>
										</div>
									</div>
									<div className="col-sm-12 my-3">
										<div class="rounded bg-white4 p-3">
											<p className="mb-1 small">Our observations on the Distribution of Failed Checks Risk items vs Remediation Complexity</p>
											<div className="table-responsive">
												<table className="table table-sm table-hover f10 mb-0">
													<thead>
														<tr className="bg-secondary-50">
															<th>Date</th>
															<th>Risk</th>
															<th>Complexity</th>
															<th>Observations</th>
														</tr>
													</thead>
													<tbody className="bg-white3">
														{this.state.complexityfilterData && this.state.complexityfilterData.length > 0 && this.state.complexityfilterData.slice(0,10).map((bubble,inx) => {
															return(
																<tr key={inx} className="border-bottom-gray2">
																	<td>{bubble.timestamp}</td>
																	<td>{bubble.risk}</td>
																	<td>{bubble.complexity}</td>
																	<td>{bubble.observation}</td>
																</tr>
															)
														})}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							: this.state.activeTab === 6 ?
								<div className="">
									<div className="bg-muted p-3">
										<div className="counts_threads p-3 rounded">
											<p className="mb-0">Results of Top 10 Events that are contributing to most failed checks in your cloud infrastructure. Status by Events are shown below</p>
											<div className='row my-3'>
												<div className='col-md-6'>
													<div className='row align-self-start ml-3'>
														<h6 className="w-30 text-center mb-0">{numberFormater(this.state.processCount && this.state.processCount.event_id ? this.state.processCount.event_id : '')}</h6>
														<h5 className="w-30 text-center text-dark-theme-green mb-0 mx-3">{numberFormater(this.state.failDetailsCount && this.state.failDetailsCount.events ? this.state.failDetailsCount.events : '')}</h5>
														<h6 className="w-30 text-center mb-0">{numberFormater(this.state.failDetailsCount && this.state.failDetailsCount.event_types ? this.state.failDetailsCount.event_types : '')}</h6>
													</div>
													<div className='row align-self-start ml-3'>
														<div className="w-30 text-center">
															<small className="f8">Total No. of events processed</small>
															<div className="progress mt-1">
																<div className="progress-bar bg-dark-theme-blue" role="progressbar" style={{width: "50%"}}
																aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
															</div>
														</div>
														<div className="w-30 text-center mx-3">
															<small className="f8">Events have failed checks</small>
															<div className="progress mt-1">
																<div className="progress-bar bg-dark-theme-green" role="progressbar" style={{width: "50%"}}
																aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
															</div>
														</div>
														<div className="w-30 text-center">
															<small className="f8">Event Types</small>
															<div className="progress mt-1">
																<div className="progress-bar bg-dark-theme-blue" role="progressbar" style={{width: "50%"}}
																aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
															</div>
														</div>
													</div>
												</div>
												<div className='col-md-6 align-self-center text-white'>
													<ul className="list-unstyle mb-0">
														{this.state.eventObservations && this.state.eventObservations.length > 0 && this.state.eventObservations.map(item =>
															<li className="f12 d-flex mb-1"> 
																<span className='far fa-chevron-right mr-2' style={{lineHeight:2}}></span>
																<span className="align-self-center">{item.observation}</span>
															</li>
														)}
													</ul>
												</div>
											</div>
											<p className="f16 text-white">By Event</p>
											<div className='row lineHeight5'>
												{this.state.events_graph_data && this.state.events_graph_data.length > 0 && this.state.events_graph_data.map((risk,i) => {
													let lowRiskBar = ((risk.Low * 100) / risk.totalFail) +'%'
													let mediumRiskBar = ((risk.Medium * 100) / risk.totalFail) +'%'
													let highRiskBar = ((risk.High * 100) / risk.totalFail) +'%'
													let criticalRiskBar = ((risk.Critical * 100) / risk.totalFail) +'%'
													return (
														<React.Fragment key={i}>
															<div className='col-sm-3 text-right'>
																<p class="f10">{risk.name}</p>
															</div>
															<div class="col-sm-6 d-flex float-left ml15">
																{risk.totalFail > 0 ?
																	<div className='stock-progress'>
																		<div className='risk-bg-critical' style={{width:criticalRiskBar}} id={`eventCritical-${i}`}></div>
																		<div className='risk-bg-high' style={{width:highRiskBar}} id={`eventHigh-${i}`}></div>
																		<div className='risk-bg-medium' style={{width:mediumRiskBar}} id={`eventMedium-${i}`}></div>
																		<div className='risk-bg-low' style={{width:lowRiskBar}} id={`eventLow-${i}`}></div>

																		<UncontrolledTooltip placement='top' target={`eventCritical-${i}`}>Critical {risk.Critical}</UncontrolledTooltip>		
																		<UncontrolledTooltip placement='top' target={`eventHigh-${i}`}>High {risk.High}</UncontrolledTooltip>
																		<UncontrolledTooltip placement='top' target={`eventMedium-${i}`}>Medium {risk.Medium}</UncontrolledTooltip>
																		<UncontrolledTooltip placement='top' target={`eventLow-${i}`}>low {risk.Low}</UncontrolledTooltip>
																	</div>
																	: 
																	<div className='stock-progress'>
																		<div className='stock-5'></div>
																	</div>											
																}
															</div>
															<div className="col-sm-3 text-left"><small>{risk.totalFail}</small></div>
														</React.Fragment>
													)
												})}
											</div>
										</div>
									</div>
									<div className="col-sm-12 my-3">
										<div class="rounded bg-white4 p-3">
											<p className="mb-0">Results of Top Events that have most failed compliance checks are listed below</p>
											<div className="table-responsive ComDetailEventTable">
												<table className="table table-sm table-hover f10 mb-0">
													<thead>
														<tr className="bg-secondary-50">
															<th>Event Name</th>
															<th>Issue</th>
															<th>Controls</th>
														</tr>
													</thead>
													<tbody className="bg-white3">
														{this.state.complianceByEvents && this.state.complianceByEvents.slice(0, 10).map((res,inx) => {
															return [
															<tr key={inx} className="border-bottom-gray2">
																<td>{res.event_name}</td>
																<td>{res.risk_details[0].fail}</td>
																<td>
																	{res.controlRedefined && res.controlRedefined.map(controls => {
																		return (
																		<div className="d-flex w-100 font-weight-bold">
																			<span className={`pb-2 pr-2`}>
																				{controls.one}
																				{controls.two ? (' : ')+controls.two : ''}
																			</span>
																		</div>
																		)
																	})}
																</td>
															</tr>	
															];
														})}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							: null
						:
							<div className='text-center mt-5' ><Spinner color='dark' size='lg' /></div>
						}

					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ViewSidePanel.propTypes = {
	getComplianceByAsset: PropTypes.func,
	getComplianceByRole:PropTypes.func,
	getComplianceByEvent:PropTypes.func,
	getCount:PropTypes.func,
	getComplianceByScoreCount:PropTypes.func,
	getTotalCount:PropTypes.func,
	getRiskWiseComplexity:PropTypes.func,
	getDayWiseCountTags:PropTypes.func,
	getObservations:PropTypes.func,
	getRiskByRole:PropTypes.func,
	getScore:PropTypes.func,
	getComplianceByTag:PropTypes.func,
	getDayWiseCount:PropTypes.func,
	getDayWiseAssetCount:PropTypes.func
}

const mapStateToProps = state => {
	return {		
	}
}

export default connect(mapStateToProps, {
	getComplianceByAsset,
	getComplianceByRole,
	getComplianceByEvent,
	getCount,
	getComplianceByScoreCount,
	getTotalCount,
	getRiskWiseComplexity,
	getDayWiseCountTags,
	getObservations,
	getRiskByRole,
	getScore,
	getComplianceByTag,
	getDayWiseCount,
	getDayWiseAssetCount
})(withRouter(ViewSidePanel))



