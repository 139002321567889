/*************************************************
 * Tvastar
 * @exports
 * @file Dashboard.js
 * @author Rajasekar // on 14/03/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { AppWrapper } from '../common/AppWrapper'
import PropTypes from 'prop-types'
import EditDescription from './EditDescription'
import EditNotification from './EditNotification'
import EditConfirmation from './EditConfirmation'
import EditCondition from './EditCondition'
import EditAction from './EditAction'
import EditResource from './EditResource'
import { setInitialRuleDetails, saveRule, updateClonedRule, saveUserRule, updateUserRule } from '../../actions/governance/governanceAction'
import { URL_PATH } from '../../config/urlPath'

import { store as CommonNotification } from 'react-notifications-component';

class EditRule extends Component {
	constructor(props) {
		
		super(props)
		this.state = {
			activeTab: 1,
			hasError: false,
			finishBtn: 'Finish'
		}
	}

	componentDidMount = () => {
		let ruleDetails = this.props.location.state && this.props.location.state.ruleDetails ? this.props.location.state.ruleDetails : {}
		if (!Object.entries(ruleDetails).length) {			
			if(this.props.location.pathname === '/governance/editrule') {
				this.props.history.push({
					pathname: URL_PATH.GOVERNANCE_RULES
				})
			} else {
				let temp = {}
				temp['rule_name'] = ''
				temp['description'] = ''
				temp['resources'] = []
				temp['actions'] = []
				temp['notifications'] = {
					email: [],
					slack: [],
				}			
				this.props.setInitialRuleDetails(temp)
			}

		} else {
			if(!this.props.editRuleDetails.base_rule_id || this.props.editRuleDetails.base_rule_id === '') {
				this.props.setInitialRuleDetails(ruleDetails)
			}
		}
	}
	
	/**
	 * Handles clone/edit rule finish
	 */
	handleOnFinish = () => {
		this.setState({ disableFinish: true })
		if(!this.props.editRuleDetails.rule_id || this.props.editRuleDetails.rule_id === '') {
			this.setState({ finishBtn: 'Saving...' })
			this.props.saveUserRule(this.props.editRuleDetails, (promise, saveResult) => {
				if (promise) {
					this.setState({ finishBtn: 'Saved'  })
					
					let messageType = 'success'		
					let message = 'User governance rule saved successfully'
					CommonNotification.addNotification({
						//title: "Wonderful!",
						message: message,
						type: messageType,
						insert: "top",
						container: "top-center",
						// animationIn: ["animate__animated", "animate__fadeIn"],
						// animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
						  duration: 5000,
						  onScreen: false,
						  pauseOnHover: true,
						  showIcon: true,			  
						}
					});
					this.setState({ showLoading: false },
						() => this.setTimeoutRedirect()
					)
				} else {
					this.setState({ disableFinish : false, finishBtn: "Finish" })
				}
			})
		} else {
			this.setState({ finishBtn: 'Updating...' })
			this.props.updateUserRule(this.props.editRuleDetails, (promise, updateResult) => {
				if (promise) {
					this.setState({ finishBtn: 'Updated' })
					let messageType = 'success'		
					let message = 'User governance rule updated successfully'
					CommonNotification.addNotification({
						//title: "Wonderful!",
						message: message,
						type: messageType,
						insert: "top",
						container: "top-center",
						// animationIn: ["animate__animated", "animate__fadeIn"],
						// animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
						  duration: 5000,
						  onScreen: false,
						  pauseOnHover: true,
						  showIcon: true,			  
						}
					});
					this.setState({ showLoading: false },
						() => this.setTimeoutRedirect()
					)
				} else {
					this.setState({ disableFinish : false, finishBtn: 'Finish' })
				}
			})
		}
	}
	
	setTimeoutRedirect = (ruleId) => {
		setTimeout(() => {
			this.props.history.push({
				pathname: URL_PATH.GOVERNANCE_RULES,
				state: { showRules: true },
			})
		}, 5000)
	}
	

	validatePage = (activeTab) => {
		this.setState({ hasError: false })
		// for description page validation handling
		// if(!this.props.editRuleDetails.provider || this.props.editRuleDetails.provider === 'Select' || !this.props.editRuleDetails.category || this.props.editRuleDetails.category === '' || this.props.editRuleDetails.category === 'Select' || !this.props.editRuleDetails.resource_type || this.props.editRuleDetails.resource_type === '' || this.props.editRuleDetails.resource_type === 'Select' || !this.props.editRuleDetails.rule_name || this.props.editRuleDetails.rule_name === '' || !this.props.editRuleDetails.description ||this.props.editRuleDetails.description === '') {
		// 	this.setState({ hasError: true, activeTab: 1 },
		// 		() => this.resetState(1)
		// 	)
		// 	// this.setState({ hasError: false, activeTab })
		// } else {
			if(activeTab > 2) {
				if(!this.props.editRuleDetails.resources || !this.props.editRuleDetails.resources.length) {
					this.setState({ hasError: true, activeTab: 2 },
						() => this.resetState(2)
					)
				} else {
					if(activeTab > 3) {
						// this.setState({ hasError: false })
						if(this.props.editRuleDetails.category === 'Service-Sizing' && this.props.editRuleDetails.resource_type === 'EC2') {
							let foundError = 0
							let condition = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedInstanceFamily ? this.props.editRuleDetails.condition.allowedInstanceFamily : []
							if(condition.length) {
								condition.forEach(item => {
									//item.total_count === ''
									if(!item.allowed_instance.length || !item.instance_type.length) {
										this.setState({ hasError: true, activeTab: 3, decision: item.node_type }, () => this.resetState(3) )
										foundError += 1				
									}
								})	
								if(foundError === 0) {
									this.setState({ hasError: false, decision: '', activeTab })
								}
							} else {
								this.setState({ hasError: true, activeTab: 3 })
							}
						} else if(this.props.editRuleDetails.category === 'Service-BlockStore') {
							let condition = this.props.editRuleDetails.condition ? this.props.editRuleDetails.condition : {}
							if(!condition.allowedVolumeType || !condition.allowedVolumeType.length) {
								this.setState({ hasError: true, activeTab: 3 },
									() => this.resetState(3)
								)
							} else {
								this.setState({ hasError: false, activeTab })
							}
						} else if(this.props.editRuleDetails.category === 'Service-Containers') {
							let condition = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedContainers ? this.props.editRuleDetails.condition.allowedContainers  : []
							if(!condition.length) {
								this.setState({ hasError: true, activeTab: 3 },
									() => this.resetState(3)
								)
							} else {
								this.setState({ hasError: false, activeTab })
							}
						} else if(this.props.editRuleDetails.category === 'Service-Engine' && this.props.editRuleDetails.resource_type === 'RDS') {
							let condition = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedEngineDetails ?this.props.editRuleDetails.condition.allowedEngineDetails : []
							if(!condition.length) {
								this.setState({ hasError: true, activeTab: 3 },
									() => this.resetState(3)
								)
							} else {
								this.setState({ hasError: false, activeTab })
							}
						} else if(this.props.editRuleDetails.category === 'Service-Images') {
							let condition = this.props.editRuleDetails.condition ? this.props.editRuleDetails.condition : {}
							if(!Object.entries(condition).length) {
								this.setState({ hasError: true, activeTab: 3 },
									() => this.resetState(3)
								)
							} else {
								this.setState({ hasError: false, activeTab })
							}
						} else if(this.props.editRuleDetails.category === 'Service-Logs') {
							let condition = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.log_list ? this.props.editRuleDetails.condition.log_list : {}
							if(Object.entries(condition).length) {
								let hasData = 0
								Object.values(condition).forEach(value => {									
									if(value.allowed && value.destination !== '') {
										hasData += 1								
									}
								})
								if(hasData === 0) {
									this.setState({ hasError: true, activeTab: 3 },
										() => this.resetState(3)
									)
								} else {
									this.setState({ hasError: false, activeTab })
								}
							} else {
								this.setState({ hasError: true, activeTab: 3 },
									() => this.resetState(3)
								)
							}
						} else {
							this.setState({ hasError: false, activeTab })
						}
					} else {
						this.setState({ hasError: false, activeTab })
					} 
				}
			} else {
				this.setState({ hasError: false, activeTab })
			}
		// }


		//for page resource page validation handling
	}

	resetState = (activeTab) => {
		this.setState({ hasError: true, activeTab })
	}

	/**
	 * Method to render active tab
	 */
	tabToShow = () => {
		switch (this.state.activeTab) {
			case 1:
				return (
					<EditDescription
						onClickNext={() => {this.setState({ hasError: false }, () => this.validatePage(this.state.activeTab+1))}}
						ruleDetails={this.props.location.state && this.props.location.state.ruleDetails}
						hasError={this.state.hasError}
						/* categoryList={this.props.categoryList ? this.props.categoryList : []} */
					/>
				)
			case 2:
				return (
					<EditResource
						onClickNext={() => {this.setState({ hasError: false }, () => this.validatePage(this.state.activeTab+1))}}
						onClickBack={() => this.setState({ activeTab: this.state.activeTab - 1 })}
						ruleDetails={this.props.location.state && this.props.location.state.ruleDetails}
						hasError={this.state.hasError}
					/>
				)
			case 3:
				return (
					<EditCondition
						onClickNext={() => {this.setState({ hasError: false }, () => this.validatePage(this.state.activeTab+1))}}
						onClickBack={() => this.setState({ activeTab: this.state.activeTab - 1 })}
						ruleDetails={this.props.location.state && this.props.location.state.ruleDetails}
						hasError={this.state.hasError}
						redirectDecision={this.state.decision && this.state.decision !== '' ? this.state.decision : ''}
					/>
				)
			case 4:
				return (
					<EditAction
						onClickNext={() => {this.setState({ hasError: false }, () => this.validatePage(this.state.activeTab+1))}}
						onClickBack={() => this.setState({ activeTab: this.state.activeTab - 1 })}
						ruleDetails={this.props.location.state && this.props.location.state.ruleDetails}
					/>
				)
			case 5:
				return (
					<EditNotification
						onClickNext={() => {this.setState({ hasError: false }, this.validatePage(this.state.activeTab+1))}}
						onClickBack={() => this.setState({ activeTab: this.state.activeTab - 1 })}
						ruleDetails={this.props.location.state && this.props.location.state.ruleDetails}
					/>
				)
			case 6:
				return (
					<EditConfirmation
						onClickFinish={() => this.handleOnFinish()}
						disableFinish={this.state.disableFinish}
						finishBtn={this.state.finishBtn}
						ruleDetails={this.props.location.state && this.props.location.state.ruleDetails}
						onClickBack={() => this.setState({ activeTab: this.state.activeTab - 1 })}
					/>
				)
			default:
				break
		}
	}

	render() {
		return (
			<div className='container-fluid flex-grow-1'>
				<div className='row h-100'>
					<div className='col-sm-12 p-0'>
						<div className='title-section pb-2'>
							<div className='row'>
								<div className='col-12 d-flex align-self-center'>
									{this.props.editRuleDetails.provider && this.props.editRuleDetails.provider !== 'Select' && (
										<h6 className='text-white'>Provider : <small>{this.props.editRuleDetails.provider.toUpperCase()}</small></h6>
									)}
									{/* {this.props.editRuleDetails.classification && this.props.editRuleDetails.classification !== '' && (
										<h6 className='text-white ml-3'>Rules : <small>{this.props.editRuleDetails.classification}</small></h6>
									)} */}
									{this.props.editRuleDetails.category && this.props.editRuleDetails.category !== '' && (
										<h6 className='text-white ml-3'>Category : <small>{this.props.editRuleDetails.category}</small></h6>
									)}
									{this.props.editRuleDetails.resource_type && this.props.editRuleDetails.resource_type !== '' && (
										<h6 className='text-white ml-3'>Service : <small>{this.props.editRuleDetails.resource_type}</small></h6>
									)}
									{this.props.editRuleDetails.rule_name && this.props.editRuleDetails.rule_name !== '' && (
										<h6 className='text-white ml-3'>Rule Name : <small>{this.props.editRuleDetails.rule_name}</small></h6>
									)}
								</div>
							</div>
						</div>
						<div className='container-fluid mt-3 h-100'>
							<div className='bg-muted'>
								<ul className={`nav nav-tabs border-bottom bd-highlight bg-muted shadow-none`} id="myTab" role="tablist">
									<li className={`nav-item`} onClick={() => this.validatePage(1)}>
										<span className={`cursor-pointer nav-link text-white ${this.state.activeTab === 1 ? 'active' : ''}`}>
											1. Description
										</span>
									</li>
									<li className={`nav-item`} onClick={() => this.validatePage(2)}>
										<span className={`cursor-pointer nav-link text-white ${this.state.activeTab === 2 ? 'active' : ''}`}>
											2. Resource
										</span>
									</li>
									<li className={`nav-item`} onClick={() => this.validatePage(3)}>
										<span className={`cursor-pointer nav-link text-white ${this.state.activeTab === 3 ? 'active' : ''}`}>
											3. Condition
										</span>
									</li>
									<li className={`nav-item`} onClick={() => this.validatePage(4)}>
										<span className={`cursor-pointer nav-link text-white ${this.state.activeTab === 4 ? 'active' : ''}`}>
											4. Action
										</span>
									</li>
									<li className={`nav-item`} onClick={() => this.validatePage(5)}>
										<span className={`cursor-pointer nav-link text-white ${this.state.activeTab === 5 ? 'active' : ''}`}>
											5. Notification
										</span>
									</li>
									<li className={`nav-item`} onClick={() => this.validatePage(6)}>
										<span className={`cursor-pointer nav-link text-white ${this.state.activeTab === 6 ? 'active' : ''}`}>
											6. Confirmation
										</span>
									</li>
								</ul>
								<div className='p-3'>
									{this.tabToShow()}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
/**
 * Type of the props used in the component
 */
EditRule.propTypes = {
	match: PropTypes.object,
	location: PropTypes.object,
	setInitialRuleDetails: PropTypes.func,
	updateClonedRule: PropTypes.func,
	editRuleDetails: PropTypes.object,
	history: PropTypes.object,
	saveRule: PropTypes.func,
	saveUserRule: PropTypes.func,
	updateUserRule: PropTypes.func
}
const mapStateToProps = state => {
	// console.log('edit rules', state)
	return {
		editRuleDetails: state.governance.editRuleDetails,
		categoryList: state.governance.categoryList
	}
}
export default AppWrapper(EditRule, mapStateToProps, { setInitialRuleDetails, saveRule, updateClonedRule, saveUserRule, updateUserRule })
