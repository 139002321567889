/*************************************************
 * Tvastar
 * @exports
 * @file PoliciesAction.js
 * @author Prakash // on 13/09/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'

import {
	DLPPOLICYENDPOINT,
	DLP_POLICIES_LIST_PRIVACY_POLICIES,
	DLP_POLICIES_GET_PRIVACY_POLICY_BASE_PARAMS,
	DLP_POLICIES_LIST_ATHENA_RESOURCES,
	DLP_POLICIES_INSERT_PRIVACY_POLICIES,
    DLP_POLICIES_UPDATE_PRIVACY_POLICIES,
    DLP_POLICIES_DELETE_PRIVACY_POLICIES,

	DLP_POLICIES_LIST_SECURITY_POLICIES,
	DLP_POLICIES_INSERT_SECURITY_POLICIES,
	DLP_POLICIES_UPDATE_SECURITY_POLICIES,
	DLP_POLICIES_DELETE_SECURITY_POLICIES,
} from '../../config'
import { ACTION_TYPES } from '../types'

/**
 * Action to lsit Privacy Policy
 * @param {Object} body
 * @param {Function} callback
*/

 export const listPrivacyPolicies = (body) => {
    return async dispatch => {
		try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_POLICIES_LIST_PRIVACY_POLICIES, { body })
			dispatch({
				type: ACTION_TYPES.SET_DLP_POLICIES_PRIVACY_LIST,
				payload: response,
			})
		} catch (error) {
			dispatch({
				type: ACTION_TYPES.SET_DLP_POLICIES_PRIVACY_LIST,
				payload: [],
			})
		}
	}
}

/**
 * Action to Get Privacy Policy Base Params
 * @param {Object} body
 * @param {Function} callback
 */

export const getPrivacyPolicyBaseParams = (body, callback) => {
    return async dispatch => {
		try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_POLICIES_GET_PRIVACY_POLICY_BASE_PARAMS, { body })
			// dispatch({
			// 	type: ACTION_TYPES.SET_POLICY_BASE_PARAMS,
			// 	payload: response,
			// })
			callback(true, response)
		} catch (error) {
			// dispatch({
			// 	type: ACTION_TYPES.SET_POLICY_BASE_PARAMS,
			// 	payload: [],
			// })
			callback(false, {})
		}
	}
}


/**
 * Action to Get Privacy Policy Base Params
 * @param {Object} body
 * @param {Function} callback
 */

 export const listAthenaResources = (body, callback) => {
    return async dispatch => {
		try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_POLICIES_LIST_ATHENA_RESOURCES, { body })
			callback(true, response)
		} catch (error) {
			callback(false, {})
		}
	}
}

/**
 * Action to Insert Privacy policies
 * @param {Object} body
 * @param {Function} callback
 */

export const insertPrivacyPolicies = (body, callback) => {
    return async dispatch => {
		try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_POLICIES_INSERT_PRIVACY_POLICIES, { body })
			// dispatch({
			// 	type: ACTION_TYPES.SET_METADATA_TOTAL_COUNT,
			// 	payload: response,
			// })
			callback(true, response)
		} catch (error) {
			// dispatch({
			// 	type: ACTION_TYPES.SET_METADATA_TOTAL_COUNT,
			// 	payload: [],
			// })
			callback(false)
		}
	}
}

/**
 * Action to Update Privacy policies
 * @param {Object} body
 * @param {Function} callback
 */

export const updatePrivacyPolicies = (body, callback) => {
    return async dispatch => {
		try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_POLICIES_UPDATE_PRIVACY_POLICIES, { body })
			// dispatch({
			// 	type: ACTION_TYPES.SET_METADATA_TOTAL_COUNT,
			// 	payload: response,
			// })
			callback(true, response)
		} catch (error) {
			// dispatch({
			// 	type: ACTION_TYPES.SET_METADATA_TOTAL_COUNT,
			// 	payload: [],
			// })
			callback(false)
		}
	}
}

/**
 * Action to Delete Privacy policies
 * @param {Object} body
 * @param {Function} callback
 */

export const deletePrivacyPolicies = (body, callback) => {
    return async dispatch => {
		try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_POLICIES_DELETE_PRIVACY_POLICIES, { body })
			// dispatch({
			// 	type: ACTION_TYPES.SET_METADATA_TOTAL_COUNT,
			// 	payload: response,
			// })
			callback(true, response)
		} catch (error) {
			// dispatch({
			// 	type: ACTION_TYPES.SET_METADATA_TOTAL_COUNT,
			// 	payload: [],
			// })
			callback(false)
		}
	}
}




/**
 * Action to lsit Security Policy
 * @param {Object} body
 * @param {Function} callback
*/

export const listSecurityPolicies = (body, callback) => {
    return async dispatch => {
		try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_POLICIES_LIST_SECURITY_POLICIES, { body })
			dispatch({
				type: ACTION_TYPES.SET_DLP_POLICIES_SECURITY_LIST,
				payload: response,
			})
			// callback(true, response)
		} catch (error) {
			dispatch({
				type: ACTION_TYPES.SET_DLP_POLICIES_SECURITY_LIST,
				payload: [],
			})
			// callback(false, {})
		}
	}
}
/**
 * Action to Insert Security policies
 * @param {Object} body
 * @param {Function} callback
 */

export const insertSecurityPolicies = (body, callback) => {
    return async dispatch => {
		try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_POLICIES_INSERT_SECURITY_POLICIES, { body })
			// dispatch({
			// 	type: ACTION_TYPES.SET_METADATA_TOTAL_COUNT,
			// 	payload: response,
			// })
			callback(true, response)
		} catch (error) {
			// dispatch({
			// 	type: ACTION_TYPES.SET_METADATA_TOTAL_COUNT,
			// 	payload: [],
			// })
			callback(false)
		}
	}
}

/**
 * Action to Update Security policies
 * @param {Object} body
 * @param {Function} callback
 */

export const updateSecurityPolicies = (body, callback) => {
    return async dispatch => {
		try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_POLICIES_UPDATE_SECURITY_POLICIES, { body })
			// dispatch({
			// 	type: ACTION_TYPES.SET_METADATA_TOTAL_COUNT,
			// 	payload: response,
			// })
			callback(true, response)
		} catch (error) {
			// dispatch({
			// 	type: ACTION_TYPES.SET_METADATA_TOTAL_COUNT,
			// 	payload: [],
			// })
			callback(false)
		}
	}
}

/**
 * Action to Delete Security policies
 * @param {Object} body
 * @param {Function} callback
 */

export const deleteSecurityPolicies = (body, callback) => {
    return async dispatch => {
		try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_POLICIES_DELETE_SECURITY_POLICIES, { body })
			// dispatch({
			// 	type: ACTION_TYPES.SET_METADATA_TOTAL_COUNT,
			// 	payload: response,
			// })
			callback(true, response)
		} catch (error) {
			// dispatch({
			// 	type: ACTION_TYPES.SET_METADATA_TOTAL_COUNT,
			// 	payload: [],
			// })
			callback(false)
		}
	}
}