/*************************************************
 * Tvastar
 * @exports
 * @file Table.js
 * @author Prakash // on 1/03/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTable, useRowSelect, useBlockLayout, useResizeColumns, useSortBy, usePagination } from 'react-table'
import { UncontrolledTooltip } from 'reactstrap'


const toolTipModifiers = {
	flip: { behavior: ['top'] },
	preventOverflow: { boundariesElement: 'viewport' },
}

const IndeterminateCheckbox = React.forwardRef(
	({ indeterminate, ...rest }, ref) => {
	  const defaultRef = React.useRef()
	  const resolvedRef = ref || defaultRef
  
	  React.useEffect(() => {
		resolvedRef.current.indeterminate = indeterminate
	  }, [resolvedRef, indeterminate])
  
	  return (
		  <input type="checkbox" ref={resolvedRef} {...rest} />
	  )
	}
)

function ResizeChecboxTable({ columns, data, dashboard, perPage = 10, sortByColumn, riskTooltip=false, selectedRows, tableHead=false, tableBody=false, tableSize=false, tableHover=false, textColor=false, columnBorder=false  }) {
	const [selectedRowIndex, setSelectedRowIndex] = useState(0)
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		// preGlobalFilteredRows,
		// setGlobalFilter,
		// state,
		// rows,
		// selectedFlatRows,
		// state: { pageIndex, pageSize, selectedRowIds },
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState: { 
				pageIndex: 0, pageSize: perPage,
				sortBy: [
					{
						id: sortByColumn,
						desc: true
					}
				]
			},
		},
		useBlockLayout,
		useResizeColumns,
		useSortBy,
		usePagination,
		useRowSelect,
		hooks => {
			hooks.visibleColumns.push(columns => [
			  // Let's make a column for selection
			  {
				id: 'selection',
				// The header can use the table's getToggleAllRowsSelectedProps method
				// to render a checkbox
				Header: ({ getToggleAllRowsSelectedProps }) => (
					<div>
						<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
					</div>
				),
				// The cell can use the individual row's getToggleRowSelectedProps method
				// to the render a checkbox
				Cell: ({ row }) => (
					<div>
						<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
					</div>
				),
			  },
			  ...columns,
			])
		}
		
	)
	
	

	return (
		<React.Fragment>
			<div className='table-responsive'>
				<table {...getTableProps()} className={`table ${tableSize ? tableSize : 'table-sm'} ${tableHover ? tableHover : 'table-hover'} ${textColor}`}>
					<thead className={`${tableHead ? tableHead : 'bg-secondary-50'}`}>
						{headerGroups.map((headerGroup, i) => (
							<tr key={i} {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column, index) => (
									<th key={index} {...column.getHeaderProps()}>
										{index !== 0 ?
											<span
												{...column.getSortByToggleProps({ title: 'Sort' })}
												style={{ marginRight: 10, cursor: 'pointer' }}
											>
												{column.isSorted ? (
													column.isSortedDesc ? (
														<React.Fragment>
															<i className='fas fa-sort-down' /> {column.render('Header')}
														</React.Fragment>
													) : (
														<React.Fragment>
															<i className='fas fa-sort-up' /> {column.render('Header')}
														</React.Fragment>
													)
												) : (
													column.render('Header')
													// <React.Fragment>
													// 	<i className='fas fa-sort' /> {column.render('Header')}
													// </React.Fragment>
												)}
											</span>
										: 
											column.render('Header')
										}
										{headerGroup.headers.length - 1 === index ? null : (
											<div {...column.getResizerProps()} className={`resizer`} />
										)}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody className={`${tableBody ? tableBody : 'bg-white'}`} {...getTableBodyProps()}>
						{page.map((row, i) => {
							prepareRow(row)
							return (
								<tr
									className={`${selectedRowIndex === i ? (tableBody ? 'bg-black6' : '') : '' }`}
									key={i}
									{...row.getRowProps()}
									onClick={() => {
										setSelectedRowIndex(i)
									}}
								>
								
									{row.cells.map((cell, index) => {
										return (
											<React.Fragment key={index}>
													{riskTooltip && riskTooltip.includes(index) && (
														<span className="uncontrolledTooltip">
															<UncontrolledTooltip  
																/* isOpen={true} */
																key={index}
																placement='top'
																modifiers={toolTipModifiers}
																target={`targetIdNotify${i}${index}`}
																//trigger="click"
																//className="uncontrolledTooltip"
																/* style={{backgroundColor:'red'}} */
															>
																{cell.row.values.severity}
															</UncontrolledTooltip>
														</span>
													)}
												<td
													key={index}
													className={`text-truncate ${columnBorder}`}
													{...cell.getCellProps()}
													id={`targetIdNotify${i}${index}`}
												>
													{/* {moment(cell.render('Cell').props.cell.value).isValid()
													? cell.render('Cell')
													: cell.render('Cell').props.cell.value && cell.render('Cell').props.cell.value.length > 15
													? cell.render('Cell').props.cell.value.substring(0, 15) + '...' */}
													{cell.render('Cell')}
												</td>
											</React.Fragment>
										)
									})}
								</tr>
							)
						})}
					</tbody>
				</table>
				
			</div>
			{dashboard && data.length > perPage ? (
				<div className='pagination f12'>
					Rows per page:{' '}
					<select
						className="bg-primary  text-primary-color"
						value={pageSize}
						onChange={e => {
							setPageSize(Number(e.target.value))
						}}
					>
					{[perPage * 1, perPage * 2, perPage * 3, perPage * 4, perPage * 5].map(pageSize => (
						<option className="text-primary-color" key={pageSize} value={pageSize}>
							{pageSize}
						</option>
					))}
					</select>
					<span className='mx-3'>
						Page{' '}{pageIndex + 1} of {pageOptions.length}{' '}
					</span>
					<button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
						<i className='fal fa-arrow-to-left f12 text-gray4' />
					</button>{' '}
					<button onClick={() => previousPage()} disabled={!canPreviousPage}>
						<i className='fal fa-angle-left f12 text-gray4' />
					</button>{' '}
					<button onClick={() => nextPage()} disabled={!canNextPage}>
						<i className='fal fa-angle-right f12 text-gray4' />
					</button>{' '}
					<button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
						<i className='fal fa-arrow-to-right f12 text-gray4' />
					</button>
				</div>
			) : null}
			{/* {console.log('selectedFlatRows', selectedFlatRows)} */}
		</React.Fragment>
	)
}

/**
 * Type of the props used in the component
 */
ResizeChecboxTable.propTypes = {
	columns: PropTypes.array,
	data: PropTypes.array,
	dashboard: PropTypes.bool,
	// onClickRow: PropTypes.func,
	riskTooltip: PropTypes.array,
	selectedRows: PropTypes.func
}

export default ResizeChecboxTable
