
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ActionSection.js
 * @author Prakash // on 08/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'

import { Input, Spinner } from 'reactstrap'
import { setAutomationPropsDetails } from '../../actions/commonAction'
import Search from '../common/SearchComponent'
import RunCloudwatchInsightsQuery from './RunCloudwatchInsightsQuery'
import ApplyGrokAndGroupBy from './ApplyGrokAndGroupBy'
import SlowQueryAnalysis from './SlowQueryAnalysis'
import Notify from './Notify'
import CallAgent from './CallAgent'
import CallAgentRdsMysql from './CallAgentRdsMysql'
import JoinOutput from './JoinOutput'
import RunDiagnostics from './RunDiagnostics'


class ActionSection extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            activeSection: this.props.activeSection,
            showAction: this.props.showAction,
            latestTab: this.props.latestTab,
        }
    }

    componentDidMount = () => {

        if(this.state.showAction) {
            this.props.scrollToSection(this.state.activeSection)
        }

        if(this.props.automationPropsDetails) {
            let obj = this.props.automationPropsDetails
            let actionsExist = 0
            if(obj.actions && obj.actions.length) {
                let exist = obj.actions.filter(e => e.section === this.state.activeSection)
                if(exist.length) {
                    actionsExist = 1
                    this.setState({ 
                        selectedAction: exist[0].action,
                        action_name: exist[0].action_name,
                        action_description: exist[0].description,
                        name: exist[0].name
                    })
                }
            }            
            if(!actionsExist) {
                this.formInputProps(this.state.activeSection, "section", this.state.activeSection)
            }
        }

    }

    onActionSelect = (item) => {
        this.setState({ 
            selectedAction: item.action, 
            action_name: item.action_name,
            action_description: item.description,
            showActionResults: false 
        },
            () => {
                this.formInputProps(this.state.activeSection, "action", this.state.selectedAction)
            }
        )

    }

    formInputProps = (section, field, value) => {
        let hasError = false
        let errorMessage = ""
        let obj = this.props.automationPropsDetails
        let actions = obj.actions ? obj.actions : []
        if((!actions.length || !actions.filter((e => e.section === section)).length) && field === "section") {
            let newObject = {}
            newObject[field] = value
            actions.push(newObject)
        } else if(field !== "section") {
            let objIndex = actions.findIndex((e => e.section === section))
            let nameArray = actions.filter(e => e.section !== section).map(o => o.name)

            if(nameArray.length && nameArray.includes(this.state.action_name)) {
                hasError = true
                errorMessage = "Name already exist"
            }
            if(field === "action") {
                actions[objIndex]["action"] = this.state.selectedAction
                actions[objIndex]["action_name"] = this.state.action_name
                actions[objIndex]["description"] = this.state.action_description
            } else {
                actions[objIndex][field] = value
            }
        }
        obj.actions = actions
        this.props.setAutomationPropsDetails(obj)
        this.setState({ hasError, errorMessage },
            () => {
                this.checkActionStatus()
            }    
        )        
    }

    checkActionStatus = () => {
        if(!this.state.hasError && this.state.childData && this.state.selectedAction && this.state.name && this.state.name !== "") {
            this.setState({ actionDataCompleted: true })
        } else {
            this.setState({ actionDataCompleted: false })
        }
    }

    enableSection = () => {
        this.props.actionName(this.state.name ? this.state.name : "")
        if(!this.state.showAction){
            this.props.selectAction(this.state.activeSection)
        } 
    }

    filterActions = (type) => {
        let filterActionResult = this.props.listAutomationActionsProps
        if(filterActionResult.length) {
            filterActionResult = filterActionResult.filter(e => e.action.toLowerCase() === type)
        }
        this.setState({ filterActionResult })
    }

    render() {
        return (
            <div className={"class_"+this.state.activeSection}>
                <div className="lineDown"></div>
                <div className="lineDown"></div>
                <div className="downArrow"><i className="fas fa-caret-down"></i></div>
                <div className={`section ${this.state.showAction ? "active" : ""}`}>
                    <div className={`sectionHeader ${this.state.showAction ? "" : "rounded"}`} onClick={() => this.enableSection()}>
                        <div className="d-flex">
                            <div className="">
                                {!this.state.showAction && !this.state.actionDataCompleted ?
                                    <i className="fas fa-exclamation-circle icon-stack-2x"></i>
                                : null}
                                <i className={`fas fa-radiation f30 p-2 border-gray3 align-self-center rounded ${this.state.showAction ? "text-white" : ""}`}></i>
                            </div>
                            <div className="columnHeading ml-3 align-self-center">
                                <div className="d-flex">
                                    <span className="title">{this.props.tabCount}. Action</span>
                                    {!this.state.showAction ?
                                        <div clasName="d-flex text-white">
                                            {this.state.action_name ?
                                                <span className="ml-2 text-info">Type: <span className="text-white">{this.state.action_name}</span></span>
                                            : null}
                                            {this.state.name ?
                                                <span className="ml-2 text-info">Name: <span className="text-white">{this.state.name}</span></span>
                                            : null}
                                        </div>
                                    : null}
                                </div>
                                <span className="description">Details about action to be perfomed</span>
                            </div>
                        </div>
                        <div className={`d-flex align-self-center text-black5 mt-1 ${this.state.showAction ? "" : "displayNone"}`}>
                            <i className={`fas fa-question-circle mr-4 ${!this.state.selectedAction ? "displayNone" : ""}`} onClick={() => this.props.showHelper()}></i>

                            <div className="position-relative d-inline-block" ref={this.detailDropActionRef}>
                                <i className="fas fa-ellipsis-h" onClick={() => this.setState({ showDetailDropAction: !this.state.showDetailDropAction })}></i>
                                <div className={`moreOptions ${this.state.showDetailDropAction ? "" : "displayNone"}`}>
                                    {/* <button>Rename step…</button> */}
                                    <button onClick={() => this.setState({ showDetailDropAction: false }, () => this.props.deleteStep(this.state.activeSection))}>Delete step…</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`sectionBody ${this.state.showAction ? "" : "displayNone"}`}>
                        {!this.state.selectedAction ?
                            <div className="row">
                                <div className="col-sm-8">
                                    <div className="d-flex mt-2">
                                        <i className={`fas fa-indent f30 p-2 border-gray3 align-self-center rounded text-white`}></i>
                                        <div className="ml-3 align-self-center d-flex flex-column">
                                            <span className="text-white">Action Type</span>
                                            <span className="">Search / Select the action type</span>
                                        </div>
                                    </div>
                                    <div className="position-relative d-inline-block w-100">
                                        {this.props.listAutomationActionsProps && this.props.listAutomationActionsProps.length ? 
                                            <Search
                                                data={this.props.listAutomationActionsProps}
                                                applyTags={false}
                                                applyLiteDarkTags={true}
                                                topClassName={"bg-black5 border-gray5 rounded-5 mt-3"}
                                                // searchClassName={"my-2"}
                                                searchIconColor={"text-white "}
                                                searchPlaceHolder={"Search actions...."}
                                                className={"bg-transparent text-white"}
                                                filteredData={(searchResult) => {
                                                    this.setState({ searchResult, showActionResults: true })
                                                }}
                                            />
                                        : null}
                                        {this.state.showActionResults && this.state.searchResult && this.state.searchResult.length ? 
                                            <div className={`dropdownOptions`}>
                                                {this.state.searchResult.map(item => {
                                                    return (
                                                        <button className="d-flex flex-column mb-3" onClick={() => this.onActionSelect(item)}>
                                                            <p className="mb-0">{item.action_name ? item.action_name : item.action}</p>
                                                            {item.description && item.description !== ""?
                                                                <p className="mb-0 small description d-flex flex-wrap text-left">{item.description}</p>
                                                            : null}
                                                        </button>
                                                    )
                                                })}
                                            </div>
                                        : this.state.showActionResults && this.state.searchResult && !this.state.searchResult.length ? 
                                            <div className={`dropdownOptions`}>
                                                <button className="d-flex flex-column mb-3">
                                                    <p className="mb-0">No Results</p>
                                                    <p className="mb-0 small description">no results for your search key</p>
                                                </button>
                                            </div>
                                        : null}
                                    </div>

                                    <div className="row mt-2 p-3">
                                        {this.state.filterActionResult && this.state.filterActionResult.length ?
                                            this.state.filterActionResult.slice(0,10).map((item, i) => {
                                                return(
                                                    <div className={`col-sm-6 sectionBlock`} onClick={() => this.onActionSelect(item)}>
                                                        <p className="mb-0">{item.action_name ? item.action_name : item.action}</p>
                                                    </div>
                                                )
                                            })
                                        : this.props.listAutomationActionsProps.slice(0,10).map((item, i) => {
                                            return(
                                                <div className={`col-sm-6 sectionBlock`} onClick={() => this.onActionSelect(item)}>
                                                    <p className="mb-0">{item.action_name ? item.action_name : item.action}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="p-3 bg-muted rounded cursorPointer mb-3">
                                        <div className="d-flex" onClick={() => this.filterActions("join")}>
                                            <i className={`fas fa-object-group f25 p-2 border-info align-self-center  text-info`}></i>
                                            <div className="align-self-centr ml-3">
                                                <h6 className="my-0">Joins</h6>
                                                <p className="mb-0 f10">Different set of joins of different condition</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-3 bg-muted rounded cursorPointer mb-3">
                                        <div className="d-flex">
                                            <i className={`fas fa-code-branch f25 p-2 border-info align-self-center  text-info`}></i>
                                            <div className="align-self-centr ml-3">
                                                <h6 className="my-0">Paths</h6>
                                                <p className="mb-0 f10">Different set of steps of different condition</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-3 bg-muted rounded cursorPointer mb-3">
                                        <div className="d-flex">
                                            <i className={`fas fa-filter f25 p-2 border-info align-self-center  text-info`}></i>
                                            <div className="align-self-centr ml-3">
                                                <h6 className="my-0">Filters</h6>
                                                <p className="mb-0 f10">Only Processed when a condition is met</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-3 bg-muted rounded cursorPointer mb-3">
                                        <div className="d-flex">
                                            <i className={`fas fa-stream f25 p-2 border-info align-self-center  text-info`}></i>
                                            <div className="align-self-centr ml-3">
                                                <h6 className="my-0">Format</h6>
                                                <p className="mb-0 f10">Only Processed when a condition is met</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : 
                            <div className="mt-3">
                                <div className="d-flex">
                                    <div className="w-50">
                                        <label className="mb-2 w-100">Action Type</label>
                                        <label className="text-white">{this.state.action_name}</label>
                                        {/* <Input
                                            type="text"
                                            placeholder="Type"
                                            className="inputTextbox"
                                            readOnly={true}
                                            value={this.state.action_name}
                                        /> */}
                                    </div>
                                    <div className="pl-3 w-50">
                                        <label className="mb-1">Action Name</label>
                                        <Input
                                            type="text"
                                            placeholder="Name"
                                            className="inputTextbox"
                                            value={this.state.name}
                                            onChange={event => this.setState({ name: event.target.value },
                                                () => {
                                                    this.props.actionName(this.state.name)
                                                    this.formInputProps(this.state.activeSection, "name", this.state.name)
                                                }
                                            )}
                                        />
                                        {this.state.hasError && this.state.errorMessage !== "" ?
                                            <p className="m-0 text-danger align-self-end mb-1">{this.state.errorMessage}</p>
                                        : null}
                                    </div>
                                </div>
                                {this.state.selectedAction === "RunCloudwatchInsightsQuery" ?
                                    <RunCloudwatchInsightsQuery 
                                        activeSection={this.state.activeSection}
                                        actionCompleted={(bool) => this.setState({ childData: bool }, () => this.checkActionStatus())}
                                    />
                                : this.state.selectedAction === "ApplyGrokAndGroupBy" ?
                                    <ApplyGrokAndGroupBy 
                                        activeSection={this.state.activeSection}
                                        actionCompleted={(bool) => this.setState({ childData: bool }, () => this.checkActionStatus())}
                                    />
                                : this.state.selectedAction === "SlowQueryAnalysis" ?
                                    <SlowQueryAnalysis 
                                        activeSection={this.state.activeSection}
                                        actionCompleted={(bool) => this.setState({ childData: bool }, () => this.checkActionStatus())}
                                    />
                                : this.state.selectedAction === "Notify" ?
                                    <Notify
                                        activeSection={this.state.activeSection}
                                        actionCompleted={(bool) => this.setState({ childData: bool }, () => this.checkActionStatus())}
                                    />
                                : (this.state.action_name === "Run Commands in Redis Cluster" || this.state.action_name === "Run commands in Redis") ?
                                    <CallAgent
                                        activeSection={this.state.activeSection}
                                        actionCompleted={(bool) => this.setState({ childData: bool }, () => this.checkActionStatus())}
                                        showHelperSection={(val, database) => this.props.showHelperSection(val, "showDatabaseSection", database)}
                                    />
                                : this.state.action_name === "Run RDS MySQL Queries" ?
                                    <CallAgentRdsMysql
                                        activeSection={this.state.activeSection}
                                        actionCompleted={(bool) => this.setState({ childData: bool }, () => this.checkActionStatus())}
                                        showHelperSection={(val, database) => this.props.showHelperSection(val, "showDatabaseSection", database)}
                                    />
                                : this.state.selectedAction === "Join" ?
                                    <JoinOutput
                                        activeSection={this.state.activeSection}
                                        actionCompleted={(bool) => this.setState({ childData: bool }, () => this.checkActionStatus())}
                                    />
                                : this.state.selectedAction === "RunDiagnostics" ?
                                    <RunDiagnostics
                                        activeSection={this.state.activeSection}
                                        actionCompleted={(bool) => this.setState({ childData: bool }, () => this.checkActionStatus())}
                                        showHelperSection={(val, diagnostics) => this.props.showHelperSection(val, "showDiagnosticsSection", diagnostics)}
                                    />
                                :  null}

                                <div className={`${this.state.actionDataCompleted ? "" : "displayNone"} mt-4`}>
                                    <div className={`continueButton mt-4 text-center`}>
                                        <button className={`btn bg-info p-2 text-white mr-4`} onClick={() => this.props.selectAction(this.state.activeSection + 1)}>Continue</button>
                                        {this.state.latestTab === this.state.activeSection ?
                                            <button className={`btn bg-info p-2 text-white`}  onClick={() => this.setState({ actionLoader: true }, () => this.props.createAutomation())}>
                                                {this.state.actionLoader ? <Spinner color='white' size='sm' className="mx-1" /> :null}
                                                {this.props.pageType === "edit" ? "Update Automation" : "Create Automation"}
                                            </button>
                                        : null}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
ActionSection.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log("zapAction",state)
    return {
        automationPropsDetails: state.filters.automationPropsDetails,
        listAutomationActionsProps: state.aiops.listAutomationActionsProps,
    }
}

export default connect(mapStateToProps, {
    setAutomationPropsDetails,
})(withRouter(ActionSection))        