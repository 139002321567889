import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import _ from 'lodash'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

import { listAssets } from '../../../../actions/detectors/detectorsAction'

class AccessedBySection extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            inputComponent: this.props.inputComponent,
            selectedPage: this.props.selectedPage,
            selectedOption: this.props.selectedOption,
            selectedAccount: this.props.selectedAccount,
            typeOptions: [
				{label: "Include", value: "Include"},
				{label: "Exclude", value: "Exclude"}
			],
            selectedFilters: this.props.selectedFilters
        }
    }

    componentDidMount = () => {
        if(this.state.selectedFilters && this.state.selectedFilters.length) {
            let selectedUsers = []
            let selectedRoles = []
            let access_root_account = false
            let access_cross_account = false
            this.state.selectedFilters.forEach(row => {
                if(row.type === "users"){
                    selectedUsers.push(row.value)
                } else {
                    selectedRoles.push(row.value)
                }
                if(row.access_root_account) {
                    access_root_account = true
                }
                if(row.access_cross_account) {
                    access_cross_account = true
                }
            })

            this.setState({ selectedUsers, selectedRoles, access_root_account, access_cross_account })
        }

        this.listAssets("iam-users")
        this.listAssets("iam-roles")
    }

    listAssets = (type, item) => {
        let params = {}
		if(this.state.selectedAccount && this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
			params.account_id = this.state.selectedAccount
		}
        params.service_name = type

		this.props.listAssets(params, (promise, response) => {
			if (promise) {
				console.log("response", response.results)
                if(type === 'iam-roles') {
                    let roleArray = []
                    response.results && response.results.forEach(res => {
                        let dataRow = {}
                        dataRow.label = res.asset_arn
                        dataRow.value = res.asset_arn
                        roleArray.push(dataRow)
                    })
					this.setState({ identitiesRoleList: roleArray })
				} else {
                    let userArray = []
                    response.results && response.results.forEach(res => {
                        let dataRow = {}
                        dataRow.label = res.asset_arn
                        dataRow.value = res.asset_arn
                        userArray.push(dataRow)
                    })
					this.setState({ identitiesUserList: userArray })
				}
			}
		})
    }  
	
	handleMultiSelectChange = (field, arrayValue, state) => {
		let value = arrayValue.map(item => item.value ? item.value : item)
		console.log("value", value)
		let selectedValue = []
		let prevState = this.state[field] ? this.state[field] : []
		if(prevState.length === 1 && prevState[0] === "All") {
			prevState = []
		}
		if(value.includes("All")) {
			if(!prevState.includes("All")) {
				if(state.filter(e => e.value).length) {
					selectedValue = state.map(e => e.value)
				} else {
					selectedValue = state
				}
			} else {
				const index = value.indexOf("All");
				if (index > -1) {
					value.splice(index, 1);
				}
				selectedValue = value
			}
		} else if(!prevState.includes("All")) {
			selectedValue = value
		}

		if(selectedValue.length && !selectedValue.includes("All")) {
			if(!selectedValue.includes("All") && selectedValue.length === (state.length -1)) {
				selectedValue.push("All")
			}
		}
		
		this.setState({ [field]: selectedValue }, () => this.onChangeUserRole(field))
	}
	
	getMultiSelectedCount = (type, array) => {
		return array.length && array.includes("All") ? "All" : (array.length ? array.length +" Selected" : <span className="placeholder w-100 align-self-center">Select</span>)
	}

    onChangeUserRole = (field) => {
        let selectedFilters = this.state.selectedFilters ? this.state.selectedFilters : []

        let array = []
        if(field === "selectedUsers") {
            let filterUsers = selectedFilters.filter(e => e.type !== "users")

            let data = []
            this.state.selectedUsers.forEach(item => {
                let dataRow = {}
                dataRow.label = this.state.selectedOption
                dataRow.type = "users"
                dataRow.value = item
                if(this.state.access_root_account) {
                    dataRow.access_root_account = true
                } 
                
                if(this.state.access_cross_account) {
                    dataRow.access_cross_account = true
                }
                
                dataRow.userView = this.state.selectedOption +" : user : "+item+(this.state.access_root_account ? " : Access root account" : "")+(this.state.access_root_account ? " : Access cross account" : "")

                data.push(dataRow)
            })

            array = [...filterUsers, ...data]
        } else if(field === "selectedRoles") {
            let filterUsers = selectedFilters.filter(e => e.type !== "roles") && selectedFilters.filter(e => e.type === "roles").length ? selectedFilters.filter(e => e.type === "roles") : []

            let data = []
            this.state.selectedRoles.forEach(item => {
                let dataRow = {}
                dataRow.label = this.state.selectedOption
                dataRow.type = "roles"
                dataRow.value = item
                if(this.state.access_root_account) {
                    dataRow.access_root_account = true
                } 
                
                if(this.state.access_cross_account) {
                    dataRow.access_cross_account = true
                }
                
                dataRow.userView = this.state.selectedOption +" : role : "+item+(this.state.access_root_account ? " : Access root account" : "")+(this.state.access_root_account ? " : Access cross account" : "")

                data.push(dataRow)
            })

            array = [...filterUsers, ...data]
        } else if(this.state.selectedFilters && this.state.selectedFilters.length) {
            console.log("selectedFilters", this.state.selectedFilters)
            this.state.selectedFilters.forEach(item => {
                let dataRow = {}
                dataRow.label = this.state.selectedOption
                dataRow.type = item.type
                dataRow.value = item.value
                if(this.state.access_root_account) {
                    dataRow.access_root_account = true
                } 
                
                if(this.state.access_cross_account) {
                    dataRow.access_cross_account = true
                }
                
                dataRow.userView = this.state.selectedOption +" : "+item.type+" : "+item.value+(this.state.access_root_account ? " : Access root account" : "")+(this.state.access_root_account ? " : Access cross account" : "")

                array.push(dataRow)
            })
        }

        console.log("array", array)

        this.setState({ selectedFilters: array })
        
    }

    onChangeCheckbox = (field) => {
        let selectedFilters = this.state.selectedFilters ? this.state.selectedFilters : []

        let remainingFilters = selectedFilters.filter(e => e.section !== this.state.selectedOption)

        let accessData = selectedFilters.filter(e => e.section === this.state.selectedOption)
        
        let newData = []
        accessData.array.forEach(element => {
            let dataRow = {}
            dataRow.section = this.state.selectedOption
            dataRow.label = element.type
            dataRow.value = element.accessed_by
            dataRow.root_account = this.state.access_root_account
            dataRow.cross_account = this.state.access_cross_account
            dataRow.userView = this.state.selectedOption+": " +element.type+" : "+element.accessed_by+(this.state.access_root_account ? " Accessed By Root Account" : "")+(this.state.access_root_account ? " Accessed By Cross Account" : "")
            newData.push(dataRow)            
        })
        selectedFilters = [...remainingFilters, ...newData]

        this.setState({ selectedFilters })
    }

	removeDetails = (row) => {
		let filteredResult = this.state.selectedFilters.filter(e => e !== row)
		this.setState({selectedFilters: filteredResult })
	}

    applyFilter = () => {
        if(this.state.selectedFilters && this.state.selectedFilters.length) {
            return this.props.selectedInput(this.state.selectedOption, this.state.selectedFilters)
        } else {
            return this.props.selectedInput(false);
        }
    }

    closeFilter = () => {
        return this.props.selectedInput(false);
    }
    
    render() {
        return (
            
            <div className='container-fluid bg-dark3 mt-4 py-3'>
                <p className="text-white mb-2">{this.state.selectedOption}</p>
                <div className="d-flex flex-wrap mb-3 zapInputDark">
                    <div className={`multiselectDarkTheme align-self-center mr-2 z999 w-30`}>
                        <ReactMultiSelectCheckboxes
                            placeholderButtonLabel="All"
                            getDropdownButtonLabel={() => this.getMultiSelectedCount("selectedUsers", this.state.selectedUsers ? this.state.selectedUsers : [])}
                            options={this.state.identitiesUserList && this.state.identitiesUserList.map(row => ({
                                value: row.value,
                                label: row.label
                            }))}
                            onChange={arr => { this.handleMultiSelectChange("selectedUsers", arr ? arr : [], this.state.identitiesUserList) }}
                            value={this.state.selectedUsers && this.state.selectedUsers.map(acc => ({
                                value: acc
                            }))}
                        />
                    </div>
                </div>
                
                {this.state.selectedFilters && this.state.selectedFilters.filter(e => e.type === "users").length ? 
                    this.state.selectedFilters.filter(e => e.type === "users").map(row => {
                        return(
                            <span className="badge border-gray3 mr-2 mb-3 align-self-center f14 text-info px-2">{row.value}
                                <i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeDetails(row) } ></i>
                            </span>
                        )
                    })
                : null}

                <div className="d-flex flex-wrap mb-3 zapInputDark">
                    <div className={`multiselectDarkTheme align-self-center mr-2 z998 w-30`}>
                        <ReactMultiSelectCheckboxes
                            placeholderButtonLabel="All"
                            getDropdownButtonLabel={() => this.getMultiSelectedCount("selectedRoles", this.state.selectedRoles ? this.state.selectedRoles : [])}
                            options={this.state.identitiesRoleList && this.state.identitiesRoleList.map(row => ({
                                value: row.value,
                                label: row.label,
                            }))}
                            onChange={arr => { this.handleMultiSelectChange("selectedRoles", arr ? arr : [], this.state.identitiesRoleList) }}
                            value={this.state.selectedRoles && this.state.selectedRoles.map(acc => ({
                                value: acc
                            }))}
                        />
                    </div>
                </div>
                
                {this.state.selectedFilters && this.state.selectedFilters.filter(e => e.type === "roles").length ? 
                    this.state.selectedFilters.filter(e => e.type === "roles").map(row => {
                        return(
                            <span className="badge border-gray3 mr-2 mb-2 align-self-center f14 text-info px-2">{row.value}
                                <i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeDetails(row) } ></i>
                            </span>
                        )
                    })
                : null}

                <div className="d-flex flex-wrap my-3 zapInputDark">
                    <div className={`form-check form-check-inline align-self-center mt-2 mr-2 ${this.state.access_root_account ? "" : "checkboxGrayColor"}`}>
                        <input
                            className='form-check-input m-0'
                            type='checkbox'
                            checked={this.state.access_root_account}
                            onClick={e => this.setState({ access_root_account: !this.state.access_root_account },
                                () => this.onChangeUserRole()
                            )}	
                            title={this.state.access_root_account ? "not enable" : "enable"}								
                        />
                        {!this.state.access_root_account ?
                            <span class="checkmark" onClick={e => this.setState({ access_root_account: !this.state.access_root_account}, () => this.onChangeUserRole())}></span>
                        : null}

                        <label className="form-check-label ml-2 text-white" onClick={e => this.setState({ access_root_account: !this.state.access_root_account}, () => this.onChangeUserRole())}>Accessed By Root Account</label>

                    </div>
                </div>

                <div className="d-flex flex-wrap mb-3 zapInputDark">
                    <div className={`form-check form-check-inline align-self-center mt-2 mr-2 ${this.state.access_cross_account ? "" : "checkboxGrayColor"}`}>
                        <input
                            className='form-check-input m-0'
                            type='checkbox'
                            checked={this.state.access_cross_account}
                            onClick={e => this.setState({ access_cross_account: !this.state.access_cross_account },
                                () => this.onChangeUserRole()
                            )}	
                            title={this.state.access_cross_account ? "not enable" : "enable"}								
                        />
                        {!this.state.access_cross_account ?
                            <span class="checkmark" onClick={e => this.setState({ access_cross_account: !this.state.access_cross_account}, () => this.onChangeUserRole())}></span>
                        : null}

                        <label className="form-check-label ml-2 text-white" onClick={e => this.setState({ access_cross_account: !this.state.access_cross_account}, () => this.onChangeUserRole())}>Accessed By Cross Account</label>

                    </div>
                </div>
                
                <div className='row mt-2 mb-3'>
                    <div className="col-sm-6"></div>
                    <div className="col-sm-6 justify-content-end d-flex">
                        <span className="dropCancelBtn d-block p-2 mr-2" onClick={ () => this.closeFilter()}>
                            Cancel
                        </span>
                        <span className="dropApplyBtn d-block p-2" onClick={ () => this.applyFilter()}>
                            Apply
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
	// console.log('observability query page',state)
	return {
		tagKeys: state.filters.tagKeys,
	}
}

export default connect(mapStateToProps, {
	listAssets,
})(withRouter(AccessedBySection))
