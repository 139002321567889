/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AssetComparison.js
 * @author Prakash // on 21/06/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 * 
 *************************************************/
import React, { Component } from 'react'
import { AppWrapper } from '../../common/AppWrapper'
import { URL_PATH } from '../../../config/urlPath'
// import PropTypes from 'prop-types'
import { listAllProviders, listAllAccounts, listAllRegions, getAllTagsKeys, getAllTagsValues } from '../../../actions/commonAction'
import { getCoveredServices, getAggregatedAccountsByRegion } from '../../../actions/detectors/detectorsAction'
import { listAllAssets } from '../../../actions/governance/governanceAction'

import { UncontrolledTooltip, Spinner } from 'reactstrap'
import { getAccountNameFromId, getRegionName, momentConvertionUtcToLocalTime } from '../../../utils/utility'

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import _ from 'lodash'
import { DURATION_FILTER_OPTIONS } from '../../../utils/constants'

import SearchWithHiddenInput from '../../common/SearchWithHiddenInput'

class AssetComparison extends Component {
    accRegRef = React.createRef()
    tagsRef = React.createRef()
    sliderWrap = React.createRef()
    toggleRef = React.createRef()

    constructor(props) {
        super(props)
        
        this.state = {
            showLoading: true,
            showServicesLoading: true,
            changesInFilter: true,

            //filter section start
            showBy: ["Account", "Region", "Account/Region"],
            showByValue: "Account",

            providers: [],
            selectedProvider: "",
			
			accounts: [],
            accountSelected: [],
			selectedAccount: [],

            regions: [],
            regionSelected: [],
			selectedRegion: [],
			accRegionArray: [0],

			selectedDuration: "+7d",

			tagsArray: [0],
			selectedTags: [],
			tagFilter: false,           

            //filter section end

            catServiceType: ["Category", "Services"],
            selectedCatSer: "Services",            

            totalItemShowing: 0,
            totalItemsPerPage: 0, 

            startRecord: 0, 
            currentPage: 1,
            totalPages: 1,
            perPage: 100,      
        }
    }

    componentDidMount = () => {
        if(this.props.providers && this.props.providers.length) {
            this.setState({ providers: this.props.providers, selectedProvider: this.props.providers[0].provider_name },
                () => {
                    this.getFilterData('provider')
                }
            )
        } else {
            this.props.listAllProviders((promise, providers) => {
                if (promise) {
                    this.setState({ providers, selectedProvider: providers[0].provider_name },
                        () => {
                            this.getFilterData('provider')
                        }
                    )
                } else {
                    this.setState(
                        { providers: [],  }, 
                        () => {
                            this.getFilterData()
                        }
                    )
                }
            })
        }        
    }
    
    getFilterData = from => {
        let params = {}

        if (this.state.selectedProvider !== "" && this.state.selectedProvider !== 'all') {
            params.provider = this.state.selectedProvider.toLowerCase()
        }

        if (from === 'provider') {
            if(this.props.accounts && this.props.accounts.length) {
                let allAccounts = this.props.accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
                if(this.props.accounts.length) {
                    let accRow = {}
                    accRow.account_id = "All"
                    accRow.account_name = "All"
                    accRow.date = null
                    accRow.disabled = false
                    accRow.provider = "All"
                    allAccounts.unshift(accRow);
                }
                this.setState({ accounts: allAccounts })
            } else {
                this.props.listAllAccounts(params, (promise, allAccounts) => {
                    if (promise) {						
                        let filterdAccounts = allAccounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
                        if(filterdAccounts.length) {
                            let accRow = {}
                            accRow.account_id = "All"
                            accRow.account_name = "All"
                            accRow.date = null
                            accRow.disabled = false
                            accRow.provider = "All"
                            filterdAccounts.unshift(accRow);
                        }						
                        this.setState({ accounts: filterdAccounts })
                    }
                })
            }

            if(this.props.regions && this.props.regions.length) {
                let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
                this.setState({ regions: providerRegions })
            } else {
                this.props.listAllRegions(params, (promise, regions) => {
                    if (promise) {
                        let providerRegions = regions && regions[0] && regions[0].provider_regions
                        if(providerRegions) {
                            let provider_regions_row = {}
                            provider_regions_row.availability_zones = []
                            provider_regions_row.city = "All"
                            provider_regions_row.country = "All"
                            provider_regions_row.geography = "All"
                            provider_regions_row.name = "All"
                            provider_regions_row.region = "All"
                            provider_regions_row.region_id = "All"
                            provider_regions_row.state = "All"
                            providerRegions.unshift(provider_regions_row);
                        }
                        this.setState({ regions: providerRegions })
                    } else {
                        this.setState({ regions: [] })
                    }
                })
            }
        }
        this.onSearch("onLoad")
    }

    onSearch = (type) => {
        let changesInFilter = this.state.changesInFilter
        if(this.state.showByValue === "Account/Region") {
            if(!this.state.selectedAccReg || !this.state.selectedAccReg.length) {
                changesInFilter = false
                this.setState({ showFilterSection: true })
            }
        }
        if(changesInFilter) {
            this.setState({
                changesInFilter: false,
                showLoading: type === "onLoad" ? true : false,
                showServicesLoading: true,
                selectedShowBy: this.state.showByValue,
                selectedAccount: this.state.accountSelected,
                selectedRegion: this.state.regionSelected,
                resetEnabled: type === 'onLoad' ? false : true,
                totalAccArray: [],
                totalItemShowing: 0,
                totalItemsPerPage: 0,
            },
                () => {
                    if(type === "onLoad") {
                        this.getCoveredServices()
                    } else {
                        if(this.state.selectedShowBy !== "Account/Region") {
                            this.getCoveredServiceAggreate()
                        } else {
                            this.getAggregatedAccountsByRegion()
                        }
                    }
                }
            )
        }

        // setTimeout(() => { this.setState({ showLoading: false }) }, 3000)
    }

    onReset = () => {
        this.setState({ 
            selectedProvider: this.props.providers && this.props.providers.length && this.props.providers[0].provider_name ? this.props.providers[0].provider_name.toLowerCase() : "",
            accountSelected: [],
            regionSelected: [],
            changesInFilter: this.state.resetEnabled,
        })
    }

    getCoveredServices = () => {
        let params = {}
        params.provider = this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider.toLowerCase() : ""
        if(this.state.selectedAccount && this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
            params.region = this.state.selectedRegion
        }
        let aggregateBy = "resource_type"
        if(this.state.selectedCatSer === "Category") {
            aggregateBy = "category"
        }
        params.aggregate_by = [aggregateBy]
        
        if(params.provider !== "") {
            this.props.getCoveredServices(params, (promise, response) => {
                if(promise) {
                    let results = response.results && response.results.length ? response.results : []
                    let selectedCategory = results.length && results[0] ? results[0].resource_type : ""
                    if(this.state.selectedCatSer === "Category") {
                        selectedCategory = results.length && results[0] ? results[0].category : ""
                    }
                    this.setState({ 
                        categoryList: results,
                        filterCategoryList: results,
                        selectedCategory,
                        showLoading: false,
                        showServicesLoading: selectedCategory !== "" ? true : false,
                    },
                        () => {
                            if(this.state.selectedShowBy !== "Account/Region") {
                                this.getCoveredServiceAggreate()
                            } else {
                                this.getAggregatedAccountsByRegion()
                            }
                        }
                    )
                } else {
                    this.setState({ categoryList: [], filterCategoryList: [], showLoading: false })
                }
            })
        } else {
            this.setState({ categoryList: [], filterCategoryList: [], showLoading: false })
        }
    }

    getCoveredServiceAggreate = () => {
        let inputError = false
        let params = {}
        if(this.state.selectedProvider && this.state.selectedProvider !== "") {
            params.provider = this.state.selectedProvider.toLowerCase()
        } else {
            inputError = true
        }

        if(this.state.selectedAccount && this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
            params.account_id = this.state.selectedAccount
        }
        if(this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
            params.region = this.state.selectedRegion
        }

        if(this.state.selectedCatSer === "Category") {
            params.category = this.state.selectedCategory
        } else {
            params.resource_type = this.state.selectedCategory
        }
        
        let aggregateBy = ["service_name"]
        if(this.state.selectedShowBy === "Account") {
            aggregateBy.push("account_id")
        } else if(this.state.selectedShowBy === "Region") {
            aggregateBy.push("region")
        }
        params.aggregate_by = aggregateBy
        params.assets_present = true
        params.radial = true
        if(!inputError && params.provider !== "") {
            this.props.getCoveredServices(params, (promise, response) => {
                if(promise) {
                    let results = response.results && response.results.length ? response.results : []
                    // let selectedService = results.length && results[0] ? results[0].service_name : {}
                    let totalAccArray = []
                    
                    if(results.length && results[0].data && Object.entries(results[0].data).length) {
                        Object.entries(results[0].data).forEach(([key, resource], index) => {
                            let dataRow = {}
                            dataRow.key = key
                            dataRow.status = 1
                            dataRow.index = index
                            totalAccArray.push(dataRow)
                        })
                    }

                    this.setState({
                        categoryServiceList: results,
                        totalAccArray, 
                        totalAccountArray: totalAccArray,
                        showServicesLoading: false,
                    },
                        () => {
                            if(this.state.totalAccArray.length) {
                                this.slideRight("initialize")
                            }
                        }
                    )
                } else {
                    this.setState({ categoryServiceList: [], showServicesLoading: false })
                }
            })
        } else {
            this.setState({ categoryServiceList: [], showServicesLoading: false })
        }
    }

    getAggregatedAccountsByRegion = () => {
        let inputError = false
        let params = {}
        if(this.state.selectedProvider && this.state.selectedProvider !== "") {
            params.provider = this.state.selectedProvider.toLowerCase()
        } else {
            inputError = true
        }
        params.filter = this.state.selectedAccReg

        if(this.state.selectedCatSer === "Category") {
            params.category = this.state.selectedCategory
        } else {
            params.resource_type = this.state.selectedCategory
        }
        params.aggregate_by = ["service_name"]

        if(!inputError && params.provider !== "") {
            this.props.getAggregatedAccountsByRegion(params, (promise, response) => {
                if(promise) {
                    let results = response && response.length ? response : []
                    let totalAccArray = []
                    if(results.length && results[0].data && Object.entries(results[0].data).length) {
                        Object.entries(results[0].data).forEach(([key, resource], index) => {
                            let dataRow = {}
                            dataRow.key = key
                            dataRow.status = 1
                            dataRow.index = index
                            totalAccArray.push(dataRow)
                        })
                    }

                    console.log("totalAccArray", totalAccArray)
                    console.log("categoryServiceList", results)

                    this.setState({
                        categoryServiceList: results,
                        totalAccArray, 
                        totalAccountArray: totalAccArray,
                        showServicesLoading: false,
                    },
                        () => {
                            if(this.state.totalAccArray.length) {
                                this.slideRight("initialize")
                            }
                        }
                    )
                } else {
                    this.setState({ categoryServiceList: [], showServicesLoading: false })
                }
            })
        } else {
            this.setState({ categoryServiceList: [], showServicesLoading: false })
        }
    }

    onClickCategory = (item) => {
        let selectedCategory = item.category ? item.category : item.resource_type
        this.setState({ selectedCategory, totalItemShowing: 0, showServicesLoading: true },
            () => {
                if(this.state.selectedShowBy !== "Account/Region") {
                    this.getCoveredServiceAggreate()
                } else {
                    this.getAggregatedAccountsByRegion()
                }
            } 
        )
    }
    
    handleMultiSelectChange = (field, arrayValue) => {
        let value = arrayValue.map(item => item.value)

        let selectedValue = []
        if(field === 'accountSelected') {
            let prevState = this.state[field]
            if(value.includes('All')) {
                if(!prevState.includes('All')) {
                    this.state.accounts.forEach(acc => {
                        selectedValue.push(acc.account_id)
                    })
                } else {
                    const index = value.indexOf('All');
                    if (index > -1) {
                        value.splice(index, 1);
                    }
                    selectedValue = value
                }
            } else if(!prevState.includes('All')) {
                selectedValue = value
            }

            if(selectedValue.length && !selectedValue.includes('All')) {
                if(!selectedValue.includes('All') && selectedValue.length === (this.state.accounts.length -1)) {
                    selectedValue.push('All')
                }
            }	
            
            this.setState({ [field]: selectedValue, changesInFilter: true })		
        } else if(field === 'regionSelected') {
            let prevState = this.state[field]
            if(value.includes('All')) {
                if(!prevState.includes('All')) {
                    this.state.regions.forEach(reg => {
                        selectedValue.push(reg.region)
                    })
                } else {
                    const index = value.indexOf('All');
                    if (index > -1) {
                        value.splice(index, 1);
                    }
                    selectedValue = value
                }
            } else if(!prevState.includes('All')) {
                selectedValue = value
            }

            if(selectedValue.length && !selectedValue.includes('All')) {
                if(!selectedValue.includes('All') && selectedValue.length === (this.state.regions.length -1)) {
                    selectedValue.push('All')
                }
            }
            
            this.setState({ [field]: selectedValue, changesInFilter: true })
        } 
    }
    
    getMultiSelectedCount = (type, array) => {
        return array.length && array.includes('All') ? 'All' : (array.length ? array.length +' Selected' : 'All')
    }

    handleClickOutside = (event) => {
		if(this.state.showFilterSection) {
			if (this.accRegRef && !this.accRegRef.current.contains(event.target)) {
				this.setState({ showFilterSection: false })
			}
		}

		if(this.state.showTagFilter) {
			if (this.tagsRef && !this.tagsRef.current.contains(event.target)) {
				this.setState({ showTagFilter: false })
			}
		}
        if(this.state.totalAccArray && this.state.totalAccArray.length) {
            if (this.toggleRef && !this.toggleRef.current.contains(event.target)) {
                this.setState({ showServiceTypeOptions: false })
            } else {
                this.setState({ showServiceTypeOptions: !this.state.showServiceTypeOptions })
            }
        }
    } 

    navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = this.state.startRecord
            if(action === 'next' && currentPage !== this.state.totalPages) {
                startRecord = startRecord + this.state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - this.state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = this.state.totalPages
                startRecord = (this.state.totalPages - 1) * this.state.perPage
            }

            this.setState({ currentPage, startRecord })
        } 
    }

    gotoPage = (pageNumber) => {
        if(pageNumber > 0  && pageNumber <= this.state.totalPages) {
            let startRecord = (pageNumber - 1) * this.state.perPage
            this.setState({ currentPage: pageNumber, pageNumber, startRecord })
        } else  if(pageNumber > this.state.totalPages) {
            this.setState({ pageNumber: this.state.currentPage })  
        } else {
            this.setState({ pageNumber })
        }
    }

    selectedColumn = (column, field, index, item) => {
        let selectedService = item.service_name ? item.service_name : ""
        if(field !== "") {
            selectedService = field+"_"+index
        }        

        this.setState({ selectedService })

        // let obj = {}
        // obj.columnLabel = columnLabel
        // obj.columnValue = columnValue
        // obj.selectedField = field
        // obj.allFields = allFields
        // obj.selectedGroup = this.state.selectedGroup
        // obj.selectedShowBy = this.state.selectedShowBy
        // obj.start_time =this.state.start_time
        // obj.end_time =this.state.end_time
        // obj.brushStartTime =this.state.brushStartTime ? this.state.brushStartTime : ""
        // obj.brushEndTime =this.state.brushEndTime ? this.state.brushEndTime : ""
        // obj.selectedProvider = this.state.selectedProvider
        // obj.pageType = this.props.pageType
        // obj.selectedGroup = this.state.selectedGroup
        
        // this.props.setResourcePropsDetails('leftSectionSelection', obj)

        // this.props.reloadRightSide()
    }

    accRegSelected = () => {
        let data = []
        this.state.accRegionArray.forEach(item => {
            let dataRow = {}
            if(this.state["accounts_"+item] && this.state["accounts_"+item] !== "" && this.state["regions_"+item] && this.state["regions_"+item] !== "") {
                dataRow.account_id = this.state["accounts_"+item]
                dataRow.region = this.state["regions_"+item]
                data.push(dataRow)
            }
        })

        this.setState({ selectedAccReg : data })
    }

	addSection = (array) => {
        let rowList = this.state[array];
        if(this.state[array]) {
            let value = this.state[array][this.state[array].length - 1]
            value = value+1 
            rowList.push(value);
        }
        
        this.setState({[array]: rowList },
            () =>{
                if(array === "accRegionArray") {
                    this.accRegSelected()
                }
            } 
        )
    }

    removeSection = (item, i) => {
        this.setState({ ["accounts_"+item]: "", ["regions_"+item]: "" },
            () => {
                let rowList = this.state.accRegionArray;
                rowList.splice(i, 1);
                this.setState({ accRegionArray: rowList },
                    () => this.accRegSelected()    
                )
            }
        )
    }

    removeTagSection = (item, i) => {
        this.setState({ ["tag_key_"+item]: "", ["tag_value_"+item]: "" },
            () => {
                let rowList = this.state.tagsArray;
                rowList.splice(i, 1);
                this.setState({ tagsArray: rowList },
                    () => this.accRegSelected()    
				)
            }
        )
    }

	resetFilterSelection = () => {
		this.state.accRegionArray.forEach(item => {
			this.setState({ ["accounts_"+item]: "", ["regions_"+item]: "" })
		})
		this.setState({ accountSelected: [], regionSelected: [], selectedAccReg: [] })
	}

    removefilter = (index, value, field) => {
        let filteredResult = this.state[field]
        filteredResult = this.state[field].filter(e => e !== value)
        
        // filteredResult = this.state[field].filter(e => e.key !== option.key && e.value !== option.value)
        this.setState({ [field]: filteredResult, changesInFilter: true })
    }

	resetTagFilterSelection = () => {
		
        this.state.tagsArray.forEach(item => {
            this.setState({ ["tag_key_"+item]: "", ["tag_value_"+item]: "" })
        })

        this.setState({ tagsArray: [0] })
	}

    getAllTagsKeys = () => {
        if(!this.props.tagKeys || !this.props.tagKeys.length) {
            let params = {}
            this.props.getAllTagsKeys(params, (promise, response) => {})
        }
    }

    getAllTagsValues = (index) => {
        let params = {}
        params.tags = [{ "key": this.state["tag_key_"+index] }]
        this.props.getAllTagsValues(params, (promise, response) => {
            if(promise) {
                this.setState({ ["tagValues_"+index]: response })
            }
        })
    }

	showDropFunction = (index) => {
		for(let i=0; i<this.state.totalAccArray.length;i++) {
			if(i === index) {
                this.setState({ ['showDrop'+i]: !this.state['showDrop'+i] })
            } else {
                this.setState({ ['showDrop'+i]: false, moveToDestination: '' })
            }
		}
	}

	removeColumn = (index) => {
		
		let totalAccArray = this.state.totalAccArray.filter(e => e.index !== index)
        console.log("totalAccArray", totalAccArray)
        let totalArray = []
        totalAccArray.forEach((item, i) => {
            item.index = i
            totalArray.push(item)  
        })
        console.log("totalArray", totalArray)

		this.setState({ totalAccArray: totalArray, ['text_'+index]: false, ['showDrop'+index]: false, moveToDestination: ''})
	}

	onChangeText = (e) => {
		const re = /^[0-9\b]+$/;
		// if value is not blank, then test the regex
	
		if (e.target.value === '' || re.test(e.target.value)) {
		   this.setState({moveToDestination: e.target.value})
		}
	}

	moveColumnTo = (index, source) => {
		let destination = this.state.moveToDestination
		if(destination > 0 && destination !== '' && this.state.totalAccArray.length > 1 && destination <= this.state.totalAccArray.length) {
			let totalArray = this.state.totalAccArray

			destination = destination-1
                
            totalArray[source] = {...totalArray[source], status: 1, index: destination}

            //remove `from` item and store it
            let f = totalArray.splice(source, 1)[0];
            
            // insert stored item into position `to`
            totalArray.splice(destination, 0, f);            
            
            for(let i = 0; i < this.state.totalAccArray.length; i++) {
                totalArray[i] = {...totalArray[i], index: i}
            }
			
			this.setState({ totalAccArray: totalArray, ['text_'+index]: false, ['showDrop'+index]: false, moveToDestination: ''})
		}
	}

    slideLeft = () => {
        let averageSliderItemWidth = 278
        
		let sliderWrapWidth = this.sliderWrap.current.getBoundingClientRect().width
		let totalItemsPerPage = sliderWrapWidth/averageSliderItemWidth;
        let totalItemShowing = parseInt(totalItemsPerPage)
        
		if(this.state.totalItemShowing) {
            totalItemShowing = this.state.totalItemShowing - 1 
		}

        this.setState({ totalItemShowing })
	}

	slideRight = () => {
        let averageSliderItemWidth = 278
        
		let sliderWrapWidth = this.sliderWrap.current.getBoundingClientRect().width
		let totalItemsPerPage = sliderWrapWidth/averageSliderItemWidth;
        totalItemsPerPage = parseInt(totalItemsPerPage)
        let totalItemShowing = parseInt(totalItemsPerPage)
        
		if(this.state.totalItemShowing) {
            totalItemShowing = this.state.totalItemShowing + 1
		}

        this.setState({ totalItemsPerPage, totalItemShowing })
	}

    redirectResourceSearch = (acc, item) => {
        let accountId = []
        let region = []
        if(this.state.selectedShowBy === "Account") {
            accountId = [acc.key]
            if(this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
                region = this.state.selectedRegion
            }
        } else if(this.state.selectedShowBy === "Region" ) {
            if(this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
                accountId = this.state.selectedAccount
            }
            region = [acc.key]
        } else { 
            acc.key.split("_").map((item, j) => {
                if(!j) {
                    accountId = [item]
                } else {
                    region = [item]
                }
            })
        }

        // this.props.history.push({
        //     pathname: URL_PATH.RESOURCES_SEARCH,
        //     state: { 
        //         selectedProvider: this.state.selectedProvider ? this.state.selectedProvider : "",
        //         selectedAccount: accountId,
        //         selectedRegion: region,
        //         selectedService: [item.service_name]
        //     }
        // })
    }

    render() {
        return (
            <div className="container-fluid overflow-auto flex-grow-1 bg-muted" onClick={ (event) => { this.handleClickOutside(event) } }>
                <div className={`loaderOverlay ${this.state.showLoading ? "" : 'displayNone'}`}>
                    <div className="overlayEqualizerLoader">
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                    </div>
                </div>
                <div className='row h-100'>
                    <div className='col-sm-12 p-0'>
                        <div className="title-section pb-3">
                            <div className="row">
                                <div className='col-4 align-self-center'>
                                    <h6 className="text-white m-0">Assets Comparison</h6>
                                    <p className='text-white mb-0'>Compilation of assets perspectives between your accounts and regions</p>
                                </div>
                                <div className={`col-lg-8 text-right`}>
                                    <div className='d-flex justify-content-end'>
                                        <div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 ${!this.state.providers.length}`} style={{maxWidth: '150px' }}>
                                            <label>Provider</label>
                                            <Select
                                                placeholder={'All'}
                                                isSearchable={false}
                                                //onFocus={this.openProivderMenu}
                                                className='f13 p-0 colorBlack reactSelectDrop'
                                                value={({
                                                    value: this.state.selectedProvider,
                                                    label: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : <span className="placeholder">Select</span>
                                                })}
                                                options={this.state.providers && this.state.providers.map(item => ({
                                                    value: item.provider_name,
                                                    label: item.provider_name,	
                                                }))}
                                                onChange={event =>{
                                                    if(this.state.selectedProvider !== event.value) {
                                                        this.setState({ selectedProvider: event.value, changesInFilter: true },
                                                            () => {
                                                                this.filterIdentifiers('provider')
                                                            }
                                                        )
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} style={{maxWidth: '150px' }}>
                                            <label>Show By</label>
                                            <Select
                                                placeholder={'All'}
                                                isSearchable={false}
                                                //onFocus={this.openProivderMenu}
                                                className='f13 p-0 colorBlack reactSelectDrop'
                                                value={({
                                                    value: this.state.showByValue,
                                                    label: this.state.showByValue && this.state.showByValue !== "" ? this.state.showByValue : <span className="placeholder">Select</span>
                                                })}
                                                options={this.state.showBy && this.state.showBy.map(item => ({
                                                    value: item,
                                                    label: item,	
                                                }))}
                                                onChange={event =>{
                                                    this.setState({ showByValue: event.value, changesInFilter: true })
                                                    this.resetFilterSelection()
                                                }}
                                            />
                                        </div>
                                        <div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} style={{maxWidth: '150px' }} ref={this.accRegRef}>
                                            <div className="" onClick={() => this.setState({ showFilterSection: true })}>
                                                <label>Account/Region</label>
                                                <p className='pt-1 mb-0'>
                                                    {this.state.showByValue === "Account/Region" ?
                                                        this.state.selectedAccReg && this.state.selectedAccReg.length ? 
                                                            <span>{this.state.selectedAccReg.length} Selected</span>
                                                        : 
                                                            <span className="placeholder">Select</span>
                                                    :
                                                        <React.Fragment>
                                                        {this.state.accountSelected && this.state.accountSelected.length && this.state.accountSelected.includes("All") ?
                                                            <span>All / </span>
                                                        : this.state.accountSelected && this.state.accountSelected.length && !this.state.accountSelected.includes("All") ?
                                                            <span>{this.state.accountSelected.length} selected / </span>
                                                        :
                                                            <span className="placeholder">Select</span>
                                                        }

                                                        {this.state.regionSelected && this.state.regionSelected.length && this.state.regionSelected.includes("All") ?
                                                            <span>All</span>
                                                        : this.state.regionSelected && this.state.regionSelected.length && !this.state.regionSelected.includes("All") ?
                                                            <span>{this.state.regionSelected.length} selected</span>
                                                        : this.state.accountSelected && this.state.accountSelected.length ?
                                                            <span>All</span>
                                                        :
                                                            <span className="placeholder">&nbsp;</span>
                                                        }
                                                        </React.Fragment>
                                                    }
                                                </p>
                                            </div>
                                            {this.state.showFilterSection ?
                                                <div className="resourcePopupFilterSection border mt-1">
                                                    <div className="p-3 zapInputDark bg-muted">
                                                        {this.state.showByValue === "Account/Region" ?
                                                            <div className={`w-100 mt-2`}>
                                                                <div className={`d-flex`}>
                                                                    <label className="mb-1 w-40">Account</label>
                                                                    <label className="mb-1 w-40 ml-3">Region</label>
                                                                </div>
                                                                {this.state.accRegionArray.map((index, i) => {
                                                                    return(
                                                                        <div className={`w-100 ${i ? "mt-2 border-top-black4" : ""}`}>
                                                                            <div className={`d-flex ${!i ? "" : "mt-3"}`}>
                                                                                <Select
                                                                                    placeholder={'Select'}
                                                                                    isSearchable={false}
                                                                                    //onFocus={this.openProivderMenu}
                                                                                    className='f13 p-0 dark-select-theme-with-dropdown-indicator w-40'
                                                                                    value={({
                                                                                        value: this.state["accounts_"+index] && this.state["accounts_"+index] !== "" ? this.state["accounts_"+index] : 'Select',
                                                                                        label: this.state["accounts_"+index] && this.state["accounts_"+index] !== "" ? this.state.accounts.filter(e => e.account_id === this.state["accounts_"+index])[0].account_name : <span className="placeholder">Select</span>
                                                                                    })}
                                                                                    options={this.state.accounts.filter(e => e.account_id !== "All").map(item => ({
                                                                                        value: item.account_id,
                                                                                        label: item.account_name
                                                                                    }))}
                                                                                    onChange={event =>
                                                                                        this.setState({ ["accounts_"+index]: event.value, changesInFilter: true })
                                                                                    }
                                                                                />
                                                                                <Select
                                                                                    placeholder={'Select'}
                                                                                    isSearchable={false}
                                                                                    //onFocus={this.openProivderMenu}
                                                                                    className='f13 p-0 dark-select-theme-with-dropdown-indicator w-40 ml-3'
                                                                                    value={({
                                                                                        value: this.state["regions_"+index] && this.state["regions_"+index] !== "" ? this.state["regions_"+index] : 'Select',
                                                                                        label: this.state["regions_"+index] && this.state["regions_"+index] !== "" ? this.state.regions.filter(e => e.region === this.state["regions_"+index])[0].name : <span className="placeholder">Select</span>
                                                                                    })}
                                                                                    options={this.state.regions.filter(e => e.region !== "All").map(item => ({
                                                                                        value: item.region,
                                                                                        label: item.name,	
                                                                                    }))}
                                                                                    onChange={event =>
                                                                                        this.setState({ ["regions_"+index]: event.value, changesInFilter: true }, 
                                                                                            () => this.accRegSelected()
                                                                                        )
                                                                                    }
                                                                                />
                                                                                
                                                                                {this.state.accRegionArray.length > 1 ?
                                                                                    <span className={`far fa-trash cursor-pointer f18 align-self-center ml-4`} onClick={() => this.removeSection(index, i)}></span>
                                                                                : null}
                                                                                {(this.state.accRegionArray.length - 1) ===  i ?
                                                                                    <span className={`far fa-plus cursor-pointer f18 align-self-center ml-4`} onClick={() => this.addSection("accRegionArray")}></span>
                                                                                : null}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        :
                                                            <React.Fragment>
                                                            <div className="w-100">
                                                                <div className="d-flex flex-wrap mb-1">
                                                                    <label className="mb-0">Account</label>
                                                                    {this.state.accountSelected && this.state.accountSelected.length && !this.state.accountSelected.includes("All") ?
                                                                        this.state.accountSelected.map((cat, index) => {
                                                                            return(
                                                                                <span className="badge badge-secondary ml-2 mb-1">
                                                                                    {cat}
                                                                                    <i onClick={ () => this.removefilter(index, cat, 'accountSelected') } className='ml-1 fal fa-times cursorPointer'></i>
                                                                                </span>
                                                                            )
                                                                        })
                                                                    : this.state.accountSelected && this.state.accountSelected.includes("All") ?
                                                                        <span className="badge badge-secondary ml-2 mb-1">All</span>
                                                                    : null}
                                                                </div>
                                                                {this.state.showByValue === "Account" ?
                                                                    <div className={`multiselectDarkTheme align-self-center z998`}>
                                                                        <ReactMultiSelectCheckboxes						
                                                                            placeholderButtonLabel="All"
                                                                            getDropdownButtonLabel={() => this.getMultiSelectedCount('accountSelected', this.state.accountSelected)}
                                                                            value={this.state.accountSelected.map(acc => ({
                                                                                value: acc
                                                                            }))}
                                                                            onChange={arr => {
                                                                            this.handleMultiSelectChange('accountSelected', arr ? arr : []) }}
                                                                            options={this.state.accounts.map(account => ({
                                                                                value: account.account_id,
                                                                                label: account.account_name,
                                                                            }))}
                                                                        />
                                                                    </div>
                                                                : 
                                                                    <Select
                                                                        placeholder={'Select'}
                                                                        isSearchable={false}
                                                                        //onFocus={this.openProivderMenu}
                                                                        className='f13 p-0 dark-select-theme-with-dropdown-indicator'
                                                                        value={({
                                                                            value: this.state.accountSelected && this.state.accountSelected.length && this.state.accountSelected[0],
                                                                            label: this.state.accountSelected && this.state.accountSelected.length ? this.state.accounts.filter(e => e.account_id === this.state.accountSelected[0])[0].account_name : <span className="placeholder">Select</span>
                                                                        })}
                                                                        options={this.state.accounts.filter(e => e.account_id !== "All").map(item => ({
                                                                            value: item.account_id,
                                                                            label: item.account_name
                                                                        }))}
                                                                        onChange={event =>
                                                                            this.setState({ accountSelected: [event.value], changesInFilter: true })
                                                                        }
                                                                    />
                                                                }
                                                            </div>
                                                            <div className="w-100 mt-3">
                                                                <div className="d-flex flex-wrap mb-1">
                                                                    <label className="mb-0">Region</label>
                                                                    {this.state.regionSelected && this.state.regionSelected.length && !this.state.regionSelected.includes("All") ?
                                                                        this.state.regionSelected.map((cat, index) => {
                                                                            return(
                                                                                <span className="badge badge-secondary ml-2 mb-1">
                                                                                    {cat}
                                                                                    <i onClick={ () => this.removefilter(index, cat, 'regionSelected') } className='ml-1 fal fa-times cursorPointer'></i>
                                                                                </span>
                                                                            )
                                                                        })
                                                                    : this.state.regionSelected && this.state.regionSelected.includes("All") ?
                                                                        <span className="badge badge-secondary ml-2 mb-1">All</span>
                                                                    : null}
                                                                </div>
                                                                {this.state.showByValue === "Region" ?
                                                                    <div className={`multiselectDarkTheme align-self-center z998`}>
                                                                        <ReactMultiSelectCheckboxes						
                                                                            placeholderButtonLabel="All"
                                                                            getDropdownButtonLabel={() => this.getMultiSelectedCount('regionSelected', this.state.regionSelected)}
                                                                            value={this.state.regionSelected.map(reg => ({
                                                                                value: reg
                                                                            }))}
                                                                            onChange={arr => {
                                                                            this.handleMultiSelectChange('regionSelected', arr ? arr : []) }}
                                                                            options={this.state.regions.map(reg => ({
                                                                                value: reg.region,
                                                                                label: reg.name,
                                                                            }))}
                                                                        />
                                                                    </div>
                                                                : 
                                                                    <Select
                                                                        placeholder={'Select'}
                                                                        isSearchable={false}
                                                                        //onFocus={this.openProivderMenu}
                                                                        className='f13 p-0 dark-select-theme-with-dropdown-indicator'
                                                                        value={({
                                                                            value: this.state.regionSelected && this.state.regionSelected.length && this.state.regionSelected[0],
                                                                            label: this.state.regionSelected && this.state.regionSelected.length ? this.state.regions.filter(e => e.region === this.state.regionSelected[0])[0].name : <span className="placeholder">Select</span>
                                                                        })}
                                                                        options={this.state.regions.filter(e => e.region !== "All").map(item => ({
                                                                            value: item.region,
                                                                            label: item.name,	
                                                                        }))}
                                                                        onChange={event =>
                                                                            this.setState({ regionSelected: [event.value], changesInFilter: true })
                                                                        }
                                                                    />
                                                                }
                                                            </div>
                                                            </React.Fragment>			
                                                        }															
                                                        <div className="d-flex justify-content-end mt-3">
                                                            <span className={`text-info mr-2 align-self-center`} onClick={() => this.resetFilterSelection()}>Clear</span>
                                                            {/* <button className={`btn btn-sm bg-info text-white mr-2`} onClick={() => this.setState({ showFilterSection: false }, () => this.filteredResults())}>Filter</button> */}
                                                            <button className={`btn btn-sm btn-light`} onClick={() => this.setState({ showFilterSection: false })}>Select</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            : null}
                                        </div>
                                        <div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 displayNone`} style={{maxWidth: '150px' }} ref={this.tagsRef}>
                                            <div className="" onClick={() => this.setState({ showTagFilter: true }, () => this.getAllTagsKeys())}>
                                                <label>Tags</label>
                                                <p className='pt-1 mb-0 placeholder'>Select</p>
                                            </div>
                                            {this.state.showTagFilter ?
                                                <div className="resourcePopupFilterSection border mt-1">
                                                    <div className="p-3 zapInputDark bg-muted">	
                                                        <div className={`w-100`}>
                                                            <p className="mb-2 w-100">Tags</p>
                                                            <div className={`d-flex`}>
                                                                <label className="mb-1 w-40">Key</label>
                                                                <label className="mb-1 w-40 ml-3">Value</label>
                                                            </div>
                                                            {this.state.tagsArray.map((index, i) => {
                                                                return(
                                                                    <div className={`w-100 ${i ? "mt-2 border-top-black4" : ""}`}>
                                                                        <div className={`d-flex ${!i ? "" : "mt-3"}`}>
                                                                            <Select
                                                                                placeholder={'Select'}
                                                                                isSearchable={true}
                                                                                // menuIsOpen={true}
                                                                                className='f13 p-0 dark-select-theme-with-dropdown-indicator w-40'
                                                                                value={({
                                                                                    value: this.state["tag_key_"+index] && this.state["tag_key_"+index] !== "" ? this.state["tag_key_"+index] : 'Select',
                                                                                    label: this.state["tag_key_"+index] && this.state["tag_key_"+index] !== "" ? this.state["tag_key_"+index] : <span className="placeholder">Select</span>
                                                                                })}
                                                                                options={this.props.tagKeys && this.props.tagKeys.map(item => ({
                                                                                    value: item,
                                                                                    label: item,	
                                                                                }))}
                                                                                onChange={event => this.setState({
                                                                                    ["tag_key_"+index]: event.value,
                                                                                    ["tagValues_"+index]: [],
                                                                                },
                                                                                    () => this.getAllTagsValues(index)
                                                                                )}
                                                                            />
                                                                            {this.state["tag_key_"+index] && this.state["tag_key_"+index] !== "" && !this.state["tagValues_"+index].length ?
                                                                                <div className='ml-3'>
                                                                                    <Spinner className='text-center align-self-center' color='white' size='sm' />
                                                                                </div>
                                                                            :
                                                                                <React.Fragment>
                                                                                    <Select
                                                                                        placeholder={'Select'}
                                                                                        isSearchable={true}
                                                                                        // menuIsOpen={true}
                                                                                        className='f13 p-0 dark-select-theme-with-dropdown-indicator w-40 ml-3'
                                                                                        value={({
                                                                                            value: this.state["tag_value_"+index] && this.state["tag_value_"+index] !== "" ? this.state["tag_value_"+index] : 'Select',
                                                                                            label: this.state["tag_value_"+index] && this.state["tag_value_"+index] !== "" ? this.state["tag_value_"+index] : <span className="placeholder">Select</span>
                                                                                        })}
                                                                                        options={this.state["tagValues_"+index] && this.state["tagValues_"+index].map(item => ({
                                                                                            value: item,
                                                                                            label: item,	
                                                                                        }))}
                                                                                        onChange={event => this.setState({
                                                                                            ["tag_value_"+index]: event.value
                                                                                        })}
                                                                                    />
                                                                                    {this.state.tagsArray.length > 1 ?
                                                                                        <span className={`far fa-trash cursor-pointer f18 align-self-center ml-4`} onClick={() => this.removeTagSection(index, i)}></span>
                                                                                    : null}
                                                                                    {(this.state.tagsArray.length - 1) ===  i ?
                                                                                        <span className={`far fa-plus cursor-pointer f18 align-self-center ml-4`} onClick={() => this.addSection("tagsArray")}></span>
                                                                                    : null}
                                                                                </React.Fragment>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                        <div className="d-flex justify-content-end mt-3">
                                                            <span className={`text-info mr-2 align-self-center`} onClick={() => this.resetTagFilterSelection()}>Clear</span>
                                                            {/* <button className={`btn btn-sm bg-info text-white mr-2`} onClick={() => this.setState({ showFilterSection: false }, () => this.filteredResults())}>Filter</button> */}
                                                            <button className={`btn btn-sm btn-light`} onClick={() => this.setState({ showTagFilter: false })}>Select</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            : null}                                            
                                        </div>
                                        <div className='form-group flex-fill bd-highlight mb-sm-0 styled-feild displayNone' style={{maxWidth: '150px' }}>
                                            <label>Duration</label>
                                            <Select
                                                isSearchable={false}
                                                className='f13 p-0 colorBlack reactSelectDrop'
                                                value={({
                                                    value: this.state.selectedDuration,
                                                    label: this.state.selectedDuration ? DURATION_FILTER_OPTIONS.filter(e => e.value === this.state.selectedDuration)[0].option : ""
                                                })}
                                                options={DURATION_FILTER_OPTIONS.map(item => ({
                                                    value: item.value,
                                                    label: item.option,	
                                                }))}
                                                onChange={event =>{
                                                    if(this.state.selectedDuration !== event.value) {
                                                        this.setState({ selectedDuration: event.value })
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="ml-3 d-flex align-self-center">
                                            <div className="btn-group" role="group" aria-label="assetComparison">
                                                <button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-right`} onClick={()=> this.onSearch()}>Search</button>
                                                <button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-left`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
                                            </div>
                                        </div>
                                    </div>									
                                </div>
                            </div>
                        </div>
                        <div className='p-2 overflow-x-hidden'>
                            <div className="row">
                                <div className={`col-sm-12`}>
                                    <div class="bg-dark rounded">
                                        {this.state.totalAccArray && this.state.totalAccArray.length ?
                                            <div className="col-sm-12 d-flex justify-content-between p-2">
                                                <div className="d-flex">
                                                    <div className="select-sm-bg curvedDropdown minWidth120" ref={this.toggleRef}>
                                                        <div className="dropdown">
                                                            <span className="dropdown-toggle d-flex justify-content-between px-2 text-white">
                                                                {this.state.selectedCatSer ? this.state.selectedCatSer : <span className="placeholder">Select</span>}
                                                            </span>
                                                            <ul className={`dropdown-menu p-0 ${this.state.showServiceTypeOptions ? "show" : ''}`}>
                                                                {this.state.catServiceType.map(item => {
                                                                    return (
                                                                        <li 
                                                                            onClick={() => {
                                                                                this.setState({ 
                                                                                    selectedCatSer: item, 
                                                                                    showServiceTypeOptions: false,
                                                                                    totalItemShowing:0,
                                                                                    totalItemsPerPage: 0, 
                                                                                    categoryList: [],
                                                                                    filterCategoryList: [],
                                                                                    categoryServiceList: [],
                                                                                    emptySearchText: true
                                                                                    // showLoading: true
                                                                                },
                                                                                    () => this.getCoveredServices()
                                                                                )
                                                                            }}
                                                                            className={`${this.state.selectedCatSer === item ? "selected" :  ""}`}
                                                                        >
                                                                            {item}
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    {this.state.categoryList && this.state.categoryList.length ?
                                                        <SearchWithHiddenInput
                                                            data={this.state.categoryList ? this.state.categoryList : []}
                                                            applyLiteDarkTags={true}
                                                            searchClassName={"border-0"}
                                                            searchIconColor={"text-gray3"}
                                                            className="form-control-sm text-gray3 bg-muted border-0"
                                                            hideInputSection={true}
                                                            searchPlaceHolder={'Search....'}
                                                            topClassName={"dark-search align-self-center ml-2"}
                                                            filteredData={(filterCategoryList, searchText) => {
                                                                this.setState({ filterCategoryList, emptySearchText: false })
                                                            }}
                                                            resetSearchBox={this.state.emptySearchText}
                                                        />
                                                    : null}
                                                </div>
                                                    
                                                <div className="d-flex justify-content-end align-self-center">
                                                    <small className="align-self-center">
                                                        Showing 
                                                        {this.state.totalAccArray &&  this.state.totalAccArray.length && this.state.totalAccArray.length > this.state.totalItemsPerPage ?  
                                                            ' '+((this.state.totalItemShowing + 1) - this.state.totalItemsPerPage) +' - '+ this.state.totalItemShowing +' ('+this.state.totalAccArray.length+') '
                                                        : 
                                                            this.state.totalAccArray.length ? 
                                                            ' '+this.state.totalAccArray.length +' ('+this.state.totalAccArray.length+') '
                                                            : ' 0'
                                                        } 
                                                    </small>
                                                    <h5 className={`mb-0 mx-2 ${this.state.totalItemShowing > this.state.totalItemsPerPage ? 'text-white bg-info rounded-50' : 'bg-muted disabled rounded-50'} `}><span className="fad fa-arrow-circle-left cursorPointer" onClick={() => this.slideLeft()}></span></h5>
                                                    
                                                    <h5 className={`mb-0 ${this.state.totalAccArray.length > this.state.totalItemShowing ? 'text-white bg-info rounded-50' : 'bg-muted disabled rounded-50'}`}><span className="fad fa-arrow-circle-right cursorPointer" onClick={() => this.slideRight()}></span></h5>
                                                </div>
                                            </div>
                                        : null}
                                        <div className="d-flex">
                                            <div className="col-sm-1 p-0 border-right-info">
                                                <div class="w-100 overflowHidden-y-scroll minMaxHeight770">
                                                    {this.state.filterCategoryList && this.state.filterCategoryList.length ?
                                                        this.state.filterCategoryList.map((item, i) => {
                                                            return (
                                                            <div className={`px-2 cursorPointer ${i ? "" : ""} ${this.state.selectedCategory && this.state.selectedCategory === (item.category || item.resource_type) ? 'bg-dark2 text-info disabled' : ""}`} onClick={() => this.onClickCategory(item)}>
                                                                <div className="hiddenEllipses d-flex" id={"category_"+i}>
                                                                    <p className={`m-0 f-20 py-3 ${this.state.selectedCategory && this.state.selectedCategory === item.category ? 'text-info' : 'text-white'}`}>{item.category ? item.category : item.resource_type}</p>
                                                                </div>
                                                                <UncontrolledTooltip placement='top' target={"category_"+i}>
                                                                    <div className="text-left">  
                                                                        <p className="mb-1 text-primary-color"><span className="text-white mr-1">{this.state.selectedCatSer}: </span>{item.category ? item.category : item.resource_type}</p>
                                                                    </div>    
                                                                </UncontrolledTooltip>
                                                            </div>
                                                            )
                                                        })
                                                    : null}
                                                </div>
                                            </div>
                                            {this.state.showServicesLoading ?
                                                <div className='col-sm-12 d-flex justify-content-center mt-4'>
                                                    <Spinner className='text-center' color='white' size='lg' />
                                                </div>
                                            :
                                                <div className="col-sm-11 p-0">
                                                    <div class="w-100 overflowHidden-y-scroll minMaxHeight770">
                                                        <div className="row">
                                                            {this.state.categoryServiceList && this.state.categoryServiceList.length ?
                                                                <div className="col-sm-2 border-right-info bg-dark2">
                                                                    <div className="d-flex justify-content-center" style={{minHeight: "50px"}}>
                                                                        <p className="f16 text-white align-self-center mb-0">Services</p>
                                                                    </div>
                                                                    
                                                                    {this.state.categoryServiceList.map((item, index) => {
                                                                        return(
                                                                            <div className="hiddenEllipses d-flex border-top-dark" id={"service_name_"+index}>
                                                                                <p className={`m-0 f-20 pl-2 py-3 ${this.state.selectedService === item.service_name ? 'text-info' : 'text-white'}`}>
                                                                                {item.service_name +(item.total ? " ("+item.total+") ": "")}
                                                                                </p>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            : null}
                                                            <div className="col-sm-10 p-0">
                                                                {this.state.totalAccArray && this.state.totalAccArray.length ?
                                                                    <div className={`col-sm-12 pl-0`}>
                                                                        <div className="d-flex" ref={this.sliderWrap}>
                                                                            {this.state.totalAccArray.filter(item => item.status === 1).slice(this.state.totalItemShowing - this.state.totalItemsPerPage, this.state.totalItemShowing).map((acc, index) => {
                                                                                return(
                                                                                    this.state.categoryServiceList && this.state.categoryServiceList.length && this.state.categoryServiceList[0].data && this.state.categoryServiceList[0].data.hasOwnProperty(acc.key) ?
                                                                                        <div className={`d-flex justify-content-between minMax270Width bg-dark2 px-3 py-1 ${!index ? "" : "ml-2"}`}>
                                                                                            <div className="text-white w-70">
                                                                                                {this.state.selectedShowBy === "Account" ?
                                                                                                    <React.Fragment>
                                                                                                    <p className="mb-0 hiddenEllipses text-center">
                                                                                                        <span className={``}>{getAccountNameFromId(acc.key, this.state.accounts)}</span>
                                                                                                        <span className="ml-2 text-primary-color">{acc.key}</span>
                                                                                                    </p>
                                                                                                    {this.state.selectedRegion.length && !this.state.selectedRegion.includes("All") ?
                                                                                                        <p className="mb-0 hiddenEllipses">
                                                                                                            <span className="">{this.state.selectedRegion[0]}</span>
                                                                                                            <span className="ml-2 text-primary-color">{getRegionName(this.state.selectedRegion[0], this.state.regions)}</span>
                                                                                                        </p>
                                                                                                    : 
                                                                                                        <p className="mb-0 text-center text-primary-color">All Region</p>
                                                                                                    }
                                                                                                    </React.Fragment>
                                                                                                : this.state.selectedShowBy === "Region" ? 
                                                                                                    <React.Fragment>
                                                                                                    {this.state.selectedAccount.length && !this.state.selectedAccount.includes("All") ?
                                                                                                        <p className="mb-0 hiddenEllipses text-center">
                                                                                                            <span className={``}>{getAccountNameFromId(this.state.selectedAccount[0], this.state.accounts)}</span>
                                                                                                            <span className="ml-2 text-primary-color">{this.state.selectedAccount[0]}</span>
                                                                                                        </p>
                                                                                                    : 
                                                                                                        <p className="mb-0 text-center text-primary-color">All Account</p>
                                                                                                    }
                                                                                                    <p className="mb-0 hiddenEllipses">
                                                                                                        <span className={``}>{acc.key}</span>
                                                                                                        {this.state.selectedShowBy === "Region" ? 
                                                                                                            <span className="ml-2 text-primary-color">{this.state.selectedShowBy === "Region" ? getRegionName(acc.key, this.state.regions) : ""}</span>
                                                                                                        : null}
                                                                                                    </p>
                                                                                                    </React.Fragment>
                                                                                                : 
                                                                                                    acc.key.split("_").map((item, j) => {
                                                                                                        return(
                                                                                                            <React.Fragment>
                                                                                                            {!j ?
                                                                                                                <p className="mb-0 hiddenEllipses text-center">
                                                                                                                    <span className={``}>{getAccountNameFromId(item, this.state.accounts)}</span>
                                                                                                                    <span className="ml-2 text-primary-color">{item}</span>
                                                                                                                </p>
                                                                                                            : 
                                                                                                                <p className="mb-0 hiddenEllipses">
                                                                                                                    <span className={`text-white`}>{item}</span>
                                                                                                                    <span className="ml-2 text-primary-color">{getRegionName(item, this.state.regions)}</span>
                                                                                                                </p>
                                                                                                            }
                                                                                                            </React.Fragment>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                            <div className={`w-30`}>
                                                                                                <div className={`text-right`}>
                                                                                                    <div className="dropdown w-100">
                                                                                                        <div><span className="far fa-chevron-square-down fa-lg cursorPointer" onClick={ () => this.showDropFunction(index) }>
                                                                                                        </span></div>
                                                                                                        <ul className={`comparisonRemoveDrop f10 ${this.state['showDrop'+index] && this.state['showDrop'+index] ? '' : 'displayNone' }`}>
                                                                                                            <li onClick={ () => this.removeColumn(index)}><span>Remove</span></li>
                                                                                                            <li className={` ${this.state['text_'+index] ? '' : 'mb-1'} `} onClick={()=> this.setState({['text_'+index]: this.state['text_'+index] ? false : true })}><span>Move To</span></li>
                                                                                                            <li className={`mb-1 ${this.state['text_'+index] ? '' : 'displayNone'} `}>
                                                                                                                <span>
                                                                                                                    <input type="text" className="moveTextbox" onChange={e => this.onChangeText(e)} value={this.state.moveToDestination} />
                                                                                                                    <button className="btn btn-sm btn-primary align-self-left moveBtn" onClick={()=> this.moveColumnTo(index, acc.index) }>Submit</button>
                                                                                                                </span>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                    {/* <p className="mb-0 text-muted f12">{getAccountNameFromId(this.state.resources[acc.key].account_id, this.state.accounts)}</p> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                : null
                                                                                )
                                                                            })}
                                                                        </div>
                                                                        {this.state.categoryServiceList && this.state.categoryServiceList.length ?
                                                                            this.state.categoryServiceList.map((item, index) => {
                                                                                return(
                                                                                    <div className="d-flex overflow-hidden">
                                                                                        {this.state.totalAccArray.filter(item => item.status === 1).slice(this.state.totalItemShowing - this.state.totalItemsPerPage, this.state.totalItemShowing).map((acc, index) => {
                                                                                            return(  
                                                                                                <div className={`bg-dark2 minMax270Width py-3 border-top-dark text-white text-center cursorPointer ${!index ? "" : "ml-2"}`} onClick={() => this.redirectResourceSearch(acc, item)}>
                                                                                                    {item.data && item.data[acc.key] ? item.data[acc.key] : "-"}
                                                                                                </div>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        : null}
                                                                    </div>
                                                                : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
AssetComparison.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log('observability landing page',state)
    return {
        providers: state.filters.providers,
        accounts: state.filters.accounts,
        regions: state.filters.regions,
		tagKeys: state.filters.tagKeys,
    }
}

export default AppWrapper(AssetComparison, mapStateToProps, {
    listAllProviders,
    listAllAccounts,
    listAllRegions,
    getAllTagsKeys,
    getAllTagsValues,
    listAllAssets,
    getCoveredServices,
    getAggregatedAccountsByRegion,
})