/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Input, Spinner } from 'reactstrap'
import _ from 'lodash'
import { listAllProviders, listAllServices, listAllRegions, listAllAccounts } from '../../../actions/commonAction'
import { listAllAssets } from '../../../actions/assets/assetsAction'
import { applyTag, setEditTagGroupDetails } from '../../../actions/governance/taggingAction'
import Table from '../../designComponents/Table/Table'
import Search from '../../common/SearchComponent'

class ApplyTags extends Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedConditionObject: {},
			regions: [],
			selectedRegion: '',
			accounts: [],
			selectedAccount: '',
			providers: [],
			selectedProvider: '',
			services: [],
			selectedService: '',
			showApplyLoading: false,
			showAssetsLoading: false,
			filteredArray: [],
			selectedCondition: '',
			selectedAsset: '',
			disableFilter: true,
			setAssetsEmpty: false,
		}
	}
	componentDidMount = () => {
		if (this.state.selectedCondition === '') {
			this.setState({ disableFilter: false })
		}
		this.props.listAllProviders((promise, providers) => {
			if (promise) {
				if (providers.length === 1) {
					this.setState({ selectedProvider: providers[0].provider_name })
				}
				this.setState({ providers }, () => this.onSelectProvider())
			}
		})
	}
	onSelectProvider = () => {
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'all') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		this.props.listAllServices(params, (promise, services) => {
			if (promise) {
				this.setState({ services })
			}
		})
		this.props.listAllRegions(params, (promise, regions) => {
			if (promise) {
				this.setState({ regions: regions[0].provider_regions })
			}
		})
		this.props.listAllAccounts(params, (promise, accounts) => {
			if (promise) {
				this.setState({ accounts })
			}
		})
	}

	handleChange = e => {
		if (e.target.value !== '') {
			let temp = this.props.tagGroupConditions
			let matched = _.find(temp, item => item.condition_id === e.target.value)
			this.setState(
				{
					selectedCondition: e.target.value,
					selectedConditionObject: matched,
					selectedProvider: matched.provider && matched.provider.toUpperCase(),
					selectedRegion: matched.region,
					selectedAccount: matched.account_id,
					selectedAsset: matched.asset_id,
					selectedService: matched.service_name,
					disableFilter: true,
					setAssetsEmpty: false,
				},
				() => this.filterIdentifiers()
			)
			let tempArr = this.props.editTagGroupDetails ? this.props.editTagGroupDetails.tags : []
			tempArr.push({ tags: matched.tags })
			this.props.setEditTagGroupDetails('tags', tempArr)
			if (matched.service_name === '' || matched.region === '') {
				this.setState({ disableFilter: false })
			}
		} else {
			this.setState({
				setAssetsEmpty: true,
				selectedCondition: '',
				selectedConditionObject: '',
				selectedProvider: '',
				selectedAsset: '',
				selectedRegion: '',
				selectedAccount: '',
				selectedService: '',
				disableFilter: false,
			})
		}
	}

	filterIdentifiers = from => {
		this.setState({ filteredArray: [] })
		let params = {}
		if (this.state.selectedConditionObject.provider !== '' && this.state.selectedConditionObject.provider !== 'all') {
			params.provider = this.state.selectedConditionObject.provider
		}
		if (
			this.state.selectedConditionObject.service_name !== '' &&
			this.state.selectedConditionObject.service_name !== 'all'
		) {
			params.service_name = this.state.selectedConditionObject.service_name
		}
		if (this.state.selectedConditionObject.region !== '' && this.state.selectedConditionObject.region !== 'all') {
			params.region = this.state.selectedConditionObject.region
		}
		// if (
		// 	this.state.selectedConditionObject.asset_name !== '' &&
		// 	this.state.selectedConditionObject.asset_name !== 'all'
		// ) {
		// 	params.asset_name = this.state.selectedConditionObject.asset_name
		// }
		if (
			this.state.selectedConditionObject.account_id !== '' &&
			this.state.selectedConditionObject.account_id !== 'all'
		) {
			params.account_id = this.state.selectedConditionObject.account_id
		}
		if (this.state.selectedConditionObject.tags && this.state.selectedConditionObject.tags.length) {
			params.tags = this.state.selectedConditionObject.tags
		}
		if (from === 'provider') {
			this.props.listAllServices(params, (promise, services) => {
				if (promise) {
					this.setState({ services })
				}
			})
			this.props.listAllRegions(params, (promise, regions) => {
				if (promise) {
					this.setState({ regions: regions[0].provider_regions })
				}
			})
			this.props.listAllAccounts(params, (promise, accounts) => {
				if (promise) {
					this.setState({ accounts })
				}
			})
		}
		this.setState({ showAssetsLoading: true })
		this.props.listAllAssets(params, () => {
			this.setState({ showAssetsLoading: false })
		})
	}

	applyTag = () => {
		this.setState({ showApplyLoading: true })
		let params = {
			provider: this.state.selectedConditionObject.provider,
			condition_id: this.state.selectedConditionObject.condition_id,
			mode: 'equalize',
			tag_group_id: this.props.tagGroupId,
			option: 'tag_group',
			operation: 'batch',
		}
		this.props.applyTag(params, () => {
			this.setState({ showApplyLoading: false })
		})
	}

	render() {
		return (
			<>
				<h4>Apply Tags</h4>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
					magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
					consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
					pariatur.
				</p>
				<div className='row'>
					<div className='col-3 pr-0'>
						<div className='form-group'>
							<label>Condition ID</label>
							<select
								className='form-control'
								onChange={e => this.handleChange(e)}
								value={this.state.selectedCondition}
							>
								<option value=''>Select Condition</option>
								{this.props.tagGroupConditions.map((item, i) => {
									return (
										<option key={i} value={item.condition_id}>
											{item.condition_id}
										</option>
									)
								})}
							</select>
						</div>
					</div>
					<div className='col-3 pr-0'>
						<div className='form-group'>
							<label>Mode</label>
							<select className='form-control'>
								<option value='equalize'>Equalize</option>
							</select>
						</div>
					</div>
					{this.state.selectedConditionObject.condition_name && (
						<div className='col-sm align-self-center pr-0'>
							<p className='mb-0'>
								Conditional Name: <strong>{this.state.selectedConditionObject.condition_name}</strong>
							</p>
						</div>
					)}
					{this.state.selectedConditionObject.batch_id && (
						<div className='col-sm align-self-center'>
							<p className='mb-0'>
								Batch ID: <strong>{this.state.selectedConditionObject.batch_id}</strong>
							</p>
						</div>
					)}
				</div>

				<div className='row my-3'>
					{this.state.selectedConditionObject.provider && (
						<div className='col-sm align-self-center'>
							<p className='mb-0'>
								Provider:
								<strong>
									{this.state.selectedConditionObject.provider +
										':' +
										this.state.selectedConditionObject.account_id +
										':' +
										this.state.selectedConditionObject.region}
								</strong>
							</p>
						</div>
					)}
					{this.state.selectedConditionObject.service_name && (
						<div className='col-sm align-self-center'>
							<p className='mb-0'>
								Service: <strong>{this.state.selectedConditionObject.service_name}</strong>
							</p>
						</div>
					)}
					{this.state.selectedConditionObject.service_name && (
						<div className='col-sm align-self-center'>
							<p className='mb-0'>
								No.of Assets matching condition: <strong>{this.props.assets ? this.props.assets.length : 0}</strong>
							</p>
						</div>
					)}
				</div>

				{this.state.selectedCondition !== '' && (
					<div className='row'>
						<div className='col-12'>
							<div className={`rounded bg-white3 p-3 ${this.state.disableFilter ? 'disabled' : ''}`}>
								<b>Filters</b>
								<div className='row'>
									<div className='col-3 pr-0'>
										<div className='form-group mb-0'>
											<label>Provider</label>
											<Input
												// disabled={!this.state.providers.length}
												type='select'
												value={this.state.selectedProvider}
												onChange={event => {
													this.setState(
														{
															selectedProvider: event.target.value,
														},
														() => this.filterIdentifiers('provider')
													)
												}}
											>
												<option value=''>Select</option>
												{this.state.providers.map((provider, index) => {
													return (
														<option key={index} value={provider.provider_name}>
															{provider.provider_name}
														</option>
													)
												})}
											</Input>
										</div>
									</div>
									<div className='col-3 pr-0'>
										<div className='form-group mb-0'>
											<label>Account</label>
											<Input
												// disabled={!this.state.accounts.length}
												type='select'
												value={this.state.selectedAccount}
												onChange={event => {
													this.setState(
														{
															selectedAccount: event.target.value,
														},
														() => this.filterIdentifiers()
													)
												}}
											>
												<option value=''>Select</option>
												{this.state.accounts.map((account, index) => {
													return (
														<option key={index} value={account.account_id}>
															{account.account_name}
														</option>
													)
												})}
											</Input>
										</div>
									</div>
									<div className='col-3 align-self-center pr-0'>
										<div className='form-group mb-0'>
											<label>Region</label>
											<Input
												// disabled={!this.state.regions.length}
												type='select'
												value={this.state.selectedRegion}
												onChange={event => {
													this.setState(
														{
															selectedRegion: event.target.value,
														},
														() => this.filterIdentifiers()
													)
												}}
											>
												<option value=''>Select</option>
												{this.state.regions.length &&
													this.state.regions.map((region, index) => {
														return (
															<option key={index} value={region.region}>
																{region.name}
															</option>
														)
													})}
											</Input>
										</div>
									</div>
									<div className='col-3 align-self-center'>
										<div className='form-group mb-0'>
											<label>Service</label>
											<Input
												// disabled={!this.state.services.length}
												type='select'
												value={this.state.selectedService}
												onChange={event => {
													this.setState(
														{
															selectedService: event.target.value,
														},
														() => this.filterIdentifiers()
													)
												}}
											>
												<option value=''>Select</option>
												{this.state.services &&
													this.state.services.length &&
													_.chain(this.state.services)
														.groupBy('resource_type')
														.map((value, key) => ({ label: key, sub: value }))
														.value()
														.map((service, i) => {
															return (
																<optgroup key={i} label={service.label}>
																	{service.sub.map((item, i) => {
																		return (
																			<option key={i} value={service.service_name}>
																				{item.service_name}
																			</option>
																		)
																	})}
																</optgroup>
															)
														})}
											</Input>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				{this.props.assets &&
				this.props.assets.length &&
				!this.state.setAssetsEmpty &&
				this.state.selectedCondition !== '' ? (
					<div className='row my-3'>
						<div className='col-12'>
							<div className='d-flex justify-content-between'>
								<div className='align-self-center'>
									<p className='mb-0'>Assets</p>
								</div>
								<div>
									<Search
										applyTags
										data={this.props.assets && this.props.assets}
										filteredData={filteredArray => this.setState({ filteredArray })}
									/>
								</div>
							</div>
						</div>
						<div className='col-12 mt-3'>
							<div className='box table-responsive rounded overflow-hidden'>
								{this.props.assets && this.props.assets.length && !this.state.showAssetsLoading ? (
									<Table
										columns={[
											{
												Header: 'Provider',
												accessor: 'provider',
											},
											{
												Header: 'Account',
												accessor: 'account_id',
											},
											{
												Header: 'Region',
												accessor: 'region',
											},
											{
												Header: 'Service',
												accessor: 'service_name',
											},
											{
												Header: 'Asset',
												accessor: 'asset_name',
											},
										]}
										data={this.state.filteredArray.length ? this.state.filteredArray : this.props.assets}
										onClickRow={() => {}}
										tooltipFor={[1]}
									/>
								) : (!this.state.showAssetsLoading && !this.props.assets && this.state.selectedCondition !== '') ||
								  (this.props.assets && !this.props.assets.length) ? (
									<div className='d-flex justify-content-center m-4'>
										<p>No assets found</p>
									</div>
								) : null}
								{this.state.showAssetsLoading ? (
									<div className='d-flex justify-content-center m-4'>
										<Spinner className='text-center' color='dark' size='lg' />
									</div>
								) : null}
							</div>
						</div>
					</div>
				) : (
					<div className='row mt-3'>
						<div className='col-12'>
							<div className='d-flex justify-content-center'>
								<p>No assets found.</p>
							</div>
						</div>
					</div>
				)}
				<div className='row'>
					<div className='col-12'>
						<hr />
						<button
							className='btn btn-link mr-3'
							onClick={() => {
								this.props.onClickBack()
							}}
						>
							Back
						</button>
						<button
							className='btn btn-primary'
							onClick={() => {
								this.props.onClickFinish()
							}}
						>
							Finish
						</button>
					</div>
				</div>
			</>
		)
	}
}

/**
 * Type of the props used in the component
 */
ApplyTags.propTypes = {
	onClickFinish: PropTypes.func,
	onClickBack: PropTypes.func,
	tagGroupConditions: PropTypes.array,
	listAllProviders: PropTypes.func,
	listAllServices: PropTypes.func,
	listAllRegions: PropTypes.func,
	listAllAccounts: PropTypes.func,
	listAllAssets: PropTypes.func,
	applyTag: PropTypes.func,
	editTagGroupDetails: PropTypes.object,
	setEditTagGroupDetails: PropTypes.func,
	tagGroupId: PropTypes.string,
	assets: PropTypes.array,
}
const mapStateToProps = state => {
	return {
		editTagGroupDetails: state.governance.editTagGroupDetails,
		editRuleDetails: state.governance.editRuleDetails,
		tagGroupId: state.governance.tagGroupId,
		tagGroupConditions: state.governance.tagGroupConditions,
		assets: state.assets.assets.results,
	}
}
export default connect(mapStateToProps, {
	listAllProviders,
	listAllServices,
	listAllRegions,
	listAllAccounts,
	setEditTagGroupDetails,
	listAllAssets,
	applyTag,
})(ApplyTags)
