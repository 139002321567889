/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file QueryResults.js
 * @author Prakash // on 23/03/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { Spinner, UncontrolledTooltip } from 'reactstrap'

import { getDayFromSelectedDuration, momentDateGivenFormat, momentConvertionUtcToLocalTime, momentConvertionLocalToUtcTime, currentLocaltime, subHours, subDays, twoDateDiffrence, capitalizeTheFirstLetterOfEachWord, alphaNumeric } from '../../../utils/utility'

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import Search from '../../common/SearchComponent'
import { startQueryExection, getQueryResult, setFavouriteQuery, unsetFavouriteQuery } from '../../../actions/QueryAction'
import { dynamicCallApi } from '../../../actions/DynamicLoadAction'


import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import _ from 'lodash'

import { store as CommonNotification } from 'react-notifications-component';

import Chart from 'react-apexcharts'
import { CSVLink } from "react-csv";

const durationOptions = [
	{ value: '+1h', option: 'Last 1 hour' },
    { value: '+3h', option: 'Last 3 hours' }, 
    { value: '+6h', option: 'Last 6 hours' },
    { value: '+12h', option: 'Last 12 hours' },
	{ value: '+24h', option: 'Last 24 hours' },
	{ value: '+3d', option: 'Last 3 days' },
	{ value: '+7d', option: 'Last 1 week' },
	{ value: '+14d', option: 'Last 2 weeks' },
	{ value: '+30d', option: 'Last 1 month' },
	{ value: 'custom', option: 'Custom Date' },
]

const commonFields = ["severity", "event_time", "importance", "event_id", "description", "message", "provider", "event_source", "event_name", "asset_name", "account_id", "region", "resource_type", "service_name", "additional_details", "assets", "event_analysis"]

const queryDateTime = current => {
	return current.isBefore(currentLocaltime()) && current.isAfter(subDays(currentLocaltime(), 90))
}

// const jumpToInputProps = {
//     placeholder: 'Jump To',
//     className: 'form-control-sm bg-black5 border-gray5 text-white cursorPointer mr-4',
// };

// const disableFutureDt = current => {
//     return current.isBefore(currentLocaltime())
// }


let timer = null;

class QueryResults extends Component {
    constructor(props) {
        super(props)

        this.state = {
            startRecord: 0, 
            currentPage: 1,
            totalPages: 1,
            overAllTotalPages: 1,
            perPage: 100,

            accounts: [],
            regions: [],
            selectedQueryAccount: [], 
            selectedQueryRegion: [], 
            selectedQueryDuration: ""
        }
    }

	handleStartTimeChange  = this.handleStartTimeChange.bind(this)
    handleEndTimeChange    = this.handleEndTimeChange.bind(this)

    componentDidMount = () => {
        this.setState({ 
            selectedQuery: this.props.selectedQueryDetails && this.props.selectedQueryDetails.selectedQuery ? this.props.selectedQueryDetails.selectedQuery : {},
            selectedInput: this.props.selectedQueryDetails && this.props.selectedQueryDetails.params ? this.props.selectedQueryDetails.params : {},
            querybuilder: this.props.selectedQueryDetails && this.props.selectedQueryDetails.querybuilder ? this.props.selectedQueryDetails.querybuilder : [],
            queryStartTime: momentConvertionUtcToLocalTime(this.props.selectedQueryDetails.params.start_time, "YYYY-MM-DD HH:mm:00"),
            queryEndTime: momentConvertionUtcToLocalTime(this.props.selectedQueryDetails.params.end_time, "YYYY-MM-DD HH:mm:00"),
            // durationStartTime: momentConvertionUtcToLocalTime(this.props.selectedQueryDetails.params.start_time, "YYYY-MM-DD HH:mm:00"),
            // durationEndTime: momentConvertionUtcToLocalTime(this.props.selectedQueryDetails.params.start_time, "YYYY-MM-DD HH:mm:00"),
        },
            () => this.processSelectedInput()
        )

        this.structureQueryResults()
        this.filterData()
    }

	filterData = () => {
		
		let params = {}
		params.provider = this.props.selectedProvider.toLowerCase()
		if(this.props.accounts && this.props.accounts.length) {
			let allAccounts = this.props.accounts.filter(account => account.provider === this.props.selectedProvider.toLowerCase())
			if(this.props.accounts.length) {
				let accRow = {}
				accRow.account_id = "All"
				accRow.account_name = "All"
				accRow.date = null
				accRow.disabled = false
				accRow.provider = "All"
				allAccounts.unshift(accRow);
			}
			this.setState({ accounts: allAccounts })
		} else {
			this.props.listAllAccounts(params, (promise, allAccounts) => {
				if (promise) {						
					let filterdAccounts = allAccounts.filter(account => account.provider === this.props.selectedProvider.toLowerCase())
					if(filterdAccounts.length) {
						let accRow = {}
						accRow.account_id = "All"
						accRow.account_name = "All"
						accRow.date = null
						accRow.disabled = false
						accRow.provider = "All"
						filterdAccounts.unshift(accRow);
					}						
					this.setState({ accounts: filterdAccounts })
				}
			})
		}

		if(this.props.regions && this.props.regions.length) {
			let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
			this.setState({ regions: providerRegions })
		} else {
			this.props.listAllRegions(params, (promise, regions) => {
				if (promise) {
					let providerRegions = regions && regions[0] && regions[0].provider_regions
					if(providerRegions) {
						let provider_regions_row = {}
						provider_regions_row.availability_zones = []
						provider_regions_row.city = "All"
						provider_regions_row.country = "All"
						provider_regions_row.geography = "All"
						provider_regions_row.name = "All"
						provider_regions_row.region = "All"
						provider_regions_row.region_id = "All"
						provider_regions_row.state = "All"
						providerRegions.unshift(provider_regions_row);
					}
					this.setState({ regions: providerRegions })
				} else {
					this.setState({ regions: [] })
				}
			})
		}
	}

    processSelectedInput = () => {
        if(this.state.selectedQuery.display_output && Object.entries(this.state.selectedQuery.display_output).length) {
            Object.values(this.state.selectedQuery.display_output).forEach((item, i) => {
                this.setState({ [item.output_field]: this.state.selectedInput[item.output_field] },
                    () => {
                        if(i === Object.entries(this.state.selectedQuery.display_output).length - 1) {
                            this.querySelection()
                        }
                    }    
                )
            })
            
        }

        if(this.state.selectedQuery.required_fields && this.state.selectedQuery.required_fields.length) {
            if(this.state.selectedQuery.required_fields.includes('account_id')){
                this.setState({ selectedQueryAccount: this.state.selectedInput.account_id && this.state.selectedInput.account_id !== "ALL" ? this.state.selectedInput.account_id : [] })
            }
            if(this.state.selectedQuery.required_fields.includes('region')){
                this.setState({ selectedQueryRegion: this.state.selectedInput.region && this.state.selectedInput.region !== "ALL" ? this.state.selectedInput.region : [] })
            }
            if(this.state.selectedQuery.required_fields.includes('duration')){
                let duration = this.props.selectedQueryDetails.duration
                this.setState({ 
                    selectedQueryDuration: this.props.selectedQueryDetails.duration,
                    showQueryDateTimePicker: duration === "custom" ? true : false
                })
            }
        }        
    }

	queryDurationFunction = (type) => {
		let duration = this.state.selectedQueryDuration
		let period = duration.substring(duration.length, duration.length-1)
		let durationBtw = getDayFromSelectedDuration(duration)
		let startTime = ""
		if(period === "d") {
			startTime = momentDateGivenFormat(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
		} else {
			startTime = momentDateGivenFormat(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
		}
		
		let endTime = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')

		this.setState({ queryStartTime: startTime, queryEndTime: endTime })
	}
	
	handleStartTimeChange(date) {
		let queryStartTime = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
		let queryEndTime = this.state.queryEndTime
		if(new Date(queryStartTime).getTime() > new Date(queryEndTime).getTime() ) {
			queryEndTime = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')
		}
		this.setState({ queryStartTime, queryEndTime})
	}

	handleEndTimeChange(date) {
		let queryStartTime = this.state.queryStartTime
		let queryEndTime = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
		if(new Date(queryEndTime).getTime() > new Date(queryStartTime).getTime() ) {
			this.setState({ queryEndTime})
		}
	}

    componentDidUpdate = (prevProps, prevState) => {
        if(!_.isEqual(prevProps.observabilityQueryResult, this.props.observabilityQueryResult)) {
            this.setState({ brushSeries: [], optionsBrush: {}, brushStartTime: "", brushEndTime: "", brushTime: false },
                () => this.structureQueryResults()
            )
        } else if(!_.isEqual(prevState.queryStartTime, this.state.queryStartTime) || !_.isEqual(prevState.queryEndTime, this.state.queryEndTime)) {
            this.setState({ brushSeries: [], optionsBrush: {}, brushStartTime: "", brushEndTime: "", brushTime: false },
                () => this.structureQueryResults()
            )
        }

        if(!_.isEqual(prevProps.selectedQueryDetails, this.props.selectedQueryDetails)) {
            this.setState({ 
                selectedQuery: this.props.selectedQueryDetails && this.props.selectedQueryDetails.selectedQuery ? this.props.selectedQueryDetails.selectedQuery : {},
                selectedInput: this.props.selectedQueryDetails && this.props.selectedQueryDetails.params ? this.props.selectedQueryDetails.params : {},
                querybuilder: this.props.selectedQueryDetails && this.props.selectedQueryDetails.querybuilder ? this.props.selectedQueryDetails.querybuilder : [],
                queryStartTime: momentConvertionUtcToLocalTime(this.props.selectedQueryDetails.params.start_time, "YYYY-MM-DD HH:mm:00"),
                queryEndTime: momentConvertionUtcToLocalTime(this.props.selectedQueryDetails.params.end_time, "YYYY-MM-DD HH:mm:00"),
                // durationStartTime: momentConvertionUtcToLocalTime(this.props.selectedQueryDetails.params.start_time, "YYYY-MM-DD HH:mm:00"),
                // durationEndTime: momentConvertionUtcToLocalTime(this.props.selectedQueryDetails.params.start_time, "YYYY-MM-DD HH:mm:00"),
            },
                () => this.processSelectedInput()
            )
        }
    }

    navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = this.state.startRecord
            if(action === 'next' && currentPage !== this.state.totalPages) {
                startRecord = startRecord + this.state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - this.state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = this.state.totalPages
                startRecord = (this.state.totalPages - 1) * this.state.perPage
            }
            this.setState({ currentPage, startRecord })
        } 
    }

    gotoPage = (pageNumber) => {
        if(pageNumber > 0  && pageNumber <= this.state.totalPages) {
            let startRecord = (pageNumber - 1) * this.state.perPage
            this.setState({ currentPage: pageNumber, pageNumber, startRecord })
        } else  if(pageNumber > this.state.totalPages) {
            this.setState({ pageNumber: this.state.currentPage })  
        } else {
            this.setState({ pageNumber })
        }
    }

    structureQueryResults = () => {
        //  let listAllQueries = this.props.queryResult
        //  let listQueryLabel = this.props.queryResult.labels
        let listAllQueries = this.props.observabilityQueryResult && this.props.observabilityQueryResult.results ? this.props.observabilityQueryResult.results : []

        let listQueryLabel = this.props.observabilityQueryResult && this.props.observabilityQueryResult.labels ? this.props.observabilityQueryResult.labels : {}

        let totalPages = 1
        if(listAllQueries.length > this.state.perPage) {
            totalPages = Math.ceil(listAllQueries.length / this.state.perPage)
        }

        this.setState({ listAllQueries, selectedPeriodData: listAllQueries, filteredArray: listAllQueries, totalPages, listQueryLabel },
        () => {
            if(this.state.listAllQueries.filter(e => e.event_time).length) {
                this.brushOptions()
            }
        })
    }    

	querySelection = () => {
        let item = this.state.selectedQuery
        if(item && item.display_output && Object.entries(item.display_output).length) {
            Object.values(item.display_output).forEach(out => {
                // if(out.default) {
                //     this.setState({ [out.output_field]: item.default })
                // }
                if(out.api_details && Object.entries(out.api_details).length) {
                    let apiDetails = out.api_details
                    let params = apiDetails.api_request
                    if(apiDetails.additional_fields && apiDetails.additional_fields) {
                        apiDetails.additional_fields.forEach(addFields => {
                            if(addFields === "account_id" && this.state.selectedAccount && this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
                                params.account_id = this.state.selectedAccount
                            }
                            if(addFields === "regions" && this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
                                params.region = this.state.selectedRegion
                            }
                        })
                    }
                    let manditoryFieldsFilled = true
                    if(apiDetails.mandatory_fields && apiDetails.mandatory_fields.length) {
                        apiDetails.mandatory_fields.forEach(manFields => {
                            if(!this.state[manFields] || this.state[manFields] === "" || (Array.isArray(this.state[manFields]) && !this.state[manFields].length)) {
                                manditoryFieldsFilled = false
                            } else {
                                params[manFields] = this.state[manFields]
                            }
                        })
                    }
                    if(manditoryFieldsFilled) {
                        this.dynamicCallApi(params, item, apiDetails, out.output_field, out.output_type)
                    }
                } else if(out.type === "dropdown" && out.output_type === "list") {
                    let options = ["All"]
                    options =  options.concat(out.values)
                    this.setState({ ["drop_options_"+out.output_field]: options },
                        () => this.handleMultiSelectChange(out.output_field, this.state[out.output_field], options)
                    )
                }
            })
        }
	}

    handleJumpTo = (date) => {
        let jumpDate = momentConvertionLocalToUtcTime(date, 'YYYY-MM-DD HH:mm:ss')
        this.setState({ jumpDate })
    }

    jumpToQueryResult = () => {
        let data = this.state.listAllQueries
        if(data.length && data[0].hasOwnProperty("event_time")) {
            data = data.filter(e => momentDateGivenFormat(e.event_time, "YYYY-MM-DD HH:mm:ss") >= this.state.jumpDate)
        }
        let totalPages = 1
        if(data.length > this.state.perPage) {
            totalPages = Math.ceil(data.length / this.state.perPage)
        }

        this.setState({ filteredArray: data, totalPages })
    }

    brushOptions = () => {
        let brushSeries = []
        let brushRow = {}
        brushRow.data = [
            {x: momentDateGivenFormat(this.state.queryStartTime, 'YYYY-MM-DD HH:mm'), y: 0},
            {x: momentDateGivenFormat(this.state.queryEndTime, 'YYYY-MM-DD HH:mm'), y: 0}
        ]
        brushSeries.push(brushRow)

        let endTIme = momentDateGivenFormat(this.state.queryEndTime, 'YYYY-MM-DD HH:mm:ss')
        //endTIme = this.state.jumpDate !== "" ? this.state.jumpDate : endTIme

        let brushHours = 2
        let diff = twoDateDiffrence(this.state.queryEndTime, this.state.queryStartTime)
        if(diff > 1 && diff <= 3) {            
            brushHours = 6
        } else if(diff > 3 && diff <= 7) {
            brushHours = 24 //3
        } else if(diff > 7 && diff <= 14) {
            brushHours = 36 //6
        } else if(diff > 14 && diff <= 30) {
            brushHours = 48 //12
        } else if(diff > 30) {
            brushHours = 96 //24
        }

        let brushStartTime = subHours(endTIme, brushHours)
        let brushEndTime = endTIme

        let optionsBrush = {
            chart: {
                background: '#fff',
                id: 'chart1',
                type: 'line',
                sparkline: {
                    enabled: true
                },
                zoom: {
                    enabled: false,
                },
                brush:{
                    // targets: ['bar1', 'bar2'],
                    target: "",
                    enabled: true,
                    autoScaleYaxis: true,
                },
                selection: {
                    enabled: true,
                    fill: {
                        color: "#039BE5",
                        opacity: 1,
                    },
                    xaxis: {
                        min: new Date(momentDateGivenFormat(brushStartTime, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                        max: new Date(momentDateGivenFormat(brushEndTime, 'YYYY-MM-DD HH:mm:ss')).getTime()
                    }
                },
                events: {
                    selection: (chartContext, { xaxis, yaxis }) => {
                        this.brushSelection(xaxis)
                    }
                }
            },
            markers: {
                size: 0
            },
            colors: ['#F44336'],
            fill: {
                type: 'solid',
                color: "#000",
                opacity: 1,
            },
            xaxis: {
                type: 'datetime',
                tooltip: {
                    enabled: false
                }
            },
            yaxis: {
                show: false,
                tickAmount: 2
            },
            annotations: {
                yaxis: [
                {
                    y: 1,
                    borderColor: '#000',
                    label: {
                    borderColor: '#00E396',
                    style: {
                        color: '#fff',
                        background: '#00E396'
                    },
                    text: ""
                    }
                }
                ]
            }
        }

        this.setState({ brushSeries, optionsBrush, jumpDate: "" })
    }

    brushSelection = (xaxis, type) => {
        clearTimeout(timer)
        let brushStartTime = momentDateGivenFormat(xaxis.min, 'YYYY-MM-DD HH:mm:ss')
        let brushEndTime = momentDateGivenFormat(xaxis.max, 'YYYY-MM-DD HH:mm:ss')       

        if(this.state.brushStartTime && this.state.brushStartTime !== "" && this.state.brushEndTime !== this.state.queryEndTime) {
            timer = setTimeout(() => this.filterQueryReuslts(brushStartTime, brushEndTime, true), 1000)
        } else {
            this.filterQueryReuslts(this.state.queryStartTime, this.state.queryEndTime, false)
        }
        
        if(brushStartTime && brushEndTime) {
            this.setState({ brushStartTime, brushEndTime })
        }
    }
        
    filterQueryReuslts = (startTime, endTime, brushTime) => {
        let filteredArray = this.state.listAllQueries
        if(filteredArray.filter(e => e.event_time).length) {
            filteredArray = filteredArray.filter(e => e.event_time >= momentConvertionLocalToUtcTime(startTime, 'YYYY-MM-DD HH:mm:ss') && e.event_time <= momentConvertionLocalToUtcTime(endTime, 'YYYY-MM-DD HH:mm:ss'))
        }

        let totalPages = 1
        if(filteredArray.length > this.state.perPage) {
            totalPages = Math.ceil(filteredArray.length / this.state.perPage)
        }

        this.setState({ selectedPeriodData: filteredArray, filteredArray, totalPages, brushTime },
            () => {
                if(this.state.filteredArray && this.state.filteredArray.length) {
                    this.formDownloadData()    
                }
            }
        )
    }

    formDownloadData = () => {
        let headers = [] 
        Object.keys(this.state.filteredArray[0]).forEach(item => {
            let dataRow = {}
            dataRow.label = capitalizeTheFirstLetterOfEachWord(item)
            dataRow.key = item
            headers.push(dataRow)
        })

        this.setState({ headers })
    }
	
	handleMultiSelectChange = (field, arrayValue, state) => {
		let value = arrayValue.map(item => item.value ? item.value : item)
		let selectedValue = []
		if(field === 'selectedAccount' || field === "selectedQueryAccount") {
			let prevState = this.state[field] ? this.state[field] : []
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.accounts.forEach(acc => {
						selectedValue.push(acc.account_id)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.accounts.length -1)) {
					selectedValue.push('All')
				}
			}	
			this.setState({ [field]: selectedValue })
		} else if(field === 'selectedRegion' || field === "selectedQueryRegion") {
			let prevState = this.state[field] ? this.state[field] : []
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.regions.length -1)) {
					selectedValue.push('All')
				}
			}
			this.setState({ [field]: selectedValue })
		} else {
			//for array		
			if(state) {
				let prevState = this.state[field] ? this.state[field] : []
                if(prevState.length === 1 && prevState[0] === "All") {
                    prevState = []
                }
				if(value.includes('All')) {
					if(!prevState.includes('All')) {
						if(state.filter(e => e.value).length) {
							selectedValue = state.map(e => e.value)
						} else {
							selectedValue = state
						}
					} else {
						const index = value.indexOf('All');
						if (index > -1) {
							value.splice(index, 1);
						}
						selectedValue = value
					}
				} else if(!prevState.includes('All')) {
					selectedValue = value
				}
	
				if(selectedValue.length && !selectedValue.includes('All')) {
					if(!selectedValue.includes('All') && selectedValue.length === (state.length -1)) {
						selectedValue.push('All')
					}
				}
			} else {
				selectedValue = value
			}

			this.setState({ [field]: selectedValue })
		}
	}

    getMultiSelectedCount = (type, array) => {
        return array.length && array.includes('All') ? 'All' : (array.length ? array.length +' Selected' : 'All')
    }

	getMultiSelectedCountNew = (type, array) => {
		return array.length && array.includes('All') ? 'All' : (array.length ? array.length +' Selected' : <span className="placeholder w-100">Select</span>)
	}


	executeQuery = () => {
		let selectedQuery  = this.state.selectedQuery
		let hasError = false

		let params = {}
		params.query_id = selectedQuery.query_id
		params.selectedCategory = selectedQuery.category
		if(selectedQuery.required_fields && selectedQuery.required_fields.length) {
			selectedQuery.required_fields.forEach(item => {
				if(item === "account_id" && this.state.selectedQueryAccount && this.state.selectedQueryAccount.length && !this.state.selectedQueryAccount.includes("All")) {
					// params.account_id = this.state.selectedQueryAccount.filter(e => e !== "All")
					params.account_id = this.state.selectedQueryAccount
				} else if(item === "account_id")  {
					params.account_id = "ALL"
				}
				if(item === "region" && this.state.selectedQueryRegion && this.state.selectedQueryRegion.length && !this.state.selectedQueryRegion.includes("All")) {
					params.region = this.state.selectedQueryRegion
				} else if(item === "region")  {
					params.region = "ALL"
				}
				if(item === "duration") {
					params.start_time = momentConvertionLocalToUtcTime(this.state.queryStartTime, "YYYY-MM-DD HH:mm:00")
					params.end_time = momentConvertionLocalToUtcTime(this.state.queryEndTime, "YYYY-MM-DD HH:mm:00")
				}
			})
		}

		if(selectedQuery.display_output && Object.entries(selectedQuery.display_output).length) {
			Object.entries(selectedQuery.display_output).forEach(([key, value]) => {
				if(value.type === "text") {                    
					if(value.output_type === "string") {
						if(!this.state[value.output_field] || this.state[value.output_field] === "") {
							hasError = true
						} else if(value.validation && Object.entries(value.validation).length) {
							let validation = value.validation
							if(validation.min && this.state[value.output_field].length < validation.min) {
								hasError = true
								this.setState({ ['error_message_'+value.output_field]: 'field should be greater than '+ validation.min +' and lesser than '+ validation.max })
							} else if(validation.max && this.state[value.output_field].length > validation.max) {
								hasError = true
								this.setState({ ['error_message_'+value.output_field]: 'field should be greater than '+ validation.min +' and lesser than '+ validation.max })
							} else {
								this.setState({ ['error_message_'+value.output_field]: "" })
							}
						} else {
							params[value.output_field] = this.state[value.output_field]
						}
					} else if(value.output_type === "list") {
						if(!this.state[value.output_field] || !this.state[value.output_field].length) {
							hasError = true
						} else {
							if(value.api_details && value.include_all) {
								if(this.state[value.output_field] && this.state[value.output_field].includes("All")) {
									params[value.output_field] = ["All"]
								} else {
									params[value.output_field] = this.state[value.output_field]
								}
							} else {

                                if(this.state[value.output_field].length > 1 && this.state[value.output_field].includes("All")) {
                                    let data = this.state[value.output_field]
                                    params[value.output_field] = data.filter(e => e !== 'All')
                                } else {
                                    params[value.output_field] = this.state[value.output_field]
                                }
                            }
						}
					}
				} else {
					let output = this.state[value.output_field]
					// if(value.output_type === "list") {
					// 	output = this.state[value.output_field]
					// }
                    if(value.output_type === "list") {
                        if(!output || !output.length ) {
                            hasError = true
                        }
                    } else {
                        if(!value || value === "") {
                            hasError = true
                        }
                    }
					if(output.includes("All") && value.output_type === "list") {
						if(value.include_all) {
							output = ["All"]
						} else {
							output = output.filter(e => e !== 'All')
						}
					}
					params[value.output_field] = output
				}
			})
		}

        // console.log("params", params)        

		this.setState({ hasError })

		if(!hasError) {

			if(selectedQuery.async) {
				this.setState({ queryLoading: true },
					() => this.startQueryExection(params)					
				)
			} else {
				this.setState({ queryLoading: true },
					() => this.getQueryResult(params)
				)
			}
		}
	}

	startQueryExection = (params) => {
		this.props.startQueryExection(params, (promise, response) => {
			if(promise) {
				if(response.status === "FAILED") {
					this.setState({ queryErrorMessage: response, queryLoading: false })
				} else {
					this.getQueryResult(response)
				}
			} else {
				this.setState({ queryErrorMessage: response, queryLoading: false })	
			}
		})
	}

	getQueryResult = (params) => {
		this.props.getQueryResult(params, (promise, response) => {
			if(promise) {
				if(response.status === "RUNNING") {
					setTimeout(() => { this.getQueryResult(response) }, 3000)
				} else if(response.status === "FAILED") {
					this.setState({ queryErrorMessage: response, queryLoading: false },
                        () => this.structureQueryResults()    
                    )
				} else {
					setTimeout(() => {
						this.setState({ queryResult: response, queryLoading: false })
					}, 2000)
				}
			} else {
				this.setState({ queryErrorMessage: response, queryLoading: false })	
			}
		})
	}	

	onKeyDown = (e, field, stateField) => {
		let array = this.state[stateField] && Array.isArray(this.state[stateField]) ? this.state[stateField] : []
		//|| e.keyCode === 188
		if (e.keyCode === 13 || e.keyCode === 9) {
			if(this.state[field] && this.state[field] !== "") {
				array.push(this.state[field].trim())
				if(e.keyCode === 9) {
					e.preventDefault();
				}
				this.setState({ [stateField]: array, [field]: "" })
			}
		}
	}

	onBlur = (e, field, stateField) => {
		let array = this.state[stateField] ? this.state[stateField] : []
		if(this.state[field] && this.state[field] !== "") {
			array.push(this.state[field].trim())
			this.setState({ [stateField]: array, [field]: "" })
		}
	}

	removeSelectedBadge = (field, value, index) => {
		let filteredReslt = this.state[field]
		filteredReslt.splice(index, 1);
		this.setState({ [field]: filteredReslt })
	}

	onChangeTextString = (outputStructure, field, value) => {
		let hasError = false
		let validation = outputStructure.validation
		if(validation) {
			if(validation.min && value.length < validation.min) {
				hasError = true
				this.setState({ ['error_message_'+field]: 'field should be greater than '+ validation.min +' and lesser than '+ validation.max })
			} else if(validation.max && value.length > validation.max) {
				hasError = true
				this.setState({ ['error_message_'+field]: 'field should be greater than '+ validation.min +' and lesser than '+ validation.max })
			} else {
				this.setState({ ['error_message_'+field]: "" })
			}
			if(validation.data_type) {
				if(validation.data_type === "alphanumeric") {
					value = alphaNumeric(value)
				}
			}
		}
		
		this.setState({ [field]: value, hasError })
	}

	onChangeTextDropDown = (displayOutput, changedKey) => {
		let selectedQuery = this.state.selectedQuery
		let keysToReload = displayOutput[changedKey].api_details.keys_to_reload
		if(keysToReload && keysToReload.length) {
			keysToReload.forEach(item => {
				let out = displayOutput[item]
				// if(out.default) {
				// 	this.setState({ [out.output_field]: selectedQuery.default })
				// }
				if(out.api_details && Object.entries(out.api_details).length) {
					let apiDetails = out.api_details
					let params = apiDetails.api_request
					if(apiDetails.additional_fields && apiDetails.additional_fields) {
						apiDetails.additional_fields.forEach(addFields => {
							if(addFields === "account_id" && this.state.selectedAccount && this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
								params.account_id = this.state.selectedAccount
							}
							if(addFields === "regions" && this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
								params.region = this.state.selectedRegion
							}
						})
					}
					let manditoryFieldsFilled = true
					if(apiDetails.mandatory_fields && apiDetails.mandatory_fields.length) {
						apiDetails.mandatory_fields.forEach(manFields => {
							if(!this.state[manFields] || this.state[manFields] === "" || (Array.isArray(this.state[manFields]) && !this.state[manFields].length)) {
								manditoryFieldsFilled = false
							} else {
								params[manFields] = this.state[manFields]
							}
						})
					}
					if(manditoryFieldsFilled) {
                        this.setState({ [out.output_field]: out.output_type === "text" ? "" : [] },
							() =>this.dynamicCallApi(params, selectedQuery, apiDetails, out.output_field, out.output_type)
						)
					}
				}
			})
		}
	}

    dynamicCallApi = (params, selectedQuery, apiDetails, outputField, outputType) => {
		this.props.dynamicCallApi(apiDetails.api_name, apiDetails.api_resource, params, (promise, response) => {
			if(promise) {
				let options = []
				if(outputType === "list") {
					let dataRow = {}
					dataRow.key = "All"
					dataRow.value = "All"
					options.push(dataRow)
				}
				if(response.results) {
					let results = response.results
					results.forEach(res => {
						if(apiDetails.field_name === "id_name_map") {
							options.push(res[apiDetails.field_name])
						} else {
							let dataRow = {}
							dataRow.key = res[apiDetails.field_name]
							dataRow.value = res[apiDetails.field_name]
							options.push(dataRow)
						}
					})
				}
				this.setState({ ["options_"+outputField]: options },
                    () => {
                        if(outputType === "list") {
                            this.handleMultiSelectChange(outputField, this.state[outputField], this.state["options_"+outputField])
                        }
                    }
                )
			}
		})
	}

    render() {
        return (
            <div className="">
                <div className="rounded bg-dark p-3">
                    <h6 className="text-white ">Query Details</h6>
                    <p className="mb-0">{this.props.selectedQueryDetails.selectedQuery.query_id +" - "+ (this.props.selectedQueryDetails.selectedQuery.title ? this.props.selectedQueryDetails.selectedQuery.title : "")}</p>

                    {this.state.querybuilder ?
                        <React.Fragment><div className={`form-group dlpDescriptionForm dlpDescriptionFormPlain bg-dark rounded mb-2`}>
                            <div className={`d-flex flex-wrap ${this.state.queryLoading ? "disabled" : ""}`}>
                                {this.state.querybuilder ?
                                    <React.Fragment>
                                        <p className={`mb-0 mr-2 align-self-end text-white`}>Query:</p>
                                        {this.state.querybuilder.map((qry, i) => {
                                            return(
                                                !qry.input ?
                                                    <p className={`mb-0 mr-2 align-self-end text-info`}>{qry.string}</p>
                                                :
                                                    <React.Fragment>
                                                        {this.state.selectedQuery.display_output && this.state.selectedQuery.display_output[qry.field] && this.state.selectedQuery.display_output[qry.field].type === "text" ?
                                                            this.state.selectedQuery.display_output[qry.field].output_type === "string" ?
                                                                <React.Fragment>
                                                                {this.state.selectedQuery.display_output[qry.field].api_details && Object.entries(this.state.selectedQuery.display_output[qry.field].api_details).length ?
                                                                    <Select
                                                                        placeholder={'Select'}
                                                                        isSearchable={true}
                                                                        components={{
                                                                            IndicatorSeparator: () => null
                                                                        }}
                                                                        className={`selectOption mr-2 mt-3 ${this.state.hasError && (!this.state[this.state.selectedQuery.display_output[qry.field].output_field] || this.state[this.state.selectedQuery.display_output[qry.field].output_field] === "") ? 'dangerSelectLine' : ''}`}
                                                                        value={({
                                                                            value: this.state[this.state.selectedQuery.display_output[qry.field].output_field] && this.state[this.state.selectedQuery.display_output[qry.field].output_field] !== "" ? this.state[this.state.selectedQuery.display_output[qry.field].output_field] : 'Select',
                                                                            label: this.state[this.state.selectedQuery.display_output[qry.field].output_field] && this.state[this.state.selectedQuery.display_output[qry.field].output_field] !== "" ? this.state[this.state.selectedQuery.display_output[qry.field].output_field] : <span className="placeholder">Select</span>
                                                                        })}		
                                                                        options={this.state["options_"+this.state.selectedQuery.display_output[qry.field].output_field] && this.state["options_"+this.state.selectedQuery.display_output[qry.field].output_field].map(res => ({
                                                                            value: res.value,
                                                                            label: res.value+(res.value !== res.key ? " ("+res.key+")" : "")
                                                                        }))}															
                                                                        onChange={event => this.setState({ [this.state.selectedQuery.display_output[qry.field].output_field]: event.value }, () => this.onChangeTextDropDown(this.state.selectedQuery.display_output, qry.field))}
                                                                    />
                                                                :
                                                                    <React.Fragment>
                                                                    <input 
                                                                        type="text" 
                                                                        className={`inputField mt-3 w-30 ${this.state.hasError && (!this.state[this.state.selectedQuery.display_output[qry.field].output_field] || this.state[this.state.selectedQuery.display_output[qry.field].output_field] === "" || this.state["error_message_"+this.state.selectedQuery.display_output[qry.field].output_field] !== "")  ? 'dottedDangerTextboxLine' : ''}`}
                                                                        placeholder={qry.field}
                                                                        value={this.state[this.state.selectedQuery.display_output[qry.field].output_field] ? this.state[this.state.selectedQuery.display_output[qry.field].output_field] : ''}
                                                                        onChange={e => this.onChangeTextString(this.state.selectedQuery.display_output[qry.field], this.state.selectedQuery.display_output[qry.field].output_field, e.target.value)}
                                                                    />
                                                                    {this.state.selectedQuery.display_output[qry.field].validation ?
                                                                        <React.Fragment>
                                                                        <i id={this.state.selectedQuery.display_output[qry.field].output_field} className="align-self-end fas fa-info-circle ml-2 mt-3 cursorPointer"></i>
                                                                        <UncontrolledTooltip target={this.state.selectedQuery.display_output[qry.field].output_field}>
                                                                        <div className="uncontrolledTooltipLeftAlign">
                                                                            {this.state.selectedQuery.display_output[qry.field].validation.data_type ?
                                                                                <p className="mb-1 text-primary-color">
                                                                                    <span className="text-white mr-1">Data Type:</span>
                                                                                    {this.state.selectedQuery.display_output[qry.field].validation.data_type}
                                                                                </p>
                                                                            : null}
                                                                            {this.state.selectedQuery.display_output[qry.field].validation.min ?
                                                                                <p className="mb-1 text-primary-color">
                                                                                    <span className="text-white mr-1">Min length:</span>
                                                                                    {this.state.selectedQuery.display_output[qry.field].validation.min}
                                                                                </p>
                                                                            : null}
                                                                            {this.state.selectedQuery.display_output[qry.field].validation.max ?
                                                                                <p className="mb-1 text-primary-color">
                                                                                    <span className="text-white mr-1">Max length:</span>
                                                                                    {this.state.selectedQuery.display_output[qry.field].validation.max}
                                                                                </p>
                                                                            : null}
                                                                        </div>
                                                                        </UncontrolledTooltip>
                                                                        </React.Fragment>
                                                                    : null}
                                                                    </React.Fragment>
                                                                }
                                                                </React.Fragment>
                                                            : this.state.selectedQuery.display_output[qry.field].output_type === "list" ?
                                                                <React.Fragment>
                                                                {this.state.selectedQuery.display_output[qry.field].api_details && Object.entries(this.state.selectedQuery.display_output[qry.field].api_details).length ?
                                                                    <div className={`multiSelectOption align-self-end mt-3 ${this.state.hasError && (!this.state[this.state.selectedQuery.display_output[qry.field].output_field] || !this.state[this.state.selectedQuery.display_output[qry.field].output_field].length) ? 'dangerMultiSelectLine' : ''}`}>
                                                                        <ReactMultiSelectCheckboxes
                                                                            placeholderButtonLabel="Select"
                                                                            isSearchable={true}			
                                                                            getDropdownButtonLabel={() => this.getMultiSelectedCountNew(this.state.selectedQuery.display_output[qry.field].output_field, this.state[this.state.selectedQuery.display_output[qry.field].output_field] ? this.state[this.state.selectedQuery.display_output[qry.field].output_field] : [])}
                                                                            options={this.state["options_"+this.state.selectedQuery.display_output[qry.field].output_field] && this.state["options_"+this.state.selectedQuery.display_output[qry.field].output_field].map(res => ({
                                                                                value: res.value,
                                                                                label: res.value+(res.value !== res.key ? " ("+res.key+")" : "")
                                                                            }))}
                                                                            onChange={arr => { this.handleMultiSelectChange(this.state.selectedQuery.display_output[qry.field].output_field, arr ? arr : [], this.state["options_"+this.state.selectedQuery.display_output[qry.field].output_field]) }}
                                                                            value={this.state[this.state.selectedQuery.display_output[qry.field].output_field] && this.state[this.state.selectedQuery.display_output[qry.field].output_field].map(item => ({
                                                                                value: item
                                                                            }))}
                                                                        />
                                                                    </div>
                                                                :
                                                                    <React.Fragment>
                                                                    <input 
                                                                        type="text" 
                                                                        className={`inputField mt-3 w-30 ${this.state.hasError && (!this.state[this.state.selectedQuery.display_output[qry.field].output_field] || !this.state[this.state.selectedQuery.display_output[qry.field].output_field].length)  ? 'dottedDangerTextboxLine' : ''}`}
                                                                        placeholder={qry.field}
                                                                        value={this.state["listInput_"+this.state.selectedQuery.display_output[qry.field].output_field]}
                                                                        onKeyDown={e => this.onKeyDown(e, "listInput_"+this.state.selectedQuery.display_output[qry.field].output_field, this.state.selectedQuery.display_output[qry.field].output_field)}
                                                                        onBlur={e => this.onBlur(e, "listInput_"+this.state.selectedQuery.display_output[qry.field].output_field, this.state.selectedQuery.display_output[qry.field].output_field)}
                                                                        onChange={e => this.setState({ ["listInput_"+this.state.selectedQuery.display_output[qry.field].output_field]: e.target.value })}
                                                                    />
                                                                    {this.state.selectedQuery.display_output[qry.field].validation ?
                                                                        <React.Fragment>
                                                                        <i id={this.state.selectedQuery.display_output[qry.field].output_field} className="align-self-end fas fa-info-circle ml-2 mt-3 cursorPointer"></i>
                                                                        <UncontrolledTooltip target={this.state.selectedQuery.display_output[qry.field].output_field}>
                                                                        <div className="uncontrolledTooltipLeftAlign">
                                                                            {this.state.selectedQuery.display_output[qry.field].validation.data_type ?
                                                                                <p className="mb-1 text-primary-color">
                                                                                    <span className="text-white mr-1">Data Type:</span>
                                                                                    {this.state.selectedQuery.display_output[qry.field].validation.data_type}
                                                                                </p>
                                                                            : null}
                                                                            {this.state.selectedQuery.display_output[qry.field].validation.min ?
                                                                                <p className="mb-1 text-primary-color">
                                                                                    <span className="text-white mr-1">Min length:</span>
                                                                                    {this.state.selectedQuery.display_output[qry.field].validation.min}
                                                                                </p>
                                                                            : null}
                                                                            {this.state.selectedQuery.display_output[qry.field].validation.max ?
                                                                                <p className="mb-1 text-primary-color">
                                                                                    <span className="text-white mr-1">Max length:</span>
                                                                                    {this.state.selectedQuery.display_output[qry.field].validation.max}
                                                                                </p>
                                                                            : null}
                                                                        </div>
                                                                        </UncontrolledTooltip>
                                                                        </React.Fragment>
                                                                    : null}
                                                                    </React.Fragment>
                                                                }

                                                                {this.state.selectedQuery.display_output[qry.field].api_details && Object.entries(this.state.selectedQuery.display_output[qry.field].api_details).length ?
                                                                    this.state[this.state.selectedQuery.display_output[qry.field].output_field] && (!this.state[this.state.selectedQuery.display_output[qry.field].output_field].includes("All")) ? 
                                                                        this.state[this.state.selectedQuery.display_output[qry.field].output_field].map((tab, addIndex) => {
                                                                            return(
                                                                                <span key={addIndex} className={`badge-square badge-secondary f13 align-self-end p-1 ${!addIndex ? "ml-2" : "ml-1"} mr-1 mb-1`} >
                                                                                    {this.state["options_"+this.state.selectedQuery.display_output[qry.field].output_field] && this.state["options_"+this.state.selectedQuery.display_output[qry.field].output_field].map(res => {
                                                                                        return(
                                                                                        res.value === tab ?
                                                                                            res.value +(res.key !== res.value ? " ("+res.key+")" : "")
                                                                                        : null
                                                                                        )
                                                                                    })}
                                                                                    <i onClick={ () => this.removeSelectedBadge(this.state.selectedQuery.display_output[qry.field].output_field, tab, addIndex) } className="ml-1 fal fa-times cursorPointer"></i>
                                                                                </span>
                                                                            )
                                                                        })
                                                                    : this.state[this.state.selectedQuery.display_output[qry.field].output_field] && this.state[this.state.selectedQuery.display_output[qry.field].output_field].includes("All") ?
                                                                        <span className={`badge-square badge-secondary f13 align-self-end p-1 ml-2 mr-1 mb-1`} > All </span>
                                                                    : null
                                                                :
                                                                    this.state[this.state.selectedQuery.display_output[qry.field].output_field] && this.state[this.state.selectedQuery.display_output[qry.field].output_field].map((tab, addIndex) => {
                                                                        return(
                                                                            <span key={addIndex} className={`badge-square badge-secondary f13 align-self-end p-1 ${!addIndex ? "ml-2" : "ml-1"} mr-1 mb-1`} >
                                                                                {tab}
                                                                                <i onClick={ () => this.removeSelectedBadge(this.state.selectedQuery.display_output[qry.field].output_field, tab, addIndex) } className="ml-1 fal fa-times cursorPointer"></i>
                                                                            </span>
                                                                        )
                                                                    })
                                                                }
                                                                </React.Fragment>
                                                            : null
                                                        : this.state.selectedQuery.display_output && this.state.selectedQuery.display_output[qry.field] && this.state.selectedQuery.display_output[qry.field].type === "dropdown" ?
                                                            this.state.selectedQuery.display_output[qry.field].output_type === "list" ?
                                                                <div className={`multiSelectOption align-self-end mt-3 ${this.state.hasError && (!this.state[this.state.selectedQuery.display_output[qry.field].output_field] || !this.state[this.state.selectedQuery.display_output[qry.field].output_field].length) ? 'dangerMultiSelectLine' : ''}`}>
                                                                    <ReactMultiSelectCheckboxes
                                                                        placeholderButtonLabel="Select"
                                                                        isSearchable={true}			
                                                                        getDropdownButtonLabel={() => this.getMultiSelectedCountNew(this.state.selectedQuery.display_output[qry.field].output_field, this.state[this.state.selectedQuery.display_output[qry.field].output_field] ? this.state[this.state.selectedQuery.display_output[qry.field].output_field] : [])}
                                                                        options={this.state["drop_options_"+this.state.selectedQuery.display_output[qry.field].output_field] && this.state["drop_options_"+this.state.selectedQuery.display_output[qry.field].output_field].map(res => ({
                                                                            value: res,
                                                                            label: res,
                                                                        }))}
                                                                        onChange={arr => { this.handleMultiSelectChange(this.state.selectedQuery.display_output[qry.field].output_field, arr ? arr : [], this.state.selectedQuery.display_output[qry.field].values) }}
                                                                        value={this.state[this.state.selectedQuery.display_output[qry.field].output_field] && this.state[this.state.selectedQuery.display_output[qry.field].output_field].map(item => ({
                                                                            value: item
                                                                        }))}
                                                                    />
                                                                </div>
                                                            : this.state.selectedQuery.display_output[qry.field].output_type === "string" ? 
                                                                <Select
                                                                    placeholder={'Select'}
                                                                    isSearchable={false}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    className={`selectOption mr-2 mt-3 ${this.state.hasError && (!this.state[this.state.selectedQuery.display_output[qry.field].output_field] || this.state[this.state.selectedQuery.display_output[qry.field].output_field] === "") ? 'dangerSelectLine' : ''}`}
                                                                    value={({
                                                                        value: this.state[this.state.selectedQuery.display_output[qry.field].output_field] && this.state[this.state.selectedQuery.display_output[qry.field].output_field] !== "" ? this.state[this.state.selectedQuery.display_output[qry.field].output_field] : 'Select',
                                                                        label: this.state[this.state.selectedQuery.display_output[qry.field].output_field] && this.state[this.state.selectedQuery.display_output[qry.field].output_field] !== "" ? this.state[this.state.selectedQuery.display_output[qry.field].output_field] : <span className="placeholder">Select</span>
                                                                    })}
                                                                    options={this.state.selectedQuery.display_output[qry.field].values && this.state.selectedQuery.display_output[qry.field].values.map(item => ({
                                                                        value: item,
                                                                        label: item,	
                                                                    }))}															
                                                                    onChange={event => {
                                                                        this.setState({
                                                                            [this.state.selectedQuery.display_output[qry.field].output_field]: event.value
                                                                        })
                                                                    }}
                                                                />
        
                                                            : null
                                                        : null}
                                                    </React.Fragment>
                                                
                                            )
                                        })}

                                        {this.state.selectedQuery.required_fields ?
                                            <React.Fragment>
                                            {this.state.selectedQuery.required_fields.includes("account_id") ?
                                                <React.Fragment>
                                                <p className="mb-0 mx-2 align-self-end text-info">for Account</p>
                                                <div className={`multiSelectOption align-self-end mt-3`}>
                                                    <ReactMultiSelectCheckboxes
                                                        placeholderButtonLabel="Select"
                                                        isSearchable={true}														
                                                        getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedQueryAccount', this.state.selectedQueryAccount ? this.state.selectedQueryAccount : [])}
                                                        options={this.state.accounts && this.state.accounts.map(acc => ({
                                                            value: acc.account_id,
                                                            label: acc.account_name,
                                                        }))}
                                                        onChange={arr => { this.handleMultiSelectChange('selectedQueryAccount', arr ? arr : []) }}
                                                        value={this.state.selectedQueryAccount && this.state.selectedQueryAccount.map(item => ({
                                                            value: item
                                                        }))}
                                                    />
                                                </div>
                                                </React.Fragment>
                                            : null}
                                            {this.state.selectedQuery.required_fields.includes("region") ?
                                                <React.Fragment>
                                                <p className="mb-0 mx-2 align-self-end text-info">Region</p>
                                                <div className={`multiSelectOption align-self-end mt-3`}>
                                                    <ReactMultiSelectCheckboxes
                                                        placeholderButtonLabel="Select"														
                                                        getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedQueryRegion', this.state.selectedQueryRegion ? this.state.selectedQueryRegion : [])}
                                                        isSearchable={true}
                                                        options={this.state.regions && this.state.regions.map(reg => ({
                                                            value: reg.region,
                                                            label: reg.name,
                                                        }))}
                                                        onChange={arr => { this.handleMultiSelectChange('selectedQueryRegion', arr ? arr : []) }}
                                                        value={this.state.selectedQueryRegion && this.state.selectedQueryRegion.map(item => ({
                                                            value: item
                                                        }))}
                                                    />
                                                </div>
                                                </React.Fragment>
                                            : null}
                                            {this.state.selectedQuery.required_fields.includes("duration") ?
                                                <React.Fragment>
                                                <p className="mb-0 mx-2 align-self-end text-info">Duration</p>
                                                <div className="position-relative d-inline-block w-20">
                                                    <Select
                                                        placeholder={'Select'}
                                                        isSearchable={false}
                                                        components={{
                                                            IndicatorSeparator: () => null
                                                        }}
                                                        className={`selectOption mr-2 mt-3 ${this.state.hasError && !this.state.selectedQueryDuration ? 'dottedDangerSelectLine' : ''}`}
                                                        value={({
                                                            value: this.state.selectedQueryDuration && this.state.selectedQueryDuration !== "" ? this.state.selectedQueryDuration : 'Select',
                                                            label: this.state.selectedQueryDuration && this.state.selectedQueryDuration !== "" ? durationOptions.filter(e => e.value === this.state.selectedQueryDuration)[0].option : <span className="placeholder">Select</span>
                                                        })}															
                                                        options={durationOptions && durationOptions.map(item => ({
                                                            value: item.value,
                                                            label: item.option,	
                                                        }))}															
                                                        onChange={event => {
                                                            if(event.value !== 'custom') {
                                                                this.setState(
                                                                    {
                                                                        selectedQueryDuration: event.value,
                                                                        showQueryDateTimePicker: false,
                                                                    },
                                                                    () => {
                                                                        this.queryDurationFunction()
                                                                    }
                                                                )
                                                            } else {
                                                                this.setState({ showQueryDateTimePicker: true, selectedQueryDuration: event.value })
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                {this.state.showQueryDateTimePicker ?
                                                    <div className="d-flex mt-2">
                                                        <p className="mb-0 mx-2 align-self-end text-info mt-3">start&nbsp;time</p>
                                                        <Datetime 
                                                            value={momentDateGivenFormat(this.state.queryStartTime, 'DD MMM YYYY hh:mm A')}
                                                            onChange={this.handleStartTimeChange}
                                                            dateFormat={'DD MMM YYYY'}
                                                            timeFormat={'hh:mm A'}
                                                            inputProps={{ readOnly: true, class: "inputField" }}
                                                            className="w-100 mt-4"
                                                            isValidDate={queryDateTime}
                                                        />
                                                        <p className="mb-0 mx-2 align-self-end text-info mt-3">end&nbsp;time</p>
                                                        <Datetime
                                                            value={momentDateGivenFormat(this.state.queryEndTime, 'DD MMM YYYY hh:mm A')}
                                                            onChange={this.handleEndTimeChange}
                                                            dateFormat={'DD MMM YYYY'}
                                                            timeFormat={'hh:mm A'}
                                                            inputProps={{ readOnly: true, class: "inputField" }}
                                                            className="w-100 mt-4"
                                                            isValidDate={queryDateTime}
                                                        />
                                                    </div>
                                                : 
                                                    // <div className="d-flex mt-4">
                                                    // 	<p className="mb-0 mx-2 align-self-end text-info mt-3">start&nbsp;time</p>
                                                    // 	<span className="mt-3 text-white">{momentDateGivenFormat(this.state.queryStartTime, "DD MMM YYYY hh:mm A")}</span>
                                                    // 	<p className="mb-0 mx-2 align-self-end text-info mt-3">end&nbsp;time</p>
                                                    // 	<span className="mt-3 text-white">{momentDateGivenFormat(this.state.queryEndTime, "DD MMM YYYY hh:mm A")}</span>
                                                    // </div>
                                                    null
                                                }
                                                
                                                </React.Fragment>
                                            : null}
                                            </React.Fragment>
                                        : null}
                                        <button className={`btn btn-primary f12 align-self-end ml-2`} onClick={() => this.setState({ queryErrorMessage: "" }, () => this.executeQuery())}>
                                            {/* {this.state.queryLoading ?
                                                <Spinner className='text-center mr-2' color='white' size='sm' />
                                            : null} */}
                                            Run Query
                                        </button>
                                        {/* <span className={`f12 align-self-center ml-2 text-info ${this.state.queryLoading ? "disabled" : ""}`} onClick={() => this.setState({ queryErrorMessage: "" }, () => this.executeQuery())}>
                                            {this.state.queryLoading ?
                                                <Spinner className='text-center' color='white' size='sm' />
                                            : null}
                                            Run Query
                                        </span> */}
                                    </React.Fragment>
                                : null}
                            </div>
                        </div>	
                        {/* <div className="d-flex justify-content-end w-99 mt-2">
                            <button className={`btn btn-primary f12 align-self-center mr-2`}onClick={() => this.setState({ queryErrorMessage: "" }, () => this.executeQuery())}>Run Query</button>
                            <button className={`btn btn-light f12 align-self-center`}onClick={() => this.setState({ ['expand_'+i]: false, selectedQuery: "" })}>Close</button>
                        </div> */}
                        </React.Fragment>
                    : 
                        <p className="text-white displayNone">Query: {this.state.selectedQuery && this.state.selectedQuery.display_query ? this.state.selectedQuery.display_query : ""}</p>
                    }

                    {this.state.selectedQuery && this.state.selectedQuery.description ?
                        <div>
                            <span className="">Description</span>
                            {this.state.selectedQuery.description.split("\n").map(newLine => {
                                return(
                                    <span className="d-flex">
                                    {newLine.split("\t").map((tab, i) => {
                                        return (
                                            <span className={`${!i ? "" :""}`}>{tab}</span>
                                        )
                                    })}
                                    </span>
                                )
                            })}
                        </div>
                    : null}
                    {this.state.selectedQuery && this.state.selectedQuery.controls && Object.entries(this.state.selectedQuery.controls).length ?
                        <div className="d-flex mt-2 p-3 rounded bg-dark">
                            {this.state.selectedQuery.controls.hasOwnProperty("Tactic") ?
                                <div className={`mr-2 border-right-black5
                                    ${(!this.state.selectedQuery.controls.hasOwnProperty("Action/Impact") && !this.state.selectedQuery.controls.hasOwnProperty("Technique")) ? "w-100" 
                                    : (!this.state.selectedQuery.controls.hasOwnProperty("Action/Impact") || !this.state.selectedQuery.controls.hasOwnProperty("Technique")) ? "w-50" : "w-20"}
                                `}>
                                    <label className="text-white mb-2">Tactic</label>
                                    {this.state.selectedQuery.controls.Tactic.map(ctrls => {
                                        return(
                                            <p className="mb-0 d-flex flex-wrap">{ctrls}</p>
                                        )
                                    })}
                                </div>
                            : null}
                            {this.state.selectedQuery.controls.hasOwnProperty("Technique") ?
                                <div className={`mr-2 border-right-black5
                                    ${(!this.state.selectedQuery.controls.hasOwnProperty("Action/Impact") && !this.state.selectedQuery.controls.hasOwnProperty("Tactic")) ? "w-100" 
                                    : (!this.state.selectedQuery.controls.hasOwnProperty("Action/Impact") || !this.state.selectedQuery.controls.hasOwnProperty("Tactic")) ? "w-50" : "w-20"}
                                `}>
                                    <label className="text-white mb-2">Technique</label>
                                    {this.state.selectedQuery.controls.Technique.map(ctrls => {
                                        return(
                                            <p className="mb-0 d-flex flex-wrap">{ctrls}</p>
                                        )
                                    })}
                                </div>
                            : null}
                            {this.state.selectedQuery.controls.hasOwnProperty("Action/Impact") ?
                                <div className={`
                                    ${(!this.state.selectedQuery.controls.hasOwnProperty("Tactic") && !this.state.selectedQuery.controls.hasOwnProperty("Technique")) ? "w-100" : (!this.state.selectedQuery.controls.hasOwnProperty("Tactic") || !this.state.selectedQuery.controls.hasOwnProperty("Technique")) ? "w-45" : "w-45"}
                                `}>
                                    <label className="text-white mb-2">Action/Impact</label>
                                    {this.state.selectedQuery.controls["Action/Impact"].map(ctrls => {
                                        return(
                                            <p className="mb-0 d-flex flex-wrap">{ctrls}</p>
                                        )
                                    })}
                                </div>
                            : null}
                        </div>
                    : null}
                    {/* <div className="d-flex flex-wrap mt-3">
                        <p className="mb-0 f16">Category: 
                            {this.props.selectedQueryDetails.params.selectedCategory && this.props.selectedQueryDetails.params.selectedCategory !== "All" ?
                                <span className="badge badge-secondary ml-2 mb-1">{this.props.selectedQueryDetails.params.selectedCategory}</span>
                            :
                                this.props.selectedQueryDetails.selectedQuery.category.map(cat => {
                                    return(
                                    <span className="badge badge-secondary ml-2 mb-1">{cat}</span>
                                    )
                                })
                            }
                        </p>

                        <p className="mb-0 f16 ml-2">Sub Category: 
                            <span className="badge badge-secondary ml-2 mb-1">{this.props.selectedQueryDetails.selectedQuery.sub_category}</span>
                        </p>	
                    </div> */}
                </div>
                {this.state.queryLoading ?
                    <div className='d-flex justify-content-center m-4'> 
                        <Spinner className='text-center' color='white' size='lg' />
                    </div>
                :
                    <div className="rounded bg-dark p-3 w-100 mt-2">
                        <div className="d-flex w-100 mt-n2">
                            {this.state.brushSeries && this.state.brushSeries.length && this.state.optionsBrush && Object.entries(this.state.optionsBrush).length ?
                                <React.Fragment>
                                <div className="removeSvgPoint w-85">
                                    <Chart options={this.state.optionsBrush} series={this.state.brushSeries} type="line" height={10} />
                                </div>
                                <div className="pl-2 w-15">
                                    <small className="mb-0 align-self-center f10">{momentDateGivenFormat(this.state.brushStartTime, 'DD MMM HH:mm') +' - '+momentDateGivenFormat(this.state.brushEndTime, 'DD MMM HH:mm')}</small>
                                </div>
                                </React.Fragment>
                            : null}
                        </div>
                        <div className="d-flex my-2 justify-content-between">
                            <div className="d-flex mr-2 w-40">
                                <p className="f12 m-0 align-self-center pt-1">Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.state.selectedPeriodData && this.state.selectedPeriodData.length}{this.state.selectedPeriodData && this.state.selectedPeriodData.length > 1 ? ' events' : ' event'}</p>
                                {/* {this.state.totalErrorList && this.state.totalErrorList.length}{this.state.totalErrorList && this.state.totalErrorList.length > 1 ? ' errors' : ' error'} from </p> */}
                                <p className="f12 m-0 align-self-center pt-1 text-info ml-1">
                                    {this.state.brushTime ?
                                        this.state.brushStartTime ? 
                                            momentDateGivenFormat(this.state.brushStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.brushEndTime, 'DD MMM YYYY HH:mm') 
                                        : ""
                                    : 
                                        this.state.queryStartTime ? 
                                            momentDateGivenFormat(this.state.queryStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.queryEndTime, 'DD MMM YYYY HH:mm') 
                                        : ""
                                    }
                                </p>
                                {/*  */}
                            </div>
                            <div className="d-flex justify-content-end w-60 mb-n2">
                                {/* <Datetime 
                                    // value={momentDateGivenFormat(this.state.jumpDate, 'DD MMM YYYY hh:mm A')}
                                    onChange={item => this.handleJumpTo(item) }
                                    dateFormat={'DD MMM YYYY'}
                                    timeFormat={'hh:mm A'}
                                    inputProps={jumpToInputProps}
                                    input={true}
                                    isValidDate={disableFutureDt}
                                />
                                <p className={`mt-1 mb-0 ml-1 text-info ${this.state.jumpDate && this.state.jumpDate !== "" ? 'cursorPointer' : 'displayNone'}`} onClick={() => this.jumpToQueryResult()}>Jump</p> */}
                                {this.state.selectedPeriodData && this.state.selectedPeriodData.length ?
                                    <Search
                                        data={this.state.selectedPeriodData ? this.state.selectedPeriodData : []}
                                        applyTags={false}
                                        applyLiteDarkTags={true}
                                        topClassName={'bg-black5 align-self-center w-50 mr-1 border-gray5 rounded-5 f12'}
                                        searchClassName={'px-2 f12'}
                                        searchIconColor={'text-gray5 f12'}
                                        searchPlaceHolder={'Search results....'}
                                        className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                        filteredData={(filteredArray) => {
                                            this.setState({ filteredArray },
                                            () => {
                                                if(this.state.filteredArray && this.state.filteredArray.length) {
                                                    this.formDownloadData()    
                                                }
                                            }
                                        )
                                        }}
                                    />
                                : null}
                                {this.state.filteredArray && this.state.filteredArray.length > this.state.perPage ?
                                    <div className="pagination errorPagePagination f12">
                                        <span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
                                        <button><i className={`fal fa-arrow-to-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('start', this.state.currentPage)}></i></button> 
                                        <button><i className={`fal fa-angle-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('previous', this.state.currentPage)}></i></button> 
                                        <span>
                                            Go to
                                            <input
                                                type='number'
                                                value={this.state.pageNumber || this.state.pageNumber === "" ? this.state.pageNumber : this.state.currentPage}
                                                onChange={e => {
                                                    const page = e.target.value ? Number(e.target.value) : ""
                                                    this.gotoPage(page)
                                                }}
                                                className="ml-1 inputClass"
                                            />
                                        </span>
                                        <button><i className={`fal fa-angle-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
                                        <button><i className={`fal fa-arrow-to-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
                                    </div>
                                : null}
                                {this.state.filteredArray && this.state.filteredArray.length ?
                                    <CSVLink 
                                        data={this.state.filteredArray} 
                                        headers={this.state.headers ? this.state.headers : []}
                                        filename={"query-results.csv"}
                                        className={"align-self-center ml-2"}
                                        target="_blank"
                                    >
                                        <i className="fas fa-download text-white"></i>
                                        {/* <span className="text-primary-color ml-2">csv</span> */}
                                    </CSVLink>
                                : null}
                            </div>
                        </div>
                        
                        <div className="d-flex errorSectionScroll">
                            <div className={`bg-dark3 py-3 pl-2 w-100`}>
                                {this.state.alertListLoader ? 
                                    <div className='d-flex justify-content-center m-4'>
                                        <Spinner className='text-center' color='white' size='lg' />
                                    </div>
                                : this.state.filteredArray && this.state.filteredArray.length ?
                                    this.state.filteredArray.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map((item, i) => {
                                        return(
                                            <React.Fragment>
                                            <div className={`d-flex mr-2 px-2 mb-2 pb-1 ${this.state.startRecord + this.state.perPage !== this.state.startRecord + i ? 'border-bottom-dark' : ''} cursorPointer`}>  
                                                <div className="d-flex">
                                                    {item.severity === "severity" ?
                                                        <span className={`f10 mr-2 align-self-start mt-1 badge-sm risk-badge-${item.severity ? item.severity.toLowerCase() : 'critical'}`}>
                                                            {item.severity ? 
                                                                (item.severity.toLowerCase() === 'critical' ? 'C' : item.severity.toLowerCase() === 'high' ? 'H' : item.severity.toLowerCase() === 'medium' ? 'M' : 'L') 
                                                            : 'U'}
                                                        </span>
                                                    : null}

                                                    <p className="mb-0">
                                                        {item.event_time ?
                                                            <span className="mb-0 mr-2 text-white f12 font-weight-bold text-primary-color">{item.event_time ? momentConvertionUtcToLocalTime(item.event_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                        : null}
                                                        <span className={`mb-0 mr-2 f12 text-purple-2`}>
                                                            {item.event_source ? (Array.isArray(item.event_source) ? item.event_source.join(", ") : item.event_source) : ""}
                                                            {item.event_name ? " "+item.event_name : ''}
                                                        </span>
                                                        {item.importance ?
                                                            <span className={`mb-0 f12 font-weight-bold mr-2`}>Importance <span className={`ml-2 risk-${item.importance ? item.importance.toLowerCase() : 'critical'}`}>
                                                                    {item.importance}
                                                                </span>
                                                            </span>
                                                        : null}
                                                        {item.description ?
                                                            <span className={`mb-0 f12 font-weight-bold mr-2 text-white`}>Description: <span className={`text-white `}>{item.description}</span></span>
                                                        : null}
                                                        {item.event_id ?
                                                            <span className="mb-0 mr-2 text-white f12 font-weight-bold text-primary-color">Event Id: {item.event_id} </span>
                                                        : null}
                                                        {item.message ?
                                                            <span className={`mb-0 f12 font-weight-bold mr-2 text-white`}>Message: <span className={`ml-2 text-white `}>{item.message}</span></span>
                                                        : null}
                                                        {Object.entries(item).map(([key, value]) => {
                                                            return(
                                                                !commonFields.includes(key) ?
                                                                    typeof value === 'object' ?
                                                                        <React.Fragment>
                                                                        <span className="mb-0 small mx-1">{key}</span>
                                                                        {Object.entries(value).map(([objKey, objValue]) => {
                                                                            return(
                                                                                <span className={`mb-0 f12 font-weight-bold mr-2`}><span className="">{objKey}</span>{' : '+objValue}</span>
                                                                            )
                                                                        })}
                                                                        </React.Fragment>
                                                                    :
                                                                        <span className={`mb-0 f12 font-weight-bold mr-2`}><span className="">{(this.state.listQueryLabel[key] ? this.state.listQueryLabel[key] : key)}</span>{' : '+value}</span>
                                                                : null
                                                                
                                                            )
                                                        })}
                                                        {item.additional_details && Object.entries(item.additional_details).length ?
                                                            <React.Fragment>
                                                            <span className="mb-0 small mx-1">Additional Details</span>
                                                            {Object.entries(item.additional_details).map(([key, value]) => {
                                                                return(
                                                                    <span className={`mb-0 f12 font-weight-bold mr-2`}><span className="">{key}</span>{' : '+value}</span>
                                                                )
                                                            })}
                                                            </React.Fragment>
                                                        : null}
                                                        {item.assets && Object.entries(item.assets).length ?
                                                            <React.Fragment>
                                                            <span className="mb-0 small mr-2">Resources: </span>
                                                            {item.assets.map((asst, i) => {
                                                                return(
                                                                    <span className={`${this.state.showAssets ? "" : (!i ? "" : "displayNone")}`}>
                                                                        <span className={`mb-0 f12 font-weight-bold mr-2`}>{asst.asset_name}</span>                                                         
                                                                        {item.provider ?
                                                                            <span className="mb-0 f12 text-primary-color mr-1 font-weight-bold">{' '+item.provider}</span>
                                                                        : null}
                                                                        {asst.account_id ?
                                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">{' '+asst.account_id}</span>
                                                                        : null}
                                                                        {asst.region ?
                                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+asst.region}</span>
                                                                        : null}
                                                                        {asst.resource_type ?
                                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+(Array.isArray(asst.resource_type) ? asst.resource_type.join(', ') : asst.resource_type)}</span>
                                                                        : null}
                                                                        {asst.service_name ?
                                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+asst.service_name}</span>
                                                                        : null}
                                                                    </span>
                                                                )
                                                            })}
                                                            {item.assets.length > 1 ? 
                                                                <i className={`ml-2 text-info fas ${this.state.showAssets ? 'fa-minus-circle' : 'fa-plus-circle'}`} onClick={() => this.setState({ showAssets: !this.state.showAssets })}></i>
                                                            : null}
                                                            </React.Fragment>
                                                        : 
                                                            <React.Fragment>
                                                                {item.asset_name ?
                                                                    <span className="mb-0 mr-2 f12 text-white font-weight-bold">{item.asset_name}</span>
                                                                : null}
                                                                {item.account_id || item.region || item.resource_type || item.service_name ?
                                                                    <span className="mb-0 small mx-2 text-primary-color">Resources: </span>
                                                                : null}                                                                
                                                                {item.provider ?
                                                                    <span className="mb-0 f12 text-primary-color mr-1 font-weight-bold">{' '+item.provider}</span>
                                                                : null}
                                                                {item.account_id ?
                                                                    <span className="mb-0 f12 text-primary-color mr-1 font-weight-bold">{' '+item.account_id}</span>
                                                                : null}
                                                                {item.region ?
                                                                    <span className="mb-0 f12 text-primary-color mr-1 font-weight-bold">{' : '+item.region}</span>
                                                                : null}
                                                                {item.resource_type ?
                                                                    <span className="mb-0 f12 text-primary-color mr-1 font-weight-bold">{' : '+(Array.isArray(item.resource_type) ? item.resource_type.join(', ') : item.resource_type)}</span>
                                                                : null}
                                                                {item.service_name ?
                                                                    <span className="mb-0 f12 text-primary-color mr-1 font-weight-bold">{' : '+item.service_name}</span>
                                                                : null}
                                                            </React.Fragment>
                                                        }
                                                        {/* {item.event_analysis && Object.entries(item.event_analysis).length ?
                                                            <React.Fragment>
                                                            <span className="mb-0 small mx-1 text-white">Event Analysis</span>
                                                            {Object.entries(item.event_analysis).map(([key, value]) => {
                                                                return (
                                                                    typeof value === 'string' ?
                                                                        <span className={`mb-0 f12 font-weight-bold ml-2`}><span className="text-white">{key}</span>{' : '+value}</span>
                                                                    : null
                                                                )
                                                            })}
                                                            </React.Fragment>
                                                        : null} */}
                                                    </p>
                                                </div>
                                            </div>
                                            </React.Fragment>
                                        )
                                    })
                                :
                                    <div className='d-flex justify-content-center m-4'>
                                        <p>There are no data on this criteria. Please try adjusting your query.</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
QueryResults.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log('errorDashboard',state)
    return {
        accounts: state.filters.accounts,
        regions: state.filters.regions,
        observabilityQueryResult: state.observability.observabilityQueryResult
    }
}

export default connect(mapStateToProps, {
	// getQueries, 
	startQueryExection, 
	getQueryResult,
	setFavouriteQuery,
	unsetFavouriteQuery,
	dynamicCallApi
})(withRouter(QueryResults))