/*************************************************
 * Tvastar
 * @exports
 * @file CreateDiagnostics.js
 * @author Prakash // on 18/01/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Spinner, Input, UncontrolledTooltip } from 'reactstrap'

import { getRegionName, getAccountNameFromId, momentDateGivenFormat, currentLocaltime, momentConvertionLocalToUtcTime, twoDateDiffrence, subDays, subHours } from '../../utils/utility'
import { HOURLY_DURATION } from '../../utils/constants'

import { listAllProviders, listAllAccounts, listAllRegions, getAllTagsKeys, getAllTagsValues } from '../../actions/commonAction'
import { startDiagnostics, listDiagnosticChecks, saveDiagnosticTemplate } from '../../actions/aiops/DiagnosticsAction'


import Search from '../common/SearchComponent'
import Select from 'react-select'

import { store as CommonNotification } from 'react-notifications-component';

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";

// import moment from 'moment'
// const today = moment();
// const disableFutureDt = current => {
//   return current.isBefore(today)
// }
const disableFutureDt = current => {
	return current.isBefore(currentLocaltime())
}

const startInputProps = {
    placeholder: 'Start Datetime',
    className: 'form-control inputDarkTheme readonlyDatePickerInput',
    readOnly: true,
};

const endInputProps = {
    placeholder: 'End Datetime',
    className: 'form-control inputDarkTheme readonlyDatePickerInput',
    readOnly: true,
};

class CreateDiagnostics extends Component {
	proEnvWrap = React.createRef()
	constructor(props) {
		super(props)
		this.props = props;
		
		this.createAccountRef = React.createRef()
		this.createRegionRef = React.createRef()

		this.state = {
            selectedProvider: "",
            selectedAccount: "",
            selectedRegion: "",

			tagsArray: [0],
			selectedTags: [],
			allChecks: true,
		}

		this.handleStartTimeChange  = this.handleStartTimeChange.bind(this)
        this.handleEndTimeChange    = this.handleEndTimeChange.bind(this)
	}

	componentDidMount = () => {
		document.addEventListener('keydown', this._handleKeyDown)
		
		this.listDiagnosticChecks()
		this.getAllTagsKeys()
		if(this.props.selectedTemplate && Object.entries(this.props.selectedTemplate).length) {
			startInputProps.disabled = true //to disable the start time date-time picker
			endInputProps.disabled = true //to disable the end time date-time picker

			let diagnostics = this.props.selectedTemplate
			let selectedDuration = diagnostics.duration && diagnostics.duration !== "" ? diagnostics.duration : "custom"
			let showDateTimePicker = (!diagnostics.duration || diagnostics.duration === "" || diagnostics.duration === "custom") ? true: false

			let tagsArray = []
			if(diagnostics.tags && diagnostics.tags.length) {
				diagnostics.tags.forEach((list, i) => {
					tagsArray.push(i)
					this.setState({
						["tag_key_"+i]: list.key,
						["tag_value_"+i]: list.value
					},
						// () => this.formInputProps(this.state.activeSection, "tag_value", this.state["tag_value_"+i])
					)
				})
			} else {
				tagsArray = [0]
			}
			
			this.setState({
				template_id: diagnostics.template_id ? diagnostics.template_id : "",
				name: diagnostics.name ? diagnostics.name : "",
				selectedProvider: diagnostics.provider ? diagnostics.provider : "",
				selectedAccount: diagnostics.account_id ? diagnostics.account_id : "",
				selectedRegion: diagnostics.region ? diagnostics.region : "",
				selectedDuration,
				showDateTimePicker,
				start_time: diagnostics.start_time ? diagnostics.start_time : "",
				end_time: diagnostics.end_time ? diagnostics.end_time : "",
				description: diagnostics.description ? diagnostics.description : "",
				tagsArray,
				selectedDiagnoticChecks: diagnostics.checks ? diagnostics.checks : [],
				allChecks: diagnostics.checks && diagnostics.checks.length ? false : true,
				selectedTags: diagnostics.tags ? diagnostics.tags : []
			})
		}
		
        if(this.props.providers && this.props.providers.length) {
			this.setState({ providers: this.props.providers, selectedProvider: this.props.providers[0].provider_name },
				() => {
					this.getFilterData("provider")
				}
			)
		} else {
			this.props.listAllProviders((promise, providers) => {
				if (promise) {
					this.setState({ providers, selectedProvider: providers[0].provider_name },
						() => {
							this.getFilterData("provider")
						}
					)
				} else {
					this.setState(
						{ providers: [],  }, 
						() => {
							this.getFilterData()
						}
					)
				}
			})
		}	
	}	

	_handleKeyDown = event => {
		switch (event.keyCode) {
			case 27:
				this.props.closePanel()
				break
			default:
				break
		}
	}

	/**
	 * Filter identifiers based on the filter selection
	 */
	getFilterData = () => {
		let params = {}
		if (this.state.selectedProvider) {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		
        if(this.props.accounts && this.props.accounts.length) {
			let allAccounts = this.props.accounts.filter(e => e.provider === this.state.selectedProvider.toLowerCase())
			let selectedAccount = this.state.selectedAccount ? this.state.selectedAccount : (allAccounts && allAccounts.length === 1 ? allAccounts[0].account_id : "")
			this.setState({ accounts: allAccounts, selectedAccount })
		} else {
			this.props.listAllAccounts(params, (promise, allAccounts) => {
				if (promise) {						
					let filterdAccounts = allAccounts.filter(e => e.provider === this.state.selectedProvider.toLowerCase())
					let selectedAccount = this.state.selectedAccount ? this.state.selectedAccount : (filterdAccounts && filterdAccounts.length === 1 ? filterdAccounts[0].account_id : "")
					this.setState({ accounts: filterdAccounts, selectedAccount })
				}
			})
		}

		if(this.props.regions && this.props.regions.length) {
			let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
			let selectedRegion = this.state.selectedRegion ? this.state.selectedRegion : (providerRegions && providerRegions.length === 1 ? providerRegions[0].region :  "")
			this.setState({ regions: providerRegions, selectedRegion })
		} else {
			this.props.listAllRegions(params, (promise, regions) => {
				if (promise) {
					let providerRegions = regions && regions[0] && regions[0].provider_regions
					let selectedRegion = this.state.selectedRegion ? this.state.selectedRegion : (providerRegions && providerRegions.length === 1 ? providerRegions[0].region: "")
					this.setState({ regions: providerRegions, selectedRegion })
				} else {
					this.setState({ regions: [], selectedRegion: [] })
				}
			})
		}
	}	

	listDiagnosticChecks = () => {
		this.props.listDiagnosticChecks((promise, response) => {
			if (promise) {
				this.setState({ diagnosticChecks: response, filteredDiagnotics: response })
			} else {
				this.setState({ diagnosticChecks: [], filteredDiagnotics: [] })
			}
		})
	}

	handleClickOutside = (event) => {

		// if (this.createRegionRef && !this.createRegionRef.current.contains(event.target)) {
		// 	this.setState({ isRegionOpen: false })
		// } else {
		// 	this.setState({ isRegionOpen: true })
		// }
	}

	addSection = () => {
        let rowList = this.state.tagsArray;
        if(this.state.tagsArray) {
            let value = this.state.tagsArray[this.state.tagsArray.length - 1]
            value = value+1
            rowList.push(value);
        }
        
        this.setState({tagsArray: rowList })
    }

    removeSection = (item, i) => {
        this.setState({ ["tag_key_"+item]: "", ["tag_value_"+item]: "" },
            () => {
                let rowList = this.state.tagsArray;
                rowList.splice(i, 1);
                this.setState({ tagsArray: rowList })
            }
        );
    }

	onChangeEvent = (index, type) => {
		let dataRow = {}
		dataRow.key = this.state["tag_key_"+index] ? this.state["tag_key_"+index] : ""
		dataRow.value = this.state["tag_value_"+index] ? this.state["tag_value_"+index] : ""
		
		let tags = this.state.selectedTags ? this.state.selectedTags : []
		// tags.push(dataRow)

		if(tags.length && tags.length-1 >= index) {
			tags[index] = dataRow
		} else {
            tags.push(dataRow)
        }

		this.setState({ selectedTags: tags })
	}

	startDiagnostics = () => {
		let params = {}
		params.provider = this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider.toLowerCase() : ""
		params.account_id = this.state.selectedAccount ? this.state.selectedAccount : ""
		params.region = this.state.selectedRegion ? this.state.selectedRegion : ""
		params.tags = this.state.selectedTags ? this.state.selectedTags : []
		params.description = this.state.description ? this.state.description : ""
		params.name = this.state.name ? this.state.name : ""
		if(this.state.selectedDuration === "custom") {
			params.start_time = this.state.start_time ?  momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:00') : ""
			params.end_time = this.state.end_time ? momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:00') : ""
		} else if(this.state.selectedDuration && this.state.selectedDuration !== "") {
			let start_time = ""
			let end_time = ""
			let dayType = this.state.selectedDuration[this.state.selectedDuration.length - 1]
			if(dayType === "d") {
				let days = this.state.selectedDuration.replace('+', "").replace('d', "")
				let reducedDays = days
				start_time = momentConvertionLocalToUtcTime(subDays(new Date(), reducedDays), 'YYYY-MM-DD HH:mm:00')
				end_time = momentConvertionLocalToUtcTime(new Date(), 'YYYY-MM-DD HH:mm:00')
			} else {
				let hours = this.state.selectedDuration.replace('+', "").replace('h', "")
				// let reducedDays = days - 1
				start_time = momentConvertionLocalToUtcTime(subHours(new Date(), hours), 'YYYY-MM-DD HH:mm:00')
				end_time = momentConvertionLocalToUtcTime(new Date(), 'YYYY-MM-DD HH:mm:00')
			}

			params.duration = this.state.selectedDuration ? this.state.selectedDuration : ""
			// let days = this.state.selectedDuration.replace('+', "").replace('d', "")
			// let reducedDays = days - 1
        
			params.start_time = start_time
			params.end_time = end_time
		}

		let checks = []
		if(this.state.selectedDiagnoticChecks && this.state.selectedDiagnoticChecks.length) {
			checks = this.state.selectedDiagnoticChecks
		}

		params.checks = checks

		let hasError = false
		if(params.name === "" || params.provider === "" || params.account_id === "" || params.region === "" || !this.state.selectedDuration) {
			hasError = true
		}

		// if(!hasError) {
		// 	this.state.selectedTags.forEach(tag => {
		// 		if(tag.key === "" || tag.value === "") {
		// 			hasError = false
		// 		}
		// 	})
		// }

		if(this.state.selectedDuration === "custom") {
			if(params.start_time === "" || params.end_time === "") {
				hasError = true
			} else if(this.state.hasDateError) {
				hasError = this.state.hasDateError
			}
		}

		if(!hasError) {
			this.props.startDiagnostics(params, (promise, response) => {
				let messageType = ""
				if(promise) {
					messageType = "success"
					if(!response.status) {
						messageType = "danger"
					}
					this.setState({ runDiagnosticsLoading: false, runSaveDiagnosticsLoading: false })
				} else {
					messageType = "danger"	
					this.setState({ runDiagnosticsLoading: false, runSaveDiagnosticsLoading: false })
				}

                let message = response && response.message ? response.message : ""
				let diagnosticId = response.result && response.result.diagnostic_id ? response.result.diagnostic_id : ""
				if(messageType === "success") {
					message = 'Diagnostics submitted successfully. Diagnostics Id: '+diagnosticId
				}
                CommonNotification.addNotification({
                    message: message,
                    type: messageType,
                    insert: "top",
                    container: "top-center",
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                });

				if(messageType === "success") {
					this.props.callListApi()
				}
			})
		} else {
			this.setState({ hasError, runDiagnosticsLoading: false, runSaveDiagnosticsLoading: false })
		}
	}

	saveDiagnosticTemplate = (type) => {
		let params = {}
		params.provider = this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider.toLowerCase() : ""
		params.account_id = this.state.selectedAccount ? this.state.selectedAccount : ""
		params.region = this.state.selectedRegion ? this.state.selectedRegion : ""
		params.tags = this.state.selectedTags ? this.state.selectedTags : []
		params.description = this.state.description ? this.state.description : ""
		params.name = this.state.name ? this.state.name : ""
		if(this.state.selectedDuration === "custom") {
			params.start_time = this.state.start_time ?  momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:00') : ""
			params.end_time = this.state.end_time ? momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:00') : ""
		} else if(this.state.selectedDuration && this.state.selectedDuration !== "") {
			let start_time = ""
			let end_time = ""
			let dayType = this.state.selectedDuration[this.state.selectedDuration.length - 1]
			if(dayType === "d") {
				let days = this.state.selectedDuration.replace('+', "").replace('d', "")
				let reducedDays = days - 1
				start_time = momentConvertionLocalToUtcTime(subDays(new Date(), reducedDays), 'YYYY-MM-DD HH:mm:00')
				end_time = momentConvertionLocalToUtcTime(new Date(), 'YYYY-MM-DD HH:mm:00')
			} else {
				let hours = this.state.selectedDuration.replace('+', "").replace('h', "")
				// let reducedDays = days - 1
				start_time = momentConvertionLocalToUtcTime(subHours(new Date(), hours), 'YYYY-MM-DD HH:mm:00')
				end_time = momentConvertionLocalToUtcTime(new Date(), 'YYYY-MM-DD HH:mm:00')
			}

			params.duration = this.state.selectedDuration ? this.state.selectedDuration : ""
			// let days = this.state.selectedDuration.replace('+', "").replace('d', "")
			// let reducedDays = days - 1
        
			params.start_time = start_time
			params.end_time = end_time
		}
		// 	params.duration = this.state.selectedDuration ? this.state.selectedDuration : ""
		// 	let days = this.state.selectedDuration.replace('+', "").replace('d', "")
		// 	let reducedDays = days - 1
        
		// 	params.start_time = momentConvertionLocalToUtcTime(subDays(new Date(), reducedDays), 'YYYY-MM-DD 00:00:00')
		// 	params.end_time = momentConvertionLocalToUtcTime(new Date(), 'YYYY-MM-DD HH:mm:00')
		// }

		let checks = []
		if(this.state.selectedDiagnoticChecks && this.state.selectedDiagnoticChecks.length) {
			checks = this.state.selectedDiagnoticChecks
		}

		params.checks = checks

		let hasError = false
		if(params.name === "" || params.provider === "" || params.account_id === "" || params.region === "" || !this.state.selectedDuration) {
			hasError = true
		}

		// if(!hasError) {
		// 	this.state.selectedTags.forEach(tag => {
		// 		if(tag.key === "" || tag.value === "") {
		// 			hasError = false
		// 		}
		// 	})
		// }

		if(this.state.selectedDuration === "custom") {
			if(params.start_time === "" || params.end_time === "") {
				hasError = true
			} else if(this.state.hasDateError) {
				hasError = this.state.hasDateError
			}
		}
		if(!hasError) {
			this.props.saveDiagnosticTemplate(params, (promise, response) => {
				let messageType = ""
				if(promise) {
					messageType = "success"
					if(!response.status) {
						messageType = "danger"
					}
					this.setState({ saveDiagnosticLoading: false })
				} else {
					messageType = "danger"	
					this.setState({ saveDiagnosticLoading: false })
				}

                let message = response && response.message ? response.message : ""
				// let templateId = response.result && response.result.template_id ? response.result.template_id : ""
				if(messageType === "success") {
					message = 'Diagnostic templated submitted successfully.'
					if(type === "save_run") {
						this.startDiagnostics()
					}
				}
                CommonNotification.addNotification({
                    message: message,
                    type: messageType,
                    insert: "top",
                    container: "top-center",
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                });
			})
		} else {
			this.setState({ hasError, saveDiagnosticLoading: false })
		}
	}

	durationFunction = (type) => {
		if(this.state.selectedDuration !== "custom") {
			// let days = this.state.selectedDuration.replace('+', "").replace('d', "")
			// let reducedDays = days - 1
        
			// let start_time = momentDateGivenFormat(subDays(new Date(), reducedDays), 'YYYY-MM-DD 00:00:00')
			// let end_time = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:00')

			// this.setState({ start_time, end_time, showDateTimePicker: false })
			this.setState({ showDateTimePicker: false })
		} else {
			this.setState({ showDateTimePicker: true })
		}
    }
  
    handleStartTimeChange(date) {
        let start_time = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
        let end_time = this.state.end_time
		let endBoolen = false
		let message = ""
		if(this.state.start_time && this.state.end_time) {
			if(new Date(start_time).getTime() > new Date(end_time).getTime()) {
				endBoolen = true
				message = "please select valid date"
			} else if(twoDateDiffrence(end_time, start_time) >= 7) {
				endBoolen = true
				message = "please select date interval of 7 days"
			}
		}
		this.setState({ start_time, hasDateError: endBoolen, showDateisNotValid: message })
    }

    handleEndTimeChange(date) {
		let start_time = this.state.start_time
        let end_time = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
		let startBoolen = false
		let message = ""
		if(this.state.start_time) {
			if(new Date(end_time).getTime() < new Date(start_time).getTime() ) {
				startBoolen = true
				message = "please select valid date"
			} else if(twoDateDiffrence(end_time, start_time) >= 7) {
				startBoolen = true
				message = "please select date interval of 7 days"
			} 
		}
		this.setState({ end_time, hasDateError: startBoolen, showDateisNotValid: message  },
			
		)
    }

	onChangeDiagnotics = (check) => {
		let selectedDiagnoticChecks = this.state.selectedDiagnoticChecks ? this.state.selectedDiagnoticChecks : []
		if(this.state.allChecks) {
			selectedDiagnoticChecks = []
		}		

		if(selectedDiagnoticChecks.length || check) {
			let idExist = selectedDiagnoticChecks.filter(e => e === check)
			if(idExist.length) {
				selectedDiagnoticChecks = selectedDiagnoticChecks.filter(e => e !== check)
			} else {
				selectedDiagnoticChecks.push(check)
			}
		}

		if(this.state.diagnosticChecks.length === selectedDiagnoticChecks.length) {
			this.setState({ markAllDiagnostics: true })
		} else {
			this.setState({ markAllDiagnostics: false })
		}

		this.setState({ selectedDiagnoticChecks })
	}

	startDateFuntion = (state) => {
		this.setState({ [state]: false })
	}

	onChangeMarkAllDiagnostics = () => {
		let selectedDiagnoticChecks = []
		if(this.state.markAllDiagnostics) {
			this.state.diagnosticChecks.forEach(item => {
				selectedDiagnoticChecks.push(item.check)
			})
		}
		this.setState({ selectedDiagnoticChecks })
	}

    getAllTagsKeys = () => {
        let params = {}
        this.props.getAllTagsKeys(params, (promise, response) => {})
    }

    getAllTagsValues = (index) => {
        let params = {}
        params.tags = [{ "key": this.state["tag_key_"+index] }]
        this.props.getAllTagsValues(params, (promise, response) => {
            if(promise) {
                this.setState({ ["tagValues_"+index]: response })
            }
        })
    }
	
	render() {		
		return (
			<div className={`advanced-search w- ${(this.state.runDiagnosticsLoading || this.state.runSaveDiagnosticsLoading || this.state.saveDiagnosticLoading) ? "disabled" : ""}`} onClick={ (event) => { this.handleClickOutside(event) } }>
				<div className="search-content bg-muted overflow-auto w-80">
					<div className="header-search bd-highlight justify-content-between">
						{/* <label>{this.props.actionType} Diagnostic Template</label> */}
						<h6 className='text-white m-0'>Run Diagnostics</h6>
						<i className="far fa-times" onClick={() => this.props.closePanel()}></i>
					</div>
					<div className="p-3">
						<div className="rounded bg-dark p-3">
							<div className="d-flex mb-2">
								<div className="py-1 w-30">
									<div className="d-flex justify-content-between">
										<p className="mb-0 text-white">Name</p>										
										{this.state.hasError && (!this.state.name || this.state.name === "") ?
											<p className="mb-0 text-danger">required</p>
										: null}
									</div>
									<Input 
										type="text" 
										placeholder="Enter name"
										maxLength={80}
										className={`${this.props.actionType === "Run" ? "inputDarkThemeDisabled disabled" : "inputDarkTheme"}`}
										value={this.state.name ? this.state.name : ""}
										onChange={e => this.setState({ name: e.target.value })}
									/>
								</div>
								<div className="py-1 w-15 pl-3">
									<div className="d-flex justify-content-between">
										<p className="b-block mb-0 text-white">Provider</p>
										{this.state.hasError && this.state.selectedProvider === "" ?
											<p className="mb-0 text-danger">required</p>
										: null}
									</div>
									<Select
										placeholder={"Select"}
										isSearchable={true}
										// menuIsOpen={this.props.actionType === "Run" ? false : true}
										isDisabled={this.props.actionType === "Run" ? true : false}
										className="f13 p-0 selectDarkTheme"
										value={({
											value: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : "Select",
											label: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : <span className="placeholder">Select</span>
										})}
										options={this.state.providers && this.state.providers.map(item => ({
											value: item.provider_name,
											label: item.provider_name,	
										}))}
										onChange={event =>  
											this.setState({
												selectedProvider: event.value
											},
												() => this.getFilterData()
											)
										}
									/>
								</div>
								<div className="py-1 w-15 pl-3">
									<div className="d-flex justify-content-between">
										<p className="b-block mb-0 text-white">Account</p>
										{this.state.hasError && (!this.state.selectedAccount || this.state.selectedAccount === "") ?
											<p className="mb-0 text-danger">required</p>
										: null}
									</div>
									<Select
										placeholder={"Select"}
										isSearchable={true}
										isDisabled={this.props.actionType === "Run" ? true : false}
										className="f13 p-0 selectDarkTheme"
										options={this.state.accounts && this.state.accounts.map(account => ({
											value: account.account_id,
											label: account.account_name,
										}))}
										value={({
											value: this.state.selectedAccount && this.state.selectedAccount !== "" ? this.state.selectedAccount : "Select",
											label: this.state.selectedAccount && this.state.selectedAccount !== "" ? getAccountNameFromId(this.state.selectedAccount, this.state.accounts) : <span className="placeholder">Select</span>
										})}
										onChange={event =>  
											this.setState({
												selectedAccount: event.value
											})
										}
									/>
								</div>
								<div className="py-1 w-15 pl-3">
									<div className="d-flex justify-content-between">
										<p className="b-block mb-0 text-white">Region</p>
										{this.state.hasError && (!this.state.selectedRegion || this.state.selectedRegion === "") ?
											<p className="mb-0 text-danger">required</p>
										: null}
									</div>
									<Select
										placeholder={"Select"}
										isSearchable={true}
										// menuIsOpen={true}
										isDisabled={this.props.actionType === "Run" ? true : false}
										className="f13 p-0 selectDarkTheme"
										options={this.state.regions && this.state.regions.map(reg => ({
											value: reg.region,
											label: reg.name,
										}))}
										value={({
											value: this.state.selectedRegion && this.state.selectedRegion !== "" ? this.state.selectedRegion : "Select",
											label: this.state.selectedRegion && this.state.selectedRegion !== "" ? getRegionName(this.state.selectedRegion, this.state.regions) : <span className="placeholder">Select</span>
										})}
										onChange={event =>  
											this.setState({
												selectedRegion: event.value
											})
										}
									/>
								</div>
								<div className="py-1 w-15 pl-3">
									<div className="d-flex justify-content-between">
										<p className="b-block mb-0 text-white">Duration</p>
										{this.state.hasError && (!this.state.selectedDuration || this.state.selectedDuration === "") ?
											<p className="mb-0 text-danger">required</p>
										: null}
									</div>
									<Select
										placeholder={"Select"}
										isSearchable={true}
										// menuIsOpen={true}
										isDisabled={this.props.actionType === "Run" ? true : false}
										className="f13 p-0 selectDarkTheme"
										options={HOURLY_DURATION.map(dur => ({
											value: dur.value,
											label: dur.option,
										}))}
										value={({
											value: this.state.selectedDuration && this.state.selectedDuration !== "" ? this.state.selectedDuration : "Select",
											label: this.state.selectedDuration && this.state.selectedDuration !== "" ? (HOURLY_DURATION.filter(e => e.value === this.state.selectedDuration).length ? HOURLY_DURATION.filter(e => e.value === this.state.selectedDuration)[0].option : <span className="placeholder">Select</span>) : <span className="placeholder">Select</span>
										})}
										onChange={event =>  
											this.setState({
												selectedDuration: event.value
											},
												() => this.durationFunction()
											)
										}
									/>
								</div>
							</div>
							{this.state.showDateTimePicker ?
								<div className="d-flex mt-2">
									<div className="py-1 w-15">
										<div className="d-flex justify-content-between">
											<p className="b-block mb-0 text-white">Start Time</p>
											{this.state.hasError && !this.state.start_time ?
												<p className="mb-0 text-danger">required</p>
											: null}
										</div>
										<Datetime 
											// value={momentDateGivenFormat(this.state.start_time, 'DD MMM YYYY hh:mm A')}
											// open={this.state.open_start_date}
											// onClick={() => this.setState({ open_start_date: true })}
											onChange={this.handleStartTimeChange}
											dateFormat={'DD MMM YYYY'}
											timeFormat={'hh:mm A'}
											inputProps={ startInputProps }
											className="w-100"
											isValidDate={disableFutureDt}
										/>
									</div>
									<div className="py-1 w-15 pl-3">
										<div className="d-flex justify-content-between">
											<p className="b-block mb-0 text-white">End Time</p>
											{this.state.hasError && !this.state.end_time ?
												<p className="mb-0 text-danger">required</p>
											: null}
										</div>
										<Datetime
											// value={momentDateGivenFormat(this.state.end_time, 'DD MMM YYYY hh:mm A')}
											// open={this.state.open_end_date}
											// onClick={() => this.setState({ open_end_date: true })}
											onChange={this.handleEndTimeChange}
											dateFormat={'DD MMM YYYY'}
											timeFormat={'hh:mm A'}
											inputProps={ endInputProps }
											className="w-100"
											isValidDate={disableFutureDt}
										/>
									</div>
									<div className="py-1 w-70 pl-3 align-self-end">
										{this.state.hasDateError ?
											<p className="mb-0 text-danger">{this.state.showDateisNotValid}</p>
										: null}
									</div>
									{/* {this.state.hasError && (!this.state.start_time || this.state.start_time === "" || !this.state.end_time || this.state.end_time === "") ?
										<p className="mb-0 text-danger">Please select date</p>
									: null} */}
								</div>
							: null}
							
							<div className="mt-2">
								<div className="w-82 d-flex justify-content-between">
									<p className="b-block mb-0 text-white">Tags</p>
									{/* {this.state.hasError && (!this.state.selectedTags || !this.state.selectedTags.length) ?
										<p className="mb-0 text-danger">required</p>
									: null} */}
								</div>
								{this.state.tagsArray.map((item, i) => {
									return(
										<div className={`w-100 ${i ? "mt-3" : ""}`}>
											<div className="d-flex">
												{/* <p className="mb-0 mr-2 align-self-end text-info">Bucket</p> */}
												<Select
													placeholder={'Select'}
													isSearchable={true}
													// menuIsOpen={true}
													className='f13 p-0 selectDarkTheme w-20'
													value={({
														value: this.state["tag_key_"+item] && this.state["tag_key_"+item] !== "" ? this.state["tag_key_"+item] : 'Select',
														label: this.state["tag_key_"+item] && this.state["tag_key_"+item] !== "" ? this.state["tag_key_"+item] : <span className="placeholder">key (environment)</span>
													})}
													options={this.props.tagKeys && this.props.tagKeys.map(item => ({
														value: item,
														label: item,	
													}))}
													onChange={event => this.setState({
														["tag_key_"+item]: event.value,
														["tag_value_"+item]: "", //empty selected input
														["tagValues_"+item]: [], //empty the options
													},
														() => this.getAllTagsValues(item)
													)}
												/>
												{/* <Input
													type="text" 
													placeholder="key (environment)"
													className={`w-40 ${this.props.actionType === "Run" ? "inputDarkThemeDisabled disabled" : "inputDarkTheme"}`}
													value={this.state["tag_key_"+item] ? this.state["tag_key_"+item] : ""}
													onChange={e => this.setState({ ["tag_key_"+item]: e.target.value }, () => this.onChangeEvent(i, "key"))}
												/> */}
												{/* <p className="mb-0 mx-2 align-self-end text-info">Prefix</p> */}
												
												<Select
													placeholder={'Select'}
													isSearchable={true}
													// menuIsOpen={true}
													className='f13 p-0 selectDarkTheme w-20 ml-2'
													value={({
														value: this.state["tag_value_"+item] && this.state["tag_value_"+item] !== "" ? this.state["tag_value_"+item] : 'Select',
														label: this.state["tag_value_"+item] && this.state["tag_value_"+item] !== "" ? this.state["tag_value_"+item] : <span className="placeholder">value (prod)</span>
													})}
													options={this.state["tagValues_"+item] && this.state["tagValues_"+item].map(item => ({
														value: item,
														label: item,	
													}))}
													onChange={event => this.setState({
														["tag_value_"+item]: event.value
													},
														() => this.onChangeEvent(i, "value")
													)}
												/>
												{/* <Input 
													type="text" 
													placeholder="value (prod)"
													className={`w-40 ${this.props.actionType === "Run" ? "inputDarkThemeDisabled disabled" : "inputDarkTheme"} ml-3`}
													value={this.state["tag_value_"+item] ? this.state["tag_value_"+item] : ""}
													onChange={e => this.setState({ ["tag_value_"+item]: e.target.value }, () => this.onChangeEvent(i, "value"))}
												/> */}
												{this.props.actionType !== "Run" && this.state.tagsArray.length > 1 ?
													<span className={`far fa-trash cursor-pointer f18 align-self-center ml-4`} onClick={() => this.removeSection(item, i)}></span>
												: null}
												{this.props.actionType !== "Run" && (this.state.tagsArray.length - 1) ===  i ?
													<span className={`far fa-plus cursor-pointer f18 align-self-center ml-4`} onClick={() => this.addSection()}></span>
												: null}
											</div>
										</div>
									)
								})}
							</div>
							{this.props.actionType !== "Run" ?
								<React.Fragment>
								<div className="d-flex justify-content-between mt-3">
									<div className="form-group form-check mb-0 align-self-center">
										<input 
											type="checkbox" 
											className={`form-check-input ${this.props.actionType === "Run" ? "disabled" : ""}`}
											checked={this.state.allChecks}
											onChange={e => this.setState({ allChecks: !this.state.allChecks }, () => this.onChangeDiagnotics())}
										/>
										<label className="form-check-label text-white f14">
											Run all Diagnostic checks
										</label>
									</div>
									{!this.state.allChecks && this.state.diagnosticChecks && this.state.diagnosticChecks.length ?
										<div className="w-30">
											<Search
												data={this.state.diagnosticChecks ? this.state.diagnosticChecks : []}
												applyTags={false}
												applyLiteDarkTags={true}
												topClassName={'bg-black5 align-self-center mr-1 border-gray5 rounded-5 f12'}
												searchClassName={'px-2 f12'}
												searchIconColor={'text-gray5 f12'}
												searchPlaceHolder={'Search....'}
												className={"bg-transparent text-white pl-0 form-control-sm f12"}
												filteredData={(filteredDiagnotics) => {
													this.setState({ filteredDiagnotics })
												}}
											/>
										</div>
									: null}
									
								</div>
								{!this.state.allChecks ?
									<div className="table-responsive rounded border mb-3">
										<table className="table table-sm mb-0 text-primary-color">
											<thead className="bg-dark3">
												<tr>
													<th className="text-nowrap w-5">
														<input 
															type="checkbox"
															checked={this.state.markAllDiagnostics}
															onChange={e => this.setState({ markAllDiagnostics: !this.state.markAllDiagnostics }, () => this.onChangeMarkAllDiagnostics())}
														/> All
													</th>
													<th className="w-40">Diagnostic Checks</th>
												</tr>
											</thead>
										</table>
										<div className="row mx-0 my-2">
											{this.state.filteredDiagnotics && this.state.filteredDiagnotics.map((item, index) => {
												return(
													<div className={`col-sm-6 d-flex py-1 ${index%2 ? "" : "border-right-info"}`}>
														<div className="w-5 align-self-center">
															<input
																type='checkbox'
																min='1'
																className={`form-check-input m-0 mt-n2 align-self-center `}
																onChange={e => this.onChangeDiagnotics(item.check)}
																checked={this.state.selectedDiagnoticChecks && this.state.selectedDiagnoticChecks.includes(item.check) ? true : false}
															/>
														</div>
														<div  className="w-95 hiddenEllipses">
															<span className="text-white">
																{item.name}<span className="text-primary-color" id={`description_`+index}> <span className="mx-2">{item.description && item.description !== "" ? "-" : ""}</span> {item.description}</span>
															</span>
															<UncontrolledTooltip placement='top' target={`description_`+index}>{item.description}</UncontrolledTooltip>
														</div>
													</div>
												)
											})}
										</div>
								
									</div>
								: null}
							</React.Fragment>
							: 
								this.state.selectedDiagnoticChecks && this.state.selectedDiagnoticChecks.length ?
									<div className="mt-3">
										<div className="table-responsive rounded border">
											<table className="table table-sm mb-0 text-primary-color">
												<thead className="bg-dark3">
													<tr>
														<th colspan="1" className="w-40">Diagnostic Checks</th>
														{/* <th className="text-nowrap w-20">Check</th> */}
														<th className="w-60">Description</th>
													</tr>
												</thead>
												<tbody className="bg-dark text-white">
													{this.state.diagnosticChecks && this.state.diagnosticChecks.map((item, index) => {
														return(
															this.state.selectedDiagnoticChecks.includes(item.check) ?
																<tr className={`${index === this.state.diagnosticChecks.length-1 ? "" : "border-bottom-dark"}`}>
																	<td>
																		{item.name}
																	</td>
																	<td>
																		<p className="mb-0" id={`description_`+index}>{item.description}</p>
																		<UncontrolledTooltip placement='top' target={`description_`+index}>{item.description}</UncontrolledTooltip>		
																	</td>
																</tr>
															: null
														)
													})}
												</tbody>
											</table>
										</div>
									</div>
								: 
								<label className="form-check-label text-white f14">Run all Diagnostic checks</label>
							}
							<div className="mt-3">
								<p className="mb-0 text-white">Description</p>
								<Input 
									type="textarea" 
									rows={3}
									maxLength={512}
									placeholder="enter description"
									className={`${this.props.actionType === "Run" ? "inputDarkThemeDisabled disabled" : "inputDarkTheme"}`}
									value={this.state.description ? this.state.description : ""}
									onChange={e => this.setState({ description: e.target.value })}
								/>
							</div>
							<div className="d-flex mt-3 mb-2">
								<button className={`btn bg-secondary p-2 text-white mr-3`} onClick={() => this.props.closePanel()}>Cancel</button>
								<button className={`btn bg-info p-2 text-white mr-3`} onClick={() => this.setState({ runDiagnosticsLoading: true }, () => this.startDiagnostics())}>
									<Spinner className={`mr-2 ${this.state.runDiagnosticsLoading ? "" : "displayNone"}`} color="white" size="sm" />Run Diagnostics
								</button>
								{this.props.actionType !== "Run" ?
									<button className={`btn bg-info p-2 text-white mr-2`} onClick={() => this.setState({ runSaveDiagnosticsLoading: true }, () => this.saveDiagnosticTemplate('save_run'))}>
										<Spinner className={`mr-2 ${this.state.runSaveDiagnosticsLoading ? "" : "displayNone"}`} color="white" size="sm" />Run & Save Template
									</button>
								: null}
								{/* <button className={`btn text-info mr-3`} onClick={() => this.setState({ saveDiagnosticLoading: true }, () => this.saveDiagnosticTemplate('save'))}>
									<Spinner className={`mr-2 ${this.state.saveDiagnosticLoading ? "" : "displayNone"}`} color="white" size="sm" />Save as Template
								</button> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
CreateDiagnostics.propTypes = {}

const mapStateToProps = state => {
	return {
		providers: state.filters.providers,
        accounts: state.filters.accounts,
		regions: state.filters.regions,
        tagKeys: state.filters.tagKeys,
	}
}

export default connect(mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
	startDiagnostics,
	listDiagnosticChecks,
	saveDiagnosticTemplate,
	getAllTagsKeys, 
	getAllTagsValues
})(withRouter(CreateDiagnostics))



