/*************************************************
 * Tvastar
 * @exports
 * @file governanceAction.js
 * @author Rajasekar // on 27/02/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import { API, Auth } from 'aws-amplify'
import axios from 'axios'

import {

    //PROFILE
    DLPENDPOINT,
    GET_DLP_METADATA_OVERALL_DISTRIBUTION,
    GET_METADATA_CROSS_ACCOUNT_DISTRIBUTION,
    GET_METADATA_TOTAL_COUNT,
    GET_METADATA_RANGE_DISTRIBUTION,
    GET_METADATA_OVERALL_DISTRIBUTION,

    //COMPLIANCE
    GET_DATA_FINDINGS_DAILY_COUNT,
    GET_DATA_FINDINGS_TOTAL_COUNT,

    //TREND
    GET_DLP_FILTER_PROPERTIES,
    START_DATA_EVENTS_DAILY_TREND,
    GET_DATA_EVENTS_DAILY_TREND,

    //THREAT
    DLPTHREATENDPOINT,
    DETECTORS_END_POINT,
    RESOLVE_ISSUE,

	GET_TREND_SCORE,

	// DLP threat start

	DLP_THREAT_END_POINT,
	DLP_THREAT_GET_DAY_WISE_COUNT,
	DLP_THREAT_GET_COUNT,
	DLP_THREAT_GET_STATUS_COUNT,
	DLP_THREAT_GET_TOTAL_COUNT,
	DLP_THREAT_GET_TRANSITION_TREND,
	DLP_THREAT_GET_PASS_FAIL_DETAILS,
	DLP_THREAT_GET_COMPLIANCE_CONTROL_DETAILS,
	DLP_THREAT_GET_COMPLIANCE_MINOR_CONTROL_DETAILS,
	DLP_THREAT_GET_COMPLIANCE_SUB_CONTROL_DETAILS,
	DLP_THREAT_DOWNLOAD_CHECKS_RESULTS,
	DLP_THREAT_GET_THREAT_DETAILS,
	DLP_THREAT_GET_TOP_BUCKETS,
	DLP_THREAT_GET_THREAT_COVERAGE,
	DLP_THREAT_GET_THREAT_COVERAGE_AND_TOP_STATS,

	// DLP threat end

} from '../../config'
import { ACTION_TYPES } from '../types'
import { myLog, capitalizeFirstLetter, numberFormater } from '../../utils/utility'
import moment from 'moment'


/**
 * Action to set selected filter
 */
export const setDlpFilters = (filter, selectedValue) => {
	return async (dispatch, getState) => {
		let selectedFilters = getState().dlp.selectedFilters
		selectedFilters = selectedValue
		dispatch({
			type: ACTION_TYPES.SET_SELECTED_DLP_FILTER,
			payload: selectedFilters,
		})
	}
}


/**
 * Action to set aiops props details
 */
 export const setDlpPropsDetails = (key, value) => {
	return async (dispatch, getState) => {
		let dlpPropsDetails = JSON.parse(JSON.stringify(getState().dlp.dlpPropsDetails))
		dlpPropsDetails[key] = value
		dispatch({
			type: ACTION_TYPES.SET_DLP_PROPS_DETAILS,
			payload: dlpPropsDetails,
		})
	}
}

/**
 * Action to get GET DLP METADATA OVERALL DISTRIBUTION
 * @param {Object} body
 * @param {Function} callback
 */
export const getDlpMetadataOverallDistribution = (body, callback) => {
	return async dispatch => {
		try {
            const dlpDistributuionList = await API.post(DLPENDPOINT, GET_DLP_METADATA_OVERALL_DISTRIBUTION, { body })
            let dlpDistributuionArray = []
            dlpDistributuionList.length && dlpDistributuionList.forEach(item => {
                if(item.group_name === 'sensitive' || item.group_name === 'compliant' || item.group_name === 'structured') {
                    let donut = formCirlceGraph(item.data)
                    item.donut = donut
                }
                dlpDistributuionArray.push(item)
            })
			// dispatch({
			// 	type: ACTION_TYPES.SET_DLP_PROFILE_OVERALL_DISTRIBUTION,
			// 	payload: dlpDistributuionArray,
			// })
			callback(true, dlpDistributuionArray)
		} catch (error) {
			// dispatch({
			// 	type: ACTION_TYPES.SET_DLP_PROFILE_OVERALL_DISTRIBUTION,
			// 	payload: [],
			// })
			callback(false, [])
		}
	}
}

export const getMetadataOverallDistribution = (body, callback) => {
    return async dispatch => {
		try {
            const metaDataOverallDistribution = await API.post(DLPENDPOINT, GET_METADATA_OVERALL_DISTRIBUTION, { body })
            let metaDataOverallDistributionArray = []
            metaDataOverallDistribution.length && metaDataOverallDistribution.forEach(item => {
                if(item.group_name === 'encrypted' || item.group_name === 'compressed' || item.group_name === 'structured') {
                    let donut = formCirlceGraph(item.data)
                    item.donut = donut
                }
                metaDataOverallDistributionArray.push(item)
            })
			// dispatch({
			// 	type: ACTION_TYPES.SET_METADATA_OVERALL_DISTRIBUTION,
			// 	payload: metaDataTotalCount,
			// })
			callback(true, metaDataOverallDistributionArray)
		} catch (error) {
			// dispatch({
			// 	type: ACTION_TYPES.SET_METADATA_OVERALL_DISTRIBUTION,
			// 	payload: [],
			// })
			callback(false, [])
		}
	}
}

const formCirlceGraph = (data) => {
	let series = [] 
	let labels = []
    let total = 0
	Object.entries(data).forEach(([key, value]) => {
		series.push(value)
		labels.push(key)
		total += value 
	})
	
	//let overAll = value.overall ? parseInt(value.overall.split('/')[0]) : 0
	let options = {
		stroke: {
			width: 0
		},
		chart: {
			  type: 'donut',
		},
		legend: {
			show: false,
		},
		dataLabels: {
			  enabled: false
		},
		fill: {
			type: 'gradient',
		},
		labels: labels,
		colors: ['#08B089', '#39BFA0', '#4A397C', '#6E6096'],
		//colors: ['#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'],
		tooltip: {
			enabled: false,
		},
		plotOptions: {
			pie: {
				donut: {
					size: '80%',
					labels: {
						show: true,
						value: {
							fontSize: '13px',
							fontWeight: 800,
							offsetY: 0,
							formatter: function (val) {
								return numberFormater(val)
							}
						},
						name: {
							fontSize: '13px',
							offsetY: -2,
							formatter: function (val) {
								return capitalizeFirstLetter(val)
							}
						},						
						total: {
							show: true,
							label: 'Total',
							fontSize: '13px',
							color: '#4d4848',
							formatter: function (w) {
								return numberFormater(total)
							}
						}
					}
				},      
			}
		},
	}

	let graph = {}
	graph.series = series
	graph.options = options
	graph.data = data
	return graph
}

export const getMetadataCrossAccountDistribution = (body, callback) => {
    return async dispatch => {
		try {
            const crossAccountDistribution = await API.post(DLPENDPOINT, GET_METADATA_CROSS_ACCOUNT_DISTRIBUTION, { body })
			// dispatch({
			// 	type: ACTION_TYPES.SET_CROSS_ACCOUNT_DISTRIBUTION,
			// 	payload: crossAccountDistribution,
            // })
            console.log(crossAccountDistribution)
			callback(true, crossAccountDistribution)
		} catch (error) {
			// dispatch({
			// 	type: ACTION_TYPES.SET_CROSS_ACCOUNT_DISTRIBUTION,
			// 	payload: [],
			// })
			callback(false, [])
		}
	}

}

export const getMetadataTotalCount = (body, callback) => {
    return async dispatch => {
		try {
            const metaDataTotalCount = await API.post(DLPENDPOINT, GET_METADATA_TOTAL_COUNT, { body })
			dispatch({
				type: ACTION_TYPES.SET_METADATA_TOTAL_COUNT,
				payload: metaDataTotalCount,
			})
			callback(true)
		} catch (error) {
			dispatch({
				type: ACTION_TYPES.SET_METADATA_TOTAL_COUNT,
				payload: [],
			})
			callback(false)
		}
	}
}

export const getMetadataRangeDistribution = (body, callback) => {
    return async dispatch => {
		try {
            const metaDataTotalCount = await API.post(DLPENDPOINT, GET_METADATA_RANGE_DISTRIBUTION, { body })
			dispatch({
				type: ACTION_TYPES.SET_METADATA_RANGE_DISTRIBUTION,
				payload: metaDataTotalCount,
			})
			callback(true)
		} catch (error) {
			dispatch({
				type: ACTION_TYPES.SET_METADATA_RANGE_DISTRIBUTION,
				payload: [],
			})
			callback(false)
		}
	}
}

export const getDataFindingsDailyCount = (body, callback) => {
	return async dispatch => {
		try {
            const dailyCount = await API.post(DLPENDPOINT, GET_DATA_FINDINGS_DAILY_COUNT, { body })
            let dailyCountArray = []
            if(body.aggregate_by.includes('entity_type')) {
                let graphData = {}
                dailyCount.length && dailyCount.forEach(item => {
                    Object.entries(item.data).forEach(([key, value]) => {
                        graphData[key] = formBarGraph(key, value, item.dates)
                    })
                    item.graphData = graphData
                    dailyCountArray.push(item)
                })
            } else {
                dailyCountArray = dailyCount
            }
			callback(true, dailyCountArray)
		} catch (error) {
			callback(false, [])
		}
	}
}

const formBarGraph = (key, data, dates) => {
    // let summaryGraphLabelsData = [];
    let graphData = data
    // let backgroundColor
    //let borderColor
    // let grayColor
    let graphLabels = dates
    // let dataItems = {}

    let labels = []
    let dataValues = []
    for(let x=0;x<graphLabels.length;x++) {
        let dataItems = {}
        dataItems.x = moment(graphLabels[x]).format('DD MMM')
        dataItems.y  = graphData[x]
        labels.push(moment(graphLabels[x]).format('DD MMM'))
        dataValues.push(dataItems)
    }			
    
    let failSum = graphData.reduce(function(a, b){
        return a + b;
    }, 0);

    let gData = []
    let dataRow = {}
    dataRow.data = dataValues
    dataRow.name = capitalizeFirstLetter(key)
    gData.push(dataRow)

    let trend_bar_options = {
        plotOptions: {
            bar: {
                barHeight: '100%',
                columnWidth: '50%',
                distributed: false,
                rangeBarOverlap: false,
                colors: {
                    backgroundBarColors: ['#f7f7f7'],
                    backgroundBarOpacity: 1,
                    backgroundBarRadius: 2,
                }        
            },          
        },
        tooltip: {
            enabled: true,
            enabledOnSeries: false,
            shared: false,
            followCursor: false,
            intersect: false,
            inverseOrder: false,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                let val  = series[seriesIndex][dataPointIndex];
                let labelName = w.globals.initialSeries[0].data[dataPointIndex].x
                let name = w.globals.seriesNames[seriesIndex]
                return '<div class="arrow_box"> '+ labelName +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  capitalizeFirstLetter(name) + '  '+ val + '</span> </div>'
            },
            fillSeriesColor: false,
            theme: false,
            style: {
                fontSize: '12px',				
            },
            onDatasetHover: {
                highlightDataSeries: false,
            },
            x: {
                show: false,
                format: 'dd MMM',
                formatter: undefined,
            },
            y: {
                show: false,
                formatter: undefined,
                title: {
                    formatter: (seriesName) => seriesName,
                },
            },
            marker: {
                show: false,
            }
        },
        chart: {
            zoom: {
                enabled: false,
            },
            height: 350,
            type: 'bar',
            toolbar: {
                show: false,
            },
            sparkline: {
                enabled: false
            },
        },
        colors: ['#bca1f0'],
        dataLabels: {
            enabled: false,
        },
        title: {
            text: ''
        },
        
        grid: {  	
            padding: {
                left: -10,
                right: 0,
                top: 0,
                bottom: 0,
            },			        
            xaxis: {
                lines: {
                    show: false
                },
            },
            yaxis: {
                lines: {
                    show: false
                }
            },
        },
        xaxis: {
            type: 'datetime',
            categories: [],
            position: 'bottom',
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            tooltip: {
                enabled: false,
            },
            labels: {
                show: false,
                style: {
                    fontSize: '9px'
                }
            }
        },
        yaxis: {
            tickAmount: 2,
            axisTicks: {
                show: false,
            },
            axisBorder: {
                show: false
            },			  
            labels: {
                show: false,
                formatter: function (val) {
                return val;
                },
                style: {
                    fontSize: '9px'
                }
            }
        }
    }
    //this.setState({ tbar_transition_data: newData , new_bar_transition_option: trend_bar_options, newTransitionFailSum: failSum });

    let graph = {}
    graph.data = gData
    graph.options = trend_bar_options
    graph.failSum = failSum

    return graph
}

export const getDataFindingsTotalCount = (body, callback) => {
	return async dispatch => {
		try {
            const totalCount = await API.post(DLPENDPOINT, GET_DATA_FINDINGS_TOTAL_COUNT, { body })
			callback(true, totalCount)
		} catch (error) {
			callback(false, [])
		}
	}
}

export const getDlpFilterProperties = (body, callback) => {
	return async dispatch => {
		try {
            const trendFilter = await API.post(DLPENDPOINT, GET_DLP_FILTER_PROPERTIES, { body })
            dispatch({
                type: ACTION_TYPES.SET_DLP_FILTER_PROPERTIES,
				payload: trendFilter,
			})
			callback(true, trendFilter)
		} catch (error) {
            dispatch({
				type: ACTION_TYPES.SET_DLP_FILTER_PROPERTIES,
				payload: [],
			})
			callback(false, [])
		}
	}
}

export const startDataEventsDailyTrend = (body, callback) => {
	return async dispatch => {
		try {
            const startTrend = await API.post(DLPENDPOINT, START_DATA_EVENTS_DAILY_TREND, { body })
            dispatch({
                type: ACTION_TYPES.SET_START_DAILY_TREND,
				payload: startTrend,
			})
			callback(true)
		} catch (error) {
            dispatch({
				type: ACTION_TYPES.SET_START_DAILY_TREND,
				payload: {},
			})
			callback(false)
		}
	}
}

export const getDataEventsDailyTrend = (body, callback) => {
	return async dispatch => {
		try {
            const getTrend = await API.post(DLPENDPOINT, GET_DATA_EVENTS_DAILY_TREND, { body })
            if(Object.entries(getTrend).length) {
                if(getTrend.status === 'FAILED' || getTrend.status === 'CANCELLED' || getTrend.status === 'SUCCEEDED') {
                    dispatch({
                        type: ACTION_TYPES.SET_START_DAILY_TREND,
                        payload: {},
                    })
                }
            }
            dispatch({
                type: ACTION_TYPES.SET_DATA_DAILY_TREND,
				payload: getTrend,
			})
			callback(true, getTrend)
		} catch (error) {
            dispatch({
                type: ACTION_TYPES.SET_DATA_DAILY_TREND,
				payload: {},
			})
			callback(false, {})
		}
	}
}




//Threat Start

/**
 * Action to get total count
 * @param {Object} body
 * @param {Function} callback
 */
export const getTotalCount = (body, callback) => {
	return async () => {
		try {
			const totalCount = await API.post(DLPTHREATENDPOINT, DLP_THREAT_GET_TOTAL_COUNT, { body })
			callback(true, totalCount)
		} catch (error) {
			myLog('totalCount', error)
			callback(false)
		}
	}
}


/**
 * Action to get day wise count
 * @param {Object} body
 * @param {Function} callback
 */
export const getDayWiseCount = (body, callback) => {
	return async () => {
		try {
			const totalCount = await API.post(DLPTHREATENDPOINT, DLP_THREAT_GET_DAY_WISE_COUNT, { body })
			callback(true, totalCount)
		} catch (error) {
			callback(false)
			myLog('daywiseCount', error)
		}
	}
}


/**
 * Action to get close issue
 * @param {Object} body
 * @param {Function} callback
 */
export const resolveIssue = (body, callback) => {
	return async dispatch => {
		try {
			const url = DETECTORS_END_POINT + RESOLVE_ISSUE;
			const resolveIssue = await axios.post(url, body, {
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}`
				}
			})
			callback(true, resolveIssue)
		} catch (error) {
			let respone = {}
			respone.message = error.response.data.message
			respone.status = error.response.data.status
			callback(false, respone)
		}
	}
}


/**
 * Action to get compliance Control details
 * @param {Object} body
 * @param {Function} callback
 */
export const getComplianceControlDetails = (body, callback) => {
	return async () => {
		try {
			const getComEvent = await API.post(DLPTHREATENDPOINT, DLP_THREAT_GET_COMPLIANCE_CONTROL_DETAILS, { body })

			let result = null
			if(getComEvent.length > 0){
				 result = getComEvent[0].controls;
			}
			callback(true, result)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to get compliance sub control details
 * @param {Object} body
 * @param {Function} callback
 */
export const getComplianceSubControlDetails = (body, callback) => {
	return async () => {
		try {
			const results = await API.post(DLPTHREATENDPOINT, DLP_THREAT_GET_COMPLIANCE_SUB_CONTROL_DETAILS, { body })			
			let response = null
			var subControl ={};
			results[0].controls.forEach((item)=>{
			  if(item.total_sub_controls >0){
				subControl[item.control] = item;
			  }
			})
			
			if(Object.entries(subControl).length > 0){
			  response = subControl;
			}
			callback(true, response)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to get compliance minor control details
 * @param {Object} body
 * @param {Function} callback
 */
export const getComplianceMinorControlDetails = (body, callback) => {
	return async () => {
		try {
			const results = await API.post(DLPTHREATENDPOINT, DLP_THREAT_GET_COMPLIANCE_MINOR_CONTROL_DETAILS, { body })
			var minorControl ={};
			results[0].controls.forEach((item)=>{
				minorControl[item.control] = item;
			})
			callback(true, minorControl)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to get compliance by event
 * @param {Object} body
 * @param {Function} callback
 */
export const getPassFailDetails = (body, callback) => {
	return async () => {
		try {
			const getComEvent = await API.post(DLPTHREATENDPOINT, DLP_THREAT_GET_PASS_FAIL_DETAILS, { body })
			callback(true, getComEvent)
		} catch (error) {
			callback(false)
		}
	}
}

/** Action to get Transition Trend
* @param {Object} body
* @param {Function} callback
*/
export const getTransitionTrend = (body, callback) => {
	return async () => {
		try {
			const results = await API.post(DLPTHREATENDPOINT, DLP_THREAT_GET_TRANSITION_TREND, { body })
			callback(true, results)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to get score
 * @param {Object} body
 * @param {Function} callback
 */
export const getScore = (body, callback) => {
	return async () => {
		try {
			const score = await API.post(DLPTHREATENDPOINT, GET_TREND_SCORE, { body })
			callback(true, score)
		} catch (error) {
			myLog('score', error)
			callback(false)
		}
	}
}


/**
 * Action to get count
 * @param {Object} body
 * @param {Function} callback
 */
export const getCount = (body, callback) => {
	return async () => {
		try {
			const get_count = await API.post(DLPTHREATENDPOINT, DLP_THREAT_GET_COUNT, { body })
			callback(true, get_count)
		} catch (error) {
			myLog('getCount', error)
			callback(false)
		}
	}
}

/**
 * Action to get bill count
 * @param {Object} body
 * @param {Function} callback
 */
export const getStatusCount = (body, callback) => {
	return async () => {
		try {
			const issue_status_count = await API.post(DLPTHREATENDPOINT, DLP_THREAT_GET_STATUS_COUNT, { body })
			callback(true, issue_status_count)
		} catch (error) {
			myLog('getIssueStatusCount', error)
			callback(false)
		}
	}
}

/**
//  * Action to download check results for bucket
//  * @param {Object} body
//  * @param {Function} callback
//  */
//  export const downloadChecksResults = (body, callback) => {
// 	return async () => {
// 		try {
// 			const results = await API.post(DLPTHREATENDPOINT, DLP_THREAT_DOWNLOAD_CHECKS_RESULTS, { body })		
// 			callback(true, results)
// 		} catch (error) {
// 			callback(false)
// 		}
// 	}
// }


/**
 * Action to download chedks results
 * @param {Object} body
 * @param {Function} callback
 */
 export const downloadChecksResults = (body, callback) => {
	return async dispatch => {
		try {
			const apiUrl = DLP_THREAT_END_POINT + DLP_THREAT_DOWNLOAD_CHECKS_RESULTS;

			let contentType = 'application/octet-stream'
			if(body.response_format === 'json') {
				contentType = 'application/json'
			} 
			
			const download = await axios.post(apiUrl, body, {
				headers: {
					'Content-Type': contentType,
					Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}`
				}
			})

			let data = download.data;
			if(body.response_format === 'json') {
				data = JSON.stringify(download.data);
			}
			const url = window.URL.createObjectURL(new Blob([data])); 
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'report.'+body.response_format); //or any other extension
			document.body.appendChild(link);
			link.click();
			callback(true, download)
		} catch (error) {
			let respone = {}
			respone.message = error.response.data.message
			respone.status = error.response.data.status
			callback(false, respone)
		}
	}
}

/**
 * Action to get threat details
 * @param {Object} body
 * @param {Function} callback
 */
 export const getThreatDetails = (body, callback) => {
	return async () => {
		try {
			const results = await API.post(DLPTHREATENDPOINT, DLP_THREAT_GET_THREAT_DETAILS, { body })		
			callback(true, results)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to get top buckets
 * @param {Object} bodygetTopBuckets
 * @param {Function} callback
 */
 export const getTopBuckets = (body, callback) => {
	return async () => {
		try {
			const results = await API.post(DLPTHREATENDPOINT, DLP_THREAT_GET_TOP_BUCKETS, { body })		
			callback(true, results)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to get threat coverage
 * @param {Object} body
 * @param {Function} callback
 */
 export const getThreatCoverage = (body, callback) => {
	return async () => {
		try {
			const results = await API.post(DLPTHREATENDPOINT, DLP_THREAT_GET_THREAT_COVERAGE, { body })		
			callback(true, results)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to get threat coverage
 * @param {Object} body
 * @param {Function} callback
 */
 export const getThreatCoverageAndTopStats = (body, callback) => {
	return async () => {
		try {
			const results = await API.post(DLPTHREATENDPOINT, DLP_THREAT_GET_THREAT_COVERAGE_AND_TOP_STATS, { body })		
			callback(true, results)
		} catch (error) {
			callback(false)
		}
	}
}

//Threat End