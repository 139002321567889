/*************************************************
 * Tvastar
 * @exports
 * @file aiopsReducer.js
 * @author Prakash // on 05/02/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from '../../actions/types'

const INITIAL_STATE = {
    costPropsDetails: {},
    costTagKeys: [],
}

/**
 * To store assets list in redux store
 * @param {Object} state
 * @param {Object} action
 */
export const costReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
        case ACTION_TYPES.SET_COST_PROPS_DETAILS:
            return {
                ...state,
                costPropsDetails: { ...action.payload },
            }
        case ACTION_TYPES.SET_COST_TAG_KEYS:
            return {
                ...state,
                costTagKeys: [ ...action.payload],
            }
		default:
			return state
	}
}
