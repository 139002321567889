import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import CommonDropdown from '../../../common/CommonDropdown'
import { getTotalCount } from '../../../../actions/detectors/detectorsAction'
import { getComplianceControlDetails,
    getComplianceSubControlDetails,
    getComplianceMinorControlDetails } from '../../../../actions/detectors/complianceDashboardAction'

import { Spinner } from 'reactstrap'

class ComplianceFilterSection extends React.Component {
    constructor(props) {
        super(props)
        console.log('complianceProps', props)
        this.state = {
            rowList: [true],
            showLoading: false,
            selectedCompliance: '',
            selectedMajorControl: [],
            selectedControl: [],
            complianceArray: [],
            controlsTotalArray: [],
            controlArray: [],
            showSelectedCompliance: []
        };
    };

    componentDidMount = () => {
        let params = {}
		if (this.props.selectedProvider !== '' && this.props.selectedProvider !== 'all') {
			params.provider = this.props.selectedProvider
		}
		if (!this.props.selectedDuration !== '' && this.props.selectedDuration !== 'all') {
			params.duration = this.props.selectedDuration
		}
		if (this.props.selectedAccount !== '' && this.props.selectedAccount !== 'all') {
			params.account_id = this.props.selectedAccount
		}
		
		params.aggregate_by = 'compliance.complianceId'
		this.props.getTotalCount(params, (promise, getAllCounts) => {
			if (promise) {
                let complianceArray = []
                if(getAllCounts !== null) {
                    getAllCounts.forEach((item, i) => {
                        item.value = '0';
                        let dataRow = {}
                        dataRow.label = item.complianceId
                        dataRow.value = item.complianceId
                        complianceArray.push(dataRow)
                    })
                }
                this.setState({ complianceArray })
			} else {				
                this.setState({ complianceArray: [] })
			}
		})
    }

    getComplianceControlDetails = (index) =>{
        let params = {}
        //params.compliance = [{"complianceId":this.state.selectedCompliance}]
        params.compliance = [{"complianceId":this.state['selectedCompliance_'+index]}]
        // if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
		// 	params.account_id = this.state.selectedAccount
        // }
        if (this.props.selectedProvider !== '' && this.props.selectedProvider !== 'all') {
			params.provider = this.props.selectedProvider
		}
        
		if (!this.props.selectedDuration !== '' && this.props.selectedDuration !== 'all') {
			params.duration = this.props.selectedDuration
		}
		
		this.props.getComplianceControlDetails(params, (promise, complianceControlDetails) => {
			if (promise) {
                this.setState({ complianceControlDetails },
                   () =>  this.getComplianceSubControlDetails(index)
                );
			} else {
                this.setState({ complianceControlDetails: [] })
			}
		})
    }    

    getComplianceSubControlDetails = (index) =>{
        let params = {}
        //params.compliance = [{"complianceId":this.state.selectedCompliance}]
        
        params.compliance = [{"complianceId":this.state['selectedCompliance_'+index]}]
        // if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
		// 	params.account_id = this.state.selectedAccount
        // }
        if (this.props.selectedProvider !== '' && this.props.selectedProvider !== 'all') {
			params.provider = this.props.selectedProvider
		}
        
		if (!this.props.selectedDuration !== '' && this.props.selectedDuration !== 'all') {
			params.duration = this.props.selectedDuration
		}
		
		this.props.getComplianceSubControlDetails(params, (promise, complianceSubControlDetails) => {
			if (promise) {
                if(complianceSubControlDetails === null) {
                    this.setState({ complianceSubControlDetails });
                    this.getComplianceMinorControlDetails(index)
                } else {
                    let controlsTotalArray = []
                    let controlsObj = {}
                    this.state.complianceControlDetails.forEach((item,i) =>{
                        item.label = item.control
                        // +' ('+item.count+')'
                        item.value = item.control
                        item.minor_control = []
                        let subArray = []
                        complianceSubControlDetails && complianceSubControlDetails[item.control] &&
                        complianceSubControlDetails[item.control].sub_controls.forEach(subCtrl => {
                            let sub_control = {}
                            sub_control.count = subCtrl.count
                            sub_control.description = subCtrl.description
                            sub_control.detectors = subCtrl.detectors
                            sub_control.control = subCtrl.sub_control
                            sub_control.label = subCtrl.sub_control
                            sub_control.value = subCtrl.sub_control
                            
                            const subCtrlColor = this.subCtrlColor(item.control, subCtrl.sub_control)
                            let passCount = 0
                            let failCount = 0
                            let naCount = 0
                            let bgColor = '';
                            if(subCtrlColor) {
                                passCount = subCtrlColor.pass_count
                                failCount = subCtrlColor.fail_count
                                naCount = subCtrlColor.NA_count
                                bgColor = subCtrlColor.bgColor
                            }
                            sub_control.passCount = passCount
                            sub_control.failCount = failCount
                            sub_control.naCount = naCount
                            sub_control.bgColor = bgColor
                            subArray.push(sub_control)
                        })
                        item.sub_control = subArray
                        controlsObj[item.control] = item
                        controlsTotalArray.push(item)
                    })
                    console.log(controlsTotalArray)
                    this.setState({ complianceSubControlDetails, ['controlsTotalArray_'+index]: controlsTotalArray, filteredArray: controlsObj, showLoading: false  });
                }
			} else {
                this.setState({ complianceSubControlDetails: [] })
			}
		})
    }    
    
    subCtrlColor = (control, subControl) => {
        if(this.state.subControlCounts){
            for(let i=0;i<this.state.subControlCounts.length;i++){
                let item = this.state.subControlCounts[i]
                let subCtrlData = {}
                if(control === item.authorityMajorControl && subControl === item.authoritySubControl) {
                    // if (item.fail_count > 0) {
                    //     return "border-danger";
                    // } else if(item.fail_count === 0) {
                    //     return "border-success";
                    // } else {
                    //     return "border-dark"
                    // }
                    subCtrlData.NA_count = item.NA_count; 
                    subCtrlData.pass_count = item.pass_count; 
                    subCtrlData.fail_count = item.fail_count;                  
                    let bgColor = "border-dark";
                    if (item.fail_count > 0) {
                        bgColor = "border-danger";
                    } else if(item.fail_count === 0) {
                        bgColor = "border-success";
                    }
                    subCtrlData.bgColor = bgColor

                    return subCtrlData;
                    //break;
                }
            }
        }
    }

    getComplianceMinorControlDetails = (index) =>{
        let params = {}
        //params.compliance = [{"complianceId":this.state.selectedCompliance}]
        
        params.compliance = [{"complianceId":this.state['selectedCompliance_'+index]}]
        // if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'all') {
        // 	params.account_id = this.state.selectedAccount
        // }
        if (this.props.selectedProvider !== '' && this.props.selectedProvider !== 'all') {
			params.provider = this.props.selectedProvider
		}
        
		if (!this.props.selectedDuration !== '' && this.props.selectedDuration !== 'all') {
			params.duration = this.props.selectedDuration
		}
        
        this.props.getComplianceMinorControlDetails(params, (promise, complianceMinorControlDetails) => {
            if (promise) {
                let controlsTotalArray = []
                let controlsObj = {}
                this.state.complianceControlDetails.forEach((item,i) =>{
                    item.label = item.control
                    // +' ('+item.count+')'
                    item.value = item.control
                    item.sub_control = []
                    let minorArray = []
                    complianceMinorControlDetails && complianceMinorControlDetails[item.control] &&
                    complianceMinorControlDetails[item.control].minor_controls.forEach(minCtrl => {
                        let minor_control = {}
                        minor_control.count = minCtrl.count
                        minor_control.description = minCtrl.description
                        minor_control.detectors = minCtrl.detectors
                        minor_control.control = minCtrl.minor_control
                        minor_control.label = minCtrl.minor_control
                        minor_control.value = minCtrl.minor_control
                        
                        const minCtrlColor = this.minCtrlColor(item.control, minCtrl.minor_control)
                        let passCount = 0
                        let failCount = 0
                        let naCount = 0
                        let bgColor = '';
                        if(minCtrlColor) {
                            passCount = minCtrlColor.pass_count
                            failCount = minCtrlColor.fail_count
                            naCount = minCtrlColor.NA_count
                            bgColor = minCtrlColor.bgColor
                        }
                        minor_control.passCount = passCount
                        minor_control.failCount = failCount
                        minor_control.naCount = naCount
                        minor_control.bgColor = bgColor
                        minorArray.push(minor_control);
                    })

                    item.minor_control = minorArray
                    controlsObj[item.control] = item
                    controlsTotalArray.push(item)
                })
                console.log('minor',controlsTotalArray)
                this.setState({ complianceMinorControlDetails, ['controlsTotalArray_'+index]: controlsTotalArray, filteredArray: controlsObj, showLoading: false });
            } else {
                this.setState({ complianceMinorControlDetails: [] })
            }
        })
    }

    minCtrlColor = (control, minorControl) => {
        if(this.state.minorControlCounts){
            for(let i=0;i<this.state.minorControlCounts.length;i++){
                let item = this.state.minorControlCounts[i]
                let minCtrlData = {}
                if(control === item.authorityMajorControl && minorControl === item.authorityMinorControl) {  
                    minCtrlData.NA_count = item.NA_count; 
                    minCtrlData.pass_count = item.pass_count; 
                    minCtrlData.fail_count = item.fail_count;                  
                    let bgColor = "border-dark";
                    if (item.fail_count > 0) {
                        bgColor = "border-danger";
                    } else if(item.fail_count === 0) {
                        bgColor = "border-success";
                    }
                    minCtrlData.bgColor = bgColor

                    return minCtrlData;
                    //break;
                }
            }
        }
    }
    
    selectedOptionFunction = (label, index, value) => {
        this.setState({ showLoading: true })
        let elementExist = 0
        if(label === 'Compliance') {
            this.setState({ ['selectedCompliance_'+index]: value, ['controlsTotalArray_'+index]: [], controlArray: [], selectedMajorControl: [], selectedControl: [], resetMenu: false, ['showSelectedCompliance_'+index]: [value] },
                () => this.getComplianceControlDetails(index)
            )
        } else if(label === 'Major_Controls') {
            let totalArray = this.state['selectedMajorControl_'+index] ? this.state['selectedMajorControl_'+index] : []; 
            if(!totalArray.length) {
                this.setState({ ['showSelectedCompliance_'+index]: [] })
            }
            totalArray.forEach((row, index) => {
                if(row === value) {
                    elementExist = 1
                    totalArray.splice(index, 1);
                }
            })
            if(elementExist === 0) {
                let showSelectedComplianceVar = this.state['selectedCompliance_'+index]+' '+ value
                this.setState(prevState => ({
                    ['selectedMajorControl_'+index]: this.state['selectedMajorControl_'+index] ? [...prevState['selectedMajorControl_'+index], value] : [value],
                    ['showSelectedCompliance_'+index]: this.state['showSelectedCompliance_'+index] ? this.state['showSelectedCompliance_'+index].length === 1 ? [...prevState['showSelectedCompliance_'+index], showSelectedComplianceVar] : this.state['showSelectedCompliance_'+index] : [showSelectedComplianceVar]
                }),
                    () => this.getControls(index) 
                )
            } else {
                this.setState({ ['selectedMajorControl_'+index]: totalArray },
                    () => this.getControls(index) 
                )
            }
        } else if(label === 'Controls') {
            this.setState({ showLoading: false })
            let totalArray = this.state['selectedControl_'+index] ? this.state['selectedControl_'+index] : []; 
            if(!totalArray.length) {
                this.setState({ ['showSelectedCompliance_'+index]: [], showLoading: false })
            }
            totalArray.forEach((row, index) => {
                if(row === value) {
                    elementExist = 1
                    //totalArray.splice(index, 1);
                }
            })
            if(elementExist === 0) {
                let showSelectedComplianceVar = this.state['selectedCompliance_'+index]+' '+ value
                this.setState(prevState => ({
                    ['selectedControl_'+index]: this.state['selectedControl_'+index] ? [...prevState['selectedControl_'+index], value] : [value],
                    ['showSelectedCompliance_'+index]: this.state['showSelectedCompliance_'+index] ? [...prevState['showSelectedCompliance_'+index], showSelectedComplianceVar] : [showSelectedComplianceVar]
                }))
            }
        }
    }

    getControls = (index) => {
        // let controls = this.state['selectedMajorControl_'+index]
        let controlArray = []
        this.state.filteredArray && Object.entries(this.state.filteredArray).length && Object.entries(this.state.filteredArray).forEach(([key, value]) => {
            console.log(this.state['selectedMajorControl_'+index],index)
            this.state['selectedMajorControl_'+index].forEach(control => {
                if(key === control) {
                    if(value.sub_control.length) {
                        value.sub_control.forEach(sub => {
                            controlArray.push(sub)
                        })
                    } else {
                        value.minor_control.forEach(minor => {
                            controlArray.push(minor)
                        })
                    }                    
                }
            })            
        })
        this.setState({ ['controlArray_'+index]: controlArray, showLoading: false })
        
    }

    applyFilter = () => {
        let complianceResultArray = []
        this.state.rowList.forEach((item, i) => {
            let obj = {}
            obj['label'] = 'Compliance'
            obj['majorControl'] = this.state['selectedMajorControl_'+i]
            obj['selectedControl'] = this.state['selectedControl_'+i]
            if(!this.state['selectedControl_'+i] && !this.state['selectedMajorControl_'+i]) {
                obj['selectedCompliance'] = this.state['selectedCompliance_'+i]
            }
            complianceResultArray.push(obj)
        })
        //obj['majorControls'] = this.state.selectedMajorControl
        // let selectedComplianceArray = [this.state.selectedCompliance]
        // obj['controls'] = this.state.selectedControl.length ?  this.state.selectedControl : this.state.selectedMajorControl.length ? this.state.selectedMajorControl : selectedComplianceArray
        
        return this.props.data(complianceResultArray)
    }

    closeFilter = () => {
        return this.props.data(false);
    }

    removeFilter = (type, majorCtrlIndex, index) => {
        if(this.state['showSelectedCompliance_'+majorCtrlIndex] && this.state['showSelectedCompliance_'+majorCtrlIndex].length) {
            let totalArray = '' 
            totalArray = this.state['showSelectedCompliance_'+majorCtrlIndex]; 
            totalArray.splice(index, 1);
            this.setState({ ['showSelectedCompliance_'+majorCtrlIndex] : totalArray });
        }
        
        if(this.state['selectedControl_'+majorCtrlIndex] && this.state['selectedControl_'+majorCtrlIndex].length) {
            this.setState({ ['selectedControl_'+majorCtrlIndex]: this.state['selectedControl_'+majorCtrlIndex].splice(index, 1) });
        } else if(this.state['selectedMajorControl_'+majorCtrlIndex] && this.state['selectedMajorControl_'+majorCtrlIndex].length) {
            this.setState({ ['selectedMajorControl_'+majorCtrlIndex]: this.state['selectedMajorControl_'+majorCtrlIndex].splice(index, 1) });
        } else {
            this.setState({ ['selectedCompliance_'+majorCtrlIndex] : '' })
        }
    }    

    add = () => {
        let rowList = [...this.state.rowList];
        rowList.push(true);
        this.setState({ rowList });
    }

    remove = (i) => {
        // let rowList = [...this.state.rowList];
        // rowList.splice(i, 1);
        this.setState({ ['selectedMajorControl_'+i]: [], ['selectedControl_'+i]: [], ['selectedCompliance_'+i]: '', ['row_'+i]: true });
    }

    render() {
        return (
            <div className="cardDropSection">
                <div className='container-fluid'>
                    <div className="row mt-3">
                        <div className="col-sm-10 align-self-center">
                            <p className="text-white mb-0">Lorem ipsum text industry since 1500s</p>
                        </div>
                        <div className="col-sm-2 text-right">
                            <span className='far fa-plus-circle cursor-pointer text-white f18' onClick={() => this.add()}></span>
                        </div>
                    </div>

                    {this.state.rowList.map((x, i) => {
                        return (
                            <div className={`${this.state['row_'+i] ? 'displayNone' : ''}`} key={i}>
                                <div className='row my-3'>
                                    <div className="col-sm-2 pr-0">
                                        <CommonDropdown 
                                            data = {this.selectedOptionFunction.bind(this, 'Compliance', i)}
                                            hideHeader = {false}
                                            headerName = {'Select'}
                                            dropOptions = {this.state.complianceArray}
                                            displayMenu = {false}
                                            isMultiSelect = {false}
                                            isDefaultDropdown = {false}
                                            styleTop={'top57'}
                                            searchOption={false}
                                            label={'Compliance'}
                                            disabled={this.state.showLoading}
                                        />
                                    </div>
                                    <div className="col-sm-5 pr-0">
                                        <CommonDropdown 
                                            data = {this.selectedOptionFunction.bind(this, 'Major_Controls', i)}
                                            hideHeader = {false}
                                            headerName = {'Select'}
                                            dropOptions = {this.state['controlsTotalArray_'+i] ? this.state['controlsTotalArray_'+i] : []}
                                            displayMenu = {false}
                                            resetMenu = {this.state.resetMenu}
                                            isMultiSelect = {true}
                                            isDefaultDropdown = {false}
                                            styleTop={'top57'}
                                            searchOption={true}
                                            label={'Major Controls'}
                                            disabled={this.state.showLoading}
                                        />
                                    </div>
                                    <div className="col-sm-4 pr-0">
                                        <CommonDropdown 
                                            data = {this.selectedOptionFunction.bind(this, 'Controls', i)}
                                            hideHeader = {false}
                                            headerName = {'Select'}
                                            dropOptions = {this.state['controlArray_'+i] ? this.state['controlArray_'+i] : []}
                                            displayMenu = {false}
                                            isMultiSelect = {false}
                                            isDefaultDropdown = {false}
                                            resetMenu = {this.state.resetMenu}
                                            styleTop={'top57'}
                                            searchOption={true}
                                            label={'Controls'}
                                            disabled={this.state.showLoading}
                                        />
                                    </div>
                                    <div className="col-sm-1 align-self-end">
                                        <label>&nbsp;</label>
                                        <span className={`far fa-trash cursor-pointer text-white f18 ${i===0 ? 'displayNone' : ''}`} onClick={() => this.remove(i)}></span>
                                    </div>
                                    <div className="d-flex mb-2 col-sm-12">
                                        <div className="mr-3 minWidth300 align-self-center">
                                            {this.state['showSelectedCompliance_'+i] && this.state['showSelectedCompliance_'+i].length ? 
                                                this.state['showSelectedCompliance_'+i].map((item, index) => {
                                                    return (
                                                        <span className='badge badge-primary custom-badge ml-2 text-white p-1'> {item}
                                                            <i className='ml-1 fal fa-times cursorPointer'onClick={() => this.removeFilter('compliance', i,  index)}></i>
                                                        </span> 
                                                    )       
                                                })
                                            :
                                                null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex mb-2">
                                    <div className="mr-3 align-self-center">
                                        {this.state.selectedControl && this.state.selectedControl.length > 10 ?
                                            <p className="text-white col-sm-12 f10 pb-0 mb-0">  {this.state.selectedControl && this.state.selectedControl.length+' Selected '}</p>
                                        :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <div className='row mt-3 mb-3'>
                        <div className="col-sm-6"></div>
                        <div className="col-sm-6 justify-content-end d-flex">
                            {this.state.showLoading ? <Spinner className='align-self-center mr-2' color='light' size='sm' /> : null }
                            <span className="dropCancelBtn d-block p-2 mr-2" onClick={ () => this.closeFilter()}>
                                Cancel
                            </span>
                            <span className="dropApplyBtn d-block p-2" onClick={ () => this.applyFilter()}>
                                Apply
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

/**
 * Type of the props used in the component
 */
ComplianceFilterSection.propTypes = {
    getTotalCount: PropTypes.func,
    getComplianceControlDetails: PropTypes.func,
    getComplianceSubControlDetails: PropTypes.func,
    getComplianceMinorControlDetails: PropTypes.func,
}

const mapStateToProps = state => {
	return { }
}

export default connect(mapStateToProps, { 
        getTotalCount, getComplianceControlDetails, getComplianceSubControlDetails, getComplianceMinorControlDetails 
    }
)(ComplianceFilterSection);
