/*************************************************
 * Tvastar
 * @exports
 * @file SidePanel.js
 * @author Rajasekar // on 22/04/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'

class SidePanel extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	render() {
		return (
			<div className='advanced-search'>
				<div className='search-content'>
					<div className='header-search flex-column bd-highlight'>
						<div className='w-100 d-flex'>
							<div className='text-right flex-fill bd-highlight'>
								<i className='far fa-times cursor-pointer' onClick={() => this.props.closeSidePanel()} />
							</div>
						</div>
						<div className='w-100 small d-flex'>
							<div className='mr-3'>
								<p className='m-0'>Account Id : {this.props.generated.account_id}</p>
								<p className='m-0'>Description : {this.props.generated.description}</p>
							</div>
							<div>
								<p className='m-0'>Policy Name: {this.props.generated.policy_name}</p>
							</div>
						</div>
					</div>
					<div className='container-fluid p-0 overflow-auto'>
						<ul className='nav nav-tabs bd-highlight' id='myTab' role='tablist'>
							<li className='nav-item'>
								<a
									className='nav-link active'
									id='assets-tab'
									data-toggle='tab'
									href='#assets'
									role='tab'
									aria-controls='assets'
									aria-selected='true'
								>
									Policy Generated
								</a>
							</li>
						</ul>
						<div className='tab-content p-2' id='myTabContent'>
							<div className='tab-pane fade show active p-3' id='assets' role='tabpanel' aria-labelledby='gov-sideab'>
								<pre>{JSON.stringify(this.props.generated.policy, null, 2)}</pre>
							</div>
						</div>
						<div className='d-flex justify-content-end'>
							<button
								className='btn btn-primary mr-3 mb-3'
								onClick={() => this.props.onClickCreatePolicy()}
								disabled={this.props.creatingPolicy}
							>
								Create Policy {this.props.creatingPolicy ? <Spinner size='sm' color='dark' /> : null}
							</button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
/**
 * Type of the props used in the component
 */
SidePanel.propTypes = {
	closeSidePanel: PropTypes.func,
	onClickCreatePolicy: PropTypes.func,
	generated: PropTypes.object,
	creatingPolicy: PropTypes.bool,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		createPolicyDetails: state.iam.createPolicyDetails,
	}
}

export default connect(mapStateToProps, null)(SidePanel)
