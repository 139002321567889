/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Metrics.js
 * @author Prakash // on 22/02/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Graph from "react-graph-vis"; //https://visjs.github.io/vis-network/examples/
import _ from 'lodash'

import { currentLocaltime } from '../../../utils/utility'
// import { getNodeByDegree, getTopology } from '../../../actions/aiops/TopologyAction'
import { getTopology } from '../../../actions/aiops/TopologyAction'
import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'

import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

import compute from '../../../assets/images/compute.png'
import loadBalance from '../../../assets/images/load_balance.png'
// import rds from '../../../assets/images/rds.png'
// import EBSDARK from '../../../assets/images/storage/Elastic-Block-Store-Dark.png'
import EBSLIGHT from '../../../assets/images/storage/Elastic-Block-Store-Light.png'

class TopologyLeftSection extends Component {
    
    constructor(props) {
        super(props)		
        this.nodeTypeRef = React.createRef()
        this.graphRef = React.createRef();

		this.state = {
            caseId: this.props.caseId,

            options: {
                width: '100%',
                height: 500,
                autoResize: true,
                clickToUse: false,
                layout: {
                    hierarchical: {
                        enabled: false,
                        levelSeparation: 40,
                        nodeSpacing: 300,
                        treeSpacing: 100,
                        blockShifting: true,
                        edgeMinimization: true,
                        direction: "LR",
                        sortMethod: "directed",
                        shakeTowards: "roots",
                        //enabled: false,
                        //direction: "LR",
                        //sortMethod: "hubsize",
                        //levelSeparation: 1000,
                        //nodeSpacing: 400,
                        //treeSpacing: 300,
                        //blockShifting: true,
                        //edgeMinimization: false,
                        //sortMethod: "directed",
                        //parentCentralization: false, 
                    }
                },                
                interaction: {
                    navigationButtons: true,
                    keyboard: true,
                    tooltipDelay: 100,
                    hover: true,
                    multiselect: true,
                    hoverConnectedEdges: true
                },
                configure: {
                    enabled: false,
                    showButton: true
                },
                physics: {
                   
                    forceAtlas2Based: {
                        // theta: 0.5,
                        avoidOverlap: 0.5,
                        gravitationalConstant: -95,
                        centralGravity: 0.01,
                        springLength: 150,
                        springConstant: 0.19,
                        nodeDistance: 150,
                        damping: 1
                    },
                    // hierarchicalRepulsion: {
                    //   centralGravity: 1,
                    //   springLength: 200,
                    //   springConstant: 0.1,
                    //   nodeDistance: 150,
                    //   damping: 1
                    // },
                    
                    minVelocity: 0.75,
                    // maxVelocity: 500,
                    solver: 'forceAtlas2Based',
                    stabilization: {
                        enabled: true,
                        iterations: 1000,
                        updateInterval: 1,
                        onlyDynamicEdges: false,
                        fit: true
                    },
                    // timestep: 0.5,
                    adaptiveTimestep: true,
                    // barnesHut: {
                    //     springConstant: 0,
                    //     avoidOverlap: 0.2
                    // },
                    // barnesHut: {
                    //     "avoidOverlap": 1
                    // },
                    enable: true,
                    wind: { x: 1, y: 0 },
                    
                    // forceAtlas2Based: {
                    //   gravitationalConstant: -26,
                    //   centralGravity: 0.005,
                    //   springLength: 500,
                    //   springConstant: 0.18,
                    //   avoidOverlap: 1.5
                    // },
                },
                nodes: {
                    // fixed: {
                    //   x: true,
                    //   y: true
                    // },
                    // margin: 40,
                    widthConstraint: {
                        maximum: 200,
                    },
                    color: {
                        border: "#000",
                    hover: {
                        border: "darkcyan",
                        background: "red"
                    }
                    },
                    font: {
                        size: 20
                    },
                    shape: "dot",
                    size: 25,
                    scaling: {
                        type: "incomingAndOutgoingConnections",
                        min: 10,
                        max: 60,
                        label: {
                            enabled: true,
                            min: 20,
                            max: 32
                        }
                    },
                    margin: {
                        top: 7,
                        bottom: 7,
                        left: 10,
                        right: 10
                    },
                    mass: 1
                },
                edges: {
                    // smooth: {
                    //     enabled: true,
                    //     roundness: 0.5,
                    //     type: 'cubicBezier',
                    //     forceDirection: 'vertical'
                    // }
                    length: 143,
                    hoverWidth: 3,
                    arrows: {
                        to: {
                            enabled: true,
                            scaleFactor: 1,
                            // type: "arrow",
                            type: "triangle",
                            size: 1,
                        },
                        // from: {
                        //     enabled: true,
                        //     scaleFactor: 0.5,
                        //     type: "arrow",
                        //     size: 1,
                        // }
                    },
                    color: {
                        color: "#000000",
                        highlight: "#000000",
                        hover: "#000000",
                        // inherit: 'from',
                        inherit: false
                    },
                    font: {
                        size: 14,
                        color: "black",
                        // color: 'white',
                        // strokeWidth: 0,
                        // strokeColor: "white"
                        // strokeColor: 'black',
                    },
                    smooth: true,
                }
            },

            isnodeTypeOpen: false,
            // topologyType: 'node',
            allGraphData: {nodes:[], edges: []},
            filteredGraphData: {nodes:[], edges: []},

			fullScreen: false,
            directedGraph: false,
            showAssetDetail: false,
            
            removeNode: '',
            removeEdge: '',

            selectedNodeTypes: [],
            nodeCategory: [],
        }
        
        // this.handleClick = this.handleClick.bind(this);
    }

	componentDidMount = () => {
        if(this.props.caseDetails && Object.entries(this.props.caseDetails).length) {
            this.getTopology()
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.caseDetails && Object.entries(this.props.caseDetails).length && prevProps.caseDetails !== this.props.caseDetails) {
            this.getTopology()
        }
    }
    
    getTopology = () => {
        let params = {}
        params.account_id = this.props.caseDetails ? (Array.isArray(this.props.caseDetails.account_id) ? this.props.caseDetails.account_id[0] : this.props.caseDetails.account_id) : ''
        params.region = this.props.caseDetails ? this.props.caseDetails.region : ''
        params.case_id = this.state.caseId
        params.alerts = true

        let caseStartTime = this.props.caseDetails.created_at
        // if(this.props.caseDetails.first_event) {
        //     caseStartTime = this.props.caseDetails.first_event
        // } else {
            // caseStartTime = this.props.caseDetails.created_at
        // }
        
        let caseEndTime = this.props.caseDetails.last_event
        if(this.props.caseDetails.last_event) {
            if(caseEndTime < caseStartTime) {
                caseEndTime = caseStartTime
            }
        } else {
            caseEndTime = currentLocaltime()
        }
        
        params.start_time = caseStartTime
        params.end_time = caseEndTime
        this.props.getTopology(params, (promise, result) => {
            if(promise) {
                let nodeCategory = []
                let topologyGraphData = {}
                let nodes = []
                let edges = []
                let asset = []

                if(result.edge_details && result.edge_details.length) {
                    result.edge_details.forEach((vertex, i) => {
                        let edgeRow = {}
                        
                        edgeRow.from = result.vertex_details[vertex.start] && result.vertex_details[vertex.start].asset_id
                        edgeRow.to = result.vertex_details[vertex.end] && result.vertex_details[vertex.end].asset_id
                        edgeRow.label = vertex.label
                        edgeRow.length = 300
                        // edgeRow.description = 'Sed in semper ex. Nulla sed finibus orci, eget semper odio. Aenean viverra bibendum ultrices viverra bibendum ultrices..'
                        if(i === 0) {
                            edgeRow.dashes = true
                        } else {
                            edgeRow.dashes = false
                        }
                        edgeRow.font ={
                            color: '#FF6F00',
                            align: "top"
                        }
                        edgeRow.color = '#FF9900'
                        edges.push(edgeRow)
                        
                        let nodeRow = {}
                        let assetRow = {}
                        if(!nodes.filter(arr => arr.id === result.vertex_details[vertex.start].asset_id).length) {
                            nodeRow = {}
                            nodeRow.id = result.vertex_details[vertex.start].asset_id
                            nodeRow.name = result.vertex_details[vertex.start].asset_name
                            nodeRow.custom_label = nodeRow.name
                            
                            // nodeRow.vectoreId = result.vertex_details[vertex.start].asset_name
                            // nodeRow.name = result.vertex_details[vertex.start].asset_name
                            // nodeRow.vpc_id = result.vertex_details[vertex.start].vpc_id
                            // nodeRow.resource_type = result.vertex_details[vertex.start].resource_type
                            // nodeRow.x = (i * 50)
                            // nodeRow.y = (i * 100)
                            

                            nodeRow.node_category = result.vertex_details[vertex.start].node_type ? result.vertex_details[vertex.start].node_type : []
                            nodeRow.node_category.length && nodeRow.node_category.forEach(cat => {
                                if(nodeCategory.indexOf(cat) === -1) {
                                    nodeCategory.push(cat)
                                }

                                if(nodeRow.node_category.indexOf('Networking') >= 0) { 
                                    nodeRow.fontColor = '#693EBC'
                                } else if(nodeRow.node_category.indexOf('Analytics') >= 0) { 
                                    nodeRow.fontColor = '#693EBC' 
                                } else if(nodeRow.node_category.indexOf('Security') >= 0) { 
                                    nodeRow.fontColor = '#693EBC'
                                } else if(nodeRow.node_category.indexOf('CDN') >= 0) { 
                                    nodeRow.fontColor = '#693EBC'
                                } else if(nodeRow.node_category.indexOf('IAM') >= 0) { 
                                    nodeRow.fontColor = '#693EBC'
                                } else if(nodeRow.node_category.indexOf('Compute') >= 0) { 
                                    nodeRow.fontColor = '#007bff'
                                    nodeRow.shape = 'image'
                                    // nodeRow.shape = 'circularImage'
                                    nodeRow.image = compute
                                    nodeRow.size = 30
                                    nodeRow.borderWidth = 0
                                } else if(nodeRow.node_category.indexOf('Database') >= 0) { 
                                    nodeRow.fontColor = '#06CB9E'
                                } else if(nodeRow.node_category.indexOf('LoadBalancer') >= 0) {
                                    nodeRow.fontColor = '#007bff'                                     
                                    nodeRow.shape = 'image'
                                    // nodeRow.shape = 'circularImage'
                                    nodeRow.image = loadBalance
                                    nodeRow.size = 30
                                    nodeRow.borderWidth = 0
                                } else if(nodeRow.node_category.indexOf('Storage') >= 0) {      
                                    nodeRow.shape = 'image'
                                    // nodeRow.shape = 'circularImage'
                                    nodeRow.image = EBSLIGHT
                                    nodeRow.size = 30
                                    nodeRow.borderWidth = 0
                                }

                                
                                if(nodeRow.node_category.indexOf('Compute') < 0 && nodeRow.node_category.indexOf('LoadBalancer') < 0 && nodeRow.node_category.indexOf('Storage') < 0) {
                                    nodeRow.shape = "custom"
                                    nodeRow.borderWidth = 5
                                    nodeRow.ctxRenderer = ({ ctx, id, x, y, state: { selected, hover }, style }) => {
                                        console.log(ctx,'...................')
                                        let message = nodeRow.name
                                        let size = 30
                                        return this.drawDoubleCircle(ctx, id, x, y, style, message, size)
                                    }
                                }
                            })
                            // nodeRow.tags = result.vertex_details[vertex.start].tags ? result.vertex_details[vertex.start].tags : []
                            let getAlertDetails = this.getVertexAlertDetails(nodeRow.id, result)
                            // let size = 200;
                            let count = 0;
                            let severity = ''
                            let color = '#d3d3d3'
                            if(getAlertDetails && Object.entries(getAlertDetails).length) {
                                if(getAlertDetails.count) {
                                    count = getAlertDetails.count
                                    // if(getAlertDetails.count > 10) { size = 800
                                    // } else if(getAlertDetails.count > 7) { size = 600
                                    // } else if(getAlertDetails.count > 4) { size = 500
                                    // } else { size = 300 }
                                }
                
                                if(getAlertDetails.severity) {
                                    severity = getAlertDetails.severity
                                    if(getAlertDetails.severity === 'Critical') { color = '#F75E3F'
                                    } else if(getAlertDetails.severity === 'High') { color = '#FFD200'
                                    } else if(getAlertDetails.severity === 'Medium') { color = '#1FCFE7'
                                    } else { color = '#06CB9E' }
                                }
                                
                                if(getAlertDetails.risk_wise) {
                                    nodeRow.risk_wise = getAlertDetails.risk_wise
                                }
                            }
                            nodeRow.severity = severity                            
                            nodeRow.color = color
                            // nodeRow.size = size
                            nodeRow.count = count
                            nodeRow.fontSize = 12
                            nodeRow.fontWeight = 600
                            nodeRow.description = nodeRow.vectoreId+' Sed in semper ex. Nulla sed finibus orci, eget semper odio. Aenean viverra bibendum ultrices viverra bibendum ultrices..'

                            nodes.push(nodeRow)
                            assetRow = {}
                            assetRow.label = result.vertex_details[vertex.start].asset_name
                            assetRow.id = result.vertex_details[vertex.start].asset_name
                            asset.push(assetRow)                    
                        } 
                        
                        if(!nodes.filter(arr => arr.id === result.vertex_details[vertex.end].asset_id).length) {
                            nodeRow = {}
                            
                            nodeRow.id = result.vertex_details[vertex.end].asset_id
                            nodeRow.name = result.vertex_details[vertex.end].asset_name
                            nodeRow.custom_label = nodeRow.name

                            nodeRow.node_category = result.vertex_details[vertex.end].node_type ? result.vertex_details[vertex.end].node_type : []
                            nodeRow.node_category.length && nodeRow.node_category.forEach(cat => {
                                if(nodeCategory.indexOf(cat) === -1) {
                                    nodeCategory.push(cat)
                                }

                                if(nodeRow.node_category.indexOf('Networking') >= 0) {
                                     nodeRow.fontColor = '#693EBC'
                                } else if(nodeRow.node_category.indexOf('Analytics') >= 0) {
                                     nodeRow.fontColor = '#693EBC' 
                                } else if(nodeRow.node_category.indexOf('Security') >= 0) {
                                     nodeRow.fontColor = '#693EBC'
                                } else if(nodeRow.node_category.indexOf('CDN') >= 0) {
                                     nodeRow.fontColor = '#693EBC'
                                } else if(nodeRow.node_category.indexOf('IAM') >= 0) {
                                     nodeRow.fontColor = '#693EBC'
                                } else if(nodeRow.node_category.indexOf('Compute') >= 0) {
                                    nodeRow.fontColor = '#007bff'                                     
                                    nodeRow.shape = 'image'
                                    // nodeRow.shape = 'circularImage'
                                    nodeRow.image = compute
                                    nodeRow.size = 30
                                    nodeRow.borderWidth = 0
                                } else if(nodeRow.node_category.indexOf('Database') >= 0) {
                                     nodeRow.fontColor = '#06CB9E'
                                } else if(nodeRow.node_category.indexOf('LoadBalancer') >= 0) {
                                    nodeRow.fontColor = '#007bff'                                     
                                    nodeRow.shape = 'image'
                                    // nodeRow.shape = 'circularImage'
                                    nodeRow.image = loadBalance
                                    nodeRow.size = 30
                                    nodeRow.borderWidth = 0
                                } else if(nodeRow.node_category.indexOf('Storage') >= 0) {
                                    nodeRow.shape = 'image'
                                    // nodeRow.shape = 'circularImage'
                                    nodeRow.image = EBSLIGHT
                                    nodeRow.size = 30
                                    nodeRow.borderWidth = 0
                                }
                                
                                if(nodeRow.node_category.indexOf('Compute') < 0 && nodeRow.node_category.indexOf('LoadBalancer') < 0 && nodeRow.node_category.indexOf('Storage') < 0) {
                                    nodeRow.shape = "custom"
                                    nodeRow.borderWidth = 5
                                    nodeRow.ctxRenderer = ({ ctx, id, x, y, state: { selected, hover }, style }) => {
                                        console.log(ctx,'...................')
                                        let message = nodeRow.name
                                        let size = 30
                                        return this.drawDoubleCircle(ctx, id, x, y, style, message, size)
                                    }
                                }
                            })
                            // nodeRow.tags = result.vertex_details[vertex.end].tags ? result.vertex_details[vertex.end].tags : []
                            let getAlertDetails = this.getVertexAlertDetails(nodeRow.id, result)
                            // let size = 200;
                            let count = 0;
                            let severity = ''
                            let color = '#d3d3d3'
                            if(getAlertDetails && Object.entries(getAlertDetails).length) {
                                if(getAlertDetails.count) {
                                    count = getAlertDetails.count
                                    // if(getAlertDetails.count > 10) { size = 800
                                    // } else if(getAlertDetails.count > 7) { size = 600
                                    // } else if(getAlertDetails.count > 4) { size = 500
                                    // } else { size = 300 }
                                }
                
                                if(getAlertDetails.severity) {
                                    severity = getAlertDetails.severity
                                    if(getAlertDetails.severity === 'Critical') { color = '#26B68F'
                                    } else if(getAlertDetails.severity === 'High') { color = '#2ED1BE'
                                    } else if(getAlertDetails.severity === 'Medium') { color = '#67E1D6'
                                    } else { color = '#00B5D4' }
                                }
                                
                                if(getAlertDetails.risk_wise) {
                                    nodeRow.risk_wise = getAlertDetails.risk_wise
                                }
                            }
                            nodeRow.severity = severity
                            nodeRow.color = color
                            // nodeRow.size = size
                            nodeRow.count = count
                            
                            nodeRow.fontSize = 12
                            nodeRow.fontWeight = 600
                            nodeRow.description = nodeRow.vectoreId+' Sed in semper ex. Nulla sed finibus orci, eget semper odio. Aenean viverra bibendum ultrices viverra bibendum ultrices..'

                            nodes.push(nodeRow)

                            assetRow = {}
                            assetRow.label = result.vertex_details[vertex.end].asset_name
                            assetRow.id = result.vertex_details[vertex.end].asset_name
                            asset.push(assetRow)                    
                        }
                    })
                }

                topologyGraphData['nodes'] = nodes
                topologyGraphData['edges'] = edges

                asset = asset.filter((thing, index, self) =>
                    index === self.findIndex((t) => (
                        t.label === thing.label && t.id === thing.id
                    ))
                )

                // console.log('topologyGraphData', topologyGraphData)
                
                let selectedNodeTypes = []
                if(nodeCategory.includes('Compute')) {
                    selectedNodeTypes.push('Compute')
                }
                if(nodeCategory.includes('Database')) {
                    selectedNodeTypes.push('Database')
                }
                
                if(nodeCategory.includes('Storage')) {
                    selectedNodeTypes.push('Storage')
                }
                

                this.setState({ allGraphData: topologyGraphData, filterAsset: asset, nodeCategory, selectedNodeTypes },
                    () => this.updateInput()
                )
            } else {
                this.setState({ allGraphData: {nodes:[], edges: []}, filteredGraphData: {nodes:[], edges: []}, nodeCategory: [] })
            }
        })
    }
    
    getVertexAlertDetails = (asset_name, result) => {
        let alertDetails = result.alert_count[asset_name] ? result.alert_count[asset_name] : {}
        return alertDetails
    }
    
    changeGraphOption = (label) => {
        let myConfig = this.state.myConfig
        if(label === 'directed') {
            myConfig = Object.assign({}, myConfig, {                
                directed: this.state.directedGraph
            });
        }

        this.setState({ myConfig })
    }
    
    resetGraph = () => {
        let selectedNodeTypes = []
        if(this.state.nodeCategory.includes('Compute')) {
            selectedNodeTypes.push('Compute')
        }
        if(this.state.nodeCategory.includes('Database')) {
            selectedNodeTypes.push('Database')
        }        
        if(this.state.nodeCategory.includes('Storage')) {
            selectedNodeTypes.push('Storage')
        }
        this.setState({ fullScreen: false, filteredGraphData: {nodes:[], edges: []}, options: {}, events: {}, selectedNodeTypes }, 
            () => {
                this.updateInput()
                this.props.fullScreenOption(false)
            }
        )
    }
    
    onToggleFullScreen = () => {
        // //this.setState({ filteredGraphData: {} })
        // let fullScreen = this.state.fullScreen ? false : true
        // let myConfig = this.state.myConfig
        // // document.getElementById('').style("transform", "translate(0,0)")
        // //document.getElementById('graph-id-graph-container-zoomable').setProperty("transform", "translate(0,0)")
        // // element.style.transform = null;
        // // element.style.height = null;
        // if(fullScreen) {
        //     let d3 = Object.assign({}, myConfig.d3, {
        //         size: ([this.state.graphWidth, this.state.graphHeight])
        //     })
        //     myConfig = Object.assign({}, myConfig, {                
        //         // height: this.state.graphHeight,
        //         // width: this.state.graphWidth,
        //         // width: this.d3GraphWidthRef.current.offsetWidth * 1.75,
        //         d3: d3
        //     });
        // } else {
        //     alert(2)
        //     let d3 = Object.assign({}, myConfig.d3, {
        //         size: ([800]),
        //         // gravity: 100
        //     })
        //     myConfig = Object.assign({}, myConfig, {
        //         height: 500,
        //         width: this.state.basicGraphWidth,
        //         d3: d3
        //     });
        // }

        // this.setState({
        //     fullScreen,
        //     myConfig,
        //    // filteredGraphData: this.state.allGraphData
        // },
        //     () => this.props.fullScreenOption(fullScreen)
        // )

        this.setState({ fullScreen: !this.state.fullScreen },
            () => this.props.fullScreenOption(this.state.fullScreen)
        )
    }
    
    addRemoveNodes = (type) => {
        let data = this.state.filteredGraphData
        let edges = data.edges
        let nodes = data.nodes
        if(type === 'remove') {
            if(nodes.length > 1) {
                let removeNode = nodes[nodes.length-1].id
                edges = edges.filter(arr => arr.source !== removeNode)
                edges = edges.filter(arr => arr.target !== removeNode)
                nodes = nodes.filter(arr => arr.id !== removeNode)
            }
        } else {
            let allData = this.state.allGraphData
            let allEdges = allData.edges
            let allNodes = allData.nodes
            if(nodes.length < allNodes.length) {
                let addNode = allNodes[nodes.length].id
                allNodes = allNodes.filter(arr => arr.id === addNode)
                nodes = [...nodes, ...allNodes];
                
                let formNewEdgesSource = allEdges.filter(arr => arr.source === addNode)
                let formNewEdgesTarget = allEdges.filter(arr => arr.target === addNode)                
                allEdges = [...formNewEdgesSource, ...formNewEdgesTarget];

                let formEdges = []
                allEdges.length && allEdges.forEach(item => {
                    if(nodes.filter(arr => arr.id === item.source).length) {
                        formEdges.push(item)
                    }
                })
                
                edges = [...edges, ...formEdges];

            }
        }
        data = Object.assign({}, data, {
            edges: edges,
            nodes: nodes
        });

        this.setState({ filteredGraphData: data }, 
            // () => this.updateInput('from_remove_edges')
        )
    }

    decorateGraphNodesWithInitialPositioning = nodes => (nodes.map(n =>
        Object.assign({}, n, {
          x: n.x || Math.floor(Math.random() * 500),
          y: n.y || Math.floor(Math.random() * 500)
        })
    ));

    updateInput = () => {
        // this.setState({ filteredGraphData: {nodes:[], edges: []} })
        let data = this.state.allGraphData

        let edges = data.edges
        let nodes = data.nodes
        let existNodes = []
        let arrayDiff = this.state.nodeCategory.filter(x => !this.state.selectedNodeTypes.includes(x));
        if(this.state.selectedNodeTypes && this.state.selectedNodeTypes.length) {
            this.state.selectedNodeTypes.forEach(cat => {
                let newNodes = nodes
                newNodes.filter((item) => {
                    if(item.node_category.indexOf(cat) >= 0) {
                        existNodes.push(item)
                    }
                })
            })

            arrayDiff.length && arrayDiff.forEach(cat => {
                nodes.filter((item) => {
                    if(item.node_category.indexOf(cat) >= 0 || !item.node_category.length) {
                        edges = edges.filter(arr => arr.from !== item.id)
                        edges = edges.filter(arr => arr.to !== item.id)
                    }
                });
            })
            nodes = existNodes 
        }

        nodes.length && nodes.forEach((item, i) => {
            // if(i === 0) {
            //     item.fixed = true
            //     item.x = -1000
            //     item.y = 0
            // }
        })

        let graphData = {
            edges: edges,
            nodes: nodes
            // nodes: nodes
        }

        // console.log(graphData)

        let options = {
            width: '100%',
            height: 500,
            autoResize: true,
            clickToUse: false,
            layout: {
                hierarchical: {
                    enabled: false,
                    levelSeparation: 40,
                    nodeSpacing: 300,
                    treeSpacing: 100,
                    blockShifting: true,
                    edgeMinimization: true,
                    direction: "LR",
                    sortMethod: "directed",
                    shakeTowards: "roots",
                    //enabled: false,
                    //direction: "LR",
                    //sortMethod: "hubsize",
                    //levelSeparation: 1000,
                    //nodeSpacing: 400,
                    //treeSpacing: 300,
                    //blockShifting: true,
                    //edgeMinimization: false,
                    //sortMethod: "directed",
                    //parentCentralization: false, 
                }
            },                
            interaction: {
                navigationButtons: true,
                keyboard: true,
                tooltipDelay: 100,
                hover: true,
                multiselect: true,
                hoverConnectedEdges: true
            },
            configure: {
                enabled: false,
                showButton: true
            },
            physics: {
               
                forceAtlas2Based: {
                    // theta: 0.5,
                    avoidOverlap: 0.5,
                    gravitationalConstant: -138,
                    centralGravity: 0.02,
                    springLength: 150,
                    springConstant: 0.19,
                    nodeDistance: 150,
                    damping: 1
                },
                // hierarchicalRepulsion: {
                //   centralGravity: 1,
                //   springLength: 200,
                //   springConstant: 0.1,
                //   nodeDistance: 150,
                //   damping: 1
                // },
                
                minVelocity: 0.75,
                // maxVelocity: 500,
                solver: 'forceAtlas2Based',
                stabilization: {
                    enabled: true,
                    iterations: 1000,
                    updateInterval: 1,
                    onlyDynamicEdges: false,
                    fit: true
                },
                // timestep: 0.5,
                adaptiveTimestep: true,
                // barnesHut: {
                //     springConstant: 0,
                //     avoidOverlap: 0.2
                // },
                // barnesHut: {
                //     "avoidOverlap": 1
                // },
                enable: true,
                wind: { x: 1, y: 0 },
                
                // forceAtlas2Based: {
                //   gravitationalConstant: -26,
                //   centralGravity: 0.005,
                //   springLength: 500,
                //   springConstant: 0.18,
                //   avoidOverlap: 1.5
                // },
            },
            nodes: {
                // fixed: {
                //   x: true,
                //   y: true
                // },
                // margin: 40,
                widthConstraint: {
                    maximum: 200,
                },
                color: {
                    border: "#000",
                hover: {
                    border: "darkcyan",
                    background: "red"
                }
                },
                font: {
                    size: 20
                },
                shape: "dot",
                size: 25,
                scaling: {
                    type: "incomingAndOutgoingConnections",
                    min: 10,
                    max: 60,
                    label: {
                        enabled: true,
                        min: 20,
                        max: 32
                    }
                },
                margin: {
                    top: 7,
                    bottom: 7,
                    left: 10,
                    right: 10
                },
                mass: 1
            },
            edges: {
                // smooth: {
                //     enabled: true,
                //     roundness: 0.5,
                //     type: 'cubicBezier',
                //     forceDirection: 'vertical'
                // }
                length: 143,
                hoverWidth: 3,
                arrows: {
                    to: {
                        enabled: true,
                        scaleFactor: 1,
                        type: "arrow",
                        size: 1,
                    },
                    // from: {
                    //     enabled: true,
                    //     scaleFactor: 0.5,
                    //     type: "arrow",
                    //     size: 1,
                    // }
                },
                color: {
                    color: "#000000",
                    highlight: "#000000",
                    hover: "#000000",
                    // inherit: 'from',
                    inherit: false
                },
                font: {
                    size: 12,
                    color: "black",
                    strokeWidth: 0,
                    // strokeColor: "white"
                },
                smooth: true,
            }
        }

        let events = {
            hoverNode: el => {
                const node = el.node;
                const currentGraph = this.graphRef.current;
                const network = currentGraph.Network;
                const tree = document.getElementById("data-tree-container");
                if (tree) {
                const elements = document.getElementsByClassName(
                    "dataTree_" + node
                );
                if (elements) {
                    [...elements].forEach(elVal => {
                    elVal.style.fontWeight = 600;
                    });
                }
                network.body.nodes[node].options.borderWidth = 6;
                const optionsContainer = document.getElementById(
                    "graph-options-container"
                );
                if (optionsContainer) {
                    optionsContainer.style.visibility = "hidden";
                    optionsContainer.style.display = "none";
                }
                const contextMenuContainer = document.getElementById(
                    "graph-contextmenu-container"
                );
                const contextMenuEdgeContainer = document.getElementById(
                    "graph-contextmenu-edge-container"
                );
                if (contextMenuContainer) {
                    contextMenuContainer.style.visibility = "hidden";
                    contextMenuContainer.style.display = "none";
                }
                if (contextMenuEdgeContainer) {
                    contextMenuEdgeContainer.style.visibility = "hidden";
                    contextMenuEdgeContainer.style.display = "none";
                }
                network.unselectAll();
                }
            },
            blurNode: el => {
                const node = el.node;
                const currentGraph = this.graphRef.current;
                const network = currentGraph.Network;
                const tree = document.getElementById("data-tree-container");
                if (tree) {
                const elements = document.getElementsByClassName(
                    "dataTree_" + node
                );
                if (elements) {
                    [...elements].forEach(elValue => {
                    elValue.style.fontWeight = 100;
                    });
                }
                network.body.nodes[node].options.borderWidth = 3;
                }
            },
            hoverEdge: el => {
                const tree = document.getElementById("data-tree-container");
                if (tree) {
                const currentGraph = this.graphRef.current;
                const network = currentGraph.Network;
                const nodeParent = network.body.edges[el.edge].fromId;
                Object.values(network.body.edges).forEach(edge => {
                    if (
                    edge.fromId === nodeParent &&
                    edge.options.label === network.body.edges[el.edge].options.label
                    ) {
                    const index = network.body.edgeIndices.findIndex(
                        id => id === edge.id
                    );
                    const obj = document.getElementById("dataTree_edge" + index);
                    if (obj) {
                        obj.style.fontWeight = 600;
                    }
                    }
                });
                }
            },
            blurEdge: el => {
                const tree = document.getElementById("data-tree-container");
                if (tree) {
                const currentGraph = this.graphRef.current;
                const network = currentGraph.Network;
                const nodeParent = network.body.edges[el.edge].fromId;
                Object.values(network.body.edges).forEach(edge => {
                    if (
                    edge.fromId === nodeParent &&
                    edge.options.label === network.body.edges[el.edge].options.label
                    ) {
                    const index = network.body.edgeIndices.findIndex(
                        id => id === edge.id
                    );
                    const obj = document.getElementById("dataTree_edge" + index);
                    if (obj) {
                        obj.style.fontWeight = 100;
                    }
                    }
                });
                }
            },
            click: event => {
                const { nodes, edges } = event;
                const contextMenuContainer = document.getElementById(
                "graph-contextmenu-container"
                );
                const contextMenuEdgeContainer = document.getElementById(
                "graph-contextmenu-edge-container"
                );
                if (contextMenuContainer) {
                contextMenuContainer.style.visibility = "hidden";
                contextMenuContainer.style.display = "none";
                }
                if (contextMenuEdgeContainer) {
                contextMenuEdgeContainer.style.visibility = "hidden";
                contextMenuEdgeContainer.style.display = "none";
                }
                this.getSelectedNodeEdgeDetails(nodes, edges)
                return { nodes, edges };
            },
            // afterDrawing: ctx => {
            //     var percent = 50
            //     const currentGraph = this.graphRef.current;
            //     const network = currentGraph.Network;
            //     var pos = network.getPositions([1, 2]);
            //     ctx.strokeStyle = ctx.filStyle = 'green';
            //     ctx.moveTo(pos[1].x, pos[1].y);
            //     ctx.lineTo(pos[1].x + (pos[2].x-pos[1].x)*percent/100, pos[1].y + (pos[2].y - pos[1].y)*percent/100);
            //     ctx.fill();
            //     ctx.stroke();   
            // },
            afterDrawing: ctx => ctx,
            // beforeDrawing: ctx => ctx,
            beforeDrawing: ctx => {
                const currentGraph = this.graphRef.current;
                const network = currentGraph.Network;
                let nodeIds = network.body.nodeIndices
                let nodeDetails = network.body.nodes 

                Object.entries(nodeDetails).forEach(([key, value]) => {
                    if(nodeIds.includes(key)) {
                        if(value.options && value.options.name) {
                            var nodeId = value.options.id;
                            var nodePosition = network.getPositions([nodeId]);
                            ctx.fillStyle = '#24A597';
                            ctx.font = "16px Muli";
                            if(value.options.shape === 'image') {
                                ctx.fillText(value.options.name, nodePosition[nodeId].x - 40, nodePosition[nodeId].y - 40);
                            } else if(value.options.shape === 'custom') {
                                ctx.fillText(value.options.name, nodePosition[nodeId].x - 30, nodePosition[nodeId].y - 50);
                            } else {
                                ctx.fillText(value.options.name, nodePosition[nodeId].x - 30, nodePosition[nodeId].y - 50);
                            }
                        }
                    }
                    // let nodeItem = nodeDetails.item
                })
                // body = network.body
                // var nodeId = 1;
                // var nodePosition = network.getPositions([nodeId]);

                // ctx.fillStyle = '#EDEDED';
                // ctx.font = "20px";
                
                // var scale = network.getScale();                
                // var visibleFontSize = 20;
                // if (visibleFontSize > 30) {
                //   ctx.font = 30/scale + "px Arial";  
                // }
                // else {
                //   ctx.font = 20 + "px Arial";
                // }
                
                
                // ctx.fillText('hello world', nodePosition[nodeId].x - 20, nodePosition[nodeId].y - 50);
                
            },
            selectNode: clickEvent => {
                const { nodes } = clickEvent;
                const nodeId = nodes[0];
                const currentGraph = this.graphRef.current;
                currentGraph.lastClickedNode = nodeId;
    
                if (currentGraph) {
                const network = currentGraph.Network;
                // const graphOptionsRef = this.graphOptionsRef.current;
                if (network && nodeId) {
                    if (network.body.nodes[nodeId]) {
                    network.body.nodes[nodeId].options.borderWidth = 6;
                    }
                }
                if (
                    nodeId &&
                    // nodeId.includes("cluster:") &&
                    network.isCluster(nodeId)
                ) {
                    network.openCluster(nodeId);
                    network.setOptions({ physics: true });
                    setTimeout(() => {
                    network.setOptions({ physics: false });
                    }, 300);
                } else if (nodeId) {
                    // graphOptionsRef.setState({});
                    // network.focus(nodeId, {
                    //   scale: 1,
                    //   animation: {
                    //     duration: 120,scc
                    //     easingFunction: 'easeInOutQuad',
                    //   }
                    // });
                    // window.setTimeout(() => {
                    //   const optionsContainer = document.getElementById(
                    //     "graph-options-container"
                    //   );
                    //   optionsContainer.style.visibility = "visible";
                    //   optionsContainer.style.display = "block";
                    //   optionsContainer.style.right = "0px";
                    //   optionsContainer.style.top = "43px";
                    //   // neighbourhoodHighlight(clickEvent, graphOptionsRef.props.getNodes(), network);
                    // }, 220);
                }
                }
            },
            deselectNode: (/* clickEvent */) => {
                const optionsContainer = document.getElementById(
                "graph-options-container"
                );
                if (optionsContainer) {
                optionsContainer.style.visibility = "hidden";
                optionsContainer.style.display = "none";
                }
                const contextMenuContainer = document.getElementById(
                "graph-contextmenu-container"
                );
                const contextMenuEdgeContainer = document.getElementById(
                "graph-contextmenu-edge-container"
                );
                if (contextMenuContainer) {
                contextMenuContainer.style.visibility = "hidden";
                contextMenuContainer.style.display = "none";
                }
                if (contextMenuEdgeContainer) {
                contextMenuEdgeContainer.style.visibility = "hidden";
                contextMenuEdgeContainer.style.display = "none";
                }
                // neighbourhoodHighlight(clickEvent, graphOptionsRef.props.getNodes(), network);
                this.graphRef.current.lastClickedNode = null;
            },
            doubleClick: params => {
                params.event.preventDefault();
                const currentGraph = this.graphRef.current;
                const network = currentGraph.Network;
                const firstDegreeConnections = [
                ...network.getConnectedNodes(params.nodes),
                ...params.nodes
                ];
                const connectedNodes = [];
                firstDegreeConnections.forEach(node => {
                const nodeConnections = network.getConnectedNodes(node);
                connectedNodes.push(node);
                nodeConnections.forEach(scndNode => {
                    if (!connectedNodes.includes(scndNode)) {
                    connectedNodes.push(scndNode); // Will bring second degree node connections
                    }
                });
                });
                const contextMenuContainer = document.getElementById(
                "graph-contextmenu-container"
                );
                const contextMenuEdgeContainer = document.getElementById(
                "graph-contextmenu-edge-container"
                );
                if (contextMenuContainer) {
                contextMenuContainer.style.visibility = "hidden";
                contextMenuContainer.style.display = "none";
                }
                if (contextMenuEdgeContainer) {
                contextMenuEdgeContainer.style.visibility = "hidden";
                contextMenuEdgeContainer.style.display = "none";
                }
                network.selectNodes(connectedNodes);
            },
            stabilizationProgress: params => {
                const maxWidth = 496;
                const minWidth = 20;
                const widthFactor = params.iterations / params.total;
                const width = Math.max(minWidth, maxWidth * widthFactor);
                // document.getElementById('graphLoadingBar').style.visibility = 'auto';
                if(document.getElementById("graphLoadingBar")) {
                    document.getElementById("graphLoadingBar").style.display = "block";
                }
                if(document.getElementById("bar")) {
                    document.getElementById("bar").style.width = width + "px";
                }
                if(document.getElementById("text")) {
                    document.getElementById("text").innerText = Math.round(widthFactor * 100) + "%";
                }
            },
            stabilizationIterationsDone: () => {
                if(document.getElementById("graphLoadingBar")) {
                    document.getElementById("graphLoadingBar").style.display = "none";
                }
                const currentGraph = this.graphRef.current;
                if (currentGraph) {
                const network = currentGraph.Network;
                const nodePositions = network.getPositions();
                const graph = _.cloneDeep(this.state.filteredGraphData);
                graph.nodes = graph.nodes.map(node => ({
                    ...node,
                    ...nodePositions[node.id]
                }));
                // network.setData({ nodes: graph.nodes, edges: graph.edges });
                // network.setOptions({ physics: false });
    
                this.setState({
                    filteredGraphData: { nodes: graph.nodes, edges: graph.edges },
                    options: { ...options, physics: false }
                });
    
                const lastClickedNode = currentGraph.lastClickedNode;
                if (lastClickedNode) {
                    setTimeout(() => {
                    network.selectNodes([lastClickedNode]);
                    currentGraph.props.events.selectNode({
                        nodes: [lastClickedNode]
                    });
                    }, 0);
                }
                }
            }
        }

        this.setState({ filteredGraphData: graphData, options, events })
    }
    
    getMultiSelectedCount = (type, array) => {
        if(array) {
            return array.length && array.includes('All') ? 'All' :  array.length ? array.length +' Selected' : 'All'
        } else {
            return 'All'
        }
    }

    handleMultiSelectChange = (childKey, arrayValue) => {
        // let value = arrayValue.map(item => item.value)
        let label = arrayValue.map(item => item.label)	

        if(childKey === 'nodeTypes') {
            if(this.state.selectedNodeTypes.length < label.length) {
                this.setState({ selectedNodeTypes: label, options: {}, events: {}, filteredGraphData: {nodes: [], edges: []} },
                    () => this.updateInput()
                )
            } else {
                this.setState({ selectedNodeTypes: label },
                    () => this.updateInput()
                )
            }
        }
    }
	
	handleClickOutside(event) {
        if (this.nodeTypeRef && !this.nodeTypeRef.current.contains(event.target)) {
            this.setState({ isnodeTypeOpen: false })
        } else {
            this.setState({ isnodeTypeOpen: true })
        }
    }

    drawDoubleCircle = (ctx, id, x, y, style, message, size) => {
        let innerCircle = size
        let outerCircle = size + 10
        const r = style.size;
        const drawNode = () => {

            ctx.beginPath();
            ctx.arc(x, y, outerCircle, 0, 2 * Math.PI);
            ctx.closePath();
            ctx.save();                    
            ctx.fillStyle = "#00D180";
            ctx.fill();
            ctx.lineWidth = 3
            ctx.strokeStyle = "#FFFFFF";
            ctx.stroke();

            
            ctx.beginPath();
            ctx.arc(x, y, innerCircle, 0, 2 * Math.PI);
            ctx.closePath();
            ctx.save();                    
            ctx.fillStyle = "#00D180";
            ctx.fill();
            ctx.lineWidth = 3
            ctx.strokeStyle = "#FFFFFF";
            ctx.stroke();

            ctx.restore();

            // ctx.font = "normal 12px sans-serif";
            // ctx.fillStyle = "black";
            // ctx.font = "10px"
            // ctx.fillStyle = "#90CBF6"

            // var lines = message.split('\n');
        
            // for (var i = 0; i<lines.length; i++)
                // ctx.fillText(lines[i], x - 20, y,r - 20 );
                // ctx.fillText(lines[i], x - r + 10, y, 2 * r - 20 );
                // ctx.fillText(lines[i], x - 30, y - 45, r - 20 );
        };
        return {
            drawNode,
            nodeDimensions: { width: 2 * r, height: 2 * r },
        };
    }

    getSelectedNodeEdgeDetails = (nodes, edges) => {
        console.log(nodes, edges)
        if(nodes.length  || edges.length) {
            let assetName = []
            if(this.state.allGraphData && this.state.allGraphData && Object.entries(this.state.allGraphData.nodes).length) {

                if(!nodes.length && edges.length) {
                    this.state.allGraphData.edges.forEach(edg => {
                        if(edges.includes(edg.id)) {
                            nodes.push(edg.from)
                            nodes.push(edg.to)
                        }
                    })
                }
                this.state.allGraphData.nodes.forEach(nod => {
                    if(nodes.includes(nod.id)) {
                        assetName.push(nod.name)
                    }
                })
            }

            let obj ={}
            obj.selected_node = assetName
            obj.selected_edge = edges
            this.props.setAiopsPropsDetails('topology_selected_node_edges', obj)
        }
    }
        
	render() {
		return (
            <div className='col-md-12' onClick={ (event) => { this.handleClickOutside(event) } }>
                <div className="rounded bg-dark p-3 mt-3">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex justify-content-start">
                            <div className="w-fit-content">
                                <div className="from-group">
                                    <div className={`multi-select-dark-theme align-self-center mr-2 z999 ${this.state.nodeCategory && this.state.nodeCategory.length > 10 ? '' : 'removeDropdownSearchBar'}`} ref={this.nodeTypeRef}>
                                        <ReactMultiSelectCheckboxes						
                                            placeholderButtonLabel="All"
                                            getDropdownButtonLabel={() => this.getMultiSelectedCount('nodeTypes', this.state.selectedNodeTypes)}
                                            menuIsOpen ={this.state.isnodeTypeOpen}
                                            value={this.state.selectedNodeTypes.map(item => ({
                                                value: item,
                                                label: item
                                            }))}
                                            onChange={arr => {
                                            this.handleMultiSelectChange('nodeTypes', arr ? arr : []) }}
                                            options={this.state.nodeCategory.map(itm => ({
                                                value: itm,
                                                label: itm,
                                            }))}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-check mr-3 align-self-center displayNone">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                <input 
                                    type="checkbox" 
                                    className="form-check-input" 
                                    id="assetsInfoCheckbox" 
                                    checked={this.state.showAssetDetail}
                                    onChange={e => this.setState({ showAssetDetail: this.state.showAssetDetail ? false : true })}
                                />
                                <label className="form-check-label" for="assetsInfoCheckbox">Show Asset Info</label>
                            </div>
                            <div className="form-check mr-3 align-self-center displayNone">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                <input 
                                    type="checkbox" 
                                    className="form-check-input" 
                                    id="directedCheckbox" 
                                    checked={this.state.directedGraph}
                                    onChange={e => this.setState({ directedGraph: this.state.directedGraph ? false : true },
                                        () => this.changeGraphOption('directed')
                                    )}
                                />
                                <label className="form-check-label" for="directedCheckbox">Directed</label>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-self-center">
                            {/* <div className="rounded bg-secondary text-white py-1 px-2 mr-2">
                                Node
                                <span className="ml-2 fa fa-plus-square cursorPointer" onClick={() => this.addRemoveNodes('add')}></span>
                                <span className="ml-2 fa fa-minus-square cursorPointer" onClick={() => this.addRemoveNodes('remove')}></span>
                            </div> */}
                            <div className="rounded bg-secondary text-white px-2 py-1">
                                <span onClick={this.resetGraph} className="fa fa-redo-alt border-right pr-2 cursorPointer"></span>
                                {/* <span onClick={this.onClickZoomIn}  className="fa fa-search-plus border-right px-2 cursorPointer"></span>
                                <span onClick={this.onClickZoomOut}  className="fa fa-search-minus border-right px-2 cursorPointer"></span> */}
                                {!this.state.fullScreen ?
                                    <span onClick={this.onToggleFullScreen} className="fa fa-expand-arrows-alt pl-2 cursorPointer"></span>
                                :
                                    <span onClick={this.onToggleFullScreen} className="fa fa-compress-arrows-alt pl-2 cursorPointer"></span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="d-flex mt-2">
                    </div>
                    <div className="topologyGraph">
                        <div id="graphLoadingBar">
                            <div>LOADING</div>
                            <div id="loadingBar">
                                <div className="outerBorder">
                                    <div id="text">0%</div>
                                    <div id="border" />
                                    <div id="bar" />
                                </div>
                            </div>
                        </div>
                        {this.state.filteredGraphData && this.state.filteredGraphData && Object.entries(this.state.filteredGraphData.nodes).length ? 
                            <div id="graph">
                                <Graph
                                    ref={this.graphRef}
                                    graph={this.state.filteredGraphData}
                                    options={this.state.options}
                                    events={this.state.events}
                                />
                            </div>
                        : null }
                    </div> 
                    <div className="mt-2 d-flex justify-content-end displayNone">
                        <div className="rounded bg-secondary text-white py-1 px-2 mr-2">
                            Node
                            <span className="ml-2 fa fa-plus-square cursorPointer" onClick={() => this.addRemoveNodes('add')}></span>
                            <span className="ml-2 fa fa-minus-square cursorPointer" onClick={() => this.addRemoveNodes('remove')}></span>
                        </div>
                        <div className="rounded bg-secondary text-white px-2 py-1">
                            <span onClick={this.resetGraph} className="fa fa-redo-alt border-right pr-2 cursorPointer"></span>
                            <span onClick={this.onClickZoomIn}  className="fa fa-search-plus border-right px-2 cursorPointer"></span>
                            <span onClick={this.onClickZoomOut}  className="fa fa-search-minus border-right px-2 cursorPointer"></span>
                            {!this.state.fullScreen ?
                                <span onClick={this.onToggleFullScreen} className="fa fa-expand-arrows-alt pl-2 cursorPointer"></span>
                            :
                                <span onClick={this.onToggleFullScreen} className="fa fa-compress-arrows-alt pl-2 cursorPointer"></span>
                            }
                        </div>
                    </div>
                </div>
                   
            </div>
		)
	}
}
/**
 * Type of the props used in the component
 */
TopologyLeftSection.propTypes = {
    getTopology: PropTypes.func,
    fullScreenOption: PropTypes.bool,
    setAiopsPropsDetails: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
	// console.log('state', state)
	return {
        caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : {}
    }
}

export default connect(mapStateToProps, {
    getTopology,
    setAiopsPropsDetails,
    
})(withRouter(TopologyLeftSection))