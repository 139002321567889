/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AssetDetail.js
 * @author Rajasekar // on 18/02/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AppWrapper } from '../../common/AppWrapper'
import ConfigureSection from '../searchModule/ConfigureSection'
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner } from 'reactstrap'
import SupressSection from '../searchModule/SupressSection'
import DetailsSection from '../searchModule/DetailsSection'
import {
	getAssetDetailExt,
	getAssetNetworkDetails,
	getAssetSGDetails,
	getAssetStorageDetails,
} from '../../../actions/assets/assetsAction'
import {
	getAssetDetails,
	getRelatedIssues,
	getBillingSnapshot,
	getMonitoringSnapShot,
	getTopEvents,
	getDayWiseCount,
	getRecentEvents,
	getEventGraphCounts,
} from '../../../actions/detectors/detectorsAction'
import { listAllSuppressors } from '../../../actions/detectors/suppressorsAction'
import { myLog, trimmedUserName } from '../../../utils/utility'
import BasicDetails from './BasicDetails'
import UserDetails from './UserDetails'
import StorageDetails from './StorageDetails'
import ServiceDetails from './ServiceDetails'
import NetworkDetails from './NetworkDetails'
import ClusterDetails from './ClusterDetails'
import TriggerConfiguration from './TriggerConfiguration'
import BackupAndSnapshot from './BackupAndSnapshot'
import OtherDetails from './OtherDetails'
import LogsAndEvents from './LogsAndEvents'
import Tags from './Tags'
import ProvisionConcurrency from './ProvisionConcurrency'
import { Link } from 'react-router-dom'
import StateDetails from './StateDetails'
import ConfigurationDetails from './ConfigurationDetails'

class AssetDetail extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showScreenLoading: false,
			detailsTabNames: [
				{ name: 'Asset', value: 'asset_details' },
				{ name: 'Related Issues', value: 'related_issues' },
				{ name: 'Events', value: 'events' },
				{ name: 'Monitoring', value: 'monitoring_details' },
				{ name: 'Billing', value: 'billing' },
			],
			detailsTabItemsToShow: 2,
			showDetailsSection: false,
			showDetailsTabListDropdown: false,
			selectedDetailsTab: 0,
			selectedRowAssetDetails: [],
			selectedRowBillingDetails: [],
			selectedRowMonitoringSnapshot: {},
			showDetailsAssetSectionLoading: false,
			showDetailsMonitoringSectionLoading: false,
			showDetailsBillingSectionLoading: false,
			showDetailsEventsSectionLoading: false,
			showDetailsRelatedIssueLoading: false,
			showSupressSectionLoading: false,
			dayWiseCountGraphData: {},
			relatedIssuesData: [],
			assetConfigrationsList: [],
			summaryGraphData: {},
			topEventsData: {},
			recentEventsData: [],
			eventCountData: {},
			selectedMonitoringDuration: '',
			selectedDuration: '+30d',
		}
	}

	componentDidMount = () => {
		this.setState({ showScreenLoading: true })
		if (this.props.location.state && Object.keys(this.props.location.state.asset).length !== 0) {
			this.getAssetDetails(this.props.location.state.asset)
			// this.getBillingSnap(this.props.location.state.asset)
			// this.getRelatedIssues(this.props.location.state.asset)
			// this.getMonitoringSnap(this.props.location.state.asset)
			this.getAssetSupressList(this.props.location.state.asset)
			// this.getAssetConfigurationsList(this.props.location.state.asset)
		}
		let params = {
			// provider: 'aws',
			// service_name: 'elasticsearch',
			// asset_id: '6d7a0dc0b93d1334c2e40bad82f149bb_1580332599299',
			service_name: this.props.location.state && this.props.location.state.asset.service_name,
			provider: this.props.location.state && this.props.location.state.asset.provider,
			asset_id: this.props.match.params.id,
		}
		this.props.getAssetDetailExt(params, () => {})
		this.props.getAssetNetworkDetails(params, () => {
			this.setState({ showScreenLoading: false, showDetailsSection: true })
		})
		this.props.getAssetSGDetails(params, () => {})
		this.props.getAssetStorageDetails(params, () => {})
	}
	/**
	 * Get asset details of the detector
	 */
	getAssetDetails = asset => {
		this.setState({ showDetailsAssetSectionLoading: true, showSupressSectionLoading: true })
		let params = {
			service_name: asset.service_name,
			asset_id: asset.asset_id,
		}
		this.props.getAssetDetails(params, (promise, assetDetails) => {
			if (!promise) {
				this.setState({
					selectedRowAssetDetails: [],
					showDetailsAssetSectionLoading: false,
					showSupressSectionLoading: false,
				})
			} else {
				this.setState({
					selectedRowAssetDetails: assetDetails,
					showDetailsAssetSectionLoading: false,
					showSupressSectionLoading: false,
				})
			}
		})
	}

	/**
	 * Get related details of the detector
	 */
	getRelatedIssues = asset => {
		this.setState({ showDetailsRelatedIssueLoading: true, relatedIssuesData: [] })
		let params = {
			asset_id: asset.asset_id,
			risk: asset.risk,
			duration: this.state.selectedDuration,
			status: asset.status,
		}
		this.props.getRelatedIssues(params, (promise, relatedIssuesData) => {
			if (promise) {
				this.setState({ relatedIssuesData })
			} else {
				this.setState({ relatedIssuesData: [] })
			}
		})
		const countParams = {
			asset_id: asset.asset_id,
			duration: this.state.selectedDuration,
		}
		this.props.getDayWiseCount(countParams, (promise, dayWiseCountGraphData) => {
			if (promise) {
				this.setState({ dayWiseCountGraphData })
			}
			this.setState({ showDetailsRelatedIssueLoading: false })
		})
	}

	/**
	 * Get billing details of the detector
	 */
	getBillingSnap = asset => {
		this.setState({ showDetailsBillingSectionLoading: true })
		let params = {
			resource_id: asset.asset_id,
			provider: asset.provider,
			type: 'extended',
			current_month: true,
			last_month: true,
			duration: '3m',
			aggregate: 'daily',
		}
		this.props.getBillingSnapshot(params, billingDetails => {
			this.setState({ selectedRowBillingDetails: billingDetails, showDetailsBillingSectionLoading: false })
		})
	}
	/**
	 * Get asset events
	 */
	getAssetEvents = asset => {
		this.setState({ showDetailsEventsSectionLoading: true })
		const params = {
			provider: asset.provider,
			service_name: asset.service_name,
			asset_id: asset.asset_id,
			aggregate_by: 'event',
			duration: '3m',
			account_id: asset.account_id,
			// network: 'vpc-0804b9afc7e81dbcc',
			region: asset.region,
			// tags: [
			// 	{
			// 		key: 'Name',
			// 		value: 'Test_Public_EC2',
			// 	},
			// ],
		}
		this.props.getTopEvents(params, (promise, topEvents) => {
			if (promise) {
				this.setState({ topEventsData: topEvents, showDetailsEventsSectionLoading: false })
			} else {
				this.setState({ showDetailsEventsSectionLoading: false })
			}
		})

		const recentEventsParams = {
			service_name: asset.service_name,
			provider: asset.provider,
			asset_id: asset.asset_id,
		}
		this.props.getRecentEvents(recentEventsParams, (promise, recentEvents) => {
			if (promise) {
				this.setState({ recentEventsData: recentEvents, showDetailsEventsSectionLoading: false })
			} else {
				this.setState({ showDetailsEventsSectionLoading: false })
			}
		})

		const eventCountsParams = {
			service_name: asset.service_name,
			provider: asset.provider,
			asset_id: asset.asset_id,
			duration: '30d',
		}
		this.props.getEventGraphCounts(eventCountsParams, (promise, eventCounts) => {
			if (promise) {
				this.setState({ eventCountData: eventCounts, showDetailsEventsSectionLoading: false })
			} else {
				this.setState({ showDetailsEventsSectionLoading: false })
			}
		})
	}

	/**
	 * Get monitoring details of the detector
	 */
	getMonitoringSnap = (asset, duration) => {
		this.setState({
			showDetailsMonitoringSectionLoading: true,
			selectedRowMonitoringSnapshot: {},
			selectedMonitoringDuration: duration ? duration : '1h',
		})
		let params = {
			provider: asset.provider,
			account_id: asset.account_id,
			region: asset.region,
			servicename: asset.service_name,
			resource_id: asset.asset_id,
			timestamp: asset.asset_time,
			aggregated_by: 'Hour',
			anamoly: 2,
			boundary: duration ? duration : '1h',
		}
		this.props.getMonitoringSnapShot(params, snapshot => {
			this.setState({
				selectedRowMonitoringSnapshot: snapshot,
				showDetailsMonitoringSectionLoading: false,
				showDetailsSection: true,
			})
		})
	}

	/**
	 * Get suppress details of the detector
	 */
	getAssetSupressList = asset => {
		let params = {
			module_name: 'identifier',
			module_id: asset.asset_id,
		}
		/**
		 * List of rules against each identifier
		 */
		this.props.listAllSuppressors(params, supressRules => {
			this.setState({ supressRules })
			myLog(supressRules, 'supressRules')
		})
	}
	/**
	 * toggles action dropdown
	 */
	toggleActionDropdown = () => {
		this.setState(prevState => ({
			isActionDropdownOpen: !prevState.isActionDropdownOpen,
		}))
	}
	/**
	 * toggles details tab dropdown
	 */
	toggleDetailsTabListDropdown = () => {
		this.setState(prevState => ({
			showDetailsTabListDropdown: !prevState.showDetailsTabListDropdown,
		}))
	}
	/**
	 * Handles on click tab dropdown
	 */
	onClickDropDownItem = (item, index) => {
		let tempArray = this.state.categoryCounts
		tempArray.splice(index, 1)
		tempArray.splice(this.state.tabItemsToShow - 2, 0, item)
		this.setState({
			categoryCounts: tempArray,
			selectedTableTab: this.state.tabItemsToShow - 2,
			selectedCategory: item.category,
		})
		this.getAllDetectorsOnTabChange(item.category)
	}
	/**
	 * Get all detectors on change the tab
	 */
	getAllDetectorsOnTabChange = selectedCategory => {
		this.setState({ selectedCategory }, () => this.filterIdentifiers())
	}
	/**
	 * Handles on click details tab dropdown
	 */
	onClickDetailsTabDropDownItem = (item, index) => {
		let tempArray = this.state.detailsTabNames
		tempArray.splice(index, 1)
		// tempArray.splice(0, 0, item)
		tempArray.splice(this.state.detailsTabItemsToShow - 1, 0, item)
		this.setState({ detailsTabNames: tempArray })
		this.setState({ selectedDetailsTab: this.state.detailsTabItemsToShow - 1 })
	}
	/**
	 * Handles loading toggle from details section screen
	 */
	handleLoadingToggle = (from, boolean) => {
		if (from === 'asset') this.setState({ showDetailsAssetSectionLoading: boolean })
		if (from === 'related') this.setState({ showDetailsRelatedIssueLoading: boolean })
		if (from === 'events') this.setState({ showDetailsEventsSectionLoading: boolean })
		if (from === 'monitoring') this.setState({ showDetailsMonitoringSectionLoading: boolean })
		if (from === 'billing') this.setState({ showDetailsBillingSectionLoading: boolean })
	}

	render() {
		return (
			<div className='container-fluid overflow-auto flex-grow-1 bg-muted'>
				<div className='row h-100'>
					<div className={`${this.state.showDetailsSection ? 'col-sm-12 p-0' : 'd-none'} `}>
						<div className='title-section pb-1 pt-2'>
							<div className='row'>
								<div className='col-4 align-self-center'>
									<Link
										className='text-decoration-none'
										to={this.props.location.state && this.props.location.state.backToScreenUrl}
									>
										<i className='far fa-arrow-left mr-2' />
										Back
									</Link>
									<h6 className='text-white m-0'>Synchronizer</h6>
									<p className='text-white m-0'>Cloud Asset Details</p>
								</div>
								<div className='col-8 text-right'>
									<div className='d-flex text-white justify-content-end'>
										{this.props.assetDetails && this.props.assetDetails.status ? (
											<div className='ml-3'>
												<p className='mb-1'>
													Instance State{' '}
													<span className='badge badge-outline-danger'>{this.props.assetDetails.status}</span>
												</p>
											</div>
										) : null}
										{this.props.assetDetails && this.props.assetDetails.provider ? (
											<div className='ml-3'>
												<p className='mb-1'>
													Provider: <b>{this.props.assetDetails.provider.toUpperCase()}</b>
												</p>
											</div>
										) : null}
										{this.props.assetDetails && this.props.assetDetails.account_id ? (
											<div className='ml-3'>
												<p className='mb-1'>
													Account: <b>{this.props.assetDetails.account_id}</b>
												</p>
											</div>
										) : null}
									</div>
									<div className='d-flex text-white justify-content-end'>
										{this.props.assetDetails && this.props.assetDetails.resource_type ? (
											<div className='ml-3'>
												<p>
													Resource Type: <b>{this.props.assetDetails.resource_type}</b>
												</p>
											</div>
										) : null}
										<div className='ml-3'>
											<p>
												Console Link:{' '}
												<b>
													<a target='_blank' rel='noopener noreferrer' href='https://sr6o9gmy17.execute.com'>
														https://sr6o9gmy17.execute.com
													</a>
												</b>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						{this.props.assetDetails && Object.keys(this.props.assetDetails).length ? (
							<div className='container-fluid pt-3'>
								<div className='row'>
									<div
										className={`${
											this.props.assetStorageDetails.db_instance_storage_type ||
											this.props.assetDetails.es_data_instance_storage_limits ||
											this.props.assetDetails.instance_root_device_type
												? 'col-md-6'
												: this.props.assetDetails.tags &&
												  (!this.props.assetDetails.tags.length || this.props.assetDetails.tags.length >= 4)
												? 'col-md-9'
												: 'col-md-6'
										}`}
									>
										<BasicDetails
											region={this.props.assetDetails.region}
											availabilityZone={this.props.assetDetails.db_instance_availability_zone}
											serviceName={this.props.assetDetails.service_name}
											dbInstanceId={
												this.props.assetDetails.db_instance_identifier ||
												this.props.assetDetails.lambda_function_name ||
												this.props.assetDetails.asset_name
											}
											dbName={this.props.assetDetails.dbiResourceId}
											description={this.props.assetDetails.lambda_function_description}
											endpoint={
												this.props.assetDetails.es_domain_endpoints && this.props.assetDetails.es_domain_endpoints.vpc
											}
											dbInstanceArn={
												this.props.assetDetails.db_instance_arn ||
												this.props.assetDetails.lambda_function_arn ||
												this.props.assetDetails.es_domain_arn
											}
										/>
									</div>
									{/* {this.props.assetStorageDetails.es_data_instance_storage_limits &&
									!this.props.assetDetails.provisioned_concurrency_config ? ( */}
									<div
										className={
											!this.props.assetStorageDetails.es_data_instance_storage_limits &&
											!this.props.assetDetails.instance_root_device_type &&
											!this.props.assetDetails.provisioned_concurrency_config &&
											(!this.props.assetDetails.tags || !this.props.assetDetails.tags.length > 4)
												? 'col-md-6 pl-md-0'
												: 'col-md-3 pl-md-0'
										}
									>
										<UserDetails
											createdBy={
												trimmedUserName(this.props.assetDetails.created_by) ||
												trimmedUserName(this.props.assetDetails.action_by)
											}
											createdAt={this.props.assetDetails.created_at || this.props.assetDetails.asset_time}
										/>
									</div>
									{/* ) : null} */}
									{this.props.assetDetails.es_data_instance_storage_limits ||
									this.props.assetStorageDetails.db_instance_storage_type ||
									this.props.assetDetails.instance_root_device_type ||
									this.props.assetStorageDetails.es_data_instance_storage_limits ? (
										<div className='col-md-3 pl-md-0'>
											<StorageDetails
												IOPS={this.props.assetStorageDetails.db_instance_iops}
												storageType={
													this.props.assetStorageDetails.db_instance_storage_type ||
													(this.props.assetStorageDetails.es_data_instance_storage_limits &&
														this.props.assetStorageDetails.es_data_instance_storage_limits.storageTypeName)
												}
												storageSize={
													this.props.assetStorageDetails.db_instance_allocated_storage ||
													this.props.assetStorageDetails.es_ebs_volume_size
												}
												storageAutoScaling={
													this.props.assetStorageDetails.db_instance_storage_type ||
													(this.props.assetStorageDetails.es_data_instance_storage_limits &&
														this.props.assetStorageDetails.es_data_instance_storage_limits.storageSubTypeName)
												}
												maximumStorageThreshold={this.props.assetStorageDetails.maxAllocatedStorage}
												encyption={this.props.assetStorageDetails.db_instance_kms_key_id}
												rootDevicetype={this.props.assetDetails.instance_root_device_type}
												rootDeviceName={this.props.assetDetails.instance_root_device_name}
												blockDeviceMappings={
													this.props.assetDetails.instance_block_device_mappings &&
													this.props.assetDetails.instance_block_device_mappings.length &&
													this.props.assetDetails.instance_block_device_mappings[0].ebs.volumeId
												}
												blockDeviceMappingsVolume={
													this.props.assetDetails.instance_block_device_mappings &&
													this.props.assetDetails.instance_block_device_mappings.length &&
													this.props.assetDetails.instance_block_device_mappings[0].deviceName
												}
												dataStorageNodes={
													this.props.assetDetails.es_data_instance_storage_limits &&
													this.props.assetDetails.es_data_instance_storage_limits.length &&
													this.props.assetDetails.es_data_instance_storage_limits[0].storageTypeName
												}
												storageSubTypeName={
													this.props.assetDetails.es_data_instance_storage_limits &&
													this.props.assetDetails.es_data_instance_storage_limits.length &&
													this.props.assetDetails.es_data_instance_storage_limits[0].storageSubTypeName
												}
												es_ebs_volume_size={this.props.assetDetails.es_ebs_volume_size}
											/>
										</div>
									) : this.props.assetDetails.tags && this.props.assetDetails.tags.length < 4 ? (
										<div className='col-md-3 pl-md-0'>
											<Tags tags={this.props.assetDetails.tags} />
										</div>
									) : null}

									{this.props.assetDetails.provisioned_concurrency_config ? (
										<div className='col-md-3 pl-md-0'>
											<div className='box p-3 h-100'>
												<div className='d-flex mb-2'>
													<div className='flex-grow-1'>Unreserved account concurrency</div>
													<div className='text-right'>
														<b>1000</b>
													</div>
												</div>
												{this.props.assetDetails.lambda_function_event_source_mapping_configurations &&
												this.props.assetDetails.lambda_function_event_source_mapping_configurations.length ? (
													<>
														<div className='d-flex mb-2'>
															<div className='flex-grow-1'>Maximum age of event</div>
															<div className='text-right'>
																<b>
																	{
																		this.props.assetDetails.lambda_function_event_source_mapping_configurations
																			.maximumRecordAgeInSecond
																	}
																</b>
															</div>
														</div>
														<div className='d-flex mb-2'>
															<div className='flex-grow-1'>Retry attempts</div>
															<div className='text-right text-break'>
																<b>
																	{
																		this.props.assetDetails.lambda_function_event_source_mapping_configurations
																			.maximumRetryAttempts
																	}
																</b>
															</div>
														</div>
													</>
												) : null}
												{this.props.assetDetails.lambda_function_dead_letter_config ? (
													<div className='d-flex'>
														<div className='flex-grow-1'>Dead-letter queue</div>
														<div className='text-right text-break'>
															<b>{this.props.assetDetails.lambda_function_dead_letter_config.targetArn}</b>
														</div>
													</div>
												) : null}
											</div>
										</div>
									) : null}
								</div>
								<div className='row'>
									{this.props.assetDetails.tags && this.props.assetDetails.tags.length >= 4 ? (
										<div className='col-md-12 mt-3'>
											<Tags tags={this.props.assetDetails.tags} />
										</div>
									) : null}
								</div>
								<div className='row'>
									{this.props.assetDetails.db_instance_class || this.props.assetDetails.status ? (
										<div className='col-lg-4 mt-3'>
											<ServiceDetails
												dbInstanceEngineName={this.props.assetDetails.db_instance_engine_name}
												dbInstanceClass={this.props.assetDetails.db_instance_class}
												instanceType={this.props.assetDetails.instance_type}
												dbInstanceEndpointAddress={this.props.assetDetails.db_instance_endpoint_address}
												dbInstancePort={this.props.assetDetails.db_instance_port}
												dbInstancePublicAccessible={this.props.assetDetails.db_instance_public_accessible}
												iamDatabaseAuthenticationEnabled={this.props.assetDetails.iAMDatabaseAuthenticationEnabled}
												status={this.props.assetDetails.status}
												publicIp={this.props.assetDetails.instance_public_ip_address}
												publicDns={this.props.assetDetails.instance_public_dns_name}
												privateDns={this.props.assetDetails.instance_private_dns_name}
												privateIp={this.props.assetDetails.instance_private_ip_address}
												keyPairName={this.props.assetDetails.instance_key_pair_name}
												amiId={this.props.assetDetails.instance_image_id}
												iamRole={this.props.assetDetails.instance_iam_profile_arn}
											/>
										</div>
									) : null}
									{this.props.assetNetworkDetails &&
									this.props.assetNetworkDetails.network_details &&
									this.props.assetNetworkDetails.network_details.length ? (
										<div
											className={`${
												this.props.assetNetworkDetails.db_instance_engine_name ||
												this.props.assetDetails.db_instance_class ||
												this.props.assetDetails.status
													? 'col-lg-8 pl-0 mt-3'
													: 'pl-3 col-lg-12 mt-3'
											}`}
										>
											<NetworkDetails
												vpcId={this.props.assetNetworkDetails.network_details[0].vpc_id}
												subnetIdentifier={
													this.props.assetNetworkDetails.network_details[0].vpc_attached_subnets[0] ||
													this.props.assetNetworkDetails.es_subnets[0]
												}
												networkInterface={this.props.assetNetworkDetails.network_details[0].vpc_id}
												dbInstanceVpcSecurityGroups={
													this.props.assetNetworkDetails.network_details[0].vpc_attached_security_groups[0]
												}
												inboundRules={
													this.props.assetSGDetails &&
													this.props.assetSGDetails.security_groups_details &&
													this.props.assetSGDetails.security_groups_details[0].security_group_ip_permissions
												}
												outboundRules={
													this.props.assetSGDetails &&
													this.props.assetSGDetails.security_groups_details &&
													this.props.assetSGDetails.security_groups_details[0].security_group_ip_permissions_egress
												}
											/>
										</div>
									) : null}
								</div>
								<div className='row'>
									{this.props.assetDetails.db_instance_preferred_backup_window ||
									(this.props.assetDetails.es_log_publishing_options &&
										this.props.assetDetails.es_log_publishing_options.properties) ? (
										<div
											className={`${
												this.props.assetDetails.db_instance_preferred_backup_window &&
												this.props.assetDetails.db_instance_preferred_maintenance_window
													? 'col-sm-8 mt-3'
													: 'col-sm-12 mt-3'
											}`}
										>
											<LogsAndEvents
												dbInstanceAutoMinorVersionUpgrade={this.props.assetDetails.db_instance_preferred_backup_window}
												dbInstancePreferredMaintenanceWindow={
													this.props.assetDetails.db_instance_preferred_maintenance_window
												}
												name={
													this.props.assetDetails.es_log_publishing_options &&
													this.props.assetDetails.es_log_publishing_options.properties
												}
												modifications={this.props.assetDetails.db_instance_pending_modified_values}
												logDetails={this.props.assetDetails.db_log_files_details}
											/>
										</div>
									) : null}
									{this.props.assetDetails.db_instance_preferred_backup_window ||
									this.props.assetDetails.es_domain_endpoint_options ||
									this.props.assetDetails.es_encryption_atrest_options ||
									this.props.assetDetails.es_node_to_node_encryption_options ||
									this.props.assetDetails.es_cognito_options ? (
										<div
											className={`mt-3 ${
												(this.props.assetDetails.es_domain_endpoint_options ||
													this.props.assetDetails.es_encryption_atrest_options ||
													this.props.assetDetails.es_node_to_node_encryption_options) &&
												!this.props.assetDetails.lambda_function_runtime &&
												!this.props.assetDetails.es_log_publishing_options &&
												!this.props.assetDetails.db_instance_preferred_backup_window
													? 'col-sm-12'
													: 'col-sm-4 pl-2'
											}`}
										>
											<OtherDetails
												dbInstanceCaCertificateIdentifier={
													this.props.assetDetails.db_instance_ca_certificate_identifier
												}
												certificatesValidTill={' '}
												performanceInsight={
													this.props.assetDetails.performanceInsightsEnabled ? 'Enabled' : 'Not Enabled'
												}
												status={' '}
												proxyId={' '}
												engineCompatibility={' '}
												requireHttps={
													this.props.assetDetails.es_domain_endpoint_options &&
													this.props.assetDetails.es_domain_endpoint_options.options &&
													this.props.assetDetails.es_domain_endpoint_options.options.enforceHTTPS
														? 'True'
														: 'False'
												}
												restEncrption={
													this.props.assetDetails.es_encryption_atrest_options &&
													this.props.assetDetails.es_encryption_atrest_options.options &&
													this.props.assetDetails.es_encryption_atrest_options.options.enabled
														? 'True'
														: 'False'
												}
												nodeToNode={
													this.props.assetDetails.es_encryption_atrest_options &&
													this.props.assetDetails.es_encryption_atrest_options.options &&
													this.props.assetDetails.es_node_to_node_encryption_options.options.enabled
														? 'True'
														: 'False'
												}
												updateStatus={
													this.props.assetDetails.es_service_software_options &&
													this.props.assetDetails.es_service_software_options.updateStatus
												}
												startHour={this.props.assetDetails.es_snapshot_automated_start_hour}
												cognitoEnabled={
													this.props.assetDetails.es_cognito_options &&
													this.props.assetDetails.es_cognito_options.options &&
													this.props.assetDetails.es_cognito_options.options.enabled
														? 'True'
														: 'False'
												}
												softwareRelease={
													this.props.assetDetails.es_service_software_options &&
													this.props.assetDetails.es_service_software_options.currentVersion
												}
											/>
										</div>
									) : null}
									{this.props.assetDetails.lambda_function_runtime ? (
										<div className='mt-3 col-sm-4'>
											<ConfigurationDetails
												lambdaFunctionRuntime={this.props.assetDetails.lambda_function_runtime}
												lambdaFunctionMemorySize={this.props.assetDetails.lambda_function_memory_size}
												lambdaFunctionTimeout={this.props.assetDetails.lambda_function_timeout}
												lambda_function_role={trimmedUserName(this.props.assetDetails.lambda_function_role)}
												lambda_function_code_size={this.props.assetDetails.lambda_function_code_size}
												layers={
													this.props.assetDetails.layers &&
													this.props.assetDetails.layers.length &&
													this.props.assetDetails.layers[0].codeSize
												}
												lambda_function_tracing_config_mode={
													this.props.assetDetails.lambda_function_tracing_config_mode
												}
												lambda_function_handler={this.props.assetDetails.lambda_function_handler}
												lambda_function_configuration_versions={
													this.props.assetDetails.lambda_function_configuration_versions &&
													this.props.assetDetails.lambda_function_configuration_versions.length &&
													this.props.assetDetails.lambda_function_configuration_versions[0].version
												}
												project={
													this.props.assetDetails.lambda_function_configuration_versions &&
													this.props.assetDetails.lambda_function_configuration_versions.length &&
													this.props.assetDetails.lambda_function_configuration_versions[0].version
												}
											/>
										</div>
									) : null}
								</div>
								<div className='row'>
									{this.props.assetDetails.lambda_function_event_source_mapping_configurations &&
									this.props.assetDetails.lambda_function_event_source_mapping_configurations.length ? (
										<div className='col-sm-12 pl-3'>
											<TriggerConfiguration
												lambdaFunctionEventSourceMappingConfigurations={
													this.props.assetDetails.lambda_function_event_source_mapping_configurations
												}
											/>
										</div>
									) : null}
								</div>
								<div className='row my-3'>
									{this.props.assetDetails.db_instance_multi_az ||
									this.props.assetDetails.es_cluster_status ||
									this.props.assetDetails.es_domain_endpoint_options ? (
										<div className='col-sm-6 pr-0'>
											<ClusterDetails
												dbInstanceMultiAz={this.props.assetDetails.db_instance_multi_az ? 'Yes' : 'No'}
												dbInstanceAvailabilityZone={this.props.assetDetails.db_instance_availability_zone}
												dbInstanceMonitoringRoleArn={this.props.assetDetails.db_instance_arn}
												replicationSource={' '}
												esClusterStatus={
													this.props.assetDetails.es_cluster_status && this.props.assetDetails.es_cluster_status.state
												}
												esDomainEndpointOptions={
													this.props.assetDetails.es_domain_endpoint_options &&
													this.props.assetDetails.es_domain_endpoint_options.status &&
													this.props.assetDetails.es_domain_endpoint_options.status.state
												}
												ultraWarmEnabled={this.props.assetDetails.es_cluster_warm_enabled ? 'True' : 'False'}
												esAvailabilityZones={
													this.props.assetDetails.es_availability_zones &&
													this.props.assetDetails.es_availability_zones[0]
												}
												esClusterInstanceType={
													this.props.assetDetails.es_cluster_instance_type ||
													this.props.assetDetails.es_cluster_dedicated_master_type
												}
												esClusterInstanceCount={
													this.props.assetDetails.es_cluster_instance_count ||
													this.props.assetDetails.es_cluster_dedicated_master_count
												}
											/>
										</div>
									) : null}
									{this.props.assetDetails.instance_virtualization_type ? (
										<div
											className={`${
												this.props.assetDetails.instance_virtualization_type ? 'col-sm-4 pl-3' : 'col-sm-6 pl-3'
											}`}
										>
											<StateDetails
												status={this.props.assetDetails.status}
												instanceStatus={this.props.assetDetails.instance_status}
												systemStatus={
													this.props.assetDetails.instance_system_status_details &&
													this.props.assetDetails.instance_system_status_details.length &&
													this.props.assetDetails.instance_system_status_details[0].name
												}
												reasonMessage={this.props.assetDetails.instance_state_transition_reason}
											/>
										</div>
									) : null}
									{this.props.assetDetails.db_instance_preferred_backup_window ? (
										<div
											className={`${
												this.props.assetDetails.db_instance_preferred_backup_window ||
												this.props.assetDetails.instance_virtualization_type
													? 'col-sm-4'
													: 'col-sm-6 pl-0'
											}`}
										>
											<BackupAndSnapshot
												automatedBackups={this.props.assetDetails.automated_backups}
												restoreWindowlatestTime={' '}
												dbInstancePreferredBackupWindow={this.props.assetDetails.db_instance_preferred_backup_window}
												dbInstanceCopyTagsToSnapshot={
													this.props.assetDetails.db_instance_copy_tags_to_snapshot ? 'Yes' : 'No'
												}
												time={this.props.assetDetails.asset_time}
												status={this.props.assetDetails.status}
												snapshotName={this.props.assetDetails.db_instance_name}
												db_instance_copy_tags_to_snapshot={
													this.props.assetDetails.db_instance_copy_tags_to_snapshot ? 'True' : 'False'
												}
											/>
										</div>
									) : null}

									{this.props.assetDetails.instance_virtualization_type ? (
										<div className='col-sm-4 pl-0'>
											<OtherDetails
												virtualizationType={this.props.assetDetails.instance_virtualization_type}
												apiTermination={this.props.assetDetails.instance_attribute_disable_api_termination}
												monitoringState={this.props.assetDetails.instance_monitoring_state}
												shutdownBehavior={this.props.assetDetails.instance_attribute_initiated_shutdown_behaviour}
												sourceCheck={this.props.assetDetails.instance_source_dest_check ? 'True' : 'False'}
												tentancy={this.props.assetDetails.instance_placement_tenancy}
												lifeCycle={this.props.assetDetails.instance_life_cycle}
												placementGroupName={this.props.assetDetails.instance_placement_group_name}
												performanceInsight={
													this.props.assetDetails.performanceInsightsEnabled ? 'Enabled' : 'Not Enabled'
												}
											/>
										</div>
									) : null}

									{this.props.assetDetails.provisioned_concurrency_config &&
									this.props.assetDetails.provisioned_concurrency_config.length ? (
										<div className='col-sm-8 pl-0 my-3'>
											<ProvisionConcurrency
												provisionedConcurrencyConfig={this.props.assetDetails.provisioned_concurrency_config}
											/>
										</div>
									) : null}
								</div>
							</div>
						) : null}
					</div>

					{this.state.showScreenLoading ? (
						<div className='col-sm-12 p-0 d-flex justify-content-center'>
							<div className='mt-5'>
								<Spinner color='dark' size='lg' />
							</div>
						</div>
					) : null}

					{/* {!this.state.showScreenLoading ? (
						<div className='col-sm-9 p-0 d-flex flex-column justify-content-center align-items-center'>
							<p>No details found</p>
						</div>
					) : null} */}

					<div className={`p-0 col-sm-3 displayNone`}>
						{this.state.showConfigureSection ? (
							<ConfigureSection
								data={this.state.assetConfigrationsList}
								onClickBackButton={() => this.setState({ showConfigureSection: false, showDetailsSection: true })}
							/>
						) : null}

						<div
							className={`
								${this.props.location.state && this.props.location.state.asset &&
									Object.keys(this.props.location.state.asset).length &&
									this.state.showDetailsSection
										? 'detail'
										: 'd-none'
								}
							`}
						>
							<div className='detail-title'>
								<div className='d-flex justify-content-end'>
									<div className='dropdown mb-2'>
										<ButtonDropdown isOpen={this.state.isActionDropdownOpen} toggle={() => this.toggleActionDropdown()}>
											<DropdownToggle className='btn-light btn-sm btn-xs dropdown-toggle' caret>
												Action
											</DropdownToggle>
											<DropdownMenu>
												<DropdownItem>Fix</DropdownItem>
												<DropdownItem>Suppress</DropdownItem>
												<DropdownItem>Configure</DropdownItem>
											</DropdownMenu>
										</ButtonDropdown>
									</div>
								</div>
								<div className='d-lg-flex'>
									<div className={`d-flex flex-column health-score mr-3 justify-content-end`}>
										<h3 className='m-0'>
											{this.props.location.state && this.props.location.state.asset.score ? this.props.location.state.asset.score : 0}
										</h3>
										<small className='fs-12 badge badge-primary rounded-0 mt-1'>Health</small>
									</div>
									<div className='align-self-center text-white'>
										<table>
											<tbody>
												<tr>
													<td className='text-right pr-2'>Region: </td>
													<td>{this.props.location.state && this.props.location.state.asset.region}</td>
												</tr>
												<tr>
													<td className='text-right pr-2'>Account: </td>
													<td>
														{this.props.location.state && this.props.location.state.asset.provider +
															':' +
															this.props.location.state && this.props.location.state.asset.account_id}
													</td>
												</tr>
												<tr>
													<td className='text-right pr-2'>Resource: </td>
													<td>{this.props.location.state && this.props.location.state.asset.resource_type}</td>
												</tr>
												<tr>
													<td className='text-right pr-2'>Service: </td>
													<td>{this.props.location.state && this.props.location.state.asset.service_name}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
								<span className='badge badge-pill badge-secondary p-2 mt-2 text-break text-wrap'>
									{this.props.location.state && this.props.location.state.asset.asset_name}
								</span>
								<p className='mt-2 mb-0'>
									<small className='text-white mt-2'>
										{this.props.location.state && this.props.location.state.asset.status === 'pass'
											? this.props.location.state.asset.description
											: this.props.location.state.asset.detail}
									</small>
									<small className='text-white mt-2'>{this.props.location.state && this.props.location.state.asset.additional_details}</small>
								</p>
							</div>
							<div className='detail-content'>
								{/* View Configuration */}
								{this.state.showSupressInDetailsSection ? (
									<SupressSection
										onClickSupressBackButton={() => {
											this.setState({
												showSupressInDetailsSection: false,
												showDetailsSection: true,
												onClickSupressCreateButton: false,
												// selectedDetailsTab: 0,
											})
										}}
										supressRules={this.state.supressRules}
										showLoading={this.state.showSupressSectionLoading}
										providersData={this.state.providers}
										selectedRowAssetDetails={this.state.selectedRowAssetDetails}
										showSupressForm={this.state.onClickSupressCreateButton}
										onClickSupressCreateButton={bool => this.setState({ onClickSupressCreateButton: bool })}
									/>
								) : null}

								{this.state.showDetailsSection ? (
									<DetailsSection
										selectedTableRow={this.props.location.state && this.props.location.state.asset}
										detailsTabNames={this.state.detailsTabNames}
										detailsTabItemsToShow={this.state.detailsTabItemsToShow}
										showDetailsTabListDropdown={this.state.showDetailsTabListDropdown}
										selectedDetailsTab={this.state.selectedDetailsTab}
										selectedRowAssetDetails={this.state.selectedRowAssetDetails}
										selectedRowMonitoringSnapshot={this.state.selectedRowMonitoringSnapshot}
										selectedRowBillingDetails={this.state.selectedRowBillingDetails}
										showDetailsAssetSectionLoading={this.state.showDetailsAssetSectionLoading}
										showDetailsRelatedIssueLoading={this.state.showDetailsRelatedIssueLoading}
										showDetailsBillingSectionLoading={this.state.showDetailsBillingSectionLoading}
										showDetailsMonitoringSectionLoading={this.state.showDetailsMonitoringSectionLoading}
										showDetailsEventsSectionLoading={this.state.showDetailsEventsSectionLoading}
										toggleDetailsSectionLoading={(from, bool) => this.handleLoadingToggle(from, bool)}
										getAssetDetails={item => this.getAssetDetails(item)}
										getRelatedIssues={item => this.getRelatedIssues(item)}
										getBillingSnap={item => this.getBillingSnap(item)}
										getAssetEvents={(item, duration) => this.getAssetEvents(item, duration)}
										getMonitoringSnap={(item, duration) => this.getMonitoringSnap(item, duration)}
										dayWiseCountGraphData={this.state.dayWiseCountGraphData}
										toggleDetailsTabListDropdown={() => this.toggleDetailsTabListDropdown()}
										onClickDetailsTabDropDownItem={(item, index) => this.onClickDetailsTabDropDownItem(item, index)}
										setSelectedDetailsTabIndex={index => this.setState({ selectedDetailsTab: index })}
										relatedIssuesData={this.state.relatedIssuesData}
										topEventsData={this.state.topEventsData}
										recentEventsData={this.state.recentEventsData}
										eventGraphData={this.state.eventCountData}
										selectedMonitoringDuration={this.state.selectedMonitoringDuration}
									/>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
/**
 * Type of the props used in the component
 */
AssetDetail.propTypes = {
	location: PropTypes.object,
	getAssetDetailExt: PropTypes.func,
	listAllProviders: PropTypes.func,
	listAllAccounts: PropTypes.func,
	listAllRegions: PropTypes.func,
	listAllServices: PropTypes.func,
	listAllAssets: PropTypes.func,
	getTotalCount: PropTypes.func,
	getDayWiseCount: PropTypes.func,
	getMonitoringSnapShot: PropTypes.func,
	getTopEvents: PropTypes.func,
	getRecentEvents: PropTypes.func,
	listAllSuppressors: PropTypes.func,
	getAssetDetails: PropTypes.func,
	getBillingSnapshot: PropTypes.func,
	getEventGraphCounts: PropTypes.func,
	getRelatedIssues: PropTypes.func,
	getTotalAssetCount: PropTypes.func,
	getDailyAssetCount: PropTypes.func,
	history: PropTypes.object,
	assets: PropTypes.array,
	assetDetails: PropTypes.object,
	assetNetworkDetails: PropTypes.object,
	assetSGDetails: PropTypes.object,
	assetStorageDetails: PropTypes.object,
	getAssetNetworkDetails: PropTypes.func,
	getAssetSGDetails: PropTypes.func,
	getAssetStorageDetails: PropTypes.func,
	match: PropTypes.object,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	console.log('state', state)
	return {
		assetDetails: state.assets.assetDetails[0],
		assetNetworkDetails: state.assets.assetNetworkDetails,
		assetSGDetails: state.assets.assetSGDetails,
		assetStorageDetails: state.assets.assetStorageDetails,
	}
}

export default AppWrapper(AssetDetail, mapStateToProps, {
	getAssetDetails,
	getBillingSnapshot,
	getAssetNetworkDetails,
	getAssetSGDetails,
	getAssetStorageDetails,
	getRelatedIssues,
	getMonitoringSnapShot,
	listAllSuppressors,
	getDayWiseCount,
	getAssetDetailExt,
	getTopEvents,
	getRecentEvents,
	getEventGraphCounts,
})
