import React, { Component } from 'react'
import { connect } from 'react-redux'
import { UncontrolledTooltip, Spinner, Input } from 'reactstrap'
import { setEditRuleDetails } from '../../../actions/governance/governanceAction'
import PropTypes from 'prop-types'
import _ from 'lodash'
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import Select from 'react-select'
import { onlyNumeric, stringLength } from '../../../utils/utility'

const instanceModel = [
	// {label: 'Master', value: 'Master'},
	// {label: 'Slave', value: 'Slave'}
]

class ServiceSizing extends Component {
	constructor(props) {
		super(props)
		this.state = {
			hasError: false,
			serviceSizingArray: [],

			//decision: this.props.editRuleDetails.decision ? this.props.editRuleDetails.decision : '',
			decision: '',
			totalDecisionCount: 0,
			totalCount: '',
			allowedInstance: [],
			selectedInstanceFamily: [],
			selectedInstanceType: [],
			selectedInstanceSeries: [],
			
			totalSelectedState: [],

			priceRangeValue: { min: 0, max: 5 },
			selectedInstanceFilter: {},
		}
	}
	
	componentDidMount = () => {
		let condition = this.props.editRuleDetails.condition ? this.props.editRuleDetails.condition : {}
		let allowedCondition = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedInstanceFamily ? 
		this.props.editRuleDetails.condition.allowedInstanceFamily : []
		if(allowedCondition.length) {
			let data = allowedCondition.filter(obj => obj.node_type === this.state.decision)
			let selectedInstanceFamily = data[0].instance_family
			let selectedInstanceSeries = data[0].instance_series
			let selectedInstanceType = data[0].instance_type
			let allowedInstance = data[0].allowed_instance
			let totalCount = data[0].total_count
			this.setState({ selectedInstanceFamily, selectedInstanceSeries, selectedInstanceType, allowedInstance, totalCount }) 
			
			this.props.condition.forEach(parent => {
				if(selectedInstanceFamily.length && selectedInstanceFamily.filter(obj => obj.family === parent.description)) {
					this.setState({ ['family_'+parent.description]: true })
				} else {
					this.setState({ ['family_'+parent.description]: false })
				}
				parent.vm_series.forEach(child => {
					if(selectedInstanceSeries.length) {
						let objIndex = selectedInstanceSeries.findIndex((obj => obj.series === child.sesries ));
						if(objIndex >= 0) {
							this.setState({ ['series_'+child.series]: true })
						} else {
							this.setState({ ['series_'+child.series]: false })
						}
					} else {
						this.setState({ ['series_'+child.series]: false })
					}
					child.vm_types.forEach(type => {
						if(selectedInstanceType.length) {
							let objIndex = selectedInstanceType.findIndex((obj => obj.type === type.instanceType ));
							if(objIndex >= 0) {
								this.setState({ 
									[type.instanceType]: true, 
									['number_'+type.instanceType]: selectedInstanceType[objIndex].count > 0 ? selectedInstanceType[objIndex].count : '' 
								}, 
									() => this.getCountArray()
								)
							} else {
								this.setState({ [type.instanceType]: false, ['number_'+type.instanceType]: '' })
							}
						} else {
							this.setState({ [type.instanceType]: false })
						}
					})
				})
			})
		} else {
			if(instanceModel.length) {
				let allowedCondition = []
				instanceModel.forEach((item, i) => {
					if(i === 0) {
						this.props.setEditRuleDetails('decision', item.label)
					}
					let decisionRow = {}
					decisionRow.node_type = item.label
					decisionRow.instance_family = []
					decisionRow.instance_series = []
					decisionRow.instance_type = []
					decisionRow.allowed_instance = []
					decisionRow.total_count = 0
					allowedCondition.push(decisionRow)
				})
				condition['allowedInstanceFamily'] = allowedCondition
				this.props.setEditRuleDetails('condition', condition)
			} else {
				let allowedCondition = []
				let decisionRow = {}
				decisionRow.node_type = this.state.decision
				decisionRow.instance_family = []
				decisionRow.instance_series = []
				decisionRow.instance_type = []
				decisionRow.allowed_instance = []
				decisionRow.total_count = 0
				allowedCondition.push(decisionRow)
				condition['allowedInstanceFamily'] = allowedCondition
				this.props.setEditRuleDetails('condition', condition)
			}
		}	
	}

	componentDidUpdate = (prevProps) => {
		if((this.props.condition && this.props.condition.length && !this.state.serviceSizingArray.length) || (!_.isEqual(prevProps.condition, this.props.condition))) {
			let instanceFamily = []
			if (this.props.condition) {
				this.props.condition.forEach(result => {
					instanceFamily.push(result.description)
				})
			}

			let filterServiceSizingArray = []
			if(this.state.allowedInstance.length) {
				this.props.condition.forEach(result => {
					if(this.state.allowedInstance.includes(result.description)) {
						filterServiceSizingArray.push(result)	
					}
				})
			}

			this.setState({ 
				serviceSizingArray: this.props.condition, 
				filterServiceSizingArray, 
				instanceFamily 
			})

			let condition = this.props.editRuleDetails.condition ? this.props.editRuleDetails.condition : {}
			let allowedCondition = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedInstanceFamily ? 
			this.props.editRuleDetails.condition.allowedInstanceFamily : []
			if(allowedCondition.length) {
				let data = allowedCondition.filter(obj => obj.node_type === this.state.decision)
				let selectedInstanceFamily = data[0].instance_family
				let selectedInstanceSeries = data[0].instance_series
				let selectedInstanceType = data[0].instance_type
				let allowedInstance = data[0].allowed_instance
				let totalCount = data[0].total_count
				this.setState({ selectedInstanceFamily, selectedInstanceSeries, selectedInstanceType, allowedInstance, totalCount }) 
				
				this.props.condition.forEach(parent => {
					if(selectedInstanceFamily.length && selectedInstanceFamily.filter(obj => obj.family === parent.description)) {
						this.setState({ ['family_'+parent.description]: true })
					} else {
						this.setState({ ['family_'+parent.description]: false })
					}
					parent.vm_series.forEach(child => {
						if(selectedInstanceSeries.length) {
							let objIndex = selectedInstanceSeries.findIndex((obj => obj.series === child.sesries ));
							if(objIndex >= 0) {
								this.setState({ ['series_'+child.series]: true })
							} else {
								this.setState({ ['series_'+child.series]: false })
							}
						} else {
							this.setState({ ['series_'+child.series]: false })
						}
						child.vm_types.forEach(type => {
							if(selectedInstanceType.length) {
								let objIndex = selectedInstanceType.findIndex((obj => obj.type === type.instanceType ));
								if(objIndex >= 0) {
									this.setState({ 
										[type.instanceType]: true, 
										['number_'+type.instanceType]: selectedInstanceType[objIndex].count > 0 ? selectedInstanceType[objIndex].count : '' 
									}, 
										() => this.getCountArray()
									)
								} else {
									this.setState({ [type.instanceType]: false, ['number_'+type.instanceType]: '' })
								}
							} else {
								this.setState({ [type.instanceType]: false })
							}
						})
					})
				})
			} else {
				if(instanceModel.length) {
					let allowedCondition = []
					instanceModel.forEach((item, i) => {
						if(i === 0) {
							this.props.setEditRuleDetails('decision', item.label)
						}
						let decisionRow = {}
						decisionRow.node_type = item.label
						decisionRow.instance_family = []
						decisionRow.instance_series = []
						decisionRow.instance_type = []
						decisionRow.allowed_instance = []
						decisionRow.total_count = 0
						allowedCondition.push(decisionRow)
					})
					condition['allowedInstanceFamily'] = allowedCondition
					this.props.setEditRuleDetails('condition', condition)
				} else {
					let allowedCondition = []
					let decisionRow = {}
					decisionRow.node_type = this.state.decision
					decisionRow.instance_family = []
					decisionRow.instance_series = []
					decisionRow.instance_type = []
					decisionRow.allowed_instance = []
					decisionRow.total_count = 0
					allowedCondition.push(decisionRow)
					condition['allowedInstanceFamily'] = allowedCondition
					this.props.setEditRuleDetails('condition', condition)
				}
			}
		}
		// if(this.props.redirectDecision !== this.state.decision) {
		// 	this.setState({ decision: this.props.redirectDecision })
		// }

		if(this.props.hasError !== this.state.hasError) {
			this.setState({ hasError: this.props.hasError })
		}

	}
	
	setAllowedInstanceFamily = (type, index) => {
		const exist = this.state.allowedInstance.includes(type)
		let allowedInstance = []
		if (exist) {
			allowedInstance = this.state.allowedInstance.filter(e => e !== type )
			this.setState({ allowedInstance, ['instance_'+type]: false},
				() => this.handleParentChange('instance', index, type, '', '')
			)
		} else {
			allowedInstance = this.state.allowedInstance
			allowedInstance.push(type)
			this.setState({ allowedInstance })
		}
		
		let filterServiceSizingArray = []
		if(allowedInstance.length) {
			this.state.serviceSizingArray.forEach(result => {
				if(allowedInstance.includes(result.description)) {
					filterServiceSizingArray.push(result)	
				}
			})
		}

		if(!filterServiceSizingArray.length) {
			this.setState({
				selectedInstanceFamily: [],
				selectedInstanceSeries: [],
				selectedInstanceType: [],
			})
			this.state.serviceSizingArray.forEach(parent => {
				this.setState({ ['family_'+parent.description]: false })
				parent.vm_series.forEach(child => {
					this.setState({ ['series_'+child.series]: false })
					child.vm_types.forEach(types => {
						this.setState({ [types.instanceType]: false })
					})
				})
			})
		}

		let condition = this.props.editRuleDetails.condition ? this.props.editRuleDetails.condition : {}
		let allowedCondition = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedInstanceFamily ? 
		this.props.editRuleDetails.condition.allowedInstanceFamily : []		
		let decisionArray = []
		let decisionRow = {}
		if(allowedCondition.length) {
			decisionRow.node_type = this.state.decision
			decisionRow.instance_family = allowedInstance.length ? this.state.selectedInstanceFamily : []
			decisionRow.instance_series = allowedInstance.length ? this.state.selectedInstanceSeries : []
			decisionRow.instance_type = allowedInstance.length ? this.state.selectedInstanceType : []
			decisionRow.allowed_instance = allowedInstance
			decisionRow.total_count = this.state.totalCount
			// console.log('this.state.decision', this.state.decision)
			if(allowedCondition.filter(obj => obj.node_type === this.state.decision).length) {
				let objIndex = allowedCondition.findIndex((obj => obj.node_type === this.state.decision));
				allowedCondition[objIndex] = decisionRow
				condition['allowedInstanceFamily'] = allowedCondition
				this.props.setEditRuleDetails('condition', condition)
			} else {
				allowedCondition.push(decisionRow)
				condition['allowedInstanceFamily'] = allowedCondition
				this.props.setEditRuleDetails('condition', condition)
			}
			//this.formPreivew(condition)
		} else {
			decisionRow.node_type = this.state.decision
			decisionRow.instance_family = allowedInstance.length ? this.state.selectedInstanceFamily : []
			decisionRow.instance_series = allowedInstance.length ? this.state.selectedInstanceSeries : []
			decisionRow.instance_type = allowedInstance.length ? this.state.selectedInstanceType : []
			decisionRow.allowed_instance = allowedInstance
			decisionRow.total_count = this.state.totalCount
			decisionArray.push(decisionRow)
			condition['allowedInstanceFamily'] = decisionArray
			this.props.setEditRuleDetails('condition', condition)

			//this.formPreivew(decisionArray)
		}		

		this.setState({ filterServiceSizingArray })
	}

	handleParentChange = (parentType, index, family, srIndex, series) => {
		let selectedInstanceFamily = this.state.selectedInstanceFamily
		let selectedInstanceSeries = this.state.selectedInstanceSeries
		let selectedInstanceType = this.state.selectedInstanceType
		if(parentType === 'series') {
			let newArray = []
			this.state.serviceSizingArray.forEach(parent => {
				if(parent.description === family) {
					parent.vm_series.forEach(child => {						
						if(child.series === series) {
							if(this.state['series_'+series]) {
								let seriesRow = {}
								seriesRow.series = child.series
								seriesRow.count = ''
								selectedInstanceSeries.push(seriesRow)
								child.vm_types.forEach(types => {
									if(this.state[types.instanceType]) {
										selectedInstanceType.length && selectedInstanceType.forEach(item => {
											if(item.type ===  types.instanceType) {
												newArray.push(item)
											}
										})
									} else {
										// let totalRow = {}
										types.type = types.instanceType
										types.count = ''
										newArray.push(types)
									}
									this.setState({ [types.instanceType]: this.state['series_'+series] ? true : false, selectedInstanceSeries })
								})
							} else {
								selectedInstanceSeries = selectedInstanceSeries.filter(obj => obj.series !== series);
								child.vm_types.forEach(types => {
									selectedInstanceType = selectedInstanceType.filter(obj => obj.type !== types.instanceType);
									this.setState({ [types.instanceType]: this.state['series_'+series] ? true : false, ['number_'+types.instanceType]: ''})
								})
							}
						}
					})
				}
			})
			let result = selectedInstanceType
			if(newArray.length) {
				result = _.unionBy(newArray, selectedInstanceType, 'type');
			} if(newArray.length && !selectedInstanceType.length) {
				result = newArray
			}

			let condition = this.props.editRuleDetails.condition ? this.props.editRuleDetails.condition : {}
			let allowedCondition = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedInstanceFamily ? 
			this.props.editRuleDetails.condition.allowedInstanceFamily : []		
			let decisionArray = []
			let decisionRow = {}
			if(allowedCondition.length) {
				decisionRow.node_type = this.state.decision
				decisionRow.instance_family = this.state.selectedInstanceFamily
				decisionRow.instance_series = selectedInstanceSeries
				decisionRow.instance_type = result
				decisionRow.allowed_instance = this.state.allowedInstance
				decisionRow.total_count = this.state.totalCount
				if(allowedCondition.filter(obj => obj.node_type === this.state.decision).length) {
					let objIndex = allowedCondition.findIndex((obj => obj.node_type === this.state.decision));
					allowedCondition[objIndex] = decisionRow
					condition['allowedInstanceFamily'] = allowedCondition
					this.props.setEditRuleDetails('condition', condition)
				} else {
					allowedCondition.push(decisionRow)
					condition['allowedInstanceFamily'] = allowedCondition
					this.props.setEditRuleDetails('condition', condition)
				}
				//this.formPreivew(condition)
			} else {
				decisionRow.node_type = this.state.decision
				decisionRow.instance_family = this.state.selectedInstanceFamily
				decisionRow.instance_series = selectedInstanceSeries
				decisionRow.instance_type = result
				decisionRow.allowed_instance = this.state.allowedInstance
				decisionRow.total_count = this.state.totalCount
				decisionArray.push(decisionRow)
				condition['allowedInstanceFamily'] = decisionArray
				this.props.setEditRuleDetails('condition', condition)
				//this.formPreivew(decisionArray)
			}


			this.setState({ selectedInstanceType : result, selectedInstanceSeries },
				() => this.getCountArray()
			)
		} else {
			let newArray = []
			this.state.serviceSizingArray.forEach(parent => {
				if(parent.description === family) {
					let familyCount = 0
					let newSeriesArray = []
					if(this.state['family_'+family]) {						
						parent.vm_series.forEach(child => {
							let seriesCount = 0
							this.setState({ ['series_'+child.series]: this.state['family_'+family] })
							child.vm_types.forEach(types => {
								if(this.state[types.instanceType]) {
									selectedInstanceType.length && selectedInstanceType.forEach(item => {
										if(item.type ===  types.instanceType) {
											newArray.push(item)
											familyCount = parseInt(familyCount) + parseInt(item.count)
											seriesCount = parseInt(seriesCount) + parseInt(item.count)
										}
									})
								} else {
									// let totalRow = {}
									types.type = types.instanceType
									types.count = ''
									newArray.push(types)
								}
								this.setState({ [types.instanceType]: this.state['family_'+family] })
							})
							let seriesRow = {}
							seriesRow.series = child.series
							seriesRow.count = seriesCount
							newSeriesArray.push(seriesRow)
						})

						let familyRow = {}
						familyRow.family = parent.description
						familyRow.count = familyCount
						selectedInstanceFamily.push(familyRow)
						selectedInstanceSeries = _.unionBy(newSeriesArray, selectedInstanceSeries, 'series');
					} else {
						selectedInstanceFamily = selectedInstanceFamily.filter(obj => obj.family !== parent.description);
						this.state.serviceSizingArray.forEach(parent => {
							if(parent.description === family) {	
								this.setState({ ['family_'+parent.description]: false })
								parent.vm_series.forEach(child => {	
									selectedInstanceSeries = selectedInstanceSeries.filter(obj => obj.series !== child.series);
									this.setState({ ['series_'+child.series]: false })
									child.vm_types.forEach(types => {
										selectedInstanceType = selectedInstanceType.filter(obj => obj.type !== types.instanceType);
										this.setState({ [types.instanceType]: false, ['number_'+types.instanceType]: '' })
									})
								})
							}
						})
					}
				}
			})
			let result = selectedInstanceType
			if(newArray.length) {
				result = _.unionBy(newArray, selectedInstanceType, 'type');
			} if(newArray.length && !selectedInstanceType.length) {
				result = newArray
			}

			let condition = this.props.editRuleDetails.condition ? this.props.editRuleDetails.condition : {}
			let allowedCondition = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedInstanceFamily ? 
			this.props.editRuleDetails.condition.allowedInstanceFamily : []		
			let decisionArray = []
			let decisionRow = {}
			if(allowedCondition.length) {
				decisionRow.node_type = this.state.decision
				decisionRow.instance_family = selectedInstanceFamily
				decisionRow.instance_series = selectedInstanceSeries
				decisionRow.instance_type = result
				decisionRow.allowed_instance = this.state.allowedInstance
				decisionRow.total_count = this.state.totalCount
				if(allowedCondition.filter(obj => obj.node_type === this.state.decision).length) {
					let objIndex = allowedCondition.findIndex((obj => obj.node_type === this.state.decision));
					allowedCondition[objIndex] = decisionRow
					condition['allowedInstanceFamily'] = allowedCondition
					this.props.setEditRuleDetails('condition', condition)
				} else {
					allowedCondition.push(decisionRow)
					condition['allowedInstanceFamily'] = allowedCondition
					this.props.setEditRuleDetails('condition', condition)
				}
				//this.formPreivew(condition)
			} else {
				decisionRow.node_type = this.state.decision
				decisionRow.instance_family = selectedInstanceFamily
				decisionRow.instance_series = selectedInstanceSeries
				decisionRow.instance_type = result
				decisionRow.allowed_instance = this.state.allowedInstance
				decisionRow.total_count = this.state.totalCount
				decisionArray.push(decisionRow)
				condition['allowedInstanceFamily'] = decisionArray
				this.props.setEditRuleDetails('condition', condition)
				//this.formPreivew(decisionArray)
			}

			this.setState({ selectedInstanceType : result, selectedInstanceSeries , selectedInstanceFamily},
				() => this.getCountArray()
			)
		}
	}

	handleMultiChange = (inputType, index, family, srIndex, series, instanceType, types) => {		
		let selectedInstanceFamily = this.state.selectedInstanceFamily
		let selectedInstanceSeries = this.state.selectedInstanceSeries
		// let dataRow = {}
		let selectedInstanceType = this.state.selectedInstanceType
		if (inputType === 'checkbox') {
			if(!this.state[instanceType]) {
				types.count = ''
				types.type = instanceType
				selectedInstanceType.push(types)
				this.setState({ [instanceType]: true, selectedInstanceType })
			} else {
				selectedInstanceFamily = selectedInstanceFamily.filter(obj => obj.family !== family);
				selectedInstanceSeries = selectedInstanceSeries.filter(obj => obj.series !== series);
				selectedInstanceType = selectedInstanceType.filter(obj => obj.type !== instanceType);

				this.setState({ selectedInstanceFamily, selectedInstanceSeries, ['family_'+family]: false, ['series_'+series]: false, [instanceType]: false, ['number_'+instanceType]: '', selectedInstanceType },
					() => this.getCountArray()
				)
			}
		} else {

			// if(this.state.totalCount !== '') {
				// let totalDecisionCount = this.state.totalDecisionCount
				// let totalCountAllowed = this.state.totalCount
	
	
				// if(this.state.totalCount === '' || this.state.totalCount === 0 || parseInt(totalCountAllowed) >= (parseInt(totalDecisionCount) + parseInt(this.state['number_'+instanceType]))) {
					let objIndex = selectedInstanceType.findIndex((obj => obj.type === instanceType));
					//Update object's instanceType property.
					selectedInstanceType[objIndex].count = this.state['number_'+instanceType]
					this.setState({ selectedInstanceType },
						() => this.getCountArray()
					)
				// } else {
					// alert("Please increase total instance to add more max instance")
					// this.setState({ ['number_'+instanceType]: '' })
				// }
			// } else {
			// 	() => this.getCountArray()
			// }
		}		

		let condition = this.props.editRuleDetails.condition ? this.props.editRuleDetails.condition : {}
		let allowedCondition = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedInstanceFamily ? 
		this.props.editRuleDetails.condition.allowedInstanceFamily : []		
		let decisionArray = []
		let decisionRow = {}
		if(allowedCondition.length) {
			decisionRow.node_type = this.state.decision
			decisionRow.instance_family = selectedInstanceFamily
			decisionRow.instance_series = selectedInstanceSeries
			decisionRow.instance_type = selectedInstanceType
			decisionRow.allowed_instance = this.state.allowedInstance
			decisionRow.total_count = this.state.totalCount
			if(allowedCondition.filter(obj => obj.node_type === this.state.decision).length) {
				let objIndex = allowedCondition.findIndex((obj => obj.node_type === this.state.decision));
				allowedCondition[objIndex] = decisionRow
				condition['allowedInstanceFamily'] = allowedCondition
				this.props.setEditRuleDetails('condition', condition)
			} else {
				allowedCondition.push(decisionRow)
				condition['allowedInstanceFamily'] = allowedCondition
				this.props.setEditRuleDetails('condition', condition)
			}
			//this.formPreivew(condition)
		} else {
			decisionRow.node_type = this.state.decision
			decisionRow.instance_family = selectedInstanceFamily
			decisionRow.instance_series = selectedInstanceSeries
			decisionRow.instance_type = selectedInstanceType
			decisionRow.allowed_instance = this.state.allowedInstance
			decisionRow.total_count = this.state.totalCount
			decisionArray.push(decisionRow)
			condition['allowedInstanceFamily'] = decisionArray
			this.props.setEditRuleDetails('condition', condition)
			//this.formPreivew(decisionArray)
		}

		// let totalArray = []
		// if(this.state.totalSelectedState.length) {
		// 	this.state.totalSelectedState.map(item => {
		// 		let totalObject = {}
		// 		if(item.node_type === this.state.decision) {
		// 			item.instance_type = selectedInstanceType
		// 		}
		// 		totalArray.push(item)
		// 	})
		// } else {
		// 	let totalRow = {}
		// 	totalRow.node_type = this.state.decision
		// 	totalRow.instance_type = selectedInstanceType
		// 	totalArray.push(totalRow)
		// }

		// this.setState({ totalSelectedState: totalArray })
	}

	handleInstanceCount = (totalCount) => {
		let condition = this.props.editRuleDetails.condition ? this.props.editRuleDetails.condition : {}
		let allowedCondition = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedInstanceFamily ? 
		this.props.editRuleDetails.condition.allowedInstanceFamily : []		
		let decisionArray = []
		let decisionRow = {}
		decisionRow.node_type = this.state.decision
		decisionRow.instance_family = this.state.selectedInstanceFamily
		decisionRow.instance_series = this.state.selectedInstanceSeries
		decisionRow.instance_type = this.state.selectedInstanceType
		decisionRow.allowed_instance = this.state.allowedInstance
		decisionRow.total_count = totalCount

		if(allowedCondition.length) {
			if(allowedCondition.filter(obj => obj.node_type === this.state.decision).length) {
				let objIndex = allowedCondition.findIndex((obj => obj.node_type === this.state.decision));
				allowedCondition[objIndex] = decisionRow
				condition['allowedInstanceFamily'] = allowedCondition
				this.props.setEditRuleDetails('condition', condition)
			} else {
				allowedCondition.push(decisionRow)
				condition['allowedInstanceFamily'] = allowedCondition
				this.props.setEditRuleDetails('condition', condition)
			}
			//this.formPreivew(condition)
		} else {
			decisionArray.push(decisionRow)
			condition['allowedInstanceFamily'] = decisionArray
			this.props.setEditRuleDetails('condition', condition)
			//this.formPreivew(decisionArray)
		}

		this.setState({ totalCount })
	}

	getCountArray = () => {		
		let totalDecisionCount = 0
		this.state.serviceSizingArray.forEach(parent => {
			let familyCount = 0
			parent.vm_series.forEach(child => {
				let seriesCount = 0
				child.vm_types.forEach(type => {
					seriesCount += this.state['number_'+type.instanceType] ? parseInt(this.state['number_'+type.instanceType]) : 0
					familyCount += this.state['number_'+type.instanceType] ? parseInt(this.state['number_'+type.instanceType]) : 0
					totalDecisionCount += this.state['number_'+type.instanceType] ? parseInt(this.state['number_'+type.instanceType]) : 0
				})
				this.setState({ ['seriesCount_'+child.series]: seriesCount, totalDecisionCount })				
			})
			this.setState({ ['familyCount_'+parent.description]: familyCount })
		})
	}

	getSelectedInstanceType = (decision) => {
		let condition = this.props.editRuleDetails.condition ? this.props.editRuleDetails.condition : {}
		// let allowedCondition = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedInstanceFamily ? this.props.editRuleDetails.condition.allowedInstanceFamily : []		
		let data = condition.filter(obj => obj.node_type === decision)
		let selectedInstanceFamily = data[0].instance_family
		let selectedInstanceSeries = data[0].instance_series
		let selectedInstanceType = data[0].instance_type
		let allowedInstance = data[0].allowed_instance
		let totalCount = data[0].total_count

		
		let filterServiceSizingArray = []
		if(allowedInstance.length) {
			this.state.serviceSizingArray.forEach(result => {
				if(allowedInstance.includes(result.description)) {
					filterServiceSizingArray.push(result)	
				}
			})
		}
		this.setState({ selectedInstanceFamily, selectedInstanceSeries, selectedInstanceType, allowedInstance, totalCount, filterServiceSizingArray }) 

		this.state.serviceSizingArray.forEach(parent => {
			if(selectedInstanceFamily.length && selectedInstanceFamily.filter(obj => obj.family === parent.description)) {
				this.setState({ ['family_'+parent.description]: true })
			} else {
				this.setState({ ['family_'+parent.description]: false })
			}
			parent.vm_series.forEach(child => {
				if(selectedInstanceSeries.length) {
					let objIndex = selectedInstanceSeries.findIndex((obj => obj.series === child.sesries ));
					if(objIndex >= 0) {
						this.setState({ ['series_'+child.series]: true })
					} else {
						this.setState({ ['series_'+child.series]: false })
					}
				} else {
					this.setState({ ['series_'+child.series]: false })
				}
				child.vm_types.forEach((type, index) => {
					if(selectedInstanceType.length) {

						let objIndex = selectedInstanceType.findIndex((obj => obj.type === type.instanceType ));
						if(objIndex >= 0) {
							this.setState({ 
								[type.instanceType]: true, 
								['number_'+type.instanceType]: selectedInstanceType[objIndex].count > 0 ? selectedInstanceType[objIndex].count : '' 
							}, 
								() => this.getCountArray()
							)
						} else {
							this.setState({ [type.instanceType]: false, ['number_'+type.instanceType]: '' })
						}
					} else {
						this.setState({ [type.instanceType]: false, ['number_'+type.instanceType]: '' })
					}
				})
			})
		})		
	}

	copyInstanceToOtherType = () => {
		let condition = this.props.editRuleDetails.condition ? this.props.editRuleDetails.condition : {}
		let allowedCondition = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedInstanceFamily ? 
		this.props.editRuleDetails.condition.allowedInstanceFamily : []		
		if(instanceModel.length) {
			instanceModel.forEach((item, index) => {
				let decisionRow = {}
				if(item.label !== this.state.decision && this.state.copytheSame) {
					decisionRow.node_type = item.label
					decisionRow.instance_family = this.state.selectedInstanceFamily
					decisionRow.instance_series = this.state.selectedInstanceSeries
					decisionRow.instance_type = this.state.selectedInstanceType
					decisionRow.allowed_instance = this.state.allowedInstance
					decisionRow.total_count = this.state.totalCount
					allowedCondition[index] = decisionRow
				}
			})
			condition['allowedInstanceFamily'] = allowedCondition
			this.props.setEditRuleDetails('condition', condition)

			//this.formPreivew(condition)
		}
	}


	formPreivew = (condition) => {
		let previewArray =  []
		condition.length && condition.forEach(item => {
			let array = []
			let previewRow = {}
			previewRow.label = item.node_type !== '' ? item.node_type +' '+ item.total_count : item.total_count
			let selectedInstanceType = item.instance_type
			if(selectedInstanceType && selectedInstanceType.length) {
				this.state.serviceSizingArray.forEach(parent => {	
					parent.vm_series.forEach(child => {
						child.vm_types.forEach(types => {
							let objIndex = selectedInstanceType.findIndex((obj => obj.type === types.instanceType ));
							if(objIndex >= 0) {
								let dataRow = {}
								dataRow.parent = parent.description
								dataRow.child = child.series
								dataRow.instance = types.instanceType
								dataRow.count = selectedInstanceType[objIndex].count
								array.push(dataRow)
							}
						})
					})
				})
			}
			if(array.length) {
				let parent = _.chain(array).groupBy('parent').map((value, key) => ({ label: key, sub: value })).value()
				let childArray = []
				parent.forEach(item => {
					let childRow = {}
					childRow.label = item.label
					childRow.value = _.chain(item.sub).groupBy('child').map((value, key) => ({ label: key, child: value })).value()
					childArray.push(childRow)
				})
				array = childArray
				previewRow.value = array
				previewArray.push(previewRow)
			}
		})
		let preview = {}
		preview['Condition'] = previewArray
		this.props.setEditRuleDetails('preview', preview)
	}

	clearSelectedInstance = () => {
		let condition = this.props.editRuleDetails.condition ? this.props.editRuleDetails.condition : {}
		let allowedCondition = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedInstanceFamily ? 
		this.props.editRuleDetails.condition.allowedInstanceFamily : []		
		if(instanceModel.length) {
			instanceModel.forEach(item => {
				if(item.node_type === this.state.decision) {
					let decisionRow = {}
					decisionRow.node_type = item.node_type
					decisionRow.instance_family = []
					decisionRow.instance_series = []
					decisionRow.instance_type = []
					decisionRow.allowed_instance = []
					decisionRow.total_count = 0
					allowedCondition.push(decisionRow)
				}
			})
			condition['allowedInstanceFamily'] = allowedCondition
			this.props.setEditRuleDetails('condition', condition)
		} else {
			let allowedCondition = []
			let decisionRow = {}
			decisionRow.node_type = this.state.decision
			decisionRow.instance_family = []
			decisionRow.instance_series = []
			decisionRow.instance_type = []
			decisionRow.allowed_instance = []
			decisionRow.total_count = 0
			allowedCondition.push(decisionRow)
			condition['allowedInstanceFamily'] = allowedCondition
			this.props.setEditRuleDetails('condition', condition)
		}
	}

	render() {
		return (
			<div>
				<div className={`row d-flex mb-2 mr-1 ${instanceModel.length ? '' : 'displayNone'} zapInputDark`}>
					<div className='col-1'>
						<label>Type</label>
						<Select
							placeholder={'Select'}
							/* menuIsOpen={true} */
							isSearchable={false}
							//onFocus={this.openProivderMenu}
							className='f13 p-0'
							value={({
								value: this.props.editRuleDetails.decision ? this.props.editRuleDetails.decision : 'Select',
								label: this.props.editRuleDetails.decision ? this.props.editRuleDetails.decision : <span className="placeholder">Select</span>
							})}
							options={instanceModel.length && instanceModel.map(item => ({
								value: item.value,
								label: item.label,
							}))}
							onChange={event => this.setState({ decision: event.value, totalDecisionCount: 0 },
								() => {
									this.props.setEditRuleDetails('decision', event.value)
									this.getSelectedInstanceType(event.value)
								}
							)}
						/>
					</div>
					<div className={`col-2 m-0 p-0 mb-2 form-check form-check-inline f13 align-self-end ${this.props.editRuleDetails && this.props.editRuleDetails.decision ? '' : 'displayNone'}`}>
						<input
							className='form-check-input'
							type='checkbox'
							checked={this.props.editRuleDetails.copytheSame ? true : false}
							onChange={e => this.setState({ copytheSame : this.state.copytheSame ? false : true },
								() => {
									this.copyInstanceToOtherType()
									this.props.setEditRuleDetails('copytheSame',this.state.copytheSame)
								}
							)}
						/>
						<label className='form-check-label' htmlFor='forCheckbox'>
							Copy the same to other
						</label>
					</div>
				</div>
				
				{this.state.instanceFamily && this.state.instanceFamily.length ? 
					<div className="d-flex justify-content-between">
						<div>
							<div className="d-flex">
								<h6 className="text-info">Allowed Instance Family</h6>
								{this.state.hasError && !this.state.allowedInstance.length ? (
									<small className='text-danger'>Please select the allowed instance family.</small>
								) : null}
							</div>
						
							<div className="d-flex">
								{this.state.instanceFamily.map((inFam, i) => {
									return (
										<div className="form-group form-check mr-3 mb-0">
											<input 
												type="checkbox" 
												className="form-check-input" 
												checked={ this.state.allowedInstance && this.state.allowedInstance.length && this.state.allowedInstance.includes(inFam) ? true : false }
												onChange={e => this.setAllowedInstanceFamily(inFam, i)}
											/>
											<label className="form-check-label">{inFam}</label>
										</div>
									)
								})}
							</div>
						</div>	
						<div className="align-self-end">
							<div className="btn-group">
								<button type="button" className={`btn btn-light`} onClick={() => this.setState({ showFilter: !this.state.showFilter })} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<i className="far fa-filter"></i>
								</button>
								<div className={`p-2 dropdown-menu dropdown-menu-right shadow minMaxWidth300 ${this.state.showFilter ? 'showSizingFilter' : ''}`}>
									<p className="mb-0 dropdown-item d-flex">
										<input
											className='form-check-input'
											type='checkbox'
											checked={ this.state.selectedInstanceFilter['metal'] === true ? true : false }
											onChange={e => this.setState({ 
												['instance_metal']: this.state.selectedInstanceFilter['metal'] === undefined ? true : this.state.selectedInstanceFilter['metal'] ? false : true }, 
												// () => this.selectInstanceFilter('metal')
											)}
										/>
										<label className="mb-0" for="check">Metal Instance</label>
									</p>
									<p className="mb-0 dropdown-item d-flex">
										<input
											className='form-check-input'
											type='checkbox'
											checked={ this.state.selectedInstanceFilter['nitro'] === true ? true : false }
											onChange={e => this.setState({ 
												['instance_nitro']: this.state.selectedInstanceFilter['nitro'] === undefined ? true : this.state.selectedInstanceFilter['nitro'] ? false : true }, 
												// () => this.selectInstanceFilter('nitro')
											)}
										/>
										<label className="mb-0" for="check">Nitro Instance</label>
									</p>
									<p className="mb-0 dropdown-item d-flex">
										<input
											className='form-check-input'
											type='checkbox'
											checked={ this.state.selectedInstanceFilter['intel'] === true ? true : false }
											onChange={e => this.setState({ 
												['instance_intel']: this.state.selectedInstanceFilter['intel'] === undefined ? true : this.state.selectedInstanceFilter['intel'] ? false : true }, 
												// () => this.selectInstanceFilter('intel')
											)}
										/>
										<label className="mb-0" for="check">Intel Processor</label>
									</p>
									<p className="mb-0 dropdown-item d-flex">
										<input
											className='form-check-input'
											type='checkbox'
											checked={ this.state.selectedInstanceFilter['aum'] === true ? true : false }
											onChange={e => this.setState({ 
												['instance_aum']: this.state.selectedInstanceFilter['aum'] === undefined ? true : this.state.selectedInstanceFilter['aum'] ? false : true }, 
												// () => this.selectInstanceFilter('aum')
											)}
										/>
										<label className="mb-0" for="check">AUM Processor</label>
									</p>
									<div className="dropdown-divider"></div>								
									<div className={`serviceSizingInputRange`}>
										{/* <label className="font-weight-bold f13 text-primary w-60 mb-0 mt-2 align-self-center">Pricing per hour</label> */}
										<label className="mb-2 ml-4" for="check">Pricing per hour</label>
										<InputRange
											maxValue={5}
											minValue={0}
											step={0.5}
											formatLabel={value => `${value}$`}
											value={this.state.priceRangeValue}
											onChange={value => this.setState({ priceRangeValue: value })}
											// onChangeComplete={value => this.priceRangeFunction(value)} 
										/>
									</div>
								</div>
							</div>
						</div>					
					</div>
				: 
					null
				}
				<div className={`botder-top pt-2 mt-1 ${this.state.filterServiceSizingArray && this.state.filterServiceSizingArray.length ? '' : 'displayNone'} `}>
					<div className="d-flex">
						<h6 className="text-info mb-0 align-self-center">Select instance types</h6>
						{this.state.selectedInstanceType.length ?
							<React.Fragment>
								<span className="mx-2 badge badge-light text-black align-self-center">{this.state.selectedInstanceType.length +' Selected'}</span>
								<a href="#" className="align-self-center"  onClick={e => this.clearSelectedInstance()}>Clear All</a>
							</React.Fragment>
						: null
						}
						{this.state.hasError && !this.state.selectedInstanceType.length ? (
							<small className='text-danger'>Select atleast one instance type.</small>
						) : null}
					</div>
					<div className='d-flex w-15 form-group mt-3 displayNone'>
						<label className="align-self-center m-0 mr-2">Total Allowed Instance</label>
						<Input
							type='text'
							className='inputTextbox w-40'
							placeholder=''
							min="1"
							onChange={e => {
								this.handleInstanceCount(onlyNumeric(e.target.value))
							}}
							value={this.state.totalCount && this.state.totalCount > 0 ? this.state.totalCount : ''}
						/>
					</div>
				</div>
					
				{this.state.filterServiceSizingArray && this.state.filterServiceSizingArray.length ? 
					this.state.filterServiceSizingArray.map((row, index) => {			
						return (
						<React.Fragment>
							<div className="d-flex my-3">
								<h5 className="mb-0 align-self-center text-info">{row.description}</h5>
								<div className="form-group form-check ml-3 mb-0 align-self-center">
									<input 
										type="checkbox" 
										className="form-check-input" 
										checked={ this.state['family_'+row.description] ? true : false }
										onChange={e => this.setState({ 
											['family_'+row.description]: this.state['family_'+row.description] ? false : true}, 
											() => this.handleParentChange('instance', index, row.description, '', '')
										)}
									/>
									<label className="form-check-label">Select all Instance</label>
								</div>
							</div>
							{row.vm_series && row.vm_series.map((seriesRow,srIndex) => {
								return(
								<div className="table-responsive rounded border mb-3 governanceSizing">
									<table className="table table-sm mb-0 text-white">
										<thead className="bg-dark3">
											<tr>
												<th>
													<div className="form-group form-check mb-0">
														<input 
															type="checkbox" 
															className="form-check-input"
															checked={this.state['series_'+seriesRow.series] ? true : false}
															onChange={e => this.setState({ 
																['series_'+seriesRow.series]: this.state['series_'+seriesRow.series] ? false : true}, 
																() => this.handleParentChange('series', index, row.description, srIndex, seriesRow.series)
															)}
														/>
														<label className="form-check-label">
															All
														</label>
													</div>
												</th>
												<th className="text-nowrap">Instance Type</th>
												<th className="wpx-110">Max Instance</th>
												<th>Pricing</th>
												<th>
													Infra Specification
													<span className='badge bg-info text-white ml-2 mr-2'>{row.description}: {seriesRow.series}</span>
													<span className='badge badge-light text-black'>Total Instance Count: {this.state['seriesCount_'+seriesRow.series] ? this.state['seriesCount_'+seriesRow.series] : 0} 
														{/* <i className="fa fa-times-circle"></i> */}
													</span>
												</th>
												<th className="w-15">Description</th>
											</tr>
										</thead>
										<tbody className="bg-dark">
											{seriesRow.vm_types && seriesRow.vm_types.map((types, typeIndex) => {
												return (
												<tr>
													<td>
														<input
															type='checkbox'
															min='1'
															className="form-check-input m-0 mt-n2 ml-2 align-self-center"
															onChange={e => this.handleMultiChange('checkbox', index, row.description, srIndex, seriesRow.series, types.instanceType, types)}
															checked={this.state[types.instanceType] === true ? true : false}
														/>
													</td>
													<td>
														<p className="mb-0"><b>{types.instanceType}</b></p>
													</td>
													<td className="zapInputDark">
														<Input
															type='text'
															className={`inputTextbox ${this.state[types.instanceType] ? '' : 'disabled disabledDarkBg'}`}
															placeholder={`${this.state[types.instanceType] ? 'No Limit' : ''}`}
															onChange={e => this.setState({['number_'+types.instanceType]: onlyNumeric(e.target.value) > 0 ? onlyNumeric(e.target.value) : '' }, () => this.handleMultiChange('textBox', index, row.description, srIndex, seriesRow.series, types.instanceType, types))}
															value={this.state['number_'+types.instanceType] && this.state['number_'+types.instanceType]}
														/>
													</td>
													<td>
														<h6 className="mb-0">$100.20</h6>
													</td>
													<td>
														<div className="container-fluid pl-md-0">
															<div className="row">
																{types.vCPU ?
																	<div className="col-md-3"><p className="mb-1"><b>VCPU:</b> {types.vCPU}</p></div>
																: null}
																{types.memory ?
																	<div className="col-md-2"><p className="mb-1"><b>Memory:</b> {types.memory}</p></div>
																: null}
																{types.ebsOptimized ?
																	<div className="col-md-2"><p className="mb-1"><b>EBS Optimized:</b> {types.ebsOptimized === 'Y' ? 'Yes' : 'No'}</p></div>
																: null}
																{types.storageType ?
																	<div className="col-md-3"><p className="mb-1"><b>Storage Type:</b> {types.storageType}</p></div>
																: null}
																{types.storageDetails ?
																	<div className="col-md-3"><p className="mb-1"><b>Storage Details:</b> {types.storageDetails}</p></div>
																: null}
																{types.networkingPerformance ?
																	<div className="col-md-4"><p className="mb-1"><b>NW Performance:</b> {parseInt(types.networkingPerformance)+ (types.enhancedNetworking === 'Y' ? '(Enhanced Networking)' : '')}</p></div>
																: null}
																{types.cpuProcessor ?
																	<div className="col-md-5"><p className="mb-1"><b>CPU Processor:</b> {types.cpuProcessor +' '+types.architecture}</p></div>
																: null}

																
															</div>
														</div>
													</td>
													<td>
														<p className="mb-0" id={`description-${index}-${srIndex}-${typeIndex}`}><b>{types.description && types.description !== "" ? stringLength(types.description, 100) : ""}</b></p>
														<UncontrolledTooltip placement='top' target={`description-${index}-${srIndex}-${typeIndex}`}>{types.description}</UncontrolledTooltip>		
													</td>
												</tr>
												)
											})}
										</tbody>
									</table>
								</div>
								)
							})}
						</React.Fragment>
						)
					})
				: !this.state.filterServiceSizingArray  ? 
					<div className='d-flex justify-content-center m-4'>
						<Spinner className='text-center' color='white' size='lg' />
					</div>
				:
					<div className='d-flex justify-content-center m-4'>
						<p>There are no log data on {this.props.editRuleDetails.resource_type} service. Please try adjusting your services in description tab.</p>
					</div>
				}
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ServiceSizing.propTypes = {
	setEditRuleDetails: PropTypes.func,
	condition: PropTypes.object,
	params: PropTypes.object,
	editRuleDetails: PropTypes.object,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('state',state)
	return {
		editRuleDetails: state.governance.editRuleDetails,
	}
}

export default connect(mapStateToProps, { setEditRuleDetails })(ServiceSizing)


// formPreviewArray = () => {
// 	let previewArray = []
// 	if(instanceModel.length) {
// 		{instanceModel.map(item => {
// 			let previewObject = {}
// 			if(this.props.editRuleDetails.condition[item.label]) {
// 				let previewRowArray = []
// 				this.state.serviceSizingArray.map(parent => {			
// 					let previewRow = {}
// 					if(this.props.editRuleDetails.condition[item.label+'_selectedInstance'].length) {							
// 						if(parent.description === this.props.editRuleDetails.condition[item.label+'_selectedInstance']) {
// 							alert(parent.description)
// 							previewRow.label = parent.description
// 							previewRow.subLabel = parent.vm_series.series
// 							previewRow.value = parent.vm_series.types
// 							previewRowArray.push(previewRow)
// 						}
// 					}
// 				})
// 				previewObject[item.label] = previewRowArray
// 			}
// 			previewArray.push(previewObject)
// 		})}
// 	}
// 	console.log('previewArray',previewArray)
// }