import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { listAllServices } from '../../../../actions/commonAction'
import CommonDropdown from '../../../common/CommonDropdown'

const includeOptions = [
	{ value: 'include', label: 'Include'},
	{ value: 'exclude', label: 'Exclude'}
];

class ServiceFilterSection extends React.Component {
    constructor(props) {
        super(props)
        console.log('serviceProps', props)
        this.state = {
            services: [],
            serviceResourceType: [],
            definedService: [],

            sectionDetails: this.props.sectionDetails,

            selectedService: [],
            selectedServiceType: 'include',
        };
    };

    componentDidMount = () => {
        
        let serviceResourceArray = []
        let serviceArray = []
        this.props.services &&
            this.props.services.length &&
            _.chain(this.props.services)
            .groupBy('resource_type')
            .map((value, key) => ({ label: key, sub: value }))
            .value()
            .forEach((service, i) => {
                let resourceRow = {}
                resourceRow.label = service.label
                resourceRow.value = service.label
                serviceResourceArray.push(resourceRow)
                //serviceResourceArray.push(service)
                service.sub.forEach((item, j) => {
                    let serviceRow = {}
                    serviceRow.label = item.service_name
                    serviceRow.value = item.service_name
                    serviceArray.push(serviceRow)
                    //serviceArray.push(item)
                })
            })
        
        this.setState({ services: this.props.services, serviceResourceType: serviceResourceArray, definedService: serviceArray })
	}

    // listAllServices = () => {
	// 	let params = {}
	// 	params.provider = this.state.selectedProvider
	// 	this.props.listAllServices(params, (promise, services) => {
	// 		if (promise) {
	// 			let serviceArray = []
    //             let serviceResourceArray = []
    //             console.log(services)
    //             if(services ) {
    //                 services.length &&
	// 				_.chain(services)
    //                 .groupBy('resource_type')
    //                 .map((value, key) => ({ label: key, sub: value }))
    //                 .value()
    //                 .map((service, i) => {
    //                     let resourceRow = {}
    //                     resourceRow.label = service.label
    //                     resourceRow.value = service.label
    //                     serviceResourceArray.push(resourceRow)
    //                     //serviceResourceArray.push(service)
	// 					service.sub.forEach((item, j) => {
    //                         let serviceRow = {}
    //                         serviceRow.label = item.service_name
    //                         serviceRow.value = item.service_name
    //                         serviceArray.push(serviceRow)
    //                         //serviceArray.push(item)
	// 					})
	// 				})
	// 			}
    //             this.setState({ services, serviceResourceType: serviceResourceArray,  definedService: serviceArray })
	// 		} else {
	// 			this.setState({ services: [] })
	// 		}
	// 	})
    // }

    selectedOptionFunction = (label, value) => {
        let elementExist = 0
        if(label === 'Service') {
            var totalArray = this.state.selectedService; 
            totalArray.forEach((row, index) => {
                if(row === value) {
                    elementExist = 1
                    //totalArray.splice(index, 1);
                }
            })
            if(elementExist === 0) {
                this.setState(prevState => ({
                    selectedService: [...prevState.selectedService, value]
                }))
            }
        } else if(label === 'Service_Type') {
            this.setState({ selectedServiceType: value })
        }
    }

    applyFilter = () => {
        
        let obj = {}
        obj['service'] = this.state.selectedService
        obj['type'] = this.state.selectedServiceType
        
        return this.props.data(obj)
    }

    closeFilter = () => {
        return this.props.data(false);
    }

    remove = (type, index) => {
        var totalArray = '' 
        if(type === 'Service') {
            totalArray = this.state.selectedService; 
            totalArray.splice(index, 1);
            this.setState({ selectedService : totalArray, selectedServiceType: 'include' });
        }
    }

    render() {
        return (
            <div className="card cardDropSection">
                <p className="text-white col-sm-12 f16 pb-0 mb-0">Lorem ipsum text industry since 1500s</p>

                <div className="card-body">
                    <div className="d-flex mb-2">
                        <div className="mr-3 w-100">
                            <CommonDropdown 
                                data = {this.selectedOptionFunction.bind(this, 'Service_Type')}
                                hideHeader = {false}
                                headerName = {'Include'}
                                dropOptions = {includeOptions}
                                displayMenu = {false}
                                isMultiSelect = {false}
                                isDefaultDropdown = {false}
                                styleTop={'top57'}
                                searchOption={false}
                                label={'&nbsp;'}
                            />
                        </div>
                        <div className="mr-3">
                            <CommonDropdown 
                                data = {this.selectedOptionFunction.bind(this, 'Service')}
                                hideHeader = {false}
                                headerName = {'Service'}
                                dropOptions = {this.state.serviceResourceType}
                                displayMenu = {false}
                                isMultiSelect = {false}
                                isDefaultDropdown = {false}
                                styleTop={'top57'}
                                searchOption={true}
                                label={'Service'}
                            />
                        </div>
                        <div className="mr-3 minWidth300 align-self-center">
                            {this.state.selectedService.length ? 
                                this.state.selectedService.map((item, index) => {
                                    return (
                                        index < 3 ?
                                            <span className='badge badge-primary custom-badge ml-2 text-white p-1'> {item}
                                                <i className='ml-1 fal fa-times cursorPointer'onClick={() => this.remove('Service', index)}></i>
                                            </span> 
                                        :
                                        null
                                    )       
                                })
                            :
                                null
                            }
                        </div>
                        <div className="mr-3 align-self-center">
                            {this.state.selectedService.length > 3 ?
                                <p className="text-white col-sm-12 f10 pb-0 mb-0">  {this.state.selectedService.length+' Selected '}</p>
                            :
                                null
                            }
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="col-sm-12 m-0 p-0 float-right text-right">
                        <span className="dropCancelBtn p-2 mr-2"
                            onClick={ () => this.closeFilter()}
                        >
                            Cancel
                        </span>
                        <span className="dropApplyBtn p-2" 
                            onClick={ () => this.applyFilter()}
                        >
                            Apply
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

/**
 * Type of the props used in the component
 */
ServiceFilterSection.propTypes = {
    listAllServices:PropTypes.func
}

const mapStateToProps = state => {
	return {
		services: state.filters.services,
	}
}

export default connect(mapStateToProps, { listAllServices })(ServiceFilterSection);

//export default connect(AccessedByFilterSection, null, {getIdentitiesList})

//export default AccessedByFilterSection;
