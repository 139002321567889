/*************************************************
 * Tvastar
 * @exports
 * @file CognitoService.js
 * @author Rajasekar // on 19/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import Amplify from 'aws-amplify'
import { Auth } from 'aws-amplify'
import store from 'store'
import {
	
	REGION,
	USER_POOL_ID,
	USER_POOL_WEB_CLIENT_ID,
	DOMAIN,

	SYNCHRONIZER_END_POINT,
	SYNCHRONIZER,

	DETECTORS_END_POINT,
	DETECTORS,

	USER_SERVICES_END_POINT,
	USER_SERVICES,

	COMMON_SERVICES_END_POINT,
	COMMON_SERVICES,

	GOVERNANCE_END_POINT,
	GOVERNANCE,

	GOVERNANCE_NEW_END_POINT,
	GOVERNANCE_NEW,

	MONITORING_END_POINT,
	MONITORING,

	BILLING_END_POINT,
	BILLING,

	PRICING_END_POINT,
	PRICING,

	SCORE_END_POINT,
	SCORE,
	
	AIOPS_END_POINT,
	AIOPS_SERVICE,

	COST_END_POINT,
	COST_SERVICE,

	AIOPS_DIAGNOSTICS_END_POINT,
	AIOPS_DIAGNOSTICS,

	AIOPS_SRE_END_POINT,
	AIOPS_SRE,

	AIOPS_AUTOMATION_END_POINT,
	AIOPS_AUTOMATION,
	
	REMEDIATION,
	REMEDIATION_END_POINT,

	PROFILE_END_POINT,
	PROFILE,

	TOPOLOGY_END_POINT,
	TOPOLOGY,

	MICROSERVICE_TOPOLOGY_END_POINT,
	MICROSERVICE_TOPOLOGY,

	TAGGING_END_POINT,
	TAGGING,

	CONFIGURATION_END_POINT,
	CONFIGURATION,
	
	SUPPRESSOR_END_POINT,
	SUPPRESSOR,	

	USER_INSIGHTS_END_POINT,
	USER_INSIGHTS,

	IAM_GOVERNANCE_END_POINT,
	IAM_GOVERNANCE,

	MAINTENANCE_END_POINT,
	MAINTENANCE,

	TAG_GROUPS_END_POINT,
	TAG_GROUPS,
	
	USER_SIGNUP_END_POINT,
	USER_SIGNUP,
	
	DLP_END_POINT,
	DLPENDPOINT,

	DLP_ENGINE_END_POINT,
	DLPENGINEENDPOINT,

	DLP_THREAT_END_POINT,
	DLPTHREATENDPOINT,

	DLP_POLICY_END_POINT,
	DLPPOLICYENDPOINT,

	KPI_SERVICES_END_POINT,
	KPI_SERVICES,

	HEALTH_STATUS_END_POINT,
	HEALTH_STATUS,

	IDEL_ASSETS_END_POINT,
	IDEL_ASSETS,

	MONITORING_SNAPSHOT_END_POINT,
	MONITORING_SNAPSHOT,

	ERRORENDPOINT,
	ERROR_END_POINT,
	
	SLOWQUERYENDPOINT,
	SLOW_QUERY_END_POINT,

	RECHABILITYENDPOINT,
	RECHABILITY_END_POINT,

	OBSERVABILITYANOMALYENDPOINT,
	OBSERVABILITY_ANOMALY_END_POINT,

	OBSERVABILITYEVENTSENDPOINT,
	OBSERVABILITY_EVENTS_END_POINT,

	OBSERVABILITYQUERYENDPOINT,
	OBSERVABILITY_QUERY_END_POINT,

	CAUSALCORRELATIONENDPOINT,
	CAUSAL_CORRELATION_END_POINT,

	OPERATIONALMETRICSENDPOINT,
	OPERATIONAL_METRICS_END_POINT,

	CUSTOMEROPERATIONSENDPOINT,
	CUSTOMER_OPERATIONS_END_POINT,

	ISSUEDUMP,
	ISSUE_DUMP_END_POINT,

	SCHEDULEENDPOINT,
	SCHEDULE_END_POINT


} from '../config'

// const DEV_AUTHORIZATION = 'eyJraWQiOiJsblB1UkVtQ2l0VHZRbUMxTFRCOG9rWElaTDZxTk5raTlkZ0pZdXFrb3RZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJjY2QyOGVlMS1mZDhkLTQ3MDEtODViMy05M2M3YWI0MTZlMjEiLCJhdWQiOiIyYzlncGtsdjdoa2k1ZmNpbHYzbnJrbGVnZCIsImV2ZW50X2lkIjoiMGEwOThiNDYtMTkwZi00Y2Y5LThjZWUtNjgwYTg5N2Q3MmQ5IiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2MzExNTg0ODQsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX0JhRUxaQ0haQSIsImNvZ25pdG86dXNlcm5hbWUiOiJjbG91ZG9zIiwiZXhwIjoxNjMxMjQ0ODg0LCJpYXQiOjE2MzExNTg0ODR9.UjzLNF6LwQ2zN3hxs6QfaA1c2tbLMM_CiemZFLkFVcfNaA0-FsW62ePxDLzYbrNlvJ7pa95mSaxITnBXB4RES9pLi0sv_2xEGIhVlLImtpKmUOKDI8M7YnkCczZWpdrk9rN4vsCd28oiE_zvQAob9W0QHMLkOOEWu-2Fp-ActNxmii3Jw40KLgZt2FBHihuKS53eOublS5Q0GqC8L5hGyS9GUearSrcEnGDu0oqbkB3kodspbvAAayVpoN_PvaS04wI1YtegGCO7wDGD87Bjlxe2y4Gk0iKIt1KLejku98Lq932LbLMddDI9be2_MPYElWJ6PRyxX1mw1Epe5GLvcQ'
 
class cognitoService {
	init() {
		Amplify.configure({
			Auth: {
				//process.env.REACT_APP_IDENTITY_POOL_ID,
				region: REGION, //process.env.REACT_APP_REGION,
				userPoolId: USER_POOL_ID, //process.env.REACT_APP_USER_POOL_ID,
				userPoolWebClientId: USER_POOL_WEB_CLIENT_ID, //process.env.REACT_APP_USER_POOL_WEBCLIENT_ID,
				mandatorySignIn: true,
				cookieStorage: {
					domain: DOMAIN,
					path: '/',
					expires: 365,
					secure: false,
				},
			},
			API: {
				endpoints: [					
					{
						name: SYNCHRONIZER,
						region: REGION,
						endpoint: SYNCHRONIZER_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						},
					}, //dev prod completed					
					{
						name: DETECTORS,
						region: REGION,
						endpoint: DETECTORS_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						},
					}, //dev prod completed					
					{
						name: USER_SERVICES,
						region: REGION,
						endpoint: USER_SERVICES_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						},
					}, //dev prod completed					
					{
						name: COMMON_SERVICES,
						region: REGION,
						endpoint: COMMON_SERVICES_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						},
					}, //dev prod completed					
					{
						name: GOVERNANCE,
						region: REGION,
						endpoint: GOVERNANCE_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						},
					}, //dev prod completed
					{
						name: GOVERNANCE_NEW,
						region: REGION,
						endpoint: GOVERNANCE_NEW_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						},
					}, //dev prod completed
					{
						name: MONITORING,
						region: REGION,
						endpoint: MONITORING_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						},
					}, //dev prod completed
					{
						name: BILLING,
						region: REGION,
						endpoint: BILLING_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						},
					}, //dev prod completed
					{
						name: PRICING,
						region: REGION,
						endpoint: PRICING_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					}, //dev prod completed								
					{
						name: SCORE,
						region: REGION,
						endpoint: SCORE_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						},
					}, //dev prod completed
					{
						name: AIOPS_SERVICE,
						region: REGION,
						endpoint: AIOPS_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					}, //dev prod completed		
					{
						name: COST_SERVICE,
						region: REGION,
						endpoint: COST_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					}, //dev prod completed								
					{
						name: AIOPS_DIAGNOSTICS,
						region: REGION,
						endpoint: AIOPS_DIAGNOSTICS_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					}, //dev prod completed				
					{
						name: AIOPS_SRE,
						region: REGION,
						endpoint: AIOPS_SRE_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					}, //dev prod completed
					{
						name: AIOPS_AUTOMATION,
						region: REGION,
						endpoint: AIOPS_AUTOMATION_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					}, //dev prod completed
					{
						name: REMEDIATION,
						region: REGION,
						endpoint: REMEDIATION_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					}, //dev prod complete
					{
						name: PROFILE,
						region: REGION,
						endpoint: PROFILE_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					}, //dev prod completed
					{
						name: TOPOLOGY,
						region: REGION,
						endpoint: TOPOLOGY_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					}, //dev prod completed
					{
						name: MICROSERVICE_TOPOLOGY,
						region: REGION,
						endpoint: MICROSERVICE_TOPOLOGY_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					}, //dev prod completed					
					{
						name: TAGGING,
						region: REGION,
						endpoint: TAGGING_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						},
					}, //dev prod completed
					{
						name: CONFIGURATION,
						region: REGION,
						endpoint: CONFIGURATION_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						},
					}, //dev prod completed					
					{
						name: SUPPRESSOR,
						region: REGION,
						endpoint: SUPPRESSOR_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						},
					}, //dev prod completed
					{
						name: USER_INSIGHTS,
						region: REGION,
						endpoint: USER_INSIGHTS_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						},
					}, //dev prod completed					
					{
						name: IAM_GOVERNANCE,
						region: REGION,
						endpoint: IAM_GOVERNANCE_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						},
					}, //dev prod completed
					{
						name: MAINTENANCE,
						region: REGION,
						endpoint: MAINTENANCE_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						},
					}, //dev prod completed  //not used					
					{
						name: TAG_GROUPS,
						region: REGION,
						endpoint: TAG_GROUPS_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						},
					}, //dev prod completed  //not used
					{
						name: USER_SIGNUP,
						region: REGION,
						endpoint: USER_SIGNUP_END_POINT
					}, //dev prod completed
					{
						name: DLPENDPOINT,
						region: REGION,
						endpoint: DLP_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					},
					{
						name: DLPENGINEENDPOINT,
						region: REGION,
						endpoint: DLP_ENGINE_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					},
					{
						name: DLPTHREATENDPOINT,
						region: REGION,
						endpoint: DLP_THREAT_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					},
					{
						name: DLPPOLICYENDPOINT,
						region: REGION,
						endpoint: DLP_POLICY_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					},
					{
						name: KPI_SERVICES,
						region: REGION,
						endpoint: KPI_SERVICES_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					}, //dev prod completed  //not used
					{
						name: HEALTH_STATUS,
						region: REGION,
						endpoint: HEALTH_STATUS_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					}, //dev prod completed  //not used
					{
						name: IDEL_ASSETS,
						region: REGION,
						endpoint: IDEL_ASSETS_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					}, //dev prod completed  //not used
					{
						name: MONITORING_SNAPSHOT,
						region: REGION,
						endpoint: MONITORING_SNAPSHOT_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					}, //dev prod completed  //not used
					{
						name: ERRORENDPOINT,
						region: REGION,
						endpoint: ERROR_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					},
					{
						name: SLOWQUERYENDPOINT,
						region: REGION,
						endpoint: SLOW_QUERY_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					},
					{
						name: RECHABILITYENDPOINT,
						region: REGION,
						endpoint: RECHABILITY_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					},
					{
						name: OBSERVABILITYANOMALYENDPOINT,
						region: REGION,
						endpoint: OBSERVABILITY_ANOMALY_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					},
					
					{
						name: OBSERVABILITYEVENTSENDPOINT,
						region: REGION,
						endpoint: OBSERVABILITY_EVENTS_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					},
					{
						name: OBSERVABILITYQUERYENDPOINT,
						region: REGION,
						endpoint: OBSERVABILITY_QUERY_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					},
					{
						name: CAUSALCORRELATIONENDPOINT,
						region: REGION,
						endpoint: CAUSAL_CORRELATION_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					},
					{
						name: OPERATIONALMETRICSENDPOINT,
						region: REGION,
						endpoint: OPERATIONAL_METRICS_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					},
					{
						name: CUSTOMEROPERATIONSENDPOINT,
						region: REGION,
						endpoint: CUSTOMER_OPERATIONS_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					},
					{
						name: ISSUEDUMP,
						region: REGION,
						endpoint: ISSUE_DUMP_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					},
					{
						name: SCHEDULEENDPOINT,
						region: REGION,
						endpoint: SCHEDULE_END_POINT,
						custom_header: async () => {
							return {
								Authorization: `${(await Auth.currentSession()).idToken.jwtToken}`,
							}
						}
					},
				],
			},
			Storage: {
				region: REGION,
				bucket: 'freshworks-plans',
			},
		})
	}

	isAuthenticated = async () => {
		try {
			const session = await Auth.currentSession()
			if (session) {
				return true
			}
		} catch (e) {
			return false
		}
		return false
	}

	getUserData = async () => {
		try {
			const username = await Auth.currentAuthenticatedUser()
			const result = {}
			result['role'] = 'admin'
			result['from'] = 'cognito'
			result['data'] = {
				displayName: username.username,
				settings: {},
			}
			return result
		} catch (e) {
			return e
		}
	}

	signIn = async (email, password) => {
		try {
			const result = await Auth.signIn(email, password)
			const username = await Auth.currentAuthenticatedUser()

			result['role'] = 'admin'
			result['from'] = 'cognito'
			result['data'] = {
				displayName: username.username,
				settings: {},
			}
			return result
		} catch (e) {
			return e
		}
	}

	logout = async () => {
		let username = ''
		let password = ''
		let checkbox = false
		
		if(localStorage.getItem('checkbox')) {
			if(JSON.parse(localStorage.getItem('username')) !== '' && JSON.parse(localStorage.getItem('checkbox'))) {
				username = localStorage.getItem('username')
				password = localStorage.getItem('password')
				checkbox = true
			}
		}

		store.clearAll() //clears all the cache and localstorage

		if(checkbox) {
			if(localStorage.getItem('username') !== '') {
				localStorage.setItem('username', username);
			}
			if(localStorage.getItem('password') !== '') {
				localStorage.setItem('password', password)
			}
			localStorage.setItem('checkbox', checkbox);
		}
		
		window.location.href = '/'
		await Auth.signOut({ global: true })
	}

	createUser = async data => {
		try {
			const result = await Auth.signUp(data.displayName, data.password, data.email)
			return result
		} catch (e) {
			return e
		}
	}
}

const instance = new cognitoService()

// Add axios request interceptor
// axios.interceptors.request.use(async function (config) {
//   const token = (await Auth.currentSession()).accessToken.jwtToken
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });

export default instance
