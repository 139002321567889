/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file MicroServicesRightSection.js
 * @author Prakash // on 20/03/2023
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { UncontrolledTooltip, Spinner } from 'reactstrap'
import { setMicroServicePropsDetails, getGraphSummary, getCoveredSummary, getCoveredTrendsSummary, getCoveredListsSummary } from '../../../actions/aiops/MicroServicesAction'

import { getAccountNameFromId, getRegionName, convertBytes, convertSeconds, countFormater, convertBits, momentConvertionUtcToLocalTime, momentConvertionLocalToUtcTime } from '../../../utils/utility'

import SearchWithHiddenInput from '../../common/SearchWithHiddenInput'
import MetricsTab from './MetricsTab'
import Chart from 'react-apexcharts'
import AnomalyTab from './AnomalyTab'
import ErrorsTab from './ErrorsTab'
import ChangesTab from './ChangesTab'
import IssuesTab from './IssuesTab'
import NotificationsTab from './NotificationsTab'
import AlertsTab from './AlertsTab'
import EventsTab from './EventsTab'

class MicroServicesRightSection extends Component {
    
    constructor(props) {
        super(props)
        this.scrollRef = React.createRef()
        this.state = {
            minMaxHeight: "700px", 
            selectedTab: "Summary"
            // showLoading: true
        }
    }

    componentDidMount = () => {
        this.getWindowSize()
    }

    componentDidUpdate = (prevProps) => {
        
        if((!prevProps.microservies_selected_node_edges && this.props.microservies_selected_node_edges) || !_.isEqual(this.props.microservies_selected_node_edges, prevProps.microservies_selected_node_edges)) {
            // this.onClickNode(this.props.nodeDetailsId) 
            // this.getAssetDetails()
            this.setState({ showDetails: false, showLoading: true })
            this.filterTableResult()
        }

        if((!prevProps.microSericesFilters && this.props.microSericesFilters) || !_.isEqual(this.props.microSericesFilters, prevProps.microSericesFilters)) {
            this.setState({
                start_time: this.props.microSericesFilters.start_time,
                end_time: this.props.microSericesFilters.end_time,
                selectedAccount: this.props.microSericesFilters.selectedAccount,
                selectedRegion: this.props.microSericesFilters.selectedRegion,
                selectedTags: this.props.microSericesFilters.selectedTags,
                // showLoading: true,
                // showDetails: false
            })
        }  

        if((!prevProps.observabilityMicroServicesList && this.props.observabilityMicroServicesList) || !_.isEqual(this.props.observabilityMicroServicesList, prevProps.observabilityMicroServicesList)) {
            this.setState({ 
                microServicesList: this.props.observabilityMicroServicesList,
                filteredListData: this.props.observabilityMicroServicesList,
                // filteredMicroServciesList: this.props.observabilityMicroServicesList,
                showLoading: true,
                showDetails: false,
                selectedTab: "Summary"
            },
                () => {
                    this.formListGraphDetails()
                    this.formatMicroServicesList()
                }
            )
        }       

        if(!_.isEqual(this.props.fullScreen, prevProps.fullScreen)) {
            this.setState({ 
                microServicesList: this.props.observabilityMicroServicesList ? this.props.observabilityMicroServicesList : [],
                filteredListData: this.props.observabilityMicroServicesList ? this.props.observabilityMicroServicesList : [],
                showFullScreen: true
                // filteredMicroServciesList: this.props.observabilityMicroServicesList,
                // showLoading: true
            },
                () => {
                    this.formListGraphDetails()
                    this.formatMicroServicesList()
                }
            )
        }

        // if(this.props.aiopsPropsDetails && this.props.aiopsPropsDetails.topology_selected_node_edges) {
        //     this.filterTableResult()
        // }
    }

    formatMicroServicesList = () => {
        let filteredMicroServciesList = this.state.filteredListData
        
        filteredMicroServciesList.forEach(row => {
            let itemsToShow = {}
            let i = 0
            if(row.alerts) {
                i++
                itemsToShow.Alerts = row.alerts
            }
            if(i < 3 && row.anomalies) {
                i++
                itemsToShow.Anomalies = row.anomalies
            }
            if(i < 3 && row.changes) {
                i++
                itemsToShow.Changes = row.changes
            }
            if(i < 3 && row.checks) {
                i++
                itemsToShow.Checks = row.checks
            }
            if(i < 3 && row.errors) {
                i++
                itemsToShow.Errors = row.errors
            }
            if(i < 3 && row.events) {
                i++
                itemsToShow.Events = row.events
            }
            if(i < 3 && row.issues) {
                i++
                itemsToShow.Issues = row.issues
            }
            if(i < 3 && row.notifications) {
                i++
                itemsToShow.Notifications = row.notifications
            }
            row.itemsToShow = itemsToShow
        })

        this.setState({ filteredMicroServciesList })
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 50
                
		let outerHeight = (window.outerHeight - browserBorder - headerHeight) + "px"

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }

    filterTableResult = () => {
        let selectedNode = this.props.microservies_selected_node_edges && this.props.microservies_selected_node_edges.selected_node_id
        let filteredMicroServciesList = selectedNode.length ? [] : this.state.microServicesList
        
        if(selectedNode.length && this.state.microServicesList && this.state.microServicesList.length) {
            this.state.microServicesList.forEach((row, i) => {
                if(selectedNode.includes(row.microservice_id)) {
                    filteredMicroServciesList.push(row)
                }
            })
        }

        this.setState({ filteredListData: filteredMicroServciesList, showLoading: false },
            () => this.formatMicroServicesList()    
        )
    }

    formListGraphDetails = () => {
        if(this.state.microServicesList) {
            this.state.microServicesList.forEach(graphDetails => {
                graphDetails && graphDetails.trends && graphDetails.trends.data && Object.entries(graphDetails.trends.data).forEach(([key, item], i) => {
                    let series = []
                    let labels = []                
                    let data = []

                    let displayValues = []
                    item.display.forEach(dkey => {
                        let dataRow = {}
                        dataRow.label = dkey
                        dataRow.value = item[dkey]
                        displayValues.push(dataRow)
                    })

                    graphDetails.trends.dates.forEach((lab, i) => {
                        if(this.props.xaxisFormat === 'datetime') {
                            labels.push(momentConvertionUtcToLocalTime(lab, 'DD MMM Y HH:mm'))
                        } else if(this.props.xaxisFormat === 'date') {
                            labels.push(momentConvertionUtcToLocalTime(lab, 'DD MMM Y'))
                        } else {
                            labels.push(lab)
                        }
                        
                        data.push(item.values[i] ? item.values[i] : 0)
                    })
                    let dataRow = {}
                    dataRow.name = ""
                    dataRow.data = data
                    series.push(dataRow)
    
                    let options = {
                        chart: {
                            height: 160,
                            type: 'line',
                            toolbar: {
                                show: false,
                            },
                            zoom: {
                                enabled: false,
                            },
                            sparkline: {
                                enabled: true
                            },                
                            stacked: false,
                        },
                        colors: ['#039BE5'],
                        dataLabels: {
                            enabled: false
                        },
                        stroke: {
                            curve: 'smooth',
                            width: 2,
                        },
                        xaxis: {
                            show: false,                
                            categories: labels,
                            labels: {
                                show: false,
                                style: {
                                    fontSize: '9px'
                                },
                            },
                            axisBorder: {
                                show: true,
                                // color:'#434959',
                                color: '#343947',
                                height: 2,
                                width: '100%',
                                offsetX: 0,
                                offsetY: 0
                            },
                            axisTicks: {
                                show: false
                            },
                            tooltip: {
                                enabled: false
                            },
                        },            
                        yaxis: {
                            show: false,
                            axisBorder: {
                                offsetX: -15,
                                // offsetY: 0
                            },
                            axisTicks: {
                                show: false
                            },
                            labels: {
                                show: false
                            },
                        },
                        tooltip: {
                            enabled: false,
                            custom: function({series, seriesIndex, dataPointIndex, w}) { 
                                let returnData = ''
                                returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+w.globals.categoryLabels[dataPointIndex]+'</div>'
                                if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                    Object.entries(w.globals.initialSeries).map(([key, value], index) => {
                                        returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+value.name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+value.data[dataPointIndex]+'</span></div></div></div>'
    
                                    })
                                }
    
                                returnData += '</div>'
                                return returnData
    
                                // let val  = series[seriesIndex][dataPointIndex];
                                // let labelName =  momentConvertionUtcToLocalTime(w.globals.categoryLabels[dataPointIndex], "HH:mm")
                                // let name = ""
                                // return '<div class="d-flex arrow_box"> '+ labelName +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  capitalizeFirstLetter(name) + '  '+ val + '</span> </div>'
                            },
                        },
                        grid: {
                            show: false
                        }
                    }
    
                    this.setState({ [key+"_series_"+graphDetails.microservice_id]: series, [key+"_options_"+graphDetails.microservice_id]: options, [key+"_display_"+graphDetails.microservice_id]: displayValues, showLoading: false })
                })
            })
        }
        
    }

    getGrapUnitValue = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytes(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit)
            } else if(unit === 'Count') {
                // val = val > 1 ? parseInt(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    getMicroServieDetails = (row) => {
        this.setState({ selectedMicroServies: row, showDetails: true, showSectionLoading: false })
    }

    getSectionData = () => {
        // this.getCoveredTrendsSummary()
        // this.getCoveredListsSummary()
    }

    getCoveredSummary = () => {
        let params = {}
        params.provider = "aws"
        params.account_id = this.state.selectedAccount
        if(this.state.selectedRegion && this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
            params.region = this.state.selectedRegion
        }
        if(this.state.selectedTags && this.state.selectedTags.length) {
            params.tags = this.state.selectedTags
        }
        params.microservice_id = this.state.selectedMicroServies && this.state.selectedMicroServies.microservice_id
        params.aggregate_by = "microservice"
        // params.start_time =  "2023-03-23 00:00:00"
        // params.end_time =  "2023-03-23 23:59:59"
        params.start_time = momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:00')
        params.end_time = momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:00')


        this.props.getCoveredSummary(params, (promise, response) => {
            if(promise) {
                let data = {
                    "next_token": null,
                    "previous_token": null,
                    "total": 1,
                    "size": 1,
                    "results": [
                        {
                            "microservice_id": "8849f63cc7a31dfa88e4bf38d837fed0",
                            "microservice_name": "MSC-1",
                            "provider": "aws",
                            "account_id": "222506310953",
                            "region": "us-east-2",
                            "trend": {
                                "alerts": {
                                    "data": [
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        1,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "events": {
                                    "data": [
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        1,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        1,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "checks": {
                                    "data": [
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        1,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "issues": {
                                    "data": [
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        1,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "notifications": {
                                    "data": [
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        1,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "changes": {
                                    "data": [
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        1,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "errors": {
                                    "data": [
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        1,
                                        1,
                                        2,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "anomalies": {
                                    "data": [
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        1,
                                        1,
                                        4,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0
                                    ]
                                }
                            },
                            "list": {
                                "alerts": [
                                    {
                                        "total_metrics": 61,
                                        "description": "Latency [Duration] has breached P90 (>=130.228ms)",
                                        "threshold": 130.228,
                                        "event_source": "SRE",
                                        "asset_id": "4361606d1533d2b5ed76bc061496a38b",
                                        "duration": "1h",
                                        "sre_statistic": "p90",
                                        "event_type": "Duration",
                                        "asset_name": "tclTransform",
                                        "provider": "aws",
                                        "comparison_operator": "GreaterThanThreshold",
                                        "case_id": [
                                            "0601-1538-9269"
                                        ],
                                        "threshold_percentage": 90,
                                        "state": "ALARM",
                                        "evaluation_metrics_end_time": "2022-01-06 15:55:00",
                                        "value": 133.655,
                                        "severity": "Critical",
                                        "period": 60,
                                        "statistic": "Average",
                                        "service_name": "lambda",
                                        "resource_type": "Lambda",
                                        "sre_metric": "latency",
                                        "unit": "Milliseconds",
                                        "account_id": "222506310953",
                                        "event_id": "a298807e-db6d-4051-acf8-ee39858d4e00",
                                        "event_name": "Lambda_Duration_p90_tclTransform",
                                        "evaluation_metrics_start_time": "2022-01-06 14:54:00",
                                        "region": "us-east-2",
                                        "event_time": "2022-03-24 08:55:38"
                                    }
                                ],
                                "events": [
                                    {
                                        "event_id": "a0cefa40-140d-4c18-9953-9dea10bea750",
                                        "event_source": "cloudtrail",
                                        "event_name": "TagResource",
                                        "event_time": "2023-03-24 08:05:59",
                                        "account_id": "222506310953",
                                        "region": "us-east-2",
                                        "provider": "aws",
                                        "assets": [
                                            {
                                                "provider": "aws",
                                                "service_name": "lambda",
                                                "resource_type": "Lambda",
                                                "asset_action": "modified",
                                                "asset_name": "MSC-1",
                                                "asset_id": "4361606d1533d2b5ed76bc061496a38b",
                                                "tags": [
                                                    {
                                                        "key": "microservice_name",
                                                        "value": "MSC-1"
                                                    },
                                                    {
                                                        "key": "environment",
                                                        "value": "stack200"
                                                    },
                                                    {
                                                        "key": "application",
                                                        "value": "stack200"
                                                    },
                                                    {
                                                        "key": "project",
                                                        "value": "stack200"
                                                    },
                                                    {
                                                        "key": "microservice_id",
                                                        "value": "8849f63cc7a31dfa88e4bf38d837fed0"
                                                    }
                                                ],
                                                "vpc_id": "vpc-0423d6c5a9327549b",
                                                "region": "us-east-2",
                                                "memory_size": 128,
                                                "code_size": 19176968,
                                                "timeout": 60,
                                                "asset_arn": "arn:aws:lambda:us-east-2:222506310953:function:MSC-1"
                                            }
                                        ],
                                        "user_type": "IAMUser",
                                        "user_name": "kaushik@tvastr.ai",
                                        "timestamp": "2023-03-24 08:38:40",
                                        "description": "Modifies the parameters of a cache parameter group.",
                                        "importance": "Medium",
                                        "lifecycle": [
                                            "modification",
                                            "attachment"
                                        ],
                                        "event_analysis": [
                                            {
                                                "impact_type": "Compliance",
                                                "impact_level": "affects"
                                            },
                                            {
                                                "impact_type": "Compliance",
                                                "impact_level": "improves"
                                            }
                                        ]
                                    },
                                    {
                                        "event_id": "cb1accab-7fbe-4029-8a44-1fc40f6d55dd",
                                        "event_source": "cloudtrail",
                                        "event_name": "UpdateFunctionConfiguration",
                                        "event_time": "2023-03-23 15:03:23",
                                        "account_id": "222506310953",
                                        "region": "us-east-2",
                                        "provider": "aws",
                                        "assets": [
                                            {
                                                "provider": "aws",
                                                "service_name": "lambda",
                                                "resource_type": "Lambda",
                                                "asset_action": "modified",
                                                "asset_name": "MSC-1",
                                                "asset_id": "4361606d1533d2b5ed76bc061496a38b",
                                                "tags": [
                                                    {
                                                        "key": "microservice_name",
                                                        "value": "MSC-1"
                                                    },
                                                    {
                                                        "key": "environment",
                                                        "value": "stack200"
                                                    },
                                                    {
                                                        "key": "application",
                                                        "value": "stack200"
                                                    },
                                                    {
                                                        "key": "project",
                                                        "value": "stack200"
                                                    },
                                                    {
                                                        "key": "microservice_id",
                                                        "value": "5f23ab99f67c82a61bbb62a2db36661b"
                                                    }
                                                ],
                                                "vpc_id": "vpc-0423d6c5a9327549b",
                                                "region": "us-east-2",
                                                "memory_size": 128,
                                                "code_size": 19176968,
                                                "timeout": 60,
                                                "asset_arn": "arn:aws:lambda:us-east-2:222506310953:function:MSC-1"
                                            }
                                        ],
                                        "user_type": "IAMUser",
                                        "user_name": "kaushik@tvastr.ai",
                                        "timestamp": "2023-03-23 15:37:51"
                                    }
                                ],
                                "checks": [
                                    {
                                        "status": "pass",
                                        "job_id": "LambdaMemoryAndTimeout",
                                        "category": [
                                            "Performance"
                                        ],
                                        "risk": "Low",
                                        "title": "Lambda memory and timeout",
                                        "description": "Lambda with timeout x mins and RAM x GB",
                                        "provider": "aws",
                                        "service_name": "lambda",
                                        "plan": 1,
                                        "weight": 1,
                                        "resource_type": "Lambda",
                                        "service_id": "AWSLAM005",
                                        "aws_well_architected": [
                                            "Reliability ",
                                            "Performance Efficiency"
                                        ],
                                        "service_group": [
                                            "Compute"
                                        ],
                                        "complexity": "8",
                                        "account_id": "222506310953",
                                        "region": "us-east-2",
                                        "tags": [
                                            {
                                                "key": "environment",
                                                "value": "stack200"
                                            },
                                            {
                                                "key": "application",
                                                "value": "stack200"
                                            },
                                            {
                                                "key": "project",
                                                "value": "stack200"
                                            }
                                        ],
                                        "resource_id": "MSC-1",
                                        "asset_id": "4361606d1533d2b5ed76bc061496a38b",
                                        "asset_time": "2023-03-24 08:25:26",
                                        "created_by": "arn:aws:iam::222506310953:user/kaushik@tvastr.ai",
                                        "created_at": "2023-02-16 08:22:00",
                                        "event_id": "3d3e9134-eef4-493b-8269-f0bf2a71f1d8",
                                        "event_name": "CreateFunction",
                                        "action_date": "2023-02-16 08:22:00",
                                        "trace_id": "b4af9c11-7af9-435e-a864-ff6f825a8dcb",
                                        "identifier_type": "event",
                                        "checksum": "4361606d1533d2b5ed76bc061496a38bAWSLAM005pass",
                                        "identifier_id": "4361606d1533d2b5ed76bc061496a38bAWSLAM005pass_1676536071944",
                                        "suppressed": false,
                                        "version": 1,
                                        "timestamp": "2023-02-16 08:27:51"
                                    }
                                ],
                                "issues": [
                                    {
                                        "event_id": "f1515d04-fcdf-d83f-8571-421298d767c0",
                                        "event_source": "aws.health",
                                        "event_name": "AWS Health Event",
                                        "account_id": "222506310953",
                                        "region": "us-east-2",
                                        "category": "issue",
                                        "event_time": "2023-03-24 08:19:43",
                                        "additional_details": {
                                            "eventTypeCode": "AWS_CLOUDWATCH_LOGS_INSIGHTS_INDEXING_DELAYS",
                                            "eventDescription": [
                                                {
                                                    "language": "en_US",
                                                    "latestDescription": "[1:19 PM PST] We are investigating increased indexing latency for CloudWatch Logs Insights in the us-east-2 Region.\n\n[2:31 PM PST] We can confirm elevated error rates and delays when running queries in CloudWatch Logs Insights in the US-EAST-2 Region. We are actively working to resolve the issue.\n\n[5:31 PM PST] We are starting to see recovery in put-to-get latency when running queries in CloudWatch Logs Insights in the US-EAST-2 Region. We are actively working to resolve the issue.\n\n[6:47 PM PST] We continue to see recovery in put-to-get latency when running queries in CloudWatch Logs Insights in the US-EAST-2 Region. Once the backlog is resolved, we expect that the put-to-get latency will fully recover."
                                                }
                                            ],
                                            "eventArn": "arn:aws:health:us-east-1::event/CLOUDWATCH/AWS_CLOUDWATCH_LOGS_INSIGHTS_INDEXING_DELAYS/AWS_CLOUDWATCH_LOGS_INSIGHTS_INDEXING_DELAYS_253de8e6-9b7b-525e-a5c6-c27b41e13b75",
                                            "start_time": "2021-11-09 21:19:43",
                                            "service": "CLOUDWATCH"
                                        },
                                        "provider": "aws",
                                        "raw_event_path": "cloudwatch_events/594091953541/us-east-1/aws.health/AWS Health Event/2021/11/09/21/f1515d04-fcdf-d83f-8571-421298d767c0.json",
                                        "importance": "High",
                                        "event_analysis": [
                                            {
                                                "impact_type": "DELAYS"
                                            }
                                        ]
                                    }
                                ],
                                "notifications": [
                                    {
                                        "event_id": "cfc39fc8-4f3a-1daa-e185-a257d7fc2cd4",
                                        "event_source": "aws.health",
                                        "event_name": "AWS Health Event",
                                        "account_id": "222506310953",
                                        "region": "us-east-2",
                                        "category": "accountNotification",
                                        "event_time": "2022-03-24 08:35:00",
                                        "additional_details": {
                                            "eventTypeCode": "AWS_RDS_OPERATIONAL_NOTIFICATION",
                                            "eventDescription": [
                                                {
                                                    "language": "en_US",
                                                    "latestDescription": "You are receiving this message because you have one or more Amazon Aurora MySQL clusters running a version of Aurora MySQL 1 (with MySQL 5.6 compatibility) in the US-EAST-2 Region.\n\nAmazon Aurora MySQL 1 (with MySQL 5.6 compatibility) will reach end of life on February 28, 2023. We are providing you with a 12 month notice so you have sufficient time to upgrade your database cluster(s). You can find additional information needed to plan your upgrade including a detailed timeline with milestones in our documentation [1].\n\nTo find out which clusters are affected by this deprecation notice, please refer to the user guide [2].\n\nShould you have any questions or concerns, the AWS Support Team is available via Premium Support [3] and on AWS re:Post [4].\n\n[1] https://docs.aws.amazon.com/AmazonRDS/latest/AuroraUserGuide/Aurora.MySQL56.EOL.html\n[2] https://docs.aws.amazon.com/AmazonRDS/latest/AuroraUserGuide/Aurora.MySQL56.EOL.html#find-cluster\n[3] https://aws.amazon.com/support\n[4] https://repost.aws/"
                                                }
                                            ],
                                            "eventArn": "arn:aws:health:us-east-2::event/RDS/AWS_RDS_OPERATIONAL_NOTIFICATION/AWS_RDS_OPERATIONAL_NOTIFICATION_cf852fd7023442c326ec9af9ebe5abb3a36ebb50613d37a7c16477d6f497c8cb",
                                            "start_time": "2022-02-22 01:35:00",
                                            "service": "RDS"
                                        },
                                        "assets": [
                                            {
                                                "asset_name": "222506310953",
                                                "service_name": "lambda-accountsettings",
                                                "resource_type": "Lambda",
                                                "asset_id": "4361606d1533d2b5ed76bc061496a38b",
                                                "asset_category": [
                                                    "Compute"
                                                ]
                                            }
                                        ],
                                        "provider": "aws",
                                        "raw_event_path": "cloudwatch_events/573921696245/us-east-2/aws.health/AWS Health Event/2022/02/22/01/cfc39fc8-4f3a-1daa-e185-a257d7fc2cd4.json",
                                        "importance": "Medium",
                                        "event_analysis": [
                                            {
                                                "impact_type": "OPERATIONAL_NOTIFICATION"
                                            }
                                        ]
                                    }
                                ],
                                "changes": [
                                    {
                                        "change_id": "2eefa74e-6ecf-49e6-a702-904b0b11dad7",
                                        "event_id": "9888f44f-c86c-42f7-ad7c-53bfe5ff7b30",
                                        "event_source": "config",
                                        "event_name": "UPDATE",
                                        "event_time": "2023-03-23 15:05:27",
                                        "account_id": "222506310953",
                                        "region": "us-east-2",
                                        "provider": "aws",
                                        "status": "OK",
                                        "additional_details": {
                                            "changes": [
                                                {
                                                    "action": "UPDATE",
                                                    "field": "tracingConfig.mode",
                                                    "description": "tracingConfig.mode was changed from Active to PassThrough",
                                                    "previous_value": "Active",
                                                    "current_value": "PassThrough",
                                                    "change_analysis": [
                                                        {
                                                            "category": "infra",
                                                            "impact": null,
                                                            "impact_details": null,
                                                            "previous_features": {},
                                                            "current_features": {}
                                                        }
                                                    ],
                                                    "importance": "Low",
                                                    "change_nature": [
                                                        "Configuration"
                                                    ]
                                                }
                                            ]
                                        },
                                        "assets": [
                                            {
                                                "asset_name": "MSC-1",
                                                "tags": [
                                                    {
                                                        "key": "microservice_name",
                                                        "value": "MSC-1"
                                                    },
                                                    {
                                                        "key": "environment",
                                                        "value": "stack200"
                                                    },
                                                    {
                                                        "key": "application",
                                                        "value": "stack200"
                                                    },
                                                    {
                                                        "key": "project",
                                                        "value": "stack200"
                                                    },
                                                    {
                                                        "key": "microservice_id",
                                                        "value": "5f23ab99f67c82a61bbb62a2db36661b"
                                                    }
                                                ],
                                                "service_name": "Function",
                                                "resource_type": "Lambda",
                                                "asset_arn": "arn:aws:lambda:us-east-2:222506310953:function:MSC-1",
                                                "asset_action": "modified"
                                            }
                                        ],
                                        "timestamp": "2023-03-23 15:37:51"
                                    }
                                ],
                                "errors": [
                                    {
                                        "event_id": "1-641c66eb-6d2045822678ae7e7d796f8c_6b833a5401defae8",
                                        "event_status": "RECEIVED",
                                        "event_source": "trace",
                                        "provider": "aws",
                                        "account_id": "222506310953",
                                        "region": "us-east-2",
                                        "qid": "Microservice-StatusCode-400",
                                        "http_status_code": 400,
                                        "description": "Gets status code errors from microservices",
                                        "impact": [
                                            "Affects_Access"
                                        ],
                                        "category": [
                                            "Performance"
                                        ],
                                        "type": "Error",
                                        "error_type_source": "ServerSideException",
                                        "severity": "Critical",
                                        "confidence": 99,
                                        "microservice_name": "MSC-1",
                                        "microservice_id": "8849f63cc7a31dfa88e4bf38d837fed0",
                                        "origin_microservice_id": "aa465f653e78bfabee5f8d67fbb5e9ae",
                                        "event_time": "2023-03-23 20:19:15"
                                    },
                                    {
                                        "event_id": "1-641c6766-0b645a624b091f3f1aaa6f6b_306f3548d794b5b0",
                                        "event_status": "RECEIVED",
                                        "event_source": "trace",
                                        "provider": "aws",
                                        "account_id": "222506310953",
                                        "region": "us-east-2",
                                        "qid": "Microservice-StatusCode-400",
                                        "http_status_code": 400,
                                        "description": "Gets status code errors from microservices",
                                        "impact": [
                                            "Affects_Access"
                                        ],
                                        "category": [
                                            "Performance"
                                        ],
                                        "type": "Error",
                                        "error_type_source": "ServerSideException",
                                        "severity": "Critical",
                                        "confidence": 99,
                                        "microservice_name": "MSC-1",
                                        "microservice_id": "8849f63cc7a31dfa88e4bf38d837fed0",
                                        "origin_microservice_id": "aa465f653e78bfabee5f8d67fbb5e9ae",
                                        "event_time": "2023-03-23 20:21:18"
                                    },
                                    {
                                        "event_id": "1-641c5b65-2ae7cb311c330b7027ab821f_6c2f93a6606a6dec",
                                        "event_status": "RECEIVED",
                                        "event_source": "trace",
                                        "provider": "aws",
                                        "account_id": "222506310953",
                                        "region": "us-east-2",
                                        "qid": "Microservice-StatusCode-504",
                                        "http_status_code": 504,
                                        "description": "Gets status code errors from microservices",
                                        "impact": [
                                            "Affects_Access"
                                        ],
                                        "category": [
                                            "Performance"
                                        ],
                                        "type": "Error",
                                        "error_type_source": "ServerSideException",
                                        "severity": "Critical",
                                        "confidence": 99,
                                        "microservice_name": "MSC-1",
                                        "microservice_id": "8849f63cc7a31dfa88e4bf38d837fed0",
                                        "origin_microservice_id": "aa465f653e78bfabee5f8d67fbb5e9ae",
                                        "event_time": "2023-03-23 19:30:05"
                                    },
                                    {
                                        "event_id": "1-641c506d-57b59db67d8cf1463243d6ca_7ecc4d6372f172a0",
                                        "event_status": "RECEIVED",
                                        "event_source": "trace",
                                        "provider": "aws",
                                        "account_id": "222506310953",
                                        "region": "us-east-2",
                                        "qid": "Microservice-StatusCode-504",
                                        "http_status_code": 504,
                                        "description": "Gets status code errors from microservices",
                                        "impact": [
                                            "Affects_Access"
                                        ],
                                        "category": [
                                            "Performance"
                                        ],
                                        "type": "Error",
                                        "error_type_source": "ServerSideException",
                                        "severity": "Critical",
                                        "confidence": 99,
                                        "microservice_name": "MSC-1",
                                        "microservice_id": "8849f63cc7a31dfa88e4bf38d837fed0",
                                        "origin_microservice_id": "aa465f653e78bfabee5f8d67fbb5e9ae",
                                        "event_time": "2023-03-23 18:43:17"
                                    }
                                ],
                                "anomalies": [
                                    {
                                        "provider": "aws",
                                        "account_id": "222506310953",
                                        "region": "us-east-2",
                                        "anomaly_type": "trace",
                                        "run_time": "2023-03-27 09:22:49",
                                        "anomaly_name": "Latency",
                                        "anomaly_time": "2023-03-23 20:22:29",
                                        "anomaly_value": 10.49400019645691,
                                        "anomaly_score": 99,
                                        "severity": "Critical",
                                        "product": [
                                            "Mobile",
                                            "Networking",
                                            "CDN"
                                        ],
                                        "category": [
                                            "Availability",
                                            "Performance"
                                        ],
                                        "title": "Anomalies by Microservice Latency Metric",
                                        "microservice_name": "MSC-1",
                                        "microservice_id": "8849f63cc7a31dfa88e4bf38d837fed0",
                                        "origin_microservice_id": "aa465f653e78bfabee5f8d67fbb5e9ae",
                                        "additional_details": {
                                            "metric_name": "Latency",
                                            "yhat_upper": 0.0,
                                            "yhat_lower": 0.0,
                                            "unit": "Seconds",
                                            "window_size": "2M"
                                        },
                                        "anomaly_message": "Latency metric of microservice MSC-1 reached an anomalous value of 10.49400019645691 Seconds (normal range: 0.0 to 0.0). This constitutes a Critical anomaly with a confidence score of 99%."
                                    },
                                    {
                                        "provider": "aws",
                                        "account_id": "222506310953",
                                        "region": "us-east-2",
                                        "anomaly_type": "trace",
                                        "run_time": "2023-03-27 09:22:49",
                                        "anomaly_name": "Latency",
                                        "anomaly_time": "2023-03-23 20:17:27",
                                        "anomaly_value": 10.549000024795532,
                                        "anomaly_score": 99,
                                        "severity": "Critical",
                                        "product": [
                                            "Mobile",
                                            "Networking",
                                            "CDN"
                                        ],
                                        "category": [
                                            "Availability",
                                            "Performance"
                                        ],
                                        "title": "Anomalies by Microservice Latency Metric",
                                        "microservice_name": "MSC-1",
                                        "microservice_id": "8849f63cc7a31dfa88e4bf38d837fed0",
                                        "origin_microservice_id": "aa465f653e78bfabee5f8d67fbb5e9ae",
                                        "additional_details": {
                                            "metric_name": "Latency",
                                            "yhat_upper": 0.0,
                                            "yhat_lower": 0.0,
                                            "unit": "Seconds",
                                            "window_size": "2M"
                                        },
                                        "anomaly_message": "Latency metric of microservice MSC-1 reached an anomalous value of 10.549000024795532 Seconds (normal range: 0.0 to 0.0). This constitutes a Critical anomaly with a confidence score of 99%."
                                    },
                                    {
                                        "provider": "aws",
                                        "account_id": "222506310953",
                                        "region": "us-east-2",
                                        "anomaly_type": "trace",
                                        "run_time": "2023-03-27 09:22:49",
                                        "anomaly_name": "Latency",
                                        "anomaly_time": "2023-03-23 20:18:41",
                                        "anomaly_value": 10.539000034332275,
                                        "anomaly_score": 99,
                                        "severity": "Critical",
                                        "product": [
                                            "Mobile",
                                            "Networking",
                                            "CDN"
                                        ],
                                        "category": [
                                            "Availability",
                                            "Performance"
                                        ],
                                        "title": "Anomalies by Microservice Latency Metric",
                                        "microservice_name": "MSC-1",
                                        "microservice_id": "8849f63cc7a31dfa88e4bf38d837fed0",
                                        "origin_microservice_id": "aa465f653e78bfabee5f8d67fbb5e9ae",
                                        "additional_details": {
                                            "metric_name": "Latency",
                                            "yhat_upper": 0.0,
                                            "yhat_lower": 0.0,
                                            "unit": "Seconds",
                                            "window_size": "2M"
                                        },
                                        "anomaly_message": "Latency metric of microservice MSC-1 reached an anomalous value of 10.539000034332275 Seconds (normal range: 0.0 to 0.0). This constitutes a Critical anomaly with a confidence score of 99%."
                                    },
                                    {
                                        "provider": "aws",
                                        "account_id": "222506310953",
                                        "region": "us-east-2",
                                        "anomaly_type": "trace",
                                        "run_time": "2023-03-27 09:22:49",
                                        "anomaly_name": "Latency",
                                        "anomaly_time": "2023-03-23 20:21:45",
                                        "anomaly_value": 10.513000011444092,
                                        "anomaly_score": 99,
                                        "severity": "Critical",
                                        "product": [
                                            "Mobile",
                                            "Networking",
                                            "CDN"
                                        ],
                                        "category": [
                                            "Availability",
                                            "Performance"
                                        ],
                                        "title": "Anomalies by Microservice Latency Metric",
                                        "microservice_name": "MSC-1",
                                        "microservice_id": "8849f63cc7a31dfa88e4bf38d837fed0",
                                        "origin_microservice_id": "aa465f653e78bfabee5f8d67fbb5e9ae",
                                        "additional_details": {
                                            "metric_name": "Latency",
                                            "yhat_upper": 0.0,
                                            "yhat_lower": 0.0,
                                            "unit": "Seconds",
                                            "window_size": "2M"
                                        },
                                        "anomaly_message": "Latency metric of microservice MSC-1 reached an anomalous value of 10.513000011444092 Seconds (normal range: 0.0 to 0.0). This constitutes a Critical anomaly with a confidence score of 99%."
                                    },
                                    {
                                        "provider": "aws",
                                        "account_id": "222506310953",
                                        "region": "us-east-2",
                                        "anomaly_type": "trace",
                                        "run_time": "2023-03-27 09:22:49",
                                        "anomaly_name": "Latency",
                                        "anomaly_time": "2023-03-23 19:30:05",
                                        "anomaly_value": 29.003999948501587,
                                        "anomaly_score": 99,
                                        "severity": "Critical",
                                        "product": [
                                            "Mobile",
                                            "Networking",
                                            "CDN"
                                        ],
                                        "category": [
                                            "Availability",
                                            "Performance"
                                        ],
                                        "title": "Anomalies by Microservice Latency Metric",
                                        "microservice_name": "MSC-1",
                                        "microservice_id": "8849f63cc7a31dfa88e4bf38d837fed0",
                                        "origin_microservice_id": "aa465f653e78bfabee5f8d67fbb5e9ae",
                                        "additional_details": {
                                            "metric_name": "Latency",
                                            "yhat_upper": 0.0,
                                            "yhat_lower": 0.0,
                                            "unit": "Seconds",
                                            "window_size": "2M"
                                        },
                                        "anomaly_message": "Latency metric of microservice MSC-1 reached an anomalous value of 29.003999948501587 Seconds (normal range: 0.0 to 0.0). This constitutes a Critical anomaly with a confidence score of 99%."
                                    },
                                    {
                                        "provider": "aws",
                                        "account_id": "222506310953",
                                        "region": "us-east-2",
                                        "anomaly_type": "trace",
                                        "run_time": "2023-03-27 09:22:49",
                                        "anomaly_name": "Latency",
                                        "anomaly_time": "2023-03-23 18:43:17",
                                        "anomaly_value": 29.005000114440918,
                                        "anomaly_score": 99,
                                        "severity": "Critical",
                                        "product": [
                                            "Mobile",
                                            "Networking",
                                            "CDN"
                                        ],
                                        "category": [
                                            "Availability",
                                            "Performance"
                                        ],
                                        "title": "Anomalies by Microservice Latency Metric",
                                        "microservice_name": "MSC-1",
                                        "microservice_id": "8849f63cc7a31dfa88e4bf38d837fed0",
                                        "origin_microservice_id": "aa465f653e78bfabee5f8d67fbb5e9ae",
                                        "additional_details": {
                                            "metric_name": "Latency",
                                            "yhat_upper": 0.0,
                                            "yhat_lower": 0.0,
                                            "unit": "Seconds",
                                            "window_size": "2M"
                                        },
                                        "anomaly_message": "Latency metric of microservice MSC-1 reached an anomalous value of 29.005000114440918 Seconds (normal range: 0.0 to 0.0). This constitutes a Critical anomaly with a confidence score of 99%."
                                    }
                                ]
                            }
                        }
                    ],
                    "dates": [
                        "2023-03-22 18:00:00",
                        "2023-03-22 19:00:00",
                        "2023-03-22 20:00:00",
                        "2023-03-22 21:00:00",
                        "2023-03-22 22:00:00",
                        "2023-03-22 23:00:00",
                        "2023-03-23 00:00:00",
                        "2023-03-23 01:00:00",
                        "2023-03-23 02:00:00",
                        "2023-03-23 03:00:00",
                        "2023-03-23 04:00:00",
                        "2023-03-23 05:00:00",
                        "2023-03-23 06:00:00",
                        "2023-03-23 07:00:00",
                        "2023-03-23 08:00:00",
                        "2023-03-23 09:00:00",
                        "2023-03-23 10:00:00",
                        "2023-03-23 11:00:00",
                        "2023-03-23 12:00:00",
                        "2023-03-23 13:00:00",
                        "2023-03-23 14:00:00",
                        "2023-03-23 15:00:00",
                        "2023-03-23 16:00:00",
                        "2023-03-23 17:00:00",
                        "2023-03-23 18:00:00",
                        "2023-03-23 19:00:00",
                        "2023-03-23 20:00:00",
                        "2023-03-23 21:00:00",
                        "2023-03-23 22:00:00",
                        "2023-03-23 23:00:00",
                        "2023-03-24 00:00:00",
                        "2023-03-24 01:00:00",
                        "2023-03-24 02:00:00",
                        "2023-03-24 03:00:00",
                        "2023-03-24 04:00:00",
                        "2023-03-24 05:00:00",
                        "2023-03-24 06:00:00",
                        "2023-03-24 07:00:00",
                        "2023-03-24 08:00:00",
                        "2023-03-24 09:00:00",
                        "2023-03-24 10:00:00",
                        "2023-03-24 11:00:00",
                        "2023-03-24 12:00:00",
                        "2023-03-24 13:00:00",
                        "2023-03-24 14:00:00",
                        "2023-03-24 15:00:00",
                        "2023-03-24 16:00:00",
                        "2023-03-24 17:00:00",
                        "2023-03-24 18:00:00"
                    ]
                }
                this.setState({ coveredSummary: data, showSectionLoading: false })
            } else{
                this.setState({ coveredSummary: {} })
            }
        })
    }

	render() {
		return (
            <div className="d-flex overflowYAuto"> 
                <div class="w-100">
                        {this.state.showDetails ?
                            this.state.showSectionLoading ?
                                <div className='d-flex justify-content-center m-4'>
                                    <Spinner className='text-center' color='white' size='lg' />
                                </div>                            
                            :
                                <React.Fragment>
                                <ul className={`f12 nav nav-tabs border-bottom bg-dark shadow-none rounded justify-content-between`} id="myTab" role="tablist">
                                    <span className={`d-flex `}>
                                        <li className="nav-item">
                                            <span className={`nav-link text-white cursorPointer ${this.state.selectedTab === 'Summary' ? ' active' : ''}`} onClick={() => this.setState({ selectedTab: 'Summary' })}>Summary</span>
                                        </li>
                                        <li className="nav-item">
                                            <span className={`nav-link text-white cursorPointer ${this.state.selectedTab === 'Metrics' ? ' active' : ''}`} onClick={() => this.setState({ selectedTab: 'Metrics', selectedAssetId: '', menuDisabled: true })}>Metrics</span>
                                        </li>
                                        <li className="nav-item">
                                            <span className={`nav-link text-white cursorPointer ${this.state.selectedTab === 'Alerts' ? ' active' : ''}`} onClick={() => this.setState({ selectedTab: 'Alerts', selectedAssetId: '', menuDisabled: true }, () => this.getSectionData("alerts"))}>Alerts</span>
                                        </li>
                                        <li className="nav-item">
                                            <span className={`nav-link text-white cursorPointer ${this.state.selectedTab === 'Errors' ? ' active' : ''}`} onClick={() => this.setState({ selectedTab: 'Errors', selectedAssetId: '', menuDisabled: true }, () => this.getSectionData("errors"))}>Errors</span>
                                        </li>
                                        <li className="nav-item">
                                            <span className={`nav-link text-white cursorPointer ${this.state.selectedTab === 'Anomalies' ? ' active' : ''}`} onClick={() => this.setState({ selectedTab: 'Anomalies', selectedAssetId: '', menuDisabled: true }, () => this.getSectionData("anomalies"))}>Anomalies</span>
                                        </li>
                                        <li className="nav-item">
                                            <span className={`nav-link text-white cursorPointer ${this.state.selectedTab === 'Issues' ? ' active' : ''}`} onClick={() => this.setState({ selectedTab: 'Issues', selectedAssetId: '', menuDisabled: true }, () => this.getSectionData("issues"))}>Issues</span>
                                        </li>
                                        <li className="nav-item">
                                            <span className={`nav-link text-white cursorPointer ${this.state.selectedTab === 'Changes' ? ' active' : ''}`} onClick={() => this.setState({ selectedTab: 'Changes', selectedAssetId: '', menuDisabled: true }, () => this.getSectionData("changes"))}>Changes</span>
                                        </li>
                                        <li className="nav-item">
                                            <span className={`nav-link text-white cursorPointer ${this.state.selectedTab === 'Notifications' ? ' active' : ''}`} onClick={() => this.setState({ selectedTab: 'Notifications', selectedAssetId: '', menuDisabled: true }, () => this.getSectionData("notifications"))}>Notifications</span>
                                        </li>
                                    </span>
                                </ul>
                                {this.state.selectedTab === "Summary" ?
                                    <React.Fragment>
                                    <div className="rounded bg-dark p-3 mt-2">
                                        {/* <p className="mb-1 f16 text-white">Asset Details</p> */}
                                        <div className="d-flex mb-1 border-bottom">
                                            <div className="py-1 w-50">
                                                <p className="b-block mb-0">Provider</p>
                                                <p className="mb-0 text-white">
                                                    {this.state.selectedMicroServies && this.state.selectedMicroServies.provider ? this.state.selectedMicroServies.provider.toUpperCase() : <span>&nbsp;</span>}
                                                    {this.state.selectedMicroServies && this.state.selectedMicroServies.account_id ? (' : ')+getAccountNameFromId(this.state.selectedMicroServies.account_id, this.props.accounts) : <span>&nbsp;</span>}
                                                    {this.state.selectedMicroServies && this.state.selectedMicroServies.region ?  (' : ')+getRegionName(this.state.selectedMicroServies.region, this.props.regions) : <span>&nbsp;</span>}
                                                </p>
                                            </div>
                                            <div className="py-1 w-50 pl-3">
                                                <p className="b-block mb-0">Micorservice Name:</p>
                                                <p className="mb-0 text-white">{this.state.selectedMicroServies && this.state.selectedMicroServies.microservice_name ? this.state.selectedMicroServies.microservice_name : <span>&nbsp;</span>}</p>
                                            </div>
                                        </div>
                                        <div className="d-flex mb-1 border-bottom">
                                            <div className="py-1 w-33">
                                                <p className="b-block mb-0">Error</p>
                                                <p className="mb-0 text-white">
                                                    {this.state.selectedMicroServies && this.state.selectedMicroServies.errors ?  this.state.selectedMicroServies.errors : "-"}
                                                </p>
                                            </div>
                                            <div className="py-1 w-33 pl-3">
                                                <p className="b-block mb-0">Anomaly:</p>
                                                <p className="mb-0 text-white">
                                                    {this.state.selectedMicroServies && this.state.selectedMicroServies.anomalies ?  this.state.selectedMicroServies.anomalies : "-"}
                                                </p>
                                            </div>
                                            <div className="py-1 w-33 pl-3">
                                                <p className="b-block mb-0">Latency:</p>
                                                <p className="mb-0 text-white">{this.state.selectedMicroServies && this.state.selectedMicroServies.latency ?  this.state.selectedMicroServies.latency : "-"}</p>
                                            </div>
                                        </div>
                                        <div className="d-flex mb-1 border-bottom">
                                            <div className="py-1 w-33">
                                                <p className="b-block mb-0">Changes</p>
                                                <p className="mb-0 text-white">
                                                    {this.state.selectedMicroServies && this.state.selectedMicroServies.changes ?  this.state.selectedMicroServies.changes : "-"}
                                                </p>
                                            </div>
                                            <div className="py-1 w-33 pl-3">
                                                <p className="b-block mb-0">Issues:</p>
                                                <p className="mb-0 text-white">
                                                    {this.state.selectedMicroServies && this.state.selectedMicroServies.issues ?  this.state.selectedMicroServies.issues : "-"}
                                                </p>
                                            </div>
                                            <div className="py-1 w-33 pl-3">
                                                <p className="b-block mb-0">Alerts:</p>
                                                <p className="mb-0 text-white">{this.state.selectedMicroServies && this.state.selectedMicroServies.alerts?  this.state.selectedMicroServies.alerts : "-"}</p>
                                            </div>
                                        </div>
                                        <div className="d-flex mb-1">
                                            <div className="py-1 w-33">
                                                <p className="b-block mb-0">Notifications</p>
                                                <p className="mb-0 text-white">
                                                    {this.state.selectedMicroServies && this.state.selectedMicroServies.notifications ?  this.state.selectedMicroServies.notifications : "-"}
                                                </p>
                                            </div>
                                            <div className="py-1 w-33 pl-3">
                                                <p className="b-block mb-0">Events:</p>
                                                <p className="mb-0 text-white">
                                                    {this.state.selectedMicroServies && this.state.selectedMicroServies.events ?  this.state.selectedMicroServies.events : "-"}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rounded bg-dark p-3 mt-2">
                                        <table className="table mb-0 tableLayout">
                                            <thead className="text-white">
                                                <tr style={{height: "55px"}}>
                                                    <th className={`text-center`} >Requests</th>
                                                    <th className={`text-center`} >Error&nbsp;Rate</th>
                                                    <th className={`text-center`} >Latency</th>
                                                    <th className={`text-center`} >Anomalies</th>
                                                </tr>
                                            </thead>
                                            <tbody className="text-primary-color">
                                                {this.state.filteredMicroServciesList && this.state.filteredMicroServciesList.length ? 
                                                    this.state.filteredMicroServciesList.filter(e => e.microservice_id === this.state.selectedMicroServies.microservice_id).map((row, index) => {
                                                        return(
                                                            <tr key={index} className={`${!index ? "" : "border-top"}`}>
                                                                <td className='px-1 py-1'>
                                                                    {this.state["requests_series_"+row.microservice_id] && this.state["requests_series_"+row.microservice_id].length ?
                                                                        <div className="">
                                                                            {this.state["requests_display_"+row.microservice_id] && this.state["requests_display_"+row.microservice_id].length ?
                                                                                <div className="d-flex w-100 pb-1 align-self-center f13">
                                                                                    {this.state["requests_display_"+row.microservice_id].map(dis => {
                                                                                    return(
                                                                                        <p className="mb-0 d-flex mr-1 text-primary-color f10">{dis.label} <span className="pl-1 text-primary-color">{dis.value}</span></p>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            : null}
                                                                            <div className="w-100">
                                                                                <Chart options={this.state["requests_options_"+row.microservice_id]} series={this.state["requests_series_"+row.microservice_id]} type="line" height={20} width={"100%"}/>
                                                                            </div>
                                                                        </div>
                                                                    : null}
                                                                </td>
                                                                <td className='px-1 py-1'>
                                                                    {this.state["errors_series_"+row.microservice_id] && this.state["errors_series_"+row.microservice_id].length ?
                                                                        <div className="">
                                                                            {this.state["errors_display_"+row.microservice_id] && this.state["errors_display_"+row.microservice_id].length ?
                                                                                <div className="d-flex w-100 pb-1 align-self-center f13">
                                                                                    {this.state["errors_display_"+row.microservice_id].map(dis => {
                                                                                    return(
                                                                                        <p className="mb-0 d-flex mr-1 text-primary-color f10">{dis.label} <span className="pl-1 text-primary-color">{dis.value}</span></p>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            : null}
                                                                            <div className="w-100">
                                                                                <Chart options={this.state["errors_options_"+row.microservice_id]} series={this.state["errors_series_"+row.microservice_id]} type="line" height={20} width={"100%"}/>
                                                                            </div>
                                                                        </div>
                                                                    : null}
                                                                </td>
                                                                <td className='px-1 py-1'>
                                                                    {this.state["latency_series_"+row.microservice_id] && this.state["latency_series_"+row.microservice_id].length ?
                                                                        <div className="">
                                                                            {this.state["latency_display_"+row.microservice_id] && this.state["latency_display_"+row.microservice_id].length ?
                                                                                <div className="d-flex w-100 pb-1 align-self-center f13">
                                                                                    {this.state["latency_display_"+row.microservice_id].map(dis => {
                                                                                    return(
                                                                                        <p className="d-flex mr-1 mb-0 text-primary-color f10">{dis.label} <span className="pl-1 text-primary-color">{dis.value}</span></p>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            : null}
                                                                            <div className="100">
                                                                                <Chart options={this.state["latency_options_"+row.microservice_id]} series={this.state["latency_series_"+row.microservice_id]} type="line" height={20} width={"100%"}/>
                                                                            </div>
                                                                        </div>
                                                                    : null}
                                                                </td>
                                                                <td className='px-1 py-1'>
                                                                    {this.state["anomalies_series_"+row.microservice_id] && this.state["anomalies_series_"+row.microservice_id].length ?
                                                                        <div className="">
                                                                        {this.state["anomalies_display_"+row.microservice_id] && this.state["anomalies_display_"+row.microservice_id].length ?
                                                                            <div className="d-flex w-100 pb-1 align-self-center f13">
                                                                                {this.state["anomalies_display_"+row.microservice_id].map(dis => {
                                                                                return(
                                                                                    <p className="d-flex mb-0 mr-1 text-primary-color f10">{dis.label} <span className="pl-1 text-primary-color">{dis.value}</span></p>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        : null}
                                                                            <div className="100">
                                                                                <Chart options={this.state["anomalies_options_"+row.microservice_id]} series={this.state["anomalies_series_"+row.microservice_id]} type="line" height={20} width={"100%"}/>
                                                                            </div>
                                                                        </div>
                                                                    : null}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                : 
                                                    <tr key={-1}>
                                                        <td colSpan={10} className="text-center">No Records</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    </React.Fragment>
                                : this.state.selectedTab === "Metrics" ?
                                    <MetricsTab
                                        brushStartTime={this.state.brushStartTime ? this.state.brushStartTime : ""}
                                        brushEndTime={this.state.brushEndTime ? this.state.brushEndTime : ""}
                                        details= {this.state.selectedMicroServies}
                                        height={115}
                                        backtoMicroServiceList={() => this.setState({ showDetails: !this.state.showDetails })}
                                        // selectedMetrics={(selectedMetrics) => 
                                        //     this.setState({ selectedMetrics: "" }, 
                                        //         () => this.setState({ selectedMetrics })
                                        //     )
                                        // }
                                    />
                                : this.state.selectedTab === "Alerts" ?
                                    <AlertsTab
                                        selectedMicroServies={this.state.selectedMicroServies}
                                        selectedAccount={this.state.selectedAccount}
                                        
                                    />
                                : this.state.selectedTab === "Errors" ?
                                    <ErrorsTab 
                                        selectedMicroServies={this.state.selectedMicroServies}
                                        selectedAccount={this.state.selectedAccount}
                                    />
                                : this.state.selectedTab === "Anomalies" ?
                                    <AnomalyTab 
                                        selectedMicroServies={this.state.selectedMicroServies}
                                        selectedAccount={this.state.selectedAccount}
                                    />
                                : this.state.selectedTab === "Changes" ?
                                    <ChangesTab
                                        selectedMicroServies={this.state.selectedMicroServies}
                                        selectedAccount={this.state.selectedAccount}
                                    />
                                : this.state.selectedTab === "Issues" ?
                                    <IssuesTab
                                        selectedMicroServies={this.state.selectedMicroServies}
                                        selectedAccount={this.state.selectedAccount}
                                    />
                                : this.state.selectedTab === "Notifications" ?
                                    <NotificationsTab
                                        selectedMicroServies={this.state.selectedMicroServies}
                                        selectedAccount={this.state.selectedAccount}
                                    />
                                : null}
                                </React.Fragment>
                            
                        :
                            this.state.showLoading ?
                                <div className='d-flex justify-content-center m-4'>
                                    <Spinner className='text-center' color='white' size='lg' />
                                </div>
                            :
                                <div className="" id="scrollTop">
                                    <div className="d-flex justify-content-between">
                                        <p className="mt-2 mb-1">Showing {this.state.microServicesList && this.state.microServicesList.length > this.state.endRecord ? this.state.endRecord : this.state.microServicesList && this.state.microServicesList.length} of total {this.state.microServicesList && this.state.microServicesList.length} microservices(s)</p>

                                        <span onClick={() => this.setState({ filteredMicroServciesList: this.state.microServicesList })} className="fa fa-redo-alt cursorPointer align-self-center mr-2"></span>
                                    </div>
                                    <div className="bg-dark3 p-3 rounded" style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
                                        <table className="table mb-0 tableLayout">
                                            <thead className="text-white">
                                                <tr style={{height: "55px"}}>
                                                    <th className="bg-dark3 px-0" style={{width:"16%"}}>
                                                        <SearchWithHiddenInput
                                                            ID={'alert_search'}
                                                            data={this.state.microServicesList ? this.state.microServicesList : this.state.alertsList}
                                                            applyLiteDarkTags={true}
                                                            searchClassName={'border-0'}
                                                            searchIconColor={'text-gray3'}
                                                            className="form-control-sm text-gray3 bg-muted border-0"
                                                            hideInputSection={true}
                                                            topClassName={'dark-search flexWrap-unset'}
                                                            filteredData={(filteredMicroServciesList, searchText) => {
                                                                
                                                                let totalPages = 1
                                                                if(filteredMicroServciesList && filteredMicroServciesList.length > this.state.perPage) {
                                                                    totalPages = Math.ceil(filteredMicroServciesList.length / this.state.perPage)
                                                                }
                                                                
                                                                this.setState({ filteredMicroServciesList, startRecord: 0, endRecord: filteredMicroServciesList && filteredMicroServciesList.length },
                                                                    () => {
                                                                        // this.getAssetIdForMetrics()
                                                                    }    
                                                                )
                                                            }}
                                                        />
                                                    </th>
                                                    <th className={`text-center ${this.state.showFullScreen ? "" : "width120"}`} >Requests</th>
                                                    <th className={`text-center ${this.state.showFullScreen ? "" : "width120"}`} >Error&nbsp;Rate</th>
                                                    <th className={`text-center ${this.state.showFullScreen ? "" : "width120"}`} >Latency</th>
                                                    <th className={`text-center ${this.state.showFullScreen ? "" : "width120"}`} >Anomalies</th>
                                                    {/* <th className="text-center">Alerts</th>
                                                    <th className="text-center">Anomalies</th>
                                                    <th className="text-center">Changes</th> 
                                                    <th className="text-center">Errors</th>*/}
                                                    <th className="text-center">&nbsp;</th>
                                                    {/* <th className="text-center">Events</th>*/}
                                                    {/* <th className="text-center">Notifications</th> */}
                                                </tr>
                                            </thead>
                                            <tbody className="text-primary-color">
                                                {this.state.filteredMicroServciesList && this.state.filteredMicroServciesList.length ? 
                                                    this.state.filteredMicroServciesList.map((row, index) => {
                                                        return(
                                                            <tr key={index} className={`${!index ? "" : "border-top"}`}>
                                                                <td className="align-middle bg-dark3 cursorPointer px-1 py-1" id={"asset_name_"+index} onClick={() => this.getMicroServieDetails(row)}>
                                                                    <div class="small hiddenEllipses text-white" style={{width:"100px"}}>{row.microservice_name}</div>
                                                                    {/* <div class="small hiddenEllipses" style={{width:"150px"}}>
                                                                        {row.provider ? row.provider.toUpperCase() : <span>&nbsp;</span>}
                                                                        {row.account_id ? (' : ')+getAccountNameFromId(row.account_id, this.props.accounts) : <span>&nbsp;</span>}
                                                                        {row.region ?  (' : ')+getRegionName(row.region, this.state.regions) : <span>&nbsp;</span>}
                                                                    </div> */}
                                                                    <UncontrolledTooltip placement='top' target={"asset_name_"+index}>
                                                                        <div className="">
                                                                            <p className="mb-1">{row.asset_name}</p>
                                                                            <p className="mb-1">
                                                                                {row.provider ? row.provider.toUpperCase() : <span>&nbsp;</span>}
                                                                                {row.account_id ? (' : ')+getAccountNameFromId(row.account_id, this.props.accounts) : <span>&nbsp;</span>}
                                                                                {row.region ?  (' : ')+getRegionName(row.region, this.state.regions) : <span>&nbsp;</span>}
                                                                            </p>
                                                                        </div>    
                                                                    </UncontrolledTooltip>
                                                                </td>
                                                                <td className='px-1 py-1'>
                                                                    {this.state["requests_series_"+row.microservice_id] && this.state["requests_series_"+row.microservice_id].length ?
                                                                        <div className="">
                                                                            {this.state["requests_display_"+row.microservice_id] && this.state["requests_display_"+row.microservice_id].length ?
                                                                                <div className="d-flex w-100 pb-1 align-self-center f13">
                                                                                    {this.state["requests_display_"+row.microservice_id].map(dis => {
                                                                                    return(
                                                                                        <p className="mb-0 d-flex mr-1 text-primary-color f10">{dis.label} <span className="pl-1 text-primary-color">{dis.value}</span></p>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            : null}
                                                                            <div className="w-100">
                                                                                <Chart options={this.state["requests_options_"+row.microservice_id]} series={this.state["requests_series_"+row.microservice_id]} type="line" height={20} width={"100%"}/>
                                                                            </div>
                                                                        </div>
                                                                    : null}
                                                                </td>
                                                                <td className='px-1 py-1'>
                                                                    {this.state["errors_series_"+row.microservice_id] && this.state["errors_series_"+row.microservice_id].length ?
                                                                        <div className="">
                                                                            {this.state["errors_display_"+row.microservice_id] && this.state["errors_display_"+row.microservice_id].length ?
                                                                                <div className="d-flex w-100 pb-1 align-self-center f13">
                                                                                    {this.state["errors_display_"+row.microservice_id].map(dis => {
                                                                                    return(
                                                                                        <p className="mb-0 d-flex mr-1 text-primary-color f10">{dis.label} <span className="pl-1 text-primary-color">{dis.value}</span></p>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            : null}
                                                                            <div className="w-100">
                                                                                <Chart options={this.state["errors_options_"+row.microservice_id]} series={this.state["errors_series_"+row.microservice_id]} type="line" height={20} width={"100%"}/>
                                                                            </div>
                                                                        </div>
                                                                    : null}
                                                                </td>
                                                                <td className='px-1 py-1'>
                                                                    {this.state["latency_series_"+row.microservice_id] && this.state["latency_series_"+row.microservice_id].length ?
                                                                        <div className="">
                                                                            {this.state["latency_display_"+row.microservice_id] && this.state["latency_display_"+row.microservice_id].length ?
                                                                                <div className="d-flex w-100 pb-1 align-self-center f13">
                                                                                    {this.state["latency_display_"+row.microservice_id].map(dis => {
                                                                                    return(
                                                                                        <p className="d-flex mr-1 mb-0 text-primary-color f10">{dis.label} <span className="pl-1 text-primary-color">{dis.value}</span></p>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            : null}
                                                                            <div className="100">
                                                                                <Chart options={this.state["latency_options_"+row.microservice_id]} series={this.state["latency_series_"+row.microservice_id]} type="line" height={20} width={"100%"}/>
                                                                            </div>
                                                                        </div>
                                                                    : null}
                                                                </td>
                                                                <td className='px-1 py-1'>
                                                                    {this.state["anomalies_series_"+row.microservice_id] && this.state["anomalies_series_"+row.microservice_id].length ?
                                                                        <div className="">
                                                                        {this.state["anomalies_display_"+row.microservice_id] && this.state["anomalies_display_"+row.microservice_id].length ?
                                                                            <div className="d-flex w-100 pb-1 align-self-center f13">
                                                                                {this.state["anomalies_display_"+row.microservice_id].map(dis => {
                                                                                return(
                                                                                    <p className="d-flex mb-0 mr-1 text-primary-color f10">{dis.label} <span className="pl-1 text-primary-color">{dis.value}</span></p>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        : null}
                                                                            <div className="100">
                                                                                <Chart options={this.state["anomalies_options_"+row.microservice_id]} series={this.state["anomalies_series_"+row.microservice_id]} type="line" height={20} width={"100%"}/>
                                                                            </div>
                                                                        </div>
                                                                    : null}
                                                                </td>
                                                                <td className="px-1 py-1">
                                                                    <div className="align-self-center f11">
                                                                    {row.itemsToShow && Object.entries(row.itemsToShow).map(([itmKey, itmValue]) => {
                                                                        return(
                                                                            <p className="d-flex mb-2 text-primary-color f10">{itmKey} <span className="pl-1 text-primary-color">{itmValue}</span></p>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </td>
                                                                {/* <td className="px-1 py-1 text-center">{row.alerts}</td>
                                                                <td className="px-1 py-1 text-center">{row.anomalies}</td>
                                                                <td className="px-1 py-1 text-center">{row.changes}</td>
                                                                <td className="px-1 py-1 text-center">{row.errors}</td>
                                                                <td className="px-1 py-1 text-center">{row.issues}</td> */}
                                                                {/* <td className="text-center">{row.checks}</td> */}
                                                                {/* <td className="text-center">{row.events}</td>
                                                                <td className="text-center">{row.notifications}</td> */}
                                                            </tr>
                                                        )
                                                    })
                                                : 
                                                    <tr key={-1}>
                                                        <td colSpan={10} className="text-center">No Records</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    {this.state.showLodeMore ?
                                        <div className="col-sm-12 text-center cursorPointer my-1 text-info" onClick={() => this.nextSetOfRecord()}> Load More<i className="fas fa-angle-double-down ml-1"></i></div>
                                    : null}
                                </div>
                        }
                </div>
            </div>
		)
	}
}
/**
 * Type of the props used in the component
 */
MicroServicesRightSection.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
	// console.log('state', state)
	return {
        accounts: state.filters.accounts,
        regions: state.filters.regions && state.filters.regions[0] && state.filters.regions[0].provider_regions ? state.filters.regions[0].provider_regions :[],
        microservies_selected_node_edges: state.observability.microServiesPropsDetails.microservies_selected_node_edges ? state.observability.microServiesPropsDetails.microservies_selected_node_edges : {},
        observabilityMicroServicesList: state.observability.microServiesPropsDetails.observabilityMicroServicesList ? state.observability.microServiesPropsDetails.observabilityMicroServicesList : [],
        microSericesFilters: state.observability.microServiesPropsDetails.microSericesFilters ? state.observability.microServiesPropsDetails.microSericesFilters : {},
        fullScreen: state.observability.microServiesPropsDetails.fullScreen ? state.observability.microServiesPropsDetails.fullScreen : {}
    }
}

export default connect(mapStateToProps, {
    setMicroServicePropsDetails,
    getGraphSummary,
    getCoveredSummary,
    getCoveredTrendsSummary,
    getCoveredListsSummary
})(withRouter(MicroServicesRightSection))