/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ResourcesAdvancedSearch.js
 * @author Prakash // on 30/01/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getSearchMetaResults, getResourceSearchMetaResults } from '../../../../actions/commonAction'
import { listAllDetectors } from '../../../../actions/detectors/detectorsAction'
import { connect } from 'react-redux'
import { Spinner } from 'reactstrap'

import { CUSTOM_DURATION_FILTER_OPTIONS } from '../../../../utils/constants'
// import { getDayFromSelectedDuration } from '../../../../utils/utility'

import { getTotalCount } from '../../../../actions/detectors/detectorsAction'
import _ from 'lodash'
import Search from '../../../common/SearchComponent'
import DurationFilterSection from './DurationFilterSection'
import IpAddressess from './IpAddressess'
import ResourcesInputSection from './ResourcesInputSection'
import ServicesInputSection from './ServicesInputSection'
import AccessedBySection from './AccessedBySection'
import TagsSection from './TagsSection'
import InstanceTypeInputSection from './InstanceTypeInputSection'
import { getAccountNameFromId, getRegionName, momentConvertionLocalToUtcTime } from '../../../../utils/utility'

const defaultDropdown = [ 'Accessed By', 'Account', 'Category', 'Duration', 'Events', 'IP Address', 'Networking', 'Region', 'Resources', 'Services', "Tags", "Instance Type" ];

class ResourcesAdvancedSearch extends Component {
	constructor(props) {
		super(props)
		this.advanceSearchRef = React.createRef()
		
		this.state = {
			mainDropdown: defaultDropdown,
			searchText: '',
			isLoading: false,
		}
	}
	
	componentDidMount = () => {
		this.structureServices()
	}

	structureServices = () => {
		let serviceResourceArray = []
		let serviceArray = []
		this.props.services &&
			this.props.services.length &&
			_.chain(this.props.services)
			.groupBy('resource_type')
			.map((value, key) => ({ label: key, sub: value }))
			.value()
			.forEach((service, i) => {
				let resourceRow = {}
				resourceRow.label = service.label
				resourceRow.value = service.label
				serviceResourceArray.push(resourceRow)
				//serviceResourceArray.push(service)
				service.sub.forEach((item, j) => {
					let serviceRow = {}
					serviceRow.label = item.service_name
					serviceRow.value = item.service_name
					serviceArray.push(serviceRow)
					//serviceArray.push(item)
				})
			})
		
		this.setState({ services: this.props.services, serviceResourceType: serviceResourceArray, definedService: serviceArray })
	}
	
	filterOnchange = () => {
		let filterData = _.orderBy(defaultDropdown, ['Asc'])
		if(this.state.searchText !== '') {
			filterData = filterData.filter(
				filter => 
				filter.toLocaleLowerCase().includes(this.state.searchText) ||
				filter.includes(this.state.searchText)
			)
		}
		this.setState({
			mainDropdown: filterData, 
			// showMainDropdownOption: this.state.searchText === '' ? false : true 
		})	
	}

	onSelectOptions = (value) => {
		this.setState({ selectedOption: value, showMainDropdownOption: false, searchText: "", mainDropdown: defaultDropdown },
			() => this.defineChildFilterVariables()
		)
	}

	defineChildFilterVariables = () => {
		let selectedPage = ""
		let inputComponent = []
		if(this.state.selectedOption === "Account") {
			let options = []
			this.props.accounts.forEach(row => {
				let dataRow = {
					label: row.account_name,
					value: row.account_id
				}
				options.push(dataRow)
			})
			let inputRow = {
				label: this.state.selectedOption,
				component: "multiselect",
				value_list: [],
				options,
				field: "account"
			}
			inputComponent.push(inputRow)
		} else if(this.state.selectedOption === "Region") {
			let options = []
			this.props.regions.forEach(row => {
				let dataRow = {
					label: row.name,
					value: row.region
				}
				options.push(dataRow)
			})
			let inputRow = {
				label: this.state.selectedOption,
				component: "multiselect",
				value_list: [],
				options,
				field: "region"
			}
			inputComponent.push(inputRow)
		} else if(this.state.selectedOption === "Category") {
			let options = []

			// let dataRow = {
			// 	label: "All",
			// 	value: "All"
			// }
			// options.push(dataRow)

			this.props.categoryCounts.forEach(row => {
				let dataRow = {}
				dataRow.label = row.category
				dataRow.value = row.category
				options.push(dataRow)
			})
			let inputRow = {
				label: this.state.selectedOption,
				component: "multiselect",
				value_list: [],
				options,
				field: "category"
			}
			inputComponent.push(inputRow)
		} else if(this.state.selectedOption === "Duration") {
			let options = []
			CUSTOM_DURATION_FILTER_OPTIONS.forEach(row => {
				let dataRow = {}
				dataRow.label = row.option
				dataRow.value = row.value
				options.push(dataRow)
			})
			let inputRow = {
				label: this.state.selectedOption,
				component: "Custom_Duration",
				value: "",
				options,
				field: "duration"
			}
			inputComponent.push(inputRow)
		} else if(this.state.selectedOption === "IP Address") {
			let options = [
				{label: "Include", value: "Include"},
				{label: "Exclude", value: "Exclude"}
			]

			let inputRow = {
				label: this.state.selectedOption,
				component: "select",
				options,
				field: "type",
				type: "value",
				step: 1,
				default: "Include"
			}

			inputComponent.push(inputRow)

			inputRow = {
				label: "Ip Address (Enter ip's with , seprated)",
				component: "multipleAddTextBox",
				field: "ids",
				type: "value_list",
				step: 1
			}

			inputComponent.push(inputRow)

			selectedPage = "selectTextList"
		} else if(this.state.selectedOption === "Events") { //EventSection
			let options = [
				{label: "Include", value: "Include"},
				{label: "Exclude", value: "Exclude"}
			]

			let inputRow = {
				label: this.state.selectedOption,
				component: "select",
				options,
				field: "type",
				type: "value",
				step: 1,
				default: "Include"
			}

			inputComponent.push(inputRow)

			inputRow = {
				label: "Events (Enter id's with , seprated)",
				component: "multipleAddTextBox",
				field: "ids",
				type: "value_list",
				step: 1
			}

			inputComponent.push(inputRow)

			selectedPage = "selectTextList"
		} else if(this.state.selectedOption === "Networking") {
			let options = [
				{label: "Include", value: "Include"},
				{label: "Exclude", value: "Exclude"}
			]

			let inputRow = {
				label: this.state.selectedOption,
				component: "select",
				options,
				field: "type",
				type: "value",
				step: 1,
				default: "Include"
			}

			inputComponent.push(inputRow)

			inputRow = {
				label: "Resource (Enter ids with , seprated)",
				component: "multipleAddTextBox",
				field: "ids",
				type: "value_list",
				step: 1
			}

			inputComponent.push(inputRow)

			selectedPage = "selectTextList"
		} else if(this.state.selectedOption === "Resources") {
			let options = [
				{label: "Include", value: "Include"},
				{label: "Exclude", value: "Exclude"}
			]

			let inputRow = {
				label: "",
				component: "select",
				options,
				field: "type",
				type: "value",
				default: "Include"
			}

			inputComponent.push(inputRow)

			inputRow = {
				label: this.state.selectedOption,
				component: "select",
				options: this.state.serviceResourceType,
				field: "resources",
				type: "value",
			}

			inputComponent.push(inputRow)

			inputRow = {
				label: "Resource (Enter ids with , seprated)",
				component: "multipleAddTextBox",
				field: "ids",
				type: "value_list",
				step: 1
			}

			inputComponent.push(inputRow)

			selectedPage = "Resources"
		} else if(this.state.selectedOption === "Services") {
			let options = [
				{label: "Include", value: "Include"},
				{label: "Exclude", value: "Exclude"}
			]

			let inputRow = {
				label: "",
				component: "select",
				options,
				field: "type",
				type: "value",
				default: "Include"
			}

			inputComponent.push(inputRow)

			inputRow = {
				label: this.state.selectedOption,
				component: "multiselect",
				options: this.state.serviceResourceType,
				field: "services",
				type: "value_list",
			}

			inputComponent.push(inputRow)

			selectedPage = "Services"
		} else if(this.state.selectedOption === "Accessed By") {
			selectedPage = "Accessed By"
		} else if(this.state.selectedOption === "Tags") {
			selectedPage = "Tags"
		} else if(this.state.selectedOption === "Instance Type") {
			selectedPage = "InstanceType"
		}

		this.setState({ inputComponent, selectedPage })
	}

	filterMultiselectiOptions = (inputs) => {
		console.log("this.state.multiSelectSearchText", this.state.multiSelectSearchText)
		let filterData = inputs.options
		console.log("filterData", )
		if(this.state.multiSelectSearchText !== '') {
			filterData = filterData.filter(filter => filter.includes(this.state.multiSelectSearchText))
		}

		this.setState({
			["multiselect_"+inputs.field]: filterData, 
			// showMainDropdownOption: this.state.searchText === '' ? false : true 
		})

	}

	updateMultiSelectedOptions = (row, inputs, mindex) => {
		let data = this.state.selectedFilters ? this.state.selectedFilters : []
		let selectedMultiOptions = this.state.selectedMultiOptions ? this.state.selectedMultiOptions : []

		let valueLabel = row
		if(inputs.label === "account_id") {
			valueLabel = getAccountNameFromId(row, this.props.accounts)
		} else if(inputs.label === "region") {
			valueLabel = getRegionName(row, this.props.regions)
		}
		if(this.state["checked_"+inputs.field+"_"+mindex]) {
			let dataRow = {
				label: inputs.label,
				value: row,
				userView: inputs.label+" "+ valueLabel
			}
			data.push(dataRow)
		} else {
			let otherFilters = data.filter(e => e.label !== inputs.label)
			console.log("inputs", inputs)
			console.log("row", row)
			console.log("data 0", data)
			data = data.filter(e => e.label === inputs.label && e.value !== row)
			

			data = [...otherFilters, ...data]
		}

		this.setState({ selectedFilters: data, selectedMultiOptions, selectedOption: "" })
	}

	updateSelectedFilter = (row, inputs, options, mindex) => {
		let data = this.state.selectedFilters ? this.state.selectedFilters : []

		let dataRow = {
			label: inputs.label,
			value: row,
			userView: inputs.label+" "+row
		}
		data.push(dataRow)

		this.setState({ selectedFilters: data, selectedOption: ""  })
	}
	
	selectedDurationFunction = (row) => {
		let data = this.state.selectedFilters ? this.state.selectedFilters : []
		if(row.selectedDuration === "custom") {
			let dataRow = {}
			dataRow.label = 'Duration'
			dataRow.start_time = row.from
			dataRow.end_tiem = row.to
			dataRow.userView = 'Duration from '+ row.from+' to '+row.to
			data.push(dataRow)
		} else {
			let dataRow = {}
			dataRow.label = 'Duration'
			dataRow.value = row.selectedDuration
			dataRow.userView = 'Duration '+row.selectedDuration
			data.push(dataRow)
		}

		this.setState({ selectedFilters: data, inputComponent: [], selectedOption: ""  })
	}

	selectedTextListDetails = (selectedOptions, result) => {
		let data = this.state.selectedFilters ? this.state.selectedFilters : []

		let filteredData = data 
		// if(selectedOptions === "Services") {
		filteredData = data.filter(e => e.label !== selectedOptions)
		// }
		if(result && result.length) {
			data = [...filteredData, ...result]
		}

		this.setState({ selectedFilters: data, selectedOption: "", selectedPage: "" })
	}

	removeFilterData = (row) => {
		console.log("row", row)
		console.log("this.state.selectedFilters", this.state.selectedFilters)
		let selectedFilters = this.state.selectedFilters.filter(e => e.label !== row.label && e.value !== row.value)
		console.log("selectedFilters", selectedFilters)
		this.setState({ selectedFilters })
	}
	
	handleClickOutside = (event) => {
		if (this.advanceSearchRef && this.advanceSearchRef.current && !this.advanceSearchRef.current.contains(event.target)) {
			this.setState({ showMainDropdownOption: false })
		}
    }

	onSearch = () => {
		console.log("this.state.selectedFilters", this.state.selectedFilters)
		if(this.state.selectedFilters && this.state.selectedFilters.length) {
			let params = {}
			if(this.state.selectedFilters.filter(e => e.label === "Account").length) {
				let data = []
				this.state.selectedFilters.filter(e => e.label === "Account").forEach(row => {
					data.push(row.value)
				})
				params.account_id = data
			}
			if(this.state.selectedFilters.filter(e => e.label === "Region").length) {
				let data = []
				this.state.selectedFilters.filter(e => e.label === "Region").forEach(row => {
					data.push(row.value)
				})
				params.region = data
			}
			if(this.state.selectedFilters.filter(e => e.label === "Category").length) {
				let data = []
				this.state.selectedFilters.filter(e => e.label === "Category").forEach(row => {
					data.push(row.value)
				})
				params.category = data
			}
			if(this.state.selectedFilters.filter(e => e.label === "Duration").length) {
				this.state.selectedFilters.filter(e => e.label === "Duration").forEach(row => {
					console.log(row)
					if(row.value) {
						params.duration = row.value
					} else {
						params.start_time = row.start_time
						params.end_time = row.end_time
					}
				})
			}
			if(this.state.selectedFilters.filter(e => e.label === "Events").length) {
				let groupData = _.groupBy(this.state.selectedFilters.filter(e => e.label === "Events"), 'type')

				let dataRow = {}
				Object.entries(groupData).forEach(([key, gValue]) => {
					let gData = []
					gValue.forEach(item => {
						gData.push(item.value)
					})
					dataRow[key] = gData
				})
				
				params.events = dataRow
			}
			if(this.state.selectedFilters.filter(e => e.label === "IP Address").length) {
				let groupData = _.groupBy(this.state.selectedFilters.filter(e => e.label === "IP Address"), 'type')

				let dataRow = {}
				Object.entries(groupData).forEach(([key, gValue]) => {
					let gData = []
					gValue.forEach(item => {
						gData.push(item.value)
					})
					dataRow[key] = gData
				})
				
				params.ip_ddress = dataRow
			}
			if(this.state.selectedFilters.filter(e => e.label === "Networking").length) {
				let groupData = _.groupBy(this.state.selectedFilters.filter(e => e.label === "Networking"), 'type')

				let dataRow = {}
				Object.entries(groupData).forEach(([key, gValue]) => {
					let gData = []
					gValue.forEach(item => {
						gData.push(item.value)
					})
					dataRow[key] = gData
				})
				
				params.networking = dataRow
			}
			if(this.state.selectedFilters.filter(e => e.label === "Resources").length) {
				let groupType = _.groupBy(this.state.selectedFilters.filter(e => e.label === "Resources"), 'type')
				let dataRow = {}
				Object.entries(groupType).forEach(([key, gValue]) => {
					
					let gData = []
					let groupResources = _.groupBy(gValue, 'resources')
					Object.entries(groupResources).forEach(([rKey, rValue]) => {
						let rData = []
						rValue.forEach(item => {
							rData.push(item.value)
						})
						let groupRow = {}
						groupRow[rKey] = rData

						gData.push(groupRow)
					})
					
					dataRow[key] = gData
				})
				params.resources = dataRow
			}
			if(this.state.selectedFilters.filter(e => e.label === "Services").length) {
				let groupData = _.groupBy(this.state.selectedFilters.filter(e => e.label === "Services"), 'type')

				let dataRow = {}
				Object.entries(groupData).forEach(([key, gValue]) => {
					let gData = []
					gValue.forEach(item => {
						gData.push(item.value)
					})
					dataRow[key] = gData
				})
				
				params.service = dataRow
			}
			if(this.state.selectedFilters.filter(e => e.label === "Tags").length) {
				let data = []
				this.state.selectedFilters.filter(e => e.label === "Tags").forEach(row => {
					let dataRow = {}
					dataRow.label = row.key
					dataRow.value = row.value
					data.push(dataRow)
				})				
				params.tags = data
			}
			if(this.state.selectedFilters.filter(e => e.label === "Instance Type").length) {

				let groupData = _.groupBy(this.state.selectedFilters.filter(e => e.label === "Instance Type"), 'service')

				let dataRow = {}
				Object.entries(groupData).forEach(([key, gValue]) => {
					let gData = []
					gValue.forEach(item => {
						let gRow = {}
						gRow.region = item.region
						gRow.instance_typ = item.value
						gData.push(gRow)
					})
					dataRow[key] = gData
				})
				
				params.instance_type = dataRow
			}
			if(this.state.selectedFilters.filter(e => e.label === "Accessed By").length) {
				let groupData = _.groupBy(this.state.selectedFilters, 'type')

				let dataRow = {}
				let rootAccount = false
				let crossAccount = false
				Object.entries(groupData).forEach(([key, gValue]) => {
					let gData = []
					gValue.forEach(item => {
						let gRow = {}
						if(item.access_cross_account) {
							crossAccount = true
						}
						if(item.access_root_account) {
							rootAccount = true
						}
						gData.push(item.value)
					})
					dataRow[key] = gData
				})
				dataRow.access_root_account = rootAccount
				dataRow.access_cross_account = crossAccount
				
				params.accessed_by = dataRow
			}
			

			console.log("params", params)
		}
	}

	onReset = () => {
		this.setState({ selectedFilters: [] })
	}

	render() {
		return (
			<div className='advanced-search' onClick={ (event) => { this.handleClickOutside(event) } }>
				<div className='search-content overflow-auto bg-dark'>
					<div className='d-flex bg-dark3 p-4 text-white'>
						<div className='flex-fill bd-highlight'>
							<h5>Advanced Search</h5>
							<p className='m-0'>
								Search Cloud resources database by using advanced and custom queries
							</p>
						</div>
						<div className='text-right flex-fill bd-highlight cursor-pointer'>
							<i className='far fa-times' onClick={() => this.props.onCloseModal()}/>
							<p className='m-0 pt-3 textInfo' 
								onClick={() => this.setState({ searchType : this.state.searchType === 'nlp' ? this.props.searchFrom === 'checks' ? 'checksSearchBuilder' : 'resourceSearchBuilder' : 'nlp' })} >
								{this.props.searchFrom === 'resources' ? '' : this.state.searchType === 'nlp' ? 'Use Search Builder' : 'Use NLP Search' }
							</p>
						</div>
					</div>
					<div className='container-fluid py-3'>
							{/* <div className='row'>
								<div className='col-12'>
									<p className='font-weight-bold mb-1'>Sit impedit voluptas ipsum eius sed aliquid.</p>
									<p className='mb-1 small'>
										Ea maxime magni. Et libero et voluptas et at sint iusto. Eos cumque quos.
									</p>
								</div>
							</div> */}
							<div className="position-relative d-inline-block w-100">
								{/* <label className="mb-1">Filters</label> */}
								{this.state.selectedFilters && this.state.selectedFilters.length ? 
									<div className="d-flex flex-wrap mt-3 mb-2">
										{this.state.selectedFilters.map(row => {
											return (
												<small className={`badge badge-secondary mr-1 mb-1`}>{row.userView}
												<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeFilterData(row) } ></i>
												</small>
												
											)
										})}
									</div>
								: null}
								
								<div className="comparisonTextboxDiv p-0 m-0" ref={this.advanceSearchRef}>
									{this.state.selectedOption && this.state.selectedOption !== "" ? 
										<p className="mb-2">{this.state.selectedOption}</p>
									: null}
									<div className="d-flex">
										<input type="text" className={`comparisonTextbox bg-muted text-white border-dark py-2 pl-3`}
											onChange={e => {this.setState({ searchText: e.target.value }, () => this.filterOnchange())}}
											// onKeyUp={this.onKeyUp}
											//value={this.state.searchText && this.state.selectedOption !== "" ? this.state.searchText : (this.state.selectedOption ? this.state.selectedOption +" : " : "")}
											value={this.state.searchText ? this.state.searchText : ""}
											/*{this.state.selectedDefaultOption === '' ? this.state.searchText : this.state.selectedDefaultOption +': ' } */
											onClick={ () => this.setState({ showMainDropdownOption: true, inputComponent: [], selectedPage: ""  }) }
											placeholder={"Filters"}
										/>
										
										<div className={`ml-3 d-flex align-self-center ${this.state.selectedFilters && this.state.selectedFilters.length ? '' : 'disabled'}`}>
											<div className="btn-group" role="group" aria-label="status-builder">
												<button type="button" className={`cursorPointer btn btn-secondary border-right`} onClick={()=> this.onSearch()}>Search</button>
												<button type="button" className={`cursorPointer btn btn-secondary border-left`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
											</div>
										</div>

									</div>
								</div>
								{this.state.showMainDropdownOption ? 
									<div className={`dropdownOptions topAuto mt-2 bg-muted border-muted position-unset w-25 border-radius-0`}>
										{this.state.mainDropdown && this.state.mainDropdown.map(row => {
											return(
												<button className="d-flex justify-content-between mb-3 bg-muted text-white cursorPointer" onClick={() => this.onSelectOptions(row)}>
													<p className="mb-0 text-left">{row}</p>
												</button>
											)
										})}
									</div>
								: null}
								
								{this.state.inputComponent && this.state.inputComponent.length === 1 ?
									this.state.inputComponent[0].component === "multiselect" ?
										<div className={`dropdownOptions topAuto mt-2 bg-muted border-muted position-unset w-25 border-radius-0`}>
											{/* <input type="text" className={`comparisonTextbox bg-muted text-white border-dark py-2 pl-3`}
												onChange={e => {this.setState({ multiSelectSearchText: e.target.value }, () => this.filterMultiselectiOptions(this.state.inputComponent[0]))}}
												value={this.state.multiSelectSearchText}
												placeholder={"Search"}
											/> */}
											{this.state.inputComponent[0].options && this.state.inputComponent[0].options.length > 8 ?
												<div className={`mb-1`}>
													<Search
														data={this.state.inputComponent[0].options ? this.state.inputComponent[0].options : []}
														filteredData={(filteredArray, searchText) => {
															this.setState({ ["multiselect_"+this.state.inputComponent[0].field]: searchText !== "" ? filteredArray : "undefined" })
														}}
													/>
												</div>
											: null}
											{(this.state["multiselect_"+this.state.inputComponent[0].field] ? this.state["multiselect_"+this.state.inputComponent[0].field] : this.state.inputComponent[0].options).map((row, mIndex) => {
												return(		
													<div className="d-flex my-2 bg-muted text-white cursorPointer" onClick={() => this.setState({ ["checked_"+this.state.inputComponent[0].field+"_"+mIndex]: !this.state["checked_"+this.state.inputComponent[0].field+"_"+mIndex] }, () => this.updateMultiSelectedOptions(row.value, this.state.inputComponent[0], mIndex))}>											
														<div className="ml-1 form-check">
															<input type="checkbox"
																// onChange={() => this.setState({ ["options"+row]: !this.state["options"+row] }, () => this.updateMultiSelectedOptions(row, this.state.filteredChildDropdownOptions))}
																className="form-check-input"
																checked={this.state["checked_"+this.state.inputComponent[0].field+"_"+mIndex]}
															/>
														</div>
														<p className="ml-1 mb-0 text-left">{row.label}</p>
													</div>
												)
											})}
										</div>
									: this.state.inputComponent[0].component === "select" ?
										<div className={`dropdownOptions topAuto mt-2 bg-muted border-muted position-unset w-25 border-radius-0`}>
											{this.state.inputComponent[0].options && this.state.inputComponent[0].options.map((row, sIndex) => {
												return(
													<button className="d-flex justify-content-between mb-3 bg-muted text-white cursorPointer" onClick={() => this.updateSelectedFilter(row.value, this.state.inputComponent[0], this.state.inputComponent[0].options, sIndex)}>
														<p className="mb-0 text-left">{row.label}</p>
													</button>
												)
											})}
										</div>
									: this.state.inputComponent[0].component === "Custom_Duration" ?										
										<DurationFilterSection
											inputComponent={this.state.inputComponent}
											selectedInput={(result) => this.selectedDurationFunction(result)}
										/>
									: null
								: this.state.selectedPage === "selectTextList" ?
									<IpAddressess
										inputComponent={this.state.inputComponent}
										selectedPage={this.state.selectedPage}
										selectedOption={this.state.selectedOption}
										selectedFilters={this.state.selectedFilters && this.state.selectedFilters.filter(e => e.label === this.state.selectedOption).length ? this.state.selectedFilters.filter(e => e.label === this.state.selectedOption) : []}
										selectedInput={(selectedOptions, result) => this.selectedTextListDetails(selectedOptions, result)}
									/>
								: this.state.selectedPage === "Resources" ?
									<ResourcesInputSection
										inputComponent={this.state.inputComponent}
										selectedPage={this.state.selectedPage}
										selectedOption={this.state.selectedOption}
										selectedFilters={this.state.selectedFilters && this.state.selectedFilters.filter(e => e.label === this.state.selectedOption).length ? this.state.selectedFilters.filter(e => e.label === this.state.selectedOption) : []}
										selectedInput={(selectedOptions, result) => this.selectedTextListDetails(selectedOptions, result)}
									/>
								: this.state.selectedPage === "Services" ?
									<ServicesInputSection
										inputComponent={this.state.inputComponent}
										selectedPage={this.state.selectedPage}
										selectedOption={this.state.selectedOption}
										selectedFilters={this.state.selectedFilters && this.state.selectedFilters.filter(e => e.label === this.state.selectedOption).length ? this.state.selectedFilters.filter(e => e.label === this.state.selectedOption) : []}
										selectedInput={(selectedOptions, result) => this.selectedTextListDetails(selectedOptions, result)}
									/>
								: this.state.selectedPage === "Accessed By" ?
									<AccessedBySection
										inputComponent={this.state.inputComponent}
										selectedProvider={this.props.selectedProvider}
										selectedAccount={this.props.selectedAccount}
										selectedPage={this.state.selectedPage}
										selectedOption={this.state.selectedOption}selectedFilters={this.state.selectedFilters && this.state.selectedFilters.filter(e => e.label === this.state.selectedOption).length ? this.state.selectedFilters.filter(e => e.label === this.state.selectedOption) : []}
										selectedInput={(selectedOptions, result) => this.selectedTextListDetails(selectedOptions, result)}
									/>
								: this.state.selectedPage === "Tags" ?
									<TagsSection
										inputComponent={this.state.inputComponent}
										selectedPage={this.state.selectedPage}
										selectedOption={this.state.selectedOption}selectedFilters={this.state.selectedFilters && this.state.selectedFilters.filter(e => e.label === this.state.selectedOption).length ? this.state.selectedFilters.filter(e => e.label === this.state.selectedOption) : []}
										selectedInput={(selectedOptions, result) => this.selectedTextListDetails(selectedOptions, result)}
									/>
								:this.state.selectedPage === "InstanceType" ?
									<InstanceTypeInputSection
										inputComponent={this.state.inputComponent}
										selectedPage={this.state.selectedPage}
										selectedOption={this.state.selectedOption}selectedFilters={this.state.selectedFilters && this.state.selectedFilters.filter(e => e.label === this.state.selectedOption).length ? this.state.selectedFilters.filter(e => e.label === this.state.selectedOption) : []}
										selectedInput={(selectedOptions, result) => this.selectedTextListDetails(selectedOptions, result)}
									/>
								: null}
							</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ResourcesAdvancedSearch.propTypes = {
	onCloseModal: PropTypes.func,
	listAllDetectors: PropTypes.func,
	getFilteredList: PropTypes.func,
	getSearchBuilderList: PropTypes.func,
	getSearchMetaResults: PropTypes.func,
	getResourceSearchMetaResults: PropTypes.func,
	getTotalCount: PropTypes.func,
	searchResults: PropTypes.array,
	resetForm: PropTypes.number,
}



/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	console.log('state', state)
	return {
		searchResults: state.filters.searchMetaList,
		providers: state.filters.providers,
		services: state.filters.services,
		regions: state.filters.regions.length ? state.filters.regions[0].provider_regions : [],
		accounts: state.filters.accounts,
	}
}

export default connect(mapStateToProps, {
	getSearchMetaResults,
	getResourceSearchMetaResults,
	getTotalCount,
	listAllDetectors,
})(ResourcesAdvancedSearch)
