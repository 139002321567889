/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ManagePolicies.js
 * @author Prakash // on 09/12/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { 
	Spinner
} from 'reactstrap'
import { listAllProviders, listAllAccounts, listAllRegions, getAllTagsKeys, getAllTagsValues } from '../../../actions/commonAction'
import { listAssets } from '../../../actions/detectors/detectorsAction'
import { setNewInitialRuleDetails, getGovMetaData, listGovernancePoliciesNew, getPolicyDetails } from '../../../actions/governance/newGovernanceAction'

import { HOURLY_DURATION } from '../../../utils/constants'
import { AppWrapper } from '../../common/AppWrapper'
import _, { forEach } from 'lodash'
import { CSVLink } from "react-csv";

import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'
import Search from '../../common/SearchComponent'
import { URL_PATH } from '../../../config/urlPath'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

import { capitalizeAllLetter, capitalizeFirstLetter, currentLocaltime, getAccountNameFromId, getRegionName, capitalizeTheFirstLetterOfEachWord } from '../../../utils/utility'
import FilterSidePanel from './FilterSidePanel'
import ViewSidePanel from './ViewSidePanel'

class ManagePolicies extends Component {
	constructor(props) {
		super(props)
        this.accountRef = React.createRef()
        this.regionRef = React.createRef()
		this.advanceSearchRef = React.createRef()

		this.state = {
			queryLoading: true,
			showLoading: false,
			selectedGovernanceType: "All",
			selectedProvider: "",

			selectedAccount: "",
            isAccountOpen: false,

            selectedRegion: [],
            isRegionOpen: false,

			filteredArray: [],

			governanceTypesMasterData: [
				{label: "All", value: "All"},
				{label: "Access Managment Policy", value: "access"},
				{label: "Global Policy", value: "global"},
				{label: "Infrastructure Policy", value: "infra"},
				{label: "OPA Policy", value: "opa"},
				{label: "Kubernetes(Gatekeeper) Policy", value: "opa_kubernetes"}
			],
			viewOptions: [
				{label: "Policy", value: "policy_wise"},
				{label: "Policy Rule", value: "rule_wise"}
			],
			policyTypes: [
				{label: "Configured", value: "Configured"},
				{label: "Not Configured", value: "Not Configured"},
				{label: "All Rules", value: "all_rules"}
			],
			selectedViewOption: "policy_wise",
			selectedView: "policy_wise",
			selectedPolicyType: "Configured",
		}
	}

	componentDidMount = () => {
		if(!this.props.providers || !this.props.providers.length) {
			this.props.listAllProviders(promise => {
				if (promise) {
					let providers = this.props.providers
					this.setState({ providers, showLoading: true, selectedProvider: providers[0].provider_name },
						() => this.filterGovernance("provider")
					)
				} else {
					this.filterGovernance()
				}
			})
		} else {
			let providers = this.props.providers.filter(arr => arr.provider_name !== "All")
			this.setState({ providers, showLoading: true, selectedProvider: providers[0].provider_name },
				() => this.filterGovernance("provider")
			)
		}		
	}

	/**
	 * Filter identifiers based on the filter selection
	 */
	filterGovernance = from => {
		let params = {}
		if (this.state.selectedGovernanceType !== "opa" && this.state.selectedProvider) {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		
		if (from === "provider") {
			this.props.listAllAccounts(params, (promise, accounts) => {
				if (promise) {
					// if(accounts.length) {
					// 	let accRow = {}
					// 	accRow.account_id = "All"
					// 	accRow.account_name = "All"
					// 	accRow.date = null
					// 	accRow.disabled = false
					// 	accRow.provider = "All"
					// 	accounts.unshift(accRow);
					// }
					this.setState({ accounts })
				}
			})
			
			this.props.listAllRegions(params, (promise, regions) => {
				if (promise) {
					let providerRegions = regions && regions[0] && regions[0].provider_regions
					if(providerRegions) {
						let provider_regions_row = {}
						provider_regions_row.availability_zones = []
						provider_regions_row.city = "All"
						provider_regions_row.country = "All"
						provider_regions_row.geography = "All"
						provider_regions_row.name = "All"
						provider_regions_row.region = "All"
						provider_regions_row.region_id = "All"
						provider_regions_row.state = "All"
						providerRegions.unshift(provider_regions_row);
					}
					this.setState({ regions: providerRegions })
				} else {
					this.setState({ regions: [] })
				}
			})
		}

		this.getGovMetaData()
		
		this.setState({ showLoading: true, filteredArray: [] },
			() => this.listGovernancePoliciesNew()
		)
	}

	listGovernancePoliciesNew = () => {
		let params = {
			"enabled": true,
		}	

		if(this.state.selectedGovernanceType && this.state.selectedGovernanceType !== "All") {
			params.governance_type = this.state.selectedGovernanceType
		}

		if(this.state.selectedViewOption) {
			params.view = this.state.selectedViewOption
			if(this.state.selectedPolicyType === "all_rules") {
				params.all_rules = true
			}
		}

		
		let appliesTo = {}
		if(this.state.selectedAccount && this.state.selectedAccount !== "") {
			appliesTo.account_id = this.state.selectedAccount
		}
		if(this.state.selectedRegion && this.state.selectedRegion.length) {
			appliesTo.region = this.state.selectedRegion
		}
		if(this.state.selectedTags && this.state.selectedTags.length) {
			appliesTo.tags = this.state.selectedTags
		}
		if(this.state.selectedUsers && this.state.selectedUsers.length) {
			appliesTo.users = this.state.selectedUsers
		}
		if(this.state.selectedRoles && this.state.selectedRoles.length) {
			appliesTo.roles = this.state.selectedRoles
		}

		if(this.state.selectedGovernanceType !== "opa" && appliesTo && Object.entries(appliesTo).length) {
			params.applies_to = appliesTo
		}

		if(this.state.selectedViewOption === "rule_wise") {
			if(this.state.selectedFilters && this.state.selectedFilters.length) {
				let groupedData = _.chain(this.state.selectedFilters)
								.groupBy('label')
								.map((value, key) => ({ groupLabel: key, groupValue: value })).value()

				groupedData.forEach(row => {
					let data = []
					row.groupValue.forEach(item => {
						data.push(item.value)
					})
					params[row.groupLabel] = data
				})
	
			}
		}

		params.not_configured = false
		if(this.state.selectedPolicyType === "Not Configured") {
			params.not_configured = true
		}
		
		
		let showFilteredList = true
		if(Object.entries(params).length === 2) {
			showFilteredList = false
		}

		let dynamicRulePoliceColumns = []
		if(this.state.selectedViewOption === "rule_wise") {
			let dataRow = {}
	
			dataRow.Header = 'Type'
			dataRow.accessor = 'governance_type'
			dataRow.Cell = cellInfo => (
				<span>{cellInfo.row.original.governance_type ? (this.state.governanceTypesMasterData.filter(e => e.value === cellInfo.row.original.governance_type).length ? this.state.governanceTypesMasterData.filter(e => e.value === cellInfo.row.original.governance_type)[0].label : cellInfo.row.original.governance_type) : "" }</span>
			)
			dataRow.width = 200
			dynamicRulePoliceColumns.push(dataRow)

			dataRow = {}
			dataRow.Header = 'Service'
			dataRow.accessor = 'resource_type'	
			dataRow.width = this.state.selectedPolicyType !== "all_rules" ? 100	: 250
			dynamicRulePoliceColumns.push(dataRow)

			dataRow = {}
			dataRow.Header = 'Category'
			dataRow.accessor = 'severity'
			dataRow.Cell = cellInfo => (
				<span>{cellInfo.row.original.rule_category} Policy Rule</span>
			)
			dataRow.width = this.state.selectedPolicyType !== "all_rules" ? 200 : 350
			dynamicRulePoliceColumns.push(dataRow)

			if(this.state.selectedPolicyType !== "all_rules") {	
				dataRow = {}
				dataRow.Header = 'Resources'
				// dataRow.accessor = 'severity'
				dataRow.Cell = cellInfo => (
					<div className="d-flex">
						<span className='mr-2'>{cellInfo.row.original.appResources}</span>
						{cellInfo.row.original.showMore ?
							<span className={`badge status-badge badge-outline-info`} onClick={() => this.setState({ masterData: cellInfo.row.original && cellInfo.row.original.ruleData ? cellInfo.row.original.ruleData : cellInfo.row.original}, () => this.setState({ showFilterDetailsPanel: true }))}>more</span>
						: null}
					</div>
				)
				dataRow.width = 400
				dynamicRulePoliceColumns.push(dataRow)
			}

			dataRow = {}	
			dataRow.Header = 'Tags'
			dataRow.Cell = cellInfo => (
				<div className="d-flex">
					{cellInfo.row.original.ruleTags && cellInfo.row.original.ruleTags.length ? 
						<span className={`badge status-badge risk-badge-outline-high risk-high`}>{cellInfo.row.original.ruleTags[0]}</span>
					: null}
				</div>
			)
			dataRow.width = this.state.selectedPolicyType !== "all_rules" ? 150 : 250
			dynamicRulePoliceColumns.push(dataRow)

			dataRow = {}	
			dataRow.Header = 'Status'
			dataRow.Cell = cellInfo => (
				<span className={`badge status-badge badge-outline-${cellInfo.row.original.ruleEnabled ? "success" : "danger"}`}>{cellInfo.row.original.ruleEnabled ? "Enabled" : "Disabled"}</span>
			)
			dataRow.width = this.state.selectedPolicyType !== "all_rules" ? 100 : 150
			dynamicRulePoliceColumns.push(dataRow)

			dataRow = {}	
			dataRow.Header = 'Description'
			dataRow.accessor = "ruleDescription"
			dataRow.width = this.state.selectedPolicyType !== "all_rules" ? 500 : 600
			dynamicRulePoliceColumns.push(dataRow)

			if(this.state.selectedPolicyType !== "all_rules") {	
				dataRow = {}
				dataRow.Header = 'Actions'
				// dataRow.accessor = 'severity'
				dataRow.Cell = cellInfo => (
					<div className="d-flex justify-content-between align-self-center">
						<p className="m-0 p-0 mx-1 cursorPointer" onClick={() => this.setState({ masterData: cellInfo.row.original && cellInfo.row.original.ruleData ? cellInfo.row.original.ruleData : cellInfo.row.original}, () => this.setState({ showFilterDetailsPanel: true }))}><i className="fas fa-ellipsis-h"></i></p>
					</div>
				)
				dataRow.width = 100
				dynamicRulePoliceColumns.push(dataRow)
			}
		}
		
		this.props.listGovernancePoliciesNew(params, (promise, response) => {
			if(promise) {
				this.setState({ governanceRules: response, filteredArray: [], showLoading: false, showFilteredList, queryLoading: false, selectedView: this.state.selectedViewOption, dynamicRulePoliceColumns }, 
					() => this.structurePolicyDetails()	
				)
			} else {
				this.setState({ showLoading: false, governanceRules: [], filteredArray: [], showFilteredList, queryLoading: false, selectedView: this.state.selectedViewOption, dynamicRulePoliceColumns })
			}
		})
	}

	structurePolicyDetails = () => {
		let data = this.state.governanceRules
		let policies = []
		data.length && data.forEach(row => {
			let policy_metadata = row.policy_metadata ? row.policy_metadata : {}
			let policyData = row.policy ? row.policy : {}
			let dataRow = {...policyData, ...policy_metadata}

			let appliesTo = row.policy_metadata && row.policy_metadata.applies_to ? row.policy_metadata.applies_to : []
			if(appliesTo.length) {
				let appAccounts = []	
				let appRegions = []
				let appTags = []
				let appUsers = []
				let appRoles = []
				let appResources = row.policy_metadata && row.policy_metadata.provider ? capitalizeAllLetter(row.policy_metadata.provider) : ""
				appliesTo.forEach((item, index) => {
					if(item.account_id){
					appAccounts.push(item.account_id)
					}

					if(item.region && item.region.length) {
						item.region.forEach(reg => {
							appRegions.push(reg)
						})
					}
					item.tags && item.tags.length && item.tags.forEach(tag => {
						appTags.push(tag)
					})
					item.users && item.users.length && item.users.forEach(usr => {
						appUsers.push(usr)
					})
					item.roles && item.roles.length && item.roles.forEach(rol => {
						appRoles.push(rol)
					})
				})
				
				if(appAccounts.length) {
					appResources += " : "+getAccountNameFromId(appAccounts[0], this.state.accounts)
				} 

				if(appRegions.length) {
					appResources += " : "+getRegionName(appRegions[0], this.state.regions)
				}

				// if(appRegions.length) {
				// 	appResources += " : "+appRegions[0]
				// }

				// row.appAccounts = appAccounts
				// row.appRegions = appRegions
				// row.appTags = appTags
				// row.appUsers = appUsers
				// row.appRoles = appRoles

				dataRow.appResources = appResources
				dataRow.showMore = (appAccounts.length > 1 || appRegions.length > 1) ? true : false
			}
			dataRow.ruleDescription = policyData.description
			dataRow.ruleTags = policyData.tags
			dataRow.ruleEnabled = policyData.enabled

			dataRow.ruleData = row
			policies.push(dataRow)
		})

		this.setState({ governancePolicies: policies, filteredArray: policies },
			() => this.formHeaders()	
		)
	}

	formHeaders = () => {
		let dataResult = this.state.filteredArray && this.state.filteredArray.length ? this.state.filteredArray : []
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === "string") {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})
			this.setState({ headers })
		}
	}

	getGovMetaData = () => {
		let params = {}
		this.props.getGovMetaData(params, (promise, response) => {
			if(promise) {
				this.setState({ metaData: response, showLoading: false },
					() => this.structureMetaData()	
				)
			}
		})
	}

	structureMetaData = () => {
		let metaData = this.state.metaData

		let governanceTypes = []

		if(Object.entries(metaData).length) {
			governanceTypes = Object.keys(metaData)
		}

		this.setState({ governanceTypes })
	}

	getPolicyInformation = () => {
		let details = this.state.metaData[this.state.selectedGovernanceType]

		let data = []

		if(Object.entries(details).length) {
			data = Object.keys(details)
		}

		this.setState({ policyTypeOptions: data, mainDropdown: data })
	}

	getAllTagsKeys = () => {
		
		if(!this.props.tagKeys || !this.props.tagKeys.length) {
			let params = {}
			// params.account_id = "265469181489"
			// params.region = "us-east-2"
			this.props.getAllTagsKeys(params, (promise, response) => {})
		}
	}

	getAllTagsValues = () => {
		let params = {}
		params.tags = [{ "key": this.state["tag_key"] }]
		this.props.getAllTagsValues(params, (promise, response) => {
			if(promise) {
				this.setState({ ["tagValues"]: response })
			}
		})
	}

	addNewTag = () => {
		let selectedTags = this.state["selectedTags"] ? this.state["selectedTags"] : []
		if(this.state["tag_key"] && this.state["tag_key"] !== "" && this.state["tag_value"] && this.state["tag_value"] !== "" ) {
			let dataRow = {
				key: this.state["tag_key"],
				value: this.state["tag_value"],
			}
			selectedTags.push(dataRow)
		}


		this.setState({ ["selectedTags"]: selectedTags, ["tag_key"]: "",  ["tag_value"]: "", tagValues: [] })
	}

	removeTagSection = (tag) => {
		// filteredResult = this.state.selectedTags.filter(e => e.key !== tag.key && e.value !== tag.value)
		let filteredResult = this.state["selectedTags"].filter(e => e !== tag)
		this.setState({ ["selectedTags"]: filteredResult })
	}

    listUserAssets = () => {
        let params = {}
		if(this.state.selectedAccount && this.state.selectedAccount !== "") {
			params.account_id = this.state.selectedAccount
		}
        // params.service_name = "iam-users"
		this.props.listAssets(params, (promise, response) => {
			if (promise) {
				this.setState({ applies_to_users: response.results ? response.results : [] })
			} else {
				this.setState({ applies_to_users: [] })
			}
		})
    }

	listRoleAssets = () => {
        let params = {}
		if(this.state.selectedAccount && this.state.selectedAccount !== "") {
			params.account_id = this.state.selectedAccount
		}
        params.service_name = "iam-roles"
		this.props.listAssets(params, (promise, response) => {
			if (promise) {
				this.setState({ applies_to_roles: response.results ? response.results : [] })
			} else {
				this.setState({ applies_to_roles: [] })
			}
		})
    }

    handleClickOutside = (event) => {
		// if (this.accountRef && !this.accountRef.current.contains(event.target)) {
		// 	this.setState({ isAccountOpen: false })
		// } else {
		// 	this.setState({ isAccountOpen: true })
		// }

		if (this.regionRef && !this.regionRef.current.contains(event.target)) {
			this.setState({ isRegionOpen: false })
		} else {
			this.setState({ isRegionOpen: true })
		}

		if (this.advanceSearchRef && this.advanceSearchRef.current && !this.advanceSearchRef.current.contains(event.target)) {
			this.setState({ showMainDropdownOption: false, selectedOption: "" })
		}
    }

    handleMultiSelectChange = (field, arrayValue, options, considerField) => {
		let value = arrayValue.map(item => item.value)

		let selectedValue = []
		if(field === 'selectedAccount') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.accounts.forEach(acc => {
						selectedValue.push(acc.account_id)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.accounts.length -1)) {
					selectedValue.push('All')
				}
			}
		} else if(considerField && considerField !== "") {
			let prevState = this.state[field] ? this.state[field] : []
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					options.forEach(reg => {
						selectedValue.push(reg[considerField])
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (options.length -1)) {
					selectedValue.push('All')
				}
			}
		} 
		this.setState({ [field]: selectedValue, changesInFilter: true })
    }

	getMultiSelectedCount = (type, array) => {
		return array.length && array.includes("All") ? "All" :  array.length ? array.length +" Selected" : "All"
	}

	onSearch = () => {
		this.setState({ queryLoading: true }, () => this.listGovernancePoliciesNew())
	}
	
	onReset = () => {

		if(this.state.selectedFilters && this.state.selectedFilters.length) {
			let groupedData = _.chain(this.state.selectedFilters)
							.groupBy('label')
							.map((value, key) => ({ groupLabel: key, groupValue: value })).value()
			
			groupedData.forEach(row => {
				row.groupValue.forEach(item => {
					this.setState({ ["options_"+this.state.selectedOption+"".value]: false })
				})
			})
		}

		this.setState({ 
			selectedGovernanceType: "All",
			selectedAccount: "",
			selectedRegion: [],
			selectedTags: [],
			selectedUsers: [],
			selectedRoles: [],
			selectedFilters: [],
			selectedPolicyType: "Configured",
		})
	}

	durationFunction = (type) => {
		if(this.state.selectedDuration !== "custom") {
			// let days = this.state.selectedDuration.replace('+', "").replace('d', "")
			// let reducedDays = days - 1
        
			// let start_time = momentDateGivenFormat(subDays(new Date(), reducedDays), 'YYYY-MM-DD 00:00:00')
			// let end_time = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:00')

			// this.setState({ start_time, end_time, showDateTimePicker: false })
			this.setState({ showDateTimePicker: false })
		} else {
			this.setState({ showDateTimePicker: true })
		}
    }

    comparisonFilter = () => {
		if(!this.state.searchText || this.state.searchText === '') {
            this.setState({ showMainDropdownOption: this.state.showMainDropdownOption ? false : true, selectedDefaultOption: '' })
		}
	}
    
    filterOnchange = () => {
		this.setState({ showMainDropdownOption: false }, 
			() => this.onChangeChildOperation()
		)		
	}
		
	onChangeChildOperation = () => {
		let filterData = _.orderBy(this.state.policyTypeOptions, ['Asc'])
		if(this.state.searchText !== '') {
			filterData = filterData.filter(
				filter => 
				filter.toLocaleLowerCase().includes(this.state.searchText) ||
				filter.includes(this.state.searchText)
			)
		}
		this.setState({ 
			selectedDefaultOption: '',
			mainDropdown: filterData, 
			showMainDropdownOption: 
			this.state.searchText === '' ? false : true 
		})
	}

	getSelectedOptionValues = () => {
		let details = this.state.metaData[this.state.selectedGovernanceType]
		let selectedOptionValues = details && details[this.state.selectedOption] ? details[this.state.selectedOption] : []

		// if(this.state.selectedFilters && this.state.selectedFilters.length) {
		// 	let groupedData = _.chain(this.state.selectedFilters)
		// 					.groupBy('label')
		// 					.map((value, key) => ({ groupLabel: key, groupValue: value })).value()

		// 	// let filterData = groupedData.filter(e => e.groupLabel === this.state.selectedGovernanceType) && groupedData.filter(e => e.groupLabel === this.state.selectedGovernanceType).length ? groupedData.filter(e => e.groupLabel === this.state.selectedGovernanceType) : []
			
		// 	groupedData.forEach(row => {
		// 		row.groupValue.forEach(item => {
		// 			if(selectedOptionValues.includes(item.value) && row.groupLabel === this.state.selectedOption) {
		// 				this.setState({ ["options_"+this.state.selectedOption+"_"+item.value]: true })
		// 			} else {
		// 				this.setState({ ["options_"+this.state.selectedOption+"_"+item.value]: false })
		// 			}
		// 		})
		// 	})
		// }

		this.setState({ childDropdownOptions: selectedOptionValues, filteredChildDropdownOptions: selectedOptionValues })
	}

	onSelectChildOptions = () => {

		let data = this.state.selectedFilters ? this.state.selectedFilters : []

		let dataRow = {}
		dataRow.label = this.state.selectedOption
		dataRow.value = this.state.selectedChildOptions

		data.push(dataRow)

		this.setState({ mainDropdown: this.state.policyTypeOptions, searchText: "", selectedFilters: data, showMainDropdownOption: false, selectedChildOptions: "", selectedOption: ""  })
	}

	updateMultiSelectedOptions = (row, options) => {
		let data = this.state.selectedFilters ? this.state.selectedFilters : []
		let selectedMultiOptions = this.state.selectedMultiOptions ? this.state.selectedMultiOptions : []

		if(this.state["options_"+this.state.selectedOption+"_"+row]) {				
			let dataRow = {}
			dataRow.label = this.state.selectedOption
			dataRow.value = row

			data.push(dataRow)
		} else {
			data = data.filter(e => e.value !== row)
		}

		this.setState({ selectedFilters: data, selectedMultiOptions })
	}

	onClickAdvancedSearch = () => {
		if(this.state.selectedGovernanceType === "infra") {
			this.getAllTagsKeys()
		} else {
			this.listUserAssets()
			this.listRoleAssets()
		}
	}

	removeItems = (type, row) => {
		if(type === "users") {
			let data = this.state.applies_to_users.filter(e => e !== row)
			this.setState({ selectedUsers: data  })
		} else {
			let data = this.state.applies_to_roles.filter(e => e !== row)
			this.setState({ selectedRoles: data  })
		}
	}

	/**
	 * Method to handle create rule
	 */
	onClickCreateRule = ruleObj => {
		this.props.history.push({
			pathname: URL_PATH.GOVERNANCE_CREATE_NEW_RULE,
			state: { ruleDetails: {} }
		})
	}

	onSideViewRule = (ruleId) => {
		let params = {}
		params.policy_name = ruleId
		this.props.getPolicyDetails(params, (promise, response) => {
			if(promise) {
				let ruleDetails = response
				// let policyInformation = ruleDetails.policies && ruleDetails.policies.length ? ruleDetails.policies : []
				let policyBasicDetails = ruleDetails.policy_metadata ? ruleDetails.policy_metadata : {}
				// let policies = []
				// let serviceArray = []
				// policyInformation.forEach((item, index) => {
				// 	serviceArray.push(index)
				// 	item.categories.forEach(cat => {
				// 		cat.policies.forEach(catPol => {
				// 			catPol.resource_type = item.resource_type
				// 			policies.push(catPol)
				// 		})
				// 	})
				// 	this.setState({ ["resource_type_"+index]: item.resource_type, serviceArray }, 
				// 		() => {
				// 			this.masterData("resource_type_"+index, this.state["resource_type_"+index], index)
				// 		}
				// 	)
				// })
				// let groupedPolicies = {policies: policies}
				// console.log("groupedPolicies", groupedPolicies)
				// let mergeObjects = {...groupedPolicies, ...policyBasicDetails}
				// console.log("mergeObjects", mergeObjects)
				// // this.props.setNewInitialRuleDetails(mergeObjects)

				this.setState({ queryLoading: false, ruleDetails, policyBasicDetails },
					() => {
						this.setState({ showListDetailsPanel: true })
					}
				)
			} else {
				this.setState({ editGovernanceRule: false, queryLoading: false })
			} 
		})
	}

	onClickEditRule = (ruleId) => {
		let params = {}
		params.policy_name = ruleId
		this.props.getPolicyDetails(params, (promise, response) => {
			if(promise) {
				// console.log(response)
				// console.log("results", results)
				// this.props.setNewInitialRuleDetails(results)
				// console.log("this.props.editNewRuleDetails", this.props.editNewRuleDetails)
				this.props.history.push({
					pathname: URL_PATH.GOVERNANCE_EDIT_NEW_RULE,
					state: { ruleDetails: response }
				})
			} else {
				this.setState({ editGovernanceRule: false })
			} 
		})
	}

	removeFilterData = (row) => {
		let selectedFilters = this.state.selectedFilters.filter(e => e.label === row.label && e.value === row.value)

		this.setState({ selectedFilters })
	}

	onChangeView = () => {
		let view = this.state.selectedView

		let dynamicRulePoliceColumns = []
		if(view === "rule_wise") {
			let dataRow = {}
	
			dataRow.Header = 'Type'
			dataRow.accessor = 'governance_type'
			dataRow.Cell = cellInfo => (
				<span>{cellInfo.row.original.governance_type ? (this.state.governanceTypesMasterData.filter(e => e.value === cellInfo.row.original.governance_type).length ? this.state.governanceTypesMasterData.filter(e => e.value === cellInfo.row.original.governance_type)[0].label : cellInfo.row.original.governance_type) : "" }</span>
			)
			dataRow.width = 200
			dynamicRulePoliceColumns.push(dataRow)

			dataRow = {}
			dataRow.Header = 'Service'
			dataRow.accessor = 'resource_type'	
			dataRow.width = 100				
			dynamicRulePoliceColumns.push(dataRow)

			dataRow = {}
			dataRow.Header = 'Category'
			dataRow.accessor = 'severity'
			dataRow.Cell = cellInfo => (
				<span>{cellInfo.row.original.rule_category} Policy Rule</span>
			)
			dataRow.width = 200
			dynamicRulePoliceColumns.push(dataRow)

			if(this.state.selectedPolicyType !== "all_rules") {	
				dataRow = {}
				dataRow.Header = 'Resources'
				// dataRow.accessor = 'severity'
				dataRow.Cell = cellInfo => (
					<div className="d-flex">
						<span className='mr-2'>{cellInfo.row.original.appResources}</span>
						{cellInfo.row.original.showMore ?
							<span className={`badge status-badge badge-outline-info`} onClick={() => this.setState({ masterData: cellInfo.row.original && cellInfo.row.original.ruleData ? cellInfo.row.original.ruleData : cellInfo.row.original}, () => this.setState({ showFilterDetailsPanel: true }))}>more</span>
						: null}
					</div>
				)
				dataRow.width = 400
				dynamicRulePoliceColumns.push(dataRow)
			}

			dataRow = {}	
			dataRow.Header = 'Tags'
			dataRow.Cell = cellInfo => (
				<div className="d-flex">
					{cellInfo.row.original.ruleTags && cellInfo.row.original.ruleTags.length ? 
						<span className={`badge status-badge risk-badge-outline-high risk-high`}>{cellInfo.row.original.ruleTags[0]}</span>
					: null}
				</div>
			)
			dataRow.width = 150
			dynamicRulePoliceColumns.push(dataRow)

			dataRow = {}	
			dataRow.Header = 'Status'
			dataRow.Cell = cellInfo => (
				<span className={`badge status-badge badge-outline-${cellInfo.row.original.ruleEnabled ? "success" : "danger"}`}>{cellInfo.row.original.ruleEnabled ? "Enabled" : "Disabled"}</span>
			)
			dataRow.width = 150
			dynamicRulePoliceColumns.push(dataRow)

			dataRow = {}	
			dataRow.Header = 'Description'
			dataRow.accessor = "ruleDescription"
			dataRow.width = this.state.selectedPolicyType !== "all_rules" ? 500 : 600
			dynamicRulePoliceColumns.push(dataRow)

			if(this.state.selectedPolicyType !== "all_rules") {	
				dataRow = {}
				dataRow.Header = 'Actions'
				// dataRow.accessor = 'severity'
				dataRow.Cell = cellInfo => (
					<div className="d-flex justify-content-between align-self-center">
						<p className="m-0 p-0 mx-1 cursorPointer" onClick={() => this.setState({ masterData: cellInfo.row.original && cellInfo.row.original.ruleData ? cellInfo.row.original.ruleData : cellInfo.row.original}, () => this.setState({ showFilterDetailsPanel: true }))}><i className="fas fa-ellipsis-h"></i></p>
					</div>
				)
				dataRow.width = 100
				dynamicRulePoliceColumns.push(dataRow)
			}
		}
		this.setState({ dynamicRulePoliceColumns })
	}

	/**
	 * Renders governance Dashboard
	 */
	render() {
		return (
			<div className="container-fluid overflow-auto flex-grow-1 bg-muted" onClick={ (event) => { this.handleClickOutside(event) } }>
				<div className={`loaderOverlay ${this.state.queryLoading ? "" : "displayNone"}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
				{this.state.showListDetailsPanel ? (
					<ViewSidePanel
						visitFrom={"List"}
						selectedProvider={this.state.selectedProvider}
						// resource_type={this.state.resource_type}
						ruleDetails={this.state.ruleDetails}
						governanceType={this.state.policyBasicDetails && this.state.policyBasicDetails.governance_type}
						closeSidePanel={() => this.setState({ showListDetailsPanel: false })}
					/>
				) : null}
				{this.state.showFilterDetailsPanel ? (            
					<FilterSidePanel
						masterData={this.state.masterData}
						closeSidePanel={() => this.setState({ showFilterDetailsPanel: false })}
					/>
				) : null}
				<div className="row h-100">
					<div className="col-sm-12 p-0">
						<div className="title-section p-2">
							<div className="row">
								<div className="col-4 align-self-center">
									<h6 className="text-white m-0">Governance Rules</h6>
									<p className="text-white m-0">
										Filter user governance rules on your Cloud
									</p>
								</div>
								{/* {this.state.selectedView === "policy_wise" ? */}
									<div className="col-8 d-flex justify-content-end align-self-center">
										<button className="ml-3 btn btn-primary btn-sm" onClick={() => this.onClickCreateRule(this.state.selectedTableRow)}>
											Create Rule
										</button>
									</div>
								{/* : null} */}
							</div>
						</div>

						<div className="container-fluid bg-dark m-2 pt-3 rounded h-100">
							<div className="bg-dark3 rounded p-3">
								<div className="containerFilterSection mb-2">
									<div className="d-flex">
										<div className="mr-4">
											<div className="d-flex justify-content-between">
												<label className="align-self-center m-0">Governance Type </label>
												{this.state.hasError && (!this.state.selectedGovernanceType || this.state.selectedGovernanceType === "") ? (
													<small className="text-issues">required</small>
												) : null}
											</div>
											<Select
												isSearchable={false}
												className={`selectOption f13 p-0`}
												components={{
													IndicatorSeparator: () => null
												}}
												value={({
													value: this.state.selectedGovernanceType && this.state.selectedGovernanceType !== "" ? this.state.selectedGovernanceType : "",
													label: this.state.selectedGovernanceType && this.state.selectedGovernanceType !== "" ? (this.state.governanceTypesMasterData && this.state.governanceTypesMasterData.filter(e => e.value === this.state.selectedGovernanceType).length ? this.state.governanceTypesMasterData.filter(e => e.value === this.state.selectedGovernanceType)[0].label : this.state.selectedGovernanceType) : <span className="placeholder">Select</span>
												})}
												options={this.state.governanceTypes && this.state.governanceTypes.map(row => ({
													value: row,
													label: this.state.governanceTypesMasterData && this.state.governanceTypesMasterData.filter(e => e.value === row).length ? this.state.governanceTypesMasterData.filter(e => e.value === row)[0].label : row,
												}))}
												onChange={event => this.setState({
													selectedGovernanceType: event.value,
													changesInFilter: true,
												},
													() => this.getPolicyInformation()
												)}
											/>
										</div>
										{this.state.selectedGovernanceType !== "opa" && this.state.selectedGovernanceType !== "opa_kubernetes"  ?
											<React.Fragment>
											<div className="mr-4">
												<div className="d-flex justify-content-between">
													<label className="align-self-center m-0">Provider </label>
													{this.state.hasError && (this.state.selectedProvider || this.stae.selectedProvider === "") ? (
														<small className="text-issues">required</small>
													) : null}
												</div>
												<Select
													isSearchable={false}
													className={`selectOption f13 p-0`}
													components={{
														IndicatorSeparator: () => null
													}}
													value={({
														value: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : "",
														label: this.state.selectedProvider && this.state.selectedProvider.toLowerCase() !== "" ? this.state.selectedProvider.toUpperCase() : <span className="placeholder">Select</span>
													})}
													options={this.state.providers && this.state.providers.map(row => ({
														value: row.provider_name,
														label: row.provider_name,	
													}))}
													onChange={event => this.setState({
														selectedProvider: event.value,
														changesInFilter: true,
													})}
												/>
											</div>
											<div className="mr-4">
												<div className="d-flex justify-content-between">
													<label className="align-self-center m-0">Account </label>
													{this.state.hasError && (this.state.selectedAccount || this.stae.selectedAccount === "") ? (
														<small className="text-issues">required</small>
													) : null}
												</div>
												{/* <div className={`multiSelectOption align-self-end`}>
													<ReactMultiSelectCheckboxes
														placeholderButtonLabel="All"
														getDropdownButtonLabel={() => this.getMultiSelectedCount("selectedAccount", this.state.selectedAccount ? this.state.selectedAccount : [])}
														options={this.state.accounts && this.state.accounts.map(row => ({
															value: row.account_id,
															label: row.account_name,
														}))}
														onChange={arr => { this.handleMultiSelectChange("selectedAccount", arr ? arr : []) }}
														value={this.state.selectedAccount && this.state.selectedAccount.map(acc => ({
															value: acc
														}))}
													/>
												</div> */}											
												<Select
													isSearchable={false}
													className={`selectOption f13 p-0`}
													components={{
														IndicatorSeparator: () => null
													}}
													value={({
														value: this.state.selectedAccount && this.state.selectedAccount !== "" ? this.state.selectedAccount : "",
														label: this.state.selectedAccount && this.state.selectedAccount !== "" ? getAccountNameFromId(this.state.selectedAccount, this.state.accounts) : <span className="placeholder">Select</span>
													})}
													options={this.state.accounts && this.state.accounts.map(row => ({
														value: row.account_id,
														label: row.account_name,	
													}))}
													onChange={event => this.setState({
														selectedAccount: event.value,
														changesInFilter: true,
													})}
												/>
											</div>
											</React.Fragment>
										:null}
										<div className="mr-4" ref={this.regionRef}>
											<div className="d-flex justify-content-between">
												<label className="align-self-center pb-5p">Region </label>
												{this.state.hasError && (!this.state.selectedAccount || !this.state.selectedAccount.length) ? (
													<small className="text-issues">required</small>
												) : null}
											</div>
											<div className={`multiSelectOption align-self-end`}>
												<ReactMultiSelectCheckboxes
													placeholderButtonLabel="All"
													getDropdownButtonLabel={() => this.getMultiSelectedCount("selectedRegion", this.state.selectedRegion ? this.state.selectedRegion : [])}
													options={this.state.regions && this.state.regions.map(row => ({
														value: row.region,
														label: row.name,
													}))}
													onChange={arr => { this.handleMultiSelectChange("selectedRegion", arr ? arr : [], this.state.regions, "region")}}
													value={this.state.selectedRegion && this.state.selectedRegion.map(row => ({
														value: row
													}))}
												/>
											</div>
										</div>
										<div className="mr-4">
											<div className="d-flex justify-content-between">
												<label className="align-self-center m-0">View </label>
												{this.state.hasError && (this.state.selectedView || this.stae.selectedView === "") ? (
													<small className="text-issues">required</small>
												) : null}
											</div>
											<Select
												isSearchable={false}
												className={`selectOption f13 p-0`}
												components={{
													IndicatorSeparator: () => null
												}}
												value={({
													value: this.state.selectedViewOption && this.state.selectedViewOption !== "" ? this.state.selectedViewOption : "",
													label: this.state.selectedViewOption && this.state.selectedViewOption !== "" ? (this.state.viewOptions.filter(e => e.value === this.state.selectedViewOption).length ? this.state.viewOptions.filter(e => e.value === this.state.selectedViewOption)[0].label : this.state.selectedViewOption) : <span className="placeholder">Select</span>
												})}
												options={this.state.viewOptions && this.state.viewOptions.map(row => ({
													value: row.value,
													label: row.label,	
												}))}
												onChange={event => this.setState({
													selectedViewOption: event.value,
													changesInFilter: true,
													selectedPolicyType: event.value === "policy_wise" && this.state.selectedPolicyType === "all_rules" ? "Configured" : this.state.selectedPolicyType
													// showAdvancedSearch: event.value === "rule_wise" ? true : false
												},
													// () => this.listGovernancePoliciesNew()
												)}
											/>
										</div>	
										<div className="mr-4">
											<div className="d-flex justify-content-between">
												<label className="align-self-center m-0">Policy Type </label>
												{this.state.hasError && (this.state.selectedPolicyType || this.stae.selectedPolicyType === "") ? (
													<small className="text-issues">required</small>
												) : null}
											</div>
											<Select
												isSearchable={false}
												className={`selectOption f13 p-0`}
												components={{
													IndicatorSeparator: () => null
												}}
												value={({
													value: this.state.selectedPolicyType && this.state.selectedPolicyType !== "" ? this.state.selectedPolicyType : "",
													label: this.state.selectedPolicyType && this.state.selectedPolicyType !== "" ? (this.state.policyTypes.filter(e => e.value === this.state.selectedPolicyType).length ? this.state.policyTypes.filter(e => e.value === this.state.selectedPolicyType)[0].label : this.state.selectedPolicyType) : <span className="placeholder">Select</span>
												})}
												options={this.state.policyTypes && (this.state.selectedViewOption !== "rule_wise" ? this.state.policyTypes.filter(e => e.value !== "all_rules") : this.state.policyTypes).map(row => ({
													value: row.value,
													label: row.label,	
												}))}
												onChange={event => this.setState({
													selectedPolicyType: event.value,
													changesInFilter: true
												})}
											/>
										</div>		
										{!this.state.showAdvancedSearch ?							
											<div className="align-self-center">
												<div className="d-flex align-self-center">
													<div className="btn-group" role="group" aria-label="governance-dash">
														<button type="button" className={`cursorPointer btn btn-secondary border-right`} onClick={()=> this.onSearch()}>Search</button>
														<button type="button" className={`cursorPointer btn btn-secondary border-left`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
													</div>
												</div>
												
												{this.state.selectedViewOption === "rule_wise" ?
													<p className="mb-0 mt-2 text-info cursorPointer" onClick={() => this.setState({ showAdvancedSearch: !this.state.showAdvancedSearch }, () => this.onClickAdvancedSearch())}>Advanced Search</p>
												: null}
											</div>
										: null}
									</div>
									{this.state.selectedGovernanceType !== "All" && this.state.selectedViewOption === "rule_wise" && this.state.showAdvancedSearch ? 
										<React.Fragment>
										<div className="mt-3">
											{/* <p className="mb-0 f18 text-white">Advanced Search</p> */}
											{/* <div className={`form-check form-check-inline align-self-center w-100 my-2 mr-2 ${this.state.not_configured ? "" : "checkboxGrayColor"}`}>
												<input
													className='form-check-input m-0'
													type='checkbox'
													checked={this.state.not_configured}
													onClick={e => this.setState({ not_configured: !this.state.not_configured })}	
													title={this.state.not_configured ? "not enable" : "enable"}								
												/>
												{!this.state.not_configured ?
													<span class="checkmark" onClick={e => this.setState({ not_configured: !this.state.not_configured })}></span>
												: null}
												<label className="form-check-label f14 ml-2">Not Configured</label>
											</div> */}
											{this.state.selectedFilters && this.state.selectedFilters.length ? 
												<div className="d-flex flex-wrap mt-3 mb-2">
													{this.state.selectedFilters.map(row => {
														return (
															<small className={`badge badge-secondary mr-1`}>{row.label+" : "+row.value}
															<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeFilterData(row) } ></i>
															</small>
															
														)
													})}
												</div>
											: null}
											<div className="position-relative d-inline-block w-25 mt-2"  ref={this.advanceSearchRef}>
												<label className="mb-1">Search</label>
												{/* <Input
													type="text"
													placeholder="Select Template"
													className="inputTextbox bg-dark3 text-white border-muted"
													value={this.state.selected_template ? this.state.selected_template : this.state.searchKey}
													onChange={event => 
														this.setState({ searchKey: event.target.value, showDropdown: true })
													}
													onClick={() => this.setState({ showDropdown: true })}
												/> */}
												<div className="comparisonTextboxDiv p-0 m-0">
													<input type="text" className={`comparisonTextbox bg-muted text-white border-dark py-2 pl-3 rounded`}
														onChange={e => {this.setState({ searchText: e.target.value }, () => this.filterOnchange())}}
														// onKeyUp={this.onKeyUp}
														value={this.state.searchText}
														/*{this.state.selectedDefaultOption === '' ? this.state.searchText : this.state.selectedDefaultOption +': ' } */
														onClick={ () => this.comparisonFilter() }
														placeholder={this.state.placeHolder}
													/>
												</div>
												{this.state.showMainDropdownOption ?
													<div className={`dropdownOptions topAuto mt-2 bg-muted border-muted`}>
														{this.state.mainDropdown && this.state.mainDropdown.map(row => {
															return(
																<button className="d-flex justify-content-between mb-3 bg-muted text-white cursorPointer" onClick={() => this.setState({ selectedOption: row, showDropdown: false, searchKey: "" }, 
																	() => this.getSelectedOptionValues()
																)}>
																	<p className="mb-0 text-left">{row}</p>
																</button>
															)
														})}
													</div>
												: null}
												{/* {this.state.selectedOption && this.state.selectedOption !== "" ?
													<div className={`dropdownOptions topAuto mt-2 bg-muted border-dark`}>
														{this.state.filteredChildDropdownOptions && this.state.filteredChildDropdownOptions.map(row => {
															return(
																<button className="d-flex justify-content-between mb-3 bg-muted text-white cursorPointer" onClick={() => this.setState({ selectedChildOptions: row, showDropdown: false, searchKey: "" }, () => this.onSelectChildOptions())}>
																	<p className="mb-0 text-left">{row}</p>
																</button>
															)
														})}
													</div>
												: null} */}
												{this.state.selectedOption && this.state.selectedOption !== "" ?
													<div className={`dropdownOptions topAuto mt-2 bg-muted border-dark`}>
														{this.state.filteredChildDropdownOptions && this.state.filteredChildDropdownOptions.map(row => {
															return(		
																<div className="d-flex mb-3 bg-muted text-white cursorPointer" onClick={() => this.setState({ ["options_"+this.state.selectedOption+"_"+row]: !this.state["options_"+this.state.selectedOption+"_"+row] }, () => this.updateMultiSelectedOptions(row, this.state.filteredChildDropdownOptions))}>											
																	<div className="ml-1 form-check">
																		<input type="checkbox"
																			// onChange={() => this.setState({ ["options"+row]: !this.state["options"+row] }, () => this.updateMultiSelectedOptions(row, this.state.filteredChildDropdownOptions))}
																			className="form-check-input"
																			checked={this.state["options_"+this.state.selectedOption+"_"+row]}
																		/>
																	</div>
																	<p className="ml-1 mb-0 text-left">{row}</p>
																</div>
															)
														})}
													</div>
												: null}
											</div>
										</div>
										{this.state.selectedGovernanceType === "infra" ?
											<div className="mt-3">											
												<div className="d-flex justify-content-between">
													<label className="align-self-center m-0">Tags </label>
													{this.state.hasError && (!this.state.selectedTags || !this.stae.selectedTags.length) ? (
														<small className="text-issues">required</small>
													) : null}
												</div>
												<div className="d-flex">
													<Select
														isSearchable={true}
														components={{
															IndicatorSeparator: () => null
														}}
														className={`selectOption selectBoxBottomColor mr-3 mt-2`}
														value={({
															value: this.state["tag_key"],
															label: this.state["tag_key"] && this.state["tag_key"] !== "" ? this.state["tag_key"] : <span className="placeholder">Key</span>
														})}														
														
														options={this.props.tagKeys && this.props.tagKeys.map(item => ({
															value: item,
															label: item,	
														}))}
														onChange={event => this.setState({
															["tag_key"]: event.value,
															["tagValues"]: [],
														},
															() => this.getAllTagsValues()
														)}
													/>
													<Select
														isSearchable={true}
														components={{
															IndicatorSeparator: () => null
														}}
														className={`selectOption selectBoxBottomColor mr-3 mt-2`}
														value={({
															value: this.state["tag_value"] && this.state["tag_value"] !== "" ? this.state["tag_value"] : 'Select',
															label: this.state["tag_value"] && this.state["tag_value"] !== "" ? this.state["tag_value"] : <span className="placeholder">Value</span>
														})}													
														
														options={this.state["tagValues"] && this.state["tagValues"].map(item => ({
															value: item,
															label: item,	
														}))}
														onChange={event => this.setState({ ["tag_value"]: event.value })}
													/>
													<span className={`far fa-plus cursor-pointer f18 mt-2 mb-1 align-self-end mr-4`} onClick={() => this.addNewTag()}></span>

													{this.state["selectedTags"] && this.state["selectedTags"].length ? 
														this.state["selectedTags"].map((tag, index) => {
															return(
																<span className="badge badge-secondary mr-2 mt-2 align-self-center f14 px-2">{tag.key +" : "+tag.value}
																	<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeTagSection(tag) } ></i>
																</span>
															)
														})
													: null}
												</div>
											</div>
										: this.state.selectedGovernanceType === "access" ?
											<React.Fragment>
											<div className="mt-3">		
												<div className="d-flex justify-content-between">
													<label className="align-self-center pb-5p">Users </label>
													{this.state.hasError && (!this.state.selectedUsers || !this.state.selectedUsers.length) ? (
														<small className="text-issues">required</small>
													) : null}
												</div>
												<div className={`multiSelectOption align-self-end`}>
													<ReactMultiSelectCheckboxes
														placeholderButtonLabel="All"
														getDropdownButtonLabel={() => this.getMultiSelectedCount("selectedUsers", this.state.selectedUsers ? this.state.selectedUsers : [])}
														options={this.state.applies_to_users && this.state.applies_to_users.map(row => ({
															value: row.asset_arn,
															label: row.asset_arn,
														}))}
														onChange={arr => { this.handleMultiSelectChange("selectedUsers", arr ? arr : [], this.state.applies_to_users, "asset_arn")}}
														value={this.state.selectedUsers && this.state.selectedUsers.map(row => ({
															value: row
														}))}
													/>
												</div>

												{this.state.selectedUsers && this.state.selectedUsers.length ? 
													this.state.selectedUsers.map(row=> {
														return(
															<span className="badge badge-secondary mr-2 mt-2 align-self-center f14 px-2">{row}
																<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeItems("users", row) } ></i>
															</span>
														)
													})
												: null}
											</div>

											<div className="mt-3">		
												<div className="d-flex justify-content-between">
													<label className="align-self-center pb-5p">Roles </label>
													{this.state.hasError && (!this.state.selectedRoles || !this.state.selectedRoles.length) ? (
														<small className="text-issues">required</small>
													) : null}
												</div>
												<div className={`multiSelectOption align-self-end`}>
													<ReactMultiSelectCheckboxes
														placeholderButtonLabel="All"
														getDropdownButtonLabel={() => this.getMultiSelectedCount("selectedRoles", this.state.selectedRoles ? this.state.selectedRoles : [])}
														options={this.state.applies_to_roles && this.state.applies_to_roles.map(row => ({
															value: row.asset_arn,
															label: row.asset_arn,
														}))}
														onChange={arr => { this.handleMultiSelectChange("selectedRoles", arr ? arr : [], this.state.applies_to_roles, "asset_arn")}}
														value={this.state.selectedRoles && this.state.selectedRoles.map(row => ({
															value: row
														}))}
													/>
												</div>

												{this.state.selectedRoles && this.state.selectedRoles.length ? 
													this.state.selectedRoles.map(row=> {
														return(
															<span className="badge badge-secondary mr-2 mt-2 align-self-center f14 px-2">{row}
																<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeItems("roles", row) } ></i>
															</span>
														)
													})
												: null}
											</div>
											</React.Fragment>
										: null}

										<div className='mt-4 d-flex'>
											<div className="btn-group" role="group" aria-label="governance-dash">
												<button type="button" className={`cursorPointer btn btn-secondary border-right`} onClick={()=> this.listGovernancePoliciesNew()}>Search</button>
												<button type="button" className={`cursorPointer btn btn-secondary border-left`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
											</div>
											{/* <button className="btn btn-primary" onClick={() => this.listGovernancePoliciesNew()} >Search {this.props.disableFinish ? <Spinner size='sm' color='light' className='ml-2' /> : null}
											</button> */}
											<button className="ml-4 btn btn-secondary" onClick={() => this.setState({ showAdvancedSearch: false })} >Cancel</button>
										</div>
										</React.Fragment>
									: null}
								</div>
							</div>


							<div className="d-flex justify-content-between mt-3 py-2">
								<p className="mb-0 align-self-center">Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.state.governancePolicies && this.state.governancePolicies.length} {this.state.selectedView === "policy_wise" ? "Policies" : "Rule(s)"}</p>
								{this.state.governancePolicies && this.state.governancePolicies.length ?
									<div className="d-flex">
										<CSVLink 
											data={this.state.filteredArray ? this.state.filteredArray : []} 
											headers={this.state.headers ? this.state.headers : []}
											filename={"resource-alerts.csv"}
											className={"mt-2"}
											target="_blank"
										>
											<span className="d-flex text-primary-color mr-2">
												<i className="fas fa-download text-primary-color mr-2"></i>
												{/* csv */}
											</span>
										</CSVLink>
										<div className={`mb-n2 ${this.state.showLoading ? "disabled" : ""}`}>
											<Search
												data={this.state.governancePolicies ? this.state.governancePolicies : []}
												filteredData={filteredArray => {
													this.setState({ filteredArray })
												}}
											/>
										</div>
									</div>
								: null}
							</div>
							<div className='tab-content' id='myTabContent'>
								<div className='tab-pane fade show active' id='home' role='tabpanel' aria-labelledby='home-tab'>
									{this.state.governancePolicies && this.state.governancePolicies.length && !this.state.showLoading ? 
										this.state.selectedView === "policy_wise" ?
											<ResizeableDarkThemeTable
												columns={[
													// {
													// 	Header: "Date",
													// 	accessor:  d => d.modified_at ? d.modified_at : d.created_at,
													// 	Cell: cellInfo => cellInfo.row.original.modified_at && cellInfo.row.original.modified_at !== "" ?  momentConvertionUtcToLocalTime(cellInfo.row.original.modified_at, 'DD MMM YYYY HH:mm') : momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, 'DD MMM YYYY HH:mm')
													// },
													{
														Header: "Type",
														Cell: cellInfo => (
															<span>{cellInfo.row.original.governance_type ? (this.state.governanceTypesMasterData.filter(e => e.value === cellInfo.row.original.governance_type).length ? this.state.governanceTypesMasterData.filter(e => e.value === cellInfo.row.original.governance_type)[0].label : cellInfo.row.original.governance_type) : "" }</span>
														),
														accessor: "governance_type",
														width: 200
													},
													{
														Header: "Policy Name",
														accessor: "policy_name",
														width: 200
													},
													{
														Header: "Service",
														accessor: "resource_type",
														width: 150
													},
													{
														Header: "Category",
														Cell: cellInfo => (
															<span>{cellInfo.row.original.rule_category ? cellInfo.row.original.rule_category + " Policy Rule" : ""}</span>
														),
														accessor: "rule_category",
														width: 150
													},
													{
														Header: "Resources",
														Cell: cellInfo => (
															<div className="d-flex">
																<span className='mr-2'>{cellInfo.row.original.appResources}</span>
																{cellInfo.row.original.showMore ?
																	<span className={`badge status-badge badge-outline-info`}>more</span>
																: null}
															</div>
														),
														width: 400
													},											
													{
														Header: "Description",
														accessor: "description",
														width: 1400
													},
													{
														Header: "Actions",
														Cell: cellInfo => (
															<div className="d-flex justify-content-between align-self-center">
																{this.state.selectedView === "rule_wise" ?
																	<p className="m-0 p-0 mx-1 cursorPointer" onClick={() => this.setState({ masterData: cellInfo.row.original && cellInfo.row.original.ruleData ? cellInfo.row.original.ruleData : cellInfo.row.original}, () => this.setState({ showFilterDetailsPanel: true }))}><i className="fal fa-eye"></i></p>
																:
																	<React.Fragment>
																	{/* <p className="m-0 p-0 mr-1 cursorPointer" onClick={() => this.setState({ rulesJson : {} }, () => this.onClickViewRule(cellInfo.row.original.rule_id))}><i className="fal fa-book-open"></i></p> */}
																		{/* onClick={() => this.onClickEditRule(cellInfo.row.original.policy_name)} */}
																	<p className="m-0 p-0 mr-1 cursorPointer" onClick={() => this.onClickEditRule(cellInfo.row.original.policy_name)}><i className="fal fa-user-edit"></i></p>
																	<p className="m-0 p-0 mx-1 cursorPointer" onClick={() => this.setState({ queryLoading: true }, () => this.onSideViewRule(cellInfo.row.original.policy_name))}><i className="fal fa-book-open"></i></p>
																	{/* , () => this.onClickViewDeleteOption(cellInfo.row.original.rule_id) */}
																	{/* <p className="m-0 p-0 mx-1 cursorPointer" onClick={() => this.setState({ rulesJson : {} })}><i className="fal fa-trash"></i></p>  */}
																	{/* //onClick={() => this.setState({ enteredRuleId: "", modalClose: true, deleteRuleId: cellInfo.row.original.rule_id  })}>Delete</p> */}
																	
																	{/* , () => this.onClickUpdateRule(cellInfo.row.original.rule_id, cellInfo.row.original.enabled) */}
																	{/* <p className="m-0 p-0 mx-1 cursorPointer" onClick={() => this.setState({ showEnableSpinner: cellInfo.row.original.rule_id })}>
																		{this.state.showEnableSpinner && cellInfo.row.original.rule_id === this.state.showEnableSpinner ?
																			<Spinner className="text-center" color="dark" size="sm" />
																		:
																			cellInfo.row.original.enabled ? <i className="fal fa-thumbs-up"></i> : <i className="fal fa-thumbs-down"></i>
																		}
																	</p> */}
																	</React.Fragment>
																}
															</div>
														),
														width: 75
													}
												]}
												data={this.state.filteredArray ? this.state.filteredArray : this.state.governancePolicies}
												perPage={20}
												dashboard={(this.props.filteredArray ? this.props.filteredArray.length : (this.state.governancePolicies && this.state.governancePolicies.length)) ? true : false}
												sortByColumn={"Date"}
												riskTooltip={[0]}
												onClickRow={tableRow => {}}
												// tableSize={"table-sm"}
												// tableHead={"bg-dark"}
												tooltipFor={[]}
											/>
										: 
												<React.Fragment>
													<ResizeableDarkThemeTable
														columns={this.state.dynamicRulePoliceColumns}
														data={this.state.filteredArray ? this.state.filteredArray : this.state.governancePolicies}
														perPage={20}
														dashboard={(this.props.filteredArray ? this.props.filteredArray.length : (this.state.governancePolicies && this.state.governancePolicies.length)) ? true : false}
														sortByColumn={"Date"}
														riskTooltip={[0]}
														onClickRow={tableRow => {}}
														// tableSize={"table-sm"}
														// tableHead={"bg-dark"}
														tooltipFor={[]}
													/>

												</React.Fragment>

									: (!this.state.showLoading && !this.state.governancePolicies) ||
										(this.state.governancePolicies && !this.state.governancePolicies.length && !this.state.showLoading) ? 
										<div className="d-flex justify-content-center m-4">
											<p>There are no data on this criteria. Please try adjusting your filter.</p>
										</div>
									: null}
									{this.state.showLoading ? (
										<div className="d-flex justify-content-center m-4">
											<Spinner className="text-center" color="white" size="lg" />
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ManagePolicies.propTypes = {
	listAllProviders: PropTypes.func,
	listAllAccounts: PropTypes.func,
	listAllRegions: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log("-",state)
	return {
		providers: state.filters.providers,
		services: state.filters.services,
		tagKeys: state.filters.tagKeys,
	}
}

export default AppWrapper(ManagePolicies, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
	getAllTagsKeys,
	getAllTagsValues,
	listAssets,

	setNewInitialRuleDetails,

	getGovMetaData,
	listGovernancePoliciesNew,
	getPolicyDetails
})
