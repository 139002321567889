/*************************************************
 * Tvastar
 * @exports
 * @file commonAction.js
 * @author Rajasekar // on 26/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'
import React from 'react'
import { Auth } from 'aws-amplify'
import axios from 'axios'

import {

	USER_SERVICES,
	LIST_PROVIDERS,
	LIST_REGIONS,
	LIST_SERVICES,
	LIST_ACCOUNTS,  //common and common dev service
	LIST_CATEGORIES,	
	GET_USER_ORGANIZATION,
	LIST_EVENT_SOURCES,
	CAPTURE_UI_ERRORS,

	COMMON_SERVICES,
	LIST_ORGANIZATIONS,
	LIST_MODULES,
	LIST_ACCOUNTS_DETAILS,
	
	DETECTORS,
	GET_SEARCH_META_RESULTS,
	SET_FAVOURITE_QUERY,
	UNSET_FAVOURITE_QUERY,
	
	TAGGING,
	GET_ALL_TAGS,
	GET_ALL_TAGS_KEYS,
	GET_ALL_TAGS_VALUES,

	PROFILE,
	GET_USER_PROFILE,
	GET_USER_MENU,

	SYNCHRONIZER,
	GET_RESOURCE_SEARCH_META_RESULTS,
	GET_VARIOUS_COUNTS_BY_ACCOUNT,


	AIOPS_AUTOMATION,

	ISSUEDUMP,
	GET_COLLECTED_DATA,
	LIST_TEST_DETAILS,

	PRICING_END_POINT,
	
} from '../config'
import { myLog } from '../utils/utility'
import { ACTION_TYPES } from './types'
import { toast } from 'react-toastify'
import { SUCCESS, ERROR } from '../utils/constants'

/**
 * Action to show notification
 * @param {Function} callback
 */
export const showNotification = (type, message) => {
	return () => {
		switch (type) {
			case SUCCESS:
				toast.success(
					<div className='d-flex align-items-centerr w-100'>
						<i className='fa fa-check-circle px-2' />
						<div className='project-font'>{message}</div>
					</div>
				)
				break
			case ERROR:
				toast.error(
					<div className='d-flex align-items-centerr w-100'>
						<i className='fa fa-times-circle px-2' />
						<div className='project-font'>{message}</div>
					</div>
				)
				break
			default:
				break
		}
	}
}

/**
 * Action to get all providers
 * @param {Function} callback
 */
export const listAllProviders = callback => {
	return async dispatch => {
		try {
			const providersList = await API.get(USER_SERVICES, LIST_PROVIDERS)
			dispatch({
				type: ACTION_TYPES.SET_PROVIDERS_LIST,
				payload: providersList
			})
			callback(true, providersList)
		} catch (error) {
			myLog('providers', error)
			callback(false)
		}
	}
}
/**
 *  Action to get all services
 * @param {Object} body
 * @param {Function} callback
 */
export const listAllServices = (body, callback) => {
	return async dispatch => {
		try {
			const servicesList = await API.post(USER_SERVICES, LIST_SERVICES, { body })
			dispatch({
				type: ACTION_TYPES.SET_SERVICES_LIST,
				payload: servicesList,
			})
			callback(true, servicesList)
		} catch (error) {
			myLog('servicesList', error)
			callback(false)
		}
	}
}
/**
 *  Action to get all regions
 * @param {Object} body
 * @param {Function} callback
 */
export const listAllRegions = (body, callback) => {
	return async dispatch => {
		try {
			const regionList = await API.post(USER_SERVICES, LIST_REGIONS, { body })
			dispatch({
				type: ACTION_TYPES.SET_REGIONS_LIST,
				payload: regionList,
			})
			callback(true, regionList)
		} catch (error) {
			myLog('regionList', error)
			callback(false)
		}
	}
}
/**
 *  Action to get all accounts
 * @param {Object} body
 * @param {Function} callback
 */
export const listAllAccounts = (body, callback) => {
	return async dispatch => {
		try {
			const accountsList = await API.get(USER_SERVICES, LIST_ACCOUNTS, { body })
			dispatch({
				type: ACTION_TYPES.SET_ACCOUNTS_LIST,
				payload: accountsList,
			})
			callback(true, accountsList)
		} catch (error) {
			myLog('accountsList', error)
			callback(false)
		}
	}
}

/**
 *  Action to get all categories
 * @param {Object} body
 * @param {Function} callback
 */
export const listAllCategory = (body, callback) => {
	return async dispatch => {
		try {
			const categoryList = await API.post(USER_SERVICES, LIST_CATEGORIES, {body})
			dispatch({
				type: ACTION_TYPES.SET_CATEGORIES_LIST,
				payload: categoryList,
			})
			callback(true, categoryList)
		} catch (error) {
			myLog('categoryList', error)
			callback(false)
		}
	}
}

/**
 *  Action to get list  event sources
 * @param {Object} body
 * @param {Function} callback
 */
 export const listEventSources = callback => {
	return async dispatch => {
		try {
			const response = await API.get(USER_SERVICES, LIST_EVENT_SOURCES)
			callback(true, response)
		} catch (error) {
			myLog('response', error)
			callback(false)
		}
	}
}

/**
 *  Action to capture-ui-errors
 * @param {Object} body
 * @param {Function} callback
 */
 export const captureUiErrors = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(USER_SERVICES, CAPTURE_UI_ERRORS, { body })
			callback(true, response)
		} catch (error) {
			myLog('response', error)
			callback(false)
		}
	}
}

/**
 *  Action to get all organizations
 * @param {Object} body
 * @param {Function} callback
 */
export const listOrganizations = (body, callback) => {
	return async dispatch => {
		try {
			const organizationList = await API.get(COMMON_SERVICES, LIST_ORGANIZATIONS, { body })			
			callback(true, organizationList)
		} catch (error) {
			myLog('organizationList', error)
			callback(false)
		}
	}
}

/**
*  Action to get all modules
* @param {Object} body
* @param {Function} callback
*/
export const listModules = (body, callback) => {
   return async dispatch => {
	   try {
		   const modulesList = await API.get(COMMON_SERVICES, LIST_MODULES, {body})
		   callback(true, modulesList)
	   } catch (error) {
		   myLog('modulesList', error)
		   callback(false)
	   }
   }
}

/**
 *  Action to get all accounts details
 * @param {Object} body
 * @param {Function} callback
 */
// export const listAllAccountsDetails = (body, callback) => {
// 	return async dispatch => {
// 		try {
// 			const accountsDetailsList = await API.get(USER_SERVICES, LIST_ACCOUNTS_DETAILS, { body })
// 			dispatch({
// 				type: ACTION_TYPES.SET_ACCOUNTS_DETAILS_LIST,
// 				payload: accountsDetailsList,
// 			})
// 			callback(true, accountsDetailsList)
// 		} catch (error) {
// 			myLog('accountsList', error)
// 			callback(false)
// 		}
// 	}
// }


export const listAllAccountsDetails = (body, callback) => {
	return async dispatch => {
		try {
			const accountsDetailsList = await API.post(COMMON_SERVICES, LIST_ACCOUNTS_DETAILS, { body })
			dispatch({
				type: ACTION_TYPES.SET_ACCOUNTS_DETAILS_LIST,
				payload: accountsDetailsList,
			})
			callback(true, accountsDetailsList)
		} catch (error) {
			myLog('accountsList', error)
			callback(false)
		}
	}
}

/**
 *  Action to get user orgaziation (get user details with extrenal Id)
 * @param {Object} body
 * @param {Function} callback
 */
export const getUserOrganization = (body, callback) => {
	return async () => {
		try {
			const getUserOrganization = await API.get(USER_SERVICES, GET_USER_ORGANIZATION, { body })
			callback(true, getUserOrganization)
		} catch (error) {
			// console.log(error)
			myLog('getUserOrganization', error)
			callback(false)
		}
	}
}

/**
 *  Action to get all accounts
 * @param {Object} body
 * @param {Function} callback
 */
export const getSearchMetaResults = (body, callback) => {
	return async dispatch => {
		try {
			const searchResults = await API.post(DETECTORS, GET_SEARCH_META_RESULTS, { body })
			dispatch({
				type: ACTION_TYPES.SET_SEARCH_META_LIST,
				payload: searchResults,
			})
			callback(true)
		} catch (error) {
			myLog('searchResults', error)
			callback(false)
		}
	}
}

export const getResourceSearchMetaResults = (body, callback) => {
	return async dispatch => {
		try {
			const searchResults = await API.post(SYNCHRONIZER, GET_RESOURCE_SEARCH_META_RESULTS, { body })
			dispatch({
				type: ACTION_TYPES.SET_RESOURCE_SEARCH_META_LIST,
				payload: searchResults,
			})
			callback(true)
		} catch (error) {
			myLog('resourceSearchResults', error)
			callback(false)
		}
	}
}

/**
 * Action to get-various-counts-by-account
 * @param {Object} body
 * @param {Function} callback
 */
 export const getVariousCountsByAccount = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(SYNCHRONIZER, GET_VARIOUS_COUNTS_BY_ACCOUNT, { body })
			callback(true, response)
		} catch (error) {
			myLog('response', error)
			callback(false)
		}
	}
}

/**
 *  Action to set favourite query
 * @param {Object} body
 * @param {Function} callback
 */
export const setFavouriteQuery = (body, callback) => {
	return async () => {
		try {
			const response = await API.post(DETECTORS, SET_FAVOURITE_QUERY, { body })
			callback(true, response)
		} catch (error) {
			myLog('setFavouriteQuery', error)
			callback(false)
		}
	}
}

/**
 *  Action to unset favourite query
 * @param {Object} body
 * @param {Function} callback
 */
export const unsetFavouriteQuery = (body, callback) => {
	return async () => {
		try {
			const response = await API.post(DETECTORS, UNSET_FAVOURITE_QUERY, { body })
			callback(true, response)
		} catch (error) {
			myLog('ubsetFavouriteQuery', error)
			callback(false)
		}
	}
}

export const apiToCall = (URL, body, callback) => {
	return async () => {
		try {
			const response = await API.post(COMMON_SERVICES, URL, { body })
			callback(true, response)
		} catch (error) {
			myLog('apiToCall', error)
			callback(false)
		}
	}
}

export const apiToDevCall = (URL, body, callback) => {
	return async () => {
		try {
			const response = await API.post(COMMON_SERVICES, URL, { body })
			callback(true, response)
		} catch (error) {
			myLog('apiToCall', error)
			callback(false)
		}
	}
}

export const DynamicApiToDevCall = (API_END_POINT, apiMethod, URL, body, callback) => {
	return async () => {
		try {
			if(API_END_POINT === "commonServices") {
				API_END_POINT = COMMON_SERVICES
			} else if(API_END_POINT === "AIOPS_AUTOMATION") {
				API_END_POINT = AIOPS_AUTOMATION
			} else if(API_END_POINT === "userServices") {
				API_END_POINT = USER_SERVICES
			} else if(API_END_POINT === "synchronizer") {
				API_END_POINT = SYNCHRONIZER
			} else if(API_END_POINT === "PRICING") {
				API_END_POINT = PRICING_END_POINT
			} 
			// console.log("API_END_POINT", API_END_POINT)
			if(URL === "/database/list/listvmtypes") {
				let mergeUrl = API_END_POINT + URL;
				let response = await axios.post(mergeUrl, body, {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}`
					}
				})
				callback(true, response.data ? response.data : [])
			} else {
				if(apiMethod === "get") {
					let response = await API.get(API_END_POINT, URL)
					callback(true, response)
				} else {
					let splitURL = URL.split("/")
					let response = ""
					if(splitURL.includes("listvmtypes")) {
						let mergeUrl = API_END_POINT + URL;
						// console.log("mergeUrl", mergeUrl)
						response = await axios.post(mergeUrl, body, {
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}`
							}
						})
						callback(true, response)
					} else {
						// console.log("visited post", API_END_POINT, URL, body)
						response = await API.post(API_END_POINT, URL, { body }) 
					}
					callback(true, response)
				}
			}
		} catch (error) {
			myLog('apiToCall', error)
			callback(false, {})
		}
	}
}

export const getAllTags = (body, callback) => {
	return async () => {
		try {
			const response = await API.post(TAGGING, GET_ALL_TAGS, { body })
			//let aggreateBy = body.aggregate_by
			//const covertToMapObject = await convert_to_map(searchInputs, totalAssetCount)
			callback(true, response)
		} catch (error) {
			// console.log(error)
			myLog('getTotalAssetCount', error)
			callback(false)
		}
	}
}

export const getAllTagsKeys = (body, callback) => {	
	return async dispatch => {
		try {
			const response = await API.post(TAGGING, GET_ALL_TAGS_KEYS, { body })
			dispatch({
				type: ACTION_TYPES.SET_TAG_KEYS,
				payload: response,
			})
			callback(true, response)
		} catch (error) {
			myLog('response', error)
			callback(false)
		}
	}
}

export const getAllTagsValues = (body, callback) => {
	return async () => {
		try {
			const response = await API.post(TAGGING, GET_ALL_TAGS_VALUES, { body })
			//let aggreateBy = body.aggregate_by
			//const covertToMapObject = await convert_to_map(searchInputs, totalAssetCount)
			callback(true, response)
		} catch (error) {
			// console.log(error)
			myLog('getTotalAssetCount', error)
			callback(false)
		}
	}
}

/**
 *  Action to get user profile
 * @param {Object} body
 * @param {Function} callback
 */
export const getUserProfile = callback => {
	return async dispatch => {
		try {
			const profileDetails = await API.get(PROFILE, GET_USER_PROFILE)
			dispatch({
				type: ACTION_TYPES.SET_PROFILE_DETAILS,
				payload: profileDetails,
			})
			callback(true, profileDetails)
		} catch (error) {
			myLog('profileDetails', error)
			callback(false)
		}
	}
}

/**
 *  Action to get user meu
 * @param {Object} body
 * @param {Function} callback
 * @author prakash // on 18/01/2022
 */
export const getUserMenu = callback => {
	return async dispatch => {
		try {
			const userMenuDetails = await API.get(PROFILE, GET_USER_MENU)
			dispatch({
				type: ACTION_TYPES.SET_USER_MENU,
				payload: userMenuDetails,
			})
			callback(true, userMenuDetails)
		} catch (error) {
			myLog('userMenuDetails', error)
			callback(false)
		}
	}
}
/**
 * Action to set filters props details
 */
 export const setAutomationPropsDetails = (value) => {
	return async (dispatch, getState) => {
		let automationPropsDetails = JSON.parse(JSON.stringify(getState().filters.automationPropsDetails))
		automationPropsDetails = value
		dispatch({
			type: ACTION_TYPES.SET_AUTOMATION_PROPS_DETAILS,
			payload: automationPropsDetails,
		})
	}
}

/**
 *  Action to get-collected-data
 * @param {Object} body
 * @param {Function} callback
 * @author prakash // on 18/01/2022
 */
 export const getCollectedData = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(ISSUEDUMP, GET_COLLECTED_DATA, { body })
			callback(true, response)
		} catch (error) {
			myLog('getCollectedData', error)
			callback(false)
		}
	}
}


/**
 *  Action to list-test-details
 * @param {Object} body
 * @param {Function} callback
 * @author prakash // on 18/01/2022
 */
 export const listTestDetails = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(ISSUEDUMP, LIST_TEST_DETAILS, { body })
			callback(true, response)
		} catch (error) {
			myLog('getCollectedData', error)
			callback(false)
		}
	}
}