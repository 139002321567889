/*************************************************
 * Tvastar
 * @exports
 * @file registerAction.js
 * @author Rajasekar // on 23/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
// import cognitoService from '../services/cognitoService'
// import { myLog } from '../utils/utility'
import { API } from 'aws-amplify'

import {
	USER_SIGNUP,
	SIGNUP
} from '../config'


/**
 * Action to signup
 * @param {Object} body
 * @param {Function} callback
 */
export const signup = (body, callback) => {
	return async () => {
		await API.post(USER_SIGNUP, SIGNUP, { body })
		.then((response) => {
			console.log(response);
			callback(true, response)
		}).catch((err) => {
			console.log('register',err)
			// console.log(err.response.data.error); // you can get the response like this
			// console.log(err.response.data.status); // status code of the request
			callback(false)
			// let message = err.response.data.error
			// callback(false, message)
		})
	}
}

// /**
//  * Create User using amplify
//  * @param {Object} formValues
//  */
// export const register = (formValues, callback) => {
// 	return async () => {
// 		try {
// 			const response = await cognitoService.createUser(formValues)
// 			if ('user' in response && 'userConfirmed' in response && 'userSub' in response) {
// 				callback(true)
// 			} else {
// 				callback(false)
// 			}
// 			myLog('---register---response---', response)
// 		} catch (error) {
// 			callback(false)
// 		}
// 	}
// }

/**
 * Create User using amplify
 * @param {Object} formValues
 */
// export const register = (formValues, callback) => {
// 	return async () => {
// 		try {
// 			const response = await cognitoService.createUser(formValues)
// 			if ('user' in response && 'userConfirmed' in response && 'userSub' in response) {
// 				callback(true)
// 			} else {
// 				callback(false)
// 			}
// 			myLog('---register---response---', response)
// 		} catch (error) {
// 			callback(false)
// 		}
// 	}
// }
