/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from 'react'
import { AppWrapper } from '../../common/AppWrapper'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { listAllProviders, listAllAccounts, listAllRegions, listAllServices } from '../../../actions/commonAction'
import {
	setGovernanceFilter,
	getAssetsByTagOperations,
	getNonComplaintAssets,
	getTagComplianceAggregationCount,
	getTagComplianceTotalCount,
	getTagsByOperationCount,
	getAppliedTagsDayWiseCount,
	getAllTags,
} from '../../../actions/governance/governanceAction'
import { setSelectedFilter } from '../../../actions/assets/assetsAction'
import { CustomInput } from 'reactstrap'
import { URL_PATH } from '../../../config/urlPath'
import { Link } from 'react-router-dom'
import Select from 'react-select'

class TaggingDashboard extends Component {
	constructor(props) {
		super(props)
		this.state = {
			providers: [],
			accounts: [],
			regions: [],
			services: [],
			selectedProvider: 'All',
			selectedAccount: 'All',
			selectedAccountName: 'All',
			selectedRegion: '',
			selectedService: '',
			selectedStatus: 'fail',
			selectedDuration: '+7d',
			selectedAppliedOperation: 'Project',
			selectedTags: [],
		}
	}

	componentDidMount = () => {
		this.getAllCounts()
		this.setState({ showLoading: true })
		this.props.listAllProviders((promise, providers) => {
			if (promise) {
				if (providers.length) {
					this.setState({ selectedProvider: providers[0].provider_name },
						this.onSelectFilter('provider')
					)
					let dataRow = {}
					dataRow.provider_id = "All"
					dataRow.provider_name = "All"
					providers.unshift(dataRow);
				}
				this.setState({ providers })
			} else {
				this.setState({ providers: [] })
			}
		})
	}

	onSelectFilter = from => {
		this.setState({ showLoading: true })
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'All') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if (this.state.selectedRegion !== '' && this.state.selectedRegion !== 'all') {
			params.region = this.state.selectedRegion
		}
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'All') {
			params.account_id = this.state.selectedAccount
		}
		if (this.state.selectedService !== '' && this.state.selectedService !== 'all') {
			params.service_name = this.state.selectedService
		}
		if (this.state.selectedStatus !== '' && this.state.selectedStatus !== 'all') {
			params.status = this.state.selectedStatus
		}
		if (this.state.selectedRisk !== '' && this.state.selectedRisk !== 'all') {
			params.risk = this.state.selectedRisk
		}
		if (this.state.selectedTags && this.state.selectedTags.length) {
			params.tags = this.state.selectedTags
		}
		if (from === 'provider') {
			delete params['account_id']
			delete params['region']
			delete params['service_name']
			delete params['provider']
			delete params['risk']
			this.props.listAllAccounts(params, (promise, accounts) => {
				if (promise) {
					if (accounts.length) {
						let accRow = {}
						accRow.account_id = "All"
						accRow.account_name = "All"
						accRow.date = null
						accRow.disabled = false
						accRow.provider = "All"
						accounts.unshift(accRow);
					}
					console.log(accounts)
					this.setState({ accounts, selectedAccount: 'All', selectedRegion: '', selectedService: '' })
				} else {
					this.setState({ accounts: [] })
				}
			})

			if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'All') {
				params.provider = this.state.selectedProvider.toLowerCase()
			}
			delete params['status']
			this.props.listAllRegions(params, (promise, regions) => {
				if (promise) {
					this.setState({ regions: regions[0].provider_regions })
				} else {
					this.setState({ regions: [] })
				}
			})
			this.props.listAllServices(params, (promise, services) => {
				if (promise) {
					this.setState({ services })
				} else {
					this.setState({ services: [] })
				}
			})
		}
		this.getAllCounts()
		delete params['service_name']
		delete params['risk']
		delete params['status']
		delete params['tags']
		this.props.getAllTags(params)
	}

	getTotalPassCount = () => {
		let count = 0
		if (this.props.tagging.nonComplaintAssets && this.props.tagging.nonComplaintAssets.length) {
			this.props.tagging.nonComplaintAssets.forEach(item => {
				count += item.pass_count
			})
		}
		return count
	}
	getTotalFailCount = () => {
		let count = 0
		if (this.props.tagging.nonComplaintAssets && this.props.tagging.nonComplaintAssets.length) {
			this.props.tagging.nonComplaintAssets.forEach(item => {
				count += item.fail_count
			})
		}
		return count
	}

	getOperationCount = from => {
		let count = 0
		if (this.props.tagging.operationCount && this.props.tagging.operationCount.data) {
			this.props.tagging.operationCount.data[from].forEach(item => {
				count += item
			})
		}
		return count
	}

	getAppliedTagsDayWiseCount = from => {
		let count = 0
		if (this.props.tagging.appliedTagsDayWiseCount && this.props.tagging.appliedTagsDayWiseCount.data) {
			this.props.tagging.appliedTagsDayWiseCount.data[from].forEach(item => {
				count += item
			})
		}
		return count
	}

	getAllCounts = () => {
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'All') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if (this.state.selectedDuration !== '' && this.state.selectedDuration !== 'all') {
			params.duration = this.state.selectedDuration
		}
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'All') {
			params.account_id = this.state.selectedAccount
		}
		if (this.state.selectedRegion !== '' && this.state.selectedRegion !== 'all') {
			params.region = this.state.selectedRegion
		}
		if (this.state.selectedService !== '' && this.state.selectedService !== 'all') {
			params.service_name = this.state.selectedService
		}
		params.aggregate_by = 'service_name'
		params.size = 5
		this.props.getAssetsByTagOperations(params)
		delete params['aggregate_by']
		delete params['size']
		params.category = 'Tagging'
		this.props.getNonComplaintAssets(params)
		delete params['category']
		this.props.getTagComplianceTotalCount(params)
		this.props.getTagsByOperationCount(params)
		this.getAggregationCount()
	}

	getAggregationCount = () => {
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'All') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if (this.state.selectedDuration !== '' && this.state.selectedDuration !== 'all') {
			params.duration = this.state.selectedDuration
		}
		if (this.state.selectedAccount !== '' && this.state.selectedAccount !== 'All') {
			params.account_id = this.state.selectedAccount
		}
		if (this.state.selectedRegion !== '' && this.state.selectedRegion !== 'all') {
			params.region = this.state.selectedRegion
		}
		if (this.state.selectedService !== '' && this.state.selectedService !== 'all') {
			params.service_name = this.state.selectedService
		}
		params.aggregate_by = 'account_id'
		params.tags = [{ key: this.state.selectedAppliedOperation }]
		this.props.getTagComplianceAggregationCount(params)
	}

	render() {
		return (
			<div className='container-fluid overflow-auto flex-grow-1'>
				<div className='row h-100'>
					<div className='col-lg-12 p-0'>
						<div className='title-section p-3'>
							<div className='row'>
								<div className='col-8 align-self-center'>
									<h6 className='text-white m-0'>Tagging</h6>
								</div>
								<div className='col-4 text-right'>
									<button
										onClick={() => this.props.history.push(URL_PATH.GOVERNANCE_TAG_GROUPS)}
										className='btn btn-primary'
									>
										Manage Tag Groups
									</button>
								</div>
							</div>
							<div className='row mt-3'>
								<div className={`col-sm d-md-flex bd-highlight`}>
									<div className='form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3'>
										<label>Provider</label>
										<Select
											placeholder={'All'}
											//menuIsOpen={this.state.providerIsOpen}
											isSearchable={false}
											//onFocus={this.openProivderMenu}
											className='f13 p-0 colorBlack reactSelectDrop'
											value={({
												value: this.state.selectedProvider,
												label: this.state.selectedProvider
											})}
											options={this.state.providers.map(item => ({
												value: item.provider_name,
												label: item.provider_name,	
											}))}
											onChange={event =>  
												this.setState(
													{
														selectedProvider: event.value,
														showLoading: true
													},
													() => this.onSelectFilter('provider')
												)
											}
										/>
									</div>
									<div className='form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3'>
										<label>Account</label>
										<Select
											placeholder={'All'}
											//menuIsOpen={this.state.providerIsOpen}
											isSearchable={false}
											//onFocus={this.openProivderMenu}
											className='f13 p-0 colorBlack reactSelectDrop'
											value={({
												value: this.state.selectedAccount,
												label: this.state.selectedAccountName
											})}
											options={this.state.accounts.map(item => ({
												value: item.account_id,
												label: item.account_name,	
											}))}
											onChange={event =>  
												this.setState(
													{
														selectedAccount: event.value,
														selectedAccountName: event.label
													},
													() => this.onSelectFilter('account')
												)
											}
										/>
									</div>
									<div className='form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3'>
										<label>Region</label>
										<select
											className='form-control'
											value={this.state.selectedRegion}
											onChange={event => {
												this.setState(
													{
														selectedRegion: event.target.value,
													},
													() => this.onSelectFilter('regions')
												)
											}}
										>
											<option value='all'>All</option>
											{this.state.regions &&
												this.state.regions.length &&
												this.state.regions.map((region, i) => {
													return (
														<option key={i} value={region.region}>
															{region.name}
														</option>
													)
												})}
										</select>
									</div>
									<div className='form-group flex-fill bd-highlight mb-sm-0 mr-sm-3 styled-feild'>
										<label>Service</label>
										<select
											className='form-control'
											value={this.state.selectedService}
											onChange={event => {
												this.setState(
													{
														selectedService: event.target.value,
													},
													() => this.onSelectFilter()
												)
											}}
										>
											<option value='all'>All</option>
											{this.state.services &&
												this.state.services.length &&
												_.chain(this.state.services)
													.groupBy('resource_type')
													.map((value, key) => ({ label: key, sub: value }))
													.value()
													.map((service, i) => {
														return (
															<optgroup key={i} label={service.label}>
																{service.sub.map((item, i) => {
																	return (
																		<option key={i} value={service.service_name}>
																			{item.service_name}
																		</option>
																	)
																})}
															</optgroup>
														)
													})}
										</select>
									</div>
									<div className='form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3'>
										<label>Status</label>
										<select
											className='form-control'
											value={this.state.selectedStatus}
											onChange={event => {
												this.setState(
													{
														selectedStatus: event.target.value,
													},
													() => this.onSelectFilter('status')
												)
											}}
										>
											<option value='all'>All</option>
											<option value='pass'>Pass</option>
											<option value='fail'>Fail</option>
										</select>
									</div>
									<div className='form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3'>
										<label>Duration</label>
										<select
											className='form-control'
											value={this.state.selectedDuration}
											onChange={event => {
												this.setState(
													{
														selectedDuration: event.target.value,
													},
													() => this.onSelectFilter('duration')
												)
											}}
										>
											<option value='7d'>This Week</option>
											<option value='+7d'>Last 1 week</option>
											<option value='+14d'>Last 2 weeks</option>
											<option value='+30d'>Last 1 month</option>
											<option value='+60d'>Last 2 months</option>
											<option value='+90d'>Last 3 months</option>
										</select>
									</div>
									<div className='form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 w-25'>
										<label>Tags</label>
										{/* <select
											className='form-control'
											value={this.state.selectedDuration}
											onChange={event => {
												let temp = this.state.selectedTags
												temp.push(event.target.value)
												this.setState(
													{
														selectedTags: temp,
													},
													() => this.onSelectFilter('duration')
												)
											}}
										>
											<option value='all'>All</option>
											{this.props.tags.length &&
												this.props.tags.map((item, i) => {
													return (
														<option key={i} value={item.tags.value}>
															{item.tags.value}
														</option>
													)
												})}
										</select> */}
										<Select
											onChange={arr => {
												this.setState(
													{
														selectedTags: arr ? arr : [],
													},
													() => this.onSelectFilter('tags')
												)
											}}
											getOptionValue={opt => opt}
											getOptionLabel={opt => `${opt.key}: ${opt.value}`}
											options={this.props.tags.map(({ tags }) => ({
												key: tags.key,
												value: tags.value,
												label: tags.key + +tags.value,
												style: { color: 'black' },
											}))}
											value={this.state.selectedTags.map(tag => ({
												key: tag.key,
												value: tag.value,
												label: tag.key + ' : ' + tag.value,
											}))}
											isMulti
											classNamePrefix='Select Tags'
											className='basic-multi-select text-dark'
											id='basic-multi-select'
										/>
										{/* <Select
											filterOption={null}
											onChange={arr => {
												this.setState(
													{
														selectedTags: arr,
													},
													() => this.onSelectFilter('tags')
												)
											}}
											options={this.props.tags.map(({ tags }) => ({
												value: tags.value,
												label: tags.value,
											}))}
											value={this.state.selectedTags.map(item => ({
												value: item,
												label: item,
											}))}
											isMulti
											classNamePrefix='Select Tags'
											className='basic-multi-select'
										/> */}
									</div>
								</div>
							</div>
						</div>
						<div className='container-fluid mt-3'>
							<div className='row'>
								<div className='col-lg-9'>
									<div className='row'>
										<div className='col-12'>
											<div className='d-flex bd-highlight'>
												<div className='box flex-fill bd-highlight p-3 mr-3'>
													<h5 className='text-right'>Pass</h5>
													<h1 className='mb-0 font-weight-light display-4 text-success'>{this.getTotalPassCount()}</h1>
													<p className='my-0 text-success'>Check</p>
												</div>
												<div className='box flex-fill bd-highlight p-3 mr-3'>
													<h5 className='text-right'>Fail</h5>
													<h1 className='mb-0 font-weight-light display-4 text-danger'>{this.getTotalFailCount()}</h1>
													<p className='my-0 text-danger'>Check</p>
												</div>
												<div className='box flex-fill bd-highlight p-3 mr-3'>
													<h5 className='text-right'>Applied</h5>
													<h1 className='mb-0 font-weight-light display-4 text-info'>
														{this.props.tagging.complianceTotalCount &&
														this.props.tagging.complianceTotalCount.total_count
															? this.props.tagging.complianceTotalCount.total_count
															: 0}
													</h1>
													<p className='my-0 text-info'>Check</p>
												</div>
												<div className='box flex-fill bd-highlight p-3 mr-3'>
													<h5 className='text-right'>Tagging</h5>
													<h1 className='mb-0 font-weight-light display-4 text-info'>
														{this.props.tagging.complianceTotalCount &&
														this.props.tagging.complianceTotalCount.tagged_count
															? this.props.tagging.complianceTotalCount.tagged_count
															: 0}
													</h1>
													<p className='my-0 text-info'>Check</p>
												</div>
												<div className='box flex-fill bd-highlight p-3'>
													<h5 className='text-right'>Un Tagging</h5>
													<h1 className='mb-0 font-weight-light display-4 text-info'>
														{this.props.tagging.complianceTotalCount &&
														this.props.tagging.complianceTotalCount.not_tagged_count
															? this.props.tagging.complianceTotalCount.not_tagged_count
															: 0}
													</h1>
													<p className='my-0 text-info'>Check</p>
												</div>
											</div>
										</div>
									</div>
									<div className='row mt-3'>
										<div className='col-lg-4'>
											<div className='d-flex justify-content-between'>
												<h6>Tagging Detectors</h6>
												<Link
													className='align-self-center'
													to={{
														pathname: URL_PATH.CHECKS_SEARCH,
														state: { dashboardFilters: { ...this.state, category: ['Tagging'] } },
													}}
												>
													Details
												</Link>
											</div>
											<small>Count</small>
											<div className='table-responsive mt-2'>
												<table className='table table-sm table-hover box'>
													<thead>
														<tr className='bg-primary'>
															<th>Title</th>
															<th>Count</th>
														</tr>
													</thead>
													<tbody>
														{this.props.tagging.nonComplaintAssets && this.props.tagging.nonComplaintAssets.length
															? this.props.tagging.nonComplaintAssets.map((item, i) => {
																	return (
																		<tr key={i}>
																			<td>{item.group_name}</td>
																			<td className='d-flex justify-content-between'>
																				<span className='text-success'>{item.pass_count}</span>
																				<span className='text-danger'>{item.fail_count}</span>
																			</td>
																		</tr>
																	)
															  })
															: null}
													</tbody>
												</table>
											</div>
										</div>
										<div className='col-lg-4'>
											<div className='d-flex justify-content-between'>
												<h6>Applied Operations</h6>
												<Link
													className='align-self-center'
													to={{
														pathname: URL_PATH.GOVERNANCE_APPLIED_ASSETS,
														state: { dashboardFilters: { ...this.state } },
													}}
												>
													Details
												</Link>
											</div>
											<div className='d-flex justify-content-between'>
												<small>Count</small>
												<div className='input-group-sm'>
													<CustomInput
														type='select'
														className='form-control'
														value={this.state.selectedAppliedOperation}
														onChange={e => {
															this.setState({ selectedAppliedOperation: e.target.value }, () =>
																this.getAggregationCount()
															)
														}}
													>
														<option>Project</option>
														<option>Environment</option>
														<option>Cost Center</option>
													</CustomInput>
												</div>
											</div>
											<div className='table-responsive mt-2'>
												<table className='table table-sm table-hover rounded box'>
													<thead>
														<tr className='bg-primary'>
															<th>Name</th>
															<th>Total</th>
															<th>Tagged</th>
															<th>UnTagged</th>
														</tr>
													</thead>
													<tbody>
														{this.props.tagging.tagComplianceAggregationCount &&
														this.props.tagging.tagComplianceAggregationCount.length
															? this.props.tagging.tagComplianceAggregationCount.map((item, i) => {
																	return (
																		<tr key={i}>
																			<td>{item.tags && item.tags.value}</td>
																			<td>{item.total_count}</td>
																			<td>{item.tagged_count}</td>
																			<td>{item.not_tagged_count}</td>
																		</tr>
																	)
															  })
															: null}
													</tbody>
												</table>
											</div>
										</div>
										<div className='col-lg-4'>
											<div className='d-flex justify-content-between'>
												<h6>Top 10 Services</h6>
												<button
													className='align-self-center btn btn-link p-0 btn-sm'
													onClick={() => {
														this.props.setSelectedFilter('duration', this.state.selectedDuration)
														this.props.setSelectedFilter('service', this.state.selectedService)
														this.props.setSelectedFilter('account', this.state.selectedAccount)
														this.props.setSelectedFilter('provider', this.state.selectedProvider)
														this.props.setSelectedFilter('region', this.state.selectedRegion)
														this.props.history.push(URL_PATH.RESOURCES_SEARCH)
													}}
												>
													Details
												</button>
											</div>
											<small>Tag Operations</small>
											<div className='table-responsive mt-2'>
												<table className='table table-sm table-hover rounded box'>
													<thead>
														<tr className='bg-primary'>
															<th>Service</th>
															<th>Created</th>
															<th>Modified</th>
															<th>Deleted</th>
														</tr>
													</thead>
													<tbody>
														{this.props.tagging.assetsByTagOperations &&
															this.props.tagging.assetsByTagOperations.length &&
															this.props.tagging.assetsByTagOperations.map((item, i) => {
																return (
																	<tr key={i}>
																		<td>{item.service_name}</td>
																		<td>{item.created && item.created.count}</td>
																		<td>{item.modified && item.modified.count}</td>
																		<td>{item.deleted && item.deleted.count}</td>
																	</tr>
																)
															})}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
								<div className='col-lg-3'>
									<div className='box'>
										<div className='d-flex justify-content-between px-3 pt-2'>
											<h6>Tagging Operations</h6>
											<button className='align-self-center btn btn-link p-0 btn-sm'>Details</button>
										</div>
										<div className='border-bottom px-3 pt-0 pb-1'>
											<h1 className='mb-0 font-weight-light'>{this.getOperationCount('created')}</h1>
											<p className='my-0'>Created</p>
										</div>
										<div className='border-bottom px-3 py-1'>
											<h1 className='mb-0 font-weight-light'>{this.getOperationCount('modified')}</h1>
											<p className='my-0'>Modified</p>
										</div>
										<div className='border-bottom px-3 py-1'>
											<h1 className='mb-0 font-weight-light'>{this.getOperationCount('deleted')}</h1>
											<p className='my-0'>Deleted</p>
										</div>
										<div className='border-bottom px-3 py-1'>
											<h1 className='mb-0 font-weight-light'>{this.getAppliedTagsDayWiseCount('success')}</h1>
											<p className='my-0'>Applied Tags-Success</p>
										</div>
										<div className='border-bottom px-3 pt-1 pb-3'>
											<h1 className='mb-0 font-weight-light'>{this.getAppliedTagsDayWiseCount('failed')}</h1>
											<p className='my-0'>Applied Tags-Failed</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
TaggingDashboard.propTypes = {
	listAllProviders: PropTypes.func,
	listAllAccounts: PropTypes.func,
	listAllRegions: PropTypes.func,
	listAllServices: PropTypes.func,
	getAssetsByTagOperations: PropTypes.func,
	getNonComplaintAssets: PropTypes.func,
	getTagComplianceAggregationCount: PropTypes.func,
	getTagComplianceTotalCount: PropTypes.func,
	getTagsByOperationCount: PropTypes.func,
	tags: PropTypes.array,
	setSelectedFilter: PropTypes.func,
	getAppliedTagsDayWiseCount: PropTypes.func,
	getAllTags: PropTypes.func,
	tagging: PropTypes.object,
	history: PropTypes.object,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		providers: state.filters.providers,
		accounts: state.filters.accounts,
		services: state.filters.services,
		selectedFilters: state.governance.selectedFilters,
		tagging: state.governance.tagging,
		tags: state.governance.tags,
	}
}

export default AppWrapper(TaggingDashboard, mapStateToProps, {
	setGovernanceFilter,
	listAllProviders,
	getNonComplaintAssets,
	listAllAccounts,
	listAllServices,
	getAssetsByTagOperations,
	getTagComplianceAggregationCount,
	listAllRegions,
	getTagComplianceTotalCount,
	getTagsByOperationCount,
	setSelectedFilter,
	getAppliedTagsDayWiseCount,
	getAllTags,
})
