/*************************************************
 * Tvastar
 * @exports
 * @file index.js
 * @author Rajasekar // on 19/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import reduxThunk from 'redux-thunk'

import reducers from './reducers'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import { isDevelopment } from './config/index'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
	reducers,
	isDevelopment ? composeEnhancers(applyMiddleware(reduxThunk)) : applyMiddleware(reduxThunk)
)

ReactDOM.render(
	<Provider store={store}>
		<ToastContainer
			position='top-right'
			autoClose={3000}
			hideProgressBar
			newestOnTop={false}
			closeOnClick
			rtl={false}
			closeButton={false}
			pauseOnVisibilityChange
			draggable
			pauseOnHover
		/>
		<ReactNotification />
		<App />
	</Provider>,
	document.querySelector('#root')
)

serviceWorker.unregister()
