/*************************************************
 * Tvastar
 * @exports
 * @file RootCauseAction.js
 * @author Prakash // on 10/11/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/

import { API } from 'aws-amplify'

import {
	AIOPS_SERVICE,
	LIST_RCA_DETAILS,
	GET_RCA_DETAILS,

} from '../../config'

import { ACTION_TYPES } from '../types'

/**
 * Action to list rca details
 * @param {Object} body
 * @param {Function} callback
 */
 export const listRcaDetails = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(AIOPS_SERVICE, LIST_RCA_DETAILS, { body })
			dispatch({
				type: ACTION_TYPES.SET_AIOPS_RCA_LIST,
				payload: result,
			})
			callback(true, result)
		} catch(err) {
			// let message = ''
			callback(false, {})
		}
    }
}

/**
 * Action to get Rca Details
 * @param {Object} body
 * @param {Function} callback
 */
 export const getRcaDetails = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(AIOPS_SERVICE, GET_RCA_DETAILS, { body })
			dispatch({
				type: ACTION_TYPES.SET_AIOPS_RCA_DETAILS,
				payload: result,
			})
			callback(true, result)
		} catch(err) {
			// let message = ''
			callback(false, {})
		}
    }
}