/*************************************************
 * Tvastar
 * @exports
 * @file Substitution.js
 * @author Prakash // on 13/09/2021
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
 import React, { Component } from 'react'
 import { connect } from 'react-redux'
 import { withRouter } from 'react-router-dom'
 import Select from 'react-select'

 class Substitution extends Component {
	hierarichalBucketBarChartRef = React.createRef()
	constructor(props) {
		super(props)
		this.props = props;
		this.state = {
			showLoading: false,
            case_sensitive: true,
            value: 'xxx',
            keyValueArray:[0],

			yesNoOptions: ['Yes', 'No'],
			watermark: 'No',
		}
	}

	componentDidMount = () => {
        this.editEvent()
	}

    componentDidUpdate = (prevProps) => {
        if(this.props.dlpPropsDetails && typeof this.props.dlpPropsDetails.hasPrivacyPolicyError !== 'undefined' && (prevProps.dlpPropsDetails.hasPrivacyPolicyError !== this.props.dlpPropsDetails.hasPrivacyPolicyError || (!this.state.hasError && (this.state.hasError !== this.props.dlpPropsDetails.hasPrivacyPolicyError)))) {
            this.setState({ hasError: this.props.dlpPropsDetails.hasPrivacyPolicyError })
        }
    }

    editEvent = () => {
        if(this.props.pageType === 'edit' || this.props.pageType === 'view') {
			let policyDetails = this.props.policyDetails
            let deidentification_type = policyDetails.config.deidentification_type
            let case_sensitive = policyDetails.config.case_sensitive ? policyDetails.config.case_sensitive : ''            

            let value = policyDetails.config.value ? policyDetails.config.value : "xxx"
            let watermark_id = policyDetails.config.watermark_id ? policyDetails.config.watermark_id : ''
            let watermark_name = policyDetails.config.watermark_name ? policyDetails.config.watermark_name : ''
            let watermark_type = policyDetails.config.watermark_type ? policyDetails.config.watermark_type : ''
            
            let key = policyDetails.config.key
            let keyValueArray = []
            if(key) {
                policyDetails.config.key_value && Object.entries(policyDetails.config.key_value).forEach(([key, value], i) => {
                    keyValueArray.push(i)
                    this.setState({ ['searchFor_'+i]: value, ['replaceWith_'+i]: key })
                })
            } else {
                keyValueArray = [0]
            }

            this.setState({ deidentification_type, updated_deidentification_type: deidentification_type, case_sensitive, value, key, keyValueArray, watermark: watermark_id !== '' ? 'Yes' : 'No', watermark_id, watermark_name, watermark_type }, () => this.onChangePropsEvent())
        }
    }

    addSection = () => {
        let rowList = this.state.keyValueArray;
        if(this.state.keyValueArray) {
            let value = this.state.keyValueArray[this.state.keyValueArray.length - 1]
            value = value+1
            rowList.push(value);
        }
        
        this.setState({ keyValueArray: rowList })
    }

    removeSection = (i) => {
        this.setState({ ['searchFor_'+i]: [], ['text_'+i]: '', ['replaceWith_'+i]: '' },
            () => {
                let rowList = this.state.keyValueArray;
                rowList.splice(i, 1);
                this.setState({ keyValueArray: rowList }, () => this.onChangePropsEvent())
            }
        );
    }

    onChangePropsEvent = () => {
		let errorMessage = ''
		if(!this.state.deidentification_type || this.state.deidentification_type === '') {
			errorMessage = 'Please select react using'
		}
        
        if(this.state.watermark === 'yes') {
            if(!this.state.watermark_id) {
                errorMessage = 'Please select watermark'
            }
            if(!this.state.watermark_type) {
                errorMessage = 'Please select watermark type'
            }
        }

        let data = {}
        data.deidentification_type = this.state.deidentification_type ? this.state.deidentification_type : ''
        data.case_sensitive = this.state.case_sensitive 

        let selectedText = ' Substitution type '+this.state.deidentification_label+' case sensitive '+(this.state.case_sensitive ? 'true' : 'false')
        if(this.state.key) {
            data.key = true
            selectedText += ' key value true'

            let dataKey = {}
            this.state.keyValueArray.forEach(item => {
                let dataRow = {}
                let search_for = this.state['searchFor_'+item] ? this.state['searchFor_'+item] : []
                let replace_with = this.state['replaceWith_'+item] ? this.state['replaceWith_'+item] : ''

                if(search_for.length && replace_with !=='') {
                    dataRow[replace_with] = search_for
                    dataKey = Object.assign(dataKey, dataRow)
                } else if(!search_for.length && replace_with !== '') {
                    errorMessage = 'Please enter search'
                } else if(search_for.length && replace_with === '') {
                    errorMessage = 'Please enter replace'
                }
            })
            if(Object.entries(dataKey).length) {
                data.key_value = dataKey
            } else {
                errorMessage = 'Please enter value for search and replace'
            }
        } else {
            data.value = this.state.value ? this.state.value : ''
            selectedText += ' value '+data.value
            if(data.value === '') {
                errorMessage = 'Please enter value'
            }
        }

        if(this.props.watermarkTypeArray[this.state.deidentification_type] && this.props.watermarkTypeArray[this.state.deidentification_type].length) {
            if(this.state.watermark === 'Yes') {
                data.watermark_type = this.state.watermark_type
                let filter = this.props.watermarkTypeArray[this.state.deidentification_type].filter(arr => arr.watermark_type === this.state.watermark_type)
                if(filter.length) {
                    Object.entries(filter[0]).forEach(([key, value]) => {
                        data[key] = value
                    })
                }

                data.watermark_id = this.state.watermark_id
                data.watermark_name = this.state.watermark_name
                selectedText += ' watermark type '+this.state.watermark_type
                selectedText += ' with watermark '+this.state.watermark_name
            }
        }
        

        this.props.selectedData(data, selectedText, errorMessage)
    }

    onKeyDown = (e, item) => {
        let searchFor = this.state['searchFor_'+item] ? this.state['searchFor_'+item] : []
		if (e.keyCode === 13 || e.keyCode === 9) {
            if(this.state['text_'+item] !== '') {
                searchFor.push(this.state['text_'+item].trim())
                if(e.keyCode === 9) {
                    e.preventDefault();
                }
                this.setState({ ['searchFor_'+item]: searchFor, ['text_'+item]: '' }, () => this.onChangePropsEvent())
            }
        }
    }

    removeSelectedOption = (value, index, item) => {
		let filteredReslt = this.state['searchFor_'+item]
        filteredReslt = filteredReslt.filter(e => e !== value)
        // filteredReslt = filteredReslt.splice(index, 1)
        // let splice = filteredReslt.splice(index, 1)
		this.setState({ ['searchFor_'+item]: filteredReslt })
	}

    getSelectLabel = (item, list) => {
        let filter = list.filter(arr => arr.value === item)
        if(filter.length) {
            this.setState({ deidentification_label: filter[0].label })
        } else {
            this.setState({ deidentification_label: '', deidentification_type: '' })
        }
    }

    onChangeType = () => {
        if(this.state.updated_deidentification_type && this.state.deidentification_type === this.state.updated_deidentification_type) {
            this.editEvent()
        } else {
            this.onChangePropsEvent()
        }
    }

    render() {		
		return (
            <div className={`form-group dlpDescriptionForm mt-3`}>
                <div className="d-flex flex-wrap">
                    <p className="mb-0 mr-2 align-self-end text-purple mt-3">Type</p>
                    <Select
                        // menuIsOpen={true}
                        placeholder={'Select'}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        isSearchable={false}
                        className={`selectOption align-self-end mx-2 ${this.state.hasError && (!this.state.deidentification_type || this.state.deidentification_type === '') ? 'dottedDangerSelectLine' : ''}`}
                        value={({
                            value: this.state.deidentification_type ? this.state.deidentification_type : 'Select',
                            label: this.state.deidentification_type ? (this.state.deidentification_label ? this.state.deidentification_label : this.getSelectLabel(this.state.deidentification_type, this.props.list)) : 'Select'
                        })}
                        options={this.props.list && this.props.list.map(item => ({
                            value: item.value,
                            label: item.label,
                        }))}
                        onChange={event => 
                            this.setState({ deidentification_type: event.value, deidentification_label: event.label, watermark_name: '', watermark_type: '', watermark_id: '' }, 
                                () => this.onChangeType()
                            )
                        }
                    />
                    
                    {this.state.deidentification_type === 'replace' ?
                        <React.Fragment>
                            <div style={{ display: "inline" }} className="mx-2 align-self-end">
                                <div className="form-check">
                                    <input type="checkbox"
                                        onChange={() => this.setState({ key: !this.state.key }, () => this.onChangePropsEvent())}
                                        className="form-check-input"
                                        checked={this.state.key}
                                    />
                                    <label className={`form-check-label`}>Key Value</label>
                                </div>
                            </div>
                            <div style={{ display: "inline" }} className="mx-2 align-self-end">
                                <div className="form-check">
                                    <input type="checkbox"
                                        onChange={() => this.setState({ case_sensitive: !this.state.case_sensitive }, () => this.onChangePropsEvent())}
                                        className="form-check-input"
                                        checked={this.state.case_sensitive}
                                    />
                                    <label className={`form-check-label`}>Case Sensitive</label>
                                </div>
                            </div>
                            {this.state.key ?
                                this.state.keyValueArray.map((item, i) => {
                                    return(
                                        <div className="w-100">
                                            <div className="d-flex flex-wrap">
                                                <p className="mb-0 mr-2 align-self-end text-purple mt-3">Search For</p>
                                                {this.state['searchFor_'+item] && this.state['searchFor_'+item].map((value, index) => {
													return(
													<span key={index} className="badge-square badge-secondary f13 align-self-end p-1 mr-2" >
													    {value}
														<i onClick={ () => this.removeSelectedOption(value, index, item) } className='ml-1 fal fa-times cursorPointer'></i>
													</span>
													)
												})}
                                                <input 
                                                    type="text" 
                                                    className={`inputField mt-3 ${this.state.hasError && (!this.state['searchFor_'+item] || !this.state['searchFor_'+item].length) ? 'dottedDangerTextboxLine' : ''}`}
                                                    value={this.state['text_'+item] ? this.state['text_'+item] : ''}
                                                    onChange={e => this.setState({ ['text_'+item]: e.target.value }, () => this.onChangePropsEvent())}
                                                    onKeyDown={e => this.onKeyDown(e, item)}
                                                />
                                                <p className="mb-0 mx-2 align-self-end text-purple mt-3">Replace With</p>
                                                <input 
                                                    type="text" 
                                                    className={`inputField mt-3 ${this.state.hasError && (!this.state['replaceWith_'+item] || this.state['replaceWith_'+item] === '') ? 'dottedDangerTextboxLine' : ''}`}
                                                    value={this.state['replaceWith_'+item] ? this.state['replaceWith_'+item] : ''}
                                                    onChange={e => this.setState({ ['replaceWith_'+item]: e.target.value }, () => this.onChangePropsEvent())}
                                                />
                                                {i ?
                                                    <span className={`far fa-trash cursor-pointer f18 align-self-end ml-4`} onClick={() => this.removeSection(i)}></span>
                                                : null}
                                            </div>
                                        </div>
                                    )
                                })
                            :
                                <React.Fragment>
                                    <p className="mb-0 mx-2 align-self-end text-purple mt-3">Value</p>
                                    <input 
                                        type="text" 
                                        className={`inputField mt-3 ${this.state.hasError && (!this.state.value || this.state.value === '') ? 'dottedDangerTextboxLine' : ''}`}
                                        value={this.state.value ? this.state.value : ''}
                                        onChange={e => this.setState({ value: e.target.value }, () => this.onChangePropsEvent())}
                                    />
                                </React.Fragment>
                            }
                            
                            {this.state.key ?
                                <div className="d-flex col-sm-12 justify-content-end">
                                    <span className='btn btn-secondary' onClick={() => this.addSection()}>Add</span>
                                </div>
                            : null}
                        </React.Fragment>
                    : null}
                </div>
                
                {this.props.watermarkTypeArray && this.props.watermarkTypeArray[this.state.deidentification_type] && this.props.watermarkTypeArray[this.state.deidentification_type].length ?
                    <div className="d-flex flex-wrap">
                        <p className="mb-0 mr-2 align-self-end text-purple mt-3">Add watermark</p>
                        <Select
                            // menuIsOpen={true}
                            placeholder={'Select'}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            isSearchable={false}
                            className={`selectOption mr-2 mt-3 w-15`}
                            value={({
                                value: this.state.watermark ? this.state.watermark : 'Select',
                                label: this.state.watermark ? this.state.watermark : 'Select',
                            })}
                            options={this.state.yesNoOptions && this.state.yesNoOptions.map(item => ({
                                value: item,
                                label: item,
                            }))}
                            onChange={event =>  
                                this.setState({ watermark: event.value }, () => this.onChangePropsEvent())
                            }
                        />
                        
                        {this.state.watermark === 'Yes' ?
                            <React.Fragment>
                                <p className="mb-0 mr-2 align-self-end text-purple mt-3">watermark Type</p>
                                <Select
                                    // menuIsOpen={true}
                                    placeholder={'Select'}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    isSearchable={false}
                                    className={`selectOption mr-2 mt-3 ${this.state.hasError && (!this.state.watermark_type || this.state.watermark_type === '') ? 'dottedDangerSelectLine' : ''}`}
                                    value={({
                                        value: this.state.watermark_type ? this.state.watermark_type : 'Select',
                                        label: this.state.watermark_type ? this.state.watermark_type : 'Select',
                                    })}
                                    options={this.props.watermarkTypeArray[this.state.deidentification_type] && this.props.watermarkTypeArray[this.state.deidentification_type].map(item => ({
                                        value: item.watermark_type,
                                        label: item.watermark_type,
                                    }))}
                                    onChange={event =>  
                                        this.setState({ watermark_type: event.value }, () => this.onChangePropsEvent())
                                    }
                                />
                                <p className="mb-0 mr-2 align-self-end text-purple mt-3">with watermark</p>
                                <Select
                                    // menuIsOpen={true}
                                    placeholder={'Select'}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    isSearchable={false}
                                    className={`selectOption mr-2 mt-3 w-30 ${this.state.hasError && (!this.state.watermark_name || this.state.watermark_name === '') ? 'dottedDangerSelectLine' : ''}`}
                                    value={({
                                        value: this.state.watermark_id ? this.state.watermark_id : 'Select',
                                        label: this.state.watermark_name ? this.state.watermark_name : 'Select',
                                    })}
                                    options={this.props.watermarks && this.props.watermarks.map(item => ({
                                        value: item.watermark_id,
                                        label: item.watermark_name,
                                    }))}
                                    onChange={event =>  
                                        this.setState({ watermark_id: event.value, watermark_name: event.label }, () => this.onChangePropsEvent())
                                    }
                                />
                            </React.Fragment>
                        : null}
                    </div>
                : null}
            </div>
        )
    }
}

const mapStateToProps = state => {
	return {
        dlpPropsDetails: state.dlp.dlpPropsDetails,
        watermarks: state.dlp.dlpConfigWatermarklist
	}
}

export default connect(mapStateToProps, {})(withRouter(Substitution))