import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import SelectField from '../../../common/formFields/SelectField'

import { setAiopsPropsDetails } from '../../../../actions/aiops/AiopsAction'
import { getAssetDetailExt } from '../../../../actions/aiops/RemediationAction'

class IncreaseRDSInstanceIOPS extends Component {

  constructor(props) {
    super(props);
    this.state = props || {};
    this.state = {
      validationMessage: '',
      parentValue: this.props.value,
      showLoading: true,
    }
    this.updateFormFieldValue = this.updateFormFieldValue.bind(this);
  }

  componentDidMount = () => {
    // // this.getStorageType()
    // let params = {}
    // params.provider = Array.isArray(this.props.caseDetails.provider) ? this.props.caseDetails.provider[0].toLowerCase() : this.props.caseDetails.provider.toLowerCase()
    // params.resource_type = this.props.aiopsSelectedRemediationProps.resource_type
    // this.props.listStorageTypes(params, (success, result) => {
    //   if (success) {
    //       let storageTypesOptions = []
    //       result.length && result[0].results && result[0].results.map(res => {
    //         let storageRow = {}
    //         storageRow.description = res.description
    //         storageRow.options = res.volume_details.volume_name
    //         storageRow.volume_details = res.volume_details
    //         storageTypesOptions.push(storageRow)
    //     })
    //     this.setState({ storageTypesOptions, showLoading: false })
    //   }
    // })

    let options = [
      {label: 25, options: 125},
      {label: 50, options: 150}
    ]

    this.setState({ options })
  }

  componentDidUpdate = (prevProps) => {
    if(this.props.validationError !== prevProps.validationError) {
			this.setState({ 
        validationError: this.props.validationError, 
        rdsInstanceError: this.props.aiopsRemediationParams.rdsInstanceError ? this.props.aiopsRemediationParams.rdsInstanceError : '',
      })
		}
  }

  updateFormFieldValue = (fieldName, fieldValue) => {
    let obj = this.props.aiopsRemediationParams
    obj[fieldName] = fieldValue
    

    if(fieldName === 'StorageType') {
      this.setState({ StorageTypeError: '' })
      obj.StorageTypeError = ''
    } else {
      this.setState({ ApplyImmediatelyError: '' })
      obj.ApplyImmediatelyError = ''
    }
    this.props.setAiopsPropsDetails('remediationParams', obj)

    this.setState({ [fieldName]: fieldValue });
  };

  render() {
    return (
        <div className="row mt-2">
          <div className="col-md-12">
            <div className="form-group">
              <label>RDS Instance<small className="ml-1">Current Percentage: 100 </small></label>
              <SelectField 
                className="f13 p-0 form-control dark-select-theme col-sm-3"
                recordSelection={this.updateFormFieldValue}
                value={this.state.RdsInstance ? this.state.RdsInstance : ''}
                name={'RdsInstance'}
                label='Select'
                placeholder="All"
                required={false}
                selectOptions={this.state.options ? this.state.options : []}
              />
              {this.state.validationError && this.state.rdsInstanceError && this.state.rdsInstanceError !== '' ?
                <small className='text-danger'>{this.state.rdsInstanceError}</small>
              : null }
            </div>
          </div>
        </div>
    )
  }
};

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
 const mapStateToProps = state => {	
	console.log('IncreaseRDSInstanceIOPS', state)  
	return {  
    caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : [],
    aiopsRemediationParams: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.remediationParams ? state.aiops.aiopsPropsDetails.remediationParams : {},
    aiopsSelectedRemediationProps: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.selected_remediation ? state.aiops.aiopsPropsDetails.selected_remediation : {},
  }
}

/**
 * Type of the props used in the component
 */
 IncreaseRDSInstanceIOPS.propTypes = {  
  setAiopsPropsDetails: PropTypes.func,
  getAssetDetailExt: PropTypes.func
}


export default connect(mapStateToProps, {
  setAiopsPropsDetails,
  getAssetDetailExt
})(withRouter(IncreaseRDSInstanceIOPS))
