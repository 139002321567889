/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Recommendations.js
 * @author Prakash // on 10/02/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { URL_PATH } from '../../../config/urlPath'
import { AppWrapper } from '../../common/AppWrapper'

import RecommendationsLeftSection from './RecommendationsLeftSection'
import RecommendationsRightSection from './RecommendationsRightSection'

class Recommendations extends Component {
    
    constructor(props) {

        super(props)
        this.scrollRef = React.createRef()
        
        this.state = {
            disableMenu: true,
        }
    }

    componentDidMount = () => {
        
    }

    getCostRecommendations = () => {

    }

	render() {
		return (
            <div class={`main-content bg-muted`}>
                <div className={`container-fluid overflow-auto flex-grow-1`}>
                    <div className="row h-100">
                        <div className={`border-right col-md-5 p-0`}>
                            <RecommendationsLeftSection />
                        </div>
                        <div className={`col-md-7`}>
                            <RecommendationsRightSection />
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
Recommendations.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log("-",state)
	return {}
}

export default AppWrapper(Recommendations, mapStateToProps, {})