/*************************************************
 * Tvastar
 * @exports
 * @file Table.js
 * @author Rajasekar // on 30/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
	useTable,
	useBlockLayout,
	useResizeColumns,
	usePagination,
	useSortBy,
	useFilters,
	useGlobalFilter,
} from 'react-table'
import { UncontrolledTooltip } from 'reactstrap'

// export function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
// 	// const count = preGlobalFilteredRows.length
// 	return (
// 		<div className='input-group dark-feild mb-2 mr-sm-2'>
// 			<div className='input-group-prepend'>
// 				<div className='input-group-text bg-transparent'>
// 					<i className='fal fa-search' />
// 				</div>
// 			</div>
// 			<input
// 				value={globalFilter || ''}
// 				onChange={e => {
// 					setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
// 				}}
// 				type='text'
// 				className='form-control'
// 				placeholder='Search'
// 			/>
// 		</div>
// 		// <span>
// 		// 	Search:{' '}
// 		// 	<input
// 		// 		value={globalFilter || ''}
// 		// 		onChange={e => {
// 		// 			setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
// 		// 		}}
// 		// 		placeholder={`${count} records...`}
// 		// 		style={{
// 		// 			fontSize: '1.1rem',
// 		// 			border: '0',
// 		// 		}}
// 		// 	/>
// 		// </span>
// 	)
// }

const toolTipModifiers = {
	flip: { behavior: ['top'] },
	preventOverflow: { boundariesElement: 'viewport' },
}

function Table({ columns, data, onClickRow, dashboard, tooltipFor }) {
	const [selectedRowIndex, setSelectedRowIndex] = useState(0)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		// preGlobalFilteredRows,
		// setGlobalFilter,
		// state,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0, pageSize: 10 },
		},
		useBlockLayout,
		useResizeColumns,
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination
	)

	return (
		<React.Fragment>
			<div className='p-0'>
				{/* <GlobalFilter
				preGlobalFilteredRows={preGlobalFilteredRows}
				globalFilter={state.globalFilter}
				setGlobalFilter={setGlobalFilter}
			/> */}
				<div className='table-responsive' style={{overflow: 'hidden'}}>
					<table {...getTableProps()} className='table table-sm table-hover'>
					<thead>
							{headerGroups.map((headerGroup, i) => (
								<tr key={i} className='bg-primary w-100' {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column, index) => (
										<th className='bg-primary f10' key={index} {...column.getHeaderProps()}>
											<span
												{...column.getSortByToggleProps({ title: 'Sort' })}
												style={{ marginRight: 10, cursor: 'pointer' }}
											>
												{column.isSorted ? (
													column.isSortedDesc ? (
														<React.Fragment>
															<i className='fas fa-sort-down' /> {column.render('Header')}
														</React.Fragment>
													) : (
														<React.Fragment>
															<i className='fas fa-sort-up' /> {column.render('Header')}
														</React.Fragment>
													)
												) : (
													column.render('Header')
													// <React.Fragment>
													// 	<i className='fas fa-sort' /> {column.render('Header')}
													// </React.Fragment>
												)}
											</span>
											{headerGroup.headers.length - 1 === index ? null : (
												<div {...column.getResizerProps()} className={`resizer`} />
											)}
										</th>
									))}
								</tr>
							))}
						</thead>						
						<tbody {...getTableBodyProps()}>
							{page.map((row, i) => {
								prepareRow(row)
								return (
									<tr
										className={selectedRowIndex === i ? 'bg-light w-100' : 'w-100'}
										key={i}
										{...row.getRowProps()}
										onClick={() => {
											onClickRow(row.original)
											setSelectedRowIndex(i)
										}}
									>
										{row.cells.map((cell, index) => {
											return (
												<React.Fragment>
													{tooltipFor && tooltipFor.includes(index) && (
														<UncontrolledTooltip
															key={index}
															placement='top'
															modifiers={toolTipModifiers}
															target={`targetIdNotify${i}${index}`}
														>
															{cell.render('Cell')}
														</UncontrolledTooltip>
													)}
													<td
														key={i}
														className='f10'
														{...cell.getCellProps()}
														id={`targetIdNotify${i}${index}`}
													>
														{/* {moment(cell.render('Cell').props.cell.value).isValid()
														? cell.render('Cell')
														: cell.render('Cell').props.cell.value && cell.render('Cell').props.cell.value.length > 15
														? cell.render('Cell').props.cell.value.substring(0, 15) + '...' */}
														{cell.render('Cell')}
													</td>
												</React.Fragment>
											)
										})}
									</tr>
								)
							})}
							{dashboard ?
								null
								:

								<tr className='bg-light w-100 text-center'>
									<td className='f10'>
										No Data Found
									</td>
								</tr>
							}
						</tbody>
					</table>
				</div>
			</div>
			{dashboard ? (
				<div className='pagination mt-n3 f12'>
					Rows per page:{' '}
					<select
						value={pageSize}
						onChange={e => {
							setPageSize(Number(e.target.value))
						}}
					>
						{[10, 20, 30, 40, 50].map(pageSize => (
							<option key={pageSize} value={pageSize}>
								{pageSize}
							</option>
						))}
					</select>
					<span className='mx-3'>
						Page{' '}
						<strong>
							{pageIndex + 1} of {pageOptions.length}
						</strong>{' '}
					</span>
					<button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
						<i className='fal fa-arrow-to-left' />
					</button>{' '}
					<button onClick={() => previousPage()} disabled={!canPreviousPage}>
						<i className='fal fa-angle-left' />
					</button>{' '}
					<button
						onClick={() => {
							nextPage()
						}}
						disabled={!canNextPage}
					>
						<i className='fal fa-angle-right' />
					</button>{' '}
					<button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
						<i className='fal fa-arrow-to-right' />
					</button>
					{/* <span>
					| Go to page:{' '}
					<input
						type='number'
						defaultValue={pageIndex + 1}
						onChange={e => {
							const page = e.target.value ? Number(e.target.value) - 1 : 0
							gotoPage(page)
						}}
						style={{ width: '100px' }}
					/>
				</span> */}
				</div>
			) : null}
		</React.Fragment>
	)
}
/**
 * Type of the props used in the component
 */
Table.propTypes = {
	columns: PropTypes.array,
	onClickRow: PropTypes.func,
	onClickColumn: PropTypes.func,
	onClickColumnFor: PropTypes.func,
	data: PropTypes.array,
	dashboard: PropTypes.bool,
	tooltipFor: PropTypes.array,
}

export default Table
