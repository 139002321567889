/*************************************************
 * Tvastar
 * @exports
 * @file Header.js
 * @author Rajasekar // on 06/01/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React from 'react'
import PropTypes from 'prop-types'
import { Spinner, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const ModalWrapper = props => {
	const {
		buttonPrimaryLabel,
		buttonSecondaryLabel,
		className,
		showModal,
		toggle,
		modalTitle,
		children,
		onClickPrimaryButton,
		showLoading,
		showMessage,
		responseMessage,
		showError,
	} = props

	const toggleModal = () => toggle(!showModal)

	return (
		<Modal isOpen={props.showModal} toggle={toggleModal} className={className} style={{ maxWidth: 1000 }}>
			<ModalHeader className='bg-primary' style={{ color: '#fff' }} toggle={toggleModal}>
				{modalTitle}
			</ModalHeader>
			<ModalBody>{children}</ModalBody>
			<ModalFooter className='d-flex justify-content-between'>
				<div>{showMessage ? <p className={`text-${!showError ? 'success' : 'danger'}`}>{responseMessage}</p> : ''}</div>
				<div>
					<Button color='primary' onClick={() => onClickPrimaryButton()} disabled={showLoading}>
						{showLoading ? (
							<>
								{'Saving'}
								<Spinner className='text-center ml-2' color='light' size='sm' />{' '}
							</>
						) : (
							buttonPrimaryLabel
						)}
					</Button>{' '}
					<Button color='secondary' onClick={toggleModal}>
						{buttonSecondaryLabel}
					</Button>
				</div>
			</ModalFooter>
		</Modal>
	)
}

/**
 * Type of the props used in the component
 */
ModalWrapper.propTypes = {
	showModal: PropTypes.bool,
	buttonPrimaryLabel: PropTypes.string,
	buttonSecondaryLabel: PropTypes.string,
	modalTitle: PropTypes.string,
	className: PropTypes.string,
	toggle: PropTypes.func,
	children: PropTypes.array,
	isLoading: PropTypes.bool,
	onClickPrimaryButton: PropTypes.func,
	showLoading: PropTypes.bool,
	showError: PropTypes.bool,
	responseMessage: PropTypes.string,
	showMessage: PropTypes.bool,
}
export default ModalWrapper
