/*************************************************
 * Tvastar
 * @exports
 * @file EditCondition.js
 * @author Rajasekar // on 14/03/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { apiToCall } from '../../actions/commonAction'
import { listLogType, listSizingEc2, listServiceEngines } from '../../actions/governance/governanceAction'
import { connect } from 'react-redux'
import Components from './categoryRulePages';
import _ from 'lodash'
import { convertToInt } from '../../utils/utility'
import { Spinner } from 'reactstrap'
import { Link } from 'react-router-dom'
import { URL_PATH } from '../../config/urlPath'

class EditCondition extends Component {
	constructor(props) {
		super(props)
		this.state = {
			condition: [],
			hasError: this.props.hasError,
			redirectDecision: this.props.redirectDecision,
			loadingConditionPage: false,
		}
	}

	componentDidMount = () => {
		this.setState({ hasError: this.props.hasError })

		if(this.props.editRuleDetails.category === 'Service-Logs') {
			this.setState({ loadingConditionPage: true })
			if(!this.props.logTypeProps || !Object.entries(this.props.logTypeProps).length || this.props.logTypeProps.body.resource_type !== this.props.editRuleDetails.resource_type) {
				if(this.props.editRuleDetails.provider && this.props.editRuleDetails.provider !== '') {
					let params = {}
					params.provider = this.props.editRuleDetails.provider.toLowerCase()
					params.resource_type = this.props.editRuleDetails.resource_type
					this.props.listLogType(params, (promise, log_type) => {
						if(promise) {
							this.setState({ condition: log_type.length ? log_type[0].results : [], loadingConditionPage: false })
						} else {
							this.setState({ condition: [], loadingConditionPage: false })
						}
					})
				}
			} else {
				this.setState({ condition: Object.entries(this.props.logTypeProps).length ? this.props.logTypeProps.result[0].results : [], loadingConditionPage: false })
			}
		}

		if(this.props.editRuleDetails.category === 'Service-Sizing' && this.props.editRuleDetails.resource_type === 'EC2') {
			this.setState({ loadingConditionPage: true })
			let params = {}
			// POST  {"region":{"match":"us-east-1"},"provider":{"match":"AWS"},"sourcefields":{"fieldsreq":["features"]}}
			//this.props.editRuleDetails.provider
			params.provider = {'match': this.props.editRuleDetails.provider}
			params.region = {'match': "us-east-1"}
			params.os = {"match":"Linux"}
			// params.resource_type = this.props.editRuleDetails.resource_type
			params.sourcefields = {"fieldsreq":["features"]}

			if(!this.props.sizingEc2 || !Object.entries(this.props.sizingEc2).length) {
				this.props.listSizingEc2(params, (promise, response) => {
					let sizingArray = []
					let sizingOutput = []
					if(promise) {
						let data = response.data.body
						data.length && data.forEach(item => {
							Object.values(item).forEach(parent => {
								// console.log("item", item)
								let dataRow = {}
								dataRow.description = parent.Description
								dataRow.instanceFamily = parent.General && parent.General['Instance Family'] ? parent.General['Instance Family'] : ''
								dataRow.instanceSeries = parent.General && parent.General['Series'] ? parent.General['Series'] : ''
								dataRow.instanceType = parent.General && parent.General['Instance'] ? parent.General['Instance'] : ''
								
								if(parent.Compute && parent.Compute['vCPU']) {
									dataRow.vCPU = parent.Compute['vCPU']
								}
								if(parent.Memory && parent.Memory['Memory(GiB)']) {
									dataRow.memory = parent.Memory['Memory(GiB)'] + ' GiB'
								}
								if(parent.Storage && parent.Storage['EBS Optimized']) {
									dataRow.ebsOptimized = parent.Storage['EBS Optimized']
								}
								if(parent.Storage && parent.Storage['Storage Type']) {
									dataRow.storageType = parent.Storage['Storage Type']
								}							
								if(parent.Storage && parent.Storage['Storage Details']) {
									dataRow.storageDetails = parent.Storage['Storage Details']
								}
								if(parent.Networking && parent.Networking['Enhanced Networking']) {
									dataRow.enhancedNetworking = parent.Networking['Enhanced Networking']
								}
								if(parent.Networking && parent.Networking['Network Performance(Gbit/s)']) {
									dataRow.networkingPerformance = parent.Networking['Network Performance(Gbit/s)'] +' Gbit/s'
								}
								if(parent.Processor && parent.Processor['Architecture(bit)']) {
									dataRow.architecture = convertToInt(parent.Processor['Architecture(bit)'])
								}
								if(parent.Processor && parent.Processor['CPU Processor']) {
									dataRow.cpuProcessor = parent.Processor['CPU Processor']
								}
								sizingArray.push(dataRow)	
							})
						})
											
						// let instanceFamily = _.chain(sizingArray)
						_.chain(sizingArray)
						.groupBy('instanceFamily')
						.map((value, key) => ({ label: key, sub: value })).value()
						.forEach((sizing, i) => {
							let dataRow = {}
							dataRow.instanceFamilyLable = sizing.label
							dataRow.description = sizing.label //equalizing the old array to easy the work

							let vm_series = []
							_.chain(sizing.sub)
							.groupBy('instanceSeries')
							.map((value, key) => ({ slabel: key, sSub: value })).value()
							.forEach((series, i) => {
								let seriesRow = {}
								seriesRow.series = series.slabel
								seriesRow.vm_types = series.sSub
								vm_series.push(seriesRow)
							})
							dataRow.vm_series = vm_series
							sizingOutput.push(dataRow)
						})

						this.setState({ originalData: data, condition: sizingOutput, loadingConditionPage: false })		
					} else {
						this.setState({ condition: sizingOutput, loadingConditionPage: false })
					}
				})
			} else {
				let sizingArray = []
				let sizingOutput = []
				let data = this.props.sizingEc2.data.body
				data.length && data.forEach(item => {
					Object.values(item).forEach(parent => {
						let dataRow = {}
						dataRow.description = parent.Description
						dataRow.instanceFamily = parent.General && parent.General['Instance Family'] ? parent.General['Instance Family'] : ''
						dataRow.instanceSeries = parent.General && parent.General['Series'] ? parent.General['Series'] : ''
						dataRow.instanceType = parent.General && parent.General['Instance'] ? parent.General['Instance'] : ''
						
						if(parent.Compute && parent.Compute['vCPU']) {
							dataRow.vCPU = parent.Compute['vCPU']
						}
						if(parent.Memory && parent.Memory['Memory(GiB)']) {
							dataRow.memory = parent.Memory['Memory(GiB)'] + ' GiB'
						}
						if(parent.Storage && parent.Storage['EBS Optimized']) {
							dataRow.ebsOptimized = parent.Storage['EBS Optimized']
						}
						if(parent.Storage && parent.Storage['Storage Type']) {
							dataRow.storageType = parent.Storage['Storage Type']
						}							
						if(parent.Storage && parent.Storage['Storage Details']) {
							dataRow.storageDetails = parent.Storage['Storage Details']
						}
						if(parent.Networking && parent.Networking['Enhanced Networking']) {
							dataRow.enhancedNetworking = parent.Networking['Enhanced Networking']
						}
						if(parent.Networking && parent.Networking['Network Performance(Gbit/s)']) {
							dataRow.networkingPerformance = parent.Networking['Network Performance(Gbit/s)'] +' Gbit/s'
						}
						if(parent.Processor && parent.Processor['Architecture(bit)']) {
							dataRow.architecture = parent.Processor['Architecture(bit)']
						}
						if(parent.Processor && parent.Processor['CPU Processor']) {
							dataRow.cpuProcessor = parent.Processor['CPU Processor']
						}
						sizingArray.push(dataRow)	
					})
				})
									
				// let instanceFamily = _.chain(sizingArray)
				_.chain(sizingArray)
				.groupBy('instanceFamily')
				.map((value, key) => ({ label: key, sub: value })).value()
				.forEach((sizing, i) => {
					let dataRow = {}
					dataRow.instanceFamilyLable = sizing.label
					dataRow.description = sizing.label //equalizing the old array to easy the work
					let vm_series = []
					_.chain(sizing.sub)
					.groupBy('instanceSeries')
					.map((value, key) => ({ slabel: key, sSub: value })).value()
					.forEach((series, i) => {
						let seriesRow = {}
						seriesRow.series = series.slabel
						seriesRow.vm_types = series.sSub
						vm_series.push(seriesRow)
					})
					dataRow.vm_series = vm_series
					sizingOutput.push(dataRow)
				})

				this.setState({ originalData: data, condition: sizingOutput, loadingConditionPage: false })
			}
			

			// features "General" Instance Family Series
			// console.log('sizingResult', sizingResult)
			
			// const result = _.chain(sizingResult)
			// .groupBy(({ features, General: { Series, Instance } }).map(e => {
			//   const [{ key, address, className }] = e;
			//   return {
			// 	keys: { key, address, className },
			// 	count: _.keys(_.groupBy(e, 'deviceId')).length
			//   }
			// })
			
			// sizingResult.map()

			// let instanceFamily1 = _.filter(sizingResult.features.General, 'Instance Family');
			// console.log('instanceFamily1', instanceFamily1)		

			// let instanceFamily = _.chain(sizingResult.features.General)
			// .groupBy('Instance Family')
			// .map((value, key) => ({ label: key, sub: value })).value()

			// console.log('instanceFamily', instanceFamily)			
		} 

		if(this.props.editRuleDetails.category === 'Service-Engine' && this.props.editRuleDetails.resource_type === 'RDS') {
			this.setState({ loadingConditionPage: true })
			if(!this.props.engineRds || !Object.entries(this.props.engineRds).length) {
				let params = {}
				params.provider = this.props.editRuleDetails.provider.toLowerCase()
				params.resource_type = this.props.editRuleDetails.resource_type
				this.props.listServiceEngines(params, (promise, engine) => {
					if(promise) {
						this.setState({ condition: engine.length ? engine[0].results : [], loadingConditionPage: false })
					} else {
						this.setState({ condition: [], loadingConditionPage: false })
					}
				})
			} else {
				this.setState({ condition: Object.entries(this.props.engineRds.result).length ? this.props.engineRds.result[0].results : [], loadingConditionPage: false })
			}
		}
	}
	
	componentDidUpdate = () => {
		if(this.props.hasError !== this.state.hasError) {
			this.setState({ hasError: this.props.hasError })
		}

		if(this.props.redirectDecision && this.props.redirectDecision !== this.state.redirectDecision) {
			this.setState({ redirectDecision: this.props.redirectDecision })
		}
	}

	render() {
		const ComponentToRender = Components[this.props.editRuleDetails.category]
		return (
			<div>
				<h5 className="text-white">Condition</h5>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
					magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
					consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
					pariatur.
				</p>
				{this.state.loadingConditionPage ?
					<div className='d-flex justify-content-center m-4'>
						<Spinner className='text-center' color='white' size='lg' />
					</div>
				:
					this.props.editRuleDetails.category && ComponentToRender ?
						<ComponentToRender
							hasError={this.state.hasError} 
							condition={this.state.condition}
						/>
					: 
						<div className='d-flex justify-content-center m-4'>
							<p>There are no data for {this.props.editRuleDetails.category} category. Please try adjusting your category in description tab.</p>
						</div>
				}
				
				<div className="border-top pt-2 mt-3">
					<button className='btn btn-light mr-1' onClick={() => this.props.onClickBack()}>Back</button>
					<button className='btn btn-primary' onClick={() => {this.props.onClickNext()}}>Next</button>
					<Link className='float-right btn btn-light text-muted text-decoration-none ml-1' to={URL_PATH.GOVERNANCE_RULES} >
						Cancel
					</Link>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
EditCondition.propTypes = {
	listLogType: PropTypes.func,
	onClickNext: PropTypes.func,
	onClickBack: PropTypes.func,
	apiToCall: PropTypes.func,
	ruleDetails: PropTypes.object,
	listSizingEc2: PropTypes.func,
	listServiceEngines: PropTypes.func
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('condition',state)
	return {
		editRuleDetails: state.governance.editRuleDetails,
		sizingEc2: state.governance.sizingEc2 ? state.governance.sizingEc2 : {},
		logTypeProps: state.governance.logTypeProps,
		engineRds: state.governance.engineRds,
	}
}

export default connect(mapStateToProps, { apiToCall, listLogType, listSizingEc2, listServiceEngines })(EditCondition)
