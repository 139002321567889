/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Diagnostics.js
 * @author Prakash // on 10/02/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { URL_PATH } from '../../config/urlPath'
import { AppWrapper } from '../common/AppWrapper'

import DiagnosticsLeftSection from './DiagnosticsLeftSection'
import DiagnosticsRightSection from './DiagnosticsRightSection'

class Diagnostics extends Component {
    
    constructor(props) {

        super(props)
        this.scrollRef = React.createRef()
        
        this.state = {
            disableMenu: true,
        }
    }

    componentDidMount = () => {
        if (this.props.location && this.props.location.state && Object.keys(this.props.location.state).length !== 0) {
            this.setState({
                diagnostics: this.props.location.state.diagnostics,
                diagnostic_id: this.props.location.state.diagnostics.diagnostic_id,
            })
        } else {
            this.props.history.push({
                pathname: URL_PATH.LIST_DIAGNOSTICS,
            })
        }        
    }

	render() {
		return (
            this.state.diagnostic_id ?
                <div class={`main-content bg-muted`}>
                    <div className={`container-fluid overflow-auto flex-grow-1`}>
                        <div className="row h-100">
                            <div className={`border-right col-md-6 p-0`}>
                                <DiagnosticsLeftSection 
                                    diagnostics = {this.state.diagnostics}
                                />
                            </div>
                            <div className={`col-md-6`}>
                                <DiagnosticsRightSection 
                                    diagnostics = {this.state.diagnostics}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            : null
		)
	}
}

/**
 * Type of the props used in the component
 */
 Diagnostics.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log("-",state)
	return {}
}

export default AppWrapper(Diagnostics, mapStateToProps, {})