/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AssetBoard.js
 * @author Prakash // on 05/06/2020
 * @copyright © 2020 Tvastar. All rights reserved.
 **************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
////import Chart from 'chart.js'
import { Spinner, UncontrolledTooltip, UncontrolledPopover, PopoverBody } from 'reactstrap'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import _ from 'lodash'
import { AppWrapper } from '../../common/AppWrapper'
import { 
	listAllProviders, 
	listAllAccounts, 
	listAllRegions,
	listAllCategory,
	listAllServices
} from '../../../actions/commonAction'
import { 
	getAssetByService,
	getAssetByServiceDetails,
	getAssetByCategory,
	getAssetByCategoryDetails,
	getOverAllAssetCount,
	getTotalBilling
} from '../../../actions/assets/assetBoardAction'
import { DURATION_FILTER_OPTIONS } from '../../../utils/constants'
import CustomTable from '../../designComponents/Table/customTable'
import { capitalizeFirstLetter, numberFormater, getAccountNameFromId } from '../../../utils/utility'

import Chart from 'react-apexcharts'
import { Doughnut } from 'react-chartjs-2';

import SidePanel from './SidePanel'
import TagFilterSection from '../../common/TagFilterSection'
import ApexDonutChart from '../../common/charts/ApexDonutChart'

class AssetBoard extends Component {
	accountRef = React.createRef()
	regionRef = React.createRef()
	serviceRef = React.createRef()
	constructor(props) {
		super(props)		
		this.state = {
			showDetailsPanel: false,
			left: 0,
			showLoading: true,
			
			providers: [],
            selectedProvider: 'All',
			accounts: [],
			accountArray: [],
			selectedAccount: [],
			isAccountOpen: false,

            regions: [],
			selectedRegion: [],
			isRegionOpen: false,

			selectedDuration: '+7d',
			totalselectedTag: 'Select',
			
			selectedTags: [],
			tagFilter: false,
			
			isServiceOpen: false,
			selectedService: [],
			selectedType: 'Service',
			filterData: [],
			assetService: [],
			filterDetailsData: [],
			assetByServiceDetails: [],
			assetCategory: [],
			assetByCategoryDetails: [],
			
			overallCount: [],
			activeTab: 0,
			totalBilling: [],
			empty_doughnut_data: [],
			empty_doughnut_option: [],
			
			status_options: {
				stroke: {
					width: 0
				},
				chart: {
					  type: 'donut',
					  fontSize: '12px',
				},
				legend: {
					show: false,
				},
				dataLabels: {
					  enabled: false
				},
				fill: {
					type: 'gradient',
				},
				labels: ['Active', 'InActive'],
				colors: ['#39BFA0', '#6E6096'],
				tooltip: {
					enabled: false,
				},
				plotOptions: {
					pie: {
						donut: {
							size: '80%',
							labels: {
								show: true,
								value: {
									fontSize: '13px',
									fontWeight: 600,

									formatter: function (val) {
										return numberFormater(val)
									}
								},
								total: {
									show: true,
									label: 'Total',
									color: '#4d4848',
									fontSize: '13px',
									fontWeight: 600,
									offsetY: -10,
									formatter: function (w) {
										let result = 0
										w.globals && w.globals.initialSeries && w.globals.initialSeries.forEach(function(status) {
											result += status 
										})
										return numberFormater(result)
									}
								},								
							}
						},      
					}
				},
			},
		}
	}
	
	componentDidMount = () => {		
		this.props.listAllProviders((promise, response) => {
			if (promise) {
				// let providers = []
				// if(response.length) {
				// 	let dataRow = {}
				// 	dataRow.provider_name = "All"
				// 	providers.push(dataRow);
				// }
				// providers = providers.concat(response)
				this.setState({ providers: response, selectedProvider: response.length ? response[0].provider_name : "", showLoading: true },
					() => this.filterIdentifiers('provider')	
				)
			} else {
				this.setState({ providers: [] }, 
					() => this.filterIdentifiers()
				)
			}
		})
		
		this.emptyDoughnutGraph()

		let catParams = {}
		this.props.listAllCategory(catParams, (promise, categories) => {
			if (promise) {
				let categoryArray = []
				categories.forEach((cat, i) => {
					if(i === 0) {
						let data = {}
						data.value = 'All'
						data.label = 'All'
						categoryArray.push(data)
					}
					let dataRow = {}
					// let exist = 0
					// if(this.state.categorySelected && this.state.categorySelected.length) {
					// 	this.state.categorySelected.map((row, index) => {
					// 		if(cat == row.value) {
					// 			exist = 1
					// 		}
					// 	})
					// 	if(exist === 0) {
					// 		dataRow.value = cat
					// 		dataRow.label = cat
					// 		categoryArray.push(dataRow)
					// 	}
					// } else {
						dataRow.value = cat
						dataRow.label = cat
						categoryArray.push(dataRow)
					// }
				})
				this.setState({ categories, categoryArray })
			} else {
				this.setState({ categories: [], categoryArray: [] })
			}
		})
	}
	
	/**
	 * Filter identifiers based on the filter selection
	 */
	filterIdentifiers = from => {
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'All') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if (from === 'provider') {
			if(!this.props.accounts.length) {
				this.props.listAllAccounts(params, (promise, response) => {
					if (promise) {
						let allAccounts = []
						if(response.length) {
							let accRow = {}
							accRow.account_id = "All"
							accRow.account_name = "All"
							accRow.date = null
							accRow.disabled = false
							accRow.provider = "All"
							allAccounts.push(accRow);
						}
						allAccounts = allAccounts.concat(response)
						//this.setState({ accounts: accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())})
						this.setState({ accounts: allAccounts },
							() => this.onLoadFunction()
						)
					} else {
						this.setState({ allAccounts: [], accounts: [] })
					}
				})
			} else {
				let filterAccounts = this.props.accounts
				if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'All') {
					filterAccounts = filterAccounts.filter(item => item.provider === this.state.selectedProvider.toLowerCase())
					let accounts = []
					if(filterAccounts.length) {
						let accRow = {}
						accRow.account_id = "All"
						accRow.account_name = "All"
						accRow.date = null
						accRow.disabled = false
						accRow.provider = "All"
						accounts.push(accRow);
						
						filterAccounts = accounts.concat(filterAccounts)
					}
				}

				this.setState({ accounts: filterAccounts },
					() => this.onLoadFunction()
				)
			}

			if(this.state.selectedProvider !== '') {
				if(!this.props.regions || this.props.regions.length) {
					if (this.state.selectedAccount.length && !this.state.selectedAccount.includes('All')) {
						params.account_id = this.state.selectedAccount
					}
					this.props.listAllRegions(params, (promise, regions) => {
						let providerRegions = regions && regions[0] && regions[0].provider_regions
						if(providerRegions) {
							let regions = []
							let provider_regions_row = {}
							provider_regions_row.availability_zones = []
							provider_regions_row.city = "All"
							provider_regions_row.country = "All"
							provider_regions_row.geography = "All"
							provider_regions_row.name = "All"
							provider_regions_row.region = "All"
							provider_regions_row.region_id = "All"
							provider_regions_row.state = "All"
							regions.push(provider_regions_row);
							providerRegions = regions.concat(providerRegions)
						}
						if (promise) {
							this.setState({ regions: providerRegions, tableRowRegions: providerRegions })
						} else {
							this.setState({ regions: [], tableRowRegions: [] })
						}
					})
				} else {
					let providerRegions = this.props.regions
					if(providerRegions) {
						let provider_regions_row = {}
						provider_regions_row.availability_zones = []
						provider_regions_row.city = "All"
						provider_regions_row.country = "All"
						provider_regions_row.geography = "All"
						provider_regions_row.name = "All"
						provider_regions_row.region = "All"
						provider_regions_row.region_id = "All"
						provider_regions_row.state = "All"
						providerRegions.unshift(provider_regions_row);
					}
					this.setState({ regions: providerRegions })
				}
			}

			this.props.listAllRegions(params, (promise, regions) => {
                if (promise) {
					let dataRow = {}
					dataRow.region = 'All'
					dataRow.name = 'All'
					regions[0] && regions[0].provider_regions.unshift(dataRow);
                    this.setState({ regions:regions[0] && regions[0].provider_regions })
                } else {
                    this.setState({ regions: [] })
                }
			})			
			
			this.props.listAllServices(params, (promise, services) => {
				let servicelist = []
				if (promise) {
					let serviceResourceArray = []
					let serviceArray = []
					services && services.length &&
					_.chain(services)
					.groupBy('resource_type')
					.map((value, key) => ({ label: key, sub: value }))
					.value()
					.forEach((service, i) => {
						let dataRow = {}
						if(i === 0) {
							dataRow = {}				
							dataRow.label = 'All'
							dataRow.value = 'All'
							servicelist.push(dataRow)
						}
						dataRow = {}				
						dataRow.label = service.label
						dataRow.value = service.label
						servicelist.push(dataRow)

						serviceResourceArray.push(service)
						service.sub.forEach((item, i) => {
							serviceArray.push(item)
						})
					})
					this.setState({ services, serviceArray, serviceResourceArray, servicelist, servCatDropdown: servicelist })
				} else {
					this.setState({ services: [], serviceArray: [], serviceResourceArray: [], servicelist: [] })
				}
			})
		}
	}

	selectedTagFunction = (value) => {
		if(value.length > 0 && value !== false) {
			this.setState({ totalselectedTag: value.length+ ' Key Selected'})

			this.setState({selectedTags: value})
		}

		this.setState({ tagFilter: this.state.tagFilter ? false : true })
	}
	
	clearTag = () => {
		this.setState({ totalselectedTag: 'Select', selectedTags: [] })
	}

	onLoadFunction = () => {	
		this.setState({ 
			filterData: [], 
			filterDetailsData: [],
			sidebarInputProvider : this.state.selectedProvider, 
			sidebarInputAccount : this.state.selectedAccount, 
			sidebarInputRegion : this.state.selectedRegion, 
			sidebarInputTags : this.state.selectedTags, 
			sidebarInputDuration : this.state.selectedDuration,
			overallCount: [],
			totalBilling: [],
		},
			() => {
				this.getOverAllAssetCount()
				this.getAssetByService()
			}
		)

	}

	getOverAllAssetCount = () => {
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'All') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		params.duration = this.state.selectedDuration
		
		if(this.state.selectedAccount.length) {
			params.account_id = this.state.selectedAccount
		}

		if(this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
			params.region = this.state.selectedRegion
		}

		if(this.state.selectedTags.length) {
			params.tags  = this.state.selectedTags
		}	
		
		this.props.getOverAllAssetCount(params, (promise, overallCount) => {			
			if (promise) {
				//console.log('overallCount', overallCount)
				this.setState({ overallCount, showLoading: false },
					() => {
						this.formDoughnutChartNew()
						this.getTotalBilling()
						//this.formDoughnutChart()
					}
				)
			} else {
				this.setState({ })
			}
		})
	}

	formDoughnutChartNew = () => {
		if(this.state.overallCount && Object.entries(this.state.overallCount).length) {
			Object.entries(this.state.overallCount).forEach(([key, value]) => {
				//service donut chart start
				let services = []
				let services_total_count = 0				

				if(key === "resource_type" && Object.entries(value).length) {
					Object.entries(value).forEach(([rtKey, rtValue]) => {
						let donutRow = {}
						donutRow.item_count = rtValue
						donutRow.item_name = rtKey
						services.push(donutRow)
						services_total_count += rtValue 
					})
					services = _.orderBy(services, ['item_count'], ['desc'])
					// services = services.slice(0,10)
					let resourceDonutData = {
						"items": services,
						"label": "Services",
						"total_count": services_total_count
					}

					this.setState({ resourceDonutData })
				}
				//service donut chart end

				//category donut chart start
				let category = []
				let category_total_count = 0				

				if(key === "category" && Object.entries(value).length) {
					Object.entries(value).forEach(([rtKey, rtValue]) => {
						let donutRow = {}
						donutRow.item_count = rtValue
						donutRow.item_name = rtKey
						category.push(donutRow)
						category_total_count += rtValue 
					})
					category = _.orderBy(category, ['item_count'], ['desc'])
					// category = category.slice(0,10)
					let categoryDonutData = {
						"items": category,
						"label": "Categories",
						"total_count": category_total_count
					}

					this.setState({ categoryDonutData })
				}
				//category donut chart end
			})
			// if(key === '' || key === '') {

		}
	}
	
	getTotalBilling = () => {
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'All') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		params.duration = '3M'
		
		// if(this.state.selectedAccount.length) {
		// 	params.account_id = this.state.selectedAccount
		// }

		// if(this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
		// 	params.region = this.state.selectedRegion
		// }

		// if(this.state.selectedTags.length) {
		// 	params.tags  = this.state.selectedTags
		// }
		if(this.state.selectedAccount.length) {
			params.filters = {
				'account_id': this.state.selectedAccount
			}
		}
		params.group_by = ["resource_type"]
		params.aggregate = "monthly"
		this.props.getTotalBilling(params, (promise, totalBilling) => {
			//console.log('totalBilling', totalBilling)
			if (promise) {
				this.setState({ totalBilling, showLoading: false })
			} else {
				this.setState({ })
			}
		})
	}

	getAssetByService = () => {		
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'All') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		params.duration = this.state.selectedDuration
		
		if(this.state.selectedAccount.length) {
			params.account_id = this.state.selectedAccount
		}

		if(this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
			params.region = this.state.selectedRegion
		}

		if(this.state.selectedTags.length) {
			params.tags  = this.state.selectedTags
		}
		
		if(this.state.selectedService && this.state.selectedService.length) {
			params.resource_type = this.state.selectedService
		}

		this.props.getAssetByService(params, (promise, assetService) => {
			if (promise) {
				let selectedCatSerDrop = []
				assetService.length && assetService.forEach(item => {
					selectedCatSerDrop.push(item.resource_type)
				})
				this.setState({ assetService, filterData: assetService, assetDetails: assetService, selectedCatSerDrop, selectedService: selectedCatSerDrop, showLoading: false },
					() => this.getAssetByServiceDetails()
				)
			} else {
				this.setState({ assetService: [], filterData: [] })
			}
		})
	}

	getAssetByServiceDetails = () => {
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'All') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		params.duration = this.state.selectedDuration
		
		if(this.state.selectedAccount.length) {
			params.account_id = this.state.selectedAccount
		}

		if(this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
			params.region = this.state.selectedRegion
		}

		if(this.state.selectedTags.length) {
			params.tags  = this.state.selectedTags
		}
		
		this.props.getAssetByServiceDetails(params, (promise, assetByServiceDetails) => {
			if (promise) {
				this.setState({ assetByServiceDetails, filterDetailsData: assetByServiceDetails })
			} else {
				this.setState({ assetByServiceDetails: [], filterDetailsData: [] })
			}
		})
	}
	
	getAssetByCategory = () => {
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'All') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		params.duration = this.state.selectedDuration
		
		if(this.state.selectedAccount.length) {
			params.account_id = this.state.selectedAccount
		}

		if(this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
			params.region = this.state.selectedRegion
		}

		if(this.state.selectedTags.length) {
			params.tags = this.state.selectedTags
		}
		
		if(this.state.selectedCategory && this.state.selectedCategory.length) {
			params.category = this.state.selectedCategory
		}
		
		//params.account_id = ["666665886686"]
		//params.region = []
		//params.tags = [{"key":"","value":""}]
		//params.provider = "aws"
		//params.duration = "+7d"

		this.props.getAssetByCategory(params, (promise, assetCategory) => {
			if (promise) {
				let selectedCatSerDrop = []
				assetCategory.length && assetCategory.forEach(item => {
					selectedCatSerDrop.push(item.category)
				})
				this.setState({ assetCategory, filterData: assetCategory, assetDetails: assetCategory, selectedCatSerDrop, selectedCategory: selectedCatSerDrop, showLoading: false, servCatDropdown: this.state.categoryArray },
					() => this.getAssetByCategoryDetails()
				)
			} else {
				this.setState({ assetCategory: [], filterData: [] })
			}
		})
	}

	getAssetByCategoryDetails = () => {
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'All') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		params.duration = this.state.selectedDuration
		
		if(this.state.selectedAccount.length) {
			params.account_id = this.state.selectedAccount
		}

		if(this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
			params.region = this.state.selectedRegion
		}

		if(this.state.selectedTags.length) {
			params.tags  = this.state.selectedTags
		}
		
		this.props.getAssetByCategoryDetails(params, (promise, assetByCategoryDetails) => {
			if (promise) {
				this.setState({ assetByCategoryDetails, filterDetailsData: assetByCategoryDetails })
			} else {
				this.setState({ assetByCategoryDetails: [] })
			}
		})
	}

	emptyDoughnutGraph = () => {
		let doughnutChartData = [];
		let doughnuGraphLabels = [];

		doughnuGraphLabels.push('No records');
		doughnutChartData.push(100);
		let backgroundColor = ['#ececec'];
		let hoverBackgroundColor = ['#ececec'];
			
		let datasetsColumn = [{
			data: doughnutChartData,
			backgroundColor: backgroundColor,
			hoverBackgroundColor: hoverBackgroundColor,
			barThickness : 5,
		}];

		let doughnut_datas = {labels:doughnuGraphLabels, datasets: datasetsColumn}; 

		let doughnut_option = {
			tooltips: {
				callbacks: {
					label: function(tooltipItem, doughnutChartData) {
						//let value = doughnutChartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
						//let color = doughnutChartData.datasets[tooltipItem.datasetIndex].backgroundColor[tooltipItem.index]
						let label = doughnutChartData.labels[tooltipItem.index]
						return label;
					}
				}
			},
			cutoutPercentage: 50,
			animate   : true,
			animation: { duration: 1000 },
			maintainAspectRatio: false,
			responsive: false,
			legend: {				
				display:true,
				position: 'right',
				labels: {
					boxWidth: 15,
					fontSize: 9
				}
			}
		}

		this.setState({empty_doughnut_data:doughnut_datas, empty_doughnut_option:doughnut_option});
	}

	getAssetDetails = () => {
		if(this.state.selectedType === 'Service') {
			if(this.state.assetService.length) {
				this.setState({ 
					filterData: this.state.assetService, 
					filterDetailsData: this.state.assetByServiceDetails, 
					servCatDropdown: this.state.servicelist, 
					selectedCatSerDrop: this.state.selectedService,
					showLoading: false 
				})
			} else {
				this.getAssetByService()
			}
		} else {
			if(this.state.assetCategory.length) {
				this.setState({ filterData: this.state.assetCategory, 
					filterDetailsData: this.state.assetByCategoryDetails,
					servCatDropdown: this.state.categoryArray,
					selectedCatSerDrop: this.state.selectedCategory,
					showLoading: false 
				})
			} else {
				this.getAssetByCategory()
			}
		}
	}

	handleMultiSelectChange = (field, arrayValue) => {
		let value = arrayValue.map(item => item.value)
		// let label = arrayValue.map(item => item)		
		let selectedValue = []
		if(field === 'selectedAccount' || field === 'selectedRegion') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					if(field === "selectedAccount") {
						this.state.accounts.forEach(item => {
							selectedValue.push(item.account_id)
						})
					} else if(field === "selectedRegion") {
						this.state.regions.forEach(item => {
							selectedValue.push(item.region)
						})
					}
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}
			
			if(selectedValue.length && !selectedValue.includes('All')) {
				if(field === "selectedAccount") {
					if(!selectedValue.includes('All') && selectedValue.length === (this.state.accounts.length -1)) {
						selectedValue.push('All')
					}
				} else if(field === "selectedRegion") {
					if(!selectedValue.includes('All') && selectedValue.length === (this.state.regions.length -1)) {
						selectedValue.push('All')
					}
				}
			}
			this.setState({ [field]: selectedValue },
				() => this.clearTag()
			)
		} else if(field === 'selectedTagValue') {
			this.setState({ selectedTagValue: value })
		} else if(field === 'selectedCatSerDrop') {			
			if(value !== '' && (value.includes("All") || this.state.selectedCatSerDrop.includes("All"))) {
				if(this.state.selectedType === 'Services') {
					this.setState({ selectedCatSerDrop: ['All'], selectedService: ['All'] },
						() => this.getAssetByService()
					)
				} else {
					this.setState({ selectedCatSerDrop: ['All'], selectedCategory: ['All']  },
						() => this.getAssetByCategory()
					)
				}				
			} else {
				if(this.state.selectedType === 'Service') {
					this.setState({ selectedCatSerDrop: value, selectedService: value }, 
						() => this.getAssetByService()
					)
				} else {
					this.setState({ selectedCatSerDrop: value, selectedCategory: value }, 
						() => this.getAssetByCategory()
					)
				}
			}
		}
	}

	getMultiSelectedCount = (type, array) => {
		// if(type === 'status') {
			return array.length && array.includes('All') ? 'All' :  array.length ? array.length +' Selected' : 'All'
		// } else {
		// 	return array.length && array.includes('All') ? array.length - 1 +' Selected' :  array.length ? array.length +' Selected' : 'All'
		// }
	}

	filterService = () => {
		this.setState({ showLoading: true })
		let filterData = this.state.assetCategory
		if(this.state.selectedType === 'Service') {
			filterData = this.state.assetService
		}
		let formArray = []
		if(this.state.selectedCatSerDrop) {
			this.state.selectedCatSerDrop.length ?
				this.state.selectedCatSerDrop.forEach(val=> {
					if(this.state.selectedType === 'Service') {
						let result = filterData.filter(filter => filter.resource_type === val)
						result.forEach(res=> {
							formArray.push(res);
						})
					} else {
						let result = filterData.filter(filter => filter.category === val)
						result.forEach(res=> {
							formArray.push(res);
						})
					}
				})
			: 
			formArray = filterData
		}
		this.setState({ filterData: formArray, showLoading: false })
		
	}
		
	handleClickOutside(event) {
		
		if (this.accountRef && !this.accountRef.current.contains(event.target)) {
			this.setState({ isAccountOpen: false })
		} else {
			this.setState({ isAccountOpen: true })
		}
		
		// if (this.tagRef && !this.tagRef.current.contains(event.target)) {
		// 	this.setState({ isTagOpen: false })
		// } else {
		// 	this.setState({ isTagOpen: true })
		// }

		if (this.regionRef && !this.regionRef.current.contains(event.target)) {
			this.setState({ isRegionOpen: false })
		} else {
			this.setState({ isRegionOpen: true })
		}

		if(this.state.filterData.length) {
			if (this.serviceRef && !this.serviceRef.current.contains(event.target)) {
				this.setState({ isServiceOpen: false })
			} else {
				this.setState({ isServiceOpen: true })
			}
		}
		
	}

	formDoughnutChart = () => {
		
		let overlAllData = this.state.overallCount
		let length = 7
		Object.entries(overlAllData).forEach(([key,value]) => {
			let data = value
			let doughnutChartData = [];
			let doughnuGraphLabels = [];
			let backgroundColor = [];
			let hoverBackgroundColor = [];
			if(key === 'resource_type') {
				if(Object.entries(data).length) {
					backgroundColor = [
						'#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'
					];
					hoverBackgroundColor = [
						'#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'
					];
					let sum = 0;
					Object.entries(data).slice(0,length).forEach(([key,value]) => {
						sum = sum + parseInt(value)
						doughnuGraphLabels.push(key+': '+value);
						doughnutChartData.push(value);
					})
					
					if(sum === 0) {
						doughnutChartData = [100, 100];
						backgroundColor = ['#ececec'];
						hoverBackgroundColor = ['#ececec'];
					}
				} else {
					doughnuGraphLabels.push('No records');
					doughnutChartData.push(100);
					backgroundColor = ['#ececec'];
					hoverBackgroundColor = ['#ececec'];
				}
				
				let datasetsColumn = [{
					data: doughnutChartData,
					backgroundColor: backgroundColor,
					hoverBackgroundColor: hoverBackgroundColor,
					barThickness : 5,
				}];
			
				let doughnut_datas = {labels:doughnuGraphLabels, datasets: datasetsColumn}; 
				
				let doughnut_option = {
					tooltips: {
						callbacks: {
							label: function(tooltipItem, doughnutChartData) {
								//let value = doughnutChartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
								//let color = doughnutChartData.datasets[tooltipItem.datasetIndex].backgroundColor[tooltipItem.index]
								let label = doughnutChartData.labels[tooltipItem.index]
								return label;
							}
						}
					},
					cutoutPercentage: 50,
					animate   : true,
					animation: { duration: 1000 },
					maintainAspectRatio: false,
					responsive: false,
					legend: {				
						display:true,
						position: 'right',
						labels: {
							boxWidth: 15,
							fontSize: 9
						}
					}
				}
			
				// var graph = {};
				// graph.doughnut_datas = doughnut_datas
				// graph.doughnut_option = doughnut_option
			
				// return graph;
				this.setState({ resourceTypeData: doughnut_datas, resourceTypeOptions: doughnut_option})
			}
		})
	}

	removefilter = (index, value) => {
		let filterArray = this.state.selectedCatSerDrop
		filterArray.splice(index, 1);
		if(this.state.selectedType === 'Service') {
			this.setState({ selectedCatSerDrop : filterArray, selectedService: filterArray },
				() => this.filterService()
			)
		} else {
			this.setState({ selectedCatSerDrop : filterArray, selectedCategory: filterArray },
				() => this.filterService()
			)
		}
    }

	/**
	 * Renders Asset Board
	 */
	render() {
		return (
			<div className='container-fluid overflow-auto flex-grow-1' onClick={ (event) => { this.handleClickOutside(event) } }>
				{this.state.showDetailsPanel ? (            
					<SidePanel
                        closeSidePanel={() => this.setState({ showDetailsPanel: false })}
                        selectedProvider = {this.state.sidebarInputProvider}
						accountsList = {this.state.accounts}
                        selectedAccount = {this.state.sidebarInputAccount}
						selectedRegion = {this.state.sidebarInputRegion}
						selectedTags = {this.state.sidebarInputTags}
                        selectedDuration = {this.state.sidebarInputDuration}	
						selectedServiceDetail = {this.state.selectedServiceDetail}
						/* selectedProvider = 'aws'
                        selectedAccount = ''
						selectedRegion = ''
						selectedTags = ''
                        selectedDuration = '+30d'
						selectedServiceDetail = 'EC2' */
					/>
				) : null}
				<div className='row h-100'>
					<div className='col-sm-12 p-0'>
						<div className='title-section' style={{padding: "1rem 1rem 1rem 1rem"}} >
							<div className='row'>
								<div className='col-lg-4 d-flex align-self-center'>
									<div>
										<div className='d-flex'>
                                            <h6 className='text-white m-0'>Resource Count</h6>
                                        </div>
										<p className='text-white mb-2 '>
											Count board view of resources on your Cloud
										</p>
									</div>
								</div>
								<div className={`col-lg-8 text-right`}>
									<div className='d-flex'>
										<div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: '150px' }}>
											<label className="mb-1">Provider</label>
											<Select
												placeholder={'All'}
												isSearchable={false}
												//onFocus={this.openProivderMenu}
												className='f13 p-0 colorBlack reactSelectFilterDrop'
												value={({
													value: this.state.selectedProvider,
													label: this.state.selectedProvider ? this.state.selectedProvider : "All"
												})}
												options={this.state.providers.map(item => ({
													value: item.provider_name,
													label: item.provider_name,	
												}))}
												onChange={event =>  
													this.setState(
														{
															selectedProvider: event.value,
															showLoading: true
														}
													)
												}
											/>
										</div>
										{/* <div 
											className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 ${!this.state.providers
												.length}`}>
											<label>Provider</label>
											<select
												className='form-control'
												value={this.state.selectedProvider}
												onChange={event => {
													this.setState(
														{
															selectedProvider: event.target.value,
															showLoading: true,
														},
														() => this.filterIdentifiers('provider')
													)
												}}
											>
												<option value='all'>All</option>
												{this.state.providers.length &&
													this.state.providers.map(provider => {
														return (
															<option key={provider.provider_id} value={provider.provider_name}>
																{provider.provider_name}
															</option>
														)
													})}
											</select>
										</div> */}
										{this.state.tagFilter ?
											<TagFilterSection 
												data = {this.selectedTagFunction.bind(this)}
												selectedProvider = {this.state.selectedProvider.toLowerCase()}
												selectedAccount = {this.state.selectedAccount}
												selectedRegion = {this.state.selectedRegion}
												selectedTags = {this.state.selectedTags}
											/>
										: 
										null
										}
										<div	
											className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 ${!this.state.providers
											.length}`} ref={this.accountRef} style={{maxWidth: '200px' }}>
											<label className="mb-1">Account</label>
											<div className='custom-dropdown assetDropdown removeDropdownSearchBar'>
												<ReactMultiSelectCheckboxes						
													placeholderButtonLabel="All"
													menuIsOpen ={this.state.isAccountOpen}
													value={this.state.selectedAccount.map(acc => ({
														value: acc
													}))}
													getDropdownButtonLabel={() => this.getMultiSelectedCount('account', this.state.selectedAccount)}
													onChange={arr => {
													this.handleMultiSelectChange('selectedAccount', arr ? arr : []) }}
													options={this.state.accounts.map(account => ({
														value: account.account_id,
														label: account.account_name,
													}))}
												/>
											</div>
										</div>
										<div
											className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 ${!this.state.providers
												.length}`} ref={this.regionRef} style={{maxWidth: '200px' }}>
											<label className="mb-1">Region</label>
												<div className={`custom-dropdown assetDropdown ${this.state.regions && this.state.regions.length > 10 ? '' : 'removeDropdownSearchBar' } `}>
													<ReactMultiSelectCheckboxes						
														placeholderButtonLabel="Select"
														menuIsOpen ={this.state.isRegionOpen}
														value={this.state.selectedRegion.map(reg => ({
															value: reg
														}))}
														getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedRegion', this.state.selectedRegion)}
														onChange={arr => {
														this.handleMultiSelectChange('selectedRegion', arr ? arr : []) }}
														options={this.state.regions && this.state.regions.map(region => ({
															value: region.region,
															label: region.name,
														}))}
													/>
											</div>
										</div>
										<div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} style={{maxWidth: '150px' }} onClick={() => this.setState({ tagFilter: this.state.tagFilter ? false : true }) } >
											<label>Tag</label>
											<br/>
											<label>{this.state.totalselectedTag} </label>
										</div>

										<div className='form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3' style={{maxWidth: '125px' }}>
											<label>Duration</label>
											<select
												className='form-control'
												value={this.state.selectedDuration}
												onChange={event => {
													this.setState(
														{
															selectedDuration: event.target.value,
														}
													)
												}}
											>
												{DURATION_FILTER_OPTIONS.map((item, index) => {
													return (
														<option key={index} value={item.value}>
															{item.option}
														</option>
													)
												})}
											</select>
										</div>
										
										<div className={`ml-3 align-self-center text-center`}>
											{/* <button className="btn btn-sm btn-primary" onClick={()=> this.setState({ selectedType: 'Service', }, () => this.onLoadFunction()) }>Search</button> */}
											{/* <span className={`${this.state.showLoading ? 'disabled text-muted' : 'text-white cursorPointer'}`} onClick={()=> this.setState({ selectedType: 'Service', }, () => this.onLoadFunction()) }><i className="fa fa-search " aria-hidden="true" style={{fontSize: '26px'}}></i></span>
											<br />
											<button className={`resetBtn ${this.state.showLoading ? 'disabled text-muted' : 'text-white cursorPointer'} mt-1`} onClick={()=> this.onReset()}>Reset</button> */}
											<div className="btn-group" role="group" aria-label="Asset-board">
												<button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-right`} onClick={()=> this.setState({ selectedType: 'Service', }, () => this.onLoadFunction()) }>Search</button>
												<button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-left`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
											</div>
										</div>
										{/* <div className='form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3'>
											<label>Type</label>
											<select
												className='form-control'
												value={this.state.selectedType}
												onChange={event => {
													this.setState(
														{
															selectedType: event.target.value,
														},
														() => this.getAssetDetails()
													)
												}}
											>
												<option value='Service'>Service</option>
												<option value='Category'>Category</option>
											</select>
										</div> */}
									</div>								
								</div>
							</div>
						</div>
						<div className='bashboard-bg'>
							<div className="box shadow-none">
								{Object.entries(this.state.overallCount).length ?
									<div className="container-fluid mt-3 pb-3 borderMainSection">
										<div className="row">
											<div className="col-12">
												<h5>Resource Summary</h5>
												<p className="text-muted">Overview of Cloud resource numbers grouped by account, region, services, etc.</p>
											</div>
										</div>
										<div className="row">
											<div className="col-sm-3">
												<div className="d-flex justify-content-between mb-2">
													<p className="mb-0 small">Account {Object.entries(this.state.overallCount.account_id).length > 5 ? '(5 of '+ Object.entries(this.state.overallCount.account_id).length +')' : '('+Object.entries(this.state.overallCount.account_id).length +' of '+ Object.entries(this.state.overallCount.account_id).length +')' }</p>
													<p className={`text-info mb-0 small cursorPointer ${Object.entries(this.state.overallCount.account_id).length > 5 ? '' : 'displayNone'} `} id={`allAccounts`}>See more...</p>
													{Object.entries(this.state.overallCount.account_id).length ?
														<UncontrolledPopover className="regionPopOver" trigger="hover" placement="top" target={`allAccounts`}>
															<PopoverBody>
																{Object.entries(this.state.overallCount.account_id).map(([key, acc], i) => {
																	return(
																		<React.Fragment>
																			{i===0 ? <p className={`font-weight-bold mb-2`}>Accounts</p> : null }			
																			<div className={`w-50 ${i%2 ? 'float-right' : 'float-left border-right' } `}>
																				<div className="d-flex justify-content-between mb-2 ml-2">
																					<div className="mb-0">
																						<h8 className="font-weight-bold blackMuted">{capitalizeFirstLetter(getAccountNameFromId(key, this.state.accounts))}</h8>
																																													<p className="mb-2 f12 text-muted">{key}</p>
																																												</div>
																					<div className="align-self-center mr-3">
																						<h8 className="mb-0 font-weight-bold blackMuted">{acc}</h8>
																					</div>
																				</div>
																			</div>
																		</React.Fragment>
																	)
																})}
															</PopoverBody>
														</UncontrolledPopover>
													: null }
												</div>
												{Object.entries(this.state.overallCount.account_id).map(([key, acc]) => {
													return(
													<div className="d-flex justify-content-between border-bottom mb-2">
														<div>
															<h7 className="font-weight-bold blackMuted">{capitalizeFirstLetter(getAccountNameFromId(key, this.state.accounts))}</h7>
															<p className="mb-2 f12 text-muted">{key}</p>
														</div>
														<div className="align-self-center">
															<h7 className="mb-0 font-weight-bold blackMuted">{acc}</h7>
														</div>
													</div>
													)

												})}
												
												<div className="justify-content-between mb-2">
													<div className="overflow-auto mb-2">
														<ul className="nav nav-tabs bg-transparent" id="myTab" role="tablist">
															<li className="nav-item w-50 text-left">
																<a
																	className={`nav-link cursor-pointer ${this.state.activeTab === 0 ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
																	style={{fontSize:'12px'}}
																	onClick={() => this.setState(
																		{ activeTab: 0 }
																	)}
																	href={() => false}
																>
																By Service
																</a>
															</li>
															<li className="nav-item w-50 text-left">
																<a 
																	className={`nav-link cursor-pointer ${this.state.activeTab === 1 ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
																	style={{fontSize:'12px'}}
																	onClick={() => this.setState({ activeTab: 1 }
																	)}
																	href={() => false}
																>
																	By Category
																</a>
															</li>
														</ul>
													</div>
													{this.state.activeTab === 0 && this.state.resourceDonutData ?
														<ApexDonutChart
															graphData={this.state.resourceDonutData}
															legend={true}
															showTotal={true}
															showTotalLable={true}
															showGraphHeading={false}
															height={180}
															size={'70%'}
															gradient={false}
															colors={['#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5']}
															gradientColor={['#A88CCC', '#D88ACF']}
															className={"transparentTooltip leftSide"}
															emptyGraphColor={"#2D323E"}
															totalColor={'#000000'}
														/>
													: null}
													{this.state.activeTab === 1 && this.state.categoryDonutData ?
														<ApexDonutChart
															graphData={this.state.categoryDonutData}
															legend={true}
															showTotal={true}
															showTotalLable={true}
															showGraphHeading={false}
															height={180}
															size={'70%'}
															gradient={false}
															colors={['#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5']}
															gradientColor={['#A88CCC', '#D88ACF']}
															className={"transparentTooltip leftSide"}
															emptyGraphColor={"#2D323E"}
															totalColor={'#000000'}
														/>
													: null}
												</div>
											</div>
											<div className="col-sm-9">
												<div className="bg-light d-flex rounded px-3 py-2 justify-content-between">
													{Object.entries(this.state.overallCount).forEach(([key, value]) => {	

														if(key === 'availability' || key === 'management' || key === 'pricing_model' || key === 'status' || key === 'action') {
															let targetId = ''
															let label = ''
															if(key === 'availability') {
																targetId = 'Availability_Circle'
																label = 'Availability'
															} else if(key === 'management') {
																targetId = 'Managed_Circle'
																label = 'Managed'
															} else  if(key === 'pricing_model') {
																targetId = 'Purchase_Circle'
																label = 'Purchase'
															} else if(key === 'status'){
																targetId = 'Status_Circle'
																label = 'Status'
															} else  if(key === 'action') {
																targetId = 'Lifecycle_Circle'
																label = 'Lifecycle'
															}
															return (
															<div className="w-13">
																<p className="m-0 p-0 text-center" id={targetId}>{label}</p>
																<Chart options={value.options} series={value.series} type="donut"/>
																<UncontrolledPopover trigger="hover" placement="top" target={targetId}>
																	<PopoverBody>
																		{Object.entries(value.data).map(([subKey, subValue], j) => {
																		return(
																		<React.Fragment>
																			{j===0 ?
																			<p className={`font-weight-bold mb-2`}>{label}</p>
																			: null }
																			
																			<div className={`pb-2`}>
																				<div className="d-flex mb-2">
																					<p className="mb-0 text-left">{subKey}: <span className="font-weight-bold mb-0 text-left">{subValue}</span></p>
																				</div>
																			</div>
																		</React.Fragment>
																		)
																	})}
																	</PopoverBody>
																</UncontrolledPopover>
															</div>
															)
														}
													})}
												</div>
												{Object.entries(this.state.overallCount).length ?
													<div className="row">
														<div className="col-sm-6">
															<div className="bar-chart-label">
																<p className="label cursorPointer" id={`allRegion`}>Region <i className='fad fa-info-circle'></i> {this.state.overallCount.region && this.state.overallCount.region.showingAvailabilityZones === 1 ? '/AZ Distribution' : null }</p>
																<div className="d-flex mt-3 mb-3">
																	<div className="w-100">
																		<div className="progress">
																			{this.state.overallCount.region && Object.entries(this.state.overallCount.region.data).length ?
																				<React.Fragment>
																					<UncontrolledPopover className="regionPopOver" trigger="hover" placement="top" target={`allRegion`}>
																						<PopoverBody>
																							{this.state.overallCount.region.data.map((item,i) => {
																								return(
																								<React.Fragment>
																									{i===0 ?
																									<p className={`font-weight-bold mb-2`}>Region {item.showingAvailabilityZones === 1 ? '/AZ Distribution of' + item.region : null }</p>
																									: null }
																									
																									<div className={`w-50 pb-2 cursorPointer ${i%2 ? 'float-right pl-2' : 'float-left border-right' } `}>
																										<div className="d-flex mb-2">
																											<p className="mb-0 text-left">{item.key}: <span className="font-weight-bold mb-0 text-left">{item.count}</span></p>
																										</div>
																									</div>
																								</React.Fragment>
																								)
																							})}
																						</PopoverBody>
																					</UncontrolledPopover>
																					{this.state.overallCount.region.data.map((item,i) => {														
																						let totalCount = this.state.overallCount.region.totalCount
																						return (
																							<div className="progress-bar" role="progressbar" id={`region_${i}`} style={{backgroundColor:item.color, width: parseFloat(item.count*100/totalCount)+'%'}} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">
																							<UncontrolledTooltip placement='top' target={`region_${i}`}>{capitalizeFirstLetter(item.key) +': '+item.count}</UncontrolledTooltip>
																							</div>
																						)
																					})}
																				</React.Fragment>
																			:
																				<div style={{width: '100%'}} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
																			}
																		</div>
																	</div>
																</div>
															</div>
															<div className="bar-chart-label mt-4">
																<p className="label cursorPointer" id={`allOs`}>OS <i className='fad fa-info-circle'></i></p>
																<div className="d-flex mt-3 mb-3">
																	<div className="w-100">
																		<div className="progress">
																			{this.state.overallCount.os && Object.entries(this.state.overallCount.os.data).length ?
																				<React.Fragment>
																					<UncontrolledPopover className="regionPopOver" trigger="hover" placement="top" target={`allOs`}>
																						<PopoverBody>
																							{this.state.overallCount.os.data.map((item,i) => {
																								return(
																									<React.Fragment>
																										{i===0 ?
																											<p className={`font-weight-bold mb-2`}>OS</p>
																										: null }
																											<div className={`w-50 pb-2 cursorPointer ${i%2 ? 'float-right pl-2' : 'float-left border-right' } `}>
																												<div className="d-flex mb-2">
																													<p className="mb-0 text-left">{item.key}: <span className="font-weight-bold mb-0 text-left">{item.count}</span></p>
																												</div>
																											</div>
																									</React.Fragment>
																								)
																							})}
																						</PopoverBody>
																					</UncontrolledPopover>
																				
																					{this.state.overallCount.os.data.map((item,i) => {
																						let totalCount = this.state.overallCount.os.totalCount
																						return (
																							<div className="progress-bar" role="progressbar" id={`os_${i}`} style={{backgroundColor:item.color, width: parseFloat(item.count*100/totalCount)+'%'}} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">
																							<UncontrolledTooltip placement='top' target={`os_${i}`}>{capitalizeFirstLetter(item.key) +': '+item.count}</UncontrolledTooltip>
																							</div>
																						)
																					})}
																				</React.Fragment>
																			:
																				<div style={{width: '100%'}} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
																			}
																		</div>
																	</div>
																</div>
															</div>
															
															<div className="bar-chart-label mt-4">
																<p className="label cursorPointer" id={`allEngine`}>Engines <i className='fad fa-info-circle'></i></p>
																<div className="d-flex mt-3 mb-3">
																	<div className="w-100">
																		<div className="progress">
																		{this.state.overallCount.engine && Object.entries(this.state.overallCount.engine.data).length ? 
																			<React.Fragment>
																				<UncontrolledPopover className="regionPopOver" trigger="hover" placement="top" target={`allEngine`}>
																					<PopoverBody>
																						{this.state.overallCount.engine.data.map((item,i) => {
																							return(
																								<React.Fragment>
																									{i===0 ?
																										<p className={`font-weight-bold mb-2`}>Engines</p>
																									: null }
																										<div className={`w-50 pb-2 cursorPointer ${i%2 ? 'float-right pl-2' : 'float-left border-right' } `}>
																											<div className="d-flex mb-2">
																												<p className="mb-0 text-left">{item.key}: <span className="font-weight-bold mb-0 text-left">{item.count}</span></p>
																											</div>
																										</div>
																								</React.Fragment>
																							)
																						})}
																					</PopoverBody>
																				</UncontrolledPopover>
																				{this.state.overallCount.engine.data.map((item,i) => {
																					let totalCount = this.state.overallCount.engine.totalCount
																					
																					return (
																						<div className="progress-bar" role="progressbar" id={`engine_${i}`} style={{backgroundColor:item.color, width: parseFloat(item.count*100/totalCount)+'%'}} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">
																						<UncontrolledTooltip placement='top' target={`engine_${i}`}>{capitalizeFirstLetter(item.key) +': '+item.count}</UncontrolledTooltip>
																						</div>
																					)
																				})}
																			</React.Fragment>
																		:
																			<div style={{width: '100%'}} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
																		}
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="col-sm-6">
															<div className="bar-chart-label">
																<p className="label cursorPointer" id={`allLanguage`}>Languages <i className='fad fa-info-circle'></i></p>
																<div className="d-flex mt-3 mb-3">
																	<div className="w-100">
																		<div className="progress">
																		{this.state.overallCount.language && Object.entries(this.state.overallCount.language.data).length ?
																			<React.Fragment>
																				<UncontrolledPopover className="regionPopOver" trigger="hover" placement="top" target={`allLanguage`}>
																					<PopoverBody>
																						{this.state.overallCount.language.data.map((item,i) => {
																							return(
																								<React.Fragment>
																									{i===0 ?
																										<p className={`font-weight-bold mb-2`}>Languages</p>
																									: null }
																										<div className={`w-50 pb-2 cursorPointer ${i%2 ? 'float-right pl-2' : 'float-left border-right' } `}>
																											<div className="d-flex mb-2">
																												<p className="mb-0 text-left">{item.key}: <span className="font-weight-bold mb-0 text-left">{item.count}</span></p>
																											</div>
																										</div>
																								</React.Fragment>
																							)
																						})}
																					</PopoverBody>
																				</UncontrolledPopover>
																				
																				{this.state.overallCount.language.data.map((item,i) => {
																				let totalCount = this.state.overallCount.language.totalCount
																				
																					return (
																						<div className="progress-bar" role="progressbar" id={`language_${i}`} style={{backgroundColor:item.color, width: parseFloat(item.count*100/totalCount)+'%'}} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">
																						<UncontrolledTooltip placement='top' target={`language_${i}`}>{capitalizeFirstLetter(item.key) +': '+item.count}</UncontrolledTooltip>
																						</div>
																					)
																				})}
																			</React.Fragment>
																		:
																			<div style={{width: '100%'}} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
																		}
																		
																		</div>
																	</div>
																</div>
															</div>
														
															<div className="bar-chart-label mt-4">
																<p className="label cursorPointer" id={`allChanges`}>Changes <i className='fad fa-info-circle'></i></p>
																<div className="d-flex mt-3 mb-3">
																	<div className="w-100">
																		<div className="progress">
																			{this.state.overallCount.changes && Object.entries(this.state.overallCount.changes.data).length ? 
																				<React.Fragment>
																					<UncontrolledPopover className="regionPopOver" trigger="hover" placement="top" target={`allChanges`}>
																						<PopoverBody>
																							{this.state.overallCount.changes.data.map((item,i) => {
																								return(
																									<React.Fragment>
																										{i===0 ?
																											<p className={`font-weight-bold mb-2`}>Changes</p>
																										: null }
																											<div className={`w-50 pb-2 cursorPointer ${i%2 ? 'float-right pl-2' : 'float-left border-right' } `}>
																												<div className="d-flex mb-2">
																													<p className="mb-0 text-left">{item.key}: <span className="font-weight-bold mb-0 text-left">{item.count}</span></p>
																												</div>
																											</div>
																									</React.Fragment>
																								)
																							})}
																						</PopoverBody>
																					</UncontrolledPopover>

																					{this.state.overallCount.changes.data.map((item,i) => {
																						let totalCount = this.state.overallCount.changes.totalCount
																						return (
																							<div className="progress-bar" role="progressbar" id={`changes_${i}`} style={{backgroundColor:item.color, width: parseFloat(item.count*100/totalCount)+'%'}} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">
																							<UncontrolledTooltip placement='top' target={`changes_${i}`}>{capitalizeFirstLetter(item.key) +': '+item.count}</UncontrolledTooltip>
																							</div>
																						)
																					})}
																				</React.Fragment>
																			:
																				<div style={{width: '100%'}} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
																			}
																		</div>
																	</div>
																</div>
															</div>

															<div className="bar-chart-label mt-4">
																<p className="label cursorPointer" id={`allPatches`}>Patches <i className='fad fa-info-circle'></i></p>
																<div className="d-flex mt-3 mb-3">
																	<div className="w-100">
																		{/* <div className="legend text-muted f12">
																			<span className="legend-square"></span>
																		</div> */}
																		<div className="progress">
																			{this.state.overallCount.patches && Object.entries(this.state.overallCount.patches.data).length ?
																				<React.Fragment>
																					<UncontrolledPopover className="regionPopOver" trigger="hover" placement="top" target={`allPatches`}>
																						<PopoverBody>
																							{this.state.overallCount.patches.data.map((item,i) => {
																								return(
																									<React.Fragment>
																										{i===0 ?
																											<p className={`font-weight-bold mb-2`}>Patches</p>
																										: null }
																											<div className={`w-50 pb-2 cursorPointer ${i%2 ? 'float-right pl-2' : 'float-left border-right' } `}>
																												<div className="d-flex mb-2">
																													<p className="mb-0 text-left">{item.key}: <span className="font-weight-bold mb-0 text-left">{item.count}</span></p>
																												</div>
																											</div>
																									</React.Fragment>
																								)
																							})}
																						</PopoverBody>
																					</UncontrolledPopover>	
																					{this.state.overallCount.patches.data.map((item,i) => {
																						let totalCount = this.state.overallCount.patches.totalCount
																						return (
																							<div className="progress-bar" role="progressbar" id={`patches_${i}`} style={{backgroundColor:item.color, width: parseFloat(item.count*100/totalCount)+'%'}} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">
																							<UncontrolledTooltip placement='top' target={`patches_${i}`}>{capitalizeFirstLetter(item.key) +': '+item.count}</UncontrolledTooltip>
																							</div>
																						)
																					})}
																				</React.Fragment>
																			:
																				<div style={{width: '100%'}} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
																			}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												: null
												}
												<table className="mt-5 w-100">
													<tbody>
														<tr>
															<td>
																<div className="stock-bar">
																	<ul className="stock-legend mb-1 p-0">
																		<li><span className="text-muted d-inline-block f12 mb-1" id={`allBilling`}> By Billing (USD $)</span></li> 
																		{Object.entries(this.state.totalBilling).length && this.state.totalBilling.data.length ?
																			<UncontrolledPopover className="regionPopOver" trigger="hover" placement="top" target={`allBilling`}>
																				<PopoverBody>
																					{this.state.totalBilling.data.map((item,i) => {
																						return(
																						<React.Fragment>
																							{i===0 ?
																							<p className={`font-weight-bold mb-2`}>Billing Details</p>
																							: null }
																							
																							<div className={`w-50 pb-2 cursorPointer ${i%2 ? 'float-right pl-2' : 'float-left border-right' } `}>
																								<div className="d-flex mb-2">
																									<p className="mb-0 text-left">{item.label}: <span className="font-weight-bold mb-0 text-left">{item.total_cost}</span></p>
																								</div>
																							</div>
																						</React.Fragment>
																						)
																					})}
																				</PopoverBody>
																			</UncontrolledPopover>
																		: null }
																	</ul>
																	<div className="table-div">
																		{Object.entries(this.state.totalBilling).length ? this.state.totalBilling.data.map((bill, i) =>{
																		let totalBilling = this.state.totalBilling.totalCost;
																		
																		return(
																			<React.Fragment>
																		<div id={`billing_${i}`} style={{width: parseFloat(bill.count*100/totalBilling) + '%', backgroundColor: bill.color}}></div>
																		<UncontrolledTooltip placement='top' target={`billing_${i}`}>{capitalizeFirstLetter(bill.label) +': '+bill.total_cost}</UncontrolledTooltip>
																		</React.Fragment>
																		)
																	})
																	:
																	<div className="w-100 bgGray"></div>
																	}
																	</div>
																</div>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								: null}
								<div className="container-fluid mt-4">
									{!this.state.showLoading && this.state.filterData && this.state.filterData.length ? 
										<div className="col-sm-12 m-0 p-0">
											<div className='d-flex mb-3'>
												<div>
													<select
														className='customDropdown float-right mr-3'
														value={this.state.selectedType}
														onChange={event => {
															this.setState(
																{
																	selectedType: event.target.value,
																	filterData: [],
																	filterDetailsData: [], 
																	showLoading: true
																},
																() => this.getAssetDetails()
															)
														}}
													>
														<option value='Service'>Service</option>
														<option value='Category'>Category</option>
													</select>
												</div>
												
												<div
													className={`mr-sm-3`} 
													ref={this.serviceRef} 
													style={{minWidth: '100px', maxWidth: '200px' }}>
													<div className={`innerMultiDrop ${this.state.regions.length > 10 ? '' : 'removeDropdownSearchBar' } `}>
														<ReactMultiSelectCheckboxes						
															placeholderButtonLabel="All"
															menuIsOpen ={this.state.isServiceOpen}
															value={this.state.selectedCatSerDrop && this.state.selectedCatSerDrop.map(value => ({
																value: value,
																label: value
															}))}
															//groupHeading={'indeterminate '}
															setState={this.state.selectedCatSerDrop}
															/* selectedValues={this.state.selectedCatSerDrop} */
															onChange={arr => {
															this.handleMultiSelectChange('selectedCatSerDrop', arr ? arr : []) }}
															options={this.state.servCatDropdown && this.state.servCatDropdown.map(cat => ({
																value: cat.label,
																label: cat.value
															}))}
														/>
													</div>
												</div>
												<div className="ml-3">
													{this.state.selectedCatSerDrop.length ? 
														this.state.selectedCatSerDrop.map((row, index) =>
															<span className="badge badge-light f14 mr-1 mb-1" >
															{row} 
																<i onClick={ () => this.removefilter(index, row) } className='ml-1 fal fa-times cursorPointer'></i>
															</span>
														)
													: null
													}
												</div>
											</div>
										
											{this.state.filterData.map((cat, key) => {
												let totalAction = 
												parseFloat(cat.actions.deleted ? cat.actions.deleted : 0) +  parseFloat(
												cat.actions.modified ? cat.actions.modified : 0) + parseFloat(cat.actions.created ? cat.actions.created : 0)
												let progressCircle = []
												progressCircle.push(cat.status.active ? cat.status.active : 0)
												progressCircle.push(cat.status.inactive ? cat.status.inactive : 0)
												let services = cat.category ? cat.category : cat.resource_type
												return (
												<div key={key}>
												<div className="row mt-3">
													<div className="col-sm-6">
														<div className="d-flex">
															<h3>{services}</h3>
															<span className={`ml-3 text-info align-self-center cursorPointer ${this.state.selectedType === 'Service' ? '' : 'displayNone'} `} onClick={ () => this.setState({showDetailsPanel:true, selectedServiceDetail: services})}>Show Details</span>
														</div>
														<p className="text-muted">{services} resources summary grouped by state, lifecyle, sub-services</p>
													</div>
													<div className="col-sm-2">&nbsp;</div>
													<div className="col-sm-4 align-self-center">
														<table className="float-right w-75">
															<tbody>
																<tr>
																	<td>
																		<div className="stock-bar">
																			<ul className="stock-legend mb-1 p-0"> 
																				<li><span className="legend-circle-low"></span> <span className="text-muted d-inline-block f12"> &nbsp; Created: {cat.actions.created ? cat.actions.created : 0}</span></li>  
																				<li><span className="legend-circle-high"></span> <span className="text-muted d-inline-block f12"> &nbsp; Modified: {cat.actions.modified ? cat.actions.modified : 0}</span></li> 
																				<li><span className="legend-circle-critical"></span> <span className="text-muted d-inline-block f12"> &nbsp;Deleted: {cat.actions.deleted ? cat.actions.deleted : 0}</span></li> 
																			</ul>
																			<div className="createdBar-div">
																				<div style={{width: parseFloat(cat.actions.created * 100 / totalAction) + '%'}}></div>
																				<div style={{width: parseFloat(cat.actions.modified * 100 / totalAction) + '%'}}></div>
																				<div style={{width: parseFloat(cat.actions.deleted * 100 / totalAction) + '%'}}></div>
																			</div>
																		</div>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
												<div className="row mt-3">
													<div className="col-sm-6 d-flex align-self-center">
														<div className="d-flex flex-column">
															<div className="d-flex flex-column mb-3 mr-3">
																<div className="d-flex">
																	<h2
																		className="mr-3 border-right pr-3 mb-0 align-self-center title-count">{(cat.total_count && cat.total_count)}</h2>
																	<div className="align-self-center">
																		<p className="mb-0 text-primary font-weight-bold">Total Resources</p>
																		{/* <p className="small align-self-end mb-0 text-muted">Sed in semper ex. Nulla
																			sed finibus orci, eget semper odio. Aenean viverra
																			bibendum ultrices.</p> */}
																	</div>
																</div>
															</div>
															<div className="d-flex flex-column displayNone">
																<div className="d-flex">
																	<h2
																		className="mr-3 border-right pr-3 mb-0 align-self-center title-count">
																		111</h2>
																	<div>
																		<p className="mb-0 text-primary font-weight-bold">Total</p>
																		<p className="small align-self-end mb-0 text-muted">Sed in semper ex. Nulla
																			sed finibus orci, eget semper odio. Aenean viverra
																			bibendum ultrices.</p>
																	</div>
																</div>
															</div>
														</div>
														<div className="d-flex flex-column">
															<div className="d-flex flex-column mb-3 mr-3">
																<div className="d-flex">
																	<h2
																		className="mr-3 border-right pr-3 mb-0 align-self-center title-count">
																		{cat.status && cat.status.active ? (cat.status.active) : 0}</h2>
																	<div className="align-self-center">
																		<p className="mb-0 text-primary font-weight-bold">Active Resources</p>
																		{/* <p className="small align-self-end mb-0 text-muted">Sed in semper ex. Nulla
																			sed finibus orci, eget semper odio. Aenean viverra
																			bibendum ultrices.</p> */}
																	</div>
																</div>
															</div>
															<div className="d-flex flex-column displayNone">
																<div className="d-flex">
																	<h2
																		className="mr-3 border-right pr-3 mb-0 align-self-center title-count">
																		9463</h2>
																	<div>
																		<p className="mb-0 text-primary font-weight-bold">Total</p>
																		<p className="small align-self-end mb-0 text-muted">Sed in semper ex. Nulla
																			sed finibus orci, eget semper odio. Aenean viverra
																			bibendum ultrices.</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="col-sm-6 d-flex justify-content-between">
														<div className="bg-light d-table rounded p-3 w-25">
															<p className="m-0 p-0 text-center blackMuted">Active/InActive</p>
															<Chart options={this.state.status_options} series={progressCircle} type="donut"/>
															{/* <p className="small text-center m-0">Lorem Ipsum</p> */}
														</div>
														<div className="align-self-start">
														<Doughnut data={cat.donut.doughnut_datas} options={cat.donut.doughnut_option} width={300} height={200} />
															{/* <Doughnut data={this.state.empty_doughnut_data} options={this.state.empty_doughnut_option} width={300} height={200} /> */}
														</div>
													</div>
												</div>
												<div className="row mb-3 mt-3">
													{this.state.filterDetailsData && this.state.filterDetailsData.hasOwnProperty(services) ?

													<div className="col-12 mt-3">
														<CustomTable
															columns={[
																{
																	Header: this.state.selectedType,
																	accessor: 'service',
																},
																{
																	Header: 'Active',
																	accessor: 'active',
																},
																{
																	Header: 'InActive',
																	accessor: 'inactive',
																},
																{
																	Header: 'Created',
																	accessor: 'created',
																},
																{
																	Header: 'Modified',
																	accessor: 'modified',
																},
																{
																	Header: 'Deleted',
																	accessor: 'deleted',
																}
															]}
															data={this.state.filterDetailsData[services]}
															onClickRow={tableRow => {}}
															dashboard ={this.state.filterDetailsData[services].length > 5 ? true : false }
															perPage = {5}
															borderRadius = {0}
															border= {'borderSubSection'}
														/>
														{/* <div className="d-flex justify-content-between border-bottom">
															<p>23 Observation available</p>
															<p className="text-info">Show Details</p>
														</div> */}
													</div>

													: null
													}

												</div>
												</div>
												)
											})}
										</div>
									: this.state.showLoading ?
										<div className='d-flex justify-content-center m-4'>
											<Spinner className='text-center' color='dark' size='lg' />
										</div>
									: 
										<div className='d-flex justify-content-center m-4'>
											<p>There are no data on this criteria. Please try adjusting your filter.</p>
										</div>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
AssetBoard.propTypes = {
	getAssetByService: PropTypes.func,
	getAssetByServiceDetails: PropTypes.func,
	getAssetByCategory: PropTypes.func,
	getAssetByCategoryDetails: PropTypes.func,
	getOverAllAssetCount: PropTypes.func,
	getTotalBilling: PropTypes.func,
	listAllServices: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log(state,'--------------')
	return {
		assets: state.assets.assets.results,
		providers: state.filters.providers,
		services: state.filters.services,
		regions: state.filters.regions && state.filters.regions.length ? state.filters.regions[0].provider_regions : [], 
		accounts: state.filters.accounts,
	}
}

export default AppWrapper(AssetBoard, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
	listAllServices,
	listAllCategory,
	getAssetByService,
	getAssetByServiceDetails,
	getAssetByCategory,
	getAssetByCategoryDetails,
	getOverAllAssetCount,
	getTotalBilling,
})



