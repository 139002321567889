/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file MonitoringTab.js
 * @author Prakash // on 08/06/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'

import { Spinner } from 'reactstrap'
import _ from 'lodash'

import { setResourcePropsDetails } from '../../../../actions/assets/assetsAction'
import { getMonitoringMetricsByTrend } from '../../../../actions/aiops/MetricsAction'

import { momentDateGivenFormat, momentConvertionUtcToLocalTime, convertBytes, convertSeconds, countFormater, convertBits, subHours, addHours, momentConvertionLocalToUtcTime, currentLocaltime } from '../../../../utils/utility'

import Chart from 'react-apexcharts'

class MonitoringTab extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            showAnnotation: true,
            // metricsLoader: true,
        }
    }

	componentDidMount = () => {
        if(this.props.leftSectionSelection && Object.entries(this.props.leftSectionSelection).length) {
            this.setState({
                selectedDetail: this.props.leftSectionSelection.selectedDetail,
                metricsLoader: true,
                metricsData: [],
                assetTableData: [],
            },
                () => {
                    this.getMetricDetails()
                }
            )
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.leftSectionSelection && Object.entries(this.props.leftSectionSelection).length) {
            if(!_.isEqual(prevProps.leftSectionSelection, this.props.leftSectionSelection)) {
                this.setState({
                    selectedDetail: this.props.leftSectionSelection.selectedDetail,
                    metricsLoader: true,
                    metricsData: [],
                    assetTableData: [],
                },
                    () => {
                        this.getMetricDetails()
                    }
                )
            }
        }
    }

    getMetricDetails = () => {
        let startTime = momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:00')
        let endTime = momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:00')
        // let params = {
        //     'service_name': 'lambda',
        //     'metric_name': 'ConcurrentExecutions',
        //     'statistic': 'Maximum',
        //     'start_time': '2022-04-21 13:00:00', 
        //     'end_time':'2022-04-21 23:30:00'
        // }

        let paramsData = this.state.selectedDetail.request_metric_details ? this.state.selectedDetail.request_metric_details : ""

        let params = paramsData

        if(paramsData !== "") {
            this.props.getMonitoringMetricsByTrend(params, (promise, response) => {
                if(promise) {
                    let orderByResponse = response
                    if(response && response.length) {
                        orderByResponse = _.orderBy(response, ['order'], ['asc'])
                    }
                    this.setState({ 
                        metricsData: orderByResponse, 
                        metricsLoader: response.length ? true : false,
                    },
                        () => {
                            this.metricsListGraph()
                            this.props.menuDisabled()
                        }
                    )
                    // if(response.length) {
                    //     let obj = this.props.resourcesMonitoringList
                    //     obj[this.state.assetId] = orderByResponse
                    //     this.props.setResourcePropsDetails('resourcesMonitoringList', obj)
                    // }
                } else {
                    this.setState({ metricsLoader: false },
                        () => this.props.menuDisabled()    
                    )
                }
            })
        } else {
            this.setState({ metricsLoader: false },
                () => this.props.menuDisabled()    
            )
        }
    }
    
    metricsListGraph = () => {
        let graphDetails = this.state.metricsData ? this.state.metricsData : []
        if(graphDetails && graphDetails.length) {
            let colors = ['#775BA2', '#3DAFE8']
            let series = []
            let annotations = {}
            let combinedValue = []
            let dateLabels = []
            graphDetails.forEach(graph => {
                let graphData = graph.data
                let graphLabels = graph.dates
                Object.entries(graphData).forEach(([key, item], index) => {
                    let  strokeWidth = []
                    if(item.values && item.values.length) { //statistic
                        strokeWidth.push(2)
                        let dataRow = {}
                        dataRow.name = graph.asset_name
                        let plotValue = []
                        graphLabels.map((lab, i) => {
                            let dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            // if(!index) {
                            //     dateLabels.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            // }
                            let val = item.values[i]
                            dataArray.push(val)
                            plotValue.push(dataArray)
                            let existingValue = combinedValue[i] ? combinedValue[i] : 0
                            let sumValue = existingValue + val
                            combinedValue[i] = sumValue
                        })
                        dataRow.data = plotValue

                        // dataRow.data = item.values
                        series.push(dataRow)
                    }
                    this.setState({ monitoringKey: key })
                })
                let highestValue = Math.max(...combinedValue);

                let highestIndex = combinedValue.indexOf(highestValue);

                let tickAmount = 3
                if(highestValue >= 4) {
                    tickAmount = 4
                }
                let yaxisAnnotation = []
                if(this.state.showAnnotation) {
                    let textLabel = ""
                    if(this.state.selectedDetail && this.state.selectedDetail.applied_quota_value) {
                        textLabel = "Applied "+(this.state.selectedDetail.applied_quota_value.toString())
                    } else if(this.state.selectedDetail && this.state.selectedDetail.default_quota_value) {
                        textLabel = "Default "+(this.state.selectedDetail.default_quota_value.toString())
                    }

                    let annotateRow = {}
                    // annotateRow.x = new Date(momentConvertionUtcToLocalTime(addMinutes(item, (z*10)), 'YYYY-MM-DD HH:mm:ss')).getTime()
                    annotateRow.y = highestValue
                    // annotateRow.strokeDashArray = 0
                    annotateRow.borderColor = '#FF6F00'
                    annotateRow.label = {
                        borderColor: '#FF6F00',
                        width: '100%',
                        offsetX: -20,
                        style: {
                            fontSize: '12px',
                            color: '#fff',
                            background: '#FF6F00',
                            padding: {
                                left: 0,
                                right: 10,
                                // top: 0,
                                // bottom: 2,
                            }
                        },
                        // orientation: 'horizontal',
                        text: textLabel,
                        // text: momentConvertionUtcToLocalTime(item, 'DD MMM YY HH:mm'),
                    }

                    yaxisAnnotation.push(annotateRow)
                }

                annotations = {
                    yaxis: yaxisAnnotation
                }
                    
                let options = {
                    chart: {
                        stacked: true,
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: true
                        },
                        sparkline: {
                            enabled: false
                        },
                        type: "bar",
                        width: '100%',
                        animations: {
                            enabled: false
                        },
                        events: {
                            dataPointSelection: (event, chartContext, config) => { 
                                // console.log("config.w.globals", config.w.globals)
                                this.getAssetDetails(config.w.globals.initialSeries, config.dataPointIndex)
                            }
                        },
                    },
                    states: {
                        normal: {
                            filter: {
                                type: 'none',
                                value: 0,
                            }
                        },
                        hover: {
                            filter: {
                                type: 'lighten',
                                value: 0.15,
                            }
                        },
                        active: {
                            allowMultipleDataPointsSelection: false,
                            filter: {
                                type: 'darken',
                                value: 0.35,
                            }
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            // barHeight: "40%",
                            borderRadius: 0,
                            // startingShape: 'flat',
                            // endingShape: 'flat',
                            // columnWidth: '45%',
                            // distributed: true,
                            // rangeBarOverlap: false,
                            // colors: ['#333947']
                        }
                    },
                    colors: colors,
                    dataLabels: {
                        enabled: false,
                    },
                    // stroke: {
                    //     width: [2, 2],
                    //     curve: 'smooth',
                    // },
                    legend: {
                        show: false
                    },
                    // fill: {
                    //   type:'solid',
                    //   opacity: [0.25, 1],
                    // },
                    // fill: {
                    //     type: "gradient",
                    //     gradient: {
                    //         // shadeIntensity: 1,
                    //         // opacityFrom: 0.7,
                    //         // opacityTo: 0.9,
                    //         // stops: [0, 90, 100]
                    //         gradientToColors: ['#775BA2'],
                    //         shadeIntensity: 0,
                    //         opacityFrom: .3,
                    //         opacityTo: 1,
                    //         stops: [30, 90]
                    //     }
                    // },
                    tooltip: {
                        // custom: function({series, seriesIndex, dataPointIndex, w}) {
                        //     let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
                        //     dateLable = dateLable.toString()
                        //     dateLable = momentDateGivenFormat(dateLable, 'DD MMM HH:mm')
                        //     let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]
                        //     return '<div class="arrow_box">'+dateLable+' <span style="color:'+ w.globals.colors +'">' + parseFloat(val).toFixed(2) + '</span> </div>'
                        // },
                        custom: function({series, seriesIndex, dataPointIndex, w}) {         
                            let returnData = ''
                            
                            if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                Object.entries(w.globals.initialSeries).map(([key, value], index) => {
                                    if(!index) {
                                        returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                    }
                                    returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'

                                })
                            }

                            returnData += '</div>'
                            return returnData
                        },
                        fixed: {
                            enabled: false
                        },
                        x: {
                            show: false,
                        },
                        y: {
                            show: false
                        },        
                        marker: {
                            show: false
                        }
                    },
                    xaxis: {
                        type: 'datetime',
                        // category: dateLabels,
                        labels: {
                            datetimeUTC: false,
                            style: {
                                colors: '#60687C'
                            },
                            // formatter: function (value) {
                            //     return momentDateGivenFormat(value, 'HH:mm')
                            // },
                        },
                        axisTicks: {
                            show: false
                        },
                        tooltip: {
                            enabled: false
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    yaxis: [
                        {
                            show: true,
                            axisTicks: {
                                show: false,
                            },
                            axisBorder: {
                                show: false,
                                color: '#60687C'
                            },
                            tooltip: {
                                enabled: false
                            },
                            tickAmount: tickAmount,
                            // min: 0,
                            // max: yaxisMaxValue, 
                            // tickAmount: 1,
                            labels: {
                                offsetX: -10,
                                // minWidth: 50,
                                show: true,
                                style: {
                                    fontSize: '9px',
                                    colors: '#FFFFFF'
                                },
                                formatter: (value) => { 
                                    if(Number(value) === value && value % 1 === 0) {
                                        return Math.ceil(value.toFixed(1))
                                    } else {
                                        return Math.ceil(value.toFixed(1))
                                    }
                                },
                                // offsetX: 0,
                                // offsetY: 0,
                            },
                        }
                    ],
                    grid: {
                        show: false,
                        borderColor: '#2D323E',
                        strokeDashArray: 0,
                        position: 'back',
                        xaxis: {
                            lines: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: ['#FFF'],
                                }
                            },
                            axisTicks: {
                                show: false
                            }
                        },   
                        yaxis: {
                            lines: {
                                show: false
                            },
                            labels: {
                                style: {
                                    colors: ['#969AA7'],
                                }
                            }
                        },                
                        padding: {
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0
                        },
                    },
                    annotations: annotations,
                }

                this.setState({ series, options, metricsLoader: false, highestValue },
                    () => {
                        this.getAssetDetails(series, highestIndex)
                    }
                )
            })
        }
    }

    getGrapUnitValue = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : val
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytes(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit)
            } else if(unit === 'Count') {
                // val = val > 1 ? parseInt(val) : (!val ? val : parseFloat(val).toFixed(2))
                // val = val.split(".")
                // if(val[1] && val[1] > 0) {
                //     return val.toFixed(2)
                // } else {
                //     return parseInt(val[0])
                // }
                val = val ? Math.ceil(val) : 0
                if(val) {
                    val = countFormater(val)
                }
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {
            // val = val.split(".")
            // if(val[1] && val[1] > 0) {
            //     return val.toFixed(2)
            // } else {
            //     return parseInt(val[0])
            // }
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    resetGraph = () => {
        this.setState({ series: [], options: {} },
            () => {
                this.metricsListGraph()
            }
        )
    }

    getAssetDetails = (dataDetails, dataPointIndex) => {
        let assetTableData = []
        dataDetails.length && dataDetails.forEach(item => {
            let dataRow = {}
            dataRow.name = item.name
            // dataRow.assetDetails = item.assetDetails
            dataRow.value = item.data[dataPointIndex][1]
            dataRow.date = item.data[dataPointIndex][0]
            assetTableData.push(dataRow)
        })

        this.setState({ assetTableData })
    }

    render() {
        return (
            this.state.metricsLoader ?
                <div className='d-flex justify-content-center m-4'>
                    <Spinner className='text-center' color='white' size='lg' />
                </div>
            :
                <div className="py-2">
                    {this.state.metricsData && this.state.metricsData.length ?
                        <React.Fragment>
                        <p className="d-flex flex-wrap mb-1">
                            <span className="mr-1">
                                {this.state.selectedDetail && this.state.selectedDetail.applied_quota_value ?
                                    "Applied value"
                                : this.state.selectedDetail && this.state.selectedDetail.default_quota_value ?
                                    "Default value "
                                : null}
                            </span>
                            <span className="mr-1">of the {this.state.monitoringKey}</span>
                            <span className="mr-1">
                                {this.state.selectedDetail && this.state.selectedDetail.applied_quota_value ?
                                    this.state.selectedDetail.applied_quota_value
                                : this.state.selectedDetail && this.state.selectedDetail.default_quota_value ?
                                    this.state.selectedDetail.default_quota_value
                                : null}
                            </span>
                            <span className="ml-1">we are observing the maximim value of</span> 
                            <span className="ml-1">{this.state.highestValue}, and with the</span> 
                            <span className="ml-1">utilization of </span>
                            <span className="ml-1">{this.state.selectedDetail && this.state.selectedDetail.utilization}%</span>
                        </p>
                        <div className={`p-2 border rounded bg-dark3 mb-2`}>
                            <div className="d-flex justify-content-between mt-1 mb-1">
                                <h7 className="text-white mb-0">{this.state.monitoringKey}</h7>
                                <i className='far fa-redo cursorPointer mr-1' onClick={()=> this.resetGraph()}></i>
                            </div>
                            {this.state.series && this.state.series.length && this.state.options ?
                                <div className="cursorPointer transparentTooltip mb-n4">
                                    <Chart options={this.state.options} series={this.state.series} type="bar" height={200}/>
                                </div>
                            : 
                                <div style={{minHeight:"85px"}} className="d-flex align-self-center justify-content-center">
                                    <div className="align-self-center">
                                        No Data
                                    </div>
                                </div>
                            }
                        </div>
                        </React.Fragment>
                    : null}
                    {this.state.assetTableData && this.state.assetTableData.length ?
                        <div className={`pl-0 mt-3`}>
                            {/* <div className="rounded bg-dark p-2 overflowHidden-y-scroll obsSectionHeight"> */}
                            <p className="text-primary-color mb-1">Showing the metrics value of {this.state.monitoringKey} at<span className="text-info ml-1">{momentDateGivenFormat(this.state.assetTableData[0].date, "DD MMM YYYY HH:mm")}</span></p>
                            <div className="rounded bg-dark">
                                <div className="table-responsive overflowYAuto">
                                    <table className="table text-white mb-0 border">
                                        <thead className="bg-dark3" >
                                            <tr class="w-100">
                                                <th style={{width: "300px"}}>Asset</th>
                                                <th className="text-center">Metric Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.assetTableData.map(item => {
                                                return(
                                                    <tr className={`w-100 border-top text-white`}>
                                                        <td className="align-middle py-1">
                                                            <p className={`mb-0 hiddenEllipses`}>{item.name}</p>
                                                        </td>
                                                        <td className="text-center">{item.value}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    : null}
                </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
MonitoringTab.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log('errorDashboard',state)
    return {
        leftSectionSelection: state.assets.resourcesPropsDetails && state.assets.resourcesPropsDetails.leftSectionSelection ? state.assets.resourcesPropsDetails.leftSectionSelection : {},
        resourcesMonitoringList: state.assets.resourcesPropsDetails && state.assets.resourcesPropsDetails.resourcesMonitoringList ? state.assets.resourcesPropsDetails.resourcesMonitoringList : [],
    }
}

export default connect(mapStateToProps, {
    setResourcePropsDetails,
    getMonitoringMetricsByTrend,
})(withRouter(MonitoringTab))