/*************************************************
 * Tvastar
 * @exports
 * @file TopologyAction.js
 * @author prakash
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'

import {	
	MICROSERVICE_TOPOLOGY,
	GET_TOPOLOGY,
	GET_GRAPH_SUMMARY,
	GET_COVERED_SUMMARY,
	GET_COVERED_TRENDS_SUMMARY,
	GET_COVERED_LISTS_SUMMARY,
	LIST_MICROSERVICE_RCA_RESULTS,
	LIST_MICROSERVICE_RCA_DETAILS
	// MICROSERVICES_GET_TOPOLOGY
} from '../../config'
import { myLog } from '../../utils/utility'
import { ACTION_TYPES } from '../types'

/**
 * Action to set microServices props details
 */
export const setMicroServicePropsDetails = (key, value) => {
	return async (dispatch, getState) => {
		let microServiesPropsDetails = JSON.parse(JSON.stringify(getState().observability.microServiesPropsDetails))
		microServiesPropsDetails[key] = value
		dispatch({
			type: ACTION_TYPES.SET_MICROSERVICES_PROPS_DETAILS,
			payload: microServiesPropsDetails,
		})
	}
}

/**
 * Action to get get-topology
 * @param {Object} body
 * @param {Function} callback
 */
export const microServicesGetTopology = (body, callback) => {
	return async () => {
		try {
			const response = await API.post(MICROSERVICE_TOPOLOGY, GET_TOPOLOGY, { body })
			callback(true, response)
		} catch (error) {
			myLog('microServicesGetTopology', error)
			callback(false)
		}
	}
}

/**
 * Action to get-graph-summary
 * @param {Object} body
 * @param {Function} callback
 */
export const getGraphSummary = (body, callback) => {
	return async () => {
		try {
			const response = await API.post(MICROSERVICE_TOPOLOGY, GET_GRAPH_SUMMARY, { body })
			callback(true, response)
		} catch (error) {
			myLog('getGraphSummary', error)
			callback(false)
		}
	}
}

/**
 * Action to get-covered-summary
 * @param {Object} body
 * @param {Function} callback
 */
export const getCoveredSummary = (body, callback) => {
	return async () => {
		try {
			const response = await API.post(MICROSERVICE_TOPOLOGY, GET_COVERED_SUMMARY, { body })
			callback(true, response)
		} catch (error) {
			myLog('getCoveredSummary', error)
			callback(false)
		}
	}
}

/**
 * Action to get-covered-trends-summary
 * @param {Object} body
 * @param {Function} callback
 */
export const getCoveredTrendsSummary = (body, callback) => {
	return async () => {
		try {
			const response = await API.post(MICROSERVICE_TOPOLOGY, GET_COVERED_TRENDS_SUMMARY, { body })
			callback(true, response)
		} catch (error) {
			myLog('getCoveredTrendsSummary', error)
			callback(false)
		}
	}
}

/**
 * Action to get-covered-lists-summary
 * @param {Object} body
 * @param {Function} callback
 */
export const getCoveredListsSummary = (body, callback) => {
	return async () => {
		try {
			const response = await API.post(MICROSERVICE_TOPOLOGY, GET_COVERED_LISTS_SUMMARY, { body })
			callback(true, response)
		} catch (error) {
			myLog('getCoveredListsSummary', error)
			callback(false)
		}
	}
}

/**
 * Action to list-microservice-rca-results
 * @param {Object} body
 * @param {Function} callback
 */
export const listMicroserviceRcaResults = (body, callback) => {
	return async () => {
		try {
			const response = await API.post(MICROSERVICE_TOPOLOGY, LIST_MICROSERVICE_RCA_RESULTS, { body })
			callback(true, response)
		} catch (error) {
			myLog('listMicroserviceRcaResults', error)
			callback(false)
		}
	}
}

/**
 * Action to list-microservice-rca-details
 * @param {Object} body
 * @param {Function} callback
 */
export const listMicroserviceRcaDetails = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(MICROSERVICE_TOPOLOGY, LIST_MICROSERVICE_RCA_DETAILS, { body })
			
			dispatch({
				type: ACTION_TYPES.SET_OBSERVABILITY_MICROSERVCIES_RCA_DETAILS,
				payload: response,
			})
			callback(true, response)
		} catch (error) {
			myLog('listMicroserviceRcaDetails', error)
			
			dispatch({
				type: ACTION_TYPES.SET_OBSERVABILITY_MICROSERVCIES_RCA_DETAILS,
				payload: [],
			})
			callback(false)
		}
	}
}