/*************************************************
 * Tvastar
 * @exports
 * @file SavedFiltersSidePanel.js
 * @author Prakash // on 18/04/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
// import PropTypes from 'prop-types'
import { Spinner, Input } from 'reactstrap'
import { getAllTagsKeys, getAllTagsValues } from '../../../actions/commonAction'

import { setCostPropsDetails, listCostTemplates, saveTemplate } from '../../../actions/cost/CostAction'

import { capitalizeFirstLetter, getDayFromSelectedDuration, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, momentDateGivenFormat, subDays, subHours } from '../../../utils/utility'

import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';
import Search from '../../common/SearchComponent';
import { store as CommonNotification } from 'react-notifications-component';

class SavedFiltersSidePanel extends Component {
	constructor(props) {
		super(props)
		this.dateRef = React.createRef()
        this.tagRef = React.createRef()
		this.state = {
            minMaxHeight: 700,
			showLoading: true,
			

			startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
			perPage: 15,
		}
	}

	componentDidMount = () => {
		document.addEventListener('keydown', this._handleKeyDown)
		this.getWindowSize()
		window.addEventListener('resize', () => {
            this.getWindowSize()
        })
		if(this.props.templateType === "view") {
			this.templateViewFunction()
		} else {
			this.setState({ selectedFilters: this.props.selectedFilters },
				() => this.listCostTemplates()
			)
		}
    }

	templateViewFunction = () => {		
		let inputFilters = this.props.selectedFilters
		let selectedFilters = []
		if(inputFilters.Tags) {
			selectedFilters.push(inputFilters.Tags)
		}
		if(inputFilters.Duration) {
			selectedFilters.push(inputFilters.Duration)
		}
		selectedFilters = [...selectedFilters, ...inputFilters.filters]
		this.setState({ savedFilters: this.props.selectedFilters, selectedFilters },
			() => this.listCostTemplates()
		)
	}

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
        let headerHeihgt = 100
		let outerHeight = (window.outerHeight - browserBorder - headerHeihgt)

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }

	_handleKeyDown = event => {
		switch (event.keyCode) {
			case 27:
				this.props.closeSidePanel()
				break
			default:
				break
		}
	}

	listCostTemplates = () => {
		let params = {}
		params.source = this.props.selectedProvider
		this.props.listCostTemplates(params, (promise, response) => {
			if(promise) {
				let totalPages = 1
				if(response.length > this.state.perPage) {
					totalPages = Math.ceil(response.length / this.state.perPage)
				}
				this.setState({ listData: response, filteredListData: response, totalPages })
			} else {
				this.setState({ listData: [], filteredListData: [] })
			}
		})
	}

    navigatePage = (action, currentPage) => {
        if(currentPage === this.state.totalPages && action === 'next') {
            this.setState({ showLoading: true, currentPage: currentPage + 1 },
                () => this.listCoveredAssets()                
            )
        } else if(currentPage > 0) {
            let startRecord = this.state.startRecord
            if(action === 'next' && currentPage !== this.state.totalPages) {
                startRecord = startRecord + this.state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - this.state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = this.state.totalPages
                startRecord = (this.state.totalPages - 1) * this.state.perPage
            }

            this.setState({ currentPage, startRecord })
        } 
	}

    gotoPage = (pageNumber) => {
        if(pageNumber > 0  && pageNumber <= this.state.totalPages) {
            let startRecord = (pageNumber - 1) * this.state.perPage
            this.setState({ currentPage: pageNumber, pageNumber, startRecord })
        } else  if(pageNumber > this.state.totalPages) {
            this.setState({ pageNumber: this.state.currentPage })  
        } else {
            this.setState({ pageNumber })
        }
    }

	onSaveFunction = () => {
		let hasError = false
		let params = {}
		params.source = this.props.selectedProvider		
		
		if(this.state.template_name && this.state.template_name !== "") {
			params.template_name = this.state.template_name
		} else {
			hasError = true
		}

		if(this.state.description && this.state.description !== "") {
			params.description = this.state.description
		} else {
			hasError = true
		}

		let selectedFilters = this.state.selectedFilters

		let filters = []
		if(selectedFilters.filter(e => e.label !== "Duration" && e.label !== "Tags").length) {
			selectedFilters.forEach(row => {
				if(row.label !== "Duration" && row.label !== "Tags") {
					filters.push(row)
				} else {
					params[row.label] = row
				}
			})
		}

		params.filters = filters
		
		if(!hasError) {
			this.props.saveTemplate(params, (promise, response) => {
				if(promise) {
					let messageType = 'danger'		
					if(response.status) {
						messageType = 'success'
					}
					let message = response.message
					CommonNotification.addNotification({
						message: message,
						type: messageType,
						insert: "top",
						container: "top-center",
						dismiss: {
							duration: 5000,
							onScreen: false,
							pauseOnHover: true,
							showIcon: true,
						}
					});
					if(response.status) {
						this.setState({ saveLoading: false },
							() => {
								setTimeout(() => { this.listCostTemplates() }, 2000)
							}
						)
					} else {
						this.setState({ saveLoading: false })
					}
				} else {
					this.setState({ saveLoading: false })
				}
			})
		} else {
			this.setState({ hasError, saveLoading: false })
		}

	}
	
	render() {		
		return (
			<div className="advanced-search">
				<div className={`loaderOverlay ${this.state.queryLoading ? "" : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
				<div className="search-content bg-muted w-80">
					<div className="header-search bd-highlight d-flex justify-content-between">
						<div className="flex-fill bd-highlight">
							<h5>{this.props.templateType === "save" ? "Save Filter (as Template)" : this.props.templateType === "view" ? "View Template" : "Templates"}</h5>
							<div className="mr-2 d-flex">
							</div>
						</div>

						<div className="text-right flex-fill bd-highlight align-self-center">
							<i className={`far fa-times cursorPointer`} onClick={() => this.props.closeSidePanel()}></i>
						</div>
					</div>
					<div className={`overflow-auto p-2`}>						
						{this.props.templateType !== "template" ?
							<div className="card-body m-2 pt-2 rounded counts_threads">
								<div className="d-flex zapInputDark">

									<div className="py-1 w-50">
										<div className='w-100'>
											<div className="d-flex justify-content-between">
												<label className="mb-1">Title </label>
												{this.state.hasError && (!this.state.template_name || this.state.template_name === "") ?
													<small className={`text-danger mr-2`}>(required)</small>
												: null}
											</div>
											<p className="mb-0 text-white">
												{this.props.templateType === "view" ?
													capitalizeFirstLetter(this.state.savedFilters && this.state.savedFilters.template_name)
												: 
													<Input
														type="text"
														placeholder="Enter template name"
														className={`inputTextbox`}
														// maxlength="128"
														value={this.state.template_name ? this.state.template_name : ""}
														onChange={e => this.setState({ template_name: e.target.value })}
													/>
												}
											</p>
										</div>
										<div className='w-100 mt-4'>
											<div className="d-flex justify-content-between">
												<label className="mb-1">Source </label>
											</div>
											<p className="mb-0 text-white">{capitalizeFirstLetter(this.props.selectedProvider)}</p>
										</div>
									</div>
									<div className="py-1 w-50 pl-3">
										<div className="d-flex justify-content-between">
											<label className="mb-1">Description </label>
											{this.state.hasError && (!this.state.description || this.state.description === "") ?
												<small className={`text-danger mr-2`}>(required)</small>
											: null}
										</div>
										{this.props.templateType === "view" ? 
											<p className="mb-0 text-white">{this.state.savedFilters && this.state.savedFilters.description}</p>
										: 
											<Input
												type='textarea'
												className='inputTextbox'
												placeholder='Enter Description'
												rows='4'
												id='comment'
												maxlength='256'
												onChange={e => this.setState({ description: e.target.value })}
												value={this.state.description}
											/>
										}
									</div>
								</div>
								<div className='bg-dark3 p-3 rounded my-4'>
									<div className='d-flex justify-content-between mb-2'>
										<p className="mb-2 w-100 text-white">Selected Filters</p>
									</div>
									<div className="d-flex flex-wrap">
										{this.state.selectedFilters && !this.state.selectedFilters.filter(e => e.label === "Account").length ?
											<small className="font-weight-bold align-self-center cursorPointer mr-2 mt-2">Account : <span className='text-info'>All</span></small>
										: null}

										{this.state.selectedFilters && !this.state.selectedFilters.filter(e => e.label === "Region").length ?
											<small className="font-weight-bold align-self-center cursorPointer mx-2 mt-2">Region : <span className='text-info'>All</span></small>
										: null}
										
										{this.state.selectedFilters && this.state.selectedFilters.length ? 
											this.state.selectedFilters.map((filters, index) => {
												return(
													filters.label === "Duration" ?
														<React.Fragment>
															<small className="font-weight-bold align-self-center mt-2 cursorPointer mr-2">Duration : <span className='text-info'> {momentDateGivenFormat(filters.start_time, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(filters.end_time, 'DD MMM YYYY')}</span> </small>
														</React.Fragment>
													: filters.value && !Array.isArray(filters.value) ?
														<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2"><span className='text-primary-color'>{filters.label+" : "}</span>{filters.output_label ? filters.output_label : filters.label}</span>
													: filters.label === "Tags" ?
														filters.value.map(row => {
															return(
																<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																	<span className='text-primary-color'>Tag :</span> 
																	{row.key +" : "+row.value}
																</span>
															)
														})
													:
														filters.value.map(row => {
															return(
																<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																	<span className='text-primary-color'>{filters.type+" : "}</span> 
																	{filters.label +" : "+row}
																</span>
															)
														})
												)
											})
										: null}
									</div>
								</div>
								<div className={`d-flex mb-2 justify-content-${this.props.templateType === "save" ? "between" : "end"}`}>
									{this.props.templateType === "save" ?
										this.state.saveLoading ?
											<button className="btn btn-success">
												Saving <Spinner size='sm' color='light' className='ml-2' />
											</button>
										: 
											<button className={`d-flex justify-content-end cursorPointer btn-sm ${this.state.saveLoading ? 'disabled btn-primary' : 'btn-primary'}`} onClick={ () => this.setState({ saveLoading: true }, () => this.onSaveFunction())}>Save</button>
									: null}
									<button className={`d-flex justify-content-end cursorPointer btn-sm btn-light`} onClick={ () => this.props.closeSidePanel()}>Cancel</button>
								</div>
							</div>
						: null}
						<div className={`d-flex pb-2 justify-content-end mt-2`}>
							<div className="d-flex mr-2 w-40">
								<p className="f12 m-0 align-self-center pt-1">Showing {this.state.filteredListData && this.state.filteredListData.length} of total {this.state.listData && this.state.listData.length} template(s)</p>
							</div>
							<div className="d-flex justify-content-end w-60 mb-n2">
								{this.state.listData && this.state.listData.length ?
									<Search
										data={this.state.listData ? this.state.listData : []}
										applyTags={false}
										applyLiteDarkTags={true}
										topClassName={'bg-black5 align-self-center w-50 mr-1 border-gray5 rounded-5 f12'}
										searchClassName={'px-2 f12'}
										searchIconColor={'text-gray5 f12'}
										searchPlaceHolder={'Search....'}
										className={"bg-transparent text-white pl-0 form-control-sm f12"}
										filteredData={(filteredListData) => {
											let totalPages = 1
											if(filteredListData.length > this.state.perPage) {
												totalPages = Math.ceil(filteredListData.length / this.state.perPage)
											}
											this.setState({ filteredListData, startRecord: 0, currentPage: 1, totalPages })
										}}
									/>
								: null}

								{this.state.filteredListData && this.state.filteredListData.length > this.state.perPage ?
									<div className="pagination errorPagePagination f12 mt-n1">
										<span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
										<button><i className={`fal fa-arrow-to-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('start', this.state.currentPage)}></i></button> 
										<button><i className={`fal fa-angle-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('previous', this.state.currentPage)}></i></button> 
										<button><i className={`fal fa-angle-right cursorPointer ${!this.state.nextErrorListToken && this.state.nextErrorListToken ==="" && this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
										<button><i className={`fal fa-arrow-to-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
									</div>
								: null}
							</div>
						</div>
						<div className="table-responsive">
							<table className="table">
								<thead className="text-white bg-dark3">
									<tr>
										<th className="" width="10%">Date</th>
										<th className="" width="10%">Title</th>
										<th className="" width="30%">Description</th>
										<th className="" width="50%">Filters</th>
									</tr>
								</thead>
								<tbody className="text-white">
									{this.state.filteredListData && this.state.filteredListData.length ?
										this.state.filteredListData.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map((row, i) => {
											return(
												<tr className='border-bottom-black5'> 
													<td className="">{row.created_at ? momentConvertionUtcToLocalTime(row.created_at, 'DD MMM YYYY HH:mm') : ""} </td>
													<td className="">{row.template_name ? row.template_name : ""}</td>
													<td className="">{row.description ? row.description : ""}</td>
													<td className="d-flex flex-wrap">
														{Array.isArray(row.filters) && !row.filters.filter(e => e.label === "Account").length ?
															<small className="font-weight-bold align-self-center cursorPointer mr-2">Account : <span className='text-info'>All</span></small>
														: null}	

														{Array.isArray(row.filters) && !row.filters.filter(e => e.label === "Region").length ?
															<small className="font-weight-bold align-self-center cursorPointer mx-2">Region : <span className='text-info'>All</span></small>
														: null}

														{row["Duration"] ?
															<div className='d-flex ml-2'>
																<small className="font-weight-bold align-self-center cursorPointer mr-2">Duration : <span className='text-info'> {momentDateGivenFormat(row["Duration"].start_time, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(row["Duration"].end_time, 'DD MMM YYYY')}</span> </small>
															</div>
														: null}

														{row["Tags"] ?
															row["Tags"].value.map(tag => {
																return(
																	<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2"><span className='text-primary-color'>Tag :</span> {tag.key +" : "+tag.value}</span>
																)
															})
														: null}

														{row.filters.map(flt => {
															return(
																Array.isArray(flt.value) ?
																flt.value.map(item => {
																		return(
																			<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																				<span className='text-primary-color'>{flt.type} : </span> 
																				{flt.label +" : "+item}
																			</span>
																		)
																	})
																:
																	<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2"><span className='text-primary-color'>{flt.label+" : "}</span> {flt.output_label ? flt.output_label : flt.value}</span>
															)
														})}
													</td>
												</tr>
											)
										})
									: (!this.state.showLoading && !this.state.assetsList) ||
										(this.state.assetsList && !this.state.assetsList.length && !this.state.showLoading) ? 
										<div className="d-flex justify-content-center m-4">
											<p>There are no data on this criteria. Please try adjusting your filter.</p>
										</div>
									: null}
									{/* {this.state.showLoading ? (
										<div className="d-flex justify-content-center m-4">
											<Spinner className="text-center" color="white" size="lg" />
										</div>
									) : null} */}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
SavedFiltersSidePanel.propTypes = {}

const mapStateToProps = state => {
	// console.log('csot SavedFiltersSidePanel',state)
	return {
		tagKeys: state.filters.tagKeys,
		costFilterValues: state.cost.costPropsDetails && state.cost.costPropsDetails.costFilterValues ? state.cost.costPropsDetails.costFilterValues : {},
	}
}

export default connect(mapStateToProps, {
	
	getAllTagsKeys,
	getAllTagsValues,

	setCostPropsDetails,
	listCostTemplates,
	saveTemplate,
})(withRouter(SavedFiltersSidePanel))