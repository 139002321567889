/*************************************************
 * Tvastar
 * @exports
 * @file Perturbation.js
 * @author Prakash // on 13/09/2021
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
 import React, { Component } from 'react'
 import { connect } from 'react-redux'
 import { withRouter } from 'react-router-dom'
 import { onlyNumeric } from '../../../../utils/utility'
 import Select from 'react-select'

 class Perturbation extends Component {
	hierarichalBucketBarChartRef = React.createRef()
	constructor(props) {
		super(props)
		this.props = props;
		this.state = {
			showLoading: false,
            date_pattern: "%Y-%m-%d %H:%M:%S.%f",
            output_pattern: "%Y-%m-%d %H:%M:%S",
            deflect_round: 3
		}
	}

	componentDidMount = () => {
        this.editEvent()        
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.dlpPropsDetails && typeof this.props.dlpPropsDetails.hasPrivacyPolicyError !== 'undefined' && (prevProps.dlpPropsDetails.hasPrivacyPolicyError !== this.props.dlpPropsDetails.hasPrivacyPolicyError || (!this.state.hasError && (this.state.hasError !== this.props.dlpPropsDetails.hasPrivacyPolicyError)))) {
            this.setState({ hasError: this.props.dlpPropsDetails.hasPrivacyPolicyError })
        }
    }

    editEvent = () => {
        if(this.props.pageType === 'edit' || this.props.pageType === 'view') {
			let policyDetails = this.props.policyDetails
            let deidentification_type = policyDetails.config.deidentification_type
            let deflect_type = policyDetails.config.deflect_type ? policyDetails.config.deflect_type : ''
            let deflect_lb = policyDetails.config.deflect_lb
            let deflect_ub = policyDetails.config.deflect_ub
            
            let watermark_id = policyDetails.config.watermark_id ? policyDetails.config.watermark_id : ''
            let watermark_name = policyDetails.config.watermark_name ? policyDetails.config.watermark_name : ''
            let watermark_type = policyDetails.config.watermark_type ? policyDetails.config.watermark_type : ''
            
            if(!deflect_lb && deflect_type === 'datetime') {
                deflect_lb = -1000
                deflect_ub = 1000
            } else if(!deflect_lb && deflect_type === 'float') {
                deflect_lb = -10.5
                deflect_ub = 10.5
            } else if(!deflect_lb && deflect_type === 'number') {
                deflect_lb = -10
                deflect_ub = 10
            }

            let date_pattern = policyDetails.config.date_pattern ? policyDetails.config.date_pattern : "%Y-%m-%d %H:%M:%S.%f"
            let output_pattern = policyDetails.config.output_pattern ? policyDetails.config.output_pattern : "%Y-%m-%d %H:%M:%S"
            let deflect_round = policyDetails.config.deflect_round ? policyDetails.config.deflect_round : 3

            this.setState({ deidentification_type, deflect_type, updated_deflect_type: deflect_type, date_pattern, output_pattern, deflect_lb, deflect_ub, deflect_round, watermark: watermark_id !== '' ? 'Yes' : 'No', watermark_id, watermark_name, watermark_type }, () => this.onChangePropsEvent())
        }
    }

    onChangePropsEvent = () => {
		let errorMessage = ''
		if(!this.state.deidentification_type || this.state.deidentification_type === '') {
			errorMessage = 'Please select perturbation type'
		}

        let data = {}
        data.deidentification_type = this.state.deidentification_type ? this.state.deidentification_type : ''
        data.deflect_type = this.state.deflect_type ? this.state.deflect_type : ''

        let selectedText = 'Perturbation type '+this.state.deidentification_label

        data.deflect_lb = this.state.deflect_lb ? this.state.deflect_lb : ''
        data.deflect_ub = this.state.deflect_ub ? this.state.deflect_ub : ''
        selectedText += ' lower bound '+data.deflect_lb+' upper bound '+data.deflect_ub

        if(this.state.deflect_type === 'datetime') {
            data.date_pattern = this.state.date_pattern ? this.state.date_pattern : ''
            data.output_pattern = this.state.output_pattern ? this.state.output_pattern : ''
            selectedText += ' date pattern '+data.date_pattern+' output pattern '+data.output_pattern

            if(!this.state.date_pattern || this.state.date_pattern === '' || !this.state.output_pattern || this.state.output_pattern === '') {
                errorMessage = 'has Error'
            }
        } else if(this.state.deflect_type === 'float') {
            data.deflect_round = this.state.deflect_round ? this.state.deflect_round : ''
            selectedText += ' round to '+data.deflect_round

            if(!this.state.deflect_round || this.state.deflect_round === '') {
                errorMessage = 'has Error'
            }
        }
        if(!this.state.deflect_lb || this.state.deflect_lb === '' || !this.state.deflect_ub || this.state.deflect_ub === '') {
            errorMessage = 'has Error'
        }

        if(this.props.watermarkTypeArray[this.state.deflect_type] && this.props.watermarkTypeArray[this.state.deflect_type].length) {
            if(this.state.watermark === 'Yes') {
                data.watermark_type = this.state.watermark_type
                let filter = this.props.watermarkTypeArray[this.state.deflect_type].filter(arr => arr.watermark_type === this.state.watermark_type)
                if(filter.length) {
                    Object.entries(filter[0]).forEach(([key, value]) => {
                        data[key] = value
                    })
                }

                data.watermark_id = this.state.watermark_id
                data.watermark_name = this.state.watermark_name
                selectedText += ' watermark type '+this.state.watermark_type
                selectedText += ' with watermark '+this.state.watermark_name

                if(!data.watermark_type || data.watermark_type === '') {
                    errorMessage = 'Please select watermark type'
                } else if(!data.watermark_name || data.watermark_name === '') {
                    errorMessage = 'Please select watermark'
                }
            }
        }


        this.props.selectedData(data, selectedText, errorMessage)
    }

    getSelectLabel = (item, list) => {
        let filter = list.filter(arr => arr.type === item)
        if(filter.length) {
            this.setState({ deidentification_label: filter[0].label })
        } else {
            this.setState({ deidentification_label: '', deidentification_type: '', deflect_type: '' })
        }
    }

    onChangeType = () => {
        if(this.state.updated_deflect_type && this.state.deflect_type === this.state.updated_deflect_type) {
            this.editEvent()
        } else {
            this.onChangePropsEvent()
        }
    }

    render() {		
		return (
            <div className={`form-group dlpDescriptionForm mt-3`}>
                <div className="d-flex flex-wrap">
                    <p className="mb-0 mr-2 align-self-end text-purple mt-3">Type</p>
                    <Select
                        // menuIsOpen={true}
                        placeholder={'Select'}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        isSearchable={false}
                        className={`selectOption align-self-end mx-2 ${this.state.hasError && (!this.state.deflect_type || this.state.deflect_type === '') ? 'dottedDangerSelectLine' : ''}`}
                        
                        value={({
                            value: this.state.deflect_type ? this.state.deflect_type : 'Select',
                            label: this.state.deflect_type ? (this.state.deidentification_label ? this.state.deidentification_label : this.getSelectLabel(this.state.deflect_type, this.props.list)) : 'Select'
                        })}
                        options={this.props.list && this.props.list.map(item => ({
                            value: item.type,
                            label: item.label,
                            type: item.value,
                        }))}
                        onChange={event => 
                            this.setState({ deidentification_type: event.type, deflect_type: event.value, deidentification_label: event.label, watermark_name: '', watermark_type: '', watermark_id: '' }, 
                                () => {
                                    let deflect_lb = -10
                                    let deflect_ub = 10
                                    if(this.state.deflect_type === 'datetime') {
                                        deflect_lb = -1000
                                        deflect_ub = 1000
                                    } else if(this.state.deflect_type === 'float') {
                                        deflect_lb = -10.5
                                        deflect_ub = 10.5
                                    }
                                    this.setState({ deflect_lb, deflect_ub }, 
                                        () => this.onChangeType()
                                    )
                                }
                            )
                        }
                    />
                    {this.state.deflect_type === 'datetime' ?
                        <React.Fragment>
                            <p className="mb-0 mx-2 align-self-end text-purple mt-3">lower bound</p>
                            <input 
                                type="number" 
                                className={`inputField mt-3 w-10 ${this.state.hasError && (!this.state.deflect_lb || this.state.deflect_lb === '') ? 'dottedDangerTextboxLine' : ''}`}
                                value={this.state.deflect_lb ? this.state.deflect_lb : ''}
                                onChange={e => this.setState({ deflect_lb: e.target.value }, () => this.onChangePropsEvent())}
                                />
                            <p className="mb-0 mx-2 align-self-end text-purple mt-3">upper bound</p>
                            <input 
                                type="number" 
                                className={`inputField mt-3 w-10 ${this.state.hasError && (!this.state.deflect_ub || this.state.deflect_ub === '') ? 'dottedDangerTextboxLine' : ''}`}
                                value={this.state.deflect_ub ? this.state.deflect_ub : ''}
                                onChange={e => this.setState({ deflect_ub: e.target.value }, () => this.onChangePropsEvent())}
                            />
                            <p className="mb-0 mx-2 align-self-end text-purple mt-3">date pattern</p>
                            <input 
                                type="text" 
                                className={`inputField mt-3 w-25 ${this.state.hasError && (!this.state.date_pattern || this.state.date_pattern === '') ? 'dottedDangerTextboxLine' : ''}`}
                                value={this.state.date_pattern ? this.state.date_pattern : ''}
                                onChange={e => this.setState({ date_pattern: e.target.value }, () => this.onChangePropsEvent())}
                            />
                            <p className="mb-0 mx-2 align-self-end text-purple mt-3">output pattern</p>
                            <input 
                                type="text" 
                                className={`inputField mt-3 w-25 ${this.state.hasError && (!this.state.output_pattern || this.state.output_pattern === '') ? 'dottedDangerTextboxLine' : ''}`}
                                value={this.state.output_pattern ? this.state.output_pattern : ''}
                                onChange={e => this.setState({ output_pattern: e.target.value }, () => this.onChangePropsEvent())}
                            />
                        </React.Fragment>
                    : this.state.deflect_type === 'float' ?
                        <React.Fragment>
                            <p className="mb-0 mx-2 align-self-end text-purple mt-3">lower bound</p>
                            <input 
                                type="number" 
                                className={`inputField mt-3 w-10 ${this.state.hasError && (!this.state.deflect_lb || this.state.deflect_lb === '') ? 'dottedDangerTextboxLine' : ''}`}
                                value={this.state.deflect_lb ? this.state.deflect_lb : ''}
                                onChange={e => this.setState({ deflect_lb: e.target.value }, () => this.onChangePropsEvent())}
                                />
                            <p className="mb-0 mx-2 align-self-end text-purple mt-3">upper bound</p>
                            <input 
                                type="number" 
                                className={`inputField mt-3 w-10 ${this.state.hasError && (!this.state.deflect_ub || this.state.deflect_ub === '') ? 'dottedDangerTextboxLine' : ''}`}
                                value={this.state.deflect_ub ? this.state.deflect_ub : ''}
                                onChange={e => this.setState({ deflect_ub: e.target.value }, () => this.onChangePropsEvent())}
                            />
                            <p className="mb-0 mx-2 align-self-end text-purple mt-3">round to</p>
                            <input 
                                type="number" 
                                className={`inputField mt-3 w-10 ${this.state.hasError && (!this.state.deflect_round || this.state.deflect_round === '') ? 'dottedDangerTextboxLine' : ''}`}
                                min={0}
                                value={this.state.deflect_round ? this.state.deflect_round : ''}
                                onChange={e => this.setState({ deflect_round: onlyNumeric(e.target.value) }, () => this.onChangePropsEvent())}
                            />
                        </React.Fragment>
                    : this.state.deflect_type === 'number' ?
                        <React.Fragment>
                            <p className="mb-0 mx-2 align-self-end text-purple mt-3">lower bound</p>
                            <input 
                                type="number" 
                                className={`inputField mt-3 w-10 ${this.state.hasError && (!this.state.deflect_lb || this.state.deflect_lb === '') ? 'dottedDangerTextboxLine' : ''}`}
                                value={this.state.deflect_lb ? this.state.deflect_lb : ''}
                                onChange={e => this.setState({ deflect_lb: e.target.value }, () => this.onChangePropsEvent())}
                                />
                            <p className="mb-0 mx-2 align-self-end text-purple mt-3">upper bound</p>
                            <input 
                                type="number" 
                                className={`inputField mt-3 w-10 ${this.state.hasError && (!this.state.deflect_ub || this.state.deflect_ub === '') ? 'dottedDangerTextboxLine' : ''}`}
                                value={this.state.deflect_ub ? this.state.deflect_ub : ''}
                                onChange={e => this.setState({ deflect_ub: e.target.value }, () => this.onChangePropsEvent())}
                            />
                        </React.Fragment>
                    : null}
                </div>
                {this.props.watermarkTypeArray && this.props.watermarkTypeArray[this.state.deflect_type] && this.props.watermarkTypeArray[this.state.deflect_type].length ?
                    <div className="d-flex flex-wrap">
                        <p className="mb-0 mr-2 align-self-end text-purple mt-3">Add watermark</p>
                        <Select
                            // menuIsOpen={true}
                            placeholder={'Select'}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            isSearchable={false}
                            className={`selectOption mr-2 mt-3 w-15`}
                            value={({
                                value: this.state.watermark ? this.state.watermark : 'Select',
                                label: this.state.watermark ? this.state.watermark : 'Select',
                            })}
                            options={this.state.yesNoOptions && this.state.yesNoOptions.map(item => ({
                                value: item,
                                label: item,
                            }))}
                            onChange={event =>  
                                this.setState({ watermark: event.value }, () => this.onChangePropsEvent())
                            }
                        />
                        
                        {this.state.watermark === 'Yes' ?
                            <React.Fragment>
                                <p className="mb-0 mr-2 align-self-end text-purple mt-3">watermark Type</p>
                                <Select
                                    // menuIsOpen={true}
                                    placeholder={'Select'}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    isSearchable={false}
                                    className={`selectOption mr-2 mt-3 ${this.state.hasError && (!this.state.watermark_type || this.state.watermark_type === '') ? 'dottedDangerSelectLine' : ''}`}
                                    value={({
                                        value: this.state.watermark_type ? this.state.watermark_type : 'Select',
                                        label: this.state.watermark_type ? this.state.watermark_type : 'Select',
                                    })}
                                    options={this.props.watermarkTypeArray[this.state.generalize_type] && this.props.watermarkTypeArray[this.state.generalize_type].map(item => ({
                                        value: item.watermark_type,
                                        label: item.watermark_type,
                                    }))}
                                    onChange={event =>  
                                        this.setState({ watermark_type: event.value }, () => this.onChangePropsEvent())
                                    }
                                />
                                <p className="mb-0 mr-2 align-self-end text-purple mt-3">with watermark</p>
                                <Select
                                    // menuIsOpen={true}
                                    placeholder={'Select'}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    isSearchable={false}
                                    className={`selectOption mr-2 mt-3 w-30 ${this.state.hasError && (!this.state.watermark_name || this.state.watermark_name === '') ? 'dottedDangerSelectLine' : ''}`}
                                    value={({
                                        value: this.state.watermark_id ? this.state.watermark_id : 'Select',
                                        label: this.state.watermark_name ? this.state.watermark_name : 'Select',
                                    })}
                                    options={this.props.watermarks && this.props.watermarks.map(item => ({
                                        value: item.watermark_id,
                                        label: item.watermark_name,
                                    }))}
                                    onChange={event =>  
                                        this.setState({ watermark_id: event.value, watermark_name: event.label }, () => this.onChangePropsEvent())
                                    }
                                />
                            </React.Fragment>
                        : null}
                    </div>
                : null}
            </div>
        )
    }
}

const mapStateToProps = state => {
	return {
        dlpPropsDetails: state.dlp.dlpPropsDetails,
        watermarks: state.dlp.dlpConfigWatermarklist
	}
}


export default connect(mapStateToProps, {})(withRouter(Perturbation))