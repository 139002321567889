/*************************************************
 * Tvastar
 * @exports
 * @file TriggerConfiguration.js
 * @author Rajasekar // on 26/02/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class TriggerConfiguration extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	render() {
		return (
			<div className='box p-3 bg-dark3'>
				<div className='d-flex'>
					<p className='flex-grow-1 mb-2 text-white'>
						<b>Trigger Configuration</b>
					</p>
				</div>
				{this.props.lambdaFunctionEventSourceMappingConfigurations.map((item, index) => {
					return (
						<div className='border-bottom' key={index}>
							<div className='d-flex mb-2'>
								<div className='flex-grow-1 align-self-center'>
									<h4 className='mb-0 text-white'>{item.functionArn}</h4>
								</div>
								<div className='text-right'>
									<small>{item.eventSourceArn}</small>
									<h5 className='mb-0 text-white'>{item.batchSize}</h5>
								</div>
							</div>
							<p>
								Service Name: <br />
								<span className='text-white'>{item.functionArn}</span>
							</p>
						</div>
					)
				})}
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
TriggerConfiguration.propTypes = {
	lambdaFunctionEventSourceMappingConfigurations: PropTypes.array,
}

export default TriggerConfiguration
