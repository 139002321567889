
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ErrorsTab.js
 * @author Prakash // on 06/04/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'

import { Spinner } from 'reactstrap'
import { listErrorResults, getObservabilityDailyTrend } from '../../../actions/aiops/ErrorAction'
import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'

import _ from 'lodash'
import { momentDateGivenFormat, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, capitalizeFirstLetter } from '../../../utils/utility'

import Search from '../../common/SearchComponent'
import ApexTrendBarChart from '../../common/charts/ApexTrendBarChart'

class ErrorsTab extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            errorListLoader: true,

            startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
            overAllTotalPages: 1,
			perPage: 100,
            
            groupedBy: 'severity',
            selectedCategory: 'Important / All',
            pageType: "Errors",
            minimizeGraphSection: false,
        }
    }

    componentDidMount = () => {
        if(this.props.observabilityAssetPageFilter && Object.entries(this.props.observabilityAssetPageFilter).length) {
            this.setState({ 
                start_time: this.props.observabilityAssetPageFilter.start_time,
                end_time: this.props.observabilityAssetPageFilter.end_time,
                brushStartTime: this.props.observabilityAssetPageFilter.brushStartTime ? this.props.observabilityAssetPageFilter.brushStartTime : this.props.observabilityAssetPageFilter.start_time,
                brushEndTime: this.props.observabilityAssetPageFilter.brushEndTime ? this.props.observabilityAssetPageFilter.brushEndTime : this.props.observabilityAssetPageFilter.end_time,
                selectedAsset: this.props.observabilityAssetPageFilter.selectedAsset ? this.props.observabilityAssetPageFilter.selectedAsset : {},
                errorListLoader: true,
                graphData: {}
            },
                () => {
                    this.getObservabilityErrorDailyTrend()
                    this.listErrorResults()
                }
            )
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.observabilityAssetPageFilter && Object.entries(this.props.observabilityAssetPageFilter).length) {
            if(!_.isEqual(prevProps.observabilityAssetPageFilter, this.props.observabilityAssetPageFilter)) {
                this.setState({ 
                    start_time: this.props.observabilityAssetPageFilter.start_time,
                    end_time: this.props.observabilityAssetPageFilter.end_time,
                    brushStartTime: this.props.observabilityAssetPageFilter.brushStartTime ? this.props.observabilityAssetPageFilter.brushStartTime : this.props.observabilityAssetPageFilter.start_time,
                    brushEndTime: this.props.observabilityAssetPageFilter.brushEndTime ? this.props.observabilityAssetPageFilter.brushEndTime : this.props.observabilityAssetPageFilter.end_time,
                    selectedAsset: this.props.observabilityAssetPageFilter.selectedAsset ? this.props.observabilityAssetPageFilter.selectedAsset : {},
                    errorListLoader: true,
                    graphData: {},
                },
                    () => {
                        this.getObservabilityErrorDailyTrend()
                        this.listErrorResults()
                    }
                )
            }
        }
    }

    getObservabilityErrorDailyTrend = () => {
        let params = {}
        params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss')
        params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss')
        params.duration_aggregate_by = "seconds"
        let assetId = this.state.selectedAsset && this.state.selectedAsset.asset_id ? this.state.selectedAsset.asset_id : ""
        params.asset_id = assetId

        // let params = {}
        // params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss')
        // params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss')
        // let assetId = this.state.selectedAsset && this.state.selectedAsset.asset_id ? this.state.selectedAsset.asset_id : ""
        // params.asset_id = assetId
        // params.aggregate_by = ["event_type"]
        // params.radial = true
        // params.duration_aggregate_by = "minutes"
        
        this.props.getObservabilityDailyTrend(params, (promise, response) => {
            if(promise) {
                this.setState({ errorDailyTrendData: response},
                    () => this.errorTrend()
                )
            } else {
                this.setState({ errorDailyTrendData: {} })
            }
        })
    }

    errorTrend = () => {
        let graphData = {
            start_time: momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss'), 
            end_time: momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss')
        }
        let trendSeverityCount = this.state.errorDailyTrendData.total_count ? this.state.errorDailyTrendData.total_count : {}
        let graphDetails = this.state.errorDailyTrendData.results ? this.state.errorDailyTrendData.results[0] : {} 
        if(graphDetails && graphDetails.data) {
            graphData = {
                data: graphDetails.data,
                labels: graphDetails.dates,
                start_time: momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'), 
                end_time: momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss')
            }
        }

        this.setState({ graphData, trendSeverityCount })
    }

    resetGraph = () => {
        let graphData = this.state.graphData
        this.setState({ graphData: {}, zoomedStartDate: "", zoomedEndDate: "" },
            () => {
                this.getCategoryAlerts()
                this.setState({ graphData })
            }
        )
    }

    listErrorResults = () => {
        let params = {}
        params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss')
        params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss')
        params.aggregate_by = this.state.groupedBy
        if(this.state.nextErrorListToken && this.state.nextErrorListToken !== '') {
            params.next_token = this.state.nextErrorListToken
        }
        let assetId = this.state.selectedAsset && this.state.selectedAsset.asset_id ? this.state.selectedAsset.asset_id : ""
        params.asset_id = assetId
        params.size = 1000
        this.props.listErrorResults(params, (promise, response) => {
            if(promise) {
                let startRecord = 0
                let currentPage = 1
                
                let results = response.results && response.results.details ? response.results.details : []
        
                let totalPages = 1
                if(results.length > this.state.perPage) {
                    totalPages = Math.ceil(results.length / this.state.perPage)
                }

                let totalErrorList = [] 
                if(this.state.nextErrorListToken && this.state.nextErrorListToken !== '') {
                    totalErrorList = this.state.errorResponse ? this.state.errorResponse : []
                    startRecord = this.state.startRecord
                    currentPage = this.state.currentPage
                }
                
                if(results.length) {
                    totalErrorList = totalErrorList.concat(results)
                }

                this.setState({
                    errorResponse: totalErrorList,
                    filteredArray: totalErrorList,
                    errorListLoader: false,
                    nextErrorListToken: response.next_token ? response.next_token : '', 
                    totalPages,
                    startRecord,
                    currentPage 
                },
                    () => {
                        this.structureErrors()
                        this.props.menuDisabled()
                    }
                )
            } else {
                this.setState({ errorListLoader: false },
                    () => this.props.menuDisabled()    
                )
            }
        })
    }

    structureErrors = () => {
        let listAllErrors = []
        let totalCount = 0
        let errorsCategory = []
        if(this.state.errorResponse && this.state.errorResponse.length) {
            totalCount = this.state.errorResponse.reduce( function(a, b){ return a + b.count;}, 0);
            this.state.errorResponse.forEach((item, index) => {
                let dataRow = {}
                dataRow.count = item.count
                dataRow.category = item.label
                dataRow.orderBy = index+1
                let percentage = Math.ceil(((item.count / totalCount) * 100)/10)
                let array = []
                for(let i=1; i<=percentage; i++) {
                    array.push(i)
                }
                dataRow.totalBars = array

                errorsCategory.push(dataRow)
                item.events.forEach(alt => {
                    listAllErrors.push(alt)
                })
            })
        }

        let dataRow = {}
        dataRow.count = ""
        dataRow.category = 'Important / All'
        dataRow.orderBy = 0
        errorsCategory.push(dataRow)

        this.setState({ listAllErrors, errorsList: listAllErrors, errorsCategory: _.orderBy(errorsCategory, ['orderBy'], ['asc'])},
            () => this.getCategoryAlerts()
        )
    }
    
    getCategoryAlerts = () => {
        let filterdCategoryErrors = this.state.listAllErrors
        if(this.state.selectedCategory !== 'Important / All') {
            filterdCategoryErrors = this.state.errorResponse.filter(arr => arr.label === this.state.selectedCategory)
            if(filterdCategoryErrors && filterdCategoryErrors.length) {
                filterdCategoryErrors = filterdCategoryErrors[0].events ? filterdCategoryErrors[0].events : []
            }
        }

        let orderedArray = _.orderBy(filterdCategoryErrors, ['event_time'], ['desc'])

        let totalPages = 1
		if(orderedArray.length > this.state.perPage) {
			totalPages = Math.ceil(orderedArray.length / this.state.perPage)
		}
        this.setState({ totalFilteredArray: orderedArray, filteredArray: orderedArray, selectedError: "", selectedErrorId: "", totalPages, startRecord: 0, currentPage: 1 })
    }

    gotoPage = (pageNumber) => {
        if(pageNumber > 0  && pageNumber <= this.state.totalPages) {
            let startRecord = (pageNumber - 1) * this.state.perPage
            this.setState({ currentPage: pageNumber, startRecord })
        }
    }

    getErrorDetails = (selectedError) => {
        this.setState({ 
            selectedErrorId: selectedError.event_id === this.state.selectedErrorId ? "" : selectedError.event_id, 
            selectedError: selectedError.event_id === this.state.selectedErrorId ? "" : selectedError,
            expandErrorDetails: true, 
            expandMoreDetails: false,
            expandAssetDetails: false
        })
    }
    
    handleChange = (event) => {
        this.setState({ groupType: event.target.value, selectedError: {}, selectedErrorId: "", errorListLoader: true },
            () => {
                this.listErrorResults()
                // this.redirectToAnalysis()
            }    
        )
    }

    filterZoomData = (zoomedStartDate, zoomedEndDate) => {

        let data = this.state.totalFilteredArray ? this.state.totalFilteredArray : []

        data = data.filter(e => momentConvertionUtcToLocalTime(e.event_time, "YYYY-MM-DD HH:mm:ss") >= zoomedStartDate && momentConvertionUtcToLocalTime(e.event_time, "YYYY-MM-DD HH:mm:ss") <= zoomedEndDate)

        data = _.orderBy(data, ['event_time'], ['desc'])

        let totalPages = 1
		if(data.length > this.state.perPage) {
			totalPages = Math.ceil(data.length / this.state.perPage)
		}

        this.setState({ totalFilteredArray: data, filteredArray: data, totalPages, startRecord: 0, currentPage: 1, zoomedStartDate, zoomedEndDate },
            () => {
                if(this.state.filteredArray.length) {
                    // this.getEventDetails(this.state.filteredArray[0])
                }
            }    
        )

    }

    navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = this.state.startRecord
            if(action === 'next' && currentPage !== this.state.totalPages) {
                startRecord = startRecord + this.state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - this.state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = this.state.totalPages
                startRecord = (this.state.totalPages - 1) * this.state.perPage
            }
            this.setState({ currentPage, startRecord })
        }
	}

    gotoPage = (pageNumber) => {
        if(pageNumber > 0  && pageNumber <= this.state.totalPages) {
            let startRecord = (pageNumber - 1) * this.state.perPage
            this.setState({ currentPage: pageNumber, pageNumber, startRecord })
        } else  if(pageNumber > this.state.totalPages) {
            this.setState({ pageNumber: this.state.currentPage })  
        } else {
            this.setState({ pageNumber })
        }
    }

    handleClickOutside(event) {
        // if (this.toggleRef && !this.toggleRef.current.contains(event.target)) {
        //     this.setState({ showPageTypeOptions: false })
        // } else {
        //     this.setState({ showPageTypeOptions: true })
        // }
    }

    render() {
        return (
            <div className="" onClick={(event) => this.handleClickOutside(event)}>
                <div className="bg-dark3 mt-2 p-3 rounded">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex">
                            <label className="m-0 p-0">Total ({this.state.trendSeverityCount && this.state.trendSeverityCount.total ? this.state.trendSeverityCount.total : 0})</label>
                            <div className="d-flex ml-2 align-self-center">
                                <div className={`f10 badge-square-sm risk-badge-critical`}>C</div>
                                <p className="m-0 f11 text-white"><span className="mx-1">:</span> {this.state.trendSeverityCount && this.state.trendSeverityCount.severity && this.state.trendSeverityCount.severity.Critical ? this.state.trendSeverityCount.severity.Critical : 0}</p>
                            </div>
                            <div className="d-flex ml-2 align-self-center">
                                <div className={`f10 badge-square-sm risk-badge-high`}>H</div>
                                <p className="m-0 f11 text-white"><span className="mx-1">:</span> {this.state.trendSeverityCount && this.state.trendSeverityCount.severity && this.state.trendSeverityCount.severity.High ? this.state.trendSeverityCount.severity.High : 0}</p>
                            </div>
                            <div className="d-flex ml-2 align-self-center">
                                <div className={`f10 badge-square-sm risk-badge-medium`}>M</div>
                                <p className="m-0 f11 text-white"><span className="mx-1">:</span> {this.state.trendSeverityCount && this.state.trendSeverityCount.severity && this.state.trendSeverityCount.severity.Medium ? this.state.trendSeverityCount.severity.Medium : 0}</p>
                            </div>
                            <div className="d-flex ml-2 align-self-center">
                                <div className={`f10 badge-square-sm risk-badge-low`}>L</div>
                                <p className="m-0 f11 text-white"><span className="mx-1">:</span> {this.state.trendSeverityCount && this.state.trendSeverityCount.severity && this.state.trendSeverityCount.severity.Low ? this.state.trendSeverityCount.severity.Low : 0}</p>
                            </div>
                        </div>
                        <div className="d-flex">
                            {!this.state.minimizeGraphSection ?
                                <i className='far fa-redo cursorPointer' onClick={()=> this.resetGraph()}></i>
                            : null}
                            <i className={`fa fa-caret-${this.state.minimizeGraphSection ? "down" : "down"} cursorPointer ml-3 text-gray2`} onClick={() => this.setState({ minimizeGraphSection: !this.state.minimizeGraphSection })}></i>
                        </div>
                    </div>
                    {this.state.graphData && Object.keys(this.state.graphData).length && !this.state.minimizeGraphSection ?
                        <div className="mt-n2"> 
                            <ApexTrendBarChart
                                graphData={this.state.graphData}
                                graphRiskCountSection={false}
                                sparkline={false}
                                yaxis={false}
                                yaxisLabel={true}
                                yaxisTickAmount={2}
                                xaxis={true}
                                xaxisFormat={'string'}
                                xaxisLabel={true}
                                axisLabelColor={'#50586A'}
                                legend={false}
                                stacked={false}
                                height={100}
                                horizontal={false}
                                barHeight={'40%'}
                                barEndShape={'flat'}
                                columnWidth={'0%'}
                                backgroundBarShape={'rounded'}
                                backgroundBarColors={['#333947']}
                                showBackgroundBarColors={true}
                                className={"transparentTooltip"}
                                colors={['#3DAFE8']}
                                zoomEnabled={true}
                                getZoomPeriod={true}
                                zoomPeriod={(zoomedStartDate, zoomedEndDate) => {this.filterZoomData(zoomedStartDate, zoomedEndDate)}}
                            />
                        </div>
                    : null}
                </div>
                <div className="d-flex mt-2">
                    <div className="rounded bg-dark p-0 col-sm-12">
                        <div className={`d-flex justify-content-between mt-2 ml-1 mb-2 ${this.state.errorListLoader ? 'disabled' : ''}`}>
                            <div className="d-flex">
                                <p className="m-0 align-self-end small mr-2">Show All</p>
                                {/* <div class={`transparentSelectBorderBottom align-self-end`}>
                                    <select class="form-control" 
                                    onChange={this.handleChange}
                                    value={this.state.alertType}
                                    >
                                        <option key="All" value="All">ALL</option>
                                        <option key="Application" value="Application">Application</option>
                                        <option key="Infra" value="Infra">Infra</option>
                                    </select>
                                </div> */}
                                <p className="m-0 align-self-end small mr-2">errors groupd by</p>
                                <div class={`transparentSelectBorderBottom align-self-end`}>
                                    <select class="form-control" 
                                        onChange={(event) => {
                                            this.setState({ groupedBy: event.target.value, showGroupLoading: true, selectedCategory: "Important / All", selectedError: {}, selectedErrorId: "", errorListLoader: true },
                                                () => {
                                                    this.listErrorResults()
                                                }
                                            )
                                        }}
                                        value={this.state.groupedBy}                            
                                        >
                                        <option key="severity" value="severity">Severity</option>
                                        <option key="impact_type" value="impact_type">Impact</option>
                                        <option key="category" value='category'>Category</option>
                                    </select>
                                </div>
                            </div>
                            <div className="d-flex ml-2 w-30">
                                <p className="m-0 align-self-end small mx-2">Category</p>
                                {this.state.errorsCategory ? 
                                    <div class={`transparentSelectBorderBottom align-self-end`}>
                                        <select className="form-control" 
                                            value={this.state.selectedCategory}
                                            onChange={(event) => {
                                                this.setState({ selectedCategory: event.target.value },
                                                    () => {
                                                        this.getCategoryAlerts()
                                                    }    
                                                )
                                            }}>
                                            {this.state.errorsCategory.map(item => {
                                                return(
                                                <option value={item.category}>{item.category+" "+item.count}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                : null}
                            </div>
                        </div>
                        <div className="d-flex mb-2 justify-content-between">
                            <div className="d-flex mr-2 w-70">
                                <p className="f12 m-0 align-self-center pt-1">Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.state.totalFilteredArray && this.state.totalFilteredArray.length}{this.state.totalFilteredArray && this.state.totalFilteredArray.length > 1 ? ' errors' : ' error'}</p>
                                {this.state.zoomedStartDate && this.state.zoomedStartDate !== "" ? 
                                    <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{momentDateGivenFormat(this.state.zoomedStartDate, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.zoomedEndDate, 'DD MMM YYYY HH:mm')}</p>
                                : 
                                    <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{this.state.brushStartTime ? momentDateGivenFormat(this.state.brushStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.brushEndTime, 'DD MMM YYYY HH:mm') : ''}</p>
                                }
                            </div>
                            <div className="d-flex justify-content-end w-30 mb-n2">
                                {this.state.totalFilteredArray && this.state.totalFilteredArray.length ?
                                    <Search
                                        data={this.state.totalFilteredArray ? this.state.totalFilteredArray : []}
                                        applyTags={false}
                                        applyLiteDarkTags={true}
                                        topClassName={'bg-black5 align-self-center w-100 mr-1 border-gray5 rounded-5 f12'}
                                        searchClassName={'px-2 f12'}
                                        searchIconColor={'text-gray5 f12'}
                                        searchPlaceHolder={'Search errors....'}
                                        className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                        filteredData={(filteredArray) => {
                                            this.setState({ filteredArray: filteredArray, selectedError: {}, merticsAssetNameInvolved: [] },
                                                // () => this.redirectToAnalysis()  
                                                () => {
                                                    if(this.state.filteredArray.length) {
                                                        // this.getErrorDetails(this.state.filteredArray[0])
                                                    }  
                                                }
                                            )
                                        }}
                                    />
                                : null}
                                {this.state.filteredArray && this.state.filteredArray.length > this.state.perPage ?
                                    <div className="pagination errorPagePagination f12 displayNone">
                                        <span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
                                        <button><i className={`fal fa-arrow-to-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('start', this.state.currentPage)}></i></button> 
                                        <button><i className={`fal fa-angle-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('previous', this.state.currentPage)}></i></button> 
                                        <span>
                                            Go to
                                            <input
                                                type='number'
                                                value={this.state.pageNumber || this.state.pageNumber === "" ? this.state.pageNumber : this.state.currentPage}
                                                onChange={e => {
                                                    const page = e.target.value ? Number(e.target.value) : ""
                                                    this.gotoPage(page)
                                                }}
                                                className="ml-1 inputClass"
                                            />
                                        </span>
                                        <button><i className={`fal fa-angle-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
                                        <button><i className={`fal fa-arrow-to-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
                                    </div>
                                : null}
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className={`w-100`}>
                                <div className={`bg-dark3 py-3 pl-2 ${!this.state.minimizeGraphSection ? "obsMessageSection" : "obsMessageSectionLg"}`}>
                                    {this.state.errorListLoader ? 
                                        <div className='d-flex justify-content-center m-4'>
                                            <Spinner className='text-center' color='white' size='lg' />
                                        </div>
                                    : this.state.filteredArray && this.state.filteredArray.length ?
                                        // this.state.filteredArray.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map((item, i) => {
                                        this.state.filteredArray.map((item, i) => {
                                            return(
                                                <div className={`px-2 mb-2 pb-1 border-bottom-black5 ${this.state.selectedErrorId && this.state.selectedErrorId === item.event_id ? "bg-muted" : ""}`}>
                                                    <div className="d-flex cursorPointer" onClick={() => this.getErrorDetails(item)}>
                                                        <span className={`f10 mr-2 align-self-start mt-1 badge-sm risk-badge-${item.severity ? item.severity.toLowerCase() : 'critical'}`}>
                                                            {item.severity ? 
                                                                (item.severity.toLowerCase() === 'critical' ? 'C' : item.severity.toLowerCase() === 'high' ? 'H' : item.severity.toLowerCase() === 'medium' ? 'M' : 'L') 
                                                            : 'U'}
                                                        </span>
                                                        <p className="mb-0 text-white">
                                                            <span className="mb-0 mr-2 f12 text-primary-color font-weight-bold">{item.event_time ? momentConvertionUtcToLocalTime(item.event_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                            <span className="mb-0 mr-2 f12 font-weight-bold text-purple-2">Errors</span>
                                                            {item.origin && item.origin.asset_name ? 
                                                                <span className="f12 mb-0 mr-2 text-purple-2"> Asset {item.origin.asset_name}</span> 
                                                            : null}
                                                            <span className="mb-0 mr-2 f12 font-weight-bold">{item.message ? item.message : ''}</span>
                                                        </p>
                                                    </div>
                                                    {this.state.selectedErrorId && this.state.selectedErrorId === item.event_id ?
                                                        <div className="rounded bg-dark3 p-3 mt-2">
                                                            <div className="accordion" id="accordionExample">
                                                                <div className={`card bg-dark border-0 mb-2`}>
                                                                    <div id={'heading_event'} onClick={() => this.setState({ expandErrorDetails: !this.state.expandErrorDetails })}>
                                                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
                                                                            <div className="text-white"><span className={`far ${this.state.expandErrorDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Error Details</div>
                                                                            {/* <div className="text-info">Showing event details</div> */}
                                                                        </div>
                                                                    </div>
                                                                    <div id={'collapse_event'} className={`collapse ${this.state.expandErrorDetails ? 'show' : <span>&nbsp;</span>}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
                                                                        <div className="card-body">
                                                                            <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
                                                                                <div>
                                                                                    <p className="mb-0 f16 text-white">{this.state.selectedError.qid ? this.state.selectedError.qid : <span>&nbsp;</span>}</p>
                                                                                    <p className="mb-0 f12">{this.state.selectedError.description ? this.state.selectedError.description : <span>&nbsp;</span>}</p>
                                                                                </div>
                                                                                <div className="ml-3">
                                                                                    <p className="b-block mb-0">Severity</p>
                                                                                    <span className={`mr-2 badge ${this.state.selectedError.severity && this.state.selectedError.severity.toLowerCase() !== 'nan' ? 'risk-badge-'+this.state.selectedError.severity.toLowerCase() : 'badge-secondary' }`}>  
                                                                                        {this.state.selectedError.severity ? capitalizeFirstLetter(this.state.selectedError.severity) : 'Unknown'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex mb-2 border-bottom">
                                                                                <div className="py-1 w-50">
                                                                                    <p className="b-block mb-0">Log Type Source:</p>
                                                                                    <p className="mb-0 text-white">{this.state.selectedError.error_type_source ? this.state.selectedError.error_type_source : ''}</p>
                                                                                </div>
                                                                                <div className="py-1 w-50 pl-3">
                                                                                    <p className="b-block mb-0">Log File Type Seen:</p>
                                                                                    <p className="mb-0 text-white">
                                                                                        {this.state.selectedError.log_file_type ? this.state.selectedError.log_file_type : ''}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className={`card bg-dark border-0 mt-3 displayNone`}>
                                                                    <div id={'heading_event'} className="cursorPointer" onClick={() => this.setState({ expandMoreDetails: !this.state.expandMoreDetails })}>
                                                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
                                                                            <div className="text-white">
                                                                                <span className={`far ${this.state.expandMoreDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>
                                                                                Solution
                                                                            </div>
                                                                            {/* <div className="text-info">Showing event details</div> */}
                                                                        </div>
                                                                    </div>
                                                                    <div id={'collapse_event'} className={`collapse ${this.state.expandMoreDetails ? 'show' : ''}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
                                                                        <div className="card-body">
                                                                            <div className="d-flex mb-2 border-bottom">
                                                                                <div className="py-1">
                                                                                    <p className="b-block mb-0">Cause:</p> 
                                                                                    {this.state.selectedError.cause ? 
                                                                                        this.state.selectedError.cause.map((item, i) => {
                                                                                            return (
                                                                                                <small className={`d-flex flex-wrap mb-1 text-white`}>
                                                                                                    <span className="primary-color mr-1">{(i+1)+'. '}</span>
                                                                                                    {item}
                                                                                                </small>
                                                                                            )
                                                                                        }) 
                                                                                    : null
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex mb-2 border-bottom">
                                                                                <div className="py-1">
                                                                                    <p className="b-block mb-0">Solution:</p> 
                                                                                    <small className={`d-flex flex-wrap mb-1 text-white`}>{this.state.selectedError.solution ? this.state.selectedError.solution : ''}</small>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex flex-row">
                                                                                <div className="py-1">
                                                                                    <p className="b-block mb-0">External Link:</p>
                                                                                    {this.state.selectedError.external_link ? 
                                                                                        this.state.selectedError.external_link.map((item, i) => {
                                                                                            return (
                                                                                                <small className={`d-flex flex-wrap mb-1`}>
                                                                                                    <a target='_blank' href={item}><span className="primary-color mr-1">{(i+1)+'. '}</span>{item}</a>
                                                                                                </small>
                                                                                            )
                                                                                        })
                                                                                    : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className={`card bg-dark border-0 mt-3`}>
                                                                    <div id={'heading_config_assets'} onClick={() => this.setState({ expandAssetDetails: !this.state.expandAssetDetails })}>
                                                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                                                            <div className="text-white"><span className={`far ${this.state.expandAssetDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Asset Details</div>
                                                                            {/* <div className="text-info">Showing asset details</div> */}
                                                                        </div>
                                                                    </div>
                                                                    <div id={'collapse_config_assets'} className={`collapse ${this.state.expandAssetDetails ? 'show' : ""}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                                                        {this.state.selectedError.assets.map(item => {
                                                                            return(
                                                                            <div className="card-body m-2 pt-2 rounded counts_threads">
                                                                                <div className="d-flex mb-2">
                                                                                    <div className="py-1 w-50">
                                                                                        <p className="b-block mb-0">Name:</p>
                                                                                        <p className="mb-0 text-white">{item.asset_name ? item.asset_name : ""}</p>
                                                                                    </div>
                                                                                    <div className="py-1 w-50 pl-3">
                                                                                        <p className="b-block mb-0">Id:</p>
                                                                                        <p className="mb-0 text-white">{item.asset_id ? item.asset_id : ""}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex mb-2 border-top">
                                                                                    <div className="py-1 w-50">
                                                                                        <p className="b-block mb-0">Resource:</p>
                                                                                        <p className="mb-0 text-white">
                                                                                            {this.state.selectedError.provider ? this.state.selectedError.provider.toUpperCase() : ""}
                                                                                            {this.state.selectedError.account_id ? (" : ")+this.state.selectedError.account_id : ""}
                                                                                            {this.state.selectedError.region ? (" : ")+this.state.selectedError.region : ""}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="py-1 w-50 pl-3">
                                                                                        <p className="b-block mb-0">Services:</p>
                                                                                        <p className="mb-0 text-white">
                                                                                            {item.resource_type ? item.resource_type : ""}
                                                                                            {item.service_name ? (" : ")+item.service_name : ""}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>                                                        
                                                                                <div className="d-flex mb-2 border-top">
                                                                                    <div className="py-1 w-100">
                                                                                        <p className="b-block mb-0">ARN:</p>
                                                                                        <p className="mb-0 text-white">{item.asset_arn ?item.asset_arn : <span>&nbsp;</span>}</p>
                                                                                    </div>
                                                                                </div>
                                                                                {item.category ? 
                                                                                    <div className="d-flex mb-2 border-top">
                                                                                        <div className="py-1 w-100">
                                                                                            <p className="b-block mb-0">Category:</p>
                                                                                            <div className={`d-flex flex-wrap`}>
                                                                                                {item.category.map(item => {
                                                                                                    return (
                                                                                                        <small className={`mr-1 mb-1 badge badge-light`}>{item}</small> 
                                                                                                    )
                                                                                                })}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                : null}
                                                                            </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    : null}
                                                </div>
                                                
                                            )
                                        })
                                    :
                                        <div className='d-flex justify-content-center m-4'>
                                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
ErrorsTab.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log('errorDashboard',state)
    return {
        observabilityAssetPageFilter: state.aiops.aiopsPropsDetails.observabilityAssetPageFilter ? state.aiops.aiopsPropsDetails.observabilityAssetPageFilter : {},
        obsAssetErrorsList: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.obsAssetErrorsList ? state.aiops.aiopsPropsDetails.obsAssetErrorsList : {},
    }
}

export default connect(mapStateToProps, {
    setAiopsPropsDetails,
    getObservabilityDailyTrend,
    listErrorResults,
})(withRouter(ErrorsTab))