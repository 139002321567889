
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ApplyGrokAndGroupBy.js
 * @author Prakash // on 13/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'

import { Input } from 'reactstrap'
import { setAutomationPropsDetails } from '../../actions/commonAction'

class ApplyGrokAndGroupBy extends Component {
    constructor(props) {
        super(props)
        this.extractRef = React.createRef()
        this.state = {
            activeSection: this.props.activeSection,
            // previousAutomations: [],
            fieldstoExtract: []
        }
    }

    componentDidMount = () => {
        if(this.props.automationPropsDetails) {
            let obj = this.props.automationPropsDetails
            let exist = obj.actions.filter(e => e.section === this.state.activeSection)
            if(exist.length) {
                let input = exist[0].input ? exist[0].input : []
                input.forEach(item => {
                    this.setState({ [item.key]: item.value_list ? item.value_list : item.value },
                        () => {
                            if(item.key === "pattern") {
                                this.listFieldstoExtract("onLoad")
                            }
                            this.checkAllDataFilled()
                        }
                    )
                })                
            }
            // let actions = this.props.automationPropsDetails.actions ? this.props.automationPropsDetails.actions : []
            // let previousAutomations = actions.filter(e => e.section < this.state.activeSection)

            // this.setState({ previousAutomations, previousAutomationsSearchResult: previousAutomations })
        }
    }

    componentDidUpdate = (prevProps) => {}

    onActionSelect = (action) => {
        this.setState({ 
            selectedAction: action, 
            showActionResults: false 
        },
            // () => this.formInputProps(["action_"+this.state.activeSection], "action", this.state.selectedAction)
        )
    }

    formInputProps = (section, field, value) => {
        let obj = this.props.automationPropsDetails
        let actions = obj.actions
        let objIndex = actions.findIndex((e => e.section === section))
        let dataRow = {}
        dataRow.key = field
        if(field === 'fields_to_extract') {
            dataRow.value_list = value
        } else {
            dataRow.value = value
        }
        if(this.state["dynamic_"+field]) {
            dataRow.dynamic = true
        }

        let input = actions[objIndex].input ? actions[objIndex].input : []        
        let inputIndex = input.findIndex((e => e.key === field))
        if(inputIndex > -1) {
            input[inputIndex] = dataRow
        } else {
            input.push(dataRow)
        }
        actions[objIndex].input = input
        obj.actions = actions
        this.props.setAutomationPropsDetails(obj)

        this.checkAllDataFilled()
    }

    checkAllDataFilled = () => {
        if(this.state.pattern && this.state.pattern !== "" && this.state.fields_to_extract && this.state.fields_to_extract.length) {
            this.props.actionCompleted(true)
        } else {
            this.props.actionCompleted(false)
        }
    }

    onKeyDown = (e, field, stateField) => {
        let array = this.state[stateField] ? this.state[stateField] : []
		if (e.keyCode === 13 || e.keyCode === 9) {
            if(this.state[field] && this.state[field] !== "") {
                array.push(this.state[field].trim())
                if(e.keyCode === 9) {
                    e.preventDefault();
                }
                this.setState({ [stateField]: array, [field]: "" },
                    () => this.formInputProps(this.state.activeSection, stateField, this.state[stateField])
                )
            }
        }
    }

    onBlur = (e, field, stateField) => {
        let array = this.state[stateField] ? this.state[stateField] : []
        if(this.state[field] && this.state[field] !== "") {
            array.push(this.state[field].trim())
            this.setState({ [stateField]: array, [field]: "" },
                () => this.formInputProps(this.state.activeSection, stateField, this.state[stateField])
            )
        }
    }   

    removeSelectedBadge = (field, value) => {
        let filteredReslt = this.state[field].filter(e => e !== value)
        this.setState({ [field]: filteredReslt })
    }

    listFieldstoExtract = (type) => {
        let fieldstoExtract = []
        let re = /\[(.*?)\]/
        var matches = this.state.pattern.split(re);
        if(matches.length) {
            matches.forEach(mat => {
                let res = /{(.*?)}/;
                if(mat.match(res) !== null) {
                    var split = mat.split("%")
                    split.forEach(it => {
                        if(it.match(res) !== null) {
                            let split1 = it.match(res)
                            let splitColen = split1[1].split(":")
                            if(splitColen[1]) {
                                fieldstoExtract.push(splitColen[1])
                            }
                        }
                    })
                }
            })
        }
        let fields_to_extract = this.state.fields_to_extract ? this.state.fields_to_extract : []
        if(type !== "onLoad") {

            //add newly added extract to the state
            let difference = fieldstoExtract.filter(x => !this.state.fieldstoExtract.includes(x));
            fields_to_extract = [...fields_to_extract, ...difference];

            //remove recently removed extract to the state
            fields_to_extract = fields_to_extract.filter(x => fieldstoExtract.includes(x));
        }
        this.setState({ fieldstoExtract, fields_to_extract },
            () => this.formInputProps(this.state.activeSection, "fields_to_extract", this.state.fields_to_extract)  
        )
    }

    getMultiSelectedCount = (type, array) => {
		return array && array.length && array.filter(e => e.toLowerCase() === 'all').length ? 'All' :  array && array.length ? array.length +' Selected' : 'Select'
	}

    handleMultiSelectChange = (field, arrayValue) => {
		let value = arrayValue.map(item => item.value)
		// let label = arrayValue.map(item => item)

		this.setState({ [field]: value },
            this.formInputProps(this.state.activeSection, field, this.state[field])    
        )
	}

	handleClickDropOutside = (event) => {
		if (this.extractRef && this.extractRef.current !== null && !this.extractRef.current.contains(event.target)) {
			this.setState({ isExtractopen: false })
		} else {
			this.setState({ isExtractopen: true })
		}
	}

    updateFieldstoExtract = (field, tab) => {
        let selectedFields = this.state[field]
        if(this.state.fieldstoExtract && this.state.fieldstoExtract.length) {
            if(selectedFields.includes(tab)) {
                selectedFields = selectedFields.filter(e => e !== tab)
            } else {
                selectedFields.push(tab)
            }
        }
        
        this.setState({ [field]: selectedFields },
            () => this.formInputProps(this.state.activeSection, field, this.state[field])    
        )
        // this.removeSelectedBadge("fields_to_extract", tab)
    }

    render() {
        return (
            <div className="">
                <div className="d-flex mt-3">
                    <div className="w-100">
                        <label className="mb-1">Grok Pattern</label>
                        <div className="col-sm-12 p-0">
                            <Input
                                type="textarea"
                                rows={5}
                                placeholder="pattern"
                                className="inputTextbox"
                                value={this.state.pattern}
                                onChange={event =>
                                    this.setState({ pattern: event.target.value },
                                        () => {
                                            this.listFieldstoExtract()
                                            this.formInputProps(this.state.activeSection, "pattern", this.state.pattern)
                                        }
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="d-flex mt-3">                
                    <div className=" w-50">
                        <label className="mb-1">List of fields to extract</label>
                        <div className="col-sm-12 p-0 displayNone">
                            {/* <div className='multiselectDarkTheme' ref={this.extractRef}>
                                <ReactMultiSelectCheckboxes						
                                    placeholderButtonLabel="Select"
                                    // menuIsOpen ={this.state.isExtractopen}
                                    getDropdownButtonLabel={() => this.getMultiSelectedCount('fields_to_extract', this.state.fields_to_extract)}
                                    onChange={arr => { this.handleMultiSelectChange('fields_to_extract', arr ? arr : []) }}
                                    options={this.state.fieldstoExtract.map(acc => ({
                                        value: acc,
                                        label: acc,
                                    }))}
                                    value={this.state.fields_to_extract && this.state.fields_to_extract.map(comp => ({
                                        value: comp,
                                    }))}
                                />
                            </div> */}
                            {/* <Input
                                type="text"
                                placeholder="fields to extract"
                                className="inputTextbox"
                                value={this.state.fields_to_extract_input}
                                onChange={event => this.setState({ fields_to_extract_input: event.target.value })}
                                onKeyDown={e => this.onKeyDown(e, "fields_to_extract_input", "fields_to_extract")}
                                onBlur={e => this.onBlur(e, "fields_to_extract_input", "fields_to_extract")}
                            /> */}
                        </div>
                    
                        <div className="d-flex flex-wrap mt-1">
                            {this.state.fieldstoExtract && this.state.fieldstoExtract.map((tab, index) => {
                                return(
                                <span key={index} className={`badge-square f14 align-self-center p-1 mr-2 mb-1 badge-${this.state.fields_to_extract && this.state.fields_to_extract.includes(tab) ? "primary" : "secondary text-decoration-line-through"} `} >
                                {tab}
                                {/* <i class="far fa-times-circle"></i> */}
                                {/* <i class="fa-solid fa-check"></i> */}
                                    <i className={`ml-1 ${this.state.fields_to_extract && this.state.fields_to_extract.includes(tab) ? "fal fa-times" : "fal fa-check"} cursorPointer`} onClick={ () => this.updateFieldstoExtract("fields_to_extract", tab) } ></i>
                                </span>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="d-flex mt-3">
                    <div className="w-50">
                        <div className="col-sm-12 p-0">
                            <div style={{ display: "inline" }} className="mx-2 align-self-end">
                                <div className="form-check">
                                    <input type="checkbox"
                                        onChange={() => this.setState({ occurrences: !this.state.occurrences },
                                            () => this.formInputProps(this.state.activeSection, "occurrences", this.state.occurrences)
                                        )}
                                        className="form-check-input"
                                        checked={this.state.occurrences}
                                    />
                                    <label className={`form-check-label f14 mt-3px`}>Get count of unique values in extracted columns</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
ApplyGrokAndGroupBy.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log("ApplyGrokAndGroupBy",state)
    return {
        automationPropsDetails: state.filters.automationPropsDetails
    }
}

export default connect(mapStateToProps, {
    setAutomationPropsDetails,
})(withRouter(ApplyGrokAndGroupBy))        