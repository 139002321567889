/*************************************************
 * Tvastar
 * @exports
 * @file index.js
 * @author Rajasekar // on 19/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
let isDev = false
let domain = ''
let region = ''
let userPoolId = ''
let userPoolWebClientId = ''


if (window.location.hostname === 'localhost') {
	isDev = true
	domain = 'localhost'
	region = 'us-east-2'
	// userPoolId = 'us-east-1_BaELZCHZA'
	// userPoolWebClientId = '2c9gpklv7hki5fcilv3nrklegd'
	userPoolId = 'us-east-2_6LEfNehe8'
	userPoolWebClientId = 'vq4uqqd05etg1alanfr1ge0p5'
} else if(window.location.hostname === 'dev.tvastr.ai') {
	isDev = true
	domain='dev.tvastr.ai'
	region = 'us-east-2'
	// userPoolId = 'us-east-1_BaELZCHZA'
	// userPoolWebClientId = '2c9gpklv7hki5fcilv3nrklegd'
	userPoolId = 'us-east-2_6LEfNehe8'
	userPoolWebClientId = 'vq4uqqd05etg1alanfr1ge0p5'
} else if(window.location.hostname === 'tvastr.gravity.invisibl.io') {
	isDev = true
	domain='tvastr.gravity.invisibl.io'
	region = 'us-east-1'
	userPoolId = 'us-east-1_BaELZCHZA'
	userPoolWebClientId = '2c9gpklv7hki5fcilv3nrklegd'
} else if (window.location.hostname === 'beta.tvastr.ai') {	
	isDev = false
	// domain = 'localhost'
	domain = 'beta.tvastr.ai'
	region = 'us-east-2'
	userPoolId = 'us-east-2_5PDHNrief'
	userPoolWebClientId = '1qii3t4bkrj5th55srbpvmumve'
}

export const isDevelopment = isDev

export const REGION = region
export const DOMAIN = domain
export const USER_POOL_ID = userPoolId
export const USER_POOL_WEB_CLIENT_ID = userPoolWebClientId

let synchronizerEndPoint 		= 'https://resources.beta.tvastr.ai' //prod
let detectoreEndPoint           = 'https://checks.beta.tvastr.ai' //prod
let userServiceEndPoint 		= 'https://usrservices.beta.tvastr.ai' //prod
let commonServicesEndPoint		= 'https://cmnservices.beta.tvastr.ai' //prod

let governanceEndPoint			= 'https://governance.beta.tvastr.ai' //prod
// let governanceNewEndPoint		= 'https://sy56zz89x8.execute-api.us-east-2.amazonaws.com/dev'
let governanceNewEndPoint 		= 'https://governance.beta.tvastr.ai'
let iamGovernanceEndPoint  		= 'https://iamgovernance.beta.tvastr.ai' //prod
// let monitoringEndPoint          = 'https://monitoring.beta.tvastr.ai/' //prod
let monitoringEndPoint          = 'https://407o6sa36g.execute-api.us-east-2.amazonaws.com/dev/' //prod
let billingEndPoint          	= 'https://billing.beta.tvastr.ai' //prod
// let pricingEndPoint          	= 'https://pricing.app.tvastr.ai' //prod
let pricingEndPoint          	= 'https://pricing.beta.tvastr.ai' //prod
let scoreEndpoint				= 'https://score.beta.tvastr.ai/' //prod
let aiopsEndPoint 				= 'https://aiops.beta.tvastr.ai/' //prod
let costEndPoint 				= 'https://1umpar3g3f.execute-api.us-east-2.amazonaws.com/dev/'//prod
let aiopsDiagnosticsEndPoint 	= 'https://diagnostics.beta.tvastr.ai/' //prod
let aiopsSreEndPoint			= 'https://sremetrics.beta.tvastr.ai/' //prod
let aiopsAutomationEndPoint		= 'https://4dp20tyx67.execute-api.us-east-2.amazonaws.com/dev' //prod
// let remediationEndPoint 		= 'https://remediations.beta.tvastr.ai/' //prod
let remediationEndPoint 		= 'https://yn8dfcn2re.execute-api.us-east-2.amazonaws.com/dev/' //prod
let profileEndPoint 			= 'https://profiles.beta.tvastr.ai/' //prod
let topologyEndPoint 			= 'https://topology.beta.tvastr.ai/' //prod
let microServiceTopolgyEndPoint = 'https://xd8t6ri0mh.execute-api.us-east-2.amazonaws.com/dev/' //prod
let taggingEndPoint				= 'https://duog3igcdd.execute-api.us-east-2.amazonaws.com/dev' //prod
let configurationEndPoint		= 'https://ewedioa7k3.execute-api.us-east-2.amazonaws.com/dev' //prod
let suppressorEndPoint			= 'https://j4yqoiime4.execute-api.us-east-2.amazonaws.com/dev' //prod

//need to get form backend
let userInsightsEndPoint       	= 'https://userinsights.beta.tvastr.ai' //prod
let maintenanceEndPoint			= 'https://9paurgre45.execute-api.us-east-1.amazonaws.com/dev' //dev and prod
let tagGroupsEndPoint			= 'https://79vgse789b.execute-api.us-east-1.amazonaws.com/dev' //dev and prod
let userSignupEndPoint         	= 'https://4t610991ch.execute-api.us-east-1.amazonaws.com/dev' //dev and prod


//not used
let kpiServicesEndPoint			= 'https://kpi.beta.tvastr.ai' //prod
let healthStatusEndPoint        = 'https://u2b9ze4imi.execute-api.us-east-2.amazonaws.com/status' //prod
let idleAssetsEndPoint			= 'https://11dzqg01zc.execute-api.us-east-2.amazonaws.com/dev' //prod
let monitoringSnapshotEndPoint  = 'https://2qgvi578h1.execute-api.us-east-2.amazonaws.com/dev' //prod


//ned to get from backend not listed in excel
let dlpEndPoint					 = 'https://cx1omlesth.execute-api.us-east-1.amazonaws.com/dev' //dev and prod
let dlpEngineEndPoint			 = 'https://t4yb1teix8.execute-api.us-east-1.amazonaws.com/dev/' //dev and prod
// let dlpThreateEndPoint			 = 'https://0jg97fuwqh.execute-api.us-east-1.amazonaws.com/dev/'
let dlpThreateEndPoint			 = 'https://flodx5rcg5.execute-api.us-east-1.amazonaws.com/dev/' //dev and prod
let dlpPolicyEndPoint			 = 'https://flodx5rcg5.execute-api.us-east-1.amazonaws.com/dev/' //dev and prod


// let errorEndPoint				 = 'https://0glh2s9adi.execute-api.us-east-1.amazonaws.com/dev/'
let errorEndPoint				 = 'https://3apxuz9ef0.execute-api.us-east-2.amazonaws.com/dev/' //prod
// let slowQueryEndPoint			 = 'https://ieilc6s706.execute-api.us-east-1.amazonaws.com/dev/' //dev and prod
let slowQueryEndPoint			 = 'https://toyuffreoh.execute-api.us-east-2.amazonaws.com/dev/'
let rechabilityEndPoint          = 'https://0m9xdcnx00.execute-api.us-east-1.amazonaws.com/dev/' //dev and prod

// let observabilityAnomalyEndPoint = 'https://5nahv2pbdg.execute-api.us-east-1.amazonaws.com/dev/'

let observabilityAnomalyEndPoint = 'https://jmm0betc0g.execute-api.us-east-2.amazonaws.com/dev/'

let observabilityEventsEndPoint  = 'https://syu1phzkpi.execute-api.us-east-2.amazonaws.com/dev/'

let observabilityQueryEndPoint 	 = 'https://e9dzz1ozwa.execute-api.us-east-2.amazonaws.com/dev/'

let causalCorrelationEndPoint  	 = 'https://fsr5n8lzne.execute-api.us-east-2.amazonaws.com/dev/'

let operationalMetricsEndPoint   = 'https://vf6hs6qi1i.execute-api.us-east-2.amazonaws.com/dev'

let customerOpertaionsEndPoint   = 'https://xeqqzucvq6.execute-api.us-east-2.amazonaws.com/dev'

let issueDumpEndPoint 			 = 'https://19ip1vb1t9.execute-api.us-east-2.amazonaws.com/dev'
//let issueDumpEndPoint 	     	 = 'https://jh8jpsdio9.execute-api.us-east-2.amazonaws.com/dev'

let scheduleEndPoint 			 = 'https://ok1gfxqmci.execute-api.us-east-2.amazonaws.com/dev'


if(isDev) {
	synchronizerEndPoint 		= 'https://ms4c1sh8ah.execute-api.us-east-2.amazonaws.com/dev'
	detectoreEndPoint           = 'https://cc621f5412.execute-api.us-east-2.amazonaws.com/dev'
	userServiceEndPoint			= 'https://17fqu2fvo7.execute-api.us-east-2.amazonaws.com/cs'
	commonServicesEndPoint		= 'https://pxu06yeiz3.execute-api.us-east-2.amazonaws.com/cs'	
	// governanceEndPoint			= 'https://4g4qkadiw3.execute-api.us-east-2.amazonaws.com/dev'
	governanceEndPoint			= 'https://accfo06mef.execute-api.us-east-2.amazonaws.com/dev'
	governanceNewEndPoint		= 'https://hxz9yjc43b.execute-api.us-east-2.amazonaws.com/dev'
	iamGovernanceEndPoint		= 'https://0xzt2to4il.execute-api.us-east-2.amazonaws.com/dev'
	monitoringEndPoint			= 'https://mwf3b9nkd3.execute-api.us-east-2.amazonaws.com/dev/'
	billingEndPoint				= 'https://fr2vpnj1s6.execute-api.us-east-2.amazonaws.com/dev'
	pricingEndPoint				= 'https://tbb57spzia.execute-api.us-east-2.amazonaws.com/cloudpricingspec'
	scoreEndpoint				= 'https://0p72hzhak6.execute-api.us-east-2.amazonaws.com/score/'
	aiopsEndPoint 				= 'https://i63b3e4vmj.execute-api.us-east-2.amazonaws.com/dev/'
	costEndPoint 				= 'https://7979wbx6h9.execute-api.us-east-2.amazonaws.com/dev/'
	aiopsDiagnosticsEndPoint 	= 'https://44ppjjt6e0.execute-api.us-east-2.amazonaws.com/dev/'
	aiopsSreEndPoint			= 'https://dud88fvuc3.execute-api.us-east-2.amazonaws.com/dev/'
	aiopsAutomationEndPoint		= 'https://dh7wnxq785.execute-api.us-east-2.amazonaws.com/dev'	
	remediationEndPoint			= 'https://0j7cnkk696.execute-api.us-east-2.amazonaws.com/dev/'
	// profileEndPoint 			= 'https://vherog3b33.execute-api.us-east-2.amazonaws.com/dev/'
	// profileEndPoint				= 'https://h4jnrazsq1.execute-api.us-east-2.amazonaws.com/dev/'
	profileEndPoint				= 'https://3b775m6q4d.execute-api.us-east-2.amazonaws.com/dev/'
	topologyEndPoint			= 'https://jf5u5nw4nb.execute-api.us-east-2.amazonaws.com/dev/'
	microServiceTopolgyEndPoint = 'https://xd8t6ri0mh.execute-api.us-east-2.amazonaws.com/dev/'
	taggingEndPoint 			= 'https://b42iyzd1c7.execute-api.us-east-2.amazonaws.com/dev'
	configurationEndPoint 		= 'https://s5tqvnz50a.execute-api.us-east-2.amazonaws.com/dev'
	suppressorEndPoint			= 'https://bk0aotl5lh.execute-api.us-east-2.amazonaws.com/dev'
	
	//need to get form backend
	userInsightsEndPoint       	= 'https://userinsights.beta.tvastr.ai' //no dev api
	maintenanceEndPoint			= 'https://9paurgre45.execute-api.us-east-2.amazonaws.com/dev' //no dev api//not used
	tagGroupsEndPoint			= 'https://79vgse789b.execute-api.us-east-2.amazonaws.com/dev' //no dev api//not used
	
	userSignupEndPoint         	= 'https://4t610991ch.execute-api.us-east-2.amazonaws.com/dev' //no dev api//not there in 312(signup,addaccount)

	//need to create cognitoServices
	kpiServicesEndPoint			= 'https://kxjfnbrqzc.execute-api.us-east-2.amazonaws.com/dev'
	healthStatusEndPoint        = 'https://pgeb5o851i.execute-api.us-east-2.amazonaws.com/status'//not used in ui
	idleAssetsEndPoint			= 'https://h5airm21h1.execute-api.us-east-2.amazonaws.com/dev'//not used in ui
	monitoringSnapshotEndPoint  = 'https://52cmzigs2k.execute-api.us-east-2.amazonaws.com/dev'//not deployed

	dlpEndPoint					 = 'https://cx1omlesth.execute-api.us-east-1.amazonaws.com/dev'//not deployed
	dlpEngineEndPoint			 = 'https://t4yb1teix8.execute-api.us-east-1.amazonaws.com/dev/'//not deployed
	dlpThreateEndPoint			 = 'https://flodx5rcg5.execute-api.us-east-1.amazonaws.com/dev/'//not deployed
	dlpPolicyEndPoint			 = 'https://flodx5rcg5.execute-api.us-east-1.amazonaws.com/dev/'//not deployed

	errorEndPoint				 = 'https://zqi0gg1d7f.execute-api.us-east-2.amazonaws.com/dev/'
	slowQueryEndPoint			 = 'https://i90lfy4hga.execute-api.us-east-2.amazonaws.com/dev/'
	rechabilityEndPoint          = 'https://eqla1gy4c6.execute-api.us-east-2.amazonaws.com/dev/'

	observabilityAnomalyEndPoint = 'https://bhcn9ruu2c.execute-api.us-east-2.amazonaws.com/dev/'
	observabilityEventsEndPoint  = 'https://jnlhmkdt67.execute-api.us-east-2.amazonaws.com/dev/'

	observabilityQueryEndPoint 	 = 'https://zaizucgebi.execute-api.us-east-2.amazonaws.com/dev/'

	causalCorrelationEndPoint  	 = 'https://ipu0chigmc.execute-api.us-east-2.amazonaws.com/dev/' 

	operationalMetricsEndPoint   = 'https://mwf3b9nkd3.execute-api.us-east-2.amazonaws.com/dev'
	
	customerOpertaionsEndPoint   = 'https://fzutmigq3c.execute-api.us-east-2.amazonaws.com/dev'

	issueDumpEndPoint 			 = 'https://e3x0i2u792.execute-api.us-east-2.amazonaws.com/dev'
	scheduleEndPoint 			 = 'https://fg3syw3jrf.execute-api.us-east-2.amazonaws.com/dev'
}


export const SYNCHRONIZER_END_POINT 	= synchronizerEndPoint
export const DETECTORS_END_POINT 		= detectoreEndPoint
export const USER_SERVICES_END_POINT 	= userServiceEndPoint
export const COMMON_SERVICES_END_POINT  = commonServicesEndPoint
export const GOVERNANCE_END_POINT  		= governanceEndPoint
export const GOVERNANCE_NEW_END_POINT  	= governanceNewEndPoint
export const MONITORING_END_POINT  		= monitoringEndPoint
export const BILLING_END_POINT			= billingEndPoint
export const PRICING_END_POINT			= pricingEndPoint
export const SCORE_END_POINT			= scoreEndpoint
export const AIOPS_END_POINT			= aiopsEndPoint
export const COST_END_POINT 			= costEndPoint
export const AIOPS_DIAGNOSTICS_END_POINT = aiopsDiagnosticsEndPoint
export const AIOPS_SRE_END_POINT 		= aiopsSreEndPoint
export const AIOPS_AUTOMATION_END_POINT = aiopsAutomationEndPoint
export const REMEDIATION_END_POINT 		= remediationEndPoint
export const PROFILE_END_POINT 			= profileEndPoint
export const TOPOLOGY_END_POINT 		= topologyEndPoint
export const MICROSERVICE_TOPOLOGY_END_POINT 	= microServiceTopolgyEndPoint
export const TAGGING_END_POINT 			= taggingEndPoint
export const CONFIGURATION_END_POINT 	= configurationEndPoint
export const SUPPRESSOR_END_POINT 		= suppressorEndPoint

export const USER_INSIGHTS_END_POINT 	= userInsightsEndPoint
export const IAM_GOVERNANCE_END_POINT 	= iamGovernanceEndPoint
export const MAINTENANCE_END_POINT 		= maintenanceEndPoint
export const TAG_GROUPS_END_POINT 		= tagGroupsEndPoint
export const USER_SIGNUP_END_POINT 		= userSignupEndPoint


export const KPI_SERVICES_END_POINT  	= kpiServicesEndPoint
export const HEALTH_STATUS_END_POINT 	= healthStatusEndPoint
export const IDEL_ASSETS_END_POINT 		= idleAssetsEndPoint
export const MONITORING_SNAPSHOT_END_POINT = monitoringSnapshotEndPoint

export const DLP_END_POINT 				= dlpEndPoint
export const DLP_ENGINE_END_POINT		= dlpEngineEndPoint
export const DLP_THREAT_END_POINT 		= dlpThreateEndPoint
export const DLP_POLICY_END_POINT 		= dlpPolicyEndPoint

export const ERROR_END_POINT			= errorEndPoint
export const SLOW_QUERY_END_POINT 		= slowQueryEndPoint
export const RECHABILITY_END_POINT 		= rechabilityEndPoint

export const OBSERVABILITY_ANOMALY_END_POINT = observabilityAnomalyEndPoint
export const OBSERVABILITY_EVENTS_END_POINT = observabilityEventsEndPoint

export const OBSERVABILITY_QUERY_END_POINT = observabilityQueryEndPoint

export const CAUSAL_CORRELATION_END_POINT = causalCorrelationEndPoint

export const OPERATIONAL_METRICS_END_POINT = operationalMetricsEndPoint
export const CUSTOMER_OPERATIONS_END_POINT = customerOpertaionsEndPoint

export const ISSUE_DUMP_END_POINT = issueDumpEndPoint
export const SCHEDULE_END_POINT   = scheduleEndPoint



export const SYNCHRONIZER = 'synchronizer'
export const DETECTORS = 'detectors'
export const USER_SERVICES = 'userServices'
export const COMMON_SERVICES = 'otherServices'
export const GOVERNANCE = 'governance'
export const GOVERNANCE_NEW = 'governanceNew'
export const MONITORING = 'monitoring'
export const BILLING = 'billing'
export const PRICING = 'pricing'
export const SCORE = 'score'
export const AIOPS_SERVICE = 'aiopsEndPoint'
export const COST_SERVICE = 'costEndPoint'
export const AIOPS_DIAGNOSTICS = 'aiopsDiagnosticsEndPoint'
export const AIOPS_SRE = 'aiopsSreEndPoint'
export const AIOPS_AUTOMATION = 'aiopsAutomationEndPoint'
export const REMEDIATION = 'remediationEndPoint'
export const PROFILE = 'profileEndPoint'
export const TOPOLOGY = 'topologyEndPoint'
export const MICROSERVICE_TOPOLOGY = 'microServiceTopolgyEndPoint'
export const TAGGING = 'taggingEndPoint'
export const CONFIGURATION = 'configurationEndPoint'
export const SUPPRESSOR = 'suppressorEndPoint'

export const USER_INSIGHTS = 'userInsightsEndPoint'
export const IAM_GOVERNANCE = 'iamGovernanceEndPoint'
export const MAINTENANCE = 'maintenanceEndPoint'
export const TAG_GROUPS = 'tagGroupsEndPoint'
export const USER_SIGNUP = 'userSignupEndPoint'

export const KPI_SERVICES = 'kpiServices'
export const HEALTH_STATUS = 'healthStatusEndPoint'
export const IDEL_ASSETS = 'idleAssetsEndPoint'
export const MONITORING_SNAPSHOT = 'monitoringSnapshotEndPoint'

export const DLPENDPOINT = 'dlpEndPoint'
export const DLPENGINEENDPOINT = 'dlpEndEnginePoint'
export const DLPTHREATENDPOINT = 'dlpThreatEndPoint'
export const DLPPOLICYENDPOINT = 'dlpPolicyEndPoint'

export const ERRORENDPOINT = 'errorEndPoint'
export const SLOWQUERYENDPOINT = 'slowQueryEndPoint'
export const RECHABILITYENDPOINT = 'rechabilityEndPoint'
export const OBSERVABILITYANOMALYENDPOINT = 'observabilityAnomalyEndPoint'
export const OBSERVABILITYEVENTSENDPOINT = 'observabilityEventsEndPoint'
export const OBSERVABILITYQUERYENDPOINT = 'observabilityQueryEndPoint'
export const CAUSALCORRELATIONENDPOINT = 'causalCorrelationEndPoint'
export const OPERATIONALMETRICSENDPOINT = 'operationalMetricsEndPoint'
export const CUSTOMEROPERATIONSENDPOINT = 'customerOpertaionsEndPoint'

export const ISSUEDUMP = 'issueDump'
export const SCHEDULEENDPOINT = 'scheduleEndPoint'


//APis

//synchronizerEndPoint start
export const GET_ASSET_DETAIL_EXT = '/getAssetDetailExt' //remediationAction and assetAction
export const GET_ASSET_DETAIL = '/getAssetDetail' //detectorsAction	
export const GET_TOP_EVENTS = '/getTopAssetEvents' //detectorsAction	
export const GET_RECENT_EVENTS = '/getAssetEvents' //detectorsAction	
export const GET_EVENTS_GRAPH_COUNT = '/getAssetEventDailyCount' //detectorsAction	
export const GET_DAILY_ASSET_AVAILABILITY = '/getDailyAssetAvailability' //detectorsAction	
export const GET_EVENT_INFO = '/getEventInfo' //detectorsAction	
export const GET_EVENT_BY_BOUNDARY = '/getEventsByBoundary' //detectorsAction	

export const GET_RESOURCE_SEARCH_META_RESULTS = '/getSearchMetaResults' //commonAction
export const GET_TOTAL_ASSET_COUNT = '/getTotalAssetCount' //comparisonAction and assetBoardAction and assetAction	
export const GET_DAILY_ACTIVE_ASSET_COUNT = '/getDailyActiveAssetCount' //comparisonAction
export const GET_ASSET_COUNT_BY_PIRCING_MODEL = '/getAssetCountByPricingModel' //comparisonAction

export const GET_ASSET_BY_SERVICE = '/getAssetByService' //assetBoardAction
export const GET_ASSET_BY_SERVICE_DETAILS = '/getAssetByServiceDetails' //assetBoardAction
export const GET_ASSET_BY_CATEGORY = '/getAssetByCategory' //assetBoardAction
export const GET_ASSET_BY_CATEGORY_DETAILS = '/getAssetByCategoryDetails' //assetBoardAction
export const GET_RESOURCE_CHART = '/getResourceChart' //assetBoardAction
export const GET_PURCHASE_DETAILS = '/getPurchaseDetails' //assetBoardAction
export const GET_PURCHASE_BY_INSTANACE_TYPE = '/getPurchaseByInstanceType' //assetBoardAction
export const GET_OVERALL_ASSET_COUNT = '/getOverAllAssetCount' //assetBoardAction

export const LIST_ASSETS = '/listAssets' //assetAction and governanceAction and IamAction
export const LIST_COVERED_ASSETS = '/list-covered-assets' //detectorsAction
export const GET_COVERED_SERVICES = '/get-covered-services' //detectorsAction
export const GET_AGGREGATED_ACCOUNTS_BY_REGION = '/get-aggregated-accounts-by-region' //detectorsAction

export const GET_ADVANCED_SEARCH_LIST = '/getAdvancedSearchResults' //assetAction
export const GET_DAILY_ASSET_COUNT = '/getDailyAssetCount' //assetAction
export const GET_ASSET_NETWORK_DETAILS = '/getAssetNetworkDetails' //assetAction
export const GET_ASSET_SG_DETAILS = '/getAssetSGDetails' //assetAction
export const GET_ASSET_STORAGE_DETAILS = '/getAssetStorageDetails' //assetAction

export const GET_AGGREGATED_IDENTIFIER_RESULTS = '/get-aggregated-identifier-results' //detectorsAction	
export const GET_SERVICE_QUOTA_DETAILS = '/get-service-quota-details' //detectorsAction	

export const GET_VARIOUS_COUNTS_BY_ACCOUNT = '/get-various-counts-by-account' //detectorsAction	

//synchronizerEndPoint end

//detectoreEndPoint start
export const GET_SEARCH_META_RESULTS = '/get-search-meta-results' //commonAction
export const SET_FAVOURITE_QUERY = '/set-favourite-query' //commonAction
export const UNSET_FAVOURITE_QUERY = '/unset-favourite-query' //commonAction
export const GET_DAY_WISE_COUNT_TAGS = '/get-day-wise-count-tags' //checksDashboardAction
export const GET_BILL_DETAILS = '/get-bill-details' //checksDashboardAction
export const GET_ISSUE_STATUS_COUNT = '/get-issue-status-count' //checksDashboardAction
export const GET_STATUS_COUNT = '/get-status-count' //checksDashboardAction
export const GET_COUNT = '/get-count' //checksDashboardAction
export const GET_COST_TREND ='/get-cost-trend' //checksDashboardAction


export const GET_COMPLIANCE_BY_EVENT = '/get-compliance-by-event' //complianceDashboardAction
export const GET_COMPLIANCE_BY_ASSET = '/get-compliance-by-asset' //complianceDashboardAction
export const GET_COMPLIANCE_BY_SERVICE_COUNT = '/get-compliance-by-service-count' //complianceDashboardAction
export const GET_COMPLIANCE_BY_ROLE = '/get-compliance-by-role' //complianceDashboardAction
export const GET_COMPLIANCE_CONTROL_DETAILS = '/get-compliance-control-details' //complianceDashboardAction
export const GET_COMPLIANCE_SUB_CONTROL_DETAILS = '/get-compliance-sub-control-details' //complianceDashboardAction
export const GET_COMPLIANCE_MINOR_CONTROL_DETAILS = '/get-compliance-minor-control-details' //complianceDashboardAction
export const GET_RISK_WISE_COMPLEXITY = '/get-risk-wise-complexity' //complianceDashboardAction
export const GET_PASS_FAIL_DETAILS ='/get-pass-fail-details' //complianceDashboardAction
export const GET_OBSERVATIONS ='/get-observations' //complianceDashboardAction
export const GET_RISK_BY_ROLE ='/get-risk-by-role' //complianceDashboardAction
export const GET_COMPLIANCE_BY_TAG ='/get-compliance-by-tag' //complianceDashboardAction
export const GET_TRANSITION_TREND ='/get-transition-trend' //complianceDashboardAction
export const GET_DAY_WISE_ASSET_COUNT ='/get-day-wise-asset-count' //complianceDashboardAction

export const GET_IDENTIFIERS = '/get-identifiers' //configurationAction
export const GET_IDENTIFIERS_TOTAL_COUNT = '/get-identifiers-total-count' //configurationAction

export const GET_TOTAL_COUNT = '/get-total-count' //detectorAction
export const GET_DAY_WISE_COUNT = '/get-day-wise-count' //detectorAction
export const GET_ALL_IDENTIFIERS_RESULTS = '/get-identifier-results' //detectorAction
export const GET_LATEST_IDENTIFIER_RESULTS = '/get-latest-identifier-results' //detectorAction
export const RESOLVE_ISSUE ='/resolve-issue' //detectorAction and dlpAction
export const GET_NON_COMPLAINT_ASSETS = '/get-non-compliant-assets' //governanceAction and taggingAction

export const GET_IDENTIFIER_RESULTS ='/get-identifier-results' //userinsightsAction
export const GET_RELATED_IDENTIFIER_RESULTS ='/get-related-identifier-results' //userinsightsAction
export const GET_USER_RELATED_ISSUES ='/get-user-related-issues' //userinsightsAction

export const GET_COMPLIANCE_COVERAGE ='/get-compliance-coverage' //userinsightsAction

//detectoreEndPoint end


//userServiceEndPoint start

export const LIST_PROVIDERS = '/list-user-providers' //commonAction
export const LIST_REGIONS = '/list-user-regions' //commonAction
export const LIST_SERVICES = '/list-user-services' //commonAction
export const LIST_ACCOUNTS = '/list-user-accounts' //commonAction
export const LIST_CATEGORIES = '/list-user-categories' //commonAction
export const GET_USER_ORGANIZATION = '/get-user-organization' //commonAction
export const LIST_EVENT_SOURCES = '/list-event-sources' //commonAction
export const CAPTURE_UI_ERRORS = '/capture-ui-errors'  //commonAction

export const AUTOMATION_TRIGGER_LIST_EVENTS = '/list-events' // Automation Action


//userServiceEndPoint end

//commonServicesEndPoint start

export const LIST_ORGANIZATIONS = '/list-organizations' //commonAction.js
export const LIST_MODULES = '/list-modules' //commonAction.js
export const LIST_ACCOUNTS_DETAILS = '/list-accounts' //commonAction.js
export const GET_VM_TYPES = '/get-vm-types'

export const LIST_LOG_TYPE = '/list-log-type' //governanceAction
export const LIST_STORAGE_TYPES = '/list-storage-types' //governanceAction
export const LIST_SERVICE_ENGINES = '/list-service-engines' //governanceAction
export const LIST_OPERATING_SYSTEM = '/list-operating-system' //governanceAction
export const LIST_EVENTS = '/list-events' //governanceAction

//commonServicesEndPoint end


//governanceEndPoint start

export const GOVERNANCE_RULES_LIST = '/list-rules' //governanceAction
export const GOVERNANCE_CLONED_RULES_LIST = '/list-cloned-rules' //governanceAction
export const GOVERNANCE_GET_RULE_COUNT = '/get-rule-count' //governanceAction
export const CLONE_RULE = '/clone-rule' //governanceAction
export const UPDATE_CLONED_RULE = '/update-cloned-rule' //governanceAction
export const VALIDATE_IMAGE = '/validate-image' //governanceAction
export const VALIDATE_REGISTRY_CONTAINER = '/validate-registry-image' //governanceAction
export const DELETE_CLONED_RULE = '/delete-cloned-rule' //governanceAction
export const LIST_GOVERNANCE_CATEGORIES = '/list-governance-categories' //governanceAction
export const GET_GOVERNANCE_MASTER_RULE = '/get-governance-master-rule' //governanceAction
export const CREATE_USER_RULE = '/create-user-rule' //governanceAction
export const UPDATE_USER_RULE = '/update-user-rule' //governanceAction
export const LIST_USER_GOVERNANCE_CATEGORIES = '/list-user-governance-categories' //governanceAction
export const LIST_USER_RULES = '/list-user-rules' //governanceAction
export const GET_GOVERNANCE_USER_RULES = '/get-governance-user-rule' //governanceAction
export const UPDATE_STATUS_RULE = '/update-status-rule' //governanceAction
export const DELETE_USER_RULE = '/delete-user-rule' //governanceAction

export const LIST_IAM_CONDITIONS = '/list-iam-conditions' //IamAction


export const GOVERNANCE_LIST_CATEGORIES = '/list-categories'   //not user

//governanceEndPoint end


//governanceNewEndPoint start

export const LIST_GOVERNANCE_POLICIES = '/list-governance-policies' //newGovernanceAction
export const GET_MASTER_GOVERNANCE_RULES = '/get-master-governance-rules' //newGovernanceAction
export const GET_POLICY_DETAILS = '/get-policy-details' //newGovernanceAction
export const CREATE_GOVERNANCE_POLICY = '/create-governance-policy' //newGovernanceAction
export const EDIT_GOVERNANCE_POLICY = '/edit-governance-policy' //newGovernanceAction

export const NEW_GOVERNANCE_GET_TOTAL_COUNT = '/get-total-count' //newGovernanceAction
export const NEW_GOVERNANCE_GET_DAY_WISE_COUNT = '/get-day-wise-count' //newGovernanceAction
export const NEW_GOVERNANCE_GET_TRANSITION_TREND = '/get-transition-trend' //newGovernanceAction
export const GET_GOVERNANCE_VIOLATIONS = '/get-governance-violations' //newGovernanceAction
export const NEW_GOVERNANCE_GENERATE_IAM_POLICY = '/generate-iam-policy' //newGovernanceAction

export const NEW_GOVERNANCE_GET_GOVERNANCE_SERVICES = '/get-governance-services' //newGovernanceAction

export const NEW_GOVERNANCE_GET_GOV_META_DATA = '/get-gov-meta-data' //newGovernanceAction

export const NEW_GOVERNANCE_LIST_GOVERNANCE_RESULTS = '/list-goveranance-results' //newGovernanceAction


//governanceNewEndPoint end


//monitoringEndPoint start

export const GET_MONITORING_SNAPSHOT = 'monitoringproxysnapshot/getMonitoringSnapshot' //detectorAction
export const GET_MONITORING_METRICS_BY_TREND = 'get-monitoring-metrics-by-trend' //metricsAction
export const GET_MONITORING_METRICS_BY_TREND_V2 = 'get-monitoring-metrics-by-trend-v2' //metricsAction

//monitoringEndPoint end

//billingEndPoint start

export const GET_TOTAL_BILLING = '/get-total-billing' //comparisonAction and assetBoardAction
export const GET_BILLING_SNAPSHOT = '/get-asset-billing-snapshot' //detectorAction

//billingEndPoint end

//pricingEndPoint start

export const PRICING_LIST_VMS = '/cloudpricingspec/database/list/listvms' //remediationAction
export const LISTVMS = '/compute/list/listvms' //governanceAction

//pricingEndPoint end

//scoreEndPoint start

export const GET_SCORE_DETAILS = 'get-score-details' //comparisonAction
export const GET_SCORE = 'get-score' //checksDashboardAction
export const GET_DAILY_SCORE ='get-daily-score' //checksDashboardAction

//scoreEndPoint end


//aiopsEndPoint start

export const CLOSE_CASE = 'close-case' //AiopsAction
export const LIST_CASES = 'list-cases' //AiopsAction
export const LIST_CASES_WITH_DETAILS = 'list-cases-with-details' //AiopsAction
export const GET_AIOPS_COVERED_RESOURCES = 'get-aiops-covered-resources' //AiopsAction
export const LIST_AIOPS_ALERTS = 'list-alerts' //AiopsAction
export const LIST_AIOPS_ASSETS = 'list-assets' //AiopsAction
export const GET_CASE_DETAILS = 'get-case-details' //AiopsAction
export const GET_CASES_TOTAL_COUNT = 'get-cases-total-count' //AiopsAction
export const GET_AIOPS_DAILY_TREND = 'get-aiops-daily-trend' //AiopsAction
export const AIOPS_CASE_UPDATE_CASE_DETAILS = 'update-case-details' // AiopsAction
export const AIOPS_GET_ALERT_TREND_BY_ASSET = 'get-alert-trend-by-asset' //AiopsAction

export const AIOPS_ALERTS_LIST_ALERT_GROUPS = 'list-alert-groups' //alertAction
export const AIOPS_ALERTS_GET_ALERT_GROUPS_DAILY_TREND = 'get-alert-groups-daily-trend' //alertAction
export const AIOPS_ALERTS_GET_ALERT_REDUCTION_STATS = 'get-alert-reduction-stats' //alertAction

export const LIST_ALERTS_BY_TYPE = 'list-alerts-bytype' //distributionAction
export const LIST_ALERTS_BY_ASSETS = 'list-alerts-by-assets' //distributionAction

export const AIOPS_EVENT_GET_EVENTS_TOTAL_COUNT = 'get-events-total-count' //eventAction 
export const AIOPS_EVENT_GET_IMPORTANT_EVENTS_DAILY_TREND = 'get-important-events-daily-trend' //eventAction
export const LIST_AIOPS_EVENTS = 'list-events' //eventAction
export const GET_EVENTS_DAILY_TREND = 'get-events-daily-trend' //eventAction
export const GET_EVENTS_DETAILS = 'get-event-details' //eventAction
export const LIST_EVENTS_BY_ASSETS = 'list-events-by-assets' //eventAction

export const GET_ALERTS_DAILY_TREND = 'get-alerts-daily-trend' //metricsAction
export const GET_TOTAL_ALERTS_COUNT = 'get-total-alerts-count'  //metricsAction
export const GET_AIOPS_DAILY_TREND_BY_ASSETS = 'get-aiops-daily-trend-by-assets' //metricsAction


export const GET_AIOPS_REMEDIATION_TREND = 'get-aiops-remediation-trend' //RemediationAction

export const LIST_RCA_DETAILS = 'list-rca-details' //RootCauseAction
export const GET_RCA_DETAILS = 'get-rca-details' //RootCauseAction

export const OBSERVABILITY_GET_ALERTS_DAILY_TREND = 'observability/get-alerts-daily-trend' //observabilityAction
export const OBSERVABILITY_GET_ALERTS_GROUP_DAILY_TREND = 'observability/get-alert-groups-daily-trend' //observabilityAction
export const OBSERVABILITY_LIST_ALERT_GROUPS = 'observability/list-alert-groups' //observabilityAction
export const OBSERVABILITY_GET_IMPORTANT_EVENTS_DAILY_TREND = 'observability/get-important-events-daily-trend' //observabilityAction
export const OBSERVABILITY_LIST_ALERTS = 'observability/list-alerts' //observabilityAction
export const OBSERVABILITY_LIST_EVENTS = 'observability/list-events' //observabilityAction
export const OBSERVABILITY_GET_TOP_ALERTS = 'observability/get-top-alerts' //observabilityAction
export const OBSERVABILITY_GET_ALERTS_TOTAL_COUNT = 'observability/get-alerts-total-count' //observabilityAction

export const OBSERVABILITY_GET_EVENTS_DAILY_TREND = 'observability/get-events-daily-trend' //observabilityAction
export const OBSERVABILITY_GET_TOP_EVENTS = 'observability/get-top-events' //observabilityAction
export const OBSERVABILITY_LIST_EVENT_GROUPS = 'observability/list-event-groups' //observabilityAction
export const OBSERVABILITY_GET_EVENTS_TOTAL_COUNT = 'observability/get-events-total-count' //observabilityAction

export const OBSERVABILITY_GET_CHANGE_EVENTS = 'observability/get-change-events' //observabilityAction
export const OBSERVABILITY_LIST_CHANGE_EVENTS = 'observability/list-change-events' //observabilityAction

export const OBSERVABILITY_ANOMALY_GET_DAILY_COUNT = 'get-daily-count' //observabilityAction
export const OBSERVABILITY_ANOMALY_GET_TOTAL_COUNT = 'get-total-count' //observabilityAction
export const OBSERVABILITY_ANOMALY_GET_TOP_METRIC_ANOMALIES = 'get-top-metric-anomalies' //observabilityAction



export const OBSERVABILITY_ANOMALY_LIST_ANOMALY_RESULTS = 'list-anomaly-results' //observabilityAction
export const OBSERVABILITY_ANOMALY_GET_ANOMALY_DAILY_TREND = 'get-anomaly-daily-trend' //observabilityAction
export const OBSERVABILITY_ANOMALY_GET_TOP_ANOMALIES = 'get-top-anomalies' //observabilityAction
export const GET_ANOMALY_TOTAL_COUNT = 'get-anomaly-total-count' //observabilityAction - costAction


export const OBSERVABILITY_EVENTS_LIST_EVENT_GROUPS = 'observability/list-event-groups' //observabilityAction


export const GET_PEAK_DIP_DETECTION_RESULTS = 'get-peak-dip-detection-results' //aiops/DiagnosticsAction
export const GET_EVENTS_BY_IMPACT = 'get-events-by-impact' //assetsAction
export const GET_AGGREGATED_EVENTS = 'get-aggregated-events' //assetsAction

export const GET_CHANGE_IMPACT_ANALYSIS_RESULTS = 'get-change-impact-analysis-results' //aiopsAction

export const LIST_ALARMS = 'list-alarms' //resourcesAction
export const CREATE_ALARM = 'create-alarm' //resourcesAction

//aiopsEndPoint end

//costEndPoint start

export const GET_COST_RECOMMENDATIONS = 'get-cost-recommendations' //costAction
export const START_POWER_TUNING = 'start-power-tuning' //costAction
export const GET_POWER_TUNING_ASSETS = 'get-power-tuning-assets' //costAction
export const GET_POWER_TUNING_RESULTS = 'get-power-tuning-results' //costAction
export const GET_COST_FILTERS = 'get-cost-filters' //costAction
export const GET_COST_FILTER_VALUES = 'get-cost-filter-values' //costAction
export const GET_COST_TOTAL_COUNT = 'get-cost-total-count' //costAction
export const LIST_COST_TEMPLATE = 'list-cost-templates' //costAction
export const SAVE_TEMPLATE = 'save-template' //costAction
export const COST_GET_COST_TREND = 'get-cost-trend' //costAction

export const GET_COST_QUERIES = 'get-cost-queries' //costQueryAction
export const GET_COST_QUERY_RESULTS = 'get-cost-query-results' //costQueryAction

// export const GET_ANOMALY_TOTAL_COUNT = 'get-anomaly-total-count' //costAction
export const GET_ANOMALY_DAILY_TREND = 'get-anomaly-daily-trend' //costAction
export const LIST_ANOMALY_RESULTS = 'list-anomaly-results' //costAction
export const GET_TOP_ANOMALIES = 'get-top-anomalies' //costAction

export const COST_COMPARISON = 'cost-comparison' //costAction
export const GET_BUDGET_DETAILS = 'get-budget-details' //costAction
export const GET_BUDGET_FILTERS = 'get-budget-filters' //costAction 
export const INSERT_BUDGET_DETAILS = 'insert-budget-details' //costAction
export const UPDATE_BUDGET_DETAILS = 'update-budget-details' //costAction 
export const GET_COST_ALL_TAGS_KEYS = 'get-all-tags-keys' //commonAction
export const GET_COST_ALL_TAGS_VALUES = 'get-all-tags-values' //commonAction
export const LIST_BUDGET_DETAILS = 'list-budget-details' //commonAction

//costEndPoint end

//aiopsDiagnosticsEndPoint end

export const AIOPS_DIAGNOSTICS_LIST_DIAGNOSTICS = 'list-diagnostics'  // aiops diagnosticsActions
export const AIOPS_DIAGNOSTICS_GET_DIAGNOSTIC_DETAILS = 'get-diagnostic-details'// aiops diagnosticsActions
export const AIOPS_DIAGNOSTICS_START_DIAGNOSTICS = 'start-diagnostics'// aiops diagnosticsActions 
export const AIOPS_DIAGNOSTICS_LIST_DIAGNOSTIC_CHECKS = 'list-diagnostic-checks'// aiops diagnosticsActions

export const AIOPS_DIAGNOSTICS_LIST_DIAGNOSTIC_TEMPLATE = 'list-diagnostic-templates'  // aiops diagnosticsActions
export const AIOPS_DIAGNOSTICS_SAVE_DIAGNOSTIC_TEMPLATE = 'save-diagnostic-template'  // aiops diagnosticsActions


export const AIOPS_DIAGNOSTICS_GET_TROUBLSHOOT_DETAILS = 'get-troubleshoot-details'  // aiops diagnosticsActions



//aiopsDiagnosticsEndPoint end

//aiopsSreEndPoint end
export const AIOPS_DIAGNOSTICS_GET_SRE_DETAILS = 'get-sre-metrics' //AIOPS_ANALYSIS_DIAGNOSTICS_SRE_END_POINT
//aiopsSreEndPoint end


//aiopsAutomationEndPoint start
export const AIOPS_AUTOMATION_GET_APIS = '/get-apis' // Automation Action
export const AIOPS_AUTOMATION_GET_DAILY_COUNT = '/get-daily-count' // Automation Action
export const AIOPS_AUTOMATION_GET_TOP_RESULTS = '/get-top-results' // Automation Action  //not used
export const AIOPS_AUTOMATION_GET_TOTAL_COUNT = '/get-total-count' // Automation Action

export const LIST_AUTOMATION_ACTIONS = '/list-automation-actions' // Automation Action
export const CREATE_AUTOMATION = '/create-automation' // Automation Action
export const EDIT_AUTOMATION = '/edit-automation' // Automation Action
export const LIST_AUTOMATION_PLANS = '/list-automation-plans' // Automation Action
export const LIST_PREBUILD_AUTOMATION_PLANS = '/list-prebuilt-automation-plans' // Automation Action
export const LIST_AUTOMATION_EXECUTIONS = '/list-automation-executions' // Automation Action
export const GET_AUTOMATION_EXECUTION = '/get-automation-execution' // Automation Action
export const GET_AUTOMATION_EXECUTION_DAILY_TREND = '/get-automation-execution-daily-trend' // Automation Action

export const VALIDATE_GROK_PATTERN = '/utils/validate-grok-pattern' // Automation Action

export const ENDISABLE_AUTOMATION = '/endisable-automation' // Automation Action
export const VIEW_AUTOMATION = '/view-automation' // Automation Action

export const START_AUTOMATION = '/start-automation' // Automation Action

//aiopsAutomationEndPoint end

//remediationEndPoint start

export const GET_AIOPS_REMEDIATION_ESTIMATE = 'get-aiops-remediation-estimate' //aiopsAction

export const GET_REMEDIATION_COUNT = 'get-remediation-count' //remediationAction
export const GET_REMEDIATION_STATUS = 'get-remediation-status' //remediationAction
export const GET_REMEDIATION_DETAILS = 'get-remediation-details' //remediationAction
export const GET_ARTIFACT_DETAILS = 'get-artifact-details' //remediationAction
export const LIST_REMEDIATIONS = 'list-remediations' //remediationAction
export const START_REMEDIATIONS = 'start-remediation' //remediationAction
export const GET_TOTAL_REMEDIATION_COUNT = 'get-total-remediation-count' //remediationAction

//remediationEndPoint end



//profileEndPoint start

export const GET_USER_PROFILE = 'get-user-profile'
export const GET_USER_MENU = 'get-user-menu'

//profileEndPoint end


//topologyEndPoint start

export const GET_NODE_BY_DEGREE = 'get-nodes-by-degree'
export const GET_TOPOLOGY = 'get-topology'

//topologyEndPoint start

//microServiceTopolgyEndPoint start
export const GET_GRAPH_SUMMARY = 'get-graph-summary'
export const GET_COVERED_SUMMARY = 'get-covered-summary'
export const GET_COVERED_TRENDS_SUMMARY = 'get-covered-trends-summary'
export const GET_COVERED_LISTS_SUMMARY = 'get-covered-lists-summary'
// export const MICROSERVICES_GET_TOPOLOGY = 'microservice/get-topology'

export const LIST_MICROSERVICE_RCA_RESULTS = 'list-microservice-rca-results'
export const LIST_MICROSERVICE_RCA_DETAILS = 'list-microservice-rca-details'

//microServiceTopolgyEndPoint end

//taggingEndPoint start

export const GET_ALL_TAGS = '/get-all-tags' //commonAction and assetComparisionAction and governanceAction
export const GET_ALL_TAGS_KEYS = '/get-all-tags-keys' //commonAction
export const GET_ALL_TAGS_VALUES = '/get-all-tags-values' //commonAction
export const GET_ASSETS_BY_TAG_OPERATIONS = '/get-assets-by-tag-operations' //governanceAction
export const GET_TAG_COMPLIANCE_AGGREGATION_COUNT = '/get-tag-compliance-aggregation-count'//governanceAction
export const GET_TAG_COMLIANCE_TOTAL_COUNT = '/get-tag-compliance-total-count'//governanceAction
export const GET_TAGS_BY_OPERATION_COUNT = '/get-tags-by-operation-count'//governanceAction


export const GET_TAG_GROUPS = '/get-tag-groups' //taggingAction
export const GET_MASTER_TAG_GROUPS = '/get-master-tag-groups' //taggingAction
export const INSERT_TAG_GROUPS = '/insert-tag-groups' //taggingAction
export const INSERT_ASSET_TAG_GROUP_CONDITIONS = '/insert-asset-tag-group-conditions' //taggingAction
export const UPDATE_TAG_GROUPS = '/update-tag-groups' //taggingAction
export const UPDATE_ASSET_TAG_GROUP_CONDITIONS = '/update-asset-tag-group-conditions' //taggingAction
export const DELETE_TAG_GROUPS = '/delete-tag-groups' //taggingAction
export const GET_ASSET_TAG_GROUP_CONDITIONS = '/get-asset-tag-group-conditions' //taggingAction
export const APPLY_TAGS_CONFIRM_TO_TAG_GROUP = '/apply-tags-confirm-to-tag-group' //taggingAction
export const GET_APPLIED_TAG_ASSETS = '/get-applied-tag-assets' //taggingAction
export const DELETE_ASSET_TAG_GROUPS = '/delete-asset-tag-groups' //taggingAction

//taggingEndPoint end

//configurationEndPoint start

export const INSERT_CONFIGURATON = '/insert-configuration' //configurationAction and governanceAction
export const UPDATE_CONFIGURATION = '/update-configuration' //configurationAction and governanceAction
export const DELETE_CONFIGURATON = '/delete-configuration' //configurationAction and governanceAction
export const GET_CONFIGURATION = '/get-configuration' //detectorsAction
export const LIST_CONFIGURATION = '/list-configuration' //governanceAction

//configurationEndPoint end


//suppressorEndPoint start

export const LIST_SUPPRESSION = '/list-suppression' //suppressorsAction
export const INSERT_SUPPRESSION = '/insert-suppression' //suppressorsAction
export const DELETE_SUPPRESSION = '/delete-suppression' //suppressorsAction

export const UPDATE_SUPPRESSION = '/update-suppression' //not used

//suppressorEndPoint end


//userInsightsEndPoint start

export const LIST_IDENTITIES ='/list-identities' //userinsightsAction
export const GET_PRIVILEAGE_ANALYSIS ='/get-privilege-analysis' //userinsightsAction
export const LAST_ACCESSED_DETAILS ='/last-accessed-details' //userinsightsAction
export const UI_GET_TOTAL_COUNT ='/get-total-count' //userinsightsAction
export const GET_TOTAL_ASSETS_ACCESSED ='/get-total-assets-accessed' //userinsightsAction
export const GET_ASSETS_ACCESSIBLE_COUNT ='/get-assets-accessible-count' //userinsightsAction
export const GET_ACCESS_TREND ='/get-access-trend' //userinsightsAction
export const LIST_USERS_ASSUMING_ROLE ='/list-users-assuming-role' //userinsightsAction
export const GET_PERMISSION_DETAILS ='/get-permission-details' //userinsightsAction
export const GET_ACTIONS_CATEGORY_WISE ='/get-actions-category-wise' //userinsightsAction
export const GET_ACTIONS_SERVICE_WISE ='/get-actions-service-wise' //userinsightsAction
export const GET_ANOMALY_DETAILS ='/get-anomaly-details' //userinsightsAction
export const GET_RECENT_BEHAVIOR ='/get-recent-behavior' //userinsightsAction
export const GET_DETECTOR_ISSUES ='/get-detector-issues' //userinsightsAction
export const LIST_FREQUENTLY_ACCESSED ='/list-frequently-accessed' //userinsightsAction
export const GET_RELATED_RISKS ='/get-related-risks' //userinsightsAction
export const GET_RELATED_ISSUES_SERVICE_WISE ='/get-related-issues-service-wise' //userinsightsAction
export const GET_DAYWISE_DENIED ='/get-daywise-denied' //userinsightsAction
export const GET_DENIED_SERVICE_WISE ='/get-denied-service-wise' //userinsightsAction

//userInsightsEndPoint end


//iamGovernanceEndPoint start

export const LIST_POLICY = '/list-policies' //governanceAction
export const CHECK_POLICY_NAME = '/check-policy-name' //iamAction
export const GENERATE_POLICY = '/generate-policy' //iamAction
export const CREATE_POLICY = '/create-policy' //iamAction
export const REMOVE_POLICY = '/remove-policy' //iamAction

//iamGovernanceEndPoint end


//maintenanceEndPoint start


//maintenanceEndPoint end


//tagGroupsEndPoint start


//tagGroupsEndPoint end

//userSignupEndPoint Start
export const SIGNUP = '/signup' //registerAction
export const ADD_ACCOUNT = '/addaccount' //AccountRegisterAction

//userSignupEndPoint end


//DLP Start

//dlp profile start
export const GET_DLP_METADATA_OVERALL_DISTRIBUTION = '/get-dlp-metadata-overall-distribution'
export const GET_METADATA_CROSS_ACCOUNT_DISTRIBUTION = '/get-metadata-cross-account-distribution'
export const GET_METADATA_TOTAL_COUNT = '/get-metadata-total-count'
export const GET_METADATA_RANGE_DISTRIBUTION = '/get-metadata-range-distribution'
export const GET_METADATA_OVERALL_DISTRIBUTION = '/get-metadata-overall-distribution'
//dlp profile end

//dlp compliance start
export const GET_DATA_FINDINGS_DAILY_COUNT = '/get-data-findings-daily-count'
export const GET_DATA_FINDINGS_TOTAL_COUNT = '/get-data-findings-total-count'
//dlp compliance end

//dlp Trend start
export const GET_DLP_FILTER_PROPERTIES = '/get-dlp-filter-properties'
export const START_DATA_EVENTS_DAILY_TREND = '/start-data-events-daily-trend'
export const GET_DATA_EVENTS_DAILY_TREND = '/get-data-events-daily-trend'
export const GET_TREND_SCORE = '/get-score'
//dlp Trend end

// DLP Threat Start
export const DLP_THREAT_GET_DASHBOARD_DATA = 'threat/checks/get-dashboard-data'
export const DLP_THREAT_GET_COUNT = 'threat/checks/get-count'
export const DLP_THREAT_GET_STATUS_COUNT = 'threat/checks/get-status-count'
export const DLP_THREAT_GET_TOTAL_COUNT = 'threat/checks/get-total-count'
export const DLP_THREAT_GET_PASS_FAIL_DETAILS ='threat/checks/get-pass-fail-details'
export const DLP_THREAT_GET_COMPLIANCE_MINOR_CONTROL_DETAILS = 'threat/checks/get-compliance-minor-control-details'
export const DLP_THREAT_GET_COMPLIANCE_SUB_CONTROL_DETAILS = 'threat/checks/get-compliance-sub-control-details'
export const DLP_THREAT_GET_TOP_BUCKETS = 'threat/checks/get-top-buckets' //not neaded
export const DLP_THREAT_GET_THREAT_COVERAGE = 'threat/checks/get-threat-coverage' //not neaded

export const DLP_THREAT_GET_DAY_WISE_COUNT = 'threat/checks/get-day-wise-count'
export const DLP_THREAT_GET_TRANSITION_TREND ='threat/checks/get-transition-trend'
export const DLP_THREAT_GET_COMPLIANCE_CONTROL_DETAILS = 'threat/checks/get-compliance-control-details'
export const DLP_THREAT_DOWNLOAD_CHECKS_RESULTS = 'threat/checks/download-checks-results'
export const DLP_THREAT_GET_THREAT_DETAILS = 'threat/checks/get-threat-details'
export const DLP_THREAT_GET_THREAT_COVERAGE_AND_TOP_STATS = 'threat/checks/get-threat-coverage-and-top-stats'
// DLP Threat End

// DLP Ueba Start
export const DLP_UEBA_GET_DAY_WISE_COUNT = 'ueba/checks/get-day-wise-count'
export const DLP_UEBA_GET_COMPLIANCE_CONTROL_DETAILS = 'ueba/checks/get-compliance-control-details'
export const DLP_UEBA_GET_UEBA_DETAILS = 'ueba/checks/get-ueba-details'
export const DLP_UEBA_GET_UEBA_COVERAGE_AND_TOP_STATS = 'ueba/checks/get-ueba-coverage-and-top-stats'
export const DLP_UEBA_DOWNLOAD_CHECKS_RESULTS = 'ueba/checks/download-ueba-results'
export const DLP_UEBA_GET_TRANSITION_TREND ='ueba/checks/get-transition-trend'
// DLP Ueba End

// DLP Compliance Start
// export const DLP_COMPLIANCE_GET_DAY_WISE_COUNT = 'compliance/checks/get-day-wise-count'
export const DLP_COMPLIANCE_GET_COMPLIANCE_CONTROL_DETAILS = 'compliance/checks/get-compliance-control-details'
export const DLP_COMPLIANCE_GET_COMPLIANCE_DETAILS = 'compliance/checks/get-compliance-details'
export const DLP_COMPLIANCE_GET_COMPLIANCE_COVERAGE_AND_TOP_STATS = 'compliance/checks/get-compliance-coverage-and-top-stats'
export const DLP_COMPLIANCE_DOWNLOAD_CHECKS_RESULTS = 'compliance/checks/download-compliance-results'
// DLP Compliance End

// DLP Virus Start
export const DLP_MALWARE_GET_DAY_WISE_COUNT = 'malware/get-day-wise-count'
export const DLP_MALWARE_GET_TRANSITION_TREND = 'malware/get-transition-trend'
export const DLP_MALWARE_GET_COMPLIANCE_CONTROL_DETAILS = 'malware/get-compliance-control-details'
export const DLP_MALWARE_DOWNLOAD_CHECKS_RESULTS = 'malware/download-malware-results'
export const DLP_MALWARE_GET_MALWARE_DETAILS = 'malware/get-malware-details'
export const DLP_MALWARE_GET_MALWARE_COVERAGE_AND_TOP_STATS = 'malware/get-malware-coverage-and-top-stats'
// DLP Virus End

// DLP Report Start
export const DLP_REPORT_LIST_REPORTS = 'reports/list-reports'
export const DLP_REPORT_GET_SCHEDULED_REPORTS = 'reports/get-scheduled-reports'
export const DLP_REPORT_INSERT_REPORTS = 'reports/insert-reports'
export const DLP_REPORT_UPDATE_REPORTS = 'reports/update-reports'
export const DLP_REPORT_DELETE_REPORTS = 'reports/delete-reports'
export const DLP_REPORT_GENERATE_REPORT = 'reports/generate-report'
export const DLP_REPORT_DOWNLOAD_REPORT = 'reports/download-report'
export const DLP_REPORT_LIST_GENERATED_REPORTS = 'reports/list-generated-reports'
export const DLP_REPORT_LIST_MASTER_REPORTS = 'reports/list-master-reports'
export const DLP_REPORT_GET_BUCKETS_PREFIXES = 'reports/get-buckets-prefixes'
export const DLP_REPORT_INSERT_MASTER_REPORTS = 'reports/insert-master-reports'
export const DLP_REPORT_UPDATE_MASTER_REPORTS = 'reports/update-master-reports'
export const DLP_REPORT_DELTE_MASTER_REPORTS = 'reports/delete-master-reports'
// DLP Report End

// DLP Policies Start
export const DLP_POLICIES_LIST_PRIVACY_POLICIES = 'deidentify/list-privacy-policies' 
export const DLP_POLICIES_GET_PRIVACY_POLICY_BASE_PARAMS = 'deidentify/get-privacy-policy-base-params'
export const DLP_POLICIES_LIST_ATHENA_RESOURCES = 'deidentify/list-athena-resources'
export const DLP_POLICIES_INSERT_PRIVACY_POLICIES = 'deidentify/insert-privacy-policies'
export const DLP_POLICIES_UPDATE_PRIVACY_POLICIES = 'deidentify/update-privacy-policies'
export const DLP_POLICIES_DELETE_PRIVACY_POLICIES = 'deidentify/delete-privacy-policies'

export const DLP_POLICIES_LIST_SECURITY_POLICIES = 'deidentify/list-security-policies'
export const DLP_POLICIES_INSERT_SECURITY_POLICIES = 'deidentify/insert-security-policies'
export const DLP_POLICIES_UPDATE_SECURITY_POLICIES = 'deidentify/update-security-policies'
export const DLP_POLICIES_DELETE_SECURITY_POLICIES = 'deidentify/delete-security-policies'
// DLP Policies End

//DLP Configuration start
export const DLP_CONFIGURATION_LIST_CUSTOMER_ACCOUNT_INFO = 'deidentify/list-customer-account-info' //in dlp CustomerAccountAction
export const DLP_CONFIGURATION_INSERT_CUSTOMER_ACCOUNT_INFO = 'deidentify/insert-customer-account-info' //in dlp CustomerAccountAction
export const DLP_CONFIGURATION_UPDATE_CUSTOMER_ACCOUNT_INFO = 'deidentify/update-customer-account-info' //in dlp CustomerAccountAction
export const DLP_CONFIGURATION_DELETE_CUSTOMER_ACCOUNT_INFO = 'deidentify/delete-customer-account-info' //in dlp CustomerAccountAction


export const DLP_CONFIGURATION_GET_SOURCE_DATA_CATALOG_BASE_PARAMS = 'deidentify/get-source-data-catalog-base-params' //in dlp BucketTableAction
export const DLP_CONFIGURATION_LIST_SOURCE_DATA_CATALOG = 'deidentify/list-source-data-catalog' //in dlp BucketTableAction
export const DLP_CONFIGURATION_INSERT_SOURCE_DATA_CATALOG = 'deidentify/insert-source-data-catalog' //in dlp BucketTableAction
export const DLP_CONFIGURATION_UPDATE_SOURCE_DATA_CATALOG = 'deidentify/update-source-data-catalog' //in dlp BucketTableAction
export const DLP_CONFIGURATION_DELETE_SOURCE_DATA_CATALOG = 'deidentify/delete-source-data-catalog' //in dlp BucketTableAction

export const DLP_CONFIGURATION_LIST_WATERMARK = 'deidentify/list-watermark'    	//WatermarkAction
export const DLP_CONFIGURATION_CREATE_WATERMARK = 'deidentify/create-watermark' //WatermarkAction
export const DLP_CONFIGURATION_UPDATE_WATERMARK = 'deidentify/update-watermark' //WatermarkAction
export const DLP_CONFIGURATION_DELETE_WATERMARK = 'deidentify/delete-watermark' //WatermarkAction


export const DLP_CONFIGURATION_GET_DLP_ENGINE_EVENTS_BASE_PARAMS = 'deidentify/get-dlp-engine-events-base-params' //DlpJobsAction
export const DLP_CONFIGURATION_LIST_MSC = 'deidentify/list-msc'		//DlpJobsAction
export const DLP_CONFIGURATION_INSERT_MSC = 'deidentify/insert-msc'	//DlpJobsAction
export const DLP_CONFIGURATION_UPDATE_MSC = 'deidentify/update-msc'	//DlpJobsAction
export const DLP_CONFIGURATION_DELETE_MSC = 'deidentify/delete-msc'	//DlpJobsAction


export const DLP_CONFIGURATION_GET_THREAT_JOB_BASE_PARAMS = 'deidentify/get-threat-jobs-base-params' //DlpJobsAction
export const DLP_CONFIGURATION_GET_UEBA_JOB_BASE_PARAMS = 'deidentify/get-ueba-jobs-base-params' //DlpJobsAction
export const DLP_CONFIGURATION_GET_DATA_EVENTS_PROCESSOR_BASE_PARAMS = 'deidentify/get-data-events-processor-base-params' //DlpJobsAction
export const DLP_CONFIGURATION_GET_DATA_EVENTS_CONSOLIDATOR_BASE_PARAMS = 'deidentify/get-data-events-consolidator-base-params' //DlpJobsAction
export const DLP_CONFIGURATION_GET_ACCESS_LOGS_PROCESSOR_BASE_PARAMS = 'deidentify/get-access-logs-processor-base-params' //DlpJobsAction
export const DLP_CONFIGURATION_GET_DATA_EVENTS_AGGREGATOR_BASE_PARAMS = 'deidentify/get-data-events-aggregator-base-params' //DlpJobsAction
export const DLP_CONFIGURATION_GET_INVENTORY_PROCESSOR_BASE_PARAMS = 'deidentify/get-inventory-processor-base-params' //DlpJobsAction

//DLP Configuration End


//DLP Query start

export const DLP_QUERY_INVOKE_QUERY = 'deidentify/invoke-query' //DlpQueryAction
export const DLP_QUERY_GET_QUERY_RESULT = 'deidentify/get-query-result' //DlpQueryAction

export const DLP_QUERY_LIST_QUERY_LOG = 'deidentify/list-user-query-log' //DlpQueryAction
export const DLP_QUERY_DOWNLOAD_QUERY_RESULT = 'deidentify/download-query-result' //DlpQueryAction

//DLP Query end

export const GET_MONITORING_METRICS = 'get-monitoring-metrics' //not used

export const DETECTORS_LIST = '/' //not used

export const GET_PLAN = '/maintenance/get-plan/' //not used
export const LIST_PLANS = '/maintenance/list-plans' //not used
export const CREATE_PLAN = '/maintenance/create-plan' //not used

export const GET_APPLIED_TAGS_BY_DAY_WISE_COUNT = '/get-applied-tags-day-wise-count' //not used

// export const GET_TOTAL ='/get-total-count'
// export const GET_UI_DAY_WISE_COUNT ='/get-day-wise-count'


//errorEndPoint start
export const ERRORS_GET_DAILY_COUNT = 'get-daily-count' //Errors Action
export const ERRORS_GET_TOTAL_COUNT = 'get-total-count' //Errors Action
export const ERRORS_LIST_ERROR_RESULTS = 'list-error-results' //Errors Action
export const ERRORS_GET_TOP_ERRORS = 'get-top-errors' //Errors Action
export const ERRORS_GET_TOP_ERRORS_WITH_MORE_SEVERITY = 'get-top-errors-with-more-severity' //Errors Action   //not used
export const ERRORS_GET_FIRST_SEEN_ERRORS = 'get-first-seen-errors' //Errors Action   //not used
export const ERRORS_GET_LAST_SEEN_ERRORS = 'get-last-seen-errors' //Errors Action   //not used
//errorEndPoint end


//slowQueryEndPoint start
export const SLOW_QUERY_GET_SLOW_QUERY_SUMMARY = 'get-slow-query-summary'
export const SLOW_QUERY_GET_EACH_SLOW_QUERY_DAILY_TREND = 'get-each-slow-query-daily-trend'
export const POSTGRESQL_GET_QUERY_SUMMARY = 'postgresql/get-slow-query-summary'
export const POSTGRESQL_GET_EACH_QUERY_DAILY_TREND = 'postgresql/get-each-slow-query-daily-trend'


export const ATHENA_QUERY_GET_QUERY_SUMMARY = 'athena/get-query-summary'
export const ATHENA_QUERY_GET_EACH_QUERY_DAILY_TREND = 'athena/get-each-query-daily-trend'

export const ELASTICACHE_QUERY_GET_SLOW_QUERY_HISTOGRAM = 'elasticache/get-slow-query-histogram'
export const ELASTICACHE_GET_QUERY_SUMMARY = 'elasticache/get-slow-query-summary'
export const ELASTICACHE_GET_EACH_QUERY_DAILY_TREND = 'elasticache/get-each-slow-query-daily-trend'


//slowQueryEndPoint end

//rechabilityEndPoint start
export const GET_RECHABILITY_ANALYSIS_RESULTS = 'get-reachability-analysis-results'
//rechabilityEndPoint end


//observabilityQueryEndPoint start
export const GET_QUERIES = 'get-queries' //QueryAction
export const START_QUERY_EXECUTION = 'start-query-execution' //QueryAction
export const GET_QUERY_RESULT = 'get-query-results' //QueryAction
export const SET_OBSERVABILITY_ASSET_FAVOURITE_QUERY = 'set-favourite-query' //QueryAction
export const UNSET_OBSERVABILITY_ASSET_FAVOURITE_QUERY = 'unset-favourite-query' //QueryAction
//observabilityQueryEndPoint end


//causalCorrelationEndPoint start
export const GET_CAUSE_AND_IMPACTED_METRICS = 'get-cause-and-impacted-metrics' //CausalCorrelationAction  //used in {observaion-assets}
//causalCorrelationEndPoint end


//operationalMetricsEndPoint start
export const GET_JOB_TOTAL_COUNT = '/get-job-total-count'
export const GET_JOB_DAILY_COUNT = '/get-job-daily-count'
export const GET_JOB_HISTOGRAM = '/get-job-histogram'
export const GET_JOB_DETAILS_BY_MODULE = '/get-job-details-by-module'
export const GET_JOB_DETAILS = '/get-job-details'
export const GET_COMPLETE_JOB_DETAILS = '/get-complete-job-details'
//operationalMetricsEndPoint start


//ISSUEDUMP start
export const GET_COLLECTED_DATA = '/get-collected-data'
export const LIST_TEST_DETAILS = '/list-test-details'
//operationalMetricsEndPoint start

//SCHEDULEENDPOINT start
export const LIST_LIFECYCLE_POLICIES = '/list-lifecycle-policies'
export const PUT_LIFECYCLE_POLICIES = '/put-lifecycle-policy'
export const LIST_LIFECYCLE_RULES = '/list-lifecycle-rules'
//SCHEDULEENDPOINT end