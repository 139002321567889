/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 01/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 * 
 *************************************************/
import React, { Component } from 'react'
import { AppWrapper } from '../../common/AppWrapper'
// import PropTypes from 'prop-types'
import { listAllProviders, listAllAccounts, listAllRegions } from '../../../actions/commonAction'
import { setCostPropsDetails, getCostAnomalyDailyTrend, listCostAnomalyResults, getCostAnomalyTotalCount, getCostTopAnomalies } from '../../../actions/cost/CostAction'

import { momentDateGivenFormat, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, addHours, subHours, twoDateDiffrence, currentLocaltime, subDays, capitalizeFirstLetter, getDayFromSelectedDuration } from '../../../utils/utility'

// import _ from 'lodash'

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import Chart from 'react-apexcharts'

import { RangePicker } from "react-minimal-datetime-range";
import "react-minimal-datetime-range/lib/react-minimal-datetime-range.min.css";

import AnomalyDashboard from './AnomalyDashboard'
import { Spinner } from 'reactstrap'
import ApexBarChart from '../../common/charts/ApexBarChart'

const durationOptions = [    
	{ value: '+1h', option: 'Last 1 hour' },
    { value: '+3h', option: 'Last 3 hours' },
    { value: '+6h', option: 'Last 6 hours' },
    { value: '+12h', option: 'Last 12 hours' },
	{ value: '+24h', option: 'Last 24 hours' },
	{ value: '+3d', option: 'Last 3 days' },
	{ value: '+7d', option: 'Last 1 week' },
    { value: '+14d', option: 'Last 2 weeks' },
    { value: '+30d', option: 'Last 1 month' },
	{ value: 'custom', option: 'Custom Date' },
]

let timer = null;

class LandingPage extends Component {
    constructor(props) {
        super(props)
        this.accountRef = React.createRef()
        this.daterRangeRef = React.createRef()
        
        this.state = {
            minMaxHeight: "700px",
            showLoading: true,
            providers1:[
                {provider_name: "AWS"}
            ],

            //filter section start

            selectedProvider: "",

            selectedAccount: [],
            isAccountOpen: false,

            selectedRegion: [],
            isRegionOpen: false,

            selectedDuration: "+3h",

            //filter section end

            selectionRange: [{
				startDate: new Date(),
				endDate: new Date(),
				key: "selection"
			}],
        }
    }

	componentDidMount = () => {
        if(this.props.providers && this.props.providers.length) {
			this.setState({ providers: this.props.providers, selectedProvider: this.props.providers[0].provider_name },
				() => {
					this.getFilterData('provider')
				}
			)
		} else {
			this.props.listAllProviders((promise, providers) => {
				if (promise) {
					this.setState({ providers, selectedProvider: providers[0].provider_name },
						() => {
							this.getFilterData('provider')
						}
					)
				} else {
					this.setState(
						{ providers: [],  }, 
						() => {
							this.getFilterData()
						}
					)
				}
			})
		}

        this.durationFunction('onLoad')

        this.getWindowSize()
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 100  
                
		let outerHeight = (window.outerHeight - browserBorder - headerHeight ) + "px"

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }
    
	getFilterData = from => {
		let params = {}

		if (this.state.selectedProvider !== "" && this.state.selectedProvider !== 'all') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}

		if (from === 'provider') {
			if(this.props.accounts && this.props.accounts.length) {
				let allAccounts = this.props.accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
				if(this.props.accounts.length) {
					let accRow = {}
					accRow.account_id = "All"
					accRow.account_name = "All"
					accRow.date = null
					accRow.disabled = false
					accRow.provider = "All"
					allAccounts.unshift(accRow);
				}
				this.setState({ accounts: allAccounts })
			} else {
				this.props.listAllAccounts(params, (promise, allAccounts) => {
					if (promise) {						
						let filterdAccounts = allAccounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
						if(filterdAccounts.length) {
							let accRow = {}
							accRow.account_id = "All"
							accRow.account_name = "All"
							accRow.date = null
							accRow.disabled = false
							accRow.provider = "All"
							filterdAccounts.unshift(accRow);
						}						
						this.setState({ accounts: filterdAccounts })
					}
				})
			}

			if(this.props.regions && this.props.regions.length) {
				let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
				this.setState({ regions: providerRegions })
			} else {
				this.props.listAllRegions(params, (promise, regions) => {
					if (promise) {
						let providerRegions = regions && regions[0] && regions[0].provider_regions
						if(providerRegions) {
							let provider_regions_row = {}
							provider_regions_row.availability_zones = []
							provider_regions_row.city = "All"
							provider_regions_row.country = "All"
							provider_regions_row.geography = "All"
							provider_regions_row.name = "All"
							provider_regions_row.region = "All"
							provider_regions_row.region_id = "All"
							provider_regions_row.state = "All"
							providerRegions.unshift(provider_regions_row);
						}
						this.setState({ regions: providerRegions })
					} else {
						this.setState({ regions: [] })
					}
				})
			}
		}
	}

    onSearch = (type) => {
        if(this.state.changesInFilter) {
            let sliderStartDate = this.state.sliderStartDate
            let sliderEndDate = this.state.sliderEndDate
            if(this.state.selectedDuration === "custom") {
                sliderStartDate = this.state.datePickerStartDate && this.state.datePickerStartDate !== "" ? this.state.datePickerStartDate : sliderStartDate
                sliderEndDate = this.state.datePickerEndDate && this.state.datePickerEndDate !== "" ? this.state.datePickerEndDate : sliderEndDate
            }
            this.setState({
                trendSpinner: true,
                brushStartTime: "",
                brushEndTime: "",
                alertSeries: [], alertOptions: {},
                eventSeries: [], eventOptions: {},
                anomalySeries: [], anomalyOptions: {},
                errorSeries: [], errorOptions: {},
                changesInFilter: false,
                showLoading: true,
                resetEnabled: type === 'onLoad' ? false : true,
                sliderStartDate,
                sliderEndDate
            },
                () => {
                    let obj = {}
                    obj.start_time = this.state.sliderStartDate
                    obj.end_time = this.state.sliderEndDate
                    this.props.setCostPropsDetails('costCommonPageFilter', obj)

                    this.getCostAnomalyDailyTrend()
                    this.brushOptions()
                }
            )
        }

        setTimeout(() => { this.setState({ showLoading: false }) }, 3000)
    }

    onReset = () => {
        this.setState({ 
            selectedProvider: "",
            selectedAccount: [],
            selectedDuration: '+3h',
            changesInFilter: this.state.resetEnabled,
            datePickerStartDate: "",
            datePickerEndDate: ""
        },
            () => {
                if(this.state.resetEnabled) {
                    this.durationFunction('onLoad')
                }
            }
        )
    }

    getCostAnomalyDailyTrend = () => {
        let params = {}
        params.start_time = momentConvertionLocalToUtcTime(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:ss')
        params.end_time = momentConvertionLocalToUtcTime(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:ss')
        params.duration_aggregate_by = "seconds"
        
        this.props.getCostAnomalyDailyTrend(params, (promise, response) => {
            if(promise) {
                this.setState({ anomalyDailyTrend: response && response.results && response.results.length ? response.results[0] : {}, trendSpinner: false },
                    () => {
                        this.totalAnomalies()
                    }    
                )
            } else {
                this.setState({ anomalyDailyTrend: [], trendSpinner: false }, 
                    () => this.totalAnomalies()    
                )
            }
        })
    }

    totalAnomalies = () => {
        // let colors = ['#FF0000']
        let colors = ['#775BA2']
        let graphDetails = this.state.anomalyDailyTrend
        let anomalySeries = []
        // let columnWidth = ""
        let anomalyCount = 0
        if(graphDetails && graphDetails.data && graphDetails.data) {

            anomalyCount = graphDetails.data.reduce((a, b) => a + b, 0)
            
            let dataArray = []
            let graphLabels = graphDetails.dates

            graphDetails.data.forEach((val, index) => {
                let dataRow = {}
                dataRow = {}
                dataRow.x =  momentConvertionUtcToLocalTime(graphLabels[index], 'YYYY-MM-DD HH:mm:ss')
                dataRow.y = val
                dataArray.push(dataRow)
            })

            if(!graphLabels.includes(momentConvertionLocalToUtcTime(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:00'))) {
                let dataRow = {}
                dataRow.x = momentDateGivenFormat(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:00')
                dataRow.y = 0 
                dataArray.push(dataRow) //to initialize the start time in the graph
            }
            
            if(!graphLabels.includes(momentConvertionLocalToUtcTime(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:00'))) {
                let dataRow = {}
                dataRow.x = momentDateGivenFormat(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:00')
                dataRow.y = 0
                dataArray.push(dataRow) //to initialize the end time in the graph
            }
            let seriesRow = {}
            seriesRow.name = ""
            seriesRow.data = dataArray

            anomalySeries = [seriesRow]

            // if(dataArray.length <= 10) {
                // columnWidth = '1%'
            // }
        } else {
            let dataArray = []
            let dataItems = {}
            dataItems.x = momentDateGivenFormat(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0 
            dataArray.push(dataItems) //to initialize the start time in the graph

            dataItems = {}
            dataItems.x = momentDateGivenFormat(addHours(this.state.sliderStartDate, 1), 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0 
            dataArray.push(dataItems) //to initialize the start time in the graph

            dataItems = {}
            dataItems.x = momentDateGivenFormat(subHours(this.state.sliderEndDate, 1), 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0
            dataArray.push(dataItems) //to initialize the end time in the graph

            dataItems = {}
            dataItems.x = momentDateGivenFormat(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0
            dataArray.push(dataItems) //to initialize the end time in the graph

            let seriesRow = {}
            seriesRow.name = "No Data"
            seriesRow.data = dataArray
            anomalySeries = [seriesRow]

            // if(dataArray.length <= 10) {
                // columnWidth = '1%'
            // }
        }

        let barOptions = {                
            rangeBarOverlap: false,
            rangeBarGroupRows: false,
            columnWidth: "0%"
        }

        // if(columnWidth !== "") {
        //     barOptions.columnWidth = "0%"
        // }
        
        let anomalyOptions = {
            chart: {
                id:"",
                // redrawOnParentResize: true,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
                sparkline: {
                    enabled: false
                },
                animations: {
                    enabled: false,
                },
                type: 'bar',
                height: 150,
                stacked: false,
            },
            markers: {
                size: 0
            },
            colors: colors,
            plotOptions: {
                bar: barOptions
                // bar: {
                //     horizontal: false,
                //     columnWidth: columnWidth,
                //     distributed: false,
                //     rangeBarOverlap: false,
                //     rangeBarGroupRows: false,
                // },       
            },
            dataLabels: {
                enabled: false
            },                
            grid: {
                show: false,			        
                xaxis: {
                    lines: {
                        show: false
                    },
                },
                yaxis: {
                    lines: {
                        show: false
                    }
                },
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: -5
                },
            },
            xaxis: {
                show: false,
                tooltip: {
                    enabled: false
                },
                axisTicks: {
                    show: false
                },
                type: 'datetime',
                axisBorder: {
                    show: true,
                    color: '#434B5E',
                },
                labels: {
                    show: false,
                    datetimeUTC: false,
                    style: {
                        colors: '#B8BBBE',
                        fontSize: '10px',
                        fontWeight: 'bolder',
                    },
                    offsetX: 0,
                    offsetY: -5,
                },
            },
            yaxis: {
                show: false,
            },
            legend: {
                show: false,
            },
            tooltip: {
                enabled: true,
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    let val  = series[seriesIndex][dataPointIndex];
                    // let labelName = w.globals.initialSeries[seriesIndex].name
                    return '<div class="arrow_box">' +
                        '<span> '+ momentDateGivenFormat(w.globals.initialSeries[0].data[dataPointIndex].x, "DD MMM YYYY HH:mm") + 
                        ' <span style="color:'+ w.globals.colors[seriesIndex] +'"> Anomalies </span> ' + val + ' </span>' +
                        '</div>'
                },
                style: {
                    fontSize: '9px',
                },
            },
        }

        this.setState({ anomalySeries, anomalyOptions,anomalyCount })
    }

    brushOptions = () => {
        let brushSeries = []
        let brushRow = {}
        brushRow.data = [
            {x: momentDateGivenFormat(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm'), y: 0},
            {x: momentDateGivenFormat(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm'), y: 0}
        ]
        brushSeries.push(brushRow)

        let endTIme = momentDateGivenFormat(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:ss')
        let time = endTIme
        let brushHours = 1
        let diff = twoDateDiffrence(this.state.sliderEndDate, this.state.sliderStartDate)
        
        if(diff > 1 && diff <= 3) {            
            brushHours = 6
        } else if(diff > 3 && diff <= 7) {
            brushHours = 24 //3
        } else if(diff > 7 && diff <= 14) {
            brushHours = 36 //6
        } else if(diff > 14 && diff <= 30) {
            brushHours = 48 //12
        } else if(diff > 30) {
            brushHours = 96 //24
        }

        let brushStartTime = subHours(time, brushHours)
        let brushEndTime = time

        let optionsBrush = {
            chart: {
                background: '#fff',
                id: 'chart1',
                type: 'line',
                sparkline: {
                    enabled: true
                },
                zoom: {
                    enabled: false,
                },
                brush:{
                    // targets: ['bar1', 'bar2'],
                    target: "",
                    enabled: true,
                    autoScaleYaxis: true,
                },
                selection: {
                    enabled: true,
                    fill: {
                        color: "#039BE5",
                        opacity: 1,
                    },
                    xaxis: {
                        min: new Date(momentDateGivenFormat(brushStartTime, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                        max: new Date(momentDateGivenFormat(brushEndTime, 'YYYY-MM-DD HH:mm:ss')).getTime()
                    }
                },
                events: {
                    selection: (chartContext, { xaxis, yaxis }) => {
                        this.brushSelection(xaxis)
                    }
                }
            },
            markers: {
                size: 0
            },
            colors: ['#F44336'],
            fill: {
                type: 'solid',
                color: "#000",
                opacity: 1,
            },
            xaxis: {
                type: 'datetime',
                tooltip: {
                    enabled: false
                }
            },
            yaxis: {
                show: false,
                tickAmount: 2
            },
            annotations: {
                yaxis: [
                  {
                    y: 1,
                    borderColor: '#000',
                    label: {
                      borderColor: '#00E396',
                      style: {
                        color: '#fff',
                        background: '#00E396'
                      },
                      text: ""
                    }
                  }
                ]
            }
        }

        this.setState({ brushSeries, optionsBrush },
            // () => this.props.loading()
        )
    }

    brushSelection = (xaxis, type) => {
        clearTimeout(timer)
        let brushStartTime = momentDateGivenFormat(xaxis.min, 'YYYY-MM-DD HH:mm:ss')
        let brushEndTime = momentDateGivenFormat(xaxis.max, 'YYYY-MM-DD HH:mm:ss')       

        this.props.setCostPropsDetails('alertPageMonitoringList', {})
        if(this.state.brushStartTime && this.state.brushStartTime !== "" && this.state.brushEndTime !== this.state.sliderEndDate) {
            timer = setTimeout(() => this.costCommonPageFilterProps(brushStartTime, brushEndTime, true), 1000)
        } else {
            this.costCommonPageFilterProps(this.state.sliderStartDate, this.state.sliderEndDate, false)
        }
        
        if(brushStartTime && brushEndTime) {
            this.setState({ brushStartTime, brushEndTime, alertListLoader: true },
                () => this.getCostTopAnomalies()    
            )
        }

    }

    costCommonPageFilterProps = (brushStartTime, brushEndTime, brushTime) => {
        let obj = {}
        obj.start_time = this.state.sliderStartDate
        obj.end_time = this.state.sliderEndDate
        obj.brushStartTime = brushStartTime
        obj.brushEndTime = brushEndTime
        this.props.setCostPropsDetails('costCommonPageFilter', obj)

        this.setState({ brushTime })
    }
    
    handleMultiSelectChange = (field, arrayValue) => {
		let value = arrayValue.map(item => item.value)

		let selectedValue = []
		if(field === 'selectedAccount') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.accounts.forEach(acc => {
						selectedValue.push(acc.account_id)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.accounts.length -1)) {
					selectedValue.push('All')
				}
			}	
            this.setState({ [field]: selectedValue, changesInFilter: true })		
		} else if(field === 'selectedRegion') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.regions.length -1)) {
					selectedValue.push('All')
				}
			}
            this.setState({ [field]: selectedValue })
		} 
    }
    
    getMultiSelectedCount = (type, array) => {
        return array.length && array.includes('All') ? 'All' : (array.length ? array.length +' Selected' : 'All')
    }

    handleSelect = (date) => {
        let datePickerStartDate = momentDateGivenFormat(date[0], "YYYY-MM-DD HH:mm:00")
		let datePickerEndDate = momentDateGivenFormat(date[1], "YYYY-MM-DD HH:mm:00")
        this.setState({ datePickerStartDate, datePickerEndDate, changesInFilter: true, showDateRangePicker: false })
	}

    durationFunction = (type) => {
        let duration = this.state.selectedDuration
        let period = duration.substring(duration.length, duration.length-1)
        let durationBtw = getDayFromSelectedDuration(duration)
        let sliderStartDate = ""
        if(period === "d") {
            sliderStartDate = momentDateGivenFormat(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
        } else {
            sliderStartDate = momentDateGivenFormat(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
        }
        let sliderEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')

        let selectionRange = [{
			startDate: new Date(sliderStartDate),
			endDate: new Date(sliderEndDate),
			key: 'selection',
		}]

        let dayDiffrence = twoDateDiffrence(this.state.sliderEndDate, this.state.sliderStartDate)

        

        this.setState({ sliderStartDate, sliderEndDate, selectionRange, changesInFilter: true, dayDiffrence },
            () =>  this.onSearch('onLoad')
        )
    }

    handleClickOutside = (event) => {
        if(!this.state.showQueryResults) {
            if (this.daterRangeRef && !this.daterRangeRef.current.contains(event.target)) {
                this.setState({ showDateRangePicker: false })
            }

            if (this.accountRef && !this.accountRef.current.contains(event.target)) {
                this.setState({ isAccountOpen: false })
            } else {
                this.setState({ isAccountOpen: true })
            }
        }
    } 

    getCostTopAnomalies = () => {
        let params = {
            "start_time": momentConvertionLocalToUtcTime(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:ss'),
            "end_time": momentConvertionLocalToUtcTime(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:ss'),
            'top': 5,            
	        "aggregate_by": [["asset_name", "anomaly_name"]],
        }

        this.props.getCostTopAnomalies(params, (promise, response) => {
            if(promise) {
                this.setState({ topCostAnomalies: response.length && response[0].data ? response[0].data : {}  },
                    () => this.topAnamolySectionGraph()
                )
            }
        })
    }

    topAnamolySectionGraph = () => {
        if(this.state.topCostAnomalies.asset_name) {
            let list = this.state.topCostAnomalies.asset_name
            let labels = Object.keys(list).sort(function(a,b){return list[b] - list[a]})

            let topStatusData = {}
            let data = []
            labels.forEach(item => {
                data.push(list[item])
            })
            
            for(let i=labels.length; i<5; i++) {
                data.push(0)
                labels.push('empty')
            }

            topStatusData.data = data
            topStatusData.labels = labels
            this.setState({ topAssets: topStatusData })
        }
        
        if(this.state.topCostAnomalies.anomaly_name) {
            let list = this.state.topCostAnomalies.anomaly_name
            let labels = Object.keys(list).sort(function(a,b){return list[b] - list[a]})

            let topStatusData = {}
            let data = []
            labels.forEach(item => {
                data.push(list[item])
            })
            
            for(let i=labels.length; i<5; i++) {
                data.push(0)
                labels.push('empty')
            }

            topStatusData.data = data
            topStatusData.labels = labels

            this.setState({ topAnomalies: topStatusData })
        }        
    }

	render() {
		return (
            <div className="container-fluid overflow-auto flex-grow-1 bg-muted" onClick={ (event) => { this.handleClickOutside(event) } }>
                <div className={`loaderOverlay ${this.state.showLoading ? "" : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
                <div className='row h-100'>
                    <div className='col-sm-12 p-0'>
                        <div className="title-section pb-3">
							<div className="row">
                                <div className='col-4 align-self-center'>
                                    <div className='d-flex'>
                                        <h6 className="text-white m-0">Anomalies</h6>
                                    </div>
                                    <p className="text-white m-0">Unified view of cost Anomlies in your Cloud</p>
								</div>
                                <div className={`col-8 align-self-center`}>
                                    <div className={`col-md-12 d-flex flex-wrap justify-content-end bd-highlight`}>
                                        <div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: '150px' }}>
                                            <label className="mb-1">Provider</label>
                                            <Select
                                                placeholder={'Select'}
                                                isSearchable={false}
                                                // menuIsOpen={true}
                                                className='f13 p-0 colorBlack reactSelectFilterDrop'
                                                value={({
                                                    value: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : 'Select',
                                                    label: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : <span className="placeholder">Select</span>
                                                })}
                                                options={this.state.providers && this.state.providers.map(item => ({
                                                    value: item.provider_name,
                                                    label: item.provider_name,	
                                                }))}
                                                onChange={event => this.setState({
                                                    selectedProvider: event.value,
                                                    changesInFilter: true,
                                                })}
                                            />
                                        </div>
                                        <div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} ref={this.accountRef} style={{maxWidth: '150px' }}>
                                            <label className="mb-1">Account</label>
                                            <div className='dlpHeaderMultiSelection removeDropdownSearchBar'>
                                                <ReactMultiSelectCheckboxes						
                                                    placeholderButtonLabel="All"
                                                    menuIsOpen ={this.state.isAccountOpen}
                                                    getDropdownButtonLabel={() => this.getMultiSelectedCount('account', this.state.selectedAccount)}
                                                    onChange={arr => {
                                                    this.handleMultiSelectChange('selectedAccount', arr ? arr : []) }}
                                                    options={this.state.accounts && this.state.accounts.map(account => ({
                                                        value: account.account_id,
                                                        label: account.account_name,
                                                    }))}
                                                    value={this.state.selectedAccount.map(comp => ({
                                                        value: comp,
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                        <div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zindex1`} ref={this.daterRangeRef} style={{maxWidth: '150px'}}>
                                            <label className="mb-1">Duration</label>
                                            <Select
                                                placeholder={'Select'}
                                                isSearchable={false}
                                                // menuIsOpen={true}
                                                className='f13 p-0 colorBlack reactSelectFilterDrop'
                                                value={({
                                                    value: this.state.selectedDuration && this.state.selectedDuration !== "" ? this.state.selectedDuration : 'Select',
                                                    label: this.state.selectedDuration && this.state.selectedDuration !== "" ? durationOptions.filter(e => e.value === this.state.selectedDuration)[0].option : <span className="placeholder">Select</span>
                                                })}
                                                options={durationOptions && durationOptions.map(item => ({
                                                    value: item.value,
                                                    label: item.option,	
                                                }))}
                                                onChange={event => {
                                                    if(event.value !== 'custom') {
                                                        this.setState(
                                                            {
                                                                selectedDuration: event.value,
                                                                showDateRangePicker: false,
                                                            },
                                                            () => {
                                                                // this.durationFunction()
                                                            }
                                                        )
                                                    } else {
                                                        this.setState({ showDateRangePicker: true, selectedDuration: event.value })
                                                    }
                                                }}
                                            />
                                            {this.state.showDateRangePicker ?
                                                <div className="obsDateTimePicker text-black5 pt-2 ml-n1" style={{"z-index": "999999999","position": "absolute","right":0}}>
                                                    <RangePicker
                                                        // locale={`en-us`} // default is en-us
                                                        show={true} // default is false
                                                        disabled={false}
                                                        placeholder={["Start Time", "End Time"]}
                                                        onConfirm={(res) => this.handleSelect(res)}
                                                        onClose={() => console.log("onClose")}
                                                        onClear={() => console.log("onClear")}
                                                        style={{ width: "610px", margin: "0 auto" }}
                                                    />
                                                </div>
                                                
                                            : null}
                                        </div>
                                        <div className={`ml-3 align-self-center`}>
                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                <button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-right px-2 py-1`} onClick={() => this.setState({ showLoading: false }, () => this.durationFunction() )}>Search</button>
                                                <button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-left px-2 py-1`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='p-2'>
                            <div className="rounded bg-dark p-3">
                                <div className="d-flex justify-content-between">
                                    <p className="mb-1">Showing data for 
                                        <span className="text-info ml-1">
                                            {this.state.brushTime ?
                                                this.state.brushStartTime ? 
                                                    momentDateGivenFormat(this.state.brushStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.brushEndTime, 'DD MMM YYYY HH:mm') 
                                                : ""
                                            : 
                                                this.state.sliderStartDate ? 
                                                    momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM YYYY HH:mm') 
                                                : ""
                                            }
                                        </span>
                                        <span className="ml-2">(local time)</span>
                                    </p>
                                </div>
                                
                                <div className={`d-flex w-100 ${!this.state.minimizeGraphSection && this.state.anomalySeries && this.state.anomalySeries.length ? 'mt-n4' : 'mt-4'}`}>
                                    {/* <div className="w-5 d-flex justify-content-end mt-n2">
                                        <p className="mb-0 align-self-center pr-3">Anomalies</p>
                                    </div> */} 
                                    {this.state.trendSpinner ?
                                        <div className='w-100 d-flex justify-content-center m-4'>
                                            <Spinner className='text-center' color='white' size='sm' />
                                        </div>    
                                    : 
                                        <React.Fragment>
                                        <div className="d-flex w-60">
                                            <div className="w-85 transparentTooltip mt-n2">
                                                {this.state.anomalySeries && this.state.anomalySeries.length ?
                                                    <Chart options={this.state.anomalyOptions} series={this.state.anomalySeries} type="bar" height={120} />
                                                : null}
                                            </div>
                                            <div className="d-flex pl-2 w-15 align-self-center">
                                                {/* <small className="mb-0 text-purple-2">Total: </small> */}
                                                <small className="ml-2 mb-0 text-white f14">{this.state.anomalyCount && this.state.anomalyCount}</small>
                                            </div>
                                        </div>
                                        <div className="w-40">
                                            <div className={`card p-3 rounded bg-dark3 border-0 mb-2`}>
                                                <div className="row mb-3">
                                                    <div className="col-lg-6">
                                                        {/* <div className="bg-dark p-3 rounded"> */}
                                                        <label>Top 5 Anomalies</label>
                                                        {this.state.topAnomalies && Object.entries(this.state.topAnomalies).length ?
                                                            <div className="mt-n2 mb-n4"> 
                                                                <ApexBarChart
                                                                    graphData={this.state.topAnomalies}
                                                                    sparkline={true}
                                                                    yaxis={false}
                                                                    yaxisLabel={false}
                                                                    xaxis={false}
                                                                    xaxisFormat={'string'}
                                                                    xaxisLabel={true}
                                                                    axisLabelColor={'#50586A'}
                                                                    paddingLeft={-10}
                                                                    legend={false}
                                                                    stacked={false}
                                                                    height={80}
                                                                    horizontal={true}
                                                                    barHeight={'40%'}
                                                                    barEndShape={'rounded'}
                                                                    columnWidth={'25%'}
                                                                    gradient={true}
                                                                    gradientColor={['#039BE5', '#5F5BA2']}
                                                                    hideTooltipValue={true}
                                                                    backgroundBarShape={'rounded'}
                                                                    backgroundBarColors={['#333947']}
                                                                    showBackgroundBarColors={false}
                                                                    className={"transparentTooltip"}
                                                                />
                                                            </div>
                                                        : null}
                                                        {/* </div> */}
                                                    </div>
                                                    <div className="col-lg-6 pl-0">
                                                        <label>Top 5 Assets</label>
                                                        {this.state.topAssets && Object.entries(this.state.topAssets).length ?
                                                            <div className="mt-n2 mb-n4"> 
                                                                <ApexBarChart
                                                                    graphData={this.state.topAssets}
                                                                    sparkline={true}
                                                                    yaxis={false}
                                                                    yaxisLabel={false}
                                                                    xaxis={false}
                                                                    xaxisFormat={'string'}
                                                                    xaxisLabel={true}
                                                                    axisLabelColor={'#50586A'}
                                                                    paddingLeft={-10}
                                                                    legend={false}
                                                                    stacked={false}
                                                                    height={80}
                                                                    horizontal={true}
                                                                    barHeight={'40%'}
                                                                    barEndShape={'rounded'}
                                                                    columnWidth={'25%'}
                                                                    gradient={true}
                                                                    gradientColor={['#039BE5', '#5F5BA2']}
                                                                    hideTooltipValue={true}
                                                                    backgroundBarShape={'rounded'}
                                                                    backgroundBarColors={['#333947']}
                                                                    showBackgroundBarColors={false}
                                                                    className={"transparentTooltip"}
                                                                />
                                                            </div>
                                                        : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </React.Fragment>
                                    }
                                </div>
                                
                                <div className="d-flex w-100 mt-n2">
                                    {this.state.brushSeries && this.state.brushSeries.length && this.state.optionsBrush && Object.entries(this.state.optionsBrush).length ?
                                        <React.Fragment>
                                        <div className="w-5 d-flex justify-content-end pr-2">
                                            
                                        </div>
                                        <div className="removeSvgPoint w-80">
                                            <Chart options={this.state.optionsBrush} series={this.state.brushSeries} type="line" height={8} />
                                        </div>
                                        <div className="pl-2 w-15">
                                            <small className="mb-0 align-self-center f10">{momentDateGivenFormat(this.state.brushStartTime, 'DD MMM HH:mm') +' - '+momentDateGivenFormat(this.state.brushEndTime, 'DD MMM HH:mm')}</small>
                                        </div>
                                        </React.Fragment>
                                    : null}
                                </div>
                            </div>
                            <AnomalyDashboard 
                                loading={() => this.setState({ showLoading: false })}
                            />
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
LandingPage.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('cost anomalies landing page',state)
	return {
        accounts: state.filters.accounts,
		regions: state.filters.regions,
    }
}

export default AppWrapper(LandingPage, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
    setCostPropsDetails,
    getCostAnomalyDailyTrend,
    getCostTopAnomalies
})