/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Database.js
 * @author Prakash // on 25/01/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Select from 'react-select';
import { Input, Spinner } from 'reactstrap'
import { onlyNumeric } from '../../../utils/utility'

const credStore = [
    {label: "None", value: "None"},
    {label: "Parameter Store", value: "Parameter Store"},
    {label: "Secrets Manager", value: "Secrets Manager"}
]

class Database extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount = () => {
        this.setState({
            name: this.props.databaseDetails && this.props.databaseDetails.name ? this.props.databaseDetails.name : "",
            source_port: this.props.databaseDetails && this.props.databaseDetails.source_port ? this.props.databaseDetails.source_port : "",
            source_endpoint: this.props.databaseDetails && this.props.databaseDetails.source_endpoint ? this.props.databaseDetails.source_endpoint : "",
            cred_store: this.props.databaseDetails && this.props.databaseDetails.cred_store ? this.props.databaseDetails.cred_store : "",
            cred_key_id: this.props.databaseDetails && this.props.databaseDetails.cred_key_id ? this.props.databaseDetails.cred_key_id : "",
            cred_usr_key: this.props.databaseDetails && this.props.databaseDetails.cred_usr_key ? this.props.databaseDetails.cred_usr_key : "",
            cred_pwd_key: this.props.databaseDetails && this.props.databaseDetails.cred_pwd_key ? this.props.databaseDetails.cred_pwd_key : "",
            cred_endpoint_key: this.props.databaseDetails && this.props.databaseDetails.cred_endpoint_key ? this.props.databaseDetails.cred_endpoint_key : "",
            cred_database_key: this.props.databaseDetails && this.props.databaseDetails.cred_database_k ? this.props.databaseDetails.cred_database_k : "",
        })
    }

    render() {
        return (
            <div className="zapInputDark">
                <div className="d-flex justify-content-between p-3 bg-gray5 border-left">
                    <p className="mb-0 font-weight-bold text-white">{this.props.databaseDetails && this.props.databaseDetails.name ? "Edit" : "Add"}  Database</p>
                    <i className="far fa-times mr-3 text-white" onClick={() => this.props.showHelperSection()}></i>
                </div>
                <div className="p-3">
                    <p className="mb-0 small">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"s standard dummy text ever since the 1500s,</p>
                    <div className="mt-2">
                        <label className="mb-1">Endpoint</label>
                        <Input
                            type="text"
                            placeholder="endpoint"
                            className="inputTextbox"
                            value={this.state.source_endpoint}
                            id={"source_endpoint"}
                            onChange={event =>
                                this.setState({ source_endpoint: event.target.value },
                                    () => {
                                        // this.formInputProps(this.state.activeSection, "source_endpoint", this.state.source_endpoint) 
                                    }
                                )
                            }
                        />
                    </div>
                    <div className="mt-3">
                        <label className="mb-1">Port</label>
                        <Input
                            type="text"
                            placeholder="port"
                            className="inputTextbox"
                            value={this.state.source_port}
                            id={"source_port"}
                            onChange={event =>
                                this.setState({ source_port: onlyNumeric(event.target.value) },
                                    () => {
                                        // this.formInputProps(this.state.activeSection, "source_port", this.state.source_port)
                                    }
                                )
                            }
                        />
                    </div>
                    <div className="mt-3">
                        <label className="mb-1">Storage in which credentials or endpoint details are stored</label>
                        <Select 
                            options={credStore.map(item => ({
                                value: item.value,
                                label: item.label,
                            }))}
                            value={({
                                value: this.state.cred_store && this.state.cred_store,
                                label: this.state.cred_store ? this.state.cred_store : <span className="placeholder">Select storage</span>
                            })}
                            onChange={event => 
                                this.setState({ cred_store: event.value },
                                    () => {
                                        // this.formInputProps(this.state.activeSection, "cred_store", this.state.cred_store)
                                    }
                                )
                            }
                        />
                    </div>
                    {this.state.cred_store && this.state.cred_store !== "None" ? 
                        <React.Fragment>
                            <div className={`mt-3`}>
                                <label className="mb-1">Identity in credential store</label>
                                <Input
                                    type="text"
                                    placeholder="cred key"
                                    className="inputTextbox"
                                    value={this.state.cred_key_id}
                                    id={"cred_key_id"}
                                    onChange={event =>
                                        this.setState({ cred_key_id: event.target.value },
                                            () => {
                                                // this.formInputProps(this.state.activeSection, "cred_key_id", this.state.cred_key_id) 
                                            }
                                        )
                                    }
                                />
                            </div>
                            <div className={`mt-3`}>
                                <label className="mb-1">Name of key in which username is saved</label>
                                <Input
                                    type="text"
                                    placeholder="cred user key"
                                    className="inputTextbox"
                                    value={this.state.cred_usr_key}
                                    id={"cred_usr_key"}
                                    onChange={event =>
                                        this.setState({ cred_usr_key: event.target.value },
                                            () => {
                                                // this.formInputProps(this.state.activeSection, "cred_usr_key", this.state.cred_usr_key) 
                                            }
                                        )
                                    }
                                />
                            </div>
                            <div className={`mt-3`}>
                                <label className="mb-1">Name of key in which password is saved</label>
                                <Input
                                    type="text"
                                    placeholder="cred password key"
                                    className="inputTextbox"
                                    value={this.state.cred_pwd_key}
                                    id={"cred_pwd_key"}
                                    onChange={event =>
                                        this.setState({ cred_pwd_key: event.target.value },
                                            () => {
                                                // this.formInputProps(this.state.activeSection, "cred_pwd_key", this.state.cred_pwd_key) 
                                            }
                                        )
                                    }
                                />
                            </div>
                            <div className={`mt-3`}>
                                <label className="mb-1">Name of key in which endpoint is saved</label>
                                <Input
                                    type="text"
                                    placeholder="cred endpoint key"
                                    className="inputTextbox"
                                    value={this.state.cred_endpoint_key}
                                    id={"cred_endpoint_key"}
                                    onChange={event =>
                                        this.setState({ cred_endpoint_key: event.target.value },
                                            () => {
                                                this.formInputProps(this.state.activeSection, "cred_endpoint_key", this.state.cred_endpoint_key) 
                                            }
                                        )
                                    }
                                />
                            </div>
                            <div className={`mt-3`}>
                                <label className="mb-1">Name of key in which database name is saved</label>
                                <Input
                                    type="text"
                                    placeholder="cred database key"
                                    className="inputTextbox"
                                    value={this.state.cred_database_key}
                                    id={"cred_database_key"}
                                    onChange={event =>
                                        this.setState({ cred_database_key: event.target.value },
                                            () => {
                                                this.formInputProps(this.state.activeSection, "cred_database_key", this.state.cred_database_key) 
                                            }
                                        )
                                    }
                                />
                            </div>
                        </React.Fragment>
                    : null}
                    <div className={`continueButton mt-4 text-center`}>
                        <button className={`btn bg-light p-2 mr-4`} onClick={() => this.props.showHelperSection()}>Back</button>
                        <button className={`btn bg-info p-2 text-white`} onClick={() => this.setState({ saveLoader: true })}>
                            {this.state.saveLoader ? <Spinner color='white' size='sm' className="mx-1" /> :null}
                            {this.props.databaseDetails && this.props.databaseDetails.name ? "Create" : "Update"} Database
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
/**
 * Type of the props used in the component
 */
Database.propTypes = {}

//export default 

export default connect(null, {})(withRouter(Database))
