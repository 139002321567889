/*************************************************
 * Tvastar
 * @exports
 * @file taggingAction.js
 * @author Rajasekar // on 27/03/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'
import _ from 'lodash'
import {
	DETECTORS,
	GET_NON_COMPLAINT_ASSETS,
	
	TAGGING,
	GET_TAG_GROUPS,
	GET_MASTER_TAG_GROUPS,
	INSERT_TAG_GROUPS,
	INSERT_ASSET_TAG_GROUP_CONDITIONS,
	UPDATE_TAG_GROUPS,
	UPDATE_ASSET_TAG_GROUP_CONDITIONS,
	DELETE_TAG_GROUPS,
	GET_ASSET_TAG_GROUP_CONDITIONS,
	APPLY_TAGS_CONFIRM_TO_TAG_GROUP,
	GET_APPLIED_TAG_ASSETS,
	DELETE_ASSET_TAG_GROUPS,
} from '../../config'
import { ACTION_TYPES } from '../types'
import { myLog, generateUuid } from '../../utils/utility'

/**
 * Action to get GET_NON_COMPLAINT_ASSETS
 * @param {Object} body
 * @param {Function} callback
 */
export const getNonComplaintAssets = (body, callback) => {
	return async dispatch => {
		try {
			const nonComplaintAssets = await API.get(DETECTORS, GET_NON_COMPLAINT_ASSETS, { body })
			dispatch({
				type: ACTION_TYPES.SET_NON_COMPLAINT_ASSETS,
				payload: nonComplaintAssets,
			})
			callback()
		} catch (error) {
			callback()
			myLog('nonComplaintAssets', error)
		}
	}
}
/**
 * Action to get-tag-groups
 * @param {Object} body
 * @param {Function} callback
 */
export const getTagGroups = (body, callback) => {
	return async dispatch => {
		try {
			const getTagGroups = await API.post(TAGGING, GET_TAG_GROUPS, { body })
			dispatch({
				type: ACTION_TYPES.SET_TAG_GROUPS,
				payload: getTagGroups,
			})
			dispatch({
				type: ACTION_TYPES.SET_TAG_GROUPS_SELECTED_TABLE_ROW,
				payload: getTagGroups[0],
			})
			callback()
		} catch (error) {
			callback()
			myLog('get-tag-groups', error)
		}
	}
}
/**
 * Action to get-tag-groups by id
 * @param {Object} body
 * @param {Function} callback
 */
export const getTagGroupsById = (body, callback) => {
	return async dispatch => {
		try {
			const details = await API.post(TAGGING, GET_TAG_GROUPS, { body })
			let data = {}
			if (details && details.length > 0) {
				data['tag_group_id'] = details[0].tag_group_id
				data['tag_group_name'] = details[0].tag_group_name
				data['conditions'] = details[0].relations ? details[0].relations : []
				data['tags'] = []
				details[0].tags.forEach(tag => {
					let tagData = {}
					tagData['key'] = tag.key
					tagData['value'] = tag.regex ? tag.regex : tag.value
					tagData['regex'] = tag.regex ? true : false
					tagData['editable'] = tag.editable
					tagData['id'] = tag.id ? tag.id : generateUuid()
					let sectionDetails = _.find(data.tags, { section_name: tag.section_name })
					if (sectionDetails) {
						sectionDetails['tags'].push(tagData)
					} else {
						sectionDetails = {}
						sectionDetails['section_name'] = tag.section_name
						sectionDetails['tags'] = []
						sectionDetails['tags'].push(tagData)
						data.tags.push(sectionDetails)
					}
				})
			}
			dispatch({
				type: ACTION_TYPES.SET_EDIT_TAG_GROUPS_DETAILS,
				payload: data,
			})
			callback()
		} catch (error) {
			callback()
			myLog('get-tag-groups by id', error)
		}
	}
}
/**
 * Action to getTagGroupsCondition by id
 * @param {Object} body
 * @param {Function} callback
 */
export const getTagGroupsConditionById = (body, callback) => {
	return async dispatch => {
		try {
			const conditions = await API.post(TAGGING, GET_ASSET_TAG_GROUP_CONDITIONS, { body })
			dispatch({
				type: ACTION_TYPES.SET_ASSET_TAG_GROUP_CONDITIONS,
				payload: conditions,
			})
			callback()
		} catch (error) {
			callback()
			myLog('getTagGroupsConditionById', error)
		}
	}
}
/**
 * Action to applyTag
 * @param {Object} body
 * @param {Function} callback
 */
export const applyTag = (body, callback) => {
	return async (dispatch, getState) => {
		try {
			const response = await API.post(TAGGING, APPLY_TAGS_CONFIRM_TO_TAG_GROUP, { body })
			if (response.status) {
				let temp = getState().governance.tagGroupConditions
				let index = _.findIndex(temp, item => item.condition_id === body.condition_id)
				temp[index].applied = true
				temp[index].batch_id = response.batch_id
				dispatch({
					type: ACTION_TYPES.SET_ASSET_TAG_GROUP_CONDITIONS,
					payload: temp,
				})
				callback(true, response)
			} else {
				callback(false, response)
			}
		} catch (error) {
			callback(false)
			myLog('applyTag', error)
		}
	}
}
/**
 * Action to delete rule
 * @param {Object} body
 */
export const deleteTagGroup = (body, callback) => {
	return async (dispatch, getState) => {
		try {
			const deleteResponse = await API.post(TAGGING, DELETE_TAG_GROUPS, { body })
			if (deleteResponse.status) {
				let temp = getState().governance.tagGroups
				let deletedList = _.filter(temp, item => item.tag_group_id !== body.tag_group_id)
				dispatch({
					type: ACTION_TYPES.SET_TAG_GROUPS,
					payload: deletedList,
				})
				callback(true, deleteResponse)
			} else {
				callback(false, deleteResponse)
			}
		} catch (error) {
			myLog('deleteTagGroup', error)
			callback(false)
		}
	}
}
/**
 * Action to delete asset tag group
 * @param {Object} body
 */
export const deleteAssetTagGroupCondition = (body, callback) => {
	return async (dispatch, getState) => {
		try {
			const deleteResponse = await API.post(TAGGING, DELETE_ASSET_TAG_GROUPS, { body })
			if (deleteResponse.status) {
				let temp = getState().governance.tagGroupConditions
				let deletedList = _.filter(temp, item => item.condition_id !== body.condition_id)
				dispatch({
					type: ACTION_TYPES.SET_ASSET_TAG_GROUP_CONDITIONS,
					payload: deletedList,
				})
				callback(true)
			} else {
				callback(false)
			}
		} catch (error) {
			myLog('deleteTagGroup', error)
			callback(false)
		}
	}
}
/**
 * Action to set selected table row
 */
export const setSelectedTableRow = row => {
	return async dispatch => {
		dispatch({
			type: ACTION_TYPES.SET_TAG_GROUPS_SELECTED_TABLE_ROW,
			payload: row,
		})
	}
}

/**
 * Action to set to initail values
 */
export const setToInitial = () => {
	return async dispatch => {
		dispatch({
			type: ACTION_TYPES.SET_TO_INITIAL_CREATE,
		})
	}
}
/**
 * Action to set teg id in store
 */
export const setTagIdInStore = tag_group_id => {
	return async dispatch => {
		dispatch({
			type: ACTION_TYPES.SET_TAG_GROUP_ID,
			payload: tag_group_id,
		})
	}
}
/**
 * Action to GET_ALL master _TAGS
 * @param {Object} body
 * @param {Function} callback
 */
export const getMasterTagGroups = (body, callback) => {
	return async dispatch => {
		try {
			const masterTags = await API.post(TAGGING, GET_MASTER_TAG_GROUPS, { body })
			let data = []
			masterTags.forEach(section => {
				let section_details = {}
				section_details.section_name = section.section_name
				section_details.description = section.description
				section_details.tags = []
				section.tags.forEach(tag => {
					let tagDetails = {}
					tagDetails.key = tag.key
					tagDetails.value = tag.regex ? tag.regex : tag.value
					if (Array.isArray(tagDetails.value) && tagDetails.value.length > 0) {
						tagDetails.value_list = tagDetails.value
						tagDetails.value = ''
					}
					if (Array.isArray(tagDetails.value) && tagDetails.value.length === 0) {
						tagDetails.value = ''
					}
					tagDetails.editable = tag.editable ? tag.editable : false
					tagDetails.regex = tag.regex ? true : false
					tagDetails.id = tag.id ? tag.id : generateUuid()
					section_details.tags.push(tagDetails)
				})
				data.push(section_details)
			})
			callback(data)
			dispatch({
				type: ACTION_TYPES.SET_ALL_MASTER_TAG_GROUPS,
				payload: data,
			})
		} catch (error) {
			myLog('masterTags', error)
		}
	}
}

/**
 * Action to insert Tag Groups
 * @param {Object} body
 * @param {Function} callback
 */
export const insertTagGroups = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(TAGGING, INSERT_TAG_GROUPS, { body })
			if (response.status) {
				dispatch({
					type: ACTION_TYPES.SET_TAG_GROUP_ID,
					payload: response.tag_group_id,
				})
				callback(true, response)
			} else {
				callback(false, response)
			}
		} catch (error) {
			myLog('insertTagGroups', error)
			callback(false)
		}
	}
}
/*
 * Action to update Tag Groups
 * @param {Object} body
 * @param {Function} callback
 */
export const updateTagGroups = (body, callback) => {
	return async () => {
		try {
			const response = await API.post(TAGGING, UPDATE_TAG_GROUPS, { body })
			if (response.status) {
				callback(true, response)
			} else {
				callback(false, response)
			}
		} catch (error) {
			myLog('updateTagGroups', error)
			callback(false)
		}
	}
}
/**
 * Action to set edit tag groups details
 */
export const setEditTagGroupDetails = (key, value) => {
	return async (dispatch, getState) => {
		let editDetails = JSON.parse(JSON.stringify(getState().governance.editTagGroupDetails))
		editDetails[key] = value
		dispatch({
			type: ACTION_TYPES.SET_EDIT_TAG_GROUPS_DETAILS,
			payload: editDetails,
		})
	}
}
/**
 * Action to insert asset Tag Group condition
 * @param {Object} body
 * @param {Function} callback
 */
export const insertAssetTagGroupCondition = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(TAGGING, INSERT_ASSET_TAG_GROUP_CONDITIONS, { body })
			if (response.status) {
				dispatch({
					type: ACTION_TYPES.SET_ASSET_TAG_GROUP_CONDITION_ID,
					payload: response.condition_id,
				})
				callback(true, response, response.condition_id)
			} else {
				callback(false, response)
			}
		} catch (error) {
			myLog('insertAssetTagGroupCondition', error)
			callback(false)
		}
	}
}
/**
 * Action to set conditions in store
 * @param {Array} arr
 */
export const setAssetTagGroupConditionsInStore = arr => {
	return async dispatch => {
		try {
			dispatch({
				type: ACTION_TYPES.SET_ASSET_TAG_GROUP_CONDITIONS,
				payload: arr,
			})
		} catch (error) {
			myLog('setAssetTagGroupConditionsInStore', error)
		}
	}
}
/**
 * Action to update asset Tag Group condition
 * @param {Object} body
 * @param {Function} callback
 */
export const updateAssetTagGroupCondition = (body, callback) => {
	return async () => {
		try {
			const response = await API.post(TAGGING, UPDATE_ASSET_TAG_GROUP_CONDITIONS, { body })
			if (response.status) {
				callback(true, response)
			} else {
				callback(false, response)
			}
		} catch (error) {
			myLog('updateAssetTagGroupCondition', error)
			callback(false)
		}
	}
}
/**
 * Action to GET_APPLIED_TAG_ASSETS
 * @param {Object} body
 * @param {Function} callback
 */
export const getAppliedTagAssets = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(TAGGING, GET_APPLIED_TAG_ASSETS, { body })
			dispatch({
				type: ACTION_TYPES.SET_APPLIED_TAG_ASSETS,
				payload: response,
			})
			callback()
		} catch (error) {
			callback()
			myLog('getAppliedTagAssets', error)
		}
	}
}
