/*************************************************
 * Tvastar
 * @exports
 * @file ApesMutliyAxis  .js
 * @author Prakash // on 16/07/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import _ from 'lodash'
import { momentDateGivenFormat, addHours, momentConvertionUtcToLocalTime, getConvertedBytesUnit, convertBytes, convertBytesTo, convertBytesWithoutUnit, convertSeconds, convertSecondsWithoutUnit, countFormater, countFormaterWithoutUnit, convertBits, convertBitsWithoutUnit } from '../../utils/utility'
import Chart from 'react-apexcharts'
import { getSreDetails } from '../../actions/aiops/DiagnosticsAction'

import { getMonitoringMetricsByTrend } from '../../actions/aiops/MetricsAction'
import { setDiagnosticsPropsDetails } from '../../actions/aiops/DiagnosticsAction'
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
// import { fireEvent } from '@testing-library/dom'

class TrafficMetrics extends Component {
    constructor(props) {
        super(props)
        this.annotationTypeRef = React.createRef()
        this.state = {
            showLoading: true,
            sreMetricsDetails: [],
            showAnnotation: true,
        }
    }
    
    componentDidMount = () => {
        let startTime = "" 
        let endTime = "" 
        let eventTime = this.props.selectedEvent && this.props.selectedEvent.event_time ? this.props.selectedEvent.event_time : ""
        if(this.props.page && this.props.page === "rca") {
            startTime = this.props.startTime
            endTime = this.props.endTime
        } else {
            startTime = this.props.selectedEvent && this.props.selectedEvent.start_time ? this.props.selectedEvent.start_time : ""
            endTime = this.props.selectedEvent && this.props.selectedEvent.end_time ? this.props.selectedEvent.end_time : ""
        }

        this.setState({ metricsStartTime: startTime, metricsEndTime: endTime, eventTime },
            () => this.onLoad()    
        )
        
    }

    onLoad = () => {
        let savedMetrics = {}
        if(this.props.page && this.props.page === "rca") {            
            savedMetrics = this.props.trafficSurgeRcaMonitMertrics
        } else {
            savedMetrics = this.props.trafficSurgeMonitMertrics
        }

        let obj_name = this.props.assetDetails.asset_id
        if(this.props.selectedEvent && this.props.selectedEvent.metric_name) {
            obj_name += "_"+this.props.selectedEvent.metric_name
        }

        if(Object.entries(savedMetrics).length) {
            if(savedMetrics.hasOwnProperty(obj_name)) {
                this.setState({ metricsByTrend: savedMetrics[obj_name], showLoading: false },
                    () => {
                        this.metricsListGraph()
                    }
                )
            } else {
                this.getMonitoringMetricsByTrend()
            }
        } else {
            this.getMonitoringMetricsByTrend()
        }
    }  

    getMonitoringMetricsByTrend = () => {
        if(this.props.assetDetails.asset_id) {
            let params = {}
            params.provider = this.props.selectedEvent && this.props.selectedEvent.provider ? this.props.selectedEvent.provider : 'aws'
            params.anomaly = true
            if(this.props.assetDetails && this.props.assetDetails.asset_id) {
                params.asset_id = this.props.assetDetails.asset_id
            } else {
                params.asset_name = [this.props.assetDetails && this.props.assetDetails.asset_name]
            }
            if(this.props.assetDetails && this.props.assetDetails.service_name) {
                params.service_name = this.props.assetDetails.service_name
            }

            
            params.start_time = this.state.metricsStartTime
            params.end_time = this.state.metricsEndTime

            let obj_name = this.props.assetDetails.asset_id
            if(this.props.selectedEvent && this.props.selectedEvent.metric_name) {
                params.metric_name = this.props.selectedEvent.metric_name
                obj_name += "_"+this.props.selectedEvent.metric_name
            }
            
            this.props.getMonitoringMetricsByTrend(params, (promise, result) => {
                if(promise) {
                    this.setState({ metricsByTrend : [] },
                        () => {
                            this.setState({ metricsByTrend: result, showLoading: false },
                                () => {
                                    this.metricsListGraph()
                                    if(this.props.page && this.props.page === "rca") {
                                        let obj = {}
                                        obj = this.props.trafficSurgeRcaMonitMertrics
                                        obj[obj_name] = result
                                        
                                        this.props.setDiagnosticsPropsDetails('trafficSurgeRcaMonitMertrics', obj)
                                    } else {
                                        let obj = {}
                                        obj = this.props.trafficSurgeMonitMertrics
                                        obj[obj_name] = result
                                        this.props.setDiagnosticsPropsDetails('trafficSurgeMonitMertrics', obj)
                                    }
                                }
                            )
                        }
                    )
                } else {
                    this.setState({ showLoading: false })
                }
            })
        } else {
            this.setState({ showLoading: false })
        }
    }

    metricsListGraph = () => {
        let graphDetails = this.state.metricsByTrend
        if(graphDetails) {
            graphDetails.forEach((graph, index) => {
                let graphData = graph.data
                let graphDates = graph.dates
                            
                Object.entries(graphData).forEach(([key, item]) => {
                    let maxValue = item.statistics && item.statistics.max ? item.statistics.max : 1
                    let avgValue = item.statistics && item.statistics.avg ? item.statistics.avg : 1
                    let minValue = item.statistics && item.statistics.min ? item.statistics.min : 1
                    
                    let graphConvertTo = ""
                    let maxRandomNumber = maxValue
                    let minRandomNumber = minValue
                    if(item.unit) {
                        graphConvertTo = this.getConvertedUnit(maxValue, item.unit)
                        maxRandomNumber = this.getGrapUnitValueWithoutUnit(maxValue, item.unit, "round")
                        minRandomNumber = this.getGrapUnitValueWithoutUnit(minValue, item.unit, "round")
                    }

                    let eventTypesCount = {"alerts": 0, "anomalies": 0, "errors": 0, "issues": 0}
                    let points =  []
                    let events = this.props.selectedEvent && this.props.selectedEvent.events ? this.props.selectedEvent.events : []                

                    if(events && events.length) {
                        events.forEach(evt => {
                            let value = Math.floor(Math.random() * (maxRandomNumber - minRandomNumber))
                            value = parseInt(value) + parseInt(minRandomNumber)
                            let pointRow = {    
                                x: new Date(momentConvertionUtcToLocalTime(evt.event_time, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                                y: value,
                                marker: {
                                    size: 6,
                                    fillColor: (evt.event_type === "alert" || evt.event_type === "alerts") ? "#3F73AD" : evt.event_type === "anomalies" ? "#775BA2" : (evt.event_type === "error" || evt.event_type === "errors") ? "#24A597" : "#FF4560",
                                    strokeColor: (evt.event_type === "alert" || evt.event_type === "alerts") ? "#3F73AD" : evt.event_type === "anomalies" ? "#775BA2" : (evt.event_type === "error" || evt.event_type === "errors") ? "#24A597" : "#FF4560",
                                    radius: 2,
                                    OffsetX: 0,
                                    OffsetY: 0,
                                    cssClass: '',
                                },
                                label: {
                                    // borderColor: "#FF4560",
                                    // offsetY: 0,
                                    // style: {
                                    //     color: "#fff",
                                    //     background: "#FF4560"
                                    // },                
                                    // text: ""
                                }
                            }

                            points.push(pointRow)
                        })

                        let groupByEventType = _.groupBy(events, 'event_type');
                        Object.entries(groupByEventType).forEach(([key, value]) => {
                            eventTypesCount[key] = value.length
                        })
                    }

                    

                    let array = []
                    let graphLabels = []
                    item.values.forEach((val, j) => {
                        graphLabels.push(momentConvertionUtcToLocalTime(graphDates[j], 'YYYY-MM-DD HH:mm:ss'))
                        let arrayValues = []
                        arrayValues.push(momentConvertionUtcToLocalTime(graphDates[j], 'YYYY-MM-DD HH:mm:ss'))
                        if(item.unit) {
                            val = this.getGrapUnitValue(val, item.unit, "", graphConvertTo)
                            arrayValues.push(val)
                        } else {
                            arrayValues.push(val)
                        }       
                        if(item.anomaly) {
                            arrayValues.push(item.anomaly[j])
                        }  

                        array.push(arrayValues)

                        // if(item.anomaly && item.anomaly[j] > 0) {
                        //     let pointRow = {}
                        //     pointRow.x = new Date(momentConvertionUtcToLocalTime(graphDates[j], 'YYYY-MM-DD HH:mm:ss')).getTime()
                        //     pointRow.y = val

                        //     pointRow.marker = {
                        //         size: 3,
                        //         fillColor: "red",
                        //         strokeColor: "red",
                        //         radius: 2
                        //     }
                        //     pointRow.label = {
                        //         borderColor: "#FF4560",
                        //         offsetY: 0,
                        //         style: {
                        //             color: "#fff",
                        //             background: "#FF4560"
                        //         }
                        //         // text: "Point Annotation (XY)"
                        //     }

                        //     points.push(pointRow)
                        // }
                    })

                    if(array.length === 1) {
                        let arrayValues = []
                        arrayValues.push(addHours(momentConvertionUtcToLocalTime(graphDates[0], 'YYYY-MM-DD HH:mm:ss'), 0.5))
                        arrayValues.push(0)
                        array.push(arrayValues)
                    }

                    let series = [{
                        data: array,
                        name: key,
                    }]

                    // let markerSize = 2
                    // if(item.values.length >= 30) {
                    //     markerSize = 0
                    // }

                    let xaxisAnnotation = []
                    if(this.state.showAnnotation && this.state.eventTime !== "") {
                        xaxisAnnotation = [{
                            x: new Date(momentConvertionUtcToLocalTime(this.state.eventTime, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                            strokeDashArray: 0,
                            borderColor: '#FF6F00',
                            label: {
                                borderColor: '#FF6F00',
                                style: {
                                    // fontSize: '12px',
                                    color: '#fff',
                                    background: '#FF6F00'
                                },
                                // orientation: 'horizontal',
                                text: 'Intervention',
                            }
                        }]
                    }
                    
                    let annotations = {
                        points: points,
                        xaxis: xaxisAnnotation
                    }

                    let options = {
                        chart: {
                            // group: 'metrics_and_sre',
                            // id:'metrics_'+i,
                            type: 'line',
                            width: '100%',
                            toolbar: {
                                show: false,
                            },
                            zoom: {
                                enabled: false
                            },
                            sparkline: {
                                enabled: false
                            }
                        },
                        stroke: {
                            show: true,
                            curve: 'smooth',
                            lineCap: 'butt',
                            width: 2,
                            dashArray: 0,      
                        },
                        colors: ['#775BA2'],
                        // fill: {
                        //     type: "gradient",
                        //     gradient: {
                        //         // shadeIntensity: 1,
                        //         // opacityFrom: 0.7,
                        //         // opacityTo: 0.9,
                        //         // stops: [0, 90, 100]
                        //         gradientToColors: ['#775BA2'],
                        //         shadeIntensity: 0,
                        //         opacityFrom: .3,
                        //         opacityTo: 1,
                        //         stops: [30, 90]
                        //     }
                        // },
                        tooltip: {
                            custom: function({series, seriesIndex, dataPointIndex, w}) {
                                let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
                                dateLable = dateLable.toString()
                                dateLable = momentDateGivenFormat(dateLable, 'DD MMM HH:mm')
                                let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]
                                let anomaly = w.globals.initialSeries[seriesIndex].data[dataPointIndex][2];
                                let returnData = '<div class="arrow_box">'
                                returnData += '<div class="small">'+dateLable+' <span style="color:'+ w.globals.colors +'">' + parseFloat(val).toFixed(2) + '</span> </div>'
                                if(anomaly) {
                                    returnData += '<div class="small mt-1"><span class="text-primary-color">Anomaly: </span>True</div>'
                                }
                                returnData += '</div>'
                                return returnData
                            },
                            fixed: {
                                enabled: false
                            },
                            x: {
                                show: false,
                            },
                            y: {
                                show: false
                            },        
                            marker: {
                                show: false
                            }
                        },
                        xaxis: {
                            type: 'datetime',
                            labels: {
                                datetimeUTC: false,
                                style: {
                                    colors: '#60687C'
                                },
                                // formatter: function (value) {
                                //     return momentDateGivenFormat(value, 'HH:mm')
                                // },
                            },                            
                            axisTicks: {
                                show: false
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        yaxis: {
                            show: true,
                            axisTicks: {
                                show: false,
                            },
                            axisBorder: {
                                show: false,
                                color: '#60687C'
                            },
                            tooltip: {
                                enabled: false
                            },
                            // tickAmount: 2,
                            min: 0,
                            // max: yaxisMaxValue, 
                            tickAmount: 1,
                            labels: {
                                offsetX: -10,
                                // minWidth: 50,
                                show: true,
                                style: {
                                    fontSize: '9px',
                                    colors: '#60687C'
                                },
                                // formatter: (value) => { 
                                //     if(Number(value) === value && value % 1 === 0) {
                                //         return Math.ceil(value)
                                //     } else {
                                //         return Math.ceil(value)
                                //     }
                                // },
                                // offsetX: 0,
                                // offsetY: 0,
                            }
                        },
                        grid: {
                            show: true,
                            borderColor: '#2D323E',
                            strokeDashArray: 0,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: true
                                },
                                labels: {
                                    style: {
                                        colors: ['#FFF'],
                                    }
                                },
                                axisTicks: {
                                    show: false
                                }
                            },   
                            yaxis: {
                                lines: {
                                    show: false
                                },
                                labels: {
                                    style: {
                                        colors: ['#969AA7'],
                                    }
                                }
                            },                
                            padding: {
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0
                            },
                        },
                        annotations: annotations,
                    }

                    this.setState({ [key+'_'+graph.asset_id]: series, [key+'_options_'+graph.asset_id]: options, eventTypesCount })
                })
            })
        }
    }    

    getConvertedUnit = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = getConvertedBytesUnit(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                // val = getConvertedSecondsUnit(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                // val = getConvertedBitsUnit(val, unit)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                // val = getConvertedCountFormater(val)
            } else {
                val = ""
            }
        } else if(val !== '') {           
            val = ""
        }
        return val
    }

    getGrapUnitValue = (val ,unit, valueType, convertTo) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseInt(val))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                if(convertTo) {
                    val = convertBytesTo(val, unit, valueType, convertTo)
                } else {
                    val = convertBytes(val, unit, valueType)
                }
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit, valueType, convertTo)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit, valueType, convertTo)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    getGrapUnitValueWithoutUnit = (val ,unit, valueType) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytesWithoutUnit(val, unit, valueType)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSecondsWithoutUnit(val, unit, valueType)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBitsWithoutUnit(val, unit, valueType)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                val = countFormaterWithoutUnit(val, valueType)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseInt(val))
        }
        return val
    }

    render() {
        return (
            !this.state.showLoading ?
                <div class={`bg-dark rounded p-3`}>
                    <div class="d-flex justify-content-between mb-1">
                        <div>
                            <p class="f16 text-white m-0">Metrics</p>
                            {/* {this.state.metricsByTrend && this.state.metricsByTrend.length ?
                                <div className="d-flex">
                                    <p class="f12 text-white mb-0">Key Metrics for {this.props.assetDetails.resource_type ? this.props.assetDetails.resource_type : ''}</p>
                                    <p class="f12 text-white mb-0 ml-2">{this.props.assetDetails.asset_name ? this.props.assetDetails.asset_name : ''}</p>
                                </div>
                            : null} */}
                        </div>
                        <div className="d-flex">
                            <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{"("+momentConvertionUtcToLocalTime(this.state.metricsStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentConvertionUtcToLocalTime(this.state.metricsEndTime, 'DD MMM YYYY HH:mm')+")"}</p>
                        </div>
                    </div>
                    {(this.state.metricsByTrend && this.state.metricsByTrend.length) || (this.state.sreMetricsDetails && this.state.sreMetricsDetails.length) ?
                        this.state.metricsByTrend && this.state.metricsByTrend.length ?
                            this.state.metricsByTrend.map((item, index) => {
                                return(
                                Object.keys(item.data).map(key => {
                                    return (
                                        <div className={`bg-dark3 rounded p-2`}>
                                            <p class="text-white f16 mb-0">
                                                {key} 
                                                <span className="ml-2 text-purple-2 small">{item.id_name_map.value}</span>
                                                {item.id_name_map.key ?
                                                    <span className="mb-0 ml-2 text-purple-2 small">
                                                        {item.id_name_map.key}
                                                    </span>
                                                : null}
                                            </p>
                                            {this.state[key+'_options_'+item.asset_id] && Object.keys(this.state[key+'_options_'+item.asset_id]).length && this.state[key+'_'+item.asset_id] && this.state[key+'_'+item.asset_id].length ?
                                                <div className="cursorPointer transparentTooltip mb-n4 mt-n3">
                                                    <Chart options={this.state[key+'_options_'+item.asset_id]} series={this.state[key+'_'+item.asset_id]} type="line" height={180} width={'100%'}/>
                                                    {this.state.eventTypesCount ?
                                                        <ul className="stock-legend mt-n2 d-flex justify-content-center">
                                                            <li><span className="legend-circle bg-alerts"></span> <span className="text-muted d-inline-block"> &nbsp;Alerts: {this.state.eventTypesCount.alerts}</span></li> 
                                                            <li><span className="legend-circle bg-anomalies"></span> <span className="text-muted d-inline-block"> &nbsp; Anomalies: {this.state.eventTypesCount.anomalies}</span></li> 
                                                            <li><span className="legend-circle bg-errors"></span> <span className="text-muted d-inline-block"> &nbsp; Errors: {this.state.eventTypesCount.errors}</span></li>
                                                            <li><span className="legend-circle bg-issues"></span> <span className="text-muted d-inline-block"> &nbsp; Issues: {this.state.eventTypesCount.issues}</span></li>
                                                        </ul>
                                                    : null}
                                                </div>
                                            : null}
                                        </div>
                                    )
                                })
                                )
                            })
                        : null
                    : 
                        <div className={`bg-dark3 rounded p-2`}>
                            <p class="text-white f16 mb-2">
                                {this.props.selectedEvent && this.props.selectedEvent.metric_name ? this.props.selectedEvent.metric_name : ""} 
                                <span className="ml-2 text-purple-2 small">{this.props.assetDetails && this.props.assetDetails.asset_name}</span>
                            </p>
                            <div className='d-flex justify-content-center m-4'>
                                No Metrics for the this criteria, Please adjust your filter to get the metric details.
                            </div>
                        </div>
                    }
                </div>
            : 
                <div className={`bg-dark rounded p-3`}>
                    <p className={`mb-2 text-white`}><b>Metrics</b></p>
                    <div className='d-flex justify-content-center m-4'>
                        <Spinner className='text-center' color='white' size='lg' />
                    </div>
                </div>
        )
    }
}

/**
* Type of the props used in the component
*/
TrafficMetrics.propTypes = {
    getSreDetails: PropTypes.func,
    getMonitoringMetricsByTrend: PropTypes.func,
    setDiagnosticsPropsDetails: PropTypes.func
}   

const mapStateToProps = state => {
    // console.log("traffic surge", state)
    return {
        trafficSurgeMonitMertrics: state.aiops.diagnosticsPropsDetails && state.aiops.diagnosticsPropsDetails.trafficSurgeMonitMertrics ? state.aiops.diagnosticsPropsDetails.trafficSurgeMonitMertrics : {},

        trafficSurgeRcaMonitMertrics: state.aiops.diagnosticsPropsDetails && state.aiops.diagnosticsPropsDetails.trafficSurgeRcaMonitMertrics ? state.aiops.diagnosticsPropsDetails.trafficSurgeRcaMonitMertrics : {},
    }
}

export default connect(mapStateToProps, {
    getSreDetails,
    getMonitoringMetricsByTrend,
    setDiagnosticsPropsDetails
})(withRouter(TrafficMetrics))