/*************************************************
 * Tvastar
 * @exports
 * @file SidePanel.js
 * @author Prakash // on 28/06/2020
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { momentConvertionUtcToLocalTime , capitalizeFirstLetter} from '../../../utils/utility'
import Chart from 'react-apexcharts'


class ApexAreaChart extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    
    componentDidMount = () => {
        let graphData = this.props.graphData;
        if(graphData === 'mockData') {
            graphData = {}
            graphData.data = [45,67,34,71,41,93,64,77,36,34,44,10,1,3,91,45,32,3,37,21,65,45,38,59,16,3,66,85,30,62,49]
            graphData.labels = ["2021-05-12 00:00:00", "2021-05-13 00:00:00", "2021-05-14 00:00:00", "2021-05-15 00:00:00", "2021-05-16 00:00:00", "2021-05-17 00:00:00", "2021-05-18 00:00:00", "2021-05-19 00:00:00", "2021-05-20 00:00:00", "2021-05-21 00:00:00", "2021-05-22 00:00:00", "2021-05-23 00:00:00", "2021-05-24 00:00:00", "2021-05-25 00:00:00", "2021-05-26 00:00:00", "2021-05-27 00:00:00", "2021-05-28 00:00:00", "2021-05-29 00:00:00", "2021-05-30 00:00:00", "2021-05-31 00:00:00", "2021-06-01 00:00:00", "2021-06-02 00:00:00", "2021-06-03 00:00:00", "2021-06-04 00:00:00", "2021-06-05 00:00:00", "2021-06-06 00:00:00", "2021-06-07 00:00:00", "2021-06-08 00:00:00", "2021-06-09 00:00:00", "2021-06-10 00:00:00", "2021-06-11 00:00:00"]
        }
        if(graphData && Object.entries(graphData).length) {
            this.drawGraph(graphData)
        }
    }

    drawGraph = (graphData) => {
        let graphLables = graphData.labels
        let axisColors = []
        let data = []
        let labels = []
        for(let x=0;x<graphLables.length;x++) {
            axisColors.push('#969AA7')
            labels.push(momentConvertionUtcToLocalTime(graphLables[x], 'DD MMM Y'))
            data.push(graphData.data[x] ? graphData.data[x] : 0)
        }
        let series = [
            {
                name: this.props.name ? this.props.name : 'New',
                data
            }
        ]

        let annotations = {}
        if(this.props.annotations) {
            let xaxisAnnotaions = []
            if(this.props.annotationToShow) {
                let borderColor = ''
                let background = ''
                let color = ''
                let text = ''
                let location = ''
                this.props.annotationToShow.forEach(item => {
                    text = item.value
                    if(item.value === 'S') {
                        borderColor = '#FF6F00'
                        background = '#FF6F00'
                        color = '#fff'
                        location = labels[10]
                    } else if(item.value === 'C') {
                        borderColor = '#24A597'
                        background = '#24A597'
                        color = '#fff'
                        location = labels[18]
                    }  else if(item.value === 'E') {
                        borderColor = '#F44336'
                        background = '#F44336'
                        color = '#fff'
                        location = labels[25]
                    } 

                    let dataRow = {
                        x: location,
                        strokeDashArray: 0,
                        borderColor: borderColor,
                        opacity: 1,
                        label: {
                            borderColor: borderColor,
                            orientation: 'horizontal',
                            text: text,
                            style: {
                                color: color,
                                background: background,
                                fontSize: '10px',
                                // width: '10px',
                            },
                        }
                    }
                    xaxisAnnotaions.push(dataRow)
                })
            }
            annotations = {
                xaxis: xaxisAnnotaions
            }
        }

        let fillData = {}
        if(this.props.gradient) {
            fillData = {
                type: "gradient",
                gradient: {
                    // shadeIntensity: 1,
                    // opacityFrom: 0.7,
                    // opacityTo: 0.9,
                    // stops: [0, 90, 100]
                    gradientToColors: [this.props.gradientColor ? this.props.gradientColor[0] : '#bee9de'],
                    shadeIntensity: 0,
                    opacityFrom: .3,
                    opacityTo: 1,
                    stops: [50, 90]
                }
            }
        }

        let markerSize = this.props.markerSize
        if(this.props.hideMarkerLength && graphLables.length >= this.props.hideMarkerLength ) {
            markerSize = 0
        }

        let options = {
            chart: {
                height: 350,
                type: 'area',
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: this.props.zoom,
                },
                sparkline: {
                    enabled: this.props.sparkline
                },
            },
            colors: this.props.colors,
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 1,
            },
            xaxis: {
                show: this.props.xaxis,
                categories: labels,
                labels: {
                    show: this.props.annotations ? this.props.annotations : this.props.xaxis,
                    style: {
                        fontSize: this.props.annotations ? '0px' : '9px'
                    },
                },
                axisBorder: {
                    show: true,
                    // color:'#434959',
                    color: '#343947',
                    height: 2,
                    width: '100%',
                    offsetX: 0,
                    offsetY: 0
                },
                axisTicks: {
                    show: false
                },
                tooltip: {
                    enabled: false
                }
            },
            
            yaxis: {
                show: this.props.yaxis,
                axisBorder: {
                    show: true,
                    // color:'#434959',
                    color: '#343947',
                    widht: 2,
                    offsetX: -15,
                    // offsetY: 0
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: this.props.yaxisLabe
                },
            },
            tooltip: {
                enabled: true,
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    let val  = series[seriesIndex][dataPointIndex];
                    let labelName = w.globals.categoryLabels[dataPointIndex]
                    let name = w.globals.seriesNames[seriesIndex]
                    return '<div class="arrow_box"> '+ labelName +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  capitalizeFirstLetter(name) + '  '+ val + '</span> </div>'
                },
            },
            
            fill: fillData,
            grid: {
                show: this.props.grid,
                borderColor: '#343947',
                strokeDashArray: 0,
                position: 'back',
                xaxis: {
                    lines: {
                        show: true
                    },
                    labels: {
                        style: {
                            colors: ['#969AA7'],
                        }
                    },  
                },   
                yaxis: {
                    lines: {
                        show: false
                    },
                    labels: {
                        style: {
                            colors: axisColors,
                        }
                    }
                },                
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: -2
                },
            },
            markers: {
                size: markerSize,
                colors: this.props.colors,
                strokeColors: this.props.colors,
            },
                        
            annotations: annotations,
        }

        this.setState({ series, options })
    }

    render() {
        return(
            this.state.series ?
                <div className={`${this.props.className}`}>
                    <Chart options={this.state.options} series={this.state.series} type="area" height={this.props.height} width={this.props.width} />
                </div>
            : null
        )
    }
}
 
/**
 * Type of the props used in the component
 */
ApexAreaChart.propTypes = {}

const mapStateToProps = state => {}

export default connect(mapStateToProps, {})(withRouter(ApexAreaChart))