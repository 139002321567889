/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 10/04/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 **************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
////import Chart from 'chart.js'
import { UncontrolledPopover, PopoverBody, Spinner } from 'reactstrap'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import _ from 'lodash'
import { AppWrapper } from '../../common/AppWrapper'
import { listAllProviders, getAllTagsKeys, getAllTagsValues } from '../../../actions/commonAction'

import { setCostPropsDetails, costGetCostTrend, getCostFilters, getCostFilterValues, listCostTemplates } from '../../../actions/cost/CostAction'

import moment from 'moment'
import { capitalizeAllLetter, capitalizeFirstLetter, currentUTCtime, getDayFromSelectedDuration, momentConvertionLocalToUtcTime, momentDateGivenFormat, removeSpacebtwString, subDays, subHours } from '../../../utils/utility'

import { RangePicker } from "react-minimal-datetime-range";
import "react-minimal-datetime-range/lib/react-minimal-datetime-range.min.css";

import ApexStackedBarChart from '../../common/charts/ApexStackedBarChart';

import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';
import { CSVLink } from 'react-csv';
import Search from '../../common/SearchComponent';
import SavedFiltersSidePanel from './SavedFiltersSidePanel';

class LandingPage extends Component {
	dateRef = React.createRef()
	tagRef = React.createRef()	
	//serviceField = React.createRef()
	
	constructor(props) {
		super(props)		
		this.tabRef = React.createRef()
		this.searchRef = React.createRef()

		this.state = {
			showLoading: true,
			minMaxHeight: "770px",
			durationActive: true,
			selectedDuration: "+30d",
			colors: ["#DEC9E9", "#D2B7E5", "#B185DB", "#7251B5", "#FFE169", "#FAD643", "#DBB42C", "#A47E1B", "#99E2B4", "#88D4AB", "#67B99A", "#358F80", "#FFE0E9", "#FF9EBB", "#E05780", "#8A2846"],
			transColor: ["#9A77D1", "#2D323E"]
		}
	}

	componentDidMount = () => {
		this.getWindowSize()

		window.addEventListener('resize', () => {
			this.getWindowSize()
		})

		
        if(this.props.providers && this.props.providers.length) {
			this.setState({ providers: this.props.providers, selectedProvider: this.props.providers[0].provider_name.toLowerCase() },
				() => this.getCostFilters()
			)
		} else {
			this.props.listAllProviders((promise, providers) => {
				if (promise) {
					this.setState({ providers, selectedProvider: providers.length ? providers[0].provider_name.toLowerCase() : "" },
						() => this.getCostFilters()
					)
				} else {
					this.setState({ providers: []})
				}
			})
		}
				
		this.durationFunction()
	}

	getWindowSize = () =>  {
		const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 180
				
		let outerHeight = (window.outerHeight - browserBorder - headerHeight ) + "px"

		this.setState({ outerHeight, minMaxHeight: outerHeight })
	}

	listCostTemplates = () => {
		let params = {}
		params.source = this.state.selectedSource
		this.props.listCostTemplates(params, (promise, response) => {
			if(promise) {
				this.setState({ listData: response, filteredListData: response, searchResultState: response })
			} else {
				this.setState({ listData: [], filteredListData: [] })
			}
		})
	}

	listTemplateSearch = () => {
		let results = this.state.listData
		if (this.state.tempSearchText && this.state.tempSearchText.length > 2) {
			results =
				results &&
				results.filter(item => {
					let isPresent = []
					isPresent = this.recursiveSearch(item, this.state.tempSearchText)
						.flat()
						.filter(bool => bool === true)
					if (isPresent[0]) {
						return true
					} else {
						return false
					}
				})
		}

		this.setState({ searchResultState: results })
	}

	// function to search every object in array by recurseive
	recursiveSearch = (item, searchValue) => {
		return Object.keys(item).map(key => {
			if (item[key] !== null && typeof item[key] == 'object') {
				return this.recursiveSearch(item[key], searchValue)
				// eslint-disable-next-line valid-typeof
			} else if (typeof item[key] === 'array') {
				return item.map((arrayElement, i) => this.recursiveSearch(arrayElement[i], searchValue))
			} else {
				// if(this.props.searchFilter.includes(key)){
				return item[key] !== null
					? item[key]
							.toString()
							.toLowerCase()
							.indexOf(searchValue.toString().toLowerCase()) > -1
					: false
				// }else{
				// return false;
				// }
			}
		})
	}

	clearFilter = () => {
		let costFilters = this.state.costFilters

		costFilters && Object.entries(costFilters).forEach(([cKey, cValue], cIndex) => {
			if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
				if(cValue.default) {
					this.setState({ ["input_change_"+cIndex]: cValue.default })
				} else {
					this.setState({ ["input_change_"+cIndex]: "" })
				}
			} else {
				this.setState({ ["includes_"+cIndex]: "Includes" })
				if(cValue.output_type === "string") {
					this.setState({ ["input_change_"+cIndex]: "" })
				} else {
					this.setState({ ["input_change_"+cIndex]: [] })
				}
			}

			if(Object.entries(costFilters).length - 1 === cIndex) {
				this.setState({ 
					durationActive: true,
					selectedDuration: "+30d",
					selectedFilters: []
				},
					() => {
						this.costGetCostTrend()
						this.durationFunction()
						this.formSelectedFitlers()
					}
				)
			}
		})
	}

	getCostFilters = () => {
		let selectedProvider = this.state.selectedProvider
		let params = {}
		this.props.getCostFilters(params, (promise, response) => {
			if (promise) {
				let resResult = {}
				let selectedSource = ""
				if(selectedProvider && response[selectedProvider]) {
					let results = response[selectedProvider]
					if(results && Object.entries(results).length) {
						resResult = results[Object.keys(results)[0]]
						selectedSource = Object.keys(results)[0]
					}
				}

				this.setState({ costFiltersResponse: response, costFilters: resResult, selectedSource },
					() => {
						this.structureCostFilterResponse()	
						this.listCostTemplates()
						this.onChangeProvider()
					}
				)
			} else {
				this.setState({ costFilters: [] })
			}
		})
	}

	onChangeProvider = () => {
		let costFilters = this.state.costFilters

		costFilters && Object.entries(costFilters).forEach(([cKey, cValue], cIndex) => {
			if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
				if(cValue.default) {
					this.setState({ ["input_change_"+cIndex]: cValue.default })
				} else {
					this.setState({ ["input_change_"+cIndex]: "" })
				}
			} else {
				this.setState({ ["includes_"+cIndex]: "Includes" })
				if(cValue.output_type === "string") {
					this.setState({ ["input_change_"+cIndex]: "" })
				} else {
					this.setState({ ["input_change_"+cIndex]: [] })
				}
			}

			if(Object.entries(costFilters).length - 1 === cIndex) {
				this.setState({
					showLoading: true,
					selectedFilters: []
				},
					() => {
						this.formProviderChangeData()
					}
				)
			}
		})
	}

	formProviderChangeData = () => {
		let selectedProvider = this.state.selectedProvider
		let costSources = this.state.costFiltersResponse[selectedProvider] ? this.state.costFiltersResponse[selectedProvider] : {}
        
		let resResult = {}
		let selectedSource = ""
		if(costSources && Object.entries(costSources).length) {
			resResult = costSources[Object.keys(costSources)[0]]
			selectedSource = Object.keys(costSources)[0]
		}

		this.setState({ costSources, costFilters: resResult, selectedSource },
			() => {
				this.structureCostFilterResponse()
				this.listCostTemplates()
			}
		)
	}

	onChangeSource = () => {
		let selectedProvider = this.state.selectedProvider
		let resResult = {}

		let results = this.state.costFiltersResponse[selectedProvider] ? this.state.costFiltersResponse[selectedProvider] : {}
		if(results && Object.entries(results)) {
			resResult = results[this.state.selectedSource]
		}

		this.setState({ costFilters: resResult },
		    () => {
				this.structureCostFilterResponse()
			    this.listCostTemplates()
			}	
		)
	}

	structureCostFilterResponse = () => {
		let costFilters = this.state.costFilters
		costFilters && Object.entries(costFilters).forEach(([cKey, cValue], cIndex) => {
			if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
				if(cValue.default) {
					this.setState({ ["input_change_"+cIndex]: cValue.default })
				}
			} else {
				this.setState({ ["includes_"+cIndex]: "Includes" })
			}

			if(Object.entries(costFilters).length - 1 === cIndex) {
				this.setState({ showLoading: false },
					() => {
						this.costGetCostTrend()
						this.formSelectedFitlers()
					}
				)
			}
		})		
	}

	costGetCostTrend = () => {
		let params = {}
		params.provider = this.state.selectedProvider
		params.source = this.state.selectedSource
		params.start_time = this.state.datePickerStartDate
		params.end_time = this.state.datePickerEndDate
		if(this.state.forecast) {
			params.forecast = this.state.forecast
		}
		// if(!this.state.durationActive) {
		// } else {
		// 	params.duration = this.state.selectedDuration
		// }
		// params.start_time = "" 
		// params.end_time = ""
		// params.duration_aggregate_by = "daily"
		// params.aggregate_by = ["region"]		
		let excludes = {}
		let groupBy = ""
		this.state.costFilters && Object.entries(this.state.costFilters).map(([cKey, cValue], cIndex) => {
			if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
				if(this.state["input_change_"+cIndex] && this.state["input_change_"+cIndex] !== "") {
					if(cValue.output_field === "aggregate_by") {
						Object.entries(cValue.mapped_values).length && Object.entries(cValue.mapped_values).forEach(([mKey, mValue]) => {
							if(this.state["input_change_"+cIndex] === mValue) {
								groupBy = mKey
							}
						})
					}
					params[cValue.output_field] = this.state["input_change_"+cIndex]
				}
			} else {
				if(this.state["selected_value_"+cIndex] && this.state["selected_value_"+cIndex].length) {
					if(this.state["includes_"+cIndex] === "Includes") {
						params[cValue.output_field] = this.state["selected_value_"+cIndex]
					} else {
						excludes[cValue.output_field] = this.state["selected_value_"+cIndex]
					}
				}
			}
		})

		if(excludes && Object.entries(excludes).length) {
			params.excludes = excludes
		}
		if(params.aggregate_by && params.aggregate_by !== "") {
			params.radial = true				
		}
		
		let granualrity = ""
		if(params.duration_aggregate_by && params.duration_aggregate_by !== "") {
			granualrity = params.duration_aggregate_by
		}

		// console.log('----------costGetCostTrend--------------',params)
		this.props.costGetCostTrend(params, (promise, response) => {
			if (promise) {
				this.setState({ trendData: response.results ? response.results : [], applyLoading: false, trendGraphData: {}, groupBy, granualrity, showLoading: false },
					() => {
						this.trendGraphStructure()	
						this.trendTableData()
					}
				)
			} else {
				this.setState({ trendData: [], trendGraphData: {}, showLoading: false, applyLoading: false })
			}
		})
	}

	trendGraphStructure = () => {
        let graphData = {}
		let labels = []
		let forcastMessage = ""
        this.state.trendData && this.state.trendData.forEach(item => {
			console.log("item", item)
            if(item.data && Array.isArray(item.data)) {
				let spend = []
			    let forecast = []
			    item.dates.forEach((lab, index) => {
				    labels.push(lab)
				    spend.push(item.data[index])
					forecast.push(0)
			    })
				if(item.forecast && item.forecast.dates) {
				    item.forecast && item.forecast.dates.forEach((lab, index) => {					
				        labels.push(lab)
				        spend.push(0)
					    forecast.push(item.forecast.data[index])
				    })
				}
				graphData.labels = labels
				graphData.spend = spend
				if(item.forecast && item.forecast.dates) {
				    graphData.forecast = forecast
				}
				if(item.forecast_message) {
					forcastMessage = item.forecast_message
				}
			} else if(item.data && !Array.isArray(item.data) && Object.entries(item.data).length) {
				graphData.labels = item.dates ? item.dates : []
				labels.push()
				Object.entries(item.data).forEach(([key, value]) => {
					graphData[key] = value
				})
				if(item.forecast_message) {
					forcastMessage = item.forecast_message
				}
			} else if(item.data && !Array.isArray(item.data) && !Object.entries(item.data).length) {
				graphData.labels = item.dates ? item.dates : []
				let data = []
				graphData.labels.forEach(row => {
					data.push(0)
				})
				if(item.forecast_message) {
					forcastMessage = item.forecast_message
				}
				graphData["cost"] = data
			} else {
				graphData["cost"] = item.data
				if(item.forecast_message) {
					forcastMessage = item.forecast_message
				}
			}
        })
		
        this.setState({ trendGraphData: graphData, forcastMessage })
    }

	trendTableData = () => {
		var trendTable = []
		this.state.trendData && this.state.trendData.forEach(item => {
            let labels = item.dates ? item.dates : []
            if(item.data && !Array.isArray(item.data) && Object.entries(item.data).length) {
				Object.entries(item.data).forEach(([key, value]) => {
					let totalCount = value.reduce(function(a, b){
						return a + b;
					}, 0);
					var result = {
						"service": key,
						'total': item.count && item.count[key] ? item.count[key] : 0
					}
				
					value.forEach((item,index) => {
						result[labels[index]] = item
					})
					trendTable.push(result);
				})
			} else if(item.data && Array.isArray(item.data)) {

				let data = item.data
				// let totalCount = item.data.reduce(function(a, b){
				// 	return a + b;
				// }, 0);
				var result = {
					"service": "Cost",
					'total': item.total
				}
				
				data.forEach((item,index) => {
					if(momentDateGivenFormat(labels[index], "YYYY-MM-DD") <= momentDateGivenFormat(currentUTCtime(), "YYYY-MM-DD")) {
						result[labels[index]] = item
					}
				})
				
				if(item.forecast && item.forecast.dates) {
				    item.forecast && item.forecast.dates.forEach((lab, index) => {					
				        result[lab] = item.forecast.data[index]
				    })
				}

				trendTable.push(result);
			}
		})


		if(trendTable.length) {
			const columns = Object.entries(trendTable[0]).map(([tKey, tValue]) =>{
				let sticky = false
				let header = ''
				let width = '100%'
				let textColor = ''
				// if(this.state.durationSelector > 7) {
				// 	width = 75
				// }
				if(tKey === 'service') {
					sticky = true
					header = this.state.groupBy
					width = 300
				} else if(tKey === 'total') {
					sticky = true
					header = 'Total'
					width = 75
				} else {
					if(momentDateGivenFormat(tKey, "YYYY-MM-DD") > momentDateGivenFormat(currentUTCtime(), "YYYY-MM-DD")) {
						textColor = "text-info"
					}
					if(this.state.granualrity === 'monthly') {
						header = moment(tKey).format('MMM YY')
						width = 75
					} else  if(this.state.granualrity === 'daily') {
						header = moment(tKey).format('MMM DD')
						width = 75
					} else  {
						header = moment(tKey).format('HH mm')
						width = 75
					}
				}
				return {
					Header: header,
					accessor: tKey,
					width: width,
					sticky: sticky ? "left" : "",
					textColor: textColor
				}
			})
			console.log("columns", columns)
			this.setState({ trendTable, filteredArray: trendTable, columns },
				() => this.formDownloadData()	
			)
		} else {
			const columns = []	
			this.setState({ trendTable, filteredArray: trendTable, columns })
		}
	}

    formDownloadData = () => {
        let headers = [] 
        Object.keys(this.state.filteredArray[0]).forEach(item => {
            let dataRow = {}
            dataRow.label = item
            dataRow.key = item
            headers.push(dataRow)
        })

        this.setState({ headers })
    }


	//on change filter items

    handleSelect = (date) => {
        let datePickerStartDate = momentDateGivenFormat(date[0], "YYYY-MM-DD 00:00:00")
		let datePickerEndDate = momentDateGivenFormat(date[1], "YYYY-MM-DD 23:59:59")
        this.setState({ datePickerStartDate, datePickerEndDate, showDateRangePicker: false, durationActive: false })
	}

	durationFunction = (type) => {
        let duration = this.state.selectedDuration
        let period = duration.substring(duration.length, duration.length-1)
        let durationBtw = getDayFromSelectedDuration(duration)
        let datePickerStartDate = momentDateGivenFormat(subHours(new Date(), durationBtw), 'YYYY-MM-DD 00:00:00')
        if(period === "d") {
            datePickerStartDate = momentDateGivenFormat(subDays(new Date(), durationBtw), 'YYYY-MM-DD 00:00:00')
        }
        let datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')

        this.setState({ datePickerStartDate, datePickerEndDate },
			() => this.formSelectedFitlers()
		)
    }

	getAllTagsKeys = () => {		
		if(!this.props.tagKeys || !this.props.tagKeys.length) {
			let params = {}
			// params.account_id = "265469181489"
			// params.region = "us-east-2"
			this.props.getAllTagsKeys(params, (promise, response) => {
				this.setState({ showTagKeyLoading: !this.state.showTagKeyLoading })	
			})
		} else {
			this.setState({ showTagKeyLoading: !this.state.showTagKeyLoading })
		}
	}

	getAllTagsValues = () => {
		let params = {}
		params.tags = [{ "key": this.state.tag_key }]
		this.props.getAllTagsValues(params, (promise, response) => {
			if(promise) {
				this.setState({ tagValues: response },
					() => {
						if(this.state.tagsSelected && this.state.tagsSelected.filter(e => e.key === this.state.tag_key).length) {
							let value = this.state.tagsSelected.filter(e => e.key === this.state.tag_key).map(e => e.value)
							this.handleMultiSelectChange("tag_value", value, this.state.tagValues)
						}
					}	
				)
			}
		})
	}

	addNewTag = () => {
		let tagsSelected = this.state.tagsSelected ? this.state.tagsSelected : []

		if(this.state.tag_key && this.state.tag_key !== "") {
			let filteredTags = tagsSelected.filter(e => e.key !== this.state.tag_key)
			this.state.tag_value.forEach(row => {
				let dataRow = {
					key: this.state.tag_key,
					value: row,
				}
				filteredTags.push(dataRow)
			})

			this.setState({ tagsSelected: filteredTags, tag_key: "",  tag_value: "", tagValues: [] },
				() => this.formSelectedFitlers()
			)
		}
	}

	removeTagSection = (tag) => {
		// filteredResult = this.state.tagsSelected.filter(e => e.key !== tag.key && e.value !== tag.value)
		let filteredResult = this.state.tagsSelected.filter(e => e !== tag)
		this.setState({ tagsSelected: filteredResult },
			() => this.formSelectedFitlers()
		)
	}

	onClickGetDropOptions = (cKey, cValue, cIndex) => {
		let params = {}
		if(this.state.selectedSource) {
			params.source = this.state.selectedSource
		}
		this.state.costFilters && Object.entries(this.state.costFilters).map(([cKey, cValue], cIndex) => {
			if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
				if(this.state["input_change_"+cIndex] && this.state["input_change_"+cIndex] !== "") {
					if(cValue.output_field === "duration_aggregate_by") {
						params[cValue.output_field] = this.state["input_change_"+cIndex]
					}
				}
			}
		})
		
		// if(!this.state.durationActive) {
		// 	params.start_time = momentConvertionLocalToUtcTime(this.state.datePickerStartDate, "YYYY-MM-DD 00:00:00")
		// 	params.end_time = momentConvertionLocalToUtcTime(this.state.datePickerEndDate, "YYYY-MM-DD 23:59:59")
		// } else {
		// 	params.duration = this.state.selectedDuration
		// }

		params.aggregate_by = cValue.output_field

		params.start_time = this.state.datePickerStartDate
		params.end_time = this.state.datePickerEndDate
		// params.radial = true

		// removeSpacebtwString
		if(!cValue.hasOwnProperty("mapped_values") && !cValue.hasOwnProperty("values")) {
			if(!this.props.costFilterValues.hasOwnProperty(this.state.selectedSource+"_"+cValue.output_field)) {
				this.props.getCostFilterValues(params, (promise, response) => {
					if(promise) {
						let results = response.results ? response.results : []
						this.setState({ 
							["hideDropLoading_"+cIndex]: true, 
							["dropOptions_"+cIndex]: results, 
							["filteredDropOptions_"+cIndex]: results
						})
						// if(results.length) {
						// 	let obj = this.props.costFilterValues
						// 	obj[this.state.selectedSource+"_"+cValue.output_field] = results
						// 	this.props.setCostPropsDetails("costFilterValues", obj)
						// }
					}
				})
			} else {
				this.setState({ 
					["hideDropLoading_"+cIndex]: true, 
					["dropOptions_"+cIndex]: this.props.costFilterValues[this.state.selectedSource+"_"+cValue.output_field], 
					["filteredDropOptions_"+cIndex]: this.props.costFilterValues[this.state.selectedSource+"_"+cValue.output_field]
				})
			}
		}

		this.state.costFilters && Object.entries(this.state.costFilters).map(([loopKey, loopValue], loopIndex) => {
			if(loopIndex !== cIndex) {
				this.setState({ ["showDetails_"+loopIndex]: false })
			} else {
				this.setState({ ["showDetails_"+cIndex]: !this.state["showDetails_"+cIndex] })
			}
		})

		this.setState({ showDateRangePicker: false, showTagFilter: false })
	}

    searchData  = (e, searchKey, cIndex, state) => {
        let newList = []
        if (searchKey !== "" && searchKey.length >=3 ) {
            let currentList = state
            newList =
                currentList &&
                currentList.filter(item => {
                    let isPresent = []
                    if(typeof item === "string") {
                        return item !== null
                            ? item
                                    .toString()
                                    .toLowerCase()
                                    .indexOf(searchKey.toString().toLowerCase()) > -1
                            : false
                    } else {
                        isPresent = this.recursiveSearch(item, searchKey)
                            .flat()
                            .filter(bool => bool === true)
                        if (isPresent[0]) {
                            return true
                        } else {
                            return false
                        }
                    }
                    
                })
        } else {
            newList = state
		}
        
        this.setState({ ["filteredDropOptions_"+cIndex]: newList })
    }

	onChangeSelectInput = (state, value, field, index, cIndex) => {
		this.setState({ [field+"_"+cIndex]: value },
			() => this.formSelectedFitlers()
		)
	}

	onChangeMultiSelectInput = (state, value, field, index, cIndex) => {
		let selectedValue = this.state["selected_value_"+cIndex] ? this.state["selected_value_"+cIndex] : []
		if(value !== "All") {
			if(this.state[field+"_"+cIndex+"_"+index]) {
				selectedValue = selectedValue.filter(e => e !== value)
				this.setState({ ["select_all_"+cIndex]: false, [field+"_"+cIndex+"_"+index]: false, ["selected_value_"+cIndex]: selectedValue },
					() => this.formSelectedFitlers()
				)
			} else {
				selectedValue.push(value)
				this.setState({ [field+"_"+cIndex+"_"+index]: true, ["selected_value_"+cIndex]: selectedValue },
					() => this.formSelectedFitlers()
				)
			}
		} else {
			if(this.state["select_all_"+cIndex]) {
				let selectedValue = []
				state.forEach((row, rIndex) => {
					this.setState({ ["select_all_"+cIndex]: false, [field+"_"+cIndex+"_"+rIndex]: false, ["selected_value_"+cIndex]: selectedValue },
						() => this.formSelectedFitlers()
					)
				})
			} else {
				let selectedValue = []
				state.forEach((row, rIndex) => {
					selectedValue.push(row)
					this.setState({ ["select_all_"+cIndex]: true, [field+"_"+cIndex+"_"+rIndex]: true, ["selected_value_"+cIndex]: selectedValue },
						() => this.formSelectedFitlers()
					)
				})
			}

		}
	}

	formSelectedFitlers = () => {
		let selectedFilters = []
		
		if(this.state.datePickerStartDate) {
			let dataRow = {}
			dataRow.label = "Duration"
			dataRow.start_time = this.state.datePickerStartDate
			dataRow.end_time = this.state.datePickerEndDate

			selectedFilters.push(dataRow)
		}

		if(this.state.tagsSelected && this.state.tagsSelected.length) {
			let dataRow = {}
			dataRow.label = "Tags"
			dataRow.output_field = "tags"
			dataRow.value = this.state.selectedTags

			selectedFilters.push(dataRow)
		}

		this.state.costFilters && Object.entries(this.state.costFilters).map(([cKey, cValue], cIndex) => {
			let dataRow = {}
			if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
				if(this.state["input_change_"+cIndex] && this.state["input_change_"+cIndex] !== "") {
					let outputLabel = this.state["input_change_"+cIndex]
					if(Object.entries(cValue.mapped_values).length) {
						outputLabel = Object.keys(cValue.mapped_values).find(key => cValue.mapped_values[key] === this.state["input_change_"+cIndex]);	
					}
					dataRow.label = cKey
					dataRow.output_label = outputLabel
					dataRow.output_field = cValue.output_field
					dataRow.value = this.state["input_change_"+cIndex]
				}
			} else {
				if(this.state["selected_value_"+cIndex] && this.state["selected_value_"+cIndex].length) {
					dataRow.label = cKey
					dataRow.output_field = cValue.output_field
					dataRow.type = this.state["includes_"+cIndex]
					dataRow.value = this.state["selected_value_"+cIndex]
				}
			}
			if(Object.entries(dataRow).length) {
				selectedFilters.push(dataRow)
			}
		})

		this.setState({ selectedFilters })
	}

	handleMultiSelectChange = (field, arrayValue, state) => {
		let value = arrayValue.map(item => item.value ? item.value : item)
		let selectedValue = []
		let prevState = this.state[field] ? this.state[field] : []

		if(value.includes("All")) {
			if(!prevState.includes("All")) {
				if(state.filter(e => e.value).length) {
					selectedValue = state.map(e => e.value)
				} else {
					selectedValue = state
				}
			} else {
				const index = value.indexOf("All");
				if (index > -1) {
					value.splice(index, 1);
				}
				selectedValue = value
			}
		} else if(!prevState.includes("All")) {
			selectedValue = value
		}

		if(selectedValue.length && !selectedValue.includes("All") && state.includes("All")) {
			if(selectedValue.length === state.length -1) {
				selectedValue.push("All")
			}
		}

		this.setState({ [field]: selectedValue })
	}

	getMultiSelectedCount = (type, array) => {
		return array.length && array.includes("All") ? "All" :  array.length ? array.length +" Selected" : "All"
	}

	//on change filter items

	handleClickOutside(event) {		

		if (this.searchRef && !this.searchRef.current.contains(event.target)) {
			this.setState({ showDropdown: false })
		}	

		if (this.dateRef && this.dateRef.current &&  !this.dateRef.current.contains(event.target)) {
			this.setState({ showDateRangePicker: false })
		}

		if (this.tagRef && this.tagRef.current &&  !this.tagRef.current.contains(event.target)) {
			this.setState({ showTagFilter: false })
		}
	}

	refreshFilters = (row) => {
		let savedFilters = row.filters
		if(row["Duration"]) {
			this.setState({ 
				datePickerStartDate: row["Duration"].start_time,
				datePickerEndDate: row["Duration"].end_time
			})
		}

		if(row["Tags"]) {
			this.setState({ selectedTags: row["Tags"].value, tagsSelected: row["Tags"].value })
		}


		let costFilters = this.state.costFilters

		costFilters && Object.entries(costFilters).forEach(([cKey, cValue], cIndex) => {
			if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
				if(savedFilters.filter(e => e.output_field === cValue.output_field).length) {
					this.setState({ ["input_change_"+cIndex]: savedFilters.filter(e => e.output_field === cValue.output_field)[0].value })
				} else {
					this.setState({ ["input_change_"+cIndex]: "" })
				}
			} else {
				if(savedFilters.filter(e => e.output_field === cValue.output_field).length) {
					this.setState({ 
						["input_change_"+cIndex]: savedFilters.filter(e => e.output_field === cValue.output_field)[0].value, 
						["includes_"+cIndex]: savedFilters.filter(e => e.output_field === cValue.output_field)[0].type
					})
				} else {
					if(cValue.output_type === "string") {
						this.setState({ ["input_change_"+cIndex]: "" })
					} else {
						this.setState({ ["includes_"+cIndex]: "Includes" })
						this.setState({ ["input_change_"+cIndex]: [] })
					}
				}
			}

			if(Object.entries(costFilters).length - 1 === cIndex) {
				this.setState({ 
					durationActive: false,
					selectedFilters: []
				},
					() => {
						this.costGetCostTrend()
						this.formSelectedFitlers()
					}
				)
			}
		})
	}

	onClickMore = (row) => {
		this.setState({ savedFilters: row, templateType: "view" },
			() => {
				this.setState({ showTemplatesSection: true })	
			}
		)

	}

	/**
	 * Renders User Insights
	 */
	render() {
		return (
			<div className='container-fluid overflow-auto flex-grow-1 bg-muted' onClick={ (event) => { this.handleClickOutside(event) } }>
				<div className={`loaderOverlay ${this.state.showLoading ? "" : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
				{this.state.showTemplatesSection ?
					<SavedFiltersSidePanel
						closeSidePanel={() => this.setState({ showTemplatesSection: false, savedFilters: [] }, () => this.listCostTemplates())}
						templateType={this.state.templateType}
						selectedSource={this.state.selectedSource}
						selectedFilters={this.state.savedFilters && Object.entries(this.state.savedFilters).length ? this.state.savedFilters : this.state.selectedFilters}
					/>
				: null}
				<div className='row h-100'>
					<div className='col-sm-12 pl-0'>
						<div className='title-section' style={{padding: "1rem 1rem 1rem 1rem"}} >
							<div className='row'>
								<div className='col-lg-4 d-flex align-self-center'>
									<div>
										<div className='d-flex'>
											<h6 className='text-white m-0'>Cost Explorer</h6>
										</div>
										<p className='text-white m-0'>
											New cost and usage report
										</p>
									</div>
								</div>
								<div className={`col-lg-8 text-right`}>
									<div className='d-flex justify-content-end'>
									    <div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: '150px' }}>
                                            <label className="mb-1">Provider</label>
                                            <Select
                                                placeholder={'Select'}
                                                isSearchable={false}
                                                // menuIsOpen={true}
                                                className='f13 p-0 colorBlack reactSelectFilterDrop'
                                                value={({
                                                    value: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : 'Select',
                                                    label: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider.toUpperCase() : <span className="placeholder">Select</span>
                                                })}
                                                options={this.state.providers && this.state.providers.map(row => ({
													value: row.provider_name,
													label: row.provider_name,	
												}))}
                                                onChange={event => this.setState({ selectedProvider: event.value.toLowerCase() }, () => this.onChangeProvider())}
                                            />
                                        </div>
										<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: '150px' }}>
                                            <label className="mb-1">Source</label>
                                            <Select
                                                placeholder={'Select'}
                                                isSearchable={false}
                                                // menuIsOpen={true}
                                                className='f13 p-0 colorBlack reactSelectFilterDrop'
                                                value={({
                                                    value: this.state.selectedSource && this.state.selectedSource !== "" ? this.state.selectedSource : 'Select',
                                                    label: this.state.selectedSource && this.state.selectedSource !== "" ? capitalizeAllLetter(this.state.selectedSource) : <span className="placeholder">Select</span>
                                                })}
                                                options={this.state.costSources && Object.keys(this.state.costSources).map(row => ({
													value: row,
													label: capitalizeAllLetter(row),
												}))}
                                                onChange={event => this.setState({ selectedSource: event.value }, () => this.onChangeSource())}
                                            />
                                        </div>
									</div>
								</div>
							</div>
						</div>
						<div className="m-2 d-flex">
							<div className='ml-2 w-60'>
								<p className='font-weight-bold m-0 f15'>Trends</p>
								<p className="mb-0 small text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
								{this.state.forcastMessage && this.state.forcastMessage !== "" ?
									<h6 className='text-danger mb-1'>{this.state.forcastMessage}</h6>
								: null}
								{!this.state.showSlectedFilters ?
									<div className='d-flex pt-2 justify-content-between'>
										<div className='d-flex'>
											{this.state.selectedFilters && this.state.selectedFilters.length ? 
												<React.Fragment>
													{this.state.selectedFilters.filter(e => e.label === "Account").length ?
														<div className='d-flex mr-2'>
															<small className="font-weight-bold align-self-center cursorPointer">Account : {this.state.selectedFilters.filter(e => e.label === "Account")[0].value} </small>
															{/* {this.state.selectedFilters.filter(e => e.label === "Account").length > 1 ?
																<span className="text-info mx-2 mt-2 align-self-center f10 px-2 cursorPointer" onClick={ () => this.setState({ showSlectedFilters: !this.state.showSlectedFilters })}>more</span>
															: null} */}
														</div>
													: 
														<small className="font-weight-bold align-self-center cursorPointer mr-2">Account : <span className='text-info'>All</span></small>
													}	

													{this.state.selectedFilters.filter(e => e.label === "Region").length ?
														<div className='d-flex mx-2'>
															<small className="font-weight-bold align-self-center cursorPointer">Region : {this.state.selectedFilters.filter(e => e.label === "Region")[0].value} </small>
															{/* {this.state.selectedFilters.filter(e => e.label === "Region")[0].value.length > 1 ?
																<span className="text-fino mx-2 mt-2 align-self-center f10 px-2 cursorPointer" onClick={ () => this.setState({ showSlectedFilters: !this.state.showSlectedFilters })}>more</span>
															: null} */}
														</div>
													: 
														<small className="font-weight-bold align-self-center cursorPointer mx-2">Region : <span className='text-info'>All</span></small>
													}
													{this.state.selectedFilters.filter(e => e.label === "Granularity").length ?
														<div className='d-flex ml-2'>
															<small className="font-weight-bold align-self-center cursorPointer mr-2">Granularity : <span className="text-info">{this.state.selectedFilters.filter(e => e.label === "Granularity")[0].output_label ? this.state.selectedFilters.filter(e => e.label === "Granularity")[0].output_label : this.state.selectedFilters.filter(e => e.label === "Granularity")[0].value}</span> </small>
														</div>
													: null}
													{this.state.selectedFilters.filter(e => e.label === "Group by").length ?
														<div className='d-flex ml-2'>
															<small className="font-weight-bold align-self-center cursorPointer mr-2">Group by : <span className="text-info">{this.state.selectedFilters.filter(e => e.label === "Group by")[0].output_label ? this.state.selectedFilters.filter(e => e.label === "Group by")[0].output_label : this.state.selectedFilters.filter(e => e.label === "Group by")[0].value}</span> </small>
														</div>
													: null}
													{this.state.selectedFilters.filter(e => e.label === "Duration").length ?
														<div className='d-flex ml-2'>
															<small className="font-weight-bold align-self-center cursorPointer mr-2">Duration : <span className='text-info'> {momentDateGivenFormat(this.state.selectedFilters.filter(e => e.label === "Duration")[0].start_time, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(this.state.selectedFilters.filter(e => e.label === "Duration")[0].end_time, 'DD MMM YYYY')}</span> </small>
														</div>
													: null}
												</React.Fragment>
											: null}
										</div>
										{this.state.selectedFilters && this.state.selectedFilters.length ? 
											<small className="text-blue font-weight-bold align-self-center cursorPointer mr-2 ml-4" onClick={ () => this.setState({ showSlectedFilters: !this.state.showSlectedFilters })}>{this.state.selectedFilters.length} Filters Selected</small>
										: null}
									</div>
								: null}
								{this.state.showSlectedFilters ?
									<div className="bg-dark3 p-3">
										<div className='d-flex justify-content-between mb-1'>
											<p className="mb-0 w-100 text-white">Selected Filters</p>
											<i className="far fa-times align-self-center cursorPointer f18 mb-1" onClick={() => this.setState({ showSlectedFilters: false })}></i>
										</div>
										{/* {this.state.datePickerStartDate ? 
											<div className='d-flex'>
												<p className="mb-1 mr-2">Date</p>
												<span className='text-info'> {momentDateGivenFormat(this.state.datePickerStartDate, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(this.state.datePickerEndDate, 'DD MMM YYYY')}</span>
											</div>
										: null}
										
										{this.state.tagsSelected && this.state.tagsSelected.length ? 
											<div className=''>
												<p className="mb-1">Tags</p>
												{this.state.tagsSelected.map((tag, index) => {
													return(
														<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">{tag.key +" : "+tag.value}
															<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeTagSection(tag) } ></i>
														</span>
													)
												})}
											</div>
										: null} */}
										<div className='d-flex flex-wrap'>
											{!this.state.selectedFilters.filter(e => e.label === "Account").length ?
												<small className="font-weight-bold align-self-center cursorPointer mr-2 mt-2">Account : <span className='text-info'>All</span></small>
											: null}

											{!this.state.selectedFilters.filter(e => e.label === "Region").length ?
												<small className="font-weight-bold align-self-center cursorPointer mx-2 mt-2">Region : <span className='text-info'>All</span></small>
											: null}
											
											{this.state.selectedFilters && this.state.selectedFilters.length ? 
												this.state.selectedFilters.map((filters, index) => {
													return(
														filters.label === "Duration" ?
															<React.Fragment>
																<small className="font-weight-bold align-self-center cursorPointer mt-2 mr-2">Duration : <span className='text-info'> {momentDateGivenFormat(filters.start_time, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(filters.end_time, 'DD MMM YYYY')}</span> </small>
															</React.Fragment>
														: filters.value && !Array.isArray(filters.value) ?
															<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2"><span className='text-primary-color'>{filters.label+" : "}</span>{filters.output_label ? filters.output_label : filters.value}</span>
														: filters.label === "Tags" ?
															<div className='d-flex'>
																{filters.value.map(row => {
																	return(
																		<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																			<span className='text-primary-color'>Tag :</span> 
																			{row.key +" : "+row.value}
																		</span>
																	)
																})}
															</div>
														:
															<div className='d-flex'>
																{filters.value.map(row => {
																	return(
																		<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																			<span className='text-primary-color'>{filters.type+" : "}</span> 
																			{filters.label +" : "+row}
																		</span>
																	)
																})}
															</div>
													)
												})
											: null}
										</div>
									</div>
								: null}
							</div>
							<div className='ml-2 w-40 align-self-center'>
								<div className='d-flex justify-content-end mt-2'>
									<span className='text-info cursorPointer mt-3' onClick={() => this.setState({ showTemplatesSection: true, templateType: "template" })}>View Templates</span>
								</div>
								<div className="input-group search-drop dark-feild mt-1">
									<div className="input-group-prepend">
										<div className="input-group-text bg-transparent">
											<i className="fal fa-search"></i>
										</div>
									</div>
									<input
										ref={this.searchRef}
										type='text'
										value={this.state.tempSearchText}
										onChange={e => {
											this.setState({ tempSearchText: e.target.value, searchResultState: [], isLoading: true }, () => this.listTemplateSearch())
										}}
										className='form-control form-control-sm'
										placeholder='Search'
										onClick={(e => this.setState({ showDropdown: true }))}
									/>
									
									<div className={`search-suggestion bg-dark3`}>
										{this.state.showDropdown &&
											this.state.searchResultState && this.state.searchResultState.length ? 
											this.state.searchResultState.map((row, index) => {
												return (
													<div key={index} className="cursorPointer border-bottom p-2" >
														<div className="w-100" onClick={() => this.refreshFilters(row)}>
															<span className='font-weight-bold m-0 text-white text-left p-0'>{row.template_name ? row.template_name : ''}</span>
															<span className='small m-0 text-white text-left p-0 text-primary-color'>{row.description ? " : "+row.description : ''}</span>
														</div>
														<div className='d-flex justify-content-between'>
															<div className="d-flex flex-wrap mt-2 w-100 align-self-center text-primary-color" onClick={() => this.refreshFilters(row)}>
																{Array.isArray(row.filters) && row.filters.filter(e => e.label === "Account").length ?
																	<div className='d-flex mr-2'>
																		<small className="font-weight-bold align-self-center cursorPointer">Account : {row.filters.filter(e => e.label === "Account")[0].value} </small>
																		{row.filters.filter(e => e.label === "Account").length > 1 ?
																			<span className="text-info mx-2 mt-2 align-self-center f10 px-2 cursorPointer" onClick={ () => this.setState({ showSlectedFilters: !this.state.showSlectedFilters })}>more</span>
																		: null}
																	</div>
																: 
																	<small className="font-weight-bold align-self-center cursorPointer mr-2">Account : <span className='text-info'>All</span></small>
																}	

																{Array.isArray(row.filters) && row.filters.filter(e => e.label === "Region").length ?
																	<div className='d-flex mx-2'>
																		<small className="font-weight-bold align-self-center cursorPointer">Region : {row.filters.filter(e => e.label === "Region")[0].value} </small>
																		{row.filters.filter(e => e.label === "Region")[0].value.length > 1 ?
																			<span className="text-fino mx-2 mt-2 align-self-center f10 px-2 cursorPointer" onClick={ () => this.setState({ showSlectedFilters: !this.state.showSlectedFilters })}>more</span>
																		: null}
																	</div>
																: 
																	<small className="font-weight-bold align-self-center cursorPointer mx-2">Region : <span className='text-info'>All</span></small>
																}
																{Array.isArray(row.filters) && row.filters.filter(e => e.label === "Granularity").length ?
																	<div className='d-flex ml-2'>
																		<small className="font-weight-bold align-self-center cursorPointer mr-2">Granularity : <span className="text-info">{row.filters.filter(e => e.label === "Granularity")[0].output_label ? row.filters.filter(e => e.label === "Granularity")[0].output_label : row.filters.filter(e => e.label === "Granularity")[0].value}</span> </small>
																	</div>	
																: null}
																{Array.isArray(row.filters) && row.filters.filter(e => e.label === "Group by").length ?
																	<div className='d-flex ml-2'>
																		<small className="font-weight-bold align-self-center cursorPointer mr-2">Group by : <span className="text-info">{row.filters.filter(e => e.label === "Group by")[0].output_label ? row.filters.filter(e => e.label === "Group by")[0].output_label : row.filters.filter(e => e.label === "Group by")[0].value}</span> </small>
																	</div>
																: null}
																{row["Duration"] ?
																	<div className='d-flex ml-2'>
																		<small className="font-weight-bold align-self-center cursorPointer mr-2">Duration : <span className='text-info'> {momentDateGivenFormat(row["Duration"].start_time, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(row["Duration"].end_time, 'DD MMM YYYY')}</span> </small>
																	</div>
																: null}
															</div>
															{Array.isArray(row.filters) && row.filters.length > 3 ?
																<span className="badge badge-outline-info mr-2 align-self-center f10 px-2" onClick={() => this.onClickMore(row)}>more</span>
															: null}
														</div>
														{/* <div className="w-20 align-self-center">
															<span className="badge badge-outline-success mr-1">123</span>
															<span className="badge badge-outline-danger">123</span>
														</div> */}
														{/* <div className="w-10 align-self-center">
															<i class={`fal fa-star ${item.favourite ? 'fillStar' : 'displayNone' } `}></i>
														</div> */}
													</div>
												)
											})
										: 
											this.state.showDropdown && this.state.isLoading && (
												<div className='d-flex justify-content-center m-4'>
													<Spinner className='text-center' color='dark' size='sm' />
												</div>
											) 
										}
									</div>
								</div>
							</div>
						</div>
						<div className='d-flex mx-2'>
							<div className={`${this.state.hideFilterPanel ? "w-95" : "w-80"} pl-0 mr-2 overflowYAuto`} style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
								<div className='bg-dark d-flex border flex-column minHeight300 p-3'>
									{this.state.trendData && this.state.trendData[0] ?
										<div className='d-flex'>
											<div className='w-50'>
												<p className='mb-0'>Total Cost</p>
												<p className='mb-0 f20 text-white'>
													${this.state.trendData[0].total && this.state.trendData[0].total}
												</p>
											</div>
											<div className='w-50 border-left pl-2'>
												<p className='mb-0'>Average Cost</p>
												<p className='mb-0 f20 text-white'>
													${this.state.trendData[0].avg && this.state.trendData[0].avg}
												</p>
											</div>
										</div>
									: null}
									{this.state.trendGraphData && Object.entries(this.state.trendGraphData).length ?
										<ApexStackedBarChart
											tooltipType={"dontConvertDate"}
											graphData={this.state.trendGraphData}
											sparkline={false}
											yaxis={true}
											yaxisLabel={true}
											xaxis={true}
											xaxisFormat={this.state.granualrity === "monthly" ? "category" : this.state.granualrity === "hourly" ? "datetime" : 'date'}
											xaxisLabel={true}
											axisLabelFontSize={'9px'}
											axisLabelColor={'#B8BBBE'}
											paddingLeft={-25}
											legend={true}
											stacked={true}
											height={450}
											horizontal={false}
											barHeight={'40%'}
											barEndShape={'flat'}
											columnWidth={this.state.granualrity === "monthly" ? '5%' :  ""}
											gradient={true}
											gradientColor={['#039BE5', '#5F5BA2']}
											hideTooltipValue={true}
											backgroundBarShape={'flat'}
											backgroundBarColors={['#333947']}
											showBackgroundBarColors={true}
											className={"transparentTooltip"}
											// colors={["#EDDCD2", "#FFF1E6", "#FDE2E4", "#FAD2E1", "#C5DEDD", "#DBE7E4", "#F0EFEB", "#D6E2E9", "#BCD4E6", "#99C1DE", "#CDB4DB"]}
											colors={this.state.forecast && !this.state.forcastMessage ? this.state.transColor : this.state.colors}
										/>
									: null}
								</div>
								{this.state.trendTable ?
									<div className="mt-3">
										<div className="d-flex justify-content-between mt-3 py-2">
											<p className="mb-0 align-self-center">Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.state.trendTable && this.state.trendTable.length} Cost and usage breakdown</p>
											{this.state.trendTable && this.state.trendTable.length ?
												<div className="d-flex">
													<CSVLink
														data={this.state.filteredArray ? this.state.filteredArray : []} 
														headers={this.state.headers ? this.state.headers : []}
														filename={"cost-explorers.csv"}
														className={"mt-2"}
														target="_blank"
													>
														<span className="d-flex text-primary-color mr-2">
															<i className="fas fa-download text-primary-color mr-2"></i>
															{/* csv */}
														</span>
													</CSVLink>
													<div className={`mb-n2`}>
														<Search
															data={this.state.trendTable ? this.state.trendTable : []}
															filteredData={filteredArray => {
																this.setState({ filteredArray })
															}}
														/>
													</div>
												</div>
											: null}
										</div>
										<div className='stickyTable'>
											<ResizeableDarkThemeTable
												data={this.state.filteredArray.length ? this.state.filteredArray : this.state.filteredArray}
												columns = { this.state.columns }
												onClickRow={tableRow => {}}
												dashboard = {this.state.filteredArray.length ? true : false}
												tooltipFor={[]}
												marginRight={10}
											/>
										</div>
									</div>
								: null}
							</div>
							<div className={`${this.state.hideFilterPanel ? "w-5" : "w-20"} bg-dark p-3 overflowYAuto`} style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
								<div className="d-flex justify-content-between mb-2">
									<p className="font-weight-bold mb-1 f14" onClick={() => this.setState({ hideFilterPanel: !this.state.hideFilterPanel })}>Filters</p>								
										{/* {this.state.selectedFilters && this.state.selectedFilters.length ? 
											<small className="text-blue font-weight-bold align-self-center cursorPointer mr-2" onClick={ () => this.setState({ showSlectedFilters: !this.state.showSlectedFilters })}>Selected Filters</small>										
										: null} */}
										{this.state.hideFilterPanel ?
											<i className="fa fa-caret-left align-self-center cursorPointer f18 mb-1" onClick={() => this.setState({ hideFilterPanel: false })}></i>
										: 
											<div className='d-flex'>
												<small className="text-blue font-weight-bold align-self-center cursorPointer mr-2" onClick={ () => this.setState({ showTemplatesSection: !this.state.showTemplatesSection, templateType: "save" })}>Save Template</small>
												<i className="far fa-times align-self-center cursorPointer f18 mb-1" onClick={() => this.setState({ hideFilterPanel: true })}></i>
											</div>
										}
								</div>
								{!this.state.hideFilterPanel ?
									<React.Fragment>
									<div className="d-flex mb-2 justify-content-between">
										{this.state.applyLoading ?
											<button className="btn btn-success">
												Applying <Spinner size='sm' color='light' className='ml-2' />
											</button>
										: 
											<button className={`d-flex justify-content-end cursorPointer btn-sm ${this.state.applyLoading ? 'disabled btn-primary' : 'btn-primary'}`} 
											onClick={ () => this.setState({ applyLoading: true }, () => this.costGetCostTrend())}>Apply</button>
										}
										<small className="text-blue font-weight-bold align-self-center f13 cursorPointer" onClick={ () => this.clearFilter()}>CLEAR ALL</small>
									</div>
									{this.state.showSlectedFilters1 ?
										<div className="bg-dark3 p-3 position-absolute" style={{"z-index": "999999999","right": "20px","minHeight": "230px", "height":"auto", "width": "600px"}}>
											<div className='d-flex justify-content-between'>
												<p className="mb-2 w-100 text-white">Selected Filters</p>
												<i className="far fa-times align-self-center cursorPointer f18 mb-1" onClick={() => this.setState({ showSlectedFilters: false })}></i>
											</div>

											{this.state.datePickerStartDate ? 
												<div className=''>
													<p className="mb-1">Date</p>
													<span> {momentDateGivenFormat(this.state.datePickerStartDate, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(this.state.datePickerEndDate, 'DD MMM YYYY')}</span>
												</div>
											: null}
											
											{this.state.tagsSelected && this.state.tagsSelected.length ? 
												<div className=''>
													<p className="mb-1">Tags</p>
													{this.state.tagsSelected.map((tag, index) => {
														return(
															<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">{tag.key +" : "+tag.value}
																<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeTagSection(tag) } ></i>
															</span>
														)
													})}
												</div>
											: null}
											{this.state.selectedFilters && this.state.selectedFilters.length ? 
												this.state.selectedFilters.map((filters, index) => {
													return(
														filters.label === "Duration" ?
															<React.Fragment>
																<small className="font-weight-bold align-self-center cursorPointer mt-2 mr-2">Duration : <span className='text-info'> {momentDateGivenFormat(filters.start_time, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(filters.end_time, 'DD MMM YYYY')}</span> </small>
															</React.Fragment>
														: filters.value && !Array.isArray(filters.value) ?
															<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">{filters.label +" : "+filters.value}</span>
														: filters.label === "Tags" ?
															<div className='d-flex'>
																{filters.value.map(row => {
																	return(
																		<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																			<span className='text-primary-color'>Tag :</span> 
																			{row.key +" : "+row.value}
																		</span>
																	)
																})}
															</div>
														:
															<div className='d-flex'>
																{filters.value.map(row => {
																	return(
																		<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																			<span className='text-primary-color'>{filters.type+" : "}</span> 
																			{filters.label +" : "+row}
																		</span>
																	)
																})}
															</div>
													)
												})
											: null}
											
											{/* <div className='d-flex justify-content-end pt-2 mt-2 border-top'>
												<button className={`btn-sm bg-light text-decoration-nonep-2 text-black mr-3`} onClick={() => this.setState({ showSlectedFilters: false })}>Cancel</button>
											</div> */}
										</div>
									: null}
									<div className="w-100 mb-2">
										<div className="d-flex w-100">
											<div 
												className={`form-control f14 bg-muted text-white`}
												readOnly={true}
												onClick={() => this.setState({ showDateRangePicker: !this.state.showDateRangePicker, showTagKeyLoading: true }, () => this.getAllTagsKeys())}
											>
												<div className='d-flex justify-content-between'>
													{this.state.datePickerStartDate ?
														<span> {momentDateGivenFormat(this.state.datePickerStartDate, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(this.state.datePickerEndDate, 'DD MMM YYYY')}</span>
													: null}
													{this.state.showDateRangePicker ?
														<i className="fa fa-caret-up"></i>
													: 
														<i className="fa fa-caret-down"></i>
													}
												</div>
											</div>

											{this.state.showDateRangePicker ?
												<div className="bg-dark3 p-3 position-absolute" style={{"z-index": "999999999","right": "20px","height": "530px"}} ref={this.dateRef}>
													<div className="RangePickerWithStartEndTime text-black5 pt-2 ml-n1">
														<div className='d-flex justify-content-between'>
															<p className="mb-2 w-100 text-white">Date Picker</p>
															<i className="far fa-times align-self-center cursorPointer f18 mb-1" onClick={() => this.setState({ showDateRangePicker: false })}></i>
														</div>
														<RangePicker
															// locale={`en-us`} // default is en-us
															show={true} // default is false
															disabled={false}
															allowPageClickToClose={false}
															placeholder={["Start Time", "End Time"]}
															onConfirm={(res) => this.handleSelect(res)}
															onClose={() => console.log("onClose")}
															onClear={() => console.log("onClear")}
															style={{ width: "610px", margin: "0 auto" }}
														/>
														<div className={`d-flex flex-wrap pt-2 border-top`}>
															<span className={`mr-2 f12 align-self-center text-white`}>Last</span>
															<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === "+7d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => this.setState({ showDateRangePicker: false, durationActive: true, selectedDuration: "+7d" }, () => this.durationFunction())}>1 Week</span>
															<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === "+14d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => this.setState({ showDateRangePicker: false, durationActive: true, selectedDuration: "+14d" }, () => this.durationFunction())}>2 Week</span>
															<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === "+30d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => this.setState({ showDateRangePicker: false, durationActive: true, selectedDuration: "+30d" }, () => this.durationFunction())}>1 Month</span>
															<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === "+60d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => this.setState({ showDateRangePicker: false, durationActive: true, selectedDuration: "+60d" }, () => this.durationFunction())}>2 Month</span>
															<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === "+90d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => this.setState({ showDateRangePicker: false, durationActive: true, selectedDuration: "+90d" }, () => this.durationFunction())}>3 Months</span>
														</div>
														<div className='d-flex justify-content-end pt-2 mt-2 border-top'>
															<button className={`btn-sm bg-light text-decoration-nonep-2 text-black mr-3`} onClick={() => this.setState({ showDateRangePicker: false })}>Cancel</button>
														</div>
													</div>
												</div>
											: null}
										</div>
									</div>

									<div className="w-100 mb-2">
										<div className="d-inline-block w-100">
											<div 
												className={`form-control f14 bg-muted text-white`}
												readOnly={true}
												onClick={() => this.setState({ showTagFilter: !this.state.showTagFilter, showTagKeyLoading: true }, () => this.getAllTagsKeys())}
											>
												<div className='d-flex justify-content-between'>
													{this.state.selectedTags && this.state.selectedTags.length ?
														<span>Tags {this.state.selectedTags.length} selected</span>
													: 
														<span>Choose Tags</span>
													}
													{this.state.showTagFilter ?
														<i className="fa fa-caret-up"></i>
													: 
														<i className="fa fa-caret-down"></i>
													}
												</div>
											</div>
											{this.state.showTagFilter ?
												<div className="containerFilterSection costPopupTagSection border mt-1" style={{right: "20px"}} ref={this.tagRef}>
													<div className="p-3 bg-muted">
														<div className={`w-100 mt-3`}>
															<p className="mb-2 w-100 text-white">Tags</p>
															{!this.state.showTagKeyLoading ?
																<React.Fragment>
																<div className={`d-flex`}>
																	<label className="mb-1 w-40">Key</label>
																	<label className="mb-1 w-40 ml-3">Value</label>
																</div>
																<div className={`w-100`}>
																	<div className={`d-flex mt-3`}>
																		<Select
																			isSearchable={true}
																			components={{
																				IndicatorSeparator: () => null
																			}}
																			className={`selectOption mr-2 mt-2`}
																			value={({
																				value: this.state.tag_key,
																				label: this.state.tag_key && this.state.tag_key !== "" ? this.state.tag_key : <span className="placeholder">Key</span>
																			})}														
																			
																			options={this.props.tagKeys && this.props.tagKeys.map(item => ({
																				value: item,
																				label: item,	
																			}))}
																			onChange={event => this.setState({
																				tag_key: event.value,
																				tagValues: [],
																			},
																				() => this.getAllTagsValues()
																			)}
																		/>
																		{this.state.tag_key && this.state.tag_key !== "" && !this.state.tagValues.length ?
																			<div className='ml-3'>
																				<Spinner className='text-center align-self-center' color='white' size='sm' />
																			</div>
																		:
																			<React.Fragment>
																				<div className={`multiSelectOption align-self-center mr-2 z998`} style={{marginTop: "1.3rem"}}>
																					<ReactMultiSelectCheckboxes
																						placeholderButtonLabel="Select"
																						getDropdownButtonLabel={() => this.getMultiSelectedCount("tag_value", this.state.tag_value ? this.state.tag_value : [])}
																						options={this.state.tagValues && this.state.tagValues.map(row => ({
																							value: row,
																							label: row,
																						}))}
																						onChange={arr => { this.handleMultiSelectChange("tag_value", arr ? arr : [], this.state.tagValues) }}
																						value={this.state.tag_value && this.state.tag_value.map(acc => ({
																							value: acc
																						}))}
																					/>
																				</div>
																				<span className={`far fa-plus cursorPointer f18 align-self-center ml-4`} onClick={() => this.addNewTag("tags")}></span>
																			</React.Fragment>
																		}
																	</div>
																</div>
																</React.Fragment>
															:
																<div className='d-flex justify-content-center m-4'>
																	<Spinner className='text-center' color='white' size='sm' />
																</div>
															}
														</div>
														{this.state.tagsSelected && this.state.tagsSelected.length ? 
															this.state.tagsSelected.map((tag, index) => {
																return(
																	<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">{tag.key +" : "+tag.value}
																		<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeTagSection(tag) } ></i>
																	</span>
																)
															})
														: null}
														<div className="d-flex mt-3">
															<span className={`text-info mr-2 align-self-center cursorPointer`} onClick={() => this.setState({ tagsSelected: [], selectedTags: [] })}>Clear</span>
															<button className={`btn btn-sm btn-light cursorPointer`} onClick={() => this.setState({ showTagFilter: false, selectedTags: this.state.tagsSelected }, () => this.formSelectedFitlers())}>Select</button>
														</div>
													</div>
												</div>
											: null}
										</div>
									</div>
									<div claassName="zapInputDark">
										{this.state.costFilters && Object.entries(this.state.costFilters).map(([cKey, cValue], cIndex) => {
											return(
												<div className="w-100 mb-2">
													<div className="position-relative d-inline-block w-100">
														<div 
															className={`form-control f14 bg-muted text-white`}
															readOnly={true}
															onClick={() => this.onClickGetDropOptions(cKey, cValue, cIndex)}
														>
															<div className='d-flex justify-content-between'>
																{this.state["selected_value_"+cIndex] && Array.isArray(this.state["selected_value_"+cIndex]) && this.state["selected_value_"+cIndex].length ?
																	this.state["selected_value_"+cIndex].length +" "+cKey
																:!this.state["selected_value_"+cIndex] || this.state["selected_value_"+cIndex] && Array.isArray(this.state["selected_value_"+cIndex]) && !this.state["selected_value_"+cIndex].length ?
																	"Choose "+cKey																
																: this.state["selected_value_"+cIndex] ?
																	this.state["selected_value_"+cIndex]
																: null}
																{/* {this.state["selected_value_"+cIndex] ?  this.state["selected_value_"+cIndex] :  "Choose "+cKey} */}
																{this.state["showDetails_"+cIndex] ?
																	<i className="fa fa-caret-up"></i>
																: 
																	<i className="fa fa-caret-down"></i>
																}
															</div>
														</div>

														{this.state["showDetails_"+cIndex] ?
															cValue.mapped_values && Object.entries(cValue.mapped_values).length ?
																<div className='bg-dark3' style={{border: "3px solid #383E4D"}}>
																	{Object.entries(cValue.mapped_values).map(([mRow, mValue], mIndex) => {
																		return(
																			<div className={`d-flex flex-wrap pt-2 pb-1 cursorPointer ${this.state["input_change_"+cIndex] === mValue ? "bg-white" : "" } ${Object.entries(cValue.mapped_values).length - 1 !== mIndex ? "border-bottom" : ""}`}>
																				<div style={{ display: "inline" }} className={`mr-2`}>
																					<div className="form-check">
																						<p className={`form-check-label mb-0 mr-2  ${this.state["input_change_"+cIndex] === mValue ? "text-info" : "text-white" }`} onClick={() => this.onChangeSelectInput(cValue.mapped_values, mValue, "input_change", mIndex, cIndex)}>{mRow}</p>
																					</div>
																				</div>
																			</div>
																		)
																	})}
																</div>
															: cValue.mapped_values && !Object.entries(cValue.mapped_values).length && cValue.values ?
																<div className='bg-dark3' style={{border: "3px solid #383E4D"}}>
																	{cValue.values.map((vRow, vIndex) => {
																		return(
																			<div className={`d-flex flex-wrap pt-2 pb-1 cursorPointer ${this.state["input_change_"+cIndex] === vRow ? "bg-white" : "" } ${Object.entries(cValue.mapped_values).length - 1 !== vIndex ? "border-bottom" : ""}`}>
																				<div style={{ display: "inline" }} className={`mr-2`}>
																					<div className="form-check">
																						<p className={`form-check-label mb-0 mr-2  ${this.state["input_change_"+cIndex] === vRow ? "text-info" : "text-white" }`} onClick={() => this.onChangeSelectInput(cValue.values, vRow, "input_change", vIndex, cIndex)}>{vRow}</p>
																					</div>
																				</div>
																			</div>
																		)
																	})}
																</div>
															: cValue.output_type === "string" ?
																<div className='bg-dark3' style={{border: "3px solid #383E4D"}}>
																	{this.state["filteredDropOptions_"+cIndex].map((row, sIndex) => {
																		return(
																			<div className={`d-flex flex-wrap pt-2 pb-1 cursorPointer ${this.state["input_change_"+cIndex] === row ? "bg-white" : "" } ${this.state["filteredDropOptions_"+cIndex].length - 1 !== sIndex ? "border-bottom" : ""}`}>
																				<div style={{ display: "inline" }} className={`mr-2`}>
																					<div className="form-check">
																						<p className={`form-check-label mb-0 mr-2  ${this.state["input_change_"+cIndex] === row ? "text-info" : "text-white" }`} onClick={() => this.onChangeSelectInput(cValue.values, row, "input_change", sIndex, cIndex)}>{row}</p>
																					</div>
																				</div>
																			</div>
																		)
																	})}
																</div>
															:
																<div className='bg-dark3' style={{border: "3px solid #383E4D"}}>
																	<div style={{ display: "inline" }} className="mb-1">
																		<div className="form-check ml-2">
																			<input type="radio"
																				onChange={() => this.setState({ ["includes_"+cIndex]: "Includes" }) }
																				className="form-check-input"
																				checked={this.state["includes_"+cIndex] === "Includes" ? true : false}
																			/>
																			<p className="form-check-label mb-0 mr-2 text-white">Includes</p>
																		</div>
																	</div>
																	<div style={{ display: "inline" }} className="mb-2 ">
																		<div className="form-check ml-2">
																			<input type="radio"
																				onChange={() => this.setState({ ["includes_"+cIndex]: "Excludes" }) }
																				className="form-check-input"
																				checked={this.state["includes_"+cIndex] === "Excludes" ? true : false}
																			/>
																			<p className="form-check-label mb-0 text-white">Excludes</p>
																		</div>
																	</div>
																	{this.state["hideDropLoading_"+cIndex]  ?
																		this.state["dropOptions_"+cIndex] ?
																			<React.Fragment>
																			<div contenteditable="true" 
																				className={`form-control contentEditable text-white h-auto f14 bg-muted mt-2`}
																				placeholder="search..."
																				id={"search_"+cIndex}
																				onKeyUp={e => this.searchData(e, e.currentTarget.textContent, cIndex, this.state["dropOptions_"+cIndex])}
																			></div>
																			<div className={`topAuto mt-2 bg-dark3 p-2`}>
																				{this.state["dropOptions_"+cIndex].length === this.state["filteredDropOptions_"+cIndex].length ?
																					<div className="d-flex flex-wrap border-bottom">
																						<div style={{ display: "inline" }} className="mr-2 align-self-end">
																							<div className="form-check">
																								<input type="checkbox"
																									onChange={() => this.onChangeMultiSelectInput(this.state["dropOptions_"+cIndex], "All", "input_change", "", cIndex)}
																									className="form-check-input"
																									checked={this.state["select_all_"+cIndex]}
																								/>
																								<p className="form-check-label mb-0 mr-2 text-white" onClick={() => this.onChangeMultiSelectInput(this.state["dropOptions_"+cIndex], "All", "input_change", "", cIndex)}>Select All({this.state["filteredDropOptions_"+cIndex].length})</p>
																							</div>
																						</div>
																					</div>
																				: null}
																				{this.state["filteredDropOptions_"+cIndex].map((row, index) => {
																					return(
																						<div className={`d-flex flex-wrap pt-2 ${this.state["filteredDropOptions_"+cIndex].length - 1 !== index ? "border-bottom" : ""}`}>
																							<div style={{ display: "inline" }} className="mr-2 align-self-end">
																								<div className="form-check">
																									<input type="checkbox"
																										onChange={() => this.onChangeMultiSelectInput(this.state["dropOptions_"+cIndex], row, "input_change", index, cIndex)}
																										className="form-check-input"
																										checked={this.state["input_change_"+cIndex+"_"+index]}
																									/>
																									<p className="form-check-label mb-0 mr-2 text-white" onClick={() => this.onChangeMultiSelectInput(this.state["dropOptions_"+cIndex], row, "input_change", index, cIndex)}>{row}</p>
																								</div>
																							</div>
																						</div>
																					)
																				})}
																			</div>
																			</React.Fragment>
																		: null
																	: !this.state["hideDropLoading_"+cIndex] ?
																		<div className='d-flex justify-content-center m-4'>
																			<Spinner className='text-center' color='white' size='sm' />
																		</div>
																	: null}
																</div>
															: null
														}
													</div>
												</div>
											)
										})}
									</div>
									<div className='p-3 bg-muted my-3'>
										<div className="form-check align-self-center">
											<input type="checkbox" className="form-check-input" id="forecastCheck" />
											<input 
												type="checkbox" 
												className="form-check-input" 
												id="forecastCheck" 
												checked={this.state.forecast}
												onChange={e => this.setState({ forecast: this.state.forecast ? false : true })}
											/>
											<label className="form-check-label f16 cursorPointer text-white" for="assetsInfoCheckbox" onClick={() => this.setState({ forecast: this.state.forecast ? false : true })}>Forecast Data</label>
										</div>
									</div>

									<div className="d-flex mb-2 justify-content-between">
										{this.state.applyLoading ?
											<button className="btn btn-success">
												Applying <Spinner size='sm' color='light' className='ml-2' />
											</button>
										: 
											<button className={`d-flex justify-content-end cursorPointer btn-sm ${this.state.applyLoading ? 'disabled btn-primary' : 'btn-primary'}`} 
											onClick={ () => this.setState({ applyLoading: true }, () => this.costGetCostTrend())}>Apply</button>
										}
										{/* <small className="text-blue font-weight-bold align-self-center f13 cursorPointer" onClick={ () => this.clearFilter()}>CLEAR ALL</small> */}
									</div>
									</React.Fragment>
								: null}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
LandingPage.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log("state.cost", state.cost)
	return {
		providers: state.filters.providers,
		tagKeys: state.filters.tagKeys,
		costFilterValues: state.cost.costPropsDetails && state.cost.costPropsDetails.costFilterValues ? state.cost.costPropsDetails.costFilterValues : {},
	}
}

export default AppWrapper(LandingPage, mapStateToProps, {
	listAllProviders,
	getAllTagsKeys,
	getAllTagsValues,

	setCostPropsDetails,
	costGetCostTrend,
	getCostFilters,
	getCostFilterValues,
	listCostTemplates
})