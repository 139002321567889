/*************************************************
 * Tvastar
 * @exports
 * @file Reachability.js
 * @author Prakash // on 16/07/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { UncontrolledTooltip } from 'reactstrap'
import { getRechabilityAnalysisResults } from '../../../actions/aiops/ReachabilityAction'
import { momentConvertionUtcToLocalTime, momentDateGivenFormat, addMinutes, getRegionName, getAccountNameFromId } from '../../../utils/utility'
import Spinner from 'reactstrap/lib/Spinner'

class Reachability extends Component {
    constructor(props) {
        super(props)
        this.annotationTypeRef = React.createRef()
        this.state = {
            showLoading: true,
            showMore: 6,
        }
    }
    
    componentDidMount = () => {
        this.getRechabilityAnalysisResults()
    }

    getRechabilityAnalysisResults = () => {
        let caseStartTime = this.props.caseDetails.created_at
        let caseEndTime = momentDateGivenFormat(addMinutes(this.props.caseDetails.last_event, 1), "YYYY-MM-DD HH:mm:ss")
        if(caseEndTime < caseStartTime) {
            caseEndTime = caseStartTime
        }
        // let updatedCaseStartTime = subDays(caseStartTime, 3)

        let params = {
            "modified_asset_id": this.props.assetDetails.asset_id,
            "case_id": this.props.caseDetails.case_id
            // "start_time": updatedCaseStartTime,
            // // "end_time": caseEndTime,
            // "end_time": "2021-12-17 12:00:00",
            // "account_id": this.props.caseDetails.account_id[0],
            // "provider": this.props.caseDetails.provider[0],
            // "region": this.props.caseDetails.region[0]
        }
        this.props.getRechabilityAnalysisResults(params, (promise, response) => {
            if(promise) {
                let selectedReachability = response[0]
                let fitlerNotReachable = response.filter(e => e.status !== "Reachable")
                
                if(fitlerNotReachable && fitlerNotReachable.length) {
                    selectedReachability = fitlerNotReachable[0]
                }

                let activeReachability = response.findIndex((e => e.status !== "Reachable"))
                if(activeReachability < 0) {
                    activeReachability = 0
                }

                this.setState({ 
                    assetReachability: response,
                    selectedReachability,
                    showLoading: false,
                },
                    () => this.getObservationDetails(activeReachability, this.state.selectedReachability)
                )
            }
        })
    }

    getObservationDetails = (activeReachability, selectedItem) => {
        let assets = []
        let dataRow = {}
        dataRow.asset_name = selectedItem.source.asset_name
        dataRow.service_name = selectedItem.source.service_name
        dataRow.vpc_id = selectedItem.source.vpc_id
        dataRow.subnet_id = selectedItem.source.subnet_id
        dataRow.created_at = selectedItem.source.created_at
        dataRow.instance_type = selectedItem.source.instance_type
        dataRow.provider = selectedItem.provider.toUpperCase()
        dataRow.account_id = selectedItem.account_id
        dataRow.region = selectedItem.region
        dataRow.status = selectedItem.status
        dataRow.run_time = selectedItem.run_time
        
        assets.push(dataRow)

        dataRow = {}
        dataRow.asset_name = selectedItem.destination.asset_name
        dataRow.service_name = selectedItem.destination.service_name
        dataRow.vpc_id = selectedItem.destination.vpc_id
        dataRow.created_at = selectedItem.destination.created_at
        dataRow.subnet_id = selectedItem.destination.subnet_id
        dataRow.instance_type = selectedItem.destination.instance_type
        dataRow.provider = selectedItem.provider.toUpperCase()
        dataRow.account_id = selectedItem.account_id
        dataRow.region = selectedItem.region
        dataRow.status = selectedItem.status
        dataRow.run_time = selectedItem.run_time        
        assets.push(dataRow)
        

        this.setState({ activeReachability, observedAssets: selectedItem, assets, selectedAsset: assets[0].asset_name },
            () => this.onChangeAsset()    
        )
    }

    onChangeAsset = () => {
        let selectedAssetDetails = this.state.assets.filter(e => e.asset_name === this.state.selectedAsset)
        this.setState({ selectedAssetDetails: selectedAssetDetails[0] })
    }

    render() {
        return (
            !this.state.showLoading ?
                <div className="mt-2">
                    <div className="rounded bg-dark p-3">
                        <p className="mb-1 f16 text-white">Details</p>
                        <div className="d-flex mb-1 border-bottom">
                            <div className="py-1 w-50">
                                <p className="b-block mb-0">Provider / Account / Region</p>
                                <p className="mb-0 text-white">
                                    {this.props.assetDetails && this.props.assetDetails.provider ? this.props.assetDetails.provider.toUpperCase() : <span>&nbsp;</span>}
                                    {this.props.assetDetails && this.props.assetDetails.account_id ? (" : ")+getAccountNameFromId(this.props.assetDetails.account_id, this.props.accounts) : <span>&nbsp;</span>}
                                    {this.props.assetDetails && this.props.assetDetails.region ?  (" : ")+getRegionName(this.props.assetDetails.region, this.props.regions) : <span>&nbsp;</span>}
                                </p>
                            </div>
                        </div>
                        {this.state.observedAssets && this.state.observedAssets.description ?
                            <p className="mb-2">{this.state.observedAssets.description}</p>
                        : null}
                    </div>
                    <div className={`row ml-2 mt-2`}>
                        {this.state.assetReachability && this.state.assetReachability.slice(0, this.state.showMore).map((item, index) => {
                            return(
                                <div className={`col-lg-4 col-md-6 col-sm-6 mb-2 cursorPointer pl-0`}>
                                    <div className={`px-3 py-2 bg-dark rounded ${this.state.activeReachability === index ? (item.status === "Reachable" ? "border-info" : "danger-border") : ""}`} onClick={() => this.getObservationDetails(index, item)}>
                                        <div className="d-flex">
                                            <i className={`mr-2 align-self-center fa fa-circle ${item.status === "Reachable" ? "text-info" : "text-danger"}`}></i>
                                            <span className="small">{item.source.asset_name}</span>
                                        </div>
                                        <div className="" style={{paddingLeft:"3px"}}>
                                            <i className={`fas fa-arrows-alt-v ${item.status === "Reachable" ? "text-info" : "text-danger"} `}></i>
                                            </div>
                                        <div className="d-flex">
                                            <i className={`mr-2 align-self-center fa fa-circle ${item.status === "Reachable" ? "text-info" : "text-danger"}`}></i>
                                            <span className="small">{item.destination.asset_name}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {this.state.showMore > 6 ?
                        <div className="text-center cursorPointer" onClick={() => this.setState({ showMore: 6 })}> less<i className="fas fa-angle-double-up ml-1"></i></div>
                    : this.state.assetReachability && this.state.assetReachability.length > 6 ?
                        <div className="text-center cursorPointer" onClick={() => this.setState({ showMore: this.state.assetReachability && this.state.assetReachability.length })}> more<i className="fas fa-angle-double-down ml-1"></i></div>
                    : null}
                    {this.state.observedAssets && this.state.observedAssets.details && this.state.observedAssets.details[0].description ?
                        <p className="my-1">Cause: {this.state.observedAssets.details[0].description}</p>
                    : null}
                    <div className="d-flex justify-content-between">
                        <label className="my-1">Observation</label>
                        <div className="d-flex mb-2">
                            <p className="font-weight-bold mb-0 mr-2 align-self-center">Asset</p>
                            <div className={`form-group bd-highlight mb-0 styled-feild pt-0 pb-0 align-self-center`}>
                                <select className="form-control-sm" 
                                    value={this.state.selectedAsset}
                                    onChange={event => {
                                        this.setState({
                                            selectedAsset: event.target.value
                                        },
                                            () => this.onChangeAsset()
                                        )
                                    }}>
                                    {this.state.assets && this.state.assets.map(item => {
                                        return(
                                            <option value={item.asset_name}>{item.asset_name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-lg-6 pl-0">
                                {this.state.observedAssets ? 
                                    <div className="px-3 py-2 bg-dark rounded border-info">
                                        {this.state.observedAssets.status === "Reachable" ?
                                            <div className="info-timeline">
                                                <ul>
                                                    {/* <li className="d-flex mt-n4">
                                                        <div style={{width: "105px"}} className="align-self-end">Source</div>
                                                        <span className="timeline-circle">&nbsp;</span>
                                                        <span className="ml-2 align-self-end cursorPointer text" id={"asset_name_source"}>{this.state.observedAssets.source.asset_name}</span>
                                                        <UncontrolledTooltip target={"asset_name_source"}>{this.state.observedAssets.source.asset_name}</UncontrolledTooltip>
                                                    </li> */}
                                                    {this.state.observedAssets.details.map((item, index) => {
                                                        return(
                                                            <li className={`d-flex ${!index ? "mt-n4" : ""}`}>
                                                                {!index ? 
                                                                    <div style={{width: "105px"}} className="align-self-end">Source</div>
                                                                : (index + 1) === this.state.observedAssets.details.length ?
                                                                    <div style={{width: "105px"}} className="align-self-end">Destination</div>
                                                                :
                                                                    <div style={{width: "105px"}} className="align-self-end">&nbsp;</div>
                                                                }
                                                                <span className="timeline-circle">&nbsp;</span>
                                                                <span className="ml-2 align-self-end cursorPointer text oneLineEllipses" id={"asset_name_"+index}>{item.asset_name}</span>
                                                                <UncontrolledTooltip target={"asset_name_"+index}>{item.asset_name}</UncontrolledTooltip>
                                                            </li>
                                                        )
                                                    })}
                                                    {/* <li className="d-flex">
                                                        <div style={{width: "105px"}} className="align-self-end">Destination</div>
                                                        <span className="timeline-circle">&nbsp;</span>
                                                        <span className="ml-2 align-self-end cursorPointer text" id={"asset_name_destinaiton"}>{this.state.observedAssets.destination.asset_name}</span>
                                                        <UncontrolledTooltip target={"asset_name_destinaiton"}>{this.state.observedAssets.destination.asset_name}</UncontrolledTooltip>
                                                    </li> */}
                                                </ul>
                                            </div>
                                        : 
                                            <pre className="text-white">{JSON.stringify(this.state.observedAssets.details, null, 4)}</pre>
                                        }    
                                    </div>
                                : null}
                            </div>
                            <div className="col-lg-6 p-0">
                                {this.state.selectedAssetDetails ?            
                                    <div className="px-3 py-2 bg-dark rounded">
                                        <p className="mb-1 f16 text-white">Asset Details</p>
                                        <div className="d-flex mb-2">
                                            <p className="f16 b-block mb-0 font-weight-bold text-right" style={{width:"105px"}}>Name :</p>
                                            <p className="f16 mb-0 text-white ml-2">{this.state.selectedAssetDetails.asset_name}</p>
                                        </div>
                                        <div className="d-flex mb-2">
                                            <p className="f16 b-block mb-0 font-weight-bold text-right" style={{width:"105px"}}>VPC :</p>
                                            <p className="f16 mb-0 text-white ml-2">{this.state.selectedAssetDetails.vpc_id ? this.state.selectedAssetDetails.vpc_id : "i-oaa6245c147fc416e"}</p>
                                        </div>
                                        <div className="d-flex mb-2">
                                            <p className="f16 b-block mb-0 font-weight-bold text-right" style={{width:"105px"}}>Subnet :</p>
                                            <p className="f16 mb-0 text-white ml-2">{this.state.selectedAssetDetails.subnet_id ? this.state.selectedAssetDetails.subnet_id : "i-oaa6245c147fc416e"}</p>
                                        </div>
                                        <div className="d-flex mb-2">
                                            <p className="f16 b-block mb-0 font-weight-bold text-right" style={{width:"105px"}}>Size :</p>
                                            <p className="f16 mb-0 text-white ml-2">{this.state.selectedAssetDetails.instance_type ? this.state.selectedAssetDetails.instance_type : ""}</p>
                                        </div>
                                        <div className="d-flex mb-2">
                                            <p className="f16 b-block mb-0 font-weight-bold text-right" style={{width:"105px"}}>Active Since :</p>
                                            <p className="f16 mb-0 text-white ml-2">{momentConvertionUtcToLocalTime(this.state.selectedAssetDetails.created_at, "DD MMM YYYY HH:mm")}</p>
                                        </div>
                                        <div className="d-flex mb-2">
                                            <p className="f16 b-block mb-0 font-weight-bold text-right" style={{width:"105px"}}>Account :</p>
                                            <p className="f16 mb-0 text-white ml-2">{this.state.selectedAssetDetails.account_id}</p>
                                        </div>
                                        <div className="d-flex mb-2">
                                            <p className="f16 b-block mb-0 font-weight-bold text-right" style={{width:"105px"}}>Region :</p>
                                            <p className="f16 mb-0 text-white ml-2">{this.state.selectedAssetDetails.region}</p>
                                        </div>
                                    </div>
                                : null}
                            </div>
                        </div>
                    </div>
                </div>
            : 
                <div className="d-flex justify-content-center m-4">
                    <Spinner className="text-center" color="white" size="lg" />
                </div>
        )
    }
}

/**
* Type of the props used in the component
*/
Reachability.propTypes = {}   

const mapStateToProps = state => {
    // console.log("Reachability", state)
    return {
        accounts: state.filters.accounts,
        regions: state.filters.regions && state.filters.regions[0] && state.filters.regions[0].provider_regions ? state.filters.regions[0].provider_regions :[],
        caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : {},
    }
}

export default connect(mapStateToProps, {
    getRechabilityAnalysisResults
})(withRouter(Reachability))