/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Help.js
 * @author Prakash // on 24/01/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { } from '../../../utils/utility'


class Help extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount = () => {}

    render() {
        return (
            <div className="">
                <div className="d-flex justify-content-between p-3 bg-gray5 border-left">
                    <p className="mb-0 font-weight-bold text-white">Help</p>
                    <i className="far fa-times mr-3 text-white" onClick={() => this.props.showHelperSection()}></i>
                </div>
                <div className="bg-dark3 p-3">
                    <div className="mt-3">
                        <h5>Helper Guid</h5>
                        <p className="mb-0 small">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"s standard dummy text ever since the 1500s,</p>
                        <div className="mt-3">
                            <div className="helpSection rounded p-3">
                                <div className="d-flex mb-2">
                                    <i className="fas fa-dot-circle mr-2 text-info f20"></i>
                                    <span className="align-self-center text-dark">Example</span>
                                </div>
                                <p className="mb-0">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged
                                </p>
                            </div>
                        </div>
                        <h6 className="mt-3">Guid</h6>
                        <p className="mb-0 mt-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"s standard dummy text ever since the 1500s,</p>
                    </div>
                </div>
            </div>
        )
    }
}
/**
 * Type of the props used in the component
 */
Help.propTypes = {}

//export default 

export default connect(null, {})(withRouter(Help))
