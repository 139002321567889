/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file compilanceDasboard.js
 * @author Prakash // on 5/06/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { AppWrapper } from '../../common/AppWrapper'
import Chart from 'react-apexcharts'
import { currentLocaltime, subDays, momentDateGivenFormat, momentConvertionLocalToUtcTime, getAccountNameFromId, getRegionName, twoDateDiffrence } from '../../../utils/utility'
import { 
	listAllProviders, 
	listAllAccounts, 
	listAllRegions
} from '../../../actions/commonAction'

import { setDlpPropsDetails } from '../../../actions/dlp/dlpAction'

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

import ServiceS3 from './Service_s3'
import ServiceAthena from './Service_Athena'

// import "react-datetime/css/react-datetime.css";
// import Datetime from "react-datetime";

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';

const complianceOptions = [
	// {value: 'dc v1.0', key: 'DC v1.0'},
	// {value: 'tcb v1.0', key: 'TCB v1.0'},
	// {value: 'hipaa', key: 'HIPAA'},
	// {value: 'pci-dss', key: 'PCI DSS'},
	// {value: 'nist', key: 'NIST'},
	// {value: 'HIPAA HCFA', key: 'HIPAA HCFA'},
	{value: 'PII', key: 'PII'},
	{value: 'PHI', key: 'PHI'},
]

const Services = [
	{value: 's3', key: 's3'},
	{value: 'athena', key: 'Athena'}
]

const Options = [
	{value: 'All', key: 'All'},
	{value: 'structured', key: 'Structured'},
	{value: 'unstructured', key: 'Unstructured'}
]


class ComplianceDashboard extends Component {
	accountRef = React.createRef()
	regionRef = React.createRef()
	complianceRef = React.createRef()
	daterRangeRef = React.createRef()
	
	constructor(props) {
		super(props)
		this.state = {
			showLoading: false,
			
			providers: [],
			selectedProvider: '',
			
			accounts: [],
			selectedAccount: [],
			isAccountOpen: false,
			
			regions: [],
			selectedRegion: [],
			isRegionOpen: false,

			selectedCompliance: complianceOptions[0].value,
			isComplianceOpen: false,

			selectedService: 's3',
			showS3Section: true,
			selectedDataStructure: 'All',

			selectedFilterPeriod: this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.selectedFilterPeriod ? this.props.history.location.state.selectedFilterPeriod : 7,
			
			selectionRange: [{
				startDate: new Date(),
				endDate: new Date(),
				key: 'selection'
			}]

		}
		// this.handleStartTimeChange  = this.handleStartTimeChange.bind(this)
		// this.handleEndTimeChange    = this.handleEndTimeChange.bind(this)
	}
	
	componentDidMount = () => {
		this.setState({ showLoading: true })
		if(this.props.providers && this.props.providers.length) {
			this.setState({ providers: this.props.providers, selectedProvider: this.props.providers[0].provider_name },
				() => {
					this.filterDlp('provider')
					this.sliderPeriod('onLoad')	
				}
			)
		} else {
			this.props.listAllProviders((promise, providers) => {
				if (promise) {
					this.setState({ providers, selectedProvider: providers[0].provider_name },
						() => {
							this.filterDlp('provider')
							this.sliderPeriod('onLoad')	
						}
					)
				} else {
					this.setState(
						{ providers: [],  }, 
						() => {
							this.filterDlp()
							this.sliderPeriod('onLoad')	
						}
					)
				}
			})
		}
	}

	filterDlp = from => {
		this.setState({ showLoading: false })
		let params = {}

		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'all') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}

		if (from === 'provider') {
			if(this.props.accounts && this.props.accounts.length) {
				let allAccounts = this.props.accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
				if(this.props.accounts.length) {
					let accRow = {}
					accRow.account_id = "All"
					accRow.account_name = "All"
					accRow.date = null
					accRow.disabled = false
					accRow.provider = "All"
					allAccounts.unshift(accRow);
				}
				this.setState({ accounts: allAccounts })
			} else {
				this.props.listAllAccounts(params, (promise, allAccounts) => {
					if (promise) {						
						let filterdAccounts = allAccounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
						if(filterdAccounts.length) {
							let accRow = {}
							accRow.account_id = "All"
							accRow.account_name = "All"
							accRow.date = null
							accRow.disabled = false
							accRow.provider = "All"
							filterdAccounts.unshift(accRow);
						}						
						this.setState({ accounts: filterdAccounts })
					}
				})
			}

			if(this.props.regions && this.props.regions.length) {
				let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
				this.setState({ regions: providerRegions })
			} else {
				this.props.listAllRegions(params, (promise, regions) => {
					if (promise) {
						let providerRegions = regions && regions[0] && regions[0].provider_regions
						if(providerRegions) {
							let provider_regions_row = {}
							provider_regions_row.availability_zones = []
							provider_regions_row.city = "All"
							provider_regions_row.country = "All"
							provider_regions_row.geography = "All"
							provider_regions_row.name = "All"
							provider_regions_row.region = "All"
							provider_regions_row.region_id = "All"
							provider_regions_row.state = "All"
							providerRegions.unshift(provider_regions_row);
						}
						this.setState({ regions: providerRegions })
					} else {
						this.setState({ regions: [] })
					}
				})
			}
		}
	}

	sliderPeriod = (type) => {
		let sliderEndDate = momentDateGivenFormat(currentLocaltime(), 'YYYY-MM-DD 23:59:59')		
		let brushInterval = 0
		let fullBar = 0
		let smallBar = 0
		let filterMonth = 0
		if(this.state.selectedFilterPeriod === 7) {
			fullBar = 10
			brushInterval = 7
			filterMonth = 90
		} else if(this.state.selectedFilterPeriod === 14) {
			fullBar = 7
			brushInterval = 14
			filterMonth = 145
		} else if(this.state.selectedFilterPeriod === 30) {
			fullBar = 5
			brushInterval = 30
			filterMonth = 180
		} else if(this.state.selectedFilterPeriod === 90) {
			fullBar = 10
			brushInterval = 90
			filterMonth = 365
		}
		// else if(this.state.selectedFilterPeriod === 180) {
		// 	fullBar = 15
		// 	smallBar = 3
		// 	brushInterval = 30
		// } else if(this.state.selectedFilterPeriod === 365) {
		// 	fullBar = 15
		// 	smallBar = 3
		// 	brushInterval = 60
		// }
		let dateArray = []

		let y = 1
		let z = 1
		for(let x=1;x<=filterMonth;x++) {
			
			let date = subDays(sliderEndDate, (filterMonth-x))

			let value = smallBar ? 0 : 5
			if(smallBar === z) {
				value = 5
				z = 1
			}
			z++
			if(y === fullBar || y === 1) {
				value = 10
				y = 1
			}
			y++;
			if(new Date(date).getTime() <= new Date(sliderEndDate).getTime()) {
				dateArray.push([date, value])
			}
		}

		let sliderStartDate = ''
		if(this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.sliderStartDate && this.props.history.location.state.sliderEndDate) {
			sliderStartDate = momentDateGivenFormat(this.props.history.location.state.sliderStartDate, 'YYYY-MM-DD 00:00:00');
			sliderEndDate = momentDateGivenFormat(this.props.history.location.state.sliderEndDate, 'YYYY-MM-DD 00:00:00');
		} else {
			sliderStartDate = subDays(sliderEndDate, brushInterval)
			sliderStartDate = momentDateGivenFormat(sliderStartDate, 'YYYY-MM-DD')
			sliderEndDate = momentDateGivenFormat(sliderEndDate, 'YYYY-MM-DD')
		}
		
		let startSlice = momentDateGivenFormat(sliderStartDate, 'YYYY-MM-DD 00:00:00');
		let endSlice = momentDateGivenFormat(sliderEndDate, 'YYYY-MM-DD 23:59:59');

		let selectionRange = [{
			startDate: new Date(startSlice),
			endDate: new Date(endSlice),
			key: 'selection'
		}]

		this.setState({ dateArray, brushSliderSeries: [], sliderStartDate, sliderEndDate, initialStartDate: sliderStartDate, initialEndDate: sliderEndDate, startSlice, endSlice, changesInFilter: true, selectionRange },
			() => {
				this.brushSliderChart(dateArray)
				if(type === 'onLoad') {
					this.onSearch()
				}
                window.addEventListener('resize', () => {
                    this.brushSliderChart(dateArray)
                })
			}
		)
	}
	
	brushSliderChart = (dateArray) => {
        let sliderStartDate = this.state.sliderStartDate
        let sliderEndDate = this.state.sliderEndDate
		if(dateArray.length) {
			let brushSliderSeries = [{
				data: dateArray
			}]
			let dataArray = []
			dateArray.forEach(item => {
				dataArray.push(item[0])
			})

			let columnWidth = '5%'
			let brushSliderOptions = {
				plotOptions: {
					bar: {
						barHeight: '100%',
						columnWidth: columnWidth,
					},          
				},
				chart: {
					redrawOnParentResize: true,
					id: 'chart1',
					height: 130,
					type: 'bar',
					brush:{
						target: 'none',
						enabled: true,
					},
					zoom: {
						enabled: false,
					},
					selection: {
						enabled: true,
						fill: {
							color: "#039BE5",
							opacity: 1,
							// opacity: 0.6
						},  
						stroke: {
							show: false,
							curve: 'straight',
							lineCap: 'round',
							// colors: '#00A3CB',
							colors: '#039BE5',
							width: 0,
							dashArray: 0,      
						},
						xaxis: {
							min: new Date(sliderStartDate).getTime(),
							max: new Date(sliderEndDate).getTime(),
						},
						style: {
							fontSize: '28px',
							color: '#000000'
						}
					},
					events: {
						selection: (chartContext, { xaxis, yaxis }) => {
							var minDate = momentDateGivenFormat(new Date(xaxis.min), 'YYYY-MM-DD')
							var maxDate = momentDateGivenFormat(new Date(xaxis.max), 'YYYY-MM-DD')

							let startSlice = momentDateGivenFormat(minDate, 'YYYY-MM-DD 00:00:00');
							let endSlice = momentDateGivenFormat(maxDate, 'YYYY-MM-DD 23:59:59');

							let selectionRange = [{
								startDate: new Date(startSlice),
								endDate: new Date(endSlice),
								key: 'selection'
							}]

							this.setState({ sliderStartDate: startSlice, sliderEndDate: endSlice, startSlice, endSlice, selectionRange },
                                () => this.getAttribute()
                            )
						},
						click: (event, chartContext, config) => {
							this.onSearch()
						}
					}
				},
				colors: ['#515151'],
				xaxis: {
					tickAmount: 8,
					type: 'datetime',
					tooltip: {
						enabled: false
					},
					position: 'top',
					labels: {
						show: true,
						datetimeUTC: false,
						style: {
							colors: '#E0E0E0'
						},
						formatter: function(val) {
							return momentDateGivenFormat(new Date(val), "DD MMM");
						}
					},
					
				},
				yaxis: {
					show: false,
					tickAmount: 2,
					labels: {
						show: false
					},
				},
				grid: {
					xaxis: {
						lines: {
							show: false
						},
					},
					yaxis: {
						lines: {
							show: false
						}
					},
				}
			}
		
			this.setState({ datePickerSliderSeries: brushSliderSeries, datePickerSliderOptions: brushSliderOptions })
		}
	}
	
	getAttribute = () => {
		var attributes = document.getElementsByClassName("apexcharts-selection-rect") && document.getElementsByClassName("apexcharts-selection-rect")[0] &&document.getElementsByClassName("apexcharts-selection-rect")[0].attributes;
		if(attributes) {
			let width = attributes.width.value
			let x1 = parseInt(attributes.x.value) + parseInt(20)
			let x2 = parseInt(attributes.x.value) + parseInt(width) + parseInt(10)

			let leftLPosition = x1
            let rightLPosition = x2
            let diff = twoDateDiffrence(this.state.sliderEndDate, this.state.sliderStartDate)
            if(diff < 8) {
                leftLPosition = x1 - 20
                if(diff < 4) {
                    leftLPosition = x1 - 40
                }
                rightLPosition = x2 + 10
            }

			var d1 = document.getElementById('leftSliderSpan');
			d1.style.position = "absolute";
			d1.style.left = leftLPosition+'px';
			d1.style.top = '110px';
			d1.style.color = '#039BE5';

			var d2 = document.getElementById('rightSliderSpan');
			d2.style.position = "absolute";
			d2.style.left = rightLPosition+'px';
			d2.style.top = '110px';
			d2.style.color = '#039BE5';
		}
	}

	handleSelect = (date) => {
		this.setState({ selectionRange: [date.selection] })
		let startSlice = momentDateGivenFormat(date.selection.startDate, 'YYYY-MM-DD 00:00:00')
		let endSlice = momentDateGivenFormat(date.selection.endDate, 'YYYY-MM-DD 23:59:59')
		// let startSlice = momentDateGivenFormat(date.selection.startDate, 'YYYY-MM-DD HH:mm:ss')
		// let endSlice = momentDateGivenFormat(date.selection.endDate, 'YYYY-MM-DD HH:mm:ss')
		this.setState({ startSlice, endSlice })
	}

	// handleStartTimeChange(date) {
	// 	let startSlice = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
	// 	let endSlice = this.state.endSlice
	// 	if(new Date(startSlice).getTime() > new Date(endSlice).getTime() ) {
	// 		endSlice = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')
	// 	}

	// 	this.setState({ startSlice, endSlice, changesInFilter: true })
	// }

	// handleEndTimeChange(date) {
	// 	let startSlice = this.state.startSlice
	// 	let endSlice = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
	// 	if(new Date(endSlice).getTime() > new Date(startSlice).getTime() ) {
	// 		this.setState({ endSlice, changesInFilter: true })
	// 	}
	// }

	onSearch = () => {
		let changesInFilter = this.state.changesInFilter
		if(!this.state.previousStartDate || (this.state.previousStartDate !== this.state.startSlice || this.state.previousEndDate !== this.state.endSlice)) {
			changesInFilter = true
			this.setState({ previousStartDate: this.state.startSlice, previousEndDate: this.state.endSlice })
		}
		let showS3Section = true
		if(changesInFilter) {
			let startTime = this.state.sliderStartDate
			let endTime = this.state.sliderEndDate
			if(this.state.sliderStartDate !== this.state.startSlice || this.state.sliderEndDate !== this.state.endSlice) {
				startTime = this.state.startSlice
				endTime = this.state.endSlice
			}

			startTime = momentConvertionLocalToUtcTime(startTime, 'YYYY-MM-DD HH:mm:ss')
			endTime = momentConvertionLocalToUtcTime(endTime, 'YYYY-MM-DD HH:mm:ss')
			
			let obj = {}
			obj.selectedProvider = this.state.selectedProvider
			obj.selectedAccount = this.state.selectedAccount
			obj.selectedRegion = this.state.selectedRegion
			obj.selectedCompliance = this.state.selectedCompliance
			obj.selectedService = this.state.selectedService
			obj.selectedDataStructure = this.state.selectedDataStructure
			obj.start_time = startTime
			obj.end_time = endTime
			
			if(this.state.selectedService === 'athena') {
				showS3Section = false
			}
	
			this.props.setDlpPropsDetails('complianceFilters', obj)
		}

		this.setState({ changesInFilter: false, showS3Section })
	}	

	onReset = () => {
		this.setState({
			selectedProvider: this.state.providers && this.state.providers.length ? this.state.providers[0].provider_name : '',
			selectedRegion: [],
			selectedAccount: [],
			sliderStartDate: this.state.initialStartDate,
			sliderEndDate: this.state.initialEndDate,
			changesInFilter: true
		},
			() => this.onSearch()
		)
	}

	handleMultiSelectChange = (field, arrayValue) => {
		let value = arrayValue.map(item => item.value)
		// let label = arrayValue.map(item => item)
		let selectedValue = []
		if(field === 'selectedAccount') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.accounts.forEach(acc => {
						selectedValue.push(acc.account_id)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.accounts.length -1)) {
					selectedValue.push('All')
				}
			}			
		} else if(field === 'selectedRegion') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.regions.length -1)) {
					selectedValue.push('All')
				}
			}
		} else if(field === 'selectedCompliance') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					complianceOptions.forEach(comp => {
						selectedValue.push(comp.value)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (complianceOptions.length -1)) {
					selectedValue.push('All')
				}
			}
		}
		
		this.setState({ [field]: selectedValue, changesInFilter: true  })
	}	

	getMultiSelectedCount = (type, array) => {
		if(array) {
			return array.length && array.includes('All') ? 
				'All' 
			:  
				array.length ? 
					array.length > 1 ? 
						array.length +' Selected' 
					: 
						type === 'account' ? 
							getAccountNameFromId(array[0], this.state.accounts) 
						: type === 'region' ? 
							getRegionName(array[0], this.state.regions) 
						: type === 'compliance' ? 
							this.getComplianceLabel() 
						: array[0]
				: 'All'
		} else {
			return 'All'
		}
	}
		
	handleClickOutside(event) {		
		if (this.accountRef && !this.accountRef.current.contains(event.target)) {
			this.setState({ isAccountOpen: false })
		} else {
			this.setState({ isAccountOpen: true })
		}

		if (this.regionRef && !this.regionRef.current.contains(event.target)) {
			this.setState({ isRegionOpen: false })
		} else {
			this.setState({ isRegionOpen: true })
		}

		if (this.complianceRef && !this.complianceRef.current.contains(event.target)) {
			this.setState({ isComplianceOpen: false })
		} else {
			this.setState({ isComplianceOpen: true })
		}

		if (this.daterRangeRef && !this.daterRangeRef.current.contains(event.target)) {
			this.setState({ showDateRangePicker: false })
		} else {
			// this.setState({ isAccountOpen: true })
		}
	}

	// getComplianceLabel = () => {
	// 	let selectedComplianceLabel = ''
	// 	complianceOptions.forEach(item => {
	// 		if(item.value === this.state.selectedCompliance[0]) {
	// 			selectedComplianceLabel = item.key
	// 		}
	// 	})
	// 	return selectedComplianceLabel
	// }

	getSelectedComplianceLabel = () => {
		let selectedComplianceLabel = ''
		complianceOptions.forEach(item => {
			if(item.value === this.state.selectedCompliance) {
				selectedComplianceLabel = item.key
			}
		})
		return selectedComplianceLabel
	}

	getSelectedServiceLabel = () => {
		let getSelectedServiceLabel = ''
		Services.forEach(item => {
			if(item.value === this.state.selectedService) {
				getSelectedServiceLabel = item.key
			}
		})
		return getSelectedServiceLabel
	}
	
	getDataStructureLabel = () => {
		let selectedDataStructureLabel = ''
		Options.forEach(item => {
			if(item.value === this.state.selectedDataStructure) {
				selectedDataStructureLabel = item.key
			}
		})
		return selectedDataStructureLabel
	}

	render() {
		return (
			<div className='container-fluid overflow-auto flex-grow-1' onClick={ (event) => { this.handleClickOutside(event) } }>
				<div className='row h-100'>
					<div className='col-sm-12 p-0'>
						<div className='title-section p-2'>
							<div className="row">
								<div className="col-7 mt-0">
									<h6 className="text-white m-0">DLP Compliance</h6>
									<p className="text-white m-0">Count board view of DLP compliance on your Cloud</p>
									<div className="row mt-3">
										<div class={`col-md-12 d-md-flex bd-highlight ${this.state.showLoading ? 'disabled' : ''}`}>
											<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} ref={this.providerRef} style={{maxWidth: '150px' }}>
												<label className="mb-1">Provider</label>
												<Select
													placeholder={'All'}
													menuIsOpen={this.state.providerIsOpen}
													isSearchable={false}
													//onFocus={this.openProivderMenu}
													className='f13 p-0 colorBlack reactSelectFilterDrop'
													value={({
														value: this.state.selectedProvider,
														label: this.state.selectedProvider
													})}
													options={this.state.providers.map(item => ({
														value: item.provider_name,
														label: item.provider_name,	
													}))}
													onChange={event =>  
														this.setState(
															{
																selectedProvider: event.value,
																changesInFilter: true
															}
														)
													}
												/>
											</div>
											<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1 ${!this.state.providers.length}`} ref={this.accountRef} style={{maxWidth: '150px' }}>
												<label className="mb-1">Account</label>
												<div className='dlpHeaderMultiSelection removeDropdownSearchBar'>
													<ReactMultiSelectCheckboxes						
														placeholderButtonLabel="All"
														menuIsOpen ={this.state.isAccountOpen}
														getDropdownButtonLabel={() => this.getMultiSelectedCount('account', this.state.selectedAccount)}
														onChange={arr => {
														this.handleMultiSelectChange('selectedAccount', arr ? arr : []) }}
														options={this.state.accounts.map(account => ({
															value: account.account_id,
															label: account.account_name,
														}))}
														value={this.state.selectedAccount.map(comp => ({
															value: comp,
														}))}
													/>
												</div>
											</div>
											<div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 ${!this.state.providers.length}`} ref={this.regionRef} style={{maxWidth: '150px'}}>
												<label className="mb-1">Region</label>
												<div className={`dlpHeaderMultiSelection ${this.state.regions && this.state.regions.length > 10 ? '' : 'removeDropdownSearchBar' } `}>
													<ReactMultiSelectCheckboxes						
														placeholderButtonLabel="Select"
														menuIsOpen ={this.state.isRegionOpen}
														getDropdownButtonLabel={() => this.getMultiSelectedCount('region', this.state.selectedRegion)}
														onChange={arr => {
														this.handleMultiSelectChange('selectedRegion', arr ? arr : []) }}
														options={this.state.regions && this.state.regions.map(region => ({
															value: region.region,
															label: region.name,
														}))}
														value={this.state.selectedRegion.map(reg => ({
															value: reg,
														}))}
													/>
												</div>
											</div>
											<div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 ${!this.state.providers.length}`} style={{maxWidth: '150px' }} ref={this.complianceRef}>
												<label>Compliance</label>
												<Select
													placeholder={'Select'}
													isSearchable={false}
													//onFocus={this.openProivderMenu}
													className='f13 p-0 colorBlack reactSelectFilterDrop'
													value={({
														value: this.state.selectedCompliance,
														label: this.state.selectedCompliance ? this.getSelectedComplianceLabel() : 'Select'
													})}
													options={complianceOptions.map(item => ({
														value: item.value,
														label: item.key,	
													}))}
													onChange={event =>  
														this.setState(
															{
																selectedCompliance: event.value,
																changesInFilter: true,
															}
														)
													}
												/>
												{/* <div className={`dlpHeaderMultiSelection removeDropdownSearchBar`}>
													<ReactMultiSelectCheckboxes						
														placeholderButtonLabel="All"
														menuIsOpen ={this.state.isComplianceOpen}
														getDropdownButtonLabel={() => this.getMultiSelectedCount('compliance', this.state.selectedCompliance)}
														onChange={arr => { this.handleMultiSelectChange('selectedCompliance', arr ? arr : []) }}
														options={complianceOptions.map(item => ({
															value: item.value,
															label: item.key,	
														}))}														
														value={this.state.selectedCompliance.map(comp => ({
															value: comp,
														}))}
													/>
												</div> */}
											</div>
											<div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 ${!this.state.providers.length}`} style={{maxWidth: '150px' }}>
												<label>Service</label>
												<Select
													placeholder={'Select'}
													isSearchable={false}
													//onFocus={this.openProivderMenu}
													className='f13 p-0 colorBlack reactSelectFilterDrop'
													value={({
														value: this.state.selectedService,
														label: this.state.selectedService ? this.getSelectedServiceLabel() : 'Select'
													})}
													options={Services.map(item => ({
														value: item.value,
														label: item.key,	
													}))}
													onChange={event =>  
														this.setState(
															{
																selectedService: event.value,
																changesInFilter: true,
																selectedDataStructure: 'All'
															}
														)
													}
												/>
											</div>
											<div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 ${!this.state.providers.length} ${this.state.selectedService === 's3' ? '' : 'displayNone'}`}  style={{maxWidth: '150px' }}>
												<label>Type</label>
												<Select
													placeholder={'Select'}
													isSearchable={false}
													//onFocus={this.openProivderMenu}
													className='f13 p-0 colorBlack reactSelectFilterDrop'
													value={({
														value: this.state.selectedDataStructure,
														label: this.state.selectedDataStructure ? this.getDataStructureLabel() : 'Select'
													})}
													options={Options.map(item => ({
														value: item.value,
														label: item.key,	
													}))}
													onChange={event =>  
														this.setState(
															{
																selectedDataStructure: event.value,
																changesInFilter: true
															}
														)
													}
												/>
											</div>
										
										</div>
									</div>
								</div>
								<div className="col-5 text-right">
									<div className={`d-flex justify-content-end align-self-center`}>
										<div className={`d-flex justify-content-between align-self-end`}>
											<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === 90 ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedFilterPeriod: 90 }, () => this.sliderPeriod('onClicl'))}>3 Months</span>
											<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === 30 ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedFilterPeriod: 30 }, () => this.sliderPeriod('onClicl'))}>1 Month</span>
											<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === 14 ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedFilterPeriod: 14 }, () => this.sliderPeriod('onClicl'))}>2 Weeks</span>
											<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === 7 ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedFilterPeriod: 7 }, () => this.sliderPeriod('onClicl'))}>1 Week</span>
										</div>
										{/* <div className={`bg-filterBlue text-white align-self-end p-1 f12 ml-2`}>
											{getPastDaysNew(this.state.sliderStartDate, this.state.sliderEndDate)}
										</div> */}
										<div className="align-self-end" ref={this.daterRangeRef}>
											<div className={''}>
												<input type="text" className="form-control-sm f12 cursorPointer" readOnly value={momentDateGivenFormat(this.state.startSlice, 'DD MMM')+ ' to '+ momentDateGivenFormat(this.state.endSlice, 'DD MMM YYYY')} onClick={() => this.setState({ showDateRangePicker: !this.state.showDateRangePicker }) }/>
											</div>
											{this.state.showDateRangePicker ?
												<div className="headerDateRange" style={{"z-index": "999999999","position": "absolute","right": "30px", "top": "50px"}}>
													<DateRange
														onChange={item => this.handleSelect(item) }
														showSelectionPreview={true}
														moveRangeOnFirstSelection={false}
														months={2}
														ranges={this.state.selectionRange}
														direction="horizontal"
														maxDate={new Date()}
														preventSnapRefocus={true}
														// calendarFocus="backwards"
													/>
												</div>
											: null}
										</div>
										{/* <div className="align-self-center ml-3">
											<Datetime 
												value={momentDateGivenFormat(this.state.startSlice, 'DD MMM YYYY hh:mm A')}
												onChange={this.handleStartTimeChange}
												dateFormat={'DD MMM YYYY'}
												timeFormat={'hh:mm A'}
												inputProps={{ readOnly: true, disabled: this.state.showLoading }}
												className="w-100 mr-1 dateSliderInputReszie"
												isValidDate={previousDay()}
												closeOnSelect={true}
											/>
											<Datetime
												value={momentDateGivenFormat(this.state.endSlice, 'DD MMM YYYY hh:mm A')}
												onChange={this.handleEndTimeChange}
												dateFormat={'DD MMM YYYY'}
												timeFormat={'hh:mm A'}
												inputProps={{ readOnly: true, disabled: this.state.showLoading }}
												className="w-100 dateSliderInputReszie"
												isValidDate={previousDay()}
												closeOnSelect={true}
											/>
										</div> */}
										<div className={`ml-2 align-self-center`}>
											<div className="btn-group" role="group" aria-label="dlp-compliance">
												<button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-right`} onClick={() => this.onSearch() }>Search</button>
												<button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-left`} onClick={() => this.onReset()}><i className='far fa-redo'></i></button>
											</div>
										</div>	
									</div>
								
									{this.state.datePickerSliderOptions && this.state.datePickerSliderSeries ?
										<div className={`w-100 mb-n3 ${this.state.showLoading ? 'disabled' : ''}`} >
											<Chart options={this.state.datePickerSliderOptions} series={this.state.datePickerSliderSeries} type="bar" height={80} />
										</div>
									: null}	
									<div className="d-flex">
										<div id="leftSliderSpan" className="f12">{momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM')}</div>
										<div id="rightSliderSpan" className="f12">{momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM')}</div>
									</div>
								</div>
							</div>
						</div>
						<div className={`bg-background`}>
							<div className={`box p-3 rounded-0 shadow-none bg-transparent`}>
								{this.state.showS3Section ?
									<ServiceS3 />
								:
									<ServiceAthena />
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ComplianceDashboard.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log(state,'--------------')
	return {
		providers: state.filters.providers,
		accounts: state.filters.accounts,
		regions: state.filters.regions,
	}
	
}

export default AppWrapper(ComplianceDashboard, mapStateToProps, {
	setDlpPropsDetails,
	listAllProviders,
	listAllAccounts,
	listAllRegions,
})