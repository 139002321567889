/*************************************************
 * Tvastar
 * @exports
 * @file DlpConfiguration.js
 * @author Prakash // on 07/10/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { AppWrapper } from '../../common/AppWrapper'

import Watermark from './Watermark/Watermark'
import WatermarkAddEdit from './Watermark/AddEdit'

import EngineEvents from './EngineEvents/EngineEvents'
import EngineEventsAddEdit from './EngineEvents/AddEdit'

import Jobs from './Jobs/DlpJobs'
import JobsAddEdit from './Jobs/AddEdit'

import Account from './Accounts/Accounts'
import AccountAddEdit from './Accounts/AddEdit'

import Buckets from './Buckets/Buckets'
import BucketsAddEdit from './Buckets/AddEdit'

import Tables from './Tables/Tables'
import TablesAddEdit from './Tables/AddEdit'

class DlpConfiguration extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	componentDidMount = () => {
		this.configureTypeFunction('accounts')
	}

	configureTypeFunction = (page) => {
		this.setState({ configureType: page, ['show_'+page+'_list']: true })
	}

	/**
	 * Renders register screen design
	 */
	render() {
		return (
			<div className="container-fluid overflow-auto flex-grow-1">
				<div className='row h-100'>
					<div className='col-sm-12 p-0'>
						<div className="title-section p-3">
							<h6 className="text-white">Configuration</h6>
							<p className="text-white">Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet
								enim.</p>
						</div>
						<div className="box m-3 p-3 h-100">
							<div className="row">
								<div className="col-sm-6">
									<h6>Configuration</h6>
									<p>Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim.</p>
								</div>
								<div className="col-sm-6 text-right">
								</div>
							</div>
							<div className="row h-100">
								<div className="col-3">
									<div className="nav flex-column nav-pills">
										<span onClick={() => this.configureTypeFunction('accounts')} className={`nav-link cursorPointer ${this.state.configureType === 'accounts' ? 'active': ''}`}>Accounts</span>
										<span onClick={() => this.configureTypeFunction('buckets')} className={`nav-link cursorPointer ${this.state.configureType === 'buckets' ? 'active': ''}`}>Buckets</span>
										<span onClick={() => this.configureTypeFunction('tables')} className={`nav-link cursorPointer ${this.state.configureType === 'tables' ? 'active': ''}`}>Tables</span>
										<span onClick={() => this.configureTypeFunction('watermark')} className={`nav-link cursorPointer ${this.state.configureType === 'watermark' ? 'active': ''}`}>Watermark</span>
										<span onClick={() => this.configureTypeFunction('engine_events')} className={`nav-link cursorPointer ${this.state.configureType === 'engine_events' ? 'active': ''}`}>Engine Events</span>
										<span onClick={() => this.configureTypeFunction('jobs')} className={`nav-link cursorPointer ${this.state.configureType === 'jobs' ? 'active': ''}`}>Jobs</span>
									</div>
								</div>
								<div className="col-9 border-left">
									<div className="tab-content">
										{this.state.configureType === 'watermark' ?
											<div className={`tab-pane fade show active`}>
												{this.state.show_watermark_list ?
													<Watermark 
														onClickAction={(selectedRecord, sideViewType) => this.setState({ selectedRecord, sideViewType, show_watermark_list: false })}
													/>
												: 
													<WatermarkAddEdit 
														pageType={this.state.sideViewType}
														selectedRecord={this.state.selectedRecord ? this.state.selectedRecord : {}}
														showListPage={() => this.setState({ show_watermark_list: true })}
													/>
												}
											</div>
										: this.state.configureType === 'engine_events' ?
											<div className={`tab-pane fade show active`}>
												{this.state.show_engine_events_list ?
													<EngineEvents 
														onClickAction={(selectedRecord, sideViewType) => this.setState({ selectedRecord, sideViewType, show_engine_events_list: false })}
													/>
												: 
													<EngineEventsAddEdit 
														pageType={this.state.sideViewType}
														selectedRecord={this.state.selectedRecord ? this.state.selectedRecord : {}}
														showListPage={() => this.setState({ show_engine_events_list: true })}
													/>
												}
											</div>
										: this.state.configureType === 'jobs' ?
											<div className={`tab-pane fade show active`}>
												{this.state.show_jobs_list ?
													<Jobs 
														onClickAction={(selectedRecord, sideViewType) => this.setState({ selectedRecord, sideViewType, show_jobs_list: false })}
													/>
												: 
													<JobsAddEdit 
														pageType={this.state.sideViewType}
														selectedRecord={this.state.selectedRecord ? this.state.selectedRecord : {}}
														showListPage={() => this.setState({ show_jobs_list: true })}
													/>
												}
											</div>
										: this.state.configureType === 'accounts' ?
											<div className={`tab-pane fade show active`}>
												{this.state.show_accounts_list ?
													<Account 
														onClickAction={(selectedAccountInfo, sideViewType) => this.setState({ selectedAccountInfo, sideViewType, show_accounts_list: false })}
													/>
												: 
													<AccountAddEdit 
														pageType={this.state.sideViewType}
														selectedRecord={this.state.selectedAccountInfo ? this.state.selectedAccountInfo : {}}
														showListPage={() => this.setState({ show_accounts_list: true })}
													/>
												}
											</div>
										: this.state.configureType === 'buckets' ?
											<div className={`tab-pane fade show active`}>
												{this.state.show_buckets_list ?
													<Buckets 
														onClickAction={(selectedBucketTable, sideViewType) => this.setState({ selectedBucketTable, sideViewType, show_buckets_list: false })}
													/>
												: 
													<BucketsAddEdit 
														pageType={this.state.sideViewType}
														selectedRecord={this.state.selectedBucketTable ? this.state.selectedBucketTable : {}}
														showListPage={() => this.setState({ show_buckets_list: true })}
													/>
												}
											</div>
										: this.state.configureType === 'tables' ?
											<div className={`tab-pane fade show active`}>
												{this.state.show_tables_list ?
													<Tables 
														onClickAction={(selectedBucketTable, sideViewType) => this.setState({ selectedBucketTable, sideViewType, show_tables_list: false })}
													/>
												: 
													<TablesAddEdit 
														pageType={this.state.sideViewType}
														selectedRecord={this.state.selectedBucketTable ? this.state.selectedBucketTable : {}}
														showListPage={() => this.setState({ show_tables_list: true })}
													/>
												}
											</div>
										: null}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
DlpConfiguration.propTypes = {
}

//export default connect(null, { DlpConfiguration })(DlpConfiguration)
export default AppWrapper(DlpConfiguration, null, {})
