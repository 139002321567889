import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Input } from 'reactstrap'
import { setEditRuleDetails } from '../../../actions/governance/governanceAction'
import PropTypes from 'prop-types'

class ServiceRetention extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	handleChange = event => {
		this.props.setEditRuleDetails('condition', {
			backup_retention: {
				...this.props.editRuleDetails.condition['backup_retention'],
				[event.target.name]: event.target.value,
			},
		})
	}

	render() {
		return (
			<table>
				<tbody>
					{this.props.condition &&
						this.props.condition.map(service => {
							return (
								<tr key={service.service_name}>
									<td>{service.service_name}</td>
									<td>
										<Input
											type='number'
											onChange={e => this.handleChange(e)}
											placeholder='Retention Period(in days)'
											name={service.service_name}
											id={service.service_name}
											value={
												this.props.editRuleDetails.condition['backup_retention'][service.service_name]
													? this.props.editRuleDetails.condition['backup_retention'][service.service_name]
													: ''
											}
											fullWidth
											required
										/>
									</td>
								</tr>
							)
						})}
				</tbody>
			</table>
		)
	}
}
/**
 * Type of the props used in the component
 */
ServiceRetention.propTypes = {
	setEditRuleDetails: PropTypes.func,
	condition: PropTypes.object,
	editRuleDetails: PropTypes.object,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		editRuleDetails: state.governance.editRuleDetails,
	}
}

export default connect(mapStateToProps, { setEditRuleDetails })(ServiceRetention)
