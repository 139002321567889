/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file RootCauseRightSection.js
 * @author Prakash // on 02/09/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { momentConvertionUtcToLocalTime, getRegionName, getAccountNameFromId } from '../../../utils/utility'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'
import _ from 'lodash'

import { 
	listAllAccounts, 
	listAllRegions
} from '../../../actions/commonAction'

import ApexSimpleProgressChart from '../../common/charts/ApexSimpleProgressChart'
import SreMetricDetails from './SreMetricDetails'
import MetricsSection from './MetricsSection'

class DiagnosticsRightSection extends Component {
    constructor(props) {
        super(props)
        this.annotationTypeRef = React.createRef()
        this.scrolltoTop = React.createRef()
        this.state = {
            caseId: this.props.caseId,
            accounts: [],
            regions: [],
        }
    }

    componentDidMount = () => {} 

    componentDidUpdate = (prevProps) => {
        if(this.props.selectedRootCauseEvent && Object.entries(this.props.selectedRootCauseEvent).length && 
       !_.isEqual(prevProps.selectedRootCauseEvent, this.props.selectedRootCauseEvent)) {
            let mertcisAnnotationTypes = []
            if(this.props.selectedRootCauseEvent.label === 'Application Changes' || this.props.selectedRootCauseEvent.label === 'Database Changes' || this.props.selectedRootCauseEvent.label === 'Capacity Changes' || this.props.selectedRootCauseEvent.label === 'Infra Changes' || this.props.selectedRootCauseEvent.label === 'SRE Observations') {
                mertcisAnnotationTypes.push({prefix: 'C', label: ' - Change', value: 'C'})
            } else if(this.props.selectedRootCauseEvent.label === 'Health Events' || this.props.selectedRootCauseEvent.label === 'Maintenance and Scheduled Events' || this.props.selectedRootCauseEvent.label === 'DB SlowQuery Events' || this.props.selectedRootCauseEvent.label === 'Connectivity Checks') {
                mertcisAnnotationTypes.push({prefix: 'E', label: ' - Event', value: 'E'})
            }

            this.setState({ 
                selectedRootCauseLabel: this.props.selectedRootCauseEvent.label,
                selectedRootCauseEvent: this.props.selectedRootCauseEvent.data, 
                assetDetails: this.props.selectedRootCauseEvent.asset_details,
                progressValue: this.props.selectedRootCauseEvent.progressValue,
                showProgressValue: false,
                mertcisAnnotationTypes,
                showMeticsChart: false
            },
                () => {
                    this.scrolltoTop.current.scrollIntoView()
                    this.setState({ showMeticsChart: true, showProgressValue: true })
                }
            )
        }
    }

	getRegionAccountDetails = () => {
        if(!this.state.accounts || !this.state.accounts.length || !this.state.regions || !this.state.regions.length) {
            if(this.props.accounts && this.props.accounts.length) {
                let filterdAccounts = this.props.accounts
                if(this.state.assetDetails.provider) {
                    filterdAccounts = this.props.accounts.filter(account => account.provider === this.state.assetDetails.provider.toLowerCase())
                }
                this.setState({ accounts: filterdAccounts })
            } else if (this.state.assetDetails && this.state.assetDetails.provider) {
                let params = {}
                params.provider = this.state.assetDetails.provider.toLowerCase()
                this.props.listAllAccounts(params, (promise, allAccounts) => {
                    if (promise) {						
                        let filterdAccounts = allAccounts
                        if(allAccounts.length) {
                            filterdAccounts = allAccounts.filter(account => account.provider === this.state.assetDetails.provider.toLowerCase())
                        }
                        this.setState({ accounts: filterdAccounts })
                    }
                })
            }

            if(this.props.regions && this.props.regions.length) {
                let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
                this.setState({ regions: providerRegions })
            } else if (this.state.assetDetails.provider) {
                let params = {}
                params.provider = this.state.assetDetails.provider.toLowerCase()
                this.props.listAllRegions(params, (promise, regions) => {
                    if (promise) {
                        let providerRegions = regions && regions[0] && regions[0].provider_regions
                        this.setState({ regions: providerRegions })
                    } else {
                        this.setState({ regions: [] })
                    }
                })
            }
        }
	}
    // redirect = (url) => {
    //     window.open(url, '_blank');
    // }

	render() {
		return (
            <div className='' ref={this.scrolltoTop}>
                <div className={`m-0 h-100`}>
                    <div className="mt-3">
                        <div className="rounded bg-dark p-3">                            
                            <div className="d-flex">
                                {this.state.showProgressValue && this.state.progressValue ?
                                    <ApexSimpleProgressChart
                                        value={parseInt(this.state.progressValue)}
                                        valueoffsetY={-12}
                                        height={110}
                                        width={110}
                                        size={'60%'}
                                        strokeWidth={'100%'}
                                        label={''}
                                        labeloffsetY={0}
                                        colors={['#775BA2']}
                                        strokeColor={'#353C4C'}
                                        trackMargin={0}
                                    />
                                : null}
                                <div className="ml-3">
                                    <p className="mb-1 f16 text-white">Root Cause Details</p>
                                    <p className="b-block mb-0">Root Cause for the code deployed in the {this.state.assetDetails && this.state.assetDetails.asset_name ? this.state.assetDetails.asset_name : ''}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion mt-3" id="accordionExample">
                        {this.state.selectedRootCauseLabel === 'Maintenance and Scheduled Events' ?
                            <div className={`card bg-dark border-0 mb-2 cursorPointer`}>
                                <div id={'maintinance_event'} onClick={() => this.setState({ expandMaintinanceEvent: !this.state.expandMaintinanceEvent })}>
                                    <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
                                        <div className="text-white"><span className={`far ${this.state.expandMaintinanceEvent ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Details</div>
                                        <div className="text-info">Showing details</div>
                                    </div>
                                </div>
                                <div id={'collapse_event'} className={`collapse ${this.state.expandMaintinanceEvent ? 'show' : ''}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between mb-1">
                                            <p className="mb-0 f16 text-white">{this.state.selectedRootCauseEvent && this.state.selectedRootCauseEvent.maintsch_event_name ? this.state.selectedRootCauseEvent.maintsch_event_name : <span>&nbsp;</span>}</p>
                                            <p className="f12">{this.state.selectedRootCauseEvent && this.state.selectedRootCauseEvent.maintsch_event_description ? this.state.selectedRootCauseEvent.maintsch_event_description : <span>&nbsp;</span>}</p>
                                        </div>
                                        <div className="d-flex mb-1 border-bottom">
                                            <div className="py-1 w-50">
                                                <p className="b-block mb-0">Event ID:</p>
                                                <p className="mb-0 text-white">{this.state.selectedRootCauseEvent && this.state.selectedRootCauseEvent.maintsch_event_id ? this.state.selectedRootCauseEvent.maintsch_event_id : <span>&nbsp;</span>}</p>
                                            </div>
                                            <div className="py-1 w-50 pl-3">
                                                <p className="b-block mb-0">Event Time:</p>
                                                <p className="mb-0 text-white">{this.state.selectedRootCauseEvent && this.state.selectedRootCauseEvent.maintsch_event_time ? momentConvertionUtcToLocalTime(this.state.selectedRootCauseEvent.maintsch_event_time, 'DD MMM YYYY HH:mm:ss') : <span>&nbsp;</span>}</p>
                                            </div>
                                        </div>

                                        <div className="d-flex mb-1 border-bottom">
                                            <div className="py-1 w-50">
                                                <p className="b-block mb-0">Event Type:</p>
                                                <p className="mb-0 text-info" onClick={() => this.redirect('https://status.aws.amazon.com/')}>{this.state.selectedRootCauseEvent && this.state.selectedRootCauseEvent.maintsch_event_type ? this.state.selectedRootCauseEvent.maintsch_event_type : <span>&nbsp;</span>}</p>
                                            </div>
                                            <div className="py-1 w-50 pl-3">
                                                <p className="b-block mb-0">Arn:</p>
                                                <p className="mb-0 text-white text-break">{this.state.selectedRootCauseEvent && this.state.selectedRootCauseEvent.maintsch_event_arn ? this.state.selectedRootCauseEvent.maintsch_event_arn : <span>&nbsp;</span>}</p>
                                            </div>
                                        </div>
                                
                                        <div className="d-flex mb-1 border-bottom">
                                            <div className="py-1 w-50">
                                                <p className="b-block mb-0">Region:</p>
                                                <p className="mb-0 text-white">{this.state.selectedRootCauseEvent && this.state.selectedRootCauseEvent.maintsch_event_region ? getRegionName(this.state.selectedRootCauseEvent.maintsch_event_region, this.state.regions) : <span>&nbsp;</span>}</p>
                                            </div>
                                            <div className="py-1 w-50 pl-3">
                                                <p className="b-block mb-0">Service:</p>
                                                <p className="mb-0 text-white">{this.state.selectedRootCauseEvent && this.state.selectedRootCauseEvent.maintsch_event_service ? this.state.selectedRootCauseEvent.maintsch_event_service : <span>&nbsp;</span>}</p>
                                            </div>
                                        </div>

                                        <div className="d-flex mb-1 border-bottom">
                                            <div className="py-1 w-50">
                                                <p className="b-block mb-0">Start Time:</p>
                                                <p className="mb-0 text-white">{this.state.selectedRootCauseEvent && this.state.selectedRootCauseEvent.maintsch_start_time ? momentConvertionUtcToLocalTime(this.state.selectedRootCauseEvent.maintsch_start_time, 'DD MMM YYYY HH:mm:ss') : <span>&nbsp;</span>}</p>
                                            </div>
                                            <div className="py-1 w-50 pl-3">
                                                <p className="b-block mb-0">End Time:</p>
                                                <p className="mb-0 text-white">{this.state.selectedRootCauseEvent && this.state.selectedRootCauseEvent.maintsch_end_time ? momentConvertionUtcToLocalTime(this.state.selectedRootCauseEvent.maintsch_end_time, 'DD MMM YYYY HH:mm:ss') : <span>&nbsp;</span>}</p>
                                            </div>
                                        </div>

                                        <div className="d-flex mb-1">
                                    <div className="py-1 w-50">
                                        <p className="b-block mb-0">Category:</p>
                                        {this.state.selectedRootCauseEvent && this.state.selectedRootCauseEvent.maintsch_event_category ? 
                                            <small className={`badge badge-secondary`}>{this.state.selectedRootCauseEvent.maintsch_event_category}</small> 
                                        : null}
                                    </div>
                                </div>
                                    </div>
                                </div>
                            </div>
                        : this.state.selectedRootCauseLabel === 'SRE Observations' ?
                            <div className={`card bg-dark border-0 mb-2 cursorPointer`}>
                                <div id={'sre_event'} onClick={() => this.setState({ expandSreObservation: !this.state.expandSreObservation })}>
                                    <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
                                        <div className="text-white"><span className={`far ${this.state.expandSreObservation ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Asset Details</div>
                                        <div className="text-info">Showing asset details</div>
                                    </div>
                                </div>
                                <div id={'collapse_sre-asset'} className={`collapse ${this.state.expandSreObservation ? 'show' : ''}`} aria-labelledby={'heading_sre_event'} data-parent="#accordionExample">
                                    <div className="card-body">
                                        <div className="d-flex mb-1 border-bottom">
                                            <div className="py-1 w-50">
                                                <p className="b-block mb-0">Provider / Account / Region</p>
                                                <p className="mb-0 text-white">
                                                    {this.state.assetDetails && this.state.assetDetails.provider ? this.state.assetDetails.provider.toUpperCase() : <span>&nbsp;</span>}
                                                    {this.state.assetDetails && this.state.assetDetails.account_id ? (' : ')+getAccountNameFromId(this.state.assetDetails.account_id, this.state.accounts) : <span>&nbsp;</span>}
                                                    {this.state.assetDetails && this.state.assetDetails.region ?  (' : ')+getRegionName(this.state.assetDetails.region, this.state.regions) : <span>&nbsp;</span>}
                                                </p>
                                            </div>
                                            <div className="py-1 w-50 pl-3">
                                                <p className="b-block mb-0">Service:</p>
                                                <p className="mb-0 text-white">
                                                    {this.state.assetDetails && this.state.assetDetails.resource_type ? this.state.assetDetails.resource_type : <span>&nbsp;</span>}
                                                    {this.state.assetDetails && this.state.assetDetails.service_name ?  (' : ')+this.state.assetDetails.service_name : <span>&nbsp;</span>}
                                                </p>
                                            </div>
                                        </div>
                                        
                                        <div className="d-flex">
                                            <div className="py-1 w-50">
                                                <p className="b-block mb-0">Asset Name:</p>
                                                <p className="mb-0 text-white">{this.state.selectedRootCauseEvent && this.state.selectedRootCauseEvent.sre_asset_name ? this.state.selectedRootCauseEvent.sre_asset_name : <span>&nbsp;</span>}</p>
                                            </div>
                                            <div className="py-1 w-50 pl-3">
                                                <p className="b-block mb-0">Asset Id:</p>
                                                <p className="mb-0 text-white">{this.state.selectedRootCauseEvent && this.state.selectedRootCauseEvent.sre_asset_id ? this.state.selectedRootCauseEvent.sre_asset_id : <span>&nbsp;</span>}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : 
                            <div className={`card bg-dark border-0 mb-2 cursorPointer`}>
                                <div id={'asset_details'} onClick={() => this.setState({ expandAssetDetails: !this.state.expandAssetDetails })}>
                                    <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
                                        <div className="text-white"><span className={`far ${this.state.expandAssetDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Asset Details</div>
                                        <div className="text-info">Showing asset details</div>
                                    </div>
                                </div>
                                <div id={'collapse_asset'} className={`collapse ${this.state.expandAssetDetails ? 'show' : ''}`} aria-labelledby={'heading_assets'} data-parent="#accordionExample">
                                    <div className="card-body">
                                        <div className="d-flex mb-1 border-bottom">
                                            <div className="py-1 w-50">
                                                <p className="b-block mb-0">Provider / Account / Region</p>
                                                <p className="mb-0 text-white">
                                                    {this.state.assetDetails && this.state.assetDetails.provider ? this.state.assetDetails.provider.toUpperCase() : <span>&nbsp;</span>}
                                                    {this.state.assetDetails && this.state.assetDetails.account_id ? (' : ')+getAccountNameFromId(this.state.assetDetails.account_id, this.state.accounts) : <span>&nbsp;</span>}
                                                    {this.state.assetDetails && this.state.assetDetails.region ?  (' : ')+getRegionName(this.state.assetDetails.region, this.state.regions) : <span>&nbsp;</span>}
                                                </p>
                                            </div>                                    
                                            <div className="py-1 w-50 pl-3">
                                                <p className="b-block mb-0">Service:</p>
                                                <p className="mb-0 text-white">
                                                    {this.state.assetDetails && this.state.assetDetails.resource_type ? this.state.assetDetails.resource_type : <span>&nbsp;</span>}
                                                    {this.state.assetDetails && this.state.assetDetails.service_name ?  (' : ')+this.state.assetDetails.service_name : <span>&nbsp;</span>}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="d-flex">
                                            <div className="py-1 w-50">
                                                <p className="b-block mb-0">Asset:</p>
                                                <p className="mb-0 text-white">{this.state.assetDetails && this.state.assetDetails.asset_name ? this.state.assetDetails.asset_name : <span>&nbsp;</span>}</p>
                                            </div>
                                            <div className="py-1 w-50 pl-3">
                                                <p className="b-block mb-0">Asset Time:</p>
                                                <p className="mb-0 text-white">{this.state.assetDetails && this.state.assetDetails.event_time ? momentConvertionUtcToLocalTime(this.state.assetDetails.event_time, 'DD MMM YYYY HH:mm:ss') : <span>&nbsp;</span>}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    {this.state.selectedRootCauseLabel !== 'SRE Observations' ?
                        <div className="accordion mt-3" id="section">
                            <div className={`card bg-dark border-0 mb-2 cursorPointer`}>
                                <div id={'middle_section'} onClick={() => this.setState({ expandMiddleSection: !this.state.expandMiddleSection })}>
                                    <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
                                        <div className="text-white"><span className={`far ${this.state.expandMiddleSection ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>{this.state.selectedRootCauseLabel}</div>
                                        {/* <div className="text-info">Showing details</div> */}
                                    </div>
                                </div>
                                <div id={'middle_collapse'} className={`collapse ${this.state.expandMiddleSection ? 'show' : ''}`} aria-labelledby={'heading_middle'} data-parent="#section">
                                    <div className="card-body">
                                        {this.state.selectedRootCauseEvent && Object.entries(this.state.selectedRootCauseEvent).length ? 
                                            this.state.selectedRootCauseLabel === 'Application Changes' ?
                                                <div className="bg-dark3 rounded p-3">
                                                    {this.state.selectedRootCauseEvent.deployment_events ?
                                                        <div className="mb-3">
                                                            <p className="f16 text-white mb-1">Deployment Events</p>
                                                            <p className="f12 text-white">Showing {this.state.selectedRootCauseEvent.deployment_events && this.state.selectedRootCauseEvent.deployment_events.length} deployment events in application</p>
                                                            <ResizeableDarkThemeTable
                                                                columns={[
                                                                    {
                                                                        Header: 'Tool',
                                                                        // accessor: d => d.additional_details.tool,
                                                                        Cell: cellInfo => (
                                                                            <span className=''>
                                                                                {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.tool ? cellInfo.row.original.additional_details.tool : ''}
                                                                            </span>
                                                                        ),
                                                                    },
                                                                    {
                                                                        Header: 'Deployment ID',
                                                                        // accessor: d => d.additional_details.deployment_id,
                                                                        Cell: cellInfo => (
                                                                            <span className=''>
                                                                                {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.deployment_id ? cellInfo.row.original.additional_details.deployment_id : ''}
                                                                            </span>
                                                                        ),
                                                                    },                                                        
                                                                    {
                                                                        Header: 'Date / User',
                                                                        Cell: cellInfo => (
                                                                            <span className=''>
                                                                                {momentConvertionUtcToLocalTime(cellInfo.row.original.event_time, 'DD MMM YYYY HH:mm') +' / '+ (cellInfo.row.original.additional_details.user ? cellInfo.row.original.additional_details.user.name : '')}
                                                                            </span>
                                                                        ),
                                                                    },
                                                                    {
                                                                        Header: 'Description',
                                                                        Cell: cellInfo => (
                                                                            <span className=''>
                                                                                {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.artifacts && cellInfo.row.original.additional_details.artifacts.location ? cellInfo.row.original.additional_details.artifacts.location : ''}
                                                                            </span>
                                                                        ),
                                                                        width: 200
                                                                    },
                                                                    {
                                                                        Header: ' ',
                                                                        Cell: cellInfo => (
                                                                            <span className=''>&nbsp;</span>
                                                                        ),
                                                                        width: 1
                                                                    }
                                                                ]}
                                                                data={this.state.selectedRootCauseEvent.deployment_events}
                                                                perPage={2}
                                                                dashboard={(this.state.selectedRootCauseEvent.deployment_events && this.state.selectedRootCauseEvent.deployment_events.length) ? true : false}
                                                                sortByColumn={''}
                                                                riskTooltip={[]}
                                                                onClickRow={tableRow => {}}
                                                                tableSize={'table-xs'}
                                                            />
                                                        </div>
                                                    : this.state.selectedRootCauseEvent.build_events ?
                                                        <div className="mb-3">
                                                            <p className="f16 text-white mb-1">Build Events</p>
                                                            <p className="f12 text-white">Showing {this.state.selectedRootCauseEvent.build_events && this.state.selectedRootCauseEvent.build_events.length} build events in application</p>
                                                            <ResizeableDarkThemeTable
                                                                columns={[
                                                                    {
                                                                        Header: 'Tool',
                                                                        // accessor: d => d.additional_details.tool,
                                                                        Cell: cellInfo => (
                                                                            <span className=''>
                                                                                {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.tool ? cellInfo.row.original.additional_details.tool : ''}
                                                                            </span>
                                                                        ),
                                                                    },
                                                                    {
                                                                        Header: 'Reference',
                                                                        Cell: cellInfo => (
                                                                            <span className=''>
                                                                                {cellInfo.row.original.additional_details ? cellInfo.row.original.additional_details.build_job_name +' : '+ cellInfo.row.original.additional_details.build_number : ''}
                                                                            </span>
                                                                        ),
                                                                    },
                                                                    {
                                                                        Header: 'Date / User',
                                                                        // accessor: 'event_time'
                                                                        Cell: cellInfo => (
                                                                            <span className=''>
                                                                                {momentConvertionUtcToLocalTime(cellInfo.row.original.event_time, 'DD MMM YYYY HH:mm') +' / '+ (cellInfo.row.original.additional_details.user ? cellInfo.row.original.additional_details.user.name : '')}
                                                                            </span>
                                                                        ),
                                                                    },
                                                                    {
                                                                        Header: 'Artifacts',
                                                                        // accessor: 'event_time'
                                                                        Cell: cellInfo => (
                                                                            <span className=''>
                                                                                {Object.entries(cellInfo.row.original.additional_details.artifacts).length+' Artifacts'}
                                                                            </span>
                                                                        ),
                                                                    },
                                                                    {
                                                                        Header: 'Description',
                                                                        Cell: cellInfo => (
                                                                            <span className=''>
                                                                                {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.artifacts && cellInfo.row.original.additional_details.artifacts.location ? cellInfo.row.original.additional_details.artifacts.location : ''}
                                                                            </span>
                                                                        ),
                                                                        width: 200
                                                                    },
                                                                    {
                                                                        Header: ' ',
                                                                        Cell: cellInfo => (
                                                                            <span className=''>&nbsp;</span>
                                                                        ),
                                                                        width: 1
                                                                    }
                                                                ]}
                                                                data={this.state.selectedRootCauseEvent.build_events}
                                                                perPage={2}
                                                                dashboard={(this.state.selectedRootCauseEvent.build_events && this.state.selectedRootCauseEvent.build_events.length) ? true : false}
                                                                sortByColumn={''}
                                                                riskTooltip={[]}
                                                                onClickRow={tableRow => {}}
                                                                tableSize={'table-xs'}
                                                            />
                                                        </div>
                                                    : this.state.selectedRootCauseEvent.commit_events ?
                                                        <div className="mb-3">
                                                            <p className="f16 text-white mb-1">Commit Events</p>
                                                            <p className="f12 text-white">Showing {this.state.selectedRootCauseEvent.commit_events && this.state.selectedRootCauseEvent.commit_events.length} commit events in application</p>
                                                            <ResizeableDarkThemeTable
                                                                columns={[
                                                                    {
                                                                        Header: 'Tool',
                                                                        Cell: cellInfo => (
                                                                            <span className=''>
                                                                                {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.tool ? cellInfo.row.original.additional_details.tool : ''}
                                                                            </span>
                                                                        ),
                                                                    },
                                                                    {
                                                                        Header: 'Repo / URL',
                                                                        Cell: cellInfo => (
                                                                            <span className=''>
                                                                                {cellInfo.row.original.additional_details ? cellInfo.row.original.additional_details.repository_name +' / '+ cellInfo.row.original.additional_details.branch_name : ''}
                                                                            </span>
                                                                        ),
                                                                    },                                                    
                                                                    {
                                                                        Header: 'Date / User',
                                                                        Cell: cellInfo => (
                                                                            <span className=''>
                                                                                {momentConvertionUtcToLocalTime(cellInfo.row.original.event_time, 'DD MMM YYYY HH:mm') +' / '+ (cellInfo.row.original.additional_details.user ? cellInfo.row.original.additional_details.user.name : '')}
                                                                            </span>
                                                                        ),
                                                                    },
                                                                    {
                                                                        Header: 'Commit ID',
                                                                        Cell: cellInfo => (
                                                                            <span className=''>
                                                                                {cellInfo.row.original.additional_details ? cellInfo.row.original.additional_details.commit_id : ''}
                                                                            </span>
                                                                        ),
                                                                    },
                                                                    {
                                                                        Header: 'Files',
                                                                        Cell: cellInfo => (
                                                                            <span className=''>&nbsp;</span>
                                                                        ),
                                                                    }
                                                                ]}
                                                                data={this.state.selectedRootCauseEvent.commit_events}
                                                                perPage={2}
                                                                dashboard={(this.state.selectedRootCauseEvent.commit_events && this.state.selectedRootCauseEvent.commit_events.length) ? true : false}
                                                                sortByColumn={''}
                                                                riskTooltip={[]}
                                                                onClickRow={tableRow => {}}
                                                                tableSize={'table-xs'}
                                                            />
                                                        </div>
                                                    :null}
                                                </div>
                                            : this.state.selectedRootCauseLabel === 'Database Changes' ?
                                                <div className="bg-dark3 rounded p-3">
                                                    {console.log(this.props.selectedRootCauseEvent)}
                                                    <p className="f16 text-white mb-1">{this.props.selectedRootCauseEvent && this.props.selectedRootCauseEvent.tableHeading}</p>
                                                    <p className="f12 text-white">Showing {this.state.selectedRootCauseEvent.details && this.state.selectedRootCauseEvent.details.length} changes in {this.props.selectedRootCauseEvent && this.props.selectedRootCauseEvent.tableHeading ? this.props.selectedRootCauseEvent.tableHeading.toLowerCase() : ''}</p>
                                                    <ResizeableDarkThemeTable
                                                        columns={[
                                                            {
                                                                Header: 'Date',
                                                                Cell: cellInfo => (
                                                                    <span className=''>
                                                                        {momentConvertionUtcToLocalTime(cellInfo.row.original.event_time, 'DD MMM YYYY HH:mm')}
                                                                    </span>
                                                                ),
                                                            },
                                                            {
                                                                Header: 'Type',
                                                                Cell: cellInfo => (
                                                                    <span className=''>
                                                                        {(cellInfo.row.original.additional_details.db_object ? cellInfo.row.original.additional_details.db_object : '')}
                                                                    </span>
                                                                ),
                                                                width: 75
                                                            },
                                                            {
                                                                Header: 'DB Name',
                                                                Cell: cellInfo => (
                                                                    <span className=''>
                                                                        {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.database ? cellInfo.row.original.additional_details.database : ''}
                                                                    </span>
                                                                ),
                                                            },
                                                            {
                                                                Header: 'DB Object',
                                                                Cell: cellInfo => (
                                                                    <span className=''>
                                                                        {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.db_object_name ? cellInfo.row.original.additional_details.db_object_name : ''}
                                                                    </span>
                                                                ),
                                                            },
                                                            {
                                                                Header: 'Operation',
                                                                Cell: cellInfo => (
                                                                    <span className=''>
                                                                        {(cellInfo.row.original.additional_details.action ? cellInfo.row.original.additional_details.action : '')}
                                                                    </span>
                                                                ),
                                                            },
                                                            {
                                                                Header: 'User',
                                                                Cell: cellInfo => (
                                                                    <span className=''>
                                                                        {(cellInfo.row.original.additional_details.user ? cellInfo.row.original.additional_details.user.name : '')}
                                                                    </span>
                                                                ),
                                                            },
                                                            {
                                                                Header: ' ',
                                                                Cell: cellInfo => (
                                                                    <span className=''>&nbsp;</span>
                                                                ),
                                                                width: 1
                                                            }
                                                        ]}
                                                        
                                                        data={this.state.selectedRootCauseEvent.details}
                                                        perPage={5}
                                                        dashboard={(this.state.selectedRootCauseEvent.details && this.state.selectedRootCauseEvent.details.length) ? true : false}
                                                        sortByColumn={''}
                                                        riskTooltip={[]}
                                                        onClickRow={tableRow => {}}
                                                        tableSize={'table-xs'}
                                                    />
                                                </div>
                                            : (this.state.selectedRootCauseLabel === 'Maintenance and Scheduled Events') && this.state.selectedRootCauseEvent.details && this.state.selectedRootCauseEvent.details !== 'None' && this.state.selectedRootCauseEvent.details.length ?
                                                <div className="bg-dark3 rounded p-3">
                                                    <p className="f16 text-white mb-1">Assets</p>
                                                    <p className="f12 text-white">Showing {this.state.selectedRootCauseEvent.details.length} {this.state.selectedRootCauseEvent.details.length > 1 ? ' assets' : 'asset'} involved in { this.state.selectedRootCauseLabel === 'Health Events' ? 'Health events' : 'Maintenance and Scheduled events'}</p>
                                                    <ResizeableDarkThemeTable
                                                        columns={[
                                                            {
                                                                Header: 'Asset',
                                                                accessor: 'asset_name'
                                                            },
                                                            {
                                                                Header: 'Arn',
                                                                accessor: 'asset_arn',
                                                                width: 300
                                                            },
                                                            {
                                                                Header: 'Region',
                                                                accessor: 'region',
                                                                Cell: cellInfo => (
                                                                    <span className=''>
                                                                        {(cellInfo.row.original.region ? getRegionName(cellInfo.row.original.region, this.state.regions): '')}
                                                                    </span>
                                                                )
                                                            },
                                                            {
                                                                Header: 'Resource',
                                                                Cell: cellInfo => (
                                                                    <span className=''>
                                                                        {((cellInfo.row.original.resource_type ? cellInfo.row.original.resource_type : '') + ' : '+(cellInfo.row.original.service_name ? cellInfo.row.original.service_name : ''))}
                                                                    </span>
                                                                ),
                                                            },
                                                            {
                                                                Header: ' ',
                                                                Cell: cellInfo => (
                                                                    <span className=''>&nbsp;</span>
                                                                ),
                                                                width: 1
                                                            }
                                                        ]}
                                                        
                                                        data={this.state.selectedRootCauseEvent.details}
                                                        perPage={5}
                                                        dashboard={(this.state.selectedRootCauseEvent.details && this.state.selectedRootCauseEvent.details !== 'None' && this.state.selectedRootCauseEvent.details.length) ? true : false}
                                                        sortByColumn={''}
                                                        riskTooltip={[]}
                                                        onClickRow={tableRow => {}}
                                                        tableSize={'table-xs'}
                                                    />
                                                </div>
                                            : this.state.selectedRootCauseLabel === 'Capacity Changes' ?
                                                <React.Fragment>
                                                <div className="bg-dark3 rounded p-3">
                                                    {this.state.selectedRootCauseEvent && this.state.selectedRootCauseEvent.changes ?
                                                        this.state.selectedRootCauseEvent.changes.map((cng,index) => {
                                                            return(
                                                                <React.Fragment>
                                                                <div className={`mb-1 ${!index}`}>
                                                                    <p className="mb-0 f16 text-white">Before Changes</p>
                                                                    <p className="f12 mb-0">{(cng.field ? cng.field : '')+''+(cng.previous_value ? +' : '+cng.previous_value : '')}</p>
                                                                </div>
                                                                {cng.previous_features ? 
                                                                    Object.entries(cng.previous_features).map(([key, value]) => {
                                                                        return(
                                                                        <div className="py-1">
                                                                            <p className={`mb-0 f16 ${typeof value === 'object' ? 'text-white' : ''}`}>{key}</p>
                                                                            <div className="d-flex mb-1 border-bottom">
                                                                                {typeof value === 'object' ? 
                                                                                    Object.entries(value).map(([vKey, vValue]) => {
                                                                                        return(
                                                                                        <div className="py-1 w-33">
                                                                                            <p className="b-block mb-0">{vKey}:</p>
                                                                                            <p className="mb-0 text-white">{vValue.toLowerCase() === 'y' ? 'Yes' : vValue.toLowerCase() === 'n' ? 'No' : vValue }</p>
                                                                                        </div>
                                                                                        )
                                                                                    })
                                                                                :     
                                                                                    <p className="f12 text-white">{value}</p>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        )
                                                                    })
                                                                : null}
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    : null}
                                                </div>
                                                <div className="bg-dark3 rounded p-3 mt-2">
                                                    {this.state.selectedRootCauseEvent && this.state.selectedRootCauseEvent.changes ?
                                                        this.state.selectedRootCauseEvent.changes.map((cng,index) => {
                                                            return(
                                                                <React.Fragment>
                                                                <div className={`mb-1 ${!index}`}>
                                                                    <p className="mb-0 f16 text-white">After Changes</p>
                                                                    <p className="f12">{(cng.field ? cng.field : '')+''+(cng.updated_value ? +' : '+cng.updated_value : '')}</p>
                                                                </div>
                                                                {cng.current_features ? 
                                                                    Object.entries(cng.current_features).map(([key, value]) => {
                                                                        return(
                                                                        <div className="py-1">
                                                                            <p className={`mb-0 f16 ${typeof value === 'object' ? 'text-white' : ''}`}>{key}</p>
                                                                            <div className="d-flex mb-1 border-bottom">
                                                                                {typeof value === 'object' ? 
                                                                                    Object.entries(value).map(([vKey, vValue]) => {
                                                                                        return(
                                                                                        <div className="py-1 w-33">
                                                                                            <p className="b-block mb-0">{vKey}:</p>
                                                                                            <p className="mb-0 text-white">{vValue.toLowerCase() === 'y' ? 'Yes' : vValue.toLowerCase() === 'n' ? 'No' : vValue }</p>
                                                                                        </div>
                                                                                        )
                                                                                    })
                                                                                :     
                                                                                    <p className="f12 text-white">{value}</p>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        )
                                                                    })
                                                                : null}
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    : null}
                                                </div>
                                                </React.Fragment>
                                            : this.state.selectedRootCauseLabel === 'Infra Changes' ?
                                                <React.Fragment>
                                                <div className="bg-dark3 rounded p-3">
                                                    {this.state.selectedRootCauseEvent && this.state.selectedRootCauseEvent.changes ?
                                                        this.state.selectedRootCauseEvent.changes.map((cng,index) => {
                                                            return(
                                                                <React.Fragment>
                                                                <div className={`mb-1 ${!index}`}>
                                                                    <p className="mb-0 f16 text-white">Before Changes</p>
                                                                    <p className="f12">{(cng.field ? cng.field : '')+' : '+(cng.previous_value ? cng.previous_value : '')}</p>
                                                                </div>
                                                                {cng.previous_features ? 
                                                                    Object.entries(cng.previous_features).map(([key, value]) => {
                                                                        return(
                                                                        <div className="py-1">
                                                                            <p className={`mb-0 f16 ${typeof value === 'object' ? 'text-white' : ''}`}>{key}</p>
                                                                            <div className="d-flex mb-1 border-bottom">
                                                                                {typeof value === 'object' ? 
                                                                                    Object.entries(value).map(([vKey, vValue]) => {
                                                                                        return(
                                                                                        <div className="py-1 w-33">
                                                                                            <p className="b-block mb-0">{vKey}:</p>
                                                                                            <p className="mb-0 text-white">{vValue.toLowerCase() === 'y' ? 'Yes' : vValue.toLowerCase() === 'n' ? 'No' : vValue }</p>
                                                                                        </div>
                                                                                        )
                                                                                    })
                                                                                :     
                                                                                    <p className="f12 text-white">{value}</p>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        )
                                                                    })
                                                                : null}
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    : null}
                                                </div>
                                                <div className="bg-dark3 rounded p-3">
                                                    {this.state.selectedRootCauseEvent && this.state.selectedRootCauseEvent.changes ?
                                                        this.state.selectedRootCauseEvent.changes.map((cng,index) => {
                                                            return(
                                                                <React.Fragment>
                                                                <div className={`mb-1 ${!index}`}>
                                                                    <p className="mb-0 f16 text-white">After Changes</p>
                                                                    <p className="f12">{(cng.field ? cng.field : '')+' : '+(cng.updated_value ? cng.updated_value : '')}</p>
                                                                </div>
                                                                {cng.current_features ? 
                                                                    Object.entries(cng.current_features).map(([key, value]) => {
                                                                        return(
                                                                        <div className="py-1">
                                                                            <p className={`mb-0 f16 ${typeof value === 'object' ? 'text-white' : ''}`}>{key}</p>
                                                                            <div className="d-flex mb-1 border-bottom">
                                                                                {typeof value === 'object' ? 
                                                                                    Object.entries(value).map(([vKey, vValue]) => {
                                                                                        return(
                                                                                        <div className="py-1 w-33">
                                                                                            <p className="b-block mb-0">{vKey}:</p>
                                                                                            <p className="mb-0 text-white">{vValue.toLowerCase() === 'y' ? 'Yes' : vValue.toLowerCase() === 'n' ? 'No' : vValue }</p>
                                                                                        </div>
                                                                                        )
                                                                                    })
                                                                                :     
                                                                                    <p className="f12 text-white">{value}</p>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        )
                                                                    })
                                                                : null}
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    : null}
                                                </div>
                                                </React.Fragment>
                                            : null
                                        : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    : this.state.selectedRootCauseLabel === 'SRE Observations' ?
                        <div className="mt-3">
                            <SreMetricDetails 
                                assetDetails={this.state.assetDetails}
                                brush={true}
                                sparkline={false}
                                yaxis={true}
                                yaxisLabel={true}
                                xaxis={true}
                                xaxisFormat={''}
                                xaxisLabel={true}
                                grid={false}
                                axisLabelColor={'#B8BBBE'}
                                legend={false}
                                stacked={false}
                                height={200}
                                horizontal={true}
                                barHeight={'40%'}
                                barEndShape={'rounded'}
                                columnWidth={'50%'}
                                gradient={true}
                                gradientColor={['#24A597', '#775BA2']}
                                hideTooltipValue={true}
                                backgroundBarShape={'rounded'}
                                backgroundBarColors={['#333947']}
                                showBackgroundBarColors={false}
                                className={"transparentTooltip"}
                                annotations={true}
                            />
                        </div>
                    : null}
                    
                    <div className="my-3">
                        {this.state.showMeticsChart ?
                            <MetricsSection
                                metricsType={this.state.selectedRootCauseLabel}
                                assetDetails={this.state.assetDetails}
                                brush={true}
                                sparkline={false}
                                yaxis={true}
                                yaxisLabel={true}
                                xaxis={true}
                                xaxisFormat={''}
                                xaxisLabel={true}
                                grid={false}
                                axisLabelColor={'#B8BBBE'}
                                legend={false}
                                stacked={false}
                                height={115}
                                horizontal={true}
                                barHeight={'40%'}
                                barEndShape={'rounded'}
                                columnWidth={'50%'}
                                gradient={true}
                                gradientColor={['#039BE5', '#5F5BA2']}
                                hideTooltipValue={true}
                                backgroundBarShape={'rounded'}
                                backgroundBarColors={['#333947']}
                                showBackgroundBarColors={false}
                                className={"transparentTooltip mt-n4 mb-n4"}
                                annotations={true}
                                annotaionOptions={this.state.mertcisAnnotationTypes}
                            />
                        : null}
                    </div>
                </div>
            </div>
		)
	}
}
/**
 * Type of the props used in the component
 */
DiagnosticsRightSection.propTypes = {
    listAllAccounts: PropTypes.func,
	listAllRegions: PropTypes.func,
    getMonitoringMetricsByTrend: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
    return {
        accounts: state.filters.accounts,
        regions: state.filters.regions,
        caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : {},
        selectedRootCauseEvent: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.selectedRootCauseEvent ? state.aiops.aiopsPropsDetails.selectedRootCauseEvent : {},
    }
}

export default connect(mapStateToProps, {
    listAllAccounts,
	listAllRegions,
})(withRouter(DiagnosticsRightSection))