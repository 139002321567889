/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ErrorPage.js
 * @author Prakash // on 28/03/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 **************************************************/
import React, { Component } from 'react'
import { URL_PATH } from '../config/urlPath'
import { AppWrapper } from './common/AppWrapper'
import { captureUiErrors } from '../actions/commonAction'
import { currentUTCtime } from '../utils/utility'
import _ from 'lodash'

class ErrorPage extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    componentDidMount = () => {
        if (this.props.location && this.props.location.state && this.props.location.state) {
            let params = {}
            params.create_at = currentUTCtime()
            if (window.location.hostname === 'localhost' || window.location.hostname === 'dev.tvastr.ai') {
                params.doamin = "dev"
            } else {
                params.doamin = "prod"
            }
            let browserName = ""
            let userAgent = navigator.userAgent;
            if(userAgent.match(/chrome|chromium|crios/i)){
                browserName = "chrome";
            }else if(userAgent.match(/firefox|fxios/i)){
                browserName = "firefox";
            }  else if(userAgent.match(/safari/i)){
                browserName = "safari";
            }else if(userAgent.match(/opr\//i)){
                browserName = "opera";
            } else if(userAgent.match(/edg/i)){
                browserName = "edge";
            }else{
                browserName="No browser detection";
            }

            params.browser = browserName

            let message = this.props.location.state.error && this.props.location.state.error.message ? this.props.location.state.error.message : ""
            params.message = message

            params.errorDetail = this.props.location.state.error && this.props.location.state.error.stack ? this.props.location.state.error.stack : ""

            let errorInfo = this.props.location.state.errorInfo && this.props.location.state.errorInfo.componentStack ? this.props.location.state.errorInfo.componentStack : ""

            let page = errorInfo.split("\n")
            params.page = page[1].trim()

            let pathName = this.props.location.state.pathName ? this.props.location.state.pathName : ""
            params.path = pathName

            let splitPath = pathName.split("/")

            if(splitPath.length && splitPath[1]) {
                params.module = splitPath[1]
            }

            console.log("this.props.location.state.error", this.props.location.state)

            // let pathUrl = this.props.location.state.pathUrl ? this.props.location.state.pathUrl : ""
            // params.path = pathName
            this.props.captureUiErrors(params, (promise, response) => {})

            this.setState({
                error: this.props.location.state.error,
                errorInfo: this.props.location.state.errorInfo
            })

            setTimeout(() => this.takeToHome(), 10000);
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.location && !this.props.location.state && this.props.userMenu && Object.entries(this.props.userMenu).length && !_.isEqual(prevProps.userMenu, this.props.userMenu)) {
            this.takeToHome()
        }
    }

    takeToHome = () => {
        window.location.href = this.props.userMenu.defaultLandingPage && this.props.userMenu.defaultLandingPage.link ? this.props.userMenu.defaultLandingPage.link : URL_PATH.CHECKS_SEARCH
    }

    /**
     * Renders Errro page
     */
    render() {
        return (
            <div id="notfound" className="bg-muted">
                <div class="notfound">
                    <div class="notfound-404">
                        <h1>Oops!</h1>
                        <h2>SOMETHING&nbsp;WENT&nbsp;WRONG</h2>
                    </div>
                    <button className="btn btn-primary" onClick={() => this.takeToHome()}>Take Me Home</button>
                </div>
            </div>
        )
    }
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    return {
		userMenu: state.filters.userMenu,
    }
}

export default AppWrapper(ErrorPage, mapStateToProps, {
    captureUiErrors
})



