/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Metrics.js
 * @author Prakash // on 22/02/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {  withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'
import SearchWithHiddenInput from '../../common/SearchWithHiddenInput'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'

class AssetsLeftSection extends Component {
    
    constructor(props) {

        super(props)

        this.state = {
            showMetricsLoading: false,
            // startDate: new Date('25 Jan 2021').getTime(),
            // endDate: new Date('25 Feb 2021').getTime()
            alertDynamicColumns: [],
            assetDynamicColumns: [],
            tableType: 'Assets',
            resetSearchBox: false,
            caseId: this.props.caseId,
            totalAlertCount: [],
            tableLoading: true
        }
    }

	componentDidMount = () => {
        this.listAssets()
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.assetsList.length !== prevProps.assetsList.length) {
            this.listAssets()
        }
    }

    listAssets = () => {
        let assetsTableColumns = []
        let dataRow = {}
        dataRow.Header = 'Asset'
        dataRow.accessor =  d =>Array.isArray(d.asset_name) ? d.asset_name.map((item, index) => (d.asset_name.length - 1 === index ? item : item + ', ')) : (d.asset_name ? d.asset_name : '')
        assetsTableColumns.push(dataRow)
        
        dataRow = {}
        dataRow.Header = 'Asset Id'
        dataRow.accessor = 'asset_id'
        assetsTableColumns.push(dataRow)

        dataRow = {}
        dataRow.Header = 'Resource'
        // dataRow.accessor = 'provider'
        dataRow.accessor =  d  => d.provider.toUpperCase() +' : '+d.account_id+' : '+d.region
        assetsTableColumns.push(dataRow)

        // dataRow = {}
        // dataRow.Header = 'Account'
        // dataRow.accessor = 'account_id'
        // assetsTableColumns.push(dataRow)

        // dataRow = {}
        // dataRow.Header = 'Region'
        // dataRow.accessor = 'region'
        // assetsTableColumns.push(dataRow)

        dataRow = {}
        dataRow.Header = 'Service'
        // dataRow.accessor = 'resource_type'
        dataRow.accessor =  d  => d.resource_type +' : '+d.service_name
        assetsTableColumns.push(dataRow)

        // dataRow = {}
        // dataRow.Header = 'Service'
        // dataRow.accessor = 'service_name'
        // assetsTableColumns.push(dataRow)

        this.setState({ assetsList: this.props.assetsList ? this.props.assetsList : [], filteredArray: this.props.assetsList ? this.props.assetsList : [], assetDynamicColumns: assetsTableColumns, tableLoading: false })
    }

    onClickViewAssetDetails = (selectedAssest) => {
        let asset = Array.isArray(selectedAssest.asset_name) ? selectedAssest.asset_name.map((item, index) => (selectedAssest.asset_name.length - 1 === index ? item : item + ', ')) : (selectedAssest.asset_name ? selectedAssest.asset_name : '')

        this.props.setAiopsPropsDetails('changeDetailAsset', asset)
    }

	render() {
		return (
            <div className='col-md-12'>
                <div className="rounded bg-dark p-3 mt-3">
                    <div className="d-flex justify-content-between">
                        <div className="align-self-center">
                            <p className="mb-0 f16 text-white">Assets List</p>
                        </div>
                        {this.state.assetsList && this.state.assetsList.length ?
                            <div className="">
                                <SearchWithHiddenInput
                                    ID={'asset_search'}
                                    data={this.state.assetsList}
                                    applyLiteDarkTags={true}
                                    searchClassName={'border-0'}
                                    searchIconColor={'text-gray3'}
                                    className="form-control-sm text-gray3 bg-muted border-0"
                                    hideInputSection={true}
                                    topClassName={'dark-search mb-2'}
                                    filteredData={(filteredArray, searchText) => {
                                        this.setState({ filteredArray })
                                    }}
                                />
                            </div>
                        : null}
                    </div>
                    <div className={`scrollbarDesignDark`}>
                        {this.state.filteredArray && this.state.filteredArray.length && !this.state.tableLoading ?
                        <ResizeableDarkThemeTable
                            columns={[{
                                    Header: 'Asset',
                                    accessor: d =>Array.isArray(d.asset_name) ? d.asset_name.map((item, index) => (d.asset_name.length - 1 === index ? item : item + ', ')) : (d.asset_name ? d.asset_name : ''),
                                     width: 190
                                },
                                {
                                    Header: 'Provider',
                                    accessor: d  => d.provider.toUpperCase() +' : '+d.account_id+' : '+d.region,
                                    width: 140
                                },
                                {
                                    Header: 'Service',
                                    accessor:  d  => d.resource_type +' : '+d.service_name,
                                    width: 140
                                },
                                {
                                    Header: 'ARN',
                                    accessor:  d  => d.asset_arn ? d.asset_arn : '',
                                    width: 180
                                },
                                {
                                    Header: 'Alerts/Events',
                                    accessor:  '',
                                    width: 120
                                },
                                {
                                    Header: '  ',
                                    Cell: cellInfo => (
                                        <div className="d-flex justify-content-between align-self-center">
                                            <p className="m-0 p-0 mr-1 cursorPointer f10 text-info" onClick={() => this.onClickViewAssetDetails(cellInfo.row.original)}>View Asset</p>
                                        </div>
                                    ),
                                    width: 80
                                },
                                {
                                    Header: ' ',
                                    accessor:  '',
                                    width: 1
                                }
                            ]}
                            data={this.state.filteredArray ? this.state.filteredArray : []}
                            perPage={20}
                            dashboard={this.state.filteredArray && this.state.filteredArray.length ? true : false}
                            sortByColumn={''}
                            riskTooltip={[]}
                            onClickRow={tableRow => {}}
                            tableSize={'table-xs'}
                        />
                        : this.state.filteredArray && !this.state.filteredArray.length && !this.state.tableLoading ?
                            <div className='d-flex justify-content-center m-4'>
                                <p>There are no data on this criteria. Please try adjusting your Search.</p>
                            </div>
                        : null}
                        {this.state.tableLoading ? (
                            <div className='d-flex justify-content-center m-4'>
                                <Spinner className='text-center' color='dark' size='sm' />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
		)
	}
}
/**
 * Type of the props used in the component
 */
AssetsLeftSection.propTypes = {
    setAiopsPropsDetails: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
	// console.log('state', state)
	return {
        caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : {},
        alertsList: state.aiops.caseDetails.results && state.aiops.caseDetails.results.alerts ? state.aiops.caseDetails.results.alerts : [],
        assetsList: state.aiops.caseDetails.results && state.aiops.caseDetails.results.assets ? state.aiops.caseDetails.results.assets : [],
        aiopsPropsDetails: state.aiops.aiopsPropsDetails ? state.aiops.aiopsPropsDetails : {},
    }
}

export default connect(mapStateToProps, {
    setAiopsPropsDetails
})(withRouter(AssetsLeftSection))