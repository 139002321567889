import React from 'react';


class CommonDropdown extends React.Component {
    node = React.createRef()
    constructor(props) {
        super(props)
        //console.log(this.props)
        this.state = {
            displayMenu: this.props.displayMenu,
            hideHeader: this.props.hideHeader,
            headerName: this.props.headerName,
            dropOptions: this.props.dropOptions,
            selectedSearch: this.props.selectedSearch,
            isDefaultDropdown: this.props.isDefaultDropdown,
            isMultiSelect: this.props.isMultiSelect,
            selectedOptions: [],
            isChecked: [],
            styleTop: this.props.styleTop,
            searchText: '',
            searchOption: this.props.searchOption,
            showLabel: this.props.label,
        };
        // this.showDropdownMenu = this.showDropdownMenu.bind(this);
        // this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    };

    showDropdownMenu = () => {
        this.setState({ displayMenu: 
            this.state.isMultiSelect ? this.state.displayMenu ? false : true :
            this.state.displayMenu ? false : true },
            () => this.getSearchResult()
            //() => document.addEventListener('click', this.showDropdownMenu)
        );
    }

    hideDropdownMenu = () => {
        this.setState({ displayMenu: false }, () => {
            //document.removeEventListener('click', this.hideDropdownMenu);
        });

    }

    selectedDropValue = (label, value, index, checked) => {
        if(this.state.isMultiSelect === true) {
            // let isChecked = this.state.isChecked.slice();
            // isChecked[index] = e.target.checked;
            // this.setState({ isChecked })
            // alert(index)
            // let ref = 'ref_' + index;
            // console.log(this.refs[ref].checked)
            // console.log(checked)
            // if(this.refs[ref].checked === false) {
            //     alert(123)
            //     this.refs[ref].checked = !this.refs[ref].checked
            // }


            // if(checked === true && this.state[index] === undefined) {
            //     alert(index)
            //     this.setState({ [index]: false})
            // }


            // if(this.refs[ref].checked === true || this.refs[ref].checked === null) {
            //     this.refs[ref].checked = null
            // } else {
            //     this.refs[ref].checked = null
            // }
            
            //this.setState({ displayMenu: true })
        } else if(!this.state.isDefaultDropdown) {
            this.setState({ 
                headerName: this.state.searchOption ? this.props.searchOptionWithSingleSelect ? label : this.state.headerName : label, 
                displayMenu: this.state.searchOption ? this.props.searchOptionWithSingleSelect ? false : true : false 
            })
        }
        return this.props.data(value, label)
    }

    handleMultiSelectChange = (e, index, value) => {
        let isChecked = this.state.isChecked.slice();
        isChecked[index] = e.target.checked;
        this.setState({ isChecked })

        return this.props.data(value)
    }

    // handleMultiSelectChange = (value) => {
    //     return this.props.data(value)
    //     // let array = this.state.selectedOptions
    //     // array.push(value)
    //     this.setState({ selectedOptions: array },
    //         () => this.arrayState()
    //     )
    // }

    arrayState = () => {
    }

    getSearchResult = () => {
        let filterData = this.props.dropOptions
        if(this.state.searchText !== '') {
			filterData = filterData.filter(
				filter => 
				filter.label.toLocaleLowerCase().includes(this.state.searchText) ||
				filter.value.includes(this.state.searchText)
            )
            
            this.setState({ dropOptions: filterData })
        } else {
            this.setState({ dropOptions: filterData })
        }
        
    }

    // handleClickOutside(event) {
    //     alert(123)
	// 	console.log('-------------',event.target)
	// 	if (this.dropDown && !this.dropDown.current.contains(event.target)) {
	// 		this.setState({ displayMenu: false })
	// 	}
    // }

    // useEffect = () => {
    //     // add when mounted
    //     document.addEventListener("mousedown", handleClick);
    //     // return function to be called when unmounted
    //     return () => {
    //       document.removeEventListener("mousedown", handleClick);
    //     };
    // }

    // handleClick = e => {
    //     if (this.node.current.contains(e.target)) {
    //       alert(123)
    //       return;
    //     }
    //     // outside click 
    //     //... do whatever on click outside here ...
    // };

    render() {
        return (
            <div className="dropdown w-100" 
                //onBlur={() => this.setState({displayMenu: false})} 
                //ref={this.node}
            >
                <p 
                    className={`text-white m-0 p-0 f14 font-weight-bold 
                        ${this.state.showLabel !== '' ? '' : 'displayNone'} 
                    `}>
                        {this.state.showLabel !== '&nbsp;' ? 
                            this.state.showLabel : 
                            <span>&nbsp;</span>
                        }
                </p>
                <div className={`customDropdown ${this.props.disabled ? 'disabled' : '' } ${this.state.hideHeader ? 'displayNone' : ''} `} onClick={() => this.showDropdownMenu()}>
                    {this.state.searchOption ?
                        <input type="text" 
                            value={this.state.searchText} 
                            onChange={e => {this.setState({ searchText: e.target.value, displayMenu: true }, 
                                () => this.getSearchResult()
                            )}} 
                            onClick={() => this.showDropdownMenu() }
                            placeholder={this.state.headerName}
                            className='border-0'
                        />
                    :
                        this.state.headerName
                    }
                </div>
                
                { this.state.displayMenu && this.state.dropOptions.length ? (
                    <ul className={`${this.state.styleTop ? this.state.styleTop : '' }`}>
                        {this.state.isMultiSelect ?
                            this.state.dropOptions.map((drop, index) =>
                                <li key={drop.value}>
                                    <input
                                        type="checkbox" 
                                        id={drop.value}
                                        ref={'ref_' + index}
                                        //checked={this.state[index] && !this.state[index] ? null : drop.checked}
                                        //checked={drop.checked ? drop.checked : null}
                                        //checked={this.state[index]}

                                        onClick={ () => this.selectedDropValue(drop.label, drop.value, index, drop.checked) }
                                        //onChange={(e) => this.handleMultiSelectChange(e, index, drop.value)}
                                    />
                                    <label className="checkBox"> <span>{drop.label}</span> </label>
                                </li>
                            )
                        :
                            this.state.dropOptions.map((drop, index) =>
                                <li onClick={ () => this.selectedDropValue(drop.label, drop.value, index, 0) } key={drop.value}>
                                    <label className="checkBox"> <span>{drop.label}</span> </label>
                                </li>
                            )
                        }
                    </ul>
                ):
                (
                    null
                )
            }

        </div>

        );
    }
}

export default CommonDropdown;
