/*************************************************
 * Tvastar
 * @exports
 * @file ConfigurationDetails.js
 * @author Rajasekar // on 11/03/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ConfigurationDetails extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	render() {
		return (
			<div className='box p-3 bg-dark3'>
				<div className='d-flex'>
					<p className='flex-grow-1 mb-2 text-white'>
						<b>Configuration Details</b>
					</p>
				</div>
				{this.props.lambdaFunctionRuntime ? (
					<div className='d-flex mb-2 border-bottom'>
						<div className='py-2'>Runtime</div>
						<div className='flex-grow-1 text-right py-2 text-white'>{this.props.lambdaFunctionRuntime}</div>
					</div>
				) : null}
				{this.props.lambdaFunctionMemorySize ? (
					<div className='d-flex mb-2 border-bottom'>
						<div className='py-2'>Memory</div>
						<div className='flex-grow-1 text-right py-2 text-white'>{this.props.lambdaFunctionMemorySize}</div>
					</div>
				) : null}
				{this.props.lambdaFunctionTimeout ? (
					<div className='d-flex mb-2 border-bottom'>
						<div className='py-2'>Timeout</div>
						<div className='flex-grow-1 text-right py-2 text-white'>{this.props.lambdaFunctionTimeout}</div>
					</div>
				) : null}
				{this.props.lambda_function_role ? (
					<div className='d-flex mb-2 border-bottom'>
						<div className='py-2'>IAM Role</div>
						<div className='flex-grow-1 text-right py-2 text-white'>{this.props.lambda_function_role}</div>
					</div>
				) : null}
				{this.props.lambda_function_code_size ? (
					<div className='d-flex mb-2 border-bottom'>
						<div className='py-2'>Code size</div>
						<div className='flex-grow-1 text-right py-2 text-white'>{this.props.lambda_function_code_size}</div>
					</div>
				) : null}
				{this.props.layers ? (
					<div className='d-flex mb-2 border-bottom'>
						<div className='py-2'>Layers </div>
						<div className='flex-grow-1 text-right py-2 text-white'>{this.props.layers}</div>
					</div>
				) : null}
				{this.props.lambda_function_tracing_config_mode ? (
					<div className='d-flex mb-2 border-bottom'>
						<div className='py-2'>Active Tracing</div>
						<div className='flex-grow-1 text-right py-2 text-white'>{this.props.lambda_function_tracing_config_mode}</div>
					</div>
				) : null}
				{this.props.lambda_function_handler ? (
					<div className='d-flex mb-2 border-bottom'>
						<div className='py-2'>Handler</div>
						<div className='flex-grow-1 text-right py-2 text-white'>{this.props.lambda_function_handler}</div>
					</div>
				) : null}
				{this.props.lambda_function_configuration_versions ? (
					<div className='d-flex mb-2 border-bottom'>
						<div className='py-2'>Environment variables</div>
						<div className='flex-grow-1 text-right py-2 text-white'>{this.props.lambda_function_configuration_versions}</div>
					</div>
				) : null}
				{this.props.lambda_function_configuration_versions ? (
					<div className='d-flex mb-2 border-bottom'>
						<div className='py-2'>Project</div>
						<div className='flex-grow-1 text-right py-2 text-white'>{this.props.lambda_function_configuration_versions}</div>
					</div>
				) : null}
			</div>
		)
	}
}
/**
 * Type of the props used in the component
 */
ConfigurationDetails.propTypes = {
	lambda_function_configuration_versions: PropTypes.string,
	lambda_function_handler: PropTypes.string,
	lambda_function_tracing_config_mode: PropTypes.string,
	layers: PropTypes.string,
	lambda_function_code_size: PropTypes.string,
	lambdaFunctionTimeout: PropTypes.string,
	lambdaFunctionMemorySize: PropTypes.string,
	lambda_function_role: PropTypes.string,
	lambdaFunctionRuntime: PropTypes.string,
}

export default ConfigurationDetails
