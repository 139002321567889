
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from 'react'
import { setEditRuleDetails, addContainerImage } from '../../../actions/governance/governanceAction'
import { listAllRegions, listAllAccounts, listAllServices, apiToCall } from '../../../actions/commonAction'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { DURATION_FILTER_OPTIONS } from '../../../utils/constants'
import { currentUTCtime, isNumber, onlyNumeric, getRegionName, getAccountNameFromId } from '../../../utils/utility'
import { Input } from 'reactstrap'

class ServiceContainers extends Component {
	constructor(props) {
		super(props)
		this.searchRef = React.createRef()
		this.regionRef = React.createRef()
		this.searchRepositoryRef = React.createRef()
		this.scrollRef = React.createRef() 

		this.state = {
			startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
			perPage: 3,
			
			hasErrorInRuleForm: false,
			hasError: this.props.hasError,
			form: {
				account_id: '',
				region: '',
				repository_name: '',
				image_id: '',
				services: [],
				decision: 'allow',
			},
			containers: [],
			containerResponse: '',

			newSearchText: '',
			selectedAccount: [],
			selectedRegion: [],
			isRegionOpen: false,
			latestImageVersion: true,
			onlyScannedContainers: true,
			onlyEncryptedContainers: true,
			onlyKMSEncryptedContainers: true,
			selectedRepository: [],
			selectedDuration: 'Last 3 months',
			searchSavedContainersText: '',
			condition: this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedContainers ? this.props.editRuleDetails.condition.allowedContainers : [],
			filterCondition: this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedContainers ? this.props.editRuleDetails.condition : [],
		}
	}

	componentDidMount = () => {
		let testCondition = {}
		
		// testCondition['account'] = ['All']
		testCondition['account'] = []
		testCondition['repository'] = []
		testCondition['region'] = ['All']
		testCondition['latestImageVersion'] = true
		testCondition['onlyScannedContainers'] = true
		testCondition['onlyEncryptedContainers'] = true
		testCondition['onlyKMSEncryptedContainers'] = true
		testCondition['duration'] = this.state.selectedDuration
		this.props.setEditRuleDetails('testCondition', testCondition)
		this.setState({ 
			accounts: [],
			accountSearchResult: [],
			regions: [],
		},
			() =>  {
				this.getAllFilters()
			}
		)
	}

	componentDidUpdate = (prevProps) => {
		if ( this.props.editRuleDetails.condition !== prevProps.editRuleDetails.condition ) {
			let condition = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedContainers ? this.props.editRuleDetails.condition.allowedContainers : []
			this.setState({ condition }, 
				() => this.searchSavedContainers()
			)
		}
		
		if(this.props.hasError !== this.state.hasError) {
			this.setState({ hasError: this.props.hasError })
		}
	}

	getAllFilters = () => {
		let params = {
			provider: this.props.editRuleDetails && this.props.editRuleDetails.provider.toLowerCase(),
		}

		if(this.props.accounts && this.props.accounts.length) {
			let accounts = this.props.accounts.filter(arr => arr.account !== 'All');
			
			this.setState({ accounts, accountSearchResult: accounts, repositorySearchResult: accounts })
		} else {
			this.props.listAllAccounts(params, (promise, accounts) => {
				if (promise) {
					this.setState({ accounts, accountSearchResult: accounts, repositorySearchResult: accounts })
				}
			})
		}
		
		if(this.props.regions && this.props.regions.length) {
			let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
			this.setState({ regions: providerRegions })
		} else {
			this.getRegions()
		}
	}

	getRegions = () => {
		let params = {
			provider: this.props.editRuleDetails && this.props.editRuleDetails.provider.toLowerCase(),
		}
		
		this.props.listAllRegions(params, (promise, regions) => {
			if (promise) {
				let providerRegions = regions && regions[0] && regions[0].provider_regions
				if(providerRegions) {
					let provider_regions_row = {}
					provider_regions_row.availability_zones = []
					provider_regions_row.city = "All"
					provider_regions_row.country = "All"
					provider_regions_row.geography = "All"
					provider_regions_row.name = "All"
					provider_regions_row.region = "All"
					provider_regions_row.region_id = "All"
					provider_regions_row.state = "All"
					providerRegions.unshift(provider_regions_row);
				}
				this.setState({ regions: providerRegions })
			} else {
				this.setState({ regions: [] })
			}
		})
	}

	handleChange = event => {
		this.setState({ containerResponse: '', form: { ...this.state.form, [event.target.name]: event.target.value } })
	}

	removeContainerImage = index => {
		var images = this.state.containers
		images.splice(index, 1)
		this.props.setEditRuleDetails('testCondition', { containers: images })
	}

	handleChipChange = (value, name) => {
		this.setState({ containerResponse: '', form: { ...this.state.form, [name]: value.map(item => item.value) } })
	}

	addContainerImage = () => {
		this.setState({ containerResponse: '' })
		this.props.addContainerImage(
			{ ...this.state.form, provider: this.props.parameters.provider },
			(success, response) => {
				if (success) {
					if (response.status) {
						if (Array.isArray(response.results)) {
							this.setState({ containers: response.results })
							this.props.setEditRuleDetails('testCondition', { containers: response.results })
						} else {
							let temp = [{ ...this.state.form, ...response.results }]
							this.setState({ containers: temp })
							// temp.push(response.results)
							this.props.setEditRuleDetails('testCondition', { containers: temp })
						}
					}
				} else {
					this.setState({ containerResponse: 'Image not found' })
				}
			}
		)
	}

	handleCondition = (conditionValue, value, conditionLabel, label) => {
		let selectedValue = []
		if(conditionValue === 'account') {
			selectedValue = this.state.selectedAccount
			selectedValue.push(value)
			this.setState({ newSearchText: '' })
		} else if(conditionValue === 'selectedRegion') {
			selectedValue = this.state.selectedRegion
		} else if(conditionValue === 'repository') {
			selectedValue = this.state.selectedRepository
			selectedValue.push(value)
			this.setState({ newRepositorySearchText: '' })
		} else {
			selectedValue = value
		}
		let testCondition = this.props.editRuleDetails.testCondition && this.props.editRuleDetails.testCondition ? this.props.editRuleDetails.testCondition && this.props.editRuleDetails.testCondition : {}
		testCondition[conditionValue] = selectedValue
		this.props.setEditRuleDetails('testCondition', testCondition)
	}

	handleConditionOptionTable = (value) => {
		let testCondition = this.props.editRuleDetails.testCondition && this.props.editRuleDetails.testCondition ? this.props.editRuleDetails.testCondition && this.props.editRuleDetails.testCondition : {}
		testCondition[value] = this.state[value]
		
		this.props.setEditRuleDetails('testCondition', testCondition)
	}

	handleDurationCondition = () => {
		let testCondition = this.props.editRuleDetails.testCondition && this.props.editRuleDetails.testCondition ? this.props.editRuleDetails.testCondition && this.props.editRuleDetails.testCondition : {}
		
		this.props.setEditRuleDetails('testCondition', testCondition)
	}

	handleKeyPress = (event) => {
		if(event.charCode === 13 || event.keyCode === 13){
			this.setState({ hasErrorInRuleAccount: false, showDropdown: false })
			if(this.state.newSearchText !== '' && this.state.newSearchText.length === 12) {
				this.handleCondition('account', this.state.newSearchText, '', this.state.newSearchText)
			} else if(this.state.newSearchText.length < 12) {
				this.setState({ hasErrorInRuleAccount: true })
			}
		}
	}

	getSearchAccountList = () => {
		let accountSearchResult = []
		this.state.accounts.length && this.state.accounts.forEach(item => {
			if(item.account_id.includes(this.state.newSearchText)) {
				accountSearchResult.push(item)
			}
		})
		this.setState({ accountSearchResult, showDropdown: true, hasErrorInRuleAccount: false },
			//() => this.handleCondition('account', this.state.newSearchText, '', this.state.newSearchText)
		)
	}

	getSearchRepositorytList = () => {
		let repositorySearchResult = []
		this.state.accounts.length && this.state.accounts.forEach(item => {
			if(item.account.includes(this.state.newRepositorySearchText)) {
				repositorySearchResult.push(item)
			}
		})
		this.setState({ repositorySearchResult, showRepositoryDropdown: true },
			() => this.handleCondition('repository', this.state.newRepositorySearchText, '', this.state.newRepositorySearchText)
		)
	}

	handleRepositoryKeyPress = (event) => {
		let hasRepositoryError = false
		if(event.charCode === 13 || event.keyCode === 13){
			this.setState({ hasRepositoryError: false, showDropdown: false })
			if(this.state.newRepositorySearchText !== '') {
				let repository = this.state.newRepositorySearchText
				let split = repository.split('.')
				// let acc = repository.slice(0,12)
				if(split.length !== 6) {
					//hasRepositoryError = true
				} else {
					let acc = split[0]
					let getaccLength = isNumber(acc)
					if(acc.length !== 12 || !getaccLength) {
						hasRepositoryError = true
					} else {
						let service = split[2]
						if(service !== 'ecr') {
							hasRepositoryError = true
						}
						// let serviceExist = this.state.serviceArray.filter((item) => item.service_name === service);
						// if(serviceExist) {
						// 	alert(123)
						// }
						let region = split[3]
						let regionExist = this.state.regions.filter((item) => item.region === region);
						if(!regionExist) {
							hasRepositoryError = true
						}
						let splitRepo = split[5].split('/')
						if(splitRepo.legnth !== 2) {
							hasRepositoryError = true
						} else {
							// let repo = splitRepo[1]
						}
					}
				}
				if(!hasRepositoryError) {
					this.handleCondition('repository', this.state.newRepositorySearchText, '', this.state.newRepositorySearchText)
				} else {
					this.setState({ hasRepositoryError })
				}
			}
		}
	}

	handleClickOutside(event) {
		if (this.searchRef && !this.searchRef.current && !this.searchRef.current.contains(event.target)) {
			this.setState({ showDropdown: false })
		}

		if (this.searchRepositoryRef && !this.searchRepositoryRef.current.contains(event.target)) {
			this.setState({ showRepositoryDropdown: false })
		}

		if (this.regionRef && !this.regionRef.current.contains(event.target)) {
			this.setState({ isRegionOpen: false })
		} else {
			this.setState({ isRegionOpen: true })
		}
	}

	addUpdateContainerArray = (type) => {
		
		let condition = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedContainers ? this.props.editRuleDetails.condition.allowedContainers : []
		let testCondition = this.props.editRuleDetails.testCondition && this.props.editRuleDetails.testCondition ? this.props.editRuleDetails.testCondition && this.props.editRuleDetails.testCondition : {}
		let hasErrorInRuleForm = false
		let hasRepositoryError = false

		if((!testCondition.account || (testCondition.account && !testCondition.account.length)) && (!testCondition.repository || (testCondition.repository && !testCondition.repository.length))){
			hasErrorInRuleForm = true
		}
		if((!this.props.editRuleDetails.testCondition && !this.props.editRuleDetails.testCondition.region) || (this.props.editRuleDetails.testCondition && this.props.editRuleDetails.testCondition.region === '')) {
			hasErrorInRuleForm = true
		}

		if(this.props.editRuleDetails.testCondition && this.props.editRuleDetails.testCondition.repository && this.props.editRuleDetails.testCondition && this.props.editRuleDetails.testCondition.repository !== '') {
			// let repository = this.props.editRuleDetails.testCondition && this.props.editRuleDetails.testCondition.repository
			// let split = repository.split('.')
			// // let acc = repository.slice(0,12)
			// if(split.length !== 6) {
			// 	//hasRepositoryError = true
			// } else {
			// 	let acc = split[0]
			// 	let getaccLength = isNumber(acc)
			// 	if(acc.length !== 12 || !getaccLength) {
			// 		hasRepositoryError = true
			// 	} else {
			// 		let service = split[2]
			// 		if(service !== 'ecr') {
			// 			hasRepositoryError = true
			// 		}
			// 		// let serviceExist = this.state.serviceArray.filter((item) => item.service_name === service);
			// 		// if(serviceExist) {
			// 		// 	alert(123)
			// 		// }
			// 		let region = split[3]
			// 		let regionExist = this.state.regions.filter((item) => item.region === region);
			// 		if(!regionExist) {
			// 			hasRepositoryError = true
			// 		}
			// 		let splitRepo = split[5].split('/')
			// 		if(splitRepo.legnth !== 2) {
			// 			hasRepositoryError = true
			// 		} else {
			// 			let repo = splitRepo[1]
			// 		}
			// 	}
			// }
		}

		this.setState({ hasErrorInRuleForm, hasRepositoryError })
		if(!hasErrorInRuleForm && !hasRepositoryError && Object.entries(testCondition).length) {
			testCondition['timestamp'] = currentUTCtime()
			if(type === 'Add') {
				testCondition['rowIndex'] = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedContainers ? this.props.editRuleDetails.condition.allowedContainers.length : 0
				condition.push(testCondition)
			} else {
				condition[this.state.rowIndex] = testCondition
			}
			this.setState({
				condition
			}, () => this.searchSavedContainers())

			let formCondition = {}
			formCondition['allowedContainers'] = condition
			this.props.setEditRuleDetails('condition', formCondition)
			
			let newCondition = {}
			newCondition['latestImageVersion'] = true
			newCondition['onlyScannedContainers'] = true
			newCondition['onlyEncryptedContainers'] = true
			newCondition['onlyKMSEncryptedContainers'] = true
			newCondition['region'] = 'All'
			newCondition['duration'] = 'Last 3 months'

			this.props.setEditRuleDetails('testCondition', newCondition)
			this.setState({ 
				editEnabled: false,
				newSearchText: '',
				selectedAccount: [],
				selectedRegion: [],
				newRepositorySearchText: '',
				selectedRepository: '',
				latestImageVersion: true,
				onlyScannedContainers: true,
				onlyEncryptedContainers: true,
				onlyKMSEncryptedContainers: true,
				selectedDuration: 'Last 3 months'
			})
		}
	}

	actionOnContainerData = (action, index) => {
		if(action === 'edit') {
			this.scrollRef.current.scrollIntoView({ behavior: 'smooth' })
			let editCondtion = {}
			editCondtion['account'] = this.props.editRuleDetails.condition.allowedContainers[index].account ? this.props.editRuleDetails.condition.allowedContainers[index].account : ''
			editCondtion['region'] = this.props.editRuleDetails.condition.allowedContainers[index].region
			editCondtion['repository'] = this.props.editRuleDetails.condition.allowedContainers[index].repository ? this.props.editRuleDetails.condition.allowedContainers[index].repository : ''
			editCondtion['duration'] = this.props.editRuleDetails.condition.allowedContainers[index].duration
			editCondtion['latestImageVersion'] = this.props.editRuleDetails.condition.allowedContainers[index].latestImageVersion
			editCondtion['onlyScannedContainers'] = this.props.editRuleDetails.condition.allowedContainers[index].onlyScannedContainers
			editCondtion['onlyEncryptedContainers'] = this.props.editRuleDetails.condition.allowedContainers[index].onlyEncryptedContainers
			editCondtion['onlyKMSEncryptedContainers'] = this.props.editRuleDetails.condition.allowedContainers[index].onlyKMSEncryptedContainers
			editCondtion['rowIndex'] = index

			this.props.setEditRuleDetails('testCondition', editCondtion)
			this.setState({ 
				editEnabled: true,
				rowIndex: index,
				newSearchText: '',
				selectedAccount: this.props.editRuleDetails.condition.allowedContainers[index].account,
				newRepositorySearchText: this.props.editRuleDetails.condition.allowedContainers[index].repository,
				selectedRepository: this.props.editRuleDetails.condition.allowedContainers[index].repository,
				latestImageVersion: this.props.editRuleDetails.condition.allowedContainers[index].latestImageVersion,
				onlyScannedContainers: this.props.editRuleDetails.condition.allowedContainers[index].onlyScannedContainers,
				onlyEncryptedContainers: this.props.editRuleDetails.condition.allowedContainers[index].onlyEncryptedContainers,
				onlyKMSEncryptedContainers: this.props.editRuleDetails.condition.allowedContainers[index].onlyKMSEncryptedContainers,
				selectedDuration: this.props.editRuleDetails.condition.allowedContainers[index].duration,
				selectedRegion: editCondtion['region']
			})
			
		} else {
			let condition = {}
			condition['allowedContainers'] = this.state.condition.filter((item) => item.rowIndex !== index);
			this.props.setEditRuleDetails('condition', condition)
			this.setState({ condition, filterCondition: condition, searchSavedContainersText: '' })
		}
	}
	
	clearSelectedContainerData = () => {
		let newCondition = {}
		newCondition['latestImageVersion'] = true
		newCondition['onlyScannedContainers'] = true
		newCondition['onlyEncryptedContainers'] = true
		newCondition['onlyKMSEncryptedContainers'] = true
		newCondition['region'] = 'All'

		this.props.setEditRuleDetails('testCondition', newCondition)

		this.setState({ 
			editEnabled: false,
			newSearchText: '',
			selectedAccount: [],
			newRepositorySearchText: '',
			selectedRepository: '',
			latestImageVersion: true,
			onlyScannedContainers: true,
			onlyEncryptedContainers: true,
			onlyKMSEncryptedContainers: true,
			selectedDuration: 'Last 3 months'
		})
	}

	searchSavedContainers = () => {
		let filterCondition = this.state.condition
		if (this.state.searchSavedContainersText !== '') {
			filterCondition =
				filterCondition &&
				filterCondition.filter(item => {
					let isPresent = []
					isPresent = this.recursiveSearch(item, this.state.searchSavedContainersText)
						.flat()
						.filter(bool => bool === true)
					if (isPresent[0]) {
						return true
					} else {
						return false
					}
				})
		}
		let totalPages = 1
		if(filterCondition.length > this.state.perPage) {
			totalPages = Math.ceil(filterCondition.length / this.state.perPage)
		}
		this.setState({ filterCondition, totalPages, startRecord: 0, currentPage: 1 })
	}

	recursiveSearch = (item, searchValue) => {
		return Object.keys(item).map(key => {
			if (item[key] !== null && typeof item[key] === 'object') {
				return this.recursiveSearch(item[key], searchValue)
				// eslint-disable-next-line valid-typeof
			} else if (typeof item[key] === 'array') {
				return item.map((arrayElement, i) => this.recursiveSearch(arrayElement[i], searchValue))
			} else {
				// if(this.props.searchFilter.includes(key)){
				return item[key] !== null
					? item[key]
							.toString()
							.toLowerCase()
							.indexOf(searchValue.toString().toLowerCase()) > -1
					: false
				// }else{
				// return false;
				// }
			}
		})
	}

	navigatePage = (action, currentPage) => {
		let startRecord = this.state.startRecord
		if(action === 'next' && currentPage !== this.state.totalPages) {
			startRecord = startRecord + this.state.perPage
			currentPage = currentPage + 1
		} else if(action === 'previous' && currentPage !== 0) {
			currentPage = currentPage - 1
			startRecord = startRecord - this.state.perPage
		} else if(action === 'start' && currentPage !== 0) {
			currentPage = 1
			startRecord = 0
		} else if(action === 'end' && currentPage !== 0) {
			currentPage = this.state.totalPages
			startRecord = (this.state.totalPages - 1) * this.state.perPage
		}
		this.setState({ currentPage, startRecord })
	}

	// getSelectedDateLabel = (selectedDurationLabel) => {
	// 	DURATION_FILTER_OPTIONS.map(item => {
	// 		if(item.option === selectedDurationLabel) {
	// 			this.setState({ selectedDuration: selectedDurationLabel})
	// 		}
	// 	})
	// }

	removeSelectedOption = (section, index, option) => {
		let testCondition = this.props.editRuleDetails.testCondition && this.props.editRuleDetails.testCondition ? this.props.editRuleDetails.testCondition && this.props.editRuleDetails.testCondition : {}

		let sectionState = []
		let details = []
		if(section === 'account') {
			sectionState = this.state.selectedAccount
			details = sectionState.filter(e => e !== option)
		} else if(section === 'region') {
			sectionState = this.state.selectedRegion
			details = sectionState.filter(e => e !== option.value)
		}
		if(section === 'account') {
			this.setState({ selectedAccount: details })
			testCondition['account'] = details
		} else if(section === 'region') {
			this.setState({ selectedRegion: details })
			testCondition['region'] = details
		}

		
		this.props.setEditRuleDetails('testCondition', testCondition)
	}
	
	getMultiSelectedCount = (type, array) => {
		if(array) {
			return array.length && array.includes('All') ? 'All' :  array.length ? array.length +' Selected' : 'All'
		} else {
			return 'All'
		}
	}

	handleMultiSelectChange = (field, arrayValue) => {
		let value = arrayValue.map(item => item.value)
		// let onlyLabel = arrayValue.map(item => item.label)
		// let label = arrayValue.map(item => item)
		let selectedValue = []
		if(field === 'selectedRegion') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.regions.length -1)) {
					selectedValue.push('All')
				}
			}

			this.setState({ [field]: selectedValue },
				() => this.handleCondition('region', this.state.selectedRegion, '', '')
			)
		}
	}
	
	render() {
		return (
			<React.Fragment>
				<div className="row" ref={this.scrollRef}  onClick={ (event) => { this.handleClickOutside(event) } }> 
					<div className="col-lg-3 border-right">
						<div className="rounded bg-dark p-3 h-100">
							<h6 className="text-info">Container Details</h6>

							<div className="row zapInputDark">
								<div className="col-md-12">
									<div className="form-group">
										<label>Choose Account</label>
										<div className="searchDropText">
											<Input
												ref={this.searchRef}
												type='text'
												value={this.state.newSearchText}
												maxlength="12"
												minlength="12"
												onChange={e => {
													this.setState({ newSearchText: onlyNumeric(e.target.value)}, 
														() => this.getSearchAccountList()
													)
												}}
												onKeyDown={this.handleKeyPress}
												className="inputTextbox"
												placeholder='Enter Account Number'
												onClick={(e => this.setState({ showDropdown : true }))}
											/>
											<div className="d-flex">
												<div className="flex-grow align-self-center">
													{this.state.selectedAccount.length ? this.state.selectedAccount.map((item, index) => {
														return(
															item.label !== 'All' ?
																<span key={index} className="badge badge-light f14 mr-1 mb-1 mt-1" >
																	{getAccountNameFromId(item,this.state.accounts)}
																	<i onClick={ () => this.removeSelectedOption('account', index, item) } className='ml-1 fal fa-times cursorPointer'></i>
																</span>
															:
															null
														)
													})
													: null
													}
												</div>
											</div>
											{this.state.hasErrorInRuleForm && (!this.state.selectedAccount.length && !this.state.selectedRepository.length) ? (
												<p className='text-danger m-0 p-0'>Account is Required.</p>
											) : 
												this.state.hasErrorInRuleAccount ? 
													<p className='text-danger m-0 p-0'>Account is Invalid.</p>
												: null
											}
											{this.state.showDropdown && this.state.accountSearchResult && this.state.accountSearchResult.length ? 
												<div className={`dropdownOptions topAuto mt-2`}>
													{this.state.accountSearchResult.map((item, index) => {
														return (
															<React.Fragment>
																<button className="d-flex flex-column mb-3" onClick ={() => this.setState({ newSearchText: item.account_id, showDropdown: false }, () => this.handleCondition('account', this.state.newSearchText, '', this.state.newSearchText ))}>
																	<div className="d-flex justify-content-between w-100">
																		<div>
																			<p className="mb-0 text-left">{item.account_id}</p>
																			{/* <p className="mb-0 small description">{prevAct.action_name}</p> */}
																		</div>
																	</div>
																</button>
															</React.Fragment>
														)
													})}
												</div>
											: null}
										</div>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group">
										<label>Choose Region</label>
										<div className={`multiselectDarkTheme align-self-center mr-2 z998 ${this.state.regions && this.state.regions.length > 10 ? '' : 'removeDropdownSearchBar' } `} ref={this.regionRef}>
											<ReactMultiSelectCheckboxes						
												placeholderButtonLabel="All"
												getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedRegion', this.state.selectedRegion)}
												// menuIsOpen ={this.state.isRegionOpen}
												value={this.state.selectedRegion.map(user => ({
													value: user
												}))}
												onChange={arr => {
												this.handleMultiSelectChange('selectedRegion', arr ? arr : []) }}
												options={this.state.regions && this.state.regions.map(region => ({
													value: region.region,
													label: region.name,
												}))}
											/>
										</div>
										<div className="d-flex">
											<div className="flex-grow align-self-center">
												{this.state.selectedRegion.length ? this.state.selectedRegion.map((item, index) => {
													return(
														item !== 'All' ?
															<span key={index} className="badge badge-light f14 mr-1 mb-1 mt-1" >
																{getRegionName(item, this.state.regions)}
																<i onClick={ () => this.removeSelectedOption('selectedRegion', index, item) } className='ml-1 fal fa-times cursorPointer'></i>
															</span>
														:
														null
													)
												})
												: null
												}
											</div>
										</div>
										{this.state.hasErrorInRuleForm && ((!this.props.editRuleDetails.testCondition && this.props.editRuleDetails.testCondition.region) || (this.props.editRuleDetails.testCondition && this.props.editRuleDetails.testCondition.region === '')) ? (
											<p className='text-danger m-0 p-0'>Region is Required.</p>
										) : null}
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group">
										<label>Choose Repositroy</label>
										<div className="searchDropText w-100">
											<div ref={this.searchRepositoryRef}>
												<Input
													type='text'
													value={this.state.newRepositorySearchText}
													onChange={e => {
														this.setState({ newRepositorySearchText: e.target.value }, 
															//() => this.getSearchRepositorytList()
														)
													}}
													onKeyDown={this.handleRepositoryKeyPress}
													className="inputTextbox"
													placeholder='All'
													onClick={(e => this.setState({ showRepositoryDropdown : true }))}
												/>
											</div>
											<div className="d-flex">
												<div className="flex-grow align-self-center">
													{this.state.selectedRepository && this.state.selectedRepository.length ? this.state.selectedRepository.map((item, index) => {
														return(
															item.label !== 'All' ?
																<span key={index} className="badge badge-light f14 mr-1 mb-1 mt-1" >
																	{item}
																	<i onClick={ () => this.removeSelectedOption('repository', index, item) } className='ml-1 fal fa-times cursorPointer'></i>
																</span>
															:
															null
														)
													})
													: null
													}
												</div>
											</div>
											{this.state.hasErrorInRuleForm && (!this.state.selectedAccount.length && this.state.selectedRepository.length) 
											? (
												<p className='text-danger m-0 p-0'>Repository is Required.</p>
											) : null}

											{this.state.hasRepositoryError ? (
												<p className='text-danger m-0 p-0'>Repository is invalid.</p>
											) : null}
											{/* <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"></svg> */}	
											
											{this.state.showRepositoryDropdown && this.state.repositorySearchResult && this.state.repositorySearchResult.length ?
												<div className={`dropdownOptions topAuto mt-2`}>
													{this.state.repositorySearchResult.map((item, index) => {
														return (
															<React.Fragment>
																<button className="d-flex flex-column mb-3"onClick ={() => this.setState({ newRepositorySearchText: item.account_id, showRepositoryDropdown: false }, () => this.handleCondition('repository', this.state.newRepositorySearchText, '', this.state.newRepositorySearchText ))}>
																	<div className="d-flex justify-content-between w-100">
																		<div>
																			<p className="mb-0 text-left">{item.account_id}</p>
																			{/* <p className="mb-0 small description">{prevAct.action_name}</p> */}
																		</div>
																	</div>
																</button>
															</React.Fragment>
														)
													})}
												</div>
											: null}
										</div>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group">
										<label>Allow Container Created between Date Range</label>
										<Select
											// placeholder='Select'
											/* menuIsOpen={true} */
											isSearchable={false}
											//onFocus={this.openProivderMenu}
											className={`${this.props.ruleDetails && Object.entries(this.props.ruleDetails).length ? 'disabled disabledBg' : ''}`}
											value={({
												value: this.state.selectedDuration,
												label: this.state.selectedDuration ? this.state.selectedDuration : <span className="placeholder">Select</span>
											})}
											options={DURATION_FILTER_OPTIONS.map(item => ({
												value: item.option,
												label: item.option,	
											}))}
											onChange={event => this.setState({ selectedDuration: event.value },
												() => this.handleCondition('duration', this.state.selectedDuration, '', '' )
											)}
										/>
									</div>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-md-12">
									<div className="form-group form-check mb-1">
										<input 
											type="checkbox" 
											className="form-check-input" 
											id="imageVersion" 
											checked={this.state.latestImageVersion ? true : false}
											onChange={e => this.setState({ latestImageVersion: this.state.latestImageVersion ? false : true },
													() => this.handleConditionOptionTable('latestImageVersion') 
												)
											}
										/>
										<label className="form-check-label" for="imageVersion">Allow Only latest Image Version</label>
									</div>
									<div className="form-group form-check mb-1">
										<input 
											type="checkbox" 
											className="form-check-input" 
											id="scannedContainers" 
											checked={this.state.onlyScannedContainers ? true : false}
											onChange={e => this.setState({ onlyScannedContainers: this.state.onlyScannedContainers ? false : true },
													() => this.handleConditionOptionTable('onlyScannedContainers') 
												)
											}
										/>
										<label className="form-check-label" for="scannedContainers">Allow Only Scanned Containers</label>
									</div>
									<div className="form-group form-check mb-1">
										<input 
											type="checkbox" 
											className="form-check-input" 
											id="encryptedContainers" 
											checked={this.state.onlyEncryptedContainers ? true : false}
											onChange={e => this.setState({ onlyEncryptedContainers: this.state.onlyEncryptedContainers ? false : true },
													() => this.handleConditionOptionTable('onlyEncryptedContainers') 
												)
											}
										/>
										<label className="form-check-label" for="encryptedContainers">Allow Only Encrypted Containers</label>
									</div>
									<div className="form-group form-check mb-1">
										<input 
											type="checkbox" 
											className="form-check-input" 
											id="kmsEncryptedContainers" 
											checked={this.state.onlyKMSEncryptedContainers ? true : false}
											onChange={e => this.setState({ onlyKMSEncryptedContainers: this.state.onlyKMSEncryptedContainers ? false : true },
													() => this.handleConditionOptionTable('onlyKMSEncryptedContainers') 
												)
											}
										/>
										<label className="form-check-label" for="kmsEncryptedContainers">Allow Only KMS Encrypted Containers</label>
									</div>
									{/* <button className="btn btn-sm btn-dark">Add</button>
									<button className="btn btn-sm btn-light">Reset</button> */}
								</div>
							</div>
							<button className='btn btn-sm btn-info align-self-center mr-3' onClick={() => this.addUpdateContainerArray(this.state.editEnabled ? 'Update' : 'Add')}>
								{this.state.editEnabled ? 'Update' : 'Add'}
							</button>
							<button className='btn btn-sm btn-light align-self-center' onClick={() => this.clearSelectedContainerData()}>
								Reset
							</button>
						</div>
					</div>
					<div className="col-lg-9">
						<div className="rounded bg-dark p-3 h-100">
							<div className="d-flex justify-content-between mb-2">
								<div className="d-flex">
									<h6 className="text-info m-0 p-0">Added Contianers</h6>
									{this.state.hasError && !this.state.condition.length ? 
										<small className='ml-3 text-danger align-self-end'>Please add containers.</small>
									: null}
								</div>
								<input
									type='text'
									value={this.state.searchSavedContainersText}
									onChange={e => {
										this.setState({ searchSavedContainersText: e.target.value }, () => this.searchSavedContainers())
									}}
									className='form-control form-control-sm w-20 bg-muted border-gray5 text-white'
									placeholder='Search'
								/>
							</div>
							{this.state.filterCondition && this.state.filterCondition.length ?
								<React.Fragment>
									{this.state.filterCondition.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map(item => {
										return(
											<div className="p-3 bg-dark3 rounded mb-3">
												<div className="row">
													<div className="col-lg-2 col-sm-3 align-self-center">
														<div>
															<label className="small mb-0 d-block">Account</label>
															{item.account.map((acc, i) => {
																return(
																<React.Fragment>
																	<label className={`badge badge-outline-light mr-1 mb-1`}>{getAccountNameFromId(acc,this.state.accounts) !== '-' ? getAccountNameFromId(acc,this.state.accounts) : acc}</label>
																</React.Fragment>
																)
															})}
														</div>
														<div>
															<button className="btn btn-outline-primary btn-sm border-0" onClick={() => this.actionOnContainerData('edit', item.rowIndex)}>Edit</button>
															<button className="btn btn-outline-danger btn-sm ml-3 border-0" onClick={() => this.actionOnContainerData('remove', item.rowIndex)}>Delete</button>
														</div>
													</div>
													<div className="col-lg-2 col-sm-3 align-self-center">
														<label className="small mb-0 d-block">Region</label>
														{item.region && item.region.length ?
															item.region.map((reg, i) => {
																return(
																<React.Fragment>
																<i className={`${i===0 ? '':'displayNone'} far fa-location mr-1 align-self-center`}></i>
																	<label className={`badge badge-outline-light mb-1 mr-1`}>{getRegionName(reg, this.state.regions)}</label>
																</React.Fragment>
																)
															})
														:
															<React.Fragment>
																<i className={`far fa-location mr-1 align-self-center`}></i>
																<small className={`badge badge-outline-light mb-1 mr-1`}>All</small>
															</React.Fragment>
														}
													</div>
													<div className="col-lg-2 col-sm-3 align-self-center">
														<label className="small mb-0 d-block">Repository Image</label>
														{item.repository && item.repository.length ?
															item.repository.map((rep, i) => {
																return(
																	<small className={`badge badge-outline-light mb-1 mr-1`}>{rep}</small>
																)
															})
														:
															<small className={`badge badge-outline-light mb-1 mr-1`}>All</small>
														}													
													</div>
													<div className="col-lg-2 col-sm-3 align-self-center">
														<label className="small mb-0 d-block">Duration</label>
														<p className="mb-0"><i className="far fa-clock mr-1"></i>{item.duration}</p>
													</div>
													<div className="col-lg-2 col-sm-3">
														<p className="small m-0 p-0">Latest Image Version</p>
														<small className={`mb-1 badge ${item.latestImageVersion ? 'badge-outline-success' : 'badge-outline-danger'}`}>{item.latestImageVersion ? 'Yes' : 'No'}</small>
														<p className="small mb-0">Scanned Containers</p>
														<small className={`mb-1 badge ${item.onlyScannedContainers ? 'badge-outline-success' : 'badge-outline-danger'}`}>{item.onlyScannedContainers ? 'Yes' : 'No'}</small>
													</div>
													<div className="col-lg-2 col-sm-3">
														<p className="small m-0 p-0">Encrypted Containers</p>
														<small className={`badge ${item.onlyEncryptedContainers ? 'badge-outline-success' : 'badge-outline-danger'}`}>{item.onlyEncryptedContainers ? 'Yes' : 'No'}</small>
														<p className="small m-0 p-0">KMS Encrypted Containers</p>
														<small className={`mb-1 badge ${item.onlyKMSEncryptedContainers ? 'badge-outline-success' : 'badge-outline-danger'}`}>{item.onlyKMSEncryptedContainers ? 'Yes' : 'No'}</small>
													</div>
												</div>
											</div>
										)
									})}
									{this.state.filterCondition.length > this.state.perPage ?
										<div className="pagination mt-3">
											<span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
											<button disabled=""  onClick={() => this.navigatePage('start', this.state.currentPage)}><i className="fal fa-arrow-to-left"></i></button> 
											<button disabled="" onClick={() => this.navigatePage('previous', this.state.currentPage)}><i className="fal fa-angle-left"></i></button> 
											<button><i className="fal fa-angle-right" onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
											<button><i className="fal fa-arrow-to-right" onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
										</div>
									: null
									}
								</React.Fragment>
							: 
								this.state.condition && !this.state.condition.length ?
									<div className='d-flex justify-content-center m-4'>
										<p>There are no data on this continers. Try add some container.</p>
									</div>
								:
									<div className='d-flex justify-content-center m-4'>
										<p>There are no data on this criteria. Please try adjusting your search.</p>
									</div>
							}
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

/**
 * Type of the props used in the component
 */
ServiceContainers.propTypes = {
	logType: PropTypes.object,
	setEditRuleDetails: PropTypes.func,
	listAllRegions: PropTypes.func,
	listAllAccounts: PropTypes.func,
	listAllServices: PropTypes.func,
	addContainerImage: PropTypes.func,
	apiToCall: PropTypes.func,
	editRuleDetails: PropTypes.object,
	parameters: PropTypes.object,
	provider: PropTypes.string,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('state',state)
	return {
		editRuleDetails: state.governance.editRuleDetails,		
		accounts: state.filters.accounts,
		regions: state.filters.regions,	
	}
}
export default connect(mapStateToProps, {
	setEditRuleDetails,
	apiToCall,
	listAllAccounts,
	listAllServices,
	listAllRegions,
	addContainerImage,
})(ServiceContainers)
