/*************************************************
 * Tvastar
 * @exports
 * @file newGovernanceAction.js
 * @author Prakash // on 10/08/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'

import {	
	GOVERNANCE_NEW,
	LIST_GOVERNANCE_POLICIES,
	GET_MASTER_GOVERNANCE_RULES,
	GET_POLICY_DETAILS,
	CREATE_GOVERNANCE_POLICY,
	EDIT_GOVERNANCE_POLICY,
	NEW_GOVERNANCE_GET_TOTAL_COUNT,
	NEW_GOVERNANCE_GET_DAY_WISE_COUNT,
	NEW_GOVERNANCE_GET_TRANSITION_TREND,
	GET_GOVERNANCE_VIOLATIONS,
	NEW_GOVERNANCE_GENERATE_IAM_POLICY,
	NEW_GOVERNANCE_GET_GOVERNANCE_SERVICES,
	NEW_GOVERNANCE_GET_GOV_META_DATA,
	NEW_GOVERNANCE_LIST_GOVERNANCE_RESULTS,
	GET_DAY_WISE_COUNT
} from '../../config'

import { ACTION_TYPES } from '../types'
import { myLog } from '../../utils/utility'
import _ from 'lodash'

export const setNewInitialRuleDetails = ruleDetails => {
	return async dispatch => {
		dispatch({
			type: ACTION_TYPES.SET_NEW_EDIT_RULE_DETAILS,
			payload: ruleDetails,
		})
	}
}
/**
 * Action to set resource props details
 */
 export const setGovernancePropsDetails = (key, value) => {
	return async (dispatch, getState) => {
		const governancePropsDetails = JSON.parse(JSON.stringify(getState().governance.governancePropsDetails))
		governancePropsDetails[key] = value		
		dispatch({
			type: ACTION_TYPES.SET_GOVERNANCE_PROPS_DETAILS,
			payload: governancePropsDetails,
		})
	}
}

/**
 * Action to set new edit rule details
 */
 export const setNewEditRuleDetails = (key, value) => {
	return async (dispatch, getState) => {
		let editRuleDetails = JSON.parse(JSON.stringify(getState().governance.editNewRuleDetails))
		editRuleDetails[key] = value
		dispatch({
			type: ACTION_TYPES.SET_NEW_EDIT_RULE_DETAILS,
			payload: editRuleDetails,
		})
	}
}

/**
 * Action to list-governance-policies
 * @param {Object} body
 * @param {Function} callback
 */
export const listGovernancePolicies = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(GOVERNANCE_NEW, LIST_GOVERNANCE_POLICIES, { body })
			dispatch({
				type: ACTION_TYPES.SET_NEW_RULES_LIST,
				payload: response,
			})
			
			callback(true, response)
		} catch (error) {
			callback(false, [])
			
			myLog('LIST_USER_NEW_RULE_ERROR', error)
		}
	}
}

/**
 * Action to list-governance-policies
 * @param {Object} body
 * @param {Function} callback
 */
 export const listGovernancePoliciesNew = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(GOVERNANCE_NEW, LIST_GOVERNANCE_POLICIES, { body })			
			callback(true, response)
		} catch (error) {
			callback(false, [])
			
			myLog('LIST_USER_NEW_RULE_ERROR', error)
		}
	}
}

/**
 * Action to get-master-governance-rules
 * @param {Object} body
 * @param {Function} callback
 */
 export const getMasterGovernanceRules = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(GOVERNANCE_NEW, GET_MASTER_GOVERNANCE_RULES, { body })
			callback(true, response)
		} catch (error) {
			myLog('rulesList', error)
			callback(false)
		}
	}
}

/**
 * Action to get-policy-details
 * @param {Object} body
 * @param {Function} callback
 */
 export const getPolicyDetails = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(GOVERNANCE_NEW, GET_POLICY_DETAILS, { body })
			console.log("response", response)
			// dispatch({
			// 	type: ACTION_TYPES.SET_NEW_EDIT_RULE_DETAILS,
			// 	payload: response.results[0],
			// })
			callback(true, response)
		} catch (error) {
			myLog('rulesList', error)
			callback(false)
		}
	}
}

/**
 * Action to create-governance-policy
 * @param {Object} body
 * @param {Function} callback
 */
 export const createGovernancePolicy = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(GOVERNANCE_NEW, CREATE_GOVERNANCE_POLICY, { body })
			callback(true, response)
		} catch (error) {
			myLog('rulesList', error)
			callback(false)
		}
	}
}

/**
 * Action to edit-governance-policy
 * @param {Object} body
 * @param {Function} callback
 */
 export const editGovernancePolicy = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(GOVERNANCE_NEW, EDIT_GOVERNANCE_POLICY, { body })
			callback(true, response)
		} catch (error) {
			myLog('rulesList', error)
			callback(false)
		}
	}
}

/**
 * Action to get-total-count
 * @param {Object} body
 * @param {Function} callback
 */
 export const newGovernanceGetTotalCount = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(GOVERNANCE_NEW, NEW_GOVERNANCE_GET_TOTAL_COUNT, { body })
			callback(true, response)
		} catch (error) {
			myLog('rulesList', error)
			callback(false)
		}
	}
}

/**
 * Action to get-day-wise-count
 * @param {Object} body
 * @param {Function} callback
 */
 export const newGovernanceGetDayWiseCount = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(GOVERNANCE_NEW, NEW_GOVERNANCE_GET_DAY_WISE_COUNT, { body })
			callback(true, response)
		} catch (error) {
			myLog('rulesList', error)
			callback(false)
		}
	}
}

/**
 * Action to get-transition-trend
 * @param {Object} body
 * @param {Function} callback
 */
 export const newGovernanceGetTransitionTrend = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(GOVERNANCE_NEW, NEW_GOVERNANCE_GET_TRANSITION_TREND, { body })
			callback(true, response)
		} catch (error) {
			myLog('rulesList', error)
			callback(false)
		}
	}
}

/**
 * Action to get-governance-violations
 * @param {Object} body
 * @param {Function} callback
 */
 export const getGovernanceViolations = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(GOVERNANCE_NEW, GET_GOVERNANCE_VIOLATIONS, { body })
			callback(true, response)
		} catch (error) {
			myLog('rulesList', error)
			callback(false)
		}
	}
}

/**
 * Action to generate-iam-policy
 * @param {Object} body
 * @param {Function} callback
 */
 export const generateIamPolicy = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(GOVERNANCE_NEW, NEW_GOVERNANCE_GENERATE_IAM_POLICY, { body })
			callback(true, response)
		} catch (error) {
			myLog('rulesList', error)
			callback(false)
		}
	}
}

/**
 * Action to get-master-governance-rules
 * @param {Object} body
 * @param {Function} callback
 */
 export const getGovernanceServices = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(GOVERNANCE_NEW, NEW_GOVERNANCE_GET_GOVERNANCE_SERVICES, { body })
			callback(true, response)
		} catch (error) {
			myLog('rulesList', error)
			callback(false)
		}
	}
}

/**
 * Action to get-gov-meta-data
 * @param {Object} body
 * @param {Function} callback
 */
 export const getGovMetaData = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(GOVERNANCE_NEW, NEW_GOVERNANCE_GET_GOV_META_DATA, { body })
			callback(true, response)
		} catch (error) {
			myLog('rulesList', error)
			callback(false)
		}
	}
}

/**
 * Action to list-goveranance-results
 * @param {Object} body
 * @param {Function} callback
 */
export const listGovernanceResults = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(GOVERNANCE_NEW, NEW_GOVERNANCE_LIST_GOVERNANCE_RESULTS, { body })
			callback(true, response)
		} catch (error) {
			myLog('rulesList', error)
			callback(false)
		}
	}
}
/**
 * Action to get-day-wise-count
 * @param {Object} body
 * @param {Function} callback
 */
export const governanceGetDayWiseCount = (body, callback) => {
	return async () => {
		try {
			const totalCount = await API.post(GOVERNANCE_NEW, GET_DAY_WISE_COUNT, { body })
			callback(true, totalCount)
		} catch (error) {
			callback(false)
			myLog('daywiseCount', error)
		}
	}
}