/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormGroup, Label, Input } from 'reactstrap'
import { setEditRuleDetails } from '../../../actions/governance/governanceAction'
import PropTypes from 'prop-types'
import _ from 'lodash'

class ServiceActionLimits extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	addActionToList = () => {
		let action = {
			action_name: '',
			hourly: '',
			daily: '',
			monthly: '',
		}
		let list = this.props.editRuleDetails.condition.apis
		list.push(action)
		this.props.setEditRuleDetails('condition', {
			apis: list,
		})
	}
	removeActionFromList = action => {
		let list = this.props.condition.apis
		_.pull(list, { action_name: action })
		this.props.setEditRuleDetails('condition', { apis: list })
	}
	handleChange = event => {
		let field_to_change = event.target.name.split('@')[0]
		let index = event.target.name.split('@')[1]
		let list = this.props.editRuleDetails.condition.apis
		let item_to_change = this.props.editRuleDetails.condition.apis[index]
		item_to_change[field_to_change] = event.target.value
		list.splice(index, 1, item_to_change)
		this.props.setEditRuleDetails('condition', { apis: list })
	}
	render() {
		return (
			<>
				{this.props.editRuleDetails.condition.apis && this.props.editRuleDetails.condition.apis.length === 0 ? (
					<button type='button' onClick={() => this.addActionToList()} className='btn btn-outline-primary'>
						<span>Add Actions</span>
					</button>
				) : (
					<>
						<b>Maximum Limit on Number of Actions that can be performed:</b>
						<br />
						<small>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed d</small>
					</>
				)}
				<table>
					<tbody>
						{this.props.editRuleDetails.condition.apis && this.props.editRuleDetails.condition.apis.length
							? this.props.editRuleDetails.condition.apis.map((action, index) => {
									return (
										<tr key={index}>
											<td>
												<FormGroup>
													<Label>Action</Label>
													<Input
														onChange={e => this.handleChange(e)}
														value={action.action_name}
														type='select'
														name={'action_name@' + index}
														id={index + 'action-native-simple'}
													>
														<option value=''>Select</option>
														{this.props.condition &&
															this.props.condition.events &&
															this.props.condition.events.map(parameter => {
																return (
																	<option value={parameter.name} key={parameter}>
																		{parameter.name}
																	</option>
																)
															})}
													</Input>
												</FormGroup>
											</td>
											<td>
												<FormGroup>
													<Label>Maximum per Hour</Label>
													<Input
														type='number'
														onChange={e => this.handleChange(e)}
														name={'hourly@' + index}
														id={index + 'hourly-input'}
														value={action.hourly}
														fullWidth
														required
													/>
												</FormGroup>
											</td>
											<td>
												<FormGroup>
													<Label>Maximum per Day</Label>
													<Input
														type='number'
														onChange={e => this.handleChange(e)}
														name={'daily@' + index}
														id={index + 'daily-input'}
														value={action.daily}
														fullWidth
														required
													/>
												</FormGroup>
											</td>
											<td>
												<FormGroup>
													<Label>Maximum per Month</Label>
													<Input
														type='number'
														onChange={e => this.handleChange(e)}
														name={'monthly@' + index}
														id={index + 'monthly-input'}
														value={action.monthly}
														fullWidth
														required
													/>
												</FormGroup>
											</td>
											<td>
												<button className='btn btn-outline-success border-0' onClick={() => this.addActionToList()}>
													<i className='far fa-plus-circle' />
												</button>
											</td>
											<td>
												<button
													className='btn btn-outline-danger border-0'
													onClick={() => this.removeActionFromList(action.action_name)}
												>
													<i className='far fa-trash-alt' />
												</button>
											</td>
										</tr>
									)
							  })
							: null}
					</tbody>
				</table>
			</>
		)
	}
}

/**
 * Type of the props used in the component
 */
ServiceActionLimits.propTypes = {
	setEditRuleDetails: PropTypes.func,
	condition: PropTypes.object,
	editRuleDetails: PropTypes.object,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		editRuleDetails: state.governance.editRuleDetails,
	}
}

export default connect(mapStateToProps, { setEditRuleDetails })(ServiceActionLimits)
