/*************************************************
 * Tvastar
 * @exports
 * @file metricsAction.js
 * @author prakash
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
// import cognitoService from '../../services/cognitoService'
// import { myLog } from '../../utils/utility'
import { API } from 'aws-amplify'

import {
	MONITORING,
    GET_MONITORING_METRICS_BY_TREND,
	GET_MONITORING_METRICS_BY_TREND_V2,
    
    AIOPS_SERVICE,
	GET_ALERTS_DAILY_TREND,
	GET_TOTAL_ALERTS_COUNT,
	GET_AIOPS_DAILY_TREND_BY_ASSETS
} from '../../config'

import { ACTION_TYPES } from '../types'


/**
 * Action to get Remediation Status
 * @param {Object} body
 * @param {Function} callback
 */
export const getMonitoringMetricsByTrend = (body, callback) => {
	return async () => {
		await API.post(MONITORING, GET_MONITORING_METRICS_BY_TREND, { body })
		.then((response) => {
			// console.log(response);
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to get Remediation Status
 * @param {Object} body
 * @param {Function} callback
 */
 export const getMonitoringMetricsByTrendV2 = (body, callback) => {
	return async () => {
		await API.post(MONITORING, GET_MONITORING_METRICS_BY_TREND_V2, { body })
		.then((response) => {
			// console.log(response);
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}


/**
 * Action to get get Aiops DailyTrend By Assets
 * @param {Object} body
 * @param {Function} callback
 */
export const getAiopsDailyTrendByAssets = (body, callback) => {	
	return async dispatch => {
		try {
			const result = await API.post(AIOPS_SERVICE, GET_AIOPS_DAILY_TREND_BY_ASSETS, { body })
			dispatch({
				type: ACTION_TYPES.SET_AIOPS_DAILY_TREND_BY_ASSETS,
				payload: result,
			}) 
			callback(true, result)
		} catch(err) {
			dispatch({
				type: ACTION_TYPES.SET_AIOPS_DAILY_TREND_BY_ASSETS,
				payload: {},
			}) 
			callback(false, {})
		}
    }
}

/**
 * Action to get Metrics Alert
 * @param {Object} body
 * @param {Function} callback
 */
export const getAlertsDailyTrend = (body, callback) => {
	return async () => {
		await API.post(AIOPS_SERVICE, GET_ALERTS_DAILY_TREND, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}


/**
 * Action to get alert total count
 * @param {Object} body
 * @param {Function} callback
 */
export const getTotalAlertsCount = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(AIOPS_SERVICE, GET_TOTAL_ALERTS_COUNT, { body })
			dispatch({
				type: ACTION_TYPES.SET_AIOPS_TOTAL_ALERT_COUNT,
				payload: result,
			})
			callback(true, result)
		} catch(err) {
			dispatch({
				type: ACTION_TYPES.SET_AIOPS_TOTAL_ALERT_COUNT,
				payload: [],
			})
			callback(false, {})
		}
	}
}
