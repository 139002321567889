/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ComparisionOption.js
 * @author Prakash // on 22/06/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 **************************************************/
import React, { Component } from 'react'
import { AppWrapper } from '../../common/AppWrapper'
import { URL_PATH } from '../../../config/urlPath'

class ComparisionOption extends Component {
	constructor(props) {
		super(props)

		this.state = {
			minMaxHeight: "770px",
		}
	}
    	
	componentDidMount = () => {
		this.getWindowSize()
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 70
		let outerHeight = (window.outerHeight - browserBorder - headerHeight) +"px"

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }
	
	redirectPage = (type) => {
		let pathName = URL_PATH.CONSOLIDATED_COMPARISON
		if(type === "consolidatedComparision") {
			pathName = URL_PATH.CONSOLIDATED_COMPARISON
		} else if(type === "assetComparision") {
			pathName = URL_PATH.ASSETS_COMPARISON
		} 
		this.props.history.push({
			pathname: pathName
		})
	}

	/**
	 * Renders Comparison
	*/
	render() {
		return (
			<div className='container-fluid overflow-auto flex-grow-1 bg-muted'>
				<div className='row h-100'>
					<div className='col-sm-12 p-0'>
						<div className='title-section p-2'>
							<div className="d-flex">
								<h6 className='text-white m-0 pl-2'>Comparison</h6>
							</div>
						</div>
						<div className="p-2">
							<div className="container-fluid rounded">
								<div className="d-flex justify-content-center">
									<div className="col-sm-4 bg-dark cursorPointer comparisonOption" onClick={() => this.redirectPage("consolidatedComparision")} style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
										<div className="p-4 rounded">
											<p className="my-0 f20">Consolidated Comparison</p>
										</div>
									</div>
									<div className="col-sm-4 bg-dark comparisonOption cursorPointer ml-3" onClick={() => this.redirectPage("assetComparision")} style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
										<div className="p-4 rounded" >
											<p className="my-0 f20">Assets Comparison</p>
										</div>
									</div>
									{/* <div className="col-sm-4 cursorPointer" onClick={() => this.redirectPage("costComparision")}>
										<div className="p-4 bg-dark rounded minMaxHeight820 comparisonOption">
											<p className="my-0">Cost Comparison</p>
										</div>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
*/
const mapStateToProps = state => {
	return {}
}

export default AppWrapper(ComparisionOption, mapStateToProps, {})
