/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Metrics.js
 * @author Prakash // on 22/02/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Spinner, UncontrolledTooltip } from 'reactstrap'
import { getAiopsDailyTrend, setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'
import { getMonitoringMetricsByTrend, getAiopsDailyTrendByAssets, getAlertsDailyTrend } from '../../../actions/aiops/MetricsAction'
import { getEventsDailyTrend } from '../../../actions/aiops/EventAction'
import moment from 'moment'
import { momentDateGivenFormat, momentConvertionUtcToLocalTime, momentConvertionLocalToUtcTime, twoDateTimeInterval, subDays, subHours, addHours, subMinutes, addMinutes } from '../../../utils/utility'

import Chart from 'react-apexcharts'

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";

import AssetsMetricDetails from './AssetsMetricDetails'

const today = moment();
const disableFutureDt = current => {
  return current.isBefore(today)
}
class AssetsRightSection extends Component {
    
    constructor(props) {

        super(props)        
        this.scrolltoTop = React.createRef()
        
        this.state = {
            showMetricsLoading: false,
            activeSideTopTab: 'InstanceMetrics',
            showMetricsDetails: this.props.showMetricsDetails ? true : false,
            showFilerSection: true,

            caseId: this.props.caseId,
            metricsByTrend: [], 

            selectedFilterType: 'H',
            selectedFilterPeriod: 6,
            sliderStartDate: '',
            sliderEndDate: ''
        }
    }
    
    handleStartTimeChange  = this.handleStartTimeChange.bind(this)
    handleEndTimeChange    = this.handleEndTimeChange.bind(this)

	componentDidMount = () => {
        if(this.props.caseDetails && Object.entries(this.props.caseDetails).length) {
            let caseStartTime = this.props.caseDetails.created_at
            let caseEndTime = momentDateGivenFormat(addMinutes(this.props.caseDetails.last_event, 1), 'YYYY-MM-DD HH:mm:ss')

            if(caseEndTime < caseStartTime) {
                caseEndTime = caseStartTime
            }

            let updatedCaseStartTime = subDays(this.props.caseDetails.created_at, 3)
            
            this.setState({ caseStartTime: updatedCaseStartTime, caseEndTime },
                () => {
                    this.changeGraphTimings()
                }
            )
        }
    }

    changeGraphTimings = () => {

        let selectedFilterType = this.state.selectedFilterType
        let selectedFilterPeriod = this.state.selectedFilterPeriod

        let sliderStartDate = momentConvertionUtcToLocalTime(this.state.caseStartTime, 'YYYY-MM-DD HH:mm:ss')
        let sliderEndDate = momentConvertionUtcToLocalTime(this.state.caseEndTime, 'YYYY-MM-DD HH:mm:ss')
        
        if(selectedFilterType === 'D') {
            sliderStartDate = subDays(sliderEndDate, selectedFilterPeriod)
        } else {
            sliderStartDate = subHours(sliderEndDate, selectedFilterPeriod)
        }

        let difference = twoDateTimeInterval(sliderStartDate, sliderEndDate)
        let brushStartDate = sliderStartDate
        let brushEndDate = sliderEndDate
        if(difference && difference.length) {
            if(difference[0] > 0) {
                if(difference[0] === 1) {
                    brushStartDate = subHours(brushEndDate, 1)
                } else if(difference[0] < 2) {
                    brushStartDate = subHours(brushEndDate, 3)
                } else if(difference[0] < 3) {
                    brushStartDate = subHours(brushEndDate, 4)
                } else {
                    brushStartDate = subHours(brushEndDate, 8)
                }
            } else if(difference[1] > 0) {
                if(difference[1] <= 1) {
                    brushStartDate = subMinutes(brushEndDate, 10)
                } else if(difference[0] < 3) {
                    brushStartDate = subMinutes(brushEndDate, 15)
                } else if(difference[0] < 6) {
                    brushStartDate = subMinutes(brushEndDate, 30)
                } else if(difference[0] < 12) {
                    brushStartDate = subMinutes(brushEndDate, 45)
                }
            } else {
                brushStartDate = subMinutes(brushEndDate, 10)
            }
        }
        
        let dateArray = []
        let localCaseStartTime = sliderStartDate
        let localCaseEndTime = sliderEndDate
        if(selectedFilterType === 'D' && selectedFilterPeriod === 3) {
            dateArray = []
            let min = 0
            let reachedEnd = 0
            for(let y=0;y<=73;y++) {
                let dateHour = momentDateGivenFormat(addHours(localCaseStartTime, y), 'YYYY-MM-DD HH:mm')
                let value = 5
                if(min === 0) {
                    value = 10
                    min += 1
                } else {
                    min += 1
                    if(min === 4) {
                        min = 0
                    }
                }
                if(new Date(dateHour).getTime() < new Date(localCaseEndTime).getTime()) {
                    dateArray.push([dateHour, value])
                } else if(reachedEnd === 0) {
                    reachedEnd = 1
                    dateArray.push([localCaseEndTime, 5])
                }
            }
        } else if(selectedFilterType === 'D' && selectedFilterPeriod === 1) {
            let min = 0
            let reachedEnd = 0
            for(let y=0;y<=72;y++) {
                let dateHour = momentDateGivenFormat(addHours(localCaseStartTime, (y/3)), 'YYYY-MM-DD HH:mm')
                let value = 5
                if(min === 0) {
                    value = 10
                    min += 1
                } else {
                    min += 1
                    if(min === 6) {
                        min = 0
                    }
                }
                if(new Date(dateHour).getTime() < new Date(localCaseEndTime).getTime()) {
                    dateArray.push([dateHour, value])
                } else if(reachedEnd === 0) {
                    reachedEnd = 1
                    dateArray.push([localCaseEndTime, 5])
                }
            }
        } else if(selectedFilterType === 'H' && selectedFilterPeriod === 12) {
            let min = 0
            let reachedEnd = 0
            for(let y=0;y<=48;y++) {
                let dateHour = momentDateGivenFormat(addHours(localCaseStartTime, (y/4)), 'YYYY-MM-DD HH:mm')
                let value = 5
                if(momentDateGivenFormat(dateHour, 'mm') === '00' && min === 0) {
                    value = 10
                    min += 1
                } else {
                    min += 1
                    if(min === 8) {
                        min = 0
                    }
                }
                if(new Date(dateHour).getTime() < new Date(localCaseEndTime).getTime()) {
                    dateArray.push([dateHour, value])
                } else if(reachedEnd === 0) {
                    reachedEnd = 1
                    dateArray.push([localCaseEndTime, 5])
                }
            }
        } else if(selectedFilterType === 'H' && selectedFilterPeriod === 6) {
            let hour = ''
            let reachedEnd = 0
            for(let y=0;y<50;y++) {
                let dateHour = momentDateGivenFormat(addHours(localCaseStartTime, y/8),'YYYY-MM-DD HH:mm')
                let value = 5                
                if(momentDateGivenFormat(dateHour, 'HH') !== hour) {
                    hour = momentDateGivenFormat(dateHour, 'HH')
                    value = 10
                }
                if(new Date(dateHour).getTime() < new Date(localCaseEndTime).getTime()) {
                    dateArray.push([dateHour, value])
                } else if(reachedEnd === 0) {
                    reachedEnd = 1
                    dateArray.push([localCaseEndTime, 5])
                }
            }
        } else if(selectedFilterType === 'H' && selectedFilterPeriod === 3) {
            let hour = ''
            let reachedEnd = 0
            for(let y=0;y<49;y++) {
                let dateHour = momentDateGivenFormat(addHours(localCaseStartTime, y/16),'YYYY-MM-DD HH:mm')
                let value = 5
                if(momentDateGivenFormat(dateHour, 'HH') !== hour) {
                    hour = momentDateGivenFormat(dateHour, 'HH')
                    value = 10
                }
                if(new Date(dateHour).getTime() < new Date(localCaseEndTime).getTime()) {
                    dateArray.push([dateHour, value])
                } else if(reachedEnd === 0) {
                    reachedEnd = 1
                    dateArray.push([localCaseEndTime, 5])
                }
            }
        } else if(selectedFilterType === 'H' && selectedFilterPeriod === 1) {
            // let minute = ''
            let reachedEnd = 0
            let min = 0
            for(let y=0;y<32;y++) {
                let dateHour = momentDateGivenFormat(addMinutes(localCaseStartTime, (y*2)),'YYYY-MM-DD HH:mm')
                let value = 5
                if(min === 0) {
                    // minute = momentDateGivenFormat(dateHour, 'mm')
                    value = 10
                    min++;
                } else {
                    min++;
                    if(min === 6) {
                        min= 0
                    }
                }
                if(new Date(dateHour).getTime() < new Date(localCaseEndTime).getTime()) {
                    dateArray.push([dateHour, value])
                } else if(reachedEnd === 0) {
                    reachedEnd = 1
                    dateArray.push([localCaseEndTime, 5])
                }
            }
        }

        if(dateArray[dateArray.length] < sliderEndDate) {
            dateArray.push([localCaseEndTime, 5])
        }
        
        this.setState({ dateArray, selectedFilterPeriod, brushSliderSeries: [], brushSliderOptions: {}, sliderStartDate, sliderEndDate, brushStartDate, brushEndDate, showLoading: true  },
            () => {
                this.brushSliderChart()
                this.getMonitoringMetricsByTrend()

                if(!this.props.assetsAiopsDailyTrend.hasOwnProperty(this.props.caseDetails.case_id)) {
                    this.getOverallAlertEventDailyTrend()
                } else {
                    this.setState({ topAlertEvent: this.props.assetsAiopsDailyTrend[this.props.caseDetails.case_id] }, 
                        () => {
                            this.overallAlertEventGraph()
                        }
                    )
                }
                if(!this.props.assetsAlertEventTrend.hasOwnProperty(this.props.caseDetails.case_id)) {
                    this.getAiopsDailyTrendByAssets()
                } else {
                    this.setState({ alertEventTrend: this.props.assetsAlertEventTrend[this.props.caseDetails.case_id] }, 
                        () => {
                            this.metricsEventChart()
                        }
                    )
                }
            }
                
        )
    }

    brushSliderChart = () => {
        let dateArray = this.state.dateArray

        if(dateArray.length) {
            let brushSliderSeries = [{
                data: dateArray
            }]

            let columnWidth = '10%'
            let brushSliderOptions = {
                plotOptions: {
                    bar: {
                        barHeight: '100%',
                        columnWidth: columnWidth,
                    },          
                },
                chart: {
                    id: 'chart1',
                    height: 130,
                    type: 'bar',
                    brush:{
                        target: 'none',
                        enabled: true,
                    },
                    selection: {
                        enabled: true,
                        fill: {
                            color: "#039BE5",
                            opacity: 1,
                            // opacity: 0.6
                        },  
                        stroke: {
                            show: false,
                            curve: 'smooth',
                            lineCap: 'round',
                            // colors: '#00A3CB',
                            colors: '#039BE5',
                            width: 0,
                            dashArray: 0,      
                        },
                        xaxis: {
                            min: new Date(this.state.brushStartDate).getTime(),
                            max: new Date(this.state.brushEndDate).getTime(),
                        },
                        style: {
                            fontSize: '28px',
                            color: '#000000'
                        }
                    },
                    events: {
                        selection: (chartContext, { xaxis, yaxis }) => {
                            var minDate = new Date(xaxis.min);
                            var maxDate = new Date(xaxis.max);
                            let startSlice = momentDateGivenFormat(minDate, 'YYYY-MM-DD HH:mm:ss');
                            let endSlice = momentDateGivenFormat(maxDate, 'YYYY-MM-DD HH:mm:ss');
                            this.setState({ brushStartDate: startSlice, brushEndDate: endSlice })
                            // this.getAttribute()
                        },
                        click: (event, chartContext, config) => {
                            this.brushScrolled()
                        }
                    }
                },
                colors: ['#515151'],
                xaxis: {
                    type: 'datetime',
                    tooltip: {
                        enabled: false
                    },
                    position: 'top',
                    labels: {
                        datetimeUTC: false,
                    },
                    axisTicks: {
                        show: false,
                    }
                },
                yaxis: {
                    show: false,
                    tickAmount: 2,
                    labels: {
                        show: false
                    },
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: false
                        },
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                }
            }
        
            this.setState({ brushSliderSeries, brushSliderOptions })
        }
    }

    brushScrolled = () => {
        this.overallAlertEventGraph()
        this.metricsEventChart()
        this.metricsListGraph()
    }

    getOverallAlertEventDailyTrend = () => {
        let params = {}
        params.alerts = true
        params.events = true
        params.case_id = this.state.caseId
        params.start_time = this.state.caseStartTime
        params.end_time = this.state.caseEndTime
        params.interval = "1m"

        this.props.getAiopsDailyTrend(params, (promise, result) => {
            if(promise) {
                this.setState({ topAlertEvent: result, overallEventSeries: [], overallEventOptions: {}, overallAlertSeries: [], overallAlertOptions: {} },
                    () => {
                        let obj = {}
                        obj[this.props.caseDetails.case_id] = result
                        this.props.setAiopsPropsDetails('assetsAiopsDailyTrend', obj)
                        this.overallAlertEventGraph()
                    }
                )
            }
        })
    }
    
    overallAlertEventGraph = () => {
        let graphDetails = this.state.topAlertEvent
        let startIndex = 0
        let endIndex = 0
        if(graphDetails && Object.entries(graphDetails).length) {
            let graphLabels = graphDetails.labels
            graphLabels.forEach((lab, i) => {
                if(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm') === momentDateGivenFormat(this.state.brushStartDate, 'YYYY-MM-DD HH:mm')) {
                    startIndex = i
                }
                if(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm') === momentDateGivenFormat(this.state.brushEndDate, 'YYYY-MM-DD HH:mm')) {
                    endIndex = (i+1)
                }
            })  

            Object.entries(graphDetails).forEach(([key, value]) => {
                let data = []
                let graphDataRow = {}
                let caseEndTIme = this.props.caseDetails.last_event
                if(this.props.caseDetails.last_event < this.props.caseDetails.created_at) {
                    caseEndTIme = this.props.caseDetails.created_at
                }

                let annotations = {
                    xaxis: [{
                        x: new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                        strokeDashArray: 0,
                        borderColor: '#FF6F00',
                        label: {
                        borderColor: '#FF6F00',
                        orientation: 'horizontal',
                        text: 'CS',
                            style: {
                                color: '#fff',
                                background: '#FF6F00',
                                fontSize: '10px',
                                // width: '10px',
                            },
                        }
                    },
                    {
                        x: new Date(momentConvertionUtcToLocalTime(caseEndTIme, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                        strokeDashArray: 0,
                        borderColor: '#FF6F00',
                        label: {
                        borderColor: '#FF6F00',
                        orientation: 'horizontal',
                        text: 'CE',
                            style: {
                                color: '#fff',
                                background: '#FF6F00',
                                fontSize: '10px',
                                // width: '10px',
                            },
                        }
                    }]
                }

                let xaxis = {
                    type: 'datetime',
                    labels: {
                        datetimeUTC: false,
                        // formatter: function (value) {
                        //     if(interval[1] === 0) {
                        //         return momentDateGivenFormat(value, 'HH:mm:ss')
                        //     } else {
                        //         return momentDateGivenFormat(value, 'HH:mm')
                        //     }
                        // }
                    },
                    tooltip: {
                        enabled: false,
                    },
                }

                if(key === 'events') {
                    let eventSeries = []
                    value.count  && value.count.length && value.count.slice(startIndex, endIndex).forEach((item, i) => {
                        let arrayValuesRow = []
                        arrayValuesRow.push(momentConvertionUtcToLocalTime(graphLabels[startIndex + i], 'YYYY-MM-DD HH:mm:ss'))
                        arrayValuesRow.push(item)
                        data.push(arrayValuesRow)
                    })
                    graphDataRow.data = data
                    graphDataRow.arrayDates = graphLabels
                    eventSeries.push(graphDataRow)
                    
                    let eventOptions = {
                        chart: {
                            id: 'chart2',
                            //group: 'barBrushChart',
                            type: 'line',
                            height: 230,
                            sparkline: {
                                enabled: false
                            },
                            redrawOnParentResize: true,                        
                            zoom: {
                                enabled: false,
                            },
                            toolbar: {
                                show: false
                            }
                        },                        
                        annotations: annotations,
                        // colors: ['#039BE5'],
                        colors: ['#19B2C0'],
                        stroke: {
                            show: true,
                            curve: 'smooth',
                            lineCap: 'butt',
                            colors: undefined,
                            width: 3,
                            dashArray: 0,      
                        },
                        dataLabels: {
                            enabled: false
                        },
                        markers: {
                            size: 0
                        },
                        xaxis: xaxis,  
                        yaxis: {
                            show: false,
                            labels: {
                                datetimeUTC: false,
                                show: false,
                            },
                            tooltip: {
                                enabled: false,
                            }
                        },                     
                        grid: {
                            xaxis: {
                                lines: {
                                    show: false
                                },
                            },
                            yaxis: {
                                lines: {
                                    show: false
                                }
                            },
                        },
                        tooltip: {
                            fixed: {
                                enabled: false
                            },
                            x: {
                                show: false
                            },
                            y: {
                                show: false
                            },
                            marker: {
                                show: false
                            },
                            custom: function({series, seriesIndex, dataPointIndex, w}) {
                                let val = w.globals.initialSeries[0].data[dataPointIndex][1]
                                let dateLabel = momentDateGivenFormat(w.globals.initialSeries[0].data[dataPointIndex][0], 'MMM DD HH:mm:ss')
                                // let labelName = w.globals.initialSeries[0].event_id
                                let fontColor = '#693EBC'

                                return '<div class="arrow_box">'+ dateLabel +'<span style="color:'+ fontColor +'"> '+ val +'</span></div>'
                            }
                        }
                    }

                    this.setState({ overallEventSeries: eventSeries, overallEventOptions: eventOptions })
                }
                
                if(key === 'alerts') {
                    let alertSeries = []
                    value.count  && value.count.length && value.count.slice(startIndex, endIndex).forEach((item, j) => {
                        let arrayValuesRow = []
                        arrayValuesRow.push(momentConvertionUtcToLocalTime(graphLabels[startIndex + j], 'YYYY-MM-DD HH:mm:ss'))
                        arrayValuesRow.push(item)
                        data.push(arrayValuesRow)
                    })
                    graphDataRow.data = data
                    graphDataRow.arrayDates = graphLabels
                    alertSeries.push(graphDataRow)

                    let columnWidth = '25%'
                    if(data.length <= 10) {
                        columnWidth = '2%'
                    } else if(data.length <= 20) {
                        columnWidth = '4%'
                    } else if(data.length <= 30) {
                        columnWidth = '6%'
                    } else if(data.length <= 40) {
                        columnWidth = '8%'
                    } else if(data.length <= 50) {
                        columnWidth = '10%'
                    } else if(data.length <= 60) {
                        columnWidth = '12%'
                    } else if(data.length <= 70) {
                        columnWidth = '14%'
                    } else if(data.length <= 80) {
                        columnWidth = '16%'
                    } else if(data.length <= 90) {
                        columnWidth = '18%'
                    } else if(data.length <= 100) {
                        columnWidth = '20%'
                    }

                    let alertOptions = {
                        chart: {
                            id: 'chart2',
                            //group: 'barBrushChart',
                            type: 'bar',
                            height: 230,
                            sparkline: {
                                enabled: false
                            },
                            redrawOnParentResize: true,
                            animations: {
                                enabled: false,
                            },
                            zoom: {
                                enabled: false,
                            },
                            toolbar: {
                                show: false
                            }
                        },
                        // annotations: annotations,
                        
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                barHeight: "100%",
                                borderRadius: 10,
                                startingShape: 'flat',
                                endingShape: 'rounded',
                                columnWidth: columnWidth,
                                distributed: false,
                                rangeBarOverlap: false,
                            },
                        },
                        // colors: ['#19B2C0'],
                        colors: ['#CBF2D5'],
                        fill: {
                            colors: ['#19B2C0'],
                            type: 'gradient', // gradient
                            gradient: {
                                type: 'vertical', // The gradient in the horizontal direction
                                gradientToColors: ['#CBF2D5'], // The color at the end of the gradient
                                opacityFrom: .9, // transparency
                                opacityTo: 1,
                                stops:[0, 120]
                            }
                        },
                        stroke: {
                            show: false,
                            curve: 'straight',
                            lineCap: 'butt',
                            colors: undefined,
                            width: 2,
                            dashArray: 0,      
                        },
                        dataLabels: {
                            enabled: false
                        },
                        markers: {
                            size: 0
                        },
                        xaxis: xaxis,
                        yaxis: {
                            show: false,
                            labels: {
                                datetimeUTC: false,
                                show: false,
                            },
                            tooltip: {
                                enabled: false,
                            }
                        },                                                
                        grid: {
                            xaxis: {
                                lines: {
                                    show: false
                                },
                            },
                            yaxis: {
                                lines: {
                                    show: false
                                }
                            },
                        },
                        tooltip: {
                            fixed: {
                                enabled: false
                            },
                            x: {
                                show: false
                            },
                            y: {
                                title: {
                                formatter: (seriesName) => seriesName,
                                }
                            },
                            marker: {
                                show: false
                            },
                            custom: function({series, seriesIndex, dataPointIndex, w}) {
                                let val = w.globals.initialSeries[0].data[dataPointIndex][1]
                                let dateLabel = momentDateGivenFormat(w.globals.initialSeries[0].data[dataPointIndex][0], 'MMM DD HH:mm:ss')
                                // let labelName = w.globals.initialSeries[0].event_id
                                let fontColor = '#693EBC'

                                return '<div class="arrow_box">'+ dateLabel +'<span style="color:'+ fontColor +'"> '+ val +'</span></div>'
                            }
                        }
                    }

                    this.setState({ overallAlertSeries: alertSeries, overallAlertOptions: alertOptions })
                }
            })
        } else {
            this.setState({ overallEventSeries: [], overallEventOptions: {}, overallAlertSeries: [], overallAlertOptions: {} })
        }
    }

    getAiopsDailyTrendByAssets = () => {
        let params = {}
        params.alerts_case_id = this.state.caseId
        params.start_time = this.state.caseStartTime
        params.end_time = this.state.caseEndTime
        
        this.props.getAiopsDailyTrendByAssets(params, (promise, result) => {
            if(promise) {
                this.setState({ alertEventTrend: result.results ? result.results : {} },
                    () => {
                        let obj = {}
                        obj[this.props.caseDetails.case_id] = this.state.alertEventTrend
                        this.props.setAiopsPropsDetails('assetsAlertEventTrend', obj)
                        this.metricsEventChart()
                    }
                )
            }
        })
    }

    metricsEventChart = () => {
        let startIndex = 0
        let endIndex = 0
        let graphDetails = this.state.alertEventTrend
        if(graphDetails) {
            let graphData = graphDetails.data
            let graphLabels = graphDetails.labels

            graphLabels.forEach((lab, i) => {
                if(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm') === momentDateGivenFormat(this.state.brushStartDate, 'YYYY-MM-DD HH:mm')) {
                    startIndex = i
                }
                if(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm') === momentDateGivenFormat(this.state.brushEndDate, 'YYYY-MM-DD HH:mm')) {
                    endIndex = (i+1)
                }
            })
            
            Object.entries(graphData).length && Object.entries(graphData).forEach(([key, value], index) => {
                let eventSeries = []
                let data = []
                let graphDataRow = {}
                value.events && value.events.length && value.events.slice(startIndex, endIndex).forEach((item, index) => {
                    let arrayValuesRow = []
                    arrayValuesRow.push(this.getTimeLocal(momentConvertionUtcToLocalTime(graphLabels[startIndex + index], 'YYYY-MM-DD HH:mm:ss')))
                    arrayValuesRow.push(item)
                    data.push(arrayValuesRow)
                    
                })
                graphDataRow.data = data
                graphDataRow.arrayDates = graphLabels
                eventSeries.push(graphDataRow)
                
                let eventOptions = {
                    chart: {
                        id: 'chart2',
                        //group: 'barBrushChart',
                        type: 'line',
                        height: 230,
                        sparkline: {
                            enabled: true
                        },
                        redrawOnParentResize: true,
                        animations: {
                            enabled: false,
                        },
                    },
                    // colors: ['#039BE5'],
                    colors: ['#19B2C0'],
                    stroke: {
                        show: true,
                        curve: 'straight',
                        lineCap: 'butt',
                        colors: undefined,
                        width: 2,
                        dashArray: 0,      
                    },
                    dataLabels: {
                        enabled: false
                    },
                    markers: {
                        size: 0
                    },
                    xaxis: {
                        type: 'datetime',
                        labels: {
                            datetimeUTC: false
                        },
                    },
                    tooltip: {
                        fixed: {
                            enabled: false
                        },
                        x: {
                            show: false
                        },
                        y: {
                            title: {
                            formatter: (seriesName) => seriesName,
                            }
                        },
                        marker: {
                            show: false
                        },
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                            let val = w.globals.initialSeries[0].data[dataPointIndex][1]
                            let dateLabel = momentDateGivenFormat(w.globals.initialSeries[0].data[dataPointIndex][0], 'MMM DD HH:mm:ss')
                            // let labelName = w.globals.initialSeries[0].event_id
                            let fontColor = '#693EBC'

                            return '<div class="arrow_box">'+ dateLabel +'<span style="color:'+ fontColor +'"> '+ val +'</span></div>'
                        }
                    }
                }

                let alertSeries = []
                data = []
                graphDataRow = {}
                value.alerts && value.alerts.length && value.alerts.slice(startIndex, endIndex).forEach((item, i) => {
                    let arrayValuesRow = []
                    arrayValuesRow.push(this.getTimeLocal(momentConvertionUtcToLocalTime(graphLabels[startIndex + i], 'YYYY-MM-DD HH:mm:ss')))
                    arrayValuesRow.push(item)
                    data.push(arrayValuesRow)
                })
                graphDataRow.data = data
                graphDataRow.arrayDates = graphLabels
                alertSeries.push(graphDataRow)

                let alertOptions = {
                    chart: {
                        id: 'chart2',
                        //group: 'barBrushChart',
                        type: 'bar',
                        height: 230,
                        sparkline: {
                            enabled: true
                        },
                        redrawOnParentResize: true,
                        animations: {
                            enabled: false,
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            barHeight: '100%',
                            columnWidth: '20%',
                            distributed: false
                        }
                    },
                    colors: ['#19B2C0'],
                    fill: {
                        colors: ['#039BE5'],
                        type: 'gradient', // gradient
                        gradient: {
                            type: 'vertical', // The gradient in the horizontal direction
                            gradientToColors: ['#5F5BA2'], // The color at the end of the gradient
                            opacityFrom: .9, // transparency
                            opacityTo: 1,
                            stops:[0, 120]
                        }
                    },
                    stroke: {
                        show: true,
                        curve: 'straight',
                        lineCap: 'butt',
                        colors: undefined,
                        width: 0,
                        dashArray: 0,      
                    },
                    dataLabels: {
                        enabled: false
                    },
                    markers: {
                        size: 0
                    },
                    xaxis: {
                        type: 'datetime',
                        labels: {
                            datetimeUTC: false
                        },
                    },
                    tooltip: {
                        fixed: {
                            enabled: false
                        },
                        x: {
                            show: false
                        },
                        y: {
                            title: {
                            formatter: (seriesName) => seriesName,
                            }
                        },
                        marker: {
                            show: false
                        },
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                            let val = w.globals.initialSeries[0].data[dataPointIndex][1]
                            let dateLabel = momentDateGivenFormat(w.globals.initialSeries[0].data[dataPointIndex][0], 'MMM DD HH:mm:ss')
                            // let labelName = w.globals.initialSeries[0].event_id
                            let fontColor = '#693EBC'

                            return '<div class="arrow_box">'+ dateLabel +'<span style="color:'+ fontColor +'"> '+ val +'</span></div>'
                        }
                    }
                }

                this.setState({ [key+'_eventSeries']: eventSeries, [key+'_alertSeries']: alertSeries, [key+'_eventOptions']: eventOptions, [key+'_alertOptions']: alertOptions })
            })
        }
    }

    getMonitoringMetricsByTrend = () => {
        this.scrolltoTop.current.scrollIntoView()
        this.setState({ disableDateSlider: true })
        let params = {}
        params.provider = this.props.caseDetails && this.props.caseDetails.provider ? (Array.isArray(this.props.caseDetails.provider) ? this.props.caseDetails.provider[0] : this.props.caseDetails.provider) : 'aws'
        params.start_time = momentConvertionLocalToUtcTime(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:ss')
        params.end_time = momentConvertionLocalToUtcTime(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:ss')
        params.anomaly = true
        params.case_id = this.state.caseId
        params.top = 3
    
        this.props.getMonitoringMetricsByTrend(params, (promise, result) => {
            if(promise) {
                this.setState({ metricsByTrend : [] },
                    () => {
                        this.setState({ metricsByTrend: result, disableDateSlider: result.length ? false : true, showLoading: false },
                            () => {
                                this.metricsListGraph()
                                this.metricsEventChart()
                                this.props.setAiopsPropsDetails('assetsMonitroingList', this.state.metricsByTrend)
                            }
                        )
                    }
                )
            } else {
                this.setState({ disableDateSlider: false, showLoading: false })
            }
        })
    }

    metricsListGraph = () => {
        let startIndex = 0
        let endIndex = 0
        let graphDetails = this.state.metricsByTrend
        if(graphDetails) {
            graphDetails.forEach((graph, index) => {
                let graphData = graph.data
                let graphDates = graph.dates

                graphDates.forEach((lab, i) => {
                    if(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm') === momentDateGivenFormat(this.state.brushStartDate, 'YYYY-MM-DD HH:mm')) {
                        startIndex = i
                    }
                    if(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm') === momentDateGivenFormat(this.state.brushEndDate, 'YYYY-MM-DD HH:mm')) {
                        endIndex = (i+1)
                    }
                })
                Object.entries(graphData).forEach(([key, item]) => {
                    this.setState({ [key+'_'+graph.asset_id]: [], [key+'_options_'+graph.asset_id]: {} })
                })

                Object.entries(graphData).forEach(([key, item]) => {
                    let array = []
                    item.values.slice(startIndex, endIndex).forEach((val, j) => {
                        let arrayValues = []
                        arrayValues.push(this.getTimeLocal(momentConvertionUtcToLocalTime(graphDates[startIndex + j], 'YYYY-MM-DD HH:mm:ss')))
                        arrayValues.push(val)
                        array.push(arrayValues)
                    })

                    if(array.length === 1) {
                        let arrayValues = []
                        arrayValues.push(addHours(momentConvertionUtcToLocalTime(graphDates[0], 'YYYY-MM-DD HH:mm:ss'), 0.5))
                        arrayValues.push(0)
                        array.push(arrayValues)
                    }

                    let series = [{
                        data: array
                    }]

                    let options = {
                        chart: {
                            // id: key+'_'+index,
                            group: 'slider',
                            type: 'area',
                            width: 100,
                            height: 35,
                            sparkline: {
                                enabled: true
                            },
                            // animation: {
                            //     enabled: false
                            // }
                        },
                        stroke: {
                            show: true,
                            curve: 'straight',
                            lineCap: 'butt',
                            colors: undefined,
                            width: 2,
                            dashArray: 0,      
                        },
                        // labels: this.state.labels,
                        fill: {
                            type: 'none',
                            // show: false,
                            opacity: 0
                        },
                        colors: ['#693EBC'],
                        tooltip: {
                            custom: function({series, seriesIndex, dataPointIndex, w}) {
                                let dateLabel = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
                                dateLabel = dateLabel.toString()
        
                                // dateLable = new Date(dateLable);
                                // dateLable = dateLable.getTime()
                                // dateLable = new Date(dateLable);
                                // dateLable = dateLable.toString()
        
                                dateLabel = momentDateGivenFormat(dateLabel, 'HH:mm')
                                let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]

                                // return '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+dateLabel+'</div><div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">Value: </span><span class="apexcharts-tooltip-text-value ml-2">'+parseFloat(val).toFixed(2)+'</span></div></div></div></div>'
                                
                                return '<div className="arrow_box">'+dateLabel+' <span style="color:'+ w.globals.colors[0] +'">' + parseFloat(val).toFixed(2) + '</span> </div>'
                            },
                            fixed: {
                                enabled: false
                            },
                            x: {
                                show: false,
                            },
                            y: {
                                show: false
                            },        
                            marker: {
                                show: false
                            }
                        },
                        xaxis: {
                            type: 'datetime',
                            
                            // min: new Date(this.props.aiopsPropsDetails.asset_slider_changes.startDate).getTime(),
                            // max: new Date(this.props.aiopsPropsDetails.asset_slider_changes.endDate).getTime(),  
                            // min: this.getTimeLocal(graphDates[start]),
                            // max: this.getTimeLocal(graphDates[end]),
                            labels: {
                                datetimeUTC: false,
                            }
                        }
                    }
                    this.setState({ [key+'_'+graph.asset_id]: series, [key+'_options_'+graph.asset_id]: options })
                })
            })
        }
    }
    
    getTimeLocal = (time) => {
        time = new Date(time).getTime()
        // time = time.getTime() - (time.getTimezoneOffset() * 60000)
        // let x = new Date(time);
        // x = x.toString()
        return time
    }

    getMetricsDeatils = (assetId, assetName) => {
        // let params = {}
        // params.provider = "aws"
        // params.service_name = "ec2"
        // params.anomaly = true
        // params.case_id = this.state.caseId
        // params.asset_name = [assetName]

        let filterAssets = this.props.assetsList.filter(arr => arr.asset_name === assetName)

        if(filterAssets.length) {
            this.scrolltoTop.current.scrollIntoView()
            this.setState({ selectedAsset: filterAssets[0], showMetricsDetails: true })
        }

    }

    getLoopDate = (date, days) => {
        var result = new Date(date);
        result.setDate(result.getDate() - days);
        return result.getTime();
    }    

    getLoopHour = (date, hours) => {
        let result = moment(date).subtract(hours, 'hour')
        return new Date(result).getTime();
    }
    
    addHourDateFormat = (lastDate, hour) => {
        let result = moment(lastDate).add(hour, 'hour')
        return momentDateGivenFormat(result, 'YYYY-MM-DD HH:mm:ss')
    }

    subHourDateFormat = (lastDate, hour) => {
        let result = moment(lastDate).subtract(hour, 'hour')
        return momentDateGivenFormat(result, 'YYYY-MM-DD HH:mm:ss')
    }
  
    handleStartTimeChange(date) {
        let sliderStartDate = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
        let sliderEndDate = this.state.sliderEndDate
        if(new Date(sliderStartDate).getTime() > new Date(sliderEndDate).getTime() ) {
            sliderEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')
        }
        this.setState({ sliderStartDate, sliderEndDate },
            () => {
                this.getMonitoringMetricsByTrend('')
                this.getAiopsDailyTrendByAssets()
            }
        )
    }

    handleEndTimeChange(date) {
        let sliderStartDate = this.state.sliderStartDate
        let sliderEndDate = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
        if(new Date(sliderEndDate).getTime() > new Date(sliderStartDate).getTime() ) {
            this.setState({ sliderEndDate },
                () => {
                    this.getMonitoringMetricsByTrend('')
                    this.getAiopsDailyTrendByAssets()
                }
            )
        }
    }

    alertEventGraph = () => {
        // let params = {}
        // params.alerts_case_id = this.state.caseId
        // this.props.getAlertsDailyTrend(params, (promise, result) => {
        //     if(promise) {
        //         this.setState({ showLoading: false, alertDailyTrend: result },
        //             () => this.alertBarChart()
        //         )
        //     } else {
        //         this.setState({ showLoading: false, alertDailyTrend: {} })
        //     }
        // })

        // params.start_time = this.state.caseStartTime
        // params.end_time = this.state.caseEndTime
        // if(this.state.caseEndTime && this.state.caseEndTime !== 'Invalid date') {
        //     this.props.getEventsDailyTrend(params, (promise, result) => {
        //         if(promise) {
        //             this.setState({ showLoading: false, eventsDailyTrend: result },
        //                 () => this.eventsLineChart()
        //             )
        //         } else {
        //             this.setState({ showLoading: false, eventsDailyTrend: {} })
        //         }
        //     })
        // }
    }

    // alertBarChart = () => {
    //     let graphDetails = this.state.alertDailyTrend.results
        
    //     let array = []
    //     if(graphDetails && graphDetails.data && graphDetails.data.count) {
    //         graphDetails.data.count.map((val, index) => {
    //             // if(index === 0) {
    //             //     val = 1
    //             // }
    //             let arrayValues = []
    //             arrayValues.push(this.getTimeLocal(graphDetails.labels[index]))
    //             arrayValues.push(val) 
    //             array.push(arrayValues)
    //         })

    //         let alertSeries = [{
    //             data: array
    //         }]
    //         let metricsStartInput = graphDetails.labels[0]
    //         let metricsEndInput = graphDetails.labels[graphDetails.labels.length - 1]
    //         let min = graphDetails.labels[graphDetails.labels.length - 2]
    //         let max = graphDetails.labels[graphDetails.labels.length - 1]

    //         // let min = graphDetails.labels[graphDetails.labels.length - 2]
    //         // let max = graphDetails.labels[graphDetails.labels.length - 1]
            
    //         // if(this.state.aggregate_by === 'minutes') {
    //         //     let min = graphDetails.labels[graphDetails.labels.length - 12]
    //         //     let max = graphDetails.labels[graphDetails.labels.length - 1]
    //         // }

    //         let columnWidth = '70%'
    //         if(alertSeries[0].data.length <= 10) {
    //             columnWidth = '5%'
    //         } else if(alertSeries[0].data.length <= 20) {
    //             columnWidth = '10%'
    //         } else if(alertSeries[0].data.length <= 30) {
    //             columnWidth = '20%'
    //         } else if(alertSeries[0].data.length <= 40) {
    //             columnWidth = '25%'
    //         } else if(alertSeries[0].data.length <= 50) {
    //             columnWidth = '30%'
    //         }
    //         let alertOptions = {
    //             chart: {
    //                 id: 'chart1',
    //                 height: 130,
    //                 type: 'bar',
    //                 toolbar: {
    //                     show: false
    //                 },
    //                 zoom: {
    //                     enabled: false,
    //                 },
    //             },
    //             stroke: {
    //                 width: 0
    //             },
                
    //             dataLabels: {
    //                 enabled: false
    //             },
    //             plotOptions: {
    //                 bar: {
    //                     endingShape: 'rounded',
    //                     columnWidth: columnWidth,
    //                     // barHeight: '100%',
    //                 }
    //             },

    //             colors: ['#19B2C0'],
    //             xaxis: {
    //                 type: 'datetime',
    //                 tooltip: {
    //                     enabled: false
    //                 },
    //                 labels: {
    //                     datetimeUTC: false,
    //                 }
    //             },
    //             yaxis: {
    //                 labels: {
    //                     show: false
    //                 }
    //             },            
    //             grid: {
    //                 show: false,
    //                 xaxis: {
    //                     lines: {
    //                         show: false
    //                     },
    //                 },
    //                 yaxis: {
    //                     lines: {
    //                         show: false
    //                     }
    //                 },
    //             }
    //         }

    //         this.setState({ alertSeries, alertOptions })
    //     } else {
    //         this.setState({ alertSeries: [], alertOptions: {} })
    //     }

    // }

    eventsLineChart = () => {
        let graphDetails = this.state.eventsDailyTrend.results
        
        let array = []
        if(graphDetails && graphDetails.data && graphDetails.data.count) {
            graphDetails.data.count.forEach((val, index) => {
                // if(index === 0) {
                //     val = 1
                // }
                let arrayValues = []
                arrayValues.push(new Date(graphDetails.labels[index]).getTime())
                arrayValues.push(val) 
                array.push(arrayValues)
            })

            let seriesLine = [{
                name: 'Events',
                data: array
            }]

            let optionsLine = {
                chart: {
                    id: 'chart2',
                    type: 'line',
                    height: 230,
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false,
                    },
                },
                // colors: ['#19B2C0'],
                stroke: {
                    width: 2
                },
                dataLabels: {
                    enabled: false
                },
                fill: {
                    opacity: 1,
                },
                markers: {
                    size: 0
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        datetimeUTC: false,
                    },
                    tooltip: {
                        enabled: false,
                    }
                },
                yaxis: {
                    labels: {
                        show: false
                    },
                    tooltip: {
                        enabled: false,
                    }
                },                        
                grid: {
                    xaxis: {
                        lines: {
                            show: false
                        },
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                },
                tooltip: {
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        show: false
                    },
                    marker: {
                        show: false
                    },
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]                    
                        let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
                        dateLable = dateLable.toString()
                        dateLable = momentDateGivenFormat(dateLable, 'DD MMM Y HH:mm')
                        
                        return '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+dateLable+'</div><div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[0] +'" ></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">Events</span><span class="font-weight-normal ml-2">'+val+'</span></div></div></div></div>'
                    },
                }
            }

            this.setState({ eventSeries: seriesLine, eventOptions: optionsLine })
        } else {
            this.setState({ eventSeries: [], eventOptions: {} })
        }

    }

    getAttribute = () => {
        // let a = document.getElementsByClassName('apexcharts-selection-rect')

        var attributes = document.querySelector('.assetDetailBrush .apexcharts-selection-rect') && document.querySelector('.assetDetailBrush .apexcharts-selection-rect').attributes
        if(attributes) {
            let width = attributes.width.value

            let x1 = parseInt(attributes.x.value) + parseInt(20)
            let x2 = parseInt(attributes.x.value) + parseInt(width) + parseInt(10)
            var d1 = document.getElementById('leftSliderSpan');
            d1.style.position = "absolute";
            d1.style.left = x1+'px';
            d1.style.top = '80px';
            d1.style.color = '#039BE5';
            
            var d2 = document.getElementById('rightSliderSpan');
            d2.style.position = "absolute";
            d2.style.left = x2+'px';
            d2.style.top = '80px';
            d2.style.color = '#039BE5';

            // let caseTime = twoDateTimeInterval(this.state.sliderStartDate, this.state.sliderEndDate)
            let sliderBelowStartDate = ''
            let sliderBelowEndDate = ''
            if(width > 80) {
                sliderBelowStartDate = momentDateGivenFormat(this.state.sliderStartDate, 'DD/M HH:mm')
                sliderBelowEndDate = momentDateGivenFormat(this.state.sliderEndDate, 'DD/M HH:mm')
            }

            this.setState({ sliderBelowStartDate, sliderBelowEndDate })
        }
    }

	render() {
		return (
            <div className='' ref={this.scrolltoTop}>
            {!this.state.showMetricsDetails ?
                !this.state.showLoading ?
                    <div className="">
                        <div className="row detail">
                            <div className={`detail-title mb-0 p-2`}>
                                <div className="aiopsTopFiltersection">
                                    <div className={`row`}>
                                        <div className={`col-sm-12 d-flex justify-content-between mb-1`}>
                                            <div className="d-flex align-self-center">
                                                <div className="d-flex">
                                                    <p className="indicatoreBox bg-orange mb-0 mr-2"></p>
                                                    <p className="f12 mb-0 align-self-center mr-2">CS - Case Start</p>
                                                    <p className="f12 mb-0 align-self-center">CE - Case End</p>
                                                </div>
                                            </div>
                                            <div className={`d-flex justify-content-end`}>
                                                <div className={`d-flex`}>
                                                    <span className={`mr-2 f12 align-self-center `}>Last</span>
                                                    <span className={`mr-2 align-self-center f12 ${this.state.selectedFilterType === 'D' && this.state.selectedFilterPeriod === 3 ? 'badge badge-info' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'D', selectedFilterPeriod: 3, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>3D</span>
                                                    <span className={`mr-2 align-self-center f12 ${this.state.selectedFilterType === 'D' && this.state.selectedFilterPeriod === 1 ? 'badge badge-info' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'D', selectedFilterPeriod: 1, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>1D</span>
                                                    <span className={`mr-2 align-self-center f12 ${this.state.selectedFilterType === 'H' && this.state.selectedFilterPeriod === 12 ? 'badge badge-info' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'H', selectedFilterPeriod: 12, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>12H</span>
                                                    <span className={`mr-2 align-self-center f12 ${this.state.selectedFilterType === 'H' && this.state.selectedFilterPeriod === 6 ? 'badge badge-info' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'H', selectedFilterPeriod: 6, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>6H</span>
                                                    {/* <span className={`mr-2 align-self-center f12 ${this.state.selectedFilterType === 'H' && this.state.selectedFilterPeriod === 3 ? 'badge badge-info' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'H', selectedFilterPeriod: 3, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>3H</span> */}
                                                    <span className={`mr-2 align-self-center f12 ${this.state.selectedFilterType === 'H' && this.state.selectedFilterPeriod === 1 ? 'badge badge-info' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'H', selectedFilterPeriod: 1, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>1H</span>
                                                </div>
                                                <div className="d-flex displayNone">
                                                    <Datetime 
                                                        value={momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM YYYY hh:mm A')}
                                                        onChange={this.handleStartTimeChange}
                                                        dateFormat={'DD MMM YYYY'}
                                                        timeFormat={'hh:mm A'}
                                                        inputProps={{ readOnly: true }}
                                                        className="w-100 mr-2 aiopsMetricsDatepicker"
                                                        //maxDate={new Date().getTime()}
                                                        isValidDate={disableFutureDt}
                                                    />
                                                    <Datetime
                                                        value={momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM YYYY hh:mm A')}
                                                        onChange={this.handleEndTimeChange}
                                                        dateFormat={'DD MMM YYYY'}
                                                        timeFormat={'hh:mm A'}
                                                        inputProps={{ readOnly: true }}
                                                        className="w-100 aiopsMetricsDatepicker"
                                                        //minDate={new Date(this.state.sliderStartDate).getTime()}
                                                        //maxDate={new Date()}
                                                        isValidDate={disableFutureDt}
                                                    />
                                                </div>
                                                {/* <div>
                                                    <p className="f11 m-0 p-0">{momentDateGivenFormat(this.state.brushStartDate, 'DD MMM YYYY HH:mm:ss')}</p>
                                                    <p className="f11 m-0 p-0">{momentDateGivenFormat(this.state.brushEndDate, 'DD MMM YYYY HH:mm:ss')}</p>
                                                </div> */}
                                            </div>
                                        </div>
                                        {this.state.brushSliderOptions && Object.entries(this.state.brushSliderOptions).length && this.state.brushSliderSeries && this.state.brushSliderSeries.length ?
                                            <React.Fragment>
                                            <div className={`col-md-12 assetDetailBrush mb-n2 ${this.state.disableDateSlider ? '' : ''}`} >
                                                <Chart options={this.state.brushSliderOptions} series={this.state.brushSliderSeries} type="bar" height={80} />
                                            </div>
                                            <div className="d-flex dsplayNone">
                                                <div id="leftSliderSpan" className="f12">{this.state.sliderBelowStartDate}</div>
                                                <div id="rightSliderSpan" className="f12">{this.state.sliderBelowEndDate}</div>
                                            </div>
                                            </React.Fragment>
                                        : null}
                                        {this.state.overallAlertOptions && Object.entries(this.state.overallAlertOptions).length && this.state.overallAlertSeries ?
                                            <React.Fragment>
                                            <small className="col-sm-12">Alert</small>
                                            <div className={`col-md-12 transparentTooltip ${this.state.selectedFilterPeriod === 'caseTime' ? 'mt-n2' : 'mt-n4'} mb-n3`}>
                                                <Chart options={this.state.overallAlertOptions} series={this.state.overallAlertSeries} type="bar" height={100} />
                                            </div>
                                            </React.Fragment>
                                        : null}
                                        {this.state.overallEventOptions && Object.entries(this.state.overallEventOptions).length && this.state.overallEventSeries ?
                                            <React.Fragment>
                                            <small className="col-sm-12">Event</small>
                                            <div className={`col-md-12 mt-n4 mb-n2 transparentTooltip`}>
                                                <Chart options={this.state.overallEventOptions} series={this.state.overallEventSeries} type="line" height={100} />
                                            </div>
                                            </React.Fragment>
                                        : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 mt-3">
                            <div className="row mb-1">
                                <p className="mb-0 f16 text-white">Metrics</p>
                                <div className="d-flex ml-1">
                                    <p className="f12 m-0 align-self-center pt-1">Showing the metrics for assets in period </p>
                                    <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{this.state.brushStartDate ? momentDateGivenFormat(this.state.brushStartDate, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.brushEndDate, 'DD MMM YYYY HH:mm') : ''}</p>
                                    <span className="ml-2 align-self-center">(local time)</span>
                                </div>
                                {/* <p className="f12 ml-2 mb-0 pt-1 align-self-center">Showing the  of assets fo the period of <span {this.state.sliderStartDate && this.state.sliderEndDate ? (momentDateGivenFormat(this.state.brushStartDate, 'DD MMM YYYY HH:mm') + ' '+momentDateGivenFormat(this.state.brushEndDate, 'DD MMM YYYY HH:mm')) : ''}</p> */}
                            </div>
                            <div  className="row">
                                {this.state.metricsByTrend && this.state.metricsByTrend.length ?
                                    this.state.metricsByTrend.map((item, index) => {
                                        return (
                                        <div className={`col-lg-6 mb-3 ${index % 2 === 0 ? 'pl-0' : 'p-0'}`}>
                                            <div className="bg-dark-50 rounded p-2">
                                                <div className="d-flex justify-content-between mb-1">
                                                    <div className="rounded text-info bg-muted p-1 f12 hiddenEllipses" id={`metricsTitle_`+index}>{item.asset_name}</div>
                                                    <UncontrolledTooltip placement='top' target={`metricsTitle_`+index}>{item.asset_name}</UncontrolledTooltip>
                                                    <span className="fa fa-expand-wide cursorPointer text-white align-self-center ml-3" onClick={() => this.getMetricsDeatils(item.asset_id, item.asset_name) }></span>
                                                </div>
                                                <div className="d-flex py-2 border-top-dark">
                                                    <div className="small align-self-center text-nowrap text-truncate w-25" id="Alerts">
                                                        Alerts
                                                        <UncontrolledTooltip placement='top' target="Alerts">Alerts</UncontrolledTooltip>
                                                    </div>
                                                    <div className="w-75">
                                                        {this.state[item.asset_name+'_alertSeries'] && this.state[item.asset_name+'_alertSeries'].length && this.state[item.asset_name+'_alertOptions'] ?
                                                            <div className="cursorPointer transparentTooltip">
                                                                <Chart options={this.state[item.asset_name+'_alertOptions']} series={this.state[item.asset_name+'_alertSeries']} type="bar" height={15}/>
                                                            </div>
                                                        : null}
                                                    </div>
                                                </div>
                                                <div className="d-flex py-2 border-top-dark">
                                                    <div className="small align-self-center text-nowrap text-truncate w-25" id="Events">
                                                        Events
                                                        <UncontrolledTooltip placement='top' target="Events">Events</UncontrolledTooltip>
                                                    </div>
                                                    <div className="w-75">
                                                            {this.state[item.asset_name+'_eventSeries'] && this.state[item.asset_name+'_eventSeries'].length && this.state[item.asset_name+'_eventOptions'] ?
                                                            <div className="cursorPointer transparentTooltip">
                                                                <Chart options={this.state[item.asset_name+'_eventOptions']} series={this.state[item.asset_name+'_eventSeries']} type="line" height={15}/>
                                                            </div>
                                                        : null}
                                                    </div>
                                                </div>
                                                {Object.keys(item.data).map((key, i) => {
                                                    return (
                                                    <div className="d-flex py-2 border-top-dark">
                                                        <div className="small align-self-center text-nowrap text-truncate w-25" id={'index_'+index+'_'+i}>
                                                        {key}
                                                        <UncontrolledTooltip placement='top' target={'index_'+index+'_'+i}>{key}</UncontrolledTooltip>
                                                        </div>
                                                        <div className="w-75">
                                                            {this.state[key+'_options_'+item.asset_id] && Object.entries(this.state[key+'_options_'+item.asset_id]).length && this.state[key+'_'+item.asset_id] && this.state[key+'_'+item.asset_id].length ?
                                                                <div className="cursorPointer transparentTooltip">
                                                                    <Chart options={this.state[key+'_options_'+item.asset_id]} series={this.state[key+'_'+item.asset_id]} type="area" height={20}/>
                                                                </div>
                                                            : null}
                                                        </div>
                                                    </div>
                                                    )
                                                })}
                                                {/* {Object.keys(item.data).legnth < 3  ?
                                                    <div className="d-flex py-2 border-top-dark" style={{minHeight: "37px"}}></div>
                                                : null} */}
                                            </div>
                                        </div>
                                        )
                                    })
                                : null}
                            </div>
                        </div>
                    </div>
                : 
                    <div className='d-flex justify-content-center m-4'>
                        <Spinner className='text-center' color='white' size='lg' />
                    </div>
            :
                <div className="mt-3">
                    <AssetsMetricDetails 
                        assetPageDetails = {this.state.selectedAsset ? this.state.selectedAsset : {}}
                        showMetricsDetails={(bool) => this.setState({ showMetricsDetails: bool})}
                        backGroudColor={'bg-muted'}
                        heading={'bg-black text-info-50'}
                        textColor={'text-white4'}
                        navBg={'bg-muted'}
                        newDesign={true}
                        caseId={this.state.caseId}
                    />
                </div>
            }
            </div>
		)
	}
}
/**
 * Type of the props used in the component
 */
AssetsRightSection.propTypes = {
    getAiopsDailyTrend: PropTypes.func,
    getMonitoringMetricsByTrend: PropTypes.func,
    getAiopsDailyTrendByAssets: PropTypes.func,
    // listEventsByAssets: PropTypes.func,
    // listAlertsByAssets: PropTypes.func,
    getAlertsDailyTrend: PropTypes.func,
    getEventsDailyTrend: PropTypes.func,
    setAiopsPropsDetails: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
	// console.log('state', state)
	return {
        aiopsPropsDetails: state.aiops.aiopsPropsDetails ? state.aiops.aiopsPropsDetails : {},
        caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : {},
        assetsList: state.aiops.caseDetails.results && state.aiops.caseDetails.results.assets ? state.aiops.caseDetails.results.assets : [],
        
        assetsAiopsDailyTrend: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.assetsAiopsDailyTrend ? state.aiops.aiopsPropsDetails.assetsAiopsDailyTrend : {},
        assetsAlertEventTrend: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.assetsAlertEventTrend ? state.aiops.aiopsPropsDetails.assetsAlertEventTrend : {},
        assetsMonitroingList: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.assetsMonitroingList ? state.aiops.aiopsPropsDetails.assetsMonitroingList : {},
    }
}

export default connect(mapStateToProps, {
    getAiopsDailyTrend,
    getMonitoringMetricsByTrend,
    getAiopsDailyTrendByAssets,
    // listEventsByAssets,
    // listAlertsByAssets,
    getAlertsDailyTrend,
    getEventsDailyTrend,
    setAiopsPropsDetails,
})(withRouter(AssetsRightSection))