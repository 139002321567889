/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file loadingpage.js
 * @author Prakash // on 28/03/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 **************************************************/
import React, { Component } from 'react'
import { URL_PATH } from '../config/urlPath'
import { AppWrapper } from './common/AppWrapper'
import _ from 'lodash'

class loadingPage extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    componentDidMount = () => {
        if(this.props.userMenu && Object.entries(this.props.userMenu).length) {
            // this.props.history.push(URL_PATH.LOADING_PAGE)
            // this.props.history.push(URL_PATH.AIOPS_DASHBOARD)
            // window.location.href = URL_PATH.CHECKS_SEARCH
            this.props.history.push(this.props.userMenu.defaultLandingPage && this.props.userMenu.defaultLandingPage.link ? this.props.userMenu.defaultLandingPage.link : URL_PATH.CHECKS_SEARCH)
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.userMenu && Object.entries(this.props.userMenu).length && !_.isEqual(prevProps.userMenu, this.props.userMenu)) {
            // this.props.history.push(URL_PATH.CHECKS_SEARCH)
            // alert(URL_PATH.LOADING_PAGE)
            this.props.history.push(this.props.userMenu.defaultLandingPage && this.props.userMenu.defaultLandingPage.link ? this.props.userMenu.defaultLandingPage.link : URL_PATH.CHECKS_SEARCH)
        }
    }

    /**
     * Renders Errro page
     */
    render() {
        return (
            <div className='container-fluid overflow-auto flex-grow-1'>
                <div className={`loaderOverlay`}>
                    <div className="overlayEqualizerLoader">
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                    </div>
                </div>
            </div>
        )
    }
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    return {
		userMenu: state.filters.userMenu,
    }
}

export default AppWrapper(loadingPage, mapStateToProps, {})