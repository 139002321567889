/*************************************************
 * Tvastar
 * @exports
 * @file configurationsAction.js
 * @author Rajasekar // on 27/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'

import {
	DETECTORS,
	GET_IDENTIFIERS,
	GET_IDENTIFIERS_TOTAL_COUNT,
	
	CONFIGURATION,
	INSERT_CONFIGURATON,
	DELETE_CONFIGURATON,
	UPDATE_CONFIGURATION,
} from '../../config'
import { ACTION_TYPES } from '../types'
import { myLog } from '../../utils/utility'

/**
 * Action to get all configurations list
 * @param {Object} body
 */
export const listAllIdentifiers = (body, callback) => {
	return async dispatch => {
		try {
			const identifiersList = await API.post(DETECTORS, GET_IDENTIFIERS, { body })

			dispatch({
				type: ACTION_TYPES.SET_IDENTIFIERS_LIST,
				payload: identifiersList,
			})
			callback(identifiersList)
		} catch (error) {
			dispatch({
				type: ACTION_TYPES.SET_IDENTIFIERS_LIST,
				payload: {},
			})
			callback(false)
			myLog('identifiers', error)
		}
	}
}

/**
 * Action to create a new configuration
 * @param {Object} body
 * @param {Function} callback
 */
export const createConfiguration = (body, callback) => {
	return async () => {
		try {
			const result = await API.post(CONFIGURATION, INSERT_CONFIGURATON, { body })
			myLog('insert config', result)
			callback(true, result)
		} catch (error) {
			myLog('insert config', error)
			callback(false)
		}
	}
}

/**
 * Action to get total count of configuration for pie chart
 * @param {Object} body
 * @param {Function} callback
 */
export const getIdentifiersTotalCount = (body, callback) => {
	return async () => {
		try {
			const totalCount = await API.post(DETECTORS, GET_IDENTIFIERS_TOTAL_COUNT, { body })
			callback(true, totalCount)
		} catch (error) {
			myLog('getIdentifiersTotalCount', error)
			callback(false)
		}
	}
}

/**
 * Action update configuration
 * @param {Object} body
 * @param {Function} callback
 */
export const updateConfiguration = (body, callback) => {
	return async () => {
		try {
			const response = await API.post(CONFIGURATION, UPDATE_CONFIGURATION, { body })
			if (response.status) callback(true)
			else callback(false)
		} catch (error) {
			myLog('updateConfiguration', error)
			callback(false)
		}
	}
}

/**
 * Action to delete configuration
 * @param {Object} body
 * @param {Function} callback
 */
export const deleteConfiguration = (body, callback) => {
	return async () => {
		try {
			const response = await API.post(CONFIGURATION, DELETE_CONFIGURATON, { body })
			if (response.status) callback(true)
			else callback(false)
		} catch (error) {
			myLog('deleteConfiguration', error)
			callback(false)
		}
	}
}
