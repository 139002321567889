/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Metrics.js
 * @author Prakash // on 22/02/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Spinner, UncontrolledTooltip } from 'reactstrap'
import moment from 'moment'
import { momentDateGivenFormat, momentConvertionUtcToLocalTime, twoDateTimeInterval, convertBytes, convertBytesWithoutUnit, convertSeconds, convertSecondsWithoutUnit, countFormater, countFormaterWithoutUnit, convertBits, convertBitsWithoutUnit, subDays, subHours, addHours, subMinutes, addMinutes } from '../../../utils/utility'
import _ from 'lodash'

import { setAiopsPropsDetails, getAlertTrendByAsset } from '../../../actions/aiops/AiopsAction'
import { getMonitoringMetricsByTrend, getAiopsDailyTrendByAssets } from '../../../actions/aiops/MetricsAction'

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";

import Chart from 'react-apexcharts'
import EventSreDetails from './EventSreDetails'
var hoverOnDetailGraph = ''


const today = moment();
const disableFutureDt = current => {
  return current.isBefore(today)
}
class EventMetricDetails extends Component {
    
    constructor(props) {

        super(props)
        this.scrollRef = React.createRef()
        this.timer = null;
        
        this.state = {
            showLoading: true,
            activeSideTopTab: 'InstanceMetrics',

            selectedFilterType: 'H',
            selectedFilterPeriod: 6,
            showAnnotation: true
        }
    }
    handleStartTimeChange  = this.handleStartTimeChange.bind(this)
    handleEndTimeChange    = this.handleEndTimeChange.bind(this)
    

	componentDidMount = () => {

        this.changeGraphTimings()

        this.handleMetricData()
    }

    handleMetricData = () => {
        if(this.props.eventMonitoringDetails && Object.entries(this.props.eventMonitoringDetails).length) {
            if(this.props.eventMonitoringDetails.hasOwnProperty(this.props.eventPageDetails.assetName)) {
                this.setState({ 
                    assetName: this.props.eventPageDetails.assetName,
                    assetID: this.props.eventPageDetails.assetID,
                    filterdMetricsByTrend: this.props.eventMonitoringDetails[this.props.eventPageDetails.assetName],
                    showLoading: false,
                }, 
                    () => this.metricsDetailGraph()
                )
            } else {
                this.setState({ assetName: this.props.eventPageDetails.assetName, assetID: this.props.eventPageDetails.assetID },
                    () => {
                        this.getMetricsDeatils()
                        this.getAlertTrendByAsset()
                    }
                )
            }
        } else {
            this.setState({ assetName: this.props.eventPageDetails.assetName, assetID: this.props.eventPageDetails.assetID },
                () => {
                    this.getMetricsDeatils()
                    this.getAlertTrendByAsset()
                }
            )
        }
        
        if(this.props.eventMonitoringEvent && Object.entries(this.props.eventMonitoringEvent).length) {
            if(this.props.eventMonitoringEvent.hasOwnProperty(this.props.eventPageDetails.assetName)) {
                this.setState({ metricsAlertTrend: [] },
                    () => {
                        this.setState({ 
                            assetName: this.props.eventPageDetails.assetName,
                            assetID: this.props.eventPageDetails.assetID,
                            metricsAlertTrend: this.props.eventMonitoringEvent[this.props.eventPageDetails.assetName]
                        })
                    }
                )
            }
        }
    }

    getAlertTrendByAsset = () => {
        let caseStartTime = this.props.caseDetails.created_at
        let caseEndTime = momentDateGivenFormat(addMinutes(this.props.caseDetails.last_event, 1), 'YYYY-MM-DD HH:mm:ss')
        if(caseEndTime < caseStartTime) {
            caseEndTime = caseStartTime
        }
        let updatedCaseStartTime =  subDays(caseStartTime, 3)

        let params = {}
        params.start_time = updatedCaseStartTime
        params.end_time = caseEndTime
        params.asset_id = this.state.assetID
    
        this.props.getAlertTrendByAsset(params, (promise, response) => {
            if(promise) {
                this.setState({ metricsAlertTrend: response && response.results ? response.results : [] },
                    () => {
                        if(response.results && response.results.length) {
                            let obj = this.props.eventMonitoringEvent
                            obj[this.state.assetName] = response.results
                            this.props.setAiopsPropsDetails('eventMonitoringEvent', obj)
                        }
                    }
                )
            }
        })
    }

    getMetricsDeatils = () => {
        let params = {}
        params.provider = this.props.caseDetails && this.props.caseDetails.provider ? (Array.isArray(this.props.caseDetails.provider) ? this.props.caseDetails.provider[0] : this.props.caseDetails.provider) : 'aws'
        
        let caseStartTime = this.props.caseDetails.created_at
        let caseEndTime = momentDateGivenFormat(addMinutes(this.props.caseDetails.last_event, 1), 'YYYY-MM-DD HH:mm:ss')
        if(caseEndTime < caseStartTime) {
            caseEndTime = caseStartTime
        }
        let updatedCaseStartTime = subDays(caseStartTime, 3)

        params.start_time = updatedCaseStartTime
        params.end_time = caseEndTime
        params.anomaly = true
        params.case_id = this.props.caseDetails.case_id
        let assetArray = []
        if(this.props.eventPageDetails && this.props.eventPageDetails.assets) {
            this.props.eventPageDetails.assets.forEach(item => {
                assetArray.push(item.asset_name)
            })
            
        }
        params.asset_name = assetArray
        params.top = 10
        params.service_name = this.props.eventPageDetails.serviceName

        if(params.asset_name) {
            this.props.getMonitoringMetricsByTrend(params, (promise, result) =>{
                if(promise) {
                    this.setState({ filterdMetricsByTrend: result, showLoading: result.length ? true : false },
                        () => {
                            this.metricsDetailGraph()
                            if(result.length) {
                                let obj = this.props.eventMonitoringDetails
                                obj[this.state.assetName] = result
                                this.props.setAiopsPropsDetails('eventMonitoringDetails', obj)
                            }
                        }   
                    )
                } else {
                    this.setState({ showLoading: false })
                }
            })
        }
    }
    
    changeGraphTimings = () => {

        let caseStartTime = this.props.caseDetails.created_at
        let caseEndTime = momentDateGivenFormat(addMinutes(this.props.caseDetails.last_event, 1), 'YYYY-MM-DD HH:mm:ss')
        if(caseEndTime < caseStartTime) {
            caseEndTime = caseStartTime
        }
        let updatedCaseStartTime = subDays(caseStartTime, 3)

        let selectedFilterType = this.state.selectedFilterType
        let selectedFilterPeriod = this.state.selectedFilterPeriod

        let sliderStartDate = momentConvertionUtcToLocalTime(updatedCaseStartTime, 'YYYY-MM-DD HH:mm:ss')
        let sliderEndDate = momentConvertionUtcToLocalTime(caseEndTime, 'YYYY-MM-DD HH:mm:ss')
        
        if(selectedFilterType === 'D') {
            sliderStartDate = subDays(sliderEndDate, selectedFilterPeriod)
        } else {
            sliderStartDate = subHours(sliderEndDate, selectedFilterPeriod)
        }

        let difference = twoDateTimeInterval(sliderStartDate, sliderEndDate)
        let brushStartDate = sliderStartDate
        let brushEndDate = sliderEndDate
        if(difference && difference.length) {
            if(difference[0] > 0) {
                if(difference[0] === 1) {
                    brushStartDate = subHours(brushEndDate, 1)
                } else if(difference[0] < 2) {
                    brushStartDate = subHours(brushEndDate, 3)
                } else if(difference[0] < 3) {
                    brushStartDate = subHours(brushEndDate, 4)
                } else {
                    brushStartDate = subHours(brushEndDate, 8)
                }
            } else if(difference[1] > 0) {
                if(difference[1] <= 1) {
                    brushStartDate = subMinutes(brushEndDate, 10)
                } else if(difference[1] < 3) {
                    brushStartDate = subMinutes(brushEndDate, 15)
                } else if(difference[1] < 6) {
                    brushStartDate = subMinutes(brushEndDate, 30)
                } else if(difference[1] < 12) {
                    brushStartDate = subMinutes(brushEndDate, 45)
                } else if(difference[1] < 24) {
                    brushStartDate = subHours(brushEndDate, 1)
                }
            } else {
                brushStartDate = subMinutes(brushEndDate, 10)
            }
        }
        
        let dateArray = []
        let localCaseStartTime = sliderStartDate
        let localCaseEndTime = sliderEndDate
        if(selectedFilterType === 'D' && selectedFilterPeriod === 3) {
            dateArray = []
            let min = 0
            let reachedEnd = 0
            for(let y=0;y<=73;y++) {
                let dateHour = momentDateGivenFormat(addHours(localCaseStartTime, y), 'YYYY-MM-DD HH:mm')
                let value = 5
                if(min === 0) {
                    value = 10
                    min += 1
                } else {
                    min += 1
                    if(min === 4) {
                        min = 0
                    }
                }
                if(new Date(dateHour).getTime() < new Date(localCaseEndTime).getTime()) {
                    dateArray.push([dateHour, value])
                } else if(reachedEnd === 0) {
                    reachedEnd = 1
                    dateArray.push([localCaseEndTime, 5])
                }
            }
        } else if(selectedFilterType === 'D' && selectedFilterPeriod === 1) {
            let min = 0
            let reachedEnd = 0
            for(let y=0;y<=72;y++) {
                let dateHour = momentDateGivenFormat(addHours(localCaseStartTime, (y/3)), 'YYYY-MM-DD HH:mm')
                let value = 5
                if(min === 0) {
                    value = 10
                    min += 1
                } else {
                    min += 1
                    if(min === 6) {
                        min = 0
                    }
                }
                if(new Date(dateHour).getTime() < new Date(localCaseEndTime).getTime()) {
                    dateArray.push([dateHour, value])
                } else if(reachedEnd === 0) {
                    reachedEnd = 1
                    dateArray.push([localCaseEndTime, 5])
                }
            }
        } else if(selectedFilterType === 'H' && selectedFilterPeriod === 12) {
            let min = 0
            let reachedEnd = 0
            for(let y=0;y<=48;y++) {
                let dateHour = momentDateGivenFormat(addHours(localCaseStartTime, (y/4)), 'YYYY-MM-DD HH:mm')
                let value = 5
                if(momentDateGivenFormat(dateHour, 'mm') === '00' && min === 0) {
                    value = 10
                    min += 1
                } else {
                    min += 1
                    if(min === 8) {
                        min = 0
                    }
                }
                if(new Date(dateHour).getTime() < new Date(localCaseEndTime).getTime()) {
                    dateArray.push([dateHour, value])
                } else if(reachedEnd === 0) {
                    reachedEnd = 1
                    dateArray.push([localCaseEndTime, 5])
                }
            }
        } else if(selectedFilterType === 'H' && selectedFilterPeriod === 6) {
            let hour = ''
            let reachedEnd = 0
            for(let y=0;y<50;y++) {
                let dateHour = momentDateGivenFormat(addHours(localCaseStartTime, y/8),'YYYY-MM-DD HH:mm')
                let value = 5                
                if(momentDateGivenFormat(dateHour, 'HH') !== hour) {
                    hour = momentDateGivenFormat(dateHour, 'HH')
                    value = 10
                }
                if(new Date(dateHour).getTime() < new Date(localCaseEndTime).getTime()) {
                    dateArray.push([dateHour, value])
                } else if(reachedEnd === 0) {
                    reachedEnd = 1
                    dateArray.push([localCaseEndTime, 5])
                }
            }
        } else if(selectedFilterType === 'H' && selectedFilterPeriod === 3) {
            let hour = ''
            let reachedEnd = 0
            for(let y=0;y<49;y++) {
                let dateHour = momentDateGivenFormat(addHours(localCaseStartTime, y/16),'YYYY-MM-DD HH:mm')
                let value = 5
                if(momentDateGivenFormat(dateHour, 'HH') !== hour) {
                    hour = momentDateGivenFormat(dateHour, 'HH')
                    value = 10
                }
                if(new Date(dateHour).getTime() < new Date(localCaseEndTime).getTime()) {
                    dateArray.push([dateHour, value])
                } else if(reachedEnd === 0) {
                    reachedEnd = 1
                    dateArray.push([localCaseEndTime, 5])
                }
            }
        } else if(selectedFilterType === 'H' && selectedFilterPeriod === 1) {
            // let minute = ''
            let reachedEnd = 0
            let min = 0
            for(let y=0;y<32;y++) {
                let dateHour = momentDateGivenFormat(addMinutes(localCaseStartTime, (y*2)),'YYYY-MM-DD HH:mm')
                let value = 5
                if(min === 0) {
                    // minute = momentDateGivenFormat(dateHour, 'mm')
                    value = 10
                    min++;
                } else {
                    min++;
                    if(min === 6) {
                        min= 0
                    }
                }
                if(new Date(dateHour).getTime() < new Date(localCaseEndTime).getTime()) {
                    dateArray.push([dateHour, value])
                } else if(reachedEnd === 0) {
                    reachedEnd = 1
                    dateArray.push([localCaseEndTime, 5])
                }
            }
        }

        this.setState({ dateArray, sliderStartDate, sliderEndDate, brushStartDate, brushEndDate },
            () => {
                this.brushSliderChart()
                if(this.props.eventMonitoringDetails.hasOwnProperty(this.props.eventPageDetails.assetName)) {
                    this.metricsDetailGraph()
                }
            }
        )
    }
    
    brushSliderChart = () => {
        let dateArray = this.state.dateArray

        let obj = {}
        obj.brushStartDate = this.state.brushStartDate
        obj.brushEndDate = this.state.brushEndDate
        this.props.setAiopsPropsDetails('event_sre_slider_period', obj)

        if(dateArray.length) {
            let brushSliderSeries = [{
                data: dateArray
            }]

            let columnWidth = '10%'
            let brushSliderOptions = {
                plotOptions: {
                    bar: {
                        barHeight: '100%',
                        columnWidth: columnWidth,
                    },          
                },
                chart: {
                    id: 'chart1',
                    height: 130,
                    type: 'bar',
                    brush:{
                        target: 'none',
                        enabled: true,
                    },
                    selection: {
                        enabled: true,
                        fill: {
                            color: "#039BE5",
                            opacity: 1,
                            // opacity: 0.6
                        },  
                        stroke: {
                            show: false,
                            curve: 'smooth',
                            lineCap: 'round',
                            // colors: '#00A3CB',
                            colors: '#039BE5',
                            width: 0,
                            dashArray: 0,      
                        },
                        xaxis: {
                            min: new Date(this.state.brushStartDate).getTime(),
                            max: new Date(this.state.brushEndDate).getTime(),
                        },
                        style: {
                            fontSize: '28px',
                            color: '#000000'
                        }
                    },
                    events: {
                        selection: (chartContext, { xaxis, yaxis }) => {
                            var minDate = new Date(xaxis.min);
                            var maxDate = new Date(xaxis.max);
                            let startSlice = momentDateGivenFormat(minDate, 'YYYY-MM-DD HH:mm:ss');
                            let endSlice = momentDateGivenFormat(maxDate, 'YYYY-MM-DD HH:mm:ss');
                            this.setState({ brushStartDate: startSlice, brushEndDate: endSlice })
                            // this.getAttribute()
                        },
                        click: (event, chartContext, config) => {
                            this.brushScrolled()
                        }
                    }
                },
                colors: ['#515151'],
                xaxis: {
                    type: 'datetime',
                    tooltip: {
                        enabled: false
                    },
                    position: 'top',
                    labels: {
                        datetimeUTC: false,
                    },
                    axisTicks: {
                        show: false,
                    }
                },
                yaxis: {
                    show: false,
                    tickAmount: 2,
                    labels: {
                        show: false
                    },
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: false
                        },
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                }
            }
        
            this.setState({ brushSliderSeries, brushSliderOptions })
        }
    }

    brushScrolled = () => {
            let obj = {}
            obj.brushStartDate = this.state.brushStartDate
            obj.brushEndDate = this.state.brushEndDate
            this.props.setAiopsPropsDetails('event_sre_slider_period', obj)
        
            if(this.state.activeSideTopTab === 'InstanceMetrics') {
                this.metricsDetailGraph()
            }
    }

    setSREProps = () => {
        // let obj = {}
        // if(this.state.activeSideTopTab === 'SREMetrics') {
        //     obj.brushStartDate = this.state.brushStartDate
        //     obj.brushEndDate = this.state.brushEndDate
        // }
        // this.props.setAiopsPropsDetails('event_sre_slider_period', obj)

        // this.setState({ activeSideTopTab: 'SREMetrics' })
    }

    metricsDetailGraph = () => {
        let brushStartDate = this.state.brushStartDate
        let brushEndDate = this.state.brushEndDate 
        
        let graphDetails = this.state.filterdMetricsByTrend && this.state.filterdMetricsByTrend[0] ? this.state.filterdMetricsByTrend[0] : {}
        let startIndex = 0
        let endIndex = 0
        let hoverContent = {}
        
        if(graphDetails && Object.entries(graphDetails).length) {
            hoverContent = graphDetails;
            let graphData = graphDetails.data
            let graphDates = graphDetails.dates
            graphDates.forEach((lab, i) => {
                if(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm') === momentDateGivenFormat(brushStartDate, 'YYYY-MM-DD HH:mm')) {
                    startIndex = i
                }
                if(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm') === momentDateGivenFormat(brushEndDate, 'YYYY-MM-DD HH:mm')) {
                    endIndex = (i+1)
                }
            })

            if(endIndex === 0) {
                endIndex = graphDates.length-1
            }

            
            Object.entries(graphData).forEach(([key, item], i) => {
                let yhatAvailable = 0
                let alertAvailable = 0
                let series = []    
                let dataRow = {}                
                var points =  []
                let higherArray = []
                let yDetails = []
                
                if(item.values && item.values.length) {                    
                    let alertGraphDetails = this.state.metricsAlertTrend.filter(arr => arr.event_type === key)
                    let alertsTimeLine = []
                    if(alertGraphDetails.length) {
                        alertAvailable = 1
                        alertsTimeLine = alertGraphDetails[0].timeline
                    }
                    
                    let alerts = []
                    let alertSeverity = []

                    let yaxisMaxValue = 0
                    let occurance = []
                    item.values.slice(startIndex, endIndex).forEach((val, j) => {
                        let arrayValues = []
                        arrayValues.push(momentConvertionUtcToLocalTime(graphDates[startIndex + j], 'YYYY-MM-DD HH:mm:ss'))
                        let value = val 
                        if(yaxisMaxValue < val) {
                            if(item.unit === 'Percent') {
                                yaxisMaxValue = Math.ceil(value)                                
                            } else {
                                yaxisMaxValue = Math.ceil(this.getGrapUnitValueWithoutUnit(value, item.unit ? item.unit : ''))
                            }
                        }
                        if(item.unit === 'Percent') {
                            value = Math.ceil(value)                                
                        } else {
                            value = this.getGrapUnitValue(value, item.unit ? item.unit : '')
                        }
                        arrayValues.push(value)
                        occurance.push(arrayValues)
                        
                        let alertValues = []
                        alertValues.push(momentConvertionUtcToLocalTime(graphDates[startIndex + j], 'YYYY-MM-DD HH:mm:ss'))
                        let totalCount = ''
                        let serverityRow = {}

                        let checkAlertExist = alertsTimeLine.filter(arr => arr.event_time === graphDates[startIndex + j])
                        if(checkAlertExist.length) {
                            let count = 0
                            if(checkAlertExist[0].severity_distribution && checkAlertExist[0].severity_distribution.length) {
                                checkAlertExist[0].severity_distribution.forEach(item => {
                                    count += item.count 
                                    serverityRow[item.severity] = item.count
                                })
                            } else { 
                                count = ''
                            }
                            totalCount = count
                        }
                        alertValues.push(totalCount)
                        alerts.push(alertValues)
                        alertSeverity.push(serverityRow)

                        if(item.anomaly && item.anomaly[j] > 0) {
                            let pointRow = {}
                            pointRow.x = momentConvertionUtcToLocalTime(graphDates[startIndex + j], 'YYYY-MM-DD HH:mm:ss')
                            pointRow.y = val

                            pointRow.marker = {
                                size: 3,
                                fillColor: "red",
                                strokeColor: "red",
                                radius: 2
                            }
                            pointRow.label = {
                                borderColor: "#FF4560",
                                offsetY: 0,
                                style: {
                                    color: "#fff",
                                    background: "#FF4560"
                                }
                                // text: "Point Annotation (XY)"
                            }

                            points.push(pointRow)
                        }
                    })

                    let yaxisRow = {}
                    yaxisRow = {
                        show: true,
                        axisTicks: {
                            show: false,
                        },
                        axisBorder: {
                            show: false,
                            color: '#008FFB'
                        },
                        tooltip: {
                            enabled: false
                        },
                        // tickAmount: 2,
                        min: 0,
                        // max: yaxisMaxValue, 
                        tickAmount: 1,
                        labels: {
                            minWidth: 50,
                            show: true,
                            style: {
                                fontSize: '9px',
                                colors: '#EDEDED'
                            },
                            // formatter: (value) => { 
                            //     if(Number(value) === value && value % 1 === 0) {
                            //         return Math.ceil(value)
                            //     } else {
                            //         return Math.ceil(value)
                            //     }
                            // },
                            // offsetX: 0,
                            // offsetY: 0,
                        }
                    }
                    yDetails.push(yaxisRow)

                    dataRow = {}
                    dataRow.key = key
                    dataRow.name = key
                    dataRow.type = 'line'
                    dataRow.data = occurance
                    // dataRow.dates = graphDates
                    dataRow.hoverContent = graphDetails
                    dataRow.orderBy = 1
                    series.push(dataRow)

                    if(item.yhat_upper) {
                        yaxisRow = {
                            show: false
                        }
                        yDetails.push(yaxisRow)
                        yhatAvailable = 1
                        item.yhat_upper.slice(startIndex, endIndex).forEach((val, j) => {
                            let arrayValues = []
                            arrayValues.push(momentConvertionUtcToLocalTime(graphDates[startIndex + j], 'YYYY-MM-DD HH:mm:ss'))
                            let value = val 
                            if(item.unit === 'Percent') {
                                value = Math.ceil(value)                                
                            } else {
                                value = this.getGrapUnitValue(value, item.unit ? item.unit : '')
                            }
                            arrayValues.push(value)
                            higherArray.push(arrayValues)
                        })
                        
                        dataRow = {}
                        dataRow.key = key
                        dataRow.name = 'High'
                        dataRow.type = 'line'
                        dataRow.data = higherArray
                        // dataRow.dates = graphDates
                        dataRow.hoverContent = graphDetails
                        dataRow.orderBy = 2
                        series.push(dataRow)                    
                    }

                    if(item.yhat_lower) {
                        yaxisRow = {
                            show: false
                        }
                        yDetails.push(yaxisRow)
                        let lowerArray = []
                        item.yhat_lower.slice(startIndex, endIndex).forEach((val, j) => {
                            let arrayValues = []
                            arrayValues.push(momentConvertionUtcToLocalTime(graphDates[startIndex + j], 'YYYY-MM-DD HH:mm:ss'))
                            // arrayValues.push(val === 0 ? -0.5 : val)
                            // val = parseInt((val / 100) * 50)
                            val = val < 0 ? 0 : val
                            let value = val 
                            if(item.unit === 'Percent') {
                                value = Math.ceil(value)                                
                            } else {
                                value = this.getGrapUnitValue(value, item.unit ? item.unit : '')
                            }
                            arrayValues.push(value)
                            lowerArray.push(arrayValues)
                        })
                        
                        dataRow = {}
                        dataRow.key = key
                        dataRow.name = 'Lower'
                        dataRow.type = 'line'
                        dataRow.data = lowerArray
                        // dataRow.dates = graphDates
                        dataRow.hoverContent = graphDetails
                        dataRow.orderBy = 3
                        series.push(dataRow)
                    }

                    if(alerts.length) {
                        yaxisRow = {
                            show: false
                        }
                        yDetails.push(yaxisRow)
                        dataRow = {}
                        dataRow.key = key
                        dataRow.name = 'Alerts'
                        dataRow.type = 'bar'
                        dataRow.data = alerts
                        // dataRow.dates = graphDates
                        // dataRow.hoverContent = graphDetails
                        dataRow.severity = alertSeverity
                        dataRow.orderBy = 4
                        series.push(dataRow)
                    }
                }

                series = _.orderBy(series, ['orderBy'], ['asc'])
                
                // let width = [2]
                // let curve = ['straight']
                let colors = ['#693EBC']
                // let type = ['none']
                // let opacity = [0]
                if(yhatAvailable === 1) {
                    // width = [2, 1, 1]
                    // curve = ['straight', 'smooth', 'smooth']
                    colors = ['#693EBC', '#9B9898', '#9B9898']
                    // type = [ 'none','solid', 'solid']
                    // opacity = [0, 0.85, 1, 1]
                }

                if(alertAvailable && yhatAvailable) {
                    colors = ['#693EBC', '#9B9898', '#9B9898', '#007bff']
                } else if(alertAvailable) {
                    colors = ['#693EBC', '#007bff']
                }

                let animations = {}
                animations = {
                    enabled: false
                }

                let xaxisAnnotation = []
                    if(this.state.showAnnotation) {
                        xaxisAnnotation = [{
                            x: new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                            strokeDashArray: 0,
                            borderColor: '#FF6F00',
                            label: {
                                borderColor: '#FF6F00',
                                style: {
                                    // fontSize: '12px',
                                    color: '#fff',
                                    background: '#FF6F00'
                                },
                                orientation: 'horizontal',
                                text: '',
                            }
                        },
                        // {
                        //     x: new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.last_event, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                        //     strokeDashArray: 0,
                        //     borderColor: '#FF6F00',
                        //     label: {
                        //         borderColor: '#FF6F00',
                        //         style: {
                        //             // fontSize: '12px',
                        //             color: '#fff',
                        //             background: '#FF6F00'
                        //         },
                        //         orientation: 'horizontal',
                        //         text: 'CE',
                        //     }
                        // }
                        ]
                    }

                    let annotations = {
                        points: points,
                        xaxis: xaxisAnnotation
                    }

                let mixedOptions = {
                    chart: {
                        id: key,
                        group: 'mixed',
                        height: 350,
                        type: 'line',
                        stacked: true,
                        
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false
                        },
                        events: {
                            mouseMove: (event, chartContext, config) => {
                                hoverOnDetailGraph = config.globals.chartID
                                let dataPointIndex = (config.dataPointIndex)
                                if(dataPointIndex !== -1) {
                                    dataPointIndex = (config.dataPointIndex) + startIndex
                                }
                                let seriesIndex = config.seriesIndex
                                let hoverContent = config.globals.initialSeries[0].hoverContent
                                // let data = []
                                // initialSeries.forEach(item => {
                                //     let dataRow = {}
                                //     dataRow.name = item.name
                                //     dataRow.date = item.data[dataPointIndex][0]
                                //     dataRow.value = item.data[dataPointIndex][1]
                                //     data.push(dataRow)
                                // })
                                this.hoverSectionFunction(dataPointIndex, seriesIndex, hoverContent)
                            }
                        },
                        animations: animations
                    },                    
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            barHeight: '100%',
                            // borderRadius: 8,
                            startingShape: 'flat',
                            endingShape: 'flat',
                            columnWidth: '60%',
                            distributed: false,
                            rangeBarOverlap: false,
                        }
                    },
                    stroke: {
                        show: true,
                        curve: 'smooth',
                        width: 3,
                        dashArray: 0, 
                    },
                    colors: colors,
                    legend: {
                        show: false,
                    },
                    xaxis: {
                        type: 'datetime',
                        labels: {
                            datetimeUTC: false,
                            style: {
                                colors: '#EDEDED'
                            }
                        },
                        axisBorder: {
                            show: true,
                            color:'#434959',
                            widht: 2,
                            // offsetX: -15,
                            // offsetY: 0
                        },
                        axisTicks: {
                            show: false,
                            color: '#EDEDED',
                        },
                        //     datetimeFormatter: {
                        //         year: 'yyyy',
                        //         month: "MMM 'yy",
                        //         day: 'dd MMM',
                        //         hour: 'HH:mm',
                        //     },
                        // },
                        tooltip: {
                            enabled: false,
                        },
                        // min: 4,
                    },                    
                    yaxis: yDetails,
                    // yaxis: {
                    //     tickAmount: 2,
                    //     min: 0,
                    //     labels: {
                    //         show: true,
                    //         style: {
                    //             colors: '#EDEDED'
                    //         },
                    //         formatter: (value) => { 
                    //             if(Number(value) === value && value % 1 === 0) {
                    //                 return Math.ceil(value)
                    //             } else {
                    //                 return Math.ceil(value)
                    //             }
                    //         },
                    //     }
                    // },
                    grid: {
                        show: true,
                        borderColor: '#2D323E',
                        strokeDashArray: 0,
                        position: 'back',
                        xaxis: {
                            lines: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: ['#FFF'],
                                }
                            },
                            axisTicks: {
                                show: false
                            }
                        },   
                        yaxis: {
                            lines: {
                                show: false
                            },
                            labels: {
                                style: {
                                    colors: ['#969AA7'],
                                }
                            }
                        },                
                        padding: {
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0
                        },
                    },
                    tooltip: {
                        shared: true,
                        intersect: false,
                        followCursor: false,
                        y: {
                            show: false,
                        },
                        x: {
                            show: false
                        },
                        marker: {
                            show: false
                        },
                        // custom: function({series, seriesIndex, dataPointIndex, w}) {
                        //     let returnData = ''
                        //     if(w.globals.initialSeries[seriesIndex].key === hoverOnDetailGraph) {
                        //         if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                        //             Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                        //                 if(!index) {
                        //                     returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(value.data[dataPointIndex][0], 'DD MMM YYYY HH:mm')+'</div>'
                        //                 }
                        //                 returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+value.name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+value.data[dataPointIndex][1]+'</span></div></div></div>'

                        //                 if(index === (Object.entries(w.globals.initialSeries).length -1)) {
                        //                     returnData += '</div>'
                        //                 }

                        //             })
                        //         }
                        //     }
                        //     return returnData
                        // },
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                            // let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]
                            // let labelName = w.globals.initialSeries[seriesIndex].name
                            // // return '<div className="arrow_box"> '+ capitalizeFirstLetter(labelName) +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' + '  '+ val + '</span> </div>'         
                            let returnData = ''
                            if(w.globals.initialSeries[seriesIndex] && w.globals.initialSeries[seriesIndex].key === hoverOnDetailGraph) {
                                if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                    Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                        if(!index) {
                                            returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                        }
                                        if(w.globals.initialSeries[index].data[dataPointIndex][1] !== '') {
                                            if(w.globals.initialSeries[index].name === 'Alerts') {
                                                returnData += '<div class="apexcharts-tooltip-title f12">Alerts</div><div class="d-flex p-2">'
                                                if(w.globals.initialSeries[index].severity[dataPointIndex] && Object.entries(w.globals.initialSeries[index].severity[dataPointIndex]).length) {
                                                    // if(w.globals.initialSeries[index].severity[dataPointIndex].Critical) {
                                                    let Critical = w.globals.initialSeries[index].severity[dataPointIndex].Critical ? w.globals.initialSeries[index].severity[dataPointIndex].Critical : 0
                                                    let High = w.globals.initialSeries[index].severity[dataPointIndex].High ? w.globals.initialSeries[index].severity[dataPointIndex].High : 0
                                                    let Medium = w.globals.initialSeries[index].severity[dataPointIndex].Medium ? w.globals.initialSeries[index].severity[dataPointIndex].Medium : 0
                                                    let Low = w.globals.initialSeries[index].severity[dataPointIndex].Low ? w.globals.initialSeries[index].severity[dataPointIndex].Low : 0

                                                    returnData += '<div class="d-flex mr-2"><div class="f10 badge-square-sm risk-badge-critical">C</div><p class="m-0 f11 text-white"><span class="mx-1">:</span>'+Critical+'</p></div>'

                                                    returnData += '<div class="d-flex mr-2"><div class="f10 badge-square-sm risk-badge-high">H</div><p class="m-0 f11 text-white"><span class="mx-1">:</span>'+High+'</p></div>'
                                                    
                                                    returnData += '<div class="d-flex mr-2"><div class="f10 badge-square-sm risk-badge-medium">M</div><p class="m-0 f11 text-white"><span class="mx-1">:</span>'+Medium+'</p></div>'
                                                    
                                                    returnData += '<div class="d-flex mr-2"><div class="f10 badge-square-sm risk-badge-low">L</div><p class="m-0 f11 text-white"><span class="mx-1">:</span>'+Low+'</p></div>'

                                                    returnData += '</div>'
                                                }
                                                // C</div><p class="m-0 f11 text-white"><span class="mx-1">:</span>10</p></div><div class="d-flex mr-2"><div class="f10 badge-square-sm risk-badge-high">H</div><p class="m-0 f11 text-white"><span class="mx-1">:</span> 4</p></div><div class="d-flex mr-2"><div class="f10 badge-square-sm risk-badge-medium">M</div><p class="m-0 f11 text-white"><span class="mx-1">:</span>10</p></div><div class="d-flex"><div class="f10 badge-square-sm risk-badge-low">L</div><p class="m-0 f11 text-white"><span class="mx-1">:</span>6</p></div></div>'
                                            } else {
                                                returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label" style="color:#039BE5">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2" style="color:#FFD200">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'
                                            }
                                        }

                                    })
                                    returnData += '</div>'
                                }
                            }

                            return returnData
                        }
                    },
                    annotations: annotations
                }

                this.setState({ [key+'_detail_'+graphDetails.asset_id]: [] },
                    () => {
                        this.setState({ [key+'_detail_'+graphDetails.asset_id]: series, [key+'_mixedOptions_'+graphDetails.asset_id]: mixedOptions, showLoading: false },
                            () => {
                                this.hoverSectionFunction(startIndex, 0, hoverContent)
                            }
                        )
                    }
                )
            })
        }
    }

    hoverSectionFunction = (dataPointIndex, seriesIndex, hoverContent) => {
        if(dataPointIndex > -1 && seriesIndex > -1 && (hoverContent && Object.entries(hoverContent).length)) { 
            Object.entries(hoverContent.data).forEach(([key, item], index) => {
                // if(index === 0) {
                    // let data = []
                    let dataRow = {}
                    dataRow.account_id = hoverContent.account_id
                    dataRow.asset_id = hoverContent.asset_id
                    dataRow.asset_name = hoverContent.asset_name
                    dataRow.provider = hoverContent.provider
                    dataRow.region = hoverContent.region
                    dataRow.service_name = hoverContent.service_name
                    dataRow.date = hoverContent.dates[dataPointIndex]
                    dataRow.metrics = key
                    dataRow.anomaly = item.anomaly ? item.anomaly[dataPointIndex] : ''
                    
                    dataRow.value = this.getGrapUnitValue(item.values[dataPointIndex], item.unit ? item.unit : '')
                    dataRow.lower = this.getGrapUnitValue(item.yhat_lower ? item.yhat_lower[dataPointIndex] : '', item.unit ? item.unit : '')
                    dataRow.upper = this.getGrapUnitValue(item.yhat_upper ? item.yhat_upper[dataPointIndex] : '', item.unit ? item.unit : '')
                    // data.push(dataRow)

                    this.setState({ [key+'_serviceDetails_'+dataRow.asset_id+'_'+index]: dataRow })
                // }
            })
        }
    }

    getGrapUnitValue = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = Math.ceil(val)
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytes(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit)
            } else if(unit === 'Count') {
                // val = val > 1 ? parseInt(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    getGrapUnitValueWithoutUnit = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytesWithoutUnit(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSecondsWithoutUnit(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBitsWithoutUnit(val, unit)
            } else if(unit === 'Count') {
                // val = val > 1 ? parseInt(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val ? Math.ceil(val) : 0
                val = countFormaterWithoutUnit(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }
  
    handleStartTimeChange(date) {
        let sliderStartDate = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
        let sliderEndDate = this.state.sliderEndDate
        if(new Date(sliderStartDate).getTime() > new Date(sliderEndDate).getTime() ) {
            sliderEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')
        }
        this.setState({ sliderStartDate, sliderEndDate },
            () => this.metricsDetailGraph()
        )
    }

    handleEndTimeChange(date) {
        let sliderStartDate = this.state.sliderStartDate
        let sliderEndDate = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
        if(new Date(sliderEndDate).getTime() > new Date(sliderStartDate).getTime() ) {
            this.setState({ sliderEndDate },
                () => this.metricsDetailGraph()
            )
        } 
    }
    
    getAttribute = () => {

        var attributes = document.querySelector('.metricsDetailBrush .apexcharts-selection-rect') && document.querySelector('.metricsDetailBrush .apexcharts-selection-rect').attributes
        if(attributes) {
            let width = attributes.width.value

            let x1 = parseInt(attributes.x.value) + parseInt(20)
            let x2 = parseInt(attributes.x.value) + parseInt(width) + parseInt(10)
            var d1 = document.getElementById('leftSliderSpan');
            d1.style.position = "absolute";
            d1.style.left = x1+'px';
            d1.style.top = '135px';
            d1.style.color = '#039BE5';
            
            var d2 = document.getElementById('rightSliderSpan');
            d2.style.position = "absolute";
            d2.style.left = x2+'px';
            d2.style.top = '135px';
            d2.style.color = '#039BE5';

            let caseTime = twoDateTimeInterval(this.state.sliderStartDate, this.state.sliderEndDate)
            let sliderBelowStartDate = ''
            let sliderBelowEndDate = ''
            if(width > 60) {
                if(caseTime[0] > 0) {
                    sliderBelowStartDate = momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM')
                    sliderBelowEndDate = momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM')
                } else {
                    sliderBelowStartDate = momentDateGivenFormat(this.state.sliderStartDate, 'HH:mm:ss')
                    sliderBelowEndDate = momentDateGivenFormat(this.state.sliderEndDate, 'HH:mm:ss')
                }
            }
            this.setState({ sliderBelowStartDate, sliderBelowEndDate })
        }
    }

    merticGraphOptions = () => {
        this.setState({ showAnnotation: !this.state.showAnnotation }, 
            () => this.metricsDetailGraph()   
        )
        // if(option === 'Alert') {
        //     this.getAiopsDailyTrendByAssets(metrics, assetId)
        // } else if(option === 'Case') {
        // }
    } 
    
    getAiopsDailyTrendByAssets = (metrics, assetId) => {
        let caseStartTime = this.props.caseDetails.created_at
        let caseEndTime = this.props.caseDetails.last_event
        if(caseEndTime < caseStartTime) {
            caseEndTime = addMinutes(caseStartTime, 30)
        }
        
        let params = {}
        params.alerts_case_id = this.props.caseDetails.case_id
        params.start_time = caseStartTime
        params.end_time = caseEndTime
        
        this.props.getAiopsDailyTrendByAssets(params, (promise, result) => {
            let alertEventTrend = result && result.results ? result.results : {}
            if(promise) {
                this.setState({ alertEventTrend },
                    () => this.newEventChart(metrics, assetId)
                )
            }
        })
    }  
    
    newEventChart = (metrics, assetId) => {
        let graphDetails = this.state.alertEventTrend
        if(graphDetails) {
            let graphLabels = graphDetails.labels
            let graphData = graphDetails.data
            Object.entries(graphData).length && Object.entries(graphData).forEach(([key, value], index) => {
                let eventSeries = []
                let data = []
                let graphDataRow = {}
                value.events && value.events.length && value.events.forEach((item, index) => {
                    let arrayValuesRow = []
                    arrayValuesRow.push(graphLabels[index])
                    arrayValuesRow.push(item)
                    data.push(arrayValuesRow)
                })
                graphDataRow.data = data
                graphDataRow.arrayDates = graphLabels
                eventSeries.push(graphDataRow)
                
                let eventOptions = {
                    chart: {
                        id: 'chart2',
                        //group: 'barBrushChart',
                        type: 'line',
                        height: 230,
                        sparkline: {
                            enabled: true
                        },
                        redrawOnParentResize: true
                    },
                    colors: ['#039BE5'],
                    stroke: {
                        show: true,
                        curve: 'straight',
                        lineCap: 'butt',
                        colors: undefined,
                        width: 2,
                        dashArray: 0,      
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    markers: {
                        size: 0
                    },
                    xaxis: {
                        type: 'datetime',
                        labels: {
                            datetimeUTC: false
                        },
                    },
                    tooltip: {
                        fixed: {
                            enabled: false
                        },
                        x: {
                            show: false
                        },
                        y: {
                            title: {
                            formatter: (seriesName) => seriesName,
                            }
                        },
                        marker: {
                            show: false
                        },
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                            let val = w.globals.initialSeries[0].data[dataPointIndex][1]
                            let dateLabel = momentDateGivenFormat(w.globals.initialSeries[0].data[dataPointIndex][0], 'MMM DD HH:mm')
                            // let labelName = w.globals.initialSeries[0].event_id
                            let fontColor = '#693EBC'

                            return '<div class="arrow_box">'+ dateLabel +'<span style="color:'+ fontColor +'"> '+ val +'</span></div>'
                        }
                    }
                }

                let alertSeries = []
                data = []
                graphDataRow = {}
                value.alerts && value.alerts.length && value.alerts.forEach((item, index) => {
                    let arrayValuesRow = []
                    arrayValuesRow.push(graphLabels[index])
                    arrayValuesRow.push(item)
                    data.push(arrayValuesRow)
                })
                graphDataRow.data = data
                graphDataRow.arrayDates = graphLabels
                alertSeries.push(graphDataRow)

                let columnWidth = '25%'
                if(data.length <= 10) {
                    columnWidth = '2%'
                } else if(data.length <= 20) {
                    columnWidth = '4%'
                } else if(data.length <= 30) {
                    columnWidth = '6%'
                } else if(data.length <= 40) {
                    columnWidth = '8%'
                } else if(data.length <= 50) {
                    columnWidth = '10%'
                } else if(data.length <= 60) {
                    columnWidth = '12%'
                } else if(data.length <= 70) {
                    columnWidth = '14%'
                } else if(data.length <= 80) {
                    columnWidth = '16%'
                } else if(data.length <= 90) {
                    columnWidth = '18%'
                } else if(data.length <= 100) {
                    columnWidth = '20%'
                }

                let alertOptions = {
                    chart: {
                        id: 'chart2',
                        //group: 'barBrushChart',
                        type: 'bar',
                        height: 230,
                        sparkline: {
                            enabled: true
                        },
                        redrawOnParentResize: true
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            barHeight: '100%',
                            columnWidth: columnWidth,
                            distributed: false
                        }
                    },
                    colors: ['#19B2C0'],
                    stroke: {
                        show: true,
                        curve: 'straight',
                        lineCap: 'butt',
                        colors: undefined,
                        width: 0,
                        dashArray: 0,      
                    },
                    dataLabels: {
                        enabled: false
                    },
                    markers: {
                        size: 0
                    },
                    xaxis: {
                        type: 'datetime',
                        labels: {
                            datetimeUTC: false
                        },
                    },
                    tooltip: {
                        fixed: {
                            enabled: false
                        },
                        x: {
                            show: false
                        },
                        y: {
                            title: {
                            formatter: (seriesName) => seriesName,
                            }
                        },
                        marker: {
                            show: false
                        },
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                            let val = w.globals.initialSeries[0].data[dataPointIndex][1]
                            // let dateLabel = momentConvertionUtcToLocalTime(w.globals.initialSeries[0].data[dataPointIndex][0], 'MMM DD HH:mm')
                            let dateLabel = momentConvertionUtcToLocalTime(w.globals.initialSeries[0].arrayDates[dataPointIndex], 'MMM DD HH:mm')
                            // let labelName = w.globals.initialSeries[0].event_id
                            let fontColor = '#693EBC'

                            return '<div class="arrow_box">'+ dateLabel +'<span style="color:'+ fontColor +'"> '+ val +'</span></div>'
                        }
                    }
                }

                this.setState({ 
                    [metrics+'_alertSeries_'+assetId]: alertSeries, 
                    [metrics+'_alertOptions_'+assetId]: alertOptions,
                    [metrics+'_eventSeries_'+assetId]: eventSeries, 
                    [metrics+'_eventOptions_'+assetId]: eventOptions, 
                })
            })
        } else {
            
        }
    }
    
	render() {
		return (
            !this.state.showLoading ? 
                <div className=''>
                    <div class="row detail">
                        <div class={`detail-title mb-0 p-2`}>
                            <div className="aiopsTopFiltersection">
                                <div className={`row`}>
                                    <div className={`col-sm-12 d-flex justify-content-end mb-1`}>
                                        <div className={`d-flex`}>
                                            <span className={`mr-2 f12 align-self-center `}>Last</span>
                                            <span className={`mr-2 align-self-center f12 ${this.state.selectedFilterType === 'D' && this.state.selectedFilterPeriod === 3 ? 'badge badge-info disabled' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'D', selectedFilterPeriod: 3, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>3D</span>
                                            <span className={`mr-2 align-self-center f12 ${this.state.selectedFilterType === 'D' && this.state.selectedFilterPeriod === 1 ? 'badge badge-info disabled' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'D', selectedFilterPeriod: 1, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>1D</span>
                                            <span className={`mr-2 align-self-center f12 ${this.state.selectedFilterType === 'H' && this.state.selectedFilterPeriod === 12 ? 'badge badge-info disabled' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'H', selectedFilterPeriod: 12, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>12H</span>
                                            <span className={`mr-2 align-self-center f12 ${this.state.selectedFilterType === 'H' && this.state.selectedFilterPeriod === 6 ? 'badge badge-info disabled' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'H', selectedFilterPeriod: 6, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>6H</span>
                                            <span className={`mr-2 align-self-center f12 ${this.state.selectedFilterType === 'H' && this.state.selectedFilterPeriod === 3 ? 'badge badge-info disabled' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'H', selectedFilterPeriod: 3, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>3H</span>
                                            <span className={`mr-2 align-self-center f12 ${this.state.selectedFilterType === 'H' && this.state.selectedFilterPeriod === 1 ? 'badge badge-info disabled' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'H', selectedFilterPeriod: 1, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>1H</span>

                                            <div className="d-flex displayNone">
                                                <Datetime 
                                                    value={momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM YYYY hh:mm A')}
                                                    onChange={this.handleStartTimeChange}
                                                    dateFormat={'DD MMM YYYY'}
                                                    timeFormat={'hh:mm A'}
                                                    inputProps={{ readOnly: true }}
                                                    className="w-100 mr-2 aiopsMetricsDatepicker"
                                                    //maxDate={new Date().getTime()}
                                                    isValidDate={disableFutureDt}
                                                />
                                                <Datetime
                                                    value={momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM YYYY hh:mm A')}
                                                    onChange={this.handleEndTimeChange}
                                                    dateFormat={'DD MMM YYYY'}
                                                    timeFormat={'hh:mm A'}
                                                    inputProps={{ readOnly: true }}
                                                    className="w-100 aiopsMetricsDatepicker"
                                                    //minDate={new Date(this.state.sliderStartDate).getTime()}
                                                    //maxDate={new Date()}
                                                    isValidDate={disableFutureDt}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className={`bg-filterBlue text-white align-self-center p-1 mx-2 cursorPointer`} onClick={() => this.setState({ showFilerSection: !this.state.showFilerSection})}>
                                            {getGivenPastDaysNew(this.state.brushStartDate, this.state.brushEndDate, this.state.caseEndTime)}
                                        </div> */}
                                        <div>
                                        <div>
                                            <p className="f11 m-0 p-0">{momentDateGivenFormat(this.state.brushStartDate, 'DD MMM YYYY HH:mm:ss')}</p>
                                            <p className="f11 m-0 p-0">{momentDateGivenFormat(this.state.brushEndDate, 'DD MMM YYYY HH:mm:ss')}</p>
                                        </div>
                                    </div>
                                </div>
                                {this.state.brushSliderSeries && this.state.brushSliderSeries.length ?
                                    <React.Fragment>
                                    <div className={`col-md-12 pl-0 metricsDetailBrush mb-n2 ${this.state.disableDateSlider ? 'disabled' : ''}`} >
                                        <Chart options={this.state.brushSliderOptions} series={this.state.brushSliderSeries} type="bar" height={80} width={"100%"} />
                                    </div>
                                    {/* <div className="d-flex">
                                        <div id="leftSliderSpan" className="f12">{this.state.sliderBelowStartDate}</div>
                                        <div id="rightSliderSpan" className="f12">{this.state.sliderBelowEndDate}</div>
                                    </div> */}
                                    </React.Fragment>
                                : null}
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col-lg-12`}> 
                            <div className={``}>
                                <ul className={`nav nav-tabs border-bottom bd-highlight bg-muted shadow-none justify-content-between ${this.state.showLoading ? 'disabled' : ''}`} id="myTab" role="tablist">
                                    <span className="d-flex">
                                        <li className="nav-item">
                                            <span className={`nav-link text-white cursorPointer ${this.state.activeSideTopTab === 'InstanceMetrics' ? ' active' : ''}`} onClick={() => this.setState({ activeSideTopTab: 'InstanceMetrics' }, () => this.setSREProps())}>Instance Metrics</span>
                                        </li>
                                        {this.props.eventPageDetails && this.props.eventPageDetails.resourceType && this.props.eventPageDetails.resourceType !== 'EC2' ?
                                            <li className="nav-item">
                                                <span className={`nav-link text-white cursorPointer ${this.state.activeSideTopTab === 'SREMetrics' ? ' active' : ''}`} onClick={() => this.setState({ activeSideTopTab: 'SREMetrics' }, () => this.setSREProps() )}>SRE Metrics</span>
                                            </li>
                                        : null}
                                        <li className="nav-item displayNone">
                                            <span className={`nav-link text-white cursorPointer ${this.state.activeSideTopTab === 'ProcessMetrics' ? ' active' : ''}`} onClick={() => this.setState({ activeSideTopTab: 'ProcessMetrics' })}>Process Metrics</span>
                                        </li>
                                    </span>
                                    <li>
                                        <button className='align-self-center btn btn-sm btn-dark mt-2' onClick={() => this.props.showMetricsDetails(false)}>Back</button>
                                    </li>
                                </ul>
                                {/* <button className='align-self-center btn btn-light mr-1' onClick={() => this.props.showMetricsDetails(false) }>Back</button> */}
                                {/* <button className='align-self-center btn btn-dark mt-n1' onClick={() => this.props.showMetricsDetails(false) }>Back</button> */}
                            </div>
                            {this.state.activeSideTopTab === 'InstanceMetrics' ?
                                <div className={`rounded bg-dark p-3 mt-3`}>
                                    <div className={`row m-0 `}>
                                        <div className="col-lg-12 px-2 mb-3">
                                            {this.state.filterdMetricsByTrend && this.state.filterdMetricsByTrend.length ?
                                                this.state.filterdMetricsByTrend.map(item  => {
                                                    return (
                                                        <React.Fragment>
                                                        <div className="d-flex justify-content-between">
                                                            <p className={`row text-white mb-0`}>Asset Name: {item.asset_name}</p>
                                                            <div className="d-flex">
                                                                <div className="d-flex mr-2 align-self-center">
                                                                    <p className="indicatoreBox bg-orange mb-0 mr-2"></p>
                                                                    <p className="f12 mb-0">Case Time</p>
                                                                </div>
                                                                <div className="d-flex mr-2 align-self-center">
                                                                    <p className="indicatoreBox bg-info mb-0 mr-2"></p>
                                                                    <p className="f12 mb-0">Alert</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {Object.entries(item.data).map(([key, value], i) => {
                                                            return (
                                                                // <div className="row bg-dark-50 rounded p-1 mt-2 d-flex">
                                                                <div className="row mt-2 d-flex">
                                                                    <div className={`col-lg-9 p-0 mb-n3`}>
                                                                        {this.state[key+'_alertOptions_'+item.asset_id] && this.state[key+'_alertSeries_'+item.asset_id] && this.state[key+'_alertSeries_'+item.asset_id].length ?
                                                                            <div className="ml-4 transparentTooltip">
                                                                                <Chart options={this.state[key+'_alertOptions_'+item.asset_id]} series={this.state[key+'_alertSeries_'+item.asset_id]} type="bar" height={20} />
                                                                            </div>
                                                                        : null}
                                                                        {this.state[key+'_mixedOptions_'+item.asset_id] && Object.keys(this.state[key+'_mixedOptions_'+item.asset_id]).length && this.state[key+'_detail_'+item.asset_id] && this.state[key+'_detail_'+item.asset_id].length ?
                                                                            <div className="ml-n2 transparentTooltip">
                                                                                <Chart options={this.state[key+'_mixedOptions_'+item.asset_id]} series={this.state[key+'_detail_'+item.asset_id]} type="line" height={120} />
                                                                            </div>
                                                                        : 
                                                                            <div style={{minHeight:"64px"}}>
                                                                                <div className='d-flex justify-content-center align-self-center pt-4'>
                                                                                    No Data
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    {this.state[key+'_detail_'+item.asset_id] && this.state[key+'_detail_'+item.asset_id].length && this.state[key+'_serviceDetails_'+item.asset_id+'_'+i] ?
                                                                        <div className="col-lg-3 pr-0">
                                                                            <div className="d-flex justify-content-between">
                                                                                <div class="text-info m-0 ml-1 p-1 f12 w-95 mb-1 hiddenEllipses" id={'title_'+i}>
                                                                                    {key+ (value.unit ? ('('+ value.unit +')') : '')}
                                                                                </div>
                                                                                <UncontrolledTooltip placement='top' className="text-gray3" target={'title_'+i}>{key+ (value.unit ? ('('+ value.unit +')') : '')}</UncontrolledTooltip>
                                                                                {/* <div className="ml-2 btn-group dropDown" onClick={() => this.setState({ ['metricDetailOptions_'+i]: !this.state['metricDetailOptions_'+i] })}>
                                                                                    <i className="far fa-cog f15 align-self-center cursorPointer text-gray3"></i>
                                                                                    <div className={`dropdown-menu dropdown-menu-right bg-gray2 cursorPointer ${this.state['metricDetailOptions_'+i] ? 'show active' : ''}`}>
                                                                                        <button className="dropdown-item" type="button" onClick={() => this.merticGraphOptions(key, item.asset_id, 'Alert')}>Alert</button>
                                                                                        <button className="dropdown-item" type="button" onClick={() => this.merticGraphOptions(key, item.asset_id, 'Case')}>Case</button>
                                                                                    </div>
                                                                                </div> */}
                                                                            </div>
                                                                            <div className="mask bg-dark-50">
                                                                                <p className="mb-0 font-weight-bold f16 text-warning align-self-center pt-1">{this.state[key+'_serviceDetails_'+item.asset_id+'_'+i].value}</p>
                                                                            </div>
                                                                            <div className="p-1 bg-muted text-white">
                                                                                <p className="d-flex mb-0 f12 pl-2 pt-1" style={{height:"30px"}}>{momentConvertionUtcToLocalTime(this.state[key+'_serviceDetails_'+item.asset_id+'_'+i].date, 'DD MMM YYYY HH:mm')}</p>
                                                                                                                                                        
                                                                                <div className="d-flex border-bottom">
                                                                                    <div className="d-flex w-50 pt-1 pr-2">
                                                                                        <p className="b-block mb-0 f10">Anomaly:</p>
                                                                                        <p className="mb-0 flex-grow-1 text-right f10"><b>{this.state[key+'_serviceDetails_'+item.asset_id+'_'+i].anomaly}</b></p>
                                                                                    </div>
                                                                                    {/* <div className="d-flex w-50 pl-2">
                                                                                        <p className="b-block mb-0 pb-1 f10">Severity:</p>
                                                                                        <p className="mb-0 flex-grow-1 pb-1 text-right f10"><b>No</b></p>
                                                                                    </div> */}
                                                                                    <div className="d-flex w-50 pt-1 pl-2">
                                                                                        <p className="b-block mb-0 f10">Score:</p>
                                                                                        <p className="mb-0 flex-grow-1 pb-1 text-right f10"><b>No</b></p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex">
                                                                                    <div className="d-flex w-50 pt-1 pr-2">
                                                                                        <p className="b-block mb-0 f10">Upper:</p>
                                                                                        <p className="mb-0 flex-grow-1 text-right f10"><b>{this.state[key+'_serviceDetails_'+item.asset_id+'_'+i].upper}</b></p>
                                                                                    </div>
                                                                                    <div className="d-flex w-50 pt-1 pl-2"> {/*  border-right-dark */}
                                                                                        <p className="b-block mb-0 f10">Lower:</p>
                                                                                        <p className="mb-0 flex-grow-1 text-right f10"><b>{this.state[key+'_serviceDetails_'+item.asset_id+'_'+i].lower}</b></p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    : 
                                                                        <div className="col-lg-3 pr-0 align-self-center">
                                                                            <div className="text-info m-0 ml-1 f14 w-80 hiddenEllipses" id={'title_'+i}>
                                                                                {key+ (value.unit ? ('('+ value.unit +')') : '')}
                                                                            </div>
                                                                            <UncontrolledTooltip placement='top' className="text-gray3" target={'title_'+i}>{key+ (value.unit ? ('('+ value.unit +')') : '')}</UncontrolledTooltip>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )
                                                        })}
                                                        </React.Fragment>
                                                    )
                                                })
                                            : null}
                                        </div>
                                    </div>
                                </div>
                            : this.state.activeSideTopTab === 'SREMetrics' ?
                                <div className={`rounded bg-muted mt-3`}>
                                    <div className="mb-3">
                                        <EventSreDetails 
                                            // alertDetails={this.props.eventPageDetails}
                                            brush={true}
                                            sparkline={false}
                                            yaxis={true}
                                            yaxisLabel={true}
                                            xaxis={true}
                                            xaxisFormat={''}
                                            xaxisLabel={true}
                                            grid={false}
                                            axisLabelColor={'#B8BBBE'}
                                            legend={false}
                                            stacked={false}
                                            height={200}
                                            horizontal={true}
                                            barHeight={'40%'}
                                            barEndShape={'rounded'}
                                            columnWidth={'50%'}
                                            gradient={true}
                                            gradientColor={['#039BE5', '#5F5BA2']}
                                            hideTooltipValue={true}
                                            backgroundBarShape={'rounded'}
                                            backgroundBarColors={['#333947']}
                                            showBackgroundBarColors={false}
                                            className={"transparentTooltip"}
                                            annotations={true}
                                        />
                                    </div>
                                </div>
                            : null}
                        </div>
                    </div>
                </div>
            :
                <div className='d-flex justify-content-center m-4'>
                    <Spinner className='text-center' color='white' size='lg' />
                </div>
        )
	}
}
/**
 * Type of the props used in the component
 */
EventMetricDetails.propTypes = {
    getMonitoringMetricsByTrend: PropTypes.func,
    getAlertTrendByAsset: PropTypes.func,
    getAiopsDailyTrendByAssets: PropTypes.func,
    showMetricsDetails: PropTypes.bool,
    setAiopsPropsDetails: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
	// console.log('detail state', state)
	return {
        aiopsPropsDetails: state.aiops.aiopsPropsDetails ? state.aiops.aiopsPropsDetails : {},
        eventPageDetails: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.event_page_details ? state.aiops.aiopsPropsDetails.event_page_details : {},
        eventSliderChanges: state.aiops.eventSliderChanges && state.aiops.eventSliderChanges.alert_slider_changes ? state.aiops.eventSliderChanges.alert_slider_changes : {},
        caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : {},
        assetsList: state.aiops.caseDetails.results && state.aiops.caseDetails.results.assets ? state.aiops.caseDetails.results.assets : [],

        

        eventMonitoringDetails: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.eventMonitoringDetails ? state.aiops.aiopsPropsDetails.eventMonitoringDetails : {},
        eventMonitoringEvent: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.eventMonitoringEvent ? state.aiops.aiopsPropsDetails.eventMonitoringEvent : {},
    }
}

export default connect(mapStateToProps, {
    getMonitoringMetricsByTrend,
    getAlertTrendByAsset,
    getAiopsDailyTrendByAssets,
    setAiopsPropsDetails,
})(withRouter(EventMetricDetails))