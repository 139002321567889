/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file DlpPolicy.js
 * @author Prakash // on 11/10/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { AppWrapper } from '../../common/AppWrapper'

import PrivacyPolicy from './PrivacyPolicy/Policy'
import PrivacyPolicyAddEdit from './PrivacyPolicy/AddEdit'

import SecurityPolicy from './SecurityPolicy/Policy'
import SecurityPolicyAddEdit from './SecurityPolicy/AddEdit'

class DlpPolicy extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: false,
        }
    }
    
    componentDidMount = () => {
		this.policyTypeFunction('privacy_policy')
	}

	policyTypeFunction = (page) => {
		this.setState({ policyType: page, ['show_'+page+'_list']: true })
	}

	render() {
		return (
			<div className="container-fluid overflow-auto flex-grow-1">
				<div className='row h-100'>
					<div className='col-sm-12 p-0'>
						<div className="title-section p-3">
							<h6 className="text-white">Policies</h6>
							<p className="text-white">Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet
								enim.</p>
						</div>
						<div className="box m-3 p-3">
							<div className="row">
								<div className="col-sm-6">
									<h6>Policies</h6>
									<p>Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim.</p>
								</div>
								<div className="col-sm-6 text-right">
								</div>
							</div>
							<div className="row">
								<div className="col-3">
									<div className="nav flex-column nav-pills">
										<span onClick={() => this.policyTypeFunction('privacy_policy')} className={`nav-link cursorPointer ${this.state.policyType === 'privacy_policy' ? 'active': ''}`}>Privacy Policy</span>
										<span onClick={() => this.policyTypeFunction('security_policy')} className={`nav-link cursorPointer ${this.state.policyType === 'security_policy' ? 'active': ''}`}>Security Policy</span>
									</div>
								</div>
								<div className="col-9 border-left">
									<div className="tab-content vh-100">
										{this.state.policyType === 'privacy_policy' ?
											<div className={`tab-pane fade show active`}>
												{this.state.show_privacy_policy_list ?
													<PrivacyPolicy 
														onClickAction={(privacyPolicyDetails, sideViewType) => this.setState({ privacyPolicyDetails, sideViewType, show_privacy_policy_list: false })}
													/>
												: 
													<PrivacyPolicyAddEdit
														pageType={this.state.sideViewType}
														policyDetails={this.state.privacyPolicyDetails ? this.state.privacyPolicyDetails : {}}
														showListPage={() => this.setState({ show_privacy_policy_list: true })}
													/>
												}
											</div>
										: this.state.policyType === 'security_policy' ?
											<div className={`tab-pane fade show active`}>
												{this.state.show_security_policy_list ?
													<SecurityPolicy 
														onClickAction={(securityPolicyDetails, sideViewType) => this.setState({ securityPolicyDetails, sideViewType, show_security_policy_list: false })}
													/>
												: 
													<SecurityPolicyAddEdit
														pageType={this.state.sideViewType}
														policyDetails={this.state.securityPolicyDetails ? this.state.securityPolicyDetails : {}}
														showListPage={() => this.setState({ show_security_policy_list: true })}
													/>
												}
											</div>
										: null}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
DlpPolicy.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
 const mapStateToProps = state => {
    return {}
}

export default AppWrapper(DlpPolicy, mapStateToProps, {})