/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'
import { getAllTags } from '../../../actions/governance/governanceAction'
import { showNotification } from '../../../actions/commonAction'
import { setCreatePolicyDetails, listEvents, listIamConditions } from '../../../actions/governance/IamAction'
import { Spinner, UncontrolledTooltip } from 'reactstrap'
import CategoryCard from './CategoryCard'
import { ERROR } from '../../../utils/constants'
import _ from 'lodash'

class Policy extends Component {
	constructor(props) {
		super(props)
		this.state = {
			principalTagChecked: false,
			roleConditions: [],
			disabledRoleConditions: [],
			globalConditions: [],
			disabledGlobalConditions: [],
		}
	}

	componentDidMount = () => {
		this.props.getAllTags({ account_id: this.props.createPolicyDetails.account_id })
		let paths = this.props.location.pathname.split('/')
		if (paths.includes('edit')) {
			this.onSelectService(this.props.createPolicyDetails.statements_to_generate[this.props.index].service_name)
			if (
				this.props.createPolicyDetails.statements_to_generate[this.props.index].condition_to_check.ResourceTags &&
				this.props.createPolicyDetails.statements_to_generate[this.props.index].condition_to_check.ResourceTags.length >
					0 &&
				typeof this.props.createPolicyDetails.statements_to_generate[this.props.index].condition_to_check
					.ResourceTags[0] === 'string'
			) {
				this.setState({ principalTagChecked: true })
			}
		}
	}

	onSelectTag = (key, arr) => {
		if (key === 'TagKeys') {
			let storeArr = this.props.createPolicyDetails.statements_to_generate
			let obj = storeArr[this.props.index]['condition_to_check']
			obj[key] = arr.map(item => item.value)
			this.props.setCreatePolicyDetails('statements_to_generate', storeArr)
		} else {
			let storeArr = this.props.createPolicyDetails.statements_to_generate
			let obj = storeArr[this.props.index]['condition_to_check']
			let temp = []
			if (key === 'ResourceTags' && this.state.principalTagChecked) {
				if (this.props.createPolicyDetails.statements_to_generate[this.props.index].service_name !== '') {
					this.setState({ showServiceLoading: true })
					this.props.listIamConditions(
						{
							account_id: this.props.createPolicyDetails.account_id,
							service_name: this.props.createPolicyDetails.statements_to_generate[this.props.index].service_name,
							provider: this.props.createPolicyDetails.provider,
							tags: arr.map(item => item.value),
						},
						success => {
							if (!success) {
								this.props.showNotification(ERROR, 'Unable to get iam conditions')
							}
							this.setState({ showServiceLoading: false })
							this.diffEnabledAndDisabledConditions()
						}
					)
				}
				obj[key] = arr.map(item => item.value)
			} else {
				arr.forEach(item => temp.push({ key: item.label.split(':')[0], value: item.label.split(':')[1] }))
				obj[key] = temp
				if (
					this.props.createPolicyDetails.statements_to_generate[this.props.index].service_name !== '' &&
					key === 'ResourceTags'
				) {
					this.setState({ showServiceLoading: true })
					this.props.listIamConditions(
						{
							account_id: this.props.createPolicyDetails.account_id,
							service_name: this.props.createPolicyDetails.statements_to_generate[this.props.index].service_name,
							provider: this.props.createPolicyDetails.provider,
							tags: arr.map(item => item.value),
						},
						success => {
							if (!success) {
								this.props.showNotification(ERROR, 'Unable to get iam conditions')
							}
							this.diffEnabledAndDisabledConditions()
							this.setState({ showServiceLoading: false })
						}
					)
				}
			}
			this.props.setCreatePolicyDetails('statements_to_generate', storeArr)
		}
	}

	getOptionsForTagsToUse = () => {
		let tag_list = []
		if (this.props.tags) {
			let list = this.props.tags
			list.forEach(item => {
				if (!tag_list.includes(item.tags.key)) {
					tag_list.push(item.tags.key)
				}
			})
		}
		return tag_list
	}

	getOptionForResourceTags = () => {
		let tag_list = []
		if (this.props.tags) {
			let list = this.props.tags
			if (this.state.principalTagChecked) {
				list.forEach(item => {
					if (!tag_list.includes(item.tags.key)) {
						tag_list.push(item.tags.key)
					}
				})
			} else {
				list.forEach(item => {
					let tagItem = {}
					tagItem['label'] = item.tags.key + ':' + item.tags.value
					tagItem['value'] = item.tags.key + ' : ' + item.tags.value
					tag_list.push(tagItem)
				})
			}
		}
		return tag_list
	}

	diffEnabledAndDisabledConditions = () => {
		let specific_conditions_enabled = []
		let specific_conditions_disabled = []
		let global_conditions_enabled = []
		let global_conditions_disabled = []
		if (this.props.conditions && this.props.conditions.service_conditions && this.props.conditions.global_conditions) {
			this.props.conditions.roleConditions &&
				this.props.conditions.roleConditions.forEach(condition => {
					if (_.find(this.props.conditions.service_conditions, { condition_key: condition.condition_key })) {
						specific_conditions_enabled.push(condition)
					} else {
						specific_conditions_disabled.push(condition)
					}
				})

			this.props.conditions.globalConditions &&
				this.props.conditions.globalConditions.forEach(condition => {
					if (_.find(this.props.conditions.global_conditions, { condition_key: condition.condition_key })) {
						global_conditions_enabled.push(condition)
					} else {
						global_conditions_disabled.push(condition)
					}
				})
		}
		this.setState({
			roleConditions: specific_conditions_enabled,
			globalConditions: global_conditions_enabled,
			disabledRoleConditions: specific_conditions_disabled,
			disabledGlobalConditions: global_conditions_disabled,
		})
	}

	onSelectService = value => {
		this.setState({ showServiceLoading: true })
		this.props.listEvents(
			{
				provider: this.props.createPolicyDetails.provider,
				service: value,
				event_category: true,
				event_condition: true,
			},
			success => {
				if (success) {
					let storeArr = this.props.createPolicyDetails.statements_to_generate
					let obj = storeArr[this.props.index]
					let temp = []
					if (this.props.conditions.categories) {
						Object.entries(this.props.conditions.categories).map(([service_category]) =>
							temp.push({ category: service_category, usecases: [] })
						)
					}
					obj.categories = temp
					this.props.setCreatePolicyDetails('statements_to_generate', storeArr)
					this.diffEnabledAndDisabledConditions()
				} else {
					this.props.showNotification(ERROR, 'Unable to get service categories')
				}
			}
		)
		this.setState({ showServiceLoading: true })
		this.props.listIamConditions(
			{
				account_id: this.props.createPolicyDetails.account_id,
				provider: this.props.createPolicyDetails.provider,
				service_name: value,
				tags: this.props.createPolicyDetails.statements_to_generate[this.props.index].condition_to_check.ResourceTags,
			},
			success => {
				this.diffEnabledAndDisabledConditions()
				if (!success) {
					this.props.showNotification(ERROR, 'Unable to get iam conditions')
				}
			}
		)
		let storeArr = this.props.createPolicyDetails.statements_to_generate
		let obj = storeArr[this.props.index]
		obj.service_name = value
		this.props.setCreatePolicyDetails('statements_to_generate', storeArr)
		this.setState({ showServiceLoading: false })
	}

	handleCategoryChange = event => {
		let storeArr = this.props.createPolicyDetails.statements_to_generate
		let obj = storeArr[this.props.index]
		let index = event.target.name
		let tmp = obj.categories
		let new_category = {
			category: event.target.value,
			usecases: [],
		}
		tmp.splice(index, 1, new_category)
		this.props.setCreatePolicyDetails('statements_to_generate', storeArr)
	}

	onClickDeletePolicy = () => {
		let storeArr = this.props.createPolicyDetails.statements_to_generate
		storeArr.splice(this.props.index, 1)
		this.props.setCreatePolicyDetails('statements_to_generate', storeArr)
	}

	updateRoleCondition = (event, key) => {
		let condition_index = event.target.name.split('@')[0]
		let role_condition = JSON.parse(event.target.name.split('@')[1])
		let storeArr = this.props.createPolicyDetails.statements_to_generate
		let tmp = storeArr[this.props.index].condition_to_check
		if (event.target.checked) {
			tmp[key].push(role_condition)
		} else {
			tmp[key].splice(condition_index)
		}
		this.props.setCreatePolicyDetails('statements_to_generate', storeArr)
	}

	isConditionEnabled = key => {
		let storeArr = this.props.createPolicyDetails.statements_to_generate
		let tmp = storeArr[this.props.index]
		let condition_index = _.findIndex(tmp.condition_to_check.specific, { condition_key: key })
		if (condition_index >= 0) {
			return true
		} else {
			return false
		}
	}

	isGlobalConditionEnabled = key => {
		let storeArr = this.props.createPolicyDetails.statements_to_generate
		let tmp = storeArr[this.props.index]
		let condition_index = _.findIndex(tmp.condition_to_check.global, { condition_key: key })
		if (condition_index >= 0) {
			return true
		} else {
			return false
		}
	}

	render() {
		return (
			<div className="accordion mt-3" id="accordionExample">
				<div className={`card bg-dark px-3 border-0 overflow-unset`}>
					<div className='card-header p-0 border-0 bg-transparent d-flex justify-content-between' id='headingOne'>
						<h2 className='mb-0'>
							<button
								className='btn btn-outlink-primary pl-0 text-white'
								type='button'
								onClick={() => this.props.onOpen(this.props.index)}
							>
								{this.props.policy.isOpen === true ? (
									<i className='fa fa-caret-down mr-3' />
								) : (
									<i className='fa fa-caret-right mr-3' />
								)}
								# {this.props.createPolicyDetails.statements_to_generate[this.props.index].service_name}
							</button>
						</h2>
						<div className="align-self-center">
							<i className='fa fa-trash text-danger cursor-pointer' onClick={() => this.onClickDeletePolicy()} />
						</div>
					</div>
					<div className={`collapse ${this.props.policy.isOpen === true ? 'show' : ''}`}>
						<div className='card-body pl-0 zapInputDark'>
							<div className='row'>
								<div className='col-4'>
									<div className='form-group darkThemeSelectMulti'>
										<label data-toggle='tooltip' data-placement='right' title='Tooltip on right'>
											Tags allowed to use
										</label>
										<div className='small text-muted'>
											Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim proident et.
										</div>
										<Select
											onChange={arr => this.onSelectTag('TagKeys', arr ? arr : [])}
											options={this.getOptionsForTagsToUse().map(key => ({
												value: key,
												label: key,
											}))}
											value={
												this.props.createPolicyDetails.statements_to_generate[this.props.index] &&
												this.props.createPolicyDetails.statements_to_generate[this.props.index].condition_to_check
													.TagKeys &&
												this.props.createPolicyDetails.statements_to_generate[
													this.props.index
												].condition_to_check.TagKeys.map(tag => ({
													value: tag.key || tag,
													label: tag.key || tag,
												}))
											}
											isMulti
											placeholder='Select Tags'
											// className='basic-multi-select text-dark'
											id='basic-multi-select'
										/>
									</div>
								</div>
								<div className='col-4'>
									<div className='form-group darkThemeSelectMulti'>
										<label>Tags allowed for new resources</label>
										<div className='small text-muted'>
											Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim proident et.
										</div>
										<Select
											onChange={arr => this.onSelectTag('RequestTags', arr ? arr : [])}
											options={this.props.tags.map(({ tags }) => ({
												key: tags.key,
												value: tags.value,
												label: tags.key + ':' + tags.value,
												style: { color: 'black' },
											}))}
											value={
												this.props.createPolicyDetails.statements_to_generate[this.props.index] &&
												this.props.createPolicyDetails.statements_to_generate[this.props.index].condition_to_check
													.RequestTags &&
												this.props.createPolicyDetails.statements_to_generate[
													this.props.index
												].condition_to_check.RequestTags.map(tag => ({
													key: tag.key || tag,
													value: tag.value || tag,
													label: tag.key + ' : ' + tag.value || tag,
												}))
											}
											isMulti
											classNamePrefix='Select Tags'
											className='basic-multi-select text-dark'
											id='basic-multi-select1'
										/>
									</div>
								</div>
								<div className='col-4'>
									<div className='form-group darkThemeSelectMulti'>
										<label data-toggle='tooltip' data-placement='right' title='Tooltip on right'>
											Resource Tags
										</label>
										<div className='small text-muted'>
											Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim proident et.
										</div>
										<Select
											onChange={arr => this.onSelectTag('ResourceTags', arr ? arr : [])}
											options={this.getOptionForResourceTags().map(item => ({
												value: item.value || item,
												label: item.label || item,
											}))}
											value={
												this.props.createPolicyDetails.statements_to_generate &&
												this.props.createPolicyDetails.statements_to_generate[this.props.index].condition_to_check &&
												this.props.createPolicyDetails.statements_to_generate[this.props.index].condition_to_check
													.ResourceTags &&
												this.props.createPolicyDetails.statements_to_generate[
													this.props.index
												].condition_to_check.ResourceTags.map(tag => ({
													key: tag.key || tag,
													value: tag.value || tag,
													label: tag.key && tag.value ? tag.key + ' : ' + tag.value : tag,
												}))
											}
											isMulti
											classNamePrefix='Select Tags'
											className='basic-multi-select text-dark'
											id='basic-multi-select2'
										/>
										<div className='form-check form-check-inline mt-2'>
											<input
												className='form-check-input'
												type='checkbox'
												value={this.state.principalTagChecked}
												onChange={e => this.setState({ principalTagChecked: e.target.checked })}
												defaultValue='option1'
											/>
											<label className='form-check-label'>Resource Tag should match Principal Tag</label>
										</div>
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col-sm-12'>
									<h4 className="text-white">Categories</h4>
									<p className='text-muted small'>
										Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim proident et. Officia amet
										eiusmod eu sunt tempor voluptate laboris velit nisi amet enim proident et.
									</p>
								</div>
								<div className='col-sm-12'>
									<div className='form-group'>
										<Select
											placeholder={"All"}
											// menuIsOpen={true}
											//onFocus={this.openProivderMenu}
											// className="f13 p-0"
											value={({
												value: this.props.createPolicyDetails.statements_to_generate[this.props.index].service_name,
												label: this.props.createPolicyDetails.statements_to_generate[this.props.index].service_name ? this.props.createPolicyDetails.statements_to_generate[this.props.index].service_name : <span className="placeholder">Select a service</span>
											})}
											options={this.props.services && this.props.services.map(item => ({
												value: item.service_name,
												label: item.service_name,	
											}))}
											disabled={this.state.isEditMode}
											onChange={e => {
												this.onSelectService(e.value)
											}}
										/>
									</div>
									<div className='d-flex row justify-content-start'>
										{this.props.conditions.categories &&
											!this.state.showServiceLoading &&
											Object.entries(this.props.conditions.categories).map((category, i) => (
												<CategoryCard
													categoryDetail={category}
													key={category}
													index={i}
													policyIndex={this.props.index}
													deleteCategory={index => {
														let tempp = this.props.conditions.categories
														delete tempp[Object.keys(tempp)[index]]
														this.setState({ categories: tempp })
													}}
												/>
											))}
									</div>
									<div className='d-flex justify-content-center'>
										{this.state.showServiceLoading ? <Spinner size='md' color='dark' /> : null}
									</div>
								</div>
							</div>
							{this.props.createPolicyDetails.statements_to_generate[this.props.index].service_name !== '' && (
								<>
									<div className='row mt-3'>
										<div className='col-sm-12'>
											<h4 className="text-white">Conditions</h4>
											<p className='text-muted small'>
												Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim proident et. Officia
												amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim proident et.
											</p>
										</div>
									</div>
									<div className='row'>
										<div className='col-sm-6'>
											<p className='mb-0'>Service specfic Conditions</p>
											<p className='small text-muted'>
												Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim proident et.
											</p>
											{this.state.roleConditions &&
												this.state.roleConditions.map((condition, condition_index) => {
													return (
														<div className='form-check form-check-inline w-100' key={condition_index}>
															<input
																className='form-check-input'
																type='checkbox'
																name={condition_index + '@' + JSON.stringify(condition)}
																onChange={e => this.updateRoleCondition(e, 'specific')}
																checked={this.isConditionEnabled(condition.condition_key) === true ? true : false}
															/>
															<label className='form-check-label' htmlFor='forCheckbox'>
																{condition.name || condition.condition_key}
															</label>
														</div>
													)
												})}
											{/* {this.props.createPolicyDetails.statements_to_generate[this.props.index] &&
												this.props.createPolicyDetails.statements_to_generate[this.props.index].condition_to_check
													.specific &&
												this.props.createPolicyDetails.statements_to_generate[
													this.props.index
												].condition_to_check.specific.map((condition, condition_index) => {
													return (
														<div className='form-check form-check-inline w-100' key={condition_index}>
															<input
																className='form-check-input'
																type='checkbox'
																checked={condition.name}
																name={condition_index + '@' + JSON.stringify(condition)}
																onChange={e => this.updateRoleCondition(e, 'specific')}
															/>
															<label className='form-check-label' htmlFor='forCheckbox'>
																{condition.name || condition.condition_key}
															</label>
														</div>
													)
												})} */}
											<p className='text-blue'>Not available - (Governance rules not configured)</p>
											<div id='serviceNotAvailable'>
												<UncontrolledTooltip placement='right' target='serviceNotAvailable'>
													Service Conditions not available - (Governance rules not configured)
												</UncontrolledTooltip>
												{this.state.disabledRoleConditions &&
													this.state.disabledRoleConditions.map((condition, condition_index) => {
														return (
															<div className='form-check form-check-inline w-100' key={condition_index}>
																<input className='form-check-input' type='checkbox' disabled />
																<label className='form-check-label'>{condition.name || condition.key}</label>
															</div>
														)
													})}
											</div>
										</div>
										<div className='col-sm-6'>
											<p className='mb-0'>Global Conditions</p>
											<p className='small text-muted'>
												Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim proident et.
											</p>
											{this.state.globalConditions &&
												this.state.globalConditions.map((condition, condition_index) => {
													return (
														<div className='form-check form-check-inline w-100' key={condition_index}>
															<input
																checked={this.isGlobalConditionEnabled(condition.condition_key) === true ? true : false}
																className='form-check-input'
																type='checkbox'
																name={condition_index + '@' + JSON.stringify(condition)}
																onChange={e => this.updateRoleCondition(e, 'global')}
															/>
															<label className='form-check-label'>{condition.name || condition.condition_key}</label>
														</div>
													)
												})}
											{/* {this.props.createPolicyDetails.statements_to_generate[this.props.index] &&
												this.props.createPolicyDetails.statements_to_generate[this.props.index].condition_to_check
													.specific &&
												this.props.createPolicyDetails.statements_to_generate[
													this.props.index
												].condition_to_check.global.map((condition, condition_index) => {
													return (
														<div className='form-check form-check-inline w-100' key={condition_index}>
															<input
																className='form-check-input'
																type='checkbox'
																name={condition_index + '@' + JSON.stringify(condition)}
																onChange={e => this.updateRoleCondition(e, 'global')}
															/>
															<label className='form-check-label' htmlFor='forCheckbox'>
																{condition.name || condition.condition_key}
															</label>
														</div>
													)
												})} */}
											<p className='text-blue'>Not available - (Governance rules not configured)</p>
											<div id='governanceNotAvailable'>
												<UncontrolledTooltip placement='right' target='governanceNotAvailable'>
													Global Conditions not available - (Governance rules not configured)
												</UncontrolledTooltip>
												{this.state.disabledGlobalConditions &&
													this.state.disabledGlobalConditions.map((condition, condition_index) => {
														return (
															<div className='form-check form-check-inline w-100' key={condition_index}>
																<input className='form-check-input' type='checkbox' disabled />
																<label className='form-check-label' htmlFor='forCheckbox'>
																	{condition.name || condition.key}
																</label>
															</div>
														)
													})}
											</div>
										</div>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
Policy.propTypes = {
	index: PropTypes.number,
	onOpen: PropTypes.func,
	getAllTags: PropTypes.func,
	setCreatePolicyDetails: PropTypes.func,
	listEvents: PropTypes.func,
	showNotification: PropTypes.func,
	policy: PropTypes.object,
	location: PropTypes.object,
	createPolicyDetails: PropTypes.object,
	conditions: PropTypes.object,
	listIamConditions: PropTypes.func,
	tags: PropTypes.array,
	services: PropTypes.array,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		createPolicyDetails: state.iam.createPolicyDetails,
		conditions: state.iam.conditions,
		tags: state.governance.tags,
		services: state.filters.services,
	}
}

export default connect(mapStateToProps, {
	getAllTags,
	listEvents,
	listIamConditions,
	setCreatePolicyDetails,
	showNotification,
})(withRouter(Policy))
