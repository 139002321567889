/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 28/04/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 **************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Spinner, UncontrolledTooltip } from 'reactstrap'
import _ from 'lodash'

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

import { AppWrapper } from '../../common/AppWrapper'
import { listAllProviders, listAllAccounts, listAllRegions, getAllTagsKeys, getAllTagsValues } from '../../../actions/commonAction'
import { costComparison } from '../../../actions/cost/CostAction'

import { getAccountNameFromId, getRegionName, getDayFromSelectedDuration, momentDateGivenFormat, subDays, subHours, capitalizeFirstLetter, onlyNumeric } from '../../../utils/utility'

import { RangePicker } from "react-minimal-datetime-range";
import "react-minimal-datetime-range/lib/react-minimal-datetime-range.min.css";

const entityResponse = {
	filters: [{
		"account_id": "account 1",
		"region": "region 1",
	},{
		"account_id": "account 2"
	},{
		"account_id": "account 3",
		"region": "region 3",
	},{
		"account_id": "account 4"
	},{
		"account_id": "account 5"
	}],
	results: {
		"EC2": [{
			"cost": [1.999999999, 2],
			"usage": [3, 4],
			"data": {
				"ope 1": {
					"cost": ["a", "b"],
					"usage": ["c", "d"]
				},
				"ope 2": {
					"cost": [5.7777777777777777777, 6],
					"usage": [7, 8]
				}
			}
		}],
		"KMS": [{
			"cost": [11, 12],
			"usage": [13, 14],
			"data": {
				"ope 1": {
					"cost": ["e", "f"],
					"usage": ["g", "h"]
				},
				"ope 2": {
					"cost": [15, 16],
					"usage": [17, 18]
				}
			}
		}]
	}
}

class LandingPage extends Component {
	dateRef = React.createRef()
	tagsRef = React.createRef()	
	sliderWrap = React.createRef()
	sliderRef = React.createRef()
	getBlockWidthRef = React.createRef()

	sliderRef1 = React.createRef()
	getBlockWidthRef1 = React.createRef()
	
	constructor(props) {
		super(props)		
		this.tabRef = React.createRef()
		this.searchRef = React.createRef()

		this.state = {
			selectedSource: "aws",
			showLoading: true,
			minMaxHeight: "770px",
			durationActive: true,
			selectedPeriod: "1",
			costSource: ["aws", "kubernetes"],
			metrics: [
				{label: "All", value: "All"},
				{label: "Cost", value: "cost"},
				{label: "Usage", value: "usage"}
			],
			groupBy: [
				{label: "Service", value: "Service"},
				{label: "Service Api", value: "Service Api"}
			],
			selectedGroupBy: "Service",
			periodOptions: [
				{label: "+7d", value: "Last 1 Week"},
				{label: "+14d", value: "Last 2 Week"},
				{label: "+30d", value: "Last 1 Month"},
				{label: "+14d", value: "Last 2 Week"},
				{label: "+14d", value: "Last 2 Week"},
			],

			// compareResponse: entityResponse,
			selectedMetric: ["cost", "usage"],
			itemMovedLeft: 0,
			itemMovedRight: 0
		}
	}

	componentDidMount = () => {
		
		this.getWindowSize()

		window.addEventListener('resize', () => {
			this.getWindowSize()
		})
		this.getFilters()
		
		// this.formCompareStructure()
		// this.formShowOptions()
	}

	formShowOptions = () => {
		let totalAccArray = this.state.compareResponse && this.state.compareResponse.filters ? this.state.compareResponse.filters : (this.state.compareResponse && this.state.compareResponse.dates ? this.state.compareResponse.dates : [])
		this.setState({ totalAccArray, totalItemShowing: 4 })
	}

	slideRight = () => {
		// let totalWidth = this.getBlockWidthRef.current.childNodes[0].getBoundingClientRect().width
		let width = this.getBlockWidthRef.current.childNodes[0] && this.getBlockWidthRef.current.childNodes[0].getBoundingClientRect().width

		// let width1 = this.getBlockWidthRef1.current.childNodes[0] && this.getBlockWidthRef1.current.childNodes[0].getBoundingClientRect().width
		if((this.state.totalItemShowing + this.state.itemMovedRight) <= this.state.totalAccArray.length) {
			this.sliderRef.current.scrollLeft += width
			if(this.state.servicesToShow && this.state.servicesToShow.length) {
				this.sliderRef1.current.scrollLeft += width
			}

			this.setState({ itemMovedRight: this.state.itemMovedRight + 1 })

		}
	}

	slideLeft = () => {
		if(this.state.itemMovedRight >= 1) {
			let width = this.getBlockWidthRef.current.childNodes[0] && this.getBlockWidthRef.current.childNodes[0].getBoundingClientRect().width
			this.sliderRef.current.scrollLeft -= width
			if(this.state.servicesToShow && this.state.servicesToShow.length) {
				this.sliderRef1.current.scrollLeft += width
			}

			this.setState({ itemMovedRight: this.state.itemMovedRight - 1 })
		}
	}

	getWindowSize = () =>  {
		const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 180
		let tableHeader = 90
		let filterSize = this.state.showSlectedFilters ? 240 : 0
		let outerHeight = (window.outerHeight - browserBorder - headerHeight - tableHeader - filterSize ) + "px"

		this.setState({ outerHeight, minMaxHeight: outerHeight })
	}

	getFilters = () => {
		this.getAllTagsKeys()
		// this.durationFunction()	

		if(this.props.providers && this.props.providers.length) {
			this.setState({ providers: this.props.providers })
		} else {
			this.props.listAllProviders(promise => {
				if (promise) {
					this.setState({ providers: this.props.providers })
				}
			})
		}

		let params = {}
		if(this.props.accounts && this.props.accounts.length) {
			this.setState({ accounts: this.props.accounts })
		} else {
			this.props.listAllAccounts(params, (promise, response) => {
				if (promise) {
					this.setState({ accounts: response })
				}
			})
		}

		params.provider = "aws"
		if(this.props.regions && this.props.regions.length) {
			this.setState({ accounts: this.props.regions })
		} else {
			this.props.listAllRegions(params, (promise, response) => {
				if (promise) {
					this.setState({ regions: response && response[0] && response[0].provider_regions ? response[0].provider_regions : [] })
				} else {
					this.setState({ regions: [] })
				}
			})
		}
	}

	//on change filter items
    handleSelect = (date) => {
        let datePickerStartDate = momentDateGivenFormat(date[0], "YYYY-MM-DD 00:00:00")
		let datePickerEndDate = momentDateGivenFormat(date[1], "YYYY-MM-DD 23:59:59")
        this.setState({ datePickerStartDate, datePickerEndDate, showDateRangePicker: false, durationActive: false })
	}

	durationFunction = (type) => {
        let duration = this.state.selectedDuration
        let period = duration.substring(duration.length, duration.length-1)
        let durationBtw = getDayFromSelectedDuration(duration)
        let datePickerStartDate = momentDateGivenFormat(subHours(new Date(), durationBtw), 'YYYY-MM-DD 00:00:00')
        if(period === "d") {
            datePickerStartDate = momentDateGivenFormat(subDays(new Date(), durationBtw), 'YYYY-MM-DD 00:00:00')
        }
        let datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')

        this.setState({ datePickerStartDate, datePickerEndDate })
    }

	getAllTagsKeys = () => {		
		if(!this.props.tagKeys || !this.props.tagKeys.length) {
			let params = {}
			// params.account_id = "265469181489"
			// params.region = "us-east-2"
			this.props.getAllTagsKeys(params, (promise, response) => {
				this.setState({ showTagKeyLoading: !this.state.showTagKeyLoading })	
			})
		} else {
			this.setState({ showTagKeyLoading: !this.state.showTagKeyLoading })
		}
	}

	getAllTagsValues = () => {
		let params = {}
		params.tags = [{ "key": this.state.tag_key }]
		this.props.getAllTagsValues(params, (promise, response) => {
			if(promise) {
				this.setState({ tagValues: response },
					() => {
						if(this.state.tagsSelected && this.state.tagsSelected.filter(e => e.key === this.state.tag_key).length) {
							let value = this.state.tagsSelected.filter(e => e.key === this.state.tag_key).map(e => e.value)
							this.handleMultiSelectChange("tag_value", value, this.state.tagValues)
						}
					}	
				)
			}
		})
	}

	addNewTag = () => {
		let tagsSelected = this.state.tagsSelected ? this.state.tagsSelected : []

		if(this.state.tag_key && this.state.tag_key !== "") {
			let filteredTags = tagsSelected.filter(e => e.key !== this.state.tag_key)
			this.state.tag_value.forEach(row => {
				let dataRow = {
					key: this.state.tag_key,
					value: row,
				}
				filteredTags.push(dataRow)
			})

			this.setState({ tagsSelected: filteredTags, tag_key: "",  tag_value: "", tagValues: [], changesInFilter: true })
		}
	}

	removeTagSection = (tag) => {
		let filteredResult = this.state.tagsSelected.filter(e => e !== tag)
		this.setState({ tagsSelected: filteredResult })
	}

	addToCompare = () => {
		let commonFilters = {}		
		if(this.state.selectedSource) {
			commonFilters.source = this.state.selectedSource
		}
		if(this.state.selectedMetric && this.state.selectedMetric.length) {
			commonFilters.metric_name = this.state.selectedMetric.filter(e => e !== "All")
		}
		if(this.state.selectedGroupBy) {
			let aggregateBy = [this.state.selectedGroupBy]
			if(this.state.selectedSource === "aws") {
				aggregateBy = ["resource_type"]
				if(this.state.selectedGroupBy !== "Service") {
					aggregateBy = ["resource_type", "operation"]
				}			
			}			
			commonFilters.aggregate_by = aggregateBy
		}

		let selectedFilters = this.state.selectedFilters ? this.state.selectedFilters : []
		
		let dataRow = {}
		if(this.state.selectedAccount) {
			dataRow.account_id = this.state.selectedAccount
		}
		if(this.state.selectedRegion) {
			dataRow.region = this.state.selectedRegion
		}
		if(this.state.selectedTags && this.state.selectedTags.length) {
			dataRow.tags = this.state.selectedTags
		}
		selectedFilters.push(dataRow)
		commonFilters.selectedFilters = selectedFilters

		this.setState({ commonFilters, selectedFilters, showSlectedFilters: true },
			() => {
				this.resetFilters()	
				this.getWindowSize()
			}
		)
	}

	resetFilters = () => {
		this.setState({
			selectedAccount: "",
			selectedRegion: "",
			selectedTags: [],
			tagsSelected: [],
			tag_key: "",
			tag_value: "",
			changesInFilter: false		
		})
	}

	handleMultiSelectChange = (field, arrayValue, state) => {
		let value = arrayValue.map(item => item.value ? item.value : item)
		let selectedValue = []
		let prevState = this.state[field] ? this.state[field] : []
		if(value.includes("All")) {
			if(!prevState.includes("All")) {
				if(state.filter(e => e.value).length) {
					selectedValue = state.map(e => e.value)
				} else {
					selectedValue = state
				}
			} else {
				const index = value.indexOf("All");
				if (index > -1) {
					value.splice(index, 1);
				}
				selectedValue = value
			}
		} else if(!prevState.includes("All")) {
			selectedValue = value
		}

		if(selectedValue.length && !selectedValue.includes("All") && (state.includes("All") || state.filter(e => e.value === "All").length)) {
			if(selectedValue.length === state.length -1) {
				selectedValue.push("All")
			}
		}

		
		this.setState({ [field]: selectedValue },
			() => {
				if(field === "selectedMetric") {
					this.onChangeCommonFilter()
				}
			}	
		)
	}

	getMultiSelectedCount = (type, array) => {
		return array.length && array.includes("All") ? "All" :  array.length ? array.length +" Selected" : "All"
	}

	//on change filter items

	handleClickOutside(event) {

		if (this.dateRef && this.dateRef.current &&  !this.dateRef.current.contains(event.target)) {
			this.setState({ showDateRangePicker: false })
		}

		if(this.state.showTagFilter) {
			if (this.tagsRef && !this.tagsRef.current.contains(event.target)) {
				this.setState({ showTagFilter: false })
			}
		}
	}

	validateDay = (dayType) => {
		if(this.state.start_day && this.state.end_day) {
			if(this.state.start_day > this.state.end_day) {
				if(dayType === "start_day") {
					this.setState({ end_day: "" })
				} else {
					this.setState({ start_day: "" })
				}
			}
		}
	}

	validatePeriod = () => {
		let period = ""
		if(this.state.selectedPeriod) {
			if(this.state.selectedPeriod > 6) {
				period = 6
			} else {
				period = this.state.selectedPeriod
			}
		}

		this.setState({ selectedPeriod: period })
	}

	onChangeCommonFilter = (type) => {
		let commonFilters = this.state.commonFilters ? this.state.commonFilters : {}
		if(this.state.selectedSource) {
			commonFilters.source = this.state.selectedSource
		}
		console.log("commonFilters dddddddd", commonFilters)
		if(type === "source") {
			let metrics = [
				{label: "All", value: "All"},
				{label: "Cost", value: "cost"},
				{label: "Usage", value: "usage"}
			]
			let selectedGroupBy = ["Service"]
			let aggregateBy = ["resource_type"]
			if(selectedGroupBy !== "Service") {
				aggregateBy = ["resource_type", "operation"]
			}
			let groupBy = [
				{label: "Service", value: "Service"},
				{label: "Service Api", value: "Service Api"}
			]
			let selectedMetric = ["cost", "usage"]
			
			if(this.state.selectedSource !== "aws") {
				groupBy = [
					{label: "Namespace", value: "namespace"},
					{label: "Cluster Name", value: "cluster_name"}
				]
				selectedGroupBy = ["namespace"]
				aggregateBy = ["namespace"]
				
				metrics = [ 
					{label: "Cost", value: "cost"}
				]
				selectedMetric = ["cost"]
			}
			commonFilters.aggregate_by = aggregateBy
			commonFilters.metric_name = selectedMetric
			console.log("commonFilters", commonFilters)
			this.setState({ groupBy, selectedGroupBy, selectedMetric, commonFilters })
		} else {
			if(this.state.selectedMetric && this.state.selectedMetric.length) {
				commonFilters.metric_name = this.state.selectedMetric.filter(e => e !== "All")
			}
			if(this.state.selectedGroupBy) {
				let aggregateBy = [this.state.selectedGroupBy]
				if(this.state.selectedSource === "aws") {
					aggregateBy = ["resource_type"]
					if(this.state.selectedGroupBy !== "Service") {
						aggregateBy = ["resource_type", "operation"]
					}			
				}
				commonFilters.aggregate_by = aggregateBy
			}
		}		

		this.setState({ commonFilters })
	}

	onSearch = () => {
		let params = {}
		if(this.state.commonFilters.source) {
			params.source = this.state.commonFilters.source
		}
		if(this.state.commonFilters.metric_name) {
			params.metric_name = this.state.commonFilters.metric_name.filter(e => e !== "All")
		}
		if(this.state.commonFilters.aggregate_by) {
			params.aggregate_by = this.state.commonFilters.aggregate_by
		}
		if(this.state.start_day && this.state.end_day) {
			params.start_day = parseInt(this.state.start_day)
			params.end_day = parseInt(this.state.end_day)
		}
		if(this.state.selectedPeriod) {
			params.duration = this.state.selectedPeriod+"M"
		}

		params.filters = this.state.commonFilters.selectedFilters
		if(this.state.commonFilters.selectedFilters.length === 1) {
			params.comparison_type = "trend"
		}
		// this.state.commonFilters.selectedFilters.forEach(row => {
		// 	filters.push
		// 	Object.entries(row).forEach(([key, value]) => {
		// 		params[key] = value
		// 	})
		// })
		this.props.costComparison(params, (promise, response) => {
			if(promise) {
				this.setState({ compareResponse: response, compareLoading: false },
					() => {
						this.formCompareStructure()
						this.formShowOptions()
					}	
				)
			} else {
				this.setState({ compareLoading: false })
			}
		})
		
		this.setState({ paramsFilter: this.state.commonFilters.metric_name })
	}

	clearFilers = () => {
		this.setState({ commonFilters: {}, selectedFilters: [], showSlectedFilters: false },
			() => this.getWindowSize()	
		)
	}

	formCompareStructure = () => {
		let compareResponse = this.state.compareResponse

		let compareResponseResults = this.state.compareResponse && this.state.compareResponse.results ? this.state.compareResponse.results : {}

		let tableData = []
		let filters = []
		if(compareResponse.filters) {
			filters = compareResponse.filters
		} else if(compareResponse.dates) {
			filters = compareResponse.dates
		}

		

		let servicesToShow = []
		Object.entries(compareResponseResults).forEach(([cKey, cValue], index) => {
			if(!index && this.state.paramsFilter && this.state.paramsFilter.length === 1 && this.state.paramsFilter[0] === "usage") {
				this.setState({ ["show_"+index]: true })
			}
			console.log("cKey", cKey)
			let serRow = {}
			let tableRow = {}
			serRow.service = cKey
			tableRow.label = cKey
			console.log("cValue", cValue)
			let datas = {}
			// cValue.forEach(row => {
				if(cValue.data && Object.entries(cValue.data).length) {
					serRow.service_api = Object.keys(cValue.data)
				} 
				filters.forEach((fil, index) => {					
					tableRow.filter = fil
					let datasRow = {}					
					if(cValue.hasOwnProperty("cost")) {
						datasRow["cost"] = cValue["cost"][index]
					}
					if(cValue.hasOwnProperty("usage")) {
						datasRow["usage"] = cValue["usage"][index]
					}
					if(cValue.data && Object.entries(cValue.data).length) {
						serRow.service_api = Object.keys(cValue.data)
						let apis = []
						Object.entries(cValue.data).forEach(([opKey, opValue]) => {
							let childRow = {}
							childRow.label = opKey
							if(opValue.hasOwnProperty("cost")) {
								childRow["cost"] = opValue["cost"][index]
							}
							if(opValue.hasOwnProperty("usage")) {
								childRow["usage"] = opValue["usage"][index]
							}
							apis.push(childRow)
						})
						datasRow.apis = apis
					}
					datas["filter_"+index] = (datasRow)

					tableRow.datas = datas
				})
				console.log("serRow", serRow)
				servicesToShow.push(serRow)
			// })
			tableData.push(tableRow)
		})
		console.log("tableData", tableData)
		console.log("servicesToShow", servicesToShow)

		this.setState({ tableData, servicesToShow, filters: filters, compareResponseResults  })
	}

	removeFilter = (filters, index) => {
		let commonFilters = this.state.commonFilters
		let selectedFilters = commonFilters.selectedFilters.filter(e => e !== filters)
		console.log("selectedFilters ", selectedFilters )
		commonFilters.selectedFilters = selectedFilters

		if(selectedFilters.length < 1) {
			commonFilters = {}
		}
		this.setState({ commonFilters, selectedFilters })
	}

	/**
	 * Renders cost comparison Landing page
	*/
	render() {
		return (
			<div className='container-fluid overflow-auto flex-grow-1 bg-muted' onClick={ (event) => { this.handleClickOutside(event) } }>
				<div className={`loaderOverlay ${this.state.compareLoading ? "" : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
				<div className='row h-100'>
					<div className='col-sm-12 px-0'>
						<div className='title-section' style={{padding: "1rem 1rem 1rem 1rem"}} >
							<div className='row'>
								<div className='col-lg-3 d-flex align-self-center'>
									<div>
										<div className='d-flex'>
											<h6 className='text-white m-0'>Cost Comparison</h6>
										</div>
										<p className='text-white m-0'>
											New cost comparison on entities
										</p>
									</div>
								</div>
								<div className={`col-lg-9`}>
									<div className="containerFilterSection">
										<div className="d-flex justify-content-end mt-2">
											<div className="mr-4">
												<div className="d-flex justify-content-between">
													<label className="align-self-center m-0">Source </label>
												</div>
												<Select
													placeholder={'Select'}
													isSearchable={false}
													// menuIsOpen={true}
													className={`selectOptionsm f13 p-0`}
													components={{
														IndicatorSeparator: () => null
													}}
													value={({
														value: this.state.selectedSource && this.state.selectedSource !== "" ? this.state.selectedSource : 'Select',
														label: this.state.selectedSource && this.state.selectedSource !== "" ? capitalizeFirstLetter(this.state.selectedSource) : <span className="placeholder">Select</span>
													})}
													options={this.state.costSource && this.state.costSource.map(item => ({
														value: item,
														label: capitalizeFirstLetter(item),
													}))}
													onChange={event => this.setState({ selectedSource: event.value }, 
														() => {
															this.onChangeCommonFilter("source")
														}
													)}
												/>
											</div>

											<div className="mr-4">
												<div className="d-flex justify-content-between">
													<label className="align-self-center pb-5p">Metric </label>
													{this.state.hasError && (!this.state.selectedMetric || !this.state.selectedMetric.length) ? (
														<small className="text-issues">required</small>
													) : null}
												</div>
												<div className={`multiSelectOptionSm align-self-end`}>
													<ReactMultiSelectCheckboxes
														placeholderButtonLabel="All"
														getDropdownButtonLabel={() => this.getMultiSelectedCount("selectedMetric", this.state.selectedMetric ? this.state.selectedMetric : [])}
														options={this.state.metrics && this.state.metrics.map(row => ({
															value: row.value,
															label: row.label,
														}))}
														onChange={arr => { this.handleMultiSelectChange("selectedMetric", arr ? arr : [], this.state.metrics)}}
														value={this.state.selectedMetric && this.state.selectedMetric.map(row => ({
															value: row
														}))}
													/>
												</div>
											</div>
											
											<div className="mr-4">
												<div className="d-flex justify-content-between">
													<label className="align-self-center m-0">Group By </label>
													{this.state.hasError && (!this.state.selectedGroupBy || !this.state.selectedGroupBy.length) ? (
														<small className="text-issues">required</small>
													) : null}
												</div>
												<Select
													isSearchable={false}
													className={`selectOptionsm f13 p-0`}
													components={{
														IndicatorSeparator: () => null
													}}
													value={({
														value: this.state.selectedGroupBy && this.state.selectedGroupBy !== "" ? this.state.selectedGroupBy : "",	
														label: this.state.selectedGroupBy && this.state.selectedGroupBy !== "" ? (this.state.groupBy.filter(e => e.value === this.state.selectedGroupBy) && this.state.groupBy.filter(e => e.value === this.state.selectedGroupBy).length ? this.state.groupBy.filter(e => e.value === this.state.selectedGroupBy)[0].label : this.state.selectedGroupBy) : <span className="placeholder">Select</span>
													})}
													options={this.state.groupBy && this.state.groupBy.map(row => ({
														value: row.value,
														label: row.label,	
													}))}
													onChange={event => this.setState({ selectedGroupBy: event.value }, 
														() => this.onChangeCommonFilter()
													)}
												/>
											</div>

											<div className="mr-4">
												<div className="d-flex justify-content-between">
													<label className="align-self-center m-0">Account </label>
													{this.state.hasError && (this.state.selectedAccount || this.stae.selectedAccount === "") ? (
														<small className="text-issues">required</small>
													) : null}
												</div>
												<Select
													isSearchable={true}
													className={`selectOption f13 p-0`}
													components={{
														IndicatorSeparator: () => null
													}}
													value={({
														value: this.state.selectedAccount && this.state.selectedAccount !== "" ? this.state.selectedAccount : "",
														label: this.state.selectedAccount && this.state.selectedAccount !== "" ? getAccountNameFromId(this.state.selectedAccount, this.state.accounts) : <span className="placeholder">Select</span>
													})}
													options={this.state.accounts && this.state.accounts.map(row => ({
														value: row.account_id,
														label: row.account_name,	
													}))}
													onChange={event => this.setState({
														selectedAccount: event.value,
														changesInFilter: true,
													})}
												/>
											</div>

											<div className="mr-4">
												<div className="d-flex justify-content-between">
													<label className="align-self-center m-0">Region </label>
													{this.state.hasError && (this.state.selectedRegion || this.stae.selectedRegion === "") ? (
														<small className="text-issues">required</small>
													) : null}
												</div>
												<Select
													isSearchable={true}
													className={`selectOption f13 p-0`}
													components={{
														IndicatorSeparator: () => null
													}}
													value={({
														value: this.state.selectedRegion && this.state.selectedRegion !== "" ? this.state.selectedRegion : "",
														label: this.state.selectedRegion && this.state.selectedRegion !== "" ? getRegionName(this.state.selectedRegion, this.state.regions) : <span className="placeholder">Select</span>
													})}
													options={this.state.regions && this.state.regions.map(row => ({
														value: row.region,
														label: row.name,	
													}))}
													onChange={event => this.setState({
														selectedRegion: event.value,
														changesInFilter: true,
													})}
												/>
											</div>		

											<div className="mr-4" ref={this.tagsRef}>
												<div className="" onClick={() => this.setState({ showTagFilter: true })}>
													<label className="mb-0">Tags</label>
													<p className='mb-0 STDborderBottom mt-3' style={{ minWidth: "150px" }}>
														{!this.state.selectedTags || (this.state.selectedTags && !this.state.selectedTags.length) ?
															<span>All</span>
														: this.state.selectedTags && this.state.selectedTags.length ?
															<span>{this.state.selectedTags.length} selected</span>
														: null}
													</p>
												</div>
												{this.state.showTagFilter ?
													<div className="costPopupTagSection border mt-1" style={{ right: "10px" }}>
														<div className="p-3 bg-dark3">
															<div className={`w-100 mt-3`}>
																<p className="mb-2 w-100 text-white">Tags</p>
																<div className={`d-flex`}>
																	<label className="mb-1 w-40">Key</label>
																	<label className="mb-1 w-40 ml-3">Value</label>
																</div>
																<div className={`w-100`}>
																	<div className={`d-flex mt-3`}>
																		<Select
																			isSearchable={true}
																			components={{
																				IndicatorSeparator: () => null
																			}}
																			className={`selectOption mr-2 mt-2`}
																			value={({
																				value: this.state.tag_key,
																				label: this.state.tag_key && this.state.tag_key !== "" ? this.state.tag_key : <span className="placeholder">Key</span>
																			})}														
																			
																			options={this.props.tagKeys && this.props.tagKeys.map(item => ({
																				value: item,
																				label: item,	
																			}))}
																			onChange={event => this.setState({
																				tag_key: event.value,
																				tagValues: [],
																			},
																				() => this.getAllTagsValues()
																			)}
																		/>
																		{this.state.tag_key && this.state.tag_key !== "" && !this.state.tagValues.length ?
																			<div className='ml-3'>
																				<Spinner className='text-center align-self-center' color='white' size='sm' />
																			</div>
																		:
																			<React.Fragment>
																				<div className={`multiSelectOption align-self-center mr-2 z998`} style={{marginTop: "1.3rem"}}>
																					<ReactMultiSelectCheckboxes
																						placeholderButtonLabel="Select"
																						getDropdownButtonLabel={() => this.getMultiSelectedCount("tag_value", this.state.tag_value ? this.state.tag_value : [])}
																						options={this.state.tagValues && this.state.tagValues.map(row => ({
																							value: row,
																							label: row,
																						}))}
																						onChange={arr => { this.handleMultiSelectChange("tag_value", arr ? arr : [], this.state.tagValues) }}
																						value={this.state.tag_value && this.state.tag_value.map(acc => ({
																							value: acc
																						}))}
																					/>
																				</div>
																				<span className={`far fa-plus cursorPointer f18 align-self-center ml-4`} onClick={() => this.addNewTag("tags")}></span>
																			</React.Fragment>
																		}
																	</div>
																</div>
															</div>
															{this.state.tagsSelected && this.state.tagsSelected.length ? 
																this.state.tagsSelected.map((tag, index) => {
																	return(
																		<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">{tag.key +" : "+tag.value}
																			<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeTagSection(tag) } ></i>
																		</span>
																	)
																})
															: null}
															<div className="d-flex mt-3">
																<span className={`text-info mr-2 align-self-center cursorPointer`} onClick={() => this.setState({ tagsSelected: [], selectedTags: [] })}>Clear</span>
																<button className={`btn btn-sm btn-light cursorPointer`} onClick={() => this.setState({ showTagFilter: false, selectedTags: this.state.tagsSelected })}>Select</button>
															</div>
														</div>
													</div>
												: null}
											</div>

											{/* <div className="mr-4 displayNone">
												<div className="d-flex justify-content-between">
													<label className="align-self-center m-0">Comparison Method</label>
													{this.state.hasError && (this.state.comparisonMethod || this.stae.comparisonMethod === "") ? (
														<small className="text-issues">required</small>
													) : null}
												</div>
												<Select
													placeholder={'Select'}
													isSearchable={false}
													// menuIsOpen={true}
													className={`selectOptionsm f13 p-0`}
													components={{
														IndicatorSeparator: () => null
													}}
													value={({
														value: this.state.comparisonMethod && this.state.comparisonMethod !== "" ? this.state.comparisonMethod : 'Select',
														label: this.state.comparisonMethod && this.state.comparisonMethod !== "" ? this.state.comparisonMethod : <span className="placeholder">Select</span>
													})}
													options={this.state.comparisonMethod && this.state.comparisonMethod.map(item => ({
														value: item,
														label: item,
													}))}
													onChange={event => this.setState({ selectedSource: event.value })}
												/>
											</div> */}
																					
											{/* <div className={`mr-4 displayNone`} style={{maxWidth: '250px'}} ref={this.dateRef}>
												<div className="" onClick={() => this.setState({ showDateRangePicker: true })}>
													<label className="mb-0">Duration</label>
													<p className='mb-0 STDborderBottom mt-3'>
														{this.state.datePickerStartDate ?
															<span> {momentDateGivenFormat(this.state.datePickerStartDate, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(this.state.datePickerEndDate, 'DD MMM YYYY')}</span>
														: null}
													</p>
												</div>
												{this.state.showDateRangePicker ?
													<div className="bg-dark3 p-3 position-absolute" style={{"z-index": "999999999","right": "20px","height": "530px"}} ref={this.dateRef}>
														<div className="darkThemeRangePicketPopup RangePickerWithStartEndTime text-black5 pt-2 ml-n1">
															<div className='d-flex justify-content-between'>
																<p className="mb-2 w-100 text-white">Date Picker</p>
																<i className="far fa-times align-self-center cursorPointer f18 mb-1" onClick={() => this.setState({ showDateRangePicker: false })}></i>
															</div>
															<RangePicker
																// locale={`en-us`} // default is en-us
																show={true} // default is false
																disabled={false}
																allowPageClickToClose={false}
																placeholder={["Start Time", "End Time"]}
																onConfirm={(res) => this.handleSelect(res)}
																onClose={() => console.log("onClose")}
																onClear={() => console.log("onClear")}
																style={{ width: "610px", margin: "0 auto" }}
															/>
															<div className={`d-flex flex-wrap pt-2 border-top`}>
																<span className={`mr-2 f12 align-self-center text-white`}>Last</span>
																<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === "+7d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => this.setState({ showDateRangePicker: false, durationActive: true, selectedDuration: "+7d" }, () => this.durationFunction())}>1 Week</span>
																<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === "+14d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => this.setState({ showDateRangePicker: false, durationActive: true, selectedDuration: "+14d" }, () => this.durationFunction())}>2 Week</span>
																<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === "+30d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => this.setState({ showDateRangePicker: false, durationActive: true, selectedDuration: "+30d" }, () => this.durationFunction())}>1 Month</span>
																<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === "+60d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => this.setState({ showDateRangePicker: false, durationActive: true, selectedDuration: "+60d" }, () => this.durationFunction())}>2 Month</span>
																<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedDuration === "+90d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => this.setState({ showDateRangePicker: false, durationActive: true, selectedDuration: "+90d" }, () => this.durationFunction())}>3 Months</span>
															</div>
															<div className='d-flex justify-content-end pt-2 mt-2 border-top'>
																<button className={`btn-sm bg-light text-decoration-nonep-2 text-black mr-3`} onClick={() => this.setState({ showDateRangePicker: false })}>Cancel</button>
															</div>
														</div>
													</div>
												: null}
											</div> */}

											{this.state.changesInFilter ?
												<span className='text-info cursorPointer mt-3' onClick={() => this.addToCompare()}>Add to Compare</span>
											: null}
											{/* <div className="align-self-center">
												<div className="d-flex align-self-center">
													<div className="btn-group" role="group" aria-label="governance-dash">
														<button type="button" className={`cursorPointer btn btn-secondary border-right`} onClick={()=> this.onSearch()}>Search</button>
														<button type="button" className={`cursorPointer btn btn-secondary border-left`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
													</div>
												</div>
											</div> */}
										</div>
									</div>
								</div>
							</div>
							
							{this.state.showSlectedFilters ?
								<div className="bg-dark3 p-3">
									<div className='d-flex justify-content-between mb-1'>
										<p className="mb-0 w-100 text-white">Selected Filters</p>
										{!this.state.compareLoading ? 
											<i className="far fa-times align-self-center cursorPointer f18 mb-1" onClick={() => this.setState({ showSlectedFilters: false }, () => this.getWindowSize())}></i>
										: null}
									</div>
									{this.state.commonFilters ?
										<React.Fragment>
											<div className='bg-dark2 rounded p-2 mb-2'>
												<div className='d-flex flex-wrap'>
													{this.state.commonFilters.source && this.state.commonFilters.source !== "" ?
														<span className="font-weight-bold align-self-center cursorPointer mt-2 mr-2">Source : <span className='text-info'> {this.state.commonFilters.source}</span> </span>
													: null}
													{this.state.commonFilters.metric_name && this.state.commonFilters.metric_name.length ?
														<div className='d-flex'>
															{this.state.commonFilters.metric_name.filter(e => e !== "All").map(row => {
																return(
																	<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																		<span className='text-primary-color'>Metric :</span> 
																		{row}
																	</span>
																)
															})}
														</div>
													: null}
													{this.state.commonFilters.aggregate_by && this.state.commonFilters.aggregate_by !== "" ?
														<div className='d-flex'>
															{this.state.commonFilters.aggregate_by.map(row => {
																return(
																	<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																		<span className='text-primary-color'>Group By :</span> 
																		{row}
																	</span>
																)
															})}
														</div>
													: null}
												</div>
											</div>
											{this.state.commonFilters.selectedFilters && this.state.commonFilters.selectedFilters.length ? 
												this.state.commonFilters.selectedFilters.map((filters, index) => {
													return(
														<div className='bg-dark2 d-flex justify-content-between rounded p-2 mb-2'>
															<div className='d-flex flex-wrap'>
																{filters.account_id && filters.account_id !== "" ?
																	<small className="font-weight-bold align-self-center cursorPointer mt-2 mr-2">Account : <span className='text-info'> {getAccountNameFromId(filters.account_id, this.state.accounts)}</span> </small>
																: null}
																{filters.region && filters.region !== "" ?
																	<small className="font-weight-bold align-self-center cursorPointer mt-2 mr-2">Region : <span 	className='text-info'> {getRegionName(filters.region, this.state.regions)}</span> </small>
																: null}
																{filters.tags ?
																	<div className='d-flex'>
																		{filters.tags.map(row => {
																			return(
																				<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																					<span className='text-primary-color'>Tag :</span> 
																					{row.key +" : "+row.value}
																				</span>
																			)
																		})}
																	</div>
																: null}
															</div>
															{/* <div className='' */}
															{!this.state.compareLoading ? 
																<i className='fa fa-times-circle px-2' onClick={() => this.removeFilter(filters, index)} />
															: null}
														</div>
													)
												})
											: null}
										</React.Fragment>
									: null}

									<div className='d-flex justify-content-end'>
										<React.Fragment>
											<div className='d-flex mr-4'>
												<span className='align-self-center text-info cursorPointer mt-3 ml-4' onClick={() => this.setState({ showDayOptions: true })}>Specific Day</span>
											</div>
											{/* {this.state.selectedFilters && this.state.selectedFilters.length === 1 ?
												!this.state.showDayOptions ?
												: null
											: null} */}
										</React.Fragment>
										{this.state.showDayOptions ?
											<div className='d-flex dlpDescriptionForm dlpDescriptionFormPlain justify-content-end'>
												<div className=''>
													<p className={`mb-1 mr-2 mt-2`}>Day</p>
													<div className='d-flex'>
														<div className='selectBoxBottomColor text-white' style={{width: "70px"}}>
															<input
																type='text'
																className={`inputField w-100`}
																style={{ minHeight: '38px' }}
																placeholder={'Start'}
																value={this.state.start_day}
																onChange={e => this.setState({ start_day: onlyNumeric(e.target.value) }, () => this.validateDay("start_day"))}
															/>
														</div>
														<div className='selectBoxBottomColor text-white ml-2' style={{width: "70px"}}>
															<input
																type='text'
																className={`inputField w-100`}
																style={{ minHeight: '38px' }}
																placeholder={'End'}
																value={this.state.end_day}
																onChange={e => this.setState({ end_day: onlyNumeric(e.target.value) }, () => this.validateDay("end_day"))}
															/>
														</div>
														<span className='align-self-center text-info cursorPointer mt-3 ml-4' onClick={() => this.setState({ showDayOptions: false, start_day: "", end_day: "" })}>Hide Specific Day</span>
													</div>
												</div>
											</div>
										: null}
										<div className='d-flex dlpDescriptionForm dlpDescriptionFormPlain justify-content-end'>
											<div className='mx-4'>
												<p className={`mb-1 mr-2 mt-2 align-self-end`}>Period</p>
												<div className={`d-flex`}>
													<div className='selectBoxBottomColor text-white' style={{width: "70px"}}>
														<input
															type='text'
															className={`inputField w-100`}
															style={{ minHeight: '38px' }}
															placeholder={'Month'}
															value={this.state.selectedPeriod}
															onChange={e => this.setState({ selectedPeriod: onlyNumeric(e.target.value) }, () => this.validatePeriod() )}
														/>
													</div>
													<p className={`m1-2 mr-2 mt-2 mb-1 align-self-end`}>Month(s)</p>
												</div>
											</div>
										</div>
										<div className="d-flex justify-content-end align-self-center mt-3 mb-2">
											{this.state.compareLoading ?
												<button className="btn btn-secondary">
													Comparing <Spinner size='sm' color='light' className='ml-2' />
												</button>
											: 
												<React.Fragment>
												<button type="button" className={`cursorPointer btn btn-primary border-right`} onClick={()=> this.setState({ compareLoading: true, compareResponse: {}, servicesToShow: [] }, () => this.onSearch())}>Compare</button>
												{/* <button type="button" className={`ml-2 cursorPointer btn btn-secondary border-right`} onClick={()=> this.onSearch()}>Close</button> */}
												{!this.state.compareLoading ? 
													<span className='align-self-center text-info cursorPointer mt-3 ml-4' onClick={() => this.clearFilers()}>Clear</span>
												: null}
												</React.Fragment>
											}
										</div>
									</div>

								</div>
							: this.state.selectedFilters && this.state.selectedFilters.length ?
								<span className='text-info cursorPointer mt-3 d-flex justiy-content-end' onClick={() => this.setState({ showSlectedFilters: !this.state.showSlectedFilters }, () => this.getWindowSize())}>Filters</span>
							: null}
						</div>
						<div className="m-2 d-flex justify-content-between">
							<div className='ml-2 w-60'>
								<p className='font-weight-bold m-0 f15'>Comparison</p>
								<p className="mb-0 small text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
							</div>
							
							<div className="d-flex justify-content-end">
								{this.state.totalAccArray ?
									<div className="align-self-center">
										<div className="d-flex justify-content-end">
											<small className="align-self-center">
												<span className='mr-2'>Showing</span>
												{this.state.totalAccArray.length > 4 ?  
													'  '+((this.state.totalItemShowing+this.state.itemMovedRight) > this.state.totalAccArray.length ? this.state.itemMovedRight - 1 : this.state.itemMovedRight) +' - '+ ((this.state.totalItemShowing+this.state.itemMovedRight) > this.state.totalAccArray.length ? this.state.totalAccArray.length : (this.state.totalItemShowing+this.state.itemMovedRight)) +' ('+this.state.totalAccArray.length+') '
												: 
													this.state.totalAccArray.length ? 
													' '+this.state.totalAccArray.length +' ('+this.state.totalAccArray.length+') '
													: ' 0'
												} 
											</small>
											<h5 className={`mb-0 mx-2 ${this.state.itemMovedRight > 0 ? 'text-white bg-info rounded-50' : 'bg-muted disabled rounded-50'} `}><span className="fad fa-arrow-circle-left cursorPointer" onClick={() => this.slideLeft()}></span></h5>
											<h5 className={`mb-0 ${this.state.totalAccArray.length > 4 && this.state.totalAccArray.length >= (this.state.totalItemShowing + this.state.itemMovedRight) ? 'text-white bg-info rounded-50' : 'bg-muted disabled rounded-50'}`}><span className="fad fa-arrow-circle-right cursorPointer" onClick={() => this.slideRight()}></span></h5>
										</div>
									</div>
								: null}
							</div>
						</div>
						<div className='m-2 bg-dark3 px-2'>
							<div className={`col-sm-12 overflow-auto`}>
								<div className="d-flex w-100 ">
									<div className='w-18'>
										<div className='d-flex'>
											<p className="w-100 m-0 f18 font-weight-bold text-white align-self-center py-3">Service</p>
										</div>
									</div>
									<div className='w-82 overflow-x-atuo overflow-hidden' ref={this.sliderRef}>
										<div className='d-flex' ref={this.getBlockWidthRef}>
											{this.state.compareResponse && this.state.compareResponse.filters ?
												this.state.compareResponse.filters.map((row, index) => {
													return(
														<div className={`d-flex compare-cost justify-content-between pt-2 pr-2 py-2`}>
															<div className="d-flex w-100">
																<div className='w-100'>
																	<p className="m-0 font-weight-bold text-white f16" id={"headerName_"+index}>
																		{row.account_id ? 
																			<span>{getAccountNameFromId(row.account_id, this.state.accounts)}</span>
																		: row.region ?
																			<span>{getRegionName(row.region, this.state.regions)}</span>
																		: row.tags ?
																			<span>{row.tags[0].key +" "+row.tags[0].value}</span> 
																		: 
																			<span>{row}</span> 
																		}
																		<UncontrolledTooltip placement='top' target={"headerName_"+index}>
																			<div className=''>
																				{row.account_id ? 
																					<div>Account: {getAccountNameFromId(row.account_id, this.state.accounts)}</div>
																				: null}
																				{row.region ? 
																					<div>Region: {getRegionName(row.region, this.state.region)}</div>
																				: null}
																				{row.tags && row.tags.length ? 
																					<div className='d-flex flex-wrap'>
																						{row.tags.map((tag, index) => {
																							return(
																								<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">{tag.key +" : "+tag.value}
																								</span>
																							)
																						})}
																					</div>
																				: null}
																			</div>
																		</UncontrolledTooltip> 
																	</p>
																	<div class="d-flex justify-content-between mt-1">
																		<span className='align-self-center'>{this.state.paramsFilter && this.state.paramsFilter.length && this.state.paramsFilter.includes("usage") ? "Usage" : <span>&nbsp;</span>}
																		</span>
																		{this.state.paramsFilter && this.state.paramsFilter.length && this.state.paramsFilter.includes("cost") ?
																			<span className='d-flex justiy-content-end align-self-center'>Cost</span>
																		: null}
																	</div>
																</div>
															</div>
															<div className={`text-right displayNone`}>												
																<div className="dropdown w-100">	
																	<div><span className="far fa-chevron-square-down fa-lg cursorPointer" onClick={ () => this.showDropFunction() }>
																	</span></div>
																	<ul className={`comparisonRemoveDrop f10`}>
																		<li onClick={ () => this.removeColumn()}><span>Remove</span></li>
																		<li className={` ${this.state['text_'] ? '' : 'mb-1'} `} onClick={()=> this.setState({['text_']: this.state['text_'] ? false : true })}><span>Move To</span></li>
																		<li className={`mb-1 ${this.state['text_'] ? '' : 'displayNone'} `}>
																			<span>
																				<input type="text" className="moveTextbox" onChange={e => this.onChangeText(e)} value={this.state.moveToDestination} />
																				<button className="btn btn-sm btn-primary align-self-left moveBtn" onClick={()=> this.moveColumnTo() }>Submit</button>
																			</span>
																		</li>
																	</ul>
																</div>
															</div>
														</div>
													)
												})
											: this.state.compareResponse && this.state.compareResponse.dates ?
											this.state.compareResponse.dates.map((row, index) => {
												return(
													<div className={`d-flex compare-cost justify-content-between pr-2 py-2`}>
														<div className="d-flex w-100">
															<div className='w-100'>
																<p className="m-0 font-weight-bold text-white" id={"headerName_"+index}>
																	{momentDateGivenFormat(row, "MMM YYYY")}
																</p>
																<div class="d-flex justify-content-between mt-1">	
																	<span className='align-self-center'>{this.state.paramsFilter && this.state.paramsFilter.length && this.state.paramsFilter.includes("usage") ? "Usage" : <span>&nbsp;</span>}
																	</span>
																	{this.state.paramsFilter && this.state.paramsFilter.length && this.state.paramsFilter.includes("cost") ?
																		<span className='d-flex justiy-content-end align-self-center'>Cost</span>
																	: null}
																</div>
															</div>
														</div>
														<div className={`text-right displayNone`}>												
															<div className="dropdown w-100">	
																<div><span className="far fa-chevron-square-down fa-lg cursorPointer" onClick={ () => this.showDropFunction() }>
																</span></div>
																<ul className={`comparisonRemoveDrop f10`}>
																	<li onClick={ () => this.removeColumn()}><span>Remove</span></li>
																	<li className={` ${this.state['text_'] ? '' : 'mb-1'} `} onClick={()=> this.setState({['text_']: this.state['text_'] ? false : true })}><span>Move To</span></li>
																	<li className={`mb-1 ${this.state['text_'] ? '' : 'displayNone'} `}>
																		<span>
																			<input type="text" className="moveTextbox" onChange={e => this.onChangeText(e)} value={this.state.moveToDestination} />
																			<button className="btn btn-sm btn-primary align-self-left moveBtn" onClick={()=> this.moveColumnTo() }>Submit</button>
																		</span>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												)
											})
										: null}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='m-2 bg-dark px-2'>
							<div className="overflowYAuto" style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
								<div className={`col-sm-12 overflow-auto`}>
									<div className="d-flex">
										<div className='w-18'>
											{this.state.servicesToShow && this.state.servicesToShow.length ?
												this.state.servicesToShow.map((row, index) => {
													return(
														<React.Fragment>
														<div className={`d-flex border-right compare-cost-service`}>
															{row.service_api && row.service_api.length ?
																<i class="fas fa-angle-right f18 align-self-center mr-2 cursorPointer" onClick={() => this.setState({ ["show_"+index]: !this.state["show_"+index] })}></i>
															: null}
															<h6 className="text-primary mb-0 align-self-center cursorPointer" onClick={() => this.setState({ ["show_"+index]: !this.state["show_"+index] })}>{row.service}</h6>
														</div>
														{this.state["show_"+index] ?
															row.service_api && row.service_api.length && row.service_api.map(seRow => {
																return(
																	<div className={`d-flex border-right compare-cost-service`}>
																		<h6 className="text-primary-color mb-0 align-self-center cursorPointer">{seRow}</h6>
																	</div>
																)
															})
														: null}
														</React.Fragment>
													)
												})
											: null}
										</div>
										<div className='w-82 overflow-x-atuo overflow-hidden' ref={this.sliderRef1}>
											{this.state.servicesToShow && this.state.servicesToShow.length ?
												this.state.servicesToShow.map((row, index) => {
													return(
														this.state.compareResponseResults.hasOwnProperty(row.service) ?
															<React.Fragment>
															<div className={`${this.state.filters && this.state.filters.length > 1 ? "d-flex" : ""}`} ref={this.getBlockWidthRef1}>
																{this.state.filters && this.state.filters.map((met, mIndex) => {
																	return(
																		<div className='compare-cost justify-content-between border-bottom-dark'>
																			<div className='d-flex justify-content-between'>
																				
																				<span className={`text-white align-self-center pl-3`}>{this.state.compareResponseResults[row.service].hasOwnProperty("usage") ? this.state.compareResponseResults[row.service].usage[mIndex] : <span>&nbsp;</span>}</span>
																				{this.state.compareResponseResults[row.service].hasOwnProperty("cost") ?
																					<span className={`d-flex justiy-content-end text-white align-self-center pr-3`}>{this.state.compareResponseResults[row.service].cost[mIndex]}</span>
																				: null}

																				{!this.state.compareResponseResults[row.service].hasOwnProperty("cost") && !this.state.compareResponseResults[row.service].hasOwnProperty("usage") ?
																					<span className={`text-white align-self-center pr-3`}>&nbsp;</span>
																				: null}
																			</div>
																		</div>
																	)
																})}
															</div>
															{row.service_api && row.service_api.length && this.state.compareResponseResults[row.service].hasOwnProperty("data") ?
																row.service_api.map(ope => {
																	return(
																		<div className={`${this.state.filters && this.state.filters.length > 1 ? "d-flex" : ""}`}>
																			{this.state.filters && this.state.filters.map((met, mIndex) => {
																				return(
																					this.state["show_"+index] && this.state.compareResponseResults[row.service].data.hasOwnProperty(ope) ?
																						<div className='compare-cost-child justify-content-between border-bottom-black5'>
																							<div className='d-flex justify-content-between'>
																								<span className={`text-white align-self-center pl-3`}>{this.state.compareResponseResults[row.service].data[ope].hasOwnProperty("usage") ? this.state.compareResponseResults[row.service].data[ope].usage[mIndex] : <span>&nbsp;</span>}</span>
																								{this.state.compareResponseResults[row.service].data[ope].hasOwnProperty("cost") ?
																									<span className={`d-flex justiy-content-end text-white align-self-center pr-3`}>{this.state.compareResponseResults[row.service].data[ope].cost[mIndex]}</span>
																								: null}
																							</div>
																						</div>
																					: null
																				)
																			})}
																		</div>
																	)
																})
															: null}
															</React.Fragment>
														: null
													)
												})
											: null}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
LandingPage.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log("state.cost", state.cost)
	return {
		providers: state.filters.providers,
		regions: state.filters.regions && state.filters.regions[0] && state.filters.regions[0].provider_regions ? state.filters.regions[0].provider_regions :[],
		tagKeys: state.filters.tagKeys,
	}
}

export default AppWrapper(LandingPage, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
	getAllTagsKeys,
	getAllTagsValues,
	costComparison,
})