/*************************************************
 * Tvastar
 * @exports
 * @file ViewSupressSection.js
 * @author Rajasekar // on 09/01/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
	Input,
	Spinner,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from 'reactstrap'
import {
	createConfiguration,
	deleteConfiguration,
	updateConfiguration,
} from '../../../actions/detectors/configurationsAction'
import { listAllProviders, listAllServices } from '../../../actions/commonAction'
import _ from 'lodash'

import Select from 'react-select'

const categoryOptions = ["Availability", "Compliance", "Cost", "Governance", "Performance", "Security", "Tagging", "Operations"]

class ConfigureSection extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isCreateClicked: false,
			hasErrorInForm: false,
			showLoading: false,
			isCardClicked: false,

			providers: [],
			services: [],

			configName: '',
			detectorId: '',
			groupName: '',
			provider: '',
			description: '',
			service: '',
			category: '',
			resourceType: '',
			mandatory: '',
			complaince: '',

			responseMessage: '',
			responseErrMessage: '',
			showMessage: false,
			showErrMessage: false,
		}
	}

	componentDidMount = () => {
		this.props.listAllProviders((promise, providers) => {
			if (promise) {
				this.setState({ providers })
			} else {
				this.setState({ providers: [] })
			}
		})
	}
	/**
	 * to get all services based on provider
	 */
	getAllServices = () => {
		const params = { provider: this.state.provider.toLowerCase() }
		this.props.listAllServices(params, (promise, services) => {
			if (promise) {
				this.setState({ services },
					() => this.formSericeSelect()	
				)
			}
		})
	}	

	formSericeSelect = () => {
		let data = []
		if(this.state.services && this.state.services.length) {
			_.chain(this.state.services)
				.groupBy('resource_type')
				.map((value, key) => ({ label: key, sub: value }))
				.value()
				.forEach((service, i) => {
					let child = []
					service.sub.forEach((item, i) => {
						let childRow = {}
						childRow.value = service.service_name
						childRow.label = item.service_name
						child.push(childRow)
					})
					let dataRow = {}
					dataRow.label = service.label
					dataRow.options = child

					data.push(dataRow)
				})
		}

		this.setState({ servicesOptGrop: data })
	}

	/**
	 * Handles new configuration form submission
	 */
	onSubmitNewConfigForm = () => {
		if (
			this.state.configName === '' ||
			this.state.detectorId === '' ||
			this.state.groupName === '' ||
			this.state.provider === '' ||
			this.state.description === '' ||
			this.state.service === '' ||
			this.state.category === '' ||
			this.state.resourceType === '' ||
			this.state.mandatory === '' ||
			this.state.complaince === ''
		) {
			this.setState({ hasErrorInForm: true })
		} else {
			this.setState({ showLoading: true })
			const params = {
				name: this.state.configName,
				service_id: this.state.detectorId,
				group_name: this.state.groupName,
				provider: this.state.provider,
				description: this.state.description,
				service: this.state.service,
				category: this.state.category,
				resource_type: this.state.resourceType,
				mandatory: this.state.mandatory,
				complaince: this.state.complaince,
			}
			// const paarams = {
			// 	provider: this.state.provider,
			// 	service_name: this.state.service,
			// 	region: 'us-east-2',
			// 	account_id: '150143397566',
			// 	organization: '8kmiles',
			// 	tags: [
			// 		{
			// 			key: 'project',
			// 			value: 'pana-life',
			// 		},
			// 	],
			// 	asset_name: 'subnet-5d5a2235',
			// 	key_value: '90',
			// 	key_name: 'subnet block',
			// 	module_id: 'AWSVPC027',
			// 	module_name: 'identifier',
			// 	created_by: 'lavanya.m@8kmiles.com',
			// }
			if (!this.state.isCardClicked && this.state.isCreateClicked) {
				this.props.createConfiguration(params, (promise, response) => {
					this.setState({ showLoading: false })
					if (promise) {
						if (response.status) {
							this.setState({ showMessage: true, responseMessage: 'Created Successfully.' })
						} else {
							this.setState({ showErrMessage: true, responseErrMessage: 'Configuration not created' })
						}
					} else {
						this.setState({ showErrMessage: true, responseErrMessage: 'Configuration not created' })
					}
				})
			} else {
				const updateParams = {
					provider: 'aws',
					service_name: 'vpc-subnets',
					region: 'us-east-2',
					account_id: '150143397566',
					organization: '8kmiles',
					tags: [
						{
							key: 'project',
							value: 'pana-life',
						},
						{
							key: 'application',
							value: 'config',
						},
					],
					asset_name: 'subnet-5d5a2235',
					key_value: '100',
					key_name: 'subnet block',
					module_id: 'AWSVPC027',
					module_name: 'identifier',
					modified_by: 'lavanya.m@8kmiles.com',
					configuration_id: '905868963',
				}

				this.props.updateConfiguration(updateParams, (promise, response) => {
					this.setState({ showLoading: false })
					if (promise) {
						if (response.status) {
							this.setState({ showMessage: true, responseMessage: 'Created Successfully.' })
						} else {
							this.setState({ showErrMessage: true, responseErrMessage: 'Configuration not created' })
						}
					} else {
						this.setState({ showErrMessage: true, responseErrMessage: 'Configuration not created' })
					}
				})
			}
		}
	}

	toggleDeleteModal = () => this.setState({ showDeleteConfigModal: !this.state.showDeleteConfigModal })

	/**
	 * Deletes configuartion
	 */
	deleteConfiguration = item => {
		this.props.deleteConfiguration(
			{
				provider: item.provider,
				service_name: item.service_name,
				region: 'us-east-2',
				account_id: '150143397566',
				organization: '8kmiles',
				tags: [
					{
						key: 'project',
						value: 'pana-life',
					},
					{
						key: 'application',
						value: 'config',
					},
				],
				asset_name: 'subnet-5d5a2235',
				key_name: 'subnet block',
				module_id: 'AWSVPC027',
				module_name: 'identifier',
				configuration_id: '905868963',
			},
			() => {}
		)
	}

	render() {
		return (
			<div className='pop-slider bg-dark3'>
				<div className='container-fluid bg-muted'>
					<div className='row py-2'>
						<div className='col-7 pr-0 align-self-center'>
							<h6 className='m-0 text-white'>
								<i
									className='far fa-arrow-left mr-2 cursor-pointer'
									onClick={() => {
										this.props.onClickBackButton()
										this.setState({ isCreateClicked: false })
									}}
								/>{' '}
								Configurations
							</h6>
						</div>
						{!this.state.isCreateClicked ? (
							<div className='col-5 text-right'>
								<button
									className='btn btn-sm btn-xs btn-primary'
									onClick={() => {
										this.setState({ isCreateClicked: true })
									}}
								>
									CREATE
								</button>
							</div>
						) : null}
					</div>
				</div>
				{!this.state.isCreateClicked && !this.state.isCardClicked ? (
					this.props.data &&
					this.props.data.map((item, i) => {
						return (
							<div className='container-fluid overflow-auto mt-2' key={i}>
								<div className='card bg-dark rounded'>
									<div className='card-body'>
										<div className='d-flex'>
											<h6
												onClick={() => {
													this.setState({
														isCardClicked: true,
														isCreateClicked: true,
														configName: item.title,
														detectorId: item.service_id,
														groupName: item.title,
														provider: item.provider,
														description: item.description,
														service: item.service_name,
														category: item.category,
														resourceType: item.resource_type,
														mandatory: item.mandatory,
														complaince: item.compliance[0] && item.compliance[0].complianceId,
													})
												}}
												className='card-title m-0 align-self-center mr-3'
											>
												{item.title}
											</h6>
											<h6 onClick={() => this.toggleDeleteModal()} className='trash-card cursor-pointer'>
												<i className='fal fa-trash' />
											</h6>
										</div>
										<p className='card-subtitle m-0 align-self-center text-muted'>{item.service_id ? item.service_id : ''}</p>
										<p className='card-text m-0'>
											<span className='text-muted'>Service:</span> {item.service_name ? item.service_name : ''}
											<span className='text-muted ml-2'>Provider:</span> {item.provider ? item.provider : ''}
										</p>
										<p className='card-text m-0'>
											<small>{item.description ? item.description : ''}</small>
										</p>
										<Modal isOpen={this.state.showDeleteConfigModal} toggle={() => this.toggleDeleteModal()}>
											<ModalHeader toggle={() => this.toggleDeleteModal()}>Delete</ModalHeader>
											<ModalBody>Are you sure want to delete configuration?</ModalBody>
											<ModalFooter>
												<Button color='primary' onClick={() => this.deleteConfiguration(item)}>
													Yes
												</Button>{' '}
												<Button color='secondary' onClick={() => this.toggleDeleteModal()}>
													No
												</Button>
											</ModalFooter>
										</Modal>
									</div>
								</div>
							</div>
						)
					})
				) : (
					<React.Fragment>
						<div className="d-flex mx-3">
							<div className="py-1 w-50">
								<div className="d-flex justify-content-between">
									<p className="mb-0 text-white">Name</p>																			
								</div>
								<Input 
									type="text" 
									placeholder="Name"
									maxLength={80}
									className={`inputDarkTheme`}
									value={this.state.configName ? this.state.configName : ""}
									onChange={e => this.setState({ configName: e.target.value })}
								/>
								{this.state.hasErrorInForm && (!this.state.configName || this.state.configName === "") ?
									<p className="mb-0 text-danger">required</p>
								: null}
							</div>
							<div className="pl-3 py-1 w-50">
								<div className="d-flex justify-content-between">
									<p className="b-block mb-0 text-white">Detector ID</p>									
								</div>
								<Input 
									type="text" 
									placeholder="Detector ID"
									maxLength={80}
									className={`inputDarkTheme`}
									value={this.state.detectorId ? this.state.detectorId : ""}
									onChange={e => this.setState({ detectorId: e.target.value })}
								/>
								{this.state.hasErrorInForm && (!this.state.detectorId || this.state.detectorId === "") ?
									<p className="mb-0 text-danger">required</p>
								: null}
							</div>
						</div>
						<div className="d-flex mt-2 mx-3">
							<div className="py-1 w-50">
								<div className="d-flex justify-content-between">
									<p className="mb-0 text-white">Group Name</p>																			
								</div>
								<Input 
									type="text" 
									placeholder="Group Name"
									maxLength={80}
									className={`inputDarkTheme`}
									value={this.state.groupName ? this.state.groupName : ""}
									onChange={e => this.setState({ groupName: e.target.value })}
								/>
								{this.state.hasErrorInForm && (!this.state.groupName || this.state.groupName === "") ?
									<p className="mb-0 text-danger">required</p>
								: null}
							</div>
							<div className="py-1 pl-3 w-50">
								<div className="d-flex justify-content-between">
									<p className="b-block mb-0 text-white">Provider</p>
								</div>
								<Select
									placeholder={"Select"}
									isSearchable={true}
									// isDisabled={this.props.actionType === "Run" ? true : false}
									className="f13 p-0 selectDarkTheme"
									options={this.state.providers && this.state.providers.map(item => ({
										value: item.provider_name,
										label: item.provider_name,
									}))}
									value={({
										value: this.state.provider,
										label: this.state.provider && this.state.provider !== "" ? this.state.provider.toUpperCase() : <span className="placeholder">Select</span>
									})}
									onChange={event =>  
										this.setState({
											provider: event.value.toLowerCase()
										},
											() => this.getAllServices()
										)
									}
								/>
								
								{this.state.hasErrorInForm && (!this.state.provider || this.state.provider === "") ?
									<p className="mb-0 text-danger">required</p>
								: null}
							</div>
						</div>
						<div className="d-flex mt-2 mx-3">
							<div className="py-1 w-100">
								<div className="d-flex justify-content-between">
									<p className="mb-0 text-white">Description</p>																			
								</div>
								<Input 
									type="text" 
									placeholder="Description"
									maxLength={80}
									className={`inputDarkTheme`}
									value={this.state.description ? this.state.description : ""}
									onChange={e => this.setState({ description: e.target.value })}
								/>
								{this.state.hasErrorInForm && (!this.state.description || this.state.description === "") ?
									<p className="mb-0 text-danger">required</p>
								: null}
							</div>
						</div>
						<div className="d-flex mt-2 mx-3">
							<div className="py-1 w-50">
								<div className="d-flex justify-content-between">
									<p className="b-block mb-0 text-white">Services</p>
								</div>
								<Select
									placeholder={"Select"}
									// isSearchable={false}
									// menuIsOpen={true}
									//onFocus={this.openProivderMenu}
									className="f13 p-0 selectDarkTheme reactSelectOptGroupFilterDrop"
									// label={({
									// 	label: this.state.selectedService,
									// 	// label: this.state.selectedService ? getRegionName(this.state.selectedService, regions) : <span className="placeholder">Select</span>
									// })}
									
									value={({
										value: this.state.service && this.state.service !== "" ? this.state.service : "Select",
										label: this.state.service && this.state.service !== "" ?this.state.service : <span className="placeholder">Select</span>
									})}
									options={this.state.servicesOptGrop && this.state.servicesOptGrop}
									onChange={event => this.setState({ service: event.label, changesInFilter: true })}
								/>
								{this.state.hasErrorInForm && (!this.state.service || this.state.service === "") ?
									<p className="mb-0 text-danger">required</p>
								: null}
							</div>
							<div className="pl-3 py-1 w-50">
								<div className="d-flex justify-content-between">
									<p className="b-block mb-0 text-white">Category</p>
								</div>
								<Select
									placeholder={"Select"}
									isSearchable={true}
									// menuIsOpen={this.props.actionType === "Run" ? false : true}
									className="f13 p-0 selectDarkTheme"
									value={({
										value: this.state.category && this.state.category !== "" ? this.state.category : "Select",
										label: this.state.category && this.state.category !== "" ? this.state.category : <span className="placeholder">Select</span>
									})}
									options={categoryOptions && categoryOptions.map(item => ({
										value: item,
										label: item,	
									}))}
									onChange={event =>  
										this.setState({
											category: event.value
										})
									}
								/>
								{this.state.hasErrorInForm && (!this.state.category || this.state.category === "") ?
									<p className="mb-0 text-danger">required</p>
								: null}
							</div>
						</div>
						<div className="d-flex mt-2 mx-3">
							<div className="py-1 w-50">
								<div className="d-flex justify-content-between">
									<p className="mb-0 text-white">Resource Type</p>																			
								</div>
								<Input 
									type="text" 
									placeholder="Resource Type"
									maxLength={80}
									className={`inputDarkTheme`}
									value={this.state.resourceType ? this.state.resourceType : ""}
									onChange={e => this.setState({ resourceType: e.target.value })}
								/>
								{this.state.hasErrorInForm && (!this.state.resourceType || this.state.resourceType === "") ?
									<p className="mb-0 text-danger">required</p>
								: null}
							</div>
							<div className="pl-3 py-1 w-50">
								<div className="d-flex justify-content-between">
									<p className="b-block mb-0 text-white">Mandatory</p>
								</div>
								<Input 
									type="text" 
									placeholder="mandatory"
									maxLength={80}
									className={`inputDarkTheme`}
									value={this.state.mandatory ? this.state.mandatory : ""}
									onChange={e => this.setState({ mandatory: e.target.value })}
								/>
								{this.state.hasErrorInForm && (!this.state.mandatory || this.state.mandatory === "") ?
									<p className="mb-0 text-danger">required</p>
								: null}
							</div>
						</div>
						<div className="d-flex mt-2 mx-3">
							<div className="py-1 w-100">
								<div className="d-flex justify-content-between">
									<p className="mb-0 text-white">Compliance</p>																			
								</div>
								<Input 
									type="text" 
									placeholder="Complaince"
									maxLength={80}
									className={`inputDarkTheme`}
									value={this.state.complaince ? this.state.complaince : ""}
									onChange={e => this.setState({ complaince: e.target.value })}
								/>
								{this.state.hasErrorInForm && (!this.state.complaince || this.state.complaince === "") ?
									<p className="mb-0 text-danger">required</p>
								: null}
							</div>
						</div>
						{this.state.showMessage ? <p className='text-success ml-3 mt-3'>{this.state.responseMessage}</p> : ''}
						{this.state.showErrMessage ? <p className='text-danger ml-3 mt-3'>{this.state.responseErrMessage}</p> : ''}
						<div className='text-right my-3 mr-2'>
							<button className='btn btn-sm btn-light mr-3' onClick={() => this.setState({ isCreateClicked: false })}>
								Back
							</button>
							<button
								className='btn btn-sm btn-primary'
								onClick={() => this.onSubmitNewConfigForm()}
								disabled={this.state.showLoading}
							>
								{this.state.showLoading ? (
									<React.Fragment>
										{this.state.isCreateClicked ? 'Creating' : 'Updating'}
										<Spinner className='text-center ml-2' color='light' size='sm' />{' '}
									</React.Fragment>
								) : this.state.isCreateClicked && !this.state.isCardClicked ? (
									'Create'
								) : (
									'Update'
								)}
							</button>
						</div>
					</React.Fragment>
				)}
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ConfigureSection.propTypes = {
	onClickBackButton: PropTypes.func,
	createConfiguration: PropTypes.func,
	listAllProviders: PropTypes.func,
	listAllServices: PropTypes.func,
	deleteConfiguration: PropTypes.func,
	updateConfiguration: PropTypes.func,
	providersData: PropTypes.array,
	data: PropTypes.array,
}

export default connect(null, {
	createConfiguration,
	listAllProviders,
	updateConfiguration,
	listAllServices,
	deleteConfiguration,
})(ConfigureSection)
