/*************************************************
 * Tvastar
 * @exports
 * @file JobDetailPanel.js
 * @author Prakash // on 27/07/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { capitalizeFirstLetter, getAccountNameFromId, momentDateGivenFormat } from '../../utils/utility'
import { CUSTOM_DURATION_FILTER_OPTIONS } from '../../utils/constants'
import  { getJobTotalCount, getJobDetails, getJobDetailsByModule, getJobDailyCount, getJobHistogram } from '../../actions/CustomerOperationsAction'
import { Spinner } from 'reactstrap'
import ApexStackedBarChart from '../common/charts/ApexStackedBarChart'


class JobDetailPanel extends Component {
	proEnvWrap = React.createRef()
	constructor(props) {
		super(props)
		this.props = props;
		this.state = {
			activeTab: 0,
			showLoading: false,
			selectedJob: this.props.selectedJob,
			choosenAccount: this.props.choosenAccount,
			selectedDuration: this.props.selectedDuration,
			start_time: this.props.start_time,
			end_time: this.props.end_time,
			
			trendGraphs: this.props.trendGraphs,
			trendGraphColors: this.props.trendGraphColors,
		}
	}

	componentDidMount = () => {
		this.getJobDailyCount("COMPLETED")
		this.getJobDailyCount("FAILED")
		this.getCpuMemoryHourDetails()
	}

	getJobDailyCount = (type) => {
		let params = {}
		if(this.state.selectedDuration === "custom") {
			params.start_time = this.state.start_time
			params.end_time = this.state.end_time
		} else {
			params.duration = this.state.selectedDuration
		}
		if(this.state.choosenAccount && this.state.choosenAccount !== "") {
			params.account_id = this.state.choosenAccount
		}

		if(type === "COMPLETED" || type === "FAILED") {
			params.status = [type]
			params.aggregate_by = ["status"]
			params.radial = true
		}
		if(this.state.selectedJob && this.state.selectedJob.job_type) {
			params.job_type = this.state.selectedJob.job_type
		}

		
		this.props.getJobDailyCount(params, (promise, response) => {
			if(promise) {
				if(type === "COMPLETED" || type === "FAILED") {
					this.setState({ [type+"_job_status"]: response },
						() => this.jobStatusTrend(type)
					)
				}
			}
		})
	}

	jobStatusTrend = (type) => {
		let results = this.state[type+"_job_status"] && this.state[type+"_job_status"].results && this.state[type+"_job_status"].results.length ? this.state[type+"_job_status"].results[0] : {}
		
		let graphData = {}
		graphData.labels = results.dates
		
		if(Object.entries(results.data).length) {
			Object.entries(results.data).forEach(([key, value]) => {				
				if(key === "COMPLETED") {
					key = "SUCCESS"
				}
				graphData[capitalizeFirstLetter(key)] = value
			})
		} else {
			let value = []
			results.dates.forEach(date => {
				value.push(0)
			})
			graphData[capitalizeFirstLetter(type)] = value
		}

		this.setState({ [type+"_job_status_data"]: graphData })
	}

	getCpuMemoryHourDetails = () => {
		let params = {}
		if(this.state.selectedDuration === "custom") {
			params.start_time = this.state.start_time
			params.end_time = this.state.end_time
		} else {
			params.duration = this.state.selectedDuration
		}
		if(this.state.choosenAccount && this.state.choosenAccount !== "") {
			params.account_id = this.state.choosenAccount
		}
		params.sum_aggregate_by = this.state.trendGraphs
		params.duration_aggregate_by = "hourly"
		if(this.state.selectedJob && this.state.selectedJob.job_type) {
			params.job_type = this.state.selectedJob.job_type
		}

		this.props.getJobDailyCount(params, (promise, response) => {
			if(promise) {
				let results = response && response.results && response.results.length ? response.results[0] : {}
				this.setState({ cpuMemoryData: results },
					() => this.cpuMemoryTrend()	
				)
			} else {
				this.setState({ cpuMemoryData: {} })
			}
		})
	}

	cpuMemoryTrend = () => {		
		let results = this.state.cpuMemoryData
		if(results.data && Object.entries(results.data).length) {
			Object.entries(results.data).forEach(([key, value]) => {
				let graphData = {}
				graphData.labels = results.dates
				graphData[key] = value

				this.setState({ [key+"_graph_data"]: graphData })
			})
		}
	}

    resetGraph = (type) => {
        let graphData = this.state[type]
        this.setState({ [type]: "" },
            () => {
                this.setState({ [type]: graphData })
            }
        )
    }

	render() {		
		return (
			<div className="advanced-search" style={{zIndex:99}}>
				<div className="search-content bg-muted w-60">
					<div className="header-search bd-highlight">
						<div className="flex-fill bd-highlight">
							<h5>{this.state.selectedJob && this.state.selectedJob.job_type ? this.state.selectedJob.job_type : ""}</h5>
							<div className="d-flex">
								<p className="m-0 small"><span className="text-pirmayr-color">Account: </span>
									{this.state.choosenAccount && this.state.choosenAccount !== "" ? 
										<span className="ml-1">{getAccountNameFromId(this.state.choosenAccount, this.props.accountsDetailsList)} <span className="text-primary-color mx-1">({this.state.choosenAccount})</span></span>
									: null}
								</p>
								<p className="m-0 small"><span className="text-pirmayr-color">Duration: </span>
									{this.state.selectedDuration !== "custom" ? 
										<span>{CUSTOM_DURATION_FILTER_OPTIONS.filter(e => e.value === this.state.selectedDuration)[0].option}</span>
									:
										<span>{this.state.start_time ? (momentDateGivenFormat(this.state.start_time, "DD MMM YYYY HH:mm") +" "+momentDateGivenFormat(this.state.end_time, "DD MMM YYYY HH:mm")) : ""}</span>
									}
								</p>
							</div>
						</div>
						<div className="text-right flex-fill bd-highlight ml-2 align-self-center">
							<i className="far fa-times cursorPointer" onClick={() => this.props.closeJobDetailPanel()}></i>
						</div>
					</div>
					<div className="overflow-auto">
						<ul className="nav nav-tabs bd-highlight displayNone" id="myTab" role="tablist">
							<li className="nav-item">
								<span
									className={`nav-link cursor-pointer ${this.state.activeTab === 0 ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
									style={{fontSize:'12px'}}
									onClick={() => this.setState(
										{ activeTab: 0, showLoading:true},
										() => {
											if(Object.entries(this.state.resourceChart).length === 0)  {
												this.setState({ donutData: {}, donutOptions: {} },
													() => this.getResourceChart()
												)
											} else {
												this.setState({ donutData: {}, donutOptions: {}, showLoading:false }, 
													() => this.getResourceDonutDetails()
												)
											}
										}
									)}
								>
								Resources
								</span>
							</li>
						</ul>
						{!this.state.showLoading?
							<div className="tab-content p-2" id="myTabContent">
								<div className="tab-pane fade show active" id="assets" role="tabpanel"
									aria-labelledby="ops-side-tab">
									{this.state.activeTab === 0 ?
										<React.Fragment>
										<div className="bg-dark3 p-3 rounded">
											<div className="d-flex mb-1">
												<div className="w-33">
													<p className="b-block mb-0">Job Type</p>
													<p className="mb-0 text-white">{this.state.selectedJob && this.state.selectedJob.job_type ? this.state.selectedJob.job_type : ""}</p>
												</div>
												<div className="py-1 w-33 pl-3">
													<p className="b-block mb-0">Frequency</p>
													<p className="mb-0 text-white">{this.state.selectedJob && this.state.selectedJob.frequency ? this.state.selectedJob.frequency : ""}</p>
												</div>
												<div className="py-1 w-33 pl-3">
													<p className="b-block mb-0">Runs</p>
													<p className="mb-0 text-white">{this.state.selectedJob && this.state.selectedJob.count ? this.state.selectedJob.count : ""}</p>
												</div>
											</div>
											<div className="d-flex mb-1 border-top">
												<div className="w-33">
													<p className="b-block mb-0">Success/Failed</p>
													<p className="mb-0 text-white d-flex">
														<span className="text-warning">{(this.state.selectedJob && this.state.selectedJob.success ? this.state.selectedJob.success : 0)}</span>
														<span className="mx-1">/</span>
														<span className="text-dark-theme-orange">{(this.state.selectedJob && this.state.selectedJob.failed ? this.state.selectedJob.failed : 0)}</span>
													</p>
												</div>
												<div className="py-1 w-33 pl-3">
													<p className="b-block mb-0">Time</p>
													<div className="d-flex">
														<p className="mb-0 small text-white"><span className="text-primary-color">Min: </span> {this.state.selectedJob && this.state.selectedJob.min_hours ? this.state.selectedJob.min_hours : 0 }</p>
														<p className="mb-0 small text-white ml-2"><span className="text-primary-color">Max: </span> {this.state.selectedJob && this.state.selectedJob.max_hours ? this.state.selectedJob.max_hours : 0 }</p>
														<p className="mb-1 small ml-2"><span className="text-primary-color">Avg: </span> {this.state.selectedJob && this.state.selectedJob.avg_hours ? this.state.selectedJob.avg_hours : 0 }</p>
													</div>
													<div className="d-flex">
														<p className="mb-0 small text-white"><span className="text-primary-color ">P95: </span> {this.state.selectedJob && this.state.selectedJob.p95_hours ? this.state.selectedJob.p95_hours : 0 }</p>
														<p className="mb-0 small text-white ml-2"><span className="text-primary-color">P99: </span> {this.state.selectedJob && this.state.selectedJob.p99_hours ? this.state.selectedJob.p99_hours : 0 }</p>
													</div>
												</div>
												<div className="py-1 w-33 pl-3">
													<p className="b-block mb-0">CPU Hours</p>
													<p className="mb-0 text-white">{(this.state.selectedJob && this.state.selectedJob.cpu_hours ? this.state.selectedJob.cpu_hours : 0)}</p>
												</div>
											</div>
											<div className="d-flex mb-1 border-top">
												<div className="w-33">
													<p className="b-block mb-0">Meomory Hours</p>
													<p className="mb-0 text-white">{(this.state.selectedJob && this.state.selectedJob.memory_hours ? this.state.selectedJob.memory_hours : 0)}</p>
												</div>
												<div className="py-1 w-33 pl-3">
													<p className="b-block mb-0">Total Time (Hours)</p>
													<p className="mb-0 text-white">{this.state.selectedJob && this.state.selectedJob.sum_hours ? this.state.selectedJob.sum_hours : ""}</p>
												</div>
												<div className="py-1 w-33 pl-3">
													<p className="b-block mb-0">Total Time (Seconds)</p>
													<p className="mb-0 text-white">{this.state.selectedJob && this.state.selectedJob.sum_seconds ? this.state.selectedJob.sum_seconds : ""}</p>
												</div>
											</div>
										</div>
										<div className="d-flex mt-2 border-top">
											<div className="col-sm-6 pl-0">
												{this.state.COMPLETED_job_status_data && Object.entries(this.state.COMPLETED_job_status_data).length ?
													<div className="bg-dark3 p-2 rounded">
														<div className="d-flex justify-content-between">
															<div className="w-90">
																<p className="text-white mb-0">Success Trend</p>
																<p className="small mb-0">Showing success type trend</p>
															</div>
															<div className="d-flex">
																{/* <div className="select-sm-bg curvedDropdown minWidth120 mr-3" ref={this.toggleTrendTypeRef}>
																	<div className="dropdown">
																		<span className="dropdown-toggle d-flex justify-content-between px-2 text-white">
																			{this.state.selectedTrendType ? this.state.trendTypes.filter(e => e.value === this.state.selectedTrendType)[0].label : <span className="placeholder">Select</span>}
																		</span>
																		<ul className={`dropdown-menu p-0 ${this.state.showTrendType ? "show" : ''}`}>
																			{this.state.trendTypes.map(item => {
																				return (
																					<li 
																						onClick={() => {
																							this.setState({ 
																								selectedTrendType: item.value,
																							.COMPLETED_job_status_data: {}
																							},
																								() => this.jobStatusTrend()
																							)
																						}}
																						className={`${this.state.selectedTrendType === item.value ? "selected" :  ""}`}
																					>
																						{item.label}
																					</li>
																				)
																			})}
																		</ul>
																	</div>
																</div> */}
																<i className='far fa-redo cursorPointer align-self-center' onClick={() => this.resetGraph("COMPLETED_job_status_data")}></i>
															</div>
														</div>
														<ApexStackedBarChart 
															graphData={this.state.COMPLETED_job_status_data}
															sparkline={false}
															zoom={true}
															yaxis={true}
															yaxisLabel={true}
															xaxis={true}
															xaxisFormat={"datetime"}
															xaxisLabel={true}
															axisLabelColor={"#50586a"}
															paddingLeft={0}
															legend={false}
															stacked={true}
															height={180}
															horizontal={false}
															barHeight={"40%"}
															barEndShape={"flat"}
															columnWidth={""}
															gradient={false}
															colors={["#FFD200"]}
															hideTooltipValue={true}
															backgroundBarShape={"flat"}
															backgroundBarColors={["#333947"]}
															showBackgroundBarColors={false}
															className={"transparentTooltip"}
														/>
													</div>
												: null}
											</div>
											<div className="col-sm-6 p-0">
												{this.state.FAILED_job_status_data && Object.entries(this.state.FAILED_job_status_data).length ?
													<div className="bg-dark3 p-2 rounded">
														<div className="d-flex justify-content-between">
															<div className="w-90">
																<p className="text-white mb-0">Failed Trend</p>
																<p className="small mb-0">Showing faiiled type trend</p>
															</div>
															<div className="d-flex">
																{/* <div className="select-sm-bg curvedDropdown minWidth120 mr-3" ref={this.toggleTrendTypeRef}>
																	<div className="dropdown">
																		<span className="dropdown-toggle d-flex justify-content-between px-2 text-white">
																			{this.state.selectedTrendType ? this.state.trendTypes.filter(e => e.value === this.state.selectedTrendType)[0].label : <span className="placeholder">Select</span>}
																		</span>
																		<ul className={`dropdown-menu p-0 ${this.state.showTrendType ? "show" : ''}`}>
																			{this.state.trendTypes.map(item => {
																				return (
																					<li 
																						onClick={() => {
																							this.setState({ 
																								selectedTrendType: item.value,
																							.COMPLETED_job_status_data: {}
																							},
																								() => this.jobStatusTrend()
																							)
																						}}
																						className={`${this.state.selectedTrendType === item.value ? "selected" :  ""}`}
																					>
																						{item.label}
																					</li>
																				)
																			})}
																		</ul>
																	</div>
																</div> */}
																<i className='far fa-redo cursorPointer align-self-center' onClick={() => this.resetGraph("FAILED_job_status_data")}></i>
															</div>
														</div>
														<ApexStackedBarChart 
															graphData={this.state.FAILED_job_status_data}
															sparkline={false}
															zoom={true}
															yaxis={true}
															yaxisLabel={true}
															xaxis={true}
															xaxisFormat={"datetime"}
															xaxisLabel={true}
															axisLabelColor={"#50586a"}
															paddingLeft={0}
															legend={false}
															stacked={true}
															height={180}
															horizontal={false}
															barHeight={"40%"}
															barEndShape={"flat"}
															columnWidth={""}
															gradient={false}
															colors={["#B96422"]}
															hideTooltipValue={true}
															backgroundBarShape={"flat"}
															backgroundBarColors={["#333947"]}
															showBackgroundBarColors={false}
															className={"transparentTooltip"}
														/>
													</div>
												: null}
											</div>
										</div>
										<div className="col-sm-12">
											<div className="row">
												{this.state.trendGraphs ?
													this.state.trendGraphs.map((item ,index) => {
														return(
														this.state[item+"_graph_data"] && Object.entries(this.state[item+"_graph_data"]).length ?
															<div className={`col-sm-6 ${index%2 ? "p-0" : "pl-0"} mt-2`}>
																<div className="bg-dark3 p-2 rounded">
																	<div className="d-flex justify-content-between">
																		<div className="w-90">
																			<p className="text-white mb-0">{item === "cpu_hours" ? "CPU Hours" : item === "memory_hours" ? "Memory Hours" : item === "vcpus" ? "CPU Usage" : item === "memory" ? "Memory Usage" : ""} Trend</p>
																			<p className="small mb-0">Showing {item === "cpu_hours" ? "CPU hours" : item === "memory_hours" ? "Memory hours" : item === "vcpus" ? "CPU usage" : item === "memory" ? "Memory Usage" : ""} trend</p>
																		</div>
																		<i className="far fa-redo cursorPointer align-self-center mr-2" onClick={() => this.resetGraph(item+"_graph_data")}></i>
																	</div>																	
																	<div className="d-flex justify-content-between">
																		<div className="w-80">
																			<ApexStackedBarChart 
																				graphData={this.state[item+"_graph_data"]}
																				sparkline={false}
																				zoom={true}
																				yaxis={true}
																				yaxisLabel={true}
																				xaxis={true}
																				xaxisFormat={"datetime"}
																				xaxisLabel={true}
																				axisLabelColor={"#50586a"}
																				paddingLeft={0}
																				legend={false}
																				stacked={true}
																				height={180}
																				horizontal={false}
																				barHeight={"40%"}
																				barEndShape={"flat"}
																				columnWidth={"25%"}
																				gradient={true}
																				gradientColor={[this.state.trendGraphColors[index]]}
																				colors={[this.state.trendGraphColors[index]]}
																				hideTooltipValue={true}
																				backgroundBarShape={"flat"}
																				backgroundBarColors={["#333947"]}
																				showBackgroundBarColors={false}
																				className={"transparentTooltip"}
																			/>
																		</div>
																		<div className="w-18">
																			<div className="bg-dark2 p-2 mt-2 rounded" style={{height: "180px"}}>
																				<div class="d-flex pb-2">
																					<p className="m-0 small">Total:</p>
																					<p className="m-0 pl-2 small">{this.state.cpuMemoryData && this.state.cpuMemoryData.total && this.state.cpuMemoryData.total[item] ? this.state.cpuMemoryData.total[item] : "-"}</p>
																				</div>
																				<div class="d-flex pb-2">
																					<p className="m-0 small">Min:</p>
																					<p className="m-0 pl-2 small">{this.state.cpuMemoryData && this.state.cpuMemoryData.min && this.state.cpuMemoryData.min[item] ? this.state.cpuMemoryData.min[item] : "-"}</p>
																				</div>
																				<div class="d-flex pb-2">
																					<p className="m-0 small">Max:</p>
																					<p className="m-0 pl-2 small">{this.state.cpuMemoryData && this.state.cpuMemoryData.max && this.state.cpuMemoryData.max[item] ? this.state.cpuMemoryData.max[item] : "-"}</p>
																				</div>
																				<div class="d-flex pb-2">
																					<p className="m-0 small">Avg:</p>
																					<p className="m-0 pl-2 small">{this.state.cpuMemoryData && this.state.cpuMemoryData.avg && this.state.cpuMemoryData.avg[item] ? this.state.cpuMemoryData.avg[item] : "-"}</p>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														: null
														)
													})
												: null}
											</div>
										</div>
										</React.Fragment>
									: null}

								</div>
							</div>
							:
							<div className='text-center mt-5' >
								<Spinner color='dark' size='lg' />
							</div>
						}
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
JobDetailPanel.propTypes = {
	getResourceChart: PropTypes.func,  //Resource
	getTotalAssetCount: PropTypes.func,  //Resource
	getPurchaseDetails: PropTypes.func,  //Purchase
	getPurchaseByInstanceType: PropTypes.func,  //Purchase
	getTotalCount: PropTypes.func, //checks
	getDayWiseCount: PropTypes.func, //checks
}

const mapStateToProps = state => {
	return {
		accountsDetailsList: state.filters.accountsDetailsList
	}
}

export default connect(mapStateToProps, {
	getJobDailyCount
})(withRouter(JobDetailPanel))



