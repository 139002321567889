/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file MetricsTab.js
 * @author Prakash // on 01/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'

import { Spinner } from 'reactstrap'
import _ from 'lodash'

import { setResourcePropsDetails } from '../../../actions/assets/assetsAction'
import { getMonitoringMetricsByTrend } from '../../../actions/aiops/MetricsAction'

import { momentDateGivenFormat, momentConvertionUtcToLocalTime, convertBytes, convertSeconds, countFormater, convertBits, subHours, addHours, momentConvertionLocalToUtcTime, currentLocaltime } from '../../../utils/utility'

import Chart from 'react-apexcharts'

class MetricsTab extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            showAnnotation: true,
            // coreMetricsListLoader: true,
        }
    }

	componentDidMount = () => {
        if(this.props.leftSectionSelection && Object.entries(this.props.leftSectionSelection).length) {
            this.setState({
                start_time: this.props.leftSectionSelection.start_time,
                end_time: this.props.leftSectionSelection.end_time,
                brushStartTime: this.props.leftSectionSelection.brushStartTime ? this.props.leftSectionSelection.brushStartTime : this.props.leftSectionSelection.start_time,
                brushEndTime: this.props.leftSectionSelection.brushEndTime ? this.props.leftSectionSelection.brushEndTime : this.props.leftSectionSelection.end_time,
                columnValue: this.props.leftSectionSelection.columnValue ? this.props.leftSectionSelection.columnValue : "",
                pageType: this.props.leftSectionSelection.pageType ? this.props.leftSectionSelection.pageType : "",
                coreMetricsListLoader: true,
                selectedMetricsType: "Core Metrics",
                graphData: {},
                metricsData: []
            },
                () => {
                    this.getMetricDetails()
                }
            )
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.leftSectionSelection && Object.entries(this.props.leftSectionSelection).length) {
            if(!_.isEqual(prevProps.leftSectionSelection, this.props.leftSectionSelection)) {
                this.setState({ 
                    start_time: this.props.leftSectionSelection.start_time,
                    end_time: this.props.leftSectionSelection.end_time,
                    brushStartTime: this.props.leftSectionSelection.brushStartTime ? this.props.leftSectionSelection.brushStartTime : this.props.leftSectionSelection.start_time,
                    brushEndTime: this.props.leftSectionSelection.brushEndTime ? this.props.leftSectionSelection.brushEndTime : this.props.leftSectionSelection.end_time,
                    columnValue: this.props.leftSectionSelection.columnValue ? this.props.leftSectionSelection.columnValue : "",
                    pageType: this.props.leftSectionSelection.pageType ? this.props.leftSectionSelection.pageType : "",
                    selectedMetricsType: "Core Metrics",
                    coreMetricsListLoader: true,
                    graphData: {},
                    metricsData: []
                },
                    () => {
                        this.getMetricDetails()
                    }
                )
            } 
            // else if(this.props.dashboardDetails && Object.entries(this.props.dashboardDetails).length) {
            //     if(!_.isEqual(prevProps.dashboardDetails, this.props.dashboardDetails)) {
            //         this.setState({ 
            //             start_time: this.props.dashboardDetails.start_time,
            //             end_time: this.props.dashboardDetails.end_time,
            //             brushStartTime: this.props.dashboardDetails.brushStartTime ? this.props.dashboardDetails.brushStartTime : this.props.dashboardDetails.start_time,
            //             brushEndTime: this.props.dashboardDetails.brushEndTime ? this.props.dashboardDetails.brushEndTime : this.props.dashboardDetails.end_time,
            //             coreMetricsListLoader: true,
            //             metricsData: []
            //         },
            //             () => {
            //                 if(this.state.assetId) {
            //                     this.getMetricDetails()
            //                 }
            //             }
            //         )
            //     }
            // }
        }
    }

    getMetricDetails = () => {
        let startTime = momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss')
        let endTime = momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss')
        let params = {}
        params.start_time = startTime
        params.end_time = endTime
        if(this.state.pageType === "ChangeEvents") {
            params.asset_arn = this.state.columnValue
        } else {
            params.asset_id = this.state.columnValue
        }
        params.top = 6
        params.anomaly = false
        if(!this.props.resourcesCoreMetricsList.hasOwnProperty(this.state.columnValue)) {
            this.props.getMonitoringMetricsByTrend(params, (promise, response) => {
                if(promise) {
                    let orderByResponse = response
                    if(response && response.length) {
                        orderByResponse = _.orderBy(response, ['order'], ['asc'])
                    }
                    this.setState({ 
                        metricsData: orderByResponse, 
                        coreMetricsListLoader: response.length ? true : false,
                    },
                        () => {
                            this.metricsListGraph()
                        }
                    )
                    if(response.length) {
                        let obj = this.props.resourcesCoreMetricsList
                        obj[this.state.columnValue] = orderByResponse
                        this.props.setResourcePropsDetails('resourcesCoreMetricsList', obj)
                    }
                } else {
                    this.setState({ coreMetricsListLoader: false })
                }
            })
        } else {
            this.setState({ 
                metricsData: this.props.resourcesCoreMetricsList[this.state.columnValue],
                coreMetricsListLoader: true,
            },
                () => {
                    this.metricsListGraph()
                }
            )
        }

        this.setState({ startTime, endTime })
    }
    
    metricsListGraph = () => {
        let graphDetails = this.state.metricsData ? this.state.metricsData : []
        if(graphDetails && graphDetails.length) {
            graphDetails.forEach(graph => {
                let graphData = graph.data
                let graphLabels = graph.dates
                Object.entries(graphData).forEach(([key, item], i) => {
                    let colors = []
                    let series = []
                    let options = {}

                    let  strokeWidth = []

                    if(item.values && item.values.length) {
                        if(item.values && item.values.length) { //statistic
                            if(item.statistic !== "Maximum") {
                                colors.push('#775BA2')
                            } else {
                                colors.push('#3DAFE8')
                            }
                            strokeWidth.push(2)
                            let dataRow = {}
                            dataRow.name = item.statistic === "Maximum" ? "max" : item.statistic === "Average" ? "avg" : item.statistic === "Sum" ? "sum" : item.statistic
                            dataRow.type = item.statistic !== "Maximum" ? "area" : "line"
                            let plotValue = []
                            graphLabels.map((lab, i) => {
                                let dataArray = []
                                dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                                let val = item.values[i]
                                if(item.unit) {
                                    val = this.getGrapUnitValue(val, item.unit)
                                }
                                dataArray.push(val)
                                plotValue.push(dataArray)
                            })
                            dataRow.data = plotValue
                            series.push(dataRow)
                        }

                        if(item.additional_statistics && item.additional_statistics.length) {
                            if(item.values && item.values.length) {
                                if(item.additional_statistics[0] !== "Maximum") {
                                    colors.push('#775BA2')
                                } else {
                                    colors.push('#3DAFE8')
                                }
                                strokeWidth.push(2)
                                let dataRow = {}
                                dataRow.name = item.additional_statistics[0] === "Maximum" ? "max" : item.additional_statistics[0] === "Average" ? "avg" : item.additional_statistics[0] === "Sum" ? "sum" : item.additional_statistics[0]
                                dataRow.type = item.additional_statistics[0] !== "Maximum" ? "area" : "line"
                                let plotValue = []
                                graphLabels.map((lab, i) => {
                                    let dataArray = []
                                    dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                                    let val = item.values[i]
                                    if(item.unit) {
                                        val = this.getGrapUnitValue(val, item.unit)
                                    }
                                    dataArray.push(val)
                                    plotValue.push(dataArray)
                                })
                                dataRow.data = plotValue
                                series.push(dataRow)
                            }
                        }

                        // console.log(key, series)
                        
                        let xaxisAnnotation = []
                        // if(this.state.showAnnotation) {
                        //     let alerts = [this.props.details.event_time]

                        //     if(alerts.length) {
                        //         alerts.forEach((item, z) => {
                        //             let alertRow = {}
                        //             // alertRow.x = new Date(momentConvertionUtcToLocalTime(addMinutes(item, (z*10)), 'YYYY-MM-DD HH:mm:ss')).getTime()
                        //             alertRow.x = new Date(momentConvertionUtcToLocalTime(item, 'YYYY-MM-DD HH:mm:ss')).getTime()
                        //             alertRow.strokeDashArray = 0
                        //             alertRow.borderColor = '#FF6F00'
                        //             alertRow.label = {
                        //                 borderColor: '#FF6F00',
                        //                 style: {
                        //                     // fontSize: '12px',
                        //                     color: '#fff',
                        //                     background: '#FF6F00'
                        //                 },
                        //                 orientation: 'horizontal',
                        //                 text: ''
                        //                 // text: momentConvertionUtcToLocalTime(item, 'DD MMM YY HH:mm'),
                        //             }

                        //             xaxisAnnotation.push(alertRow)
                        //         })
                        //     }
                        // }

                        let annotations = {
                            xaxis: xaxisAnnotation
                        }

                        options = {
                            chart: {
                                // group: 'metrics_sre',
                                // id: 'chart_rate_'+index,
                                // redrawOnParentResize: true,
                                stacked: true,
                                toolbar: {
                                    show: false,
                                },
                                zoom: {
                                    enabled: false
                                },
                                sparkline: {
                                    enabled: false
                                },
                                type: "line",
                                width: '100%',
                                animations: {
                                    enabled: false
                                }
                            },
                            colors: colors,
                            dataLabels: {
                                enabled: false,
                            },
                            stroke: {
                                width: strokeWidth,
                                curve: 'smooth',
                            },
                            legend: {
                                show: false
                            },
                            fill: {
                              type:'solid',
                              opacity: [0.25, 1],
                            },
                            // fill: {
                            //     type: "gradient",
                            //     gradient: {
                            //         // shadeIntensity: 1,
                            //         // opacityFrom: 0.7,
                            //         // opacityTo: 0.9,
                            //         // stops: [0, 90, 100]
                            //         gradientToColors: ['#775BA2'],
                            //         shadeIntensity: 0,
                            //         opacityFrom: .3,
                            //         opacityTo: 1,
                            //         stops: [30, 90]
                            //     }
                            // },
                            tooltip: {
                                // custom: function({series, seriesIndex, dataPointIndex, w}) {
                                //     let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
                                //     dateLable = dateLable.toString()
                                //     dateLable = momentDateGivenFormat(dateLable, 'DD MMM HH:mm')
                                //     let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]
                                //     return '<div class="arrow_box">'+dateLable+' <span style="color:'+ w.globals.colors +'">' + parseFloat(val).toFixed(2) + '</span> </div>'
                                // },
                                custom: function({series, seriesIndex, dataPointIndex, w}) {         
                                    let returnData = ''
                                    
                                    if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                        Object.entries(w.globals.initialSeries).map(([key, value], index) => {
                                            if(!index) {
                                                returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                            }
                                            returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'
    
                                        })
                                    }
    
                                    returnData += '</div>'
                                    return returnData
                                },
                                fixed: {
                                    enabled: false
                                },
                                x: {
                                    show: false,
                                },
                                y: {
                                    show: false
                                },        
                                marker: {
                                    show: false
                                }
                            },
                            xaxis: {
                                type: 'datetime',
                                labels: {
                                    datetimeUTC: false,
                                    style: {
                                        colors: '#60687C'
                                    },
                                    // formatter: function (value) {
                                    //     return momentDateGivenFormat(value, 'HH:mm')
                                    // },
                                },                            
                                axisTicks: {
                                    show: false
                                },
                                tooltip: {
                                    enabled: false
                                },
                            },
                            dataLabels: {
                                enabled: false
                            },
                            yaxis: [
                                {
                                    show: true,
                                    axisTicks: {
                                        show: false,
                                    },
                                    axisBorder: {
                                        show: false,
                                        color: '#60687C'
                                    },
                                    tooltip: {
                                        enabled: false
                                    },
                                    // tickAmount: 2,
                                    min: 0,
                                    // max: yaxisMaxValue, 
                                    tickAmount: 1,
                                    labels: {
                                        offsetX: -10,
                                        // minWidth: 50,
                                        show: true,
                                        style: {
                                            fontSize: '9px',
                                            colors: '#FFFFFF'
                                        },
                                        formatter: (value) => { 
                                            if(Number(value) === value && value % 1 === 0) {
                                                return Math.ceil(value.toFixed(1))
                                            } else {
                                                return Math.ceil(value.toFixed(1))
                                            }
                                            
                                            // if(!value) {
                                            //     return 0
                                            // } else {
                                            //     let val = value.toFixed(2)
                                            //     val = val.split(".")
                                            //     if(val[1] && val[1] > 0) {
                                            //         return value.toFixed(2)
                                            //     } else {
                                            //         return parseInt(val[0])
                                            //     }
                                            // }
                                        },
                                        // offsetX: 0,
                                        // offsetY: 0,
                                    },
                                },
                                {
                                    show: false,
                                }
                            ],
                            grid: {
                                show: false,
                                borderColor: '#2D323E',
                                strokeDashArray: 0,
                                position: 'back',
                                xaxis: {
                                    lines: {
                                        show: true
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#FFF'],
                                        }
                                    },
                                    axisTicks: {
                                        show: false
                                    }
                                },   
                                yaxis: {
                                    lines: {
                                        show: false
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#969AA7'],
                                        }
                                    }
                                },                
                                padding: {
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0
                                },
                            },
                            annotations: annotations,
                        }
                    }
                    this.setState({ [key+"_series_"+graph.asset_id]: series, [key+"_options_"+graph.asset_id]: options, coreMetricsListLoader: false },
                        () => {
                            // console.log([key+"_series_"+graph.asset_id], series)
                            // console.log([key+"_options_"+graph.asset_id], options)
                        }
                    )
                })
                
            })
        }
    }

    getGrapUnitValue = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : val
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytes(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit)
            } else if(unit === 'Count') {
                // val = val > 1 ? parseInt(val) : (!val ? val : parseFloat(val).toFixed(2))
                // val = val.split(".")
                // if(val[1] && val[1] > 0) {
                //     return val.toFixed(2)
                // } else {
                //     return parseInt(val[0])
                // }
                val = val ? Math.ceil(val) : 0
                if(val) {
                    val = countFormater(val)
                }
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {
            // val = val.split(".")
            // if(val[1] && val[1] > 0) {
            //     return val.toFixed(2)
            // } else {
            //     return parseInt(val[0])
            // }
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    render() {
        return (
            this.state.coreMetricsListLoader ?
                <div className='d-flex justify-content-center m-4'>
                    <Spinner className='text-center' color='white' size='lg' />
                </div>
            :
                <div className="py-2">
                    {this.state.metricsData && this.state.metricsData.length ? 
                        this.state.metricsData.map(item => {
                            return (
                                Object.entries(item.data).map(([key, value]) => {
                                    return (
                                        <div className={`p-2 border rounded bg-dark3 mb-2`}>
                                            <div className=" d-flex justify-content-between">
                                                <div className="d-flex mb-2">
                                                    <h7 className="text-white mb-0">{key}</h7>
                                                    <p className="text-primary-color f12 align-self-end mb-0 ml-2">
                                                        ({value.statistic+""+(value.additional_statistics && value.additional_statistics.length ?  " - "+value.additional_statistics[0] : "")})
                                                    </p>
                                                </div>
                                                {/* <i className="fas fa-eye"></i>   */}
                                                {/* <i className="fas fa-hand-point-right"></i> */}
                                                {/* <i class="fas fa-folder-open"></i> */}
                                                {/* <i className="fas fa-door-open text-white"></i> */}
                                            </div>
                                            {this.state[key+"_series_"+item.asset_id] && this.state[key+"_series_"+item.asset_id].length && this.state[key+"_options_"+item.asset_id] ?
                                                <div className="cursorPointer transparentTooltip mb-n4 mt-n4">
                                                    <Chart options={this.state[key+"_options_"+item.asset_id]} series={this.state[key+"_series_"+item.asset_id]} type="line" height={this.props.height}/>
                                                </div>
                                            : 
                                                <div style={{minHeight:"85px"}} className="d-flex align-self-center justify-content-center">
                                                    <div className="align-self-center">
                                                        No Data
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            )
                        })
                    : this.state.columnValue && this.state.columnValue !== "" ?
                        <div className='d-flex justify-content-center m-4'>
                            <p>There are no metrics for the selected Asset.</p>
                        </div>
                    : 
                        <div className='d-flex justify-content-center m-4'>
                            <p>Please selected an Asset to fetch the metrics.</p>
                        </div>
                    }
                </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
MetricsTab.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log('errorDashboard',state)
    return {
        leftSectionSelection: state.assets.resourcesPropsDetails && state.assets.resourcesPropsDetails.leftSectionSelection ? state.assets.resourcesPropsDetails.leftSectionSelection : {},
        resourcesCoreMetricsList: state.assets.resourcesPropsDetails && state.assets.resourcesPropsDetails.resourcesCoreMetricsList ? state.assets.resourcesPropsDetails.resourcesCoreMetricsList : [],
        dashboardDetails: state.assets.resourcesPropsDetails && state.assets.resourcesPropsDetails.dashboardDetails ? state.assets.resourcesPropsDetails.dashboardDetails : {},
    }
}

export default connect(mapStateToProps, {
    setResourcePropsDetails,
    getMonitoringMetricsByTrend,
})(withRouter(MetricsTab))