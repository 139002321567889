/*************************************************
 * Tvastar
 * @exports
 * @file SidePanel.js
 * @author Prakash // on 28/06/2020
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
 import React, { Component } from 'react'
 import { connect } from 'react-redux'
 import { withRouter } from 'react-router-dom'
 import { capitalizeFirstLetter, splitString } from '../../../utils/utility'
 import * as d3 from 'd3'
 
 class HierarchicalBarChart extends Component {
    constructor(props) {
        super(props)
        this.props = props;
        this.state = {
            breadCrumb: ''
        }
    }

    componentDidMount = () => {
        this.drawGraph()
    }

    componentDidUpdate = (prevProps) => {
        // console.log('prevProps', prevProps)
        // console.log('this.props.hirericalData', this.props)
        // if(prevProps !== this.props) {
        //     this.drawGraph()
        // }
    }

    drawGraph = () => {
        var that = this
        var ID = '#'+this.props.ID
        var root = d3.hierarchy(this.props.hirericalData)
                        .sum(d => d.value)
                        .sort((a,b) => b.value - a.value)
                        // .eachAfter(d => d.index = d.parent ? d.parent.index = d.parent.index + 1 || 0 : 0);

        var barTextColor = '#000'
        if(this.props.barTextColor) {
            barTextColor = this.props.barTextColor
        }
       

        var barStep = 27,
            // barPadding = 3 / barStep,
            margin = this.props.margin,
            width = this.props.width - margin.left - margin.right,
            height = 150 + margin.top + margin.bottom;

        // var height = heightFunction()
        // height = height + margin.top + margin.bottom;

        var startPoint = parseInt(width / 4)
        if(startPoint > 100) {
            startPoint = 100
        }

        var barHeight = 20;
        
        var x = d3.scaleLinear()
            .range([0, width]);

        var color = d3.scaleOrdinal()
            .range(["#ccc"]);
            // .range(["#B59DD3", "#ccc"]);
            // .range(["steelblue", "#ccc"]);

        var colorsGradient = [ 'red', 'pink' ];
        if(this.props.gradient && this.props.gradientColors) {
            colorsGradient = this.props.gradientColors
        }

        var duration = 750,
            delay = 25

        var partition = d3.partition()

        var xAxis = d3.axisTop()
            .scale(x)
            .tickFormat(function (d) {
                if ((d / 1000) >= 1) {
                  d = d / 1000 + "K";
                }
                return d;
            });

        var svg = d3.select(ID).append("svg")
            .attr("id","hieraricharlId")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        var grad = svg.append('defs')
            .append('linearGradient')
            .attr('id', 'grad')
            .attr('x1', '0%')
            .attr('x2', '100%')
            .attr('y1', '0%')
            .attr('y2', '0%');

            grad.selectAll('stop')
            .data(colorsGradient)
            .enter()
            .append('stop')
            .style('stop-color', function(d){ return d; })
            .attr('offset', function(d,i){
                return 100 * (i / (colorsGradient.length - 1)) + '%';
            })

        svg.append("rect")
            .attr("class", "background")
            .attr("width", width)
            .attr("height", height)
            .attr("fill", this.props.backgroundColor ? this.props.backgroundColor : '#FFF')
            .on("click", (event, d) => up(d, 0))

        svg.append("g")
            .attr("class", "x axis")

        svg.append("g")
            .attr("class", "y axis")
            .append("line")
            .attr("y1", "100%");
        // svg.append("rect")
        //     .attr('id', "legend")
        //     .attr("x", width - 18)
        //     .attr("width", 18)
        //     .attr("height", 18)
        //     .style("fill", "red")
        //     .attr("stroke", "red")
        //     .attr("stroke-width", 2)
        //     .on("click", (event, d) => {
        //         // up(d, 0)
        //     });

        // svg.append("rect")
        //     .attr("class", "background")
        //     .attr('id', "background_btn")
        //     // .attr('id', function(d) { return d.data.State })
        //     .attr("width", 30)
        //     .attr("height", 30)
        //     .attr("y", -20)
        //     .attr("x", width - 30)
        //     // .on("click", up);
        //     .attr("fill", 'red')
        //     .on("click", (event, d) => up(d, 0))

        // svg.append("rect")
        //     // .attr("class", "background")
        //     // .attr('id', "background_top_rect")
        //     .attr("y", -50)
        //     .attr("x", width - 40)
        //     .attr("width", 40)
        //     .attr("height", 20)
        //     .attr("rx", 4)
        //     .attr("fill", "#ECECEC")
        //     // .attr("stroke","#000")
        //     // .on("click", (event, d) => up(d, 0))
        
        svg.append("text")
            .attr("class", "background")
            .attr('id', "background_text")
            .attr("y", -35)
            .attr("x", width - 30)
            .attr("width", 40)
            .attr("height", 20)
            .attr("stroke", this.props.backBtnColor ? this.props.backBtnColor : '#000')
            .attr("stroke-width", 0.5)
            .attr("font-size", "10px")
            .style("cursor", "pointer")
            .text('Back')
            .on("click", (event, d) => up(d, 0))

        let tooltip = d3.select(ID).append("text").attr("id", "tooltip")
        let tooltipLabel = d3.select(ID).append("text").attr("id", "tooltipLabel")
        let backBtn = d3.select("#background_text")

        let barLabelColor = this.props.barLabelColor ? this.props.barLabelColor : '#DEE2E6'

        partition(root);
        
        x.domain([0, root.value]).nice();
        
        down(root, 0);

        function heightFunction(type, child) {
            let length = 0
            if(type === 'up') {
                length = child.children.length
            } else {
                length = child.length
            }
            // let max = 1;
            // root.each(d => d.children && (max = Math.max(max, d.children.length)));
            let newHeight = length * barStep;
            // var svg = d3.select('#hieraricharlId')
            d3.select('#hieraricharlId')
              .attr("width", width + margin.left + margin.right)
              .attr("height", newHeight + margin.top + margin.bottom)
            return newHeight
            
        }

        // function barHeightFunction(type, child) {
        //     let length = 0
        //     if(type === 'up') {
        //     length = child.children.length
        //     } else {
        //     length = child.length
        //     }
        //     let max = 1;
        //     root.each(d => d.children && (max = Math.max(max, d.children.length)));
        //     let newHeight = 20

        //     if(length > 40) {
        //     newHeight = 7
        //     } else if(length > 40) {
        //     newHeight = 10
        //     } else if(length > 25) {
        //     newHeight = 12
        //     } else if(length > 20) {
        //     newHeight = 18
        //     }
        //     return newHeight
        // }

        function down(d, i) {  
            
            if (!d.children) return;
            if(!d.parent) {
                backBtn.style("display", "none")
            } else {
                backBtn.style("display", "block") 
            }

            height = heightFunction('down', d.children)
            
            // var react = d3.select("#background")
            //   .attr("width", width + margin.left + margin.right)
            //   .attr("height", height + margin.top + margin.bottom)

            // barHeight = barHeightFunction('down', d.children);
            if(d.children && d.children[0] && d.children[0].data && d.children[0].data.breadCrumb) {
                that.breadCrumbFunction(d.children[0].data.breadCrumb)
            }
            var end = duration + d.children.length * delay;
        
            // Mark any currently-displayed bars as exiting.
            var exit = svg.selectAll(".enter")
                .attr("class", "exit");
        
            // Entering nodes immediately obscure the clicked-on bar, so hide it.
            exit.selectAll("rect").filter(function(p) { return p === d; })
                .style("fill-opacity", 1e-6);
        
            // Enter the new bars for the clicked-on data.
            // Per above, entering bars are immediately visible.
            var enter = bar(d)
                .attr("transform", stack(i))
                .style("opacity", 1);
        
            // Have the text fade-in, even though the bars are visible.
            // Color the bars as parents; they will fade to children if appropriate.
            enter.select("text").style("fill-opacity", 1e-6);
            enter.select("rect").style("fill", 'url(#grad)') // .style("fill", color(true))
            .on("mouseover", function (actual, i) {
                d3.select('.enter').selectAll(`rect`).style('opacity', 0.5);
                d3.select(this).style('opacity', 1)
                tooltip.style("display", "block")
            })
            .on("mouseout", d => {     
                tooltip.style("display", "none")
                d3.select('#hieraricharlId')
                .selectAll(`rect`)
                .style('opacity', 1);
            })
            .on("mousemove", function(event, d) {
                let hoverContent = capitalizeFirstLetter(d.data.name)+' '+d.value
                tooltip.text(hoverContent).style('transform', `translate(${event.offsetX - 50}px, ${event.pageY - (height + 273)}px)`);
                
                // let xposition = d.data.name.length < 30 ? 100 : d.data.name.length > 40 ? 150 : 250
                // let yposition = height +(d.data.name.length > 50 ? 240 : d.data.name.length > 40 ? 260 : 273)
                // tooltip.text(hoverContent).style('transform', `translate(${event.offsetX - 50}px, ${event.pageY - yposition}px)`);

                // tooltip.text(hoverContent)
                // .style("left", (d3.pointers(this)[0]+70) + "px")
                // .style("top", (d3.pointers(this)[1] - height) + "px")
            });
            // enter.attr("d", function(d,i){ return rightRoundedRect(barHeight / 2, x(d.value === 0 ? d.data.value : d.value),x(d.value === 0 ? d.data.value : d.value),barHeight,15)});
            // Update the x-scale domain.
            x.domain([0, d3.max(d.children, function(d) { return d.value; })]).nice();
        
            // Update the x-axis.
            svg.selectAll(".x.axis").transition()
                .duration(duration)
                .call(xAxis)
                .attr('stroke', barLabelColor)
                // .attr('fill', '#DEE2E6')
                .attr('stroke-width', '.7');
        
            // Transition entering bars to their new position.
            var enterTransition = enter.transition()
                .duration(duration)
                .delay(function(d, i) { return i * delay; })
                .attr("transform", function(d, i) { return "translate(0," + barHeight * i * 1.2 + ")"; });
        
            // Transition entering text.
            enterTransition.select("text")
                .style("fill-opacity", 1);
        
            // Transition entering rects to the new x-scale.
            enterTransition.select("rect")
                .attr("width", function(d) { return x(d.value); })
                .style("fill", function(d) { if(!!d.children) { return 'url(#grad)' } else { return color(!!d.children); } });
        
            // Transition exiting bars to fade out.
            var exitTransition = exit.transition()
                .duration(duration)
                .style("opacity", 1e-6)
                .remove();
        
            // Transition exiting bars to the new x-scale.
            exitTransition.selectAll("rect")
                .attr("width", function(d) { return x(d.value); });
        
            // Rebind the current node to the background.
            svg.selectAll(".background")
                .datum(d)
            .transition()
                .duration(end);
        
            d.index = i;
        }
        
        function up(d) {
            if (!d || !d.parent) {
                backBtn.style("display", "none")
                return;
            }
           
            if(d.parent && d.parent.data && d.parent.data.children && d.parent.data.children[0].breadCrumb) {
                that.breadCrumbFunction(d.parent.data.children[0].breadCrumb)
            }
            
            // barHeight = barHeightFunction('up', d.parent)
            
            height = heightFunction('up', d.parent)
            
            var end = duration + d.children.length * delay;
        
            // Mark any currently-displayed bars as exiting.
            var exit = svg.selectAll(".enter")
                .attr("class", "exit");
        
            // Enter the new bars for the clicked-on data's parent.
            var enter = bar(d.parent)
                .attr("transform", function(d, i) { return "translate(0," + barHeight * i * 1.2 + ")"; })
                .style("opacity", 1e-6);
        
            // Color the bars as appropriate.
            // Exiting nodes will obscure the parent bar, so hide it.
            enter.select("rect")
                // .style("fill", function(d) { return color(!!d.children); })
                .style("fill", function(d) { if(!!d.children) { return 'url(#grad)' } else { return color(!!d.children); } })
                .on("mouseover", function (actual, i) {
                    d3.select('.enter').selectAll(`rect`).style('opacity', 0.5);
                    d3.select(this).style('opacity', 1)
                    tooltip.style("display", "block")
                })
                .on("mouseout", d => {     
                    tooltip.style("display", "none")
                    d3.select('#hieraricharlId')
                    .selectAll(`rect`)
                    .style('opacity', 1);
                })
                .on("mousemove", function(event, d) {
                    let hoverContent = capitalizeFirstLetter(d.data.name)+' '+d.value
                    tooltip.text(hoverContent).style('transform', `translate(${event.offsetX - 50}px, ${event.pageY - (height + 273)}px)`);
                })
            .filter(function(p) { return p === d; })
                .style("fill-opacity", 1e-6);
        
            // Update the x-scale domain.
            x.domain([0, d3.max(d.parent.children, function(d) { return d.value; })]).nice();
        
            // Update the x-axis.
            svg.selectAll(".x.axis").transition()
                .duration(duration)
                .call(xAxis);
        
            // Transition entering bars to fade in over the full duration.
            var enterTransition = enter.transition()
                .duration(end)
                .style("opacity", 1);
        
            // Transition entering rects to the new x-scale.
            // When the entering parent rect is done, make it visible!
            enterTransition.select("rect")
                .attr("width", function(d) { return x(d.value); })
                .on("end",function(p) { if (p === d) d3.select(this).style("fill-opacity", null); });
        
            // Transition exiting bars to the parent's position.
            var exitTransition = exit.selectAll("g").transition()
                .duration(duration)
                .delay(function(d, i) { return i * delay; })
                .attr("transform", stack(d.index));
        
            // Transition exiting text to fade out.
            exitTransition.select("text")
                .style("fill-opacity", 1e-6);
        
            // Transition exiting rects to the new scale and fade to parent color.
            exitTransition.select("rect")
                .attr("width", function(d) { return x(d.value); })
                // .style("fill", color(true));
                .style('fill', 'url(#grad)')
        
            // Remove exiting nodes when the last child has finished transitioning.
            exit.transition()
                .duration(end)
                .remove();
        
            // Rebind the current parent to the background.
            svg.selectAll(".background")
                .datum(d.parent)
            .transition()
                .duration(end);
        }

        // Creates a set of bars for the given data node, at the specified index.
        function bar(d) {
            var bar = svg.insert("g", ".y.axis")
                .attr("class", "enter")
                .attr("transform", "translate(0,5)")
            .selectAll("g")
                .data(d.children)
            .enter().append("g")
                .style("cursor", function(d) { return !d.children ? null : "pointer"; })
                .on("click", (event, d) => down(d, 0));
        
            bar.append("text")
            	.attr("x",  - 6)
            	.attr("y", barHeight / 2)
            	.attr("dy", ".35em")
                .attr("font-size", "10px")
                .attr("stroke", barTextColor)
                .attr("stroke-width", 0.5)
            	.style("text-anchor", "end")
                // .attr("id", function(d, index) { return 'bar_index_'+index })
            	.text(function(d) { return d.data.name; })
                .on("mouseover", function (actual, i) {  
                    tooltipLabel.style("display", 'block')
                })
                .on("mouseout", d => {     
                    tooltipLabel.style("display", 'none')
                })
                .on("mousemove", function(event, d) {
                    tooltipLabel.text(d.data.name).style('transform', `translate(${event.offsetX}px, ${event.offsetY - (height + 30)}px)`);
                });

            
            bar.append("rect")
                // .attr("x", startPoint)
                .attr("width", function(d) { return x(d.value === 0 ? d.data.value : d.value); })
                .attr("height", barHeight)
                
                // .attr("d", function(d,i){ return rightRoundedRect(barHeight / 2, x(d.value === 0 ? d.data.value : d.value), barHeight, x(d.value === 0 ? d.data.value : d.value),15)});

            return bar;
        }

        // function rightRoundedRect(x, y, width, height, radius) {
        //     return "M" + x + "," + y
        //          + "h" + (width - radius)
        //          + "a" + radius + "," + radius + " 0 0 1 " + radius + "," + radius
        //          + "v" + (height - 2 * radius)
        //          + "a" + radius + "," + radius + " 0 0 1 " + -radius + "," + radius
        //          + "h" + (radius - width)
        //          + "z";
        // }

        // function rectangle(x, y, width, height, radius){
        //     return "M" + (x + radius) + "," + y + "h" + (width - 2*radius) + "a" + radius + "," + radius + " 0 0 1 " + radius + "," + radius + "v" + (height - 2*radius) + "v" + radius + "h" + -radius + "h" + (2*radius - width) + "h" + -radius + "v" + -radius + "v" + (2*radius - height) + "a" + radius + "," + radius + " 0 0 1 " + radius + "," + -radius + "z";
        // };
        
        // A stateful closure for stacking bars horizontally.
        function stack(i) {
            var x0 = 0;
            return function(d) {
                var tx = "translate(" + x0 + "," + barHeight * i * 1.2 + ")";
                x0 += x(d.value);
                return tx;
            };
        }
    }

    breadCrumbFunction = (breadCrumb) => {
        this.setState({ breadCrumb })
    }
 
    render() {		
        return (
            <div className="hierarchicalChart">
                <div class={`d-flex ${this.props.breadCrumbColor ? this.props.breadCrumbColor : ''}`}>
                    {this.state.breadCrumb !== '' ?
                        splitString(this.state.breadCrumb, '_').map((item, index) => {
                            return(
                                // <a href="#" className={`${!index ? 'active' : ''}`}>{item}</a>
                                <div class={`f10`}>
                                    {item}{(index === (splitString(this.state.breadCrumb, '_').length - 1) ? '' : <span>&nbsp; - &nbsp;</span>)}
                                </div>
                            )
                        })
                    : null}
                </div>
                <div className="overflowHidden-y-scroll mt-n4" style={{"height": this.props.height}} >
                    <div id={this.props.ID} />
                </div>
            </div>
        )
    }
}
 
 /**
  * Type of the props used in the component
  */
 HierarchicalBarChart.propTypes = {}
 
 const mapStateToProps = state => {}
 
 export default connect(mapStateToProps, {})(withRouter(HierarchicalBarChart))