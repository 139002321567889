/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file RootCauseAnalysis.js
 * @author Prakash // on 07/10/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { URL_PATH } from '../../../config/urlPath'
import { AppWrapper } from '../../common/AppWrapper'

import { listMicroserviceRcaDetails } from '../../../actions/aiops/MicroServicesAction'

import RcaLeftSection from './RcaLeftSection'
import RcaRightSection from './RcaRightSection'

class RootCauseAnalysis extends Component {
    
    constructor(props) {

        super(props)
        this.scrollRef = React.createRef()
        
        this.state = {}
    }

    componentDidMount = () => {
        if (this.props.location && this.props.location.state && Object.keys(this.props.location.state).length !== 0) {
            this.setState({ microServiceRca: this.props.location.state.microServiceRca, showLoading: true },
                () => this.listMicroserviceRcaDetails()    
            )
        } else {
            this.props.history.push({
                pathname: URL_PATH.OBSERVABILITY_MICROSERVICES_RCA_LIST,
            })
        }
    }
    
    listMicroserviceRcaDetails = () => {
        let params = {
            "microservice_rca_id": this.state.microServiceRca.microservice_rca_id
        }

        this.props.listMicroserviceRcaDetails(params, (promise, response) => {
            if(promise) {
                this.setState({  showLoading: false })
            } else {
                this.setState({ showLoading: false })
            }
        })
    }

	render() {
		return (
            <div class={`main-content bg-muted`}>
                <div className={`loaderOverlay ${this.state.showLoading ? "" : "displayNone"}`}>
                    <div className="overlayEqualizerLoader">
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                    </div>
                </div>
                <div className={`container-fluid overflow-auto flex-grow-1`}>
                    <div className="row h-100">
                        <div className={`border-right col-md-6 p-0`}>
                            <RcaLeftSection 
                                microServiceRca = {this.state.microServiceRca}
                            />
                        </div>
                        <div className={`col-md-6`}>
                            <RcaRightSection 
                                microServiceRca = {this.state.microServiceRca}
                            />
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
 RootCauseAnalysis.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log("micro RootCauseAnalysis",state)
	return {
        microServicesRcaDetails: state.observability.observabilityMicroServicesRcaDetails ? state.observability.observabilityMicroServicesRcaDetails : {},
    }
}

export default AppWrapper(RootCauseAnalysis, mapStateToProps, {
    listMicroserviceRcaDetails
})