/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file RecommendationsLeftSection.js
 * @author Prakash // on 04/02/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/
import React, { Component  } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Search from '../../common/SearchComponent'
import SearchWithHiddenInput from '../../common/SearchWithHiddenInput'

import { momentConvertionUtcToLocalTime, capitalizeFirstLetter, getAccountNameFromId, getRegionName } from '../../../utils/utility'
import { listAllAccounts, listAllRegions } from '../../../actions/commonAction'
import { setCostPropsDetails, getCostRecommendations } from  '../../../actions/cost/CostAction'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'


class RecommendationsLeftSection extends Component {
	diagnosticsSlider = React.createRef()
    showByRef = React.createRef()

    constructor(props) {
        super(props)

        this.state = {
            minMaxHeight: "700px",
            expandMenuId: "",
            activeCollapseMenu: "",
            showLoading: true,
            recommendationType: "All",

            startRecord: 0,
            perPage: 1,
            currentPage: 1,

            activeTab: "Consolidated",
            showBy: "Resource"
        }
    }
    
    componentDidMount = () => {
        this.getCostRecommendations()

        window.addEventListener("resize", () => {
            this.getWindowSize()
        })

        let params = {}
        if(this.props.accounts && this.props.accounts.length) {
            let filterdAccounts = this.props.accounts
            // if(this.state.assetDetails && this.state.assetDetails.provider) {
            //     // filterdAccounts = this.props.accounts.filter(account => account.provider === this.state.assetDetails.provider.toLowerCase())
            // }
            this.setState({ accounts: filterdAccounts })
        } else {
            this.props.listAllAccounts(params, (promise, allAccounts) => {
                if (promise) {						
                    let filterdAccounts = allAccounts
                    // if(this.state.assetDetails && this.state.assetDetails.provider) {
                    //     // filterdAccounts = allAccounts.filter(account => account.provider === this.state.assetDetails.provider.toLowerCase())
                    // }
                    this.setState({ accounts: filterdAccounts })
                }
            })
        }

        if(this.props.regions && this.props.regions.length) {
            let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
            this.setState({ regions: providerRegions })
        } else {
            this.props.listAllRegions(params, (promise, regions) => {
                if (promise) {
                    let providerRegions = regions && regions[0] && regions[0].provider_regions
                    this.setState({ regions: providerRegions })
                } else {
                    this.setState({ regions: [] })
                }
            })
        }
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 150  
		

        let groupSection = 30
        if(this.state.groupedRecommendations && this.state.groupedRecommendations.length) {
            let groupRowsHeight = Math.ceil(this.state.groupedRecommendations.length / 5)
            groupRowsHeight = 44 * groupRowsHeight

            groupSection = groupSection + groupRowsHeight
        }
        let outerHeight = (window.outerHeight - browserBorder - headerHeight - groupSection) +"px"

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }

    getCostRecommendations = () => {
        let params = {}
        this.props.getCostRecommendations(params, (promise, response) => {
            if(promise) {
                this.setState({ recommendedDetails: response, showLoading: false, expandMenuId: "", activeCollapseMenu: "", filteredResouceArray: response.checks ? response.checks : [] },
                    () => {
                        // console.log("filteredResouceArray", this.state.filteredResouceArray)
                        // let obj = {}
                        // obj.label = "assets_list"
                        // obj.eventGroup = ""
                        // obj.data = {}
                        // this.props.setCostPropsDetails("selectedRecommendedEvent", obj)

                        this.structureRecommendationDetails()
                    }
                )
            } else {
                this.setState({ recommendedDetails: {}, showLoading: false, expandMenuId: "", activeCollapseMenu: "" })
            }
        })
    }

    structureRecommendationDetails = () => {
        let groupedRecommendations = []
        let totalRecommendedData = []
        let tableViewData = []
        if(this.state.recommendedDetails && Object.entries(this.state.recommendedDetails).length) {
            let filteredData = this.state.recommendedDetails.checks.filter(e => e.data.length)  //remove the diagnotstics which has now data
            if(filteredData && filteredData.length) {
                groupedRecommendations = _.chain(filteredData).groupBy("tag")
                .map((value, key) => ({ 
                    label: key ? key : "Not Grouped", 
                    sub: _.orderBy(value, ["flag"], ["desc"]),
                    observedFlag: value.filter(e => e.flag).length 
                })).value()


                groupedRecommendations = _.orderBy(groupedRecommendations, ["observedFlag"], ["desc"])    
            }

            //for table View section

            this.state.recommendedDetails && this.state.recommendedDetails.checks && this.state.recommendedDetails.checks.forEach(row => {
                if(row.data && row.data.length) {
                    row.data.forEach(item => {
                        item.label = row.label
                        item.tag = row.tag
                        // item.percentage_savings = row.percentage_savings
                        // item.price_unit = row.price_unit
                        // item.savings = row.savings
                        tableViewData.push(item)
                    })
                }
            })

            //for table ViewSection
            
            if(groupedRecommendations && groupedRecommendations.length) {
                totalRecommendedData = _.orderBy(filteredData, ["flag"], ["desc"])
            }
        }

        this.setState({ totalRecommendedData, groupedRecommendations, tableViewData, filteredTableArray: tableViewData },
            () => {
                this.filterRecommendations("All", this.state.totalRecommendedData)  
                this.getWindowSize()
            }
        )
    }

    filterRecommendations = (type, selectedArray) => {
        let selectedRecommendationGroup = ""
        if(type !== "All") {
            if(type === this.state.selectedRecommendationGroup) {
                selectedArray = this.state.totalRecommendedData
            } else {
                selectedRecommendationGroup = type
            }
        }

        // if(this.state.activeTab !== "Table") {
            let observedRecommendations = []
            selectedArray.forEach((item, index) => {
                this.setState({ ["recommendation_label_"+index]: item.label, ["recommendation_data_"+index]: item.data, ["filtered_recommendation_data_"+index]: item.data })
                let detailView = false
                if(item.data && item.data.length) {
                    detailView = true
                }
                item.detailView = detailView
                observedRecommendations.push(item)
            })
    
            // observedRecommendations = _.orderBy(observedRecommendations, ["orderBy"], ["asc"])
    
            observedRecommendations = [
                ...observedRecommendations.filter(e => e.flag),
                ...observedRecommendations.filter(e => !e.flag && e.detailView),
                ...observedRecommendations.filter(e => !e.flag && !e.detailView),
            ];
    
            this.setState({ selectedRecommendationGroup, observedRecommendations, searchData: observedRecommendations, filteredObservedRecommendation: observedRecommendations },
                () => {
                    this.filterTableResults()
                    this.filteredResourceTableResults()
                }
            )
        // }
    }

    filterResults = (event) => {
        let recommendationType = this.state.recommendationType
        if(event) {
            recommendationType = event.target.value
        }
        let observedRecommendations = this.state.observedRecommendations

        if(recommendationType !== "All") {
            if(recommendationType === "observed") {
                observedRecommendations = observedRecommendations.filter(e => e.flag)
            } else {
                observedRecommendations = observedRecommendations.filter(e => !e.flag)
            }
        }

        if(this.state.searchText && this.state.searchText !== "") {
			observedRecommendations =
				observedRecommendations &&
				observedRecommendations.filter(item => {
					let isPresent = []
					isPresent = this.recursiveSearch(item, this.state.searchText).flat(5).filter(bool => bool === true)
					if (isPresent[0]) {
						return true
					} else {
						return false
					}
				})
        }

        this.setState({ recommendationType, filteredObservedRecommendation: observedRecommendations })
    }

    filterTableResults = (event) => {
        let filteredTableArray = this.state.tableViewData

        if(this.state.selectedRecommendationGroup && this.state.selectedRecommendationGroup !== "All") {
            filteredTableArray = filteredTableArray.filter(e => e.category === this.state.selectedRecommendationGroup)
        }

        if(this.state.searchTableText && this.state.searchTableText !== "") {
			filteredTableArray =
				filteredTableArray &&
				filteredTableArray.filter(item => {
					let isPresent = []
					isPresent = this.recursiveSearch(item, this.state.searchTableText).flat(5).filter(bool => bool === true)
					if (isPresent[0]) {
						return true
					} else {
						return false
					}
				})
        }

        filteredTableArray = _.orderBy(filteredTableArray, ['start_time'], ['desc'])

        this.setState({ filteredTableArray },
            () => {
                if(filteredTableArray && filteredTableArray.length && this.state.activeTab === "Table" && this.state.showBy === "Resource") {
                    this.onChangeRecommendationEvent(filteredTableArray[filteredTableArray.length-1].label, filteredTableArray[filteredTableArray.length-1].tag, filteredTableArray[filteredTableArray.length-1], 0, "", filteredTableArray[filteredTableArray.length-1].asset_id)
                }
            }
        )

        // let filteredResourceTableArray = this.state.tableViewData

        // if(this.state.selectedRecommendationGroup && this.state.selectedRecommendationGroup !== "All") {
        //     filteredResourceTableArray = filteredResourceTableArray.filter(e => e.category === this.state.selectedRecommendationGroup)
        // }

        // if(this.state.searchTableText && this.state.searchTableText !== "") {
		// 	filteredResourceTableArray =
		// 		filteredResourceTableArray &&
		// 		filteredResourceTableArray.filter(item => {
		// 			let isPresent = []
		// 			isPresent = this.recursiveSearch(item, this.state.searchTableText).flat(5).filter(bool => bool === true)
		// 			if (isPresent[0]) {
		// 				return true
		// 			} else {
		// 				return false
		// 			}
		// 		})
        // }

        // console.log("filteredTableArray", filteredTableArray)

        // this.setState({ filteredResourceTableArray },
        //     // () => this.onChangeRecommendationEvent = (label, tag, data, index, selectedEvent, assetId)    
        // )
    }

    filteredResourceTableResults = (event) => {
        let filteredResouceArray = this.state.recommendedDetails.checks ? this.state.recommendedDetails.checks : []

        if(this.state.selectedRecommendationGroup && this.state.selectedRecommendationGroup !== "All") {
            filteredResouceArray = filteredResouceArray.filter(e => e.tag === this.state.selectedRecommendationGroup)
        }

        if(this.state.searchTableText && this.state.searchTableText !== "") {
			filteredResouceArray =
				filteredResouceArray &&
				filteredResouceArray.filter(item => {
					let isPresent = []
					isPresent = this.recursiveSearch(item, this.state.searchTableText).flat(5).filter(bool => bool === true)
					if (isPresent[0]) {
						return true
					} else {
						return false
					}
				})
        }

        this.setState({ filteredResouceArray },
            () => {
                if(filteredResouceArray && filteredResouceArray.length && this.state.activeTab === "Table" && this.state.showBy === "Service") {
                    this.onChangeServiceRecommendationEvent(filteredResouceArray[filteredResouceArray.length-1].label, filteredResouceArray[filteredResouceArray.length-1].tag, filteredResouceArray[filteredResouceArray.length-1])
                }
            }
            
        )
    }

	// function to search every object in array by recurseive
	recursiveSearch = (item, searchValue) => {
		return Object.keys(item).map(key => {
			if (item[key] !== null && typeof item[key] == "object") {
				return this.recursiveSearch(item[key], searchValue)
				// eslint-disable-next-line valid-typeof
			} else if (typeof item[key] === "array") {
				return item.map((arrayElement, i) => this.recursiveSearch(arrayElement[i], searchValue))
			} else {
				// if(this.props.searchFilter.includes(key)){
				return item[key] !== null
					? item[key]
							.toString()
							.toLowerCase()
							.indexOf(searchValue.toString().toLowerCase()) > -1
					: false
				// }else{
				// return false;
				// }
			}
		})
	}

    expandCollapse = (expandMenuId, label, tag) => {
        if(this.state.activeCollapseMenu !== expandMenuId) {
            this.onClickExpandMenu(expandMenuId, label, tag)
        } else {
            let obj = {}
            obj.label = "assets_list"
            obj.eventGroup = ""
            obj.data = this.state.recommendedDetails ? this.state.recommendedDetails : []
            this.props.setCostPropsDetails("selectedRecommendedEvent", obj)
        }
        this.setState({ 
            expandMenuId: expandMenuId === this.state.expandMenuId ? "" : expandMenuId, 
            activeCollapseMenu: expandMenuId === this.state.expandMenuId ? "" : expandMenuId,
            startRecord: 0,
            currentPage: 1,
        })
    }

    onClickExpandMenu = (expandMenuId, label, tag) => {
        if(this.state.observedRecommendations && Object.entries(this.state.observedRecommendations).length) {
            this.state.observedRecommendations && this.state.observedRecommendations.forEach((item, index) => {
                this.setState({ ["recommendation_label_"+index]: item.category, ["recommendation_data_"+index]: item.data, ["filtered_recommendation_data_"+index]: item.data },
                        () => {
                            if(index === expandMenuId) {
                                this.onChangeRecommendationEvent(item.label, item.tag, item.data[0], index, "recomm_"+expandMenuId+"_0", item.data[0].asset_id)
                            }
                        }
                    )
            })
        }
    }

    onChangeRecommendationEvent = (label, tag, data, index, selectedEvent, assetId) => {
        let assetDetails = {}

        if(data.asset_id && assetDetails.asset_id !== "") {
            if(!data.resource_type || data.resource_type === "") {
                data.resource_type = data.resource_type ? data.resource_type : ""
            }
            if(!data.service_name || data.service_name === "") {
                data.service_name = data.service_name ? data.service_name : ""
            }
            if(!data.provider || data.provider === "") {
                data.provider = data.provider ? data.provider : ""
            }
            if(!data.account_id || data.account_id === "") {
                data.account_id = data.account_id ? data.account_id : ""
            }
            if(!data.region || data.region === "") {
                data.region = data.region ? data.region : ""
            }
        }
        let obj = {}
        obj.label = label
        obj.eventGroup = tag
        obj.data = data
        obj.asset_details = assetDetails
        obj.showBy = this.state.showBy
        
        this.setState({ 
            selectedRecommandation: selectedEvent

        },
            () => this.props.setCostPropsDetails("selectedRecommendedEvent", obj)
        )
    }

    onChangeServiceRecommendationEvent = (label, tag, checks) => {
        let obj = {}
        obj.label = label
        obj.eventGroup = tag
        obj.data = checks.data
        obj.showBy = this.state.showBy

        this.props.setCostPropsDetails("selectedResourceRecommendedEvent", obj)
    }

    navigatePage = (action, currentPage, totalPages) => {
		let startRecord = this.state.startRecord
		if(action === "next" && currentPage !== totalPages) {
			startRecord = startRecord + this.state.perPage
			currentPage = currentPage + 1
		} else if(action === "previous" && currentPage !== 0) {
			currentPage = currentPage - 1
			startRecord = startRecord - this.state.perPage
		} else if(action === "start" && currentPage !== 0) {
			currentPage = 1
			startRecord = 0
		} else if(action === "end" && currentPage !== 0) {
			currentPage = totalPages
			startRecord = (totalPages - 1) * this.state.perPage
		}
		this.setState({ currentPage, startRecord })
    }    
	
	handleClickOutside(event) {
		
        if (this.state.activeTab === "Table" && this.showByRef && !this.showByRef.current.contains(event.target)) {
            this.setState({ showByOptions: false })
        } else if(this.state.activeTab === "Table") {
            this.setState({ showByOptions: true })
        }
	}

	render() {
		return (
            <div className="">
                <div className={`loaderOverlay ${this.state.showLoading ? "" : "displayNone"}`}>
                    <div className="overlayEqualizerLoader">
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                    </div>
                </div> 
                <div className="col-md-12 p-3 mt-2">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-self-center">
                            <div className="">
                                <h6 className="mb-1 w-100">Observed Recommendations</h6>
                                <p className="mb-0">Summary of analysis and observations of the chosen Cloud resources</p>
                            </div>
                        </div>
                        <div className="">
                            <p className="mb-1">
                                {this.state.diagnosticDetails && this.state.diagnosticDetails.provider ? this.state.diagnosticDetails.provider.toUpperCase() : null}
                                {this.state.diagnosticDetails && this.state.diagnosticDetails.account_id ? (" : ")+ getAccountNameFromId(this.state.diagnosticDetails.account_id, this.props.accounts) : null}
                                {this.state.diagnosticDetails && this.state.diagnosticDetails.region ?  (" : ")+this.state.diagnosticDetails.region : null}
                            </p>
                            <small className="mb-0 align-self-center text-info d-flex justify-content-end">{this.state.diagnosticDetails ? (this.state.diagnosticDetails.start_time && this.state.diagnosticDetails.end_time ? momentConvertionUtcToLocalTime(this.state.diagnosticDetails.start_time, "DD MMM HH:mm") +" - "+momentConvertionUtcToLocalTime(this.state.diagnosticDetails.end_time, "DD MMM HH:mm") : null) : null}</small>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 px-3">
                    <div className="mt-2 text-white">
                        <div className="d-flex justify-content-between border-bottom mb-2">
                            <ul className={`nav nav-tabs border-0 bg-muted shadow-none rounded mb-0`} id="myTab" role="tablist">
                                <li className="nav-item">
                                    <span className={`nav-link text-white cursorPointer ${this.state.activeTab === 'Consolidated' ? ' active' : ''}`} onClick={() => this.setState({ activeTab: 'Consolidated' })}>Consolidated View</span>
                                </li>
                                <li className="nav-item">
                                    <span className={`nav-link text-white cursorPointer  ${this.state.activeTab === 'Table' ? ' active' : ''}`} onClick={() => this.setState({ activeTab: 'Table', showBy: 'Resource' }, () => this.filterTableResults())}>Table View</span>
                                </li>
                            </ul>
                            <div className="w-50 align-self-center">
                                <div className="d-flex justify-content-end mb-n2">
                                    {this.state.activeTab !== "Table" ? 
                                        this.state.totalRecommendedData && this.state.totalRecommendedData.length ?
                                            <div className={`input-group ml-2 mb-0 bg-black5 mr-1 border-gray5 rounded-5 f12 align-self-center`}>
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text bg-transparent px-2 f12">
                                                        <i className="fal fa-search text-gray5 f12"></i>
                                                    </div>
                                                </div>
                                                <input
                                                    value={this.state.searchText}
                                                    onChange={e => {
                                                        this.setState({ searchText: e.target.value }, () => this.filterResults())
                                                    }}
                                                    type="text"
                                                    className={`form-control form-control-sm bg-transparent text-white pl-0 f12`}
                                                    placeholder="Search"
                                                />
                                            </div>
                                        : null
                                    :
                                        this.state.tableViewData && this.state.tableViewData.length ?
                                            <div className={`input-group ml-2 mb-0 bg-black5 mr-1 border-gray5 rounded-5 f12 align-self-center`}>
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text bg-transparent px-2 f12">
                                                        <i className="fal fa-search text-gray5 f12"></i>
                                                    </div>
                                                </div>
                                                <input
                                                    value={this.state.searchTableText}
                                                    onChange={e => {
                                                        this.setState({ searchTableText: e.target.value }, () => {
                                                            this.filterTableResults()
                                                            this.filteredResourceTableResults()
                                                        })
                                                    }}
                                                    type="text"
                                                    className={`form-control form-control-sm bg-transparent text-white pl-0 f12`}
                                                    placeholder="Search"
                                                />
                                            </div>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                        {this.state.groupedRecommendations && this.state.groupedRecommendations.length ?
                            <React.Fragment>
                            <div className="d-flex justify-content-between">
                                <p className="my-1 text-primary-color">Showing the observerd recommendations with <span className={`text-success`}>Savings  <span className="text-primary-color f12">{this.state.recommendedDetails && this.state.recommendedDetails.price_unit ? " ("+ this.state.recommendedDetails.price_unit+")" : ""}</span> of {this.state.recommendedDetails && this.state.recommendedDetails.savings && this.state.recommendedDetails.savings}<span className="text-primary-color f12">({this.state.recommendedDetails && this.state.recommendedDetails.percentage_savings && this.state.recommendedDetails.percentage_savings}%)</span> </span></p>
                                
                                {this.state.activeTab === "Table" ? 
                                    <div className="d-flex">
                                        <p className="mb-0 mr-2 mt-2 text-muted">show by</p>
                                        <div className="w-10 select-sm-bg curvedDropdown minWidth150" ref={this.showByRef}>
                                            <div className="dropdown">
                                                <span className="dropdown-toggle d-flex justify-content-between px-2 text-white" onClick={() => this.setState({ showByOptions: !this.state.showByOptions })}>
                                                    {this.state.showBy}
                                                    <span className="caret"></span>
                                                    {/* <i className="fas fa-angle-down"></i> */}
                                                </span>
                                                <ul className={`dropdown-menu p-0 ${this.state.showByOptions ? "show" : ''}`}>
                                                    <li 
                                                        onClick={() => this.setState({ showBy: "Resource", searchTableText: "", showByOptions: false, selectedRecommendationGroup: "All" }, () => {
                                                            this.filterTableResults()
                                                            this.filteredResourceTableResults()
                                                        })} 
                                                        value={"Resource"} 
                                                        className={`${this.state.showBy === "Resource" ? "selected" :  ""}`}
                                                    >
                                                        Resource
                                                    </li>
                                                    <li 
                                                        onClick={() => this.setState({ showBy: "Service", searchTableText: "", showByOptions: false, selectedRecommendationGroup: "All" }, () => {
                                                            this.filterTableResults()
                                                            this.filteredResourceTableResults()
                                                        })} 
                                                        value={"Service"} 
                                                        className={`${this.state.showBy === "Service" ? "selected" :  ""}`}
                                                    >
                                                        Service
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                : null}
                            </div>
                            {/* {this.state.showBy !== "Service" ? */}
                                <div className={`row ml-1 mt-1 mb-2`}>
                                    {this.state.groupedRecommendations.map((item, index) => {
                                        return(
                                            <div className={`w-20 mb-2 cursorPointer pr-2`}>
                                                <div className={`p-2 bg-dark rounded ${this.state.selectedRecommendationGroup === item.label ? "border-info" : item.observedFlag ? "success-border" : "border-purplishRed"}`} onClick={() => this.filterRecommendations(item.label, item.sub)}>
                                                    <div className="d-flex">
                                                        <i className={`mr-2 align-self-center fa fa-circle ${item.observedFlag ? "text-success" : "text-purplishRed"}`}></i>
                                                        <span className={`small ${item.observedFlag ? "text-success" : "text-purplishRed"}`}>{item.label && item.label !== "undefined" ? item.label : "Others"} <span className={`small text-primary-color`}>({item.sub && item.sub.length})</span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            {/* : null} */}
                            </React.Fragment>
                        : null}
                        <div className="d-flex">
                            <div className={`w-100`}>
                                <div className={`bg-dark3 p-2 overflowYAuto`} style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
                                    {this.state.alertListLoader ? 
                                        <div className="d-flex justify-content-center m-4">
                                            {/* <Spinner className="text-center" color="white" size="lg" /> */}
                                        </div>
                                    : 
                                    this.state.activeTab !== "Table" ? 
                                        this.state.filteredObservedRecommendation && this.state.filteredObservedRecommendation.length ?
                                            this.state.filteredObservedRecommendation.map((item, index) => {
                                                return(
                                                    <React.Fragment>
                                                    <div className={`p-2 ${index ? "border-top-black5" : ""} ${this.state.expandMenuId === index ? "bg-muted" : ""}`}> 
                                                        <div className={`mb-0 d-flex justify-content-between ${item.detailView ? "cursorPointer" : "disabled"}`} onClick={() => this.expandCollapse(index, item.label, item.tag)} data-toggle="collapse" data-target={"#collapse_"+index}aria-expanded="true" aria-controls={"collapse_"+index}>
                                                            <div><span className={`far ${this.state.expandMenuId === index ? "fa-minus-circle" : "fa-plus-circle"} mr-2`} ></span>{item.label}</div>
                                                            <div className="">
                                                                <span className={`${item.flag ? "text-success" : "text-purplishRed"}`}>Savings <span className="text-primary-color f12">{" ("+ item.price_unit+")"}</span> of {item.savings} <span className="text-primary-color f12">{" ("+ item.percentage_savings+"%)"}</span> </span>
                                                                {/* <span className={`far fa-exclamation-triangle ${item.flag ? "text-success" : "text-purplishRed"} ml-3`}></span> */}
                                                            </div>
                                                        </div>

                                                        {this.state.expandMenuId === index ?
                                                            <div className="mt-1">
                                                                <div className="accordion" id="accordionExample">
                                                                    <div className={`card bg-dark border-0 ${index !== this.state.observedRecommendations.length-1 ? "" : ""}`}>
                                                                        <div id={"collapse_"+index} className={`collapse ${this.state.expandMenuId === index ? "show" : ""}`} aria-labelledby={"heading_"+index} data-parent="#accordionExample">
                                                                            <div className="card-body">
                                                                                <div className="d-flex justify-content-between mt-1">
                                                                                    <div className="d-flex">
                                                                                        <p className="small mb-0 mr-2 mt-1">Showing {this.state["filtered_recommendation_data_"+index] && this.state["filtered_recommendation_data_"+index].length} {item.label} </p>
                                                                                    </div>
                                                                                    <div className="form-group mb-0">
                                                                                        <SearchWithHiddenInput
                                                                                            ID={"search_id_"+index}
                                                                                            data={this.state["recommendation_data_"+index]}
                                                                                            applyLiteDarkTags={true}
                                                                                            className="form-control-sm text-white4 bg-muted border-0"
                                                                                            hideInputSection={true}
                                                                                            searchClassName={"border-0"}
                                                                                            searchIconColor={"text-white4"}
                                                                                            topClassName={"mb-2"}
                                                                                            filteredData={(filteredArray) => {
                                                                                                this.setState({ 
                                                                                                    ["filtered_recommendation_data_"+index]: filteredArray,
                                                                                                    ["recommendation_count_"+index]: filteredArray.length   
                                                                                                })
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="container-fluid">
                                                                                    {this.state["filtered_recommendation_data_"+index] && this.state["filtered_recommendation_data_"+index].length ?
                                                                                        this.state["filtered_recommendation_data_"+index].map((dig, i) => {
                                                                                            return (
                                                                                                <div className={`row px-2 py-1 ${i ? "border-top" : ""} ${this.state.selectedRecommandation === "recomm_"+index+"_"+i ? "bg-muted disabled" : "cursorPointer"}`} onClick={() => this.onChangeRecommendationEvent(item.label, item.tag, dig, index, "recomm_"+index+"_"+i, dig.asset_id)}>
                                                                                                    <p className="mb-0">
                                                                                                        {dig.severity ?
                                                                                                            <span className={`mr-2 risk-${dig.severity ? dig.severity.toLowerCase() : ""}`}>{capitalizeFirstLetter(dig.severity)}</span>
                                                                                                        : null}
                                                                                                        {dig.event_time ?
                                                                                                            <span className="mb-0 mr-2 text-white f12 font-weight-bold text-primary-color">{dig.event_time ? momentConvertionUtcToLocalTime(dig.event_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                                                        : null}
                                                                                                        {dig.asset_name ?
                                                                                                            <span className="mb-0 mr-2 f12 text-white font-weight-bold">{dig.asset_name}</span>
                                                                                                        : null}
                                                                                                        <span className="mb-0 small mr-1 text-primary-color">Resource</span>
                                                                                                        {dig.account_id ?
                                                                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">{' '+dig.account_id}</span>
                                                                                                        : null}
                                                                                                        {dig.region ?
                                                                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+dig.region}</span>
                                                                                                        : null}
                                                                                                        {dig.resource_type ?
                                                                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+(Array.isArray(dig.resource_type) ? dig.resource_type.join(', ') : dig.resource_type)}</span>
                                                                                                        : null}
                                                                                                        {dig.service_name ?
                                                                                                            <span className="mb-0 f12 text-primary-color font-weight-bold mr-2">{' : '+dig.service_name}</span>
                                                                                                        : null}
                                                                                                        {dig.description ?
                                                                                                            <span className="mb-0 ml-1 f12 text-white font-weight-bold">{dig.description}</span>
                                                                                                        : null}
                                                                                                    </p>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        : null}
                                                    </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        :
                                            <div className="d-flex justify-content-center m-4">
                                                <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                            </div>
                                    :
                                        this.state.showBy === "Resource" ?
                                            this.state.filteredTableArray && this.state.filteredTableArray.length ?
                                                <ResizeableDarkThemeTable
                                                    columns={[
                                                        {
                                                            Header: "Service",
                                                            accessor: d => d.resource_type+" : "+d.service_name,
                                                            width: 200
                                                        },
                                                        {
                                                            Header: "Category",
                                                            accessor: "category",
                                                            width: 150
                                                        },
                                                        {
                                                            Header: "Savings",
                                                            // accessor: d => d.additional_details && d savings+" ("+d.price_unit && d.price_unit+"%)"+"  ("+d.percentage_savings && d.percentage_savings+"%)",
                                                            Cell: cellInfo => (
                                                                <div className='d-flex'>
                                                                    <p className="f12 m-0 text-success align-self-center mr-1">{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.current_features && cellInfo.row.original.additional_details.current_features.savings_billing_prev_month ? cellInfo.row.original.additional_details.current_features.savings_billing_prev_month : ''}</p>
                                                                    <p className="mb-0 text-primary-color f11 mr-1">{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.current_features && cellInfo.row.original.additional_details.current_features.price_unit ? cellInfo.row.original.additional_details.current_features.price_unit : ''}</p>
                                                                    <p className="mb-0 text-primary-color f11">{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.current_features && cellInfo.row.original.additional_details.current_features.percentage_savings_billing_prev_month ? "("+cellInfo.row.original.additional_details.current_features.percentage_savings_billing_prev_month+"%)" : ''}</p>
                                                                </div>
                                                            ),
                                                            width: 150
                                                        },
                                                        {
                                                            Header: "Asset",
                                                            accessor: "asset_name",
                                                            width: 200
                                                        },
                                                        {
                                                            Header: "Resources",
                                                            Cell: cellInfo => (
                                                                (cellInfo.row.original.provider ? cellInfo.row.original.provider.toUpperCase() : '') + ': ' + getAccountNameFromId(cellInfo.row.original.account_id, this.state.accounts) + ': ' + (cellInfo.row.original.region ? (cellInfo.row.original.region === 'NA' ? 'Global' : getRegionName(cellInfo.row.original.region, this.state.regions)) : '')
                                                            ),
                                                            width: 200
                                                        },
                                                        {
                                                            Header: "Description",
                                                            accessor: "description",
                                                            width: 600
                                                        }
                                                    ]}
                                                    data={this.state.filteredTableArray}
                                                    perPage={20}
                                                    dashboard={this.state.filteredTableArray && this.state.filteredTableArray.length ? true : false}
                                                    sortByColumn={"Date"}
                                                    riskTooltip={[0]}
                                                    onClickRow={tableRow => {
                                                        this.onChangeRecommendationEvent(tableRow.label, tableRow.tag, tableRow, 0, "", tableRow.asset_id)
                                                    }}
                                                    // defaultSelectedRecord={""}
                                                    // tableSize={"table-sm"}
                                                    // tableHead={"bg-dark"}
                                                    tooltipFor={[1,2]}
                                                />
                                            :
                                                <div className="d-flex justify-content-center m-4">
                                                    <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                                </div>
                                        : 
                                            this.state.filteredResouceArray && this.state.filteredResouceArray.length ?
                                                <ResizeableDarkThemeTable
                                                    columns={[
                                                        {
                                                            Header: "Service",
                                                            accessor: d => d.resource_type,
                                                            width: 200
                                                        },
                                                        {
                                                            Header: "Category",
                                                            accessor: "tag",
                                                            width: 150
                                                        },
                                                        {
                                                            Header: "Savings",
                                                            accessor: d => d.savings+" ("+d.price_unit && d.price_unit+")"+"  ("+d.percentage_savings && d.percentage_savings+"%)",
                                                            Cell: cellInfo => (
                                                                <div className='d-flex'>
                                                                    <p className="f12 m-0 text-success align-self-center mr-1">{cellInfo.row.original.savings ? cellInfo.row.original.savings : ''}</p>
                                                                    <p className="mb-0 text-primary-color f11 mr-1">{cellInfo.row.original.price_unit ? cellInfo.row.original.price_unit : ''}</p>
                                                                    <p className="mb-0 text-primary-color f11">{cellInfo.row.original.percentage_savings ? "("+cellInfo.row.original.percentage_savings+"%)" : ''}</p>
                                                                </div>
                                                            ),
                                                            width: 150
                                                        },
                                                        {
                                                            Header: "Description",
                                                            accessor: "description",
                                                            width: 600
                                                        }
                                                    ]}
                                                    data={this.state.filteredResouceArray}
                                                    perPage={20}
                                                    dashboard={this.state.filteredResouceArray && this.state.filteredResouceArray.length ? true : false}
                                                    sortByColumn={"Date"}
                                                    riskTooltip={[0]}
                                                    onClickRow={tableRow => {
                                                        this.onChangeServiceRecommendationEvent(tableRow.label, tableRow.tag, tableRow)
                                                    }}
                                                    // defaultSelectedRecord={""}
                                                    // tableSize={"table-sm"}
                                                    // tableHead={"bg-dark"}
                                                    tooltipFor={[1,2]}
                                                />
                                            :
                                                <div className="d-flex justify-content-center m-4">
                                                    <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                                </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}
/**
 * Type of the props used in the component
 */
RecommendationsLeftSection.propTypes = {
    getCostRecommendations: PropTypes.func,
    setCostPropsDetails: PropTypes.func,
    setCostPropsDetails: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
	// console.log("state", state)
	return {        
        accounts: state.filters.accounts,
        regions: state.filters.regions,
    }
}

export default connect(mapStateToProps, {
    getCostRecommendations,
    setCostPropsDetails,
	listAllAccounts,
	listAllRegions,
})(withRouter(RecommendationsLeftSection))