/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AutomationsRightSection.js
 * @author Prakash // on 02/06/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { UncontrolledTooltip } from 'reactstrap'
import { getAllTagsKeys, getAllTagsValues } from '../../../../actions/commonAction'
import { listRemediation } from '../../../../actions/aiops/RemediationAction'

import  { URL_PATH } from '../../../../config/urlPath'

import _ from 'lodash'
import { momentConvertionLocalToUtcTime, getAccountNameFromId, getRegionName } from '../../../../utils/utility'

import Search from '../../../common/SearchComponent'
import ScheduleAutomation from './ScheduleAutomation'
import { select } from 'd3'

const Categories = ["All", "compute", "Networking", "CDN", "Database", "Storage"]

class AutomationsRightSection extends Component {
    constructor(props) {
        super(props)
        this.toggleRef = React.createRef()
        
        this.state = {
            minMaxHeight: 700,
            showLoader: true,

            startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
            overAllTotalPages: 1,
			perPage: 10,

            pageType: "Events",
            groupType: 'All',
            groupedBy: 'Impact',
            selectedCategory: 'Important / All',
            minimizeGraphSection: false,

            selectedTab: "Automations"
        }
    }

	componentDidMount = () => {
		this.getWindowSize()
		window.addEventListener('resize', () => {
            this.getWindowSize()
        })
        // console.log("this.props.automationDetails", this.props.automationDetails)
        if(this.props.automationDetails && Object.entries(this.props.automationDetails).length) {
            this.setState({ 
                selectedAsset: this.props.automationDetails.selectedAsset,
                showLoader: true,
            },
                () => {
                    this.listRemediation()
                }
            )
        }
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
        let headerHeihgt = 100
        let otherHeight = 20
		let outerHeight = (window.outerHeight - browserBorder - headerHeihgt - otherHeight)

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.automationDetails && Object.entries(this.props.automationDetails).length) {
            if(!_.isEqual(prevProps.automationDetails, this.props.automationDetails)) {
                // console.log("this.props.automationDetails", this.props.automationDetails)
                let selectedTab = this.state.selectedTab
                this.setState({ 
                    selectedAsset: this.props.automationDetails.selectedAsset,
                    showLoader: true,
                    selectedTab: "",
                },
                    () => {
                        if(selectedTab !== "Schedule") {
                            this.setState({ selectedTab: "Automations" }, ( )=> this.listRemediation())
                        } else {
                            this.setState({ selectedTab: "Schedule" })                            
                        }
                    }
                )
            }
        }
    }

    listRemediation = () => {
        let params = {}
        // params.identifier_id = identifierId
        // params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartTime, "YYYY-MM-DD HH:mm:00")
        // params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndTime, "YYYY-MM-DD HH:mm:00")
        // params.provider = "aws"
        params.asset_id = this.state.selectedAsset && this.state.selectedAsset.asset_id ? this.state.selectedAsset.asset_id : ""
        // if(this.state.selectedAccount && !this.state.selectedAccount.includes("All")) {
        //     params.account_id = this.state.selectedAccount
        // }
        // if(this.state.selectedRegion && !this.state.selectedRegion.includes("All")) {
        //     params.region = this.state.selectedRegion
        // }

        this.props.listRemediation(params, (promise, response) => {
            if(promise) {
                let remediations = response.remediations ? response.remediations : []
                let assetDetails = response.asset_details ? response.asset_details : []
                this.setState({ remDetails: response, assetDetails, remediationsList: remediations, filteredRemediationList: remediations, showLoader: false },
                    () => {
                        // if(assetDetails.resolution_status_info && assetDetails.resolution_status_info.status === "resolved" && assetDetails.resolution_status_info && assetDetails.resolution_status_info.remediation_id) {
                            // this.getAutomationExecution(assetDetails.resolution_status_info.remediation_id)
                        // }
                    }
                )
            } else {
                this.setState({ showLoader: false, remDetails: {}, assetDetails: {}, remediationsList: [], filteredRemediationList: [] },
                    // () => this.hideLoading()    
                )
            }
        })
    }    

    nextSetOfRecord = () => {
        let lastEndRecord = this.state.endRecord
        let endRecord = this.state.endRecord + this.state.perPage
        this.setState({ endRecord },
            () => {
                if(lastEndRecord > this.state.filteredAssetList.length && this.state.nextErrorListToken !== "") {
                    this.listRemediation()
                } else if(lastEndRecord > this.state.filteredAssetList.length) {
                    this.setState({ endRecord, showLoadMore: false })
                } else {
                    if(endRecord > this.state.filteredAssetList.length) {
                        this.setState({ showLoadMore: false })
                    }
                }
            }
        )
    }

    filterResults = () => {
        let results = this.state.assetsList
        if(this.state.assetCategory && this.state.assetCategory.length) {
            let data = []
            this.state.assetCategory.forEach(cat => {
                if(results.filter(e=> e.category.includes(cat)).length) {
                    let categoryResults = results.filter(e=> e.category.includes(cat))
                    data = data.concat(categoryResults)
                    data = data.filter((item,index) => { return (data.indexOf(item) === index) })
                }

            })
            results = data
        }
        if(this.state.selectedService && this.state.selectedService.length) {
            results = results.filter(e => this.state.selectedService.includes(e.service_name))
        }
        
        if(results && results.length && this.state.tagsArray && this.state.tagsArray.length) {
            let tagMatchedData = []
            let recordExist = false
            this.state.tagsArray.forEach(item => {
                if(this.state["tag_key_"+item] && this.state["tag_key_"+item] !== "" && this.state["tag_value_"+item] && this.state["tag_value_"+item] !== "") {
                    recordExist = true
                    if(tagMatchedData && tagMatchedData.length) {
                        let newTagsMatched = []
                        tagMatchedData.forEach(itm => {
                            if(itm.tags && itm.tags.length && itm.tags.filter(e => e.key === this.state["tag_key_"+item] && e.value === this.state["tag_value_"+item]).length) {
                                newTagsMatched.push(itm)
                            }
                        })
                        tagMatchedData = newTagsMatched
                    } else {
                        results.forEach(itm => {
                            if(itm.tags && itm.tags.length && itm.tags.filter(e => e.key === this.state["tag_key_"+item] && e.value === this.state["tag_value_"+item]).length) {
                                tagMatchedData.push(itm)
                            }
                        })                        
                    }
                }
            })
            if(recordExist) {
                results = tagMatchedData
            }
        }

        if (this.state.searchText && this.state.searchText !== '') {
			results =
				results &&
				results.filter(item => {
					let isPresent = []
					isPresent = this.recursiveSearch(item, this.state.searchText)
						.flat()
						.filter(bool => bool === true)
					if (isPresent[0]) {
						return true
					} else {
						return false
					}
				})
		}

        let totalPages = 1
        if(results && results.length > this.state.perPage) {
            totalPages = Math.ceil(results.length / this.state.perPage)
        }
        let showLoadMore = false
        if(results.length > this.state.endRecord) {
            showLoadMore = true
        } 

        this.setState({ filteredAssetList: results, totalPages, startRecord: 0, currentPage: 1, showLoadMore })
    }

	// function to search every object in array by recurseive
	recursiveSearch = (item, searchValue) => {
		return Object.keys(item).map(key => {
			if (item[key] !== null && typeof item[key] == 'object') {
				return this.recursiveSearch(item[key], searchValue)
				// eslint-disable-next-line valid-typeof
			} else if (typeof item[key] === 'array') {
				return item.map((arrayElement, i) => this.recursiveSearch(arrayElement[i], searchValue))
			} else {
				// if(this.props.searchFilter.includes(key)){
				return item[key] !== null
					? item[key]
							.toString()
							.toLowerCase()
							.indexOf(searchValue.toString().toLowerCase()) > -1
					: false
				// }else{
				// return false;
				// }
			}
		})
	}
    
    searchData = (filteredAssetList) => {
        let totalPages = 1
        if(filteredAssetList && filteredAssetList.length > this.state.perPage) {
            totalPages = Math.ceil(filteredAssetList.length / this.state.perPage)
        }

        this.setState({ filteredAssetList, totalPages, currentPage: 1, startRecord: 0 },
            () => {
                if(filteredAssetList.length) {
                    // this.getAssetObservabiltyMetrics(filteredAssetList[0])
                }
            }
        )
    }

    addSection = (array) => {
        let rowList = this.state[array];
        if(this.state[array]) {
            let value = this.state[array][this.state[array].length - 1]
            value = value+1 
            rowList.push(value);
        }
        
        this.setState({[array]: rowList },
            // () => this.checkAllDataFilled()    
        )
    }

    removeSection = (item, i) => {
        this.setState({ ["tag_key_"+item]: "", ["tag_value_"+item]: "" },
            () => {
                let rowList = this.state.tagsArray;
                rowList.splice(i, 1);
                this.setState({ tagsArray: rowList })
            }
        )
    }

    resetFilterSelection = () => {
        this.state.tagsArray.forEach(item => {
            this.setState({ ["tag_key_"+item]: "", ["tag_value_"+item]: "" })
        })

        this.setState({ showFilterSection: !this.state.showFilterSection, tagsArray: [0], selectedService: [], assetCategory: [] },
            () => this.filterResults()    
        )
    }

    removefilter = (index, value, field) => {
        let filteredResult = this.state[field]
        filteredResult = this.state[field].filter(e => e !== value)
        
        // filteredResult = this.state[field].filter(e => e.key !== option.key && e.value !== option.value)
        this.setState({ [field]: filteredResult })
    }

    getAllTagsKeys = () => {
        let params = {}
        this.props.getAllTagsKeys(params, (promise, response) => {})
    }

    getAllTagsValues = (index) => {
        let params = {}
        params.tags = [{ "key": this.state["tag_key_"+index] }]
        this.props.getAllTagsValues(params, (promise, response) => {
            if(promise) {
                this.setState({ ["tagValues_"+index]: response })
            }
        })
    }

    redirectAutomation = (item) => {
        this.props.history.push({
            pathname: URL_PATH.RESOURCE_AUTOMATION,
            state: { 
                remediationAction: item,
                assetDetails: this.state.assetDetails
            }
        })
    }
    
	render() {
		return (
            <div className="col-sm-12 overflowYAuto" id="scrollTop" style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
                <div className={`loaderOverlay ${this.state.showLoader ? "" : 'displayNone'}`}>
                    <div className="overlayEqualizerLoader">
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                    </div>
                </div>
                <div className="row">
                    <ul className="nav nav-tabs border-bottom bd-highlight bg-dark shadow-none mb-2 w-100">
                        <li className="nav-item">
                            <span
                                className={`nav-link text-white cursor-pointer f12 ${this.state.selectedTab === "Automations" ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
                                onClick={() => this.setState({ selectedTab: "Automations" }, () => this.listRemediation())}
                            >
                            Automations
                            </span>
                        </li>
                        <li className="nav-item">
                            <span 
                                className={`nav-link text-white cursor-pointer f12 ${this.state.selectedTab === "Schedule" ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
                                onClick={() => this.setState({ selectedTab: "Schedule" })}
                            >
                                Schedule
                            </span>
                        </li>
                    </ul>
                </div>
                {this.state.selectedTab === "Schedule" ?
                    <div className={`col-sm-12 py-3 px-0`}>
                        <ScheduleAutomation 
                            selectedAsset={this.state.selectedAsset}
                            showLoader={() => this.setState({ showLoader: false })}
                        />
                    </div>
                :
                    <div className="row p-3">
                        <div className="d-flex justify-content-between mb-2 w-100">
                            <p className="p-0 m-0 align-self-center">Showing the list of Automation for the <span className="text-info ml-1">{this.state.selectedAsset && this.state.selectedAsset.asset_name ? this.state.selectedAsset.asset_name : ""}</span></p>
                            {this.state.remediationsList && this.state.remediationsList.length ?
                                <Search
                                    data={this.state.remediationsList}
                                    applyTags={false}
                                    applyLiteDarkTags={true}
                                    topClassName={'bg-black5 border-gray5 rounded-5 f12 w-30'}
                                    searchClassName={'px-2 f12'}
                                    searchIconColor={'text-gray5 f12'}
                                    searchPlaceHolder={'Search....'}
                                    className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                    filteredData={(filteredList) => this.setState({ filteredRemediationList: filteredList })}
                                />
                            : null}
                        </div>
                        {this.state.filteredRemediationList && this.state.filteredRemediationList.length ? 
                            this.state.filteredRemediationList.map((item, index) => {
                                return(
                                <div className={`col-sm-4 mb-3 ${(index+1) % 3 ? "pl-0" : "p-0"}`}>
                                    <div className={`font-weight-bold p-3 cursorPointer align-self-start border-black6 text-white`} onClick={() => this.redirectAutomation(item)}>{/*automationActionBox*/}
                                        <div className="d-flex">
                                            <i className={`mr-2 fa fa-check-circle ${this.state.selectedAction === "action_"+index ? "text-info" : ""}`}></i>
                                            <div className="w-100">
                                                <p className="align-self-center mb-0">{item.name}</p>
                                                <p className={`align-self-center mb-0 small wordBreakAll ${this.state.selectedAction === "action_"+index ? " text-primary-color" : " text-primary-color"}`}>{item.description ? "    "+item.description : <span>&nbsp;</span>}</p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                )
                            })
                        : 
                            <div className='d-flex justify-content-center m-4'>
                                <p>There are no data on this criteria. Please try adjusting your filter.</p>
                            </div>
                        }
                    </div>
                }
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
AutomationsRightSection.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('AutomationsRightSection',state)
	return {
        accounts: state.filters.accounts,
		regions: state.filters.regions,
        services: state.filters.services,
        tagKeys: state.filters.tagKeys,
        automationDetails: state.assets.resourcesPropsDetails && state.assets.resourcesPropsDetails.automationDetails ? state.assets.resourcesPropsDetails.automationDetails : {},
    }
}

export default connect(mapStateToProps, {
    listRemediation,
    getAllTagsKeys,
    getAllTagsValues
})(withRouter(AutomationsRightSection))