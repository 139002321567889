import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Checkbox from '../../../common/formFields/Checkbox'
import SelectField from '../../../common/formFields/SelectField'
import { apiToDevCall } from '../../../../actions/commonAction'
import { setAiopsPropsDetails } from '../../../../actions/aiops/AiopsAction'

class LambdaRuntimeVersion extends Component {

  constructor(props) {
    super(props);
    this.state = props || {};
    this.state = {
      validationMessage: '',
      parentValue: this.props.value,
      showLoading: true,
    }
    this.updateFormFieldValue = this.updateFormFieldValue.bind(this);
  }

  componentDidMount = () => {
    // this.props.selectedRecord && this.props.selectedRecord.parameters.map((item, index) => {
      // if(item.apiToCall && Object.entries(item.apiToCall).length) {
      //   // this.funcitonApiCall(item, index)
      //   let params = {}
      //   let keyRequest =  item.apiToCall.body && item.apiToCall.body.keys
      //   keyRequest && keyRequest.map(item => {
      //       params[item.key] = item.value
      //   })
  
      //   let valueRequest =  item.apiToCall.body && item.apiToCall.body.values
      //   valueRequest && valueRequest.map(item => {
      //       params[item.key] = item.value
      //   })
      //   let name = item.apiToCall.name
  
      //   this.props.apiToDevCall(name, params, (success, result) => {
      //     if (success) {
      //         let storageTypes = []
      //         result.length && result[0].results && result[0].results.map(res => {
      //           let storageRow = {}
      //           storageRow.description = res.description
      //           storageRow.options = res.volume_details.volume_name
      //           storageRow.volume_details = res.volume_details
      //           storageTypes.push(storageRow)
      //       })
      //       this.setState({ storageTypes, showLoading: false })
      //     }
      //   })
      // }
    // })

    this.setState({ showLoading: false })
  }

  updateFormFieldValue = (fieldName, fieldValue) => {
    let obj = this.props.aiopsRemediationParams
    obj[fieldName] = fieldValue
    this.props.setAiopsPropsDetails('remediationParams', obj)

    this.setState({ [fieldName]: fieldValue }, () => {
      this.props.formInputState()
    });
  };

  render() {
    return (
      !this.state.showLoading && this.props.selectedRecord && this.props.selectedRecord.parameters.map((item, index) => {
        return (
        <div className="row mt-2">
          <div className="col-md-12">
            <div className="form-group">
              <div className="d-flex justify-content-between">
                  {item.component !== 'checkbox' ? 
                      <label>{item.label}</label>
                  : null}
              </div>
              {item.component === 'text' ?
                  <SelectField
                    className="f13 p-0 form-control dark-select-theme col-sm-6"
                    recordSelection={this.updateFormFieldValue}
                    value={this.state[item.name+'_'+this.props.selectedRecord.processor_rule_id+'_'+index] ? this.state[item.name+'_'+this.props.selectedRecord.processor_rule_id+'_'+index] : ''}
                    name={item.name+'_'+this.props.selectedRecord.processor_rule_id+'_'+index}
                    label='Select'
                    placeholder="All"
                    required={item.mandatory ? true : false}
                    // unitLabel = {item.unit ? item.unit : ''}
                    selectOptions={this.state.storageTypes ? this.state.storageTypes : []}
                  />
              : item.component === 'checkbox' ?
                <Checkbox 
                  className="form-check-input"
                  labelClassName={'text-white'}
                  recordSelection={this.updateFormFieldValue}
                  value={this.state[item.name+'_'+this.props.selectedRecord.processor_rule_id+'_'+index] ? true : false}
                  name={item.name+'_'+this.props.selectedRecord.processor_rule_id+'_'+index}
                  label={item.label ? item.label : ''}
                />
              : null }
            </div>
          </div>
        </div>
        )
      })
    )
  }
};

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
 const mapStateToProps = state => {	
	// console.log('mapStateToProps', state)  
	return {
		accounts: state.filters.accounts,
        aiopsRemediationParams: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.remediationParams ? state.aiops.aiopsPropsDetails.remediationParams : {},
    }
}

/**
 * Type of the props used in the component
 */
 LambdaRuntimeVersion.propTypes = {  
  formInputState: PropTypes.func,
  apiToDevCall: PropTypes.func,
}


export default connect(mapStateToProps, {
  setAiopsPropsDetails,
  apiToDevCall
})(withRouter(LambdaRuntimeVersion))
