/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ListAutomations.js
 * @author Prakash // on 16/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
 import React, { Component } from 'react'
 import { connect } from 'react-redux'
 import { withRouter } from 'react-router-dom'
 
 import PropTypes from 'prop-types'
 import { CSVLink } from "react-csv";
 
 import { Spinner } from 'reactstrap'
 import { setAutomationPropsDetails } from '../../actions/commonAction'
 import { listAutomationPlans, editAutomation, enableDisableAutomation } from '../../actions/AutomationAction'
 import ResizeableDarkThemeTable from '../designComponents/Table/ResizeableDarkThemeTable'
 import Search from '../common/SearchComponent'
 import { momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord } from '../../utils/utility'
 import { URL_PATH } from '../../config/urlPath'
 import { store as CommonNotification } from 'react-notifications-component';
 
 class ListAutomations extends Component {
	 accountRef = React.createRef()
	 regionRef = React.createRef()
 
	 constructor(props) {
		 super(props)
 
		 this.state = {
			 showLoading: true,
 
			 automationType: "All",
			 triggerType: "All",
			 
			 automationList: [],
			 filteredArray: [],
 
			 selectedTableRow: {},
 
		 }
	 }
 
	 componentDidMount = () => {
		 this.listAutomationPlans()
	 }
 
	 listAutomationPlans = () => {
		 let params = {}
		 if(this.props.selectedProvider && this.props.selectedProvider !== "") {
			 params.provider = this.props.selectedProvider.toLowerCase()
		 }		
		 if(this.props.selectedAccount && this.props.selectedAccount.length) {
			 params.account_id = [this.props.selectedAccount[0]]
		 }
		 if(this.props.selectedRegion && this.props.selectedRegion.length) {
			 params.region = this.props.selectedRegion
		 }
		 if(this.props.automationType && this.props.automationType !== "All") {
			 params.automation_type = this.props.automationType
		 }		
		 if(this.props.triggerType && this.props.triggerType !== "All") {
			 params.trigger_type = this.props.triggerType
		 }
 
		 this.props.listAutomationPlans(params, (promise, response) => {
			 if(response) {
				this.setState({ automationList: response, filteredArray: response, showLoading: false },
					() => this.formHeader()
				)
			 }
		 })
	 }

	 formHeader = () => {
		 let dataResult = this.state.filteredArray && this.state.filteredArray.length ? this.state.filteredArray : []
		 if(dataResult.length) {
			 let headers = []
			 Object.entries(dataResult[0]).forEach(([key, value]) => {
				 if(typeof value === "string") {
					 let headerRow = {}
					 headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					 headerRow.key = key
					 headers.push(headerRow)
				 }
			 })
			 this.setState({ headers })
		 }
	 }
 
	 onClickEditAutomation = (selectedAutomation) => {
		 if(selectedAutomation.automation_type !== 'Remediation') {
			 let actions = selectedAutomation.actions ? selectedAutomation.actions : []
	 
			 let newAction = []
			 actions.forEach((act, i) => {
				 act.section = i+4
				 newAction.push(act)
			 })		
			 selectedAutomation.actions = newAction
		 }
 
		 
		 this.props.history.push({
			 pathname: URL_PATH.EDIT_AUTOMATION,
			 state: { selectedRule: selectedAutomation }
		 })
	 }
 
	 automaiotnUpdateStatus = (data) => {
		 this.setState({ 
			 ['enabled_'+data.processor_rule_id]: this.state['enabled_'+data.processor_rule_id] ? (this.state['enabled_'+data.processor_rule_id] === "Enabled" ? 'Disabled' : "Enabled") : (data.enabled ? 'Disabled' : "Enabled")
		 }, 
			 () => {
				 let params = {}
				 params.enabled = this.state['enabled_'+data.processor_rule_id] === "Enabled" ? true : false
				 params.processor_rule_id = data.processor_rule_id
				 this.props.enableDisableAutomation(params, (promise, response) => {
					 let messageType = ""
			 
					 if(promise) {
						 messageType = 'success'
					 } else {
						 messageType = 'danger'
						 this.setState({ 
							 ['enabled_'+data.processor_rule_id]: this.state['enabled_'+data.processor_rule_id] ? (this.state['enabled_'+data.processor_rule_id] === "Enabled" ? 'Disabled' : "Enabled") : (data.enabled ? 'Disabled' : "Enabled")
						 })	
					 }
					 let message = response && response.message ? response.message : 'Error in inserting the status '
		 
					 CommonNotification.addNotification({
						 message: message,
						 type: messageType,
						 insert: "top",
						 container: "top-center",
						 dismiss: {
							 duration: 5000,
							 onScreen: false,
							 pauseOnHover: true,
							 showIcon: true,
						 }
					 });
 
					 // if(messageType === 'success') {
					 // 	this.props.history.push({
					 // 		pathname: URL_PATH.LIST_AUTOMATION,
					 // 	})
					 // }
				 })
			 }
		 )
 
		 
	 }
 
	 /**
	  * Renders governance Dashboard
	 */
	 render() {
		 return (
			 <div className=''>
				 <div className="d-flex justify-content-between pb-1">
					 <p className="mb-0 align-self-center">Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.state.automationList && this.state.automationList.length} automations</p>
					 {this.state.automationList && !this.state.automationList.length ?
						<div className="d-flex">
							<CSVLink 
								data={this.state.filteredArray ? this.state.filteredArray : []} 
								headers={this.state.headers ? this.state.headers : []}
								filename={"automations.csv"}
								className={"mt-2"}
								target="_blank"
							>
								<span className="d-flex text-primary-color mr-2">
									<i className="fas fa-download text-primary-color mr-2"></i>
									{/* csv */}
								</span>
							</CSVLink>
							<div className={`mb-n2 ${this.state.showLoading ? 'disabled' : ''}`}>
								<Search
									data={this.state.automationList}
									filteredData={searchResult => {
										this.setState({ filteredArray: searchResult })
									}}
								/>
							</div>
						</div>
					: null}
				 </div>
				 {this.state.automationList && this.state.automationList.length && !this.state.showLoading ? (
					 <ResizeableDarkThemeTable
						 columns={[
							 {
								 Header: "Date",
								 accessor:  d => d.modified_at ? d.modified_at : d.created_at,
								 Cell: cellInfo => cellInfo.row.original.modified_at && cellInfo.row.original.modified_at !== "" ?  momentConvertionUtcToLocalTime(cellInfo.row.original.modified_at, 'DD MMM YYYY HH:mm') : momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, 'DD MMM YYYY HH:mm')
							 },
							 {
								 Header: "Trigger",
								 accessor: "trigger_type",
								 Cell: cellInfo => (
									 <div className="d-flex"> 
										 <small className={`align-self-center badge risk-badge-${cellInfo.row.original.trigger_source === "Alert" ? "high" : "low"}`}>{cellInfo.row.original.trigger_source}</small>
										 <span className="ml-3">{cellInfo.row.original.trigger_type}</span>
									 </div>
								 ),
								 width: 250
							 },
							 {
								 Header: "Execution",
								 accessor: "execution",
								 width: 100
							 },
							 {
								 Header: "Provider/Account",
								 accessor: d => (d.provider + ': ' + d.account_id + ': ' + d.region === 'NA' ? 'Global' : d.region),
								 Cell: cellInfo => (
									 cellInfo.row.original.provider.toUpperCase() + ': ' + cellInfo.row.original.account_id + ': ' + (cellInfo.row.original.region ? (cellInfo.row.original.region === 'NA' ? 'Global' : cellInfo.row.original.region) : '')
								 ),
								 width: 300
 
							 },
							 {
								 Header: "Name",
								 accessor: "name",
								 Cell: cellInfo => (
									 <div className="d-flex"> 
										 <small className={`align-self-center badge risk-badge-${cellInfo.row.original.automation_type.toLowerCase() === "application" ? "critical" : "medium"}`}>{cellInfo.row.original.automation_type.toLowerCase() === 'application' ? "App" : "Rem"}</small>
										 <span className="ml-3">{cellInfo.row.original.name}</span>
									 </div>
								 ),
								 width: 350
							 },
							 {
								 Header: "Description",
								 accessor: "description",
								 width: 500
							 },
							 // {
							 // 	Header: 'Status',
							 // 	accessor: 'enabled',
							 // 	Cell: cellInfo => (
							 // 		<span
							 // 			className={`badge status-badge badge-outline-${
							 // 				cellInfo.row.original.enabled ?'success-black' : 'danger-black'
							 // 				} badge-pill`}
							 // 		>
							 // 			{cellInfo.row.original.enabled ? 'Enabled' : 'Disabled'}
							 // 		</span>
							 // 	),
							 // },
							 {
								 Header: 'Actions',
								 Cell: cellInfo => (
									 <div className="d-flex justify-content-between align-self-center">
										 {/* <p className="text-white m-0 p-0 mr-1 cursorPointer"><i className="fal fa-book-open"></i></p> */}
										 <p className="text-white m-0 p-0 mr-1 cursorPointer"><i className="fas fa-pencil" onClick={() => this.onClickEditAutomation(cellInfo.row.original)}></i></p>
										 <p className="text-white m-0 p-0 mr-1 cursorPointer"><i className="fal fa-trash"></i></p> 
										 <div className="toggleOnOffSwitchTable d-flex mr-1 align-self-center" onClick={() => this.automaiotnUpdateStatus(cellInfo.row.original)}>
											 <label className="switch mb-0" id={cellInfo.row.original.processor_rule_id} >
												 <input type="checkbox" 
													 className="inputdemo" 
													 checked={this.state['enabled_'+cellInfo.row.original.processor_rule_id] ? (this.state['enabled_'+cellInfo.row.original.processor_rule_id] === 'Enabled' ? true : false) : cellInfo.row.original.enabled}
												 />
												 <span className="slider round"></span>
											 </label>
										 </div>
										 
										 <span className="small">{this.state['enabled_'+cellInfo.row.original.processor_rule_id] ? this.state['enabled_'+cellInfo.row.original.processor_rule_id] : (cellInfo.row.original.enabled ? 'Enabled' : "Disabled")}</span>
										 {/* <p className="text-white m-0 p-0 mr-1 cursorPointer">
											 {this.state.showEnableSpinner && cellInfo.row.original.rule_id === this.state.showEnableSpinner ?
												 <Spinner className='text-center' color='white' size='sm' />
											 :
												 cellInfo.row.original.enabled ? <i className="fal fa-thumbs-up"></i> : <i className="fal fa-thumbs-down"></i>
											 }
										 </p> */}
									 </div>
								 )
							 }
						 ]}
						 data={this.state.filteredArray && this.state.filteredArray}
						 perPage={20}
						 // tableHead={'bg-dark'}
						 sortByColumn={'Date'}
						 dashboard
						 tooltipFor={[1, 3, 4]}
						 onClickRow={tableRow => {}}
						 manualWidth={true}
					 />
				 ) : (!this.state.showLoading && !this.props.rules) ||
					 (this.props.rules && !this.props.rules.length && !this.state.showLoading) ? (
					 <div className='d-flex justify-content-center m-4'>
						 <p>There are no data on this criteria. Please try adjusting your filter.</p>
					 </div>
				 ) : null}
				 {this.state.showLoading ? (
					 <div className='d-flex justify-content-center m-4'>
						 <Spinner className='text-center' color='white' size='lg' />
					 </div>
				 ) : null}
			 </div>
		 )
	 }
 }
 
 /**
  * Type of the props used in the component
  */
 ListAutomations.propTypes = {}
 
 /**
  * Map all reducer state to the props of the component
  * @param {Object} state
  */
 const mapStateToProps = state => {
	 // console.log('-',state)
	 return {
		 automationPropsDetails: state.filters.automationPropsDetails,
	 }
 }
 
 export default connect(mapStateToProps, {
	 setAutomationPropsDetails,
	 listAutomationPlans,
	 editAutomation,
	 enableDisableAutomation
 })(withRouter(ListAutomations))