/*************************************************
 * Tvastar
 * @exports
 * @file QueryAction.js
 * @author Prakash // on 27/10/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'

import {
DLPENGINEENDPOINT,
DLP_QUERY_INVOKE_QUERY,
DLP_QUERY_GET_QUERY_RESULT,
DLP_QUERY_LIST_QUERY_LOG, 

DLP_QUERY_DOWNLOAD_QUERY_RESULT,
} from '../../config'

/**
 * Action to invoke query
 * @param {Object} body
 * @param {Function} callback
 */

export const invokeQuery = (body, callback) => {
return async dispatch => {
	try {
		const response = await API.post(DLPENGINEENDPOINT, DLP_QUERY_INVOKE_QUERY, { body })
		// dispatch({
		// 	type: ACTION_TYPES.SET_DLP_CONFIGURATION_LIST_CUSTOMER_ACCOUNT,
		// 	payload: response,
		// })
		callback(true, response)
	} catch (error) {
		// dispatch({
		// 	type: ACTION_TYPES.SET_DLP_CONFIGURATION_LIST_CUSTOMER_ACCOUNT,
		// 	payload: [],
		// })
		callback(false, {})
	}
}
}

/**
 * Action to get query result
 * @param {Object} body
 * @param {Function} callback
 */

export const getQueryResult = (body, callback) => {
return async dispatch => {
	try {
		const response = await API.post(DLPENGINEENDPOINT, DLP_QUERY_GET_QUERY_RESULT, { body })
		callback(true, response)
	} catch (error) {
		callback(false)
	}
}
}

/**
 * Action to list query log
 * @param {Object} body
 * @param {Function} callback
 */

export const listQueryLog = (body, callback) => {
return async dispatch => {
	try {
		const response = await API.post(DLPENGINEENDPOINT, DLP_QUERY_LIST_QUERY_LOG, { body })
		callback(true, response)
	} catch (error) {
		callback(false, {})
	}
}
}

/**
 * Action to download query
 * @param {Object} body
 * @param {Function} callback
 */

export const downlaodQueryResult = (body, callback) => {
// return async dispatch => {
//     try {
// 		console.log(DLP_ENGINE_END_POINT, DLPENGINEENDPOINT)
//         const apiUrl = DLP_ENGINE_END_POINT + DLP_QUERY_DOWNLOAD_QUERY_RESULT;

//         let contentType = 'application/octet-stream'
//         const download = await axios.post(apiUrl, body, {
//             headers: {
//                 'Content-Type': contentType,
//                 Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}`
//             }
//         })

// 		console.log(download)

//         let data = download.data;
//         const url = window.URL.createObjectURL(new Blob([data])); 
//         const link = document.createElement('a');
//         link.href = url;
//         link.setAttribute('download', 'report.'+body.response_format); //or any other extension
//         document.body.appendChild(link);
//         link.click();
//         callback(true, download)
//     } catch (error) {
//         let respone = {}
//         respone.message = error.response.data.message
//         respone.status = error.response.data.status
//         callback(false, respone)
//     }
// }

return async dispatch => {
	try {
		const response = await API.post(DLPENGINEENDPOINT, DLP_QUERY_DOWNLOAD_QUERY_RESULT, { body })
		callback(true, response)
	} catch (error) {
		callback(false, {})
	}
}
}