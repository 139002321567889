/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file compilanceDasboard.js
 * @author Prakash // on 5/06/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { AppWrapper } from '../../common/AppWrapper'
import PropTypes from 'prop-types'
import { Spinner, UncontrolledTooltip } from 'reactstrap' 
import _ from 'lodash'

import { DURATION_FILTER_OPTIONS } from '../../../utils/constants'
import {  getAccountNameFromId, getRegionName, momentConvertionUtcToLocalTime, momentDateFormatLocal } from '../../../utils/utility'

import { 
    listAllProviders, 
    listAllAccounts, 
    listAllRegions
} from '../../../actions/commonAction'

import { 
    listReports,
    getSchedulReports,
    updateReports,
    deleteReports,
    listGeneratedReports,
    downloadReport
} from '../../../actions/dlp/reportAction'


import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

import Search from '../../common/SearchComponent'

import SidePanel from './SidePanel'
import { propTypes } from 'react-notification-system/dist/NotificationContainer'
// import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip'

let timer = null;
class DlpReport extends Component {
    accountRef = React.createRef()
    regionRef = React.createRef()
    serviceRef = React.createRef()
    searchComponentRef = React.createRef()

    constructor(props) {
        super(props)
        this.state = {
            showLoading: false,
            pageType: '',
            
            providers: [],
            selectedProvider: '',
            accounts: [],
            accountArray: [],
            selectedAccount: [],
            selectedAccountName: [],
            isAccountOpen: false,

            regions: [],
            selectedRegion: [],
            selectedRegionName: [],
            isRegionOpen: false,
            selectedFilterPeriod: 90,
            
            reportCategory: [],
            reportData: [],
            filteredData: [],
            activeTab: 'Generated Report',
            generatedData: [],

            //pagination
			perPage: 3,

            //report list pagination            
            startRecord: 0, 
			currentPage: 1,
			totalPages: 1,

            //generated list pagination
            generatedStartRecord: 0,
            generatedCurrentPage: 1,
            generatedTotalPages: 1,
        }
    }
    
    componentDidMount = () => {	
        this.setState({ showLoading: true })
        if(this.props.providers && this.props.providers.length) {
			this.setState({ providers: this.props.providers, selectedProvider: this.props.providers[0].provider_name },
				() => {
					this.filterDlp('provider')
				}
			)
		} else {
			this.props.listAllProviders((promise, providers) => {
				if (promise) {
					this.setState({ providers, selectedProvider: providers[0].provider_name },
						() => {
							this.filterDlp('provider')
						}
					)
				} else {
					this.setState(
						{ providers: [],  }, 
						() => {
							this.filterDlp()
						}
					)
				}
			})
		}
    }

    componentWillUnmount () {
        clearTimeout(timer)
    }

    filterDlp = from => {
        this.setState({ showLoading: true })
        let params = {}

        if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'all') {
            params.provider = this.state.selectedProvider.toLowerCase()
        }

        if (from === 'provider') {
			if(this.props.accounts && this.props.accounts.length) {
				let allAccounts = this.props.accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
				if(this.props.accounts.length) {
					let accRow = {}
					accRow.account_id = "All"
					accRow.account_name = "All"
					accRow.date = null
					accRow.disabled = false
					accRow.provider = "All"
					allAccounts.unshift(accRow);
				}
				this.setState({ accounts: allAccounts })
			} else {
				this.props.listAllAccounts(params, (promise, allAccounts) => {
					if (promise) {						
						let filterdAccounts = allAccounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
						if(filterdAccounts.length) {
							let accRow = {}
							accRow.account_id = "All"
							accRow.account_name = "All"
							accRow.date = null
							accRow.disabled = false
							accRow.provider = "All"
							filterdAccounts.unshift(accRow);
						}						
						this.setState({ accounts: filterdAccounts })
					}
				})
			}

			if(this.props.regions && this.props.regions.length) {
				let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
				this.setState({ regions: providerRegions })
			} else {
				this.props.listAllRegions(params, (promise, regions) => {
					if (promise) {
						let providerRegions = regions && regions[0] && regions[0].provider_regions
						if(providerRegions) {
							let provider_regions_row = {}
							provider_regions_row.availability_zones = []
							provider_regions_row.city = "All"
							provider_regions_row.country = "All"
							provider_regions_row.geography = "All"
							provider_regions_row.name = "All"
							provider_regions_row.region = "All"
							provider_regions_row.region_id = "All"
							provider_regions_row.state = "All"
							providerRegions.unshift(provider_regions_row);
						}
						this.setState({ regions: providerRegions })
					} else {
						this.setState({ regions: [] })
					}
				})
			}
		}
        // this.formReportData()
        this.onLoadFunction()
    }

    onLoadFunction = () => {
        this.listReports()
        this.getSchedulReports()
        this.listGeneratedReports()
    }

    listReports = () => {
        let params = {}
        this.props.listReports(params, (promise, response) => {
            if(promise) {
                let reportCategory = []
                let groupedObj = {} 
                _.chain(response)
                .groupBy('compliance_id')
                .map((value, key) => ({ label: key, sub: value }))
                .value()
                .forEach((data, i) => {
                    groupedObj[data.label] = data.sub
                    reportCategory.push(data.label)

                    data.sub.forEach(item => {
                        this.setState({ 
                            [item.report_id+'_favourite']: item.is_favourite,
                            [item.report_id+'_scheduled']: item.is_scheduled,
                            [item.report_id+'_enabled']: item.is_enabled,
                        })
                    })
                })

                let totalPages = 1
                if(response.length > this.state.perPage) {
                    totalPages = Math.ceil(response.length / this.state.perPage)
                }

                // let scheduledData = response.filter(arr => arr.is_scheduled)
                // let favouriteData = response.filter(arr => arr.is_favourite)
                this.setState({ reportData: response, filteredData: response, reportCategory, totalPages })
            }
        })
    }

    getSchedulReports = () => {
        let params = {}
        this.props.getSchedulReports(params, (promise, response) => {
            if(promise) {
                this.setState({ scheduledData: response, filteredScheduledData: response })
            }
        })
    }

    listGeneratedReports = () => {
        let params = {}
        this.props.listGeneratedReports(params, (promise, response) => {
            if(promise) {
                let totalPages = 1
                if(response.length > this.state.perPage) {
                    totalPages = Math.ceil(response.length / this.state.perPage)
                }
                this.setState({ generatedData: response, filteredGeneratedData: response, generatedTotalPages: totalPages },
                    () => this.checkForPedingReport()
                )
            }
        })
    }

    checkForPedingReport = () => {
        let getPendingReport = this.state.generatedData.filter(e => (e.current_status !== 'completed' && e.current_status !== 'failed'))
        if(getPendingReport.length) {
            timer = setTimeout(() => this.listGeneratedReports(), 5000)
        } else {
            clearTimeout(timer)
        }
    }

    handleMultiSelectChange = (childKey, arrayValue) => {
        this.setState({ changesInFilter: true})
        let value = arrayValue.map(item => item.value)
        let label = arrayValue.map(item => item)		
        if(childKey === 'selectedAccount') {
            this.setState({ selectedAccount: value, selectedAccountName: label })
        } else if(childKey === 'selectedRegion') {
            if(value !== '' && (value.includes("All") || this.state.selectedRegion.includes("All"))) {
                value = ['All']
                label = {}
                label.value = 'All'
                label.label = 'All'
                this.setState({ selectedRegion: ['All'], selectedRegionName: [label] })
            } else {
                this.setState({ selectedRegion: value, selectedRegionName: label})
            }
        }
    }
        
    handleClickOutside(event) {
        
        if (this.accountRef && !this.accountRef.current.contains(event.target)) {
            this.setState({ isAccountOpen: false })
        } else {
            this.setState({ isAccountOpen: true })
        }

        if (this.regionRef && !this.regionRef.current.contains(event.target)) {
            this.setState({ isRegionOpen: false })
        } else {
            this.setState({ isRegionOpen: true })
        }
    }

    filterReport = () => {
        let filteredData = this.state.searchResult
        if(!filteredData || !filteredData.length) {
            filteredData = this.state.reportData
        }

        if(this.state.is_scheduled) {
            filteredData = filteredData.filter(e => e.is_scheduled === true)
        }

        if(this.state.is_favourite) {
            filteredData = filteredData.filter(e => e.is_favourite === true)
        }

        if(this.state.selectedReportCategory && this.state.selectedReportCategory !== 'All') {
            filteredData = filteredData.filter(e => e.compliance_id === this.state.selectedReportCategory)
        }

        let totalPages = 1
        if(filteredData.length > this.state.perPage) {
            totalPages = Math.ceil(filteredData.length / this.state.perPage)
        }
        
        this.setState({ filteredData, startRecord: 0, currentPage: 1, totalPages })
    }

	updateReports = (report_id) => {
		this.setState({ [report_id+'_showUpdateLoading']: true, disableActioninPage: true })
        let params = {}
        params.report_id = report_id
        params.is_favourite = this.state[report_id+'_favourite']

        this.props.updateReports(params, (promise, result) => {
            if(promise) {
                setTimeout(() => {
                    this.listReports()
                    this.setState({ [report_id+'_showUpdateLoading']: false, disableActioninPage: false })
                }, 500)
            } else {
                this.setState({ [report_id+'_showUpdateLoading']: false, disableActioninPage: false })
            }
        })
	}

    deleteReports = (report_id) => {
		this.setState({ [report_id+'_showDeleteLoading']: true, disableActioninPage: true })
        let params = {}
        params.report_id = report_id

        this.props.deleteReports(params, (promise, result) => {
            if(promise) {
                this.setState({ [report_id+'_showDeleteLoading']: false, disableActioninPage: false },
                    () => {
                        setTimeout(() => {
                            this.listReports()
                        }, 500)
                    }
                )
            } else {
                this.setState({ [report_id+'_showDeleteLoading']: false, disableActioninPage: false })
            }
        })
	}

    downloadReport = (execution_id, state) => {
        let params = {}
        params.execution_id = execution_id
        this.props.downloadReport(params, (promise, response) => {
            if(promise) {
                if(response.status === 'success') {
                    window.open(response.download_url, "_blank")
                }
            }
            this.setState({ [state+'_'+execution_id]: false })
        })
    }

    generatedReport = () => {
        let filteredGeneratedData = this.state.searchGeneratedResult
        if(!filteredGeneratedData || !filteredGeneratedData.length) {
            filteredGeneratedData = this.state.generatedData
        }

        console.log(filteredGeneratedData)

        if(this.state.is_generated_scheduled) {
            filteredGeneratedData = filteredGeneratedData.filter(e => e.is_scheduled === true)
        }

        if(this.state.generated_favourite_report) {
            filteredGeneratedData = filteredGeneratedData.filter(e => e.is_favourite === true)
        }

        let totalPages = 1
        if(filteredGeneratedData.length > this.state.perPage) {
            totalPages = Math.ceil(filteredGeneratedData.length / this.state.perPage)
        }
        
        this.setState({ filteredGeneratedData, generatedStartRecord: 0, generatedCurrentPage: 1, generatedTotalPages: totalPages })
    }
	
	navigatePage = (action, currentPage) => {
		let startRecord = this.state.startRecord
		if(action === 'next' && currentPage !== this.state.totalPages) {
			startRecord = startRecord + this.state.perPage
			currentPage = currentPage + 1
		} else if(action === 'previous' && currentPage !== 0) {
			currentPage = currentPage - 1
			startRecord = startRecord - this.state.perPage
		} else if(action === 'start' && currentPage !== 0) {
			currentPage = 1
			startRecord = 0
		} else if(action === 'end' && currentPage !== 0) {
			currentPage = this.state.totalPages
			startRecord = (this.state.totalPages - 1) * this.state.perPage
		}
		this.setState({ currentPage, startRecord })
	}

    navigateGeneratePage = (action, generatedCurrentPage) => {
		let generatedStartRecord = this.state.generatedStartRecord
		if(action === 'next' && generatedCurrentPage !== this.state.generatedTotalPages) {
			generatedStartRecord = generatedStartRecord + this.state.perPage
			generatedCurrentPage = generatedCurrentPage + 1
		} else if(action === 'previous' && generatedCurrentPage !== 0) {
			generatedCurrentPage = generatedCurrentPage - 1
			generatedStartRecord = generatedStartRecord - this.state.perPage
		} else if(action === 'start' && generatedCurrentPage !== 0) {
			generatedCurrentPage = 1
			generatedStartRecord = 0
		} else if(action === 'end' && generatedCurrentPage !== 0) {
			generatedCurrentPage = this.state.generatedTotalPages
			generatedStartRecord = (this.state.generatedTotalPages - 1) * this.state.perPage
		}
		this.setState({ generatedCurrentPage, generatedStartRecord })
	}

    render() {
        return (
            <div className={`container-fluid overflow-auto flex-grow-1 ${this.state.disableActioninPage ? 'disabled' : ''}`} onClick={ (event) => { this.handleClickOutside(event) } }>  
                {this.state.showSidepanel ? (            
					<SidePanel
						closeViewSidePanel={() => this.setState({ showSidepanel: false })} 
						selectedProvider = {this.state.selectedProvider}
                        pageType={this.state.pageType}
                        selectedRecord={this.state.selectedRecord ? this.state.selectedRecord : {}}
                        refreshReportList={() => this.setState({ showSidepanel: false }, this.onLoadFunction())}
                        refresGenerateReportList={() => this.setState({ showSidepanel: false }, this.listGeneratedReports())}
					/>
				) : null}
                <div className='row h-100'>
                    <div className='col-sm-12 p-0'>
                        <div className='title-section p-3'>
                            <div className='row'>
                                <div className='col-lg-3 align-self-center'>
                                    <h6 className='text-white m-0'>DLP Reports</h6>
                                    <p className='text-white mb-2 '>Count board view of saved reports on your Cloud</p>
                                </div>
                                <div className={`col-lg-9 displayNone`}>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className='d-flex'>
                                                <div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 ${!this.state.providers
                                                        .length}`}>
                                                    <label>Provider</label>
                                                    <select
                                                        className='form-control'
                                                        value={this.state.selectedProvider}
                                                        onChange={event => {
                                                            this.setState(
                                                                {
                                                                    selectedProvider: event.target.value,
                                                                    changesInFilter: true
                                                                }
                                                            )
                                                        }}
                                                    >
                                                        <option value='All'>All</option>
                                                        {this.state.reportCategory.length &&
                                                            this.state.reportCategory.map(item => {
                                                                return (
                                                                    <option key={item} value={item}>
                                                                        {item}
                                                                    </option>
                                                                )
                                                            })}
                                                    </select>
                                                </div>
                                                <div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1 ${!this.state.providers
                                                    .length}`} ref={this.accountRef} style={{maxWidth: '200px' }} >
                                                    <label className="mb-1">Account</label>
                                                    <div className='dlpHeaderMultiSelection removeDropdownSearchBar'>
                                                        <ReactMultiSelectCheckboxes						
                                                            placeholderButtonLabel="All"
                                                            menuIsOpen ={this.state.isAccountOpen}
                                                            value={this.state.selectedAccountName.map(user => ({
                                                                value: user.value,
                                                                label: user.label
                                                            }))}
                                                            onChange={arr => {
                                                            this.handleMultiSelectChange('selectedAccount', arr ? arr : []) }}
                                                            options={this.state.accounts.map(account => ({
                                                                value: account.account_id,
                                                                label: account.account_name,
                                                            }))}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1 ${!this.state.providers
                                                        .length}`} ref={this.regionRef} style={{maxWidth: '200px' }}>
                                                    <label className="mb-1">Region</label>
                                                        <div className={`dlpHeaderMultiSelection ${this.state.regions && this.state.regions.length > 10 ? '' : 'removeDropdownSearchBar' } `}>
                                                            <ReactMultiSelectCheckboxes						
                                                                placeholderButtonLabel="Select"
                                                                menuIsOpen ={this.state.isRegionOpen}
                                                                value={this.state.selectedRegionName.map(user => ({
                                                                    value: user.value,
                                                                    label: user.label
                                                                }))}
                                                                onChange={arr => {
                                                                this.handleMultiSelectChange('selectedRegion', arr ? arr : []) }}
                                                                options={this.state.regions && this.state.regions.map(region => ({
                                                                    value: region.region,
                                                                    label: region.name,
                                                                }))}
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>	
                                    </div>					
                                </div>
                            </div>
                        </div>
                        <div className="box m-3 p-3 h-100">
                            <div className="row h-100">
                                <div className="col-sm-7 border-right">
                                    <div className="d-flex justify-content-between mb-2">
                                        <div className="d-flex">
                                            <select
                                                className='form-control w-fit-content'
                                                value={this.state.selectedReportCategory}
                                                onChange={event => {
                                                    this.setState({ selectedReportCategory: event.target.value},
                                                        () => this.filterReport()
                                                    )
                                                }}
                                            >
                                                <option value='All'>All</option>
                                                {this.state.reportCategory.length &&
                                                    this.state.reportCategory.map(item => {
                                                        return (
                                                            <option key={item} value={item}>
                                                                {item}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <div style={{ display: "inline" }} className="mx-2 align-self-center">
                                                <div className="form-check">
                                                    <input type="checkbox"
                                                        onChange={() => this.setState({ is_scheduled: !this.state.is_scheduled }, () => this.filterReport())}
                                                        className="form-check-input"
                                                        checked={this.state.is_scheduled}
                                                    />
                                                    <p className="form-check-label mb-0 mr-2 text-purple">Scheduled Report</p>
                                                </div>
                                            </div>

                                            <div style={{ display: "inline" }} className="align-self-center">
                                                <div className="form-check">
                                                    <input type="checkbox"
                                                        onChange={() => this.setState({ is_favourite: !this.state.is_favourite }, () => this.filterReport())}
                                                        className="form-check-input"
                                                        checked={this.state.is_favourite}
                                                    />
                                                    <p className="form-check-label mb-0 mr-2 text-purple">Favourite Report</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-50 d-flex">
                                            <Search
                                                data={this.state.reportData ? this.state.reportData : {}}
                                                applyTags={false}
                                                applyLiteDarkTags={true}
                                                filteredData={filteredData => this.setState({ searchResult: filteredData }, () => this.filterReport()) }
                                                // ref={this.searchComponentRef}
                                                className='form-control-sm bg-transparent textboxBorder'
                                                topClassName={'bg-white'}
                                                searchClassName={'textboxBorder'}
                                                searchIconColor={'text-dark'}
                                                // searchText={this.state.searchText}
                                                // className='form-control w-40'
                                            />

                                            <div className={`w-30 ml-2 align-self-center ${this.state.selectedProvider !== '' ? '' : 'displayNone'}`}>
                                                <p className="mb-0 text-blue cursorPointer" onClick={() => this.setState({ pageType: 'create', selectedRecord: {}, showSidepanel: true })}>Create Report</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`mb-2`}>
                                        {this.state.reportData && this.state.reportData.length ?
                                            this.state.filteredData.length ?
                                                <React.Fragment>
                                                {this.state.filteredData.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map(item => {
                                                    return (
                                                        <div className="p-3 bg-light rounded mb-1">
                                                            <div className="row">
                                                                <div className="col-sm-8 align-self-center">
                                                                    <div className="d-flex">
                                                                        <p className="mb-1 text-primary mr-2">{item.report_title}</p>
                                                                        <span className="cursorPointer" onClick={e => { 
                                                                            e.stopPropagation() 
                                                                            this.setState({ 
                                                                                [item.report_id+'_favourite']: this.state[item.report_id+'_favourite'] ? false : true
                                                                            },
                                                                                () => this.updateReports(item.report_id)
                                                                            )
                                                                        }}>
                                                                            <i class={`fal fa-star ${this.state[item.report_id+'_favourite'] ? 'fillStar' : '' } `}></i>
                                                                        </span>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="d-flex align-self-center mr-2">
                                                                            <label className="small mr-2 d-block">Account</label>
                                                                            {item.account_id ?
                                                                                <small className={`badge badge-outline-secondary mr-1 mb-1`}>{getAccountNameFromId(item.account_id, this.state.accounts)}</small>
                                                                            : null}
                                                                        </div>	
                                                                        {item.regions && item.regions.length ?
                                                                            <div className="d-flex align-self-center mr-2">
                                                                                <label className="small mb-1 d-block"><i className={`far fa-location align-self-center mr-1`}></i>Region</label>
                                                                                    {item.regions.slice(0,2).map((reg, i) => {
                                                                                        return(
                                                                                            <small className={`badge badge-outline-secondary ml-1 mb-1`}>{getRegionName(reg, this.state.regions)}</small>
                                                                                        )
                                                                                    })}
                                                                            </div>
                                                                        : null}
                                                                        <div className="d-flex align-self-center">
                                                                            {/* <label className="small mb-1 d-block">Duration</label> */}
                                                                            <i class="far fa-calendar-alt mr-1"></i>
                                                                            {DURATION_FILTER_OPTIONS.map(dur => {
                                                                                return(
                                                                                    item.duration === dur.value ?
                                                                                        <p className={`small ml-1 mb-1`}>
                                                                                            {dur.option}
                                                                                        </p>
                                                                                    : null
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        {item.modified_at || item.created_at ?
                                                                            <div className={`d-flex mr-2`}>
                                                                                {/* <label className="small mb-1 d-block">Created Time</label> */}
                                                                                <p className="mb-0 small"><i className="far fa-clock mr-1"></i>{momentDateFormatLocal((item.modified_at ? item.modified_at : item.created_at), 'DD MMM YYYY HH:mm')}</p>
                                                                            </div>
                                                                        : null }
                                                                        <div className="d-flex">
                                                                            <label className="small mb-1 d-block">Scheduled</label>
                                                                            <small className={`ml-1 mb-1 badge ${item.is_scheduled ? 'badge-outline-success' : 'badge-outline-danger'}`}>{item.is_scheduled ? 'Yes' : 'No'}</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    {this.state[item.report_id+'_showDeleteLoading'] ?
                                                                        <div className="d-flex align-self-center justify-content-end">
                                                                            <div className='text-danger'>
                                                                                <Spinner className='align-self-center mr-2 text-danger' size='sm' /> Deleting...
                                                                            </div> 
                                                                        </div>
                                                                    : this.state[item.report_id+'_showUpdateLoading'] ?
                                                                        <div className="d-flex align-self-center justify-content-end">
                                                                            <div className='text-blue'>
                                                                                <Spinner className='align-self-center mr-2 text-blue' size='sm' /> Updating...
                                                                            </div> 
                                                                        </div>
                                                                    :
                                                                        <div className={`d-flex align-self-center justify-content-end`}>
                                                                            <button className="btn btn-outline-primary btn-sm border-0 mr-2" onClick={() => this.setState({ pageType: 'edit', selectedRecord: item, showSidepanel: true })}>Edit</button>
                                                                            <button className="btn btn-outline-danger btn-sm ml-3 border-0"onClick={() => this.deleteReports(item.report_id) }>Delete</button>
                                                                        </div>
                                                                    }
                                                                    <div className={`d-flex align-self-center justify-content-end`}>
                                                                        <button className="btn btn-outline-primary btn-sm border-0" onClick={() => this.setState({ pageType: 'generate', selectedRecord: item, showSidepanel: true })}>Generate Report</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                {this.state.filteredData.length > this.state.perPage ?
                                                    <div className="pagination mt-3">
                                                        <span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
                                                        <button className={`${this.state.currentPage === 1 ? 'disabled text-gray3' : ''}`} onClick={() => this.navigatePage('start', this.state.currentPage)}><i className={`fal fa-arrow-to-left`}></i></button> 
                                                        <button className={`${this.state.currentPage === 1 ? 'disabled text-gray3' : ''}`} onClick={() => this.navigatePage('previous', this.state.currentPage)}><i className={`fal fa-angle-left`}></i></button> 
                                                        <button className={`${this.state.currentPage === this.state.totalPages ? 'disabled text-gray3' : ''}`}><i className={`fal fa-angle-right`} onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
                                                        <button className={`${this.state.currentPage === this.state.totalPages ? 'disabled text-gray3' : ''}`}><i className={`fal fa-arrow-to-right`} onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
                                                    </div>
                                                : null}
                                                </React.Fragment>
                                            :      
                                                <div className='d-flex justify-content-center m-4'>
                                                    <p>Your search did not match any records. Please try different keywords.</p>
                                                </div>
                                        :      
                                            <div className='d-flex justify-content-center m-4'>
                                                <p>There are no data on this criteria. Please try creating a new report.</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className={`col-sm-5`}>
                                    {/* <nav class="nav pageTopnav">
                                        <span className={`nav-link ${this.state.activeTab === 'Generated Report' ? 'active' : 'cursorPointer'}`} onClick={() => this.setState({ resetSearchBox: !this.state.resetSearchBox, activeTab: 'Generated Report' })}>Generated Report</span>
                                        <span className={`nav-link ${this.state.activeTab === 'Scheduled Report' ? 'active' : 'cursorPointer'}`} onClick={() => this.setState({ resetSearchBox: !this.state.resetSearchBox, activeTab: 'Scheduled Report' })}>Scheduled Report</span>
                                        <span className={`nav-link ${this.state.activeTab === 'Favourite' ? 'active' : 'cursorPointer'}`} onClick={() => this.setState({ resetSearchBox: !this.state.resetSearchBox, activeTab: 'Favourite' })}>Favourite</span>
                                    </nav> */}
                                    <p className="mb-0 text-dark">Generted Report</p>
                                    <p className="small mb-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                    {this.state.activeTab === 'Generated Report' ?
                                        <React.Fragment>
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex">
                                                    <div style={{ display: "inline" }} className="mx-2 align-self-center">
                                                        <div className="form-check">
                                                            <input type="checkbox"
                                                                onChange={() => this.setState({ is_generated_scheduled: !this.state.is_generated_scheduled }, () => this.generatedReport())}
                                                                className="form-check-input"
                                                                checked={this.state.is_generated_scheduled}
                                                            />
                                                            <p className="form-check-label mb-0 mr-2 text-purple">Scheduled Report</p>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: "inline" }} className="align-self-center">
                                                        <div className="form-check">
                                                            <input type="checkbox"
                                                                onChange={() => this.setState({ generated_favourite_report: !this.state.generated_favourite_report }, () => this.generatedReport())}
                                                                className="form-check-input"
                                                                checked={this.state.generated_favourite_report}
                                                            />
                                                            <p className="form-check-label mb-0 mr-2 text-purple">Favourite Report</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-40">
                                                    <Search
                                                        data={this.state.generatedData}
                                                        applyTags={false}
                                                        applyLiteDarkTags={true}
                                                        filteredData={filteredArray => {
                                                            this.setState({ searchGeneratedResult: filteredArray }, () => this.generatedReport())
                                                        }}
                                                        className='form-control-sm bg-transparent textboxBorder'
                                                        topClassName={'bg-white'}
                                                        searchClassName={'textboxBorder'}
                                                        searchIconColor={'text-dark'}
                                                        resetSearchBox={this.state.resetSearchBox}
                                                    />
                                                </div>
                                            </div>
                                            {this.state.generatedData && this.state.generatedData.length ?
                                                this.state.filteredGeneratedData && this.state.filteredGeneratedData.length ? 
                                                    <React.Fragment>
                                                    {this.state.filteredGeneratedData.slice(this.state.generatedStartRecord, this.state.generatedStartRecord + this.state.perPage).map(item => {
                                                        return (
                                                        <div className="p-3 bg-light rounded mb-1">
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    {item.compliance_id+' : '+item.report_title}
                                                                </div>
                                                                <div className="col-sm-8 align-self-center">
                                                                    <div className="d-flex">
                                                                        <div className="d-flex align-self-center mr-2">
                                                                            <label className="small mb-1 d-block mr-1"><i className={`far fa-clock align-self-center mr-1`}></i></label>
                                                                            <span>{item.modified_at ? momentConvertionUtcToLocalTime(item.modified_at, 'DD MMM YYYY HH:mm') : (item.created_at ? momentConvertionUtcToLocalTime(item.created_at, 'DD MMM YYYY HH:mm')  : '')}</span>
                                                                        </div>
                                                                        <div className="d-flex align-self-center mr-2">
                                                                            <label className="small mb-1 d-block mr-1">Account</label>
                                                                            {item.account_id ? 
                                                                                <small className={`badge badge-outline-secondary mr-1 mb-1`}>{getAccountNameFromId(item.account_id, this.state.accounts)}</small>
                                                                            : null}
                                                                        </div>											
                                                                        {item.regions && item.regions.length ? 
                                                                        <div className="d-flex align-self-center">
                                                                            <label className="small mb-1 d-block"><i className={`far fa-location align-self-center mr-1`}></i>Region</label>                 
                                                                                {item.regions.slice(0,2).map((reg, i) => {
                                                                                    return(
                                                                                        <small className={`badge badge-outline-secondary ml-1 mb-1`}>{getRegionName(reg, this.state.regions)}</small>
                                                                                    )
                                                                                })}
                                                                                {/* <small className={`badge badge-outline-secondary ml-1 mb-1`}>All</small> */}
                                                                        </div>
                                                                        : null}
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        {item.bucket_name_with_prefix ?
                                                                            <div className="d-flex align-self-center mr-2">
                                                                                <label className="small mb-1 d-block mr-1">Buckets</label>
                                                                                {item.bucket_name_with_prefix.length ? 
                                                                                    item.bucket_name_with_prefix.slice(0,2).map((buck, i) => {
                                                                                        return(
                                                                                        <React.Fragment>
                                                                                            <div className="d-flex">
                                                                                                <small className={`badge badge-outline-secondary mr-1 mb-1`}>{buck}</small>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                        )
                                                                                    })
                                                                                :
                                                                                    <small className={`badge badge-outline-secondary ml-1 mb-1`}>All</small>
                                                                                }
                                                                            </div>
                                                                        : null}
                                                                        <div className="d-flex align-self-center">
                                                                            {item.duration && item.duration !== '' && item.duration !== 'custom' ?
                                                                                <React.Fragment>
                                                                                {/* <label className="small mb-1 d-block">Duration</label> */}
                                                                                <i class="far fa-calendar-alt mr-1"></i>
                                                                                {DURATION_FILTER_OPTIONS.map(dur => {
                                                                                    return(
                                                                                        item.duration === dur.value ?
                                                                                            <p className={`small ml-1 mb-1`}>
                                                                                                {dur.option}
                                                                                            </p>
                                                                                        : null
                                                                                    )
                                                                                })}
                                                                                </React.Fragment>
                                                                            : 
                                                                                <div className="d-flex align-self-center">
                                                                                    <label className="small mb-1 d-block">Start Time</label>
                                                                                    <small className={`badge badge-outline-secondary ml-1 mb-1 mr-2`}>{item.start_time ? item.start_time : '-'}</small>
                                                                                    <label className="small mb-1 d-block">End Time</label>
                                                                                    <small className={`badge badge-outline-secondary ml-1 mb-1 mr-2`}>{item.end_time ? item.end_time : '-'}</small>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4 align-self-center">
                                                                    <div className="d-flex justify-content-end">
                                                                        <small className={`ml-1 mb-1 badge ${item.current_status.toLowerCase() === 'completed' ? 'badge-outline-success' : item.current_status.toLowerCase() === 'failed' ? 'badge-outline-danger' : 'badge-outline-warning'}`}>{item.current_status}</small>
                                                                    </div>
                                                                    {item.current_status.toLowerCase() === 'completed' && item.output_location ?
                                                                        <div className="d-flex justify-content-end">
                                                                            <button className={`btn btn-outline-primary btn-sm border-0 ${this.state['download_'+item.execution_id] ? 'disablee' : ''}`} onClick={() => this.setState({ ['download_'+item.execution_id]: true }, this.downloadReport(item.execution_id, 'download'))}>
                                                                                {this.state['download_'+item.execution_id] ? <Spinner size='sm' className='mx-2' color='white' /> : null}Download
                                                                            </button>
                                                                        </div>
                                                                    : (item.current_status.toLowerCase() === 'completed' || item.current_status.toLowerCase() === 'failed') && !item.output_location ? 
                                                                        <div className="d-flex justify-content-end">
                                                                            <p className="mb-0 text-info mr-2 cusorPointer" id={`statusInfo_`+item.execution_id}>info</p>
                                                                            <UncontrolledTooltip target={`statusInfo_`+item.execution_id}>
                                                                                {item.status_message ? item.status_message : item.current_status.toLowerCase() === 'failed' ? 'Report generation failed' : 'No data to downlaod'}
                                                                            </UncontrolledTooltip>
                                                                        </div>
                                                                    : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        )
                                                    })}
                                                    {this.state.filteredGeneratedData.length > this.state.perPage ?
                                                        <div className="pagination mt-3">
                                                            <span className="mx-3">Page <strong>{this.state.generatedCurrentPage} of {this.state.generatedTotalPages}</strong> </span>
                                                            <button className={`${this.state.generatedCurrentPage === 1 ? 'disabled text-gray3' : ''}`} onClick={() => this.navigateGeneratePage('start', this.state.generatedCurrentPage)}><i className="fal fa-arrow-to-left"></i></button> 
                                                            <button className={`${this.state.generatedCurrentPage === 1 ? 'disabled text-gray3' : ''}`} onClick={() => this.navigateGeneratePage('previous', this.state.generatedCurrentPage)}><i className="fal fa-angle-left"></i></button> 
                                                            <button className={`${this.state.generatedCurrentPage === this.state.generatedTotalPages ? 'disabled text-gray3' : ''}`}><i className="fal fa-angle-right" onClick={() => this.navigateGeneratePage('next', this.state.generatedCurrentPage)}></i></button> 
                                                            <button className={`${this.state.generatedCurrentPage === this.state.generatedTotalPages ? 'disabled text-gray3' : ''}`}><i className="fal fa-arrow-to-right" onClick={() => this.navigateGeneratePage('end', this.state.generatedCurrentPage)}></i></button>
                                                        </div>
                                                    : null}
                                                    </React.Fragment>
                                                : 
                                                    <div className='d-flex justify-content-center m-4'>
                                                        <p>Your search did not match any records. Please try different keywords.</p>
                                                    </div>
                                            :      
                                                <div className='d-flex justify-content-center m-4'>
                                                    <p>There are no data on this criteria.</p>
                                                </div>
                                            }
                                        </React.Fragment>
                                    : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

/**
 * Type of the props used in the co mponent
 */
DlpReport.propTypes = {
    listReports: PropTypes.func, 
    updateReports: PropTypes.func,
    deleteReports: PropTypes.func,
    listGeneratedReports: propTypes.func
}


/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    return {
		providers: state.filters.providers,
		accounts: state.filters.accounts,
		regions: state.filters.regions,
	}
}

export default AppWrapper(DlpReport, mapStateToProps, {
    listAllProviders,
    listAllAccounts,
    listAllRegions,
    listReports,
    getSchedulReports,
    updateReports,
    deleteReports,
    listGeneratedReports,
    downloadReport
})
            