import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
	getIdentitiesList,
} from '../../../../actions/governance/userInsightsAction'
import CommonDropdown from '../../../common/CommonDropdown'

const includeOptions = [
	{ value: 'include', label: 'Include'},
	{ value: 'exclude', label: 'Exclude'}
];

class LocationFilterSection extends React.Component {
    constructor(props) {
        super(props)
        console.log('accessedByProps', props)
        this.state = {
            identitiesUserList: [],

            sectionDetails: this.props.sectionDetails,

            //sectionDetials Location
            selectedGeography: [],
            selectedRegions: [],
            selectedCountries:[],
            
            selectedGeographyType: 'include',
            selectedRegionsType: 'include',
            selectedCountriesType: 'include',            
            //sectionDetials Location
        };
    };

    componentDidMount = () => {
        this.getIdentitiesList('iam-users')
	}

    getIdentitiesList = (roleType) => {
		let params = {}
		params.provider = this.props.selectedProvider
		params.account_id = this.props.selectedAccount
		params.service_name = roleType

		//console.log('-------------getIdentitiesList-----------',params)
		this.props.getIdentitiesList(params, (promise, identitiesList) => {
			console.log('----------getIdentitiesList--------------',identitiesList)
			if (promise) {
                let userArray = []
                identitiesList.results.forEach(identities => {
                    let dataRow = {}
                    dataRow.label = identities.user_name
                    dataRow.value = identities.user_name
                    userArray.push(dataRow)
                })
                this.setState({ identitiesUserList: userArray })
			} else {
				this.setState({ identitiesUserList: [] })
			}
		})
	}

    selectedOptionFunction = (label, value) => {
        let elementExist = 0
        if(label === 'Geography') {
            var totalArray = this.state.selectedGeography; 
            totalArray.forEach((row, index) => {
                if(row === value) {
                    elementExist = 1
                    //totalArray.splice(index, 1);
                }
            })
            if(elementExist === 0) {
                this.setState(prevState => ({
                    selectedGeography: [...prevState.selectedGeography, value]
                }))
            }
        } else if(label === 'Regions') {
            var totalArrayRegion = this.state.selectedRegions; 
            totalArrayRegion.forEach((row, index) => {
                if(row === value) {
                    elementExist = 1
                    //totalArrayRegion.splice(index, 1);
                }
            })
            if(elementExist === 0) {
                this.setState(prevState => ({
                    selectedRegions: [...prevState.selectedRegions, value]
                }))
            }
        } else if(label === 'Country') {
            var totalArrayCountry = this.state.selectedCountries; 
            totalArrayCountry.forEach((row, index) => {
                if(row === value) {
                    elementExist = 1
                    //totalArrayCountry.splice(index, 1);
                }
            })
            if(elementExist === 0) {
                this.setState(prevState => ({
                    selectedCountries: [...prevState.selectedCountries, value]
                }))
            }
        } else if(label === 'Geography_Type') {
            this.setState({ selectedGeographyType: value })
        } else if(label === 'Regions_Type') {
            this.setState({ selectedRegionsType: value })
        } else if(label === 'Country_Type') {
            this.setState({ selectedCountriesType: value })
        }
    }

    applyFilter = () => {
        
        let obj = {}
        let child = {}
        child['geography'] = this.state.selectedGeography
        child['type'] = this.state.selectedGeographyType
        
        obj['geography'] = child
        
        child = {}
        child['region'] = this.state.selectedRegions
        child['type'] = this.state.selectedRegionsType
        obj['region'] = child

        child = {}
        child['country'] = this.state.selectedCountries
        child['type'] = this.state.selectedCountriesType
        obj['country'] = child
        
        let result = []
        result.push(obj)
        
        return this.props.data(obj)
    }

    closeFilter = () => {
        return this.props.data(false);
    }

    remove = (type, index) => {
        var totalArray = '' 
        if(type === 'Geography') {
            totalArray = this.state.selectedGeography;             
            totalArray.splice(index, 1);
            this.setState({ selectedGeography : totalArray });
        } else if(type === 'Regions') {
            totalArray = this.state.selectedRegions; 
            totalArray.splice(index, 1);
            this.setState({ selectedRegions : totalArray });
        } else if(type === 'Country') {
            totalArray = this.state.selectedCountries;
            totalArray.splice(index, 1);
            this.setState({ selectedCountries : totalArray });
        }
    }

    render() {
        return (
            <div className="card cardDropSection">
                <p className="text-white col-sm-12 f16 pb-0 mb-0">Lorem ipsum text industry since 1500s</p>

                <div className="card-body">
                    <div className="d-flex mb-2">
                    <div className="mr-3">
                            <CommonDropdown 
                                data = {this.selectedOptionFunction.bind(this, 'Geography_Type')}
                                hideHeader = {false}
                                headerName = {'Include'}
                                dropOptions = {includeOptions}
                                displayMenu = {false}
                                isMultiSelect = {false}
                                isDefaultDropdown = {false}
                                styleTop={'top57'}
                                searchOption={false}
                                label={'&nbsp;'}
                            />
                        </div>
                        <div className="mr-3">
                            <CommonDropdown 
                                data = {this.selectedOptionFunction.bind(this, 'Geography')}
                                hideHeader = {false}
                                headerName = {'Geography'}
                                dropOptions = {this.state.identitiesUserList}
                                displayMenu = {false}
                                isMultiSelect = {false}
                                isDefaultDropdown = {false}
                                styleTop={'top57'}
                                searchOption={true}
                                label={'Geography'}
                            />
                        </div>
                        <div className="mr-3 minWidth300 align-self-center">
                            {this.state.selectedGeography.length ? 
                                this.state.selectedGeography.map((item, index) => {
                                    return (
                                        index < 3 ?
                                            <span className='badge badge-primary custom-badge ml-2 text-white p-1'> {item}
                                                <i className='ml-1 fal fa-times cursorPointer'onClick={() => this.remove('Geography', index)}></i>
                                            </span> 
                                        :
                                        null
                                    )       
                                })
                            :
                                null
                            }
                        </div>
                        <div className="mr-3 align-self-center">
                            {this.state.selectedGeography.length > 3 ?
                                <p className="text-white col-sm-12 f10 pb-0 mb-0">  {this.state.selectedGeography.length+' Selected '}</p>
                            :
                                null
                            }
                        </div>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="mr-3">
                            <CommonDropdown 
                                data = {this.selectedOptionFunction.bind(this, 'Regions_Type')}
                                hideHeader = {false}
                                headerName = {'Include'}
                                dropOptions = {includeOptions}
                                displayMenu = {false}
                                isMultiSelect = {false}
                                isDefaultDropdown = {false}
                                styleTop={'top57'}
                                searchOption={false}
                                label={'&nbsp;'}
                            />
                        </div>
                        <div className="mr-3">
                            <CommonDropdown 
                                data = {this.selectedOptionFunction.bind(this, 'Regions')}
                                hideHeader = {false}
                                headerName = {'Regions'}
                                dropOptions = {this.state.identitiesUserList}
                                displayMenu = {false}
                                isMultiSelect = {false}
                                isDefaultDropdown = {false}
                                styleTop={'top57'}
                                searchOption={true}
                                label={'Regions'}
                            />
                        </div>
                        <div className="mr-3 minWidth300 align-self-center">
                            {this.state.selectedRegions.length ? 
                                this.state.selectedRegions.map((item, index) => {
                                    return (
                                        index < 3 ?
                                            <span className='badge badge-primary custom-badge ml-2 text-white p-1'> {item}
                                                <i className='ml-1 fal fa-times cursorPointer'onClick={() => this.remove('Regions', index)}></i>
                                            </span> 
                                        :
                                        null
                                    )       
                                })
                            :
                                null
                            }
                        </div>
                        <div className="mr-3 align-self-center">
                            {this.state.selectedRegions.length > 3 ?
                                <p className="text-white col-sm-12 f10 pb-0 mb-0">  {this.state.selectedRegions.length+' Selected '}</p>
                            :
                                null
                            }
                        </div>
                    </div>

                    <div className="d-flex mb-2">
                        <div className="mr-3">
                            <CommonDropdown 
                                data = {this.selectedOptionFunction.bind(this, 'Country_Type')}
                                hideHeader = {false}
                                headerName = {'Include'}
                                dropOptions = {includeOptions}
                                displayMenu = {false}
                                isMultiSelect = {false}
                                isDefaultDropdown = {false}
                                styleTop={'top57'}
                                searchOption={false}
                                label={'&nbsp;'}
                            />
                        </div>
                        <div className="mr-3">
                            <CommonDropdown 
                                data = {this.selectedOptionFunction.bind(this, 'Country')}
                                hideHeader = {false}
                                headerName = {'Country'}
                                dropOptions = {this.state.identitiesUserList}
                                displayMenu = {false}
                                isMultiSelect = {false}
                                isDefaultDropdown = {false}
                                styleTop={'top57'}
                                searchOption={true}
                                label={'Country'}
                            />
                        </div>
                        <div className="mr-3 minWidth300 align-self-center">
                            {this.state.selectedCountries.length ? 
                                this.state.selectedCountries.map((item, index) => {
                                    return (
                                        index < 3 ?
                                            <span className='badge badge-primary custom-badge ml-2 text-white p-1'> {item}
                                                <i className='ml-1 fal fa-times cursorPointer'onClick={() => this.remove('Country', index)}></i>
                                            </span> 
                                        :
                                        null
                                    )       
                                })
                            :
                                null
                            }
                        </div>
                        <div className="mr-3 align-self-center">
                            {this.state.selectedCountries.length > 3 ?
                                <p className="text-white col-sm-12 f10 pb-0 mb-0">  {this.state.selectedCountries.length+' Selected '}</p>
                            :
                                null
                            }
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="col-sm-6 m-0 p-0 float-left">
                        &nbsp;
                    </div>
                    <div className="col-sm-6 m-0 p-0 float-right text-right">
                        <span className="dropCancelBtn p-2 mr-2"
                            onClick={ () => this.closeFilter()}
                        >
                            Cancel
                        </span>
                        <span className="dropApplyBtn p-2" 
                            onClick={ () => this.applyFilter()}
                        >
                            Apply
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

/**
 * Type of the props used in the component
 */
LocationFilterSection.propTypes = {
    getIdentitiesList:PropTypes.func
}

export default connect(null, { getIdentitiesList })(LocationFilterSection);

//export default connect(AccessedByFilterSection, null, {getIdentitiesList})

//export default AccessedByFilterSection;
