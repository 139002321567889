/*************************************************
 * Tvastar
 * @exports
 * @file SignInAction.js
 * @author Rajasekar // on 23/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import store from 'store'

import { ACTION_TYPES } from './types'
import cognitoService from '../services/cognitoService'
import { myLog } from '../utils/utility'
// import { myLog } from '../utils/utility'

/**
 * Authenticate user using cognito email/username and password
 * @param {String} email
 * @param {String} password
 * @param {Function} callback
 */
export const authenticate = (email, password, callback) => {
	return async dispatch => {
		try {
			const response = await cognitoService.signIn(email, password)
			myLog(response, '---login response----')
			if ('signInUserSession' in response) {
				/**
				 * Dispatch authenticated user data to reducer
				 */
				dispatch({
					type: ACTION_TYPES.SET_AUTH_TOKENS,
					payload: response,
				})
				store.set('userSession', response)
				callback(true)
			} else {
				callback(false)
			}
		} catch (error) {
			myLog(error, '--Login error--')
			callback(false)
		}
	}
}