/*************************************************
 * Tvastar
 * @exports
 * @file MicroservicesMetricSection  .js
 * @author Prakash // on 5/5/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { momentDateGivenFormat, addHours, momentConvertionUtcToLocalTime, getConvertedBytesUnit, convertBytes, convertBytesTo, convertBytesWithoutUnit, convertSeconds, convertSecondsWithoutUnit, countFormater, countFormaterWithoutUnit, convertBits, convertBitsWithoutUnit, subDays, getDayFromSelectedDuration, subHours, twoDateDiffrenceDayHoursMinutes, momentConvertionLocalToUtcTime, capitalizeFirstLetter } from '../../../utils/utility'
import Chart from 'react-apexcharts'
import { setMicroServicePropsDetails, getGraphSummary } from '../../../actions/aiops/MicroServicesAction'

class MicroservicesMetricSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            troubleshootDetails: this.props.troubleshootDetails,
            showCoreMertics: true
        }
    }
    
    componentDidMount = () => {
        this.getGraphSummary()
    }
    
    getGraphSummary = () => {
        let params = {}
        params.provider = this.state.troubleshootDetails && this.state.troubleshootDetails.provider ? this.state.troubleshootDetails.provider : "aws"
        params.account_id = "222506310953"
        if(this.state.troubleshootDetails && this.state.troubleshootDetails.account_id) {
            params.account_id = this.state.troubleshootDetails.account_id
        }
        params.region = "us-east-2"
        if(this.state.troubleshootDetails && this.state.troubleshootDetails.region) {
            params.region = this.state.troubleshootDetails.region
        }
        params.errors = true
        params.anomalies = true
        params.requests = true
        params.latency = true
        params.aggregate_by = "microservice"
        // params.microservice_id = this.state.troubleshootDetails && this.state.troubleshootDetails.microservice_id
        params.start_time =  "2023-03-23 00:00:00"
        params.end_time =  "2023-03-23 23:59:59"
        // params.start_time = momentConvertionLocalToUtcTime(this.state.troubleshootDetails && this.state.troubleshootDetails.start_time, 'YYYY-MM-DD HH:mm:00')
        // params.end_time = momentConvertionLocalToUtcTime(this.state.troubleshootDetails && this.state.troubleshootDetails.end_time, 'YYYY-MM-DD HH:mm:00')
        if(this.props.microservicesMetrics && !this.props.microservicesMetrics.hasOwnProperty(this.state.troubleshootDetails && this.state.troubleshootDetails.microservice_id)) {
            this.props.getGraphSummary(params, (promise, response) => {
                if(promise) {
                    this.setState({ microServicesList: response, filteredMicroServicesList: response },
                        () => {
                            let obj = {}
                            obj[this.state.troubleshootDetails.microservice_id] = this.state.microServicesList
                            this.props.setMicroServicePropsDetails("microservicesMetrics", obj)
                            this.metricsListGraph()
                        }
                    )
                } else {
                    this.setState({ microServicesList: [], showLoading: false })
                }
            })
        }
    }

    metricsListGraph = () => {
        // this.state.microServicesList.forEach(graphDetails => {
        let graphDetails = this.state.microServicesList && this.state.microServicesList.length && this.state.microServicesList[0].trends ? this.state.microServicesList[0].trends : {}
        if(graphDetails && Object.entries(graphDetails).length) {
            let graphData = graphDetails.data
            let graphDates = graphDetails.dates
            Object.entries(graphData).forEach(([key, item]) => {
                let array = []
                let graphLabels = []
                
                item.values.forEach((val, j) => {
                    graphLabels.push(momentConvertionUtcToLocalTime(graphDates[j], 'YYYY-MM-DD HH:mm:ss'))
                    let arrayValues = []
                    arrayValues.push(momentConvertionUtcToLocalTime(graphDates[j], 'YYYY-MM-DD HH:mm:ss'))
                    if(item.unit) {
                        val = this.getGrapUnitValue(val, item.unit)
                    }
                    arrayValues.push(val)
                    array.push(arrayValues)
                })

                if(array.length === 1) {
                    let arrayValues = []
                    arrayValues.push(addHours(momentConvertionUtcToLocalTime(graphDates[0], 'YYYY-MM-DD HH:mm:ss'), 0.5))
                    arrayValues.push(0)
                    array.push(arrayValues)
                }

                let series = [{
                    data: array,
                    name: key,
                }]
                
                let xaxisAnnotation = []
                let points = []
                
                let annotations = {
                    xaxis: xaxisAnnotation,
                    // position: 'back',
                    points: points
                }

                let options = {
                    chart: {
                        // group: 'metrics_and_sre',
                        // id:'metrics_'+i,
                        type: 'area',
                        width: '100%',
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false
                        },
                        sparkline: {
                            enabled: false
                        }
                    },
                    stroke: {
                        show: true,
                        curve: 'smooth',
                        lineCap: 'butt',
                        width: 2,
                        dashArray: 0,      
                    },
                    colors: ['#775BA2'],
                    fill: {
                        type: "gradient",
                        gradient: {
                            // shadeIntensity: 1,
                            // opacityFrom: 0.7,
                            // opacityTo: 0.9,
                            // stops: [0, 90, 100]
                            gradientToColors: ['#775BA2'],
                            shadeIntensity: 0,
                            opacityFrom: .3,
                            opacityTo: 1,
                            stops: [30, 90]
                        }
                    },
                    tooltip: {
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                            let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
                            dateLable = dateLable.toString()
                            dateLable = momentDateGivenFormat(dateLable, 'DD MMM HH:mm')
                            let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]
                            return '<div class="arrow_box">'+dateLable+' <span style="color:'+ w.globals.colors +'">' + parseFloat(val).toFixed(2) + '</span> </div>'
                        },
                        fixed: {
                            enabled: false
                        },
                        x: {
                            show: false,
                        },
                        y: {
                            show: false
                        },        
                        marker: {
                            show: false
                        }
                    },
                    xaxis: {
                        type: 'datetime',
                        labels: {
                            datetimeUTC: false,
                            style: {
                                colors: '#60687C'
                            },
                            // formatter: function (value) {
                            //     return momentDateGivenFormat(value, 'HH:mm')
                            // },
                        },                            
                        axisTicks: {
                            show: false
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    yaxis: {
                        show: true,
                        axisTicks: {
                            show: false,
                        },
                        axisBorder: {
                            show: false,
                            color: '#60687C'
                        },
                        tooltip: {
                            enabled: false
                        },
                        // tickAmount: 2,
                        min: 0,
                        // max: yaxisMaxValue, 
                        tickAmount: 1,
                        labels: {
                            offsetX: -10,
                            // minWidth: 50,
                            show: true,
                            style: {
                                fontSize: '9px',
                                colors: '#60687C'
                            },
                            // formatter: (value) => { 
                            //     if(Number(value) === value && value % 1 === 0) {
                            //         return Math.ceil(value)
                            //     } else {
                            //         return Math.ceil(value)
                            //     }
                            // },
                            // offsetX: 0,
                            // offsetY: 0,
                        }
                    },
                    grid: {
                        show: true,
                        borderColor: '#2D323E',
                        strokeDashArray: 0,
                        position: 'back',
                        xaxis: {
                            lines: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: ['#FFF'],
                                }
                            },
                            axisTicks: {
                                show: false
                            }
                        },   
                        yaxis: {
                            lines: {
                                show: false
                            },
                            labels: {
                                style: {
                                    colors: ['#969AA7'],
                                }
                            }
                        },                
                        padding: {
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0
                        },
                    },
                    annotations: annotations,
                }
                
                this.setState({ [key+'_series']: series, [key+'_options']: options, showLoading: false })
            })
        }

        this.setState({ graphDetails })
    }
	
	handleClickOutside(event) {}

    getConvertedUnit = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = getConvertedBytesUnit(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                // val = getConvertedSecondsUnit(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                // val = getConvertedBitsUnit(val, unit)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                // val = getConvertedCountFormater(val)
            } else {
                val = ""
            }
        } else if(val !== '') {           
            val = ""
        }
        return val
    }

    getGrapUnitValue = (val ,unit, valueType, convertTo) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseInt(val))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                if(convertTo) {
                    val = convertBytesTo(val, unit, valueType, convertTo)
                } else {
                    val = convertBytes(val, unit, valueType)
                }                         
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit, valueType, convertTo)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit, valueType, convertTo)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    getGrapUnitValueWithoutUnit = (val ,unit, valueType) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytesWithoutUnit(val, unit, valueType)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSecondsWithoutUnit(val, unit, valueType)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBitsWithoutUnit(val, unit, valueType)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                val = countFormaterWithoutUnit(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseInt(val))
        }
        return val
    }

    onClickPrimaryElipMenus = (data, key, i) => {
        Object.keys(data).forEach((item, index) => {
            if(key !== item) {
                this.setState({ ["showElipMenu_"+item]: false })
            } else {
                this.setState({ ["showElipMenu_"+item]: !this.state["showElipMenu_"+item] })
            }
        })
    }

    onClickLegend = (type, key, i) => {
        this.setState({ ["selectedLegendType_"+key]: type, ["selectedMetricsLegend_"+key]: key, ["showElipMenu_"+key]: false },
            () => this.primaryMetricsListGraph()    
        )
    }

    render() {
        return (
            !this.state.showLoading ?
                <div className="" onClick={ (event) => { this.handleClickOutside(event) } }>
                    <div className={`card bg-dark border-0 p-3 mt-3`}>
                        <div className={`${this.state.showCoreMertics ? "mb-1" : ""} d-flex justify-content-between`} onClick={() => this.setState({ showCoreMertics: !this.state.showCoreMertics })}>
                            <div className="">
                                <div className="text-white"><span className={`far ${this.state.showCoreMertics ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Metrics</div>
                                {this.state.metricsByTrend && this.state.metricsByTrend.length ?
                                    <div className="d-flex">
                                        <p class="f12 text-white mb-0">Key Metrics for {this.props.assetDetails.resource_type ? this.props.assetDetails.resource_type : ''}</p>
                                        <p class="f12 text-white mb-0 ml-2">{this.props.assetDetails.asset_name ? this.props.assetDetails.asset_name : ''}</p>
                                    </div>
                                : null}
                            </div>
                            <div className="d-flex">
                                <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{"("+momentConvertionUtcToLocalTime(this.state.troubleshootDetails && this.state.troubleshootDetails.start_time, 'DD MMM YYYY HH:mm') +' - '+ momentConvertionUtcToLocalTime(this.state.troubleshootDetails && this.state.troubleshootDetails.end_time, 'DD MMM YYYY HH:mm')+")"}</p>
                            </div>
                        </div>
                        <div className={`collapse ${this.state.showCoreMertics ? 'show' : ''}`}>
                            <div class="container-fluid mt-2">
                                {this.state.graphDetails && Object.entries(this.state.graphDetails).length ?
                                    <div class="row">
                                        <div class="mb-2 col-sm-12 px-0">
                                            {this.state.graphDetails.data && Object.keys(this.state.graphDetails.data).map(key => {
                                                return(
                                                    <div class="p-3 border bg-dark3 rounded mb-3">
                                                        <p class="text-white f16 p-1 mb-0">{capitalizeFirstLetter(key)}</p>
                                                        {/* <div class="rounded text-info bg-muted px-2 py-1 f16 d-table">{key}</div> */}
                                                        {/* <p class="f12 mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing.</p> */}
                                                        
                                                        {this.state[key+'_options'] && Object.entries(this.state[key+'_options']).length && this.state[key+'_series'] && this.state[key+'_series'].length ?
                                                            <div className="cursorPointer transparentTooltip mb-n4 mt-n4">
                                                                <Chart options={this.state[key+'_options']} series={this.state[key+'_series']} type="area" height={115} width={'100%'}/>
                                                            </div>
                                                        : null}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                : 
                                    <div className='d-flex justify-content-center m-4'>
                                        No Metrics for the this criteria, Please adjust your filter to get the metric details.
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            : 
                <div className={`bg-dark rounded p-3`}>
                    <p className={`mb-2 text-white`}><b>Metrics</b></p>
                    <div className='d-flex justify-content-center m-4'>
                        <Spinner className='text-center' color='white' size='lg' />
                    </div>
                </div>
        )
    }
}

/**
* Type of the props used in the component
*/
MicroservicesMetricSection.propTypes = {}

const mapStateToProps = state => {
    return {
        microservicesMetrics: state.aiops.setMicroServicePropsDetails && state.aiops.setMicroServicePropsDetails.microservicesMetrics ? state.aiops.setMicroServicePropsDetails.microservicesMetrics : {}
    }
}

export default connect(mapStateToProps, {
    getGraphSummary,
    setMicroServicePropsDetails
})(withRouter(MicroservicesMetricSection))