/*************************************************
 * Tvastar
 * @exports
 * @file SidePanel.js
 * @author Prakash // on 28/06/2020
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { momentConvertionUtcToLocalTime, capitalizeFirstLetter } from '../../../utils/utility'
import _ from 'lodash'
import Chart from 'react-apexcharts'


class ApexRiskStackedBarChart extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    
    componentDidMount = () => {
        let graphData = this.props.graphData;
        if(graphData){
            let graphLables = []

            // let criticalData = []
            // let highData = []
            // let mediumData = []
            // let lowData = []

            // if(!this.props.graphDataType || this.props.graphDataType !== 'object') {
            //     graphLables = graphData.labels
            //     for(let x=0;x<graphLables.length;x++) {
            //         let date = momentDateGivenFormat(graphLables[x], 'DD MMM Y')

            //         let dataItems = {}
            //         dataItems.x = date
            //         if(graphData.data.Critical) {
            //             dataItems.y  = graphData.data.Critical ? graphData.data.Critical[x] : 0
            //         } else if(graphData.data.critical) {
            //             dataItems.y  = graphData.data.critical ? graphData.data.critical[x] : 0
            //         } else {
            //             dataItems.y = 0
            //         }
            //         criticalData.push(dataItems)

            //         dataItems = {}
            //         dataItems.x = date
            //         if(graphData.data.High) {
            //             dataItems.y  = graphData.data.High ? graphData.data.High[x] : 0
            //         } else if(graphData.data.high) {
            //             dataItems.y  = graphData.data.high ? graphData.data.high[x] : 0
            //         } else {
            //             dataItems.y = 0
            //         }
            //         highData.push(dataItems)

            //         dataItems = {}
            //         dataItems.x = date
            //         if(graphData.data.Medium) {
            //             dataItems.y  = graphData.data.Medium ? graphData.data.Medium[x] : 0
            //         } else if(graphData.data.medium) {
            //             dataItems.y  = graphData.data.medium ? graphData.data.medium[x] : 0
            //         } else {
            //             dataItems.y = 0
            //         }
            //         mediumData.push(dataItems)

            //         dataItems = {}
            //         dataItems.x = date
            //         if(graphData.data.Low) {
            //             dataItems.y  = graphData.data.Low ? graphData.data.Low[x] : 0
            //         } else if(graphData.data.low) {
            //             dataItems.y  = graphData.data.low ? graphData.data.low[x] : 0
            //         } else {
            //             dataItems.y = 0
            //         }
            //         lowData.push(dataItems)
            //     }
            // } else {
            //     graphLables = graphData.labels
            //     for(let x=0;x<graphLables.length;x++) {
            //         let date = momentDateGivenFormat(graphLables[x], 'DD MMM Y')
            //         let dataItems = {}
            //         dataItems.x = date
            //         if(graphData.data.Critical) {
            //             dataItems.y  = graphData.data.Critical ? graphData.data.Critical[x] : 0
            //         } else if(graphData.data.critical) {
            //             dataItems.y  = graphData.data.critical ? graphData.data.critical[x] : 0
            //         } else {
            //             dataItems.y = 0
            //         }
                    
            //         criticalData.push(dataItems)
                    
            //         dataItems = {}
            //         dataItems.x = date
            //         if(graphData.data.High) {
            //             dataItems.y  = graphData.data.High ? graphData.data.High[x] : 0
            //         } else if(graphData.data.high) {
            //             dataItems.y  = graphData.data.high ? graphData.data.high[x] : 0
            //         } else {
            //             dataItems.y = 0
            //         }
            //         highData.push(dataItems)
                    
            //         dataItems = {}
            //         dataItems.x = date
            //         if(graphData.data.Medium) {
            //             dataItems.y  = graphData.data.Medium ? graphData.data.Medium[x] : 0
            //         } else if(graphData.data.medium) {
            //             dataItems.y  = graphData.data.medium ? graphData.data.medium[x] : 0
            //         } else {
            //             dataItems.y = 0
            //         }
            //         mediumData.push(dataItems)
    
            //         dataItems = {}
            //         dataItems.x = date
            //         if(graphData.data.Low) {
            //             dataItems.y  = graphData.data.Low ? graphData.data.Low[x] : 0
            //         } else if(graphData.data.low) {
            //             dataItems.y  = graphData.data.low ? graphData.data.low[x] : 0
            //         } else {
            //             dataItems.y = 0
            //         }
            //         lowData.push(dataItems)
            //     }
            // }
            
            // let series = [
			// 	{
			// 	  name: 'Critical',
			// 	  data: criticalData,
			// 	},
			// 	{
			// 	  name: 'High',
			// 	  data: highData,
			// 	},
			// 	{
			// 	  name: 'Medium',
			// 	  data: mediumData,
			// 	},
			// 	{
			// 	  name: 'Low',
			// 	  data: lowData,
			// 	}
            // ]

            let colors = this.props.colors ? this.props.colors : ['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']
            let totalCount = 0
            let series = []
            if(graphData.labels) {
                graphLables = graphData.labels
            } else {
                let data = {}
                if(graphData.Critical) {
                    graphLables = graphData.Critical.labels
                    data.Critical = graphData.Critical.data && graphData.Critical.data.fail ? graphData.Critical.data.fail : graphData.Critical.data
                } else {
                    data.Critical = []
                }
                if(graphData.High) {
                    graphLables = graphData.High.labels
                    data.High = graphData.High.data && graphData.High.data.fail ? graphData.High.data.fail : graphData.High.data
                } else {
                    data.High = []
                }
                if(graphData.Medium) {
                    graphLables = graphData.Medium.labels
                    data.Medium = graphData.Medium.data && graphData.Medium.data.fail ? graphData.Medium.data.fail : graphData.Medium.data
                } else {
                    data.Medium = []
                }
                if(graphData.Low) {
                    graphLables = graphData.Low.labels
                    data.Low = graphData.Low.data && graphData.Low.data.fail ? graphData.Low.data.fail : graphData.Low.data
                } else {
                    data.Low = []
                }
                graphData.data = data
            }
            Object.keys(graphData.data).forEach((key, index) => {
                let dataArray =[]
                let orderBy = 0
                let riskColor = ''
                for(let x=0;x<graphLables.length;x++) {
                    let date = momentConvertionUtcToLocalTime(graphLables[x], 'DD MMM Y')
                    let dataItems = {}
                    dataItems.x = date
                    dataItems.y = graphData.data[key] && graphData.data[key].length ? graphData.data[key][x]  : 0
                    
                    if(key.toLowerCase() === 'critical') {
                        riskColor = colors[0]
                        orderBy = 1
                    } else if(key.toLowerCase() === 'high') {
                        riskColor = colors[1]
                        orderBy = 2
                    } else if(key.toLowerCase() === 'medium') {
                        riskColor = colors[2]
                        orderBy = 3
                    } else if(key.toLowerCase() === 'low') {
                        riskColor = colors[3]
                        orderBy = 4
                    } else if(key.toLowerCase() === 'detected') {
                        riskColor = colors[0]
                        orderBy = 1
                    } else if(key.toLowerCase() === 'redacted') {
                        riskColor = colors[1]
                        orderBy = 2
                    }
                    dataArray.push(dataItems)
                }
                
                let count = graphData.data[key].reduce(function(a, b){
                    return a + b;
                }, 0);

                totalCount += count

                let seriesRow = {}
                seriesRow.name = capitalizeFirstLetter(key)
                seriesRow.color = riskColor
                seriesRow.data = dataArray
                seriesRow.count = count
                seriesRow.orderBy = orderBy
                series.push(seriesRow)
            })

            series = _.orderBy(series, ['orderBy'], ['asc'])
        
            let backgroundColors = {}
            if(this.props.showBackgroundBarColors) {
                backgroundColors = {
                    ranges: [{
                        from: 1,
                        to: 0.9,
                        color: 'red'
                    }],
                    backgroundBarColors: this.props.backgroundBarColors ? this.props.backgroundBarColors : ['#EDEDED'],
                    backgroundBarOpacity: 1,
                    // backgroundBarRadius: this.props.backgroundBarShape && this.props.backgroundBarShape === 'rounded' ? (this.props.barRadius ? this.props.barRadius : 10) : 0,
                    backgroundBarRadius: 0
                }
            }

            let options = {
                tooltip: {
                    enabled: true,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        let val  = series[seriesIndex][dataPointIndex];
                        //val = Math.abs(Math.round(val))
                        let labelName = w.globals.initialSeries[seriesIndex].name
                        //let val = str.replace('-',series[seriesIndex][dataPointIndex]);
                        return '<div class="arrow_box">' +
                            '<span> '+ w.globals.initialSeries[0].data[dataPointIndex].x + 
                            ' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val + ' </span>' +
                            '</div>'
                    },
                    style: {
                    fontSize: '9px',
                    },
                },				
                chart: {
                    redrawOnParentResize: true,
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                    sparkline: {
                        enabled: this.props.sparkline
                    },
                    type: 'bar',
                    height: 150,
                    stacked: this.props.stacked,
                },
                colors: colors,
                plotOptions: {
                    bar: {
                        horizontal: this.props.horizontal,
                        barHeight: this.props.barHeight,
                        borderRadius: this.props.barEndShape && this.props.barEndShape === 'rounded' ? (this.props.barRadius ? this.props.barRadius : 10) : 0,
                        startingShape: 'flat',
                        endingShape: this.props.barEndShape ? this.props.barEndShape : 'flat',
                        columnWidth: this.props.columnWidth ? this.props.columnWidth : '60%',
                        distributed: false,
                        rangeBarOverlap: false,
                        colors: backgroundColors
                                
                    },       
                },
                dataLabels: {
                    enabled: false
                },                
                grid: {
                    show: false,			        
                    xaxis: {
                        lines: {
                            show: false
                        },
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: -5
                    },
                },
                xaxis: {
                    show: this.props.xaxis,
                    tooltip: {
                        enabled: false
                    },
                    axisTicks: {
                        show: false
                    },
                    type: 'datetime',
                    axisBorder: {
                        show: true,
                        color: '#434B5E',
                    },
                    labels: {
                        style: {
                            colors: this.props.axisLabelColor,
                            fontSize: '11px',
                            fontWeight: 'bolder',
                        },
                    },
                },
                yaxis: {
                    show: this.props.yaxis,
                    axisBorder: {
                        show: true,
                        color: '#434B5E',
                    },
                    labels: {
                        style: {
                            colors: this.props.axisLabelColor,
                            fontSize: '11px',
                            fontWeight: 'bolder',
                        },
                        formatter: function(val, index) {
                            if ((val / 1000) >= 1) {
                                val = val / 1000
                            }
                            if(val % 1 !== 0) {
                                val = val.toFixed(1);
                                return val + "K";
                            }
                            return val
                        },
                        offsetX: -15
                    },
                },
                legend: {
                    show: this.props.legend,
                    markers: {
                        radius: 12,
                    },
                    labels: {
                        colors: colors,
                        useSeriesColors: true
                    },
                },
            }
            this.setState({ series, options, totalCount });
        }
    }

    render() {
        return(
            <div className={`${this.props.className}`}>
                <div className={`${this.props.graphRiskCountSection ? '' : 'displayNone'}`}>
                    <p className="mb-1 f16 text-white">Risk Count</p>
                    <div class="d-flex">
                        {this.state.series && this.state.series.length > 0 && this.state.series.map((riskDetails, i) => {
                            let text = riskDetails.name
                            let color = riskDetails.color
                            return (
                                <div key={i} class="mr-4" style={{color: color}}>
                                    <h6 class="m-0">{riskDetails.count}</h6>
                                    <p class="m-0 text-nowrap small">{text}</p>
                                </div>
                                // <div key={i} className={`d-flex riskcout flex-column text-left`} style={{color: color}}>
                                //     <h5 className="mb-0"></h5>
                                //     <p className="mt-1 mb-0"></p>
                                // </div>
                            )
                        })}
                    </div>
                </div>
                {this.state.series ?
                    <Chart options={this.state.options} series={this.state.series} type="bar" height={this.props.height} />
                : null}
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
ApexRiskStackedBarChart.propTypes = {}

const mapStateToProps = state => {}

export default connect(mapStateToProps, {})(withRouter(ApexRiskStackedBarChart))