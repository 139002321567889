/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file FilterSearch.js
 * @author Prakash // on 30/07/2020
 * @copyright © 2020 Tvastar. All rights reserved.
 **************************************************/
import React, { Component } from 'react'
import _ from 'lodash'
import { AppWrapper } from '../../../common/AppWrapper'
import { listAllProviders, listAllAccounts, listAllRegions } from '../../../../actions/commonAction'
import CommonDropdown from '../../../common/CommonDropdown'
import LifecycleFilterSection from './LifecycleFilterSection'
import AccessedByFilterSection from './AccessedByFilterSection'
import DurationFilterSection from './DurationFilterSection'
import LocationFilterSection from './LocationFilterSection'
import ServiceFilterSection from './ServiceFilterSection'
import SizeFilterSection from './SizeFilterSection'
import MonitoringFilterSection from './MonitoringFilterSection'
import AccessMethodFilterSection from './AccessMethodFilterSection'
import ScoreFilterSection from './ScoreFilterSection'


const defaultDropdown = [
    { label: 'Account', value: 'Account'},
	{ label: 'Lifecycle', value: 'Lifecycle'},
	{ label: 'Accessed By', value: 'Accessed By'},
	{ label: 'Duration', value: 'Duration'},
	{ label: 'Location', value: 'Location'},
	{ label: 'Service', value: 'Service'},
	{ label: 'Size', value: 'Size'},
	{ label: 'Monitoring', value: 'Monitoring'},
	{ label: 'Access Method', value: 'Access Method'},
	{ label: 'Score', value: 'Score'},
];

// const locationSectionDetails = [
// 	{ dropdownWithSearchLabel: 1, dropdownWithLabel: 1, textbox: 0, dropSearchValue: ['Geography', 'Regions', 'Country'], dropValue: 'Include'}
// ]

class FilterSearch extends Component {
	LifecycleFilterSection = React.createRef()	
	dropRef1 = React.createRef()
	dropRef2 = React.createRef()
	dropRef3 = React.createRef()
	
	constructor(props) {
		super(props)

		this.state = {
			providers: [],
            selectedProvider: '',
			accounts: [],
			accountArray: [],
            selectedAccount: '',
            regions: [],
			selectedRegion: [],
			
			placeHolder: 'Add Filter',
			selectedDefaultOption: '',
			searchText: '',
                        			
			mainDropdown: '',
            showDropdownOption: false,
			isDefaultDropdown: true,
			
			lifecycleSection: false,
			accessedBySection: false,
			monitoringSection: false,
			accessMethodSection: false,
			scoreSection: false,
			acccountSection: false,	
			accountSelected: [],
			durationSection: false,
			selecteDuration: '',
			locationSection: false,
			serviceSection: false,
			sizeSection: false,
			filterWithLabel: [],
			filterValue: [],
		}
    }
    	
	componentDidMount = () => {
		this.setState({ isDefaultDropdown: true, 
			mainDropdown: _.orderBy(defaultDropdown, ['value'], ['Asc'])
		})
		
		this.props.listAllProviders((promise, providers) => {
			if (promise) {
				this.setState({ providers })
				if (providers.length > 0) {
					this.setState(
						{ selectedProvider: providers[0].provider_name },
					)
					this.filterIdentifiers('provider')
					this.setState({ showLoading: true })
				} else {
					this.filterIdentifiers()
				}
			} else {
				this.setState(
					{ providers: [] }, 
					() => this.filterIdentifiers()
				)
			}
		})
	}
	/**
	 * Filter identifiers based on the filter selection
	 */
	filterIdentifiers = from => {
		this.setState({ showLoading: false })
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'all') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if (from === 'provider') {
			this.props.listAllAccounts(params, (promise, accounts) => {
				if (promise) {
					this.setState({ accounts: accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase()), selectedAccount: accounts[0].account_id })
				} else {
					this.setState({ accounts: [] })
				}
            })
            
            this.props.listAllRegions(params, (promise, regions) => {
                if (promise) {
                    this.setState({ regions: regions[0].provider_regions })
                } else {
                    this.setState({ regions: [] })
                }
            })
        }
        
	}

	componentWillUnmount() {
		//document.removeEventListener('keydown', this._handleKeyDown)
	}
	
	handleClickOutside(event) {
		// if(event.target !== null) {
		// 	if (this.LifecycleFilterSection && !this.LifecycleFilterSection.current.contains(event.target)) {
		// 		this.setState({ lifecycleSection: false })
		// 	}
		// }
	}

	onKeyUp = (e) => {
		// This would have the current value after hitting backspace.
		if (e.keyCode === 8) { 
			this.setState({
				lifecycleSection: false,
				accessedBySection: false,
				monitoringSection: false,
				accessMethodSection: false,
				scoreSection: false,
				acccountSection: false,
				durationSection: false,
				locationSection: false,
				serviceSection: false,
				sizeSection: false,
			})
		}
	}
    
    filterOnchange = () => {
		this.setState({ showDropdownOption: false }, 
			() => this.onChangeChildOperation()
		)		
	}
		
	onChangeChildOperation = () => {
		let filterData = _.orderBy(defaultDropdown, ['value'], ['Asc'])
		this.setState({ selectedDefaultOption: '' })
		if(this.state.searchText !== '') {
			filterData = filterData.filter(
				filter => 
				filter.label.toLocaleLowerCase().includes(this.state.searchText) ||
				filter.value.includes(this.state.searchText)
			)
		}
		// else {
		// 	this.setState({
		// 		lifecycleSection: false,
		// 		accessedBySection: false,
		// 		monitoringSection: false,
		//		accessMethodSection: false,
		//		scoreSection: false,
		// 		acccountSection: false,
		// 		durationSection: false,
		// 		locationSection: false,
		//		serviceSection: false,
		//		sizeSection: false,
		// 	})
		// }

		this.setState({ 
			mainDropdown: filterData, 
			showDropdownOption: 
			this.state.searchText === '' ? false : true 
		})
        
	}

    comparisonFilter = () => {
		if(this.state.searchText === '') {
			this.setState({ showDropdownOption: true, selectedDefaultOption: '', acccountSection: false})
		}
	}

	internalComparisonFilter = () => {
		this.setState({ showDropdownOption: 
			this.state.selectedDefaultOption === '' ? 
				this.state.showDropdownOption ? false : true 
			: false,

			lifecycleSection: 
			this.state.selectedDefaultOption === 'Lifecycle' ? 
				this.state.lifecycleSection ? false : true 
			: false,
			
			accessedBySection: this.state.selectedDefaultOption === 'Accessed By' ? 
				this.state.accessedBySection ? false : true
			: false, 

			monitoringSection: this.state.selectedDefaultOption === 'Monitoring' ? 
				this.state.monitoringSection ? false : true
			: false, 

			accessMethodSection: this.state.selectedDefaultOption === 'Access Method' ? 
				this.state.accessMethodSection ? false : true
			: false, 			

			scoreSection: this.state.selectedDefaultOption === 'Score' ? 
				this.state.scoreSection ? false : true
			: false,

			acccountSection: this.state.selectedDefaultOption === 'Account' ? 
				this.state.acccountSection ? false : true
			: false,
			
			durationSection: this.state.selectedDefaultOption === 'Duration' ? 
				this.state.durationSection ? false : true
			: false,

			locationSection: this.state.selectedDefaultOption === 'Location' ? 
				this.state.locationSection ? false : true
			: false,

			serviceSection: this.state.selectedDefaultOption === 'Service' ? 
				this.state.serviceSection ? false : true
			: false,
						
			sizeSection: this.state.selectedDefaultOption === 'Size' ? 
				this.state.sizeSection ? false : true
			: false,
		})
	}
	
	selectedOptionFunction = (selectedOption) => {
		if(this.state.selectedDefaultOption === '' && selectedOption !== '') {
			this.setState({ 
				selectedDefaultOption: selectedOption, 
				showDropdownOption: false, 
				lifecycleSection: selectedOption === 'Lifecycle' ? true : false,  accessedBySection: selectedOption === 'Accessed By' ? true : false,
				monitoringSection: selectedOption === 'Monitoring' ? true : false,
				accessMethodSection: selectedOption === 'Access Method' ? true : false,
				scoreSection: selectedOption === 'Score' ? true : false,
				acccountSection: selectedOption === 'Account' ? true : false,
				durationSection: selectedOption === 'Duration' ? true : false,
				locationSection: selectedOption === 'Location' ? true : false,
				serviceSection: selectedOption === 'Service' ? true : false,
				sizeSection:  selectedOption === 'Size' ? true : false,
				searchText: selectedOption +': ', 
				placeHolder: '',
			})
		} else if(selectedOption !== '') {
			this.setState({ 
				showDropdownOption: false, 
				lifecycleSection: selectedOption === 'Lifecycle' ? true : false,  accessedBySection: selectedOption === 'Accessed By' ? true : false,
				monitoringSection: selectedOption === 'Monitoring' ? true : false,
				accessMethodSection: selectedOption === 'Access Method' ? true : false,
				scoreSection: selectedOption === 'Score' ? true : false,
				acccountSection: selectedOption === 'Account' ? true : false,
				durationSection: selectedOption === 'Duration' ? true : false,
				locationSection: selectedOption === 'Location' ? true : false,
				serviceSection: selectedOption === 'Service' ? true : false,
				sizeSection:  selectedOption === 'Size' ? true : false,
			})
		}

		if(selectedOption === 'Account') {
			let accountdata = []
			if(this.state.accounts.length) {
                this.state.accounts.forEach((account, i) => {
                    let dataRow = {}
					//dataRow.value = account.account_id
					// dataRow.value = account.account_name
                    // dataRow.label = account.account_name
                    // if(this.state.accountSelected.length) {
					// 	this.state.accountSelected.forEach(row => {
					// 		if(account.account_id === row.value){
					// 			dataRow.checked = true
					// 		}
					// 	})
					// }
					let exist = 0
					if(this.state.accountSelected.length) {
						this.state.accountSelected.forEach((row, index) => {
							if(account.account_name === row.value) {
								exist = 1
							}
						})
						if(exist === 0) {
							dataRow.value = account.account_name
							dataRow.label = account.account_name
							accountdata.push(dataRow)
						}
					} else {
						dataRow.value = account.account_name
						dataRow.label = account.account_name
						accountdata.push(dataRow)
					}
					
                    // if(dataRow.checked === undefined) {
                    //     dataRow.checked = 0
                    // }
                })
			}

			this.setState({ accountArray: accountdata })
		}

	}

	selectedLifecycleFunction = (lifeCycle) => {
		this.setState({ searchText: '' })
		if(lifeCycle !== false) {
			//console.log('lifecycle',lifeCycle)

			Object.entries(lifeCycle).forEach(([key, value]) => {
				let item1 = '';
				let item2 = '';
				let item3 = '';
				let item4 = '';
				value.forEach(item => {
					if(item.label === 'Action') {
						item1 = item.value
					}
					if(item.label === 'Criteria') {
						item2 = item.value
					}
					if(item.label === 'Condition') {
						item3 = item.value
					}
					if(item.label === 'text') {
						item4 = item.value
					}
				})

				let dataRow = {}
				dataRow.label = 'Lifecycle';
				dataRow.value = item1+' '+item2+' '+item3+' '+item4
				dataRow.checkDuplicate = item1+' '+item2+' '+item3

				if(dataRow.value !== '') {
					//let filterArray = this.state.filterValue
					// this.state.filterValue.forEach((row, index) => {
					// 	let splitSpace = row.value.split(' ');
					// 	if(row.label === 'Lifecycle' && splitSpace[0] === item1 && splitSpace[1] === item2 && splitSpace[2] === item3) {
					// 		filterValueExist = 1
					// 		filterArray.splice(index, 1);
					// 		this.setState({ filterValue : filterArray });
							
					// 		this.setState(prevState => ({
					// 			filterValue: [...prevState.filterValue, dataRow]
					// 		}))
					// 	}
						
					// })
					this.setState(prevState => ({
						filterValue: [...prevState.filterValue, dataRow], searchText: ''
					}),
						() => {
							this.checkDuplicate('Lifecycle')
							this.filterOnchange()
						}
					)
				}
			})
		}

		this.setState({ lifecycleSection: false })
	}

	selectedAccessedByFunction = (accessedby) => {
		this.setState({ searchText: '' })
		if(accessedby !== false) {
			console.log('accessedby',accessedby)
			let cross = accessedby.cross
			let root = accessedby.root
			let crossLabel = ''
			if(cross) {
				crossLabel = ' from Cross account'
			}

			let rootLabel = ''
			if(root) {
				rootLabel = ' from Root account'
			}
			Object.entries(accessedby).forEach(([key, value]) => {
				// let item1 = '';
				// let item2 = '';
				// let item3 = '';
				// let item4 = '';
				if(key === 'Role' || key === 'User') {
					value.forEach(item => {
						let dataRow = {}
						dataRow.label = 'Accessed By'
						dataRow.value = key+' : '+item+rootLabel+crossLabel
						dataRow.checkDuplicate = key+' : '+item+rootLabel+crossLabel
						// this.state.filterValue.forEach((row, index) => {
						// 	if(row.label === 'Accessed By' && row.value === item) {
						// 		filterArray.splice(index, 1);
						// 	}
						// 	this.setState({ filterValue : filterArray });
						// })
						
						this.setState(prevState => ({
							filterValue: [...prevState.filterValue, dataRow], searchText: ''
						}),
							() => {
								this.checkDuplicate('Accessed By')
								this.filterOnchange()
							}
						)
					})
				}
			})
		}
		this.setState({ accessedBySection: false })
	}

	selectedMonitoringFunction = (monitor) => {
		this.setState({ searchText: '' })
		if(monitor !== false) {
			console.log('monitor',monitor)

			Object.entries(monitor).forEach(([key, value]) => {
				let item1 = '';
				let item2 = '';
				let item3 = '';
				let item4 = '';
				value.forEach(item => {
					if(item.label === 'Monitoring') {
						item1 = item.value
					}
					if(item.label === 'Criteria') {
						item2 = item.value
					}
					if(item.label === 'Condition') {
						item3 = item.value
					}
					if(item.label === 'text') {
						item4 = item.value
					}
				})
				let dataRow = {}
				dataRow.label = 'Monitoring';
				dataRow.value = item1+' '+item2+' '+item3+' '+item4
				dataRow.checkDuplicate = item1+' '+item2+' '+item3

				this.setState(prevState => ({
					filterValue: [...prevState.filterValue, dataRow], searchText: ''
				}), 
					() => {
						this.checkDuplicate('Monitoring')
						this.filterOnchange()
					}
				)
			})
		}
		this.setState({ monitoringSection: false })
	}

	selectedAccessMethodFunction = (accessMethod) => {
		this.setState({ searchText: '' })
		if(accessMethod !== false) {
			console.log('accessMethod',accessMethod)

			Object.entries(accessMethod).forEach(([key, value]) => {
				let item1 = '';
				let item2 = '';
				let item3 = '';
				let item4 = '';
				value.forEach(item => {
					if(item.label === 'Access Method') {
						item1 = item.value
					}
					if(item.label === 'Criteria') {
						item2 = item.value
					}
					if(item.label === 'Condition') {
						item3 = item.value
					}
					if(item.label === 'text') {
						item4 = item.value
					}
				})
				let dataRow = {}
				dataRow.label = 'Access Method';
				dataRow.value = item1+' '+item2+' '+item3+' '+item4
				dataRow.checkDuplicate = item1+' '+item2+' '+item3
				// let filterValueExist = 0
				// let filterArray = this.state.filterValue

				// if(this.state.filterValue.lenght) {
				// 	this.state.filterValue.forEach((row, index) => {
				// 		let splitSpace = row.value.split(' ');
				// 		if(row.label === 'Access Method' && splitSpace[0] === item1 && splitSpace[1] === item2 && splitSpace[2] === item3) {
				// 			filterValueExist = 1
				// 			filterArray.splice(index, 1);
							
				// 			this.setState({ filterValue : filterArray });

				// 			this.setState(prevState => ({
				// 				filterValue: [...prevState.filterValue, dataRow]
				// 			}))
				// 		}
				// 	})
				// }

				this.setState(prevState => ({
					filterValue: [...prevState.filterValue, dataRow], searchText: ''
				}), 
					() => {
						this.checkDuplicate('Access Method')
						this.filterOnchange()
					}
						
				)
			})
		}

		this.setState({ accessMethodSection: false })
	}

	checkDuplicate = (label) => {
		console.log(label)
		let arr = this.state.filterValue
		console.log(arr)
		var reversed = [];
		for(let i = arr.length-1; i >= 0; i--) {
			reversed.push(arr[i]);
		}
		
		//const reversed = arr.reverse();
		console.log(reversed)
		let duplicate = reversed.filter( (ele, ind) => ind === reversed.findIndex( elem => elem.label === ele.label && elem.checkDuplicate === ele.checkDuplicate))
		console.log(reversed)
		console.log(duplicate)
		var regular = [];
		for(let i = duplicate.length-1; i >= 0; i--) {
			regular.push(duplicate[i]);
		}
		this.setState({ filterValue: regular, searchText: '' })
	}
	
	selectedScoreFunction = (score) => {
		this.setState({ searchText: '' })
		if(score !== false) {
			console.log('score',score)

			Object.entries(score).forEach(([key, value]) => {
				let item1 = '';
				let item2 = '';
				let item3 = '';
				let item4 = '';
				value.forEach(item => {
					if(item.label === 'Score') {
						item1 = item.value
					}
					if(item.label === 'Condition') {
						item2= item.value
					}
					if(item.label === 'text_1') {
						item3 = item.value
					}
					if(item.label === 'text_2') {
						item4 = item.value
					}
				})
				let dataRow = {}
				dataRow.label = 'Score';
				if(item2 === 'between') {
					dataRow.value = item1+' '+item2+' '+item3+' to '+item4
				} else {
					dataRow.value = item1+' '+item2+' '+item3
				}
				dataRow.checkDuplicate = item1+' '+item2

				this.setState(prevState => ({
					filterValue: [...prevState.filterValue, dataRow], searchText: ''
				}), 
					() => {
						this.checkDuplicate('Score')
						this.filterOnchange()
					}
				)
			})
		}
		this.setState({ monitoringSection: false })
		this.setState({ scoreSection: false })
	}

	selectedAccountFunction = (value) => {
		//console.log('account',value)
		
		let dataRow = {}
		dataRow.label = 'Account'
		dataRow.value = value
		dataRow.checkDuplicate = value

		let accountExist = 0
		var accountArray = this.state.accountSelected
		this.state.accountSelected.forEach((row, index) => {
			if(row.label === 'Account' && row.value === value) {
				accountArray.splice(index, 1);
				accountExist = 1;
			}
			this.setState({ accountSelected : accountArray, searchText: '' });
		})
		
		if(accountExist === 0) {
			this.setState(prevState => ({
				accountSelected: [...prevState.accountSelected, dataRow],
				searchText: ''
			}))
		}

		if(this.state.searchText === '' && this.state.accountSelected.length === 0) {
			this.setState({ selectedDefaultOption: '' },
				() => this.selectedOptionFunction('Account')
			)
		}

		let filterValueExist = 0
		let filterArray = this.state.filterValue
		this.state.filterValue.forEach((row, index) => {
			if(row.label === 'Account' && row.value === value) {
				filterValueExist = 1
				filterArray.splice(index, 1);
			}
			this.setState({ filterValue : filterArray });
		})
		if(filterValueExist === 0) {
			this.setState(prevState => ({
				filterValue: [...prevState.filterValue, dataRow], searchText: ''
			}))
		}

		//this.setState({ acccountSection: false })

		console.log('account', this.state.accountSelected)
	}
	
	selectedDurationFunction = (value) => {
		let dataRow = {}
		dataRow.value = 'from '+ value[0].from+' to '+value[0].to
		dataRow.label = 'Duration'
		dataRow.checkDuplicate = 'Duration'
		this.setState(prevState => ({
			filterValue: [...prevState.filterValue, dataRow], searchText: ''
		}), 
			() =>  {
				this.checkDuplicate('Duration')
				this.filterOnchange()
			}
		)
		this.setState({ durationSection: false })
	}

	selectedLocationFunction = (value) => {
		
		if(value !== false) {
			if(value.country.country && value.country.country.length > 0) {
				value.country.country.forEach((selected, i) => {
					let dataRow = {}
					dataRow.value = 'Country '+value.country.type+' '+selected
					dataRow.label = 'Location'
					dataRow.checkDuplicate = 'Country '+selected
					this.setState(prevState => ({
						filterValue: [...prevState.filterValue, dataRow], searchText: ''
					}),
						() => {
							this.checkDuplicate('Location')
							this.filterOnchange()
						}
					)
				})
			}

			if(value.geography.geography && value.geography.geography.length > 0) {
				value.geography.geography.forEach((selected, i) => {
					let dataRow = {}
					dataRow.value = 'Geography '+selected
					dataRow.label = 'Location'
					dataRow.checkDuplicate = 'Geography '+selected

					this.setState(prevState => ({
						filterValue: [...prevState.filterValue, dataRow], searchText: ''
					}),
						() => {
							this.checkDuplicate('Location')
							this.filterOnchange()
						}
					)
				})
			}

			if(value.region.region && value.region.region.length > 0) {
				value.region.region.forEach((selected, i) => {
					let dataRow = {}
					dataRow.value = 'Region '+selected
					dataRow.label = 'Location'
					dataRow.checkDuplicate = 'Region '+selected

					this.setState(prevState => ({
						filterValue: [...prevState.filterValue, dataRow], searchText: ''
					}),
						() => {
							this.checkDuplicate('Location')
							this.filterOnchange()
						}
					)
				})
			}
		}
		this.setState({ locationSection: false })
	}

	selectedServiceFunction = (services) => {
		this.setState({ searchText: '' })
		if(services !== false) {
			console.log(services)
			if(services.service && services.service.length > 0) {
				services.service.forEach((selected, i) => {
					let dataRow = {}
					dataRow.value = services.type+' '+selected
					dataRow.label = 'Services'
					dataRow.checkDuplicate = selected
					console.log(dataRow)
					this.setState(prevState => ({
						filterValue: [...prevState.filterValue, dataRow], searchText: ''
					}),
						() => {
							this.checkDuplicate('Services')
							this.filterOnchange()
						}
					)
				})
			}
		}
		this.setState({ serviceSection: false })
	}

	selectedSizeFunction = (sizes) => {
		this.setState({ searchText: '' })
		if(sizes !== false) {
			console.log(sizes)
			if(sizes.size && sizes.size.length > 0) {
				sizes.size.forEach((selected, i) => {
					let dataRow = {}
					dataRow.value = sizes.type+' '+selected
					dataRow.label = 'Sizes'
					dataRow.checkDuplicate = selected
					console.log(dataRow)
					this.setState(prevState => ({
						filterValue: [...prevState.filterValue, dataRow], searchText: ''
					}),
						() => {
							this.checkDuplicate('Sizes')
							this.filterOnchange()
						}
					)
				})
			}
		}
		this.setState({ sizeSection: false })
	}
	
	removefilter = (index, label, value) => {
		let filterArray = this.state.filterValue
		filterArray.splice(index, 1);
		this.setState({ filterValue : filterArray });
	}
	
	
	/**
	 * Renders User Insights
	 */
	render() {
		return (
			<div className='container-fluid overflow-auto flex-grow-1'
                onClick={ (event) => { this.handleClickOutside(event) } }
                >
				<div className='row h-100'>
					<div className='col-sm-12 p-0'>
						<div className='title-section' style={{padding: "1rem 1rem 1rem 1rem"}} >
							<div className='row'>
								<div className='col-md-4 d-flex align-self-center'>
									<div>
										<div className='d-flex'>
                                            <h6 className='text-white m-0'>Dashboard</h6>
                                        </div>
										<p className='text-white m-0'>
											Detailed results of various types of user insights performed on your cloud infrastructure is presented here
										</p>
									</div>
								</div>
								<div className={`col-md-8 text-right`}>
								<div className='d-flex'>
									<div
										className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 ${!this.state.providers
											.length}`}>
										<label>Provider</label>
										<select
											className='form-control'
											value={this.state.selectedProvider}
											onChange={event => {
												this.setState(
													{
														selectedProvider: event.target.value,
														showLoading: true,
													},
													() => this.filterIdentifiers('provider')
												)
											}}
										>
											<option value='all'>All</option>
											{this.state.providers.length &&
												this.state.providers.map(provider => {
													return (
														<option key={provider.provider_id} value={provider.provider_name}>
															{provider.provider_name}
														</option>
													)
												})}
										</select>
									</div>
									<div
										className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 ${!this.state.accounts
											.length}`}>
										<label>Account</label>
										<select
											className='form-control'
											value={this.state.selectedAccount}
											disabled={!this.state.accounts.length}
											onChange={event => {
												this.setState(
													{
														selectedAccount: event.target.value,
													},
													() => this.getIdentitiesList()
												)
											}}
										>
											{this.state.accounts.length &&
												this.state.accounts.map((account, i) => {
													return (
														<option key={i} value={account.account_id}>
															{account.account_name}
														</option>
													)
												})}
										</select>
									</div>
                                    <div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`}>
										<label>Region</label>
										<select
											className='form-control'
											value={this.state.selectedRegion}
											onChange={event => {
												this.setState(
													{
														selectedRegion: event.target.value,
													},
													() => this.filterIdentifiers('regions')
												)
											}}
											>
											<option value='all'>All</option>
											{this.state.regions &&
												this.state.regions.length &&
												this.state.regions.map((region, i) => {
													return (
														<option key={i} value={region.region}>
															{region.name}
														</option>
													)
												})}
										</select>
									</div>
								</div>									
								</div>
							</div>
						</div>
						<div className='bashboard-bg'>
							<div className="box p-3 shadow-none">
								<div className="row">
									<div className="col-sm-12 pl-0 marginLeftMinus5">
										<div className="row border-bottom pb-3 ml-0">
											<div className="col-sm-12">
												<div className="mb-1">
													<p className='font-weight-bold m-0 f15'>Filters</p>
													<p className="mb-0 small text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
												</div>
												<div className='mt-2 commonDropdown'>   
                                                    <div className="form-control d-flex flex-wrap h-auto">
														{
															this.state.filterValue.length ? this.state.filterValue.map((row, index) =>
															<span className="badge badge-light f14 mr-1 mb-1" >
																{
																	row.label +': ' + row.value} 
																	<i onClick={ () => this.removefilter(index, row.label, row.value) } className='ml-1 fal fa-times cursorPointer'></i>
															</span>
															) : null
														}
                                                        <div className="comparisonTextboxDiv">
                                                            <input type="text" className={`comparisonTextbox`}
                                                            onChange={e => {this.setState({ searchText: e.target.value }, () => this.filterOnchange())}}
															onKeyUp={this.onKeyUp}
                                                            value={this.state.searchText}
															/*{this.state.selectedDefaultOption === '' ? this.state.searchText : this.state.selectedDefaultOption +': ' } */
															onClick={ () => this.comparisonFilter() }
                                                            placeholder={this.state.placeHolder}
                                                        />

															{this.state.showDropdownOption ? 
																<CommonDropdown 
																	data = {this.selectedOptionFunction.bind(this)}
																	hideHeader = {true}
																	headerName = {'Dropdown'}
																	dropOptions = {this.state.mainDropdown}
																	displayMenu = {this.state.showDropdownOption}
																	isMultiSelect = {false}
																	isDefaultDropdown = {this.state.isDefaultDropdown}
																	styleTop={'fitContent'}
																/> 
															:
															null
															}

															{this.state.acccountSection ? 
																<CommonDropdown 
																	data = {this.selectedAccountFunction.bind(this)}
																	hideHeader = {true}
																	headerName = {'Account'}
																	dropOptions = {this.state.accountArray}
																	displayMenu = {true}
																	isMultiSelect = {true}
																	isDefaultDropdown = {false}
																	styleTop={'fitContent'}
																/>
															:
															null
															}

															{this.state.durationSection ? 
																<DurationFilterSection 
																	data = {this.selectedDurationFunction.bind(this)}
																	//sectionDetails = {''}
																/>
															:
															null
															}

															{this.state.lifecycleSection ? 
																<div ref={this.LifecycleFilterSection}>
																<LifecycleFilterSection 
																	data = {this.selectedLifecycleFunction.bind(this)}
																	//sectionDetails = {''}
																/>
																</div>
															: null
															}

															{this.state.accessedBySection ?	
																<AccessedByFilterSection 
																	data = {this.selectedAccessedByFunction.bind(this)}
																	selectedProvider = {this.state.selectedProvider.toLowerCase()}
																	selectedAccount = {this.state.selectedAccount}
																	//sectionDetails = {''}
																/>
															: null
															}

															{this.state.monitoringSection ?	
																<MonitoringFilterSection 
																	data = {this.selectedMonitoringFunction.bind(this)}
																	selectedProvider = {this.state.selectedProvider.toLowerCase()}
																	selectedAccount = {this.state.selectedAccount}
																	//sectionDetails = {''}
																/>
															: null
															}

															{this.state.accessMethodSection ?	
																<AccessMethodFilterSection 
																	data = {this.selectedAccessMethodFunction.bind(this)}
																	selectedProvider = {this.state.selectedProvider.toLowerCase()}
																	selectedAccount = {this.state.selectedAccount}
																	//sectionDetails = {''}
																/>
															: null
															}

															{this.state.scoreSection ?	
																<ScoreFilterSection 
																	data = {this.selectedScoreFunction.bind(this)}
																	selectedProvider = {this.state.selectedProvider.toLowerCase()}
																	selectedAccount = {this.state.selectedAccount}
																	//sectionDetails = {''}
																/>
															: null
															}

															{this.state.locationSection ?	
																<LocationFilterSection 
																	data = {this.selectedLocationFunction.bind(this)}
																	selectedProvider = {this.state.selectedProvider.toLowerCase()}
																	selectedAccount = {this.state.selectedAccount}
																	sectionDetails = {this.state.selectedDefaultOption}
																/>
															: null
															}

															{this.state.serviceSection ?	
																<ServiceFilterSection 
																	data = {this.selectedServiceFunction.bind(this)}
																	selectedProvider = {this.state.selectedProvider.toLowerCase()}
																	selectedAccount = {this.state.selectedAccount}
																	sectionDetails = {this.state.selectedDefaultOption}
																/>
															: null
															}

															{this.state.sizeSection ?	
																<SizeFilterSection 
																	data = {this.selectedSizeFunction.bind(this)}
																	selectedProvider = {this.state.selectedProvider.toLowerCase()}
																	selectedAccount = {this.state.selectedAccount}
																	sectionDetails = {this.state.selectedDefaultOption}
																/>
															: null
															}
                                                        </div>
                                                    </div>
                                                </div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
FilterSearch.propTypes = {
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		
	}
}

export default AppWrapper(FilterSearch, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions
})
