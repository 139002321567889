import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Checkbox from '../../../common/formFields/Checkbox'
import TextField from '../../../common/formFields/TextField'
import { setAiopsPropsDetails } from '../../../../actions/aiops/AiopsAction'
import { getAssetDetailExt } from '../../../../actions/aiops/RemediationAction'

class ExpandRDSVolume extends Component {

  constructor(props) {
    super(props);
    this.state = props || {};
    this.state = {
      validationError: this.props.validationError,
      validationErrorMessage: this.props.validationErrorMessage,
      parentValue: this.props.value,
    }
    this.updateFormFieldValue = this.updateFormFieldValue.bind(this);
    this.updateTextFormFieldValue = this.updateTextFormFieldValue.bind(this);
  }

  componentDidMount = () => {
    let params = {}
    params.provider = this.props.caseDetails.provider ? (Array.isArray(this.props.caseDetails.provider) ? this.props.caseDetails.provider[0].toLowerCase() : this.props.caseDetails.provider.toLowerCase()) : ''
    params.service_name = this.props.aiopsSelectedRemediationProps.service_name
    params.asset_id = this.props.aiopsSelectedRemediationProps.asset_id

    this.props.getAssetDetailExt(params, (promise, result) => {
      console.log(result,'/././././')
      if(promise) {
        this.setState({ db_instance_allocated_storage: result && result[0] && result[0].db_instance_allocated_storage ? result[0].db_instance_allocated_storage : ''  })
      } else {
        this.setState({ result: [] })
      }
    })
  }

	componentDidUpdate = (prevProps) => {
		if(this.props.validationError !== prevProps.validationError) {
			this.setState({ 
        validationError: this.props.validationError, 
        AllocatedStorageError: this.props.aiopsRemediationParams.AllocatedStorageError ? this.props.aiopsRemediationParams.AllocatedStorageError : '',
        ApplyImmediatelyError: this.props.aiopsRemediationParams.ApplyImmediatelyError ? this.props.aiopsRemediationParams.ApplyImmediatelyError : ''
      })
		}
	}

  updateFormFieldValue = (fieldName, fieldValue) => {
    let obj = this.props.aiopsRemediationParams
    obj[fieldName] = fieldValue
    obj.processor_rule_id = this.props.selectedRecord
    this.props.setAiopsPropsDetails('remediationParams', obj)
    this.setState({ [fieldName]: fieldValue, ApplyImmediatelyError: '' });
  };

  updateTextFormFieldValue = (fieldName, fieldValue) => {
    let obj = this.props.aiopsRemediationParams
    obj[fieldName] = fieldValue
    obj.processor_rule_id = this.props.selectedRecord
    this.props.setAiopsPropsDetails('remediationParams', obj)
    this.setState({ [fieldName]: fieldValue, AllocatedStorageError: '',  });
  };

  render() {
    return (
      <div className="row mt-2">
        <div className="col-md-12">
          <div className="form-group">
            <label>Storage to allocate <small className="ml-1">Current Storage: {this.state.db_instance_allocated_storage ? this.state.db_instance_allocated_storage : ''} GB </small></label>
            <TextField
              className="form-control bg-muted text-gray3 col-sm-3"
              labelClassName={'text-gray3'}
              recordSelection={this.updateTextFormFieldValue}
              value={this.state.AllocatedStorage ? this.state.AllocatedStorage : ''}
              name={'AllocatedStorage'}
              label="Storage to allocate"
              placeholder=""
              required={true}
              unitLabel={'GiB'}
              number={true}
            />
            {this.state.validationError && this.state.AllocatedStorageError && this.state.AllocatedStorageError !== '' ?
              <small className='text-danger'>{this.state.AllocatedStorageError}</small>
            : null }
          </div>
          <div className="form-group">
            <Checkbox 
              className="form-check-input"
              labelClassName={'text-white'}
              recordSelection={this.updateFormFieldValue}
              value={this.state.ApplyImmediately ? true : false}
              name={'ApplyImmediately'}
              label={'ApplyImmediately'}
            />
            {this.state.validationError && this.state.ApplyImmediatelyError && this.state.ApplyImmediatelyError !== '' ?
              <small className='text-danger'>{this.state.ApplyImmediatelyError}</small>
            : null }
          </div>
        </div>
      </div>
    )
  }
};

/**
 * Type of the props used in the component
 */
 ExpandRDSVolume.propTypes = {
  getAssetDetailExt: PropTypes.func,
  setAiopsPropsDetails: PropTypes.func
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
 const mapStateToProps = state => {	
	console.log('ExpandRDSVolume', state)  
	return {
    caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : [],
    aiopsRemediationParams: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.remediationParams ? state.aiops.aiopsPropsDetails.remediationParams : {},
    aiopsSelectedRemediationProps: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.selected_remediation ? state.aiops.aiopsPropsDetails.selected_remediation : {},
  }
}


export default connect(mapStateToProps, {
  getAssetDetailExt,
  setAiopsPropsDetails
})(withRouter(ExpandRDSVolume))
