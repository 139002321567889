/*************************************************
 * Tvastar
 * @exports
 * @file governanceAction.js
 * @author Rajasekar // on 27/02/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'

import {
	DETECTORS,
	GET_NON_COMPLAINT_ASSETS,
	
	GOVERNANCE,
	GOVERNANCE_RULES_LIST,
	GOVERNANCE_CLONED_RULES_LIST,
	GOVERNANCE_GET_RULE_COUNT,
	CLONE_RULE,
	UPDATE_CLONED_RULE,
	VALIDATE_IMAGE,
	VALIDATE_REGISTRY_CONTAINER,	
	DELETE_CLONED_RULE,	
	LIST_GOVERNANCE_CATEGORIES,
	GET_GOVERNANCE_MASTER_RULE,
	CREATE_USER_RULE,	
	UPDATE_USER_RULE,
	LIST_USER_GOVERNANCE_CATEGORIES,
	LIST_USER_RULES,
	GET_GOVERNANCE_USER_RULES,
	UPDATE_STATUS_RULE,
	DELETE_USER_RULE,

	CONFIGURATION,
	LIST_CONFIGURATION,
	INSERT_CONFIGURATON,
	DELETE_CONFIGURATON,
	UPDATE_CONFIGURATION,
	
	TAGGING,
	GET_ASSETS_BY_TAG_OPERATIONS,
	GET_TAG_COMPLIANCE_AGGREGATION_COUNT,
	GET_TAG_COMLIANCE_TOTAL_COUNT,
	GET_TAGS_BY_OPERATION_COUNT,
	GET_ALL_TAGS,
	
	
	IAM_GOVERNANCE,
	LIST_POLICY,
	
	COMMON_SERVICES,
	LIST_LOG_TYPE,
	LIST_STORAGE_TYPES,
	LIST_SERVICE_ENGINES,
	LIST_OPERATING_SYSTEM,
	
	PRICING_END_POINT,
	LISTVMS,
	
	SYNCHRONIZER,
	LIST_ASSETS
	
	
} from '../../config'
import { ACTION_TYPES } from '../types'
import { myLog } from '../../utils/utility'
import _ from 'lodash'
import axios from 'axios'

/**
 * Action to get all governance rules list
 * @param {Object} body
 * @param {Function} callback
 */
export const listAllRules = (body, callback) => {
	return async dispatch => {
		try {
			const rulesList = await API.post(GOVERNANCE, GOVERNANCE_RULES_LIST, { body })
			dispatch({
				type: ACTION_TYPES.SET_RULES_LIST,
				payload: rulesList,
			})
			dispatch({
				type: ACTION_TYPES.SET_GOVERNANCE_SELECTED_TABLE_ROW,
				payload: rulesList.results[0],
			})
			callback(true)
		} catch (error) {
			myLog('rulesList', error)

			dispatch({
				type: ACTION_TYPES.SET_RULES_LIST,
				payload: {},
			})
			callback(false)
		}
	}
}
/**
 * Action to get all policies list
 * @param {Object} body
 * @param {Function} callback
 */
export const listAllPolicies = (body, callback) => {
	return async dispatch => {
		try {
			const policyList = await API.post(IAM_GOVERNANCE, LIST_POLICY, { body })
			dispatch({
				type: ACTION_TYPES.SET_POLICY_LIST,
				payload: policyList,
			})
			dispatch({
				type: ACTION_TYPES.SET_POLICY_SELECTED_TABLE_ROW,
				payload: policyList.results[0],
			})
			callback(true)
		} catch (error) {
			myLog('listAllPolicies', error)
			dispatch({
				type: ACTION_TYPES.SET_POLICY_SELECTED_TABLE_ROW,
				payload: {},
			})
			callback(false)
		}
	}
}
/**
 * Action to set selected table row policy
 */
export const setSelectedPolicyTableRow = row => {
	return async dispatch => {
		dispatch({
			type: ACTION_TYPES.SET_POLICY_SELECTED_TABLE_ROW,
			payload: row,
		})
	}
}
/**
 * Action to get all governance cloned rules list
 * @param {Object} body
 * @param {Function} callback
 */
export const listAllClonedRules = (body, callback) => {
	return async dispatch => {
		try {
			const clonedRulesList = await API.post(GOVERNANCE, GOVERNANCE_CLONED_RULES_LIST, { body })
			dispatch({
				type: ACTION_TYPES.SET_RULES_LIST,
				payload: clonedRulesList,
			})
			dispatch({
				type: ACTION_TYPES.SET_GOVERNANCE_SELECTED_TABLE_ROW,
				payload: clonedRulesList.results[0],
			})
			callback(true)
		} catch (error) {
			myLog('clonedRulesList', error)
			dispatch({
				type: ACTION_TYPES.SET_RULES_LIST,
				payload: [],
			})
			callback(false)
		}
	}
}

/**
 * Action to get all categories list
 * @param {Object} body
 * @param {Function} callback
 */
export const listAllCategories = (body, callback) => {
	return async dispatch => {
		try {
			const categoryList = await API.post(GOVERNANCE, GOVERNANCE_GET_RULE_COUNT, { body })			
			dispatch({
				type: ACTION_TYPES.SET_GOVERNANCE_PROVIDER_COUNT_LIST,
				payload: categoryList.results ? categoryList.results.provider_count : [],
			})
			dispatch({
				type: ACTION_TYPES.SET_GOVERNANCE_PRIORITY_COUNT_LIST,
				payload: categoryList.results ? categoryList.results.priority_count : [],
			})
			dispatch({
				type: ACTION_TYPES.SET_GOVERNANCE_RESOURCE_COUNT_LIST,
				payload: categoryList.results ? categoryList.results.resource_type_count : [],
			})
			callback()
		} catch (error) {
			callback()
			myLog('categoryList', error)
		}
	}
}
/**
 * Action to get all categories list
 * @param {Object} body
 * @param {Function} callback
 */
export const listAllConfigurations = (body, callback) => {
	return async dispatch => {
		try {
			const configList = await API.post(CONFIGURATION, LIST_CONFIGURATION, { body })
			dispatch({
				type: ACTION_TYPES.SET_GOVERNANCE_CONFIG_LIST,
				payload: configList,
			})
			callback(true)
		} catch (error) {
			callback(false)
			myLog('configList', error)
		}
	}
}
/**
 * Action to set selected filter
 */
export const setGovernanceFilter = (filter, selectedValue) => {
	return async (dispatch, getState) => {
		let selectedFilters = getState().governance.selectedFilters
		selectedFilters[filter] = selectedValue
		dispatch({
			type: ACTION_TYPES.SET_SELECTED_GOVERNANCE_FILTER,
			payload: selectedFilters,
		})
	}
}
/**
 * Action to set selected table row
 */
export const setSelectedTableRow = row => {
	return async dispatch => {
		dispatch({
			type: ACTION_TYPES.SET_GOVERNANCE_SELECTED_TABLE_ROW,
			payload: row,
		})
	}
}
/**
 * Action to set selected table row
 */
export const setCategoryList = list => {
	return async dispatch => {
		dispatch({
			type: ACTION_TYPES.SET_GOVERNANCE_CATEGORY_LIST,
			payload: list,
		})
	}
}
/**
 * Action to GET_ALL_TAGS
 * @param {Object} body
 * @param {Function} callback
 */
export const getAllTags = body => {
	return async dispatch => {
		try {
			const tags = await API.post(TAGGING, GET_ALL_TAGS, { body })
			dispatch({
				type: ACTION_TYPES.SET_ALL_TAGS,
				payload: tags,
			})
		} catch (error) {
			myLog('getAllTags', error)
		}
	}
}
/**
 * Action to set edit rule details
 */
export const setInitialRuleDetails = ruleDetails => {
	return async dispatch => {
		dispatch({
			type: ACTION_TYPES.SET_EDIT_RULE_DETAILS,
			payload: ruleDetails,
		})
	}
}
/**
 * Action to set edit rule details
 */
export const setInitialEditTagGroupDetails = () => {
	return async dispatch => {
		dispatch({
			type: ACTION_TYPES.SET_EDIT_TAG_GROUP_DETAILS_TO_INITIAL,
		})
	}
}

/**
 * Action to set edit rule details
 */
export const setEditRuleDetails = (key, value) => {
	return async (dispatch, getState) => {
		let editRuleDetails = JSON.parse(JSON.stringify(getState().governance.editRuleDetails))
		editRuleDetails[key] = value
		dispatch({
			type: ACTION_TYPES.SET_EDIT_RULE_DETAILS,
			payload: editRuleDetails,
		})
	}
}

export const setEditRulePreviewDetails = (key, value) => {
	return async (dispatch, getState) => {
		let editRulePreviewDetails = JSON.parse(JSON.stringify(getState().governance.editRuleDetails.preview))
		editRulePreviewDetails[key] = value
		dispatch({
			type: ACTION_TYPES.SET_EDIT_RULE_PREVIEW_DETAILS,
			payload: editRulePreviewDetails,
		})
	}
}
/**
 * Action to set submit rule details
 */
export const saveRule = (body, callback) => {
	return async () => {
		try {
			const response = await API.post(GOVERNANCE, CLONE_RULE, { body })
			
			if (response.status) {
				callback(true)
			}
		} catch (error) {
			callback(false)
			myLog('saveRule', error)
		}
	}
}
/**
 * Action to update cloned rule
 */
export const updateClonedRule = (body, callback) => {
	return async () => {
		try {
			const response = await API.post(GOVERNANCE, UPDATE_CLONED_RULE, { body })
			if (response.status) {
				callback(true)
			}
		} catch (error) {
			callback(false)
			myLog('saveRule', error)
		}
	}
}
/**
 * Action to insert config
 * @param {Object} body
 * @param {Function} callback
 */
export const insertConfiguration = (body, callback) => {
	return async () => {
		try {
			const insertResponse = await API.post(CONFIGURATION, INSERT_CONFIGURATON, { body })
			if (insertResponse) {
				callback(true, insertResponse)
			}
		} catch (error) {
			callback(false)
			myLog('insertConfiguration', error)
		}
	}
}

/**
 * Action to update config
 * @param {Object} body
 * @param {Function} callback
 */
export const updateConfiguration = (body, callback) => {
	return async () => {
		try {
			const updateResponse = await API.post(CONFIGURATION, UPDATE_CONFIGURATION, { body })
			if (updateResponse) {
				callback(true, updateResponse)
			}
		} catch (error) {
			callback(false)
			myLog('updateResponse', error)
		}
	}
}
/**
 * Action to delete config
 * @param {Object} body
 * @param {Function} callback
 */
export const deleteConfiguration = (body, callback) => {
	return async (dispatch, getState) => {
		try {
			const deleteResponse = await API.post(CONFIGURATION, DELETE_CONFIGURATON, { body })
			if (deleteResponse.status) {
				let configList = getState().governance.configurations
				var newConfigList = _.filter(configList, x =>
					x.configuration_id ? x.configuration_id !== body.configuration_id : null
				)
				dispatch({
					type: ACTION_TYPES.SET_GOVERNANCE_CONFIG_LIST,
					payload: newConfigList,
				})
			}
			if (deleteResponse) {
				callback(true, deleteResponse)
			}
		} catch (error) {
			callback(false)
			myLog('deleteResponse', error)
		}
	}
}
/**
 * Action to add image api in service image
 * @param {Object} body
 * @param {Function} callback
 */
export const addImage = (body, callback) => {
	return async () => {
		try {
			const updateResponse = await API.post(GOVERNANCE, VALIDATE_IMAGE, { body })
			if (updateResponse) {
				callback(true, updateResponse)
			}
		} catch (error) {
			callback(false)
			myLog('VALIDATE_IMAGE', error)
		}
	}
}
/**
 * Action to add image api in container image
 * @param {Object} body
 * @param {Function} callback
 */
export const addContainerImage = (body, callback) => {
	return async () => {
		try {
			const updateResponse = await API.post(GOVERNANCE, VALIDATE_REGISTRY_CONTAINER, { body })
			if (updateResponse) {
				callback(true, updateResponse)
			}
		} catch (error) {
			callback(false)
			myLog('VALIDATE_REGISTRY_CONTAINER', error)
		}
	}
}
/**
 * Action to delete rule
 * @param {Object} body
 * @param {Function} callback
 */
export const deleteRule = body => {
	return async (dispatch, getState) => {
		try {
			const deleteResponse = await API.post(GOVERNANCE, DELETE_CLONED_RULE, { body })
			if (deleteResponse.status) {
				let temp = getState().governance.rules.results
				let deletedList = _.filter(temp, item => item.rule_id !== body.rule_id)
				const clonedRulesList = {
					status: true,
					results: deletedList,
				}
				dispatch({
					type: ACTION_TYPES.SET_RULES_LIST,
					payload: clonedRulesList,
				})
			}
		} catch (error) {
			myLog('deleteRule', error)
		}
	}
}

/**
 * Action to get-assets-by-tag-operations
 * @param {Object} body
 */
export const getAssetsByTagOperations = body => {
	return async dispatch => {
		try {
			const tags = await API.post(TAGGING, GET_ASSETS_BY_TAG_OPERATIONS, { body })
			let temp = []
			temp = _.orderBy(tags, ['created.count', 'modified.count', 'deleted.count'], ['desc', 'desc', 'desc'])
			dispatch({
				type: ACTION_TYPES.SET_ASSETS_BY_TAG_OPERATIONS,
				payload: temp.slice(0, 10),
			})
		} catch (error) {
			myLog('getAssetsByTagOperations', error)
		}
	}
}
/**
 * Action to getNonComplaintAssets
 * @param {Object} body
 */
export const getNonComplaintAssets = body => {
	return async dispatch => {
		try {
			const nonComplaints = await API.post(DETECTORS, GET_NON_COMPLAINT_ASSETS, { body })
			dispatch({
				type: ACTION_TYPES.SET_NON_COMPLAINT_ASSETS,
				payload: nonComplaints,
			})
		} catch (error) {
			myLog('getNonComplaintAssets', error)
		}
	}
}

/**
 * Action to getTagComplianceAggregationCount
 * @param {Object} body
 */
export const getTagComplianceAggregationCount = body => {
	return async dispatch => {
		try {
			const aggregationCount = await API.post(TAGGING, GET_TAG_COMPLIANCE_AGGREGATION_COUNT, { body })
			dispatch({
				type: ACTION_TYPES.SET_TAG_COMPLIANCE_AGGREGATION_COUNT,
				payload: aggregationCount,
			})
		} catch (error) {
			myLog('aggregationCount', error)
		}
	}
}

/**
 * Action to getTagComplianceTotalCount
 * @param {Object} body
 */
export const getTagComplianceTotalCount = body => {
	return async dispatch => {
		try {
			const totalCount = await API.post(TAGGING, GET_TAG_COMLIANCE_TOTAL_COUNT, { body })
			dispatch({
				type: ACTION_TYPES.SET_TAG_COMPLIANCE_TOTAL_COUNT,
				payload: totalCount,
			})
		} catch (error) {
			myLog('getTagComplianceTotalCount', error)
		}
	}
}
/**
 * Action to get-tags-by-operation-count
 * @param {Object} body
 */
export const getTagsByOperationCount = body => {
	return async dispatch => {
		try {
			const totalCount = await API.post(TAGGING, GET_TAGS_BY_OPERATION_COUNT, { body })
			dispatch({
				type: ACTION_TYPES.SET_TAG_OPERATION_COUNT,
				payload: totalCount,
			})
		} catch (error) {
			myLog('get-tags-by-operation-count', error)
		}
	}
}
/**
 * Action to get-applied-tags-day-wise-count
 * @param {Object} body
 */
export const getAppliedTagsDayWiseCount = body => {
	return async dispatch => {
		try {
			const totalCount = await API.post(TAGGING, GET_TAGS_BY_OPERATION_COUNT, { body })
			dispatch({
				type: ACTION_TYPES.SET_TAG_OPERATION_COUNT,
				payload: totalCount,
			})
		} catch (error) {
			myLog('get-applied-tags-day-wise-count', error)
		}
	}
}




export const listGovernanceCategories = (body, callback) => {
	return async dispatch => {
		try {
			// const categoryList = await API.get(GOVERNANCE, GOVERNANCE_LIST_CATEGORIES)
			const categoryList = await API.post(GOVERNANCE, LIST_GOVERNANCE_CATEGORIES, { body })
			dispatch({
				type: ACTION_TYPES.SET_GOVERNANCE_CATEGORY_LIST_NEW,
				payload: categoryList.results,
			})
			callback(true, categoryList.results)
		} catch (error) {
			callback(false, [])
			myLog('categoryList', error)
		}
	}
}

export const getGernanceMasterRule = (body, callback) => {
	return async dispatch => {
		try {
			const rule = await API.post(GOVERNANCE, GET_GOVERNANCE_MASTER_RULE, { body })
			callback(true, rule)
		} catch (error) {
			callback(false, [])
			myLog('categoryList', error)
		}
	}
}

export const saveUserRule = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(GOVERNANCE, CREATE_USER_RULE, { body })
			
			if (response.status) {
				dispatch({
					type: ACTION_TYPES.SET_EDIT_RULE_DETAILS,
					payload: {},
				})
				callback(true, response)
			} else {
				callback(false, response)
			}
		} catch (error) {
			callback(false, [])
			myLog('CREATE_USER_RULE', error)
		}
	}
}

export const updateUserRule = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(GOVERNANCE, UPDATE_USER_RULE, { body })
			
			if (response.status) {
				dispatch({
					type: ACTION_TYPES.SET_EDIT_RULE_DETAILS,
					payload: {},
				})
				callback(true, response)
			} else {
				callback(false, response)
			}
		} catch (error) {
			callback(false, [])
			myLog('UPDATE_USER_RULE', error)
		}
	}
}

export const listUserGovernanceCateories = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(GOVERNANCE, LIST_USER_GOVERNANCE_CATEGORIES, { body })
			
			dispatch({
				type: ACTION_TYPES.SET_GOVERNANCE_CATEGORY_LIST,
				payload: response.results ? response.results : []
			})
			callback(true, response.results ? response.results : [])
		} catch (error) {
			callback(false, [])
			myLog('listUserGovernanceCateories', error)
		}
	}
}

export const listUserRules = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(GOVERNANCE, LIST_USER_RULES, { body })
			dispatch({
				type: ACTION_TYPES.SET_RULES_LIST,
				payload: response,
			})
			
			callback(true)
		} catch (error) {
			callback(false)
			
			myLog('LIST_USER_RULE_ERROR', error)
		}
	}
}

export const getGovernanceUserRules = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(GOVERNANCE, GET_GOVERNANCE_USER_RULES, { body })
			
			dispatch({
				type: ACTION_TYPES.SET_EDIT_RULE_DETAILS,
				payload: response.results[0],
			})
			callback(true)
		} catch (error) {
			callback(false)
			dispatch({
				type: ACTION_TYPES.SET_EDIT_RULE_DETAILS,
				payload: [],
			})
		}
	}
}

export const updateStatusRule = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(GOVERNANCE, UPDATE_STATUS_RULE, { body })
			
			callback(true, response)
		} catch (error) {
			callback(false, error)
		}
	}
}

export const deleteUserRule = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(GOVERNANCE, DELETE_USER_RULE, { body })
			
			callback(true, response)
		} catch (error) {
			
			callback(false, error)
		}
	}
}

export const listLogType = (body, callback) => {
	return async dispatch => {
		try {
			const logType = await API.post(COMMON_SERVICES, LIST_LOG_TYPE, { body })
			
			let obj = {}
			obj.body = body
			obj.result = logType
			dispatch({
				type: ACTION_TYPES.SET_GOVERNANCE_LOGTYPE,
				payload: obj
			})
			
			callback(true, logType)
		} catch (error) {
			dispatch({
				type: ACTION_TYPES.SET_GOVERNANCE_LOGTYPE,
				payload: {}
			})

			callback(false, [])
			myLog('listLogType', error)
		}
	}
}

export const listServiceEngines = (body, callback) => {
	return async dispatch => {
		try {
			const engineRds = await API.post(COMMON_SERVICES, LIST_SERVICE_ENGINES, { body })
			let obj = {}
			obj.body = body
			obj.result = engineRds
			dispatch({
				type: ACTION_TYPES.SET_GOVERNANCE_ENGINE_RDS,
				payload: obj
			})
			callback(true, engineRds)
		} catch (error) {
			dispatch({
				type: ACTION_TYPES.SET_GOVERNANCE_ENGINE_RDS,
				payload: {}
			})
			callback(false, [])
			myLog('listServiceEngines', error)
		}
	}
}

export const listStorageTypes = (body, callback) => {
	return async dispatch => {
		try {
			const storageTypes = await API.post(COMMON_SERVICES, LIST_STORAGE_TYPES, { body })

			let obj = {}
			obj.result = storageTypes
			obj.body = body
			dispatch({
				type: ACTION_TYPES.SET_GOVERNANCE_BLOCKSTORE,
				payload: obj
			})
			callback(true, storageTypes)
		} catch (error) {
			dispatch({
				type: ACTION_TYPES.SET_GOVERNANCE_BLOCKSTORE,
				payload: {}
			})
			myLog('storageTypes', error)
			callback(false)
		}
	}
}

export const listOperatingSystem = (body, callback) => {
	return async dispatch => {
		try {
			const operatingSystem = await API.post(COMMON_SERVICES, LIST_OPERATING_SYSTEM, { body })
			dispatch({
				type: ACTION_TYPES.SET_LIST_OPERATING_SYSTEM,
				payload: operatingSystem
			})
			callback(true, operatingSystem)
		} catch (error) {
			myLog('operatingSyste', error)
			callback(false)
		}
	}
}

export const listSizingEc2 = (body, callback) => {
	return async dispatch => {
		try {
			const url = PRICING_END_POINT + LISTVMS;
			const listSizingEc2 = await axios.post(url, body)
			dispatch({
				type: ACTION_TYPES.SET_GOVERNANCE_SIZING_EC2,
				payload: listSizingEc2
			})
			callback(true, listSizingEc2)
		} catch (error) {
			myLog('exception', error)
			dispatch({
				type: ACTION_TYPES.SET_GOVERNANCE_SIZING_EC2,
				payload: {}
			})
			callback(false, '')
		}
	}
}


export const listAllAssets = (body, callback) => {
	return async dispatch => {
		try {
			const assetsList = await API.post(SYNCHRONIZER, LIST_ASSETS, { body })
			
			callback(true, assetsList)
		} catch (error) {
			myLog('assetsList', error)
			callback(false, {})
		}
	}
}