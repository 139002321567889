/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
	listAllProviders,
	listAllServices,
	listAllRegions,
	listAllAccounts,
	showNotification,
} from '../../../actions/commonAction'
import { Row, Col, Label, FormGroup, Input } from 'reactstrap'
import ModalWrapper from '../../common/ModalWrapper'
import _ from 'lodash'
import { getAllTags } from '../../../actions/governance/governanceAction'

import {
	insertAssetTagGroupCondition,
	setAssetTagGroupConditionsInStore,
	updateAssetTagGroupCondition,
	deleteAssetTagGroupCondition,
} from '../../../actions/governance/taggingAction'
import { listAllAssets } from '../../../actions/assets/assetsAction'
import { ERROR, SUCCESS } from '../../../utils/constants'
import Select from 'react-select'

class ManageCondition extends Component {
	constructor(props) {
		super(props)
		this.state = {
			providers: [],
			services: [],
			regions: [],
			accounts: [],
			conditionProvider: '',
			conditionName: '',
			conditionAccount: '',
			conditionAsset: '',
			conditionAssetName: '',
			conditionOrganization: '',
			conditionRegion: '',
			conditionService: '',
			showModal: false,
			tagsButtonClicked: false,
			newRuleTags: [],
			isEditModal: false,
			creatingCondition: false,
			hasErrorInRuleForm: false,
			editIndex: 0,
			showMessage: false,
			responseMessage: '',
			conditionId: '',
		}
	}

	componentDidMount = () => {
		this.props.listAllProviders((promise, providers) => {
			if (promise) {
				if (providers.length === 1) {
					this.setState({ conditionProvider: providers[0].provider_name })
				}
				this.setState({ providers }, () => this.onSelectProvider('provider'))
			}
		})
	}
	onSelectProvider = from => {
		let params = {}
		if (this.state.conditionProvider !== '' && this.state.conditionProvider !== 'all') {
			params.provider = this.state.conditionProvider.toLowerCase()
		}
		if (this.state.conditionService !== '' && this.state.conditionService !== 'all') {
			params.service_name = this.state.conditionService
		}
		if (this.state.conditionRegion !== '' && this.state.conditionRegion !== 'all') {
			params.region = this.state.conditionRegion
		}
		if (this.state.conditionAccount !== '' && this.state.conditionAccount !== 'all') {
			params.account_id = this.state.conditionAccount
		}
		if (from === 'provider') {
			this.props.listAllServices(params, (promise, services) => {
				if (promise) {
					this.setState({ services })
				}
			})
			this.props.listAllRegions(params, (promise, regions) => {
				if (promise) {
					this.setState({ regions: regions[0].provider_regions })
				}
			})
			this.props.listAllAccounts(params, (promise, accounts) => {
				if (promise) {
					this.setState({ accounts })
				}
			})
		}
		if (from === 'service' || from === 'region') {
			this.props.listAllAssets(params, () => {
				this.setState({ showAssetsLoading: false })
			})
			this.props.getAllTags(params)
		}
	}
	/**
	 * Add new rule form
	 */
	addNewRuleTags = () => {
		this.setState({ newRuleTags: [] })
	}
	/**
	 * Delete new rule form
	 */
	deleteNewRuleTags = index => {
		if (this.state.newRuleTags.length === 1) {
			this.setState({ tagsButtonClicked: false })
			const temp = this.state.newRuleTags
			temp.splice(index, 1)
			this.setState({ newRuleTags: temp })
		} else {
			const temp = this.state.newRuleTags
			temp.splice(index, 1)
			this.setState({ newRuleTags: temp })
		}
	}
	onSubmitConditionForm = () => {
		if (
			this.state.conditionName === '' ||
			this.state.conditionProvider === '' ||
			this.state.conditionAccount === '' ||
			this.state.conditionOrganization === ''
		) {
			this.setState({ hasErrorInRuleForm: true })
		} else {
			this.setState({ creatingCondition: true })
			let params = {
				provider: this.state.conditionProvider.toLowerCase(),
				service_name: this.state.conditionService,
				account_id: this.state.conditionAccount,
				organization: this.state.conditionOrganization,
				condition_name: this.state.conditionName,
			}
			if (this.state.conditionAsset !== '') params.asset_id = this.state.conditionAsset
			if (this.state.conditionAssetName !== '') params.asset_name = this.state.conditionAssetName
			if (this.state.conditionRegion !== '') params.region = this.state.conditionRegion
			// if (this.state.newRuleTags[0].key !== '' && this.state.newRuleTags[0].value !== '') {
			params.tags = this.state.newRuleTags
			// }
			if (this.props.tagGroupId !== '') params.tag_group_id = this.props.tagGroupId
			Object.entries(params).forEach(([key, value]) => {
				if (value && value.length === 0) {
					delete params[key]
				}
			})
			if (params.tags) {
				params.tags.forEach(tag => {
					delete tag.id
				})
			}
			if (this.state.isEditModal) {
				params.condition_id = this.state.conditionId
				delete params.condition_name
				delete params.tags_length
				delete params.created_at
				delete params.created_by
				if (params.modified_at) {
					delete params.modified_at
				}
				if (params.modified_by) {
					delete params.modified_by
				}
				this.props.updateAssetTagGroupCondition(params, (success, res) => {
					this.setState({ creatingCondition: false })
					if (success) {
						let data = this.props.tagGroupConditions
						data[this.state.editIndex].provider = this.state.conditionProvider.toLowerCase()
						data[this.state.editIndex].service_name = this.state.conditionService
						data[this.state.editIndex].region = this.state.conditionRegion
						data[this.state.editIndex].account_id = this.state.conditionAccount
						data[this.state.editIndex].asset_id = this.state.conditionAsset
						data[this.state.editIndex].asset_name = this.state.conditionAssetName
						data[this.state.editIndex].organization = this.state.conditionOrganization
						data[this.state.editIndex].tags = this.state.newRuleTags
						data[this.state.editIndex].condition_name = this.state.conditionName
						this.props.setAssetTagGroupConditionsInStore(data)
						this.setState({
							showModal: false,
							conditionProvider: '',
							conditionName: '',
							conditionAccount: '',
							conditionAsset: '',
							conditionAssetName: '',
							conditionOrganization: '',
							conditionRegion: '',
							conditionService: '',
							newRuleTags: [],
							tagsButtonClicked: false,
							responseMessage: '',
							hasErrorInRuleForm: false,
						})
						this.props.showNotification(SUCCESS, (res && res.message) || 'Condition updated.')
					} else {
						this.setState({ showMessage: true, responseMessage: 'Please try again later.' })
						this.props.showNotification(ERROR, (res && res.message) || 'Condition not updated.')
					}
				})
			} else {
				params.created_by = 'tagging-api'
				this.props.insertAssetTagGroupCondition(params, (success, res, id) => {
					this.setState({ creatingCondition: false })
					if (success) {
						let data = this.props.tagGroupConditions
						let temp = {
							provider: this.state.conditionProvider.toLowerCase(),
							service_name: this.state.conditionService,
							region: this.state.conditionRegion,
							account_id: this.state.conditionAccount,
							asset_id: this.state.conditionAsset,
							organization: this.state.conditionOrganization,
							tags: this.state.newRuleTags,
							condition_name: this.state.conditionName,
							condition_id: id,
						}
						data.push(temp)
						this.props.setAssetTagGroupConditionsInStore(data)
						this.setState({
							showModal: false,
							conditionProvider: '',
							conditionName: '',
							conditionAccount: '',
							conditionAsset: '',
							conditionOrganization: '',
							conditionRegion: '',
							conditionService: '',
							newRuleTags: [],
							tagsButtonClicked: false,
							responseMessage: '',
							hasErrorInRuleForm: false,
						})
						this.props.showNotification(SUCCESS, res.message)
					} else {
						this.setState({ showMessage: true, responseMessage: 'Something went wrong! Pleasae try again later.' })
						this.props.showNotification(ERROR, (res && res.message) || 'Condition not saved.')
					}
				})
			}
		}
	}

	deleteCondition = id => {
		this.props.deleteAssetTagGroupCondition({ condition_id: id }, () => {})
	}

	render() {
		const { regions, showModal } = this.state
		return (
			<>
				<h4>Manage Conditions</h4>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
					magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
					consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
					pariatur.
				</p>
				<div className='row'>
					<div className='col-sm-12'>
						<div className='row'>
							<div className='col-6'>
								<div className='form-group'>
									<button
										onClick={() => {
											this.setState({
												showModal: true,
												isEditModal: false,
												hasErrorInRuleForm: false,
												conditionName: '',
												conditionAccount: '',
												conditionAsset: '',
												conditionOrganization: '',
												conditionRegion: '',
												conditionService: '',
												newRuleTags: [],
												tagsButtonClicked: false,
												responseMessage: '',
											})
										}}
										className='btn btn-outline-primary'
									>
										Add Condition
									</button>
								</div>
							</div>
						</div>
						{this.props.tagGroupConditions.length ? (
							<div className='row'>
								<div className='col-12'>
									<div className='box table-responsive rounded overflow-hidden'>
										<table className='table table-sm table-hover'>
											<thead>
												<tr className='bg-light'>
													<th>Condition ID</th>
													<th>Condition Name</th>
													<th>Provider</th>
													<th>Account</th>
													<th>Service</th>
													<th>Tags</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												{this.props.tagGroupConditions &&
													this.props.tagGroupConditions.map((item, i) => {
														return (
															<tr key={i}>
																<td>{item.condition_id}</td>
																<td>{item.condition_name}</td>
																<td>{item.provider}</td>
																<td>{item.account_id}</td>
																<td>{item.service_name}</td>
																<td>{item.tags && item.tags.length}</td>
																<td>
																	<span
																		onClick={() =>
																			this.setState(
																				{
																					showModal: true,
																					conditionAccount: item.account_id,
																					conditionProvider: item.provider && item.provider.toLowerCase(),
																					conditionRegion: item.region,
																					conditionService: item.service_name,
																					conditionOrganization: item.organization,
																					conditionAsset: item.asset_id,
																					conditionAssetName: item.asset_name,
																					conditionName: item.condition_name,
																					conditionId: item.condition_id,
																					newRuleTags: item.tags ? item.tags : [],
																					isEditModal: true,
																					editIndex: i,
																				},
																				() => this.onSelectProvider('service')
																			)
																		}
																	>
																		<i className='fal fa-pen text-primary mr-2 cursor-pointer' />
																	</span>
																	<span onClick={() => this.deleteCondition(item.condition_id, i)}>
																		<i className='fal fa-trash text-primary cursor-pointer' />
																	</span>
																</td>
															</tr>
														)
													})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						) : null}
						<div className='row'>
							<div className='col-12'>
								<hr />
								<button
									className='btn btn-link mr-3'
									onClick={() => {
										this.props.onClickBack()
									}}
								>
									Back
								</button>
								<button
									className='btn btn-primary'
									onClick={() => {
										this.props.onClickNext()
									}}
								>
									Next
								</button>
							</div>
						</div>
					</div>
				</div>
				<ModalWrapper
					showModal={showModal}
					modalTitle={this.state.isEditModal ? 'Edit Condition' : 'New Condition'}
					buttonPrimaryLabel='Save'
					buttonSecondaryLabel='Cancel'
					onClickPrimaryButton={() => this.onSubmitConditionForm()}
					toggle={boolean => this.setState({ showModal: boolean })}
					showLoading={this.state.creatingCondition}
					responseMessage={this.state.responseMessage}
					showMessage={this.state.showMessage}
					showError
				>
					<Row>
						<Col>
							<FormGroup>
								<Label>Name</Label>
								<Input
									type='text'
									placeholder='Name'
									value={this.state.conditionName}
									onChange={event => this.setState({ conditionName: event.target.value })}
								/>
								{this.state.hasErrorInRuleForm && this.state.conditionName === '' ? (
									<p className='text-danger'>Name is required.</p>
								) : null}
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col md={6}>
							<FormGroup>
								<Label>Provider</Label>
								<Input
									disabled={!this.state.providers.length}
									type='select'
									value={this.state.conditionProvider}
									onChange={event => {
										this.setState(
											{
												conditionProvider: event.target.value,
											},
											() => this.onSelectProvider('provider')
										)
									}}
								>
									<option value=''>Select</option>
									{this.state.providers.map((provider, index) => {
										return (
											<option key={index} value={provider.provider_name}>
												{provider.provider_name}
											</option>
										)
									})}
								</Input>
								{this.state.hasErrorInRuleForm && this.state.conditionProvider === '' ? (
									<p className='text-danger'>Provider is required.</p>
								) : null}
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup>
								<Label>Organization</Label>
								<Input
									type='select'
									value={this.state.conditionOrganization}
									onChange={event => {
										this.setState(
											{
												conditionOrganization: event.target.value,
											},
											() => this.onSelectProvider('org')
										)
									}}
								>
									<option value=''>Select</option>
									<option>8kMiles</option>
								</Input>
								{this.state.hasErrorInRuleForm && this.state.conditionOrganization === '' ? (
									<p className='text-danger'>Organization is required.</p>
								) : null}
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col md={6}>
							<FormGroup>
								<Label>Account</Label>
								<Input
									disabled={!this.state.accounts.length}
									type='select'
									value={this.state.conditionAccount}
									onChange={event => {
										this.setState(
											{
												conditionAccount: event.target.value,
											},
											() => this.onSelectProvider('account')
										)
									}}
								>
									<option value=''>Select</option>
									{this.state.accounts.map((account, index) => {
										return (
											<option key={index} value={account.account_id}>
												{account.account_name}
											</option>
										)
									})}
								</Input>
								{this.state.hasErrorInRuleForm && this.state.conditionAccount === '' ? (
									<p className='text-danger'>Account is required.</p>
								) : null}
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup>
								<Label>Region</Label>
								<Input
									disabled={!this.state.regions.length}
									type='select'
									value={this.state.conditionRegion}
									onChange={event => {
										this.setState(
											{
												conditionRegion: event.target.value,
											},
											() => this.onSelectProvider('region')
										)
									}}
								>
									<option value=''>Select</option>
									{regions.length &&
										regions.map((region, index) => {
											return (
												<option key={index} value={region.region}>
													{region.name}
												</option>
											)
										})}
								</Input>
								{/* {this.state.hasErrorInRuleForm && this.state.conditionRegion === '' ? (
									<p className='text-danger'>Region is required.</p>
								) : null} */}
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col md={6}>
							<FormGroup>
								<Label>Service</Label>
								<Input
									disabled={!this.state.services.length}
									type='select'
									value={this.state.conditionService}
									onChange={event => {
										this.setState(
											{
												conditionService: event.target.value,
											},
											() => this.onSelectProvider('service')
										)
									}}
								>
									<option value=''>Select</option>
									{this.state.services &&
										this.state.services.length &&
										_.chain(this.state.services)
											.groupBy('resource_type')
											.map((value, key) => ({ label: key, sub: value }))
											.value()
											.map((service, i) => {
												return (
													<optgroup key={i} label={service.label}>
														{service.sub.map((item, i) => {
															return (
																<option key={i} value={service.service_name}>
																	{item.service_name}
																</option>
															)
														})}
													</optgroup>
												)
											})}
								</Input>
								{/* {this.state.hasErrorInRuleForm && this.state.conditionService === '' ? (
									<p className='text-danger'>Service is required.</p>
								) : null} */}
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup>
								<Label>Asset</Label>
								<select
									className='form-control'
									value={this.state.conditionAsset}
									id='select'
									onChange={event => {
										const { options, selectedIndex } = event.target
										this.setState(
											{
												conditionAssetName: event.target.value,
												conditionAsset: options[selectedIndex].id,
											},
											() => this.onSelectProvider('asset_id')
										)
									}}
								>
									{/* <option value=''>Select</option> */}
									<option value={this.state.conditionAssetName}>{this.state.conditionAssetName || 'Select'}</option>
									{this.props.assets &&
										this.props.assets.map((item, i) => {
											return (
												<option key={i} value={item.asset_name} id={item.asset_id}>
													{item.asset_name}
												</option>
											)
										})}
								</select>
								{/* {this.state.hasErrorInRuleForm && this.state.conditionAsset === '' ? (
									<p className='text-danger'>Asset is required.</p>
								) : null} */}
							</FormGroup>
						</Col>
					</Row>
					{!this.state.tagsButtonClicked && !this.state.newRuleTags.length ? (
						<button
							onClick={() => {
								this.addNewRuleTags()
								this.setState({ tagsButtonClicked: true })
							}}
							className='btn btn-sm btn-primary'
						>
							TAGS
						</button>
					) : (
						<>
							{' '}
							<label>Tags</label>
							<Select
								filterOption={null}
								onChange={arr => {
									arr.map(item => delete item['label'])
									this.setState({
										newRuleTags: arr,
									})
								}}
								options={this.props.tags.map(({ tags }) => ({
									key: tags.key,
									label: tags.key + ':' + tags.value,
									value: tags.value,
								}))}
								value={
									this.state.newRuleTags
										? this.state.newRuleTags.map(item => ({
												key: item.key,
												value: item.value,
												label: item.key + ' : ' + item.value,
										  }))
										: []
								}
								isMulti
								classNamePrefix='Select Tags'
								className='basic-multi-select'
							/>
							{/* {this.state.newRuleTags.map((rule, index) => {
								return (
									<>
										<div className='d-flex justify-content-start mt-3' key={index}>
											<div className='row'>
												<div className='col'>
													<input
														value={rule.key}
														onChange={event => {
															let tags = this.state.newRuleTags
															tags[index].key = event.target.value
															this.setState({ newRuleTags: tags })
														}}
														type='text'
														className='form-control form-control-sm'
														placeholder='Key'
													/>
												</div>
												:
												<div className='col'>
													<input
														value={rule.value}
														onChange={event => {
															let tags = this.state.newRuleTags
															tags[index].value = event.target.value
															this.setState({ newRuleTags: tags })
														}}
														type='text'
														className='form-control form-control-sm'
														placeholder='Value'
													/>
												</div>
											</div>
											<div>
												<i
													title='Add'
													onClick={() => this.addNewRuleTags()}
													className='fal fa-plus-circle p-1 fs-20 ml-3 text-success cursor-pointer'
												/>
												<i
													title='Delete'
													onClick={() => this.deleteNewRuleTags(index)}
													className='fal fa-trash-alt text-danger p-1 fs-20 cursor-pointer'
												/>
											</div>
										</div>
									</>
								)
							})} */}
						</>
					)}
					{/* {this.state.hasErrorInRuleForm &&
					(this.state.newRuleTags[0].key === '' || this.state.newRuleTags[0].value === '') ? (
						<p className='text-danger'>Tags is required.</p>
					) : null} */}
				</ModalWrapper>
			</>
		)
	}
}
/**
 * Type of the props used in the component
 */
ManageCondition.propTypes = {
	onClickNext: PropTypes.func,
	onClickBack: PropTypes.func,
	listAllProviders: PropTypes.func,
	listAllServices: PropTypes.func,
	listAllRegions: PropTypes.func,
	listAllAccounts: PropTypes.func,
	tagGroupId: PropTypes.string,
	tagConditionId: PropTypes.string,
	insertAssetTagGroupCondition: PropTypes.func,
	deleteAssetTagGroupCondition: PropTypes.func,
	tagGroupConditions: PropTypes.array,
	setAssetTagGroupConditionsInStore: PropTypes.func,
	updateAssetTagGroupCondition: PropTypes.func,
	listAllAssets: PropTypes.func,
	showNotification: PropTypes.func,
	getAllTags: PropTypes.func,
	assets: PropTypes.array,
	tags: PropTypes.array,
}
const mapStateToProps = state => {
	return {
		editRuleDetails: state.governance.editRuleDetails,
		tagGroupId: state.governance.tagGroupId,
		tagConditionId: state.governance.tagConditionId,
		tagGroupConditions: state.governance.tagGroupConditions,
		assets: state.assets.assets.results,
		tags: state.governance.tags,
	}
}
export default connect(mapStateToProps, {
	listAllProviders,
	listAllServices,
	listAllRegions,
	showNotification,
	listAllAccounts,
	insertAssetTagGroupCondition,
	setAssetTagGroupConditionsInStore,
	deleteAssetTagGroupCondition,
	updateAssetTagGroupCondition,
	listAllAssets,
	getAllTags,
})(ManageCondition)
