/*************************************************
 * Tvastar
 * @exports
 * @file AccountRegister.js
 * @author Rajasekar // on 19/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import YouTube from 'react-youtube'
import { Link, Redirect } from 'react-router-dom'
// import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { AppWrapper } from '../common/AppWrapper'

// import logo from '../../assets/images/logo.svg'
import gcp from '../../assets/images/gcp.png'
import azure from '../../assets/images/azure.png'
import aws from '../../assets/images/aws.png'
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap'
// import { validateEmail, validatePassword, validatePhone } from '../../utils/utility'
import { URL_PATH } from '../../config/urlPath'
import { addAccount } from '../../actions/accounts/AccountRegisterAction'
import { getUserOrganization } from '../../actions/commonAction'
//const videoPopup = `https://www.youtube.com/embed/${video.id.videoId}`

class AccountRegister extends Component {
	constructor(props) {
		super(props)
		this.state = {
			userDetails: [],
			redirect: false,
			navigatePage: 1,
			modalHeader: '',
			videoPopup: false,
			infoPopup: false,
			selectedAccount: '',
			stepProcessed: 0,

			accountName: '',
			isManualChecked: false,
			isAutomatedChecked: false,
			accountId: '',
			arn: '',
			externalId: '112367390792',
			log_bucket: '',
			log_path: '',
			billing_bucket: '',
			billing_path: '',

			hasError: false,
			onLoading: false,
			showAccountSetupErrorMessage: '',
		}

		this.toggleCloseModal = this.toggleCloseModal.bind(this)
		this.toggleInfoModal = this.toggleInfoModal.bind(this)
	}

	componentDidMount = () => {
		let params = {}
		this.props.getUserOrganization(params, (promise, userDetails) => {
			console.log(userDetails)
			if (promise) {
				this.setState({ userDetails })
			} else {
				this.setState({ userDetails: [] })
			}
		})
	}

	navigatePage = (page) => {
		this.setState({ navigatePage: page })
	}

	toggleCloseModal = (event) => {
        if(event.keyCode !== 27)  {
            this.setState({
                videoPopup: !this.state.videoPopup
            })
        }
	}

	toggleInfoModal = (event) => {
        if(event.keyCode !== 27)  {
            this.setState({
                infoPopup: !this.state.infoPopup
            })
        }
	}
	
	setupAccount = (step) => {
		this.setState({ hasError: false })
		if(step === 2) {
			if(this.state.accountName === '' ||
				(!this.state.isManualChecked && !this.state.isAutomatedChecked)) {
				this.setState({ hasError: true })
			} else {
				this.setState({ stepProcessed: 2, navigatePage: 3 })
			}
		} else if(step === 3) {
			if(this.state.accountId === '' ||
				this.state.arn === '') {
				this.setState({ hasError: true })
			} else {
				this.setState({ onLoading: true })

				let params = {}
				params.provider = this.state.selectedAccount
				params.account_name = this.state.accountName
				params.account_id = this.state.accountId

				// let authenticationType = 'Automated'
				// if(this.state.isManualChecked) {
				// 	authenticationType = 'Manual'
				// }
				//params.authenticationType = authenticationType

				let dataRow = {}
				dataRow.arn_value = this.state.arn
				dataRow.external_id = 'Test-External-ID'
				params.key_metadata = dataRow

				//if(this.state.log_bucket !== '') {
					params.log_bucket = this.state.log_bucket
				//}
				//if(this.state.log_path !== '') {
					params.log_path = this.state.log_path
				//}
				//if(this.state.billing_bucket !== '') {
					params.billing_bucket = this.state.billing_bucket
				//}
				//if(this.state.billing_path !== '') {
					params.billing_path = this.state.billing_path
				//}

				this.props.addAccount(params, (promise, result) => {
					console.log(result)
					if(promise) {
						this.setState({ stepProcessed: '', navigatePage: 4 },
							() => this.redirectToSettings()
						)
					} else {
						this.setState({ showAccountSetupErrorMessage: result.error, onLoading: false })
					}
				})
			}
		}
	}

	redirectToSettings = () => {
		setTimeout(() => {
			this.setState({ redirect: true }) 
		}, 5000)
	}

	/**
	 * Renders register screen design
	 */
	render() {		
		const opts = {
			height: '390',
			width: '640',
			playerVars: {
			  // https://developers.google.com/youtube/player_parameters
			  autoplay: 1,
			},
		}

		const {
			onLoading,
			hasError,
			accountName,
			isManualChecked, 
			isAutomatedChecked,
			accountId,
			arn,
			// log_bucket,
			// log_path,
			// billing_bucket,
			// billing_path,
			userDetails
		} = this.state
		return (
		this.state.redirect ? 
			<Redirect to={URL_PATH.SETTINGS} /> 
		: 
			<div className="container-fluid overflow-auto flex-grow-1">
				<div className='row h-100'>
					<div className='col-sm-12 p-0'>
						<div className="title-section p-3">
							<h6 className="text-white">Register Account</h6>
							<p className="text-white">Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet
								enim.</p>
						</div>
						<div className="box m-3 p-3">
							<div className="row">
								<div className="col-sm-6">
									<h6>Add new account</h6>
									<p className="text-muted">Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim.</p>
								</div>
								<div className="col-sm-6 text-right">
									<span className="text-decoration-none cursorPointer" onClick={() => this.setState({ videoPopup: !this.state.videoPopup }) }><i className="fa fa-info-circle"></i> How to add AWS account help video</span>
								</div>
							</div>
							<div className="row">
								<div className="col-3">
									<div className="nav flex-column nav-pills">
										<span onClick={() => this.navigatePage(1)} className={`nav-link cursorPointer ${this.state.stepProcessed !== '' && this.state.stepProcessed >= 0 ? this.state.stepProcessed : 'disabled colorBlack'} ${this.state.navigatePage === 1 ? 'active': ''}`}>Select Subscription</span>
										<span onClick={() => this.navigatePage(2)} className={`nav-link cursorPointer ${this.state.stepProcessed >= 1 ? '' : 'disabled colorBlack'} ${this.state.navigatePage === 2 ? 'active': ''}`}>Account Details</span>
										<span onClick={() => this.navigatePage(3)} className={`nav-link cursorPointer ${this.state.stepProcessed >= 2 ? '' : 'disabled colorBlack'} ${this.state.navigatePage === 3 ? 'active': ''}`}>Configuration</span>
										<span onClick={() => this.navigatePage(4)} className={`nav-link cursorPointer ${this.state.stepProcessed === 3 ? '' : 'disabled colorBlack'} ${this.state.navigatePage === 4 ? 'active': ''}`}>Complete Setup</span>
									</div>
								</div>
								<div className="col-9 border-left">
									<div className="tab-content">
										<div className={`tab-pane fade ${this.state.navigatePage === 1 ? 'show active': ''}`}>
											<h6 className="font-weight-normal">Select your subscription</h6>
											<p className="text-muted">Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet
												enim.</p>
											<div className={`d-flex mb-3 border rounded p-3 cursorPointer ${this.state.selectedAccount === 'aws' ? 'border-success' : '' }`} onClick={() => this.setState({ selectedAccount: 'aws', navigatePage: 2, stepProcessed: 1 }) }>
												<div>
													<img src={aws} className="img-fluid" alt="AWS" />
												</div>
												<div className="mx-3 flex-grow-1">
													<h6>Amazon Web Services</h6>
													<p className="text-muted">Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi
														amet enim.</p>
													<span className="text-info">Learn More</span>
												</div>
												<div className={`align-self-center ${this.state.selectedAccount === 'aws' ? '': 'displayNone'} `}>
													<i className="fa fa-check-circle text-success"></i>
												</div>
											</div>
											<div className={`d-flex mb-3 border rounded p-3 cursorPointer ${this.state.selectedAccount === 'azure' ? 'border-success' : '' }`} onClick={() => this.setState({ selectedAccount: 'azure', navigatePage: 2, stepProcessed: 1 }) }>
												<div>
													<img src={azure} className="img-fluid" alt="AWS" />
												</div>
												<div className="mx-3 flex-grow-1">
													<h6>Azure Cloud Services</h6>
													<p className="text-muted">Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi
														amet enim.</p>
													<span className="text-info">Learn More</span>
												</div>
												<div className={`align-self-center ${this.state.selectedAccount === 'azure' ? '': 'displayNone'} `}>
													<i className="fa fa-check-circle text-success"></i>
												</div>
											</div>
											<div className={`d-flex mb-3 border rounded p-3 cursorPointer ${this.state.selectedAccount === 'gcp' ? 'border-success' : '' }`} onClick={() => this.setState({ selectedAccount: 'gcp', navigatePage: 2, stepProcessed: 1 }) }>
												<div>
													<img src={gcp} className="img-fluid" alt="AWS" />
												</div>
												<div className="mx-3 flex-grow-1">
													<h6>Google Cloud Platform</h6>
													<p className="text-muted">Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi
														amet enim.</p>
													<span className="text-info">Learn More</span>
												</div>
												<div className={`align-self-center ${this.state.selectedAccount === 'gcp' ? '': 'displayNone'} `}>
													<i className="fa fa-check-circle text-success"></i>
												</div>
											</div>
										</div>
										
										<Modal className="videoUrl" backdrop="static" isOpen={this.state.videoPopup} toggle={this.toggleCloseModal}>
											<ModalHeader toggle={this.toggleCloseModal}> How to add AWS account help video 
											</ModalHeader>
											<ModalBody>
												<YouTube videoId="5Peo-ivmupE" opts={opts} onReady={this._onReady} />
											</ModalBody>
											<ModalFooter>
												<button className='btn btn-sm btn-light mr-3' onClick={this.toggleCloseModal}> Close</button>
											</ModalFooter>
										</Modal>
										<div className={`tab-pane fade ${this.state.navigatePage === 2 ? 'show active': ''}`}>
											<h6 className="font-weight-normal">Enter your account details</h6>
											<p className="text-muted">Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet
												enim.</p>
											<div className='form-group w-50'>
												<label>Account name</label>
												<input
													type='text'
													className={`form-control ${(hasError && accountName === '') ? 'is-invalid' : accountName === '' ? '' : 'is-valid'}`}
													value={this.state.accountName}
													onChange={event => this.setState({ accountName: event.target.value })}
													placeholder='Account Name'
												/>
												<div className='invalid-feedback'>Please enter a valid account name.</div>
											</div>
											<h6 className="font-weight-normal mt-4">Choose the authentication type</h6>
											<p className="text-muted">Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet
												enim.</p>
											<div className="custom-control custom-checkbox mr-sm-2">
												<input type="checkbox"
													className={`custom-control-input ${
													(hasError && !isAutomatedChecked && !isManualChecked) ? 'is-invalid' : ''}`}
													id="automatedSetup" 
													onChange={event => { this.setState({ isAutomatedChecked: event.target.checked, isManualChecked: false }, () => this.setupAccount(2)) }}
													checked={isAutomatedChecked} />
												<label className="accountTypepadding custom-control-label"
													for="automatedSetup">Automated Setup</label>
											</div>
											<p  className="text-muted small ml-4">Officia amet eiusmod eu sunt tempor voluptate laboris
												velit nisi amet
												enim.</p>

											<div className="custom-control custom-checkbox mr-sm-2">
												<input type="checkbox"
													className={`custom-control-input ${
													(hasError && !isAutomatedChecked && !isManualChecked) ? 'is-invalid' : ''}`}
													id="manualSetup" 
													onChange={event => { this.setState({ isManualChecked: event.target.checked, isAutomatedChecked: false }, () => this.setupAccount(2) ) }}
													checked={isManualChecked}/>
												<label className="accountTypepadding custom-control-label" for="manualSetup">Manual
													Setup</label>
											</div>
											<p  className="text-muted small ml-4">Officia amet eiusmod eu sunt tempor voluptate laboris
												velit nisi amet
												enim.</p>
											<div className={`${(hasError && !isAutomatedChecked && !isManualChecked) ? 'is-invalid' : ''}`}></div>
											<div className={`invalid-feedback mb-3`}>Please choose the authentication type.</div>
											<div className="d-flex">
												<button className="btn btn-primary" onClick={() => this.setupAccount(2) }>
													Next
												</button>
											</div>
										</div>
										<div className={`tab-pane fade ${this.state.navigatePage === 3 ? 'show active': ''}`}>
											<h6 className="font-weight-normal">To configure your account using CloudFormation automation</h6>
											<p className="text-muted">Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim.</p>
											<div className="row">
												<div className="col-sm-6">
													<div className='form-group'>
														<label>AWS Account name</label>
														<input
															disabled={true}
															type='text'
															className={`form-control`}
															value={this.state.accountName}
														/>
														<small className="text-muted">Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim.</small>
													</div>
													<div className='form-group'>
														<label>Account ID</label>
														<input
															type='text'
															className={`form-control ${(hasError && accountId === '') ? 'is-invalid' : accountId === '' ? '' : 'is-valid' }`}
															value={this.state.accountId}
															onChange={event => this.setState({ accountId: event.target.value, showLoginError: false })}
															placeholder='Account ID'
														/>
														<div className='invalid-feedback'>Please enter a valid account id.</div>
														<small className="text-muted">Officia amet eiusmod eu sunt tempor voluptate laboris
															velit nisi amet enim.</small>
													</div>

													<div className='form-group'>
														<label>ARN of IAM role</label>
														<input
															type='text'
															className={`form-control ${(hasError && arn === '') ? 'is-invalid': arn === '' ? '' : 'is-valid' }`}
															value={this.state.arn}
															onChange={event => this.setState({ arn: event.target.value, showLoginError: false })}
															placeholder='Arn'
														/>
														<div className='invalid-feedback'>Please enter a valid ARN.</div>
														<small className="text-muted">Officia amet eiusmod eu sunt tempor voluptate laboris
															velit nisi amet enim.</small>
													</div>

													<h6 className="font-weight-bold my-2 text-primary">Cloud Trial</h6>
													
													<div className='form-group'>
														<label>Cloud Trial S3 Bucket name</label>
														<input
															type='text'
															className={`form-control`}
															value={this.state.log_bucket}
															onChange={event => this.setState({ log_bucket: event.target.value, showLoginError: false })}
														/>
														<small className="text-muted">Officia amet eiusmod eu sunt tempor voluptate laboris
															velit nisi amet enim.</small>
													</div>
													<div className='form-group'>
														<label>Cloud Trial s3 Prefix</label>
														<input
															type='text'
															className={`form-control`}
															value={this.state.log_path}
															onChange={event => this.setState({ log_path: event.target.value, showLoginError: false })}
														/>
														<small className="text-muted">Officia amet eiusmod eu sunt tempor voluptate laboris
															velit nisi amet enim.</small>
													</div>
													<h6 className="font-weight-bold my-2 text-primary">Billing</h6>
													<div className='form-group'>
														<label>Billing S3 Bucket name</label>
														<input
															type='text'
															className={`form-control`}
															value={this.state.billing_bucket}
															onChange={event => this.setState({ billing_bucket: event.target.value, showLoginError: false })}
														/>
														<small className="text-muted">Officia amet eiusmod eu sunt tempor voluptate laboris
															velit nisi amet enim.</small>
													</div>
													<div className='form-group'>
														<label>Billing s3 Prefix</label>
														<input
															type='text'
															className={`form-control`}
															value={this.state.billing_path}
															onChange={event => this.setState({ billing_path: event.target.value, showLoginError: false })}
														/>
														<small className="text-muted">Officia amet eiusmod eu sunt tempor voluptate laboris
															velit nisi amet enim.</small>
													</div>
													<div className='text-danger mb-2'>{this.state.showAccountSetupErrorMessage}</div>
													<div className="d-flex">
														<button className='btn btn-primary mr-3' onClick={() => this.setupAccount(3)} disabled={onLoading}>
															{onLoading ? 
																<React.Fragment>
																	{'Complete Setup'}
																	<Spinner className='text-center ml-2' color='light' size='sm' />{' '}
																</React.Fragment>
															: 'Complete Setup'
															}
														</button>
													</div>
												</div>
												<div className="col-sm-6">
													<div className="border p-3 rounded accountInstruction">
														<div className="d-flex">
															<div className="col-sm-9 m-0 p-0">
															<h6 className="font-weight-normal">Instructions for config your account</h6>
															</div>
															<div className="col-sm-3 m-0 p-0 text-right">
																<span className="text-info cursorPointer" onClick={() => this.setState({ infoPopup: !this.state.infoPopup }) }>View</span>

															</div>
														</div>
														<p className="text-muted">To configure your account using CloudFormation automation</p>
														<ol>
															<li>Extrena Id {userDetails && userDetails.contactNumber && userDetails.contactNumber}</li>
															<li>Sign in to your target AWS account browser tab</li>
															<li>Download and review our CloudForamtion template</li>
															<li>Click Launch Stack</li>
															<li>Check "acknowledgement the AWS CloudFormation might create IAM resources with custom names." and click Create</li>
															<li>When Stack Creation is finished, go to the Output and copy CloudConfirmationRoleArn and paste it below. <br />
															<small>Note if the Slack is not shown you might need to click refresh button.</small></li>
															
														</ol>
													</div>
												</div>
											</div>
										</div>
										<div className={`tab-pane fade ${this.state.navigatePage === 4 ? 'show active': ''}`}>
											<h4 className="text-success">Your account set up has been completed</h4>
											<p className="small text-muted">
												Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet
												enim.
											</p>
											{/* <p>Checking <b>599</b> Rules</p>
											<p>Checking security group excessive counts..</p> */}
											<Link to={URL_PATH.SETTINGS} className='text-decoration-none'>
												Back to Dashboard
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>				
				<Modal centered className="infoPopup" backdrop="static" isOpen={this.state.infoPopup} toggle={this.toggleInfoModal}>
					<ModalHeader toggle={this.toggleInfoModal}> Instructions for config your account
					</ModalHeader>
					<ModalBody>
						<div className="p-3 accountInstruction">
							<p className="text-muted">To configure your account using CloudFormation automation</p>
							<ol>
								<li>Sign in to your target AWS account browser tab</li>
								<li>Download and review our CloudForamtion template</li>
								<li>Click Launch Stack</li>
								<li>Check "acknowledgement the AWS CloudFormation might create IAM resources with custom names." and click Create</li>
								<li>When Stack Creation is finished, go to the Output and copy CloudConfirmationRoleArn and paste it below. <br />
								<small>Note if the Slack is not shown you might need to click refresh button.</small></li>
								
							</ol>
						</div>
					</ModalBody>
					{/* <ModalFooter>
						<button className='btn btn-sm btn-light mr-3' onClick={this.toggleInfoModal}> Close</button>
					</ModalFooter> */}
				</Modal>
			</div>
		
		)
	}
}

/**
 * Type of the props used in the component
 */
AccountRegister.propTypes = {
	addAccount: PropTypes.func,
	getUserOrganization: PropTypes.func,
}

export default AppWrapper(AccountRegister, null, {addAccount, getUserOrganization})
