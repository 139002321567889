/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Remediation.js
 * @author Prakash // on 10/02/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Spinner, UncontrolledPopover, PopoverHeader, PopoverBody, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { getRemediationStatus } from '../../../actions/aiops/RemediationAction'
import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'
import { PRIORITY_FILTER_OPTIONS } from '../../../utils/constants'
import { momentDateTimeSec, twoDateDiffrenceDayHoursMinutes, capitalizeFirstLetter, momentDateGivenFormat } from '../../../utils/utility'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'

import aiops_architecture_diagram from '../../../assets/images/aiops_architecture_diagram.png'
import YouTube from 'react-youtube'
// import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
// const testHTML = `<div class="col-md-12 mt-2 mb-2"><h6 class="font-weight-bold text-primary">Overview</h6><div class="row mb-2"><div class="col-md-12"><label class="mb-0 font-weight-bold"> What is this Remediation?</label></div><div class="col-md-12 mb-2"><small class="mb-2">LOREMIPSUM LOREMIPSUM LOREMIPSUM LOREMIPSUM LOREMIPSUM LOREMIPSUM</small></div><div class="col-md-12"><label class="mb-0 font-weight-bold"> What steps involved in this Remediation?</label></div><div class="col-md-12"><small>Log in to your Windows instance using Remote Desktop. For more information, see Connect to your Windows instance.</small></div><div class="col-md-12"><small>2.Start the Disk Management utility. On the taskbar, open the context (right-click) menu for the Windows logo and choose Disk Management.</small></div><div class="col-md-12"><small>3.Bring the volume online. In the lower pane, open the context (right-click) menu for the left panel for the disk for the EBS volume. Choose Online.</small></div><div class="col-md-12 my-2"><small><img src="/static/media/aiops_architecture_diagram.db344755.png" class="img-fluid" alt="Acrhcitecture Diagram"></small></div><div class="col-md-12 my-2"><h6>Recommended Video</h6><small><div class=""><iframe frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" width="640" height="360" src="https://www.youtube.com/embed/5Peo-ivmupE?enablejsapi=1&amp;origin=http%3A%2F%2Flocalhost%3A3000&amp;widgetid=1" id="widget2"></iframe></div></small></div></div></div>

//     <p>Link to <a href="https://www.google.com">Google.com</a></p>
// `;

const instanceType = [
    'Mac',
    'T4g',
    'T3'
]

const runbookArray = [
    {name: 'Test 1', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit', link: 'www.google.com'},
    {name: 'Test 2', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit', link: 'www.gmail.com'},
    {name: 'Test 3', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit', link: 'www.tvastr.com'}
]

class RemediationRightSection extends Component {
    
    constructor(props) {

        super(props)
        this.providerRef = React.createRef()
        this.scrollRef = React.createRef()
        
        this.state = {
            providers: [],			
            isProviderOpen: false,
            selectedProvider: this.props.selectedProvider,
            
            isPriorityOpen: false,
            selectedPriority: [],

            activeSideTopTab: 'Overview',

            recommandedArchitecture: false,
            recommandedVideo: false,

            showExecutionLoading: false,

            progress: 30,
            remediationStatus: {},
            instanceType: instanceType,
            
        }
        this.toggleArchitectureCloseModal = this.toggleArchitectureCloseModal.bind(this)
        this.toggleVideoCloseModal = this.toggleVideoCloseModal.bind(this)   

        this.detailSteps = 0;
        
    }
    
    toggleArchitectureCloseModal = (event) => {
        this.setState({ recommandedArchitecture: !this.state.recommandedArchitecture })
	}
	
    toggleVideoCloseModal = (event) => {
        this.setState({ recommandedVideo: !this.state.recommandedVideo })
	}

    componentDidMount = () => {
        // if(this.state.activeSideTopTab === 'Execution') {
        //     this.getRemediationStatus()
        // }        
    }
    
    componentDidUpdate = (prevProps) => {
        if(this.props.selectedRemediation !== prevProps.selectedRemediation) {
            this.setState({
                // selectedRecord: this.props.selectedRemediation.selectedRecord,
                // selectedRecordId: this.props.selectedRemediation.remediation_id,
                activeSideTopTab: this.props.selectedRemediation.sideMenu
            })
            if(this.props.selectedRemediation.sideMenu === 'Execution') {
                this.getRemediationStatus()
            }
        }

    }

    getRemediationStatus = () => {
        let remStatus = {}
        remStatus.provider =  Array.isArray(this.props.caseDetails.provider) ? this.props.caseDetails.provider[0].toLowerCase() : this.props.caseDetails.provider
        remStatus.region = this.props.selectedRemediation.region
        remStatus.account_id =  this.props.selectedRemediation.account_id
        remStatus.processor_id = this.props.selectedRemediation.remediation_id

        this.props.getRemediationStatus(remStatus, (promise, result) => {
            if(promise) {
                if(this.state.executionStatus !== result.overall_status.status) {
                    this.props.setAiopsPropsDetails('remediation_status_change', result.overall_status.status)
                }
                this.setState({ showExecutionLoading: false, remediationStatus: result, executionStatus: result.overall_status.status },
                    () => {                                    
                        this.getStatusLoop()
                    }
                )
            } else {
                this.setState({ showExecutionLoading: false, remediationStatus: [] })
            }
        })
    }

	getStatusLoop = () => {
        if(this.state.executionStatus === 'InProgress' || this.state.executionStatus === 'inProgress') {
            setTimeout(() => {
                this.getRemediationStatus()
            }, 5000)
        }
	}

    handleMultiSelectChange = (childKey, arrayValue) => {
        // let value = arrayValue.map(item => item.value)
        // let label = arrayValue.map(item => item)
        let currentState = arrayValue

        if(childKey === 'selectedPriority') {
            let selectedPriority = arrayValue
            let prevState = this.state.selectedPriority

            // let value = arrayValue.map(item => item.value)
            let label = arrayValue.map(item => item)

            prevState = prevState.filter(obj => obj.value === 'All')
            currentState = arrayValue.filter(obj => obj.value === 'All')
            if(prevState.length && currentState.length) {
                arrayValue = arrayValue.filter(obj => obj.value !== 'All')
                selectedPriority = []
                arrayValue.forEach(item => {
                    PRIORITY_FILTER_OPTIONS.forEach(prio => {
                        if(prio.value === item.value) {
                            label = {}
                            label.value = prio.value
                            label.label = prio.option
                            selectedPriority.push(label)
                        }
                    })
                })
            } else if(prevState.length && !currentState.length) {
                selectedPriority = []
            } else if(!prevState.length && !currentState.length) {
                selectedPriority = arrayValue
            } else if(!prevState.length && currentState.length) {
                selectedPriority = []
                PRIORITY_FILTER_OPTIONS.forEach(prio => {
                    label = {}
                    label.value = prio.value
                    label.label = prio.option
                    selectedPriority.push(label)
                })
                
            }
            this.setState({ selectedPriority }) 	
        }
    }

    getMultiSelectedCount = (type, array) => {
        return array.length && array.filter(obj => obj.value === 'All').length ? 'All' :  array.length ? array.length +' Selected' : 'All'
    }	

    handleClickGroup = (val) => {
        this.setState({ selectedTableDrop: this.state.selectedTableDrop === val ? '' : val })
    }

    getCmdResult = (event) => {
        if(event.charCode === 13 || event.keyCode === 13) {
            let cmdResult = [
                {
                    "taskStartTime": "2021-02-15 12:58:43",
                    "processorId": "07e5faf3-5c84-4f4e-8301-45bede259b3a",
                    "eventName": "StopEC2Instance",
                    "type": "loop",
                    "taskStatus": "completed",
                    "taskDuration": "0:01:07",
                    "index": null,
                    "processor_rule_id": null,
                    "output": "{\"status\": \"Success\", \"data\": {\"WAIT60\": [{}], \"DescribeInstanceState\": [{\"id\": \"i-0dfbaa84a60153f25\", \"message\": \"Current State and Desired state is stopped\", \"status\": \"stopped\"}]}}",
                    "sequence": 1,
                    "taskMessage": "{}",
                    "taskName": "loop-sequence",
                    "taskId": "InstanceStopStateDecider",
                    "taskEndTime": "2021-02-15 12:59:50"
                },
                {
                    "taskStartTime": "2021-02-15 12:58:47",
                    "processorId": "07e5faf3-5c84-4f4e-8301-45bede259b3a",
                    "eventName": "StopEC2Instance",
                    "type": "task",
                    "taskStatus": "completed",
                    "taskDuration": "0:01:01",
                    "index": 0,
                    "processor_rule_id": null,
                    "output": "[{\"id\": \"i-0dfbaa84a60153f25\", \"message\": \"Current State and Desired state is stopped\", \"status\": \"stopped\"}]",
                    "sequence": 2,
                    "taskMessage": "{}",
                    "taskName": "DescribeInstanceState",
                    "taskId": "DescribeInstanceState",
                    "taskEndTime": "2021-02-15 12:59:48"
                }
            ]

            this.setState({ cmdResult })
        }
    }

    scrollBottom = () => {
        // console.log(this.scrollRef, 'his.scrollRef.current')
        this.scrollRef.current.scrollIntoView({ behavior: 'smooth' })
        // window.scrollTo(0, this.scrollRef);
    }
    

	render() {
		return (
            <div className="row remediationRightSection">
                <div className="detail w-100">
                    <div className="detail-title w-100">
                        <div className="d-flex text-white justify-content-between displayNone">
                            <div>
                                <p className="text-blue mb-0">Rem MemID 012254</p>
                            </div>
                        </div>
                        <div className="row text-white mt-2">
                            <div className="col-sm-6">
                                <p className="mb-2">Type: <b>{this.props.remediationDetails && this.props.remediationDetails.processor_flow ? this.props.remediationDetails.processor_flow : ''}</b></p>
                                <p className="mb-2">Score: <b>100%</b></p>
                                <p className="mb-0">No. of version: <b>{this.props.remediationDetails && this.props.remediationDetails.version ? this.props.remediationDetails.version : ''}<span id="previousVersion" className="cursorPointer displayNone"> (see previous versions)</span></b></p>
                                <UncontrolledPopover className="previousVersionPopup" trigger="hover" placement="top" target='previousVersion'>
                                    <PopoverHeader>
                                        <label className="mb-0 font-weight-bold"> Previous Version</label>
                                    </PopoverHeader>
                                    <PopoverBody>
                                        <div className="col-md-12">
                                            <small>v1.0.0</small>
                                        </div>
                                        <div className="col-md-12">
                                            <small>v1.0.1</small>
                                        </div>
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <div className="col-sm-6">
                                <p className="mb-2">Rel Cases: <b>{this.props.remediationDetails && this.props.remediationDetails.processor_rule_id ? this.props.remediationDetails.processor_rule_id : ''}</b></p>
                                <p className="mb-2">Other Details: <b>FAQs and Remediation step</b></p>
                                <p className="mb-0">Average Completion time: <b>{this.props.remediationDetails && this.props.remediationDetails.average_completion_time ? this.props.remediationDetails.average_completion_time : ''}</b></p>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <ul className={`nav nav-tabs border-bottom bd-highlight bg-muted shadow-none`} id="myTab" role="tablist">
                            <li className="nav-item">
                                <span className={`nav-link text-white cursorPointer ${this.state.activeSideTopTab === 'Overview' ? ' active' : ''}`} onClick={() => this.setState({ activeSideTopTab: 'Overview' })}>
                                    Overview
                                </span>
                            </li>
                            <li className="nav-item">
                                <span className={`nav-link text-white cursorPointer ${this.state.activeSideTopTab === 'Execution' ? ' active' : ''}`} onClick={() => this.setState({ activeSideTopTab: 'Execution' }, () => this.getRemediationStatus())}>
                                    Execution
                                </span>
                            </li>
                            <li className="nav-item">
                                <span className={`nav-link text-white cursorPointer ${this.state.activeSideTopTab === 'Runbook' ? ' active' : ''}`} onClick={() => this.setState({ activeSideTopTab: 'Runbook' })}>
                                    Runbook
                                </span>
                            </li>
                            <li className="nav-item displayNone">
                                <span className={`nav-link cursorPointer ${this.state.activeSideTopTab === 'Similar' ? ' active' : ''}`} onClick={() => this.setState({ activeSideTopTab: 'Similar' })}>
                                    Similar
                                </span>
                            </li>
                            <li className="nav-item displayNone">
                                <span className={`nav-link cursorPointer ${this.state.activeSideTopTab === 'Diagnostics' ? ' active' : ''}`} onClick={() => this.setState({ activeSideTopTab: 'Diagnostics' })}>
                                    Diagnostics
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-content p-3" id="myTabContent">
                        <div class="tab-pane fade show active">
                            {this.state.activeSideTopTab === 'Overview' ?
                                this.props.remediationDetails && this.props.remediationDetails.details ?
                                    <React.Fragment>
                                    {/* <p className="mb-0 f16 text-white">Overview</p> */}
                                    {/* <p class="small mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p> */}
                                    <div class="rounded bg-dark p-3 mb-3">
                                        <div class="row">
                                            <div className="col-sm-12">
                                                <p className="mb-1 f16 text-white">{this.props.remediationDetails.title ? this.props.remediationDetails.title : ''}</p>
                                                {/* <div className='dropdown-divider-dark'></div> */}
                                                <div className="d-flex mb-1 border-bottom">
                                                    <div className="py-1 w-50">
                                                        <p className="b-block mb-0">Created By:</p>
                                                        <p className="mb-0 text-white">{this.props.remediationDetails.created_by ? this.props.remediationDetails.created_by : ''}</p>
                                                    </div>
                                                    <div className="py-1 w-50 pl-3">
                                                        <p className="b-block mb-0">Created Date:</p>
                                                        <p className="mb-0 text-white">{this.props.remediationDetails.created_date ? momentDateGivenFormat(this.props.remediationDetails.created_date, 'DD MMM YYYY HH:mm') : ''}</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-1 border-bottom">
                                                    <div className="py-1 w-50">
                                                        <p className="b-block mb-0">Last Mod. By:</p>
                                                        <p className="mb-0 text-white">{this.props.remediationDetails.last_modified_by ? this.props.remediationDetails.last_modified_by : ''}</p>
                                                    </div>
                                                    <div className="py-1 w-50 pl-3">
                                                        <p className="b-block mb-0">Last Mod Date:</p>
                                                        <p className="mb-0 text-white">{this.props.remediationDetails.last_modified_date ? momentDateGivenFormat(this.props.remediationDetails.last_modified_date, 'DD MMM YYYY HH:mm') : ''}</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="py-1 w-50">
                                                        <p className="b-block mb-0">No. of version:</p>
                                                        <p className="mb-0 text-white">
                                                            {this.props.remediationDetails.version ? this.props.remediationDetails.version : ''}
                                                            <span id="previousVersion" className="cursorPointer displayNone"> (see previous versions)</span>
                                                            <UncontrolledPopover className="previousVersionPopup" trigger="hover" placement="top" target='previousVersion'>
                                                                <PopoverHeader>
                                                                    <label className="mb-0 font-weight-bold"> Previous Version</label>
                                                                </PopoverHeader>
                                                                <PopoverBody>
                                                                    <div className="col-md-12">
                                                                        <small>v1.0.0</small>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <small>v1.0.1</small>
                                                                    </div>
                                                                </PopoverBody>
                                                            </UncontrolledPopover>
                                                        </p>
                                                    </div>
                                                    <div className="py-1 w-50 pl-3">
                                                        <p className="b-block mb-0">Average Completion time:</p>
                                                        <p className="mb-0 text-white">{this.props.remediationDetails.average_completion_time ? this.props.remediationDetails.average_completion_time : ''}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="rounded bg-dark p-3 mb-3">
                                        <p className="mb-1 f16 text-white">Resource Information</p>
                                        {this.props.assetsList && this.props.assetsList.length && this.props.aiopsPropsDetails && this.props.selectedRemediation ?
                                            this.props.assetsList.filter(arr => arr.asset_name === this.props.selectedRemediation.asset_name).map(item => {
                                                return (
                                                    <React.Fragment>
                                                    <div className="d-flex mb-1 border-bottom">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Account:</p>
                                                            <p className="mb-0 text-white">{item.provider ? item.provider.toUpperCase()+' /' : ''} {item.account_id ? item.account_id : ''}</p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Region:</p>
                                                            <p className="mb-0 text-white">{item.region ? item.region : ''}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-1 border-bottom">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Resource Type:</p>
                                                            <p className="mb-0 text-white">{item.resource_type ? item.resource_type : ''}</p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Service:</p>
                                                            <p className="mb-0 text-white">{item.service_name ? item.service_name : ''}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Asset Id:</p>
                                                            <p className="mb-0 text-white">{item.asset_id ? item.asset_id : ''}</p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Asset Name:</p>
                                                            <p className="mb-0 text-white">{item.asset_name ? item.asset_name : ''}</p>
                                                        </div>
                                                    </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        : null }
                                    </div>
                                    <div class="rounded bg-dark p-3 mb-3 displayNone">
                                        <p class="text-white mb-2">Artifacts / Documents Associated with this Remediation</p>
                                        {this.props.artifactDetails && this.props.artifactDetails.length ?
                                            this.props.artifactDetails.map(item => {
                                                return(
                                                <React.Fragment>
                                                    <p class="mb-1 text-white">{item.name ? item.name : ''}</p>
                                                    {/* <small class="col-sm-12 mb-2">{item.description ? item.description : ''}</small> */}
                                                    <div className="d-flex mb-1 border-bottom">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Remediation Id: </p>
                                                            {item.remediation_ids ? 
                                                                item.remediation_ids.map(id => {
                                                                    return (
                                                                    <small className={`mr-1 badge badge-secondary`}>{id}</small>
                                                                    )
                                                                }) 
                                                            : null
                                                            }
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Remediation Type:</p>
                                                            <p className="mb-0 text-white">{item.artifact_type ? item.artifact_type : ''}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-1 border-bottom">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Created By:</p>
                                                            <p className="mb-0 text-white">{item.created_by ? item.created_by : ''}</p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Created Date: </p>
                                                            <p className="mb-0 text-white">{item.created_at ? momentDateGivenFormat(item.created_at, 'DD MMM YYYY HH:mm') : ''}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-1 border-bottom">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Modified By:</p>
                                                            <p className="mb-0 text-white">{item.modified_by ? item.modified_by : ''}</p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">CreateModifiedd Date: </p>
                                                            <p className="mb-0 text-white">{item.modified_at ? momentDateGivenFormat(item.modified_at, 'DD MMM YYYY HH:mm') : ''}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex border-bottom">
                                                        <div className="py-1 w-100">
                                                            <p className="b-block mb-0">Description:</p>
                                                            <p className="mb-0 text-white">{item.description ? item.description : ''}</p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">CreateModifiedd Date: </p>
                                                            <p className="mb-0 text-white">{item.modified_at ? momentDateGivenFormat(item.modified_at, 'DD MMM YYYY HH:mm') : ''}</p>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                                )
                                            })
                                        : null}

                                        <div className={`mt-3`}>
                                            <ResizeableDarkThemeTable
                                                columns={[
                                                {
                                                    Header: 'Name',
                                                    accessor: 'name',
                                                    //width: 200
                                                },
                                                {
                                                    Header: 'Description',
                                                    accessor: 'description',
                                                    width: 300
                                                },
                                                {
                                                    Header: 'Link',
                                                    accessor: 'link',
                                                    //width: 200
                                                }
                                            ]}
                                                data={runbookArray}
                                                perPage={5}
                                                dashboard={runbookArray.length > 5 ? true : false}
                                                sortByColumn={'name'}
                                                riskTooltip={[]}
                                                onClickRow={tableRow => {}}
                                                tableSize={'table-xs'}
                                            />
                                        </div>
                                    </div>
                                    
                                    <div class="d-flex justify-content-end text-primary">
                                        {this.props.remediationDetails.details && this.props.remediationDetails.details.architecture_diagram_url && this.props.remediationDetails.details.architecture_diagram_url !== '' ?
                                            <p class="mb-0 mr-3 cursorPointer" onClick={() => this.setState({ recommandedArchitecture: true })}><i class="fa fa-user-chart"></i> Recommended Architecture</p>
                                        : null}

                                        {this.props.remediationDetails.details && this.props.remediationDetails.details.remediation_video_url && this.props.remediationDetails.details.remediation_video_url !== '' ?
                                            <p class="mb-0 cursorPointer" onClick={() => this.setState({ recommandedVideo: true })}><i class="fab fa-youtube"></i> Recommended Video</p>
                                        : null}
                                    </div>
                                    <div class="rounded bg-dark p-3 mb-3">
                                        <div className="col-md-12 mt-2 mb-2">
                                            {this.props.remediationDetails && this.props.remediationDetails.details ?
                                                this.props.remediationDetails.details.overview ?
                                                    <div className="row mb-2">
                                                        {this.props.remediationDetails.details.overview.split("\n").map((str, index) => {
                                                            return (
                                                                index === 0 ?
                                                                    <p class="mb-1 f16 text-white">{str}</p>
                                                                : 
                                                                    <p>{str}</p>
                                                            )
                                                        })}                                                            
                                                    </div>
                                                : null
                                            : null
                                            }
                                        </div>
                                    </div>
                                    {/* { ReactHtmlParser(testHTML) } */}
                                        
                                    
                                    </React.Fragment>
                                : this.props.remediationDetails && !this.props.remediationDetails.details && Object.entries(this.props.remediationDetails).length ? 
                                    <div className='col-md-12 d-flex justify-content-center m-4'>
                                        <p>There are no data on this remediation. Please try adjusting your remediation.</p>
                                    </div>
                                :  !this.props.remediationDetails || !Object.entries(this.props.remediationDetails).length ? 
                                    <div className='col-md-12 d-flex justify-content-center m-4'>
                                        <Spinner className='text-center' color='dark' size='lg' />
                                    </div>
                                : null
                            : this.state.activeSideTopTab === 'Runbook' ?
                                this.props.remediationDetails && this.props.remediationDetails.details ?
                                    <React.Fragment>
                                    {/* <p class="mb-1 f16 text-white">Runbook</p> */}
                                    {/* <p class="small mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p> */}
                                        
                                    <div class="rounded bg-dark p-3 mb-3">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <p class="mb-1 f16">Artifacts / Documents Associated with this Remediation</p>
                                                <div className="d-flex mb-1 border-bottom">
                                                    <div className="py-1 w-50">
                                                        <p className="b-block mb-0">Remediation Id:</p>
                                                        <p className="mb-0 text-white">{this.props.remediationDetails.created_by ? this.props.remediationDetails.created_by : ''}</p>
                                                    </div>
                                                    <div className="py-1 w-50 pl-3">
                                                        <p className="b-block mb-0">Remediation Type:</p>
                                                        <p className="mb-0 text-white">Ansible</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-1 border-bottom">
                                                    <div className="py-1 w-50">
                                                        <p className="b-block mb-0">Created By:</p>
                                                        <p className="mb-0 text-white">{this.props.remediationDetails.created_by ? this.props.remediationDetails.created_by : ''}</p>
                                                    </div>
                                                    <div className="py-1 w-50 pl-3">
                                                        <p className="b-block mb-0">Created Date:</p>
                                                        <p className="mb-0 text-white">{this.props.remediationDetails.created_date ? momentDateGivenFormat(this.props.remediationDetails.created_date, 'DD MMM YYYY HH:mm') : ''}</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-1 border-bottom">
                                                    <div className="py-1 w-50">
                                                        <p className="b-block mb-0">Last Mod. By:</p>
                                                        <p className="mb-0 text-white">{this.props.remediationDetails.last_modified_by ? this.props.remediationDetails.last_modified_by : ''}</p>
                                                    </div>
                                                    <div className="py-1 w-50 pl-3">
                                                        <p className="b-block mb-0">Last Mod. Date:</p>
                                                        <p className="mb-0 text-white">{this.props.remediationDetails.last_modified_date ? momentDateGivenFormat(this.props.remediationDetails.last_modified_date, 'DD MMM YYYY HH:mm') : ''}</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-1 border-bottom">
                                                    <div className="py-1 w-100">
                                                        <p className="b-block mb-0">Description:</p>
                                                        <p className="mb-0 text-white">{this.props.remediationDetails.description ? this.props.remediationDetails.description : ''}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </React.Fragment>
                                : this.props.remediationDetails && !this.props.remediationDetails.details && Object.entries(this.props.remediationDetails).length ? 
                                    <div className='col-md-12 d-flex justify-content-center m-4'>
                                        <p>There are no data on this remediation. Please try adjusting your remediation.</p>
                                    </div>
                                :  !this.props.remediationDetails || !Object.entries(this.props.remediationDetails).length ? 
                                    <div className='col-md-12 d-flex justify-content-center m-4'>
                                        <Spinner className='text-center' color='dark' size='lg' />
                                    </div>
                                : null
                            : this.state.activeSideTopTab === 'Execution' ?       
                                !this.state.showExecutionLoading && this.props.remediationStatus && Object.entries(this.props.remediationStatus).length && this.props.remediationStatus.overall_status ?
                                    <React.Fragment>
                                        {/* <p class="mb-0"><b>Execution</b></p> */}
                                        {/* <p class="small mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p> */}

                                        <div class="rounded bg-dark p-3 my-3">
                                            <div class="row">
                                                <div className="col-sm-12">
                                                    <p className="mb-1 f16 text-white">{this.props.selectedRemediation.selectedRecord && this.props.selectedRemediation.selectedRecord.title ? this.props.selectedRemediation.selectedRecord.title : ''}</p>
                                                    <div className="d-flex mb-1 border-bottom">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Start Time:</p>
                                                            <p className="mb-0 text-white">{this.props.remediationStatus.overall_status.start_time ? momentDateTimeSec(this.props.remediationStatus.overall_status.start_time, 'DD MMM YYYY HH:mm:ss') : '-'}</p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">End Time:</p>
                                                            <p className="mb-0 text-white">{this.props.remediationStatus.overall_status.end_time ? momentDateTimeSec(this.props.remediationStatus.overall_status.end_time, 'DD MMM YYYY HH:mm:ss') : '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Status:</p>
                                                            {this.props.remediationStatus.overall_status.status ?
                                                                <small className={`mr-1 badge badge-outline-${this.props.remediationStatus.overall_status.status.toLowerCase() === 'success' ? 'success' : this.props.remediationStatus.overall_status.status.toLowerCase() === 'failed' ? 'danger' : 'warning'} text-white`}>
                                                                    {capitalizeFirstLetter(this.props.remediationStatus.overall_status.status)}
                                                                </small>
                                                            : null}
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Duration:</p>
                                                            <p className="mb-0 text-white">{twoDateDiffrenceDayHoursMinutes(this.props.remediationStatus.overall_status.start_time, this.props.remediationStatus.overall_status.end_time ? this.props.remediationStatus.overall_status.end_time : 'currentTime')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="rounded bg-dark p-3 mb-3">
                                            <div class="row">
                                                <div className="col-sm-12">
                                                <p className="mb-1 f16 text-white">Resource Information</p>
                                                    {this.props.assetsList && this.props.assetsList.length && this.props.aiopsPropsDetails && this.props.selectedRemediation ?
                                                        this.props.assetsList.filter(arr => arr.asset_name === this.props.selectedRemediation.asset_name).map(item => {
                                                            return (
                                                                <React.Fragment>
                                                                <div className="d-flex mb-1 border-bottom">
                                                                    <div className="py-1 w-50">
                                                                        <p className="b-block mb-0">Account:</p>
                                                                        <p className="mb-0 text-white">{item.provider ? item.provider.toUpperCase()+' /' : ''} {item.account_id ? item.account_id : ''}</p>
                                                                    </div>
                                                                    <div className="py-1 w-50 pl-3">
                                                                        <p className="b-block mb-0">Region:</p>
                                                                        <p className="mb-0 text-white">{item.region ? item.region : ''}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex mb-1 border-bottom">
                                                                    <div className="py-1 w-50">
                                                                        <p className="b-block mb-0">Resource Type:</p>
                                                                        <p className="mb-0 text-white">{item.resource_type ? item.resource_type : ''}</p>
                                                                    </div>
                                                                    <div className="py-1 w-50 pl-3">
                                                                        <p className="b-block mb-0">Service:</p>
                                                                        <p className="mb-0 text-white">{item.service_name ? item.service_name : ''}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex">
                                                                    <div className="py-1 w-50">
                                                                        <p className="b-block mb-0">Asset Id:</p>
                                                                        <p className="mb-0 text-white">{item.asset_id ? item.asset_id : ''}</p>
                                                                    </div>
                                                                    <div className="py-1 w-50 pl-3">
                                                                        <p className="b-block mb-0">Asset Name:</p>
                                                                        <p className="mb-0 text-white">{item.asset_name ? item.asset_name : ''}</p>
                                                                    </div>
                                                                </div>
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    : null }
                                                </div>
                                            </div>
                                        </div>
                                        <div class="rounded bg-dark p-3 mb-3">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <p className="mb-1 f16 text-white">Remediation steps and details</p>
                                                    {this.props.remediationStatus && Object.entries(this.props.remediationStatus).length ?
                                                        Array.isArray(this.props.remediationStatus.task_status) && this.props.remediationStatus.task_status.map((item, index) => {
                                                            if(index === 0) {
                                                                this.detailSteps = 0
                                                            }
                                                            if(item.taskStartMessage && item.taskStartMessage !== '') {
                                                                this.detailSteps++;
                                                                return(
                                                                    <p><span className="text-underline mb-1">Step {this.detailSteps}</span>: Task Id {item.taskId},&nbsp;{item.taskEndMessage && item.taskEndMessage !== '' ? item.taskEndMessage : item.taskStartMessage} </p>
                                                                )
                                                            }

                                                            // let steps = 0;
                                                            // return(
                                                            //     item.taskStartMessage && item.taskStartMessage !== '' ?
                                                            //         <p><span className="text-underline mb-1">Step {steps}</span>: Task Id {item.taskId},&nbsp;{item.taskEndMessage && item.taskEndMessage !== '' ? item.taskEndMessage : item.taskStartMessage} </p>
                                                            //     : null
                                                            // )
                                                            // steps++
                                                        })
                                                    : null
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div class="rounded bg-white4 p-3 mb-3">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <p class="text-primary mb-1">Remediation Progress</p>
                                                    <div class="rounded bg-dark2 text-white p-3 code">
                                                        {this.props.remediationStatus && Object.entries(this.state.remediationStatus).length ?
                                                            <samp>$ currently executing remediation</samp>
                                                        : null
                                                        } 
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        
                                        <div class="mb-3">                                                
                                            {this.props.remediationStatus.overall_status.status.toLowerCase() === 'success' ?
                                                <div class="d-flex">
                                                    <div class="progress w-50 mr-3 mb-0 align-self-center">
                                                        <div class="progress-bar bg-success w-100"></div>
                                                    </div>
                                                    <p className="font-weight-bold m-0 p-0">100 % Completed. {this.props.remediationStatus.overall_status.step_count} Steps Executed</p>
                                                </div>
                                            : this.props.remediationStatus.overall_status.status.toLowerCase() === 'failed' ?
                                                <div class="d-flex">
                                                    <div class="progress w-50 mr-3 mb-0 align-self-center">
                                                        <div class="progress-bar bg-danger w-100"></div>
                                                    </div> <p className="font-weight-bold m-0 p-0"> Failed 
                                                    {this.props.remediationStatus.overall_status.step_count} Steps Executed</p>
                                                </div>
                                            :
                                                <div class="d-flex">
                                                    <div class="progress w-50 mr-3 mb-0 align-self-center">
                                                        <div class="progress-bar bg-info" style={{backgroundColor:'#06CB9E', width: ((this.props.remediationStatus.current_step ? parseInt(this.props.remediationStatus.current_step) : 0) / this.props.remediationStatus.overall_status.step_count)*100 +'%'}}></div>
                                                    </div>
                                                    <p className="font-weight-bold m-0 p-0">
                                                        {((this.props.remediationStatus.current_step ? parseInt(this.props.remediationStatus.current_step) : 0) / this.props.remediationStatus.overall_status.step_count)*100}% Completed. Executing Step {this.props.remediationStatus.current_step} of {this.props.remediationStatus.overall_status.step_count}</p>
                                                </div>
                                            }
                                        </div>

                                        <div class="rounded bg-dark p-3 mb-3">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <p class="mb-1 f16 text-white">Remediation Progress</p>
                                                    <div class="rounded bg-muted text-white p-3 maxHeight300 overflow-auto">
                                                        {this.props.remediationStatus && Object.entries(this.props.remediationStatus).length ?
                                                            Array.isArray(this.props.remediationStatus.task_status) && this.props.remediationStatus.task_status.map((item, index) => {
                                                            return(
                                                                item.taskStartMessage && item.taskStartMessage !== '' ?
                                                                    <div className="mb-2">
                                                                        <p className="line2">
                                                                            <div className="w-100">
                                                                                <span>Task Id {item.taskId}&nbsp;</span>
                                                                                <span className={`mr-1 ${item.taskStatus.toLowerCase() === 'completed' ? 'text-success' : item.taskStatus.toLowerCase() === 'failed' ? 'text-danger' : 'text-warning' }`}>{item.taskStatus && item.taskStatus !== '' ? capitalizeFirstLetter(item.taskStatus) : ''}</span>
                                                                            </div>
                                                                            <div className="w-100">
                                                                                <span>Task start time {momentDateTimeSec(item.taskStartTime, 'DD MMM YYYY HH:mm:ss')}, {item.taskStartMessage} </span>
                                                                            </div>
                                                                            <div className="w-100">
                                                                                {item.taskEndTime && item.taskEndTime !== '' ?
                                                                                    <React.Fragment>
                                                                                    <div className="w-100">
                                                                                        <span>Task end time {momentDateTimeSec(item.taskEndTime, 'DD MMM YYYY HH:mm:ss')}, {item.taskEndMessage} </span>
                                                                                    </div>
                                                                                    <div className="w-100">
                                                                                        <span className="line2">Time Taken: {twoDateDiffrenceDayHoursMinutes(item.taskStartTime, item.taskEndTime)}</span>
                                                                                    </div>
                                                                                    </React.Fragment>
                                                                                : null
                                                                                }
                                                                            </div>
                                                                            {item.taskStatus && (item.taskStatus === 'Failed' || item.taskStatus === 'failed') ?
                                                                                <div className="w-100">
                                                                                    <span>Task Message {item.taskMessage ? item.taskMessage : ''} </span>
                                                                                </div>
                                                                            : null }
                                                                        </p>
                                                                    </div>
                                                                : null
                                                            )
                                                        })
                                                        : null
                                                        }   
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="rounded bg-dark p-3 mb-3 displayNone">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="d-flex mb-3 justify-content-between">
                                                        <p class="mb-0 align-self-center">Type a command</p>
                                                        <select class="form-control form-control-sm w-auto"
                                                            onChange={event => {
                                                                this.setState({ selectedInstanceType: event.value })
                                                            }}
                                                            >
                                                            <option value="">All</option>
                                                            <option value="Mac">Mac</option>
                                                            <option value="T4g">T4g</option>
                                                            <option value="T3">T3</option>
                                                        </select>
                                                    </div>
                                                    <div class="rounded bg-dark2 text-white p-3 maxHeight300 overflow-auto mb-3">
                                                        {this.state.cmdResult && this.state.cmdResult.length ?
                                                            <div class="d-flex rounded bg-dark2 text-white p-3 maxHeight300 overflow-auto mb-3">
                                                                {this.state.cmdResult.map((item, index) => {
                                                                    return(
                                                                        <div className="w-100">
                                                                            {index === 0 ? 
                                                                                <p className="line3 w-10">$ {this.state.cmdLine}</p>
                                                                            : null }
                                                                            
                                                                            <p className="line2 w-90">$ {item.taskStartTime}: Step {item.sequence}: 
                                                                                <span className="line2 ml-1 mr-1">{item.eventName}</span>
                                                                                <span className="line2 mr-1">{item.taskMessage} </span>
                                                                                <span className="line2 mr-1">{item.taskEndTime}</span>
                                                                                <label className={`${item.taskStatus === 'completed' ? 'text-success' : (item.taskStatus === 'Failed' || item.status === 'failed') ? 'text-danger' : 'text-warning' }`}>[{item.taskStatus}]</label>
                                                                            </p>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        : this.state.cmdResult && !this.state.cmdResult.length ?
                                                            <div class="d-flex rounded bg-dark2 text-white p-3 maxHeight300 overflow-auto mb-3">
                                                                <p className="line4"><span className="cursor4">...</span></p>
                                                            </div>
                                                        : null
                                                        }
                                                        <div className="d-flex bg-black text-terminal position-relative w-100 mt-2">
                                                            <input 
                                                                type="text"
                                                                className="form-control border-0 bg-dark2 text-white pl-4" 
                                                                maxlength="128"
                                                                value={this.state.cmdLine ? this.state.cmdLine : ''}
                                                                onKeyDown={this.getCmdResult}
                                                                onChange={e => this.setState({ cmdLine: e.target.value })}
                                                                id="youridhere"
                                                            />
                                                            <label for="youridhere" class="static-value text-white">$ </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                : !this.state.showExecutionLoading && this.state.remediationStatus && !this.state.remediationStatus.overall_status ? 
                                    <div className='col-md-12 d-flex justify-content-center m-4'>
                                        <p>There are no data on this tracking. Please try adjusting your filter.</p>
                                    </div>
                                : this.state.showExecutionLoading ? 
                                    <div className='col-md-12 d-flex justify-content-center m-4'>
                                        <Spinner className='text-center' color='dark' size='lg' />
                                    </div>
                                : null
                            : null
                            }
                        </div>
                    </div>
                </div>
                <Modal className="remediationRightSectionModel" backdrop="static" isOpen={this.state.recommandedArchitecture} toggle={this.toggleArchitectureCloseModal}>
                    <ModalHeader toggle={this.toggleArchitectureCloseModal}>
                        Acrhcitecture Diagram
                    </ModalHeader>
                    <ModalBody>
                        {this.props.remediationDetails && this.props.remediationDetails.details && this.props.remediationDetails.details.architecture_diagram_url && this.props.remediationDetails.details.architecture_diagram_url !== '' ?
                            <small><img src={this.props.remediationDetails.details.architecture_diagram_url} className="img-fluid" alt="Acrhcitecture Diagram" /></small>
                        : 
                            <small><img src={aiops_architecture_diagram} className="img-fluid" alt="Acrhcitecture Diagram" /></small>
                        }
                    </ModalBody>
                </Modal>

                <Modal className="remediationRightSectionModel" backdrop="static" isOpen={this.state.recommandedVideo} toggle={this.toggleVideoCloseModal}>
                    <ModalHeader toggle={this.toggleVideoCloseModal}>  
                        Acrhcitecture Video
                    </ModalHeader>
                    <ModalBody>
                        {this.props.remediationDetails && this.props.remediationDetails.details && this.props.remediationDetails.details.remediation_video_url && this.props.remediationDetails.details.remediation_video_url === '' ?
                            <small><YouTube videoId={this.props.remediationDetails.details.remediation_video_url}  onReady={this._onReady} /></small>
                        : 
                            <small><YouTube videoId="5Peo-ivmupE" onReady={this._onReady} /></small>
                        }
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
RemediationRightSection.propTypes = {
    getRemediationStatus: PropTypes.func,
    setAiopsPropsDetails: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('-',state)
	return {
        remediationDetails: state.aiops.remediationDetails.details,
        artifactDetails: state.aiops.artifactDetails.results ? state.aiops.artifactDetails.results : {},
        remediationStatus: state.aiops.remediationStatus,
        caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : {},
        assetsList: state.aiops.caseDetails.results && state.aiops.caseDetails.results.assets ? state.aiops.caseDetails.results.assets : [],
        aiopsPropsDetails: state.aiops.aiopsPropsDetails ? state.aiops.aiopsPropsDetails : {},
        selectedRemediation: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.selected_remediation ? state.aiops.aiopsPropsDetails.selected_remediation : {},
    }
}

export default connect(mapStateToProps, {
  getRemediationStatus,
  setAiopsPropsDetails,
})(withRouter(RemediationRightSection))