/*************************************************
 * Tvastar
 * @exports
 * @file SlowQueryAction.js
 * @author parakash // 26/11/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'

import {
	SLOWQUERYENDPOINT,
	SLOW_QUERY_GET_SLOW_QUERY_SUMMARY,
	SLOW_QUERY_GET_EACH_SLOW_QUERY_DAILY_TREND,
	POSTGRESQL_GET_QUERY_SUMMARY,
	POSTGRESQL_GET_EACH_QUERY_DAILY_TREND,
	ELASTICACHE_QUERY_GET_SLOW_QUERY_HISTOGRAM,

	ELASTICACHE_GET_QUERY_SUMMARY,
	ELASTICACHE_GET_EACH_QUERY_DAILY_TREND,
} from '../../config'

/**
 * Action to get slow query summary
 * @param {Object} body
 * @param {Function} callback
 */
export const getSlowQuerySummary = (body, callback) => {
	return async () => {
		await API.post(SLOWQUERYENDPOINT, SLOW_QUERY_GET_SLOW_QUERY_SUMMARY, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			callback(false)
		})
    }
}

/**
 * Action to get each slow query daily trend
 * @param {Object} body
 * @param {Function} callback
 */
export const getEachSlowQueryDailyTrend = (body, callback) => {
	return async () => {
		await API.post(SLOWQUERYENDPOINT, SLOW_QUERY_GET_EACH_SLOW_QUERY_DAILY_TREND, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			callback(false)
		})
    }
}


/**
 * Action to get slow query summary
 * @param {Object} body
 * @param {Function} callback
 */
 export const getPostgreSQLSlowQuerySummary = (body, callback) => {
	return async () => {
		await API.post(SLOWQUERYENDPOINT, POSTGRESQL_GET_QUERY_SUMMARY, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			callback(false)
		})
    }
}

/**
 * Action to get each slow query daily trend
 * @param {Object} body
 * @param {Function} callback
 */
export const getPostgreSQLEachSlowQueryDailyTrend = (body, callback) => {
	return async () => {
		await API.post(SLOWQUERYENDPOINT, POSTGRESQL_GET_EACH_QUERY_DAILY_TREND, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			callback(false)
		})
    }
}

/**
 * Action to get each slow query daily trend
 * @param {Object} body
 * @param {Function} callback
 */
export const getElasticacheSlowQueryHistogram = (body, callback) => {
	return async () => {
		await API.post(SLOWQUERYENDPOINT, ELASTICACHE_QUERY_GET_SLOW_QUERY_HISTOGRAM, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			callback(false)
		})
    }
}

/**
 * Action to get slow query summary
 * @param {Object} body
 * @param {Function} callback
 */
 export const getElaticSQLSlowQuerySummary = (body, callback) => {
	return async () => {
		await API.post(SLOWQUERYENDPOINT, ELASTICACHE_GET_QUERY_SUMMARY, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			callback(false)
		})
    }
}

/**
 * Action to get each slow query daily trend
 * @param {Object} body
 * @param {Function} callback
 */
export const getElasticSQLEachSlowQueryDailyTrend = (body, callback) => {
	return async () => {
		await API.post(SLOWQUERYENDPOINT, ELASTICACHE_GET_EACH_QUERY_DAILY_TREND, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			callback(false)
		})
    }
}