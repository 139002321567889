/*************************************************
 * Tvastar
 * @exports
 * @file Analysis.js
 * @author Prakash // on 02/03/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { Spinner  } from 'reactstrap'
import { momentDateGivenFormat } from '../../../utils/utility'

import AlertAnalysis from './alerts/AlertAnalysis'
import AnomalyAnalysis from './anomaly/AnomalyAnalysis'
import ErrorAnalysis from './errors/ErrorAnalysis'
import EventAnalysis from './events/EventAnalysis'

const durationOptions = [    
	{ value: '+1h', option: 'Last 1 hour' },
    { value: '+3h', option: 'Last 3 hours' },
    { value: '+6h', option: 'Last 6 hours' },
    { value: '+12h', option: 'Last 12 hours' },
	{ value: '+24h', option: 'Last 24 hours' },
	{ value: '+3d', option: 'Last 3 days' },
	{ value: '+7d', option: 'Last 1 week' },
    { value: '+14d', option: 'Last 2 weeks' },
    { value: '+30d', option: 'Last 1 month' },
	{ value: 'custom', option: 'Custom Date' },
]

class Analysis extends Component {
	constructor(props) {
		super(props)
		this.state = {
			activeTab: "Alerts",
		}
	}

	componentDidMount = () => {
		document.addEventListener('keydown', this._handleKeyDown)
	}		

	_handleKeyDown = event => {
		switch (event.keyCode) {
			case 27:
				this.props.hideAnalysisDetails()
				break
			default:
				break
		}
	}
    
	render() {		
		return (
			<div className="advanced-search">
				<div className="search-content bg-muted w-80">
					<div className="header-search bd-highlight d-flex justify-content-between">
						<div className="flex-fill bd-highlight">
							<h5>Observability Analysis</h5>
							<div className="mr-3 d-flex">
								<div className="d-flex mr-3">
									<p className="m-0 small text-primary-color pr-1">Provider: </p>  
									<p className="m-0 small">{this.props.selectedProvider.toUpperCase()}</p>
								</div>
								<div className="mr-3 d-flex">
									<p className="m-0 small text-primary-color pr-1">Account: </p>
									<p className="m-0 small"> {this.props.selectedAccount && this.props.selectedAccount.length ? this.state.selectedAccount.join(", ") : 'All'}</p>
								</div>
								{this.props.selectedDuration !== "custom" ?
									<div className="mr-3 d-flex">
										<p className="m-0 small text-primary-color pr-1">Duration: </p>
										{durationOptions.map((item, index) => {
											return (
												this.props.selectedDuration === item.value ?
													<p className="m-0 small">{item.option}</p>
												: null
											)
										})}
									</div>
								: 
									<div className="mr-3 d-flex">
										<p className="m-0 small text-primary-color pr-1">Duration:  </p>
										<p className="m-0 small">{momentDateGivenFormat(this.props.start_time, 'DD MMM YYYY HH:mm')}  -  {momentDateGivenFormat(this.props.end_time, 'DD MMM YYYY HH:mm')}</p>
									</div>
								}
							</div>
						</div>
						

						<div className="text-right flex-fill bd-highlight align-self-center">
							<i className="far fa-times cursorPointer" onClick={() => this.props.hideAnalysisDetails()}></i>
						</div>
					</div>
					<div className={`${!this.state.showLoading ? 'overflow-auto' : ''}`}>
						<ul className="nav nav-tabs border-bottom bd-highlight bg-muted shadow-none mb-0" id="myTab" role="tablist">
							<li className="nav-item">
								<span
									className={`nav-link text-white cursor-pointer ${this.state.activeTab === "Alerts" ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
									style={{fontSize:'12px'}}
									onClick={() => this.setState({ activeTab: "Alerts", showLoading:true}, 
										() => {
											if(this.state.complianceByAssets && this.state.complianceByAssets.length === 0) {
												this.getComplianceByAsset()
											} else {
												this.setState ( {showLoading:false} )
											}
										}
									)}
								>
								Alerts
								</span>
							</li>							
							<li className="nav-item">
								<span
									className={`nav-link text-white cursor-pointer ${this.state.activeTab === "Events" ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
									style={{fontSize:'12px'}}
									onClick={() => this.setState({ activeTab: "Events" })}
								>
									Events
								</span>
							</li>
							<li className="nav-item">
								<span 
									className={`nav-link text-white cursor-pointer ${this.state.activeTab === "Anomalies" ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
									style={{fontSize:'12px'}}
									onClick={() => this.setState({ activeTab: "Anomalies" })}
								>
									Anomalies
								</span>
							</li>
							<li className="nav-item">
								<span
									className={`nav-link text-white cursor-pointer ${this.state.activeTab === "Errors" ? 'active' : ''} ${this.state.showLoading ? 'disabled' : ''}`}
									style={{fontSize:'12px'}}
									onClick={() => this.setState({ activeTab: "Errors" })}
								>
								Errors
								</span>
							</li>
						</ul>
						{!this.state.showLoading ?
							this.state.activeTab === "Alerts" ?
								<AlertAnalysis
									start_time = {this.props.start_time}
									end_time = {this.props.end_time}
								/>
							: this.state.activeTab === "Anomalies" ?
								<AnomalyAnalysis
									start_time = {this.props.start_time}
									end_time = {this.props.end_time}
								/>	
							: this.state.activeTab === "Errors" ?
								<ErrorAnalysis
									start_time = {this.props.start_time}
									end_time = {this.props.end_time}
								/>
							: this.state.activeTab === "Events" ?
								<EventAnalysis
									start_time = {this.props.start_time}
									end_time = {this.props.end_time}
								/>
							: null
						:
							<div className='text-center mt-5' ><Spinner color='dark' size='lg' /></div>
						}
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
 Analysis.propTypes = {}

const mapStateToProps = state => {
	return {		
	}
}

export default connect(mapStateToProps, {})(withRouter(Analysis))



